import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Assert } from '../../../../framework/index';
import {
  EmployeeCallLog,
  IEmployeeCallLogRecord, IEmployeeCallLog,
  EmployeeCallLogRecord,
  EmployeeCallLogDto,
  IMessageCenterCallLogRecord
} from '../../models/index';
import { EmployeeDefinitionsMapService } from '../../../../organization/services/index';
import { EmployeeDefinition } from '../../../../organization/models/index';
import { dateTimeUtils } from '../../../../common/utils/index';

@Injectable()
export class EmployeeCallLogMapService {

  private employeeDefinitionsMapService: EmployeeDefinitionsMapService;
  constructor(employeeDefinitionsMapService: EmployeeDefinitionsMapService) {
    this.employeeDefinitionsMapService = employeeDefinitionsMapService;
  }
  public mapToCallLogDto(orgLevelId: number, employeeIds: number[], startDate: Date, endDate: Date, isMessageCenter = false): EmployeeCallLogDto {
    const callLogDto = new EmployeeCallLogDto();

    callLogDto.orgLevelId = orgLevelId;
    callLogDto.employeeIds = employeeIds;

    if (!isMessageCenter) {
      callLogDto.startDate = dateTimeUtils.convertToDtoDateTimeString(startDate);
      callLogDto.endDate = dateTimeUtils.convertToDtoDateTimeString(endDate);
    } else {
      callLogDto.startDate = moment(startDate).toISOString();
      callLogDto.endDate = moment(endDate).toISOString();
    }

    return callLogDto;
  }

  public mapToEmployeeCallLogRecord(drecord: IEmployeeCallLogRecord): EmployeeCallLogRecord {
    Assert.isNotNull(drecord, 'drecord');
    const record: EmployeeCallLogRecord = new EmployeeCallLogRecord();
    record.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(drecord.employee);
    record.type = drecord.contactType;
    record.message = drecord.contactMessage;
    record.sentBy = drecord.sentBy;
    record.sentTime = drecord.sentTime;
    record.response = drecord.response;
    record.responseTime = drecord.responseTime;
    record.noted = drecord.notes;
    record.error = drecord.error;
    record.expiration = drecord.expiration;
    record.status = drecord.status;
    record.sentTimeFC = record.sentTime ? moment(record.sentTime).startOf('day').toDate() : null;
    record.responseTimeFC = record.responseTime ? moment(record.responseTime).startOf('day').toDate() : null;
    return record;
  }

  public mapToCallLog(log: IEmployeeCallLog): EmployeeCallLog {
    Assert.isNotNull(log, 'records');
    Assert.isNotNull(log.records, 'records');
    const callog: EmployeeCallLog = new EmployeeCallLog();
    callog.canLogPhoneCall = log.canLogPhoneCall;
    callog.canSendSms = log.canSendSms;
    callog.canSendToTimeClock = log.canSendToTimeClock;
    _.forEach(log.records, (drecord: IEmployeeCallLogRecord) => {
      const record: EmployeeCallLogRecord = this.mapToEmployeeCallLogRecord(drecord);
      callog.records.push(record);
    });
    return callog;
  }

  public mapWithMCToCallLog(employeeCallLog: EmployeeCallLog, mcCallLogRecordDtos: IMessageCenterCallLogRecord[]): EmployeeCallLog {
    const mcCallLogRecords = this.mapToCallLogRecords(mcCallLogRecordDtos);
    employeeCallLog.records = _.concat(employeeCallLog.records, mcCallLogRecords);
    return employeeCallLog;
  }

  public mapToCallLogRecords(dtos: IMessageCenterCallLogRecord[]): EmployeeCallLogRecord[] {
    return _.map(dtos, dto => this.mapToCallLogRecord(dto));
  }

  public mapToCallLogRecord(dto: IMessageCenterCallLogRecord): EmployeeCallLogRecord {
    const record = new EmployeeCallLogRecord();
    record.employee = new EmployeeDefinition();
    record.employee.id = dto.employeeId;
    record.employee.name = dto.employeeName;
    record.type = dto.messageType;
    record.message = dto.body;
    record.sentBy = dto.sentBy;
    record.sentTime = dateTimeUtils.convertFromDtoToLocalDateTimeString(dto.sentTime);
    record.response = dto.response;
    record.responseTime = dateTimeUtils.convertFromDtoToLocalDateTimeString(dto.responseTime);
    record.error = dto.error;
    record.status = _.lowerCase(dto.status) == 'delivered' ? 'sent' : dto.status;
    record.sentTimeFC = record.sentTime ? moment(record.sentTime).toDate() : null;
    record.responseTimeFC = record.responseTime ? moment(record.responseTime).toDate() : null;
    record.numSegments = dto.numSegments;
    return record;
  }
}

