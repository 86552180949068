import * as moment from 'moment';
import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { EmployeeSectionsFutureUpdate } from '../../../employee/employee-sections/models/index';

@Pipe({
    name: 'checkFutureUpdateRemovablePipe',
    pure: false
})
@Injectable()
export class CheckFutureUpdateRemovablePipe implements PipeTransform {
    public transform(obj: EmployeeSectionsFutureUpdate, now: Date): boolean {
        if (moment(obj.scheduledFor).isAfter(now)) return true;
        return false;
    }
}
