<div class="accruals-toolbar">
  <slx-toolbar class="accruals-toolbar__helper" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate alignMode="left" [width]="effectiveDateWidth">
      <span class="accruals-toolbar__control">
        <div class="accruals-toolbar__effectivedate">
          <label class="accruals-toolbar__effectivedate_date">
            <slx-datepicker #effectiveDateRef="ngModel"
                            class="slx-wide"
                            name="effectiveDate"
                            placeholder="Effective Date"
                            format="MM/dd/yyyy"
                            [acceptNullDate]="false"
                            [ngModel]="effectiveDate"
                            (ngModelChange)="onChangeEffectiveDate($event)"
            ></slx-datepicker>
            <div class="slx-daterange__error slx-error-block" *ngIf="effectiveDateRef.errors">
              <span errorMessage for="date" *ngIf="effectiveDateRef.errors.date"></span>
            </div>
          </label>

          <button type="button" *ngIf="isDesktop" (click)="applyEffectiveDateChange()" [disabled]="effectiveDateRef.errors" class="slx-blue slx-button slx-daterange__button">Apply</button>
        </div>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="55">
      <span class="accruals-toolbar__control  as-flex-start mr-20" *ngIf="!isCollapsed">
        <slx-switcher label="Active Only"
                      width="100"
                      labelOn="Yes"
                      labelOff="No"
                      [ngModel]="state?.isEmployeeActive"
                      (ngModelChange)="onShowActiveEmployee($event)">
        </slx-switcher>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="55">
      <span class="accruals-toolbar__control as-flex-start" *ngIf="!isCollapsed">
        <slx-switcher label="Negative Balances"
                      width="100"
                      labelOn="Yes"
                      labelOff="No"
                      [ngModel]="state?.isShowNegativeBalance"
                      (ngModelChange)="onShowNegativeBalance($event)">
        </slx-switcher>
      </span>
    </ng-template>

    <ng-template  slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="55">
      <span class="accruals-toolbar__control as-flex-start " *ngIf="!isCollapsed">
        <slx-switcher *ngIf="!hideRates" label="Show Dollar Value"
                      labelOn="Yes"
                      labelOff="No"
                      [ngModel]="state?.isDollarValueActive"
                      (ngModelChange)="onToggleDollarValue($event)">
        </slx-switcher>
      </span>
    </ng-template>

 
    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="35">
      <span class="accruals-toolbar__control as-flex-start mt-16" *ngIf="!isCollapsed">
        <button type="button"
          class="slx-button slx-only-icon slx-toolbar"
          [popper]="columnsToggler"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-start'"
          [popperDisableStyle]="true"
        >
          <i aria-hidden="true" class="fa fa-cog"></i>
        </button>
        <popper-content #columnsToggler class="slx-popper accruals-toolbar__column-setting">
          <span class="accruals-toolbar__popper-title">Columns settings</span>
          <div class="accruals-toolbar__popper-body">
            <div *ngFor="let column of columnState?.allColumns; let idx=index;">
              <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                <slx-checkbox-input slx-input
                  [caption]="column.description"
                  [(ngModel)]="column.displayed"
                  (ngModelChange)="onChangeColumn()"
                  name="empColumn{{idx}}"
                  fieldName="empColumn{{idx}}"
                ></slx-checkbox-input>
              </slx-input-decorator>
            </div>
          </div>
          <div class="accruals-toolbar__popper-footer">
            <button class="slx-button btn-apply slx-margin-r" type="button" (click)="onClickToggleSelection(true)">Select All </button>
            <button class="slx-button btn-cancel" type="button" (click)="onClickToggleSelection(false)"> Clear All</button>
          </div>
        </popper-content>
      </span>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="95">
      <span class="accruals-toolbar__control as-flex-start mt-16" *ngIf="!isCollapsed">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>
            <slx-action-list-item (onClick)="onClickExport(true)">Export to PDF</slx-action-list-item>
            <slx-action-list-item *ngIf="calculateAccrualsAction" (onClick)="onClickRecalcAccruals()">Recalculate Accruals</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </span>
    </ng-template>
  </slx-toolbar>
</div>
