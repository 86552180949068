import * as _ from 'lodash';
import { Role, RoleComponentsModule, RoleComponent, RolesComponentAccess, RoleComponentsModuleGroup } from '../role-models/index';
import { RolesRowDefinition, IRolesRow } from './roles-row';
import { RolesComponentRow } from './roles-component-row';

export class RolesComponentsModuleRow implements IRolesRow<RoleComponentsModule> {
  public roles: Role[];
  public mapByRole: NumberMap<RoleComponentsModule>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesComponentRow[];
  public type: RolesRowDefinition = 'RolesComponentsModuleRow';
  public description: string;

  public recalcStatus(roleId: number): void {
    let menuModule: RoleComponentsModule = this.mapByRole[roleId];
    this.dirtyByRole[roleId] = false;
    let existEnabled: boolean = false;
    let existDisabled: boolean = false;
    let existMixed: boolean = false;
    _.forEach(this.childRows, (childRow: RolesComponentRow) => {
      let child: RoleComponent = childRow.mapByRole[roleId];
      existEnabled = existEnabled || (child.access === RolesComponentAccess.enabled);
      existDisabled = existDisabled || (child.access === RolesComponentAccess.disabled);
      existMixed = existMixed || (child.access === RolesComponentAccess.mixed);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
    if (existEnabled && existDisabled || existMixed) {
      menuModule.access = RolesComponentAccess.mixed;
    } else if (existEnabled) {
      menuModule.access = RolesComponentAccess.enabled;
    } else {
      menuModule.access = RolesComponentAccess.disabled;
    }
  }

  public setNextStatus(roleId: number): void {
    let menuModule: RoleComponentsModule = this.mapByRole[roleId];
    if (menuModule.access === RolesComponentAccess.disabled) {
      this.setStatus(true, roleId);
    } else {
      this.setStatus(false, roleId);
    }
  }

  public setStatus(isEnabled: boolean, roleId: number): void {
    let menuModule: RoleComponentsModule = this.mapByRole[roleId];
    menuModule.access = isEnabled ? RolesComponentAccess.enabled : RolesComponentAccess.disabled;
    this.dirtyByRole[roleId] = false;
    _.forEach(this.childRows, (childRow: RolesComponentRow) => {
      childRow.setStatus(isEnabled, roleId);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
  }
}

export class RolesComponentsModuleGroupRow implements IRolesRow<RoleComponentsModuleGroup> {
  public roles: Role[];
  public mapByRole: NumberMap<RoleComponentsModuleGroup>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesComponentsModuleRow[];
  public type: RolesRowDefinition = 'RolesComponentsModuleGroupRow';
  public description: string;

   public recalcStatus(roleId: number): void {
    let menuModuleGroup: RoleComponentsModuleGroup = this.mapByRole[roleId];
    this.dirtyByRole[roleId] = false;
    let existEnabled: boolean = false;
    let existDisabled: boolean = false;
    let existMixed: boolean = false;
    _.forEach(this.childRows, (childRow: RolesComponentsModuleRow) => {
      let child: RoleComponentsModule = childRow.mapByRole[roleId];
      existEnabled = existEnabled || (child.access === RolesComponentAccess.enabled);
      existDisabled = existDisabled || (child.access === RolesComponentAccess.disabled);
      existMixed = existMixed || (child.access === RolesComponentAccess.mixed);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
    if (existEnabled && existDisabled || existMixed) {
      menuModuleGroup.access = RolesComponentAccess.mixed;
    } else if (existEnabled) {
      menuModuleGroup.access = RolesComponentAccess.enabled;
    } else {
      menuModuleGroup.access = RolesComponentAccess.disabled;
    }
  }

  public setNextStatus(roleId: number): void {
    let menuModuleGroup: RoleComponentsModuleGroup = this.mapByRole[roleId];
    if (menuModuleGroup.access === RolesComponentAccess.disabled) {
      this.setStatus(true, roleId);
    } else {
      this.setStatus(false, roleId);
    }
  }

  public setStatus(isEnabled: boolean, roleId: number): void {
    let menuModuleGroup: RoleComponentsModuleGroup = this.mapByRole[roleId];
    menuModuleGroup.access = isEnabled ? RolesComponentAccess.enabled : RolesComponentAccess.disabled;
    this.dirtyByRole[roleId] = false;
    _.forEach(this.childRows, (childRow: RolesComponentsModuleRow) => {
      childRow.setStatus(isEnabled, roleId);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
  }
}
