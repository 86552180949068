import { Injectable } from '@angular/core';
import { IServerValidatorAdapter, IServerValidationResult } from '../../../../common/validators/common-validators-models';
import { BenefitClassApiService } from './benefit-class-api.service';
import * as _ from 'lodash';

@Injectable()
export class BenefitClassValidatorAdapter implements IServerValidatorAdapter {
  public static benefitClassNameValidation: string = 'benefitClassNameValidation';
  public static benefitClassCodeValidation: string = 'benefitClassCodeValidation';

  constructor(private apiService: BenefitClassApiService) {

  }
  public validate(validationName: string, value: any, params: any): Promise<IServerValidationResult> {
    let promise: Promise<IServerValidationResult>;
    let defPromise: Promise<IServerValidationResult> = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
    switch (validationName) {
      case BenefitClassValidatorAdapter.benefitClassNameValidation:
        promise = this.apiService.validateBenefitClass(params.id, params.name,'name', params.orgLevelID);
        break;
      case BenefitClassValidatorAdapter.benefitClassCodeValidation:
          promise = this.apiService.validateBenefitClass(params.id, params.name, 'code',params.orgLevelID);
          break;
      default:
        promise = defPromise;
    }
    return promise;
  }
}
