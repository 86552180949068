export class DisplayType {
  public static idealScheduleHours: DisplayDefinition = 'idealScheduleHours';
  public static idealSchedulePPD: DisplayDefinition = 'idealSchedulePPD';
  public static budgetedPPD: DisplayDefinition = 'budgetedPPD';
  public static budgetedHours: DisplayDefinition = 'budgetedHours';
}

export type DisplayDefinition = 'idealScheduleHours' |
  'idealSchedulePPD' |
  'budgetedPPD' |
  'budgetedHours';
