<div class="slx-static-list" [ngClass]="disabledClass">
  <span class="slx-static-list__label" (click)="onClickLabel()">
    <span class="slx-static-list__icon">
      <i aria-hidden="true" [ngClass]="controlIcon"></i>
    </span>
    <span class="slx-static-list__text">{{ placeholder }}</span>
    <span class="slx-static-list__arrow">
      <i aria-hidden="true" class="fas" [ngClass]="arrowIcon"></i>
    </span>
  </span>
  <span class="slx-static-list__label-m" (click)="onClickLabel()">
    <i aria-hidden="true" class="fas fa-plus"></i>
  </span>
  <ul class="slx-static-list__list" [ngClass]="listClass">
    <li class="slx-static-list__item" *ngFor="let item of items" (click)="onClickItem(item)">
      <span class="slx-static-list__i-icon">
        <i aria-hidden="true" [ngClass]="item[iconField]"></i>
      </span>
      <span class="slx-static-list__i-text">{{ item[titleField] }}</span>
    </li>
  </ul>
</div>
