<div [ngClass]="{'window-container': true, 'full-size': fullSize, 'fixed-szie':!fullSize}">
  <div class="alert alert-danger alert-dismissible" *ngIf="unsavedDataAlert">
    <button class="close" (click)="hideNotSavedErrorMessage()">
      <span aria-hidden="true">&times;</span>
    </button>
    <span>Changes did not saved! Save or discard it!</span>
  </div>
  <slx-census-entries class="census-form" #census [disableDateInput]="true" [hideButtons]="true" (onSaved)="onSave($event)"></slx-census-entries>

  <div class="buttons col-lg-4 col-md-6 col-sm-6">
    <button class="save-btn btn btn-default theme-button-apply margin-r" type="button" [disabled]="!census.canSave" (click)="census.saveChanges()">
      <i class="fa fa-save" aria-hidden="true"></i>Save</button>
    <button class="btn btn-default theme-button-cancel margin-r" *ngIf="census.hasChanges" type="button" (click)="census.discardChanges()">
      <i class="fa fa-ban" aria-hidden="true"></i>Discard</button>
    <button class="btn btn-default theme-button-cancel" *ngIf="!census.hasChanges" type="button" (click)="closeWindow()">
      <i class="fa fa-ban" aria-hidden="true"></i>Cancel
    </button>

  </div>

</div>