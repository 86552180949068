<div class="absences-container">
  <div class="absences-actions-bar">
    <button type="button" class="slx-button slx-with-icon" [disabled]="!canEditTimecard" (click)="addAbsence()">
        <i class="fal fa-plus-circle slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Add Absence</span>
    </button>
    <button type="button" class="slx-button slx-with-icon" *ngIf="canEditTimecard" (click)="discardChanges()">
        <i class="fal fa-times-circle slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Discard Changes</span>
    </button>
  </div>
  <div class="absence-row">
    <div *ngFor="let dataItem of recordsToShow; let rowIndex=index" class="absence-container">
      <div class="absence-header">
        <div class="absence-title">Absence {{rowIndex+1}}</div>
        <div class="absence-buttons">
          <button *ngIf="canEditTimecard" class="slx-icon-button slx-inline-edit-button" (click)="editHandler(rowIndex, dataItem)">
            <i class="fas fa-pencil-alt" aria-hidden="true"></i>
          </button>
          <button *ngIf="canEditTimecard" class="slx-icon-button slx-inline-edit-button" (click)="removeHandler(rowIndex, dataItem)">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <div class="absence-par">
        <span class="absence-par-header">Description:</span>
        <span class="absence-par-value">{{ dataItem.absence?.description }}</span>
      </div>
      <div class="absence-par">
        <span class="absence-par-header">Code:</span>
        <span class="absence-par-value">{{ dataItem.absence?.code }}</span>
      </div>
      <div class="absence-par">
        <span class="absence-par-header">From:</span>
        <span class="absence-par-value">{{ dataItem.start | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </div>
      <div class="absence-par">
        <span class="absence-par-header">To:</span>
        <span class="absence-par-value">{{ dataItem.end | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </div>
      <div class="absence-par">
        <span class="absence-par-header">Interval:</span>
        <span class="absence-par-value" *ngIf="displayOptions==='number'">{{ dataItem.interval | slxInterval }}</span>
        <span class="absence-par-value" *ngIf="displayOptions==='hmstring'">{{ dataItem.interval | slxDurationHM }}</span>
      </div>
    </div>
</div>
</div>

