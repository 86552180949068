import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from './../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/index';
import { GeolocationDialogOptions } from '../../../models/index';
import { GeolocationManagementService } from '../../../services/index';
import { GeolocationDialogComponent } from '../geolocation-dialog/geolocation-dialog.component';
var GeolocationGridComponent = /** @class */ (function () {
    function GeolocationGridComponent(manService, modalService) {
        this.manService = manService;
        this.modalService = modalService;
        this.highlightedRows = [];
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.sort = [{ field: 'employee.name', dir: 'asc' }];
        this.appConfig = appConfig;
    }
    GeolocationGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.entitySelected = this.manService.subscribeToOrgLevelChanged(function (orgLevel) {
            _this.orgLevel = orgLevel;
        });
        this.subscriptions.loaded = this.manService.subscribeToEntitiesLoaded(function (records) {
            _this.records = records;
            _this.refreshGrid();
        });
        this.subscriptions.refresh = this.gridState.onRefreshGrid.subscribe(function () { return _this.refreshGrid(); });
        this.subscriptions.buttons = this.manService.subscribeToGeoEvent(function (event) {
            if (event.isSave || event.isUpdate || event.isCancel) {
                _this.clearGridSelection();
            }
            else if (event.isAdd) {
                _this.isDisabled = true;
            }
            else if (event.isExportToPDF) {
                _this.grid.saveAsPDF();
            }
            else if (event.isExportToExcel) {
                _this.grid.saveAsExcel();
            }
        });
    };
    GeolocationGridComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    GeolocationGridComponent.prototype.onClickEdit = function (entity) {
        this.isDisabled = true;
        this.manService.entitySelected(entity);
        this.highlightedRows.push(entity.id);
    };
    GeolocationGridComponent.prototype.onClickDelete = function (entity, acceptPopover) {
        this.deleteEntity(entity);
        acceptPopover.hide();
    };
    GeolocationGridComponent.prototype.onClickCancel = function (acceptPopover) {
        acceptPopover.hide();
    };
    GeolocationGridComponent.prototype.onClickMap = function (entity, event) {
        event.preventDefault();
        event.stopPropagation();
        var coords = entity.location();
        var options = new GeolocationDialogOptions(coords.lat, coords.lng, 640, 508, 17);
        GeolocationDialogComponent.openDialog(options, this.modalService);
    };
    GeolocationGridComponent.prototype.canEdit = function (entity) {
        return entity.orgLevelId === _.get(this.orgLevel, 'id');
    };
    GeolocationGridComponent.prototype.isParentOrgLevel = function (entity) {
        return this.manService.isParentOrgLevel(_.get(this.orgLevel, 'id'), entity.orgLevelId);
    };
    GeolocationGridComponent.prototype.clearGridSelection = function () {
        this.highlightedRows.length = 0;
        this.isDisabled = false;
    };
    GeolocationGridComponent.prototype.isRowSelected = function () {
        var _this = this;
        return function (event) {
            var entityId = event.dataItem.id;
            return _.indexOf(_this.highlightedRows, entityId) !== -1;
        };
    };
    GeolocationGridComponent.prototype.deleteEntity = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manService.deleteGeolocation(entity)];
                    case 1:
                        _a.sent();
                        this.manService.loadGeolocations(entity.orgLevelId);
                        return [2 /*return*/];
                }
            });
        });
    };
    GeolocationGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    return GeolocationGridComponent;
}());
export { GeolocationGridComponent };
