export interface IWatchAreaConfig {
  watchAreaId: string;
  watchSourceId: string;
  topScrollOffset: number;
  bottomScrollOffset: number;
  prepareScrollRange: number;
}

export class ScrollWatchAreaEventType {
  public static enter: string = 'enter';
  public static prepare: string = 'prepare';
  public static prepareEnter: string = 'prepareEnter';
  public static leave: string = 'leave';
  public static inside: string = 'inside';
  public static outside: string = 'outside';
}

export class ScrollWatchEvent {
  public scrollEvent: MouseEvent;
  public sourceId: string;
  public scrollTop: number;
  public scrollLeft: number;
  public clientHeight: number;
  public clientWidth: number;

  constructor(event: MouseEvent) {
    this.scrollEvent = event;
  }
}

export class ScrollWatchAreaEvent {
  public areaId: string;
  public offsetTop: number;
  public offsetLeft: number;
  public offsetHeight: number;
  public offsetWidth: number;
  public scrollWatchEvent: ScrollWatchEvent;
  public eventHeightType: ScrollWatchAreaEventType;

  constructor(event: ScrollWatchEvent) {
    this.scrollWatchEvent = event;
  }
}
