import { ControlValueAccessorBase } from './control-value-accessor-base';
import * as _ from 'lodash';

export abstract class AcceptableControlValueAccessor extends ControlValueAccessorBase {
  protected onAcceptCallback: (val: any) => boolean | Promise<boolean>;

   public registerOnAccept(fn?: any): void {
    this.onAcceptCallback = fn;
  }

  public changeValue(val: any): boolean | Promise<boolean> {
    if (!this.onAcceptCallback) {
      this.onChangeCallback(val);
      return true;
    }
    let res = this.onAcceptCallback(val);
    if (res instanceof Promise) {
      res.then((value: boolean) => {
        if (value) {
          this.onChangeCallback(val);
        }
      });
      return res;
    }
    if (res) {
      this.onChangeCallback(val);
    }
    return res;
  }
}

export function isAcceptableControlValueAccessor(source: any): source is AcceptableControlValueAccessor {
  if(!source) {
    return false;
  }
  return !!(source.registerOnChange && source.registerOnAccept && source.registerOnTouched);
}
