<div class="am-toolbar">
  <slx-toolbar class="slx-so-toolbar-container" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate [width]="getAcaWidth()">
      <span class="am-toolbar__control as-flex-end">
        <slx-dropdown-list name="yearDropdown"
                           class="slx-wide slx-short"
                           [valuePrimitive]="true"
                           [options]="yearOptions"
                           (ngModelChange)="onChangeYear($event)"
                           [(ngModel)]="selectedYear"
                           [readonly]="isQuickConfirmActive || isQuickEditActive"
                           >
        </slx-dropdown-list>

      </span>
      <span class="pendo-aca-config-1095c-year"></span>
    </ng-template>
    <ng-template slxToolbarSectionTemplate>
      <span class="am-toolbar__control as-flex-end">
        <button type="button"
        class="slx-button slx-wide slx-short slx-with-icon slx-mobile-adapted quickedit slx-no-breaks"
        [disabled]="!isEditableYear"
        (click)="openQuickEdit()"
        [slxTooltip]="reportClosed"
        tipPosition="bottom"
        *ngIf="actions?.edit1095c && !isEditableYear">
        <em class="far fa-calendar-plus"></em>
        <em class="fas fa-hand-point-up"></em>
        <span>Quick Edit</span>
      </button>
      <button type="button"
      class="slx-button slx-wide slx-short slx-with-icon slx-mobile-adapted quickedit slx-no-breaks"
      [disabled]="isQuickConfirmActive"
      (click)="openQuickEdit()"

      tipPosition="bottom"
      *ngIf="actions?.edit1095c && isEditableYear">
      <em class="far fa-calendar-plus"></em>
      <em class="fas fa-hand-point-up"></em>
      <span>Quick Edit</span>
    </button>
      </span>
    </ng-template>
    <ng-template slxToolbarSectionTemplate>
      <span class="am-toolbar__control as-flex-end">
        <button type="button"
        class="slx-button slx-wide slx-short slx-with-icon slx-mobile-adapted quickedit slx-no-breaks"
        [disabled]="!isEditableYear"
        (click)="openQuickConfirm()"
        [slxTooltip]="reportClosed"
        tipPosition="bottom"
        *ngIf="actions?.confirm1095c && !isEditableYear">
        <em class="far fa-calendar-plus"></em>
        <em class="fas fa-hand-point-up"></em>
        <span>Quick Confirm</span>
      </button>
      <button type="button"
        class="slx-button slx-wide slx-short slx-with-icon slx-mobile-adapted quickedit slx-no-breaks"
        [disabled]="isQuickEditActive"
        (click)="openQuickConfirm()"

        tipPosition="bottom"
        *ngIf="actions?.confirm1095c && isEditableYear">
        <em class="far fa-calendar-plus"></em>
        <em class="fas fa-hand-point-up"></em>
        <span>Quick Confirm</span>
      </button>
      </span>
    </ng-template>
    <ng-template slxToolbarSectionTemplate >
      <span class="am-toolbar__control as-flex-end">
        <button type="button"
        class="slx-button slx-wide slx-short slx-with-icon slx-mobile-adapted slx-no-breaks expand "
        *ngIf="!isExpand"
        (click)="onClickExpand(true)">
        <em class="fas fa-chevron-double-down slx-button__icon"></em>
        <span>Expand All</span>
        </button>
        <button type="button"
        class="slx-button slx-wide slx-short slx-with-icon slx-no-breaks expand collapsee"
        *ngIf="isExpand"
        (click)="onClickExpand(false)">
        <em class="fas fa-chevron-double-up slx-button__icon"></em>
        <span>Collapse All</span>
      </button>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" >
      <span class="am-toolbar__control as-flex-end">
        <button type="button"
        class="slx-button slx-wide slx-with-icon slx-mobile-adapted slx-short slx-no-breaks addEmployee"
        [disabled]="isQuickConfirmActive || isQuickEditActive"
        (click)="openAddEmployee()"
        *ngIf="actions?.addEmployee && selectedYear>2019">
          <em class="fas fa-plus slx-button__icon"></em>
          <span>Add Employee</span>
        </button>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="30">
      <span class="am-toolbar__control legand" *ngIf="!isCollapsed">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-list-alt'" [popperContent]="popperLegand" [popperPosition]="'bottom-end'"></slx-action-button>
          <popper-content #popperLegand>
            <slx-action-list-item><em class="rect-10 bg_solitude"></em>Before Hire</slx-action-list-item>
            <slx-action-list-item><em class="rect-10 bg_blue"></em>Enrolled in benefits</slx-action-list-item>
            <slx-action-list-item><em class="rect-10 bg_orange"></em>Not enrolled in benefits</slx-action-list-item>
            <slx-action-list-item><em class="rect-10 bg_brown"></em>Terminated</slx-action-list-item>
            <slx-action-list-item><em class="r_bold">Bold</em>Confirmed Record</slx-action-list-item>
            <slx-action-list-item><em class="r_italic">italic</em>Edited Record</slx-action-list-item>
            <slx-action-list-item><em class="r_bold_italic">Bold+<br />Italic</em>Edited and Confirmed Record</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="30">
      <div class="am-toolbar__control">
        <slx-columns-config-button [colGroupKey]="columnsStateName"
                                   [blueStyleConfig]="{ btnClass: 'slx-button slx-only-icon slx-toolbar', iconClass: 'fa fa-cog' }"
                                   title="Columns settings"></slx-columns-config-button>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="100">
      <span class="am-toolbar__control" [ngClass]="{ 'collapsed': isCollapsed }">
        <slx-multiselect class="am-toolbar__filter"
                         [options]="employeeFilterRecords"
                         [(ngModel)]="filter"
                         (ngModelChange)="onChangeFilter($event)"
                         placeholder="Employee filter"
                         name="filter"></slx-multiselect>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="90">
      <span class="am-toolbar__control" *ngIf="!isCollapsed">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onClickExport(false)" *ngIf="actions?.exportToExcel">Export to Excel</slx-action-list-item>
            <slx-action-list-item [disabled]="true" [title]="closeOutTitle">Close Out ACA Year</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </span>
    </ng-template>
  </slx-toolbar>
</div>

<kendo-window class="quick-edit-window"
  *ngIf="isQuickEditActive"
  (dragEnd)="onQuickWindowDragEnd()"
  [resizable]="false"
  [(top)]="quickEditWindowTop"
  [(left)]="quickEditWindowLeft"
  [width]="quickEditWidth"
  [slxKendoWindowPositionRight]="300"
  (close)="closeQuickEdit()"
>
  <kendo-window-titlebar>
    <div class="quick-edit-header-icon-container">
      <i class="far fa-calendar-plus main-icon" aria-hidden="true"></i>
      <i class="far fa-hand-point-up additional-icon" aria-hidden="true"></i>
    </div>
    <a (click)="openQuickEditTab()" [class.tab-selected]="!isEditResetTabActive"  [class.tab]="isEditResetTabActive" >Quick Edit Panel</a> <span class='seperator'>|</span> <a (click)="openQuickEditResetTab()" [class.tab-selected]="isEditResetTabActive" [class.tab]="!isEditResetTabActive" >Reset Panel</a>
    <button class="quick-edit-close-button" (click)="closeQuickEdit()">
        <i class="fas fa-times-circle" aria-hidden="true"></i>
    </button>
  </kendo-window-titlebar>
 <slx-aca-1095c-quick-edit *ngIf="!isEditResetTabActive" [popupInitData]="quickEditInitData"  [reasonOptions]="acaReasons" [coverageOptions]="acaOfferOfCoverage" [harborOptions]="acaSafeHarbors"></slx-aca-1095c-quick-edit>
 <slx-aca-1095c-quick-edit-reset *ngIf="isEditResetTabActive"></slx-aca-1095c-quick-edit-reset>
</kendo-window>

<kendo-window class="quick-confirm-window"
  *ngIf="isQuickConfirmActive"
  (dragEnd)="onQuickWindowDragEnd()"
  [resizable]="false"
  [(top)]="quickEditWindowTop"
  [(left)]="quickEditWindowLeft"
  [width]="quickEditWidth"
  [slxKendoWindowPositionRight]="300"
  (close)="closeQuickConfirm()"
>
  <kendo-window-titlebar>
    <div class="quick-edit-header-icon-container">
      <i class="far fa-calendar-plus main-icon" aria-hidden="true"></i>
      <i class="far fa-hand-point-up additional-icon" aria-hidden="true"></i>
    </div>
        <a (click)="openQuickConfirmTab()" [class.tab-selected]="!isConfirmResetTabActive" [class.tab]="isConfirmResetTabActive">Quick Confirm Panel</a> <span class='seperator'>|</span> <a (click)="openQuickConfirmResetTab()" [class.tab-selected]="isConfirmResetTabActive" [class.tab]="isConfirmResetTabActive">Reset Panel</a>
    <button class="quick-edit-close-button" (click)="closeQuickConfirm()">
        <i class="fas fa-times-circle" aria-hidden="true"></i>
    </button>
  </kendo-window-titlebar>
 <slx-aca-1095c-quick-confirm-reset  *ngIf="isConfirmResetTabActive"></slx-aca-1095c-quick-confirm-reset>
 <slx-aca-1095c-quick-confirm  *ngIf="!isConfirmResetTabActive"></slx-aca-1095c-quick-confirm>
</kendo-window>
