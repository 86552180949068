import * as tslib_1 from "tslib";
import { OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { unsubscribe } from '../../../core/decorators/index';
import { SidebarActions } from '../../actions/index';
import { ApplicationService } from '../../services/index';
import { appConfig } from '../../../app.config';
import { ChangeManagementService } from '../../../common/services/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { LeftSidebarManagementService } from '../../services/index';
import { NotificationType } from '../../models/notification-type.enum';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(sidebarActions, applicationService, route, changeManagementService, leftSidebarService, renderer) {
        this.sidebarActions = sidebarActions;
        this.applicationService = applicationService;
        this.route = route;
        this.changeManagementService = changeManagementService;
        this.leftSidebarService = leftSidebarService;
        this.renderer = renderer;
        this.notificationTypes = NotificationType;
        this.state = {
            isLeftSidebarOpen: false,
            isRightSidebarOpen: false
        };
        this.appConfig = appConfig;
    }
    Object.defineProperty(HeaderComponent.prototype, "isMobile", {
        get: function () {
            return (screen.width <= appConfig.mobileMaxWidth);
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        scheduleMicrotask(function () {
            _this.isLeftSidebarOpenSubscripion = _this.isLeftSidebarOpen.subscribe(function (value) { return _this.state.isLeftSidebarOpen = value; });
            _this.isRightSidebarOpenSubscripion = _this.isRightSidebarOpen.subscribe(function (value) { return _this.state.isRightSidebarOpen = value; });
            _this.routeSubscripion = _this.route.url
                .combineLatest(_this.route.queryParams)
                .subscribe(function (_a) {
                var urslSegments = _a[0], params = _a[1];
                _this.actualLeftSidebarState = !!params['hideLeftSideBar'];
                _this.keepOrgLevelBreadcrmb = !!params['keepOrgLevelBreadcrmb'];
                if (!_this.leftSidebarService.isAlwaysShowMode) {
                    _this.updateLeftSideBarState(_this.actualLeftSidebarState);
                }
            });
            _this.leftSidebarSubscripion = _this.leftSidebarService.leftSidebarMode$.subscribe(function (isAlwaysShowMode) {
                _this.updateLeftSideBarState(isAlwaysShowMode ? false : _this.actualLeftSidebarState);
            });
            if (_this.isMobile) {
                _this.sidebarActions.setLeftSidebar(false);
                _this.sidebarActions.setRightSidebar(false);
                _this.state.isRightSidebarOpen = false;
            }
        });
    };
    HeaderComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(HeaderComponent.prototype, "isShowSecondLine", {
        get: function () {
            return _.some(this.route.parent.children, function (route) {
                var showSecondLine = _.get(route, 'snapshot.data.showSecondLine');
                return _.isBoolean(showSecondLine) && showSecondLine === true && route.outlet === 'primary';
            });
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.updateLeftSideBarState = function (isHidden) {
        this.isHiddenLeftSidebar = isHidden;
        this.isHiddenOrgLevelBreadcrumb = !this.keepOrgLevelBreadcrmb && this.isHiddenLeftSidebar;
        this.leftSidebarService.updateState(this.isHiddenLeftSidebar, this.isMobile);
    };
    HeaderComponent.prototype.toggleRightSidebar = function () {
        this.sidebarActions.setRightSidebar(!this.state.isRightSidebarOpen);
    };
    HeaderComponent.prototype.toggleLeftSidebar = function () {
        this.sidebarActions.setLeftSidebar(!this.state.isLeftSidebarOpen);
    };
    tslib_1.__decorate([
        select(['sidebar', 'isRightSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], HeaderComponent.prototype, "isRightSidebarOpen", void 0);
    tslib_1.__decorate([
        select(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], HeaderComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderComponent.prototype, "isLeftSidebarOpenSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderComponent.prototype, "isRightSidebarOpenSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderComponent.prototype, "appSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderComponent.prototype, "leftSidebarSubscripion", void 0);
    return HeaderComponent;
}());
export { HeaderComponent };
