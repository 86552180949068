/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-plan-types.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./benefit-type-section/benefit-plan-type.component.ngfactory";
import * as i3 from "./benefit-type-section/benefit-plan-type.component";
import * as i4 from "../../../services/benefit-console/benefit-list-management.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../services/benefit-console/benefit-console-common.service";
import * as i7 from "@angular/common";
import * as i8 from "./benefit-plan-types.component";
var styles_BenefitPlanTypesComponent = [i0.styles];
var RenderType_BenefitPlanTypesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitPlanTypesComponent, data: {} });
export { RenderType_BenefitPlanTypesComponent as RenderType_BenefitPlanTypesComponent };
function View_BenefitPlanTypesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "incident-report-item injury-details plantype-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-benefit-plan-type", [], null, null, null, i2.View_BenefitPlanTypeComponent_0, i2.RenderType_BenefitPlanTypeComponent)), i1.ɵdid(2, 245760, null, 0, i3.BenefitPlanTypeComponent, [i4.BenefitListManagementService, i5.ActivatedRoute, i5.Router, i6.BenefitConsoleCommonService], { title: [0, "title"], icon: [1, "icon"], groupId: [2, "groupId"], index: [3, "index"], creatPlanAccess: [4, "creatPlanAccess"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.getIcon(_v.context.$implicit.type), ""); var currVal_2 = _v.context.$implicit.id; var currVal_3 = _v.context.index; var currVal_4 = _co.creatPlanAccess; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_BenefitPlanTypesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitPlanTypesComponent_1)), i1.ɵdid(1, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.planTypes; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BenefitPlanTypesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "benefit-plan-types", [], null, null, null, View_BenefitPlanTypesComponent_0, RenderType_BenefitPlanTypesComponent)), i1.ɵdid(1, 245760, null, 0, i8.BenefitPlanTypesComponent, [i4.BenefitListManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BenefitPlanTypesComponentNgFactory = i1.ɵccf("benefit-plan-types", i8.BenefitPlanTypesComponent, View_BenefitPlanTypesComponent_Host_0, {}, {}, []);
export { BenefitPlanTypesComponentNgFactory as BenefitPlanTypesComponentNgFactory };
