import { IRecipientInfo, RecipientInfo } from '../../models/index';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Assert } from '../../../../framework/index';
import {
  EmployeeCallLog,
  IEmployeeCallLogRecord,
  EmployeeCallLogRecord,
} from '../../models/index';
import { EmployeeDefinitionsMapService } from '../../../../organization/services/index';

@Injectable()
export class EmployeeMessagesMapService {
  public mapRecipientsInfoDto(recipientInfo: RecipientInfo[]): IRecipientInfo[] {
     return _.map(recipientInfo, recipient => this.mapRecipientInfoDto(recipient));
  }

  public mapRecipientInfoDto(recipientInfo: RecipientInfo): IRecipientInfo {
    let recipientInfoDto: IRecipientInfo = {
      recipientId: recipientInfo.recipientId,
      recipientType: recipientInfo.recipientType.toString()
    };
    return recipientInfoDto;
  }
}

