<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [sort]="gridState.state.sort"
  [pageSize]="pageSize"
  [pageable]="true"
  [skip]="gridState.state.skip"
  (pageChange)="pageChanged($event)"
  (sortChange)="sortChange($event)"
  (filterChange)="filterChange($event)"
  (excelExport)="excelExport($event)"
>
  <kendo-grid-column title="Date/Time" locked="false" field="auditDateTime" [filterable]="false" width="150">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Date/Time</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.auditDateTime | amDateFormat: appConfig.dateTimeFormatUS}}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Modified by" field="userLoginId" locked="false" width="130">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Modified by</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.userLoginId}}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="User Modified" field="entityDescription" locked="false" width="130">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>User Modified</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.entityDescription}}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Area" field="area" locked="false" width="200" class="slx-break-all-cell">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Area</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.area}}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Action" field="action" locked="false" width="300" class="slx-break-all-cell">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Action</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="audit-trail">
        <div *ngIf="dataItem.actionType">{{dataItem.actionType}}.</div><span *ngIf="dataItem.fieldName">{{dataItem.fieldName | slxAuditTrail : ''}}</span><span *ngIf="dataItem.valueBefore && dataItem.valueAfter"> changed from {{dataItem.valueBefore | slxAuditTrail}} to {{dataItem.valueAfter | slxAuditTrail}}</span>
      </div>
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Field Name" field="fieldName" locked="false" width="150" class="slx-break-all-cell">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Field Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.fieldName}}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Value Before" field="valueBefore" locked="false" width="250" class="slx-break-all-cell">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Value Before</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div  class="audit-trail">
        {{dataItem.valueBefore | slxAuditTrail : ''}}
      </div>
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Value After" field="valueAfter" locked="false" width="250" class="slx-break-all-cell">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Value After</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div  class="audit-trail">
        {{dataItem.valueAfter | slxAuditTrail : ''}}
      </div>
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Applicable/Effective Date" locked="false" filter="date" field="effectiveDate" filter="date" width="200">
    <ng-template kendoGridHeaderTemplate>
      <span [slxKendoGridHeaderTooltip]>Applicable/Effective Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.effectiveDate | amDateFormat: appConfig.dateFormat}}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel fileName="AuditTrail.xlsx" [fetchData]="getGridData()"></kendo-grid-excel>
  <kendo-grid-pdf fileName="AuditTrail.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>
</kendo-grid>
