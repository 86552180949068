import { Component, Input, OnChanges, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { dateTimeUtils } from '../../../../../common/utils/index';

@Component({
  selector: 'slx-benefit-details-eligibility-statement-date-values',
  templateUrl: './benefit-details-eligibility-statement-date-values.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class BenefitDetailsEligibilityStatementDateValuesComponent implements OnChanges {
  @Input()
  uniqueComponentId: number;

  @Input()
  value: string | string[];

  @Input()
  isMinMaxValue: boolean;

  @Output()
  valueChanged: EventEmitter<string | string[]>;

  startDate: Date;
  endDate: Date;
  innerValue: Date;
  minLimitForMaxDate: Date;

  constructor() {
    this.valueChanged = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<
      BenefitDetailsEligibilityStatementDateValuesComponent,
      SimpleChange
    >;

    const valueChange = componentChanges.value;
    if (!valueChange) {
      return;
    }
    const currentValue = valueChange.currentValue as string | string[];
    const previousValue = valueChange.previousValue as string | string[];

    if (_.isEqual(currentValue, previousValue)) {
      return;
    }
    if (!currentValue) {
      this.innerValue = null;
      this.startDate = null;
      this.endDate = null;
      this.minLimitForMaxDate = null;
      return;
    }

    if (!this.isMinMaxValue) {
      if (typeof currentValue === 'string') {
        this.innerValue = dateTimeUtils.convertFromDtoDateTimeString(currentValue);
      }
      this.startDate = null;
      this.endDate = null;
      this.minLimitForMaxDate = null;
      return;
    }

    const parsedValues = currentValue;
    if (parsedValues && parsedValues.length > 1) {
      this.startDate = dateTimeUtils.convertFromDtoDateTimeString(parsedValues[0]);
      this.minLimitForMaxDate = this.startDate;

      const endDate = dateTimeUtils.convertFromDtoDateTimeString(parsedValues[1]);
      this.endDate = this.startDate <= endDate ? endDate : null;

      this.innerValue = null;
    }
  }

  onValueChanged() {
    if (!this.isMinMaxValue) {
      const value = this.innerValue ? dateTimeUtils.convertToDtoDateTimeString(this.innerValue) : '';
      this.valueChanged.next(value);
      return;
    }

    const startDateString = this.startDate ? dateTimeUtils.convertToDtoDateTimeString(this.startDate) : '';
    const endDateString = this.endDate ? dateTimeUtils.convertToDtoDateTimeString(this.endDate) : '';
    const value = [startDateString, endDateString];
    this.valueChanged.next(value);
  }
}
