import { isCompositeFilterDescriptor, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import * as moment from 'moment';
export type filterValue = { filters: FilterDescriptor[], value: any, newOperator: string | Function, isUnknown?: boolean };
export type filterCalculated = { filter: CompositeFilterDescriptor, newOperator: string | Function };
export class KendoFilterHelper {

  public static createValuedFilters(field: string, operator: string | Function, value: any): filterCalculated {
    let filters: FilterDescriptor[] = [];
    let descriptor: CompositeFilterDescriptor = { logic: 'and', filters: [] };
    let newOperator = operator;
    switch (operator) {
      case 'slxFilterBetween1Day':
        filters.push({ field: field, operator: 'gte', value: moment(value).add(-1, 'day').toDate() });
        filters.push({ field: field, operator: 'lte', value: moment(value).add(1, 'day').toDate() });
        break;
      case 'slxFilterBetween1Week':
        filters.push({ field: field, operator: 'gte', value: moment(value).add(-1, 'week').toDate() });
        filters.push({ field: field, operator: 'lte', value: moment(value).add(1, 'week').toDate() });
        break;
      case 'slxFilterBetween1Month':
        filters.push({ field: field, operator: 'gte', value: moment(value).add(-1, 'month').toDate() });
        filters.push({ field: field, operator: 'lte', value: moment(value).add(1, 'month').toDate() });
        break;
      case 'slxFilterBetween3Month':
        filters.push({ field: field, operator: 'gte', value: moment(value).add(-3, 'month').toDate() });
        filters.push({ field: field, operator: 'lte', value: moment(value).add(3, 'month').toDate() });
        break;
      case 'slxFilterBetween1Year':
        filters.push({ field: field, operator: 'gte', value: moment(value).add(-1, 'year').toDate() });
        filters.push({ field: field, operator: 'lte', value: moment(value).add(1, 'year').toDate() });
        break;
      case 'slxFilterDate':
        filters.push({ field: field, operator: 'gte', value: moment(value).startOf('day').toDate() });
        filters.push({ field: field, operator: 'lte', value: moment(value).endOf('day').toDate() });
        break;
      case 'slxFilterNumber1':
        filters.push({ field: field, operator: 'gte', value: _.floor(value, 1) });
        filters.push({ field: field, operator: 'lt', value: _.ceil(_.floor(value, 1) + 0.01, 1) });
        break;
      case 'slxFilterNumber2':
        filters.push({ field: field, operator: 'gte', value: _.floor(value, 2) });
        filters.push({ field: field, operator: 'lt', value: _.ceil(_.floor(value, 2) + 0.001, 2) });
        break;
      case 'slxFilterBetween1Hour':
        filters.push({ field: field, operator: 'gte', value: value - 3600 });
        filters.push({ field: field, operator: 'lte', value: value + 3600 });
        break;
      case 'slxEqualsArray':
        if (value && value.length > 0) {
          _.forEach(value, (item: any) => {
            filters.push({ field: field, operator: 'eq', value: item });
          });
          descriptor.logic = 'or';
        } else {
          return null;
        }
        break;
      default:
        let res: filterValue = KendoFilterHelper.createNonValuedFilters(field, operator, value, true);
        if (!res.isUnknown) {
          filters.push(...res.filters);
          newOperator = res.newOperator;
          break;
        }
        filters.push({ field: field, operator: operator, value: value });
        break;
    }
    descriptor.filters = filters;
    return { filter: descriptor, newOperator: newOperator };
  }
  public static createNonValuedFilters(field: string, operator: string | Function, value?: any, valueChanged?: boolean): filterValue {
    let res: filterValue = { filters: [], value: null, newOperator: operator };
    let refDay: moment.Moment;
    let endRefDay: moment.Moment;
    let currentDay = moment().startOf('day');
    let endCurrentDay = moment().endOf('day');

    if (value && _.isDate(value)) {
      refDay = moment(value).startOf('day');
      endRefDay = moment(value).endOf('day');
    } else {
      refDay = moment().startOf('day');
      endRefDay = moment().endOf('day');
    }

    if (valueChanged) {
      switch (operator) {
        case 'slxFilterYesterday':
        case 'slxFilterToday':
        case 'slxFilterTomorrow':
          res.value = value;
          res.filters.push({ field: field, operator: 'gte', value: moment(value).startOf('day').toDate() });
          res.filters.push({ field: field, operator: 'lte', value: moment(value).endOf('day').toDate() });
          res.newOperator = 'slxFilterDate';
          return res;
      }
    }

    switch (operator) {
      case 'slxFilterYesterday':
        res.value = currentDay.clone().add(-1, 'day').toDate();
        res.filters.push({ field: field, operator: 'gte', value: currentDay.clone().add(-1, 'day').toDate() });
        res.filters.push({ field: field, operator: 'lte', value: currentDay.clone().add(-1, 's').toDate() });
        break;
      case 'slxFilterToday':
        res.value = currentDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: currentDay.clone().toDate() });
        res.filters.push({ field: field, operator: 'lte', value: endCurrentDay.clone().toDate() });

        break;
      case 'slxFilterTomorrow':
        res.value = currentDay.clone().add(1, 'day').toDate();
        res.filters.push({ field: field, operator: 'gte', value: currentDay.clone().add(1, 'day').toDate() });
        res.filters.push({ field: field, operator: 'lte', value: endCurrentDay.clone().add(1, 'day').toDate() });

        break;
      case 'slxFilterLast7Days':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: refDay.clone().add(-7, 'day').toDate() });
        res.filters.push({ field: field, operator: 'lte', value: endRefDay.toDate() });

        break;
      case 'slxFilterLast30Days':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: refDay.clone().add(-30, 'day').toDate() });
        res.filters.push({ field: field, operator: 'lte', value: endRefDay.toDate() });
        break;
      case 'slxFilterLast90Days':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: refDay.clone().add(-90, 'day').toDate() });
        res.filters.push({ field: field, operator: 'lte', value: endRefDay.toDate() });
        break;
      case 'slxFilterLast120Days':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: refDay.clone().add(-120, 'day').toDate() });
        res.filters.push({ field: field, operator: 'lte', value: endRefDay.toDate() });
        break;
      case 'slxFilterNext7Days':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: refDay.toDate() });
        res.filters.push({ field: field, operator: 'lte', value: refDay.clone().add(7, 'day').toDate() });

        break;
      case 'slxFilterNext30Days':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: refDay.toDate() });
        res.filters.push({ field: field, operator: 'lte', value: refDay.clone().add(30, 'day').toDate() });
        break;
      case 'slxFilterNext90Days':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: refDay.toDate() });
        res.filters.push({ field: field, operator: 'lte', value: refDay.clone().add(90, 'day').toDate() });
        break;
      case 'slxFilterNext120Days':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: refDay.toDate() });
        res.filters.push({ field: field, operator: 'lte', value: refDay.clone().add(120, 'day').toDate() });
        break;
      case 'slxFilterAfterDay':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gt', value: res.value });
        break;
      case 'slxFilterAfterOrEqualDay':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'gte', value: res.value });
        break;
      case 'slxFilterBeforeDay':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'lt', value: res.value });
        break;
      case 'slxFilterBeforeOrEqualDay':
        res.value = refDay.toDate();
        res.filters.push({ field: field, operator: 'lte', value: res.value });
        break;
      case 'isnull':
        res.value = null;
        res.filters.push({ field: field, operator: 'isnull', value: null });
        break;
      case 'isnotnull':
        res.value = null;
        res.filters.push({ field: field, operator: 'isnotnull', value: null });
        break;
        case 'isempty':
        res.value = null;
        res.filters.push({ field: field, operator: 'isempty', value: null });
        break;
      case 'isnotempty':
        res.value = null;
        res.filters.push({ field: field, operator: 'isnotempty', value: null });
        break;
        default:
        res.isUnknown = true;
        res.value = null;
        res.filters.push({ field: field, operator: 'lte', value: null });
        break;
    }
    return res;
  }
}
