/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-replacement-open.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shift-replacement-selector/shift-replacement-selector.component.ngfactory";
import * as i3 from "../../../../common/services/state-management/state-management.service";
import * as i4 from "../../../../common/services/component-state/component-state-storage.service";
import * as i5 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i6 from "../shift-replacement-selector/shift-replacement-selector.component";
import * as i7 from "../../../services/shift-replacement/shift-replacement-api.service";
import * as i8 from "../../../../common/services/modal/modal.service";
import * as i9 from "../../../../core/services/user-activity/user-activity.service";
import * as i10 from "../../../../core/services/token-validity/token-validity.service";
import * as i11 from "../../../services/shift-replacement/shift-replacement-management.service";
import * as i12 from "../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i13 from "../../../../organization/services/schedule/schedule-cycle-helper.service";
import * as i14 from "./shift-replacement-open.component";
import * as i15 from "../../../../common/models/dialog-options";
import * as i16 from "../../../models/shift-replacement/shift-replacement-request";
var styles_ShiftReplacementOpenComponent = [i0.styles];
var RenderType_ShiftReplacementOpenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftReplacementOpenComponent, data: {} });
export { RenderType_ShiftReplacementOpenComponent as RenderType_ShiftReplacementOpenComponent };
export function View_ShiftReplacementOpenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "slx-shift-replacement-selector", [], null, null, null, i2.View_ShiftReplacementSelectorComponent_0, i2.RenderType_ShiftReplacementSelectorComponent)), i1.ɵprd(512, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵdid(4, 245760, null, 0, i6.ShiftReplacementSelectorComponent, [i7.ShiftReplacementApiService, i8.ModalService, i9.UserActivityService, i3.StateManagementService, i10.TokenValidityService, i11.ShiftReplacementManagementService, i12.NotificationsService, i13.ScheduleCycleHelperService], { settings: [0, "settings"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !(((_co.selectedEmployees == null) ? null : _co.selectedEmployees.length) > 0); _ck(_v, 8, 0, currVal_1); }); }
export function View_ShiftReplacementOpenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-shift-replacement-open", [], null, null, null, View_ShiftReplacementOpenComponent_0, RenderType_ShiftReplacementOpenComponent)), i1.ɵdid(1, 114688, null, 0, i14.ShiftReplacementOpenComponent, [i15.DialogOptions, i8.ModalService, i16.ShiftReplacementRequest, i11.ShiftReplacementManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftReplacementOpenComponentNgFactory = i1.ɵccf("slx-shift-replacement-open", i14.ShiftReplacementOpenComponent, View_ShiftReplacementOpenComponent_Host_0, {}, {}, []);
export { ShiftReplacementOpenComponentNgFactory as ShiftReplacementOpenComponentNgFactory };
