 <div class="geolocation-form">
  <div class="geolocation-form__form">
    <form novalidate #geoForm="ngForm">
      <div class="geolocation-form__multi-controls">
        <p class="geolocation-form__f-control geolocation-form__wide-control">
          <label class="geolocation-form__c-label">Description *</label>
          <input class="geolocation-form__i-field"
            maxlength="255"
            [(ngModel)]="editingEntity.description"
            name="descriptionField"
            autocomplete="off"
            type="text"
            required
          />
        </p>
        <p class="geolocation-form__f-control">
          <slx-switcher
            [(ngModel)]="editingEntity.isActive"
            label="Active"
            labelOn="Yes"
            labelOff="No"
            name="activeField"
          ></slx-switcher>
        </p>
      </div>

      <p class="geolocation-form__f-control">
        <label class="geolocation-form__c-label">Org Level</label>
        <input class="geolocation-form__i-field"
          [(ngModel)]="editingEntity.orgLevelName"
          name="orgLevelField"
          autocomplete="off"
          type="text"
          readonly
        />
      </p>

      <p class="geolocation-form__f-control">
        <label class="geolocation-form__c-label">Address *</label>
        <input class="geolocation-form__i-field"
          [(ngModel)]="editingEntity.address"
          [readonly]="isDisabled"
          name="addressField"
          autocomplete="off"
          type="text"
          required
        />
      </p>

      <div class="geolocation-form__multi-controls">
        <p class="geolocation-form__f-control geolocation-form__wide-control">
          <label class="geolocation-form__c-label">City *</label>
          <input class="geolocation-form__i-field"
            [(ngModel)]="editingEntity.city"
            [readonly]="isDisabled"
            name="cityField"
            autocomplete="off"
            type="text"
            required
          />
        </p>
        <p class="geolocation-form__f-control padding-r">
          <label class="geolocation-form__c-label">State *</label>
          <slx-dropdown-list
            class="slx-wide slx-short"
            [(ngModel)]="currentState"
            (ngModelChange)="onChangedState($event)"
            [options]="statesLookup"
            [required]="true"
            [readonly]="isDisabled"
            name="statesField"
          ></slx-dropdown-list>
        </p>
        <p class="geolocation-form__f-control">
          <label class="geolocation-form__c-label">Zip *</label>
          <input class="geolocation-form__i-field"
            [(ngModel)]="editingEntity.zip"
            [readonly]="isDisabled"
            maxlength="10"
            name="zipField"
            autocomplete="off"
            type="text"
            required
          />
        </p>
      </div>

      <div class="geolocation-form__multi-controls">
        <p class="geolocation-form__f-control geolocation-form__wide-control">
          <label class="geolocation-form__c-label">Distance</label>
          <input class="geolocation-form__i-field"
            [(ngModel)]="currentDistance"
            name="distanceField"
            type="text"
            readonly
          />
        </p>
        <p class="geolocation-form__f-control">
          <label class="geolocation-form__c-label">Metric</label>
          <slx-dropdown-list
            class="slx-wide slx-short"
            [(ngModel)]="currentMetric"
            (ngModelChange)="onChangedMetric($event)"
            [options]="metricsList"
            [readonly]="isDisabled"
            name="metricField"
          ></slx-dropdown-list>
          </p>
      </div>
      
      <p class="geolocation-form__f-control">
        <slx-switcher
          [(ngModel)]="editingEntity.isAllowPunchWhenLocationServiceDisabled"
          label="Allow Punch When Location Service Disabled?"
          labelOn="Yes"
          labelOff="No"
          name="locationServiceField"
        ></slx-switcher>
      </p>

      <p class="geolocation-form__f-control text-center">
        <button class="slx-button slx-with-icon" type="button" (click)="onSearchPlaceOnMap()" [disabled]="!geoForm.valid || isDisabled">
          <i aria-hidden="true" class="fas fa-map slx-button__icon"></i>
          <span class="slx-button__text">Update map</span>
        </button>
      </p>

      <div class="geolocation-form__override" *ngIf="!editingEntity.isOverridden">
        <button class="slx-button slx-with-icon" type="button" (click)="onClickOverride()" [disabled]="!canOverride">
          <i aria-hidden="true" class="fas fa-map-marker-edit slx-button__icon"></i>
          <span class="slx-button__text">Override Map Location</span>
        </button>
        <p class="geolocation-form__text-info">
          <strong>Sometimes maps can be slightly off from what we expect when we enter an address.
            If you would like to change the location of the map circle click the Override Map Location to place new location on the map.</strong>
        </p>
      </div>

      <div class="geolocation-form__o-cancel" *ngIf="editingEntity.isOverridden">
        <header class="geolocation-form__o-header">
          <i aria-hidden="true" class="fas fa-map-marker-edit geolocation-form__o-icon"></i>
          <span class="geolocation-form__o-title">Override Map Location</span>
        </header>
        <div class="geolocation-form__o-body">
          <p class="geolocation-form__o-text">
            <strong>Drag the location circle over the center where you would like a Geolocation fence.</strong>
          </p>
          <p class="geolocation-form__o-btn-box">
            <button class="slx-button slx-with-icon" type="button" (click)="onClickDeleteOverride()">
              <i aria-hidden="true" class="fas fa-trash-alt slx-button__icon"></i>
              <span class="slx-button__text">Delete Override</span>
            </button>
          </p>
        </div>
      </div>
    </form>
  </div>
  <div class="geolocation-form__map">
    <slx-geolocation-map
      [viewModeSettings]="viewModeSettings"
      [activeModeSettings]="activeModeSettings"
      [overrideBy]="override"
      (radiusChanged)="onRadiusChanged($event)"
      (centerChange)="onCenterChange($event)"
      (addressNotFound)="onNotFound($event)"
    ></slx-geolocation-map>
  </div>
 </div>
