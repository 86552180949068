<form #form="ngForm" novalidate>
    <div class="summary-header">
        <div class="title-column">
            <span class="component-title">Payroll Export Summary Report</span>
        </div>
        <div class="col">
            <div class="form-item">
                <div class="form-label">Corporation:</div>
                <div class="form-value">{{summary?.corporation?.name}}</div>
            </div>
            <div class="form-item">
                <div class="form-label">Center:</div>
                <div class="form-value">{{summary?.organization?.name}}</div>
            </div>
            <div class="form-item">
                <div class="form-label">Payroll Cycle:</div>
                <div class="form-value">{{summary?.payrollCycle | slxPeriod }}</div>
            </div>
            <div class="form-item">
                <div class="form-label">Export requested by:</div>
                <div class="form-value">{{summary?.exportRequestedBy}}</div>
            </div>
            <div class="form-item">
                <div class="form-label">Released to Payroll by:</div>
                <div class="form-value">{{summary?.releasedToPayrollBy}}</div>
            </div>
        </div>
        <div class="col number-column">
            <div class="form-item">
                <div class="form-label">Number of employees:</div>
                <div class="form-value">{{summary?.employeesCount}}</div>
            </div>
            <div class="form-item">
                <div class="form-label">Number of Manually Modified Timecards:</div>
                <div class="form-value">{{summary?.manuallyModifiedTimecardCount}}</div>
            </div>
            <div class="form-item">
                <div class="form-label">Number of Approval Required Timecards:</div>
                <div class="form-value">{{summary?.approvalRequiredTimecardCount}}</div>
            </div>
            <div class="form-item">
                <div class="form-label">Pending Approval:</div>
                <div class="form-value">{{summary?.pendingApprovalCount}}</div>
            </div>
            <div class="form-item">
                <div class="form-label">Terminated Employees in Current Payroll:</div>
                <div class="form-value">{{summary?.terminatedEmployeesCount}}</div>
            </div>
        </div>
        <div class="col">
            <div class="diff-percent">
                <slx-input-decorator>
                    <slx-kendo-number slx-input
                      [(ngModel)]="management.diffPercent"
                      [decimals]="2"
                      [step]="1"
                      placeholder="Difference %"
                      name="diffPercent"
                    ></slx-kendo-number>
                </slx-input-decorator>
            </div>
        </div>
    </div>
</form>
