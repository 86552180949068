<kendo-grid [ngClass]="{ 'min-grid-height': requireMinHeight }"
[data]="gridState.view"
[sortable]="{ mode: 'multiple' }"
[sort]="gridState.state.sort"
[filterable]="false"
[filter]="gridState.state.filter"
[groupable]="false"
[group]="gridState.state.group"
[pageable]="false"
[pageSize]="pageSize"
[skip]="gridState.state.skip"
(pageChange)="gridState.pageChange($event)"
(dataStateChange)="gridState.dataStateChange($event)"
(groupChange)="gridState.groupChange($event)"
scrollable="'scrollable'"
>
<!-- message?.senderName
message?.text
message?.timestamp | amDateFormat: appConfig.dateTimeFormat -->
<kendo-grid-column title="ShiftId" field="receiverId" width="70"  *ngIf="isPartnerShift">
  <ng-template kendoGridHeaderTemplate><span [slxKendoGridHeaderTooltip]>Shift Id</span></ng-template>
  <ng-template kendoCellTemplate let-dataItem>{{dataItem?.receiverId}}</ng-template>
  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column title="Agency" field="partnerAgencyName" width="70" *ngIf="isPartnerShift">
  <ng-template kendoGridHeaderTemplate><span [slxKendoGridHeaderTooltip]>Agency</span></ng-template>
  <ng-template kendoCellTemplate let-dataItem>{{dataItem?.agencyName}}</ng-template>
  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column title="Name" field="senderName" width="100">
  <ng-template kendoGridHeaderTemplate><span [slxKendoGridHeaderTooltip]>Name</span></ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.senderName}}  <span style="margin: 0 0 0 5px;" [posGroupIcon]="dataItem.isPositionGroupInd == 1 ? true : false"></span></ng-template>
  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column>

  <kendo-grid-column title="Response Date" field="timestamp" width="100">
  <ng-template kendoGridHeaderTemplate><span [slxKendoGridHeaderTooltip]>Response Date</span></ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.timestamp | amDateFormat: appConfig.dateTimeFormat}}</ng-template>
 <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column> 

<kendo-grid-column title="Response" field="text" width="70">
  <ng-template kendoGridHeaderTemplate><span [slxKendoGridHeaderTooltip]>Response</span></ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.text}}</ng-template>
  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column> 

<kendo-grid-column title="Status" field="stateName" width="50">
  <ng-template kendoGridHeaderTemplate><span [slxKendoGridHeaderTooltip]>Status</span></ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.stateName }}</ng-template>
  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column title="Actions"  [sortable]="false" [filterable]="false" width="100" class="overflow-visible-cell">
    <ng-template kendoGridHeaderTemplate><span [slxKendoGridHeaderTooltip]>Actions</span></ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <slx-actions-list>
          <slx-actions-button [popperContent]="popperContent" [popperPosition]="'auto'">
            Actions <span class="caret"></span>
          </slx-actions-button>
          <popper-content #popperContent>
            <slx-actions-list-item (click)="onApproveClicked(dataItem)" [disabled]="!(dataItem?.isNew || dataItem?.isRequested || dataItem?.isReaded)">
              <i class="fa fa-check" aria-hidden="true"></i>&nbsp;Approve
            </slx-actions-list-item>
            <slx-actions-list-item (click)="onDenyClicked(dataItem)" [disabled]="!(dataItem?.isNew || dataItem?.isRequested || dataItem?.isReaded)">
              <i class="fa fa-ban" aria-hidden="true"></i>&nbsp;Deny
            </slx-actions-list-item>
            <slx-actions-list-item [hidden]="true">
              <i class="fas fa-calendar-alt" aria-hidden="true"></i>&nbsp;Schedule Detail
            </slx-actions-list-item>
          </popper-content>
        </slx-actions-list>
      </ng-template>
</kendo-grid-column> 
</kendo-grid>
