import { IBenefitEligibilityRuleStatement, BenefitEligibilityRuleStatement } from './index';

export interface IBenefitEligibilityRule {
  id: number;
  name: string;
  description: string;
  type: string;
  rule: string;
  ruleV5: string;
  isDeleted: boolean;
  modifiedAt: string;
  modifiedBy: string;
  addedAt: string;
  addedBy: string;
  ruleStatements: IBenefitEligibilityRuleStatement[];
}

export enum BenefitEligibilityRuleStatus {
  NoChanges,
  Added,
  Updated,
  Deleted
}

export class BenefitEligibilityRule {
  public readonly id: number;
  public readonly name: string;
  public readonly description: string;
  public readonly type: string;
  public readonly rule: string;
  public readonly ruleV5: string;
  public readonly isDeleted: boolean;
  public readonly modifiedAt: Date;
  public readonly modifiedBy: string;
  public readonly addedAt: Date;
  public readonly addedBy: string;
  public readonly ruleStatements: BenefitEligibilityRuleStatement[];
  public status: BenefitEligibilityRuleStatus;

  constructor({
    id,
    name,
    description,
    type,
    rule,
    ruleV5,
    isDeleted,
    modifiedAt,
    modifiedBy,
    addedAt,
    addedBy,
    ruleStatements,
    status
  }: Partial<BenefitEligibilityRule>) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.type = type;
    this.rule = rule;
    this.ruleV5 = ruleV5;
    this.isDeleted = isDeleted;
    this.modifiedAt = modifiedAt;
    this.modifiedBy = modifiedBy;
    this.addedAt = addedAt;
    this.addedBy = addedBy;
    this.ruleStatements = ruleStatements;
    this.status = status || BenefitEligibilityRuleStatus.NoChanges;
  }
}
