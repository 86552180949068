import { Component, OnInit, ChangeDetectorRef, SimpleChanges, OnDestroy, Input, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';

import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';
import { ListActionsService, PositionsManagementService } from '../../../services/index';

import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';

import { PositionModel, AdditionalInfo } from '../../../models/index';

import { LookupApiService } from '../../../../organization/services/index';
import { Department, LicenseType, Lookup, LookupType, Organization, Position, PositionGroup } from '../../../../organization/models/lookup/index';
import { EssTemplateDefinition } from '../../../../app-modules/ess-templates/models';
import { ISelectableItemContainer, SelectableItemsProducer } from '../../../../common';
import { Assert } from '../../../../framework';
import { LookupService } from '../../../../../app/organization/services/index';
import { SessionService } from '../../../../../app/core/services/session/session.service';
import { AppServerConfig } from '../../../../../app/app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app/app-settings/services/app-settings-manage.service';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-position-editor',
  templateUrl: 'configure-position-editor.component.html',
  styleUrls: ['configure-position-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: CustomListActorBase,
      useExisting: forwardRef(() => ConfigurePositionEditorComponent)
    }
  ]
})
export class ConfigurePositionEditorComponent extends EditableListEditorComponent implements OnInit, OnDestroy {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  public isNextgenPayrollEnabled: boolean = false;

  @Input()
  public set item(value: PositionModel) {
    this.privateItem = value;
    if (this.m_currentOrgLevel) this.updatePositionsLookup();
  }
  
  @Input()
  public isAddPosition: boolean = false;
  @Input()
  public isSupervisorEnabled: boolean = false;
  
  public get maxRate(): number {
    let formValue: number = this.formGroup.get('maximumRate') ? this.formGroup.get('maximumRate').value : this.maxRateLimit;
    formValue = _.isNaN(formValue) || !formValue ? this.maxRateLimit : formValue;
    return this.privateItem ? Math.min(formValue, this.maxRateLimit) : this.maxRateLimit;
  }

  public get minRate(): number {
    let formValue: number = this.formGroup.get('minimumRate') ? this.formGroup.get('minimumRate').value : this.minRateLimit;
    formValue = _.isNaN(formValue) || !formValue ? this.minRateLimit : formValue;
    return this.privateItem ? Math.max(formValue, this.minRateLimit) : this.minRateLimit;
  }

  public readonly maxRateLimit: number = 9999999;
  public readonly minRateLimit: number = 0;
  
  public isPositionLookupProgress : boolean = false;
  public isDepartmentLookupProgress : boolean = false;
  public supervisorPositionLookup: Lookup;
  public supervisorDepartmentLookup: Lookup;
  public organizationLookup: Organization[];
  public departmentLookup: Department[];
  public positionLookup: Position[];
  private lookupService: LookupService;
  public supervisorDepartment: Department[];

  public get item(): PositionModel {
    return this.privateItem;
  }

  public get positionsFiltered(): Position[] {
    return this.m_positionsFiltered;
  }

  public get positionsGroupFiltered(): PositionGroup[] {
    return this.m_positionsGroupFiltered;
  }

  private m_positionsFiltered: Position[];
  private m_positionsGroupFiltered: PositionGroup[];

  public get currentOrgLevel(): OrgLevel {
    return this.m_currentOrgLevel;
  }

  public essTemplates: EssTemplateDefinition[];
  public positionGroup: PositionGroup;
  public canReplaceOptions: Position[];
  public selectedPositions: number[] = [];
  public positionGroupForm: FormGroup;
  private m_currentOrgLevel: OrgLevel;

  public essTemplatesEnabled: boolean;
  public isCanReplaceEnabled: boolean = false;
  public isPositionSelected: boolean = false;

  public unAssignedGroupName: string = 'Unassigned';
  public positionGroupName: string = 'positionGroup';
  public searchText: string = '';
  private privateItem: PositionModel;
  private hasAdditionalControls: boolean;
  public operator: string = 'OR';
  public isSystemLogin: boolean = true;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private mainFormSubscription: Subscription;
  private selectableItemsProducer: SelectableItemsProducer;
  public licenseTypeLookups: LicenseType[];
  public positionLicenseTypesSelection: ISelectableItemContainer<LicenseType>[] = [];
  public isPositionLicenseTypeSelection:boolean = false;

  constructor(private lookup: LookupApiService,
    selectableItemsProducer: SelectableItemsProducer,
    private positionsManagementService: PositionsManagementService,
    private formBuilder: FormBuilder,
    lookupService: LookupService,
    private sessionService: SessionService,
    private appSettingsManageService: AppSettingsManageService) {
    super();
    Assert.isNotNull(selectableItemsProducer, 'selectableItemsProducer');
     this.selectableItemsProducer = selectableItemsProducer;
    this.essTemplatesEnabled = this.positionsManagementService.essTemplatesEnabled;
    this.lookupService = lookupService;
  }

  public ngOnInit(): void {
    
    super.ngOnInit();
    this.getSettings();
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.m_currentOrgLevel = orgLevel;
      this.updatePositionsLookup();
      this.positionGroupChanged(this.item.positionGroup);

      if (_.isNull(this.item.supervisorDepartment)){
        this.loadDepartments(this.item.supervisorOrganization);
      }
      if (this.essTemplatesEnabled) {
        this.positionsManagementService.getEssTemplateDefinitions()
          .then((templates: EssTemplateDefinition[]) => {
            this.essTemplates = templates;
          });
      }
      this.lookup.getLicenseType(-1, this.m_currentOrgLevel.id)
      .then((licenseTypeLookup: LicenseType[]) => {
        this.licenseTypeLookups = licenseTypeLookup;
        this.positionLicenseTypesSelection = this.selectableItemsProducer.produceSelectable<LicenseType>(this.licenseTypeLookups);
        if(this.positionLicenseTypesSelection.length > 0){

        let posLTN = this.formGroup.get('positionLicenseTypeSelection') as FormGroup;


        for (let i = 0; i < this.positionLicenseTypesSelection.length; i++) {
          let ctrlName ="licenserest" + this.positionLicenseTypesSelection[i].item.licenseTypeID;

          posLTN.addControl(ctrlName,new FormControl(this.positionLicenseTypesSelection[i].item.licenseTypeID));

          if(this.item.positionLicenseTypes)
          {
            if (this.item.positionLicenseTypes.includes(this.positionLicenseTypesSelection[i].item.licenseTypeID)) {
              this.positionLicenseTypesSelection[i].selected= true;
            }
            else {
              this.positionLicenseTypesSelection[i].selected= false;
            }
         }
        }
        this.isPositionLicenseTypeSelection = true;

      }

      });
    });

    this.mainFormSubscription = this.formGroup.statusChanges.subscribe(() => {
      if (this.formGroup.dirty) {
        this.positionsManagementService.markAsDirty();
      }
    });

    this.formGroup.get(this.positionGroupName).valueChanges.subscribe(val => {
      this.positionGroupChanged(val);
    });

    this.lookup.getPositionGroups(this.m_currentOrgLevel.id, true).then((positionsGroupLookup: PositionGroup[]) => {
      this.m_positionsGroupFiltered = _.filter(positionsGroupLookup, (p: PositionGroup) => {
        return p.id !== this.item.id;
      });
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
    super.ngOnDestroy();
  }
  
  public addAdditionalInfo(e: MouseEvent): void {
    e.preventDefault();
    if (!this.item.additionalInfo) this.item.additionalInfo = new AdditionalInfo();
    this.addAdditionalControls(this.formGroup);
    this.updateAdditionalControls(this.formGroup);
  }

  public removeAdditionalInfo(e: MouseEvent): void {
    e.preventDefault();
    this.item.additionalInfo = null;
    this.removeAdditionalControls(this.formGroup);
  }

  public licenserestBuilder(): FormArray {
    const licenserests = this.positionLicenseTypesSelection.map(licenserest => {
      return this.formBuilder.control(licenserest.item.licenseTypeID);
    });

    return this.formBuilder.array(licenserests , Validators.required);
  }

  public removeValue(event: MouseEvent, property: string): void {

    if (property === this.positionGroupName) {
      this.formGroup.removeControl('replacesPositionsIds');
      this.selectedPositions = [];
      this.item[property] = this.privateItem.positionGroup;
    } else {
      this.item[property] = null;

    }
    event.preventDefault();
    this.formGroup.get(property).setValue(null);
  }

  public getSelectedPositionsArray() {
    this.selectedPositions = [];
    for (let i = 0; i < this.canReplaceOptions.length; i++) {
      this.selectedPositions.push(this.canReplaceOptions[i].id);
    }
    this.selectAllEnabled(true);
  }

  public positionsBuilder(): FormArray {
    const positions = this.canReplaceOptions.map(position => {
      return this.formBuilder.control(position.id)
    });

    return this.formBuilder.array(positions, Validators.required);
  }

  public positionGroupChanged(event): void {
    this.isCanReplaceEnabled = false;
    this.canReplaceOptions = [];
    this.selectedPositions = [];

    if (!_.isEmpty(event)) {
      this.canReplaceOptions = _.filter(this.m_positionsFiltered, (p: Position) => {
        return p.positionGroupId == event.id;
      });
      this.getSelectedPositionsArray();
    }
    this.formGroup.removeControl('replacesPositionsIds');

    if (this.canReplaceOptions.length > 0) {
      this.isCanReplaceEnabled = true;
      this.formGroup.addControl('replacesPositionsIds', this.positionsBuilder());
      this.isPositionSelected = true;

      if (_.isArray(this.item.replacesPositionsIds) && _.isNaN(this.item.id)) {
        this.allPositionSelction(false);
        this.selectedPositions = [];
      } else {

        if (this.item.replacesPositionsIds) {
          this.selectivePositions(event);
        }
        else {
          this.allPositionSelction(true);
          this.getSelectedPositionsArray();
        }
      }

      if (this.canReplaceOptions.length == this.selectedPositions.length) {
        this.selectAllEnabled(true);
      }
    } else {
      this.selectedPositions = [];
    }
  }



  public selectivePositions(event: any) {
    if ((this.item.replacesPositionsIds.length != this.selectedPositions.length) && (event.id == this.item.positionGroup.id)) {
      let selectedPositionGroups = <FormArray>this.formGroup.controls.replacesPositionsIds;
      let uncheckedPositions = this.compareArrays(this.formGroup.controls.replacesPositionsIds.value, this.item.replacesPositionsIds);
      this.selectAllEnabled(false);

      for (let i = 0; i < uncheckedPositions.length; i++) {
        let index = selectedPositionGroups.controls.findIndex(k => k.value == uncheckedPositions[i]);
        let positionIndex = this.selectedPositions.findIndex(pID => pID == uncheckedPositions[i]);
        this.selectedPositions.splice(positionIndex, 1);
        selectedPositionGroups.controls[index].setValue(false);
      }
    }
  }

  public selectAllEnabled(selectAllStatus: boolean) {
    this.formGroup.get('selectAll').setValue(selectAllStatus);
  }

  public allPositionSelction(selectionState: boolean) {
    if (this.formGroup.controls.replacesPositionsIds) {
      this.selectAllEnabled(selectionState);
      let selectedPositionGroups = <FormArray>this.formGroup.controls.replacesPositionsIds;
      for (let i = 0; i < selectedPositionGroups.controls.length; i++) {
        selectedPositionGroups.controls[i].setValue(selectionState);
      }
    }
  }

  public compareArrays(formControlPositions, itemPositions): number[] {
    let resultArray = formControlPositions;
    for (let i = 0; i < itemPositions.length; i++) {
      let index = formControlPositions.indexOf(itemPositions[i]);
      if (index > -1) {
        resultArray.splice(index, 1);
      }
    }
    return resultArray;
  }

  get selectedPositionList() {
    return this.formGroup.get('replacesPositionsIds') as FormArray;
  }

  public selectionChanged(isChecked, id) {
    if (isChecked) {
      this.selectedPositions.push(id);
      if (this.canReplaceOptions.length == this.selectedPositions.length) {
        this.selectAllEnabled(true);
      } else {
        this.selectAllEnabled(false);
      }
    } else {
      let index = this.selectedPositions.findIndex(k => k == id);
      this.selectedPositions.splice(index, 1);
      this.selectAllEnabled(false);
    }
  }

  public selectedAllCanReplace() {
    let selectedPositionGroups = <FormArray>this.formGroup.controls.replacesPositionsIds;
    if (this.formGroup.controls.selectAll.value) {
      this.getSelectedPositionsArray();
      for (let i = 0; i < selectedPositionGroups.controls.length; i++) {
        selectedPositionGroups.controls[i].setValue(true);
      }
    } else {
      this.selectedPositions = [];
      for (let i = 0; i < selectedPositionGroups.controls.length; i++) {
        selectedPositionGroups.controls[i].setValue(false);
      }
    }
  }

  protected updatePositionsLookup(): void {
    if (this.item) {
      this.lookup.getPositions(null, this.m_currentOrgLevel.id, null, true).then((positionsLookup: Position[]) => {
        this.m_positionsFiltered = _.filter(positionsLookup, (p: Position) => {
          return p.id !== this.item.id;
        });
        if (this.item.positionGroup) {
          if (this.item.positionGroup.name != this.unAssignedGroupName) {
            this.positionGroupChanged(this.item.positionGroup);
          }
        }
      });
    }
  }

  public selectionLicenseTypeChanged(isChecked, licenseType) {
    let index = this.item.positionLicenseTypes.findIndex(l => l == licenseType);

    if (isChecked) {
      if(index == -1)
      {
      this.item.positionLicenseTypes.push(licenseType);
      }
    } else {
      this.item.positionLicenseTypes.splice(index, 1);
    }
  }

  protected updateItem(): void {

    this.item.name = this.formGroup.get('position').value;
    this.item.clientDepartment = this.formGroup.get('clientDepartment').value;
    this.item.jobClass = this.formGroup.get('jobClass').value;
    this.item.businessUnit = this.formGroup.get('businessUnit').value;
    this.item.objectAccount = this.formGroup.get('objectAccount').value;
    this.item.groupedWith = this.formGroup.get('groupedWith').value;
    this.item.budgetedPosition = this.formGroup.get('budgetedPosition').value;
    this.item.pbjCmsPosition = this.formGroup.get('pbjCmsPosition').value;
    this.item.isDirectCare = this.formGroup.get('isDirectCare').value;
    this.item.positionGroup = this.formGroup.get('positionGroup').value;

    if (!this.item.positionGroup || !this.item.positionGroup.id) {
      this.item.positionGroup = null;
    }

    if (this.isPositionSelected) {
      this.item.replacesPositionsIds = this.selectedPositions;
    }

    if (this.essTemplatesEnabled) {
      this.item.essTemplate = this.formGroup.get('essTemplate').value;
    }

    if (this.hasAdditionalControls) {
      this.item.additionalInfo.eeocGroup = this.formGroup.get('eeocGroup').value;
      this.item.additionalInfo.eeocClass = this.formGroup.get('eeocClass').value;
      this.item.additionalInfo.fte = this.formGroup.get('fte').value;
      this.item.additionalInfo.maximumRate = this.formGroup.get('maximumRate').value;
      this.item.additionalInfo.minimumRate = this.formGroup.get('minimumRate').value;
      this.item.additionalInfo.occupationCode = this.formGroup.get('occupationCode').value;
      this.item.additionalInfo.payGrade = this.formGroup.get('payGrade').value;
      this.item.additionalInfo.payType = this.formGroup.get('payType').value;
      this.item.additionalInfo.perDiemRate = this.formGroup.get('perDiemRate').value;
      this.item.additionalInfo.workersCompCode = this.formGroup.get('workersCompCode').value;
    }

    if(this.isSupervisorEnabled){
    this.item.supervisorDepartment = this.formGroup.get('supervisorDepartment').value
    this.item.supervisorOrganization = this.formGroup.get('supervisorOrganization').value
    this.item.supervisorPosition = this.formGroup.get('supervisorPosition').value
  }
    this.item.positionLicenseTypes = this.formGroup.get('positionLicenseTypes').value;
    this.item.operator = this.operator;
  }

  protected createFormGroup(): FormGroup {
    let group: FormGroup = new FormGroup({
      position: new FormControl('', Validators.required),
      clientDepartment: new FormControl(''),
      jobClass: new FormControl(''),
      businessUnit: new FormControl(''),
      objectAccount: new FormControl(''),
      groupedWith: new FormControl(''),
      pbjCmsPosition: new FormControl(''),
      budgetedPosition: new FormControl(''),
      positionGroup: new FormControl(''),
      isDirectCare: new FormControl(''),
      essTemplate: new FormControl(''),
      selectAll: new FormControl(''),
      selectAllLicenseType: new FormControl(''),
      selectAnyLicenseType: new FormControl(''),
      positionLicenseTypes: new FormControl(''),
      searchField: new FormControl(''),
      positionLicenseTypeSelection: new FormGroup({})
    });

    this.addAdditionalControls(group);
    if (this.isSupervisorEnabled){
     this.addSupervisorControls(group);
    }
    return group;
  }

  protected updateFormGroup(): void {

    this.formGroup.get('position').setValue(this.item.name);
    this.formGroup.get('clientDepartment').setValue(this.item.clientDepartment);
    this.formGroup.get('jobClass').setValue(this.item.jobClass);
    this.formGroup.get('businessUnit').setValue(this.item.businessUnit);
    this.formGroup.get('objectAccount').setValue(this.item.objectAccount);
    this.formGroup.get('groupedWith').setValue(this.item.groupedWith);
    this.formGroup.get('pbjCmsPosition').setValue(this.item.pbjCmsPosition);
    this.formGroup.get('budgetedPosition').setValue(this.item.budgetedPosition);
    this.formGroup.get('positionGroup').setValue(this.item.positionGroup);
    this.formGroup.get('isDirectCare').setValue(this.item.isDirectCare);

    if (this.isPositionSelected) {
      this.formGroup.get('replacesPositionsIds').setValue(this.selectedPositions);
      this.formGroup.get('selectAll').setValue(true);
    }

    if (this.essTemplatesEnabled) {
      this.formGroup.get('essTemplate').setValue(this.item.essTemplate);
    }

    if (this.item.additionalInfo) {
      this.updateAdditionalControls(this.formGroup);
    }
    this.formGroup.get('positionLicenseTypes').setValue(this.item.positionLicenseTypes);
    this.operator = this.item.operator || 'AND';


    if(this.isSupervisorEnabled){
      this.formGroup.get('supervisorOrganization').setValue(this.item.supervisorOrganization);
      if (!this.item.supervisorOrganization || !this.item.supervisorDepartment )
        return;
      this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.item.supervisorOrganization.orgLevelId, employeeId: undefined })
      .then((departmentLookup: Lookup) => {
        this.supervisorDepartmentLookup = new Lookup(); 
        this.supervisorDepartmentLookup = departmentLookup;
        this.supervisorPositionLookup = new Lookup();
        this.formGroup.get('supervisorDepartment').setValue(this.item.supervisorDepartment);
        this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: this.item.supervisorDepartment.orgLevelId, employeeId: undefined })
        .then((positionLookup: Lookup) => {
           this.supervisorPositionLookup = positionLookup;
           this.formGroup.get('supervisorPosition').setValue(this.item.supervisorPosition);   
        });

      });
    }
  }

  protected updateAdditionalControls(group: FormGroup): void {

    this.formGroup.get('eeocClass').setValue(this.item.additionalInfo.eeocClass);
    this.formGroup.get('eeocGroup').setValue(this.item.additionalInfo.eeocGroup);
    this.formGroup.get('payType').setValue(this.item.additionalInfo.payType);
    this.formGroup.get('payGrade').setValue(this.item.additionalInfo.payGrade);
    this.formGroup.get('minimumRate').setValue(this.item.additionalInfo.minimumRate);
    this.formGroup.get('maximumRate').setValue(this.item.additionalInfo.maximumRate);
    this.formGroup.get('perDiemRate').setValue(this.item.additionalInfo.perDiemRate);
    this.formGroup.get('occupationCode').setValue(this.item.additionalInfo.occupationCode);
    this.formGroup.get('workersCompCode').setValue(this.item.additionalInfo.workersCompCode);
    this.formGroup.get('fte').setValue(this.item.additionalInfo.fte);
  }

  protected addSupervisorControls(group: FormGroup): void {
    group.addControl('supervisorOrganization', new FormControl('', null));
    group.addControl('supervisorDepartment', new FormControl('', null));
    group.addControl('supervisorPosition', new FormControl('', null));
  }

  protected addAdditionalControls(group: FormGroup): void {
    if (!this.hasAdditionalControls) {
      group.addControl('eeocClass', new FormControl('', null));
      group.addControl('eeocGroup', new FormControl('', null));
      group.addControl('payType', new FormControl('', null));
      group.addControl('payGrade', new FormControl('', null));
      group.addControl('minimumRate', new FormControl('', null));
      group.addControl('maximumRate', new FormControl('', null));
      group.addControl('perDiemRate', new FormControl('', null));
      group.addControl('occupationCode', new FormControl('', null));
      group.addControl('workersCompCode', new FormControl('', null));
      group.addControl('fte', new FormControl('', null));
      this.hasAdditionalControls = true;
    }
  }

  protected removeAdditionalControls(group: FormGroup): void {
    if (this.hasAdditionalControls) {
      group.removeControl('eeocClass');
      group.removeControl('eeocGroup');
      group.removeControl('payType');
      group.removeControl('payGrade');
      group.removeControl('minimumRate');
      group.removeControl('maximumRate');
      group.removeControl('perDiemRate');
      group.removeControl('occupationCode');
      group.removeControl('workersCompCode');
      group.removeControl('fte');
      this.hasAdditionalControls = false;
    }
  }

  protected loadDepartments(org: Organization): void {
    if (!org) return;
    this.isDepartmentLookupProgress = true;
    this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: org.orgLevelId, employeeId: undefined })
      .then((departmentLookup: Lookup) => {
        this.supervisorDepartmentLookup = new Lookup();
        this.supervisorPositionLookup = new Lookup();
        this.supervisorDepartmentLookup = departmentLookup;
        this.isDepartmentLookupProgress = false;
      });
  }

  protected onSupervisorDepartmentChange(dep: Department): void {
    if (!dep) return;
    this.isPositionLookupProgress = true;
    this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: dep.orgLevelId, employeeId: undefined })
      .then((positionLookup: Lookup) => {
        this.isPositionLookupProgress = false;
        this.supervisorPositionLookup = positionLookup;
        this.supervisorPositionLookup.items = _.filter(positionLookup.items, (po: Position) => {
          return po.id !== this.item.id;
        });
      });
  }

  public removedropdownValue( property: string): void {
    if (property == 'supervisorOrganization') {
      this.formGroup.get('supervisorOrganization').reset();
      this.formGroup.get('supervisorDepartment').reset();
      this.formGroup.get('supervisorPosition').reset();
    }
    else if (property == 'supervisorDepartment') {
      this.formGroup.get('supervisorDepartment').reset();
      this.formGroup.get('supervisorPosition').reset();
    }
    else if (property == 'supervisorPosition') {
      this.formGroup.get('supervisorPosition').reset();
    }
  }
  
  private getSettings() {
    this.appSettingsManageService.getAppServerConfig()
      .then((conf: AppServerConfig) => {
        this.isNextgenPayrollEnabled = conf.IsNextgenPayrollEnabled;
        if(this.isNextgenPayrollEnabled && !this.isAddPosition){
          this.isSystemLogin = this.sessionService.getUser() && this.sessionService.getUser().username.toLowerCase() === 'system' ? true : false;
        }
      });
  }
}
