export interface IGenericListRequest {
  ids: number[];
  startDate: string;
  endDate: string;
  positionId?: number;
}

export class GenericListRequest {
  public ids: number[];
  public startDate: Date;
  public endDate: Date;
  public positionId?: number;
}
