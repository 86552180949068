import { IdealSchedulePositionDefinition } from './ideal-schedule-position-definition';
import { IdealScheduleConfigType } from './ideal-schedule-config-type';
import { IdealScheduleConfigCensus } from './ideal-schedule-config-census';

export class IdealScheduleOverviewItem {
    public position: IdealSchedulePositionDefinition;
    public avgDailyHours: number;
    public alternatingWeeks: boolean;
    public censusConfig: IdealScheduleConfigCensus;
    public period: string;
}
