import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { W2sManagementService } from '../../../services/w2s/w2s-management.service';
import { ModalService, ConfirmOptions } from '../../../../common/index';
import { ConfirmDialog4Component } from '../../../../common/components/index';
var W2sToolbarComponent = /** @class */ (function () {
    function W2sToolbarComponent(w2sManagementService, modalService) {
        this.w2sManagementService = w2sManagementService;
        this.modalService = modalService;
        this.currentYear = moment().toDate().getFullYear();
        this.defaultYear = 2022;
        this.selectedYear = this.defaultYear;
        this.defaultValue = this.defaultYear;
        this.yearOptions = [];
        this.isUnApproved = false;
        this.isApproved = false;
        this.isAuthorizedToPublish = false;
        this.isAuthorizedToUnpublish = false;
        this.columnsStateName = 'W2sToolbar';
        this.subscriptions = {};
    }
    W2sToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createYearOptions();
        this.subscribeToOrgLevel();
        this.subscriptions.onPublish = this.w2sManagementService.subscribeToRecordsAlreadyPublished(function (publishedType) {
            if (publishedType.publish) {
                _this.recordAlreadyPublished("published");
            }
            if (publishedType.unPublish) {
                _this.recordAlreadyPublished("unpublished");
            }
        });
        this.subscriptions.onLoaded = this.w2sManagementService
            .subscribeToLoadedRecords(function (r) {
            var selectedRecords = _.filter(r.records, function (item) { return (item.w2Document.isApproved == 0); });
            _this.isAuthorizedToPublish = r.publishW2sAction;
            _this.isAuthorizedToUnpublish = r.unPublishW2sAction;
            if (selectedRecords.length > 0) {
                _this.isUnApproved = true;
            }
            else {
                _this.isUnApproved = false;
            }
            if (selectedRecords.length < r.records.length) {
                _this.isApproved = true;
            }
            else {
                _this.isApproved = false;
            }
        });
        this.subscriptions.onLoaded = this.w2sManagementService
            .subscribeToLoadedCounts(function (c) {
            _this.companyCounts = c.companieswithW2Data;
            _this.finalizedCounts = c.companiesFinalized;
            _this.inProgressCounts = c.companiesinProgress;
        });
    };
    W2sToolbarComponent.prototype.recordAlreadyPublished = function (type) {
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        options.isWarningIcon = true;
        ConfirmDialog4Component.openDialog('Warning', "Form W-2 Statements have already been " + type + ", please refresh your screen to see the details.", this.modalService, function (result) {
        }, options);
    };
    W2sToolbarComponent.prototype.onPublish = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        options.isWarningIcon = false;
        options.notes = 'Please confirm you want to make these documents visible to employees.';
        ConfirmDialog4Component.openDialog('Publish Form W-2 Statements', 'Publishing Form W-2 Statements to your Employees will now make these documents visible to your employees in their Mobile App.', this.modalService, function (isConfirm) {
            if (isConfirm) {
                _this.publishW2s();
            }
        }, options);
    };
    W2sToolbarComponent.prototype.onUnPublish = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        options.isWarningIcon = true;
        options.notes = 'Please confirm you want to revoke the visibility of the Form W-2 Statements from the Employees.';
        ConfirmDialog4Component.openDialog('Unpublish Form W-2 Statements', 'Unpublishing Form W-2 Statements to your Employees will now revoke the visiblity from the employees in Smartlinx GO. Please note that employees may have already viewed and downloaded the document.', this.modalService, function (isConfirm) {
            if (isConfirm) {
                _this.unPublishW2s();
            }
        }, options);
    };
    W2sToolbarComponent.prototype.onYearChange = function (selYear) {
        this.selectedYear = selYear;
        if (this.defaultValue != selYear)
            this.w2sManagementService.loadW2s(this.orgLevel.id, this.selectedYear);
        this.defaultValue = 0;
        this.getCompanyCounts();
    };
    W2sToolbarComponent.prototype.createYearOptions = function () {
        this.yearOptions = _.values(_.range(this.defaultYear, this.currentYear + 1));
    };
    W2sToolbarComponent.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.w2sManagementService.loadW2s(_this.orgLevel.id, _this.selectedYear);
        });
    };
    W2sToolbarComponent.prototype.publishW2s = function () {
        this.w2sManagementService.publishLoadedRecords(this.orgLevel.id, this.selectedYear);
    };
    W2sToolbarComponent.prototype.unPublishW2s = function () {
        this.w2sManagementService.unPublishLoadedRecords(this.orgLevel.id, this.selectedYear);
    };
    W2sToolbarComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(W2sToolbarComponent.prototype, "isAllowedPublish", {
        get: function () {
            return this.isAuthorizedToPublish;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(W2sToolbarComponent.prototype, "isAllowedUnublish", {
        get: function () {
            return this.isAuthorizedToUnpublish;
        },
        enumerable: true,
        configurable: true
    });
    W2sToolbarComponent.prototype.getCompanyCounts = function () {
        this.w2sManagementService.getCompanyCounts(this.orgLevel.id, this.selectedYear);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], W2sToolbarComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], W2sToolbarComponent.prototype, "subscriptions", void 0);
    return W2sToolbarComponent;
}());
export { W2sToolbarComponent };
