import * as _ from 'lodash';
import { IEmployeeDefinition, EmployeeDefinition, Position, IPosition, PayCycle } from '../../../organization/models/index';
import { ExceptionRecord, IExceptionRecord } from './exception-record';

export interface IExceptionConsoleRecord {
  employee: IEmployeeDefinition;
  position: IPosition;
  totalExceptionsCount: number;
  pendingMissingPunchesCount: number;
  approvedMissingPunchesCount: number;
  isSelectable: boolean;
  exceptions: IExceptionRecord[];
  payCycle: PayCycle;
  isOrganizationPayrollLocked: boolean;
  isPayCycleLocked: boolean;
  isTimecardLocked: boolean;
}

export class ExceptionConsoleRecord {

  public get missingPunches(): string {
    return `${this.pendingMissingPunchesCount}/${this.approvedMissingPunchesCount}`;
  }
  public employee: EmployeeDefinition;
  public position: Position;
  public pendingMissingPunchesCount: number;
  public approvedMissingPunchesCount: number;
  public isSelectable: boolean;
  public totalExceptionsCount: number;
  public exceptionsMap: StringMap<ExceptionRecord>;
  public isSelected: boolean;
  public canModifyOwnTimecard: boolean;
  public payCycle: PayCycle;
  public exceptions: ExceptionRecord[];
  public isOrganizationPayrollLocked: boolean;
  public isPayCycleLocked: boolean;
  public isTimecardLocked: boolean;

  public get isLocked(): boolean {
    return this.isOrganizationPayrollLocked || this.isPayCycleLocked || this.isTimecardLocked;
  }

  public get startDate(): Date {
    return this.payCycle ? this.payCycle.startDate : null;
  }

  public get endDate(): Date {
    return this.payCycle ? this.payCycle.endDate : null;
  }

  public getExceptionCount(name: string): number {
    return this.exceptionsMap && this.exceptionsMap[name] ? this.exceptionsMap[name].exceptionCount : 0;
  }
}
