import { LoaAttachment, ReadFile } from '../../../organization/models/index';

export class LoaFile {
  public readonly isAttachment: boolean;
  constructor(public sourceItem: LoaAttachment | ReadFile) {
    this.isAttachment = this.sourceItem instanceof LoaAttachment;
  }

  public get id(): number {
    return this.isAttachment ? (this.sourceItem as LoaAttachment).id : null;
  }

  public get name(): string {
    return this.sourceItem.fileName;
  }
}
