<form #payCodesForm="ngForm" novalidate>

  <div class="pay-code-actions-bar">
    <div class="button-tip-container">
      <button class="pay-codes-button"
        [disabled]="!hourCodeRulesExists || editMode || !canEditTimecard" (click)="addHoursCode()">
        <i class="fal fa-plus-circle" aria-hidden="true"></i>
        Add Hours Code</button>
        <div class="tip-container" *ngIf="!hourCodeRulesExists">
          <span slxTooltip="No Hour Codes" tipPosition="bottom"></span>
        </div>
    </div>
    <div class="button-tip-container">
      <button class="pay-codes-button"
        [disabled]="!moneyCodeRulesExists || editMode || !canEditTimecard" (click)="addMoneyCode()">
        <i class="fal fa-plus-circle" aria-hidden="true"></i>
        Add Dollar Code</button>
        <div class="tip-container" *ngIf="!moneyCodeRulesExists">
          <span slxTooltip="No Dollar Codes" tipPosition="bottom"></span>
        </div>
    </div>
    <!--
    <button class="theme-iconed-accent-button" *ngIf="!editMode && canEditTimecard" [disabled]="!records?.length" (click)="toggleEditMode ()">Edit</button>
    <button class="theme-iconed-accent-button" *ngIf="editMode && canEditTimecard" [disabled]="!payCodesForm.valid" (click)="onItemSave ()">Save</button>
    -->
    <button class="pay-codes-button" [disabled]="editMode" *ngIf="canEditTimecard" (click)="discardChanges()">
      <i class="fal fa-times-circle" aria-hidden="true"></i>
      Discard Changes</button>
  </div>
  <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
    [data]="gridState?.view"
    
    [filter]="gridState.state.filter"
    [scrollable]="false"
    (cancel)="gridState.cancelHandler($event)"
    (edit)="gridState.editHandler($event)"
    (remove)="gridState.removeHandler($event)"
    (save)="gridState.saveHandler($event)">

    <kendo-grid-command-column width="70" media="(min-width: 781px)">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        <div class="inline-buttons">
          <button kendoGridEditCommand type="button" *ngIf="canEditTimecard" class="slx-icon-button slx-inline-edit-button">
            <i class="fas fa-pencil-alt" aria-hidden="true"></i>
          </button>
          <button kendoGridRemoveCommand *ngIf="canEditTimecard" class="slx-icon-button slx-inline-apply-button">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
          <button kendoGridSaveCommand type="button" [disabled]="!payCodesForm.valid || !isRowValid(dataItem)" class="slx-icon-button slx-inline-apply-button">
            <i class="far fa-check-circle" aria-hidden="true"></i>
          </button>
          <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button">
            <i class="fa fa-ban" aria-hidden="true"></i>
          </button>
        </div>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column media="(max-width: 780px)" class="slx-no-padding">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div class="slx-mobile-expandable">
           <div><span class="slx-mobile-expandable__header">{{ dataItem.ruleDefinition?.description }}</span> <span class="slx-mobile-expandable__header-additional">({{ dataItem.status }})</span></div>
           <slx-collapse-button [slxCollapseByCss]="paycodeCellContent"></slx-collapse-button>
          </div>
          <div #paycodeCellContent class="slx-mobile-expandable-content">
          <div class="slx-mobile-expandable-content__buttons">
               <button kendoGridEditCommand type="button" *ngIf="canEditTimecard" class="slx-icon-button slx-inline-edit-button">
                  <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                </button>
                <button kendoGridRemoveCommand *ngIf="canEditTimecard" class="slx-icon-button slx-inline-apply-button">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </button>
          </div>
           <div class="paycode-value col-xs-6">
              <span class="paycode-value-header">Type:</span> {{ dataItem.ruleDefinition?.description }}
           </div>
           <div class="paycode-value col-xs-6">
              <span class="paycode-value-header">Status:</span> {{ dataItem.status }}
            </div>
            <div *ngIf="dataItem.type === moneyType" class="paycode-value col-xs-6">
                <span class="paycode-value-header">Amount:</span> {{ dataItem.moneyAmount | number: "1.2-2" }}
            </div>
            <div *ngIf="dataItem.type === hoursType" class="paycode-value col-xs-6">
                <span class="paycode-value-header">Interval:</span>
                <span *ngIf="displayOptions==='number'">{{ dataItem.interval | slxInterval }}</span>
                <span *ngIf="displayOptions==='hmstring'"><span *ngIf="dataItem.interval < 0">-</span>{{ dataItem.intervalHM | amDateFormat: appConfig.hourMinutesFormat }}</span>
            </div>
            <div *ngIf="dataItem.type === hoursType" class="paycode-value col-xs-12">
                <span class="paycode-value-header">Start:</span> <span *ngIf="dataItem.type === hoursType">{{ dataItem.start | amDateFormat: appConfig.dateTimeFormatUS }}</span>
            </div>
            <div *ngIf="dataItem.type === hoursType" class="paycode-value col-xs-12">
                <span class="paycode-value-header">End:</span> <span>{{ dataItem.end | amDateFormat: appConfig.dateTimeFormatUS }}</span>
            </div>
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="slx-mobile-expandable">
              <div><span class="slx-mobile-expandable__header">{{ dataItem.ruleDefinition?.description }}</span> <span class="slx-mobile-expandable__header-additional">({{ dataItem.status }})</span></div>
              <slx-collapse-button [slxCollapseByCss]="paycodeEditCellContent"></slx-collapse-button>
            </div>
            <div #paycodeEditCellContent class="slx-mobile-expandable-content">
            <div class="slx-mobile-expandable-content__buttons">
                <button kendoGridSaveCommand type="button" [disabled]="!payCodesForm.valid || !isFormValid" class="slx-icon-button slx-inline-apply-button">
                    <i class="far fa-check-circle" aria-hidden="true"></i>
                </button>
                <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button">
                    <i class="fa fa-ban" aria-hidden="true"></i>
                </button>
            </div>
             <div class="col-xs-6">
                <div *ngIf="dataItem.type === hoursType">
                  <slx-dropdown-list #hoursType="ngModel"
                      class="slx-wide slx-short"
                      [options]="hourCodeRules"
                      [(ngModel)]="dataItem.ruleDefinition"
                      (ngModelChange)="onChangeRuleType(dataItem)"
                      [required]="true"
                      [readonly]="false"
                      [disableEmittingIfReadonly]="true"
                      name="hoursPayRule{{rowIndex}}"
                      titleField="description">
                  </slx-dropdown-list>
                  <div *ngIf="hoursType.errors" class="slx-error-block">
                    <span *ngIf="hoursType.errors.required" errorMessage for="required"></span>
                  </div>
                </div>
                <div *ngIf="dataItem.type === moneyType">
                    <slx-dropdown-list #moneyType="ngModel"
                    class="slx-wide slx-short"
                    [options]="moneyCodeRules"
                    [(ngModel)]="dataItem.ruleDefinition"
                    (ngModelChange)="onChangeRuleType(dataItem)"
                    [required]="true"
                    [readonly]="false"
                    [disableEmittingIfReadonly]="true"
                    [slxNoMore]="moneyCodesValidationConig"
                    name="moneyPayRule{{rowIndex}}"
                    titleField="description">
                    </slx-dropdown-list>
                    <div *ngIf="moneyType.errors" class="slx-error-block">
                      <span *ngIf="moneyType.errors.required" errorMessage for="required"></span>
                      <span *ngIf="moneyType.errors.noMore" errorMessage for="noMore">All dollar amount codes should be unique</span>
                    </div>
                </div>
             </div>
             <div class="col-xs-6">
                <slx-input-decorator className="slx-no-background">
                    <input slx-input type="text" [readonly]="true" name="status" placeholder="Status"
                      [ngModel]="dataItem.status">
                  </slx-input-decorator>
              </div>
              <div class="col-xs-6">
                  <div *ngIf="dataItem.type === moneyType">
                      <slx-input-decorator className="slx-no-background">
                        <slx-kendo-number slx-input
                          [(ngModel)]="dataItem.moneyAmount"
                          [decimals]="2"
                          placeholder="Amount"
                          name="amount{{rowIndex}}"
                        ></slx-kendo-number>
                        <span errorMessage for="required"></span>
                      </slx-input-decorator>
                  </div>
                  <div *ngIf="dataItem.type === hoursType">
                      <slx-input-decorator *ngIf="displayOptions==='number'" className="slx-no-background">
                        <slx-kendo-number slx-input
                          [ngModel]="dataItem.interval | slxInterval"
                          (ngModelChange)="onIntervalChanged(dataItem, $event)"
                          [decimals]="2"
                          [readonly]="!editMode"
                          placeholder="Interval"
                          name="interval{{rowIndex}}"
                        ></slx-kendo-number>
                      </slx-input-decorator>
                      <slx-input-decorator *ngIf="displayOptions==='hmstring'" className="slx-no-background">
                        <span *ngIf="dataItem.interval < 0">-</span><input slx-time-picker slx-input name="interval{{rowIndex}}" placeholder="Interval" [ngModel]="dataItem.intervalHM"
                          (onDateChanged)="onIntervalHMChanged(dataItem, $event)" format="HH:mm" dateInput="true">
                      </slx-input-decorator>
                  </div>
              </div>
              <div *ngIf="dataItem.type === hoursType" class="col-xs-12">
                  <slx-input-decorator #startTimeContainer className="slx-no-background">
                      <input slx-date-time-picker slx-input [required]="true" name="startDate{{rowIndex}}" placeholder="Start Time" [slxMinDate]="minDateLimit" [slxMaxDate]="dataItem.end"
                      [(ngModel)]="dataItem.start"
                      (onModelChange)="onStartDateChanged(dataItem)" [minDate]="minDateLimit" [maxDate]="maxDateLimit" >
                      <span errorMessage for="required">Start time is required</span>
                      <span errorMessage for="maxDate">Start date should be less than end date</span>
                      <span slx-input-hint>{{ dataItem.start | amDateFormat: appConfig.dateFormat }}</span>
                    </slx-input-decorator>
              </div>
              <div *ngIf="dataItem.type === hoursType" class="col-xs-12">
                  <slx-input-decorator #endTimeContainer className="slx-no-background">
                      <input slx-date-time-picker slx-input [required]="true" name="endDate{{rowIndex}}" placeholder="End Time" [slxMinDate]="minDateLimit"
                      [(ngModel)]="dataItem.end"
                      (onModelChange)="onEndDateChanged(dataItem)" [minDate]="minDateLimit" >
                      <span errorMessage for="required">End time is required</span>
                      <span errorMessage for="minDate">End date should be greater than start date</span>
                      <span slx-input-hint>{{ dataItem.end | amDateFormat: appConfig.dateFormat }}</span>
                    </slx-input-decorator>
              </div>
            </div>
          </ng-template>
      </kendo-grid-column>


    <kendo-grid-column title="Status" width="100" field="status" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Status</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.status }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Type" width="155" field="ruleDefinition.description" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Type</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.ruleDefinition?.description }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <div *ngIf="dataItem.type === hoursType" [ngStyle]="dataItem && dataItem.ruleDefinition ? {} : {'margin-bottom': '-13px'}">
                <slx-dropdown-list #hoursType="ngModel"
                        class="slx-wide slx-short"
                        [options]="hourCodeRules"
                        [(ngModel)]="dataItem.ruleDefinition"
                        (ngModelChange)="onChangeRuleType(dataItem)"
                        [required]="true"
                        [readonly]="false"
                        [disableEmittingIfReadonly]="true"
                        name="hoursPayRule{{rowIndex}}"
                        titleField="description">
                </slx-dropdown-list>
                <div *ngIf="hoursType.errors" class="slx-error-block">
                  <span *ngIf="hoursType.errors.required" errorMessage for="required"></span>
                </div>
          </div>
          <div *ngIf="dataItem.type === moneyType" [ngStyle]="dataItem && dataItem.ruleDefinition ? {} : {'margin-top': '10px'}">
            <slx-dropdown-list #moneyType="ngModel"
                      class="slx-wide slx-short"
                      [options]="moneyCodeRules"
                      [(ngModel)]="dataItem.ruleDefinition"
                      (ngModelChange)="onChangeRuleType(dataItem)"
                      [required]="true"
                      [readonly]="false"
                      [disableEmittingIfReadonly]="true"
                      [slxNoMore]="moneyCodesValidationConig"
                      name="moneyPayRule{{rowIndex}}"
                      titleField="description">
            </slx-dropdown-list>
            <div *ngIf="moneyType.errors" class="slx-error-block">
              <span *ngIf="moneyType.errors.required" errorMessage for="required"></span>
              <span *ngIf="moneyType.errors.noMore" errorMessage for="noMore">All dollar amount codes should be unique</span>
            </div>
          </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Amount" width="100" field="moneyAmount" filter="numeric" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Amount</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="dataItem.type === moneyType">{{ dataItem.moneyAmount | number: "1.2-2" }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div *ngIf="dataItem.type === moneyType" [ngStyle]="dataItem && (dataItem.moneyAmount===0 || dataItem.moneyAmount) ? {} : {'margin-top': '10px'}">
          <slx-number #moneyAmount="ngModel"
            class="slx-wide slx-input-number"
            [decimals]="2"
            [(ngModel)]="dataItem.moneyAmount"
            [required]="true"
            [max]="maxAmt"
            [slxMax]="maxAmt"
            name="amount{{rowIndex}}">
          </slx-number>
          <span *ngIf="moneyAmount.errors?.required" class="slx-error-block pull-left" errorMessage for="required"></span>
          <span *ngIf="moneyAmount?.errors?.max" class="slx-error-block pull-left" errorMessage>Max value 999999</span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Start Time" width="150" field="start" filter="date" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Start Time</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span *ngIf="dataItem.type === hoursType">{{ dataItem.start | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div *ngIf="dataItem.type === hoursType">
          <slx-input-decorator #startTimeContainer className="slx-no-background">
            <input slx-time-picker slx-input name="startDate{{rowIndex}}" required="true" placeholder="Start Time"
              [(ngModel)]="dataItem.start" (onDateChanged)="onStartDateChanged (dataItem)" format="hh:mm tt" dateInput="true"
              [slxMaxDate]="dataItem.end" [minDate]="minDateLimit" [maxDate]="maxDateLimit" slxDatePager [pagerMinDate]="minDateLimit"
              [pagerMaxDate]="maxDateLimit" [targetContainer]="startTimeContainer" btnLeftClass="prev-day-btn" btnRightClass="next-day-btn"
              styleContainerClass="slx-date-pager-date">
            <span errorMessage for="required">Start time is required</span>
            <span errorMessage for="maxDate">Start date should be less than end date</span>
            <span slx-input-hint>{{ dataItem.start | amDateFormat: appConfig.dateFormat }}</span>
          </slx-input-decorator>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="End Time" width="150" field="end" filter="date" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>End Time</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span *ngIf="dataItem.type === hoursType">{{ dataItem.end | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div *ngIf="dataItem.type === hoursType">
          <slx-input-decorator #endTimeContainer className="slx-no-background">
            <input slx-time-picker slx-input name="endDate{{rowIndex}}" required="true" placeholder="End Time"
              [(ngModel)]="dataItem.end" (onDateChanged)="onEndDateChanged (dataItem)" format="hh:mm tt" dateInput="true" [slxMinDate]="dataItem.start"
              [minDate]="minDateLimit" slxDatePager [pagerMinDate]="minDateLimit"
              [targetContainer]="endTimeContainer" btnLeftClass="prev-day-btn" btnRightClass="next-day-btn" styleContainerClass="slx-date-pager-date">
            <span errorMessage for="required">End time is required</span>
            <span errorMessage for="minDate">End date should be greater than start date</span>
            <span slx-input-hint>{{ dataItem.end | amDateFormat: appConfig.dateFormat }}</span>
          </slx-input-decorator>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Interval" width="100" field="interval" filter="numeric" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Interval</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngIf="dataItem.type === hoursType">
         <span *ngIf="displayOptions==='number'">{{ dataItem.interval | slxInterval }}</span>
         <span *ngIf="displayOptions==='hmstring'"><span *ngIf="dataItem.interval < 0">-</span>{{ dataItem.intervalHM | amDateFormat: appConfig.hourMinutesFormat }}</span>
       </ng-container>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div *ngIf="dataItem.type === hoursType" className="slx-no-background">
          <div *ngIf="displayOptions==='number'">
            <slx-number #interval="ngModel"
              class="slx-wide slx-input-number"
              [ngModel]="dataItem.interval | slxInterval"
              (ngModelChange)="onIntervalChanged(dataItem, $event)"
              [decimals]="2"
              [readonly]="!editMode"
              name="interval{{rowIndex}}">
            </slx-number>
          </div>
          <div *ngIf="displayOptions==='hmstring'"  class="interval">
            <span *ngIf="dataItem.interval < 0">-</span><input slx-time-picker slx-input name="interval{{rowIndex}}" [ngModel]="dataItem.intervalHM"
              (onDateChanged)="onIntervalHMChanged(dataItem, $event)" format="HH:mm" dateInput="true">
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Position" width="140" field="position.name">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Position</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span >{{ dataItem.position?.name }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-dropdown-list #position="ngModel"
          class="slx-wide slx-short"
          [options]="positionsLookup"
          [(ngModel)]="dataItem.position"
          [disableEmittingIfReadonly]="true"
          name="position"
          titleField="name">
        </slx-dropdown-list>
        <div *ngIf="position.errors" class="slx-error-block">
          <span *ngIf="position.errors.required" errorMessage for="required"></span>
        </div>
      </ng-template>
    </kendo-grid-column>


    <kendo-grid-column *ngIf="isRetroField" title="Additional Data"  width="50"  media="(min-width: 781px)" [style]="{'overflow': 'visible'}">
 
      <ng-template kendoGridHeaderTemplate let-dataItem="dataItem" >
        <i class="fas fa-layer-plus pl5" aria-hidden="true"></i>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem="dataItem" >   
        <div>  
        <i *ngIf="isRetroFieldRow(dataItem,true)" [slxTooltip]="dataItem.additionalVariable" tipPosition="left" class="fas fa-layer-plus icon-blue" aria-hidden="true"></i>
        <i *ngIf="isRetroFieldRow(dataItem,false)" class="fas fa-layer-plus icon-orange" aria-hidden="true"></i>
      </div>  
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" >
      <div>  
        <i *ngIf="isRetroFieldRow(dataItem,true)" [slxTooltip]="dataItem.additionalVariable" tipPosition="left"  class="fas fa-layer-plus icon-blue" (click)="setAdditionalData(dataItem)" aria-hidden="true"></i>
        <i *ngIf="isRetroFieldRow(dataItem,false)" class="fas fa-layer-plus icon-orange" (click)="setAdditionalData(dataItem)" aria-hidden="true"></i>
      </div>  
      </ng-template> 
    </kendo-grid-column>
  </kendo-grid>
</form>
