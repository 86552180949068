
export * from './lm-console/index';
export * from './lm-roster/index';
export * from './lm-type-configuration/index';
export * from './lm-creation-absence/index';
export * from './lm-recurrence/index';
export * from './lm-calendar/index';
export * from './lm-common-tab';

import { lmConsoleComponents } from './lm-console/index';
import { lmRosterComponents } from './lm-roster/index';
import { lmCreateAbsenceComponents, LMCreationDialogComponent } from './lm-creation-absence/index';
import { lmRecurrenceComponents } from './lm-recurrence/index';
import { lmCalendarComponents } from './lm-calendar/index';
import { lmTypeConfigurationComponents } from './lm-type-configuration/index';

export const lmComponents: any[] = [
  ...lmConsoleComponents,
  ...lmRosterComponents,
  ...lmCreateAbsenceComponents,
  ...lmRecurrenceComponents,
  ...lmCalendarComponents,
  ...lmTypeConfigurationComponents
];

export const lmEntryComponents: any[] = [
  LMCreationDialogComponent
];

export const lmExportComponents: any[] = [
];
