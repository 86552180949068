<div class="modal-body">
    <div class="row">
        <label class="col-xs-5">Enrollment Dates:</label>
        <div class="col-xs-7">
            <span>{{enrollmentStartDate | amDateFormat: appConfig.dateFormat }}-{{enrollmentEndDate | amDateFormat: appConfig.dateFormat }}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12">
            <div class="notes">
                <label class="notes__label">Notes</label>
                <textarea class="notes__textarea" [(ngModel)]="notes" maxlength="2500"></textarea>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
        <button type="button" [disabled]="hasNotes" (click)="save()"
            class="btn green"><i class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save</button>
        <button type="button" class="btn red" (click)="cancel()">
            <i class="fas fa-times slx-button__icon" aria-hidden="true"></i>
            Cancel
        </button>
</div>