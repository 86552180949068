import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { WcReport } from '../../../models';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { NgModel } from '@angular/forms';
import { wcConfig } from '../../../workers-compensation.config';

import { appConfig } from '../../../../../app.config'

@Component({
    moduleId: module.id,
    selector: 'slx-wc-incident-report',
    templateUrl: 'wc-incident-report.component.html',
    styleUrls: ['wc-incident-report.component.scss']
})

export class WcIncidentReportComponent implements OnInit, OnDestroy {

    @ViewChild('incidentDatetime', { static: false })
    public incidentDatetime: NgModel;
    @ViewChild('reportDatetime', { static: false })
    public reportDatetime: NgModel;

    public get isPristineIncidentDate(): boolean {
        if (this.originalReport && this.report) {
            if (this.originalReport.incidentDateTime && this.report.incidentDateTime) {
                return this.originalReport.incidentDateTime.getTime() === this.report.incidentDateTime.getTime();
            } else if (!this.originalReport.incidentDateTime && !this.report.incidentDateTime) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    public get isPristineReportDate(): boolean {
        if (this.originalReport && this.report) {
            if (this.originalReport.reportDateTime && this.report.reportDateTime) {
                return this.originalReport.reportDateTime.getTime() === this.report.reportDateTime.getTime();
            } else if (!this.originalReport.reportDateTime && !this.report.reportDateTime) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    public get minDate(): Date {
        return this.m_minimumDate;
    }

    public get maxDate(): Date {
        return this.m_maximumDate;
    }

    public get reportDateReadonly(): boolean {

        if (!this.report.editMode) {
            return true;
        }

        return false;
    }

    public get incidentDateTimeReadonly(): boolean {

        if (!this.report.editMode) {
            return true;
        }

        return false;
    }

    public report: WcReport;
    private originalReport: WcReport;

    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};

    public filesCount: number = 0;

    private m_minimumDate: Date;

    private m_maximumDate: Date;

    constructor(private management: WCReportManagementService) {
        this.m_minimumDate = moment(wcConfig.settings.minDateString).toDate();
        this.m_maximumDate=moment(appConfig.maxCorrectDate).toDate();
    }

    public ngOnInit(): void {

        this.subscriptions.load = this.management.subscribeToReportLoad(x => {
            this.originalReport = x;
            this.report = x;
            this.updateFileCount();
            this.management.setIncidentDateTime(this.report.incidentDateTime);
        });

        this.subscriptions.fileChanged = this.management.subscribeToFileChanged(x => {
            this.updateFileCount();
        });
    }

    public ngOnDestroy(): void { }

    public onIncidentDateChanged(value: any): void {
        if (this.incidentDatetime && this.reportDatetime) {
            this.management.setMainFormValidity(this.incidentDatetime.valid && this.reportDatetime.valid);
            this.management.setIncidentDateTime(this.report.incidentDateTime);
        }
    }

    public onReportDateChanged(value: any): void {
        if (this.incidentDatetime && this.reportDatetime) {
            this.management.setMainFormValidity(this.incidentDatetime.valid && this.reportDatetime.valid);
        }
    }

    private updateFileCount(): void {
        this.filesCount = _.size(this.management.getAttachments()) + _.size(this.management.getFilesToSave());
    }
}
