import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';

import { TimeclockAssignmentManagementService } from '../../../services/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-assignment',
  templateUrl: 'timeclock-assignment.component.html',
  styleUrls: ['timeclock-assignment.component.scss'],
  providers: [TimeclockAssignmentManagementService]
})
export class TimeclockAssignmentComponent implements OnInit, OnDestroy {

  public state: {
    isLoading: boolean;
  };

  @unsubscribe()
  private loadStatusSubscription: Subscription;

  private timeclockAssignmentManagementService: TimeclockAssignmentManagementService;
  constructor(timeclockAssignmentManagementService: TimeclockAssignmentManagementService) {
    this.timeclockAssignmentManagementService = timeclockAssignmentManagementService;
    this.state = {
      isLoading: false,
    };
  }

  public ngOnInit(): void {
    this.loadStatusSubscription = this.timeclockAssignmentManagementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}
