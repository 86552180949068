import * as _ from 'lodash';
import { Component, Input, ViewChild } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';

@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-boolean-filter',
  templateUrl: 'kendo-grid-boolean-filter.component.html',
  styleUrls: ['kendo-grid-boolean-filter.component.scss'],
})
export class KendoGridBooleanFilterComponent extends KendoGridBasicFilterComponent {

  @Input()
  public showOperators: boolean;
  @Input()
  public column: ColumnComponent;
  @Input()
  public filter: CompositeFilterDescriptor;
  @Input()
  public operator: string;

  @ViewChild('dropdown', {static: true})
  public dropdown: DropDownListComponent;

  public items: any[];
  public defaultItem: any;

  constructor(filterService: FilterService, localization: LocalizationService) {
    super(filterService, localization);
    this.operator = 'eq';
    this.items = [
      { text: 'Y', value: true },
      { text: 'N', value: false }
    ];
    this.defaultItem = { text: 'All', value: null };
    this.showOperators = false;
    this.operators = [];
  }

  public onClearFilters(): void {
    if (this.dropdown) {
      this.dropdown.reset();
    }
  }

}
