export interface IEmployeeResendOptInStatus {
  employeeId: number;
  sent: boolean;
  error: string;
}

export class EmployeeResendOptInStatus {
  public employeeId: number;
  public sent: boolean;
  public error: string;
}
