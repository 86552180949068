<div class="slx-is-grid-toolbar">
  <slx-toolbar class="slx-is-grid-toolbar-container">
    <ng-template slxToolbarSectionTemplate width="200">
      <div class="slx-is-grid-toolbar-dropdown slx-toolbar-section slx-no-padd-l item-selector">
        <slx-dropdown-list
          class="slx-wide slx-small-label"
          [options]="stateService.IdealScheduleConfigTypes"
          [(ngModel)]="stateService.selectedPositionType"
          (ngModelChange)="[typeOrCensusChanged(), typeChanged()]"
          valueField="id" titleField="name"
          placeholder="Type(s):"
        ></slx-dropdown-list>
      </div>

      <div class="slx-is-grid-toolbar-dropdown slx-toolbar-section slx-border slx-down item-selector">
        <slx-dropdown-list
          class="slx-wide slx-small-label"
          [options]="IdealScheduleConfigCensusOptions"
          [(ngModel)]="stateService.selectedConfigCensus"
          (ngModelChange)="[typeOrCensusChanged(), typeChanged()]"
          valueField="id" titleField="name"
          placeholder="Census:"
        ></slx-dropdown-list>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate width="200">
      <div class="slx-is-grid-toolbar-dropdown slx-toolbar-section" *ngIf="stateService.selectedPositionType?.id === IdealScheduleConfigTypeDefinition.ShiftUnit && stateService.isFixedCensus">
        <slx-multiselect
          class="slx-wide slx-small-label"
          [options]="lookups.unitModels"
          [(ngModel)]="selectedUnits"
          (ngModelChange)="filtersChanged()"
          [externalPlaceholder]="true"
          placeholder="Unit(s):"
        ></slx-multiselect>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate width="200">
      <div class="slx-is-grid-toolbar-dropdown slx-toolbar-section slx-border slx-down" *ngIf="stateService.isFixedCensus">
        <slx-multiselect
          class="slx-wide slx-small-label"
          [options]="lookups.shiftModels"
          [(ngModel)]="selectedShifts"
          (ngModelChange)="filtersChanged()"
          [externalPlaceholder]="true"
          [readonly]="stateService.isShiftGroupEnabled"
          placeholder="Shift(s):"
        ></slx-multiselect>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate width="200">
      <div class="slx-is-grid-toolbar-dropdown slx-toolbar-section">
        <slx-switcher
          label="Hide Unused:"
          labelOn="Yes"
          labelOff="No"
          [(ngModel)]="stateService.hideUnused"
          (ngModelChange)="filtersChanged()"
        ></slx-switcher>
      </div>
    </ng-template>
  </slx-toolbar>
</div>
