import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { ErrorHandlingService } from '../../../core/services/index';
var ApplicationStateBusService = /** @class */ (function () {
    function ApplicationStateBusService(errorService) {
        this.initialize$ = new ReplaySubject(1);
        this.appDeselected$ = new ReplaySubject(1);
        this.appSelected$ = new ReplaySubject(1);
        this.reloadOrgLevels$ = new Subject();
        this.orgLevelsLoaded$ = new ReplaySubject(1);
        this.orgLevelSelected$ = new ReplaySubject(1);
        this.orgLevelIdSelected$ = new ReplaySubject(1);
        this.lastOrgLevelRestored$ = new ReplaySubject(1);
        this.reloadMenu$ = new Subject();
        this.menuLoaded$ = new ReplaySubject(1);
        this.menuItemClicked$ = new ReplaySubject(1);
        this.menuSelected$ = new ReplaySubject(1);
        this.routeInfoChanged$ = new ReplaySubject(1);
        this.logout$ = new ReplaySubject(1);
        this.login$ = new ReplaySubject(1);
        this.resetCache$ = new Subject();
        this.errorService = errorService;
    }
    Object.defineProperty(ApplicationStateBusService.prototype, "isInitialized", {
        get: function () {
            return this.initialized;
        },
        enumerable: true,
        configurable: true
    });
    ApplicationStateBusService.prototype.subscribeInitialize = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.initialize$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToDeselectApp = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.appDeselected$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToSelectApp = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.appSelected$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToOrgLevelsLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelsLoaded$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToSelectOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelSelected$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToMenuLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.menuLoaded$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToSelectMenu = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.menuSelected$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToClickMenu = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.menuItemClicked$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToRouteInfo = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.routeInfoChanged$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToLogout = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.logout$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.subscribeToAppError = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.errorService.error$.subscribe(callback);
    };
    ApplicationStateBusService.prototype.initialize = function () {
        Assert.isFalse(this.initialized, 'Application Management already initialized');
        this.initialized = true;
        this.initialize$.next(null);
    };
    ApplicationStateBusService.prototype.deselectApp = function () {
        this.appDeselected$.next(null);
    };
    ApplicationStateBusService.prototype.selectApp = function (app) {
        this.appSelected$.next(app);
    };
    ApplicationStateBusService.prototype.reloadOrgLevels = function () {
        this.reloadOrgLevels$.next(null);
    };
    ApplicationStateBusService.prototype.selectOrgLevel = function (orgLevel) {
        this.orgLevelSelected$.next(orgLevel);
    };
    ApplicationStateBusService.prototype.selectOrgLevelId = function (id) {
        this.orgLevelIdSelected$.next(id);
    };
    ApplicationStateBusService.prototype.restoredLastOrgLevel = function (orgLevel) {
        this.lastOrgLevelRestored$.next(orgLevel);
    };
    ApplicationStateBusService.prototype.loadedOrgLevels = function (orgLevels) {
        this.orgLevelsLoaded$.next(orgLevels);
    };
    ApplicationStateBusService.prototype.reloadMenu = function (app, orgLevel) {
        this.reloadMenu$.next({ app: app, orgLevel: orgLevel });
    };
    ApplicationStateBusService.prototype.loadedMenu = function (menu) {
        this.menuLoaded$.next(menu);
    };
    ApplicationStateBusService.prototype.clickMenuItem = function (event) {
        this.menuItemClicked$.next(event);
    };
    ApplicationStateBusService.prototype.selectMenu = function (event) {
        this.menuSelected$.next(event);
    };
    ApplicationStateBusService.prototype.changeRouteInfo = function (data) {
        this.routeInfoChanged$.next(data);
    };
    ApplicationStateBusService.prototype.logout = function () {
        this.logout$.next(null);
    };
    ApplicationStateBusService.prototype.login = function (isAliasChanged) {
        this.login$.next(isAliasChanged);
    };
    ApplicationStateBusService.prototype.resetCache = function (cacheName) {
        this.resetCache$.next(cacheName);
    };
    return ApplicationStateBusService;
}());
export { ApplicationStateBusService };
