import { ExceptionConsoleRecord, IExceptionConsoleRecord } from './exception-console-record';
import { PayCycle } from '../../../organization/models/index';
import { ExceptionColumn, IExceptionColumn } from './exception-column';

export class ExceptionConsoleContainer {
  public payCycle: PayCycle;
  public exceptionColumns: ExceptionColumn[];
  public records: ExceptionConsoleRecord[];
}

export interface IExceptionConsoleContainer {
  exceptions: IExceptionColumn[];
  records: IExceptionConsoleRecord[];
}
