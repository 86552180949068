import { FavoritesConfigurationComponent } from './favorites-configuration/favorites-configuration.component';
import { Provider, Component, ViewChild } from '@angular/core';
import { ModalService } from './../../../common/services/modal/modal.service';
import { DialogOptions2, DialogModeSize, IDialog } from './../../../common/models/dialog-options';

@Component({
  moduleId: module.id,
  selector: 'slx-favorites-configuration-dialog',
  templateUrl: 'favorites-configuration-dialog.component.html',
  styleUrls: ['./favorites-configuration-dialog.component.scss']
})
export class FavoritesConfigurationDialogComponent implements IDialog {

  public dialogOptions: DialogOptions2;
  public dialogResult: boolean;

  private modalService: ModalService;

  public static openDialog(modalService: ModalService, callback: (result: boolean) => void): FavoritesConfigurationDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      }
    ];
    const title = 'Favorites';

    return modalService.globalAnchor.openDialog2(
      FavoritesConfigurationDialogComponent,
      title,
      dialogOptions,
      resolvedProviders,
      (result: boolean) => {
        callback(result);
      }
    );
  }

  constructor(
    dialogOptions: DialogOptions2,
    modalService: ModalService) {
    this.modalService = modalService;
    this.dialogOptions = dialogOptions;
  }

  public onCancel(): void {
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }
}
