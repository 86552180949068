import { Injectable } from '@angular/core';
import { IAppState } from '../../../store';
import { NgRedux } from '@angular-redux/store';
import { PayCycle } from '../../../organization/models/index';
import { TimecardsState, IndividualTimecardsState } from '../../models/index';
import { OrgLevel } from "../../../state-model/models";
import { ITimecardsGridState } from './timecards.types';

@Injectable()
export class TimecardsActions {
  public static TIMECARDS_DISPLAY_CHANGE_SETTINGS: string = 'TIMECARDS_DISPLAY_CHANGE_SETTINGS';
  public static TIMECARDS_DISPLAY_CLEAR_SELECTION_SETTINGS: string = 'TIMECARDS_DISPLAY_CLEAR_SELECTION_SETTINGS';
  public static TIMECARDS_DISPLAY_CHANGE_PAYCYCLE: string = 'TIMECARDS_DISPLAY_CHANGE_PAYCYCLE';
  public static TIMECARDS_DISPLAY_CLEAR_PAYCYCLE: string = 'TIMECARDS_DISPLAY_CLEAR_PAYCYCLE';
  public static TIMECARDS_DISPLAY_CHANGE_ORGLEVEL: string = 'TIMECARDS_DISPLAY_CHANGE_ORGLEVEL';
  public static TIMECARDS_DISPLAY_CLEAR_ORGLEVEL: string = 'TIMECARDS_DISPLAY_CLEAR_ORGLEVEL';
  public static TIMECARDS_DISPLAY_CHANGE_GRIDSTATE: string = 'TIMECARDS_DISPLAY_CHANGE_GRIDSTATE';
  public static TIMECARDS_DISPLAY_CLEAR_GRIDSTATE: string = 'TIMECARDS_DISPLAY_CLEAR_GRIDSTATE';
  public static TIMECARDS_DISPLAY_CHANGE_SELECTED_EMPLOYEE: string = 'TIMECARDS_DISPLAY_CHANGE_SELECTED_EMPLOYEE';
  public static INDIVIDUAL_TIMECARDS_CHANGE_SETTINGS: string = 'INDIVIDUAL_TIMECARDS_CHANGE_SETTINGS';
  public static INDIVIDUAL_TIMECARDS_SET_SCROLL_POSITION: string = 'INDIVIDUAL_TIMECARDS_SET_SCROLL_POSITION';

  private ngRedux: NgRedux<IAppState>;

  constructor(ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }

  public changeTimecardsDisplaySettings(settings: TimecardsState): void {
    this.ngRedux.dispatch({
      type: TimecardsActions.TIMECARDS_DISPLAY_CHANGE_SETTINGS,
      payload: settings
    });
  }

  public clearTimecardsDisplaySelectionSettings(): void {
    this.ngRedux.dispatch({
      type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_SELECTION_SETTINGS,
      payload: null
    });
  }

  public changeTimecardsDisplayPayCycle(payCycle: PayCycle): void {
    this.ngRedux.dispatch({
      type: TimecardsActions.TIMECARDS_DISPLAY_CHANGE_PAYCYCLE,
      payload: payCycle
    });
  }

  public timecardsDisplayClearPayCycle(): void {
    this.ngRedux.dispatch({
      type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE,
      payload: null
    });
  }

  public changeTimecardsDisplayOrgLevel(orgLevel: OrgLevel): void {
    this.ngRedux.dispatch({
      type: TimecardsActions.TIMECARDS_DISPLAY_CHANGE_ORGLEVEL,
      payload: orgLevel
    });
  }

  public clearTimecardsDisplayOrgLevel(): void {
    this.ngRedux.dispatch({
      type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_ORGLEVEL,
      payload: null
    });
  }

  public changeTimecardsDisplayGridState(gridState: ITimecardsGridState): void {
    this.ngRedux.dispatch({
      type: TimecardsActions.TIMECARDS_DISPLAY_CHANGE_GRIDSTATE,
      payload: gridState
    });
  }

  public clearTimecardsDisplayGridState(): void {
    this.ngRedux.dispatch({
      type: TimecardsActions.TIMECARDS_DISPLAY_CHANGE_GRIDSTATE,
      payload: null
    });
  }


  public changeIndividualTimecardsSettings(settings: IndividualTimecardsState): void {
    this.ngRedux.dispatch({
      type: TimecardsActions.INDIVIDUAL_TIMECARDS_CHANGE_SETTINGS,
      payload: settings
    });
  }

  public setIndividualTimecardsScrollPosition(flatMode: boolean, top: number, left: number): void {
    let state: any = this.ngRedux.getState();
    let orgLevelId: string = state.orgLevel.get('id').toString();
    this.ngRedux.dispatch({
      type: TimecardsActions.INDIVIDUAL_TIMECARDS_SET_SCROLL_POSITION,
      payload: {
        flatMode: flatMode,
        orgLevelId: orgLevelId,
        top,
        left
      }
    });
  }

  public changeSelectedEmployeeId(employeeId: number) {
    this.ngRedux.dispatch({
      type: TimecardsActions.TIMECARDS_DISPLAY_CHANGE_SELECTED_EMPLOYEE,
      payload: employeeId
    });
  }
}
