import * as _ from 'lodash';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { DialogOptions2, IDialog, KendoGridStateHelper, ModalService } from '../../../../../common';
import { AcaExportCorrected1095C } from '../../../models/aca-export/aca-export-corrected-1095c';
import { AcaExportCorrectionType } from '../../../models/aca-export/aca-export-correctiontype';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportExecutionItem } from '../../../models/aca-export/aca-export-execution';
import { AcaExportReplace1095C, MatchEmp } from '../../../models/aca-export/aca-export-replace-1095c';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'slx-aca-corrected-select-employee',
  templateUrl: './aca-corrected-select-employee.component.html',
  styleUrls: ['./aca-corrected-select-employee.component.scss']
})
export class AcaCorrectedSelectEmployeeComponent implements IDialog, OnInit {

  public get initialized(): boolean {
    return this.m_initialized;
  }

  @ViewChild('correctionForm', { static: true })
  private ngFormChild: NgForm;
  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }

  public gridData: any[] = [''];

  private modalService: ModalService;
  private options: DialogOptions2;
  private changeDetector: ChangeDetectorRef;
  public manService: AcaExportManagementService;

  constructor(
    modalService: ModalService,
    options: DialogOptions2,
    manService: AcaExportManagementService,
    changeDetector: ChangeDetectorRef
  ) {
    this.modalService = modalService;
    this.options = options;
    this.modalService = modalService;
    this.changeDetector = changeDetector;
    this.manService = manService;
    this.gridState = new KendoGridStateHelper<AcaExportReplace1095C[]>();
    this.gridState.view = null;
  }

  public dialogResult: boolean = false;
  public isButtonEnable: boolean = false;
  public acaCorrectionType: AcaExportCorrectionType;
  public acaExportExecutionItem: AcaExportExecutionItem;
  public m_initialized: boolean;
  public isLoading: boolean = true;
  public gridState: KendoGridStateHelper<AcaExportReplace1095C[]>;
  public gridView: GridDataResult;
  public sort: SortDescriptor[] = [];
  public corrected109fCRecords: AcaExportCorrected1095C[];
  public acaReplace1095C: AcaExportCorrected1095C;
  public isSsnVisible: boolean = false;
  public matchEmp: MatchEmp;
  public matchEmployeeList: MatchEmp[];
  public records: AcaExportReplace1095C[];

  public ngOnInit(): void {
    this.m_initialized = true;
    this.isLoading = false;
  }

  public filterChange(filter: any): void {
    this.gridState.state.filter = filter;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public async getAcaReplace1095C(exectionId: string): Promise<void> {
    this.isLoading = true;
    this.records = await this.manService.fetchReplace1095CRecords(exectionId);
    this.refreshGrid();
    this.isLoading = false;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onBack(): void {
    this.onCancel();
    let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
    acaExportDialogOptions.dialogType = 2;
    acaExportDialogOptions.isBack = true;
    acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
    acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
    acaExportDialogOptions.acaExportCorrected1095CContainer = this.corrected109fCRecords;
    acaExportDialogOptions.matchEmployeeList = this.matchEmployeeList;
    this.manService.openAcaExportDialog(acaExportDialogOptions);
  }

  public linkRecord(): void {
    this.onCancel();
    let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
    acaExportDialogOptions.dialogType = 2;
    acaExportDialogOptions.isBack = false;
    acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
    acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
    acaExportDialogOptions.acaExportCorrected1095CContainer = this.corrected109fCRecords;
    if (this.matchEmployeeList == null) {
      this.matchEmployeeList = [];
    }
    this.matchEmployeeList.push(this.matchEmp);
    acaExportDialogOptions.matchEmployeeList = this.matchEmployeeList;
    this.manService.openAcaExportDialog(acaExportDialogOptions);
  }

  public onConfigChanged(empRecord: AcaExportReplace1095C, event: any): void {
    this.matchEmp = new MatchEmp();
    this.matchEmp.soureEmpID = this.acaReplace1095C.employeeIdMasterId;
    this.matchEmp.tragetEmpID = empRecord.employeeIdMasterId;

    if (event == true) {
      this.isButtonEnable = true;
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  public toggleSsnVisibility(): void {
    this.isSsnVisible = !this.isSsnVisible;
  }

  public getSSN(ssn: string): string {
    try {
      if (this.isSsnVisible) {
        return ssn;
      } else {
        return `***-**-${_.takeRight(ssn, 4).join('')}`;
      }
    } catch (e) { }

    return 'Invalid SSN';
  }
}
