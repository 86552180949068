/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-add-shift-open.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../shift-replacement-selector/shift-replacement-selector.component.ngfactory";
import * as i3 from "../../../../common/services/state-management/state-management.service";
import * as i4 from "../../../../common/services/component-state/component-state-storage.service";
import * as i5 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i6 from "../shift-replacement-selector/shift-replacement-selector.component";
import * as i7 from "../../../services/shift-replacement/shift-replacement-api.service";
import * as i8 from "../../../../common/services/modal/modal.service";
import * as i9 from "../../../../core/services/user-activity/user-activity.service";
import * as i10 from "../../../../core/services/token-validity/token-validity.service";
import * as i11 from "../../../services/shift-replacement/shift-replacement-management.service";
import * as i12 from "../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i13 from "../../../../organization/services/schedule/schedule-cycle-helper.service";
import * as i14 from "@angular/common";
import * as i15 from "./employee-add-shift-open.component";
import * as i16 from "../../../../common/models/dialog-options";
import * as i17 from "../../../../organization/services/lookup/lookup-api.service";
import * as i18 from "../../../models/shift-replacement/shift-replacement-request";
var styles_EmployeeAddShiftOpenComponent = [i0.styles];
var RenderType_EmployeeAddShiftOpenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeAddShiftOpenComponent, data: {} });
export { RenderType_EmployeeAddShiftOpenComponent as RenderType_EmployeeAddShiftOpenComponent };
function View_EmployeeAddShiftOpenComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-default red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelSend() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-ban"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-primary green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSend() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-mobile-alt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Send "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.smsData.canSend; _ck(_v, 4, 0, currVal_0); }); }
function View_EmployeeAddShiftOpenComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-default red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-ban"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Discard "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-primary green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAddDisabled; _ck(_v, 4, 0, currVal_0); }); }
export function View_EmployeeAddShiftOpenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "slx-shift-replacement-selector", [], null, null, null, i2.View_ShiftReplacementSelectorComponent_0, i2.RenderType_ShiftReplacementSelectorComponent)), i1.ɵprd(512, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵdid(4, 245760, null, 0, i6.ShiftReplacementSelectorComponent, [i7.ShiftReplacementApiService, i8.ModalService, i9.UserActivityService, i3.StateManagementService, i10.TokenValidityService, i11.ShiftReplacementManagementService, i12.NotificationsService, i13.ScheduleCycleHelperService], { settings: [0, "settings"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeeAddShiftOpenComponent_1)), i1.ɵdid(6, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeeAddShiftOpenComponent_2)), i1.ɵdid(8, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.sendSmsModeOn; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.sendSmsModeOn; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_EmployeeAddShiftOpenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-add-shift-open", [], null, null, null, View_EmployeeAddShiftOpenComponent_0, RenderType_EmployeeAddShiftOpenComponent)), i1.ɵdid(1, 245760, null, 0, i15.EmployeeAddShiftOpenComponent, [i16.DialogOptions, i8.ModalService, i17.LookupApiService, i18.GroupDetailsData, i5.ColumnSettingsStorageService, i11.ShiftReplacementManagementService, i12.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeAddShiftOpenComponentNgFactory = i1.ɵccf("slx-employee-add-shift-open", i15.EmployeeAddShiftOpenComponent, View_EmployeeAddShiftOpenComponent_Host_0, {}, {}, []);
export { EmployeeAddShiftOpenComponentNgFactory as EmployeeAddShiftOpenComponentNgFactory };
