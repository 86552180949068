<form class="dialog-form" #dialogForm="ngForm">
  <div class="modal-content">
    <div class="modal-body">
      <div class="legend-column theme-body-text">
        <div class="legend-row legend-orange"><i class="fab fa-delicious" aria-hidden="true"></i><span>Mixed Rights</span></div>
        <div class="legend-row"><i class="far fa-square" aria-hidden="true"></i><span>None</span></div>
        <div class="legend-row legend-orange"><i class="far fa-check-square" aria-hidden="true"></i><span>Edit</span></div>
        <div class="legend-row"><i class="fa fa-eye" aria-hidden="true"></i><span>View Only</span></div>
        <div class="legend-row legend-orange"><i class="fa fa-eye-slash" aria-hidden="true"></i><span>View Only (Masked)</span></div>
        <div class="legend-row"><div class="gray-icon">Gray Icon</div><span>No Change</span></div>
        <div class="legend-row legend-orange"><div class="orange-icon">Orange Icon</div><span>Unsaved Changes</span></div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onOk()" class="theme-button-apply">Close</button>
    </div>
  </div>
</form>
