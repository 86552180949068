import * as moment from 'moment';

import { ShiftOvertime } from './shift-overtime';
import { ShiftLate } from './shift-late';
import { ShiftPartialAbsence } from './shift-partial-absence';
import { ShiftDefinition, LocationUnit, Position, ConstraintDefinition, ScheduleAbsence, Department, PrescheduledOvertime } from '../../../organization/models/index';
import { EmployeeDefinition } from '../../../organization/models/index';
import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../../common/utils/index';

export class ScheduleEntryShift {

  public position: Position;
  public department: Department;
  public shift: ShiftDefinition;
  public hours: number;
  public unit: LocationUnit;
  public late: ShiftLate;
  public overtime: ShiftOvertime;
  public constraint: ConstraintDefinition;
  public partialAbsence: ShiftPartialAbsence;
  public scheduleAbsence: ScheduleAbsence;
  public notes: string;
  public replaced: EmployeeDefinition;
  public replacing: EmployeeDefinition;
  public startDate: Date;
  public endDate: Date;
  public prescheduledOvertime: PrescheduledOvertime;
}
