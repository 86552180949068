import * as tslib_1 from "tslib";
import { wcConfig } from '../../workers-compensation.config';
import { ApiUtilService } from '../../../../common/index';
import { UrlParamsService } from '../../../../common/services/url-params/url-params.service';
import { WCTMapService } from './workers-compensation-table-map.service';
var WorkersCompensationTableApiService = /** @class */ (function () {
    function WorkersCompensationTableApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    WorkersCompensationTableApiService.prototype.getWrkrsCompTableModels = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWrkrsCompTableApi();
                        request = this.urlParamsService.requestGet(url, { orgLevelId: orgLevelId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapWCTModelContainer(response.data)];
                }
            });
        });
    };
    WorkersCompensationTableApiService.prototype.createcodeDefinition = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getWrkrsCompTableApi();
                body = this.mapService.mapWorkrCompTableModelToDto(req);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapCodeDefinitionEditResult(response.data); })];
            });
        });
    };
    WorkersCompensationTableApiService.prototype.modifycodeDefinition = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getWrkrsCompTableApi();
                body = this.mapService.mapWorkrCompTableModelToDto(req);
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapCodeDefinitionEditResult(response.data); })];
            });
        });
    };
    WorkersCompensationTableApiService.prototype.deleteCodeDefinition = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getWrkrsCompTableApi() + '/' + id;
                request = this.urlParamsService.createDeleteRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapCodeDefinitionEditResult(response.data); })];
            });
        });
    };
    WorkersCompensationTableApiService.prototype.getCodeTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWrkrsCompTableApi() + "/" + wcConfig.api.configuration.codeTypes.root;
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapCodeTypes(response.data)];
                }
            });
        });
    };
    WorkersCompensationTableApiService.prototype.getWrkrsCompTableApi = function () {
        return this.apiUtilService.getApiRoot() + "/" + wcConfig.api.configuration.root + "/" + wcConfig.api.configuration.wccode.root;
    };
    return WorkersCompensationTableApiService;
}());
export { WorkersCompensationTableApiService };
