<div class="modal-content">
  <div class="modal-body">
    <div class="message-block">Would you like to re-generate the employee rotation for the primary position?</div>
    <div class="col-md-6">
      <slx-input-decorator className="slx-form-group slx-no-border small-font">
        <slx-radio-input slx-input name="currentScheduleCycle" fieldName="currentScheduleCycle" [option]="currentScheduleCycle" caption="Current Schedule Cycle"
          [(ngModel)]="request.regenerateScope" (ngModelChange)="onSelectionChanged(currentScheduleCycle)">
        </slx-radio-input>
      </slx-input-decorator>
    </div>
    <div class="col-md-6">
      <slx-input-decorator className="slx-form-group slx-no-border small-font">
        <slx-radio-input slx-input name="nextScheduleCycle" fieldName="nextScheduleCycle" [option]="nextScheduleCycle" caption="Next Schedule Cycle"
          [(ngModel)]="request.regenerateScope" (ngModelChange)="onSelectionChanged(nextScheduleCycle)">
        </slx-radio-input>
      </slx-input-decorator>
    </div>
    <div *ngIf="selectedScheduleCycle">
      <div class="message-block">Schedule cycle start date is: {{selectedScheduleCycle?.startDate | amDateFormat: appConfig.dateTimeFormat}}</div>
      <div class="message-block">Selecting No will still save changes to employee rotation.</div>
    </div>
    <div *ngIf="!selectedScheduleCycle" class="message-block error">Schedule cycle not found. Please try to select another option</div>
  </div>
  <div class="modal-footer">
    <div class="buttons-container">
    <button type="button" [disabled]="!selectedScheduleCycle" (click)="onYes()" class="theme-button-apply margin-r">Yes</button>
    <button type="button" (click)="onNo()" class="theme-button-cancel margin-r">No</button>
    <button type="button" (click)="onCancel()" class="theme-button-cancel">Cancel</button>
   </div>
  </div>
</div>
