import * as tslib_1 from "tslib";
import { BarChartVerticalValuePairComponent } from '../bar-chart-vertical-value-pair/bar-chart-vertical-value-pair.component';
var BarChartHorizontalValuePairComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BarChartHorizontalValuePairComponent, _super);
    function BarChartHorizontalValuePairComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hideValues = false; //Hiding original values
        _this.outLabel = false;
        return _this;
    }
    BarChartHorizontalValuePairComponent.prototype.handleClick = function (event) {
        if (event.originalEvent && event.originalEvent.type === 'contextmenu') {
            return;
        }
        this.onClick.emit(event);
    };
    return BarChartHorizontalValuePairComponent;
}(BarChartVerticalValuePairComponent));
export { BarChartHorizontalValuePairComponent };
