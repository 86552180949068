
  <div *ngIf="isQuickEditStarted && isAllFieldsPopulated" class="message-line">
    <span class="message1">Quick Edit Active!</span>
    <span class="message2">Click a cell in the grid to apply edit.</span>
  </div>
  <div *ngIf="isQuickEditStarted && !isAllFieldsPopulated" class="message-line error-message">
    <span class="message1">Quick Edit Error!</span>
    <span class="message2">Not all required fields populated.</span>
  </div>
  <div class="shift-editor-holder">

    <div class="quick-modal-body">
      <div>
        <div>
          14 - Offer of Coverage:
          <slx-dropdown-list name="coverageDropdown"
            class="slx-wide slx-short"
            valueField="id"
            titleField="name"
            [options]="coverageOptions"          
            [(ngModel)]="selectedCoverage"
            (ngModelChange)="onChangeCoverage($event)"
          >
          </slx-dropdown-list>
        </div>
        <div>
          15 - Cost:
          <slx-number class="slx-wide slx-input-number" [min]="minAmt" [max]="maxAmt" [step]="stepcurrency"
                      [decimals]="2" [format]="currencyFormat" [autoCorrect]='true' [(ngModel)]="selectedCost"
                      name="employerContributionAmount" (ngModelChange)="onChangeContribution()" >
          </slx-number>
        </div>
        <div>
          16 - Safe Harbor:
          <slx-dropdown-list name="harborDropdown"
          class="slx-wide slx-short"
          valueField="id"
          titleField="name"
          [options]="harborOptions"          
          [(ngModel)]="selectedHarbor"
          (ngModelChange)="onChangeHarbor($event)"
          >
          </slx-dropdown-list>
        </div>
      </div>
      <div>
        <div>
          Reason:
          <slx-dropdown-list name="reasonDropdown"
          class="slx-wide slx-short"
          valueField="id"
          titleField="name"
          [options]="reasonOptions"          
          [(ngModel)]="selectedReason"
          (ngModelChange)="onChangeReason($event)"
          >
          </slx-dropdown-list>
        </div>
        <div>
          Enrolled:
          <slx-checkbox-input slx-input name="chkEnroll" fieldName="chkEnroll"  [(ngModel)]="isEnrolled"
          (ngModelChange)="onChangeEnrolled($event)">
          </slx-checkbox-input>
        </div>
        <div></div>
      </div>
    </div>   
  </div>
  <div class="buttons-holder">
    <button *ngIf="!isQuickEditStarted" [disabled]="!isAllFieldsPopulated" (click)="startQuickEdit()" class="start-button">Start</button>
    <button *ngIf="isQuickEditStarted" (click)="saveQuickEdit()" class="stop-button">Save</button>
    <button *ngIf="!showChanges" [disabled]="!hasChanges" (click)="showChangesClick()" class="show-button">Show Changes</button>
    <button *ngIf="showChanges" (click)="hideChangesClick()" class="hide-button">Hide Changes</button>
  
  </div>
  <div *ngIf="showChanges" class="changes-holder">
    <div class="changes-holder-title">
      <span>Last Changes</span>
      <hr>
    </div>
    <ng-container   *ngFor="let item of items; let index = index">  
      <div #elm [ngClass]="{ hideChildren : expandedItem?.employeeMasterId !== item.employeeMasterId }">
      <div  class="change-item">
        <span class="change-emp-name" (click)="setExpandedItem(item)" >
          <em class="fa fa-angle-down" [ngClass]="{ hideArrow : expandedItem?.employeeMasterId !== item.employeeMasterId  }" [ngClass]="{ showArrow : expandedItem?.employeeMasterId == item.employeeMasterId}"></em>
          <em class="fa fa-angle-right"  [ngClass]="{ hideArrow : expandedItem?.employeeMasterId == item.employeeMasterId }" [ngClass]="{ showArrow : expandedItem?.employeeMasterId !== item.employeeMasterId }" ></em>
          {{item.employeeName}}
        </span>      
        <button (click)="undoEdit(item)" class="undo-button">
          <i class="fas fa-undo-alt" aria-hidden="true"></i>
        </button>
      </div>
        <ng-container *ngFor="let monthItem of item.months; let index = index">       
          <div class="change-child-item">
            <span class="change-month-name">{{monthItem.monthName}}</span>
            <span class="change-value">{{monthItem.selectedCode}}  {{monthItem.selectedCost | currency}}  {{monthItem.selectedHarbor }}</span>       
            <button (click)="undoEdit(item,monthItem)" class="undo-button">
              <i class="fas fa-undo-alt" aria-hidden="true"></i>
            </button>
          </div>
        </ng-container>   
      </div>
    
    </ng-container>
  </div>
  