import * as _ from 'lodash';
import { ScheduledShiftDefinition, IScheduledShiftDefinition, IShiftDefinition, ShiftDefinition } from '../../../organization/models/index';
import { ITimecardsEarning, TimecardsEarning } from './index';
import { IDatedObject } from '../../../core/models/index';

export interface IIndividualTimecardsDay {
  date: Date;
  shift: IScheduledShiftDefinition;
  shiftCode: number;
  earnings: ITimecardsEarning[];
  punchInTime: string;
  punchOutTime: string;
  standardPayRate: number;
  //totals
  productiveHours: number;
  nonProductiveHours: number;
  totalHours: number;
  regularPay: number;
  overtimeAndExtra: number;
  totalOtherPay: number;
  overTimePay: number;
  totalAbsencePay: number;
  totalPay: number;
  //alert Badges
  warningState: number; //0x01 - No Show, 0x02, 0x04 - Invalid Punches, 0x03 - Invalid Login

  isError: boolean;
  approved: boolean;
  scheduledHours: string;
  shifts: IShiftDefinition[];
  isLocked: boolean;
  isEdited: boolean;
}

export class IndividualTimecardsDay implements IDatedObject {
  public date: Date;
  public shift: ScheduledShiftDefinition;
  public shiftCode: number;
  public earnings: TimecardsEarning[];

  public punchInTime: Date;
  public punchInSeconds: number;
  public punchOutTime: Date;
  public punchOutSeconds: number;

  public standardPayRate: number;
  //totals
  public productiveHours: number;
  public nonProductiveHours: number;
  public totalHours: number;
  public regularPay: number;
  public overtimeAndExtra: number;
  public totalOtherPay: number;
  public overTimePay: number;
  public totalAbsencePay: number;
  public totalPay: number;

  public warningState: number; //0x01 - No Show, 0x02, 0x04 - Invalid Punches, 0x03 - Invalid Login
  public isNoShow: boolean;
  public isInvalidPunch: boolean;
  public isError: boolean;
  public approved: boolean;
  public isInvalidLogin: boolean;

  public week: number;
  public weekDay: string;
  public scheduledHours: string;
  public shifts: ShiftDefinition[];
  public shiftNames: string;
  public isLocked: boolean;
  public isEdited: boolean;

  public get warningMessage(): string {
    let msg = [];
    if (this.isNoShow) {
      msg.push('No Show');
    }
    if (this.isInvalidPunch) {
      msg.push('Invalid punches');
    }
   if(this.isInvalidLogin){
      msg.push('Invalid login');
    }
    return msg.join();
  }

  public get shiftStart(): Date {
    if (!this.shift) {
      return null;
    }
    return this.shift.start;
  }

  public get shiftEnd(): Date {
    if (!this.shift) {
      return null;
    }
    return this.shift.end;
  }

  public get departmentName(): string {
    if (!this.shift) {
      return null;
    }
    return this.shift.department.name;
  }

  public get positionName(): string {
    if (!this.shift) {
      return null;
    }
    return this.shift.position.name;
  }
  public isInPayCycle: boolean;
}
