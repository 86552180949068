import { ITimeclockAssignmentRestriction, TimeclockAssignmentRestriction } from '../../../employee/employee-sections/models/index';
import {
  EmployeeDefinition, IEmployeeDefinition, Organization, IOrganization, Position, IPosition, Department, IDepartment,
  TimeclockDefinition, ITimeclockDefinition
} from '../../../organization/models/index';

export interface ITimeclockAssignmentEmployee {
  badge: string;
  employee: IEmployeeDefinition;
  organization: IOrganization;
  department: IDepartment;
  position: IPosition;
  assignments: ITimeclockAssignmentRestriction[];
}

export class TimeclockAssignmentEmployee {
  public badge: string;
  public employee: EmployeeDefinition;
  public organization: Organization;
  public department: Department;
  public position: Position;
  public assignments: TimeclockAssignmentRestriction[];
  public isSelected: boolean;
}
