import { Actions } from '../../../core/models/index';
export interface IAcaC1095Audit {
    records: IAcaC1095AuditRecord[];
    actions: Actions;
}
export class AcaC1095Audit {
    public records: AcaC1095AuditRecord[];
    public actions: Actions;
}
export interface IAcaC1095AuditRecord {
    employeeId: number;
    employeeName: string;
    year: number;
    month: string;
    expand: boolean;
    expandMonth: boolean;
    sugCode: string;
    sugCost: number;
    sugHarbor: string;
    sugZip: string;
    selCode: string;
    selCost: number;
    selZip: string;
    selHarbor: string;
    selectedBy: string;
    selectedOn: string;
    confirmedInd: string;
    confirmedBy: string;
    confirmedOn: string;
}
export class AcaC1095AuditRecord {
    public employeeId: number;
    public employeeName: string;
    public year: number;
    public month: string;
    public expand: boolean = false;
    public expandMonth: boolean = false;
    public sugCode: string;
    public sugCost: number;
    public sugHarbor: string;
    public sugZip: string;
    public selCode: string;
    public selCost: number;
    public selZip: string;
    public selHarbor: string;
    public selectedBy: string;
    public selectedOn: string;
    public confirmedInd: string;
    public confirmedBy: string;
    public confirmedOn: string;
}
export class EmployeeIdList {
    public employeeId: number;
    public expand: boolean;
    public expandMonth: boolean
}

export class AcaC1095AuditExport {
    public employeeId: number;
    public employeeName: string;
    public year: number;
    public month: string;
    public expand: boolean = false;
    public sugCode: string;
    public sugCost: number;
    public sugHarbor: string;
    public sugZip: string;
    public selCode: string;
    public selCost: number;
    public selZip: string;
    public selHarbor: string;
    public selectedBy: string;
    public selectedOn: string;
    public confirmedInd: string;
    public confirmedBy: string;
    public confirmedOn: string;

}




