import { NavigationMenuItem } from './../../../../organization/models/navigation-menu-item';
import { FavoriteItem } from './../../../models/favorites/favorite-item';
import { unsubscribe } from './../../../../core/decorators';
import { Subscription } from 'rxjs/Subscription';
import { Provider, Component, OnInit, ElementRef } from '@angular/core';
import { FavoriteManagementService } from '../../../services';



@Component({
  moduleId: module.id,
  selector: 'slx-favorites-list-configuration',
  templateUrl: 'favorites-list-configuration.component.html',
  styleUrls: ['./favorites-list-configuration.component.scss']
})
export class FavoritesListConfigurationComponent implements OnInit {
  public favorites: NavigationMenuItem[] = [];
  public disIndexes: number[] = [];
  public canDrag: boolean = false;
  @unsubscribe()
  private favSubscription: Subscription;

  constructor(private favService: FavoriteManagementService , private elementRef: ElementRef) {
  }

  public ngOnInit(): void {
    this.favSubscription = this.favService.configurationItemsUpdated$
      .subscribe((items: NavigationMenuItem[]) => {
        this.favorites = items;
        this.disIndexes = [];
        this.favorites.forEach((f: NavigationMenuItem, index: number, arr: NavigationMenuItem[]) => this.disIndexes.push(index));
      });
  }

  public onStarClicked(favorite: NavigationMenuItem): void {
    this.favService.changeFavoriteState(favorite);
  }

  public scrollToPosition() {
    this.canDrag = false;
    if (!this.elementRef.nativeElement) {
      return false;
    }
    const favListElements = this.elementRef.nativeElement.querySelectorAll('#favitem');
    if (favListElements && favListElements[favListElements.length - 1]) {
      favListElements[0].scrollIntoView(true);
    }
  }
}
