import * as _ from 'lodash';
import { IColumnSettings } from '../../../core/models';
import { AccrualType } from './accruals-balances-record';



export class AccrualBalanceSettings {
  public columns: AccrualBalanceColumnsSettings;
}

export class AccrualBalanceColumnsSettings {
  public columnsMap: StringMap<IColumnSettings>;
  public columns: IColumnSettings[];
  public dynamicColumns: IColumnSettings[];

  public createColumns(): void {
    this.columns = [
      {
        name: 'payrollNumber',
        description: 'Payroll Number',
        readonly: false,
        displayed: true,
        width: 140,
        filter: 'string'
      },
      {
        name: 'employeeStatus',
        description: 'Employee Status',
        readonly: false,
        displayed: true,
        width: 140,
        filter: 'string'
      },

      {
        name: 'policyName',
        description: 'Policy Name',
        readonly: false,
        displayed: true,
        width: 250,
        filter: 'string'
      },
      {
        name: 'policyAssignmentDate',
        description: 'Policy Assignment Date',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'date'
      },
      {
        name: 'organization',
        description: 'Organization',
        readonly: false,
        displayed: true,
        width: 170,
        filter: 'string'
      },
      {      
        name: 'department',
        description: 'Department',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'string'
      },
      {        
        name: 'position',
        description: 'Position',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'numeric'
      },
      {
        name: 'employeeType',
        description: 'Employee Type',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'numeric'
      }
    ];   

  }

  public get allColumns(): IColumnSettings[] {
    return this.dynamicColumns ? _.concat(this.columns, this.dynamicColumns) : this.columns;
  }

  public mapColumns(): void {
    this.columnsMap = _.keyBy(this.allColumns, (column: IColumnSettings) => {
      return column.name;
    });
  }

  public setDynamicColumns(accrualTypes: AccrualType[]): void {
    let columns: IColumnSettings[] = _.map(accrualTypes, (accrualType: AccrualType) => {
     if(accrualType.name)
     {
        return {
          name: accrualType.name,
          description: accrualType.name,
          readonly: false,
          displayed: true,
          width: 150,
          filter: 'numeric'   
        };
      }
    });
    this.dynamicColumns = columns;
    this.mapColumns();
  }
}


