/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group-sms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../../../../common/directives/prevent-default-link/prevent-default-link.directive";
import * as i5 from "@angular/forms";
import * as i6 from "../employee-grid/employee-grid.component.ngfactory";
import * as i7 from "../employee-grid/employee-grid.component";
import * as i8 from "../../services/employee-list/employee-list-common.service";
import * as i9 from "../../../../core/services/local-storage/local-storage.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../../common/services/column-settings/column-management.service";
import * as i12 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i13 from "./group-sms.component";
import * as i14 from "../../services/group-activities/group-activities-api.service";
import * as i15 from "../../../../core/models/tokens";
import * as i16 from "../../models/employeeActionDefinition";
import * as i17 from "../../../../state-model/models/org-level/org-level";
import * as i18 from "../../../../common/services/modal/modal.service";
import * as i19 from "../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i20 from "../../../../common/models/dialog-options";
import * as i21 from "../../../../portal/services/notifications/notifications-api.service";
var styles_GroupSmsComponent = [i0.styles];
var RenderType_GroupSmsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupSmsComponent, data: {} });
export { RenderType_GroupSmsComponent as RenderType_GroupSmsComponent };
export function View_GroupSmsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { employeeGrid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 34, "div", [["class", "modal-content group-sms"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 33, "slx-spinner", [["novalidate", ""]], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(3, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 24, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "compose-message medium"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "compose-message-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["By using the SmartLinx Application, you agree to the "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "a", [["href", "https://www.smartlinx.com/sms-terms/"], ["target", "_blank"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i4.PreventDefaultLinkDirective, [], { href: [0, "href"] }, null), (_l()(), i1.ɵted(-1, null, ["Terms and Conditions."])), (_l()(), i1.ɵted(-1, null, [" Please remember, conversations are visible to users with rights to the employee(s)."])), (_l()(), i1.ɵeld(13, 0, null, null, 12, "div", [["class", "header-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 9, "textarea", [["class", "form-control align-textarea"], ["cols", "40"], ["name", "sms-Text"], ["required", ""], ["rows", "3"]], [[8, "placeholder", 0], [1, "required", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.smsText = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(16, 16384, null, 0, i5.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵdid(17, 540672, null, 0, i5.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i5.NG_VALIDATORS, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i5.RequiredValidator, i5.MaxLengthValidator]), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(20, 671744, null, 0, i5.NgModel, [[8, null], [6, i5.NG_VALIDATORS], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(22, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["      "])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "span", [["class", "msgcount"]], null, null, null, null, null)), (_l()(), i1.ɵted(25, null, ["", "/", ""])), (_l()(), i1.ɵeld(26, 0, null, null, 2, "div", [["class", "employees-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 1, "slx-employee-grid", [], null, [[null, "onEmployeesSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEmployeesSelect" === en)) {
        var pd_0 = (_co.employeesSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_EmployeeGridComponent_0, i6.RenderType_EmployeeGridComponent)), i1.ɵdid(28, 245760, [[1, 4]], 0, i7.EmployeeGridComponent, [i1.ChangeDetectorRef, i8.EmployeeListCommonService, i9.LocalStorageService, i10.Router], { isMultiselect: [0, "isMultiselect"], fieldDefinition: [1, "fieldDefinition"], employees: [2, "employees"], isOptInStatus: [3, "isOptInStatus"] }, { onEmployeesSelect: "onEmployeesSelect" }), (_l()(), i1.ɵeld(29, 0, null, 0, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(33, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendSms() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-paper-plane slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Send"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 3, 0, currVal_0); var currVal_1 = "https://www.smartlinx.com/sms-terms/"; _ck(_v, 10, 0, currVal_1); var currVal_12 = ""; _ck(_v, 16, 0, currVal_12); var currVal_13 = _co.maxSmsCharactersLength; _ck(_v, 17, 0, currVal_13); var currVal_14 = "sms-Text"; var currVal_15 = _co.smsText; _ck(_v, 20, 0, currVal_14, currVal_15); var currVal_18 = true; var currVal_19 = _co.fieldDefinition; var currVal_20 = _co.actionEmployees; var currVal_21 = true; _ck(_v, 28, 0, currVal_18, currVal_19, currVal_20, currVal_21); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "Enter SMS message here (up to ", _co.maxSmsCharactersLength, " characters)"); var currVal_3 = (i1.ɵnov(_v, 16).required ? "" : null); var currVal_4 = (i1.ɵnov(_v, 17).maxlength ? i1.ɵnov(_v, 17).maxlength : null); var currVal_5 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_6 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_7 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_8 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_9 = i1.ɵnov(_v, 22).ngClassValid; var currVal_10 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_11 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 14, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_16 = (_co.maxSmsCharactersLength - _co.smsText.length); var currVal_17 = _co.maxSmsCharactersLength; _ck(_v, 25, 0, currVal_16, currVal_17); var currVal_22 = (!_co.canSend || (_co.smsText.length == 0)); _ck(_v, 33, 0, currVal_22); }); }
export function View_GroupSmsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-group-sms", [], null, null, null, View_GroupSmsComponent_0, RenderType_GroupSmsComponent)), i1.ɵprd(4608, null, i11.ColumnManagementService, i11.ColumnManagementService, [i12.ColumnSettingsStorageService]), i1.ɵdid(2, 114688, null, 0, i13.GroupSmsComponent, [i14.GroupActivitiesApiService, i15.EMPLOYEES_ID_TOKEN, i16.EmployeeActionDefinition, i17.OrgLevel, i18.ModalService, i19.NotificationsService, i20.DialogOptions2, i21.NotificationsApiService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var GroupSmsComponentNgFactory = i1.ɵccf("slx-group-sms", i13.GroupSmsComponent, View_GroupSmsComponent_Host_0, {}, { onComplete: "onComplete" }, []);
export { GroupSmsComponentNgFactory as GroupSmsComponentNgFactory };
