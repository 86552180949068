export * from './schedule-cycle-summary/schedule-cycle-summary.component';
export * from './schedule-cycle-summary-dialog/schedule-cycle-summary-dialog.component';
export * from './schedule-cycle-summary-grid/schedule-cycle-summary-grid.component';

import { ScheduleCycleSummaryComponent } from './schedule-cycle-summary/schedule-cycle-summary.component';
import { ScheduleCycleSummaryDialogComponent } from './schedule-cycle-summary-dialog/schedule-cycle-summary-dialog.component';
import { ScheduleCycleSummaryGridComponent } from './schedule-cycle-summary-grid/schedule-cycle-summary-grid.component';


export const generateScheduleSummaryComponents: any = [
  ScheduleCycleSummaryComponent,
  ScheduleCycleSummaryDialogComponent,
  ScheduleCycleSummaryGridComponent
];

export const entryGenerateSummaryComponents: any[] = [
  ScheduleCycleSummaryDialogComponent
];
