import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { appConfig } from '../../../app.config';
import { W2sMapService } from './w2s-map.service';
import { reportsConfig } from '../../reports.config';
var W2sApiService = /** @class */ (function () {
    function W2sApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    W2sApiService.prototype.publishRecords = function (orgLevelId, year, Ids) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getW2sRootApi() + "/" + reportsConfig.api.w2document.root + "/orglevel/" + orgLevelId + "/year/" + year;
                request = this.urlParamsService.createPutRequest(url, Ids);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapW2sPublishRecords(response.data); })];
            });
        });
    };
    W2sApiService.prototype.getW2sRecords = function (orgLevelId, year) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getW2sRootApi() + "/" + reportsConfig.api.w2document.root + "/orglevel/" + orgLevelId + "/year/" + year + "/all";
                request = this.urlParamsService.createGetRequest(url, {});
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapW2sRecords(response.data, response.meta); })];
            });
        });
    };
    W2sApiService.prototype.unPublishRecords = function (orgLevelId, year, ids) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getW2sRootApi() + "/" + reportsConfig.api.w2document.root + "/unpublish/orglevel/" + orgLevelId + "/year/" + year;
                request = this.urlParamsService.createPutRequest(url, ids);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapW2sPublishRecords(response.data); })];
            });
        });
    };
    W2sApiService.prototype.downloadW2Document = function (exportId) {
        var url = this.getW2sRootApi() + "/" + reportsConfig.api.w2document.root + "/export/" + exportId;
        var request = this.urlParamsService.createGetRequest(url, {});
        return this.apiUtilService.requestForFile(request)
            .then(function (file) { return file; });
    };
    W2sApiService.prototype.getCompanyCounts = function (orgLevelId, year) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getW2sRootApi() + "/" + reportsConfig.api.w2document.root + "/companycounts/orglevel/" + orgLevelId + "/year/" + year;
                request = this.urlParamsService.createGetRequest(url, {});
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapCompanyCounts(response.data, response.meta); })];
            });
        });
    };
    W2sApiService.prototype.getW2sRootApi = function () {
        return this.getApiRoot() + "/" + reportsConfig.api.root;
    };
    W2sApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return W2sApiService;
}());
export { W2sApiService };
