import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const DURATION_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DurationValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[duration][formControlName],[duration][formControl],[duration][ngModel]',
  /* tslint:enable */
  providers: [DURATION_VALIDATOR]
})
export class DurationValidator implements Validator {
  public validate(c: AbstractControl): {[key: string]: any} {
    return CommonValidators.duration(c);
  }
}
