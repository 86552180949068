export const AccrualPolicyDropDown: any = {

    carryOver: [
        { id: 0, name: '' },
        { id: 1, name: 'on Anniversary' },
        { id: 2, name: 'on Year End' },
        { id: 3, name: 'Other' }],
    carryOverUpTo: [
        { id: 0, name: '' },
        { id: 1, name: 'Entered Value' },
        { id: 2, name: 'Value of' },
        { id: 3, name: 'Annual Max' },
        { id: 4, name: 'Annual Max * 2' }
    ],
    accrualCalc: [
        { id: 1, name: 'Periods' },
        { id: 2, name: 'Specific Dates' }
    ],
    accruedPeriod: [
        { id: 0, name: '' },
        { id: 1, name: 'Pay Period' },
        { id: 2, name: 'Weekly' },
        { id: 3, name: 'Biweekly' },
        { id: 4, name: 'On Anniversary' },
        { id: 5, name: 'January 1' }
    ],
    probationPeriod: [
        { id: 0, name: '' },
        { id: 1, name: 'Hours' },
        { id: 2, name: 'Days' },
        { id: 3, name: 'Months' },
        { id: 4, name: 'Years' },
    ],
    deferralPeriod: [
        { id: 0, name: '' },
        { id: 1, name: 'Hours' },
        { id: 2, name: 'Days' },
        { id: 3, name: 'Months' },
        { id: 4, name: 'Years' },
    ],
    anchorDate: [
        { id: 0, name: '' },
        { id: 1, name: 'Date Hired' },
        { id: 2, name: 'Seniority Date' },
        { id: 3, name: 'Accruals Eligibility Date' },
        { id: 4, name: 'Custom Date' },
    ],
    annualMax: [
        { id: 1, name: 'Anniversary Year' },
        { id: 2, name: 'Calendar Year' }
    ],
    SeniorityPeriod: [
        { id: 0, name: '' },
        { id: 1, name: 'Days' },
        { id: 2, name: 'Weeks' },
        { id: 3, name: 'Months' },
        { id: 4, name: 'Years' }
    ],
    SeniorityRate: [
        { id: 0, name: '' },
        { id: 1, name: 'hours' },
        { id: 2, name: 'Standard Days' },
        { id: 3, name: 'Work Days' }
    ],
    SeniorityPer: [
        { id: 0, name: '' },
        { id: 1, name: 'Hour' },
        { id: 2, name: 'Day' },
        { id: 3, name: 'Week' },
        { id: 4, name: 'Pay Period' },
        { id: 5, name: 'Month' },
        { id: 6, name: 'Year' },
    ],
    AccruedBasedOn: [
        { id: 1, name: 'Periods' },
        { id: 2, name: 'Specific Dates' },
        
    ]
};