/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-schedule-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../post-schedule-settings/post-schedule-settings.component.ngfactory";
import * as i3 from "../post-schedule-settings/post-schedule-settings.component";
import * as i4 from "../../../../organization/services/lookup/lookup.service";
import * as i5 from "../../../services/open-shift-management/open-shift-management-management.service";
import * as i6 from "../../../../app-settings/services/app-settings-manage.service";
import * as i7 from "./post-schedule-form.component";
var styles_PostScheduleFormComponent = [i0.styles];
var RenderType_PostScheduleFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostScheduleFormComponent, data: {} });
export { RenderType_PostScheduleFormComponent as RenderType_PostScheduleFormComponent };
export function View_PostScheduleFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-post-schedule-settings", [], null, null, null, i2.View_PostScheduleSettingsComponent_0, i2.RenderType_PostScheduleSettingsComponent)), i1.ɵdid(2, 114688, null, 0, i3.PostScheduleSettingsComponent, [i4.LookupService, i5.OpenShiftManagementManagementService, i6.AppSettingsManageService], { settings: [0, "settings"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "theme-exsm-text post-schedule-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Posting this schedule means that employees will be able to see their schedules and will be able to submit requests for open shifts. Are you sure that you want to post this schedule?"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPostHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Post"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PostScheduleFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-post-schedule-form", [], null, null, null, View_PostScheduleFormComponent_0, RenderType_PostScheduleFormComponent)), i1.ɵdid(1, 49152, null, 0, i7.PostScheduleFormComponent, [i5.OpenShiftManagementManagementService], null, null)], null, null); }
var PostScheduleFormComponentNgFactory = i1.ɵccf("slx-post-schedule-form", i7.PostScheduleFormComponent, View_PostScheduleFormComponent_Host_0, {}, { onCancel: "onCancel", onPost: "onPost" }, []);
export { PostScheduleFormComponentNgFactory as PostScheduleFormComponentNgFactory };
