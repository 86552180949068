import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { CompanyCounts, ICompanyCounts, Iw2Document, IW2sRecord, w2Document, W2sInfo, W2sRecord } from '../../models/w2s/w2s-model';
import { EmployeeDefinitionsMapService, LookupMapService } from '../../../../app/organization';
import { FieldsMeta } from '../../../../app/core/models';





@Injectable()
export class W2sMapService {

  constructor(
    private lookupMapService: LookupMapService,
    private employeeDefinitionsMapService: EmployeeDefinitionsMapService
  ) { }
    
  public mapW2sRecords(dto: IW2sRecord[], meta: FieldsMeta): W2sInfo {
    let w2sInfo:W2sInfo=new W2sInfo();    
    w2sInfo.publishW2sAction=false;
    w2sInfo.unPublishW2sAction=false;
    w2sInfo.records= _.map(dto, (dto: IW2sRecord) => this.mapW2sRecord(dto));
    w2sInfo = this.mapActionPermission(w2sInfo,meta);
    return w2sInfo;
  }

  public mapCompanyCounts(dto: ICompanyCounts, meta: FieldsMeta): ICompanyCounts{
    let data:CompanyCounts = new CompanyCounts();
    data.companieswithW2Data = dto.companieswithW2Data;
    data.companiesFinalized = dto.companiesFinalized;
    data.companiesinProgress = dto.companiesinProgress;
    return data;
  }

  public mapActionPermission(obj:W2sInfo, meta: FieldsMeta): W2sInfo {
    let bool: boolean = false;
    _.forEach(meta.actions, (act: string) => {
      if (act == 'Publish W2s') {
        obj.publishW2sAction = true;
      }
      if(act == 'Unpublish W2s')
      {
        obj.unPublishW2sAction=true;
      }
    });
    return obj;
  }
  public mapW2sRecord(dto: IW2sRecord): W2sRecord {
    const data = new W2sRecord();

    data.employee = _.isObjectLike(dto.employee) ? this.employeeDefinitionsMapService.mapToEmployeeShortInfo(dto.employee) : null;
  
    data.department = _.isObjectLike(dto.department) ? this.lookupMapService.mapDepartment(dto.department) : null;
    data.position = _.isObjectLike(dto.position) ? this.lookupMapService.mapPosition(dto.position) : null;
    data.w2Document = _.isObjectLike(dto.w2Document) ? this.mapW2sDocument(dto.w2Document) : null;
   
    return data;
  }
  public mapW2sDocument(dto: Iw2Document): w2Document {
    const data: w2Document = new w2Document();
    data.id = dto.id;
    data.exportId = dto.exportId;
    data.isApproved = dto.isApproved;
    data.approvedTime = dto.approvedTime;
    data.costCenter=dto.costCenter;
    data.w2DocumentTitle=dto.w2DocumentTitle;
    data.w2DocumentFile=dto.w2DocumentFile;
    data.status=dto.status;
    return data;
  }

  public mapW2sPublishRecords(updatedCount:any):any{
    return updatedCount;

  }
}
