import * as _ from 'lodash';
import { ReportParameter } from '../../../models/index';
import { OrgLevel } from '../../../../state-model/models/index';
var PayCycleParameterComponent = /** @class */ (function () {
    function PayCycleParameterComponent() {
        this.includeThousandsSeparator = false;
    }
    Object.defineProperty(PayCycleParameterComponent.prototype, "parameter", {
        get: function () {
            return this.m_parameter;
        },
        set: function (value) {
            this.m_parameter = value;
            this.processParameter(this.m_parameter);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleParameterComponent.prototype, "hasDefaultValue", {
        get: function () {
            return !_.isNull(this.parameter.defaultValue) && !_.isUndefined(this.parameter.defaultValue) && this.parameter.defaultValue !== '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleParameterComponent.prototype, "lookupValue", {
        get: function () {
            return this.m_lookupValue;
        },
        set: function (value) {
            if (this.m_parameter) {
                this.m_parameter.value = value;
            }
            this.m_lookupValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleParameterComponent.prototype, "lookupName", {
        get: function () {
            if (this.parameter && this.parameter.dataType)
                return this.parameter.dataType.lookupName;
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    PayCycleParameterComponent.prototype.processParameter = function (parameter) {
        parameter.value = parameter.defaultValue;
    };
    return PayCycleParameterComponent;
}());
export { PayCycleParameterComponent };
