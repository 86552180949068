import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TelepunchLocationsManagementService } from '../../../services/telepunch-locations/telepunch-locations-management.service';
import * as _ from 'lodash';
import { appConfig } from '../../../../app.config';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
var TelepunchLocationsMobileComponent = /** @class */ (function () {
    function TelepunchLocationsMobileComponent(management) {
        this.management = management;
    }
    TelepunchLocationsMobileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.collapsed = {};
        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(function () {
            if (_this.mainForm.dirty) {
                _this.management.markAsDirty();
            }
        });
        this.dataLoadedSubscription = this.management.onLoaded$.subscribe(function (container) {
            _this.originalData = _.cloneDeep(container);
        });
        this.itemSavedSubscription = this.management.onItemSaved$.subscribe(function (location) {
            var orig = _.find(_this.originalData.records, function (tp) { return tp.id === location.id; });
            _this.copyFields(location, orig);
        });
        this.addItemClickedSubscription = this.management.addItemClicked$.subscribe(function (value) {
            _this.onChangedState(_this.management.createEmptyItem());
        });
    };
    TelepunchLocationsMobileComponent.prototype.getCurrentState = function () {
        var indexes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indexes[_i] = arguments[_i];
        }
        var key = _.map(indexes, function (index) { return String(index); }).join('');
        return _.isBoolean(this.collapsed[key]) ? this.collapsed[key] : (this.collapsed[key] = true);
    };
    TelepunchLocationsMobileComponent.prototype.onChangedState = function () {
        var indexes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indexes[_i] = arguments[_i];
        }
        var key = _.map(indexes, function (index) { return String(index); }).join('');
        this.collapsed[key] = !this.collapsed[key];
    };
    TelepunchLocationsMobileComponent.prototype.save = function (loc) {
        this.management.onSaveItem({ dataItem: loc, isNew: !loc.id });
    };
    TelepunchLocationsMobileComponent.prototype.cancel = function (loc) {
        if (loc.id > 0) {
            var orig = _.find(this.originalData.records, function (tp) { return tp.id === loc.id; });
            this.copyFields(orig, loc);
        }
        else {
            this.management.removeEmptyItem(loc);
        }
    };
    TelepunchLocationsMobileComponent.prototype.remove = function (loc) {
        if (loc.id > 0) {
            this.management.onRemoveItem(loc);
        }
        else {
            this.management.removeEmptyItem(loc);
        }
    };
    TelepunchLocationsMobileComponent.prototype.copyFields = function (src, dest) {
        dest.authorized = src.authorized;
        dest.phone = src.phone;
        dest.locationName = src.locationName;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsMobileComponent.prototype, "dataLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsMobileComponent.prototype, "itemSavedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsMobileComponent.prototype, "addItemClickedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsMobileComponent.prototype, "mainFormSubscription", void 0);
    return TelepunchLocationsMobileComponent;
}());
export { TelepunchLocationsMobileComponent };
