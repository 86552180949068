import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect } from '../../../core/decorators/index';
import { LookupApiService } from '../../../organization/services/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { GeolocationApiService } from './geolocation-api.service';
import { GeolocationMapService } from './geolocation-map.service';
import { GeolocationEntity, IGeolocationEvent } from '../../models/index';
var GeolocationManagementService = /** @class */ (function () {
    function GeolocationManagementService(apiService, mapService, orgLevelWatchService, lookup) {
        this.apiService = apiService;
        this.mapService = mapService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.lookup = lookup;
        this.subscriptions = {};
        this.orgLevelChanged$ = new ReplaySubject();
        this.onEmitEvent$ = new Subject();
        this.onChangeToolbarMode$ = new Subject();
        this.onCloseForm$ = new Subject();
        this.loading$ = new Subject();
        this.entitiesLoaded$ = new Subject();
        this.statesLoaded$ = new Subject();
        this.entitySelected$ = new Subject();
        this.cachedResults = new Map();
    }
    GeolocationManagementService.prototype.init = function () {
        this.subscribeToOrgLevelTree();
    };
    GeolocationManagementService.prototype.destroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    GeolocationManagementService.prototype.subscribeToOrgLevelChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    GeolocationManagementService.prototype.emitGeoEvent = function (e) {
        this.onEmitEvent$.next(e);
    };
    GeolocationManagementService.prototype.subscribeToGeoEvent = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onEmitEvent$.subscribe(callback);
    };
    GeolocationManagementService.prototype.changeToolbarMode = function (isActive) {
        this.onChangeToolbarMode$.next(isActive);
    };
    GeolocationManagementService.prototype.subscribeToChangeToolbarMode = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onChangeToolbarMode$.subscribe(callback);
    };
    GeolocationManagementService.prototype.closeForm = function () {
        this.onCloseForm$.next();
    };
    GeolocationManagementService.prototype.subscribeToCloseForm = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onCloseForm$.subscribe(callback);
    };
    GeolocationManagementService.prototype.entitySelected = function (entity) {
        var dto = this.mapService.mapExistingEntityToDTO(entity);
        var copy = this.mapService.mapEntity(dto);
        copy.orgLevelName = entity.orgLevelName;
        this.entitySelected$.next(copy);
    };
    GeolocationManagementService.prototype.subscribeToEntitySelected = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.entitySelected$.subscribe(callback);
    };
    GeolocationManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    GeolocationManagementService.prototype.subscribeToEntitiesLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.entitiesLoaded$.subscribe(callback);
    };
    GeolocationManagementService.prototype.subscribeToStatesLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.statesLoaded$.subscribe(callback);
    };
    GeolocationManagementService.prototype.loadGeolocations = function (orgLevelId, emitLoading) {
        if (emitLoading === void 0) { emitLoading = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (emitLoading) {
                    this.loading$.next(true);
                }
                return [2 /*return*/, this.apiService.getEntities(orgLevelId)
                        .then(function (entities) {
                        _this.entitiesLoaded$.next(entities);
                        if (emitLoading) {
                            _this.loading$.next(false);
                        }
                        return entities;
                    })
                        .catch(function (err) {
                        if (emitLoading) {
                            _this.loading$.next(false);
                        }
                        console.error(err);
                        return [];
                    })];
            });
        });
    };
    GeolocationManagementService.prototype.saveGeolocation = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loading$.next(true);
                return [2 /*return*/, this.apiService.saveEntity(entity)
                        .then(function () {
                        _this.loading$.next(false);
                    })
                        .catch(function (err) {
                        _this.loading$.next(false);
                        console.error(err);
                    })];
            });
        });
    };
    GeolocationManagementService.prototype.updateGeolocation = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loading$.next(true);
                return [2 /*return*/, this.apiService.udateEntity(entity)
                        .then(function () {
                        _this.loading$.next(false);
                    })
                        .catch(function (err) {
                        _this.loading$.next(false);
                        console.error(err);
                    })];
            });
        });
    };
    GeolocationManagementService.prototype.deleteGeolocation = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loading$.next(true);
                return [2 /*return*/, this.apiService.deleteEntity(entity)
                        .then(function () {
                        _this.loading$.next(false);
                    })
                        .catch(function (err) {
                        _this.loading$.next(false);
                        console.error(err);
                    })];
            });
        });
    };
    GeolocationManagementService.prototype.isParentOrgLevel = function (currentOrgLevelId, entityOrgLevelId) {
        if (!_.isArray(this.orgLevelsList) || !_.isFinite(currentOrgLevelId) || !_.isFinite(entityOrgLevelId))
            return false;
        var key = currentOrgLevelId + "_" + entityOrgLevelId;
        if (this.cachedResults.has(key)) {
            return this.cachedResults.get(key);
        }
        var value = this.doesOrgLevelIsParent(currentOrgLevelId, entityOrgLevelId);
        this.cachedResults.set(key, value);
        return value;
    };
    GeolocationManagementService.prototype.doesOrgLevelIsParent = function (currentOrgLevelId, entityOrgLevelId) {
        var currentOrgLevel = _.find(this.orgLevelsList, function (o) { return o.orgLevel.id === currentOrgLevelId; });
        var entityOrgLevel = _.find(this.orgLevelsList, function (o) { return o.orgLevel.id === entityOrgLevelId; });
        var isParentOrgLevel = false;
        if (_.isObjectLike(currentOrgLevel) && _.isObjectLike(entityOrgLevel)) {
            switch (currentOrgLevel.orgLevel.type) {
                case OrgLevelType.department:
                    isParentOrgLevel = entityOrgLevel.orgLevel.type === OrgLevelType.organization
                        || entityOrgLevel.orgLevel.type === OrgLevelType.division
                        || entityOrgLevel.orgLevel.type === OrgLevelType.region
                        || entityOrgLevel.orgLevel.type === OrgLevelType.corporation;
                    break;
                case OrgLevelType.organization:
                    isParentOrgLevel = entityOrgLevel.orgLevel.type === OrgLevelType.division
                        || entityOrgLevel.orgLevel.type === OrgLevelType.region
                        || entityOrgLevel.orgLevel.type === OrgLevelType.corporation;
                    break;
                case OrgLevelType.division:
                    isParentOrgLevel = entityOrgLevel.orgLevel.type === OrgLevelType.region
                        || entityOrgLevel.orgLevel.type === OrgLevelType.corporation;
                    break;
                case OrgLevelType.region:
                    isParentOrgLevel = entityOrgLevel.orgLevel.type === OrgLevelType.corporation;
                    break;
                case OrgLevelType.corporation:
                    isParentOrgLevel = false;
                    break;
                default: isParentOrgLevel = false;
            }
        }
        return isParentOrgLevel;
    };
    GeolocationManagementService.prototype.loadStates = function (emitLoading) {
        if (emitLoading === void 0) { emitLoading = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (emitLoading) {
                    this.loading$.next(true);
                }
                return [2 /*return*/, this.lookup.getStates()
                        .then(function (states) {
                        _this.statesLoaded$.next(states);
                        if (emitLoading) {
                            _this.loading$.next(false);
                        }
                        return states;
                    })];
            });
        });
    };
    GeolocationManagementService.prototype.subscribeToOrgLevelTree = function () {
        var _this = this;
        this.subscriptions.orgLevelTree = this.orgLevelWatchService.orgLevelTreeLoaded$
            .subscribe(function () {
            _this.orgLevelsList = _this.orgLevelWatchService.getFlatList();
            if (_.isArray(_this.orgLevelsList) && _.size(_this.orgLevelsList) > 0) {
                var map = _.reduce(_this.orgLevelsList, function (map, o) {
                    var orgLevelName = o.orgLevel.name;
                    if (_.size(o.parentName) > 0) {
                        orgLevelName = o.parentName + " > " + o.orgLevel.name;
                    }
                    map.set(o.orgLevel.id, orgLevelName);
                    return map;
                }, new Map());
                _this.mapService.setOrgLevelsList(map);
            }
            if (!_this.subscriptions.orgLevel) {
                _this.subscribeToOrgLevel();
            }
        });
    };
    GeolocationManagementService.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        return [4 /*yield*/, this.loadGeolocations(orgLevel.id, false)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadStates(false)];
                    case 2:
                        _a.sent();
                        this.orgLevelChanged$.next(orgLevel);
                        this.loading$.next(false);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], GeolocationManagementService.prototype, "orgLevel$", void 0);
    return GeolocationManagementService;
}());
export { GeolocationManagementService };
