import { ExportDataStatus } from '../../enums/export-data-status';
import { IExportDataExecutionStatus, ExportDataExecutionStatus } from '../export-data-execution-status';

export interface IAcaExportExecutionStatus extends IExportDataExecutionStatus {
  exportType: number;
}

export class AcaExportExecutionStatus extends ExportDataExecutionStatus{
  public exportType: number;
}
