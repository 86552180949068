<kendo-grid #kendoGrid class="slx-full-height"
  [data]="gridState?.view"
  [selectable]="false"
  [filterable]="true"
  [sortable]="false"
  [filter]="gridState.state.filter"
  (dataStateChange)="gridState.dataStateChange($event)"
  (cancel)="gridState.cancelHandler($event)"
  (edit)="gridState.editHandler($event)"
  (remove)="gridState.removeHandler($event)"
  (save)="gridState.saveHandler($event)"
>

    <kendo-grid-column title="Name" field="employeeName" [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.employeeName }}</span>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Department" field="centerName" [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.centerName }}</span>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Position" field="positionName" [filterable]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.positionName }}</span>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
    </kendo-grid-column>

</kendo-grid>
