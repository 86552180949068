import { WeatherQueryParams, WeatherLayout, WeatherSettings, TemperatureScale } from './../../weather.interfaces';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { ChangeDetectionStrategy, OnDestroy, HostBinding, Component, Input, Renderer2, ChangeDetectorRef, ElementRef } from '@angular/core';
import { WeatherApiService } from '../../services/api/weather.api.service';
import { IForecast, ICurrentWeather } from '../../models/current-weather.model';

@Component({
  moduleId: module.id,
  selector: 'weather-widget',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'weather-container.component.html',
  styleUrls: ['weather-container.component.scss'],
})
export class WeatherContainerComponent implements OnDestroy {
  @Input()
  public forecast: IForecast[] | null;
  @Input()
  public currentWeather: ICurrentWeather | null;
  @Input()
  public set settings(value: WeatherSettings) {
    if (!value) {
      return;
    }
    this._settings = value;
    this.getWeather();
    if (this._settings.layout) {
      this.isWideLayout = this._settings.layout === WeatherLayout.WIDE;
    }
    this.scaleLabel = this.getScaleLabel(value.scale);
  }

  public get settings(): WeatherSettings {
    return this._settings;
  }

  public isWideLayout = false;
  public subscriptionCurrentWeather: Subscription;
  public subscriptionForecast: Subscription;
  public currentWeather$: Observable<ICurrentWeather>;
  public forecast$: Observable<IForecast[]>;
  public isMouseOn: boolean;
  public scaleLabel: string;

  private _settings: WeatherSettings;
  private _destroyed: boolean;

  constructor(
    private weatherApi: WeatherApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private element: ElementRef
  ) { }

  public ngOnDestroy(): void {
    if (this.subscriptionCurrentWeather) {
      this.subscriptionCurrentWeather.unsubscribe();
    }
    if (this.subscriptionForecast) {
      this.subscriptionForecast.unsubscribe();
    }
    this.changeDetectorRef.detach();
    this._destroyed = true;
  }

  public getWeather(): void {
    if (this._destroyed) return;
    const params: WeatherQueryParams = Object.assign(
      {},
      this.settings.location,
      { units: this.settings.scale },
      { lang: this.settings.language }
    );
    this.weatherApi.getWeather(params)
      .then((value: ICurrentWeather) => {
        if (this._destroyed) return;
        this.currentWeather = value;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      }).catch((reason: any) => {
        if (this._destroyed) return;
        this.currentWeather = null;
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });
  }

  public get location(): string {
    if (!this.currentWeather) return '';
    let p: string = this.currentWeather.location;
    if (this._settings && this._settings.location && this._settings.location.stateAbbreviation) {
      p = `${p}, ${this._settings.location.stateAbbreviation}`;
    }
    return p;
  }

  private getScaleLabel(value: TemperatureScale): string {
    switch (value) {
      case TemperatureScale.CELCIUS:
        return 'C';
      case TemperatureScale.FAHRENHEIT:
        return 'F';
      case TemperatureScale.KELVIN:
        return 'K';
      default:
        return 'F';
    }
  }
}
