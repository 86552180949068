import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../../common/services/index';
import { BenefitDetailsExpireDialogComponent } from '../../components/benefit-details/benefit-details-expire-dialog/benefit-details-expire-dialog.component';
import { BenefitDetailsLineStandart } from '../../models/index';

@Directive({
  selector: '[slxBenefitDetailsExpireDialog]',
})
export class BenefitDetailsExpireDialogDirective {
  constructor(private modalService: ModalService) {}

  @Input()
  public slxBenefitDetailsExpireDialog: BenefitDetailsLineStandart;
  @Input()
  public canDoAction: boolean;
  @Output()
  public changedExpiredNewEndDate = new EventEmitter<Date>();

  @HostListener('click', ['$event'])
  public onClick(): void {
    if (this.canDoAction) {
      const expireDateValue = BenefitDetailsExpireDialogComponent.openDialog(
        this.slxBenefitDetailsExpireDialog,
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.changedExpiredNewEndDate.emit(expireDateValue.benefitDetailsLineNewEndDate);
          }
        }
      );
    }
  }
}
