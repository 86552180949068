import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Assert } from '../../../../framework/index';
import * as _ from 'lodash';

@Injectable()
export class BenefitConsoleCommonService {
  private expandedGroupIds$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([1]);
  private myExpandedGroups: number[] = [];

  constructor() { }

  public destroy(): void {
    this.expandedGroupIds$.complete();
  }

  public getCurrentExpandedGroups() {
    this.subscribetoExpandedGroups(ids => {
      this.myExpandedGroups = _.flatten(ids);
    });
  }

  public removeCollapsedGroups(groupId: number): void {
    this.getCurrentExpandedGroups();
    this.myExpandedGroups = this.myExpandedGroups.filter((item) => { return groupId !== item; });
    this.expandedGroupIds$.next(this.myExpandedGroups);
  }

  public recordExpandedGroups(groupId: number): void {
    this.getCurrentExpandedGroups();
    if (this.myExpandedGroups.indexOf(groupId) === -1) {
      this.myExpandedGroups.push(groupId);
    }
    this.expandedGroupIds$.next(this.myExpandedGroups);
  }

  public subscribetoExpandedGroups(callback: (v: number[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.expandedGroupIds$.subscribe(callback);
  }
}
