import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import { appConfig } from '../../../../app.config';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { Assert } from '../../../../framework/index';
import { ResponseBody, Meta, FieldsMeta } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { ReadFile } from '../../../../organization/models/index';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { EmployeeSectionsBenefitsManagementMapService } from './employee-sections-benefits-management-map.service';
import {
  IEmployeeSectionsSubsectionContainer,
  IEmployeeSectionsBenefitsManagement,
  EmployeeSectionsBenefitsManagement,
  EmployeeSubsectionBenefitClasses,
  IEmployeeSubsectionBenefitClasses,
  IEmployeeSubsectionDependents,
  EmployeeSubsectionDependents,
  IEmployeeSectionsEnrollmentAttachments,
  EmployeeSectionsEnrollmentAttachments,
  EmployeeSubsectionEnrollment,
  IEmployeeSubsectionEnrollment,
  IEnrollmentHistoryRecord,
  EnrollmentHistoryRecord,
  BenefitPlanShortInfo,
  IBenefitPlanShortInfo,
  IBenefitsEmpEnrollOptionRate,
  BenefitsEmpEnrollOptionRate,
  BenefitTierDefinition,
  IBenefitTierDefinition,
  BenefitsEmpEnrollmentFlat,
  IBenefitsEmpEnrollmentFlat,
  BenefitEmpEnrollmentFormula,
  IBenefitEmpEnrollmentFormula,
  BenefitEmpEnrollment401k,
  IBenefitEmpEnrollment401k
} from '../../models/index';

import { EmpBeneficiaryRelation, IEmpBeneficiaryRelation } from '../../models/employee-sections-benefits-management/beneficiary-relation';
import { Beneficiary, IBeneficiary } from '../../models/employee-sections-benefits-management/beneficiary';
import { dateTimeUtils } from '../../../../common/utils';

@Injectable()
export class EmployeeSectionsBenefitsManagementApiService {

  constructor(
    private mapService: EmployeeSectionsBenefitsManagementMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getBenefitsManagementSection(employeeId: number, effectiveDate: Date): Promise<EmployeeSectionsBenefitsManagement> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url = this.getBenefitsManagementApiRoot(employeeId);
    const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });

    return this.apiUtilService.request<IEmployeeSectionsBenefitsManagement, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsBenefitsManagement, Meta>) => this.mapService.mapToSection(response.data));
  }

  public getEmployeeEnrollments(employeeId: number, effectiveDate: Date): Promise<EmployeeSubsectionEnrollment> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url = this.getEnrollmentApiRootWithoutEmp();
    const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { employeeId: employeeId, effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });

    return this.apiUtilService.request<IEmployeeSubsectionEnrollment, Meta>(request)
      .then((response: ResponseBody<IEmployeeSubsectionEnrollment, FieldsMeta>) => this.mapService.mapToEnrollment(response.data, response.meta));
  }

  public getEmployeeBenefitClasses(employeeId: number): Promise<EmployeeSubsectionBenefitClasses> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getBenManApiRootWithoutEmp()}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.benefitClasses}`;
    const req = this.urlParamsService.createGetRequest(url, { employeeId: employeeId });

    return this.apiUtilService.request<IEmployeeSubsectionBenefitClasses, FieldsMeta>(req)
      .then((response: ResponseBody<IEmployeeSubsectionBenefitClasses, FieldsMeta>) =>
        this.mapService.mapToEmployeeBenefitClasses(response.data, response.meta)
      );
  }

  public saveBenefitClassesSection(employeeSectionsBenefitClasses: EmployeeSubsectionBenefitClasses, employeeId: number) {
    const url: string = `${this.getBenefitsManagementApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.benefitClasses}`;
    const saveDto: IEmployeeSubsectionBenefitClasses = this.mapService.mapToBenefitClassesDTO(employeeSectionsBenefitClasses);
    const request: HttpRequest<any> = new HttpRequest('PUT', url, { benefitClassesSubsection: saveDto });

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
  }

  public getBenefitsManagementDependents(employeeId: number): Promise<EmployeeSubsectionDependents> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url = this.getBenefitsManagementDependentsApiRoot(employeeId);
    const request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<IEmployeeSectionsSubsectionContainer<IEmployeeSubsectionDependents>, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsSubsectionContainer<IEmployeeSubsectionDependents>, Meta>) =>
        this.mapService.mapToDependents(response.data.data, response.data.metadata));
  }

  public getBenefitsEnrollmentAttachment(employeeId): Promise<EmployeeSectionsEnrollmentAttachments> {
    const url = this.getBenefitsManagementEnrollmentAttachmentsApiRoot();
    const request = this.urlParamsService.createGetRequest(url, { employeeId });
    return this.apiUtilService.request<IEmployeeSectionsEnrollmentAttachments, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsEnrollmentAttachments, Meta>) => this.mapService.mapToAttachments(response.data));
  }

  public saveNotes(employeeId: number, selectedRecord: EnrollmentHistoryRecord): Promise<EnrollmentHistoryRecord> {
    const url: string = this.getBenefitsManagemApiSaveNotesRoot(employeeId);
    const request = this.urlParamsService.createPostRequest(url, selectedRecord);
    return this.apiUtilService.request<IEnrollmentHistoryRecord, Meta>(request)
      .then((response: ResponseBody<IEnrollmentHistoryRecord, Meta>) => this.mapService.mapBenefitEnrollmentHistoryRecord(response.data));
  }

  public addDocument(documents: ReadFile[], employeeId: number, name: string): Promise<EmployeeSectionsEnrollmentAttachments> {
    const url: string = this.getBenefitsManagementEnrollmentAttachmentsApiRoot();
    let formData: FormData = this.mapService.mapFilesToFormData(documents, name);
    const request = this.urlParamsService.requestPost(url, formData, { employeeId });
    request.autoContentType = true;

    let promise: Promise<any> = this.apiUtilService.requestNew<IEmployeeSectionsEnrollmentAttachments, FieldsMeta>(request, false)
      .then((response: ResponseBody<IEmployeeSectionsEnrollmentAttachments, Meta>) => response.data);
    return promise;
  }

  public async deleteAttachment(documentIds: number): Promise<void> {
    const url = this.getBenefitsManagementEnrollmentAttachmentsApiRoot();
    const request = this.urlParamsService.createDeleteRequest(url, { documentIds });

    await this.apiUtilService.request<IEmployeeSectionsEnrollmentAttachments, Meta>(request);

  }

  public downloadAttachment(documentId: number): Promise<FileBlobResponse> {
    const url = this.getBenefitsManagementEnrollmentDownLoad();
    const request = this.urlParamsService.createGetRequest(url, { documentId });
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public deleteEnrollment(recordId: number): Promise<void> {

    Assert.isNotNull(recordId, 'recordId');
    const url = this.getEnrollmentApiRootWithoutEmp();
    const request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url, { employeeToBenefitId: recordId });
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => response.data);

  }

  public updateEnrollment(enrollment: EnrollmentHistoryRecord): Promise<EnrollmentHistoryRecord> {
    const url = this.getEnrollmentApiRootWithoutEmp();
    const body = this.mapService.mapBenefitEnrollmentHistoryDto(enrollment);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<IEnrollmentHistoryRecord, FieldsMeta>(request)
      .then((response: ResponseBody<IEnrollmentHistoryRecord, FieldsMeta>) => this.mapService.mapBenefitEnrollmentHistoryRecord(response.data));
  }


  public getEmployeeActiveBenefitPlans(empId: number, groupId: number, effectiveDate: Date): Promise<BenefitPlanShortInfo[]> {
    const url = `${this.getBenManApiRootWithoutEmp()}/activeBenefitPlans`;
    const req = this.urlParamsService.createGetRequest(url, { employeeId: empId, benefitGroupId: groupId, effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
    return this.apiUtilService.request<IBenefitPlanShortInfo[], Meta>(req)
      .then((response: ResponseBody<IBenefitPlanShortInfo[], Meta>) =>
        this.mapService.mapToBenefitPlanShortInfos(response.data, response.meta));
  }

  public getBenefitPlanOptionRates(lineId: number): Promise<BenefitsEmpEnrollOptionRate[]> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitPlanOptionrates`;
    const req = this.urlParamsService.createGetRequest(url, { lineId: lineId });
    return this.apiUtilService.request<IBenefitsEmpEnrollOptionRate[], Meta>(req)
      .then((response: ResponseBody<IBenefitsEmpEnrollOptionRate[], Meta>) =>
        this.mapService.mapToBenefitPlanOptionRates(response.data, response.meta));
  }

  public getBenefitTiers(lineId: number): Promise<BenefitTierDefinition[]> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitTiers`;
    const req = this.urlParamsService.createGetRequest(url, { lineId: lineId });
    return this.apiUtilService.request<IBenefitTierDefinition[], Meta>(req)
      .then((response: ResponseBody<IBenefitTierDefinition[], Meta>) =>
        this.mapService.mapToBenefitTierDefinitions(response.data, response.meta));
  }

  public getBenefitPlanFlat(tierId: number): Promise<BenefitsEmpEnrollmentFlat> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitPlanFlat`;
    const req = this.urlParamsService.createGetRequest(url, { tierId: tierId });
    return this.apiUtilService.request<IBenefitsEmpEnrollmentFlat, Meta>(req)
      .then((response: ResponseBody<IBenefitsEmpEnrollmentFlat, Meta>) =>
        this.mapService.mapToBenefitsEmpEnrollmentFlat(response.data, response.meta));
  }

  public getBenefitPlanFormula(tierId: number, employeeId: number, effectiveDate: Date, coverage: number, optionCode: string): Promise<BenefitEmpEnrollmentFormula> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitPlanFormula`;
    const req = this.urlParamsService.createGetRequest(url, { tierId: tierId, employeeId: employeeId, effectiveDate: effectiveDate, coverage: coverage, optionCode: optionCode });
    return this.apiUtilService.request<IBenefitEmpEnrollmentFormula, Meta>(req)
      .then((response: ResponseBody<IBenefitEmpEnrollmentFormula, Meta>) =>
        this.mapService.mapToBenefitsEmpEnrollmentFormula(response.data, response.meta));
  }

  public getBenefitPlan401k(tierId: number): Promise<BenefitEmpEnrollment401k> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitPlan401K`;
    const req = this.urlParamsService.createGetRequest(url, { tierId: tierId });
    return this.apiUtilService.request<IBenefitEmpEnrollment401k, Meta>(req)
      .then((response: ResponseBody<IBenefitEmpEnrollment401k, Meta>) =>
        this.mapService.mapToBenefitsEmpEnrollment401k(response.data, response.meta));
  }

  public getBeneficiaries(enrollmentId: number): Promise<{ beneficiaries: Beneficiary[], relations: EmpBeneficiaryRelation[] }> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitsEnrollBeneficiaries`;
    const req = this.urlParamsService.createGetRequest(url, { employeeToBenefitId: enrollmentId });
    return this.apiUtilService.request<{ empBeneficiaries: IBeneficiary[], relations: IEmpBeneficiaryRelation[] }, Meta>(req)
      .then((response: ResponseBody<{ empBeneficiaries: IBeneficiary[], relations: IEmpBeneficiaryRelation[] }, Meta>) => {
        let beneficiaries = this.mapService.mapToBeneficiaries(response.data.empBeneficiaries, response.meta);
        let relations = this.mapService.mapToRelations(response.data.relations);
        return { beneficiaries: beneficiaries, relations: relations };
      });
  }

  public getBeneficiariesPrev(enrollmentId: number): Promise<{ beneficiaries: Beneficiary[], relations: EmpBeneficiaryRelation[] }> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitsEnrollPrevBeneficiaries`;
    const req = this.urlParamsService.createGetRequest(url, { employeeToBenefitId: enrollmentId });
    return this.apiUtilService.request<{ empBeneficiaries: IBeneficiary[], relations: IEmpBeneficiaryRelation[] }, Meta>(req)
      .then((response: ResponseBody<{ empBeneficiaries: IBeneficiary[], relations: IEmpBeneficiaryRelation[] }, Meta>) => {
        let beneficiaries = this.mapService.mapToBeneficiaries(response.data.empBeneficiaries, response.meta);
        let relations = this.mapService.mapToRelations(response.data.relations);
        return { beneficiaries: beneficiaries, relations: relations };
      });
  }

  public removeBeneficiary(item: Beneficiary): Promise<any> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitsEnrollBeneficiaries`;
    const req = this.urlParamsService.createDeleteRequest(url, { beneficiaryId: item.id });
    return this.apiUtilService.request<any, Meta>(req)
      .then((response: ResponseBody<any, FieldsMeta>) => response.data);
  }

  public addBeneficiaries(items: Beneficiary[]): Promise<{ beneficiaries: Beneficiary[], relations: EmpBeneficiaryRelation[] }> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitsEnrollBeneficiaries`;
    const req = this.urlParamsService.createPostRequest(url, this.mapService.mapToBeneficiariesDto(items));
    return this.apiUtilService.request<{ empBeneficiaries: IBeneficiary[], relations: IEmpBeneficiaryRelation[] }, Meta>(req)
      .then((response: ResponseBody<{ empBeneficiaries: IBeneficiary[], relations: IEmpBeneficiaryRelation[] }, Meta>) => {
        let beneficiaries = this.mapService.mapToBeneficiaries(response.data.empBeneficiaries, response.meta);
        let relations = this.mapService.mapToRelations(response.data.relations);
        return { beneficiaries: beneficiaries, relations: relations };
      });
  }

  public updateBeneficiaries(items: Beneficiary[]): Promise<{ beneficiaries: Beneficiary[], relations: EmpBeneficiaryRelation[] }> {
    const url = `${this.getBenManApiRootWithoutEmp()}/benefitsEnrollBeneficiaries`;
    const req = this.urlParamsService.createPostRequest(url, this.mapService.mapToBeneficiariesDto(items));
    return this.apiUtilService.request<{ empBeneficiaries: IBeneficiary[], relations: IEmpBeneficiaryRelation[] }, Meta>(req)
      .then((response: ResponseBody<{ empBeneficiaries: IBeneficiary[], relations: IEmpBeneficiaryRelation[] }, Meta>) => {
        let beneficiaries = this.mapService.mapToBeneficiaries(response.data.empBeneficiaries, response.meta);
        let relations = this.mapService.mapToRelations(response.data.relations);
        return { beneficiaries: beneficiaries, relations: relations };
      });
  }




  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.employees.root}`;
  }

  private getSectionsApiRoot(employeeId: number): string {
    return `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.sections.root}`;
  }

  private getBenefitsManagementApiRoot(employeeId?: number): string {
    return `${this.getSectionsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.root}`;
  }

  private getEnrollmentApiRootWithoutEmp(): string {
    return `${this.getBenManApiRootWithoutEmp()}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.benefitHistory}`;
  }

  private getBenManApiRootWithoutEmp(): string {
    return `${this.getApiRoot()}/${employeeSectionsConfig.api.employees.sections.root}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.root}`;
  }


  private getBenefitsManagementDependentsApiRoot(employeeId: number): string {
    return `${this.getBenefitsManagementApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.dependents}`;
  }

  private getBenefitsManagementEnrollmentAttachmentsApiRoot(): string {
    return `${this.getApiRoot()}/${employeeSectionsConfig.api.employees.sections.root}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.root}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.attachments}`;
  }
  private getBenefitsManagementEnrollmentDownLoad(): string {
    return `${this.getBenefitsManagementEnrollmentAttachmentsApiRoot()}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.download}`;
  }

  private getBenefitsManagemApiSaveNotesRoot(employeeId: number): string {
    return `${this.getApiRoot()}/${employeeSectionsConfig.api.employees.sections.root}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.root}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.benefitHistory}/${employeeSectionsConfig.api.employees.sections.benefitsManagement.notes}`;
  }

}
