import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Employee, EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { employeeListConfig } from '../../employee-list.config';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
import { ModalService, ModalAnchorDirective } from '../../../../common/index';
import { EmployeeSelfServiceApiService } from '../../../employee/services/index';
import { AppSettingsManageService } from '../../../../app-settings/services';
var CreateESSPolicyComponent = /** @class */ (function () {
    function CreateESSPolicyComponent(employeeSelfServiceApiService, groupActivitiesService, employees, fieldDefinition, orgLevel, modalService, appSettingsManageService) {
        this.appSettingsManageService = appSettingsManageService;
        this.canSend = false;
        this.employeeSelfServiceApiService = employeeSelfServiceApiService;
        this.groupActivitiesService = groupActivitiesService;
        this.employees = employees;
        this.fieldDefinition = fieldDefinition;
        this.orgLevel = orgLevel;
        this.modalService = modalService;
        this.onCancel = new EventEmitter();
        this.onComplete = new EventEmitter();
        this.state = {
            isLoading: false,
        };
    }
    CreateESSPolicyComponent.prototype.ngOnInit = function () {
        var _this = this;
        var employeeIds = _.map(this.employees, function (employee) { return employee[employeeListConfig.employeeIdentifierName]; });
        this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, true, employeeIds, this.fieldDefinition).then(function (actionEmployees) {
            _this.actionEmployees = actionEmployees;
        });
        this.assignIntFormat();
        this.getGoClientsetting();
    };
    CreateESSPolicyComponent.prototype.cancel = function () {
        this.onCancel.emit();
    };
    CreateESSPolicyComponent.prototype.employeesSelect = function (selectedEmployees) {
        this.selectedEmployees = selectedEmployees;
        this.canSend = this.selectedEmployees && this.selectedEmployees.length > 0;
    };
    CreateESSPolicyComponent.prototype.createPolicy = function () {
        var _this = this;
        this.state.isLoading = true;
        var employeeIds = _.map(this.selectedEmployees, function (emp) { return emp['EmpId']; });
        var optionsIds = _.map(this.options, function (opt) { return opt.id; });
        this.employeeSelfServiceApiService.createESSPolicy(employeeIds, this.passType ? this.passType.id : null, this.timeMethod ? this.timeMethod.id : null, optionsIds)
            .then(function (response) {
            _this.state.isLoading = false;
            _this.modalAnchor.openInfoDialog('Information', 'ESS Accounts created!');
        })
            .catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    CreateESSPolicyComponent.prototype.assignIntFormat = function () {
        _.forEach(this.fieldDefinition.fields, function (f) {
            if (f.fieldType === 'int' && (_.isNil(f.fieldFormat) || _.size(f.fieldFormat) === 0)) {
                f.fieldFormat = 'n0';
            }
        });
    };
    CreateESSPolicyComponent.prototype.getGoClientsetting = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isGoClient = config.IsGOClient;
                        return [2 /*return*/];
                }
            });
        });
    };
    return CreateESSPolicyComponent;
}());
export { CreateESSPolicyComponent };
