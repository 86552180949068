import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appConfig } from '../../../../app/app.config';
import { ComponentStateStorageService, KendoGridStateHelper, ModalService, StateManagementService } from '../../../../app/common';
import { process } from '@progress/kendo-data-query';
import { ShiftRequestService } from '../../services/schedule/shift-request.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DailyUnitGridComponent } from '../daily-unit-grid/daily-unit-grid.component';
import { DetailScreenService, ScheduleApiService, ScheduleEntryApiService, ScheduleEntryManagementService, ShiftReplacementApiService, ShiftReplacementManagementService } from '../../services';
import { LookupService, OrgLevelWatchService, ScheduleCycleHelperService } from '../../../../app/organization';
import { AgenciesApiService, RolesApiService, PartnerConfigManagementService } from '../../../../app/configuration/services';
import { AppSettingsManageService } from '../../../../app/app-settings/services';
import { Observable } from 'rxjs-compat';
import { mutableSelect } from '../../../../app/core/decorators';
import { SessionService } from '../../../../app/core/services';
var RequestAgencyStaffComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RequestAgencyStaffComponent, _super);
    function RequestAgencyStaffComponent(route, sessionService, shiftService, scheduleApiService, detailScreenService, modalService, router, shiftReplacementApiService, scheduleCycleHelperService, lookupService, stateManagement, storageService, orgLevelService, shiftRequestService, management, agenciesApiService, appSettingsManageService, partnerConfigManagementService, rolesApiService, scheduleEntryApiService, ShiftReplacementManagementService) {
        var _this = _super.call(this, scheduleEntryApiService, scheduleApiService, detailScreenService, modalService, route, router, ShiftReplacementManagementService, shiftReplacementApiService, scheduleCycleHelperService, lookupService, stateManagement, storageService, orgLevelService, shiftRequestService, management, agenciesApiService, appSettingsManageService, partnerConfigManagementService, rolesApiService) || this;
        _this.route = route;
        _this.sessionService = sessionService;
        _this.shiftService = shiftService;
        _this.pageSize = 10;
        _this.isShiftSelected = false;
        _this.displayId = 0;
        _this.selectedShiftDetails = [];
        _this.updatedShiftDetails = [];
        _this.editShiftDetails = [];
        _this.isRequestAgencyStaff = false;
        _this.group = [{ field: 'Hours' }];
        _this.agency = [];
        _this.isRequestAgency = true;
        _this.storeSelectedDataArr = [];
        _this.scrollCountentSize = 3;
        _this.multiSelectShift = [];
        _this.states = {};
        _this.isMultiSelectedShift = false;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.take = _this.pageSize;
        _this.gridState.state.skip = 0;
        return _this;
    }
    RequestAgencyStaffComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var constUserDetails;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.appConfig = appConfig;
                        if (!this.dateOn) {
                            this.dateOn = this.getSavedDate();
                        }
                        return [4 /*yield*/, _super.prototype.ngOnInit.call(this)];
                    case 1:
                        _a.sent();
                        if (!this.shiftService.isRequestFromDua) {
                            this.onLoad = true;
                            this.orgLevelSubscripion = this.orgLevel$.subscribe(function (selectedOrgLevel) {
                                _this.orgLevel = selectedOrgLevel;
                            });
                            this.loadDetails(this.orgLevel, this.dateOn)
                                .then(function (d) {
                                _this.details = d;
                                if (_this.details) {
                                    _this.previousGroups = _.cloneDeep(_this.details.groups);
                                }
                                _this.requestAgencyStaffs();
                                if (_this.agencyListData.length > 0) {
                                    _this.onLoad = false;
                                }
                            })
                                .catch(function (error) { return console.log(error); })
                                .then(function () { return (_this.isLoading = false,
                                _this.isLoading = false); });
                            this.onLoad = true;
                        }
                        constUserDetails = this.sessionService.getUser();
                        this.userName = constUserDetails.name;
                        this.userId = constUserDetails.id;
                        this.alias = this.sessionService.getAlias();
                        this.shiftService.updateShiftRequests().subscribe(function (addShift) {
                            if (addShift) {
                                _this.updateShiftRecords();
                            }
                            else {
                                _this.isEditMode = false;
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    RequestAgencyStaffComponent.prototype.requestAgencyStaffs = function () {
        var requestdata = [];
        var OpenShiftData = [];
        this.previousGroups.forEach(function (el) {
            requestdata.push(el.rows);
        });
        if (requestdata.length > 0) {
            requestdata.forEach(function (e) {
                e.forEach(function (child) {
                    OpenShiftData.push(child);
                });
            });
        }
        this.shiftService.setGridData(OpenShiftData);
    };
    RequestAgencyStaffComponent.prototype.getSelectedShift = function (data) {
        if (this.agencyListData.length > 0) {
            if (data.length >= 1) {
                this.selectedShiftDetails = data;
                this.isShiftSelected = true;
                this.setAgencyDetails();
            }
            else {
                this.selectedShiftDetails = [];
                this.isShiftSelected = false;
            }
        }
        else {
            this.selectedShiftDetails = [];
            this.isShiftSelected = false;
        }
    };
    RequestAgencyStaffComponent.prototype.dataStateChange = function (state) {
        this.states = state;
        this.refreshGrid(this.states);
    };
    RequestAgencyStaffComponent.prototype.setAgencyDetails = function () {
        var DetailsPartners = this.agencyListData;
        var agencyListObj = this.shiftService.setPartnerModelData(DetailsPartners);
        var tempAgency = Object.assign(agencyListObj);
        this.prepareAgencyList(tempAgency); // remove if agency name  undefined 
        if (this.agency.length < 3) {
            this.scrollCountentSize = this.agency.length;
        }
        else {
            this.scrollCountentSize = 3;
        }
    };
    RequestAgencyStaffComponent.prototype.prepareAgencyList = function (tempAgency) {
        var _this = this;
        this.agency = [];
        tempAgency.forEach(function (element) {
            if (element.name != undefined) {
                _this.agency.push(element);
            }
        });
    };
    RequestAgencyStaffComponent.prototype.updateShiftRecords = function () {
        if (this.isMultiSelectedShift) {
            this.updatedShiftDetails = this.updatedShiftDetails.concat(this.multiSelectShift);
            this.isMultiSelectedShift = false;
        }
        if (this.isEditMode) {
            this.updatedShiftDetails.push(this.editShiftDetails);
            this.isEditMode = false;
        }
        this.multiSelectShift = [];
        this.refreshGrid(this.gridState.state);
    };
    RequestAgencyStaffComponent.prototype.requestedAgencyDetails = function (details) {
        var _this = this;
        if (this.isEditMode) {
            this.editShiftDetails['selectedAgency'] = _.cloneDeep(details);
            this.updatedShiftDetails.push(this.editShiftDetails);
            this.isEditMode = false;
            this.refreshGrid(this.gridState.state);
        }
        else if (this.isMultiSelectedShift) {
            this.multiSelectShift.forEach(function (element) {
                element['selectedAgency'] = details;
                _this.updatedShiftDetails.push(element);
            });
            this.isMultiSelectedShift = false;
            this.multiSelectShift = [];
            this.refreshGrid(this.gridState.state);
        }
        else {
            this.isRequestAgencyStaff = true;
            var tempShiftDetail = _.cloneDeep(this.selectedShiftDetails);
            tempShiftDetail.forEach(function (element) {
                var tempSelectedAgency = details;
                element['selectedAgency'] = _.cloneDeep(tempSelectedAgency);
                var tempDetails = element;
                _this.updatedShiftDetails.push(tempDetails);
                _this.setAgencyDetails();
                _this.refreshGrid(_this.gridState.state);
            });
        }
        this.selectedShiftDetails = [];
        this.shiftService.updateShifts(true);
        this.isEditMode = false;
    };
    RequestAgencyStaffComponent.prototype.cancelHandler = function (dataItem) {
        this.onLoad = true;
        this.shiftService.deletedDisplayId = dataItem.displayId;
        this.shiftService.isrequestAgencyStaffCancelled = true;
        this.updatedShiftDetails = this.updatedShiftDetails.filter(function (e) { return e.displayId != dataItem.displayId; });
        this.shiftService.updateShifts(false);
        this.refreshGrid(this.gridState.state);
        this.onLoad = false;
    };
    RequestAgencyStaffComponent.prototype.editHandler = function (dataItem) {
        this.onLoad = true;
        this.isEditMode = true;
        var tempDataItem = _.cloneDeep(dataItem);
        this.editShiftDetails = Object.assign(tempDataItem);
        this.updatedShiftDetails = this.updatedShiftDetails.filter(function (e) { return e.displayId != tempDataItem.displayId; });
        this.shiftService.editAgencyStaffRequest(tempDataItem);
        this.onLoad = false;
        this.setAgencyDetails();
    };
    RequestAgencyStaffComponent.prototype.refreshGrid = function (state) {
        this.updatedShiftDetails.forEach(function (item) {
            item.Hours = parseFloat(item.Hours);
        });
        this.updatedShiftDetails.sort(function (a, b) { return a.displayId < b.displayId ? -1 : 1; });
        state.take = this.updatedShiftDetails.length;
        this.gridState.view = process(this.updatedShiftDetails, state);
        if (this.updatedShiftDetails.length == 0) {
            this.isRequestAgencyStaff = false;
        }
    };
    RequestAgencyStaffComponent.prototype.getAgencyCount = function (dataItem) {
        return this.selectedAgencyCount = dataItem['selectedAgency'].length >= 2 ? " " + dataItem['selectedAgency'].length + " agencies" : dataItem['selectedAgency'].length + " agency";
    };
    RequestAgencyStaffComponent.prototype.selectedAgencyDetails = function (dataItem) {
        return dataItem['selectedAgency'];
    };
    RequestAgencyStaffComponent.prototype.getShiftAttributes = function (dataItem, attr) {
        var attribute;
        dataItem.forEach(function (element) {
            if (element.name == attr) {
                attribute = element.attrValues[0].name;
            }
            else {
                '';
            }
        });
        return attribute;
    };
    RequestAgencyStaffComponent.prototype.senedRequestAgencyStaff = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orgdetailsId, parent_id, orgLevelId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.storeSelectedDataArr = [];
                        orgdetailsId = this.orgLevelId;
                        parent_id = this.orgLevel.parentId;
                        this.updatedShiftDetails.forEach(function (elem, i) {
                            elem.selectedAgency.forEach(function (element) {
                                element.attributes.filter(function (el) {
                                    if (el.name == 'instantAccept') {
                                        if (el.attrValues[0].name == 'No') {
                                            el.attrValues[0].name = 'false';
                                        }
                                        else if (el.attrValues[0].name == 'Yes') {
                                            el.attrValues[0].name = 'true';
                                        }
                                    }
                                });
                            });
                            var seletedAgency = elem.selectedAgency;
                            var startDateTime = moment(elem.ShiftStart).toDate();
                            var endDateTime = moment(elem.ShiftEnd).toDate();
                            var storeSelectedData = {};
                            storeSelectedData.Clientid = _this.alias;
                            storeSelectedData.SlxshiftId = elem.ShiftId;
                            storeSelectedData.SlxshiftGroupId = elem.ShiftGroupId;
                            storeSelectedData.PositionGroupId = elem.PositionGroupId;
                            storeSelectedData.JobCode = elem.JobCode;
                            storeSelectedData.JobDesc = elem.JobDescription;
                            storeSelectedData.ShiftDate = moment(_this.dateOn).format('MM/DD/YYYY');
                            storeSelectedData.ShiftStart = moment(startDateTime).format('MM/DD/YYYY HH:mm:ss');
                            storeSelectedData.ShiftEnd = moment(endDateTime).format('MM/DD/YYYY HH:mm:ss');
                            storeSelectedData.OrgLevelId = _this.orgLevel.id;
                            storeSelectedData.UnitId = _this.updatedShiftDetails[i].UnitId;
                            storeSelectedData.DepartmentId = _this.updatedShiftDetails[i].DepartmentId;
                            storeSelectedData.Hours = _this.updatedShiftDetails[i].Hours.toString();
                            storeSelectedData.shiftRequestbyId = _this.userId;
                            storeSelectedData.shiftRequestbyName = _this.userName;
                            storeSelectedData.SubTypeId = seletedAgency[0].sub_type_id.toString();
                            storeSelectedData.PartnerId = "0";
                            storeSelectedData.SubscriptionName = seletedAgency[0].sub_type_name;
                            storeSelectedData.parentId = parent_id;
                            storeSelectedData.AgencyId = 0;
                            storeSelectedData.AgencyList = elem.selectedAgency;
                            if (!moment(endDateTime).isAfter(startDateTime)) {
                                var shitEndTime = _this.updatedShiftDetails[i].ShiftEnd;
                                var addDaytoWorkDate = moment(storeSelectedData.ShiftDate).add(1, 'days').format('MM/DD/YYYY');
                                var nextday = new Date(addDaytoWorkDate + ' ' + shitEndTime);
                                storeSelectedData.ShiftEnd = moment(nextday).format('MM/DD/YYYY HH:mm:ss');
                            }
                            if (storeSelectedData.AgencyList.length > 0) {
                                _this.storeSelectedDataArr.push(storeSelectedData);
                            }
                        });
                        orgLevelId = this.orgLevel.id;
                        return [4 /*yield*/, this.management.postRequestStaffing(this.storeSelectedDataArr, orgLevelId).then(function (status) {
                                _this.shiftService.isRequestFromDua = true;
                                _this.shiftService.sendclickEvent();
                                _this.navigateToDailyUnitGrid();
                            }).catch(function (err) {
                                _this.navigateToDailyUnitGrid();
                            })];
                    case 1:
                        _a.sent();
                        this.navigateToDailyUnitGrid();
                        this.isLoading = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    RequestAgencyStaffComponent.prototype.updateMultipleShifts = function () {
        var _this = this;
        var tempMultiSelectShift = _.cloneDeep(this.multiSelectShift[0]);
        this.shiftService.editAgencyStaffRequest(tempMultiSelectShift);
        this.multiSelectShift.forEach(function (element) {
            _this.updatedShiftDetails = _this.updatedShiftDetails.filter(function (e) { return e.displayId != element.displayId; });
        });
        this.setAgencyDetails();
    };
    RequestAgencyStaffComponent.prototype.gridSelectedShift = function (selection) {
        var _this = this;
        if (selection.selectedRows.length !== 0) {
            selection.selectedRows.forEach(function (row) {
                _this.multiSelectShift.push(row.dataItem);
            });
        }
        if (selection.deselectedRows.length !== 0) {
            selection.deselectedRows.forEach(function (row) {
                _this.multiSelectShift = _this.multiSelectShift.filter(function (e) { return e.displayId !== row.dataItem.displayId; });
            });
        }
    };
    RequestAgencyStaffComponent.prototype.selectedMultiSelectShift = function () {
        if (this.multiSelectShift.length > 1) {
            this.isMultiSelectedShift = true;
        }
        else {
            this.isMultiSelectedShift = false;
        }
    };
    RequestAgencyStaffComponent.prototype.cancelRequestAgencyStaff = function () {
        this.onLoad = true;
        this.updatedShiftDetails = [];
        this.refreshGrid(this.states);
        this.navigateToDailyUnitGrid();
        this.onLoad = false;
    };
    RequestAgencyStaffComponent.prototype.isDisabledMultiselect = function () {
        if (!this.isMultiSelectedShift) {
            return this.updatedShiftDetails.length == 0;
        }
    };
    RequestAgencyStaffComponent.prototype.navigateToDailyUnitGrid = function () {
        var extras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        this.router.navigateByUrl(this.getUrlTree());
    };
    RequestAgencyStaffComponent.prototype.getUrlTree = function () {
        var params = {
            keepOrgLevelBreadcrmb: true,
            dateOn: moment(this.dateOn).format(appConfig.linkDateFormat)
        };
        return this.router.createUrlTree(['daily_unit_assignment'], {
            relativeTo: this.activatedRoute.pathFromRoot[2],
            queryParams: params
        });
    };
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], RequestAgencyStaffComponent.prototype, "user$", void 0);
    return RequestAgencyStaffComponent;
}(DailyUnitGridComponent));
export { RequestAgencyStaffComponent };
