import { Injectable } from '@angular/core';

import { OperationalConsoleEntry } from '../../../employee/employee-list/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { OperationalConsoleApiService } from './operational-console-api.service';
import { UserApplication } from '../../../state-model/models/user';
import { Subject } from 'rxjs/Subject';
import { ReportExportType } from '../../../reports/models/report-export-type';
import { LocationUnit, Shift } from '../../../organization/models/index';
import { ScheduleConsoleFilterItem } from '../../schedule-console/models/index';
import * as _ from 'lodash';

@Injectable()
export class OperationalConsoleStateService {

  public filtersChanged$: Subject<OperationalConsoleEntry[]>;
  public exportAction$: Subject<ReportExportType>;

  private m_canLoadData: boolean;
  private m_currentDate: Date;
  private m_entries: OperationalConsoleEntry[];
  private m_isLoading: boolean;
  private m_orgLevel: OrgLevel;
  private m_application: UserApplication;

  private m_unitFilter: LocationUnit[];
  private m_shiftFilter: Shift[];
  private m_consoleFilters: ScheduleConsoleFilterItem[];

  public get canLoadData(): boolean {
    return this.m_canLoadData;
  }
  public set canLoadData(value:boolean) {
    this.m_canLoadData = value;
  }

  public get currentDate(): Date {
    return this.m_currentDate;
  }
  public set currentDate(value:Date) {
    this.m_currentDate = value;
  }

  public get isLoading(): boolean {
    return this.m_isLoading;
  }
  public set isLoading(value:boolean) {
    this.m_isLoading = value;
  }

  public get entries(): OperationalConsoleEntry[] {
    return this.m_entries;
  }
  public set entries(value:OperationalConsoleEntry[]) {
    this.m_entries = value;
  }

  public get orgLevel(): OrgLevel {
    return this.m_orgLevel;
  }
  public set orgLevel(value:OrgLevel) {
    this.m_orgLevel = value;
  }

  public get application(): UserApplication {
    return this.m_application;
  }
  public set application(value:UserApplication) {
    this.m_application = value;
  }

  public get consoleFilters(): ScheduleConsoleFilterItem[] {
    return this.m_consoleFilters;
  }
  public set consoleFilters(filters:ScheduleConsoleFilterItem[]) {
    if (filters) {
      this.m_consoleFilters = filters;
    } else {
      this.m_consoleFilters = [];
    }
  }

  constructor(private apiService: OperationalConsoleApiService) {
    this.filtersChanged$ = new Subject();
    this.exportAction$ = new Subject();
    this.m_canLoadData = false;
  }

  public loadData(): void {
    if(!this.m_canLoadData) {
      return;
    }
    this.isLoading = true;
    let unitIds: number[] = _.map(this.m_unitFilter, (item: LocationUnit) => item.id);
    let shiftIds: number[] = _.map(this.m_shiftFilter, (item: Shift) => item.id);
    this.apiService.getOperationalConsole(this.orgLevel.id, this.currentDate, unitIds, shiftIds)
      .then((entries: OperationalConsoleEntry[]) => {
        this.entries = this.applyFilters(entries);
        this.filtersChanged$.next(this.entries);
        this.isLoading = false;
      });
  }

  public applyFilters(entries: OperationalConsoleEntry[]): OperationalConsoleEntry[] {
    if(!this.consoleFilters || this.consoleFilters.length === 0) {
      return entries;
    }
    const filtersNames: string[] = _.map(this.consoleFilters, (filter: ScheduleConsoleFilterItem) => filter.name);
    return _.filter(entries, (item: OperationalConsoleEntry) => {
      return item.orgLevel ? _.includes(filtersNames, item.orgLevel.name) : true;
    });
  }

  public overviewFiltersChanged(unitFilter: LocationUnit[], shiftFilter: Shift[]): void {
    this.m_unitFilter = unitFilter;
    this.m_shiftFilter = shiftFilter;
  }

  public onExportToExcel(): void {
    this.exportAction$.next(ReportExportType.Excel);
  }

  public onExportToPdf(): void {
    this.exportAction$.next(ReportExportType.Pdf);
  }
}
