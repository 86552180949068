import { Component, Input, ViewChild } from '@angular/core';

import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';

import * as _ from 'lodash';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';

@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-boolean-filter-menu',
  templateUrl: 'kendo-grid-boolean-filter-menu.component.html',
  styleUrls: ['kendo-grid-boolean-filter-menu.component.scss'],
})
export class KendoGridBooleanFilterMenuComponent {

  @Input()
  public column: ColumnComponent;

  @Input()
  public filter: CompositeFilterDescriptor;

  @Input()
  public filterService: FilterService;

  public items: any[];

  public get value(): any {
    const filter = _.find(
      kendoUtils.flatten(this.filter || {}),
      filter => filter.field === this.column.field
    );

    return filter ? filter.value : null;
  }

  constructor() {
    this.items = [
      { text: 'Y', value: true },
      { text: 'N', value: false }
    ];
  }

  public onChange(value: any): void {
    this.filterService.filter({
      filters: [{
        field: this.column.field,
        operator: 'eq',
        value
      }],
      logic: 'and'
    });
  }
}
