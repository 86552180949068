import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { NgForm } from '@angular/forms';
import { EmployeeSectionsBase, TelepunchSubsection, PhonePunchRestrictions } from '../../../models';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import * as _ from 'lodash';
import { EmployeeSectionsEmploymentApiService } from '../../../services';
var EmployeeSectionsTelepunchComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsTelepunchComponent, _super);
    function EmployeeSectionsTelepunchComponent(employeeSectionsEmploymentApiService, ngZone, decorator) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.m_employeeSectionsTelepunch = new TelepunchSubsection();
        _this.restrictionsLookup = [];
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsTelepunchComponent.prototype, "employeeSectionsTelepunch", {
        get: function () {
            return this.m_employeeSectionsTelepunch;
        },
        set: function (value) {
            this.m_employeeSectionsTelepunch = value;
            this.fillValues();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTelepunchComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsTelepunchComponent.prototype.getSubsectionModel = function () {
        return this.m_employeeSectionsTelepunch;
    };
    EmployeeSectionsTelepunchComponent.prototype.fillValues = function () {
        var _this = this;
        if (this.employeeSectionsTelepunch) {
            this.restrictionsLookup = this.employeeSectionsTelepunch.phonepunchRestrictions.fieldValue;
            var list = _.filter(this.employeeSectionsTelepunch.phonepunchRestrictions.fieldValue, function (item) { return item.id === _this.employeeSectionsTelepunch.restrictionId; });
            this.phonePunchRestrictions = list[0];
        }
    };
    EmployeeSectionsTelepunchComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmployeeTelepunch(this.employeeId)
            .then(function (employeeTelepunch) {
            _this.employeeSectionsTelepunch = employeeTelepunch;
            _this.stopProgress();
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsTelepunchComponent.prototype.updateData = function () {
        if (this.employeeSectionsTelepunch && !this.employeeSectionsTelepunch.phonepunchEnabled.fieldValue) {
            this.phonePunchRestrictions = null;
        }
    };
    EmployeeSectionsTelepunchComponent.prototype.doSave = function () {
        var _this = this;
        if (this.employeeSectionsTelepunch && !this.employeeSectionsTelepunch.phonepunchEnabled.fieldValue) {
            this.phonePunchRestrictions = new PhonePunchRestrictions();
            this.phonePunchRestrictions.id = "";
            this.phonePunchRestrictions.name = "";
        }
        this.employeeSectionsEmploymentApiService.setEmployeeTelepunch(this.employeeId, this.phonePunchRestrictions).then(function (response) {
            _this.onActionComplete(true);
        }).catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    return EmployeeSectionsTelepunchComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsTelepunchComponent };
