import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';

import { ResponseBody } from '../../../core/models/api/response-body';

import { ILogicExpression } from '../components/query-builder/index';
import { OrgLevel } from '../../../state-model/models/index';
import { employeeSearchConfig } from '../employee-search.config';

import { EmployeeSearchMapService } from './employee-search-map.service';
import { IEmployeeSearchResult } from '../models/index';

@Injectable ()
export class EmployeeSearchApiService  {
    constructor (
      private apiUtilService: ApiUtilService,
      private urlParamsService: UrlParamsService,
      private mapService: EmployeeSearchMapService) {
    }

   public search (expressionDTO: ILogicExpression, orgLevel: OrgLevel): Promise<any> {
     const url: string = `${this.apiUtilService.getApiRoot()}/${employeeSearchConfig.api.employees.root}/${employeeSearchConfig.api.employees.search}`;

     let params: HttpParams = this.urlParamsService.convertToParams({orgLevelId: orgLevel.id});

     let request: HttpRequest<any> = new HttpRequest('POST', url, {
      root: expressionDTO
     }, {
       params: params
     });

     return this.apiUtilService.request<any[], Meta>(request).then((response: ResponseBody<IEmployeeSearchResult[], Meta>) => {
       return this.mapService.mapSeachResult(response.data);
     });
   }
}
