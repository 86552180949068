import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';

import { PunchProfileManagementService } from '../../services/index';
import { IPunchProfile } from '../../../../organization/models/lookup/punch-profile';
import { AttestationType, ILookupEntity } from '../../../../organization';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPunchProfileGroup } from '../../models/punch-profile-group';
import { ActivatedRoute } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'slx-punch-profile',
  templateUrl: 'punch-profile.component.html',
  styleUrls: ['punch-profile.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchProfileComponent implements OnInit, OnDestroy {

  public isLoading: boolean;
  public orgLevel: OrgLevel;
  public attestationType: AttestationType[];
  public punchType: ILookupEntity[];

  @unsubscribe()
  private loadedSubscription: Subscription;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  private statusSubscription: Subscription;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  public punchProfile: IPunchProfile[] = [];
  punchProfileGroup$ = new BehaviorSubject<IPunchProfileGroup[]>([]);

  constructor(
    private punchProfileManagementService: PunchProfileManagementService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    if (this.route.snapshot.data) {
      const lookupData = this.route.snapshot.data[1];
      this.punchProfile = lookupData[0].items;
      this.attestationType = lookupData[1];
      this.punchType = lookupData[2].items;
    }
    this.punchProfileManagementService.getGroupsAndProfiles(0);

    this.loadedSubscription = this.punchProfileManagementService.onLoaded$.subscribe(punchProfileGroup => {
      this.punchProfileGroup$.next(punchProfileGroup);
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.statusSubscription = this.punchProfileManagementService.onLoadStatus$.subscribe(isLoading => {
      this.isLoading = isLoading;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = orgLevel;
      }
    });
  }



  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  get groups$() {
    return this.punchProfileManagementService.groups$;
  }
}
