import { Renderer2, ElementRef } from '@angular/core';
import { WeatherSettings } from '../../../app-modules/angular-weather-widget/index';
import { commonConfig } from '../../common.config';
import { OrgLevelLocation } from '../../../state-model/models/index';
import { screenUtils } from '../../utils/index';
var WeatherWidgetComponent = /** @class */ (function () {
    function WeatherWidgetComponent(renderer, elementRef) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.settings = commonConfig.weatherWidgetSettings;
        this.screenUtils = screenUtils;
    }
    Object.defineProperty(WeatherWidgetComponent.prototype, "orgLevelLocation", {
        set: function (location) {
            this.weatherExists = !!location;
            if (!this.weatherExists) {
                return;
            }
            this.currentState = location.stateAbbreviation;
            this.zipCode = location.zipCode;
            this.onUpdate();
        },
        enumerable: true,
        configurable: true
    });
    WeatherWidgetComponent.prototype.onUpdate = function () {
        if (this.zipCode) {
            this.settings.location = {};
            this.settings.location.stateAbbreviation = this.currentState;
            this.settings.location.zipCode = this.zipCode;
            this.settings = Object.assign({}, this.settings);
        }
    };
    return WeatherWidgetComponent;
}());
export { WeatherWidgetComponent };
