<div class="modal-content">
  <div class="modal-body">
    <div class="header-message" *ngIf="result.hasErrors">
        Operation can not be performed because there are errors:
    </div>
    <div class="result-message" *ngFor="let res of result?.results">
      <span  [ngClass]="{'error': res.errorCode>0}">{{res.errorMessage}}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="onCancel()" class="theme-button-cancel">Close</button>
  </div>
</div>
