import { EventEmitter } from '@angular/core';
import { ShiftOvertime } from '../../../../models/index';
import { appConfig } from '../../../../../app.config';
import { ShiftDefinition } from '../../../../../organization/models/index';
var ScheduledShiftOvertimeSectionComponent = /** @class */ (function () {
    function ScheduledShiftOvertimeSectionComponent() {
        this.index = -1;
        this.appConfig = appConfig;
        this.onOvertimeDateChanged = new EventEmitter();
        this.onOvertimeHoursChanged = new EventEmitter();
    }
    Object.defineProperty(ScheduledShiftOvertimeSectionComponent.prototype, "overtime", {
        get: function () {
            return this.m_overtime;
        },
        set: function (value) {
            this.m_overtime = value;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledShiftOvertimeSectionComponent.prototype.overtimeDateChanged = function () {
        this.onOvertimeDateChanged.next(this.m_overtime);
    };
    ScheduledShiftOvertimeSectionComponent.prototype.overtimeHoursChanged = function (value) {
        this.onOvertimeHoursChanged.next(this.m_overtime);
    };
    return ScheduledShiftOvertimeSectionComponent;
}());
export { ScheduledShiftOvertimeSectionComponent };
