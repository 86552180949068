var RoleUsersGridComponent = /** @class */ (function () {
    function RoleUsersGridComponent() {
    }
    Object.defineProperty(RoleUsersGridComponent.prototype, "users", {
        get: function () {
            return this.m_users;
        },
        set: function (value) {
            this.m_users = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RoleUsersGridComponent.prototype, "filter", {
        set: function (value) {
            this.m_filter = value;
        },
        enumerable: true,
        configurable: true
    });
    RoleUsersGridComponent.prototype.rowHasDetails = function (dataItem, index) {
        return dataItem.orgLevels && dataItem.orgLevels.length > 0;
    };
    return RoleUsersGridComponent;
}());
export { RoleUsersGridComponent };
