<ng-container *ngFor="let item of messagesList">
  <div [ngClass]="item.id === selectedId ? 'selected-msg': ''" class="msg-row" [ngStyle]="{'border-left': borderLeft}"
  [class.smsUnread]="!item.read && !isArchive" [class.archiveUnread]="!item.read && isArchive" *ngIf="!isExpanded" (click)="showMessage(item)" [attr.data-message-id]="item.id">
   <div class="msg-row-content flex">
      <div class="checkbox-btn">
        <slx-checkbox-button
            (click)="$event.stopPropagation();"
            class="slx-no-indents"
            [(ngModel)]="item.isSelected"
            (ngModelChange)="selectionChange(item, $event)"
          ></slx-checkbox-button>
      </div>
      <div class="avatar circle" [ngClass]="getDisableStatusOfemplyee(item)">
        <slx-msg-grid-thumbnail-src [employeeId]="item.employeeId" [employeeName]="item.fullName" [parent]="'messageGrid'"></slx-msg-grid-thumbnail-src>
          <span class="img-terminated-icon" *ngIf="item.status === 'Terminated'"><em class="fas fa-times"></em></span>
          <span class="img-terminated-icon" *ngIf="item.status === 'Transferred'"><em class="fa fa-exchange"></em></span>
          <span class="img-loa-icon" *ngIf="item.optIn === 1 && item.status === 'Active' && item.isOnLeave"><em
            class="far fa-calendar-minus"></em></span>
          <span class="sms-Opt-Out" *ngIf="item.optIn === 0 && item.status === 'Active'"><em
              class="fas fa-mobile-alt mobile-alt algin "></em></span>
          <span class="sms-Opt-In" *ngIf="item.optIn === 1 && item.status === 'Active' && !item.isOnLeave"><em
              class="fas fa-mobile-alt mobile-alt algin "></em></span>
          <span class="sms-Not-Responeded" *ngIf="item.optIn === 2 && item.status === 'Active'"><em
              class="fas fa-mobile-alt mobile-alt algin "></em></span>

      </div>
      <div class="second-container">
        <div class="flex">
          <div class="employee-name" [innerHTML]="item?.fullName | highlight : searchText" [ngClass]="[item.status === 'Transferred'? 'terminated-transferred': '', item.status === 'Terminated'? 'terminated-transferred': '', (item.status === 'Active' && item?.isOnLeave)? 'terminated-transferred': '']" [title]="item?.fullName"></div>
          <div class="reply"><em
            [ngClass]="[item.replyCount === 1 ? 'fas fa-reply' : '', item.replyCount > 1 ? 'fas fa-reply-all' : '']"></em></div>
        </div>
        <a class="phoneno" href="tel:{{item?.mobilePhoneNumber}}" [innerHTML]="item?.mobilePhoneNumber | convertUSformatNumber | highlight : searchFormattedNumber" [ngClass]="[item.status === 'Terminated'? 'terminated-transferred': '', item.status === 'Transferred'? 'terminated-transferred': '', (item.status === 'Active' && item?.isOnLeave)? 'terminated-transferred': '']"></a>
      </div>
      <div [innerHTML]="item?.body | highlight : searchText" class="msg-body msg-content"></div>
      <div class="date" >
        {{item.modified | convertDate}}
      </div>
  </div>
  </div>
  <div  [ngClass]="item.id === selectedId ? 'selected-msg': ''" (click)="showMessage(item)" *ngIf="isExpanded"
    class="msg-row-expanded" [class.unread]="!item.read" [attr.data-message-id]="item.id">
    <div class="msg-row-content">
      <div class="flex">
        <div class="employee-name" [innerHTML]="item?.fullName | highlight : searchText" [ngClass]="[(item.status === 'Active' && item?.isOnLeave)? 'terminated-transferred': '', item.status === 'Terminated'? 'terminated-transferred': '', item.status === 'Transferred'? 'terminated-transferred': '']" [title]="item?.fullName">

        </div>
        <div class="reply"><em
          [ngClass]="[item.replyCount === 1 ? 'fas fa-reply' : '', item.replyCount > 1 ? 'fas fa-reply-all' : '']"></em>
        </div>
      </div>

      <div [innerHTML]="item.body | highlight : searchText" class="msg-body msg-content-collapse"></div>
    </div>
  </div>
</ng-container>
