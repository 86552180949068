    <div class="report">
    <header class="report-header">
        <p class="report-emp flex align-items__center">
            <img class="report-emp-icon" [employeeThumbinalSrc]="empId" alt="Employee Photo" />
            <span class="report-emp-info flex__grow">
                <strong class="report-emp-name">{{ empName }}</strong>
                <strong class="report-emp-field">Position:&nbsp;</strong>
                <span class="report-emp-value wrapword">{{ posName }}</span>
                <strong class="report-emp-field">Facility:&nbsp;</strong>
                <span class="report-emp-value wrapword">{{ orgName }}</span>
                <strong class="report-emp-field">Department:&nbsp;</strong>
                <span class="report-emp-value wrapword">{{ depName }}</span>
            </span>
            <slx-collapse-button [isCollapsed]="isCollapsed" (isCollapsedChange)="collapsedChange($event)"
                [slxCollapseByCss]="collapseContent">
            </slx-collapse-button>
        </p>
        <div #collapseContent class="collapse-section-content">
            <div class="emp-fields-container">
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Emp Id</strong>
                    <span class="report-emp-value wrapword">{{ empId }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Emp Type</strong>
                    <span class="report-emp-value wrapword">{{ empType }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Date Hired</strong>
                    <span class="report-emp-value wrapword">{{ dateHiredStr }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Gender</strong>
                    <span class="report-emp-value wrapword">{{ gender }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Race</strong>
                    <span class="report-emp-value wrapword">{{ race }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Date Of Birth</strong>
                    <span class="report-emp-value wrapword">{{ dateOfBirthStr }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Marital Status</strong>
                    <span class="report-emp-value wrapword">{{ maritalStatus }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Dependents</strong>
                    <span class="report-emp-value wrapword">{{ dependents }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Age</strong>
                    <span class="report-emp-value wrapword">{{ age }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">Address</strong>
                    <span class="report-emp-value wrapword">{{ address }}</span>
                </div>
                <div class="emp-fields-container-item">
                    <strong class="report-emp-field">SSN</strong>
                    <span class="report-emp-value wrapword">{{ ssn }}</span>
                </div>
            </div>
        </div>
        <p *ngIf="report" class="flex report-buttons">

            <button class="slx-button slx-with-icon slx-margin-r"
            type="button" [disabled]="!report.editMode"
            (click)="onClickEmployee()">
                <i aria-hidden="true" class="fas fa-user slx-button__icon"></i>
                <span class="slx-button__text">View Profile</span>
            </button>

          <slx-static-dropdown-list *slxAccessible="{provider: 'Menu', permission: 'leave_management'}"
            class="slx-wide slx-short slx-white-style"
            [options]="leaveOptions"
            (selectItem)="createNewLOARequest($event)"
            controlIcon="fas fa-bars"
            placeholder="LOA"
            [readonly]="!report.editMode"
          ></slx-static-dropdown-list>

        </p>
    </header>
</div>
