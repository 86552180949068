import { Component, Host, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { KendoGridStateHelper } from '../../../../../common';
import { EmployeeSectionsBase, EmployeeSectionsTemporalModel } from '../../../models';
import { AgencyCertificate, AgencyCertificates } from '../../../models/employee-sections-personal/employee-sections-agency-certificate';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { process } from '@progress/kendo-data-query';
import { EmployeeSectionsPersonalApiService } from '../../../services';

@Component({
  selector: 'slx-employee-sections-agency-certificate',
  templateUrl: './employee-sections-agency-certificate.component.html',
  styleUrls: ['./employee-sections-agency-certificate.component.scss'],

})
export class EmployeeSectionsCertificateComponent extends EmployeeSectionsBasicComponent implements OnInit {

  @ViewChild('form', { static: false })
  public ngForm: NgForm;
 public agencyCertificates:AgencyCertificates

  @Input() public employeeId: number;

  @Input() 
  public set agencyCertificate(value) {
    this.agencyCertificates = value;
    this.getCurrentStatus();
}

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }
  public pageSize: number = 10;
  public gridState: KendoGridStateHelper<AgencyCertificate>;
  public dnr: boolean;

  public preferredEmp: boolean = false;
  public doNotReturn:boolean = false;
  public details: string;
  constructor(private employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService, @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone,) {
    super(decorator, ngZone);
    this.gridState = new KendoGridStateHelper<AgencyCertificate>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'changedOn', dir: 'desc' }];
  }

  ngOnInit() {
    super.ngOnInit();    
    this.loadCertificateHistroy(); 

  }
  public getSubsectionModel(): EmployeeSectionsBase {
    return this.agencyCertificates;
  }
  public loadSubsection(): void {
    this.startProgress();
    this.loadCertificateHistroy();
  

  }
  public getDisplayStatus(status: boolean): string {
    if (status) {
      return 'Yes';
    }
    else {
      return 'No';
    }
  }
  private async loadCertificateHistroy(): Promise<void> {
    this.details = '';
    this.startProgress();
    await this.employeeSectionsPersonalApiService.getAgencyCertificateDetails(this.employeeId)
      .then((agencyCertificate: AgencyCertificate[]) => {
        this.refreshGrid(agencyCertificate);
        this.stopProgress()
      })
      .catch((res: any) => {
        this.stopProgress();
      });

  }
  public getCurrentStatus(): void {

    if (this.agencyCertificates != null && (this.agencyCertificates.agencyCertificate.length>0)) {
      this.doNotReturn = this.agencyCertificates.agencyCertificate[0].dnr;
      this.preferredEmp = this.agencyCertificates.agencyCertificate[0].preferred;
    }
  
  }
  private refreshGrid(agencyCertificate: AgencyCertificate[]): void {
    if(agencyCertificate.length>0){
      this.doNotReturn =  agencyCertificate[0].dnr;
      this.preferredEmp = agencyCertificate[0].preferred;

    }
    else {
      this.doNotReturn = false;
      this.preferredEmp = false;
    }
    this.gridState.view = process(agencyCertificate, this.gridState.state);
  }
  public verifyDNRStateChange(value) {
    this.details = '';
    this.doNotReturn = value;
    this.preferredEmp = this.doNotReturn == true ? false : this.preferredEmp;
   }
  public verifyPreferredStateChange(value) {
    this.details = '';
    this.preferredEmp =value;
    this.doNotReturn = this.preferredEmp == true ? false : this.doNotReturn;
   
  }

  public verifyDisabledState(): boolean {
    if (this.doNotReturn) {
      return false;
    }
    return true;
  }
  protected doSave(): void {
    this.startProgress();
    let certificate: AgencyCertificate = new AgencyCertificate();
    certificate.dnr = this.doNotReturn;
    certificate.preferred = this.preferredEmp;
    certificate.reason = this.details;
    this.employeeSectionsPersonalApiService.saveAgencyCertificate(certificate, this.employeeId)
      .then((status: any) => {
        this.onActionComplete(true);
        this.stopProgress();
      })
      .catch((reason: any) => {
        this.onActionError(reason);
        this.stopProgress();
      });

  }
  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    return this.metaFieldsTemporalDirtyChecker(this.agencyCertificate);
  }
  public verifyDnrState(): boolean {
    if (!this.state.isEditMode) {
      return true;
    }
    return false;
  }
  public getMinLength(): string {
   if(this.doNotReturn) {
      return '50';
    }
    else {
      return '0';
    }
  }
}


