import { PerformanceReviewCode, IPerformanceReviewCode } from '../../../organization/models';

export interface IOrgLevelObject {
    id: number;
    name: string;
}
export class OrgLevelObject {
    id: number;
    name: string;
}

export interface ICfgPmCode extends IPerformanceReviewCode {
    orgLevel: IOrgLevelObject;
    addedBy: string;
    updatedBy: string;
    addedOn: string;
    updatedOn: string;
    useCount: number;
}

export class CfgPmCode extends PerformanceReviewCode {

    public get modifiedUserName(): string {
        return this.updatedBy || this.addedBy;
    }

    public get modifiedDate(): Date {
        return this.updatedOn || this.addedOn;
    }

    public get typeName(): string {
        return this.type ? this.type.description : '';
    }

    public orgLevel: OrgLevelObject;
    public addedBy: string;
    public addedOn: Date;
    public updatedBy: string;
    public updatedOn: Date;
    public useCount: number;
}