import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit, Renderer2 } from '@angular/core';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { Observable, Subscription } from 'rxjs';
import { AccrualPolicyDropDown } from '../../models/index';
import * as _ from 'lodash';
import { AccrualsPolicyApiService } from '../../services/accruals-policy/accruals-policy-api.service';
import { FileService } from './../../../../common/services/file/file.service';
import { AppSettingsManageService } from './../../../../../app/app-settings/services';
import { FormGroup, FormControl, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { AccrualsPolicyMapService } from '../../services/accruals-policy/accruals-policy-map.service';
import { NotificationsService } from './../../../../../app/core/components';
import { ConfirmDialogComponent, ConfirmOptions, ModalAnchorDirective, ModalService } from './../../../../../app/common';
import { appConfig } from './../../../../../app/app.config';
import { AccrualPolicyDialogInfo } from '../../models/accruals-policy/accrual-policy-dialog-info';
import { AccrualPolicyManagementService } from '../../services/accruals-policy/accrual-policy-management.service';
import { LocalStorageService } from './../../../../../app/core/services';
import { carryOverTypeValidator, dateRangeValidator } from '../../pipes/date-range.validator';
var AccrualsPolicyComponent = /** @class */ (function () {
    function AccrualsPolicyComponent(accrualsPolicyDefinitionsApiService, manService, fileService, appSettingManageService, fb, accrualPolicyMapService, modalService, notificationService, cdRef, localService, renderer) {
        var _this = this;
        this.accrualsPolicyDefinitionsApiService = accrualsPolicyDefinitionsApiService;
        this.manService = manService;
        this.fileService = fileService;
        this.appSettingManageService = appSettingManageService;
        this.fb = fb;
        this.accrualPolicyMapService = accrualPolicyMapService;
        this.modalService = modalService;
        this.notificationService = notificationService;
        this.cdRef = cdRef;
        this.localService = localService;
        this.renderer = renderer;
        this.isNewPolicy = false;
        this.errorMessage = "";
        this.isShow = false;
        this.accrualPolicies = [];
        this.accrualRules = [];
        this.originalAccrualRules = [];
        this.isLoading = false;
        this.isPolicyLoading = false;
        this.isExpanded = false;
        this.isBenefitTypeVisible = false;
        this.isViewed = 0;
        this.isActive = false;
        this.isEditActive = false;
        this.m_minimumDate = new Date("1/1/2014");
        this.m_maximumDate = new Date("6/6/2079");
        this.sendData = [];
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.accDropDown = AccrualPolicyDropDown;
        this.dialogInfo = AccrualPolicyDialogInfo;
        this.renderer.listen('window', 'click', function (e) {
            if (!_this.manService.menuBtnClick) {
                _this.isShow = false;
                _this.manService.isActive = false;
            }
            _this.manService.menuBtnClick = false;
        });
    }
    AccrualsPolicyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
            _this.orgLevelName = orgLevel.name;
            _this.bindOnLoad();
            _this.getSettings();
            if (_this.manService.isDisabled) {
                _this.manService.isDisabled = false;
            }
        });
    };
    AccrualsPolicyComponent.prototype.ngAfterViewChecked = function () {
        this.cdRef.detectChanges();
    };
    AccrualsPolicyComponent.prototype.onSectionExpandChanged = function (accrualPolicyFormGroup, policyId) {
        var _this = this;
        this.isPolicyLoading = true;
        accrualPolicyFormGroup.get('expanded').setValue(!accrualPolicyFormGroup.get('expanded').value);
        setTimeout(function () {
            _this.isPolicyLoading = false;
        }, 1000);
    };
    AccrualsPolicyComponent.prototype.onExpandCollapse = function (isExpand) {
        var _this = this;
        this.isLoading = true;
        setTimeout(function () {
            _this.isLoading = false;
        }, 1000);
        var policiesArray = this.policyForm.get('policies');
        policiesArray.controls.forEach(function (control) {
            control.get('expanded').setValue(isExpand);
        });
    };
    AccrualsPolicyComponent.prototype.bindOnLoad = function () {
        var _this = this;
        if (this.orgLevelId) {
            this.isLoading = true;
            this.accrualsPolicyDefinitionsApiService.getAccrualPolicyDetailed(this.orgLevelId)
                .then(function (accrualPolicyDefinition) {
                _this.isLoading = false;
                _this.accrualPolicies = accrualPolicyDefinition.accrualPolicy;
                _this.manService.accrualPoliciesData = _this.accrualPolicies;
                _this.validateExistingPolicyNames();
                _this.manService.accrualPolicies = accrualPolicyDefinition.accrualPolicy;
                _this.accrualActions = accrualPolicyDefinition.actions;
                _this.isCreateorEditAccrualsRolePermission = accrualPolicyDefinition.CreateorEditAccruals;
                _this.isDeleteAccrualsRolePermission = accrualPolicyDefinition.DeleteAccruals;
                _this.isCopyAccrualsRolePermission = accrualPolicyDefinition.CopyAccrualPolicy;
                _this.buildAndLoadForm();
            });
        }
    };
    AccrualsPolicyComponent.prototype.buildAndLoadForm = function () {
        this.policyForm = new FormGroup({
            policies: new FormArray([])
        });
        this.fetchFormValues(this.accrualPolicies);
    };
    AccrualsPolicyComponent.prototype.fetchFormValues = function (data) {
        var _this = this;
        var policiesArray = this.policyForm.get('policies');
        data.forEach(function (policy) {
            var policyGroup = _this.patchForm(policy);
            policiesArray.push(policyGroup);
        });
    };
    AccrualsPolicyComponent.prototype.patchForm = function (data) {
        return this.fb.group({
            id: new FormControl(data.id),
            policyName: new FormControl(data.policyName),
            orgLevelId: new FormControl(data.orgLevelId),
            policyStartDate: new FormControl(data.policyStartDate),
            policyEndDate: new FormControl(data.policyEndDate),
            rules: this.patchRulesArray(data.rules),
            // for ui purpose only
            oldPolicyName: new FormControl(data.policyName),
            expanded: new FormControl(this.isExpanded),
            areRulesPresent: new FormControl(data.rules.length > 0),
            isPolicyViewMode: new FormControl(true),
            isNewPolicy: new FormControl(this.isNewPolicy)
        });
    };
    AccrualsPolicyComponent.prototype.patchRulesArray = function (rules) {
        var _this = this;
        var rulesFormArray = new FormArray([]);
        rules.forEach(function (c) {
            rulesFormArray.push(_this.fb.group({
                id: new FormControl(c.id),
                ruleName: new FormControl(c.ruleName),
                accrualTypeId: new FormControl(c.accrualTypeId),
                accrualTypeName: new FormControl(c.accrualTypeName),
                startDate: new FormControl(c.startDate),
                endDate: new FormControl(c.endDate),
                anchorDateSource: new FormControl(c.anchorDateSource),
                accrualCalcType: new FormControl(c.accrualCalcType),
                accrualCalcTypeName: new FormControl(c.accrualCalcTypeName),
                accruedOnPeriod: new FormControl(c.accruedOnPeriod),
                maxBasisHours: new FormControl(c.maxBasisHours),
                minimumRequiredHours: new FormControl(c.minimumRequiredHours),
                deferralPeriodValue: new FormControl(c.deferralPeriodValue),
                deferralPeriodType: new FormControl(c.deferralPeriodType),
                probationValue: new FormControl(c.probationValue),
                probationType: new FormControl(c.probationType),
                maxAnnualEarnedType: new FormControl(c.maxAnnualEarnedType),
                rollOverMax: new FormControl(c.rollOverMax),
                deleted: new FormControl(c.deleted),
                rateFormula: new FormControl(c.rateFormula),
                carryover: new FormControl(c.carryover),
                carryoverDay: new FormControl(c.carryoverDay),
                carryoverType: new FormControl(c.carryoverType),
                carryoverValue: new FormControl(c.carryoverValue),
                carryoverToAccrualTypeName: new FormControl(c.carryoverToAccrualTypeName),
                isBenefitViewMode: true,
                oldPolicyName: new FormControl(_this.oldPolicyName),
                oldBenefitName: new FormControl(_this.oldBenefitName),
                rdoAccrualsTypePeriod: new FormControl(_this.rdoAccrualsTypePeriod),
                rdoAccrualsTypeDates: new FormControl(_this.rdoAccrualsTypeDates),
                addNewBenefit: new FormControl(false),
                seniorityRules: _this.patchSeniorityRulesArray(c.seniorityRules),
                //ui purpose only
                areSeniorityRulesPresent: new FormControl(c.seniorityRules.length > 0)
            }));
        });
        return rulesFormArray;
    };
    AccrualsPolicyComponent.prototype.patchSeniorityRulesArray = function (seniorityRules) {
        var _this = this;
        var seniorityRulesFormArray = new FormArray([]);
        seniorityRules.forEach(function (d) {
            seniorityRulesFormArray.push(_this.fb.group({
                id: new FormControl(d.id),
                ruleId: new FormControl(d.ruleId),
                periodFromValue: new FormControl(d.periodFromValue),
                periodFromType: new FormControl(d.periodFromType),
                periodToValue: new FormControl(d.periodToValue),
                periodToType: new FormControl(d.periodToType),
                accrualRate: new FormControl(d.accrualRate),
                rateUnit: new FormControl(d.rateUnit),
                basisUnit: new FormControl(d.basisUnit),
                rateFormula: new FormControl(d.rateFormula),
                accruedOnDates: new FormControl(d.accruedOnDates),
                minRequiredHours: new FormControl(d.minRequiredHours),
                accruedOnDay: new FormControl(d.accruedOnDay),
                maxAccruedAnnual: new FormControl(d.maxAccruedAnnual),
                maxAccruedBalance: new FormControl(d.maxAccruedBalance),
                carryoverValue: new FormControl(d.carryoverValue),
                carryoverType: new FormControl(d.carryoverType),
                carryoverDay: new FormControl(d.carryoverDay),
                carryoverValueType: new FormControl(d.carryoverValueType),
                carryoverExpirationDays: new FormControl(d.carryoverExpirationDays),
                carryoverToAccrualTypeId: new FormControl(d.carryoverToAccrualTypeId),
                carryoverToAccrualTypeName: new FormControl(d.carryoverToAccrualTypeName),
                ruleCalcType: new FormControl(d.ruleCalcType),
                rollOverMax: new FormControl(d.rollOverMax),
                accruedOn_Seniority: new FormControl(d.accruedOn_Seniority),
                basisHour_Seniority: new FormControl(d.basisHour_Seniority)
            }));
        });
        return seniorityRulesFormArray;
    };
    AccrualsPolicyComponent.prototype.addBenefit = function (accrualPolicyFormGroup, rules, index) {
        document.getElementById("myDropdown").classList.toggle("show");
        this.manService.isShow = false;
        var rulesFormArray = accrualPolicyFormGroup.controls['rules'];
        var rulesFormGroup = rulesFormArray.controls[index];
        if (!rulesFormGroup.get('isBenefitViewMode').value) {
            rulesFormGroup.get('isBenefitViewMode').setValue(true);
            rulesFormGroup.controls.startDate.setErrors(null);
            rulesFormGroup.controls.endDate.setErrors(null);
            this.disabled = false;
        }
        rulesFormArray.push(this.fb.group({
            id: new FormControl(1),
            ruleName: new FormControl(''),
            accrualTypeId: new FormControl(''),
            accrualTypeName: new FormControl('', [Validators.required, this.manService.noWhitespaceValidator]),
            startDate: new FormControl(null),
            endDate: new FormControl(null),
            anchorDateSource: new FormControl(''),
            accrualCalcType: new FormControl(1),
            accrualCalcTypeName: new FormControl(''),
            accruedOnPeriod: new FormControl('Pay Period'),
            maxBasisHours: new FormControl('', [Validators.min(1), Validators.max(999)]),
            minimumRequiredHours: new FormControl(''),
            deferralPeriodValue: new FormControl(''),
            deferralPeriodType: new FormControl(''),
            probationValue: new FormControl(''),
            probationType: new FormControl(''),
            maxAnnualEarnedType: new FormControl(''),
            rollOverMax: new FormControl(''),
            deleted: new FormControl(''),
            rateFormula: new FormControl(''),
            carryover: new FormControl(''),
            carryoverDay: new FormControl('', [carryOverTypeValidator]),
            carryoverType: new FormControl('Entered Value'),
            carryoverValue: new FormControl(''),
            carryoverToAccrualTypeName: new FormControl(''),
            isBenefitViewMode: false,
            oldPolicyName: this.oldPolicyName,
            oldBenefitName: this.oldBenefitName,
            rdoAccrualsTypePeriod: new FormControl(this.rdoAccrualsTypePeriod),
            rdoAccrualsTypeDates: new FormControl(this.rdoAccrualsTypeDates),
            addNewBenefit: true,
            seniorityRules: this.fb.array([this.fb.group({
                    id: new FormControl(1),
                    ruleId: new FormControl(''),
                    periodFromValue: new FormControl(''),
                    periodFromType: new FormControl('Days'),
                    periodToValue: new FormControl(''),
                    periodToType: new FormControl('Months'),
                    accrualRate: new FormControl(''),
                    rateUnit: new FormControl('hours'),
                    basisUnit: new FormControl('Hour'),
                    rateFormula: new FormControl(''),
                    accruedOnDates: new FormControl(''),
                    minRequiredHours: new FormControl(''),
                    accruedOnDay: new FormControl(''),
                    maxAccruedAnnual: new FormControl(''),
                    maxAccruedBalance: new FormControl(''),
                    carryoverValue: new FormControl(''),
                    carryoverType: new FormControl('Entered Value'),
                    carryoverDay: new FormControl(''),
                    carryoverValueType: new FormControl(''),
                    carryoverExpirationDays: new FormControl(''),
                    carryoverToAccrualTypeId: new FormControl(''),
                    carryoverToAccrualTypeName: new FormControl(''),
                    ruleCalcType: new FormControl(''),
                    rollOverMax: new FormControl(''),
                    accruedOn_Seniority: new FormControl('Pay Period'),
                    basisHour_Seniority: new FormControl('')
                })])
        }, { validator: dateRangeValidator }));
    };
    // Add Seniority Band under the Benefit Type
    AccrualsPolicyComponent.prototype.addseniorityRule = function (accrualPolicyFormGroup, index, srIndex) {
        var rulesFormArray = accrualPolicyFormGroup.controls['rules'];
        var seniorityRulesFormGroup = rulesFormArray.controls[index];
        var seniorityRulesFormArray = seniorityRulesFormGroup.controls['seniorityRules'];
        seniorityRulesFormArray.push(this.fb.group({
            id: new FormControl(seniorityRulesFormArray.length + 1),
            ruleId: new FormControl(0),
            periodFromValue: new FormControl(''),
            periodFromType: new FormControl('Days'),
            periodToValue: new FormControl(''),
            periodToType: new FormControl('Months'),
            accrualRate: new FormControl(''),
            rateUnit: new FormControl('hours'),
            basisUnit: new FormControl('Hour'),
            rateFormula: new FormControl(''),
            accruedOnDates: new FormControl(''),
            minRequiredHours: new FormControl(''),
            accruedOnDay: new FormControl(''),
            maxAccruedAnnual: new FormControl(''),
            maxAccruedBalance: new FormControl(''),
            carryoverValue: new FormControl(''),
            carryoverType: new FormControl('Entered Value'),
            carryoverDay: new FormControl(''),
            carryoverValueType: new FormControl(''),
            carryoverExpirationDays: new FormControl(''),
            carryoverToAccrualTypeId: new FormControl(''),
            carryoverToAccrualTypeName: new FormControl(''),
            ruleCalcType: new FormControl(''),
            rollOverMax: new FormControl(''),
            accruedOn_Seniority: new FormControl('Pay Period'),
            basisHour_Seniority: new FormControl('')
        }));
    };
    AccrualsPolicyComponent.prototype.removeSeniorityRule = function (accrualPolicyFormGroup, childIndex, childParentIndex) {
        var rulesFormArray = accrualPolicyFormGroup.controls['rules'];
        var seniorityRulesFormGroup = rulesFormArray.controls[childIndex];
        var seniorityRulesFormArray = seniorityRulesFormGroup.controls['seniorityRules'];
        seniorityRulesFormArray.removeAt(childParentIndex);
    };
    AccrualsPolicyComponent.prototype.createPolicyForm = function () {
        this.isNewPolicy = true;
        this.manService.isShow = false;
        this.policyForm = new FormGroup({
            policies: new FormArray([
                this.fb.group({
                    id: new FormControl(1),
                    policyName: new FormControl('', [Validators.required, this.manService.noWhitespaceValidator, this.manService.policyNameValidator]),
                    orgLevelId: new FormControl(this.orgLevelId),
                    lastUpdateId: new FormControl(''),
                    lastUpdateDate: new FormControl(''),
                    policyStartDate: new FormControl(''),
                    policyEndDate: new FormControl(''),
                    expanded: new FormControl(true),
                    isNewPolicy: new FormControl(this.isNewPolicy),
                    rules: this.fb.array([this.fb.group({
                            id: new FormControl(1),
                            ruleName: new FormControl(''),
                            accrualTypeId: new FormControl(''),
                            accrualTypeName: new FormControl('', [Validators.required, this.manService.noWhitespaceValidator]),
                            startDate: new FormControl(null),
                            endDate: new FormControl(null),
                            anchorDateSource: new FormControl(''),
                            accrualCalcType: new FormControl(1),
                            accrualCalcTypeName: new FormControl(''),
                            accruedOnPeriod: new FormControl('Pay Period'),
                            maxBasisHours: new FormControl(''),
                            minimumRequiredHours: new FormControl(''),
                            deferralPeriodValue: new FormControl(''),
                            deferralPeriodType: new FormControl(''),
                            probationValue: new FormControl(''),
                            probationType: new FormControl(''),
                            maxAnnualEarnedType: new FormControl('Anniversary Year'),
                            rollOverMax: new FormControl(''),
                            deleted: new FormControl(''),
                            rateFormula: new FormControl(''),
                            carryover: new FormControl(''),
                            carryoverDay: new FormControl('', [carryOverTypeValidator]),
                            carryoverType: new FormControl('Entered Value'),
                            carryoverValue: new FormControl(''),
                            carryoverToAccrualTypeName: new FormControl(''),
                            isBenefitViewMode: false,
                            addNewBenefit: true,
                            rdoAccrualsTypePeriod: new FormControl(''),
                            rdoAccrualsTypeDates: new FormControl(''),
                            oldPolicyName: null,
                            oldBenefitName: null,
                            seniorityRules: this.fb.array([this.fb.group({
                                    id: new FormControl(1),
                                    ruleId: new FormControl(0),
                                    periodFromValue: new FormControl(''),
                                    periodFromType: new FormControl('Days'),
                                    periodToValue: new FormControl(''),
                                    periodToType: new FormControl('Months'),
                                    accrualRate: new FormControl(''),
                                    rateUnit: new FormControl('hours'),
                                    basisUnit: new FormControl('Hour'),
                                    rateFormula: new FormControl(''),
                                    accruedOnDates: new FormControl(''),
                                    minRequiredHours: new FormControl(''),
                                    accruedOnDay: new FormControl(''),
                                    maxAccruedAnnual: new FormControl(''),
                                    maxAccruedBalance: new FormControl(''),
                                    carryoverValue: new FormControl(''),
                                    carryoverType: new FormControl('Entered Value'),
                                    carryoverDay: new FormControl(''),
                                    carryoverValueType: new FormControl(''),
                                    carryoverExpirationDays: new FormControl(''),
                                    carryoverToAccrualTypeId: new FormControl(''),
                                    carryoverToAccrualTypeName: new FormControl(''),
                                    ruleCalcType: new FormControl(''),
                                    rollOverMax: new FormControl(''),
                                    accruedOn_Seniority: new FormControl('Pay Period'),
                                    basisHour_Seniority: new FormControl('')
                                })])
                        }, { validator: dateRangeValidator })
                    ])
                })
            ])
        });
    };
    AccrualsPolicyComponent.prototype.generateDynamicUniqId = function (items) {
        var key = _.map(items, function (item) {
            return item.headerName;
        }).join('_');
        return this.orgLevelId + "_" + key;
    };
    AccrualsPolicyComponent.prototype.getAccrualPolicy = function (accrualPolicyFormGroup, rulesFormGroup) {
        var _this = this;
        if (this.orgLevelId) {
            var policyId = accrualPolicyFormGroup.get('id').value;
            this.accrualsPolicyDefinitionsApiService.getAccrualPolicy(this.orgLevelId, policyId)
                .then(function (accrualPolicy) {
                var rulesData = accrualPolicy.rules;
                _this.localService.set("Rules", rulesData);
                _this.getAllOtherHoursDropDownItem(rulesData);
            });
        }
    };
    AccrualsPolicyComponent.prototype.getAllOtherHoursDropDownItem = function (items) {
        var _this = this;
        var data = [''];
        items.forEach(function (x) {
            if (x.id != _this.ruleId) {
                data.push(x.accrualTypeName);
            }
        });
        this.allOtherHoursDropDown = new Set(data);
    };
    AccrualsPolicyComponent.prototype.newBenefitDropdown = function () {
        var data = [''];
        var rulesData = this.localService.get("Rules");
        rulesData.forEach(function (x) {
            data.push(x.accrualTypeName);
        });
        this.allOtherHoursDropDown = new Set(data);
    };
    // Populate Accrual Policy in the Reactive Form UI
    AccrualsPolicyComponent.prototype.accrualPoliciesFormArray = function () {
        var policiesArray = this.policyForm.get('policies');
        return policiesArray.controls;
    };
    // Populate Benefit types in the Reactive Form UI
    AccrualsPolicyComponent.prototype.rule = function (accrualPolicyFormGroup) {
        var rulesFormArray = accrualPolicyFormGroup.controls['rules'];
        return rulesFormArray.controls;
    };
    // Populate Seniority Band Based on Benefit Types in the Reactive Form UI
    AccrualsPolicyComponent.prototype.seniorityRule = function (accrualPolicyFormGroup, index) {
        var rulesFormArray = accrualPolicyFormGroup.controls['rules'];
        var seniorityRulesFormGroup = rulesFormArray.controls[index];
        var seniorityRulesFormArray = seniorityRulesFormGroup.controls['seniorityRules'];
        return seniorityRulesFormArray.controls;
    };
    // Edit the Existing Policy
    AccrualsPolicyComponent.prototype.editPolicy = function (accrualPolicy) {
        accrualPolicy.get('isPolicyViewMode').setValue(false);
        this.isDisabled = true;
        this.localService.remove('Policy Names');
        var policyName = [];
        this.accrualPolicies.forEach(function (x) {
            if (x.policyName != accrualPolicy.get('policyName').value) {
                policyName.push(x.policyName);
            }
        });
        this.localService.set('Policy Names', policyName);
    };
    AccrualsPolicyComponent.prototype.existingPolicyCheck = function (e, accrualPolicy) {
        var accrualPolicyNameControl = accrualPolicy.controls['policyName'];
        accrualPolicyNameControl.setValidators([this.manService.policyNameValidator]);
    };
    AccrualsPolicyComponent.prototype.cancelPolicy = function (accrualPolicyFormGroup) {
        var _this = this;
        accrualPolicyFormGroup.get('isPolicyViewMode').setValue(true);
        this.isDisabled = false;
        this.isPolicyLoading = true;
        setTimeout(function () {
            _this.isPolicyLoading = false;
        }, 1000);
        if (accrualPolicyFormGroup.dirty) {
            var policyId = accrualPolicyFormGroup.get('id').value;
            for (var i in this.accrualPolicies) {
                if (this.accrualPolicies[i].id == policyId) {
                    this.isExpanded = true;
                    var data = this.patchForm(this.accrualPolicies[i]);
                    setTimeout(function () {
                        _this.validateExistingPolicyNames();
                    }, 2000);
                    return accrualPolicyFormGroup.reset(data.value);
                }
            }
        }
    };
    AccrualsPolicyComponent.prototype.savePolicyChanges = function (accrualPolicyFormGroup) {
        var _this = this;
        if (this.policyForm.valid) {
            accrualPolicyFormGroup.get('isPolicyViewMode').setValue(true);
            this.isDisabled = false;
            this.isLoading = true;
            var AccrualPolicyName = this.accrualPolicyMapService.updatePolicyName(accrualPolicyFormGroup.value);
            this.accrualsPolicyDefinitionsApiService.updatePolicyName(this.orgLevelId, AccrualPolicyName).then(function (res) {
                if (res.data !== null && res.data == 'Policy name updated successfully') {
                    _this.notificationService.success('Updated', res.data);
                    _this.bindOnLoad();
                }
                else {
                    _this.notificationService.error('Error', res.data);
                    _this.bindOnLoad();
                }
            }).catch(function (err) {
                console.log(err);
                _this.bindOnLoad();
            });
            this.isShow = !this.isShow;
            this.disabled = false;
        }
        else {
            console.log(this.policyForm);
            this.notificationService.info('Policy Form Invalid', 'Missing Required Fields');
        }
    };
    AccrualsPolicyComponent.prototype.deletePolicy = function (accrualPolicy) {
        var _this = this;
        var orgLevelId = this.orgLevelId;
        var policyName = this.accrualPolicyMapService.deletePolicy(accrualPolicy.value);
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Warning', 'These policy will be deleted, Would you like to continue?', this.modalService, function (result) {
            if (result) {
                _this.isPolicyLoading = true;
                _this.accrualsPolicyDefinitionsApiService.deleteAccrualPolicy(orgLevelId, policyName).then(function (res) {
                    _this.bindOnLoad();
                    _this.notificationService.info('Delete Info', res.data);
                    _this.isPolicyLoading = false;
                }).catch(function (err) {
                    console.log(err);
                    _this.bindOnLoad();
                });
            }
        }, options);
        if (!this.manService.isShow) {
            this.manService.isShow = true;
        }
    };
    AccrualsPolicyComponent.prototype.deleteBenefit = function (accrualPolicy, accrualBenefit) {
        var _this = this;
        var orgLevelId = this.orgLevelId;
        var obj = {
            Accrual_Policy_Id: accrualBenefit.get('id').value,
            policyName: accrualPolicy.get('policyName').value,
            Benefit_Type: accrualBenefit.get('accrualTypeName').value
        };
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Warning', 'These policy Benefit will be deleted, Would you like to continue?', this.modalService, function (result) {
            if (result) {
                _this.isPolicyLoading = true;
                _this.accrualsPolicyDefinitionsApiService.deleteAccrualBenefit(orgLevelId, obj).then(function (res) {
                    _this.bindOnLoad();
                    _this.notificationService.info('Delete Info', res.data);
                    _this.isPolicyLoading = false;
                }).catch(function (err) {
                    console.log(err);
                    _this.bindOnLoad();
                });
            }
        }, options);
        if (!this.manService.isShow) {
            this.manService.isShow = true;
        }
        this.manService.dropDownClose(accrualBenefit);
    };
    AccrualsPolicyComponent.prototype.editRule = function (accrualPolicyFormGroup, ruleFormGroup, ruleIndex) {
        ruleFormGroup.get('isBenefitViewMode').setValue(false);
        accrualPolicyFormGroup.get('isPolicyViewMode').setValue(true);
        this.oldPolicyName = accrualPolicyFormGroup.get('policyName').value;
        this.oldBenefitName = ruleFormGroup.get('accrualTypeName').value;
        this.isDisabled = true;
        this.isEditActive = !this.isEditActive;
        this.ruleId = ruleFormGroup.get('id').value;
        this.getAccrualPolicy(accrualPolicyFormGroup, ruleFormGroup);
        var sr = ruleFormGroup.value;
        if (sr.seniorityRules.length == 0) {
            this.addseniorityRule(accrualPolicyFormGroup, ruleIndex, null);
        }
        if (this.manService.isShow) {
            this.manService.isShow = false;
        }
    };
    AccrualsPolicyComponent.prototype.cancelRule = function (accrualPolicyFormGroup, ruleFormGroup, index) {
        var rulesFormArray = accrualPolicyFormGroup.controls['rules'];
        var seniorityRulesFormGroup = rulesFormArray.controls[index];
        var seniorityRulesFormArray = seniorityRulesFormGroup.controls['seniorityRules'];
        ruleFormGroup.get('isBenefitViewMode').setValue(true);
        this.isDisabled = false;
        this.isNewPolicy = false;
        this.isExpanded = false;
        this.manService.dropDownClose(ruleFormGroup);
        this.clearAll();
        this.bindOnLoad();
        var invalidIndex = seniorityRulesFormArray.controls.findIndex(function (control) { return control.get('id').value == ''; });
        if (invalidIndex != -1) {
            this.removeSeniorityRule(accrualPolicyFormGroup, index, invalidIndex);
        }
        if (accrualPolicyFormGroup.dirty) {
            var policyId = accrualPolicyFormGroup.get('id').value;
            for (var i in this.accrualPolicies) {
                if (this.accrualPolicies[i].id == policyId) {
                    this.isExpanded = false;
                    var data = this.patchForm(this.accrualPolicies[i]);
                    this.manService.dropDownClose(ruleFormGroup);
                    return accrualPolicyFormGroup.reset(data.value);
                }
            }
        }
        if (!this.manService.isShow) {
            this.manService.isShow = true;
        }
    };
    AccrualsPolicyComponent.prototype.saveRuleChanges = function (accrualPolicyFormGroup, ruleFormGroup) {
        var _this = this;
        if (this.policyForm.valid) {
            ruleFormGroup.get('isBenefitViewMode').setValue(true);
            accrualPolicyFormGroup.get('policyStartDate').setValue(ruleFormGroup.get('startDate').value);
            accrualPolicyFormGroup.get('policyEndDate').setValue(ruleFormGroup.get('endDate').value);
            var newBenefitId_1 = ruleFormGroup.get('id').value;
            this.manService.setRequiredValues(accrualPolicyFormGroup, ruleFormGroup);
            this.isDisabled = false;
            this.isLoading = true;
            if (ruleFormGroup.get('addNewBenefit').value) {
                ruleFormGroup.get('oldBenefitName').setValue(null);
            }
            var AccrualPolicy_1 = this.accrualPolicyMapService.reverseMapAccrualPolicy(accrualPolicyFormGroup.value);
            AccrualPolicy_1.rules.forEach(function (x) {
                if (x.id == _this.ruleId && !ruleFormGroup.get('addNewBenefit').value) {
                    if (!accrualPolicyFormGroup.get('isNewPolicy').value) {
                        x.oldPolicyName = _this.oldPolicyName;
                        x.oldBenefitName = _this.oldBenefitName;
                    }
                    AccrualPolicy_1.rules = [x];
                }
                if (x.id == newBenefitId_1 && ruleFormGroup.get('addNewBenefit').value) {
                    AccrualPolicy_1.rules = [x];
                }
            });
            this.accrualsPolicyDefinitionsApiService.editAccrualPolicy(this.orgLevelId, AccrualPolicy_1).then(function (res) {
                if (res == 'Record Saved Successfully.') {
                    if (accrualPolicyFormGroup.get('isNewPolicy').value) {
                        _this.notificationService.success('Policy Created', res);
                        _this.isNewPolicy = false;
                    }
                    else {
                        _this.notificationService.success('Updated', res);
                        _this.isNewPolicy = false;
                    }
                    _this.bindOnLoad();
                }
                else {
                    _this.notificationService.error('Error', res);
                    _this.isNewPolicy = false;
                    _this.bindOnLoad();
                }
            }).catch(function (err) {
                console.log(err);
                _this.isNewPolicy = false;
                _this.bindOnLoad();
            });
            if (!this.manService.isShow) {
                this.manService.isShow = true;
            }
            this.manService.dropDownClose(ruleFormGroup);
            this.clearAll();
        }
        else {
            console.log(this.policyForm);
            this.notificationService.info('Policy Form Invalid', 'Missing Required Fields');
        }
    };
    AccrualsPolicyComponent.prototype.onClickExportExcel = function () {
        var _this = this;
        this.isLoading = true;
        this.accrualsPolicyDefinitionsApiService.getAccrualPolicyExportToExcel(this.orgLevelId)
            .then(function (file) {
            _this.fileService.saveToFileSystem(file.blob, file.file);
            _this.isLoading = false;
        }).catch(function (reason) {
            _this.isLoading = false;
        });
    };
    AccrualsPolicyComponent.prototype.onClickExportPdf = function () {
        var _this = this;
        this.isLoading = true;
        this.accrualsPolicyDefinitionsApiService.getAccrualPolicyExportToPdf(this.orgLevelId)
            .then(function (file) {
            _this.fileService.saveToFileSystem(file.blob, file.file);
            _this.isLoading = false;
        }).catch(function (reason) {
            _this.isLoading = false;
        });
    };
    AccrualsPolicyComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.isCreateorEditPolicy = appServerConfig.CreateorEditPolicy;
                        this.isDeletePolicy = appServerConfig.Deletepolicy;
                        this.isCopyPolicy = appServerConfig.CopyAccrualPolicy;
                        this.isAbilitytoChangeAccrualRuleTypeBasedonSeniority = appServerConfig.AbilitytoChangeAccrualRuleTypeBasedonSeniority;
                        return [2 /*return*/];
                }
            });
        });
    };
    AccrualsPolicyComponent.prototype.getMinDate = function (child) {
        return child.get('startDate').value ? new Date(child.get('startDate').value) : new Date();
    };
    // getMaxDate to get date
    AccrualsPolicyComponent.prototype.getMaxOrEndDate = function (child) {
        return child.get('endDate').value ? new Date(child.get('endDate').value) : this.m_maximumDate;
    };
    AccrualsPolicyComponent.prototype.getMaxDate = function (child) {
        return child.get('endDate').value ? new Date(child.get('endDate').value) : this.manService.maxDate();
    };
    AccrualsPolicyComponent.prototype.maxDate = function () {
        return this.m_maximumDate;
    };
    AccrualsPolicyComponent.prototype.clearAll = function () {
        this.manService.isActive = false;
        this.allOtherHoursDropDown = null;
        this.isDisabled = false;
        this.localService.remove("Rules");
        this.localService.remove("Policy Names");
    };
    AccrualsPolicyComponent.prototype.validateExistingPolicyNames = function () {
        var policyName = [];
        this.accrualPolicies.forEach(function (x) {
            policyName.push(x.policyName);
        });
        this.localService.set('Policy Names', policyName);
    };
    AccrualsPolicyComponent.prototype.carryoverDayChange = function (value, control) {
        var regex = /(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)(0[1-9]|[12]\d|3[01])/;
        var isValidValue = regex.test(value.toLowerCase());
        if (!isValidValue) {
            control.setErrors({ 'carryoverDayInvalid': true });
        }
        else {
            control.setErrors(null);
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AccrualsPolicyComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AccrualsPolicyComponent.prototype, "orgLevelSubscription", void 0);
    return AccrualsPolicyComponent;
}());
export { AccrualsPolicyComponent };
