
export interface IEditResult<T> {
  isSuccess: boolean;
  message?: string;
  item: T;
}

export class EditResult<T> {
  public isSuccess: boolean;
  public message?: string;
  public item: T;
}
