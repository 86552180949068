import * as moment from 'moment';
import { PayRule, IPayRule, PayRuleType, IPosition, Position } from '../../../organization/models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { DailyTimecardChangeStatus } from './daily-timecard-change-status';

export enum DailyPayRuleStatus { generated = 'Generated', adjusted = 'Adjusted', deleted = 'Deleted' }

export interface IDailyPayRule {
  ruleDefinition: IPayRule;
  position: IPosition;
  status: string;
  start: string;
  end: string;
  interval: string;
  moneyAmount: number;
  additionalVariable: string;
}

export class DailyPayRule {
  public isNew: boolean;
  public ruleDefinition: PayRule;
  public serverStatus: string;
  public status: DailyPayRuleStatus;
  public start: Date;
  public end: Date;
  public interval: number;
  public intervalHM: Date;
  public moneyAmount: number;
  public type: PayRuleType;
  public position: Position;
  public additionalVariable: string;
  public get isAdjusted(): boolean {
    if (this.serverInterval !== undefined && this.serverInterval !== this.interval) {
      return true;
    }
    if (this.serverMoneyAmount !== undefined && this.serverMoneyAmount !== this.moneyAmount) {
      return true;
    }
    return false;
  }
  
  public serverInterval: number;
  public serverMoneyAmount: number;
  public changeStatus: DailyTimecardChangeStatus;


  public static calculateInterval(item: DailyPayRule): void {
    if (moment(item.end).isAfter(moment(item.start))) {
      let diff: number = moment.range(item.start, item.end).diff();
      item.interval = diff;
    }
    item.intervalHM = dateTimeUtils.getIntervalTimeFromInterval(item.start, item.interval, 'ms');
  }

  

}
