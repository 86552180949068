import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { OnInit } from '@angular/core';
import { AcaC1095AuditManagementService } from '../../../services/aca-c1095-audit/aca-c1095-audit-management.service';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
var AcaC1095AuditToolbarComponent = /** @class */ (function () {
    function AcaC1095AuditToolbarComponent(acaAuditManagementService) {
        this.acaAuditManagementService = acaAuditManagementService;
        this.defaultYear = moment().startOf('day').toDate().getFullYear();
        this.yearOptions = [];
        this.isExpand = false;
        this.subscriptions = {};
    }
    AcaC1095AuditToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createYearOptions();
        this.selectedYear = this.defaultYear;
        this.subscriptions.onLoaded = this.acaAuditManagementService
            .subscribeToEmployeeFilter(function (records) {
            _this.filter = records.filter;
            _this.employeeFilterRecords = records.employeeFilterRecord;
        });
        this.subscriptions.onLoadedActions = this.acaAuditManagementService
            .subscribeToLoadedRecords(function (container) {
            _this.canExportToExcel = container.actions.canExportToExcel;
            _this.canExportToPdf = container.actions.canExportToPdf;
        });
    };
    AcaC1095AuditToolbarComponent.prototype.ngOnDestroy = function () { };
    AcaC1095AuditToolbarComponent.prototype.createYearOptions = function () {
        this.yearOptions = _.reverse(_.values(_.range(2020, this.defaultYear + 1)));
    };
    AcaC1095AuditToolbarComponent.prototype.getAcaWidth = function () {
        return (screen.width <= appConfig.mobileMaxWidth) ? 30 : 130;
    };
    AcaC1095AuditToolbarComponent.prototype.onChangeYear = function (selYear) {
        this.selectedYear = selYear;
        this.acaAuditManagementService.setSelectedYear(selYear);
    };
    AcaC1095AuditToolbarComponent.prototype.onClickExpand = function (isExpand) {
        this.isExpand = isExpand;
        this.acaAuditManagementService.expandAll(isExpand);
    };
    AcaC1095AuditToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.acaAuditManagementService.exportTo(isPDF);
    };
    AcaC1095AuditToolbarComponent.prototype.onChangeFilter = function (filters) {
        this.acaAuditManagementService.setEmployeeFilter(filters);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaC1095AuditToolbarComponent.prototype, "subscriptions", void 0);
    return AcaC1095AuditToolbarComponent;
}());
export { AcaC1095AuditToolbarComponent };
