import { TimeclockCommunicationMapService } from './timeclock-communication-map.service';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { employeeConfig } from '../../employee.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { dateTimeUtils } from '../../../../common/utils/index';

@Injectable()
export class TimeclockCommunicationApiService {
    constructor(
        private mapService: TimeclockCommunicationMapService,
        private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService) {
    }

    public sendEmployeeSpecificMessage(message: String, empIds: number[]): Promise<any> {
        const url: string = `${this.getApiRoot()}/${employeeConfig.api.communications.sendEmployeeSpecificMessage.root}`;
        let request: HttpRequest<any> = new HttpRequest('POST', url, {
            message: message,
            employeeIds: empIds
        });

        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
        return promise;
    }

    public sendTimeclockTickerMessage(message: String, clocksIds: number[], startDate: Date, endDate: Date): Promise<any> {
        const url: string = `${this.getApiRoot()}/${employeeConfig.api.communications.sendTimeclockTickerMessage.root}`;
        let request: HttpRequest<any> = new HttpRequest('POST', url, {
            message: message,
            timeclockIds: clocksIds,
            messageStartDate: dateTimeUtils.convertToDtoDateTimeString(startDate),
            messageEndDate: dateTimeUtils.convertToDtoDateTimeString(endDate)
        });

        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
        return promise;
    }

    public stopTimeclockTickerMessage(clocksIds: number[]): Promise<any> {
        const url: string = `${this.getApiRoot()}/${employeeConfig.api.communications.stopTimeclockTickerMessage.root}`;
        let request: HttpRequest<any> = new HttpRequest('POST', url, {
            timeclockIds: clocksIds
        });

        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
        return promise;
    }

    public saveCall(employeeId: number, date: Date, phone: string, message: String): Promise<any> {
        const url: string = `${this.getApiRoot()}/${employeeConfig.api.communications.calls.root}/${employeeConfig.api.communications.calls.save}`;
        let request: HttpRequest<any> = new HttpRequest('POST', url, {
            employeeId: employeeId,
            contactPhone: phone,
            comments: message,
            contactDate: dateTimeUtils.convertToDtoDateTimeString(date)
        });

        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
        return promise;
    }

    private getApiRoot(): string {
        return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.communications.root}`;
    }

}
