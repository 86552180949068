import { OrgLevelTypeDefinition } from './org-level-type';
import { OrgLevelLocation } from './org-level-location';

export class OrgLevel {
  public id: number;
  public name: string;
  public type: OrgLevelTypeDefinition;
  public sequenceNumber: number;
  public relatedItemId: number;
  public parentId: number;
  public childs: OrgLevel[];
  public treeViewPath: number[];
  public treeViewNamePath: string[];
  public organizationId: number;
  public location: OrgLevelLocation;
  public get hasChilds(): boolean {
    if (this.childs && this.childs.length > 0) {
      return true;
    }
    return false;
  }
}
