/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manage-ale-groups-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../../common/components/spinner/spinner.component";
import * as i4 from "../manage-ale-groups/manage-ale-groups.component.ngfactory";
import * as i5 from "../../../../services/employers/employers-configuration-management.service";
import * as i6 from "../../../../../common/services/change-management/change-management.service";
import * as i7 from "../../../../services/employers/employers-configuration-api.service";
import * as i8 from "../../../../services/employers/employers-configuration-map.service";
import * as i9 from "../manage-ale-groups/manage-ale-groups.component";
import * as i10 from "@angular/common";
import * as i11 from "./manage-ale-groups-dialog.component";
import * as i12 from "../../../../../common/models/dialog-options";
import * as i13 from "../../../../../common/services/modal/modal.service";
var styles_ManageAleGroupsDialogComponent = [i0.styles];
var RenderType_ManageAleGroupsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManageAleGroupsDialogComponent, data: {} });
export { RenderType_ManageAleGroupsDialogComponent as RenderType_ManageAleGroupsDialogComponent };
export function View_ManageAleGroupsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "headless-modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "txt-pad10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "pull-left header-cls"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage Groups"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "pull-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times top-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 20, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(9, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "slx-manage-ale-groups", [], null, [[null, "onLoading"], [null, "onHasChanges"], [null, "isGroupChanges"], [null, "isSaveComplete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLoading" === en)) {
        var pd_0 = (_co.onLoading($event) !== false);
        ad = (pd_0 && ad);
    } if (("onHasChanges" === en)) {
        var pd_1 = (_co.onHasChanges($event) !== false);
        ad = (pd_1 && ad);
    } if (("isGroupChanges" === en)) {
        var pd_2 = (_co.isGroupChanges($event) !== false);
        ad = (pd_2 && ad);
    } if (("isSaveComplete" === en)) {
        var pd_3 = (_co.isSaveComplete($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_ManageAleGroupsComponent_0, i4.RenderType_ManageAleGroupsComponent)), i1.ɵprd(512, null, i5.EmployersManagementService, i5.EmployersManagementService, [i6.ChangeManagementService, i7.EmployersConfigurationApiService, i8.EmployersConfigurationMapService]), i1.ɵdid(13, 114688, [["groups", 4]], 0, i9.ManageAleGroupsComponent, [i5.EmployersManagementService], null, { onLoading: "onLoading", onHasChanges: "onHasChanges", isGroupChanges: "isGroupChanges", isSaveComplete: "isSaveComplete" }), (_l()(), i1.ɵeld(14, 0, null, 0, 14, "div", [["class", "modal-footer center-align"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "btn-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times marl5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel"])), (_l()(), i1.ɵeld(19, 0, null, null, 9, "div", [["class", "btn-save"]], null, null, null, null, null)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(21, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(22, { "disabledbtn": 0 }), (_l()(), i1.ɵeld(23, 0, null, null, 5, "button", [["class", "savecls"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).saveGroupAssignments() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(25, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(26, { "disabledbtn": 0 }), (_l()(), i1.ɵeld(27, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save marl5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Save "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 9, 0, currVal_0); _ck(_v, 13, 0); var currVal_1 = "btn-save"; var currVal_2 = _ck(_v, 22, 0, _co.isSaveDisabled); _ck(_v, 21, 0, currVal_1, currVal_2); var currVal_4 = "savecls"; var currVal_5 = _ck(_v, 26, 0, _co.isSaveDisabled); _ck(_v, 25, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isSaveDisabled; _ck(_v, 23, 0, currVal_3); }); }
export function View_ManageAleGroupsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-manage-ale-groups-dialog", [], null, null, null, View_ManageAleGroupsDialogComponent_0, RenderType_ManageAleGroupsDialogComponent)), i1.ɵprd(512, null, i5.EmployersManagementService, i5.EmployersManagementService, [i6.ChangeManagementService, i7.EmployersConfigurationApiService, i8.EmployersConfigurationMapService]), i1.ɵdid(2, 114688, null, 0, i11.ManageAleGroupsDialogComponent, [i12.DialogOptions, i13.ModalService, i5.EmployersManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ManageAleGroupsDialogComponentNgFactory = i1.ɵccf("slx-manage-ale-groups-dialog", i11.ManageAleGroupsDialogComponent, View_ManageAleGroupsDialogComponent_Host_0, {}, {}, []);
export { ManageAleGroupsDialogComponentNgFactory as ManageAleGroupsDialogComponentNgFactory };
