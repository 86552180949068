/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./geolocation-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../components-library/components/action-list/action-list/action-list.component.ngfactory";
import * as i4 from "../../../../components-library/components/action-list/action-list/action-list.component";
import * as i5 from "../../../../components-library/components/action-list/action-button/action-button.component.ngfactory";
import * as i6 from "../../../../components-library/components/action-list/action-button/action-button.component";
import * as i7 from "../../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i8 from "ngx-popper";
import * as i9 from "../../../../components-library/components/action-list/action-list-item/action-list-item.component.ngfactory";
import * as i10 from "../../../../components-library/components/action-list/action-list-item/action-list-item.component";
import * as i11 from "./geolocation-toolbar.component";
import * as i12 from "../../../services/geolocation/geolocation-management.service";
var styles_GeolocationToolbarComponent = [i0.styles];
var RenderType_GeolocationToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeolocationToolbarComponent, data: {} });
export { RenderType_GeolocationToolbarComponent as RenderType_GeolocationToolbarComponent };
function View_GeolocationToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon slx-margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-plus-circle slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add"]))], null, null); }
function View_GeolocationToolbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon slx-margin-r"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actionsDisabled; _ck(_v, 0, 0, currVal_0); }); }
function View_GeolocationToolbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon slx-margin-r"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Update"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actionsDisabled; _ck(_v, 0, 0, currVal_0); }); }
function View_GeolocationToolbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon slx-margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-ban slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], null, null); }
export function View_GeolocationToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "geo-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "geo-toolbar__lcol"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeolocationToolbarComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeolocationToolbarComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeolocationToolbarComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeolocationToolbarComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 13, "div", [["class", "geo-toolbar__rcol"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 12, "slx-action-list", [], null, null, null, i3.View_ActionListComponent_0, i3.RenderType_ActionListComponent)), i1.ɵdid(12, 49152, null, 0, i4.ActionListComponent, [], null, null), (_l()(), i1.ɵeld(13, 0, null, 1, 2, "slx-action-button", [], null, null, null, i5.View_ActionButtonComponent_0, i5.RenderType_ActionButtonComponent)), i1.ɵdid(14, 49152, null, 0, i6.ActionButtonComponent, [], { popperContent: [0, "popperContent"], popperPosition: [1, "popperPosition"], iconName: [2, "iconName"] }, null), (_l()(), i1.ɵted(-1, 0, ["Actions"])), (_l()(), i1.ɵeld(16, 0, null, 1, 7, "popper-content", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 17).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_PopperContent_0, i7.RenderType_PopperContent)), i1.ɵdid(17, 180224, [["popperContent", 4]], 0, i8.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(18, 0, null, 0, 2, "slx-action-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onExportToExcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ActionListItemComponent_0, i9.RenderType_ActionListItemComponent)), i1.ɵdid(19, 49152, null, 0, i10.ActionListItemComponent, [i8.PopperContent], null, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, 0, [" Export to Excel "])), (_l()(), i1.ɵeld(21, 0, null, 0, 2, "slx-action-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onExportToPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ActionListItemComponent_0, i9.RenderType_ActionListItemComponent)), i1.ɵdid(22, 49152, null, 0, i10.ActionListItemComponent, [i8.PopperContent], null, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, 0, [" Export to PDF "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canAdd; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.canSave; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.canUpdate; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.canCancel; _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 17); var currVal_5 = "bottom-end"; var currVal_6 = "fas fa-cog"; _ck(_v, 14, 0, currVal_4, currVal_5, currVal_6); }, null); }
export function View_GeolocationToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-geolocation-toolbar", [], null, null, null, View_GeolocationToolbarComponent_0, RenderType_GeolocationToolbarComponent)), i1.ɵdid(1, 245760, null, 0, i11.GeolocationToolbarComponent, [i12.GeolocationManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GeolocationToolbarComponentNgFactory = i1.ɵccf("slx-geolocation-toolbar", i11.GeolocationToolbarComponent, View_GeolocationToolbarComponent_Host_0, {}, {}, []);
export { GeolocationToolbarComponentNgFactory as GeolocationToolbarComponentNgFactory };
