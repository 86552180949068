<div class="pop-main" *ngIf="five_star_v2_enabled">
    <div class="pop-left">
        <div>Rating</div>
        <div *ngFor="let _ of [].constructor(5); let i = index"><span>{{i+1}}</span><span *ngFor="let _ of [].constructor(i+1);"><em class="rating-star fas fa-star"></em></span></div>
    </div>
    <div class="pop-right">
        <div>Staff Rating Point Values</div>
        <div>0-154</div>
        <div>155-204</div>
        <div>205-254</div>
        <div>255-319</div>
        <div>320-380</div>
    </div>
</div>

<div class="main-container" *ngIf="!five_star_v2_enabled">
    <div class="container-row title-row">
        <div class="title-group">
            <span class="title-lbl">Staffing Points and Rating</span>
            <span class="timestamp-lbl">Updated: {{currentTable?.startDate | amDateFormat: appConfig.fullMonthYearFormat}}</span>
        </div>
        <div class="selector-group">
            <slx-dropdown-list class="slx-wide slx-short slx-middle-label" 
                [valueField]="'id'" [titleField]="'label'" 
                [options]="staffingPointsTables"
                [valuePrimitive]="false" 
                [(ngModel)]="currentTable"
                icon="fal fa-calendar-alt">
            </slx-dropdown-list>
        </div>
    </div>
    <div class="container-row table-row">

        <div class="matrix-table" *ngIf="currentTable">

            <!-- table grouped headers -->
            <div class="table-row table-headers">
                <div class="table-cell first-column-cell name-column-header">RN rating and hours</div>
                <div class="table-cell value-columns-header">Total nurse staffing rating and hours (RN, LPN and nurse  aide)</div>
            </div>

            <div class="scrollable-table-body">
                <!-- headers -->
                <div class="table-row">
                        <div class="table-cell column-header first-column-cell blank-cell"></div>
                        <div class="table-cell column-header"
                            *ngFor="let matrixCell of currentTable.firstRow.cells; let i = index">
                            <div class="header-content">
                                <span class="index-value">{{i+1}}</span>
                                <span class="range-value">{{matrixCell.rangeString}}</span>
                            </div>
                            <div *ngIf="isInTotalRange (organizationInfo.organization.totalHoursPoints, matrixCell)"
                                class="organization-mark total-mark">you: {{organizationInfo.organization.totalHoursPoints}}
                            </div>
                        </div>
                    </div>
        
                    <!-- cells -->
                    <div class="table-row" *ngFor="let matrixRow of currentTable.rows; let i = index">
                        <div class="table-cell first-column-cell">
                            <div class="cell-content">
                                <span class="index-value">{{i+1}}</span>
                                <span class="range-value">{{matrixRow.rangeString}}</span>
                            </div>
                            <div *ngIf="isInRnRange (organizationInfo.organization.rnHoursPoints, matrixRow)"
                                class="organization-mark rn-mark">you: {{organizationInfo.organization.rnHoursPoints}}</div>
                        </div>
                        <div class="table-cell" *ngFor="let matrixCell of matrixRow.cells; let j = index">
                            <div class="cell-content">
                                <div class="star-holder">
                                    <i class="rating-star fas fa-star" *ngFor="let r of matrixCell.ratingArray"></i>
                                </div>
                            </div>
                            <div *ngIf="isInTotalRange (organizationInfo.organization.totalHoursPoints, matrixCell) && isInRnRange (organizationInfo.organization.rnHoursPoints, matrixRow)"
                                class="organization-mark rating-mark">your rating</div>
                        </div>
                    </div>
            </div>
           

        </div>
    </div>
    <div class="container-row footer-row">
        <span class="note-info">Note: Adjusted staffing values are rounded to three decimal places before the cut points are applied</span>
    </div>
</div>