import * as _ from 'lodash';

import { IColumnSettings } from '../../../core/models/index';
import { TimecardsColumnState } from './timecards-column-state';
import { PayRuleDefinition } from '../../../organization/models/index';

export class IndividualTimecardsState {
  public isShowPayRates: boolean;
  public isShowHighPrecision: boolean;
  public empColumns: TimecardsColumnState;
  public payCodeColumns: TimecardsColumnState;
  public createEmpColumns(): void {
    this.empColumns = new TimecardsColumnState();
    this.empColumns.columns = [
      {
        name: 'schedule',
        description: 'Schedule',
        displayed: true,
        width: 200,
        filter: null
      },
      {
        name: 'in',
        description: 'IN',
        displayed: true,
        width: 90,
        filter: null
      },
      {
        name: 'out',
        description: 'OUT',
        displayed: true,
        width: 90,
        filter: null
      },
      {
        name: 'organization',
        description: 'Organization',
        displayed: false,
        width: 200,
        filter: null
      },
      {
        name: 'department',
        description: 'Department',
        displayed: false,
        width: 200,
        filter: null
      },
      {
        name: 'position',
        description: 'Position',
        displayed: true,
        width: 200,
        filter: null
      },
      {
        name: 'approved',
        description: 'Approved',
        displayed: true,
        width: 100,
        filter: 'boolean'
      },
      {
        name: 'payPolicy',
        description: 'Pay Policy',
        displayed: true,
        width: 150,
        filter: null
      },
      {
        name: 'shiftDiffPolicy',
        description: 'Shift Diff Policy',
        displayed: true,
        width: 150,
        filter: null
      },
      {
        name: 'shiftCodeName',
        description: 'Shift Code',
        displayed: true,
        width: 130,
        filter: null
      },
      {
        name: 'payRate',
        description: 'Pay Rate',
        displayed: true,
        width: 120,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      // {
      //   name: 'shiftCode',
      //   description: 'Shift Code',
      //   width: 150,
      //   displayed: false,
      //   filter: null
      // },
      {
        name: 'costCenterCode',
        description: 'Cost Center Code',
        width: 180,
        displayed: true,
        filter: null
      },
      // {
      //   name: 'workedHours',
      //   description: 'Worked Hours',
      //   displayed: true,
      //   width: 130,
      //   filter: 'numeric'
      // },
      {
        name: 'productiveHours',
        description: 'Productive Hours',
        displayed: true,
        width: 120,
        filter: 'numeric'
      },
      {
        name: 'nonProductiveHours',
        description: 'Non-Productive Hourss',
        displayed: true,
        width: 120,
        filter: 'numeric'
      },
      {
        name: 'totalHours',
        description: 'Total Hours',
        displayed: true,
        width: 130,
        filter: 'numeric'
      },
      {
        name: 'regularPay',
        description: 'Regular Pay',
        displayed: true,
        width: 130,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      // {
      //   name: 'overtimeAndExtra',
      //   description: 'Overtime and Other',
      //   displayed: true,
      //   width: 130,
      //   filter: 'numeric',
      //   unit: '$',
      //   payload: { payRateRelated: true },
      // },
      // {
      //   name: 'overtimeAndExtraHours',
      //   description: 'Overtime and Other Hours',
      //   displayed: true,
      //   width: 150,
      //   filter: 'numeric'
      // },
      {
        name: 'overTimePay',
        description: 'Overtime Pay',
        displayed: true,
        width: 150,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      {
        name: 'totalAbsencePay',
        description: 'Total Paid Absences',
        displayed: true,
        width: 150,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      {
        name: 'totalOtherPay',
        description: 'Total Other pay',
        displayed: true,
        width: 150,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      {
        name: 'overtimeAndExtraPay',
        description: 'Overtime and Other Pay',
        displayed: true,
        width: 150,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      {
        name: 'totalPay',
        description: 'Total Pay',
        displayed: true,
        width: 150,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
    ];
  }

  public createPayCodeColumns(usedPayRules: PayRuleDefinition[]): void {
    this.payCodeColumns = new TimecardsColumnState();
    this.payCodeColumns.columns = [ ];
    _.forEach(usedPayRules, (ruleDef: PayRuleDefinition) => {
      let column: IColumnSettings = {
        name: ruleDef.name,
        description: ruleDef.description,
        displayed: true,
      };
      this.payCodeColumns.columns.push(column);
    });
  }

}
