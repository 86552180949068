import { Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { commonConfig } from '../common.config';
import { appConfig } from '../../app.config';
import { dateTimeUtils } from '../utils/index';
var CommonValidators = /** @class */ (function () {
    function CommonValidators() {
    }
    CommonValidators.max = function (max, strict) {
        return function (control) {
            if (!_.isNil(Validators.required(control)))
                return null;
            var v = control.value;
            if (strict)
                return v < max ? null : { 'max': true };
            return v <= max ? null : { 'max': true };
        };
    };
    CommonValidators.prohibitedValues = function (conf) {
        return function (control) {
            var v;
            if (conf instanceof Array) {
                v = _.find(conf, function (val) { return control.value === val; });
            }
            else {
                var selfExcludedOnce_1 = false;
                v = _.find(conf.values, function (val) {
                    if (!control.value) {
                        return false;
                    }
                    var selfValue = conf.selfValue;
                    var controlValue = control.value;
                    var currValue = val;
                    if (conf.valuePropertyName) {
                        controlValue = control.value[conf.valuePropertyName];
                        currValue = val[conf.valuePropertyName];
                    }
                    if (!conf.caseSensitive) {
                        controlValue = _.toLower(controlValue);
                        currValue = _.toLower(currValue);
                        selfValue = _.toLower(selfValue);
                    }
                    if (conf.trimValue) {
                        controlValue = _.trim(controlValue);
                        currValue = _.trim(currValue);
                        selfValue = _.trim(selfValue);
                    }
                    if (conf.excludeSelf && !selfExcludedOnce_1) {
                        if (controlValue === currValue) {
                            selfExcludedOnce_1 = true;
                        }
                        if (conf.valuePropertyName) {
                            return val !== control.value && controlValue === currValue;
                        }
                        else {
                            return selfValue !== controlValue && controlValue === currValue;
                        }
                    }
                    else {
                        return controlValue === currValue;
                    }
                });
            }
            return v ? { 'prohibitedValues': true } : null;
        };
    };
    CommonValidators.noMore = function (conf) {
        return function (control) {
            var v;
            if (conf.value === undefined) {
                v = _.filter(conf.values, function (val) {
                    if (val) {
                        return control.value ? control.value[conf.propertyName] === val[conf.propertyName] : false;
                    }
                    else {
                        return false;
                    }
                });
            }
            else {
                v = _.filter(conf.values, function (val) {
                    return conf.value === val[conf.propertyName];
                });
            }
            return v.length >= conf.noMore ? { 'noMore': true } : null;
        };
    };
    CommonValidators.unique = function (conf) {
        return function (control) {
            var v;
            v = _.find(conf.values, function (val) {
                return control.value ? control.value === val[conf.propertyName] && val[conf.idPropertyName] !== conf.idValue : false;
            });
            return v ? { 'unique': true } : null;
        };
    };
    CommonValidators.min = function (min, strict) {
        return function (control) {
            if (!_.isNil(Validators.required(control)))
                return null;
            var v = control.value;
            if (strict)
                return v > min ? null : { 'min': true };
            return v >= min ? null : { 'min': true };
        };
    };
    CommonValidators.slxMinLength = function (min) {
        return function (control) {
            if (!_.isNil(Validators.required(control)))
                return null;
            var v = control.value || '';
            return v.length >= min ? null : { 'slxMinLength': true };
        };
    };
    CommonValidators.slxMaxLength = function (max) {
        return function (control) {
            if (!_.isNil(Validators.required(control)))
                return null;
            var v = control.value || '';
            return v.length <= max ? null : { 'slxMaxLength': true };
        };
    };
    CommonValidators.notLeadZeroes = function (control) {
        var validator = Validators.pattern(commonConfig.notLeadZeroes);
        return !validator(control) ? null : { 'notLeadZeroes': true };
    };
    CommonValidators.number = function (control) {
        var validator = Validators.pattern(commonConfig.numberPattern);
        return !validator(control) ? null : { 'number': true };
    };
    CommonValidators.ssn = function (control) {
        if (control.value === '' || control.value === null || control.value === undefined)
            return null;
        var validator = Validators.pattern(commonConfig.ssnPattern);
        return !validator(control) ? null : { 'ssn': true };
    };
    CommonValidators.email = function (control) {
        if (!control.value) {
            return null;
        }
        var validator = Validators.pattern(commonConfig.emailPattern);
        return !validator(control) ? null : { 'email': true };
    };
    CommonValidators.phone = function (control) {
        var validator = Validators.pattern(commonConfig.phonePattern);
        return !validator(control) ? null : { 'phone': true };
    };
    CommonValidators.acaReceiptId = function (control) {
        var validator = Validators.pattern(commonConfig.acaReceiptIdPattern);
        return !validator(control) ? null : { 'acaReceiptId': true };
    };
    CommonValidators.ipv4 = function (control) {
        var validator = Validators.pattern(commonConfig.ipv4Pattern);
        return !validator(control) ? null : { 'ipv4': true };
    };
    CommonValidators.pbjId = function (control) {
        var validator = Validators.pattern(commonConfig.pbjIdPattern);
        return !validator(control) ? null : { 'pbjId': true };
    };
    CommonValidators.datetime = function (control) {
        if (dateTimeUtils.validateDate(control.value)) {
            return null;
        }
        return { date: true };
    };
    CommonValidators.date = function (control) {
        if (dateTimeUtils.validateDate(control.value)) {
            return null;
        }
        return { date: true };
    };
    CommonValidators.time = function (control) {
        var validator = Validators.pattern(commonConfig.timePattern);
        return !validator(control) ? null : { 'time': true };
    };
    CommonValidators.filled = function (control) {
        var validator = Validators.pattern(commonConfig.notEmptyPattern);
        return !validator(control) ? null : { 'filled': true };
    };
    CommonValidators.alphanumeric = function (control) {
        var validator = Validators.pattern(commonConfig.alphanumericPattern);
        return !validator(control) ? null : { 'alphanumeric': true };
    };
    CommonValidators.password = function (control) {
        var validator = Validators.pattern(commonConfig.passwordPattern);
        return !validator(control) ? null : { 'password': true };
    };
    CommonValidators.duration = function (control) {
        var validator = Validators.pattern(commonConfig.durationPattern);
        return !validator(control) ? null : { 'time': true };
    };
    CommonValidators.minDate = function (minDate) {
        //if (!moment(minDate).isValid()) throw Error('minDate value must be a formatted date');
        return function (control) {
            if (!_.isNil(Validators.required(control)))
                return null;
            var d = moment(control.value);
            if (!moment(minDate).isValid())
                return { minDate: true };
            return moment(minDate).isSameOrBefore(d) ? null : { minDate: true };
        };
    };
    CommonValidators.maxDate = function (maxDate) {
        //if (!moment(maxDate).isValid()) throw Error('maxDate value must be a formatted date');
        return function (control) {
            if (!_.isNil(Validators.required(control)))
                return null;
            var d = moment(control.value);
            if (!moment(maxDate).isValid())
                return { maxDate: true };
            return moment(maxDate).isSameOrAfter(d) ? null : { maxDate: true };
        };
    };
    CommonValidators.minTime = function (minTime) {
        return function (control) {
            if (!_.isNil(Validators.required(control)) && !_.isNil(CommonValidators.time(control)))
                return null;
            var d = moment(control.value, appConfig.timeFormat);
            if (!moment(minTime, appConfig.timeFormat).isValid)
                return { minDate: true };
            return moment(minTime, appConfig.timeFormat).isSameOrBefore(d) ? null : { minTime: true };
        };
    };
    CommonValidators.maxTime = function (maxTime) {
        return function (control) {
            if (!_.isNil(Validators.required(control)) && !_.isNil(CommonValidators.time(control)))
                return null;
            var d = moment(control.value, appConfig.timeFormat);
            if (!moment(maxTime, appConfig.timeFormat).isValid)
                return { maxDate: true };
            return moment(maxTime, appConfig.timeFormat).isSameOrAfter(d) ? null : { maxTime: true };
        };
    };
    CommonValidators.required = function (control) {
        var hasRequired = !_.isNil(Validators.required(control));
        return (hasRequired && !control.value) ? { 'required': true } : null;
    };
    CommonValidators.equal = function (value) {
        return function (control) {
            if (!_.isNil(Validators.required(control)))
                return null;
            var v = control.value;
            return v === value ? null : { 'equal': true };
        };
    };
    CommonValidators.workHoursValidation = function (workHoursValidation, strict) {
        return function (control) {
            if (!_.isNil(Validators.required(control)))
                return null;
            var v = control.value;
            if (strict)
                return v > workHoursValidation ? null : { 'workHoursValidation': true };
            return v >= workHoursValidation ? null : { 'workHoursValidation': true };
        };
    };
    CommonValidators.serverValidator = function (conf) {
        return function (control) {
            if (!_.isNil(Validators.required(control)))
                return Promise.resolve(null);
            var v = control.value;
            var adapter = conf.validationAdapter;
            var name = conf.validationName;
            var result = adapter.validate.apply(adapter, [name, v].concat(conf.parameters)).then(function (r) {
                var _a;
                var res = (_a = {}, _a[name] = r.errorMessage, _a);
                return r.isValid ? null : res;
            });
            return result;
        };
    };
    return CommonValidators;
}());
export { CommonValidators };
