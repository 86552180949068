<div class="slx-top-header">
    <slx-toolbar class="accruals-toolbar__helper" [alignExpandButtonRight]="true">
        <div class="scheduled-rotations-toolbar">
            <div class="filter-options">
                <ng-template slxToolbarSectionTemplate alignMode="left" [width]="toolbarWidth">
                    <div class="date-picker-margin" style="padding: 12px;">
                        <slx-schedule-cycle-calendar-slider [ngModel]="manService.dateFrom"
                            (ngModelChange)="manService.onDateFromChanged(manService.cycles, $event)" [minDate]="min"
                            [maxDate]="max" [cycles]="manService.cycles"
                            name="dateFrom"></slx-schedule-cycle-calendar-slider>
                    </div>
                </ng-template>
                <ng-template slxToolbarSectionTemplate alignMode="left" [width]="toolbarWidth">
                    <div class="srt-toolbar-div-margin">
                        <slx-multiselect [options]="positionGroupLookup?.items"
                            [(ngModel)]="manService.selectedPosGroup" placeholder="Position Group"
                            (ngModelChange)="manService.onFilterChange()" name="positionGroup"
                            externalPlaceholderText="Position Group:" externalPlaceholder="true">
                        </slx-multiselect>
                    </div>
                </ng-template>
                <ng-template slxToolbarSectionTemplate alignMode="left" [width]="toolbarWidth">
                    <div class="srt-toolbar-div-margin">
                        <slx-multiselect [options]="positionLookup?.items" class="srt-custom"
                            [(ngModel)]="manService.selectedPos" placeholder="Position"
                            (ngModelChange)="manService.onFilterChange()" name="position"
                            externalPlaceholderText="Position:" externalPlaceholder="true">
                        </slx-multiselect>
                    </div>
                </ng-template>
                <ng-template slxToolbarSectionTemplate alignMode="left" [width]="toolbarWidth">
                    <div class="srt-toolbar-div-margin">
                        <slx-multiselect [options]="shiftsLookup?.items" [(ngModel)]="manService.selectedShiftGroup"
                            placeholder="Shift Group" (ngModelChange)="manService.onFilterChange()" name="shift"
                            externalPlaceholderText="Shift Group:" externalPlaceholder="true">
                        </slx-multiselect>
                    </div>
                </ng-template>
                <ng-template slxToolbarSectionTemplate alignMode="left" [width]="toolbarWidth">
                    <div class="srt-toolbar-div-margin">
                        <slx-multiselect [options]="unitsData" [(ngModel)]="manService.selectedUnit" placeholder="Unit"
                            (ngModelChange)="manService.onFilterChange()" name="unit" externalPlaceholderText="Unit:"
                            externalPlaceholder="true">
                        </slx-multiselect>
                    </div>
                </ng-template>
                <!-- <div>
                        <slx-check-box caption="View Secondary Positions" [(ngModel)]="manService.isPrimary" (ngModelChange)="manService.isPrimaryChange()">
                        </slx-check-box>
                    </div> -->
            </div>
            <div class="button-container">
                <ng-template slxToolbarSectionTemplate alignMode="right" [width]="toolbarWidth">
                    <button class="srt-toolbar-save-button"
                        [ngClass]="manService.isSaveDisable ? 'srt-toolbar-disable-save-button' : 'srt-toolbar-save-button'"
                        [disabled]="manService.isSaveDisable" (click)="saveRotations()">
                        Save
                    </button>
                    <button class="srt-filter-settings" [popper]="filterSettingsToggler" [popperTrigger]="'click'"
                        [popperPlacement]="'bottom-start'" [popperDisableStyle]="'true'"
                        [popperHideOnClickOutside]="false">
                        <i class="fal fa-cog"></i>
                    </button>
                    <div [class.active]="isActionListActive" class="schedule-toolbar__control">
                        <slx-action-list>
                            <slx-action-button [iconName]="'fa fa-caret-down'" [popperContent]="popperContent"
                                [popperPosition]="'bottom-end'">Actions</slx-action-button>
                            <popper-content #popperContent>
                                <slx-action-list-item (onClick)="generateRotation()"
                                    [disabled]="disableGenerateRotation">Generate
                                    Rotation</slx-action-list-item>
                                <slx-action-list-item (onClick)="exportRotations()">Export
                                    Rotation</slx-action-list-item>
                            </popper-content>
                        </slx-action-list>
                    </div>
                </ng-template>
            </div>
        </div>
    </slx-toolbar>
    <div>
        <slx-scheduled-rotations-avail-shifts [orgLevelId]="orgLevelId" [startDate]="startDate"
            [endDate]="endDate"></slx-scheduled-rotations-avail-shifts>
    </div>
</div>
<div class="slx-grid-container">
    <div style="margin: 0 5px 0 -5px;">
        <slx-scheduled-rotations-employee-grid [orgLevelId]="orgLevelId" [startDate]="startDate"
            [endDate]="endDate"></slx-scheduled-rotations-employee-grid>
    </div>
</div>

<popper-content class="popper-white-background wide345-popper height500-popper" #filterSettingsToggler>
    <div class="filter-rotation-settings">
        <div class="filter-container">
            <div class="filter-setting-container">
                <div class="filter-by">
                    <p>Apply Filters to Grid:</p>
                    <div class="filter-grid-switch">
                        <slx-switcher [(ngModel)]="filterOptions.gridFilterOption.isSelected" label="" labelOn="Yes"
                            labelOff="No" name="filterOptions.gridFilterOption.name"></slx-switcher>
                        <p class="grid-text">The grid will filter rotations based on:</p>
                        <p class="grid-text1">Position Group, Position, Shift Group and Unit</p>
                    </div>
                </div>
                <div class="filter-by">
                    <p>View Grid Headers as:</p>
                    <div *ngFor="let viewWeeksOption of filterOptions.viewWeeks">
                        <input class="form-check-input" type="radio" name="viewWeeks" id="viewWeeksOption.id"
                            name="viewWeeksOption.name" [checked]="viewWeeksOption.isSelected"
                            (change)="checkedChange(viewWeeksOption, filterOptions.viewWeeks)"><span
                            class="filter-label">{{viewWeeksOption.name}}</span><br>
                    </div>
                </div>
                <div class="filter-by">
                    <p>Employee List Filter Options:</p>
                    <div class="filter-position-options">
                        <div class="agency-staff-pos">
                            <input type="checkbox" class="form-check-input"
                                id="filterOptions.positionOptions.primaryPosition.id"
                                (change)="checkAgencyChange(filterOptions.positionOptions.primaryPosition, filterOptions.positionOptions.primaryPosition.name, $event.target.checked)"
                                [checked]="filterOptions.positionOptions.primaryPosition.isSelected">
                            <span class="filter-label">{{filterOptions.positionOptions.primaryPosition.name}}</span><br>
                        </div>
                        <div class="secondary-pos">
                            <input type="checkbox" class="form-check-input"
                                id="filterOptions.positionOptions.secondaryPosition.id"
                                (change)="checkAllChange(filterOptions.positionOptions.secondaryPosition, $event.target.checked)"
                                [checked]="isSelectAll">
                            <span
                                class="filter-label">{{filterOptions.positionOptions.secondaryPosition.name}}</span><br>
                            <div>
                                <span class="secondary-pos-child"
                                    *ngFor="let item of filterOptions.positionOptions.secondaryPosition.options">
                                    <input type="checkbox" class="form-check-input" id="item.id"
                                        (change)="checkBoxChange(filterOptions.positionOptions.secondaryPosition, filterOptions.positionOptions.secondaryPosition.options, item.name, $event.target.checked)"
                                        [checked]="item.isSelected">
                                    <span class="filter-label">{{item.name}}</span><br>
                                </span>
                            </div>
                        </div>
                        <div class="agency-staff-pos">
                            <input type="checkbox" class="form-check-input"
                                id="filterOptions.positionOptions.agencyStaff.id"
                                (change)="checkAgencyChange(filterOptions.positionOptions.agencyStaff, filterOptions.positionOptions.agencyStaff.name, $event.target.checked)"
                                [checked]="filterOptions.positionOptions.agencyStaff.isSelected">
                            <span class="filter-label">{{filterOptions.positionOptions.agencyStaff.name}}</span><br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="settings-buttons">
            <button #filterSettingsTogglerApply class="settings-apply-button theme-button-apply margin-r" type="button"
                (click)="onSettingsChanged(filterSettingsToggler, true)">Apply</button>
            <button #filterSettingsTogglerClose class="settings-cancel-button theme-button-cancel" type="button"
                (click)="onSettingsChanged(filterSettingsToggler, false)">Close</button>
        </div>
    </div>
</popper-content>