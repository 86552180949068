import * as moment from 'moment';
import * as _ from 'lodash';
import { appConfig } from '../../../app.config';
var MessageList = /** @class */ (function () {
    function MessageList() {
    }
    return MessageList;
}());
export { MessageList };
var EmployeeProfilePicture = /** @class */ (function () {
    function EmployeeProfilePicture() {
    }
    return EmployeeProfilePicture;
}());
export { EmployeeProfilePicture };
var UnreadMessageCount = /** @class */ (function () {
    function UnreadMessageCount() {
    }
    return UnreadMessageCount;
}());
export { UnreadMessageCount };
var UserPicture = /** @class */ (function () {
    function UserPicture() {
    }
    return UserPicture;
}());
export { UserPicture };
var LatestScheduleCycle = /** @class */ (function () {
    function LatestScheduleCycle() {
    }
    return LatestScheduleCycle;
}());
export { LatestScheduleCycle };
var ColdStorageFlag = /** @class */ (function () {
    function ColdStorageFlag() {
    }
    return ColdStorageFlag;
}());
export { ColdStorageFlag };
var sectionType = /** @class */ (function () {
    function sectionType() {
    }
    sectionType.lessThanOneMonth = 'lessthanmonth';
    sectionType.olderThanOneMonth = 'olderthanonemonth';
    sectionType.olderThanThreeMonth = 'olderthanthreemonth';
    sectionType.olderThanSixMonth = 'loadolderThan6Month';
    return sectionType;
}());
export { sectionType };
var SectionState = /** @class */ (function () {
    function SectionState() {
    }
    return SectionState;
}());
export { SectionState };
var SectionDetail = /** @class */ (function () {
    function SectionDetail() {
    }
    Object.defineProperty(SectionDetail.prototype, "startDate", {
        get: function () {
            if (sectionType.lessThanOneMonth == this.sectionName) {
                if (_.isNull(this.orderByNameAsc)) {
                    return moment().subtract(1, 'month').toDate();
                }
                else {
                    return moment(appConfig.minCorrectDate).toDate();
                }
            }
            else if (sectionType.olderThanOneMonth == this.sectionName) {
                return moment().subtract(3, 'month').toDate();
            }
            else if (sectionType.olderThanThreeMonth == this.sectionName) {
                return moment().subtract(6, 'month').toDate();
            }
            else if (sectionType.olderThanSixMonth == this.sectionName) {
                return moment(appConfig.minCorrectDate).toDate();
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(SectionDetail.prototype, "endDate", {
        get: function () {
            if (sectionType.lessThanOneMonth == this.sectionName) {
                return moment().add(1, 'day').toDate();
            }
            else if (sectionType.olderThanOneMonth == this.sectionName) {
                return moment().subtract(1, 'month').toDate();
            }
            else if (sectionType.olderThanThreeMonth == this.sectionName) {
                return moment().subtract(3, 'month').toDate();
            }
            else if (sectionType.olderThanSixMonth == this.sectionName) {
                return moment().subtract(6, 'month').toDate();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionDetail.prototype, "includeCold", {
        get: function () {
            return this.sectionName == sectionType.olderThanSixMonth ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    return SectionDetail;
}());
export { SectionDetail };
var MessageCountRequest = /** @class */ (function () {
    function MessageCountRequest() {
    }
    return MessageCountRequest;
}());
export { MessageCountRequest };
var MessageCountResponse = /** @class */ (function () {
    function MessageCountResponse() {
    }
    return MessageCountResponse;
}());
export { MessageCountResponse };
var TabMessages = /** @class */ (function () {
    function TabMessages() {
    }
    return TabMessages;
}());
export { TabMessages };
var SlateSection = /** @class */ (function () {
    function SlateSection() {
    }
    return SlateSection;
}());
export { SlateSection };
