import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsTerminationHistoryEntry = /** @class */ (function () {
    function EmployeeSectionsTerminationHistoryEntry() {
    }
    return EmployeeSectionsTerminationHistoryEntry;
}());
export { EmployeeSectionsTerminationHistoryEntry };
var EmployeeSectionsTerminationHistoryEntries = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsTerminationHistoryEntries, _super);
    function EmployeeSectionsTerminationHistoryEntries() {
        var _this = _super.call(this) || this;
        _this.terminationEntries = [];
        return _this;
    }
    return EmployeeSectionsTerminationHistoryEntries;
}(EmployeeSectionsBase));
export { EmployeeSectionsTerminationHistoryEntries };
