import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
import { appConfig } from '../../../../../app/app.config';
import * as moment from 'moment';
var EmployeeSectionAccrualTransactions = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionAccrualTransactions, _super);
    function EmployeeSectionAccrualTransactions() {
        var _this = _super.call(this) || this;
        _this.records = [];
        return _this;
    }
    return EmployeeSectionAccrualTransactions;
}(EmployeeSectionsBase));
export { EmployeeSectionAccrualTransactions };
var EmployeeAccrualTransaction = /** @class */ (function () {
    function EmployeeAccrualTransaction() {
        this.isDirty = false;
    }
    Object.defineProperty(EmployeeAccrualTransaction.prototype, "transactionDateFormat", {
        get: function () {
            return moment(this.transactionDate).format(appConfig.dateFormat);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeAccrualTransaction.prototype, "updatedDate", {
        get: function () {
            return moment(this.modifiedOn).format(appConfig.dateTimeFormatUS);
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeAccrualTransaction;
}());
export { EmployeeAccrualTransaction };
