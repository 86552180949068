import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IApplicationConfig, appConfig } from '../../../../app.config';
import { TimeclockDailySummary } from '../../../../organization/models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-header-timeclocks-list',
    templateUrl: 'header-timeclocks-list.component.html',
    styleUrls: [
        'header-timeclocks-list.component.scss'
    ]
})
export class HeaderTimeclocksListComponent {

    @Input()
    public displayedRecords: TimeclockDailySummary[];

    @Input()
    public totalRecordsCount: number = 0;

    @Output()
    public close: EventEmitter<any>;

    public appConfig: IApplicationConfig;

    constructor() {
        this.appConfig = appConfig;
        this.close = new EventEmitter();
    }

    public onViewAllClick(): void {
        this.close.next();
    }

}
