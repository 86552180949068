import * as tslib_1 from "tslib";
import { TreeComponent } from '../../../tree/index';
var QueryBuilderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(QueryBuilderComponent, _super);
    function QueryBuilderComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return QueryBuilderComponent;
}(TreeComponent));
export { QueryBuilderComponent };
