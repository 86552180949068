import * as _ from 'lodash';
import { ReportParameter } from '../../../models/index';
var ReportIntegerParameterComponent = /** @class */ (function () {
    function ReportIntegerParameterComponent() {
        this.includeThousandsSeparator = false;
    }
    Object.defineProperty(ReportIntegerParameterComponent.prototype, "parameter", {
        get: function () {
            return this.m_parameter;
        },
        set: function (value) {
            this.processParameter(value);
            this.m_parameter = value;
        },
        enumerable: true,
        configurable: true
    });
    ReportIntegerParameterComponent.prototype.processParameter = function (parameter) {
        if (!(_.isNull(parameter.defaultValue) || _.isUndefined(parameter.defaultValue))) {
            parameter.value = parameter.defaultValue;
        }
        else {
            parameter.value = 0;
        }
    };
    return ReportIntegerParameterComponent;
}());
export { ReportIntegerParameterComponent };
