import { ApprovalPayCodesNavigationService } from './../../../../../common/services/navigation/approval-pay-codes-navigation.service';
import { ApprovalOvertimesNavigationService } from './../../../../../common/services/navigation/approval-overtimes-navigation.service';
import { AccrualsNavigationService } from './../../../../../common/services/navigation/accruals-navigation.service';
import { ConfirmOptions, ConfirmDialogComponent } from './../../../../../common/components/confirm-dialog/confirm-dialog.component';
import { PayrollExportSubmitResults } from './../../../../../time-and-attendance/models/timecards/payroll-export-submit-result';
import { PayrollExportTestState } from './../../../models/payroll-export-test/payroll-export-test-state';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import * as _ from 'lodash';
import { OrganizationPayroll } from '../../../models/payroll-export-test/organization-payroll';
import { PayrollExportTestManagementService } from '../../../services/payroll-export-test/payroll-export-test-management.service';
import { PayrollExportTestsContainer } from '../../../models/payroll-export-test/payroll-export-test-container';
import { TimecardsNavigationService, PaycodesConfigurationNavigationService } from './../../../../../common/services/navigation/index';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoDialogComponent, ModalService } from './../../../../../../app/common';
import { OrgPayrollExportState } from '../../../models/payroll-export-test/org-payroll-export-state';
import { OrgPayrollExportStatus } from '../../../models/payroll-export-test/org-payroll-export-status';
import { ExportPayrollTimeInterval } from '../../../models/payroll-export-test/export-payroll-time-interval';
import { PayrollExportSignalrService } from '../../../services/payroll-export/payroll-export-signalr.service';
import { SessionService } from '../../../../../core/services/index';
@Component({
    moduleId: module.id,
    selector: 'slx-payroll-export-test-section',
    templateUrl: 'payroll-export-test-section.component.html',
    styleUrls: ['payroll-export-test-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayrollExportTestSectionComponent implements OnInit, OnDestroy {
    public appConfig: IApplicationConfig;
    public isCollapsed: boolean = true;
    public isLoading: boolean = false;
    public isAccrualModuleEnabled: boolean = false;
    @Input()
    public organization: OrganizationPayroll;
    public tests: PayrollExportTestState[];

    public durationInSeconds: number;
    public frequencyInSeconds: number;
    public frequencyInML: number;
    public exportTimeInterval: ExportPayrollTimeInterval[] = [];
    public exportedIds: ExportPayrollTimeInterval[] = [];
    public isNextgenPayrollEnabled: boolean = false;
    public exportToPayrollFrequency: number = 0;
    public exportToPayrollDuration: number  = 0;
    public isexportToPayrollClicked: boolean = false;
    public isNextgenTimeExportEnabled: boolean = false;
    public nextgenPhasedRollout: boolean = false;
    private liveWithTAExport = 'LiveWithTAExport';

  constructor(
    public manageService: PayrollExportTestManagementService,
    private changeDetector: ChangeDetectorRef,
    private router: Router, private route: ActivatedRoute,

    public modalService: ModalService,
    private payrollExportSignalrService: PayrollExportSignalrService,
    private sessionService: SessionService,
  ) {
        this.manageService.updateexportPayrollStatus.subscribe((value: OrganizationPayroll) => {
            if(value.organizationPayrollId === this.organization.organizationPayrollId) {
                this.organization.status = value.status;
                this.organization.isCheckingExportStatus = value.isCheckingExportStatus;
                this.detectChanges();
            }
        });

        this.appConfig = appConfig;
    }
    public ngOnInit() {
      this.manageService.subscribeToIsAccrualModuleEnabled((result: boolean) => {
        this.isAccrualModuleEnabled = result;
      });

      //signalr notify when payroll export status is failed
      this.payrollExportSignalrService.payrollExportedStatus$.subscribe((args: any[]) => {
        let orgPayrollExportStatus: OrgPayrollExportStatus = args && args.length > 2 ? JSON.parse(args[2]) : null;
        if(this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase() && this.organization.organizationPayrollId === orgPayrollExportStatus.PayrollRecordId){
            this.updateStatusAndEnableBtn(this.organization.organizationPayrollId, OrgPayrollExportState.ExportFailed, false);
        }
      })

      this.getSettings();
    }
    public get isMobile(): boolean {
        return (screen.width <= appConfig.mobileMaxWidth);
    }

    public collapsedChange(isCollapsed: boolean): void {
        if (!isCollapsed) {
            this.getTests();
        }
    }

  public runTests(): void {
   
        this.isLoading = true;
        this.manageService.runTests(this.organization.organization.id)
            .then((value: boolean) => {
                this.getTests();
            });
    }

    public exportToPayroll(): void {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Export Timecards',
          'Save Timecards to file?',
          this.manageService.modalService,
          (result: boolean) => {
            if (result) {
                if(this.isTAExportNGPChanges()){
                    this.updateStatusAndEnableBtn(this.organization.organizationPayrollId, OrgPayrollExportState.InProgress, true);
                }
                this.manageService.exportToPayroll(this.organization.organization.id)
                    .then((value: boolean) => {
                        if (value) {
                            this.getTests();
                        }
                        this.isCollapsed = false;

                        if(this.isTAExportNGPChanges()) {
                            this.isexportToPayrollClicked = true;
                            //reset duration when user click on Export to Payroll button
                            this.durationInSeconds = this.exportToPayrollDuration;

                            //Add organizationPayrollId in session when click on Export to Payroll button
                            let val = new ExportPayrollTimeInterval();
                            val.organizationPayrollId = this.organization.organizationPayrollId;
                            val.intervalSeconds = this.exportToPayrollFrequency;

                            this.exportTimeInterval = JSON.parse(sessionStorage.getItem('PayrollExportTimeInterval'));
                            this.exportTimeInterval.push(val);
                    
                            sessionStorage.setItem('PayrollExportTimeInterval', JSON.stringify(this.exportTimeInterval));

                            this.exportPayrollStatus();
                        }
                    });
            }
          },
          options
        );
    }

    public requestExport(): void {
        this.isLoading = true;
        this.manageService.requestExport(this.organization.organization.id)
        .then((value: PayrollExportSubmitResults) => {
            if (!value.hasErrors) {
                this.getTests();
            }
            this.isCollapsed = false;
            this.isLoading = false;
            this.detectChanges();
        });
    }

    public lockOrganizationPayroll(): void {
        this.isLoading = true;
        this.manageService.lockOrganizationPayroll(this.organization, true)
        .then((value: boolean) => {
            this.isLoading = false;
            this.detectChanges();
        });
    }

    public unlockOrganizationPayroll(): void {
        this.isLoading = true;
        this.manageService.lockOrganizationPayroll(this.organization, false)
        .then((value: boolean) => {
            this.isLoading = false;
            this.detectChanges();
        });
    }

    public onTestClicked(test: PayrollExportTestState): void {
        const orgLevelId: number = this.organization.organization.orgLevelId;
        const startDate: Date = this.manageService.selectedPayCycle.startDate;
        const endDate: Date = this.manageService.selectedPayCycle.endDate;

        switch(test.testId) {
            case 1: case 4: case 5: case 6: case 9:
                const tcNavi: TimecardsNavigationService = new TimecardsNavigationService(this.router, this.route);
                tcNavi.NavigateToTimecardsByOrgLevel(orgLevelId, startDate, endDate);
            break;

            case 2: //unapproved OT
                const aoNavi: ApprovalOvertimesNavigationService = new ApprovalOvertimesNavigationService(this.router, this.route);
                aoNavi.navigateToOvertimeApproval(startDate, endDate, null, orgLevelId);
            break;

            case 3: //Pending approval required codes (approval required codes screen for payroll cycle and organization in question)
                const apcNavi: ApprovalPayCodesNavigationService = new ApprovalPayCodesNavigationService(this.router, this.route);
                apcNavi.navigateToPayCodesApproval(startDate, endDate, orgLevelId);
            break;

            case 7: //Paycodes screen for organization in question
                const pcNavi: PaycodesConfigurationNavigationService = new PaycodesConfigurationNavigationService(this.router, this.route);
                pcNavi.navigateToPaycodesConfiguration(orgLevelId);
            break;

            case 8: //Negative accruals
              {
                if (!this.isAccrualModuleEnabled) {
                    InfoDialogComponent.OpenDialog('Warning', this.negativeAccrual, this.modalService);
                    return;
                }

                const accrualsNavi: AccrualsNavigationService = new AccrualsNavigationService(this.router, this.route);
                accrualsNavi.navigateToBalance(endDate, orgLevelId);
                break;
              }

        }
    }

    private getTests(): void {
        this.isLoading = true;
        this.manageService.getTests(this.organization.organizationPayrollId)
        .then((value: PayrollExportTestsContainer) => {
            this.organization.runAt = value.organizationPayroll.runAt;
            this.tests = value.tests;
            this.isLoading = false;
            this.isCollapsed = false;
            this.detectChanges();

            if(this.isTAExportNGPChanges() && this.isexportToPayrollClicked){
                this.updateStatusAndEnableBtn(this.organization.organizationPayrollId, OrgPayrollExportState.InProgress, true);
                this.isexportToPayrollClicked = false;
            }
        });
    }

    private detectChanges(): void {
        this.changeDetector.markForCheck();
        if (!this.changeDetector['destroyed']) {
            this.changeDetector.detectChanges();
        }
    }

    public get negativeAccrual(): string {
          let negativeAccrual: string;
           this.tests.forEach(x => {
            if (x.testId == 8) {
                negativeAccrual = x.summary;
            }
        });
        return negativeAccrual;
    }
        
    public async getSettings(): Promise<void> {
        this.isNextgenPayrollEnabled = this.manageService.isNextgenPayrollEnabled;
        this.exportToPayrollFrequency = this.manageService.exportToPayrollFrequency;
        this.exportToPayrollDuration = this.manageService.exportToPayrollDuration;
        this.isNextgenTimeExportEnabled = this.manageService.isNextgenTimeExportEnabled;
        this.nextgenPhasedRollout = this.manageService.nextgenPhasedRollout;
        this.durationInSeconds = this.exportToPayrollDuration;
        this.frequencyInSeconds = this.exportToPayrollFrequency;
        this.frequencyInML = this.frequencyInSeconds * 1000

        if(this.isTAExportNGPChanges()) {
            let exportIds = JSON.parse(sessionStorage.getItem('PayrollExportTimeInterval'));

            if(exportIds !== null && exportIds !== undefined && exportIds.length > 0) {
                _.map(exportIds, (item: any) => {
                    if(item.organizationPayrollId === this.organization.organizationPayrollId) {
                        this.updateStatusAndEnableBtn(this.organization.organizationPayrollId, OrgPayrollExportState.InProgress, true);
                        this.exportPayrollStatus();
                    }
                });
            }
        }
    }

    public ngOnDestroy(): void {
        clearInterval(this.manageService.intervalId);
    }

    public exportPayrollStatus() {
        //clear timer before starting
        if(this.manageService.intervalId) {
            clearInterval(this.manageService.intervalId);
        }

        //update duration in session for each record
        this.exportedIds = JSON.parse(sessionStorage.getItem('PayrollExportTimeInterval'));
        
        //run time interval to update export status
        this.manageService.intervalId = setInterval(() => {
            if(this.durationInSeconds > 0 && this.exportedIds !== null 
                && this.exportedIds !== undefined && this.exportedIds.length > 0) {
                //Call API to get export status
                let Ids = Object.keys(this.exportedIds).map(key => this.exportedIds[key].organizationPayrollId).join(",");
                this.updateStatus(Ids);
            } else {
                //clear interval
                if(this.manageService.intervalId) {
                    clearInterval(this.manageService.intervalId);
                }
            }

            this.durationInSeconds = this.durationInSeconds - this.frequencyInSeconds;
            //Update values in SessionStorage
            this.updateSessionStorage();
          }, this.frequencyInML);
    }

    private updateStatus(exportedIds: string): void {
        this.manageService.updateStatus(exportedIds)
        .then((value: OrgPayrollExportStatus[]) => {
            let failedRecords = _.filter(value, (item) => item.Status === OrgPayrollExportState.ExportFailed);

            //Removed Ids which were failed and update status
            for(let i = 0; i < failedRecords.length; i++) 
            {
                _.map(this.exportedIds, (item: any) => {
                    if(item.organizationPayrollId === failedRecords[i].PayrollRecordId){
                        var updateStatus = new OrganizationPayroll();
                        updateStatus.status = OrgPayrollExportState.ExportFailed;
                        updateStatus.organizationPayrollId = item.organizationPayrollId;
                        updateStatus.isCheckingExportStatus = false;
                        this.manageService.updateexportPayrollStatus.next(updateStatus);
                    }
                });
                this.exportedIds.splice(i, 1);
            }
            sessionStorage.setItem('PayrollExportTimeInterval', JSON.stringify(this.exportedIds)); 
        });
    }

    private updateStatusAndEnableBtn(organizationPayrollId: number, state: number, isEnabled: boolean): void {
        var value = this.manageService.updateStatusAndEnableBtn(organizationPayrollId, state, isEnabled);
        this.organization.status = value.status;
        this.organization.isCheckingExportStatus = value.isCheckingExportStatus;
        this.detectChanges();
    }

    private updateSessionStorage() {
        if(this.exportedIds !== null) {
            for(let i = 0; i < this.exportedIds.length; i++) 
            {
                if(this.exportedIds[i].intervalSeconds >= this.exportToPayrollDuration) {
                    _.map(this.exportedIds, (item: any) => {
                        if(this.exportedIds[i].organizationPayrollId === item.organizationPayrollId){
                            var updateStatus = new OrganizationPayroll();
                            updateStatus.status = OrgPayrollExportState.ExportSuccess;
                            updateStatus.organizationPayrollId = item.organizationPayrollId;
                            updateStatus.isCheckingExportStatus = false;
                            this.manageService.updateexportPayrollStatus.next(updateStatus);
                        }
                    });
                    this.exportedIds.splice(i, 1);                        
                } else {
                    this.exportedIds[i].intervalSeconds = this.exportedIds[i].intervalSeconds + this.exportToPayrollFrequency
                }
            }
            sessionStorage.setItem('PayrollExportTimeInterval', JSON.stringify(this.exportedIds)); 
        }
    }

    public isTAExportNGPChanges() {
        if((!this.nextgenPhasedRollout && this.isNextgenPayrollEnabled && this.isNextgenTimeExportEnabled)
            || (this.nextgenPhasedRollout && this.isNextgenPayrollEnabled && this.organization.payrollAppStatus === this.liveWithTAExport)) {
                return true;
        } else {
            return false;
        }
    }
}

