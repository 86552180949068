export interface IBenefitEligibilityRuleStatement {
  ruleId: number;
  variableCode: string;
  predicate: string;
  value: string;
  groupId: number;
  sequence: number;
}

export class BenefitEligibilityRuleStatement {
  public readonly ruleId: number;
  public readonly variableCode: string;
  public readonly predicate: string;
  public readonly value: string;
  public readonly groupId: number;
  public readonly sequence: number;

  constructor({ ruleId, variableCode, predicate, value, groupId, sequence }: Partial<BenefitEligibilityRuleStatement>) {
    this.ruleId = ruleId;
    this.variableCode = variableCode;
    this.predicate = predicate;
    this.value = value;
    this.groupId = groupId;
    this.sequence = sequence;
  }
}
