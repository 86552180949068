import { Component, OnInit, Provider } from '@angular/core';
import { ConfigForceLoad, MidnightToMidnightReport, MidnightToMidnightReportData } from '../../models';
import { DialogModeSize, DialogOptions, DialogOptions2, IDialog, ModalService } from './../../../../app/common';
import { Assert } from './../../../../app/framework';
import * as _ from 'lodash';
import { ReportsApiService } from '../../services/reports-api.service';

@Component({
  selector: 'slx-midnight-to-midnight-report-dialog',
  templateUrl: './midnight-to-midnight-report-dialog.component.html',
  styleUrls: ['./midnight-to-midnight-report-dialog.component.scss']
})
export class MidnightToMidnightReportDialogComponent implements IDialog {

  public hasValueChange: boolean = false;
  public dialogResult: boolean;
  public reportDefinition: MidnightToMidnightReportData;
  public forceLoad: boolean = false;
  public modifiedData: any[] = [];
  public modifiedResult: MidnightToMidnightReport[] = [];
  public isLoading: boolean = false;

  constructor(
    private modalService: ModalService,
    private options: DialogOptions,
    forceLoad: ConfigForceLoad,
    reportDefinition: MidnightToMidnightReportData,
    public reportApi: ReportsApiService) {
    Assert.isNotNull(reportDefinition, 'reportDefinition');
    this.reportDefinition = reportDefinition;
    this.forceLoad = forceLoad.midnightToMidnightReportConfig;
  }

  public static openDialog(request: MidnightToMidnightReportData, configForceLoad: ConfigForceLoad, modalService: ModalService, callback: (result: boolean, request: any) => void): MidnightToMidnightReportDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.width = 767;
    dialogOptions.height = 557;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: MidnightToMidnightReportData,
        useValue: request
      },
      {
        provide: ConfigForceLoad,
        useValue: configForceLoad
      }
    ];
    const title = `Edit Midnight to Midnight Settings`;
    let component = modalService.globalAnchor.openDialog2(
      MidnightToMidnightReportDialogComponent,
      title,
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId: string) => {
        callback(result, request);
      }
    );

    return component;
  }

  ngOnInit() {
  }

  public onValueChanged(hasValueChange: boolean) {
    this.hasValueChange = hasValueChange;
  }

  public modifiedDataChanges(value: any) {
    this.modifiedData = value;
    let modifiedIds = Array.from(new Set(this.modifiedData[0]));
    this.modifiedResult = _.filter(this.modifiedData[1], (item) => modifiedIds.includes(item.id));
    console.log(this.modifiedResult);
  }

  public onSave(): void {
    this.dialogResult = true;
    this.isLoading = true;
    this.reportApi.postMidnightToMidnightConfigData(this.reportDefinition.orgLevelId, this.modifiedResult).then((data: any) => {
      this.isLoading = false;
      this.modalService.closeWindow(this.options.windowUniqueId);
    });
  }
  public onCancel(): void {
    this.closeWindow();
  }

  private closeWindow() {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

}
