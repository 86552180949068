import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { FieldsMeta } from '../../../core/models/index';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { ResponseBody } from '../../../core/models/api/response-body';

import { appConfig } from '../../../app.config';
import { accrualsConfig } from '../accruals.config';
import {
  IAccrualBalances,
  AccrualBalances,
  IAccrualsTransactionRecord,
  AccrualsTransactionRecord,
  AccrualRecalculate,
  IAccrualRecalculate
} from '../models/index';
import { AccrualsMapService } from './accruals-map.service';
import { HttpRequest } from '@angular/common/http';

@Injectable()
export class AccrualsApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private mapService: AccrualsMapService,
    private urlParamsService: UrlParamsService
  ) { }

  public async getAccrualBalances(orgLevelId: number, effectiveDate: Date): Promise<AccrualBalances> {
    const url: string = `${this.getAccrualsRootApi()}/${accrualsConfig.api.accruals.balances}/${orgLevelId}/all`;

    const request = this.urlParamsService.createGetRequest(url, {
      effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate)
    });

    return this.apiUtilService
      .request<IAccrualBalances, FieldsMeta>(request)
      .then((response: ResponseBody<IAccrualBalances, FieldsMeta>) => this.mapService.mapAccrualBalances(response.data, response.meta));
  }

  public async getAccrualsTransactionRecords(orgLevelId: number, sDate: Date, eDate: Date): Promise<any> {
    const url: string = `${this.getAccrualsRootApi()}/${accrualsConfig.api.accruals.transaction}/${orgLevelId}/all`;

    const request = this.urlParamsService.createGetRequest(url, {
      startDate: dateTimeUtils.convertToDtoString(sDate),
      endDate: dateTimeUtils.convertToDtoString(eDate)
    });

    return this.apiUtilService
      .request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapAccrualsTransactions(response.data, response.meta));
  }


  public async getAccrualsRecalculateData(orgLevelId: number): Promise<AccrualRecalculate[]> {
    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.status.root}/${accrualsConfig.api.accruals.status.calculationRequest}`;
    const request = this.urlParamsService.createGetRequest(url)

    return this.apiUtilService
      .request<IAccrualRecalculate[], FieldsMeta>(request)
      .then((response: ResponseBody<IAccrualRecalculate[], FieldsMeta>) => this.mapService.mapAccrualsRecalculateRecords(response.data));
  }

  public postAccrualsRecalculate(orgLevelId: number): Promise<any> {

    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.submit.root}/${accrualsConfig.api.accruals.submit.recalculate}`;
    let body: any;
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<number, FieldsMeta>) => response.status
      );
    return promise;
  }

  private getAccrualsRootApi(): string {
    return `${this.getApiRoot()}/${accrualsConfig.api.accruals.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
