import { Component, Input, OnInit, Provider } from '@angular/core';
import * as _ from 'lodash';

import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { ScheduleAbsence } from '../../../../organization/models/lookup/index';
import { ShiftPhoneCall, ShiftEligibleEmployee } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-shift-replacement-phone-call',
  templateUrl: 'shift-replacement-phone-call.component.html',
  styleUrls: ['shift-replacement-phone-call.component.scss']
})
export class ShiftReplacementPhoneCallComponent implements OnInit, IDialog {

  public appConfig: IApplicationConfig;
  public phoneCall: ShiftPhoneCall;
  public options: DialogOptions;
  public dialogResult: boolean;
  public record: ShiftEligibleEmployee;
  public isHomePhoneCall: boolean;
  public isMobilePhoneCall: boolean;
  public isAltPhoneCall: boolean;

  private modalService: ModalService;

  public static openDialog(modalService: ModalService, record: ShiftEligibleEmployee, callback: (result: boolean, phoneCall: ShiftPhoneCall) => void): ShiftReplacementPhoneCallComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 400;
    dialogOptions.width = 600;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ShiftEligibleEmployee,
        useValue: record
      },

    ];
    let dialog: ShiftReplacementPhoneCallComponent = modalService.globalAnchor
      .openDialog(ShiftReplacementPhoneCallComponent, 'Phone Call', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.phoneCall);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    record: ShiftEligibleEmployee
  ) {
    this.modalService = modalService;
    this.options = options;
    this.record = record;
    if (this.record.phoneCall) {
      this.phoneCall = this.record.phoneCall;
    } else {
      this.phoneCall = new ShiftPhoneCall();
    }
    this.onHomePhone();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public onHomePhone(): void {
    this.isHomePhoneCall = true;
    this.isMobilePhoneCall = false;
    this.isAltPhoneCall = false;
    this.phoneCall.phoneNumber = this.record.homePhone;
  }

  public onMobilePhone(): void {
    this.isHomePhoneCall = false;
    this.isMobilePhoneCall = true;
    this.isAltPhoneCall = false;
    this.phoneCall.phoneNumber = this.record.mobilePhone;
  }

  public onAltPhone(): void {
    this.isHomePhoneCall = false;
    this.isMobilePhoneCall = false;
    this.isAltPhoneCall = true;
    this.phoneCall.phoneNumber = this.record.altPhone;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
