import * as tslib_1 from "tslib";
import { OnDestroy, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { EmployeesPunchesContainer, PunchesDisplaySettings, PunchesEventFilter } from '../../../models/index';
import { PunchesManagementService } from '../../../services/punches/punches-management.service';
import { unsubscribe } from '../../../../core/decorators/index';
import { Lookup, Position, LookupType } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';
import { StateManagementService } from '../../../../common/services/index';
import { StateResetTypes } from '../../../../core/models/index';
var PunchesFilterComponent = /** @class */ (function () {
    function PunchesFilterComponent(lookupService, punchesManagementService, stateManagement) {
        var _this = this;
        this.lookupService = lookupService;
        this.punchesManagementService = punchesManagementService;
        this.stateManagement = stateManagement;
        this.filterControlKey = 'EmployeePunchesFilters';
        this.filtersRestored = false;
        this.filter = new PunchesDisplaySettings();
        this.filter.eventFilter = new PunchesEventFilter();
        this.stateInitSubscription = this.stateManagement.onInit$.subscribe(function () {
            _this.filter = _this.restoreFilters();
            _this.shiftsFilter = _this.getShiftsFilter(_this.orgLevelId, _this.filter);
            _this.positionsFilter = _this.getPositionsFilter(_this.orgLevelId, _this.filter);
            _this.punchesManagementService.setFilter(_this.filter);
            _this.filtersRestored = true;
        });
        this.close = new EventEmitter();
        this.filteringState = new EventEmitter();
        this.saveFiltersSubscription = this.punchesManagementService.saveFilters$.subscribe(function () {
            _this.saveFilters(_this.filter);
        });
    }
    Object.defineProperty(PunchesFilterComponent.prototype, "orgLevelId", {
        get: function () {
            return this.m_orgLevelId;
        },
        set: function (value) {
            this.m_orgLevelId = value;
            if (value > 0 && !!this.filter) {
                this.shiftsFilter = this.getShiftsFilter(this.orgLevelId, this.filter);
                this.positionsFilter = this.getPositionsFilter(this.orgLevelId, this.filter);
            }
        },
        enumerable: true,
        configurable: true
    });
    PunchesFilterComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PunchesFilterComponent.prototype.ngOnChanges = function (changes) {
        if (changes['orgLevelId']) {
            this.loadLookup();
        }
        if (changes['container']) {
            this.createLookups();
        }
    };
    PunchesFilterComponent.prototype.onFiltersChanged = function () {
        var _this = this;
        if (!this.filtersRestored) {
            return;
        }
        this.filteringState.emit(true);
        setTimeout(function () {
            _this.setShiftsFilter(_this.orgLevelId, _this.filter, _this.shiftsFilter);
            _this.setPositionsFilter(_this.orgLevelId, _this.filter, _this.positionsFilter);
            _this.punchesManagementService.applyFilter(_this.container, _this.filter);
            _this.saveFilters(_this.filter);
            _this.close.emit(true);
            _this.filteringState.emit(false);
        }, 5);
    };
    PunchesFilterComponent.prototype.onClose = function () {
        this.close.emit(false);
    };
    PunchesFilterComponent.prototype.createLookups = function () {
        if (!this.container || !this.shiftLookup) {
            return;
        }
        this.positionLookup = new Lookup();
        this.positionLookup.titleField = 'name';
        this.positionLookup.valueField = 'id';
        var positions = _.filter(_.map(this.container.entities, function (emp) { return emp.header.position; }), function (pos) { return !!pos && _.isString(pos.name) && pos.name.length > 0; });
        this.positionLookup.items = _.uniqBy(positions, 'id');
        var shiftsIds = _.reduce(this.container.entities, function (shifts, emp) {
            return shifts.concat(_.map(emp.header.scheduledShifts, function (s) { return s.shift.id; }));
        }, []);
        this.shiftLookup.items = _.filter(this.shiftLookup.items, function (s) { return _.includes(shiftsIds, s.id); });
        this.shiftLookup.items = _.sortBy(this.shiftLookup.items, ['group', 'startDate', 'endDate']);
    };
    PunchesFilterComponent.prototype.loadLookup = function () {
        var _this = this;
        if (!this.orgLevelId) {
            return;
        }
        this.shiftLookup = null;
        this.lookupService.getLookup({ lookupType: LookupType.shift, orgLevelId: this.orgLevelId, employeeId: undefined })
            .then(function (lookup) {
            _this.shiftLookup = lookup;
            _this.createLookups();
        });
    };
    PunchesFilterComponent.prototype.saveFilters = function (filter) {
        this.stateManagement.setControlState(this.filterControlKey, { value: filter }, StateResetTypes.OrgLevelChange);
    };
    PunchesFilterComponent.prototype.restoreFilters = function () {
        var filters;
        var state = this.stateManagement.getControlState(this.filterControlKey);
        if (state && state.value !== undefined) {
            filters = state.value;
            this.restoreEventFilters(filters);
            return filters;
        }
        filters = new PunchesDisplaySettings();
        filters.empMissingPunches = true;
        filters.empInvalidPunches = true;
        filters.empScheduleOnly = true;
        filters.empValid = true;
        filters.empNoPunches = true;
        filters.empRequest = true;
        filters.invalidLogin = true;
        this.restoreEventFilters(filters);
        return filters;
    };
    PunchesFilterComponent.prototype.restoreEventFilters = function (filters) {
        if (!filters.eventFilter) {
            filters.eventFilter = new PunchesEventFilter();
            filters.eventFilter.empPunch = true;
            filters.eventFilter.editPunch = true;
            filters.eventFilter.essRequest = true;
            filters.eventFilter.invalidPunch = true;
            filters.eventFilter.schedule = true;
            filters.eventFilter.invalidLogin = true;
        }
    };
    PunchesFilterComponent.prototype.getShiftsFilter = function (orgLevelId, filters) {
        var shiftFilter = _.get(filters, 'shiftFilter');
        if (_.isObject(shiftFilter) && !_.isArray(shiftFilter)) {
            return _.isArray(shiftFilter[orgLevelId]) ? shiftFilter[orgLevelId] : [];
        }
        return [];
    };
    PunchesFilterComponent.prototype.setShiftsFilter = function (orgLevelId, filters, s) {
        var shiftFilter = _.get(filters, 'shiftFilter');
        var shifts = _.isArray(s) ? s.concat() : [];
        if (_.isObject(shiftFilter) && !_.isArray(shiftFilter)) {
            shiftFilter[orgLevelId] = shifts;
        }
        if (!filters.shiftFilter) {
            filters.shiftFilter = {};
        }
        filters.shiftFilter[orgLevelId] = shifts;
    };
    PunchesFilterComponent.prototype.getPositionsFilter = function (orgLevelId, filters) {
        var posFilter = _.get(filters, 'positionFilter');
        if (_.isObject(posFilter) && !_.isArray(posFilter)) {
            return _.isArray(posFilter[orgLevelId]) ? posFilter[orgLevelId] : [];
        }
        return [];
    };
    PunchesFilterComponent.prototype.setPositionsFilter = function (orgLevelId, filters, s) {
        var posFilter = _.get(filters, 'positionFilter');
        var positions = _.isArray(s) ? s.concat() : [];
        if (_.isObject(posFilter) && !_.isArray(posFilter)) {
            posFilter[orgLevelId] = positions;
        }
        if (!filters.positionFilter) {
            filters.positionFilter = {};
        }
        filters.positionFilter[orgLevelId] = positions;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesFilterComponent.prototype, "stateInitSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesFilterComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesFilterComponent.prototype, "saveFiltersSubscription", void 0);
    return PunchesFilterComponent;
}());
export { PunchesFilterComponent };
