<slx-spinner [show]="state.isLoading">
  <div class="header-container scheduler shift-replacement-selector">

    <slx-shift-replacement-header [settings]="settings" (settingsChanged)="onSettingsChanged($event)"
      (columnsChanged)="onColumnsChanged()"></slx-shift-replacement-header>

    <div class="filters-container">
      <button type="button"
        class="slx-button slx-with-icon slx-mobile-adapted"
        [popper]="filters"
        [popperTrigger]="'click'"
        [popperPlacement]="'bottom-start'"
        [popperDisableStyle]="true"
      >
        <i class="fas fa-sliders-h slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Filters</span>
      </button>

      <popper-content #filters class="slx-popper">
        <div class="filters-list">
          <div>
            <slx-checkbox-toggle className="toggle-left" [(ngModel)]="settings.request.showDayOffEmployees" (ngModelChange)="onRefreshByCheck()"
                caption="Show Day Off Employees" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
            </div>
          <div>
            <slx-checkbox-toggle className="toggle-left red-label" [(ngModel)]="settings.request.showAgencyEmployees" (ngModelChange)="onRefreshByCheck()"
              caption="Show Agency Employees" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
          </div>
          <div>
            <slx-checkbox-toggle className="toggle-left green-label" [(ngModel)]="settings.request.showAvailabilityRecords" (ngModelChange)="onRefreshByCheck()"
              caption="Show Availability Records" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
          </div>
          <div>
            <slx-checkbox-toggle className="toggle-left blue-label" [(ngModel)]="settings.request.showEmployeesOtherShifts" (ngModelChange)="onRefreshByCheck()"
              caption="Show Employees working other shifts that day" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
          </div>
        </div>
      </popper-content>
    </div>

    <div class="spacer"></div>

    <div class="absence-selector" *ngIf="hasAbcenses && !sendSmsModeOn">
      <slx-dropdown-list
        class="slx-wide slx-small-label"
        [options]="scheduleAbsences"
        [(ngModel)]="scheduleAbsence"
        (ngModelChange)="onAbsenceChanged($event)"
        valueField="code" titleField="codeDescription"
        name="scheduleAbsences"
        placeholder="* Absence Reason:"
      ></slx-dropdown-list>

      <div *ngIf="!scheduleAbsence" class="slx-error-block absence-error">
        <span class="right-offset">Please select any Absence</span>
      </div>
    </div>

    <div class="last-flex" *ngIf="settings?.request.showSendSmsButton && !sendSmsModeOn && canSendSms">
      <button type="button"
        (click)="onTurnOnSmsMode()"
        class="slx-button slx-with-icon slx-mobile-adapted"
      >
        <i class="fas fa-mobile-alt slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Send SMS</span>
      </button>
    </div>
  </div>

  <slx-shift-replacement-sms *ngIf="sendSmsModeOn" [message]="smsText"></slx-shift-replacement-sms>

  <div class="employees-list">
    <slx-shift-replacement-grid
      [settings]="settings"
      [shiftReplacementInfo]="shiftReplacementInfo"
    ></slx-shift-replacement-grid>
  </div>

</slx-spinner>
