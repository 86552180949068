export interface IPayrollExportSubmitResults {
  messages: IPayrollExportSubmitResult[];
}

export interface IPayrollExportSubmitResult {
  errorCode: number;
  errorMessage: string;
}

export class PayrollExportSubmitResult {
  public errorCode: number;
  public errorMessage: string;
}

export class PayrollExportSubmitResults {
  public results: PayrollExportSubmitResult[];
  public hasErrors: boolean;
}
