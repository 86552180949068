import { BenAdminMenuManagementService } from './../../../organization/services/navigation/ben-admin/ben-admin-menu-management.service';
import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { StyledUserApplication } from '../../../organization/models/index';
import { SsoNavigationService } from '../../../organization/services/index';
import { appConfig } from '../../../app.config';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../../../common/services/modal/modal.service';
import { InfoDialogComponent } from '../../../common/components/info-dialog/info-dialog.component';
import { DashBoardAlertsModel } from '../../../portal/models/index';
import { DashboardAlertsSelectPopupDialogComponent } from '../dashboard-alerts/dashboard-alerts-select-popup-dialog/dashboard-alerts-select-popup-dialog.component';
var ApplicationItemComponent = /** @class */ (function () {
    function ApplicationItemComponent(navigationService, route, benAdminService, modalService, detector) {
        this.navigationService = navigationService;
        this.route = route;
        this.benAdminService = benAdminService;
        this.modalService = modalService;
        this.detector = detector;
        this.onHasChanges = new EventEmitter();
        this.modifiers = {
            preventOverflow: {
                escapeWithReference: true,
                boundariesElement: 'viewport',
                priority: ['right', 'bottom', 'top', 'left'],
                padding: 10
            }
        };
        this._isExternalLink = false;
        this.modelData = new DashBoardAlertsModel();
    }
    Object.defineProperty(ApplicationItemComponent.prototype, "isExternalLink", {
        get: function () {
            return this._isExternalLink;
        },
        set: function (value) {
            this._isExternalLink = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApplicationItemComponent.prototype, "className", {
        get: function () {
            return this.application && this.application.loadCounters ? 'expanded' : 'collapsed';
        },
        enumerable: true,
        configurable: true
    });
    ApplicationItemComponent.prototype.ngOnInit = function () {
    };
    ApplicationItemComponent.prototype.redirectToApp = function () {
        if (this.benAdminService.isBenAdminItem(this.application.link)) {
            var menuItemState = this.benAdminService.getBenAdminMenuItemState();
            if (!menuItemState.isMenuAccessible) {
                InfoDialogComponent.OpenDialog('Warning', menuItemState.userMessage, this.modalService);
            }
            else {
                this.benAdminService.openBswift();
            }
        }
        else {
            this.openV5();
        }
        return false;
    };
    ApplicationItemComponent.prototype.openBenAdmin = function () {
        var orgLevel = this.getOrglevelIdFromRoute();
        this.benAdminService.openBswift(orgLevel);
    };
    ApplicationItemComponent.prototype.openV5 = function () {
        var orgLevel = this.getOrglevelIdFromRoute();
        var url = appConfig.api.url + "/" + this.application.link + "?orglevel=" + orgLevel;
        this.navigationService.navigationToV5(url);
    };
    ApplicationItemComponent.prototype.getOrglevelIdFromRoute = function () {
        var id = this.route.snapshot.queryParamMap.get('orgLevelId');
        return _.toInteger(id);
    };
    ApplicationItemComponent.prototype.onStartAlerts = function () {
        var _this = this;
        this.modelData.appId = this.application.id;
        this.modelData.appTitle = this.application.title;
        DashboardAlertsSelectPopupDialogComponent.initiateDialog(this.modelData, this.modalService, function (hasChanges) {
            if (hasChanges) {
                _this.emitChanges();
            }
        });
    };
    ApplicationItemComponent.prototype.emitChanges = function () {
        this.onHasChanges.emit(true);
    };
    return ApplicationItemComponent;
}());
export { ApplicationItemComponent };
