import * as tslib_1 from "tslib";
import { LookupService } from './../../../../organization/services/lookup/lookup.service';
import { LookupType } from './../../../../organization/models/lookup/lookup-definition';
import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';
import { appConfig } from './../../../../app.config';
import { MassRateAdjustmentsDefenition, MassRateAdjustmentsDefenitions } from './../../../../organization/models/employee/mass-rate-adjustments';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { OnInit, Provider } from '@angular/core';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { employeeListConfig } from '../../employee-list.config';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
import { DialogOptions2, DialogModeSize, KendoGridStateHelper } from '../../../../common/index';
import { NotificationsService } from '../../../../core/components';
import { process } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { MassPayRateAdjustmentMapService } from '../../services/mass-rate-adjustments/mass-rate-adjustments-map.service';
var MassRateAdjustmentsDialogComponent = /** @class */ (function () {
    function MassRateAdjustmentsDialogComponent(groupActivitiesService, employees, fieldDefinition, massPayRateAdjustmentMapService, orgLevel, modalService, options, notificationsService, appSettingsManageService, lookupService) {
        this.massPayRateAdjustmentMapService = massPayRateAdjustmentMapService;
        this.notificationsService = notificationsService;
        this.appSettingsManageService = appSettingsManageService;
        this.lookupService = lookupService;
        this.isRatecomponentenabled = false;
        this.adjustedEmployees = new MassRateAdjustmentsDefenitions(); //obj for saving 
        this.isLoading = false;
        this.type = true;
        this.isPreviewState = false;
        this.effectiveDate = new Date();
        this.decimalLimit = null;
        this.subscriptions = {};
        this.incrementType = "FlatRate";
        this.increment = 0.00;
        this.incrementFormat = 'n2';
        this.incrementdecimal = 2;
        this.increaseReason = null;
        this.increaseReasons = null;
        this.isIncreaseReasonsEnabled = false;
        this.defaultItem = { id: 0, name: '' };
        this.groupActivitiesService = groupActivitiesService;
        this.employees = employees;
        this.fieldDefinition = fieldDefinition;
        this.pageSize = employees.length;
        this.orgLevel = orgLevel;
        this.initializeGrid();
        this.modalService = modalService;
        this.options = options;
    }
    MassRateAdjustmentsDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.getSettings();
        this.lookupService.getLookup({ lookupType: LookupType.increaseReason, employeeId: undefined, orgLevelId: undefined })
            .then(function (increaseReasons) {
            _this.increaseReasons = increaseReasons;
        });
        this.employeeIds = _.map(this.employees, function (employee) { return employee[employeeListConfig.employeeIdentifierName]; });
        this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, true, this.employeeIds, this.fieldDefinition)
            .then(function (actionEmployees) {
            _this.actionEmployees = actionEmployees;
            _this.validateEmployee(_this.actionEmployees);
            _this.isLoading = false;
            _this.refreshGrid();
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function () {
            _this.refreshGrid();
        });
        this.subscriptions.previewGridRefreshSubscription = this.previewgridState.onRefreshGrid.subscribe(function () {
            _this.refreshPreviewGrid();
        });
    };
    MassRateAdjustmentsDialogComponent.prototype.ngOnDestroy = function () { };
    MassRateAdjustmentsDialogComponent.prototype.initializeGrid = function () {
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [];
        this.previewgridState = new KendoGridStateHelper();
        this.previewgridState.view = null;
        this.previewgridState.state.skip = 0;
        this.previewgridState.state.take = this.pageSize;
        this.previewgridState.state.sort = [];
    };
    MassRateAdjustmentsDialogComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.enableRate4DecimalPlaces = config.EnableRate4DecimalPlaces;
                        this.isIncreaseReasonsEnabled = config.enableIncreaseReason;
                        if (this.enableRate4DecimalPlaces) {
                            this.incrementFormat = 'n4';
                            this.incrementdecimal = 4;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    MassRateAdjustmentsDialogComponent.prototype.refreshGrid = function () {
        if (!this.actionEmployees) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.actionEmployees, this.gridState.state);
    };
    MassRateAdjustmentsDialogComponent.prototype.validateEmployee = function (actionEmployees) {
        var _this = this;
        var selectedEmployees = _.filter(actionEmployees, function (employee) {
            employee.EmployeePayRateComponent = _this.massPayRateAdjustmentMapService.mapPayRateComponentDto(employee.EmpRateComponent);
            if (!employee.LastApprovedDate || moment(_this.effectiveDate).isAfter(employee.LastApprovedDate, 'day')) {
                employee.Selectable = true;
            }
            else {
                employee.Selectable = false;
                employee.isSelected = false;
                employee.Tooltip = "this employee is not eligible for mass rate adjustment as the timecard is already approved for the selected date.";
            }
            return employee;
        });
        this.actionEmployees = selectedEmployees;
    };
    Object.defineProperty(MassRateAdjustmentsDialogComponent.prototype, "canPreview", {
        get: function () {
            if (this.selectedEmployees && this.selectedEmployees.length > 0) {
                this.selectedEmployees = this.selectedEmployees.filter(function (e) { return e.isSelected; });
                return this.selectedEmployees.length > 0;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    MassRateAdjustmentsDialogComponent.prototype.employeesSelect = function (selectedEmployees) {
        this.selectedEmployees = selectedEmployees;
    };
    MassRateAdjustmentsDialogComponent.prototype.onChangeEffectiveDate = function (date) {
        if (_.isDate(date)) {
            this.effectiveDate = date;
            this.validateEmployee(this.actionEmployees);
            this.refreshGrid();
        }
    };
    MassRateAdjustmentsDialogComponent.prototype.loadPreviewGrid = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.groupActivitiesService.getEmployeesWithPayRate(this.orgLevel.id, this.selectedEmployees)
                            .then(function (actionwithRateComponent) {
                            _this.empRateComponent = actionwithRateComponent;
                            _this.isRatecomponentenabled = actionwithRateComponent.isEnableRateComponent;
                            _this.isLoading = false;
                            _this.refreshGrid();
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MassRateAdjustmentsDialogComponent.openDialog = function (modalService, actionDefinition, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.height = 400;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions,
            }
        ];
        resolvedProviders = resolvedProviders.concat(actionDefinition);
        var dialog = modalService.globalAnchor.openDialog2(MassRateAdjustmentsDialogComponent, 'Mass Pay Rate Adjustment', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result);
        });
        return dialog;
    };
    MassRateAdjustmentsDialogComponent.prototype.onisincrementTypeChanged = function (enable) {
        if (enable) {
            this.incrementType = 'FlatRate';
        }
        else {
            this.incrementType = 'Percentage';
        }
    };
    MassRateAdjustmentsDialogComponent.prototype.PreviewDialog = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.isPreviewState) return [3 /*break*/, 2];
                        this.isLoading = true;
                        return [4 /*yield*/, this.loadPreviewGrid()];
                    case 1:
                        _a.sent();
                        this.retreiveMasspayrateDefinitions(this.selectedEmployees);
                        this.buttonChanges();
                        this.refreshPreviewGrid();
                        return [3 /*break*/, 3];
                    case 2:
                        this.adjustedEmployees.empMassPayRateRecords = this.adjustedMassRates;
                        this.adjustedEmployees.increment = this.increment;
                        this.adjustedEmployees.incrementType = this.incrementType;
                        if (!_.isNil(this.increaseReason))
                            this.adjustedEmployees.increaseReason = this.increaseReason.id;
                        this.adjustedEmployees.effectiveDate = moment(this.effectiveDate).format(appConfig.dateTimeFormatUS);
                        this.saveRecords();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MassRateAdjustmentsDialogComponent.prototype.buttonChanges = function () {
        if (!this.isPreviewState) {
            this.isPreviewState = true;
        }
        else {
            this.isPreviewState = false;
        }
    };
    MassRateAdjustmentsDialogComponent.prototype.cancel = function () {
        if (!this.isPreviewState) {
            this.dialogResult = false;
            this.modalService.closeWindow(this.options.windowUniqueId);
        }
        else {
            this.buttonChanges();
        }
    };
    MassRateAdjustmentsDialogComponent.prototype.retreiveMasspayrateDefinitions = function (data) {
        var _this = this;
        this.adjustedMassRates = _.map(data, function (v) { return _this.massPayRateDefinitionChanges(v); });
    };
    MassRateAdjustmentsDialogComponent.prototype.massPayRateDefinitionChanges = function (data) {
        var effectiveDate = moment(this.effectiveDate).format(appConfig.dateTimeFormatUS);
        var dt = new MassRateAdjustmentsDefenition();
        dt.empId = data.EmpId;
        dt.empName = data.EmpName;
        dt.incrementType = this.incrementType;
        dt.increment = this.increment;
        dt.effectiveDate = effectiveDate;
        dt.department = data.EmpDepartment;
        dt.departmentID = data.EmpDepartmentId;
        dt.position = data.JobDescription;
        dt.positionID = data.JobCode;
        dt.union = data.EmpUnion;
        dt.hourlyRate = _.round(data.HourlyRate, this.incrementdecimal);
        var _a = this.getPayRatewithCalculation(data.EmpId, data.HourlyRate), payRateComp = _a.payRateComp, rate = _a.rate;
        dt.payRateComponents = payRateComp;
        dt.revisedHourlyRate = rate;
        return dt;
    };
    MassRateAdjustmentsDialogComponent.prototype.refreshPreviewGrid = function () {
        if (!this.adjustedMassRates) {
            this.previewgridState.view = null;
            return;
        }
        this.previewgridState.view = process(this.adjustedMassRates, this.previewgridState.state);
    };
    MassRateAdjustmentsDialogComponent.prototype.getPayRatewithCalculation = function (empid, rate) {
        var payrates = _.find(this.empRateComponent.empMassPayRateRecords, function (p) { return p.empId === empid; });
        var _a = this.calculatePayRate(payrates.payRateComponents, rate), RateComp = _a.RateComp, payrate = _a.payrate;
        return { payRateComp: RateComp, rate: payrate };
    };
    MassRateAdjustmentsDialogComponent.prototype.calculatePayRate = function (payRatesRecords, payrate) {
        var _this = this;
        var newpayrate = _.cloneDeep(payRatesRecords);
        if (this.incrementType == 'FlatRate') {
            _.forEach(newpayrate, function (rec) {
                if (rec.component == 'Base Rate' || rec.component == 'Base') {
                    rec.rate = _.sumBy([rec.rate, _this.increment]);
                }
            });
        }
        else {
            _.forEach(newpayrate, function (rec) {
                if ((rec.component == 'Base Rate' || rec.component == 'Base') && _this.increment != 0 && rec.rate != 0) {
                    rec.rate = _.round(_.sumBy([rec.rate, _.multiply(rec.rate, _.divide(_this.increment, 100))]), _this.incrementdecimal);
                }
            });
        }
        return { RateComp: newpayrate, payrate: _.round(_.sumBy(newpayrate, function (value) { return value.rate || 0; }), this.incrementdecimal) };
    };
    MassRateAdjustmentsDialogComponent.prototype.saveRecords = function () {
        var _this = this;
        this.isLoading = true;
        this.groupActivitiesService.adjustEmployeesMassrate(this.adjustedEmployees)
            .then(function () {
            _this.notificationsService.success('Mass Pay Rate', 'Mass Pay Rate Adjusted successfully');
            _this.dialogResult = false;
            _this.modalService.closeWindow(_this.options.windowUniqueId);
        })
            .catch(function () {
            _this.isLoading = false;
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], MassRateAdjustmentsDialogComponent.prototype, "subscriptions", void 0);
    return MassRateAdjustmentsDialogComponent;
}());
export { MassRateAdjustmentsDialogComponent };
