import * as tslib_1 from "tslib";
import { AppSettingsManageService } from './../../../../../app-settings/services/app-settings-manage.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { appConfig } from '../../../../../app.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitDetailsConfig, BenefitDetailsGroup, BenefitDetailsLineStandart } from '../../../models/index';
import { BenefitDetailsManagementService, BenefitDetailsStandartManagementService, BenefitDetailsPermissionService, BenefitDetailsValidatorAdapter } from '../../../services/index';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
var BenefitDetailsProviderInfoLineComponent = /** @class */ (function () {
    function BenefitDetailsProviderInfoLineComponent(modalService, commonManService, permissionService, manService, benefitsValidatorAdapter, appSettingsManageService) {
        this.modalService = modalService;
        this.commonManService = commonManService;
        this.permissionService = permissionService;
        this.manService = manService;
        this.benefitsValidatorAdapter = benefitsValidatorAdapter;
        this.appSettingsManageService = appSettingsManageService;
        this.config = new BenefitDetailsConfig();
        this.group = new BenefitDetailsGroup();
        this.min = 1;
        this.max = 99;
        this.step = 1;
        this.isEditMode = false;
        this.isDraftProvider = false;
        this.isValidProviderName = true;
        this.pattern = /[^\w\s]|[_]/gi;
        this.totalEnrolledEmployees = 0;
        this.hasEnrolledEmployees = false;
        this.hasEnrolledEmpTierLevel = false;
        this.enrolledEmpTooltipText = "You cannot change the calculation method as employees are currently enrolled in this plan.";
        this.isBenefitDeductionEndabled = true;
        this.subscriptions = {};
        this.providerLineStandart = new BenefitDetailsLineStandart();
        this.maxValidDate = moment(appConfig.maxCorrectDate).toDate();
    }
    BenefitDetailsProviderInfoLineComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.commonManService
            .subscribeToOrgLevel(function (o) { return (_this.orgLevelId = o.id); });
        this.subscriptions.config = this.commonManService
            .subscribeToChangeConfig(function (config) { return (_this.config = config); });
        this.subscriptions.group = this.commonManService
            .subscribeToSelectGroup(function (group) { return (_this.group = group); });
        this.subscriptions.selectProvider = this.commonManService
            .subscribeToSelectProvider(function (p) {
            _this.isDraftProvider = p.isDraft;
            _this.hasEnrolledEmpTierLevel = false;
            _this.assignValidationParams(!p.isDraft ? p.current.id : null);
        });
        this.subscriptions.providerLine = this.commonManService
            .subscribeToSelectProviderLine(function (v) {
            var line = v.current;
            _this.providerName = line.name || null;
            _this.providerLineStandart = line;
            _this.childAgeLimit = line.childAgeLimit;
            _this.isIncludeInReport = line.includeInReport;
            _this.costFreq = line.costFreq;
            _this.empFreq = line.empContFreq;
            _this.calcMethod = line.calcMethod;
            _this.effectiveStartDate = line.line.startOfStartDate || null;
            _this.effectiveEndDate = line.line.startOfEndDate || null;
            _this.mappedDeductionName = line.mappedDeduction ? line.mappedDeduction.description : null;
            _this.showMappedDeduction = _this.mappedDeductionName && !line.mappedDeduction.isIgnored;
            _this.deductionDate = line.payrollDeductionDate || null;
            _this.hasEnrolledEmployees = _.gt(line.enrollmentCount, 0);
            _this.getSettings();
        });
        this.subscriptions.editMode = this.permissionService
            .subscribeToEditMode(function (v) { return (_this.isEditMode = v.providerInfo); });
        this.subscriptions.enrolledEmployeesInfo = this.manService.subscribeToBenefitEnrolledEmployeesInfo(function (count) {
            _this.totalEnrolledEmployees = count || 0;
        });
        this.subscriptions.enrolledEmployeesChanged = this.manService
            .subscribeToBenefitEnrolledEmployeesChanged(function (hasEnrollEmp) {
            _this.hasEnrolledEmpTierLevel = !_this.isDraftProvider && hasEnrollEmp;
        });
    };
    BenefitDetailsProviderInfoLineComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.subscriptions.enrolledEmployeesChanged = this.providerNameControl.statusChanges
            .subscribe(function (status) {
            if (_this.isEditMode) {
                _this.manService.updateProviderName(_this.providerNameControl.valid ? _this.providerName : '');
            }
        });
    };
    BenefitDetailsProviderInfoLineComponent.prototype.ngOnDestroy = function () { };
    BenefitDetailsProviderInfoLineComponent.prototype.onChangeChildAgeLimit = function () {
        if (this.providerLineStandart.childAgeLimit !== this.childAgeLimit) {
            if (this.totalEnrolledEmployees > 0 && this.childAgeLimit <= this.max) {
                var options = new ConfirmOptions();
                options.showCancel = false;
                options.showOK = true;
                ConfirmDialog2Component.openDialog('Warning', 'There may be dependents enrolled that have exceeded max age!', this.modalService, function (result) { }, options);
            }
            this.providerLineStandart.childAgeLimit = this.childAgeLimit;
            this.manService.updateProviderLine();
        }
    };
    BenefitDetailsProviderInfoLineComponent.prototype.onChangeIncludeInReport = function () {
        if (this.providerLineStandart.includeInReport !== this.isIncludeInReport) {
            this.providerLineStandart.includeInReport = this.isIncludeInReport;
            this.manService.updateProviderLine();
        }
    };
    BenefitDetailsProviderInfoLineComponent.prototype.onChangeCostFreq = function () {
        if (!_.isEqual(this.providerLineStandart.costFreq, this.costFreq)) {
            this.providerLineStandart.costFreq = this.costFreq;
            this.manService.updateProviderLine(true);
        }
    };
    BenefitDetailsProviderInfoLineComponent.prototype.onChangeEmpFreq = function () {
        if (!_.isEqual(this.providerLineStandart.empContFreq, this.empFreq)) {
            this.providerLineStandart.empContFreq = this.empFreq;
            this.manService.updateProviderLine(false);
        }
    };
    BenefitDetailsProviderInfoLineComponent.prototype.onChangeCalcMethod = function () {
        if (!_.isEqual(this.providerLineStandart.calcMethod, this.calcMethod)) {
            this.providerLineStandart.calcMethod = this.calcMethod;
            this.manService.updateProviderLine();
            this.manService.changeCalcMethod(this.calcMethod);
        }
    };
    BenefitDetailsProviderInfoLineComponent.prototype.onChangeStartDate = function () {
        if (this.isEditMode && !moment(this.providerLineStandart.line.startDate).isSame(this.effectiveStartDate)) {
            this.providerLineStandart.line.startDate = this.effectiveStartDate;
            this.manService.updateProviderLine();
        }
    };
    BenefitDetailsProviderInfoLineComponent.prototype.onChangeEndDate = function () {
        if (this.isEditMode && !moment(this.providerLineStandart.line.endDate).isSame(this.effectiveEndDate)) {
            this.providerLineStandart.line.endDate = this.effectiveEndDate;
            this.manService.updateProviderLine();
        }
    };
    BenefitDetailsProviderInfoLineComponent.prototype.onChangeDeductionDate = function () {
        if (this.isEditMode && !moment(this.providerLineStandart.payrollDeductionDate).isSame(this.deductionDate)) {
            this.providerLineStandart.payrollDeductionDate = this.deductionDate;
            this.manService.updateProviderLine();
        }
    };
    Object.defineProperty(BenefitDetailsProviderInfoLineComponent.prototype, "isCalcMethodReadOnly", {
        get: function () {
            return !this.isEditMode || this.hasEnrolledEmployees || this.hasEnrolledEmpTierLevel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsProviderInfoLineComponent.prototype, "canHideCalcMethodToolTip", {
        get: function () {
            return !(this.isEditMode && (this.hasEnrolledEmployees || this.hasEnrolledEmpTierLevel));
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsProviderInfoLineComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isBenefitDeductionEndabled = config.isBenefitDeductionEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsProviderInfoLineComponent.prototype.assignValidationParams = function (providerId) {
        var params = [this.orgLevelId];
        if (_.isFinite(providerId)) {
            params.push(providerId);
        }
        this.serverValidationParams = {
            validationName: BenefitDetailsValidatorAdapter.providerNameValidation,
            parameters: params,
            validationAdapter: this.benefitsValidatorAdapter
        };
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsProviderInfoLineComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsProviderInfoLineComponent;
}());
export { BenefitDetailsProviderInfoLineComponent };
