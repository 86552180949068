import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { W2sManagementService } from '../../../../../app/reports/services/w2s/w2s-management.service';
import { unsubscribeAll } from '../../../../core/decorators/index';
var W2sListComponent = /** @class */ (function () {
    function W2sListComponent(w2sManagementService) {
        this.w2sManagementService = w2sManagementService;
        this.isLoading = true;
        this.subscriptions = {};
    }
    W2sListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.w2sManagementService.init();
        this.subscriptions.loading = this.w2sManagementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    W2sListComponent.prototype.ngOnDestroy = function () {
        this.w2sManagementService.destroy();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], W2sListComponent.prototype, "subscriptions", void 0);
    return W2sListComponent;
}());
export { W2sListComponent };
