export interface IEmployeeTransferringResponse {
  employeeId: number;
}

export class EmployeeTransferringResponse {
  public employeeId: number;

  constructor(empId: number) {
    this.employeeId = empId;
  }
}
