import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { RangeDates } from '../../../../../common/models/range-dates';
import { PBJExportRequest, PBJExportRange, PBJExportType } from '../../../models/index';
import { DaterangeInputComponent } from '../../../../../components-library/components/index';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { ApplicationStateBusService, OrgLevelWatchService } from '../../../../../organization/services';
import { unsubscribe } from '../../../../../core/decorators/index';
import { AppSettingsManageService } from './../../../../../../app/app-settings/services';
import { PbjNewExportHierarchyHelper } from './pbj-new-export-hierarchy-helper';
var PbjNewExportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PbjNewExportComponent, _super);
    function PbjNewExportComponent(orgLevelWatchService, applicationStateBusService, appSettingsManageService) {
        var _this = _super.call(this) || this;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.applicationStateBusService = applicationStateBusService;
        _this.appSettingsManageService = appSettingsManageService;
        _this.getDateRangeError = new EventEmitter(null);
        _this.orgIds = [];
        _this.config = TreeviewConfig.create({
            hasAllCheckBox: false,
        });
        _this.disableYear = false;
        _this.changeExportRequest = new EventEmitter();
        var year = (new Date()).getFullYear();
        _this.standardQuarters = [];
        _this.standardQuarters.push(_this.createQuarter(new Date(year, 0, 1), new Date(year, 2, 31)));
        _this.standardQuarters.push(_this.createQuarter(new Date(year, 3, 1), new Date(year, 5, 30)));
        _this.standardQuarters.push(_this.createQuarter(new Date(year, 6, 1), new Date(year, 8, 30)));
        _this.standardQuarters.push(_this.createQuarter(new Date(year, 9, 1), new Date(year, 11, 31)));
        var exportRangeTexts = {
            'Q1': 'Q1:Oct-Dec',
            'Q2': 'Q2:Jan-Mar',
            'Q3': 'Q3:Apr-Jun',
            'Q4': 'Q4:Jul-Sep',
            'Custom': 'Custom'
        };
        _this.exportRangeTypes = _.map(PBJExportRange, function (expRangeId) { return ({ id: expRangeId, text: exportRangeTexts[expRangeId] }); });
        var amountOfYearsLessCurrent = 5;
        var currentYear = moment().year();
        var years = _.range(currentYear - amountOfYearsLessCurrent, currentYear + 4, 1);
        _this.yearsList = _.map(years, function (year) { return ({ id: year, year: year }); });
        _this.selectedYear = _this.yearsList[amountOfYearsLessCurrent];
        return _this;
    }
    PbjNewExportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.applicationStateBusService.subscribeToSelectOrgLevel(function (o) {
            if (_.isObject(o) && _.isNumber(o.id) && (!_this.orgLevel || _this.orgLevel.id !== o.id)) {
                _this.orgLevel = o;
                _this.orgHierarchy = _this.setOrgHierarchy(o, 'exportDialog');
            }
        });
        this.items = this.getItems([this.orgHierarchy]);
        this.request = new PBJExportRequest();
        this.request.exportRange = PBJExportRange.Q1;
        this.request.staffingHoursExportType = PBJExportType.Merge;
        this.request.fiscalYear = this.selectedYear.id;
        this.dateRangeCorrect = true;
        this.assignDates();
        this.exportRangeType = _.find(this.exportRangeTypes, function (expRange) { return expRange.id === _this.request.exportRange; });
        this.appSettingsManageService.getAppServerConfig().then(function (config) {
            _this.isConfigEnabledPbj = config.IsPBJExportCorpLevelEnabled;
            _this.request.includeEmployee = _this.isConfigEnabledPbj ? true : false;
            _this.request.includeStaffingHours = _this.isConfigEnabledPbj ? true : false;
            _this.getDateRangeError.emit([_this.dateRangeCorrect, _this.exportRangeType, _this.request]);
        });
    };
    Object.defineProperty(PbjNewExportComponent.prototype, "canGenerate", {
        get: function () {
            return this.dateRangeCorrect &&
                this.request && (this.request.includeEmployee || this.request.includeStaffingHours) &&
                (!this.exportRangeIsCustom || this.exportRangeIsCustom && this.dateRange && this.dateRange.isValidRange);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjNewExportComponent.prototype, "exportRangeIsCustom", {
        get: function () {
            return this.request && this.request.exportRange === 'Custom';
        },
        enumerable: true,
        configurable: true
    });
    PbjNewExportComponent.prototype.onSelectYear = function (year) {
        if (_.isObject(year)) {
            this.request.fiscalYear = year.id;
            this.updateExportRequest();
        }
    };
    PbjNewExportComponent.prototype.onChangeExportRange = function (exportRange) {
        if (_.isObject(exportRange)) {
            this.request.exportRange = exportRange.id;
            this.disableYear = exportRange.id === 'Custom' ? true : false;
            if (exportRange.id === 'Custom') {
                this.dateRangeCorrect = this.isValidDateRange(this.request.startDate, this.request.endDate);
                return;
            }
            this.dateRangeCorrect = true;
            this.getDateRangeError.emit([this.dateRangeCorrect, this.exportRangeType, this.request]);
        }
    };
    PbjNewExportComponent.prototype.onDatesChanged = function (range) {
        var isCorrect = this.isValidDateRange(range.startDate, range.endDate);
        if (isCorrect) {
            this.request.startDate = range.startDate;
            this.request.endDate = range.endDate;
            this.dateRangeCorrect = true;
            this.getDateRangeError.emit([this.dateRangeCorrect, this.exportRangeType, this.request]);
            this.updateExportRequest();
            return;
        }
        this.dateRangeCorrect = false;
        this.getDateRangeError.emit([this.dateRangeCorrect, this.exportRangeType, this.request]);
        this.updateExportRequest();
    };
    PbjNewExportComponent.prototype.onValueChanged = function (value) {
        if (value.valid) {
            var rg = { startDate: this.request.startDate, endDate: this.request.endDate };
            if (!!value.startDate) {
                this.expstartDate = value.startDate;
            }
            else {
                this.expEndDate = value.endDate;
            }
            if (this.expstartDate != null) {
                rg.startDate = this.expstartDate;
            }
            if (this.expEndDate != null) {
                rg.endDate = this.expEndDate;
            }
            this.onDatesChanged(rg);
        }
        else {
            this.dateRangeCorrect = false;
        }
        this.updateExportRequest();
    };
    PbjNewExportComponent.prototype.updateExportRequest = function () {
        if (this.canGenerate) {
            this.changeExportRequest.emit(this.request);
        }
        else {
            this.changeExportRequest.emit(null);
        }
        this.getDateRangeError.emit([this.dateRangeCorrect, this.exportRangeType, this.request]);
    };
    PbjNewExportComponent.prototype.createQuarter = function (sDate, eDate) {
        var dr = new RangeDates();
        sDate.setHours(0, 0, 0);
        eDate.setHours(23, 59, 59);
        dr.startDate = sDate;
        dr.endDate = eDate;
        return dr;
    };
    PbjNewExportComponent.prototype.isValidDateRange = function (sDate, eDate) {
        if (sDate > eDate)
            return false;
        var curYear = sDate.getFullYear();
        return _.some(this.standardQuarters, function (value) {
            value.startDate.setFullYear(curYear);
            value.endDate.setFullYear(curYear);
            return value.startDate.getTime() <= sDate.getTime() && value.endDate.getTime() >= eDate.getTime();
        });
    };
    PbjNewExportComponent.prototype.assignDates = function () {
        var today = new Date();
        var qIndex = Math.ceil((today.getMonth() + 1) / 3) - 1;
        var q = this.standardQuarters[qIndex];
        this.request.startDate = new Date(q.startDate);
        this.request.endDate = new Date(q.endDate);
    };
    PbjNewExportComponent.prototype.getItems = function (parentChildObj) {
        var itemsArray = [];
        parentChildObj.forEach(function (set) {
            itemsArray.push(new TreeviewItem(set));
        });
        return itemsArray;
    };
    PbjNewExportComponent.prototype.getSelectedIds = function (orgIds) {
        sessionStorage.setItem('selIds', orgIds);
        this.request.orgLevelId = orgIds.toString();
        this.updateExportRequest();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjNewExportComponent.prototype, "orgLevelSubscription", void 0);
    return PbjNewExportComponent;
}(PbjNewExportHierarchyHelper));
export { PbjNewExportComponent };
