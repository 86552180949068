import { IOrganization, Organization } from '../../../../organization/models/index';

export interface IPBJExportLogHeader {
  exportId: number;
  startDate: string;
  endDate: string;
  exportDate: string;
  employeesCount: number;
  staffingHoursCount: number;
  censusDataCount: number;
  errorsCount: number;
  warningsCount: number;
  organization: IOrganization;
  pbjidConfigEnabled: boolean;
}

export class PBJExportLogHeader {

  public get totalMessagesCount(): number {
    return this.errorsCount + this.warningsCount;
  }

  public exportId: number;
  public startDate: Date;
  public endDate: Date;
  public exportDate: Date;
  public employeesCount: number;
  public staffingHoursCount: number;
  public censusDataCount: number;
  public errorsCount: number;
  public warningsCount: number;
  public organization: Organization;
  public pbjIdConfigEnabled: boolean;
}
