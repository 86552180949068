import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'removeAsterisk'
})

export class PositionRemoveAsteriskPipe implements PipeTransform {
    transform(value: string): string {
        return value.split('').filter(x => x != '*').join('');
    }

}