import { ActivatedRoute, Router, UrlTree } from '@angular/router';

export class PositionMappingNavigationService {
    private router: Router;
    private route: ActivatedRoute;
  
    constructor(router: Router, route: ActivatedRoute) {
      this.router = router;
      this.route = route;
    }

    public navigateToPositionMapping(orgLevelId: number){
        this.router.navigateByUrl(this.getUrlTree(orgLevelId));
    }
    
    private getUrlTree(orgLevelId: number): UrlTree {
        let params: any = {
            orgLevelId: orgLevelId
        }
        return this.router.createUrlTree(['common', 'intg_position_mapping'], { relativeTo: this.route.pathFromRoot[1], queryParams: params })
    }
}
