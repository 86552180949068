import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody, Meta, FieldsMeta } from '../../../../core/models/index';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { organizationConfig } from '../../../../../../src/app/organization/organization.config';
import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../../common/services/index';
import { ApplicationStateBusService } from '../../../../organization/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeSectionsEmploymentMapService } from './employee-section-employment-map.service';
import {
  EmployeeSectionsEmployment,
  IEmployeeSectionsEmployment,
  EmployeeSectionsPayroll,
  IEmployeeSectionsPayroll,
  EmployeeSectionsPositions,
  IEmployeeSectionsPositions,
  EmployeeSectionsTerminationHistoryEntries,
  IEmployeeSectionsTerminationHistoryEntries,
  EmployeeSectionsRateHistory,
  IEmployeeSectionsRateHistory,
  EmployeeSectionsPositionHistory,
  IEmployeeSectionsPositionHistory,
  IEmployeeSectionsPayCycles,
  EmployeeSectionsPayCycles,
  IEmployeeSectionsPayCycleCalcConf,
  EmployeeSectionsPayCycleCalcConf,
  EmployeeSectionTimeclockContainer,
  IEmployeeSectionTimeclockContainer,
  EmployeeSectionsSelfService,
  IEmployeeSectionsSelfService,
  EmployeeSectionsI9,
  IEmployeeSectionsI9,
  EmployeeSectionsEssTemplate,
  IEmployeeSectionsEssTemplate,
  EmployeeLeaveManagement,
  IEmployeeLeaveManagement,
  IEmployeeSectionsSubsectionContainer,
  TelepunchSubsection,
  ITelepunchSubsection,
  PhonePunchRestrictions
} from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import * as _ from 'lodash';
import { EmployeeIncidents, IEmployeeIncidents } from '../../models/employee-sections-employment/employee-incidents';
import { IEmployeeSectionsReviews, EmployeeSectionsReviews } from '../../models/employee-sections-employment/employee-sections-reviews';
import { ChangesDialogOptions } from '../../../../common/index';
import { EmployeeSectionsACA, IEmployeeSectionsACA, EmployeeACA } from '../../models/employee-sections-employment/employee-sections-aca';
@Injectable()
export class EmployeeSectionsEmploymentApiService {

  constructor(
    private employeeSectionsEmploymentMapService: EmployeeSectionsEmploymentMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private cacheUtilService: CacheUtilService,
    private applicationStateBusService: ApplicationStateBusService
  ) {
  }

  public getEmploymentSection(employeeId: number): Promise<EmployeeSectionsEmployment> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsEmployment> = this.apiUtilService.request<IEmployeeSectionsEmployment, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsEmployment, Meta>) =>
        this.employeeSectionsEmploymentMapService.mapToSectionsEmployment(response.data)
      );
    return promise;
  }

  public getEmploymentPayroll(employeeId: number): Promise<EmployeeSectionsPayroll> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.payroll}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsPayroll> = this.apiUtilService.request<IEmployeeSectionsPayroll, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsPayroll, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToPayroll(response.data, response.meta)
      );
    return promise;
  }

  public getEmploymentSelfService(employeeId: number): Promise<EmployeeSectionsSelfService> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.selfService}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsSelfService> = this.apiUtilService.request<IEmployeeSectionsSelfService, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsSelfService, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToSelfService(response.data, response.meta)
      );
    return promise;
  }

  public getEmployeeSectionsEssTemplate(employeeId: number): Promise<EmployeeSectionsEssTemplate> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.essTemplate}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsEssTemplate> = this.apiUtilService.request<IEmployeeSectionsEssTemplate, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsEssTemplate, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToEssTemplateSubsection(response.data, response.meta)
      );
    return promise;
  }

  public getEmployeeI9(employeeId: number): Promise<EmployeeSectionsI9> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.i9}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsI9> = this.apiUtilService.request<IEmployeeSectionsI9, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsI9, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToI9(response.data, response.meta)
      );
    return promise;
  }

  public getEmployeeTelepunch(employeeId: number): Promise<TelepunchSubsection> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.telepunch}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<TelepunchSubsection> = this.apiUtilService.request<ITelepunchSubsection, FieldsMeta>(request)
      .then((response: ResponseBody<ITelepunchSubsection, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToTelepunch(response.data, response.meta)
      );
    return promise;
  }

  public setEmployeeTelepunch(employeeId: number, telepunchCode: PhonePunchRestrictions): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.telepunch}`;
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, telepunchCode);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public setEmployeeI9(employeeId: number, i9: EmployeeSectionsI9): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(i9, 'i9');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.i9}`;
    let di9: IEmployeeSectionsI9 = this.employeeSectionsEmploymentMapService.mapToI9Dto(i9);
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, {
      i9: di9,
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public getEmploymentTerminationsHistory(employeeId: number): Promise<EmployeeSectionsTerminationHistoryEntries> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.termination}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsTerminationHistoryEntries> = this.apiUtilService.request<IEmployeeSectionsTerminationHistoryEntries, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsTerminationHistoryEntries, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToTerminationHistory(response.data, response.meta)
      );
    return promise;
  }

  public setEmploymentPayroll(employeeId: number, payroll: EmployeeSectionsPayroll, effectiveDate?: Date): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(payroll, 'payroll');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.payroll}`;
    let dpayroll: IEmployeeSectionsPayroll = this.employeeSectionsEmploymentMapService.mapToPayrollDto(payroll);
    let body: any = {
      payrollSubsection: dpayroll,
      metadata: payroll.fieldsMeta
    };

    let request: HttpRequest<any> = new HttpRequest('PUT', url, body, {
      params: effectiveDate ? this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) }) : null
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.applicationStateBusService.resetCache('TimecardsApiServiceCache');
        return response.status;
      });
    return promise;
  }

  public setEmploymentTerminationHistory(employeeId: number, terminations: EmployeeSectionsTerminationHistoryEntries, effectiveDate?: Date): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(terminations, 'terminations');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.termination}`;
    let terminationsDto: IEmployeeSectionsTerminationHistoryEntries = this.employeeSectionsEmploymentMapService.mapToTerminationHistoryDto(terminations);
    let body: any = {
      terminationHistory: terminationsDto,
      effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate)
    };
    let params: HttpParams = this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body, {
      params: params
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public getEmploymentPositions(employeeId: number): Promise<EmployeeSectionsPositions> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.positions}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsPositions> = this.apiUtilService.request<IEmployeeSectionsPositions, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsPositions, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToPositions(response.data, response.meta)
      );
    return promise;
  }

  public setEmploymentPositions(employeeId: number, positions: EmployeeSectionsPositions, effectiveDate?: Date): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(positions, 'positions');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.positions}`;
    let dpositions: IEmployeeSectionsPositions = this.employeeSectionsEmploymentMapService.mapToPositionsDto(positions);
    let body: any = {
      positions: dpositions,
      metadata: positions.fieldsMeta
    };

    let request: HttpRequest<any> = new HttpRequest('PUT', url, body, {
      params: effectiveDate ? this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) }) : null
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public getEmploymentRateHistory(employeeId: number): Promise<EmployeeSectionsRateHistory> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.ratehistory}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsRateHistory> = this.apiUtilService.request<IEmployeeSectionsRateHistory, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsRateHistory, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToRateHistory(response.data, response.meta)
      );
    return promise;
  }

  public getEmploymentPositionHistory(employeeId: number, showpositionattribute?: string): Promise<EmployeeSectionsPositionHistory> {
    Assert.isNotNull(employeeId, 'employeeId'); 
    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${showpositionattribute}/${employeeSectionsConfig.api.employees.sections.employment.positionhistory}`;    
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<EmployeeSectionsPositionHistory> = this.apiUtilService.request<IEmployeeSectionsPositionHistory, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsPositionHistory, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToPositionHistory(response.data, response.meta)
      );
    return promise;
  }

  public getEmploymentPayCycles(employeeId: number): Promise<EmployeeSectionsPayCycles> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.paycycles.root}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsPayCycles> = this.apiUtilService.request<IEmployeeSectionsPayCycles, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsPayCycles, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToPayCycles(response.data, response.meta)
      );
    return promise;
  }

  public setEmploymentPayCyclesConf(orgLevelId: number, employeeId: number, conf: EmployeeSectionsPayCycleCalcConf): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(conf, 'conf');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.paycycles.root}/${employeeSectionsConfig.api.employees.sections.employment.paycycles.regenerate}`;
    let dconf: IEmployeeSectionsPayCycleCalcConf = this.employeeSectionsEmploymentMapService.mapToPayCyclesConfDto(conf);
    let body: any = dconf;
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        let urls: string = `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.lookup.root}/${organizationConfig.lookup.paycycles}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(urls, { employeeId });
        urls = this.apiUtilService.getReducedUrl(request.urlWithParams);
        this.cacheUtilService.delete({ key: urls }, '');
        response.status
      });
    return promise;
  }

  public getEmployeeLeaveManagement(employeeId: number): Promise<EmployeeLeaveManagement> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.leaveManagement}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeLeaveManagement> = this.apiUtilService.request<IEmployeeLeaveManagement, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeLeaveManagement, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToLeaveManagement(response.data, response.meta)
      );

    return promise;
  }

  public getEmployeeTimeclocks(employeeId: number): Promise<EmployeeSectionTimeclockContainer> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.timeclocks.root}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionTimeclockContainer> = this.apiUtilService.request<IEmployeeSectionTimeclockContainer, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionTimeclockContainer, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToTimeclocks(response.data, response.meta)
      );

    return promise;
  }

  public setEmployeeTimeclocks(employeeId: number, timeclocks: EmployeeSectionTimeclockContainer, effectiveDate?: Date): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(timeclocks, 'timeclocks');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.timeclocks.root}`;
    let saveDto: IEmployeeSectionTimeclockContainer = this.employeeSectionsEmploymentMapService.mapToTimeclocksDto(timeclocks);
    let body: any = {
      timeclocks: saveDto,
      effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate)
    };
    let params: HttpParams = this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body, {
      params: params
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public resubmitTimeclocks(employeeId: number): Promise<any> {
    const url = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.timeclocks.resubmitTimeclocks}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, null);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => response.status);
    return promise;
  }

  public getEmployeeWrokersCompensation(employeeId: number) {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.workersCompensation.root}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeIncidents> = this.apiUtilService.request<IEmployeeIncidents, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeIncidents, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToEmployeeIncidents(response.data, response.meta)
      );

    return promise;
  }

  public getEmployeePayrollCycle(employeeId: number) {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.workersCompensation.root}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<EmployeeIncidents> = this.apiUtilService.request<IEmployeeIncidents, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeIncidents, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToEmployeeIncidents(response.data, response.meta)
      );

    return promise;
  }



  public getEmployeePerformanceReviews(employeeId: number) {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.performanceManagement.root}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsReviews> = this.apiUtilService.request<IEmployeeSectionsReviews, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsReviews, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToEmployeeReviews(response.data, response.meta)
      );

    return promise;
  }


  public getEmployeeACA(employeeId: number): Promise<EmployeeSectionsACA> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.aca.root}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsACA> = this.apiUtilService.request<IEmployeeSectionsACA, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsACA, FieldsMeta>) =>
        this.employeeSectionsEmploymentMapService.mapToEmployeeACA(response.data, response.meta)
      );

    return promise;
  }

  public postEmployeeACA(employeeId: number, aca: EmployeeACA): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(aca, 'aca');

    const url: string = `${this.getEmploymentApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.aca.root}`;
    let body: any = this.employeeSectionsEmploymentMapService.mapToEmployeeAcaDTO(aca);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => response.status
      );
    return promise;
  }


  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.employees.root}`;
  }

  private getSectionsApiRoot(employeeId: number): string {
    return `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.sections.root}`;
  }

  private getEmploymentApiRoot(employeeId: number): string {
    return `${this.getSectionsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.employment.root}`;
  }

}
