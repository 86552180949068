import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { EmployeePunchRequestManagementService } from './../../../../time-and-attendance/services/punches/employee-punch-request-management.service';
import { appConfig } from '../../../../app.config';
var EmployeePunchRequestGridComponent = /** @class */ (function () {
    function EmployeePunchRequestGridComponent(manageService) {
        this.manageService = manageService;
        this.subscriptions = {};
        this.appConfig = appConfig;
    }
    EmployeePunchRequestGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onLoaded = this.manageService
            .subscribeToLoadedRecords(function (r) { return _this.assignRecords(r); });
        this.manageService.init();
    };
    EmployeePunchRequestGridComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    EmployeePunchRequestGridComponent.prototype.assignRecords = function (records) {
        this.records = records;
        this.manageService.refreshGrid();
    };
    EmployeePunchRequestGridComponent.prototype.sortChangeHandler = function (sort) {
        this.manageService.setSort(sort);
    };
    EmployeePunchRequestGridComponent.prototype.pageChangeHandler = function (event) {
        this.manageService.pageChange(event);
    };
    EmployeePunchRequestGridComponent.prototype.filterChangeHandler = function (event) {
        this.manageService.filterChange(event);
    };
    Object.defineProperty(EmployeePunchRequestGridComponent.prototype, "dateFormat", {
        get: function () {
            return '{0:MM/dd/yyyy}';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePunchRequestGridComponent.prototype, "dateWithTimeFormat", {
        get: function () {
            return '{0:MM/dd/yyyy HH:mm a}';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeePunchRequestGridComponent.prototype, "subscriptions", void 0);
    return EmployeePunchRequestGridComponent;
}());
export { EmployeePunchRequestGridComponent };
