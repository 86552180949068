import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Router, ActivatedRoute, NavigationEnd, Event } from '@angular/router';
import { ApplicationStateBusService, OrgLevelWatchService } from '../../../organization/services/index';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/combineLatest';
import { ApplicationService } from '../application/application.service';
import { mutableSelect } from '../../../core/decorators/index';
import { StateNavigationService, ChangeManagementService, RestoreQueryParamsService } from '../../../common/services/index';
import { NavigationMenuItem, flattenMenu, NavigationMenuEventType, NavigationMenuType } from '../../../organization/models/index';
import { AccessibleService } from '../../../organization/services/index';
var UrlStateManagementService = /** @class */ (function () {
    function UrlStateManagementService(router, route, applicationService, appStateBusService, orgLevelWatchService, accessibleService, changeManagement, restoreQueryParamsService) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.applicationService = applicationService;
        this.appStateBusService = appStateBusService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.accessibleService = accessibleService;
        this.changeManagement = changeManagement;
        this.restoreQueryParamsService = restoreQueryParamsService;
        this.subscriptions = {};
        this.navigationEvent$ = new ReplaySubject(1);
        this.navigationEventAfterApps$ = new ReplaySubject(1);
        this.navigationService = new StateNavigationService(this.router, this.route, this.changeManagement, this.restoreQueryParamsService);
        this.subscriptions.navSubscription = this.router.events
            .filter(function (e) { return e instanceof NavigationEnd; })
            .subscribe(function (e) {
            _this.navigationEvent$.next(e);
            _this.updateRouteInfo();
        });
        this.appStateBusService.lastOrgLevelRestored$.subscribe(function () {
            _this.subscribeToSources();
        });
        this.appStateBusService.login$.subscribe(function (isAliasChanged) {
            if (isAliasChanged) {
                _this.lastSelectedOrglevelId = undefined;
                _this.unsubscribeFromSources();
            }
        });
    }
    UrlStateManagementService.prototype.updateRouteInfo = function () {
        var currentRoute = this.route;
        while (currentRoute.firstChild)
            currentRoute = currentRoute.firstChild;
        if (_.get(currentRoute, 'outlet') === 'primary') {
            var data = _.get(currentRoute, 'snapshot.data') || null;
            this.appStateBusService.changeRouteInfo(data);
        }
    };
    UrlStateManagementService.prototype.unsubscribeFromSources = function () {
        if (this.subscriptions.routeAppSubscription) {
            this.subscriptions.routeAppSubscription.unsubscribe();
            this.subscriptions.routeAppSubscription = null;
        }
        if (this.subscriptions.routeMenuSubscription) {
            this.subscriptions.routeMenuSubscription.unsubscribe();
            this.subscriptions.routeMenuSubscription = null;
        }
    };
    UrlStateManagementService.prototype.subscribeToSources = function () {
        var _this = this;
        this.unsubscribeFromSources();
        this.subscriptions.routeAppSubscription = this.navigationEvent$
            .withLatestFrom(this.user$, this.application$, this.appStateBusService.lastOrgLevelRestored$)
            .subscribe(function (_a) {
            var e = _a[0], user = _a[1], application = _a[2], lastOrgLevelRestored = _a[3];
            if (!user) {
                _this.selectedApplication = null;
                _this.selectedOrglevelId = 0;
                return;
            }
            var orgLevelId = _this.getOrglevelIdFromRoute();
            if (!orgLevelId) {
                if (_this.lastSelectedOrglevelId) {
                    orgLevelId = _this.lastSelectedOrglevelId;
                }
                else if (lastOrgLevelRestored) {
                    orgLevelId = lastOrgLevelRestored.id;
                }
            }
            if (!_this.selectedOrglevelId || (orgLevelId && orgLevelId !== _this.selectedOrglevelId)) {
                _this.orgLevelWatchService.getOrgLevelByIdOrDeafault(orgLevelId)
                    .then(function (value) {
                    if (!_this.selectedOrglevelId) {
                        if (value)
                            _this.applyNewOrgLevel(e, application, value.id);
                        return;
                    }
                    _this.checkAndApplyNewOrgLevel(e, application, value.id);
                });
                return;
            }
            _this.parseApplicationFromUrl(e, application, orgLevelId);
        });
        this.subscriptions.routeMenuSubscription = this.navigationEventAfterApps$
            .combineLatest(this.appStateBusService.menuLoaded$)
            .subscribe(function (_a) {
            var e = _a[0], menu = _a[1];
            var path = _this.parseUrl(e);
            _this.reselectMenu(path, menu);
        });
    };
    UrlStateManagementService.prototype.checkAndApplyNewOrgLevel = function (e, application, orgLevelId) {
        var _this = this;
        this.accessibleService.isAccessibleForChangeOrgLevel(orgLevelId)
            .then(function (isAccessible) {
            if (!isAccessible) {
                _this.navigationService.navigateToOrgLevel(_this.selectedOrglevelId);
                _this.applyNewOrgLevel(e, application, _this.selectedOrglevelId);
                return;
            }
            _this.applyNewOrgLevel(e, application, orgLevelId);
        });
    };
    UrlStateManagementService.prototype.applyNewOrgLevel = function (e, application, orgLevelId) {
        this.appStateBusService.selectOrgLevelId(orgLevelId);
        this.selectedOrglevelId = orgLevelId;
        this.lastSelectedOrglevelId = orgLevelId;
        this.parseApplicationFromUrl(e, application, orgLevelId);
    };
    UrlStateManagementService.prototype.parseApplicationFromUrl = function (e, application, orgLevelId) {
        var _this = this;
        var path = this.parseUrl(e);
        if (path.length < 2) {
            //no applications in route
            this.navigationService.navigateToHome(orgLevelId);
            this.applicationService.applicationSelected(null);
            this.selectedApplication = null;
            this.initializeIfNot();
            this.navigationEventAfterApps$.next(e);
            return;
        }
        // avoid further check for login page (forgot/reset password)
        if (path[0] === 'login') {
            return;
        }
        var applicationName = path[1];
        this.applicationService.getApplicationByName(applicationName).then(function (app) {
            if (!app) {
                //wrong or not applicable application url
                _this.selectedApplication = null;
                _this.applicationService.deselectApplication();
                _this.navigationService.navigateToHome(orgLevelId);
                _this.applicationService.applicationSelected(null);
                _this.initializeIfNot();
                return;
            }
            if (!_this.selectedApplication || app.id !== _this.selectedApplication.id) {
                _this.selectedApplication = app;
                _this.applicationService.applicationSelected(app);
            }
            else if (_this.selectedApplication) {
                _this.applicationService.applicationSelected(_this.selectedApplication);
            }
            _this.initializeIfNot();
            _this.navigationEventAfterApps$.next(e);
        });
    };
    UrlStateManagementService.prototype.reselectMenu = function (path, menu) {
        var _this = this;
        if (!menu) {
            return;
        }
        if (!this.selectedApplication || !this.selectedApplication.id) {
            var navMenu = new NavigationMenuItem();
            navMenu.type = NavigationMenuType.menuItem;
            this.appStateBusService.selectMenu({ type: NavigationMenuEventType.empty, menuItem: navMenu });
            return;
        }
        var flatItems = flattenMenu(menu);
        if (path.length < 3) {
            var appMenu = new NavigationMenuItem();
            appMenu.applicationId = this.selectedApplication.id;
            appMenu.name = this.selectedApplication.link;
            appMenu.link = this.selectedApplication.link;
            appMenu.type = NavigationMenuType.application;
            this.appStateBusService.selectMenu({ type: NavigationMenuEventType.new, menuItem: appMenu });
            return;
        }
        var name = path[2];
        var selectedItem = _.find(flatItems, function (menuItem) {
            return menuItem.name === name && (_this.selectedApplication.id === menuItem.applicationId || menuItem.isGlobal);
        });
        if (selectedItem) {
            if (this.selectedMenuItem && selectedItem.id === this.selectedMenuItem.id) {
                this.appStateBusService.selectMenu({ type: NavigationMenuEventType.reselect, menuItem: selectedItem });
            }
            else {
                this.appStateBusService.selectMenu({ type: NavigationMenuEventType.new, menuItem: selectedItem });
            }
            this.selectedMenuItem = selectedItem;
        }
        else {
            var navMenu = new NavigationMenuItem();
            navMenu.applicationId = this.selectedApplication.id;
            navMenu.name = name;
            navMenu.link = name;
            navMenu.type = NavigationMenuType.menuItem;
            this.appStateBusService.selectMenu({ type: NavigationMenuEventType.notexist, menuItem: navMenu });
            this.selectedMenuItem = navMenu;
        }
    };
    UrlStateManagementService.prototype.parseUrl = function (e) {
        var url = _.split(e.urlAfterRedirects, '?');
        if (!url) {
            return [];
        }
        var path = _.filter(_.split(url[0], '/'), function (s) { return s.length > 0; });
        return path;
    };
    UrlStateManagementService.prototype.initializeIfNot = function () {
        if (!this.initialized) {
            this.initialized = true;
            this.appStateBusService.initialize();
        }
    };
    UrlStateManagementService.prototype.getOrglevelIdFromRoute = function () {
        var id = this.route.snapshot.queryParamMap.get('orgLevelId');
        return _.toInteger(id);
    };
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], UrlStateManagementService.prototype, "user$", void 0);
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], UrlStateManagementService.prototype, "application$", void 0);
    return UrlStateManagementService;
}());
export { UrlStateManagementService };
