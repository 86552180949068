<kendo-grid #kendoGrid [data]="gridState?.view" (excelExport)="onExcelExport($event)">
    <kendo-grid-column title="Name" field="employee.name">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <a [employeeSectionLink]="dataItem?.employee.id">{{dataItem?.employee.name}}</a>
        </ng-template>
        <ng-template kendoGridFooterTemplate ><strong>Total</strong></ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Position" field="position.name"></kendo-grid-column>
    <kendo-grid-column title="Exempt Type" field="exemptType"></kendo-grid-column>
    <kendo-grid-column title="Current Cycle Hours" field="currentCycleHours">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align {{ checkDifference (dataItem, management.diffPercent) ? 'highlight' : '' }}">{{dataItem.currentCycleHours | number:'.2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align">
                <strong>{{ totals['currentCycleHours']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Prev Cycle Hours" field="previousCycleHours">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{dataItem.previousCycleHours | number:'.2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align">
                <strong>{{ totals['previousCycleHours']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-excel fileName="exempt-exployees.xlsx">
      <kendo-excelexport-column title="Name" field="employee.name">
        <ng-template kendoExcelExportFooterTemplate>Total</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column title="Position" field="position.name"></kendo-excelexport-column>
      <kendo-excelexport-column title="Exempt Type" field="exemptType"></kendo-excelexport-column>
      <kendo-excelexport-column
        title="Current Cycle Hours"
        field="currentCycleHours"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: numberFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['currentCycleHours']?.sum }}</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Prev Cycle Hours"
        field="previousCycleHours"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: numberFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['previousCycleHours']?.sum }}</ng-template>
      </kendo-excelexport-column>
    </kendo-grid-excel>

    <kendo-grid-pdf fileName="exempt-exployees.pdf" [allPages]="true" paperSize="A4" [landscape]="true" [repeatHeaders]="true" [scale]="0.7">
      <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
      <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <div class="page-template">
           <div class="header">
            <div class="title">Exempt Employees - {{ payrollCycle | slxPeriod }}; {{ orgLevelName }}; {{ todayDateTime }}</div>
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
       </ng-template>
    </kendo-grid-pdf>
</kendo-grid>
