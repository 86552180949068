import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import * as _ from 'lodash';
import { ExcelFileExtractHelper } from './excel-file-extract.helper';
var FileUploadComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FileUploadComponent, _super);
    function FileUploadComponent(fb) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.cancelChanges = new EventEmitter();
        _this.uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
        _this.uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
        _this.acceptString = '.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';
        _this.submitted = false;
        _this.myRestrictions = {
            allowedExtensions: ['xlsx'],
        };
        return _this;
    }
    FileUploadComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.myForm = this.fb.group({
            myUpload: [this.userFiles, [Validators.required]],
        });
        this.myForm.valueChanges.subscribe(function (data) {
            if (!_.isNull(data.myUpload)) {
                _this.onValueChanged(data);
            }
        });
    };
    FileUploadComponent.prototype.onValueChanged = function (_data) {
        this.convertToBinaryString(_data.myUpload[0].rawFile);
    };
    FileUploadComponent.prototype.cancelEventHandler = function (e) {
        this.cancelChanges.emit(true);
    };
    FileUploadComponent.prototype.save = function (_value, valid) {
        this.submitted = true;
        if (valid) {
            console.log('Everything is OK!');
        }
    };
    return FileUploadComponent;
}(ExcelFileExtractHelper));
export { FileUploadComponent };
