import * as tslib_1 from "tslib";
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { schedulerConfig } from '../../../scheduler.config';
import { ScheduleConsoleMapService } from './schedule-console-map.service';
import { dateTimeUtils } from '../../../../common/utils/index';
var ScheduleConsoleApiService = /** @class */ (function () {
    function ScheduleConsoleApiService(apiUtilService, urlParamsService, scheduleConsoleMapService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.scheduleConsoleMapService = scheduleConsoleMapService;
    }
    ScheduleConsoleApiService.prototype.getScheduleConsoleData = function (orgLevelId, startDate, endDate) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.console, orgLevelId);
        var request = this.urlParamsService.createPostRequest(url, null, {
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.scheduleConsoleMapService.mapScheduleConsoleCycles(response.data, startDate); });
        return promise;
    };
    return ScheduleConsoleApiService;
}());
export { ScheduleConsoleApiService };
var templateObject_1;
