import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { ResponseBody, Meta, EditResult } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { SlxHttpRequest } from '../../../../core/models/index';
import { benefitsConfig } from '../../benefits.config';
import { BenefitEmployeesMapService } from './benefit-employees-map.service';
import { dateTimeUtils } from '../../../../common/utils/index';
import {
  IBenefitEnrolledEmployee,
  BenefitEnrolledEmployee,
  BenefitTerminationReason,
  IBenefitTerminationReason,
  IDropEmployeeBenefits,
  DropEmployeeBenefits,
  IEmployeeBenefitsEditResult,
  EmployeeBenefitsEditResult,
  IBenefitEmployeeDependent,
  BenefitEmployeeDependent,
  IBenefitEmpDependentEnrollment,
  BenefitEmpDependentEnrollment,
  IBenefitEligibleDependentBenefit,
  BenefitEligibleDependentBenefit,
  BenefitDependentDroppedEnrollment,
  BenefitEligibleEmployee,
  IBenefitEligibleEmployee,
  BenefitEligibleEmployeesInfo,
  IBenefitEligibleEmployeesInfo,
  BenefitEmployeeDependentsEnrollments
} from '../../models/index';
import { BenefitEnrolledEmployeePreview, IBenefitEnrolledEmployeePreview } from '../../models/benefit-employees/benefit-enrolled-employee-preview';

@Injectable()
export class BenefitEmployeesApiService {
  constructor(
    private mapService: BenefitEmployeesMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public async getBenefitEligibleEmployees(orgLevelId: number, tierId: number, refDate: Date): Promise<BenefitEligibleEmployee[]> {
    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.benefits.employee.eligible}`;
    const params = {
      orgLevelId: orgLevelId,
      benefitTierId: tierId,
      effectiveDate: dateTimeUtils.convertToDtoString(refDate),
    };
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, params);

    const response = await this.apiUtilService.requestNew<IBenefitEligibleEmployee[], Meta>(request);
    return this.mapService.mapToBenefitEligibleEmployees(response.data);
  }

  public async getBenefitEnrolledPreviewEmployees(orgLevelId: number, tierId: number, refDate: Date, coverage: number, selectedEmployee: IBenefitEnrolledEmployeePreview[]): Promise<BenefitEnrolledEmployeePreview[]> {
    return this.getBenefitEnrolledPreviewEmployeesWithOptionCode(orgLevelId, tierId, refDate, coverage, '', selectedEmployee);
  }

  public async getBenefitEnrolledPreviewEmployeesWithOptionCode(orgLevelId: number, tierId: number, refDate: Date, coverage: number, optionCode: string, selectedEmployee: IBenefitEnrolledEmployeePreview[]): Promise<BenefitEnrolledEmployeePreview[]> {
    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.benefits.employee.processFormulaForEmployees}`;
    const body = this.mapService.mapToEmployeeBenefitFormulaContributionsRequest2(orgLevelId, tierId, refDate, coverage, optionCode, selectedEmployee);
    const request = this.urlParamsService.requestPost(url, body);

    const response = await this.apiUtilService.requestNew<IBenefitEnrolledEmployeePreview[], Meta>(request);
    return this.mapService.mapToBenefitEnrolledEmployeesPreview(response.data);
  }

  public async getBenefitEligibleEmployeesInfo(orgLevelId: number, tierId: number, refDate: Date): Promise<BenefitEligibleEmployeesInfo> {
    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.benefits.employee.eligibleInfo}`;
    const params = {
      orgLevelId: orgLevelId,
      benefitTierId: tierId,
      effectiveDate: dateTimeUtils.convertToDtoString(refDate),
    };
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, params);

    const response = await this.apiUtilService.requestNew<IBenefitEligibleEmployeesInfo, Meta>(request);
    return this.mapService.mapToBenefitEligibleEmployeesInfo(response.data);
  }

  public async getBenefitEnrolledEmployeesInfo(orgLevelId: number, tierId: number, refDate: Date): Promise<number> {
    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.benefits.employee.enrolledInfo}`;
    const params = {
      orgLevelId: orgLevelId,
      benefitTierId: tierId,
      effectiveDate: dateTimeUtils.convertToDtoString(refDate),
    };
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, params);

    const response = await this.apiUtilService.requestNew<number, Meta>(request);
    return this.mapService.mapToBenefitEnrolledEmployeesInfo(response.data);
  }

  public async getBenefitEnrolledEmployees(orgLevelId: number, lineId: number, tierId: number, refDate: Date): Promise<BenefitEnrolledEmployee[]> {
    const url: string = `${this.getEnrolledEmployeeApiUrl()}/${benefitsConfig.api.benefits.employee.enrolledEmployees}`;
    const params = {
      orgLevelId: orgLevelId,
      benefitLineId: lineId,
      benefitTierId: tierId,
      effectiveDate: refDate
    };
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, params);

    const response = await this.apiUtilService.requestNew<IBenefitEnrolledEmployee[], Meta>(request);
    return this.mapService.mapToBenefitEnrolledEmployees(response.data);
  }

  public async getReasonsList(): Promise<BenefitTerminationReason[]> {
    const url: string = `${this.getEnrolledEmployeeApiUrl()}/${benefitsConfig.api.benefits.employee.dropReason}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);

    const response = await this.apiUtilService.requestNew<IBenefitTerminationReason[], Meta>(request);
    return this.mapService.mapBenefitTerminationReasons(response.data);
  }

  public async dropEmployeeBenefits(selectedEmployee, startDate, payrollDeductionEndDate, eventDate, reason): Promise<DropEmployeeBenefits> {
    startDate = dateTimeUtils.convertToDtoString(startDate);
    payrollDeductionEndDate = dateTimeUtils.convertToDtoString(payrollDeductionEndDate);
    eventDate = dateTimeUtils.convertToDtoString(eventDate);
    const url = `${this.getEnrolledEmployeeApiUrl()}/${benefitsConfig.api.benefits.employee.dropCoverage}`;
    const body = this.mapService.mapDropToDto(selectedEmployee, startDate, payrollDeductionEndDate, eventDate, reason);
    const request = this.urlParamsService.requestPut(url, body);
    request.autoContentType = true;

    return this.apiUtilService.requestNew<IDropEmployeeBenefits, Meta>(request)
      .then((response) => this.mapService.mapToDropBenefits(response.data));
  }

  public async updateEmployeeBenefitEnrollment(req: BenefitEnrolledEmployee, lineId: number, tierId: number, date: Date, method: string): Promise<EmployeeBenefitsEditResult> {
    const url: string = `${this.getEnrolledEmployeeApiUrl()}/${benefitsConfig.api.benefits.employee.updateEmployeeBenefitEnrollment}`;
    const body = this.mapService.mapDtoToEmployeeInformationUpdate(req, lineId, tierId, date, method);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<IEmployeeBenefitsEditResult, Meta>(request)
      .then((response: ResponseBody<IEmployeeBenefitsEditResult, Meta>) => this.mapService.mapEmployeeBenefitsEditResult(response.data));
  }

  public async deleteEmployeeBenefitEnrollment(req: number[]): Promise<any> {
    const url: string = `${this.getEnrolledEmployeeApiUrl()}/${benefitsConfig.api.benefits.employee.deleteEmployeeBenefitEnrollment}`;
    const body = req;
    const params = {};
    const request = this.urlParamsService.createDeleteRequest(url, params, body);

    return this.apiUtilService
      .request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => this.mapService.mapEmployeeBenefitsDeleteResult(response.data));
  }

  public async enrollEmployeesBenefit(req: BenefitEnrolledEmployee[], notesText: string, effectiveDate: any, lineId: number, tierId: number, calculationMethod: string): Promise<EmployeeBenefitsEditResult> {
    effectiveDate = dateTimeUtils.convertToDtoString(effectiveDate);
    const url: string = `${this.getEnrolledEmployeeApiUrl()}/${benefitsConfig.api.benefits.employee.enrollBenefits}`;
    const body = this.mapService.mapBenefitsEnrollOptionsToDto(req, notesText, effectiveDate, lineId, tierId, calculationMethod);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<IEmployeeBenefitsEditResult, Meta>(request)
      .then((response: ResponseBody<IEmployeeBenefitsEditResult, Meta>) => this.mapService.mapEmployeeBenefitsEditResult(response.data));
  }

  public async createEmployeeDependent(dependent: BenefitEmployeeDependent): Promise<BenefitEmployeeDependent> {
    const url = this.getEmployeeDependentApiUrl();
    const body = this.mapService.mapEmployeeDependentToDto(dependent);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<IBenefitEmployeeDependent, Meta>(request)
      .then((response: ResponseBody<IBenefitEmployeeDependent, Meta>) => this.mapService.mapDtoToEmployeeDependent(response.data));
  }

  public async editEmployeeDependent(dependent: BenefitEmployeeDependent): Promise<BenefitEmployeeDependent> {
    const url = this.getEmployeeDependentApiUrl();
    const body = this.mapService.mapEmployeeDependentToDto(dependent);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<IBenefitEmployeeDependent, Meta>(request)
      .then((response: ResponseBody<IBenefitEmployeeDependent, Meta>) => this.mapService.mapDtoToEmployeeDependent(response.data));
  }

  public deleteEmployeeDependent(dependentId: number): Promise<any> {
    const url = this.getEmployeeDependentApiUrl();
    const request = this.urlParamsService.createDeleteRequest(url, { dependentId });

    return this.apiUtilService.request<IBenefitEmployeeDependent, Meta>(request);
  }
  
  public getEmployeeDependentEnrollments(dependentId: number, employeeid: number): Promise<BenefitEmpDependentEnrollment[]> {
    const url = `${this.getEmployeeDependentApiUrl()}/${benefitsConfig.api.benefits.employee.enrolled}`;
    const request = this.urlParamsService.createGetRequest(url, { dependentId, employeeid});

    return this.apiUtilService
      .request<IBenefitEmpDependentEnrollment[], Meta>(request)
      .then((response: ResponseBody<IBenefitEmpDependentEnrollment[], Meta>) => this.mapService.mapDtoToEmpDependentEnrollments(response.data));
  }

  public getEmployeeDependentEligibleBenefits(dependentId: number): Promise<BenefitEligibleDependentBenefit[]> {
    const url = `${this.getEmployeeDependentEnrollApiUrl()}/${benefitsConfig.api.benefits.employee.eligible}`;
    const request = this.urlParamsService.createGetRequest(url, { dependentId });

    return this.apiUtilService
      .request<IBenefitEligibleDependentBenefit[], Meta>(request)
      .then((response: ResponseBody<IBenefitEligibleDependentBenefit[], Meta>) => this.mapService.mapDtoToEmpDependentEligibleBenefits(response.data));
  }

  public getEmployeeDependentsEligibleBenefits(dependentIds: Array<number>): Promise<BenefitEligibleDependentBenefit[]> {
    const url = `${this.getEmployeeDependentEnrollApiUrl()}/${benefitsConfig.api.benefits.employee.eligible}`;
    const request = this.urlParamsService.createGetRequest(url, { dependentIds }, true);

    return this.apiUtilService
      .request<IBenefitEligibleDependentBenefit[], Meta>(request)
      .then((response: ResponseBody<IBenefitEligibleDependentBenefit[], Meta>) => this.mapService.mapDtoToEmpDependentEligibleBenefits(response.data));
  }

  public addMultipleDependentsEnrollments(enrollments: BenefitEmployeeDependentsEnrollments): Promise<void> {
    const url = this.getEmployeeDependentEnrollApiUrl();
    const body = this.mapService.mapEmpDependentsEnrollmentsToDto(enrollments);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<void, Meta>(request)
      .then((response: ResponseBody<void, Meta>) => response.data);
  }

  public async addDependentEnrollment(enroll: BenefitEmpDependentEnrollment): Promise<BenefitEmpDependentEnrollment> {
    const url = this.getEmployeeDependentEnrollApiUrl();
    const body = this.mapService.mapEmpDependentEnrollmentToDto(enroll);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<IBenefitEmpDependentEnrollment, Meta>(request)
      .then((response: ResponseBody<IBenefitEmpDependentEnrollment, Meta>) => this.mapService.mapDtoToEmpDependentEnrollment(response.data));
  }

  public async editDependentEnrollment(enroll: BenefitEmpDependentEnrollment): Promise<BenefitEmpDependentEnrollment> {
    const url = this.getEmployeeDependentEnrollApiUrl();
    const body = this.mapService.mapEmpDependentEnrollmentToDto(enroll);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<IBenefitEmpDependentEnrollment, Meta>(request)
      .then((response: ResponseBody<IBenefitEmpDependentEnrollment, Meta>) => this.mapService.mapDtoToEmpDependentEnrollment(response.data));
  }

  public deleteDependentEnrollment(empDependentEnrollmentId: number): Promise<any> {
    const url = this.getEmployeeDependentEnrollApiUrl();
    const request = this.urlParamsService.createDeleteRequest(url, { empDependentEnrollmentId });

    return this.apiUtilService.request<IBenefitEmpDependentEnrollment, Meta>(request);
  }

  public dropDependentEnrollment(enrollment: BenefitDependentDroppedEnrollment): Promise<BenefitEmpDependentEnrollment> {
    const url = `${this.getEmployeeDependentApiUrl()}/${benefitsConfig.api.benefits.employee.dropCoverage}`;
    const body = this.mapService.mapEmpDependentDroppedEnrollmentToDto(enrollment);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService.request<IBenefitEmpDependentEnrollment, Meta>(request)
      .then((response: ResponseBody<IBenefitEmpDependentEnrollment, Meta>) => this.mapService.mapDtoToEmpDependentEnrollment(response.data));
  }

  public getBenefitTerminationReasons(): Promise<BenefitTerminationReason[]> {
    const url = `${this.getEmployeeDependentApiUrl()}/${benefitsConfig.api.benefits.employee.dropReasons}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .request<IBenefitTerminationReason[], Meta>(request)
      .then((response: ResponseBody<IBenefitTerminationReason[], Meta>) => this.mapService.mapBenefitTerminationReasons(response.data));
  }

  public getApiUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${benefitsConfig.api.benefits.root}/${benefitsConfig.api.benefits.employee.root}`;
  }

  public getEnrolledEmployeeApiUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${benefitsConfig.api.benefits.employee.benefitEnrollment}`;
  }

  public getEmployeeDependentApiUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${benefitsConfig.api.benefits.root}/${benefitsConfig.api.benefits.employee.dependents}`;
  }

  public getEmployeeDependentEnrollApiUrl(): string {
    return `${this.getEmployeeDependentApiUrl()}/${benefitsConfig.api.benefits.employee.enroll}`;
  }

}
