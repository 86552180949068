import * as _ from 'lodash';
var WcReport = /** @class */ (function () {
    function WcReport() {
    }
    Object.defineProperty(WcReport.prototype, "editMode", {
        get: function () {
            return this.m_editMode;
        },
        set: function (value) {
            this.m_editMode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcReport.prototype, "isNew", {
        get: function () {
            return !_.isFinite(this.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcReport.prototype, "isValid", {
        get: function () {
            var valid = true;
            if (_.isNil(this.employee)) {
                valid = false;
            }
            if (_.isNil(this.incidentDateTime)) {
                valid = false;
            }
            if (_.isNil(this.reportDateTime)) {
                valid = false;
            }
            if (_.isNil(this.injuryReport)) {
                valid = false;
            }
            if (!_.isNil(this.injuryReport)) {
                if (this.injuryReport.isOSHARecordable && _.isNil(this.oshaReport)) {
                    valid = false;
                }
                if (this.injuryReport.isWorkersCompCase && _.isNil(this.compReport)) {
                    valid = false;
                }
            }
            return valid;
        },
        enumerable: true,
        configurable: true
    });
    return WcReport;
}());
export { WcReport };
