import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { PayCycleHelperService } from './../../../../organization/services/index';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe, unsubscribeAll } from '../../../../core/decorators/index';
import { DailyTimecardManagementService } from '../../../services/index';
import { TimeCardDisplayOptions, TimecardAddCommentsReq } from '../../../models/daily-timecard/index';
import { AuditTrailLimitedDialogComponent } from '../../../../app-modules/audit-trail/components/index';
import { ModalService, ChangeManagementService, TimecardsNavigationService } from '../../../../common/services/index';
import { ChangesDialogOptions } from '../../../../common/models/index';
import { AuditTrailLimitedReq } from '../../../../app-modules/audit-trail/models/index';
import { ConfirmDialogComponent } from '../../../../common/components/index';
import { LookupService } from '../../../../organization/services/index';
import { LookupType } from '../../../../organization/models/index';
import { TimecardAddCommentsDialogComponent } from '../../punches/add-comments-dialog/add-comments-dialog.component';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
var DailyTimeCardActionsBarComponent = /** @class */ (function () {
    function DailyTimeCardActionsBarComponent(managementService, modalService, changeManagementService, lookupService, appSettingsManageService, payCycleHelper, router, route) {
        var _this = this;
        this.managementService = managementService;
        this.modalService = modalService;
        this.changeManagementService = changeManagementService;
        this.lookupService = lookupService;
        this.appSettingsManageService = appSettingsManageService;
        this.payCycleHelper = payCycleHelper;
        this.router = router;
        this.route = route;
        this.showIntervalsInTime = TimeCardDisplayOptions.showIntervalsInTime;
        this.showIntervalsInDecimal = TimeCardDisplayOptions.showIntervalsInDecimal;
        this.hasOverlap = false;
        this.approveGroup = 'approved';
        this.loadSubscription = this.managementService.onLoaded$.subscribe(function (model) {
            _this.model = model;
            _this.logicalDate = _this.model.date;
            _this.hasChanges = false;
            _this.initialApproved = _this.model.isLoadedApproved;
            _this.findPaycycle();
        });
        this.editSubscription = this.managementService.sectionEditAction$.subscribe(function (action) {
            _this.lockSave = action.lockOthers;
        });
        this.overlapSubscription = this.managementService.hasOverlap$.subscribe(function (action) {
            _this.hasOverlap = action;
        });
        // this.overlapDescSubscription = this.managementService.overlapPunchesDesc$.subscribe((data: boolean) => {
        //   this.hasOverlapNum = data;
        // })
        this.optionsChangedSubscription = this.managementService.displayOptionSelected$
            .subscribe(function (options) {
            _this.displayOptions = options;
        });
        this.changeManagementService.changesEvent.subscribe(function (event) {
            _this.hasChanges = !!event.changes;
        });
        this.tcNaviService = new TimecardsNavigationService(router, route);
        this.lookupService.getLookup({ lookupType: LookupType.timecardPredefinedComment })
            .then(function (res) {
            _this.timecardPredefinedComments = res;
        });
        this.overlapDescSubscription = this.managementService.overlapPunchesDesc$.subscribe(function (data) {
            if (!data) {
                if (!_this.model.hasMissingPunches) {
                    _this.changeManagementService.clearChanges();
                    _this.managementService.onSaveTimecard();
                    return;
                }
                _this.modalService.globalAnchor.openConfirmDialog("Warning", 'Missing punches appear on this timecard, do you wish to proceed?', function (result) {
                    if (result) {
                        _this.changeManagementService.clearChanges();
                        _this.managementService.onSaveTimecard();
                    }
                });
            }
        });
    }
    DailyTimeCardActionsBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appSettingsManageService.getAppServerConfig()
            .then(function (config) {
            _this.preventOverlappingPunches = config.preventOverlappingPunches;
        });
    };
    DailyTimeCardActionsBarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    DailyTimeCardActionsBarComponent.prototype.onDateChange = function () {
        /*this.changeManagementService.canMoveForward()
          .then((canMove: boolean) => {
            if (canMove) {
              this.changeManagementService.clearChanges();
            }
          });*/
        this.findPaycycle();
        this.managementService.changeDate(this.logicalDate);
        this.hasOverlap = false;
        this.managementService.setHasOverlapToTC(false);
    };
    DailyTimeCardActionsBarComponent.prototype.onRecalculate = function () {
        var _this = this;
        this.changeManagementService.canMoveForward()
            .then(function (canMove) {
            if (canMove) {
                _this.changeManagementService.clearChanges();
            }
        });
        this.managementService.recalculateTimecard();
    };
    DailyTimeCardActionsBarComponent.prototype.saveTimecard = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var req, res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.model.comment && this.timecardPredefinedComments && this.timecardPredefinedComments.items.length > 0)) return [3 /*break*/, 2];
                        req = new TimecardAddCommentsReq();
                        req.employeeId = this.model.employee.id;
                        req.employeeName = this.model.employee.name;
                        req.date = this.model.date;
                        req.timecardPredefinedComments = this.timecardPredefinedComments;
                        return [4 /*yield*/, TimecardAddCommentsDialogComponent.openDialog('Add Comments', req, this.modalService, 'multiple')];
                    case 1:
                        res = _a.sent();
                        if (!res.isApply) {
                            return [2 /*return*/];
                        }
                        this.model.comment = res.comment.replace(/\n\n/g, "|");
                        _a.label = 2;
                    case 2:
                        if (this.model.unApprovedPayCodesCount > 0 && this.model.isApproved) {
                            this.modalService.globalAnchor.openConfirmDialog("Warning", 'This timecard can`t be saved. Pay codes must be approved first. Do you want to navigate to the Pay Code Approvals screen?', function (result) {
                                if (result) {
                                    _this.navigateToUnapprovedPaycodes();
                                }
                            });
                            return [2 /*return*/];
                        }
                        if (this.model.unApprovedOTCount > 0 && this.model.isApproved) {
                            this.modalService.globalAnchor.openConfirmDialog("Warning", 'This timecard can`t be saved. Overtime must be approved first. Do you want to navigate to the Overtime Approvals Screen?', function (result) {
                                if (result) {
                                    _this.navigateToUnapprovedOvertimes(_this.model.primaryPosition.orgLevelId);
                                }
                            });
                            return [2 /*return*/];
                        }
                        this.saveTimecardInternal();
                        return [2 /*return*/];
                }
            });
        });
    };
    DailyTimeCardActionsBarComponent.prototype.approveTimecard = function () {
        var _this = this;
        if (this.model.locked || !this.model.canModifyOwnTimecard || !this.model.canApprove || this.lockSave || !this.model.canEditTimecard || this.model.isPayrollCycleSubmitted || this.model.hasMissingPunchesAlert || this.hasOverlap || this.model.hasMissingPunches) {
            this.modalService.globalAnchor.openInfoDialog("Warning", 'This timecard can`t be approved.');
            return;
        }
        if (this.model.unApprovedPayCodesCount > 0) {
            this.modalService.globalAnchor.openConfirmDialog("Warning", 'This timecard can`t be approved. Pay codes must be approved first. Do you want to navigate to the Pay Code Approvals screen?', function (result) {
                if (result) {
                    _this.navigateToUnapprovedPaycodes();
                }
            });
            return;
        }
        if (this.model.unApprovedOTCount > 0) {
            this.modalService.globalAnchor.openConfirmDialog("Warning", 'This timecard can`t be approved. Overtime must be approved first. Do you want to navigate to the Overtime Approvals Screen?', function (result) {
                if (result) {
                    _this.navigateToUnapprovedOvertimes(_this.model.primaryPosition.orgLevelId);
                }
            });
            return;
        }
        this.managementService.approveTimecard();
        if (this.initialApproved === this.model.isApproved) {
            this.changeManagementService.clearChanges(this.approveGroup);
        }
        else {
            this.changeManagementService.changeNotify(this.approveGroup);
        }
        this.saveTimecard();
    };
    DailyTimeCardActionsBarComponent.prototype.unapproveTimecard = function () {
        if (this.model.locked || !this.model.canModifyOwnTimecard || !this.model.canUnapprove || this.model.beforeHire || this.lockSave || this.model.isPayrollCycleSubmitted || this.hasOverlap) {
            this.modalService.globalAnchor.openInfoDialog("Warning", 'This timecard can`t be un-approved.');
            return;
        }
        this.managementService.unapproveTimecard();
        if (this.initialApproved === this.model.isApproved) {
            this.changeManagementService.clearChanges(this.approveGroup);
        }
        else {
            this.changeManagementService.changeNotify(this.approveGroup);
        }
        this.saveTimecard();
    };
    DailyTimeCardActionsBarComponent.prototype.exportDebugTimecard = function () {
        this.managementService.onExportTimecard();
    };
    DailyTimeCardActionsBarComponent.prototype.navigateToUnapprovedPaycodes = function () {
        this.managementService.navigateToUnapprovedPaycodes();
    };
    DailyTimeCardActionsBarComponent.prototype.navigateToUnapprovedOvertimes = function (homeOrgLevelId) {
        this.managementService.navigateToUnapprovedOvertimes(homeOrgLevelId);
    };
    DailyTimeCardActionsBarComponent.prototype.onRecreatePunches = function () {
        var _this = this;
        if (this.disableEditTimeCard) {
            this.modalService.globalAnchor.openInfoDialog("Warning", 'This timecard can`t be Edited.');
            return;
        }
        ConfirmDialogComponent.openDialog('Warning', 'This action will remove all existing punches and create new punches based on schedule start and end times. Do you wish to proceed?', this.modalService, function (result) {
            if (!result) {
                return;
            }
            _this.changeManagementService.canMoveForward(undefined, ChangesDialogOptions.DOWORK)
                .then(function (canMove) {
                if (canMove) {
                    _this.managementService.discardPunches();
                }
            });
        });
    };
    DailyTimeCardActionsBarComponent.prototype.onRemoveCodes = function () {
        var _this = this;
        if (this.disableEditTimeCard) {
            this.modalService.globalAnchor.openInfoDialog("Warning", 'This timecard can`t be Edited.');
            return;
        }
        this.changeManagementService.canMoveForward(undefined, ChangesDialogOptions.DOWORK)
            .then(function (canMove) {
            if (canMove) {
                _this.managementService.discardPayRules();
            }
        });
    };
    DailyTimeCardActionsBarComponent.prototype.onOptionSelected = function (option) {
        this.managementService.selectDisplayOption(option);
    };
    DailyTimeCardActionsBarComponent.prototype.openAudit = function () {
        var req = new AuditTrailLimitedReq();
        req.employeeId = +this.model.employee.id;
        req.employeeName = this.model.employee.name;
        req.date = this.logicalDate;
        req.area = 'Timecard';
        req.header = 'Timecard Audit';
        req.orgLevelId = this.model.primaryPosition.orgLevelId;
        AuditTrailLimitedDialogComponent.openDialog(req, this.modalService, function (result, cmd) {
            //todo
        });
    };
    DailyTimeCardActionsBarComponent.prototype.navigateToIndividualTimecards = function () {
        this.tcNaviService.NavigateToIndividualTimecards(+this.model.employee.id, this.currentPaycycle.startDate, this.currentPaycycle.endDate);
    };
    DailyTimeCardActionsBarComponent.prototype.lockTimecard = function () {
        this.managementService.lockTimecard(this.model);
    };
    DailyTimeCardActionsBarComponent.prototype.unlockTimecard = function () {
        this.managementService.unlockTimecard(this.model);
    };
    DailyTimeCardActionsBarComponent.prototype.findPaycycle = function () {
        var _this = this;
        this.currentPaycycle = null;
        this.payCycleHelper.getPayCycleByDate(this.logicalDate, this.model.primaryPosition.orgLevelId, +this.model.employee.id)
            .then(function (cycle) {
            _this.currentPaycycle = cycle;
        });
    };
    DailyTimeCardActionsBarComponent.prototype.saveTimecardInternal = function () {
        var _this = this;
        if (this.preventOverlappingPunches) {
            this.managementService.overlapingPunchesAction(true);
        }
        else {
            if (!this.model.hasMissingPunches) {
                this.changeManagementService.clearChanges();
                this.managementService.onSaveTimecard();
                return;
            }
            this.modalService.globalAnchor.openConfirmDialog("Warning", 'Missing punches appear on this timecard, do you wish to proceed?', function (result) {
                if (result) {
                    _this.changeManagementService.clearChanges();
                    _this.managementService.onSaveTimecard();
                }
            });
        }
    };
    Object.defineProperty(DailyTimeCardActionsBarComponent.prototype, "disableEditTimeCard", {
        get: function () {
            return this.model ? (!this.model.canEditTimecard || !this.model.canEditOwnTimecard) : true;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardActionsBarComponent.prototype, "loadSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardActionsBarComponent.prototype, "editSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardActionsBarComponent.prototype, "overlapSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardActionsBarComponent.prototype, "optionsChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardActionsBarComponent.prototype, "notifyChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardActionsBarComponent.prototype, "overlapDescSubscription", void 0);
    return DailyTimeCardActionsBarComponent;
}());
export { DailyTimeCardActionsBarComponent };
