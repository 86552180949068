import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { ResponseBody } from '../../../core/models/api/response-body';
import { FieldsMeta } from '../../../core/models/index';
import { configurationConfig } from '../../configuration.config';
import { EmployersConfigurationMapService } from './employers-configuration-map.service';
import { AleGroupView, Employer, AleGroup, IAleGroup, AleGroupAssign, OrganizationDialogOptions } from '../../../configuration/models/employers/index';
import { EmployersContainer } from '../../models/employers/employers-container';

@Injectable()
export class EmployersConfigurationApiService {
  constructor(private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: EmployersConfigurationMapService) {
  }

  public getEmployers(orgLevelId: number): Promise<EmployersContainer> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.ACA.companies.all}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapEmployers(response.data)
      );
    return promise;
  }

  public getEmployersOrganization(employerId: number): Promise<OrganizationDialogOptions> {
    const url: string = `${this.getApiRootWithoutOrgLevel()}/${employerId}/${configurationConfig.api.ACA.companies.organization.root}/${configurationConfig.api.ACA.companies.organization.all}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapEmployersOrganization(response.data)
      );
    return promise;
  }

  public getAleGroups(orgLevelId: number): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.ACA.companies.groups.root}/${configurationConfig.api.ACA.companies.groups.all}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    return this.apiUtilService
      .request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return this.mapService.mapAleGroups(response.data)
      });
  }

  public postEmployers(orgLevelId: number, employer: Employer): Promise<any>  {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.ACA.companies.company}`;
    let body: any = this.mapService.mapToEmployersDto(employer);
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }
  
  public saveAssignGroup(orgLevelOrgId : number, groups: AleGroupAssign[]): Promise<any> {
    const url =  `${this.getOrgLevelApiRoot(orgLevelOrgId)}/${configurationConfig.api.ACA.companies.assign.root}`;
    let body: any = this.mapService.mapAssignGroup(groups);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public addUpdateGroup(orgLevelId: number, group: AleGroupView): Promise<AleGroup> {
    
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.ACA.companies.groups.root}`;
    const body = this.mapService.mapEmployerGroupDto(group);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService.request<IAleGroup, Meta>(request)
      .then((response: ResponseBody<IAleGroup, Meta>) => {
        return this.mapService.mapEmployerGroup(response.data)});
  }

  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.companies.root}/${orgLevelId}`;
  }

  private getApiRootWithoutOrgLevel(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.companies.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
