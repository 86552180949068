/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i4 from "../../../common/components/spinner/spinner.component";
import * as i5 from "./dashboard-item.component";
import * as i6 from "../../../common/services/modal/modal.service";
var styles_DashboardItemComponent = [i0.styles];
var RenderType_DashboardItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardItemComponent, data: {} });
export { RenderType_DashboardItemComponent as RenderType_DashboardItemComponent };
function View_DashboardItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_DashboardItemComponent_2(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_DashboardItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "app__logo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "app__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "app__url"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "app-url-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "dashboard-edit-button-alert"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onStartAlerts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "fas fa-cog"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Edit "])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "slx-spinner", [["class", "app__spinner"]], null, null, null, i3.View_SpinnerComponent_0, i3.RenderType_SpinnerComponent)), i1.ɵdid(13, 49152, null, 0, i4.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DashboardItemComponent_1)), i1.ɵdid(15, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, [["contentTmpl", 2]], null, 0, null, View_DashboardItemComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fa"; var currVal_2 = ((_co.dashboard == null) ? null : _co.dashboard.icon); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = _co.progressbar; _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 16); _ck(_v, 15, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "app ", _co.className, ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = ((_co.dashboard == null) ? null : _co.dashboard.title); _ck(_v, 8, 0, currVal_3); }); }
export function View_DashboardItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-dashboard-item", [], null, null, null, View_DashboardItemComponent_0, RenderType_DashboardItemComponent)), i1.ɵdid(1, 49152, null, 0, i5.DashboardItemComponent, [i6.ModalService], null, null)], null, null); }
var DashboardItemComponentNgFactory = i1.ɵccf("slx-dashboard-item", i5.DashboardItemComponent, View_DashboardItemComponent_Host_0, { dashboard: "dashboard", className: "className", progressbar: "progressbar" }, { navigationClick: "navigationClick", onHasChanges: "onHasChanges" }, ["*"]);
export { DashboardItemComponentNgFactory as DashboardItemComponentNgFactory };
