<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <div class="ec-header">
      <div class="flex-horizontal left-header">
            <div class="dates-range-host">
              <slx-date-range-ngx
                name="startEndDate"
                [startDate]="managementService.startDate"
                [endDate]="managementService.endDate"
                (rangeDateChanged)="onFilterDateChanged($event)"
                [hasApplyButton]="true"
                [maxSecRange]="2592000"
                [maxRangeErrorText]="'You can not choose dates wider than 30 days'"
                slxControlActiveState
              ></slx-date-range-ngx>
            </div>
            <div class="rollup-message">
              Employees with hours at multiple facilities on the same timecard day will be included in counts on multiple rows
            </div>
      </div>
      <div class="flex-horizontal">
        <button type="button" class="theme-icon-button col-toggler"
        [popper]="columnsToggler"
        [popperTrigger]="'click'"
        [popperPlacement]="'bottom-start'"
        [popperDisableStyle]="'true'">
          <i class="fa fa-cog" aria-hidden="true"></i>
         </button>

        <popper-content #columnsToggler class="popper-white-background">
          <div class="column-row">
            <span class="title-col"><strong>Column description</strong></span>
            <span class="display-col"><strong>Display</strong></span>
          </div>
          <div *ngFor="let column of globalState.columns; let idx=index;" class="column-row">
            <span class="title-col">{{column.description}}</span>
            <div class="display-col"  [ngClass]="{ 'display-check': column.readonly }">
              <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-checkbox-small">
                <slx-checkbox-input slx-input name="column{{idx}}" [readonly]="column.readonly" fieldName="column{{idx}}" [(ngModel)]="column.displayed" (ngModelChange)="onChangeColumn($event, column)">
                </slx-checkbox-input>
              </slx-input-decorator>
            </div>
          </div>
          <div class="button-container">
            <button class="margin-button theme-button-apply" type="button" (click)="selectAll($event)"> Select All </button>
            <button class="margin-button theme-button-cancel" type="button" (click)="clearAll($event)"> Clear All</button>
          </div>
        </popper-content>
      </div>
    </div>
    <div class="slx-high-box__body grid-container">
      <slx-punches-rollup-grid></slx-punches-rollup-grid>
    </div>
  </div>
</slx-spinner>


