export interface IPBJChildDownload {
    exportIds: number;
    batchId:number;
}


export class PBJChildDownload {
   public exportIds: number;
   public batchId: number;
   public orgLevelId:number;
}

export class PBJDownloadSummary {
    public batchId: number;
    public startDate: Date;
    public endDate: Date;
    public orgLevelId: string;
 }

 export class PBJDownloadBulkExport {
    public batchId: number;
    public orgLevelId: string;
 }

 export class PBJDownloadExcelPdf {
  public batchId: number;
  public type: string;
  public exportId: string;
  public userId: number;
  public alias: string;
  public orgLevelId: number;
}

export class PBJDownloadExcelPdfOptions {
public BatchId: number;
public FileType: string;
public ExportIds: string;
public UserId: number;
public ExportIdStatus: string;
public ExportIdDetails: string;
public OrgLevelId: number;
}

