import { Component, OnInit } from '@angular/core';
import { KendoGridStateHelper } from '../../../../app/common';
import { IPartnerClientStatusData, PartnerClientStatusData } from '../../../../app/common/models/integrations/partner-client-status.model';
import { PartnerConfigApiService } from '../../services';
import { process, State } from '@progress/kendo-data-query';
import { appConfig, IApplicationConfig } from '../../../../app/app.config';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'slx-client-activation',
  templateUrl: './client-activation.component.html',
  styleUrls: ['./client-activation.component.scss']
})
export class ClientActivationComponent implements OnInit {
  public status = 'Activate';
  public isLoading:boolean = false;
  public isActive:boolean ;
  public url:string;
  public partnerStatus:string= '';
  public partnerName: string;
  public version: string ='';
  public authDate:string;
  public appConfig: IApplicationConfig;
  public partnerClientStatusData : IPartnerClientStatusData;
  public records: PartnerClientStatusData[] = [];
  constructor(private partnerConfigApiService : PartnerConfigApiService, public datepipe: DatePipe) {  }

  ngOnInit() {
    this.appConfig = appConfig;
    this.getPartnerClientActivationStatus();
  }

  openNewTab(url) {
    window.open(url, '_blank');
  }

  public getPartnerClientActivationStatus() {
    this.isLoading = true;
    this.partnerConfigApiService.getPartnerClientActivationStatus().then((partnerClientStatusData: IPartnerClientStatusData) => {
      this.partnerClientStatusData = partnerClientStatusData;      
      this.url = this.partnerClientStatusData.initiateUrl;
      this.partnerStatus = this.partnerClientStatusData.status ;
      this.partnerName = this.partnerClientStatusData.partnerName;
      this.version = this.partnerClientStatusData.version ;
      this.authDate = this.datepipe.transform(this.partnerClientStatusData.authorizedDate, 'MM/dd/yyyy')
         this.isLoading = false;
         this.isActive = false;
         this.verifyStatus()
    });
  }
  public verifyStatus() : void {
   
    if(this.partnerStatus == 'Active') {
    this.isActive = true;
    }
  }
  public showInActiveMsg() : boolean {
    if(this.isActive===undefined ){
      return false
    }
    else{
      return true;
    }
  }
 
}