import * as tslib_1 from "tslib";
import { PerformanceReviewCode } from '../../../organization/models';
var OrgLevelObject = /** @class */ (function () {
    function OrgLevelObject() {
    }
    return OrgLevelObject;
}());
export { OrgLevelObject };
var CfgPmCode = /** @class */ (function (_super) {
    tslib_1.__extends(CfgPmCode, _super);
    function CfgPmCode() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CfgPmCode.prototype, "modifiedUserName", {
        get: function () {
            return this.updatedBy || this.addedBy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CfgPmCode.prototype, "modifiedDate", {
        get: function () {
            return this.updatedOn || this.addedOn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CfgPmCode.prototype, "typeName", {
        get: function () {
            return this.type ? this.type.description : '';
        },
        enumerable: true,
        configurable: true
    });
    return CfgPmCode;
}(PerformanceReviewCode));
export { CfgPmCode };
