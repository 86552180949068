<slx-spinner [show]="isLoading">
  <div class="slx-high-box__body slx-main-content-indents aca-export-data-content">
    <div class="aca-export-data-content_header">
      <div class="header-item group-item">
        <i class="fas fa-building" aria-hidden="true"></i>
        <span class="header-item__text">Export Name</span>
      </div>
      <div class="header-item request-status-item">
        <i class="fas fa-thermometer-three-quarters" aria-hidden="true"></i>
        <span class="header-item__text">Status</span>
      </div>
    </div>
    <div class="aca-export-data-section" *ngFor="let configuration of exportConfigurations">
      <slx-aca-export-section [configuration]="configuration" style="width: 100%">
      </slx-aca-export-section>
    </div>
  </div>
</slx-spinner>
