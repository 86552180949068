/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./static-dropdown-list.component";
var styles_StaticDropdownListComponent = [];
var RenderType_StaticDropdownListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StaticDropdownListComponent, data: {} });
export { RenderType_StaticDropdownListComponent as RenderType_StaticDropdownListComponent };
function View_StaticDropdownListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "li", [["class", "slx-static-list__item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["class", "slx-static-list__i-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "i", [["aria-hidden", "true"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "slx-static-list__i-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit[_co.iconField]; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit[_co.titleField]; _ck(_v, 6, 0, currVal_1); }); }
export function View_StaticDropdownListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "div", [["class", "slx-static-list"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 10, "span", [["class", "slx-static-list__label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickLabel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [["class", "slx-static-list__icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "i", [["aria-hidden", "true"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(7, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "slx-static-list__text"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "span", [["class", "slx-static-list__arrow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fas"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(13, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [["class", "slx-static-list__label-m"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickLabel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-plus"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 4, "ul", [["class", "slx-static-list__list"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(18, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StaticDropdownListComponent_1)), i0.ɵdid(20, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-static-list"; var currVal_1 = _co.disabledClass; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.controlIcon; _ck(_v, 7, 0, currVal_2); var currVal_4 = "fas"; var currVal_5 = _co.arrowIcon; _ck(_v, 13, 0, currVal_4, currVal_5); var currVal_6 = "slx-static-list__list"; var currVal_7 = _co.listClass; _ck(_v, 18, 0, currVal_6, currVal_7); var currVal_8 = _co.items; _ck(_v, 20, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.placeholder; _ck(_v, 9, 0, currVal_3); }); }
export function View_StaticDropdownListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-static-dropdown-list", [], null, null, null, View_StaticDropdownListComponent_0, RenderType_StaticDropdownListComponent)), i0.ɵdid(1, 49152, null, 0, i2.StaticDropdownListComponent, [], null, null)], null, null); }
var StaticDropdownListComponentNgFactory = i0.ɵccf("slx-static-dropdown-list", i2.StaticDropdownListComponent, View_StaticDropdownListComponent_Host_0, { options: "options", valueField: "valueField", titleField: "titleField", iconField: "iconField", readonly: "readonly", placeholder: "placeholder", controlIcon: "controlIcon" }, { selectItem: "selectItem" }, []);
export { StaticDropdownListComponentNgFactory as StaticDropdownListComponentNgFactory };
