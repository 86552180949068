var RoleRight = /** @class */ (function () {
    function RoleRight() {
    }
    Object.defineProperty(RoleRight.prototype, "isDirty", {
        get: function () {
            return this.isEnabled !== this.initial_isEnabled;
        },
        enumerable: true,
        configurable: true
    });
    RoleRight.prototype.setInitialState = function () {
        this.initial_isEnabled = this.isEnabled;
    };
    return RoleRight;
}());
export { RoleRight };
var RoleRightModule = /** @class */ (function () {
    function RoleRightModule() {
    }
    return RoleRightModule;
}());
export { RoleRightModule };
var RoleRightGroup = /** @class */ (function () {
    function RoleRightGroup() {
    }
    return RoleRightGroup;
}());
export { RoleRightGroup };
