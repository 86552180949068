import { appConfig } from './../../../../app.config';
import { ResponseBody } from './../../../../core/models/api/response-body';
import { Meta } from './../../../../core/models/api/meta';
import { HttpRequest } from '@angular/common/http';
import { GetHiredLink, IGetHiredLink } from './../../../models/gethired/gethired-link';
import { ApiUtilService, UrlParamsService } from './../../../../common/services/index';
import { Injectable } from '@angular/core';
import { organizationConfig } from '../../../../organization/organization.config';
import { GetHiredMenuMapService } from './get-hired-menu-map.service';

@Injectable()
export class GetHiredMenuApiService {
  constructor(
    private mapService: GetHiredMenuMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getLink(orgLevelId: number): Promise<GetHiredLink> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.api.gethired.link}`;
   
    let request: HttpRequest<any> =  this.urlParamsService.createGetRequest(url, {
      orgLevelId: orgLevelId
    });

    let promise: Promise<GetHiredLink> = this.apiUtilService.request<IGetHiredLink, Meta>(request)
    .then((response: ResponseBody<IGetHiredLink, Meta>) => this.mapService.mapToModel(response.data));
    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.api.gethired.root}`;
  }
}
