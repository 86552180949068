import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { searchComponents } from './components/index';
import { treeClasses, treeComponents } from './components/tree/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { services } from './services/index';
import { pipes } from './components/query-builder/pipes/index';
import { OrganizationModule } from '../../organization/index';
import { GridModule } from '@progress/kendo-angular-grid';
import { MomentModule } from 'angular2-moment';
import { models } from './models/index';

@NgModule({
  imports: [
    CommonAppModule,
    CommonModule,
    RouterModule,
    FormsModule, ReactiveFormsModule,
    OrganizationModule,
    GridModule,
    MomentModule,
    BrowserAnimationsModule
  ],
  declarations: [
    ...searchComponents,
    ...treeComponents,
    ...pipes,
  ],
  exports: [
    ...searchComponents,
    ...treeComponents
  ],
  providers: [
    ...services,
    ...pipes
  ]
})
export class EmployeeSearchModule {

}
