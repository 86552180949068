import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorEvent, FileRestrictions } from '@progress/kendo-angular-upload';
import * as _ from 'lodash';
import { ExcelFileExtractHelper } from './excel-file-extract.helper';

@Component({
  selector: 'slx-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends ExcelFileExtractHelper implements OnInit {

  @Input()
  public isDisabled: boolean;

  @Output()
  public cancelChanges: EventEmitter<any> = new EventEmitter();

  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  acceptString = '.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';

  public userName: string;
  public userFiles: Array<any>;
  public myForm: FormGroup;
  public submitted = false;
  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['xlsx'],
  };

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      myUpload: [this.userFiles, [Validators.required]],
    });

    this.myForm.valueChanges.subscribe((data) => {
      if (!_.isNull(data.myUpload)) {
        this.onValueChanged(data);
      }
    });
  }

  onValueChanged(_data?: any) {
    this.convertToBinaryString(_data.myUpload[0].rawFile);
  }

  cancelEventHandler(e: any) {
    this.cancelChanges.emit(true);
  }

  save(_value: any, valid: boolean) {
    this.submitted = true;

    if (valid) {
      console.log('Everything is OK!');
    }
  }

}
