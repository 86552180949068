import * as tslib_1 from "tslib";
import { OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { EmployeeSectionsDocuments, EmployeeDocument } from '../../../models/index';
import { EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { KendoGridStateHelper, FileService, ConfirmDialogComponent, ModalService, ConfirmOptions } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { LookupService, LookupType } from '../../../../../organization/index';
import { AppSettingsManageService } from '../../../../../app-settings/services/index';
var EmployeeSectionsDocumentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsDocumentsComponent, _super);
    function EmployeeSectionsDocumentsComponent(fileService, modalService, lookupService, changeDetector, appSettingsManageService, employeeSectionsPersonalApiService, decorator, ngZone) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.fileService = fileService;
        _this.modalService = modalService;
        _this.lookupService = lookupService;
        _this.changeDetector = changeDetector;
        _this.appSettingsManageService = appSettingsManageService;
        _this.sort = [];
        _this.categoriesLookup = [];
        _this.canShowFileName = false;
        decorator.isEditableByConfiguration = false;
        _this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.skip = 0;
        _this.gridState.state.sort = [{ field: 'addedOn', dir: 'desc' }];
        _this.gridRefreshSubscription = _this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        _this.gridState.onEdit$.subscribe(function (item) {
            _this.isFileValid = true;
            _this.canShowFileName = true;
        });
        _this.gridState.onCancel$.subscribe(function (item) {
            _this.isFileValid = false;
            _this.canShowFileName = false;
        });
        _this.gridSaveSubscription = _this.gridState.onSave$.subscribe(function (item) {
            if (item.isNew) {
                _this.doAdd(item.dataItem);
                _this.gridState.closeEditor(_this.grid);
                _this.isFileValid = false;
                _this.canShowFileName = false;
            }
            else {
                _this.doUpdate(item.dataItem);
                _this.gridState.closeEditor(_this.grid);
            }
        });
        _this.gridRemoveSubscription = _this.gridState.onRemove$.subscribe(function (item) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure that you want to remove this document?', _this.modalService, function (result) {
                if (result) {
                    _this.doRemove(item.dataItem);
                }
            }, options);
        });
        _this.appSettingsManageService.getAppServerConfig()
            .then(function (conf) {
            _this.fileSizeLimit = conf.maxFileSizeLimit;
        });
        _this.updateLookups();
        return _this;
    }
    Object.defineProperty(EmployeeSectionsDocumentsComponent.prototype, "documents", {
        set: function (employeeSectionsDocuments) {
            this.employeeSectionsDocuments = employeeSectionsDocuments;
            this.refreshGrid();
            this.updateLookups();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDocumentsComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isEditableWhenEmpTerminated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDocumentsComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDocumentsComponent.prototype, "fileSizeLimitInMB", {
        get: function () {
            return Math.round(this.fileSizeLimit / 1024) + "MB";
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsDocumentsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsDocumentsComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsDocuments;
    };
    EmployeeSectionsDocumentsComponent.prototype.onDocumentFileClick = function (documentId) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.getDocumentFile(documentId)
            .then(function (data) { return _this.fileService.saveToFileSystem(data.blob, data.file); })
            .finally(function () { return _this.stopProgress(); });
    };
    EmployeeSectionsDocumentsComponent.prototype.onFileClick = function (event, item) {
        var elem = event.target;
        elem.value = '';
        item.file = null;
        this.isFileValid = false;
    };
    EmployeeSectionsDocumentsComponent.prototype.onFileChange = function (event, item, fileInput) {
        this.canShowFileName = false;
        var files = _.get(event, 'target.files');
        var file = files[0];
        if (!file) {
            return;
        }
        item.file = file;
        item.fileName = file.name;
        item.name = file.name;
        this.validateFile(file.size, item, fileInput);
    };
    EmployeeSectionsDocumentsComponent.prototype.canEditItem = function (item) {
        if (!item || !item.category) {
            return true;
        }
        var categoryMeta = _.find(this.employeeSectionsDocuments.fieldsMeta.fields, function (field) { return field.fieldName.toLowerCase() === item.category.description.toLowerCase(); });
        if (!categoryMeta) {
            return true;
        }
        return categoryMeta.access.toLowerCase() === 'full';
    };
    EmployeeSectionsDocumentsComponent.prototype.onStartAdd = function () {
        this.gridState.closeEditor(this.grid);
        var document = new EmployeeDocument();
        this.grid.addRow(document);
        this.isFileValid = false;
    };
    EmployeeSectionsDocumentsComponent.prototype.doAdd = function (document) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.addDocument(document, this.employeeId)
            .then(function (status) {
            _this.loadSubsection();
        }).catch(function (error) {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsDocumentsComponent.prototype.doUpdate = function (document) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.editDocument(document, this.employeeId)
            .then(function (status) {
            _this.loadSubsection();
        })
            .catch(function (error) {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsDocumentsComponent.prototype.doRemove = function (document) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.deleteDocument(document.id, this.employeeId)
            .then(function (status) {
            _this.loadSubsection();
        }).catch(function (error) {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsDocumentsComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.getPersonalDocuments(this.employeeId)
            .then(function (employeeSectionsDocuments) {
            _this.employeeSectionsDocuments = employeeSectionsDocuments;
            _this.refreshGrid();
            _this.stopProgress();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsDocumentsComponent.prototype.validateFile = function (fileSize, item, fileInput) {
        var _this = this;
        if (!fileSize) {
            fileInput.value = null;
            item.name = '';
            this.isFileValid = true;
            return;
        }
        var fileSizeInKbytes = fileSize / 1024;
        this.isFileValid = fileSizeInKbytes <= this.fileSizeLimit;
        setTimeout(function () {
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        }, 0);
    };
    EmployeeSectionsDocumentsComponent.prototype.refreshGrid = function () {
        if (!this.employeeSectionsDocuments) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.employeeSectionsDocuments.documents, this.gridState.state);
    };
    EmployeeSectionsDocumentsComponent.prototype.updateLookups = function () {
        var _this = this;
        this.lookupService.getLookup({
            lookupType: LookupType.employeeDocumentCategories
        }).then(function (lookup) {
            var categories = [];
            if (_this.employeeSectionsDocuments && _this.employeeSectionsDocuments.fieldsMeta) {
                _.forEach(lookup.items, function (item) {
                    if (!item || !item.description) {
                        return;
                    }
                    var categoryMeta = _.find(_this.employeeSectionsDocuments.fieldsMeta.fields, function (field) { return field.fieldName.toLowerCase() === item.description.toLowerCase(); });
                    if (!categoryMeta) {
                        return;
                    }
                    if (categoryMeta.access.toLowerCase() === 'full') {
                        categories.push(item);
                    }
                });
            }
            _this.categoriesLookup = categories;
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsDocumentsComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsDocumentsComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsDocumentsComponent.prototype, "gridRemoveSubscription", void 0);
    return EmployeeSectionsDocumentsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsDocumentsComponent };
