import { Injectable, Type } from '@angular/core';
import {
  ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, NavigationExtras,
  Params, Resolve, UrlSegment
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

import { ApplicationStateBusService } from '../../../organization/services/index';
import { OrgLevel } from '../../../state-model/models/index';

@Injectable()
export class OrgLevelResolver implements Resolve<OrgLevel> {

  private selectedOrgLevel: OrgLevel;
  constructor(private busService: ApplicationStateBusService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.busService.orgLevelSelected$.subscribe((orgLevel: OrgLevel) => {
      this.selectedOrgLevel = orgLevel;
    });
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): OrgLevel {
    if (this.selectedOrgLevel) {
      let id = _.toInteger(route.queryParamMap.get('orgLevelId'));
      if (!id) {
       const tree = this.router.parseUrl(state.url);
       tree.queryParams['orgLevelId'] = this.selectedOrgLevel.id;
        this.router.navigateByUrl(tree);
      }
      return this.selectedOrgLevel;
    }
    return null;
  }
}
