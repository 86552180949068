<form novalidate #form="ngForm">
 
  <!-- <div class="row slx-dnr">
    <div class="col-xs-12">
          <label class="slx-label dnr-label">Preferred Worker: </label>
        <slx-switcher
          class="indent-right dnr"
          [(ngModel)]="preferredEmp"   
          (ngModelChange)="verifyPreferredStateChange($event)"            
          label=""
          name="preferredEmp"
          [disabled]="verifyDnrState() || doNotReturn"
          labelOn="Yes"
          labelOff="No"> 
      </slx-switcher>
      
    </div>  
  </div> -->
  <div class="row slx-dnr">
    <div class="col-xs-2">
          <label class="slx-label dnr-label">Do Not Return: </label>
        <slx-switcher
          class="indent-right dnr"
          [(ngModel)]="doNotReturn"
          (ngModelChange)="verifyDNRStateChange($event)"
          label=""
          name="dnr"
          [disabled]="verifyDnrState()|| preferredEmp"
          labelOn="Yes"
          labelOff="No">  
      </slx-switcher>
      
    </div>
    <div class="col-xs-6">
      <span *ngIf = "doNotReturn" ><label class="slx-label dnr-screen-msg" >Upon saving this record, the agency worker will be removed from all Future Shifts.<br/>  
        A Do Not Return notification will be distributed internally.<br/>  
        For Staffing Marketplace, their Agency will be notified of the do not return state and reason. </label>
      </span>
    </div>
  </div>
  <div class="row slx-dnr" *ngIf="state.isEditMode">
    <div class="col-xs-6">
      <slx-input-decorator className="slx-form-group slx-form-expand"  >
        <textarea slx-input  [disabled] = "!doNotReturn" [required]="doNotReturn" type="text" slxMaxLength="1000" [slxMinLength] ="getMinLength()" rows="3" name="detail" placeholder="Reason for Do Not return" [(ngModel)]="details"
        ></textarea>
        <span errorMessage for="required">*required</span>
        <span errorMessage for="slxMinLength">Reason should be a minimun length of '50'</span>
               
      </slx-input-decorator>
    </div>
    <div class="col-xs-6">
      
    </div>
  </div>
</form>

<kendo-grid scrollable="'scrollable'"
[data]="gridState.view"
(dataStateChange)="gridState.dataStateChange($event)"
[sortable]="{ mode: 'multiple' }"
[sort]="gridState.state.sort"
[pageable]="true"
[pageSize]="pageSize"
[skip]="gridState.state.skip" 
selectable="true"
(selectionChange)="gridState.selectionChange($event)"
[slxKendoGridState]="gridState.gridDefinition"
(stateRestored)="gridState.dataStateChange($event)">

<kendo-grid-column field="dnr" title="DNR">
<ng-template kendoGridHeaderTemplate>
DNR
</ng-template>
<ng-template kendoGridCellTemplate let-dataItem>
{{getDisplayStatus(dataItem.dnr)}}
</ng-template>
<ng-template kendoGridFilterCellTemplate let-filter let-column="column">
<slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
</slx-kendo-grid-string-filter>
</ng-template>
</kendo-grid-column>
<!-- <kendo-grid-column field="preferred" title="Preferred">
<ng-template kendoGridCellTemplate let-dataItem>
{{getDisplayStatus(dataItem.preferred)}}
</ng-template>
<ng-template kendoGridFilterCellTemplate let-filter let-column="column">
<slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
</slx-kendo-grid-string-filter>
</ng-template>
</kendo-grid-column> -->
<kendo-grid-column field="reason" title="Reason">
<ng-template kendoGridHeaderTemplate>
  Reason
</ng-template>
<ng-template kendoGridCellTemplate let-dataItem>
  {{dataItem.reason}}
</ng-template>
<ng-template kendoGridFilterCellTemplate let-filter let-column="column">
  <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
  </slx-kendo-grid-string-filter>
</ng-template>
</kendo-grid-column>
<kendo-grid-column field="user" title="User">
<ng-template kendoGridHeaderTemplate>
  User
</ng-template>
<ng-template kendoGridCellTemplate let-dataItem>
  {{dataItem.user }}
</ng-template>
<ng-template kendoGridFilterCellTemplate let-filter let-column="column">
  <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
  </slx-kendo-grid-string-filter>
</ng-template>
</kendo-grid-column>

<kendo-grid-column field="startDate" title="Start Date">
<ng-template kendoGridHeaderTemplate>
 Start Date
</ng-template>
<ng-template kendoGridCellTemplate let-dataItem>
  {{dataItem.startDate | amDateFormat: appConfig.dateFormat }}
</ng-template>
<ng-template kendoGridFilterCellTemplate let-filter let-column="column">
  <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
  </slx-kendo-grid-date-filter>
</ng-template>
</kendo-grid-column>
<kendo-grid-column field="endDate" title="End Date">
<ng-template kendoGridHeaderTemplate>
 End Date
</ng-template>
<ng-template kendoGridCellTemplate let-dataItem>
  {{dataItem.endDate | amDateFormat: appConfig.dateFormat }}
</ng-template>
<ng-template kendoGridFilterCellTemplate let-filter let-column="column">
  <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
  </slx-kendo-grid-date-filter>
</ng-template>
</kendo-grid-column>
</kendo-grid>
