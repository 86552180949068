import { Month } from './month';

export const Months: Month[] = [
    { name: 'January', shortName: 'Jan', id: 1 },
    { name: 'February', shortName: 'Feb', id: 2 },
    { name: 'March', shortName: 'Mar', id: 3 },
    { name: 'April', shortName: 'Apr', id: 4 },
    { name: 'May', shortName: 'May', id: 5 },
    { name: 'June', shortName: 'Jun', id: 6 },
    { name: 'July', shortName: 'Jul', id: 7 },
    { name: 'August', shortName: 'Aug', id: 8 },
    { name: 'September', shortName: 'Sep', id: 9 },
    { name: 'October', shortName: 'Oct', id: 10 },
    { name: 'November', shortName: 'Nov', id: 11 },
    { name: 'December', shortName: 'Dec', id: 12 }];

export const MonthsWithEmpty: Month[] = [{ name: '', shortName: '', id: 0 }].concat(Months);
