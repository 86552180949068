import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Assert } from '../../../../framework/index';
import {
  IEmployeeSectionsAudit,
  EmployeeSectionsAudit,
  IEmployeeSectionsFutureUpdateField,
  IEmployeeSectionsFutureUpdate,
  IEmployeeSectionsFutureUpdates,
  EmployeeSectionsFutureUpdateField,
  EmployeeSectionsFutureUpdate,
  EmployeeSectionsFutureUpdates,
  IEmployeeSectionsAuditTrail,
  EmployeeSectionsAuditTrail,
  IEmployeeSectionsAuditTrailNoteRecord,
  EmployeeSectionsAuditTrailNoteRecord,
  IEmployeeSectionsAuditTrailJournalRecord,
  EmployeeSectionsAuditTrailJournalRecord,
  IEmployeeSectionsAuditTrailEditRecord,
  EmployeeSectionsAuditTrailEditRecord,
  IEmployeeSectionsAuditTrailRecord,
  EmployeeSectionsAuditTrailRecord
} from '../../models/index';

import { FieldRenderType, FieldsMeta, Actions } from '../../../../core/models/index';
import { MetaMapService } from '../../../../core/services/index';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { dateTimeUtils } from '../../../../common/utils/index';
import { AppSettingsManageService } from './../../../../app-settings/services';

@Injectable()
export class EmployeeSectionsAuditMapService {
  private metaMapService: MetaMapService;

  serverUtcOffsetSec: number;
  constructor(metaMapService: MetaMapService, private appSettingsManageService: AppSettingsManageService) {
    Assert.isNotNull(metaMapService, 'metaMapService');
    this.metaMapService = metaMapService;
    this.appSettingsManageService.getAppServerConfig()
    .then((appConfig)=> {
      this.serverUtcOffsetSec = appConfig.serverUtcOffsetSec;
    });
  }

  public mapToFutureUpdateField<T>(dfield: IEmployeeSectionsFutureUpdateField): EmployeeSectionsFutureUpdateField<T> {
    Assert.isNotNull(dfield, 'dfield');
    let field: EmployeeSectionsFutureUpdateField<T> = new EmployeeSectionsFutureUpdateField<T>();
    field.fieldData = this.metaMapService.emptyFieldMeta<T>(dfield.value, dfield.fieldName);
    this.metaMapService.setSecurityAccess(field.fieldData.securityAttribute, dfield.access);
    field.displayName = dfield.displayName;
    field.fieldType = dfield.fieldType;
    return field;
  }

  public mapToFutureUpdate(dupdate: IEmployeeSectionsFutureUpdate): EmployeeSectionsFutureUpdate {
    Assert.isNotNull(dupdate, 'dupdate');
    let update: EmployeeSectionsFutureUpdate = new EmployeeSectionsFutureUpdate();
    update.id = dupdate.id;
    update.description = dupdate.description;
    update.requestedOn = dupdate.requestedOn;
    update.scheduledFor = dupdate.scheduledFor;
    update.processedOn = dupdate.processedOn;
    update.requestedBy = dupdate.requestedBy;
    update.updateSystem = dupdate.updateSystem;

    _.forEach(dupdate.updatedFields, (dfield: IEmployeeSectionsFutureUpdateField) => {
      let field: EmployeeSectionsFutureUpdateField<any>;
      switch (dfield.fieldType) {
        case 'number':
          field = this.mapToFutureUpdateField<number>(dfield);
          field.fieldRender = FieldRenderType.number;
          break;
        case 'datetime':
          field = this.mapToFutureUpdateField<Date>(dfield);
          field.fieldRender = FieldRenderType.datetime;
          break;
        default:
          field = this.mapToFutureUpdateField<string>(dfield);
          field.fieldRender = FieldRenderType.string;
          break;
      }
      update.updatedFields.push(field);
    });
    return update;
  }

  public mapToFutureUpdates(dupdates: IEmployeeSectionsFutureUpdates, meta: FieldsMeta): EmployeeSectionsFutureUpdates {
    Assert.isNotNull(dupdates, 'dupdates');
    let updates: EmployeeSectionsFutureUpdates = new EmployeeSectionsFutureUpdates();
    _.forEach(dupdates.updates, (dupdate: IEmployeeSectionsFutureUpdate) => {
      let update: EmployeeSectionsFutureUpdate = this.mapToFutureUpdate(dupdate);
      updates.updates.push(update);
    });
    updates.actions = this.metaMapService.mapActions(meta);
    return updates;
  }

  public mapToAuditTrailNoteRecord(auditTrailRecordDto: IEmployeeSectionsAuditTrailNoteRecord): EmployeeSectionsAuditTrailNoteRecord {
    let record: EmployeeSectionsAuditTrailNoteRecord = new EmployeeSectionsAuditTrailNoteRecord();
    record.isPublic = auditTrailRecordDto.isPublic;
    record.subject = this.metaMapService.emptyFieldMeta<string>(auditTrailRecordDto.subject, 'subject');
    record.detail = this.metaMapService.emptyFieldMeta<string>(auditTrailRecordDto.detail, 'detail');
    record.typeDescription = 'Note';
    return record;
  }

  public mapToAuditTrailJournalRecord(auditTrailRecordDto: IEmployeeSectionsAuditTrailJournalRecord): EmployeeSectionsAuditTrailJournalRecord {
    let record: EmployeeSectionsAuditTrailJournalRecord = new EmployeeSectionsAuditTrailJournalRecord();
    record.before = this.metaMapService.emptyFieldMeta<string>(auditTrailRecordDto.before, 'before');
    record.after = this.metaMapService.emptyFieldMeta<string>(auditTrailRecordDto.after, 'after');
    record.effectiveDate = auditTrailRecordDto.effectiveDate;
    record.subject = this.metaMapService.emptyFieldMeta<string>(auditTrailRecordDto.subject, 'subject');
    record.detail = this.metaMapService.emptyFieldMeta<string>(auditTrailRecordDto.detail, 'detail');
    record.typeDescription = 'Journal';
    return record;
  }

  public mapToAuditTrailEditRecord(auditTrailRecordDto: IEmployeeSectionsAuditTrailEditRecord): EmployeeSectionsAuditTrailEditRecord {
    let record: EmployeeSectionsAuditTrailJournalRecord = new EmployeeSectionsAuditTrailJournalRecord();
    record.before = this.metaMapService.emptyFieldMeta<string>(auditTrailRecordDto.before, 'before');
    record.after = this.metaMapService.emptyFieldMeta<string>(auditTrailRecordDto.after, 'after');
    record.effectiveDate = auditTrailRecordDto.effectiveDate;
    record.typeDescription = 'Edit';

    record.subject = this.metaMapService.emptyFieldMeta<string>(auditTrailRecordDto.fieldName, 'fieldName');
    let detail: string = `Changed from ${record.before.fieldValue} to ${record.after.fieldValue}`;
    if (record.effectiveDate) {
      detail += ` Effective ${moment(record.effectiveDate).format(appConfig.dateFormat)}`;
    }
    record.detail = this.metaMapService.emptyFieldMeta<string>(detail, 'detail');
    return record;
  }

  public mapToAuditTrailRecord(auditTrailRecordDto: IEmployeeSectionsAuditTrailRecord): EmployeeSectionsAuditTrailRecord {
    let record: EmployeeSectionsAuditTrailRecord;

    switch (auditTrailRecordDto.type.toLowerCase()) {
      case 'note':
        record = this.mapToAuditTrailNoteRecord(<IEmployeeSectionsAuditTrailNoteRecord>auditTrailRecordDto);
        break;
      case 'journal':
        record = this.mapToAuditTrailJournalRecord(<IEmployeeSectionsAuditTrailJournalRecord>auditTrailRecordDto);
        break;
      case 'edit':
        record = this.mapToAuditTrailEditRecord(<IEmployeeSectionsAuditTrailEditRecord>auditTrailRecordDto);
        break;
      default:
        throw new Error('Unknown type of EmployeeSectionsAuditTrailRecord');
    }
    record.id = auditTrailRecordDto.id;
    record.type = auditTrailRecordDto.type;
    record.user = auditTrailRecordDto.user;
    record.changedOn =  dateTimeUtils.convertFromDtoLocalTimeZone(auditTrailRecordDto.changedOn.toString(), this.serverUtcOffsetSec);
    return record;
  }

  public mapToAuditTrailNoteRecordDto(auditTrailRecord: EmployeeSectionsAuditTrailNoteRecord): IEmployeeSectionsAuditTrailNoteRecord {
    let recordDto: IEmployeeSectionsAuditTrailNoteRecord = {
      id: auditTrailRecord.id,
      type: 'note',
      subject: auditTrailRecord.subject.fieldValue,
      detail: auditTrailRecord.detail.fieldValue,
      user: '',
      changedOn: null,
      isPublic: auditTrailRecord.isPublic
    };
    return recordDto;
  }

  public mapToAuditTrail(auditTrailDto: IEmployeeSectionsAuditTrail, meta: FieldsMeta): EmployeeSectionsAuditTrail {
    Assert.isNotNull(auditTrailDto, 'auditTrailDto');
    let auditTrail: EmployeeSectionsAuditTrail = new EmployeeSectionsAuditTrail();
    _.forEach(auditTrailDto.records, (recordDto: IEmployeeSectionsAuditTrailRecord) => {
      let record: EmployeeSectionsAuditTrailRecord = this.mapToAuditTrailRecord(recordDto);
      auditTrail.records.push(record);
    });
    auditTrail.actions = this.metaMapService.mapActions(meta);
    /*
    auditTrail.actions = new Actions();
    auditTrail.actions.canEdit = true;
    auditTrail.actions.canAdd = true;
    auditTrail.actions.canDelete = true;*/
    return auditTrail;
  }

  public mapToSectionsAudit(data: IEmployeeSectionsAudit): EmployeeSectionsAudit {
    let audit: EmployeeSectionsAudit = new EmployeeSectionsAudit();
    audit.futureUpdates = this.mapToFutureUpdates(data.futureUpdates.data, data.futureUpdates.metadata);
    audit.auditTrail = this.mapToAuditTrail(data.auditTrail.data, data.auditTrail.metadata);
    return audit;
  }
}

