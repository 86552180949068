import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { Assert } from '../../../framework/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { AuthApiService } from '../../../core/services/auth-api/auth-api.service';
import { unsubscribe } from '../../../core/decorators/index';
var AuthenticationGuard = /** @class */ (function () {
    function AuthenticationGuard(router, authenticationService, userActivityService, authApiService) {
        var _this = this;
        this.router = router;
        this.authenticationService = authenticationService;
        this.userActivityService = userActivityService;
        this.authApiService = authApiService;
        Assert.isNotNull(router, 'router');
        Assert.isNotNull(authenticationService, 'authenticationService');
        Assert.isNotNull(userActivityService, 'userActivityService');
        Assert.isNotNull(authApiService, 'authApiService');
        if (this.authenticationService.isAuthenticated()) {
            this.userActivityService.start();
        }
        this.apiRequestStartedSubscription = this.authApiService.apiRequestStarted.subscribe(function (authenticationRequired) {
            if (authenticationRequired) {
                _this.checkAuthentication();
            }
        });
    }
    AuthenticationGuard.prototype.canActivate = function (route, state) {
        var isAuthenticated = this.checkAuthentication(state.url);
        return isAuthenticated;
    };
    AuthenticationGuard.prototype.canActivateChild = function (childRoute, state) {
        var isAuthenticated = this.checkAuthentication(state.url);
        return isAuthenticated;
    };
    AuthenticationGuard.prototype.checkAuthentication = function (url) {
        var isAuthenticated = this.authenticationService.isAuthenticated();
        if (isAuthenticated) {
            return true;
        }
        this.authenticationService.navigateToLogout(this.router, url);
        return false;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuthenticationGuard.prototype, "apiRequestStartedSubscription", void 0);
    return AuthenticationGuard;
}());
export { AuthenticationGuard };
