<slx-spinner [show]="isLoading">
  <div>
    <kendo-grid
      #kendoGridBackgroundCheck
      class="slx-no-wrap"
      scrollable="'scrollable'"
      [data]="gridState.view"
      (dataStateChange)="gridState.dataStateChange($event)"
      [sortable]="true"
      [sort]="gridState.state.sort"
      [pageable]="true"
      (edit)="gridState.editHandler($event)"
      (save)="gridState.saveHandler($event)"
      (cancel)="gridState.cancelHandler($event)"
      (remove)="gridState.removeHandler($event)"
      (pageChange)="pageChange($event)"
      [pageSize]="pageSize"
      [skip]="skip"
      [groupable]="false"
      [selectable]="false"
      (selectionChange)="gridState.selectionChange($event)"
      [slxKendoGridState]="gridState.gridDefinition"
      (stateRestored)="gridState.dataStateChange($event)"
      [filterable]="false"
      [filter]="gridState.state.filter"
    >
      <!-- Add background button  -->
      <ng-template kendoGridToolbarTemplate position="top">
        <div class="col-xs-12" *ngIf="canAddCheck && isEditable">
          <div class="row">
            <button class="theme-iconed-accent-button pull-right" (click)="onAddCheck()">
              <span class="icon-button-content">
                <span>Create New</span><i class="fa fa-plus" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
      </ng-template>

      <!-- background check button controlls -->
      <kendo-grid-column title="" [sortable]="false" [filterable]="false" width="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex">
            <button kendoGridEditCommand type="button" class="slx-button slx-only-icon slx-toolbar edit-icon-color" *ngIf="canEditCheck && isEditable" [disabled]="addMode">
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button kendoGridRemoveCommand type="button" class="slx-button slx-only-icon slx-toolbar del-icon-color" *ngIf="canDeleteCheck && isEditable" [disabled]="addMode">
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
            </button>
            <button kendoGridSaveCommand type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r" [disabled]="!enableSave">
              <i class="far fa-check-circle" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="slx-button slx-only-icon slx-toolbar">
              <i class="fa fa-ban" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <!-- subject -->
      <kendo-grid-column field="subject" [sortable]="true" title="Subject" width="150">
        <ng-template kendoGridHeaderTemplate>
          Subject
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.subject}}"
            >{{ dataItem.subject }}</span
          >
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <input
              slx-input
              required="true"
              [(ngModel)]="dataItem.subject"
              (ngModelChange)="onChangeValue(dataItem)"
              class="k-textbox"
              placeholder="Subject"
              slxMaxLength="50"
            />
            <span errorMessage for="required" *ngIf="!dataItem.subject"
              >Enter Subject</span
            >
            <span errorMessage for="slxMaxLength"
              >Max length '50'</span
            >
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- description  -->
      <kendo-grid-column
        field="description"
        title="Description"
        width="200"
        editable="false"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          Description
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.description}}"
            >{{ dataItem.description }}</span
          >
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <input
              slx-input
              required="true"
              [(ngModel)]="dataItem.description"
              (ngModelChange)="onChangeValue(dataItem)"
              class="k-textbox"
              placeholder="Description"
              slxMaxLength="255"
            />
            <span errorMessage for="required" *ngIf="!dataItem.degree"
              >Enter Description</span
            >
            <span errorMessage for="slxMaxLength"
              >Max length '255'</span
            >
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- Added On  -->
      <kendo-grid-column
        field="addedOn"
        title="Added On"
        width="110"
        editable="false"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          Added On
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.addedOn | amDateFormat: appConfig.dateFormat }}
        </ng-template>
      </kendo-grid-column>

      <!-- Added by  -->
      <kendo-grid-column [sortable]="true" field="addedBy" title="Added by" width="110">
        <ng-template kendoGridHeaderTemplate>
          Added by
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.addedBy}}"
            >{{ dataItem.addedBy }}</span
          >
        </ng-template>
      </kendo-grid-column>

      <!-- Last Modified On -->
      <kendo-grid-column
        field="lastModifiedOn"
        title="Last Modified On"
        width="130"
        editable="false"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          Last Modified On
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>
            {{ dataItem.lastModifiedOn | amDateFormat: appConfig.dateFormat }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <!-- Last Modified by  -->
      <kendo-grid-column
        field="lastModifiedBy"
        title="Last Modified by "
        width="130"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          Last Modified by
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.lastModifiedBy}}"
            >{{ dataItem.lastModifiedBy }}</span
          >
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</slx-spinner>
