import { Injectable, ElementRef, QueryList, Renderer2 } from '@angular/core';
import * as _ from 'lodash';

import * as domUtils from '../../utils/domUtils';
import { ITimelineItem } from '../../models/index';

export type TimelineComponentConfigiration = {
  eventsWrapper: ElementRef,
  fillingLineStart: ElementRef,
  fillingLineEnd: ElementRef,
  timelineEvents: QueryList<ElementRef>,
  renderer: Renderer2,
  eventsMinDistance: number;
};

export type TimelineComponentDrawConfigiration = {
  fitToWidth: boolean;
  startDate: Date;
  endDate: Date;
  eventsMinDistance?: number;
};

export abstract class TimelineHelper {
  public norm: number;
  public offset: number = 30;
  public elementWidth: number = 30;
  public eventsWrapperWidth: number = 0;
  public defaultTimelineWrapperWidth = 720;
  public parent: Element;
  public parentWidth: number;

  public prevLinkInactive: boolean = true;
  public nextLinkInactive: boolean = false;

  protected startDate: Date;
  protected endDate: Date;

  constructor(protected config: TimelineComponentConfigiration) {

  }

  public initTimeline(timeLines: ITimelineItem[], rtConfig: TimelineComponentDrawConfigiration): void {
    this.startDate = rtConfig.startDate;
    this.endDate = rtConfig.endDate;
    this.parent = domUtils.parentElementByClass(this.config.eventsWrapper.nativeElement, 'events-wrapper');
    if (this.parent) {
      this.parentWidth = this.getElementWidth(this.parent);
    } else {
      this.parentWidth = this.defaultTimelineWrapperWidth;
    }
    this.initTimelineImpl(timeLines, rtConfig);
  }
  public abstract updateSlide(timelineTotWidth: number, forward: boolean): void;
  public abstract updateFillingStartByDate(date: Date): void;
  public abstract updateFillingEndByDate(date: Date): void;

  public updateFillingAll(): void {
    this.updateFillingStartByDate(this.endDate);
  }
  public updateFilling(element: any): void {
    if (!element) {
      return;
    }
    let eventStyle = window.getComputedStyle(element);
    let eventLeft = eventStyle.getPropertyValue('left');
    let eventWidth = eventStyle.getPropertyValue('width');
    let eventLeftNum = this.pxToNumber(eventLeft) + this.pxToNumber(eventWidth) / 2;
    let scaleValue = eventLeftNum / this.eventsWrapperWidth;
    this.setTransformValue(this.config.fillingLineStart.nativeElement, 'scaleX', scaleValue);
  }

  protected abstract calcDistanceNorm(startDate: Date, endDate: Date): number;
  protected abstract initTimelineImpl(timeLines: ITimelineItem[], rtConfig: TimelineComponentDrawConfigiration): void;

  protected updateTimelinePosition(element: Element): void {
    if (!element) {
      return;
    }
    let eventStyle = window.getComputedStyle(element);
    let eventLeft = this.pxToNumber(eventStyle.getPropertyValue('left'));
    let translateValue = this.getTranslateValue(this.config.eventsWrapper.nativeElement);

    if (eventLeft > this.defaultTimelineWrapperWidth - translateValue) {
      this.translateTimeline(-eventLeft + this.defaultTimelineWrapperWidth / 2, this.defaultTimelineWrapperWidth - this.eventsWrapperWidth);
    }
  }

  protected translateTimeline(value: number, totWidth: number | null): void {
    // only negative translate value
    value = (value > 0) ? 0 : value;
    // do not translate more than timeline width
    value = (!(totWidth === null) && value < totWidth) ? totWidth : value;
    this.setTransformValue(this.config.eventsWrapper.nativeElement, 'translateX', value + 'px');
    // update navigation arrows visibility
    this.prevLinkInactive = value === 0;
    this.nextLinkInactive = value === totWidth;
  }

  protected setTransformValue(element: any, property: any, value: any): void {
    element.style['-webkit-transform'] = property + '(' + value + ')';
    element.style['-moz-transform'] = property + '(' + value + ')';
    element.style['-ms-transform'] = property + '(' + value + ')';
    element.style['-o-transform'] = property + '(' + value + ')';
    element.style['transform'] = property + '(' + value + ')';
  }

  protected getTranslateValue(timeline: Element): number {
    let timelineStyle = window.getComputedStyle(timeline);
    let timelineTranslate = timelineStyle.getPropertyValue('-webkit-transform') ||
      timelineStyle.getPropertyValue('-moz-transform') ||
      timelineStyle.getPropertyValue('-ms-transform') ||
      timelineStyle.getPropertyValue('-o-transform') ||
      timelineStyle.getPropertyValue('transform');

    let translateValue = 0;
    if (timelineTranslate.indexOf('(') >= 0) {
      let timelineTranslateStr = timelineTranslate
        .split('(')[1]
        .split(')')[0]
        .split(',')[4];
      translateValue = Number(timelineTranslateStr);
    }

    return translateValue;
  }

  protected minLapse(elements: ITimelineItem[]): number {
    if (elements && elements.length && elements.length === 1) {
      return 0;
    }
    const minLapseRelatedElements = _.filter(elements, (e: ITimelineItem) => e.minDistanceRelated);
    let result: number = 0;
    for (let i = 1; i < minLapseRelatedElements.length; i++) {
      let distance = this.dayDiff(minLapseRelatedElements[i - 1].date, minLapseRelatedElements[i].date);
      result = result ? Math.min(result, distance) : distance;
    }
    return result ? result : 60000;
  }

  protected pxToNumber(val: string): number {
    return Number(val.replace('px', ''));
  }

  protected getElementWidth(element: Element): number {
    const computedStyle = window.getComputedStyle(element);
    if (!computedStyle.width) {
      return 0;
    }
    return this.pxToNumber(computedStyle.width);
  }

  protected parentElement(element: any, tagName: string): any {
    if (!element || !element.parentNode) {
      return null;
    }

    let parent = element.parentNode;
    while (true) {
      if (parent.tagName.toLowerCase() === tagName) {
        return parent;
      }
      parent = parent.parentNode;
      if (!parent) {
        return null;
      }
    }
  }

  protected dayDiff(first: Date, second: Date): number {
    return Math.round(second.getTime() - first.getTime());
  }
}
