import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ShiftOvertime } from '../../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { ShiftDefinition } from '../../../../../organization/models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-scheduled-shift-overtime-section',
    templateUrl: 'scheduled-shift-overtime-section.component.html',
    styleUrls: [
        'scheduled-shift-overtime-section.component.scss'
    ]
})
export class ScheduledShiftOvertimeSectionComponent {

    @Input()
    public set overtime(value: ShiftOvertime) {
        this.m_overtime = value;
    }

    @Input()
    public shift: ShiftDefinition;

    @Input()
    public hasScheduleAbsence: boolean;

    @Input()
    public minDate: Date;

    @Input()
    public maxDate: Date;

    @Input()
    public startDate: Date;

    @Input()
    public endDate: Date;

    @Input()
    public fullTime: number;

    @Input()
    public index: number = -1;

    @Output()
    public onOvertimeDateChanged: EventEmitter<ShiftOvertime>;
    @Output()
    public onOvertimeHoursChanged: EventEmitter<ShiftOvertime>;

    public appConfig: IApplicationConfig;

    public get overtime(): ShiftOvertime {
        return this.m_overtime;
    }

    private m_overtime: ShiftOvertime;

    constructor() {
        this.appConfig = appConfig;
        this.onOvertimeDateChanged = new EventEmitter<ShiftOvertime>();
        this.onOvertimeHoursChanged = new EventEmitter<ShiftOvertime>();
    }

    public overtimeDateChanged(): void {
        this.onOvertimeDateChanged.next(this.m_overtime);
    }

    public overtimeHoursChanged(value: number): void {
        this.onOvertimeHoursChanged.next(this.m_overtime);
   }


}
