import { EmployeeIdList } from './aca-c1095-audit-record';
import { EmployeeFilterRecord } from '../aca-1095-c/index';

export interface IAcaC1095AuditState {
    acaC1095AuditStateList: AcaC1095AuditFilterState[];
}

export const initialAcaC1095AuditState: IAcaC1095AuditState = {
    acaC1095AuditStateList: []
};

export class AcaC1095AuditFilterState {
    public orgLevelId: number;
    public year: number;
    public employeeListFilter: EmployeeFilterRecord[];
    public employeeIdList: EmployeeIdList[]
    public pageNumber: number;
    public isExpandAll: boolean;
}


