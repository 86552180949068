<slx-spinner [show]="state.isLoading">
  <form novalidate class="slx-high-box">
    <div class="slx-content-toolbar-indents pbj-manual-entry">
      <div>
        <slx-daterange
          class="pbj-manual-entry___date-range"
          name="startEndDate"
          startDatePlaceholder="From"
          endDatePlaceholder="To"
          [disableFutureDates]="false"
          [startDate]="startDate"
          [endDate]="endDate"
          [maxSecRange]="maxDateRange"
          [maxRangeErrorText]="maxDateRangeError"
          [hasApplyButton]="true"
          (rangeDateChanged)="onDataRangeChange($event)"
        ></slx-daterange>

      </div>
      <div class="pbj-manual-entry__buttons">
        <button type="button"
          class="slx-button slx-with-icon slx-mobile-adapted slx-margin-r"
          [disabled]="!canRemove || !canAdd"
          (click)="onRemoveEntries()"
        >
          <i class="fas fa-trash-alt slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Remove</span>
        </button>
        <button type="button"
          class="slx-button slx-with-icon slx-mobile-adapted"
          [disabled]="!canAdd"
          (click)="onAddEntry()"
        >
          <i class="fas fa-plus-circle slx-button__icon slx-min-w90" aria-hidden="true"></i>
          <span class="slx-button__text">Add</span>
        </button>
      </div>
      <slx-action-list class="action-list">
        <slx-action-button [iconName]="'fas fa-cog'"
        [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
        <popper-content #popperContent>
          <slx-action-list-item (onClick)="onExportToExcel()" [disabled]="!canExportData">Export to Excel</slx-action-list-item>
          <slx-action-list-item (onClick)="onExportToPdf()">Export to PDF</slx-action-list-item>
        </popper-content>
      </slx-action-list>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <slx-pbj-manual-entry-grid #empDataGrid [pageSize]="pageSize"></slx-pbj-manual-entry-grid>
    </div>
  </form>
</slx-spinner>
