<div [ngClass]="{'field-container':true, 'table-record': true, 'alternate-row': rowIndex%2 == 0}">

    <div class="col actions-column" *ngIf="editable">

        <div class="slx-form-group slx-no-border small-font">
            <div class="slx-input-group">
                <div class="slx-form-control">
                    <input [attr.id]="'selectRow'+rowIndex" type="checkbox" [checked]="item.isSelected" (click)="onItemSelect()">
                    <label class="checkbox-label" [attr.for]="'selectRow'+rowIndex">
                                    <span class="check"></span>
                                    <span class="box"></span>
                                </label>
                </div>
            </div>
        </div>
    </div>

    <div class="col edit-column" *ngIf="editable">
        <button class="edit-button" (click)="onEditClick ()"></button>
    </div>

    <div class="col name-column md-full-line-column text-column">
        <span class="field-label">Name: </span> {{ item.name }}
    </div>
    <div class="col code-column md-full-line-column">
        <span class="field-label">Code: </span> {{ item.code }}
    </div>
    <div class="col count-column md-full-line-column numeric-column">
        <span class="field-label">Count as: </span> {{ item.countAs }}
    </div>
    <div class="col count-column md-full-line-column numeric-column">
        <span class="field-label">Used Count: </span> {{ item.usedCount }}
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Updated on: </span> {{ item.lastUpdateDate | amDateFormat: appConfig.dateFormat }}
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Updated by: </span> {{ item.lastUpdateUsername }}
    </div>

</div>