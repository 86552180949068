import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { KendoGridStateHelper, saveEvent, removeEvent, cancelEvent } from '../../../../../common/models/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../../common/services/index';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import {
  BenefitDetailsEditModes,
  BenefitDetailsTier,
  BenefitDetailsOption,
  BenefitDetailsCalcMethod,
  BenefitDetailsGroup
} from '../../../models/index';
import { BenefitDetailsStandartManagementService, BenefitDetailsPermissionService, BenefitDetailsManagementService } from '../../../services/index';

@Component({
  selector: 'slx-benefit-details-coverage-calc-401K',
  templateUrl: './benefit-details-coverage-calc-401K.component.html',
  styleUrls: ['./benefit-details-coverage-calc-401K.component.scss'],
  providers: [StateManagementService, ColumnManagementService]
})
export class BenefitDetailsCoverageCalc401KComponent implements OnInit, OnDestroy {
  public appConfig: IApplicationConfig;
  public records: BenefitDetailsOption[] = [];
  public gridState: KendoGridStateHelper<BenefitDetailsOption>;
  public canEdit: boolean;
  public pageSize = 50;
  public selectedProvidedTier: BenefitDetailsTier = null;
  public group: string;
  public min = 0;
  public max = 100;
  public step = 0.1;
  public percentage = 0.7;
  public format = 'p';
  public currencyFormat = 'c2';
  public minAmt: number = 1;
  public maxAmt: number = 99999999.99;
  public stepcurrency = 1;
  public value = 0;
  public isDelete: boolean = false;
  public isEditMode = false;

  public get isRowEditing(): boolean {
    return _.isObjectLike(this.gridState.editedRecord);
  }

  @ViewChild('kendoGrid', { static: true })
  public kendoGrid: GridComponent;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private modalService: ModalService,
    private manService: BenefitDetailsStandartManagementService,
    private permissionService: BenefitDetailsPermissionService,
  ) {
    this.gridState = new KendoGridStateHelper<BenefitDetailsOption>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.take = this.pageSize;
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.subscriptions.editMode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => {
        this.isEditMode = v.providerInfo;
        this.permissionService.toggleCanAddCovOptions(this.isEditMode);
        if (!this.isEditMode) {
          this.gridState.closeEditor(this.kendoGrid);
        }
      });

    this.subscriptions.gridEditSubscription = this.gridState.onEdit$
      .subscribe((option: BenefitDetailsOption) => {
        this.permissionService.toggleCanAddCovOptions(false);
      });

    this.subscriptions.gridCancelSubscription = this.gridState.onCancel$
      .subscribe((event: cancelEvent<BenefitDetailsOption>) => {
        this.permissionService.toggleCanAddCovOptions(true);
        if (_.isObjectLike(event.dataItem) && event.dataItem.isDraft) {
          this.clearRecords();
        }
      });

    this.subscriptions.gridSaveSubscription = this.gridState.onSave$
      .subscribe((event: saveEvent<BenefitDetailsOption>) => {
        this.permissionService.toggleCanAddCovOptions(true);
        if (event.dataItem.isDraft) {
          this.addOption(event.dataItem);
          return;
        }
        this.updateOption(event.dataItem);
      });

    this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$
      .subscribe((event: removeEvent<BenefitDetailsOption>) => {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog(
          'Delete Coverage Option',
          'Are you sure you want to remove selected coverage option? This will remove this plan for all enrolled employees',
          this.modalService,
          (isDelete: boolean) => {
            if (isDelete) {
              this.removeOption(event.dataItem);
              this.permissionService.toggleCanAddCovOptions(true);
            }
          },
          options);

      });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());

    this.subscriptions.selectProviderTier = this.manService
      .subscribeToSelectProviderTier((v: BenefitDetailsTier) => {
        this.selectedProvidedTier = v;
        this.gridState.closeEditor(this.kendoGrid);
        this.filterOutRecords();
        this.addDefaultOption();
        this.refreshGrid();
        this.permissionService.toggleCanAddCovOptions(true);
      });
  }

  public ngOnDestroy(): void {}

  public addDefaultOption(): void {
    if (_.size(this.records) === 0) {
      this.records.push(this.manService.createBenefitProviderOption());
    }
  }

  public addOption(option: BenefitDetailsOption): void {
    this.manService.saveCoverageOption(option, true);
    this.filterOutRecords();
    this.refreshGrid();
  }

  public updateOption(option: BenefitDetailsOption): void {
    this.manService.updateCoverageOption(option);
  }

  public removeOption(option: BenefitDetailsOption): void {
    this.manService.removeCoverageOption(option);
    this.filterOutRecords();
    this.addDefaultOption();
    this.refreshGrid();
  }

  public filterOutRecords(): void {
    this.records = _.filter(this.selectedProvidedTier.options, (o: BenefitDetailsOption) => !o.stateIsDeleted);
  }

  private clearRecords(): void {
    this.records.length = 0;
    this.addDefaultOption();
    this.refreshGrid();
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}
