/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../beneficiary-editor/beneficiary-editor.component.ngfactory";
import * as i2 from "../beneficiary-editor/beneficiary-editor.component";
import * as i3 from "../../../../../../common/services/modal/modal.service";
import * as i4 from "../../../../services/employee-sections-benefits-management/employee-sections-benefits-management-api.service";
import * as i5 from "./beneficiary-editor-popup.component";
import * as i6 from "../../../../../../common/models/dialog-options";
import * as i7 from "../../../../../../organization/services/lookup/lookup.service";
var styles_BeneficiaryEditorPopupComponent = [];
var RenderType_BeneficiaryEditorPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BeneficiaryEditorPopupComponent, data: {} });
export { RenderType_BeneficiaryEditorPopupComponent as RenderType_BeneficiaryEditorPopupComponent };
export function View_BeneficiaryEditorPopupComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { editor: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "slx-beneficiary-editor", [], null, null, null, i1.View_BeneficiaryEditorComponent_0, i1.RenderType_BeneficiaryEditorComponent)), i0.ɵdid(3, 245760, [[1, 4], ["editor", 4]], 0, i2.BeneficiaryEditorComponent, [i3.ModalService, i4.EmployeeSectionsBenefitsManagementApiService], { enrollmentId: [0, "enrollmentId"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save"])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.enrollmentId; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.canSave; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.canCancel; _ck(_v, 8, 0, currVal_2); }); }
export function View_BeneficiaryEditorPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-beneficiary-editor-popup", [], null, null, null, View_BeneficiaryEditorPopupComponent_0, RenderType_BeneficiaryEditorPopupComponent)), i0.ɵdid(1, 114688, null, 0, i5.BeneficiaryEditorPopupComponent, [i6.DialogOptions2, i3.ModalService, i7.LookupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BeneficiaryEditorPopupComponentNgFactory = i0.ɵccf("slx-beneficiary-editor-popup", i5.BeneficiaryEditorPopupComponent, View_BeneficiaryEditorPopupComponent_Host_0, {}, {}, []);
export { BeneficiaryEditorPopupComponentNgFactory as BeneficiaryEditorPopupComponentNgFactory };
