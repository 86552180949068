export interface IClientMessage {
  id: number;
  createdOn: string;
  title: string;
  text: string;
  level: string;
  clientSpecific: boolean;
}

export class ClientMessage {
  public id: number;
  public createdOn: Date;
  public header: string;
  public title: string;
  public text: string;
  public level: string;
  public clientSpecific: boolean;
  public messageHash: string;
}
