
    <form novalidate #insightsform="ngForm">
        <slx-collapsible-section id="slx-Collapse" title="Insights" [expanded]="true" mode="blue">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
            <ng-container body>
            <div class="aca-1095-c-insights-scroll">
              
                  <div class="aca-1095-c-insights-container">
                    <div class="card-container"  [class.selected]="selectedFilter=='All'"   (click)="applyInsightsFilter('All')">
                      <div class="card-box">
                        <div class="card-head">All Employees</div>
                      </div>
                      <div>
                        <div class="card-value">
                          <a>{{insights?.total}}</a>
                        </div>
                      </div>
                    </div>

                    <div class="card-container"  [class.selected]="selectedFilter=='FT_Enrolled_FullYear'" (click)="applyInsightsFilter('FT_Enrolled_FullYear')">
                      <div class="card-box">
                        <div class="card-head"  >FT + Enrolled Full Year</div>
                      </div>
                      <div>
                        <div class="card-value">
                          <a>{{insights?.fullTimeEnrolledFullYear}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="card-container"  [class.selected]="selectedFilter=='FT_Enrolled_PartYear'" (click)="applyInsightsFilter('FT_Enrolled_PartYear')">
                      <div class="card-box">
                        <div class="card-head" >FT + Enrolled Part Year</div>
                      </div>
                      <div>
                        <div class="card-value">
                          <a>{{insights?.fullTimeEnrolledPartYear}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="card-container" [class.selected]="selectedFilter=='FT_NotEnrolled'"  (click)="applyInsightsFilter('FT_NotEnrolled')">
                      <div class="card-box">
                        <div class="card-head">FT + Not Enrolled</div>
                      </div>
                      <div>
                        <div class="card-value">
                          <a>{{insights?.fullTimeNotEnrolled}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="card-container" [class.selected]="selectedFilter=='PT_Enrolled'"  (click)="applyInsightsFilter('PT_Enrolled')">
                      <div class="card-box">
                        <div class="card-head">PT + Enrolled</div>
                      </div>
                      <div>
                        <div class="card-value">
                          <a>{{insights?.partTimeEnrolled}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="card-container" [class.selected]="selectedFilter=='NotConfirmed'" (click)="applyInsightsFilter('NotConfirmed')">
                      <div class="card-box">
                        <div class="card-head" >Not Confirmed</div>
                      </div>
                      <div>
                        <div class="card-value">
                          <a>{{insights?.totalNotConfirmed}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </ng-container>
        </slx-collapsible-section>
    </form>

