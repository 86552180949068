import { Component, OnInit, Input } from '@angular/core';
import { TimecardSummaryModel } from '../../../models/index';
import { TimecardsSummaryManagementService } from '../../../services/index';

@Component({
    moduleId: module.id,
    selector: 'slx-timecard-summary-header',
    templateUrl: 'timecard-summary-header.component.html',
    styleUrls: ['timecard-summary-header.component.scss']
})

export class TimecardSummaryHeaderComponent implements OnInit {

    @Input()
    public summary: TimecardSummaryModel;

    constructor(public management: TimecardsSummaryManagementService) {
        //
    }

    public ngOnInit(): void {
        //
    }
}
