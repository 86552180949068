import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Assert } from '../../../../framework/index';
import { EmployeeTransfer, IEmployeeTransfer, IEmployeeTransferringResponse, EmployeeTransferringResponse } from '../../models/index';
import { LookupMapService } from '../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';

@Injectable()
export class EmployeeTransferMapService {

  private lookupMapService: LookupMapService;

  public mapToTransferEmployeeDto(data: EmployeeTransfer): IEmployeeTransfer {
    Assert.isNotNull(data, 'data');
    let dto: IEmployeeTransfer = {
      date: dateTimeUtils.convertToDtoUTC(data.date),
      organizationId: data.organization ? data.organization.id : null,
      departmentId: data.department ? data.department.id : null,
      positionId: data.position ? data.position.id : null,
      shiftId: data.shift ? data.shift.id : null,
      unitId: data.unit ? data.unit.id : null,
      payrollNumber: data.payrollNumber,
      benefitClassId: data.benefitClass ? data.benefitClass.id : null,
      benefitClassEffectiveDate: data.benefitClassEffectiveDate ? dateTimeUtils.convertToDtoString(data.benefitClassEffectiveDate) : null,
      oldOrganizationId: data.oldOrganizationId ? data.oldOrganizationId : null,
      employeeType: data.employeeType ? data.employeeType : null
    };
    return dto;
  }

  public mapToDtoTransferredEmployee(data: IEmployeeTransferringResponse): EmployeeTransferringResponse {
    Assert.isNotNull(data, 'data');

    return new EmployeeTransferringResponse(data.employeeId);
  }
}

