import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/filter';
import * as moment from 'moment';
import * as _ from 'lodash';
import { mutableSelect } from '../../../core/decorators/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { EmployeeSectionNavigationService } from '../../../employee/employee-sections/services/index';
import { ShiftReplacementRequest } from '../../models/index';
import { employeeListConfig } from '../../../employee/employee-list/employee-list.config';
import { ModalService } from '../../../common/services/modal/modal.service';
import { ShiftReplacementOpenComponent } from '../shift-replacement/shift-replacement-open/shift-replacement-open.component';
import { DetailScreenService, DetailScreenMapService, ShiftReplacementApiService } from '../../services/index';
import { Filter } from '../../models/index';
import { appConfig } from '../../../app.config';
var DetailScreenComponent = /** @class */ (function () {
    function DetailScreenComponent(detailScreenService, detailScreenMapService, modalService, shiftReplacementApiService, activatedRoute, router, dateOn, filters, groups) {
        this.detailScreenService = detailScreenService;
        this.detailScreenMapService = detailScreenMapService;
        this.shiftReplacementApiService = shiftReplacementApiService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.restrictedInfo = ['Position', 'Shift', 'Unit'];
        this.dateOn = dateOn || new Date();
        this.filters = filters || [];
        this.groups = groups || [{
                fieldName: 'Position'
            }, {
                fieldName: 'Shift'
            }, {
                fieldName: 'Unit'
            }];
        this.state = {
            isLoading: false
        };
        this.filtersAndGroupingChange$ = new Subject();
    }
    DetailScreenComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state.isLoading = true;
        var dateOnEvent$ = this.activatedRoute.params
            .map(function (_a) {
            var dateOn = _a.date;
            if (!dateOn) {
                _this.changeDateOn(new Date());
            }
            return moment(dateOn, appConfig.linkDateFormat).toDate();
        });
        this.dateSubscription = dateOnEvent$.subscribe(function (dateOn) {
            _this.dateOn = dateOn;
        });
        var orgLevelChangeEvent$ = this.orgLevel$
            .filter(function (selectedOrgLevel) { return selectedOrgLevel.type === OrgLevelType.department; });
        var detailsEvent$ = Observable.combineLatest(orgLevelChangeEvent$, dateOnEvent$, this.filtersAndGroupingChange$)
            .do(function () { return _this.state.isLoading = true; });
        this.detailsSubscription = detailsEvent$.subscribe(function (_a) {
            var selectedOrgLevel = _a[0], dateOn = _a[1];
            _this.loadDetails(selectedOrgLevel, dateOn)
                .then(function (d) { return _this.details = d; })
                .catch(function (error) { return console.log(error); })
                .then(function () { return _this.state.isLoading = false; });
        });
        this.filtersAndGroupingChange$.next();
    };
    DetailScreenComponent.prototype.loadDetails = function (orgLevel, dateOn) {
        var positionFilter = _.find(this.filters, function (filter) { return filter.fieldName === 'Position'; }) || new Filter();
        var shiftFilter = _.find(this.filters, function (filter) { return filter.fieldName === 'Shift'; }) || new Filter();
        var unitFilter = _.find(this.filters, function (filter) { return filter.fieldName === 'Unit'; }) || new Filter();
        var groupings = this.groups.map(function (group) { return group.fieldName; });
        return this.detailScreenService.getDetails(orgLevel, dateOn, positionFilter.value, shiftFilter.value, unitFilter.value, groupings);
    };
    DetailScreenComponent.prototype.ngOnDestroy = function () {
        this.detailsSubscription.unsubscribe();
        this.dateSubscription.unsubscribe();
    };
    DetailScreenComponent.prototype.getFieldValue = function (row, column) {
        var field = _.find(row.fields, function (f) { return f.name === column.name; });
        return field ? field.value : '';
    };
    DetailScreenComponent.prototype.filtersChanged = function (filters) {
        this.filters = filters;
        this.filtersAndGroupingChange$.next(filters);
    };
    DetailScreenComponent.prototype.groupsChanged = function (groups) {
        this.groups = groups;
        this.filtersAndGroupingChange$.next(groups);
    };
    DetailScreenComponent.prototype.employeeOpen = function (row) {
        var empId = _.find(row.fields, function (field) { return field.name === employeeListConfig.employeeIdentifierName; }).value;
        var navService = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
        navService.NavigateToEmployeeSections(+empId);
    };
    DetailScreenComponent.prototype.onOpenShift = function (group) {
        var _this = this;
        var shiftIdGrouping = _.find(group.grouping, function (grouping) { return grouping.name === 'ShiftId'; });
        var positionIdGrouping = _.find(group.grouping, function (grouping) { return grouping.name === 'PositionId'; });
        var unitIdGrouping = _.find(group.grouping, function (grouping) { return grouping.name === 'UnitId'; });
        if (!shiftIdGrouping || !positionIdGrouping || !unitIdGrouping) {
            return;
        }
        var request = new ShiftReplacementRequest();
        request.shiftId = +shiftIdGrouping.value;
        request.positionId = +positionIdGrouping.value;
        request.date = new Date();
        request.showDayOffEmployees = true;
        var replacementComponent = ShiftReplacementOpenComponent.openDialog(request, +unitIdGrouping.value, this.modalService, function (result, cmd) {
            if (result && cmd) {
                _this.shiftReplacementApiService.openShift(cmd)
                    .then(function (status) {
                    var employees = replacementComponent.selectedEmployees;
                    _.forEach(employees, function (employee) {
                        group.rows.push(_this.detailScreenMapService.mapShiftEligibleEmployeeToDetailRow(employee));
                    });
                });
            }
        });
    };
    DetailScreenComponent.prototype.changeDateOn = function (dateOn) {
        this.router.navigate([
            'details_screen',
            moment(dateOn).format(appConfig.linkDateFormat)
        ], {
            relativeTo: this.activatedRoute.parent
        });
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], DetailScreenComponent.prototype, "orgLevel$", void 0);
    return DetailScreenComponent;
}());
export { DetailScreenComponent };
