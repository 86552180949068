import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ServerCompositeFilterDescriptor, IFilteredItems, PagingData } from '../../../../../core/models/index';
import { ServerFilterService } from '../../../../../core/services/index';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { AuditTrailLimitedReq } from '../../../models/index';
import { AuditTrailApiService, AuditTrailMapService } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import * as moment from 'moment';
var AuditTrailLimitedComponent = /** @class */ (function () {
    function AuditTrailLimitedComponent(apiService, serverFilterService, orgLevelWatchService, auditTrailMapService) {
        this.serverFilterService = serverFilterService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.auditTrailMapService = auditTrailMapService;
        this.apiService = apiService;
        this.pagingData = { skip: 0, take: 50 };
        this.state = { isLoading: false };
    }
    AuditTrailLimitedComponent.prototype.ngOnChanges = function (changes) {
        this.composeFilterAndRefresh();
    };
    AuditTrailLimitedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filterChangeSubscription = this.serverFilterService.changes$.subscribe(function (filter) {
            _this.refreshData();
        });
    };
    AuditTrailLimitedComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    AuditTrailLimitedComponent.prototype.canFilter = function (field) {
        return true;
    };
    AuditTrailLimitedComponent.prototype.composeFilterAndRefresh = function () {
        if (this.req) {
            this.setFilters();
            this.refreshData();
        }
    };
    AuditTrailLimitedComponent.prototype.refreshData = function () {
        var _this = this;
        this.state.isLoading = true;
        this.apiService.getEmployeeActionsForEffectiveDate(this.serverFilterService.createQuery(this.pagingData), this.req.orgLevelId, false, moment(this.req.date).startOf('day').toDate())
            .then(function (val) {
            _this.state.isLoading = false;
            _this.actionList = val;
        }).catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    AuditTrailLimitedComponent.prototype.setFilters = function () {
        this.serverFilterService.composeFilter({ field: 'employeeId', operator: 'eq', value: this.req.employeeId });
        this.serverFilterService.composeFilter({ field: 'area', operator: 'eq', value: this.req.area });
    };
    AuditTrailLimitedComponent.prototype.gridPageChanged = function (pagingData) {
        this.pagingData = pagingData;
        this.refreshData();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuditTrailLimitedComponent.prototype, "filterChangeSubscription", void 0);
    return AuditTrailLimitedComponent;
}());
export { AuditTrailLimitedComponent };
