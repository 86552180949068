
  <div class="flex-horizontal row-panel">
    <button class="refresh-button pull-right" type="button">
      <span class="text-button-content" (click)="onRefreshClicked()">Refresh</span>
    </button>
  </div>
  <kendo-grid  [data]="gridView"
			   class="rate-history-grid"
               [sortable]="false"
               [sort]="sort" scrollable="'scrollable'"
               (sortChange)="sortChange($event)">
    <kendo-grid-column field="startDate.fieldValue" [locked]="employeeSectionsRateHistory?.isEnableIncreasedReason" [width]="130">
      <ng-template kendoGridHeaderTemplate>
        Start Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input name="startDate_{{ idx }}"
            [required]="false"
            [readonly]="true"
            [inputPolicy]="dataItem.startDate.Policy"
            [(ngModel)]="dataItem.startDate.fieldValue"
          ></slx-date-picker-ngx>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="endDate.fieldValue" [locked]="employeeSectionsRateHistory?.isEnableIncreasedReason" [width]="130" >
      <ng-template kendoGridHeaderTemplate>
        End Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input name="endDate_{{ idx }}"
            [required]="false"
            [readonly]="true"
            [inputPolicy]="dataItem.endDate.Policy"
            [(ngModel)]="dataItem.endDate.fieldValue"
          ></slx-date-picker-ngx>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="300" *ngIf="employeeSectionsRateHistory?.isEnableIncreasedReason">
      <ng-template kendoGridHeaderTemplate>
        Increase Reason
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        {{dataItem.incReason}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="120">
      <ng-template kendoGridHeaderTemplate>
        Rate
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-grid-cell">
          <slx-money-input slx-input [required]="false"  [decimalLimit]="setDecimalLimit" [requireDecimal]="false" [allowNegative]="false" [readonly]="true"
            name="hourlyRate{{idx}}" placeholder="Hourly Rate" min="0" slxMin="0" number [inputPolicy]="dataItem.rate.Policy" [(ngModel)]="dataItem.rate.fieldValue">
          </slx-money-input>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="120">
      <ng-template kendoGridHeaderTemplate>
        Rate 2
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-grid-cell">
          <slx-money-input slx-input [required]="false" [decimalLimit]="2" [requireDecimal]="false" [allowNegative]="false" [readonly]="true"
            name="hourlyRate{{idx}}" placeholder="Hourly Rate" min="0" slxMin="0" number [inputPolicy]="dataItem.rate2.Policy" [(ngModel)]="dataItem.rate2.fieldValue">
          </slx-money-input>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="120">
      <ng-template kendoGridHeaderTemplate>
        Rate 3
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-grid-cell">
          <slx-money-input slx-input [required]="false" [decimalLimit]="2" [requireDecimal]="false" [allowNegative]="false" [readonly]="true"
            name="hourlyRate{{idx}}" placeholder="Hourly Rate" min="0" slxMin="0" number [inputPolicy]="dataItem.rate3.Policy" [(ngModel)]="dataItem.rate3.fieldValue">
          </slx-money-input>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Pay Type
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-grid-cell">
          <input slx-input name="payType_{{ idx }}"
            [required]="false"
            [readonly]="true"
            [inputPolicy]="dataItem.payType.Policy"
            [(ngModel)]="dataItem.payType.fieldValue.name"
          />
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="130">
      <ng-template kendoGridHeaderTemplate>
        Standard Hours
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-grid-cell">
          <slx-kendo-number slx-input name="standardHours_{{ idx }}"
            [required]="false"
            [readonly]="true"
            [inputPolicy]="dataItem.standardHours.Policy"
            [(ngModel)]="dataItem.standardHours.fieldValue"
            [format]="'n2'"
            [decimals]="2"
          ></slx-kendo-number>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="130">
      <ng-template kendoGridHeaderTemplate>
        Benefit Hours
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-grid-cell">
          <slx-kendo-number slx-input name="benefitHours_{{ idx }}"
            [required]="false"
            [readonly]="true"
            [inputPolicy]="dataItem.benefitHours.Policy"
            [(ngModel)]="dataItem.benefitHours.fieldValue"
            [format]="'n2'"
            [decimals]="2"
          ></slx-kendo-number>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="130">
      <ng-template kendoGridHeaderTemplate>
        Exempt Status
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-grid-cell">
          <input slx-input name="exemptStatus_{{ idx }}"
            [required]="false"
            [readonly]="true"
            [inputPolicy]="dataItem.exemptStatus.Policy"
            [(ngModel)]="dataItem.exemptStatus.fieldValue.name"
          />
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
