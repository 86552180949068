import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { appConfig } from '../../../app.config';
import { DateRange } from '../../../core/models/index';
var DateRangeListComponent = /** @class */ (function () {
    function DateRangeListComponent() {
        this.format = 'MM/DD/YYYY';
        this.placeholder = '';
        this.readonly = false;
        this.valueChanged = new EventEmitter();
        this.appConfig = appConfig;
        this.m_selectedValue = null;
        this.separator = ' — ';
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
    }
    Object.defineProperty(DateRangeListComponent.prototype, "listRanges", {
        set: function (ranges) {
            if (_.isArray(ranges)) {
                this.prepareOptions(ranges);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateRangeListComponent.prototype, "selectedRange", {
        get: function () {
            return this.m_selectedValue;
        },
        set: function (v) {
            if (!_.isEqual(v, this.m_selectedValue)) {
                this.m_selectedValue = _.isNil(v) ? null : v;
                if (!this.readonly && !_.isNil(v)) {
                    this.onChangeCallback(this.getItem(v));
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    DateRangeListComponent.prototype.writeValue = function (v) {
        this.selectedRange = _.isNil(v) ? null : this.getOption(v);
    };
    DateRangeListComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    DateRangeListComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DateRangeListComponent.prototype.prepareOptions = function (ranges) {
        var _this = this;
        this.ranges = ranges;
        this.options = _.map(this.ranges, function (r) { return _this.getOption(r); });
    };
    DateRangeListComponent.prototype.getOption = function (v) {
        return {
            id: v.id,
            name: "" + moment(v.startDate).format(this.format) + this.separator + moment(v.endDate).format(this.format)
        };
    };
    DateRangeListComponent.prototype.getItem = function (v) {
        var item = _.find(this.ranges, function (r) { return r.id === v.id; });
        if (_.isObjectLike(item)) {
            return item;
        }
        return null;
    };
    return DateRangeListComponent;
}());
export { DateRangeListComponent };
