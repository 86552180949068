import { NgModel, NgControl } from '@angular/forms';
import { Directive, ElementRef, Renderer, HostListener, Input, Renderer2, SimpleChanges, OnChanges } from '@angular/core';
import { CustomDomEvents } from '../../models/index';
@Directive({
  selector: 'input [slxSetIfLessThen], slx-date-picker-ngx[slxSetIfLessThen]'
})
export class SetIfLessThenDirective  implements OnChanges {
    @Input()
    public slxSetIfLessThen: any;
    private ngControl: any;
    private ngControlHost: any;

    constructor(ngControl: NgControl) {
        this.ngControl = ngControl;
        this.ngControlHost = ngControl.valueAccessor;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['slxSetIfLessThen']) {
            if (this.slxSetIfLessThen && this.slxSetIfLessThen > this.ngControl.model) {
                let newValue = this.slxSetIfLessThen;
                if(newValue.getTime) {
                    newValue = new Date(this.slxSetIfLessThen.getTime());
                }
                this.ngControlHost.writeValue(newValue);
                this.ngControl.update.emit(newValue);
            }
        }
    }
}
