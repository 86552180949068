import { BenefitEligibilityRuleDataType } from './benefit-eligibility-rule-model';

export interface IBenefitEligibilityRuleConfigurationVariable {
  id: string;
  name: string;
  dataType: BenefitEligibilityRuleDataType;
  operators: number[];
  variableType: string;
}

export class BenefitEligibilityRuleConfigurationVariable {
  public readonly id: string;
  public readonly name: string;
  public readonly dataType: BenefitEligibilityRuleDataType;
  public readonly operatorIds: number[];
  public readonly variableType: string; // TODO to type
  public predicates: BenefitEligibilityRuleConfigurationPredicate[];

  constructor({
    id,
    name,
    dataType,
    operatorIds,
    variableType,
    predicates,
  }: Partial<BenefitEligibilityRuleConfigurationVariable>) {
    this.id = id;
    this.name = name;
    this.dataType = dataType;
    this.operatorIds = operatorIds;
    this.variableType = variableType;
    this.predicates = predicates;
  }
}

export class BenefitEligibilityRuleConfigurationPredicate {
  public readonly id: number;
  public readonly name: string;
  public readonly predicateEnum: string;

  constructor({ id, name, predicateEnum }: Partial<BenefitEligibilityRuleConfigurationPredicate>) {
    this.id = id;
    this.name = name;
    this.predicateEnum = predicateEnum;
  }
}

export interface IBenefitEligibilityRuleConfigurationData {
  variables: BenefitEligibilityRuleConfigurationVariable[];
  predicates: BenefitEligibilityRuleConfigurationPredicate[];
}

export class BenefitEligibilityRuleConfigurationData {
  public readonly variables: BenefitEligibilityRuleConfigurationVariable[];
  public readonly predicates: BenefitEligibilityRuleConfigurationPredicate[];

  constructor({ variables, predicates }: Partial<BenefitEligibilityRuleConfigurationData>) {
    this.variables = variables;
    this.predicates = predicates;
  }
}
