import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { appConfig, IApplicationConfig } from '../../../../../../app.config';

import { EmployeeWarningSections, EmployeeWarningExtended, EmployeeWarningSectionViolationsAB, EmployeeWarningViolation } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-warnings-violationsab',
  templateUrl: 'employee-sections-warnings-violationsab.component.html',
  styleUrls: ['employee-sections-warnings-violationsab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsWarningsViolationsABComponent implements OnInit, OnDestroy {
  public violations: EmployeeWarningSectionViolationsAB;
  public violationNumber: string;
  public isPartAType: boolean;
  public violationTypes: Array<{ id: number, name: string, isPartAType: boolean }>;
  public selectedViolationType: { id: number, name: string, isPartAType: boolean };
  public violationAbsence: string;
  public violationTardiness: string;
  public violationDate: Date;

  @unsubscribeAll()
  public subscriptions: StringMap<Subscription> = {};
  @ViewChild('form', { static: true })
  public form: NgForm;
  public appConfig: IApplicationConfig = appConfig;

  constructor(
    private readonly manService: EmployeeSectionWarningsManagementService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.violationTypes = _.map(['Part A violation', 'Part B violation'], (t, i) => ({id: i, name: t, isPartAType: i === 0}));
  }

  public ngOnInit(): void {
    this.violations = this.manService.getViolationsABSection();

    this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(warning => {
      this.assignViolations(warning);
      this.assignViolationsData();
      this.updateView();
    });

    this.subscriptions.statusChanges = this.form.statusChanges.subscribe(() => {
      this.manService.changeSectionValidity(EmployeeWarningSections.CustomViolationsAB, this.form.valid);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onChangeViolationNumber(): void {
    this.violations.data.violationNumber = this.violationNumber;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeViolationType(): void {
    this.violations.data.isPartAType = this.selectedViolationType.isPartAType;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeViolationAbsence(): void {
    this.violations.data.violationAbsence = this.violationAbsence;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeViolationTardiness(): void {
    this.violations.data.violationTardiness = this.violationTardiness;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeViolationDate(): void {
    this.violations.data.violationDate = this.violationDate;
    this.manService.markWarningAsEdited(true);
  }

  private updateView(): void {
    Observable.of(true)
      .delay(200)
      .subscribe(() => this.changeDetector.detectChanges());
  }

  private assignViolations(warning: EmployeeWarningExtended): void {
    const violations = _.find(warning.sections, s => s.isCustomViolationsAB);
    if (_.isObjectLike(violations) && _.isObjectLike(violations.data)) {
      this.violations = violations as EmployeeWarningSectionViolationsAB;
    }
  }

  private assignViolationsData(): void {
    this.violationNumber = this.violations.data.violationNumber;
    this.isPartAType = this.violations.data.isPartAType;
    this.violationAbsence = this.violations.data.violationAbsence;
    this.violationTardiness = this.violations.data.violationTardiness;
    this.violationDate = this.violations.data.violationDate;

    this.selectedViolationType = _.find(this.violationTypes, t => this.isPartAType === t.isPartAType);
  }
}
