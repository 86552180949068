var PayType = /** @class */ (function () {
    function PayType() {
    }
    Object.defineProperty(PayType.prototype, "isSalaryPayType", {
        get: function () {
            return this.name === 'Salaried';
        },
        enumerable: true,
        configurable: true
    });
    return PayType;
}());
export { PayType };
