/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../node_modules/@progress/kendo-angular-dateinputs/dist/es2015/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@progress/kendo-angular-dateinputs";
import * as i5 from "@progress/kendo-angular-common";
import * as i6 from "@progress/kendo-angular-l10n";
import * as i7 from "@progress/kendo-angular-popup";
import * as i8 from "@progress/kendo-angular-intl";
import * as i9 from "./timepicker.component";
var styles_TimepickerInputComponent = [];
var RenderType_TimepickerInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimepickerInputComponent, data: {} });
export { RenderType_TimepickerInputComponent as RenderType_TimepickerInputComponent };
function View_TimepickerInputComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [["class", "slx-timepicker__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ":"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
export function View_TimepickerInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "slx-timepicker"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimepickerInputComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 7, "kendo-timepicker", [["name", "dateInput"]], [[8, "className", 0], [2, "k-widget", null], [2, "k-timepicker", null], [2, "k-header", null], [2, "k-state-disabled", null]], [[null, "blur"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.onBlur() !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = (_co.onChangeValue($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TimePickerComponent_0, i2.RenderType_TimePickerComponent)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.TimePickerComponent]), i0.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.TimePickerComponent]), i0.ɵprd(6144, null, i5.KendoInput, null, [i4.TimePickerComponent]), i0.ɵprd(256, null, i6.L10N_PREFIX, "kendo.timepicker", []), i0.ɵprd(131584, null, i6.LocalizationService, i6.LocalizationService, [i6.L10N_PREFIX, [2, i6.MessageService], [2, i6.RTL]]), i0.ɵprd(512, null, i4.PickerService, i4.PickerService, []), i0.ɵdid(10, 770048, null, 0, i4.TimePickerComponent, [i0.NgZone, i6.LocalizationService, i0.ChangeDetectorRef, i7.PopupService, i0.ElementRef, i0.Renderer2, i0.Injector, i4.PickerService, i8.IntlService, [2, i4.TOUCH_ENABLED]], { disabled: [0, "disabled"], format: [1, "format"], placeholder: [2, "placeholder"], min: [3, "min"], max: [4, "max"], value: [5, "value"] }, { valueChange: "valueChange", onBlur: "blur" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasPlaceholder; _ck(_v, 2, 0, currVal_0); var currVal_6 = _co.readonly; var currVal_7 = _co.format; var currVal_8 = (_co.hasPlaceholder ? _co.placeholder : ""); var currVal_9 = _co.minTime; var currVal_10 = _co.maxTime; var currVal_11 = _co.value; _ck(_v, 10, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "slx-timepicker__kendo ", _co.cssClass, ""); var currVal_2 = i0.ɵnov(_v, 10).wrapperClasses; var currVal_3 = i0.ɵnov(_v, 10).wrapperClasses; var currVal_4 = i0.ɵnov(_v, 10).wrapperClasses; var currVal_5 = i0.ɵnov(_v, 10).disabledClass; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
export function View_TimepickerInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "slx-timepicker", [], [[2, "slx-watermark", null]], null, null, View_TimepickerInputComponent_0, RenderType_TimepickerInputComponent)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.TimepickerInputComponent]), i0.ɵdid(2, 49152, null, 0, i9.TimepickerInputComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).watermarkClass; _ck(_v, 0, 0, currVal_0); }); }
var TimepickerInputComponentNgFactory = i0.ɵccf("slx-timepicker", i9.TimepickerInputComponent, View_TimepickerInputComponent_Host_0, { minTime: "minTime", maxTime: "maxTime", readonly: "readonly", placeholder: "placeholder", format: "format", cssClass: "cssClass", watermarkStyleUnlessTouched: "watermarkStyleUnlessTouched" }, {}, []);
export { TimepickerInputComponentNgFactory as TimepickerInputComponentNgFactory };
