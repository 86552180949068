import { IdealScheduleAcuity } from './ideal-schedule-acuity';
var IdealSchedulePositionRange = /** @class */ (function () {
    function IdealSchedulePositionRange() {
        this.id = -1;
        this.acuity = new IdealScheduleAcuity();
        this.parLevels = {};
        this.counters = [];
    }
    Object.defineProperty(IdealSchedulePositionRange.prototype, "name", {
        get: function () {
            var acuityName = this.acuity.acuity ? this.acuity.acuity.name : 'Default';
            return "Range - " + acuityName + " (" + (this.acuity.minValue || 0) + " - " + (this.acuity.maxValue || 0) + ")";
        },
        enumerable: true,
        configurable: true
    });
    return IdealSchedulePositionRange;
}());
export { IdealSchedulePositionRange };
