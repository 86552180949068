import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AcaMonthRecord, acaQuickPopupState } from '../../../models/aca-1095-c';
import { AcaOfferOfCoverage, AcaSafeHarbor, AcaReason } from '../../../../organization/models/index';
import { Aca1095cManagementService } from '../../../services/aca-1095-c/aca-1095-c-management.service';
import { unsubscribeAll } from '../../../../core/decorators/index';
var QuickEdit1095CPopup = /** @class */ (function () {
    function QuickEdit1095CPopup(acaManagementService) {
        this.acaManagementService = acaManagementService;
        this.currencyFormat = 'c2';
        this.minAmt = 0.0;
        this.maxAmt = 99999999.99;
        this.stepcurrency = 0.1;
        this.showChanges = false;
        this.isQuickEditStarted = false;
        this.isAllFieldsPopulated = false;
        this.subscriptions = {};
    }
    Object.defineProperty(QuickEdit1095CPopup.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuickEdit1095CPopup.prototype, "hasChanges", {
        get: function () {
            return this.items && this.items.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    QuickEdit1095CPopup.prototype.markChanges = function () {
        if (this.items && this.items.length > 0) {
            this.acaManagementService.markAsDirty();
        }
        else {
            this.acaManagementService.clearChanges();
        }
    };
    QuickEdit1095CPopup.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onMarkItemForQickEdit = this.acaManagementService.subscribeToQuickEditRecords(function (items) {
            _this.items = items;
            _this.markChanges();
        });
        if (this.popupInitData) {
            this.selectedCoverage = new AcaOfferOfCoverage();
            this.selectedReason = new AcaReason();
            this.selectedHarbor = new AcaSafeHarbor();
            if (this.popupInitData.selectedCode || this.popupInitData.selectedHarbor || this.popupInitData.selectedCost) {
                this.selectedCoverage.id = this.popupInitData.selectedCode;
                this.selectedCoverage.name = this.popupInitData.selectedCode;
                this.selectedHarbor.id = this.popupInitData.selectedHarbor;
                this.selectedHarbor.name = this.popupInitData.selectedHarbor;
                this.selectedCost = this.popupInitData.selectedCost;
            }
            else {
                this.selectedCoverage.id = this.popupInitData.suggestedCode;
                this.selectedCoverage.name = this.popupInitData.suggestedCode;
                this.selectedHarbor.id = this.popupInitData.suggestedHarbor;
                this.selectedHarbor.name = this.popupInitData.suggestedHarbor;
                this.selectedCost = this.popupInitData.suggestedCost;
            }
            this.selectedReason.id = this.popupInitData.reason;
            this.selectedReason.name = this.popupInitData.reason;
            this.isEnrolled = this.popupInitData.enrolledInd;
        }
    };
    QuickEdit1095CPopup.prototype.configurationChanged = function () {
        if (this.selectedReason && this.selectedCoverage && this.selectedHarbor && !_.isEmpty(this.selectedCoverage.id)) {
            this.quickEditSelectedData = new AcaMonthRecord();
            this.isAllFieldsPopulated = true;
            this.quickEditSelectedData.selectedHarbor = this.selectedHarbor.id;
            this.quickEditSelectedData.selectedCost = this.selectedCost;
            this.quickEditSelectedData.selectedCode = this.selectedCoverage.id;
            this.quickEditSelectedData.reason = this.selectedReason.id;
            this.quickEditSelectedData.enrolledInd = this.isEnrolled;
            this.acaManagementService.setQuickEditSelectedData(this.quickEditSelectedData);
        }
        else {
            this.isAllFieldsPopulated = false;
        }
    };
    QuickEdit1095CPopup.prototype.undoEdit = function (item, monthItem) {
        var undoItem;
        if (monthItem && item.months.length > 1) {
            undoItem = _.clone(item);
            undoItem.months = [monthItem];
        }
        else {
            undoItem = _.clone(item);
        }
        if (undoItem) {
            this.acaManagementService.setUndoQuickRecord(undoItem);
        }
    };
    QuickEdit1095CPopup.prototype.showChangesClick = function () {
        this.showChanges = true;
    };
    QuickEdit1095CPopup.prototype.onChangeEnrolled = function (event) {
        this.configurationChanged();
    };
    QuickEdit1095CPopup.prototype.onChangeContribution = function () {
        this.configurationChanged();
    };
    QuickEdit1095CPopup.prototype.onChangeHarbor = function (event) {
        this.configurationChanged();
    };
    QuickEdit1095CPopup.prototype.onChangeReason = function (event) {
        this.configurationChanged();
    };
    QuickEdit1095CPopup.prototype.onChangeCoverage = function (event) {
        this.configurationChanged();
    };
    QuickEdit1095CPopup.prototype.hideChangesClick = function () {
        this.showChanges = false;
    };
    QuickEdit1095CPopup.prototype.startQuickEdit = function () {
        if (!this.isQuickEditStarted) {
            this.isQuickEditStarted = true;
            this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditStart);
        }
    };
    QuickEdit1095CPopup.prototype.saveQuickEdit = function () {
        if (this.isQuickEditStarted) {
            this.isQuickEditStarted = false;
            this.items = [];
            this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditSave);
            this.acaManagementService.clearChanges();
        }
    };
    QuickEdit1095CPopup.prototype.ngOnDestroy = function () {
        // Must be, see #issueWithAOTCompiler
    };
    QuickEdit1095CPopup.prototype.setExpandedItem = function (item) {
        if (_.isEqual(this.expandedItem, item)) {
            this.expandedItem = null;
        }
        else {
            this.expandedItem = item;
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], QuickEdit1095CPopup.prototype, "subscriptions", void 0);
    return QuickEdit1095CPopup;
}());
export { QuickEdit1095CPopup };
