import { Component, Output, Inject, EventEmitter, OnInit, Provider, ViewChild } from '@angular/core';
import { ColumnManagementService } from './../../../../common/services/column-settings/column-management.service';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { Employee, SmsResponse, EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { employeeListConfig } from '../../employee-list.config';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN } from '../../../../core/models/index';
import { commonConfig } from '../../../../common/common.config';
import { IDialog, DialogOptions2, DialogModeSize } from '../../../../common/index';
import { EmployeeGridComponent } from '../employee-grid/employee-grid.component';
import { NotificationsService } from '../../../../core/components';
import { IconAccess } from '../../../../portal/models';
import { NotificationsApiService } from '../../../../portal/services';

@Component({
  moduleId: module.id,
  selector: 'slx-group-sms',
  templateUrl: 'group-sms.component.html',
  styleUrls: ['group-sms.component.scss'],
  providers: [ColumnManagementService]
})
export class GroupSmsComponent implements OnInit, IDialog {
  @ViewChild(EmployeeGridComponent, { static: true })
  private employeeGrid: EmployeeGridComponent;

  @Output()
  public onComplete: EventEmitter<string>;

  public actionEmployees: Employee[];

  public employees: Employee[];
  public selectedEmployees: Employee[];
  public fieldDefinition: EmployeeActionDefinition;
  public orgLevel: OrgLevel;
  public messageCount: number = 0;
  private options: DialogOptions2;

  public smsText: string = '';
  public maxSmsCharactersLength: number = 255;
  public state: {
    isLoading: boolean;
    isActiveOnly: boolean;
  };
  public canSend: boolean = false;
  private groupActivitiesService: GroupActivitiesApiService;
  private modalService: ModalService;

  constructor(groupActivitiesService: GroupActivitiesApiService, @Inject(EMPLOYEES_ID_TOKEN) employees: Employee[], fieldDefinition: EmployeeActionDefinition,
    orgLevel: OrgLevel,
    modalService: ModalService,
    private notificationsService: NotificationsService, options: DialogOptions2, private notificationApiService: NotificationsApiService) {
    this.groupActivitiesService = groupActivitiesService;
    this.employees = employees;
    this.fieldDefinition = fieldDefinition;
    this.orgLevel = orgLevel;
    this.modalService = modalService;
    this.options = options;
    this.onComplete = new EventEmitter<string>();
    this.state = {
      isLoading: false,
      isActiveOnly: true
    };
    this.checkMessageCenterAccess();
  }
  dialogResult: boolean;

  public ngOnInit(): void {
    let employeeIds: string[] = _.map(this.employees, (employee: Employee) => employee[employeeListConfig.employeeIdentifierName]);
    this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, this.state.isActiveOnly, employeeIds, this.fieldDefinition).then((actionEmployees: Employee[]) => {
      _.map(actionEmployees, (employee: Employee) => { employee['Selectable'] = (Boolean(employee['CellPhoneNo']) && employee['CellPhoneNo'].match(commonConfig.phonePattern) !== null) });
      this.actionEmployees = actionEmployees;
      this.validateEmployee(actionEmployees);

    });
  }

  public validateEmployee(actionEmployees: Employee[]): void {
    const selectedEmployees: Employee[] = _.filter(actionEmployees, (employee: Employee) => {
      if (employee.CellPhoneNo && _.trim(employee.CellPhoneNo) != '' &&  _.trim(employee.CellPhoneNo) !=  '(___) ___-____') {
        if (employee.OptIn == 'Not Responded to the Opt In Request' || employee.OptIn == 'Opted Out') {
          employee.Selectable = false;
          employee.Tooltip = '';
        } else {
          employee.Selectable = true;
          employee.isSelected = false;
        }
      } else {
        employee.Selectable = false;
        employee.isSelected = false;
        employee.Tooltip = `Employee doesn't have mobile number`;
      }
      return employee;
    });
    this.actionEmployees = this.getEmployeeMessage(selectedEmployees);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public employeesSelect(selectedEmployees: Employee[]): void {
    this.selectedEmployees = selectedEmployees;
    this.canSend = this.selectedEmployees && this.selectedEmployees.length > 0;
  }

  public sendSms(): void {
    this.state.isLoading = true;
    this.groupActivitiesService.sendSms(this.smsText, this.selectedEmployees, this.orgLevel.id)
      .then(() => {
        this.state.isLoading = false;
        this.canSend = false;
        this.notificationsService.success('SMS message sent', 'SMS message was sent successfully');
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
      })
      .catch(() => {
        this.state.isLoading = false;
        this.canSend = false;
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
      });
  }

  private getEmployeeMessage(actionEmployees: Employee[]): Employee[] {
    if (!actionEmployees) {
      return;
    }
    const selectableEmployees: number[] = _.map(_.filter(actionEmployees, ['Selectable', true]), (employee: number) => employee['EmpId']);
    
    this.groupActivitiesService.getEmployeeMessages(selectableEmployees)
      .then((result: any) => {
        _.each(actionEmployees, (item: any) => {
          var filterResult = _.filter(result, (data: any) => {
            return item['EmpId'] == data.employeeId
          });
          if (filterResult && filterResult.length>0) {
            if (filterResult[0].smsDate) {
              item.OptInDate = filterResult[0].smsDate;
            }
            else {
              item.OptInDate = null;
            }
          }
          else {
            item.OptInDate = null;
          }
        });
      })
      .catch(() => {
      });

    return actionEmployees;
  }

  public static openDialog(
    modalService: ModalService, actionDefinition: Provider[],
    callback: (result: boolean) => void
  ): GroupSmsComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.height = 400;
    dialogOptions.minHegiht = 500;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions,

      }
    ];
    
    resolvedProviders = [...resolvedProviders, ...actionDefinition];
    let dialog: GroupSmsComponent = modalService.globalAnchor.openDialog2(
      GroupSmsComponent,
      'Send SMS to a Group of Employees',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
      }
    );
    return dialog;
  }

  public async checkMessageCenterAccess(): Promise<void> {
    const access: IconAccess = await this.notificationApiService.getIconAccessCheck(this.orgLevel.id);
    if (access.isMessageCenterEnabled) {
      this.maxSmsCharactersLength = 320;
    }
    else {
      this.maxSmsCharactersLength = 255;
    }
  }
}
