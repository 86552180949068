import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsPolicyAssignments = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPolicyAssignments, _super);
    function EmployeeSectionsPolicyAssignments() {
        var _this = _super.call(this) || this;
        _this.records = [];
        return _this;
    }
    return EmployeeSectionsPolicyAssignments;
}(EmployeeSectionsBase));
export { EmployeeSectionsPolicyAssignments };
var EmployeePolicyAssignment = /** @class */ (function () {
    function EmployeePolicyAssignment() {
    }
    return EmployeePolicyAssignment;
}());
export { EmployeePolicyAssignment };
var EmployeePolicyAssignmentDTO = /** @class */ (function () {
    function EmployeePolicyAssignmentDTO() {
    }
    return EmployeePolicyAssignmentDTO;
}());
export { EmployeePolicyAssignmentDTO };
