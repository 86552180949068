import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MidnightToMidnightReport } from './../../../../../app/reports/models';
import { NgForm } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'slx-midnight-to-midnight-report',
  templateUrl: './midnight-to-midnight-report.component.html',
  styleUrls: ['./midnight-to-midnight-report.component.scss']
})
export class MidnightToMidnightReportComponent implements OnInit {

  @Input()
  public gridData: MidnightToMidnightReport[];

  @Output()
  public hasValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public modifiedIndex: EventEmitter<[string[], MidnightToMidnightReport[]]> = new EventEmitter<[string[], MidnightToMidnightReport[]]>();

  public minValue: number = 0;
  public maxValue: number = 100;
  public modifiedData: string[] = [];

  private stream: Subject<any> = new Subject<any>();
  private subscription: Subscription;



  constructor() {
    this.subscription = this.stream
      .pipe(debounceTime(500))
      .subscribe((value: string) => { 
        this.hasValueChange.emit(true);
        this.modifiedData.push(value); 
        this.modifiedIndex.emit([this.modifiedData, this.gridData]);
      });
   }

  ngOnInit() {
  }

  @HostListener('valueChange', [ '$event' ])
  public onValueChange(value: any, id: string): void {
    this.stream.next(id);
  }

  getModifiedData(dataItem): MidnightToMidnightReport[] {
    return null;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
