import { ArrivalsDeparturesDetailsRecord, IArrivalsDeparturesDetailsRecord } from './arrivals-departures-details-record';
import { FieldData } from '../../../core/models/index';

export interface IArrivalsDeparturesDetails {
  records: IArrivalsDeparturesDetailsRecord[];
}

export class ArrivalsDeparturesDetails {
  public records: ArrivalsDeparturesDetailsRecord[];
  public filteredRecords: ArrivalsDeparturesDetailsRecord[];
  public empPhoneMeta: FieldData<string>;
  public cellPhoneNoMeta: FieldData<string>;
}
