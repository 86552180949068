/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-decorator.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../error-messages/error-messages.component";
import * as i4 from "../../error-filters/errors";
import * as i5 from "../../error-filters/pending";
import * as i6 from "./input-decorator.component";
var styles_InputDecoratorComponent = [i0.styles];
var RenderType_InputDecoratorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_InputDecoratorComponent, data: {} });
export { RenderType_InputDecoratorComponent as RenderType_InputDecoratorComponent };
function View_InputDecoratorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "slx-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["** Hidden **"]))], null, null); }
function View_InputDecoratorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "slx-masked"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputPolicy.rawValue; _ck(_v, 1, 0, currVal_0); }); }
function View_InputDecoratorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputDecoratorComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputDecoratorComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inputPolicy.isVisible; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.inputPolicy.isMasked; _ck(_v, 4, 0, currVal_1); }, null); }
function View_InputDecoratorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "slx-pending"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-clock"]], null, null, null, null, null))], null, null); }
function View_InputDecoratorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "slx-error-block"], ["error-messages", ""]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "slx-readonly": 0 }), i1.ɵdid(4, 1064960, null, 0, i3.ErrorMessagesDirective, [], { messages: [0, "messages"], errors: [1, "errors"] }, null), i1.ɵpid(0, i4.Errors, []), i1.ɵncd(null, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-error-block"; var currVal_1 = _ck(_v, 3, 0, ((_co.fieldChild == null) ? null : _co.fieldChild.readonly)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.errorMessages; var currVal_3 = i1.ɵunv(_v, 4, 1, i1.ɵnov(_v, 5).transform(((_co.fieldChild == null) ? null : _co.fieldChild.ngControl))); _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_InputDecoratorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [["class", "slx-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "slx-input-group kendo kendo-common kendo-default"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputDecoratorComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), i1.ɵncd(null, 1), i1.ɵncd(null, 2), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_InputDecoratorComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(0, i5.Pending, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputDecoratorComponent_5)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.decoratorClass; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.hideFieldChild; _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.fieldChild == null) ? null : _co.fieldChild.ngControl) && i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(((_co.fieldChild == null) ? null : _co.fieldChild.ngControl)))); _ck(_v, 12, 0, currVal_3); var currVal_4 = (!_co.hideFieldChild && ((_co.fieldChild == null) ? null : _co.fieldChild.ngControl)); _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.titlename; _ck(_v, 4, 0, currVal_1); }); }
export function View_InputDecoratorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-input-decorator", [], null, null, null, View_InputDecoratorComponent_0, RenderType_InputDecoratorComponent)), i1.ɵdid(1, 311296, null, 4, i6.InputDecoratorComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵqud(603979776, 1, { fieldChild1: 0 }), i1.ɵqud(603979776, 2, { fieldChild2: 0 }), i1.ɵqud(603979776, 3, { inputPolicyDirective: 0 }), i1.ɵqud(603979776, 4, { errorMessages: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputDecoratorComponentNgFactory = i1.ɵccf("slx-input-decorator", i6.InputDecoratorComponent, View_InputDecoratorComponent_Host_0, { className: "className" }, {}, ["*", "[slx-input-suffix]", "[slx-input-hint]", "[errorMessage]"]);
export { InputDecoratorComponentNgFactory as InputDecoratorComponentNgFactory };
