<div class="selector-element">
  <slx-dropdown-list class="slx-wide" [lookup]="shiftLookup" name="shiftSelector" placeholder="Shift:" [(ngModel)]="shiftTemplate.shift"
  (ngModelChange)="shiftChanged()">
  </slx-dropdown-list>
</div>
<div class="selector-element">
  <slx-dropdown-list class="slx-wide" [readonly]="isAbsenceSelected" [lookup]="unitLookup" [defaultItem]="{ id: 0, name: '' }" name="unitSelector" placeholder="Unit:" [(ngModel)]="shiftTemplate.unit"
  (ngModelChange)="unitChanged()">
  </slx-dropdown-list>
</div>
<div class="selector-element">
  <slx-dropdown-list class="slx-wide" [readonly]="isAbsenceSelected" [lookup]="constraintLookup" [defaultItem]="{ id: 0, name: '' }" name="constraintSelector" placeholder="Constraint:" [(ngModel)]="shiftTemplate.constraint"
  (ngModelChange)="constraintChanged()">
  </slx-dropdown-list>
</div>
<div class="selector-element absence" [ngClass]="{'absence-active': isAbsenceSelected}">
  <slx-dropdown-list class="slx-wide" [lookup]="scheduleAbsenceLookup" [defaultItem]="{ code: 0, description: '' }" name="absenseSelector" placeholder="Absence:" [(ngModel)]="shiftTemplate.absence"
  (ngModelChange)="absenceChanged()">
  </slx-dropdown-list>
  <button *ngIf="isAbsenceSelected" (click)="absenceRemove()" class="remove-absence-button">
    <i class="remove-absence fas fa-trash-alt" aria-hidden="true"></i>
  </button>

</div>
