import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { BenefitEnrolledEmployeesRequest } from '../../models/index';
import { ModalService } from '../../../../common/services/index';
import { BenefitEnrolledEmployeesDialogComponent } from '../../components/benefit-employees/benefit-employees-enrolled-dialog/benefit-employees-enrolled-dialog.component';

@Directive({
  selector: '[slxBenefitEnrolledEmployeesModal]',
})
export class BenefitEnrolledEmployeesModalDirective {
  constructor(private modalService: ModalService) {
    this.refreshEmployees = new EventEmitter<boolean>();
  }
  @Input()
  public slxBenefitEnrolledEmployeesModal: BenefitEnrolledEmployeesRequest;
  @Output()
  public refreshEmployees: EventEmitter<any>;

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    BenefitEnrolledEmployeesDialogComponent.openDialog(this.slxBenefitEnrolledEmployeesModal, this.modalService, (result: boolean, cmd: any) => {
      this.refreshEmployees.emit(true);
    });
  }
}
