<slx-spinner [show]="isLoading" novalidate>
    <kendo-grid [data]="gridData"
    [filter]="filter"
    [filterable]="'menu'"
    (filterChange)="filterChange($event)"
    class="slx-full-height">
      <ng-template kendoGridToolbarTemplate position="top">
        <form novalidate>
            <div class="hbox">
                <div class="vbox col-sm-6">
                    <div class="form-item">
                        <label for="msg-text">Message (48 characters)</label>
                    </div>
                    <div class="message-container">
                        <textarea name="msg-text" class="message-field" [(ngModel)]="messageText" [maxlength]="48" required></textarea>
                    </div>
                    <div>
                        This message will remain at clock until it has been read by the employee, or a new one is sent in its place.
                    </div>
                </div>
                <div class="hbox col-sm-6 left-align">
                    <div>
                        <button type="button" class="btn action-button" (click)="send()" [disabled]="!canSend">Send</button>
                    </div>
                </div>
            </div>
        </form>

      </ng-template>
        <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" field="isSelected" [locked]="false"
                width="40px">
            <ng-template kendoGridHeaderTemplate>
                <input type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected()">
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <input type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange()">
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Name" field="name">
            <ng-template kendoGridHeaderTemplate>
                Name
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.name}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Department" field="departmentName">
            <ng-template kendoGridHeaderTemplate>
                Department
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.departmentName}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Position" field="positionName">
            <ng-template kendoGridHeaderTemplate>
                Position
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.positionName}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Badge Id" field="badgeId">
            <ng-template kendoGridHeaderTemplate>
                Badge Id
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.badgeId}}
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</slx-spinner>
