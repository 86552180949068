import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../app.config';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { PbjReconciliationManagementService } from '../../services/index';
var PbjReconciliationComponent = /** @class */ (function () {
    function PbjReconciliationComponent(managementService, router, route, cdr, orgLevelWatchService) {
        this.router = router;
        this.route = route;
        this.cdr = cdr;
        this.orgLevelWatchService = orgLevelWatchService;
        this.appConfig = appConfig;
        this.managementService = managementService;
        this.currentEmpId = 0;
        this.isLoading = true;
    }
    PbjReconciliationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.managementService.init(this.router, this.route, this.expanded = true);
        this.loadStatusSubscription = this.managementService.onLoadStatus$.subscribe(function (state) {
            _this.isLoading = state;
            _this.cdr.detectChanges();
        });
        this.orgLevelSubscription = this.managementService.subscribeToOrgLevel(function (orgLevel) {
            _this.orgLevel = orgLevel;
            var test = _this.hasOrglevel;
            if (_this.isDepartment) {
                _this.findParentOrgLevel();
            }
            _this.cdr.detectChanges();
        });
    };
    PbjReconciliationComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.managementService.destroy();
    };
    Object.defineProperty(PbjReconciliationComponent.prototype, "hasOrglevel", {
        get: function () {
            return _.isFinite(_.get(this.orgLevel, 'id'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjReconciliationComponent.prototype, "isDepartment", {
        get: function () {
            return _.get(this.orgLevel, 'type') === OrgLevelType.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjReconciliationComponent.prototype, "isOrganization", {
        get: function () {
            return _.get(this.orgLevel, 'type') === OrgLevelType.organization;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjReconciliationComponent.prototype, "isAboveOrganization", {
        get: function () {
            return this.hasOrglevel && !this.isDepartment && !this.isOrganization;
        },
        enumerable: true,
        configurable: true
    });
    PbjReconciliationComponent.prototype.findParentOrgLevel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orgLevel;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.orgLevelWatchService.getOrgLevelByIdSafe(this.orgLevel.parentId)];
                    case 1:
                        orgLevel = _a.sent();
                        this.parentOrganizationName = _.get(orgLevel, 'name');
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PbjReconciliationManagementService)
    ], PbjReconciliationComponent.prototype, "managementService", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationComponent.prototype, "loadStatusSubscription", void 0);
    return PbjReconciliationComponent;
}());
export { PbjReconciliationComponent };
