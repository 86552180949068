import { OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ReportParameter } from '../../../models/index';
import { OrgLevel } from '../../../../state-model/models/index';
var EmployeeListParameterComponent = /** @class */ (function () {
    function EmployeeListParameterComponent() {
        this.includeThousandsSeparator = false;
        this.isActive = true;
    }
    Object.defineProperty(EmployeeListParameterComponent.prototype, "parameter", {
        get: function () {
            return this.m_parameter;
        },
        set: function (value) {
            this.m_parameter = value;
            this.processParameter(this.m_parameter);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListParameterComponent.prototype, "hasDefaultValue", {
        get: function () {
            return !_.isNull(this.parameter.defaultValue) && !_.isUndefined(this.parameter.defaultValue) && this.parameter.defaultValue !== '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListParameterComponent.prototype, "lookupValue", {
        get: function () {
            return this.m_lookupValue;
        },
        set: function (value) {
            if (this.m_parameter) {
                this.m_parameter.value = value;
            }
            this.m_lookupValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListParameterComponent.prototype, "lookupName", {
        get: function () {
            if (this.parameter && this.parameter.dataType)
                return this.parameter.dataType.lookupName;
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeListParameterComponent.prototype.ngOnInit = function () {
    };
    EmployeeListParameterComponent.prototype.processParameter = function (parameter) {
        parameter.value = parameter.defaultValue;
        this.createRequest();
    };
    EmployeeListParameterComponent.prototype.createRequest = function () {
        if (!this.lookupName) {
            return;
        }
        this.lookupRequest = {
            lookupType: this.lookupName,
            orgLevelId: this.orgLevel.id,
            isActive: this.isActive
        };
    };
    return EmployeeListParameterComponent;
}());
export { EmployeeListParameterComponent };
