import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../app.config';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { LookupApiService } from '../../../../organization/services/index';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
import { TimeclocksApiService, AccessManagementService } from '../../../services/index';
import { Timeclock, EditableListActionKind, TimeclockContainer } from '../../../models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';
var TimeclockRendererComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TimeclockRendererComponent, _super);
    function TimeclockRendererComponent(timeclocksApiService, lookupService, appSettingsManageService, stateManagement) {
        var _this = _super.call(this) || this;
        _this.editable = true;
        _this.selectable = true;
        _this.enableQR = false;
        _this.editTimeclockConfig = true;
        _this.isCollapsed = true;
        _this.eIdErrorMessage = false;
        _this.lookupService = lookupService;
        _this.timeclocksApiService = timeclocksApiService;
        _this.m_stateManagement = stateManagement;
        _this.appSettingsManageService = appSettingsManageService;
        return _this;
    }
    Object.defineProperty(TimeclockRendererComponent.prototype, "context", {
        set: function (value) {
            this.item = value.item;
            this.rowIndex = value.rowIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockRendererComponent.prototype, "appConfig", {
        get: function () {
            return this.m_appConfig;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockRendererComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockRendererComponent.prototype, "stateManagement", {
        get: function () {
            return this.m_stateManagement;
        },
        enumerable: true,
        configurable: true
    });
    TimeclockRendererComponent.prototype.onToggleCollapseClick = function () {
        this.isCollapsed = !this.isCollapsed;
    };
    TimeclockRendererComponent.prototype.ngOnDestroy = function () {
    };
    TimeclockRendererComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.m_appConfig = appConfig;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
        });
        this.getTimeclockModelData();
        this.getEnterpriseId();
    };
    TimeclockRendererComponent.prototype.onEditClick = function () {
        this.actionEmitter.emit(EditableListActionKind.START_EDIT);
    };
    TimeclockRendererComponent.prototype.onItemSelect = function () {
        this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
    };
    TimeclockRendererComponent.prototype.getTimeclockModelData = function () {
        var _this = this;
        var orgLevelId = this.item.organization.orgLevelId;
        this.lookupService.getTimeclockModelDefinitions(orgLevelId)
            .then(function (value) {
            _this.clockModelLookup = value;
        });
    };
    TimeclockRendererComponent.prototype.getTimeClockContainerData = function () {
        var _this = this;
        this.timeclocksApiService.getTimeclocks(this.currentOrgLevel.id).
            then(function (container) {
            _this.m_container = container;
            _this.m_stateManagement.actions = container.actions;
        }).catch(function (e) {
        });
    };
    TimeclockRendererComponent.prototype.getEnterpriseId = function () {
        var _this = this;
        this.appSettingsManageService.getAppServerConfig()
            .then(function (conf) {
            _this.eId = conf.enterpriseId;
        });
    };
    TimeclockRendererComponent.prototype.onClockModelSelected = function (event) {
        if (!this.eId) {
            this.item.isQRPunchEnabled = false;
            this.enableQR = false;
        }
        else {
            this.enableQR = event.qrPunchSupported;
        }
    };
    TimeclockRendererComponent.prototype.onEditMode = function (item) {
        if (_.isNull(this.eId)) {
            this.eIdErrorMessage = true;
        }
        this.originalItem = _.cloneDeep(item);
        item.isEditable = true;
        if (this.isCollapsed) {
            this.onToggleCollapseClick();
        }
    };
    TimeclockRendererComponent.prototype.onSave = function (item) {
        var _this = this;
        item.isEditable = false;
        this.timeclocksApiService.saveTimeclock(item, this.currentOrgLevel.id).then(function () {
            _this.originalItem = _this.item;
            _this.enableQR = false;
        }).catch(function () {
        });
        if (!this.isCollapsed) {
            this.onToggleCollapseClick();
        }
    };
    TimeclockRendererComponent.prototype.onCancel = function (item) {
        item.model = this.originalItem.model;
        item.isEditable = false;
        this.enableQR = false;
        this.eIdErrorMessage = false;
        if (!this.isCollapsed) {
            this.onToggleCollapseClick();
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], TimeclockRendererComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockRendererComponent.prototype, "orgLevelSubscription", void 0);
    return TimeclockRendererComponent;
}(CustomListActorBase));
export { TimeclockRendererComponent };
