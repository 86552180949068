<div class="wc-toolbar">
  <slx-toolbar class="wc-toolbar__helper" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate alignMode="left" [width]="getRangeWidth()">
      <span class="wc-toolbar__control" *ngIf="!currentView?.isComparisons">
        <slx-daterange
          class="slx-blue-btn wc-toolbar__range"
          name="startEndDate"
          [hasApplyButton]="isDesktop()"
          [disableFutureDates]="true"
          [startDate]="dateRange.startDate"
          [endDate]="dateRange.endDate"
          (rangeDateChanged)="onChangeDates($event)"
        ></slx-daterange>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate alignMode="left" width="300">
      <span class="wc-toolbar__control ytd-span-host as-flex-end ytd-flex_width" *ngIf="currentView?.isComparisons">
        <slx-dropdown-multiselect slx-input
        [options]="ytdList" 
        [readonly]="false" 
        [(ngModel)]="ytdListDefaultVal" 
        name="ytd" 
        (ngModelChange)="onYTDChange($event)"
        placeholder="YTD"
       ></slx-dropdown-multiselect>
    </span>
 </ng-template>
    
 <ng-template slxToolbarSectionTemplate alignMode="left" [width]="getBtnWidth()">
      <span class="wc-toolbar__control as-flex-start mt-15">
        <button type="button" *ngIf="canCreateNewIncident"
          class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"
          (click)="onClickCreate()"
          [disabled]="!isActiveBtn || !toolbarActionsAllowed"
        >
          <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
          <span class="slx-button__text">Create New Incident</span>
        </button>
      </span>
    </ng-template>
  
   <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" [width]="getIncidentWidth()">
      <span class="wc-toolbar__control" [ngClass]="{ 'collapsed': isCollapsed }" *ngIf="currentView?.isRepeatInjuryList">
        <slx-dropdown-list
          class="slx-wide slx-tiny wc-toolbar__incidents"
          [options]="incidentsList"
          [(ngModel)]="currentIncidentAmount"
          (ngModelChange)="onChangeIncidentsAmount()"
          [readonly]="!toolbarActionsAllowed"
          placeholder="# Incidents"
          name="incidentsAmount"
        ></slx-dropdown-list>
      </span>
    </ng-template>



    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="245">
      <span class="wc-toolbar__control" [ngClass]="{ 'collapsed': isCollapsed }">
        <slx-dropdown-list
          class="slx-wide wc-toolbar__views"
          [options]="viewsList"
          [(ngModel)]="currentView"
          (ngModelChange)="onChangeView()"
          [listHeight]="listHeight"
          [readonly]="!toolbarActionsAllowed"
          placeholder="View"
          name="viewsList"
        ></slx-dropdown-list>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="35">
      <span class="wc-toolbar__control as-flex-start mt-16" *ngIf="!isCollapsed">
        <button type="button"
          class="slx-button slx-only-icon slx-toolbar"
          [popper]="columnsToggler"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-start'"
          [popperDisableStyle]="true"
        >
          <i aria-hidden="true" class="fa fa-cog"></i>
        </button>
        <popper-content #columnsToggler class="slx-popper">
          <span class="wc-toolbar__popper-title">Columns to Display</span>
          <div class="wc-toolbar__popper-body">
            <div *ngFor="let column of state?.columns; let idx=index;">
              <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                <slx-checkbox-input slx-input
                  [caption]="column.description"
                  [(ngModel)]="column.displayed"
                  (ngModelChange)="onChangeColumn()"
                  name="empColumn{{idx}}"
                  fieldName="empColumn{{idx}}"
                ></slx-checkbox-input>
              </slx-input-decorator>
            </div>
          </div>
          <div class="wc-toolbar__popper-footer">
            <button class="slx-button slx-margin-r" type="button" (click)="onClickToggleSelection(true)">Select All </button>
            <button class="slx-button" type="button" (click)="onClickToggleSelection(false)"> Clear All</button>
          </div>
        </popper-content>
      </span>
    </ng-template>


    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="95">
      <span class="wc-toolbar__control as-flex-start mt-16" *ngIf="!isCollapsed">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item 
              [disabled]="IsGenerateActionEnabled" 
              *ngIf="currentView.isCreateOshaRkForms" 
              (onClick)="generateOSHAForm(generateOshaFormAction.generateOSHAForm300ACSV)"
              [title]="getTitleValue()"
            >Generate OSHA 300/300A CSV</slx-action-list-item>
            <slx-action-list-item [disabled]="!toolbarActionsAllowed || !generatePDF300AAllowed" *ngIf="currentView.isCreateOshaRkForms" [title]="generatePDF300AAllowed? '' : 'Please Select a Facility'" (onClick)="generateOSHAForm(generateOshaFormAction.generateOSHAForm300APDF)">Generate OSHA Form 300/300A PDF</slx-action-list-item>
            <slx-action-list-item [disabled]="!toolbarActionsAllowed" *ngIf="currentView.isCreateOshaRkForms" (onClick)="generateOSHAForm(generateOshaFormAction.generateOSHAForm301APDF)">Generate OSHA Form 301 PDF</slx-action-list-item>
            <slx-action-list-item [disabled]="!toolbarActionsAllowed" (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>
            <slx-action-list-item [disabled]="!toolbarActionsAllowed" (onClick)="onClickExport(true)">Export to PDF</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </span>
    </ng-template>


  </slx-toolbar>
</div>
