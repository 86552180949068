var EmpTimecardPayUnit = /** @class */ (function () {
    function EmpTimecardPayUnit() {
    }
    Object.defineProperty(EmpTimecardPayUnit.prototype, "lockDay", {
        get: function () {
            return this.isApproved || this.isNonActive || this.isLocked;
        },
        enumerable: true,
        configurable: true
    });
    return EmpTimecardPayUnit;
}());
export { EmpTimecardPayUnit };
