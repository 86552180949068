import { Directive, ElementRef, Input, Renderer2, EventEmitter, Output, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[elementSelector]',
})
export class ElementSelectorDirective implements AfterViewInit {
  @Input()
  public elementSelector: string;
  public selectedElement: Element;
  public renderer: Renderer2;

  private elementRef: ElementRef;

  constructor(elementRef: ElementRef, renderer: Renderer2) {
    this.elementRef = elementRef;
    this.renderer = renderer;
  }

  public ngAfterViewInit(): void {
    let el: HTMLElement = this.elementRef.nativeElement;
    this.selectedElement = el.querySelector(this.elementSelector);
  }
}

@Directive({
  selector: '[elementSelector2]',
})
export class ElementSelectorSecondDirective implements AfterViewInit {
  @Input()
  public elementSelector2: string;
  public selectedElement: Element;
  public renderer: Renderer2;

  private elementRef: ElementRef;


  constructor(elementRef: ElementRef, renderer: Renderer2) {
    this.elementRef = elementRef;
    this.renderer = renderer;
  }

  public ngAfterViewInit(): void {
    let el: HTMLElement = this.elementRef.nativeElement;
    this.selectedElement = el.querySelector(this.elementSelector2);
  }
}

