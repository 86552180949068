import { PayrollExportTestStateStatuses, PayrollExportTestStateStatusLabels } from './payroll-export-test-state-statuses';
import { AfterTestActionCodes } from './after-test-action-codes';
export interface IPayrollExportTestState {
    id: number;
    description: string;
    testId: number;
    runById: number;
    runByUser: string;
    runAt: string;
    completedAt: string;
    summary: string;
    status: number;
    afterTestActionCode: number;
}

export class PayrollExportTestState {
    public id: number;
    public description: string;
    public testId: number;
    public runById: number;
    public runByUser: string;
    public runAt: Date;
    public completedAt: Date;
    public summary: string;
    public status: PayrollExportTestStateStatuses;
    public afterTestActionCode: AfterTestActionCodes;
    public statusLabel: PayrollExportTestStateStatusLabels;
}
