import { AttachmentDefinition } from './attachment-definition';
var FileModel = /** @class */ (function () {
    function FileModel(sourceItem, currentUserName) {
        if (currentUserName === void 0) { currentUserName = null; }
        this.sourceItem = sourceItem;
        this.isAttachment = this.sourceItem instanceof AttachmentDefinition;
        this.currentUserName = currentUserName;
    }
    Object.defineProperty(FileModel.prototype, "id", {
        get: function () {
            return this.isAttachment ? this.sourceItem.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileModel.prototype, "name", {
        get: function () {
            return this.sourceItem.fileName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileModel.prototype, "addedBy", {
        get: function () {
            return this.isAttachment ? this.sourceItem.addedBy : this.currentUserName;
        },
        enumerable: true,
        configurable: true
    });
    return FileModel;
}());
export { FileModel };
