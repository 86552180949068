import { Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { timeAndAttendanceConfig } from '../../../time-and-attendance.config';
import { KendoGridStateHelper, saveEvent } from '../../../../common/models/index';
import { SupervisorGroupDefinition } from '../../../../organization/models/index';
import { SupervisorGroup, SupervisorGroupsContainer, Supervisor, SupervisedEmployee, SupervisorAssignmentAction } from '../../../models/index';
import { SupervisorAssignmentManagementService, supervisorsGroup } from '../../../services/supervisor-assignment/supervisor-assignment-management.service';
import { StateManagementService } from '../../../../common/index';
import { unsubscribe } from '../../../../core/decorators/index';

const formGroup = (dataItem: SupervisorGroup) => new FormGroup({
  'name': new FormControl(dataItem.name, Validators.required),
  'supervisors': new FormControl(dataItem.supervisors),
  'employees': new FormControl(dataItem.employees),
  'orgLevel': new FormControl(dataItem.orgLevel)
});

@Component({
  moduleId: module.id,
  selector: 'slx-supervisor-group-grid',
  templateUrl: 'supervisor-group-grid.component.html',
  styleUrls: ['supervisor-group-grid.component.scss'],
  providers:[StateManagementService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupervisorGroupGridComponent implements OnInit, OnDestroy {

  public get isEditorValid (): boolean {
    if (this.addGroupMode || this.editGroupMode) {
      return this.selectedFormGroup ? this.selectedFormGroup.valid : false;
    }
    return true;
  }

  public appConfig: IApplicationConfig;
  public records: SupervisorGroup[];
  public selected: SupervisorGroup;
  public container: SupervisorGroupsContainer;
  public gridState: KendoGridStateHelper<SupervisorGroup>;
  @ViewChild('groupForm', {static: true})
  public form: NgForm;
  public addGroupMode: boolean;
  public editGroupMode: boolean;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private startAddGroupCmdSubscription: Subscription;
  @unsubscribe()
  private addedGroupCmdSubscription: Subscription;
  @unsubscribe()
  private startEditGroupCmdSubscription: Subscription;
  @unsubscribe()
  private deletedGroupCmdSubscription: Subscription;
  @unsubscribe()
  private actionCmdSubscription: Subscription;
  @ViewChild('kendoSupervisorGroupGrid', {static: true})
  private grid: GridComponent;

  private selectedRowIndex: number;
  private selectedFormGroup: FormGroup;

  constructor(private managementService: SupervisorAssignmentManagementService, private changeDetector: ChangeDetectorRef, private stateManagement: StateManagementService) {
    this.gridState = new KendoGridStateHelper<SupervisorGroup>();
  }

  public ngOnInit(): void {
    this.stateManagement.init('SupervisorGroupGridComponent');
    this.appConfig = appConfig;
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (container: SupervisorGroupsContainer) => {
        this.container = container;
        this.records = container.groups;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.stateManagement.loadedData({});
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.startEditGroupCmdSubscription = this.managementService.startEditGroupCmd$.subscribe(
      (value: any) => {
        this.onEditGroup();
        this.addGroupMode = false;
        this.editGroupMode = true;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.startAddGroupCmdSubscription = this.managementService.startAddGroupCmd$.subscribe(
      (value: any) => {
        this.onAddGroup();
        this.addGroupMode = true;
        this.editGroupMode = false;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.addedGroupCmdSubscription = this.managementService.addedGroupCmd$.subscribe(
      (addedGroup: SupervisorGroup) => {
        this.records.unshift(addedGroup);
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.deletedGroupCmdSubscription = this.managementService.deletedGroupCmd$.subscribe(
      (deletedGroup: SupervisorGroup) => {
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.actionCmdSubscription = this.managementService.action$.subscribe(
      (action: SupervisorAssignmentAction) => {
        if (action === SupervisorAssignmentAction.cancel) {
          this.addGroupMode = false;
          this.editGroupMode = false;
          this.cancel();
        }
        if (action === SupervisorAssignmentAction.saveSupervisedGroup) {
          if (this.addGroupMode) {
            this.addGroupMode = false;
            this.addGroup();
          } else {
            this.editGroupMode = false;
            this.saveGroup();
          }
        }
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public selectionChange(group: SupervisorGroup, rowIndex: number): void {
    this.selectedRowIndex = rowIndex;
    let selectedRecords: SupervisorGroup[] = [];
    _.forEach(this.records, (record: SupervisorGroup) => {
      if (record.id !== group.id) {
        record.isSelected = false;
      }
    });
    this.selected = group.isSelected ? group : null;
    if (group.isSelected) {
      selectedRecords.push(group);
    }
    this.managementService.selectedGroups(selectedRecords);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  private onAddGroup(): void {
    this.gridState.closeEditor(this.grid);
    this.selectedFormGroup = formGroup({
      id: 0,
      name: '',
      employees: [],
      supervisors: [],
      orgLevel: this.managementService.currentOrgLevel,
      isSelected: true
    });
    this.grid.addRow(this.selectedFormGroup);
  }

  private addGroup(): void {
    let group: SupervisorGroup = this.selectedFormGroup.value;
    this.managementService.addGroupCmd(group);
    this.gridState.closeEditor(this.grid);
  }

  private onEditGroup(): void {
    this.selectedFormGroup = formGroup(this.selected);
    this.gridState.editHandlerReactive({ sender: this.grid, dataItem: this.selected, rowIndex: this.selectedRowIndex }, this.selectedFormGroup);
  }

  private saveGroup(): void {
    let group: SupervisorGroup = this.selectedFormGroup.value;
    this.selected.name = group.name;
    this.managementService.editGroupCmd(this.selected);
    this.gridState.closeEditor(this.grid);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private cancel(): void {
    this.gridState.cancelHandler({ sender: this.grid, rowIndex: this.selectedRowIndex });
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }
}

