import { Component, OnInit, OnDestroy } from "@angular/core";
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ACAPeriodType, PeriodsPopupModel, ACAPeriod } from '../../../models';
import * as _ from 'lodash';
import { AcaMeasurementManagementService } from '../../../services/aca-measurement/aca-measurement-management.service';
import { EmployeeIdList } from '../../../models/aca-measurement/aca-measurement-state';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../../core/decorators';

@Component({
  selector: 'aca-measurement-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss'],
})

export class ProgressbarComponent implements ICellRendererAngularComp, OnDestroy {
  public periodTypes: ACAPeriodType[] = [];
  public isExpand: boolean = false;
  public isTodayBarShow: boolean = true;
  public isTodayShow: boolean = false;
  public todayBarWidth: number;
  public params: any;
  private selectedYear: number;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private acaManagementService: AcaMeasurementManagementService
  ) { }

  public agInit(params: any): void {
    this.params = params;
    this.periodTypes = params.data.periodTypes;
    this.isExpand = this.params.data.expand;
  }

  public ngOnInit() {

    this.subscriptions.state = this.acaManagementService.subscribeToCurrentYear((year: number) => {
      this.selectedYear = year;

      this.setTodayBar();
    });
  }

  public ngOnDestroy(): void { }

  public refresh(): boolean {
    return false;
  }

  public onExpandCollapse(state: boolean): void {
    this.setColumnState(state);
    this.setEmployeeIdList(state);
  }

  private setEmployeeIdList(state: boolean): void {
    let employeeIdModel: EmployeeIdList = new EmployeeIdList();
    employeeIdModel.employeeId = this.params.data.empId;
    employeeIdModel.expand = state;
    this.acaManagementService.setEmployeeIdList(employeeIdModel);
  }

  private setColumnState(state: boolean): void {
    this.isExpand = state;
    this.params.data.expand = state;
    if (this.params.api) {
      this.params.api.resetRowHeights();
    }
  }

  public togglePopper(item: ACAPeriod, val: boolean, event): void {
    let periods: PeriodsPopupModel = new PeriodsPopupModel();
    periods.isVisible = val;
    let boxHeight = item.periodSortName === 'AP' ? 55 : item.periodSortName === 'SP' ? 65 : 115;
    let boxWidth = 200;
    periods.posLeft = (event.view.outerWidth - event.screenX > boxWidth) ? event.pageX + 5 : event.pageX - boxWidth;
    periods.posTop = (event.view.outerHeight - event.screenY > boxHeight) ? event.pageY + 5 : event.pageY - boxHeight;
    periods.acaPeriod = item;
    this.acaManagementService.togglePopper(periods);
  }

  private setTodayBar(): void {
    let today: Date = moment().startOf('day').toDate();
    let currentYear = today.getFullYear();
    if (currentYear == this.selectedYear) {
      this.isTodayShow = this.params.rowIndex == 0 ? true : false;
      this.isTodayBarShow = true;
      let daysInYear = moment(currentYear + "-02", "YYYY-MM").daysInMonth() == 29 ? 366 : 365;
      this.todayBarWidth = (Math.round((100 / daysInYear) * ((moment().startOf('day').dayOfYear()))));
    }
    else {
      this.isTodayShow = false;
      this.isTodayBarShow = false;
    }
  }

}
