import { Injectable } from '@angular/core';
import { IServerValidatorAdapter, IServerValidationResult } from '../../../common/validators/common-validators-models';
import { UserProfileApiService } from './user-profile-api.service';

@Injectable()
export class UserValidatorAdapter implements IServerValidatorAdapter {

    public static readonly loginValidation: string = 'loginValidation';

    constructor(private userApiService: UserProfileApiService) { }

    public validate(validationName: string, value: any, ...params: any[]): Promise<IServerValidationResult> {
        let promise: Promise<IServerValidationResult>;
        let defPromise: Promise<IServerValidationResult> = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
        switch (validationName) {
            case UserValidatorAdapter.loginValidation:
                promise = this.userApiService.validateUserName(value);
                break;
            default:
                promise = defPromise;
        }
        return promise;
    }

}
