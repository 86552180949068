<div class="report-body" *ngIf="report">

    <slx-wc-incident-report-header class="body-item report-header"></slx-wc-incident-report-header>

    <div class="body-item report-dates">

        <div class="flex align-items__center dates-section-ctrl">
            <label class="dates-section-label">Incident Date:</label>
            <span class="flex align-items__center date-block">
                <slx-datetimepicker [(ngModel)]="report.incidentDateTime"
                    #incidentDatetime="ngModel"
                    [minDateTime]="minDate"
                    [slxMinDate]="minDate"
                    [maxDateTime]="maxDate"
                    [slxMaxDate]="maxDate"
                    [readonly]="incidentDateTimeReadonly"
                    [required]="true"
                    [readonlyOnlyTime]="false" [readonlyTimeWithoutDate]="true"
                    [watermarkStyleUnlessTouched]="isPristineIncidentDate"
                    (ngModelChange)="onIncidentDateChanged($event)"
                    >
                </slx-datetimepicker>
            </span>
            <span class="slx-error-block" *ngIf="incidentDatetime.errors" errorMessage for="required"></span>
            <span errorMessage for="date" *ngIf="incidentDatetime.errors && report.incidentDateTime !==null" class="slx-error-block">
                Incorrect Date
            </span>
        </div>
        <div class="flex align-items__center dates-section-ctrl">
            <label class="dates-section-label">Date Reported:</label>
            <span class="flex align-items__center date-block">
                <slx-datetimepicker [(ngModel)]="report.reportDateTime"
                    [minDateTime]="minDate"
                    [slxMinDate]="minDate"
                    [maxDateTime]="maxDate"
                    [slxMaxDate]="maxDate"
                    [readonlyOnlyTime]="false"
                    [readonly]="reportDateReadonly"
                    [required]="true"
                    [readonlyTimeWithoutDate]="true"
                    [watermarkStyleUnlessTouched]="isPristineReportDate"
                    #reportDatetime="ngModel"
                    (ngModelChange)="onReportDateChanged($event)"
                    >
                </slx-datetimepicker>
            </span>
            <span class="slx-error-block" *ngIf="reportDatetime.errors"  errorMessage for="required"></span>
            <span *ngIf="reportDatetime.errors && report.reportDateTime !==null" class="slx-error-block">
                Incorrect Date
            </span>
        </div>

    </div>

    <div class="body-item report-tabs">
        <kendo-tabstrip class="slx-tabs">
            <kendo-tabstrip-tab title="Incident Report" [selected]="true">
                <ng-template kendoTabContent>
                    <slx-wc-injury-report class="slx-tabs__tab"></slx-wc-injury-report>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="OSHA" [disabled]="!report.injuryReport.isOSHARecordable">
                <ng-template kendoTabContent>
                    <slx-wc-osha-report class="slx-tabs__tab"></slx-wc-osha-report>
                </ng-template>
            </kendo-tabstrip-tab>
            <kendo-tabstrip-tab title="Workers' Comp" [disabled]="!report.injuryReport.isWorkersCompCase">
                <ng-template kendoTabContent>
                    <slx-wc-worker-compensation-report class="slx-tabs__tab"></slx-wc-worker-compensation-report>
                </ng-template>
            </kendo-tabstrip-tab>
        </kendo-tabstrip>
    </div>

    <div class="body-item report-attachments">
        <slx-wc-report-section title="Attachments" icon="far fa-paperclip" [notifications]="filesCount">
            <div class="panel-content">
                <slx-wc-report-attachments [editMode]="report.editMode"></slx-wc-report-attachments>
            </div>
        </slx-wc-report-section>
    </div>
</div>
