/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./punch-profile-attestation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/directives/slx-tooltip/slx-tooltip.directive";
import * as i3 from "../../../../components-library/components/checkbox-button/checkbox-button.component.ngfactory";
import * as i4 from "../../../../components-library/components/checkbox-button/checkbox-button.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../components-library/components/dropdown-list/dropdown-list.component.ngfactory";
import * as i7 from "../../../../components-library/components/dropdown-list/dropdown-list.component";
import * as i8 from "@angular/common";
import * as i9 from "./punch-profile-attestation.component";
import * as i10 from "../../services/punch-profile-management.service";
import * as i11 from "../../../../common/services/modal/modal.service";
import * as i12 from "../../../punch-attestation/services/punch-attestation-management.service";
var styles_PunchProfileAttestationComponent = [i0.styles];
var RenderType_PunchProfileAttestationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PunchProfileAttestationComponent, data: {} });
export { RenderType_PunchProfileAttestationComponent as RenderType_PunchProfileAttestationComponent };
function View_PunchProfileAttestationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["slxTooltip", "You are not an authorized user to save punch profile"], ["tipPosition", "bottom"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null)], function (_ck, _v) { var currVal_0 = "You are not an authorized user to save punch profile"; var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PunchProfileAttestationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "div", [["class", "attestation-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Punch Attestation"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "hr", [["style", "border-top: 1px solid #d5cdcd;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "slx-benefit-box__control flex align-items__center attestation-div-titles"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [["class", "slx-benefit-box__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enable Punch Attestation "])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "b", [["class", "slx-benefit-box__value flex__grow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "slx-checkbox-button", [["class", "dates-tab__checkbox"], ["name", "enabled"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.isEnabled = $event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.checkboxChecked() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_CheckboxButtonComponent_0, i3.RenderType_CheckboxButtonComponent)), i1.ɵdid(9, 49152, null, 0, i4.CheckboxButtonComponent, [], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CheckboxButtonComponent]), i1.ɵdid(11, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(13, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [["class", "slx-benefit-box__control flex align-items__center attestation-div-titles"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "b", [["class", "slx-benefit-box__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Punch Attestation Groups : "])), (_l()(), i1.ɵeld(17, 0, null, null, 6, "b", [["class", "slx-benefit-box__value flex__grow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 5, "slx-dropdown-list", [["class", "slx-wide"], ["titleField", "groupName"], ["valueField", "id"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.group = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DropdownListComponent_0, i6.RenderType_DropdownListComponent)), i1.ɵdid(19, 49152, null, 0, i7.DropdownListComponent, [], { options: [0, "options"], valueField: [1, "valueField"], titleField: [2, "titleField"], readonly: [3, "readonly"] }, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DropdownListComponent]), i1.ɵdid(21, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(23, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 7, "div", [["class", "slx-benefit-box__control flex attestation-div-titles"], ["style", "display:contents;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 4, "button", [["class", "slx-button slx-with-icon save-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.savePunchProfileGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "slx-button__icon fas fa-save"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Save "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PunchProfileAttestationComponent_1)), i1.ɵdid(29, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 1, "a", [["class", "hreflink"], ["style", "float:right;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewQuestions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Preview Question Groups"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "enabled"; var currVal_8 = _co.isEnabled; _ck(_v, 11, 0, currVal_7, currVal_8); var currVal_16 = _co.groups; var currVal_17 = "id"; var currVal_18 = "groupName"; var currVal_19 = !_co.isEnabled; _ck(_v, 19, 0, currVal_16, currVal_17, currVal_18, currVal_19); var currVal_20 = _co.group; _ck(_v, 21, 0, currVal_20); var currVal_22 = !_co.authorized; _ck(_v, 29, 0, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 13).ngClassValid; var currVal_5 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 23).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 23).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 23).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 23).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 23).ngClassValid; var currVal_14 = i1.ɵnov(_v, 23).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 23).ngClassPending; _ck(_v, 18, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_21 = (!_co.authorized || _co.disableSaveButton); _ck(_v, 25, 0, currVal_21); }); }
export function View_PunchProfileAttestationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-punch-profile-attestation", [], null, null, null, View_PunchProfileAttestationComponent_0, RenderType_PunchProfileAttestationComponent)), i1.ɵdid(1, 638976, null, 0, i9.PunchProfileAttestationComponent, [i10.PunchProfileManagementService, i1.ChangeDetectorRef, i11.ModalService, i12.PunchAttestationManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PunchProfileAttestationComponentNgFactory = i1.ɵccf("slx-punch-profile-attestation", i9.PunchProfileAttestationComponent, View_PunchProfileAttestationComponent_Host_0, { groups: "groups", profileId: "profileId", punchProfileGroup: "punchProfileGroup", attestationType: "attestationType", punchType: "punchType" }, {}, []);
export { PunchProfileAttestationComponentNgFactory as PunchProfileAttestationComponentNgFactory };
