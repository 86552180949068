export interface IPmAttachment {
  id: number;
  employeeId: number;
  fileName: string;
  name: string;
  addedBy: string;
  addedOn: string;
}

export class PmAttachment {
  public id: number;
  public employeeId: number;
  public fileName: string;
  public name: string;
  public description: string;
  public addedBy: string;
  public addedOn: Date;
}
