import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Assert } from '../../../framework/index';
import {
  TimeclockDailySummaryContainer, ITimeclockDailySummaryContainer,
  TimeclockDailySummary, ITimeclockDailySummary,RebootClockContainer,IRebootClockContainer
} from '../../models/index';
import { LookupMapService } from '../../../organization/services/lookup/lookup-map.service';

@Injectable()
export class TimeclockMapService {
  private lookupMapService: LookupMapService;

  constructor(lookupMapService: LookupMapService) {
    this.lookupMapService = lookupMapService;
  }

  public mapTimeclocksDailySummaryContainer(containerDto: ITimeclockDailySummaryContainer): TimeclockDailySummaryContainer {
    Assert.isNotNull(containerDto, 'containerDto');
    let container: TimeclockDailySummaryContainer = new TimeclockDailySummaryContainer();
    container.orgLevelId = containerDto.orgLevelId;
    container.orgLevelName = containerDto.orgLevelName;
    container.records = this.mapDailySummaryRecords(containerDto.records);

    return container;
  }

  public mapDailySummaryRecords(arrivalsDto: ITimeclockDailySummary[]): TimeclockDailySummary[] {
    Assert.isNotNull(arrivalsDto, 'arrivalsDto');
    let records: TimeclockDailySummary[] = [];
    _.forEach(arrivalsDto, (arrivalDto: ITimeclockDailySummary) => {
      let arrival: TimeclockDailySummary = this.mapDailySummary(arrivalDto);
      records.push(arrival);
    });
    return records;
  }

  public mapDailySummary(arrivalDto: ITimeclockDailySummary): TimeclockDailySummary {
    Assert.isNotNull(arrivalDto, 'arrivalDto');
    let dailySummary: TimeclockDailySummary = new TimeclockDailySummary();
    dailySummary.assignedCount = arrivalDto.assignedCount;
    dailySummary.communicationStatus = arrivalDto.communicationStatus;
    dailySummary.dailyFPErrorsPercent = arrivalDto.dailyFPErrorsPercent;
    dailySummary.dailyRestrictionsPercent = arrivalDto.dailyRestrictionsPercent;
    dailySummary.enrolledCount = arrivalDto.enrolledCount;
    dailySummary.id = arrivalDto.id;
    dailySummary.lastCommunicationDate = arrivalDto.lastCommunicationDate;
    dailySummary.name = arrivalDto.name;
    dailySummary.organization = this.lookupMapService.mapOrganization(arrivalDto.organization);
    dailySummary.isVirtual = arrivalDto.isVirtual;
    dailySummary.rebootStatus = arrivalDto.rebootStatus ? arrivalDto.rebootStatus : '';
    dailySummary.isTimeClockRebootStarted = false;
    return dailySummary;
  }

  public rebootClock(clockDto: IRebootClockContainer): RebootClockContainer {
    Assert.isNotNull(clockDto, 'clockDto');
    let reebootClock: RebootClockContainer = new RebootClockContainer();
    reebootClock.RealpostId = clockDto.RealpostId;
    reebootClock.DeviceId = clockDto.DeviceId;
    reebootClock.Id = clockDto.Id;
    reebootClock.ProcessedOn = clockDto.ProcessedOn;
    reebootClock.RequestedOn = clockDto.RequestedOn;
    reebootClock.RequestedUserId = clockDto.RequestedUserId;
    reebootClock.Status = clockDto.Status;
    reebootClock.StatusDetails = clockDto.StatusDetails;
    return reebootClock;
  }
}

