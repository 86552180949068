export interface IEmployeeSectionsAssignedDefinitions {
    id: number;
    dateOn: string;
    categoryName: string;
    definition: string;
  }
  
  export class EmployeeSectionsAssignedDefinitions {
    public id: number;
    public dateOn: Date;
    public categoryName: string;
    public definition: string;
    public isBuyBack: boolean = true;
  }