<div class="lm-overview flex justify-content__space-between">
  <ng-container *ngIf="!!data && data.length > 0">
    <kendo-chart class="lm-overview-chart"
    (seriesClick)="onSeriesClick($event)"
    >
      <kendo-chart-axis-defaults [visible]="false" [majorGridLines]="{visible: false}" [narrowRange]="false"></kendo-chart-axis-defaults>
      <kendo-chart-area background="transparent" [margin]="0">
      </kendo-chart-area>
      <kendo-chart-series>
        <kendo-chart-series-item *ngFor="let item of data" type="bar"  [stack]="{ type: '100%' }" [data]="[item]" 
          colorField="color">
          <kendo-chart-series-item-labels [visual]="labelVisual">
          </kendo-chart-series-item-labels>
          <kendo-chart-series-item-tooltip [padding]="{left: 5, top: 5, right:5, bottom:5}" position="left">
            <ng-template>
              {{ item.name }}: {{item.actualValue}}
            </ng-template>
            </kendo-chart-series-item-tooltip> 
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
   
  </ng-container>
  <div class="lm-overview-empty" *ngIf="!data || data.length === 0">You have no open requests</div>
  <div class="lm-overview__total flex flex-direction__column justify-content__center align-items__center">
    <span class="lm-overview__value">{{loaConsole?.totalCount}}</span>
    <span class="lm-overview__hint">Total</span>
  </div>
  <div class="lm-overview__incomplete flex flex-direction__column justify-content__center align-items__center"  [lmRosterLink]="'Incomplete'">
    <div class="flex justify-content__flex-center align-items__center">
      <i class="lm-overview__icon fas fa-exclamation-triangle" aria-hidden="true"></i>
      <span class="lm-overview__value">{{loaConsole?.incompleteTotalCount}}</span>
    </div>
    <span class="lm-overview__hint">Incomplete</span>
  </div>

</div>
<div class="lm-overview-mobile flex-direction__column  flex justify-content__center">
  <div class="flex flex__width100 justify-content__flex-start align-items__center">
      <ng-container *ngIf="!!data && data.length > 0">
        <kendo-chart class="lm-overview-chart"
        (seriesClick)="onSeriesClick($event)"
        >
          <kendo-chart-series>
            <kendo-chart-series-item type="donut" [data]="data" categoryField="name" field="value" colorField="color" [padding]="0" [margin]="0" [size]="50">
              <kendo-chart-series-item-labels [content]="labelContent" color="#fff" background="none">
              </kendo-chart-series-item-labels>
            </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-legend [visible]="false"></kendo-chart-legend>
        </kendo-chart>
    </ng-container>
    <div class="lm-overview-empty" *ngIf="!data || data.length === 0">You have no open requests</div>
    <div class="lm-overview__right flex flex-direction__column justify-content__space-around align-items__center">
        <div class="lm-overview__total flex justify-content__center align-items__center">
            <span class="lm-overview__value">{{loaConsole?.totalCount}}</span>
            <span class="lm-overview__hint">Total</span>
          </div>
          <div class="lm-overview__incomplete flex flex-direction__column justify-content__center align-items__center"  [lmRosterLink]="'Incomplete'">
            <div class="flex justify-content__flex-center align-items__center">
              <i class="lm-overview__icon fas fa-exclamation-triangle" aria-hidden="true"></i>
              <span class="lm-overview__value">{{loaConsole?.incompleteTotalCount}}</span>
            </div>
            <span class="lm-overview__hint">Total</span>
          </div>
    </div>
  </div>
  <div class="lm-overview-legend flex justify-content__center align-items__center">
    <div class="lm-overview-legend__item" [style.border-left-color]="item.color" *ngFor="let item of data">{{item.name}}</div>
  </div>

</div>
