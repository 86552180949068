import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slxHumanizeAccrualBalance'
})
@Injectable()
export class HumanizeAccrualBalancePipe implements PipeTransform {
  public transform(accrualBalance: number, isAvailableBalance: boolean, unavailableMessage: string): any {
    return isAvailableBalance ? String(accrualBalance) : unavailableMessage;
  }
}
