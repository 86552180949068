import { RoleSubsection, IRoleSubsection } from './role-subsection';
import { RolesAccess } from './role-access-definition';

export interface IRoleSection {
  name: string;
  subsections: IRoleSubsection[];
}

export class RoleSection {
  public name: string;
  public subsections: IRoleSubsection[];
  public access: RolesAccess;
}
