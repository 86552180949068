<div>
  <slx-input-decorator>
    <slx-kendo-number slx-input number
      [(ngModel)]="parameter.value"
      [required]="'true'"
      [format]="includeThousandsSeparator ? 'n' : '#'"
      [placeholder]="parameter.displayName"
      name="parameterValue"
    ></slx-kendo-number>
    <span errorMessage for="required">Report parameter is required.</span>
  </slx-input-decorator>
</div>
