import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const SLXACARECEIPTID_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SlxAcaReceiptIdValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[slx-aca-receiptid][formControlName],[slx-aca-receiptid][formControl],[slx-aca-receiptid][ngModel]',
  /* tslint:enable */
  providers: [SLXACARECEIPTID_VALIDATOR]
})
export class SlxAcaReceiptIdValidator implements Validator {
  public validate(c: AbstractControl): { [key: string]: any } {
    if (c.dirty) {
      return CommonValidators.acaReceiptId(c);
    }
    return null;
  }
}
