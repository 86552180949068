import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { configurationConfig } from '../../configuration.config';
import { TempSensorReading, ITempSensorReading } from '../../models/timeclocks/temp-sensor-reading';
import { TempSensorReadingMapService } from './temp-sensor-reading-map.service';

import { dateTimeUtils } from '../../../common/utils';

@Injectable()
export class TempSensorReadingApiService {
  constructor(private apiUtilService: ApiUtilService,
              private urlParamService: UrlParamsService,
              private mapService: TempSensorReadingMapService) {
  }

  public getTimeSensorReadings(orgLevelId: number, sDate: Date, eDate: Date): Promise<TempSensorReading[]> {
    const request = this.urlParamService.createGetRequest(this.getUrlRoot(orgLevelId), {
      startDate: dateTimeUtils.convertToDtoString(sDate),
      endDate: dateTimeUtils.convertToDtoString(eDate)
    });

    let promise: Promise<TempSensorReading[]> = this.apiUtilService.request<ITempSensorReading[], Meta>(request)
      .then((response: ResponseBody<ITempSensorReading[], Meta>) => this.mapService.mapTempSensorReadings(response.data));
    return promise;
  }

  private getUrlRoot(orgLevelId: number): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.timeclocks.timeAndAttendance}/${configurationConfig.api.configuration.timeclocks.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.timeclocks.tempSensorReadings}`;
  }
}
