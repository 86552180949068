import * as tslib_1 from "tslib";
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { schedulerConfig } from '../../scheduler.config';
import { AvailShifts, IAvailShifts, IScheduleRotationTemplate, IScheduleRotationPermisions, INoRotationEmployeeList, EmployeesGenerateRotation, AssignEmpToEmptySlot, EmployeeScheduledData, FutureValidationShift, FilterRotationOptions } from '../../models/schedule-rotation/schedule-rotation-template.model';
import { dateTimeUtils } from './../../../../app/common/utils';
import { ScheduledRotationMapService } from './scheduled-rotation-map.service';
import { filterOptionsRecord } from '../../models/schedule-rotation/schedule-rotation-template-filter';
var ScheduleRotationApiService = /** @class */ (function () {
    function ScheduleRotationApiService(apiUtilService, urlParamsService, mapService, http) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.mapService = mapService;
        this.http = http;
        this.apiUtilService = apiUtilService;
    }
    ScheduleRotationApiService.prototype.getEmployeeRotation = function (orgId, startDate, endDate) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.employeerotation);
        var request = this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapEmployeeRotation(response.data, response.meta); });
        return promise;
    };
    ScheduleRotationApiService.prototype.saveEmployeeRotation = function (orgId, startDate, endDate, saveResultOutput) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["", "/", "/", "?orgLevelId=", "&startDate=", "&endDate=", ""], ["", "/", "/", "?orgLevelId=", "&startDate=", "&endDate=", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.saveemployeerotation, orgId, startDate, endDate);
        var request = this.urlParamsService.createPostRequest(url, saveResultOutput);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapEmployeeRotation(response.data, response.meta); });
        return promise;
    };
    ScheduleRotationApiService.prototype.getScheduleRotationAvailShifts = function (orgLevelId, startDate, endDate) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.availShifts.availShiftsList);
        var request = this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgLevelId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });
        var promise = this.apiUtilService
            .request(request)
            .then(function (response) { return _this.mapService.mapScheduleRotationAvailShifts(response.data, response.meta); });
        return promise;
    };
    ScheduleRotationApiService.prototype.getScheduleRotationPermission = function (orgId) {
        var url = this.apiUtilService.apiRoot(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.permissions);
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgId })
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
        return promise;
    };
    ScheduleRotationApiService.prototype.getNoRotationEmployeeList = function (orgId, startDate, endDate) {
        var url = this.apiUtilService.apiRoot(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.noRotationEmployeeList);
        var request = this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
        return promise;
    };
    ScheduleRotationApiService.prototype.generateScheduleRotation = function (empRecord) {
        var url = this.apiUtilService.apiRoot(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.generate.root);
        var request = this.urlParamsService.createPostRequest(url, empRecord);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response; });
        return promise;
    };
    ScheduleRotationApiService.prototype.assignTerminatedSlotToEmployee = function (empRecord) {
        var url = this.apiUtilService.apiRoot(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.assignTerminatedSlotEmployee.root);
        var request = this.urlParamsService.createPutRequest(url, empRecord);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response; });
        return promise;
    };
    ScheduleRotationApiService.prototype.futureDateValidation = function (shiftDetail) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.futureDatesValidations.root);
        var request = this.urlParamsService.createPostRequest(url, shiftDetail);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapFutureDateValidationResult(response.data); });
        return promise;
    };
    ScheduleRotationApiService.prototype.removeSlotRotations = function (slotId) {
        var url = this.apiUtilService.apiRoot(templateObject_9 || (templateObject_9 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.deleteSlotRotation);
        var request = this.urlParamsService.createDeleteRequest(url, { slotId: slotId });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response; });
        return promise;
    };
    ScheduleRotationApiService.prototype.getSRTDefaultFilterOption = function () {
        var filterOptionData = filterOptionsRecord;
        var data = this.mapService.mapFilterRecordOptions(filterOptionData);
        return data;
    };
    ScheduleRotationApiService.prototype.saveEmpRotationAndEmptySlotRotation = function (orgId, startDate, endDate, saveResultOutput, empRecord) {
        var _this = this;
        var saveEmpRotationUrl = this.apiUtilService.apiRoot(templateObject_10 || (templateObject_10 = tslib_1.__makeTemplateObject(["", "/", "/", "?orgLevelId=", "&startDate=", "&endDate=", ""], ["", "/", "/", "?orgLevelId=", "&startDate=", "&endDate=", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.saveemployeerotation, orgId, startDate, endDate);
        var saveEmpRotationRequest = this.urlParamsService.createPostRequest(saveEmpRotationUrl, saveResultOutput);
        var saveEmptySlotUrl = this.apiUtilService.apiRoot(templateObject_11 || (templateObject_11 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.schedulerotation.root, schedulerConfig.api.schedulerotation.assignTerminatedSlotEmployee.root);
        var saveEmptySlotRequest = this.urlParamsService.createPutRequest(saveEmptySlotUrl, empRecord);
        var saveEmpRotationPromise = this.apiUtilService.request(saveEmpRotationRequest);
        var saveEmptySlotPromise = this.apiUtilService.request(saveEmptySlotRequest);
        return Promise.all([saveEmpRotationPromise, saveEmptySlotPromise])
            .then(function (values) {
            return _this.mapService.mapEmployeeRotation(values[0].data, values[0].meta);
        });
    };
    return ScheduleRotationApiService;
}());
export { ScheduleRotationApiService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
