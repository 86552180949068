import { Injectable, Type } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras,
  CanActivateChild, Params
} from '@angular/router';
import { AccessibleService } from './accessible.service';
import { IRouteInfo, isRouteInfo, IRouteDetails } from '../../../core/models/index';
import { IOrgRouteData } from '../../models/index';
import * as _ from 'lodash';

@Injectable()
export class AccessibleGuard implements CanActivate, CanActivateChild {

  constructor(private accessibleService: AccessibleService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    if (!route.data || !isRouteInfo(route.data)) {
      return true;
    }
    let orgRouteData: any = this.extractRouteInfo(state, route);
    return this.accessibleService.isAccessible(route.data, orgRouteData);
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    if (!childRoute.data || !isRouteInfo(childRoute.data)) {
      this.accessibleService.clearComponentId();
      return true;
    }
    let orgRouteData: any = this.extractRouteInfo(state, childRoute);
    return this.accessibleService.isAccessible(childRoute.data, orgRouteData);
  }

  private extractRouteInfo(state: RouterStateSnapshot, route: ActivatedRouteSnapshot): IOrgRouteData {
    let orgRouteData: IOrgRouteData = { orgLevelId: null, empId: null };
    const regexEmp = /(?:employee\/)(\d+)/i;
    const empMatches = state.url.match(regexEmp);

    const regexOrgLevel = /(?:orgLevelId=)(\d+)/i;
    const orgLevelMatches = state.url.match(regexOrgLevel);

    if (_.size(orgLevelMatches) >= 2) {
      orgRouteData.orgLevelId = parseInt(orgLevelMatches[1]);
    }

    let employeeId = parseInt(route.paramMap.get('employeeId'));
    if (_.isFinite(employeeId)) {
      orgRouteData.empId = employeeId;
    } else if (_.size(empMatches) >= 2) {
      orgRouteData.empId = parseInt(empMatches[1]);
    }

    return orgRouteData;
  }
}
