import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, RouterStateSnapshot, Router, Resolve
} from '@angular/router';

import { BaseResolver } from '../../../common/index';
import { IAccrualsBalancesState } from '../models/index';

@Injectable()
export class AccrualsBalancesResolver extends BaseResolver<IAccrualsBalancesState> implements Resolve<IAccrualsBalancesState> {

  constructor (router: Router) {
    super(router);
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<IAccrualsBalancesState> {
    let isDollarValueActive: boolean = this.parseBool(route.queryParamMap.get('showDollarValues'));
    let isEmployeeActive: boolean = this.parseBool(route.queryParamMap.get('filterByActiveEmployees'));
    let isShowNegativeBalance: boolean = this.parseBool(route.queryParamMap.get('filterByNegativeBalances'));

    const result: IAccrualsBalancesState = {};

    if (!_.isNull(isDollarValueActive)) {
      result.isDollarValueActive = isDollarValueActive;
    }

    if (!_.isNull(isEmployeeActive)) {
      result.isEmployeeActive = isEmployeeActive;
    }

    if (!_.isNull(isShowNegativeBalance)) {
      result.isShowNegativeBalance = isShowNegativeBalance;
    }

    return Promise.resolve(result);
  }

  private parseBool(param: string): boolean {
    return _.isNull(param) ? null : (param === 'true' || param === '1');
  }
}

