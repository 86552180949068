export * from './master-schedule-header/master-schedule-header.component';
export * from './master-shedule.component';
export * from './master-schedule-grid/master-schedule-grid.component';
export * from './master-schedule-grid/master-schedule-options-dialog.component';
export * from './master-schedule-day-info/master-schedule-day-info.component';
export * from './master-schedule-day-info-dialog/master-schedule-day-info-dialog.component';
export * from './master-schedule-columns-settings/master-schedule-columns-settings.component';
export * from './ag-grid-extentions/master-schedule-custom-gh/master-schedule-custom-gh.component';
export * from './ag-grid-extentions/master-schedule-custom-ch/master-schedule-custom-ch.component';
export * from './master-schedule-quick-edit/master-schedule-quick-edit.component';
export * from './master-schedule-quick-edit-prompt-dialog/master-schedule-quick-edit-prompt-dialog.component';


import { MasterScheduleHeaderComponent } from './master-schedule-header/master-schedule-header.component';
import { MasterScheduleComponent } from './master-shedule.component';
import { MasterScheduleGridComponent } from './master-schedule-grid/master-schedule-grid.component';
import { MasterScheduleOptionsDialogComponent } from './master-schedule-grid/master-schedule-options-dialog.component';
import { MasterScheduleDayInfoComponent } from './master-schedule-day-info/master-schedule-day-info.component';
import { MasterScheduleDayInfoDialogComponent } from './master-schedule-day-info-dialog/master-schedule-day-info-dialog.component';
import { MasterScheduleColumnsSettingsComponent } from './master-schedule-columns-settings/master-schedule-columns-settings.component';
import { MasterScheduleTotalsSettingsComponent } from './master-schedule-totals-settings/master-schedule-totals-settings.component';
import { MasterScheduleCustomGroupHeader } from './ag-grid-extentions/master-schedule-custom-gh/master-schedule-custom-gh.component';
import { MasterScheduleCustomColumnHeader } from './ag-grid-extentions/master-schedule-custom-ch/master-schedule-custom-ch.component';
import { MasterScheduleQuickEditComponent } from './master-schedule-quick-edit/master-schedule-quick-edit.component';
import { MasterScheduleQuickEditPromptDialogComponent } from './master-schedule-quick-edit-prompt-dialog/master-schedule-quick-edit-prompt-dialog.component';
import { ViewIndividualScheduleDialogComponent } from './view-individual-schedule-dialog/view-individual-schedule-dialog.component';

export const masterScheduleEntryComponents: any = [
  MasterScheduleOptionsDialogComponent,
  MasterScheduleDayInfoDialogComponent,
  MasterScheduleCustomGroupHeader,
  MasterScheduleCustomColumnHeader,
  MasterScheduleQuickEditPromptDialogComponent,
  ViewIndividualScheduleDialogComponent
];
export const masterScheduleComponents: any = [
  MasterScheduleHeaderComponent,
  MasterScheduleComponent,
  MasterScheduleGridComponent,
  MasterScheduleOptionsDialogComponent,
  MasterScheduleDayInfoComponent,
  MasterScheduleDayInfoDialogComponent,
  MasterScheduleColumnsSettingsComponent,
  MasterScheduleTotalsSettingsComponent,
  MasterScheduleCustomGroupHeader,
  MasterScheduleCustomColumnHeader,
  MasterScheduleQuickEditComponent,
  MasterScheduleQuickEditPromptDialogComponent,
  ViewIndividualScheduleDialogComponent
];
