import { Directive, Input, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';

const SLX_MIN_LENGTH_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SlxMinLengthValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[slxMinLength][formControlName],[slxMinLength][formControl],[slxMinLength][ngModel]',
  /* tslint:enable */
  providers: [SLX_MIN_LENGTH_VALIDATOR]
})
export class SlxMinLengthValidator implements Validator, OnChanges {
  @Input()
  public slxMinLength: string;

  /* tslint:disable */
  private _validator: ValidatorFn;
  private _onChange: () => void;
  /* tslint:enable */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['slxMinLength']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): {[key: string]: any} {
    return _.isFinite(parseInt(this.slxMinLength, 10)) ? this._validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = CommonValidators.slxMinLength(parseInt(this.slxMinLength, 10));
  }
}
