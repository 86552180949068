import { ModalService } from './../../../common/services/modal/modal.service';
import { FavoritesMapService } from './favorites-map.service';
import { NavigationMenuItem, NavigationMenuType } from './../../../organization/models/navigation-menu-item';
import { ApplicationStateBusService } from './../../../organization/services/application-state-bus/application-state-bus.service';
import { FavoriteApiService } from './favorites-api.service';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Injectable } from '@angular/core';
import { FavoriteItem } from './../../models/favorites/favorite-item';
import * as _ from 'lodash';
import { FavoritesConfigurationDialogComponent } from '../../components/favorites/favorites-configuration-dialog.component';

@Injectable()
export class FavoriteManagementService {
    public favoriteItemsUpdated$: ReplaySubject<NavigationMenuItem[]> = new ReplaySubject(1);
    public configurationItemsUpdated$: ReplaySubject<NavigationMenuItem[]> = new ReplaySubject(1);
    public menuItemsUpdated$: ReplaySubject<NavigationMenuItem[]> = new ReplaySubject(1);

    private menuItems: NavigationMenuItem[] = [];
    private favoriteList: NavigationMenuItem[] = [];

    private menuItemsMap: NumberMap<NavigationMenuItem>;

    constructor(private api: FavoriteApiService, private busService: ApplicationStateBusService, private modalService: ModalService) {
      this.busService.menuLoaded$.subscribe((menuItems: NavigationMenuItem[]) => {
        this.menuItems = menuItems;
        this.menuItemsMap = {};
        this.createMap(this.menuItems, this.menuItemsMap);
        this.loadList();
        this.menuItemsUpdated$.next(this.menuItems);
      });
    }

    public canBeFavorite(menuItem: NavigationMenuItem): boolean {
      return menuItem.type === NavigationMenuType.menuItem && !!menuItem.link && (!menuItem.childs || menuItem.childs.length === 0);
    }

    public changeFavoriteState(menuItem: NavigationMenuItem): void {
      if (menuItem.isFavorite) {
        this.removeItem(menuItem);
      } else {
        this.addItem(menuItem);
      }
    }

    public showConfigDialog(): void {
      FavoritesConfigurationDialogComponent.openDialog(this.modalService, (success: boolean) => {
        this.saveList();
      });
    }

    private loadList(): void {
      this.api.loadFavoriteList().then((value: NavigationMenuItem[]) => {
        this.favoriteList = [];
        _.forEach(value, item => {
           const f: NavigationMenuItem = this.menuItemsMap[item.id];
           if (f) {
             f.isFavorite = true;
             f.order = item.order;
             this.favoriteList.push(f);
           }
        });
        this.favoriteList = _.sortBy(this.favoriteList, x => x.order);
        this.favoriteItemsUpdated$.next(this.favoriteList);
        this.configurationItemsUpdated$.next(this.favoriteList);
      });
    }

    private recalculateOrder(): void {
      let i: number = 0;
      _.forEach(this.favoriteList, x => x.order = i++);
    }

    private saveList(): Promise<any> {
      this.recalculateOrder();
      this.favoriteItemsUpdated$.next(this.favoriteList);
      return this.api.saveFavoriteList(this.favoriteList);
    }

    private updateConfigItems(): void {
      this.favoriteList = [...this.favoriteList];
      this.configurationItemsUpdated$.next(this.favoriteList);
    }

    private addItem(menuItem: NavigationMenuItem): void {
      menuItem.isFavorite = true;
      this.favoriteList.push(menuItem);
      this.updateConfigItems();
    }

    private removeItem(menuItem: NavigationMenuItem): void {
      menuItem.isFavorite = false;
      _.remove(this.favoriteList, x => x.id === menuItem.id);
      this.updateConfigItems();
    }

    private createMap(items: NavigationMenuItem[], map: NumberMap<NavigationMenuItem>): void {
      if (items) {
        _.forEach(items, x => {
          if (x.type == 'MenuItem'){
            map[x.id] = x;
          }
          this.createMap(x.childs, map);
        });
      }
    }
}
