import { IBenefitDetailsBasic, BenefitDetailsBasic } from './benefit-details-basic';

export enum BenefitFormulaCalculationType {
  Formula = 'Formula',
  Fixed = 'Fixed',
  MultiplerMaxCap = 'MultiplerMaxCap',
  AnyValueMaxCap = 'AnyValueMaxCap'
}

export enum BenefitExpressionType {
  Sql = 'Sql',
  NetExpression = 'NetExpression'
}

export interface IBenefitDetailsFormula {
  formulaDefinition: IBenefitDetailsBasic<number, string>;
  start: string;
  end: string;
  payrollFormula: string;
  fixedAmount: number;
  maxCup: number;
  multipler: number;
  calculationType: string;
  expression: string;
  expressionType: string;
}

export class BenefitDetailsFormula {
  public formula = new BenefitDetailsBasic<number, string>(null, null, null);
  public startDate: Date;
  public endDate: Date;
  public payrollFormula: string;
  public fixedAmount: number;
  public maxCap: number;
  public multipler: number;
  public calculationType: string;
  public expression: string;
  public expressionType: string;

  public get isFormulaCalcType(): boolean {
    return this.calculationType === BenefitFormulaCalculationType.Formula;
  }

  public get isFixedCalcType(): boolean {
    return this.calculationType === BenefitFormulaCalculationType.Fixed;
  }

  public get isMultiplierCalcType(): boolean {
    return this.calculationType === BenefitFormulaCalculationType.MultiplerMaxCap;
  }

  public get isAnyValueMaxCapCalcType(): boolean {
    return this.calculationType === BenefitFormulaCalculationType.AnyValueMaxCap;
  }

  public get isSqlExpressionType(): boolean {
    return this.expressionType === BenefitExpressionType.Sql;
  }

  public get isNetExpressionType(): boolean {
    return this.expressionType === BenefitExpressionType.NetExpression;
  }
}
