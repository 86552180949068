import { OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ReportParameter } from '../../../models/index';
import { LookupDefinition, Lookup } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';
var RadioParameterComponent = /** @class */ (function () {
    function RadioParameterComponent(lookupService) {
        this.lookupService = lookupService;
    }
    Object.defineProperty(RadioParameterComponent.prototype, "parameter", {
        get: function () {
            return this.m_parameter;
        },
        set: function (value) {
            this.m_parameter = value;
            this.processParameter(this.m_parameter);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RadioParameterComponent.prototype, "hasDefaultValue", {
        get: function () {
            return _.size(this.parameter.defaultValue) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RadioParameterComponent.prototype, "lookupName", {
        get: function () {
            if (this.parameter && this.parameter.dataType)
                return this.parameter.dataType.lookupName;
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    RadioParameterComponent.prototype.ngOnInit = function () {
        var _this = this;
        var lookupPromise = this.lookupService.getLookup({
            lookupType: this.lookupName
        });
        lookupPromise.then(function (lookup) {
            _this.options = lookup.items;
        });
    };
    RadioParameterComponent.prototype.processParameter = function (parameter) {
        if (this.hasDefaultValue) {
            parameter.value = parameter.defaultValue;
        }
        else {
            parameter.value = '0';
        }
    };
    return RadioParameterComponent;
}());
export { RadioParameterComponent };
