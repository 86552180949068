import { WcViewList } from './wc-view-list';

export class WcViewItem {
  constructor(
    public id: string,
    public name: string,
    public path: string
  ) {}

  public get isIncidentRoster(): boolean {
    return this.id === WcViewList.IncidentRoster;
  }

  public get isComparisons(): boolean {
    return this.id === WcViewList.Comparisons;
  }

  public get isSummary(): boolean {
    return this.id === WcViewList.Summary;
  }

  public get isRepeatInjuryList(): boolean {
    return this.id === WcViewList.RepeatInjuryList;
  }

  public get isOshaBudgetTracker(): boolean {
    return this.id === WcViewList.OshaBudgetTracker;
  }

  public get isCreateOshaRkForms(): boolean {
    return this.id === WcViewList.CreateOshaRkForms;
  }
}
