import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { EmployeeSection, IEmployeeWorkplace, EmployeeWorkplace } from '../models/index';
import { employeeSectionsConfig } from '../employee-sections.config';
import { EmployeeSectionMapService, IEmployeeSection } from './employee-section-map.service';
import { ApiUtilService } from '../../../common/services/index';
import { Meta } from '../../../core/models/api/meta';
import { Assert } from '../../../framework/index';
import { CacheType } from '../../../common/models/cache/cache-definition';
@Injectable()
export class EmployeeSectionApiService {
  constructor(
    private employeeSectionMapService: EmployeeSectionMapService,
    private apiUtilService: ApiUtilService) {
  }

  public getSections(employeeId: number): Promise<EmployeeSection[]> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.sections.root}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSection[]> = this.apiUtilService.request<EmployeeSection[], Meta>(request)
      .then((response: ResponseBody<IEmployeeSection[], Meta>) => this.employeeSectionMapService.mapToSections(response.data));
    return promise;
  }

  public getWorkplace(): Promise<EmployeeWorkplace> {
    const url: string = `${this.getApiRoot()}/${employeeSectionsConfig.api.employees.sections.workplace}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeWorkplace> = this.apiUtilService
      .cachedRequest<IEmployeeWorkplace, Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IEmployeeWorkplace, Meta>) => this.employeeSectionMapService.mapToWorkplace(response.data));
    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.employees.root}`;
  }
}
