import { Component, OnInit, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { UserApplication } from '../../../state-model/models/index';
import { SidebarActions } from '../../actions/index';
import { scheduleMicrotask } from '../../../core/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-header-menu-button',
  templateUrl: 'header-menu-button.component.html',
  styleUrls: ['header-menu-button.component.scss']
})
export class HeaderMenuButtonComponent implements OnInit, OnDestroy {
  @mutableSelect(['sidebar', 'isRightSidebarOpen'])
  public isRightSidebarOpen: Observable<boolean>;
  @mutableSelect('application')
  public application$: Observable<UserApplication>;

  public state: {
    isRightSidebarOpen: boolean;
    application: UserApplication;
  };

  private sidebarActions: SidebarActions;
  @unsubscribe()
  private isRightSidebarOpenSubscripion: Subscription;
  @unsubscribe()
  private applicationSubscripion: Subscription;

  constructor(sidebarActions: SidebarActions) {
    this.sidebarActions = sidebarActions;
    this.state = {
      isRightSidebarOpen: false,
      application: null
    };
  }

  public ngOnInit(): void {
    this.isRightSidebarOpenSubscripion = this.isRightSidebarOpen.subscribe((value: boolean) => {
      scheduleMicrotask(() => { this.state.isRightSidebarOpen = value; });
    });
    this.applicationSubscripion = this.application$.subscribe((value: UserApplication) => {
      scheduleMicrotask(() => { this.state.application = value; });
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public toggleRightSidebar(): void {
    this.sidebarActions.setRightSidebar(!this.state.isRightSidebarOpen);
  }
}
