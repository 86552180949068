import * as _ from 'lodash';
var IHoliday = /** @class */ (function () {
    function IHoliday() {
    }
    return IHoliday;
}());
export { IHoliday };
var Holiday = /** @class */ (function () {
    function Holiday() {
        this.id = 0;
        this.selectable = true;
    }
    Object.defineProperty(Holiday.prototype, "isRuleBased", {
        get: function () {
            return this.origin === 'RuleBased';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Holiday.prototype, "ruleDescription", {
        get: function () {
            return this.getRuleDescription();
        },
        enumerable: true,
        configurable: true
    });
    Holiday.prototype.getRuleDescription = function () {
        if (this.rule) {
            var m = this.getMonthBy(this.rule.month);
            return this.rule.dayDefiningPattern + " " + this.rule.dayOfWeek + " of " + (m ? m.name : '');
        }
        return '';
    };
    Holiday.prototype.getMonthBy = function (id) {
        return _.find(this.months, function (item) { return item.id === id; });
    };
    return Holiday;
}());
export { Holiday };
var HolidayRule = /** @class */ (function () {
    function HolidayRule() {
    }
    return HolidayRule;
}());
export { HolidayRule };
