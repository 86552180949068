import * as tslib_1 from "tslib";
import { AppSettingsManageService } from './../../../app-settings/services/app-settings-manage.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmDialogComponent } from '../../../common/index';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
import { ConfigurePositionsReassignPopupComponent } from './reassignPopup/configure-positions-reassign-popup.component';
import { ManagePositionGroupsDialogComponent } from './manage-groups/manage-position-groups-dialog/manage-position-groups-dialog.component';
import { PositionModel, AdditionalInfo } from '../../models/index';
import { PositionsManagementService } from '../../services/index';
import { ConfirmOptions } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import { EditableListActionKind } from '../../models/editableList/editable-list-action.model';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { screenUtils } from '../../../common/utils/index';
import { OrgLevelType } from '../../../state-model/models/index';
var ConfigurePositionsComponent = /** @class */ (function () {
    function ConfigurePositionsComponent(management, modalService, appSettingsManageService) {
        var _this = this;
        this.modalService = modalService;
        this.appSettingsManageService = appSettingsManageService;
        this.isAddPosition = false;
        this.groups = [{ field: 'positionGroupName' }];
        this.management = management;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        // directive intercepted methods (scope bug)
        this.onAddItem = function () {
            _this.isAddPosition = true;
            var position = new PositionModel();
            position.additionalInfo = new AdditionalInfo();
            _this.crudHelper.addItem(position);
        };
        this.onDeletedItems = function () {
            _this.crudHelper.deleteSelected();
        };
    }
    Object.defineProperty(ConfigurePositionsComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePositionsComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePositionsComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurePositionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            configureMode: true,
            copyMode: false
        };
        this.savedSubscription = this.management.onItemSaved$.subscribe(function (item) {
            _this.gridState.editedRecord = null;
            _this.gridState.savedEditedRecord = null;
        });
        this.onloadedSubscription = this.management.onLoaded$.subscribe(function (state) {
            _this.gridState.state.group = _this.groups;
        });
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
            if (_.has(state, 'configureMode'))
                _this.state.configureMode = state.configureMode;
            if (_.has(state, 'copyMode'))
                _this.state.copyMode = state.copyMode;
            _this.essTemplatesEnabled = _this.management.essTemplatesEnabled;
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.removeSubscription = this.management.removeItemsCmd$.subscribe(function (request) {
            if (request.requireReassign) {
                ConfigurePositionsReassignPopupComponent.openDialog(request, _this.modalService, function (result, reassingRequest) {
                    if (result) {
                        _this.management.doRemoveItem(request.itemToDelete, reassingRequest.ressignedPositionId, reassingRequest.effectiveDate);
                    }
                });
            }
            else {
                var options = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                ConfirmDialogComponent.openDialog('Confirmation', 'Open Shifts are posted for this position, by deleting the position, all open shifts for this position will be removed effective of this date and this cannot be undone. Are you sure you want to delete this position?', _this.modalService, function (result) {
                    if (result) {
                        _this.management.doRemoveItem(request.itemToDelete);
                    }
                }, options);
            }
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
        this.orgChangeSubscription = this.management.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
        });
        this.getSettings();
    };
    // Must be, see #issueWithAOTCompiler
    ConfigurePositionsComponent.prototype.ngOnDestroy = function () {
        if (this.crudHelper) {
            this.crudHelper.destroy();
        }
    };
    ConfigurePositionsComponent.prototype.onManageGroups = function () {
        var _this = this;
        ManagePositionGroupsDialogComponent.openDialog(this.modalService, function (hasChanges) {
            if (hasChanges) {
                _this.management.loadPositions();
            }
        });
    };
    ConfigurePositionsComponent.prototype.onCopyItems = function (event) {
        event.preventDefault();
        this.management.openCopyItems();
    };
    ConfigurePositionsComponent.prototype.switchToConfigure = function () {
        this.management.closeCopyItems();
    };
    ConfigurePositionsComponent.prototype.extenalEditorAction = function (action) {
        if (action === EditableListActionKind.COMPLETE_EDIT) {
            this.isAddPosition = false;
            this.crudHelper.onSaveItem({ dataItem: this.management.editingItem, isNew: this.management.isEditingNewItem });
        }
        else if (action === EditableListActionKind.CANCEL_EDIT) {
            this.isAddPosition = false;
            this.crudHelper.onCancelItem(this.management.editingItem);
        }
    };
    ConfigurePositionsComponent.prototype.onMobileRendererEvent = function (action, item, index) {
        if (action === EditableListActionKind.SELECTION_CHANGE) {
            this.crudHelper.selectionChange(item, index);
        }
    };
    ConfigurePositionsComponent.prototype.getGridData = function () {
        var _this = this;
        return function () {
            return {
                data: _.cloneDeep(_this.gridState.view.data),
                group: _this.gridState.state.group
            };
        };
    };
    ConfigurePositionsComponent.prototype.onExportToExcel = function () {
        this.generateExportFileHeader();
        this.grid.saveAsExcel();
    };
    ConfigurePositionsComponent.prototype.onExportToPdf = function () {
        this.generateExportFileHeader();
        this.grid.saveAsPDF();
    };
    ConfigurePositionsComponent.prototype.generateExportFileHeader = function () {
        var organization = this.currentOrgLevel.name;
        var exported = moment().format(appConfig.dateTimeFormatUS);
        var header = organization + " Exported on " + exported;
        if (this.currentOrgLevel.type === OrgLevelType.department) {
            header = _.last(this.currentOrgLevel.treeViewNamePath) + ' - ' + header;
        }
        this.exportedFileHeader = header;
    };
    ConfigurePositionsComponent.prototype.onExcelExport = function (event) {
        event.workbook.sheets[0].rows.unshift({
            cells: [
                {
                    value: this.exportedFileHeader
                }
            ]
        });
    };
    ConfigurePositionsComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        _a.appSetting = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PositionsManagementService)
    ], ConfigurePositionsComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePositionsComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePositionsComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePositionsComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePositionsComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePositionsComponent.prototype, "onloadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePositionsComponent.prototype, "orgChangeSubscription", void 0);
    return ConfigurePositionsComponent;
}());
export { ConfigurePositionsComponent };
