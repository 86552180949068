<div class="report-item" *ngFor="let report of reports">
  <button type="button" class="button-link" (click)="onClick(report)">
    {{ report.displayName }}
  </button>
  <div [slxOpenReportConfig]="report" class="config-edit-button"
    *ngIf="report.reportConfigId > 0 
            && report.name !== 'BIPA' 
            && report.name !== 'ta_labor_report_midnightV6' 
            && report.name !== 'ta_labor_report_midnight_detailV6'
            && report.name !== 'BIPA_Without_Units'
            && report.name !== 'BIPA_Without_Units_Without_Ratio'
            && report.name !== 'BIPA_NJ_Actual_Hours'">
    <em class="fas fa-cog" aria-hidden="true"></em>
  </div>
  <div *ngIf="(report.name === 'BIPA' 
              || report.name === 'BIPA_Without_Units'
              || report.name === 'BIPA_Without_Units_Without_Ratio'
              || report.name === 'BIPA_NJ_Actual_Hours') && report.reportConfigId > 0" [slxBIPAReport]="report" class="config-edit-button">
    <em class="fas fa-cog" aria-hidden="true"></em>
  </div>
  <div *ngIf="report.name === 'ta_labor_report_midnightV6' && report.reportConfigId > 0" [slxMidnightReport]="report"
    class="config-edit-button">
    <em class="fas fa-cog" aria-hidden="true"></em>
  </div>
  <div *ngIf="report.name === 'ta_labor_report_midnight_detailV6' && report.reportConfigId > 0"
    [slxMidnightReport]="report" class="config-edit-button">
    <em class="fas fa-cog" aria-hidden="true"></em>
  </div>
</div>