export interface ISeniorityRules {
  id: number;
  ruleId: number;
  periodFromValue: number;
  periodFromType: string;
  periodToValue: number;
  periodToType: string;
  accrualRate: string;
  rateUnit: string;
  basisUnit: string;
  rateFormula: string;
  accruedOnDates: string;     
  minRequiredHours: number;
  accruedOnDay: number;
  maxAccruedAnnual: number;
  maxAccruedBalance: number;
  carryoverValue: number;
  carryoverType: string;
  carryoverDay: string;
  carryoverValueType: string;
  carryoverExpirationDays: number;
  carryoverToAccrualTypeId: number;
  carryoverToAccrualTypeName: string;
  ruleCalcType: string;
  rollOverMax: string;
  accruedOn_Seniority: string;
  basisHour_Seniority: number;
}

export class SeniorityRules {
  public id: number;
  public ruleId: number;
  public periodFromValue: number;
  public periodFromType: string;
  public periodToValue: number;
  public periodToType: string;
  public accrualRate: string;
  public rateUnit: string;
  public basisUnit: string;
  public rateFormula: string;
  public accruedOnDates: string;   
  public minRequiredHours: number;
  public accruedOnDay: number;
  public maxAccruedAnnual: number;
  public maxAccruedBalance: number;
  public carryoverValue: number;
  public carryoverType: string;
  public carryoverDay: string;
  public carryoverValueType: string;
  public carryoverExpirationDays: number;
  public carryoverToAccrualTypeId: number;
  public carryoverToAccrualTypeName: string;
  public ruleCalcType: string;
  public rollOverMax: string;
  public accruedOn_Seniority: string;
  public basisHour_Seniority: number;
}
