import * as tslib_1 from "tslib";
import { WizardActions } from '../../../common/store/index';
import { Observable } from 'rxjs/Observable';
import { AddEmployeeWizardActions } from '../store/add-employee-wizard/add-employee-wizard.actions';
import { EmployeeActivitiesApiService } from '../services/employee-activities/employee-add-api.service';
import { NgRedux } from '@angular-redux/store';
import { AddEmployeeWizardStatus } from '../store/add-employee-wizard/add-employee-wizard.types';
import { ValidationError } from '../../../core/models/index';
var AddEmployeeWizardStateEpics = /** @class */ (function () {
    function AddEmployeeWizardStateEpics(employeeActivitiesApiService, ngRedux) {
        var _this = this;
        this.addEmployee = function (action$) {
            var addEmployee$ = action$.filter(function (_a) {
                var type = _a.type;
                return type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE;
            });
            return addEmployee$.mergeMap(function (action) {
                var model = action.payload;
                return Observable
                    .fromPromise(_this.employeeActivitiesApiService.addNewEmployee(model))
                    .map(function (response) {
                    return {
                        type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_SUCCESS,
                        payload: {
                            status: AddEmployeeWizardStatus.success,
                            newEmpId: response.id,
                            payrollNumber: response.payrollNumber,
                            model: tslib_1.__assign({}, model, { payrollNumber: response.payrollNumber })
                        }
                    };
                })
                    .catch(function (error) {
                    if (error instanceof ValidationError) {
                        return Observable.of({
                            type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_VALIDATION_ERROR,
                            payload: {
                                status: AddEmployeeWizardStatus.validationFailed
                            }
                        });
                    }
                    return Observable.of({
                        type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_ERROR,
                        payload: {
                            status: AddEmployeeWizardStatus.error,
                            newEmpId: 0
                        }
                    });
                });
            });
        };
        this.addEmployeeFinish = function (action$) {
            var addEmployee$ = action$.filter(function (_a) {
                var type = _a.type;
                return type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_SUCCESS || type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_ERROR;
            });
            return addEmployee$.mergeMap(function (action) {
                return Observable.of({
                    type: WizardActions.SELECT_NEXT_STEP,
                    payload: null
                });
            });
        };
        this.employeeActivitiesApiService = employeeActivitiesApiService;
        this.ngRedux = ngRedux;
    }
    return AddEmployeeWizardStateEpics;
}());
export { AddEmployeeWizardStateEpics };
