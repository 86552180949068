import { Component, Provider } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { RecipientInfo, SendShiftSmsRequest, SentMessageAttributes } from '../../../models/index';

import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-send-shift-sms-dialog',
  templateUrl: 'send-shift-sms-dialog.component.html',
  styleUrls: ['send-shift-sms-dialog.component.scss']
})
export class SendShiftSmsDialogComponent implements IDialog {
  public dialogResult: boolean;
  public newMessageId: number;
  private options: DialogOptions;
  private modalService: ModalService;

  public static openDialog(request: SendShiftSmsRequest, modalService: ModalService, callback: (result: boolean, request: SendShiftSmsRequest) => void): SendShiftSmsDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 400;
    dialogOptions.fullHeightOnMobile = false;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: SendShiftSmsRequest,
        useValue: request
      }
    ];
    let dialog: SendShiftSmsDialogComponent = modalService.globalAnchor.openDialog(
      SendShiftSmsDialogComponent,
      'Send sms on shift',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result, dialog.request);
      });

    return dialog;
  }

  constructor(
    public request: SendShiftSmsRequest,
    options: DialogOptions,
    modalService: ModalService
  ) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public onMessageSent(newMessageAttributes: SentMessageAttributes): void {
    this.newMessageId = newMessageAttributes.messageId;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onSendCanceled(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onErrorOccured(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
