var EmployeeShortInfo = /** @class */ (function () {
    function EmployeeShortInfo() {
    }
    Object.defineProperty(EmployeeShortInfo.prototype, "name", {
        get: function () {
            if (!this.lastName && !this.firstName) {
                return this.fullName;
            }
            return this.lastName + ", " + this.firstName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeShortInfo.prototype, "avoidsBswiftChecks", {
        get: function () {
            return this.isAgency || this.type === 'AFT' || this.type === 'APT';
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeShortInfo;
}());
export { EmployeeShortInfo };
