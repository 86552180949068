import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { PmRosterManagementService } from '../../../services/index';
var PmRosterComponent = /** @class */ (function () {
    function PmRosterComponent(pmManagementService) {
        this.pmManagementService = pmManagementService;
        this.isLoading = true;
        this.subscriptions = {};
    }
    PmRosterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pmManagementService.init();
        this.subscriptions.loading = this.pmManagementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    PmRosterComponent.prototype.ngOnDestroy = function () {
        this.pmManagementService.destroy();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PmRosterComponent.prototype, "subscriptions", void 0);
    return PmRosterComponent;
}());
export { PmRosterComponent };
