import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, SimpleChanges } from '@angular/core';
import { ExportDataConfigurationInfo, ExportDataExecutionItem, ExportDataConfigurationParameter, ExportDataExecutionStatus } from '../../../models';
import { ToolbarSectionTypes } from '../../../../../common';
import { ExportDataManagementService } from '../../../services/export-data-management.service';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { ExportDataStatus } from '../../../enums/export-data-status';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../../../core/decorators';
import * as moment from 'moment';

@Component({
  selector: 'slx-export-data-section',
  templateUrl: './export-data-section.component.html',
  styleUrls: ['./export-data-section.component.scss']
})
export class ExportDataSectionComponent implements OnInit, OnDestroy {

  public isSubmitted: boolean;
  public isCollapsed: boolean = true;
  public get formValid(): boolean {
    return !_.some(this.parameters, x =>  (!x.isValid || (x.dataType.type !== 'hidden' && x.dataType.required && x.value !== undefined)));
  }

  public get isExpired(): boolean {
    return this.configuration.lastExecuted && (this.configuration.lastExecuted.completedOn && this.configuration.lastExecuted.completedOn < moment().subtract(1, 'days').toDate()
                                           || !this.configuration.lastExecuted.completedOn && this.configuration.lastExecuted.startedOn < moment().subtract(1, 'days').toDate());
  }

  @Input() configuration: ExportDataConfigurationInfo;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public parameters: ExportDataConfigurationParameter[];
  constructor(private manService: ExportDataManagementService,
              private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.parameters = _.cloneDeep(this.configuration.parameters);
    this.initParams(this.parameters);

    this.subscriptions.exportExecuted = this.manService.subscribeToExportExecuted((v: ExportDataExecutionItem) => {
      if (!v) {
        return;
      }

      if (this.configuration.id === v.configurationId) {
        this.configuration.lastExecuted = v;
      }
    });

    if (!this.isCollapsed) {
      this.manService.loadExportDataHistory(this.configuration.id);
    }
  }

  ngOnDestroy() {
    this.configuration = null;
    this.isSubmitted = false;
    this.isCollapsed = true;
    this.changeDetector.detach();
  }

  public get exportDataStatus() {
    return ExportDataStatus;
  }

  public get generateIsAllowed(): boolean {
    return this.configuration && (!this.configuration.lastExecuted || (this.configuration.lastExecuted.status !== ExportDataStatus.Waiting &&
                                                                       this.configuration.lastExecuted.status !== ExportDataStatus.InProgress));
  }

  public get cancelIsAllowed(): boolean {
    return this.configuration && !this.generateIsAllowed;
  }

  public collapsedChange(isCollapsed: boolean): void {
    if (!isCollapsed && !this.configuration.history) {
      this.manService.loadExportDataHistory(this.configuration.id);
    }
  }

  private initParams(parameters: ExportDataConfigurationParameter[]): void {
    _.each(parameters, (x) => {
      x.isValid = true;
      x.dataType.required = false;
      if (x.dataType.type === 'bit') {
        x.value = false;
      }

      if (x.dataType.type === 'hidden') {
        switch(x.name) {
          case 'org_level_id': x.value = this.manService.orgLevel.id; break;
        }
      }
    });
  }

  private generate(): void {
    if (this.isSubmitted) {
      return;
    }

    this.isSubmitted = true;
    this.manService.generateExportData(this.configuration.id, this.parameters);
    this.isSubmitted = false;
  }

  private cancelExecution(): void {
    this.manService.cancelExportData(this.configuration.id, this.configuration.lastExecuted.id);
  }
}
