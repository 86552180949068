<div class="toggler" (click)="onAccessClick()">
  <span *ngIf="linkedRightName" tipPosition="bottom" slxTooltip="Menu Item is linked to Action '{{linkedRightName}}'. This Action will be edited also."></span>
  <i [ngClass]="{
    'far': rowItem.mapByRole[roleId].access==='enabled' || rowItem.mapByRole[roleId].access==='disabled',
    'fa-check-square': rowItem.mapByRole[roleId].access==='enabled',
    'fa-square': rowItem.mapByRole[roleId].access==='disabled',
    'fab fa-delicious': rowItem.mapByRole[roleId].access==='mixed',
    'dirty': rowItem.dirtyByRole[roleId]
    }"
    aria-hidden="true"></i>
</div>
