import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { Subscription } from 'rxjs';
import { TreeView } from '../../../../../components-library/models/tree-view';
import { unsubscribe } from '../../../../../core/decorators/index';
import { ApplicationStateBusService, OrgLevelWatchService } from '../../../../../organization/services';
import * as _ from 'lodash';
import { MealDeductionManagementService } from '../../../services';
import { PbjNewExportHierarchyHelper } from '../../pbj-export/pbj-new-export/pbj-new-export-hierarchy-helper';
var PbjExportLocationsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PbjExportLocationsComponent, _super);
    function PbjExportLocationsComponent(orgLevelWatchService, applicationStateBusService, managementService) {
        var _this = _super.call(this) || this;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.applicationStateBusService = applicationStateBusService;
        _this.managementService = managementService;
        _this.orgIds = [];
        _this.config = TreeviewConfig.create({
            hasAllCheckBox: false,
        });
        _this.orgLevelSubscription = _this.applicationStateBusService.subscribeToSelectOrgLevel(function (o) {
            if (_.isObject(o) && _.isNumber(o.id) && (!_this.orgLevel || _this.orgLevel.id !== o.id)) {
                _this.orgLevel = o;
                _this.orgHireacy = _this.setOrgHierarchy(o, 'mealConfigDialog');
                _this.getAllMealConfigOrgIds(o);
                _this.managementService.setSelectedIds(_this.organizationIds);
            }
        });
        return _this;
    }
    PbjExportLocationsComponent.prototype.ngOnInit = function () {
        this.items = this.getItems([this.orgHireacy]);
    };
    PbjExportLocationsComponent.prototype.getItems = function (parentChildObj) {
        var itemsArray = [];
        parentChildObj.forEach(function (set) {
            itemsArray.push(new TreeviewItem(set));
        });
        return itemsArray;
    };
    PbjExportLocationsComponent.prototype.onSelectAll = function () {
        this.checkAll(this.items);
    };
    PbjExportLocationsComponent.prototype.checkAll = function (items) {
        var _this = this;
        items.forEach(function (element) {
            element.checked = true;
            //element.collapsed = false;
            if (element.internalChildren) {
                _this.checkAll(element.internalChildren);
            }
        });
        this.selOrgIds = this.getSelectedValuesRecursive(items);
        this.getAllMealConfigOrgIds(this.orgLevel);
        this.managementService.setSelectedIds(Array.from(new Set(this.organizationIds)));
    };
    PbjExportLocationsComponent.prototype.getSelectedValuesRecursive = function (items) {
        var _this = this;
        var selectedValues = [];
        items.forEach(function (item) {
            if (item.checked) {
                selectedValues.push(item.value);
            }
            if (item.children) {
                var childSelectedValues = _this.getSelectedValuesRecursive(item.children);
                selectedValues.push.apply(selectedValues, childSelectedValues);
            }
        });
        return selectedValues;
    };
    PbjExportLocationsComponent.prototype.getSelectedIds = function (selectedOrgIds) {
        var orgIds = this.managementService.getSelectedIds();
        var modifiedOrgIds = selectedOrgIds.filter(function (orgId) { return orgIds.some(function (id) { return orgId == id; }); });
        this.managementService.setSelectedIds(null);
        this.managementService.setSelectedIds(modifiedOrgIds);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjExportLocationsComponent.prototype, "orgLevelSubscription", void 0);
    return PbjExportLocationsComponent;
}(PbjNewExportHierarchyHelper));
export { PbjExportLocationsComponent };
