import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { destroyService } from './../../../../app/core/decorators';
import { ShiftGroupManagementService } from '../../services/shift-group/shift-group-management.service';
var ShiftGroupComponent = /** @class */ (function () {
    function ShiftGroupComponent(management) {
        this.management = management;
    }
    ShiftGroupComponent.prototype.ngOnInit = function () {
        this.management.init();
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", ShiftGroupManagementService)
    ], ShiftGroupComponent.prototype, "management", void 0);
    return ShiftGroupComponent;
}());
export { ShiftGroupComponent };
