  export interface IAccrualPolicyRule {
    id: number;
    ruleName: string;
    accrualTypeName: string;
    accrualTypeId: string;
  }

export class AccrualPolicyRules {
    public id: number;
    public ruleName: string;
    public accrualTypeName: string;
    public accrualTypeId: string;
  }
