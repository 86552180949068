/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bipa-report-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./bipa-report/bipa-report.component.ngfactory";
import * as i3 from "./bipa-report/bipa-report.component";
import * as i4 from "../../services/reports-management.service";
import * as i5 from "@angular/common";
import * as i6 from "./bipa-report-dialog.component";
import * as i7 from "../../models/report-definition";
import * as i8 from "../../../common/models/dialog-options";
import * as i9 from "../../../common/services/modal/modal.service";
var styles_BipaReportDialogComponent = [i0.styles];
var RenderType_BipaReportDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BipaReportDialogComponent, data: {} });
export { RenderType_BipaReportDialogComponent as RenderType_BipaReportDialogComponent };
export function View_BipaReportDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-bipa-report", [], null, [[null, "hasValueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hasValueChange" === en)) {
        var pd_0 = (_co.onValueChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BipaReportComponent_0, i2.RenderType_BipaReportComponent)), i1.ɵdid(2, 114688, null, 0, i3.BipaReportComponent, [i4.ReportsManagementService], { configData: [0, "configData"], forceLoad: [1, "forceLoad"] }, { hasValueChange: "hasValueChange" }), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "modal-footer wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "btn red pad-top-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel "])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "btn green pad-top-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "disabled-content": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reportDefinition; var currVal_1 = _co.forceLoad; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "btn green pad-top-10"; var currVal_3 = _ck(_v, 10, 0, !_co.hasValueChange); _ck(_v, 9, 0, currVal_2, currVal_3); }, null); }
export function View_BipaReportDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-bipa-report-dialog", [], null, null, null, View_BipaReportDialogComponent_0, RenderType_BipaReportDialogComponent)), i1.ɵdid(1, 49152, null, 0, i6.BipaReportDialogComponent, [i7.BIPAReportDefinition, i7.ConfigForceLoad, i8.DialogOptions, i9.ModalService], null, null)], null, null); }
var BipaReportDialogComponentNgFactory = i1.ɵccf("slx-bipa-report-dialog", i6.BipaReportDialogComponent, View_BipaReportDialogComponent_Host_0, {}, {}, []);
export { BipaReportDialogComponentNgFactory as BipaReportDialogComponentNgFactory };
