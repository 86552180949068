import { Router, ActivatedRoute } from '@angular/router';
import { appConfig } from '../../../app.config';
import * as moment from 'moment';

export class SoConsoleNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToSoConsole(orgLevelId: number = 0): void {
    let params: any;
    if (orgLevelId) {
      params = { orgLevelId: orgLevelId };
    }
    this.router.navigate(['so_console'], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
  }
}
