import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import * as moment from 'moment';
import * as _ from 'lodash';

import { IndSchEmployeeSchedulesRequest } from '../../../models/index';
import { IndividualScheduleEmpManagementService, IndividualScheduleManagementService } from '../../../services/index';
import { unsubscribe, destroyService, mutableSelect } from '../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ScheduleCycle, EmployeeShortInfo } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { LookupApiService, EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { IndividualScheduleNavigationService } from '../../../../common/services/index';
import { MasterScheduleContextMenuRequest } from '../../../models/master-schedule/master-schedule-context-menu-request';

@Component({
  moduleId: module.id,
  selector: 'slx-individual-schedule-employee',
  templateUrl: 'individual-schedule-employee.component.html',
  styleUrls: ['individual-schedule-employee.component.scss'],
  providers: [IndividualScheduleEmpManagementService, IndividualScheduleManagementService]
})
export class IndividualScheduleEmployeeComponent implements OnDestroy, OnInit {
  public state: {
    isLoading: boolean;
  };
  @mutableSelect()
  public orgLevel$: Observable<OrgLevel>;

  public appConfig: IApplicationConfig;
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public currentDate: Date;
  public empInfo: EmployeeShortInfo;
  public hasScheduleViewParams: boolean = false;
  private employeeId: number;
  private orgLevelId: number;
  private get numWeeks(): number {
    let numDays: number = this.endDate.diff(this.startDate, 'days');
    numDays  = Math.ceil(numDays  / 7);
    return numDays ;
  }

  @unsubscribe()
  private routeSubscripion: Subscription;
  @unsubscribe()
  private uiLockSubscription: Subscription;

  @Input()
  public scheduleViewParams: MasterScheduleContextMenuRequest = null;

  @Output()
  public onHasChanges = new EventEmitter<boolean>();

  @destroyService()
  private individualScheduleEmpManagementService: IndividualScheduleEmpManagementService;
  constructor(private route: ActivatedRoute, private router: Router,
    private lookupApiService: LookupApiService, private employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    individualScheduleEmpManagementService: IndividualScheduleEmpManagementService
  ) {
    this.state = { isLoading: false };
    this.individualScheduleEmpManagementService = individualScheduleEmpManagementService;
  }

  public emitChanges(hasChanges: boolean): void {
    this.onHasChanges.emit(hasChanges);
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.uiLockSubscription = this.individualScheduleEmpManagementService.onUILock$.subscribe((isLocked: boolean) => {
      this.state.isLoading = isLocked;
    });
    this.routeSubscripion = this.route.params
      .combineLatest(this.route.queryParams, this.orgLevel$)
      .subscribe(([params, queryParams, orgLevel]) => {
        this.orgLevelId = orgLevel.id;
        let pStartDate: any;
        let pEndDate: any;
        let currentDate: any;
        if (_.isObject(this.scheduleViewParams)) {
          this.hasScheduleViewParams = true;
          this.employeeId = +this.scheduleViewParams.empId;
          pStartDate = this.scheduleViewParams.startDate;
          pEndDate = this.scheduleViewParams.endDate;
        } else {
          this.employeeId = +params['employeeId'];
          pStartDate = queryParams['startDate'];
          pEndDate = queryParams['endDate'];
          currentDate = queryParams['currentDate'];
        }

        if (!pStartDate || !pEndDate) {
          this.getLastScheduleCycle();
          return;
        }
        this.startDate = moment(pStartDate, appConfig.linkDateFormat);
        this.endDate = moment(pEndDate, appConfig.linkDateFormat);
        this.currentDate = _.isString(currentDate) ? moment(currentDate, appConfig.linkDateFormat).toDate() : null;
        this.loadEmployeeInfo();
      });
  }
  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
  private loadEmployeeInfo(): void {
    this.state.isLoading = true;
    this.employeeDefinitionsApiService.getEmployeeShortInfo(this.employeeId)
      .then((info: EmployeeShortInfo) => {
        this.state.isLoading = false;
        this.empInfo = info;
        this.emitRequest();
      })
      .catch(() => {
        this.state.isLoading = false;
      });
  }
  private getLastScheduleCycle(): void {
    if (!this.orgLevelId) {
      return;
    }
    this.state.isLoading = true;
    this.lookupApiService.getScheduleCycles(this.orgLevelId)
      .then((cycles: ScheduleCycle[]) => {
        this.state.isLoading = false;
        let { startDate, endDate }: ScheduleCycle = _.first(_.orderBy(cycles, (cycle: ScheduleCycle) => {
          return cycle.startDate.unix;
        }, 'desc'));
        let navService: IndividualScheduleNavigationService = new IndividualScheduleNavigationService(this.router, this.route);
        navService.NavigateToIndividualScheduleEmp(this.employeeId, startDate.toDate(), endDate.toDate());
      })
      .catch(() => {
        this.state.isLoading = false;
      });
  }

  private emitRequest(): void {
    let req: IndSchEmployeeSchedulesRequest = new IndSchEmployeeSchedulesRequest();
    req.employeeId = this.employeeId;
    req.orgLevelId = this.orgLevelId;
    req.scheduleWeeksCount = this.numWeeks;
    req.startDate = this.startDate.toDate();
    req.employeeName = this.empInfo.name;
    req.currentDate = this.currentDate;
    this.individualScheduleEmpManagementService.onEmployeeRequest(req);
  }
}
