import { LookupEntity, ILookupEntity } from '../../../organization/models/index';
import * as moment from 'moment';
import { IBaseSelectableModel } from '../../../common/models/kendo-grid-helpers/base-selectable-model';
import { IShiftSetting, ShiftSetting } from './shift-setting';

export interface IShift {
  id: number;
  name: string;
  group: ILookupEntity;
  employeesCount: number;
  usedInIdealSchedule: boolean;
  start: string;
  end: string;
  duration: string;
  lunchDuration: string;
  lastUpdateUsername: string;
  lastUpdateDate: string;
  defaultshift: number;
  setDefaultShift: string;
  hasPartialShift: boolean;
  parentShiftId: number;
  partialShiftList:IShift[]
  isUpdated: boolean;
  isDeleted: boolean;
  shiftSetting:IShiftSetting;
}

export class Shift implements IBaseSelectableModel {
  public isNameEdited: boolean = false;

  public lastUpdateUsername: string;
  public lastUpdateDate: Date;

  public id: number;
  public name: string;
  public employeesCount: number;
  public usedInIdealSchedule: boolean;
  public group: LookupEntity;

  public start: Date;
  public end: Date;
  public lunchDuration: number = 0;
  public duration: number = 0;
  public defaultshift: number;
  public hasPartialShift: boolean = false;
  public parentShiftId: number;
  public setDefaultShift: string;
  public partialShiftList : Shift[] = [];
  public deletedPartials: Shift[] = [];    
  public get defaultShiftData(): string {
    return this.setDefaultShift === 'NO' ? 'NO' : 'YES';
  }
  public get defaultPartailShiftFlag(): string {
    return this.hasPartialShift ? 'YES' : 'NO';
  }
  // props for kendo grid filters
  public startTime: number;
  public endTime: number;

  public isSelected: boolean;
  public selectable: boolean = true;
  public isUpdated: boolean = false;
  public isDeleted: boolean = false;
  public isEditing: boolean = false;
  public shiftSetting:ShiftSetting = undefined;
  

  public get groupName(): string {
    return this.group ? this.group.name : '';
  }

  public static createShiftName(start: Date, end: Date): string {

    let name: string = 'new shift';
    if (start && end) {
      let mStart: string = moment(start).format('hh:mm A');
      let mEnd: string = moment(end).format('hh:mm A');

      mStart = mStart.replace(':00', '');
      mStart = mStart.replace('AM', 'A');
      mStart = mStart.replace('PM', 'P');
      mStart = mStart.replace(' ', '');

      mEnd = mEnd.replace(':00', '');
      mEnd = mEnd.replace('AM', 'A');
      mEnd = mEnd.replace('PM', 'P');
      mEnd = mEnd.replace(' ', '');

      name = `${mStart}-${mEnd}`;
    }

    return name;
  }
}
