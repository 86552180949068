
export class ReadFile {
  constructor(
    public name: string,
    public sizeInBytes: number,
    public mimeType: string,
    public extension: string,
    public data: Blob
  ) {}

  public get fileName(): string {
    return `${this.name}.${this.extension}`;
  }
}
