<kendo-grid scrollable="'scrollable'"
  [data]="gridState.view"
  (dataStateChange)="gridState.dataStateChange($event)"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  [groupable]="true"
  [group]="gridState.state.group"
  selectable="true"
  (selectionChange)="gridState.selectionChange($event)"
  [slxKendoGridState]="gridState.gridDefinition"
  (stateRestored)="gridState.dataStateChange($event)"
  [filterable]="true"
  [filter]="gridState.state.filter"
>
  <ng-template kendoGridToolbarTemplate position="top">
    <ng-container *ngIf="!appSettings?.isNotesEnabled else enableNotes">
      <div class="col-xs-12" *ngIf="employeeSectionsAuditTrail?.actions.canAdd && isEditable">
        <div class="row">
         <button class="theme-iconed-accent-button pull-right" (click)="onStartAdd()">
            <span class="icon-button-content">
              <span>Add Note</span>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </span>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #enableNotes>
      <div class="col-xs-12">
        <div class="row">
         <button class="theme-iconed-accent-button pull-right" (click)="onStartAdd()">
            <span class="icon-button-content">
              <span>Add Note</span>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </span>
          </button>
        </div>
      </div>
    </ng-template>
    
  </ng-template>
  <kendo-grid-column width="40">
    <ng-template kendoGridHeaderTemplate>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <button class="remove-button" (click)="onStartRemove(dataItem)" *ngIf="checkCanDelete(dataItem)">
          <i class="fa fa-lg fa-times" aria-hidden="true"></i>
        </button>
      <!-- <button class="edit-button" (click)="onStartModify(dataItem)" *ngIf="checkCanEdit(dataItem)">
      </button> -->
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="typeDescription" title="Type" width="120">
    <ng-template kendoGridHeaderTemplate>
      Type
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.typeDescription}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="subject.fieldValue" title="Subject">
    <ng-template kendoGridHeaderTemplate>
      Subject
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.subject.fieldValue }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="detail.fieldValue" title="Detail">
    <ng-template kendoGridHeaderTemplate>
      Detail
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="dataItem.typeDescription == 'Edit'" class="audit-trail">
        Changed from {{dataItem.before.fieldValue | slxAuditTrail }} to {{dataItem.after.fieldValue | slxAuditTrail }}<span *ngIf="dataItem.effectiveDate"> Effective {{dataItem.effectiveDate | amDateFormat: appConfig.dateFormat }}</span>
      </div>
      <div *ngIf="dataItem.typeDescription == 'Note' || dataItem.typeDescription == 'Journal'" class="audit-trail">
        {{dataItem.detail.fieldValue }}
      </div>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="user" title="User">
    <ng-template kendoGridHeaderTemplate>
      User
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.user }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="changedOn" title="Changed On">
    <ng-template kendoGridHeaderTemplate>
      Changed On
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.changedOn | amDateFormat: appConfig.dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
