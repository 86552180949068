import { Employee, PerformanceReviewCode, IEmployee, IPerformanceReviewCode } from '../../../organization/models/index';
import { PmReviewRecord, IPmReviewRecord } from './pm-review-records';

export interface IEmployeeRosterRecord {
    reviewType: IPerformanceReviewCode;
    rating: IPerformanceReviewCode;
    reviewRecord: IPmReviewRecord;
}

export class EmployeeRosterRecord {
    public reviewType: PerformanceReviewCode;
    public rating: PerformanceReviewCode;
    public status: string;
    public reviewRecord: PmReviewRecord;
}