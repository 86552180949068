import * as _ from 'lodash';
var ActionListComponent = /** @class */ (function () {
    function ActionListComponent() {
        this.fullyCustomStyle = false;
        this.defaultCssClasse = 'slx-action-list';
    }
    Object.defineProperty(ActionListComponent.prototype, "className", {
        get: function () {
            var classes = [];
            if (_.isString(this.customClassName) && _.size(this.customClassName) > 0) {
                classes.push(this.customClassName);
            }
            if (!this.fullyCustomStyle) {
                classes.push(this.defaultCssClasse);
            }
            return classes.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    return ActionListComponent;
}());
export { ActionListComponent };
