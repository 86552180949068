/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./telepunch-locations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./telepunch-locations-mobile/telepunch-locations-mobile.component.ngfactory";
import * as i3 from "./telepunch-locations-mobile/telepunch-locations-mobile.component";
import * as i4 from "../../services/telepunch-locations/telepunch-locations-management.service";
import * as i5 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i6 from "../../../common/components/spinner/spinner.component";
import * as i7 from "./telepunch-locations-toolbar/telepunch-locations-toolbar.component.ngfactory";
import * as i8 from "./telepunch-locations-toolbar/telepunch-locations-toolbar.component";
import * as i9 from "./telepunch-locations-grid/telepunch-locations-grid.component.ngfactory";
import * as i10 from "./telepunch-locations-grid/telepunch-locations-grid.component";
import * as i11 from "../../../common/services/modal/modal.service";
import * as i12 from "@angular/common";
import * as i13 from "../../services/accessManagement/access-management.service";
import * as i14 from "../../../common/services/change-management/change-management.service";
import * as i15 from "../../services/telepunch-locations/telepunch-locations-api.service";
import * as i16 from "../../services/telepunch-locations/telepunch-locations-map.service";
import * as i17 from "./telepunch-locations.component";
var styles_TelepunchLocationsComponent = [i0.styles];
var RenderType_TelepunchLocationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TelepunchLocationsComponent, data: {} });
export { RenderType_TelepunchLocationsComponent as RenderType_TelepunchLocationsComponent };
function View_TelepunchLocationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-telepunch-locations-mobile", [["class", "telepunches-mobile-component"]], null, null, null, i2.View_TelepunchLocationsMobileComponent_0, i2.RenderType_TelepunchLocationsMobileComponent)), i1.ɵdid(1, 114688, null, 0, i3.TelepunchLocationsMobileComponent, [i4.TelepunchLocationsManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_TelepunchLocationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-spinner", [["class", "telepunch-locations"]], null, null, null, i5.View_SpinnerComponent_0, i5.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i6.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-telepunch-locations-toolbar", [], null, null, null, i7.View_TelepunchLocationsToolbarComponent_0, i7.RenderType_TelepunchLocationsToolbarComponent)), i1.ɵdid(4, 49152, null, 0, i8.TelepunchLocationsToolbarComponent, [i4.TelepunchLocationsManagementService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-telepunch-locations-grid", [["class", "telepunches-grid-components"]], null, null, null, i9.View_TelepunchLocationsGridComponent_0, i9.RenderType_TelepunchLocationsGridComponent)), i1.ɵdid(6, 114688, null, 0, i10.TelepunchLocationsGridComponent, [i4.TelepunchLocationsManagementService, i11.ModalService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TelepunchLocationsComponent_1)), i1.ɵdid(8, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 6, 0); var currVal_1 = _co.screenUtil.isMobile; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_TelepunchLocationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-telepunch-locations", [], null, null, null, View_TelepunchLocationsComponent_0, RenderType_TelepunchLocationsComponent)), i1.ɵprd(4608, null, i13.AccessManagementService, i13.AccessManagementService, []), i1.ɵprd(4608, null, i4.TelepunchLocationsManagementService, i4.TelepunchLocationsManagementService, [i13.AccessManagementService, i14.ChangeManagementService, i15.TelepunchLocationsApiService, i16.TelepunchLocationsMapService]), i1.ɵdid(3, 49152, null, 0, i17.TelepunchLocationsComponent, [], null, null)], null, null); }
var TelepunchLocationsComponentNgFactory = i1.ɵccf("slx-telepunch-locations", i17.TelepunchLocationsComponent, View_TelepunchLocationsComponent_Host_0, {}, {}, []);
export { TelepunchLocationsComponentNgFactory as TelepunchLocationsComponentNgFactory };
