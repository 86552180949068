import { RoleProfile, IRoleProfile } from './role-profile';
import { RoleRight, IRoleRight, IRoleRightModule, RoleRightModule } from './role-right';
import { RoleMenuModule, IRoleMenuModule } from './role-menu-module';
import { RoleComponentsModule, IRoleComponentsModule } from './role-components-module';


export interface IRole {
  id: number;
  name: string;
  reportGroup: string;
  profile: IRoleProfile;
  rights: IRoleRight[];
  rightGroups: IRoleRightModule[];
  otherRights: IRoleRight[];
  otherRightGroups: IRoleRightModule[];
  menuModules: IRoleMenuModule[];
  componentModules: IRoleComponentsModule[];
  lastUpdateDate: string;
  lastUpdateUsername: string;
}

export class Role {
  public id: number;
  public name: string;
  public reportGroup: string;
  public profile: RoleProfile;
  public rights: RoleRightModule[];
  public otherRights: RoleRightModule[];
  public menuModules: RoleMenuModule[];
  public componentModules: RoleComponentsModule[];
  public lastUpdateDate: Date;
  public lastUpdateUsername: string;
  public displaySequence? :number;

}


