<div class="col-xs-12">
  <div class="command-container">
    <slx-input-decorator className="slx-no-label" *ngIf="rotationsExists">
      <slx-dropdown-input slx-input [options]="rotations" [(ngModel)]="selectedRotation" (ngModelChange)="selectedRotaionChanged()"
        name="rotationSelector" placeholder="Rotation" valueField="id" titleField='description'>
      </slx-dropdown-input>
    </slx-input-decorator>
    <div class="rotation-button-container" *ngIf="uiState!=uiStates.historicalViewState">
      <button class="theme-icon-button rotation-config-button" name="btnConfigureRotation" [disabled]="!rotationsSection?.actions.canEdit || !hasActiveState" (click)="onEditRotationConfigClicked()">
        <i class="fa fa-cog text-button-content" aria-hidden="true"></i>
      </button>
    </div>
    <div class="additional-button-container">
      <button class="theme-iconed-button restore-rotation-button" *ngIf="uiState==uiStates.historicalViewState" (click)="onRestoreRotationClicked()" [disabled]="!rotationsSection?.actions.canEdit || !hasActiveState"
        type="button"><span class="text-button-content">Restore rotations</span></button>
      <button class="theme-iconed-button import-rotation-button" name="importRotations" id="importRotations" [disabled]="!rotationsSection?.actions.canEdit || !hasActiveState"
        (click)="onImportRotationsClicked()">
        <span class="icon-button-content">
          <span>Import Rotations</span>
          <i class="fa fa-download" aria-hidden="true"></i>
        </span>
      </button>
    </div>
  </div>
</div>
<div class="col-xs-12" *ngIf="uiState!=uiStates.historicalViewState && rotationsExists">
  <div class="row">
    <div class="col-xs-12 ">
      <div class="row rectangle">
        <div class="quick-div">
          <i class="far fa-calendar-plus slx-button__icon quick-edit-btn__c-icon" aria-hidden="true"></i>
          <i class="far fa-hand-point-up slx-button__icon quick-edit-btn__h-icon" aria-hidden="true"></i>
          <span class="slx-button__text">Quick Edit Panel</span>
        </div> 
        <div class="flex-horizontal quick-edit-div">
        <div class="shift-editor">
          <slx-employee-shift-editor *ngIf="rotationsSection" 
            [employeeId]="employeeId"
            [homePositionId]="rotationsSection.homePositionId" 
            [homeShiftId]="rotationsSection.homeShiftId"
            [homeUnitId]="rotationsSection.homeUnitId" 
            [employeeShift]="selectedEmployeeShift" 
            [isReadOnly]="uiState!=uiStates.editShiftTemplateState"
            [loadHomeValues]="loadHomeValues">
          </slx-employee-shift-editor>
        </div>
        <div class="edit-buttons edit-section">
          <button class="slx-button slx-blue slx-with-icon slx-mobile-adapted"
            *ngIf="uiState!=uiStates.editShiftTemplateState && rotationsExists"
            [disabled]="!rotationsSection?.actions.canEdit || !hasActiveState"
            (click)="onEditShiftTemplateClicked()"><i class="fas fa-edit" aria-hidden="true"></i><span class="btn-text">Edit</span></button>
          <button *ngIf="uiState==uiStates.editShiftTemplateState"
            class="slx-button slx-red slx-with-icon slx-margin-r slx-mobile-adapted slx-red"
            id="cancelSave" (click)="onCancelClicked()" type="button"><i class="fa fa-times" aria-hidden="true"></i>
            <span class="btn-text">Cancel</span>
          </button>
          <button *ngIf="uiState==uiStates.editShiftTemplateState"
            class="slx-button slx-with-icon slx-mobile-adapted slx-green" [disabled]="!isValid"
            id="acceptSave" (click)="onAcceptClicked()" type="button"><i class="fa fa-check" aria-hidden="true"></i>
            <span class="btn-text">Save</span>
          </button>
        </div>
      </div>
      </div>
    </div>
  </div>
</div>
<div class="col-xs-12" *ngIf="rotationsExists">
  <slx-employee-rotations [selectedEmployeeShift]="selectedEmployeeShift" [selectedRotation]="selectedRotation" [isInEditMode]="uiState==uiStates.editShiftTemplateState"></slx-employee-rotations>
</div>
