import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TreeNodeModel } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-tree-node-expander',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['tree-node-expander.component.scss'],
  templateUrl: 'tree-node-expander.component.html'
})
export class TreeNodeExpanderComponent {
  @Input() public node: TreeNodeModel;
}
