import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeLeaveManagement = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeLeaveManagement, _super);
    function EmployeeLeaveManagement() {
        var _this = _super.call(this) || this;
        _this.records = [];
        return _this;
    }
    return EmployeeLeaveManagement;
}(EmployeeSectionsBase));
export { EmployeeLeaveManagement };
