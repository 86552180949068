import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ManagementBaseService } from '../../../core/services/index';
import { PunchesApiService } from './punches-api.service';
var PunchLogsManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PunchLogsManagementService, _super);
    function PunchLogsManagementService(apiService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.onExport$ = new ReplaySubject(1);
        return _this;
    }
    PunchLogsManagementService.prototype.export = function () {
        this.onExport$.next(null);
    };
    PunchLogsManagementService.prototype.reloadLogs = function (start, end, employeeId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getDailyPunchLogs(start, end, employeeId)
            .then(function (records) {
            _this.onLoadStatusChanged(false);
            _this.onLoaded(records);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    return PunchLogsManagementService;
}(ManagementBaseService));
export { PunchLogsManagementService };
