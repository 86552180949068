import { Component, HostListener, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ImportedFiles } from './../../../../../app/configuration/models/imported-files/ImportedFiles';
import { IApplicationConfig, appConfig } from './../../../../../app/app.config';
import { dateTimeUtils } from '../../../../../app/common/utils';
import { FileUploadsManagementService } from './../../../../../app/configuration/services/file-uploads/file-uploads-management.service';
import { Router } from '@angular/router';

@Component({
  selector: 'slx-uploaded-file-status',
  templateUrl: './uploaded-file-status.component.html',
  styleUrls: ['./uploaded-file-status.component.scss']
})
export class UploadedFileStatusComponent {

  public filterByModuleName: string = null;
  public filterByImportDate: string = null;
  public appConfig: IApplicationConfig;

  get dateWithTimeFormat(): string {
    return '{0:MM/dd/yyyy HH:mm:ss a}';
  }

  public gridHeight = window.innerHeight - 200;

  @HostListener("window:resize", ['$event'])
  onResize(event) {
    this.gridHeight = event.target.innerHeight - 200;
  }

  constructor(public manService: FileUploadsManagementService, public router: Router) {
    this.appConfig = appConfig;
   }

}
