import { MenuItem } from '../menu-item';

export class MenuItemActivatedEvent {
  public item: MenuItem;
  public subItem: MenuItem;
  constructor(item: MenuItem, subItem: MenuItem) {
    this.item = item;
    this.subItem = subItem;
  }
}
