<slx-spinner [show]="isLoading">
  <div class="slx-high-box leave-management">
    <div class="slx-content-toolbar-indents">
      <slx-lm-roster-toolbar></slx-lm-roster-toolbar>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <ng-container *ngIf="isListView">
        <slx-lm-roster-list></slx-lm-roster-list>
      </ng-container>
      <ng-container *ngIf="!isListView">
        <slx-lm-calendar-view></slx-lm-calendar-view>
      </ng-container>
    </div>
  </div>
</slx-spinner>
