<div class="slx-high-box benefit-coverage-configuration">
  <div class="slx-high-box__body">
    <form novalidate class="slx-full-height grid-indent">
      <kendo-grid #kendoGrid
        class="slx-blue-grid slx-full-height slx-benefit-management-grid benefit-option"
        [data]="gridState?.view"
        [filterable]="'menu'"
        [sortable]="{ mode: 'multiple' }"
        [filter]="gridState.state.filter"
        [skip]="gridState.state.skip"
        [sort]="gridState.state.sort"
        (cancel)="gridState.cancelHandler($event)"
        (edit)="gridState.editHandler($event)"
        (remove)="gridState.removeHandler($event)"
        (save)="gridState.saveHandler($event)"
        (dataStateChange)="gridState.dataStateChange($event)"
      >
        <kendo-grid-command-column width="100" [locked]="true">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="inline-buttons">
              <button kendoGridEditCommand
                type="button"
                class="slx-button slx-only-icon slx-toolbar slx-margin-r"
                *ngIf="isEditMode"
              >
                <i class="fas fa-edit" aria-hidden="true"></i>
              </button>
              <button kendoGridRemoveCommand
                class="slx-button slx-gray slx-only-icon slx-toolbar"
                *ngIf="isEditMode && !isRowEditing"
              >
                <i class="fas fa-trash" aria-hidden="true"></i>
              </button>
              <button kendoGridSaveCommand
                type="button"
                class="slx-button slx-only-icon slx-toolbar slx-margin-r"
                *ngIf="isEditMode"
              >
                  <i class="fas fa-check" aria-hidden="true"></i>
              </button>
              <button kendoGridCancelCommand
                type="button"
                class="slx-button slx-only-icon slx-toolbar"
                *ngIf="isEditMode"
              >
                  <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-column title="Max Contribution" width="200" field="maxContribution">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Max Contribution</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.maxContribution | number: ".2-2"| slxMoney}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-number #maxContributionField="ngModel"
                class="slx-wide slx-input-number"
                [min]="minAmt" [max]="maxAmt"
                [step]="stepcurrency"
                [format]="currencyFormat"
                [decimals]="2"
                [autoCorrect]='false'
                [(ngModel)]="dataItem.maxContribution"
                name="maxContribution">
              </slx-number>
              <span *ngIf="maxContributionField?.errors?.min || maxContributionField?.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Suggested Contribution (%)" width="200" field="suggestedContribution">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Suggested Contribution (%)</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.suggestedContribution | number:'.2'| slxPercent}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-number #suggestedContributionField="ngModel"
                class="slx-wide slx-input-number"
                [min]="min" [max]="max"
                [slxMin]="min" [slxMax]="max"
                [step]="step"
                [decimals]="2" [autoCorrect]="false"
                [(ngModel)]="dataItem.suggestedContribution"
                name="suggestedContribution">
              </slx-number>
              <span *ngIf="suggestedContributionField?.errors?.min || suggestedContributionField?.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Max Matching Grant (%)" width="200" field="maxMatchGrant">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Max Matching Grant (%)</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.maxMatchGrant | number:'.2'| slxPercent}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-number #maxMatchGrantField="ngModel"
                class="slx-wide slx-input-number"
                [min]="min" [max]="max"
                [slxMin]="min" [slxMax]="max"
                [step]="step"
                [decimals]="2"
                [autoCorrect]="false"
                [(ngModel)]="dataItem.maxMatchGrant"
                name="maxMatchGrant">
              </slx-number>
              <span *ngIf="maxMatchGrantField?.errors?.min || maxMatchGrantField?.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Min Contribution (%)" width="200" field="empContribution">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Min Contribution (%)</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.empContribution | number:'.2'| slxPercent}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-number #empContributionField="ngModel"
                class="slx-wide slx-input-number"
                [min]="min" [max]="max"
                [slxMin]="min" [slxMax]="max"
                [step]="step"
                [decimals]="2"
                [autoCorrect]="false"
                [(ngModel)]="dataItem.empContribution"
                name="empContribution">
              </slx-number>
              <span *ngIf="empContributionField?.errors?.min || empContributionField?.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Max Employee Contribution (%)" width="200" field="maxEmpContribution">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Max Employee Contribution (%)</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.maxEmpContribution | number:'.2'| slxPercent}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-number #maxEmpContributionField="ngModel"
                class="slx-wide slx-input-number"
                [min]="min" [max]="max"
                [slxMin]="min" [slxMax]="max"
                [step]="step" [decimals]="2"
                [autoCorrect]="false"
                [(ngModel)]="dataItem.maxEmpContribution"
                name="maxEmpContribution">
              </slx-number>
              <span *ngIf="maxEmpContributionField?.errors?.min || maxEmpContributionField?.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Limit Amount" width="200" field="limitAmount">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Limit Amount</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.limitAmount | number: ".2-2"| slxMoney}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-number #limitAmountField="ngModel"
                class="slx-wide slx-input-number"
                [min]="minAmt" [max]="maxAmt"
                [slxMin]="minAmt" [slxMax]="maxAmt"
                [step]="stepcurrency" [format]="currencyFormat"
                [decimals]="2"
                [autoCorrect]="false"
                [(ngModel)]="dataItem.limitAmount"
                name="limitAmount">
              </slx-number>
              <span *ngIf="limitAmountField?.errors?.min || limitAmountField?.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Compensation Limit" width="200" field="compensationLimit">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Compensation Limit</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.compensationLimit | number: ".2-2"| slxMoney}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-number #compensationLimitField="ngModel"
                class="slx-wide slx-input-number"
                [min]="minAmt" [max]="maxAmt"
                [slxMin]="minAmt" [slxMax]="maxAmt"
                [step]="stepcurrency" [format]="currencyFormat"
                [decimals]="2"
                [autoCorrect]="false"
                [(ngModel)]="dataItem.compensationLimit"
                name="compensationLimit">
              </slx-number>
              <span *ngIf="compensationLimitField?.errors?.min || compensationLimitField?.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>
</div>
