import { UnprocessedTimecardStats, TimecardQueueDialogRequest } from './../../../models/timecards/unprocessed-timecard-stats';
import { TimecardsApiService } from './../../../services/timecards/timecards-api.service';
import { OrgLevel } from './../../../../state-model/models/org-level/org-level';
import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';

import { appConfig, IApplicationConfig } from '../../../../app.config';


@Component({
  moduleId: module.id,
  selector: 'slx-timecard-queue-dialog',
  templateUrl: 'timecard-queue-dialog.component.html',
  styleUrls: ['timecard-queue-dialog.component.scss']
})
export class TimecardQueueDialogComponent implements IDialog {

  public options: DialogOptions;
  public dialogResult: boolean;
  public orgLevelName: string;
  public stats: UnprocessedTimecardStats;
  public appConfig: IApplicationConfig = appConfig;
  public canApprove:boolean = false;
  public approveTimecards:boolean;
  private modalService: ModalService;
  private isLoading: boolean = false;

  public static openDialog(modalService: ModalService, apiService: TimecardsApiService, request: TimecardQueueDialogRequest, callback: (result: boolean) => void): TimecardQueueDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = request.approveTimeCards ? 350 : 300;
    dialogOptions.width = 350;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: TimecardsApiService,
        useValue: apiService
      },
      {
        provide: TimecardQueueDialogRequest,
        useValue: request
      }
    ];

    let dialog: TimecardQueueDialogComponent = modalService.globalAnchor
      .openDialog(TimecardQueueDialogComponent,
      `Pending Timecards`, dialogOptions, resolvedProviders,(result: boolean) => {
        callback(result);
    });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    private apiService: TimecardsApiService,
    private request: TimecardQueueDialogRequest
  ) {
    this.modalService = modalService;
    this.options = options;
    this.orgLevelName = this.request.orgLevel.name;
    this.approveTimecards = this.request.approveTimeCards;
    this.stats = this.request.unprocessedTimecardStats;
    if (this.canRefresh && this.request.unprocessedTimecardStats === null) {
      this.onRefresh();
    }
  }

  public get canRefresh(): boolean {
    return !this.isLoading && !!this.request && this.request.isValid;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onRefresh(): void {
    this.isLoading = true;
    this.apiService.getPendingTimecards(this.request.orgLevel.id, this.request.payCycle.startDate, this.request.payCycle.endDate, this.request.approveTimeCards ? this.request.empIds: null)
    .then((stats: UnprocessedTimecardStats) => {
      this.isLoading = false;
      this.stats = stats;
      stats.pendingRecords === 0 ? this.canApprove = true : this.canApprove = false;
    }).catch((reason: any) => {
      this.isLoading = false;
      this.stats = null;
    });
  }

  public onApprove():void{
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
