export interface IPerformanceManagementConfig {
 settings: {
  minDateString: string;
 };
 files: {
    acceptedTypes: string[];
    maxFileSizeBytes: number;
    maxFiles: number;
  };
  api: {
    pm: {
      root: string;
      roster: string;
      employee: string;
      review: string;
      attachment: string;
    },
    configuration: {
      root: string;
      pm: {
        root: string;
        pmCode: {
          root: string;
        },
        templates: {
          root: string;
          attachments: string;
        }
      }
    }
  };
}

export const pmConfig: IPerformanceManagementConfig = {
  settings: {
    minDateString: '01/01/1900'
  },
  files: {
    acceptedTypes: ['.csv', '.doc', '.docm', '.docx', '.gif', '.jpeg', '.jpg', '.pdf', '.png', '.tiff', '.tif', '.txt', '.xls', '.xlsm', '.xlsx'],
    maxFileSizeBytes: 10485760,
    maxFiles: 100
  },
  api: {
    pm: {
      root: 'pm',
      roster: 'roster',
      employee: 'employee',
      review: 'review',
      attachment: 'attachment'
    },
    configuration: {
      root:'configuration',
      pm: {
        root: 'pm',
        pmCode: {
          root: 'codes'
        },
        templates: {
          root:'templates',
          attachments: 'attachments'
        }
      }
    }
  }
};
