import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { RecaptchaMapService } from './recaptcha-map.service';
import { Meta } from '../../../core/models/api/meta';
import { Assert } from '../../../framework/index';
import { ApiUtilService } from '../api/api-util.service';
import { RecaptchaInfo, RecaptchaInfoDto } from '../../../common/index';

@Injectable()
export class RecaptchaApiService {
  constructor(private recaptchaMapService: RecaptchaMapService, private apiUtilService: ApiUtilService) {
    Assert.isNotNull(recaptchaMapService, 'versionMapService');
    Assert.isNotNull(apiUtilService, 'apiUtilService');
  }

  public getInfo(): Promise<RecaptchaInfo> {
    const url: string = `${this.getApiRoot()}/technical/recaptcha/info`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<any> = this.apiUtilService.requestUnauthorized<any, Meta>(request, '')
      .then((response: ResponseBody<RecaptchaInfoDto, Meta>) =>
        this.recaptchaMapService.mapInfo(response.data));

    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
