import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AppUserSettingsApiService } from './app-user-settings-api.service';
import { AppUserSetting } from '../../models/index';
import { appSettingsConfig, IAppSettingGroupConfig, IAppSettingConfig } from '../../app-settings.config';

@Injectable()
export class AppUserSettingsService {

  private restoredGroups: StringMap<any>;
  constructor(private appUserSettingsApiService: AppUserSettingsApiService) {
    this.restoredGroups = {};
  }

  public isLocalOrRestoredGroup(group: string, forceReload?: boolean): boolean {

    if (!forceReload && this.restoredGroups[group]) {
      return true;
    }
    if (!appSettingsConfig[group]) {
      return true;
    }
    let grp: IAppSettingGroupConfig = appSettingsConfig[group];
    let result = true;
    _.forOwn(grp.fields, (field: IAppSettingConfig) => {
      if (field.serverSide) {
        result = false;
        return false;
      }
      return true;
    });
    return result;
  }

  public getConfigurationByServerNames(serverGroupName: string, serverName: string): IAppSettingConfig {
    let localGroupName: string = _.findKey(appSettingsConfig, (group: IAppSettingGroupConfig) => {
      return group.serverName === serverGroupName;
    });
    if (!localGroupName) {
      return null;
    }
    let localName: string = _.findKey(appSettingsConfig[localGroupName].fields, (field: IAppSettingConfig) => {
      return field.serverName === serverName;
    });
    if (!localName) {
      return null;
    }
    return appSettingsConfig[localGroupName].fields[localName];
  }

  public getServerGroupName(group: string): string {
    if (!appSettingsConfig[group]) {
      return null;
    }
    return appSettingsConfig[group].serverName;
  }

  public getConfigurationByLocalNames(group: string, name: string): IAppSettingConfig {
    if (!appSettingsConfig[group]) {
      return null;
    }
    return appSettingsConfig[group].fields[name];
  }

  public saveToServer(settings: AppUserSetting[]): void {
    this.appUserSettingsApiService.saveSettings(settings)
      .then((res: any) => {
        //todo
      })
      .catch((res: any) => {
        //todo
      });
  }

  public getFromServer(group: string): Promise<AppUserSetting[]> {
    return this.appUserSettingsApiService.getSettings(group).then((value: AppUserSetting[]) => {
      this.restoredGroups[group] = true;
      return value;
    });
  }

  public getAllFromServer(): Promise<AppUserSetting[]> {
    return this.appUserSettingsApiService.getSettings();
  }
}
