import { ReadFile, PmAttachment } from '../../../organization/models';

export class PmFile {
  
  public readonly isAttachment: boolean;
  public readonly currentUserName: string;


  constructor (public sourceItem: PmAttachment | ReadFile, currentUserName: string = null) {
    this.currentUserName = currentUserName;
    this.isAttachment = this.sourceItem instanceof PmAttachment;
  }

  public get id(): number {
    return this.isAttachment ? (this.sourceItem as PmAttachment).id : null;
  }

  public get name(): string {
    return this.sourceItem.name;
  }

  public get fileName(): string {
    return this.sourceItem.fileName;
  }

  public get addedBy (): string {
    return this.isAttachment ? (this.sourceItem as PmAttachment).addedBy : this.currentUserName;
}
}
