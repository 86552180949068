import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common';
import * as moment from 'moment';
import { process } from '@progress/kendo-data-query';
import { sectionType, SlateSection } from '../../models';
import { SlateMessagesApiService } from '../../services/slate-messages-api.service';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { Observable } from 'rxjs';
import { appConfig } from '../../../../app.config';
import { SlateMessagesManagementService } from '../../services/slate-messages-management.service';
var SlateMessageListComponent = /** @class */ (function () {
    function SlateMessageListComponent(slateMessagesApiService, slateMessagesManagementService) {
        this.slateMessagesApiService = slateMessagesApiService;
        this.slateMessagesManagementService = slateMessagesManagementService;
        this.sectionTypeStrings = ['lessthanmonth', 'olderthanonemonth', 'olderthanthreemonth', 'loadolderThan6Month'];
        this.sectionTypes = [];
        this.messages = [];
        this.subscriptions = {};
        this.updateMessagesCount = new EventEmitter();
    }
    SlateMessageListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setSlateSections();
        this.subscribeToOrgLevel();
        this.slateMessagesManagementService.isMessagesDataUpdated$.subscribe(function (isDataUpdated) {
            if (isDataUpdated) {
                _this.loadMessages();
            }
        });
    };
    SlateMessageListComponent.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        this.isLoading = true;
        this.subscriptions.orgLevel = this.orgLevel$
            .subscribe(function (orgLevel) {
            if (!!orgLevel && orgLevel.id) {
                _this.isLoading = false;
                _this.orgLevel = orgLevel;
                _this.loadMessages();
            }
        });
    };
    SlateMessageListComponent.prototype.loadMessages = function () {
        var sectionType = this.sectionTypes.find(function (type) { return type.sectionType == 'lessthanmonth'; });
        var startDate = this.startDate(sectionType);
        var endDate = this.endDate(sectionType);
        this.getSlateMessages(sectionType, startDate, endDate);
    };
    SlateMessageListComponent.prototype.setSlateSections = function () {
        var _this = this;
        this.sectionTypeStrings.forEach(function (type) {
            var sectionType = {
                isCollapsed: true,
                sectionTypeText: _this.getAccordianlevel(type),
                sectionType: type,
                messages: []
            };
            _this.sectionTypes.push(sectionType);
        });
    };
    SlateMessageListComponent.prototype.getSlateMessages = function (type, startDate, endDate) {
        var _this = this;
        this.isLoading = true;
        this.slateMessagesApiService.getSlateMessageGroupsWithDateRange(this.orgLevel.id, startDate, endDate).then(function (data) {
            if (type.sectionType == sectionType.lessThanOneMonth) {
                _this.messages = data;
                _this.updateCount();
            }
            else {
                type.messages = data;
                _this.updateCount();
            }
            _this.isLoading = false;
            _this.gridState = new KendoGridStateHelper();
            _this.gridState.view = null;
            _this.refreshGrid();
        });
    };
    SlateMessageListComponent.prototype.collapsedChange = function (event, type) {
        if (type.messages.length == 0) {
            var startDate = this.startDate(type);
            var endDate = this.endDate(type);
            this.getSlateMessages(type, startDate, endDate);
        }
        type.isCollapsed = !type.isCollapsed;
    };
    SlateMessageListComponent.prototype.updateCount = function () {
        var count = 0;
        count = this.messages.length;
        this.sectionTypes.forEach(function (type) {
            count += type.messages.length;
        });
        this.updateMessagesCount.emit(count);
    };
    SlateMessageListComponent.prototype.startDate = function (slateSection) {
        if (sectionType.lessThanOneMonth == slateSection.sectionType) {
            return moment().subtract(1, 'month').toDate();
        }
        else if (sectionType.olderThanOneMonth == slateSection.sectionType) {
            return moment().subtract(3, 'month').toDate();
        }
        else if (sectionType.olderThanThreeMonth == slateSection.sectionType) {
            return moment().subtract(6, 'month').toDate();
        }
        else if (sectionType.olderThanSixMonth == slateSection.sectionType) {
            return moment(appConfig.minCorrectDate).toDate();
        }
    };
    ;
    SlateMessageListComponent.prototype.endDate = function (slateSection) {
        if (sectionType.lessThanOneMonth == slateSection.sectionType) {
            return moment().add(1, 'day').toDate();
        }
        else if (sectionType.olderThanOneMonth == slateSection.sectionType) {
            return moment().subtract(1, 'month').toDate();
        }
        else if (sectionType.olderThanThreeMonth == slateSection.sectionType) {
            return moment().subtract(3, 'month').toDate();
        }
        else if (sectionType.olderThanSixMonth == slateSection.sectionType) {
            return moment().subtract(6, 'month').toDate();
        }
    };
    SlateMessageListComponent.prototype.refreshGrid = function () {
        if (!this.sectionTypeStrings) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process([''], this.gridState.state);
    };
    SlateMessageListComponent.prototype.getAccordianlevel = function (item) {
        switch (item) {
            case 'lessthanmonth': {
                return 'less than a month';
            }
            case 'olderthanthreemonth': {
                return 'Older than three months';
            }
            case 'olderthanonemonth': {
                return 'Older than one month';
            }
            case 'loadolderThan6Month': {
                return 'Older than six months';
            }
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Object)
    ], SlateMessageListComponent.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], SlateMessageListComponent.prototype, "orgLevel$", void 0);
    return SlateMessageListComponent;
}());
export { SlateMessageListComponent };
