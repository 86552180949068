import { EmployeeSectionsBase } from '../employee-sections-base';
import { FieldData, FieldsMeta } from '../../../../core/models/index';
import { EmpType, MaritalStatus, Gender, Race, VeteranStatus, DisabilityStatus } from '../../../../organization/models/index';

export interface IEmployeeSectionsProfile {
  fullName: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  type: string;
  organization: {
    id: number;
    name: string;
  };
  department: {
    id: number;
    name: string;
  };
  position: {
    id: number;
    name: string;
  };
  hireDate: string;
  birthDate: string;
  phone: string;
  mobilePhone: string;
  gender: {
    value: string;
  };
  race: {
    value: string;
  };
  maritalStatus: {
    value: string;
  };
  veteranStatus: string;
  status: string;
  terminationDate: Date;
  rehireDate: Date;
  disabilityStatus: string;
  createdThroughTransfer: boolean;
  isTransferPending: boolean;
  isLatestTerminate?: boolean;
  isPayrollVisible: boolean;
}

export class EmployeeSectionsProfile extends EmployeeSectionsBase {
  public fullName: FieldData<string>;
  public firstName: FieldData<string>;
  public lastName: FieldData<string>;
  public middleName: FieldData<string>;
  public suffix: FieldData<string>;
  public employeeType: FieldData<EmpType>;
  public organizationId: number;
  public organization: FieldData<string>;
  public departmentId: number;
  public department: FieldData<string>;
  public positionId: number;
  public position: FieldData<string>;
  public hireDate: FieldData<Date>;
  public birthDate: FieldData<Date>;
  public phone: FieldData<string>;
  public mobilePhone: FieldData<string>;
  public maritalStatus: FieldData<MaritalStatus>;
  public race: FieldData<Race>;
  public gender: FieldData<Gender>;
  public canTransfer: boolean;
  public canTerminate: boolean;
  public canCancelTerminate: boolean;
  public status: string;
  public terminationDate: Date;
  public canRehire: boolean;
  public rehireDate: Date;
  public disabilityStatus: FieldData<DisabilityStatus>;
  public veteranStatus: FieldData<VeteranStatus>;
  public veteranStatusId: string;
  public createdThroughTransfer: boolean;
  public isTransferPending: boolean;
  public isLatestTerminate: boolean;
  public isPayrollVisible: boolean;

  public get isTerminated(): boolean {
    return this.status.toUpperCase() === 'TERMINATED';
  }
  public get isTransferred(): boolean {
    return this.status.toUpperCase() === 'TRANSFERRED';
  }
  public get isRehired(): boolean {
    return this.status.toUpperCase() === 'REHIRED';
  }
  public get isFutureRehire(): boolean {
    return this.status.toUpperCase() === 'FUTURE REHIRE';
  }

  public fieldsMeta: FieldsMeta;

  public areBenefitsFieldsDirty(): boolean {
    return this.birthDate.isDirty;
  }
}
