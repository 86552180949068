export interface IPayRuleDefinition {
 name: string;
 description: string;
 color: number;
 order: number;
}

export class PayRuleDefinition {
 public name: string;
 public description: string;
 public color: number;
 public order: number;
 public get uniqName(): string {
   return `uniq${this.name}`;
 }
}
