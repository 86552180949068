import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { DetailsPartner } from '../../../models';
import { AgencyShiftDetails } from '../../../models/agency-staffing-console/agency-shift-details';
import { RowGroupingDisplayType, GridSizeChangedEvent } from '@ag-grid-community/core';
import { AgencyStaffingConsoleApiService } from '../../../services/agency-staffing-console/agency-staffing-console-api.service';
import { AgencyShiftSummary, AgencyShiftSummaryDetails } from '../../../models/agency-staffing-console/agency-shift-short-details';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { EmployeeSectionNavigationService } from '../../../../common/services';
import { FirstDataRenderedEvent } from '@ag-grid-community/core';
import * as jspdf from 'jspdf';
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'slx-agency-staffing-console-grid',
  templateUrl: './agency-staffing-console-grid.component.html',
  styleUrls: ['./agency-staffing-console-grid.component.scss']

})
export class AgencyStaffingConsoleGridComponent implements OnInit, OnChanges {
  @ViewChildren("agGrid") agGrid: QueryList<ElementRef>;

  @Input()
  public filters: any[];

  @Input()
  public selectedAgency: any[];

  @Input()
  public agency: DetailsPartner;

  @Input()
  public shiftSummary: AgencyShiftSummary[];

  @Input()
  public filterEnabled: boolean;

  @Output()
  public selectAllRecords: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public exportRecords: EventEmitter<AgencyShiftDetails[]> = new EventEmitter<AgencyShiftDetails[]>();


  @Input()
  public exportToExcel: string[];

  @Input()
  public shiftDetails: AgencyShiftDetails[]

  @Input()
  public filterStartDate: any;

  @Input()
  public filterEndDate: any;

  public agencyId: string;
  public partnerId: string;
  public agencyHeader: string;
  public pending: boolean;
  public assigned: boolean;
  public requested: boolean;
  public cancelled: boolean;
  public failed:boolean;
  public processedPartner: string[] = [];
  public rowHeight = 40;
  public records: AgencyShiftDetails[] = [];
  public shiftStateCount: AgencyShiftSummaryDetails[] = [];
  public gridOptions = {
    defaultColDef: {
      resizable: true,
      suppressSizeToFit: true
    },
  }
  
  columnDefs: ColDef[] = [    
  { field: "state", resizable: true,  cellStyle: { 'padding': "6px" },width: 150,cellClass: "cell-border cell-vertical-align-text-right", filter: 'agTextColumnFilter', cellRenderer: function (params) {
    let backgroundColor;
    if (params.value == "Assigned") {
      backgroundColor = "#0092cb";
    }
    else if (params.value == "Cancelled") {
      backgroundColor = "#b9422c";
    }
    else if (params.value == "Pending") {
      backgroundColor = "#8fad15";
    }
    else if (params.value == "Pending Action") {
      backgroundColor = "#f68d2e";
    }else if(params.value == "Failed"){
      backgroundColor = "red";
    }
    return '<i class="slx-square" style=" width: 10px;height: 10px;padding: 2px 2px 2px 2px;margin-right: 5px; background-color:' + backgroundColor + ';box-sizing: border-box;float: left;margin-top: 8px;"></i>' + params.value + ''
  }},
  { field: "employeeName", width: 300, cellStyle: { 'padding': "6px" }, filter: 'agTextColumnFilter', resizable: true, cellRenderer: this.createHyperLink.bind(this) },
  {field: "shiftDate", cellStyle: { 'padding': "6px" }, width: 150, filter: 'agDateColumnFilter',
  filterParams: {
    comparator: function(filterLocalDateAtMidnight, cellValue) {
       if (cellValue === null) return -1;
       let cellDate = new Date(cellValue);
       if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
         return 0;
       }
      if (cellDate < filterLocalDateAtMidnight) {
          return -1;
      }
     if (cellDate > filterLocalDateAtMidnight) {
         return 1;
     }
  },
   browserDatePicker: true
},
  resizable: true, cellRenderer: function (params) {
      return moment(params.value).format('MM/DD/YYYY');
    }  },
  { field: "shift", width: 200, cellStyle: { 'padding': "6px" }, resizable: true,filter: 'agTextColumnFilter' },
  { field: "hours", width: 150,  cellStyle: { 'padding': "6px" },filter: 'agTextColumnFilter' }, 
  { field: "position", cellStyle: { 'padding': "6px" }, width: 200,resizable: true, filter: 'agTextColumnFilter' },
  { field: "unit", width: 150, cellStyle: { 'padding': "6px" }, filter: 'agTextColumnFilter' },
  { field: "attributes", headerName:"Agency Attributes", cellStyle: { 'padding': "6px" }, resizable: true, width: 300, filter: 'agTextColumnFilter' },
  { field: "workedHours", width: 150, resizable: true, cellStyle: { 'padding': "6px" },filter: 'agTextColumnFilter' },
  { field: "mealBreakDuration", width: 150, resizable: true, cellStyle: { 'padding': "6px" },filter: 'agTextColumnFilter' },
  { field: "cancellationType",headerName:'Cancellation Type', cellStyle: { 'padding': "6px" }, resizable: true, width: 200, filter: 'agTextColumnFilter' },
  { field: "cancellationDate", headerName:'Cancellation Date ', cellStyle: { 'padding': "6px" }, resizable: true, width: 200, 
  filter: 'agDateColumnFilter',
  filterParams: {
    comparator: function(filterLocalDateAtMidnight, cellValue) {
       if (cellValue === null) return -1;
       let cellDate = new Date(cellValue);
       if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
         return 0;
       }
      if (cellDate < filterLocalDateAtMidnight) {
          return -1;
      }
     if (cellDate > filterLocalDateAtMidnight) {
         return 1;
     }
  },
   browserDatePicker: true
},
  cellRenderer: function (params) {
   if(params.value){
    return moment(params.value).format('MM/DD/YYYY');
   }
   return 
  }  }];

 
  public groupDisplayType: RowGroupingDisplayType = 'groupRows';
  public rowData: any[] = [];
  public gridApi: GridApi[] = [];
  public filterAll: boolean = true;
  public spreadsheets: any[] = [];
  public filteredRecords: AgencyShiftDetails[] = [];
  public totalCount: number = 0;
  static agencyCount:number=0;
  public firstAgency:boolean = false;
  assignedCount: number= 0;
  pendingCount: number= 0;
  cancelledCount: number= 0;
  pendingActionCount:number= 0;
  failedCount:number=0;
  constructor(private router: Router, private route: ActivatedRoute, private agencyConsoleApiService: AgencyStaffingConsoleApiService) { 
    AgencyStaffingConsoleGridComponent.agencyCount=0;
  }

  ngOnInit() {
    
   
    if ( this.agency.agency_name == null ) {
      this.agencyHeader = this.agency.partner_id;
      this.agencyId = this.agency.partner_id;
    } else {
      this.agencyHeader = this.agency.agency_name;
      this.agencyId = this.agency.agency_id.toString();
    }
   
    this.partnerId = this.agency.partner_id;
  }
  onGridReady(params, partnerId) {
    this.gridApi[partnerId] = params.api;
    this.gridApi[partnerId].sizeColumnsToFit();
    AgencyStaffingConsoleGridComponent.agencyCount++;
    if(AgencyStaffingConsoleGridComponent.agencyCount== 1) {
      this.changeExpandedIconState(this.agencyId);
      document.getElementById('button_'+this.agencyId).setAttribute("aria-expanded", 'true');
      this.firstAgency= true;
    }

  }
  public ngOnChanges() {
    this.processedPartner = [];  
    if ( this.agency.agency_name == null ) {
      this.agencyId = this.agency.partner_id;
    } else {
      this.agencyId = this.agency.agency_id.toString();
    }

    this.partnerId = this.agency.partner_id;
    this.getAgencyShiftSummary();
    this.getShiftDetails();
    this.addFilterHighlighters();
    this.shiftStatusFilter();

  }


  public onFirstDataRedndered(event: FirstDataRenderedEvent) {
    event.api.sizeColumnsToFit();
  }
  onGridSizeChanged(params: GridSizeChangedEvent) {
    params.api.sizeColumnsToFit();
  }
  public getShiftDetails() {
    this.records = this.shiftDetails.filter(record => record.partnerId == this.partnerId)

  }
  public createHyperLink(params): any {
    if (!params.data) { return; }
    if (params.value == "Pending Agency Open Shift Request" || params.value == "Response Required For Agency Open Shift Request") { return params.value; }
    const spanElement = document.createElement('span');
    spanElement.innerHTML = `<a href="" > ${params.value} </a>`;
    spanElement.addEventListener('click', ($event) => {
      $event.preventDefault();

      let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
      navService.NavigateToEmployeeSections(params.data.empId, true);
    });
    return spanElement;
  }

  
  public addFilterHighlighters(): void {

    if (this.filters.length > 0) {
      this.filters.forEach(filter => {
        if (filter[0].filterName == 'all') {
          this.filterAll = true;
          this.pending = this.assigned = this.requested = this.cancelled = this.failed = false;

        }
        else {
          this.filterAll = false;
          if (filter[0].filterName == 'pending') {
            this.pending = filter[0].checked;
          }
          if (filter[0].filterName == 'assigned') {
            this.assigned = filter[0].checked;
          }
          if (filter[0].filterName == 'cancelled') {
            this.cancelled = filter[0].checked;
          }
          if (filter[0].filterName == 'failed') {
            this.failed = filter[0].checked;
          }
          if (filter[0].filterName == 'requested') {
            this.requested = filter[0].checked;
          }
        }
      });
    }
    
  }
  public getAgencyShiftSummary() {
    let shiftState = ['Assigned', 'Pending', 'Pending Action', 'Failed', 'Cancelled']
    this.shiftSummary.map(x => {
      if (x.partnerId === this.partnerId) {
        this.totalCount = x.totalCount;
        this.processedPartner.push(this.partnerId);
        this.shiftStateCount = [];
        shiftState.forEach(state => {
          let summary: AgencyShiftSummaryDetails = new AgencyShiftSummaryDetails();
          summary.partnerId = this.partnerId;
          summary.state = state;
          let data = x.agencyShiftCount.find(e => e.state == state);
          if (data !== null && data !== undefined) {
            summary.shiftCount = data.shiftCount;
          }
          else {
            summary.shiftCount = 0;
          }
          this.shiftStateCount.push(summary);
        });    
      
      }
      else {
        if (!this.processedPartner.includes(this.partnerId)) {
          this.shiftStateCount = [];
          this.totalCount = 0;
          shiftState.forEach(state => {
            let summary: AgencyShiftSummaryDetails = new AgencyShiftSummaryDetails();
            summary.partnerId = this.partnerId;
            summary.shiftCount = 0;
            summary.state = state

            this.shiftStateCount.push(summary);
          });
        }
      }
    });
   this.shiftStateCount.filter(e=>{  if(e.state=="Assigned"){this.assignedCount= e.shiftCount}});
   this.shiftStateCount.filter(e=>{  if(e.state=="Pending"){this.pendingCount= e.shiftCount} });
   this.shiftStateCount.filter(e=>{  if(e.state=="Cancelled"){this.cancelledCount= e.shiftCount;}});
   this.shiftStateCount.filter(e=>{  if(e.state=="Failed"){this.failedCount= e.shiftCount;}});
   this.shiftStateCount.filter(e=>{  if(e.state=="Pending Action"){this.pendingActionCount= e.shiftCount;} });
  }

  public changeExpandedIconState(agencyId)
  {
    var element = document.getElementById('button_'+agencyId).getAttribute("aria-expanded") ;
    if(element)
    {
      if(element === "true")
      {
        var icon = document.getElementById(agencyId+'_icon');
        if(icon)
        {
          icon.classList.remove("fa-angle-up");
          icon.classList.add("fa-angle-down");
        }
      }
      else
      {
        var icon = document.getElementById(agencyId+'_icon');
        if(icon)
        {
          icon.classList.remove("fa-angle-down");
          icon.classList.add("fa-angle-up");
        }
      }
    }    
  }


  onExcelExport(partnerId) {
    let fileName = "AgencyStaffingConsole_"+this.agencyHeader.trim()+'_'+this.filterStartDate+"-"+this.filterEndDate+'.xlsx';
    this.gridApi[partnerId].exportDataAsExcel({ fileName: fileName, sheetName: fileName });


  }

  exportExcel() {
    if (this.exportToExcel.length > 0) { }
  }
  
  public captureScreen(partnerId) 
  {
    let filteredData : any[] = [];
    this.gridApi[partnerId].forEachNodeAfterFilter(node => {
      filteredData.push(node.data);
    })

    let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
    let current = new Date();
    let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
    let cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
    let dateTime = cDate + ' ' + cTime;

    pdf.setFontSize(10);
    pdf.text("Downloaded On: "+dateTime, 5, 5);

    pdf.setFontSize(10);
    pdf.text("Date Filter: "+this.filterStartDate+" - "+this.filterEndDate, 5, 15);

    pdf.setFontSize(14);
    pdf.text(this.agencyHeader.trim(), 5, 25);

    pdf.setFontSize(14);
    pdf.text("Total Agency Staff: "+ this.totalCount, 5, 35);

    // *********** Prepare Partner Info Table ****************
    //prepare grid columns to be displayed
    var partnerCol = [];
    partnerCol.push(["State", "Count"]);
    var partnerRows = [];

    //prepare grid data
    this.shiftStateCount.filter(x => x.partnerId === partnerId).forEach(item =>{
      var temp = [item.state, item.shiftCount];
      partnerRows.push(temp);
    });

    //prepare and add table to the pdf file
    autoTable(pdf, {
      startY: 40,
      theme: 'striped',
      pageBreak: 'auto',
      tableWidth: 'auto',
      margin: {top: 5, right:5, bottom: 5, left: 5},
      head: partnerCol,
      body: partnerRows
    })
    
    // ********* Prepare Shift Details Table ****************
    //prepare grid columns to be displayed
    var col = [];
    col.push(["State", "Employee Name", "Shift Date", "Shift", "Hours", "Position", "Unit", "Attributes", "Worked Hours", "Meal Break Duration", "Cancellation Type", "Cancellation Date"]);
    var rows = [];

    //prepare grid data
    filteredData.forEach(item =>{
      var temp = [item.state, 
                  item.employeeName, 
                  item.shiftDate ? moment(item.shiftDate).format('MM/DD/YYYY') : "", 
                  item.shift, 
                  item.hours, 
                  item.position, 
                  item.unit, 
                  item.attributes, 
                  item.workedHours, 
                  item.mealBreakDuration, 
                  item.cancellationType, 
                  item.cancellationDate ? moment(item.cancellationDate).format('MM/DD/YYYY') : ""];
      rows.push(temp);
    });

    if(rows.length == 0)
    {
      rows.push([{ content: 'No records found.', colSpan: 10, styles: { halign: 'center' } }]);
    }

    //prepare and add table to the pdf file
    autoTable(pdf, {
      theme: 'grid',
      pageBreak: 'auto',
      tableWidth: 'auto',
      margin: {top: 10, right:5, bottom: 10, left: 5},
        styles: {overflow: 'linebreak', fontSize: 8},
        columnStyles: {
          0: {cellWidth: 18},
          1: {cellWidth: 20},
          2: {cellWidth: 20},
          3: {cellWidth: 22},
          4: {cellWidth: 12},
          5: {cellWidth: 10},
          6: {cellWidth: 10},
          7: {cellWidth: 20},
          8: {cellWidth: 14},
          9: {cellWidth: 14},
          10: {cellWidth: 20},
          11: {cellWidth: 20}
        },
      head: col,
      body: rows
    })

    pdf.save('AgencyStaffingConsole_'+this.agencyHeader.trim()+'_'+this.filterStartDate+"-"+this.filterEndDate+'.pdf'); // Generated PDF
  }

  public shiftStatusFilter(): void {
    let recordState: boolean = false;

    if (this.filterAll) {
      this.rowData = this.records;
    }
    else {
      this.rowData = [];
      this.records.forEach(record => {

        recordState = false;
        if (record.state == 'Assigned' && this.assigned) {

          recordState = true;
        }
        else if (record.state == 'Pending' && this.pending) {
          recordState = true;
        }
        else if (record.state == 'Pending Action' && this.requested) {
          recordState = true;
        }
        else if (record.state == 'Cancelled' && this.cancelled) {
          recordState = true;
        }else if(record.state == 'Failed' && this.failed){
          recordState = true;
        }
        if (recordState) {
          this.rowData.push(record);
        }
      });
    }
    if (this.rowData.length == 0 && !(this.requested || this.cancelled || this.pending || this.assigned || this.failed)) {
      this.rowData = this.records;
      this.selectAllRecords.emit(true);
    }
    this.exportRecords.emit(this.rowData);
  }
}


