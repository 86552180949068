import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { WcReport, WcInjuryReport, WcLookupsContainer } from '../../../models';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Lookup, Employee } from '../../../../../organization/models';
import { FormGroup, NgModel } from '@angular/forms';
import { wcConfig } from '../../../workers-compensation.config';
var WcInjuryReportComponent = /** @class */ (function () {
    function WcInjuryReportComponent(management) {
        this.management = management;
        this.min = 0;
        this.max = 99999999.99;
        this.enableOsha = false;
        this.enableWorkersComp = false;
        this.subscriptions = {};
        this.phoneMaskConf = this.phoneMaskConf = wcConfig.settings.phoneMask;
        this.m_minimumDate = moment(wcConfig.settings.minDateString).toDate();
    }
    Object.defineProperty(WcInjuryReportComponent.prototype, "authorName", {
        get: function () {
            if (this.injury && this.injury.user) {
                return this.injury.user.name || this.injury.user.login;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcInjuryReportComponent.prototype, "isCreatingNew", {
        get: function () {
            return this.report && !_.isFinite(this.report.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcInjuryReportComponent.prototype, "isOshaSwitchEnabled", {
        get: function () {
            return this.isCreatingNew ? !this.report.editMode : !this.report.editMode || this.injury.isOSHARecordable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcInjuryReportComponent.prototype, "isOshaSwitchDisabled", {
        get: function () {
            return this.isCreatingNew ? !this.report.editMode : !this.report.editMode || (this.enableOsha ? false : this.injury.isOSHARecordable);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcInjuryReportComponent.prototype, "isWCSwitchEnabled", {
        get: function () {
            return this.isCreatingNew ? !this.report.editMode : !this.report.editMode || this.injury.isWorkersCompCase;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcInjuryReportComponent.prototype, "isWCSwitchDisabled", {
        get: function () {
            return this.isCreatingNew ? !this.report.editMode : !this.report.editMode || (this.enableWorkersComp ? false : this.injury.isWorkersCompCase);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcInjuryReportComponent.prototype, "injury", {
        get: function () {
            return this.report ? this.report.injuryReport : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcInjuryReportComponent.prototype, "isOtherEquipment", {
        get: function () {
            if (this.report && this.report.injuryReport && this.report.injuryReport.equipmentCode) {
                return this.report.injuryReport.equipmentCode.code === 'Other';
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcInjuryReportComponent.prototype, "minimumDate", {
        get: function () {
            return moment(this.startOfMinDate).isSameOrAfter(this.m_minimumDate) ? this.startOfMinDate : this.m_minimumDate;
        },
        enumerable: true,
        configurable: true
    });
    WcInjuryReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.management.subscribeToOrgLevelChange(function (o) {
            _this.orgLevel = o;
            _this.loadEmployeesLookup();
        });
        this.subscriptions.incidentDate = this.management.subscribeToSetIncidentDate(function (iDate) {
            _this.incidentDate = iDate;
            _this.startOfMinDate = moment(iDate).startOf('day').toDate();
        });
        this.subscriptions.load = this.management.onLoaded$
            .combineLatest(this.management.wcLookupsLoaded$)
            .subscribe(function (_a) {
            var report = _a[0], lookups = _a[1];
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_b) {
                    this.lookups = lookups;
                    this.report = report;
                    return [2 /*return*/];
                });
            });
        });
        this.subscriptions.value = this.injuryReportForm.statusChanges.subscribe(function (x) {
            if (_this.injuryReportForm.dirty && _this.injuryReportForm.touched) {
                _this.management.onChangeNotify();
            }
            _this.management.setInjuryFormValidity(_this.injuryReportForm.valid);
            _this.updateCommentsCount();
        });
    };
    WcInjuryReportComponent.prototype.ngOnDestroy = function () { };
    WcInjuryReportComponent.prototype.onClickDelete = function (acceptPopover) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        acceptPopover.hide();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.management.deleteReport()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    WcInjuryReportComponent.prototype.onClickCancel = function (acceptPopover) {
        acceptPopover.hide();
    };
    WcInjuryReportComponent.prototype.onChangeOsha = function (enable) {
        if (enable) {
            this.management.enableOshaReport();
            this.enableOsha = true;
        }
        else {
            this.management.disableOshaReport();
        }
    };
    WcInjuryReportComponent.prototype.onChangeWCCase = function (enable) {
        if (enable) {
            this.management.enableWCReport();
            this.enableWorkersComp = true;
        }
        else {
            this.management.disableWCReport();
        }
    };
    WcInjuryReportComponent.prototype.onWitnessEmployeeChanged = function (newEmployee) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!newEmployee) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.management.setWitnessEmployee(newEmployee.id)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    WcInjuryReportComponent.prototype.onWitnessIsEmployeeChanged = function (isEmp) {
        if (isEmp !== this.injury.isWitnessEmployee) {
            this.injury.witnessEmployeeObj = null;
            this.injury.witnessEmployee = null;
        }
        this.injury.witnessPhone = null;
    };
    WcInjuryReportComponent.prototype.onHasEmployeeSignChanged = function (has) {
        if (!has) {
            this.injury.employeeSignDate = null;
        }
        else {
            this.injury.employeeSignDate = new Date();
        }
    };
    WcInjuryReportComponent.prototype.onHasSupervisorSignChanged = function (has) {
        if (!has) {
            this.injury.supervisorSignDate = null;
        }
        else {
            this.injury.supervisorSignDate = new Date();
        }
    };
    WcInjuryReportComponent.prototype.onHasDirectorSignChanged = function (has) {
        if (!has) {
            this.injury.exDirectorSignDate = null;
        }
        else {
            this.injury.exDirectorSignDate = new Date();
        }
    };
    WcInjuryReportComponent.prototype.loadEmployeesLookup = function () {
        var _this = this;
        this.management.loadEmployees()
            .then(function (l) {
            _this.employeesLookup = l;
        });
    };
    WcInjuryReportComponent.prototype.updateCommentsCount = function () {
        var commentsCount = 0;
        if (!_.isNil(this.injury.employeeComment) && _.size(this.injury.employeeComment) > 0) {
            commentsCount++;
        }
        if (!_.isNil(this.injury.supervisorComment) && _.size(this.injury.supervisorComment) > 0) {
            commentsCount++;
        }
        if (!_.isNil(this.injury.exDirectorComment) && _.size(this.injury.exDirectorComment) > 0) {
            commentsCount++;
        }
        if (!_.isNil(this.injury.userComment) && _.size(this.injury.userComment) > 0) {
            commentsCount++;
        }
        if (commentsCount !== this.commentsCount) {
            this.commentsCount = commentsCount;
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcInjuryReportComponent.prototype, "subscriptions", void 0);
    return WcInjuryReportComponent;
}());
export { WcInjuryReportComponent };
