import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { ChangeManagementService } from "../../../common";
import { ManagementBaseService } from "../../../core/services/management/management-base.service";
import { LookupApiService} from "../../../organization";
import { RoleDefinition } from '../../../organization';
import { NotificationGroup, UsersRoles } from "../../models";
import { INotificationRole,  NotificationRole } from "../../models/notifications/notifications-role";
import { UserRolesApiService } from "../users/user-roles-api.service";
import { NotificationsApiService } from "./notifications-api.service";
import{ NotificationEventType}from "../../models/notifications/notifications-event-type"
import { NotificationGroupEvent } from "../../models/notifications/notifications-group-event";

@Injectable()
export class NotificationsManagementService extends ManagementBaseService<NotificationGroup[], any> {
  [x: string]: any;

  public selectRecord$: Subject<NotificationGroup>;
  public addCmd$: Subject<any>;
  public clearChanges$: Subject<any>;
  public loadPage$:Subject<boolean>;
  public onGroupSaved$: Subject<{ savedRole: NotificationGroup, records: NotificationGroup[], index: number }>;
  public records: NotificationGroup[];
  private apiService: NotificationsApiService;
  private lookupApiService: LookupApiService;
  public userRoles: RoleDefinition[];
  notificationRole:NotificationRole[];
  public notificationGroup:NotificationGroup[];
  public notificationEventType:NotificationEventType[];
  listRecords: any;

  constructor(lookupApiService: LookupApiService, apiService: NotificationsApiService, public changeService: ChangeManagementService) {
    super();
    this.selectRecord$ = new Subject<NotificationGroup>();
    this.addCmd$ = new Subject<any>();
    this.loadPage$=new Subject<boolean>();
    this.clearChanges$ = new Subject<any>();
    this.onGroupSaved$ = new Subject<{ savedRole: NotificationGroup, records: NotificationGroup[], index: number }>();
     this.apiService = apiService;
    this.lookupApiService = lookupApiService;
   
  }

  public loadNotificationGroups(orgLevelId: number): void {
    this.onLoadStatusChanged(true);
    this.apiService.getNotificationGroups(orgLevelId)
      .then((records: NotificationGroup[]) => {  
        this.records = records;       
        this.onLoaded(records);
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      })

    
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
        this.clearChanges$.next (false);
      });      
  }
  
  public onRemoveRecord(orgLevelId : number, notificationGroup: NotificationGroup): void {
    this.onLoadStatusChanged(true);
    this.apiService.removeGroup(orgLevelId, notificationGroup.groupId)
      .then((res: any) => {
        this.records = _.filter(this.records, (r: NotificationGroup) => r.groupId !== notificationGroup.groupId);
        this.onLoaded(this.records);
        this.onLoadStatusChanged(false);
        this.clearChanges$.next (false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        this.clearChanges$.next (false);
      });
  }
  public onAddRecord(orgLevelId : number, notificationGroup: NotificationGroup): void {
    this.onLoadStatusChanged(true);
    this.apiService.addGroup(notificationGroup, orgLevelId)
      .then((res: any) => {
        this.loadNotificationGroups(orgLevelId);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
        this.clearChanges$.next (false);
      });
  }

  public onSaveRecord(orgLevelId : number, notificationGroup: NotificationGroup): void {
    this.onLoadStatusChanged(true);
    // We cannot simply update the row, it needs delete and then add because of event and roles
    this.apiService.removeGroup(orgLevelId, notificationGroup.groupId)
    .then((res: any) => {
      this.onAddRecord(orgLevelId, notificationGroup );
    })
    .catch((reason: any) => {
      this.onLoadStatusChanged(false);
      this.clearChanges$.next (false);
    });
  }


  public onEditItem(item: any): void {
    this.changeService.changeNotify();
    this.changeNotify$.next ();
  }

  public onCancelEdit(item: any): void {
    this.changeService.clearChanges();
    this.clearChanges$.next (false);
  }

  public addCmd(): void {
    this.changeService.changeNotify();
    this.changeNotify$.next ();
    this.addCmd$.next(null);
  }

}