<slx-spinner [show]="isLoading">
    <div class="slx-high-box benefit-employees-eligible ">
        <div class="slx-high-box__body">
            <form #form="ngForm" novalidate class="slx-full-height">
                <kendo-grid #kendoGrid
                    class="slx-blue-grid slx-transparent-selection slx-grid-slim-rows slx-full-height"
                    slxKendoGridState="BENEFIT_OPTION_FLAT_GRID" [data]="gridState?.view"
                    [filterable]="'menu'" [sortable]="true" [filter]="gridState.state.filter"
                    [sort]="gridState.state.sort" [selectable]="selectableSettings"
                    (dataStateChange)="gridState.dataStateChange($event)" (edit)="gridState.editHandler($event)">

                <kendo-grid-column title="Default Employer Contribution" width="250" field="erContribution" [sortable]="true" filter="numeric">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Default Employer Contribution</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{ dataItem.erContribution | number: ".2-2"| slxMoney }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Default Employee Contribution" width="250" field="empContribution" [sortable]="true" filter="numeric">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Default Employee Contribution</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{ dataItem.empContribution | number: ".2-2"| slxMoney }}
                        </ng-template>
                    </kendo-grid-column>

                </kendo-grid>
            </form>
        </div>
    </div>
</slx-spinner>