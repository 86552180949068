export interface ILookupMultiselectModel {
  isSelected: boolean;
  id: string;
  name: string;
}

export class LookupMultiselectModel implements ILookupMultiselectModel {
  public isSelected: boolean;
  public id: string;
  public name: string;
  public object: any;

  public constructor(init?: Partial<LookupMultiselectModel>) {
    Object.assign(this, init);
  }
}
