import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { appConfig } from '../../../../app.config';
import { configurationConfig } from '../../../configuration.config';
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/services/api/api-util.service";
import * as i2 from "../../../../common/services/url-params/url-params.service";
var OrgStructureCodeService = /** @class */ (function () {
    function OrgStructureCodeService(apiUtilService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.newTabData = {
            // ParentLevel: [],
            Organizations: [],
            Positions: [],
            Departments: [],
        };
        this.codeMappingData = new Subject();
    }
    OrgStructureCodeService.prototype.getCodeMappingData = function () {
        return this.codeMappingData.asObservable();
    };
    OrgStructureCodeService.prototype.editDataHandler = function (data) {
        this.codeMappingData.next(data);
    };
    OrgStructureCodeService.prototype.saveDataHandler = function (data) {
        var url = this.getConfigurationApiRoot() + "/orgLevel/orgStructureCodes";
        var body = tslib_1.__assign({}, data);
        var request = this.urlParamsService.createPostRequest(url, body);
        var promise = this.apiUtilService
            .request(request)
            .then(function (response) {
            return response;
        });
        return promise;
    };
    OrgStructureCodeService.prototype.getOrgStructureCodes = function (orgLevelType, orgLevelId) {
        var url = this.getConfigurationApiRoot() + "/orgLevel/" + orgLevelType + "/" + orgLevelId + "/orgStructureCodes";
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService
            .request(request)
            .then(function (response) {
            return response;
        });
        return promise;
    };
    OrgStructureCodeService.prototype.getOrgStructureCodeByType = function (orgLevelType) {
        var url = this.getConfigurationApiRoot() + "/orgLevel/" + orgLevelType + "/orgStructureCodes";
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService
            .request(request)
            .then(function (response) {
            return response;
        });
        return promise;
    };
    // public getOrgParentLevelOptions(orgLevelId: number): Promise<OrgStructureCode[]> {
    //   const url: string = `${this.getConfigurationApiRoot()}/orgLevel/${orgLevelId}/getParentLevel`;
    //   let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    //   let promise: Promise<any> = this.apiUtilService
    //     .request<any[], Meta>(request)
    //     .then((response: ResponseBody<[], Meta>) => {
    //       return response;
    //     });
    //   return promise;
    // }
    // public getOrgParentLevelData(orgLevelType: string): Promise<OrgStructureCode[]> {
    //   const url: string =  `${this.getConfigurationApiRoot()}/orgLevel/${orgLevelType}/parentLevelCodesByType`;
    //   let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    //   let promise: Promise<any> = this.apiUtilService
    //     .request<any[], Meta>(request)
    //     .then((response: ResponseBody<[], Meta>) => {
    //       return response;
    //     });
    //   return promise;
    // }
    OrgStructureCodeService.prototype.getConfigurationApiRoot = function () {
        return this.getApiRoot() + "/" + configurationConfig.api.configuration.root;
    };
    OrgStructureCodeService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    OrgStructureCodeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrgStructureCodeService_Factory() { return new OrgStructureCodeService(i0.ɵɵinject(i1.ApiUtilService), i0.ɵɵinject(i2.UrlParamsService)); }, token: OrgStructureCodeService, providedIn: "root" });
    return OrgStructureCodeService;
}());
export { OrgStructureCodeService };
