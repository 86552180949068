import { ValuePairWidgetConfig } from '../../../common/models/value-pair-widget/value-pair-widget-config';
import { PairColorScheme } from '../../../common/models/pair-color-scheme/pair-color-scheme';

const gray = '#dadada';
const red = '#dd3a13';
const green = '#bcd885';
const blue = '#2574b6';
const orange = '#ff9900';
const dullerYellow = '#f0d360';

const colorOverDefault = new PairColorScheme();
colorOverDefault.fontColor1 = red;
colorOverDefault.backgroundColor1 = gray;
colorOverDefault.fontColor2 = gray;
colorOverDefault.backgroundColor2 = red;

const colorUnderDefault = new PairColorScheme();
colorUnderDefault.fontColor1 = blue;
colorUnderDefault.backgroundColor1 = blue;
colorUnderDefault.fontColor2 = gray;
colorUnderDefault.backgroundColor2 = gray;

const colorMatchedDefault = new PairColorScheme();
colorMatchedDefault.fontColor1 = green;
colorMatchedDefault.backgroundColor1 = green;
colorMatchedDefault.fontColor2 = gray;
colorMatchedDefault.backgroundColor2 = green;

const colorOverWithinLimitsDefault = new PairColorScheme();
colorOverWithinLimitsDefault.fontColor1 = dullerYellow;
colorOverWithinLimitsDefault.backgroundColor1 = dullerYellow;
colorOverWithinLimitsDefault.fontColor2 = gray;
colorOverWithinLimitsDefault.backgroundColor2 = orange;

const colorUnderWithinLimitsDefault = new PairColorScheme();
colorUnderWithinLimitsDefault.fontColor1 = orange;
colorUnderWithinLimitsDefault.backgroundColor1 = dullerYellow;
colorUnderWithinLimitsDefault.fontColor2 = gray;
colorUnderWithinLimitsDefault.backgroundColor2 = orange;

export class SoValuePairWidgetConfig extends ValuePairWidgetConfig {
  public colorOver: PairColorScheme = colorOverDefault;
  public colorUnder: PairColorScheme = colorUnderDefault;
  public colorMatched: PairColorScheme = colorMatchedDefault;
  public colorOverWithinLimits: PairColorScheme = colorOverWithinLimitsDefault;
  public colorUnderWithinLimits: PairColorScheme = colorUnderWithinLimitsDefault;
}
