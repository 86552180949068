<div class="geo-toolbar">
  <div class="geo-toolbar__lcol">
    <button class="slx-button slx-with-icon slx-margin-r" type="button" (click)="onAdd()" *ngIf="canAdd">
      <i aria-hidden="true" class="fas fa-plus-circle slx-button__icon"></i>
      <span class="slx-button__text">Add</span>
    </button>
    <button class="slx-button slx-with-icon slx-margin-r" type="button" (click)="onSave()" *ngIf="canSave" [disabled]="actionsDisabled">
        <i aria-hidden="true" class="fas fa-save slx-button__icon"></i>
        <span class="slx-button__text">Save</span>
      </button>
    <button class="slx-button slx-with-icon slx-margin-r" type="button" (click)="onUpdate()" *ngIf="canUpdate" [disabled]="actionsDisabled">
        <i aria-hidden="true" class="fas fa-save slx-button__icon"></i>
        <span class="slx-button__text">Update</span>
      </button>
    <button class="slx-button slx-with-icon slx-margin-r" type="button" (click)="onCancel()" *ngIf="canCancel">
      <i aria-hidden="true" class="fas fa-ban slx-button__icon"></i>
      <span class="slx-button__text">Cancel</span>
    </button>
  </div>
  <div class="geo-toolbar__rcol">
    <slx-action-list>
      <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
      <popper-content #popperContent>
        <slx-action-list-item (onClick)="onExportToExcel()">
          Export to Excel
        </slx-action-list-item>
        <slx-action-list-item (onClick)="onExportToPdf()">
          Export to PDF
        </slx-action-list-item>
      </popper-content>
    </slx-action-list>
  </div>
</div>
