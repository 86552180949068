import { Directive, TemplateRef } from '@angular/core';

@Directive ({
    selector: '[slx-editable-list-renderer]'
})
export class ListRendererTemplateDirective {
    public templateRef: TemplateRef<any>;

    constructor (templateRef: TemplateRef<any>) {
        this.templateRef = templateRef;
    }
}
