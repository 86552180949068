<div class="app {{ className }}" *ngIf="!isExternalLink">
  <a *ngIf="application" class="app__logo" [routerLink]="application.link">
    <i class="fa" [ngClass]="application.icon" aria-hidden="true"></i>
  </a>
  <div class="app__content">
    <div class="app__url">
      <a *ngIf="application" class="app-url-link" [routerLink]="application.link">{{ application.title }}</a>
      <div (click)="onStartAlerts()" class="dashboard-edit-button-alert" *ngIf="application && (application.id===3|| application.id===4)">
        <em class="fas fa-cog" aria-hidden="true"></em>
        Edit
      </div>
    </div>
    <slx-spinner [show]="progressbar" class="app__spinner">
      <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
    </slx-spinner>
  </div>
</div>
<div class="app {{ className }}" *ngIf="isExternalLink">
  <a *ngIf="application" class="app__logo" [attr.href]="application.link" target="_blank" (click)="redirectToApp()">
    <i class="fa" [ngClass]="application.icon" aria-hidden="true"></i>
  </a>
  <div class="app__content">
    <a *ngIf="application" class="app__url" [attr.href]="application.link" target="_blank"
      (click)="redirectToApp()">{{ application.title }}</a>
    <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
  </div>
</div>

<ng-template #contentTmpl>
  <ng-content></ng-content>
</ng-template>