import { Subscription } from 'rxjs/Subscription';
import { OrgLevel } from './../../../state-model/models/org-level/org-level';
import { Observable } from 'rxjs';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { LicenseType } from './../../../organization/models/lookup/license-type';
import { Injectable } from '@angular/core';
import { LicenseTypesApiService } from './license-types-api.service';
import { mutableSelect, unsubscribeInService } from '../../../../app/core/decorators';
import * as _ from 'lodash';
@Injectable()
export class LicenseTypesManagementService {
  public isLoading: boolean;
  public list: LicenseType[];
  public gridData: GridDataResult;
  public orgLevelId: number;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  @unsubscribeInService()
  private orgLevelSubscription: Subscription;

  public sort: SortDescriptor[] = [];

  constructor(
    private apiService: LicenseTypesApiService
  ) {}

  public init(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
      this.loadData();
    });
  }

  public loadData(): void {
    this.isLoading = true;
    this.apiService.getList(this.orgLevelId).then((value: LicenseType[]) => {
      this.list = value;
      this.refreshGrid();
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public setSort(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public save(type: LicenseType): Promise<any> {
    this.isLoading = true;
    return this.apiService.save(this.orgLevelId, type).then((value: LicenseType) => {
      const editedEntry: LicenseType = _.find(this.list, { 'licenseTypeID': type.licenseTypeID });
      _.assign(editedEntry, type);
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public add(type: LicenseType): Promise<any> {
    this.isLoading = true;
    return this.apiService.add(this.orgLevelId, type).then((value: LicenseType) => {
      this.list.push(value);
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public delete(typeId: number): Promise<any> {
    this.isLoading = true;
    return this.apiService.delete(this.orgLevelId, typeId).then(() => {
      const index = _.findIndex(this.list, { 'licenseTypeID': typeId });
      this.list.splice(index, 1);
      this.refreshGrid();
    }).finally(() => {
      this.isLoading = false;
    });
  }

  private refreshGrid(): void {
    if (!this.list) {
      this.gridData = null;
      return;
    }
    this.gridData = {
      data: orderBy(this.list, this.sort),
      total: this.list.length
    };
  }
}
