import { IPayrollExportConfig, PayrollExportConfig } from './payroll-export-config';
export interface IPayrollExportConfigContainer {
    id: number;
    configName: string;
    organizationId: number;
    lockTimecardsAfterExport: boolean;
    configs: IPayrollExportConfig[];
}

export class PayrollExportConfigContainer {
    public id: number;
    public configName: string;
    public organizationId: number;
    public lockTimecardsAfterExport: boolean;
    public configs: PayrollExportConfig[];
    public isGlobal: boolean;
}
