import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolyn',
  pure: false
})
@Injectable()
export class BoolYNPipe implements PipeTransform {
  public transform(obj?: any, isFullText: boolean = false): any {
    if (obj === null || obj === undefined) return null;
    let text = '';
    if (obj) {
      text = isFullText ? 'Yes' : 'Y';
    } else {
      text = isFullText ? 'No' : 'N';
    }
    return text;
  }
}
