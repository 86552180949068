
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { NavigationMenuItem, INavigationMenuEvent } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { UserApplication } from '../../../state-model/models/index';
import { Assert } from '../../../framework/index';
import { IRouteInfo } from '../../../core/models/index';
import { ErrorHandlingService } from '../../../core/services/index';
import { NotificationMessage } from '../../../app.messages';

export interface IReloadMenuEvent {
  app: UserApplication;
  orgLevel: OrgLevel;
}

@Injectable()
export class ApplicationStateBusService {

  public get isInitialized(): boolean {
    return this.initialized;
  }

  public initialize$: ReplaySubject<any>;
  public appDeselected$: ReplaySubject<any>;
  public appSelected$: ReplaySubject<UserApplication>;
  public reloadOrgLevels$: Subject<any>;
  public orgLevelsLoaded$: ReplaySubject<OrgLevel[]>;
  public orgLevelSelected$: ReplaySubject<OrgLevel>;
  public orgLevelIdSelected$: ReplaySubject<number>;
  public lastOrgLevelRestored$: ReplaySubject<OrgLevel>;
  public reloadMenu$: Subject<IReloadMenuEvent>;
  public menuLoaded$: ReplaySubject<NavigationMenuItem[]>;
  public menuItemClicked$: ReplaySubject<NavigationMenuItem>;
  public menuSelected$: ReplaySubject<INavigationMenuEvent>;
  public routeInfoChanged$: ReplaySubject<IRouteInfo>;
  public logout$: ReplaySubject<any>;
  public login$: ReplaySubject<any>;
  public resetCache$: Subject<string>;

  private application: UserApplication;
  private initialized: boolean;
  private errorService: ErrorHandlingService;

  constructor(errorService: ErrorHandlingService) {
    this.initialize$ = new ReplaySubject<any>(1);
    this.appDeselected$ = new ReplaySubject<any>(1);
    this.appSelected$ = new ReplaySubject<UserApplication>(1);
    this.reloadOrgLevels$ = new Subject<any>();
    this.orgLevelsLoaded$ = new ReplaySubject<OrgLevel[]>(1);
    this.orgLevelSelected$ = new ReplaySubject<OrgLevel>(1);
    this.orgLevelIdSelected$ = new ReplaySubject<number>(1);
    this.lastOrgLevelRestored$ = new ReplaySubject<OrgLevel>(1);
    this.reloadMenu$ = new Subject<IReloadMenuEvent>();
    this.menuLoaded$ = new ReplaySubject<NavigationMenuItem[]>(1);
    this.menuItemClicked$ = new ReplaySubject<NavigationMenuItem>(1);
    this.menuSelected$ = new ReplaySubject<INavigationMenuEvent>(1);
    this.routeInfoChanged$ = new ReplaySubject<IRouteInfo>(1);
    this.logout$ = new ReplaySubject<any>(1);
    this.login$ = new ReplaySubject<boolean>(1);
    this.resetCache$ = new Subject<string>();
    this.errorService = errorService;
  }

  public subscribeInitialize(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.initialize$.subscribe(callback);
  }

  public subscribeToDeselectApp(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.appDeselected$.subscribe(callback);
  }

  public subscribeToSelectApp(callback: (app: UserApplication) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.appSelected$.subscribe(callback);
  }

  public subscribeToOrgLevelsLoaded(callback: (orgLevels: OrgLevel[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelsLoaded$.subscribe(callback);
  }

  public subscribeToSelectOrgLevel(callback: (orgLevel: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelSelected$.subscribe(callback);
  }

  public subscribeToMenuLoaded(callback: (menu: NavigationMenuItem[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.menuLoaded$.subscribe(callback);
  }

  public subscribeToSelectMenu(callback: (event: INavigationMenuEvent) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.menuSelected$.subscribe(callback);
  }

  public subscribeToClickMenu(callback: (event: NavigationMenuItem) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.menuItemClicked$.subscribe(callback);
  }

  public subscribeToRouteInfo(callback: (data: IRouteInfo) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.routeInfoChanged$.subscribe(callback);
  }

  public subscribeToLogout(callback: (event: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.logout$.subscribe(callback);
  }

  public subscribeToAppError(callback: (error: NotificationMessage) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.errorService.error$.subscribe(callback);
  }

  public initialize(): void {
    Assert.isFalse(this.initialized, 'Application Management already initialized');
    this.initialized = true;
    this.initialize$.next(null);
  }

  public deselectApp(): void {
    this.appDeselected$.next(null);
  }

  public selectApp(app: UserApplication): void {
    this.appSelected$.next(app);
  }

  public reloadOrgLevels(): void {
    this.reloadOrgLevels$.next(null);
  }

  public selectOrgLevel(orgLevel: OrgLevel): void {
    this.orgLevelSelected$.next(orgLevel);
  }

  public selectOrgLevelId(id: number): void {
    this.orgLevelIdSelected$.next(id);
  }

  public restoredLastOrgLevel(orgLevel: OrgLevel): void {
    this.lastOrgLevelRestored$.next(orgLevel);
  }

  public loadedOrgLevels(orgLevels: OrgLevel[]): void {
    this.orgLevelsLoaded$.next(orgLevels);
  }

  public reloadMenu(app: UserApplication, orgLevel: OrgLevel): void {
    this.reloadMenu$.next({ app, orgLevel });
  }

  public loadedMenu(menu: NavigationMenuItem[]): void {
    this.menuLoaded$.next(menu);
  }

  public clickMenuItem(event: NavigationMenuItem): void {
    this.menuItemClicked$.next(event);
  }
  public selectMenu(event: INavigationMenuEvent): void {
    this.menuSelected$.next(event);
  }

  public changeRouteInfo(data: IRouteInfo): void {
    this.routeInfoChanged$.next(data);
  }

  public logout(): void {
    this.logout$.next(null);
  }

  public login(isAliasChanged: boolean): void {
    this.login$.next(isAliasChanged);
  }

  public resetCache(cacheName: string): void {
    this.resetCache$.next(cacheName);
  }
}
