<kendo-grid class="slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [groupable]="false"
  [group]="gridState.state.group"
  [skip]="gridState.state.skip"
  (dataStateChange)="gridState.dataStateChange($event)"
  (groupChange)="gridState.groupChange($event)"
>

<!-- <kendo-grid-column title="Position" field="positionName" width="100">
  <ng-template kendoGridHeaderTemplate>Position</ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.positionName}}</ng-template>

  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
</kendo-grid-column> -->

<kendo-grid-column title="Shift" field="shiftName" width="120">
  <ng-template kendoGridHeaderTemplate>Shift</ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <div class="slx-parameter-partial-label" *ngIf="!dataItem.hasPartialShift"><label>Partial</label></div>
    {{dataItem.shiftName}}
  </ng-template>
  <!-- <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.shiftName}}</ng-template> -->
</kendo-grid-column>

<kendo-grid-column title="Unit" field="unitName" width="90">
  <ng-template kendoGridHeaderTemplate>Unit</ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.unitName}}</ng-template>
</kendo-grid-column>
<kendo-grid-column title="Open" field="openShiftCount" width="90">
    <ng-template kendoGridHeaderTemplate>Open</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.openShiftCount }}</ng-template>
</kendo-grid-column>
<kendo-grid-column title="Over" field="overScheduleCount" width="90">
  <ng-template kendoGridHeaderTemplate>Over</ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.overScheduleCount }}</ng-template>
</kendo-grid-column>
<kendo-grid-column title="Requests" field="newMessageCount" width="90">
    <ng-template kendoGridHeaderTemplate>Requests</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem?.newMessageCount + dataItem?.partnerShiftCount }}</ng-template>
</kendo-grid-column>

<div *kendoGridDetailTemplate="let dataItem">
        <slx-daily-shift-messages-grid [summary]="dataItem"
        (itemRemove)="removeItem($event,dataItem)"></slx-daily-shift-messages-grid>
</div>
</kendo-grid>
<!--   -->
