
import { Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'convertUSformatNumber',
})

export class ConvertUSformatNumber implements PipeTransform {
    transform(phoneNumberString) {
        if(phoneNumberString) {
            if(phoneNumberString.toString().length > 10 && phoneNumberString.includes('+')) {
                phoneNumberString = phoneNumberString.substr( phoneNumberString.length - 10)
            }
            const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
              return '(' + match[1] + ') ' + match[2] + '-' + match[3]
            } else {
                return phoneNumberString;
            }
        } else {
            return phoneNumberString;
        }
    }
}

