import * as _ from 'lodash';

import { Injectable, Provider, Output, EventEmitter } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';

import { GenericListDefinition } from '../../../../organization/models/generic-list/generic-list-definition';
import { EmployeeListMapService } from './../../../../employee/employee-list/services/employee-list/employee-list-map.service';
import { GenericListApiService } from '../../../../organization/services/generic-list/generic-list-api.service';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN } from './../../../../core/models/tokens';
import { EmployeeActionDefinition } from './../../../../employee/employee-list/models/employeeActionDefinition';

import { OrgLevel } from '../../../../state-model/models/index';
import { NotificationsApiService } from '../../../../portal/services/notifications/notifications-api.service';

import { ManagementBaseService } from '../../../../core/services/index';
import { EmployeeCallLogApiService } from './employee-call-log-api.service';
import { EmployeeCallLog } from '../../models/index';

@Injectable()
export class EmployeeCallLogManagementService extends ManagementBaseService<EmployeeCallLog, any> {
  public resetGridState$: Subject<any>;
  public messageCenterEnabled$: ReplaySubject<boolean>;

  @Output()
  public smsDataLoaded$: EventEmitter<EmployeeActionDefinition>;
  public applicationId: number;
  public freeze: boolean;

  private orgLevelId: number;
  private orgLevel: OrgLevel;
  private employeeIds: number[] = [];
  private startDate: Date;
  private endDate: Date;
  private isMessageCenterEnabled: boolean;

  private employeeCallLogApiService: EmployeeCallLogApiService;

  constructor(
    employeeCallLogApiService: EmployeeCallLogApiService,
    private genericService: GenericListApiService,
    private employeeMapService: EmployeeListMapService,
    private notificationsApiService: NotificationsApiService
  ) {
    super();

    this.employeeCallLogApiService = employeeCallLogApiService;
    this.employeeIds = [];
    this.smsDataLoaded$ = new EventEmitter();
    this.resetGridState$ = new Subject<any>();
    this.messageCenterEnabled$ = new ReplaySubject<boolean>(1);

    this.initialize();
  }

  private async initialize(): Promise<void> {
    this.onLoadStatusChanged(true);

    this.isMessageCenterEnabled = false;

    try {
      this.isMessageCenterEnabled = (await this.notificationsApiService.getIconAccessCheck(this.orgLevelId)).isMessageCenterEnabled;
    } catch (e) { }

    this.messageCenterEnabled$.next(this.isMessageCenterEnabled);
    this.onLoadStatusChanged(false);
  }

  public resetGridState(): void {
    this.resetGridState$.next(null);
  }

  public orgLevelChanged(orgLevel: OrgLevel): void {
    this.orgLevel = orgLevel;
    if (orgLevel) this.orgLevelId = orgLevel.id;
    this.checkRequiredData();
  }

  public employeesChanged(employeeIds: number[]): void {
    this.employeeIds = employeeIds ? employeeIds : [];
    this.checkRequiredData();
  }

  public dateRangeChanged(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.checkRequiredData();
  }

  public loadSmsData(): void {
    this.onLoadStatusChanged(true);
    this.genericService.getListDefinitions(this.applicationId, this.orgLevelId, 'SendSMStoaGroupofEmployees')
      .then((definition: GenericListDefinition) => {
        let empDef: EmployeeActionDefinition = this.employeeMapService.mapFromGenericListDefinition(definition);
        this.smsDataLoaded$.emit(empDef);
        this.onLoadStatusChanged(false);
      }).catch((reason: any) => {
        this.onError(reason);
      });
  }

  public getSmsWindowProviders(actionDefinition: EmployeeActionDefinition): Provider[] {
    let resolvedProvidersConf: Provider[] = [
      { provide: EMPLOYEES_ID_TOKEN, useValue: [] },
      { provide: EmployeeActionDefinition, useValue: actionDefinition },
      { provide: OrgLevel, useValue: this.orgLevel },
      { provide: ISACTIVEONLY_ID_TOKEN, useValue: true }
    ];
    return resolvedProvidersConf;
  }

  private checkRequiredData(): void {
    if (!this.freeze && this.orgLevelId && this.startDate && this.endDate && _.isArray(this.employeeIds)) {
      this.loadCallLog();
    }
  }

  private loadCallLog(): void {
    this.onLoadStatusChanged(true);

    const promise = this.isMessageCenterEnabled ?
      this.employeeCallLogApiService.getMessageCenterCallLog(this.orgLevelId, this.employeeIds, this.startDate, this.endDate) :
      this.employeeCallLogApiService.getCallLog(this.orgLevelId, this.employeeIds, this.startDate, this.endDate);

    promise.then(employeeCallLog => {
      this.onLoadStatusChanged(false);
      this.onLoaded(employeeCallLog);
    }).catch((reason: any) => {
      this.onError(reason);
    });
  }
}
