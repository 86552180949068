import * as _ from 'lodash';
import { BenefitDetailsState } from './benefit-details-state';
import { BenefitDetailsLineStandart } from './benefit-details-line-standart';
import { BenefitDetailsLine } from './benefit-details-line';

export class BenefitDetailsProviderLineStandartEntity extends BenefitDetailsState {
  public current: BenefitDetailsLineStandart;
  public selectedTierId: number = null;
  public hasTiersAttachments = false;
  public nextLineDetails: BenefitDetailsLine;

  private stored: BenefitDetailsLineStandart = null;
  private draft = false;

  public get isDraft(): boolean {
    return this.draft;
  }

  public get isStored(): boolean {
    return _.isObjectLike(this.stored);
  }

  public markAsDraft(): void {
    this.draft = true;
  }

  public storeEntity(): BenefitDetailsProviderLineStandartEntity {
    this.stored = _.cloneDeep(this.current);

    return this;
  }

  public restoreEntity(): BenefitDetailsProviderLineStandartEntity {
    this.current = _.cloneDeep(this.stored);
    this.stored = null;

    return this;
  }
}
export class DeductionCodeValidation{
  public nextGenEnabled:boolean;
  public isDuplicateDeductionCode:boolean;
}
