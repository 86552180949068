import { ShiftEligibleEmployee } from './index';
import { ScheduleAbsence } from '../../../organization/models/index';

export interface IShiftReplaceCmd {
  dateOn: string;
  shiftId: number;
  absenceCode: string;
  replaceById: number;
  comment: string;
}
export class ShiftReplaceCmd {
  public selectedEmployee: ShiftEligibleEmployee;
  public scheduleAbsence: ScheduleAbsence;
  public dateOn: Date;
  public shiftId: number;
  public absenceCode: string;
  public replacedEmployeeId: number;
  public comment: string;
}
