import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework';
import { IpManagementApiService } from './ip-management-api.service';
var IpManagementService = /** @class */ (function () {
    function IpManagementService(apiService) {
        this.apiService = apiService;
        // private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
        this.ipWhiteList$ = new ReplaySubject(1);
        this.ipBlackList$ = new ReplaySubject(1);
        this.ipAddress$ = new ReplaySubject(1);
        this.ipSettings$ = new ReplaySubject(1);
        this.loading$ = new Subject();
        this.activeTab$ = new ReplaySubject(1);
        this.downloadWhitelistConfig$ = new Subject();
        this.downloadBlacklistConfig$ = new Subject();
        this.addNewIp$ = new Subject();
        this.ipAddress = [];
        this.ipWhiteListAddrs = [];
        this.ipBlackListAddrs = [];
        this.ipListobj = [];
        this.activeTab$.next('Allowlist');
    }
    IpManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    IpManagementService.prototype.subscribeToIpWhitelist = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.ipWhiteList$.subscribe(callback);
    };
    IpManagementService.prototype.subscribeToIpBlacklist = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.ipBlackList$.subscribe(callback);
    };
    IpManagementService.prototype.subscribeToIpAddress = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.ipAddress$.subscribe(callback);
    };
    IpManagementService.prototype.subscribeToIpSettings = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.ipSettings$.subscribe(callback);
    };
    IpManagementService.prototype.subscribeToActiveTab = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.activeTab$.subscribe(callback);
    };
    IpManagementService.prototype.subscribeToDownloadWhitelistConfig = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.downloadWhitelistConfig$.subscribe(callback);
    };
    IpManagementService.prototype.subscribeToDownloadBlacklistConfig = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.downloadBlacklistConfig$.subscribe(callback);
    };
    IpManagementService.prototype.subscribeToAddNewIp = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.addNewIp$.subscribe(callback);
    };
    IpManagementService.prototype.getIpManagementConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.startProgress();
                this.apiService
                    .getIpManagementConfig()
                    .then(function (container) {
                    _this.ipWhiteList$.next(container.allowAccessList);
                    _this.ipBlackList$.next(container.denyAccessList);
                    _this.ipSettings$.next(container.settings);
                    _this.setIPList(container);
                    _this.stopProgress();
                });
                return [2 /*return*/];
            });
        });
    };
    IpManagementService.prototype.setIPList = function (container) {
        var _this = this;
        _.forEach(container.allowAccessList, function (ip) {
            _this.ipAddress.push({ id: ip.id, address: ip.ipAddress });
            _this.ipWhiteListAddrs.push({ id: ip.id, address: ip.ipAddress });
        });
        _.forEach(container.denyAccessList, function (ip) {
            _this.ipAddress.push({ id: ip.id, address: ip.ipAddress });
            _this.ipBlackListAddrs.push({ id: ip.id, address: ip.ipAddress });
        });
        this.ipAddress$.next(this.ipAddress);
    };
    IpManagementService.prototype.setRestrictions = function (whiteListRestriction, blackListRestriction, isCanEdit, isCanExport) {
        var restrictions = {
            enforceWhitelistRestrictions: whiteListRestriction,
            enforceBlacklistRestrictions: blackListRestriction,
            canEdit: isCanEdit,
            canExport: isCanExport,
        };
        this.apiService.setRestrictions(restrictions);
    };
    IpManagementService.prototype.updateRestriction = function (ipRestriction) {
        return this.apiService.updateRestriction(ipRestriction);
    };
    IpManagementService.prototype.addRestriction = function (ipRestriction) {
        return this.apiService.addRestriction(ipRestriction);
    };
    IpManagementService.prototype.deleteRestriction = function (restrictionId) {
        return this.apiService.deleteRestriction(restrictionId);
    };
    IpManagementService.prototype.updateIPList = function (ip, ipType) {
        this.ipAddress.push({ id: ip.id, address: ip.ipAddress });
        if (ipType) {
            this.ipWhiteListAddrs.push({ id: ip.id, address: ip.ipAddress });
        }
        else {
            this.ipBlackListAddrs.push({ id: ip.id, address: ip.ipAddress });
        }
    };
    IpManagementService.prototype.setActiveTab = function (tab) {
        this.activeTab$.next(tab);
    };
    IpManagementService.prototype.setDownloadFile = function (tab, type) {
        var downloadConfig = {
            tabType: tab,
            fileType: type,
        };
        if (tab === 'Allowlist') {
            this.downloadWhitelistConfig$.next(downloadConfig);
        }
        else if (tab === 'Denylist') {
            this.downloadBlacklistConfig$.next(downloadConfig);
        }
    };
    IpManagementService.prototype.addNewIp = function (isNew) {
        this.addNewIp$.next(isNew);
    };
    IpManagementService.prototype.checkIpValidation = function (ip) {
        var regex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*])\.)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*])\.)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*])\.)([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
        var validIp = regex.exec(ip);
        return validIp;
    };
    IpManagementService.prototype.checkDuplicateToEdit = function (e, id) {
        var iplist = [];
        var tab;
        var excludeIP;
        _.forEach(this.ipAddress, function (ip) {
            iplist.push(ip);
            if (ip.id === id) {
                excludeIP = ip;
            }
        });
        if (id) {
            _.remove(iplist, function (ip) { return ip.address == excludeIP.address; });
        }
        var isIpExists = _.find(iplist, function (ip) { return ip.address === e; });
        if (_.find(this.ipWhiteListAddrs, function (ip) { return ip.address === e; })) {
            tab = 'Allowlist';
        }
        else if (_.find(this.ipBlackListAddrs, function (ip) { return ip.address === e; })) {
            tab = 'Denylist';
        }
        if (isIpExists) {
            return { show: true, inTab: tab };
        }
    };
    IpManagementService.prototype.updateIPAddress = function (id) {
        _.remove(this.ipAddress, function (ip) {
            return ip.id == id;
        });
    };
    IpManagementService.prototype.startProgress = function () {
        this.loading$.next(true);
    };
    IpManagementService.prototype.stopProgress = function () {
        this.loading$.next(false);
    };
    return IpManagementService;
}());
export { IpManagementService };
