import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';

import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { PayrollExportGroup, PayrollExportFacility } from '../../../models/index';
import { KendoGridStateHelper, ScrollWatchEvent } from '../../../../../common/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-payroll-export-mobile-list',
  templateUrl: 'payroll-export-mobile-list.component.html',
  styleUrls: ['payroll-export-mobile-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayrollExportMobileListComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public group: PayrollExportGroup;

  public appConfig: IApplicationConfig;

  public records: PayrollExportFacility[];
  constructor(private changeDetector: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['group']) {
      this.records = this.group.facilities;
    }
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

}
