import { Employer} from './employers.model';
import { AleGroup } from './ale-group';

export interface IAleGroupView {
  groupId: number;
  employerIds: number[];
}

export class AleGroupView extends AleGroup {
  public employers: Employer[];
  public isEditing: boolean;
  public deleted: boolean;
  public disabled: boolean;
}

export class AleGroupAssign {
  aleGroupId: number;
  companyIds: number[];
}
