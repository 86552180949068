import { RolesRightsAccess } from './role-access-definition';

export interface IRoleRight {
  id: number;
  name: string;
  isEnabled: boolean;
  linkedMenuId: number;
  groupId: number;
  displaySequence : number;
}

export interface IRoleRightModule {
  id: number;
  name: string;
}

export class RoleRight {
  public id: number;
  public name: string;
  public isEnabled: boolean;
  public linkedMenuId: number;
  public groupId: number;
  public displaySequence: number;

  public get isDirty(): boolean {
    return this.isEnabled !== this.initial_isEnabled;
  }
  private initial_isEnabled: boolean;

  public setInitialState(): void {
    this.initial_isEnabled = this.isEnabled;
  }
}

export class RoleRightModule {
  public id: number;
  public name: string;
  public rights: RoleRight[];
  public access: RolesRightsAccess;
  public hasLinkedMenus: boolean;

}

export class RoleRightGroup {
  public id: number;
  public name: string;
  public rightModules: RoleRightModule[];
  public access: RolesRightsAccess;
}


