import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { mutableSelect } from './../../../../app/core/decorators';
import { OrgLevel } from './../../../../app/state-model/models';
import { Assert } from './../../../../app/framework';
import { Lookup, LookupApiService, ScheduleCycle } from './../../../../app/organization';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ScheduleRotationApiService } from './scheduled-rotation-api.service';
import { AssignEmpToEmptySlot, AvailShifts, EmployeeScheduledData, EmployeesGenerateRotation, FilterRotationOptions, FutureValidationShift, FutureValidationShiftResult, IAvailShifts, IDays, INoRotationEmployeeList, IPositionOptions, IScheduleDays, IScheduleRotationPermisions, IScheduleRotationTemplate, SaveRotationData, ScheduleRotationTemplate, TerminateRequest } from '../../models/schedule-rotation/schedule-rotation-template.model';
import { ScheduledRotationMapService } from './scheduled-rotation-map.service';
import { ChangeManagementService, ModalService } from './../../../../app/common';
import { v4 as UUID } from 'uuid';
import { ScheduledRotationEmployeeGridHelper } from '../../components/scheduled-rotations/scheduled-rotations-employee-grid/scheduled-rotations-employee-grid-helper';


@Injectable()
export class ScheduleRotationsManagementService extends ScheduledRotationEmployeeGridHelper {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;
    public orgLevel: OrgLevel;
    private orgLevelSubscription: Subscription;
    private loadDataSubscription$ = new Subject<void>();
    public exportRotation$ = new Subject<boolean>();
    public date = new Date();
    public cycles: ScheduleCycle[] = [];
    public dateFrom = moment().toDate();
    public firstCycleStart: any;
    public firstCycle: any;
    public selectedUnit: any[] = [];
    public selectedShiftGroup: any[] = [];
    public payCycle = new Subject<ScheduleCycle>();

    public availableShifts$ = new Subject<IScheduleDays[]>();
    public ShiftList: IScheduleDays[] = [];
    public dates: IDays[] = [];
    public totalAvailShifts: number = 0;
    public permissions$ = new Subject<IScheduleRotationPermisions>();
    public totalHours: string = '0.00';
    public isPrimary: boolean = false;
    public shiftRotationDropDown: any[] = [
        { id: 1, name: 'Weekly' },
        { id: 2, name: '2 Weeks' },
        { id: 3, name: '3 Weeks' },
        { id: 4, name: '4 Weeks' }
    ];
    public draggedShift: AvailShifts;
    public saveRotationHandler$ = new Subject<any>();
    public isLoading: boolean;
    public currentWeek: IDays[] = [];
    public isDragabble$ = new Subject<boolean>();
    public availShiftLoader: boolean;
    public employeeLoader: boolean;
    public empScheduleEntries: any[] = [];
    public emptyAddRotationSequenceCount: number = 0;
    public termEmployeeData: any;
    public emptyScheduleDates: IScheduleDays[] = [];
    public noRotationEmployeeList$ = new Subject<INoRotationEmployeeList[]>();
    public isExpand$ = new Subject<any>();
    public generateRotationEmployees: any;
    public generateRotationRecord: EmployeesGenerateRotation;
    public storeAssignedSlot$ = new Subject<any>();
    public assignEmployeeToEmptySlot: AssignEmpToEmptySlot[] = [];
    public frequencyResultData: FutureValidationShiftResult[];
    public isEditViewSaveDisable: boolean = false;
    public filterChangeCompleteSubscription: Subscription;
    public filterRotationOptions: FilterRotationOptions[] = [];
    public toolbarFilterAppliedSubscription: Subscription;
    public gridFilterAppliedSubscription: Subscription;
    public isEmployeeAssigeeMode: boolean = false;
    public filterRotationsRecord: FilterRotationOptions;
    public filterRotationsRecord$ = new Subject<FilterRotationOptions>();

    constructor(public lookupApi: LookupApiService,
        public scheduleRotationApiService: ScheduleRotationApiService,
        private mapService: ScheduledRotationMapService,
        private changeManagementService: ChangeManagementService,
        public modalService: ModalService) {
        super();
        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            this.orgLevel = orgLevel;
            this.clearAllFilters();
        });

        this.filterChangeCompleteSubscription = this.filterChangeComplete$.subscribe((isNewRotationAdded: boolean) => {
            this.mapService.weeklyCount$.subscribe((count) => this.totalAvailShifts = count);
            this.loadDataSubscription([this.filterShiftData, this.filterEmployeeData]);
            if(this.assignedEmployeeAndEmptySlotEmployee.length > 0 || isNewRotationAdded) {
                this.isNewRotationAdded = true;
            }
        });

        this.storeAssignedSlot$.subscribe(() => {
            this.assignEmployeeToEmptySlot = this.mapService.AssignEmployeeToEmptySlot(this.assignedEmployeeAndEmptySlotEmployee);
            this.isEditViewSaveDisable = false;
        });

        this.gridFilterAppliedSubscription = this.isGridFilterApplied$.subscribe((isApplied) => {
            if (isApplied) {
                let selectedOptions = this.filterRotationsRecord.showStaff.filter(x => x.isSelected);
                this.defaultGridFilterOptions = selectedOptions;
            }
            else {
                let selectedIds = this.defaultGridFilterOptions.map(x => x.id);
                this.filterRotationsRecord = this.setDefaultShowStaff(this.filterRotationsRecord, selectedIds);
            }
        });

        this.toolbarFilterAppliedSubscription = this.isToolbarFilterApplied$.subscribe((isApplied) => {
            if (isApplied) {
                let selectedOptions = this.generatePositionOptionsCombinations(this.filterRotationsRecord.positionOptions);
                this.defaultToolbarFilterOptions = selectedOptions;
                if (this.filterRotationsRecord.gridFilterOption.isSelected) {
                    this.defaultToolbarFilterOptions.push(this.filterRotationsRecord.gridFilterOption);
                }
            }
            else {
                let selectedIds = this.defaultToolbarFilterOptions.map(x => x.id);
                this.filterRotationsRecord = this.setPositionOptions(this.filterRotationsRecord, selectedIds);
            }
        });
    }

    public async getScheduleRotationAvailShifts(orgLevelId: number, startDate: any, endDate: any) {
        this.availShiftLoader = true;
        await this.scheduleRotationApiService.getScheduleRotationAvailShifts(orgLevelId, startDate, endDate).then((shifts: any) => {
            this.actualShiftData = shifts;
            this.filterShiftData = shifts;
            const availableShifts = this.mapService.mapScheduleRotationAvailShiftsData(shifts, this.weekOfDays);
            this.ShiftList = availableShifts;
            this.mapService.weeklyCount$.subscribe((count) => this.totalAvailShifts = count);
            this.availableShifts$.next(availableShifts);
            this.availShiftLoader = false;
            this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
        }).catch(() => {
            const availableShifts = this.mapService.mapScheduleRotationAvailShiftsData([], this.weekOfDays);
            this.mapService.weeklyCount$.subscribe((count) => this.totalAvailShifts = count);
            this.availableShifts$.next(availableShifts);
            this.availShiftLoader = false;
        });
    }

    public async getScheduleRotationPermission(orgLevelId: number) {
        this.scheduleRotationApiService.getScheduleRotationPermission(orgLevelId).then((permission) => {
            this.permissions$.next(permission);
        }).catch(() => {
        });
    }

    public async getEmployeeRotations(orgLevelId: number, startDate: any, endDate: any) {
        this.employeeLoader = true;
        await this.scheduleRotationApiService.getEmployeeRotation(orgLevelId, startDate, endDate).then((list: any) => {
            list.map(x => x.id = UUID());
            this.employeeList$.next(list);
            this.employeeLoader = false;
            this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
        }).catch(() => {
            this.employeeList$.next([]);
            this.employeeLoader = false;
        });
    }

    public get weekOfDays() {
        return this.currentWeek;
    }

    public getScheduleCycles() {
        this.lookupApi.getScheduleCycles(this.orgLevel.id).then((data: any) => {
            this.cycles = data;
            if (this.cycles.length > 0) {
                const today = moment().startOf('day');
                this.firstCycle = this.findPayCycle(this.cycles, today.toDate());
                this.dates = this.enumerateDaysBetweenDates(this.firstCycle.startDate, this.firstCycle.endDate);
                this.firstCycleStart = this.firstCycle ? this.firstCycle.startDate : today;
                this.dateFrom = this.firstCycleStart.toDate();
                this.payCycle.next(this.firstCycle);
            }
        });
    }

    public findPayCycle(cycles: ScheduleCycle[], date: Date): ScheduleCycle {
        const currentDate: moment.Moment = moment(date).startOf('day');
        let selectedCycle: ScheduleCycle = _.find(cycles, (cycle: ScheduleCycle) => {
            return currentDate.isSameOrAfter(cycle.startDate) && currentDate.isSameOrBefore(cycle.endDate);
        });
        return selectedCycle;
    }

    public onDateFromChanged(cycles: any, selectedDate: Date): void {
        if (cycles.length > 0) {
            let firstCycle = this.findPayCycle(cycles, selectedDate);
            this.dates = this.enumerateDaysBetweenDates(firstCycle.startDate, firstCycle.endDate);
            this.firstCycleStart = firstCycle ? firstCycle.startDate : selectedDate;
            this.firstCycle = firstCycle;
            this.dateFrom = this.firstCycleStart.toDate();
            this.payCycle.next(firstCycle);
        }
    }

    public onFilterChange() {
        this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
    }

    public filterNames(data: any) {
        return data.map((x) => { return x.name; });
    }

    public filterById(data: any) {
        return data.map((x) => { return x.id; });
    }

    public loadDataSubscription(data: any): void {
        this.loadDataSubscription$.next(data);
    }

    public subscribeToLoadDataSubscription(callback: (data: any) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.loadDataSubscription$.subscribe(callback);
    }

    public enumerateDaysBetweenDates(startDate, endDate) {
        let dates = [];
        const currDate = moment(startDate).startOf('day');
        const lastDate = moment(endDate).startOf('day');
        const day = moment(currDate).format('ddd');
        const currentData: IDays = {
            day: day,
            fullDayName: moment(currDate).format('dddd'),
            date: currDate.clone().toDate(),
            dayName: `${day}(${moment(currDate).format('MM/DD')})`,
            formatDate: moment(currDate).format('MM/DD/YYYY')
        };
        dates.push(currentData);
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            const day = moment(currDate).format('ddd');
            const data: IDays = {
                day: day,
                fullDayName: moment(currDate).format('dddd'),
                date: currDate.clone().toDate(),
                dayName: `${day}(${moment(currDate).format('MM/DD')})`,
                formatDate: moment(currDate).format('MM/DD/YYYY')
            };
            dates.push(data);
        }
        return dates;
    }

    public uniqueId(data: any[]) {
        _.forEach(data, (i, index) => {
            i.id = index + 1;
        });
    }

    public clearAllFilters() {
        this.selectedPos = [];
        this.selectedPosGroup = [];
        this.selectedShiftGroup = [];
        this.selectedUnit = [];
    }

    public isPrimaryChange() {
        this.employeeList$.subscribe((res: any) => {
            const primaryPositionData = res;
        });
    }

    public weeklyHours(employee, currentWeek) {
        _.forEach(employee, (emp) => {
            let totalHours: any = 0.00;
            _.forEach(emp.scheduleDays, (days) => {
                _.forEach(currentWeek, (week) => {
                    if (week.formatDate === days.day.formatDate) {
                        _.forEach(days.shifts, (total) => totalHours += total.hours);
                    }
                });
            });
            emp.totalHours = parseFloat(totalHours).toFixed(2);
            this.totalHours = emp.totalHours;
            emp.isOverTime = this.totalHours > emp.otHours ? true : false;
        });
    }

    public shiftReducer(data) {
        this.empScheduleEntries = this.mapService.employeeScheduleEntriesCheck(data);
        data[1].scheduleDate = data[2].formatDate;
        let empIndex = data[0].scheduleDays.findIndex(x => x.day.formatDate === data[1].scheduleDate);
        let shiftColIndex = data[3].findIndex(x => x.day.formatDate === data[1].scheduleDate);
        let shiftIndex = data[3][shiftColIndex].shifts.findIndex(x => x.id === data[1].id && x.weekDay === data[1].weekDay && x.shiftId === data[1].shiftId && x.unitId === data[1].unitId && x.jobCode === data[1].jobCode);
        data[3][shiftColIndex].shifts[shiftIndex].scheduleDate = data[1].scheduleDate;
        this.updateAvailShiftsData(data[3], data[1], data[2], empIndex, shiftColIndex, shiftIndex);
        this.updateEmployeeRecord(data[0], empIndex, data[1]);
        this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
        this.setNotifyChanges();
    }

    private setNotifyChanges() {
        if (this.empScheduleEntries.length > 0 || this.assignedEmployeeAndEmptySlotEmployee.length > 0) {
            this.changeManagementService.changeNotify();
        } else {
            this.changeManagementService.clearChanges();
        }
    }

    private updateAvailShiftsData(availShifts, shift, day, empIndex, shiftColIndex, shiftIndex) {
        if (shift.count > 1) {
            shift.count--;
            availShifts[shiftColIndex].shifts[shiftIndex].count--;
            this.totalAvailShifts--;
            this.updateAction(availShifts);
        }
        else {
            let availShiftsData = availShifts;
            let filteredShift = availShiftsData[shiftColIndex].shifts.filter(x => x.scheduleDate === null);
            availShiftsData[shiftColIndex].shifts = [];
            availShiftsData[shiftColIndex].shifts = filteredShift;
            this.filterShiftData = this.filterShiftData.filter(x => x.id != shift.id);
            this.actualShiftData = this.actualShiftData.filter(x => x.id != shift.id);
            const filterData = this.mapService.mapScheduleRotationAvailShiftsData(this.actualShiftData, this.weekOfDays);
            this.mapService.weeklyCount$.subscribe((count) => this.totalAvailShifts = count);
            this.availableShifts$.next(filterData);
        }
    }

    private updateEmployeeRecord(employee, empIndex, shift) {
        employee.scheduleDays[empIndex].shifts.push(shift);
    }

    private updateAction(availShifts) {
        this.mapService.weeklyCount$.subscribe((count) => this.totalAvailShifts = count);
        this.availableShifts$.next(availShifts);
    }

    public deleteShiftFromEmployee(data) {
        this.empScheduleEntries = this.mapService.employeeScheduleEntriesCheck(data);
        let empIndex = data[0].scheduleDays.findIndex(x => x.day.formatDate === data[2].formatDate);
        let availShiftIndex = data[3].findIndex(x => x.day.formatDate === data[1].scheduleDate);
        data[0].scheduleDays[empIndex].shifts = data[0].scheduleDays[empIndex].shifts.filter(x => x.id != data[1].id);
        let addData = data[3][availShiftIndex].shifts.filter(x => x.id === data[1].id && x.weekDay === data[1].weekDay && x.shiftId === data[1].shiftId && x.unitId === data[1].unitId && x.jobCode === data[1].jobCode);
        if (addData.length === 0) {
            if (data[1].isAssignedShift) {
                data[0].totalHours -= data[1].hours;
                data[1].scheduleDate = null;
                this.setNotifyChanges();
                return;
            }
            data[0].totalHours -= data[1].hours;
            data[1].scheduleDate = null;
            data[3][availShiftIndex].shifts.push(data[1]);
            // this.filterShiftData.push(data[1]);
            this.actualShiftData.push(data[1]);
            const filterData = this.mapService.mapScheduleRotationAvailShiftsData(this.actualShiftData, this.weekOfDays);
            this.mapService.weeklyCount$.subscribe((count) => this.totalAvailShifts = count);
            this.availableShifts$.next(filterData);
        }
        else {
            let addDataIndex = data[3][availShiftIndex].shifts.findIndex(x => x.id === data[1].id && x.weekDay === data[1].weekDay && x.shiftId === data[1].shiftId && x.unitId === data[1].unitId && x.jobCode === data[1].jobCode);
            data[0].totalHours -= data[1].hours;
            data[3][availShiftIndex].shifts[addDataIndex].scheduleDate = null;
            data[3][availShiftIndex].shifts[addDataIndex].count++;
            this.totalAvailShifts++;
            this.updateAction(data[3]);
        }
        this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
        this.setNotifyChanges();
    }

    public updateDeletedData(employee, shift, date): EmployeeScheduledData {
        let deletedData: EmployeeScheduledData = new EmployeeScheduledData();
        deletedData.employeeId = employee.employeeId;
        deletedData.shiftId = shift.shiftId;
        deletedData.unitId = shift.unitId;
        deletedData.jobCode = shift.jobCode;
        deletedData.scheduledDate = date.formatDate;
        deletedData.deleteInd = true;
        return deletedData;
    }

    public async saveRotations(orgLevelId: number) {
        this.employeeLoader = true;
        this.isEditViewSaveDisable = true;
        this.termEmployeeData = this.setEmployeeTermData();
        this.empScheduleEntries = this.empScheduleEntries ? this.empScheduleEntries : [];
        let resultData = this.employeeEntryDataProcess(this.empScheduleEntries, this.assignedEmployeeAndEmptySlotEmployee);
        let saveRotationData: SaveRotationData = new SaveRotationData();
        saveRotationData.empSaveRecord = this.empScheduleEntries;
        saveRotationData.empTermRecord = this.termEmployeeData;
        if (resultData.length > 0 || this.assignEmployeeToEmptySlot.length > 0) {
            let slottedData = _.filter(this.assignEmployeeToEmptySlot, (item: AssignEmpToEmptySlot) => item.slotId !== 0);
            if(slottedData.length > 0 && resultData.length > 0) {
                this.saveEmpRotationAndEmptySlotRotationRecords(orgLevelId, resultData, slottedData);
            }
            else if(resultData.length > 0) {
                this.saveEmpRecordsRotation(orgLevelId, resultData);
            }
            else if(slottedData.length > 0) {
                this.saveEmptyRotationAssignedRecords(slottedData);
            }
            else {
                this.employeeLoader = false;
                this.isNewRotationAdded = false;
                this.assignedEmployeeAndEmptySlotEmployee = [];
                this.getEmployeeRotations(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
                this.getScheduleRotationAvailShifts(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
                this.getNoRotationEmployeeList(orgLevelId, this.firstCycle.startDate, this.firstCycle.endDate);
                this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
            }
        }
        else {
            this.employeeLoader = false;
        }
    }

    public async saveEmpRecordsRotation(orgLevelId, saveRotationData) {
        await this.scheduleRotationApiService.saveEmployeeRotation(orgLevelId, this.firstCycle.startDate, this.firstCycle.endDate, saveRotationData).then((list) => {
            this.generateRotationEmployees = [orgLevelId, this.empScheduleEntries, this.firstCycle.startDate];
            this.empScheduleEntries = [];
            this.mapService.employeeScheduledEntries = [];
            this.assignedEmployeeAndEmptySlotEmployee = [];
            this.termEmployeeData = [];
            this.emptyScheduleDates = this.setEmptyScheduleShift();
            list.map(x => x.scheduleDays.map(y => y.shifts.map(z => z.count = z.count ? z.count : 1)));
            list.map(x => x.id = UUID());
            this.employeeList$.next(list);
            this.getNoRotationEmployeeList(orgLevelId, this.firstCycle.startDate, this.firstCycle.endDate);
            this.getScheduleRotationAvailShifts(orgLevelId, this.firstCycle.startDate, this.firstCycle.endDate);
            this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
            this.employeeLoader = false;
            this.isEditViewSaveDisable = true;
            this.isNewRotationAdded = false;
            this.generateRotations();
        }).catch(() => {
        });
    }

    public async saveEmptyRotationAssignedRecords(empTermRecord) {
        await this.scheduleRotationApiService.assignTerminatedSlotToEmployee(empTermRecord).then(() => {
            // if (this.generateRotationRecord && (this.orgLevel.id === this.generateRotationRecord.orgLevelId)) {
            let empIds = _.map(empTermRecord, (i) => i.employeeId);
            this.generateRotationRecord.empIds.push(...empIds);
            this.setSessionStorageforGenerateRotation(this.generateRotationRecord);
            // }
            this.assignedEmployeeAndEmptySlotEmployee = [];
            this.assignEmployeeToEmptySlot = [];
            this.employeeLoader = false;
            this.isEditViewSaveDisable = true;
            this.isNewRotationAdded = false;
            this.getEmployeeRotations(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
            this.getScheduleRotationAvailShifts(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
            this.getNoRotationEmployeeList(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
            this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
        });
    }

    public async saveEmpRotationAndEmptySlotRotationRecords(orgLevelId, saveRotationData, empTermRecord) {
        await this.scheduleRotationApiService.saveEmpRotationAndEmptySlotRotation(orgLevelId, this.firstCycle.startDate, this.firstCycle.endDate, saveRotationData, empTermRecord).then((list) => {
            this.generateRotationEmployees = [orgLevelId, this.empScheduleEntries, this.firstCycle.startDate];
            this.empScheduleEntries = [];
            this.mapService.employeeScheduledEntries = [];
            this.termEmployeeData = [];
            this.emptyScheduleDates = this.setEmptyScheduleShift();
            list.map(x => x.scheduleDays.map(y => y.shifts.map(z => z.count = z.count ? z.count : 1)));
            list.map(x => x.id = UUID());
            this.employeeList$.next(list);
            this.generateRotations();

            let employeeIds = _.map(empTermRecord, (i) => i.employeeId);
            this.generateRotationRecord.empIds.push(...employeeIds);
            this.setSessionStorageforGenerateRotation(this.generateRotationRecord);
            this.assignedEmployeeAndEmptySlotEmployee = [];
            this.assignEmployeeToEmptySlot = [];
            this.employeeLoader = false;
            this.isEditViewSaveDisable = true;
            this.isNewRotationAdded = false;
            this.getEmployeeRotations(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
            this.getScheduleRotationAvailShifts(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
            this.getNoRotationEmployeeList(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
            this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
        }).catch(() => {
            this.employeeLoader = false;
        });
    }

    public generateRotations() {
        let uniqueData = _.uniqBy(this.generateRotationEmployees[1], (e) => {
            return e['employeeId'];
        });
        let generateRotationsRecord: EmployeesGenerateRotation = new EmployeesGenerateRotation();
        generateRotationsRecord.orgLevelId = this.orgLevel.id;
        generateRotationsRecord.empIds = _.map(uniqueData, (item: any) => item.employeeId);
        generateRotationsRecord.startDate = moment(this.firstCycle.startDate).format('MM/DD/YYYY');
        generateRotationsRecord.empIds = _.filter(generateRotationsRecord.empIds, (i) => i !== 0)
        this.generateRotationRecord = generateRotationsRecord;
        this.setSessionStorageforGenerateRotation(this.generateRotationRecord);
    }

    public async getNoRotationEmployeeList(orgLevelId: number, startDate: any, endDate: any) {
        await this.scheduleRotationApiService.getNoRotationEmployeeList(orgLevelId, startDate, endDate).then((list) => {
            this.noRotationEmployeeList$.next(list);
        }).catch(() => {
        });
    }

    public async saveGenerateRotation() {
        this.employeeLoader = true;
        await this.scheduleRotationApiService.generateScheduleRotation(this.generateRotationRecord).then((result) => {
            this.generateRotationEmployees = [];
            this.generateRotationRecord = null;
            this.removeSessionStorageforGenerateRotation();
        })
            .catch((err) => {
                console.log(err);
            })
        setTimeout(() => {
            this.employeeLoader = false;
        }, 3000);
    }

    public async futureDateValidation(data: FutureValidationShift, employee: ScheduleRotationTemplate, shift: IAvailShifts, day: IDays) {
        this.employeeLoader = true;
        this.isEditViewSaveDisable = true;
        await this.scheduleRotationApiService.futureDateValidation(data).then((result: any) => {
            this.frequencyResultData = result;
            let overLappingData: any[] = [];
            let overTimeData: any[] = [];
            if (this.frequencyResultData) {
                _.forEach(this.frequencyResultData, (item) => {
                    if (item.errorMessage === 'Overlapping') {
                        overLappingData.push(item);
                    }
                    if (item.errorMessage === 'Overtime') {
                        overTimeData.push(item);
                    }
                })
            }
            if (overLappingData.length > 0) {
                this.shiftOverLapMessage(overLappingData, employee, shift);
            }
            if (overLappingData.length === 0 && overTimeData.length > 0) {
                this.shiftOverTimeMessage(overTimeData, employee, shift);
            }
            this.employeeLoader = false;
            this.isEditViewSaveDisable = false;
        });
    }

    public setEmployeeTermData(): any[] {
        let sampleData: any = [];
        this.assignedEmployeeAndEmptySlotEmployee.forEach((d, ind) => {
            d.scheduleDays.forEach((x) => {
                if (x.shifts.length > 0) {
                    x.shifts.forEach(y => {
                        // if (!y.isAssignedShift || d.isNewRotation) {
                        let data: TerminateRequest = new TerminateRequest();
                        data.employeeId = d.employeeId;
                        data.isNew = y.isAssignedShift ? true : false;
                        data.slotId = d.slotId ? d.slotId : 0;
                        data.seqId = y.isAssignedShift ? ind : this.emptyAddRotationSequenceCount;
                        data.scheduledDate = y.calendarFormatDate;
                        data.shiftId = y.shiftId;
                        data.unitId = y.unitId === 100001 ? -1 : y.unitId;
                        data.jobCode = y.jobCode;
                        data.frequency = y.scheduleRotationCount;
                        data.deleteInd = false;
                        sampleData.push(data);
                        // }
                    })
                }
            })
        });
        let uniqueData = _.uniqBy(sampleData, (e) => {
            return e['employeeId'];
        });
        return uniqueData;
    }

    public setSessionStorageforGenerateRotation(generateRotation: EmployeesGenerateRotation) {
        this.removeSessionStorageforGenerateRotation();
        sessionStorage.setItem('SrtGenerateRotation', JSON.stringify(generateRotation));
    }

    public getSessionStorageforGenerateRotation() {
        const data = sessionStorage.getItem('SrtGenerateRotation');
        if (data) {
            this.generateRotationRecord = JSON.parse(data);
        }
    }

    public removeSessionStorageforGenerateRotation() {
        sessionStorage.removeItem('SrtGenerateRotation');
    }

    public updateModifiedEntry(employee, shift) {
        if (this.empScheduleEntries) {
            let empScheduleIndex = this.empScheduleEntries.findIndex((data) => data.employeeId === employee.employeeId && data.deleteInd === false && data.scheduledDate === shift.scheduleDate);
            if (empScheduleIndex >= 0) {
                this.empScheduleEntries[empScheduleIndex].frequency = shift.frequency;
                this.empScheduleEntries[empScheduleIndex].dayNumber = shift.dayNumber;
            }
            else {
                let dto: EmployeeScheduledData = <EmployeeScheduledData>{};
                dto.employeeId = employee.employeeId;
                dto.jobCode = shift.jobCode;
                dto.unitId = shift.unitId === 100001 ? -1 : shift.unitId;
                dto.shiftId = shift.shiftId;
                dto.scheduledDate = moment(shift.calendarFormatDate).format('MM/DD/YYYY');
                dto.deleteInd = false;
                dto.frequency = shift.frequency;
                dto.dayNo = shift.dayNo;
                this.empScheduleEntries.push(dto);
            }
        }
        else {
            let dto: EmployeeScheduledData = <EmployeeScheduledData>{};
            dto.employeeId = employee.employeeId;
            dto.jobCode = shift.jobCode;
            dto.unitId = shift.unitId === 100001 ? -1 : shift.unitId;
            dto.shiftId = shift.shiftId;
            dto.scheduledDate = moment(shift.calendarFormatDate).format('MM/DD/YYYY');
            dto.deleteInd = false;
            dto.frequency = shift.frequency;
            dto.dayNo = shift.dayNo;
            this.empScheduleEntries.push(dto);
        }
        return this.empScheduleEntries;
    }

    public employeeEntryDataProcess(empEntries, assignedEmployees) {
        _.forEach(empEntries, (a) => {
            _.forEach(assignedEmployees, (b) => {
                if (a.employeeId === 0) {
                    _.forEach(b.scheduleDays, (c) => {
                        if (c.day.formatDate === a.scheduledDate) {
                            _.forEach(c.shifts, (d) => {
                                if (d.shiftId === a.shiftId && !d.isAssignedShift) {
                                    a.employeeId = b.employeeId;
                                    a.isNewlyAssigned = true;
                                }
                            })
                        }
                    })
                }
            })
        })
        return empEntries;
    }


    public setEmptyScheduleShift() {
        _.map(this.emptyScheduleDates, (item) => item.shifts = []);
        return this.emptyScheduleDates;
    }

    public setDefaultShowStaff(filterOptions, ids) {
        filterOptions.showStaff.map(x => x.isSelected = ids.includes(x.id) ? true : false);
        return filterOptions;
    }

    public setPositionOptions(filterOptions, ids) {
        filterOptions.positionOptions.primaryPosition.isSelected = ids.includes(filterOptions.positionOptions.primaryPosition.id) ? true : false;
        filterOptions.positionOptions.agencyStaff.isSelected = ids.includes(filterOptions.positionOptions.agencyStaff.id) ? true : false;
        filterOptions.positionOptions.emptyRotation.isSelected = ids.includes(filterOptions.positionOptions.emptyRotation.id) ? true : false;
        filterOptions.positionOptions.secondaryPosition.options.map(x => x.isSelected = ids.includes(x.id) ? true : false);
        filterOptions.positionOptions.secondaryPosition.isSelectAll = filterOptions.positionOptions.secondaryPosition.options.some(x => x.isSelected);
        filterOptions.gridFilterOption.isSelected = ids.includes(filterOptions.gridFilterOption.id) ? true : false;
        return filterOptions;
    }
    public DeleteEmptyRotation(slotId: number): Promise<any> {
        this.employeeLoader = true;
        return this.scheduleRotationApiService.removeSlotRotations(slotId)
    }

    get isSaveDisable() {
        if (this.isEditViewSaveDisable) { return true; }
        return this.empScheduleEntries.length === 0 && this.assignEmployeeToEmptySlot.length === 0;
    }

    public get isAddNewRotationDisable() {
        return this.isNewRotationAdded;
    }

    public setMandatoryChecksForFilter() {
        this.isNewRotationAdded = true;
        let ids = [3, 4];
        this.filterRotationsRecord.showStaff = this.filterRotationsRecord.showStaff.map(item => {
            if (ids.includes(item.id)) {
                item.isSelected = true;
            }
            return item;
        });
        this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
    }

}
