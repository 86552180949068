import * as _ from 'lodash';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { KendoGridStateHelper, removeEvent, saveEvent } from '../../../../../../common';
import { Subscription } from 'rxjs/Subscription';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { EmployeeSectionsBenefitsManagementApiService } from '../../../../services';
import { EmpBeneficiaryRelation } from '../../../../models/employee-sections-benefits-management/beneficiary-relation';
import { Beneficiary } from '../../../../models/employee-sections-benefits-management/beneficiary';
import { unsubscribeAll } from '../../../../../../core/decorators';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';

@Component({
  selector: 'slx-beneficiary-editor',
  templateUrl: './beneficiary-editor.component.html',
  styleUrls: ['./beneficiary-editor.component.scss']
})
export class BeneficiaryEditorComponent implements OnInit {

  @Input()
  public set enrollmentId(value: number) {
    this.m_enrollmentId = value;
    if (this.m_enrollmentId) {
      this.loadBeneficiaries(this.m_enrollmentId);
    } else {
      this.beneficiaries = [];
    }
  }

  public get canSave(): boolean {
    return (!this.isLoading && !this.editedItem && this.primaryTotal == 100 && (this.contCount > 0 ? this.contTotal == 100 : true) 
            || (this.primCount == 0 && this.contCount == 0 && this.beneficiariesInitCount > 0));
  }

  public get canCancel(): boolean {
    return !this.isLoading;
  }

  public get isEditing(): boolean {
    return (this.editedItem != null);
  }

  public beneficiaries: Beneficiary[];
  public loadedBeneficiaries: Beneficiary[];
  public prevBeneficiaries: Beneficiary[];
  public relations: EmpBeneficiaryRelation[];
  public selectedPrevBeneficiary: Beneficiary;
  public defaultItem: { fullName: string, id: number } = { fullName: "", id: null };

  // rights
  public canAdd: boolean = true;
  public canEdit: boolean = true;
  public canDelete: boolean = true;

  // configuration
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<any>;
  public gridView: GridDataResult;
  public pageSize: number = 5;
  public namePattern = /[^\w\s]|[_]/gi;
  public addressPattern = /[^a-zA-Z\d\s:]/gi;

  public isLoading: boolean = false;

  public incompleteWarning: string = 'Total for each type of beneficiaries must add up to 100%.';
  public noPrimaryWarning: string = 'Primary beneficiary is required';


  public primaryTotal: number = 0;
  public primCount: number = 0;
  public contTotal: number = 0;
  public contCount: number = 0;
  public beneficiariesInitCount: number = 0;


  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  private ngFormChild: NgForm;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  private editedItem: Beneficiary;
  private m_enrollmentId: number;

  constructor(private modalService: ModalService, private apiService: EmployeeSectionsBenefitsManagementApiService) {
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<any>();
    this.gridState.state.sort = [
      { field: 'startDate', dir: 'desc' }
    ];
  }

  public ngOnInit(): void {

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.subscriptions.gridEdit = this.gridState.onEdit$
      .subscribe((record: any) => {
        this.editedItem = record;
      });

    this.subscriptions.gridCancel = this.gridState.onCancel$
      .subscribe(() => {
        this.editedItem = null;
      });


    this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<Beneficiary>) => {
      if (event.isNew) {
        this.doAdd(event.dataItem);
        return;
      }
      this.doEdit(event.dataItem, event.rowIndex);
    });

    this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<Beneficiary>) => {

      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      options.buttonOKtext = 'Yes';
      ConfirmDialog2Component.openDialog(
        'Delete Beneficiary',
        'Are you sure you want to delete the beneficiary?',
        this.modalService,
        (isDelete: boolean) => {
          if (isDelete) {
            this.doRemove(event.dataItem, event.rowIndex);
          }
        },
        options);

    });

  }


  public ngOnDestroy(): void { }

  protected doRemove(dataItem: Beneficiary, rowIndex: number): void {

    this.beneficiaries = _.without(this.beneficiaries, dataItem);
    this.updateTotals();
    this.refreshGrid();
  }

  protected doEdit(dataItem: Beneficiary, rowIndex: number): void {
    this.updateTotals();
    this.editedItem = null;
  }

  protected doAdd(dataItem: Beneficiary): void {
    this.beneficiaries.push(dataItem);
    this.updateTotals();
    this.editedItem = null;
  }

  public async loadBeneficiaries(enrollmentId: number): Promise<void> {
    this.isLoading = true;
    let beneficiaryPromise = this.apiService.getBeneficiaries(enrollmentId);
    let prevBeneficiaryPromise = this.apiService.getBeneficiariesPrev(enrollmentId);
    try {
      let results: any[] = await Promise.all([beneficiaryPromise, prevBeneficiaryPromise]);
      this.beneficiaries = results[0].beneficiaries;
      this.loadedBeneficiaries = results[0].beneficiaries;
      this.prevBeneficiaries = results[1].beneficiaries;
      this.relations = results[0].relations;
      this.beneficiariesInitCount = _.size(results[0].beneficiaries);
    } catch (e) {
      this.beneficiaries = [];
      this.prevBeneficiaries = [];
      this.relations = [];
    } finally {
      this.isLoading = false;
      this.refreshGrid();
    }

    this.updateTotals();
  }

  public onChangePrevBeneficiary(b: Beneficiary): void {
    if (b.id != null) {
      this.selectedPrevBeneficiary = b;
    } else {
      this.selectedPrevBeneficiary = null;
    }
  }

  public isEditingRow(b: Beneficiary): boolean {
    if (_.isObjectLike(this.editedItem) && _.isObjectLike(b)) {
      return _.isEqualWith(this.editedItem, b, (value, other) => {
        return value.id == other.id
          && value.empToBenefitsId == other.empToBenefitsId
      })
    }

    return false;
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  public addPrimary(): void {
    let newBeneficiary = new Beneficiary();
    newBeneficiary.isPrimary = true;
    newBeneficiary.empToBenefitsId = this.m_enrollmentId;

    if (this.selectedPrevBeneficiary) {
      newBeneficiary.lastName = this.selectedPrevBeneficiary.lastName;
      newBeneficiary.firstName = this.selectedPrevBeneficiary.firstName;
      newBeneficiary.birthDate = this.selectedPrevBeneficiary.birthDate;
      newBeneficiary.address = this.selectedPrevBeneficiary.address;
      newBeneficiary.ssn = this.selectedPrevBeneficiary.ssn;
      newBeneficiary.relation = this.selectedPrevBeneficiary.relation;
      newBeneficiary.primaryPercentage = this.selectedPrevBeneficiary.primaryPercentage;
      newBeneficiary.contigentPercentage = 0;
    }

    if (!newBeneficiary.birthDate) {
      newBeneficiary.birthDate = new Date()
    }

    if (!newBeneficiary.relation) {
      let relation = new EmpBeneficiaryRelation();
      relation.id = 'Child';
      relation.name = 'Child';
      newBeneficiary.relation = relation;
    }

    newBeneficiary.id = null;
    this.editedItem = newBeneficiary;
    this.grid.addRow(newBeneficiary);
  }

  public addContingent(): void {
    let newBeneficiary = new Beneficiary();
    newBeneficiary.isPrimary = false;
    newBeneficiary.empToBenefitsId = this.m_enrollmentId;
    if (this.selectedPrevBeneficiary) {
      newBeneficiary.lastName = this.selectedPrevBeneficiary.lastName;
      newBeneficiary.firstName = this.selectedPrevBeneficiary.firstName;
      newBeneficiary.birthDate = this.selectedPrevBeneficiary.birthDate;
      newBeneficiary.address = this.selectedPrevBeneficiary.address;
      newBeneficiary.ssn = this.selectedPrevBeneficiary.ssn;
      newBeneficiary.relation = this.selectedPrevBeneficiary.relation;
      newBeneficiary.contigentPercentage = this.selectedPrevBeneficiary.contigentPercentage;
      newBeneficiary.primaryPercentage = 0;
    }

    if (!newBeneficiary.birthDate) {
      newBeneficiary.birthDate = new Date()
    }

    if (!newBeneficiary.relation) {
      let relation = new EmpBeneficiaryRelation();
      relation.id = 'Child';
      relation.name = 'Child';
      newBeneficiary.relation = relation;
    }

    newBeneficiary.id = null;
    this.editedItem = newBeneficiary;
    this.grid.addRow(newBeneficiary);
  }


  public onStartEditPrimary(item: Beneficiary): void {
    item.contigentPercentage = 0;
  }

  public onStartEditCont(item: Beneficiary): void {
    item.primaryPercentage = 0;
  }

  public onPrimaryChange(item: Beneficiary): void {
    this.updateTotals();
  }

  public onContChange(item: Beneficiary): void {
    this.updateTotals();
  }

  public updateTotals(): void {
    this.primaryTotal = 0;
    this.contTotal = 0
    this.contCount = 0;
    this.primCount = 0;
    _.each(this.beneficiaries, (b: Beneficiary) => {
      this.primaryTotal += b.primaryPercentage ? b.primaryPercentage : 0;
      this.contTotal += b.contigentPercentage ? b.contigentPercentage : 0;
      if (!b.isPrimary) {
        this.contCount++;
      } else {
        this.primCount++;
      }
    });
  }

  public async saveBeneficiaries(): Promise<boolean> {

    if ((this.primaryTotal > 0 && this.primaryTotal != 100) && (this.contTotal > 0 && this.contTotal != 100)) {
      return false;
    }

    this.isLoading = true;

    let toAdd = _.filter(this.beneficiaries, b => _.isNil(b.id));
    let toUpdate = _.without(this.beneficiaries, ...toAdd);
    let toDelete = _.without(this.loadedBeneficiaries, ...this.beneficiaries);
    toDelete = _.filter(toDelete, d => !_.isNil(d.id));
    _.each(toDelete, b => b.isDeleted = true);

    const toSave = _.concat(toUpdate, toAdd, toDelete);

    try {
      await this.apiService.updateBeneficiaries(toSave);
    } catch (e) {
      console.error(e);
      this.isLoading = false;
      return false;
    }

    this.isLoading = false;
    return true;

  }

  private refreshGrid(): void {

    if (!this.beneficiaries) {
      this.gridView = null;
      return;
    }
    this.gridState.view = process(this.beneficiaries, this.gridState.state);
  }

}
