import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { BreadcrumbStateService } from '../../../core/services/index';
import { IBreadcrumbItemConfig, BreadcrumbItem } from '../../../core/models/index';
import { unsubscribe } from '../../../core/decorators/index';

@Component({
    moduleId: module.id,
    selector: 'slx-header-history-bar',
    templateUrl: 'header-history-bar.component.html',
    styleUrls: ['header-history-bar.component.scss']
})

export class HeaderHistoryBarComponent implements OnInit, OnDestroy {

    public item: BreadcrumbItem;

    @unsubscribe()
    private serviceSubscription: Subscription;

    constructor(private breadcrumbService: BreadcrumbStateService) { }

    public ngOnInit(): void {
        this.serviceSubscription = this.breadcrumbService.changeNotify$.subscribe(() => {
            this.updateLabel();
        });
        this.updateLabel();
    }

    public ngOnDestroy(): void {
      // See #issueWithAOTCompiler
    }

    private updateLabel(): void {
        if (this.breadcrumbService) {
            let urls: BreadcrumbItem[] = this.breadcrumbService.breadcrumbs;
            if (urls && urls.length > 0) {
                this.item = _.last(urls);
                return;
            }
        }
        this.item = null;
    }
}
