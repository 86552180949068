import { TemplateRef, ViewContainerRef } from '@angular/core';

export class SwitchView {
  private _created = false;

  constructor(
    private _viewContainerRef: ViewContainerRef, private _templateRef: TemplateRef<Object>) { }

  create(): void {
    this._created = true;
    this._viewContainerRef.createEmbeddedView(this._templateRef);
  }

  destroy(): void {
    this._created = false;
    this._viewContainerRef.clear();
  }

  enforceState(created: boolean) {
    if (created && !this._created) {
      this.create();
    } else if (!created && this._created) {
      this.destroy();
    }
  }
}
