import { ITImecardSummarySectionContainer } from './timecard-summary-section-container.interface';
import { Position, Organization, Department, EmployeeDefinition } from '../../../organization/models/index';

export class PositionsSummaryContainer implements ITImecardSummarySectionContainer {
    public records: TimecardSummarySecondaryPosition[];
}

export class TimecardSummarySecondaryPosition {
    public employee: EmployeeDefinition;
    public organization: Organization;
    public department: Department;
    public position: Position;
    public secondaryOrganization: Organization;
    public secondaryDepartment: Department;
    public secondaryPosition: Position;
    public rate: number;
    public hours: number;

    public get positionPath(): string {
      const posName = this.position && this.position.name || '';
      const orgName = this.organization && this.organization.name || '';
      const depName = this.department && this.department.name || '';

      if (orgName.length > 0 && depName.length > 0) {
        return `${orgName} > ${depName} > ${posName}`;
      }
      return posName;
    }

    public get secondaryPositionPath(): string {
      const posName = this.secondaryPosition && this.secondaryPosition.name || '';
      const orgName = this.secondaryOrganization && this.secondaryOrganization.name || '';
      const depName = this.secondaryDepartment && this.secondaryDepartment.name || '';

      if (orgName.length > 0 && depName.length > 0) {
        return `${orgName} > ${depName} > ${posName}`;
      }
      return posName;
    }
}
