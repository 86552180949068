<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <slx-ess-templates-header class="slx-content-toolbar-indents"></slx-ess-templates-header>
    <div class="slx-high-box__body slx-main-content-indents ess-templates-content" *ngIf="!!punchAttestationGroups">
      <div class="slx-ess-template-sections" *ngFor="let template of data">
          <slx-ess-template-section [template]="template" [punchAttestationGroups]="punchAttestationGroups"></slx-ess-template-section>
      </div>
    </div>
  </div>
</slx-spinner>
