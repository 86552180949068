import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Assert } from '../../../../framework/index';
import {
  IEmployeeSectionsCustomFields,
  IEmployeeSectionsCustomField,
  EmployeeSectionsCustomFields,
  EmployeeSectionsCustomField,
  IEmployeeSectionsCustom,
  EmployeeSectionsCustom
} from '../../models/index';
import { IFieldData, FieldRenderType, FieldsMeta } from '../../../../core/models/index';
import { MetaMapService } from '../../../../core/services/index';
import { dateTimeUtils } from './../../../../common/utils';

@Injectable()
export class EmployeeSectionsCustomMapService {
  private metaMapService: MetaMapService;

  constructor(metaMapService: MetaMapService) {
    Assert.isNotNull(metaMapService, 'metaMapService');
    this.metaMapService = metaMapService;
  }

  public mapToCustomField<T>(dfield: IEmployeeSectionsCustomField, metaMap: StringMap<IFieldData>): EmployeeSectionsCustomField<T> {
    let field: EmployeeSectionsCustomField<T> = new EmployeeSectionsCustomField<T>();
    field.fieldData = this.metaMapService.mapMeta<T>(metaMap, dfield.name, dfield.value);
    field.displayName = dfield.displayName;
    field.fieldType = dfield.fieldType;
    field.id = dfield.id;
    field.seq = dfield.seq;
    field.dataTypeLength = dfield.dataTypeLength;
    field.dataType = this.getAccrualsEligbilityDateType(dfield);
    field.dataTypePrecision = dfield.dataTypePrecision;
    return field;
  }

  public getAccrualsEligbilityDateType(field: IEmployeeSectionsCustomField) {
    return field.displayName == "AccrualsEligibilityDate" ? field.dataType = "date" : field.dataType;
  }

  public mapToCustomFields(dfields: IEmployeeSectionsCustomFields, meta: FieldsMeta): EmployeeSectionsCustomFields {
    Assert.isNotNull(dfields, 'dphields');
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    let fields: EmployeeSectionsCustomFields = new EmployeeSectionsCustomFields();

    fields.specialConsideration = this.metaMapService.mapMeta<string>(metaMap, 'specialConsideration', dfields.specialConsideration);
    fields.specialConsideration2 = this.metaMapService.mapMeta<string>(metaMap, 'specialConsideration2', dfields.specialConsideration2);
    
    _.forEach(dfields.fields, (dfield: IEmployeeSectionsCustomField) => {
      let field: EmployeeSectionsCustomField<any>;
      switch (dfield.fieldType) {
        case 'char':
        case 'string':
          field = this.mapToCustomField<string>(dfield, metaMap);
          field.fieldRender = FieldRenderType.string;
          break;
        case 'number':
          field = this.mapToCustomField<number>(dfield, metaMap);
          field.fieldRender = FieldRenderType.number;
          break;

        case 'datetime':
          field = this.mapToCustomField<Date>(dfield, metaMap);
          field.fieldRender = FieldRenderType.datetime;
          break;
        default:
          field = this.mapToCustomField<string>(dfield, metaMap);
          field.fieldRender = FieldRenderType.string;
          break;
      }

      let seq: number = _.sortedIndexBy(fields.fields, field, (ol: EmployeeSectionsCustomField<any>): number => {
        return ol.seq;
      });
      fields.fields.splice(seq, 0, field);
    });
    fields.actions = this.metaMapService.mapActions(meta);
    fields.fieldsMeta = meta;
    return fields;
  }

  public mapToCustomFieldsDto(fields: EmployeeSectionsCustomFields): IEmployeeSectionsCustomFields {
    Assert.isNotNull(fields, 'fields');
    let dfieldsArray: IEmployeeSectionsCustomField[] = [];
    _.forEach(fields.fields, (field: EmployeeSectionsCustomField<any>) => {
      if(field.dataType.toLowerCase() === 'bit' || field.dataType.toLowerCase() === 'char') {
        field.fieldData.fieldValue = field.fieldData.fieldValue.id;
      }
      if (field.dataType.toLowerCase() === 'date') {
        if (!_.isNil(field.fieldData.fieldValue)) {
          field.fieldData.fieldValue = moment(new Date(field.fieldData.fieldValue)).format('MM/DD/YYYY');
        }
        else {
          field.fieldData.fieldValue = null;
        }
      }
      let dfield: IEmployeeSectionsCustomField = {
        id: field.id,
        name: field.fieldData.fieldName,
        displayName: field.displayName,
        value: this.setAccrualsEligbilityDateNullType(field),
        fieldType: field.fieldType,
        renderType: field.fieldType,
        seq: field.seq,
        dataType: field.dataType,
        dataTypeLength: field.dataTypeLength,
        dataTypePrecision: field.dataTypePrecision
      };
      dfieldsArray.push(dfield);
    });
    let dfields: IEmployeeSectionsCustomFields = {
      fields: dfieldsArray,
      specialConsideration: fields.specialConsideration.fieldValue,
      specialConsideration2: fields.specialConsideration2.fieldValue
    };
    return dfields;
  }

  public setAccrualsEligbilityDateNullType(field: EmployeeSectionsCustomField<any>) {
    return field.displayName == "AccrualsEligibilityDate" && !dateTimeUtils.validateDate(field.fieldData.fieldValue) ? field.fieldData.fieldValue = null : field.fieldData.fieldValue;
  }

  public mapToSectionsCustom(data: IEmployeeSectionsCustom): EmployeeSectionsCustom {
    let custom: EmployeeSectionsCustom = new EmployeeSectionsCustom();
    custom.customFields = this.mapToCustomFields(data.customFields.data, data.customFields.metadata);
    return custom;
  }
}

