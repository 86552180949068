import {
  Injectable,
} from '@angular/core';

import * as moment from 'moment';

import { appConfig } from '../../../app.config';

import { commonConfig } from '../../common.config';
import { dateTimeUtils } from '../../utils/dateTimeUtils';

export interface IDateRange {
  start: moment.Moment;
  end: moment.Moment;
}

@Injectable()
export class DateTimeService {
  public GetDiffSeconds(startDate: Date, endDate: Date): number {
    let startDateMoment: moment.Moment = moment(startDate);
    let endDateMoment: moment.Moment = moment(endDate);
    if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
      return 0;
    }
    if (endDateMoment.isBefore(startDateMoment)) {
      return 0;
    }
    return endDateMoment.diff(startDateMoment, 'seconds');
  }
  public GetDiffDays(startDate: Date, endDate: Date): number {
    let startDateMoment: moment.Moment = moment(startDate);
    let endDateMoment: moment.Moment = moment(endDate);
    if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
      return 0;
    }
    if (endDateMoment.isBefore(startDateMoment)) {
      return 0;
    }
    return endDateMoment.diff(startDateMoment, 'days');
  }

  public get GetMinDate(): Date {
    return new Date(-8640000000000000);
  }
  public get GetMaxDate(): Date {
    return new Date(3453224400000);
  }

  public getDateTimeFromTime(refDate: moment.Moment, militaryTime: string): moment.Moment {
    return moment(`${refDate.format(appConfig.dateFormat)} ${militaryTime}`, `${appConfig.dateFormat}  ${appConfig.militaryTimeFormat}`);
  }

  public getDateRangeFromTimeRange(refDate: moment.Moment, startTime: string, endTime: string): IDateRange {
    let startDate: moment.Moment = this.getDateTimeFromTime(refDate, startTime);
    let endDate: moment.Moment = this.getDateTimeFromTime(refDate, endTime);
    if (startDate.isAfter(endDate)) {
      endDate.add(1, 'day');
    }
    return { start: startDate, end: endDate };
  }

  public isDateRangeOverlapped(startDate1: moment.Moment, endDate1: moment.Moment, startDate2: moment.Moment, endDate2: moment.Moment, strict: boolean = true): boolean {
    if (strict) {
      return startDate1.isSameOrBefore(endDate2) && endDate1.isSameOrAfter(startDate2);
    }
    return startDate1.isBefore(endDate2) && endDate1.isAfter(startDate2);
  }

  public militaryTimeToLocaTime(militaryTime: string): string {
    return moment(militaryTime, appConfig.militaryTimeFormat).format(appConfig.timeFormat);
  }

  public setTime(date: Date, localTime: string): Date {
    let time: moment.Moment = moment(localTime, appConfig.timeFormat);
    return moment(date).hour(time.hour()).minute(time.minute()).second(time.second()).toDate();
  }

  public setTimeToMoment(mnt: moment.Moment, localTime: string): void {
    dateTimeUtils.setTimeToMoment(mnt, localTime);
  }

  public isAfter(militaryTime1: string, militaryTime2: string): boolean {
    return moment(militaryTime1, appConfig.militaryTimeFormat).isAfter(moment(militaryTime2, appConfig.militaryTimeFormat));
  }

  public isBefore(militaryTime1: string, militaryTime2: string): boolean {
    return moment(militaryTime1, appConfig.militaryTimeFormat).isBefore(moment(militaryTime2, appConfig.militaryTimeFormat));
  }

  public getDuration(start: moment.Moment, end: moment.Moment): string {
    return moment().startOf('date').add(end.diff(start)).format(appConfig.militaryTimeFormat);
  }

  public durationToHours(duration: string): number {
    return moment.duration(duration).asHours();
  }

  public hoursToDuration(hours: number): string {
    let duration: moment.Duration = moment.duration(hours, 'hours');
    return `${duration.hours()}:${duration.minutes()}`;
  }

  public convertToUTC(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), 0));
  }

  public formatDurationDecimal(d: moment.Duration): string {
    return dateTimeUtils.formatDurationDecimal(d);
  }

  public formatDuration(d: moment.Duration): string {
    return dateTimeUtils.formatDuration(d);
  }

  public durationToMiliseconds(d: moment.Duration): number {
    return d.asMilliseconds();
  }

  public convertToDtoDateTimeString(d: Date): string {
    return dateTimeUtils.convertToDtoDateTimeString(d);
  }
}
