<section class="groups">
  <header class="groups__header">
    <button type="button" class="slx-button slx-blue slx-with-icon" [disabled]="isEditing" (click)="onAdd()">
      <i aria-hidden="true" class="slx-button__icon fal fa-plus"></i>
      <span class="slx-button__text">Add Group</span>
    </button>
    <p class="groups__hd-text">Click <i aria-hidden="true" class="fas fa-bars"></i> and drag to reorder Positions inside each Group</p>
  </header>
  <section class="groups__grid flex flex-direction__column">
    <header class="groups__grid-hd flex flex-direction-row justify-content__space-around">
      <div class="groups__grid-th">Edit</div>
      <div class="groups__grid-th">Position Group</div>
      <div class="groups__grid-th flex justify-content__space-between align-items__center">
        <span>Position Assigned</span>
        <div class="groups__filter">
          <em class="groups__filter-icon"
            [popper]="positionFilter"
            [popperTrigger]="'click'"
            [popperPlacement]="'bottom-end'"
            [popperDisableStyle]="true"
          >
            <i aria-hidden="true" class="fas fa-filter"></i>
          </em>
          <popper-content #positionFilter class="slx-popper">
            <div class="groups__popper">
              <input type="text"
                class="groups__input search"
                [(ngModel)]="searchText"
                (input)="onFilterChanged()"
                placeholder="Enter position name"
                name="positionName"
                autocomplete="off"
              />
              <em class="groups__clear-icon" (click)="onResetSearch()">
                <i aria-hidden="true" class="fas fa-times"></i>
              </em>
            </div>
          </popper-content>
        </div>
      </div>
    </header>
    <div class="groups__grid-body">
      <div class="flex flex-direction-row justify-content__space-around flex__grow groups__grid-row" *ngFor="let group of groups">
        <span class="groups__grid-td button-cell">
          <ng-container *ngIf="!group.isEditing && !isDefaultGroup(group)">
            <em [class.disabled]="!canEditGroup(group)"
              (click)="onEdit(group)"
              class="groups__edit-icon"
            >
              <i aria-hidden="true" class="fas fa-edit"></i>
            </em>
            <em [class.disabled]="!canRemoveGroup(group)"
              (click)="onDelete(group)"
              class="groups__del-icon"
            >
              <i aria-hidden="true" class="fas fa-trash"></i>
            </em>
          </ng-container>
          <ng-container *ngIf="group.isEditing && !isDefaultGroup(group)">
            <em [class.disabled]="!canSaveGroup(group)"
              (click)="onSave(group)"
              class="groups__edit-icon"
            >
              <i aria-hidden="true" class="fas fa-save"></i>
            </em>
            <em (click)="onCancel(group)"
              class="groups__del-icon"
            >
              <i aria-hidden="true" class="fas fa-times"></i>
            </em>
          </ng-container>
        </span>
        <span class="groups__grid-td">
          <ng-container *ngIf="!group.isEditing">{{ group.name }}</ng-container>
          <ng-container *ngIf="group.isEditing">
            <input #groupName="ngModel"
              type="text"
              class="groups__input"
              maxlength="255"
              [(ngModel)]="group.name"
              (input)="onInputGroupName(groupName, group)"
              (keyup)="onPressEnter($event, groupName, group)"
              [required]="true"
              placeholder="Enter group name"
              name="groupName"
              autocomplete="off"
            />
            <p *ngIf="groupName.errors" class="slx-error-block">
              <span *ngIf="groupName.errors.required" errorMessage for="required"></span>
              <span *ngIf="groupName.errors.uniqueName" errorMessage for="required">Group name have to be unique</span>
            </p>
          </ng-container>
        </span>
        <span class="groups__grid-td scroll flex">
          <span class="groups__pos-length">{{ group.positions.length }}</span>
          <span class="flex flex-direction__column groups__grid-positions" [class.disabled]="!canDragNDropGroups()">
            <kendo-sortable
              [kendoSortableBinding]="group.positions"
              (dataAdd)="onDragAdd($event)"
              (dragStart)="onDragStart(group)"
              (dragEnd)="onDragEnd(group)"
              [animation]="true"
              itemClass="groups__pos-item"
              emptyText="Drag positions here..."
              emptyItemClass="groups__pos-name"
              zone="positions"
            >
              <ng-template let-position="item">
                <i aria-hidden="true" class="groups__pos-icon fas fa-bars"></i>
                <em class="groups__pos-name" [title]="position.name">{{ position.name }}</em>
              </ng-template>
            </kendo-sortable>
          </span>
        </span>
      </div>
    </div>
  </section>
</section>
