import { IEmpType, EmpType } from '../lookup/emp-type';

export interface IEmployeeDefinition {
  id: number;
  name: string;
  employeeType: IEmpType;
  dateHired: string;
  dateTerminated: string;
  dateActivation: string;
  payrollNumber: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  averageHoursPerWeek: number;
  hoursPerRotation: number;
  isAgencyEmployee: boolean;
  hasRotations: boolean;
  daterehire?: string;
}

export class EmployeeDefinition {
  public id: number;
  public name: string;
  public employeeType: EmpType;
  public dateHired: Date;
  public dateTerminated: Date;
  public dateActivation: Date;
  public payrollNumber: string;
  public phoneNumber: string;
  public mobilePhoneNumber: string;
  public averageHoursPerWeek: number;
  public hoursPerRotation: number;
  public isAgencyEmployee: boolean;
  public hasRotations: boolean;
  public daterehire?: Date;
}
