import { Directive, DoCheck, Host, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { StepView } from './wizard-step-view';
import { IStepCaseConf, StepDefinition } from './wizard-step.types';
import * as _ from 'lodash';

@Directive({ selector: '[stepSwitch]' })
export class StepSwitchDirective {
  private defaultViews: StepView[];
  private defaultUsed: boolean;
  private caseCount: number;
  private lastCaseCheckIndex: number;
  private lastCasesMatched: boolean;
  private selectedStepSwitch: any;
  private stepDefinitions: _.NumericDictionary<StepView>;

  @Input()
  public set stepSwitch(newValue: any) {
    this.selectedStepSwitch = newValue;
    if (this.caseCount === 0) {
      this.updateDefaultCases(true);
    }
  }

  constructor() {
    this.stepDefinitions = {};
    this.defaultUsed = false;
    this.caseCount = 0;
    this.lastCaseCheckIndex = 0;
    this.lastCasesMatched = false;
  }

  public getStepDefinitions(): StepDefinition[] {
    let stepDefs: StepDefinition[] = _.map(_.map(this.stepDefinitions), (view: StepView) => view.stepDefinition);
    return stepDefs;
  }

  public addStep(view: StepView): number {
    this.caseCount++;
    this.stepDefinitions[this.caseCount] = view;
    return this.caseCount;
  }

  public addDefault(view: StepView): void {
    if (!this.defaultViews) {
      this.defaultViews = [];
    }
    this.defaultViews.push(view);
  }

  public matchStep(value: any): boolean {
    const matched: boolean = (value === this.selectedStepSwitch);
    this.lastCasesMatched = this.lastCasesMatched || matched;
    this.lastCaseCheckIndex++;
    if (this.lastCaseCheckIndex === this.caseCount) {
      this.updateDefaultCases(!this.lastCasesMatched);
      this.lastCaseCheckIndex = 0;
      this.lastCasesMatched = false;
    }
    return matched;
  }

  private updateDefaultCases(useDefault: boolean): void {
    if (this.defaultViews && useDefault !== this.defaultUsed) {
      this.defaultUsed = useDefault;
      _.forEach(this.defaultViews, (defaultView: StepView) => {
        defaultView.enforceState(useDefault);
      });
    }
  }
}

@Directive(
  {
    selector: '[stepCase]'
  }
)
export class StepCaseDirective implements DoCheck {
  @Input()
  public set stepCase(conf: IStepCaseConf) {
    if (this.view && conf) {
      this.view.setConf(conf);
    }
  }

  private view: StepView;
  private stepSwitch: StepSwitchDirective;

  constructor(viewContainer: ViewContainerRef,
              templateRef: TemplateRef<Object>,
              @Host() stepSwitch: StepSwitchDirective) {

    this.stepSwitch = stepSwitch;
    this.view = new StepView(viewContainer, templateRef);
    let stepNum: number = stepSwitch.addStep(this.view);
    this.view.setStep(stepNum);
  }

  public ngDoCheck(): void {
    this.view.enforceState(this.stepSwitch.matchStep(this.view.step));
  }
}

@Directive({ selector: '[stepDefault]' })
export class StepDefaultDirective {
  private view: StepView;
  constructor(viewContainer: ViewContainerRef,
              templateRef: TemplateRef<Object>,
              @Host() stepSwitch: StepSwitchDirective) {
    this.view = new StepView(viewContainer, templateRef);
    stepSwitch.addDefault(this.view);
    this.view.setStep(0);
  }
}
