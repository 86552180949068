import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { MasterScheduleManagementService, MasterScheduleQuickEditService, ScheduleEntryApiService } from '../../../services/index';
import { ScheduleQuickEditConfiguration } from '../../../models/index';
import { LookupType } from '../../../../organization/models/index';
import { appConfig } from '../../../../app.config';
import { LookupService } from '../../../../organization/services/index';
import { ConfirmDialog2Component, ConfirmOptions } from '../../../../common';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { AppSettingsManageService } from '../../../../../app/app-settings/services/app-settings-manage.service';
var MasterScheduleQuickEditComponent = /** @class */ (function () {
    function MasterScheduleQuickEditComponent(masterScheduleQuickEditService, lookupService, modalService, managementService, appSettingsManageService, ScheduleEntryApiService) {
        this.masterScheduleQuickEditService = masterScheduleQuickEditService;
        this.lookupService = lookupService;
        this.modalService = modalService;
        this.managementService = managementService;
        this.appSettingsManageService = appSettingsManageService;
        this.ScheduleEntryApiService = ScheduleEntryApiService;
        this.showChanges = false;
        this.isQuickEditStarted = false;
        this.disablepopup = true;
        this.configuration = new ScheduleQuickEditConfiguration();
    }
    Object.defineProperty(MasterScheduleQuickEditComponent.prototype, "hasChanges", {
        get: function () {
            return this.items && this.items.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    MasterScheduleQuickEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.masterScheduleQuickEditService.quickEditListChanged$.subscribe(function (items) {
            _this.items = items;
        });
        this.getSettings();
        this.actionsSubscription = this.managementService.actions$.subscribe(function (data) {
            _this.actions = data;
        });
    };
    MasterScheduleQuickEditComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['orgLevelId'] && this.orgLevelId) {
            this.department = null;
            this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.orgLevelId })
                .then(function (res) {
                _this.department = _.first(res.items);
            });
        }
    };
    MasterScheduleQuickEditComponent.prototype.undoEdit = function (item) {
        this.masterScheduleQuickEditService.undoEditCommand([item]);
    };
    MasterScheduleQuickEditComponent.prototype.showChangesClick = function () {
        this.showChanges = true;
    };
    MasterScheduleQuickEditComponent.prototype.hideChangesClick = function () {
        this.showChanges = false;
    };
    MasterScheduleQuickEditComponent.prototype.startQuickEdit = function () {
        this.isQuickEditStarted = true;
        this.masterScheduleQuickEditService.startQuickEdit();
    };
    MasterScheduleQuickEditComponent.prototype.stopQuickEdit = function () {
        var _this = this;
        this.disablepopup = false;
        var req = [];
        for (var _i = 0, _a = this.items; _i < _a.length; _i++) {
            var val = _a[_i];
            var enddate = moment(val.date).add(23, 'hours').add(59, 'minutes').add(59, 'seconds');
            var finaldate = moment(enddate).format("YYYY-MM-DD HH:mm:ss");
            var obj = {
                EmpId: val.employeeId,
                StartDate: moment(val.date).format("YYYY-MM-DD HH:mm:ss"),
                EndDate: finaldate
            };
            req.push(obj);
        }
        this.ScheduleEntryApiService.getApprovedPayPeriodForQuickEdit(req).then(function (data) {
            _this.approvedPayPeriod = data;
            if (_this.isModifyPayPeriodApproved && _this.approvedPayPeriod == true) {
                var message = _this.actions.canEditForApprovedPayPeriod ?
                    "This will modify a schedule in an approved pay period and impact the PBJ Calculation for the \n        employee, are you sure you want to continue?" : "You do not have permissions to modify a \n        schedule in an approved pay period";
                var popupOptions = new ConfirmOptions();
                popupOptions.showCancel = true;
                popupOptions.showOK = _this.actions.canEditForApprovedPayPeriod ? true : false;
                popupOptions.buttonOKtext = 'Ok';
                popupOptions.buttonCanceltext = _this.actions.canEditForApprovedPayPeriod ? 'Cancel' : 'Ok';
                ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                    if (result) {
                        _this.stopQuickEdits();
                    }
                    else {
                        _this.disablepopup = true;
                    }
                }, popupOptions);
            }
            else {
                _this.stopQuickEdits();
                _this.disablepopup = true;
            }
        });
    };
    ;
    MasterScheduleQuickEditComponent.prototype.stopQuickEdits = function () {
        this.masterScheduleQuickEditService.stopQuickEdit();
        this.isQuickEditStarted = false;
    };
    MasterScheduleQuickEditComponent.prototype.onReplaceAlwaysChanged = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.masterScheduleQuickEditService.setEditConfiguration(this.configuration, !this.isAllFieldsPopulated)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MasterScheduleQuickEditComponent.prototype.configurationChanged = function (shiftTemplate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (shiftTemplate.shift && shiftTemplate.shift.id &&
                            (shiftTemplate.unit && shiftTemplate.unit.id
                                || shiftTemplate.absence && shiftTemplate.absence.code)) {
                            this.isAllFieldsPopulated = true;
                        }
                        else {
                            this.isAllFieldsPopulated = false;
                        }
                        this.configuration.shift = shiftTemplate.shift;
                        this.configuration.unit = shiftTemplate.unit;
                        this.configuration.constraint = shiftTemplate.constraint;
                        this.configuration.absence = shiftTemplate.absence;
                        this.configuration.department = this.department;
                        return [4 /*yield*/, this.masterScheduleQuickEditService.setEditConfiguration(this.configuration, !this.isAllFieldsPopulated)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MasterScheduleQuickEditComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleQuickEditComponent.prototype, "actionsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleQuickEditComponent.prototype, "orgLevelSubscription", void 0);
    return MasterScheduleQuickEditComponent;
}());
export { MasterScheduleQuickEditComponent };
