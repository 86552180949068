<span class="fav-title">Select your favorite modules to display on the dashboard for easier access.</span>
<span class="fav-subtitle">(Click <i class="fas fa-star" aria-hidden="true"></i> to Show or Hide a dashboard and Click <i class="fas fa-bars" aria-hidden="true"></i> and Drag to Reorder)</span>
<hr class="fav-line">
<div class="section-container">
    <div class="section">
      <div class="section-header blue">
          <div class="section-header-button-left">
              <slx-collapse-button [(isCollapsed)]="favoritesIsCollapsed" className="slx-white-icon"
                  collapsedIcon="far fa-angle-right"
                  [slxCollapseByCss]="favoriteContent">
              </slx-collapse-button>
          </div>
          <div class="section-header-content">
            <div class="header-item header-title">
                <span class="header-title__text">Favorites</span>
            </div>
        </div>
      </div>
      <div #favoriteContent class="section-content">
        <slx-favorites-list-configuration></slx-favorites-list-configuration>
      </div>
    </div>

    <div class="section">
      <div class="section-header">
          <div class="section-header-button-left">
            <slx-collapse-button [(isCollapsed)]="modulesIsCollapsed" className="slx-black-icon"
              collapsedIcon="far fa-angle-right"
              [slxCollapseByCss]="moduleContent">
            </slx-collapse-button>
          </div>
          <div class="section-header-content">
              <div class="header-item header-title">
                  <span class="header-title__text">Modules</span>
              </div>
          </div>

      </div>
      <div #moduleContent class="section-content">
        <slx-modules-list-configuration></slx-modules-list-configuration>
      </div>
    </div>
</div>
