import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { EmployeeSectionApiService } from './employee-section-api.service';
import { EmployeeSectionsPersonalApiService } from './employee-section-personal/employee-section-personal-api.service';
import { EmployeeSectionsEmploymentApiService } from './employee-section-employment/employee-section-employment-api.service';
import { EmployeeSectionsCustomApiService } from './employee-section-custom/employee-section-custom-api.service';
import { EmployeeSectionsAuditApiService } from './employee-section-audit/employee-section-audit-api.service';
import { EmployeeSectionsPerformanceApiService } from './employee-section-performance/employee-section-performance-api.service';
import { EmployeeSectionsScheduleApiService } from './employee-section-schedule/employee-section-schedule-api.service';
import { EmployeeSectionsBenefitsManagementApiService } from './employee-sections-benefits-management/employee-sections-benefits-management-api.service';
import { EmployeeSectionsScheduleMapService } from './employee-section-schedule/employee-section-schedule-map.service';
import { EmployeeSectionsAccrualsApiService } from './employee-section-accruals/employee-section-accruals-api.service';
import { EmployeeSectionsAccrualsMapService } from './employee-section-accruals/employee-section-accruals-map.service';
import { Assert } from '../../../framework/index';
import { Subscription } from 'rxjs/Subscription';

import {
  EmployeeSectionsPersonal,
  EmployeeSectionsEmployment,
  EmployeeSectionsCustom,
  EmployeeSectionsAudit,
  EmployeeSectionsPerformance,
  EmployeeSectionsSchedule,
  EmployeeSectionsBenefitsManagement,
  EmployeeSection,
  EmployeeSubSection,
  EmployeeSectionsAccruals,
  EmployeeSectionsEnrollmentAttachments,
  EmployeeSectionsContacts,
  EmployeeSectionsProfile
} from '../models/index';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class EmployeeSectionBridgeService {

  public editStateChange$: ReplaySubject<{ editing: boolean, section: EmployeeSubSection }>;
  public sectionsAreVisible: boolean = true;

  private employeeSectionApiService: EmployeeSectionApiService;
  private employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService;

  private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService;
  private employeeSectionsCustomApiService: EmployeeSectionsCustomApiService;
  private employeeSectionsAuditApiService: EmployeeSectionsAuditApiService;
  private employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService;
  private employeeSectionsScheduleApiService: EmployeeSectionsScheduleApiService;
  private employeeSectionsBenefitsManagementApiService: EmployeeSectionsBenefitsManagementApiService;
  private employeeSectionsAccrualsApiService: EmployeeSectionsAccrualsApiService;

  private contactUpdated$=new Subject<EmployeeSectionsContacts>();

  private hireDateUpdated$=new Subject<Date>();
  private birthDateUpdated$=new Subject<Date>();
  private updateContactSection$=new Subject<string>();
  private payRate4Decimals$=new Subject<boolean>();


  constructor(employeeSectionApiService: EmployeeSectionApiService,
    employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService,
    employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    employeeSectionsCustomApiService: EmployeeSectionsCustomApiService,
    employeeSectionsAuditApiService: EmployeeSectionsAuditApiService,
    employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService,
    employeeSectionsScheduleApiService: EmployeeSectionsScheduleApiService,
    employeeSectionsBenefitsManagementApiService: EmployeeSectionsBenefitsManagementApiService,
    employeeSectionsAccrualsApiService: EmployeeSectionsAccrualsApiService
  ) {
    this.employeeSectionApiService = employeeSectionApiService;
    this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
    this.employeeSectionsCustomApiService = employeeSectionsCustomApiService;
    this.employeeSectionsAuditApiService = employeeSectionsAuditApiService;
    this.employeeSectionsPerformanceApiService = employeeSectionsPerformanceApiService;
    this.employeeSectionsScheduleApiService = employeeSectionsScheduleApiService;
    this.employeeSectionsBenefitsManagementApiService = employeeSectionsBenefitsManagementApiService;
    this.employeeSectionsAccrualsApiService = employeeSectionsAccrualsApiService;
    this.employeeSectionsAccrualsApiService = employeeSectionsAccrualsApiService;

    this.editStateChange$ = new ReplaySubject<{ editing: boolean, section: EmployeeSubSection }>();
  }

  public notifyEditStateChange(editing: boolean, section: EmployeeSubSection): void {
    this.editStateChange$.next({ editing: editing, section: section });
  }

  public getSections(employeeId: number): Promise<EmployeeSection[]> {
    return this.employeeSectionApiService.getSections(employeeId);
  }

  public getPersonalSection(employeeId: number): Promise<EmployeeSectionsPersonal> {
    return this.employeeSectionsPersonalApiService.getPersonalSection(employeeId);
  }

  public getEmploymentSection(employeeId: number): Promise<EmployeeSectionsEmployment> {
    return this.employeeSectionsEmploymentApiService.getEmploymentSection(employeeId);
  }

  public getCustomSection(employeeId: number): Promise<EmployeeSectionsCustom> {
    return this.employeeSectionsCustomApiService.getCustomSection(employeeId);
  }

  public getAuditSection(employeeId: number): Promise<EmployeeSectionsAudit> {
    return this.employeeSectionsAuditApiService.getAuditSection(employeeId);
  }

  public getPerformanceSection(employeeId: number, attendancePointsStartDate: Date, attendancePointsEndDate: Date, attendanceStartDate: Date, attendanceEndDate: Date): Promise<EmployeeSectionsPerformance> {
    return this.employeeSectionsPerformanceApiService.getPerformanceSection(employeeId, attendancePointsStartDate, attendancePointsEndDate, attendanceStartDate, attendanceEndDate);
  }

  public getScheduleSection(employeeId: number): Promise<EmployeeSectionsSchedule> {
    return this.employeeSectionsScheduleApiService.getScheduleSection(employeeId);
  }

  public getBenefitsManagementSection(employeeId: number, effectiveDate: Date): Promise<EmployeeSectionsBenefitsManagement> {
    return this.employeeSectionsBenefitsManagementApiService.getBenefitsManagementSection(employeeId, effectiveDate);
  }

  public getAccrualsSection(employeeId: number, accrualsStartDate: Date, accrualsEndDate: Date): Promise<EmployeeSectionsAccruals> {
    return this.employeeSectionsAccrualsApiService.getAccrualsSection(employeeId, accrualsStartDate, accrualsEndDate);
  }

  public reloadProfileSection(employeeContactInfo:EmployeeSectionsContacts):void{
    this.contactUpdated$.next(employeeContactInfo);
  }

  public subscribeTorReloadProfileSection(callback: (employeeContactInfo: EmployeeSectionsContacts) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.contactUpdated$.subscribe(callback);
  }

  public updateContactSection(employeeMobileNumber: string):void{
    this.updateContactSection$.next(employeeMobileNumber);
  }

  public subscribeTorUpdateContactSection(callback: (employeeMobileNumber: string) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.updateContactSection$.subscribe(callback);
  }


  public changeHireDate(hireDate:Date):void{
    this.hireDateUpdated$.next(hireDate);
  }

  public subscribeToChangeHireDate(callback: (hireDate: Date) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.hireDateUpdated$.subscribe(callback);
  }

  public changePayRateFormat(isPayRate4Decimals: boolean):void{
    this.payRate4Decimals$.next(isPayRate4Decimals);
  }

  public subscribeToChangePayRateFormat(callback: (isPayRate4Decimals: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.payRate4Decimals$.subscribe(callback);
  }

  public changeBirthDate(birthDate:Date):void{
    this.birthDateUpdated$.next(birthDate);
  }

  public subscribeToChangeBirthDate(callback: (birthDate: Date) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.birthDateUpdated$.subscribe(callback);
  }

}
