import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { EventEmitter, ChangeDetectorRef } from '@angular/core';
import { appConfig } from '../../../app.config';
import { AcceptableControlValueAccessor } from '../../../core/models/index';
var DateNavigatorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DateNavigatorComponent, _super);
    function DateNavigatorComponent(changeDetector) {
        var _this = _super.call(this) || this;
        _this.changeDetector = changeDetector;
        _this.className = '';
        _this.appConfig = appConfig;
        _this.format = 'MMMM dd, yyyy';
        _this.dateChanged = new EventEmitter();
        return _this;
    }
    Object.defineProperty(DateNavigatorComponent.prototype, "selectedDate", {
        get: function () {
            return this.m_currentDate;
        },
        set: function (value) {
            this.m_currentDate = value;
            this.changeDate(value);
        },
        enumerable: true,
        configurable: true
    });
    DateNavigatorComponent.prototype.ngOnInit = function () {
        //
    };
    DateNavigatorComponent.prototype.writeValue = function (value) {
        this.m_currentDate = value;
    };
    DateNavigatorComponent.prototype.onNavButtonClick = function (event, isNext) {
        if (this.readonly)
            return;
        var currentDate = moment(this.m_currentDate).startOf('day');
        var newCurrentDate = null;
        if (isNext) {
            newCurrentDate = currentDate.add(1, 'days');
        }
        else {
            newCurrentDate = currentDate.subtract(1, 'days');
        }
        this.changeDate(newCurrentDate.toDate());
    };
    DateNavigatorComponent.prototype.selectorDateChange = function (date) {
        this.m_currentDate = date;
        this.dateChanged.emit(this.m_currentDate);
        this.onChangeCallback(this.m_currentDate);
    };
    DateNavigatorComponent.prototype.isMaximum = function (date) {
        if (this.maxDate) {
            return moment(date).startOf('day').isSameOrAfter(moment(this.maxDate).startOf('day'));
        }
        return false;
    };
    DateNavigatorComponent.prototype.isMinimum = function (date) {
        if (this.minDate) {
            return moment(date).startOf('day').isSameOrBefore(moment(this.minDate).startOf('day'));
        }
        return false;
    };
    DateNavigatorComponent.prototype.changeDate = function (date) {
        if (this.m_currentDate !== date) {
            this.m_currentDate = date;
            this.dateChanged.emit(date);
            this.onChangeCallback(this.m_currentDate);
            this.changeDetector.markForCheck();
            if (!this.changeDetector['destroyed']) {
                this.changeDetector.detectChanges();
            }
        }
    };
    return DateNavigatorComponent;
}(AcceptableControlValueAccessor));
export { DateNavigatorComponent };
