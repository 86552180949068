<kendo-grid class="slx-full-height slx-blue-grid slx-user-roles-grid"
  [data]="gridState.view"
  [sortable]="true"
  [sort]="gridState.state.sort"
  [filterable]="false"
  (dataStateChange)="gridState.dataStateChange($event)"
>
  <kendo-grid-column [sortable]="true" [filterable]="false" field="description" media="sm" width="300" class="overflow-visible-cell">
    <ng-template kendoGridHeaderTemplate>
      Description
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-container *ngIf="!isShowFilter(dataItem)">
        {{dataItem.description}}
      </ng-container>
      <ng-container *ngIf="isShowFilter(dataItem)">
      <div class="flex">
        <span class="flex-grow-1">{{dataItem.description}}</span>
        <slx-kendo-grid-cell-filter
          field="description"
          (cellFilterChanged)="onCellFilterChanged($event, dataItem)"
        ></slx-kendo-grid-cell-filter>
      </div>
      </ng-container>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <ng-template ngFor let-role [ngForOf]="container?.roles">
    <kendo-grid-column title="Select" [sortable]="false" media="sm" [filterable]="false" width="100" [hidden]="!roleColumnsState.isVisible(role)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>{{role.name}}</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <slx-roles-profile-access-toggler *ngIf="dataItem.type==='RolesProfileRow'" [rowItem]="dataItem" [roleId]="role.id" (roleToggled)="onRoleProfileToggled($event, dataItem)"></slx-roles-profile-access-toggler>
        <div class="access-changer" *ngIf="dataItem.type==='RolesRightGroupRow'||dataItem.type==='RolesOtherRightGroupRow'">

          <i [ngClass]="{
            'far': dataItem.mapByRole[role.id].access==='enabled' || dataItem.mapByRole[role.id].access==='disabled',
            'fa-check-square': dataItem.mapByRole[role.id].access==='enabled',
            'fa-square': dataItem.mapByRole[role.id].access==='disabled',
            'fab fa-delicious': dataItem.mapByRole[role.id].access==='mixed',
            'dirty': dataItem.dirtyByRole[role.id]
            }"
            aria-hidden="true"></i>
        </div>
        <slx-roles-menu-access-toggler *ngIf="dataItem.type==='RolesMenuModuleGroupRow'" [rowItem]="dataItem" [roleId]="role.id" (roleToggled)="onRoleMenuGroupToggled($event, dataItem)"></slx-roles-menu-access-toggler>
        <slx-roles-component-access-toggler *ngIf="dataItem.type==='RolesComponentsModuleGroupRow'" [rowItem]="dataItem" [roleId]="role.id" (roleToggled)="onRoleComponentsGroupToggled($event, dataItem)"></slx-roles-component-access-toggler>
      </ng-template>
    </kendo-grid-column>
  </ng-template>
  <ng-template kendoGridDetailTemplate let-dataItem>
    <div class="role-details-grid">
      <slx-roles-section-grid *ngIf="dataItem.type==='RolesProfileRow'" [container]="dataItem" (roleSectionChanged)="roleSectionChanged($event, dataItem)"></slx-roles-section-grid>
      <slx-roles-right-module-grid *ngIf="dataItem.type==='RolesRightGroupRow'" [container]="dataItem" (roleRightModuleChanged)="roleRightModuleChanged($event, dataItem)"></slx-roles-right-module-grid>
      <slx-roles-right-module-grid *ngIf="dataItem.type==='RolesOtherRightGroupRow'" [container]="dataItem" (roleRightModuleChanged)="roleOtherRightModuleChanged($event, dataItem)"></slx-roles-right-module-grid>
      <slx-roles-menu-module-grid *ngIf="dataItem.type==='RolesMenuModuleGroupRow'" [container]="dataItem" (roleMenuModuleChanged)="roleMenuModuleChanged($event, dataItem)"></slx-roles-menu-module-grid>
      <slx-roles-components-module-grid *ngIf="dataItem.type==='RolesComponentsModuleGroupRow'" [container]="dataItem" (roleMenuModuleChanged)="roleComponentsModuleChanged($event, dataItem)"></slx-roles-components-module-grid>
    </div>
  </ng-template>
</kendo-grid>
