export * from './timeclock-assignment-grid/timeclock-assignment-grid.component';
export * from './timeclock-assignment-header/timeclock-assignment-header.component';
export * from './timeclock-assignment/timeclock-assignment.component';
export * from './timeclock-assignment-timeclocks/timeclock-assignment-timeclocks.component';
export * from './timeclock-assignment-dialog/timeclock-assignment-dialog.component';

import { TimeclockAssignmentGridComponent } from './timeclock-assignment-grid/timeclock-assignment-grid.component';
import { TimeclockAssignmentHeaderComponent } from './timeclock-assignment-header/timeclock-assignment-header.component';
import { TimeclockAssignmentComponent } from './timeclock-assignment/timeclock-assignment.component';
import { TimeclockAssignmentTimeclocksComponent } from './timeclock-assignment-timeclocks/timeclock-assignment-timeclocks.component';
import { TimeclockAssignmentDialogComponent } from './timeclock-assignment-dialog/timeclock-assignment-dialog.component';

export const timeclockAssignmentComponents: any = [
  TimeclockAssignmentGridComponent,
  TimeclockAssignmentHeaderComponent,
  TimeclockAssignmentComponent,
  TimeclockAssignmentTimeclocksComponent,
  TimeclockAssignmentDialogComponent
];
