import { ExportDataStatus } from '../enums/export-data-status';

export interface IExportDataExecutionItem {
  executionId: string;
  configurationId: number;
  exportStatus: number;
  executedBy: string;
  startedOn: string;
  completedOn: string;
  failReason: string;
}

export class ExportDataExecutionItem {
  public id: string;
  public configurationId: number;
  public status: ExportDataStatus;
  public executedBy: string;
  public startedOn: Date;
  public completedOn: Date;
  public reason: string;
}
