import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { ControlStateKey, StateResetTypes } from '../../../core/models/index';
import { ComponentStateStorageService, StateManagementService } from '../../../common/services/index';
import { unsubscribe } from '../../../core/decorators/index';
import { PayCycleDropdownSelectorComponent } from '../../components/index';
import { PayCycle } from '../../../organization/models/index';
import { dateTimeUtils } from '../../../common/utils/index';
var PayCycleActiveStateDirective = /** @class */ (function () {
    function PayCycleActiveStateDirective(selector, storageService, stateManagement) {
        var _this = this;
        this.selector = selector;
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.controlId = 'slx-pay-cycle-dropdown-selector';
        this.m_resetType = StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.contextChangeSubscription = this.stateManagement.onComponentActiveStateChanged$.subscribe(function (key) {
            _this.switchState(key);
        });
    }
    Object.defineProperty(PayCycleActiveStateDirective.prototype, "resetType", {
        set: function (type) {
            if (!type) {
                return;
            }
            this.m_resetType = type;
        },
        enumerable: true,
        configurable: true
    });
    PayCycleActiveStateDirective.prototype.ngOnInit = function () {
        this.stateManagement.registerControl(this.controlId);
    };
    PayCycleActiveStateDirective.prototype.ngOnDestroy = function () {
        this.stateManagement.unregisterControl(this.controlId);
    };
    PayCycleActiveStateDirective.prototype.valueChanged = function (val) {
        this.state.value = { startDate: dateTimeUtils.convertToDtoDateTimeString(val.startDate), endDate: dateTimeUtils.convertToDtoDateTimeString(val.endDate) };
        this.storageService.setControlState(this.stateManagement.componentKey, this.controlId, this.state, this.m_resetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.controlId);
    };
    PayCycleActiveStateDirective.prototype.loadState = function () {
        var _this = this;
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
        this.state = this.storageService.getControlState(this.stateManagement.componentKey, this.controlId, this.stateKey);
        if (this.state.value !== undefined) {
            var value = new PayCycle();
            value.startDate = dateTimeUtils.convertFromDtoDateTimeString(this.state.value.startDate);
            value.endDate = dateTimeUtils.convertFromDtoDateTimeString(this.state.value.endDate);
            this.selector.SelectPayCycle(value, false);
        }
        this.valueChangesSubscription = this.selector.payCycleSelected.subscribe(function (x) {
            _this.valueChanged(x);
        });
        this.stateManagement.controlRestored(this.controlId, this.state.value !== undefined);
    };
    PayCycleActiveStateDirective.prototype.switchState = function (context) {
        this.stateKey = new ControlStateKey(context);
        this.loadState();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCycleActiveStateDirective.prototype, "contextChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCycleActiveStateDirective.prototype, "valueChangesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCycleActiveStateDirective.prototype, "loadedPayCyclesSubscription", void 0);
    return PayCycleActiveStateDirective;
}());
export { PayCycleActiveStateDirective };
