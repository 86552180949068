import * as _ from 'lodash';
import { GroupResult } from '@progress/kendo-data-query';

import { VacationPlanEmployeeRecord } from './vacation-plan-employee-record';
import { VacationPlanEmployeeWeek } from './vacation-plan-employee-week';

export class VacationPlanWeekGroup {
  public totalDays: number = 0;
  public calculateGroups(groupedRecords: VacationPlanEmployeeRecord[] | GroupResult[], week: number): void {
    _.forEach(groupedRecords, (record: VacationPlanEmployeeRecord | GroupResult) => {
      if (record instanceof VacationPlanEmployeeRecord) {
        let vRecord: VacationPlanEmployeeRecord = <VacationPlanEmployeeRecord>record;
        this.addRecord(vRecord, week);
      } else {
        let grpRes: GroupResult = record as GroupResult;
        this.addGroup(grpRes, week);
      }
    });
  }

  public addGroup(grpRes: GroupResult, key: number): void {
    let items: VacationPlanEmployeeRecord[] | GroupResult[] = <VacationPlanEmployeeRecord[] | GroupResult[]>grpRes.items;
    let group: VacationPlanWeekGroup = new VacationPlanWeekGroup();
    group.calculateGroups(items, key);
    this.totalDays += group.totalDays;
  }

  public addRecord(record: VacationPlanEmployeeRecord, key: number): void {
    {
      let week: VacationPlanEmployeeWeek = record.weeks[key];
      if (week) {
        this.totalDays += week.days;
      }
    }
  }
}

