import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'angular2-moment';
import { NgReduxModule } from '@angular-redux/store';
import { NgxPopperModule } from 'ngx-popper';

import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { TreeViewModule } from '@progress/kendo-angular-treeview';

import { CommonModule as CommonAppModule } from '../common/index';
import { exportComponents, components, entryComponents } from './components/index';
import { exportDirectives, directives } from './directives/index';
import { organizationPipes, genericListPipes } from './pipes/index';
import { AuthenticationModule } from './../authentication/index';
import { ComponentsLibraryModule } from '../components-library/index';

import { services } from './services/index';
import { ACTION_PROVIDERS } from './actions/index';
import { EPIC_PROVIDERS, OrgLevelEpics } from './epics/index';
import { rootEpics } from '../state-model/state-model.module';

@NgModule({
  imports: [CommonAppModule,
    CommonModule,
    FormsModule,
    RouterModule,
    NgReduxModule,
    MomentModule,
    GridModule,
    ExcelModule,
    PDFModule,
    TreeViewModule,
    DropDownsModule,
    DateInputsModule,
    NgxPopperModule,
    AuthenticationModule,
    ComponentsLibraryModule
  ],
  declarations: [...components, ...directives, ...organizationPipes, ...genericListPipes],
  providers: [...services, ...ACTION_PROVIDERS, ...EPIC_PROVIDERS],
  entryComponents: [...entryComponents],
  exports: [...exportComponents, ...exportDirectives, ...organizationPipes, ...genericListPipes]
})
export class OrganizationModule {
  constructor(orgLevelEpics: OrgLevelEpics) {
    rootEpics.push(orgLevelEpics.selectOrgLevel);
    rootEpics.push(orgLevelEpics.deselectOrgLevel);
  }
}
