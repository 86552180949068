import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { FieldsMeta } from '../../../core/models/index';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { ResponseBody } from '../../../core/models/api/response-body';

import { appConfig } from '../../../app.config';
import { Aca1095cMapService } from './aca-1095-c-map.service';
import { HttpRequest } from '@angular/common/http';
import { configurationConfig } from '../../configuration.config';
import { Aca1095c, IAca1095c } from '../../models/index';

@Injectable()
export class Aca1095cApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private mapService: Aca1095cMapService,
    private urlParamsService: UrlParamsService
  ) { }

  public async getAca1095cRecords(orgLevelId: number, year: number): Promise<Aca1095c> {
    const url: string = this.getAca1095cApi(orgLevelId, year);
  
    const request = this.urlParamsService.createGetRequest(url, {
      year: year
    });
    
    return this.apiUtilService
      .request<IAca1095c, FieldsMeta>(request)
      .then((response: ResponseBody<IAca1095c, FieldsMeta>) => this.mapService.mapAca1095cRecords(response.data, response.meta));
  }



public async addEmployee1095c(orgLevelId: number, year: number, empMasterId: number,empId: number,companyId: number,departmentId:number,organizationId:number): Promise<Aca1095c> {
    const url: string = this.getAcaAddEmployee1095cApi(orgLevelId, year);
    let requestBody = { 'year': year, 'employeeMasterId': empMasterId,'employeeId':empId,'companyId':companyId,'departmentId':departmentId,'organizationId':organizationId };
    const request = this.urlParamsService.createPostRequest(url, requestBody);
    return this.apiUtilService
      .request<IAca1095c, FieldsMeta>(request)
      .then((response: ResponseBody<IAca1095c, FieldsMeta>) => this.mapService.mapAca1095cRecords(response.data, response.meta));
  }
public async removeEmployee1095c(orgLevelId: number, year: number, empMasterId: number, empId: number,companyId: number): Promise<Aca1095c> {
    const url: string = this.getAcaRemoveEmployee1095cApi(orgLevelId, year);
    let requestBody = { 'year': year, 'employeeMasterId': empMasterId,'employeeId':empId,'companyId':companyId,'departmentId':0,'organizationId':0 };
    const params = {};
    const request = this.urlParamsService.createDeleteRequest(url, params, requestBody);
    return this.apiUtilService
      .request<IAca1095c, FieldsMeta>(request)
      .then((response: ResponseBody<IAca1095c, FieldsMeta>) => this.mapService.mapAca1095cRecords(response.data, response.meta));
  }
  public async edit1095cRecords(orgLevelId: number, year: number,requestBody:any): Promise<Aca1095c> {
    const url: string = this.getAca1095cEditApi(orgLevelId, year);      
    
    let request: HttpRequest<any> = new HttpRequest('PUT', url, requestBody);    
    return this.apiUtilService
      .request<IAca1095c, FieldsMeta>(request)
      .then((response: ResponseBody<IAca1095c, FieldsMeta>) => this.mapService.mapAca1095cRecords(response.data, response.meta));
  }
  public async editReset1095cRecords(orgLevelId: number, year: number,requestBody:any): Promise<Aca1095c> {
    const url: string = this.getAca1095cEditResetApi(orgLevelId, year);      
    
    let request: HttpRequest<any> = new HttpRequest('PUT', url, requestBody);    
    return this.apiUtilService
      .request<IAca1095c, FieldsMeta>(request)
      .then((response: ResponseBody<IAca1095c, FieldsMeta>) => this.mapService.mapAca1095cRecords(response.data, response.meta));
  }
  public async confirm1095cRecords(orgLevelId: number, year: number,requestBody:any): Promise<Aca1095c> {
    const url: string = this.getAca1095cConfirmApi(orgLevelId, year);      
    
    let request: HttpRequest<any> = new HttpRequest('PUT', url, requestBody);    
    return this.apiUtilService
      .request<IAca1095c, FieldsMeta>(request)
      .then((response: ResponseBody<IAca1095c, FieldsMeta>) => this.mapService.mapAca1095cRecords(response.data, response.meta));
  }
  public async confirmReset1095cRecords(orgLevelId: number, year: number,requestBody:any): Promise<Aca1095c> {
    const url: string = this.getAca1095cConfirmResetApi(orgLevelId, year);      
    
    let request: HttpRequest<any> = new HttpRequest('PUT', url, requestBody);    
    return this.apiUtilService
      .request<IAca1095c, FieldsMeta>(request)
      .then((response: ResponseBody<IAca1095c, FieldsMeta>) => this.mapService.mapAca1095cRecords(response.data, response.meta));
  }
 private getAcaAddEmployee1095cApi(orgLevelId: number, year: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.c1095.root}/${orgLevelId}/${configurationConfig.api.ACA.c1095.addEmployee}`;
  }
  private getAcaRemoveEmployee1095cApi(orgLevelId: number, year: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.c1095.root}/${orgLevelId}/${configurationConfig.api.ACA.c1095.removeEmployee}`;
  }
 
  private getAca1095cApi(orgLevelId: number, year: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.c1095.root}/${orgLevelId}/${year}/${configurationConfig.api.ACA.c1095.all}`;
  }
  private getAca1095cEditApi(orgLevelId: number, year: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.c1095.root}/${orgLevelId}/${year}/${configurationConfig.api.ACA.c1095.edit1095cRecords}`;
  }
  private getAca1095cEditResetApi(orgLevelId: number, year: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.c1095.root}/${orgLevelId}/${year}/${configurationConfig.api.ACA.c1095.editReset1095cRecords}`;
  }
  private getAca1095cConfirmApi(orgLevelId: number, year: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.c1095.root}/${orgLevelId}/${year}/${configurationConfig.api.ACA.c1095.confirm1095cRecords}`;
  }
  private getAca1095cConfirmResetApi(orgLevelId: number, year: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.c1095.root}/${orgLevelId}/${year}/${configurationConfig.api.ACA.c1095.confirmReset1095cRecords}`;
  }
  private getAcaRootApi(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
