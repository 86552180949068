import { IMasterSchedule, IMasterScheduleFilters } from './master-schedule.types';
import { masterScheduleReducer } from './master-schedule.reducer';
import { masterScheduleFactory } from './master-schedule.initial-state';
import { MasterScheduleFilterActions } from './master-schedule-filter.actions';
import { MasterScheduleActions } from './master-schedule.actions';
import { IPayloadAction } from '../../../state-model/models/index';

export {
  IMasterSchedule, IMasterScheduleFilters,
  masterScheduleReducer,
  masterScheduleFactory
};

export const MASTER_SCHEDULE_ACTION_PROVIDERS: any[] = [
   MasterScheduleFilterActions, MasterScheduleActions
 ];

export interface IMasterSchedulePayloadAction extends IPayloadAction {
  filters?: any;
  employees?: any;
}
