import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { appConfig } from '../../../../app.config';
import { TimeclockAssignmentEmployee, TimeclockAssignmentState, TimeclockRestrictionTotal, TimeclockAssignment, AssignMode } from '../../../models/index';
import { EmployeeBadge, EmployeeDefinition } from '../../../../organization/models/index';
import { EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { TimeclockAssignmentManagementService } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimeclockAssignmentDialogComponent } from '../timeclock-assignment-dialog/timeclock-assignment-dialog.component';
var TimeclockAssignmentHeaderComponent = /** @class */ (function () {
    function TimeclockAssignmentHeaderComponent(timeclockAssignmentManagementService, employeeDefinitionsApiService, modalService) {
        var _this = this;
        this.timeclockAssignmentManagementService = timeclockAssignmentManagementService;
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        this.modalService = modalService;
        this.appConfig = appConfig;
        this.state = new TimeclockAssignmentState();
        this.stateChangedSubscription = this.timeclockAssignmentManagementService.onStateChanged$.subscribe(function (state) {
            _this.state = state;
            _this.hasSelectedWithoutBudges = false;
            _this.hasSelected = false;
        });
        this.selectionChangedSubscription = this.timeclockAssignmentManagementService.onSelectionChanged$.subscribe(function (records) {
            _this.selectedRecords = records;
            _this.hasSelected = (records && records.length > 0);
            if (!_this.hasSelected) {
                _this.hasSelectedWithoutBudges = false;
                return;
            }
            _this.hasSelectedWithoutBudges = !!_.find(records, function (record) {
                return !record.badge;
            });
        });
    }
    TimeclockAssignmentHeaderComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeclockAssignmentHeaderComponent.prototype.onUnassigned = function () {
        this.state.showAssigned = false;
        this.state.showUnassigned = true;
        this.timeclockAssignmentManagementService.onStateChanged(true);
    };
    TimeclockAssignmentHeaderComponent.prototype.onAssigned = function () {
        this.state.showAssigned = true;
        this.state.showUnassigned = false;
        if (this.state.showRestrictions.length === 0) {
            var restTotal = this.state.totals[0];
            restTotal.isChecked = true;
            this.state.showRestrictions.push(restTotal.restriction);
        }
        this.timeclockAssignmentManagementService.onStateChanged(true);
    };
    TimeclockAssignmentHeaderComponent.prototype.toggleRestriction = function (restTotal) {
        restTotal.isChecked = !restTotal.isChecked;
        var selected = _.filter(this.state.totals, function (total) {
            return total.isChecked;
        });
        this.state.showRestrictions = _.map(selected, function (total) {
            return total.restriction;
        });
        this.timeclockAssignmentManagementService.onStateChanged(true);
    };
    TimeclockAssignmentHeaderComponent.prototype.onGenerateBadge = function () {
        var _this = this;
        this.timeclockAssignmentManagementService.onLoadStatusChanged(true);
        var ids = _.map(this.selectedRecords, function (record) {
            return record.employee.id;
        });
        this.employeeDefinitionsApiService.generateBadges(ids)
            .then(function (badges) {
            _.forEach(badges, function (badge) {
                var emp = _.find(_this.state.records, function (employee) {
                    return employee.employee.id === badge.employeeId;
                });
                if (emp) {
                    emp.badge = badge.badge;
                }
            });
            _this.timeclockAssignmentManagementService.onLoadStatusChanged(false);
            _this.timeclockAssignmentManagementService.onStateChanged(false);
        });
    };
    TimeclockAssignmentHeaderComponent.prototype.onAssign = function () {
        var _this = this;
        this.state.selectedEmployees = this.getSelectedEmployees();
        TimeclockAssignmentDialogComponent.openDialog('Assign Employees to Timeclocks', this.state, AssignMode.assign, this.modalService, function (result, assignment) {
            if (result) {
                _this.timeclockAssignmentManagementService.onAssign(assignment);
            }
        });
    };
    TimeclockAssignmentHeaderComponent.prototype.onReAssign = function () {
        var _this = this;
        this.state.selectedEmployees = this.getSelectedEmployees();
        TimeclockAssignmentDialogComponent.openDialog('Re-Assign Employees to Timeclocks', this.state, AssignMode.reassign, this.modalService, function (result, assignment) {
            if (result) {
                _this.timeclockAssignmentManagementService.onReAssign(assignment);
            }
        });
    };
    TimeclockAssignmentHeaderComponent.prototype.onChangeRestriction = function () {
        var _this = this;
        this.state.selectedEmployees = this.getSelectedEmployees();
        TimeclockAssignmentDialogComponent.openDialog('Change Restriction Employees to Timeclocks', this.state, AssignMode.changeRestriction, this.modalService, function (result, assignment) {
            if (result) {
                _this.timeclockAssignmentManagementService.onAssign(assignment);
            }
        });
    };
    TimeclockAssignmentHeaderComponent.prototype.onUnAssign = function () {
        var _this = this;
        this.state.selectedEmployees = this.getSelectedEmployees();
        TimeclockAssignmentDialogComponent.openDialog('Unassign Employees from Timeclocks', this.state, AssignMode.unassign, this.modalService, function (result, assignment) {
            if (result) {
                _this.timeclockAssignmentManagementService.onUnAssign(assignment);
            }
        });
    };
    TimeclockAssignmentHeaderComponent.prototype.getSelectedEmployees = function () {
        return _.map(this.selectedRecords, function (emp) { return emp.employee; });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentHeaderComponent.prototype, "stateChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentHeaderComponent.prototype, "selectionChangedSubscription", void 0);
    return TimeclockAssignmentHeaderComponent;
}());
export { TimeclockAssignmentHeaderComponent };
