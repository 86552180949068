import { Organization, IOrganization, Department, IDepartment, ScheduleCycle, IScheduleCycle } from '../../../organization/models/index';

export interface IPostedSchedule {
  organization: IOrganization;
  department: IDepartment;
  scheduleCycle: IScheduleCycle;
  openShifts: number;
  postedDate: Date;
  username: string;
}

export class PostedSchedule {
  public get organizationName(): string {
    return this.organization ? this.organization.name : '';
  }
  public get departmentName(): string {
    return this.department ? this.department.name : '';
  }
  public get scheduleCycleStartDate(): Date {
    return this.scheduleCycle ? this.scheduleCycle.startDate.toDate() : null;
  }
  public get scheduleCycleEndDate(): Date {
    return this.scheduleCycle ? this.scheduleCycle.endDate.toDate() : null;
  }
  public organization: Organization;
  public department: Department;
  public scheduleCycle: ScheduleCycle;
  public openShifts: number;
  public postedDate: Date;
  public username: string;
}
