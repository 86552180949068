<div class="modal-body">
  <slx-employee-dependent-benefits
    [options]="options"
    [dependentsEnrollments]="dependentsEnrollments"
    (selectBenefits)="onSelectBenefits($event)"
    (enrollmentDone)="onCompleteEnrollment($event)"
  ></slx-employee-dependent-benefits>
</div>
<div class="modal-footer">
  <button type="button"
    class="btn green"
    [disabled]="!canEnroll"
    (click)="onEnroll()"
  >
    <i class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save
  </button>
  <button type="button"
    class="btn red"
    [disabled]="!canCancel"
    (click)="onCancel()"
  >
    <i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel
  </button>
</div>
