<div class="window-container {{className}}" [attr.modalId]="modalId">
  <div class="window-header {{headerClassName}}">
    <span class="title-label" *ngIf="title" title={{title}}>{{title}}</span>
    <div class="action-buttons">
      <button *ngIf="showCloseButton" type="button" class="close-button" (click)="closeWindow()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="window-content">
    <ng-content></ng-content>
  </div>
</div>