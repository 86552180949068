import * as _ from 'lodash';
import { Injector, ApplicationRef, ComponentFactoryResolver, ElementRef, ComponentRef, EmbeddedViewRef, TemplateRef, ViewContainerRef, InjectionToken, Provider, ResolvedReflectiveProvider, ReflectiveInjector, Type, ComponentFactory } from '@angular/core';
import { PopupComponent } from '@progress/kendo-angular-popup';
export var POPUP_CONTAINER2 = new InjectionToken('Popup Container 2');
export var removeElement = function (element) {
    if (element && element.parentNode) {
        element.parentNode.removeChild(element);
    }
};
var PopupService = /** @class */ (function () {
    function PopupService(applicationRef, componentFactoryResolver, injector, container) {
        this.applicationRef = applicationRef;
        this.componentFactoryResolver = componentFactoryResolver;
        this.injector = injector;
        this.container = container;
    }
    Object.defineProperty(PopupService.prototype, "rootViewContainer", {
        get: function () {
            var rootComponents = this.applicationRef.components || [];
            if (rootComponents[0]) {
                return rootComponents[0];
            }
            throw new Error('View Container not found! Inject the POPUP_CONTAINER or define a specific ViewContainerRef via the appendTo option.\n See http://www.telerik.com/kendo-angular-ui/components/popup/api/POPUP_CONTAINER/ for more details');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PopupService.prototype, "rootViewContainerNode", {
        get: function () {
            return this.container ? this.container.nativeElement : this.getComponentRootNode(this.rootViewContainer);
        },
        enumerable: true,
        configurable: true
    });
    PopupService.prototype.openWithWindowWrapper = function (wrapperType, options) {
        if (options === void 0) {
            options = {};
        }
        if (options.providers) {
            this.providers = options.providers;
        }
        else {
            this.providers = undefined;
        }
        var component;
        var nodes;
        component = this.createComponent(options.content, undefined, undefined, this.providers);
        nodes = [component ? [component.location.nativeElement] : []];
        //create wrapper 
        var wrapper = this.createComponent(wrapperType, nodes);
        var wrapperNodes = [wrapper ? [wrapper.location.nativeElement] : []];
        var popupComponentRef = this.appendPopup(wrapperNodes, options.appendTo);
        var popupInstance = popupComponentRef.instance;
        this.projectComponentInputs(popupComponentRef, options);
        popupComponentRef.changeDetectorRef.detectChanges();
        if (wrapper) {
            wrapper.changeDetectorRef.detectChanges();
        }
        if (component) {
            component.changeDetectorRef.detectChanges();
        }
        return {
            wrapper: wrapper,
            ref: {
                close: function () {
                    if (component) {
                        component.destroy();
                    }
                    if (wrapper) {
                        wrapper.destroy();
                    }
                    else {
                        popupComponentRef.instance.content = null;
                        popupComponentRef.changeDetectorRef.detectChanges();
                    }
                    popupComponentRef.destroy();
                },
                content: component,
                popup: popupComponentRef,
                popupAnchorViewportLeave: popupInstance.anchorViewportLeave,
                popupClose: popupInstance.close,
                popupElement: this.getComponentRootNode(popupComponentRef),
                popupOpen: popupInstance.open,
                popupPositionChange: popupInstance.positionChange
            }
        };
    };
    PopupService.prototype.open = function (options) {
        if (options === void 0) {
            options = {};
        }
        if (options.providers) {
            this.providers = options.providers;
        }
        else {
            this.providers = undefined;
        }
        var content = this.contentFrom(options.content);
        var component = content.component;
        var nodes = content.nodes;
        var popupComponentRef = this.appendPopup(nodes, options.appendTo);
        var popupInstance = popupComponentRef.instance;
        this.projectComponentInputs(popupComponentRef, options);
        popupComponentRef.changeDetectorRef.detectChanges();
        if (component) {
            component.changeDetectorRef.detectChanges();
        }
        var popupElement = this.getComponentRootNode(popupComponentRef);
        return {
            close: function () {
                if (component) {
                    component.destroy();
                }
                else {
                    popupComponentRef.instance.content = null;
                    popupComponentRef.changeDetectorRef.detectChanges();
                }
                popupComponentRef.destroy();
                removeElement(popupElement);
            },
            content: component,
            popup: popupComponentRef,
            popupAnchorViewportLeave: popupInstance.anchorViewportLeave,
            popupClose: popupInstance.close,
            popupElement: this.getComponentRootNode(popupComponentRef),
            popupOpen: popupInstance.open,
            popupPositionChange: popupInstance.positionChange
        };
    };
    PopupService.prototype.projectComponentInputs = function (component, options) {
        /*
        Object.getOwnPropertyNames(options)
            .filter(function (prop) { return prop !== 'content' || options.content instanceof TemplateRef; })
            .map(function (prop) {
                component.instance[prop] = options[prop];
            });
         */
        var props = _.filter(_.keys(options), function (key) { return key !== 'content' && key !== 'providers'; });
        _.each(props, function (prop) {
            component.instance[prop] = _.get(options, prop);
        });
    };
    PopupService.prototype.appendPopup = function (nodes, container) {
        var appRef = this.applicationRef;
        var popupComponentRef = this.createComponent(PopupComponent, nodes, container);
        if (!container) {
            appRef.attachView(popupComponentRef.hostView);
            this.rootViewContainerNode.appendChild(this.getComponentRootNode(popupComponentRef));
        }
        return popupComponentRef;
    };
    PopupService.prototype.getComponentRootNode = function (componentRef) {
        return componentRef.hostView.rootNodes[0];
    };
    PopupService.prototype.getComponentFactory = function (componentClass) {
        return this.componentFactoryResolver.resolveComponentFactory(componentClass);
    };
    PopupService.prototype.createComponent = function (componentClass, nodes, container, providers) {
        var factory = this.getComponentFactory(componentClass);
        var optionalInjector;
        if (providers)
            optionalInjector = this.mergeProviders();
        if (container) {
            return container.createComponent(factory, undefined, optionalInjector || this.injector, nodes);
        }
        else {
            var component = factory.create(optionalInjector || this.injector, nodes);
            this.applicationRef.attachView(component.hostView);
            return component;
        }
    };
    PopupService.prototype.mergeProviders = function () {
        var resolvedProviders = ReflectiveInjector.resolve(this.providers);
        var optionalInjector = ReflectiveInjector.fromResolvedProviders(resolvedProviders, this.injector);
        return optionalInjector;
    };
    PopupService.prototype.contentFrom = function (content) {
        if (!content || content instanceof TemplateRef) {
            return { component: null, nodes: [[]] };
        }
        var component = this.createComponent(content, undefined, undefined, this.providers);
        var nodes = component ? [component.location.nativeElement] : [];
        return {
            component: component,
            nodes: [
                nodes // <ng-content>
            ]
        };
    };
    return PopupService;
}());
export { PopupService };
