import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { LookupType } from '../../../organization/models/index';
import { LookupService } from '../../../organization/services/index';
import { ManageGroupsApiService } from './manage-groups-api.service';
import { ManageGroupsMapService } from './manage-groups-map.service';
var ManageGroupsManagementService = /** @class */ (function () {
    function ManageGroupsManagementService(lookup, apiService, mapService) {
        this.lookup = lookup;
        this.apiService = apiService;
        this.mapService = mapService;
        this.defaultGroupId = -1;
        this.defaultGroupName = 'Unassigned Group';
        this.subscriptions = {};
        this.loading$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.groups$ = new ReplaySubject(1);
        this.positions$ = new ReplaySubject(1);
    }
    ManageGroupsManagementService.prototype.init = function () {
        this.subscribeToOrgLevelChanges();
    };
    ManageGroupsManagementService.prototype.destroy = function () {
        this.loading$.complete();
        this.orgLevelChanged$.complete();
        this.groups$.complete();
        this.positions$.complete();
    };
    ManageGroupsManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    ManageGroupsManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    ManageGroupsManagementService.prototype.subscribeToLoadGroups = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.groups$.subscribe(callback);
    };
    ManageGroupsManagementService.prototype.subscribeToLoadPositions = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.positions$.subscribe(callback);
    };
    ManageGroupsManagementService.prototype.filteringGroups = function (g, srcText) {
        var _this = this;
        var groups = _.cloneDeep(g);
        if (_.size(srcText) > 0) {
            var searchText_1 = _.toLower(srcText);
            return _.reduce(groups, function (accum, groupView) {
                var isDefaultGroup = groupView.id === _this.defaultGroupId;
                var positions = _.filter(groupView.positions, function (pos) { return _.includes(_.toLower(pos.name), searchText_1); });
                if (isDefaultGroup || !isDefaultGroup && _.size(positions) > 0) {
                    accum.push(groupView);
                    groupView.positions = positions;
                }
                return accum;
            }, []);
        }
        return groups;
    };
    ManageGroupsManagementService.prototype.assignGroupsToPositions = function (group, position) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, groups;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = null;
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        position.positionGroupId = group.id === this.defaultGroupId ? null : group.id;
                        groups = this.mapService.mapToGroupsWithPositions(group, position, this.defaultGroupId, this.defaultGroupName);
                        return [4 /*yield*/, this.apiService.assignGroupsToPositions(this.orgLevel.id, groups)];
                    case 2:
                        result = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading$.next(false);
                        return [2 /*return*/, result];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ManageGroupsManagementService.prototype.createGroup = function (g) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var groupView, posGroup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        groupView = null;
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.apiService.createGroup(this.orgLevel.id, g)];
                    case 2:
                        posGroup = _a.sent();
                        groupView = this.mapService.makePosViewOfPosGroup(posGroup);
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading$.next(false);
                        return [2 /*return*/, groupView];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ManageGroupsManagementService.prototype.updateGroup = function (g) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var groupView, posGroup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        groupView = null;
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.apiService.updateGroup(this.orgLevel.id, g)];
                    case 2:
                        posGroup = _a.sent();
                        groupView = this.mapService.makePosViewOfPosGroup(posGroup);
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading$.next(false);
                        return [2 /*return*/, groupView];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ManageGroupsManagementService.prototype.deleteGroup = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = null;
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.apiService.deleteGroup(this.orgLevel.id, group.id)];
                    case 2:
                        result = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.loading$.next(false);
                        return [2 /*return*/, result];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ManageGroupsManagementService.prototype.createGroupView = function () {
        return this.mapService.createGroupView(null, '', this.orgLevel.relatedItemId);
    };
    ManageGroupsManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (o) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === o.id)
                return;
            _this.orgLevel = o;
            _this.loadData();
            _this.orgLevelChanged$.next(_this.orgLevel);
        });
    };
    ManageGroupsManagementService.prototype.loadData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lookups, groups;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadLookups()];
                    case 1:
                        lookups = _a.sent();
                        groups = this.mapService.mapToPositionGroupView(lookups[0], lookups[1], this.defaultGroupId, this.defaultGroupName);
                        this.groups$.next(groups);
                        this.positions$.next(lookups[0]);
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageGroupsManagementService.prototype.loadLookups = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lookups;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        return [4 /*yield*/, Promise.all([
                                this.lookup.getLookup({ lookupType: LookupType.position, orgLevelId: this.orgLevel.id, updateCacheForced: true }),
                                this.lookup.getLookup({ lookupType: LookupType.positionGroups, orgLevelId: this.orgLevel.id, updateCacheForced: true })
                            ])];
                    case 1:
                        lookups = _a.sent();
                        this.loading$.next(false);
                        return [2 /*return*/, lookups];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ManageGroupsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], ManageGroupsManagementService.prototype, "subscriptions", void 0);
    return ManageGroupsManagementService;
}());
export { ManageGroupsManagementService };
