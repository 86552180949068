var ScheduleRotationTemplate = /** @class */ (function () {
    function ScheduleRotationTemplate() {
        this.totalHours = 0;
    }
    Object.defineProperty(ScheduleRotationTemplate.prototype, "isFullTimeEmployee", {
        get: function () {
            return this.isFullTime ? 'FT' : 'PT';
        },
        enumerable: true,
        configurable: true
    });
    return ScheduleRotationTemplate;
}());
export { ScheduleRotationTemplate };
var SecondaryJobCodes = /** @class */ (function () {
    function SecondaryJobCodes() {
    }
    return SecondaryJobCodes;
}());
export { SecondaryJobCodes };
var ScheduleDays = /** @class */ (function () {
    function ScheduleDays() {
    }
    return ScheduleDays;
}());
export { ScheduleDays };
var Days = /** @class */ (function () {
    function Days() {
    }
    return Days;
}());
export { Days };
var AvailShifts = /** @class */ (function () {
    function AvailShifts() {
    }
    return AvailShifts;
}());
export { AvailShifts };
var EmployeeScheduledData = /** @class */ (function () {
    function EmployeeScheduledData() {
    }
    return EmployeeScheduledData;
}());
export { EmployeeScheduledData };
var EmpScheduleEntries = /** @class */ (function () {
    function EmpScheduleEntries() {
    }
    return EmpScheduleEntries;
}());
export { EmpScheduleEntries };
var NoRotationEmployeeList = /** @class */ (function () {
    function NoRotationEmployeeList() {
    }
    return NoRotationEmployeeList;
}());
export { NoRotationEmployeeList };
var TerminateRequest = /** @class */ (function () {
    function TerminateRequest() {
    }
    return TerminateRequest;
}());
export { TerminateRequest };
var SaveRotationData = /** @class */ (function () {
    function SaveRotationData() {
    }
    return SaveRotationData;
}());
export { SaveRotationData };
var EmployeesGenerateRotation = /** @class */ (function () {
    function EmployeesGenerateRotation() {
    }
    return EmployeesGenerateRotation;
}());
export { EmployeesGenerateRotation };
var AssignEmpToEmptySlot = /** @class */ (function () {
    function AssignEmpToEmptySlot() {
    }
    return AssignEmpToEmptySlot;
}());
export { AssignEmpToEmptySlot };
var FutureValidationShift = /** @class */ (function () {
    function FutureValidationShift() {
    }
    return FutureValidationShift;
}());
export { FutureValidationShift };
var FutureValidationShiftResult = /** @class */ (function () {
    function FutureValidationShiftResult() {
    }
    return FutureValidationShiftResult;
}());
export { FutureValidationShiftResult };
var OverlapOverTimeMessage = /** @class */ (function () {
    function OverlapOverTimeMessage() {
    }
    return OverlapOverTimeMessage;
}());
export { OverlapOverTimeMessage };
var FilterRotationOptions = /** @class */ (function () {
    function FilterRotationOptions() {
    }
    return FilterRotationOptions;
}());
export { FilterRotationOptions };
var PositionOptions = /** @class */ (function () {
    function PositionOptions() {
    }
    return PositionOptions;
}());
export { PositionOptions };
var FilterData = /** @class */ (function () {
    function FilterData() {
    }
    return FilterData;
}());
export { FilterData };
var SecondaryPositionData = /** @class */ (function () {
    function SecondaryPositionData() {
    }
    return SecondaryPositionData;
}());
export { SecondaryPositionData };
