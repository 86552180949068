import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { LeaveRequestsNavigationService } from '../../services/index';

@Directive({
  /* tslint:disable */
  selector: '[leaveRequestsLink]',
  /* tslint:enable */
})
export class LeaveRequestsLinkDirective {
  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: LeaveRequestsNavigationService = new LeaveRequestsNavigationService(this.router, this.route);
    navService.NavigateToLeaveRequests();
  }
}
