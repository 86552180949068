import { DialogOptions2, DialogModeSize } from './../../../../../common/models/dialog-options';
import { Provider } from '@angular/core';
import * as _ from 'lodash';
import { ModalService, } from '../../../../../common/index';
import { EmployeeDependentBenefitsOptions } from '../../../models/index';
var EmployeeDependentBenefitsDialogComponent = /** @class */ (function () {
    function EmployeeDependentBenefitsDialogComponent(dialogOptions, modalService, options) {
        this.isEnrolling = false;
        this.modalService = modalService;
        this.dialogOptions = dialogOptions;
        this.options = options;
    }
    Object.defineProperty(EmployeeDependentBenefitsDialogComponent.prototype, "canEnroll", {
        get: function () {
            return !this.isEnrolling
                && _.isObjectLike(this.benefits)
                && _.isArray(this.benefits.benefits)
                && _.size(this.benefits.benefits) > 0
                && _.isArray(this.benefits.dependents)
                && _.size(this.benefits.dependents) > 0
                && _.isDate(this.benefits.startDate)
                && _.isDate(this.benefits.endDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeDependentBenefitsDialogComponent.prototype, "canCancel", {
        get: function () {
            return !this.isEnrolling;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeDependentBenefitsDialogComponent.openDialog = function (data, modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.grid;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: EmployeeDependentBenefitsOptions,
                useValue: data
            }
        ];
        var title = 'Add Benefit(s) for Dependent(s)';
        return modalService.globalAnchor.openDialog2(EmployeeDependentBenefitsDialogComponent, title, dialogOptions, resolvedProviders, function (result) {
            callback(result);
        });
    };
    EmployeeDependentBenefitsDialogComponent.prototype.onSelectBenefits = function (benefits) {
        this.benefits = benefits;
    };
    EmployeeDependentBenefitsDialogComponent.prototype.onEnroll = function () {
        this.isEnrolling = true;
        this.dependentsEnrollments = this.benefits;
    };
    EmployeeDependentBenefitsDialogComponent.prototype.onCompleteEnrollment = function (isSuccessful) {
        if (isSuccessful) {
            this.dialogResult = true;
            this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
        }
        else {
            this.isEnrolling = false;
        }
    };
    EmployeeDependentBenefitsDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    return EmployeeDependentBenefitsDialogComponent;
}());
export { EmployeeDependentBenefitsDialogComponent };
