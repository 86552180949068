export interface IPBJExportLogEmployeeData {
  pbjId: string;
  exportId: number;
  employeeName: string;
  payrollNumber: string;
  employeeMasterId: number;
}

export class PBJExportLogEmployeeData {
  public pbjId: string;
  public exportId: number;
  public employeeName: string;
  public payrollNumber: string;
  public employeeMasterId: number;
}

