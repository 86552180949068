import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { StaffingPredictorManagementService } from '../../services/index';
import { FiveStarRatingContainer, StaffingRatingContainer } from '../../models/staffing-rating-container';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import html2pdf from 'html2pdf.js';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
import { SessionService } from '../../../../core/services/session/session.service';
var StaffingPredictorComponent = /** @class */ (function () {
    function StaffingPredictorComponent(managementService, changeDetector, appSettingsManageService, sessionService) {
        var _this = this;
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.appSettingsManageService = appSettingsManageService;
        this.sessionService = sessionService;
        this.five_star_v2_enabled = false;
        this.five_star_goal_section_enabled = false;
        this.hideToPrint = false;
        this.managementService.disableCollapsableContainer$.subscribe(function (result) {
            _this.value = result[0] ? result[1] : '';
        });
    }
    StaffingPredictorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadedSubscription = this.managementService.onLoaded$.subscribe(function (data) {
            if (_this.five_star_v2_enabled) {
                _this.fiveStartContainer = data;
                _this.container = new StaffingRatingContainer();
            }
            else {
                _this.container = data;
                _this.fiveStartContainer = new FiveStarRatingContainer();
            }
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.appSettingsManageService.getAppServerConfig().then(function (config) {
            _this.five_star_v2_enabled = config.five_star_v2_enabled;
            _this.five_star_goal_section_enabled = config.five_star_goal_section_enabled;
        });
        this.statusSubscription = this.managementService.onLoadStatus$.subscribe(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    StaffingPredictorComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    StaffingPredictorComponent.prototype.onYearSelected = function (option) {
        this.yearSelected = option;
    };
    StaffingPredictorComponent.prototype.onPeriodSelected = function (option) {
        this.periodSelected = option;
    };
    StaffingPredictorComponent.prototype.onOrgSelected = function (option) {
        this.orgSelected = option;
    };
    StaffingPredictorComponent.prototype.getUserName = function () {
        var user = this.sessionService.getUser();
        return user.username;
    };
    StaffingPredictorComponent.prototype.generatePDF = function () {
        var _this = this;
        this.hideToPrint = true;
        this.managementService.hideOnPrint(true);
        var element = document.getElementById('contentToConvert');
        var opt = {
            margin: [5, 0, 5, 0],
            filename: "Five-Star-" + this.orgSelected + "-" + this.yearSelected + "-" + this.periodSelected + "-" + this.getUserName() + ".pdf",
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', orientation: 'portrait' }
        };
        html2pdf().from(element).set(opt).save().then(function () {
            _this.hideToPrint = false;
            _this.managementService.hideOnPrint(false);
        }).catch(function () {
            _this.hideToPrint = false;
            _this.managementService.hideOnPrint(false);
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], StaffingPredictorComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], StaffingPredictorComponent.prototype, "statusSubscription", void 0);
    return StaffingPredictorComponent;
}());
export { StaffingPredictorComponent };
