export interface IPrescheduledOvertime {
  start: string;
  end: string;
  duration: string;
}

export class PrescheduledOvertime implements IPrescheduledOvertime {
  public start: string;
  public end: string;
  public duration: string;
}
