import { Component, OnInit } from '@angular/core';
import { LegendLabelsContentArgs, SeriesClickEvent } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import { WFMDashBoardDrillDownModel, WFMDashBoardModel, data_Colors } from '../../../../models/wfm-sync';
import { WfmSyncService } from '../../../../services/wfm/wfm-sync.service';
import * as _ from 'lodash';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';


@Component({
  selector: 'slx-org-payrollappstatus-sync',
  templateUrl: './org-payrollappstatus-sync.component.html',
  styleUrls: ['./org-payrollappstatus-sync.component.scss'],
})
export class OrgPayrollappstatusSyncComponent implements OnInit {
  autofit: any;
  public gridData: WFMDashBoardModel[] = [];
  public data: WFMDashBoardModel[] = [];
  public TotalCount: number;
  public success: number;
  public message: any;
  public dataView: WFMDashBoardDrillDownModel[] = [];
  public Status: string;
  public opened = false;
  public StatusMessage: string;
  public pieData: WFMDashBoardModel[] = [];
  public isLoading:boolean=false;

  constructor(private intl: IntlService, private wfmService: WfmSyncService, private popupService: PopupService) {
    this.isLoading = true;
    this.labelContent = this.labelContent.bind(this);
    this.allData = this.allData.bind(this); 
  }
  ngOnInit() {
    this.res();
    this.isLoading = false;
  }

  public labelContent(args: WFMDashBoardModel): string {
    this.res();
    const result = args.totalCount + ' share: \n' + args.statusMessage;
    return result;
  }
  public color:string;
  public res() {
    this.isLoading = true;
    this.wfmService.syncDashboard('ongoing', 'Organization', true).then((value2: any) => {
      this.gridData =data_Colors (value2.data);
      this.color=value2.color;
      this.Status = value2.st;
      this.data = value2.totalCount;
      this.TotalCount = value2.data[0].totalCount;
      this.isLoading = false;
    });
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }

  public onClick(event: SeriesClickEvent) {
    this.opened = true;
    this.dataView =[];
    this.StatusMessage = '';
    this.wfmService.syncPostDashboard('ongoing', 'organization', event.category, true).then((value2: any) => {
      this.dataView = value2.data;
      this.StatusMessage = event.category;
    });
  }

  public close(status: string): void {
    this.opened = false;
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataView, {
        sort: [{ field: 'orgLevelId', dir: 'asc' }],
      }).data
    };

    return result;
  }
}

