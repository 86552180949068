export * from './pbj-log-export/pbj-log-export.component';
export * from './pbj-log-grid/pbj-log-grid.component';
export * from './pbj-export-details/pbj-export-details.component';
export * from './pbj-details-emp-data-grid/pbj-details-emp-data-grid.component';
export * from './pbj-details-census-data-grid/pbj-details-census-data-grid.component';
export * from './pbj-details-errors-data-grid/pbj-details-errors-data-grid.component';
export * from './pbj-details-staffing-hours-grid/pbj-details-staffing-hours-grid.component';
export * from './pbj-manual-entry/pbj-manual-entry.component';
export * from './pbj-manual-entry-grid/pbj-manual-entry-grid.component';
export * from './pbj-new-export/pbj-new-export.component';
export * from './pbj-new-export-dialog/pbj-new-export-dialog.component';

import { PbjLogExportComponent } from './pbj-log-export/pbj-log-export.component';
import { PbjLogGridComponent } from './pbj-log-grid/pbj-log-grid.component';
import { PbjLogExportDetailsComponent } from './pbj-export-details/pbj-export-details.component';
import { PbjDetailsEmpDataGridComponent } from './pbj-details-emp-data-grid/pbj-details-emp-data-grid.component';
import { PbjDetailsCensusDataGridComponent } from './pbj-details-census-data-grid/pbj-details-census-data-grid.component';
import { PbjDetailsErrorsDataGridComponent } from './pbj-details-errors-data-grid/pbj-details-errors-data-grid.component';
import { PbjDetailsStaffingHoursGridComponent } from './pbj-details-staffing-hours-grid/pbj-details-staffing-hours-grid.component';
import { PbjManualEntryComponent } from './pbj-manual-entry/pbj-manual-entry.component';
import { PbjManualEntryGridComponent } from './pbj-manual-entry-grid/pbj-manual-entry-grid.component';
import { PbjNewExportComponent } from './pbj-new-export/pbj-new-export.component';
import { PbjNewExportDialog } from './pbj-new-export-dialog/pbj-new-export-dialog.component';
import { PbjNewExportWarningDialogComponent } from './pbj-new-export-warning-dialog/pbj-new-export-warning-dialog/pbj-new-export-warning-dialog.component';
import { PbjDuplicateMatchesDialogComponent } from '../pbj-duplicate-workers/pbj-duplicate-matches-dialog/pbj-duplicate-matches-dialog.component';

export const pbjExportComponents: any[] = [PbjLogExportComponent, PbjLogExportDetailsComponent, PbjNewExportComponent];
export const pbjEntryComponents: any[] = [PbjNewExportDialog, PbjNewExportWarningDialogComponent, PbjDuplicateMatchesDialogComponent];

export const pbjComponents: any[] = [
  PbjLogGridComponent,
  PbjDetailsEmpDataGridComponent,
  PbjDetailsCensusDataGridComponent,
  PbjDetailsErrorsDataGridComponent,
  PbjDetailsStaffingHoursGridComponent,
  PbjManualEntryComponent,
  PbjManualEntryGridComponent,
  PbjNewExportDialog,
  PbjNewExportWarningDialogComponent,
  PbjDuplicateMatchesDialogComponent,
  ...pbjExportComponents
];
