export interface IPunchProfileConfig {
  api: {
    root: string;
    punchProfile: {
      root: string;
      add : string;
      orgLevel: {
        root: string;
      }
    }
  };
}

export const punchProfileConfig: IPunchProfileConfig = {
  api: {
    root: 'ta',
    punchProfile: {
      root: 'punchProfile',
      add : 'AddPunchProfile',
      orgLevel: {
        root: 'orgLevel'
      }
    }
  }
};
