import { EmployeeDefinition, Shift, Position } from '../../../organization/models/index';

export class PunchesDisplaySettings {
  public positionFilter: NumberMap<number[]>;
  public shiftFilter: NumberMap<number[]>;

  public empMissingPunches: boolean;
  public empInvalidPunches: boolean;
  public empScheduleOnly: boolean;
  public empValid: boolean;
  public empNoPunches: boolean;
  public empRequest: boolean;
  public invalidLogin: boolean;
  public eventFilter: PunchesEventFilter;
}

export class PunchesEventFilter {
  public empPunch: boolean;
  public editPunch: boolean;
  public essRequest: boolean;
  public invalidPunch: boolean;
  public invalidLogin: boolean;
  public schedule: boolean;
  public deleted: boolean;
}
