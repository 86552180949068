import * as tslib_1 from "tslib";
import { OnDestroy, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var DeparturesGridComponent = /** @class */ (function () {
    function DeparturesGridComponent() {
        this.onOutFieldClick = new EventEmitter();
        this.onScheduledFieldClick = new EventEmitter();
        this.onOvertimeFieldClick = new EventEmitter();
        this.onDifferenceFieldClick = new EventEmitter();
        this.onRowSelected = new EventEmitter();
    }
    Object.defineProperty(DeparturesGridComponent.prototype, "records", {
        set: function (data) {
            if (data) {
                this.gridView = {
                    data: data,
                    total: data.length
                };
            }
        },
        enumerable: true,
        configurable: true
    });
    DeparturesGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    DeparturesGridComponent.prototype.selectionChange = function (selection) {
        var lastRow = _.maxBy(selection.selectedRows, function (r) { return r.index; });
        if (lastRow) {
            var records = _.slice(this.gridView.data, lastRow.index, this.gridView.data.length - 1);
            var filtered = _.filter(records, function (r) { return !!r.departureTime; });
            this.onRowSelected.emit(filtered);
        }
        else {
            this.onRowSelected.emit([]);
        }
    };
    DeparturesGridComponent.prototype.onOutFieldClickHandler = function (departure) {
        this.onOutFieldClick.emit(departure);
    };
    DeparturesGridComponent.prototype.onScheduledFieldClickHandler = function (departure) {
        this.onScheduledFieldClick.emit(departure);
    };
    DeparturesGridComponent.prototype.onOvertimeFieldClickHandler = function (departure) {
        this.onOvertimeFieldClick.emit(departure);
    };
    DeparturesGridComponent.prototype.onDifferenceFieldClickHandler = function (departure) {
        this.onDifferenceFieldClick.emit(departure);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DeparturesGridComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DeparturesGridComponent.prototype, "loadStartedSubscription", void 0);
    return DeparturesGridComponent;
}());
export { DeparturesGridComponent };
