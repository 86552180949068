import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { loaActionsDropdownItems } from '../../../../../common/models';
var WcIncidentReportHeaderComponent = /** @class */ (function () {
    function WcIncidentReportHeaderComponent(management, changeDetector) {
        this.management = management;
        this.changeDetector = changeDetector;
        this.leaveOptions = loaActionsDropdownItems;
        this.m_isCollapsed = false;
        this.subscriptions = {};
    }
    Object.defineProperty(WcIncidentReportHeaderComponent.prototype, "isCollapsed", {
        get: function () {
            return this.m_isCollapsed;
        },
        enumerable: true,
        configurable: true
    });
    WcIncidentReportHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.load = this.management.subscribeToReportLoad(function (x) {
            _this.report = x;
            if (_this.report && _this.report.employee) {
                _this.empId = _this.report.employee.id;
                if (_this.report.employee.employee) {
                    _this.empName = _this.prettyString(_this.report.employee.employee.name);
                }
                else {
                    _this.empName = '';
                }
                if (_this.report.employee.position) {
                    _this.posName = _this.prettyString(_this.report.employee.position.name);
                }
                else {
                    _this.posName = '';
                }
                if (_this.report.employee.organization) {
                    _this.orgName = _this.prettyString(_this.report.employee.organization.name);
                }
                else {
                    _this.orgName = '';
                }
                if (_this.report.employee.department) {
                    _this.depName = _this.prettyString(_this.report.employee.department.name);
                }
                else {
                    _this.depName = '';
                }
                _this.empType = _this.prettyString(_this.report.employee.empType);
                _this.gender = _this.prettyString(_this.report.employee.gender);
                _this.race = _this.prettyString(_this.report.employee.race);
                _this.maritalStatus = _this.prettyString(_this.report.employee.maritalStatus);
                _this.dependents = _this.report.employee.dependents.toString();
                _this.ssn = _this.prettyString(_this.report.employee.ssn);
                _this.age = _.isFinite(_this.report.employee.age) ? _this.report.employee.age.toString() : '';
                var zip = _this.prettyString(_this.report.employee.zip);
                var state = _this.prettyString(_this.report.employee.state);
                var city = _this.prettyString(_this.report.employee.city);
                var address = _this.prettyString(_this.report.employee.address);
                _this.address = address + ", " + city + ", " + state + ", " + zip;
                var date = _this.report.employee.dateHired;
                _this.dateHiredStr = _.isDate(date) ? _this.toDateString(date) : '' + _this.prettyString(date);
                date = _this.report.employee.dateOfBirth;
                _this.dateOfBirthStr = _.isDate(date) ? _this.toDateString(date) : '' + _this.prettyString(date);
            }
            else {
                _this.empName = '';
                _this.posName = '';
                _this.orgName = '';
                _this.depName = '';
                _this.empType = '';
                _this.gender = '';
                _this.race = '';
                _this.age = '';
                _this.maritalStatus = '';
                _this.dateHiredStr = '';
                _this.empId = null;
            }
        });
    };
    WcIncidentReportHeaderComponent.prototype.ngOnDestroy = function () { };
    WcIncidentReportHeaderComponent.prototype.collapsedChange = function (isCollapsed) {
        this.m_isCollapsed = isCollapsed;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    WcIncidentReportHeaderComponent.prototype.onClickEmployee = function () {
        this.management.navigateToUserProfile(this.report.employee.id);
    };
    WcIncidentReportHeaderComponent.prototype.createNewLOARequest = function (type) {
        this.management.navigateToLeaveManagementWithAction(type, this.report.employee.id);
    };
    WcIncidentReportHeaderComponent.prototype.toDateString = function (date) {
        if (date) {
            return moment(date).format(appConfig.dateFormat);
        }
        return '';
    };
    WcIncidentReportHeaderComponent.prototype.prettyString = function (str) {
        if (_.isNil(str)) {
            return '';
        }
        return str;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcIncidentReportHeaderComponent.prototype, "subscriptions", void 0);
    return WcIncidentReportHeaderComponent;
}());
export { WcIncidentReportHeaderComponent };
