import { Directive, Optional, Input, OnInit, SimpleChanges, DoCheck } from '@angular/core';
import { NgControl } from '@angular/forms';

import * as _ from 'lodash';

import { LookupDropdownInputComponent } from '../../../common/components/index';

@Directive({
  selector: 'slx-lookup-dropdown-input[emptyOption]'
})
export class EmptyOptionLdiDirective implements OnInit, DoCheck {
  @Input()
  public emptyOption: string | Object;

  private dropdown: LookupDropdownInputComponent;
  private optionAdded: boolean;
  private lastItems: any[];
  private lastItemsCount: number;

  constructor( @Optional() dropdown: LookupDropdownInputComponent) {
    this.dropdown = dropdown;
  }

  public ngOnInit(): void {
    this.lastItems = undefined;
    this.lastItemsCount = 0;
  }

  public ngDoCheck(): void {
    let doAdd: boolean = false;
    doAdd = !!this.dropdown.lookups;
    if (this.dropdown.lookups !== this.lastItems || this.dropdown.lookups && this.dropdown.lookups.length !== this.lastItemsCount) {
      this.dropdown.lookups = this.addEmptyOption(this.dropdown.lookups);
      this.lastItems = this.dropdown.lookups;
      this.lastItemsCount = this.dropdown.lookups.length;
    }
  }

  private addEmptyOption(items: any): any[] {
    let optionToAdd: any;
    if (typeof this.emptyOption === 'string') {
      optionToAdd = {
        [this.dropdown.descriptionMemberPath]: this.emptyOption,
        [this.dropdown.valueMemberPath]: 0
      };
    } else if (this.emptyOption instanceof Object) {
      optionToAdd = this.emptyOption;
    } else {
      return items;
    }

    if (items && items.length) {
      items = [optionToAdd, ...items];
    }
    return items;
  }
}
