import * as moment from 'moment';
import { IdealScheduleConfigType } from './ideal-schedule-config-type';

export class IdealSchedulePositionHistoryRecord {
  public name: string;

  public deviation: number;
  public idealScheduleId: string;
  public idealSchedulePositionId: string;
  public isDailyVariance: boolean;
  public jobCode: string;
  public targetHours: number;
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public idealScheduleType: IdealScheduleConfigType;
  public isActive: boolean;
}
