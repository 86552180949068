<ul class="menu-breadcrumb">
  <li *ngIf="icon && icon!='hidden'" class="menu-breadcrumb__item icon-item">
    <i class="{{icon}}" aria-hidden="true"></i>
  </li>
  <li *ngIf="hasMoreThanNecessary" class="menu-breadcrumb__item">
      <span class="menu-breadcrumb__link">..</span>
      <i class="fa fa-chevron-right menu-breadcrumb__icon" aria-hidden="true"></i>
  </li>
  <li *ngFor="let url of urls; let last = last; let index = index" class="menu-breadcrumb__item">
      <span class="menu-breadcrumb__link 1" *ngIf="url.isNotLink">{{url.title}}</span>
      <ng-container *ngIf="!url.isNotLink">
        <ng-container *ngIf="url.isAsync && url.title | async as title">
          <a class="menu-breadcrumb__link 2"
            *ngIf="!isHrmsApp(title)"
            (click)="onBreadcrumbClick(url)"
            [routerLink]="url.linkPath"
            [queryParams]="url.queryParamsLink"
          >{{ title }}</a>
          <span class="menu-breadcrumb__link 2" *ngIf="isHrmsApp(title)">{{ title }}</span>
        </ng-container>
        <ng-container *ngIf="!url.isAsync && url.title as title">
            <a class="menu-breadcrumb__link 2"
            *ngIf="!isHrmsApp(title)"
            (click)="onBreadcrumbClick(url)"
            [routerLink]="url.linkPath"
            [queryParams]="url.queryParamsLink"
          >{{ title }}</a>
          <span class="menu-breadcrumb__link 2" *ngIf="isHrmsApp(title)">{{ title }}</span>
        </ng-container>
      </ng-container>
      <i *ngIf="!last" class="fa fa-chevron-right menu-breadcrumb__icon" aria-hidden="true"></i>
  </li>
</ul>
