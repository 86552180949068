import { Subscription } from 'rxjs/Subscription';
import { OrgLevel, OrgLevelType } from './../../../../state-model/models/index';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from './../../../../core/decorators/index';
import { Component, OnInit } from '@angular/core';
@Component({
    moduleId: module.id,
    selector: 'slx-exception-console-page',
    templateUrl: 'exception-console-page.component.html',
    styleUrls: ['exception-console-page.component.scss']
})
export class ExceptionConsolePageComponent implements OnInit {
    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;
    public orgLevel: OrgLevel;
    public isDepartament: boolean;
    @unsubscribe()
    private orgLevelSubscription: Subscription;

    public ngOnInit(): void {
        this.orgLevelSubscription = this.orgLevel$.subscribe((value: OrgLevel) => {
            this.orgLevel = value;
            this.isDepartament = this.orgLevel.type === OrgLevelType.department;
        });
    }
}
