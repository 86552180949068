import { Injectable } from '@angular/core';
import { IAppState } from '../../store';
import { NgRedux } from '@angular-redux/store';


@Injectable()
export class ApplicationActions {
  public static SELECT_APPLICATION: string = 'SELECT_APPLICATION';
  public static APPLICATION_SELECTED: string = 'APPLICATION_SELECTED';
  public static DESELECT_APPLICATION: string = 'DESELECT_APPLICATION';

  private ngRedux: NgRedux<IAppState>;

  constructor(ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }

  public select(application: any): void {
    this.ngRedux.dispatch({
      type: ApplicationActions.SELECT_APPLICATION,
      payload: application
    });
  }

  public deselect(): void {
    this.ngRedux.dispatch({
      type: ApplicationActions.DESELECT_APPLICATION
    });
  }
}
