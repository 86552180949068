import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ISoDashboardCounters, 
  SoDashboardCounters, 
  ITaDashboardCounters, 
  TaDashboardCounters, 
  ILoaRequestCounters, 
  LoaRequestCounters, 
  AppAlertConfig, 
  DashboardAppAlertConfig,
  AlertConfig,
  IDashboardAppAlertConfig,
  IAppAlertConfig,
  IAlertConfig
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { DashboardApplication, DashboardApplications, IApplicationStyleMap, StyledUserApplication } from '../../../organization/models/index';
import { organizationConfig } from '../../../organization/organization.config';

@Injectable()
export class ApplicationsDashboardMapService {
  public mapTaCounters(taCounters: ITaDashboardCounters): TaDashboardCounters {
    const taDashboardCounters: TaDashboardCounters = new TaDashboardCounters(taCounters);

    return taDashboardCounters;
  }

  public mapSoCounters(soCounters: ISoDashboardCounters): SoDashboardCounters {
    const taDashboardCounters: SoDashboardCounters = new SoDashboardCounters(soCounters);

    return taDashboardCounters;
  }

  public mapLoaRequestCounters(dto: ILoaRequestCounters): LoaRequestCounters {
    const loaCounters: LoaRequestCounters = new LoaRequestCounters();
    loaCounters.onLeaveCount = dto.onLeaveCount;
    loaCounters.returningCount = dto.returningCount;
    loaCounters.upcomingCount = dto.upcomingCount;
    loaCounters.pastDueCount = dto.pastDueCount;
    loaCounters.incompleteCount = dto.incompleteCount;
    return loaCounters;
  }

  public mapDashboardUserConfig(dto: IDashboardAppAlertConfig): DashboardAppAlertConfig {
    const dashboardConfig: DashboardAppAlertConfig = new DashboardAppAlertConfig();
    dashboardConfig.soAlerts = this.mapAlertConfig(dto.soAlerts);
    dashboardConfig.taAlerts = this.mapAlertConfig(dto.taAlerts);
    dashboardConfig.lmAlerts = this.mapAlertConfig(dto.lmAlerts);

    return dashboardConfig;
  }

  public mapAlertConfig(appAlert: IAppAlertConfig): AppAlertConfig {
    const dtoAlerts: AppAlertConfig = new AppAlertConfig();
    dtoAlerts.appId = appAlert.appId;
    dtoAlerts.alerts = [];
    _.forEach(appAlert.alerts, (a) => {
      let dtoAlert: AlertConfig = this.mapConfig(a);
      dtoAlerts.alerts.push(dtoAlert);
    });
    return dtoAlerts;
  }

  public mapConfig(alert: IAlertConfig): AlertConfig {
    let dtoAlert: AlertConfig = new AlertConfig();
    dtoAlert.alertId = alert.alertId;
    dtoAlert.alertName = alert.alertName;
    dtoAlert.displayOrder = alert.displayOrder;
    dtoAlert.icon = alert.icon;
    dtoAlert.visible = alert.visible;
    dtoAlert.loginId = alert.loginId;
    dtoAlert.userAppAlertId = alert.userAppAlertId;
    dtoAlert.lastModifiedDate = dateTimeUtils.convertFromDtoDateTimeString(alert.lastModifiedDate);
    return dtoAlert;
  }

  public mapAppConfig(dto: DashboardApplications): DashboardApplications {
    let apps: DashboardApplication[] = [];
    _.forEach(dto.applications, (a) => {
      let styleMap: IApplicationStyleMap = _.find(organizationConfig.applications,
        (styleMapApp: IApplicationStyleMap) => styleMapApp.id === a.appId
      );

      if (!styleMap) {
        styleMap = organizationConfig.unknownApplication;
      }

      a.icon = styleMap.icon;
    })
    return dto;
  }
}
