import { IdealSchedulePositionRange } from './../../../models/ideal-schedule/ideal-schedule-position-range';
import { Component, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-ideal-schedule-range-grid',
    templateUrl: 'ideal-schedule-range-grid.component.html',
    styleUrls: ['ideal-schedule-range-grid.component.scss']
})

export class IdealScheduleRangeGridComponent {
    @Input()
    public ranges: IdealSchedulePositionRange[];

    public rangeName(range: IdealSchedulePositionRange): string {
        const acuityName = range.acuity.acuity ? range.acuity.acuity.name : 'Default';
        return `Range - ${acuityName} (${range.acuity.minValue || 0} - ${range.acuity.maxValue || 0})`;
    }
}
