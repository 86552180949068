import * as _ from 'lodash';
import { Component, Provider } from '@angular/core';

import { DialogOptions, IDialog } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { GeoPunchDialogOptions } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-geo-punch-dialog',
  templateUrl: 'geo-punch-dialog.component.html',
  styleUrls: ['geo-punch-dialog.component.scss']
})
export class GeoPunchDialogComponent implements IDialog {
  public geoPunchDetails: GeoPunchDialogOptions;
  public dialogResult: boolean;
  private options: DialogOptions;
  private modalService: ModalService;

  public static openDialog(data: GeoPunchDialogOptions, modalService: ModalService): GeoPunchDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 510;
    dialogOptions.width = 630;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: GeoPunchDialogOptions,
        useValue: data
      }
    ];
    return modalService.globalAnchor.openDialog(
      GeoPunchDialogComponent,
      'Geo Punch Details',
      dialogOptions,
      resolvedProviders
    );
  }

  constructor(
    geoPunchDetails: GeoPunchDialogOptions,
    options: DialogOptions,
    modalService: ModalService
  ) {
    this.geoPunchDetails = geoPunchDetails;
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
