import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { NgForm } from '@angular/forms';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSectionsAvailability, EmployeeWeeklyAvailability } from '../../../models/employee-sections-schedule/employee-sections-availability';
import { EmployeeSectionsScheduleApiService, WeekDayService } from '../../../services/index';
import { LookupService } from '../../../../../organization/services/index';
import { Lookup, LookupType } from '../../../../../organization/models/index';
import { Assert } from '../../../../../framework/assert/assert';
import { IRangeDates } from '../../../../../common/models/range-dates';
import * as moment from 'moment';
import * as _ from 'lodash';
var EmployeeSectionsAvailabilityComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAvailabilityComponent, _super);
    function EmployeeSectionsAvailabilityComponent(weekDayService, lookupService, employeeSectionsScheduleApiService, decorator, ngZone, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.weekDayService = weekDayService;
        _this.lookupService = lookupService;
        _this.configHours = [
            { count: 1 },
            { count: 2 },
            { count: 3 },
            { count: 4 },
            { count: 5 },
            { count: 6 },
            { count: 7 },
            { count: 8 },
            { count: 9 },
            { count: 10 },
            { count: 11 },
            { count: 12 }
        ];
        //TODO: Move into appropriate service or config
        _this.hours = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        _this.m_startDate = moment().startOf('year').toDate();
        _this.m_endDate = moment().endOf('year').toDate();
        _this.changeDetector = changeDetector;
        Assert.isNotNull(employeeSectionsScheduleApiService, 'employeeSectionsScheduleApiService');
        _this.employeeSectionsScheduleApiService = employeeSectionsScheduleApiService;
        _this.selectedConfigHour = _this.configHours[7];
        return _this;
    }
    Object.defineProperty(EmployeeSectionsAvailabilityComponent.prototype, "startDate", {
        get: function () {
            return this.m_startDate;
        },
        set: function (value) {
            this.m_startDate = value;
            if (this.availabilityDateRange) {
                this.availabilityDateRange.startDate = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAvailabilityComponent.prototype, "endDate", {
        get: function () {
            return this.m_endDate;
        },
        set: function (value) {
            this.m_endDate = value;
            if (this.availabilityDateRange) {
                this.availabilityDateRange.endDate = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAvailabilityComponent.prototype, "weeklyAvailability", {
        get: function () {
            return this.m_weeklyAvailability;
        },
        set: function (value) {
            this.m_weeklyAvailability = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAvailabilityComponent.prototype, "availabilityDateRange", {
        get: function () {
            return this.m_availabilityDateRange;
        },
        set: function (value) {
            if (this.isAnyAvailabilityRecord) {
                this.m_availabilityDateRange = value;
                if (value) {
                    this.startDate = value.startDate;
                    this.endDate = value.endDate;
                }
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAvailabilityComponent.prototype, "isAnyAvailabilityRecord", {
        get: function () {
            return this.availabilityLookup && this.availabilityLookup.items && this.availabilityLookup.items.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAvailabilityComponent.prototype, "employeeSubsectionAvailability", {
        get: function () {
            return this.availabilitySection;
        },
        set: function (value) {
            if (value !== null && value !== undefined) {
                this.availabilitySection = value;
                if (value.availabilityDateRange) {
                    this.availabilityDateRange = value.availabilityDateRange;
                }
                if (value.weeklyAvailability) {
                    this.weeklyAvailability = value.weeklyAvailability;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAvailabilityComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAvailabilityComponent.prototype.getSubsectionModel = function () {
        return this.employeeSubsectionAvailability;
    };
    EmployeeSectionsAvailabilityComponent.prototype.onHourClick = function (hour) {
        Assert.isNotNull(this.weeklyAvailability, 'weeklyAvailability');
        if (hour.isAvailable) {
            hour.isAvailable = false;
            return;
        }
        var weeklyHours = this.weeklyAvailability.hours;
        var foundWeeklyHourIndex = _.findIndex(weeklyHours, function (weeklyHour) { return weeklyHour.day === hour.day && weeklyHour.hour === hour.hour; });
        if (foundWeeklyHourIndex >= 0) {
            for (var i = 0; i < this.selectedConfigHour.count; i++) {
                var weeklyHourIndex = foundWeeklyHourIndex + i;
                weeklyHourIndex = weeklyHourIndex >= weeklyHours.length ? weeklyHourIndex % weeklyHours.length : weeklyHourIndex;
                if (!weeklyHours[weeklyHourIndex].isAvailable) {
                    weeklyHours[weeklyHourIndex].isAvailable = true;
                }
            }
        }
    };
    EmployeeSectionsAvailabilityComponent.prototype.onAcceptClearAvailability = function (acceptPopover) {
        acceptPopover.hide();
        this.clearAvailabilityRecords();
    };
    EmployeeSectionsAvailabilityComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadAvailabilityRanges(false);
    };
    EmployeeSectionsAvailabilityComponent.prototype.onSelectedRecordChanged = function (evt) {
        this.loadSubsection();
    };
    EmployeeSectionsAvailabilityComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
    };
    EmployeeSectionsAvailabilityComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsScheduleApiService.getAvailability(this.employeeId, this.startDate, this.endDate)
            .then(function (availability) {
            _this.employeeSubsectionAvailability = availability;
            _this.stopProgress();
            if (_this.isDestroyed) {
                return;
            }
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsAvailabilityComponent.prototype.doSave = function (effectiveDate) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsScheduleApiService.setAvailability(this.employeeId, this.startDate, this.endDate, this.employeeSubsectionAvailability)
            .then(function (response) {
            _this.stopProgress();
            _this.loadAvailabilityRanges(true);
            if (_this.isDestroyed) {
                return;
            }
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsAvailabilityComponent.prototype.loadAvailabilityRanges = function (reloadSubsection) {
        var _this = this;
        this.startProgress();
        this.lookupService.getLookup({ lookupType: LookupType.availabilityDefinition, employeeId: this.employeeId, orgLevelId: undefined, updateCacheForced: true })
            .then(function (availabilityLookup) {
            if (availabilityLookup && availabilityLookup.items && availabilityLookup.items.length === 0) {
                var availabilityRecordEmpty = { name: 'No Records Available' };
                _this.m_availabilityDateRange = availabilityRecordEmpty;
            }
            _this.availabilityLookup = availabilityLookup;
            if (reloadSubsection) {
                _this.loadSubsection();
            }
            else {
                _this.stopProgress();
            }
            if (_this.isDestroyed) {
                return;
            }
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    EmployeeSectionsAvailabilityComponent.prototype.clearAvailabilityRecords = function () {
        var weeklyHours = this.weeklyAvailability.hours;
        _.forEach(weeklyHours, function (weeklyHour) {
            weeklyHour.isAvailable = false;
        });
    };
    return EmployeeSectionsAvailabilityComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsAvailabilityComponent };
