import { Provider } from '@angular/core';

export * from './benefit-class-map.service';
export * from './benefit-class-api.service';
export * from './benefit-class-validator-adapter';

import { BenefitClassMapService } from './benefit-class-map.service';
import { BenefitClassApiService } from './benefit-class-api.service';
import { BenefitClassValidatorAdapter } from './benefit-class-validator-adapter';

export const benefitClassservices: Provider[] = [
  BenefitClassMapService,
  BenefitClassApiService,
  BenefitClassValidatorAdapter
];
