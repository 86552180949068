import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { dateTimeUtils } from './../../../../common/utils/dateTimeUtils';
import { IOvertimeApprovalsRecord, OvertimeApprovalsRecord } from './../../models/overtime-approvals/overtime-approvals-record.model';
import { GenericField } from '../../../../organization/models/generic-list/generic-field';

@Injectable()
export class OvertimeApprovalsMapService {

  public toOvertimeApprovalsRecordsDto(entries: StringMap<GenericField>[]): IOvertimeApprovalsRecord[] {
    return _.map(entries, (entry: StringMap<GenericField>) => this.toOvertimeApprovalsRecordDto(entry));
  }

  public toOvertimeApprovalsRecordDto(entry: StringMap<GenericField>): IOvertimeApprovalsRecord {
    const { EmpId, JobCode, OvertimeDate, OvertimeStart, OvertimeEnd, OvertimeExceptionId, OvertimeApprovalId } = entry;
    const item: IOvertimeApprovalsRecord = {
      employeeId: EmpId.value.value,
      positionId: JobCode.value.value,
      date: dateTimeUtils.convertToDtoDateTimeString(OvertimeDate.value.value),
      startDate: dateTimeUtils.convertToDtoDateTimeString(OvertimeStart.value.value),
      endDate: dateTimeUtils.convertToDtoDateTimeString(OvertimeEnd.value.value),
      exceptionId: OvertimeExceptionId.value.value,
      overtimeApprovalId: OvertimeApprovalId.value.value
    };

    return item;
  }
}
