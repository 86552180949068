export class ValuePairChartInput {
  public value1: number;
  public value2: number;
  public label1: string;
  public label2: string;
  public additionalValue: string;
  public additionalLabel: string;
  public upperLimit: number;
  public lowerLimit: number;
  public additionalInfo?: any;
}
