<div class="pm-tpl-grid slx-full-height">
  <span class="grid-title">Configure Templates</span>
  <slx-pm-config-toolbar [canCreateNew]="canCreateNew" [itemClass]="itemClass"></slx-pm-config-toolbar>

  <form novalidate #gridForm="ngForm" class="grid-form">

    <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
      [slxKendoGridColumnsGroup]="columnsGroupName" [data]="gridState?.view" scrollable="'scrollable'" [sortable]="true"
      [sort]="gridState.state.sort" [skip]="gridState.state.skip" [pageable]="true" [pageSize]="pageSize"
      [filterable]="'menu'" [filter]="gridState.state.filter" [groupable]="false" [selectable]="false"
      [slxKendoGridState]="gridState.gridDefinition" (dataStateChange)="gridState.dataStateChange($event)"
      (selectionChange)="gridState.selectionChange($event)" (stateRestored)="gridState.dataStateChange($event)"
      (edit)="editHandler($event)" (save)="gridState.saveHandler($event)" (cancel)="gridState.cancelHandler($event)"
      (remove)="gridState.removeHandler($event)" (pageChange)="pageChanged($event)">

      <kendo-grid-command-column title="Edit" width="70" [hidden]="!canEdit">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="inline-buttons">
            <button kendoGridEditCommand type="button" *ngIf="canEdit" class="slx-inline-edit-button2">
              <i class="fas fa-edit slx-inline-edit-button__icon" aria-hidden="true"></i>
            </button>
            <button kendoGridRemoveCommand *ngIf="canEdit" class="slx-icon-button slx-inline-remove-button2">
              <i class="fas fa-trash slx-inline-edit-button__icon" aria-hidden="true"></i>
            </button>
            <button kendoGridSaveCommand type="button" [disabled]="!ngFormChild.valid"
              class="slx-icon-button slx-inline-apply-button2">
              <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button2">
              <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-column  title="Template Id" 
      field="templateId" width="50"
        [kendoGridColumnFilterableOn]="['desktop']" [kendoGridColumnSortableOn]="['desktop']"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Template Id</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.templateId }}</ng-template>
      </kendo-grid-column>


      <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']" title="OrgLevel" 
      width="50" [editable]="false" field="orgLevelName"
        [kendoGridColumnFilterableOn]="['desktop']" [kendoGridColumnSortableOn]="['desktop']"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>OrgLevel</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.orgLevelName }}</ng-template>
      </kendo-grid-column>

      <kendo-grid-column [editable]="false"
        title="Template Name" field="fileName"
        width="150" [kendoGridColumnFilterableOn]="['desktop']" 
        [kendoGridColumnSortableOn]="['desktop']"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Template Name</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.fileName }}</ng-template>
      </kendo-grid-column>

      <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']" 
      title="Template Description" field="templateDescription"
        width="110" [kendoGridColumnFilterableOn]="['desktop']" 
        [kendoGridColumnSortableOn]="['desktop']"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Template Description</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.templateDescription }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <input slx-input 
            [(ngModel)]="dataItem.templateDescription" 
            name="templateDescription" 
            placeholder="Description"
            slxMaxLength="4000"
            required="true" 
            >
            <span errorMessage for="required"></span>
            <span errorMessage for="slxMaxLength">Description should be a maximum length of 4000</span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']" 
      title="Modified By" width="110" [editable]="false"
        [kendoGridColumnFilterableOn]="['desktop']" 
        [kendoGridColumnSortableOn]="['desktop']" 
        field="modifiedUserName"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Modified By</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.modifiedUserName }}</ng-template>
      </kendo-grid-column>

      <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']" 
      title="Modified On" width="110" [editable]="false"
        [kendoGridColumnFilterableOn]="['desktop']" 
        [kendoGridColumnSortableOn]="['desktop']" 
        field="modifiedDate"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Modified On</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.modifiedDate | amDateFormat: appConfig.dateFormat }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *slxHiddenOn="['desktop']"
      title=""
      class="overflow-visible-cell action-column"
      [width]="50"
      [filterable]="false"
      [sortable]="false"
    >
    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
      <div class="mobile-grid-editor m-grid">
    
        <div class="flex flex-direction-row m-grid__group textarea-child">
          <p class="m-grid__field">
              <textarea 
                #tplDescMobile="ngModel"
                required="true" 
                class="grid-editor__input full-width-textarea"
                name="tplDescMobile" 
                [(ngModel)]="dataItem.templateDescription"
                maxlength="4000">
              </textarea>
          </p>
        </div>

        <div *ngIf="tplDescMobile.errors" class="m-grid__group slx-error-block">
          <span errorMessage for="required"></span>
        </div>
    
        <div class="flex flex-direction-row m-grid__group">
          <p class="m-grid__field">
            <span class="m-grid__title">Template Id</span>
            <span class="m-grid__value">{{ dataItem.templateId }}</span>
          </p>
        </div>
    
        <div class="flex flex-direction-row m-grid__group">
          <p class="m-grid__field">
            <span class="m-grid__title">Template Name</span>
            <span class="m-grid__value">{{ dataItem.fileName }}</span>
          </p>
        </div>
    
        <div class="flex flex-direction-row m-grid__group">
          <p class="m-grid__field">
            <span class="m-grid__title">OrgLevel</span>
            <span class="m-grid__value">{{ dataItem.orgLevelName }}</span>
          </p>
        </div>
    
        <div class="flex flex-direction-row m-grid__group">
          <p class="m-grid__field">
            <span class="m-grid__title">Created by</span>
            <span class="m-grid__value">{{ dataItem.modifiedUserName }}</span>
          </p>
    
          <p class="m-grid__field">
            <span class="m-grid__title">Reviewed By</span>
            <span class="m-grid__value">{{ dataItem.modifiedDate | amDateFormat: appConfig.dateFormat }}</span>
          </p>
    
        </div>

        <div class="flex flex-direction-row m-grid__group">
          <button kendoGridSaveCommand type="button" [disabled]="!ngFormChild.valid"
            class="slx-icon-button slx-inline-apply-button2">
            <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
          </button>
          <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button2">
            <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
          </button>
        </div>

      </div>
    </ng-template>
    </kendo-grid-column>

      <kendo-grid-excel [fileName]="xlsxName" [fetchData]="retriveAllPages()"></kendo-grid-excel>
      <kendo-grid-pdf [fileName]="pdfName" [allPages]="true" [repeatHeaders]="true">
        <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
        <kendo-grid-column field="templateId" title="Template Id">
        </kendo-grid-column>
        <kendo-grid-column field="orgLevelName" title="OrgLevel">
        </kendo-grid-column>
        <kendo-grid-column field="fileName" title="Template Name">
        </kendo-grid-column>
        <kendo-grid-column field="templateDescription" title="Template Description">
        </kendo-grid-column>
        <kendo-grid-column field="modifiedUserName" title="Modified By">
        </kendo-grid-column>
        <kendo-grid-column field="modifiedDate" title="Modified On">
          <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.modifiedDate | amDateFormat: appConfig.dateFormat }}</ng-template>
        </kendo-grid-column>
      </kendo-grid-pdf>

    </kendo-grid>

  </form>
</div>