/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./approval-required-codes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../../../../organization/components/generic-list/generic-grid/generic-grid.component.ngfactory";
import * as i5 from "../../../../organization/components/generic-list/generic-grid/generic-grid.component";
import * as i6 from "../../../../organization/services/generic-list/generic-list-management.service";
import * as i7 from "@angular/forms";
import * as i8 from "../../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component.ngfactory";
import * as i9 from "../../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component";
import * as i10 from "../../../../common/directives/kendo-ui-extensions/date-range-ngx-state/date-range-ngx-active-state.directive";
import * as i11 from "../../../../common/services/component-state/component-state-storage.service";
import * as i12 from "../../../../common/services/state-management/state-management.service";
import * as i13 from "../../../../common/services/column-settings/column-management.service";
import * as i14 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i15 from "../../../../organization/services/generic-list/generic-list-api.service";
import * as i16 from "./approval-required-codes.component";
import * as i17 from "../../services/approval-required-codes/approval-required-codes-api.service";
import * as i18 from "@angular/router";
var styles_ApprovalRequiredCodesComponent = [i0.styles];
var RenderType_ApprovalRequiredCodesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApprovalRequiredCodesComponent, data: {} });
export { RenderType_ApprovalRequiredCodesComponent as RenderType_ApprovalRequiredCodesComponent };
export function View_ApprovalRequiredCodesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "slx-spinner", [["novalidate", ""]], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 17, "slx-generic-grid", [["gridKey", "APPROVAL_REQUIRED_CODES"]], null, [[null, "selectionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = (_co.onSelectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_GenericGridComponent_0, i4.RenderType_GenericGridComponent)), i1.ɵdid(3, 245760, null, 0, i5.GenericGridComponent, [i6.GenericListManagementService, i1.ChangeDetectorRef], { gridKey: [0, "gridKey"] }, { selectionChanged: "selectionChanged" }), (_l()(), i1.ɵeld(4, 0, null, 0, 15, "form", [["class", "row-form row-item"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i7.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(6, 4210688, [["form", 4]], 0, i7.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i7.ControlContainer, null, [i7.NgForm]), i1.ɵdid(8, 16384, null, 0, i7.NgControlStatusGroup, [[4, i7.ControlContainer]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "slx-date-range-ngx", [["endDatePlaceholder", "End Date"], ["name", "startEndDate"], ["slxControlActiveState", ""], ["startDatePlaceholder", "Start Date"]], null, [[null, "rangeDateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rangeDateChanged" === en)) {
        var pd_0 = (_co.onFilterDateChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_DateRangeNgxComponent_0, i8.RenderType_DateRangeNgxComponent)), i1.ɵdid(11, 573440, null, 0, i9.DateRangeNgxComponent, [], { startDate: [0, "startDate"], endDate: [1, "endDate"], startDatePlaceholder: [2, "startDatePlaceholder"], endDatePlaceholder: [3, "endDatePlaceholder"] }, { rangeDateChanged: "rangeDateChanged" }), i1.ɵdid(12, 212992, null, 0, i10.DateRangeNgxActiveStateDirective, [i9.DateRangeNgxComponent, i11.ComponentStateStorageService, i12.StateManagementService], { resetType: [0, "resetType"], controlId: [1, "controlId"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "spacer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "element top-offset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "theme-button-apply btn"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unapprove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Un-Approve Pay Code"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "element top-offset"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "theme-button-apply btn"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.approve() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Approve Pay Code"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = "APPROVAL_REQUIRED_CODES"; _ck(_v, 3, 0, currVal_1); var currVal_9 = _co.startDate; var currVal_10 = _co.endDate; var currVal_11 = "Start Date"; var currVal_12 = "End Date"; _ck(_v, 11, 0, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = ""; var currVal_14 = "startEndDate"; _ck(_v, 12, 0, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 8).ngClassValid; var currVal_7 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_15 = !_co.canUnapprove; _ck(_v, 15, 0, currVal_15); var currVal_16 = !_co.canApprove; _ck(_v, 18, 0, currVal_16); }); }
export function View_ApprovalRequiredCodesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-approval-required-codes", [], null, null, null, View_ApprovalRequiredCodesComponent_0, RenderType_ApprovalRequiredCodesComponent)), i1.ɵprd(4608, null, i13.ColumnManagementService, i13.ColumnManagementService, [i14.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.GenericListManagementService, i6.GenericListManagementService, [i15.GenericListApiService]), i1.ɵprd(512, null, i12.StateManagementService, i12.StateManagementService, [i11.ComponentStateStorageService, i14.ColumnSettingsStorageService]), i1.ɵdid(4, 245760, null, 0, i16.ApprovalRequiredCodesComponent, [i6.GenericListManagementService, i17.ApprovalRequiredCodesApiService, i12.StateManagementService, i11.ComponentStateStorageService, i18.ActivatedRoute, i18.Router], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var ApprovalRequiredCodesComponentNgFactory = i1.ɵccf("slx-approval-required-codes", i16.ApprovalRequiredCodesComponent, View_ApprovalRequiredCodesComponent_Host_0, {}, {}, []);
export { ApprovalRequiredCodesComponentNgFactory as ApprovalRequiredCodesComponentNgFactory };
