import {
  FieldData, FieldsMeta,

} from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';
import * as _ from 'lodash';

export interface IEmployeeSectionsSelfService {
  badgeId: string;
  employeeId: number;
  enterTimeMethod: string;
  password: string;
  timecard: boolean;
  schedule: boolean;
  availability: boolean;
  inservice: boolean;
  changePassword: boolean;
  changeAddress: boolean;
  payStubs: boolean;
}

export class EmployeeSectionsSelfService extends EmployeeSectionsBase {
  public hasEssAccount: boolean;
  public isEssEnabled: boolean;
  public badgeId: string;
  public employeeId: number;
  public enterTimeMethod: string;
  public password: string;
  public timecard: boolean;
  public schedule: boolean;
  public availability: boolean;
  public inservice: boolean;
  public changePassword: boolean;
  public changeAddress: boolean;
  public payStubs: boolean;

  public get availableOptions(): string[] {
    const allOptions: string[] = ['timecard', 'schedule', 'availability', 
              'inservice', 'changePassword', 'changeAddress','payStubs'];
    let options: string[] = _.filter(allOptions, (opt: string) => !!(<any>this)[opt]);
    return options;
  }
}
