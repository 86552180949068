import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import * as moment from 'moment';
import { Assert } from '../../../../framework/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Meta, ResponseBody } from '../../../../core/models/index';
import { PbjManualEntryMapService } from './pbj-manual-entry-map.service';
import { dateTimeUtils } from '../../../../common/utils/index';
import {
  PBJManualTimeEntry,
  PBJManualTimeEntity,
  IPBJManualTimeEntity,
  IPBJManualTimeEntry
} from '../../models/index';

import { pbjConfig } from '../../pbj.config';

@Injectable()
export class PbjManualEntryApiService {
  apiUtilService: ApiUtilService;
  urlParamsService: UrlParamsService;
  mapService: PbjManualEntryMapService;

  constructor(apiUtilService: ApiUtilService, urlParamsService: UrlParamsService, mapService: PbjManualEntryMapService) {
    this.apiUtilService = apiUtilService;
    this.urlParamsService = urlParamsService;
    this.mapService = mapService;
  }

  public getPBJManualEntries(orgLevelId: number, startDate: Date, endDate: Date): Promise<PBJManualTimeEntity> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getApiRoot()}/orgLevel/${orgLevelId}`;
    const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    const promise: Promise<PBJManualTimeEntity> = this.apiUtilService.request<IPBJManualTimeEntity, Meta>(request)
      .then((response: ResponseBody<IPBJManualTimeEntity, Meta>) => this.mapService.mapToPBJManualEntity(response.data));

    return promise;
  }

  public savePBJManualEntry(orgLevelId: number, entry: PBJManualTimeEntry): Promise<PBJManualTimeEntry> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(entry, 'entry');

    const entryDTO = this.mapService.mapToPBJManualEntryDTO(entry, false);
    const url: string = `${this.getApiRoot()}/orgLevel/${orgLevelId}/${entryDTO.id}`;
    const request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, entryDTO);

    const promise: Promise<PBJManualTimeEntry> = this.apiUtilService.request<IPBJManualTimeEntry, Meta>(request)
      .then((response: ResponseBody<IPBJManualTimeEntry, Meta>) => this.mapService.mapToPBJManualEntry(response.data));

    return promise;
  }

  public addPBJManualEntry(orgLevelId: number, entry: PBJManualTimeEntry): Promise<PBJManualTimeEntry> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(entry, 'entry');

    const entryDTO = this.mapService.mapToPBJManualEntryDTO(entry, true);
    const url: string = `${this.getApiRoot()}/orgLevel/${orgLevelId}`;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, entryDTO);

    const promise: Promise<PBJManualTimeEntry> = this.apiUtilService.request<IPBJManualTimeEntry, Meta>(request)
      .then((response: ResponseBody<IPBJManualTimeEntry, Meta>) => this.mapService.mapToPBJManualEntry(response.data));

    return promise;
  }

  public removePBJManualEntries(entriesIds: number[], orgLevelId: number): Promise<any> {
    Assert.isNotNull(entriesIds, 'entries');

    const url: string = `${this.getApiRoot()}/orgLevel/${orgLevelId}`;
    const request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url, null, entriesIds);

    const promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);

    return promise;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${pbjConfig.api.pbj.root}/${pbjConfig.api.pbj.manualEntry.root}`;
  }
}
