var OrgLevel = /** @class */ (function () {
    function OrgLevel() {
    }
    Object.defineProperty(OrgLevel.prototype, "hasChilds", {
        get: function () {
            if (this.childs && this.childs.length > 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    return OrgLevel;
}());
export { OrgLevel };
