/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading-indicator.component";
import * as i4 from "../../../organization/services/application-state-bus/application-state-bus.service";
var styles_LoadingIndicatorComponent = [i0.styles];
var RenderType_LoadingIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingIndicatorComponent, data: {} });
export { RenderType_LoadingIndicatorComponent as RenderType_LoadingIndicatorComponent };
export function View_LoadingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "loading-indicator"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "loading-indicator"; var currVal_1 = _ck(_v, 3, 0, !_co.isShow); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LoadingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-loading-indicator", [], null, null, null, View_LoadingIndicatorComponent_0, RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 245760, null, 0, i3.LoadingIndicatorComponent, [i4.ApplicationStateBusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingIndicatorComponentNgFactory = i1.ɵccf("slx-loading-indicator", i3.LoadingIndicatorComponent, View_LoadingIndicatorComponent_Host_0, {}, {}, []);
export { LoadingIndicatorComponentNgFactory as LoadingIndicatorComponentNgFactory };
