import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/index';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { BenefitDetailsEditModes, BenefitDetailsTier, BenefitDetailsOption } from '../../../models/index';
import { BenefitDetailsStandartManagementService, BenefitDetailsPermissionService } from '../../../services/index';
var BenefitDetailsCoverageCalc401KComponent = /** @class */ (function () {
    function BenefitDetailsCoverageCalc401KComponent(modalService, manService, permissionService) {
        this.modalService = modalService;
        this.manService = manService;
        this.permissionService = permissionService;
        this.records = [];
        this.pageSize = 50;
        this.selectedProvidedTier = null;
        this.min = 0;
        this.max = 100;
        this.step = 0.1;
        this.percentage = 0.7;
        this.format = 'p';
        this.currencyFormat = 'c2';
        this.minAmt = 1;
        this.maxAmt = 99999999.99;
        this.stepcurrency = 1;
        this.value = 0;
        this.isDelete = false;
        this.isEditMode = false;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.take = this.pageSize;
        this.appConfig = appConfig;
    }
    Object.defineProperty(BenefitDetailsCoverageCalc401KComponent.prototype, "isRowEditing", {
        get: function () {
            return _.isObjectLike(this.gridState.editedRecord);
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsCoverageCalc401KComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.editMode = this.permissionService
            .subscribeToEditMode(function (v) {
            _this.isEditMode = v.providerInfo;
            _this.permissionService.toggleCanAddCovOptions(_this.isEditMode);
            if (!_this.isEditMode) {
                _this.gridState.closeEditor(_this.kendoGrid);
            }
        });
        this.subscriptions.gridEditSubscription = this.gridState.onEdit$
            .subscribe(function (option) {
            _this.permissionService.toggleCanAddCovOptions(false);
        });
        this.subscriptions.gridCancelSubscription = this.gridState.onCancel$
            .subscribe(function (event) {
            _this.permissionService.toggleCanAddCovOptions(true);
            if (_.isObjectLike(event.dataItem) && event.dataItem.isDraft) {
                _this.clearRecords();
            }
        });
        this.subscriptions.gridSaveSubscription = this.gridState.onSave$
            .subscribe(function (event) {
            _this.permissionService.toggleCanAddCovOptions(true);
            if (event.dataItem.isDraft) {
                _this.addOption(event.dataItem);
                return;
            }
            _this.updateOption(event.dataItem);
        });
        this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$
            .subscribe(function (event) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            options.buttonOKtext = 'Yes';
            ConfirmDialog2Component.openDialog('Delete Coverage Option', 'Are you sure you want to remove selected coverage option? This will remove this plan for all enrolled employees', _this.modalService, function (isDelete) {
                if (isDelete) {
                    _this.removeOption(event.dataItem);
                    _this.permissionService.toggleCanAddCovOptions(true);
                }
            }, options);
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
        this.subscriptions.selectProviderTier = this.manService
            .subscribeToSelectProviderTier(function (v) {
            _this.selectedProvidedTier = v;
            _this.gridState.closeEditor(_this.kendoGrid);
            _this.filterOutRecords();
            _this.addDefaultOption();
            _this.refreshGrid();
            _this.permissionService.toggleCanAddCovOptions(true);
        });
    };
    BenefitDetailsCoverageCalc401KComponent.prototype.ngOnDestroy = function () { };
    BenefitDetailsCoverageCalc401KComponent.prototype.addDefaultOption = function () {
        if (_.size(this.records) === 0) {
            this.records.push(this.manService.createBenefitProviderOption());
        }
    };
    BenefitDetailsCoverageCalc401KComponent.prototype.addOption = function (option) {
        this.manService.saveCoverageOption(option, true);
        this.filterOutRecords();
        this.refreshGrid();
    };
    BenefitDetailsCoverageCalc401KComponent.prototype.updateOption = function (option) {
        this.manService.updateCoverageOption(option);
    };
    BenefitDetailsCoverageCalc401KComponent.prototype.removeOption = function (option) {
        this.manService.removeCoverageOption(option);
        this.filterOutRecords();
        this.addDefaultOption();
        this.refreshGrid();
    };
    BenefitDetailsCoverageCalc401KComponent.prototype.filterOutRecords = function () {
        this.records = _.filter(this.selectedProvidedTier.options, function (o) { return !o.stateIsDeleted; });
    };
    BenefitDetailsCoverageCalc401KComponent.prototype.clearRecords = function () {
        this.records.length = 0;
        this.addDefaultOption();
        this.refreshGrid();
    };
    BenefitDetailsCoverageCalc401KComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsCoverageCalc401KComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsCoverageCalc401KComponent;
}());
export { BenefitDetailsCoverageCalc401KComponent };
