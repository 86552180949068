import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { FileService } from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { DailyTimecardManagementService, editAction } from '../../services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { appConfig } from '../../../app.config';
import { TimeCardModel } from '../../models/index';
import { TimecardsNavigationService, ApprovalPayCodesNavigationService, ApprovalOvertimesNavigationService } from '../../../common/services/navigation/index';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { MessagesService } from '../../../components-library/services/index';
var DailyTimeCardComponent = /** @class */ (function () {
    function DailyTimeCardComponent(fileService, managementService, router, activatedRoute, stateManagementService, changeManagementService, messagesService) {
        this.fileService = fileService;
        this.managementService = managementService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.stateManagementService = stateManagementService;
        this.changeManagementService = changeManagementService;
        this.messagesService = messagesService;
        this.punchesHeight = '50%';
        this.paycodesHeight = '50%';
        this.summaryHeight = '25%';
        this.scheduleHeight = '25%';
        this.absencesHeight = '25%';
        this.commentsHeight = '25%';
        this.scheduleIsCollapsed = true;
        this.absencesIsCollapsed = true;
        this.commentsIsCollapsed = true;
        this.abscenceCount = 0;
        this.state = {
            isLoading: false,
            punchesLocked: false,
            scheduleLocked: false,
            paycodesLocked: false,
            absencesLocked: false,
        };
        this.subscribeRouter(this.activatedRoute);
    }
    DailyTimeCardComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        scheduleMicrotask(function () {
            _this.recalcLeftPanel();
            _this.recalcRightPanel();
        });
    };
    DailyTimeCardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editActionSubscripion = this.managementService.sectionEditAction$.subscribe(function (action) {
            if (action.source === 'punches') {
                _this.state.scheduleLocked = action.lockOthers;
                _this.state.paycodesLocked = action.lockOthers;
                _this.state.absencesLocked = action.lockOthers;
            }
            if (action.source === 'absences') {
                _this.state.punchesLocked = action.lockOthers;
                _this.state.scheduleLocked = action.lockOthers;
                _this.state.paycodesLocked = action.lockOthers;
            }
            if (action.source === 'paycodes') {
                _this.state.punchesLocked = action.lockOthers;
                _this.state.scheduleLocked = action.lockOthers;
                _this.state.absencesLocked = action.lockOthers;
            }
            if (action.source === 'schedule') {
                _this.state.punchesLocked = action.lockOthers;
                _this.state.paycodesLocked = action.lockOthers;
                _this.state.absencesLocked = action.lockOthers;
            }
        });
        this.stateSubscripion = this.managementService.onLoadStatus$.subscribe(function (isLoading) {
            _this.state.isLoading = isLoading;
        });
        this.loadSubscripion = this.managementService.onLoaded$.subscribe(function (timecard) {
            _this.messagesService.resetClosed();
            _this.timecard = timecard;
            _this.abscenceCount = _this.managementService.getAbsencescount(_this.timecard.absences);
            _this.updateAbscenseCount(_this.abscenceCount);
        });
        this.exportSubscription = this.managementService.exportedTimecard$
            .subscribe(function (file) { return _this.fileService.saveToFileSystem(file.blob, file.file); });
        this.canEditChangedSubscription = this.managementService.canEditChanged$
            .subscribe(function (canEdit) {
            _this.canEditTimecard = canEdit;
        });
        this.changeDateSubscripion = this.managementService.changedDate$.subscribe(function (date) {
            _this.setTimecardDate(date);
        });
        this.navigateToPCSubscripion = this.managementService.navigateToUnApprovedPC$.subscribe(function () {
            _this.navigateToUnApprovedPC();
        });
        this.abscenceCountSubscription = this.managementService.abscenceCount$.subscribe(function (v) {
            _this.updateAbscenseCount(v);
        });
        this.navigateToOTSubscripion = this.managementService.navigateToUnApprovedOT$.subscribe(function (homeOrgLevelId) {
            _this.navigateToUnApprovedOT(homeOrgLevelId);
        });
    };
    DailyTimeCardComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    DailyTimeCardComponent.prototype.onCommentChanged = function (value) {
        if (this.timecard) {
            this.timecard.comment = value;
        }
    };
    DailyTimeCardComponent.prototype.setTimecardDate = function (d) {
        var navigationservice = new TimecardsNavigationService(this.router, this.activatedRoute);
        navigationservice.NavigateToDailyTimecards(this.timecard.employee.id, d);
    };
    DailyTimeCardComponent.prototype.punchesCollapsedChange = function (isCollapsed) {
        this.punchesIsCollapsed = isCollapsed;
        this.recalcLeftPanel();
    };
    DailyTimeCardComponent.prototype.paycodesCollapsedChange = function (isCollapsed) {
        this.paycodesIsCollapsed = isCollapsed;
        this.recalcLeftPanel();
    };
    DailyTimeCardComponent.prototype.recalcLeftPanel = function () {
        var oneHeight = 'calc(100% - 70px)';
        var twoHeight = 'calc(50% - 15px)';
        if (!this.punchesIsCollapsed && this.paycodesIsCollapsed) {
            this.punchesHeight = oneHeight;
            this.paycodesHeight = twoHeight;
            return;
        }
        if (this.punchesIsCollapsed && !this.paycodesIsCollapsed) {
            this.punchesHeight = twoHeight;
            this.paycodesHeight = oneHeight;
            return;
        }
        this.paycodesHeight = twoHeight;
        this.punchesHeight = twoHeight;
    };
    DailyTimeCardComponent.prototype.summaryCollapsedChange = function (isCollapsed) {
        this.summaryIsCollapsed = isCollapsed;
        this.recalcRightPanel();
    };
    DailyTimeCardComponent.prototype.scheduleCollapsedChange = function (isCollapsed) {
        this.scheduleIsCollapsed = isCollapsed;
        this.recalcRightPanel();
    };
    DailyTimeCardComponent.prototype.absencesCollapsedChange = function (isCollapsed) {
        this.absencesIsCollapsed = isCollapsed;
        this.recalcRightPanel();
    };
    DailyTimeCardComponent.prototype.commentsCollapsedChange = function (isCollapsed) {
        this.commentsIsCollapsed = isCollapsed;
        this.recalcRightPanel();
    };
    DailyTimeCardComponent.prototype.recalcRightPanel = function () {
        var oneHeight = 'calc(100% - 180px)';
        var twoHeight = 'calc(50% - 70px)';
        var threeHeight = 'calc(33% - 31px)';
        var fourHeight = 'calc(25% - 15px)';
        if (this.summaryIsCollapsed && this.scheduleIsCollapsed && this.absencesIsCollapsed && !this.commentsIsCollapsed) {
            this.summaryHeight = fourHeight;
            this.scheduleHeight = fourHeight;
            this.absencesHeight = fourHeight;
            this.commentsHeight = oneHeight;
            return;
        }
        if (this.summaryIsCollapsed && this.scheduleIsCollapsed && !this.absencesIsCollapsed && this.commentsIsCollapsed) {
            this.summaryHeight = fourHeight;
            this.scheduleHeight = fourHeight;
            this.absencesHeight = oneHeight;
            this.commentsHeight = fourHeight;
            return;
        }
        if (this.summaryIsCollapsed && this.scheduleIsCollapsed && !this.absencesIsCollapsed && !this.commentsIsCollapsed) {
            this.summaryHeight = fourHeight;
            this.scheduleHeight = fourHeight;
            this.absencesHeight = twoHeight;
            this.commentsHeight = twoHeight;
            return;
        }
        if (this.summaryIsCollapsed && !this.scheduleIsCollapsed && this.absencesIsCollapsed && this.commentsIsCollapsed) {
            this.summaryHeight = fourHeight;
            this.scheduleHeight = oneHeight;
            this.absencesHeight = fourHeight;
            this.commentsHeight = fourHeight;
            return;
        }
        if (this.summaryIsCollapsed && !this.scheduleIsCollapsed && this.absencesIsCollapsed && !this.commentsIsCollapsed) {
            this.summaryHeight = fourHeight;
            this.scheduleHeight = twoHeight;
            this.absencesHeight = fourHeight;
            this.commentsHeight = twoHeight;
            return;
        }
        if (this.summaryIsCollapsed && !this.scheduleIsCollapsed && !this.absencesIsCollapsed && this.commentsIsCollapsed) {
            this.summaryHeight = fourHeight;
            this.scheduleHeight = twoHeight;
            this.absencesHeight = twoHeight;
            this.commentsHeight = fourHeight;
            return;
        }
        if (this.summaryIsCollapsed && !this.scheduleIsCollapsed && !this.absencesIsCollapsed && !this.commentsIsCollapsed) {
            this.summaryHeight = fourHeight;
            this.scheduleHeight = threeHeight;
            this.absencesHeight = threeHeight;
            this.commentsHeight = threeHeight;
            return;
        }
        if (!this.summaryIsCollapsed && this.scheduleIsCollapsed && this.absencesIsCollapsed && this.commentsIsCollapsed) {
            this.summaryHeight = oneHeight;
            this.scheduleHeight = fourHeight;
            this.absencesHeight = fourHeight;
            this.commentsHeight = fourHeight;
            return;
        }
        if (!this.summaryIsCollapsed && this.scheduleIsCollapsed && this.absencesIsCollapsed && !this.commentsIsCollapsed) {
            this.summaryHeight = twoHeight;
            this.scheduleHeight = fourHeight;
            this.absencesHeight = fourHeight;
            this.commentsHeight = twoHeight;
            return;
        }
        if (!this.summaryIsCollapsed && this.scheduleIsCollapsed && !this.absencesIsCollapsed && this.commentsIsCollapsed) {
            this.summaryHeight = twoHeight;
            this.scheduleHeight = fourHeight;
            this.absencesHeight = twoHeight;
            this.commentsHeight = fourHeight;
            return;
        }
        if (!this.summaryIsCollapsed && this.scheduleIsCollapsed && !this.absencesIsCollapsed && !this.commentsIsCollapsed) {
            this.summaryHeight = threeHeight;
            this.scheduleHeight = fourHeight;
            this.absencesHeight = threeHeight;
            this.commentsHeight = threeHeight;
            return;
        }
        if (!this.summaryIsCollapsed && !this.scheduleIsCollapsed && this.absencesIsCollapsed && this.commentsIsCollapsed) {
            this.summaryHeight = twoHeight;
            this.scheduleHeight = twoHeight;
            this.absencesHeight = fourHeight;
            this.commentsHeight = fourHeight;
            return;
        }
        if (!this.summaryIsCollapsed && !this.scheduleIsCollapsed && this.absencesIsCollapsed && !this.commentsIsCollapsed) {
            this.summaryHeight = threeHeight;
            this.scheduleHeight = threeHeight;
            this.absencesHeight = fourHeight;
            this.commentsHeight = threeHeight;
            return;
        }
        if (!this.summaryIsCollapsed && !this.scheduleIsCollapsed && !this.absencesIsCollapsed && this.commentsIsCollapsed) {
            this.summaryHeight = threeHeight;
            this.scheduleHeight = threeHeight;
            this.absencesHeight = threeHeight;
            this.commentsHeight = fourHeight;
            return;
        }
        this.summaryHeight = fourHeight;
        this.scheduleHeight = fourHeight;
        this.absencesHeight = fourHeight;
        this.commentsHeight = fourHeight;
    };
    DailyTimeCardComponent.prototype.subscribeRouter = function (activatedRoute) {
        var _this = this;
        this.routeSubscripion = this.activatedRoute.params.combineLatest(this.activatedRoute.queryParams).subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            var employeeId = params['employeeId'];
            var dateOn = params['date'];
            var date = moment(dateOn, appConfig.requestDate).toDate();
            _this.managementService.setRouteData(+employeeId, date);
        });
    };
    DailyTimeCardComponent.prototype.navigateToUnApprovedPC = function () {
        var navigationservice = new ApprovalPayCodesNavigationService(this.router, this.activatedRoute);
        navigationservice.navigateToPayCodesApproval(this.timecard.date, this.timecard.date);
    };
    DailyTimeCardComponent.prototype.navigateToUnApprovedOT = function (homeOrgLevelId) {
        var navigationservice = new ApprovalOvertimesNavigationService(this.router, this.activatedRoute);
        navigationservice.navigateToOvertimeApproval(this.timecard.date, this.timecard.date, undefined, homeOrgLevelId);
    };
    DailyTimeCardComponent.prototype.updateAbscenseCount = function (v) {
        this.abscenceCount = v;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "stateSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "editActionSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "loadSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "exportSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "canEditChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "changeDateSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "navigateToPCSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "navigateToOTSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardComponent.prototype, "abscenceCountSubscription", void 0);
    return DailyTimeCardComponent;
}());
export { DailyTimeCardComponent };
