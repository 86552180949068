import * as _ from 'lodash';
import * as moment from 'moment';

import {
  EmployeeDefinition, IEmployeeDefinition,
  Organization, IOrganization,
  Department, IDepartment,
  Position, IPosition, IScheduleAbsence, ScheduleAbsence,
  IShift, Shift
} from '../../../organization/models/index';
import { Actions } from '../../../core/models/index';
import { EmployeeAccrual, IEmployeeAccrual } from '../../../employee/employee-sections/models/index';
import { LocationUnit, ILocationUnit } from '../../../organization/models/index';

export interface ILeaveRequestEntries {
  requests: ILeaveRequestEntry[];
}

export class LeaveRequestEntries {
  public requests: LeaveRequestEntry[];
  public actions: Actions;
}

export interface ILeaveRequestEntry {
  id: number;
  employee: IEmployeeDefinition;
  organization: IOrganization;
  department: IDepartment;
  position: IPosition;
  submittedOn: Date;
  submittedTo: string;
  start: Date;
  end: Date;
  overlapsApproved: number;
  overlapsPending: number;
  reason: string;
  status: string;
  detailsLength: number;
  accruals: IEmployeeAccrual[];
}

export class LeaveRequestEntry {
  public id: number;
  public employee: EmployeeDefinition;
  public organization: Organization;
  public department: Department;
  public position: Position;
  public submittedOn: Date;
  public submittedTo: string;
  public start: Date;
  public end: Date;
  public overlapsApproved: number;
  public overlapsPending: number;
  public reason: string;
  public status: string;
  public comment: string;
  public actionsMenuOpened: boolean;
  public buttonText: string;
  public header: string;
  public detailsLength: number;
  public accruals: EmployeeAccrual[];
}

export interface ILeaveRequestDetailEntry {
  date: string;
  shift: IShift;
  unit: ILocationUnit;
  absenceReason: IScheduleAbsence;
  accrualBalance: number;
  approvedAbsences: number;
  submittedAbsences: number;
  replacement: string;
  isAccruaBalanceAvailable: boolean;
}

export interface ILeaveRequestDetailEntries {
  details: ILeaveRequestDetailEntry[];
  canReplaceEmployee: boolean;
}

export class LeaveRequestDetailEntries {
  public details: LeaveRequestDetailEntry[];
  public canReplaceEmployee: boolean;
}

export class LeaveRequestDetailEntry {
  public date: Date;
  public unit: LocationUnit;
  public shift: Shift;
  public shiftDuration: moment.Duration;
  public absenceReason: ScheduleAbsence;
  public accrualBalance: number;
  public approvedAbsences: number;
  public submittedAbsences: number;
  public replacement: string;
  public isAccruaBalanceAvailable: boolean;
  public replacedEmployee: EmployeeDefinition;
  public hasEdited: boolean;
  public localId: number;

  public get repStatus(): string {
    return _.isString(this.replacement) ? this.replacement : '';
  }

  public get hasReplacedEmployee(): boolean {
    return _.isObject(this.replacedEmployee);
  }
}

export type LeaveRequestFilter = {
  id: number;
  name: string;
};
