import * as tslib_1 from "tslib";
import { HandledError } from './handled-error';
var ValidationError = /** @class */ (function (_super) {
    tslib_1.__extends(ValidationError, _super);
    function ValidationError(error) {
        var _this = _super.call(this, error) || this;
        _this.name = 'ValidationError';
        return _this;
    }
    return ValidationError;
}(HandledError));
export { ValidationError };
