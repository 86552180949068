import { LookupService } from './../../../organization/services/lookup/lookup.service';
import { HttpRequest } from '@angular/common/http';
import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { schedulerConfig } from '../../scheduler.config';
import { CacheType } from '../../../common/models/cache/cache-definition';
import { AgencyStaffingConsoleMapService } from './agency-staffing-console-map.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../common/services/api/api-util.service";
import * as i2 from "../../../common/services/url-params/url-params.service";
import * as i3 from "./agency-staffing-console-map.service";
import * as i4 from "../../../organization/services/lookup/lookup.service";
import * as i5 from "../../../common/services/cache/cache.service";
var AgencyStaffingConsoleApiService = /** @class */ (function () {
    function AgencyStaffingConsoleApiService(apiUtilService, urlParamService, agencyMapService, lookupService, cacheUtilService) {
        this.apiUtilService = apiUtilService;
        this.urlParamService = urlParamService;
        this.agencyMapService = agencyMapService;
        this.lookupService = lookupService;
        this.cacheUtilService = cacheUtilService;
    }
    AgencyStaffingConsoleApiService.prototype.getShiftDetails = function (startDate, endDate) {
        var _this = this;
        Assert.isNotNull(startDate);
        Assert.isNotNull(endDate);
        var url = this.apiUtilService.getApiRoot() + "/" + schedulerConfig.api.schedule.orglevel.agencyConsole.root + "/" + schedulerConfig.api.schedule.orglevel.agencyConsole.startDate + "/" + startDate + "/" + schedulerConfig.api.schedule.orglevel.agencyConsole.endDate + "/" + endDate;
        var request = new HttpRequest('GET', url);
        // clear cache
        this.cacheUtilService.delete({ key: url }, '');
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.agencyMapService.mapShiftDetails(response.data); });
        return promise;
    };
    AgencyStaffingConsoleApiService.prototype.getShiftSummary = function (startDate, endDate, custamerId) {
        var _this = this;
        Assert.isNotNull(startDate);
        Assert.isNotNull(endDate);
        Assert.isNotNull(custamerId);
        var url = this.apiUtilService.getApiRoot() + "/" + schedulerConfig.api.schedule.orglevel.agencyConsole.root + "/" + schedulerConfig.api.schedule.orglevel.agencyConsole.shortInfo + "/" + schedulerConfig.api.schedule.orglevel.agencyConsole.startDate + "/" + startDate + "/" + schedulerConfig.api.schedule.orglevel.agencyConsole.endDate + "/" + endDate;
        var request = new HttpRequest('GET', url);
        // clear cache
        this.cacheUtilService.delete({ key: url }, '');
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.agencyMapService.mapShiftShortDetails(response.data); });
        return promise;
    };
    AgencyStaffingConsoleApiService.prototype.exportToPdf = function (data) {
        return;
    };
    AgencyStaffingConsoleApiService.prototype.exportToExcel = function (data) {
        return;
    };
    AgencyStaffingConsoleApiService.prototype.exportToPdfAgencyDetails = function (data) {
        return;
    };
    AgencyStaffingConsoleApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AgencyStaffingConsoleApiService_Factory() { return new AgencyStaffingConsoleApiService(i0.ɵɵinject(i1.ApiUtilService), i0.ɵɵinject(i2.UrlParamsService), i0.ɵɵinject(i3.AgencyStaffingConsoleMapService), i0.ɵɵinject(i4.LookupService), i0.ɵɵinject(i5.CacheUtilService)); }, token: AgencyStaffingConsoleApiService, providedIn: "root" });
    return AgencyStaffingConsoleApiService;
}());
export { AgencyStaffingConsoleApiService };
