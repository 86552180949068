import { forwardRef, EventEmitter, QueryList, ElementRef, Renderer, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { v4, v6, inputSelection } from './utils';
// if supported set it, else try once
var COPY_FEAT_SUPPORTED = document.queryCommandSupported('copy') ? true : undefined;
function isV6(mode) {
    return mode === 'ipv6';
}
export var ANGULAR2_IP_CONTROL_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return Ng2IpComponent; }),
    multi: true
};
function cancelEvent($event) {
    $event.preventDefault();
    $event.stopPropagation();
}
var Ng2IpComponent = /** @class */ (function () {
    function Ng2IpComponent(_renderer, _cdr) {
        this._renderer = _renderer;
        this._cdr = _cdr;
        this.blocks = v4.blocks();
        this.blocksRef = this.blocks.map(function (v, i) { return i; });
        this.invalidBlocks = [];
        this.containerClass = [];
        this.vX = v4;
        /**
         * The validation level performed on an input.
         * This is a validation performed based on a keystroke. Does not apply to paste.
         * none - No validation
         * char - Only valid char's are allowed (however, invalid value can be set. e.g: 333)
         * block - Only valid char's that compose a valid block are allowed
         *
         * Default: 'block'
         * @type {string}
         */
        this.inputValidation = 'block';
        /**
         * A bit map representing disabled blocks.
         * e.g: [1, 1, 0, 0] will set disabled the first 2 blocks (from the left).
         * Since the component is set to OnPush this is an immutable array, to change the state
         * replace the array (don't change it's items).
         * @type {Array}
         */
        this.disabledBlocks = [];
        this.change = new EventEmitter();
        this._mode = 'ipv4';
        this._value = null;
        this._onTouchedCallback = _.noop;
        this._onChangeCallback = _.noop;
        this._theme = '';
        this._highlightInvalidBlocks = true;
    }
    Object.defineProperty(Ng2IpComponent.prototype, "focused", {
        get: function () {
            return this.containerClass.indexOf('ng2-ip-focused') > -1;
        },
        set: function (value) {
            var idx = this.containerClass.indexOf('ng2-ip-focused');
            if (value && idx === -1) {
                this.containerClass.push('ng2-ip-focused');
            }
            else if (!value && idx > -1) {
                this.containerClass.splice(idx, 1);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ng2IpComponent.prototype, "mode", {
        get: function () {
            return this._mode;
        },
        /**
         * IP format.
         * Valid values: "ipv4" or "ipv6"
         * @param mode
         */
        set: function (mode) {
            if (this._mode === mode)
                return;
            this.vX = isV6(mode) ? v6 : v4;
            this._mode = mode;
            this.blocks = this.vX.blocks();
            this.blocksRef = this.blocks.map(function (v, i) { return i; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ng2IpComponent.prototype, "value", {
        get: function () { return this._value; },
        set: function (v) {
            if (v !== this._value) {
                this._value = v;
                this.blocks = this.toBlocks(v);
                this._onChangeCallback(v);
                if (!v) {
                    for (var i = 0; i < this.blocks.length; i++) {
                        this.invalidBlocks[i] = undefined;
                    }
                }
                else {
                    for (var i = 0; i < this.blocks.length; i++) {
                        this.markBlockValidity(this.blocks[i], i);
                    }
                }
                this._cdr.markForCheck();
                this._cdr.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ng2IpComponent.prototype, "highlightInvalidBlocks", {
        get: function () {
            return this._highlightInvalidBlocks;
        },
        /**
         * When true add's the 'ng2-ip-error' class to the block when it's invalid.
         * @param value
         */
        set: function (value) {
            if (this._highlightInvalidBlocks === value)
                return;
            this._highlightInvalidBlocks = value;
            for (var i = 0; i < this.blocks.length; i++) {
                this.markBlockValidity(this.blocks[i], i);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ng2IpComponent.prototype, "theme", {
        get: function () {
            return this._theme;
        },
        /**
         * The CSS class representing the theme of this instance.
         * @param value
         */
        set: function (value) {
            if (this._theme === value)
                return;
            var idx = this.containerClass.indexOf(this._theme);
            if (idx > -1)
                this.containerClass.splice(idx, 1);
            this._theme = value;
            if (value) {
                this.containerClass.push(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Ng2IpComponent.prototype, "copyMode", {
        get: function () {
            switch (this.autoCopy) {
                case 'DEFAULT_ADDRESS':
                    return 'address';
                case 'DEFAULT_BLOCK':
                    return 'block';
                default:
                    return 'select';
            }
        },
        set: function (value) {
            // if copy is not supported in this browser don't allow select mode.
            if (COPY_FEAT_SUPPORTED === false && value === 'select') {
                value = undefined;
            }
            switch (value) {
                case 'select':
                    this.autoCopy = undefined;
                    break;
                case 'address':
                    this.autoCopy = 'DEFAULT_ADDRESS';
                    break;
                default:
                    this.autoCopy = 'DEFAULT_BLOCK';
                    break;
            }
        },
        enumerable: true,
        configurable: true
    });
    Ng2IpComponent.prototype.writeValue = function (value) {
        this.value = value;
    };
    Ng2IpComponent.prototype.registerOnChange = function (fn) {
        this._onChangeCallback = fn;
    };
    Ng2IpComponent.prototype.registerOnTouched = function (fn) {
        this._onTouchedCallback = fn;
    };
    Ng2IpComponent.prototype.onPaste = function ($event, idx) {
        try {
            var clipData = $event.clipboardData.getData('text');
            if (typeof clipData === 'string') {
                var arr = clipData.split(this.vX.SEP);
                if (arr.length === this.vX.BLOCK_COUNT) {
                    this.value = this.fromBlocks(arr);
                }
                else {
                    var value = inputSelection.insert($event.target, arr[0]);
                    this.onChange(value, idx);
                }
                cancelEvent($event);
            }
        }
        catch (e) {
            //nothing todo
        }
    };
    Ng2IpComponent.prototype.onCopy = function ($event, idx) {
        try {
            this.showCopySelection = false;
            this.inputAnim = undefined;
            switch (this.autoCopy) {
                case 'TEST_MODE':
                    COPY_FEAT_SUPPORTED = true;
                    break;
                case 'block':
                    this.autoCopy = undefined;
                    break;
                case 'DEFAULT_BLOCK':
                    return;
                case 'address':
                    this.autoCopy = undefined;
                    break;
                case 'DEFAULT_ADDRESS':
                    var value = this.fromBlocks(this.blocks);
                    $event.clipboardData.setData('text', value);
                    cancelEvent($event);
                    break;
                default:
                    if (inputSelection.all($event.target)) {
                        // fail safe protection when copy paste is not supported.
                        if (typeof COPY_FEAT_SUPPORTED === 'undefined') {
                            // make sure recursion will not overflow
                            this.autoCopy = 'TEST_MODE';
                            // set to false, if supported it will change to true
                            COPY_FEAT_SUPPORTED = false;
                            document.execCommand('copy');
                            // if not supported return without cancelling, resulting in the original copy command
                            // passed through as usual, setting COPY_FEAT_SUPPORTED to false also means
                            // it will not allow changing the copyMode. We also set the copy mode to the default.
                            if (COPY_FEAT_SUPPORTED === false) {
                                this.autoCopy = 'DEFAULT_BLOCK';
                                return;
                            }
                        }
                        this.autoCopy = idx.toString();
                        this.showCopySelection = true;
                        this.inputAnim = 'hide';
                        cancelEvent($event);
                    }
                    break;
            }
        }
        catch (e) {
            //todo
        }
    };
    Ng2IpComponent.prototype.onCopyDecision = function (decision) {
        switch (decision) {
            case 'block':
            case 'address':
                try {
                    var idx = parseInt(this.autoCopy);
                    this.autoCopy = decision;
                    var input = this.inputs.toArray()[idx].nativeElement;
                    // we can't use the renderer here since it's async thus will run on the next turn.
                    // it will force us to run the copy command in a timeout (after selection was made).
                    // this will break clipboard policy on some browsers.
                    if (input && input.select) {
                        input.select();
                    }
                    document.execCommand('copy');
                }
                catch (e) {
                    this.autoCopy = 'DEFAULT_BLOCK';
                }
                break;
            default:
                this.autoCopy = undefined;
        }
    };
    Ng2IpComponent.prototype.onChange = function (value, idx) {
        if (this.blocks[idx] === value)
            return;
        this.blocks[idx] = value;
        this.notifyChange(this.fromBlocks(this.blocks));
        this.markBlockValidity(value, idx);
    };
    Ng2IpComponent.prototype.onKeyPress = function ($event, idx) {
        // safari/ff will cancel copy/paste , chrome wont... so don't mess with it.
        if ($event.metaKey || $event.ctrlKey || $event.altKey)
            return;
        // browser support (e.g: safari)
        var key = typeof $event.key === 'string' ? $event.key : String.fromCharCode($event.charCode);
        if (key === this.vX.SEP) {
            cancelEvent($event);
            this.focusNext(idx);
        }
        var value = inputSelection.insert($event.target, key);
        if (this.inputValidation === 'char' && !this.vX.RE_CHAR.test(key)) {
            return cancelEvent($event);
        }
        else if (this.inputValidation === 'block' && !this.vX.RE_BLOCK.test(value)) {
            return cancelEvent($event);
        }
        this.markBlockValidity(value, idx);
    };
    Ng2IpComponent.prototype.onKeyUp = function ($event, idx) {
        if (typeof $event.keyCode === 'number') {
            if ($event.keyCode !== 8)
                return;
        }
        else if ($event.key !== 'Backspace')
            return;
        var input = $event.target;
        var value = input && input.selectionStart >= 0 && input.selectionEnd > input.selectionStart
            ? input.value.substr(0, input.selectionStart) + input.value.substr(input.selectionEnd)
            : input.value.substr(0, input.value.length - 1);
        this.markBlockValidity(value, idx);
    };
    Ng2IpComponent.prototype.onBlur = function (idx) {
        this.focused = false;
    };
    Ng2IpComponent.prototype.onFocus = function (idx) {
        this.focused = true;
    };
    Ng2IpComponent.prototype.focusNext = function (idx, selectRange) {
        if (selectRange === void 0) { selectRange = true; }
        var next = this.inputs.toArray()[idx + 1];
        if (next) {
            this._renderer.invokeElementMethod(next.nativeElement, 'focus');
            if (selectRange && this.blocks[idx + 1]) {
                this._renderer.invokeElementMethod(next.nativeElement, 'setSelectionRange', [0, this.blocks[idx + 1].toString().length]);
            }
        }
    };
    Ng2IpComponent.prototype.markBlockValidity = function (value, idx) {
        this.invalidBlocks[idx] = !this.highlightInvalidBlocks || this.vX.RE_BLOCK.test(value) ? undefined : 'ng2-ip-error';
    };
    Ng2IpComponent.prototype.notifyChange = function (value) {
        this._onChangeCallback(value);
        this.change.emit(value);
    };
    Ng2IpComponent.prototype.toBlocks = function (value) {
        return this.vX.split(value);
    };
    Ng2IpComponent.prototype.fromBlocks = function (blocks) {
        return this.vX.fromBlocks(blocks);
    };
    return Ng2IpComponent;
}());
export { Ng2IpComponent };
