<div class="modal-content photo-editor">
  <div #croppieContainer class="modal-body">
    <slx-photo-cropper *ngIf="canCropping && !canMakePhoto" [cropperOptions]="cropperOptions" [imageUrl]="employee.photo.src"></slx-photo-cropper>
    <slx-photo-maker *ngIf="canMakePhoto" (onPhoto)="onMadePhoto($event)" (onError)="onErrorPhoto($event)"></slx-photo-maker>
    <div class="photo-editor-placeholder" *ngIf="!canCropping && !canMakePhoto">
      <img class="photo-editor-placeholder-img" [src]="employee.photo.src" alt="Employee Photo" />
    </div>
     <div class="slx-error-block photo-editor-errors" *ngIf="!canMakePhoto && (errors.uploadFormat || errors.imageFormat || errors.photoError)">
      <span *ngIf="errors.uploadFormat" errorMessage for="uploadFormat"></span>
      <span *ngIf="errors.imageFormat" errorMessage for="imageFormat"></span>
      <span *ngIf="errors.photoError" errorMessage>{{ errorMessage }}</span>
    </div>
  </div>
  <div class="modal-footer">
    <div class="col-xs-6 left-align">
      <div [ngClass]="{ 'pseudo-button-disabled': canMakePhoto }"  class="theme-button-cancel photo-editor-upload pseudo-button min-button">
        Select file
        <input class="photo-editor-upload-file"
          type="file"
          [ngClass]="{ 'hidden': canMakePhoto }"
          [accept]="fileTypesFieldFormat"
          (change)="onFileChange($event)"
          (click)="onFileClick($event)"
        />
      </div>
      <button type="button" *ngIf="!canMakePhoto" (click)="onMakePhoto()" class="theme-button-cancel min-button">Take a photo</button>
    </div>
    <div class="col-xs-6">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
      <button type="button" (click)="onApply()" [disabled]="!canCropping" class="theme-button-apply">Apply</button>
    </div>
  </div>
</div>
