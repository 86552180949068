import * as _ from 'lodash';
import { DisplayDefinition } from './display-type';
import { ComparisonItem } from './comparison-item';


export class ScheduleConsoleEntry {
  public name: string;
  public values: any[];
  public hidden: boolean;
  public expanded: boolean;

  constructor(name: string) {
    this.name = name;
    this.values = [];
    this.hidden = false;
    this.expanded = false;
  }
}

export class ScheduleConsoleCycles {
  public groupByUnit: ScheduleConsoleEntry[];
  public groupByShift: ScheduleConsoleEntry[];
  public groupByPosition: ScheduleConsoleEntry[];
  public filterType: string;
  public lowerLimit: number;
  public upperLimit: number;
  public filters: ScheduleConsoleFilterItem[];
}

export class ScheduleConsoleFilterItem {
  public id: number;
  public name: string;
  public type: string;
  public isDirectCare: boolean;
}

export class ScheduleConsoleFilterChangedEvent {
  public filters: ScheduleConsoleFilterItem[];
  public isDirectCare: boolean;
}

export class ConsoleConfig {
  displayType: DisplayDefinition;
  lowerLimit: number;
  upperLimit: number;
  constructor(displayType: DisplayDefinition, lowerLimit: number, upperLimit: number) {
    this.displayType = displayType;
    this.lowerLimit = lowerLimit;
    this.upperLimit = upperLimit;
  }
}

export enum ScheduleConsoleGroupByType {
  NONE = 'No Grouping',
  UNIT = 'Group by Unit',
  SHIFT = 'Group by Shift Group'
}

export class ScheduleConsoleGroupBy {
  public id: string;
  public name: ScheduleConsoleGroupByType;
  constructor(id: string, name: ScheduleConsoleGroupByType) {
    this.id = id;
    this.name = name;
  }

  public get longTypeName(): string {
    return `groupBy${_.capitalize(this.id)}`;
  }

  public get shortTypeName(): string {
    return _.lowerCase(this.id);
  }
}

export class ScheduleConsoleViewItem {
  public actualText: string;
  public limitText: string;
  public viewMode: string;
  public scheduled: ComparisonItem;
  constructor(actualText: string, limitText: string, viewMode: string, scheduled: ComparisonItem) {
    this.actualText = actualText;
    this.limitText = limitText;
    this.viewMode = viewMode;
    this.scheduled = scheduled;
  }
}

export class ScheduleConsoleDayIndicator {
  public color: string;
  public date: Date;
  constructor(color: string, date: Date) {
    this.color = color;
    this.date = date;
  }
}
