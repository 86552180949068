<form class="dialog-form" #dialogForm="ngForm">
  <div class="modal-content">
    <div class="modal-body">
      <div class="col-xs-12">
        <div class="col-sm-6">
          <div class="row">
            <slx-input-decorator>
              <input slx-input type="text" maxlength="50" [required]="true"
                  name="subject" placeholder="New Note" [(ngModel)]="subject">
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-form-group slx-form-expand">
          <textarea slx-input type="text" maxlength="2500" rows="5" name="detail" placeholder="Description" [(ngModel)]="details"
          ></textarea>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-3">
            <slx-input-decorator>
              <slx-checkbox-input slx-input name="isPublic" fieldName="isPublicField" caption="Public" placeholder="Public" [(ngModel)]="isPublic">
              </slx-checkbox-input>
            </slx-input-decorator>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
      <button type="button" (click)="onOk()" [disabled]="!dialogForm.valid" class="theme-button-apply">OK</button>
    </div>
  </div>
</form>
