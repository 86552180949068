import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import * as _ from 'lodash';
import {
  BenefitEligibilityRuleVariable,
  BenefitEligibilityRuleConditionType,
  benefitEligibilityRuleConditions,
  benefitEligibilityRuleBooleanTypes,
  benefitEligibilityRulePayTypes,
  BenefitEligibilityRulePayType,
  BenefitEligibilityRuleVariableEnum,
  BenefitEligibilityRulePredicateEnum,
  BenefitEligibilityRuleDataType,
} from '../../../models/benefit-eligibility-rules/benefit-eligibility-rule-model';
import {
  BenefitEligibilityRuleConfigurationVariable,
  BenefitClass,
  BenefitEligibilityRuleConfigurationPredicate,
} from '../../../models';
import * as uuid from 'uuid';
import { BenefitEligibilityRuleStatementsManagementService } from '../../../services/benefit-eligibility-rules/index';
import { BenefitClassDefinition } from '../../../../../organization/models/index';

@Component({
  selector: 'slx-benefit-details-eligibility-statements-clause',
  templateUrl: './benefit-details-eligibility-statements-clause.component.html',
  styleUrls: ['./benefit-details-eligibility-statements-clause.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class BenefitDetailsEligibilityStatementsClauseComponent implements OnChanges, AfterViewInit {
  @Input()
  ruleStatementSequence: number;

  @Input()
  ruleVariable: BenefitEligibilityRuleVariable;

  @Input()
  conditionType: BenefitEligibilityRuleConditionType;

  @Input()
  configurationVariables: BenefitEligibilityRuleConfigurationVariable[];

  @Input()
  configurationPredicates: BenefitEligibilityRuleConfigurationPredicate[];

  @Input()
  benefitClasses: BenefitClass[];

  @Input()
  isFirstItem: boolean;

  @Output()
  ruleVariableChanged: EventEmitter<BenefitEligibilityRuleVariable>;

  @Output()
  conditionTypeChanged: EventEmitter<BenefitEligibilityRuleConditionType>;

  startDate: Date;
  endDate: Date;

  uniqueComponentId: string;
  variable: BenefitEligibilityRuleConfigurationVariable;
  value: string | string[];
  dataType: BenefitEligibilityRuleDataType;
  predicate: BenefitEligibilityRuleConfigurationPredicate;
  isInNotInPredicate: boolean;
  isMinMaxPredicate: boolean;

  condition: BenefitEligibilityRuleConditionType;
  currentPredicates: BenefitEligibilityRuleConfigurationPredicate[];
  benefitClassDefinitions: BenefitClassDefinition[];
  selectedBenefitDefinition: BenefitClassDefinition;
  selectedBenefitRulePayType: BenefitEligibilityRulePayType;

  state: {
    isControlCreating: boolean;
  };

  get benefitEligibilityRuleConditions() {
    return benefitEligibilityRuleConditions;
  }

  get benefitEligibilityRuleBooleanTypes() {
    return benefitEligibilityRuleBooleanTypes;
  }

  get benefitEligibilityRulePayTypes() {
    return benefitEligibilityRulePayTypes;
  }

  get BenefitEligibilityRuleDataType() {
    return BenefitEligibilityRuleDataType;
  }

  get BenefitEligibilityRuleVariableEnum() {
    return BenefitEligibilityRuleVariableEnum;
  }

  constructor(private ruleStatementsManagementService: BenefitEligibilityRuleStatementsManagementService) {
    this.uniqueComponentId = uuid.v4();
    this.variable = null;
    this.value = '';
    this.dataType = null;
    this.condition = this.benefitEligibilityRuleConditions[0];
    this.predicate = null;
    this.currentPredicates = [];
    this.benefitClassDefinitions = [];
    this.isInNotInPredicate = false;
    this.isMinMaxPredicate = false;

    this.state = {
      isControlCreating: true,
    };

    this.ruleVariableChanged = new EventEmitter();
    this.conditionTypeChanged = new EventEmitter();
  }

  ngAfterViewInit(): void {
    // avoid - Expression has changed after it was checked
    setTimeout(() => {
      this.state.isControlCreating = false;
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<BenefitDetailsEligibilityStatementsClauseComponent, SimpleChange>;

    const conditionTypeChange = componentChanges.conditionType;
    if (conditionTypeChange) {
      const conditionType = conditionTypeChange.currentValue as BenefitEligibilityRuleConditionType;
      const previousConditionType = conditionTypeChange.previousValue as BenefitEligibilityRuleConditionType;

      if (!_.isEqual(conditionType, previousConditionType)) {
        this.condition = conditionType;
      }
    }

    const configurationVariablesChange = componentChanges.configurationVariables;
    if (configurationVariablesChange) {
      const configurationVariables = configurationVariablesChange.currentValue as BenefitEligibilityRuleConfigurationVariable[];
      const previousConfigurationVariables = configurationVariablesChange.previousValue as BenefitEligibilityRuleConfigurationVariable[];

      if (!_.isEqual(configurationVariables, previousConfigurationVariables)) {
        const variableId = this.ruleVariable ? this.ruleVariable.variableId : null;
        this.variable = this.ruleStatementsManagementService.findConfigurationVariable(
          variableId,
          configurationVariables
        );
        this.setCurrentPredicates();
      }
    }

    const ruleVariableChange = componentChanges.ruleVariable;
    if (ruleVariableChange) {
      const currentVariable = ruleVariableChange.currentValue as BenefitEligibilityRuleVariable;
      const previousVariable = ruleVariableChange.previousValue as BenefitEligibilityRuleVariable;

      if (!_.isEqual(currentVariable, previousVariable)) {
        this.setRuleVariable(currentVariable);
      }
    }

    const configurationPredicatesChange = componentChanges.configurationPredicates;
    if (configurationPredicatesChange) {
      const configurationPredicates = configurationPredicatesChange.currentValue as BenefitEligibilityRuleConfigurationPredicate[];
      const previousConfigurationPredicates = configurationPredicatesChange.previousValue as BenefitEligibilityRuleConfigurationPredicate[];

      if (!_.isEqual(configurationPredicates, previousConfigurationPredicates)) {
        this.configurationPredicates = configurationPredicates;
      }
    }

    const benefitClassesChange = componentChanges.benefitClasses;
    if (benefitClassesChange) {
      const currentBenefitClassesChange = benefitClassesChange.currentValue as BenefitClass[];
      const previousBenefitClassesChange = benefitClassesChange.previousValue as BenefitClass[];

      if (!_.isEqual(currentBenefitClassesChange, previousBenefitClassesChange)) {
        this.benefitClassDefinitions = _.map(currentBenefitClassesChange, (bc) => bc.benefitClassDefinition);
        if (this.ruleVariable) {
          this.setRuleVariable(this.ruleVariable);
        }
      }
    }
  }

  setCurrentPredicates() {
    if (this.variable) {
      this.currentPredicates = this.variable.predicates;
    }
  }

  onRuleConfigurationVariableChanged(configurationVariable: BenefitEligibilityRuleConfigurationVariable) {
    this.currentPredicates = configurationVariable ? configurationVariable.predicates : [];
    this.value = '';
    const dataType = configurationVariable ? configurationVariable.dataType : null;
    const variableId = configurationVariable ? configurationVariable.id : null;
    const valueType = configurationVariable ? configurationVariable.variableType : null;
    const predicateEnumValue = this.predicate ? this.predicate.predicateEnum : null;
    const newRule = new BenefitEligibilityRuleVariable({
      variableId: variableId,
      operatorType: predicateEnumValue,
      valueType: valueType,
      value: this.value,
      dataType: dataType,
    });
    this.ruleVariableChanged.next(newRule);
  }

  onRuleConfigurationPredicateChanged(predicate: BenefitEligibilityRuleConfigurationPredicate) {
    this.predicate = predicate;
    this.isInNotInPredicate = this.isPredicateMulti(this.predicate);
    this.isMinMaxPredicate = this.isPredicateMinMax(this.predicate);

    const variableId = this.variable ? this.variable.id : null;
    const variableType = this.variable ? this.variable.variableType : null;
    const predicateEnumValue = predicate ? predicate.predicateEnum : null;
    const newRule = new BenefitEligibilityRuleVariable({
      variableId: variableId,
      operatorType: predicateEnumValue,
      valueType: variableType,
      value: this.value,
      dataType: this.dataType,
    });

    this.ruleVariableChanged.next(newRule);
  }

  onRuleConditionTypeChanged() {
    this.conditionType = this.condition;
    this.conditionTypeChanged.next(this.condition);
  }

  convertStringToArray(stringValue: String): string[] {
    if (!stringValue) {
      return [];
    }

    const formattedValuesArray = _.chain(stringValue)
      .split(',')
      .map((item) => _.trim(item))
      .value();

    return formattedValuesArray;
  }

  convertBenefitClassNamesToIds(stringValue: string): string[] {
    if (!stringValue) {
      return [];
    }

    const formattedIdsArray = _.chain(stringValue)
      .split(',')
      .map((item: string) => _.trim(item))
      .map((item: string) => {
        const benefitClassDefinition = _.find(
          this.benefitClassDefinitions,
          (benefitClass: BenefitClassDefinition) => benefitClass.name.toLowerCase() === item.toLowerCase()
        );
        return benefitClassDefinition ? benefitClassDefinition.id.toString() : null;
      })
      .filter((item: string) => item !== null)
      .value();

    return formattedIdsArray;
  }

  convertBenefitClassIdsToNames(idValues: string[]): string {
    if (!idValues) {
      return '';
    }

    const formattedNamesArray = _.chain(idValues)
      .split(',')
      .map((stringId: string) => +stringId)
      .map((id: number) => {
        const benefitClassDefinition = _.find(
          this.benefitClassDefinitions,
          (benefitClass: BenefitClassDefinition) => benefitClass.id === id
        );
        return benefitClassDefinition ? benefitClassDefinition.name : null;
      })
      .filter((item: string) => item !== null)
      .join(',')
      .value();

    return formattedNamesArray;
  }

  singleValueOrPairChanged(newInputValue: string | string[]) {
    this.ruleVariableValueChanged(newInputValue);
  }

  payTypeValueChanged(payType: BenefitEligibilityRulePayType) {
    if (this.variable.id !== BenefitEligibilityRuleVariableEnum.PayType) {
      return;
    }

    const newValue = payType.id.toString();
    this.selectedBenefitRulePayType = payType;

    this.ruleVariableValueChanged(newValue);
  }

  benefitClassValueChanged(benefitClass: BenefitClassDefinition) {
    if (this.variable.id !== BenefitEligibilityRuleVariableEnum.BenefitClass) {
      return;
    }
    const newValue = benefitClass.id.toString();
    this.selectedBenefitDefinition = benefitClass;

    this.ruleVariableValueChanged(newValue);
  }

  multiValuesChanged(newMultiValueInput: string) {
    const isMulti = this.isPredicateMulti(this.predicate);
    if (!isMulti) {
      return;
    }
    const newValue = this.convertStringToArray(newMultiValueInput);

    this.ruleVariableValueChanged(newValue);
  }

  multiBenefitClassValueChanged(newInputType: string) {
    const benefitClassIds = this.convertBenefitClassNamesToIds(newInputType);
    this.value = this.convertBenefitClassIdsToNames(benefitClassIds);

    this.ruleVariableValueChanged(benefitClassIds);
  }

  setSelectedBenefitClassDefinitionOrPayType(ruleVariable: BenefitEligibilityRuleVariable) {
    if (ruleVariable) {
      const ruleVariableStringValue = _.isNull(ruleVariable.value) ? ruleVariable.value : ruleVariable.value.toString();
      if (ruleVariable.variableId === BenefitEligibilityRuleVariableEnum.BenefitClass) {
        _.forEach(this.benefitClassDefinitions, (cd) => {
          if (cd.id.toString() === ruleVariableStringValue) {
            this.selectedBenefitDefinition = cd;
          }
        });
      }

      if (ruleVariable.variableId === BenefitEligibilityRuleVariableEnum.PayType) {
        _.forEach(this.benefitEligibilityRulePayTypes, (btype) => {
          if (btype.id.toString() === ruleVariableStringValue) {
            this.selectedBenefitRulePayType = btype;
          }
        });
      }
    }
  }

  setRuleVariable(ruleVariable: BenefitEligibilityRuleVariable) {
    this.dataType = ruleVariable ? (ruleVariable.dataType as BenefitEligibilityRuleDataType) : null;

    const variableId = ruleVariable ? ruleVariable.variableId : null;
    this.variable = this.ruleStatementsManagementService.findConfigurationVariable(
      variableId,
      this.configurationVariables
    );

    const operatorType = ruleVariable ? ruleVariable.operatorType : null;
    this.setCurrentPredicates();

    if (this.currentPredicates && this.currentPredicates.length > 0) {
      this.predicate = _.find(this.currentPredicates, (predicate) => operatorType === predicate.predicateEnum);
      this.isInNotInPredicate = this.isPredicateMulti(this.predicate);
      this.isMinMaxPredicate = this.isPredicateMinMax(this.predicate);
    }

    const value = ruleVariable ? ruleVariable.value : null;
    if (variableId === BenefitEligibilityRuleVariableEnum.BenefitClass && this.isInNotInPredicate) {
      this.value = this.convertBenefitClassIdsToNames(value as string[]);
    } else {
      this.value = value;
    }

    this.setSelectedBenefitClassDefinitionOrPayType(ruleVariable);
  }

  isPredicateMinMax(predicate: BenefitEligibilityRuleConfigurationPredicate): boolean {
    let isMinMax = false;

    if (!predicate) {
      return isMinMax;
    }
    const predicateEnumValue = predicate.predicateEnum;
    if (predicateEnumValue === BenefitEligibilityRulePredicateEnum.Between) {
      isMinMax = true;
    }

    return isMinMax;
  }

  isPredicateMulti(predicate: BenefitEligibilityRuleConfigurationPredicate): boolean {
    let inNotInPredicateUsed = false;

    if (!predicate) {
      return inNotInPredicateUsed;
    }

    const predicateEnumValue = predicate.predicateEnum;
    if (
      predicateEnumValue === BenefitEligibilityRulePredicateEnum.In ||
      predicateEnumValue === BenefitEligibilityRulePredicateEnum.NotIn
    ) {
      inNotInPredicateUsed = true;
    }

    return inNotInPredicateUsed;
  }

  private ruleVariableValueChanged(value: string | string[]) {
    const variableId = this.variable ? this.variable.id : null;
    const variableType = this.variable ? this.variable.variableType : null;
    const predicateEnumValue = this.predicate ? this.predicate.predicateEnum : null;

    const newRule = new BenefitEligibilityRuleVariable({
      variableId: variableId,
      operatorType: predicateEnumValue,
      valueType: variableType,
      value: value,
      dataType: this.dataType,
    });

    this.ruleVariableChanged.next(newRule);
  }
}
