import { IOrganization, IPosition, IDepartment, IEmpType, Organization, Position, Department, EmpType } from '../../../../organization/models/index';

export interface IBenefitEligibleEmployee {
  employeeId: number;
  employeeName: string;
  department: IDepartment;
  type: IEmpType;
  organization: IOrganization;
  position: IPosition;
}



export class BenefitEligibleEmployee {
  public employeeId: number;
  public employeeName: string;
  public department: Department;
  public type: EmpType;
  public organization: Organization;
  public position: Position;
}
