import { IPosition, Position } from './position';

export interface IPbjActualPosition extends IPosition {
  organizationId?: number;
  departmentId?: number;
  departmentName?: string;
  isPrimary?: boolean;
}

export class PbjActualPosition extends Position {
  public organizationId: number;
  public departmentId: number;
  public departmentName: string;
  public isPrimary: boolean;
}
