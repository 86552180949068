import * as tslib_1 from "tslib";
import { CollapsibleSectionService } from './../../../../components-library/services/collapsible-section.service';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { DisplayType, ConsoleConfig, SoValuePairWidgetConfig, SoChartMenuItems } from '../../models/index';
import { ScheduleConsoleApiService, ScheduleConsoleFiltersService } from '../../services/index';
import { StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';
import { StateResetTypes } from '../../../../core/models/index';
import { ScheduleConsoleToolbarService, ScheduleConsoleChartService } from '../../services/index';
import { ScheduleConsoleGroupBy, ScheduleConsoleGroupByType } from '../../models/index';
import { OperationalConsoleStateService } from '../../../services/operational-console/operational-console-state.service';
var ScheduleConsoleComponent = /** @class */ (function () {
    function ScheduleConsoleComponent(scheduleConsoleApiService, activatedRoute, router, stateManagement, storageService, filtersService, stateService, consoleToolbarService, collapsibleService) {
        this.scheduleConsoleApiService = scheduleConsoleApiService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.filtersService = filtersService;
        this.stateService = stateService;
        this.consoleToolbarService = consoleToolbarService;
        this.collapsibleService = collapsibleService;
        this.isPositionData = false;
        this.cycleInterval = 7;
        this.dateFilterControlId = 'DateFilter';
        this.configControlKey = 'consoleConfig';
        this.m_resetType = StateResetTypes.SessionEnd;
        this.chartTypeKey = 'chartType';
        this.groupTypeKey = 'groupType';
        this.rangeTypeKey = 'rangeType';
        this.collapseKey = 'collapseAll';
        this.sectionsKeys = 'sectionsKeys';
        this.scrollToValue = 0;
        this.state = {
            isLoading: true
        };
        this.consoleConfig = new ConsoleConfig(DisplayType.idealScheduleHours, 0, 0);
        this.currentRange = null;
        this.startOfWeek = 'Sunday';
        this.selectedRangeType = 'Week';
        this.charts = SoChartMenuItems;
        this.shownChart = _.head(this.charts);
        this.groupByList = _.map(ScheduleConsoleGroupByType, function (value, key) {
            return new ScheduleConsoleGroupBy(key, value);
        });
        this.groupBy = this.groupByList[0];
    }
    Object.defineProperty(ScheduleConsoleComponent.prototype, "isGroupedByUnit", {
        get: function () {
            return this.groupBy.name === ScheduleConsoleGroupByType.UNIT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleComponent.prototype, "isGroupedByShiftGroup", {
        get: function () {
            return this.groupBy.name === ScheduleConsoleGroupByType.SHIFT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleComponent.prototype, "isDisabledGrouping", {
        get: function () {
            return this.groupBy.name === ScheduleConsoleGroupByType.NONE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleComponent.prototype, "orgLevelId", {
        get: function () {
            return this.orgLevel && this.orgLevel.id ? this.orgLevel.id : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleComponent.prototype, "isDayRange", {
        get: function () {
            return this.selectedRangeType === 'Day';
        },
        enumerable: true,
        configurable: true
    });
    ScheduleConsoleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateService.entries = [];
        this.stateManagement.init('ScheduleConsoleComponent', true);
        this.initSubscription = this.stateManagement.onInit$.subscribe(function (value) {
            _this.onRestored();
        });
        this.filtersService.onApplyConsoleFilters.subscribe(function (filters) {
            // refresh view?
        });
        this.collapseSubscription = this.collapsibleService.subscribeToToggleSections(function (isExpand) {
            _this.saveParam(_this.collapseKey, isExpand);
        });
    };
    ScheduleConsoleComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    ScheduleConsoleComponent.prototype.onRestored = function () {
        var _this = this;
        this.filtersService.isDisabledGrouping = this.isDisabledGrouping;
        this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
        this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;
        this.loadSubscription = this.stateManagement.loadData$
            .subscribe(function () {
            _this.loadValues();
        });
        this.scheduleConsoleSubscription = this.orgLevel$
            .subscribe(function (orgLevel) {
            if (!orgLevel || !orgLevel.id
                || _this.orgLevel && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.stateService.orgLevel = orgLevel;
            _this.filtersService.isDisabledGrouping = _this.isDisabledGrouping;
            _this.filtersService.isGroupedByUnit = _this.isGroupedByUnit;
            _this.filtersService.isGroupedByShiftGroup = _this.isGroupedByShiftGroup;
            _this.filtersService.consoleFiltersChanged([], false);
            if (_this.readyToLoad()) {
                _this.stateManagement.loadData();
            }
            else {
                _this.restoreParams();
                _this.restoreDateFilter();
                _this.restoreConfig();
            }
        });
    };
    ScheduleConsoleComponent.prototype.loadValues = function () {
        var _this = this;
        this.state.isLoading = true;
        this.scheduleConsoleData = null;
        this.filtersList = null;
        this.scheduleConsoleApiService.getScheduleConsoleData(this.orgLevel.id, this.currentRange.startDate, this.currentRange.endDate)
            .then(function (scheduleConsoleData) {
            _this.scheduleConsoleData = scheduleConsoleData;
            _this.filtersList = _this.scheduleConsoleData.filters;
            var lowerLimit = _this.consoleConfig.lowerLimit || _this.scheduleConsoleData.lowerLimit;
            var upperLimit = _this.consoleConfig.upperLimit || _this.scheduleConsoleData.upperLimit;
            _this.updateConsoleConfig(null, lowerLimit, upperLimit);
            _this.filtersService.isDisabledGrouping = _this.isDisabledGrouping;
            _this.filtersService.isGroupedByUnit = _this.isGroupedByUnit;
            _this.filtersService.isGroupedByShiftGroup = _this.isGroupedByShiftGroup;
            _this.filtersService.consoleData = scheduleConsoleData;
            _this.stateManagement.loadedData({});
            _this.state.isLoading = false;
            if (scheduleConsoleData && scheduleConsoleData.groupByPosition.length === 0) {
                return _this.isPositionData = true;
            }
            else {
                return _this.isPositionData = false;
            }
        })
            .catch(function () {
            _this.state.isLoading = false;
        });
    };
    ScheduleConsoleComponent.prototype.onConfigChange = function (config) {
        this.updateConsoleConfig(config.displayType, config.lowerLimit, config.upperLimit);
        this.saveConfig();
    };
    ScheduleConsoleComponent.prototype.onGrouppingChange = function (groupBy) {
        if (!_.isEqual(this.groupBy, groupBy)) {
            this.groupBy = groupBy;
            this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
            this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;
            this.saveParam(this.groupTypeKey, groupBy.id);
        }
    };
    ScheduleConsoleComponent.prototype.onDateRangeChange = function (range) {
        this.currentRange = range;
        this.saveDateFilter(range.selectedDate);
        this.stateService.currentDate = range.selectedDate;
        if (_.isObject(this.orgLevel) && _.isNumber(this.orgLevel.id)) {
            this.stateManagement.loadData();
            this.stateService.loadData();
        }
    };
    ScheduleConsoleComponent.prototype.onDateRangeTypeChange = function (range) {
        this.selectedRangeType = range.name;
        this.saveParam(this.rangeTypeKey, range.name);
    };
    ScheduleConsoleComponent.prototype.onShownChartChange = function (chart) {
        this.shownChart = chart;
        this.saveParam(this.chartTypeKey, chart.type);
    };
    ScheduleConsoleComponent.prototype.getWeek = function (comparisons) {
        var widgetConfig = new SoValuePairWidgetConfig();
        return ScheduleConsoleChartService.makeWeeklyColorMap(comparisons, this.consoleConfig, widgetConfig);
    };
    ScheduleConsoleComponent.prototype.onFilterChange = function (event) {
        if (this.readyToLoad()) {
            this.stateService.consoleFilters = event.filters;
            this.filtersService.isDisabledGrouping = this.isDisabledGrouping;
            this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
            this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;
            this.filtersService.consoleFiltersChanged(event.filters, event.isDirectCare);
        }
    };
    ScheduleConsoleComponent.prototype.generateDynamicUniqId = function (items) {
        var key = _.map(items, function (item) {
            return item.name;
        }).join('_');
        return this.orgLevelId + "_" + key;
    };
    ScheduleConsoleComponent.prototype.generateStaticUniqId = function (orgLevel, isOverview) {
        var staticKeyPart = isOverview ? '_overview' : '_ungroupped';
        var dynamicKeyPart = _.get(orgLevel, 'name', '') + staticKeyPart;
        return this.orgLevelId + "_" + dynamicKeyPart;
    };
    ScheduleConsoleComponent.prototype.onSectionExpandChanged = function (section) {
        var sections = this.restoreParam(this.sectionsKeys, {});
        sections[section.uniqId] = section.expanded;
        this.saveParam(this.sectionsKeys, sections, StateResetTypes.SessionEnd);
    };
    ScheduleConsoleComponent.prototype.restoreExpanded = function (uniqId, defValue) {
        if (defValue === void 0) { defValue = false; }
        var sections = this.restoreParam(this.sectionsKeys, {});
        if (sections.hasOwnProperty(uniqId)) {
            return sections[uniqId];
        }
        var collapseAll = this.restoreParam(this.collapseKey, false);
        return collapseAll || defValue;
    };
    ScheduleConsoleComponent.prototype.groupContainerCreated = function (item) {
        this.groupContainer = item;
        this.scrollGroupContainer();
    };
    ScheduleConsoleComponent.prototype.scrollToGraphicComparison = function (item) {
        var _this = this;
        setTimeout(function () {
            var c = item.getBoundingClientRect();
            _this.scrollToValue = c.top - 100;
            _this.scrollGroupContainer();
        }, 1000);
    };
    ScheduleConsoleComponent.prototype.scrollGroupContainer = function () {
        if (this.groupContainer && this.scrollToValue > 0) {
            var gc = this.groupContainer.getBoundingClientRect();
            this.groupContainer.scrollTop = this.scrollToValue - gc.top;
            this.scrollToValue = 0;
            this.groupContainer = null;
        }
    };
    ScheduleConsoleComponent.prototype.saveDateFilter = function (date) {
        this.storageService.setControlState(this.stateManagement.componentKey, this.dateFilterControlId, { value: { dateOn: date } }, this.m_resetType, this.stateKey);
    };
    ScheduleConsoleComponent.prototype.restoreDateFilter = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.dateFilterControlId);
        if (state && state.value) {
            if (state.value.dateOn) {
                this.selectedDate = moment(state.value.dateOn).toDate();
            }
        }
        else {
            var currentDate = new Date();
            this.selectedDate = currentDate;
        }
    };
    ScheduleConsoleComponent.prototype.saveConfig = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.configControlKey, { value: this.consoleConfig }, StateResetTypes.None);
    };
    ScheduleConsoleComponent.prototype.restoreConfig = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.configControlKey);
        var dType = _.get(state, 'value.displayType') || DisplayType.idealSchedulePPD;
        var lLimit = +_.get(state, 'value.lowerLimit') || 0;
        var uLimit = +_.get(state, 'value.upperLimit') || 0;
        this.updateConsoleConfig(dType, lLimit, uLimit);
    };
    ScheduleConsoleComponent.prototype.updateConsoleConfig = function (dType, lLimit, uLimit) {
        var displayType = dType ? dType : this.consoleConfig.displayType;
        var lowerLimit = lLimit ? lLimit : this.consoleConfig.lowerLimit;
        var upperLimit = uLimit ? uLimit : this.consoleConfig.upperLimit;
        this.consoleConfig = new ConsoleConfig(displayType, lowerLimit, upperLimit);
    };
    ScheduleConsoleComponent.prototype.readyToLoad = function () {
        return _.isDate(this.selectedDate) &&
            _.isObject(this.orgLevel) && _.isNumber(this.orgLevel.id) &&
            _.isObject(this.currentRange) && _.isDate(this.currentRange.startDate);
    };
    ScheduleConsoleComponent.prototype.saveParam = function (param, value, resetType) {
        if (resetType === void 0) { resetType = StateResetTypes.None; }
        this.storageService.setControlState(this.stateManagement.componentKey, param, { value: value }, resetType);
    };
    ScheduleConsoleComponent.prototype.restoreParam = function (param, defValue) {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, param);
        if (state && state.value) {
            return state.value;
        }
        else {
            return defValue;
        }
    };
    ScheduleConsoleComponent.prototype.restoreParams = function () {
        this.restoreRangeType();
        this.restoreChartType();
        this.restoreGroupType();
        this.restoreCollapseAll();
    };
    ScheduleConsoleComponent.prototype.restoreRangeType = function () {
        this.selectedRangeType = this.restoreParam(this.rangeTypeKey, 'Week');
    };
    ScheduleConsoleComponent.prototype.restoreCollapseAll = function () {
        this.collapsibleService.toggleAllSections(this.restoreParam(this.collapseKey, false));
    };
    ScheduleConsoleComponent.prototype.restoreChartType = function () {
        var chartType = this.restoreParam(this.chartTypeKey, 1);
        this.shownChart = _.find(this.charts, (function (chart) {
            return chart.type === chartType;
        }));
    };
    ScheduleConsoleComponent.prototype.restoreGroupType = function () {
        var groupId = this.restoreParam(this.groupTypeKey, null);
        if (groupId) {
            this.groupBy = _.find(this.groupByList, (function (gb) {
                return gb.id === groupId;
            }));
        }
        else {
            this.groupBy = this.groupByList[0];
        }
        this.filtersService.isGroupedByUnit = this.isGroupedByUnit;
        this.filtersService.isGroupedByShiftGroup = this.isGroupedByShiftGroup;
    };
    tslib_1.__decorate([
        mutableSelect(),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduleConsoleComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduleConsoleComponent.prototype, "application$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleComponent.prototype, "scheduleConsoleSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleComponent.prototype, "loadSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleComponent.prototype, "initSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleComponent.prototype, "collapseSubscription", void 0);
    return ScheduleConsoleComponent;
}());
export { ScheduleConsoleComponent };
