import * as _ from 'lodash';

import { IColumnSettings } from '../../../core/models/index';
import { TimecardsColumnState } from './timecards-column-state';
import { PayRuleDefinition } from '../../../organization/models/index';
import { ITimecardsGridState } from '../../store/timecards/timecards.types';

export class TimecardsState {
  public isShowPayRates: boolean;
  public isShowHighPrecision: boolean;
  public flatMode: boolean;
  public lastViewedEmployee: number;
  public lastViewedPage: number;
  public lastSelectedEntries: number[];
  public empColumns: TimecardsColumnState;
  public payCodeColumns: TimecardsColumnState;
  public gridState: ITimecardsGridState;
  public selectedEmployeeId: number;

  public createEmpColumns(): void {
    this.empColumns = new TimecardsColumnState();
    this.empColumns.columns = [
      {
        name: 'organization',
        description: 'Organization',
        displayed: false,
        width: 200,
        filter: null,
      },
      {
        name: 'department',
        description: 'Department',
        displayed: false,
        width: 200,
        filter: null,
      },
      {
        name: 'position',
        description: 'Position',
        displayed: true,
        width: 200,
        filter: null
      },
      {
        name: 'payroll',
        description: 'Payroll #',
        displayed: true,
        width: 110,
        filter: null
      },
      {
        name: 'empType',
        description: 'Employee Type',
        displayed: true,
        width: 120,
        filter: null
      },
      {
        name: 'approved',
        description: 'Approved',
        displayed: true,
        width: 100,
        filter: 'boolean'
      },
      {
        name: 'certified',
        description: 'Signoff',
        displayed: true,
        width: 100,
        filter: 'boolean'
      },
      {
        name: 'payPolicy',
        description: 'Pay Policy',
        displayed: true,
        width: 130,
        filter: null
      },
      {
        name: 'shiftDiffPolicy',
        description: 'Shift Diff Policy',
        displayed: true,
        width: 120,
        filter: null
      },
      {
        name: 'shiftCodeName',
        description: 'Shift Code',
        displayed: true,
        width: 130,
        filter: null
      },
      {
        name: 'hireDate',
        description: 'Hire Date',
        displayed: false,
        width: 120,
        filter: 'date'
      },
      {
        name: 'payRate',
        description: 'Pay Rate',
        displayed: true,
        width: 120,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      // {
      //   name: 'homeShift',
      //   description: 'Home Shift',
      //   displayed: false,
      //   width: 150,
      //   filter: null
      // },
      {
         name: 'costCenterCode',
         description: 'Cost Center Code',
         displayed: true,
         width: 180,
         filter: null
       },
      {
        name: 'productiveHours',
        description: 'Productive Hours',
        displayed: true,
        width: 120,
        filter: 'numeric'
      },
      {
        name: 'nonProductiveHours',
        description: 'Non-Productive Hourss',
        displayed: true,
        width: 120,
        filter: 'numeric'
      },
      {
        name: 'totalHours',
        description: 'Total Hours',
        displayed: true,
        width: 120,
        filter: 'numeric'
      },
      {
        name: 'regularPay',
        description: 'Regular Pay',
        displayed: true,
        width: 120,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      // {
      //   name: 'regularHours',
      //   description: 'Regular Hours',
      //   displayed: true,
      //   width: 150,
      //   filter: 'numeric'
      // },
      // {
      //   name: 'overtimeAndExtra',
      //   description: 'Overtime and Other',
      //   displayed: true,
      //   width: 150,
      //   filter: 'numeric',
      //   unit: '$',
      //   payload: { payRateRelated: true },
      // },
      // {
      //   name: 'overtimeAndExtraHours',
      //   description: 'Overtime and Other Hours',
      //   displayed: true,
      //   width: 150,
      //   filter: 'numeric'
      // },
      {
        name: 'overTimePay',
        description: 'Overtime Pay',
        displayed: true,
        width: 120,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      {
        name: 'totalAbsencePay',
        description: 'Total Paid Absences',
        displayed: true,
        width: 120,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      {
        name: 'totalOtherPay',
        description: 'Total Other Pay',
        displayed: true,
        width: 120,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      {
        name: 'overtimeAndExtraPay',
        description: 'Overtime and Other Pay',
        displayed: true,
        width: 120,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      },
      {
        name: 'totalPay',
        description: 'Total',
        displayed: true,
        width: 120,
        filter: 'numeric',
        unit: '$',
        payload: { payRateRelated: true },
      }
    ];
  }
  public createPayCodeColumns(usedPayRules: PayRuleDefinition[]): void {
    this.payCodeColumns = new TimecardsColumnState();
    this.payCodeColumns.columns = [ ];
    _.forEach(usedPayRules, (ruleDef: PayRuleDefinition) => {
      let column: IColumnSettings = {
        name: ruleDef.name,
        description: ruleDef.description,
        displayed: true,
      };
      this.payCodeColumns.columns.push(column);
    });
  }


}

