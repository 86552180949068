import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'convertUserFrndlyName',
})

export class ConvertUserFrndlyName implements PipeTransform {
  transform(name) {
    if(name !== null) {
      if (name.toString().includes(',')) {
        let nm = name.split(',');
        return (nm[1].trimStart(' ') + ' ' + nm[0]).toString();
      } else {
        return name;
      }
    } else {
      return '';
    }    
  }
}
