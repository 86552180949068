import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { Budget } from '../../../models/index';
import { BudgetManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
var BudgetListComponent = /** @class */ (function () {
    function BudgetListComponent(budgetManagementService, modalService) {
        this.budgetManagementService = budgetManagementService;
        this.modalService = modalService;
        this.state = {
            isLoading: false,
            isCorporation: false,
            isDepartment: false,
            isOrganization: false,
            isBudgetGroupEditMode: false,
            isRestoreBudgetMode: false,
            isBudgetAdjustMode: false
        };
    }
    BudgetListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.budgetManagementService.onOrgLevelChanged$
            .subscribe(function (orgLevelId) {
            _this.selectedOrganizationOrgLevelId = _this.budgetManagementService.selectedOrganizationOrgLevelId;
            _this.state.isCorporation = (orgLevelId === 0);
            _this.state.isDepartment = (orgLevelId === 0);
            _this.state.isOrganization = (orgLevelId !== 0);
        });
        this.statusSubscription = this.budgetManagementService.onLoadStatus$
            .subscribe(function (isLoading) {
            _this.state.isLoading = isLoading;
        });
        this.groupEditSubscription = this.budgetManagementService.onGroupEdit$
            .subscribe(function (editMode) {
            _this.state.isBudgetGroupEditMode = editMode;
        });
        this.restoreBudgetSubscription = this.budgetManagementService.onRestoreBudget$
            .subscribe(function (editMode) {
            _this.state.isRestoreBudgetMode = editMode;
        });
        this.budgetCensusAdjustSubscription = this.budgetManagementService.onBudgetCensusAdjust$
            .subscribe(function (editMode) {
            _this.state.isBudgetAdjustMode = editMode;
        });
        this.budgetSelectSubscription = this.budgetManagementService.onBudgetSelect$.subscribe(function (req) {
            _this.budget = req;
        });
        this.loadedSubscription = this.budgetManagementService.onLoaded$.subscribe(function (budget) {
            _this.budget = budget;
        });
        this.addedSubscription = this.budgetManagementService.onAdded$.subscribe(function (budget) {
            _this.budget = budget;
        });
    };
    BudgetListComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    BudgetListComponent.prototype.groupsfinishEditing = function (result) {
        this.budgetManagementService.onGroupEdit(false);
    };
    BudgetListComponent.prototype.restorefinishEditing = function (result) {
        this.budgetManagementService.onRestoreBudget(false);
        if (result) {
            this.budgetManagementService.onBudgetSelect(result);
            //this.budgetManagementService.onLoadRequest(result);
        }
    };
    BudgetListComponent.prototype.budgetCensusAdjustfinishEditing = function (result) {
        this.budgetManagementService.onBudgetCensusAdjust(false);
    };
    BudgetListComponent.prototype.onDiscard = function () {
        if (this.state.isBudgetGroupEditMode) {
            this.groupsfinishEditing(false);
        }
        else if (this.state.isRestoreBudgetMode) {
            this.restorefinishEditing(null);
        }
        else if (this.state.isBudgetAdjustMode) {
            this.budgetCensusAdjustfinishEditing(false);
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetListComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetListComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetListComponent.prototype, "addedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetListComponent.prototype, "statusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetListComponent.prototype, "groupEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetListComponent.prototype, "restoreBudgetSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetListComponent.prototype, "budgetCensusAdjustSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetListComponent.prototype, "budgetSelectSubscription", void 0);
    return BudgetListComponent;
}());
export { BudgetListComponent };
