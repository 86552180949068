import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import * as _ from 'lodash';
import * as kendoODataUtils from '../../../core/utils/kendo-odata-utils';
import { appConfig } from '../../../app.config';
import { commonConfig } from '../../common.config';
import { ApiUtilService } from '../api/api-util.service';
import { UrlParamsService } from '../url-params/url-params.service';
import { Meta, ResponseBody, IFilteredItems, ServerQuery, MetadataInfo, IMetadataInfo } from '../../../core/models/index';
import { MetaMapService } from '../../../core/services/index';

export type ItemsIterator<T, TResult> = (value: T, index: number, collection: T[]) => TResult;

@Injectable()
export class OdataApiService {

  constructor(private apiUtilService: ApiUtilService, private urlParamsService: UrlParamsService, private metaMapService: MetaMapService) {
  }

  public getData<T>(entity: string, query: ServerQuery, mapIteratee: ItemsIterator<any, T>, queryParams?: StringMap<any>): Promise<IFilteredItems<T>> {
    let queryStr = `${kendoODataUtils.slxToODataString(query)}&$count=true`;
    let params: HttpParams = new HttpParams({
      fromString: queryStr
    });

    if (queryParams) {
      let paramsAdd: HttpParams = this.urlParamsService.convertToParams(queryParams);
      paramsAdd.keys().forEach((value: string) => {
        params = params.append(value, paramsAdd.get(value));
      });
    }

    let url: string = `${this.getODataApiRoot()}/${entity}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<IFilteredItems<T>> = this.apiUtilService.request<IFilteredItems<T>, Meta>(request)
      .then((response: ResponseBody<IFilteredItems<T>, Meta>) => this.mapOData(query.skip, query.take, response, mapIteratee));
    return promise;
  }

  public mapOData<T>(skip: number, take: number, data: any, mapIteratee: ItemsIterator<any, T>): IFilteredItems<T> {
    let res: IFilteredItems<T> = {
      items: _.map(data.value, mapIteratee),
      count: data['@odata.count'],
      skiped: skip,
      taked: take
    };
    return res;
  }

  public getEntityMethadata(orgLevelId: number, entity: string): Promise<MetadataInfo> {
    const url: string = `${this.getCommonApiRoot()}/${commonConfig.api.entityMetadata}`;
    let promise: Promise<MetadataInfo> = this.apiUtilService.request<IMetadataInfo, Meta>(this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, entityName: entity }))
      .then((response: ResponseBody<IMetadataInfo, Meta>) => this.metaMapService.mapEntityMetadata(response.data));
    return promise;
  }

  public getODataApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${appConfig.api.odata}`;
  }

  public getCommonApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${commonConfig.api.root}`;
  }
}
