import { Injectable } from '@angular/core';
import { OrgLevelType } from '../../../state-model/models/index';
import { NavigationMenuItem, IMenuItemMapping, INavigationMenuItem, NavigationMenuType, IApplicationStyleMap } from '../../models/index';
import { organizationConfig } from '../../organization.config';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';



@Injectable()
export class NavigationMenuMapService {
  private readonly commonAppLink: string = 'common';
  private defIcon: string = organizationConfig.navigationMenu.menuItemsMapping['Default'].icon;

  public mapToModel(item: INavigationMenuItem): NavigationMenuItem {
    Assert.isNotNull(item, 'item');
    Assert.isNotNull(item.id, 'item.id');

    let menuItem: NavigationMenuItem = new NavigationMenuItem();
    menuItem.id = item.id;
    menuItem.name = item.name;
    menuItem.link = _.toLower(item.name);
    menuItem.displayName = item.displayName;
    menuItem.type = item.type;
    menuItem.order = item.order;
    menuItem.parentId = item.parentId;
    menuItem.applicationId = item.applicationId;
    menuItem.supportedOrgTypes = _.compact(item.supportedOrgTypes);
    menuItem.childs = [];

    menuItem.isAvailableOnMobile = item.isAvailableOnMobile;
    menuItem.isGlobal = item.isGlobal;
    menuItem.isExpanded = false;
    menuItem.isSelected = false;
    menuItem.childLevelsCount = item.childLevelsCount;

    let mapping: IMenuItemMapping;
    if (item.type === NavigationMenuType.application) {
      let styleMap: IApplicationStyleMap = _.find(organizationConfig.applications, (a: IApplicationStyleMap) => a.id === item.id);
      if (!styleMap) {
        styleMap = organizationConfig.unknownApplication;
      }
      mapping = { name: item.displayName, icon: styleMap.icon, url: item.name, isV5App: false };
    } else {
      mapping = organizationConfig.navigationMenu.menuItemsMapping[item.displayName];
      if (!mapping) {
        mapping = _.cloneDeep(organizationConfig.navigationMenu.menuItemsMapping['Default']);
      }
    }
    menuItem.menuItemMapping = mapping;

    return menuItem;
  }

  public addConfigurationMenuItem(): INavigationMenuItem {
    const configMenuItem: INavigationMenuItem = {
      applicationId: -2,
      displayName: 'Configuration',
      id: -2,
      isAvailableOnMobile: true,
      isGlobal: true,
      name: '',
      order: 5,
      parentId: 0,
      supportedOrgTypes: [],
      type: 'MenuItem',
      childLevelsCount: 3
    };
    return configMenuItem;
  }

  public mapperToConfigurationID(data: INavigationMenuItem[]) {
    _.forEach(data, (item: INavigationMenuItem) => {
      if(item.parentId === 0 && item.applicationId === -2 && item.displayName !== 'Configuration') {
        item.parentId = -2;
      }
    });
  }

  public configurationMenuExists(data: INavigationMenuItem[]) {
    let configPresent = false;
    _.forEach(data, (item: INavigationMenuItem) => {
      if(item.parentId === 0 && item.applicationId === -2 && item.displayName === 'Configuration') {
        configPresent = true;
      }
    });
    return configPresent;
  }

  public mapToMenu(data: INavigationMenuItem[]): NavigationMenuItem[] {
    this.mapperToConfigurationID(data);
    if(!this.configurationMenuExists(data)) { data.push(this.addConfigurationMenuItem()); }
    let items = _.map(data, (dto: INavigationMenuItem) => this.mapToModel(dto));
    let topItems = _.filter(items, (item: NavigationMenuItem) => item.type === NavigationMenuType.application);
    topItems = _.orderBy(topItems, (item: NavigationMenuItem) => item.order);
    let menuItems = _.filter(items, (item: NavigationMenuItem) => item.type === NavigationMenuType.menuItem && !item.isGlobal);
    _.forEach(topItems, (app: NavigationMenuItem) => {
      let appMenuItems = _.filter(menuItems, (item: NavigationMenuItem) => item.applicationId === app.id);
      app.childs = this.mapGroupsMenu(appMenuItems, app);
    });
    let globalMenuItems = _.filter(items, (item: NavigationMenuItem) => item.type === NavigationMenuType.menuItem && item.parentId === 0 && item.isGlobal);
    _.forEach(globalMenuItems, (item: NavigationMenuItem) => {
      item.appLink = this.commonAppLink;
    });
    let childsGlobalmenuItems = _.filter(items, (item: NavigationMenuItem) => item.type === NavigationMenuType.menuItem && item.parentId !== 0 && item.isGlobal);
    globalMenuItems = _.orderBy(globalMenuItems, (item: NavigationMenuItem) => item.order);
    _.forEach(globalMenuItems, (globalItem: NavigationMenuItem) => {
      let childGlobalMenuItems = _.filter(childsGlobalmenuItems, (item: NavigationMenuItem) => item.parentId === globalItem.id);
      globalItem.childs = _.orderBy(childGlobalMenuItems, (item: NavigationMenuItem) => item.order);
    });
    _.forEach(childsGlobalmenuItems, (childItem: NavigationMenuItem) => {
      let childOfChildGlobalMenuItems = _.filter(childsGlobalmenuItems, (item: NavigationMenuItem) => item.parentId === childItem.id);
      childItem.childs = _.orderBy(childOfChildGlobalMenuItems, (item: NavigationMenuItem) => item.order);
      _.map(childsGlobalmenuItems, (grandChild: NavigationMenuItem) => {
        if(childItem.id === grandChild.parentId) { childItem.childLevelsCount = 3; }
      });
    });
    _.forEach(childsGlobalmenuItems, (item: NavigationMenuItem) => {
      item.appLink = this.commonAppLink;
      item.link = item.name;
    });
    topItems.push(...globalMenuItems);
    return topItems;
  }

  public mapGroupsMenu(appItems: NavigationMenuItem[], app: NavigationMenuItem): NavigationMenuItem[] {
    let roots: NavigationMenuItem[] = _.filter(appItems, (item: NavigationMenuItem) => item.parentId === 0);
    roots = _.orderBy(roots, (item: NavigationMenuItem) => item.order);
    let hash: NumberMap<NavigationMenuItem> = _.keyBy(roots, (item: NavigationMenuItem) => item.id);
    const appLink = _.toLower(app.name);
    _.forIn(hash, (item: NavigationMenuItem) => item.appLink = appLink);
    this.mapToParents(hash, appItems, appLink);
    return roots;
  }

  public mapToParents(hash: NumberMap<NavigationMenuItem>, items: NavigationMenuItem[], appLink: string): void {
    _.forEach(items, (item: NavigationMenuItem) => {
      if (item.parentId !== 0) {
        item.appLink = appLink;
        item.link = item.name;
        let parentMenuItem: NavigationMenuItem = hash[item.parentId];
        if (parentMenuItem) {
          let seq: number = _.sortedIndexBy(parentMenuItem.childs, item, (item: NavigationMenuItem): number => {
            return item.order;
          });
          parentMenuItem.childs.splice(seq, 0, item);
          if (item.menuItemMapping.icon === this.defIcon) {
            item.menuItemMapping.icon = parentMenuItem.menuItemMapping.icon;
          }
        }
      }
    });
  }
}


