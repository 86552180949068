<kendo-grid #grid
  kendoGridFixFreezeHeader slxKendoGridMobileManager
  slxKendoGridState="PJB_EXPORT_CENSUS_DATA" (stateRestored)="gridState.dataStateChange($event)"
  class="slx-full-height slx-blue-grid slx-no-border"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [slxGroupable]="{ showFooter: true }"
  [slxGroupableMobile]="false"
  [group]="gridState.state.group"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  [pageable]="true"
  (pageChange)="pageChanged($event)"
  (sortChange)="sortChange($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
  >
  <!--(filterChange)="filterChange($event)"-->

  <kendo-grid-column media="xs" title="PBJ Log">
    <ng-template kendoGridCellTemplate let-dataItem>
      <dl>
        <dt>Export ID</dt>
        <dd>{{ dataItem?.exportId }}</dd>

        <dt>Month End</dt>
        <dd>{{ dataItem?.monthEndDate | amDateFormat: appConfig.dateFormat }}</dd>

        <dt>Medicaid</dt>
        <dd>{{ dataItem?.medicaid }}</dd>

        <dt>Medicare</dt>
        <dd>{{ dataItem?.medicare }}</dd>

        <dt>Other</dt>
        <dd>{{ dataItem?.other }}</dd>
      </dl>
    </ng-template>
  </kendo-grid-column>

  <!-- <kendo-grid-column title="Emp Master ID" width="150" media="sm" field="exportId" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Emp Master ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.exportId }}
    </ng-template>
  </kendo-grid-column> -->

  <kendo-grid-column title="Month End" width="150" media="sm" field="monthEndDate" filter="date">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Month End</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.monthEndDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Medicaid" width="100" media="sm" field="medicaid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Medicaid</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.medicaid }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-numeric-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-numeric-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Medicare" width="100" media="sm" field="medicare">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Medicare</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.medicare }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-numeric-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-numeric-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Other" width="100" media="sm" field="other">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Other</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.other }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-numeric-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-numeric-filter-menu>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

<kendo-grid #exportGrid *ngIf="isExportInProgress && currentExportType != xlsExportType" class="export-grid slx-blue-grid slx-no-border"
  [data]="exportGridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="exportGridState.state.sort"
  [group]="exportGridState.state.group"
  [filterable]="'menu'"
  [filter]="exportGridState.state.filter"
  [pageable]="false"
>

  <kendo-grid-column title="Month End" width="150" field="monthEndDate" filter="date">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Month End</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.monthEndDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Medicaid" width="150" field="medicaid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Medicaid</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.medicaid }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Medicare" width="150" field="medicare">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Medicare</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.medicare }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Other" width="150" field="other">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Other</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.other }}
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-pdf fileName="{{exportFilename}}.pdf"
      paperSize="A4" [scale]="pdfScale" [landscape]="true" [allPages]="true" [repeatHeaders]="true"
      >

      <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>

      <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
      <div class="page-template">
          <div class="header">
              <span class="top-running-title">{{pbjHeader?.organization?.name}} ({{pbjHeader?.startDate| amDateFormat: appConfig.dateFormat}} - {{pbjHeader?.endDate| amDateFormat: appConfig.dateFormat}}) Exported on {{pbjHeader?.exportDate| amDateFormat: appConfig.dateTimeFormatUS}}</span>
          </div>
          <div class="footer">
            Page {{ pageNum }} of {{ totalPages }}
          </div>
        </div>
      </ng-template>

  </kendo-grid-pdf>

</kendo-grid>
