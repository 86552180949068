import * as _ from 'lodash';

export class DailyLinePunchActions {
  public static readonly EditTimecardsKey: string = 'editTimecards';

  public canSave: boolean;
  public canEditTimecards: boolean;

  constructor(canEditTimecards: boolean, canSave?: boolean) {
    this.canEditTimecards = canEditTimecards;

    if (_.isBoolean(canSave)) {
      this.canSave = canSave;
    }
  }
}
