import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridCustomSelectionHelper, KendoGridStateHelper } from '../../../common/index';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
import { ConfirmDialogComponent } from './../../../common/components/confirm-dialog/confirm-dialog.component';
import { ModalService } from '../../../common/services/modal/modal.service';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { AccessManagementService } from '../../services/accessManagement/access-management.service';
import { Holiday } from '../../models/index';
import { HolidaysConfigurationManagementService } from '../../services/holidays/holidays-configuration-management.service';
import { NgForm } from '@angular/forms';
var HolidaysComponent = /** @class */ (function () {
    function HolidaysComponent(management, access, modalService) {
        var _this = this;
        this.access = access;
        this.modalService = modalService;
        this.appConfig = appConfig;
        this.management = management;
        this.currentYear = moment().year();
        this.yearItems = _.range(this.currentYear - 5, this.currentYear + 4, 1);
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        // directive intercepted methods (scope bug)
        this.onAddItem = function () {
            var item = new Holiday();
            item.date = moment().startOf('day').toDate();
            item.start = moment().startOf('day').subtract(1, 'hours').toDate();
            item.end = moment().startOf('day').add(23, 'hours').toDate();
            _this.crudHelper.addItem(item);
        };
        this.onDeletedItems = function () {
            _this.crudHelper.deleteSelected();
        };
    }
    Object.defineProperty(HolidaysComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    HolidaysComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false,
            configureMode: true,
            copyMode: false,
            isEditing: false
        };
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
            if (_.has(state, 'configureMode'))
                _this.state.configureMode = state.configureMode;
            if (_.has(state, 'copyMode'))
                _this.state.copyMode = state.copyMode;
        });
        this.editSubscription = this.management.editItemCmd$.subscribe(function (item) {
            if (item) {
                _this.mainFormSubscription = _this.mainForm.statusChanges.subscribe(function () {
                    if (_this.mainForm && _this.mainForm.dirty) {
                        _this.management.markAsDirty();
                    }
                });
            }
            else {
                if (_this.mainFormSubscription) {
                    _this.mainFormSubscription.unsubscribe();
                }
            }
            _this.state.isEditing = (item !== null);
        });
        this.savedSubscription = this.management.onItemSaved$.subscribe(function (item) {
            _this.state.isEditing = false;
            _this.gridState.refreshGrid();
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.removeSubscription = this.management.removeItemsCmd$.subscribe(function (item) {
            _this.showRemoveDialog(item);
        });
        this.addSubscription = this.management.addItemCmd$.subscribe(function (item) {
            _this.state.isEditing = true;
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.setYear(this.currentYear);
        this.management.init();
    };
    // Must be, see #issueWithAOTCompiler
    HolidaysComponent.prototype.ngOnDestroy = function () {
        if (this.crudHelper) {
            this.crudHelper.destroy();
        }
    };
    HolidaysComponent.prototype.onSelectYear = function (year) {
        this.currentYear = year;
        this.management.setYear(this.currentYear);
    };
    HolidaysComponent.prototype.saveItem = function () {
        this.crudHelper.onSaveItem({ dataItem: this.management.editingItem, isNew: this.management.isEditingNewItem });
    };
    HolidaysComponent.prototype.discardChanges = function () {
        this.crudHelper.cancelEdit(this.gridState.editedRowIndex);
    };
    HolidaysComponent.prototype.showRemoveDialog = function (selected) {
        var _this = this;
        ConfirmDialogComponent.openDialog('Remove holidays', 'Do you want to delete the holiday?', this.modalService, function (result) {
            if (result) {
                _this.management.doRemoveItem(selected);
            }
        });
    };
    HolidaysComponent.prototype.onCopyItems = function (event) {
        event.preventDefault();
        this.management.openCopyItems();
    };
    HolidaysComponent.prototype.switchToConfigure = function () {
        this.management.closeCopyItems();
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", HolidaysConfigurationManagementService)
    ], HolidaysComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysComponent.prototype, "editSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysComponent.prototype, "addSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysComponent.prototype, "mainFormSubscription", void 0);
    return HolidaysComponent;
}());
export { HolidaysComponent };
