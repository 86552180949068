<div class="app {{className}}">
  <a class="app__logo" (click)="navigation()">
    <i class="fa" [ngClass]="dashboard?.icon" aria-hidden="true"></i>
  </a>
  <div class="app__content">
    <div class="app__url">
      <a class="app-url-link" (click)="navigation()">{{ dashboard?.title }}</a>
      <div (click)="onStartAlerts()" class="dashboard-edit-button-alert">
        <em class="fas fa-cog" aria-hidden="true"></em>
        Edit
      </div>
    </div>
    <slx-spinner [show]="progressbar" class="app__spinner">
      <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
    </slx-spinner>
  </div>
</div>

<ng-template #contentTmpl>
  <ng-content></ng-content>
</ng-template>
