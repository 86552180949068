<slx-spinner [show]="state.isLoading">
  <div class="modal-body">
    <div class="header">
      <span class="title">
        {{(orgLevel$ | async).name}}
      </span>
    </div>
    <div class="grid-holder">

        <kendo-grid [data]="backupGridData" class="center-part slx-full-height" [selectable]="{ mode:'single'}" (selectionChange)="onSelectedChanged($event)"
          scrollable="'scrollable'">
          <kendo-grid-column [width]="40">
            <ng-template kendoGridHeaderTemplate></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              <i class="fa fa-users" aria-hidden="true" *ngIf="dataItem.isFull"></i>
              <i class="fa fa-user" aria-hidden="true" *ngIf="!dataItem.isFull"></i>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="User" field="username">
          </kendo-grid-column>
          <kendo-grid-column title="Created" field="createdAt">
            <ng-template kendoGridHeaderTemplate>
              Created
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem?.createdAt | amDateFormat: appConfig.dateTimeFormatUS }}
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Comment" field="comment">
          </kendo-grid-column>
          <kendo-grid-column title="Start Date">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.startDate | amDateFormat: appConfig.dateFormat}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="End Date">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.endDate | amDateFormat: appConfig.dateFormat}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Employee">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.employee?.name}}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="#Records" field="numberOfRecords">
          </kendo-grid-column>
        </kendo-grid>

    </div>
    <div class="information" *ngIf="backupGridData?.data?.length > 0">
      * Backup Sets are saved for 30 days
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn green" (click)="restoreBackup()" [class.disabled]="!selectedBackup || state.isLoading">
      Restore
    </button>
    <button type="button" class="btn red" (click)="cancelRestoring()">
      Cancel
    </button>
  </div>

</slx-spinner>


