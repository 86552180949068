<div class="shift schedule-details-shift" provide-parent-form>

    <section class="title-section">
      <span class="title">Shift {{index + 1}}</span>
    </section>

    <slx-scheduled-shift-main-section
        [index]="index"
        [container]="shift"
        (onPositionChanged)="onPositionChanged ($event)"
        (onShiftChanged)="onShiftChanged ($event)"
        (onUnitChanged)="onUnitChanged ($event)"
        (onConstraintChanged)="onConstraintChanged ($event)"
        (onAbsenceChanged)="onAbsenceChanged ($event)"
        (onStartDateChanged)="onStartDateChanged ($event)"
        (onEndDateChanged)="onEndDateChanged ($event)"
        (onShiftHoursChanged)="onShiftHoursChanged ($event)"
        (onUnpaidHoursChanged)="onUnpaidHoursChanged ($event)"
    ></slx-scheduled-shift-main-section>

    <slx-scheduled-shift-partial-absence-section
    [index]="index"
    [shift]="shift?.scheduleEntryShift?.shift"
    [hasScheduleAbsence]="shift?.hasScheduleAbsence"
    [startDate]="shift.shiftStartDate"
    [endDate]="shift.shiftEndDate"
    [shiftHours]="shift.fullTime"
    [partialAbsToEnd]="shift.partialAbsToEnd"
    [absences]="shift?.absences"
    [partialAbsence]="shift?.scheduleEntryShift?.partialAbsence"
    (onChange)="onPartialAbsenceChange($event)"
    (onTimeChange)="onPartialAbsenceTimeChange($event)"
    (onAttachmentChange)="onPartialAbsenceAttachmentChange ($event)"
    >
    </slx-scheduled-shift-partial-absence-section>

    <slx-scheduled-shift-lateness-section
        [index]="index"
        [maxDate]="management.maxDate"
        [minDate]="management.minDate"
        [startDate]="shift?.shiftStartDate"
        [endDate]="shift?.shiftEndDate"
        [shiftHoursInMinutes]="shift?.shiftHoursInMinutes"
        [late]="shift?.scheduleEntryShift?.late"
        (onLateStartDateChanged)="lateDateChanged ($event)"
        (onLateIntervalChanged)="onLateIntervalChanged ($event)"
    ></slx-scheduled-shift-lateness-section>

    <slx-scheduled-shift-overtime-section
        [index]="index"
        [shift]="shift?.scheduleEntryShift?.shift"
        [hasScheduleAbsence]="shift?.hasScheduleAbsence"
        [maxDate]="management.maxDate"
        [minDate]="management.minDate"
        [startDate]="shift?.shiftStartDate"
        [endDate]="shift?.shiftEndDate"
        [fullTime]="shift?.fullTime"
        [overtime]="shift?.scheduleEntryShift?.overtime"
        (onOvertimeDateChanged)="overtimeDateChanged($event)"
        (onOvertimeHoursChanged)="overtimeHoursChanged($event)"
    >
    </slx-scheduled-shift-overtime-section>

    <slx-scheduled-shift-notes-section
    [notes]="shift?.scheduleEntryShift?.notes" [index]="index"
    (onNoteChanged)="onNoteChanged($event)"
    ></slx-scheduled-shift-notes-section>

    <slx-scheduled-shift-replacements-section
    [index]="index"
    [replaced]="shift?.scheduleEntryShift?.replaced"
    [replacing]="shift?.scheduleEntryShift?.replacing"
    >
    </slx-scheduled-shift-replacements-section>


    <div class="btn-group shift-actions">

        <button type="button" class="theme-icon-button edit-button dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-pencil-alt" aria-hidden="true"></i>
        </button>
        <ul *ngIf="!shift.scheduleEntryShift.shift" class="dropdown-menu dropdown-menu-left">
            <li (click)="removeShift()"><a>Remove</a></li>
        </ul>
        <ul *ngIf="!!shift.scheduleEntryShift.shift" class="dropdown-menu dropdown-menu-left">
            <li (click)="removeShift()"><a>Remove</a></li>
            <li *ngIf="!shift.hasScheduleAbsence && shift.scheduleEntryShift.partialAbsence == null && !!shift.scheduleEntryShift.position && management.scheduleEntryContainer.canReplaceEmployee"><a (click)="onReplacement()">Replace</a></li>
            <li *ngIf="!shift.hasScheduleAbsence && !shift.scheduleEntryShift.constraint" class="constraints-menu-item"
                (click)="addConstraint()"
            >
                <a *ngIf="!shift.hasConstraints" class="disabled-menu-item" slxTooltip="No constraints configured" tipPosition="right">Add Constraint</a>
                <a *ngIf="!!shift.hasConstraints">Add Constraint</a>
            </li>
            <li *ngIf="!shift.hasScheduleAbsence && !!shift.scheduleEntryShift.constraint" (click)="removeConstraint()"><a>Remove Constraint</a></li>
            <li *ngIf="!shift.hasScheduleAbsence" (click)="markAbsent()"><a>Mark Absent</a></li>
            <li *ngIf="!!shift.hasScheduleAbsence" (click)="markPresent()"><a>Remove Absence</a></li>
            <li *ngIf="!shift.hasScheduleAbsence && !shift.scheduleEntryShift.late" (click)="addLate()"><a>Add Lateness</a></li>
            <li *ngIf="!shift.hasScheduleAbsence && !!shift.scheduleEntryShift.late" (click)="removeLate()"><a>Remove Lateness</a></li>
            <li *ngIf="!shift.hasScheduleAbsence && !shift.scheduleEntryShift.overtime" (click)="addOvertime()"><a>Add Prescheduled OT</a></li>
            <li *ngIf="!shift.hasScheduleAbsence && !!shift.scheduleEntryShift.overtime" (click)="removeOvertime()"><a>Remove Prescheduled OT</a></li>
            <li *ngIf="!shift.hasScheduleAbsence && !shift.scheduleEntryShift.partialAbsence" (click)="addPartialAbsence()"><a>Add Partial Absence</a></li>
            <li *ngIf="!shift.hasScheduleAbsence && !!shift.scheduleEntryShift.partialAbsence" (click)="removePartialAbsence()"><a>Remove Partial Absence</a></li>
            <li *ngIf="shift.scheduleEntryShift.notes == null" (click)="addNotes()"><a>Add Notes</a></li>
            <li *ngIf="shift.scheduleEntryShift.notes != null" (click)="removeNotes()"><a>Remove Notes</a></li>
        </ul>
    </div>

</div>
