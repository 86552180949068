export * from './benefit-details.component';
export * from './benefit-details-toolbar/benefit-details-toolbar.component';
export * from './benefit-details-tabs/benefit-details-tabs.component';
export * from './benefit-details-standart/benefit-details-standart.component';
export * from './benefit-details-providers-menu/benefit-details-providers-menu.component';
export * from './benefit-details-provider-info-line/benefit-details-provider-info-line.component';
export * from './benefit-details-benefit-info-menu/benefit-details-benefit-info-menu.component';
export * from './benefit-details-benefit-info-line/benefit-details-benefit-info-line.component';
export * from './benefit-details-coverage-calc-options/benefit-details-coverage-calc-options.component';
export * from './benefit-details-coverage-calc-flat/benefit-details-coverage-calc-flat.component';
export * from './benefit-details-coverage-calc-formula/benefit-details-coverage-calc-formula.component';
export * from './benefit-details-coverage-calc-formula-with-option/benefit-details-coverage-calc-formula-with-option.component';
export * from './benefit-details-extend-dialog/benefit-details-extend-dialog.component';
export * from './benefit-details-expire-dialog/benefit-details-expire-dialog.component';
export * from './benefit-details-renew-dialog/benefit-details-renew-dialog.component';
export * from './benefit-details-eligibility-rule-dialog/benefit-details-eligibility-rule-dialog.component';
export * from './benefit-details-eligibility-rules-list/benefit-details-eligibility-rules-list.component';
export * from './benefit-details-eligibility-statement-number-values/benefit-details-eligibility-statement-number-values.component';
export * from './benefit-details-eligibility-statement-date-values/benefit-details-eligibility-statement-date-values.component';

import { BenefitDetailsComponent } from './benefit-details.component';
import { BenefitDetailsToolbarComponent } from './benefit-details-toolbar/benefit-details-toolbar.component';
import { BenefitDetailsTabsComponent } from './benefit-details-tabs/benefit-details-tabs.component';
import { BenefitDetailsStandartComponent } from './benefit-details-standart/benefit-details-standart.component';
import { BenefitDetailsProvidersMenuComponent } from './benefit-details-providers-menu/benefit-details-providers-menu.component';
import { BenefitDetailsProviderInfoLineComponent } from './benefit-details-provider-info-line/benefit-details-provider-info-line.component';
import { BenefitDetailsBenefitInfoMenuComponent } from './benefit-details-benefit-info-menu/benefit-details-benefit-info-menu.component';
import { BenefitDetailsBenefitInfoLineComponent } from './benefit-details-benefit-info-line/benefit-details-benefit-info-line.component';
import { BenefitDetailsCoverageCalcOptionsComponent } from './benefit-details-coverage-calc-options/benefit-details-coverage-calc-options.component';
import { BenefitDetailsCoverageCalc401KComponent } from './benefit-details-401k/benefit-details-coverage-calc-401K.component';
import { BenefitDetailsCoverageCalcFlatComponent } from './benefit-details-coverage-calc-flat/benefit-details-coverage-calc-flat.component';
import { BenefitDetailsCoverageCalcFormulaComponent } from './benefit-details-coverage-calc-formula/benefit-details-coverage-calc-formula.component';
import { BenefitDetailsCoverageCalcFormulaWithOptionComponent } from './benefit-details-coverage-calc-formula-with-option/benefit-details-coverage-calc-formula-with-option.component';
import { BenefitDetailsExtendDialogComponent } from './benefit-details-extend-dialog/benefit-details-extend-dialog.component';
import { BenefitDetailsExpireDialogComponent } from './benefit-details-expire-dialog/benefit-details-expire-dialog.component';
import { BenefitDetailsRenewDialogComponent } from './benefit-details-renew-dialog/benefit-details-renew-dialog.component';
import { BenefitDetailsEligibilityRuleDialogComponent } from './benefit-details-eligibility-rule-dialog/benefit-details-eligibility-rule-dialog.component';
import { BenefitDetailsEligibilityRulesListComponent } from './benefit-details-eligibility-rules-list/benefit-details-eligibility-rules-list.component';
import { BenefitDetailsEligibilityRuleDialogStatementsComponent } from './benefit-details-eligibility-rule-dialog-statements/benefit-details-eligibility-rule-dialog-statements.component';
import { BenefitDetailsEligibilityStatementsClauseGroupComponent } from './benefit-details-eligibility-statements-clause-group/benefit-details-eligibility-statements-clause-group.component';
import { BenefitDetailsEligibilityStatementsClauseComponent } from './benefit-details-eligibility-statements-clause/benefit-details-eligibility-statements-clause.component';
import { BenefitDetailsEligibilityStatementNumberValuesComponent } from './benefit-details-eligibility-statement-number-values/benefit-details-eligibility-statement-number-values.component';
import { BenefitDetailsEligibilityStatementDateValuesComponent } from './benefit-details-eligibility-statement-date-values/benefit-details-eligibility-statement-date-values.component';
import { BenefitDetailsEligibiltyStatementMultiValuesComponent } from './benefit-details-eligibilty-statement-multi-values/benefit-details-eligibilty-statement-multi-values.component';

export const benefitDetailsComponents: any[] = [
  BenefitDetailsComponent,
  BenefitDetailsToolbarComponent,
  BenefitDetailsTabsComponent,
  BenefitDetailsStandartComponent,
  BenefitDetailsProvidersMenuComponent,
  BenefitDetailsProviderInfoLineComponent,
  BenefitDetailsBenefitInfoMenuComponent,
  BenefitDetailsBenefitInfoLineComponent,
  BenefitDetailsCoverageCalcOptionsComponent,
  BenefitDetailsCoverageCalc401KComponent,
  BenefitDetailsCoverageCalcFlatComponent,
  BenefitDetailsCoverageCalcFormulaComponent,
  BenefitDetailsCoverageCalcFormulaWithOptionComponent,
  BenefitDetailsExtendDialogComponent,
  BenefitDetailsExpireDialogComponent,
  BenefitDetailsRenewDialogComponent,
  BenefitDetailsEligibilityRuleDialogComponent,
  BenefitDetailsEligibilityRulesListComponent,
  BenefitDetailsEligibilityRuleDialogStatementsComponent,
  BenefitDetailsEligibilityStatementsClauseGroupComponent,
  BenefitDetailsEligibilityStatementsClauseComponent,
  BenefitDetailsEligibilityStatementNumberValuesComponent,
  BenefitDetailsEligibilityStatementDateValuesComponent,
  BenefitDetailsEligibiltyStatementMultiValuesComponent
];
