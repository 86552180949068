import * as tslib_1 from "tslib";
import { OnInit, OnChanges, EventEmitter, OnDestroy, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { PayUnitsMassAssignmentApiService, PayUnitsMassAssignmentMapService } from '../../services/index';
import { MassAssignPayUnitsRequest } from '../../models/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { KendoGridStateHelper, DialogOptions } from '../../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { LookupService } from '../../../../organization/services/index';
import { Lookup, LookupType, isNilPayUnit } from '../../../../organization/models/index';
import { NotificationsService } from '../../../../core/components';
import { ModalService, } from '../../../../common/services/index';
import { ModalAnchorDirective } from '../../../../common/index';
var MassAssignmentPayUnitsComponent = /** @class */ (function () {
    function MassAssignmentPayUnitsComponent(apiService, mapService, lookupService, changeDetector, notificationsService, modalService) {
        var _this = this;
        this.apiService = apiService;
        this.mapService = mapService;
        this.lookupService = lookupService;
        this.changeDetector = changeDetector;
        this.notificationsService = notificationsService;
        this.modalService = modalService;
        this.onClose = new EventEmitter();
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'employeeName', dir: 'asc' }];
        this.rowCallback = function (context) { return _this.rowClassFnc(context); };
    }
    MassAssignmentPayUnitsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    MassAssignmentPayUnitsComponent.prototype.ngOnDestroy = function () { };
    MassAssignmentPayUnitsComponent.prototype.ngOnChanges = function (changes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.request) {
                    this.load();
                    this.lookupService.getLookup({ lookupType: LookupType.payUnits, orgLevelId: this.request.orgLevelId, isActive: true })
                        .then(function (lookup) {
                        _this.payunitsLookup = {};
                        var items = lookup.items;
                        var dict = _.groupBy(items, function (item) { return item.departmentId; });
                        if (!dict[0]) {
                            dict[0] = [];
                        }
                        var orgItems = _.map(dict[0]);
                        _.forIn(dict, function (value, key) {
                            var _a;
                            var depLookup = new Lookup();
                            depLookup.titleField = lookup.titleField;
                            depLookup.valueField = lookup.valueField;
                            depLookup.items = value;
                            if (key !== '0') {
                                (_a = depLookup.items).push.apply(_a, orgItems);
                            }
                            depLookup.items = _.orderBy(depLookup.items, function (item) { return item.name; });
                            depLookup.items.unshift({ id: 0, name: '' });
                            _this.payunitsLookup[+key] = depLookup;
                        });
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    MassAssignmentPayUnitsComponent.prototype.load = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                this.apiService.getMassAssignPayUnits(this.request.orgLevelId, this.request.startDate, this.request.endDate, this.request.employeeIds)
                    .then(function (res) {
                    _this.data = res;
                    _this.selectedDate = _this.request.startDate;
                    _this.changeDate();
                    _this.refreshGrid();
                }).finally(function () {
                    _this.isLoading = false;
                    setTimeout(function () {
                        _this.detectChanges();
                    }, 10);
                });
                return [2 /*return*/];
            });
        });
    };
    MassAssignmentPayUnitsComponent.prototype.rowClassFnc = function (context) {
        if (!context.dataItem || !context.dataItem.dayTimecard || !context.dataItem.dayTimecard[this.dateKey]) {
            return null;
        }
        var timecard = context.dataItem.dayTimecard[this.dateKey];
        var isLock = timecard.lockDay;
        var hasError = this.isTimecardError(timecard);
        return {
            'slx-non-editable': isLock,
            'slx-error-row': hasError
        };
    };
    MassAssignmentPayUnitsComponent.prototype.isTimecardError = function (timecard) {
        return (!!timecard.payUnits[0].payUnit && !timecard.payUnits[0].units) || (!!timecard.payUnits[1].payUnit && !timecard.payUnits[1].units) || (!!timecard.payUnits[2].payUnit && !timecard.payUnits[2].units);
    };
    MassAssignmentPayUnitsComponent.prototype.isModelHasError = function () {
        var _this = this;
        var hasError = false;
        _.forEach(this.data.records, function (r) {
            _.forEach(r.timecardPayUnits, function (t) {
                hasError = hasError || _this.isTimecardError(t);
                if (hasError) {
                    return false;
                }
            });
            if (hasError) {
                return false;
            }
        });
        return hasError;
    };
    MassAssignmentPayUnitsComponent.prototype.changeDate = function () {
        if (!this.selectedDate || !moment(this.selectedDate).isValid() || moment(this.request.startDate).isAfter(this.selectedDate) || moment(this.request.endDate).isBefore(this.selectedDate)) {
            return;
        }
        this.dateKey = moment(this.selectedDate).format(appConfig.linkDateFormat);
        this.detectChanges();
    };
    MassAssignmentPayUnitsComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options, payload, hasChanges, options;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.isModelHasError()) {
                    options = new DialogOptions();
                    options.message = 'Some Pay Units has zero or blank value!';
                    options.height = 150;
                    options.width = 300;
                    this.modalAnchor.openInfoDialogEx("Warning", options, function (result) {
                        //nothing to do;
                    });
                    return [2 /*return*/];
                }
                payload = this.mapService.mapToPayload(this.data);
                hasChanges = !!_.find(payload.records, function (r) { return r.addRecords.length > 0 || r.deleteRecords.length > 0 || r.updateRecords.length > 0; });
                if (!hasChanges) {
                    options = new DialogOptions();
                    options.message = 'There are no changes to save!';
                    options.height = 150;
                    options.width = 300;
                    this.modalAnchor.openInfoDialogEx("Warning", options, function (result) {
                        //nothing to do;
                    });
                    return [2 /*return*/];
                }
                this.isLoading = true;
                this.apiService.saveMassAssignPayUnits(this.request.orgLevelId, payload)
                    .then(function () {
                    _this.onClose.next(true);
                    _this.notificationsService.success('Success', 'Unit codes were successfully updated');
                })
                    .catch(function (err) {
                    _this.notificationsService.error('Error', 'Error has been occured during update Unit Codes');
                })
                    .finally(function () {
                    _this.isLoading = false;
                });
                return [2 /*return*/];
            });
        });
    };
    MassAssignmentPayUnitsComponent.prototype.getPayunitsLookup = function (dateItem) {
        if (!this.payunitsLookup) {
            return null;
        }
        var l = this.payunitsLookup[dateItem.departmentId];
        if (!l) {
            l = this.payunitsLookup[0];
        }
        return l;
    };
    MassAssignmentPayUnitsComponent.prototype.getEmpPositions = function (dateItem) {
        var _this = this;
        if (!dateItem.positionsLookup || dateItem.positionsLookupDateKey !== this.dateKey) {
            dateItem.positionsLookup = new Lookup();
            dateItem.positionsLookup.items = _.filter(dateItem.positions, function (p) { return (!p.startDate || moment(p.startDate).isSameOrBefore(_this.selectedDate)) && (!p.endDate || moment(p.endDate).isSameOrAfter(_this.selectedDate)); });
            dateItem.positionsLookup.titleField = 'name';
            dateItem.positionsLookup.valueField = 'id';
            dateItem.positionsLookupDateKey = this.dateKey;
        }
        return dateItem.positionsLookup;
    };
    MassAssignmentPayUnitsComponent.prototype.changePayUnit = function (p, timecard, pu, index) {
        pu.payUnit = p;
        if (isNilPayUnit(pu.payUnit)) {
            for (var i = index; i < timecard.payUnits.length; i++) {
                var temp = timecard.payUnits[i];
                temp.position = undefined;
                temp.payUnit = undefined;
                temp.units = undefined;
            }
        }
        else {
            if (pu.position === undefined) {
                pu.position = timecard.position;
            }
            if (pu.units === undefined || pu.units === null) {
                pu.units = 1;
            }
        }
        this.detectChanges();
    };
    MassAssignmentPayUnitsComponent.prototype.changePosition = function (position, pu) {
        if (isNilPayUnit(pu.payUnit)) {
            pu.position = undefined;
            return;
        }
        pu.position = position;
    };
    MassAssignmentPayUnitsComponent.prototype.changeUnits = function (units, pu) {
        if (isNilPayUnit(pu.payUnit)) {
            pu.units = undefined;
            return;
        }
        pu.units = units;
    };
    MassAssignmentPayUnitsComponent.prototype.cancel = function () {
        this.onClose.next(false);
    };
    MassAssignmentPayUnitsComponent.prototype.refreshGrid = function () {
        if (!this.data) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.data.records, this.gridState.state);
        this.detectChanges();
    };
    MassAssignmentPayUnitsComponent.prototype.detectChanges = function () {
        this.changeDetector.markForCheck();
        if (!this.changeDetector['destroyed']) {
            this.changeDetector.detectChanges();
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], MassAssignmentPayUnitsComponent.prototype, "subscriptions", void 0);
    return MassAssignmentPayUnitsComponent;
}());
export { MassAssignmentPayUnitsComponent };
