import { Component, OnInit, Input } from '@angular/core';
import { IDialog, DialogOptions } from '../../../../common/models/dialog-options';
import { Assert } from '../../../../framework/assert/assert';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { UnassignedEmployee } from '../../../models/supervisor-assignment/index';

@Component({
    moduleId: module.id,
    selector: 'slx-unassigned-employees-popup',
    templateUrl: 'unassigned-employees-popup.component.html',
    styleUrls: ['unassigned-employees-popup.component.scss']
})
export class UnassignedEmployeesPopupComponent implements IDialog, OnInit {

    @Input()
    public set employees(value: UnassignedEmployee[]) {
        this.m_employees = value;
        if (this.state) this.state.isLoading = this.m_employees === null;
    }

    public get employees(): UnassignedEmployee[] {
        return this.m_employees;
    }

    public state: {
        isLoading: boolean;
    };

    public dialogResult: boolean;

    private m_employees: UnassignedEmployee[] = null;

    constructor(private options: DialogOptions, private modalService: ModalService) {
        Assert.isNotNull(this.modalService, 'modalService');
        Assert.isNotNull(this.options, 'options');
    }

    public ngOnInit(): void {
        this.state = {
            isLoading: this.m_employees === null
        };
    }

    public onOk(): void {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }

    public onCancel(): void {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }
}
