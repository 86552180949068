/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../core/components/angular2-notifications/simple-notifications/components/simple-notifications.component.ngfactory";
import * as i3 from "../../core/components/angular2-notifications/simple-notifications/components/simple-notifications.component";
import * as i4 from "../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i5 from "../../common/components/init-pendo/init-pendo.component.ngfactory";
import * as i6 from "../../common/components/init-pendo/init-pendo.component";
import * as i7 from "../../authentication/services/authentication/authentication.service";
import * as i8 from "../../common/services/device-detector/device-detector.service";
import * as i9 from "../../authentication/services/user-extended-settings/user-extended-settings-management.service";
import * as i10 from "../../common/components/init-fullstory/init-fullstory.component.ngfactory";
import * as i11 from "../../common/components/init-fullstory/init-fullstory.component";
import * as i12 from "@angular/router";
import * as i13 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "simple-notifications", [], null, null, null, i2.View_SimpleNotificationsComponent_0, i2.RenderType_SimpleNotificationsComponent)), i1.ɵdid(1, 245760, null, 0, i3.SimpleNotificationsComponent, [i4.NotificationsService], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "slx-init-pendo", [], null, null, null, i5.View_InitPendoComponent_0, i5.RenderType_InitPendoComponent)), i1.ɵdid(3, 4308992, null, 0, i6.InitPendoComponent, [i7.AuthenticationService, i8.DeviceDetectorService, i9.UserExtendedSettingsManagementService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-init-fullstory", [], null, null, null, i10.View_InitFullstoryComponent_0, i10.RenderType_InitFullstoryComponent)), i1.ɵdid(5, 4308992, null, 0, i11.InitFullstoryComponent, [i7.AuthenticationService, i8.DeviceDetectorService], null, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "slx-app", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i13.AppComponent, [i1.ViewContainerRef], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("slx-app", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
