import { AppServerConfig } from './../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../../app-settings/services/index';
import { Component, OnInit, OnDestroy, Input, Output, Host, ViewChild, NgZone, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';

import { Assert } from '../../../../../framework/index';
import { LookupService, EmployeeValidatorAdapter, EmployeeDefinitionsApiService } from '../../../../../organization/services/index';
import { Lookup, LookupType, ILookupRequest, Position, LookupEntity, Employee, Supervisor } from '../../../../../organization/models/index';
import { EmployeePayrollEffectiveDateSettings, EmployeeSectionsPayCycleRecord, EmployeeSectionsPayCycles, EmployeeSectionsPayroll, EmployeeSectionsTemporalModel, EmployeeSectionsBase, BenefitClassDialogReq, EmployeeSectionsProfile } from '../../../models/index';
import { EmployeeSectionBridgeService, EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { IServerValidationResult } from '../../../../../common/validators/common-validators-models';
import { AppSettingKey, PbjModes } from '../../../../../app-settings/model/app-setting-keys';
import { ModalService } from '../../../../../common/services/index';
import { ConfirmDialogComponent, InfoDialogComponent } from '../../../../../common/components/index';
import { BenefitClassDialogComponent } from '../../benefit-class-dialog/benefit-class-dialog.component';
import * as moment from 'moment';
import { appConfig } from '../../../../../app.config';
import { dateTimeUtils } from '../../../../../common/utils/index';

import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';

import { employeeConfig } from '../../../../employee/employee.config';
import { EmployeePayRateComponent } from '../../../../../employee/employee';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-payroll',
  templateUrl: 'employee-sections-payroll.component.html',
  styleUrls: ['employee-sections-payroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EmployeeSectionsPayrollComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public employeeId: number;
  @Input() public employeeStatus: string;
  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form', { static: false })
  public ngForm: NgForm;

  public hourlyRateLimit: number = 9999.99;
  public weeklyRateLimit: number = 399999.99;
  public hourlyRateWarningLimit: number = 299.99;
  public weeklyRateWarningLimit: number = 11999.99;
  public positionOrgLevelId: number;
  public positionLookupRequest: ILookupRequest;
  public payPolicyLookupRequest: ILookupRequest;
  public shiftDiffPolicyLookupRequest: ILookupRequest;
  public employeePayrollEffectiveDateSettings: EmployeePayrollEffectiveDateSettings;
  public pbjEnabled: boolean = false;
  public pbjIsManual: boolean = false;
  public bswiftEnabled: boolean = false;
  public isShowCurrentPayCycleWarning = true;
  public disabledDatesData: EmployeeSectionsPayCycleRecord[];
  public enabledDatesData: EmployeeSectionsPayCycleRecord[];
  public enabledDates: Date[];
  public employeeSectionsPayCycles: EmployeeSectionsPayCycles;
  public employeePayRateComponent: EmployeePayRateComponent[];
  public isPbjIdLoading: boolean = false;
  public decimalLimit: number = null;

  public increaseReason: LookupEntity;
  public supervisorOptions: Lookup;
  public increaseReasons: Lookup;
  public selectedSupervisor : Supervisor;
  public SupervisorName : string;
  public isSupervisorEnabled : boolean;
  public get employeeSectionsPayroll(): EmployeeSectionsPayroll {
    return this.m_employeeSectionsPayroll;
  }

  @Input()
  public set employeeSectionsPayroll(value: EmployeeSectionsPayroll) {
    let l: EmployeePayrollEffectiveDateSettings;
    if (!_.isNil(this.m_employeeSectionsPayroll) && !_.isNil(this.m_employeeSectionsPayroll.effectiveDateSetting)) {
      l = this.m_employeeSectionsPayroll.effectiveDateSetting;
    }
    this.m_employeeSectionsPayroll = value;
    if (value) {
      if (this.increaseReasons) {
        this.increaseReason = this.increaseReasons.items.find(x => x.id == value.icnreaseReason);
      } else {
        this.increaseReason = null;
      }
      this.selectedSupervisor = value.supervisor;
      this.originalPbjId = value.pbjId.fieldValue;
      this.originalSSN = value.ssn.fieldValue;
      this.employeePayRateComponent = value.payRateComponents.fieldValue;
      this.maxHireDate = value.maxAllowableHireDate;
      this.m_employeeSectionsPayroll.effectiveDateSetting = l;
      this.setDecimalLimit(value.isEnableRate4DecimalPlaces);
      this.employeeSectionBridgeService.changePayRateFormat(value.isEnableRate4DecimalPlaces);
    } else {
      this.increaseReason = null;
    }
  }

  public get employeePayCycles(): EmployeeSectionsPayCycles {
    return this.employeeSectionsPayCycles;
  }

  @Input()
  public set employeePayCycles(value: EmployeeSectionsPayCycles) {
    if (value) {
      this.employeeSectionsPayCycles = value;
      this.setPayCycleData();
    }
  }

  public get pbjIdMaxSymbols(): number {
    return this.originalPbjId === this.employeeSectionsPayroll.pbjId.fieldValue ? 30 : 9;
  }

  public get payrollNumberValidation(): string {
    return EmployeeValidatorAdapter.payrollNumberValidation;
  }

  public get ssnValidation(): string {
    return EmployeeValidatorAdapter.ssnValidation;
  }

  public get pbjIdValidation(): string {
    return EmployeeValidatorAdapter.pbjIdValidation;
  }

  public get isSalaryPayType(): boolean {
    return this.employeeSectionsPayroll &&
      this.employeeSectionsPayroll.payType &&
      this.employeeSectionsPayroll.payType.fieldValue.isSalaryPayType;
  }

  public get getRateLimit(): number {
    return this.isSalaryPayType ? this.weeklyRateLimit : this.hourlyRateLimit;
  }

  public get getRateWarningLimit(): number {
    return this.isSalaryPayType ? this.weeklyRateWarningLimit : this.hourlyRateWarningLimit;
  }

  public get getRateTitle(): string {
    return this.isSalaryPayType ? 'Weekly Rate' : 'Hourly Rate';
  }
  public setPayCycleData() {
    this.employeePayrollEffectiveDateSettings = new EmployeePayrollEffectiveDateSettings();
    const currentDate: moment.Moment = moment();
    const allPayCyclesSorted = _.orderBy(this.employeeSectionsPayCycles.records, c => c.startDate, ['asc']);
    const currentPayCycles = _.filter(allPayCyclesSorted, (cycle) => {
      const currStartDate: moment.Moment = moment(cycle.startDate).add(-1, 'days');;
      const currEndDate: moment.Moment = moment(cycle.endDate).add(1, 'days');;
      return moment(currentDate).isBetween(currStartDate, currEndDate);
    });

    if (currentPayCycles.length > 0) {
      const payCycle: EmployeeSectionsPayCycleRecord = _.first(_.orderBy(currentPayCycles, c => moment(c.endDate), ['desc']));
      this.employeePayrollEffectiveDateSettings.effectiveDateData = payCycle.startDate;
    }

    const lockedPayCycles = _.filter(allPayCyclesSorted, (cycle) => {
      return (cycle.approved == true || cycle.locked == true);
    });
    this.disabledDatesData = lockedPayCycles;
    const unlockedPayCycles = _.filter(allPayCyclesSorted, (cycle) => {
      return (cycle.locked == false && cycle.approved == false);
    });
    this.enabledDatesData = unlockedPayCycles;
    this.enabledDates = _.map(unlockedPayCycles, cycle => cycle.startDate);

    if(this.employeeStatus.toLowerCase() === "future rehire"){
      this.employeePayrollEffectiveDateSettings.minDate2 = this.m_employeeSectionsPayroll.rehireDate;
      this.employeePayrollEffectiveDateSettings.minDate = this.m_employeeSectionsPayroll.rehireDate;
    }
    else{
      const lastLockedPayCycleIndex = _.findLastIndex(allPayCyclesSorted, c => (c.approved == true || c.locked == true));
      if (lastLockedPayCycleIndex != -1) {
        const minDateAllPayCycle = allPayCyclesSorted[lastLockedPayCycleIndex + 1];
        if (minDateAllPayCycle) {
          this.employeePayrollEffectiveDateSettings.minDate2 = minDateAllPayCycle.startDate;
          this.employeePayrollEffectiveDateSettings.minDate = minDateAllPayCycle.startDate;
        } else {
          const lastLockedPayCycle = allPayCyclesSorted[lastLockedPayCycleIndex];
          const minDate = moment(lastLockedPayCycle.endDate).add(1, 'days');
          this.employeePayrollEffectiveDateSettings.minDate2 = minDate.toDate();
          this.employeePayrollEffectiveDateSettings.minDate = minDate.toDate();
        }
      }
    }

    const maxDateAllPayCycle = _.first(_.orderBy(unlockedPayCycles, c => moment(c.endDate), ['desc']));
    if (maxDateAllPayCycle) {
      this.employeePayrollEffectiveDateSettings.maxDate2 = moment(maxDateAllPayCycle.endDate).add(1, 'years').toDate();
      this.employeePayrollEffectiveDateSettings.maxDate = moment(maxDateAllPayCycle.endDate).add(1, 'years').toDate();

      let projectedPayCycleStart: Date = moment(maxDateAllPayCycle.endDate).add(1, 'days').toDate();

      let paycycleInterval: number = moment.duration(moment(projectedPayCycleStart).diff(moment(maxDateAllPayCycle.startDate))).asDays();
      this.enabledDates = this.addProjectedDates(this.enabledDates, projectedPayCycleStart, this.employeePayrollEffectiveDateSettings.maxDate2, paycycleInterval);
    }
    this.m_employeeSectionsPayroll.effectiveDateSetting = this.employeePayrollEffectiveDateSettings;
  }

  public get isOverSalary(): boolean {
    return this.state.isEditMode &&
      this.employeeSectionsPayroll &&
      ((this.employeeSectionsPayroll.payRate &&
        this.employeeSectionsPayroll.payRate.fieldValue) ||
        (this.employeeSectionsPayroll.payRate2 &&
          this.employeeSectionsPayroll.payRate2.fieldValue) ||
        (this.employeeSectionsPayroll.payRate3 &&
          this.employeeSectionsPayroll.payRate3.fieldValue)) > this.getRateWarningLimit;
  }

  public get ftDateNeeded(): boolean {
    if (!this.employeeSectionsPayroll) return false;
    return _.toLower(_.trim(this.employeeSectionsPayroll.empType.fieldValue.fullTimeIndex)) === 'y'; //<--- TBD implement this logic on backend
  }

  public get allocationsSize(): number {
    if (
      _.isObjectLike(this.employeeSectionsPayroll)
      && _.isObjectLike(this.employeeSectionsPayroll.costCenterAllocations)
      && _.isArray(this.employeeSectionsPayroll.costCenterAllocations.fieldValue)
    ) {
      const allocations = _.filter(this.employeeSectionsPayroll.costCenterAllocations.fieldValue, a => _.isObjectLike(a.costCenter));

      return _.size(allocations);
    }
    return 0;
  }

  public get displayMaxDateTip(): boolean {
    return _.isDate(this.maxHireDate);
  }
  public get isSalariedWithoutHours(): boolean {
    return this.state.isEditMode &&
      this.employeeSectionsPayroll &&
      this.employeeSectionsPayroll.standardWeeklyHours &&
      this.employeeSectionsPayroll.standardWeeklyHours.fieldValue === 0 &&
      this.employeeSectionsPayroll.payType.fieldValue &&
      this.employeeSectionsPayroll.payType.fieldValue.name == 'Salaried'
  }
  public employeeValidatorAdapter: EmployeeValidatorAdapter;
  public payTypeLookup: Lookup;


  private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService;
  private lookupService: LookupService;
  public m_employeeSectionsPayroll: EmployeeSectionsPayroll;
  private originalPbjId: string;
  private originalSSN: string;
  public isNgpUser: boolean = false;
  @unsubscribe()
  private birthDateSubscriptions: Subscription;

  public minHireDate: Date;
  public maxHireDate: Date;

  constructor(employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService, lookupService: LookupService, employeeValidatorAdapter: EmployeeValidatorAdapter,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone, private appSettingsManageService: AppSettingsManageService,
    private modalService: ModalService, private employeeDefinitionApi: EmployeeDefinitionsApiService,
    private changeDetector: ChangeDetectorRef,
    private employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    private employeeSectionBridgeService: EmployeeSectionBridgeService) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
    this.lookupService = lookupService;
    this.employeeValidatorAdapter = employeeValidatorAdapter;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsPayroll;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['employeeSectionsPayroll']) {
      if (this.employeeSectionsPayroll) {
        this.positionLookupRequest = { lookupType: 'position', orgLevelId: this.employeeSectionsPayroll.homeOrgLevelId };
        this.payPolicyLookupRequest = { lookupType: 'payPolicy', orgLevelId: this.employeeSectionsPayroll.homeOrgLevelId };
        this.shiftDiffPolicyLookupRequest = { lookupType: 'shiftDiffPolicy', orgLevelId: this.employeeSectionsPayroll.homeOrgLevelId };
      }
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.lookupService.getLookup({ lookupType: LookupType.payType, employeeId: undefined, orgLevelId: undefined })
      .then((payTypeLookup: Lookup) => { this.payTypeLookup = payTypeLookup; });

    this.lookupService.getLookup({ lookupType: LookupType.increaseReason, employeeId: undefined, orgLevelId: undefined })
      .then((increaseReasons: Lookup) => {
        this.increaseReasons = increaseReasons;
        if (this.m_employeeSectionsPayroll) {
          this.increaseReason = this.increaseReasons.items.find(x => x.id == this.m_employeeSectionsPayroll.icnreaseReason);
        }
      });

    this.getSettings();

    this.birthDateSubscriptions = this.employeeSectionBridgeService.subscribeToChangeBirthDate((birthDate: Date) => {
      this.minHireDate = moment(birthDate).add(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
    });
  }

  public positionChanged(position: Position): void {
    if (!position) {
      return;
    }
    this.payPolicyLookupRequest = { lookupType: 'payPolicy', orgLevelId: position.orgLevelId };
    this.shiftDiffPolicyLookupRequest = { lookupType: 'shiftDiffPolicy', orgLevelId: position.orgLevelId };
  }

  public showCurrentPayCycleWarning(): void {
    if (this.isShowCurrentPayCycleWarning) {
      let message = 'No Current Payroll Cycles have been found. Be sure to select the appropriate effective date as additional validation cannot be provided.';
      InfoDialogComponent.OpenDialog('Warning', message, this.modalService);
      this.isShowCurrentPayCycleWarning = false;
    }
  }

  public resetCurrentPayCycleWarning(): void {
    this.isShowCurrentPayCycleWarning = true;
  }

  public generatePbjId(): void {
    ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to generate a PBJ ID?', this.modalService, (result: boolean) => {
      if (result) {
        this.isPbjIdLoading = true;
        this.employeeDefinitionApi.generatePbjId()
          .then((value: string) => {
            this.employeeSectionsPayroll.pbjId.fieldValue = value;
            this.isPbjIdLoading = false;
            this.updateData();
          });
      }
    });
  }

  public checkSalariedHours(): void {
    const standardWeeklyHoursControl = this.form.get('standardWeeklyHours');
    let errors = standardWeeklyHoursControl.errors || {};
    if (!this.isSalariedWithoutHours) {
      delete errors.isSalariedWithoutHours;
      if (_.size(errors) === 0) {
        errors = null;
      }
    } else {
      errors.isSalariedWithoutHours = true;
    }
    standardWeeklyHoursControl.setErrors(errors);
  }

  public onCostCenterChanged(isValid: boolean): void {
    const costCenterCodeControl = this.form.get('costCenterCode');
    let errors = costCenterCodeControl.errors || {};
    if (isValid) {
      delete errors.costCenterAllocations;
      if (_.size(errors) === 0) {
        errors = null;
      }
    } else {
      errors.costCenterAllocations = true;
    }
    costCenterCodeControl.setErrors(errors);
  }

  public onPayRateChanged(controlName: string): void {
    const payRateControl = this.form.get(controlName);
    if (_.isNaN(payRateControl.value)) {
      payRateControl.setValue(null);
      payRateControl.setErrors(null);
      if (controlName === 'payRate2' && this.employeeSectionsPayroll && this.employeeSectionsPayroll.payRate2) {
        this.employeeSectionsPayroll.payRate2.fieldValue = null;
      }
      else if (controlName === 'payRate3' && this.employeeSectionsPayroll && this.employeeSectionsPayroll.payRate3) {
        this.employeeSectionsPayroll.payRate3.fieldValue = null;
      }
    }
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmploymentPayroll(this.employeeId)
      .then((employeeSectionsPayroll: EmployeeSectionsPayroll) => {
        this.employeeSectionsPayroll = employeeSectionsPayroll;
		    this.selectedSupervisor = employeeSectionsPayroll.supervisor;
        this.updateRequiredPolicyField();
        this.stopProgress();
        this.updateData();
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
      this.lookupService.getLookup({ lookupType: LookupType.supervisorList,employeeId: this.employeeId, orgLevelId:  this.employeeSectionsPayroll.homeOrgLevelId  })
      .then((splist: Lookup) => {
        this.supervisorOptions = splist;
      });
  }

  public updateData(): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public updatePayType(event: any): void {
    this.employeeSectionsPayroll.payType.fieldValue = event;
    setTimeout(() => {
      this.updateData();
      this.checkSalariedHours();
      this.updateData();
    }, 0);
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    return this.metaFieldsTemporalDirtyChecker(this.employeeSectionsPayroll);
  }

  protected doSave(effectiveDate: Date): void {

    if (this.increaseReason) {
      this.m_employeeSectionsPayroll.icnreaseReason = this.increaseReason.id;
    } else {
      this.m_employeeSectionsPayroll.icnreaseReason = null;
    }

    if (this.m_employeeSectionsPayroll.payrollNumber.fieldValue) {
      this.m_employeeSectionsPayroll.payrollNumber.fieldValue = _.trim(this.m_employeeSectionsPayroll.payrollNumber.fieldValue);
    }

    this.m_employeeSectionsPayroll.supervisor = this.selectedSupervisor;
    if (this.bswiftEnabled &&
      !this.employeeShortInfo.avoidsBswiftChecks &&
      this.m_employeeSectionsPayroll.areBenefitsFieldsDirty()
    ) {
      this.triggerBenefitClassPopup(effectiveDate);
    } else {
      this.saveEmploymentPayroll(effectiveDate);
    }
  }

  private saveEmploymentPayroll(effectiveDate: Date): Promise<any> {
    return this.employeeSectionsEmploymentApiService.setEmploymentPayroll(this.employeeId, this.employeeSectionsPayroll, effectiveDate)
      .then((status: any) => {
        this.onActionComplete(true);
        this.employeeSectionBridgeService.changeHireDate(this.employeeSectionsPayroll.hireDate.fieldValue);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.pbjEnabled = config.PbjEnabled;
    this.isSupervisorEnabled = config.isSupervisorEnabled
    this.pbjIsManual = config.PbjMode === PbjModes.Manual;
    this.updateRequiredPolicyField();
    this.bswiftEnabled = config.bswiftIntegrationEnabled;
    this.isNgpUser = config.IsNextgenPayrollEnabled || false;

  }

  private updateRequiredPolicyField(): void {
    if (this.employeeSectionsPayroll) {
      if (!this.employeeSectionsPayroll.pbjId.Policy.isVisible || this.employeeSectionsPayroll.pbjId.Policy.isMasked || !this.employeeSectionsPayroll.pbjId.Policy.isEditable) {
        this.employeeSectionsPayroll.pbjId.isRequired = false;
      } else {
        this.employeeSectionsPayroll.pbjId.isRequired = this.employeeSectionsPayroll.pbjId.isRequired || this.pbjIsManual;
      }
    }
  }

  public disabledDate(date: Date): any {
    if (this.m_employeeSectionsPayroll.isOnlyPayRate()) {
      const unlockedPayCycles = _.filter(this.enabledDatesData, (cycle) => {
        const currStartDate: moment.Moment = moment(cycle.startDate).add(-1, 'days');
        const currEndDate: moment.Moment = moment(cycle.endDate).add(1, 'years').add(1, 'days');
        return moment(date).isBetween(currStartDate, currEndDate);
      });
      if(this.employeeSectionsPayroll.payType.fieldValue.description.toLowerCase() === 'salaried'){		
      return this.enableStartDate(date);		
        }		
        	else{		
        	return (unlockedPayCycles.length > 0) ? false : true;		
        	}
    }
    else if (this.m_employeeSectionsPayroll.isNotOnlyPayRate()) {
      
      if(this.employeeSectionsPayroll.payType.fieldValue.description.toLowerCase() === 'hourly'){
        return false;
      }
      else{
        return this.enableStartDate(date);
      }
    }
  }
  public enableStartDate(date){
    if (this.enabledDates.length > 0) {
      let result = _.find(this.enabledDates, function (n) {
        if (moment(n).isSame(date)) {
            return true;
          }
        });
        if (!_.isNil(result)) {
          return false;
        }
        else {
          return true;
        }
      }
    }

  private async triggerBenefitClassPopup(effectiveDate: Date): Promise<void> {
    const canEditBenefitClass = await this.employeeDefinitionsApiService.getCanEditBenefitClass(this.employeeId);

    if (canEditBenefitClass) {
      const request = new BenefitClassDialogReq();
      request.employeeId = this.employeeId;
      request.hireDate = this.employeeSectionsPayroll.hireDate.fieldValue;
      request.employeeBenefitClass = await this.employeeDefinitionsApiService.getEmployeeBenefitClass(this.employeeId, moment().startOf('day').toDate());
      if(this.employeeStatus.toLowerCase() === "future rehire"){
        request.rehireDate = this.employeeSectionsPayroll.rehireDate;
      }
      BenefitClassDialogComponent.OpenDialog(request, this.modalService, (result: boolean) => {
        if (result) {
          this.saveEmploymentPayroll(effectiveDate);
        } else {
          this.loadSubsection();
        }
      });
    } else {
      await this.saveEmploymentPayroll(effectiveDate);

      InfoDialogComponent.OpenDialog(
        'Warning',
        'The changes you have made to the employee record could prompt a change to the employee Benefit Class. Please contact your Benefits Administrator and advise them of the changes.',
        this.modalService
      );
    }
  }

  public isValidEffectiveDate(date: Date): any {
    let minDate: Date;
    let maxDate: Date;
    let isValid = true;

    if (_.isNull(date) || _.isUndefined(date)) {
      isValid = false;
    }
    if (!dateTimeUtils.validateDate(date) && isValid) {
      isValid = false;
    }
    if ((this.m_employeeSectionsPayroll.isNotOnlyPayRate() || this.m_employeeSectionsPayroll.isOnlyPayRate()) && this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.effectiveDateData) {
      if (this.m_employeeSectionsPayroll.isOnlyPayRate()) {
        if (this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.minDate) {
          minDate = this.m_employeeSectionsPayroll.effectiveDateSetting.minDate;
        }

        if (this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.maxDate) {
          maxDate = this.m_employeeSectionsPayroll.effectiveDateSetting.maxDate;
        }
      }
      if (this.m_employeeSectionsPayroll.isNotOnlyPayRate()) {
        if (this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.minDate2) {
          minDate = this.m_employeeSectionsPayroll.effectiveDateSetting.minDate2;
        }

        if (this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.maxDate2) {
          maxDate = this.m_employeeSectionsPayroll.effectiveDateSetting.maxDate2;
        }
      }
      if (isValid && minDate && !this.isValidMinDate(date, minDate)) {
        isValid = false;
      }
      if (isValid && maxDate && !this.isValidMaxDate(date, maxDate)) {
        isValid = false;
      }
      if (isValid && this.disabledDate(date)) {
        isValid = false;
      }
    }
    return isValid;
  }

  private isValidMinDate(date: Date, minDate: Date): boolean {
    if (!_.isDate(minDate)) {
      return true;
    }
    const momentDate: moment.Moment = moment(date);
    return moment(minDate).startOf('day').isSameOrBefore(momentDate.startOf('day'));
  }

  private isValidMaxDate(date: Date, maxDate: Date): boolean {
    if (!_.isDate(maxDate)) {
      return true;
    }
    const momentDate: moment.Moment = moment(date);
    return moment(maxDate).startOf('day').isSameOrAfter(momentDate.startOf('day'));
  }

  private addProjectedDates(enabledDates: Date[], startDate: Date, endDate: Date, paycycleInterval: number): Date[] {
    while (moment(startDate) <= moment(endDate)) {
      enabledDates.push(startDate);
      startDate = moment(startDate).add(paycycleInterval, 'days').toDate();
    }
    return enabledDates;
  }

  public payRateChange(value: number): void {
    const payRate: any = this.decimalLimit === 4 ? value.toFixed(4) : value.toFixed(2);
    if (!_.isEqual(this.m_employeeSectionsPayroll.payRate.fieldValue, payRate)) {
      this.m_employeeSectionsPayroll.payRate.fieldValue = payRate;
      this.m_employeeSectionsPayroll.payRate.isDirty = true;
    }
  }

  public setDecimalLimit(isEnableRate4DecimalPlaces: boolean): void {
    if (isEnableRate4DecimalPlaces) {
      this.decimalLimit = 4;
    } else
      this.decimalLimit = 2;
  }
}
