import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[slxChartWidgetMiddleTemplate]'
})
export class ChartWidgetMiddleTemplateDirective {

  @Input()
  public isHidden: boolean;

  constructor(public template: TemplateRef<ChartWidgetMiddleTemplateDirective>) {}
}
