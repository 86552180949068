import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../../framework/index';
import { appConfig } from '../../../../app.config';
import { unsubscribe } from '../../../../core/decorators/index';
import { AttendanceType, ArrivalsDeparturesAnalytics } from '../../../models/index';
import { ArrivalDeparturesManagementService } from '../../../services/index';
import * as _ from 'lodash';
var ArrivalsWidgetComponent = /** @class */ (function () {
    function ArrivalsWidgetComponent(activatedRoute, router, arrivalDeparturesManagementService) {
        this.lateSum = 0;
        this.recordsSum = 0;
        Assert.isNotNull(arrivalDeparturesManagementService, 'budgetManagementService');
        this.arrivalDeparturesManagementService = arrivalDeparturesManagementService;
        this.activatedRoute = activatedRoute;
        this.router = router;
    }
    ArrivalsWidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.loadStatusSubscription = this.arrivalDeparturesManagementService.onLoadStatus$.subscribe(function (val) { _this.isLoading = val; });
        this.loadedSubscription = this.arrivalDeparturesManagementService.onLoaded$.subscribe(function (container) {
            _this.arrivalDeparturesContainer = container;
            _this.recordsSum = container.arrivals ? container.arrivals.length : 0;
            _this.lateSum = _.sumBy(_this.arrivalDeparturesContainer.arrivals, function (item) { return item.late; });
        });
    };
    ArrivalsWidgetComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ArrivalsWidgetComponent.prototype.onInFieldClickHandler = function (arrival) {
        if (arrival.arrivalTime) {
            this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.in, this.dateOn, arrival.arrivalTime);
        }
        else if (!arrival.arrivalTime && arrival.scheduled === 0) {
            this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.unscheduled, this.dateOn);
        }
    };
    ArrivalsWidgetComponent.prototype.onScheduledFieldClickHandler = function (arrival) {
        this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.scheduled, this.dateOn, arrival.arrivalTime);
    };
    ArrivalsWidgetComponent.prototype.onLateFieldClickHandler = function (arrival) {
        this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.late, this.dateOn, arrival.arrivalTime);
    };
    ArrivalsWidgetComponent.prototype.onDifferenceFieldClickHandler = function (arrival) {
        if (arrival.arrivalTime) {
            this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.difference, this.dateOn, arrival.arrivalTime);
        }
        else if (!arrival.arrivalTime && arrival.scheduled === 0) {
            this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.unscheduled, this.dateOn);
        }
    };
    ArrivalsWidgetComponent.prototype.onRowSelectedHandler = function (arrivals) {
        var start = _.first(arrivals);
        var end = _.last(arrivals);
        if (start && end) {
            this.arrivalDeparturesManagementService.onArrivalRangeChanged({ startDate: start.arrivalTime, endDate: end.arrivalTime });
        }
        else {
            this.arrivalDeparturesManagementService.onArrivalRangeChanged(null);
        }
    };
    ArrivalsWidgetComponent.prototype.toggleState = function () {
        this.collapsed = !this.collapsed;
    };
    ArrivalsWidgetComponent.prototype.navigateToArrivalsDeparturesDetails = function (attendanceTypeDefinition, arrivalsDeparturesAnalyticsDefinition, dateOn, time) {
        if (time === void 0) { time = null; }
        var params = undefined;
        if (time) {
            params = {
                date: moment(dateOn).format(appConfig.linkDateFormat),
                time: moment(time).format(appConfig.linkDateTimeFormat),
                attendance_type: attendanceTypeDefinition,
                analytics: arrivalsDeparturesAnalyticsDefinition
            };
        }
        else {
            params = {
                date: moment(dateOn).format(appConfig.linkDateFormat),
                attendance_type: attendanceTypeDefinition,
                analytics: arrivalsDeparturesAnalyticsDefinition
            };
        }
        this.router.navigate(['arrivals_departures_details'], { relativeTo: this.activatedRoute.pathFromRoot[2], queryParams: params });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsWidgetComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsWidgetComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsWidgetComponent.prototype, "orgLevelSubscription", void 0);
    return ArrivalsWidgetComponent;
}());
export { ArrivalsWidgetComponent };
