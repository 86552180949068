export * from './lm-creation-dialog/lm-creation-dialog.component';
export * from './lm-creation-container/lm-creation-container.component';
export * from './lm-create-absence/lm-create-absence.component';
export * from './lm-absence-notes-tab/lm-absence-notes-tab.component';
export * from './lm-absence-exceptions-tab/lm-absence-exceptions-tab.component';
export * from './lm-absence-attachments-tab/lm-absence-attachments-tab.component';

export * from './lm-continuous-tab/lm-continuous-tab.component';
export * from './lm-intermittent-tab/lm-intermittent-tab.component';

import { LMCreationDialogComponent } from './lm-creation-dialog/lm-creation-dialog.component';
import { LMCreationContainerComponent } from './lm-creation-container/lm-creation-container.component';
import { LMCreateAbsenceComponent } from './lm-create-absence/lm-create-absence.component';
import { LMAbsenceNotesTabComponent } from './lm-absence-notes-tab/lm-absence-notes-tab.component';
import { LMAbsenceExceptionsTabComponent } from './lm-absence-exceptions-tab/lm-absence-exceptions-tab.component';
import { LMAbsenceAttachmentsTabComponent } from './lm-absence-attachments-tab/lm-absence-attachments-tab.component';

import { LMContinuousTabComponent } from './lm-continuous-tab/lm-continuous-tab.component';
import { LMIntermittentTabComponent } from './lm-intermittent-tab/lm-intermittent-tab.component';

export const lmCreateAbsenceComponents: any[] = [
  LMCreationDialogComponent,
  LMCreationContainerComponent,
  LMCreateAbsenceComponent,
  LMAbsenceNotesTabComponent,
  LMAbsenceExceptionsTabComponent,
  LMAbsenceAttachmentsTabComponent,
  LMContinuousTabComponent,
  LMIntermittentTabComponent
];
