import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsPhysicals } from '../../../models/index';
import { EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import * as _ from 'lodash';
import { dateTimeUtils } from '../../../../../common/utils/index';
var EmployeeSectionsPhysicalsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPhysicalsComponent, _super);
    function EmployeeSectionsPhysicalsComponent(employeeSectionsPersonalApiService, decorator, ngZone) {
        var _this = _super.call(this, decorator, ngZone) || this;
        Assert.isNotNull(employeeSectionsPersonalApiService, 'employeeSectionsPersonalApiService');
        _this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsPhysicalsComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsPhysicalsComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsPhysicals;
    };
    EmployeeSectionsPhysicalsComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.getPersonalPhysicals(this.employeeId)
            .then(function (employeeSectionsPhysicals) {
            _this.employeeSectionsPhysicals = employeeSectionsPhysicals;
            _this.stopProgress();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsPhysicalsComponent.prototype.doSave = function (effectiveDate) {
        var _this = this;
        this.employeeSectionsPersonalApiService.setPersonalPhysicals(this.employeeId, this.employeeSectionsPhysicals, effectiveDate)
            .then(function (status) {
            _this.onActionComplete(true);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsPhysicalsComponent.prototype.checkTemporalDirty = function () {
        var _this = this;
        if (!this.employeeSectionsPhysicals)
            return null;
        var temporalModel;
        _.forEach(this.employeeSectionsPhysicals.groups, function (group) {
            var tm = _this.metaFieldsTemporalDirtyArrayChecker(group.fields);
            temporalModel = _this.metaConcatTemporalModels(temporalModel, tm);
        });
        return temporalModel;
    };
    EmployeeSectionsPhysicalsComponent.prototype.isValidEffectiveDate = function (date) {
        var isValid = true;
        if (_.isNull(date) || _.isUndefined(date)) {
            isValid = false;
        }
        if (!dateTimeUtils.validateDate(date) && isValid) {
            isValid = false;
        }
        return isValid;
    };
    return EmployeeSectionsPhysicalsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsPhysicalsComponent };
