import { Component, Input, OnInit } from '@angular/core';

import { appConfig, IApplicationConfig } from '../../../../app.config';

import {
  EmployeeDetails
} from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-details',
  templateUrl: 'employee-details.component.html',
  styleUrls: ['employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {

  @Input()
  public employeeDetails: EmployeeDetails;

  @Input()
  public dateOn: Date;

  public appConfig: IApplicationConfig;

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }
}
