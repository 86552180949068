import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DialogOptions2, ModalService, KendoGridStateHelper, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common';
import { NgForm } from '@angular/forms';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { AcaExportCorrection1094CCountContainer } from '../../../models/aca-export/aca-export-correction-1094c-counts';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { AcaExportConfiguration } from '../../../models/aca-export/aca-export-configuration';
import { AcaExportParameter } from '../../../models/aca-export/aca-export-parameter';
import { AcaExportType } from '../../../enums/aca-export-type';
import * as _ from 'lodash';
var AcaExportCorrected1094cCountsComponent = /** @class */ (function () {
    function AcaExportCorrected1094cCountsComponent(modalService, options, manService) {
        this.dialogResult = false;
        this.isLoading = true;
        this.container = new AcaExportCorrection1094CCountContainer();
        this.configuration = new AcaExportConfiguration();
        this.options = options;
        this.modalService = modalService;
        this.manService = manService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
    }
    Object.defineProperty(AcaExportCorrected1094cCountsComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaExportCorrected1094cCountsComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    AcaExportCorrected1094cCountsComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
    };
    AcaExportCorrected1094cCountsComponent.prototype.getAcaExport1094CCountData = function (item, correctionParameters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoading = true;
                        _a = this;
                        return [4 /*yield*/, this.manService.getAcaExport1094CCountData(item, correctionParameters)];
                    case 1:
                        _a.container = _b.sent();
                        this.refreshGrid();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(AcaExportCorrected1094cCountsComponent.prototype, "valid", {
        get: function () {
            var formValid = this.form.invalid ? false : true;
            return formValid;
        },
        enumerable: true,
        configurable: true
    });
    AcaExportCorrected1094cCountsComponent.prototype.onBack = function () {
        this.onCancel();
        var acaExportDialogOptions = new AcaExportDialogOptions();
        acaExportDialogOptions.dialogType = 1;
        acaExportDialogOptions.acaExportCorrectionParameter = this.acaExportCorrectionParameter;
        acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
        acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
        this.manService.openAcaExportDialog(acaExportDialogOptions);
    };
    AcaExportCorrected1094cCountsComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    AcaExportCorrected1094cCountsComponent.prototype.refreshGrid = function () {
        if (!this.container || !this.container.counts) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.container.counts, this.gridState.state);
    };
    AcaExportCorrected1094cCountsComponent.prototype.generate1094cPdf = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('Generate PDF', 'Do you wish to proceed with generating a PDF export?', this.modalService, function (result) {
            if (result) {
                _this.configuration.exportParams = new AcaExportParameter();
                _this.configuration.exportParams.exportType = AcaExportType.ExportPdfCorrected1094C;
                _this.configuration.exportParams.employerId = _this.acaExportExecutionItem.employerId;
                _this.configuration.exportParams.employerName = _this.acaExportExecutionItem.employerName;
                _this.configuration.exportParams.memberOfAleGroup = _.filter(_this.acaExportCorrectionParameter.memberOfAleGroup, function (item) { return (item.isSelected == true); });
                _this.configuration.exportParams.offeredMec = _.filter(_this.acaExportCorrectionParameter.offeredMec, function (item) { return (item.isSelected == true); });
                _this.configuration.exportParams.offerMethod98 = _this.acaExportCorrectionParameter.offerMethod98 ? 'Yes' : 'No';
                _this.configuration.exportParams.qualifiedOfferMethod = _this.acaExportCorrectionParameter.qualifiedOfferMethod ? 'Yes' : 'No';
                _this.configuration.exportParams.receiptId = _this.acaCorrectionType.receiptId;
                _this.configuration.exportParams.year = _this.acaExportExecutionItem.year;
                _this.configuration.exportParams.overrides = _this.container;
                _this.manService.generateAcaExport(_this.configuration.exportParams);
                _this.onCancel();
            }
        }, options);
    };
    AcaExportCorrected1094cCountsComponent.prototype.generate1094cXml = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('Generate Corrected XML', 'Are you sure you wish to generate a corrected XML export according to the parameters you specified?', this.modalService, function (result) {
            if (result) {
                _this.configuration.exportParams = new AcaExportParameter();
                _this.configuration.exportParams.exportType = AcaExportType.ExportXmlCorrected1094C;
                _this.configuration.exportParams.employerId = _this.acaExportExecutionItem.employerId;
                _this.configuration.exportParams.employerName = _this.acaExportExecutionItem.employerName;
                _this.configuration.exportParams.memberOfAleGroup = _.filter(_this.acaExportCorrectionParameter.memberOfAleGroup, function (item) { return (item.isSelected == true); });
                _this.configuration.exportParams.offeredMec = _.filter(_this.acaExportCorrectionParameter.offeredMec, function (item) { return (item.isSelected == true); });
                _this.configuration.exportParams.offerMethod98 = _this.acaExportCorrectionParameter.offerMethod98 ? 'Yes' : 'No';
                _this.configuration.exportParams.qualifiedOfferMethod = _this.acaExportCorrectionParameter.qualifiedOfferMethod ? 'Yes' : 'No';
                _this.configuration.exportParams.receiptId = _this.acaCorrectionType.receiptId;
                _this.configuration.exportParams.year = _this.acaExportExecutionItem.year;
                _this.configuration.exportParams.overrides = _this.container;
                _this.manService.generateAcaExport(_this.configuration.exportParams);
                _this.onCancel();
            }
        }, options);
    };
    return AcaExportCorrected1094cCountsComponent;
}());
export { AcaExportCorrected1094cCountsComponent };
