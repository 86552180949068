/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-console-graphic-comparison.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../schedule-console-graphic-comparison-day/schedule-console-graphic-comparison-day.component.ngfactory";
import * as i3 from "../../../../common/directives/slx-element-created-selector/slx-element-created-selector.directive";
import * as i4 from "../schedule-console-graphic-comparison-day/schedule-console-graphic-comparison-day.component";
import * as i5 from "@angular/common";
import * as i6 from "../schedule-console-graphic-comparison-week/schedule-console-graphic-comparison-week.component.ngfactory";
import * as i7 from "../schedule-console-graphic-comparison-week/schedule-console-graphic-comparison-week.component";
import * as i8 from "./schedule-console-graphic-comparison.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../../common/services/state-management/state-management.service";
import * as i11 from "../../../../common/services/component-state/component-state-storage.service";
var styles_ScheduleConsoleGraphicComparisonComponent = [i0.styles];
var RenderType_ScheduleConsoleGraphicComparisonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleConsoleGraphicComparisonComponent, data: {} });
export { RenderType_ScheduleConsoleGraphicComparisonComponent as RenderType_ScheduleConsoleGraphicComparisonComponent };
function View_ScheduleConsoleGraphicComparisonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "comparison-content__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "slx-schedule-console-graphic-comparison-day", [["class", "comparison-content__body"], ["slxElementCreatedSelector", ""]], null, [[null, "onCreated"], [null, "onClickChart"], [null, "onClickPair1"], [null, "onClickPair2"], [null, "onClickBadgeMessages"], [null, "onClickBadgeOvertime"], [null, "onClickBadgePto"], [null, "onClickBadgeLate"], [null, "onClickWidget"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCreated" === en)) {
        var pd_0 = (_co.onCreated($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClickChart" === en)) {
        var pd_1 = (_co.handleOnClick(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("onClickPair1" === en)) {
        var pd_2 = (_co.handleOnClick(_v.parent.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } if (("onClickPair2" === en)) {
        var pd_3 = (_co.handleOnClick(_v.parent.context.$implicit) !== false);
        ad = (pd_3 && ad);
    } if (("onClickBadgeMessages" === en)) {
        var pd_4 = (_co.handleOnClick(_v.parent.context.$implicit) !== false);
        ad = (pd_4 && ad);
    } if (("onClickBadgeOvertime" === en)) {
        var pd_5 = (_co.handleOnClick(_v.parent.context.$implicit) !== false);
        ad = (pd_5 && ad);
    } if (("onClickBadgePto" === en)) {
        var pd_6 = (_co.handleBadgePtoClick(_v.parent.context.$implicit) !== false);
        ad = (pd_6 && ad);
    } if (("onClickBadgeLate" === en)) {
        var pd_7 = (_co.handleOnClick(_v.parent.context.$implicit) !== false);
        ad = (pd_7 && ad);
    } if (("onClickWidget" === en)) {
        var pd_8 = (_co.handleOnClick(_v.parent.context.$implicit) !== false);
        ad = (pd_8 && ad);
    } return ad; }, i2.View_ScheduleConsoleGraphicComparisonDayComponent_0, i2.RenderType_ScheduleConsoleGraphicComparisonDayComponent)), i1.ɵdid(4, 4210688, null, 0, i3.SlxElementCreatedSelectorDirective, [i1.ElementRef, i1.Renderer2], { emitEvent: [0, "emitEvent"] }, { onCreated: "onCreated" }), i1.ɵdid(5, 49152, null, 0, i4.ScheduleConsoleGraphicComparisonDayComponent, [], { comparison: [0, "comparison"], widgetConfig: [1, "widgetConfig"], chartInput: [2, "chartInput"], chart: [3, "chart"] }, { onClickChart: "onClickChart", onClickWidget: "onClickWidget", onClickPair1: "onClickPair1", onClickPair2: "onClickPair2", onClickBadgeMessages: "onClickBadgeMessages", onClickBadgeOvertime: "onClickBadgeOvertime", onClickBadgePto: "onClickBadgePto", onClickBadgeLate: "onClickBadgeLate" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.itWasClicked(_v.parent.context.$implicit); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit; var currVal_3 = _co.widgetConfig; var currVal_4 = _co.getChartInput(_v.parent.context.$implicit); var currVal_5 = _co.chart; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.comparisonName; _ck(_v, 2, 0, currVal_0); }); }
function View_ScheduleConsoleGraphicComparisonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleGraphicComparisonComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.isEmpty; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScheduleConsoleGraphicComparisonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleGraphicComparisonComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.comparisons; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScheduleConsoleGraphicComparisonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-schedule-console-graphic-comparison-week", [], null, [[null, "onClickChart"], [null, "onClickPair1"], [null, "onClickPair2"], [null, "onClickBadgeMessages"], [null, "onClickBadgeOvertime"], [null, "onClickBadgePto"], [null, "onClickBadgeLate"], [null, "onClickWidget"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClickChart" === en)) {
        var pd_0 = (_co.handleOnClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("onClickPair1" === en)) {
        var pd_1 = (_co.handleOnClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("onClickPair2" === en)) {
        var pd_2 = (_co.handleOnClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("onClickBadgeMessages" === en)) {
        var pd_3 = (_co.handleOnClick($event) !== false);
        ad = (pd_3 && ad);
    } if (("onClickBadgeOvertime" === en)) {
        var pd_4 = (_co.handleOnClick($event) !== false);
        ad = (pd_4 && ad);
    } if (("onClickBadgePto" === en)) {
        var pd_5 = (_co.handleBadgePtoClick($event) !== false);
        ad = (pd_5 && ad);
    } if (("onClickBadgeLate" === en)) {
        var pd_6 = (_co.handleOnClick($event) !== false);
        ad = (pd_6 && ad);
    } if (("onClickWidget" === en)) {
        var pd_7 = (_co.handleOnClick($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, i6.View_ScheduleConsoleGraphicComparisonWeekComponent_0, i6.RenderType_ScheduleConsoleGraphicComparisonWeekComponent)), i1.ɵdid(1, 49152, null, 0, i7.ScheduleConsoleGraphicComparisonWeekComponent, [], { comparisons: [0, "comparisons"], widgetConfig: [1, "widgetConfig"], chart: [2, "chart"], consoleConfig: [3, "consoleConfig"] }, { onClickWidget: "onClickWidget", onClickChart: "onClickChart", onClickPair1: "onClickPair1", onClickPair2: "onClickPair2", onClickBadgeMessages: "onClickBadgeMessages", onClickBadgeOvertime: "onClickBadgeOvertime", onClickBadgePto: "onClickBadgePto", onClickBadgeLate: "onClickBadgeLate" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.comparisons; var currVal_1 = _co.widgetConfig; var currVal_2 = _co.chart; var currVal_3 = _co.consoleConfig; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ScheduleConsoleGraphicComparisonComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_ScheduleConsoleGraphicComparisonComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "comparison-content"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "plate": 0 }), i1.ɵdid(4, 16384, null, 0, i5.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleGraphicComparisonComponent_1)), i1.ɵdid(6, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleGraphicComparisonComponent_4)), i1.ɵdid(8, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleGraphicComparisonComponent_5)), i1.ɵdid(10, 16384, null, 0, i5.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "comparison-content"; var currVal_1 = _ck(_v, 3, 0, (_co.rangeType === "Day")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.rangeType; _ck(_v, 4, 0, currVal_2); var currVal_3 = "Day"; _ck(_v, 6, 0, currVal_3); var currVal_4 = "Week"; _ck(_v, 8, 0, currVal_4); }, null); }
export function View_ScheduleConsoleGraphicComparisonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-schedule-console-graphic-comparison", [], null, null, null, View_ScheduleConsoleGraphicComparisonComponent_0, RenderType_ScheduleConsoleGraphicComparisonComponent)), i1.ɵdid(1, 49152, null, 0, i8.ScheduleConsoleGraphicComparisonComponent, [i9.Router, i9.ActivatedRoute, i10.StateManagementService, i11.ComponentStateStorageService], null, null)], null, null); }
var ScheduleConsoleGraphicComparisonComponentNgFactory = i1.ɵccf("slx-schedule-console-graphic-comparison", i8.ScheduleConsoleGraphicComparisonComponent, View_ScheduleConsoleGraphicComparisonComponent_Host_0, { comparisons: "comparisons", comparisonName: "comparisonName", consoleConfig: "consoleConfig", chart: "chart", rangeType: "rangeType" }, { onNeedScroll: "onNeedScroll" }, []);
export { ScheduleConsoleGraphicComparisonComponentNgFactory as ScheduleConsoleGraphicComparisonComponentNgFactory };
