import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
var ArrivalsGridComponent = /** @class */ (function () {
    function ArrivalsGridComponent() {
        this.onInFieldClick = new EventEmitter();
        this.onScheduledFieldClick = new EventEmitter();
        this.onLateFieldClick = new EventEmitter();
        this.onDifferenceFieldClick = new EventEmitter();
        this.onRowSelected = new EventEmitter();
    }
    Object.defineProperty(ArrivalsGridComponent.prototype, "records", {
        set: function (data) {
            if (data) {
                this.gridView = {
                    data: data,
                    total: data.length
                };
            }
        },
        enumerable: true,
        configurable: true
    });
    ArrivalsGridComponent.prototype.selectionChange = function (selection) {
        var lastRow = _.maxBy(selection.selectedRows, function (r) { return r.index; });
        if (lastRow) {
            var records = _.slice(this.gridView.data, 0, lastRow.index + 1);
            var filtered = _.filter(records, function (r) { return !!r.arrivalTime; });
            this.onRowSelected.emit(filtered);
        }
        else {
            this.onRowSelected.emit([]);
        }
    };
    ArrivalsGridComponent.prototype.onInFieldClickHandler = function (arrival) {
        this.onInFieldClick.emit(arrival);
    };
    ArrivalsGridComponent.prototype.onScheduledFieldClickHandler = function (arrival) {
        this.onScheduledFieldClick.emit(arrival);
    };
    ArrivalsGridComponent.prototype.onLateFieldClickHandler = function (arrival) {
        this.onLateFieldClick.emit(arrival);
    };
    ArrivalsGridComponent.prototype.onDifferencceFieldClickHandler = function (arrival) {
        this.onDifferenceFieldClick.emit(arrival);
    };
    return ArrivalsGridComponent;
}());
export { ArrivalsGridComponent };
