import * as _ from 'lodash';
import * as moment from 'moment';
import { OnDestroy, OnChanges, EventEmitter } from '@angular/core';
import { dateTimeUtils } from '../../../../../common/utils/index';
import { appConfig } from '../../../../../app.config';
import { DateRange } from '../../../../../core/models/index';
import { LoaMappedRequest } from '../../../models/index';
var AbsenceDay = /** @class */ (function () {
    function AbsenceDay(day, left, right, cssClass, hasBorder) {
        if (day === void 0) { day = null; }
        if (left === void 0) { left = ''; }
        if (right === void 0) { right = ''; }
        if (cssClass === void 0) { cssClass = ''; }
        if (hasBorder === void 0) { hasBorder = true; }
        this.day = day;
        this.left = left;
        this.right = right;
        this.cssClass = cssClass;
        this.hasBorder = hasBorder;
    }
    Object.defineProperty(AbsenceDay.prototype, "isEmpty", {
        get: function () {
            return this.left === '' && this.right === '';
        },
        enumerable: true,
        configurable: true
    });
    return AbsenceDay;
}());
export { AbsenceDay };
var LMScheduleLineComponent = /** @class */ (function () {
    function LMScheduleLineComponent() {
        this.dateRange = new DateRange(null, null);
        this.daysLength = 1;
        this.disableToClickOnDay = false;
        this.clickOnDay = new EventEmitter();
        this.weekDays = [];
        this.appConfig = appConfig;
        this.subscriptions = {};
        this.dayMs = 1000 * 60 * 60 * 24;
    }
    LMScheduleLineComponent.prototype.ngOnChanges = function () {
        var hasDaysLength = _.isFinite(this.daysLength);
        var hasRange = _.isObjectLike(this.dateRange) && _.isDate(this.dateRange.startDate);
        var hasRequest = _.isObjectLike(this.loaRequest);
        if (hasDaysLength && hasRange && hasRequest) {
            this.renderScheduleLine(this.dateRange.startDate, this.daysLength, this.loaRequest);
        }
    };
    LMScheduleLineComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMScheduleLineComponent.prototype.onDayClick = function (day) {
        var isClickable = this.isClickable(day);
        if (isClickable) {
            var date = _.head(day).day.startDate;
            this.clickOnDay.emit(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
        }
    };
    LMScheduleLineComponent.prototype.isClickable = function (day) {
        return !_.every(day, function (piece) { return piece.isEmpty; }) && !this.disableToClickOnDay;
    };
    LMScheduleLineComponent.prototype.getBorder = function (days) {
        var noBorder = _.some(days, function (d) { return !d.hasBorder; });
        return noBorder ? '0px' : '';
    };
    LMScheduleLineComponent.prototype.renderScheduleLine = function (sDate, daysLength, loaRequest) {
        var weekDays = this.getWeekDays(sDate, daysLength);
        var absenceDays = this.getAbsenceDays(sDate, daysLength, loaRequest);
        this.weekDays = this.combineWeekAndAbsenceDays(weekDays, absenceDays);
    };
    LMScheduleLineComponent.prototype.getAbsenceDays = function (sDate, length, req) {
        if (_.size(req.absenceDays) > 0) {
            var startDateTime_1 = dateTimeUtils.copyDate(sDate).setHours(0, 0, 0, 0);
            var counter_1 = 1;
            var absenceDaysWithinRange = _.reduce(req.absenceDays, function (accum, days, startDayMs) {
                var _a;
                var sDayMs = +startDayMs;
                var absenceEndDayMs = new Date(sDayMs).setHours(23, 59, 59, 999);
                if ((sDayMs >= startDateTime_1 || absenceEndDayMs >= startDateTime_1) && counter_1 <= length) {
                    (_a = (accum[sDayMs] = accum[sDayMs] || [])).push.apply(_a, days);
                    counter_1++;
                }
                return accum;
            }, {});
            return _.size(_.values(absenceDaysWithinRange)) > 0 ? absenceDaysWithinRange : {};
        }
        return {};
    };
    LMScheduleLineComponent.prototype.combineWeekAndAbsenceDays = function (weekDays, absenceDays) {
        var _this = this;
        return _.reduce(weekDays, function (accum, weekDay, dayIndex) {
            var weekDayMs = String(weekDay.startDate.getTime());
            var absenceDay = absenceDays[weekDayMs];
            if (_.isObjectLike(absenceDay)) {
                var prevAbsenceDay = dayIndex > 0 ? accum[dayIndex - 1] : [];
                accum.push(_this.getAbsenceDay(absenceDay, prevAbsenceDay));
            }
            else {
                accum.push([new AbsenceDay()]);
            }
            return accum;
        }, []);
    };
    LMScheduleLineComponent.prototype.getAbsenceDay = function (absenceDay, prevAbsenceDay) {
        var _this = this;
        var prevLastAbsencePiece = _.last(prevAbsenceDay) || new AbsenceDay();
        return _.map(absenceDay, function (r) {
            var startDate = r.startDate, endDate = r.endDate;
            var _a = _this.getMinMaxOfDay(startDate), min = _a.startDate, max = _a.endDate;
            var cssClasses = '';
            var start = parseFloat(((dateTimeUtils.getTime(startDate) - dateTimeUtils.getTime(min)) * 100 / _this.dayMs).toFixed(2));
            var end = parseFloat(((dateTimeUtils.getTime(endDate) - dateTimeUtils.getTime(max)) * 100 / _this.dayMs).toFixed(2));
            var currentLeft = Math.abs(start) + "%";
            var currentRight = Math.abs(end) + "%";
            var hasBorder = true;
            if (currentRight === '0%') {
                hasBorder = false;
            }
            if (prevLastAbsencePiece.right === '0%' && currentLeft === '0%') {
                cssClasses = 'no-left';
                prevLastAbsencePiece.cssClass += ' no-right';
            }
            return new AbsenceDay(r, currentLeft, currentRight, cssClasses, hasBorder);
        });
    };
    LMScheduleLineComponent.prototype.getWeekDays = function (startDate, daysLength) {
        var weekDays = [];
        var prevDate = null;
        for (var i = 1; daysLength >= i; i++) {
            var date = null;
            if (i === 1) {
                date = this.getMinMaxOfDay(startDate);
            }
            else {
                date = this.getMinMaxOfDay(dateTimeUtils.getTime(prevDate.endDate) + this.dayMs);
            }
            prevDate = date;
            weekDays.push(date);
        }
        return weekDays;
    };
    LMScheduleLineComponent.prototype.getMinMaxOfDay = function (date) {
        var d = moment(dateTimeUtils.copyDate(date));
        return new DateRange(d.startOf('day').toDate(), d.endOf('day').toDate());
    };
    return LMScheduleLineComponent;
}());
export { LMScheduleLineComponent };
