import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { MasterScheduleDayInfo, MasterScheduleShiftInfo } from '../../../models/index';
@Component({
  moduleId: module.id,
  selector: 'slx-master-schedule-day-info',
  templateUrl: 'master-schedule-day-info.component.html',
  styleUrls: ['master-schedule-day-info.component.scss']
})
export class MasterScheduleDayInfoComponent implements OnChanges {

  @Input()
  public req: MasterScheduleDayInfo;

  public startOfWeek: Date;
  public endOfWeek: Date;
  public appConfig: IApplicationConfig;

  constructor() {
    this.appConfig = appConfig;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['req']) {
      this.startOfWeek = moment(this.req.dayCell.dateOn).startOf('week').toDate();
      this.endOfWeek = moment(this.req.dayCell.dateOn).endOf('week').toDate();
    }
  }

  public getTimeString(shiftInfo: MasterScheduleShiftInfo): string {
    let startStr = moment(shiftInfo.shiftStart).format(appConfig.timeFormat);
    let endStr = moment(shiftInfo.shiftEnd).format(appConfig.timeFormat);
    return `${startStr}-${endStr}`;
  }
}
