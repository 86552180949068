import * as _ from 'lodash';
import { PmManagementService } from './pm-management.service';
import { PmReviewRecord, PmReviewRecords } from './../models/pm-review-records';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from './../../../framework/assert/assert';
import { DateRange, IDateRange } from '../../../core/models/index';
import { Injectable } from '@angular/core';
import { PerformanceManagementApiService } from './performance-management-api.service';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { unsubscribeAll } from '../../../core/decorators/index';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeRosterRecord } from '../models';
import { Employee } from '../../../organization/models';

@Injectable()
export class PmEmployeeRosterManagementService {

  constructor(
    private api: PerformanceManagementApiService,
    private manService: PmManagementService
  ) { }

  private loading$ = new Subject<boolean>();
  private dataLoaded$ = new ReplaySubject<PmReviewRecords<EmployeeRosterRecord>>();
  private recordsLoaded$ = new Subject<PmReviewRecord[]>();
  private dateRange$ = new Subject<DateRange>();
  private exportTo$ = new Subject<boolean>();
  private createNewReview$ = new Subject<boolean>();

  private dateRange: IDateRange;

  private currentEmpId: number;
  private employee: Employee;

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};


  public init(): void {
    this.manService.subscribeToLoadReviews(() => {
      this.internalReloadRoster();
    })
    this.internalReloadRoster();
  }

  public setEmployeeId(employeeId: number): void {
    this.currentEmpId = employeeId;
    this.internalReloadRoster();
  }


  public destroy(): void {
    this.loading$.complete();
    this.dataLoaded$.complete();
    this.recordsLoaded$.complete();
    this.dateRange$.complete();
    this.exportTo$.complete();
    this.createNewReview$.complete();
  }


  public async loadEmployeeReviews(empId: number, start: Date, end: Date): Promise<void> {
    this.loading$.next(true);
    let container = await this.api.getEmployeeReviews(empId, start, end);
    this.employee = container.employee;
    this.createNewReview$.next(container.canCreateNewReview);
    this.dataLoaded$.next(container);
    this.loading$.next(false);
  }

  public async reloadRoster(): Promise<void> {
    this.internalReloadRoster();
  }

  public getDefaultDateRange(): IDateRange {
    const d = new Date();
    return new DateRange(new Date(1900, 1, 1), d);
  }

  public subscribeToCanCreateNewReview(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.createNewReview$.subscribe(callback);
  }

  public openReviewPopup(review: PmReviewRecord): void {
    const title = `${_.isObjectLike(review) ? 'Edit' : 'Create New'} Review`;
    if (review) {
      review.employee = this.employee || review.employee;
      this.manService.openReviewPopup(title, review);
    } else {
      this.manService.openEmployeNewReviewPopup(title, this.employee)
    }
  }



  public changeDateRange(r: IDateRange): void {
    this.dateRange = r;
    this.dateRange$.next(r);
    if (_.isNumber(this.currentEmpId)) {
      this.loadEmployeeReviews(this.currentEmpId, this.dateRange.startDate, this.dateRange.endDate);
    }
  }

  public subscribeToDateRange(callback: (r: DateRange) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.dateRange$.subscribe(callback);
  }

  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }

  public subscribeToExport(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.exportTo$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (b: PmReviewRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recordsLoaded$.subscribe(callback);
  }

  public subscribeToDataLoaded(callback: (c: PmReviewRecords<EmployeeRosterRecord>) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.dataLoaded$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  private internalReloadRoster(): void {
    if (this.currentEmpId) {
     this.loadEmployeeReviews(this.currentEmpId, null, null);
    }
  }
}
