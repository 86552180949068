import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';

@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-date-filter',
  templateUrl: 'kendo-grid-date-filter.component.html',
  styleUrls: ['kendo-grid-date-filter.component.scss'],
})
export class KendoGridDateFilterComponent extends KendoGridBasicFilterComponent {

  @Input()
  public showOperators: boolean;
  @Input()
  public column: ColumnComponent;
  @Input()
  public filter: CompositeFilterDescriptor;
  @Input()
  public operator: string;




  constructor(filterService: FilterService, localization: LocalizationService) {
    super(filterService, localization);

    let slxFilters: string[] = _.map(kendoUtils.slxDateFilters, (filter: kendoUtils.slxFilter) => { return filter.key; });
    let slxFiltersKeysMap: StringMap<string> = _.keyBy(slxFilters, (key: string) => key);
    let dateOperators: any = this.localizeOperators(
      Object.assign(slxFiltersKeysMap, {
        'filterIsNullOperator': 'isnull',
        'filterIsNotNullOperator': 'isnotnull'
      }));
    this.showOperators = true;
    this.operator = 'slxFilterDate';
    this.defaultOperators = dateOperators(this.localization);
  }
}
