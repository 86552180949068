<kendo-grid [data]="gridState.view" scrollable="none"  [selectable]="false" (cancel)="gridState.cancelHandler($event)" (edit)="gridState.editHandler($event)"
  (remove)="gridState.removeHandler($event)" (save)="gridState.saveHandler($event)">

  <kendo-grid-command-column title="Сommand" width="100">
    <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex">
      <div class="flex-horizontal">
        <button kendoGridEditCommand type="button" [disabled]="isEditMode" class="theme-icon-button theme-inline-edit-button">
          <i class="fas fa-pencil-alt" aria-hidden="true"></i>
         </button>
        <button kendoGridRemoveCommand type="button" [disabled]="isEditMode" class="theme-icon-button theme-inline-apply-button">
           <i class="fa fa-times" aria-hidden="true"></i>
         </button>
        <button kendoGridSaveCommand type="button" [disabled]="!restrictionChanged" class="theme-icon-button theme-inline-apply-button">
          <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
         </button>
        <button kendoGridCancelCommand type="button" class="theme-icon-button theme-inline-cancel-button">
          <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
         </button>
      </div>
    </ng-template>
  </kendo-grid-command-column>

  <kendo-grid-column title="Id" field="timeclock.id" width="100" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      Id
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.timeclock.id}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Timeclock" field="timeclock.name">
    <ng-template kendoGridHeaderTemplate>
      Timeclock
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.timeclock.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Restriction" field="restriction.name" width="350">
    <ng-template kendoGridHeaderTemplate>
      Restriction
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.restriction.name}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      <slx-input-decorator>
        <slx-dropdown-input slx-input
          lookup="timeclockRestrictionDefinition"
          name="restrictions"
          placeholder="Restriction"
          [(ngModel)]="dataItem.restriction"
          (ngModelChange)="onRestrictionChanged(dataItem.restriction)"
        ></slx-dropdown-input>
      </slx-input-decorator>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>
