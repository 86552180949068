import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { IControlState, StateResetTypes } from '../../../core/models/index';
import { ComponentStateStorageService, StateManagementService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';
var ControlInitStateDirective = /** @class */ (function () {
    function ControlInitStateDirective(ngControl, storageService, stateManagement) {
        this.ngControl = ngControl;
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.accessor = ngControl.valueAccessor;
        this.m_resetType = StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
    }
    Object.defineProperty(ControlInitStateDirective.prototype, "resetType", {
        set: function (type) {
            if (!type) {
                return;
            }
            this.m_resetType = type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlInitStateDirective.prototype, "controlId", {
        get: function () {
            return this.m_controlId;
        },
        enumerable: true,
        configurable: true
    });
    ControlInitStateDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.m_controlId = this.ngControl.name;
        if (!this.m_controlId) {
            throw Error('State directive can be used only with control which has a name');
        }
        if (this.restoreManually) {
            this.state = {};
            this.valueChangesSubscription = this.ngControl.valueChanges.subscribe(function (x) {
                _this.valueChanged(x);
            });
        }
        this.initSubscription = this.stateManagement.onInit$.subscribe(function () {
            scheduleMicrotask(function () { _this.loadState(); });
        });
    };
    ControlInitStateDirective.prototype.valueChanged = function (val) {
        this.storageService.mapValueToState(val, this.state);
        this.storageService.setControlState(this.stateManagement.componentKey, this.controlId, this.state, this.m_resetType);
    };
    ControlInitStateDirective.prototype.loadState = function () {
        var _this = this;
        if (this.restoreManually) {
            return;
        }
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
        this.state = this.storageService.getControlState(this.stateManagement.componentKey, this.controlId);
        if (this.state.value !== undefined) {
            var value = this.storageService.mapValueFromState(this.state);
            this.accessor.writeValue(value);
            this.ngControl.viewToModelUpdate(value);
        }
        this.valueChangesSubscription = this.ngControl.valueChanges.subscribe(function (x) {
            _this.valueChanged(x);
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ControlInitStateDirective.prototype, "initSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ControlInitStateDirective.prototype, "valueChangesSubscription", void 0);
    return ControlInitStateDirective;
}());
export { ControlInitStateDirective };
