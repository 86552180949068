import * as tslib_1 from "tslib";
import { statusCheck } from './../../../models/wfm-sync';
import { OnInit } from '@angular/core';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
import { process } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { appConfig } from '../../../../app.config';
import { NotificationsService } from '../../../../../app/core/components/index';
import * as moment from 'moment';
import { KendoGridStateHelper } from '../../../../common/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var WfmDailytransactionReportComponent = /** @class */ (function () {
    function WfmDailytransactionReportComponent(wfmsyncService, notificationService) {
        this.wfmsyncService = wfmsyncService;
        this.notificationService = notificationService;
        this.dataView = [];
        this.isLoading = false;
        this.endDate = '';
        this.startDate = '';
        this.dateError = false;
        this.endDateValue = new Date();
        this.today = new Date();
        this.month = (this.endDateValue.getMonth());
        this.day = (this.endDateValue.getDay());
        this.year = (this.endDateValue.getFullYear());
        this.previousMonth = (this.month === 0) ? 11 : this.month - 1;
        this.previousYear = (this.month === 0) ? this.year - 1 : this.year;
        // public startDateValue: Date = new Date(this.previousYear, this.previousMonth, this.endDateValue.getDate()+1, 0, 0, 0, 0);
        this.startDateValue = moment(new Date()).subtract(31, 'days').toDate();
        this.isActiveOnly = false;
        this.actualData = [];
        this.filterData = [];
        this.disable = false;
        this.pageSize = 50;
        this.allData = this.allData.bind(this);
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
    }
    WfmDailytransactionReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.isLoading = false;
        this.startDate = this.startDateValue ? dateTimeUtils.convertToDtoString(this.startDateValue) : '';
        this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';
        this.res();
        this.refreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    WfmDailytransactionReportComponent.prototype.res = function () {
        var _this = this;
        this.isLoading = true;
        this.wfmsyncService.dailyTransactionReport(this.startDate, this.endDate).then(function (value2) {
            _this.dataView = value2.data;
            _this.actualData = statusCheck(value2.data);
            _this.setDefaultData(_this.isActiveOnly);
            _this.isLoading = false;
        }).catch(function () {
            _this.isLoading = false;
        });
    };
    WfmDailytransactionReportComponent.prototype.exportToExcel = function (grid) {
        grid.saveAsExcel();
    };
    WfmDailytransactionReportComponent.prototype.onChangeStartDate = function (dateSent) {
        this.dateError = false;
        this.startDateMessage = '';
        var value = Math.floor((Date.UTC(this.endDateValue.getFullYear(), this.endDateValue.getMonth(), this.endDateValue.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
        if (value >= 31 || value < 0) {
            this.dateError = true;
            this.disable = true;
            this.startDateMessage = 'Difference between Start date and End date should not be greater than one month';
        }
        else {
            this.disable = false;
        }
        this.startDateValue = dateSent;
        this.startDate = this.startDateValue ? dateTimeUtils.convertToDtoString(this.startDateValue) : '';
        this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';
    };
    WfmDailytransactionReportComponent.prototype.onEndDateChange = function (dateSent) {
        this.dateError = false;
        this.endDateMessage = '';
        var value = Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(this.startDateValue.getFullYear(), this.startDateValue.getMonth(), this.startDateValue.getDate())) / (1000 * 60 * 60 * 24));
        if (value >= 31 || value < 0) {
            this.dateError = true;
            this.disable = true;
            this.endDateMessage = 'Difference between Start date and End date should not be greater than one month';
        }
        else {
            this.disable = false;
        }
        this.endDateValue = dateSent;
        this.startDate = this.startDate;
        this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';
    };
    WfmDailytransactionReportComponent.prototype.apply = function () {
        this.res();
    };
    WfmDailytransactionReportComponent.prototype.allData = function () {
        var result = {
            data: process(this.dataView, {
                sort: [{ field: 'transactionId', dir: 'asc' }],
            }).data
        };
        return result;
    };
    WfmDailytransactionReportComponent.prototype.handleButtonClick = function (dataItem) {
        var _this = this;
        this.isLoading = true;
        dataItem.reSyncAction = "RETRY";
        this.wfmsyncService.resync(dataItem).then(function (value2) {
            _this.isLoading = false;
            _this.notificationService.success('Re-submit', 'successful');
            _this.res();
        }).catch(function () {
            _this.isLoading = false;
        });
    };
    WfmDailytransactionReportComponent.prototype.click = function (dataItem) {
        var _this = this;
        this.isLoading = true;
        dataItem.reSyncAction = "ACCEPT";
        this.wfmsyncService.resync(dataItem).then(function (value2) {
            _this.isLoading = false;
            _this.notificationService.success('Accept', 'successful');
            _this.res();
        }).catch(function () {
            _this.isLoading = false;
        });
    };
    WfmDailytransactionReportComponent.prototype.onDisplaySettingsChanged = function (e) {
        // let pageevent = {skip:0, take:50};
        // this.pageChange(pageevent);
        this.setDefaultData(e);
    };
    WfmDailytransactionReportComponent.prototype.setDefaultData = function (isActiveOnly) {
        var filterData = ["FAILED", "QUEUED", "", "NULL", "RESUBMITTED", "PENDING", "EVENT PAUSED", "EVENT STOPPED", "UN KNOWN", "ACCEPTED"];
        this.isActiveOnly = isActiveOnly;
        var onlyInsert = _.filter(this.actualData, function (item) { return filterData.includes(item.transactionStatus); });
        this.dataView = this.isActiveOnly ? this.actualData : onlyInsert;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.refreshGrid();
    };
    WfmDailytransactionReportComponent.prototype.refreshGrid = function () {
        if (!this.dataView) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.dataView, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], WfmDailytransactionReportComponent.prototype, "refreshSubscription", void 0);
    return WfmDailytransactionReportComponent;
}());
export { WfmDailytransactionReportComponent };
