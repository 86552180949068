import { Injectable } from '@angular/core';
import { BenefitDetailsBasic } from '../../models/index';

@Injectable()
export class CalculationCommonService {

  public getMonthly(employeeContFrequency: string, employeeContribution: number) {
    let convertedEmployeeContribution = 0;
    switch (employeeContFrequency) {
      case 'Monthly':
        convertedEmployeeContribution = employeeContribution;
        break;
      case 'Weekly':
        convertedEmployeeContribution = (employeeContribution * 52) / 12;
        break;
      case 'BiWeekly':
        convertedEmployeeContribution = (employeeContribution * 26) / 12;
        break;
      case 'SemiMonthly':
        convertedEmployeeContribution = employeeContribution * 2;
        break;
    }
    return convertedEmployeeContribution;
  }

  public getSemiMonthly(employeeContFrequency: string, employeeContribution: number) {
    let convertedEmployeeContribution = 0;
    switch (employeeContFrequency) {
      case 'Monthly':
        convertedEmployeeContribution = (employeeContribution / 2);
        break;
      case 'Weekly':
        convertedEmployeeContribution = (employeeContribution * 52) / 24;
        break;
      case 'BiWeekly':
        convertedEmployeeContribution = (employeeContribution * 26) / 24;
        break;
      case 'SemiMonthly':
        convertedEmployeeContribution = employeeContribution;
        break;
    }
    return convertedEmployeeContribution;
  }

  public getBiWeekly(employeeContFrequency: string, employeeContribution: number) {
    let convertedEmployeeContribution = 0;
    switch (employeeContFrequency) {
      case 'Monthly':
        convertedEmployeeContribution = (employeeContribution * 12) / 26;
        break;
      case 'Weekly':
        convertedEmployeeContribution = (employeeContribution * 2);
        break;
      case 'BiWeekly':
        convertedEmployeeContribution = employeeContribution;
        break;
      case 'SemiMonthly':
        convertedEmployeeContribution = (employeeContribution * 24) / 26;
        break;
    }
    return convertedEmployeeContribution;
  }

  public getWeekly(employeeContFrequency: string, employeeContribution: number) {
    let convertedEmployeeContribution = 0;
    switch (employeeContFrequency) {
      case 'Monthly':
        convertedEmployeeContribution = (employeeContribution * 12) / 52;
        break;
      case 'Weekly':
        convertedEmployeeContribution = employeeContribution;
        break;
      case 'BiWeekly':
        convertedEmployeeContribution = (employeeContribution / 2);
        break;
      case 'SemiMonthly':
        convertedEmployeeContribution = (employeeContribution * 24) / 52;
        break;
    }
    return convertedEmployeeContribution;
  }

  public getConvertedEmployeeContribution(costFrequency: BenefitDetailsBasic<number, string>, employeeContFrequency: BenefitDetailsBasic<number, string>, employeeContribution: number): number {

    let finalValue = 0;
    if (costFrequency) {
      switch (costFrequency.name) {
        case 'Monthly':
          finalValue = this.getMonthly(employeeContFrequency.name, employeeContribution);
          break;
        case 'Weekly':
          finalValue = this.getWeekly(employeeContFrequency.name, employeeContribution);
          break;
        case 'BiWeekly':
          finalValue = this.getBiWeekly(employeeContFrequency.name, employeeContribution);
          break;
        case 'SemiMonthly':
          finalValue = this.getSemiMonthly(employeeContFrequency.name, employeeContribution);
          break;
      }
    }
    return parseFloat(finalValue.toFixed(2));
  }
}
