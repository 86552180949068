import { OnInit, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';

import { UserProfileManagementService } from '../../../services/index';
import { UserProfileSectionType } from '../../../models/users/models/user-profile-section-type';

export abstract class UserProfileSectionBaseComponent implements OnInit, OnDestroy {

    public get isValid(): boolean {
        return false;
    }

    public get type(): UserProfileSectionType {
        return this.m_type;
    }

    public state: {
        isEditMode: boolean;
        isLoading: boolean;
    };

    @unsubscribe()
    public enterEditStateSubscription: Subscription;
    @unsubscribe()
    public sectionDiscardSubscription: Subscription;
    @unsubscribe()
    public sectionSaveSubscription: Subscription;
    @unsubscribe()
    public sectionSavedSubscription: Subscription;
    @unsubscribe()
    public sectionErrorSubscription: Subscription;

    protected management: UserProfileManagementService;
    protected m_type: UserProfileSectionType;

    constructor(management: UserProfileManagementService) {
        this.management = management;
    }

    public ngOnInit(): void {

        if (!this.state) {
            this.state = {
                isEditMode: false,
                isLoading: false
            };
        }

        this.enterEditStateSubscription = this.management.onSectionEnteredEditState$.subscribe((section: UserProfileSectionBaseComponent) => {
            if (this === section) {
                this.state.isEditMode = true;
            }
        });

        this.sectionDiscardSubscription = this.management.onSectionDiscarded$.subscribe((section: UserProfileSectionBaseComponent) => {
            if (this === section) {
                this.discardChanges();
            }
        });

        this.sectionSaveSubscription = this.management.onSectionSave$.subscribe((section: UserProfileSectionBaseComponent) => {
            if (section === this) {
                this.state.isLoading = true;
            }
        });

        this.sectionSavedSubscription = this.management.onSectionSaved$.subscribe((section: UserProfileSectionBaseComponent) => {
            if (this === section) {
                this.onChangesSaved();
            }
        });

        this.sectionErrorSubscription = this.management.onSectionServiceError$.subscribe((type: UserProfileSectionType) => {
            if (type === this.type) {
                this.state.isLoading = false;
            }
        });
    }

    public ngOnDestroy(): void {
      // See #issueWithAOTCompiler
    }

    public abstract onChangesSaved(): void;
    public abstract discardChanges(): void;
    public abstract getSaveData(): any;
}

