import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, QueryList, AfterViewInit } from '@angular/core';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitDetailsTier, BenefitEnrolledEmployee } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/benefit-employees/index';
import { NgForm, NgModel } from '@angular/forms';
var BenefitOptionFlatGridComponent = /** @class */ (function () {
    function BenefitOptionFlatGridComponent(manService) {
        this.manService = manService;
        this.columnGroup = 'BenefitOptionFlatGrid';
        this.isLoading = false;
        this.format = 'c2';
        this.minAmt = 0;
        this.maxAmt = 99999999.99;
        this.pageSize = 50;
        this.subscriptions = {};
        this.invalidEnrollments = new Set();
        this.isFormValid = true;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.selectableSettings = {
            checkboxOnly: true
        };
        this.appConfig = appConfig;
    }
    BenefitOptionFlatGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (_.isObjectLike(this.tierDetails)) {
            var _a = this.tierDetails, empContribution_1 = _a.empContribution, erContribution_1 = _a.erContribution;
            var enrollmentIsValid_1 = true;
            _.forEach(this.selectedEmployees, function (item) {
                var empContr = item.employeeContributionAmount, erContr = item.employerContributionAmount;
                item.employeeContributionAmount = !_.isFinite(empContr) && _.isFinite(empContribution_1) ? empContribution_1 : empContr;
                item.employerContributionAmount = !_.isFinite(erContr) && _.isFinite(erContribution_1) ? erContribution_1 : erContr;
                if (!_this.manService.isValidEnrollment(item.employeeContributionAmount, item.employerContributionAmount)) {
                    enrollmentIsValid_1 = false;
                    _this.invalidEnrollments
                        .add("empContribution_" + item.employeeId)
                        .add("erContribution_" + item.employeeId);
                }
            });
            this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid || !enrollmentIsValid_1);
        }
        this.refreshGrid();
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(function () {
            _this.isFormValid = _this.ngForm.valid;
            _this.manService.benefitsOptionTabDetailsChanged(!_this.isFormValid);
        });
    };
    BenefitOptionFlatGridComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.invalidEnrollments.size > 0) {
            var models_1 = _.concat(this.empContrList.toArray(), this.erContrList.toArray());
            Promise.resolve()
                .then(function () {
                _.forEach(models_1, function (m) {
                    if (_this.invalidEnrollments.has(m.name)) {
                        _this.manService.setControlErrors(m, { contributionInvalid: true });
                    }
                });
                _this.invalidEnrollments.clear();
            });
        }
    };
    BenefitOptionFlatGridComponent.prototype.ngOnDestroy = function () { };
    BenefitOptionFlatGridComponent.prototype.updateRowsData = function () {
        var _this = this;
        this.manService.updateEmployeesUpdatedInfo(this.selectedEmployees);
        if (_.size(this.selectedEmployees) > 0) {
            var enrollmentIsValid = _.every(this.selectedEmployees, function (e) { return _this.manService.isValidEnrollment(e.employeeContributionAmount, e.employerContributionAmount); });
            this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid || !enrollmentIsValid);
        }
    };
    BenefitOptionFlatGridComponent.prototype.onContributionChange = function () {
        this.updateRowsData();
        this.refreshGrid();
    };
    BenefitOptionFlatGridComponent.prototype.refreshGrid = function () {
        if (!this.selectedEmployees) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.selectedEmployees, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitOptionFlatGridComponent.prototype, "subscriptions", void 0);
    return BenefitOptionFlatGridComponent;
}());
export { BenefitOptionFlatGridComponent };
