import * as tslib_1 from "tslib";
import { appConfig } from '../../../app.config';
import { ModalService } from '../modal/modal.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { VersionApiService } from './version-api.service';
import { SessionService } from '../../../core/services/index';
import { LocalStorageService } from '../../../core/services/local-storage/local-storage.service';
import { Subject } from 'rxjs/Subject';
var VersionSubscribeService = /** @class */ (function () {
    function VersionSubscribeService(hotkeysService, modalService, versionService, sessionService, localStorageService) {
        this.hotkeysService = hotkeysService;
        this.modalService = modalService;
        this.versionService = versionService;
        this.sessionService = sessionService;
        this.localStorageService = localStorageService;
        this.appVersion$ = new Subject();
        this.hotKeys = 'ctrl+alt+i';
    }
    VersionSubscribeService.prototype.get = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.sessionService.isExpired()) {
                reject();
            }
            var value = _this.localStorageService.get(VersionSubscribeService.storageKey);
            if (value && (new Date().getTime() - new Date(value.updateDate).getTime()) < 1000 * 60) {
                resolve(value);
                return;
            }
            _this.refreshAppVersion().then(function (data) { return resolve(data); });
        });
    };
    VersionSubscribeService.prototype.getCachedVersion = function () {
        return this.localStorageService.get(VersionSubscribeService.storageKey);
    };
    VersionSubscribeService.prototype.getVersion = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var version;
            return tslib_1.__generator(this, function (_a) {
                version = this.getCachedVersion();
                if (version) {
                    return [2 /*return*/, Promise.resolve(version)];
                }
                return [2 /*return*/, this.refreshAppVersion()];
            });
        });
    };
    VersionSubscribeService.prototype.refreshAppVersion = function () {
        var _this = this;
        return this.versionService.getServerVersion().then(function (data) {
            _this.appVersion$.next(data);
            _this.localStorageService.set(VersionSubscribeService.storageKey, data);
            return data;
        });
    };
    VersionSubscribeService.prototype.runDialogListener = function () {
        var _this = this;
        this.hotkeysService.add(new Hotkey(this.hotKeys, function (event) {
            _this.get()
                .then(function (data) { return _this.showDialogWithInfo(data); })
                .catch(function (ex) {
                console.log('Error: ' + ex);
            });
            return false; // Prevent bubbling
        }));
    };
    VersionSubscribeService.prototype.getAppVersion = function () {
        return this.appVersion$.asObservable();
    };
    VersionSubscribeService.prototype.showDialogWithInfo = function (data) {
        var appVersion = 'App version: ' + appConfig.appVersion;
        var serviceVersion = 'Service version: ' + data.serviceVersion;
        var dbVersion = (data.dbVersion) ? 'Database version: ' + data.dbVersion : '';
        this.modalService.globalAnchor.openInfoDialog('Application info', [appVersion, serviceVersion, dbVersion].join('<br/>'));
    };
    VersionSubscribeService.storageKey = 'app-version';
    return VersionSubscribeService;
}());
export { VersionSubscribeService };
