import { UnprocessedTimecardStats, IUnprocessedTimecardStats } from './../../models/timecards/unprocessed-timecard-stats';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { LookupMapService, EmployeeDefinitionsMapService, ScheduleDefinitionsMapService } from '../../../organization/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { CalendarDataService } from '../../../core/services/index';

import {
  EmployeeDefinition, PayPolicy, ShiftDiffPolicy, Position, PayRuleDefinition, ShiftDefinition,
  ExemptStatus, PayCycle, IPayRuleDefinition, IShiftDefinition
} from '../../../organization/models/index';

import {
  ITimecardsSummary, TimecardsSummary,
  ITimecardsEmployee, TimecardsEmployee,
  ITimecardsEarning, TimecardsEarning,
  ITimecardsEarningPayRule, TimecardsEarningPayRule,
  IIndividualTimecardsContainer, IndividualTimecardsContainer,
  IIndividualTimecardsDay, IndividualTimecardsDay, TimecardWarningState,
  IPayrollExportSubmitResult, PayrollExportSubmitResult, PayrollExportSubmitResults, IPayrollExportSubmitResults, ITimecardFirstWeekTotals, TimecardFirstWeekTotals
} from '../../models/index';
import { TimecardsCheckRightsService } from './timecards-check-rights.service';

@Injectable()
export class TimecardsMapService {
  private lookupMapService: LookupMapService;
  private employeeDefinitionsMapService: EmployeeDefinitionsMapService;
  private scheduleDefinitionsMapService: ScheduleDefinitionsMapService;
  private calendarDataService: CalendarDataService;
  private usedRules: TimecardsEarningPayRule[];
  constructor(lookupMapService: LookupMapService, employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    scheduleDefinitionsMapService: ScheduleDefinitionsMapService, calendarDataService: CalendarDataService,
    private timecardsCheckService: TimecardsCheckRightsService) {
    this.lookupMapService = lookupMapService;
    this.employeeDefinitionsMapService = employeeDefinitionsMapService;
    this.scheduleDefinitionsMapService = scheduleDefinitionsMapService;
    this.calendarDataService = calendarDataService;
    this.usedRules = [];
  }

  public mapFirstWeekTotals(dto: ITimecardFirstWeekTotals): TimecardFirstWeekTotals {
    Assert.isNotNull(dto, 'ITimecardFirstWeekTotals');
    let data: TimecardFirstWeekTotals = new TimecardFirstWeekTotals();
    data.paymentTransactionType = dto.paymentTransactionType;
    data.regularHours = dto.regularHours;
    data.regularPay = dto.regularPay;
    data.productiveHours = dto.productiveHours;
    data.nonProductiveHours = dto.nonProductiveHours;
    data.totalHours = dto.totalHours;
    data.overtimeAndExtraHours = dto.overtimeAndExtraHours;
    data.overtimeAndExtraPay = dto.overtimeAndExtraPay;
    data.totalOtherPay = dto.totalOtherPay;
    data.overTimePay = dto.overTimePay;
    data.totalAbsencePay =  dto.totalAbsencePay;
    data.ruleDetails = _.map(dto.ruleDetails, (ruledto: ITimecardsEarningPayRule) => this.mapTimecardsEarningRule(ruledto));
    return data;
  }

  public mapTimecardsEarning(dto: ITimecardsEarning): TimecardsEarning {
    Assert.isNotNull(dto, 'ITimecardsEarning');
    let data: TimecardsEarning = new TimecardsEarning();
    data.department = this.lookupMapService.mapDepartment(dto.department);
    data.organization = this.lookupMapService.mapOrganization(dto.organization);
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.costCenterCode = dto.costCenterCode;
    data.shiftCodeName = dto.shiftCodeName;
    data.payRate = dto.payRate;
    data.regularHours = dto.regularHours;
    data.regularPay = dto.regularPay;
    data.overtimeAndExtraHours = dto.overtimeAndExtraHours;
    data.overtimeAndExtraPay = dto.overtimeAndExtraPay;
    data.totalOtherPay = dto.totalOtherPay;
    data.overTimePay = dto.overTimePay;
    data.totalAbsencePay =  dto.totalAbsencePay;
    data.productiveHours = dto.productiveHours;
    data.nonProductiveHours = dto.nonProductiveHours;
    data.totalHours = dto.totalHours;
    data.rules = _.map(dto.rules, (ruledto: ITimecardsEarningPayRule) => this.mapTimecardsEarningRule(ruledto));
    data.rulesMap = _.groupBy(data.rules, (rule: TimecardsEarningPayRule) => {
      return rule.payRule.name;
    });
    data.shiftDiffPolicy = dto.shiftDifferentialPolicy ? this.lookupMapService.mapShiftDiffPolicy(dto.shiftDifferentialPolicy) : new ShiftDiffPolicy();
    data.payPolicy = dto.payPolicy ? this.lookupMapService.mapPayPolicy(dto.payPolicy) : new PayPolicy();
    data.totalPay = dto.totalPay;
    return data;
  }
  public mapTimecardsEarningRule(dto: ITimecardsEarningPayRule): TimecardsEarningPayRule {
    Assert.isNotNull(dto, 'ITimecardsEarningRule');
    let data: TimecardsEarningPayRule = new TimecardsEarningPayRule();
    data.name = dto.name;
    let dtoDef: IPayRuleDefinition = {
      name: dto.name,
      description: dto.name,
      color: null,
      order: dto.order,
    };
    data.payRule = this.employeeDefinitionsMapService.mapPayRuleDefinition(dtoDef);
    data.hours = dto.hours;
    data.payRate = dto.payRate;
    data.moneyAmount = dto.moneyAmount;

    this.usedRules.push(data);

    return data;
  }
  public mapTimecardsEmployee(dto: ITimecardsEmployee): TimecardsEmployee {
    Assert.isNotNull(dto, 'ITimecardsEmployee');
    let data: TimecardsEmployee = new TimecardsEmployee();
    data.employeePosition = this.employeeDefinitionsMapService.mapToEmployeePosition(dto.employeePosition);
    data.standardPayRate = dto.standardPayRate;
    data.productiveHours = dto.productiveHours;
    data.nonProductiveHours = dto.nonProductiveHours;
    data.totalHours = dto.totalHours;
    data.regularPay = dto.regularPay;
    data.overtimeAndExtra = dto.overtimeAndExtra;
    data.totalOtherPay = dto.totalOtherPay ? dto.totalOtherPay : 0;
    data.overTimePay = dto.overTimePay ? dto.overTimePay : 0;
    data.totalAbsencePay = dto.totalAbsencePay ? dto.totalAbsencePay : 0;
    data.totalPay = dto.totalPay;
    data.homeShift = dto.homeShift ? this.lookupMapService.mapShiftDefinition(dto.homeShift) : null;
    data.approved = dto.approved;
    data.certified = dto.certified;
    data.isError = dto.isError;
    data.isWarning = dto.isWarning;
    data.shiftDiffPolicy = dto.shiftDifferentialPolicy ? this.lookupMapService.mapShiftDiffPolicy(dto.shiftDifferentialPolicy) : new ShiftDiffPolicy();
    data.payPolicy = dto.payPolicy ? this.lookupMapService.mapPayPolicy(dto.payPolicy) : new PayPolicy();
    data.earnings = _.map(dto.earnings, (earningdto: ITimecardsEarning) => this.mapTimecardsEarning(earningdto));
    data.canModifyOwnTimecard = this.timecardsCheckService.userCanApproveOwnTimecard(data.employeePosition.employee.id);
    data.empOrganization = dto.empOrganization;
    data.isLocked = dto.isLocked;
    data.isPayCycleLocked = dto.isPayCycleLocked;
    data.isEdited = dto.isEdited;
    return data;
  }

  public mapTimecardsSummary(dto: ITimecardsSummary): TimecardsSummary {
    Assert.isNotNull(dto, 'ITimecardsSummary');
    let data: TimecardsSummary = new TimecardsSummary();
    data.payCycle = this.lookupMapService.mapPayCycle(dto.payCycle);
    data.infoDisplay = dto.infoDisplay;
    data.canApprove = dto.canApprove;
    data.canUnapprove = dto.canUnapprove;
    data.canExport = dto.canExport;
    data.canManageExports = dto.canManageExports;
    data.hideRates = dto.hideRates;
    data.canExportToPDF = dto.canExportToPDF;
    data.canExportToExcel = dto.canExportToExcel;
    data.canRecalculate = dto.canRecalculate;
    data.canViewSummary = dto.canViewSummary;
    data.isLocked = dto.isLocked;
    data.shiftDiffBasedOnTime = dto.shiftDiffBasedOnTime;
    data.hideCostCenter = dto.hideCostCenter;
    data.facilityHasPayUnits = dto.facilityHasPayUnits;
    data.hasMassAssignPayUnitsRights = dto.hasMassAssignPayUnitsRights;
    data.records = _.map(dto.records, (empdto: ITimecardsEmployee) => this.mapTimecardsEmployee(empdto));
    data.rulesDefinitions = _.map(dto.rulesDefinitions, (ruledto: PayRuleDefinition) => this.employeeDefinitionsMapService.mapPayRuleDefinition(ruledto));

    const ruleName: string = 'regular'; // Rule with this name should be appeared first
    let ruleIndex: number = -1;
    const usedRulesMap: StringMap<PayRuleDefinition> = _.reduce(this.usedRules, (accumulator: StringMap<PayRuleDefinition>, rule: TimecardsEarningPayRule): StringMap<PayRuleDefinition> => {
      const ruleDef: PayRuleDefinition = _.find(data.rulesDefinitions, (def: PayRuleDefinition) => _.toLower(def.name) === _.toLower(rule.payRule.name));
      if (_.isObject(ruleDef) && !_.isObject(accumulator[rule.payRule.name])) {
        accumulator[rule.payRule.name] = ruleDef;
        if (_.lowerCase(ruleDef.name) === _.lowerCase(ruleName)) {
          ruleIndex = _.size(accumulator) - 1;
        }
      }

      return accumulator;
    }, {});

    data.usedRulesDefinitions = _.values(usedRulesMap);
    data.usedRulesDefinitions = _.sortBy(data.usedRulesDefinitions, 'order');
    this.usedRules.length = 0;
    data.hideEmptyTimeCards = dto.hideEmptyTimeCards;
    return data;
  }

  public mapIndividualTimecardsDay(dto: IIndividualTimecardsDay): IndividualTimecardsDay {
    Assert.isNotNull(dto, 'ITimecardsEmployee');
    let data: IndividualTimecardsDay = new IndividualTimecardsDay();
    data.date = dateTimeUtils.getUtcDateTime(dto.date);
    data.shift = this.scheduleDefinitionsMapService.mapToScheduledShiftDefinition(dto.shift, data.date);
    data.punchInTime = dto.punchInTime ? dateTimeUtils.convertFromDtoString(dto.punchInTime) : null;
    data.punchInSeconds = dateTimeUtils.getTimeTotalSecondsWithUTC(dto.punchInTime);
    data.punchOutTime = dto.punchOutTime ? dateTimeUtils.convertFromDtoString(dto.punchOutTime) : null;
    data.punchOutSeconds = dateTimeUtils.getTimeTotalSecondsWithUTC(dto.punchOutTime);
    data.shiftCode = dto.shiftCode;
    data.standardPayRate = dto.standardPayRate;
    data.earnings = _.map(dto.earnings, (earningdto: ITimecardsEarning) => this.mapTimecardsEarning(earningdto));
    data.productiveHours = dto.productiveHours;
    data.nonProductiveHours = dto.nonProductiveHours;
    data.totalHours = dto.totalHours;
    data.regularPay = dto.regularPay;
    data.overtimeAndExtra = dto.overtimeAndExtra;
    data.totalOtherPay = dto.totalOtherPay ? dto.totalOtherPay : 0;
    data.overTimePay = dto.overTimePay ? dto.overTimePay : 0;
    data.totalAbsencePay = dto.totalAbsencePay ? dto.totalAbsencePay : 0;
    data.totalPay = dto.totalPay;
    data.isError = dto.isError;
    data.warningState = dto.warningState;
    data.isNoShow = (dto.warningState ) === TimecardWarningState.noShow;
    data.isInvalidPunch = (dto.warningState ) === TimecardWarningState.invalidMaxTime ||
      (dto.warningState ) === TimecardWarningState.invalidMinTime;
    data.isInvalidLogin = (dto.warningState) === TimecardWarningState.invalidLogin;
    data.approved = dto.approved;
    data.shifts = _.map(dto.shifts, (sdto: IShiftDefinition) => this.lookupMapService.mapShiftDefinition(sdto));
    data.shiftNames = _.join(_.map(data.shifts, (s: ShiftDefinition) => s.name), ',');
    data.isLocked = dto.isLocked;
    data.isEdited = dto.isEdited;
    return data;
  }

  public mapIndividualTimecardDays(days: IIndividualTimecardsDay[]): IndividualTimecardsDay[] {
    const mappedDays: IndividualTimecardsDay[] = _.map(days, (day: IIndividualTimecardsDay) => this.mapIndividualTimecardsDay(day));
    const sortedDays: IndividualTimecardsDay[] = _.sortBy(mappedDays, (day: IndividualTimecardsDay) => {
      return moment(day.date).unix();
    });

    return sortedDays;
  }

  public mapIndividualTimecardsDays(days: IIndividualTimecardsDay[], payCycleStartDay: number): IndividualTimecardsDay[] {
    const mappedDays: IndividualTimecardsDay[] = this.mapIndividualTimecardDays(days);
    if (mappedDays.length === 0) {
      return mappedDays;
    }
    const firstDay: IndividualTimecardsDay = _.first(mappedDays);
    const lastDay: IndividualTimecardsDay = _.last(mappedDays);
    const startOfCycle: moment.Moment = moment(firstDay.date);
    const endOfCycle: moment.Moment = moment(lastDay.date);

    return this.extendTimecardDays(mappedDays, payCycleStartDay, startOfCycle, endOfCycle);
  }

  public extendTimecardDays(days: IndividualTimecardsDay[], payCycleStartDayOffset: number, startOfCycle: moment.Moment, endOfCycle: moment.Moment): IndividualTimecardsDay[] {
    let currentWeek: number = 0;
    let weekCounter: number = 0;
    _.forEach(days, (day: IndividualTimecardsDay) => {
      const dateOfDay: moment.Moment = moment(day.date);
      const startOfWeek: number = dateOfDay.clone().add('day', (1 - payCycleStartDayOffset)).startOf('week').unix();

      if (currentWeek !== startOfWeek) {
        weekCounter++;
        currentWeek = startOfWeek;
      }

      day.week = weekCounter;
      day.weekDay = dateOfDay.format(appConfig.dayShortNameWeekDayFormat);
      day.isInPayCycle = dateOfDay.isSameOrAfter(startOfCycle) && dateOfDay.isSameOrBefore(endOfCycle);
    });

    return days;
  }

  public mapIndividualTimecards(dto: IIndividualTimecardsContainer): IndividualTimecardsContainer {
    Assert.isNotNull(dto, 'ITimecardsSummary');
    let data: IndividualTimecardsContainer = new IndividualTimecardsContainer();
    data.employee = dto.employee ? this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee) : new EmployeeDefinition();
    data.primaryPosition = dto.primaryPosition ? this.lookupMapService.mapPosition(dto.primaryPosition) : new Position();
    data.exemptStatus = dto.exemptStatus ? this.lookupMapService.mapExemptStatus(dto.exemptStatus) : new ExemptStatus();
    data.payCycle = dto.payCycle ? this.lookupMapService.mapPayCycle(dto.payCycle) : new PayCycle();
    data.shiftDiffPolicy = dto.shiftDifferentialPolicy ? this.lookupMapService.mapShiftDiffPolicy(dto.shiftDifferentialPolicy) : new ShiftDiffPolicy();
    data.payPolicy = dto.payPolicy ? this.lookupMapService.mapPayPolicy(dto.payPolicy) : new PayPolicy();
    data.infoDisplay = dto.infoDisplay;
    data.canApprove = dto.canApprove;
    data.canUnapprove = dto.canUnapprove;
    data.hideRates = dto.hideRates;
    data.canExportToExcel = dto.canExportToExcel;
    data.canExportToPDF = dto.canExportToPDF;
    data.canRecalculate = true;
    data.canViewSummary = true;
    data.records = this.mapIndividualTimecardsDays(dto.timecards, dto.payCycleStartDay);
    data.rulesDefinitions = _.map(dto.rulesDefinitions, (ruledto: PayRuleDefinition) => this.employeeDefinitionsMapService.mapPayRuleDefinition(ruledto));
    data.hasErrors = !!_.find(data.records, (day: IndividualTimecardsDay) => day.isError);
    data.hasWarnings = !!_.find(data.records, (day: IndividualTimecardsDay) => day.warningState !== 0);
    data.hasUnapprovedOvertime = dto.hasUnapprovedOvertime;
    data.approved = dto.approved;
    data.approvedOn = dto.approvedOn ? dateTimeUtils.convertFromDtoDateTimeString(dto.approvedOn) : null;
    data.approvedBy = dto.approvedBy;
    data.certified = dto.certified;
    data.certifiedOn = dto.certifiedOn ? dateTimeUtils.convertFromDtoDateTimeString(dto.certifiedOn) : null;
    data.certifiedBy = dto.certifiedBy;
    data.payCycleStartDay = dto.payCycleStartDay;
    data.isApprovablePayCycle = moment(data.payCycle.endDate).isBefore(moment());
    data.empOrganization = dto.empOrganization;
    data.isPayCycleLocked = dto.isPayCycleLocked;
    data.isOrganizationPayrollLocked = dto.isOrganizationPayrollLocked;
    data.firstWeekTotals = this.mapFirstWeekTotals(dto.firstWeekTotals);
    data.shiftDiffBasedOnTime = dto.shiftDiffBasedOnTime;
    data.hideCostCenter = dto.hideCostCenter;
    data.isPayRollVisible = dto.isPayRollVisible;
    data.payRollLink = dto.payRollLink;

    const ruleName: string = 'regular'; // Rule with this name should be appeared first
    let ruleIndex: number = -1;
    const usedRulesMap: StringMap<PayRuleDefinition> = _.reduce(this.usedRules, (accumulator: StringMap<PayRuleDefinition>, rule: TimecardsEarningPayRule): StringMap<PayRuleDefinition> => {
      const ruleDef: PayRuleDefinition = _.find(data.rulesDefinitions, (def: PayRuleDefinition) => def.name === rule.payRule.name);
      if (_.isObject(ruleDef) && !_.isObject(accumulator[rule.payRule.name])) {
        accumulator[rule.payRule.name] = ruleDef;
        if (_.lowerCase(ruleDef.name) === _.lowerCase(ruleName)) {
          ruleIndex = _.size(accumulator) - 1;
        }
      }

      return accumulator;
    }, {});

    data.usedRulesDefinitions = _.values(usedRulesMap);
    data.usedRulesDefinitions = _.sortBy(data.usedRulesDefinitions, 'order');
    this.usedRules.length = 0;

    data.canModifyOwnTimecard = this.timecardsCheckService.userCanApproveOwnTimecard(data.employee.id);

    return data;
  }

  public mapPayrollExportSubmitResult(dto: IPayrollExportSubmitResult): PayrollExportSubmitResult {
    const data = new PayrollExportSubmitResult();
    data.errorCode = dto.errorCode;
    data.errorMessage = dto.errorMessage;
    return data;
  }

  public mapPayrollExportSubmitResults(dtos: IPayrollExportSubmitResults): PayrollExportSubmitResults {
    const data = new PayrollExportSubmitResults();
    data.results = _.map(dtos.messages, (dto: IPayrollExportSubmitResult) => this.mapPayrollExportSubmitResult(dto));
    data.hasErrors = !!_.find(data.results, (r: PayrollExportSubmitResult) => r.errorCode > 0);
    return data;
  }

  public mapUnprocessedTimecardStats(dto: IUnprocessedTimecardStats): UnprocessedTimecardStats {
    let stats: UnprocessedTimecardStats = new UnprocessedTimecardStats();
    stats.pendingRecords = dto.pendingRecords;
    stats.totalRecords = dto.totalRecords;
    stats.startDate = dateTimeUtils.convertFromDtoDateTimeString(dto.startDate);
    stats.endDate = dateTimeUtils.convertFromDtoDateTimeString(dto.endDate);
    return stats;
  }
}

