<slx-spinner [show]="isLoading">
  <form #form="ngForm">
    <div class="modal-body mcontent">
      <p *ngIf="!employeeBenefitClass || !employeeBenefitClass.id">Benefit Class and Effective Date are required for this employee:</p>
      <p *ngIf="employeeBenefitClass && employeeBenefitClass.id">Please Confirm the Benefit Class and Effective Date for this employee:</p>
      <div>
        <div class="col-lg-4 mar-tp">Benefit Class</div>
        <div class="col-lg-8">
          <slx-input-decorator>
            <slx-dropdown-input slx-input
                                name="benefitClass"
                                [lookup]="benefitClassesLookup"
                                [(ngModel)]="benefitClass"
                                [required]="true"
            ></slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
      </div>
      <div>
        <div class="col-lg-4 mar-tp">Effective Date</div>
        <div class="col-lg-8">
          <slx-input-decorator>
            <slx-date-picker-ngx slx-input
                                 name="benefitClassEffectiveDate"
                                 [(ngModel)]="benefitClassEffectiveDate"
                                 [acceptNullDate]="false"
                                 [minDate]="request.rehireDate ? request.rehireDate : request.hireDate"
                                 [required]="true"
            ></slx-date-picker-ngx>
            <span errorMessage for="required"></span>
            <span errorMessage for="date"></span>
          </slx-input-decorator>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r pull-left"  [disabled]="disabledBenifitFooterBtn">Cancel</button>
      <button type="button" (click)="onOk()" [disabled]="!form.valid || disabledBenifitFooterBtn" class="theme-button-apply pull-right">Ok</button>
    </div>
  </form>
</slx-spinner>
