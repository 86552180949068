<form novalidate #editForm="ngForm" *ngIf="holiday">
    <div class="holder">

        <div class="flex-container">

            <div class="col md-full-line-column name-column">
                <slx-input-decorator>
                    <input class="form-control" slx-input type="text" [(ngModel)]="holiday.name" [required]="true" placeholder="Holiday" [prohibitedValues]="prohibitedNameValues"
                        name="nameInput">
                    <span errorMessage for="prohibitedValues"></span>
                    <span errorMessage for="required"></span>
                </slx-input-decorator>
            </div>

            <div class="col md-full-line-column origin-column">
                <slx-input-decorator>
                    <slx-dropdown-input slx-input [lookup]="{lookupType: 'holidayOrigins'}" valueField="id" titleField="name" [(ngModel)]="origin"
                        name="originInput" [required]="true" placeholder="Origin">
                    </slx-dropdown-input>
                    <span errorMessage for="required"></span>
                </slx-input-decorator>
            </div>

            <div class="col md-full-line-column field-container pattern-column">

                <div class="col md-full-line-column day-column" *ngIf="holiday.isRuleBased">
                    <slx-input-decorator>
                        <slx-dropdown-input slx-input [lookup]="{lookupType: 'holidayDayPatterns'}" valueField="id" titleField="name" [(ngModel)]="ruleDayDefiningPattern"
                            name="everyInput" [required]="holiday.isRuleBased" placeholder="Day Pattern" (ngModelChange)="autoPopulateDate()">
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>
                </div>

                <div class="col md-full-line-column week-column" *ngIf="holiday.isRuleBased">
                    <slx-input-decorator>
                        <slx-dropdown-input slx-input valueField="name" titleField="name" [(ngModel)]="ruleDayOfWeek" name="dayInput" [lookup]="{lookupType: 'daysOfWeek'}"
                            [required]="holiday.isRuleBased" placeholder="Day of Week" (ngModelChange)="autoPopulateDate()">
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>
                </div>

                <div class="col md-full-line-column month-column" *ngIf="holiday.isRuleBased">
                    <div *ngIf="holiday.isRuleBased" class="of-sign">of</div>
                    <slx-input-decorator>
                        <slx-dropdown-input slx-input valueField="id" titleField="name" [(ngModel)]="ruleMonth" name="monthInput" [lookup]="{lookupType: 'months'}"
                            [required]="holiday.isRuleBased" placeholder="Month" (ngModelChange)="autoPopulateDate()">
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>
                </div>

            </div>

            <div class="col md-full-line-column field-container dates-column">

                <div class="col md-full-line-column main-date-column">
                    <slx-input-decorator>
                        <slx-date-picker-ngx slx-input placeholder="Date" name="dateInput" [required]="true" [readonly]="holiday.isRuleBased" [(ngModel)]="holiday.date"
                            (ngModelChange)="holidayDateChanged($event)" [minDate]="minDate" [maxDate]="maxDate"></slx-date-picker-ngx>
                        <span errorMessage for="required"></span>
                        <span errorMessage for="date"></span>
                    </slx-input-decorator>
                </div>

                <div class="col md-full-line-column field-container start-column">
                    <div class="col md-full-line-column start-date-column">
                        <slx-input-decorator>
                            <slx-date-picker-ngx slx-input placeholder="Start Date" name="startInput" [required]="true"
                                                 [minDate]="minDate" [maxDate]="maxDate"
                                                 [(ngModel)]="holiday.start"></slx-date-picker-ngx>
                            <span errorMessage for="required"></span>
                            <span errorMessage for="date"></span>
                        </slx-input-decorator>
                    </div>
                    <div class="col md-full-line-column start-time-column">
                        <slx-input-decorator>
                            <input slx-time-picker slx-input name="startDate{{index}}" required="true"
                                   [minDate]="minDate" [maxDate]="maxDate"
                                   [(ngModel)]="holiday.start" format="hh:mm tt"
                                dateInput="true" [slxMaxDate]="holiday.end">
                            <span errorMessage for="required">Start time is required</span>
                            <span errorMessage for="maxDate">Start time should be less than end time</span>
                        </slx-input-decorator>
                    </div>
                </div>

                <div class="col md-full-line-column field-container end-column">
                    <div class="col md-full-line-column end-date-column">
                        <slx-input-decorator>
                            <slx-date-picker-ngx slx-input placeholder="End Date" name="endInput" [required]="true" [(ngModel)]="holiday.end"></slx-date-picker-ngx>
                            <span errorMessage for="required"></span>
                            <span errorMessage for="date"></span>
                        </slx-input-decorator>
                    </div>
                    <div class="col md-full-line-column end-time-column">
                        <slx-input-decorator>
                            <input slx-time-picker slx-input name="endDate{{index}}" required="true" [(ngModel)]="holiday.end" format="hh:mm tt" dateInput="true"
                                [slxMinDate]="holiday.start">
                            <span errorMessage for="required">End time is required</span>
                            <span errorMessage for="minDate">End time should be greater than start time</span>
                        </slx-input-decorator>
                    </div>
                </div>

            </div>

            <div class="col md-full-line-column">
                <slx-input-decorator>
                    <input slx-input type="text" [(ngModel)]="holiday.type" [slxMaxLength]="50" [required]="false" placeholder="Type" name="typeInput">
                    <span errorMessage for="slxMaxLength">value must not exceed 50 characters</span>
                </slx-input-decorator>
            </div>

            <div class="col md-full-line-column">
                <slx-input-decorator>
                    <input slx-input type="text" [(ngModel)]="holiday.code" [slxMaxLength]="3" [required]="false" placeholder="Code" name="codeInput">
                    <span errorMessage for="slxMaxLength">Code must not exceed 3 characters</span>
                </slx-input-decorator>
            </div>

        </div>
    </div>
    <div class="actions-bar">
        <button class="action-button save-btn" (click)="onSave($event)" [disabled]="!editForm.valid">Save</button>
        <button class="action-button cancel-btn" (click)="onCancel($event)">Cancel</button>
    </div>
</form>
