<slx-spinner [show]="state.isLoading">
  <form #censusForm="ngForm" class="census-form" [ngClass]="{ 'disabled': isCorpOrglevelSelected }">
  <div class="toolbar slx-content-toolbar-indents" *ngIf="acuityConfiguration" >
      <div class="toolbar-section toolbar-first">
        <div class="date-section">
            <slx-input-decorator>
                <slx-date-picker-ngx slx-input
                  [(ngModel)]="entryDate"
                  [required]="true"
                  placeholder="Date"
                  name="entryDatePicker"
                  [readonly]="disableDateInput"
                ></slx-date-picker-ngx>
                <span errorMessage for="required">This field is required.</span>
                <span errorMessage for="date"></span>
              </slx-input-decorator>
        </div>
        <div class="check-next-control">
            <slx-input-decorator class="copy" className="slx-form-group slx-no-border slx-no-label small-font">
              <slx-checkbox-input slx-input name="copyToFutureDatesCheckBox" fieldName="copyToFutureDatesCheckBox" [(ngModel)]="acuityConfiguration.copyToFutureDates" (ngModelChange)="onChangeCopyToFutureDatesCheckbox()"
                caption="Copy to future dates" placeholder=""></slx-checkbox-input>
            </slx-input-decorator>
        </div>
      </div>
      <div class="toolbar-section buttons-section" *ngIf="!hideButtons">
        <button class="btn-default theme-button-apply margin-r"
        type="button" [disabled]="!canSave" (click)="saveChanges()"><i class="fa fa-save" aria-hidden="true"></i>Save</button>

        <button class="theme-button-cancel" [disabled]="!hasChanges" type="button" (click)="discardChanges()"><i class="fa fa-ban" aria-hidden="true"></i>Discard</button>


    </div>
  </div>

    <div class="labels-row slx-main-content-indents">
        <h3>Acuity Configuration</h3>
        <div class="toolbar align-center census-capacity">
          <i class="fas fa-battery-three-quarters" aria-hidden="true"></i>
          <span class="capacity-title">Capacity</span>
          <div class="capacity-value">{{acuityConfiguration?.capacity}}</div>
        </div>
      </div>

    <span [style.visibility]="capacityExceeded ? 'visible' : 'hidden'" class="error-message">The value exceeds the maximum capacity.</span>

    <div class="toolbar-panel">
      <slx-checkbox-toggle 
      class="tab-switch" 
      className="toggle-left slx-green-checkbox-toggle"
      (ngModelChange)="onSettingChanged($event)"
      name="allowToExcludeFromTotals" 
      caption="Disable Multiple columns to be excluded in the Total"
      checkedText="Yes"
      uncheckedText="No"
      [ngModel]="this.censusGridData?.categoriesConfig?.allowToExcludeFromTotals" 
      ></slx-checkbox-toggle>
    </div>

    <kendo-grid class="slx-main-content-indents ac-grid" [data]="censusGridData?.rows">

      <kendo-grid-column media="(max-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="mobile-cell">
              <div>Type:{{dataItem.type}}</div>
              <div *ngFor="let col of censusGridData?.columns; let idx=index; let isFirst=first">
                  <slx-input-decorator *ngIf="dataItem.isSettings" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                    <slx-checkbox-input slx-input
                      [(ngModel)]="dataItem.entries[col.field].excludeFromTotal"
                    ></slx-checkbox-input>
                  </slx-input-decorator>
                  <slx-input-decorator *ngIf="!dataItem.isSettings" className="slx-no-border small-font">
                      <slx-kendo-number slx-input number
                        [autofocus]="isFirst"
                        [ngModel]="dataItem.values[col.field]"
                        [min]="0"
                        [max]="999"
                        [step]="1"
                        [required]="'true'"
                        [placeholder]="col.title"
                        [autoCorrect]="true"
                        [readonly]="!acuityConfiguration?.canEditCensus"
                        (ngModelChange)="onChanged($event, col.field, dataItem)"
                        name="{{col.field}}{{rowIndex}}"
                      ></slx-kendo-number>
                      <span errorMessage for="required"></span>
                      <span errorMessage for="number"></span>
                    </slx-input-decorator>
              </div>
            </div>
          </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="type" [sortable]="false" [filterable]="false" width="85"  media="(min-width: 450px)">
        <ng-template kendoGridHeaderTemplate>Type</ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.type}}
        </ng-template>
        <ng-template kendoGridFooterTemplate>
          Total
      </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngFor="let col of censusGridData?.columns; let idx=index; let isFirst=first"
        [sortable]="false" [filterable]="false" [field]="col.field" [title]="col.title" width="85" media="(min-width: 450px)">
        <ng-template kendoGridHeaderTemplate let-column><div></div>{{column.title}}</ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-column="column" let-rowIndex="rowIndex" let-columnIndex="columnIndex">
              <slx-input-decorator *ngIf="dataItem.isSettings" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                <slx-checkbox-input slx-input
                  [(ngModel)]="dataItem.entries[col.field].censusConfig.excludeFromTotal"
                  (ngModelChange)="onCategoryConfigChange($event, dataItem.entries[col.field].censusConfig.includedInTotal)"
                  name="censusSetting{{idx}}"
                  fieldName="censusSetting{{idx}}"
                ></slx-checkbox-input>
              </slx-input-decorator>
              <slx-input-decorator *ngIf="!dataItem.isSettings" className="slx-no-border small-font slx-no-label">
                <slx-kendo-number slx-input number
                  [autofocus]="isFirst"
                  [ngModel]="dataItem.values[column.field]"
                  [min]="0"
                  [max]="999"
                  [step]="1"
                  [required]="'true'"
                  [placeholder]="column.title"
                  [autoCorrect]="true"
                  [readonly]="!acuityConfiguration?.canEditCensus"
                  (ngModelChange)="onChanged($event, column.field, dataItem)"
                  name="entryCategory{{columnIndex}}{{rowIndex}}"
                ></slx-kendo-number>
                <span errorMessage for="required"></span>
                <span errorMessage for="number"></span>
              </slx-input-decorator>
        </ng-template>
        <ng-template kendoGridFooterTemplate let-column="column">
            <span class="total-label">{{ totals[column.field] }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="capacity" [sortable]="false" [filterable]="false" width="85" media="(min-width: 450px)">
        <ng-template kendoGridHeaderTemplate>Total</ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="!dataItem.isSettings" class="total-label">{{ dataItem.capacity }}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate>
          <span class="total-label">{{ totals['totals'] }}</span>
        </ng-template>
      </kendo-grid-column>
      </kendo-grid>
  </form>
</slx-spinner>
