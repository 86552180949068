import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { configurationConfig } from '../../configuration.config';
import { ResponseBody, Meta, EditResult, FieldsMeta } from '../../../core/models/index';
import { EmployeeSmsManagementMapService } from './employee-sms-management-map.service';
import { HttpRequest } from '@angular/common/http';
import { IEmployeeSMSContacts, EmployeeSMSContacts } from '../../../app-modules/message-center/models';
import { EmployeeSendSms, IEmployeeSendSms, IEmployeeSendSmsResponse } from '../../../app-modules/message-center/models/employee-send-sms';

@Injectable()
export class EmployeeSmsManagementApiService {


  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private employeeSmsManagementMapService: EmployeeSmsManagementMapService) { }

  public getEmployeeSmsManagementList(searchText: string) : Promise<IEmployeeSMSContacts[]> {
    const url = `${this.getApiRoot()}/${configurationConfig.api.configuration.employeeSms.configuration}` + '/' + searchText;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService.request<IEmployeeSMSContacts[], Meta>(request)
    .then((response: ResponseBody<IEmployeeSMSContacts[], Meta>) => this.employeeSmsManagementMapService.mapToEmployeeSmsContactList(response.data));
  }

  public sendSmsToEmployee(employeeSendSmsList: EmployeeSendSms): Promise<IEmployeeSendSmsResponse[]> {
    const url = `${this.getCommunicationApiRoot()}/${configurationConfig.api.configuration.employeeSendSms.configuration}` + "?" + configurationConfig.api.configuration.employeeSendSms.apiVersion + "=" + this.getCommunicationApiRootVersion();
    const request = this.urlParamsService.createPostRequest(url, employeeSendSmsList, null, null);
    return this.apiUtilService.request<IEmployeeSendSmsResponse[], Meta>(request)
      .then((response: ResponseBody<IEmployeeSendSmsResponse[], Meta>) => this.employeeSmsManagementMapService.mapToSendSmsContactList(response.data));
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.employeeSms.root}`;
  }

  private getCommunicationApiRoot(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.employeeSendSms.root}`;
  }

  private getCommunicationApiRootVersion(): string {
    return `${this.apiUtilService.getCommunicationApiRootVersion()}`;
  }
}
