import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  HostListener,
  HostBinding,
  SimpleChanges,
  OnChanges
} from '@angular/core';

import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CustomDomEvents } from '../../models/index';
import { AcceptableControlValueAccessor } from '../../../core/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-ssn-input',
  templateUrl: 'ssn-input.component.html',
  styleUrls: ['ssn-input.component.scss']
})
export class SsnInputComponent extends AcceptableControlValueAccessor implements OnChanges, ControlValueAccessor {
  @Input()
  public className: string;
  @Input()
  public placeholder: string = 'SSN';

  @Input()
  public required: boolean;

  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }
  public get readonly(): boolean {
    return !this.inEdit;
  }

  public ssnMaskConf: any;
  public innerValue: any = '';
  public inEdit: boolean;

  @ViewChild('ssnInput', {static: true}) private inputComponent: ElementRef;
  @HostBinding('class.edited') private hasFocus: boolean = false;

  constructor(private elementRef: ElementRef, private ngControl: NgControl) {
    super();
    ngControl.valueAccessor = this;

    this.ssnMaskConf = {
      mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      guide: true
    };
    this.hasFocus = false;
  }
  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }
  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
  }
  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !myattr;
  }
  public writeValue(value?: any): void {
    this.innerValue = value;
    this.inputComponent.nativeElement.value = value ? value : null;
  }

  public valueChanged(value: any): void {
    this.changeValue(value);
  }
}
