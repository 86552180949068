import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { EmployeeBannerInfo } from '../../models/index';
import { UserService } from '../../../../core/services/index';
import { EmployeeSelfServiceApiService } from '../../services';
import { Observable } from 'rxjs';
import { mutableSelect } from '../../../../core/decorators';
import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';
var EmployeeBannerComponent = /** @class */ (function () {
    function EmployeeBannerComponent(userService, employeeSelfServiceApiService, appSettingManageService) {
        this.userService = userService;
        this.employeeSelfServiceApiService = employeeSelfServiceApiService;
        this.isNGPUser = false;
        this.isSystemUser = false;
        this.canSeePayroll = false;
        this.onTerminate = new EventEmitter();
        this.onTransfer = new EventEmitter();
        this.onUndoTransfer = new EventEmitter();
        this.onRehire = new EventEmitter();
        this.onCancelTerminate = new EventEmitter();
        this.onChangeTerminateDate = new EventEmitter();
        this.onResendTimeclocksEmail = new EventEmitter();
        this.appSettingManageService = appSettingManageService;
    }
    EmployeeBannerComponent.prototype.canEditOwnProfile = function () {
        if (this.employeeBannerInfo) {
            if (this.userService.isEmployeeLinkedToStoredUser(this.employeeBannerInfo.empoyeeId)) {
                return !this.canNotEditOwnProfile;
            }
        }
        return true;
    };
    EmployeeBannerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel ? orgLevel.id : undefined;
            _this.orgLevel = orgLevel;
        });
        this.getNGPFlag();
    };
    EmployeeBannerComponent.prototype.transfer = function () {
        this.onTransfer.emit(null);
    };
    EmployeeBannerComponent.prototype.undoTransfer = function () {
        this.onUndoTransfer.emit(null);
    };
    EmployeeBannerComponent.prototype.terminate = function () {
        this.onTerminate.emit(null);
    };
    EmployeeBannerComponent.prototype.rehire = function () {
        this.onRehire.emit(null);
    };
    EmployeeBannerComponent.prototype.cancelTerminate = function () {
        this.onCancelTerminate.emit(null);
    };
    EmployeeBannerComponent.prototype.changeTerminateDate = function () {
        this.onChangeTerminateDate.emit(null);
    };
    EmployeeBannerComponent.prototype.resendEmail = function () {
        this.onResendTimeclocksEmail.emit(null);
    };
    Object.defineProperty(EmployeeBannerComponent.prototype, "employeeFullName", {
        get: function () {
            return this.employeeBannerInfo.fullName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeBannerComponent.prototype, "actionsAvailable", {
        get: function () {
            if (!this.employeeBannerInfo)
                return false;
            if (this.employeeBannerInfo.employeeType.name !== 'AFT' && this.employeeBannerInfo.employeeType.name !== 'APT'
                && this.employeeBannerInfo.isPayrollVisible && this.isNGPUser && !this.employeeBannerInfo.isTransferred
                && !this.employeeBannerInfo.isTerminated && !this.employeeBannerInfo.isAgency) {
                this.canSeePayroll = true;
            }
            if (this.employeeBannerInfo.isTerminated) {
                return this.employeeBannerInfo.canRehire || this.employeeBannerInfo.canCancelTerminate;
            }
            else {
                return this.employeeBannerInfo.canTerminate || this.employeeBannerInfo.canTransfer;
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeeBannerComponent.prototype.getNGPFlag = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.isNGPUser = appServerConfig.IsNextgenPayrollEnabled;
                        if (this.isNGPUser) {
                            this.isSystemUser = this.userService.getUser() && this.userService.getUser().username.toLowerCase() === 'system' ? true : false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeBannerComponent.prototype.activateNGPSSO = function () {
        //console.log('NGP SSO Window Activate');
        this.employeeSelfServiceApiService.navigateSSO('nextgenpayroll', this.orgLevelId);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeBannerComponent.prototype, "orgLevel$", void 0);
    return EmployeeBannerComponent;
}());
export { EmployeeBannerComponent };
