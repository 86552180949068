export enum CoverageOptionTypes {
  EmployeeOnly = 'Employee Only',
  EmployeeChild = 'Employee + Child',
  EmployeeChildren = 'Employee + Children',
  EmployeeSpouse = 'Employee + Spouse',
  Family = 'Family'
}


export const coverageOptionTypesList = [
  CoverageOptionTypes.EmployeeOnly,
  CoverageOptionTypes.EmployeeChild,
  CoverageOptionTypes.EmployeeChildren,
  CoverageOptionTypes.EmployeeSpouse,
  CoverageOptionTypes.Family
];
