<div class="modal-content-pe">
    <slx-spinner [show]="isLoading">
    <div class="modal-body-pe">
        <slx-payroll-export-config #exportConfig></slx-payroll-export-config>
    </div>  
    <div class="pe-popup-footer pe-row" >
        <div (click)="onSave()" class="save-button">
            <i class="icon fas fa-save" aria-hidden="true"></i>
            Save
        </div>
        <div (click)="onClose()">
            <i class="icon fas fa-times" aria-hidden="true"></i>
            Cancel
        </div>
    </div>
    </slx-spinner>
</div>
      