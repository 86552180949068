import * as tslib_1 from "tslib";
import 'rxjs/add/operator/combineLatest';
import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';
import { FileService } from './../../../../common/services/file/file.service';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { PayrollExportResultDialogComponent } from './../../../../time-and-attendance/components/timecards-display/payroll-export-result-dialog/payroll-export-result-dialog.component';
import { TimecardsApiService } from './../../../../time-and-attendance/services/timecards/timecards-api.service';
import { mutableSelect, unsubscribeInService } from './../../../../core/decorators';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { PayrollExportTestApiService } from './payroll-export-test-api.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { OrganizationPayrollStates } from '../../models/payroll-export-test/organization-payroll-states';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { appConfig } from './../../../../app.config';
import { Assert } from '../../../../framework/index';
import { OrgPayrollExportState } from '../../models/payroll-export-test/org-payroll-export-state';
var PayrollExportTestManagementService = /** @class */ (function () {
    function PayrollExportTestManagementService(api, taApi, modalService, activatedRoute, fileService, appSettingsManageService) {
        var _this = this;
        this.api = api;
        this.taApi = taApi;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.fileService = fileService;
        this.appSettingsManageService = appSettingsManageService;
        this.loadingChanged = new Subject();
        this.paycycleChanged = new Subject();
        this.urlParamsChanged = new Subject();
        this.paycyclesChanged = new Subject();
        this.isAccrualModuleEnabled$ = new ReplaySubject(1);
        this.isNextgenPayrollEnabled = false;
        this.exportToPayrollFrequency = 0;
        this.exportToPayrollDuration = 0;
        this.updateexportPayrollStatus = new Subject();
        this.isNextgenTimeExportEnabled = false;
        this.nextgenPhasedRollout = false;
        this.appConfig = appConfig;
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.activatedRoute.queryParams)
            .subscribe((function (value) {
            _this.startDate = null;
            _this.endDate = null;
            _this.setSelectedPaycycle(null);
            _this.setPaycycles(null);
            var orgLevel = value[0], queryParams = value[1];
            var queryOrgLevelId = Number(queryParams['orgLevelId']);
            if (queryOrgLevelId > 0 && queryOrgLevelId !== orgLevel.id) {
                return;
            }
            _this.orgLevel = orgLevel;
            var startDate = queryParams['startDate'];
            var endDate = queryParams['endDate'];
            if (startDate && endDate) {
                _this.startDate = moment(startDate, appConfig.linkDateFormat).toDate();
                _this.endDate = moment(endDate, appConfig.linkDateFormat).toDate();
            }
            if (orgLevel && _this.orgLevelId !== orgLevel.id) {
                _this.orgLevel = orgLevel;
                _this.orgLevelId = orgLevel.id;
                _this.updatePayCycleList();
            }
        }));
        this.appSettingsManageService.getAppServerConfig()
            .then(function (conf) {
            _this.isRequestExport = conf.payrollExpManagementEnabled;
            _this.isNextgenPayrollEnabled = conf.IsNextgenPayrollEnabled;
            _this.exportToPayrollFrequency = conf.ExportToPayrollFrequency;
            _this.exportToPayrollDuration = conf.ExportToPayrollDuration;
            _this.isNextgenTimeExportEnabled = conf.IsNextgenTimeExportEnabled;
            _this.nextgenPhasedRollout = conf.NextgenPhasedRollout;
        });
    }
    PayrollExportTestManagementService.prototype.updatePayCycleList = function () {
        var _this = this;
        if (this.orgLevelId > 0) {
            this.changeLoading(true);
            this.api.getPayCycles(this.orgLevelId)
                .then(function (value) {
                _this.setPaycycles(value);
                _this.autoSelectPaycycle();
                //this.updateList();
            });
        }
    };
    PayrollExportTestManagementService.prototype.updateList = function () {
        var _this = this;
        if (this.orgLevelId > 0 && this.selectedPayCycle) {
            this.changeLoading(true);
            this.api.getOrganizationPayrollList(this.orgLevelId, this.selectedPayCycle.startDate, this.selectedPayCycle.endDate)
                .then(function (value) {
                _this.orgList = value.organizationPayrollList;
                _this.settingsAvailable = value.settingsAvailable;
                _this.globalExportSettingsEnabled = value.globalExportSettingsEnabled;
                _this.canExportToPayroll = value.canExportToPayroll;
                _this.canChangeConfig = value.canChangeConfig;
                _this.canUnlockTimecards = value.canUnlockTimecards;
                _this.canLockTimecards = _this.selectedPayCycle.endDate < new Date();
                _this.setSelectedPaycycle(_this.selectedPayCycle);
                _this.changeLoading(false);
                _this.updateStatusOnload();
            });
        }
        else {
            this.changeLoading(false);
        }
    };
    PayrollExportTestManagementService.prototype.getTests = function (orgPayrollId) {
        var _this = this;
        this.changeLoading(true);
        return this.api.getTests(orgPayrollId).then(function (value) {
            var org = _.find(_this.orgList, function (org) { return org.organizationPayrollId === orgPayrollId; });
            org.state = value.organizationPayroll.state;
            org.alertCount = value.organizationPayroll.alertCount;
            org.failCount = value.organizationPayroll.failCount;
            org.canExportToPayroll = value.organizationPayroll.canExportToPayroll;
            org.isLocked = value.organizationPayroll.isLocked;
            _this.changeLoading(false);
            return value;
        });
    };
    PayrollExportTestManagementService.prototype.runTests = function (organizationId) {
        var _this = this;
        this.changeLoading(true);
        return this.api.runTests(organizationId, this.selectedPayCycle.startDate, this.selectedPayCycle.endDate)
            .then(function (value) {
            _this.changeLoading(false);
            return value;
        });
    };
    PayrollExportTestManagementService.prototype.requestExport = function (organizationId) {
        var _this = this;
        this.changeLoading(true);
        return this.taApi.submitPayroll(organizationId, this.selectedPayCycle.startDate, this.selectedPayCycle.endDate)
            .then(function (value) {
            if (value) {
                PayrollExportResultDialogComponent.openDialog(value, _this.modalService, function () { });
            }
            _this.changeLoading(false);
            return value;
        });
    };
    PayrollExportTestManagementService.prototype.getConfig = function () {
        var _this = this;
        this.changeLoading(true);
        return this.api.getConfig(this.orgLevelId)
            .then(function (value) {
            _this.changeLoading(false);
            return value;
        });
    };
    PayrollExportTestManagementService.prototype.saveConfig = function (config) {
        var _this = this;
        this.changeLoading(true);
        return this.api.saveConfig(config)
            .then(function (value) {
            _this.changeLoading(false);
            return value;
        });
    };
    PayrollExportTestManagementService.prototype.changeConfigIsGlobal = function () {
        var _this = this;
        this.changeLoading(true);
        var newValue = !this.globalExportSettingsEnabled;
        return this.api.saveGlobalFlag(newValue)
            .then(function (value) {
            if (value) {
                _this.globalExportSettingsEnabled = newValue;
            }
            _this.changeLoading(false);
            return value;
        });
    };
    PayrollExportTestManagementService.prototype.lockOrganizationPayroll = function (orgPayroll, lock) {
        var _this = this;
        this.changeLoading(true);
        return this.api.lockOrganizationPayroll(orgPayroll.organizationPayrollId, lock)
            .then(function (success) {
            if (success) {
                orgPayroll.isLocked = lock;
                if (!lock) {
                    orgPayroll.state = OrganizationPayrollStates.REOPENED;
                    orgPayroll.status = OrgPayrollExportState.Reopened;
                }
            }
            _this.changeLoading(false);
            return success;
        });
    };
    PayrollExportTestManagementService.prototype.exportToPayroll = function (organizationId) {
        var _this = this;
        this.changeLoading(true);
        return this.taApi.getExportedTimecards(organizationId, this.selectedPayCycle.startDate, this.selectedPayCycle.endDate)
            .then(function (file) {
            _this.fileService.saveToFileSystem(file.blob, file.file);
            _this.changeLoading(false);
            return true;
        })
            .catch(function (reason) {
            _this.changeLoading(false);
            return false;
        });
    };
    PayrollExportTestManagementService.prototype.changeLoading = function (isLoading) {
        this.loadingChanged.next(isLoading);
    };
    PayrollExportTestManagementService.prototype.autoSelectPaycycle = function () {
        this.selectedPayCycle = null;
        var paycycle;
        if (this.startDate && this.endDate) {
            var sd_1 = this.startDate.getTime();
            var ed_1 = this.endDate.getTime();
            paycycle = _.find(this.payCycles, function (pc) {
                return pc.startDate.getTime() === sd_1 && pc.endDate.getTime() === ed_1;
            });
        }
        if (!paycycle) {
            var current_1 = new Date();
            paycycle = _.find(this.payCycles, function (pc) {
                return pc.startDate <= current_1 && pc.endDate >= current_1;
            });
            if (!paycycle) {
                paycycle = _.maxBy(this.payCycles, function (pc) { return pc.endDate; });
            }
        }
        this.setSelectedPaycycle(paycycle);
    };
    PayrollExportTestManagementService.prototype.setSelectedPaycycle = function (paycycle) {
        this.selectedPayCycle = paycycle;
        if (!this.selectedPayCycle) {
            this.paycycleChanged.next(paycycle);
            return;
        }
        if (this.startDate && this.endDate) {
            var startSame = moment(this.startDate).isSame(paycycle.startDate);
            var endSame = moment(this.endDate).isSame(paycycle.endDate);
            if (startSame && endSame) {
                this.paycycleChanged.next(paycycle);
                return;
            }
        }
        this.orgLevelId = null;
        this.orgLevel = null;
        this.urlParamsChanged.next(paycycle);
        return;
    };
    PayrollExportTestManagementService.prototype.setPaycycles = function (paycycles) {
        this.payCycles = paycycles;
        this.paycyclesChanged.next(paycycles);
    };
    PayrollExportTestManagementService.prototype.subscribeToIsAccrualModuleEnabled = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.isAccrualModuleEnabled$.subscribe(callback);
    };
    PayrollExportTestManagementService.prototype.isAccrualModuleEnabled = function () {
        var _this = this;
        this.api.isAccrualModuleEnabled()
            .then(function (data) {
            _this.isAccrualModuleEnabled$.next(data);
        });
    };
    PayrollExportTestManagementService.prototype.updateStatus = function (exportedIds) {
        return this.api.getOrganizationPayrollExportStatus(exportedIds)
            .then(function (value) {
            return value;
        });
    };
    PayrollExportTestManagementService.prototype.updateStatusAndEnableBtn = function (orgPayrollId, state, isEnabled) {
        var org = _.find(this.orgList, function (org) { return org.organizationPayrollId === orgPayrollId; });
        org.status = state;
        org.isCheckingExportStatus = isEnabled;
        return org;
    };
    PayrollExportTestManagementService.prototype.getInprogressExportStatus = function () {
        var _this = this;
        var ids = Object.keys(this.orgList).map(function (key) { return _this.orgList[key].organizationPayrollId; }).join(",");
        return this.api.getInprogressExportStatus(ids)
            .then(function (value) {
            return value;
        });
    };
    PayrollExportTestManagementService.prototype.updateStatusOnload = function () {
        var liveWithTAExport = 'LiveWithTAExport';
        var isLiveWithTAExport = this.orgList.some(function (x) { return x.payrollAppStatus === liveWithTAExport; });
        if ((!this.nextgenPhasedRollout && this.isNextgenPayrollEnabled && this.isNextgenTimeExportEnabled)
            || (this.nextgenPhasedRollout && this.isNextgenPayrollEnabled && isLiveWithTAExport)) {
            var exportIds = JSON.parse(sessionStorage.getItem('PayrollExportTimeInterval'));
            if (exportIds === null || exportIds === undefined || exportIds.length === 0) {
                this.getInprogressExportStatus()
                    .then(function (value) {
                    sessionStorage.setItem('PayrollExportTimeInterval', JSON.stringify(value));
                });
            }
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayrollExportTestManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayrollExportTestManagementService.prototype, "orgLevelSubscription", void 0);
    return PayrollExportTestManagementService;
}());
export { PayrollExportTestManagementService };
