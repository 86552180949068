import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsI9ListA = /** @class */ (function () {
    function EmployeeSectionsI9ListA() {
    }
    EmployeeSectionsI9ListA.prototype.clear = function () {
        this.type = -1;
        this.documentId = null;
        this.documentExpiration = null;
    };
    return EmployeeSectionsI9ListA;
}());
export { EmployeeSectionsI9ListA };
var EmployeeSectionsI9ListB = /** @class */ (function () {
    function EmployeeSectionsI9ListB() {
    }
    EmployeeSectionsI9ListB.prototype.clear = function () {
        this.type = -1;
        this.other = null;
        this.documentId = null;
        this.documentExpiration = null;
    };
    return EmployeeSectionsI9ListB;
}());
export { EmployeeSectionsI9ListB };
var EmployeeSectionsI9ListC = /** @class */ (function () {
    function EmployeeSectionsI9ListC() {
    }
    EmployeeSectionsI9ListC.prototype.clear = function () {
        this.type = -1;
        this.documentId = null;
        this.documentExpiration = null;
    };
    return EmployeeSectionsI9ListC;
}());
export { EmployeeSectionsI9ListC };
var EmployeeSectionsI9AdditionalInfo = /** @class */ (function () {
    function EmployeeSectionsI9AdditionalInfo() {
    }
    EmployeeSectionsI9AdditionalInfo.prototype.clear = function () {
        this.type = -1;
        this.alienNumber = null;
        this.alienOrAdmissionNumber = null;
        this.expiration = null;
    };
    return EmployeeSectionsI9AdditionalInfo;
}());
export { EmployeeSectionsI9AdditionalInfo };
var EmployeeSectionsI9 = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsI9, _super);
    function EmployeeSectionsI9() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.listA = new EmployeeSectionsI9ListA();
        _this.listB = new EmployeeSectionsI9ListB();
        _this.listC = new EmployeeSectionsI9ListC();
        _this.additionalInfo = new EmployeeSectionsI9AdditionalInfo();
        return _this;
    }
    return EmployeeSectionsI9;
}(EmployeeSectionsBase));
export { EmployeeSectionsI9 };
export var I9ListATypes;
(function (I9ListATypes) {
    I9ListATypes[I9ListATypes["UsPassport"] = 0] = "UsPassport";
    I9ListATypes[I9ListATypes["UsCitizenshipCertificate"] = 1] = "UsCitizenshipCertificate";
    I9ListATypes[I9ListATypes["NaturalizationCertificate"] = 2] = "NaturalizationCertificate";
    I9ListATypes[I9ListATypes["ForeignPassportWithEmploymentAuth"] = 3] = "ForeignPassportWithEmploymentAuth";
    I9ListATypes[I9ListATypes["AlienRegistrationCard"] = 4] = "AlienRegistrationCard";
})(I9ListATypes || (I9ListATypes = {}));
export var I9ListBTypes;
(function (I9ListBTypes) {
    I9ListBTypes[I9ListBTypes["StateIssuedDriversLicense"] = 0] = "StateIssuedDriversLicense";
    I9ListBTypes[I9ListBTypes["UsMilitaryCard"] = 1] = "UsMilitaryCard";
    I9ListBTypes[I9ListBTypes["IsOther"] = 2] = "IsOther";
})(I9ListBTypes || (I9ListBTypes = {}));
export var I9ListCTypes;
(function (I9ListCTypes) {
    I9ListCTypes[I9ListCTypes["OriginalSSNCard"] = 0] = "OriginalSSNCard";
    I9ListCTypes[I9ListCTypes["BirthCertificate"] = 1] = "BirthCertificate";
    I9ListCTypes[I9ListCTypes["UnexpiredINSEmploymentAuth"] = 2] = "UnexpiredINSEmploymentAuth";
})(I9ListCTypes || (I9ListCTypes = {}));
export var I9AdditionalInfoTypes;
(function (I9AdditionalInfoTypes) {
    I9AdditionalInfoTypes[I9AdditionalInfoTypes["UsCitizen"] = 0] = "UsCitizen";
    I9AdditionalInfoTypes[I9AdditionalInfoTypes["NonUsCitizen"] = 1] = "NonUsCitizen";
    I9AdditionalInfoTypes[I9AdditionalInfoTypes["LawfulPermanentResident"] = 2] = "LawfulPermanentResident";
    I9AdditionalInfoTypes[I9AdditionalInfoTypes["AlienAuthorizedToWork"] = 3] = "AlienAuthorizedToWork";
})(I9AdditionalInfoTypes || (I9AdditionalInfoTypes = {}));
