/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aca-c1095-audit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./aca-c1095-audit-toolbar/aca-c1095-audit-toolbar.component.ngfactory";
import * as i5 from "./aca-c1095-audit-toolbar/aca-c1095-audit-toolbar.component";
import * as i6 from "../../services/aca-c1095-audit/aca-c1095-audit-management.service";
import * as i7 from "./aca-c1095-audit-grid/aca-c1095-audit-grid.component.ngfactory";
import * as i8 from "./aca-c1095-audit-grid/aca-c1095-audit-grid.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../common/services/column-settings/column-management.service";
import * as i11 from "../../../common/services/column-settings/column-settings-storage.service";
import * as i12 from "../../../common/services/state-management/state-management.service";
import * as i13 from "../../../common/services/component-state/component-state-storage.service";
import * as i14 from "../../services/aca-c1095-audit/aca-c1095-audit-api.service";
import * as i15 from "./aca-c1095-audit.component";
var styles_AcaC1095AuditComponent = [i0.styles];
var RenderType_AcaC1095AuditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AcaC1095AuditComponent, data: {} });
export { RenderType_AcaC1095AuditComponent as RenderType_AcaC1095AuditComponent };
export function View_AcaC1095AuditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-aca-c1095-audit-toolbar", [], null, null, null, i4.View_AcaC1095AuditToolbarComponent_0, i4.RenderType_AcaC1095AuditToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i5.AcaC1095AuditToolbarComponent, [i6.AcaC1095AuditManagementService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents grid-1095c-audit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-aca-c1095-audit-grid", [], null, null, null, i7.View_AcaC1095AuditGridComponent_0, i7.RenderType_AcaC1095AuditGridComponent)), i1.ɵdid(8, 245760, null, 0, i8.AcaC1095AuditGridComponent, [i6.AcaC1095AuditManagementService, i9.Router, i9.ActivatedRoute, i1.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_AcaC1095AuditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-aca-c1095-audit", [], null, null, null, View_AcaC1095AuditComponent_0, RenderType_AcaC1095AuditComponent)), i1.ɵprd(4608, null, i10.ColumnManagementService, i10.ColumnManagementService, [i11.ColumnSettingsStorageService]), i1.ɵprd(512, null, i12.StateManagementService, i12.StateManagementService, [i13.ComponentStateStorageService, i11.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.AcaC1095AuditManagementService, i6.AcaC1095AuditManagementService, [i14.AcaC1095AuditApiService, i12.StateManagementService]), i1.ɵdid(4, 245760, null, 0, i15.AcaC1095AuditComponent, [i6.AcaC1095AuditManagementService, i12.StateManagementService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var AcaC1095AuditComponentNgFactory = i1.ɵccf("slx-aca-c1095-audit", i15.AcaC1095AuditComponent, View_AcaC1095AuditComponent_Host_0, {}, {}, []);
export { AcaC1095AuditComponentNgFactory as AcaC1095AuditComponentNgFactory };
