<slx-spinner [show]="state.isLoading">

<div class="search-container">
        <slx-query-builder-component class="query-builder" #queryBuider [treeModel]="queryModel" [hidden]="!state.queryBuilder">

            <ng-template #treeRootTemplate let-treeModel let-templates="templates">

                <form [formGroup]="queryModel.root.data.formGroup" (ngSubmit)="onSubmit($event)">

                    <div class="relation-tree">

                            <slx-tree-node-component *ngIf="treeModel.showRoot"
                            [node]="treeModel.root"
                            [index]="0"
                            [templates] = "templates"
                            >
                            </slx-tree-node-component>

                            <slx-tree-node-children-component *ngIf="!treeModel.showRoot"
                            [node]="treeModel.root"
                            [templates] = "templates"
                            >
                            </slx-tree-node-children-component>
                    </div>

                    <div class="actions-box">
                        <button type="submit" class="theme-button-apply"
                        [disabled]="queryModel.root.data.formGroup.invalid">Search</button>
                     </div>

                    <div class="alert alert-warning" [hidden] ="!queryModel.root.data.formGroup.invalid">
                        <strong>Warning!</strong> Please, fill all fields and correct marked values.
                    </div>

                </form>

            </ng-template>

            <ng-template #treeNodeChildrenTemplate let-node let-templates="templates">

                <div class="subtree" >
                    <slx-tree-node-component
                        *ngFor="let childNode of node.children; let i = index"
                        [node]="childNode"
                        [index]="i"
                        [templates]="templates"
                    >
                    </slx-tree-node-component>
                </div>

            </ng-template>


            <ng-template #treeNodeFullTemplate let-node let-templates="templates" let-index="index">

                <slx-expression-selector-component [node]="node" [index]="index" [templates]="templates"></slx-expression-selector-component>

            </ng-template>

        </slx-query-builder-component>

        <slx-employee-search-results class="search-results" [hidden]="!state.displayResults"
                                     [searchResults]="searchResults" (backToQuery)="onBackToQuery ()">

        </slx-employee-search-results>
</div>

</slx-spinner>
