import { Component, Provider } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { LookupApiService } from '../../../../organization/services/index';
import { BudgetDefinition } from '../../../../organization/models/index';
import { RestoreBudgetReq } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-budget-restore-dialog',
  templateUrl: 'budget-restore-dialog.component.html',
  styleUrls: ['budget-restore-dialog.component.scss']
})
export class BudgetRestoreDialogComponent {
  public options: DialogOptions;
  public dialogResult: boolean;
  public budgetDefinition: BudgetDefinition;
  public targetBudget: 'Exist' | 'New';
  public startDate: Date;
  public endDate?: Date;
  public filterSelected: Function;
  public selectedBudgetDefinition: BudgetDefinition;
  public restoreRequest: RestoreBudgetReq;

  private modalService: ModalService;
  private lookupApiService: LookupApiService;

  public static openDialog(req: RestoreBudgetReq, modalService: ModalService, callback: (result: boolean, cmd: BudgetDefinition) => void): BudgetRestoreDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 350;
    dialogOptions.width = 600;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: RestoreBudgetReq,
        useValue: req
      }
    ];
    let header: string = `Restore budget`;
    let dialog: BudgetRestoreDialogComponent = modalService.globalAnchor
      .openDialog(BudgetRestoreDialogComponent, header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        let cmd: BudgetDefinition = dialog.budgetDefinition;
        callback(result, cmd);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    lookupApiService: LookupApiService,
    req: RestoreBudgetReq) {
    this.options = options;
    this.modalService = modalService;
    this.lookupApiService = lookupApiService;
    this.dialogResult = false;
    this.restoreRequest = req;
    this.targetBudget = 'Exist';
    this.startDate = moment().startOf('day').toDate();
    this.endDate = null;
    this.filterSelected = (items: BudgetDefinition[]): BudgetDefinition[] => {
      return _.filter(items, (item: BudgetDefinition) => {
        return item.id !== this.restoreRequest.sourceBudgetId;
      });
    };
  }

  public onExisting(): void {
    this.targetBudget = 'Exist';
  }
  public onNew(): void {
    this.targetBudget = 'New';
  }

  public onOk(): void {
    if (this.targetBudget === 'Exist') {
      this.budgetDefinition = this.selectedBudgetDefinition;
    } else {

      this.budgetDefinition = new BudgetDefinition();
      this.budgetDefinition.id = 0;
      this.budgetDefinition.orgLevelId = this.restoreRequest.orgLevelId;
      this.budgetDefinition.startDate = this.startDate;
      this.budgetDefinition.endDate = this.endDate;
    }
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
