import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../app.config';
import { BenefitClassApiService, BenefitClassValidatorAdapter } from '../../../services/index';
import { BenefitClass } from '../../../models/index';
import { BenefitClassDefinition } from '../../../../../organization/models/index';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../../common/services/index';
import { IScreenUtils, screenUtils } from '../../../../../common/utils/index';
import { InfoDialogComponent } from '../../../../../common/components/index';
import { mutableSelect } from '../../../../../core/decorators/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { subscribeToOrgLevel } from '../../../../../organization/selectors/index';
var BenefitClassConfigurationComponent = /** @class */ (function () {
    function BenefitClassConfigurationComponent(apiService, stateManagement, columnManagementService, modalService, benefitClassValidatorAdapter) {
        this.apiService = apiService;
        this.stateManagement = stateManagement;
        this.columnManagementService = columnManagementService;
        this.modalService = modalService;
        this.benefitClassValidatorAdapter = benefitClassValidatorAdapter;
        this.pageSize = 50;
        this.columnGroup = 'BenefitClasses';
        this.nameValidation = BenefitClassValidatorAdapter.benefitClassNameValidation;
        this.codeValidation = BenefitClassValidatorAdapter.benefitClassCodeValidation;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'benefitClassDefinition.name', dir: 'asc' }];
        this.gridState.state.take = this.pageSize;
        this.appConfig = appConfig;
        this.screenUtils = screenUtils;
    }
    BenefitClassConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('BenefitClassConfigurationComponent');
        this.columnManagementService.init('BenefitClassConfigurationComponent');
        this.columnManagementService.initGroup(this.columnGroup, 6);
        this.subscriptions.orgLevel = subscribeToOrgLevel(this.orgLevel$, function () { return _this.orgLevel; }, function (orgLevel) { return _this.reload(orgLevel); });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            _this.isLoading = true;
            if (event.isNew) {
                _this.doAdd(event.dataItem);
                return;
            }
            _this.doEdit(event.dataItem, event.rowIndex);
        });
        this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            _this.doRemove(event.dataItem, event.rowIndex);
        });
    };
    BenefitClassConfigurationComponent.prototype.reload = function (orgLevel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                this.orgLevel = orgLevel;
                return [2 /*return*/, this.apiService.getBenefitClasses(this.orgLevel.id)
                        .then(function (records) {
                        _this.records = records;
                        _this.refreshGrid();
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    BenefitClassConfigurationComponent.prototype.doAdd = function (dataItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.addBenefitClass(dataItem)
                        .then(function (res) {
                        if (res.isSuccess) {
                            _this.records.push(res.item);
                            _this.refreshGrid();
                        }
                        else {
                            InfoDialogComponent.OpenDialog('Warning', res.message, _this.modalService);
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    BenefitClassConfigurationComponent.prototype.doEdit = function (dataItem, rowIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.saveBenefitClass(dataItem)
                        .then(function (res) {
                        var index = _.findIndex(_this.records, function (c) { return c.benefitClassDefinition.id === res.item.benefitClassDefinition.id; });
                        if (!res.isSuccess) {
                            _this.records.splice(index, 1, res.item);
                            _this.refreshGrid();
                            InfoDialogComponent.OpenDialog('Warning', res.message, _this.modalService);
                        }
                        else {
                            _this.records[index].modifiedOn = res.item.modifiedOn;
                            _this.records[index].modifiedBy = res.item.modifiedBy;
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    BenefitClassConfigurationComponent.prototype.doRemove = function (dataItem, rowIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                return [2 /*return*/, this.apiService.deleteBenefitClass(dataItem.benefitClassDefinition.id)
                        .then(function (res) {
                        if (res.isSuccess) {
                            var index = _.findIndex(_this.records, function (c) { return c.benefitClassDefinition.id === res.item.benefitClassDefinition.id; });
                            _this.records.splice(index, 1);
                            _this.refreshGrid();
                        }
                        else {
                            InfoDialogComponent.OpenDialog('Warning', res.message, _this.modalService);
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    BenefitClassConfigurationComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    BenefitClassConfigurationComponent.prototype.addClass = function () {
        var model = new BenefitClass();
        model.benefitClassDefinition = new BenefitClassDefinition();
        model.benefitClassDefinition.orgLevelId = this.orgLevel.id;
        model.orgLevelName = this.orgLevel.name;
        model.orgLevelDirectionForEdit = 0;
        this.gridState.closeEditor(this.kendoGrid);
        this.kendoGrid.addRow(model);
    };
    BenefitClassConfigurationComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BenefitClassConfigurationComponent.prototype, "orgLevel$", void 0);
    return BenefitClassConfigurationComponent;
}());
export { BenefitClassConfigurationComponent };
