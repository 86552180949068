<div class="modal-content">
    <div class="modal-body">
        <div class="bootbox-body">

            <span>{{options?.message}}</span>

            <div class="items-list-holder" *ngIf="hasDeleted">
                <span>Deleted:</span>
                <ul>
                    <li *ngFor="let ben of deletedList">
                        {{ben.benefitClass.name}}
                        <span>({{ben.startDate | amDateFormat: appConfig.dateFormat }}-{{ ben.endDate | amDateFormat: appConfig.dateFormat | slxHideMaxDate}})</span>
                    </li>
                </ul>
            </div>

            <div class="items-list-holder" *ngIf="hasChanged">
                <span>Changed:</span>
                <ul>
                    <li *ngFor="let ben of changedList">
                        {{ben.benefitClass.name}}
                        <span>({{ben.startDate | amDateFormat: appConfig.dateFormat }}-{{ ben.endDate | amDateFormat: appConfig.dateFormat | slxHideMaxDate}})</span>
                    </li>
                </ul>
            </div>

            <div class="items-list-holder" *ngIf="hasAdded">
                <span>Added:</span>
                <ul>
                    <li *ngFor="let ben of addedList">
                        {{ben.benefitClass.name}}
                        <span>({{ben.startDate | amDateFormat: appConfig.dateFormat }}-{{ ben.endDate | amDateFormat: appConfig.dateFormat  | slxHideMaxDate}})</span>
                    </li>
                </ul>
            </div>

        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="onCancel()" class="window-button theme-button-cancel margin-r">Cancel</button>
        <button type="button" (click)="onOk()" class="window-button theme-button-apply">Continue</button>
    </div>
</div>
