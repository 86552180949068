import * as tslib_1 from "tslib";
import { EmployeeColumnsDefinition } from './employeeColumnsDefinition';
var EmployeeActionDefinition = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeActionDefinition, _super);
    function EmployeeActionDefinition() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(EmployeeActionDefinition.prototype, "isMassRateAdjustments", {
        get: function () {
            return this.type == 'mass-rate-adjustments';
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeActionDefinition;
}(EmployeeColumnsDefinition));
export { EmployeeActionDefinition };
var EmployeeActionDefinitions = /** @class */ (function () {
    function EmployeeActionDefinitions() {
        this.actions = [];
    }
    return EmployeeActionDefinitions;
}());
export { EmployeeActionDefinitions };
