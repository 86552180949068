import { Directive, Optional, Input, OnInit, DoCheck, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

import * as _ from 'lodash';

import { DropdownInputComponent } from '../../../common/components/index';

@Directive({
  selector: 'slx-dropdown-input[emptyOption]'
})
export class EmptyOptionDirective implements OnInit, DoCheck {
  @Input()
  public emptyOption: string | Object;

  private dropdown: DropdownInputComponent;
  private lastEmptyOption: any;

  constructor( @Optional() dropdown: DropdownInputComponent) {
    this.dropdown = dropdown;
  }

  public ngOnInit(): void {
    this.lastEmptyOption = undefined;
  }

  public ngDoCheck(): void {
    let doAdd: boolean = false;
    doAdd = !!this.dropdown.items;
    if (this.dropdown.items && (!this.lastEmptyOption || this.dropdown.emptyOption !== this.lastEmptyOption)) {
      let emptyOption: any = this.createEmptyOption();
      this.lastEmptyOption = emptyOption;
      this.dropdown.emptyOption = emptyOption;
    }
  }

  private createEmptyOption(): any {
    let optionToAdd: any = undefined;
    if (typeof this.emptyOption === 'string') {
      optionToAdd = {
        [this.dropdown.valueField]: 0,
        [this.dropdown.titleField]: this.emptyOption
      };
    } else if (this.emptyOption instanceof Object) {
      optionToAdd = this.emptyOption;
    }
    return optionToAdd;
  }
}
