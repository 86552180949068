import { Component, OnInit, Input, OnDestroy, forwardRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { CommonValidators } from '../../../../common/validators/common-validators';
import { Timeclock, TimeclockContainer } from '../../../models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';
import { ListActionsService } from '../../../services/index';

import { LookupService } from '../../../../organization/services/index';
import { Organization, Department, Lookup, LookupType, ILookupRequest } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';

import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';

@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-editor',
  templateUrl: 'timeclock-editor.component.html',
  styleUrls: ['timeclock-editor.component.scss'],
  providers: [
    {
      provide: CustomListActorBase,
      useExisting: forwardRef(() => TimeclockEditorComponent)
    }
  ]
})
export class TimeclockEditorComponent extends EditableListEditorComponent implements OnDestroy, OnInit {

  @Input('item')
  public set item(item: Timeclock) {
    this.m_item = item;
    this.setRecords();
  }

  public get item(): Timeclock {
    return this.m_item;
  }

  @Input('container')
  public set containerInput(container: TimeclockContainer) {
    this.container = container;
    this.setRecords();
  }

  public canSetOpenShiftRequests: boolean;

  @unsubscribe()
  private orgSubscribe: Subscription;
  @unsubscribe()
  private depSubscribe: Subscription;
  @unsubscribe()
  private msgSubscribe: Subscription;
  @unsubscribe()
  private idSubscribe: Subscription;
  @unsubscribe()
  private nameSubscribe: Subscription;
  @unsubscribe()
  private phSubscribe: Subscription;

  public get timezoneLookup(): Lookup {
    return this.m_timezoneLookup;
  }
  private m_timezoneLookup: Lookup;

  public get isMessagesEnabled(): boolean {
    return this.m_isMessagesEnabled;
  }
  private m_isMessagesEnabled: boolean;

  public get organizationLookup(): Lookup {
    return this.m_organizationLookup;
  }
  private m_organizationLookup: Lookup;

  public get departmentLookup(): Lookup {
    return this.m_departmentLookup;
  }
  private m_departmentLookup: Lookup;

  public get clockModelLookup(): Lookup {
    return this.m_clockModelLookup;
  }
  private m_clockModelLookup: Lookup;

  private container: TimeclockContainer;
  private m_item: Timeclock;
  private lookupService: LookupService;
  private records: Timeclock[];

  private name: string;
  private id: number;
  private physicalId: number;
  private organization: Organization;
  private department: Department;

  constructor(lookupService: LookupService) {
    super();
    this.lookupService = lookupService;
    this.loadOrganizations();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    // See #issueWithAOTCompiler
  }

  public ngOnInit() : void {
    super.ngOnInit();
    this.canSetOpenShiftRequests = !this.item.isVirtual;
  }

  public loadOrganizations(): void {
    this.lookupService.getLookup({ lookupType: LookupType.organization, orgLevelId: undefined, employeeId: undefined })
      .then((value: Lookup) => {
        this.m_organizationLookup = value;
        this.organization = undefined;
        if (this.item && this.item.organization) {
          this.organization = _.find(this.m_organizationLookup.items, (org: Organization) => {
            return org.orgLevelId === this.item.organization.orgLevelId;
          });
          if (this.organization) {
            this.item.organization = this.organization;
            this.formGroup.get('organization').setValue(this.item.organization);
          }
        }
        this.loadDepartments();
        this.loadModels();
        this.loadTimezones();
      });
  }

  public loadDepartments(): void {
    if (!this.organization) {
      this.m_departmentLookup = undefined;
      this.loadModels();
      return;
    }
    this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.organization.orgLevelId, employeeId: undefined })
      .then((value: Lookup) => {
        this.m_departmentLookup = value;
        let all: Department = new Department();
        all.id = 0;
        all.name = 'All';
        this.m_departmentLookup.items.unshift(all);
        this.department = undefined;
        if (this.item.department) {
          this.department = _.find(this.m_departmentLookup.items, (d: Department) => {
            return d.orgLevelId === this.item.department.orgLevelId;
          });
          if (this.department) {
            this.formGroup.get('department').setValue(this.department);
          }
        }
        this.loadModels();
        this.loadTimezones();
      });
  }

  public loadModels(): void {
    if (!this.organization) {
      this.m_clockModelLookup = undefined;
      return;
    }
    let orgLevelId: number = this.department ? this.department.orgLevelId : this.organization.orgLevelId;
    this.lookupService.getLookup({ lookupType: LookupType.timeclockModelDefinition, orgLevelId: orgLevelId })
      .then((value: Lookup) => {
        this.m_clockModelLookup = value;
      });
  }

  public loadTimezones(): void {
    if (!this.organization) {
      this.m_timezoneLookup = undefined;
      return;
    }
    let orgLevelId: number = this.department ? this.item.department.orgLevelId : this.organization.orgLevelId;
    this.lookupService.getLookup({ lookupType: LookupType.timezoneDefinition, orgLevelId: orgLevelId })
      .then((value: Lookup) => {
        this.m_timezoneLookup = value;
      });
  }

  public uniqueCheck(): void {
    if (!this.records) {
      return;
    }
    let t: Timeclock = _.find(this.records, (timeclock: Timeclock) => {
      return timeclock.id === this.id && timeclock.name === this.name && timeclock.physicalId === this.physicalId;
    });
    if (t) {
      this.formGroup.get('name').setErrors({ 'uniqueTimeclock': true });
      this.formGroup.get('id').setErrors({ 'uniqueTimeclock': true });
      this.formGroup.get('physicalId').setErrors({ 'uniqueTimeclock': true });
    } else {
      this.omitUniqueError('name');
      this.omitUniqueError('id');
      this.omitUniqueError('physicalId');
    }
  }

  public onIsVirtualClick() : void {
    this.canSetOpenShiftRequests = this.formGroup.get('isVirtual').value;
  }

  protected updateItem(): void {
    this.item.organization = this.formGroup.get('organization').value;
    this.item.department = this.formGroup.get('department').value;
    this.item.name = this.formGroup.get('name').value;
    this.item.model = this.formGroup.get('model').value;
    this.item.id = this.formGroup.get('id').value;
    this.item.physicalId = this.formGroup.get('physicalId').value;
    this.item.cmdMessageLine1 = this.formGroup.get('cmdMessageLine1').value;
    this.item.cmdMessageLine2 = this.formGroup.get('cmdMessageLine2').value;
    this.item.cmdMessageLine3 = this.formGroup.get('cmdMessageLine3').value;
    this.item.cmdMessageLine4 = this.formGroup.get('cmdMessageLine4').value;
    this.item.timezone = this.formGroup.get('timezone').value;
    this.item.ipaddress = this.formGroup.get('ipaddress').value;
    this.item.isVirtual = this.formGroup.get('isVirtual').value;
    this.item.openShiftRequestsEnabled = !this.item.isVirtual && this.formGroup.get('openShiftRequestsEnabled').value;
    this.item.isMessagesEnabled = this.formGroup.get('isMessagesEnabled').value;
  }

  protected createFormGroup(): FormGroup {
    this.m_isMessagesEnabled = this.item.isMessagesEnabled;
    let fg: FormGroup = new FormGroup({
      organization: new FormControl(this.item.organization, Validators.required),
      department: new FormControl(this.item.department, Validators.required),
      name: new FormControl(this.item.name, Validators.required),
      model: new FormControl(this.item.model, Validators.required),
      id: new FormControl(this.item.id, [Validators.required, CommonValidators.number]),
      physicalId: new FormControl(this.item.physicalId, [Validators.required, CommonValidators.number]),
      cmdMessageLine1: new FormControl(this.item.cmdMessageLine1),
      cmdMessageLine2: new FormControl(this.item.cmdMessageLine2),
      cmdMessageLine3: new FormControl(this.item.cmdMessageLine3),
      cmdMessageLine4: new FormControl(this.item.cmdMessageLine4),
      timezone: new FormControl(this.item.timezone, Validators.required),
      ipaddress: new FormControl(this.item.ipaddress, CommonValidators.ipv4),
      isVirtual: new FormControl(this.item.isVirtual),
      openShiftRequestsEnabled: new FormControl(this.item.openShiftRequestsEnabled),
      isMessagesEnabled: new FormControl(this.item.isMessagesEnabled),
    });

    this.orgSubscribe = fg.get('organization').valueChanges.subscribe((value: Organization) => {
      this.organization = value;
      this.loadDepartments();
    });
    this.depSubscribe = fg.get('department').valueChanges.subscribe((value: Department) => {
      this.department = value;
      this.loadModels();
      this.loadTimezones();
    });
    this.msgSubscribe = fg.get('isMessagesEnabled').valueChanges.subscribe((value: boolean) => {
      this.m_isMessagesEnabled = value;
    });
    this.idSubscribe = fg.get('name').valueChanges.subscribe((value: string) => {
      this.name = value;
      this.uniqueCheck();
    });
    this.nameSubscribe = fg.get('id').valueChanges.subscribe((value: number) => {
      this.id = value;
      this.uniqueCheck();
    });
    this.phSubscribe = fg.get('physicalId').valueChanges.subscribe((value: number) => {
      this.physicalId = value;
      this.uniqueCheck();
    });
    return fg;
  }

  protected updateFormGroup(): void {
    this.formGroup.get('organization').setValue(this.item.organization);
    this.formGroup.get('department').setValue(this.item.department);
    this.formGroup.get('name').setValue(this.item.name);
    this.formGroup.get('model').setValue(this.item.model);
    this.formGroup.get('id').setValue(this.item.id);
    this.formGroup.get('physicalId').setValue(this.item.physicalId);
    this.formGroup.get('cmdMessageLine1').setValue(this.item.cmdMessageLine1);
    this.formGroup.get('cmdMessageLine2').setValue(this.item.cmdMessageLine2);
    this.formGroup.get('cmdMessageLine3').setValue(this.item.cmdMessageLine3);
    this.formGroup.get('cmdMessageLine4').setValue(this.item.cmdMessageLine4);
    this.formGroup.get('timezone').setValue(this.item.timezone);
    this.formGroup.get('ipaddress').setValue(this.item.ipaddress);
    this.formGroup.get('isVirtual').setValue(this.item.isVirtual);
    this.formGroup.get('openShiftRequestsEnabled').setValue(this.item.openShiftRequestsEnabled);
    this.formGroup.get('isMessagesEnabled').setValue(this.item.isMessagesEnabled);
  }

  private setRecords(): void {
    if (!this.container || !this.item) {
      return;
    }
    this.records = _.filter(this.container.records, (record: Timeclock) => {
      return this.item.id !== record.id || this.item.name !== record.name || this.item.physicalId !== record.physicalId;
    });
  }

  private omitUniqueError(name: string): void {
    let errors: any = _.omit(this.formGroup.get(name).errors, 'uniqueTimeclock');
    if (_.values(errors).length === 0) {
      errors = null;
    }
    this.formGroup.get(name).setErrors(errors);
  }
}
