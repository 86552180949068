import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { DateRange, IDateRange } from '../../../../../core/models/index';
import { DeviceDetectorService } from '../../../../../common/services/index';
import { ColumnManagementService, StateManagementService } from '../../../../../common/services/index';
import { TempSensorReadingManagementService } from '../../../../../configuration/services';
import { TempSensorReadingEvent, TempSensorReadingEventCmd } from '../../../../../configuration/models';
import { unsubscribeAll } from '../../../../..//core/decorators';
var TempReadingsToolbarComponent = /** @class */ (function () {
    function TempReadingsToolbarComponent(managementService, columnManagementService, stateManagement, devDetector) {
        this.managementService = managementService;
        this.columnManagementService = columnManagementService;
        this.stateManagement = stateManagement;
        this.devDetector = devDetector;
        this.dateRange = new DateRange(null, null);
        this.dayInSec = 60 * 60 * 24;
        this.maxSecondsRange = 90 * this.dayInSec;
        this.maxRangeError = 'The dates range cannot be more than 90 days';
        this.canExportData = false;
        this.subscriptions = {};
        this.columnsStateName = 'TempReadings';
    }
    Object.defineProperty(TempReadingsToolbarComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TempReadingsToolbarComponent.prototype, "dateRangeWidth", {
        get: function () {
            return this.isDesktop ? 325 : 246;
        },
        enumerable: true,
        configurable: true
    });
    TempReadingsToolbarComponent.prototype.ngOnInit = function () {
        this.initServices();
        this.defineDateRange();
    };
    TempReadingsToolbarComponent.prototype.ngOnDestroy = function () { };
    TempReadingsToolbarComponent.prototype.onChangeDates = function (range) {
        this.managementService.changeDateRange(range);
    };
    TempReadingsToolbarComponent.prototype.initServices = function () {
        var _this = this;
        this.stateManagement.init('TempReadingsComponent');
        this.columnManagementService.init('TempReadingsComponent');
        this.columnManagementService.initGroup(this.columnsStateName, 7);
        this.managementService.subscribeToUserActionsChanged(function (actions) {
            _this.canExportData = _.some(actions, function (x) { return x.name === 'Export To Excel'; });
        });
    };
    TempReadingsToolbarComponent.prototype.onExportToExcel = function () {
        var action = new TempSensorReadingEvent();
        action.cmd = TempSensorReadingEventCmd.excelExport;
        this.managementService.exportTo(action);
    };
    TempReadingsToolbarComponent.prototype.onExportToPdf = function () {
        var action = new TempSensorReadingEvent();
        action.cmd = TempSensorReadingEventCmd.pdfExport;
        this.managementService.exportTo(action);
    };
    TempReadingsToolbarComponent.prototype.defineDateRange = function () {
        this.dateRange = this.managementService.getDefaultDateRange();
        this.onChangeDates(this.dateRange);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], TempReadingsToolbarComponent.prototype, "subscriptions", void 0);
    return TempReadingsToolbarComponent;
}());
export { TempReadingsToolbarComponent };
