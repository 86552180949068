import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { OrgLevelWatchService } from '../../services/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { process, State } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../core/decorators/index';
var OrgLevelSearchComponent = /** @class */ (function () {
    function OrgLevelSearchComponent(orgLevelWatchService) {
        this.orgLevelWatchService = orgLevelWatchService;
        this.state = {
            isLoading: false,
        };
        this.gridState = new KendoGridStateHelper();
    }
    OrgLevelSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.selectionSubscription = this.gridState.onSelectionChanged.subscribe(function (selectedRecords) {
            if (!selectedRecords) {
                _this.selectedOrgLevel = null;
                return;
            }
            _this.selectedOrgLevel = _.first(selectedRecords);
        });
        this.loadOrgLevels();
    };
    OrgLevelSearchComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    OrgLevelSearchComponent.prototype.loadOrgLevels = function () {
        this.records = this.orgLevelWatchService.getFlatList();
        this.refreshGrid();
    };
    OrgLevelSearchComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrgLevelSearchComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrgLevelSearchComponent.prototype, "selectionSubscription", void 0);
    return OrgLevelSearchComponent;
}());
export { OrgLevelSearchComponent };
