import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Provider, OnInit } from '@angular/core';
import { ModalService, DialogOptions, ConfirmDialogComponent, ConfirmOptions } from '../../../../common/index';
import { LookupService, LookupType, EmployeeBenefitClass, EmployeeDefinitionsApiService } from '../../../../organization/index';
import { BenefitClassDialogReq } from '../../models/index';
var BenefitClassDialogComponent = /** @class */ (function () {
    function BenefitClassDialogComponent(options, modalService, lookupService, request, employeeDefinitionsApiService) {
        this.options = options;
        this.modalService = modalService;
        this.lookupService = lookupService;
        this.request = request;
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        this.isLoading = false;
        this.disabledBenifitFooterBtn = false;
        this.benefitClassEffectiveDate = null;
        this.employeeBenefitClass = this.request.employeeBenefitClass || new EmployeeBenefitClass();
        if (this.employeeBenefitClass.startDate) {
            this.benefitClassEffectiveDate = this.employeeBenefitClass.startDate;
        }
    }
    BenefitClassDialogComponent.OpenDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 300;
        dialogOptions.width = 400;
        var resolvedProviders = [{
                provide: DialogOptions,
                useValue: dialogOptions
            }, {
                provide: BenefitClassDialogReq,
                useValue: request
            }];
        var dialog = modalService.globalAnchor.openDialog(BenefitClassDialogComponent, 'Benefit Class', dialogOptions, resolvedProviders, function (result) {
            callback(result);
        });
        return dialog;
    };
    BenefitClassDialogComponent.prototype.ngOnInit = function () {
        this.load();
    };
    BenefitClassDialogComponent.prototype.onOk = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.benefitClass || !this.benefitClassEffectiveDate) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        this.dialogResult = true;
                        if (!(!this.request.employeeBenefitClass ||
                            this.benefitClass.id !== this.request.employeeBenefitClass.benefitClassId ||
                            this.benefitClassEffectiveDate !== this.request.employeeBenefitClass.startDate)) return [3 /*break*/, 3];
                        this.employeeBenefitClass.id = 0;
                        this.employeeBenefitClass.empId = this.request.employeeId;
                        this.employeeBenefitClass.benefitClass = this.benefitClass;
                        this.employeeBenefitClass.benefitClassId = this.benefitClass.id;
                        this.employeeBenefitClass.startDate = this.benefitClassEffectiveDate;
                        this.employeeBenefitClass.endDate = null;
                        this.employeeBenefitClass.dateHired = this.request.hireDate;
                        this.isLoading = true;
                        return [4 /*yield*/, this.employeeDefinitionsApiService.postBenefitsClass(this.request.employeeId, this.employeeBenefitClass)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        this.dialogResult = false;
                        return [3 /*break*/, 5];
                    case 5:
                        this.isLoading = false;
                        this.modalService.closeWindow(this.options.windowUniqueId);
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitClassDialogComponent.prototype.onCancel = function () {
        var _this = this;
        this.disabledBenifitFooterBtn = true;
        var confirmOptions = new ConfirmOptions();
        confirmOptions.showOK = true;
        confirmOptions.showCancel = true;
        confirmOptions.buttonOKtext = 'Yes';
        confirmOptions.buttonCanceltext = 'No';
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to discard the changes that you made?', this.modalService, function (result) {
            _this.disabledBenifitFooterBtn = false;
            if (result) {
                _this.dialogResult = false;
                _this.modalService.closeWindow(_this.options.windowUniqueId);
            }
        }, confirmOptions);
    };
    BenefitClassDialogComponent.prototype.load = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var benefitClassesLookup, benefitClassDefinition;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.request || !this.request.employeeId) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.lookupService.getLookup({
                                lookupType: LookupType.benefitClasses,
                                employeeId: this.request.employeeId
                            })];
                    case 1:
                        benefitClassesLookup = _a.sent();
                        this.benefitClassesLookup = benefitClassesLookup;
                        if (this.request.employeeBenefitClass && this.benefitClassesLookup) {
                            benefitClassDefinition = _.find(this.benefitClassesLookup.items, function (benefitClass) { return benefitClass.id === _this.request.employeeBenefitClass.benefitClassId; });
                            if (benefitClassDefinition) {
                                this.benefitClass = benefitClassDefinition;
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return BenefitClassDialogComponent;
}());
export { BenefitClassDialogComponent };
