import { RoleField, IRoleField } from './role-field';
import { RolesAccess } from './role-access-definition';

export interface IRoleSubsection {
  name: string;
  fields: IRoleField[];
}

export class RoleSubsection {
  public name: string;
  public fields: IRoleField[];
  public access: RolesAccess;
}
