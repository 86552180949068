<div class="week-charts" [ngSwitch]="chart.type">
  <ng-container *ngFor="let comparison of comparisons">
    <slx-chart-widget class="chart-widget-border" *ngSwitchCase="chartTypes.Donut" [config]="widgetConfig" [input]="getChartInput(comparison)"
    [useDefaultClickHanlder]="true" (onWidgetDefaultClick)="hanldeWidgetClick (comparison)"
    >
      <ng-template slxChartWidgetTopTemplate>
        <div class="date">
          <span class="week-day">{{ comparison.date | amDateFormat: 'dddd' }}</span>
          <span class="month-date">{{ comparison.date | amDateFormat: 'M/D' }}</span>
        </div>
      </ng-template>

      <ng-template slxChartWidgetMiddleTemplate>
        <slx-donut-chart-value-pair (onClick)="handleClickChart(comparison)"></slx-donut-chart-value-pair>
        <slx-labeled-value-pair class="values padding-bottom justify" (onClick1)="handleClickPair1(comparison)" (onClick2)="handleClickPair2(comparison)"></slx-labeled-value-pair>
      </ng-template>

      <ng-template slxChartWidgetBottomTemplate>
        <div class="icons ie10">
          <div class="click-catcher"></div>
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-envelope-open" label="Messages" [value]="comparison.pendingMessagesCount" (onClick)="handleClickBadgeMessages(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="far fa-clock" label="Overtime" [value]="comparison.overtimeEmployees" (onClick)="handleClickBadgeOvertime(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-user-times" label="PTO" [value]="comparison.ptoRequests" (onClick)="handleClickBadgePto(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-exclamation-square" label="Late" [value]="comparison.lateCount" (onClick)="handleClickBadgeLate(comparison)"></slx-labeled-badge>
        </div>
      </ng-template>
    </slx-chart-widget>

    <slx-chart-widget class="week-bar-chart chart-widget-border" *ngSwitchCase="chartTypes.Bar" [config]="widgetConfig" [input]="getChartInput(comparison)">
      <ng-template slxChartWidgetTopTemplate>
        <div class="date">
          <span class="week-day">{{ comparison.date | amDateFormat: 'dddd' }}</span>
          <span class="month-date">{{ comparison.date | amDateFormat: 'M/D' }}</span>
        </div>
      </ng-template>

      <ng-template slxChartWidgetMiddleTemplate>
        <div class="chart">
          <slx-bar-chart-horizontal-value-pair [hideValues]="true" (onClick)="handleClickChart(comparison)"></slx-bar-chart-horizontal-value-pair>
        </div>

        <slx-labeled-value-pair class="values justify" (onClick1)="handleClickPair1(comparison)" (onClick2)="handleClickPair2(comparison)"></slx-labeled-value-pair>
      </ng-template>

      <ng-template slxChartWidgetBottomTemplate>
        <div class="icons ie10">
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-envelope-open" label="Messages" [value]="comparison.pendingMessagesCount" (onClick)="handleClickBadgeMessages(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="far fa-clock" label="Overtime" [value]="comparison.overtimeEmployees" (onClick)="handleClickBadgeOvertime(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-user-times" label="PTO" [value]="comparison.ptoRequests" (onClick)="handleClickBadgePto(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-exclamation-square" label="Late" [value]="comparison.lateCount" (onClick)="handleClickBadgeLate(comparison)"></slx-labeled-badge>
        </div>
      </ng-template>
    </slx-chart-widget>

    <slx-chart-widget class="week-small-chart chart-widget-border" *ngSwitchCase="chartTypes.Small" [config]="widgetConfig" [input]="getChartInput(comparison)">
      <ng-template slxChartWidgetTopTemplate>
        <div class="date">
          <span class="week-day">{{ comparison.date | amDateFormat: 'dddd' }}</span>
          <span class="month-date">{{ comparison.date | amDateFormat: 'M/D' }}</span>
        </div>
      </ng-template>

      <ng-template slxChartWidgetMiddleTemplate>
        <div class="chart">
          <slx-bar-chart-horizontal-value-pair [hideValues]="false" [hideLabel]="true" [outLabel]="true" (onClick)="handleClickChart(comparison)"></slx-bar-chart-horizontal-value-pair>
        </div>
      </ng-template>

      <ng-template slxChartWidgetBottomTemplate>
        <div class="icons ie10">
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-envelope-open" label="Messages" [value]="comparison.pendingMessagesCount" (onClick)="handleClickBadgeMessages(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="far fa-clock" label="Overtime" [value]="comparison.overtimeEmployees" (onClick)="handleClickBadgeOvertime(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-user-times" label="PTO" [value]="comparison.ptoRequests" (onClick)="handleClickBadgePto(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-exclamation-square" label="Late" [value]="comparison.lateCount" (onClick)="handleClickBadgeLate(comparison)"></slx-labeled-badge>
        </div>
      </ng-template>
    </slx-chart-widget>

    <slx-chart-widget *ngSwitchCase="chartTypes.None" class="week-bar-chart chart-widget-border" [config]="widgetConfig" [input]="getChartInput(comparison)">
      <ng-template slxChartWidgetTopTemplate>
        <div class="top-no-chart">
          <div class="date">
            <span class="week-day">{{ comparison.date | amDateFormat: 'dddd' }}</span>
            <span class="month-date">{{ comparison.date | amDateFormat: 'M/D' }}</span>
          </div>
          <slx-chart-status-icon></slx-chart-status-icon>
        </div>
      </ng-template>

      <ng-template slxChartWidgetMiddleTemplate>
        <slx-labeled-value-pair class="values justify" (onClick1)="handleClickPair1(comparison)" (onClick2)="handleClickPair2(comparison)"></slx-labeled-value-pair>
      </ng-template>

      <ng-template slxChartWidgetBottomTemplate>
        <div class="icons ie10">
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-envelope-open" label="Messages" [value]="comparison.pendingMessagesCount" (onClick)="handleClickBadgeMessages(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="far fa-clock" label="Overtime" [value]="comparison.overtimeEmployees" (onClick)="handleClickBadgeOvertime(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-user-times" label="PTO" [value]="comparison.ptoRequests" (onClick)="handleClickBadgePto(comparison)"></slx-labeled-badge>
          <slx-labeled-badge class="labeled-badge ie10" icon="fa-exclamation-square" label="Late" [value]="comparison.lateCount" (onClick)="handleClickBadgeLate(comparison)"></slx-labeled-badge>
        </div>
      </ng-template>
    </slx-chart-widget>

    <div *ngSwitchDefault>
      Loading...
    </div>
  </ng-container>
</div>
