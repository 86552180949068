
import { Shift, Unit } from "../../../../app/configuration/models";
import { LocationUnit, Position, ShiftDefinition } from "../../../../app/organization/models";
export class OpenShift {

    public displayId: number;
    public startDate: string;
    public endDate: string; 
    public startDateRange: Date; 
    public endDateRange: Date;
    public count: number = 1; //Enetred Count
    public openShiftCount: number = 0 // Existing OpenShift count
    public position: Position[];
    public Shifts: Shift[];
    public unit: Unit[]; 

}
export class OpenShiftV2 {

    public displayId: number;
    public isNewShift: boolean;
    public count: number = -1; //new Count
    public openShiftCount: number = 0; // Existing OpenShift count
    public totalShifts:number=0;
    public scheduledShiftCount: number = 0;
    public position: Position[] = [];
    public Shifts: ShiftDefinition[] = [];
    public unit: LocationUnit[] = [];
    public pendingAgencyRequests: number = 0;
    public pendingEmployeeRequests: number = 0;
    public isAdded: boolean = false;
    public unAssignedShiftCount: number = 0;
    public filledShiftCount: number = 0;
    public overScheduleCount: number = 0;

}
export class OpenShiftSchedule {
    public orgLevelId: number;
    public requestedDate: string; // Date for Version 2  for Version 1 it is null 
    public idealScheduleType: string = "ShiftUnit";
    public calculationType: string = "Fixed";
    public deviation: string = "00:00:00"
    public dailyVarianceInd: boolean = true;
    public targetHours = 0;
    public openShifts: OpenShift[];  // version 1 
    public openShiftsV2: OpenShiftV2[]; // version 2 
}
export class PendingShiftValue {
    key: string;
    value: number;
}