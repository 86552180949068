import { IOrganization, Organization } from '../../../organization/models/lookup/index';
import { AgencyModel } from './agency.model';
import { AgencyMapping } from './agency-mapping.model';

export interface IAgencyAddRequest {
    id: number;
    name: string;
    address: string;
    state: string;
    city: string;
    zip: string;
    employeeCount: number;
    organizationIds:number[];
}

export class MappingRequest {
    public isRemove: boolean;
    public mapping: AgencyMapping;
    public organization: Organization;
}
