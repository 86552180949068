import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';

import { Subscription } from 'rxjs/Subscription';

import { IBenefitsConfig, benefitsConfig } from '../../../benefits.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';

import { DeviceDetectorService } from '../../../../../common/services/index';
import { BenefitDetailsConfig, BenefitDetailsEditModes, BenefitDetailsGroup } from '../../../models/index';
import { BenefitDetailsManagementService, BenefitDetailsPermissionService } from '../../../services/index';

@Component({
  selector: 'slx-benefit-details-tabs',
  templateUrl: './benefit-details-tabs.component.html',
  styleUrls: ['./benefit-details-tabs.component.scss'],
})
export class BenefitDetailsTabsComponent implements OnInit, OnDestroy {
  public groups: BenefitDetailsGroup[] = [];
  public selectedGroup: BenefitDetailsGroup;
  public isEditMode = false;
  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private benefitGroupsByType: StringMap<{ type: string, icon: string }> = {};
  private readonly groupConfig = benefitsConfig.benefitGroupsConfig;

  constructor(
    private manService: BenefitDetailsManagementService,
    private permissionService: BenefitDetailsPermissionService,
    private devDetector: DeviceDetectorService
  ) {
    this.benefitGroupsByType = _.keyBy(this.groupConfig, (c) => c.type);
  }

  public ngOnInit() {
    this.subscriptions.config = this.manService
      .subscribeToChangeConfig((config: BenefitDetailsConfig) => (this.groups = config.groups));

    this.subscriptions.config = this.manService
      .subscribeToSelectGroup((group: BenefitDetailsGroup) => (this.selectedGroup = group));

    this.subscriptions.editMode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => (this.isEditMode = v.providerInfo));
  }

  public ngOnDestroy(): void {}

  public isSelectedGroup(group: BenefitDetailsGroup): boolean {
    return _.isObjectLike(this.selectedGroup) && this.selectedGroup.id === group.id;
  }

  public isStandartGroup(group: BenefitDetailsGroup): boolean {
    const { medical, dental, vision, insurance, C401K, tuition, voluntary } = this.groupConfig;
    return _.isObjectLike(group) &&
      (
        group.type === medical.type
        || group.type === dental.type
        || group.type === vision.type
        || group.type === insurance.type
        || group.type === C401K.type
        || group.type === tuition.type
        || group.type === voluntary.type
      );
  }

  public isUndefinedGroup(group: BenefitDetailsGroup): boolean {
    const { medical, dental, vision, C401K, insurance, cobra, tuition, voluntary } = this.groupConfig;
    return !_.isObjectLike(group) ||
      group.type !== medical.type
      && group.type !== dental.type
      && group.type !== vision.type
      && group.type !== C401K.type
      && group.type !== insurance.type
      && group.type !== cobra.type
      && group.type !== tuition.type
      && group.type !== voluntary.type;
  }

  public getIconClass(type: string): string {
    const group = this.benefitGroupsByType[type];
    return _.isObjectLike(group) && _.size(group.type) > 0 ? group.icon : '';
  }

  public onSelectTab(e: SelectEvent): void {
    this.manService.selectGroup(this.groups[e.index]);
  }

  public onSelectedGroup(): void {
    this.manService.selectGroup(this.selectedGroup);
  }
}
