import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { NotificationsService } from '../../../core/components/index';
import { Lookup, LookupType, PerformanceReviewCodeTypes } from '../../../organization/models/index';
import { LookupService } from '../../../organization/services/index';
import { OrgLevel } from '../../../state-model/models/index';
import { FileService, ChangeManagementService, EmployeeSectionNavigationService } from '../../../common/services/index';
import { PmReviewRecord, PmReviewEntry } from '../models/index';
import { PerformanceManagementApiService } from './performance-management-api.service';
import { PmManagementService } from './pm-management.service';
var PmCreationManagementService = /** @class */ (function () {
    function PmCreationManagementService(manService, apiService, lookupService, notificationService, fileService, changeService, router, route) {
        this.manService = manService;
        this.apiService = apiService;
        this.lookupService = lookupService;
        this.notificationService = notificationService;
        this.fileService = fileService;
        this.changeService = changeService;
        this.router = router;
        this.route = route;
        this.files = [];
        this.loading$ = new Subject();
        this.reviewChanged$ = new ReplaySubject(1);
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.closePopup$ = new Subject();
        this.ratingsLoaded$ = new ReplaySubject(1);
        this.typesLoaded$ = new ReplaySubject(1);
        this.templatesLoaded$ = new ReplaySubject(1);
        this.onValidityChanged$ = new ReplaySubject();
        this.formValid = true;
        this.subscriptions = {};
        this.changeGroupName = 'performance-management';
        this.changeManagementComponentId = 'pm-create-review';
    }
    PmCreationManagementService.prototype.init = function (record) {
        this.changeService.setCurrentComponentId(this.changeManagementComponentId);
        this.subscribeToOrgLevelChanges(record);
    };
    PmCreationManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.reviewChanged$.complete();
        this.orgLevelChanged$.complete();
        this.closePopup$.complete();
        this.ratingsLoaded$.complete();
        this.typesLoaded$.complete();
        this.templatesLoaded$.complete();
        this.onValidityChanged$.complete();
    };
    PmCreationManagementService.prototype.getAddedFiles = function () {
        return this.files.slice(0);
    };
    PmCreationManagementService.prototype.setFormValidity = function (valid) {
        this.formValid = valid;
        this.updateValidity();
    };
    PmCreationManagementService.prototype.onValidityChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onValidityChanged$.subscribe(callback);
    };
    PmCreationManagementService.prototype.requestClosePopup = function () {
        var _this = this;
        this.changeService.canMoveForward(this.changeManagementComponentId).then(function (canMove) {
            if (!canMove) {
                _this.toggleEditMode(true);
                return;
            }
            _this.closePopup();
        });
    };
    PmCreationManagementService.prototype.closePopup = function () {
        this.changeService.clearChanges(this.changeGroupName);
        this.changeService.clearCurrentComponentId();
        this.closePopup$.next();
        this.loadReviews();
    };
    PmCreationManagementService.prototype.loadReviews = function () {
        this.manService.loadReviews();
    };
    PmCreationManagementService.prototype.subscribeToClosePopup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.closePopup$.subscribe(callback);
    };
    PmCreationManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    PmCreationManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    PmCreationManagementService.prototype.subscribeToReviewChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.reviewChanged$.subscribe(callback);
    };
    PmCreationManagementService.prototype.subscribeToRatingsLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.ratingsLoaded$.subscribe(callback);
    };
    PmCreationManagementService.prototype.subscribeToTypesLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.typesLoaded$.subscribe(callback);
    };
    PmCreationManagementService.prototype.toggleEditMode = function (isOn) {
        this.reviewEntry.isEditMode = isOn;
        this.emitReviewChanges(this.reviewEntry);
    };
    PmCreationManagementService.prototype.cancelEditing = function () {
        this.toggleEditMode(false);
        this.requestClosePopup();
    };
    PmCreationManagementService.prototype.setEmployeeWithoutProcessing = function (emp) {
        this.selectedEmployee = emp;
    };
    PmCreationManagementService.prototype.setEmployee = function (emp) {
        this.selectedEmployee = emp;
        this.reviewEntry.employee = _.cloneDeep(emp);
        this.reviewEntry.organization = _.cloneDeep(emp.organization);
        this.reviewEntry.department = _.cloneDeep(emp.department);
        this.reviewEntry.position = _.cloneDeep(emp.position);
        this.emitReviewChanges(this.reviewEntry);
    };
    PmCreationManagementService.prototype.setReviewDate = function (date) {
        if (!_.isDate(date)) {
            this.reviewEntry.reviewDate = null;
            return;
        }
        var rDate = this.reviewEntry.reviewDate;
        if (_.isDate(rDate) && rDate.getTime() === date.getTime())
            return;
        this.onChangeNotify();
        this.reviewEntry.reviewDate = new Date(date.getTime());
        this.emitReviewChanges(this.reviewEntry);
    };
    PmCreationManagementService.prototype.setReviewType = function (type) {
        if (_.isEqual(this.reviewEntry.reviewType, type))
            return;
        this.onChangeNotify();
        this.reviewEntry.reviewType = type;
        this.emitReviewChanges(this.reviewEntry);
    };
    PmCreationManagementService.prototype.setRating = function (rating) {
        if (_.isEqual(this.reviewEntry.rating, rating))
            return;
        this.onChangeNotify();
        this.reviewEntry.rating = rating;
        this.emitReviewChanges(this.reviewEntry);
    };
    PmCreationManagementService.prototype.setComment = function (comment) {
        if (this.reviewEntry.comment === comment)
            return;
        this.onChangeNotify();
        this.reviewEntry.comment = comment;
        this.emitReviewChanges(this.reviewEntry);
    };
    PmCreationManagementService.prototype.saveReview = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var savedReview, message, savedWithAttachments, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        if (!this.reviewEntry.isNew) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.apiService.createReviewEntry(this.reviewEntry)];
                    case 2:
                        savedReview = _a.sent();
                        message = 'Review saved successfully';
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.apiService.updateReviewEntry(this.reviewEntry)];
                    case 4:
                        savedReview = _a.sent();
                        message = 'Review updated successfully';
                        _a.label = 5;
                    case 5: return [4 /*yield*/, this.attachSavedFiles(savedReview.id, this.files)];
                    case 6:
                        savedWithAttachments = _a.sent();
                        savedReview = savedWithAttachments || savedReview;
                        this.notificationService.success(message);
                        return [3 /*break*/, 8];
                    case 7:
                        e_1 = _a.sent();
                        console.error(e_1);
                        this.loading$.next(false);
                        return [2 /*return*/];
                    case 8:
                        this.reviewEntry.isEditMode = true;
                        this.reviewEntry.id = savedReview.id;
                        this.reviewEntry.attachments = savedReview.attachments;
                        this.reviewEntry.reviewedBy = savedReview.reviewedBy;
                        this.reviewEntry.startDate = savedReview.startDate;
                        this.reviewEntry.reviewDate = savedReview.reviewDate;
                        this.reviewEntry.addedFiles = [];
                        this.files = [];
                        this.storedReviewEntry = _.cloneDeep(this.reviewEntry);
                        this.emitReviewChanges(this.reviewEntry);
                        this.changeService.clearChanges(this.changeGroupName);
                        this.loading$.next(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    PmCreationManagementService.prototype.deleteReview = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.deleteReviewEntry(this.reviewEntry)];
                    case 2:
                        _a.sent();
                        this.notificationService.success('Review deleted successfully');
                        return [3 /*break*/, 5];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        this.notificationService.error('Something gone wrong, review hasn\'t deleted');
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading$.next(false);
                        this.closePopup();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PmCreationManagementService.prototype.completeReview = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var completedReview, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.completeReviewEntry(this.reviewEntry)];
                    case 2:
                        completedReview = _a.sent();
                        this.reviewEntry.status = completedReview.status;
                        this.reviewEntry.completionDate = completedReview.completionDate;
                        this.notificationService.success('Review completed successfully');
                        return [3 /*break*/, 5];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        this.notificationService.error('Something gone wrong, review hasn\'t completed');
                        return [3 /*break*/, 5];
                    case 4:
                        this.reviewEntry.isEditMode = false;
                        this.emitReviewChanges(this.reviewEntry);
                        this.loading$.next(false);
                        this.closePopup();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PmCreationManagementService.prototype.deleteAttachment = function (attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.deleteAttachment(this.reviewEntry.id, attachmentId)];
                    case 2:
                        _a.sent();
                        this.reviewEntry.attachments = _.filter(this.reviewEntry.attachments, function (a) { return a.id !== attachmentId; });
                        this.emitReviewChanges(this.reviewEntry);
                        this.onChangeNotify();
                        return [3 /*break*/, 5];
                    case 3:
                        e_4 = _a.sent();
                        console.error(e_4);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PmCreationManagementService.prototype.downloadAttachment = function (attachmentId) {
        var _this = this;
        this.loading$.next(true);
        try {
            var promise = this.apiService.downloadAttachment(attachmentId)
                .then(function (file) {
                return _this.fileService.saveToFileSystem(file.blob, file.file);
            });
            return promise;
        }
        catch (e) {
            console.error(e);
        }
        finally {
            this.loading$.next(false);
        }
    };
    PmCreationManagementService.prototype.saveFiles = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.files = this.files.concat(files);
                this.reviewEntry.addedFiles = this.files ? this.files.slice(0) : null;
                this.onChangeNotify();
                this.emitReviewChanges(this.reviewEntry);
                return [2 /*return*/];
            });
        });
    };
    PmCreationManagementService.prototype.deleteFile = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        this.files = _.filter(this.files, function (f) { return f.fileName !== file.fileName; });
                        this.reviewEntry.addedFiles = this.files ? this.files.slice(0) : null;
                        this.onChangeNotify();
                        return [4 /*yield*/, Observable.of(true).delay(500).toPromise()];
                    case 1:
                        _a.sent();
                        this.loading$.next(false);
                        this.emitReviewChanges(this.reviewEntry);
                        return [2 /*return*/];
                }
            });
        });
    };
    PmCreationManagementService.prototype.onChangeNotify = function () {
        this.changeService.changeNotify(this.changeGroupName);
    };
    PmCreationManagementService.prototype.navigateToUserProfile = function (id) {
        var _this = this;
        this.changeService.canMoveForward(this.changeManagementComponentId).then(function (canMove) {
            if (!canMove) {
                return;
            }
            var navService = new EmployeeSectionNavigationService(_this.router, _this.route);
            var urlTree = navService.getUrlTreeFromRoot(id, true);
            var extras = {
                skipLocationChange: false,
                replaceUrl: false,
            };
            _this.router.navigateByUrl(urlTree, extras);
            _this.closePopup();
        });
    };
    PmCreationManagementService.prototype.attachSavedFiles = function (id, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var review;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(_.size(files) > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apiService.saveAttachments(id, files)];
                    case 1:
                        review = _a.sent();
                        return [2 /*return*/, review];
                    case 2: return [2 /*return*/, null];
                }
            });
        });
    };
    PmCreationManagementService.prototype.loadResources = function (record) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        return [4 /*yield*/, this.processReviewRecord(record)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadPerformanceReviewCodes()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadPerformanceReviewTemplates()];
                    case 3:
                        _a.sent();
                        this.emitReviewChanges(this.reviewEntry);
                        this.loading$.next(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    PmCreationManagementService.prototype.processReviewRecord = function (record) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.getReviewEntry(record)];
                    case 1:
                        _a.reviewEntry = _b.sent();
                        this.storedReviewEntry = _.cloneDeep(this.reviewEntry);
                        this.reviewEntry.isEditMode = !_.isObjectLike(record);
                        return [2 /*return*/];
                }
            });
        });
    };
    PmCreationManagementService.prototype.getReviewEntry = function (record) {
        if (!_.isObjectLike(record)) {
            var review = new PmReviewEntry();
            review.employee = this.selectedEmployee;
            return Promise.resolve(review);
        }
        return this.apiService.loadReviewEntry(record.id);
    };
    PmCreationManagementService.prototype.loadPerformanceReviewCodes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lookup, ratings, reviewTypes;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.lookupService
                            .getLookup({ lookupType: LookupType.performanceReviewCodes, orgLevelId: this.orgLevel.id })];
                    case 1:
                        lookup = _a.sent();
                        ratings = this.filterOutCodes(lookup, PerformanceReviewCodeTypes.rating);
                        reviewTypes = this.filterOutCodes(lookup, PerformanceReviewCodeTypes.reviewType);
                        this.ratingsLoaded$.next(ratings);
                        this.typesLoaded$.next(reviewTypes);
                        return [2 /*return*/];
                }
            });
        });
    };
    PmCreationManagementService.prototype.loadPerformanceReviewTemplates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lookup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.lookupService
                            .getLookup({ lookupType: LookupType.performanceReviewTemplates, orgLevelId: this.orgLevel.id, updateCacheForced: false })];
                    case 1:
                        lookup = _a.sent();
                        this.reviewEntry.templates = lookup.items;
                        this.templatesLoaded$.next(lookup);
                        return [2 /*return*/];
                }
            });
        });
    };
    PmCreationManagementService.prototype.filterOutCodes = function (lkp, codeType) {
        if (_.size(lkp.items) > 0) {
            var lookup = new Lookup();
            lookup.type = lkp.type;
            lookup.items = _.filter(lkp.items, function (ct) { return ct.type.name === codeType; });
            lookup.titleField = lkp.titleField;
            lookup.valueField = lkp.valueField;
            return lookup;
        }
        return lkp;
    };
    PmCreationManagementService.prototype.subscribeToOrgLevelChanges = function (record) {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return _.isFinite(_.get(o, 'id')); })
            .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id)
                            return [2 /*return*/];
                        this.orgLevel = orgLevel;
                        this.orgLevelChanged$.next(this.orgLevel);
                        return [4 /*yield*/, this.loadResources(record)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    PmCreationManagementService.prototype.emitReviewChanges = function (r) {
        this.reviewChanged$.next(_.cloneDeep(r));
    };
    PmCreationManagementService.prototype.updateValidity = function () {
        this.onValidityChanged$.next(this.formValid);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PmCreationManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], PmCreationManagementService.prototype, "subscriptions", void 0);
    return PmCreationManagementService;
}());
export { PmCreationManagementService };
