import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const DATETIME_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DatetimeValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[datetime][formControlName],[datetime][formControl],[datetime][ngModel]',
  /* tslint:enable */
  providers: [DATETIME_VALIDATOR]
})
export class DatetimeValidator implements Validator {
  public validate(c: AbstractControl): {[key: string]: any} {
    return CommonValidators.datetime(c);
  }
}
