import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';
import { TimeclockRestrictionTotal, TimeclockAssignmentState } from '../../models/index';
import { TimeclockRestrictionDefinition, TimeclockDefinition } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/index';
import { TimeclockAssignmentApiService } from './timeclock-assignment-api.service';
var TimeclockAssignmentManagementService = /** @class */ (function () {
    function TimeclockAssignmentManagementService(timeclockAssignmentApiService, lookupApiService) {
        var _this = this;
        this.timeclockAssignmentApiService = timeclockAssignmentApiService;
        this.lookupApiService = lookupApiService;
        this.state = new TimeclockAssignmentState();
        this.state.showRestrictions = [];
        this.state.resetGrid = false;
        this.firstLoad = true;
        this.orgLevelHasChanged = false;
        this.onSelectionChanged$ = new ReplaySubject(1);
        this.onLoadStatus$ = new ReplaySubject(1);
        this.onStateChanged$ = new ReplaySubject(1);
        this.onOrgLevelChanged$ = new ReplaySubject(1);
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.currentOrgLevel || o && _this.currentOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            if (!o || !o.id) {
                return;
            }
            _this.onOrgLevelChanged(o);
        });
    }
    TimeclockAssignmentManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeclockAssignmentManagementService.prototype.loadTimeclockAssignments = function (orgLevelId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.timeclockAssignmentApiService.getTimeclockAssignments(orgLevelId)
            .then(function (container) {
            _this.container = container;
            _this.calculateTotals(_this.container, _this.restrictions, _this.state);
            if (_this.firstLoad || _this.orgLevelHasChanged) {
                if (_this.state.unassignedEmployees > 0) {
                    _this.state.showUnassigned = true;
                    _this.state.showAssigned = false;
                }
                else {
                    _this.state.showUnassigned = false;
                    _this.state.showAssigned = true;
                }
            }
            _this.onStateChanged(_this.orgLevelHasChanged);
            _this.onLoadStatusChanged(false);
            _this.firstLoad = false;
            _this.orgLevelHasChanged = false;
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimeclockAssignmentManagementService.prototype.onAssign = function (assignment) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.timeclockAssignmentApiService.assignEmployees(this.currentOrgLevel.id, assignment)
            .then(function (result) {
            _this.loadTimeclockAssignments(_this.currentOrgLevel.id);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimeclockAssignmentManagementService.prototype.onUnAssign = function (assignment) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.timeclockAssignmentApiService.unassignEmployees(this.currentOrgLevel.id, assignment)
            .then(function (result) {
            _this.loadTimeclockAssignments(_this.currentOrgLevel.id);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimeclockAssignmentManagementService.prototype.onReAssign = function (assignment) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.timeclockAssignmentApiService.assignEmployees(this.currentOrgLevel.id, assignment)
            .then(function (result) {
            _this.loadTimeclockAssignments(_this.currentOrgLevel.id);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimeclockAssignmentManagementService.prototype.onStateChanged = function (isResetGrid) {
        this.state.resetGrid = isResetGrid;
        this.applyFilters(this.container, this.state);
        this.onStateChanged$.next(this.state);
    };
    TimeclockAssignmentManagementService.prototype.onRecordsSelected = function (selectedRecords) {
        this.selectedRecords = selectedRecords;
        this.onSelectionChanged$.next(selectedRecords);
    };
    TimeclockAssignmentManagementService.prototype.onLoadStatusChanged = function (isLoading) {
        this.onLoadStatus$.next(isLoading);
    };
    TimeclockAssignmentManagementService.prototype.calculateTotals = function (container, restrictions, state) {
        var _this = this;
        var assignedDefaults = false;
        state.unassignedEmployees = _.filter(container.employees, function (employee) {
            return employee.assignments.length === 0;
        }).length;
        state.assignedEmployees = 0;
        state.totals = _.map(restrictions, function (restriction) {
            var restTotal = new TimeclockRestrictionTotal();
            restTotal.restriction = restriction;
            restTotal.employeeCount = _.filter(container.employees, function (employee) {
                return !!_.find(employee.assignments, function (assignment) {
                    return assignment.restriction.id === restriction.id;
                });
            }).length;
            state.assignedEmployees += restTotal.employeeCount;
            if ((_this.firstLoad || _this.orgLevelHasChanged) && !assignedDefaults && restTotal.employeeCount > 0) {
                state.showRestrictions.push(restriction);
                restTotal.isChecked = true;
                assignedDefaults = true;
            }
            else if (!_this.firstLoad) {
                var index = _.indexOf(state.showRestrictions, restriction);
                if (index !== -1) {
                    restTotal.isChecked = true;
                }
            }
            return restTotal;
        });
        if (state.showRestrictions.length === 0) {
            var firstItem = _.head(state.totals);
            firstItem.isChecked = true;
            state.showRestrictions.push(firstItem.restriction);
        }
    };
    TimeclockAssignmentManagementService.prototype.applyFilters = function (container, state) {
        if (state.showUnassigned) {
            state.records = _.filter(container.employees, function (employee) {
                return employee.assignments.length === 0;
            });
            return;
        }
        if (state.showAssigned) {
            state.records = _.filter(container.employees, function (employee) {
                var result = false;
                _.forEach(state.showRestrictions, function (rest) {
                    var founded = _.find(employee.assignments, function (assignment) {
                        return assignment.restriction.id === rest.id;
                    });
                    if (founded) {
                        result = true;
                        return;
                    }
                });
                return result;
            });
            return;
        }
        state.records = [];
    };
    TimeclockAssignmentManagementService.prototype.onOrgLevelChanged = function (o) {
        this.currentOrgLevel = o;
        this.orgLevelHasChanged = true;
        this.onOrgLevelChanged$.next(this.currentOrgLevel ? this.currentOrgLevel.id : undefined);
        if (this.currentOrgLevel) {
            this.loadTimeclockRestrictions(this.currentOrgLevel.id);
            this.loadTimeclocks(this.currentOrgLevel.id);
        }
    };
    TimeclockAssignmentManagementService.prototype.loadTimeclockRestrictions = function (orgLevelId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.lookupApiService.getTimeclockRestrictionDefinitions(orgLevelId)
            .then(function (restrictions) {
            _this.restrictions = restrictions;
            _this.loadTimeclockAssignments(_this.currentOrgLevel.id);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimeclockAssignmentManagementService.prototype.loadTimeclocks = function (orgLevelId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.lookupApiService.getTimeclockDefinitions(orgLevelId)
            .then(function (restrictions) {
            _this.state.timeclocks = restrictions;
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], TimeclockAssignmentManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentManagementService.prototype, "orgLevelSubscription", void 0);
    return TimeclockAssignmentManagementService;
}());
export { TimeclockAssignmentManagementService };
