<slx-spinner [show]="isLoading$ | async" class="response">
  <div class="slx-high-box">
    <div class="row slx-high-box__body slx-main-content-indents res">
      <div class="display-toggle">
        <button type="submit" class="theme-button-apply btn-sync"[disabled]="isSyncDisabled$ | async" (click)="syncData()">Sync Now</button>
        <button
        type="submit"
        class="theme-button-apply btn-syncrefresh"
        (click)="onRefreshClicked()">Refresh
      </button>
      </div>
      <kendo-grid
        #grid="kendoGrid"
        [kendoGridBinding]="viewModel$ | async"
        [pageable]="true"
        filterable="menu"
        [filter]="filter$ | async"
        (filterChange)="onFilterChanged($event)"
        [sortable]="{ mode: 'multiple' }"
        [pageSize]="pageSize"
        class="grid slx-full-height slx-blue-grid"
      >
        <kendo-grid-checkbox-column [width]="35">
          <ng-template kendoGridHeaderTemplate>
            <input
              type="checkbox"
              [checked]="isCheckedAll$ | async"
              [disabled]="isCheckAllDisabled$ | async" (change)="toggleCheckAll($event)"
            />
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <input
              type="checkbox"
              [checked]="dataItem.ischecked"
              (change)="onCheckboxChange($event, dataItem)"
              [disabled]="dataItem.isDisabled"
            />
          </ng-template>
        </kendo-grid-checkbox-column>
        <kendo-grid-column field="orgLevelId" title="Orglevel ID" width="160"></kendo-grid-column>
        <kendo-grid-column field="orgLevelName" title="Position" width="160"></kendo-grid-column>
        <kendo-grid-column field="jobCode" title="Job Code" width="160"></kendo-grid-column>
        <kendo-grid-column title="Org Type" field="orgLevelType" width="130"> </kendo-grid-column>
        <kendo-grid-column title="Action" field="action" width="140"> </kendo-grid-column>
        <kendo-grid-column title="Company ID" field="companyId" width="140"> </kendo-grid-column>
        <kendo-grid-column title="Company Code" field="companyCode" width="170">
        </kendo-grid-column>
        <kendo-grid-column title="Paygroup" field="paygroup" width="170"></kendo-grid-column>
        <kendo-grid-column field="shortCode" title="Short Code" width="160"></kendo-grid-column>
        <kendo-grid-column title="Status" field="status" width="170"> </kendo-grid-column>
        <kendo-grid-column title="Failed At" field="failedAt" width="170"> </kendo-grid-column>
        <kendo-grid-column title="Message" field="errorMessage" width="170"> </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <p>There is no data to display</p>
        </ng-template>
      </kendo-grid>
    </div>
  </div>
</slx-spinner>

