/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../node_modules/@progress/kendo-angular-inputs/dist/es2015/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@progress/kendo-angular-inputs";
import * as i5 from "@progress/kendo-angular-common";
import * as i6 from "@progress/kendo-angular-l10n";
import * as i7 from "@progress/kendo-angular-intl";
import * as i8 from "./number.component";
var styles_NumberComponent = [];
var RenderType_NumberComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NumberComponent, data: {} });
export { RenderType_NumberComponent as RenderType_NumberComponent };
function View_NumberComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [["class", "slx-number__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
export function View_NumberComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { kendoNumber: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "slx-number"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NumberComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 6, "kendo-numerictextbox", [["class", "slx-number__number"]], [[1, "dir", 0], [2, "k-widget", null], [2, "k-numerictextbox", null]], [[null, "valueChange"], [null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.onValueChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.onBlur(_co.currentValue) !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = (_co.onFocus(_co.currentValue) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_NumericTextBoxComponent_0, i2.RenderType_NumericTextBoxComponent)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.NumericTextBoxComponent]), i0.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.NumericTextBoxComponent]), i0.ɵprd(6144, null, i5.KendoInput, null, [i4.NumericTextBoxComponent]), i0.ɵprd(256, null, i6.L10N_PREFIX, "kendo.numerictextbox", []), i0.ɵprd(131584, null, i6.LocalizationService, i6.LocalizationService, [i6.L10N_PREFIX, [2, i6.MessageService], [2, i6.RTL]]), i0.ɵdid(10, 770048, [[1, 4], ["textbox", 4]], 0, i4.NumericTextBoxComponent, [i7.IntlService, i0.Renderer2, i6.LocalizationService, i0.Injector, i0.NgZone, i0.ChangeDetectorRef, i0.ElementRef], { disabled: [0, "disabled"], autoCorrect: [1, "autoCorrect"], format: [2, "format"], max: [3, "max"], min: [4, "min"], decimals: [5, "decimals"], step: [6, "step"], spinners: [7, "spinners"], value: [8, "value"] }, { valueChange: "valueChange", onFocus: "focus", onBlur: "blur" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasPlaceholder; _ck(_v, 3, 0, currVal_0); var currVal_4 = _co.isDisabled; var currVal_5 = _co.autoCorrect; var currVal_6 = _co.format; var currVal_7 = _co.max; var currVal_8 = _co.min; var currVal_9 = _co.decimals; var currVal_10 = _co.step; var currVal_11 = _co.spinners; var currVal_12 = _co.currentValue; _ck(_v, 10, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 10).direction; var currVal_2 = i0.ɵnov(_v, 10).widgetClasses; var currVal_3 = i0.ɵnov(_v, 10).widgetClasses; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_NumberComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-number", [], null, null, null, View_NumberComponent_0, RenderType_NumberComponent)), i0.ɵdid(1, 8437760, null, 0, i8.NumberComponent, [i3.NgControl], null, null)], null, null); }
var NumberComponentNgFactory = i0.ɵccf("slx-number", i8.NumberComponent, View_NumberComponent_Host_0, { step: "step", min: "min", max: "max", decimals: "decimals", format: "format", autoCorrect: "autoCorrect", required: "required", isDisabled: "readonly", placeholder: "placeholder", autofocus: "autofocus", spinners: "spinners" }, { blur: "blur", focus: "focus" }, []);
export { NumberComponentNgFactory as NumberComponentNgFactory };
