import { RoleDefinition, IRoleDefinition, IOrgLevelDto } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';

export interface IUserRolerelationDTO {
    role: IRoleDefinition;
    orgLevels: IOrgLevelDto[];
}

export class UserRoleRelationModel {
    public userId: number;
    public roleDefinition: RoleDefinition;
    public orgLevels: OrgLevel[];
}
