import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ShiftReplacementSettings } from '../../../models/index';
import { IColumnSettings } from '../../../../core/models/index';
import { ColumnSettingsStorageService } from '../../../../common/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-shift-replacement-header',
  templateUrl: 'shift-replacement-header.component.html',
  styleUrls: ['shift-replacement-header.component.scss']
})
export class ShiftReplacementHeaderComponent implements OnInit {
  @Input()
  public settings: ShiftReplacementSettings;
  @Output()
  public settingsChanged: EventEmitter<ShiftReplacementSettings>;
  @Output()
  public columnsChanged: EventEmitter<any>;

  public appConfig: IApplicationConfig;
  public columnsMenuOpened: boolean;

  private columnSettingsStorageService: ColumnSettingsStorageService;

  constructor(columnSettingsStorageService: ColumnSettingsStorageService) {
    this.settingsChanged = new EventEmitter<ShiftReplacementSettings>();
    this.columnSettingsStorageService = columnSettingsStorageService;
    this.columnsChanged =  new EventEmitter<any>();
  }
  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public columnSelected(): void {
    this.settingsChanged.emit(this.settings);
    this.columnsMenuOpened = false;
  }
  public toggleColumnsMenu(): void {
    this.columnsMenuOpened = !this.columnsMenuOpened;
  }

  public selectAll(event: MouseEvent): void {
    _.forEach(this.settings.columns, (column: IColumnSettings) => {
      column.displayed = true;
    });
    this.saveColumns();
    this.columnsChanged.emit(null);
    this.columnsMenuOpened = false;
  }

  public clearAll(event: MouseEvent): void {
    _.forEach(this.settings.columns, (column: IColumnSettings) => {
      column.displayed = false;
    });
    this.saveColumns();
    this.columnsChanged.emit(null);
    this.columnsMenuOpened = false;
  }

  public onChange(event: MouseEvent, column: IColumnSettings): any {
    column.displayed = !column.displayed;
    this.saveColumns();
    this.columnsChanged.emit(null);
  }

  private saveColumns(): void {
    if (!this.settings) {
      return;
    }
    this.columnSettingsStorageService.setColumnsState(this.settings.context.toString(), undefined, this.settings.columns);
  }

}

