import * as tslib_1 from "tslib";
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ValuePairChartSeriesDataService } from '../../../../../common/services/value-pair-chart-data/value-pair-chart-series-data.service';
import { ValuePairChartSeriesModel, ValuePairWidgetConfig, ValuePairChartModel } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import * as _ from 'lodash';
var ScheduleConsoleOverviewWeekComponent = /** @class */ (function () {
    function ScheduleConsoleOverviewWeekComponent(changeDetector, dataService) {
        this.changeDetector = changeDetector;
        this.dataService = dataService;
        this.categories = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
        ];
    }
    ScheduleConsoleOverviewWeekComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.configLoaded = this.dataService.onConfigLoaded.subscribe(function (config) {
            if (_.isEqual(_this.config, config)) {
                return;
            }
            _this.config = config;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.modelLoaded = this.dataService.onModelsLoaded.subscribe(function (models) {
            if (_.isEqual(_this.models, models)) {
                return;
            }
            _this.models = models;
            _this.updateIdealHoursByCategory();
            _this.updateChartData();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    ScheduleConsoleOverviewWeekComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ScheduleConsoleOverviewWeekComponent.prototype.updateIdealHoursByCategory = function () {
        var _this = this;
        var hoursByCategory = [];
        _.forEach(this.categories, function (category, index) {
            var count = _.sumBy(_this.models, function (model) {
                if (!model || !model.models[index]) {
                    return 0;
                }
                return model.models[index].input.value2;
            });
            hoursByCategory.push(count);
        });
        this.idealHoursByCategory = hoursByCategory;
    };
    ScheduleConsoleOverviewWeekComponent.prototype.updateChartData = function () {
        var aggregatedValues = [
            0, 0, 0, 0, 0, 0, 0
        ];
        var chartSeries = [];
        _.forEach(this.models, function (seriesModel) {
            var dataItems = [];
            _.forEach(seriesModel.models, function (model, index) {
                aggregatedValues[index] += model.rawValue1;
                dataItems.push({
                    model: model,
                    color: model.color ? model.color.fontColor1 : null,
                    value: aggregatedValues[index],
                });
            });
            chartSeries.push({
                dataItems: dataItems,
                model: seriesModel,
            });
        });
        this.chartSeries = chartSeries;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleOverviewWeekComponent.prototype, "configLoaded", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleOverviewWeekComponent.prototype, "modelLoaded", void 0);
    return ScheduleConsoleOverviewWeekComponent;
}());
export { ScheduleConsoleOverviewWeekComponent };
