import * as _ from 'lodash';
import { StaffingPointsTableRow, IStaffingPointsTableRowDto } from './staffing-points-table-row';

export class StaffingPointsTable {
    public startDate: Date;
    public endDate: Date;
    public rows: StaffingPointsTableRow[];

    public label: string;

    public get firstRow(): StaffingPointsTableRow {
        return _.first(this.rows);
    }
}

export interface IStaffingPointsTableDto {
    startDate: string;
    endDate: string;
    rows: IStaffingPointsTableRowDto[];
}
