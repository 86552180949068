import { IReportConfigPositionCategory, ReportConfigPositionCategory } from './report-config-position-category';

export interface IReportAttendanceSetting {
    departmentId: number;
    positionCategory: IReportConfigPositionCategory[];
}

export class ReportAttendanceSetting {
    public departmentId: number;
    public positionCategory: ReportConfigPositionCategory[];
}

