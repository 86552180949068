import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EditResult, IEditResult } from '../../../../core/models/index';
import {
  IBenefitClass, BenefitClass
} from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { LookupMapService } from '../../../../organization/services/index';
@Injectable()
export class BenefitClassMapService {

  constructor(private lookupMapService: LookupMapService) {

  }

  public mapToBenefitClasses(dtos: IBenefitClass[]): BenefitClass[] {
    return _.map(dtos, (dto: IBenefitClass) => this.mapToBenefitClass(dto));
  }

  public mapToBenefitClass(dto: IBenefitClass): BenefitClass {
    const data = new BenefitClass();
    data.benefitClassDefinition = this.lookupMapService.mapBenefitClassDefinition(dto.benefitClassDefinition);
    data.orgLevelDirectionForEdit = dto.orgLevelDirectionForEdit;
    data.modifiedOn = dateTimeUtils.convertFromDtoDateTimeString(dto.modifiedOn);
    data.modifiedBy = dto.modifiedBy;
    data.orgLevelName = dto.orgLevelName;
    return data;
  }

  public mapToBenefitClassDto(data: BenefitClass): IBenefitClass {
    const dto: IBenefitClass = {
      benefitClassDefinition: this.lookupMapService.mapToBenefitClassDefinitionDTO(data.benefitClassDefinition),
      orgLevelDirectionForEdit: data.orgLevelDirectionForEdit,
      modifiedOn: dateTimeUtils.convertToDtoDateTimeString(data.modifiedOn),
      modifiedBy: data.modifiedBy,
      orgLevelName: data.orgLevelName
    };
    return dto;
  }

  public mapEditResult(dto: IEditResult<IBenefitClass>): EditResult<BenefitClass> {
    const data = new EditResult<BenefitClass>();
    data.item = dto.item ? this.mapToBenefitClass(dto.item): null;
    data.isSuccess = dto.isSuccess;
    data.message = dto.message;
    return data;
  }

}
