import * as tslib_1 from "tslib";
import { EmployeeTerminationReason } from './../../../../../organization/models/lookup/employee-termination-reason';
import { LookupType } from './../../../../../organization/models/lookup/lookup-definition';
import { LookupService } from './../../../../../organization/services/lookup/lookup.service';
import { OnInit, OnDestroy, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeSectionsTerminationHistoryEntries } from '../../../models/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import * as _ from 'lodash';
var EmployeeSectionsTerminationsHistoryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsTerminationsHistoryComponent, _super);
    function EmployeeSectionsTerminationsHistoryComponent(employeeSectionsEmploymentApiService, decorator, ngZone, lookupService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.lookupService = lookupService;
        _this.terminateReasons = null;
        _this.empreasons = null;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsTerminationsHistoryComponent.prototype, "employeeSectionsTerminationHistoryEntries", {
        set: function (terminations) {
            this.terminations = terminations;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTerminationsHistoryComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTerminationsHistoryComponent.prototype, "terminationEntries", {
        get: function () {
            return this.terminations ? this.terminations.terminationEntries : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsTerminationsHistoryComponent.prototype.getSubsectionModel = function () {
        return this.terminations;
    };
    EmployeeSectionsTerminationsHistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lookupService.getLookup({ lookupType: LookupType.employeeTerminationReason2, employeeId: undefined, orgLevelId: undefined })
            .then(function (reason) {
            _this.terminateReasons = reason;
        });
    };
    EmployeeSectionsTerminationsHistoryComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmploymentTerminationsHistory(this.employeeId)
            .then(function (term) {
            _this.terminations = term;
            _this.empreasons = _this.getUniqueReasons(_this.terminations.terminationEntries);
            if (_this.empreasons !== undefined)
                _this.terminateReasons.items = _.sortBy(_.uniqBy(_this.terminateReasons.items.concat(_this.empreasons), 'reason'), 'reason');
            _this.stopProgress();
        })
            .catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsTerminationsHistoryComponent.prototype.getUniqueReasons = function (entries) {
        var _this = this;
        var reasons = _.without(_.uniq(_.map(entries, 'reason.fieldValue[reason]')), undefined);
        return _.map(reasons, function (v) { return _this.mapTerminateReason(v); });
    };
    EmployeeSectionsTerminationsHistoryComponent.prototype.mapTerminateReason = function (data) {
        if (!data) {
            return null;
        }
        var value = new EmployeeTerminationReason();
        value.reason = data;
        return value;
    };
    EmployeeSectionsTerminationsHistoryComponent.prototype.doSave = function (effectiveDate) {
        var _this = this;
        this.employeeSectionsEmploymentApiService.setEmploymentTerminationHistory(this.employeeId, this.terminations, effectiveDate)
            .then(function (status) {
            _this.onActionComplete(true);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsTerminationsHistoryComponent.prototype.checkTemporalDirty = function () {
        return this.metaFieldsTemporalDirtyArrayChecker(this.terminationEntries);
    };
    return EmployeeSectionsTerminationsHistoryComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsTerminationsHistoryComponent };
