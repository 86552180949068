import * as _ from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
var ScheduleConsoleWeeklyIndicatorComponent = /** @class */ (function () {
    function ScheduleConsoleWeeklyIndicatorComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.week = [];
    }
    Object.defineProperty(ScheduleConsoleWeeklyIndicatorComponent.prototype, "days", {
        set: function (value) {
            if (!_.isEqual(this.week, value)) {
                this.week = value;
                this.changeDetectorRef.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleWeeklyIndicatorComponent.prototype, "hasDays", {
        get: function () {
            return this.week && this.week.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return ScheduleConsoleWeeklyIndicatorComponent;
}());
export { ScheduleConsoleWeeklyIndicatorComponent };
