<div class="kendo-chart">
  <kendo-chart *ngIf="model" (seriesClick)="handleClick($event)">
    <ng-template kendoChartDonutCenterTemplate>
      <div class="chart-center">
        <div *ngIf="model.input.additionalValue !== null">
          <div class="value" [style.color]="model.additionalColor">{{ model.input.additionalValue }}</div>
          <div class="label-text">{{ model.input.additionalLabel }}</div>
        </div>

        <div *ngIf="model.input.additionalValue === null">
          <i class="fas fa-check fa-3x" aria-hidden="true"></i>
        </div>
      </div>
    </ng-template>
    <kendo-chart-series>
      <kendo-chart-series-item type="donut" [data]="[{
        color: model.color.backgroundColor2,
        value: model.value1
      }, {
        color: model.color.backgroundColor1,
        value: model.value2
      }]" [holeSize]="33" field="value" colorField="color"></kendo-chart-series-item>
    </kendo-chart-series>
    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
  </kendo-chart>
</div>
