<slx-daily-line-punch-grid
  [employeeId]="timecard?.employee.id"
  [employeeName]="timecard?.employee.name"
  [minPunchDate]="timecard?.minPunchDate"
  [maxPunchDate]="timecard?.maxPunchDate"
  [defaultPunchTime]="timecard?.date"
  [userActions]="userActions"
  [displayOptions]="displayOptions"
></slx-daily-line-punch-grid>

