import { Session } from './session';

export class AuthResponse {
    public statusCode: AuthStatusCode;
    public description: string;
    public session: Session;
}

export enum AuthStatusCode {
    valid,
    passwordExpired,
    resetRequested,
    invalidUserOrPassword,
    invalidIPAddress
}
