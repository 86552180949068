<div [ngSwitch]="chart.type">
  <slx-chart-widget class="day-donut-chart" [config]="widgetConfig" [input]="chartInput" *ngSwitchCase="chartTypes.Donut"
  [useDefaultClickHanlder]="true" (onWidgetDefaultClick)="hanldeWidgetClick (comparison)"
  >
    <ng-template slxChartWidgetMiddleTemplate>
      <div class="hor-container">
        <div class="ver-container">
          <slx-labeled-value-pair class="values" (onClick1)="handleClickPair1($event)" (onClick2)="handleClickPair2($event)"></slx-labeled-value-pair>

          <div class="icons">
            <div class="click-catcher"></div>
            <slx-labeled-badge class="labeled-badge" icon="fa-envelope-open" label="Messages" [value]="comparison.pendingMessagesCount" (onClick)="handleClickBadgeMessages($event)"></slx-labeled-badge>
            <slx-labeled-badge class="labeled-badge" icon="far fa-clock" label="Overtime" [value]="comparison.overtimeEmployees" (onClick)="handleClickBadgeOvertime($event)"></slx-labeled-badge>
            <slx-labeled-badge class="labeled-badge" icon="fa-user-times" label="PTO" [value]="comparison.ptoRequests" (onClick)="handleClickBadgePto($event)"></slx-labeled-badge>
            <slx-labeled-badge class="labeled-badge" icon="fa-exclamation-square" label="Late" [value]="comparison.lateCount" (onClick)="handleClickBadgeLate($event)"></slx-labeled-badge>
          </div>
        </div>

        <div class="chart">
          <slx-donut-chart-value-pair (onClick)="handleClickChart($event)"></slx-donut-chart-value-pair>
        </div>
      </div>
    </ng-template>
  </slx-chart-widget>

  <slx-chart-widget class="day-bar-chart" [config]="widgetConfig" [input]="chartInput" *ngSwitchCase="chartTypes.Bar">
    <ng-template slxChartWidgetMiddleTemplate>
      <div class="hor-container">
        <div>
          <slx-labeled-value-pair class="values" (onClick1)="handleClickPair1($event)" (onClick2)="handleClickPair2($event)"></slx-labeled-value-pair>

          <div class="icons">
        <slx-labeled-badge class="labeled-badge" icon="fa-envelope-open" label="Messages" [value]="comparison.pendingMessagesCount" (onClick)="handleClickBadgeMessages($event)"></slx-labeled-badge>
        <slx-labeled-badge class="labeled-badge" icon="far fa-clock" label="Overtime" [value]="comparison.overtimeEmployees" (onClick)="handleClickBadgeOvertime($event)"></slx-labeled-badge>
        <slx-labeled-badge class="labeled-badge" icon="fa-user-times" label="PTO" [value]="comparison.ptoRequests" (onClick)="handleClickBadgePto($event)"></slx-labeled-badge>
        <slx-labeled-badge class="labeled-badge" icon="fa-exclamation-square" label="Late" [value]="comparison.lateCount" (onClick)="handleClickBadgeLate($event)"></slx-labeled-badge>
          </div>
        </div>

        <div class="chart">
          <slx-bar-chart-vertical-value-pair (onClick)="handleClickChart($event)"></slx-bar-chart-vertical-value-pair>
        </div>
      </div>
    </ng-template>
  </slx-chart-widget>

  <slx-chart-widget class="day-small-chart" [config]="widgetConfig" [input]="chartInput" *ngSwitchCase="chartTypes.Small">
    <ng-template slxChartWidgetMiddleTemplate>
      <div class="chart">
        <slx-bar-chart-horizontal-value-pair [hideValues]="false" [hideLabel]="true" [outLabel]="true" (onClick)="handleClickChart($event)"></slx-bar-chart-horizontal-value-pair>
      </div>
    </ng-template>

    <ng-template slxChartWidgetBottomTemplate>
      <div class="icons">
        <slx-labeled-badge class="labeled-badge" icon="fa-envelope-open" label="Messages" [value]="comparison.pendingMessagesCount" (onClick)="handleClickBadgeMessages($event)"></slx-labeled-badge>
        <slx-labeled-badge class="labeled-badge" icon="far fa-clock" label="Overtime" [value]="comparison.overtimeEmployees" (onClick)="handleClickBadgeOvertime($event)"></slx-labeled-badge>
        <slx-labeled-badge class="labeled-badge" icon="fa-user-times" label="PTO" [value]="comparison.ptoRequests" (onClick)="handleClickBadgePto($event)"></slx-labeled-badge>
        <slx-labeled-badge class="labeled-badge" icon="fa-exclamation-square" label="Late" [value]="comparison.lateCount" (onClick)="handleClickBadgeLate($event)"></slx-labeled-badge>
      </div>
    </ng-template>
  </slx-chart-widget>

  <slx-chart-widget class="day-bar-chart" [config]="widgetConfig" [input]="chartInput" *ngSwitchCase="chartTypes.None">
      <ng-template slxChartWidgetTopTemplate>
          <div class="top-no-chart">
            <slx-chart-status-icon></slx-chart-status-icon>
          </div>
        </ng-template>
      <ng-template slxChartWidgetMiddleTemplate>
      <div class="hor-container">
        <div>
          <slx-labeled-value-pair class="values" (onClick1)="handleClickPair1($event)" (onClick2)="handleClickPair2($event)"></slx-labeled-value-pair>
          <div class="icons">
        <slx-labeled-badge class="labeled-badge" icon="fa-envelope-open" label="Messages" [value]="comparison.pendingMessagesCount" (onClick)="handleClickBadgeMessages($event)"></slx-labeled-badge>
        <slx-labeled-badge class="labeled-badge" icon="far fa-clock" label="Overtime" [value]="comparison.overtimeEmployees" (onClick)="handleClickBadgeOvertime($event)"></slx-labeled-badge>
        <slx-labeled-badge class="labeled-badge" icon="fa-user-times" label="PTO" [value]="comparison.ptoRequests" (onClick)="handleClickBadgePto($event)"></slx-labeled-badge>
        <slx-labeled-badge class="labeled-badge" icon="fa-exclamation-square" label="Late" [value]="comparison.lateCount" (onClick)="handleClickBadgeLate($event)"></slx-labeled-badge>
          </div>
        </div>
      </div>
    </ng-template>
  </slx-chart-widget>

  <div *ngSwitchDefault>
    Loading...
  </div>
</div>
