<slx-spinner [show]="state.isLoading" novalidate>
  <div class="main-container">
  <div class="sections">
      <form #form="ngForm" class="row-form row-item">
        <slx-input-decorator class="element">
          <slx-date-picker-ngx slx-input
            [minDate]="maxApprovedDate"
            [(ngModel)]="effectiveDate"
            (ngModelChange)="onModelChanged()"
            [required]="true"
            name="effectiveDate"
            placeholder="Pay Cycle Start"
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <span errorMessage for="date"></span>
        </slx-input-decorator>

        <slx-input-decorator class="element">
            <slx-dropdown-input slx-input
                [lookup]="{lookupType: 'payCyclePeriods'}"
                valueField="name" titleField="description" [(ngModel)]="period"
                name="periodInput"
                [required]="true" placeholder="Pay Cycle Type">
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
        </slx-input-decorator>

        <slx-input-decorator class="element" *ngIf="isSemiMonthly">
          <slx-kendo-number slx-input
            [(ngModel)]="startDay1"
            [min]="days.min"
            [max]="maxDay"
            [slxMin]="days.min"
            [slxMax]="maxDay"
            [step]="1"
            [required]="'true'"
            [readonly]="!days.canChoose"
            placeholder="Start Day 1 (ex.1)"
            name="startDay1"
          ></slx-kendo-number>
          <span errorMessage for="required"></span>
          <span errorMessage for="min">The value should be equal or greater than {{ days.min  }}</span>
          <span errorMessage for="max">The value should be equal or lower than {{ maxDay }}</span>
        </slx-input-decorator>

        <slx-input-decorator class="element" *ngIf="isSemiMonthly">
          <slx-kendo-number slx-input
            [(ngModel)]="startDay2"
            [min]="minDay"
            [max]="days.max"
            [slxMin]="minDay"
            [slxMax]="days.max"
            [step]="1"
            [required]="'true'"
            [readonly]="!days.canChoose"
            placeholder="Start Day 2 (ex.16)"
            name="startDay2"
          ></slx-kendo-number>
          <span errorMessage for="required"></span>
          <span errorMessage for="min">The value should be equal or greater than {{ minDay }}</span>
          <span errorMessage for="max">The value should be equal or lower than {{ days.max }}</span>
        </slx-input-decorator>

        <div class="spacer"></div>

        <div class="buttons">
          <button type="button" class="theme-button-apply margin-r" (click)="createPayCycles()" [disabled]="!form.valid || !canSend">Assign</button>
          <button type="button" class="theme-button-cancel" (click)="cancel()">Cancel</button>
        </div>
      </form>

    <div class="section">
      <slx-employee-grid [fieldDefinition]="fieldDefinition" [employees]="actionEmployees" [isMultiselect]="true" (onEmployeesSelect)="employeesSelect($event)"></slx-employee-grid>
    </div>
    
  </div>

</div>
</slx-spinner>
