import { ElementRef, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export var ZIP_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return ZipInputComponent; }),
    multi: true
};
export var ZIP_VALIDATOR = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(function () { return ZipInputComponent; }),
    multi: true
};
var ZipInputComponent = /** @class */ (function () {
    function ZipInputComponent(elementRef) {
        this.placeholder = 'Zip';
        this.minlength = 5;
        this.maxlength = 10;
        this.innerValue = '';
        this.hasFocus = false;
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.hasFocus = false;
        this.zipMaskConf = {
            mask: [/[0-9]/, /\d/, /\d/, /\d/, /\d/],
            guide: false
        };
    }
    Object.defineProperty(ZipInputComponent.prototype, "readonly", {
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ZipInputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    ZipInputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
    };
    ZipInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !myattr;
    };
    ZipInputComponent.prototype.writeValue = function (value) {
        this.innerValue = value;
        this.elementRef.nativeElement.value = value ? value : null;
    };
    ZipInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    ZipInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    ZipInputComponent.prototype.valueChanged = function (value) {
        this.onChangeCallback(value);
        this.onTouchedCallback();
    };
    ZipInputComponent.prototype.validate = function (c) {
        var minLengthValidator = Validators.minLength(+this.minlength);
        var maxLengthValidator = Validators.maxLength(+this.maxlength);
        var errors = {};
        if (minLengthValidator(c)) {
            errors.min = true;
        }
        if (maxLengthValidator(c)) {
            errors.max = true;
        }
        return _.size(errors) > 0 ? errors : null;
    };
    return ZipInputComponent;
}());
export { ZipInputComponent };
