/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./date-picker.component";
import * as i3 from "@angular/forms";
var styles_DatePickerComponent = [i0.styles];
var RenderType_DatePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DatePickerComponent, data: {} });
export { RenderType_DatePickerComponent as RenderType_DatePickerComponent };
export function View_DatePickerComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_DatePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["slx-date-picker", ""]], null, null, null, View_DatePickerComponent_0, RenderType_DatePickerComponent)), i1.ɵdid(1, 770048, null, 0, i2.DatePickerComponent, [i1.ElementRef, i3.NgControl], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DatePickerComponentNgFactory = i1.ɵccf("[slx-date-picker]", i2.DatePickerComponent, View_DatePickerComponent_Host_0, { placeholder: "placeholder", readonly: "readonly", minDate: "minDate", maxDate: "maxDate", format: "format" }, {}, []);
export { DatePickerComponentNgFactory as DatePickerComponentNgFactory };
