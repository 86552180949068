<form novalidate #dailyPunchesPairForm="ngForm" class="time-and-attendance daily-line-punch-grid">
  <kendo-grid [slxKendoGridColumnsGroup]="groupName" #kendoGrid
  class="slx-detail-grid slx-blue-grid slx-grid-slim-rows"
  [slxKendoGridRowClick]="canEdit"
  [editedRowIndex]="gridState.editedRowIndex"
  cancelOnBlur="true"
  ignoreParentClass="k-animation-container"
  [disableRowClick]="!userActions.canEditTimecards || isMobile"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [data]="gridState.view"
  (save)="saveHandler($event)"
  (editRow)="editHandler($event)"
  (cancelRow)="gridState.cancelHandler($event)"
  [data]="gridState.view"
  [selectable]="false"
  (dataStateChange)="gridState.dataStateChange($event)"
  [sortable]="false"
  [sort]="gridState.state.sort"
  [filterable]="false"
  [filter]="gridState.state.filter"
  [scrollable]="false"
  [slxGroupable]="false"
  [slxGroupableMobile]="false"
  (resizeEvent)="resizeEvent($event)"
  (cancel)="cancelHandler($event)"
  (remove)="removeHandler($event)"
  (selectionChange)="gridState.selectionChange($event)" slxKendoGridMobileManager >

    <ng-template kendoGridToolbarTemplate>
        <slx-toolbar [alignExpandButtonRight]="true">
          <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" [width]="150" alignMode="leftWithPriorityIfNotEnoughPlace">
              <slx-toolbar-section [showDivider]="false">
                  <button *ngIf="canEdit" type="button" class="slx-button slx-with-icon slx-inore-by-kendo-row-click-directive"
                  [ngClass]="{'slx-wide': isCollapsed}"
                  [disabled]="isTimecardLocked || !userActions.canEditTimecards || rowInEditing || hasOverlapPunches" (click)="onAddPunch(kendoGrid)">
                      <i class="fal fa-calendar-plus slx-button__icon" aria-hidden="true"></i>
                      <span class="slx-button__text">Add Punch</span>
                  </button>
              </slx-toolbar-section>
            </ng-template>
            <ng-container *ngIf="canEdit && userActions.canSave">
              <ng-template  slxToolbarSectionTemplate let-isCollapsed="isCollapsed" [width]="150" alignMode="leftWithPriorityIfNotEnoughPlace">
                  <slx-toolbar-section [showDivider]="false">
                      <button type="button" class="slx-button slx-with-icon slx-inore-by-kendo-row-click-directive"
                      [ngClass]="{'slx-wide': isCollapsed}"
                      [disabled]="isTimecardLocked || !userActions.canEditTimecards || !(hasChanges || hasRemoved) || !dailyPunchesPairForm.valid || hasOverlapPunches" (click)="onSaveChanges()">
                          <i class="fas fa-save slx-button__icon" aria-hidden="true"></i>
                          <span class="slx-button__text">Save</span>
                      </button>
                  </slx-toolbar-section>
              </ng-template>
            </ng-container>
            <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" [width]="151">
                <slx-toolbar-section [showDivider]="false">
                    <button *ngIf="canEdit" type="button" class="slx-button slx-with-icon slx-inore-by-kendo-row-click-directive"
                    [ngClass]="{'slx-wide': isCollapsed}"
                    [disabled]="isTimecardLocked || !userActions.canEditTimecards || !hasChanges" (click)="onDiscardChanges()">
                        <i class="fas fa-undo-alt slx-button__icon" aria-hidden="true"></i>
                        <span class="slx-button__text">Reset</span>
                    </button>
                </slx-toolbar-section>
            </ng-template>
            <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" [width]="152">
              <slx-toolbar-section [showDivider]="false">
                  <button type="button" class="slx-button slx-with-icon slx-inore-by-kendo-row-click-directive"
                  [ngClass]="{'slx-wide': isCollapsed}"
                  (click)="showPunchLogs()">
                      <i class="fas fa-undo-alt slx-button__icon" aria-hidden="true"></i>
                      <span class="slx-button__text">Punch Log</span>
                  </button>
              </slx-toolbar-section>
          </ng-template>
            <ng-template slxToolbarSectionTemplate  let-isCollapsed="isCollapsed" [width]="153" alignMode="leftWithPriorityIfNotEnoughPlace">
                <slx-toolbar-section [showDivider]="false">
                    <span *ngIf="!isCollapsed && hasInMissingPunch" class="missing-alert">Missing In Punch</span>
                    <span *ngIf="!isCollapsed && hasOutMissingPunch" class="missing-alert">Missing Out Punch</span>
                    <span *ngIf="!isCollapsed && filteredPunchesCount<punchesCount" class="filtered-alert">Punches (Filtered): {{filteredPunchesCount}} of {{punchesCount}}</span>
                </slx-toolbar-section>
            </ng-template>
            <ng-container *ngIf="!userActions.canSave">
              <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" [width]="150" alignMode="rightIfNothingCollapsed">
                  <slx-toolbar-section [showDivider]="false">
                      <ng-container *ngIf="!isCollapsed">
                          <button type="button" class="theme-icon-button"
                          [popper]="filterToggler"
                          [popperTrigger]="'click'"
                          [popperPlacement]="popperPosition"
                          [popperDisableStyle]="'true'"
                          [popperPositionFixed]="true">
                            <i class="fa fa-cog" aria-hidden="true"></i>
                          </button>
                          <popper-content #filterToggler class="popper-white-background">
                            <slx-punches-event-filter [records]="savedRecords"></slx-punches-event-filter>
                          </popper-content>
                        </ng-container>
                        <slx-columns-config-button *ngIf="!isCollapsed && !hideSettingsButton" [colGroupKey]="groupName" title="Punches columns"></slx-columns-config-button>
                  </slx-toolbar-section>
              </ng-template>
          </ng-container>
       </slx-toolbar>
<!--
      <div class="toolbar-buttons">
        <div #toolBarLeft class="toolbar-left-buttons">
          <button *ngIf="canEdit" type="button" [disabled]="rowInEditing" (click)="onAddPunch(kendoGrid)" class="theme-button-apply slx-inore-by-kendo-row-click-directive">Add Punch</button>
          <button *ngIf="canEdit && userActions.canSave" type="button" [disabled]="!(hasChanges || hasRemoved) || !dailyPunchesPairForm.valid" (click)="onSaveChanges()"
            class="theme-button-apply">Save</button>
          <button *ngIf="canEdit" type="button" (click)="onDiscardChanges()" class="theme-button-apply discard-button slx-inore-by-kendo-row-click-directive"
            [disabled]="!hasChanges">Reset</button>
          <button type="button" (click)="showPunchLogs()" class="theme-button-apply punch-log-button">Punch Log</button>
          <span *ngIf="hasInMissingPunch" class="missing-alert">Missing In Punch</span>
          <span *ngIf="hasOutMissingPunch" class="missing-alert">Missing Out Punch</span>
          <span *ngIf="filteredPunchesCount<punchesCount" class="filtered-alert">Punches (Filtered): {{filteredPunchesCount}} of {{punchesCount}}</span>
        </div>
        <div class="toolbar-right-buttons">
          <ng-container *ngIf="!userActions.canSave">
            <button type="button" class="theme-icon-button" slx-popup [popupContent]="filterToggler">
              <i class="fa fa-cog" aria-hidden="true"></i>
            </button>
            <div #filterToggler class="filter-menu theme-popup-menu">
              <slx-punches-event-filter [records]="savedRecords"></slx-punches-event-filter>
            </div>
          </ng-container>
          <slx-columns-config-button *ngIf="!hideSettingsButton" [colGroupKey]="groupName" title="Punches columns"></slx-columns-config-button>
        </div>
      </div>
    -->
    </ng-template>

    <kendo-grid-command-column width="65" class="overflow-visible-cell" media="(min-width: 781px)" *ngIf="!isTimecardLocked">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
        <div class="commands-container">
          <button kendoGridRemoveCommand *ngIf="canEdit && (dataItem?.punch.punchDisplay === validPunch
          ||dataItem?.punch.punchDisplay === validEdited
          ||dataItem?.punch.punchDisplay === edited
          || dataItem?.punch.punchDisplay === overlap
         || dataItem?.punch.punchDisplay === invalidLogin
          || (dataItem?.punch.punchDisplay === punchRequest && !dataItem?.punch.isDeleted))"
            type="button"
            class="slx-icon-button slx-inline-edit-button"
            [disabled]="!userActions.canEditTimecards">
            <i *ngIf="dataItem?.punch.punchDisplay !== punchRequest" class="fa fa-times" aria-hidden="true"></i>
            <i *ngIf="dataItem?.punch.punchDisplay === punchRequest" class="far fa-minus-circle" aria-hidden="true"></i>
          </button>
          <button kendoGridSaveCommand type="button" [disabled]="!userActions.canEditTimecards || !dailyPunchesPairForm.valid || dataItem?.punch.type?.description == ''"
            class="slx-icon-button slx-inline-edit-button" [title]="BindTitle(dataItem?.punch.type?.description)">
            <i class="far fa-check-circle" aria-hidden="true"></i>
          </button>
          <button *ngIf="dataItem?.punch.isEditedAndValidated && !rowInEditing && dataItem?.punch.punchDisplay === edited" type="button"
            class="slx-icon-button slx-inline-edit-button" (click)="onInvalidate(dataItem)">
            <i class="fa fa-undo" aria-hidden="true"></i>
          </button>
          <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button">
            <i class="fa fa-ban" aria-hidden="true"></i>
          </button>
        </div>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column media="(max-width: 780px)" class="slx-no-padding" *ngIf="!isTimecardLocked">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
        <div class="slx-mobile-expandable">
          <div class="punch-status-container"><slx-daily-line-punch-status [punch]="dataItem?.punch" (showComment)="showComment($event)"></slx-daily-line-punch-status> <span class="slx-mobile-expandable__header-additional">({{dataItem?.punch.type?.description}})</span></div>
          <slx-collapse-button [slxCollapseByCss]="paycodeCellContent"></slx-collapse-button>
        </div>
        <div #paycodeCellContent class="slx-mobile-expandable-content">
          <div class="slx-mobile-expandable-content__buttons" *ngIf="!isTimecardLocked">
              <button type="button" class="slx-icon-button slx-inline-edit-button" (click)="editHandler({sender:kendoGrid, dataItem: dataItem, rowIndex: rowIndex })">
                  <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                </button>
                <button *ngIf="dataItem?.punch.isEditedAndValidated && !rowInEditing && dataItem?.punch.punchDisplay === edited" type="button"
                  class="slx-icon-button slx-inline-edit-button" (click)="onInvalidate(dataItem)">
                  <i class="fa fa-undo" aria-hidden="true"></i>
                </button>
                <button kendoGridRemoveCommand *ngIf="canEdit && (dataItem?.punch.punchDisplay === validPunch||dataItem?.punch.punchDisplay === validEdited||dataItem?.punch.punchDisplay === edited || dataItem?.punch.punchDisplay === overlap|| dataItem?.punch.punchDisplay === invalidLogin)"
                type="button" class="slx-icon-button slx-inline-edit-button">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
          </div>
          <div>
              <slx-daily-line-punch-mobile [editable]="false" [dataItem]="dataItem" [punchTypesLookup]="punchTypesLookup" [employeeId]="employeeId" [displayOptions]="displayOptions"></slx-daily-line-punch-mobile>
          </div>
        </div>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div class="slx-mobile-expandable">
          <div><slx-daily-line-punch-status [punch]="dataItem?.punch"  (showComment)="showComment($event)"></slx-daily-line-punch-status><span class="slx-mobile-expandable__header-additional">({{dataItem?.punch.type?.description}})</span></div>
          <slx-collapse-button [slxCollapseByCss]="paycodeCellContent"></slx-collapse-button>
        </div>
        <div #paycodeEditCellContent class="slx-mobile-expandable-content">
          <div class="slx-mobile-expandable-content__buttons" *ngIf="!isTimecardLocked">
            <button kendoGridSaveCommand type="button" [disabled]="!dailyPunchesPairForm.valid || dataItem?.punch.type?.description == ''" class="slx-icon-button slx-inline-edit-button" [title]="BindTitle(dataItem?.punch.type?.description)">
              <i class="far fa-check-circle" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button">
              <i class="fa fa-ban" aria-hidden="true"></i>
            </button>
          </div>
          <div>
             <slx-daily-line-punch-mobile [editable]="true" [dataItem]="dataItem" [punchTypesLookup]="punchTypesLookup" [employeeId]="employeeId" [displayOptions]="displayOptions"></slx-daily-line-punch-mobile>
          </div>
        </div>
      </ng-template>
  </kendo-grid-column>

    <kendo-grid-column title="Display" width="110" class="overflow-visible-cell" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Display</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <p class="punch-status-wrapper">
          <slx-daily-line-punch-status class="punch-status" [punch]="dataItem?.punch"  (showComment)="showComment($event)"></slx-daily-line-punch-status>
          <span *ngIf="dataItem?.punch.isGeoPunch" (click)="onShowMap(dataItem?.punch)" class="geo-punch k-animation-container" title="Click to see the map">
            <i class="fal fa-globe" aria-hidden="true"></i>
          </span>
        </p>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Type" [width]="rowInEditing ? 200 : 130" field="punch.type" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Type</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.punch.type?.description != '' ? dataItem?.punch.type?.description : ''}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator className="slx-no-label">
          <slx-dropdown-input slx-input required="true" name="type" placeholder="Type" *ngIf="dataItem?.punch.type?.description != ''" [lookup]="invalidLoginPunchTypesLookup" [(ngModel)]="dataItem?.punch.type">
          </slx-dropdown-input>
          <slx-dropdown-input slx-input required="true" name="invalidLoginType" placeholder="InvalidLoginType" *ngIf="dataItem?.punch.type?.description == ''" [lookup]="invalidLoginPunchTypesLookup" [(ngModel)]="dataItem?.punch.type">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Timeclock" [width]="rowInEditing ? 200 : 150" field="punch.timeclock.name" slxKendoGridColumnHiddenState media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Timeclock</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.punch.timeclock?.name}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator className="slx-no-label" *ngIf="!requireTimeclockForPunches">
          <slx-dropdown-input slx-input name="timeclock" lookup-add-item emptyOption placeholder="Time Clock" [lookup]="{lookupType: 'timeclockDefinition', orgLevelId: undefined, employeeId: employeeId}"
            [(ngModel)]="dataItem?.punch.timeclock">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
        <slx-input-decorator className="slx-no-label" *ngIf="requireTimeclockForPunches">
          <slx-dropdown-input slx-input name="timeclock" required placeholder="Time Clock" [lookup]="{lookupType: 'timeclockDefinition', orgLevelId: undefined, employeeId: employeeId}"
            [(ngModel)]="dataItem?.punch.timeclock">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Actual time" [width]="rowInEditing ? 250 : 150" field="punch.time" slxKendoGridColumnHiddenState media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Actual time</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.punch.time | amDateFormat: appConfig.dateTimeFormatUS }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator className="slx-no-label">
          <input slx-date-time-picker slx-input [required]="true" name="time" placeholder="Time" [slxMinDate]="minPunchDate" [slxMaxDate]="maxPunchDate"
            [(ngModel)]="dataItem.punch.time">
          <span errorMessage for="minDate">The value should be greater than {{minPunchDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
          <span errorMessage for="maxDate">The value should be lower than {{maxPunchDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
          <span errorMessage for="datetime">Invalid datetime</span>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Rounded time" [width]="rowInEditing ? 250 : 150" field="punch.roundedTime" class="numeric-cell"
      slxKendoGridColumnHiddenState media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Rounded time</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.punch.roundedTime | amDateFormat: appConfig.dateTimeFormatUS }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Rounded Interval" width="120" field="punch.punchPairInfo.roundedInterval" slxKendoGridColumnHiddenState media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Rounded Interval</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="(dataItem?.punch.closingPunch || dataItem?.punch.type.name === 'in from paid break') && (!displayOptions || displayOptions==='number')">{{ getRoundedInterval(dataItem?.punch) | slxDuration:"hours" | number: "1.2-2" }}</span>
        <span *ngIf="(dataItem?.punch.closingPunch || dataItem?.punch.type.name === 'in from paid break') && displayOptions==='hmstring'">{{  getRoundedInterval(dataItem?.punch) | slxInterval: "hminstring" }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Position" width="150" field="punch.position.name" slxKendoGridColumnHiddenState [displayedDefault]="false" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Position</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.punch.canBeClosing">{{ dataItem?.punch.position?.name }}</span>
        <span *ngIf="!dataItem?.punch.canBeClosing">{{ dataItem?.punch.positionsString }}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator *ngIf="dataItem?.punch.canBeClosing" className="slx-no-label slx-no-error-block">
          <slx-dropdown-input slx-input name="position" placeholder="Position" [lookup]="positionsLookup"
            [(ngModel)]="dataItem?.punch.position">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
        <span *ngIf="!dataItem?.punch.canBeClosing">{{ dataItem?.punch.positionsString }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Inservice" width="100" field="punch.inService" slxKendoGridColumnHiddenState [displayedDefault]="false" media="(min-width: 781px)">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Inservice</span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem?.punch.inService | boolyn}}</span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator className="slx-no-label slx-no-border slx-small-font slx-checkbox-small slx-no-error-block">
          <slx-checkbox-input slx-input name="inservice" fieldName="inservice{{rowIndex}}" placeholder="" caption="" [(ngModel)]="dataItem.punch.inService">
          </slx-checkbox-input>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <!--
    <kendo-grid-column title="Schedule Start Time" width="170" media="sm" field="punch.closingPunch.schedule.startTime" slxKendoGridColumnHiddenState>
      <ng-template kendoGridHeaderTemplate>
        Schedule Start Time
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.punch.closingPunch">{{ dataItem?.punch.closingPunch?.schedule?.startTime | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Schedule End Time" width="170" media="sm" field="punch.closingPunch.schedule.endTime" slxKendoGridColumnHiddenState>
      <ng-template kendoGridHeaderTemplate>
        Schedule End Time
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.punch.closingPunch">{{ dataItem?.punch.closingPunch?.schedule?.endTime | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Hours" width="100" media="sm" field="punch.closingPunch.schedule.hours" slxKendoGridColumnHiddenState>
      <ng-template kendoGridHeaderTemplate>
        Hours
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.punch.closingPunch">{{ dataItem?.punch.closingPunch?.schedule?.hours | number: "1.2-2"}}</span>
      </ng-template>
    </kendo-grid-column>
  -->
  </kendo-grid>
