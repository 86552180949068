import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { GenericListApiService } from './generic-list-api.service';
import {
  GenericListDefinition, GenericList, GenericRow, GenericListRequest
} from '../../models/generic-list/index';

/*
use this service only in component injector (which wraps generic-grid)
@Component({
  providers: [ GenericListManagementService ]
})
*/
@Injectable()
export class GenericListManagementService {
  public onRowsSelectionChanged$: Subject<GenericRow[]>;
  public onDefinitionsLoaded$: Subject<GenericListDefinition>;
  public onDefinitionsPrepare$: Subject<GenericListDefinition>;
  public onValuesLoaded$: Subject<GenericList>;
  public selectedRows: GenericRow[];
  public listDefinition: GenericListDefinition;
  public listValues: GenericList;

  private genericListApiService: GenericListApiService;
  constructor(genericListApiService: GenericListApiService) {
    this.genericListApiService = genericListApiService;
    this.onRowsSelectionChanged$ = new Subject();
    this.onDefinitionsLoaded$ = new Subject();
    this.onDefinitionsPrepare$ = new Subject();
    this.onValuesLoaded$ = new Subject();
  }

  public onRowsSelected(event: GenericRow[]): void {
    this.selectedRows = event;
    this.onRowsSelectionChanged$.next(event);
  }

  public loadListDefinition(applicationId: number, orgLevelId: number, listName: string, updateCacheForced : boolean = true): void {
    this.genericListApiService.getListDefinitions(applicationId, orgLevelId, listName, updateCacheForced)
      .then((listDefinition: GenericListDefinition) => {
        this.onDefinitionsPrepare$.next(listDefinition);
        this.listDefinition = listDefinition;
        this.onDefinitionsLoaded$.next(this.listDefinition);
      }).catch((error: any)=> {
        this.listDefinition = null;
        this.onDefinitionsLoaded$.next(null);
      });
  }

  public loadListValues(orgLevelId: number, req: GenericListRequest): void {
    this.genericListApiService.listValues(orgLevelId, this.listDefinition, req)
      .then((listValues: GenericList) => {
        this.listValues = listValues;
        this.onValuesLoaded$.next(this.listValues);
      }).catch((error: any)=> {
        this.listValues = null;
        this.onValuesLoaded$.next(null);
      });
  }

}
