import { ShiftSwapManageService } from './../../services/shift-swap/shift-swap-manage.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-shift-swap',
    templateUrl: 'shift-swap.component.html',
    styleUrls: ['shift-swap.component.scss']
})
export class ShiftSwapComponent implements OnInit, OnDestroy {
    constructor(public manageService: ShiftSwapManageService) {}

    public ngOnInit(): void {
        this.manageService.init();
    }

    public ngOnDestroy(): void {
      this.manageService.destroy();
    }
}
