import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { ScheduleConsoleFiltersService } from '../../../services/index';
import { OperationalConsoleStateService } from '../../../../services/operational-console/operational-console-state.service';
import { LookupType } from '../../../../../organization/index';
import { LookupService } from '../../../../../organization/services/index';
var ScheduleConsoleOverviewToolbarComponent = /** @class */ (function () {
    function ScheduleConsoleOverviewToolbarComponent(filterService, stateService, lookupService) {
        this.filterService = filterService;
        this.stateService = stateService;
        this.lookupService = lookupService;
        this.showDetailedControl = false;
        this.onShowDetailedChange = new EventEmitter();
    }
    Object.defineProperty(ScheduleConsoleOverviewToolbarComponent.prototype, "currentOrgLevel", {
        get: function () {
            return this.m_currentOrgLevel;
        },
        enumerable: true,
        configurable: true
    });
    ScheduleConsoleOverviewToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.m_currentOrgLevel = orgLevel;
            _this.updateLookups();
        });
        this.updateLookups();
    };
    ScheduleConsoleOverviewToolbarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ScheduleConsoleOverviewToolbarComponent.prototype.updateLookups = function () {
        var _this = this;
        if (!this.currentOrgLevel) {
            return;
        }
        this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: this.currentOrgLevel.id })
            .then(function (locationUnitLookup) {
            _this.locationUnitLookup = locationUnitLookup;
            _this.isUnitDropdownAvailable = locationUnitLookup && locationUnitLookup.items.length > 0;
        });
        this.lookupService.getLookup({ lookupType: LookupType.shift, orgLevelId: this.currentOrgLevel.id })
            .then(function (shiftLookup) {
            _this.shiftLookup = shiftLookup;
            _this.isShiftDropdownAvailable = shiftLookup && shiftLookup.items.length > 0;
        });
    };
    ScheduleConsoleOverviewToolbarComponent.prototype.onUnitChanged = function () {
        this.stateService.overviewFiltersChanged(this.unitFilter, this.shiftFilter);
        this.filterService.applyFilters();
    };
    ScheduleConsoleOverviewToolbarComponent.prototype.onShiftChanged = function () {
        this.stateService.overviewFiltersChanged(this.unitFilter, this.shiftFilter);
        this.filterService.applyFilters();
    };
    ScheduleConsoleOverviewToolbarComponent.prototype.onShowDetailedChanged = function (value) {
        this.onShowDetailedChange.emit(value);
    };
    tslib_1.__decorate([
        mutableSelect(),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduleConsoleOverviewToolbarComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleOverviewToolbarComponent.prototype, "orgLevelSubscription", void 0);
    return ScheduleConsoleOverviewToolbarComponent;
}());
export { ScheduleConsoleOverviewToolbarComponent };
