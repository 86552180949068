<div>
  <form novalidate #gridForm="ngForm">
    <kendo-grid #kendoGrid scrollable="'scrollable'" [data]="gridState.view"
      (dataStateChange)="gridState.dataStateChange($event)" [sortable]="{ mode: 'multiple' }"
      [sort]="gridState.state.sort" [pageable]="false" [groupable]="false" [selectable]="false"
      (selectionChange)="gridState.selectionChange($event)" [slxKendoGridState]="gridState.gridDefinition"
      (stateRestored)="gridState.dataStateChange($event)" [filterable]="false" [filter]="gridState.state.filter"
      (edit)="gridState.editHandler($event)" (save)="gridState.saveHandler($event)"
      (cancel)="gridState.cancelHandler($event)" (remove)="gridState.removeHandler($event)">
      <ng-template kendoGridToolbarTemplate position="top">
        <div class="col-xs-12" *ngIf="employeeSectionsDocuments?.actions.canAdd && isEditable">
          <div class="row flex justify-content__flex-end">
            <button class="theme-iconed-accent-button" (click)="onStartAdd()">
              <span>Add</span>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </ng-template>

      <kendo-grid-column title="" [sortable]="false" [filterable]="false" width="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex">
            <button kendoGridRemoveCommand type="button" class="slx-button slx-only-icon slx-toolbar"
              *ngIf="canEditItem(dataItem) && isEditable" title="Delete">
              <i class="fas fa-times" aria-hidden="true"></i>
            </button>
            <button kendoGridEditCommand type="button" class="slx-button slx-only-icon slx-toolbar"
              *ngIf="canEditItem(dataItem) && isEditable" title="Edit">
              <i class="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
            <button kendoGridSaveCommand type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r"
              [disabled]="!gridForm.valid || (!dataItem.id && !dataItem.file) || !isFileValid" title="Save">
              <i class="far fa-check-circle" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="slx-button slx-only-icon slx-toolbar" title="Cancel">
              <i class="fa fa-ban" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="category" title="Category" width="150">
        <ng-template kendoGridHeaderTemplate>
          Category
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.category?.description }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <slx-dropdown-input slx-input [options]="categoriesLookup" valueField="description" titleField="description"
              [(ngModel)]="dataItem.category" name="categoryInput{{rowIndex}}" [required]="true" placeholder="Category">
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="name" title="Name" width="200">
        <ng-template kendoGridHeaderTemplate>
          Name
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.name }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <input slx-input class="form-control" type="text" [(ngModel)]="dataItem.name" [required]="true"
              placeholder="Name" maxlength="50" slxMaxLength="50" name="nameInput{{rowIndex}}">
            <span errorMessage for="required"></span>
            <span errorMessage for="slxMaxLength">name must not exceed 50 characters</span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="fileName" title="File" width="200">
        <ng-template kendoGridHeaderTemplate>
          File
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="file" (click)="onDocumentFileClick(dataItem.id)">{{ dataItem.fileName }}</span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <div class="input-file">
            <input #fileInput slx-input type="file" [required]="!dataItem.fileName" (change)="onFileChange($event, dataItem, fileInput)"
              (click)="onFileClick($event, dataItem)" />
          </div>
          <div class="edit-file" *ngIf="canShowFileName">
            <span class="file" (click)="onDocumentFileClick(dataItem.id)">{{ dataItem.fileName }}</span>
          </div>
          <div class="slx-error-block">
            <span *ngIf="!isFileValid && dataItem.file" class="validation-result">File size exceeds limit
              ({{ fileSizeLimitInMB }})</span>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="addedOn" title="Added On" width="200">
        <ng-template kendoGridHeaderTemplate>
          Added On
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.addedOn | amDateFormat: appConfig.dateFormat }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="addedBy" title="Added By" width="200">
        <ng-template kendoGridHeaderTemplate>
          Added By
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.addedBy }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</div>
