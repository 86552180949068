/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logi-host.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "../services/logi-management.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../services/logi-api.service";
import * as i8 from "../../../organization/services/application-state-bus/application-state-bus.service";
import * as i9 from "../../../core/services/user-activity/user-activity.service";
import * as i10 from "../services/logi-messaging.service";
import * as i11 from "../../../core/services/breadcrumb/breadcrumb-state.service";
import * as i12 from "./logi-host.component";
import * as i13 from "@angular/router";
import * as i14 from "../../../core/services/window/window-ref.model";
var styles_LogiHostComponent = [i0.styles];
var RenderType_LogiHostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogiHostComponent, data: {} });
export { RenderType_LogiHostComponent as RenderType_LogiHostComponent };
function View_LogiHostComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "logi-host-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["scrolling", "yes"], ["title", "Spotlight"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logiUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_LogiHostComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LogiHostComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isIFrameLoading || _co.isDataLoading); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isActive; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LogiHostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-logi-host", [], null, null, null, View_LogiHostComponent_0, RenderType_LogiHostComponent)), i1.ɵprd(512, null, i5.LogiManagementService, i5.LogiManagementService, [i6.DomSanitizer, i7.LogiApiService, i8.ApplicationStateBusService, i9.UserActivityService, i10.LogiMessagingServices, i11.BreadcrumbStateService]), i1.ɵdid(2, 4374528, null, 0, i12.LogiHostComponent, [i13.Router, i5.LogiManagementService, i14.WindowRef], null, null)], null, null); }
var LogiHostComponentNgFactory = i1.ɵccf("slx-logi-host", i12.LogiHostComponent, View_LogiHostComponent_Host_0, {}, {}, []);
export { LogiHostComponentNgFactory as LogiHostComponentNgFactory };
