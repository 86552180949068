import { IDateRange } from '../../../core/models/index';
import { LocationUnit, EmployeeDefinition } from '../../../organization/models/index';

export class ArrivalDeparturesVariances {
  public static late: ArrivalDeparturesVariancesDefinition = 'Late';
  public static approachingOT: ArrivalDeparturesVariancesDefinition = 'Approaching Overtime';
  public static arrivals: ArrivalDeparturesVariancesDefinition = 'Arrivals';
  public static departures: ArrivalDeparturesVariancesDefinition = 'Departures';
}

export type ArrivalDeparturesVariancesDefinition = 'Late'  | 'Approaching Overtime' | 'Arrivals' | 'Departures'
;

export class ArrivalDeparturesFilter {
  public arrivalsRange: IDateRange;
  public departuresRange: IDateRange;
}

export class ArrivalDeparturesTimelineFilter {
  public arrivalsRange: IDateRange;
  public departuresRange: IDateRange;
  public units: LocationUnit[];
  public variances: ArrivalDeparturesVariances[];
  public empFilter: EmployeeDefinition[];
}
