import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';

export abstract class KendoGridBasicFilterComponent extends BaseFilterCellComponent {
  public abstract showOperators: boolean;
  public abstract column: ColumnComponent;
  public abstract filter: CompositeFilterDescriptor;
  public abstract operator: string;
  public readOnly: boolean;

  public get currentFilter(): FilterDescriptor {
    return this.filterByField(this.column.field);
  }

  public get currentOperator(): string | Function {
    return this.currentFilter ? this.currentFilter.operator : this.operator;
  }

  protected localization: LocalizationService;
  protected localizeOperators(operators: any): Function {
    return function (localization: LocalizationService): { text: string, value: any }[] {
      let res: { text: string, value: any }[] = _.map(_.keys(operators), (key: string) => {

        let slxLocal: kendoUtils.slxFilter = kendoUtils.slxFiltersMap[key];
        if (slxLocal) {
          return { text: slxLocal.text, value: operators[key] };
        }
        return { text: localization.get(key), value: operators[key] };
      });
      return res;
    };
  }

  constructor(filterService: FilterService, localization: LocalizationService) {
    super(filterService);
    this.localization = localization;
  }

  public operatorChanged(operator: string): void {
    this.readOnly = kendoUtils.isBlankOperator(operator);
  }
}
