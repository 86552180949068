import * as tslib_1 from "tslib";
import { SessionActions } from './../../../authentication/actions/session.actions';
import { OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../framework/index';
import { LoggerService, SessionService, ThrottlingService, TokenValidityService } from '../../../core/services/index';
import { IncativityNotificationComponent } from '../../../common/components/inactivity-notification/inactivity-notification.component';
import { ApplicationActions } from '../../actions/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { unsubscribe } from '../../../core/decorators/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { LoginDialogComponent } from '../../../authentication/components/login-dialog/login-dialog.component';
import { AuthenticationService } from '../../../authentication/services/index';
import { clearPersistedMainState } from '../../../store/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { PbjExportLogManagementService } from '../../../app-modules/pbj/services';
import { PBJDownloadExcelPdf } from '../../../app-modules/pbj/models/pbj-export/pbjChildGridDownload';
import { NotificationsService } from '../../../core/components';
import { PbjSignalrService } from '../../services/pbj-signalr/pbj-signalr.service';
var PortalComponent = /** @class */ (function () {
    function PortalComponent(appStateBusService, loggerService, applicationActions, userActivityService, modalService, authenticationService, router, sessionAction, thtottling, tokenValidity, pbjSignalrService, pbjExportLogManagementService, sessionService, notificationsService) {
        this.appStateBusService = appStateBusService;
        this.loggerService = loggerService;
        this.applicationActions = applicationActions;
        this.userActivityService = userActivityService;
        this.modalService = modalService;
        this.authenticationService = authenticationService;
        this.router = router;
        this.sessionAction = sessionAction;
        this.thtottling = thtottling;
        this.tokenValidity = tokenValidity;
        this.pbjSignalrService = pbjSignalrService;
        this.pbjExportLogManagementService = pbjExportLogManagementService;
        this.sessionService = sessionService;
        this.notificationsService = notificationsService;
        Assert.isNotNull(loggerService, 'loggerService');
    }
    PortalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelsLoadedSubscription = this.appStateBusService.subscribeToOrgLevelsLoaded(function (orgLevels) {
            scheduleMicrotask(function () {
                if (orgLevels.length === 0) {
                    _this.modalService.globalAnchor.openInfoDialog('Warning', "Your user role does not appear to have access to this part of the application.\n            Please have your System Administrator review your account to make sure that the appropriate access levels are granted.", function () {
                        _this.authenticationService.navigateToLogout(_this.router);
                    });
                }
            });
        });
        this.thresholdReachedSubscription = this.userActivityService.onTimeoutThresholdReached.subscribe(function () {
            _this.incativityNotificationComponent =
                IncativityNotificationComponent.openDialog(_this.modalService, function (result) {
                    //DO NOTHING
                });
        });
        this.inactivityResetedSubscription = this.userActivityService.onIncativityReseted.subscribe(function () {
            _this.closeInactivityNotification();
        });
        this.inactivityTimeoutSubscription = this.userActivityService.onNeedToHideIncativityDialog.subscribe(function () {
            _this.closeInactivityNotification();
        });
        this.timeoutReachedSubscription = this.userActivityService.onInactivityTimedOut.subscribe(function () {
            clearPersistedMainState();
            _this.sessionAction.clearSession();
            if (!_this.loginDialogComponent) {
                _this.loginDialogComponent = LoginDialogComponent.openDialog(_this.modalService, function (result) {
                    _this.loginDialogComponent = null;
                });
            }
        });
        this.tokenExpiredSubscription = this.tokenValidity.onTokenExpired.subscribe(function () {
            clearPersistedMainState();
            _this.sessionAction.clearSession();
            if (!_this.loginDialogComponent) {
                _this.loginDialogComponent = LoginDialogComponent.openDialog(_this.modalService, function (result) {
                    _this.loginDialogComponent = null;
                });
            }
        });
        this.isPbjLoadedSubscription = this.pbjSignalrService.isPDFDownloadInitiated$.subscribe(function (result) {
            if (result) {
                _this.pbjSignalrService.init();
                _this.pbjSignalrService.pbjDownloadOptionsFeedback$.subscribe(function (args) {
                    var downloadOptions = args && args.length > 2 ? JSON.parse(args[2]) : null;
                    if (_this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase() && _this.sessionService.getUser().id === downloadOptions.UserId) {
                        _this.notificationsService.success("Your download is ready!, Downloading now.");
                        var pdfExcelObj = new PBJDownloadExcelPdf();
                        pdfExcelObj.type = downloadOptions.FileType.toLowerCase();
                        pdfExcelObj.exportId = downloadOptions.ExportIds === null ? "0" : downloadOptions.ExportIds;
                        pdfExcelObj.batchId = downloadOptions.BatchId;
                        pdfExcelObj.alias = null;
                        pdfExcelObj.userId = null;
                        pdfExcelObj.orgLevelId = downloadOptions.OrgLevelId;
                        _this.pbjExportLogManagementService.downloadExcelPDF(pdfExcelObj);
                    }
                });
            }
        });
    };
    PortalComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PortalComponent.prototype.closeInactivityNotification = function () {
        if (this.incativityNotificationComponent) {
            this.incativityNotificationComponent.close();
            this.incativityNotificationComponent = null;
        }
    };
    tslib_1.__decorate([
        select(['sidebar', 'isRightSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], PortalComponent.prototype, "isRightSidebarOpen", void 0);
    tslib_1.__decorate([
        select(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], PortalComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PortalComponent.prototype, "thresholdReachedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PortalComponent.prototype, "timeoutReachedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PortalComponent.prototype, "inactivityTimeoutSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PortalComponent.prototype, "inactivityResetedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PortalComponent.prototype, "tokenExpiredSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PortalComponent.prototype, "orgLevelsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PortalComponent.prototype, "isPbjLoadedSubscription", void 0);
    return PortalComponent;
}());
export { PortalComponent };
