import { Component, Provider } from '@angular/core';
import { IDialog, DialogOptions } from '../../../../../../../../app/common/models/index';
import { ModalService } from '../../../../../../../../app/common/services/modal/modal.service';
import { ICreateOsha300AFormDialogOptions } from '../../../../../models/index';

@Component({
  selector: 'slx-wc-create-establishment-size-dialog',
  templateUrl: './wc-create-establishment-size-dialog.component.html',
  styleUrls: ['./wc-create-establishment-size-dialog.component.scss']
})
export class WcCreateEstablishmentSizeDialogComponent implements IDialog {

  public dialogResult: boolean;
  public size: ICreateOsha300AFormDialogOptions;
  public initEstablishmentSize: ICreateOsha300AFormDialogOptions = { establishmentSize: 0 };

  public static openDialog(modalService: ModalService, callback?: (result: boolean) => void): WcCreateEstablishmentSizeDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 450;
    dialogOptions.height = 160;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.showCloseButton = false;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];

    let dialog: WcCreateEstablishmentSizeDialogComponent = modalService.globalAnchor
      .openDialog(WcCreateEstablishmentSizeDialogComponent, 'Change Establishment Size', dialogOptions, resolvedProviders, (result: boolean) => {
        callback(result);
      });
    dialog.size = dialog.size || dialog.initEstablishmentSize;
    return dialog;
  }

  constructor(private dialogOptions: DialogOptions, private modalService: ModalService) {
    this.modalService = modalService;
  }

  public create(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public updateValue(value) {
    this.size = { establishmentSize: value };
  }
}
