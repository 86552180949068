import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {
  BenefitEligibilityRuleStatementGroupItem,
  BenefitEligibilityRuleVariable,
  BenefitEligibilityRuleConditionType,
  BenefitEligibilityRuleConfigurationVariable,
  BenefitClass,
  BenefitEligibilityRuleConfigurationPredicate
} from '../../../models';
import * as _ from 'lodash';

@Component({
  selector: 'slx-benefit-details-eligibility-statements-clause-group',
  templateUrl: './benefit-details-eligibility-statements-clause-group.component.html',
  styleUrls: ['./benefit-details-eligibility-statements-clause-group.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class BenefitDetailsEligibilityStatementsClauseGroupComponent {
  @Input()
  ruleStatementGroupItem: BenefitEligibilityRuleStatementGroupItem;

  @Input()
  configurationVariables: BenefitEligibilityRuleConfigurationVariable[];

  @Input()
  configurationPredicates: BenefitEligibilityRuleConfigurationPredicate[];

  @Input()
  tiersGroupCount: number;

  @Input()
  currentGroupLevel: number;

  @Input()
  benefitClasses: BenefitClass[];

  @Input()
  isFirstItem: boolean;

  @Output()
  ruleStatementGroupItemChanged: EventEmitter<BenefitEligibilityRuleStatementGroupItem>;

  @Output()
  ruleStatementUngrouped: EventEmitter<BenefitEligibilityRuleStatementGroupItem>;

  private groupColors: string[];

  constructor() {
    this.ruleStatementGroupItemChanged = new EventEmitter();
    this.ruleStatementUngrouped = new EventEmitter();

    this.groupColors = ['#DCDCDC', '#DEF0AF', '#7DCAE9'];
  }

  onRuleVariableChanged(ruleVariable: BenefitEligibilityRuleVariable) {
    const newRuleGroupItem = new BenefitEligibilityRuleStatementGroupItem({
      ...this.ruleStatementGroupItem,
      variable: ruleVariable,
    });
    this.ruleStatementGroupItemChanged.next(newRuleGroupItem);
  }

  onConditionTypeChanged(conditionType: BenefitEligibilityRuleConditionType) {
    const newRuleGroupItem = new BenefitEligibilityRuleStatementGroupItem({
      ...this.ruleStatementGroupItem,
      conditionType: conditionType,
    });
    this.ruleStatementGroupItemChanged.next(newRuleGroupItem);
  }

  onRuleStatementGroupItemChanged(groupItem: BenefitEligibilityRuleStatementGroupItem) {
    if(groupItem) {
      if(this.ruleStatementGroupItem) {
        const newRuleStatementGroups = _.map(this.ruleStatementGroupItem.groups, (statement) => {
          return groupItem.id === statement.id ? groupItem : statement;
        });

        const newRuleGroupItem = new BenefitEligibilityRuleStatementGroupItem({
          ...this.ruleStatementGroupItem,
          groups: newRuleStatementGroups,
        });

        this.ruleStatementGroupItemChanged.next(newRuleGroupItem);
      }
    }
  }

  onRuleStatementUngrouped(groupItem: BenefitEligibilityRuleStatementGroupItem) {
    this.ruleStatementUngrouped.next(groupItem);
  }

  trackByItem(index: number, item: BenefitEligibilityRuleStatementGroupItem) {
    return item.id;
  }

  getGroupColor(level: number) {
    const index = level % this.groupColors.length;
    return this.groupColors[index];
  }
}
