import { Component } from '@angular/core';
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { BaseOrgDeptPosSyncComponent } from '../base-org-dept-pos-sync.component';
import { WFMOrgLevelChangeLogModel } from './../../../models/wfm-sync';

@Component({
  selector: 'slx-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.scss'],
})
export class PositionComponent extends BaseOrgDeptPosSyncComponent {
  constructor(
    protected wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(wfmSyncService, notificationService, busyService);
  }

  protected getSyncItemKey(item: WFMOrgLevelChangeLogModel): string {
    return '' + item.jobCode;
  }

  protected async fetchDataCore(): Promise<{ data: WFMOrgLevelChangeLogModel[] }> {
    return this.wfmSyncService.GetOrganizationLevelsChangeLogs('migration', 'position');
  }
}



