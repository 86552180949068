<div class="swap-flex-horizontal swap-list-item">
    <div class="type-text-container" [ngClass]="statusStyle">
        <div class="type-text"
            [ngClass]="statusStyle">{{shiftSwap.type}}</div>
    </div>
    <div class="profile-container profile-by">
        <slx-shift-swap-list-item-profile [profile]="shiftSwap.submittedBy"></slx-shift-swap-list-item-profile>
    </div>
    <div class="swap-flex-horizontal lines-container">
        <hr class="lines">
        <div [ngClass]="statusStyle + '-text'" class="icon-container">
            <i *ngIf="shiftSwap.hasSubmittedTo && !expired" class="fas fa-exchange-alt" aria-hidden="true"></i>
            <i *ngIf="!shiftSwap.hasSubmittedTo && !expired" class="fas fa-hourglass-start" aria-hidden="true"></i>
            <i *ngIf="expired" class="fas fa-clock" aria-hidden="true"></i>    
        </div>
        <hr class="lines">
    </div>
    <div class="profile-container profile-to">
        <slx-shift-swap-list-item-profile [colorStyle]="statusStyle + '-text'" [profile]="shiftSwap.submittedTo"></slx-shift-swap-list-item-profile>
    </div>
    <div class="swap-flex-horizontal buttons-container">
        <div class="mini-button swap-blue swap-flex-vertical" (click)="seeDetailsClicked()">
            <i class="icon far fa-eye" aria-hidden="true"></i>
            <div>View Details</div>
        </div>
        <div class="mini-button status-denied swap-flex-vertical" *ngIf="shiftSwap.canAction"
            [ngClass]="{'status-expired': manageService.isLoading }" (click)="denyClicked()">
            <i class="icon fal fa-times" aria-hidden="true"></i>
            <div>Deny</div>
        </div>
        <div class="mini-button status-approved swap-flex-vertical" *ngIf="shiftSwap.canAction"
            [ngClass]="{'status-expired': manageService.isLoading }" (click)="approveClicked()">
            <i class="icon fal fa-check" aria-hidden="true"></i>
            <div>Approve</div>
        </div>

        <div class="mid-button status-denied swap-flex-horizontal" *ngIf="showDenied">
            <i class="icon fal fa-times" aria-hidden="true"></i>
            <div>Denied</div>
        </div>
        <div class="mid-button status-approved swap-flex-horizontal" *ngIf="showApproved">
            <i class="icon fal fa-check" aria-hidden="true"></i>
            <div>Approved</div>
        </div>
        <div class="mid-button status-expired swap-flex-horizontal" *ngIf="expired">
            <i class="icon fas fa-clock" aria-hidden="true"></i>
            <div>Expired</div>
        </div>
    </div>
</div>