/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./favorites-list-configuration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@progress/kendo-angular-sortable/dist/es2015/index.ngfactory";
import * as i3 from "@progress/kendo-angular-l10n";
import * as i4 from "@progress/kendo-angular-sortable";
import * as i5 from "./favorites-list-configuration.component";
import * as i6 from "../../../services/favorites/favorites-management.service";
var styles_FavoritesListConfigurationComponent = [i0.styles];
var RenderType_FavoritesListConfigurationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FavoritesListConfigurationComponent, data: {} });
export { RenderType_FavoritesListConfigurationComponent as RenderType_FavoritesListConfigurationComponent };
function View_FavoritesListConfigurationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "fav-item"], ["id", "favitem"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "fav-name"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fav-icon-pointer far fa-bars"]], null, [[null, "mousedown"], [null, "touchend"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = ((_co.canDrag = true) !== false);
        ad = (pd_0 && ad);
    } if (("touchend" === en)) {
        var pd_1 = ((_co.canDrag = false) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = ((_co.canDrag = true) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fav-icon-pointer star-icon fas fa-star selected"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onStarClicked(_v.context.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = ("icon fas " + ((_v.context.item.menuItemMapping == null) ? null : _v.context.item.menuItemMapping.icon)); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.item.displayName; _ck(_v, 2, 0, currVal_1); var currVal_2 = _v.context.item.displayName; _ck(_v, 3, 0, currVal_2); }); }
export function View_FavoritesListConfigurationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "fav-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "fav-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Reorder"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "fav-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Show/Hide"])), (_l()(), i1.ɵeld(5, 0, null, null, 9, "span", [["class", "flex flex-direction__column groups__grid-positions"]], null, [[null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseup" === en)) {
        var pd_0 = (_co.scrollToPosition() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "kendo-sortable", [["emptyItemClass", "fav-name"], ["emptyText", ""], ["itemClass", "fav-item"], ["zone", "favorites"]], [[4, "touch-action", null], [1, "dir", 0]], [[null, "kendoSortableBindingChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("kendoSortableBindingChange" === en)) {
        var pd_0 = ((_co.favorites = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SortableComponent_0, i2.RenderType_SortableComponent)), i1.ɵprd(256, null, i3.L10N_PREFIX, "kendo.sortable", []), i1.ɵprd(131584, null, i3.LocalizationService, i3.LocalizationService, [i3.L10N_PREFIX, [2, i3.MessageService], [2, i3.RTL]]), i1.ɵdid(9, 9682944, null, 3, i4.SortableComponent, [i1.NgZone, i3.LocalizationService, i1.ChangeDetectorRef, i1.ElementRef, i4.SortableService], { animation: [0, "animation"], disabledIndexes: [1, "disabledIndexes"], zone: [2, "zone"], itemClass: [3, "itemClass"], emptyItemClass: [4, "emptyItemClass"], emptyText: [5, "emptyText"] }, null), i1.ɵqud(335544320, 1, { defaultTemplateRef: 0 }), i1.ɵqud(335544320, 2, { itemTemplateRef: 0 }), i1.ɵqud(335544320, 3, { placeholderTemplateRef: 0 }), i1.ɵdid(13, 212992, null, 0, i4.SortableBindingDirective, [i4.SortableComponent, i4.SortableService], { data: [0, "data"] }, null), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_FavoritesListConfigurationComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_2 = true; var currVal_3 = (_co.canDrag ? i1.ɵEMPTY_ARRAY : _co.disIndexes); var currVal_4 = "favorites"; var currVal_5 = "fav-item"; var currVal_6 = "fav-name"; var currVal_7 = ""; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.favorites; _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).touchAction; var currVal_1 = i1.ɵnov(_v, 9).dir; _ck(_v, 6, 0, currVal_0, currVal_1); }); }
export function View_FavoritesListConfigurationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-favorites-list-configuration", [], null, null, null, View_FavoritesListConfigurationComponent_0, RenderType_FavoritesListConfigurationComponent)), i1.ɵdid(1, 114688, null, 0, i5.FavoritesListConfigurationComponent, [i6.FavoriteManagementService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FavoritesListConfigurationComponentNgFactory = i1.ɵccf("slx-favorites-list-configuration", i5.FavoritesListConfigurationComponent, View_FavoritesListConfigurationComponent_Host_0, {}, {}, []);
export { FavoritesListConfigurationComponentNgFactory as FavoritesListConfigurationComponentNgFactory };
