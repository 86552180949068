<div class="slx-employee-banner banner-container">
  <div class="banner-text" *ngIf="employeeBannerInfo" [ngClass]="{'mobile-hide': hasEditingSection}">
    <span class="text-container name-item">{{employeeFullName}}</span>
    <span class="text-container status-item-desktop theme-header-text" *ngIf="employeeBannerInfo?.isTerminated || employeeBannerInfo?.isTransferred || employeeBannerInfo?.isFutureRehire">({{employeeBannerInfo?.status}})</span>
    <span class="text-container status-item-mobile theme-header-text" *ngIf="employeeBannerInfo?.isTerminated || employeeBannerInfo?.isTransferred || employeeBannerInfo?.isFutureRehire">({{employeeBannerInfo?.status | slxEmployeeStatus }})</span>
    <span class="text-container position-item">{{employeeBannerInfo?.position}}</span>
    <span class="text-container emp-type-item">{{employeeBannerInfo?.employeeType.description}}</span>
    <span class="text-container payroll-number-item">{{employeeBannerInfo?.payrollNumber}}</span>
  </div>

  <slx-single-message [isActive]="!canEditOwnProfile" level="warning">
    <div class="slx-single-message__message">You can not edit own profile.</div>
  </slx-single-message>

  <!-- <slx-single-message [isActive]="employeeBannerInfo?.bannerInfoLicense === 1 && !employeeBannerInfo?.isTerminated && !employeeBannerInfo?.isTransferred" level="warning">
    <div class="slx-single-message__message">Employee is restricted from punching due to expired/missing license!</div>
  </slx-single-message> -->

  <slx-actions-list *ngIf="canEditOwnProfile" [ngClass]="{'mobile-hide': hasEditingSection, 'disabled-action': employeeBannerInfo?.isTerminated && !employeeBannerInfo?.isLatestTerminate}" title="{{ (employeeBannerInfo?.isTerminated && !employeeBannerInfo?.isLatestTerminate)? 'It is not the latest terminated record for this employee' : ''}}">
    <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'"  *ngIf="actionsAvailable" [ngClass]="{'disabled-action-btn': employeeBannerInfo?.isTerminated && !employeeBannerInfo?.isLatestTerminate}">
      <span class="icon-button-content">
        <span>Actions</span> 
        <span class="caret"></span>
      </span>
    </slx-actions-button>
    <popper-content #popperContent>
      
      <slx-actions-list-item 
      *ngIf="employeeBannerInfo?.canTerminate && !employeeBannerInfo?.isTerminated && !employeeBannerInfo?.isTransferred" 
      [className]="'emp-banner-action-item'"
      (onClick)="terminate()">
      Terminate Employee</slx-actions-list-item>
      
      <slx-actions-list-item 
      *ngIf="employeeBannerInfo?.canTransfer && !employeeBannerInfo?.isTerminated && !employeeBannerInfo?.isTransferred && !employeeBannerInfo?.isTransferPending" 
      [className]="'emp-banner-action-item'"
      (onClick)="transfer()">
      Transfer Employee</slx-actions-list-item>

      <slx-actions-list-item 
        *ngIf="employeeBannerInfo?.isTransferred && !employeeBannerInfo?.isTerminated && !employeeBannerInfo?.isTransferPending" 
        [className]="'emp-banner-action-item disabled-action-item'"
        title="Undo transfer can only be completed on the employee's active record"
        >Transfer Employee</slx-actions-list-item>

        <slx-actions-list-item 
        *ngIf="employeeBannerInfo?.isTransferPending" 
        [className]="'emp-banner-action-item disabled-action-item'"
        title="There is already transfer pending"
        [disabled]="employeeBannerInfo?.isTransferPending"
        >Transfer Employee</slx-actions-list-item>
          
      <slx-actions-list-item 
      *ngIf="!employeeBannerInfo?.isTerminated && employeeBannerInfo?.isAgency" 
      [className]="'emp-banner-action-item'"
      (onClick)="resendEmail()">
      Resend Timeclock Email</slx-actions-list-item>
      <ng-container *ngIf="employeeBannerInfo?.isLatestTerminate">
        <slx-actions-list-item 
        *ngIf="employeeBannerInfo?.canRehire && employeeBannerInfo?.isTerminated"
        [className]="'emp-banner-action-item'" 
        (onClick)="rehire()">
        Rehire Employee</slx-actions-list-item>

        <slx-actions-list-item 
        *ngIf="(isNGPUser && isSystemUser) && employeeBannerInfo?.canCancelTerminate && employeeBannerInfo?.isTerminated"
        [className]="'emp-banner-action-item'" 
        (onClick)="cancelTerminate()">
        Cancel Termination</slx-actions-list-item>

        <slx-actions-list-item 
        *ngIf="!isNGPUser && employeeBannerInfo?.canCancelTerminate && employeeBannerInfo?.isTerminated"
        [className]="'emp-banner-action-item'" 
        (onClick)="cancelTerminate()">
        Cancel Termination</slx-actions-list-item>

        <slx-actions-list-item 
        *ngIf="(isNGPUser && isSystemUser) && employeeBannerInfo?.canTerminate && employeeBannerInfo?.isTerminated" 
        [className]="'emp-banner-action-item'"
        (onClick)="changeTerminateDate()">
        Change Termination Date</slx-actions-list-item>

        <slx-actions-list-item 
        *ngIf="!isNGPUser && employeeBannerInfo?.canTerminate && employeeBannerInfo?.isTerminated" 
        [className]="'emp-banner-action-item'"
        (onClick)="changeTerminateDate()">
        Change Termination Date</slx-actions-list-item>
      </ng-container>

      <slx-actions-list-item 
      *ngIf="canSeePayroll"
      [className]="'emp-banner-action-item'"
      (onClick)="activateNGPSSO()">
      Payroll</slx-actions-list-item>
    </popper-content>
  </slx-actions-list>
</div>
