import { Directive, Input, TemplateRef, Optional } from '@angular/core';
import * as _ from 'lodash';
import { ToolbarSectionTypes } from '../../models/index';

export type ToolbarAlignMode = 'left'|'right'|'rightOnlyDesktop'|'rightIfEnoughPlace'|'rightIfNothingCollapsed'|'leftWithPriorityIfNotEnoughPlace';
@Directive({
  selector: '[slxToolbarSectionTemplate]'
})
export class ToolbarSectionTemplateDirective {
  @Input()
  public collapsed: boolean;
  /**Please do not use this deprecated input, instead please use alignMode='right'*/
  //@Input()
  //public alignRight: boolean;
  @Input()
  public alignMode: ToolbarAlignMode = 'left';

  @Input()
  public set width(value: number) {
    if (_.isNumber(value)) {
      this.m_width = value;
    } else {
      this.m_width = _.toNumber(value);
    }
  }
  public get width(): number {
    return this.m_width;
  }

  public sectionType: ToolbarSectionTypes;

  private m_width: number = 100;
  constructor(@Optional() public templateRef: TemplateRef<any>) { }
}
