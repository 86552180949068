import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { DeviceDetectorService } from '../../../../common/services/index';
import { StateManagementService } from '../../../../common/services/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { IColumnSettings, StateResetTypes } from '../../../../core/models/index';

import { IAccrualsBalancesState, AccrualBalances, initialBalancesState } from '../../models/index';
import { AccrualsBalancesManagementService } from '../../services/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { ModalService, DialogOptions } from '../../../../common/index';
import { RecalculateDialogComponent } from '../recalculate-dialog/recalculate-dialog.component';
import { AccrualBalanceColumnsSettings } from '../../models/accruals-column-settings';

@Component({
  selector: 'slx-accruals-balances-toolbar',
  templateUrl: './accruals-balances-toolbar.component.html',
  styleUrls: ['./accruals-balances-toolbar.component.scss']
})
export class AccrualsBalancesToolbarComponent implements OnInit, OnDestroy {

  public effectiveDate: Date = null;
  public state: IAccrualsBalancesState;
  public hideRates: boolean = true;
 
  public columnState: AccrualBalanceColumnsSettings;
  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }

  public get effectiveDateWidth(): number {
    return this.isDesktop ? 325 : 246;
  }

  private readonly stateKey: string = 'AccrualsBalancesState';
  
  public calculateAccrualsAction: boolean = false;
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private modalService: ModalService,
    private accrualsManagementService: AccrualsBalancesManagementService,
    private stateManagement: StateManagementService,
    private devDetector: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,

  ) { }

  public ngOnInit(): void {
    
    this.setDefaultDate();
    this.subscribeToOrgLevel();
    this.subscribeTocalculateAccrualsAction();

    this.subscriptions.state = this.accrualsManagementService.subscribeToState((state: IAccrualsBalancesState) => {
      this.state = state;
    });

    this.subscriptions.loadedRecords = this.accrualsManagementService.subscribeToLoadedRecords((r: AccrualBalances) => {
      this.hideRates = r.hideRates;      
    });


    this.subscriptions.columnState = this.accrualsManagementService
    .subscribeToColumnState((s: AccrualBalanceColumnsSettings) => (this.columnState = s));

    this.subscriptions.restoreState = this.activatedRoute.data
      .combineLatest(this.stateManagement.onInit$)
      .map(values => values[0])
      .subscribe(routeData => {
        this.restoreState(routeData);
      });
  }

  private subscribeTocalculateAccrualsAction(): void {
    this.subscriptions.onLoaded = this.accrualsManagementService
      .subscribeTocalculateAccrualsAction((r: boolean) => {
        this.calculateAccrualsAction = r;
        
      });
  }

  private subscribeToOrgLevel(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
      });
  }

  public ngOnDestroy(): void { }

  public onChangeEffectiveDate(effectiveDate: Date): void {
    this.effectiveDate = effectiveDate;

    if (!this.isDesktop) {
      this.applyEffectiveDateChange();
    }
  }

  public applyEffectiveDateChange(): void {
    this.accrualsManagementService.changeEffectiveDate(this.effectiveDate);
  }

  public onClickExport(isPDF: boolean): void {
    this.accrualsManagementService.exportTo(isPDF);
  }

  public onShowActiveEmployee($event: boolean): void {
    this.state.isEmployeeActive = $event;
    this.accrualsManagementService.updateState(this.state);
    this.saveState();
  }

  public onShowNegativeBalance($event: boolean): void {
    this.state.isShowNegativeBalance = $event;
    this.accrualsManagementService.updateState(this.state);
    this.saveState();
  }

  public onToggleDollarValue($event: boolean): void {
    this.state.isDollarValueActive = $event;
    this.accrualsManagementService.updateState(this.state);
    this.saveState();
  }

  private async setDefaultDate(): Promise<void> {
    this.effectiveDate = await this.accrualsManagementService.getDefaultEffectiveDate();
  }

  private saveState(): void {
    this.stateManagement.setControlState(this.stateKey, {
      value: this.state
    }, StateResetTypes.None);
  }

  private restoreState(routeData: any): void {
    const routeState = routeData ? _.find(
      _.values(routeData),
      d => _.isObjectLike(d) && (d.isEmployeeActive || d.isShowNegativeBalance || d.isDollarValueActive)
    ) : null;

    let state: IAccrualsBalancesState = _.clone(initialBalancesState);
    const controlState = this.stateManagement.getControlState(this.stateKey);

    if (controlState && controlState.value) {
      state = controlState.value;
    }

    if (routeState) {
      if (_.isBoolean(routeState.isDollarValueActive)) {
        state.isDollarValueActive = routeState.isDollarValueActive;
      }

      if (_.isBoolean(routeState.isEmployeeActive)) {
        state.isEmployeeActive = routeState.isEmployeeActive;
      }

      if (_.isBoolean(routeState.isShowNegativeBalance)) {
        state.isShowNegativeBalance = routeState.isShowNegativeBalance;
      }
    }

    this.accrualsManagementService.updateState(state);
  }

  public onClickRecalcAccruals(): void {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 950;
    dialogOptions.height = 480;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.hideTitleBar = true;
    let dialog: RecalculateDialogComponent = this.modalService.globalAnchor.openDialog(
      RecalculateDialogComponent,
      'Confirmation',
      dialogOptions, undefined);
    dialog.orgLevelId = this.orgLevel.id;
    if (dialog.initialized) {
      dialog.onLoadRecalculateData();
    }
  }
  
  public onClickToggleSelection(isSelectAll: boolean): void {
    _.forEach(this.columnState.allColumns, (column: IColumnSettings) => {
      column.displayed = isSelectAll;
    });
    this.accrualsManagementService.setColumnState(this.columnState);
  }

  public onChangeColumn(): void {
    this.accrualsManagementService.setColumnState(this.columnState);
  }
}
