import * as tslib_1 from "tslib";
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework';
import { EmployeeSmsManagementApiService } from './employee-sms-management-api.service';
import { EmployeeSMSContacts, EmployeeSendSms } from '../../../app-modules/message-center/models';
import { debounceTime, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { from } from 'rxjs';
var EmployeeSmsManagementService = /** @class */ (function () {
    function EmployeeSmsManagementService(apiService) {
        this.apiService = apiService;
        this.loading$ = new Subject();
        this.sendSms$ = new Subject();
    }
    EmployeeSmsManagementService.prototype.subscribeToSendSms = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.sendSms$.subscribe(callback);
    };
    EmployeeSmsManagementService.prototype.sendSmsToEmployee = function (employeeSendSms) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.startProgress();
                return [2 /*return*/, this.apiService.sendSmsToEmployee(employeeSendSms).then(function (response) {
                        _this.stopProgress();
                    })];
            });
        });
    };
    EmployeeSmsManagementService.prototype.startProgress = function () {
        this.loading$.next(true);
    };
    EmployeeSmsManagementService.prototype.stopProgress = function () {
        this.loading$.next(false);
    };
    EmployeeSmsManagementService.prototype.searchEmployee = function (terms$) {
        var _this = this;
        return terms$.pipe(debounceTime(300), distinctUntilChanged(), switchMap(function (term) { return from(_this.apiService.getEmployeeSmsManagementList(term)); }));
    };
    return EmployeeSmsManagementService;
}());
export { EmployeeSmsManagementService };
