import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { AlertConfig } from '../../../../portal/models/index';
import { OnInit, EventEmitter } from '@angular/core';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { ApplicationDashboardManagementService } from '../../../services/index';
import { DashBoardAlertsModel } from '../../../../portal/models/index';
import { SessionService } from '../../../../core/services/index';
var DashboardAlertsSelectPopupComponent = /** @class */ (function () {
    function DashboardAlertsSelectPopupComponent(appDashboardMngtService, sessionService) {
        this.appDashboardMngtService = appDashboardMngtService;
        this.sessionService = sessionService;
        this.alertsToUpdate = [];
        this.subscriptions = {};
        this.onLoading = new EventEmitter();
        this.onHasChanges = new EventEmitter();
        this.alertsModel = this.activeAlerts = this.inActiveAlerts = [];
    }
    DashboardAlertsSelectPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.init();
        this.subscriptions.loading = this.appDashboardMngtService
            .subscribeToLoading(function (isLoading) {
            _this.onLoading.emit(isLoading);
        });
    };
    DashboardAlertsSelectPopupComponent.prototype.ngOnDestroy = function () {
        this.appDashboardMngtService.destroy();
    };
    DashboardAlertsSelectPopupComponent.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.appDashboardMngtService.getDashboardAppAlerts(this.modelData.appId)];
                    case 1:
                        _a.alertsModel = _b.sent();
                        this.configureAlerts();
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardAlertsSelectPopupComponent.prototype.configureAlerts = function () {
        this.activeAlerts = _.filter(this.alertsModel, function (t) { return t.visible; });
        this.inActiveAlerts = _.filter(this.alertsModel, function (t) { return !t.visible; });
    };
    DashboardAlertsSelectPopupComponent.prototype.onDragEnd = function (movedTo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, order;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = this.sessionService.getUser();
                        this.alertsToUpdate = [];
                        order = 1;
                        _.forEach(this.activeAlerts, function (item) {
                            item.displayOrder = order;
                            order++;
                            var model = new DashBoardAlertsModel();
                            model.appId = _this.modelData.appId;
                            model.visible = item.visible;
                            model.alertId = item.alertId;
                            model.alertName = item.alertName;
                            model.displayOrder = item.displayOrder;
                            model.userAppAlertId = item.userAppAlertId;
                            model.loginId = item.loginId;
                            model.userId = user.id;
                            model.lastModifiedDate = new Date();
                            _this.alertsToUpdate.push(model);
                        });
                        return [4 /*yield*/, this.appDashboardMngtService.modifyAlerts(this.alertsToUpdate)];
                    case 1:
                        _a.sent();
                        this.emitChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardAlertsSelectPopupComponent.prototype.emitChanges = function () {
        this.onHasChanges.emit(true);
    };
    DashboardAlertsSelectPopupComponent.prototype.showHideAlertItem = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, model;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = this.sessionService.getUser();
                        this.alertsToUpdate = [];
                        item.visible = !item.visible;
                        model = new DashBoardAlertsModel();
                        model.appId = this.modelData.appId;
                        model.visible = item.visible;
                        model.alertId = item.alertId;
                        model.alertName = item.alertName;
                        model.displayOrder = item.displayOrder;
                        model.userAppAlertId = item.userAppAlertId;
                        model.loginId = item.loginId;
                        model.userId = user.id;
                        model.lastModifiedDate = new Date();
                        this.alertsToUpdate.push(model);
                        this.configureAlerts();
                        return [4 /*yield*/, this.appDashboardMngtService.modifyAlerts(this.alertsToUpdate)];
                    case 1:
                        _a.sent();
                        this.emitChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], DashboardAlertsSelectPopupComponent.prototype, "subscriptions", void 0);
    return DashboardAlertsSelectPopupComponent;
}());
export { DashboardAlertsSelectPopupComponent };
