import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { IFilterData, IFilterRotationOptions, IScheduleRotationTemplate, ScheduleRotationTemplate } from "../../models/schedule-rotation/schedule-rotation-template.model";
import * as _ from "lodash";
import { Lookup } from "./../../../../app/organization";

export class ScheduleRotationFilterService {

    public filterDataSubscription: Subscription;
    public filterData$ = new Subject<any>();
    public actualShiftData: any;
    public actualEmployeeData: IScheduleRotationTemplate[];
    public filterShiftData: any;
    public filterEmployeeData: IScheduleRotationTemplate[];
    public isToolbarFilterApplied$ = new Subject<any>();
    public isGridFilterApplied$ = new Subject<any>();
    public positionLookup: Lookup;
    public positionGroupLookup: Lookup;
    public selectedPos: any[] = [];
    public selectedPosGroup: any[] = [];

    public filterChangeComplete$ = new Subject<any>();
    public viewWeeksDate: boolean = true;
    public employeeList$ = new BehaviorSubject<IScheduleRotationTemplate[]>([]);

    public defaultToolbarFilterOptions: any[] = [];
    public defaultGridFilterOptions: any[] = [];

    public isNewRotationAdded: boolean = false;
    public backToListTrigger$ = new Subject<boolean>();
    public assignedEmployeeAndEmptySlotEmployee: any[] = [];

    constructor() {
        this.filterDataSubscription = this.filterData$.subscribe((data: any) => {
            if (this.actualEmployeeData.some(x => x.employeeId == 0 && x.slotId == 0 && !x.isNewRotation)) {
                this.isNewRotationAdded = false;
                this.backToListTrigger$.next(true);
            }
            this.filterShiftData = this.actualShiftData;
            this.filterEmployeeData = this.actualEmployeeData;
            this.toolbarSectionFilter(data[0], data[1], data[3], data[2], data[4]);
            this.filterChangeComplete$.next(this.isNewRotationAdded);
        });
    }

    public toolbarSectionFilter(positionGroup, position, shiftGroup, unit, filterRequest) {
        if (positionGroup.length > 0) {
            if (this.filterShiftData && this.filterShiftData.length > 0) {
                this.filterShiftData = this.filterShiftData.filter(x => positionGroup.includes(x.positionGroupId));
            }
            if (this.filterEmployeeData && this.filterEmployeeData.length > 0) {
                this.filterEmployeeData = this.filterEmployeeData.filter(x => (positionGroup.includes(x.positionGroupId) || this.isPrimaryPositionGroupPresent(x) || this.isSecondaryPositionGroupPresent(positionGroup, x)));
            }
        }
        if (position.length > 0) {
            position = position.map(x => x.trim());
            if (this.filterShiftData && this.filterShiftData.length > 0) {
                this.filterShiftData = this.filterShiftData.filter(x => position.includes(x.positionName.trim()));
            }
            if (this.filterEmployeeData && this.filterEmployeeData.length > 0) {
                this.filterEmployeeData = this.filterEmployeeData.filter(x => position.includes(x.positionName.trim()) || this.isSecondaryPresent(position, x));
            }
        }
        if (unit.length > 0) {
            if (this.filterShiftData && this.filterShiftData.length > 0) {
                this.filterShiftData = this.filterShiftData.filter(x => unit.includes(x.unitId));
            }
        }
        if (shiftGroup.length > 0) {
            shiftGroup = shiftGroup.map(x => x.trim());
            if (this.filterShiftData && this.filterShiftData.length > 0) {
                this.filterShiftData = this.filterShiftData.filter(x => shiftGroup.includes(x.shiftGroupDescription));
            }
            if (this.filterEmployeeData && this.filterEmployeeData.length > 0) {
                this.filterEmployeeData = this.filterEmployeeData.filter(x => shiftGroup.includes(x.shiftGroupName));
            }
        }
        this.toolbarSectionSettingFilter(filterRequest.showStaff);
        this.employeeGridFilter(filterRequest.viewWeeks, filterRequest.positionOptions);
        this.gridShiftFilter(filterRequest.gridFilterOption, this.filterEmployeeData);
        return this.filterShiftData && this.filterEmployeeData;
    }

    public isSecondaryPresent(position, employee): boolean {
        return employee.employeeId > 0 && employee.onlySecondaryJobCodes.some(x => position.includes(x.jobName.trim()));
    }

    public isPrimaryPositionGroupPresent(employee): boolean {
        let selectedPosGroup = this.selectedPosGroup.map(x => x.id);
        let positions = this.positionLookup.items.filter(x => x.positionGroupId != null && selectedPosGroup.includes(x.positionGroupId));
        let positionNames = positions.map(x => x.name.trim());
        return positionNames.includes(employee.positionName.trim());
    }

    public isSecondaryPositionGroupPresent(positionGroup, employee): boolean {
        return employee.employeeId > 0 && employee.onlySecondaryJobCodes.some(x => positionGroup.includes(x.positionGroupId));
    }

    public toolbarSectionSettingFilter(showStaff) {
        let selectedFilter = _.filter(showStaff, (i) => i.isSelected);

        if (this.generateCombination(selectedFilter, '1234')) {
            let fullTime = this.filterEmployeeData.filter(x => x.isFullTime && x.employeeId != 0);
            let partTime = this.filterEmployeeData.filter(x => !x.isFullTime && x.employeeId != 0);
            let emptySlots = this.filterEmployeeData.filter(x => x.isEmptySlot && x.isFullTime == null);
            let res: IScheduleRotationTemplate[] = [];
            res = [...emptySlots, ...fullTime, ...partTime];
            this.filterEmployeeData = res;
        }

        if (this.generateCombination(selectedFilter, '1')) {
            this.filterEmployeeData = this.filterEmployeeData.filter(x => x.isFullTime && x.employeeId != 0 && x.hasRotations);
        }

        if (this.generateCombination(selectedFilter, '2')) {
            this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.isFullTime && x.employeeId != 0 && x.hasRotations);
        }

        if (this.generateCombination(selectedFilter, '3')) {
            this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.hasRotations && x.employeeId != 0);
        }

        if (this.generateCombination(selectedFilter, '4')) {
            this.filterEmployeeData = this.filterEmployeeData.filter(x => x.isEmptySlot && x.isFullTime == null);
        }

        if (this.generateCombination(selectedFilter, '12')) {
            let fullTime = this.filterEmployeeData.filter(x => x.isFullTime && x.employeeId != 0 && x.hasRotations);
            let partTime = this.filterEmployeeData.filter(x => !x.isFullTime && x.employeeId != 0 && x.hasRotations);
            let res: IScheduleRotationTemplate[] = [];
            res = [...fullTime, ...partTime];
            this.filterEmployeeData = res;
        }

        if (this.generateCombination(selectedFilter, '13')) {
            let fullTime = this.filterEmployeeData.filter(x => x.isFullTime && x.employeeId != 0);
            this.filterEmployeeData = fullTime;
        }

        if (this.generateCombination(selectedFilter, '14')) {
            let fullTime = this.filterEmployeeData.filter(x => x.isFullTime && x.employeeId != 0 && x.hasRotations);
            let emptySlot = this.filterEmployeeData.filter(x => x.isEmptySlot && x.isFullTime == null);
            let res: IScheduleRotationTemplate[] = [];
            res = [...emptySlot, ...fullTime];
            this.filterEmployeeData = res;
        }

        if (this.generateCombination(selectedFilter, '23')) {
            let partTime = this.filterEmployeeData.filter(x => !x.isFullTime && x.employeeId != 0);
            this.filterEmployeeData = partTime;
        }

        if (this.generateCombination(selectedFilter, '24')) {
            let partTime = this.filterEmployeeData.filter(x => !x.isFullTime && x.employeeId != 0 && x.hasRotations);
            let emptySlot = this.filterEmployeeData.filter(x => x.isEmptySlot && x.isFullTime == null);
            let res: IScheduleRotationTemplate[] = [];
            res = [...emptySlot, ...partTime];
            this.filterEmployeeData = res;
        }

        if (this.generateCombination(selectedFilter, '34')) {
            let fullTime = this.filterEmployeeData.filter(x => x.isFullTime && !x.hasRotations && x.employeeId != 0);
            let partTime = this.filterEmployeeData.filter(x => !x.isFullTime && !x.hasRotations && x.employeeId != 0);
            let emptySlot = this.filterEmployeeData.filter(x => x.isEmptySlot && x.isFullTime == null);
            let res: IScheduleRotationTemplate[] = [];
            res = [...emptySlot, ...fullTime, ...partTime];
            this.filterEmployeeData = res;
        }

        if (this.generateCombination(selectedFilter, '123')) {
            let fullTimePartTimeEER = this.filterEmployeeData.filter(x => (x.isFullTime || !x.isFullTime) && x.employeeId != 0);
            this.filterEmployeeData = fullTimePartTimeEER;
        }

        if (this.generateCombination(selectedFilter, '124')) {
            let fullTime = this.filterEmployeeData.filter(x => x.isFullTime && x.employeeId != 0 && x.hasRotations);
            let partTime = this.filterEmployeeData.filter(x => !x.isFullTime && x.employeeId != 0 && x.hasRotations);
            let emptySlots = this.filterEmployeeData.filter(x => x.isEmptySlot && x.isFullTime == null);
            let res: IScheduleRotationTemplate[] = [];
            res = [...emptySlots, ...fullTime, ...partTime];
            this.filterEmployeeData = res;
        }

        if (this.generateCombination(selectedFilter, '234')) {
            let partTime = this.filterEmployeeData.filter(x => !x.isFullTime && x.employeeId != 0);
            let emptySlots = this.filterEmployeeData.filter(x => x.isEmptySlot && x.isFullTime == null);
            let res: IScheduleRotationTemplate[] = [];
            res = [...emptySlots, ...partTime];
            this.filterEmployeeData = res;
        }

        if (this.generateCombination(selectedFilter, '134')) {
            let fullTime = this.filterEmployeeData.filter(x => x.isFullTime && x.employeeId != 0);
            let emptySlots = this.filterEmployeeData.filter(x => x.isEmptySlot && x.isFullTime == null);
            let res: IScheduleRotationTemplate[] = [];
            res = [...emptySlots, ...fullTime];
            this.filterEmployeeData = res;
        }
    }

    public employeeGridFilter(viewWeeks, positionOptions) {
        if (viewWeeks.length > 0) {
            _.forEach(viewWeeks, (i: IFilterData) => {

                if (i.isSelected) {
                    // { id: 11, name: 'Dates' }
                    this.viewWeeksDate = (i.id == 11) ? true : false;
                }
            })
        }

        if (positionOptions != null) {
            const selectedPositions = this.generatePositionOptionsCombinations(positionOptions);
            // const selectedSecPos = positionOptions['secondaryPosition'].options.filter(x => x.isSelected);

            if (this.generateCombination(selectedPositions, '201')) {
                if (this.selectedPos.length > 0 && this.selectedPosGroup.length == 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && selectedPosName.includes(x.positionName));
                }
                else if (this.selectedPosGroup.length > 0 && this.selectedPos.length == 0) {
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.isPrimaryPositionGroupPresent(x));
                }
                else if (this.selectedPos.length > 0 && this.selectedPosGroup.length > 0) {
                    let posGroupId = this.selectedPosGroup.map(x => x.id);
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && posGroupId.includes(x.positionGroupId) && selectedPosName.includes(x.positionName));
                }
                else {
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary);
                }
            }

            if (this.generateCombination(selectedPositions, '203')) {
                if (this.selectedPos.length > 0 && this.selectedPosGroup.length == 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.isSecondaryPresent(selectedPosName, x));
                    return this.filterEmployeeData;
                }
                else if (this.selectedPosGroup.length > 0 && this.selectedPos.length == 0) {
                    let selectedPosGroup = this.selectedPosGroup.map(x => x.id);
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.isSecondaryPositionGroupPresent(selectedPosGroup, x));
                }
                else if (this.selectedPos.length > 0 && this.selectedPosGroup.length > 0) {
                    let posGroupId = this.selectedPosGroup.map(x => x.id);
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodeOccurs(x, posGroupId) && this.isSecondaryPresent(selectedPosName, x));
                }
                else {
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodes(x));
                    return this.filterEmployeeData;
                }
            }

            if (this.generateCombination(selectedPositions, '204')) {
                if (this.selectedPos.length > 0 && this.selectedPosGroup.length == 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary && !x.isAgencyEmployee && this.isSecondaryPresent(selectedPosName, x));
                    return this.filterEmployeeData;
                }
                else if (this.selectedPosGroup.length > 0 && this.selectedPos.length == 0) {
                    let posGroupId = this.selectedPosGroup.map(x => x.id);
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary && !x.isAgencyEmployee && this.hasSecondaryJobCodeOccurs(x, posGroupId));
                }
                else if (this.selectedPos.length > 0 && this.selectedPosGroup.length > 0) {
                    let posGroupId = this.selectedPosGroup.map(x => x.id);
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary && !x.isAgencyEmployee && this.hasSecondaryJobCodeOccurs(x, posGroupId));
                }
                else {
                    this.filterEmployeeData = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary && !x.isAgencyEmployee);
                    return this.filterEmployeeData;
                }
            }

            if (this.generateCombination(selectedPositions, '205')) {
                this.filterEmployeeData = this.filterEmployeeData.filter(x => x.isAgencyEmployee);
            }

            if (this.generateCombination(selectedPositions, '201203')) {
                let showSecondaryPositionCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodes(x));
                let posGroupId = this.selectedPosGroup.map(x => x.id);
                let selectedPosName = this.selectedPos.map(x => x.name.trim());
                let posGroupShowSecondaryPositionCurrent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodeOccurs(x, posGroupId) && this.isSecondaryPresent(selectedPosName, x));
                // let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary);
                let res: IScheduleRotationTemplate[] = [];
                if (this.selectedPos.length > 0 && this.selectedPosGroup.length > 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    res = this.filterEmployeeData.filter(x => selectedPosName.includes(x.positionName));
                    this.filterEmployeeData = [...res, ...showSecondaryPositionCurrent];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
                else if (this.selectedPos.length > 0 && this.selectedPosGroup.length > 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    res = this.filterEmployeeData.filter(x => selectedPosName.includes(x.positionName));
                    this.filterEmployeeData = [...res, ...posGroupShowSecondaryPositionCurrent];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
                else {
                    res = this.filterEmployeeData.filter(x => x.isPrimary);
                    this.filterEmployeeData = [...res, ...showSecondaryPositionCurrent];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
            }

            if (this.generateCombination(selectedPositions, '201204')) {
                let res: IScheduleRotationTemplate[] = [];
                if (this.selectedPos.length > 0 && this.selectedPosGroup.length == 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    res = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && selectedPosName.includes(x.positionName));
                    let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && this.isSecondaryPresent(selectedPosName, x));
                    this.filterEmployeeData = [...res, ...showSecondaryPositionDifferent];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
                else if (this.selectedPosGroup.length > 0 && this.selectedPos.length == 0) {
                    let posGroupId = this.selectedPosGroup.map(x => x.id);
                    res = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.isPrimaryPositionGroupPresent(x));
                    let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && this.hasSecondaryJobCodeOccurs(x, posGroupId));
                    this.filterEmployeeData = [...res, ...showSecondaryPositionDifferent];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
                else if (this.selectedPos.length > 0 && this.selectedPosGroup.length > 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    let posGroupId = this.selectedPosGroup.map(x => x.id);
                    res = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && posGroupId.includes(x.positionGroupId) && selectedPosName.includes(x.positionName));
                    let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && this.hasSecondaryJobCodeOccurs(x, posGroupId));
                    this.filterEmployeeData = [...res, ...showSecondaryPositionDifferent];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
                else {
                    res = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary);
                    let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary);
                    this.filterEmployeeData = [...res, ...showSecondaryPositionDifferent];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
            }

            if (this.generateCombination(selectedPositions, '201205')) {
                let agency = this.filterEmployeeData.filter(x => x.isAgencyEmployee);
                let res: IScheduleRotationTemplate[] = [];
                if (this.selectedPos.length > 0 && this.selectedPosGroup.length == 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    res = this.filterEmployeeData.filter(x => selectedPosName.includes(x.positionName));
                    this.filterEmployeeData = [...res, ...agency];
                }
                else if (this.selectedPos.length > 0 && this.selectedPosGroup.length > 0) {
                    let posGroupId = this.selectedPosGroup.map(x => x.id);
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    res = this.filterEmployeeData.filter(x => posGroupId.includes(x.positionGroupId) && selectedPosName.includes(x.positionName));
                    this.filterEmployeeData = [...res, ...agency];
                }
                else {
                    res = this.filterEmployeeData.filter(x => x.isPrimary);
                    this.filterEmployeeData = [...res, ...agency];
                }
            }

            if (this.generateCombination(selectedPositions, '203204')) {
                let res: IScheduleRotationTemplate[] = [];
                if (this.selectedPos.length > 0 && this.selectedPosGroup.length == 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    let showSecondaryPositionCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.isSecondaryPresent(selectedPosName, x));
                    let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary && !x.isAgencyEmployee && this.isSecondaryPresent(selectedPosName, x));
                    res = [...showSecondaryPositionCurrent, ...showSecondaryPositionDifferent];
                    this.filterEmployeeData = res;
                }
                else if (this.selectedPosGroup.length > 0 && this.selectedPos.length == 0) {
                    let selectedPosGroup = this.selectedPosGroup.map(x => x.id);
                    let showSecondaryPositionCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.isSecondaryPositionGroupPresent(selectedPosGroup, x));
                    let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary && !x.isAgencyEmployee && this.hasSecondaryJobCodeOccurs(x, selectedPosGroup));
                    res = [...showSecondaryPositionCurrent, ...showSecondaryPositionDifferent];
                    this.filterEmployeeData = res;
                }
                else if (this.selectedPos.length > 0 && this.selectedPosGroup.length > 0) {
                    let posGroupId = this.selectedPosGroup.map(x => x.id);
                    let showSecondaryPositionCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodeOccurs(x, posGroupId));
                    let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary && !x.isAgencyEmployee && this.hasSecondaryJobCodeOccurs(x, posGroupId));;
                    res = [...showSecondaryPositionCurrent, ...showSecondaryPositionDifferent];
                    this.filterEmployeeData = res;
                }
                else {
                    let showSecondaryPositionCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodes(x));
                    let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary && !x.isAgencyEmployee);
                    res = [...showSecondaryPositionCurrent, ...showSecondaryPositionDifferent];
                    this.filterEmployeeData = res;
                }
            }

            if (this.generateCombination(selectedPositions, '203205')) {
                let agency = this.filterEmployeeData.filter(x => x.isAgencyEmployee);
                let showSecondaryPositionCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodes(x));
                let res: IScheduleRotationTemplate[] = [];
                res.push(...showSecondaryPositionCurrent, ...agency);
                let uniqueData = _.uniqBy(res, (e) => {
                    return e['id'];
                });
                this.filterEmployeeData = uniqueData;
            }

            if (this.generateCombination(selectedPositions, '204205')) {
                let agency = this.filterEmployeeData.filter(x => x.isAgencyEmployee);
                let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary);
                let res: IScheduleRotationTemplate[] = [];
                res.push(...showSecondaryPositionDifferent, ...agency);
                let uniqueData = _.uniqBy(res, (e) => {
                    return e['id'];
                });
                this.filterEmployeeData = uniqueData;
            }

            if (this.generateCombination(selectedPositions, '201203204')) {
                let showSecondaryPositionCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodes(x));
                let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary && !x.isAgencyEmployee);
                let res: IScheduleRotationTemplate[] = [];
                if (this.selectedPos.length > 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    res = this.filterEmployeeData.filter(x => selectedPosName.includes(x.positionName));
                    this.filterEmployeeData = [...res, ...showSecondaryPositionCurrent, ...showSecondaryPositionDifferent];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
                else {
                    res = this.filterEmployeeData.filter(x => x.isPrimary);
                    this.filterEmployeeData = [...res, ...showSecondaryPositionCurrent, ...showSecondaryPositionDifferent];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
            }

            if (this.generateCombination(selectedPositions, '201203205')) {
                let agency = this.filterEmployeeData.filter(x => x.isAgencyEmployee);
                let showSecondaryPositionCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodes(x));
                let res: IScheduleRotationTemplate[] = [];
                if (this.selectedPos.length > 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    res = this.filterEmployeeData.filter(x => selectedPosName.includes(x.positionName));
                    this.filterEmployeeData = [...res, ...showSecondaryPositionCurrent, ...agency];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
                else {
                    res = this.filterEmployeeData.filter(x => x.isPrimary);
                    this.filterEmployeeData = [...res, ...showSecondaryPositionCurrent, ...agency];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
            }

            if (this.generateCombination(selectedPositions, '201204205')) {
                let agency = this.filterEmployeeData.filter(x => x.isAgencyEmployee);
                let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary);
                let res: IScheduleRotationTemplate[] = [];
                if (this.selectedPos.length > 0) {
                    let selectedPosName = this.selectedPos.map(x => x.name.trim());
                    res = this.filterEmployeeData.filter(x => selectedPosName.includes(x.positionName));
                    this.filterEmployeeData = [...res, ...showSecondaryPositionDifferent, ...agency];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
                else {
                    res = this.filterEmployeeData.filter(x => x.isPrimary);
                    this.filterEmployeeData = [...res, ...showSecondaryPositionDifferent, ...agency];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
            }

            if (this.generateCombination(selectedPositions, '203204205')) {
                let agency = this.filterEmployeeData.filter(x => x.isAgencyEmployee);
                let showSecondaryPositionCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodes(x));
                let showSecondaryPositionDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary);
                this.filterEmployeeData = [...showSecondaryPositionCurrent, ...showSecondaryPositionDifferent, ...agency];
                let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                    return e['id'];
                });
                this.filterEmployeeData = uniqueData;
            }

            if (this.generateCombination(selectedPositions, '201203204205')) {
                if (this.selectedPos.length > 0) {
                    let posName = this.selectedPos.map(x => x.name.trim());
                    let primaryOnly = this.filterEmployeeData.filter(x => posName.includes(x.positionName));
                    let secondaryCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && this.isSecondaryPresent(posName, x));
                    let secondaryDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && this.isSecondaryPresent(posName, x));
                    let agencyEmployee = this.filterEmployeeData.filter(x => x.isAgencyEmployee);
                    this.filterEmployeeData = [...primaryOnly, ...secondaryCurrent, ...secondaryDifferent, ...agencyEmployee];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
                else {
                    let primaryOnly = this.filterEmployeeData.filter(x => x.isPrimary);
                    let secondaryCurrent = this.filterEmployeeData.filter(x => !x.isDifferentDepartment && x.isPrimary && this.hasSecondaryJobCodes(x));
                    let secondaryDifferent = this.filterEmployeeData.filter(x => x.isDifferentDepartment && !x.isPrimary);
                    let agencyEmployee = this.filterEmployeeData.filter(x => x.isAgencyEmployee);
                    this.filterEmployeeData = [...primaryOnly, ...secondaryCurrent, ...secondaryDifferent, ...agencyEmployee];
                    let uniqueData = _.uniqBy(this.filterEmployeeData, (e) => {
                        return e['id'];
                    });
                    this.filterEmployeeData = uniqueData;
                }
            }
        }
    }

    public gridShiftFilter(gridFilterOption: IFilterData, filteredEmpData: IScheduleRotationTemplate[]): IScheduleRotationTemplate[] {
        if (gridFilterOption.isSelected) {
            let data: IScheduleRotationTemplate[] = _.forEach(filteredEmpData, (item) => {
                if ((this.selectedPos.length > 0 && this.selectedPosGroup.length == 0)) {
                    let selectedPosNames = this.selectedPos.map(x => x.name.trim());
                    item.scheduleDays.map(a => a.shifts.map(b => {
                        b.isJobCodeHidden = (!selectedPosNames.includes(b.positionName.trim())) ? true : false;
                    }));
                }
                else if ((this.selectedPosGroup.length > 0 && this.selectedPos.length == 0)) {
                    let selectedPosGroup = this.selectedPosGroup.map(x => x.id);
                    let positions = this.positionLookup.items.filter(x => x.positionGroupId != null && selectedPosGroup.includes(x.positionGroupId));
                    let positionNames = positions.map(x => x.name.trim());
                    item.scheduleDays.map(a => a.shifts.map(b => {
                        b.isJobCodeHidden = (!positionNames.includes(b.positionName.trim())) ? true : false;
                    }));
                }
                else if ((this.selectedPosGroup.length > 0
                    && this.selectedPos.length > 0)) {
                    let selectedPosGroup = this.selectedPosGroup.map(x => x.id);
                    let positions = this.selectedPos.filter(x => x.positionGroupId != null && selectedPosGroup.includes(x.positionGroupId));
                    let positionNames = positions.map(x => x.name.trim());
                    item.scheduleDays.map(a => a.shifts.map(b => {
                        b.isJobCodeHidden = (!positionNames.includes(b.positionName.trim())) ? true : false;
                    }));
                }
                else if (this.selectedPos.length == 0 && this.selectedPosGroup.length == 0) {
                    item.scheduleDays.map(a => a.shifts.map(b => {
                        b.isJobCodeHidden = false;
                    }));
                }
            });
            return data;
        }
        else {
            filteredEmpData.forEach(x => x.scheduleDays.map(y => y.shifts.map(z => z.isJobCodeHidden = false)));
            return filteredEmpData;
        }
    }

    public hasSecondaryJobCodes(item: any): boolean {
        let positionLookupNames = this.positionLookup.items.map(x => x.name.trim());
        return item.employeeId > 0 && item.onlySecondaryJobCodes.some(x => positionLookupNames.includes(x.jobName.trim()));
    }

    public hasSecondaryPosGroup(item: any): boolean {
        let positionGroupId = this.positionGroupLookup.items.map(x => x.id);
        return item.employeeId > 0 && item.onlySecondaryJobCodes.some(x => positionGroupId.includes(x.positionGroupId));
    }

    generateCombination(options, combinationToCheck): boolean {
        const selectedOptions = options.filter(option => option.isSelected);
        const selectedIds = selectedOptions.map(option => option.id).join('');
        return selectedIds === combinationToCheck;
    }

    generatePositionOptionsCombinations(positionOptions): any[] {
        const selectedOptions = [];
        for (const key in positionOptions) {
            if (positionOptions[key].isSelected) {
                selectedOptions.push({
                    id: positionOptions[key].id,
                    name: key,
                    isSelected: positionOptions[key].isSelected
                });
            }

            if (positionOptions[key].options) {
                for (const option of positionOptions[key].options) {
                    if (option.isSelected) {
                        selectedOptions.push({
                            id: option.id,
                            name: option.name,
                            isSelected: option.isSelected
                        });
                    }
                }
            }
        }
        return selectedOptions;
    }

    getSelectedShowStaffOptions(showStaff): any[] {
        const selectedOptions = showStaff.filter(x => x.isSelected);
        return selectedOptions;
    }

    hasSecondaryJobCodeOccurs(employee, posGroup) {
        return employee.employeeId > 0 && employee.onlySecondaryJobCodes.some(x => posGroup.includes(x.positionGroupId));
    }

}