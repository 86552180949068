import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { appConfig } from '../../../../app.config';
import { ConfirmDialogComponent } from '../../../../common/components/confirm-dialog/confirm-dialog.component';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { unsubscribe } from '../../../../core/decorators/index';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
import { BudgetManagementService } from '../../../services/index';
import { Budget, BudgetRecord, AddRecordReq, BudgetHeaderModes } from '../../../models/index';
import { Subscription } from 'rxjs/Subscription';
import { BudgetDefinition, BudgetedPosition } from '../../../../organization/models/index';
import { BudgetSelectorDropdownComponent } from '../../../../organization/components/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';
var BudgetHeaderComponent = /** @class */ (function () {
    function BudgetHeaderComponent(budgetManagementService, modalService, orgLevelService) {
        this.budgetManagementService = budgetManagementService;
        this.modalService = modalService;
        this.orgLevelService = orgLevelService;
        this.orglevelid = undefined;
        this.state = {
            mode: BudgetHeaderModes.None,
            isRecordEditMode: false,
            isGroupEditMode: false
        };
    }
    BudgetHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.orgLevelSubscription = this.budgetManagementService.onOrgLevelChanged$
            .subscribe(function (orgLevelId) {
            if (_this.budgetSelector) {
                var o = _this.orgLevelService.getOrgLevelById(orgLevelId);
                if (o && o.id != _this.orglevelid) {
                    _this.budgetSelector.setOrglevel(o);
                    _this.orglevelid = o.id;
                }
                else {
                    _this.orglevelsLoadedSubscription = _this.orgLevelService.orgLevelTreeLoaded$.subscribe(function () {
                        var o = _this.orgLevelService.getOrgLevelById(orgLevelId);
                        if (o && o.id != _this.orglevelid) {
                            _this.budgetSelector.setOrglevel(o);
                            _this.orglevelid = o.id;
                        }
                    });
                }
            }
        });
        this.dummyLoadedSubscription = this.budgetManagementService.onDummyLoaded$.subscribe(function (budget) {
            if (_this.budgetManagementService.editMode)
                return;
            if (_this.budgetSelector && _this.budgetSelector.budgetDefinitions) {
                var definitions = _this.budgetSelector.budgetDefinitions.slice(0);
                var latestDefinition = void 0;
                if (definitions.length > 0) {
                    latestDefinition = _.first(definitions);
                    var endMoment = moment(latestDefinition.endDate);
                    if (dateTimeUtils.isBeforeMaxSQLDate(latestDefinition.endDate)) {
                        budget.startDate = endMoment.add(1, 'days').toDate();
                    }
                    else {
                        budget.startDate = moment(latestDefinition.startDate).add(1, 'months').toDate();
                    }
                }
            }
            _this.state.mode = BudgetHeaderModes.AddBudget;
            _this.budgetManagementService.priorBudgetDefinition = _this.budgetManagementService.currentBudgetDefinition;
            _this.budget = budget;
            _this.budgetManagementService.onBudgetSelect(_this.budget);
        });
        this.editModeSubscription = this.budgetManagementService.onEditBudgetMode$.subscribe(function (editMode) {
            if (_this.budgetManagementService.addMode)
                return;
            _this.state.mode = editMode ? BudgetHeaderModes.EditBudget : BudgetHeaderModes.None;
        });
        this.loadedSubscription = this.budgetManagementService.onLoaded$.subscribe(function (budget) {
            if (_this.budgetManagementService.addMode)
                return;
            _this.budget = budget;
            if (_this.budgetManagementService.editMode)
                return;
            _this.state.mode = BudgetHeaderModes.None;
        });
        this.addedSubscription = this.budgetManagementService.onAdded$.subscribe(function (budget) {
            _this.budget = budget;
            _this.budgetManagementService.currentBudgetDefinition = new BudgetDefinition();
            _this.budgetManagementService.currentBudgetDefinition.id = budget.id;
            _this.budgetManagementService.currentBudgetDefinition.orgLevelId = _this.budgetManagementService.selectedOrganizationOrgLevelId;
            _this.budgetSelector.loadBudgetsDefinitions(_this.budgetManagementService.selectedOrganizationOrgLevelId, true, false);
        });
        this.deletedSubscription = this.budgetManagementService.onDeleted$.subscribe(function (budgetId) {
            _this.budgetSelector.SelectBudgetDefinition = undefined;
            _this.budgetManagementService.currentBudgetDefinition = undefined;
            _this.budgetSelector.loadBudgetsDefinitions(_this.budgetManagementService.selectedOrganizationOrgLevelId, true, true);
        });
        this.restoredSubscription = this.budgetManagementService.onRestoreBudget$.subscribe(function (result) {
            if (!result) {
                _this.budgetSelector.budgetDefinitions = [];
                _this.budgetSelector.loadBudgetsDefinitions(_this.budgetManagementService.selectedOrganizationOrgLevelId, true);
            }
        });
        this.budgetSelectSubscription = this.budgetManagementService.onBudgetSelect$.subscribe(function (req) {
            if (_this.budgetManagementService.addMode)
                return;
            if (_this.budgetManagementService.editMode)
                return;
            if (req) {
                _this.budget = req;
                var def = new BudgetDefinition();
                def.id = req.id;
                def.orgLevelId = req.orgLevelId;
                def.startDate = req.startDate;
                def.endDate = req.endDate;
                _this.budgetManagementService.currentBudgetDefinition = def;
                _this.budgetSelector.SelectBudgetDefinition(def, false);
            }
        });
        this.editRecordSubscription = this.budgetManagementService.onEditRecord$.subscribe(function (inRecordEdit) {
            _this.state.isRecordEditMode = inRecordEdit;
        });
        this.addedRecordSubscription = this.budgetManagementService.onPositionAdded$.subscribe(function (record) {
            _this.state.isRecordEditMode = false;
        });
        this.groupEditSubscription = this.budgetManagementService.onGroupEdit$
            .subscribe(function (editMode) {
            _this.state.isGroupEditMode = editMode;
            if (!editMode) {
                _this.budget = _this.budgetManagementService.currentBudget;
            }
        });
        this.overlapSubscription = this.budgetManagementService.overlapWarning$.subscribe(function (overlap) {
            ConfirmDialogComponent.openDialog('Warning', 'The dates you have entered overlap with an existing budget. If you want to keep these dates the existing budget will be modified to correct the overlap. Do you want to proceed with these dates?', _this.modalService, function (result) {
                if (result) {
                    if (overlap.isNew) {
                        _this.budgetManagementService.onAddRequest(overlap.budget, null, true);
                    }
                    else {
                        _this.budgetManagementService.onSaveRequest(overlap.budget, null, true);
                    }
                }
            });
        });
    };
    BudgetHeaderComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    BudgetHeaderComponent.prototype.budgetDefinitionSelected = function (item) {
        if (this.budgetManagementService.addMode)
            return;
        this.budgetManagementService.currentBudgetDefinition = item;
        if (this.budgetManagementService.editMode)
            return;
        this.budgetManagementService.onLoadRequest(this.budgetManagementService.currentBudgetDefinition);
    };
    BudgetHeaderComponent.prototype.budgetDefinitionAdd = function (item) {
        /*
        this.state.mode = 'AddBudget';
        this.priorBudgetDefinition = this.currentBudgetDefinition;
        this.budget = new Budget();
        this.budget.census = new BudgetCensus();
        this.budget.orgLevelId = this.budgetManagementService.selectedOrganizationOrgLevelId;
        this.budget.records = [];
        this.budgetManagementService.onBudgetSelect(this.budget);
        */
        this.budgetManagementService.onAddBudgetMode(true);
        this.budgetManagementService.getDummy();
    };
    BudgetHeaderComponent.prototype.onEditDiscard = function () {
        this.state.mode = BudgetHeaderModes.None;
        this.budgetManagementService.onEditBudgetMode(false);
        if (this.budgetManagementService.currentBudgetDefinition) {
            this.budgetManagementService.onLoadRequest(this.budgetManagementService.currentBudgetDefinition);
        }
    };
    BudgetHeaderComponent.prototype.onEditSave = function () {
        this.budget.orgLevelId = this.budgetManagementService.selectedOrganizationOrgLevelId;
        this.budgetManagementService.onEditBudgetMode(false);
        this.budgetManagementService.onSaveRequest(this.budget, null, true);
    };
    BudgetHeaderComponent.prototype.onAddDiscard = function () {
        this.state.mode = BudgetHeaderModes.None;
        this.budgetManagementService.onEditBudgetMode(false);
        this.budgetManagementService.onAddBudgetMode(false);
        this.budgetManagementService.currentBudgetDefinition = this.budgetManagementService.priorBudgetDefinition;
        if (this.budgetManagementService.currentBudgetDefinition) {
            this.budgetManagementService.onLoadRequest(this.budgetManagementService.currentBudgetDefinition);
        }
    };
    BudgetHeaderComponent.prototype.onAddSave = function () {
        var definitions;
        if (this.budgetSelector && this.budgetSelector.budgetDefinitions) {
            definitions = this.budgetSelector.budgetDefinitions.slice(0);
        }
        if (definitions) {
            var overlaps = this.budgetManagementService.checkOverlapping(this.budget, definitions);
            if (!overlaps) {
                this.state.mode = BudgetHeaderModes.None;
                this.budgetManagementService.onEditBudgetMode(false);
                this.budgetManagementService.onAddBudgetMode(false);
                this.budgetManagementService.onAddRequest(this.budget, definitions, true);
            }
            else {
                this.budgetManagementService.sendOverlapWaning(this.budget, true);
            }
        }
        else {
            this.budgetManagementService.onEditBudgetMode(false);
            this.budgetManagementService.onAddBudgetMode(false);
            this.budgetManagementService.onAddRequest(this.budget, null, true);
        }
    };
    BudgetHeaderComponent.prototype.onRestore = function () {
        this.budgetManagementService.onRestoreBudget(true);
    };
    BudgetHeaderComponent.prototype.onDelete = function () {
        var _this = this;
        ConfirmDialogComponent.openDialog('Warning', 'You are about to delete this budget. Once executed, this action cannot be un-done. Are you sure you want to delete?', this.modalService, function (result) {
            if (result) {
                _this.budgetManagementService.onDeleteRequest(_this.budget.id);
            }
        });
    };
    BudgetHeaderComponent.prototype.onEdit = function () {
        this.budgetManagementService.onEditBudgetMode(true);
    };
    BudgetHeaderComponent.prototype.editCensus = function () {
        this.budgetManagementService.onBudgetCensusAdjust(true);
    };
    BudgetHeaderComponent.prototype.onManageGroups = function () {
        this.state.isGroupEditMode = true;
        this.budgetManagementService.onGroupEdit(true);
    };
    BudgetHeaderComponent.prototype.onEditGroupsDiscard = function () {
        this.state.isGroupEditMode = false;
        this.budgetManagementService.onGroupEdit(false);
    };
    BudgetHeaderComponent.prototype.onEditGroupsSave = function () {
        this.state.isGroupEditMode = false;
        this.budgetManagementService.onGroupEdit(false);
    };
    BudgetHeaderComponent.prototype.onAddPosition = function () {
        this.state.isRecordEditMode = true;
        var req = new AddRecordReq();
        req.record = new BudgetRecord();
        req.record.budgetedPosition = new BudgetedPosition();
        req.record.censusAdjust = false;
        var maxId = 0;
        _.each(this.budget.records, function (rec) {
            maxId = Math.max(rec.uniqId, maxId);
        });
        req.record.uniqId = maxId + 1;
        this.budgetManagementService.onAddPosition(req);
    };
    BudgetHeaderComponent.prototype.removeEndDate = function () {
        this.budget.endDate = null;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "dummyLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "addedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "deletedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "restoredSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "groupEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "addedRecordSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "budgetSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "editRecordSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "overlapSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "editModeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHeaderComponent.prototype, "orglevelsLoadedSubscription", void 0);
    return BudgetHeaderComponent;
}());
export { BudgetHeaderComponent };
