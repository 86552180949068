export * from './daily-timecard.component';
export * from './daily-timecard-employee-info/daily-timecard-employee-info.component';
export * from './daily-timecard-actions-bar/daily-timecard-actions-bar.component';
export * from './punches-management/index';
export * from './shifts-list/index';
export * from './timecard-absences-list/timecard-absences-list.component';
export * from './timecard-absences-editor/timecard-absences-editor.component';
export * from './timecard-absences-editor-dialog/timecard-absences-editor-dialog.component';
export * from './pay-codes-list/index';
export * from './summary/index';
export * from './comments/comment.component';
export * from './daily-timecard-section/daily-timecard-section.component';

import { DailyTimeCardComponent } from './daily-timecard.component';
import { DailyTimeCardEmployeeInfoComponent } from './daily-timecard-employee-info/daily-timecard-employee-info.component';
import { DailyTimeCardActionsBarComponent } from './daily-timecard-actions-bar/daily-timecard-actions-bar.component';

import { punchesComponents, exportPunchesComponents } from './punches-management/index';
import { shitsListComponents, exportShiftsListComponents } from './shifts-list/index';
import { TimeCardAbsencesListComponent } from './timecard-absences-list/timecard-absences-list.component';
import { TimeCardAbsencesEditorComponent } from './timecard-absences-editor/timecard-absences-editor.component';
import { TimecardAbsencesEditorDialogComponent } from './timecard-absences-editor-dialog/timecard-absences-editor-dialog.component';
import { paycodesListComponents, exportPaycodesListComponents } from './pay-codes-list/index';

import { SummaryListComponent } from './summary/index';

import { CommentComponent } from './comments/comment.component';
import { DailyTimecardSectionComponent } from './daily-timecard-section/daily-timecard-section.component';
import { PayCodesAdditionalDataComponent } from './pay-codes-additional-data/pay-codes-additional-data.component';

export const timeCardComponents: any = [
    TimeCardAbsencesListComponent, ...shitsListComponents, ...punchesComponents, ...paycodesListComponents,
    DailyTimeCardComponent, DailyTimeCardEmployeeInfoComponent, DailyTimeCardActionsBarComponent,
    SummaryListComponent, CommentComponent, DailyTimecardSectionComponent, TimeCardAbsencesEditorComponent, TimecardAbsencesEditorDialogComponent,
    PayCodesAdditionalDataComponent
];

export const exportTimeCardComponents: any = [
    DailyTimeCardComponent, ...exportShiftsListComponents, ...exportPunchesComponents, ...exportPaycodesListComponents,
    SummaryListComponent,
    PayCodesAdditionalDataComponent
];
