var PbjOrganization = /** @class */ (function () {
    function PbjOrganization() {
    }
    Object.defineProperty(PbjOrganization.prototype, "id", {
        get: function () {
            return this.organizationId;
        },
        enumerable: true,
        configurable: true
    });
    return PbjOrganization;
}());
export { PbjOrganization };
