import * as _ from 'lodash';
import { InjectionToken } from '@angular/core';

export class BenefitsEmployeeDependentsEnrollmentValidity {
  public latestDepDOB: Date = null;
  private error = new Set<string>();

  constructor(
    public startDate: Date = null,
    public endDate: Date = null,
    public maxDate: Date = null,
    public minDate: Date = null,
  ) {}

  public get hasError(): boolean {
    return this.error.size > 0;
  }

  public setErrorInvalidBenefits(isAdd: boolean): void {
    if (isAdd) {
      this.error.add('invalidBenefits');
    } else {
      this.error.delete('invalidBenefits');
    }
  }

  public isErrorInvalidBenefits(): boolean {
    return this.error.has('invalidBenefits');
  }

  public setErrorInvalidDependents(isAdd: boolean): void {
    if (isAdd) {
      this.error.add('invalidDependents');
    } else {
      this.error.delete('invalidDependents');
    }
  }

  public isErrorInvalidDependents(): boolean {
    return this.error.has('invalidDependents');
  }

  public setErrorNoBenefits(isAdd: boolean): void {
    if (isAdd) {
      this.error.add('noBenefits');
    } else {
      this.error.delete('noBenefits');
    }
  }

  public isErrorNoBenefits(): boolean {
    return this.error.has('noBenefits');
  }

  public setErrorNoDependents(isAdd: boolean): void {
    if (isAdd) {
      this.error.add('noDependents');
    } else {
      this.error.delete('noDependents');
    }
  }

  public isErrorNoDependents(): boolean {
    return this.error.has('noDependents');
  }

  public setErrorInvalidStartOrEndDate(isAdd: boolean): void {
    if (isAdd) {
      this.error.add('invalidStartOrEndDate');
    } else {
      this.error.delete('invalidStartOrEndDate');
    }
  }

  public isErrorInvalidStartOrEndDate(): boolean {
    return this.error.has('invalidStartOrEndDate');
  }
}

export interface IBenefitsEmployeeDependentsEnrollment<T, M, S> {
  getDependentsEligibleBenefits(dependents: Array<number>): Promise<Array<T>>
  enrollDependents(a: M): Promise<void>
  validateSelection(enrollments: Array<[Date, Date]>, dependents: Array<Date>): S
  validateDates(sDate?: Date, eDate?: Date): S
}

export const BENEFITS_EMPLOYEE_DEPENDENTS_ENROLLMENT = new InjectionToken<IBenefitsEmployeeDependentsEnrollment<any, any, any>>('BENEFITS_EMPLOYEE_DEPENDENTS_ENROLLMENT');
