import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { StaffingPredictorOrganizationInfo } from '../../models/index';
import { appConfig } from '../../../../app.config';
var PredictorMatrixComponent = /** @class */ (function () {
    function PredictorMatrixComponent() {
        this.maxRatingArray = 5;
    }
    Object.defineProperty(PredictorMatrixComponent.prototype, "staffingPointsTables", {
        get: function () {
            return this.m_staffingPointsTables;
        },
        set: function (value) {
            this.m_staffingPointsTables = value;
            if (this.m_staffingPointsTables !== null) {
                this.currentTable = _.first(this.m_staffingPointsTables);
            }
        },
        enumerable: true,
        configurable: true
    });
    PredictorMatrixComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    PredictorMatrixComponent.prototype.isInRnRange = function (value, row) {
        return value >= row.lowLimitRn && (value <= row.highLimitRn || row.highLimitRn === 0);
    };
    PredictorMatrixComponent.prototype.isInTotalRange = function (value, cell) {
        return value >= cell.lowLimitTotal && (value <= cell.highLimitTotal || cell.highLimitTotal === 0);
    };
    return PredictorMatrixComponent;
}());
export { PredictorMatrixComponent };
