import { Organization } from '../../../../organization/models/index';

export interface IPBJExportLogStaffingHours {
  exportId: number;
  pbjId: string;
  employeeMasterId: number;
  employeeId: number;
  organizationId: number;
  organizationName: string;
  employeeName: string;
  payrollNumber: string;
  workDate: string;
  workHours: number;
  cmsJobCode: number;
  cmsJobNameTitle: string;
  exemptType: string;
}

export class PBJExportLogStaffingHours {
  public exportId: number;
  public pbjId: string;
  public employeeMasterId: number;
  public employeeId: number;
  public organization: Organization;
  public organizationName: string;
  public employeeName: string;
  public payrollNumber: string;
  public workDate: Date;
  public workHours: number;
  public cmsJobCode: number;
  public cmsJobNameTitle: string;
  public exemptType: string;
}

