import * as _ from 'lodash';
import { OnDestroy, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { AddEmployeeWizardActions } from '../../../../store/index';
import { AddEmployeeModel } from '../../../../models/index';
import { NgForm } from '@angular/forms';

export abstract class AddEmployeeBasicComponent implements OnDestroy {
  @Output()
  public onFormChanged: EventEmitter<any>;
  public addEmployeeModel: AddEmployeeModel;
  public ignoreValidation: boolean;
  protected addEmployeeWizardActions: AddEmployeeWizardActions;
  protected addEmployeeWizardSubscription: Subscription;
  protected formChangesSubscription: Subscription;
  private m_mainForm: NgForm;

  constructor(addEmployeeWizardActions: AddEmployeeWizardActions) {
    this.addEmployeeWizardActions = addEmployeeWizardActions;
    this.ignoreValidation = false;

    this.onFormChanged = new EventEmitter();
  }

  public ngOnDestroy(): void {
    if (this.addEmployeeWizardSubscription) {
      this.addEmployeeWizardSubscription.unsubscribe();
    }

    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  public hasAssignedOrgLevel(): boolean {
    const orgId: number = _.get(this.addEmployeeModel, 'organization.id');
    const depId: number = _.get(this.addEmployeeModel, 'department.id');
    const posId: number = _.get(this.addEmployeeModel, 'position.id');

    return _.isNumber(orgId) && _.isNumber(depId) && _.isNumber(posId);
  }

  public nextStep(): void {
    this.addEmployeeWizardActions.nextStep(this.addEmployeeModel);
  }

  public prevStep(): void {
    this.addEmployeeWizardActions.prevStep(this.addEmployeeModel);
  }

  public finishStep(): void {
    this.addEmployeeWizardActions.finishWizard(this.addEmployeeModel);
  }

  protected onWizard(model: AddEmployeeModel): void {
    this.addEmployeeModel = model;
  }

  protected addChangesSubscriptionToForm(mainForm: NgForm): void {
    this.m_mainForm = mainForm;
    this.formChangesSubscription = mainForm.control.valueChanges.subscribe(x => {
      if (this.m_mainForm.dirty) {
        this.onFormChanged.emit();
      }
    });
  }
}
