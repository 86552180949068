export class IGenericLinkDefinition {
  public hyperlinkType: GenericLinkType;
  public fieldNames: string[];
}

export class GenericLinkDefinition {
  public linkType: GenericLinkType;
  public fieldNames: string[];
}

export class GenericLinkType {
  public static employee: GenericLinkTypeDefinition = 'employee';
  public static scheduleDetail: GenericLinkTypeDefinition = 'scheduleDetail';
}

export type GenericLinkTypeDefinition = 'employee' | 'scheduleDetail';
