import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter, ElementRef } from '@angular/core';
export var FiltersDescriptiors;
(function (FiltersDescriptiors) {
    FiltersDescriptiors["eq"] = "Is equal to";
    FiltersDescriptiors["neq"] = "Is not equal to";
    FiltersDescriptiors["contains"] = "Contains";
    FiltersDescriptiors["doesnotcontain"] = "Does not contain";
    FiltersDescriptiors["startswith"] = "Starts with";
    FiltersDescriptiors["endswith"] = "Ends with";
    FiltersDescriptiors["isnull"] = "Is null";
    FiltersDescriptiors["isnotnull"] = "Is not null";
    FiltersDescriptiors["isempty"] = "Is empty";
    FiltersDescriptiors["isnotempty"] = "Is not empty";
    // LT  = 'Less than',
    // LTE = 'Less than or equal to',
    // GT  = 'Greater than',
    // GTE = 'Greater than or equal to'
})(FiltersDescriptiors || (FiltersDescriptiors = {}));
export var FiltersCombinations;
(function (FiltersCombinations) {
    FiltersCombinations["and"] = "and";
    FiltersCombinations["or"] = "or";
})(FiltersCombinations || (FiltersCombinations = {}));
var KendoGridCellFilterComponent = /** @class */ (function () {
    function KendoGridCellFilterComponent(elementRef) {
        this.elementRef = elementRef;
        this.cellFilterChanged$ = new EventEmitter();
        this.createFiltersDescriptors();
        this.createFiltersCombinations();
        this.resetControls();
    }
    Object.defineProperty(KendoGridCellFilterComponent.prototype, "hasFirstSearchValue", {
        get: function () {
            return _.size(this.firstSearchValue) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridCellFilterComponent.prototype, "hasSecondSearchValue", {
        get: function () {
            return _.size(this.secondSearchValue) > 0;
        },
        enumerable: true,
        configurable: true
    });
    KendoGridCellFilterComponent.prototype.ngOnInit = function () {
        //
    };
    KendoGridCellFilterComponent.prototype.ngOnDestroy = function () {
        //
    };
    KendoGridCellFilterComponent.prototype.onClick = function (e) {
        var container = this.elementRef.nativeElement;
        var srcElement = e.srcElement || e.target;
        if (!container || !srcElement)
            return;
        if (!container.contains(srcElement)) {
            this.isShown = false;
        }
    };
    KendoGridCellFilterComponent.prototype.onFilter = function () {
        var filters = [];
        if (this.hasFirstSearchValue) {
            filters.push(this.createFilterDescriptor(this.firstDescriptior.id, this.field, this.firstSearchValue));
        }
        if (this.hasSecondSearchValue) {
            filters.push(this.createFilterDescriptor(this.secondDescriptior.id, this.field, this.secondSearchValue));
        }
        var filterDescriptor = this.createCompositeFilter(this.currentCombination.id, filters);
        this.cellFilterChanged$.emit(this.createCompositeFilter(FiltersCombinations.and, [filterDescriptor]));
        this.hasFilter = true;
        this.isShown = false;
    };
    KendoGridCellFilterComponent.prototype.onClear = function () {
        var filterDescriptor = this.createCompositeFilter();
        this.cellFilterChanged$.emit(filterDescriptor);
        this.resetControls();
    };
    KendoGridCellFilterComponent.prototype.onToggleState = function () {
        this.isShown = !this.isShown;
    };
    KendoGridCellFilterComponent.prototype.createCompositeFilter = function (logic, filters) {
        if (logic === void 0) { logic = FiltersCombinations.and; }
        if (filters === void 0) { filters = []; }
        return {
            logic: logic,
            filters: filters,
        };
    };
    KendoGridCellFilterComponent.prototype.createFilterDescriptor = function (operator, field, value, ignoreCase) {
        if (field === void 0) { field = ''; }
        if (value === void 0) { value = ''; }
        if (ignoreCase === void 0) { ignoreCase = true; }
        return {
            operator: operator,
            field: field,
            value: value,
            ignoreCase: ignoreCase
        };
    };
    KendoGridCellFilterComponent.prototype.createFiltersDescriptors = function () {
        this.filterDescriptors = _.map(FiltersDescriptiors, function (text, id) { return ({ id: id, text: text }); });
    };
    KendoGridCellFilterComponent.prototype.createFiltersCombinations = function () {
        this.filterCombinations = _.map(FiltersCombinations, function (text, id) { return ({ id: id, text: _.capitalize(text) }); });
    };
    KendoGridCellFilterComponent.prototype.resetControls = function () {
        this.firstDescriptior = this.filterDescriptors[2];
        this.secondDescriptior = this.filterDescriptors[2];
        this.currentCombination = this.filterCombinations[0];
        this.firstSearchValue = '';
        this.secondSearchValue = '';
        this.isShown = false;
        this.hasFilter = false;
    };
    return KendoGridCellFilterComponent;
}());
export { KendoGridCellFilterComponent };
