import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody, Meta, FieldsMeta } from '../../../../core/models/index';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeSectionsAuditMapService } from './employee-section-audit-map.service';
import {
  IEmployeeSectionsFutureUpdates,
  EmployeeSectionsFutureUpdates,
  IEmployeeSectionsAudit,
  EmployeeSectionsAudit,
  IEmployeeSectionsAuditTrail,
  EmployeeSectionsAuditTrail,
  EmployeeSectionsAuditTrailNoteRecord,
  IEmployeeSectionsAuditTrailNoteRecord
} from '../../models/index';


@Injectable()
export class EmployeeSectionsAuditApiService {
  constructor(
    private employeeSectionsAuditMapService: EmployeeSectionsAuditMapService,
    private apiUtilService: ApiUtilService) {
  }

  public getAuditSection(employeeId: number): Promise<EmployeeSectionsAudit> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getAuditApiRoot(employeeId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);


    let promise: Promise<EmployeeSectionsAudit> = this.apiUtilService.request<IEmployeeSectionsAudit, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsAudit, Meta>) =>
        this.employeeSectionsAuditMapService.mapToSectionsAudit(response.data)
      );
    return promise;
  }

  public getFutureUpdates(employeeId: number): Promise<EmployeeSectionsFutureUpdates> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getAuditApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.audit.futureUpdates}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsFutureUpdates> = this.apiUtilService.request<IEmployeeSectionsFutureUpdates, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsFutureUpdates, FieldsMeta>) =>
        this.employeeSectionsAuditMapService.mapToFutureUpdates(response.data, response.meta)
      );
    return promise;
  }

  public deleteFutureUpdate(employeeId: number, updateId: number): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(updateId, 'updateId');
    const url: string = `${this.getAuditApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.audit.futureUpdates}/${updateId}`;

    let request: HttpRequest<any> = new HttpRequest('DELETE', url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public getAuditTrail(employeeId: number): Promise<EmployeeSectionsAuditTrail> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getAuditApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.audit.audittrail}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsAuditTrail> = this.apiUtilService.request<IEmployeeSectionsAuditTrail, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsAuditTrail, FieldsMeta>) =>
        this.employeeSectionsAuditMapService.mapToAuditTrail(response.data, response.meta)
      );
    return promise;
  }

  public addAuditTrailNote(employeeId: number, record: EmployeeSectionsAuditTrailNoteRecord ): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = this.getNotesApiRoot(employeeId);
    let dnote: IEmployeeSectionsAuditTrailNoteRecord = this.employeeSectionsAuditMapService.mapToAuditTrailNoteRecordDto(record);
    let request: HttpRequest<any> = new HttpRequest('POST', url, dnote);

    let promise: Promise<any> = this.apiUtilService.request<IEmployeeSectionsAuditTrail, FieldsMeta>(request);
    return promise;
  }

  public editAuditTrailNote(employeeId: number, record: EmployeeSectionsAuditTrailNoteRecord ): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(record.id, 'noteId');

    const url: string = this.getNotesApiRoot(employeeId, record.id);
    let dnote: IEmployeeSectionsAuditTrailNoteRecord = this.employeeSectionsAuditMapService.mapToAuditTrailNoteRecordDto(record);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, dnote);

    let promise: Promise<any> = this.apiUtilService.request<IEmployeeSectionsAuditTrail, FieldsMeta>(request);
    return promise;
  }

  public deleteAuditTrailNote(employeeId: number, noteId: number): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(noteId, 'noteId');
    const url: string = this.getNotesApiRoot(employeeId, noteId);

    let request: HttpRequest<any> = new HttpRequest('DELETE', url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.employees.root}`;
  }

  private getNotesApiRoot(employeeId: number, noteId: number = 0): string {
    let url: string = `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.notes.root}`;
    if (noteId > 0) {
      url = `${url}/${noteId}`;
    }
    return url;
  }

  private getSectionsApiRoot(employeeId: number): string {
    return `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.sections.root}`;
  }

  private getAuditApiRoot(employeeId: number): string {
    return `${this.getSectionsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.audit.root}`;
  }

}
