import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';
import { TaConsoleToolbarService } from '../../../services/ta-console/ta-console-toolbar.service';
import { screenUtils } from '../../../../common/utils/index';
import { ToolbarSectionTypes } from '../../../../common/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
    moduleId: module.id,
    selector: 'slx-ta-console-toolbar',
    templateUrl: 'ta-console-toolbar.component.html',
    styleUrls: ['ta-console-toolbar.component.scss']
})
export class TaConsoleToolbarComponent implements OnInit {

    @Input()
    public dateOn: Date;

    @Input()
    public dateNow: Date;

    @Input()
    public missingPunchesCount: number;

    @Input()
    public timecardExceptionCount: number;

    @Output()
    public onDateChanged: EventEmitter<Date>;

    @Output()
    public onNavigateToMissingPunches: EventEmitter<any>;
    @Output()
    public onNavigateToExceptions: EventEmitter<any>;


    public get dateFormat(): string {
        return screenUtils.isMobile ? 'MM/dd/yy' : 'MMMM dd, yyyy';
    }

    public appConfig: IApplicationConfig;

    constructor(@Inject(TOOLBAR_SERVICE) private consoleToolbarService: TaConsoleToolbarService) {
        this.onDateChanged = new EventEmitter<Date>();
        this.onNavigateToMissingPunches = new EventEmitter();
        this.onNavigateToExceptions = new EventEmitter();
        this.appConfig = appConfig;
    }

    public ngOnInit(): void {
        //
    }

    public onFilterDateChanged(date:Date): void {
        this.dateOn = date;
        this.onDateChanged.emit(this.dateOn);
    }

    public navigateToPunchMissing(): void {
        this.onNavigateToMissingPunches.emit();
    }

    public navigateToTimeException(): void {
        this.onNavigateToExceptions.emit();
    }

    public isVisibleSection(sectionType: ToolbarSectionTypes): boolean {
      return sectionType === ToolbarSectionTypes.VISIBLE;
    }

    public isAllSectionShown(sections: StringMap<number>): boolean {
      return sections[ToolbarSectionTypes.COLLAPSED_BY_RESIZE] === 0;
    }
}
