<div class="slx-high-box benefit-coverage-configuration">
  <div class="slx-high-box__body">
    <form #form="ngForm" novalidate class="slx-full-height grid-indent">
      <kendo-grid #kendoGrid
        class="slx-blue-grid slx-full-height slx-benefit-management-grid benefit-option"
        [data]="gridState?.view"
        [filterable]="'menu'"
        [sortable]="{ mode: 'multiple' }"
        [filter]="gridState.state.filter"
        [skip]="gridState.state.skip"
        [sort]="gridState.state.sort"
        (cancel)="gridState.cancelHandler($event)"
        (edit)="gridState.editHandler($event)"
        (remove)="gridState.removeHandler($event)"
        (save)="gridState.saveHandler($event)"
        (dataStateChange)="gridState.dataStateChange($event)"
      >
        <kendo-grid-command-column width="100" [locked]="true">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="inline-buttons">
              <button kendoGridEditCommand
                type="button"
                class="slx-button slx-only-icon slx-toolbar slx-margin-r"
                *ngIf="isEditMode"
                [disabled]='isRowEditing'
              >
                  <i class="fas fa-edit" aria-hidden="true"></i>
              </button>
              <button kendoGridRemoveCommand
                class="slx-button slx-gray slx-only-icon slx-toolbar"
                *ngIf="isEditMode && !isCurrentRowEditing(dataItem)"
                [disabled]='isRowEditing'
              >
                <i class="fas fa-trash" aria-hidden="true"></i>
              </button>
              <button kendoGridSaveCommand
                type="button"
                class="slx-button slx-only-icon slx-toolbar slx-margin-r"
                *ngIf="isEditMode"
                [disabled]='form.invalid'
              >
                <i class="fas fa-check" aria-hidden="true"></i>
              </button>
              <button kendoGridCancelCommand
                type="button"
                class="slx-button  slx-only-icon slx-toolbar"
                *ngIf="isEditMode"
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        </kendo-grid-command-column>

        <kendo-grid-column title="Type" width="200" field="type" [locked]="true">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Type</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.type}}</ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-dropdown-list class="slx-wide slx-short"
              #covType="ngModel"
              [options]="coverageOptionTypes"
              [valuePrimitive]="true"
              [(ngModel)]="dataItem.type"
              [required]="true"
              name="type{{rowIndex}}"
            ></slx-dropdown-list>
            <span *ngIf="covType.errors" class="slx-error-block error-box">
              <span *ngIf="covType.errors.required" errorMessage for="required"></span>
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Code" width="250" field="code">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Code</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.code }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
             <div class="slx-benefit-box__value flex__grow code-input-container">
                            <input type="text" class="slx-benefit-box__text-field code-input" [(ngModel)]="dataItem.code"
                                #codeRef="ngModel"
                                [selfValue]="dataItem.code"
 								                [required]="true"
                                [prohibitedValues]="{values: prohibitedCodeValues, trimValue: true, excludeSelf: true, caseSensitive: false}"
                                name="code{{rowIndex}}" 
                                maxlength="50"
                                [slxMaxLength]="50"
                                >

                                <span *ngIf="codeRef.errors" class="slx-error-block error-box">
                                  <span *ngIf="codeRef.errors.prohibitedValues" errorMessage for="prohibitedValues">Code already exists for the plan. Please enter a unique code.</span>
                                  <span *ngIf="codeRef.errors.required" errorMessage for="required"></span>
                                  <span *ngIf="codeRef.errors.slxMaxLength" errorMessage>Max length is 50 characters</span>
                                </span>
                          </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Spouse" field="spouse" width="120">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Spouse</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.spouse}}</ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-dropdown-list class="slx-wide slx-short"
              #spouseReq="ngModel"
              [options]="spousesList"
              [ngModel]="findDropDownItem(dataItem.spouse, spousesList)"
              [valuePrimitive]="false"
              [required]="true"
              name="spouse{{rowIndex}}"
              (ngModelChange)="onSpouseChanged(dataItem, $event)"
              valueField="key"
              titleField="value"
            ></slx-dropdown-list>
            <span *ngIf="spouseReq.errors" class="slx-error-block error-box">
              <span *ngIf="spouseReq.errors.required" errorMessage for="required"></span>
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Children" field="children" width="120">
          <ng-template kendoGridHeaderTemplate> <span slxKendoGridHeaderTooltip>Children</span> </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.children}}</ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-dropdown-list class="slx-wide slx-short"
              #childrenReq="ngModel"
              [options]="childrenValues"
              [ngModel]="findDropDownItem(dataItem.children, childrenValues)"
              [valuePrimitive]="false"
              [required]="true"
              name="children{{rowIndex}}"
              (ngModelChange)="onChildrenChanged(dataItem, $event)"
              valueField="key"
              titleField="value"
            ></slx-dropdown-list>
            <span *ngIf="childrenReq.errors" class="slx-error-block error-box">
              <span *ngIf="childrenReq.errors.required" errorMessage for="required"></span>
            </span>
           
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Employee Contribution" width="200" field="empContribution">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Employee Contribution</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.empContribution | number: ".2-2"| slxMoney}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-number #empContField="ngModel"
              class="slx-wide slx-input-number"
              [min]="min"
              [max]="max"
              [step]="step"
              [format]="format"
              [autoCorrect]="false"
              [decimals]="2"
              [(ngModel)]="dataItem.empContribution"
              (ngModelChange)="onChangeContribution(dataItem)"
              name="empContribution{{rowIndex}}"
            ></slx-number>
            <span *ngIf="empContField.errors?.min || empContField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Employer Contribution" width="200" field="erContribution">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Employer Contribution</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.erContribution | number: ".2-2"| slxMoney}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-number #erContField="ngModel"
              class="slx-wide slx-input-number"
              [min]="min"
              [max]="max"
              [slxMin]="min"
              [slxMax]="max"
              [step]="step"
              [format]="format"
              [decimals]="2"
              [autoCorrect]="false"
              [(ngModel)]="dataItem.erContribution"
              (ngModelChange)="onChangeContribution(dataItem)"
              name="erContribution{{rowIndex}}"
            ></slx-number>
            <span *ngIf="erContField.errors?.min || erContField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Cost" width="200" field="cost">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Cost ({{ selectedProviderLine?.costFreq?.name }})</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.cost | number: ".2-2"| slxMoney}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            {{ dataItem.cost | number: ".2-2"| slxMoney}}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="isHealthBenifitPlanSelected" title="ACA Category" width="200" field="acaCategory">
          <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.acaCategory}}</ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-dropdown-list class="slx-wide slx-short" [options]="acaCategoryLookupValues" [valuePrimitive]="true"
                               [(ngModel)]="dataItem.acaCategory" name="acaCategory"></slx-dropdown-list>
          </ng-template>
          <ng-template kendoGridHeaderTemplate> <span slxKendoGridHeaderTooltip>ACA Category</span> </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>
</div>
