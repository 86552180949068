import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { unsubscribeAll } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { StateManagementService, ColumnManagementService,  ModalService,DialogOptions, ConfirmDialog2Component, ConfirmOptions } from '../../../../common';
import { OrgLevel } from '../../../../state-model/models';
import { Aca1095cManagementService } from '../../../services/aca-1095-c/aca-1095-c-management.service';
import * as moment from 'moment';
import { Employee } from '../../../../organization/models/index';
import { appConfig } from '../../../../app.config';
import { EmployeeFilterRecord, EmployeeFilter, EmployeeIdList } from '../../../models/index';
import { Aca1095cSettings,AcaMonthRecord, Aca1095cColumnsSettings,aca1095CPopupAction,Aca1095CPopupInitData, Aca1095c, Aca1095cActions, Aca1095cRecord, acaQuickPopupState } from '../../../models/aca-1095-c';
import { Aca1095CAddEmployeePopupComponent } from '../add-employee-popup/add-employee-popup.component';
import { Edit1095CPopup } from '../edit-1095-c-popup/edit-1095-c-popup.component';
import {QuickEditReset1095CPopup} from '../aca-1095c-quick-edit-reset/aca-1095c-quick-edit-reset.component';
import { SetFilterModelValuesType } from 'ag-grid-enterprise/dist/lib/setFilter/setValueModel';
import { TestRequest } from '@angular/common/http/testing';
import { AcaOfferOfCoverage,AcaSafeHarbor,AcaReason,IAcaSafeHarbor,IAcaOfferOfCoverage,IAcaReason} from '../../../../organization/models/index';
import { ACA1095cConfig } from '../../../aca-1095.config';

@Component({
  moduleId: module.id,
  selector: 'slx-aca-1095-c-toolbar',
  templateUrl: 'aca-1095-c-toolbar.component.html',
  styleUrls: ['aca-1095-c-toolbar.component.scss']
})

export class Aca1095cToolbarComponent implements OnInit, OnDestroy {

  public defaultYear: number = moment().startOf('day').toDate().getFullYear();
  public selectedYear: number;
  public yearOptions: number[] = [];
  private disabledEmployees:Aca1095cRecord[]=[];
  public employeeFilterRecords: EmployeeFilterRecord[];
  public orgLevel:OrgLevel;
  public filter: EmployeeFilterRecord[] = [];
  public reportClosed = "This reporting year has been closed out";
  public isExpand: boolean = false;
  public employee: Employee;
  public actions: Aca1095cActions;
  public readonly columnsStateName: string = 'Aca1095c';
  private settings: Aca1095cSettings;
  public editItems:Aca1095cRecord[]=[];
  public editResetItems:Aca1095cRecord[]=[];
  public confirmItems:Aca1095cRecord[]=[];
  public confirmResetItems:Aca1095cRecord[]=[];
  public acaSafeHarbors:AcaSafeHarbor[];
  public acaReasons:AcaReason[];
  public acaOfferOfCoverage:AcaOfferOfCoverage[];
  public acaSelectedSafeHarbors:AcaSafeHarbor=new AcaSafeHarbor();
  public acaSelectedReasons:AcaReason=new AcaReason();
  public acaSelectedOfferOfCoverage:AcaOfferOfCoverage=new AcaOfferOfCoverage()
  public acaSelectedEnrolledInd:boolean;
  public acaSelectedCost:string;
  public quickEditInitData:AcaMonthRecord;
  public singleEditInitData:Aca1095CPopupInitData;
  public isQuickPopupActive: boolean;
  public isQuickEditActive: boolean;
  public isQuickConfirmActive: boolean;
  public isQuickEditStarted: boolean;
  public isEditResetTabActive:boolean = false;
  public isConfirmResetTabActive:boolean = false;

  public quickEditWindowTop: number = 42;
  public quickEditWindowLeft: number = 800;
  public quickEditWidth: number = 450;
  public quickEditHeight: number = 260;
  public isEditableYear: boolean;

  public get closeOutTitle(): string {
    if (this.isEditableYear) {
      return 'Not available until April.';
    }

    return this.reportClosed;
  }

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private acaManagementService: Aca1095cManagementService,
    private columnManagementService: ColumnManagementService,
    private stateManagement: StateManagementService ,
    private modalService: ModalService
  ) { }



  public ngOnInit(): void {

    this.createYearOptions();

    this.selectedYear = this.defaultYear;
    this.subscriptions.onMarkItemForQickEdit=this.acaManagementService.subscribeToShowPopupMessage((message:string)=>{
      this.warningPopup(message);
    });
    this.subscriptions.onMarkItemForQickEdit=this.acaManagementService.subscribeToQuickEditRecords((items:Aca1095cRecord[])=>{
      this.editItems=items;
    });
    this.subscriptions.onMarkItemForQickConfirm=this.acaManagementService.subscribeToQuickConfirmRecords((items:Aca1095cRecord[])=>{
      this.confirmItems=items;
    });
    this.subscriptions.onMarkItemForQickEditReset=this.acaManagementService.subscribeToQuickEditResetRecords((items:Aca1095cRecord[])=>{
      this.editResetItems=items;
    });
    this.subscriptions.onMarkItemForQickConfirmReset=this.acaManagementService.subscribeToQuickConfirmResetRecords((items:Aca1095cRecord[])=>{
      this.confirmResetItems=items;
    });
    this.subscriptions.quickPopupstate = this.acaManagementService.subscribeToQuickPopupState((quickPopupState: string) =>{

      switch(quickPopupState) {

        case acaQuickPopupState.quickEditClose:
          {
            this.editItems=[];
            this.editResetItems=[];
            this.closeQuickEdit();
            break;
          }
        case acaQuickPopupState.quickConfirmClose:
        {
          this.confirmItems=[];
          this.confirmResetItems=[];
          this.closeQuickConfirm();
          break;
        }
        case acaQuickPopupState.quickEditResetActive:
        {
          this.openQuickEditResetTab();
          break;
        }
        case acaQuickPopupState.quickEditActive:
        {
          this.openQuickEditTab();
          break;
        }
        case acaQuickPopupState.quickConfirmResetActive:
        {
          this.openQuickConfirmResetTab();
          break;
        }
        case acaQuickPopupState.quickConfirmActive:
        {
          this.openQuickConfirmTab();
          break;
        }

     }

     });
    this.subscriptions.onOrgLevelChange=this.acaManagementService.subscribeToOrgLevel((orgLevel:OrgLevel)=>{
      this.orgLevel=orgLevel;
    });

    this.subscriptions.populateCellData=this.acaManagementService.subscribeToQuickEditInitData((data:AcaMonthRecord)=>{
      this.quickEditInitData=data;
    });

    this.subscriptions.onAddRemoveEmployee=this.acaManagementService.subscribeToIsAddRemoveEmployee((aca1095cRecord:Aca1095cRecord)=>{
      if(_.isNil(aca1095cRecord))
      {
        this.openAddEmployee();
      }
      else
      {
        this.confirmRemoveEmployee(aca1095cRecord);
      }
    })

    this.subscriptions.onEditOrConfirm=this.acaManagementService.subscribeToInit1095Popup((popupInitData:Aca1095CPopupInitData)=>{
      if(_.isEqual(popupInitData.popupAction,aca1095CPopupAction.edit1095Record))
      {
        if (!this.isQuickEditActive && !this.isQuickConfirmActive) {
          this.initPopupDropdowns();
          this.singleEditInitData=popupInitData;
          this.acaSelectedReasons.id=popupInitData.params.reason;
          this.acaSelectedReasons.name=popupInitData.params.reason;
          this.acaSelectedEnrolledInd=popupInitData.params.enrolledInd;
          if(popupInitData.params.selectedCode ||popupInitData.params.selectedHarbor ||popupInitData.params.selectedCost)
          {
          this.acaSelectedOfferOfCoverage.id=popupInitData.params.selectedCode;
          this.acaSelectedOfferOfCoverage.name=popupInitData.params.selectedCode;
          this.acaSelectedSafeHarbors.id=popupInitData.params.selectedHarbor;
          this.acaSelectedSafeHarbors.name=popupInitData.params.selectedHarbor;
          this.acaSelectedCost=popupInitData.params.selectedCost;
          }
          else
          {
            this.acaSelectedOfferOfCoverage.id=popupInitData.params.suggestedCode;
            this.acaSelectedOfferOfCoverage.name=popupInitData.params.suggestedCode;
            this.acaSelectedSafeHarbors.id=popupInitData.params.suggestedHarbor;
            this.acaSelectedSafeHarbors.name=popupInitData.params.suggestedHarbor;
            this.acaSelectedCost=popupInitData.params.suggestedCost;
          }
          this.openEditRecord();
        }
      }
      else if(_.isEqual(popupInitData.popupAction,aca1095CPopupAction.quickEditRecord))
      {
          this.openPopup(aca1095CPopupAction.quickEditRecord);
          if(popupInitData.params)
          {
          this.quickEditInitData=popupInitData.params;
          this.acaSafeHarbors =this.acaManagementService.acaSafeHarbor;
          this.acaOfferOfCoverage =this.acaManagementService.acaOfferOfCoverage;
          this.acaReasons =this.acaManagementService.acaReason;
          }
      }

    })

    this.subscriptions.onLoadedActions = this.acaManagementService
      .subscribeToLoadedRecords((container: Aca1095c) => {
        if (!this.actions) {
          this.actions=new  Aca1095cActions();
          this.actions = container.actions;
          this.initServices();
        }
       this.disabledEmployees= container.records;

      });
    this.subscriptions.onLoaded = this.acaManagementService
      .subscribeToEmployeeFilter((records: EmployeeFilter) => {
        this.filter = records.filter;
          this.employeeFilterRecords = records.employeeFilterRecord;
      });
  }

  public initPopupDropdowns()
  {
    this.acaSafeHarbors =this.acaManagementService.acaSafeHarbor;
    this.acaOfferOfCoverage =this.acaManagementService.acaOfferOfCoverage;
    this.acaReasons =this.acaManagementService.acaReason;
  }

  private createYearOptions(): void {
    this.yearOptions = _.reverse(_.values(_.range(2015, this.defaultYear+1)));
  }

  public ngOnDestroy(): void { }




  public onQuickWindowDragEnd(): void {
    const offset = 70;
    if (this.quickEditWindowTop < 0) {
      this.quickEditWindowTop = 0;
    } else if (this.quickEditWindowTop > window.innerHeight - this.quickEditHeight - offset) {
      this.quickEditWindowTop = window.innerHeight - this.quickEditHeight - offset;
    }
    if (this.quickEditWindowLeft < 0) {
      this.quickEditWindowLeft = 0;
    } else if (this.quickEditWindowLeft > window.innerWidth - this.quickEditWidth - offset) {
      this.quickEditWindowLeft = window.innerWidth - this.quickEditWidth - offset;
    }
  }

  public onClickExport(isPDF: boolean): void {
    this.acaManagementService.exportTo(isPDF);
  }
  public onClickExpand(isExpand: boolean): void {
    this.isExpand = isExpand;
    this.acaManagementService.expandAll(isExpand);
  }
    public confirmRemoveEmployee(aca1095cRecord: Aca1095cRecord) {
    let message = '';
    message = 'Removing this employee from the list means that they will not be receiving a 1095 C for this record and the selected year. Their information will also not be counted as part of any ACA reporting for the selected year. Are you sure you want to proceed?';
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Ok';
    options.buttonCanceltext = 'Cancel';
    ConfirmDialog2Component.openDialog(
      'Warning',
      message,
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.acaManagementService.removeEmployee(aca1095cRecord.employeeId,aca1095cRecord.employeeMasterId,aca1095cRecord.companyId);
        }
        else {
        }
      }, options);
    }

  public confirmQuickClose(popupState) {
    let message = '';
    message = 'The changes you made will not be saved. Are you sure you want to proceed?';
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Ok';
    options.buttonCanceltext = 'Cancel';
    ConfirmDialog2Component.openDialog(
      'Warning',
      message,
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.acaManagementService.setQuickPopupState(popupState);
        }
        else {
        }
      }, options);
  }
  public warningPopup(message) {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    options.buttonOKtext = 'Ok';
    options.buttonCanceltext = 'Close';
    ConfirmDialog2Component.openDialog(
      'Warning',
      message,
      this.modalService,
      (result: boolean) => {
        if (result) {

        }
        else {
        }
      }, options);
  }
  public openAddEmployee():void
  {

    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 550;
    dialogOptions.height = 300;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.hideTitleBar = true;
    let dialog: Aca1095CAddEmployeePopupComponent = this.modalService.globalAnchor.openDialog(
      Aca1095CAddEmployeePopupComponent,'Add Employee 1095C',
      dialogOptions, undefined, (result: boolean) => {
        if (result) {
          this.employee=dialog.employee;
          this.acaManagementService.addEmployee(this.employee.id,this.employee.masterId,0,this.employee.department.id,this.employee.organization.id);
        }
      });
    if (dialog) {
      dialog.orgLevel = this.orgLevel;
      dialog.disabledEmployees = this.disabledEmployees;
      dialog.year = this.selectedYear;
      dialog.loadSubsection();
    }
  }
  public openPopup(popupType:string)
  {
    if(_.isEqual(popupType,aca1095CPopupAction.edit1095Record))
    {
      this.openEditRecord();
    }
    else if(_.isEqual(popupType,aca1095CPopupAction.quickEditRecord))
    {
      this.openQuickEdit();
    }
    else if(_.isEqual(popupType,aca1095CPopupAction.quickEditRecord))
    {
      this.openQuickConfirm();
    }
  }
  public closePopup(popupType:string)
  {

    if(_.isEqual(popupType,aca1095CPopupAction.quickEditRecord))
    {

      this.closeQuickEdit();
    }
    else if(_.isEqual(popupType,aca1095CPopupAction.quickConfirmRecord))
    {
      this.closeQuickConfirm();
    }
  }
  public openQuickEdit():void{
    if (!this.isQuickEditActive) {
      this.initPopupDropdowns();
      this.isQuickEditActive=true;
      this.isEditResetTabActive=false;
    }
  }
  public openQuickEditTab():void{
    if(this.editResetItems && this.editResetItems.length>0)
    {
      this.confirmQuickClose(acaQuickPopupState.quickEditResetClear);
    }
    else
    {
      if (this.isEditResetTabActive) {
        this.isEditResetTabActive=false;
        this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditActive);
      }
    }
  }
  public openQuickEditResetTab():void{
    if(this.editItems && this.editItems.length>0)
    {
      this.confirmQuickClose(acaQuickPopupState.quickEditClear)
    }
    else
    {
      if (!this.isEditResetTabActive) {
        this.isEditResetTabActive=true;
        this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditResetActive);
      }
    }
  }
  public openQuickConfirm():void{
    if (!this.isQuickConfirmActive) {
      this.initPopupDropdowns();
      this.isQuickConfirmActive=true;
      this.isConfirmResetTabActive=false;
    }
  }
  public openQuickConfirmTab():void{
    if(this.confirmResetItems && this.confirmResetItems.length>0)
    {
      this.confirmQuickClose(acaQuickPopupState.quickConfirmResetClear);
    }
    else
    {
      if (this.isConfirmResetTabActive) {
        this.isConfirmResetTabActive=false;
        this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmActive);
      }
    }
  }
  public openQuickConfirmResetTab():void{
    if(this.confirmItems && this.confirmItems.length>0)
    {
      this.confirmQuickClose(acaQuickPopupState.quickConfirmClear)
    }
    else
    {
      if (!this.isConfirmResetTabActive) {
        this.isConfirmResetTabActive=true;
        this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmResetActive);
      }
    }
  }

  public closeQuickEdit():void{
    if(this.editResetItems && this.editResetItems.length>0 && this.isEditResetTabActive)
    {
      this.confirmQuickClose(acaQuickPopupState.quickEditResetClearAndClose);
    }
    else if(this.editItems && this.editItems.length>0 && !this.isEditResetTabActive)
    {
      this.confirmQuickClose(acaQuickPopupState.quickEditClearAndClose);
    }
    else
    {
      if (this.isQuickEditActive) {
        this.isQuickEditActive=false;
        this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditClose);
      }
    }
  }

  public closeQuickConfirm():void{
    if(this.confirmResetItems && this.confirmResetItems.length>0 && this.isConfirmResetTabActive)
    {
      this.confirmQuickClose(acaQuickPopupState.quickConfirmResetClearAndClose);
    }
    else if(this.confirmItems && this.confirmItems.length>0 && !this.isConfirmResetTabActive)
    {
      this.confirmQuickClose(acaQuickPopupState.quickConfirmClearAndClose);
    }
    else
    {
      if (this.isQuickConfirmActive) {
        this.isQuickConfirmActive=false;
        this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
      }
    }

  }

  public openEditRecord():void{
    let acaRecords:Aca1095cRecord=new Aca1095cRecord();
    acaRecords.employeeId=this.singleEditInitData.employeeId;
    acaRecords.employeeMasterId=this.singleEditInitData.employeeMasterId;
    acaRecords.companyId=this.singleEditInitData.companyId;
    let acaMonthData:AcaMonthRecord=new AcaMonthRecord();
    acaMonthData=this.singleEditInitData.params;
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 450;
    dialogOptions.height = 280;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.hideTitleBar = true;
    let dialog: Edit1095CPopup = this.modalService.globalAnchor.openDialog(
      Edit1095CPopup,'Edit 1095 Records',
      dialogOptions, undefined, (result: boolean) => {
        if (result) {
          acaMonthData.selectedHarbor=dialog.selectedHarbor.id;
          acaMonthData.selectedCode=dialog.selectedCoverage.id;
          acaMonthData.selectedCost=dialog.selectedCost;
          acaMonthData.reason=dialog.selectedReason.id;
          acaMonthData.enrolledInd=dialog.isEnrolled;
          acaRecords.months=[acaMonthData];
          this.acaManagementService.submitQuick1095cRecords([acaRecords],acaQuickPopupState.quickEditSave);
        }
      });
    if (dialog) {
      dialog.harborOptions=this.acaSafeHarbors;
      dialog.coverageOptions=this.acaOfferOfCoverage;
      dialog.reasonOptions=this.acaReasons;
      dialog.selectedHarbor=this.acaSelectedSafeHarbors;
      dialog.selectedCoverage=this.acaSelectedOfferOfCoverage;
      dialog.selectedReason=this.acaSelectedReasons;
      dialog.isEnrolled=this.acaSelectedEnrolledInd;
      dialog.selectedCost=this.acaSelectedCost;
    }

  }


  private initServices(): void {
    this.restoreSettings();
    this.stateManagement.init('Aca1095cComponent');
    this.columnManagementService.init('Aca1095cComponent');
    this.columnManagementService.initGroup(this.columnsStateName, 6);
    this.columnManagementService.initializeGroupWithColumns(this.columnsStateName, this.settings.columns.columns);
  }

  public restoreSettings(): void {
    this.settings = new Aca1095cSettings();
    this.settings.columns = new Aca1095cColumnsSettings();
    this.settings.columns.createColumns();
    this.settings.columns.applySecurity(this.actions);
  }

  public getAcaWidth(): number {
    return (screen.width <= appConfig.mobileMaxWidth) ? 30 : 130;
  }

  public onChangeYear(selYear: number): void {
    this.selectedYear = selYear;
    this.isEditableYear = ACA1095cConfig.isEditableYear(selYear);
    this.acaManagementService.setSelectedYear(selYear);
  }


  public onChangeFilter(filters: EmployeeFilterRecord[]): void {
    this.acaManagementService.setEmployeeFilter(filters);
  }
}
