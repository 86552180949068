import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { process, State, filterBy, orderBy, GroupDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { KendoGridStateHelper } from '../../../../common/index';
import { IdealScheduleOverviewItem } from '../../../models/ideal-schedule/index';
import { IdealScheduleApiService } from '../../../services/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { IApplicationConfig } from '../../../../app.config';

@Component({
    moduleId: module.id,
    selector: 'slx-ideal-schedule-overview',
    templateUrl: 'ideal-schedule-overview.component.html',
    styleUrls: ['ideal-schedule-overview.component.scss']
})
export class IdealScheduleOverviewComponent implements OnInit {

    public orgLevel: OrgLevel;
    public appConfig: IApplicationConfig;
    public isLoading: boolean;
    public gridState: KendoGridStateHelper<IdealScheduleOverviewItem>;

    @mutableSelect(['orgLevel'])
    private orgLevel$: Observable<OrgLevel>;
    @unsubscribe()
    private orgLevelSubscription: Subscription;

    private records: IdealScheduleOverviewItem[];

    constructor(private api: IdealScheduleApiService) {
        this.gridState = new KendoGridStateHelper<IdealScheduleOverviewItem>();
        this.isLoading = true;
        this.gridState.state.group = [{field: 'period'}];
    }

    public ngOnInit(): void {

        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            if (orgLevel && _.isNumber(orgLevel.id)) {
                this.isLoading = true;
                this.orgLevel = orgLevel;
                this.loadOverview();
            }
        });
    }

    private loadOverview(): void {
        this.api.getIdealScheduleOverview(this.orgLevel.id).then((data: IdealScheduleOverviewItem[]) => {
            this.records = data;
            this.refreshGrid();
            this.isLoading = false;
        });
    }

    private refreshGrid(): void {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }

        //let filteredRecords: IdealScheduleOverviewItem[] = filterBy(this.records, this.gridState.state.filter);
        //let sortedRecords: IdealScheduleOverviewItem[] = orderBy(filteredRecords, this.gridState.state.sort);

        this.gridState.view = process(this.records, this.gridState.state);
    }
}

