export * from './direct-care-toolbar/direct-care-toolbar.component';
export * from './direct-care-hours.component';

import { DirectCareHoursToolbarComponent } from './direct-care-toolbar/direct-care-toolbar.component';
import { DirectCareHoursComponent } from './direct-care-hours.component';


export const directCareComponets: any[] = [
    DirectCareHoursToolbarComponent,
    DirectCareHoursComponent
];