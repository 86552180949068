import { Component, OnInit, Provider, ViewChild } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';
import { DialogOptions, IDialog } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { EmployeeToTransfer, EmployeeTransfer } from '../../../../models/index';
import { EmployeeTransferComponent } from '../employee-transfer/employee-transfer.component';
import { EmployeeShortInfo } from '../../../../../../organization/models/index';
import { NgModel } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-transfer-dialog',
  templateUrl: 'employee-transfer-dialog.component.html',
  styleUrls: ['employee-transfer-dialog.component.scss']
})
export class EmployeeTransferDialogComponent implements IDialog {
  public options: DialogOptions;
  public dialogResult: boolean;
  public employee: EmployeeToTransfer;

  public state: {
    isLoading: boolean;
  };

  public appConfig: IApplicationConfig;

  @ViewChild('employeeTransfer', {static: true})
  public employeeTransfer: EmployeeTransferComponent;

  @ViewChild('effectiveDate', {static: false})
  public effectiveDate: NgModel;

  private modalService: ModalService;
  private orgLevelId: number;

  public static openDialog(request: EmployeeToTransfer, modalService: ModalService, callback: (result: boolean, req: EmployeeTransfer) => void): EmployeeTransferDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 710;
    dialogOptions.width = 850;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: EmployeeToTransfer,
        useValue: request
      }
    ];
    let dialog: EmployeeTransferDialogComponent = modalService.globalAnchor
      .openDialog(EmployeeTransferDialogComponent, 'Transfer Employee', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.requestToTerminate);
      });
    return dialog;
  }

  public get requestToTerminate(): EmployeeTransfer {
    return this.employeeTransfer.requestToTransfer;
  }

  public get employeeShortInfo(): EmployeeShortInfo {
    return this.employeeTransfer.employeeShortInfo;
  }

  public get isEmployeeTransferFormValid(): boolean {
    if(this.employeeTransfer && this.employeeTransfer.ngForm) {
      return this.employeeTransfer.ngForm.valid;
    }
    return false;
  }


  constructor(options: DialogOptions,
    modalService: ModalService,
    employee: EmployeeToTransfer,
  ) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
    this.employee = employee;
    this.state = {
      isLoading: false,
    };
    this.appConfig = appConfig;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
