<div modalAnchor class="slx-local-anchor"></div>
<slx-spinner [show]="isLoading">
    <div class="slx-high-box benefit-employees-eligible ">
        <div class="slx-high-box__body">
          <form #form="ngForm" novalidate class="slx-full-height">
            <kendo-grid #kendoGrid
              class="slx-blue-grid slx-transparent-selection slx-grid-slim-rows slx-full-height"
              [data]="gridState?.view"
              [filterable]="'menu'"
              [sortable]="true"
              [pageable]="true"
              [pageSize]="pageSize"
              [filter]="gridState.state.filter"
              [sort]="gridState.state.sort"
              [skip]="gridState.state.skip"
              (dataStateChange)="gridState.dataStateChange($event)"
            >

              <kendo-grid-column title="Employee" field="employeeName" width="150" >
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Employee</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a [employeeSectionNewWindowLink]="dataItem.employeeId">
                    {{dataItem.employeeName}}
                  </a>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Position" field="position.name" width="110">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Position</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{dataItem.position.name}}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Department" field="department.name" *slxHiddenOn="['tablet', 'mobile']" width="120">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Department</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{dataItem.department.name}}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Type" width="110" field="type.name">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Type</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{dataItem.type.name}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Option" field="optionCode.type" width="200" >
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Option</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <ng-container>
                    <slx-dropdown-list class="slx-wide"
                                       #option="ngModel" slx-input
                                       [options]="benefitOptionData"
                                       [(ngModel)]="dataItem.optionCode"
                                       (ngModelChange)="onOptionValueChange(dataItem, rowIndex, covFormulaOverride?.invalid)"
                                       valueField="id"
                                       titleField="type"
                                       name="option{{rowIndex}}"
                                       [required]="true">
                    </slx-dropdown-list>
                    <div *ngIf="option.errors" class="slx-error-block pull-left">
                      <span *ngIf="option.errors.required" errorMessage for="required"></span>
                    </div>
                  </ng-container>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="coverage" width="110" field="coverage" class="numeric-cell">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Coverage</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <ng-container *ngIf="tierDetails?.coverageFormula?.isFixedCalcType">
                    {{ coverage | number: ".2-2"| slxMoney }}
                  </ng-container>
                  <ng-container *ngIf="tierDetails?.coverageFormula?.isMultiplierCalcType">
                    <slx-autocomplete class="slx-wide" #multiplier="ngModel"
                                      [options]="coverageMultipllierOptions"
                                      [(ngModel)]="dataItem.editedItemCoverage"
                                      (ngModelChange)="onChangeCoverageWithMultiplier($event, dataItem, rowIndex)"
                                      [strictSearch]="false"
                                      rightIcon="fas fa-search"
                                      name="coverageMultiplier{{rowIndex}}"
                                      [setNullAfterInit]="true"
                                      titleField="name"
                                      [required]="true">
                      <ng-template slxAutocompleteItem let-item="item">
                        {{item.name}}
                      </ng-template>
                    </slx-autocomplete>
                    <div *ngIf="multiplier.errors" class="slx-error-block pull-left">
                      <span *ngIf="multiplier.errors.required" errorMessage for="required"></span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="tierDetails?.coverageFormula?.isAnyValueMaxCapCalcType">
                    <slx-number class="slx-wide slx-input-number" #covAnyValue="ngModel"
                      [min]="minAmt"
                      [max]="tierDetails?.coverageFormula?.maxCap"
                      [step]="stepcurrency"
                      [format]="'c2'"
                      [decimals]="2"
                      [(ngModel)]="dataItem.coverage"
                      [required]="true"
                      (blur)="onCoverageAnyValueChange($event, dataItem, rowIndex)"
                      name="coverageAnyValue{{rowIndex}}"
                    ></slx-number>
                    <div *ngIf="covAnyValue.errors" class="slx-error-block pull-left">
                      <span *ngIf="covAnyValue.errors.required" errorMessage for="required"></span>
                      <span *ngIf="covAnyValue.errors.min" errorMessage>Must be greater than {{ minAmt }}</span>
                      <span *ngIf="covAnyValue.errors.max" errorMessage>Must be less than {{ tierDetails?.coverageFormula?.maxCap }}</span>

                    </div>
                  </ng-container>
                  <ng-container *ngIf="tierDetails?.coverageFormula?.isFormulaCalcType">
                    <span class="flex">
                      <slx-number class="slx-wide slx-input-number" [decimals]="2" #covFormulaOverride="ngModel"
                                  [step]="stepcurrency" [format]="'c2'" [max]="maxAmt" [min]="minFormulaAmt"
                                  [autoCorrect]='true' [ngModel]="dataItem.calculatedCoverage" [required]="true"
                                  (blur)="onChangeCoverageWithFormula($event, dataItem, rowIndex, covFormulaOverride.errors ? true: false)" name="covFormulaValue{{rowIndex}}">
                      </slx-number>
                      <span>
                        <i *ngIf="dataItem.calculatedCoverage==-1"
                        title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                        aria-hidden="true"
                        [class.error-exclamation]="loadError" class="fa fa-exclamation-triangle formula-warning"></i>
                      </span>
                    </span>
                    <div *ngIf="covFormulaOverride.errors" class="slx-error-block pull-left">
                      <span *ngIf="covFormulaOverride.errors.required" errorMessage for="required"></span>
                    </div>
                  </ng-container>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Employer Contribution" field="employerContribution" width="180" class="numeric-cell">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Employer Contribution</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="tierDetails.erFormula.isFixedCalcType">
                  {{ employerContribution | number: ".2-2"| slxMoney }}
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="!tierDetails.erFormula.isFixedCalcType">
                  {{ dataItem.employerContributionAmount | number: ".2-2"| slxMoney }}
                  <i *ngIf="dataItem.employerContributionAmount==-1"
                     title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                     aria-hidden="true"
                     [class.error-exclamation]="loadError" class="fa fa-exclamation-triangle formula-warning"></i>
                    </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Employee Contribution" field="employeeContribution" width="180" class="numeric-cell">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Employee Contribution</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="tierDetails.empFormula.isFixedCalcType">
                  {{ employeeContribution | number: ".2-2"| slxMoney }}
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                             *ngIf="!tierDetails.empFormula.isFixedCalcType">
                  {{ dataItem.employeeContributionAmount | number: ".2-2"| slxMoney }}
                  <i *ngIf="dataItem.employeeContributionAmount==-1"
                     title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                     aria-hidden="true"
                     [class.error-exclamation]="loadError" class="fa fa-exclamation-triangle formula-warning"></i>
                </ng-template>
              </kendo-grid-column>

            </kendo-grid>
          </form>
        </div>
    </div>
</slx-spinner>
