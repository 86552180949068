import { GenericLinkDefinition, IGenericLinkDefinition } from './generic-link-definition';

export interface IGenericFieldDefinition {
  fieldName: string;
  displayName: string;
  fieldType: GenericFieldType;
  hidden: boolean;
  width: string;
  hyperlink: IGenericLinkDefinition;
}

export class GenericFieldDefinition {
  public fieldName: string;
  public displayName: string;
  public fieldType: GenericFieldType;
  public hidden: boolean;
  public width: string;
  public hyperlink: GenericLinkDefinition;
  public align: GenericFieldAlignment;
}

export class GenericFieldType {
  public static int: GenericFieldTypeDefinition = 'int';
  public static string: GenericFieldTypeDefinition = 'string';
  public static boolean: GenericFieldTypeDefinition = 'boolean';
  public static date: GenericFieldTypeDefinition = 'date';
  public static object: GenericFieldTypeDefinition = 'object';
  public static hyperlink: GenericFieldTypeDefinition = 'hyperlink';
  public static check: GenericFieldTypeDefinition = 'check';
}

export type GenericFieldTypeDefinition = 'int' | 'string' | 'boolean' | 'date' | 'object' | 'hyperlink'| 'check';
export type GenericFieldAlignment = 'left' | 'center' | 'right';
