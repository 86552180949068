import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit, Output, AfterViewInit, EventEmitter, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs/Subscription';

import { IRangeDates, RangeDates } from '../../../../../common/models/range-dates';
import { PBJExportRequest, PBJExportRange, PBJExportType } from '../../../models/index';
import { DaterangeInputComponent } from '../../../../../components-library/components/index';
import { DateRangeValue } from '../../../../../components-library/models/index';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { ApplicationStateBusService, OrgLevelWatchService } from '../../../../../organization/services';
import { unsubscribe } from '../../../../../core/decorators/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import {TreeView } from '../../../../../components-library/models/tree-view';
import { AppSettingsManageService } from './../../../../../../app/app-settings/services';
import { AppServerConfig } from './../../../../../../app/app-settings/model/app-server-config';
import { PbjNewExportHierarchyHelper } from './pbj-new-export-hierarchy-helper';

export interface ExportRangeType {
  id: string;
  text: string;
}

export interface ExportYear {
  id: number;
  year: number;
}

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-new-export',
  templateUrl: 'pbj-new-export.component.html',
  styleUrls: ['pbj-new-export.component.scss'],
})
export class PbjNewExportComponent extends PbjNewExportHierarchyHelper implements OnInit {

  @Input()
  public showConfigWarning: boolean;

  
  @Input()
  public IsPBJExportCorpLevelEnabled: boolean;
  @ViewChild('form', { static: true })
  public form: NgForm;
  @ViewChild('dateRange', { static: true })
  public dateRange: DaterangeInputComponent;

  @Output()
  public changeExportRequest: EventEmitter<PBJExportRequest>;
  @Output()
  public getDateRangeError: EventEmitter<any> = new EventEmitter(null);
  public request: PBJExportRequest;
  public dateRangeCorrect: boolean;
  public exportRangeTypes: Array<ExportRangeType>;
  public exportRangeType: ExportRangeType;
  public yearsList: ExportYear[];
  public selectedYear: ExportYear;
  private standardQuarters: RangeDates[];
  public data: { [key: string]: Object }[]
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  public orgLevel: OrgLevel;
  public currentPath: OrgLevel[];
  public orgHierarchy: TreeView;
  selOrgIds: any[];
  selIds: string;
  orgIds = [];
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
  })
  public isConfigEnabledPbj: boolean;
  disableYear: boolean = false;
  expstartDate: Date;
  expEndDate: Date;

  constructor(private orgLevelWatchService: OrgLevelWatchService, private applicationStateBusService: ApplicationStateBusService, public appSettingsManageService: AppSettingsManageService) {
    super();
    this.changeExportRequest = new EventEmitter<PBJExportRequest>();
    let year: number = (new Date()).getFullYear();
    this.standardQuarters = [];
    this.standardQuarters.push(this.createQuarter(new Date(year, 0, 1), new Date(year, 2, 31)));
    this.standardQuarters.push(this.createQuarter(new Date(year, 3, 1), new Date(year, 5, 30)));
    this.standardQuarters.push(this.createQuarter(new Date(year, 6, 1), new Date(year, 8, 30)));
    this.standardQuarters.push(this.createQuarter(new Date(year, 9, 1), new Date(year, 11, 31)));

    const exportRangeTexts: StringMap<string> = {
      'Q1': 'Q1:Oct-Dec',
      'Q2': 'Q2:Jan-Mar',
      'Q3': 'Q3:Apr-Jun',
      'Q4': 'Q4:Jul-Sep',
      'Custom': 'Custom'
    };
    this.exportRangeTypes = _.map(PBJExportRange, (expRangeId: string) => ({ id: expRangeId, text: exportRangeTexts[expRangeId] }));

    const amountOfYearsLessCurrent: number = 5;
    const currentYear: number = moment().year();
    const years: number[] = _.range(currentYear - amountOfYearsLessCurrent, currentYear + 4, 1);
    this.yearsList = _.map(years, (year: number) => ({ id: year, year: year }));
    this.selectedYear = this.yearsList[amountOfYearsLessCurrent];
  }

  items: any;

  public ngOnInit(): void {
    this.orgLevelSubscription = this.applicationStateBusService.subscribeToSelectOrgLevel((o: OrgLevel) => {
      if (_.isObject(o) && _.isNumber(o.id) && (!this.orgLevel || this.orgLevel.id !== o.id)) {
        this.orgLevel = o;
        this.orgHierarchy =this.setOrgHierarchy(o, 'exportDialog');
      }
    });

    this.items = this.getItems([this.orgHierarchy]);

    this.request = new PBJExportRequest();
    this.request.exportRange = PBJExportRange.Q1;
    this.request.staffingHoursExportType = PBJExportType.Merge;
    this.request.fiscalYear = this.selectedYear.id;

    this.dateRangeCorrect = true;
    this.assignDates();
    this.exportRangeType = _.find(this.exportRangeTypes, (expRange: ExportRangeType) => expRange.id === this.request.exportRange);
    this.appSettingsManageService.getAppServerConfig().then((config: AppServerConfig) => {
      this.isConfigEnabledPbj = config.IsPBJExportCorpLevelEnabled;
      this.request.includeEmployee = this.isConfigEnabledPbj ? true : false;
      this.request.includeStaffingHours = this.isConfigEnabledPbj ? true : false;
      this.getDateRangeError.emit([this.dateRangeCorrect, this.exportRangeType, this.request]);
    });
  }

  public get canGenerate(): boolean {
    return this.dateRangeCorrect &&
      this.request && (this.request.includeEmployee || this.request.includeStaffingHours) &&
      (!this.exportRangeIsCustom || this.exportRangeIsCustom && this.dateRange && this.dateRange.isValidRange);
  }

  public get exportRangeIsCustom(): boolean {
    return this.request && this.request.exportRange === 'Custom';
  }

  public onSelectYear(year: ExportYear): void {
    if (_.isObject(year)) {
      this.request.fiscalYear = year.id;
      this.updateExportRequest();
    }
  }

  public onChangeExportRange(exportRange: ExportRangeType): void {
    if (_.isObject(exportRange)) {
      this.request.exportRange = exportRange.id as PBJExportRange;
      this.disableYear = exportRange.id === 'Custom' ? true : false;
      if (exportRange.id === 'Custom') {
        this.dateRangeCorrect = this.isValidDateRange(this.request.startDate, this.request.endDate);

        return;
      }
      this.dateRangeCorrect = true;
      this.getDateRangeError.emit([this.dateRangeCorrect, this.exportRangeType, this.request]);
    }
  }

  public onDatesChanged(range: IRangeDates): void {
    const isCorrect: boolean = this.isValidDateRange(range.startDate, range.endDate);
    if (isCorrect) {
      this.request.startDate = range.startDate;
      this.request.endDate = range.endDate;
      this.dateRangeCorrect = true;
      this.getDateRangeError.emit([this.dateRangeCorrect, this.exportRangeType, this.request]);
      this.updateExportRequest();
      return;
    }
    this.dateRangeCorrect = false;
    this.getDateRangeError.emit([this.dateRangeCorrect, this.exportRangeType, this.request]);

    this.updateExportRequest();
  }

  public onValueChanged(value: DateRangeValue): void {
    if (value.valid) {
      let rg: IRangeDates = { startDate: this.request.startDate, endDate: this.request.endDate };
      if (!!value.startDate) {
        this.expstartDate = value.startDate;
      } else {
        this.expEndDate = value.endDate;
      }

      if (this.expstartDate != null) {
        rg.startDate = this.expstartDate;
      }
      if (this.expEndDate != null) {
        rg.endDate = this.expEndDate;
      }
      this.onDatesChanged(rg);
    } else {
      this.dateRangeCorrect = false;
    }
    this.updateExportRequest();
  }

  public updateExportRequest(): void {
    if (this.canGenerate) {
      this.changeExportRequest.emit(this.request);
    } else {
      this.changeExportRequest.emit(null);
    }
    this.getDateRangeError.emit([this.dateRangeCorrect, this.exportRangeType, this.request]);
  }

  private createQuarter(sDate: Date, eDate: Date): RangeDates {
    let dr: RangeDates = new RangeDates();
    sDate.setHours(0, 0, 0);
    eDate.setHours(23, 59, 59);
    dr.startDate = sDate;
    dr.endDate = eDate;
    return dr;
  }

  private isValidDateRange(sDate: Date, eDate: Date): boolean {
    if (sDate > eDate) return false;
    let curYear: number = sDate.getFullYear();
    return _.some(this.standardQuarters, (value: RangeDates) => {
      value.startDate.setFullYear(curYear);
      value.endDate.setFullYear(curYear);
      return value.startDate.getTime() <= sDate.getTime() && value.endDate.getTime() >= eDate.getTime();
    });
  }

  private assignDates(): void {
    let today: Date = new Date();
    let qIndex: number = Math.ceil((today.getMonth() + 1) / 3) - 1;
    let q: RangeDates = this.standardQuarters[qIndex];
    this.request.startDate = new Date(q.startDate);
    this.request.endDate = new Date(q.endDate);
  }

  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }

  getSelectedIds(orgIds) {
    sessionStorage.setItem('selIds', orgIds);
    this.request.orgLevelId = orgIds.toString();
    this.updateExportRequest();
  }
}
