import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { employeeConfig } from '../../employee.config';
import { ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import {
  IApproveShiftRequest,
  IDenyShiftRequest,
  ISendEmployeeShiftSmsRequest
} from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { RecipientInfo, SendShiftSmsRequest } from '../../models/index';
import { EmployeeMessagesMapService } from './employee-messages-map.service';

@Injectable()
export class EmployeeMessagesApiService {
  constructor(
    private apiUtilService: ApiUtilService, private employeeMessagesMapService: EmployeeMessagesMapService) {
  }

  public sendShiftSms(sendSmsRequest: SendShiftSmsRequest): Promise<number> {
    Assert.isNotNull(sendSmsRequest, 'sendSmsRequest');
    Assert.isNotNull(sendSmsRequest.shift, 'sendSmsRequest.shift');
    Assert.isNotNull(sendSmsRequest.unit, 'sendSmsRequest.unit');
    Assert.isNotNull(sendSmsRequest.position, 'sendSmsRequest.position');

    let requestBody: ISendEmployeeShiftSmsRequest = {
      dateOn: dateTimeUtils.convertToDtoString(sendSmsRequest.dateOn),
      recipients: this.employeeMessagesMapService.mapRecipientsInfoDto(sendSmsRequest.recipients),
      shiftId: sendSmsRequest.shift.id,
      unitId: sendSmsRequest.unit.id,
      positionId: sendSmsRequest.position.id,
      messageText: sendSmsRequest.messageText
    };

    const url: string = `${this.getSendShiftSmsApiRoot(sendSmsRequest.originalMessageId)}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, requestBody);

    let promise: Promise<number> = this.apiUtilService.request<number, Meta>(request).then((response: ResponseBody<number, Meta>) => response.data);
    return promise;
  }




  public approveShift(messageId: number, dateOn: Date, employeeId: number, shiftId: number, unitId: number, positionId: number): Promise<any> {
    Assert.isNotNull(messageId, 'messageId');
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(dateOn, 'dateOn');
    Assert.isNotNull(shiftId, 'shiftId');
    Assert.isNotNull(unitId, 'unitId');
    Assert.isNotNull(positionId, 'positionId');

    let requestBody: IApproveShiftRequest = {
      dateOn: dateTimeUtils.convertToDtoString(dateOn),
      employeeId: employeeId,
      shiftId: shiftId,
      unitId: unitId,
      positionId: positionId
    };

    const url: string = `${this.getApproveShiftMessageApiRoot(messageId)}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, requestBody);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<any, Meta>) => response.status);
    return promise;
  }

  public ApprovePartnerShift(messageId: number, partnerId: string, orgLevelId: number): Promise<any> {
    Assert.isNotNull(messageId, 'messageId');
    Assert.isNotNull(partnerId, 'partnerId');
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = `${this.getApprovePartnerShiftMessageApiRoot(messageId, partnerId, orgLevelId)}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, {});

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<any, Meta>) => response);
    return promise;
  }

  public denyShift(messageId: number, dateOn: Date, employeeId: number, shiftId: number, unitId: number, positionId: number): Promise<any> {
    Assert.isNotNull(messageId, 'messageId');
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(dateOn, 'dateOn');
    Assert.isNotNull(shiftId, 'shiftId');
    Assert.isNotNull(unitId, 'unitId');
    Assert.isNotNull(positionId, 'positionId');

    let requestBody: IDenyShiftRequest = {
      dateOn: dateTimeUtils.convertToDtoString(dateOn),
      employeeId: employeeId,
      shiftId: shiftId,
      unitId: unitId,
      positionId: positionId
    };

    const url: string = `${this.getDenyShiftMessageApiRoot(messageId)}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, requestBody);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<any, Meta>) => response.status);
    return promise;
  }

  public denyPartnerShift(messageId: number, partnerId: string, orgLevelId: number): Promise<any> {
    Assert.isNotNull(messageId, 'messageId');
    Assert.isNotNull(partnerId, 'partnerId');
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = `${this.getDenyPartnerShiftMessageApiRoot(messageId, partnerId, orgLevelId)}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, {});

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<any, Meta>) => response.status);
    return promise;
  }

  public read(messageId: number): Promise<any> {
    Assert.isNotNull(messageId, 'messageId');

    const url: string = `${this.getReadMessageApiRoot(messageId)}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, { });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<any, Meta>) => response.status);
    return promise;
  }

  private getSendShiftSmsApiRoot(messageId: number): string {
    return `${this.getMessagesApiRoot()}/${messageId}/${employeeConfig.api.messages.sendShiftSms}`;
  }

  private getApproveShiftMessageApiRoot(messageId: number): string {
    return `${this.getMessagesApiRoot()}/${messageId}/${employeeConfig.api.messages.approveShift}`;
  }

  private getDenyShiftMessageApiRoot(messageId: number): string {
    return `${this.getMessagesApiRoot()}/${messageId}/${employeeConfig.api.messages.denyShift}`;
  }

  private getReadMessageApiRoot(messageId: number): string {
    return `${this.getMessagesApiRoot()}/${messageId}/${employeeConfig.api.messages.read}`;
  }

  private getMessagesApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.messages.root}`;
  }

  private getApprovePartnerShiftMessageApiRoot(messageId: number, partnerId: string, orgLevelId:number): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.partner}/${partnerId}/${employeeConfig.api.orgLevelId}/${orgLevelId}/${employeeConfig.api.shiftAssignment}/${messageId}/${employeeConfig.api.messages.approve}`;
  }

  private getDenyPartnerShiftMessageApiRoot(messageId: number, partnerId: string, orgLevelId:number): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.partner}/${partnerId}/${employeeConfig.api.orgLevelId}/${orgLevelId}/${employeeConfig.api.shiftAssignment}/${messageId}/${employeeConfig.api.messages.deny}`;
  }

}
