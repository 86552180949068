export * from './benefit-class-dialog/benefit-class-dialog.component';
export * from './employee-sections/employee-sections.component';
export * from './employee-sections/employee-sections-basic.component';
export * from './employee-subsection-decorator/employee-subsection-decorator.component';
export * from './employee-sections-personal/index';
export * from './employee-sections-employment/index';
export * from './employee-sections-employment/employee-sections-pay-cycle-conf/employee-sections-pay-cycle-conf.component';
export * from './employee-sections-performance/employee-sections-attendance-points-definition/employee-sections-attendance-points-definition.component';
export * from './employee-sections-audit/employee-sections-note-edit/employee-sections-note-edit.component';
export * from './employee-sections-schedule/index';
export * from './employee-sections-accruals/index';
export * from './employee-sections-benefits-management/index';
import { BenefitClassDialogComponent } from './benefit-class-dialog/benefit-class-dialog.component';
import { EmployeeSectionsComponent } from './employee-sections/employee-sections.component';
import { EmployeeSubSectionsDecoratorComponent } from './employee-subsection-decorator/employee-subsection-decorator.component';
import { componentsPersonalSection } from './employee-sections-personal/index';
import { componentsEmploymentSection } from './employee-sections-employment/index';
import { componentsPerformanceSection } from './employee-sections-performance/index';
import { componentsAuditSection } from './employee-sections-audit/index';
import { componentsCustomSection } from './employee-sections-custom/index';
import { componentsScheduleSection } from './employee-sections-schedule/index';
import { componentsAccrualsSection } from './employee-sections-accruals/index';
import { componentsBenefitsManagementSection } from './employee-sections-benefits-management/index';
import { EmployeeSectionsPayCycleConfComponent } from './employee-sections-employment/employee-sections-pay-cycle-conf/employee-sections-pay-cycle-conf.component';
import { EmployeeSectionsAttendancePointsDefinitionComponent } from './employee-sections-performance/employee-sections-attendance-points-definition/employee-sections-attendance-points-definition.component';
// import { ResetBalanceDialogComponent } from './employee-sections-accruals/employee-sections-transactions/reset-balance-dialog/reset-balance-dialog.component';
export var exportComponents = [
    EmployeeSectionsComponent,
    EmployeeSectionsPayCycleConfComponent,
    EmployeeSectionsAttendancePointsDefinitionComponent,
    BenefitClassDialogComponent
].concat(componentsPersonalSection, componentsEmploymentSection, componentsPerformanceSection, componentsAuditSection, componentsCustomSection, componentsScheduleSection, componentsAccrualsSection, componentsBenefitsManagementSection);
export var components = [
    EmployeeSubSectionsDecoratorComponent
].concat(exportComponents);
