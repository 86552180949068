import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { GridComponent, RowArgs, SelectionEvent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { timeAndAttendanceConfig } from '../../../time-and-attendance.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { IndividualTimecardsManagementService } from '../../../services/index';
import { IndividualTimecardsContainer, IndividualTimecardsDay, TimecardsAction, TimecardsActionCmd, IndividualTimecardsState, TimecardsColumnState } from '../../../models/index';
var IndividualTimecardsGridComponent = /** @class */ (function () {
    function IndividualTimecardsGridComponent(managementService) {
        this.aggregates = [
            { field: 'workedHours', aggregate: 'sum' },
            { field: 'totalHours', aggregate: 'sum' },
            { field: 'regularPay', aggregate: 'sum' },
            { field: 'overtimeAndExtra', aggregate: 'sum' },
            { field: 'totalPay', aggregate: 'sum' },
        ];
        this.total = {};
        this.managementService = managementService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.group = [{ field: 'week', aggregates: this.aggregates }];
        this.gridState.state.sort = [{ field: 'date', dir: 'asc' }];
        this.highlightedRows = [];
    }
    IndividualTimecardsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.taConfig = timeAndAttendanceConfig;
        this.stateChangedSubscription = this.managementService.onLoaded$.subscribe(function (container) {
            _this.container = container;
            _this.records = container.records;
            _this.refreshGrid();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.actionSubscription = this.managementService.onActionCmd$.subscribe(function (v) {
            if (v.cmd === TimecardsActionCmd.excelExport) {
                _this.grid.saveAsExcel();
            }
            if (v.cmd === TimecardsActionCmd.pdfExport) {
                _this.grid.saveAsPDF();
            }
        });
        this.stateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.state = state;
        });
    };
    IndividualTimecardsGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualTimecardsGridComponent.prototype.getUnit = function (field, value) {
        if (!value) {
            return null;
        }
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].unit : null;
    };
    IndividualTimecardsGridComponent.prototype.isVisible = function (field, payCode) {
        if (!this.state) {
            return false;
        }
        var columnState = payCode ? this.state.payCodeColumns : this.state.empColumns;
        if (!columnState || !columnState.columnsMap[field]) {
            return false;
        }
        var column = columnState.columnsMap[field];
        if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
            return false;
        }
        return column.displayed;
    };
    IndividualTimecardsGridComponent.prototype.onSelectionChanged = function (selection) {
        var selectedRow = _.head(selection.selectedRows);
        var deselectedRow = _.head(selection.deselectedRows);
        var prevSelectedRecord = null;
        var path = 'dataItem.date';
        if (deselectedRow) {
            prevSelectedRecord = this.stringifyDate(_.get(deselectedRow, path, null));
            this.highlightedRows.length = 0;
        }
        if (selectedRow) {
            var currentSelectedRecord = this.stringifyDate(_.get(selectedRow, path, null));
            if (prevSelectedRecord !== currentSelectedRecord) {
                this.highlightedRows.push(currentSelectedRecord);
            }
        }
    };
    IndividualTimecardsGridComponent.prototype.isRowSelected = function () {
        var _this = this;
        return function (event) {
            return _.indexOf(_this.highlightedRows, _this.stringifyDate(event.dataItem.date)) !== -1;
        };
    };
    IndividualTimecardsGridComponent.prototype.getWidth = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 50;
    };
    IndividualTimecardsGridComponent.prototype.getFilter = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
    };
    IndividualTimecardsGridComponent.prototype.stringifyDate = function (date) {
        return moment(date).format(appConfig.dateTimeFormat);
    };
    IndividualTimecardsGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.state.group.forEach(function (group) { return group.aggregates = _this.aggregates; });
        this.gridState.view = process(this.records, this.gridState.state);
        this.total = aggregateBy(this.records, this.aggregates);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsGridComponent.prototype, "stateChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsGridComponent.prototype, "actionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsGridComponent.prototype, "stateSubscription", void 0);
    return IndividualTimecardsGridComponent;
}());
export { IndividualTimecardsGridComponent };
