import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import { IClientMessage, ClientMessage } from '../../models/index';



@Injectable()
export class ClientMessageMapService {

  public mapToClientMessage(dto: IClientMessage, orgLevelId: number): ClientMessage {
    const data = new ClientMessage();
    data.id = dto.id;
    if (data.id === 0) {
      data.messageHash = btoa (data.text + orgLevelId.toString(10));
    }
    data.createdOn = dateTimeUtils.convertFromDtoDateTimeString(dto.createdOn);
    data.header = '';
    data.level = _.toLower(dto.level);
    data.title = dto.title;
    data.text = dto.text;
    data.clientSpecific = dto.clientSpecific;
    return data;
  }

  public mapClientMessages(dto: IClientMessage[],  orgLevelId: number): ClientMessage[] {
    return _.map(dto, (d: IClientMessage) => this.mapToClientMessage(d, orgLevelId));
  }

}
