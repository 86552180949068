<section *ngIf="!hasScheduleAbsence && partialAbsence" class="partial-absence" provide-parent-form>
    <span class="title">Partial Absence</span>
    <div class="section-body time-group">
        <slx-input-decorator>
            <slx-dropdown-input slx-input placeholder="Absence" required="true"
            [(ngModel)]="partialAbsence.scheduleAbsence" name="absence{{index}}"
             valueField="code" titleField="description" [options]="absences"
             (ngModelChange)="onPartialAbsenceChanged ()"></slx-dropdown-input>
            <span errorMessage for="required">Absence is required</span>
        </slx-input-decorator>
        <slx-input-decorator class="partial-absence-hours-field">
            <slx-kendo-number slx-input
              [(ngModel)]="partialAbsence.hours"
              (ngModelChange)="shiftPartialAbsenceTimeChanged()"
              [min]="0"
              [slxMin]="0"
              [max]="shiftHours"
              [slxMax]="shiftHours"
              [decimals]="2"
              [required]="true"
              [readonly]="!shift"
              placeholder="Absence Hours"
              name="paHours{{index}}"
            ></slx-kendo-number>
            <span errorMessage for="min">Hours should be greater than zero</span>
            <span errorMessage for="max">Hours should be less than shift total hours</span>
            <span errorMessage for="required">Hours are required</span>
        </slx-input-decorator>
        <div class="toggle-wrapper">
            <span class="left-label">Attach To Start</span>
            <slx-checkbox-toggle [(ngModel)]="partialAbsToEnd" name="paType{{index}}" class="toggle" className="toggle-right" caption=""
                checkedText=">" uncheckedText="&lt;" (ngModelChange)="onPartialAbsenceAttachmentChanged ()"></slx-checkbox-toggle>
            <span class="right-label">Attach To End</span>
            <span class="slx-input-hint">{{partialAbsence.startDate | amDateFormat: appConfig.timeFormat}}-{{partialAbsence.endDate | amDateFormat: appConfig.timeFormat}}</span>
        </div>
    </div>
</section>