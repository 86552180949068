import { Component, Provider } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';


@Component({
  moduleId: module.id,
  selector: 'slx-roles-legend-dialog',
  templateUrl: 'roles-legend-dialog.component.html',
  styleUrls: ['roles-legend-dialog.component.scss']
})
export class RolesLegendDialogComponent {
  public options: DialogOptions;
  public dialogResult: boolean;

  private modalService: ModalService;

  public static openDialog(modalService: ModalService, callback: (result: boolean, cmd: any) => void): RolesLegendDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 350;
    dialogOptions.width = 350;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];
    let header: string = `Roles Configuration Legend`;
    let dialog: RolesLegendDialogComponent = modalService.globalAnchor
      .openDialog(RolesLegendDialogComponent, header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, null);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }


  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
