export interface IBenefitLineInfo {
    id: number;

    name: string;
    benefitGroupName: string;
    benefitGroupId: number;
    benefitProviderId: number;
    modifiedAt: string;
    modifiedBy: string;
    endDate: string;
    addedAt: string;
    addedBy: string;
}

  export class BenefitLineInfo {
    public id: number;
    public name: string;
    public benefitGroupName: string;
    public benefitGroupId: number;

    public benefitProviderId: number;
    public modifiedAt: Date;
    public filterModifiedAt: Date;
    public modifiedBy: string;
    public endDate: Date;
  }
