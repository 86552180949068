import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnChanges, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { DateRange } from '../../../../../core/models/index';
import { LMManagementService, LMRosterManagementService } from '../../../services/index';
var LMCalendarGridHeaderComponent = /** @class */ (function () {
    function LMCalendarGridHeaderComponent(manService, manRosterService) {
        this.manService = manService;
        this.manRosterService = manRosterService;
        this.dateRange = new DateRange(null, null);
        this.daysLength = 1;
        this.showNavButtons = true;
        this.days = [];
        this.dateFormatShort = 'EEE, M/d';
        this.dateFormatForSmallDevice = 'M/d';
        this.subscriptions = {};
    }
    LMCalendarGridHeaderComponent.prototype.ngOnChanges = function () {
        var hasDaysLength = _.isFinite(this.daysLength);
        var hasRange = _.isObjectLike(this.dateRange) && _.isDate(this.dateRange.startDate);
        if (hasDaysLength && hasRange) {
            this.renderRange(this.dateRange.startDate, this.daysLength);
        }
    };
    LMCalendarGridHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.modeView = this.manRosterService
            .subscribeToCalenderMode(function (isDay) {
            _this.isDayView = isDay;
        });
    };
    LMCalendarGridHeaderComponent.prototype.ngOnDestroy = function () { };
    LMCalendarGridHeaderComponent.prototype.onNavButtonClick = function (isNext) {
        this.manService.navButtonClick(isNext);
    };
    LMCalendarGridHeaderComponent.prototype.renderRange = function (sDate, daysLength) {
        var days = [];
        var prevDate = null;
        for (var i = 1; daysLength >= i; i++) {
            var date = null;
            if (i === 1) {
                date = new Date(sDate.getTime());
            }
            else {
                var pDate = new Date(prevDate.getTime());
                date = new Date(pDate.setDate(pDate.getDate() + 1));
            }
            prevDate = date;
            days.push(date);
        }
        this.days = days;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], LMCalendarGridHeaderComponent.prototype, "subscriptions", void 0);
    return LMCalendarGridHeaderComponent;
}());
export { LMCalendarGridHeaderComponent };
