import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnChanges, OnDestroy, SimpleChanges, EventEmitter, ElementRef } from '@angular/core';
import { unsubscribeAll } from '../../../core/decorators/index';
import { AttachmentsManagementService } from '../../services/index';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../common/index';
var AttachmentsComponent = /** @class */ (function () {
    function AttachmentsComponent(modalService, attachmentService) {
        this.modalService = modalService;
        this.attachmentService = attachmentService;
        this.canUpload = true;
        this.canDownload = true;
        this.canDelete = true;
        this.showTitle = false;
        this.maxFiles = 10;
        this.maxFileSizeBytes = 5485760;
        this.showFileTypeAsTooltip = false;
        this.acceptedFileTypes = [
            '.csv', '.doc', '.docm', '.docx', '.txt', '.xls', '.xlsm', '.xlsx'
        ];
        this.addedFiles = new EventEmitter();
        this.deletedFile = new EventEmitter();
        this.deletedAttachment = new EventEmitter();
        this.downloadAttachment = new EventEmitter();
        this.files = [];
        this.errors = {};
        this.subscriptions = {};
        this.storedAttachments = [];
        this.storedFiles = [];
        this.m_disabled = false;
    }
    Object.defineProperty(AttachmentsComponent.prototype, "disabled", {
        get: function () {
            return this.m_disabled;
        },
        set: function (v) {
            this.m_disabled = !!v;
            if (this.m_disabled && _.isObjectLike(this.inputFile) && _.isObjectLike(this.inputFile.nativeElement)) {
                this.resetErrors(this.inputFile.nativeElement);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttachmentsComponent.prototype, "attachments", {
        set: function (v) {
            if (_.isArray(v)) {
                this.storedAttachments = v;
                this.processAttachments();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttachmentsComponent.prototype, "filesToAttach", {
        set: function (v) {
            if (_.isArray(v)) {
                this.storedFiles = v;
                this.processAttachments();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttachmentsComponent.prototype, "hasFiles", {
        get: function () {
            return _.size(this.files) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttachmentsComponent.prototype, "acceptedFileTypesInputFormat", {
        get: function () {
            return this.attachmentService.acceptedFileTypesInputFormat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttachmentsComponent.prototype, "acceptedFileTypesReadable", {
        get: function () {
            return this.attachmentService.acceptedFileTypesReadable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AttachmentsComponent.prototype, "maxFileSize", {
        get: function () {
            return this.attachmentService.maxFileSize;
        },
        enumerable: true,
        configurable: true
    });
    AttachmentsComponent.prototype.getTooltip = function () {
        return "(Allowed file types: " + this.acceptedFileTypesReadable + ", " + this.maxFileSize + " Max)";
    };
    AttachmentsComponent.prototype.ngOnChanges = function (changes) {
        this.attachmentService.init(this.maxFiles, this.maxFileSizeBytes, this.acceptedFileTypes);
    };
    AttachmentsComponent.prototype.ngOnInit = function () { };
    AttachmentsComponent.prototype.ngOnDestroy = function () { };
    AttachmentsComponent.prototype.isDownloadable = function (file) {
        return this.canDownload && file.isAttachment;
    };
    AttachmentsComponent.prototype.onFileChange = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileList, result, readFiles, files;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.disabled) return [3 /*break*/, 3];
                        fileList = _.get(event, 'target.files');
                        result = this.attachmentService.validateFiles(fileList, event);
                        if (!(result.files.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.attachmentService.readAddedFiles(result.files)];
                    case 1:
                        readFiles = _a.sent();
                        files = this.attachmentService.mapToFiles(readFiles);
                        this.files = this.files.concat(files);
                        this.addedFiles.emit(readFiles);
                        _a.label = 2;
                    case 2:
                        this.errors = result.errors;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AttachmentsComponent.prototype.onResetErrors = function (event) {
        this.resetErrors(event.target);
    };
    AttachmentsComponent.prototype.onDownloadFile = function (file) {
        if (this.isDownloadable(file)) {
            this.downloadAttachmentFile(file);
        }
    };
    AttachmentsComponent.prototype.onClickDelete = function (file) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Delete Attachment', 'Are you sure you want to delete the attachment?', this.modalService, function (isDelete) {
            if (isDelete) {
                _this.onDeleteAttachment(file);
            }
        }, options);
    };
    AttachmentsComponent.prototype.onDeleteAttachment = function (file) {
        if (!this.disabled) {
            if (file.isAttachment) {
                this.deleteAttachment(file);
            }
            else {
                this.deleteFile(file);
            }
        }
    };
    AttachmentsComponent.prototype.downloadAttachmentFile = function (file) {
        this.downloadAttachment.emit(file.sourceItem);
    };
    AttachmentsComponent.prototype.deleteAttachment = function (file) {
        var files = this.files.concat();
        _.remove(files, function (f) { return _.isFinite(f.id) && f.id === file.id; });
        this.files = files;
        this.deletedAttachment.emit(file.sourceItem);
    };
    AttachmentsComponent.prototype.deleteFile = function (file) {
        var files = this.files.concat();
        _.remove(files, function (f) { return !_.isFinite(f.id) && f.fileName === file.fileName; });
        this.files = files;
        this.deletedFile.emit(file.sourceItem);
    };
    AttachmentsComponent.prototype.processAttachments = function () {
        var attachments = this.attachmentService.mapToFiles(this.storedAttachments);
        var files = this.attachmentService.mapToFiles(this.storedFiles);
        this.files = attachments.concat(files);
    };
    AttachmentsComponent.prototype.resetErrors = function (elem) {
        this.attachmentService.resetInput(elem);
        this.errors = {};
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AttachmentsComponent.prototype, "subscriptions", void 0);
    return AttachmentsComponent;
}());
export { AttachmentsComponent };
