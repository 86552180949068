import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, State } from '@progress/kendo-data-query';

import { VacationPlanEmployeeSummary, VacationPlanEmployeeSummaryRecord } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-vacation-employee-summary',
  templateUrl: 'vacation-employee-summary.component.html',
  styleUrls: ['vacation-employee-summary.component.scss']
})
export class VacationEmployeeSummaryComponent implements OnInit {
  @Input()
  public year: number;

  @Input()
  public set employeeSummary(employeeSummary: VacationPlanEmployeeSummary) {
    this.gridData = employeeSummary;
    if (this.gridData) {
      this.refreshGrid();
    }
  }

  public appConfig: IApplicationConfig;
  public gridData: VacationPlanEmployeeSummary;
  public gridState: State;
  public gridView: GridDataResult;

  constructor() {
    this.gridState = {
      skip: undefined,
      take: undefined,
      filter: undefined,
      sort: [{ field: 'date', dir: 'asc' }],
      group: undefined
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.sort = sort;
    this.refreshGrid();
  }

  private refreshGrid(): void {
    if (!this.gridData) {
      this.gridView = null;
      return;
    }
    let sortedRecords: VacationPlanEmployeeSummaryRecord[] = orderBy(this.gridData.records, this.gridState.sort);
    this.gridView = {
      data: sortedRecords,
      total: sortedRecords.length
    };
  }
}
