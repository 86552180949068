import { ITimelineItem } from '../../../common/models/index';
import { ArrivalsDeparturesDetailsRecord } from './arrivals-departures-details-record';
import { Arrival } from './arrival';
import { Departure } from './departure';


export class ArrivalsDeparturesTimelineItemType {
  public static scheduleStart: ArrivalsDeparturesTimelineItemTypeDefinition = 'ScheduleStart';
  public static scheduleEnd: ArrivalsDeparturesTimelineItemTypeDefinition = 'ScheduleEnd';
  public static punchIn: ArrivalsDeparturesTimelineItemTypeDefinition = 'PunchIn';
  public static punchOut: ArrivalsDeparturesTimelineItemTypeDefinition = 'PunchOut';
  public static late: ArrivalsDeparturesTimelineItemTypeDefinition = 'Late';
  public static ot: ArrivalsDeparturesTimelineItemTypeDefinition = 'Ot';
}

export type ArrivalsDeparturesTimelineItemTypeDefinition = 'ScheduleStart'
  | 'ScheduleEnd'
  | 'PunchIn'
  | 'PunchOut'
  | 'Late'
  | 'Ot'
;

export type ArrivalsDeparturesTimelineViewModeDefinition = 'Simple'
  | 'FitToWidth'
  | 'GroupByShift'
;

export class ArrivalsDeparturesTimelineItem implements ITimelineItem {
  public type: ArrivalsDeparturesTimelineItemType;
  public date: Date;
  public position: number;
  public records: ArrivalsDeparturesDetailsRecord[];
  public arrival: Arrival;
  public departure: Departure;
  public minDistanceRelated?: boolean;
  public lateBageNeighbor: boolean;
  public otBageNeighbor: boolean;

  constructor(type: ArrivalsDeparturesTimelineItemType) {
    this.type = type;
  }
}
