import { Pipe, Injectable, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { dateTimeUtils } from '../utils/index';
@Pipe({
  name: 'slxDuration',
  pure: true
})
@Injectable()
export class SlxDurationPipe implements PipeTransform {
  public transform(obj: any, unit: string, substitute: string = null): any {
    if (obj === null || obj === undefined) {
      return substitute;
    }
    if (!moment.isDuration(obj)) {
      return substitute;
    }
    let duration: moment.Duration = obj;
    switch (unit) {
      case 'hours':
        return dateTimeUtils.getDurationTotalHours(duration);
      default:
        throw Error(`SlxDurationPipe unknown unit ${unit}`);
    }
  }
}
