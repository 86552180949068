import * as tslib_1 from "tslib";
import { Subject } from "rxjs";
import { IdealScheduleMapService } from "./ideal-schedule-map.service";
import { IdealScheduleStateService } from "./ideal-schedule.state.service";
import { CurrentOrganizationDetails, ExcelData, TemplateValidator, UIException } from "../../models/ideal-schedule/ideal-schedule-import-template-validator";
import * as _ from "lodash";
import * as moment from "moment";
import { v4 as UUID } from 'uuid';
import { IdealScheduleApiService } from "./ideal-schedule-api.service";
import * as i0 from "@angular/core";
import * as i1 from "./ideal-schedule-map.service";
import * as i2 from "./ideal-schedule.state.service";
import * as i3 from "./ideal-schedule-api.service";
var IdealScheduleTemplateExcelValidationService = /** @class */ (function () {
    function IdealScheduleTemplateExcelValidationService(mapService, stateService, api) {
        var _this = this;
        this.mapService = mapService;
        this.stateService = stateService;
        this.api = api;
        this.expectedHeaders = [
            'Facility',
            'Department',
            'ConfigurationType',
            'CensusType',
            'Position',
            'AcuityTypes',
            'ShiftGroup',
            'Shift',
            'Unit',
            'CensusRangeFrom',
            'CensusRangeTo',
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
        ];
        this.dayOfWeek = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ];
        this.censusRanges = [
            "Range",
            "Total"
        ];
        this.validatorObj = {
            configurationType: [
                'Shift',
                'Shift & Unit',
                'Shift Group'
            ],
            censusType: [
                'Fixed',
                'Range',
                'Total'
            ]
        };
        this._excelData = [];
        this._exceptionList = [];
        this._apiExceptionList = [];
        this.isFileExist = false;
        this._excelData$ = new Subject();
        this._exceptionListTrigger$ = new Subject();
        this._uiExceptionTrigger$ = new Subject();
        this._apiExceptionTrigger$ = new Subject();
        this._excelDataSubscription = this._excelData$.subscribe(function (data) {
            var currentOrganization = _this.currentOrganizationDetails();
            _this._excelData = _this.mapService.mapExcelResultData(data[0].excelData);
            _this.validateExcelData(data[0].excelHeader, currentOrganization, _this._excelData);
        });
        this._exceptionListSubscription = this._exceptionListTrigger$.subscribe(function (data) {
            _this._exceptionList = data[0];
            _this._uiExceptionTrigger$.next(_this._exceptionList);
            if (_this._exceptionList.length === 0) {
                _this.stateService.state.isLoading = true;
                _this.importDataValidation(_this.stateService.orgLevelId, _this._excelData);
            }
        });
    }
    IdealScheduleTemplateExcelValidationService.prototype.importDataValidation = function (orgId, uploadedData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.importTemplatePositionsValidation(orgId, uploadedData).then(function (data) {
                            _this._apiExceptionList = data;
                            _this.isFileExist = (_this._exceptionList.length === 0 && _this._apiExceptionList.length === 0) ? true : false;
                            _this._apiExceptionTrigger$.next(_this._apiExceptionList);
                        }).catch(function (err) {
                            _this.isFileExist = false;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    IdealScheduleTemplateExcelValidationService.prototype.validateExcelData = function (headers, orgDetail, uploadedData) {
        this._exceptionList = [];
        if (headers == undefined || !headers) {
            var obj = this.setException('ERR001', 'File Contain No Data');
            this._exceptionList.push(obj);
        }
        if (uploadedData.length === 0) {
            var obj = this.setException('ERR002', 'No Records Available');
            this._exceptionList.push(obj);
        }
        if (this.isArrayEqual(headers, this.expectedHeaders) || !this.isArrayEqual(headers, this.expectedHeaders, true)) {
            var obj = this.setException('ERR003', 'File Contain Incorrect Header Spelling or Header Order');
            this._exceptionList.push(obj);
        }
        "";
        if (this._exceptionList.length === 0) {
            var fileData = trailingSpaceRemover(uploadedData);
            this.validateOrganizationAndDepartment(orgDetail, fileData);
            if (this._exceptionList.length === 0) {
                this.validateConfigTypeAndCensusType(uploadedData);
                if (this._exceptionList.length === 0) {
                    this.validateFields(uploadedData);
                }
            }
        }
        var isFileValid = this._exceptionList.length === 0 ? true : false;
        this._exceptionListTrigger$.next([this._exceptionList, isFileValid, uploadedData]);
    };
    IdealScheduleTemplateExcelValidationService.prototype.isArrayEqual = function (arr1, arr2, strictOrder) {
        if (strictOrder === void 0) { strictOrder = false; }
        var trimHeader = stringTrailingSpaceRemover(arr1);
        if (strictOrder) {
            return trimHeader.join() === arr2.join();
        }
        else {
            var diff = _.difference(arr1, arr2);
            return diff.length > 0;
        }
    };
    IdealScheduleTemplateExcelValidationService.prototype.validateOrganizationAndDepartment = function (orgDetail, uploadedData) {
        var _this = this;
        if (!uploadedData)
            return;
        uploadedData.filter(function (x, i) {
            if (!(orgDetail.organizationName == x.Facility)) {
                var ind = i + 2;
                var errorMessage = "Organization name mismatch - wrong data found on " + _this.numberPower(ind) + " Row";
                var obj = _this.setException('ERR004', errorMessage, x);
                _this._exceptionList.push(obj);
            }
            if (!(orgDetail.departmentName == x.Department)) {
                var ind = i + 2;
                var errorMessage = "Department name mismatch - Wrong data found on " + _this.numberPower(ind) + " Row";
                var obj = _this.setException('ERR005', errorMessage, x);
                _this._exceptionList.push(obj);
            }
        });
        return this._exceptionList;
    };
    IdealScheduleTemplateExcelValidationService.prototype.validateConfigTypeAndCensusType = function (uploadedData) {
        var _this = this;
        if (!uploadedData)
            return;
        uploadedData.filter(function (x, i) {
            if (!_this.validatorObj.censusType.includes(x.CensusType)) {
                var ind = i + 2;
                var errorMessage = "Wrong Census Range Type found on " + _this.numberPower(ind) + " Row";
                var obj = _this.setException('ERR006', errorMessage, x);
                _this._exceptionList.push(obj);
            }
            if (!_this.validatorObj.configurationType.includes(x.ConfigurationType)) {
                var ind = i + 2;
                var errorMessage = "Wrong Configuration Type found on " + _this.numberPower(ind) + " Row";
                var obj = _this.setException('ERR006', errorMessage, x);
                _this._exceptionList.push(obj);
            }
        });
        return this._exceptionList;
    };
    IdealScheduleTemplateExcelValidationService.prototype.validateFields = function (uploadedData) {
        if (this._exceptionList.length === 0) {
            this.validateDayOfTheWeekColumn(uploadedData);
            this.validateCensusRanges(uploadedData);
        }
    };
    IdealScheduleTemplateExcelValidationService.prototype.validateDayOfTheWeekColumn = function (uploadedData) {
        var _this = this;
        if (!uploadedData)
            return;
        uploadedData.filter(function (x, i) {
            for (var item in x) {
                if (_this.dayOfWeek.includes(item)) {
                    if (_.isUndefined(x[item])) {
                        var ind = i + 2;
                        var errorMessage = "Empty Cells found on " + item + " Column " + _this.numberPower(ind) + " Row";
                        var obj = _this.setException('ERR007', errorMessage, x);
                        _this._exceptionList.push(obj);
                    }
                    if (_.isString(x[item]) || !Number.isInteger(x[item])) {
                        var ind = i + 2;
                        var errorMessage = "Days of the week column should only contain any numerical characters found on " + item + " Column " + _this.numberPower(ind) + " Row";
                        var obj = _this.setException('ERR008', errorMessage, x);
                        _this._exceptionList.push(obj);
                    }
                    if (_.gt(x[item], 99)) {
                        var ind = i + 2;
                        var errorMessage = "Day of the week cell value should be less than or equal to 99 " + _this.numberPower(ind) + " Row";
                        var obj = _this.setException('ERR009', errorMessage, x);
                        _this._exceptionList.push(obj);
                    }
                    if (_.lt(x[item], 0)) {
                        var ind = i + 2;
                        var errorMessage = "Day of the week cell value should be greater than or equal to 0 " + _this.numberPower(ind) + " Row";
                        var obj = _this.setException('ERR009', errorMessage, x);
                        _this._exceptionList.push(obj);
                    }
                }
            }
        });
        return this._exceptionList;
    };
    IdealScheduleTemplateExcelValidationService.prototype.validateCensusRanges = function (uploadedData) {
        var _this = this;
        if (!uploadedData)
            return;
        uploadedData.filter(function (x, i) {
            if (_this.censusRanges.includes(x.CensusType)) {
                if (_.isUndefined(x.CensusRangeFrom)) {
                    var ind = i + 2;
                    var errorMessage = "Empty Cells found on CensusRangeFrom Column " + _this.numberPower(ind) + " Row";
                    var obj = _this.setException('ERR007', errorMessage, x);
                    _this._exceptionList.push(obj);
                }
                if (_.isUndefined(x.CensusRangeTo)) {
                    var ind = i + 2;
                    var errorMessage = "Empty Cells found on CensusRangeTo Column " + _this.numberPower(ind) + " Row";
                    var obj = _this.setException('ERR007', errorMessage, x);
                    _this._exceptionList.push(obj);
                }
                if (_.isString(x.CensusRangeFrom) || !Number.isInteger(x.CensusRangeFrom)) {
                    var ind = i + 2;
                    var errorMessage = "CensusRangesFrom column should only contain any numerical characters " + _this.numberPower(ind) + " Row";
                    var obj = _this.setException('ERR008', errorMessage, x);
                    _this._exceptionList.push(obj);
                }
                if (_.isString(x.CensusRangeTo) || !Number.isInteger(x.CensusRangeTo)) {
                    var ind = i + 2;
                    var errorMessage = "CensusRangesTo column should only contain any numerical characters " + _this.numberPower(ind) + " Row";
                    var obj = _this.setException('ERR008', errorMessage, x);
                    _this._exceptionList.push(obj);
                }
                if (_.gt(x.CensusRangeTo, 999)) {
                    var ind = i + 2;
                    var errorMessage = "CensusRangeTo Column cell value should be less than or equal to 999 " + _this.numberPower(ind) + " Row";
                    var obj = _this.setException('ERR009', errorMessage, x);
                    _this._exceptionList.push(obj);
                }
                if (_.lt(x.CensusRangeFrom, 0)) {
                    var ind = i + 2;
                    var errorMessage = "CensusRangeFrom Column cell value should be greater than or equal to 0 " + _this.numberPower(ind) + " Row";
                    var obj = _this.setException('ERR009', errorMessage, x);
                    _this._exceptionList.push(obj);
                }
            }
        });
        return this._exceptionList;
    };
    IdealScheduleTemplateExcelValidationService.prototype.numberPower = function (index) {
        return moment.localeData().ordinal(index);
    };
    IdealScheduleTemplateExcelValidationService.prototype.setException = function (errorCode, errorMessage, item) {
        var data = new UIException();
        data.id = item ? item.UniqueId : UUID();
        data.errorCode = errorCode;
        data.errorMessage = errorMessage;
        return data;
    };
    IdealScheduleTemplateExcelValidationService.prototype.currentOrganizationDetails = function () {
        var _this = this;
        var organizationData = JSON.parse(sessionStorage.getItem('Organizations'));
        var childData = _.filter(organizationData, function (x) { return x.id == _this.stateService.orgLevelId; });
        var parentOrganization = _.filter(this.stateService.organizationDetails, function (x) { return x.organizationId == childData[0].organizationId; });
        var data = new CurrentOrganizationDetails();
        data.departmentId = childData[0].id;
        data.departmentName = childData[0].name;
        data.organizationId = +parentOrganization[0].orgLevelId;
        data.organizationName = parentOrganization[0].organizationName;
        return data;
    };
    IdealScheduleTemplateExcelValidationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IdealScheduleTemplateExcelValidationService_Factory() { return new IdealScheduleTemplateExcelValidationService(i0.ɵɵinject(i1.IdealScheduleMapService), i0.ɵɵinject(i2.IdealScheduleStateService), i0.ɵɵinject(i3.IdealScheduleApiService)); }, token: IdealScheduleTemplateExcelValidationService, providedIn: "root" });
    return IdealScheduleTemplateExcelValidationService;
}());
export { IdealScheduleTemplateExcelValidationService };
function trailingSpaceRemover(data) {
    var result = [];
    data.map(function (x) {
        var newObj = {};
        Object.keys(x).forEach(function (y) {
            var key = y.trim();
            newObj[key] = x[y];
        });
        result.push(newObj);
    });
    return result;
}
function stringTrailingSpaceRemover(headers) {
    return headers.map(function (x) { return _.isNaN(x) ? x.trim() : x; });
}
