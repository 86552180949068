<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  [reorderable]="true"
  (dataStateChange)="gridState.dataStateChange($event)"

  [slxKendoGridFiltersState]="gridState"
  (kendoGridStateChanged)="onGridStateChanged($event)"
>

<kendo-grid-column [sortable]="false" [filterable]="false" width="38" headerClass="overflow-visible-cell">
    <ng-template kendoGridHeaderTemplate>
      <div>
        <input slxTooltip="Select/Deselect All" tipPosition="right" type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected()" >
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange()">
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
  title="Employee Name"
  field="employee.name"
  width="180"
  [kendoGridColumnFilterableOn]="['desktop']"
  [kendoGridColumnSortableOn]="['desktop']"
>
  <ng-template kendoGridHeaderTemplate>
    <span slxKendoGridHeaderTooltip>Employee Name</span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span class="wc-emp">
      <img class="wc-emp__img" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
      <span class="wc-emp__text">{{ dataItem.employee?.name }}</span>
    </span>
  </ng-template>
</kendo-grid-column>


  <kendo-grid-column
    title="Facility"
    field="organization.name"
    width="150"
    filter="text"
    [hidden]="!isVisible('organization')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Facility</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization?.name }}</ng-template>
  </kendo-grid-column>


  <kendo-grid-column
  title="Department"
  field="department.name"
  [hidden]="!isVisible('department')"
  [width]="150"
  filter="text"
  [kendoGridColumnFilterableOn]="['desktop']"
  [kendoGridColumnSortableOn]="['desktop']"
>
  <ng-template kendoGridHeaderTemplate>
    <span slxKendoGridHeaderTooltip>Department</span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department?.name }}</ng-template>
</kendo-grid-column>


  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Position"
    field="position.name"
    width="180"
    filter="text"
    [hidden]="!isVisible('position')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Osha Injury Type"
    field="oshaInjuryType.description"
    width="180"
    filter="text"
    [hidden]="!isVisible('oshaInjuryType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Osha Injury Type</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.oshaInjuryType?.description }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
  title="Occurrence Date"
  field="incidentDateTime"
  width="120"
  filter="date"
  [hidden]="!isVisible('incidentDateTime')"
  [kendoGridColumnFilterableOn]="['desktop']"
  [kendoGridColumnSortableOn]="['desktop']"
>
  <ng-template kendoGridHeaderTemplate>
    <span slxKendoGridHeaderTooltip>Occurrence Date</span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span class="wc-summary-link" (click)="onOpenReport(dataItem)">{{ dataItem.incidentDateTime | amDateFormat: appConfig.dateFormat }}</span>
  </ng-template>
</kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Body Part"
    field="bodyPart.description"
    width="120"
    filter="text"
    [hidden]="!isVisible('bodyPart')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Body Part</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.bodyPart?.description }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Days Lost"
    field="oshaDaysLost"
    width="120"
    filter="text"
    [hidden]="!isVisible('oshaDaysLost')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Days Lost</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.oshaDaysLost }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="OSHA"
    field="isOshaRecordable"
    width="120"
    filter="text"
    [hidden]="!isVisible('isOSHARecordable')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>OSHA</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isOshaRecordable }}</ng-template>
  </kendo-grid-column>


  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Workers' Comp"
    field="isWorkersComp"
    width="120"
    filter="text"
    [hidden]="!isVisible('isWorkersComp')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Workers' Comp</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isWorkersComp }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Created By"
    field="addedBy"
    width="100"
    filter="text"
    [hidden]="!isVisible('addedBy')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Created By</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.addedBy }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Last Updated By"
    field="updatedBy"
    width="100"
    filter="text"
    [hidden]="!isVisible('updatedBy')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Last Updated By</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.updatedBy }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['desktop']"
    title=""
    class="overflow-visible-cell"
    [width]="50"
    [filterable]="false"
    [sortable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="m-roster">
        <span class="m-roster__btn"
          [popper]="incidentInto"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-end'"
          [popperDisableStyle]="'true'"
        >
          <i class="fas fa-list-ul" aria-hidden="true"></i>
        </span>
        <popper-content #incidentInto class="slx-popper">
          <section class="m-roster__popper">
            <div class="flex flex-direction-row m-roster__group" *slxHiddenOn="['tablet']">
              <p class="m-roster__field">
                <span class="m-roster__title">Position</span>
                <span class="m-roster__value">{{ dataItem.position?.name }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group" *slxHiddenOn="['tablet']">
              <p class="m-roster__field">
                <span class="m-roster__title">Date</span>
                <span class="m-roster__value">{{ dataItem.incidentDateTime | amDateFormat: appConfig.dateFormat }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Osha Injury Type</span>
                <span class="m-roster__value">{{ dataItem.oshaInjuryType?.description }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Occurrence Date</span>
                <span class="m-roster__value">
                  <span class="wc-summary-link" (click)="onOpenReport(dataItem)">{{ dataItem.incidentDateTime | amDateFormat: appConfig.dateFormat }}</span>
                </span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Body Part</span>
                <span class="m-roster__value">{{ dataItem.bodyPart?.description }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Days Lost</span>
                <span class="m-roster__value">{{ dataItem.oshaDaysLost }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">OSHA</span>
                <span class="m-roster__value">{{ dataItem.isOSHARecordable }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Worker Comp</span>
                <span class="m-roster__value">{{ dataItem.workersComp }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-roster__group">
              <p class="m-roster__field">
                <span class="m-roster__title">Created by</span>
                <span class="m-roster__value">{{ dataItem.addedBy }}</span>
              </p>
              <p class="m-roster__field">
                <span class="m-roster__title">Last updated by</span>
                <span class="m-roster__value">{{ dataItem.updatedBy }}</span>
              </p>
            </div>
          </section>
        </popper-content>
      </div>
    </ng-template>
  </kendo-grid-column>

 <kendo-grid-excel fileName="Workers Comp OSHA Recordkeeping Form.xlsx" [fetchData]="retriveAllPages()"></kendo-grid-excel>
 <kendo-grid-pdf fileName="Workers Comp OSHA Recordkeeping Form.pdf" [allPages]="true">
   <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
 </kendo-grid-pdf>
</kendo-grid>
