<slx-spinner [show]="isLoading">
    <div class="list-container">
        <div class="header-line">
            <span class="theme-exsm-text">Subject</span>
            <span class="theme-exsm-text">Due at</span>
        </div>
        <div *ngFor="let reminder of reminders" class="list-item">
            <div class="subject-text">{{reminder.subject}}</div>
            <div [ngClass]="{'time-label':true, 'text-green':reminder.isGreen, 'text-orange':reminder.isOrange, 'text-red':reminder.isRed}">{{reminder.dueDate | slxTimeFromNow }}</div>
            <div class="progress-body">
                <div [ngClass]="{'progress-fill':true, 'progress-green':reminder.isGreen, 'progress-orange':reminder.isOrange, 'progress-red':reminder.isRed}" [style.width.%]="reminder.percent"></div>
            </div>
        </div>
    </div>
</slx-spinner>