import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { RatingItem } from '../../models/rating-item';

@Component({
    moduleId: module.id,
    selector: 'slx-star-rating',
    templateUrl: 'star-rating.component.html',
    styleUrls: ['star-rating.component.scss']
})

export class StarRatingComponent implements OnInit {

    @Input()
    public filledItemClassName: string;
    @Input()
    public emptyItemClassName: string;

    @Input()
    public readonly: boolean;

    @Input()
    public set rating(value: number) {
        this.m_rating = value;
        if (!this.ratingData) {
            this.createRatingData();
        }
        this.updateRatingData();
    }

    public get rating(): number {
        return this.m_rating;
    }

    @Input()
    public set maxValue(value: number) {
        this.m_maxValue = value;
        this.createRatingData();
    }

    public get maxValue(): number {
        return this.m_maxValue;
    }

    @Output()
    public ratingChange: EventEmitter<number> = new EventEmitter();

    public ratingData: RatingItem[];
    private m_maxValue: number = 5;
    private m_rating: number = 0;

    constructor(private changeDetector: ChangeDetectorRef) { }

    public ngOnInit(): void {
        if (!this.ratingData) {
            this.createRatingData();
        }
        this.updateRatingData();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    }

    public onItemClick(item: RatingItem): void {
        if (this.readonly) {
            return;
        }
        if (item.isSelected) {
            this.rating = _.clamp(item.index - 1, 1, this.m_maxValue);
        } else {
            this.rating = item.index;
        }

        this.ratingChange.next(this.m_rating);
    }

    private createRatingData(): void {
        const values: RatingItem[] = [];
        _.times(this.m_maxValue, (value: number) => {
            values.push({
                index: value + 1,
                isSelected: (value + 1) <= this.m_rating
            });
        });
        this.ratingData = values;
    }

    private updateRatingData(): void {
        _.each(this.ratingData, (value: RatingItem) => {
            value.isSelected = value.index <= this.m_rating;
        });
    }

}
