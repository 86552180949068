import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Provider } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ISession } from '../../../../app/authentication/store/index';
import { SessionService } from '../../../../app/core/services';
import { DailyOpenShiftDetailsData, DetailsPartner } from '../../models';
import { ScheduleEntryManagementService } from '../../services';
import { ShiftRequestService } from '../../services/schedule/shift-request.service';
import { appConfig, IApplicationConfig } from './../../../app.config';
import { DialogOptions, IDialog } from './../../../common/index';
import { DialogModeSize, DialogOptions2 } from './../../../common/models/dialog-options';
import { ModalService } from './../../../common/services/index';
import { mutableSelect, unsubscribe } from './../../../core/decorators/index';

@Component({
  selector: 'slx-shift-request-popup',
  templateUrl: './shift-request-popup.component.html',
  styleUrls: ['./shift-request-popup.component.scss'],
})
export class ShiftRequestPopupComponent implements OnInit, OnDestroy {
  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  @unsubscribe()
  private userSubscription: Subscription;

  @Input()
  public requestedDate: Date;

  @Input()
  public orgLevelId: number;


  @Output()
  public selectedShiftDetails = new EventEmitter<any[]>()

  // public options: DialogOptions;
  public dialogResult: boolean;
  public settings: DailyOpenShiftDetailsData;
  public static items: any;
  private modalService: ModalService;
  private appConfig: IApplicationConfig;
  public storeSelectedData: DailyOpenShiftDetailsData;
  public storeSelectedDataArr: any = [];
  // public static requestedDate: Date;
  // public  orgDetails: any;
  // public static agencyDetails: DetailsPartner[];
  public static currentOffset: number;
  public isAddDisabled: boolean = false;
  public userName: string;
  public alias: string;
  public login: string;
  public userId: number;
  public positionMappingError: boolean = true;
  public shiftStarDateError: boolean = true;
  public loader: boolean = false;

  constructor(
    // options: DialogOptions,
    modalService: ModalService,
    private sessionService: SessionService,
    public management: ScheduleEntryManagementService,
    private shiftRequestService: ShiftRequestService
  ) {
    // this.orgdetailsData = this.or
    this.modalService = modalService;
    // this.options = options;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    const constUserDetails = this.sessionService.getUser();
    this.userName = constUserDetails.name;
    this.userId = constUserDetails.id;
    this.alias = this.sessionService.getAlias();

  }

  public get getcurrentOffset() {
    return ShiftRequestPopupComponent.currentOffset;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    //console.info('Destroy Add Employee popup');
  }
  public ngOnChanges() {
 
  }
public getShiftDetails(data) {
  this.selectedShiftDetails.emit(data);
}
  public onAdd(): void {
    this.loader = true;
    this.isAddDisabled = false;
    this.dialogResult = true;
    let orgLevelId = this.orgLevelId
    this.management.postRequestStaffing(this.storeSelectedDataArr, orgLevelId).then((status: any) => {
      this.shiftRequestService.sendclickEvent();
      this.loader = false;
      // this.modalService.closeWindow(this.options.windowUniqueId);
    }).catch((err: any) => {
      this.loader = false;
    });
  }

  public validateAddbutton(positionError, shiftStartDateError) {
    if (this.storeSelectedDataArr && this.storeSelectedDataArr.length > 0 && !positionError && !shiftStartDateError) {
      this.isAddDisabled = true;
    } else {
      this.isAddDisabled = false;
    }
  }

  public gridSelectionChange(data: any) {
    this.storeSelectedDataArr = [];
    let orgdetailsId = this.orgLevelId
    let parent_id = this.orgLevelId
    data.forEach((elem, i) => {
      let seletedAgency: DetailsPartner = data[i].SeletedAgency;
      let startDateTime = new Date(moment(this.requestedDate).format('MM/DD/YYYY') + ' ' + data[i].ShiftStart);
      let endDateTime = new Date(moment(this.requestedDate).format('MM/DD/YYYY') + ' ' + data[i].ShiftEnd);
      let storeSelectedData: DailyOpenShiftDetailsData = {};
      storeSelectedData.Clientid = this.alias;
      storeSelectedData.SlxshiftId = data[i].ShiftId;
      storeSelectedData.SlxshiftGroupId = data[i].ShiftGroupId;
      storeSelectedData.PositionGroupId = data[i].PositionGroupId;
      storeSelectedData.JobCode = data[i].JobCode;
      storeSelectedData.JobDesc = data[i].JobDescription;
      storeSelectedData.ShiftDate = moment(this.requestedDate).format('MM/DD/YYYY');
      storeSelectedData.ShiftStart = moment(startDateTime).format('MM/DD/YYYY HH:mm:ss');
      storeSelectedData.ShiftEnd = moment(endDateTime).format('MM/DD/YYYY HH:mm:ss');
      storeSelectedData.OrgLevelId = orgdetailsId;
      storeSelectedData.UnitId = data[i].UnitId;
      storeSelectedData.DepartmentId = data[i].DepartmentId;
      storeSelectedData.Hours = data[i].Hours.toString();
      storeSelectedData.shiftRequestbyId = this.userId;
      storeSelectedData.shiftRequestbyName = this.userName;
      storeSelectedData.SubTypeId = seletedAgency.sub_type_id.toString();
      storeSelectedData.PartnerId = "0";
      storeSelectedData.SubscriptionName = seletedAgency.sub_type_name;
      storeSelectedData.parentId = parent_id;
      storeSelectedData.AgencyId = 0;
      storeSelectedData.PositionErrorMessage = data[i].PositionErrorMessage;
      storeSelectedData.ShiftStartDateErrorMessage = data[i].ShiftStartDateErrorMessage;
      if (data[i].AgencyList.length == 1) {
        storeSelectedData.AgencyList = data[i].AgencyList;
      } else {
        storeSelectedData.AgencyList = data[i].AgencyList.length > 0 && data[i].AgencyList.filter(e => e.isChecked);
      }
      if (!moment(endDateTime).isAfter(startDateTime)) {
        let shitEndTime = data[i].ShiftEnd;
        let addDaytoWorkDate = moment(storeSelectedData.ShiftDate).add(1, 'days').format('MM/DD/YYYY');
        let nextday = new Date(addDaytoWorkDate + ' ' + shitEndTime);
        storeSelectedData.ShiftEnd = moment(nextday).format('MM/DD/YYYY HH:mm:ss');
      }
      if (storeSelectedData.AgencyList.length > 0) {
        this.storeSelectedDataArr.push(storeSelectedData);
      }
    });
    if (this.storeSelectedDataArr.length > 0) {
      this.positionMappingError = this.storeSelectedDataArr.some((e) => !e.PositionErrorMessage);
      this.shiftStarDateError = this.storeSelectedDataArr.some((e) => e.ShiftStartDateErrorMessage);
    }
    this.validateAddbutton(this.positionMappingError, this.shiftStarDateError);
  }

  public onPositionMappingList(): void {
    this.shiftRequestService.positionsClickEvent();
    // this.modalService.closeWindow(this.options.windowUniqueId);
  }
}