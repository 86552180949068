import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import {
    TimeclockDailySummaryContainer, TimeclockDataService,
} from '../../../organization/index';
import { TaSignalrService } from '../../services/signalR/ta-signalr-service';
import { SessionService } from '../../../../app/core/services';
import { NotificationsService } from '../../../../app/core/components';


@Component({
    moduleId: module.id,
    selector: 'slx-timeclocks-review',
    templateUrl: 'timeclock-review.component.html',
    styleUrls: ['timeclock-review.component.scss']
})

export class TimeclockReviewComponent implements OnInit {

    public timeclockDailySummaryContainer: TimeclockDailySummaryContainer;
    @unsubscribe()
    private timeclockLoadedSubscription: Subscription;

    constructor(private timeclockDataService: TimeclockDataService,
       private taSignalrService: TaSignalrService,
       private sessionService:SessionService,
       private notificationService: NotificationsService) {
    }

    public ngOnInit(): void {
        this.taSignalrService.checkAndEstablishConnection();
        this.taSignalrService.isRebooted$.subscribe((args: any): void => { 
            if(this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase() ){
                    let itemIndex = this.timeclockDailySummaryContainer.records.findIndex(e=> e.id === JSON.parse(args[2]).ClockId);
                    this.timeclockDailySummaryContainer.records[itemIndex].isTimeClockRebootStarted = false;
                    this.timeclockDailySummaryContainer.records[itemIndex].rebootStatus = 'Completed';
                    this.notificationService.success('Sucess', " Clock reboot sucessfully completed for "+this.timeclockDailySummaryContainer.records[itemIndex].name);
            }
        });
        this.timeclockLoadedSubscription = this.timeclockDataService.onLoaded$
            .subscribe((timeclocksContainer: TimeclockDailySummaryContainer) => {
                timeclocksContainer.records = _.orderBy(timeclocksContainer.records, ['isVirtual', 'communicationStatus', 'sortName']);
                this.timeclockDailySummaryContainer = timeclocksContainer;
            });

        this.timeclockDataService.loadTimeclocks();

    }
}
