import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common';
import * as moment from 'moment';
import { process } from '@progress/kendo-data-query';
import { MessageList, sectionType, SlateSection } from '../../models';
import { SlateMessagesApiService } from '../../services/slate-messages-api.service';
import { SlateMessageGroupInfo } from '../../models/slate-message-group-info';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { Observable, Subscription } from 'rxjs';
import { OrgLevel } from '../../../../state-model/models';
import { appConfig } from '../../../../app.config';
import { SlateMessagesManagementService } from '../../services/slate-messages-management.service';

@Component({
  selector: 'slx-slate-message-list',
  templateUrl: './slate-message-list.component.html',
  styleUrls: ['./slate-message-list.component.scss']
})
export class SlateMessageListComponent implements OnInit {
  public gridState: KendoGridStateHelper<SlateMessageGroupInfo[]>;
  public dataAge: sectionType;
  public sectionTypeStrings = ['lessthanmonth','olderthanonemonth', 'olderthanthreemonth', 'loadolderThan6Month'];
  public sectionTypes: Array<SlateSection > =[];
  messages: Array<SlateMessageGroupInfo> = [];
  isLoading: boolean;
  @unsubscribe()
  private subscriptions: StringMap<Subscription> = {};
  @Output() updateMessagesCount = new EventEmitter<number>();
  @Input() isExpanded: boolean;
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;

  constructor(private slateMessagesApiService: SlateMessagesApiService,
    private slateMessagesManagementService: SlateMessagesManagementService
  ) { }

  ngOnInit() {
    this.setSlateSections();
    this.subscribeToOrgLevel();
    this.slateMessagesManagementService.isMessagesDataUpdated$.subscribe((isDataUpdated: boolean) => {
      if (isDataUpdated) {
        this.loadMessages();
      }
    });
  }

  public subscribeToOrgLevel(): void {
    this.isLoading = true;
    this.subscriptions.orgLevel = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        if (!!orgLevel && orgLevel.id) {
          this.isLoading = false;
          this.orgLevel = orgLevel;
          this.loadMessages();
        }
      });
  }

  loadMessages() {
    let sectionType: SlateSection = this.sectionTypes.find(type => type.sectionType == 'lessthanmonth');
    let startDate: Date = this.startDate(sectionType);
    let endDate: Date = this.endDate(sectionType);
    this.getSlateMessages(sectionType, startDate, endDate);
  }

  setSlateSections() {
    this.sectionTypeStrings.forEach(type => {
      let sectionType: SlateSection = {
        isCollapsed: true,
        sectionTypeText: this.getAccordianlevel(type),
        sectionType: type,
        messages: []
      };
      this.sectionTypes.push(sectionType);
    });
  }

  getSlateMessages(type: SlateSection, startDate: Date, endDate: Date) {
    this.isLoading = true;
    this.slateMessagesApiService.getSlateMessageGroupsWithDateRange(this.orgLevel.id, startDate, endDate).then((data: SlateMessageGroupInfo[]) => {
      if (type.sectionType == sectionType.lessThanOneMonth) {
        this.messages = data;
        this.updateCount();
      }
      else {
        type.messages = data;
        this.updateCount();
      }
      this.isLoading = false;
      this.gridState = new KendoGridStateHelper<SlateMessageGroupInfo[]>();
      this.gridState.view = null;
      this.refreshGrid();
    });
  }

  collapsedChange(event: any, type: SlateSection) {
    if (type.messages.length == 0) {
      let startDate: Date = this.startDate(type);
      let endDate: Date = this.endDate(type);
      this.getSlateMessages(type, startDate, endDate);
    }
    
    type.isCollapsed = !type.isCollapsed;
  }

  private updateCount() {
    let count: number = 0;
    count = this.messages.length;
    this.sectionTypes.forEach(type => {
      count += type.messages.length;
    });

    this.updateMessagesCount.emit(count);
  }

  public startDate(slateSection: SlateSection): Date {
    if (sectionType.lessThanOneMonth == slateSection.sectionType) {
        return moment().subtract(1, 'month').toDate();
    }
    else if (sectionType.olderThanOneMonth == slateSection.sectionType) {
      return moment().subtract(3, 'month').toDate();
    }
    else if (sectionType.olderThanThreeMonth == slateSection.sectionType) {
      return moment().subtract(6, 'month').toDate();
    }
    else if (sectionType.olderThanSixMonth == slateSection.sectionType) {
      return moment(appConfig.minCorrectDate).toDate();
    }
  };

  public endDate(slateSection: SlateSection): Date {
    if (sectionType.lessThanOneMonth == slateSection.sectionType) {
      return moment().add(1, 'day').toDate();
    }
    else if (sectionType.olderThanOneMonth == slateSection.sectionType) {
      return moment().subtract(1, 'month').toDate();
    }
    else if (sectionType.olderThanThreeMonth == slateSection.sectionType) {
      return moment().subtract(3, 'month').toDate();
    }
    else if (sectionType.olderThanSixMonth == slateSection.sectionType) {
      return moment().subtract(6, 'month').toDate();
    }
  }

  public refreshGrid(): void {
    if (!this.sectionTypeStrings) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process([''], this.gridState.state);
  }

  getAccordianlevel(item) {
    switch (item) {
      case 'lessthanmonth': {
        return 'less than a month';
      }
      case 'olderthanthreemonth': {
        return 'Older than three months';
      }
      case 'olderthanonemonth': {
        return 'Older than one month';
      }
      case 'loadolderThan6Month': {
        return 'Older than six months';
      }
    }
  }
}
