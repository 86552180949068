export interface IIpSettings {
  enforceBlacklistRestrictions : boolean;
  enforceWhitelistRestrictions : boolean;
  canEdit: boolean;
  canExport: boolean;
}
  
export class IpSettings {
  public enforceBlacklistRestrictions: boolean;
  public enforceWhitelistRestrictions: boolean;
  public canEdit: boolean;
  public canExport: boolean;
}