<div class="star-rating-content">
    <div class="content-left-part">
        <slx-staffing-predictor-rating-badge [model]="organization" [showDetails]="!hasError"></slx-staffing-predictor-rating-badge>
    </div>
    <div class="content-right-part">

        <div *ngIf="outdatedData" class="error-info not-critical">
            <div class="error-messagex-box">
                <span class="error-mesage-text">
                    Due to technical issues in retrieving latest data, calculations have been based on last values retrieved from CMS. We are working with CMS to get the latest values.
                </span>
            </div>
        </div>

        <slx-staffing-predictor-rating-predictor *ngIf="!hasError" class="rating-predictor" [model]="dataModel"
            [sectionIndex]="sectionIndex">
        </slx-staffing-predictor-rating-predictor>

        <div *ngIf="hasError" class="error-info">
            <i *ngIf="hasError && !isLoading" class="triangle-exclamation fas fa-exclamation-triangle"></i>
            <div class="error-messagex-box">
                <span class="error-mesage-text" *ngIf="!hasData && !isLoading">Can not load information for this organization</span>
                <span class="error-mesage-text" *ngIf="noCmsData">
                    Calculations cannot be performed for this facility as there is no staffing hours data available on CMS.
                </span>
                <span class="error-mesage-text" *ngIf="noCcn">
                  Please enter the facility CCN number to proceed with the Staffing Rating Predictor.
                  <span class="link-text" (click)="goToConfigScreen()">Click here</span>
                  to enter the CCN number on the
                  <span class="link-text" (click)="goToConfigScreen()">“Configure PBJ Organization Screen”</span>
                </span>
                <span class="error-mesage-text" *ngIf="noCensus">
                  There is no census value for {{organization?.organization.name}}. Please enter a census value for your facility
                </span>
                <span class="error-mesage-text" *ngIf="noHoursData">No hours in the system for the date range entered</span>
                <span class="error-mesage-text" *ngIf="rnZeroDays">
                    Your Facility will receive a 1 star rating as there are Zero RN Hours for 4 or more days in the date range selected
                </span>
            </div>
        </div>
    </div>
</div>
