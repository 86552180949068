import { IPayloadAction } from '../../../../state-model/models/index';
import { EmployeeListActions } from './employee-list.actions';
import { Map } from 'immutable';
import {
  INITIAL_EMPLOYEE_LIST_STATE, INITIAL_STATE_BY_VIEW_STATE, INITIAL_STATE_BY_ORGLEVEL_STATE
} from './employee-list.initial-state';
import {
  IViewEmployeeListState, IOrgLevelEmployeeListState
} from './employee-list.types';


export function employeeListStateReducer(state: Map<string, any> = INITIAL_EMPLOYEE_LIST_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case EmployeeListActions.RESET_VIEW:
      return state.merge({ viewName: 0 });
    case EmployeeListActions.RESET_IS_ACTIVE:
      return state.merge({ isActive: true });
    case EmployeeListActions.SELECT_VIEW:
      let orgState1: Map<string, any> = state.getIn(['stateByOrglevel', action.payload.orgLevelId], INITIAL_STATE_BY_ORGLEVEL_STATE);
      let newState: Map<string, any> = state.setIn(['stateByOrglevel', action.payload.orgLevelId], orgState1);
      return newState.merge({ viewName: action.payload.viewName, isActive: action.payload.isActive });
    case EmployeeListActions.SELECT_ITEM:
      let viewState: Map<string, any> = INITIAL_STATE_BY_VIEW_STATE;
      let orgState2: Map<string, any> = state.getIn(['stateByOrglevel', action.payload.orgLevelId], INITIAL_STATE_BY_ORGLEVEL_STATE);
      orgState2 = orgState2.merge({ isActive: action.payload.isActive });
      orgState2 = orgState2.setIn(['stateByView', action.payload.viewName], viewState.merge({ itemIds: action.payload.itemIds }));
      return state.setIn(['stateByOrglevel', action.payload.orgLevelId], orgState2);
    default:
      return state;
  }
}
