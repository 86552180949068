import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { ResponseBody,Meta } from '../../../../core/models/index';
import { employeeConfig } from '../../employee.config';
import { ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';

@Injectable()
export class DailyTimecardApiService {
  constructor(
    private apiUtilService: ApiUtilService) {
  }
  
  public getDailyTimecard(empId: number, dateOn: Date): Promise<any> {

    Assert.isNotNull(empId, 'empId');
    Assert.isNotNull(dateOn, 'dateOn');
    const dateRoute: string = moment(dateOn).format(appConfig.requestDate);

    const url: string = `${this.getTaRoot()}/${dateRoute}/${this.getTimecardURIPortion()}/${empId.toString()}/${employeeConfig.api.timecards.daily.root}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response.data;
      });
    return promise;
  }
 
public getBatchId(empId: number): Promise<any> { 
  const url: string = `${this.getApiRoot()}/${employeeConfig.api.timecards.employee.root}/${empId.toString()}/${employeeConfig.api.timecards.employee.badgeId}`;

  let request: HttpRequest<any> = new HttpRequest('GET', url);

  let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
    .then((response: any) => {
      return response.data;
    });
  return promise;
}
  
private getApiRoot(): string {
  return this.apiUtilService.getApiRoot();
}
private getTaRoot(): string {
  return `${this.getApiRoot()}/${employeeConfig.api.timecards.api.root}`;
}
private getTimecardURIPortion(): string {
  return `${employeeConfig.api.timecards.root}/${employeeConfig.api.timecards.employee.root}`;
}
}