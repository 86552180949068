import * as tslib_1 from "tslib";
import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { unsubscribe } from '../../../../../core/decorators';
import { Subscription } from 'rxjs';
import { EmployersManagementService } from '../../../../../configuration/services';
var ManageAleGroupsDialogComponent = /** @class */ (function () {
    function ManageAleGroupsDialogComponent(options, modalService, manService) {
        this.manService = manService;
        this.dialogResult = false;
        this.isSaveDisabled = true;
        this.isLoading = true;
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
    }
    ManageAleGroupsDialogComponent.openDialog = function (modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 800;
        dialogOptions.height = 550;
        dialogOptions.hideTitleBar = true;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.showCloseButton = false;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            }
        ];
        return modalService.globalAnchor.openDialog(ManageAleGroupsDialogComponent, 'Manage Groups', dialogOptions, resolvedProviders, callback);
    };
    ManageAleGroupsDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptionsClose = this.manService.subscribeToClosePopup(function () {
            _this.onClose();
        });
    };
    ManageAleGroupsDialogComponent.prototype.onLoading = function (isLoading) {
        this.isLoading = isLoading;
    };
    ManageAleGroupsDialogComponent.prototype.onHasChanges = function (hasChanges) {
        this.dialogResult = hasChanges;
    };
    ManageAleGroupsDialogComponent.prototype.isGroupChanges = function (value) {
        this.isSaveDisabled = value;
    };
    ManageAleGroupsDialogComponent.prototype.onCancel = function () {
        if (!this.isSaveDisabled) {
            this.manService.markAsDirty();
            this.manService.requestClosePopup();
        }
        else {
            this.dialogResult = true;
            this.modalService.closeWindow(this.options.windowUniqueId);
        }
    };
    ManageAleGroupsDialogComponent.prototype.onClose = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ManageAleGroupsDialogComponent.prototype.isSaveComplete = function (value) {
        this.dialogResult = value;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ManageAleGroupsDialogComponent.prototype, "subscriptionsClose", void 0);
    return ManageAleGroupsDialogComponent;
}());
export { ManageAleGroupsDialogComponent };
