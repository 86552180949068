<slx-spinner [show]="isLoading">
  <kendo-grid #kendoGrid scrollable="'scrollable'" [data]="gridState?.view" [sortable]="true"
    [sort]="gridState.state.sort" [skip]="gridState.state.skip" [pageable]="true" [pageSize]="pageSize"
    (dataStateChange)="gridState.dataStateChange($event)">
    <kendo-grid-column field="plan" title="Plan" width="200" [locked]="true">
      <ng-template kendoGridHeaderTemplate>
        Plan
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="blue-link" slxSaveNotesDialog [selectedRecord]="dataItem" [employeeName]="empName"
          (saveNotes)="onPlanClick($event)">{{dataItem.plan}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="startDate" title="Start Date" width="120">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Start Date</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.startDate | amDateFormat: appConfig.dateFormat  }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="endDate" title="End Date" width="120">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>End Date</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span>{{ dataItem.endDate | amDateFormat: appConfig.dateFormat | slxHideMaxDate}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="employeeContribution" title="Employee Contribution" width="220" [sortable]="false"
      class="numeric-cell">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Employee Contribution</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span
        *ngIf="dataItem.calculationMethod == calculationMethod.Formula || dataItem.calculationMethod == calculationMethod.FormulaWithOption || dataItem.calculationMethod === calculationMethod.Flat || 
        dataItem.calculationMethod === calculationMethod.OptionsRates || (dataItem.calculationMethod === calculationMethod.C401K && dataItem.employeeContribution > 0)">
        {{ dataItem.employeeContribution | number: ".2-2"| slxMoney}}
      </span>
        <i *ngIf="dataItem.employeeContribution==-1"
          title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
          aria-hidden="true"
          [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="cost" title="Cost" width="120" [sortable]="false">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Cost</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.calculationMethod == calculationMethod.OptionsRates">{{ dataItem.cost | number: ".2-2"| slxMoney }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="option" title="Option" width="250" [sortable]="false">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Option</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.calculationMethod == calculationMethod.OptionsRates">{{ dataItem.option }}</span>
        <span *ngIf="dataItem.calculationMethod == calculationMethod.FormulaWithOption">{{ dataItem.optionType }}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="coverage" title="Coverage" width="220" [sortable]="false" class="numeric-cell">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Coverage</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="dataItem.calculationMethod == calculationMethod.Formula || dataItem.calculationMethod == calculationMethod.FormulaWithOption">{{ dataItem.calculatedCoverage | number: ".2-2"| slxMoney}}</span>
        <i *ngIf="dataItem.coverage==-1"
          title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
          aria-hidden="true"
          [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="employeePercentageContribution" title="Employee % Contribution" width="220"
      [sortable]="false">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Employee % Contribution</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="dataItem.calculationMethod == calculationMethod.C401K">
          {{ dataItem.employeePercentageContribution }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="limitAmount" title="Limit Amount" width="150" [sortable]="false">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Limit Amount</span>
      </ng-template>
      <ng-template kendoGridCellTemplate
      let-dataItem>
      <span *ngIf="dataItem.calculationMethod == calculationMethod.C401K">{{ dataItem.limitAmount | number: ".2-2"| slxMoney }}</span>
    </ng-template>
    </kendo-grid-column>

  </kendo-grid>
</slx-spinner>
