<div class="overview-tooltip" *ngIf="model" [style.border-top-color]="model.color?.fontColor1">
  <div class="values">
    <slx-labeled-value
      [label]="model.input?.label1"
      [value]="model.input?.value1"
      [color]="model.color?.fontColor1"
    ></slx-labeled-value>

    <slx-labeled-value
      [label]="model.input?.label2"
      [value]="model.input?.value2"
      [color]="model.color?.fontColor2"
    ></slx-labeled-value>
  </div>

  <div class="icons ie10">
    <slx-labeled-badge
      class="labeled-badge ie10"
      icon="fa-envelope-open"
      label="Messages"
      [value]="model.input?.additionalInfo?.pendingMessagesCount"
    ></slx-labeled-badge>

    <slx-labeled-badge
      class="labeled-badge ie10"
      icon="far fa-clock"
      label="Overtime"
      [value]="model.input?.additionalInfo?.overtimeEmployees"
    ></slx-labeled-badge>

    <slx-labeled-badge
      class="labeled-badge ie10"
      icon="fa-user-times"
      label="PTO"
      [value]="model.input?.additionalInfo?.ptoRequests"
    ></slx-labeled-badge>

    <slx-labeled-badge
      class="labeled-badge ie10"
      icon="fa-exclamation-square"
      label="Late"
      [value]="model.input?.additionalInfo?.lateCount"
    ></slx-labeled-badge>
    </div>
</div>
