export * from './employee-section-link.directive';
export * from './post-schedule-link.directive';
export * from './timecards-link.directive';
export * from './individual-timecards-link.directive';
export * from './schedule-entry-link.directive';
export * from './daily-timecards-link.directive';
export * from './add-employee-link.directive';
export * from './employee-list-link.directive';
export * from './back-link.directive';
export * from './forward-link.directive';
export * from './user-profile-link.directive';
export * from './pbj-export-details-link.directive';
export * from './home-link.directive';
export * from './daily-punches-link.directive';
export * from './timecard-summary-link.directive';
export * from './lm-roster-link.directive';
export * from './employee-section-link-new-window.directive';

import { EmployeeSectionLinkDirective } from './employee-section-link.directive';
import { PostScheduleLinkDirective } from './post-schedule-link.directive';
import { TimecardsLinkDirective } from './timecards-link.directive';
import { IndividualTimecardsLinkDirective } from './individual-timecards-link.directive';
import { ScheduleEntryLinkDirective } from './schedule-entry-link.directive';
import { DailyTimecardsLinkDirective } from './daily-timecards-link.directive';
import { LeaveRequestsLinkDirective } from './leave-requests-link.directive';
import { IndividualScheduleEmpLinkDirective } from './individual-schedule-emp-link.directive';
import { AddEmployeeLinkDirective } from './add-employee-link.directive';
import { EmployeeListLinkDirective } from './employee-list-link.directive';
import { BackLinkDirective } from './back-link.directive';
import { ForwardLinkDirective } from './forward-link.directive';
import { UserProfileLinkDirective } from './user-profile-link.directive';
import { PbjExportDetailsLinkDirective } from './pbj-export-details-link.directive';
import { HomeLinkDirective } from './home-link.directive';
import { DailyPunchesLinkDirective } from './daily-punches-link.directive';
import { TimecardsSummaryLinkDirective } from './timecard-summary-link.directive';
import { LmRosterLinkDirective } from './lm-roster-link.directive';
import { EmployeeSectionLinkNewWindowDirective } from './employee-section-link-new-window.directive';
import { EmployeeSectionTabLinkDirective } from './employee-section-tab-link.directive';

export const navigationDirectives: any[] = [
  EmployeeSectionLinkDirective,
  PostScheduleLinkDirective,
  IndividualTimecardsLinkDirective,
  TimecardsLinkDirective,
  ScheduleEntryLinkDirective,
  DailyTimecardsLinkDirective,
  LeaveRequestsLinkDirective,
  IndividualScheduleEmpLinkDirective,
  AddEmployeeLinkDirective,
  EmployeeListLinkDirective,
  BackLinkDirective,
  ForwardLinkDirective,
  UserProfileLinkDirective,
  PbjExportDetailsLinkDirective,
  HomeLinkDirective,
  DailyPunchesLinkDirective,
  TimecardsSummaryLinkDirective,
  LmRosterLinkDirective,
  EmployeeSectionLinkNewWindowDirective,
  EmployeeSectionTabLinkDirective
];
