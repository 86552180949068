import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MomentModule } from 'angular2-moment';

import { timeAndAttendaceComponents, exportTimeAndAttendaceComponents, entryTimeAndAttendaceComponent } from './components/index';
import { services } from './services/index';
import { directives, exportDirectives } from './directives/index';
import { ACTION_PROVIDERS } from './store/index';
//import { pipes } from './pipes/index';
//import { directives, exportDirectives } from './directives/index';
import { routesModule } from './time-and-attendance.routes';
import { CommonModule as CommonAppModule } from '../common/index';
import { ComponentsLibraryModule } from '../components-library/index';

import { EmployeeModule } from '../employee/employee/index';
import { EmployeeListModule } from '../employee/employee-list/index';
import { EmployeeSectionsModule } from '../employee/employee-sections/index';
import { OrganizationModule } from '../organization/index';
import { ConfigurationModule } from '../configuration/index';
import { NgxPopperModule } from 'ngx-popper';
import { EPIC_PROVIDERS, TimecardsEpics } from './epics/index';
import { rootEpics } from '../state-model/state-model.module';
import { PayUnitsModule } from '../app-modules/pay-units/pay-units.module';
import { TimeclockRebootDialogComponent } from './components/timeclock-review/timeclock-item/timeclock-reboot-dialog/timeclock-reboot-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    OrganizationModule,
    ConfigurationModule,
    CommonAppModule,
    ComponentsLibraryModule,
    routesModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DialogsModule,
    ExcelModule,
    PDFModule,
    DropDownsModule,
    EmployeeModule,
    EmployeeListModule,
    EmployeeSectionsModule,
    MomentModule,
    NgxPopperModule,
    PayUnitsModule,
  ],
  declarations: [
    ...timeAndAttendaceComponents,
    ...directives,
    TimeclockRebootDialogComponent
    //...pipes,
    //...directives
  ],
  entryComponents: [
    ...entryTimeAndAttendaceComponent
  ],
  providers: [
    ...services,
    ...ACTION_PROVIDERS,
    ...EPIC_PROVIDERS
  ],
  exports: [
    ...exportTimeAndAttendaceComponents,
    ...exportDirectives
    //...exportDirectives
  ]
})
export class TimeAndAttendaceModule {
  constructor(timecardsEpics: TimecardsEpics) {
    rootEpics.push(timecardsEpics.resetPayCycle);
    rootEpics.push(timecardsEpics.resetSelectionSettings);
  }
}
