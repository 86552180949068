<div>
  <slx-input-decorator>
    <slx-dropdown-input slx-input type="text" [lookup]="lookupName"
    [placeholder]="parameter.displayName" [(ngModel)]="lookupValue"
    [orgLevelId]="orgLevel.id" emptyOption="All"
    ></slx-dropdown-input>
    <span errorMessage for="required">Report parameter is required.</span>
  </slx-input-decorator>

</div>
