import { Component, OnInit } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../common/models/index';
import { ModalService, } from './../../../../../app/common';
import { ScheduleRotationsManagementService } from './../../../../../app/scheduler/services/scheduled-rotation-template/schedule-rotations-management.service';
import * as moment from 'moment';

@Component({
  selector: 'slx-scheduled-generate-rotation',
  templateUrl: './scheduled-generate-rotation.component.html',
  styleUrls: ['./scheduled-generate-rotation.component.scss']
})
export class ScheduledGenerateRotationComponent implements OnInit, IDialog {

  dialogResult: boolean;
  public totalEmployees: number;
  public scheduleCycleStartDate: string;

  constructor(private modalService: ModalService, private options: DialogOptions, public manService: ScheduleRotationsManagementService) {
  }

  ngOnInit() {
    this.getDefaultData();
  }

  public cycleChange(e) {
    let cycle = e.target.value;
    let currentCycle = moment(new Date()).format('MM/DD/YYYY');
    let nextCycle = moment(this.manService.firstCycle.endDate).add(1, 'days').format('MM/DD/YYYY');
    if (cycle === '1') {
      this.scheduleCycleStartDate = currentCycle;
      if (this.manService.generateRotationRecord) {
        this.manService.generateRotationRecord.startDate = currentCycle;
      }
    }
    else {
      this.scheduleCycleStartDate = nextCycle;
      if (this.manService.generateRotationRecord) {
        this.manService.generateRotationRecord.startDate = nextCycle;
      }
    }
  }

  public getDefaultData() {
      this.totalEmployees = this.manService.generateRotationRecord.empIds.length;
      this.scheduleCycleStartDate = moment(new Date()).format('MM/DD/YYYY');
      this.manService.generateRotationRecord.startDate = this.scheduleCycleStartDate;
  }

  public isDisabled(): boolean  {
    if(this.manService.generateRotationRecord) {
      return this.manService.generateRotationRecord.empIds.length === 0 ? true : false;
    }
    else {
      return false;
    }
  }

  public cancelGenerateRotation(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public saveGenerateRotation(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

}
