/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./email-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../directives/blur-forwarder/blur-forwarder.directive";
import * as i4 from "../../directives/focus-forwarder/focus-forwarder.directive";
import * as i5 from "./email-input.component";
var styles_EmailInputComponent = [i0.styles];
var RenderType_EmailInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailInputComponent, data: {} });
export { RenderType_EmailInputComponent as RenderType_EmailInputComponent };
export function View_EmailInputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { inputComponent: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["emailInput", 1]], null, 9, "input", [["maxlength", "255"], ["type", "text"]], [[1, "class", 0], [8, "readOnly", 0], [8, "placeholder", 0], [1, "tabindex", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (i1.ɵnov(_v, 9).onBlur($event) !== false);
        ad = (pd_4 && ad);
    } if (("focus" === en)) {
        var pd_5 = (i1.ɵnov(_v, 10).onFocus($event) !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.innerValue = $event) !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = (_co.valueChanged($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(3, 540672, null, 0, i2.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.MaxLengthValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i2.NgModel, [[8, null], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(8, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(9, 16384, null, 0, i3.BlurForwarderDirective, [i1.ElementRef, i1.Renderer], null, null), i1.ɵdid(10, 16384, null, 0, i4.FocusForwarderDirective, [i1.ElementRef, i1.Renderer], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_12 = "255"; _ck(_v, 3, 0, currVal_12); var currVal_13 = _co.innerValue; _ck(_v, 6, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; var currVal_1 = (!_co.inEdit ? true : null); var currVal_2 = _co.placeholder; var currVal_3 = _co.tabindex; var currVal_4 = (i1.ɵnov(_v, 3).maxlength ? i1.ɵnov(_v, 3).maxlength : null); var currVal_5 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_6 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_7 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_8 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_9 = i1.ɵnov(_v, 8).ngClassValid; var currVal_10 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_11 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }); }
export function View_EmailInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-email-input", [], [[2, "edited", null]], [[null, "slx-focus"], [null, "slx-blur"]], function (_v, en, $event) { var ad = true; if (("slx-focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onCustomFocus() !== false);
        ad = (pd_0 && ad);
    } if (("slx-blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onCustomBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_EmailInputComponent_0, RenderType_EmailInputComponent)), i1.ɵdid(1, 573440, null, 0, i5.EmailInputComponent, [i1.ElementRef, i2.NgControl], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hasFocus; _ck(_v, 0, 0, currVal_0); }); }
var EmailInputComponentNgFactory = i1.ɵccf("slx-email-input", i5.EmailInputComponent, View_EmailInputComponent_Host_0, { tabindex: "tabindex", className: "className", placeholder: "placeholder", readonly: "readonly" }, {}, []);
export { EmailInputComponentNgFactory as EmailInputComponentNgFactory };
