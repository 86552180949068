import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { OnInit, OnDestroy } from '@angular/core';
import { mutableSelect, unsubscribe, unsubscribeAll } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models';
import { MessageSortingOptions } from '../../models/index';
import { ModalService } from '../../../../common/services/index';
import { SmsComposeNewDialogComponent } from '../sms-compose-new-dialog/sms-compose-new-dialog.component';
import { MessageListComponent } from '../message-list/message-list.component';
import { MessagesManagementService } from '../../services/messages-management.service';
import { Observable } from 'rxjs';
import { MessageNotificationService } from '../../../../portal/services/notifications/msg-notifications.service';
import { SlateMessagesManagementService } from '../../services/slate-messages-management.service';
var MessageCenterComponent = /** @class */ (function () {
    function MessageCenterComponent(modalService, messageManService, slateMessageManagementService, messageNotificationService) {
        this.modalService = modalService;
        this.messageManService = messageManService;
        this.slateMessageManagementService = slateMessageManagementService;
        this.messageNotificationService = messageNotificationService;
        this.canSendSMS = false;
        this.canSendSlate = false;
        this.canViewSlateTab = false;
        this.canViewSmsTab = false;
        this.activeTab = 0;
        this.isLoading = true;
        this.pageSize = 100;
        this.loadOlderThanSixMonths = false;
        this.sortingOptions = Object.values(MessageSortingOptions);
        this.subscriptions = {};
        this.isArchiveButtonClicked = false;
        this.isRestoreButtonClicked = false;
        this.isMarkAsReadButtonClicked = false;
        this.isMarkAsUnreadButtonClicked = false;
        this.selectedMessageIds = [];
        this.selectedReadIds = [];
        this.selectedUnreadIds = [];
        this.isExpanded = false;
        this.orgLevelID = 1;
        this.selectedId = '';
        this.selectedCheckboxEvent = '';
        this.isDisabled = false;
    }
    MessageCenterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.messageManService.isMsgCenterLoaded = true;
        this.isMyMessage = this.messageManService.restoreFilterState();
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (level) {
            if (level && level.id && ((_this.orgLevel && (level.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = new OrgLevel();
                _this.orgLevel.id = level.id;
                _this.orgLevelID = level.id;
            }
        });
        this.subscriptions.id = this.messageManService
            .subscribeToMessageCount(function (v) {
            _this.smsReadUnreadCount = v;
        });
        this.subscriptions.changetab = this.messageManService
            .subscribeChangeActiveTab(function (v) {
            _this.activeTab = 0;
        });
        this.subscriptions.id = this.messageManService
            .subscribeToGetMessageId(function (v) {
            _this.selectedId = v;
        });
        this.subscriptions.unreadSmsCount = this.messageNotificationService.subscribeToUnreadSmsCount((function (res) {
            _this.smsReadUnreadCount = res;
        }));
        this.subscriptions.unreadArchiveCount = this.messageNotificationService.subscribeToUnreadArchiveCount((function (res) {
            _this.archiveReadUnreadCount = res;
        }));
        this.messageManService.getComposeNewTabsPermissions(this.orgLevelID).then(function (cNewTabs) {
            _this.canSendSMS = cNewTabs.canSendSMS;
            _this.canSendSlate = cNewTabs.canSendSlate;
            _this.canViewSlateTab = cNewTabs.canViewSlateTab;
            _this.canViewSmsTab = cNewTabs.canViewSmsTab;
            if (_this.canViewSmsTab) {
                _this.activeTab = 0;
                _this.getUnreadCount();
            }
            else if (_this.canViewSlateTab) {
                _this.onClickTab(2);
            }
        });
        this.subscriptions.selectedSlateMessage = this.slateMessageManagementService.selectedMessage$.subscribe((function (res) {
            _this.selectedSlateMessage = res;
        }));
    };
    MessageCenterComponent.prototype.isSelectedUnreadIdsEmpty = function () {
        var obj = this.setCountObj();
        if ((this.selectedUnreadIds.length > 0 || this.selectedCheckboxEvent === 'Unread' || this.selectedCheckboxEvent === 'All')) {
            return false;
        }
        else {
            return true;
        }
    };
    MessageCenterComponent.prototype.isSelectedReadIdsEmpty = function () {
        var obj = this.setCountObj();
        if ((this.selectedReadIds.length > 0 || this.selectedCheckboxEvent === 'Read' || this.selectedCheckboxEvent === 'All') && (obj.total !== obj.unRead)) {
            return false;
        }
        else {
            return true;
        }
    };
    MessageCenterComponent.prototype.composeNewDialog = function () {
        SmsComposeNewDialogComponent.openDialog(this.modalService, function (result) {
            if (result) { }
        });
    };
    MessageCenterComponent.prototype.onClickTab = function (tab) {
        if (this.activeTab === tab || this.isDisabled)
            return;
        this.activeTab = tab;
        this.isActiveMenu(this.activeTab);
        this.getUnArchiveCount();
        this.selectedId = '';
    };
    MessageCenterComponent.prototype.isActiveMenu = function (tabOrder) {
        return (this.activeTab === tabOrder) ? true : false;
    };
    MessageCenterComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
        this.messageManService.isMsgCenterLoaded = false;
    };
    MessageCenterComponent.prototype.updateMsgCenterSelectedMessageIds = function (event) {
        this.selectedMessageIds = event;
    };
    MessageCenterComponent.prototype.updateMessagesCount = function (event) {
        this.slateMessagesCount = event;
    };
    MessageCenterComponent.prototype.getArchiveReadUnreadCount = function (event) {
        this.getUnArchiveCount();
    };
    MessageCenterComponent.prototype.archiveMessages = function () {
        this.childComponent.updateArchiveMessages();
    };
    MessageCenterComponent.prototype.restoreMessages = function () {
        this.childComponent.updateUnArchiveMessages();
        this.getUnArchiveCount();
    };
    MessageCenterComponent.prototype.markAsReadMessages = function () {
        this.childComponent.updateReadMessages();
    };
    MessageCenterComponent.prototype.markAsUnreadMessages = function () {
        this.childComponent.updateUnreadMessages();
    };
    MessageCenterComponent.prototype.getUnreadCount = function () {
        this.messageNotificationService.getUnreadSmsCount(this.orgLevelID, this.isMyMessage);
    };
    MessageCenterComponent.prototype.getUnArchiveCount = function () {
        this.messageNotificationService.getUnreadArchiveCount(this.orgLevelID, this.isMyMessage);
    };
    MessageCenterComponent.prototype.getSelectedCheckboxEvent = function (event) {
        if (this.activeTab === 0) {
            if ((event === 'Unread' && this.smsReadUnreadCount.unRead > 0) || (event === 'Read' && this.smsReadUnreadCount.total > 0 && this.smsReadUnreadCount.total !== this.smsReadUnreadCount.unRead) || (event === 'All' && this.smsReadUnreadCount.unRead > 0 && this.smsReadUnreadCount.total > 0)) {
                this.selectedCheckboxEvent = event;
            }
            else if (event === 'All' || event === 'None') {
                this.selectedCheckboxEvent = '';
            }
        }
        else if (this.activeTab === 1) {
            if ((event === 'Unread' && this.archiveReadUnreadCount.unRead > 0) || (event === 'Read' && this.archiveReadUnreadCount.total > 0 && this.archiveReadUnreadCount.total !== this.archiveReadUnreadCount.unRead) || (event === 'All' && this.archiveReadUnreadCount.unRead > 0 && this.archiveReadUnreadCount.total > 0)) {
                this.selectedCheckboxEvent = event;
            }
            else if (event === 'All' || event === 'None') {
                this.selectedCheckboxEvent = '';
            }
        }
    };
    MessageCenterComponent.prototype.setCountObj = function () {
        var obj = { unRead: 0, total: 0 };
        if (this.activeTab === 0 && this.smsReadUnreadCount) {
            obj.unRead = this.smsReadUnreadCount.unRead;
            obj.total = this.smsReadUnreadCount.total;
        }
        else if (this.activeTab === 1 && this.archiveReadUnreadCount) {
            obj.total = this.archiveReadUnreadCount.total;
            obj.unRead = this.archiveReadUnreadCount.unRead;
        }
        return obj;
    };
    MessageCenterComponent.prototype.disabledTab = function (event) {
        this.isDisabled = event;
    };
    MessageCenterComponent.prototype.onChangeFilter = function () {
        if (this.activeTab === 1) {
            this.getUnArchiveCount();
        }
        this.messageManService.updateMessageFilter(this.isMyMessage);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], MessageCenterComponent.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], MessageCenterComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MessageCenterComponent.prototype, "orgLevelSubscription", void 0);
    return MessageCenterComponent;
}());
export { MessageCenterComponent };
