import { Directive, Input, Output, ElementRef, Renderer, HostListener, OnInit, Host, EventEmitter } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';


@Directive({
  selector: '[slxKendoGridMobileManager]',
})
export class KendoGridMobileManagerDirective implements OnInit {
  @Input()
  public slxGroupable: any;
  @Input()
  public slxGroupableMobile: any;
  @Output()
  public resizeEvent: EventEmitter<{ isMobile: boolean, grid: GridComponent }>;

  private get isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }
  private grid: GridComponent;

  constructor( @Host() grid: GridComponent) {
    this.grid = grid;
    this.resizeEvent = new EventEmitter<{ isMobile: boolean, grid: GridComponent }>();
  }

  public ngOnInit(): void {
    this.manageGrid();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent): void {
    this.manageGrid();
    this.resizeEvent.emit({ isMobile: this.isMobile, grid: this.grid });
  }

  protected manageGrid(): void {
    if (this.isMobile) {
      this.grid.groupable = this.slxGroupableMobile;
      return;
    }
    this.grid.groupable = this.slxGroupableMobile;
  }
}
