import * as _ from 'lodash';
var ScheduleEntryShiftContainer = /** @class */ (function () {
    function ScheduleEntryShiftContainer() {
        this.shiftHours = 0;
        this.unpaidHours = 0;
    }
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "position", {
        get: function () {
            return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.position : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "department", {
        get: function () {
            return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.department : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "selectedShiftDefinition", {
        get: function () {
            return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.shift : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "unit", {
        get: function () {
            return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.unit : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "absence", {
        get: function () {
            return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.scheduleAbsence : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "constraint", {
        get: function () {
            return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.constraint : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "shiftHoursInMinutes", {
        get: function () {
            return this.shiftHours * 60;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "scheduleEntryShift", {
        get: function () {
            return this.m_scheduleEntryShift;
        },
        set: function (value) {
            this.m_scheduleEntryShift = value;
            this.shiftStartDate = value.startDate;
            this.shiftEndDate = value.endDate;
            this.shiftHours = value.hours;
            if (this.m_scheduleEntryShift && this.m_scheduleEntryShift.position) {
                this.previousPositionOrgLevelId = this.m_scheduleEntryShift.position.orgLevelId;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "fullTime", {
        get: function () {
            if (!this.scheduleEntryShift)
                return 0;
            var partialAbsenseHours = this.scheduleEntryShift.partialAbsence ? this.scheduleEntryShift.partialAbsence.hours : 0;
            return this.unpaidHours + this.scheduleEntryShift.hours + partialAbsenseHours;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryShiftContainer.prototype, "constraints", {
        get: function () {
            return this.m_constarints;
        },
        set: function (value) {
            this.m_constarints = value;
            this.hasConstraints = _.isArray(value) && !_.isEmpty(value);
        },
        enumerable: true,
        configurable: true
    });
    return ScheduleEntryShiftContainer;
}());
export { ScheduleEntryShiftContainer };
