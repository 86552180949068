/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scheduled-rotations-avail-shifts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../components-library/components/custom-collapsible-section/custom-collapsible-section.component.ngfactory";
import * as i4 from "../../../../components-library/components/custom-collapsible-section/custom-collapsible-section.component";
import * as i5 from "../../../../components-library/services/collapsible-section.service";
import * as i6 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i7 from "../../../../common/components/spinner/spinner.component";
import * as i8 from "./scheduled-rotations-avail-shifts.component";
import * as i9 from "../../../services/scheduled-rotation-template/schedule-rotations-management.service";
import * as i10 from "../../../services/scheduled-rotation-template/scheduled-rotation-api.service";
import * as i11 from "../../../services/scheduled-rotation-template/scheduled-rotation-map.service";
var styles_ScheduledRotationsAvailShiftsComponent = [i0.styles];
var RenderType_ScheduledRotationsAvailShiftsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduledRotationsAvailShiftsComponent, data: {} });
export { RenderType_ScheduledRotationsAvailShiftsComponent as RenderType_ScheduledRotationsAvailShiftsComponent };
function View_ScheduledRotationsAvailShiftsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "card-count-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "card-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.count; _ck(_v, 2, 0, currVal_0); }); }
function View_ScheduledRotationsAvailShiftsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "shift-details-box-text"]], [[8, "draggable", 0], [8, "title", 0]], [[null, "dragstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragstart" === en)) {
        var pd_0 = (_co.drag($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "time-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "shift-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduledRotationsAvailShiftsComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = "shift-details-box-text"; var currVal_3 = ((_v.parent.context.$implicit.shifts.length > 3) ? "shift-details-box-text-active" : "shift-details-box-text"); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_6 = (_v.context.$implicit.count > 1); _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasPermissions; var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.positionName, ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.shiftName; _ck(_v, 5, 0, currVal_4); var currVal_5 = _v.context.$implicit.unitName; _ck(_v, 7, 0, currVal_5); }); }
function View_ScheduledRotationsAvailShiftsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-shift-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Shifts Available"]))], null, null); }
function View_ScheduledRotationsAvailShiftsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "main-layout-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "week-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "shift-details-box"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduledRotationsAvailShiftsComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduledRotationsAvailShiftsComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = "shift-details-box"; var currVal_2 = ((_v.context.$implicit.shifts.length > 3) ? "shift-details-box-active" : "shift-details-box"); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.shifts; _ck(_v, 8, 0, currVal_3); var currVal_4 = (_v.context.$implicit.shifts.length === 0); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.dateName(_v.context.$implicit.day))); _ck(_v, 2, 0, currVal_0); }); }
export function View_ScheduledRotationsAvailShiftsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 7, "slx-custom-collapsible-section", [["class", "available-shifts-collapse"], ["info", "Drag a shift to add rotation"], ["mode", "white"], ["title", "Available Shifts"]], null, [[null, "expandChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandChanged" === en)) {
        var pd_0 = (_co.getExpandChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CustomCollapsibleSectionComponent_0, i3.RenderType_CustomCollapsibleSectionComponent)), i1.ɵdid(2, 770048, null, 0, i4.CustomCollapsibleSectionComponent, [i5.CollapsibleSectionService], { title: [0, "title"], info: [1, "info"], counter: [2, "counter"], mode: [3, "mode"] }, { expandChanged: "expandChanged" }), (_l()(), i1.ɵeld(3, 0, null, 0, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "slx-spinner", [["class", "spinner-active"]], null, null, null, i6.View_SpinnerComponent_0, i6.RenderType_SpinnerComponent)), i1.ɵdid(5, 49152, null, 0, i7.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "div", [["class", "main-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduledRotationsAvailShiftsComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Available Shifts"; var currVal_1 = "Drag a shift to add rotation"; var currVal_2 = (_co.manService.totalAvailShifts ? _co.manService.totalAvailShifts : 0); var currVal_3 = "white"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.manService.availShiftLoader; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.ShiftList; _ck(_v, 8, 0, currVal_5); }, null); }
export function View_ScheduledRotationsAvailShiftsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-scheduled-rotations-avail-shifts", [], null, null, null, View_ScheduledRotationsAvailShiftsComponent_0, RenderType_ScheduledRotationsAvailShiftsComponent)), i1.ɵdid(1, 638976, null, 0, i8.ScheduledRotationsAvailShiftsComponent, [i9.ScheduleRotationsManagementService, i10.ScheduleRotationApiService, i11.ScheduledRotationMapService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduledRotationsAvailShiftsComponentNgFactory = i1.ɵccf("slx-scheduled-rotations-avail-shifts", i8.ScheduledRotationsAvailShiftsComponent, View_ScheduledRotationsAvailShiftsComponent_Host_0, { orgLevelId: "orgLevelId", startDate: "startDate", endDate: "endDate" }, {}, []);
export { ScheduledRotationsAvailShiftsComponentNgFactory as ScheduledRotationsAvailShiftsComponentNgFactory };
