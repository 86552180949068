/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-cycle-summary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../schedule-cycle-summary-grid/schedule-cycle-summary-grid.component.ngfactory";
import * as i3 from "../schedule-cycle-summary-grid/schedule-cycle-summary-grid.component";
import * as i4 from "./schedule-cycle-summary.component";
var styles_ScheduleCycleSummaryComponent = [i0.styles];
var RenderType_ScheduleCycleSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleCycleSummaryComponent, data: {} });
export { RenderType_ScheduleCycleSummaryComponent as RenderType_ScheduleCycleSummaryComponent };
export function View_ScheduleCycleSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "slx-high-box__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "slx-schedule-cycle-summary-grid", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.onAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ScheduleCycleSummaryGridComponent_0, i2.RenderType_ScheduleCycleSummaryGridComponent)), i1.ɵdid(3, 245760, null, 0, i3.ScheduleCycleSummaryGridComponent, [i1.ChangeDetectorRef], { summary: [0, "summary"] }, { actionEvent: "actionEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.summary; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ScheduleCycleSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-schedule-cycle-summary", [], null, null, null, View_ScheduleCycleSummaryComponent_0, RenderType_ScheduleCycleSummaryComponent)), i1.ɵdid(1, 49152, null, 0, i4.ScheduleCycleSummaryComponent, [], null, null)], null, null); }
var ScheduleCycleSummaryComponentNgFactory = i1.ɵccf("slx-schedule-cycle-summary", i4.ScheduleCycleSummaryComponent, View_ScheduleCycleSummaryComponent_Host_0, { summary: "summary" }, { actionEvent: "actionEvent" }, []);
export { ScheduleCycleSummaryComponentNgFactory as ScheduleCycleSummaryComponentNgFactory };
