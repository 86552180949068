/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "./benefit-details-toolbar/benefit-details-toolbar.component.ngfactory";
import * as i5 from "./benefit-details-toolbar/benefit-details-toolbar.component";
import * as i6 from "../../services/benefit-details/benefit-details-management.service";
import * as i7 from "../../services/benefit-details/benefit-details-permission.service";
import * as i8 from "@angular/router";
import * as i9 from "./benefit-details-tabs/benefit-details-tabs.component.ngfactory";
import * as i10 from "./benefit-details-tabs/benefit-details-tabs.component";
import * as i11 from "../../../../common/services/device-detector/device-detector.service";
import * as i12 from "../../services/benefit-details/calculation-common.service";
import * as i13 from "../../../../common/services/component-state/component-state-storage.service";
import * as i14 from "../../../../core/services/user-settings/user-settings.service";
import * as i15 from "../../../../common/services/app-user-settings/app-user-settings.service";
import * as i16 from "../../../../common/services/state-management/state-management.service";
import * as i17 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i18 from "../../services/benefit-console/benefit-console-details-common.service";
import * as i19 from "../../services/benefit-details/benefit-details-api.service";
import * as i20 from "../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i21 from "../../../../organization/services/org-level/org-level-watch.service";
import * as i22 from "../../services/benefit-eligibility-rules/benefit-eligibility-rules-management.service";
import * as i23 from "../../../../organization/services/lookup/lookup-api.service";
import * as i24 from "../../../../common/services/change-management/change-management.service";
import * as i25 from "../../../../app-settings/services/app-settings-manage.service";
import * as i26 from "./benefit-details.component";
var styles_BenefitDetailsComponent = [i0.styles];
var RenderType_BenefitDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitDetailsComponent, data: {} });
export { RenderType_BenefitDetailsComponent as RenderType_BenefitDetailsComponent };
export function View_BenefitDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "section", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-benefit-details-toolbar", [["class", "slx-content-toolbar-indents"]], null, null, null, i4.View_BenefitDetailsToolbarComponent_0, i4.RenderType_BenefitDetailsToolbarComponent)), i1.ɵdid(4, 245760, null, 0, i5.BenefitDetailsToolbarComponent, [i6.BenefitDetailsManagementService, i7.BenefitDetailsPermissionService, i8.ActivatedRoute, i8.Router], { benefitDeduction2: [0, "benefitDeduction2"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-benefit-details-tabs", [["class", "slx-high-box__body slx-main-content-indents benefits"]], null, null, null, i9.View_BenefitDetailsTabsComponent_0, i9.RenderType_BenefitDetailsTabsComponent)), i1.ɵdid(6, 245760, null, 0, i10.BenefitDetailsTabsComponent, [i6.BenefitDetailsManagementService, i7.BenefitDetailsPermissionService, i11.DeviceDetectorService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.enableBenefitDeduction2; _ck(_v, 4, 0, currVal_1); _ck(_v, 6, 0); }, null); }
export function View_BenefitDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "slx-benefit-details", [], null, null, null, View_BenefitDetailsComponent_0, RenderType_BenefitDetailsComponent)), i1.ɵprd(4608, null, i12.CalculationCommonService, i12.CalculationCommonService, []), i1.ɵprd(512, null, i7.BenefitDetailsPermissionService, i7.BenefitDetailsPermissionService, []), i1.ɵprd(512, null, i13.ComponentStateStorageService, i13.ComponentStateStorageService, [i14.UserSettingsService, i15.AppUserSettingsService]), i1.ɵprd(512, null, i16.StateManagementService, i16.StateManagementService, [i13.ComponentStateStorageService, i17.ColumnSettingsStorageService]), i1.ɵprd(512, null, i18.BenefitConsoleDetailsCommonService, i18.BenefitConsoleDetailsCommonService, [i13.ComponentStateStorageService, i16.StateManagementService]), i1.ɵprd(512, null, i6.BenefitDetailsManagementService, i6.BenefitDetailsManagementService, [i7.BenefitDetailsPermissionService, i19.BenefitDetailsApiService, i16.StateManagementService, i13.ComponentStateStorageService, i20.NotificationsService, i21.OrgLevelWatchService, i22.BenefitEligibilityRulesManagementService, i23.LookupApiService, i18.BenefitConsoleDetailsCommonService, i24.ChangeManagementService, i25.AppSettingsManageService]), i1.ɵdid(7, 245760, null, 0, i26.BenefitDetailsComponent, [i6.BenefitDetailsManagementService, i7.BenefitDetailsPermissionService, i8.Router, i8.ActivatedRoute, i25.AppSettingsManageService], null, null)], function (_ck, _v) { _ck(_v, 7, 0); }, null); }
var BenefitDetailsComponentNgFactory = i1.ɵccf("slx-benefit-details", i26.BenefitDetailsComponent, View_BenefitDetailsComponent_Host_0, {}, {}, []);
export { BenefitDetailsComponentNgFactory as BenefitDetailsComponentNgFactory };
