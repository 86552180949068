/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cost-centers-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./cost-centers-toolbar.component";
import * as i4 from "../../../services/cost-centers/cost-centers-configuration-management.service";
var styles_CostCentersToolbarComponent = [i0.styles];
var RenderType_CostCentersToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CostCentersToolbarComponent, data: {} });
export { RenderType_CostCentersToolbarComponent as RenderType_CostCentersToolbarComponent };
function View_CostCentersToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon slx-min-w90"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-plus-circle slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add"]))], null, null); }
function View_CostCentersToolbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon slx-min-w90 slx-mobile-adapted delete-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDeleteItems() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-trash-alt slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, null); }
export function View_CostCentersToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "slx-content-toolbar-indents flex-container action-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "buttons-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CostCentersToolbarComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CostCentersToolbarComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.management.state.canAdd; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.management.state.canDelete; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_CostCentersToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-cost-centers-toolbar", [], null, null, null, View_CostCentersToolbarComponent_0, RenderType_CostCentersToolbarComponent)), i1.ɵdid(1, 49152, null, 0, i3.CostCentersToolbarComponent, [i4.CostCentersConfigurationManagementService], null, null)], null, null); }
var CostCentersToolbarComponentNgFactory = i1.ɵccf("slx-cost-centers-toolbar", i3.CostCentersToolbarComponent, View_CostCentersToolbarComponent_Host_0, {}, {}, []);
export { CostCentersToolbarComponentNgFactory as CostCentersToolbarComponentNgFactory };
