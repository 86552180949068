<div class="accruals-toolbar">
  <slx-toolbar class="accruals-toolbar__helper" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate alignMode="left" [width]="dateRangeWidth">
      <span class="accruals-toolbar__control">
        <slx-daterange class="slx-blue-btn accruals-toolbar__range"
                       name="startEndDate"
                       startDatePlaceholder="Start Date"
                       endDatePlaceholder="End Date"
                       [hasApplyButton]="isDesktop"
                       [disableFutureDates]="false"
                       [maxSecRange]="maxSecondsRange"
                       [maxRangeErrorText]="maxRangeError"
                       [startDate]="dateRange.startDate"
                       [endDate]="dateRange.endDate"
                       (rangeDateChanged)="onChangeDates($event)"></slx-daterange>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="35">
      <span class="accruals-toolbar__control as-flex-start mt-16" *ngIf="!isCollapsed">
        <slx-columns-config-button [colGroupKey]="columnsStateName"
                                   [blueStyleConfig]="{ btnClass: 'slx-button slx-only-icon slx-toolbar', iconClass: 'fa fa-cog' }"
                                   title="Columns settings"></slx-columns-config-button>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="95">
      <span class="accruals-toolbar__control as-flex-start mt-16" *ngIf="!isCollapsed">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>
            <slx-action-list-item (onClick)="onClickExport(true)">Export to PDF</slx-action-list-item>
            <slx-action-list-item *ngIf="calculateAccrualsAction" (onClick)="onClickRecalcAccruals()">Recalculate Accruals</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </span>
    </ng-template>
  </slx-toolbar>
</div>
