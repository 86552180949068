import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { Assert } from '../../../../framework/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Meta, ResponseBody } from '../../../../core/models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { pbjConfig } from '../../pbj.config';

import { PbjReconciliationMapService } from './pbj-reconciliation-map.service';
import {
  IPBJRecOrgEntry,
  PBJRecOrgEntry,
  IPBJRecDepEntry,
  PBJRecDepEntry,
  IPBJRecEmpEntry,
  PBJRecEmpEntry,
  IPBJRecDailyEntry,
  PBJRecDailyEntry,
} from '../../models/index';

@Injectable()
export class PbjReconciliationApiService {
  constructor(
    public apiUtilService: ApiUtilService,
    public urlParamsService: UrlParamsService,
    public mapService: PbjReconciliationMapService
  ) {}

  public getOrgEntries(orgLevelId: number, startDate: Date, endDate: Date): Promise<PBJRecOrgEntry[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getApiRoot()}/corporation`;
    const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      orgLevelId,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    return this.apiUtilService.request<IPBJRecOrgEntry[], Meta>(request)
      .then((response: ResponseBody<IPBJRecOrgEntry[], Meta>) => this.mapService.mapToOrgEntries(response.data));
  }

  public getDepEntries(orgLevelId: number, startDate: Date, endDate: Date): Promise<PBJRecDepEntry[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getApiRoot()}/organization`;
    const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      orgLevelId,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    return this.apiUtilService.request<IPBJRecDepEntry[], Meta>(request)
      .then((response: ResponseBody<IPBJRecDepEntry[], Meta>) => this.mapService.mapToDepEntries(response.data));
  }

  public getEmpEntries(orgLevelId: number, startDate: Date, endDate: Date): Promise<PBJRecEmpEntry[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getApiRoot()}/department`;
    const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      orgLevelId,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    return this.apiUtilService.request<IPBJRecEmpEntry[], Meta>(request)
      .then((response: ResponseBody<IPBJRecEmpEntry[], Meta>) => this.mapService.mapToEmpEntries(response.data));
  }

  public getDailyEntries(orgLevelId: number ,empId: number, startDate: Date, endDate: Date): Promise<PBJRecDailyEntry[]> {
    Assert.isNotNull(empId, 'empId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');
 
    const url: string = `${this.getApiRoot()}/employee`;
    const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      orgLevelId,
      employeeId: empId,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    return this.apiUtilService.request<IPBJRecDailyEntry[], Meta>(request)
      .then((response: ResponseBody<IPBJRecDailyEntry[], Meta>) => this.mapService.mapToDailyEntries(response.data));
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${pbjConfig.api.pbj.root}/${pbjConfig.api.pbj.reconciliation.root}`;
  }
}

