import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MasterScheduleTotalsSettings, TotalsDescription } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-master-schedule-totals-settings',
  templateUrl: 'master-schedule-totals-settings.component.html',
  styleUrls: ['master-schedule-totals-settings.component.scss']
})
export class MasterScheduleTotalsSettingsComponent {
  @Input()
  public totals: MasterScheduleTotalsSettings;
  @Output()
  public totalsChanged: EventEmitter<MasterScheduleTotalsSettings>;

  public totalsDescription: TotalsDescription;
  constructor() {
    this.totalsChanged = new EventEmitter<MasterScheduleTotalsSettings>();
    this.totalsDescription = TotalsDescription;
  }

  public getTooltip(code: string): string {
    return TotalsDescription.getTooltip(code);
  }
}
