/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./portal-employee.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./portal-employee.component";
var styles_PortalEmployeeComponent = [i0.styles];
var RenderType_PortalEmployeeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PortalEmployeeComponent, data: {} });
export { RenderType_PortalEmployeeComponent as RenderType_PortalEmployeeComponent };
export function View_PortalEmployeeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [["name", "rightSidebar"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "rightSidebar"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "employees-content"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(6, { "left-sidebar-open": 0 }), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "employees-content"; var currVal_1 = _ck(_v, 6, 0, i1.ɵunv(_v, 4, 1, i1.ɵnov(_v, 5).transform(_co.isLeftSidebarOpen))); _ck(_v, 4, 0, currVal_0, currVal_1); _ck(_v, 8, 0); }, null); }
export function View_PortalEmployeeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-portal-employee", [], null, null, null, View_PortalEmployeeComponent_0, RenderType_PortalEmployeeComponent)), i1.ɵdid(1, 180224, null, 0, i4.PortalEmployeeComponent, [], null, null)], null, null); }
var PortalEmployeeComponentNgFactory = i1.ɵccf("slx-portal-employee", i4.PortalEmployeeComponent, View_PortalEmployeeComponent_Host_0, {}, {}, []);
export { PortalEmployeeComponentNgFactory as PortalEmployeeComponentNgFactory };
