import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { BreadcrumbItem } from '../../models/index';
var BreadcrumbStateService = /** @class */ (function () {
    function BreadcrumbStateService() {
        this.ignoreUrlParmeters = ['orgLevelId'];
        this.changeNotify$ = new Subject();
        this.clickOnBreadcrumb$ = new Subject();
        this.currentState = {};
    }
    Object.defineProperty(BreadcrumbStateService.prototype, "breadcrumbs", {
        get: function () {
            return this.m_breadcrumbs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BreadcrumbStateService.prototype, "icon", {
        get: function () {
            return this.m_icon;
        },
        enumerable: true,
        configurable: true
    });
    BreadcrumbStateService.prototype.configure = function (config) {
        this.config = config;
        this.routeStack = [];
    };
    BreadcrumbStateService.prototype.addNameCallback = function (id, callback) {
        var result = this.findConfigById(id);
        _.forEach(result, function (conf) {
            conf.nameCallback = callback;
        });
    };
    BreadcrumbStateService.prototype.addIconCallback = function (id, callback) {
        var result = this.findConfigById(id);
        _.forEach(result, function (conf) {
            conf.iconCallback = callback;
        });
    };
    BreadcrumbStateService.prototype.addIsHiddenCallback = function (id, callback) {
        var result = this.findConfigById(id);
        _.forEach(result, function (conf) {
            conf.isHidden = callback;
        });
    };
    BreadcrumbStateService.prototype.addTransformCallback = function (id, callback) {
        var result = this.findConfigById(id);
        _.forEach(result, function (conf) {
            conf.transformCallback = callback;
        });
    };
    BreadcrumbStateService.prototype.findConfigById = function (id) {
        Assert.isNotNull(this.config, 'BreadcrumbStateService service not configured');
        var result = [];
        if (this.config.id === id) {
            result.push(this.config);
        }
        var nestedResult = this.findConfigByIdInChilds(id, this.config.childs, true);
        result.push.apply(result, nestedResult);
        return result;
    };
    BreadcrumbStateService.prototype.clickOnBreadcrumb = function (item) {
        this.clickOnBreadcrumb$.next(item);
    };
    BreadcrumbStateService.prototype.subscribeToClickOnBreadcrumb = function (callback) {
        if (!_.isFunction(callback))
            throw new TypeError('Cannot subscribe to "clickOnBreadcrumb$", "callback" is not a function');
        return this.clickOnBreadcrumb$.subscribe(callback);
    };
    BreadcrumbStateService.prototype.restoreLastRoute = function () {
        if (this.m_lastRoutesPathToRestore) {
            this.lastRoutesPath = this.m_lastRoutesPathToRestore;
        }
        if (this.m_routeStackToRestore) {
            this.routeStack = this.m_routeStackToRestore;
        }
        this.resetLastRouteBackup();
    };
    BreadcrumbStateService.prototype.resetLastRouteBackup = function () {
        this.m_lastRoutesPathToRestore = null;
        this.m_routeStackToRestore = null;
    };
    BreadcrumbStateService.prototype.popPrevRoute = function () {
        this.m_lastRoutesPathToRestore = _.map(this.lastRoutesPath);
        this.m_routeStackToRestore = _.map(this.routeStack);
        if (this.routeStack.length > 1) {
            this.routeStack.pop();
            return this.routeStack.pop();
        }
        if (this.lastRoutesPath.length > 1) {
            var lastRoute = this.lastRoutesPath[this.lastRoutesPath.length - 2];
            return lastRoute.linkPath;
        }
        return null;
    };
    BreadcrumbStateService.prototype.removePrevRoute = function () {
        if (this.routeStack.length > 1) {
            this.routeStack.pop();
        }
    };
    BreadcrumbStateService.prototype.buildBreadcrumbs = function (eventUrl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentUrl, state, result, urlsArray, items, removeIgnores, temp;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        state = {};
                        urlsArray = eventUrl.slice(1).split('/');
                        result = this.processUrl(state, '', urlsArray, 0, 0, null);
                        Assert.isFalse(!result.parseOk, result.message);
                        this.currentState = state;
                        items = _.values(state);
                        this.resolveIcon(items);
                        return [4 /*yield*/, this.resolveMandatoryPromises(items)];
                    case 1:
                        _a.sent();
                        removeIgnores = _.filter(items, function (item) {
                            return !item.isHidden;
                        });
                        if (removeIgnores.length > 1) {
                            removeIgnores = _.slice(removeIgnores, 1);
                        }
                        temp = [];
                        _.forEach(removeIgnores, function (item) {
                            if (item.config.prefix) {
                                var prefix = _this.createItem(item.linkPath, item.config.prefix.id, item.config.prefix, item.queryParams);
                                temp.push(prefix);
                            }
                            temp.push(item);
                        });
                        removeIgnores = temp;
                        if (!this.equalsConfigPath(items, this.lastRoutesPath)) {
                            if (this.isRedirectConfigPath(items, this.lastRoutesPath)) {
                                this.removePrevRoute();
                            }
                            this.routeStack.push(eventUrl);
                        }
                        this.lastRoutesPath = items;
                        this.m_breadcrumbs = removeIgnores;
                        setTimeout(function () { return _this.changeNotify$.next(_this.m_breadcrumbs); }, 1);
                        return [2 /*return*/, removeIgnores];
                }
            });
        });
    };
    BreadcrumbStateService.prototype.resolveIcon = function (urls) {
        var icon;
        _.forEach(urls, function (url) {
            if (url.icon) {
                icon = url.icon;
            }
        });
        this.m_icon = icon;
    };
    BreadcrumbStateService.prototype.resolveMandatoryPromises = function (items) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var itemsWithIsHiddenPromises, isHiddenPromises;
            return tslib_1.__generator(this, function (_a) {
                itemsWithIsHiddenPromises = _.filter(items, function (item) {
                    return item.isHidden instanceof Promise;
                });
                isHiddenPromises = _.map(itemsWithIsHiddenPromises, function (item) {
                    return item.isHidden.then(function (value) { return item.isHidden = value; });
                });
                return [2 /*return*/, Promise.all(isHiddenPromises)];
            });
        });
    };
    BreadcrumbStateService.prototype.processUrl = function (state, currentUrl, urlsArray, srcIndex, dstIndex, currentConfNode) {
        var result;
        var routeConfig;
        var next = this.getNextUrl(urlsArray, srcIndex);
        //input route path in ended
        if (!next) {
            return { parseOk: true, resultUrl: currentUrl };
        }
        var queryParams = next.queryParams;
        var itemUrl = next.url;
        var url = currentUrl + "/" + itemUrl;
        var currentRouteItem = this.currentState[dstIndex];
        //current route is ended
        if (!currentRouteItem) {
            routeConfig = this.findNextConfig(itemUrl, currentConfNode);
            if (!routeConfig) {
                return { parseOk: false, resultUrl: url, message: "id " + itemUrl + " not found in childs " + url + ", check br route config" };
            }
            if (routeConfig.mustRefreshEveryTime || routeConfig.id !== itemUrl) {
                //chek value === exist route, dynamic cannot
                var exist = _.first(this.findConfigById(itemUrl));
                if (exist && exist.mustRefreshEveryTime) {
                    return { parseOk: false, resultUrl: url, message: "id " + itemUrl + " cannot be existing config id and be isDynamic value " + url + ", check br route config" };
                }
                else if (exist && routeConfig.id !== itemUrl) {
                    return { parseOk: false, resultUrl: url, message: "id " + itemUrl + " cannot be existing config id and it's NOT isDynamic value " + url + ", check br route config" };
                }
            }
            state[dstIndex] = this.createItem(url, itemUrl, routeConfig, queryParams);
            return this.processUrl(state, url, urlsArray, srcIndex + 1, dstIndex + 1, routeConfig);
        }
        //already in path
        if (currentRouteItem.config.id === itemUrl) {
            if (currentRouteItem.config.mustRefreshEveryTime) {
                state[dstIndex] = this.createItem(url, itemUrl, currentRouteItem.config, queryParams);
            }
            else {
                state[dstIndex] = currentRouteItem;
                this.assignQueryParams(currentRouteItem, next.queryParams, currentRouteItem.config);
            }
            return this.processUrl(state, url, urlsArray, srcIndex + 1, dstIndex + 1, currentRouteItem.config);
        }
        //changed path
        if (currentRouteItem.config.canVirtual) {
            var res = this.processUrl(state, currentUrl, urlsArray, srcIndex, dstIndex + 1, currentRouteItem.config);
            if (res.parseOk) {
                state[srcIndex] = currentRouteItem;
                this.assignQueryParams(currentRouteItem, next.queryParams, currentRouteItem.config);
                return res;
            }
        }
        //already in path isDynamic
        if (currentRouteItem.config.isDynamic) {
            //chek value === exist route, dynamic cannot
            var exist = this.findConfigById(itemUrl);
            if (!exist || exist.length === 0) {
                if (currentRouteItem.config.mustRefreshEveryTime) {
                    state[dstIndex] = this.createItem(url, itemUrl, currentRouteItem.config, queryParams);
                }
                else {
                    state[dstIndex] = currentRouteItem;
                    this.assignQueryParams(currentRouteItem, next.queryParams, currentRouteItem.config);
                }
                return this.processUrl(state, url, urlsArray, srcIndex + 1, dstIndex + 1, currentRouteItem.config);
            }
            return { parseOk: false, resultUrl: url, message: "id " + itemUrl + " cannot be existing config id and be isDynamic value " + url + ", check br route config" };
        }
        //not found in virtual, creation of new path
        this.clearCurrentState(srcIndex);
        routeConfig = this.findNextConfig(itemUrl, currentConfNode);
        if (!routeConfig) {
            return { parseOk: false, resultUrl: url, message: "id " + itemUrl + " not found in childs " + url + ", check br route config" };
        }
        state[dstIndex] = this.createItem(url, itemUrl, routeConfig, queryParams);
        return this.processUrl(state, url, urlsArray, srcIndex + 1, dstIndex + 1, routeConfig);
    };
    BreadcrumbStateService.prototype.getNextUrl = function (urlsArray, index) {
        if (index >= urlsArray.length) {
            return null;
        }
        var url = urlsArray[index];
        var val = url.split('?');
        var queryParams;
        if (val.length > 1) {
            queryParams = this.parseQuery(val[1]);
        }
        return { url: val[0], queryParams: queryParams };
    };
    BreadcrumbStateService.prototype.parseQuery = function (queryString) {
        var query = {};
        var pairs = queryString.split('&');
        _.forEach(pairs, function (pair) {
            var p = pair.split('=');
            query[decodeURIComponent(p[0])] = decodeURIComponent(p[1] || '');
        });
        return query;
    };
    BreadcrumbStateService.prototype.findConfigByIdInChilds = function (id, childs, checkNested) {
        var _this = this;
        var result = [];
        if (!childs) {
            return null;
        }
        _.forEach(childs, function (child) {
            if (child.id === id) {
                result.push(child);
                return;
            }
            if (checkNested) {
                var nestedResult = _this.findConfigByIdInChilds(id, child.childs, true);
                result.push.apply(result, nestedResult);
            }
        });
        return result;
    };
    BreadcrumbStateService.prototype.findNextConfig = function (id, root) {
        if (root === null) {
            return this.config;
        }
        if (!root) {
            return null;
        }
        var result = null;
        if (!root.childs) {
            return null;
        }
        _.forEach(root.childs, function (child) {
            if (child.id === id || child.isDynamic) {
                result = child;
                return;
            }
        });
        return result;
    };
    BreadcrumbStateService.prototype.createItem = function (currentUrl, itemUrl, currentNode, queryParams) {
        var routeItem = new BreadcrumbItem();
        routeItem.config = currentNode;
        routeItem.displayType = currentNode.displayType ? currentNode.displayType : 'link';
        routeItem.isNotLink = currentNode.isNotLink;
        this.assignCallbacks(routeItem, currentUrl, itemUrl, currentNode);
        this.assignQueryParams(routeItem, queryParams, currentNode);
        return routeItem;
    };
    BreadcrumbStateService.prototype.assignQueryParams = function (routeItem, queryParams, currentNode) {
        if (queryParams && _.isNil(currentNode.transformCallback)) {
            routeItem.queryParamsLink = _.omit(queryParams, this.ignoreUrlParmeters);
            routeItem.queryParams = queryParams;
        }
    };
    BreadcrumbStateService.prototype.assignCallbacks = function (routeItem, currentUrl, itemUrl, currentNode) {
        if (currentNode.nameCallback) {
            routeItem.title = currentNode.nameCallback(currentUrl, itemUrl);
            routeItem.isAsync = (routeItem.title instanceof Promise);
        }
        else {
            routeItem.title = currentNode.title;
        }
        if (currentNode.iconCallback) {
            routeItem.icon = currentNode.iconCallback(currentUrl, itemUrl);
        }
        else {
            routeItem.icon = currentNode.icon;
        }
        if (_.isFunction(currentNode.isHidden)) {
            routeItem.isHidden = currentNode.isHidden(currentUrl, itemUrl);
        }
        else {
            routeItem.isHidden = currentNode.isHidden;
        }
        if (currentNode.transformCallback) {
            var data = currentNode.transformCallback(currentUrl);
            routeItem.linkPath = data.path;
            routeItem.queryParams = data.queryParams;
            routeItem.queryParamsLink = data.queryParams;
        }
        else {
            routeItem.linkPath = currentUrl;
        }
    };
    BreadcrumbStateService.prototype.clearCurrentState = function (fromIndex) {
        var _this = this;
        var times = _.keys(this.currentState).length - fromIndex;
        _.times(times, function (num) {
            _this.currentState[num + fromIndex] = undefined;
        });
    };
    BreadcrumbStateService.prototype.equalsConfigPath = function (path1, path2) {
        if (!path1 && !path2) {
            return true;
        }
        if ((!path1 && path2) || (path1 && !path2)) {
            return false;
        }
        if (path1.length !== path2.length) {
            return false;
        }
        var res = true;
        _.times(path1.length, function (num) {
            var item1 = path1[num];
            var item2 = path2[num];
            if (item1.config.id !== item2.config.id) {
                res = false;
            }
            if (item1.queryParams && !item2.queryParams) {
                res = false;
            }
            else if (!item1.queryParams && item2.queryParams) {
                res = false;
            }
            else if (item1.queryParams && item2.queryParams) {
                _.each(_.keys(item2.queryParams), function (key) {
                    if (_.has(item1.queryParams, key)) {
                        res = false;
                    }
                    else if (_.get(item1.queryParams, key) !== _.get(item2.queryParams, key)) {
                        res = false;
                    }
                });
            }
        });
        return res;
    };
    BreadcrumbStateService.prototype.isRedirectConfigPath = function (path1, path2) {
        if (!path1 && !path2) {
            return false;
        }
        if ((!path1 && path2) || (path1 && !path2)) {
            return false;
        }
        if (path2.length + 1 !== path1.length) {
            return false;
        }
        var lastItem = path1[path2.length];
        if (!lastItem.config.canUseForRedirect) {
            return false;
        }
        var res = true;
        _.times(path2.length, function (num) {
            var item1 = path1[num];
            var item2 = path2[num];
            if (item1.config.id !== item2.config.id) {
                res = false;
            }
        });
        return res;
    };
    return BreadcrumbStateService;
}());
export { BreadcrumbStateService };
