import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/sample';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/zip';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ResponseBody } from '../../core/models/index';
import { Meta } from '../../core/models/api/meta';

import { IPayloadAction } from '../../state-model/models/index';
import { SessionActions } from '../../authentication/actions/index';
import { MasterScheduleActions } from '../store/master-schedule/master-schedule.actions';
import { LookupApiService, ScheduleCycleHelperService } from '../../organization/services/index';
import { ScheduleApiService, MasterScheduleManagementService } from '../services/index';
import { IChangedScheduleEntry, IMasterScheduleFilters } from '../store/master-schedule/master-schedule.types';
import { EmployeeScheduleDefinition, ScheduleCycle, ScheduleEntryDefinition, EmployeeScheduleDefinitionContainer } from '../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../state-model/models/index';
import { Employee, ScheduleTotalSummary, ScheduleEntry } from '../models/index';
import { mutableSelect } from '../../core/decorators/index';

@Injectable()
export class MasterScheduleEpics {
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['masterSchedule', 'filters'])
  public filters$: Observable<IMasterScheduleFilters>;

  constructor(
    private scheduleApiService: ScheduleApiService, private lookupApiService: LookupApiService, private scheduleCycleHelperService: ScheduleCycleHelperService) {
  }

  public entriesChange = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const entriesChange$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) => type === MasterScheduleActions.SCHEDULE_ENTRIES_CHANGE);
    return entriesChange$.
      mergeMap((action: IPayloadAction) => {
        let om: Promise<EmployeeScheduleDefinition>[] = [];
        _.forEach(action.payload.entries, (entry: IChangedScheduleEntry) => {
          om.push(this.updateMasterSchedule(action.payload.orgLevelId, entry));
        });
        return Observable.fromPromise(Promise.all(om)).map((result: EmployeeScheduleDefinition[]) => ({
          type: MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED,
          payload: result
        }))
          .catch((error: any) => Observable.of({ type: MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED_ERROR, payload: { hasError: true, errorMessage: error.message } }));
      });
  }

  public updateMasterSchedule(orgLevelId: number, entry: IChangedScheduleEntry): Promise<EmployeeScheduleDefinition> {
    let p: Promise<EmployeeScheduleDefinition> = this.scheduleCycleHelperService.getScheduleCycleByDate(entry.date, orgLevelId)
      .then((value: ScheduleCycle) => {
        if (!value) {
          return null;
        }
        return this.scheduleApiService.getEmployeeSchedule(orgLevelId, entry.employeeId, value.startDate.toDate(), value.endDate.toDate())
          .then((val: EmployeeScheduleDefinition) => {
            return val;
          });
      });
    return p;
  }

  public fetchMasterScheduleData = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const fetchEmployeesSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) => type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA);
    return fetchEmployeesSchedule$
      .combineLatest(this.orgLevel$.filter((o: OrgLevel) => o.type === OrgLevelType.department), this.filters$)
      .sample(fetchEmployeesSchedule$)
      .mergeMap(([, orgLevel, filters]: [IPayloadAction, OrgLevel, IMasterScheduleFilters]) => {
        return Observable
          .fromPromise(
          Promise.all([
            this.scheduleApiService.getEmployeesSchedule(orgLevel, filters),
            this.scheduleApiService.getTotals(orgLevel, filters)]
          ))
          .map(([container, totals]: [EmployeeScheduleDefinitionContainer, ScheduleTotalSummary]) => ({
            type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS,
            payload: {
              actions: container.actions,
              employees: container.definitions,
              totals
            }
          }))
          .catch((error: any) => Observable.of(
            {
              type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR,
              payload: { hasError: true, errorMessage: error.message }
            }
          ));
      });
  }

  public fetchEmployeesSchedule = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const fetchEmployeesSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) => type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE);
    return fetchEmployeesSchedule$
      .combineLatest(this.orgLevel$.filter((o: OrgLevel) => o.type === OrgLevelType.department), this.filters$)
      .sample(fetchEmployeesSchedule$)
      .mergeMap(([action, orgLevel, filters]: [IPayloadAction, OrgLevel, IMasterScheduleFilters]) => {
        return Observable
          .fromPromise(this.scheduleApiService.getEmployeesSchedule(orgLevel, filters))
          .map((result: EmployeeScheduleDefinitionContainer) => ({
            type: MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS,
            payload: {
              actions: result.actions,
              employees: result.definitions
            }
          }))
          .catch((error: any) => Observable.of({ type: MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR, payload: { hasError: true, errorMessage: error.message } }));
      });
  }

  public fetchTotals = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const fetchTotals$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) => type === MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED ||
        type === MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS ||
        type === MasterScheduleActions.FETCH_TOTALS);
    return fetchTotals$
      .combineLatest(this.orgLevel$.filter((o: OrgLevel) => o.type === OrgLevelType.department), this.filters$)
      .sample(fetchTotals$)
      .mergeMap(([action, orgLevel, filters]: [IPayloadAction, OrgLevel, IMasterScheduleFilters]) => {
        if (action.type === MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED) {
          let rows: EmployeeScheduleDefinition[] = action.payload;
          let dateFirst: moment.Moment = null;
          let dateEnd: moment.Moment = null;
          _.forEach(rows, (row: EmployeeScheduleDefinition) => {
            _.forEach(row.entries, (entry: ScheduleEntryDefinition) => {
              if (!dateFirst || dateFirst.isAfter(entry.dateOn)) {
                dateFirst = moment(entry.dateOn);
              }
              if (!dateEnd || dateEnd.isBefore(entry.dateOn)) {
                dateEnd = moment(entry.dateOn);
              }
            });
          });
          filters.dateFrom = dateFirst.toDate();
          filters.weekNumber = this.scheduleCycleHelperService.calcWeeks(dateFirst, dateEnd);
        }
        return Observable
          .fromPromise(this.scheduleApiService.getTotals(orgLevel, filters))
          .map((result: ScheduleTotalSummary) => ({
            type: MasterScheduleActions.FETCH_TOTALS_SUCCESS,
            payload: result
          }))
          .catch((error: any) => Observable.of({ type: MasterScheduleActions.FETCH_TOTALS_ERROR, payload: { hasError: true, errorMessage: error.message } }));
      });
  }

  public resetPayCycle = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const resetPayCycle$: Observable<IPayloadAction> = action$.filter(
      ({ type }: { type: string }) => type === SessionActions.CLEAR_SESSION);
    return resetPayCycle$.mergeMap((action: IPayloadAction) => {
      return Observable.of(
        {
          type: MasterScheduleActions.MASTER_SCHEDULE_CLEAR_PAYCYCLE,
          payload: null
        });
    });
  }

  public completeDataLoading = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const fetchMasterScheduleData$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS ||
        type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR);
    const fetchEmployeesSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS ||
        type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR);
    const generateSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.GENERATE_SCHEDULE_ERROR ||
        type === MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS);
    const deleteEmpSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.DELETE_EMP_SCHEDULE_ERROR ||
        type === MasterScheduleActions.DELETE_EMP_SCHEDULE_SUCCESS);
    const createEmpRotFromSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_ERROR ||
        type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_SUCCESS);
    return fetchEmployeesSchedule$
      .merge(fetchMasterScheduleData$)
      .merge(deleteEmpSchedule$)
      .merge(createEmpRotFromSchedule$)
      .merge(generateSchedule$).map(() => ({
        type: MasterScheduleActions.COMPLETE_DATA_LOADING
      }));
  }

  public completeLoading = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const fetchMasterScheduleData$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS ||
        type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR);
    const fetchTotals$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.FETCH_TOTALS_SUCCESS ||
        type === MasterScheduleActions.FETCH_TOTALS_ERROR);
    const fetchEmployeesSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS ||
        type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR);
    const generateSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.GENERATE_SCHEDULE_ERROR ||
        type === MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS);
    const deleteEmpSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.DELETE_EMP_SCHEDULE_ERROR ||
        type === MasterScheduleActions.DELETE_EMP_SCHEDULE_SUCCESS);
    const createEmpRotFromSchedule$: Observable<IPayloadAction> = action$
      .filter(({ type }: IPayloadAction) =>
        type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_ERROR ||
        type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_SUCCESS);
    return Observable
      .zip(fetchTotals$, fetchEmployeesSchedule$)
      .merge(fetchMasterScheduleData$)
      .merge(deleteEmpSchedule$)
      .merge(createEmpRotFromSchedule$)
      .merge(generateSchedule$).map(() => ({
        type: MasterScheduleActions.COMPLETE_LOADING
      }));
  }
}
