import { EmployeeFilterRecord } from '../aca-1095-c/index';

export interface IAcaMeasurementState {
  acaMeasurementStateList: AcaMeasurementFilterState[];
}

export const initialAcaMeasurementState: IAcaMeasurementState = {
  acaMeasurementStateList :[]
};

export class AcaMeasurementFilterState {
  public orgLevelId: number;
  public year: number;
  public employeeListFilter: EmployeeFilterRecord[];
  public employeeIdList: EmployeeIdList[];
  public isExpandAll: boolean = false;
}

export class EmployeeIdList {
  public employeeId: number;
  public expand: boolean;
}
