/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../directives/blur-forwarder/blur-forwarder.directive";
import * as i4 from "../../directives/focus-forwarder/focus-forwarder.directive";
import * as i5 from "./checkbox-input.component";
var styles_CheckboxInputComponent = [i0.styles];
var RenderType_CheckboxInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxInputComponent, data: {} });
export { RenderType_CheckboxInputComponent as RenderType_CheckboxInputComponent };
export function View_CheckboxInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["checkboxInput", 1]], null, 12, "div", [], [[1, "class", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "input", [["type", "checkbox"]], [[1, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).onBlur($event) !== false);
        ad = (pd_2 && ad);
    } if (("focus" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8).onFocus($event) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.value = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(7, 16384, null, 0, i3.BlurForwarderDirective, [i1.ElementRef, i1.Renderer], null, null), i1.ɵdid(8, 16384, null, 0, i4.FocusForwarderDirective, [i1.ElementRef, i1.Renderer], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "label", [["class", "checkbox-label"]], [[1, "for", 0]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "span", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = (_co.inEdit ? null : true); var currVal_10 = _co.value; _ck(_v, 4, 0, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.fieldName; var currVal_2 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 6).ngClassValid; var currVal_7 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_11 = _co.fieldName; _ck(_v, 9, 0, currVal_11); var currVal_12 = _co.caption; _ck(_v, 12, 0, currVal_12); }); }
export function View_CheckboxInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-checkbox-input", [], null, null, null, View_CheckboxInputComponent_0, RenderType_CheckboxInputComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.CheckboxInputComponent]), i1.ɵdid(2, 49152, null, 0, i5.CheckboxInputComponent, [i1.ElementRef, i1.ChangeDetectorRef], null, null)], null, null); }
var CheckboxInputComponentNgFactory = i1.ɵccf("slx-checkbox-input", i5.CheckboxInputComponent, View_CheckboxInputComponent_Host_0, { fieldName: "fieldName", placeholder: "placeholder", caption: "caption", className: "className", readonly: "readonly" }, {}, []);
export { CheckboxInputComponentNgFactory as CheckboxInputComponentNgFactory };
