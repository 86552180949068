import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';

import { schedulerConfig } from '../../scheduler.config';
import { ShiftReplacementMapService } from './shift-replacement-map.service';
import {
  ShiftReplacementRequest,
  ShiftNotificationResponse, IShiftNotificationResponse,
  ShiftNotificationRequest, IShiftNotificationRequest,
  ShiftPhoneCall, IShiftPhoneCall,
  ShiftReplacementInfo, IShiftReplacementInfo,
  ShiftReplaceCmd, IShiftReplaceCmd,
  ShiftOpenCmd, IShiftOpenCmd,
  ShiftAddEmployeeCmd, IShiftAddEmployeeCmd
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import * as moment from 'moment';

@Injectable()
export class ShiftReplacementApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private shiftReplacementMapService: ShiftReplacementMapService,
    private urlParamsService: UrlParamsService) {
  }

  public getShiftEligibleEmployees(req: ShiftReplacementRequest, startDate?: moment.Moment): Promise<ShiftReplacementInfo> {
    Assert.isNotNull(req, 'ShiftReplacementRequest');
    const url: string = `${this.getShiftApiRoot(req.shiftId)}/${schedulerConfig.api.schedule.shift.shiftEligibleEmployees}`;
    let params: HttpParams = this.urlParamsService.convertToParams({
      positionId: req.positionId,
      date: dateTimeUtils.convertToDtoString(req.date),
      showAgency: true, //req.showAgencyEmployees, <--TBD
      showAvailability: true, //req.showAvailabilityRecords, <--TBD
      showWorkingInOtherShifts: true, //req.showEmployeesOtherShifts, <--TBD
      scheduleCycleStartDate: moment(startDate).format('MM/DD/YYYY').toString()
    });

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });
    /*
    let promise: Promise<ShiftReplacementInfo> = Promise.resolve(
      this.shiftReplacementMapService.mapShiftReplacementInfo(ShiftReplacementInfo.mock)
    );*/
    let promise: Promise<ShiftReplacementInfo> = this.apiUtilService.request<IShiftReplacementInfo, Meta>(request)
      .then((response: ResponseBody<IShiftReplacementInfo, Meta>) => this.shiftReplacementMapService.mapShiftReplacementInfo(response.data));
    return promise;
  }

  public getShiftNotifications(shiftId: number, positionId: number, date: Date): Promise<ShiftNotificationResponse[]> {
    const url: string = this.getShiftNotificationApiRoot(shiftId);
    let params: HttpParams = this.urlParamsService.convertToParams({
      positionId: positionId,
      date: dateTimeUtils.convertToDtoString(date)
    });

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<ShiftNotificationResponse[]> = this.apiUtilService.request<IShiftNotificationResponse[], Meta>(request)
      .then((response: ResponseBody<IShiftNotificationResponse[], Meta>) => this.shiftReplacementMapService.mapShiftNotificationResponses(response.data));
    return promise;
  }

  public addPhoneCallInfo(shiftId: number, employeeId: number, positionId: number, date: Date, phoneCall: ShiftPhoneCall): Promise<number> {
    Assert.isNotNull(phoneCall, 'phoneCall');
    const url: string = `${this.getShiftNotificationApiRoot(shiftId)}/${schedulerConfig.api.schedule.shift.notifications.addPhoneCallInfo}`;
    let params: HttpParams = this.urlParamsService.convertToParams({
      employeeId: employeeId,
      positionId: positionId,
      date: date.toISOString()
    });
    let modelDTO: IShiftPhoneCall = this.shiftReplacementMapService.mapShiftPhoneCallDTO(phoneCall);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body, {
      params: params
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
    return promise;
  }

  public sendSms(shiftId: number, req: ShiftNotificationRequest): Promise<number> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.getShiftNotificationApiRoot(shiftId)}/${schedulerConfig.api.schedule.shift.notifications.sendSms}`;
    let modelDTO: IShiftNotificationRequest = this.shiftReplacementMapService.mapShiftNotificationRequestDTO(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
    return promise;
  }

  public sendAppNotification(shiftId: number, req: ShiftNotificationRequest): Promise<number> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.getShiftNotificationApiRoot(shiftId)}/${schedulerConfig.api.schedule.shift.notifications.sendAppNotification}`;
    let modelDTO: IShiftNotificationRequest = this.shiftReplacementMapService.mapShiftNotificationRequestDTO(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
    return promise;
  }

  public replaceEmployee(req: ShiftReplaceCmd): Promise<number> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getEmployeeApiRoot(req.replacedEmployeeId)}/${schedulerConfig.api.employee.replace}`;
    let modelDTO: IShiftReplaceCmd = this.shiftReplacementMapService.mapShiftReplaceCmdDTO(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
    return promise;
  }

  public addToShift(req: ShiftAddEmployeeCmd): Promise<any> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getApiRoot()}/${dateTimeUtils.convertToDtoString(req.dateOn)}/${schedulerConfig.api.schedule.shift.root}/${req.shiftId}/${schedulerConfig.api.schedule.shift.fill}`;
    let modelDTO: IShiftAddEmployeeCmd = this.shiftReplacementMapService.mapShiftAddEmployeeCmdDTO(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
    return promise;
  }

  public openShift(req: ShiftOpenCmd): Promise<number> {
    Assert.isNotNull(req, 'req');
    const url: string = `${this.getShiftApiRoot(req.shiftId)}/${schedulerConfig.api.schedule.shift.fill}`;
    let modelDTO: IShiftOpenCmd = this.shiftReplacementMapService.mapShiftOpenCmdDTO(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
    return promise;
  }


  private getShiftNotificationApiRoot(shiftId: number): string {
    return `${this.getShiftApiRoot(shiftId)}/${schedulerConfig.api.schedule.shift.notifications.root}`;
  }

  private getShiftApiRoot(shiftId: number): string {
    return `${this.getApiRoot()}/${schedulerConfig.api.schedule.shift.root}/${shiftId}`;
  }

  private getEmployeeApiRoot(employeeId: number): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.employee.root}/${employeeId}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}`;
  }
}
