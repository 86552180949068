import { StaffingPointsTableCell, IStaffingPointsTableCellDto } from './staffing-points-table-cell';

export class StaffingPointsTableRow {
    public lowLimitRn: number;
    public highLimitRn: number;
    public cells: StaffingPointsTableCell[];

    public rangeString: string;

}

export interface IStaffingPointsTableRowDto {
    lowLimitRn: number;
    highLimitRn: number;
    cells: IStaffingPointsTableCellDto[];
}
