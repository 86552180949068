import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService, CacheUtilService } from '../../../common/services/index';
import { MetaMapService } from '../../../core/services/index';
import { Actions, FieldsMeta, Meta, ResponseBody, MetadataInfo } from '../../../core/models/index';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { configurationConfig } from '../../configuration.config';

import { PbjOrganizationsMapService } from './pbj-organizations-map.service';
import { PbjOrganization, IPbjOrganizationDTO } from '../../models/pbj-organizations/pbj-organization';
import { PbjOrganizationModel, IPbjOrganizationModelDTO } from '../../../configuration/models/pbj-organizations/pbj-organization.model';
import { PbjOrganizationContainer, CCNNumberValidation, ICCNNumberValidation } from '../../models/index';

@Injectable()
export class PbjOrganizationsApiService {

    constructor(private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private mapService: PbjOrganizationsMapService,
        private metaMapService: MetaMapService,
        private cacheUtilService: CacheUtilService) {
    }

    public getPbjOrganizations(): Promise<PbjOrganizationContainer> {

        const url: string = `${this.getConfigurationApiRoot()}`;

        let request: HttpRequest<any> = new HttpRequest('GET', url);

        return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IPbjOrganizationDTO[], Meta>) => {
            let fieldsMeta: FieldsMeta = response.meta as FieldsMeta;
            let actions: Actions = response.meta ? this.metaMapService.mapActions(fieldsMeta) : null;
            let container: PbjOrganizationContainer = this.mapService.mapToPbjorganizationsContainer(response.data, fieldsMeta);
            container.actions = actions;
            return container;
        });
    }

    public saveOrganization(organization: PbjOrganizationModel, orgId: number): Promise<any> {
        const url: string = `${this.getConfigurationApiRoot()}/${orgId}`;

        let body: any = this.mapService.mapToOrganizationDto(organization.pbjOrganization);
        let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

        return this.apiUtilService.request<IPbjOrganizationModelDTO, Meta>(request);
    }

    public validateCCN(value: string, organizationId: number): Promise<CCNNumberValidation> {

        const url: string = `${this.getConfigurationApiRoot()}`
            + `/${configurationConfig.api.configuration.pbjOrganizations.ccn.root}`
            + `/${configurationConfig.api.configuration.pbjOrganizations.ccn.validation}`;

        let body: any = {
            ccnNumber: value,
            organizationId: organizationId
        };
        let request: HttpRequest<any> = new HttpRequest('POST', url, body);

        const promise: Promise<CCNNumberValidation> = this.apiUtilService.request<CCNNumberValidation, Meta>(request)
            .then((response: ResponseBody<ICCNNumberValidation, Meta>) => {
                return this.mapService.mapCCNValidation(response.data);
            });
        return promise;
    }

    private getConfigurationApiRoot(): string {
        return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.pbjOrganizations.root}`;
    }

    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }
}
