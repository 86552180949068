import * as tslib_1 from "tslib";
import { appConfig } from './../../../app.config';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { VersionSubscribeService } from '../../../common/services/index';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(versionSubscribeService) {
        this.versionSubscribeService = versionSubscribeService;
        this.appConfig = appConfig;
        this.versionNumber = '';
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.versionSubscription = this.versionSubscribeService.getAppVersion().subscribe(function (version) {
            _this.versionNumber = _this.getAppVersion(version);
        });
        this.versionSubscribeService.refreshAppVersion();
    };
    FooterComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    FooterComponent.prototype.getAppVersion = function (version) {
        var ver = "v" + version.appVersion;
        if (version.serviceVersion) {
            var serVer = version.serviceVersion.split('.');
            ver = ver + "-" + serVer[serVer.length - 2] + serVer[serVer.length - 1];
        }
        if (version.dbVersion) {
            var dbVer = version.dbVersion.split('.');
            ver = ver + "-" + dbVer[dbVer.length - 2] + dbVer[dbVer.length - 1];
        }
        return ver;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], FooterComponent.prototype, "versionSubscription", void 0);
    return FooterComponent;
}());
export { FooterComponent };
