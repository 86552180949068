<div class="accruals">
  <div class="modal-content">
    <div class="modal-body">
      <form novalidate class="row accruals" *ngIf="hasAccruals">
        <div class="row">
          <div class="col-sm-12  col-md-12 col-lg-12 div-tbl-header">
            <div class="col-md-6 col-lg-6 div-table-col">Accrual Type</div>
            <div class="col-md-6 col-lg-6 ">Balance</div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12  col-lg-12 div-tbl-body"
            *ngFor="let accrual of employeeAccruals?.accruals; let sindex = index">
            <div class="col-md-6 col-lg-6">{{accrual.accrualTypeName}}</div>
            <div class="col-md-6 col-lg-6">{{accrual.balance | slxDecimal24Switch : isShowHighPrecision}}</div>
          </div>
        </div>
      </form>
      <div class="row" *ngIf="!hasAccruals">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <p class="not-found"> <i class="fa fa-info-circle text-danger" aria-hidden="true"></i> 
             No entries found...
          </p>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel">Close</button>
    </div>
  </div>
</div>