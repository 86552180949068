import { Injectable, InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import * as moment from 'moment';
import * as _ from 'lodash';
import { ToolbarBaseService, TOOLBAR_SERVICE } from '../../../../core/services/index';

@Injectable()
export class ScheduleConsoleToolbarService extends ToolbarBaseService<any> {

  public onSettingsChanged$: ReplaySubject<any>;

  constructor() {
    super();
    this.onSettingsChanged$ = new ReplaySubject(1);
  }

  public settingsChanged(columnsSettings: any): void {
    this.onSettingsChanged$.next(columnsSettings);
  }
}
