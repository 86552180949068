import * as tslib_1 from "tslib";
import { Position } from './position';
var PbjActualPosition = /** @class */ (function (_super) {
    tslib_1.__extends(PbjActualPosition, _super);
    function PbjActualPosition() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PbjActualPosition;
}(Position));
export { PbjActualPosition };
