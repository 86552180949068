import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { appConfig } from '../../../../app.config';
import { accrualsConfig } from '../../accruals.config';
import { AccrualPolicy, IAccrualPolicy, DeletePolicy, } from '../../models/index';
import { AccrualsPolicyMapService } from './accruals-policy-map.service';
import { CopyAccrualPolicyResult, ICopyAccrualPolicyResult, ISaveCopyAccrualPolicy } from '../../models/accruals-policy/copy-accruals-policy';
var AccrualsPolicyApiService = /** @class */ (function () {
    function AccrualsPolicyApiService(apiUtilService, accrualsPolicyDefinitionsMapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.accrualsPolicyDefinitionsMapService = accrualsPolicyDefinitionsMapService;
        this.urlParamsService = urlParamsService;
    }
    AccrualsPolicyApiService.prototype.getAccrualPolicyShort = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + accrualsConfig.api.accruals.policy.short.root + "/" + accrualsConfig.api.accruals.policy.short.all;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.accrualsPolicyDefinitionsMapService.mapAccrualPolicyDefinitions(response.data, response.meta, false); })];
            });
        });
    };
    AccrualsPolicyApiService.prototype.getAccrualPolicyDetailed = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + accrualsConfig.api.accruals.policy.detailed.root + "/" + accrualsConfig.api.accruals.policy.detailed.all;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.accrualsPolicyDefinitionsMapService.mapAccrualPolicyDefinitions(response.data, response.meta, true); })];
            });
        });
    };
    AccrualsPolicyApiService.prototype.getAccrualPolicy = function (orgLevelId, policyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + policyId;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.accrualsPolicyDefinitionsMapService.mapAccrualPolicyList(response.data, true); })];
            });
        });
    };
    AccrualsPolicyApiService.prototype.getAccrualPolicyExportToExcel = function (orgLevelId) {
        var url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + accrualsConfig.api.accruals.policy.export.root + "/" + accrualsConfig.api.accruals.policy.export.excel;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.requestForFile(request)
            .then(function (file) { return file; });
        return promise;
    };
    AccrualsPolicyApiService.prototype.getAccrualPolicyExportToPdf = function (orgLevelId) {
        var url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + accrualsConfig.api.accruals.policy.export.root + "/" + accrualsConfig.api.accruals.policy.export.pdf;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.requestForFile(request)
            .then(function (file) { return file; });
        return promise;
    };
    AccrualsPolicyApiService.prototype.editAccrualPolicy = function (orgLevelId, req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + accrualsConfig.api.accruals.policy.updatePolicy.root;
                body = this.accrualsPolicyDefinitionsMapService.reverseMapAccrualPolicy(req);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response.data;
                    })];
            });
        });
    };
    AccrualsPolicyApiService.prototype.updatePolicyName = function (orgLevelId, req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + accrualsConfig.api.accruals.policy.updatePolicyName.root;
                body = this.accrualsPolicyDefinitionsMapService.updatePolicyName(req);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    AccrualsPolicyApiService.prototype.deleteAccrualPolicy = function (orgLevelId, req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + accrualsConfig.api.accruals.policy.deletePolicy.root;
                body = this.accrualsPolicyDefinitionsMapService.deletePolicy(req);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    AccrualsPolicyApiService.prototype.deleteAccrualBenefit = function (orgLevelId, req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + accrualsConfig.api.accruals.policy.deleteBenefit.root;
                body = req;
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    AccrualsPolicyApiService.prototype.getOrganizationNames = function (orgLevelId) {
        var orgLevelDetails = JSON.parse(sessionStorage.getItem('Organizations'));
        var orgLevelData = _.filter(orgLevelDetails, function (item) { return item.id !== orgLevelId && item.orgLevelType === 'Organization'; });
        return this.accrualsPolicyDefinitionsMapService.accrualOrgLevelDetails(orgLevelData);
    };
    AccrualsPolicyApiService.prototype.postCopiedAccrualPolicies = function (orgLevelId, req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.policy.root + "/" + accrualsConfig.api.accruals.policy.copyPolicies.root;
                request = this.urlParamsService.createPostRequest(url, req);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.accrualsPolicyDefinitionsMapService.mapAccrualCopiedPolicyStatus(response.data, response.meta); })];
            });
        });
    };
    AccrualsPolicyApiService.prototype.getAccrualsRootApi = function () {
        return this.getApiRoot() + "/" + accrualsConfig.api.accruals.root;
    };
    AccrualsPolicyApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return AccrualsPolicyApiService;
}());
export { AccrualsPolicyApiService };
