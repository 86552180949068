import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CostCentersConfigurationManagementService } from '../../services/index';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { ModalService } from '../../../common/services/index';
import { screenUtils } from '../../../common/utils/index';
var ConfigureCostCentersComponent = /** @class */ (function () {
    function ConfigureCostCentersComponent(management, modalService) {
        this.modalService = modalService;
        this.management = management;
        this.state = { isLoading: false };
        this.screenUtils = screenUtils;
    }
    ConfigureCostCentersComponent.prototype.isMobile = function () {
        return screenUtils.isMobile;
    };
    ConfigureCostCentersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateSubscription = this.management.onLoadStatus$.subscribe(function (isLoading) {
            _this.state.isLoading = isLoading;
        });
        this.noSelectedSubscription = this.management.noSelectedItem$.subscribe(function () {
            _this.modalService.globalAnchor.openInfoDialog('Info', 'Please, select item');
        });
        this.noSelectedSubscription = this.management.confirmDelete$.subscribe(function (item) {
            _this.modalService.globalAnchor.openConfirmDialog('Confirmation', "There are " + item.employeeCount + " employees currently assigned to this Cost Center. Are you sure you want to remove this cost center?", function (result) {
                if (result) {
                    _this.management.deleteItem(item);
                }
            });
        });
        this.management.init();
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", CostCentersConfigurationManagementService)
    ], ConfigureCostCentersComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureCostCentersComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureCostCentersComponent.prototype, "noSelectedSubscription", void 0);
    return ConfigureCostCentersComponent;
}());
export { ConfigureCostCentersComponent };
