import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DialogOptions, ModalService } from '../../../../common';
import { NotificationsService } from '../../../../core/components';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { OpenConfigOrgLevelType, openPositionControlConfig } from '../../../models';
import { OpenPositionsControlApiService } from '../../../services';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ftMultiplicationValueValidator, ptMultiplicationValueValidator, ptMinValueValidator } from '../../../pipes/opc-config-value-validator';
var OpenPositionControlConfigurationComponent = /** @class */ (function () {
    function OpenPositionControlConfigurationComponent(options, modalService, orgLevelType, openPositionsControlApiService, notificationService, fb) {
        this.openPositionsControlApiService = openPositionsControlApiService;
        this.notificationService = notificationService;
        this.fb = fb;
        this.isLoading = true;
        this.options = options;
        this.modalService = modalService;
        this.orgLevelType = orgLevelType;
        this.newOpenPositionConfigForm();
    }
    Object.defineProperty(OpenPositionControlConfigurationComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    OpenPositionControlConfigurationComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
    };
    OpenPositionControlConfigurationComponent.prototype.newOpenPositionConfigForm = function () {
        this.openPositionConfigForm = this.fb.group({
            ftMaxWeeklyHours: new FormControl(),
            ftMaxHoursPerCalenderDay: new FormControl(),
            ftMaxNoOfDaysPerCalenderWeek: new FormControl(),
            ptMaxWeeklyHours: new FormControl(),
            ptMaxHoursPerCalenderDay: new FormControl(),
            ptMaxNoOfDaysPerCalenderWeek: new FormControl(),
            ftMinWeeklyHours: new FormControl(),
            ptMinWeeklyHours: new FormControl(),
        }, { validator: [ftMultiplicationValueValidator, ptMultiplicationValueValidator, ptMinValueValidator] });
    };
    OpenPositionControlConfigurationComponent.prototype.setValue = function () {
        this.openPositionConfigForm = this.fb.group({
            ftMaxWeeklyHours: new FormControl(this.configData.ftMaxWeeklyHours, [Validators.required, Validators.min(30), Validators.max(65)]),
            ftMaxHoursPerCalenderDay: new FormControl(this.configData.ftMaxHoursPerCalenderDay, [Validators.required, Validators.min(4), Validators.max(24)]),
            ftMaxNoOfDaysPerCalenderWeek: new FormControl(this.configData.ftMaxNoOfDaysPerCalenderWeek, [Validators.required, Validators.min(2), Validators.max(7)]),
            ptMaxWeeklyHours: new FormControl(this.configData.ptMaxWeeklyHours, [Validators.required, Validators.min(5), Validators.max(45)]),
            ptMaxHoursPerCalenderDay: new FormControl(this.configData.ptMaxHoursPerCalenderDay, [Validators.required, Validators.min(5), Validators.max(24)]),
            ptMaxNoOfDaysPerCalenderWeek: new FormControl(this.configData.ptMaxNoOfDaysPerCalenderWeek, [Validators.required, Validators.min(1), Validators.max(7)]),
            ftMinWeeklyHours: new FormControl(this.configData.ftMinWeeklyHours, [Validators.required, Validators.min(4), Validators.max(30)]),
            ptMinWeeklyHours: new FormControl(this.configData.ptMinWeeklyHours, [Validators.required, Validators.min(5), Validators.max(45)]),
        }, { validator: [ftMultiplicationValueValidator, ptMultiplicationValueValidator, ptMinValueValidator] });
    };
    OpenPositionControlConfigurationComponent.prototype.LoadData = function () {
        var _this = this;
        this.isLoading = true;
        this.orglevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
            if (_this.orgLevelType.orgLevelType === 'Organization') {
                _this.openPositionsControlApiService.getOpenPositionControlConfig(_this.currentOrgLevel.id).then(function (data) {
                    _this.configData = data;
                    _this.setValue();
                    _this.isLoading = false;
                });
            }
            if (_this.orgLevelType.orgLevelType === 'Department') {
                _this.openPositionsControlApiService.getOpenPositionControlConfig(_this.orgLevelType.orgLevelId).then(function (data) {
                    _this.configData = data;
                    _this.setValue();
                    _this.isLoading = false;
                });
            }
        });
    };
    OpenPositionControlConfigurationComponent.prototype.resetValuesToDefault = function () {
        this.openPositionConfigForm.controls['ftMaxWeeklyHours'].setValue(this.configData.ftMaxWeeklyHoursDefault);
        this.openPositionConfigForm.controls['ftMaxHoursPerCalenderDay'].setValue(this.configData.ftMaxHoursPerCalenderDayDefault);
        this.openPositionConfigForm.controls['ftMaxNoOfDaysPerCalenderWeek'].setValue(this.configData.ftMaxNoOfDaysPerCalenderWeekDefault);
        this.openPositionConfigForm.controls['ptMaxWeeklyHours'].setValue(this.configData.ptMaxWeeklyHoursDefault);
        this.openPositionConfigForm.controls['ptMaxHoursPerCalenderDay'].setValue(this.configData.ptMaxHoursPerCalenderDayDefault);
        this.openPositionConfigForm.controls['ptMaxNoOfDaysPerCalenderWeek'].setValue(this.configData.ptMaxNoOfDaysPerCalenderWeekDefault);
        this.openPositionConfigForm.controls['ftMinWeeklyHours'].setValue(30);
        this.openPositionConfigForm.controls['ptMinWeeklyHours'].setValue(10);
    };
    OpenPositionControlConfigurationComponent.prototype.onKeydown = function (value) {
        if (!((value.keyCode > 95 && value.keyCode < 106)
            || (value.keyCode > 47 && value.keyCode < 58)
            || value.keyCode == 8)) {
            return false;
        }
    };
    OpenPositionControlConfigurationComponent.prototype.discard = function () {
        this.modalService.closeWindow(this.options.windowUniqueId);
        this.openPositionsControlApiService.isLoadPositionGroupDetails$.next(true);
    };
    OpenPositionControlConfigurationComponent.prototype.save = function () {
        var _this = this;
        var data = new openPositionControlConfig();
        data.orgLevelId = this.configData.orgLevelId;
        data.configurationId = this.configData.configurationId;
        data.ftMaxWeeklyHours = this.openPositionConfigForm.value.ftMaxWeeklyHours;
        data.ftMaxHoursPerCalenderDay = this.openPositionConfigForm.value.ftMaxHoursPerCalenderDay;
        data.ftMaxNoOfDaysPerCalenderWeek = this.openPositionConfigForm.value.ftMaxNoOfDaysPerCalenderWeek;
        data.ptMaxWeeklyHours = this.openPositionConfigForm.value.ptMaxWeeklyHours;
        data.ptMaxHoursPerCalenderDay = this.openPositionConfigForm.value.ptMaxHoursPerCalenderDay;
        data.ptMaxNoOfDaysPerCalenderWeek = this.openPositionConfigForm.value.ptMaxNoOfDaysPerCalenderWeek;
        data.ftMinWeeklyHours = this.openPositionConfigForm.value.ftMinWeeklyHours;
        data.ptMinWeeklyHours = this.openPositionConfigForm.value.ptMinWeeklyHours;
        this.openPositionsControlApiService.saveOpenPositionConfiguration(data).then(function (data) {
            _this.notificationService.success('Configuration saved successfully');
            _this.discard();
        });
    };
    Object.defineProperty(OpenPositionControlConfigurationComponent.prototype, "isFormValid", {
        get: function () {
            return this.openPositionConfigForm.valid;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], OpenPositionControlConfigurationComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OpenPositionControlConfigurationComponent.prototype, "orglevelSubscription", void 0);
    return OpenPositionControlConfigurationComponent;
}());
export { OpenPositionControlConfigurationComponent };
