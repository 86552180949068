
export * from './exception-console/exception-console.component';
export * from './exception-console-grid/exception-console-grid.component';
export * from './exception-console-rollup/exception-console-rollup.component';
export * from './exception-console-page/exception-console-page.component';
export * from './exception-console-grid-rollup/exception-console-grid-rollup.component';

import { ExceptionConsoleComponent } from './exception-console/exception-console.component';
import { ExceptionConsoleGridComponent } from './exception-console-grid/exception-console-grid.component';
import { ExceptionConsoleRollupComponent } from './exception-console-rollup/exception-console-rollup.component';
import { ExceptionConsolePageComponent } from './exception-console-page/exception-console-page.component';
import { ExceptionConsoleGridRollupComponent } from './exception-console-grid-rollup/exception-console-grid-rollup.component';

export const exceptionConsoleComponents: any = [
  ExceptionConsoleComponent,
  ExceptionConsoleGridComponent,
  ExceptionConsolePageComponent,
  ExceptionConsoleRollupComponent,
  ExceptionConsoleGridRollupComponent
];
