<slx-spinner [show]="isLoading">
  <kendo-grid class="lr-details"
    [data]="gridState.view"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="gridState.state.skip"
    (pageChange)="gridState.pageChange($event)"
  >
    <kendo-grid-column title="Leave Requests Details" media="(max-width: 780px)" [sortable]="false" [filterable]="false" width="200" class="overflow-visible-cell">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="lr-details-mobile">
          <header class="lr-details-mobile__header">
            <p class="lr-details__status slx-flex-row" *ngIf="dataItem.repStatus">
              <button class="slx-button slx-with-icon slx-wide" [disabled]="!dataItem.hasEdited && isEdit" (click)="onFindReplacement(dataItem)">
                <i class="fas fa-search slx-button__icon" aria-hidden="true"></i>
                <span class="slx-button__text lr-details__button-text">{{ dataItem.repStatus }}</span>
              </button>
              <span class="lr-details__info mobile" (click)="onShowSchedule(dataItem)" title="Go to Individual Schedule"><i class="fas fa-info-circle" aria-hidden="true"></i></span>
            </p>
            <button class="slx-button slx-with-icon slx-wide" *ngIf="!dataItem.repStatus" [disabled]="!dataItem.hasEdited && isEdit || !canReplaceEmployee" (click)="onFindReplacement(dataItem)">
              <i class="fas fa-search slx-button__icon" aria-hidden="true"></i>
              <span class="slx-button__text lr-details__button-text">Find Replacement</span>
            </button>
          </header>
          <section class="lr-details-mobile__row slx-flex-row">
            <div class="slx-half-width">
              <label class="lr-details-mobile__label">Date</label>
              <p class="lr-details-mobile__text">{{ dataItem.date | amDateFormat: appConfig.dateFormat }}</p>
            </div>
            <div class="slx-half-width">
              <label class="lr-details-mobile__label">Shift</label>
              <p class="lr-details-mobile__text">{{ dataItem?.shift.name }} ({{ dataItem?.shiftDuration | slxDuration: "hours": "0.0" }})</p>
            </div>
          </section>
          <section class="lr-details-mobile__row slx-flex-row">
            <div class="slx-half-width">
              <label class="lr-details-mobile__label">Absence Reason</label>
              <p class="lr-details-mobile__text">{{ dataItem?.absenceReason.code }}</p>
            </div>
            <div class="slx-half-width">
              <label class="lr-details-mobile__label">Remaining Balance</label>
              <p class="lr-details-mobile__text">{{  dataItem.accrualBalance | slxHumanizeAccrualBalance: dataItem.isAccruaBalanceAvailable: 'N/A'  }}</p>
            </div>
          </section>
          <section class="lr-details-mobile__row slx-flex-row">
            <div class="slx-half-width">
              <label class="lr-details-mobile__label">Approved absences</label>
              <p class="lr-details-mobile__text">{{ dataItem.approvedAbsences }}</p>
            </div>
            <div class="slx-half-width">
              <label class="lr-details-mobile__label">Submitted absences</label>
              <p class="lr-details-mobile__text">{{ dataItem.submittedAbsences }}</p>
            </div>
          </section>
          <div class="lr-details__flex mobile slx-flex-row" *ngIf="dataItem.hasEdited">
            <button class="slx-button" (click)="onSaveClick(dataItem)">Save</button>
            <button class="slx-button" (click)="onCancelClick(dataItem)">Cancel</button>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Edit" media="(min-width: 781px)" width="110">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="lr-details__flex slx-flex-row" *ngIf="dataItem.hasEdited">
          <button class="slx-button slx-only-icon" title="Save" (click)="onSaveClick(dataItem)">
            <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
          </button>
          <button class="slx-button slx-only-icon" title="Cancel" (click)="onCancelClick(dataItem)">
            <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Date" media="(min-width: 781px)">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.date | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Shift" media="(min-width: 781px)">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.shift.name }} ({{ dataItem?.shiftDuration | slxDuration: "hours": "0.0" }})
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Absence Reason" media="(min-width: 781px)">
      <ng-template kendoGridCellTemplate let-dataItem>
        <slx-dropdown-list class="slx-wide slx-short"
          [options]="absencesCodes"
          [readonly]="!dataItem.hasEdited && isEdit"
          [valueField]="'code'"
          [titleField]="'code'"
          [(ngModel)]="dataItem.absenceReason"
          (ngModelChange)="onModelChanged(dataItem)"
        ></slx-dropdown-list>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Remaining Balance" media="(min-width: 781px)">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{  dataItem.accrualBalance | slxHumanizeAccrualBalance: dataItem.isAccruaBalanceAvailable: 'N/A'  }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Approved absences" media="(min-width: 781px)">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.approvedAbsences }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Submitted absences" media="(min-width: 781px)">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.submittedAbsences }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Replacement status" media="(min-width: 781px)" width="190">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="!canReplaceEmployee" slxTooltip="No rights" tipClass="second-level-tooltip" tipPosition="left"></span>
        <p class="lr-details__status slx-flex-row" *ngIf="dataItem.repStatus">
          <button class="slx-button slx-with-icon lr-details__button" [disabled]="!dataItem.hasEdited && isEdit" (click)="onFindReplacement(dataItem)">
            <i class="fas fa-search slx-button__icon" aria-hidden="true"></i>
            <span class="slx-button__text lr-details__button-text">{{ dataItem.repStatus }}</span>
          </button>
          <span class="lr-details__info" (click)="onShowSchedule(dataItem)" title="Go to Individual Schedule"><i class="fas fa-info-circle" aria-hidden="true"></i></span>
        </p>
        <button class="slx-button slx-with-icon lr-details__button" *ngIf="!dataItem.repStatus" [disabled]="!dataItem.hasEdited && isEdit || !canReplaceEmployee" (click)="onFindReplacement(dataItem)">
          <i class="fas fa-search slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text lr-details__button-text">Find Replacement</span>
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</slx-spinner>
