import { IGender, Gender, IState, State } from '../../../../organization/models/lookup/index';

export interface IBenefitDependentRelation {
  id: string;
  name: string;
}

export class BenefitDependentRelation {
  public id: string;
  public name: string;
}

export interface IBenefitEmployeeDependent {
  id: number;
  employeeId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;

  address: string;
  street: string;
  city: string;
  state: IState;
  zipcode: string;
  hasBenefits: boolean;
  relation: IBenefitDependentRelation;

  gender: IGender;
  birthDate: string;
  ssn: string;

  isDeleted: boolean;
  modifiedAt: string;
  modifiedBy: string;
  addedAt: string;
  addedBy: string;
}

export class BenefitEmployeeDependent {
  public id: number;
  public employeeId: number;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public suffix: string;

  public address: string;
  public street: string;
  public city: string;
  public state: State;
  public zipcode: string;
  public hasBenefits: boolean;
  public relation: BenefitDependentRelation;

  public gender: Gender;
  public birthDate: Date;
  public ssn: string;

  public isDeleted: boolean;
  public modifiedAt: Date;
  public modifiedBy: string;
  public addedAt: Date;
  public addedBy: string;

  public get relationText(): string {
    return this.relation && this.relation.name || '';
  }

  public get fullName(): string {
    return `${this.lastName}, ${this.firstName}`;
  }

  public get genderText(): string {
    return this.gender && this.gender.name || '';
  }

  public get stateText(): string {
    return this.state && this.state.name || '';
  }
}
