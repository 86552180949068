import * as tslib_1 from "tslib";
import { OnInit, OnChanges, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { IFilteredItems, PagingData } from '../../../../../core/models/index';
import { PbjExportApiService } from '../../../services/index';
import { ServerFilterService } from '../../../../../core/services/index';
import { PbjDetailsBasicGridComponent } from '../pbj-details-basic-grid/pbj-details-basic-grid.component';
import { StateManagementService } from '../../../../../common/services/index';
var PbjDetailsStaffingHoursGridComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PbjDetailsStaffingHoursGridComponent, _super);
    function PbjDetailsStaffingHoursGridComponent(apiService, serverFilterService, changeDetector, stateManagement) {
        var _this = _super.call(this, apiService, serverFilterService, changeDetector, stateManagement) || this;
        _this.controlKey = 'PbjExportStaffingHoursDataComponent';
        return _this;
    }
    Object.defineProperty(PbjDetailsStaffingHoursGridComponent.prototype, "exportFilename", {
        get: function () {
            return "export-" + this.exportId + "-staffing-hours-data";
        },
        enumerable: true,
        configurable: true
    });
    PbjDetailsStaffingHoursGridComponent.prototype.refreshData = function () {
        var _this = this;
        this.onLoadStatus(true);
        this.apiService.getPBJExportLogStaffingHours(this.serverFilterService.createQuery(this.pagingData, this.gridState.state.sort), this.exportId)
            .then(function (val) {
            _this.records = val;
            _this.onLoadStatus(false);
            _this.showChanges();
        }).catch(function (reason) {
            _this.onLoadStatus(false);
        });
    };
    PbjDetailsStaffingHoursGridComponent.prototype.loadAndExport = function (type) {
        var _this = this;
        this.onLoadStatus(true);
        var zeroPaging = { skip: 0 };
        this.apiService.getPBJExportLogStaffingHours(this.serverFilterService.createQuery(zeroPaging, this.gridState.state.sort), this.exportId)
            .then(function (val) {
            _this.processExportData(type, val);
            _this.onLoadStatus(false);
        }).catch(function (reason) {
            _this.onLoadStatus(false);
            _this.exportRecords = null;
        });
    };
    PbjDetailsStaffingHoursGridComponent.prototype.updateColumnsConfig = function () {
        var _a;
        _super.prototype.updateColumnsConfig.call(this);
        var specificColumns = [
            { field: 'employeeName', displayTitle: 'Name', index: 1, width: 150 },
            { field: 'payrollNumber', displayTitle: 'Payroll No', index: 2, width: 150 },
            { field: 'workDate', displayTitle: 'Work Date', index: 3, width: 150 },
            { field: 'workHours', displayTitle: 'Work Hours', index: 4, width: 150 },
            { field: 'cmsJobCode', displayTitle: 'CMS Job Code', index: 5, width: 150 },
            { field: 'cmsJobNameTitle', displayTitle: 'CMS Job Title', index: 6, width: 150 },
            { field: 'exemptType', displayTitle: 'Exempt Type', index: 7, width: 150 },
        ];
        (_a = this.xlsExportColumns).push.apply(_a, specificColumns);
    };
    PbjDetailsStaffingHoursGridComponent.prototype.applyDefaultSort = function () {
        this.gridState.state.sort = [{ field: 'employeeName', dir: 'asc' }, { field: 'workDate', dir: 'asc' }];
    };
    return PbjDetailsStaffingHoursGridComponent;
}(PbjDetailsBasicGridComponent));
export { PbjDetailsStaffingHoursGridComponent };
