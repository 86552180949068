import { Subject } from 'rxjs';
import { SignalrHub2Service } from '../../../channel/services/signalr/signalr-hub2.service';
import { appConfig } from '../../../app.config';
import { pbjConfig } from '../../../app-modules/pbj/pbj.config';
import { SessionService, ThrottlingService } from '../../../core/services';
import * as i0 from "@angular/core";
import * as i1 from "../../../channel/services/signalr/signalr-hub2.service";
import * as i2 from "../../../core/services/throttling/throttling.service";
import * as i3 from "../../../core/services/session/session.service";
var PbjSignalrService = /** @class */ (function () {
    function PbjSignalrService(signalRService, throttlingService, sessionService) {
        this.signalRService = signalRService;
        this.throttlingService = throttlingService;
        this.sessionService = sessionService;
        this.pbjDownloadOptionsFeedback$ = new Subject();
        this.pbjExportRealtimeFeedback$ = new Subject();
        this.isPDFDownloadInitiated$ = new Subject();
        this.signalRService = new SignalrHub2Service(this.throttlingService, this.sessionService);
    }
    PbjSignalrService.prototype.init = function () {
        this.signalRService.init({ url: this.getApiRoot(), hubName: pbjConfig.api.signalR.signalRHub });
        this.pbjDownloadOptionsFeedback$ = this.signalRService.subscribe('PBJExportExcelPdfGroup');
        this.pbjExportRealtimeFeedback$ = this.signalRService.subscribe('PBJExportGroupName');
    };
    PbjSignalrService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version2 + "/" + pbjConfig.api.signalR.root + "/signalr";
    };
    PbjSignalrService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PbjSignalrService_Factory() { return new PbjSignalrService(i0.ɵɵinject(i1.SignalrHub2Service), i0.ɵɵinject(i2.ThrottlingService), i0.ɵɵinject(i3.SessionService)); }, token: PbjSignalrService, providedIn: "root" });
    return PbjSignalrService;
}());
export { PbjSignalrService };
