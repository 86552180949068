import { Injectable } from '@angular/core';
import { IAppState } from '../../../store';
import { NgRedux } from '@angular-redux/store';

import { EmployeeScheduleDefinition, IScheduleActions } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { Employee, ScheduleEntry } from '../../models/index';
import { IChangedScheduleEntry } from './master-schedule.types';

@Injectable()
export class MasterScheduleActions {
  public static FETCH_EMPLOYEES_SCHEDULE: string = 'FETCH_EMPLOYEES_SCHEDULE';
  public static FETCH_EMPLOYEES_SCHEDULE_ERROR: string = 'FETCH_EMPLOYEES_SCHEDULE_ERROR';
  public static FETCH_EMPLOYEES_SCHEDULE_SUCCESS: string = 'FETCH_EMPLOYEES_SCHEDULE_SUCCESS';

  public static GENERATE_SCHEDULE: string = 'GENERATE_SCHEDULE';
  public static GENERATE_SCHEDULE_SUCCESS: string = 'GENERATE_SCHEDULE_SUCCESS';
  public static GENERATE_SCHEDULE_ERROR: string = 'GENERATE_SCHEDULE_ERROR';

  public static CREATE_EMP_ROT_FROM_SCHEDULE: string = 'CREATE_EMP_ROT_FROM_SCHEDULE';
  public static CREATE_EMP_ROT_FROM_SCHEDULE_SUCCESS: string = 'CREATE_EMP_ROT_FROM_SCHEDULE_SUCCESS';
  public static CREATE_EMP_ROT_FROM_SCHEDULE_ERROR: string = 'CREATE_EMP_ROT_FROM_SCHEDULE_ERROR';

  public static DELETE_EMP_SCHEDULE: string = 'DELETE_EMP_SCHEDULE';
  public static DELETE_EMP_SCHEDULE_SUCCESS: string = 'DELETE_EMP_SCHEDULE_SUCCESS';
  public static DELETE_EMP_SCHEDULE_ERROR: string = 'DELETE_EMP_SCHEDULE_ERROR';


  public static FETCH_TOTALS: string = 'FETCH_TOTALS';
  public static FETCH_TOTALS_ERROR: string = 'FETCH_TOTALS_ERROR';
  public static FETCH_TOTALS_SUCCESS: string = 'FETCH_TOTALS_SUCCESS';

  public static FETCH_MASTER_SCHEDULE_DATA: string = 'FETCH_MASTER_SCHEDULE_DATA';
  public static FETCH_MASTER_SCHEDULE_DATA_SUCCESS: string = 'FETCH_MASTER_SCHEDULE_DATA_SUCCESS';
  public static FETCH_MASTER_SCHEDULE_DATA_ERROR: string = 'FETCH_MASTER_SCHEDULE_DATA_ERROR';

  public static SET_SCROLL_POSITION: string = 'SET_SCROLL_POSITION';
  public static SET_SELECTED_ROW: string = 'SET_SELECTED_ROW';
  public static SCHEDULE_ENTRIES_CHANGE: string = 'SCHEDULE_ENTRIES_CHANGE';
  public static SCHEDULE_ENTRIES_CHANGED: string = 'SCHEDULE_ENTRIES_CHANGED';
  public static SCHEDULE_ENTRIES_CHANGED_ERROR: string = 'SCHEDULE_ENTRIES_CHANGED_ERROR';
  public static MASTER_SCHEDULE_CLEAR_PAYCYCLE: string = 'MASTER_SCHEDULE_CLEAR_PAYCYCLE';

  public static COMPLETE_LOADING: string = 'COMPLETE_LOADING';
  public static COMPLETE_DATA_LOADING: string = 'COMPLETE_DATA_LOADING';

  private ngRedux: NgRedux<IAppState>;

  constructor(ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }

  public createEmployeeRotationFromSchedule(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE
    });
  }

  public createEmployeeRotationFromScheduleSuccess(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS
    });
  }

  public createEmployeeRotationFromScheduleError(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR
    });
  }

  public deleteEmployeesSchedule(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.DELETE_EMP_SCHEDULE
    });
  }

  public deleteEmployeesScheduleSuccess(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS
    });
  }

  public deleteEmployeesScheduleError(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR
    });
  }

  public fetchMasterScheduleData(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA
    });
  }

  public fetchEmployeesSchedule(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE
    });
  }

  public fetchEmployeesScheduleSuccess(actions: IScheduleActions,schedule: EmployeeScheduleDefinition[]): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS,
      payload: {
        actions: actions,
        employees: schedule
      }
    });
  }
  public fetchTotals(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.FETCH_TOTALS
    });
  }

  public generateSchedule(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.GENERATE_SCHEDULE,
    });
  }
  public generateScheduleSuccess(schedule: EmployeeScheduleDefinition): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS,
      payload: schedule
    });
  }

  public generateScheduleError(error: any): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.GENERATE_SCHEDULE_ERROR,
      payload: error
    });
  }

  public completeLoading(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.COMPLETE_LOADING
    });
  }

  public clearPayCycle(): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.MASTER_SCHEDULE_CLEAR_PAYCYCLE
    });
  }

  public setScrollPosition(orgLevel: OrgLevel, top: number, left: number): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.SET_SCROLL_POSITION,
      payload: {
        orgLevel: orgLevel,
        top,
        left
      }
    });
  }

  public setSelectedRow(selectedRowIndex: string): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.SET_SELECTED_ROW,
      payload: selectedRowIndex
    });
  }

  public scheduleEntryChange(orgLevelId: number, entries: IChangedScheduleEntry[]): void {
    this.ngRedux.dispatch({
      type: MasterScheduleActions.SCHEDULE_ENTRIES_CHANGE,
      payload: { orgLevelId, entries }
    });
  }

}
