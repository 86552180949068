<form #form="ngForm" novalidate>
  <kendo-grid #gridDependents class="kendo-grid slx-benefit-management-grid"
    [data]="gridState.view"
    [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort"
    [filterable]="'menu'"
    [filter]="gridState.state.filter"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="gridState.state.skip"

    (dataStateChange)="gridState.dataStateChange($event)"
    (edit)="gridState.editHandler($event)"
    (save)="gridState.saveHandler($event)"
    (cancel)="gridState.cancelHandler($event)"
    (remove)="gridState.removeHandler($event)"
  >
    <ng-template kendoGridToolbarTemplate position="top" *ngIf="isEditable">
      <div class="header-btn">
        <div>
          <button type="button"
            class="theme-iconed-accent-button header-btn"
            [disabled]="!canCreate"
            (click)="onAddNewDependent()"
          >Add New</button>
         </div>
        <div>
          <span slxTooltip="{{!canEnroll ? 'Select a dependent to enroll into benefit(s)' : ''}}" tipPosition="right">
            <button type="button"
              class="theme-iconed-accent-button header-btn"
              [disabled]="!canEnroll"
              [slxEmployeeDependentBenefitsModal]="dependents"
              (selectEmployeeDependentsBenefits)="onSelectDependentsBenefits($event)"
            >Add Benefits</button>
          </span>
        </div>
      </div>
    </ng-template>

    <kendo-grid-checkbox-column [width]="44">
      <ng-template kendoGridHeaderTemplate>
        <input type="checkbox" class="k-checkbox checkbox-employee" [disabled]="isEditMode" [checked]="isCheckedAll" (change)="onSelectAll(false)">
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <input type="checkbox" class="k-checkbox checkbox-employee" [disabled]="isEditMode" [checked]="isCheckedEmp(dataItem)" (change)="onSelectSingle(dataItem)">
      </ng-template>
    </kendo-grid-checkbox-column>

    <kendo-grid-command-column title="Edit" width="90">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="flex" *ngIf="isEditable">
          <button [disabled]="!canEdit" kendoGridEditCommand
            type="button" class="slx-button slx-only-icon slx-toolbar slx-blue-white-text"
          >
            <i class="fas fa-edit" aria-hidden="true"></i>
          </button>
          <button *ngIf="!isEditingRow(dataItem)" [disabled]="!canDelete" kendoGridRemoveCommand
            type="button" class="slx-button slx-only-icon slx-toolbar slx-red-white-text"
          >
            <i class="fas fa-trash-alt" aria-hidden="true"></i>
          </button>
          <button [disabled]="!form.valid" kendoGridSaveCommand
            type="button" class="slx-button slx-only-icon slx-toolbar slx-blue-white-text"
          >
            <i class="fas fa-save" aria-hidden="true"></i>
          </button>
          <button kendoGridCancelCommand
            type="button" class="slx-button slx-only-icon slx-toolbar slx-red-white-text"
          >
            <i class="fas fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column field="lastName" [width]="170">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Last Name</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.lastName }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <input slx-input [inputTextForbidSymbols]="pattern"
            [(ngModel)]="dataItem.lastName"
            placeholder="Last Name"
            maxlength="50"
            name="lastName{{rowIndex}}"
            autocomplete="off"
            required
          />
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="firstName" [width]="170">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>First Name</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.firstName }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <input slx-input [inputTextForbidSymbols]="pattern"
            [(ngModel)]="dataItem.firstName"
            placeholder="First Name"
            maxlength="50"
            name="firstName{{rowIndex}}"
            autocomplete="off"
            autofocus
            required
          />
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="middleName" [width]="150">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Middle Name</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.middleName }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <input slx-input [inputTextForbidSymbols]="pattern"
            [(ngModel)]="dataItem.middleName"
            placeholder="Middle Name"
            maxlength="50"
            name="middleName{{rowIndex}}"
            autocomplete="off"
          />
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="suffix" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Suffix</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.suffix }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <input slx-input [inputTextForbidSymbols]="pattern"
            [(ngModel)]="dataItem.suffix"
            placeholder="Suffix"
            maxlength="50"
            name="suffix{{rowIndex}}"
            autocomplete="off"
          />
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="relationText" [width]="160">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Relation</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.relationText }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
            [options]="dependentSection?.relations"
            [(ngModel)]="dataItem.relation"
            [emptyOption]="{ id: 0, name: '' }"
            [readonly]="dataItem.hasBenefits"
            valueField="id"
            titleField="name"
            placeholder="Relation"
            name="relation{{rowIndex}}"
            required
          ></slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="genderText" [width]="150">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Gender</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.genderText }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
            [options]="genderLookup?.items"
            [(ngModel)]="dataItem.gender"
            [valueField]="genderLookup?.valueField"
            [titleField]="genderLookup?.titleField"
            placeholder="Gender"
            name="gender{{rowIndex}}"
          ></slx-dropdown-input>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="birthDate" [width]="170" filter="date">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Date of Birth</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.birthDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input
            [(ngModel)]="dataItem.birthDate"
            (ngModelChange)="onDateChanged()"
            [acceptNullDate]="false"
            [constantValidation]="true"
            [readonly]="dataItem.hasBenefits"
            [maxDate]="maxDate"
            placeholder="Date of Birth"
            name="birthDate{{rowIndex}}"
            required
          ></slx-date-picker-ngx>
          <span errorMessage for="date"></span>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="ssn" [width]="150">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>SSN</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.ssn }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <slx-ssn-input slx-input ssn
            [(ngModel)]="dataItem.ssn"
            placeholder="SSN"
            name="ssn{{rowIndex}}"
          ></slx-ssn-input>
          <span errorMessage for="ssn"></span>
          <span errorMessage for="required"></span>
          <span errorMessage [for]="ssnValidation">msg from server</span>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="street" [width]="180">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Street</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.street }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <input slx-input [inputTextForbidSymbols]="pattern"
            [(ngModel)]="dataItem.street"
            placeholder="Street"
            maxlength="100"
            name="street{{rowIndex}}"
            autocomplete="off"
          />
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="city" [width]="150">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>City</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.city }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <input slx-input [inputTextForbidSymbols]="pattern"
            [(ngModel)]="dataItem.city"
            placeholder="City"
            maxlength="100"
            name="city{{rowIndex}}"
            autocomplete="off"
          />
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="stateText" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>State</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.stateText }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
            [options]="stateLookup?.items"
            [(ngModel)]="dataItem.state"
            [valueField]="stateLookup?.valueField"
            [titleField]="stateLookup?.titleField"
            placeholder="State"
            name="state{{rowIndex}}"
          ></slx-dropdown-input>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="zipcode" [width]="120">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Zip Code</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.zipcode }}
      </ng-template>

      <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <slx-zip-input slx-input
            [(ngModel)]="dataItem.zipcode"
            placeholder="Zip code"
            name="zipcode{{rowIndex}}"
          ></slx-zip-input>
          <span errorMessage for="required"></span>
          <span errorMessage for="min">Min length is 5</span>
          <span errorMessage for="max">Max length is 10</span>
      </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="isShownAddress()" field="address" [width]="150">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Address</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getDependentAddress(dataItem) }}
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoGridDetailTemplate let-dataItem>
      <slx-employee-sections-dependents-enrollment-grid [dependent]="dataItem"></slx-employee-sections-dependents-enrollment-grid>
    </ng-template>
  </kendo-grid>
</form>
