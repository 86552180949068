import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { CensusEntryApiService } from '../../services/index';
import { CensusEntry, CensusGridData, CensusGridColumnData, CensusGridRecordData } from './../../models/index';
import { sendCensusCategoryWithCensus } from '../../models/census/census-category';
var CensusEntriesComponent = /** @class */ (function () {
    function CensusEntriesComponent(censusEntryApiComponent) {
        var _this = this;
        this.disableDateInput = false;
        this.hideButtons = false;
        this.totals = {};
        this.state = {
            isLoading: false
        };
        this.censusEntryApiComponent = censusEntryApiComponent;
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            if (o.type !== OrgLevelType.corporation) {
                _this.isCorpOrglevelSelected = false;
                if (o.id) {
                    _this.selectedOrgLevel = o;
                    _this.loadData();
                }
            }
            else {
                _this.isCorpOrglevelSelected = true;
            }
        });
        this.onSaved = new EventEmitter();
    }
    Object.defineProperty(CensusEntriesComponent.prototype, "entryDate", {
        get: function () {
            return this.m_entryDate;
        },
        set: function (value) {
            if (value && this.m_entryDate && value.getTime() === this.m_entryDate.getTime())
                return;
            this.m_entryDate = value;
            if (this.m_entryDate) {
                this.loadData();
            }
            else {
                this.acuityConfiguration = null;
                this.censusGridData = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CensusEntriesComponent.prototype, "acuityConfiguration", {
        get: function () {
            return this.m_acuityConfiguration;
        },
        set: function (value) {
            this.m_acuityConfiguration = value;
            this.originalCopyToFutureDates = value ? value.copyToFutureDates : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CensusEntriesComponent.prototype, "capacityExceeded", {
        get: function () {
            return this.acuityConfiguration ? this.currentCapacity > this.acuityConfiguration.capacity : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CensusEntriesComponent.prototype, "currentCapacity", {
        get: function () {
            if (this.censusGridData && this.censusGridData.rows) {
                return this.censusGridData.rows
                    .map(function (r) { return r.capacity; })
                    .reduce(function (prev, current) { return prev + current; }, 0);
            }
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CensusEntriesComponent.prototype, "dateNow", {
        get: function () {
            return moment().toDate();
        },
        enumerable: true,
        configurable: true
    });
    CensusEntriesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formSubscription = this.form.valueChanges.subscribe(function () {
            _this.hasChanges = _this.isAnyChangesOnModel();
        });
    };
    CensusEntriesComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    CensusEntriesComponent.prototype.saveChanges = function () {
        var _this = this;
        this.state.isLoading = true;
        // write changes back from grid model to data model
        this.updateAcuityConf();
        // save data
        // this.censusGridData.categoriesConfig.allowToExcludeFromTotals = this.allowToExcludeFromTotals;
        var request = new sendCensusCategoryWithCensus();
        request.configData = this.censusGridData.categoriesConfig;
        request.censusData = this.acuityConfiguration;
        this.censusEntryApiComponent.saveCensusCategories(this.selectedOrgLevel, this.entryDate, request).then(function () {
            return _this.censusEntryApiComponent.saveCensus(_this.selectedOrgLevel, _this.entryDate, _this.acuityConfiguration)
                .then(function (ac) {
                _this.state.isLoading = false;
                _this.onSaved.emit(ac);
                _this.formInitialize(ac, _this.censusGridData.categoriesConfig);
                _this.recalcTotals(_this.censusGridData);
            })
                .catch(function () {
                _this.state.isLoading = false;
            });
        });
    };
    CensusEntriesComponent.prototype.discardChanges = function () {
        this.acuityConfiguration.copyToFutureDates = this.originalCopyToFutureDates;
        this.censusGridData = _.cloneDeep(this.originalCensusGridData);
        this.recalcTotals(this.censusGridData);
    };
    CensusEntriesComponent.prototype.isAnyChangesOnModel = function () {
        if (!this.originalCensusGridData)
            return false;
        if (this.acuityConfiguration.copyToFutureDates !== this.originalCopyToFutureDates)
            return true;
        var isNotEqual = false;
        isNotEqual = JSON.stringify(this.censusGridData) !== JSON.stringify(this.originalCensusGridData);
        return isNotEqual;
    };
    Object.defineProperty(CensusEntriesComponent.prototype, "canSave", {
        get: function () {
            return this.form.valid && !this.capacityExceeded
                && this.hasChanges && !_.isEmpty(this.acuityConfiguration.items);
        },
        enumerable: true,
        configurable: true
    });
    CensusEntriesComponent.prototype.onChanged = function (event, category, dataItem) {
        dataItem.values[category] = event;
        this.hasChanges = this.isAnyChangesOnModel();
        this.recalcTotals(this.censusGridData);
    };
    CensusEntriesComponent.prototype.onChangeCopyToFutureDatesCheckbox = function () {
        this.hasChanges = this.isAnyChangesOnModel();
    };
    CensusEntriesComponent.prototype.onCategoryConfigChange = function ($event, oldValue) {
        this.hasChanges = this.isAnyChangesOnModel();
        this.recalcTotals(this.censusGridData);
    };
    CensusEntriesComponent.prototype.onSettingChanged = function ($event) {
        if (this.censusGridData.categoriesConfig.allowToExcludeFromTotals != $event) {
            this.censusGridData.categoriesConfig.allowToExcludeFromTotals = $event;
            this.hasChanges = this.isAnyChangesOnModel();
            this.formInitialize(this.acuityConfiguration, this.censusGridData.categoriesConfig, false);
        }
        if (this.censusGridData.categoriesConfig != null && !this.censusGridData.categoriesConfig.allowToExcludeFromTotals) {
            for (var entryKey in this.censusGridData.categoriesConfig.records) {
                if (this.censusGridData.categoriesConfig.records.hasOwnProperty(entryKey)) {
                    var entry = this.censusGridData.categoriesConfig.records[entryKey];
                    entry.excludeFromTotal = false;
                    entry.includedInTotal = true;
                }
            }
            this.recalcTotals(this.censusGridData);
        }
        if (this.censusGridData.categoriesConfig != null && this.originalCensusGridData.categoriesConfig != null && this.censusGridData.categoriesConfig.allowToExcludeFromTotals && this.originalCensusGridData.categoriesConfig.allowToExcludeFromTotals) {
            this.discardChanges();
        }
    };
    CensusEntriesComponent.prototype.createCensusGridData = function (conf, categoriesConfig, resetOriginal) {
        if (resetOriginal === void 0) { resetOriginal = true; }
        var gridData = new CensusGridData();
        gridData.categoriesConfig = categoriesConfig;
        var columnCount = 0;
        var columns = [];
        var rows = [];
        var settingsRow = new CensusGridRecordData();
        settingsRow.type = 'Please select the box to exclude from the total';
        settingsRow.capacity = 0;
        settingsRow.isSettings = true;
        columnCount = 0;
        _.each(_.sortBy(categoriesConfig.records, 'sortId'), function (r) {
            var settingsCensusEntry = new CensusEntry();
            settingsCensusEntry.isSettings = true;
            settingsCensusEntry.category = r.name;
            settingsCensusEntry.censusConfig = r;
            settingsCensusEntry.value = 0;
            settingsRow.entries['column' + columnCount] = settingsCensusEntry;
            columnCount++;
        });
        rows.push(settingsRow);
        var rowCount = 0;
        if (conf) {
            _.each(conf.items, function (a) {
                _.sortBy(a.entries, 'category');
                columnCount = 0;
                var rowData = new CensusGridRecordData();
                rowData.type = a.type;
                rowData.capacity = a.capacity;
                _.each(a.entries, function (e) {
                    var columnData;
                    if (rowCount === 0) {
                        columnData = new CensusGridColumnData();
                        columnData.field = 'column' + columnCount;
                        columnData.title = e.category;
                        columns.push(columnData);
                    }
                    rowData.values['column' + columnCount] = e.value;
                    rowData.entries['column' + columnCount] = e;
                    columnCount++;
                });
                rowCount++;
                rows.push(rowData);
            });
        }
        gridData.columns = columns;
        gridData.rows = rows;
        this.recalcTotals(gridData);
        if (!categoriesConfig.allowToExcludeFromTotals)
            gridData.rows = rows.slice(1);
        if (resetOriginal) {
            this.originalCensusGridData = gridData;
        }
        this.censusGridData = _.cloneDeep(gridData);
    };
    CensusEntriesComponent.prototype.updateAcuityConf = function () {
        var _this = this;
        if (this.censusGridData && this.m_acuityConfiguration) {
            _.forEach(this.censusGridData.rows, function (row) {
                var valueKeys = _.keys(row.values);
                var acuity = _.find(_this.m_acuityConfiguration.items, function (a) {
                    return a.type === row.type;
                });
                if (acuity) {
                    _.each(valueKeys, function (key) {
                        var entry = _.find(acuity.entries, function (e) {
                            return e.category === row.entries[key].category;
                        });
                        if (entry) {
                            entry.value = +row.values[key];
                        }
                        else {
                            throw new Error('Can not update acuity configuration');
                        }
                    });
                }
            });
        }
    };
    CensusEntriesComponent.prototype.recalcTotals = function (censusGridData) {
        var _this = this;
        this.totals = { 'totals': 0 };
        if (censusGridData) {
            _.forEach(censusGridData.rows, function (row) {
                var keys = _.keys(row.values);
                var rowTotal = 0;
                _.forEach(keys, function (key) {
                    if (!_this.totals[key])
                        _this.totals[key] = 0;
                    var configCell = undefined;
                    if (censusGridData && censusGridData.rows[0] &&
                        censusGridData.rows[0].entries[key] && censusGridData.rows[0].entries[key].censusConfig) {
                        configCell = censusGridData.rows[0].entries[key].censusConfig;
                    }
                    if (!configCell || configCell.includedInTotal) {
                        rowTotal += +row.values[key];
                        _this.totals[key] += +row.values[key];
                    }
                });
                row.capacity = rowTotal;
                _this.totals['totals'] += rowTotal;
            });
        }
    };
    CensusEntriesComponent.prototype.formInitialize = function (value, config, resetOriginal) {
        if (resetOriginal === void 0) { resetOriginal = true; }
        Assert.isNotNull(value, 'AcuityConfiguration');
        Assert.isNotNull(config, 'CensusCategoryConfig');
        this.entryDate = value.date;
        this.acuityConfiguration = _.cloneDeep(value);
        this.createCensusGridData(value, config, resetOriginal);
    };
    CensusEntriesComponent.prototype.loadData = function () {
        var _this = this;
        if (this.selectedOrgLevel && this.entryDate) {
            this.state.isLoading = true;
            this.censusEntryApiComponent.getCensus(this.selectedOrgLevel, this.entryDate).then(function (ac) {
                _this.censusEntryApiComponent.getCensusCategories(_this.selectedOrgLevel).then(function (config) {
                    _this.formInitialize(ac, config);
                    _this.state.isLoading = false;
                    // this.recalcTotals(this.censusGridData);
                }).catch(function () {
                    _this.state.isLoading = false;
                });
            }).catch(function () {
                _this.state.isLoading = false;
            });
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], CensusEntriesComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CensusEntriesComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CensusEntriesComponent.prototype, "formSubscription", void 0);
    return CensusEntriesComponent;
}());
export { CensusEntriesComponent };
