import { Directive, Input, forwardRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';

const MIN_TIME_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MinTimeValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[minTime][formControlName],[minTime][formControl],[minTime][ngModel]',
  /* tslint:enable */
  providers: [MIN_TIME_VALIDATOR]
})
export class MinTimeValidator implements Validator, OnChanges {
  @Input()
  public minTime: string;

  /* tslint:disable */
  private _validator: ValidatorFn;
  private _onChange: () => void;
  /* tslint:enable */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['minTime']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): {[key: string]: any} {
    return !_.isNil(this.minTime) ? this._validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = CommonValidators.minTime(this.minTime);
  }
}
