import * as moment from 'moment';
import * as _ from 'lodash';
import {
    Component,
    OnInit,
    OnDestroy,
    Input, Output,
    ViewChild,
    ElementRef,
    EventEmitter
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { Assert } from '../../../framework/index';
import { commonConfig } from '../../common.config';
import { DateValidator } from '../../validators/index';
import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../utils/index';
import { createValuAccessor } from '../../utils/index';
@Component({
    moduleId: module.id,
    /* tslint:disable */
    selector: 'slx-date-time-input',
    /* tslint:enable */
    templateUrl: 'date-time-input.component.html',
    styleUrls: ['date-time-input.component.scss'],
    providers: [createValuAccessor(DateTimeInputComponent)]
})
export class DateTimeInputComponent implements OnInit, OnDestroy, ControlValueAccessor {

    @Input() public dateFormat: string = 'MM/dd/yyyy';

    @Input() public field: string;

    @Input() public model: any;

    @Input()
    public set minDateLimit(value: Date) {
        if (value) {
            this.internalMinMoment = moment(value);
        } else {
            this.internalMinMoment = null;
        }
    }

    @Input()
    public set maxDateLimit(value: Date) {
        if (value) {
            this.internalMaxMoment = moment(value);
        } else {
            this.internalMaxMoment = null;
        }
    }

    @Input()
    public set readonly(ro: boolean) {
        this.inEdit = !ro;
    }

    @Input()
    public set required(value: boolean) {
        this.requiredInternal = value;
    }

    @Input() public className: string;
    @Input() public placeholder: string = 'Time';

    @Input()
    public set value(v: any) {
        if (!(v instanceof Date)) throw new Error('This control requres Date as value');
        if (v !== this.innerValue) {
            this.innerValue = v ? v : null;
            this.internalMoment = v ? moment(v) : null;
            this.onChangeCallback(this.innerValue);
        }
    }

    @Output()
    public onDateChanged: EventEmitter<Date> = new EventEmitter<Date>();

    public get readonly(): boolean {
        return !this.inEdit;
    }

    public get value(): any {
        return this.innerValue;
    }

    public get required(): boolean {
        return this.requiredInternal;
    }

    @ViewChild('timeInput', {static: true}) private inputComponent: ElementRef;

    private inEdit: boolean = true;
    private requiredInternal: boolean;


    private initialized: boolean;
    private elementRef: ElementRef;

    private onTouchedCallback: () => void = _.noop;
    private onChangeCallback: (val: any) => void = _.noop;

    private innerValue: Date;

    private kendoDatePicker: kendo.ui.DatePicker;

    private internalMoment: moment.Moment;
    private internalMinMoment: moment.Moment;
    private internalMaxMoment: moment.Moment;

    private dateChangeHandler: (e: kendo.ui.DatePickerChangeEvent) => void;

    constructor(elementRef: ElementRef) {

        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;

        this.dateChangeHandler = (e: kendo.ui.DatePickerChangeEvent) => {
            let m: moment.Moment = moment(this.kendoDatePicker.value());
            this.calendarDateChanged(m);
        };
    }

    public writeValue(v?: any): void {
        if (v === this.innerValue) return;
        this.innerValue = v ? v : null;
        if (v === null || v === undefined) {
            this.internalMoment = null;
            return;
        }
        if (!(v instanceof Date)) throw new Error('DateTimeInput requires Date as value');
        this.internalMoment = moment(v);
    }

    public registerOnChange(fn?: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn?: any): void {
        this.onTouchedCallback = fn;
    }

    public ngOnInit(): void {
        this.initialized = true;
    }

    public ngOnDestroy(): void {
        this.initialized = false;
        if (this.kendoDatePicker) {
            this.kendoDatePicker.destroy();
        }
    }

    public toggleCalendar(): void {
        if (!this.kendoDatePicker) {
            this.createDatePicker();
        }
        this.kendoDatePicker.open();
    }

    public onTimeChanged(d: Date): void {
        //this.updateTime(moment(d));
        this.onDateChanged.emit(this.innerValue);
    }

    private createDatePicker(): void {
        if (this.$datepickerInput && !this.kendoDatePicker) {
            let kendoDatePickerOptions: kendo.ui.DatePickerOptions = this.kendoDatePickerOptions();
            let input: JQuery = this.$datepickerInput;
            input.kendoDatePicker(kendoDatePickerOptions);
            this.kendoDatePicker = input.data('kendoDatePicker');
            this.kendoDatePicker.close();
        }
    }

    private calendarDateChanged(moment: moment.Moment): void {
        this.updateDate(moment);
        this.onDateChanged.emit(this.innerValue);
    }

    private updateTime(moment: moment.Moment): void {
        dateTimeUtils.setTimeToMoment(this.internalMoment, moment.format(appConfig.timeFormat));
        this.innerValue = this.internalMoment.toDate();
        if (this.model && this.field) {
            this.model[this.field] = this.innerValue;
        }
    }

    private updateDate(moment: moment.Moment): void {

        this.internalMoment.year(moment.year()).dayOfYear(moment.dayOfYear());
        this.innerValue = this.internalMoment.toDate();

        if (this.onChangeCallback) this.onChangeCallback(this.innerValue);

        if (this.model && this.field) {
            this.model[this.field] = this.innerValue;
        }
    }

    private get $element(): JQuery {
        return $(this.elementRef.nativeElement);
    }

    private get $calendarBtn(): JQuery {
        return this.$element.find('.button.calendar');
    }

    private get $datepickerInput(): JQuery {
        return this.$element.find('input.date-picker-field');
    }

    private kendoDatePickerOptions(): kendo.ui.DatePickerOptions {
        let options: kendo.ui.DatePickerOptions = {
            format: this.dateFormat,
            change: this.dateChangeHandler
        };
        if (this.internalMinMoment) {
            options.min = this.internalMinMoment.toDate();
        }
        if (this.internalMaxMoment) {
            options.max = this.internalMaxMoment.toDate();
        }
        return options;
    }

}
