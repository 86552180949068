import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var TelepunchSubsection = /** @class */ (function (_super) {
    tslib_1.__extends(TelepunchSubsection, _super);
    function TelepunchSubsection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TelepunchSubsection;
}(EmployeeSectionsBase));
export { TelepunchSubsection };
var PhonePunchRestrictions = /** @class */ (function () {
    function PhonePunchRestrictions() {
    }
    return PhonePunchRestrictions;
}());
export { PhonePunchRestrictions };
