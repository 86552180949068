<slx-spinner [show]="isLoading">
  <div>
    <kendo-grid
      #kendoGridCertification
      class="slx-no-wrap"
      scrollable="'scrollable'"
      [data]="gridState.view"
      (dataStateChange)="gridState.dataStateChange($event)"
      [sortable]="true"
      [sort]="gridState.state.sort"
      [pageable]="true"
      [pageSize]="pageSize"
      [skip]="skip"
      [groupable]="false"
      [selectable]="false"
      (selectionChange)="gridState.selectionChange($event)"
      [slxKendoGridState]="gridState.gridDefinition"
      (stateRestored)="gridState.dataStateChange($event)"
      [filterable]="false"
      [filter]="gridState.state.filter"
      (edit)="gridState.editHandler($event)"
      (save)="gridState.saveHandler($event)"
      (cancel)="gridState.cancelHandler($event)"
      (remove)="gridState.removeHandler($event)"
      (pageChange)="pageChange($event)"
    >
      <!-- Add button  -->
      <ng-template kendoGridToolbarTemplate position="top">
        <div class="col-xs-12" *ngIf="canAdd && isEditable">
          <div class="row">
            <button
              class="theme-iconed-accent-button pull-right"
              (click)="onStartAdd()"
            >
              <span class="icon-button-content">
                <span>Create New</span>
                <i class="fa fa-plus" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
      </ng-template>

      <!-- buttons -->
      <kendo-grid-column
        title=""
        [sortable]="false"
        [filterable]="false"
        width="80"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex">
            <button
              kendoGridEditCommand
              type="button"
              class="slx-button slx-only-icon slx-toolbar edit-icon-color"
              *ngIf="canEdit && isEditable"
              [disabled]="addMode"
            >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button
              kendoGridRemoveCommand
              type="button"
              class="slx-button slx-only-icon slx-toolbar del-icon-color"
              *ngIf="canDelete && isEditable"
              [disabled]="addMode"
            >
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
            </button>
            <button
              kendoGridSaveCommand
              type="button"
              class="slx-button slx-only-icon slx-toolbar slx-margin-r"
              [disabled]="!enableSave"
            >
              <i class="far fa-check-circle" aria-hidden="true"></i>
            </button>
            <button
              kendoGridCancelCommand
              type="button"
              class="slx-button slx-only-icon slx-toolbar"
            >
              <i class="fa fa-ban" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <!-- code -->
      <kendo-grid-column field="code" title="Code" width="120" [sortable]="true">
        <ng-template kendoGridHeaderTemplate>
          Code
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.code}}"
            >{{ dataItem.code }}</span
          >
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <input
              slx-input
              required="true"
              [(ngModel)]="dataItem.code"
              (ngModelChange)="onChangeValue(dataItem)"
              class="k-textbox"
              placeholder="Code"
              slxMaxLength="255"
            />
            <span errorMessage for="required" *ngIf="!dataItem.code"
              >Enter Code</span
            >
            <span errorMessage for="slxMaxLength"
              >Max length '255'</span
            >
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- Name  -->
      <kendo-grid-column field="name" title="Name" width="130" editable="false" [sortable]="true">
        <ng-template kendoGridHeaderTemplate>
          Name
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.name}}"
            >{{ dataItem.name }}</span
          >
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <input
              slx-input
              [(ngModel)]="dataItem.name"
              (ngModelChange)="onChangeValue(dataItem)"
              class="k-textbox"
              placeholder="Name"
              slxMaxLength="255"
            />
            <span errorMessage for="slxMaxLength"
              >Max length '255'</span
            >
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- Description  -->
      <kendo-grid-column
        field="description"
        title="Name"
        width="150"
        editable="false"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          Description
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.description}}"
            >{{ dataItem.description }}</span
          >
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <input
              slx-input
              [(ngModel)]="dataItem.description"
              (ngModelChange)="onChangeValue(dataItem)"
              class="k-textbox"
              placeholder="Description"
              slxMaxLength="500"
            />
            <span errorMessage for="slxMaxLength"
              >Max length '500'</span
            >
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- Cert Date  -->
      <kendo-grid-column
        field="certDate"
        title="Cert Date"
        width="130"
        editable="false"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          Cert. Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.certDate | amDateFormat: appConfig.dateFormat }}
        </ng-template>

        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <slx-date-picker-ngx slx-input
            #startDate="ngModel"
              placeholder="Cert. Date"
              name="startDate{{rowIndex}}"
              [(ngModel)]="dataItem.certDate"
              [minDate]="minDate" 
              [maxDate]="maxDate"
              (ngModelChange)="onChangeValue(dataItem)"
            ></slx-date-picker-ngx>
            <span errorMessage for="required">Enter Cert. Date</span>
            <span errorMessage for="date"></span>
            <span errorMessage *ngIf="certError">Date should be < End Date </span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- End Date -->
      <kendo-grid-column
        field="endDate"
        title="End Date"
        width="130"
        editable="false"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          End Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>
            {{ dataItem.endDate | amDateFormat: appConfig.dateFormat }}
          </span>
        </ng-template>

        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input
            #startDate="ngModel"
            placeholder="End Date"
            name="startDate{{rowIndex}}"
            [(ngModel)]="dataItem.endDate"
            [minDate]="minDate" 
            [maxDate]="maxDate"
            (ngModelChange)="onChangeValue(dataItem)"
          ></slx-date-picker-ngx>
          <span errorMessage for="required">Enter End Date</span>
          <span errorMessage for="date"></span>
          <span errorMessage *ngIf="certError">Date should be > Cert. Date </span>
        </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- License  -->
      <kendo-grid-column field="license" title="License" width="90" [sortable]="true">
        <ng-template kendoGridHeaderTemplate>
          License
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.license}}"
            >{{ dataItem.license }}</span
          >
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <input
              slx-input
              [(ngModel)]="dataItem.license"
              (ngModelChange)="onChangeValue(dataItem)"
              class="k-textbox"
              placeholder="License"
              slxMaxLength="50"
            />
            <span errorMessage for="slxMaxLength"
              >Max length '50'</span
            >
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- confirming body -->
      <kendo-grid-column
        field="confirmingBody"
        title="Confirming Body"
        width="150"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          Confirming Body
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.confirmingBody}}"
            >{{ dataItem.confirmingBody }}</span
          >
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <input
              slx-input
              [(ngModel)]="dataItem.confirmingBody"
              (ngModelChange)="onChangeValue(dataItem)"
              class="k-textbox"
              placeholder="Confirming Body"
              slxMaxLength="255"
            />
            <span errorMessage for="slxMaxLength"
              >Max length '255'</span
            >
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- License Sequence -->
      <kendo-grid-column
        field="licenseSequence"
        title="License Sequence"
        width="150"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          License Sequence
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.licenseSequence }}
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator class="slx-margin-t15">
            <slx-money-input 
              slx-input 
              [prefix]="''" 
              [decimalLimit]="0" 
              [requireDecimal]="false" 
              [allowNegative]="false"
              placeholder="License Sequence"
              class="k-textbox" 
              min="0" slxMin="0" 
              [slxMax]="255" 
              number
              [(ngModel)]="dataItem.licenseSequence"
              (ngModelChange)="onChangeValue(dataItem)">
            </slx-money-input>
          </slx-input-decorator>
          <div class="errorBlock">
            <span class="errorMess" *ngIf="isSequenceError"
              >Max No '255'</span
            >
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</slx-spinner>
