import { EmployeeSectionsBase } from '../employee-sections-base';
import { EmployeeSectionsRotations, IEmployeeSectionsRotations } from './index';
import { IEmployeeSectionsSubsectionContainer } from '../index';
import { IEmployeeSectionsAvailability, EmployeeSectionsAvailability } from './index';

export interface IEmployeeSectionsSchedule {
  rotationsSubsection: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsRotations>;
  availabilitySubsection: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsAvailability>;
}

export class EmployeeSectionsSchedule extends EmployeeSectionsBase {
  public rotationsSubsection: EmployeeSectionsRotations;
  public availabilitySubsection: EmployeeSectionsAvailability;
}
