export class ResponseBody<TData, TMeta> {
  public status: number;
  public data: TData;
  public meta: TMeta;

  constructor(status: number, data?: TData, meta?: TMeta) {
    this.status = status;
    this.data = data;
    this.meta = meta;
  }
}
