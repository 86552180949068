<div class="wizard-container">
<form class="form-horizontal" #additionalForm="ngForm">

  <div class="flex-container padd-lr-10" *ngIf="addEmployeeRestrictions?.badgeId.isVisible">
    <div class="flex-input-container">
      <slx-input-decorator>
        <input slx-input number notLeadZeroes
          [(ngModel)]="addEmployeeModel.badgeId"
          [server]="{validationName: badgeIdValidation, parameters: [0], validationAdapter: employeeActivitiesValidatorAdapter}"
          [inputPolicy]="addEmployeeRestrictions?.badgeId.Policy"
          [required]="'true'"
          min="0"
          max="99999999"
          maxlength="8"
          placeholder="Badge Id"
          type="text"
          name="badgeId"
        >
        <span errorMessage for="required"></span>
        <span errorMessage for="number"></span>
        <span errorMessage for="max">Maximum value for badge id is 99999999 (8 numbers)</span>
        <span errorMessage for="notLeadZeroes">The leading zeroes is not supported</span>
        <span errorMessage [for]="badgeIdValidation">msg from server</span>
      </slx-input-decorator>
    </div>
    <div>
      <button type=button class="theme-iconed-accent-button generate-button" (click)="generateBadgeId()">
          Generate Badge ID
      </button>
    </div>
  </div>
  <div class="flex-container padd-lr-10 mt-5" *ngIf="addEmployeeRestrictions?.payrollNumber.isVisible">
    <div class="flex-input-container">
      <div class="payroll-label">Payroll Number: <span class="payroll-number">{{addEmployeeModel.payrollNumber}}</span></div>
    </div>
  </div>

  <div class="padd-lr-10" *ngIf="timeclocksEnabledInd">
    <slx-spinner [show]="state.isTimeclockLoading">
      <label class="slx-label timeclock-checkbox-title">Timeclock: </label>
      <div class="timeclock-container" >
        <div *ngFor="let timeclock of addEmployeeModel.timeclockDefinitions; let idx = index;">
          <slx-input-decorator className="slx-no-border slx-no-error-block slx-no-label" >
            <slx-checkbox-input slx-input
                                name="badge{{timeclock.item.name}}"
                                fieldName="badge{{timeclock.item.id}}"
                                [className]="'md-checkbox'"
                                [caption]="timeclock.item.name"
                                [(ngModel)]="timeclock.selected">
            </slx-checkbox-input>
          </slx-input-decorator>
        </div>
      </div>
    </slx-spinner>
  </div>

  <div class="col-lg-5" *ngIf="!essTemplatesEnabled">
    <div class="col-md-10 col-md-offset-2">
      <div class="portlet light bottom-bordered">
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
                              name="enableTimesheetsAccess"
                              [readonly]="!canSetEssEnabledInd"
                              fieldName="enableTimesheetsAccess"
                              [className]="'md-checkbox'"
                              caption="Enable ESS/Timesheets Access"
                              [(ngModel)]="addEmployeeModel.enableTimesheetsAccess">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
    </div>
    <div *ngIf="!canSetEssEnabledInd" class="col-md-10 col-md-offset-2">
      Email Address or Mobile Phone fields must be entered for ESS to be enabled
    </div>
    <div *ngIf="essEnabledInd && addEmployeeModel.enableTimesheetsAccess">
      <div class="col-md-10 col-md-offset-2 flex-container">
        <div class="flex-input-container">
          <slx-input-decorator>
            <input  slx-input
                    required
                    *ngIf="!isPwdVisible"
                    type="password" name="password"
                    placeholder="Password"
                    [(ngModel)]="addEmployeeModel.password"
                    number>
            <input  slx-input
                    required
                    *ngIf="isPwdVisible"
                    type="text" name="password"
                    placeholder="Password"
                    [(ngModel)]="addEmployeeModel.password"
                    number>
            <button type=button class="bt-show" (click)="togglePasswordVisibility()">
              <i class="fa" [ngClass]="{'fa-eye-slash':isPwdVisible, 'fa-eye': !isPwdVisible}" aria-hidden="true"></i>
            </button>
            <span errorMessage for="number"></span>
          </slx-input-decorator>
        </div>
        <div>
          <button type=button class="theme-iconed-accent-button generate-button" (click)="generateEssPassword()">
            Generate Password
          </button>
        </div>
      </div>
      <div class="col-md-10 col-md-offset-2">
        <div class="row">
          <div class="col-xs-4" *ngFor="let type of timeMethods; let idx = index;">
            <slx-input-decorator className="slx-no-border">
              <slx-radio-input slx-input
                               name="accessType{{type.id}}"
                               fieldName="{{type.id}}"
                               valueField="id"
                               [option]="type"
                               [caption]="type.name"
                               [(ngModel)]="addEmployeeModel.accessType">
              </slx-radio-input>
            </slx-input-decorator>
          </div>
        </div>
      </div>
      <div class="col-md-10 col-md-offset-2" *ngIf="essLookupService.getOption('timecard')">
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
                              name="timeCard"
                              fieldName="timeCard"
                              [className]="'md-checkbox'"
                              [caption]="essLookupService.getOptionLabel('timecard')"
                              [(ngModel)]="addEmployeeModel.timeCard">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-2" *ngIf="essLookupService.getOption('schedule')">
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
                              name="schedule"
                              fieldName="schedule"
                              [className]="'md-checkbox'"
                              [caption]="essLookupService.getOptionLabel('schedule')"
                              [(ngModel)]="addEmployeeModel.schedule">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-2" *ngIf="essLookupService.getOption('departmentSchedule')">
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
                              name="departmentSchedule"
                              fieldName="departmentSchedule"
                              [className]="'md-checkbox'"
                              [caption]="essLookupService.getOptionLabel('departmentSchedule')"
                              [(ngModel)]="addEmployeeModel.departmentSchedule">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-2" *ngIf="essLookupService.getOption('availability')">
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
                              name="availability"
                              fieldName="availability"
                              [className]="'md-checkbox'"
                              [caption]="essLookupService.getOptionLabel('availability')"
                              [(ngModel)]="addEmployeeModel.availability">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-2" *ngIf="essLookupService.getOption('inservice')">
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
                              name="inservice"
                              fieldName="inservice"
                              [className]="'md-checkbox'"
                              [caption]="essLookupService.getOptionLabel('inservice')"
                              [(ngModel)]="addEmployeeModel.inservice">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-2" *ngIf="essLookupService.getOption('payStubs')">
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
                              name="payStubs"
                              fieldName="payStubs"
                              [className]="'md-checkbox'"
                              [caption]="essLookupService.getOptionLabel('payStubs')"
                              [(ngModel)]="addEmployeeModel.payStubs">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-2" *ngIf="essLookupService.getOption('changePassword')">
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
                              name="allowPasswordChange"
                              fieldName="allowPasswordChange"
                              [className]="'md-checkbox'"
                              [caption]="essLookupService.getOptionLabel('changePassword')"
                              [(ngModel)]="addEmployeeModel.allowPasswordChange">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
        <div class="col-md-10 col-md-offset-2" *ngIf="essLookupService.getOption('changeAddress')">
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
                              name="allowAddressChange"
                              fieldName="allowAddressChange"
                              [className]="'md-checkbox'"
                              [caption]="essLookupService.getOptionLabel('changeAddress')"
                              [(ngModel)]="addEmployeeModel.allowAddressChange">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
    </div>
  </div>
  <div class="col-lg-5" *ngIf="essTemplatesEnabled">
    <slx-input-decorator>
      <input slx-input
        [readonly]="true"
        [ngModel]="essTemplate?.essTemplateDefinition?.name"
        placeholder="Go User Template"
        name="essTemplate"
        type="text">
    </slx-input-decorator>
    <slx-single-message [isActive]="essTemplateSsnError" [canClose]="false" level="high">
      <div class="slx-single-message__message">SSN not defined!
        <span class="slx-single-message__description">The Go user template is configured to set the default password based on SSN</span>
      </div>
    </slx-single-message>
    <slx-single-message [isActive]="essTemplateDOBError" [canClose]="false" level="high">
      <div class="slx-single-message__message">Date of Birth not defined!
        <span class="slx-single-message__description">The Go user template is configured to set the default password based on Date of Birth</span>
      </div>
    </slx-single-message>
  </div>
</form>
<div class="wizard-footer">
     <div class="wizard-buttons">
    <button type="button" slxBackLink class="theme-button-cancel margin-r">Cancel</button>
    <button class="theme-iconed-accent-button prev-step" (click)="prevStep()"><i class="far fa-arrow-alt-circle-left" aria-hidden="true"></i>Back</button>
    <button class="theme-iconed-accent-button next-step" (click)="finishStep()" [disabled]="essTemplateRequirementsInvalid || (!additionalForm.valid && !ignoreValidation)">Finish
      <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
    </button>
   </div>
</div>
</div>
<div modalAnchor></div>
