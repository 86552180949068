import { Component, Provider, ViewChild } from '@angular/core';
import { ModalService } from '../../../common/services/modal/modal.service';
import { DialogOptions } from '../../../common/models/index';
import { UserPasswordSettingsComponent } from '../user-password-settings/user-password-settings.component';

@Component({
  moduleId: module.id,
  selector: 'slx-user-password-settings-dialog',
  templateUrl: 'user-password-settings-dialog.component.html',
  styleUrls: ['user-password-settings-dialog.component.scss']
})
export class UserPasswordSettingsDialogComponent {

  @ViewChild('settingForm', {static: true})
  public settingForm: UserPasswordSettingsComponent;
  public options: DialogOptions;
  public dialogResult: boolean;

  private modalService: ModalService;

  public static openDialog(modalService: ModalService, callback: (result: boolean, cmd: any) => void): UserPasswordSettingsDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 400;
    dialogOptions.width = 700;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];
    let header: string = `Password Settings`;
    let dialog: UserPasswordSettingsDialogComponent = modalService.globalAnchor
      .openDialog(UserPasswordSettingsDialogComponent, header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, null);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }


  public onOk(): void {
    this.settingForm.saveData().then((value: boolean) => {
      this.dialogResult = true;
      this.modalService.closeWindow(this.options.windowUniqueId);
    });
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
