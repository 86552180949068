import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody, Meta, FieldsMeta } from '../../../../core/models/index';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { EmployeeSectionsPerformanceMapService } from './employee-section-performance-map.service';
import {
  EmployeeSectionsPerformance,
  IEmployeeSectionsPerformance,
  EmployeeSectionsAttendancePoints,
  IEmployeeSectionsAttendancePoints,
  EmployeeSectionsAttendance,
  IEmployeeSectionsAttendance,
  EmployeeSectionsAttendanceBuybacks,
  IEmployeeSectionsAttendanceBuybacks,
  EmployeeSectionsAssignedDefinitions,
  IEmployeeSectionsAssignedDefinitions
} from '../../models/index';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';

@Injectable()
export class EmployeeSectionsPerformanceApiService {
  constructor(
    private employeeSectionsPerformanceMapService: EmployeeSectionsPerformanceMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getPerformanceSection(employeeId: number, attendancePointsStartDate: Date, attendancePointsEndDate: Date, attendanceStartDate: Date, attendanceEndDate: Date): Promise<EmployeeSectionsPerformance> {
    Assert.isNotNull(employeeId, 'employeeId');

    let params: HttpParams = this.urlParamsService.convertToParams({
      attendancePointsStartDate: dateTimeUtils.convertToDtoString(attendancePointsStartDate),
      attendancePointsEndDate: dateTimeUtils.convertToDtoString(attendancePointsEndDate),
      attendanceStartDate: dateTimeUtils.convertToDtoString(attendanceStartDate),
      attendanceEndDate: dateTimeUtils.convertToDtoString(attendanceEndDate),
    });

    const url: string = `${this.getPerformanceApiRoot(employeeId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<EmployeeSectionsPerformance> = this.apiUtilService.request<IEmployeeSectionsPerformance, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsPerformance, Meta>) =>
        this.employeeSectionsPerformanceMapService.mapToSectionsPerformance(response.data)
      );

    return promise;
  }

  public getPerformanceAttendancePoints(employeeId: number, startDate: Date, daysCount: number): Promise<EmployeeSectionsAttendancePoints> {
    Assert.isNotNull(employeeId, 'employeeId');

    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate),
      daysCount: daysCount
    });

    const url: string = `${this.getPerformanceApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.performance.attendancePoints}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<EmployeeSectionsAttendancePoints> = this.apiUtilService.request<IEmployeeSectionsAttendancePoints, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsAttendancePoints, FieldsMeta>) =>
        this.employeeSectionsPerformanceMapService.mapToAttendancePoints(response.data, response.meta)
      );

    return promise;
  }

  public getPerformanceAttendanceBuybacks(employeeId: number, startDate: Date, endDate: Date): Promise<EmployeeSectionsAttendanceBuybacks> {
    Assert.isNotNull(employeeId, 'employeeId');

    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    const url: string = `${this.getPerformanceApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.performance.buybackPoints}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<EmployeeSectionsAttendanceBuybacks> = this.apiUtilService.request<IEmployeeSectionsAttendanceBuybacks, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsAttendanceBuybacks, FieldsMeta>) =>
        this.employeeSectionsPerformanceMapService.mapToAttendanceBuyBacks(response.data, response.meta)
      );

    return promise;
  }


  public deleteAttendancePoints(employeeId: number, pointIds: number[]): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getPerformanceApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.performance.attendancePoints}`;
    let body: any = {
      pointIds: pointIds
    };
    let request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url, null, body);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<number, FieldsMeta>) => response.status
      );
    return promise;
  }

  public addAttendancePoints(employeeId: number, dateOn: Date, pointsDefinitionIds: number[]): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getPerformanceApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.performance.attendancePoints}`;
    let body: any = {
      date: dateTimeUtils.convertToDtoString(dateOn),
      definitionIds: pointsDefinitionIds
    };
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<number, FieldsMeta>) => response.status
      );
    return promise;
  }

  public getPerformanceAttendance(employeeId: number, year: number): Promise<EmployeeSectionsAttendance> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(year, 'year');

    let params: HttpParams = this.urlParamsService.convertToParams({
      year: year
    });

    const url: string = `${this.getPerformanceApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.performance.attendance}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<EmployeeSectionsAttendance> = this.apiUtilService.request<IEmployeeSectionsAttendance, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsAttendance, FieldsMeta>) =>
        this.employeeSectionsPerformanceMapService.mapToAttendance(response.data, response.meta)
      );

    return promise;
  }

  public getAssignedDefinitions(employeeId: number, startDate?: Date,category?: string):Promise<EmployeeSectionsAssignedDefinitions[]> {
    const url: string = `${this.getPerformanceApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.performance.assignedDefintions}`;
    const req = this.urlParamsService.createGetRequest(url, { employeeId: employeeId, 
      workeddDate: dateTimeUtils.convertToDtoString(startDate),
      category: category
    });
    return this.apiUtilService.request<IEmployeeSectionsAssignedDefinitions[], Meta>(req)
      .then((response: ResponseBody<IEmployeeSectionsAssignedDefinitions[], Meta>) =>
        this.employeeSectionsPerformanceMapService.mapToAssignedDefinitions(response.data, response.meta));
  }


  public generateAttendancePdf(empId: number, year: number, showActual: boolean): Promise<FileBlobResponse> {
    const url: string = `${this.getApiRoot()}/emppdfform/${empId}/empattendancepdf`;
    const request = this.urlParamsService.createGetRequest(url, { year: year, showActual: showActual });

    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.employees.root}`;
  }

  private getSectionsApiRoot(employeeId: number): string {
    return `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.sections.root}`;
  }

  private getPerformanceApiRoot(employeeId: number): string {
    return `${this.getSectionsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.performance.root}`;
  }
}
