
export class GeoPunchDialogOptions {
  public latitude: number;
  public longitude: number;
  public mapWidth: number;
  public mapHeight: number;
  public mapZoom: number;
  public isInvalidPunch: boolean;

  constructor(latitude: number, longitude: number, isInvalidPunch: boolean, mapWidth: number = 300, mapHeight: number = 200, mapZoom: number = 15) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.mapWidth = mapWidth;
    this.mapHeight = mapHeight;
    this.mapZoom = mapZoom;
    this.isInvalidPunch = isInvalidPunch;
  }
}
