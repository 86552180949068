import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { UrlSegment, NavigationExtras, Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { NotificationsService } from '../../../core/components/index';
import { Employee, ReadFile, Lookup, LookupType, PerformanceReviewCode, PerformanceReviewCodeTypes } from '../../../organization/models/index';
import { LookupService } from '../../../organization/services/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { DateRange, IDateRange } from '../../../core/models/index';

import { StateManagementService, ComponentStateStorageService, ModalService, FileService, ChangeManagementService, EmployeeSectionNavigationService } from '../../../common/services/index';
import { StateResetTypes, IControlState } from '../../../core/models/index';

import { PmReviewRecord, PmReviewEntry, PmFile } from '../models/index';
import { PerformanceManagementApiService } from './performance-management-api.service';
import { PmManagementService } from './pm-management.service';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';

@Injectable()
export class PmCreationManagementService {

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;

  private reviewEntry: PmReviewEntry;
  private storedReviewEntry: PmReviewEntry;
  private files: ReadFile[] = [];

  private loading$ = new Subject<boolean>();
  private reviewChanged$ = new ReplaySubject<PmReviewEntry>(1);
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private closePopup$ = new Subject<void>();
  private ratingsLoaded$ = new ReplaySubject<Lookup>(1);
  private typesLoaded$ = new ReplaySubject<Lookup>(1);
  private templatesLoaded$ = new ReplaySubject<Lookup>(1);
  private onValidityChanged$ = new ReplaySubject<boolean>();

  private formValid: boolean = true;

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  private changeGroupName: string = 'performance-management';
  private changeManagementComponentId: string = 'pm-create-review';

  private selectedEmployee: Employee;

  constructor (
    private manService: PmManagementService,
    private apiService: PerformanceManagementApiService,
    private lookupService: LookupService,
    private notificationService: NotificationsService,
    private fileService: FileService,
    private changeService: ChangeManagementService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  public init(record: PmReviewRecord): void {
    this.changeService.setCurrentComponentId(this.changeManagementComponentId);
    this.subscribeToOrgLevelChanges(record);
  }

  public destroy(): void {
    this.orgLevel = null;

    this.loading$.complete();
    this.reviewChanged$.complete();
    this.orgLevelChanged$.complete();
    this.closePopup$.complete();
    this.ratingsLoaded$.complete();
    this.typesLoaded$.complete();
    this.templatesLoaded$.complete();
    this.onValidityChanged$.complete();
  }

  public getAddedFiles(): ReadFile[] {
    return this.files.slice(0);
  }


  public setFormValidity(valid: boolean) {
    this.formValid = valid;
    this.updateValidity();
  }

  public onValidityChanged(callback: (valid: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.onValidityChanged$.subscribe(callback);
  }

  public requestClosePopup(): void {
    this.changeService.canMoveForward(this.changeManagementComponentId).then(canMove => {
      if (!canMove) {
        this.toggleEditMode(true);
        return;
      }
      this.closePopup();
    });
  }

  private closePopup(): void {
    this.changeService.clearChanges(this.changeGroupName);
    this.changeService.clearCurrentComponentId();
    this.closePopup$.next();
    this.loadReviews();
  }

  public loadReviews(): void {
    this.manService.loadReviews();
  }

  public subscribeToClosePopup(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.closePopup$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToReviewChanged(callback: (v: PmReviewEntry) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.reviewChanged$.subscribe(callback);
  }

  public subscribeToRatingsLoaded(callback: (v: Lookup) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.ratingsLoaded$.subscribe(callback);
  }

  public subscribeToTypesLoaded(callback: (v: Lookup) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.typesLoaded$.subscribe(callback);
  }

  public toggleEditMode(isOn: boolean): void {
    this.reviewEntry.isEditMode = isOn;
    this.emitReviewChanges(this.reviewEntry);
  }

  public cancelEditing(): void {
    this.toggleEditMode(false);
    this.requestClosePopup();
  }

  public setEmployeeWithoutProcessing(emp: Employee) {
    this.selectedEmployee = emp;
  }

  public setEmployee(emp: Employee): void {
    this.selectedEmployee = emp;
    this.reviewEntry.employee = _.cloneDeep(emp);
    this.reviewEntry.organization = _.cloneDeep(emp.organization);
    this.reviewEntry.department = _.cloneDeep(emp.department);
    this.reviewEntry.position = _.cloneDeep(emp.position);
    this.emitReviewChanges(this.reviewEntry);
  }

  public setReviewDate(date: Date): void {
    if (!_.isDate(date)) {
      this.reviewEntry.reviewDate = null;
      return;
    }
    const rDate = this.reviewEntry.reviewDate;
    if (_.isDate(rDate) && rDate.getTime() === date.getTime()) return;
    this.onChangeNotify();
    this.reviewEntry.reviewDate = new Date(date.getTime());
    this.emitReviewChanges(this.reviewEntry);
  }

  public setReviewType(type: PerformanceReviewCode): void {
    if (_.isEqual(this.reviewEntry.reviewType, type)) return;
    this.onChangeNotify();
    this.reviewEntry.reviewType = type;
    this.emitReviewChanges(this.reviewEntry);
  }

  public setRating(rating: PerformanceReviewCode): void {
    if (_.isEqual(this.reviewEntry.rating, rating)) return;
    this.onChangeNotify();
    this.reviewEntry.rating = rating;
    this.emitReviewChanges(this.reviewEntry);
  }

  public setComment(comment: string): void {
    if (this.reviewEntry.comment === comment) return;
    this.onChangeNotify();
    this.reviewEntry.comment = comment;
    this.emitReviewChanges(this.reviewEntry);
  }

  public async saveReview(): Promise<void> {
    this.loading$.next(true);
    let savedReview: PmReviewEntry;
    let message: string;
    try {
      if (this.reviewEntry.isNew) {
        savedReview = await this.apiService.createReviewEntry(this.reviewEntry);
        message = 'Review saved successfully';
      } else {
        savedReview = await this.apiService.updateReviewEntry(this.reviewEntry);
        message = 'Review updated successfully';
      }
      var savedWithAttachments = await this.attachSavedFiles(savedReview.id, this.files);
      savedReview = savedWithAttachments || savedReview;
      this.notificationService.success(message);
    } catch (e) {
      console.error(e);
      this.loading$.next(false);
      return;
    }

    this.reviewEntry.isEditMode = true;
    this.reviewEntry.id = savedReview.id;
    this.reviewEntry.attachments = savedReview.attachments;
    this.reviewEntry.reviewedBy = savedReview.reviewedBy;
    this.reviewEntry.startDate = savedReview.startDate;
    this.reviewEntry.reviewDate = savedReview.reviewDate;
    this.reviewEntry.addedFiles = [];
    this.files = [];
    this.storedReviewEntry = _.cloneDeep(this.reviewEntry);
    this.emitReviewChanges(this.reviewEntry);
    this.changeService.clearChanges(this.changeGroupName);
    this.loading$.next(false);
  }

  public async deleteReview(): Promise<void> {
    this.loading$.next(true);
    try {
      await this.apiService.deleteReviewEntry(this.reviewEntry);
      this.notificationService.success('Review deleted successfully');
    } catch (e) {
      console.error(e);
      this.notificationService.error('Something gone wrong, review hasn\'t deleted');
    } finally {
      this.loading$.next(false);
      this.closePopup();
    }
  }

  public async completeReview(): Promise<void> {
    this.loading$.next(true);
    try {
      var completedReview = await this.apiService.completeReviewEntry(this.reviewEntry);
      this.reviewEntry.status = completedReview.status;
      this.reviewEntry.completionDate = completedReview.completionDate;
      this.notificationService.success('Review completed successfully');
    } catch (e) {
      console.error(e);
      this.notificationService.error('Something gone wrong, review hasn\'t completed');
    } finally {
      this.reviewEntry.isEditMode = false;
      this.emitReviewChanges(this.reviewEntry);
      this.loading$.next(false);
      this.closePopup();
    }
  }

  public async deleteAttachment(attachmentId: number): Promise<void> {
    this.loading$.next(true);
    try {
      await this.apiService.deleteAttachment(this.reviewEntry.id, attachmentId);
      this.reviewEntry.attachments = _.filter(this.reviewEntry.attachments, (a) => a.id !== attachmentId);
      this.emitReviewChanges(this.reviewEntry);
      this.onChangeNotify();
    } catch (e) {
      console.error(e);
    } finally {
      this.loading$.next(false);
    }
  }

  public downloadAttachment(attachmentId: number): Promise<void> {
    this.loading$.next(true);
    try {
      let promise: Promise<void> = this.apiService.downloadAttachment(attachmentId)
        .then((file) => {
          return this.fileService.saveToFileSystem(file.blob, file.file);
        });
      return promise;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading$.next(false);
    }
  }

  public async saveFiles(files: ReadFile[]): Promise<void> {
    this.files = this.files.concat(files);
    this.reviewEntry.addedFiles = this.files ? this.files.slice(0) : null;
    this.onChangeNotify();
    this.emitReviewChanges(this.reviewEntry);
  }

  public async deleteFile(file: ReadFile): Promise<void> {
    this.loading$.next(true);
    this.files = _.filter(this.files, (f: ReadFile) => f.fileName !== file.fileName);
    this.reviewEntry.addedFiles = this.files ? this.files.slice(0) : null;
    this.onChangeNotify();
    await Observable.of(true).delay(500).toPromise();
    this.loading$.next(false);
    this.emitReviewChanges(this.reviewEntry);
  }

  public onChangeNotify(): void {
    this.changeService.changeNotify(this.changeGroupName);
  }

  public navigateToUserProfile(id: number) {
    this.changeService.canMoveForward(this.changeManagementComponentId).then(canMove => {
      if (!canMove) {
        return;
      }
      let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
      const urlTree = navService.getUrlTreeFromRoot(id, true);
      const extras: NavigationExtras = {
        skipLocationChange: false,
        replaceUrl: false,
      };
      this.router.navigateByUrl(urlTree, extras);
      this.closePopup();
    });
  }

  private async attachSavedFiles(id: number, files: ReadFile[]): Promise<PmReviewEntry> {
    if (_.size(files) > 0) {
      var review = await this.apiService.saveAttachments(id, files);
      return review;
    }
    return null;
  }

  private async loadResources(record: PmReviewRecord): Promise<any> {
    this.loading$.next(true);
    await this.processReviewRecord(record);
    await this.loadPerformanceReviewCodes();
    await this.loadPerformanceReviewTemplates();
    this.emitReviewChanges(this.reviewEntry);
    this.loading$.next(false);
  }

  private async processReviewRecord(record: PmReviewRecord): Promise<void> {
    this.reviewEntry = await this.getReviewEntry(record);
    this.storedReviewEntry = _.cloneDeep(this.reviewEntry);
    this.reviewEntry.isEditMode = !_.isObjectLike(record);
  }

  private getReviewEntry(record: PmReviewRecord): Promise<PmReviewEntry> {
    if (!_.isObjectLike(record)) {
      let review = new PmReviewEntry();
      review.employee = this.selectedEmployee;
      return Promise.resolve(review);
    }
    return this.apiService.loadReviewEntry(record.id);
  }

  private async loadPerformanceReviewCodes(): Promise<void> {
    const lookup = await this.lookupService
      .getLookup({ lookupType: LookupType.performanceReviewCodes, orgLevelId: this.orgLevel.id });
    const ratings = this.filterOutCodes(lookup, PerformanceReviewCodeTypes.rating);
    const reviewTypes = this.filterOutCodes(lookup, PerformanceReviewCodeTypes.reviewType);

    this.ratingsLoaded$.next(ratings);
    this.typesLoaded$.next(reviewTypes);
  }

  private async loadPerformanceReviewTemplates(): Promise<void> {
    const lookup = await this.lookupService
      .getLookup({ lookupType: LookupType.performanceReviewTemplates, orgLevelId: this.orgLevel.id, updateCacheForced: false });
    this.reviewEntry.templates = lookup.items;
    this.templatesLoaded$.next(lookup);
  }

  private filterOutCodes(lkp: Lookup, codeType: PerformanceReviewCodeTypes): Lookup {
    if (_.size(lkp.items) > 0) {
      const lookup = new Lookup();
      lookup.type = lkp.type;
      lookup.items = _.filter(lkp.items, ct => ct.type.name === codeType);
      lookup.titleField = lkp.titleField;
      lookup.valueField = lkp.valueField;
      return lookup;
    }
    return lkp;
  }

  private subscribeToOrgLevelChanges(record: PmReviewRecord): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => _.isFinite(_.get(o, 'id')))
      .subscribe(async (orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        await this.loadResources(record);
      });
  }

  private emitReviewChanges(r: PmReviewEntry): void {
    this.reviewChanged$.next(_.cloneDeep(r));
  }

  private updateValidity(): void {
    this.onValidityChanged$.next(this.formValid);
  }
}
