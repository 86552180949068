import * as _ from 'lodash';
import { ReportParameter } from '../../../models/index';
import { LookupType } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
var ReportLookupMultiselectParameterComponent = /** @class */ (function () {
    function ReportLookupMultiselectParameterComponent() {
        this.includeThousandsSeparator = false;
        this.lookupsNamesNotActiveOnly = [LookupType.employeeList];
    }
    Object.defineProperty(ReportLookupMultiselectParameterComponent.prototype, "parameter", {
        get: function () {
            return this.m_parameter;
        },
        set: function (value) {
            this.m_parameter = value;
            this.processParameter(this.m_parameter);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportLookupMultiselectParameterComponent.prototype, "lookupValue", {
        get: function () {
            return this.m_lookupValue;
        },
        set: function (value) {
            if (this.parameter) {
                this.parameter.value = value;
            }
            this.m_lookupValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportLookupMultiselectParameterComponent.prototype, "lookupName", {
        get: function () {
            if (this.parameter && this.parameter.dataType)
                return this.parameter.dataType.lookupName;
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportLookupMultiselectParameterComponent.prototype, "isActiveOnly", {
        //TODO: Rework with getting IsActive parameter attribute from API(stored in report definition)
        get: function () {
            return _.indexOf(this.lookupsNamesNotActiveOnly, this.lookupName) === -1;
        },
        enumerable: true,
        configurable: true
    });
    ReportLookupMultiselectParameterComponent.prototype.processParameter = function (parameter) {
        parameter.value = parameter.defaultValue;
    };
    return ReportLookupMultiselectParameterComponent;
}());
export { ReportLookupMultiselectParameterComponent };
