
<div class="slx-date-range">
  <slx-input-decorator class="slx-date-range-date">
    <slx-date-picker-ngx slx-input #startDate="ngModel"
      name="startDate"
      placeholder="{{ startDatePlaceholder }}"
      [maxDate]="dates.endDate"
      [format]="format"
      [readonly]="startDateReadonly"
      [acceptNullDate]="false"
      [ngModel]="dates.startDate"
      (ngModelChange)="onStartDateChanged($event)"
    ></slx-date-picker-ngx>
    <span errorMessage for="date"></span>
    <span errorMessage for="maxDate">The value should be lower than {{ dates.endDate | amDateFormat: appConfig.dateFormat }}</span>
  </slx-input-decorator>

  <slx-input-decorator class="slx-date-range-date">
    <slx-date-picker-ngx slx-input #endDate="ngModel"
      name="endDate"
      placeholder="{{ endDatePlaceholder }}"
      [minDate]="dates.startDate"
      [format]="format"
      [readonly]="endDateReadonly"
      [acceptNullDate]="false"
      [ngModel]="dates.endDate"
      (ngModelChange)="onEndDateChanged($event)"
    ></slx-date-picker-ngx>
    <span errorMessage for="date"></span>
    <span errorMessage for="minDate">The value should be greater than {{ dates.startDate | amDateFormat: appConfig.dateFormat }}</span>
  </slx-input-decorator>
  <div class="common-errors slx-error-block" *ngIf="!isValidRange&&startDate.valid&&endDate.valid">
    <span>{{ maxRangeErrorText }}</span>
  </div>
  <button type="button" [ngClass]="isSmallDevice ? 'set-margin' : '' " *ngIf="hasApplyButton" [disabled]="!datesValid" (click)="onApply()" class="apply-button theme-button-apply">Apply</button>
</div>
