export * from './employee-rehire/employee-rehire.component';
export * from './employee-rehire-dialog/employee-rehire-dialog.component';

import { EmployeeRehireComponent } from './employee-rehire/employee-rehire.component';
import { EmployeeRehireDialogComponent } from './employee-rehire-dialog/employee-rehire-dialog.component';

export const employeeRehireComponents: any[] = [
    EmployeeRehireComponent,
    EmployeeRehireDialogComponent
];
