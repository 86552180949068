import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, FieldsMeta } from '../../../core/models/index';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { organizationConfig } from '../../../organization/organization.config';
import { dateTimeUtils } from '../../../common/utils/index';
import { IAccrualPolicy, AccrualPolicy, IDynamicAccrualPolicies, DynamicAccrualPolicies, IAccrualPolicyRule, AccrualPolicyRule } from '../../models/accruals/index';
import { AccrualMapService } from './accrual-map.service';

@Injectable()
export class AccrualApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private accrualMapService: AccrualMapService) {
  }

  public getAllowDynamicAccrualPolicies(): Promise<boolean> {
    const url: string = `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.app.root}/${organizationConfig.api.app.settings.root}/${organizationConfig.api.app.settings.accruals.root}/${organizationConfig.api.app.settings.accruals.allowDynamicAccrualPolicies}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<boolean> = this.apiUtilService.request<IDynamicAccrualPolicies, FieldsMeta>(request)
      .then((response: ResponseBody<IDynamicAccrualPolicies, FieldsMeta>) =>
        this.accrualMapService.mapToDynamicPolicy(response.data, response.meta)
      );
    return promise;
  }

  public async getAccrualsPoliciesRecords(orgLevelId: number, effectiveDate: Date): Promise<AccrualPolicy[]> {
    const url: string = `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.accruals.root}/${orgLevelId}/${organizationConfig.api.accruals.policy.root}/${organizationConfig.api.accruals.policy.all}`;
    const request = this.urlParamsService.createGetRequest(url, {
      effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate)
    });
    return this.apiUtilService
      .request<IAccrualPolicy[], FieldsMeta>(request)
      .then((response: ResponseBody<IAccrualPolicy[], FieldsMeta>) => this.accrualMapService.mapAccrualsPoliciesRecords(response.data));
  }

       
}
