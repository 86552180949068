import * as _ from 'lodash';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeePayRateComponent } from '../../models/employee-pay-rate-component';

@Component({
  moduleId: module.id,
  selector: 'slx-pay-rate-component',
  templateUrl: 'pay-rate-component.component.html',
  styleUrls: ['pay-rate-component.component.scss']
})
export class PayRateComponentComponent implements OnInit {

  @Output()
  public onPayRateChange: EventEmitter<number>;

  @Input()
  public set state(value: boolean) {
    this._isEditMode = value;
  }

  @Input()
  public set payRatesRecords(value: EmployeePayRateComponent[]) {
    if (!value) {
      value = [];
    }
    for (let i: number = value.length; i < value.length; i++) {
      value.push(new EmployeePayRateComponent());
    }

    this._payRateRecord = value;
  }

  public get payRatesRecords(): EmployeePayRateComponent[] {
    return this._payRateRecord;
  }

  private _payRateRecord: EmployeePayRateComponent[];
  public payRateTotal: number = 0;
  public currencyFormat = 'n4';
  public _isEditMode: boolean;

  constructor() {
    this.onPayRateChange = new EventEmitter<number>();
  }

  public ngOnInit(): void {
    this.calculatePayRate();
  }

  public showPayRateComponent(): void { }

  public calculatePayRate(): void {
    this.payRateTotal = _.sumBy(this.payRatesRecords, (value: EmployeePayRateComponent) => value.rate || 0);
    this.onPayRateChange.emit(this.payRateTotal);
  }
}
