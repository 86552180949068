<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents">
      <slx-accruals-balances-toolbar></slx-accruals-balances-toolbar>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <slx-accruals-balances-grid></slx-accruals-balances-grid>
    </div>
  </div>
</slx-spinner>
