import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { process, State, SortDescriptor } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { KendoGridStateHelper, cancelEvent, saveEvent, removeEvent, ConfirmOptions, ConfirmDialogComponent, ModalService } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { RangeDates } from '../../../../../common/models/range-dates';
import * as moment from 'moment';
import { EmployeeSectionAccrualTransactions, AccrualTypesDialogOptions } from '../../../models';
import { EmployeeSectionsAccrualsApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { ResetBalanceDialogComponent } from './reset-balance-dialog/reset-balance-dialog.component';
import { appConfig } from '../../../../../../app/app.config';
import { AppSettingsManageService } from './../../../../../app-settings/services/index';
import { NotificationsService } from './../../../../../../app/core/components';
var setEmployeeIdToResetBalance = /** @class */ (function () {
    function setEmployeeIdToResetBalance() {
    }
    return setEmployeeIdToResetBalance;
}());
export { setEmployeeIdToResetBalance };
var EmployeeSectionsTransactionsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsTransactionsComponent, _super);
    function EmployeeSectionsTransactionsComponent(modalService, changeDetector, employeeSectionsAccrualsApiService, appSettingsManageService, decorator, ngZone, notificationService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.modalService = modalService;
        _this.changeDetector = changeDetector;
        _this.employeeSectionsAccrualsApiService = employeeSectionsAccrualsApiService;
        _this.appSettingsManageService = appSettingsManageService;
        _this.notificationService = notificationService;
        _this.createdDateTime = moment().format(appConfig.militaryDateTimeFormat);
        _this.sort = [];
        _this.skip = 0;
        _this.pageSize = 10;
        _this.accrualTypesList = [];
        _this.deleteAccrualTransactions = [];
        _this.employeeActionToolBar = true;
        _this.pdfTemplate = {
            allPages: true,
            landscape: true,
            paperSize: 'A4',
            scale: 0.7,
            repeatHeaders: true,
            margin: '0.25in',
            marginTop: '0.675in',
            marginBottom: '0.5in',
            marginRight: '0.15in',
            marginLeft: '0.15in'
        };
        _this.gridState = new KendoGridStateHelper();
        _this.canEdit = true;
        _this.gridState.state.skip = 0;
        _this.skip = 0;
        _this.createSubscriptions();
        var endDate = moment().endOf('day');
        var startDate = moment().endOf('day');
        _this.endDate = endDate.add(10, 'day').startOf('day').toDate();
        _this.startDate = startDate.subtract(90, 'day').startOf('day').toDate();
        _this.gridState.state.take = _this.pageSize;
        _this.selectedRange = new RangeDates();
        _this.selectedRange.startDate = _this.startDate;
        _this.selectedRange.endDate = _this.endDate;
        _this.employeeName = _this.employeeShortInfo.fullName;
        _this.employeePosition = _this.employeeShortInfo.position.name;
        _this.employeeType = _this.employeeShortInfo.type;
        _this.employeePayRollNumber = _this.employeeShortInfo.payrollNumber;
        _this.updateLookups(_this.transactionDate);
        return _this;
    }
    Object.defineProperty(EmployeeSectionsTransactionsComponent.prototype, "accrualTransactions", {
        set: function (employeeAccrualTransactions) {
            this.employeeAccrualTransactions = employeeAccrualTransactions;
            var canEdit = _.get(employeeAccrualTransactions, 'actions.canEdit', null);
            if (_.isBoolean(canEdit)) {
                this.canEdit = canEdit;
            }
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTransactionsComponent.prototype, "employeeId", {
        get: function () {
            return this.m_employeeId;
        },
        set: function (value) {
            this.m_employeeId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTransactionsComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsTransactionsComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsTransactionsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.updateLookups(this.transactionDate);
        this.getSettings();
    };
    EmployeeSectionsTransactionsComponent.prototype.ngOnDestroy = function () {
    };
    EmployeeSectionsTransactionsComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.EnableRate4DecimalPlaces = config.EnableRate4DecimalPlaces;
                        if (this.EnableRate4DecimalPlaces) {
                            this.cells = {
                                format: '0.0000',
                                textAlign: 'left'
                            };
                        }
                        else {
                            this.cells = {
                                format: '0.00',
                                textAlign: 'left'
                            };
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(EmployeeSectionsTransactionsComponent.prototype, "isShowHighPrecision", {
        get: function () {
            return this.EnableRate4DecimalPlaces;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsTransactionsComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.employeeAccrualTransactions.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    EmployeeSectionsTransactionsComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    EmployeeSectionsTransactionsComponent.prototype.onClickExport = function (isPDF) {
        this.exportTo(isPDF);
    };
    EmployeeSectionsTransactionsComponent.prototype.getFileName = function (isPDF) {
        if (isPDF) {
            return this.employeeName + 'Accrual Transactions History From ' + this.getDates() + '.pdf';
        }
        else {
            return this.employeeName + 'Accrual Transactions History From ' + this.getDates() + '.xlsx';
        }
    };
    EmployeeSectionsTransactionsComponent.prototype.getTitle = function () {
        return 'Employee Accrual Transactions for the Period ' + this.getDates();
    };
    EmployeeSectionsTransactionsComponent.prototype.getDates = function () {
        return moment(this.selectedRange.startDate).format(appConfig.dateFormat) + ' to ' + moment(this.selectedRange.endDate).format(appConfig.dateFormat);
    };
    EmployeeSectionsTransactionsComponent.prototype.getEmployeeInfo = function () {
        return this.employeeName + '   ' + this.employeePosition + '   ' + this.employeeType + '   ' + this.employeePayRollNumber;
    };
    EmployeeSectionsTransactionsComponent.prototype.getSubsectionModel = function () {
        return this.employeeAccrualTransactions;
    };
    EmployeeSectionsTransactionsComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function (item) {
            _this.resetAddMode(item);
        });
        this.gridCancelSubscription = this.gridState.onCancel$.subscribe(function (item) {
            _this.cancelSubscription();
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (item) {
            _this.gridSaveSubscribe(item.dataItem, item.isNew);
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (item) {
            _this.removeSubscription(item.dataItem);
        });
    };
    EmployeeSectionsTransactionsComponent.prototype.resetAddMode = function (item) {
        this.addMode = false;
        this.editingItem = item;
    };
    EmployeeSectionsTransactionsComponent.prototype.gridSaveSubscribe = function (item, isNew) {
        item.isDirty = true;
        if (isNew) {
            var source = this.employeeAccrualTransactions.records;
            this.employeeAccrualTransactions.records = _.concat(source, [item]);
            this.addMode = false;
        }
        this.refreshGrid();
    };
    EmployeeSectionsTransactionsComponent.prototype.cancelSubscription = function () {
        this.editingItem = null;
        this.addMode = false;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsTransactionsComponent.prototype.removeSubscription = function (item) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure that you want to remove this transaction?', this.modalService, function (result) {
            if (result) {
                _this.doRemove(item);
            }
        }, options);
    };
    EmployeeSectionsTransactionsComponent.prototype.doRemove = function (item) {
        this.employeeAccrualTransactions.records = _.without(this.employeeAccrualTransactions.records, item);
        item.deleted = true;
        item.isDirty = true;
        this.deleteAccrualTransactions.push(item);
        this.doSave();
        this.refreshGrid();
    };
    EmployeeSectionsTransactionsComponent.prototype.loadOnDateChanged = function (_a) {
        var _this = this;
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startProgress();
        this.selectedRange = { startDate: startDate, endDate: endDate };
        this.employeeSectionsAccrualsApiService.getAccrualTransactions(this.employeeId, this.selectedRange.startDate, this.selectedRange.endDate)
            .then(function (subsectionData) {
            _this.employeeAccrualTransactions = subsectionData;
            _this.state.isLoaded = true;
            _this.skip = 0;
            _this.gridState.state.skip = 0;
            _this.refreshGrid();
            _this.stopProgress();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        })
            .catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsTransactionsComponent.prototype.loadSubsection = function () {
        this.loadOnDateChanged(this.selectedRange);
    };
    EmployeeSectionsTransactionsComponent.prototype.updateLookups = function (transactionDate) {
        var _this = this;
        if (this.m_employeeId && this.employeeAccrualTransactions) {
            this.employeeSectionsAccrualsApiService.getAccrualTypes(this.m_employeeId, transactionDate)
                .then(function (accrualtype) {
                _this.accrualTypesList = accrualtype;
            });
        }
    };
    EmployeeSectionsTransactionsComponent.prototype.doSave = function () {
        var _this = this;
        this.startProgress();
        if (this.deleteAccrualTransactions && this.deleteAccrualTransactions.length > 0) {
            this.employeeAccrualTransactions.records = _.concat(this.employeeAccrualTransactions.records, this.deleteAccrualTransactions);
        }
        var empAccTranUpdate = new EmployeeSectionAccrualTransactions();
        empAccTranUpdate = this.employeeAccrualTransactions;
        empAccTranUpdate.records = _.filter(this.employeeAccrualTransactions.records, function (empTransactionRecords) { return empTransactionRecords.isDirty; });
        if (empAccTranUpdate && empAccTranUpdate.records && empAccTranUpdate.records.length > 0) {
            this.employeeSectionsAccrualsApiService.postAccrualTransactions(this.m_employeeId, empAccTranUpdate, this.selectedRange)
                .then(function (status) {
                _this.state.isLoaded = true;
                _this.deleteAccrualTransactions.length = 0;
                _this.loadSubsection();
                _this.refreshGrid();
                _this.stopProgress();
            }).catch(function (reason) {
                _this.stopProgress();
                _this.onActionError(reason);
            });
        }
        else {
            this.loadSubsection();
            this.stopProgress();
            this.refreshGrid();
        }
    };
    EmployeeSectionsTransactionsComponent.prototype.colorCode = function (code) {
        var result;
        if (code > 0) {
            result = '#59c152';
        }
        else if (code < 0) {
            result = '#ff0000';
        }
        else {
            result = '#000000';
        }
        return result;
    };
    EmployeeSectionsTransactionsComponent.prototype.rowCallback = function (context) {
        if (context.dataItem.isLastManualTransaction == true)
            return {
                manualTransactions: true
            };
    };
    EmployeeSectionsTransactionsComponent.prototype.sortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeeSectionsTransactionsComponent.prototype.pageChange = function (event) {
        this.skip = event.skip;
        this.refreshGrid();
    };
    EmployeeSectionsTransactionsComponent.prototype.noAccrualTrasactionData = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        ConfirmDialogComponent.openDialog('Warning', 'There is no policy assigned to perform balance reset operations', this.modalService, function (result) {
            if (result) {
                _this.loadSubsection();
            }
        }, options);
    };
    EmployeeSectionsTransactionsComponent.prototype.onResetBal = function () {
        var _this = this;
        if (!this.m_employeeId) {
            this.noAccrualTrasactionData();
            return;
        }
        var tDate = moment().startOf('day').toDate();
        this.employeeSectionsAccrualsApiService.getAccrualTypes(this.m_employeeId, tDate)
            .then(function (accrualTypes) {
            if (!accrualTypes.length) {
                _this.noAccrualTrasactionData();
                return;
            }
            var accr = new AccrualTypesDialogOptions();
            accr.accrual = accrualTypes;
            accr.empId = _this.employeeId;
            accr.fromDate = _this.selectedRange.startDate;
            accr.toDate = _this.selectedRange.endDate;
            accr.hireDate = _this.employeeShortInfo.dateHired;
            ResetBalanceDialogComponent.openDialog('Reset Balance', _this.modalService, accr, function (result) {
                if (result) {
                    _this.state.isLoaded = true;
                    _this.loadSubsection();
                    _this.state.isLoaded = false;
                }
            });
        });
    };
    EmployeeSectionsTransactionsComponent.prototype.onRecal = function () {
        var _this = this;
        if (this.m_employeeId) {
            this.employeeSectionsAccrualsApiService.postAccrualsRecalculate(this.m_employeeId).
                then(function (response) {
                if (response.status && response.data.status) {
                    _this.state.isLoaded = true;
                    _this.loadSubsection();
                    _this.state.isLoaded = false;
                    _this.notificationService.success('Success', response.data.statusMessage != null && response.data.statusMessage.length > 0 ? response.data.statusMessage : 'Accrual Recaculation completed successfully');
                }
                else if (response.status && !response.data.status) {
                    _this.notificationService.error('Error', response.data.statusMessage != null && response.data.statusMessage.length > 0 ? response.data.statusMessage : 'There is error an error occured during accrual recalculation');
                }
            }).catch(function (reason) {
                _this.onActionError(reason);
            });
        }
    };
    EmployeeSectionsTransactionsComponent.prototype.refreshGrid = function () {
        if (!this.employeeAccrualTransactions) {
            this.gridView = null;
            this.stopProgress();
            return;
        }
        this.gridState.view = process(this.employeeAccrualTransactions.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsTransactionsComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsTransactionsComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsTransactionsComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsTransactionsComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsTransactionsComponent.prototype, "gridCancelSubscription", void 0);
    return EmployeeSectionsTransactionsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsTransactionsComponent };
