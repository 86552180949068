import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
var DropdownMultiSelectComponent = /** @class */ (function () {
    function DropdownMultiSelectComponent() {
        this.disabled = false;
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
    }
    Object.defineProperty(DropdownMultiSelectComponent.prototype, "options", {
        set: function (items) {
            this.dataFiltered = items;
            this.m_dataOriginal = items;
            this.m_internalValue = this.preselectedValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownMultiSelectComponent.prototype, "internalValue", {
        get: function () {
            return this.m_internalValue;
        },
        set: function (value) {
            if ((value && value.length === 0) || value === undefined) {
                value = null;
            }
            this.m_internalValue = value;
            this.onChangeCallback(value);
        },
        enumerable: true,
        configurable: true
    });
    DropdownMultiSelectComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = function (value) { Promise.resolve(null).then(function () { return fn(value); }); };
    };
    DropdownMultiSelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DropdownMultiSelectComponent.prototype.filterChange = function (filter) {
        var _this = this;
        if (!this.m_dataOriginal)
            return;
        this.dataFiltered = this.m_dataOriginal.filter(function (item) {
            if (!item[_this.titleField] || !item[_this.titleField].toLowerCase) {
                return false;
            }
            return item[_this.titleField].toLowerCase().indexOf(filter.toLowerCase()) !== -1;
        });
    };
    DropdownMultiSelectComponent.prototype.writeValue = function (value) {
        if (!this.m_dataOriginal) {
            this.preselectedValue = value;
            return;
        }
        if (value === undefined) {
            value = null;
            this.preselectedValue = this.m_internalValue = null;
        }
        this.preselectedValue = this.m_internalValue = value;
    };
    return DropdownMultiSelectComponent;
}());
export { DropdownMultiSelectComponent };
