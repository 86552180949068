<div class="org-str-container">
  <kendo-tabstrip class="slx-tabs">
    <kendo-tabstrip-tab title="Organization" [selected]="true">
      <ng-template kendoTabContent>
        <div class="m-2">
          <p class="titleOfCodeMappingTabs">Set Organization Codes:</p>
        </div>
        <div class="slx-high-box__body slx-main-content-indents">
          <slx-org-structure-codes-tab class="slx-full-height" [isReadonly]="isReadOnly" [tabName]="'Organization'" [tabData]="tabData.Organization"
            (saveDataHandler)="saveDataHandler($event)"></slx-org-structure-codes-tab>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Departments">
      <ng-template kendoTabContent>
        <div class="m-2">
          <p class="titleOfCodeMappingTabs">Set Department Codes:</p>
        </div>       
        <slx-org-structure-codes-tab [isReadonly]="isReadOnly" [tabName]="'Departments'" [tabData]="tabData.Departments"
        (saveDataHandler)="saveDataHandler($event)"
        ></slx-org-structure-codes-tab>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Positions">
      <ng-template kendoTabContent>
        <div class="m-2">
          <p class="titleOfCodeMappingTabs">Set Position Codes & Payroll Description:</p>
        </div>        
        <slx-org-structure-codes-tab [isReadonly]="isReadOnly" [tabName]="'Positions'" [tabData]="tabData.Positions"
        (saveDataHandler)="saveDataHandler($event)"
        ></slx-org-structure-codes-tab>
      </ng-template>
    </kendo-tabstrip-tab>
</kendo-tabstrip>
<button type="button" [disabled]="saveRequestBody.Departments.length === 0 && saveRequestBody.Organization.length === 0 && saveRequestBody.Positions.length === 0" class="theme-button-apply save-Btn" (click)="saveShortCode()">
  Save
</button>
</div>