import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';

import { LMCreationAbsenceManagementService } from '../../../services/index';
import { RecurrenceRuleOption, RecurrenceFrequencies, LMCommonRecurrence } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-daily-recurrence',
  templateUrl: 'lm-daily-recurrence.component.html',
  styleUrls: ['lm-daily-recurrence.component.scss']
})
export class LMDailyRecurrenceComponent extends LMCommonRecurrence implements OnInit {
  public limitEndOnDate: Date;
  public calculatedMaxDate: Date;

  public get canEditRequest(): boolean {
    return this.manService.canChange;
  }

  constructor(private manService: LMCreationAbsenceManagementService) {
    super();
  }

  public ngOnInit(): void {
    const estimateEndDate = this.defineStartDate();
    const recurrence = this.manService.getRecurrence();

    this.defineDefaults(recurrence, estimateEndDate);
    this.defineMode(recurrence.count, recurrence.until);
    this.updatesRestrictionDates();

    this.subscriptions.loaRequest = this.manService
      .subscribeToChangedRequest(() => {
        this.defineStartDate();
        this.onChangeRecurrence();
      });
  }

  public onChangeRecurrence() {
    const options = new RecurrenceRuleOption(this.interval, null, null);

    this.updatesRestrictionDates();
    this.updateEndMode(options);

    this.manService.setRecurrence(RecurrenceFrequencies.daily, options);
  }

  private defineDefaults(recurrence: RecurrenceRuleOption, endDate: Date): void {
    this.interval = recurrence.interval || 1;
    this.count = recurrence.count || 1;
    this.until = recurrence.until || endDate;
  }

  private updatesRestrictionDates(): void {
    const copyOfEndOnDate = this.copyDate(this.limitEndOnDate);
    this.calculatedMaxDate = new Date(copyOfEndOnDate.getTime());
    if (!_.isDate(this.until) || this.until < this.calculatedMaxDate) {
      this.until = this.copyDate(this.calculatedMaxDate);
    }
  }

  private updateEndMode(options: RecurrenceRuleOption): void {
    switch(this.endMode) {
      case this.endAfterOccurrence:
        options.count = this.count;
        break;
      case this.endOnDate:
        options.until = this.until;
        break;
    }
  }

  private defineMode(count: number, until: Date): void {
    if (_.isFinite(count) && count > 0) {
      this.endMode = this.endAfterOccurrence;
    } else {
    this.endMode = this.endOnDate;
    }
  }

  private defineStartDate(): Date {
    const { endDate } = this.manService.getLoaDates();
    const { endDate: estimateEndDate } = this.manService.getEstamatedDates();
    this.limitEndOnDate = endDate || new Date();
    return estimateEndDate;
  }
}
