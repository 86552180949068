export interface ISlateMessageGroupInfo {
  groupId: number;
  createdDate: Date;
  updatedDate: Date;
  subject: string;
  createdByDisplayName: string;
  latestMessage: ISlateLatestMessageInfo;
  employees: Array<ISlateMessageEmployeeInfo>;
}

export class SlateMessageGroupInfo {
  groupId: number;
  createdDate: Date;
  updatedDate: Date;
  subject: string;
  createdByDisplayName: string;
  latestMessage: any;
  employees: any;
}


export interface ISlateLatestMessageInfo {
  messageId: number;
  createdDate: Date;
  messageContent: string;
}

export class SlateLatestMessageInfo {
  messageId: number;
  createdDate: Date;
  messageContent: string;
}

export interface ISlateMessageEmployeeInfo {
  employeeId: number;
  employeeName: string;
}

export class SlateMessageEmployeeInfo {
  employeeId: number;
  employeeName: string;
}


export interface ISlateMessageGroupInfoOfGroupId {
  groupId: number;
  subject: string;
  createdByDisplayName: string;
  messages: Array<ISlateMessageInfoOfGroup>;
  employees: Array<ISlateMessageEmployeesInGroup>;
}

export class SlateMessageGroupInfoOfGroupId {
  groupId: number;
  subject: string;
  createdByDisplayName: string;
  messages: Array<ISlateMessageInfoOfGroup>;
  employees: Array<ISlateMessageEmployeesInGroup>;
}

export interface ISlateMessageInfoOfGroup {
  messageId: number;
  createdDate: Date;
  messageContent: string;
  createdByDisplayName: string;
  employees: Array<ISlateMessageEmployeeByGroupIdInfo>;
}

export class SlateMessageInfoOfGroup {
  messageId: number;
  createdDate: Date;
  messageContent: string;
  createdByDisplayName: string;
  employees: Array<ISlateMessageEmployeeByGroupIdInfo>;
}

export interface ISlateMessageEmployeeByGroupIdInfo {
  employeeId: number;
  read: boolean;
  readAt: Date;
 }

export class SlateMessageEmployeeByGroupIdInfo {
  employeeId: number;
  read: boolean;
  readAt: Date;
}

export interface ISlateMessageEmployeesInGroup {
  employeeId: number;
  read: boolean;
  readAt: Date;
  employeeName: string;
}

export class SlateMessageEmployeesInGroup {
  employeeId: number;
  read: boolean;
  readAt: Date;
  employeeName: string;
}
