/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wc-create-osha-form-excel-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./wc-create-osha-form-excel-dialog.component";
import * as i3 from "../../../../../../../common/models/dialog-options";
import * as i4 from "../../../../../../../common/services/modal/modal.service";
var styles_WcCreateOshaFormExcelDialogComponent = [i0.styles];
var RenderType_WcCreateOshaFormExcelDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WcCreateOshaFormExcelDialogComponent, data: {} });
export { RenderType_WcCreateOshaFormExcelDialogComponent as RenderType_WcCreateOshaFormExcelDialogComponent };
export function View_WcCreateOshaFormExcelDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" OSHA Form 300/300A CSV file will be created for the year indicated in your selected Start Date. Click OK to create the file.\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "p", [["class", "flex justify-content__flex-end modal-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "theme-button-apply  margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ok"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], null, null); }
export function View_WcCreateOshaFormExcelDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-wc-create-osha-form-excel-dialog", [], null, null, null, View_WcCreateOshaFormExcelDialogComponent_0, RenderType_WcCreateOshaFormExcelDialogComponent)), i1.ɵdid(1, 49152, null, 0, i2.WcCreateOshaFormExcelDialogComponent, [i3.DialogOptions, i4.ModalService], null, null)], null, null); }
var WcCreateOshaFormExcelDialogComponentNgFactory = i1.ɵccf("slx-wc-create-osha-form-excel-dialog", i2.WcCreateOshaFormExcelDialogComponent, View_WcCreateOshaFormExcelDialogComponent_Host_0, {}, {}, []);
export { WcCreateOshaFormExcelDialogComponentNgFactory as WcCreateOshaFormExcelDialogComponentNgFactory };
