import * as tslib_1 from "tslib";
import { DialogOptions2, DialogModeSize } from './../../../../common/models/dialog-options';
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, Provider } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { appConfig } from '../../../../app.config';
import { DialogOptions, ColumnSettingsDefinitions, ConfirmOptions, ConfirmDialog2Component } from '../../../../common/index';
import { ModalService, ColumnSettingsStorageService } from '../../../../common/services/index';
import { LookupApiService } from '../../../../organization/services/index';
import { ShiftReplacementRequest, ShiftReplacementSettings, ShiftEligibleEmployee, ShiftReplaceCmd, ShiftReplacementSms } from '../../../models/index';
import { ShiftReplacementApiService, ShiftReplacementManagementService } from '../../../services/index';
import { ShiftOverlapHelper } from '../shift-overlap-helper';
import { NotificationsService } from './../../../../../app/core/components';
var ShiftReplacementReplaceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ShiftReplacementReplaceComponent, _super);
    function ShiftReplacementReplaceComponent(options, modalService, lookupApiService, request, columnSettingsStorageService, notificationService, shiftManagementService, shiftReplacementApiService) {
        var _this = _super.call(this) || this;
        _this.shiftReplacementApiService = shiftReplacementApiService;
        _this.modalService = modalService;
        _this.options = options;
        _this.lookupApiService = lookupApiService;
        _this.columnSettingsStorageService = columnSettingsStorageService;
        _this.shiftManagementService = shiftManagementService;
        _this.notificationService = notificationService;
        _this.smsData = new ShiftReplacementSms();
        _this.groupData = request.currentDayDUAData;
        _this.previousDayGroupData = request.previousDayDUAData;
        _this.nextDayGroupData = request.nextDayDUAData;
        _this.settings = new ShiftReplacementSettings();
        _this.settings.createColumns();
        _this.settings.request = request;
        _this.settings.context = ColumnSettingsDefinitions.SHIFT_REPLACEMENT_ADD_EMPLOYEE;
        _this.settings.columns = _this.columnSettingsStorageService.getColumnsState(_this.settings.context.toString(), undefined, _this.settings.columns);
        _this.settings.isMultipleSelectionMode = false;
        return _this;
    }
    ShiftReplacementReplaceComponent.openDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.minHegiht = 550;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: ShiftReplacementRequest,
                useValue: request
            }
        ];
        var header = "Replace " + request.replacedEmployeeName + " for " + request.shiftName + " shift on " + moment(request.date).format(appConfig.serverDateFormat);
        var dialog = modalService.globalAnchor
            .openDialog2(ShiftReplacementReplaceComponent, header, dialogOptions, resolvedProviders, function (result, uniqueId) {
            var cmd;
            if (result) {
                cmd = new ShiftReplaceCmd();
                cmd.selectedEmployee = _.head(dialog.selectedEmployees);
                cmd.scheduleAbsence = dialog.scheduleAbsence;
                cmd.dateOn = request.date;
                cmd.replacedEmployeeId = request.replacedEmployeeId;
                cmd.shiftId = request.shiftId;
                cmd.absenceCode = dialog.scheduleAbsence ? dialog.scheduleAbsence.code : null;
                cmd.comment = undefined;
            }
            callback(result, cmd);
        });
        return dialog;
    };
    ShiftReplacementReplaceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.lookupApiService.getScheduleAbsences(this.settings.request.orgLevelId)
            .then(function (scheduleAbsenceLookup) {
            _this.scheduleAbsenceLookup = scheduleAbsenceLookup;
        });
        this.selectedEmployeesSubscription = this.shiftManagementService.subscribeToSelectedEmployees(function (selected) {
            _this.selectedEmployees = selected;
        });
        this.smsModeChangeSubscription = this.shiftManagementService.subscribeToChangeSmsMode(function (sendSmsOn) {
            _this.sendSmsModeOn = sendSmsOn;
        });
        this.availabilityToSendSmsSubscription = this.shiftManagementService
            .subscribeToChangeAvailabilityToSendSms(function (smsData) {
            _this.smsData = smsData;
        });
    };
    ShiftReplacementReplaceComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(ShiftReplacementReplaceComponent.prototype, "isDiabledReplaceButton", {
        get: function () {
            return _.size(this.selectedEmployees) === 0 || !this.scheduleAbsence;
        },
        enumerable: true,
        configurable: true
    });
    ShiftReplacementReplaceComponent.prototype.onChangedAbsences = function (scheduleAbsence) {
        this.scheduleAbsence = scheduleAbsence;
    };
    ShiftReplacementReplaceComponent.prototype.onCancelSend = function () {
        this.shiftManagementService.changeSmsMode(false);
    };
    ShiftReplacementReplaceComponent.prototype.onSend = function () {
        this.shiftManagementService.sendSms(this.smsData);
    };
    ShiftReplacementReplaceComponent.prototype.onCancelReplace = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ShiftReplacementReplaceComponent.prototype.onReplace = function () {
        var _this = this;
        var isApprovedLength = this.shiftManagementService.replaceApprovedResult.filter(function (x) { return x == true; });
        if (isApprovedLength && isApprovedLength.length > 0) {
            this.shiftManagementService.replaceEmployee.replacedEmployeeId = this.shiftManagementService.replaceEmployeeId;
            this.dialogResult = false;
            var message = "This will modify a schedule in an approved pay period and impact the PBJ     Calculation for the employee, are you sure you want to continue?";
            var popupOptions = new ConfirmOptions();
            popupOptions.showCancel = true;
            popupOptions.showOK = true;
            popupOptions.buttonOKtext = 'Ok';
            popupOptions.buttonCanceltext = 'Cancel';
            ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
                if (result) {
                    _this.shiftManagementService.replaceApprovedResult = [];
                    _this.checkOverlapForReplace();
                }
            });
        }
        else {
            this.checkOverlapForReplace();
        }
    };
    ShiftReplacementReplaceComponent.prototype.checkOverlapForReplace = function () {
        if (this.selectedEmployees) {
            if (this.isCurrentDayShiftOverlapped()
                || this.isPreviousDayShiftOverlapped()
                || this.isNextDayShiftOverlapped()
                || this.isCurrentDayReplacedShiftOverlapped()
                || this.isPreviousDayReplacedShiftOverlapped()
                || this.isNextDayReplacedShiftOverlapped()
                || this.isPrimarySecondaryShiftsOverlap()) {
                this.notificationService.warn('Overlap', 'This employee has an overlap shift');
                this.dialogResult = false;
                this.modalService.closeWindow(this.options.windowUniqueId);
                return;
            }
            else {
                this.dialogResult = true;
                this.modalService.closeWindow(this.options.windowUniqueId);
            }
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementReplaceComponent.prototype, "smsModeChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementReplaceComponent.prototype, "selectedEmployeesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementReplaceComponent.prototype, "availabilityToSendSmsSubscription", void 0);
    return ShiftReplacementReplaceComponent;
}(ShiftOverlapHelper));
export { ShiftReplacementReplaceComponent };
