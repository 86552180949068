import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { IControlState, StateResetTypes } from '../../../core/models/index';
import { ComponentStateStorageService, StateManagementService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';
var PropertyInitStateDirective = /** @class */ (function () {
    function PropertyInitStateDirective(storageService, stateManagement) {
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.propertySaveChange = new EventEmitter();
        this.m_resetType = StateResetTypes.OrgLevelChange | StateResetTypes.SessionEnd;
    }
    Object.defineProperty(PropertyInitStateDirective.prototype, "propertySave", {
        set: function (value) {
            this.valueChanged(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyInitStateDirective.prototype, "resetType", {
        set: function (type) {
            if (!type) {
                return;
            }
            this.m_resetType = type;
        },
        enumerable: true,
        configurable: true
    });
    PropertyInitStateDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.controlId) {
            throw Error('State directive can be used only with controlId');
        }
        this.initSubscription = this.stateManagement.onInit$.subscribe(function () {
            scheduleMicrotask(function () { _this.loadState(); });
        });
    };
    PropertyInitStateDirective.prototype.valueChanged = function (val) {
        if (!this.state) {
            return;
        }
        this.storageService.mapValueToState(val, this.state);
        this.storageService.setControlState(this.stateManagement.componentKey, this.controlId, this.state, this.m_resetType);
    };
    PropertyInitStateDirective.prototype.loadState = function () {
        this.state = this.storageService.getControlState(this.stateManagement.componentKey, this.controlId);
        if (this.state.value !== undefined) {
            var value = this.storageService.mapValueFromState(this.state);
            this.propertySaveChange.emit(value);
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PropertyInitStateDirective.prototype, "initSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PropertyInitStateDirective.prototype, "valueChangesSubscription", void 0);
    return PropertyInitStateDirective;
}());
export { PropertyInitStateDirective };
