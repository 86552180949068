import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SummarySectionBase } from '../summary-section-base/summary-section.base';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { HoursSummaryContainer, TimecardSummaryPaidHours } from '../../../models/index';
import { AggregateDescriptor, aggregateBy } from '@progress/kendo-data-query';

@Component({
    moduleId: module.id,
    selector: 'slx-timecard-summary-hours',
    templateUrl: 'hours-summary.component.html',
    styleUrls: ['hours-summary.component.scss']
})
export class HoursSummaryComponent extends SummarySectionBase implements OnInit, OnDestroy {

    @ViewChild('kendoGrid', {static: true})
    public grid: GridComponent;

    public aggregates: AggregateDescriptor[];
    public totals: StringMap<any>;
    public dollarFormat = '$0.00';
    public numberFormat = '0.00';

    constructor(management: TimecardsSummaryManagementService) {
        super(management);
        this.aggregates = [
            ,{ field: 'currentCycleHours', aggregate: 'sum' }
            ,{ field: 'currentCycleHourlyPay', aggregate: 'sum' }
            ,{ field: 'currentCycleOtherPay', aggregate: 'sum' }
            ,{ field: 'currentCycleAmount', aggregate: 'sum' }
            ,{ field: 'previousCycleHours', aggregate: 'sum' }
            ,{ field: 'previousCycleHourlyPay', aggregate: 'sum' }
            ,{ field: 'previousCycleOtherPay', aggregate: 'sum' }
            ,{ field: 'previousCycleAmount', aggregate: 'sum' }
            ,{ field: 'differenceCycleHours', aggregate: 'sum' }
            ,{ field: 'differenceCycleHourlyPay', aggregate: 'sum' }
            ,{ field: 'differenceCycleOtherPay', aggregate: 'sum' }
            ,{ field: 'differenceCycleAmount', aggregate: 'sum' }
        ];
    }

    public ngOnInit(): void {
        this.setupManagementService();
    }

    public ngOnDestroy(): void {
        this.destroy();
    }

    public checkDifference(item: TimecardSummaryPaidHours, diff: number, field: 'hours' | 'amount' | 'otherpayamounts' | 'hourlyamounts'): boolean {
        let percent: number;
        if (field === 'hours') {
            if (item.differenceCycleHours === 0) return false;
            percent = Math.abs(((item.currentCycleHours - item.previousCycleHours) / item.currentCycleHours) * 100);
        } else if (field === 'amount') {
            if (item.differenceCycleAmount === 0) return false;
            percent = Math.abs(((item.currentCycleAmount - item.previousCycleAmount) / item.currentCycleAmount) * 100);
        } else if (field === 'hourlyamounts') {
            if (item.differenceCycleHourlyPay === 0) return false;
            percent = Math.abs(((item.currentCycleHourlyPay - item.previousCycleHourlyPay) / item.currentCycleHourlyPay) * 100);
        } else if (field === 'otherpayamounts') {
            if (item.differenceCycleOtherPay === 0) return false;
            percent = Math.abs(((item.currentCycleOtherPay - item.previousCycleOtherPay) / item.currentCycleOtherPay) * 100);
        } else {
            return false;
        }

        if (percent === 0) return false;
        return percent > diff;
    }

    protected onDataLoaded(): void {
        _.noop();
    }

    protected refreshGrid (): void {
        super.refreshGrid();
        this.totals = aggregateBy(this.container.records, this.aggregates);
    }

    public onExcelExport(e: any): void {
      const rows = e.workbook.sheets[0].rows;
      const cells = _.filter(rows, { type: 'footer' });
      _.forEach(_.head(cells).cells, (cell) => {
        const value = parseFloat(cell.value);
        if (_.isFinite(value)) {
          cell.value = value;
        }
      });
    }
}
