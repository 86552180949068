/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-uploads.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./uploaded-file-status/uploaded-file-status.component.ngfactory";
import * as i3 from "./uploaded-file-status/uploaded-file-status.component";
import * as i4 from "../../services/file-uploads/file-uploads-management.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../node_modules/@progress/kendo-angular-layout/dist/es2015/index.ngfactory";
import * as i7 from "@progress/kendo-angular-l10n";
import * as i8 from "@progress/kendo-angular-layout";
import * as i9 from "./file-uploads.component";
var styles_FileUploadsComponent = [i0.styles];
var RenderType_FileUploadsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadsComponent, data: {} });
export { RenderType_FileUploadsComponent as RenderType_FileUploadsComponent };
function View_FileUploadsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-uploaded-file-status", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UploadedFileStatusComponent_0, i2.RenderType_UploadedFileStatusComponent)), i1.ɵdid(2, 49152, null, 0, i3.UploadedFileStatusComponent, [i4.FileUploadsManagementService, i5.Router], null, null)], null, null); }
export function View_FileUploadsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "kendo-tabstrip", [["class", "slx-tab-strip-wrapper"]], [[2, "k-widget", null], [2, "k-tabstrip", null], [2, "k-floatwrap", null], [2, "k-header", null], [2, "k-tabstrip-top", null], [2, "k-tabstrip-right", null], [2, "k-tabstrip-bottom", null], [2, "k-tabstrip-left", null], [1, "dir", 0]], [[null, "tabSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabSelect" === en)) {
        var pd_0 = (_co.onTabSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_TabStripComponent_0, i6.RenderType_TabStripComponent)), i1.ɵprd(256, null, i7.L10N_PREFIX, "kendo.tabstrip", []), i1.ɵprd(131584, null, i7.LocalizationService, i7.LocalizationService, [i7.L10N_PREFIX, [2, i7.MessageService], [2, i7.RTL]]), i1.ɵdid(4, 245760, null, 1, i8.TabStripComponent, [i7.LocalizationService, i1.Renderer2, i1.ElementRef], null, { tabSelect: "tabSelect" }), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 5, "kendo-tabstrip-tab", [["title", "Uploaded File Status"]], null, null, null, i6.View_TabStripTabComponent_0, i6.RenderType_TabStripTabComponent)), i1.ɵdid(7, 1622016, [[1, 4]], 2, i8.TabStripTabComponent, [], { title: [0, "title"], selected: [1, "selected"] }, null), i1.ɵqud(603979776, 2, { _tabContent: 1 }), i1.ɵqud(335544320, 3, { tabTitle: 0 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_FileUploadsComponent_1)), i1.ɵdid(11, 16384, [[2, 4]], 0, i8.TabContentDirective, [i1.TemplateRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); var currVal_9 = "Uploaded File Status"; var currVal_10 = true; _ck(_v, 7, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).hostClasses; var currVal_1 = i1.ɵnov(_v, 4).hostClasses; var currVal_2 = i1.ɵnov(_v, 4).hostClasses; var currVal_3 = i1.ɵnov(_v, 4).hostClasses; var currVal_4 = i1.ɵnov(_v, 4).tabsAtTop; var currVal_5 = i1.ɵnov(_v, 4).tabsAtRight; var currVal_6 = i1.ɵnov(_v, 4).tabsAtBottom; var currVal_7 = i1.ɵnov(_v, 4).tabsAtLeft; var currVal_8 = i1.ɵnov(_v, 4).dir; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_FileUploadsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-file-uploads", [], null, null, null, View_FileUploadsComponent_0, RenderType_FileUploadsComponent)), i1.ɵdid(1, 114688, null, 0, i9.FileUploadsComponent, [i4.FileUploadsManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileUploadsComponentNgFactory = i1.ɵccf("slx-file-uploads", i9.FileUploadsComponent, View_FileUploadsComponent_Host_0, {}, {}, []);
export { FileUploadsComponentNgFactory as FileUploadsComponentNgFactory };
