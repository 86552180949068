export interface IopenPositionControlConfig {
  configurationId: number;
  orgLevelId: number;
  positionId: number;
  ftMaxWeeklyHours: number;
  ftMaxHoursPerCalenderDay: number;
  ftMaxNoOfDaysPerCalenderWeek: number;
  ptMaxWeeklyHours: number;
  ptMaxHoursPerCalenderDay: number;
  ptMaxNoOfDaysPerCalenderWeek: number;
  ftMaxWeeklyHoursDefault: number;
  ftMaxHoursPerCalenderDayDefault: number;
  ftMaxNoOfDaysPerCalenderWeekDefault: number;
  ptMaxWeeklyHoursDefault: number;
  ptMaxHoursPerCalenderDayDefault: number;
  ptMaxNoOfDaysPerCalenderWeekDefault: number;
  ftMinWeeklyHours: number;
  ptMinWeeklyHours: number;
  FTMinWeeklyHoursDefault: number;
  PTMinWeeklyHoursDefault: number;
}

export class openPositionControlConfig {
  public configurationId: number;
  public orgLevelId: number;
  public positionId: number;
  public ftMaxWeeklyHours: number;
  public ftMaxHoursPerCalenderDay: number;
  public ftMaxNoOfDaysPerCalenderWeek: number;
  public ptMaxWeeklyHours: number;
  public ptMaxHoursPerCalenderDay: number;
  public ptMaxNoOfDaysPerCalenderWeek: number;
  public ftMaxWeeklyHoursDefault: number;
  public ftMaxHoursPerCalenderDayDefault: number;
  public ftMaxNoOfDaysPerCalenderWeekDefault: number;
  public ptMaxWeeklyHoursDefault: number;
  public ptMaxHoursPerCalenderDayDefault: number;
  public ptMaxNoOfDaysPerCalenderWeekDefault: number;
  public ftMinWeeklyHours: number;
  public ptMinWeeklyHours: number;
  public ftMinWeeklyHoursDefault: number;
  public ptMinWeeklyHoursDefault: number;
}
