export interface IValidateAgencyEmployeeReq {
  employeeId: number;
  shiftId: number;
  orgLevelId: number;
  date: string;
}

export class ValidateAgencyEmployeeReq {
  public employeeId: number;
  public shiftId: number;
  public orgLevelId: number;
  public date: Date;
}
