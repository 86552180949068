import { Component, OnInit, Input, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { CustomListActorBase } from '../../../components/editableList/custom-list-actor.base';
import { EditableListActionKind, Unit } from '../../../models/index';
@Component({
  moduleId: module.id,
  selector: 'slx-location-unit-renderer',
  templateUrl: 'location-unit-renderer.component.html',
  styleUrls: ['location-unit-renderer.component.scss'],

  providers: [{
    provide: CustomListActorBase,
    useExisting: forwardRef(() => LocationUnitRendererComponent)
  }]
})

export class LocationUnitRendererComponent extends CustomListActorBase {

  @Input()
  public set context(value: any) {
    this.item = value.item;
    this.rowIndex = value.rowIndex;
  }

  @Input()
  public editable: boolean = true;

  @Input()
  public item: Unit;

  @Input()
  public rowIndex: number;

  public onEditClick(): void {
    this.actionEmitter.emit(EditableListActionKind.START_EDIT);
  }

  public onItemSelect(): void {
    this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
  }
}
