import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as FullStory from '@fullstory/browser';
import { OnInit, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { environment as envConfig } from '../../../../environments/environment';
import { AuthenticationService } from '../../../authentication/services/index';
import { DeviceDetectorService } from '../../services/index';
var InitFullstoryComponent = /** @class */ (function () {
    function InitFullstoryComponent(authenticationService, deviceDetectorService) {
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this.isIdentified = false;
        var devMode = true;
        var envStr = _.toLower(envConfig.ENV);
        if (envStr === 'release') {
            devMode = false;
        }
        FullStory.init({
            orgId: '10A1MQ',
            devMode: devMode,
        });
    }
    InitFullstoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginSubscription = this.authenticationService.login$.subscribe(function (event) {
            if (!event.isRenewAuthentication) {
                _this.onLogin(event.alias, event.username, event.email, event.roles);
            }
        });
        this.logoutSubscription = this.authenticationService.onLogout.subscribe(function () {
            _this.onLogout();
        });
    };
    InitFullstoryComponent.prototype.ngAfterViewInit = function () {
        if (!this.isIdentified) {
            var alias = this.authenticationService.getAlias();
            var user = this.authenticationService.getUser();
            if (this.authenticationService.isAuthenticated()) {
                this.onLogin(alias, user.username, user.email, user.roles);
            }
        }
    };
    InitFullstoryComponent.prototype.onLogin = function (userAlias, username, userEmail, userRoles) {
        this.isIdentified = true;
        var alias = _.toUpper(_.size(userAlias) > 0 ? userAlias : 'default');
        var email = _.size(userEmail) > 0 ? userEmail : null;
        var roles = _.size(userRoles) > 0 ? userRoles.join(',') : '';
        var accountId = _.toUpper(username + "@" + alias);
        var device = this.deviceDetectorService.isDesktop ? 'desktop' : 'mobile';
        var userVars = {
            displayName: accountId,
            alias: alias,
            roles: roles,
            device: device,
        };
        if (email) {
            userVars.email = email;
        }
        FullStory.identify(accountId, userVars);
    };
    InitFullstoryComponent.prototype.onLogout = function () {
        this.isIdentified = false;
        FullStory.anonymize();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], InitFullstoryComponent.prototype, "loginSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], InitFullstoryComponent.prototype, "logoutSubscription", void 0);
    return InitFullstoryComponent;
}());
export { InitFullstoryComponent };
