<div class="entry-def-cell">
  <div>
    <span class="emp-shift-attribute-title theme-sm-text">{{detailsWrapper?.date | amDateFormat: appConfig.monthDayDateFormat}}</span>
  </div>
  <div class="content-host">
    <span class="fa-stack fa-2x">
      <i class="far fa-stack-2x" aria-hidden="true" [ngClass]="{
          'fa-circle': messageCount > 0 || partnerShiftCount > 0,
          'fa-square': openShiftCount > 0 ,
          'fa-check-circle': openShiftCount === 0
        }"></i>
      <span *ngIf="openShiftCount > 0 " class="fa-stack-1x theme-lg-text">{{openShiftCount}}</span>
      <span *ngIf="messageCount > 0" class="badge-2x theme-sm-text">{{messageCount}}</span>  
      <span *ngIf="partnerShiftCount > 0" class="badge-2x theme-sm-text partner-request">{{partnerShiftCount}}</span>
    </span>
  </div>
</div>
