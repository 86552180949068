import * as _ from 'lodash';
import { EmployeeAccrualBalance, IEmployeeAccrualBalance } from './employee-accrual-balance';

export interface IEndingBalanceDialogOptions {
  accruals: IEmployeeAccrualBalance[]
}
export class EndingBalanceDialogOptions {
 
  accruals: EmployeeAccrualBalance[]

  constructor() {
    this.accruals = [];
  }
}
