import { appConfig } from './../../app.config';
import { toODataString, State, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { ServerQuery, ServerCompositeFilterDescriptor, ServerFilterDescriptor, isServerCompositeFilterDescriptor } from '../models/index';
import * as moment from 'moment-timezone';

export function convertToKendoFilter(filter: ServerCompositeFilterDescriptor): CompositeFilterDescriptor {
  let kfilter: CompositeFilterDescriptor = { filters: [], logic: filter ? filter.logic : 'and' };
  if (!filter) {
    return kfilter;
  }
  _.forEach(filter.filters, (f: ServerFilterDescriptor | ServerCompositeFilterDescriptor) => {
    if (isServerCompositeFilterDescriptor(f)) {
      let ckf: CompositeFilterDescriptor = convertToKendoFilter(f);
      kfilter.filters.push(ckf);
    } else {
      kfilter.filters.push({ field: f.field, operator: f.operator, value: f.value });
    }
  });
  return kfilter;
}

export function slxToODataString(query: ServerQuery): string {
  let f: CompositeFilterDescriptor = convertToKendoFilter(query.filter);

  // #issueWithOdataFilterStrictEqualityByDate
  fixDates(f);

  let state: State = {
    filter: f,
    skip: query.skip,
    take: query.take,
    sort: query.sort
  };
  return toODataString(state, { utcDates: true });
}

export function fixDates(filter: CompositeFilterDescriptor): void {
  _.each(filter.filters, (child: FilterDescriptor | CompositeFilterDescriptor) => {
    if (_.has(child, 'field')) {
        let simpleFilter: FilterDescriptor = child as FilterDescriptor;
        if (simpleFilter.value instanceof Date) {
          let filterDate: Date = simpleFilter.value;
          const serverOffset = moment().tz(appConfig.serverTimezone).utcOffset() / 60;
          const localMOffset = moment(filterDate).utcOffset() % 60;
          const localHOffset = (moment(filterDate).utcOffset() - localMOffset) / 60;
          simpleFilter.value = new Date(filterDate.getFullYear(), filterDate.getMonth(),
          filterDate.getDate(), localHOffset - serverOffset + filterDate.getHours(), localMOffset + filterDate.getMinutes(), filterDate.getSeconds());
        }
    } else {
      fixDates(child as CompositeFilterDescriptor);
    }
  });
}
