export interface IAuthenticationConfig {
  session: {
    localStorageKey: string;
  };
  api: {
    header: string;
    aliasHeader: string,
    headerSchema: string;
    authenticate: string;
    renew: string;
    logout: string;
    forgotPassword: string;
    resetPassword: string;
    changePassword: string;
    validatePassword: string;
    passwordSettings: string;
    userMenuPinState: string;
    userMenuPinStateUpdate: string;
    userExtendedSetting:string;
    client: {
      root: string;
      messages: string;
    }
    validateAuthSession: string;
  };
  login: {
    aliasSeparator: string;
    returnUrlQueryParameter: string;
    returnUrlQueryStringParameter: string;
    defaultReturnUrl: string;
    username: string;
    alias: string;
  };
}

export const authenticationConfig: IAuthenticationConfig = {
  session: {
    localStorageKey: 'slx-session'
  },
  api: {
    header: 'Authorization',
    aliasHeader: 'X-Slx-Alias',
    headerSchema: 'Bearer',
    authenticate: 'jwt/auth',
    renew: 'jwt/renew',
    logout: 'logout',
    forgotPassword: 'user/password/askreset',
    resetPassword: 'user/password/reset',
    changePassword: 'user/password/change',
    validatePassword: 'user/password/validate',
    passwordSettings: 'user/password/settings',
    userMenuPinState: 'user/pinMenu',
    userMenuPinStateUpdate: 'user/pinMenu/change',
    userExtendedSetting: 'user/userextendedsettings',
    client: {
      root: 'client',
      messages: 'messages'
    },
    validateAuthSession: 'authentication/validateAuthSession'
  },
  login: {
    aliasSeparator: '@',
    returnUrlQueryParameter: 'returnUrl',
    returnUrlQueryStringParameter: 'returnQs',
    defaultReturnUrl: '/',
    username: 'username',
    alias: 'alias'
  }
};
