import { Subscription } from 'rxjs/Subscription';
import { OrgLevel } from './../../../state-model/models/org-level/org-level';
import { Observable, Subject } from 'rxjs';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { LicenseType } from './../../../organization/models/lookup/license-type';
import { Injectable } from '@angular/core';
import { mutableSelect, unsubscribeAll, unsubscribeInService } from '../../../../app/core/decorators';
import * as _ from 'lodash';
import { EmployeePunchRequestApiService } from './employee-punch-request-api.service';
import { EmployeePunchRequest } from '../../models/punches/employee-punch-request';
import { DateRange, IDateRange } from '../../../core/models/index';
import { KendoGridStateHelper } from './../../../../app/common';
import * as moment from 'moment';
import { LookupApiService, PayCycle } from './../../../../app/organization';
import { Assert } from './../../../../app/framework';
import { process } from '@progress/kendo-data-query';

@Injectable()
export class EmployeePunchRequestManagementService {
  public isLoading: boolean;
  public list: EmployeePunchRequest[];
  public gridData: GridDataResult;
  public orgLevelId: number;
  public startDate: Date;
  public endDate: Date;
  public empPunchRequest: EmployeePunchRequest;
  private dateRange: DateRange;
  private dateRange$ = new Subject<DateRange>();
  private loading$ = new Subject<boolean>();
  private recordsLoaded$ = new Subject<EmployeePunchRequest[]>();
  public gridState: KendoGridStateHelper<EmployeePunchRequest>;
  public skip: number = 0;
  public pageSize: number = 15;



  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  public sort: SortDescriptor[] = [];

  constructor(
    private apiService: EmployeePunchRequestApiService,
  ) {
    this.gridState = new KendoGridStateHelper<EmployeePunchRequest>();
    this.gridState.state.skip = 0;
    this.skip = 0;
    this.gridState.state.take = this.pageSize;

  }

  public init(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if(this.orgLevelId != orgLevel.id){
        this.orgLevelId = orgLevel.id;
        if(!this.dateRange){
          this.dateRange = this.getDefaultDateRange();
        }
        else{
          this.loadEmployeePunchRequestData();
        }
      };
    });
  }

  public destroy(): void {
    this.dateRange$.complete();
    this.loading$.complete();
    this.recordsLoaded$.complete();
  }

  public getDefaultDateRange(): IDateRange {
    const d = new Date();
    return new DateRange(new Date(d.getFullYear() - 1, d.getMonth(), d.getDate()), d);
  }

  public changeDateRange(r: IDateRange): void {
    this.dateRange = r;
    this.dateRange$.next(r);
    this.loadEmployeePunchRequestData();
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (b: EmployeePunchRequest[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recordsLoaded$.subscribe(callback);
  }


  public loadEmployeePunchRequestData(): void {
    this.isLoading = true;
    this.loading$.next(true);
    this.apiService.getEmployeePunchRequest(this.orgLevelId, this.dateRange.startDate, this.dateRange.endDate).then((value: any) => {
      this.recordsLoaded$.next(value);
      this.list = value;
      this.refreshGrid();
      this.isLoading = false;
      this.loading$.next(false);
    }).finally(() => {
      this.isLoading = false;
      this.loading$.next(false);
    });
  }

  public setSort(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public pageChange(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  public filterChange(event: any): void {
    this.gridState.state.filter = event;
    this.refreshGrid();
  }

  public refreshGrid(): void {
    if (!this.list) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.list, this.gridState.state);
  }
}
