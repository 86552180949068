import * as moment from 'moment';
export var TimeCardDisplayOptions;
(function (TimeCardDisplayOptions) {
    TimeCardDisplayOptions["showIntervalsInDecimal"] = "number";
    TimeCardDisplayOptions["showIntervalsInTime"] = "hmstring";
})(TimeCardDisplayOptions || (TimeCardDisplayOptions = {}));
var TimeCardModel = /** @class */ (function () {
    function TimeCardModel() {
    }
    Object.defineProperty(TimeCardModel.prototype, "canEditTimecard", {
        get: function () {
            return (this.canEdit &&
                !this.isLoadedApproved &&
                !this.afterTermination &&
                !this.beforeHire &&
                !this.isOutOfPaycycle &&
                !this.isPayrollCycleSubmitted &&
                !this.locked &&
                !this.hasLicenseRestriction);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeCardModel.prototype, "locked", {
        get: function () {
            return this.isLocked || this.isPayrollCycleLocked || this.isOrganizationPayrollLocked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeCardModel.prototype, "afterTermination", {
        get: function () {
            return this.terminationDate && moment(this.date).isAfter(this.terminationDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeCardModel.prototype, "afterTransfer", {
        get: function () {
            return this.terminationDate && moment(this.date).isAfter(this.terminationDate) && this.transferDepartmentId > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeCardModel.prototype, "beforeHire", {
        get: function () {
            var maxValidationDate = this.employee.dateActivation ? this.employee.dateActivation : this.employee.dateHired;
            return maxValidationDate && moment(this.date).isBefore(maxValidationDate);
        },
        enumerable: true,
        configurable: true
    });
    return TimeCardModel;
}());
export { TimeCardModel };
