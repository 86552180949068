import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimeclockAssignmentManagementService } from '../../../services/index';
var TimeclockAssignmentGridComponent = /** @class */ (function () {
    function TimeclockAssignmentGridComponent(timeclockAssignmentManagementService) {
        this.timeclockAssignmentManagementService = timeclockAssignmentManagementService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'employee.name', dir: 'asc' }];
    }
    TimeclockAssignmentGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.stateChangedSubscription = this.timeclockAssignmentManagementService.onStateChanged$.subscribe(function (state) {
            _this.records = state.records;
            _this.totalTimeclocks = state.timeclocks ? state.timeclocks.length : 0;
            _this.showAssigned = state.showAssigned;
            _this.showUnassigned = state.showUnassigned;
            _this.resetState(state);
            _this.refreshGrid();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    TimeclockAssignmentGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeclockAssignmentGridComponent.prototype.onToggleAllSelected = function () {
        var _this = this;
        var records = process(this.records, { filter: this.gridState.state.filter }).data;
        _.forEach(records, function (record) {
            record.isSelected = _this.isAllSelected;
        });
        this.selectionChange();
    };
    TimeclockAssignmentGridComponent.prototype.selectionChange = function () {
        var selectedRecords = [];
        selectedRecords = _.filter(this.records, function (record) {
            return record.isSelected === true;
        });
        this.timeclockAssignmentManagementService.onRecordsSelected(selectedRecords);
    };
    TimeclockAssignmentGridComponent.prototype.resetState = function (state) {
        var _this = this;
        if (_.size(this.records) > 0) {
            this.isAllSelected = false;
            _.forEach(this.records, function (record, index) {
                record.isSelected = false;
                if (state.resetGrid) {
                    _this.grid.collapseRow(index);
                }
            });
        }
    };
    TimeclockAssignmentGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentGridComponent.prototype, "stateChangedSubscription", void 0);
    return TimeclockAssignmentGridComponent;
}());
export { TimeclockAssignmentGridComponent };
