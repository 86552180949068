import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { unsubscribe } from "./../../../../app/core/decorators";

@Injectable()
export class EmployeeSectionTabNavigationService {

    public empSectionTab$ = new Subject<string>();
    public empSectionTab: string = null;

    @unsubscribe()
    private empSectionTabSubscription: Subscription;

    constructor() {
        this.empSectionTabSubscription = this.empSectionTab$.subscribe((tabName: string) => {
            this.empSectionTab = tabName;
        });
    }


    public activeContainerScroll(tabName: string) {
        this.empSectionTab$.next(tabName);
    }

}