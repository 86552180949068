import { User } from './index';
import { BaseSession } from '../../authentication/models/index';


export class Session extends BaseSession {
  public alias: string;
  public user: User;
  public clientTimeShift: number;
  public isIPAllowed :boolean;
  
  constructor(token: string) {
    super(token);
  }
}
