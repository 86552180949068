import { PostScheduleSettings } from './../../../models/open-shift-management/post-schedule-settings';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LookupService, LookupType } from '../../../../organization';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import { Subscription } from 'rxjs/Subscription';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../app-settings/services';
@Component({
    moduleId: module.id,
    selector: 'slx-post-schedule-settings',
    templateUrl: 'post-schedule-settings.component.html',
    styleUrls: ['post-schedule-settings.component.scss']
})
export class PostScheduleSettingsComponent implements OnInit {
    @Input()
    public settings: PostScheduleSettings;
    @Output() isChecked = new EventEmitter();
    @unsubscribe()
    private settingsSubscription: Subscription;
    public isIncreaseFrequencyofShiftsPostedNotifications: boolean;
    public isAbilityForAdminToTurnOnAndOffAutoShiftPickUp: boolean;

    public notifyType: any = []
    notifyCount: any = 0;
    checkNotify = false;
    notifyData : any;
    constructor(private lookupService: LookupService,
        private openShiftManagementManagementService: OpenShiftManagementManagementService,
       private appSettingManageService : AppSettingsManageService) {
    }

    ngOnInit(): void {
        this.getRestrictedNotify();
        this.getSettings();
        this.settingsSubscription = this.openShiftManagementManagementService.onPostSettingsLoaded$.subscribe((value: PostScheduleSettings) => {
            this.settings = value;
                this.notifyData = this.notifyType.find(x=> x.notifyId == this.settings.notifyDaysAndWeekly)
          });
    }

    getRestrictedNotify() {
        this.lookupService.getLookup({ lookupType: LookupType.getRestrictedNotify }).then(x => {
            this.notifyType = x.items;
        });
    }

    onNotifyChange() {
        this.settings.notifyDaysAndWeekly = this.notifyData.notifyId; 
        if(this.settings.notifyDaysAndWeekly === 0){
            this.settings.reminderCount = null;
        }
    }

    notifyCheckChange(){
        if(!this.settings.notifyWeekly){
            this.notifyData = { notifyId: 0, notifyType: '' };
            this.settings.notifyDaysAndWeekly = 0;
            this.settings.reminderCount = null;
        }
    }
    validateNumber(event){
        if(event.key=='-' || (event.target.value == '' && event.key == 0 && event.target.value == 0)) event.preventDefault();
        if (event.target.value == 0) event.target.value = '';
    }
    public async getSettings(): Promise<void> {
        let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
        this.isIncreaseFrequencyofShiftsPostedNotifications = appServerConfig.IncreaseFrequencyofShiftsPostedNotifications;
       this.openShiftManagementManagementService.isIncreaseFrequencyofShiftsPostedNotifications = this.isIncreaseFrequencyofShiftsPostedNotifications;
        this.isAbilityForAdminToTurnOnAndOffAutoShiftPickUp = appServerConfig.AbilityForAdminToTurnOnAndOffAutoShiftPickUp;

    }
}
