import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../app.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { KendoGridStateHelper, ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { EmployeeSubsectionDependents } from '../../../models/index';
import { EmployeeSectionsBenefitsManagementService } from '../../../services/index';
import { BenefitEmployeeDependent, BenefitEmpDependentEnrollment, BenefitDependentDroppedEnrollment } from '../../../../../app-modules/benefits/models/index';
var EmployeeSectionsDependentsEnrollmentGridComponent = /** @class */ (function () {
    function EmployeeSectionsDependentsEnrollmentGridComponent(modalService, manService, changeDetector) {
        this.modalService = modalService;
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.pageSize = 5;
        this.enrollmentRecords = [];
        this.globalEditMode = false;
        this.pattern = /[^\w\s]|[_]/gi;
        this.subscriptions = {};
        this.dependentSection = new EmployeeSubsectionDependents();
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'benefitName', dir: 'asc' }, { field: 'startDate', dir: 'asc' }];
    }
    Object.defineProperty(EmployeeSectionsDependentsEnrollmentGridComponent.prototype, "dependent", {
        set: function (v) {
            if (_.isObjectLike(v)) {
                this.selectedDependent = v;
                this.manService.loadDependentEnrollments(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsEnrollmentGridComponent.prototype, "isEditMode", {
        get: function () {
            return _.isObjectLike(this.editedEnrollment);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsEnrollmentGridComponent.prototype, "canEdit", {
        get: function () {
            return this.dependentSection.canEditDependents && !this.isEditMode && !this.globalEditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsEnrollmentGridComponent.prototype, "canDelete", {
        get: function () {
            return this.canDrop && !this.isEditMode && !this.globalEditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsEnrollmentGridComponent.prototype, "canDrop", {
        get: function () {
            return this.dependentSection.canDropCoverageDependents;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsDependentsEnrollmentGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.section = this.manService
            .subscribeToDependentsSubsection(function (sec) {
            _this.dependentSection = sec;
        });
        this.subscriptions.enrollments = this.manService
            .subscribeToChangedEnrollments(function (dep) {
            if (dep.dependentId === _this.selectedDependent.id) {
                _this.enrollmentRecords = dep.enrollments;
                _this.updateEnrollment();
            }
        });
        this.subscriptions.changeDep = this.manService
            .subscribeToChangedEnrollment(function (_a) {
            var enrollment = _a.enrollment, isCreated = _a.isCreated, isEdited = _a.isEdited, isDeleted = _a.isDeleted;
            if (enrollment.dependentId === _this.selectedDependent.id) {
                var index = _.findIndex(_this.enrollmentRecords, function (d) { return d.empEnrollmentId === enrollment.empEnrollmentId; });
                if (isCreated) {
                    _this.enrollmentRecords.push(enrollment);
                }
                else if (isEdited && index !== -1) {
                    _this.enrollmentRecords.splice(index, 1, enrollment);
                }
                else if (isDeleted && index !== -1) {
                    _this.enrollmentRecords.splice(index, 1);
                    _this.manService.changeEnrollmentsQuantity(enrollment.dependentId, _.size(_this.enrollmentRecords));
                }
                _this.editedEnrollment = null;
                _this.updateEnrollment();
            }
        });
        this.subscriptions.grid = this.gridState.onRefreshGrid
            .subscribe(function () {
            _this.updateEnrollment();
        });
        this.subscriptions.gridSave = this.gridState.onSave$
            .subscribe(function (event) {
            _this.gridState.closeEditor(_this.gridEnrollment);
            if (event.isNew) {
                _this.manService.addEnrollment(event.dataItem);
                _this.manService.toggleEditMode(false);
                return;
            }
            _this.manService.editEnrollment(event.dataItem);
            _this.manService.toggleEditMode(false);
        });
        this.subscriptions.gridRemove = this.gridState.onRemove$
            .subscribe(function (event) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            options.buttonOKtext = 'Yes';
            ConfirmDialog2Component.openDialog('Delete Benefit', 'Are you sure you want to delete the selected benefit? This will terminate coverage for the dependent.', _this.modalService, function (isDelete) {
                if (isDelete) {
                    _this.manService.deleteEnrollment(event.dataItem);
                    _this.editedEnrollment = null;
                }
            }, options);
        });
        this.subscriptions.gridEdit = this.gridState.onEdit$
            .subscribe(function (dependent) {
            _this.editedEnrollment = dependent;
            _this.manService.toggleEditMode(true);
            Observable.of(true)
                .delay(50)
                .subscribe(function () { return _this.updateEnrollment(); });
        });
        this.subscriptions.gridCancel = this.gridState.onCancel$
            .subscribe(function () {
            _this.editedEnrollment = null;
            _this.manService.toggleEditMode(false);
            _this.updateEnrollment();
        });
        this.subscriptions.globalEditing = this.manService
            .subscribeToEditMode(function (isEdit) {
            _this.globalEditMode = isEdit;
            _this.updateEnrollment();
        });
        this.subscriptions.reset = this.manService
            .subscribeToReset(function () {
            _this.resetEditMode();
            _this.updateEnrollment();
        });
    };
    EmployeeSectionsDependentsEnrollmentGridComponent.prototype.ngOnDestroy = function () {
        this.enrollmentRecords = [];
    };
    EmployeeSectionsDependentsEnrollmentGridComponent.prototype.isEditingRow = function (dep) {
        return _.isObjectLike(this.editedEnrollment) && _.isObjectLike(dep) && this.editedEnrollment.id === dep.id;
    };
    EmployeeSectionsDependentsEnrollmentGridComponent.prototype.onDropDependentEnrollment = function (dropEnrollment, item) {
        var _this = this;
        dropEnrollment.empDependentEnrollmentId = item.id;
        this.manService.dropDependentEnrollment(dropEnrollment)
            .then(function (rec) { return _this.updateRecord(rec); });
    };
    EmployeeSectionsDependentsEnrollmentGridComponent.prototype.updateRecord = function (record) {
        var index = _.findIndex(this.enrollmentRecords, { id: record.id });
        if (index !== -1) {
            this.enrollmentRecords.splice(index, 1, record);
            this.updateEnrollment();
        }
    };
    EmployeeSectionsDependentsEnrollmentGridComponent.prototype.updateEnrollment = function () {
        this.refreshGrid();
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsDependentsEnrollmentGridComponent.prototype.resetEditMode = function () {
        this.gridState.closeEditor(this.gridEnrollment);
        this.editedEnrollment = null;
    };
    EmployeeSectionsDependentsEnrollmentGridComponent.prototype.refreshGrid = function () {
        if (!this.enrollmentRecords) {
            return;
        }
        this.gridState.view = process(this.enrollmentRecords, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsDependentsEnrollmentGridComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsDependentsEnrollmentGridComponent;
}());
export { EmployeeSectionsDependentsEnrollmentGridComponent };
