import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { appConfig } from '../../../../app.config';

import { dateTimeUtils } from '../../../../common/utils/index';
import { LookupMapService } from '../../../../organization/services/index';
import { Position, PayUnitDefinition, isNilPayUnit } from '../../../../organization/models/index';
import {
  IEmpPayUnit, EmpPayUnit,
  IEmpTimecardPayUnit, EmpTimecardPayUnit,
  IEmpPayUnitsInfo, EmpPayUnitsInfo,
  IMassAssignPayUnits, MassAssignPayUnits,
  IEmployeeTimecardPayUnitChange, IMassAssignPayUnitsPayload
} from '../../models/index';

@Injectable()
export class PayUnitsMassAssignmentMapService {
  constructor(
    private lookupMapService: LookupMapService
  ) { }

  public mapToEmpPayUnits(dtos: IEmpPayUnit[]): EmpPayUnit[] {
    return _.map(dtos, v => this.mapToEmpPayUnit(v));
  }

  public mapToEmpPayUnit(dto: IEmpPayUnit): EmpPayUnit {
    const data = new EmpPayUnit();
    data.payUnit = this.lookupMapService.mapToPayUnitDefinition(dto.payUnit);
    data.employeeId = dto.employeeId;
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.units = dto.units;
    data.start = dateTimeUtils.convertFromDtoDateTimeString(dto.start);
    data.end = dateTimeUtils.convertFromDtoDateTimeString(dto.end);

    data.initialPayUnitId = data.payUnit ? data.payUnit.id : undefined;
    data.initialPositionId = data.position ? data.position.id : undefined;
    data.initialUnits = data.units;
    return data;
  }

  public mapToEmpTimecardPayUnits(dtos: IEmpTimecardPayUnit[]): EmpTimecardPayUnit[] {
    return _.map(dtos, v => this.mapToEmpTimecardPayUnit(v));
  }

  public mapToEmpTimecardPayUnit(dto: IEmpTimecardPayUnit): EmpTimecardPayUnit {
    const data = new EmpTimecardPayUnit();
    data.date = dateTimeUtils.convertFromDtoString(dto.date);
    data.employeeId = dto.employeeId;
    data.isApproved = dto.isApproved;
    data.isLocked = dto.isLocked;
    data.isNonActive = dto.isNonActive;
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.payUnits = _.orderBy(this.mapToEmpPayUnits(dto.payUnits), (pu) => pu.start);
    data.start = dto.start ? dateTimeUtils.convertFromDtoString(dto.start) : null;
    data.workHours = !_.isNil(dto.workHours) ? dto.workHours / 60 : null;
    while (data.payUnits.length < 3) {
      const pu = new EmpPayUnit();
      pu.employeeId = data.employeeId;
      pu.start = moment(data.date).add(data.payUnits.length, 'm').toDate();
      pu.end = pu.start;
      data.payUnits.push(pu);
    }
    return data;
  }

  public mapToEmpPayUnitsInfos(dtos: IEmpPayUnitsInfo[]): EmpPayUnitsInfo[] {
    return _.map(dtos, v => this.mapToEmpPayUnitsInfo(v));
  }

  public mapToEmpPayUnitsInfo(dto: IEmpPayUnitsInfo): EmpPayUnitsInfo {
    const data = new EmpPayUnitsInfo();
    data.employeeId = dto.employeeId;
    data.departmentId = dto.departmentId;
    data.employeeName = dto.employeeName;
    data.hireDate = dateTimeUtils.convertFromDtoString(dto.hireDate);
    data.terminateDate = dateTimeUtils.convertFromDtoString(dto.terminateDate);
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.positions = this.lookupMapService.mapPositions(dto.positions);
    data.timecardPayUnits = this.mapToEmpTimecardPayUnits(dto.timecardPayUnits);
    data.dayTimecard = _.keyBy(data.timecardPayUnits, (pu) => {
      return moment(pu.date).format(appConfig.linkDateFormat);
    });
    return data;
  }

  public mapToMassAssignPayUnits(dto: IMassAssignPayUnits): MassAssignPayUnits {
    const data = new MassAssignPayUnits();
    data.records = this.mapToEmpPayUnitsInfos(dto.records);
    return data;
  }


  public mapToPayload(data: MassAssignPayUnits): IMassAssignPayUnitsPayload {
    const records = _.reduce(data.records, (arr, r, ) => arr.concat(...this.mapToChange(r)), [])
    const dto: IMassAssignPayUnitsPayload = {
      records: records
    };
    return dto;
  }

  public mapToChange(data: EmpPayUnitsInfo): IEmployeeTimecardPayUnitChange[] {
    const res: IEmployeeTimecardPayUnitChange[] = [];
    _.forEach(data.timecardPayUnits, (pu) => {
      const change: IEmployeeTimecardPayUnitChange = {
        date: dateTimeUtils.convertToDtoString(pu.date),
        employeeId: data.employeeId,
        deleteRecords: [],
        addRecords: [],
        updateRecords: []
      };
      let hasChanges = false;
      _.forEach(pu.payUnits, (u) => {
        hasChanges = this.mapToEmpPayUnitDtoAndToChange(change, u) || hasChanges;
      });
      if (hasChanges) {
        res.push(change);
      }
    });
    return res;
  }

  public mapToEmpPayUnitDtoAndToChange(change: IEmployeeTimecardPayUnitChange, u: EmpPayUnit): boolean {
    if (u.initialPayUnitId === undefined && !isNilPayUnit(u.payUnit)) {
      const recordDto = this.mapToEmpPayUnitDto(u);
      change.addRecords.push(recordDto);
      return true;
    }
    if (u.initialPayUnitId !== undefined && isNilPayUnit(u.payUnit)) {
      const recordDeletedDto = this.mapToDeletedPayUnitDto(u);
      change.deleteRecords.push(recordDeletedDto);
      return true;
    }
    if (u.initialPayUnitId !== undefined && !isNilPayUnit(u.payUnit) && u.initialPayUnitId !== u.payUnit.id) {
      const recordDeletedDto = this.mapToDeletedPayUnitDto(u);
      change.deleteRecords.push(recordDeletedDto);
      const recordDto = this.mapToEmpPayUnitDto(u);
      change.addRecords.push(recordDto);
      return true;
    }
    if (!isNilPayUnit(u.payUnit)  && (u.initialUnits !== u.units || (u.initialPositionId !== undefined && !!u.position && u.initialPositionId !== u.position.id))) {
      const recordDto = this.mapToEmpPayUnitDto(u);
      change.updateRecords.push(recordDto);
      return true;
    }
    return false;
  }


  public mapToEmpPayUnitDto(data: EmpPayUnit): IEmpPayUnit {
    const dto: IEmpPayUnit = {
      payUnit: data.payUnit ? this.lookupMapService.mapToPayUnitDefinitionDto(data.payUnit) : undefined,
      employeeId: data.employeeId,
      position: data.position ? this.lookupMapService.mapPositionDto(data.position) : undefined,
      units: data.units,
      start: dateTimeUtils.convertToDtoDateTimeString(data.start),
      end: dateTimeUtils.convertToDtoDateTimeString(data.end),
      positionIdInitial: data.initialPositionId
    };
    return dto;
  }

  public mapToDeletedPayUnitDto(data: EmpPayUnit): IEmpPayUnit {
    const deletedPos = new Position();
    deletedPos.id = data.initialPositionId;
    const deletedPayUnit = new PayUnitDefinition();
    deletedPayUnit.id = data.initialPayUnitId;

    const dto: IEmpPayUnit = {
      payUnit: this.lookupMapService.mapToPayUnitDefinitionDto(deletedPayUnit),
      employeeId: data.employeeId,
      position: this.lookupMapService.mapPositionDto(deletedPos),
      units: data.units,
      start: dateTimeUtils.convertToDtoDateTimeString(data.start),
      end: dateTimeUtils.convertToDtoDateTimeString(data.end),
      positionIdInitial: data.initialPositionId
    };
    return dto;
  }

}
