
  <div class="headless-modal-content">

    <div class="modal-header">
      <div class="txt-pad10">
        <span class="pull-left">Edit 1095 Records</span>
        <span class="pull-right btn-close" (click)="onCancel()"><span><i class="fa fa-times" aria-hidden="true"></i></span></span>
      </div>
    </div>

    <div class="modal-body">
      
      <div>
        <div>Reason:</div>
        <div> 
          <slx-dropdown-list name="reasonDropdown"
          class="slx-wide slx-short"
          
          [options]="reasonOptions"          
          [(ngModel)]="selectedReason"
          valueField="id"
          titleField="name"
          >
          </slx-dropdown-list>
        </div>
        <div class="tooltip_edit"> 
          <i class="fa fa-question-circle" aria-hidden="true" [slxTooltip]="resonTooltip"></i>
          </div>
      </div>
      <div>
        <div>14 - Offer of Coverage:</div>
        <div> 
          <slx-dropdown-list name="coverageDropdown"
          class="slx-wide slx-short"
          
          [options]="coverageOptions"          
          [(ngModel)]="selectedCoverage"
          valueField="id"
          titleField="name"
          >
          </slx-dropdown-list>
        </div>
        <div class="tooltip_edit"><i class="fa fa-question-circle" aria-hidden="true" [slxTooltip]="coverageTooltip"></i></div>
      </div>
      <div>
        <div>15 - Cost:</div>
        <div> 
          
            <slx-number class="slx-wide slx-input-number" [min]="minAmt" [max]="maxAmt" [step]="stepcurrency"
                        [decimals]="2" [format]="currencyFormat" [autoCorrect]='true' [(ngModel)]="selectedCost"
                        name="employerContributionAmount" >
            </slx-number>
         
        </div>
        <div class="tooltip_edit"><i class="fa fa-question-circle" aria-hidden="true" [slxTooltip]="costTooltip"></i></div>
      </div>
      <div>
        <div>Enrolled:</div>
        <div> 
 
            <slx-checkbox-input slx-input name="chkEnroll" fieldName="chkEnroll"  [(ngModel)]="isEnrolled"
              >
            </slx-checkbox-input>

        </div>
        <div class="tooltip_edit"><i class="fa fa-question-circle" aria-hidden="true" [slxTooltip]="enrollTooltip"></i></div>
      </div>
      <div>
        <div>16 - Safe Harbor:</div>
        <div> 
          <slx-dropdown-list name="harborDropdown"
          class="slx-wide slx-short"        
          [options]="harborOptions"          
          [(ngModel)]="selectedHarbor"
          valueField="id"
          titleField="name"
          >
          </slx-dropdown-list>
        </div>
        <div class="tooltip_edit"><i class="fa fa-question-circle" aria-hidden="true" [slxTooltip]="safeHarborTooltip"></i></div>
      </div>
     
    </div>
    <div class="modal-footer center-align">
        <button type="button" (click)="onOk()" class="btn green" [disabled]="!populateRequireField">Save</button>
        <button type="button" (click)="onCancel()" class="btn red">Cancel</button>      
    </div>

  </div>

