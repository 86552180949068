import { Injectable } from '@angular/core';
import { dateTimeUtils } from '../../../common/utils/index';
import { Timeclock, ITimeclock, TimeclockContainer } from '../../models/index';
import { LookupMapService } from '../../../organization/services/index';
import { MetaMapService } from '../../../core/services/index';
import {
  IFieldData,
  FieldRenderType,
  FieldsMeta, FieldAccessType, Actions
} from '../../../core/models/index';

import * as _ from 'lodash';

@Injectable()
export class TimeclocksMapService {

  private lookupMapService: LookupMapService;
  private metaMapService: MetaMapService;
  constructor(lookupMapService: LookupMapService, metaMapService: MetaMapService) {
    this.lookupMapService = lookupMapService;
    this.metaMapService = metaMapService;
  }

  public mapTimeclocks(records: ITimeclock[], meta: FieldsMeta): TimeclockContainer {
    let container: TimeclockContainer = new TimeclockContainer();
    container.records = _.map(records, (item: ITimeclock) => {
      return this.mapTimeclock(item);
    });
    container.actions = this.metaMapService.mapActions(meta);
    return container;
  }
  public mapTimeclock(dto: ITimeclock): Timeclock {
    let data: Timeclock = new Timeclock();
    data.id = dto.id;
    data.name = dto.name;
    data.isVirtual = dto.isVirtual;
    data.openShiftRequestsEnabled = dto.openShiftRequestsEnabled;
    data.organization = this.lookupMapService.mapOrganization(dto.organization);
    data.department = this.lookupMapService.mapDepartment(dto.department);
    data.model = this.lookupMapService.mapTimeclockModelDefinition(dto.model);
    data.timezone = this.lookupMapService.mapTimezoneDefinition(dto.timezone);
    data.physicalId = dto.physicalId;
    data.ipaddress = dto.ipaddress;
    data.cmdMessageLine1 = dto.cmdMessageLine1;
    data.cmdMessageLine2 = dto.cmdMessageLine2;
    data.cmdMessageLine3 = dto.cmdMessageLine3;
    data.cmdMessageLine4 = dto.cmdMessageLine4;
    data.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
    data.lastUpdateUsername = dto.lastUpdateUsername;
    data.isMessagesEnabled = dto.isMessagesEnabled;
    data.isQRPunchEnabled = dto.isQRPunchEnabled;
    data.clockOrganization = dto.clockOrganization;
    return data;
  }

  public convertTimeclockToDto(data: Timeclock): ITimeclock {
    let dto: ITimeclock = {
      id: data.id,
      name: data.name,
      isVirtual: data.isVirtual,
      openShiftRequestsEnabled: data.openShiftRequestsEnabled,
      organization: data.organization ? this.lookupMapService.mapOrganizationDto(data.organization) : null,
      department: data.department ? this.lookupMapService.mapDepartmentDto(data.department) : null,
      model: data.model ? this.lookupMapService.mapTimeclockModelDefinition(data.model) : null,
      timezone: data.timezone ? this.lookupMapService.mapTimezoneDefinitionDto(data.timezone) : null,
      physicalId: data.physicalId,
      ipaddress: data.ipaddress,
      cmdMessageLine1: data.cmdMessageLine1,
      cmdMessageLine2: data.cmdMessageLine2,
      cmdMessageLine3: data.cmdMessageLine3,
      cmdMessageLine4: data.cmdMessageLine4,
      lastUpdateDate: dateTimeUtils.convertToDtoString(data.lastUpdateDate),
      lastUpdateUsername: data.lastUpdateUsername,
      isMessagesEnabled: data.isMessagesEnabled,
      isQRPunchEnabled: data.isQRPunchEnabled,
      clockOrganization: data.clockOrganization
    };
    return dto;
  }
}
