import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CustomListActorBase } from '../../../components/editableList/custom-list-actor.base';
import { EditableListActionKind, Shift } from '../../../models/index';
import { appConfig } from '../../../../app.config';
var ViewShiftRendererComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ViewShiftRendererComponent, _super);
    function ViewShiftRendererComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editable = true;
        return _this;
    }
    Object.defineProperty(ViewShiftRendererComponent.prototype, "context", {
        set: function (value) {
            this.item = value.item;
            this.rowIndex = value.rowIndex;
        },
        enumerable: true,
        configurable: true
    });
    ViewShiftRendererComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    ViewShiftRendererComponent.prototype.onEditClick = function () {
        this.actionEmitter.emit(EditableListActionKind.START_EDIT);
    };
    ViewShiftRendererComponent.prototype.onItemSelect = function () {
        this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
    };
    return ViewShiftRendererComponent;
}(CustomListActorBase));
export { ViewShiftRendererComponent };
