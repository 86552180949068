
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { subscribeToOrgLevel } from '../../../../../../organization/selectors/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../../../common/services/index';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
import { OrgLevel } from '../../../../../../state-model/models';
import { BenefitLineInfo,BenefitEnrolledEmployee, BenefitDetailsTier, BenefitDetailsOption } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/index';

@Component({
  selector: 'benefit-option-details-401k-grid',
  templateUrl: 'benefit-option-details-401k-grid.component.html',
  providers: [StateManagementService, ColumnManagementService ]
})
export class BenefitOptionDetail401kGridComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<BenefitDetailsOption>;
  public columnGroup = 'BenefitOptionDetail401kGridComponent';
  public appConfig: IApplicationConfig;
  public data: any[] = [];
  public coverageList: number[] = [];
  public isLoading: boolean = true;
  public selectableSettings: SelectableSettings;

  @Input()
  public benefitOptionData: BenefitDetailsOption[];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  constructor(private manService: BenefitEmployeeManagementService,
    private stateManagement: StateManagementService,
    private columnManagementService: ColumnManagementService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitDetailsOption>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;

    this.appConfig = appConfig;
  }

  public ngOnInit() {

    this.stateManagement.init('BenefitOptionDetail401kGridComponent');
    this.columnManagementService.init('BenefitOptionDetail401kGridComponent');
    this.columnManagementService.initGroup(this.columnGroup, 5);
    this.refreshGrid();
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
    this.selectableSettings = {
      checkboxOnly: true
    };
  }

  public ngOnDestroy(): void {

  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (!this.benefitOptionData) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.benefitOptionData, this.gridState.state);
  }

}
