<div class="formula flex justify-content__space-between wrap">
  <section class="slx-benefit-box">
    <h5 class="slx-benefit-box__hd align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-user"></i>
      <span class="slx-benefit-box__text">Options</span>
      <button type="button"
              class="slx-button slx-blue slx-with-icon add-button-mt pull-right"
              *ngIf="isEditMode"
              [disabled]="!canAddCovOptions"
              (click)="onAdd()">
        <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
        <span class="slx-button__text">Add New</span>
      </button>
    </h5>
    
      <div class="slx-benefit-box__body benefit-option-container">
        
          <form #form="ngForm" novalidate>
            <kendo-grid #kendoGrid
                        class="slx-blue-grid slx-grid-slim-rows slx-full-height benefit-option-grid"
                        [data]="gridState?.view"
                        [filterable]="'menu'"
                        [sortable]="{ mode: 'multiple' }"
                        [filter]="gridState.state.filter"
                        [skip]="gridState.state.skip"
                        [sort]="gridState.state.sort"
                        (cancel)="gridState.cancelHandler($event)"
                        (edit)="gridState.editHandler($event)"
                        (remove)="gridState.removeHandler($event)"
                        (save)="gridState.saveHandler($event)"
                        (dataStateChange)="gridState.dataStateChange($event)">
              <kendo-grid-command-column width="85" [locked]="true">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <div class="inline-buttons">
                    <button kendoGridEditCommand
                            type="button"
                            class="slx-button slx-only-icon slx-toolbar slx-margin-r"
                            *ngIf="isEditMode"
                            [disabled]='isRowEditing'>
                      <i class="fas fa-edit" aria-hidden="true"></i>
                    </button>
                    <button (click)="onDeleteOption(dataItem)"
                            *ngIf="isEditMode && !isCurrentRowEditing(dataItem)"
                            class="slx-button slx-gray slx-only-icon slx-toolbar"
                            [disabled]='isRowEditing'>
                      <i class="fas fa-trash" aria-hidden="true"></i>
                    </button>
                    <button kendoGridSaveCommand
                            type="button"
                            class="slx-button slx-only-icon slx-toolbar slx-margin-r"
                            *ngIf="isEditMode"
                            [disabled]='form.invalid'>
                      <i class="fas fa-check" aria-hidden="true"></i>
                    </button>
                    <button kendoGridCancelCommand
                            type="button"
                            class="slx-button  slx-only-icon slx-toolbar"
                            *ngIf="isEditMode">
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </ng-template>
              </kendo-grid-command-column>

              <kendo-grid-column title="Type" width="175" field="type">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Type</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.type}}</ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

                  <input type="text" class="slx-benefit-box__text-field code-input" [(ngModel)]="dataItem.type"
                         #codeType="ngModel"
                         [selfValue]="dataItem.type"
                         [required]="true"
                         [prohibitedValues]="{values: prohibitedTypeValues, trimValue: true, excludeSelf: true, caseSensitive: false}"
                         name="type{{rowIndex}}" maxlength="25">

                  <span *ngIf="codeType.errors" class="slx-error-block error-box">
                    <span *ngIf="codeType.errors.prohibitedValues" errorMessage for="prohibitedValues">Type already exists for the plan. Please enter a unique value.</span>
                    <span *ngIf="codeType.errors.required" errorMessage for="required"></span>
                  </span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Code" width="175" field="code">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Code</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem.code }}
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

                  <input type="text" class="slx-benefit-box__text-field code-input" [(ngModel)]="dataItem.code"
                         #codeRef="ngModel"
                         [selfValue]="dataItem.code"
                         [required]="true"
                         [prohibitedValues]="{values: prohibitedCodeValues, trimValue: true, excludeSelf: true, caseSensitive: false}"
                         name="code{{rowIndex}}" maxlength="25">

                  <span *ngIf="codeRef.errors" class="slx-error-block error-box">
                    <span *ngIf="codeRef.errors.prohibitedValues" errorMessage for="prohibitedValues">Code already exists for the plan. Please enter a unique code.</span>
                    <span *ngIf="codeRef.errors.required" errorMessage for="required"></span>
                  </span>

                </ng-template>
              </kendo-grid-column>

            </kendo-grid>
          </form>

      </div>
    
  </section>
  <div class="formula flex justify-content__space-between justified wrap">
    <section class="slx-benefit-box flex__grow">
      <h5 class="slx-benefit-box__hd flex align-items__center">
        <i aria-hidden="true" class="slx-benefit-box__icon fas fa-user"></i>
        <span class="slx-benefit-box__text">Employee Contribution</span>
      </h5>
      <div class="slx-benefit-box__body">
        <div class="slx-benefit-box__control flex align-items__center">
          <p class="slx-benefit-box__label formula__emp-label">Type</p>
          <p class="slx-benefit-box__value flex__grow">
            <slx-dropdown-list class="slx-wide"
                               valueField="id"
                               titleField="title"
                               [options]="calculationTypesShort"
                               [(ngModel)]="empContribution"
                               (ngModelChange)="onChangeEmpContributionType()"
                               [readonly]="isTypeReadOnly"
                               [disableEmittingIfReadonly]="true"></slx-dropdown-list>
          </p>
        </div>
        <div *ngIf="isEmpFixedCalcType" class="slx-benefit-box__control flex align-items__center">
          <p class="slx-benefit-box__label formula__emp-label">Fixed Amount</p>
          <p class="slx-benefit-box__value flex__grow">
            <slx-number #empFixedContField="ngModel" class="slx-wide"
                        [min]="min"
                        [max]="max"
                        [slxMin]="min"
                        [slxMax]="max"
                        [step]="step"
                        [decimals]="2"
                        [format]="format"
                        [autoCorrect]="false"
                        [readonly]="!isEditMode"
                        [(ngModel)]="empFixed"
                        (ngModelChange)="onChangeEmpContribution()">
                      </slx-number>
              <span *ngIf="empFixedContField.errors?.min || empFixedContField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </p>
        </div>
        <div *ngIf="isEmpFormulaCalcType && isShownFormula" class="slx-benefit-box__control flex align-items__center formula__column">
          <p class="slx-benefit-box__label formula__emp-label formula__w100">Formula</p>
          <p class="slx-benefit-box__value flex__grow formula__no-padd formula__w100">
            <textarea class="slx-benefit-box__text-field formula__textarea"
                      [disabled]="!isFormulaEditable"
                      [(ngModel)]="empFormulaText"
                      (ngModelChange)="onChangeEmpContribution()"
                      placeholder="Type your formula here..."></textarea>
          </p>
        </div>
      </div>
    </section>
    <section class="slx-benefit-box flex__grow">
      <h5 class="slx-benefit-box__hd flex align-items__center">
        <i aria-hidden="true" class="slx-benefit-box__icon fas fa-user"></i>
        <span class="slx-benefit-box__text">Employer Contribution</span>
      </h5>
      <div class="slx-benefit-box__body">
        <div class="slx-benefit-box__control flex align-items__center">
          <p class="slx-benefit-box__label formula__empr-label">Type</p>
          <p class="slx-benefit-box__value flex__grow">
            <slx-dropdown-list class="slx-wide"
                               valueField="id"
                               titleField="title"
                               [options]="calculationTypesShort"
                               [(ngModel)]="emprContribution"
                               (ngModelChange)="onChangeEmprContributionType()"
                               [readonly]="isTypeReadOnly"
                               [disableEmittingIfReadonly]="true"></slx-dropdown-list>
          </p>
        </div>
        <div *ngIf="isEmprFixedCalcType" class="slx-benefit-box__control flex align-items__center">
          <p class="slx-benefit-box__label formula__empr-label">Fixed Amount</p>
          <p class="slx-benefit-box__value flex__grow">
            <slx-number #emprFixedContField="ngModel" class="slx-wide"
                        [min]="min"
                        [max]="max"
                        [slxMin]="min"
                        [slxMax]="max"
                        [step]="step"
                        [format]="format"
                        [decimals]="2"
                        [autoCorrect]="false"
                        [readonly]="!isEditMode"
                        [(ngModel)]="emprFixed"
                        (ngModelChange)="onChangeEmprContribution()">
                      </slx-number>
              <span *ngIf="emprFixedContField.errors?.min || emprFixedContField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </p>
        </div>
        <div *ngIf="isEmprFormulaCalcType && isShownFormula" class="slx-benefit-box__control flex align-items__center formula__column">
          <p class="slx-benefit-box__label formula__empr-label formula__w100">Formula</p>
          <p class="slx-benefit-box__value flex__grow formula__no-padd formula__w100">
            <textarea class="slx-benefit-box__text-field formula__textarea"
                      [disabled]="!isFormulaEditable"
                      [(ngModel)]="emprFormulaText"
                      (ngModelChange)="onChangeEmprContribution()"
                      placeholder="Type your formula here..."></textarea>
          </p>
        </div>
      </div>
    </section>
    <section class="slx-benefit-box flex__grow">
      <h5 class="slx-benefit-box__hd flex align-items__center">
        <i aria-hidden="true" class="slx-benefit-box__icon fas fa-dollar-sign"></i>
        <span class="slx-benefit-box__text">Coverage</span>
      </h5>
      <div class="slx-benefit-box__body">
        <div class="slx-benefit-box__control flex align-items__center">
          <p class="slx-benefit-box__label formula__cov-label">Type</p>
          <p class="slx-benefit-box__value flex__grow">
            <slx-dropdown-list class="slx-wide"
                               valueField="id"
                               titleField="title"
                               [options]="calculationTypes"
                               [(ngModel)]="coverage"
                               (ngModelChange)="onChangeCoverageType()"
                               [readonly]="isTypeReadOnly"
                               [disableEmittingIfReadonly]="true"></slx-dropdown-list>
          </p>
        </div>
        <div *ngIf="isCovFixedCalcType" class="slx-benefit-box__control flex align-items__center">
          <p class="slx-benefit-box__label formula__cov-label">Fixed Amount</p>
          <p class="slx-benefit-box__value flex__grow">
            <slx-number #covFixedValueField="ngModel" class="slx-wide"
                        [min]="min"
                        [max]="max"
                        [slxMin]="min"
                        [slxMax]="max"
                        [step]="step"
                        [format]="format"
                        [decimals]="2"
                        [autoCorrect]="false"
                        [readonly]="!isEditMode"
                        [(ngModel)]="covFixed"
                        (ngModelChange)="onChangeCoverage()">
                      </slx-number>
              <span *ngIf="covFixedValueField.errors?.min || covFixedValueField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
          </p>
        </div>
        <div *ngIf="isCovMultiplierCalcType" class="slx-benefit-box__control flex align-items__center">
          <p class="slx-benefit-box__label formula__cov-label">Multiplier</p>
          <p class="slx-benefit-box__value flex__grow">
            <slx-dropdown-list class="slx-wide"
                               #multiplierType="ngModel"
                               [options]="multiplierMaxValues"
                               [(ngModel)]="covMultiplier"
                               (ngModelChange)="onChangeCoverage()"
                               [readonly]="!isEditMode"
                               [valuePrimitive]="true"
                               required></slx-dropdown-list>
            <span *ngIf="multiplierType.errors" class="slx-error-block error-box">
              <span *ngIf="multiplierType.errors.required" errorMessage for="required"></span>
            </span>
          </p>
        </div>
        <div *ngIf="isCovAnyValueCalcType || isCovMultiplierCalcType" class="slx-benefit-box__control flex align-items__center">
          <p class="slx-benefit-box__label formula__cov-label">Max Cap</p>
          <p class="slx-benefit-box__value flex__grow">
            <slx-number class="slx-wide" #covMaxCapNameField="ngModel"
                        [min]="min"
                        [max]="max"
                        [slxMin]="min"
                        [slxMax]="max"
                        [step]="step"
                        [format]="format"
                        [decimals]="2"
                        [autoCorrect]="false"
                        [readonly]="!isEditMode"
                        [(ngModel)]="covMaxCap"
                        (ngModelChange)="onChangeCoverage()"
                        required></slx-number>
            <span *ngIf="covMaxCapNameField.errors || isMaxCapLessThanMultiplier " class="slx-error-block error-box">
              <span *ngIf="covMaxCapNameField?.errors?.required" errorMessage for="required"></span>
              <span *ngIf="isMaxCapLessThanMultiplier" errorMessage>Max Cap value &nbsp;cannot be less than multiplier value &nbsp;selected</span>
              <span *ngIf="covMaxCapNameField?.errors?.min || covMaxCapNameField?.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
            </span>
          </p>
        </div>
        <div *ngIf="isCovFormulaCalcType && isShownFormula" class="slx-benefit-box__control flex align-items__center formula__column">
          <p class="slx-benefit-box__label formula__cov-label formula__w100">Formula</p>
          <p class="slx-benefit-box__value flex__grow formula__no-padd formula__w100">
            <textarea class="slx-benefit-box__text-field formula__textarea"
                      [disabled]="!isFormulaEditable"
                      [(ngModel)]="covFormulaText"
                      (ngModelChange)="onChangeCoverage()"
                      placeholder="Type your formula here..."></textarea>
          </p>
        </div>
      </div>
    </section>
  </div>
</div>


