import * as _ from 'lodash';
import { Directive, Input, OnDestroy, ViewContainerRef, TemplateRef } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { AccessProviderType, IAccessibleProvider } from '../../models/index';
import { MenuAccessibleProviderService } from '../../services/index';

export interface IAccessibleOptions {
  provider: AccessProviderType;
  permission: string;
}

@Directive({
  selector: '[slxAccessible]'
})
export class AccessibleDirective implements OnDestroy {
  @Input()
  public set slxAccessible(v: IAccessibleOptions) {
    if (!v) {
      return;
    }
    this.options = v;
    this.subscribeToProvider();
    this.updateView();
  }


  private options: IAccessibleOptions;
  private changeSubscription: Subscription;


  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    protected menuAccessibleProviderService: MenuAccessibleProviderService
  ) {
  }

  public getProvider(): IAccessibleProvider {
    switch (this.options.provider) {
      case AccessProviderType.Menu:
        return this.menuAccessibleProviderService;
      default:
        throw Error('Unknown AccessProviderType');
    }
  }

  public isAccessible(): boolean {
    const provider = this.getProvider();
    return provider.isAccessible(this.options.permission);
  }

  private subscribeToProvider(): void {
    this.unsubscribe();
    const provider = this.getProvider();
    this.changeSubscription = provider.subscribeToAccessibleChanged(() => this.updateView());
  }

  private unsubscribe(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
      this.changeSubscription = undefined;
    }
  }

  private updateView(): void {
    this.viewContainer.clear();
    if (this.isAccessible()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
