
export interface ITimezoneDefinition {
  id: string;
  name: string;
}

export class TimezoneDefinition {
  public id: string;
  public name: string;
}
