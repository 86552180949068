<kendo-dropdownlist
  #dropdown
  class="dropdown-container"
  slxKendoGridFilterInput
  [data]="items"
  textField="text"
  valueField="value"
  [popupSettings]="{ width: 'auto' }"
  [value]="value"
  [valuePrimitive]="true"
  (change)="onChange($event)"
></kendo-dropdownlist>
