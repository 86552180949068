import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { StaffingPredictorManagementService } from '../../services/index';
import { FiveStarRatingOrganization, StaffingPredictorOrganizationInfo } from '../../models/index';
import { PbjNavigationService } from '../../../../common/services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
var FivestarPredictorStarRatingComponent = /** @class */ (function () {
    function FivestarPredictorStarRatingComponent(router, route, management) {
        this.router = router;
        this.route = route;
        this.management = management;
        this.five_star_goal_section_enabled = false;
        this.isLoading = false;
        this.starRating = new EventEmitter();
        this.navigation = new PbjNavigationService(this.router, this.route);
    }
    Object.defineProperty(FivestarPredictorStarRatingComponent.prototype, "hasError", {
        get: function () {
            return !this.hasData || this.noCcn || this.noCensus || this.noCmsData || this.noHoursData || this.rnZeroDays || this.isFiveStarRatingAvailable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FivestarPredictorStarRatingComponent.prototype, "hasData", {
        get: function () {
            return this.dataModel != null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FivestarPredictorStarRatingComponent.prototype, "noCcn", {
        get: function () {
            return this.hasData ? this.srOrganization.noCcn : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FivestarPredictorStarRatingComponent.prototype, "noCensus", {
        get: function () {
            return this.hasData && !this.noCcn ? this.srOrganization.noCensus : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FivestarPredictorStarRatingComponent.prototype, "noHoursData", {
        get: function () {
            return this.hasData && !this.noCcn && !this.noCensus ? this.srOrganization.noHoursData : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FivestarPredictorStarRatingComponent.prototype, "noCmsData", {
        get: function () {
            return this.hasData && !this.noCcn && !this.noCensus && !this.noHoursData ? this.srOrganization.noCmsData : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FivestarPredictorStarRatingComponent.prototype, "rnZeroDays", {
        get: function () {
            return this.hasData && !this.noCcn && !this.noCensus && !this.noHoursData ? this.srOrganization.rnZeroDays : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FivestarPredictorStarRatingComponent.prototype, "isFiveStarRatingAvailable", {
        // public get outdatedData(): boolean {
        //     return this.hasData && !this.noCcn ? this.srOrganization.outdatedData : false;
        // }
        get: function () {
            if (this.hasData && !this.noCcn && !this.noHoursData && !this.noCensus && !this.noCmsData && !this.rnZeroDays && (this.srOrganization.noStaffingRatingPastRatedPeriodInfo || this.srOrganization.noCaseMixTotalNurseHoursInfo || this.srOrganization.noCaseMixRNHoursInfo || this.srOrganization.noTotalNurseTurnoverInfo || this.srOrganization.noAdministratorDeparturesInfo)) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    FivestarPredictorStarRatingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hideSubscription = this.management.hideOnPrint$.subscribe(function (hide) {
            _this.hideToPrint = hide;
        });
    };
    FivestarPredictorStarRatingComponent.prototype.goToConfigScreen = function () {
        this.navigation.NavigateToPbjOrganizationsConfiguration();
    };
    FivestarPredictorStarRatingComponent.prototype.getRating = function (value) {
        this.starRating.emit(value);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], FivestarPredictorStarRatingComponent.prototype, "hideSubscription", void 0);
    return FivestarPredictorStarRatingComponent;
}());
export { FivestarPredictorStarRatingComponent };
