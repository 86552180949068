<div>
    <form #displayOptions>
        <div class="option display-options-group">
            <div class="option-content">
                <slx-input-decorator className="slx-no-border slx-no-error slx-no-label">
                    <slx-radio-input [(ngModel)]="currentViewMode" (ngModelChange)="handleSettingType($event)" slx-input option="hours" name="duaHours" fieldName="duaHours"
                    caption="Hours">
                    </slx-radio-input>
                </slx-input-decorator>
                <slx-input-decorator className="slx-no-border slx-no-error slx-no-label">
                    <slx-radio-input [(ngModel)]="currentViewMode" (ngModelChange)="handleSettingType($event)" slx-input option="ppd" name="duaPpd" fieldName="duaPpd"
                    caption="PPD">
                    </slx-radio-input>
                </slx-input-decorator>
                <slx-input-decorator className="slx-no-border slx-no-error slx-no-label">
                    <slx-radio-input [(ngModel)]="currentViewMode" (ngModelChange)="handleSettingType($event)" slx-input option="slots" name="duaSlots" fieldName="duaSlots"
                    caption="Slots">
                    </slx-radio-input>
                </slx-input-decorator>
            
            </div>
        </div>
    </form>
    <div class="btn-container">
        <button type="button" class="theme-button-apply" (click)="onApply()">Apply</button>
    </div>
</div>