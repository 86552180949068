import { destroyService } from './../../../../core/decorators/destroy-service-decorator';
import { unsubscribe } from './../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { KendoGridStateHelper } from './../../../../common/models/index';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IRangeDates } from '../../../../common/models/range-dates';
import { StateManagementService } from '../../../../common/index';
import { ExceptionConsoleApiService } from '../../../services/index';
import { mutableSelect, unsubscribeInService } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { IColumnSettings } from '../../../../core/models';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PunchesRollupManagementService } from '../../../services/punches/punches-rollup-management.service';
import { PunchesRollupRecord, PunchesRollupState } from '../../../models';

@Component({
    moduleId: module.id,
    selector: 'slx-punches-management-rollup',
    templateUrl: 'punches-management-rollup.component.html',
    styleUrls: ['punches-management-rollup.component.scss'],
    providers: [PunchesRollupManagementService, StateManagementService]
})
export class PunchesManagementRollupComponent implements OnInit, OnDestroy {
    public gridState: KendoGridStateHelper<PunchesRollupRecord>;
    public globalState: PunchesRollupState;

    public state: {
        isLoading: boolean;
      };
    
    @destroyService()
    public managementService: PunchesRollupManagementService;

    @unsubscribe()
    private loadStateSubscription: Subscription;
    @unsubscribe()
    private loadStatusSubscription: Subscription;
    @unsubscribe()
    private loadDataSubscription: Subscription;
    @unsubscribe()
    private loadedSubscription: Subscription;
    @unsubscribe()
    private stateSubscription: Subscription;
    @unsubscribe()
    private controlRestoredSubscription: Subscription;
    
    private records: PunchesRollupRecord[];
    private readonly componentId: string = 'PunchesManagementRollupComponent';

    constructor(private stateService: StateManagementService, 
        managementService: PunchesRollupManagementService) {
        this.managementService = managementService;
        this.gridState = new KendoGridStateHelper<PunchesRollupRecord>();
        this.state = {
            isLoading: false,
        };
        this.stateService.init(this.componentId, true);
    }

    public ngOnInit(): void {
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe((value: boolean) => {
                this.state.isLoading = value;
            });

        this.loadStateSubscription = this.managementService.onStateChanged$
            .subscribe((state: PunchesRollupState) => {
                this.globalState = state;
            });

        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe((value: boolean) => {
                this.state.isLoading = value;
            });
        this.loadedSubscription = this.managementService.onLoaded$
            .subscribe((value: any) => {
                let col: IColumnSettings =  _.first(this.globalState.columns);
                if (col) {
                    col.readonly = this.managementService.needShowDepartments;
                    col.displayed = !this.managementService.needShowDepartments;
                    col.description = this.managementService.getOrgLevelTitle();
                }
            });
            
    }

    public ngOnDestroy(): void {
        //AOT
    }

    public onChangeColumn(event: MouseEvent, column: IColumnSettings): any {
        this.managementService.onStateChanged(this.globalState);
    }

    public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
       this.managementService.setDates(startDate, endDate);
 
    }

    public selectAll(event: MouseEvent): void {
        _.forEach(this.globalState.columns, (column: IColumnSettings) => {
            if (!column.readonly) {
            column.displayed = true;
            }
        });
        this.managementService.onStateChanged(this.globalState);
    }
      
    public clearAll(event: MouseEvent): void {
        _.forEach(this.globalState.columns, (column: IColumnSettings) => {
          if (!column.readonly) {
            column.displayed = false;
          }
        });
        this.managementService.onStateChanged(this.globalState);
    }

    
}
