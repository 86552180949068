import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { ManagementBaseService } from '../../../core/services/index';
import { ModalService } from '../../../common/services/index';
import { StaffingRatingContainer } from '../models/staffing-rating-container';
import { StaffingPredictorApiService } from './staffing-predictor-api.service';
import { StaffingPredictorOrganizationInfo, StaffingRatingRequest, StaffingOrganizationInfoRequest, StaffingPredictorMatrixDialogOptions } from '../models/index';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { PredictorMatrixDialogComponent } from '../components/predictor-matrix-dialog/predictor-matrix-dialog.component';
import { ComponentStateStorageService } from '../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { StateResetTypes } from '../../../core/models/settings/index';
import { GoalSettingRequest } from '../models/goal-setting';
import { Subject } from 'rxjs';
var StaffingPredictorManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(StaffingPredictorManagementService, _super);
    function StaffingPredictorManagementService(api, modalService, storageService, stateManagement) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.modalService = modalService;
        _this.storageService = storageService;
        _this.stateManagement = stateManagement;
        _this.goalReqObj = new Subject();
        _this.isGoalValueChanged$ = new Subject();
        _this.disableCollapsableContainer$ = new Subject();
        _this.disableCollapsableContainer2$ = new Subject();
        _this.getAllGoalInfo$ = new Subject();
        _this.organizationInfos = [];
        _this.resetBy = StateResetTypes.SessionEnd;
        _this.dataControlKey = 'fiveStarRatingDataModel';
        _this.onOrganizationInfoLoaded$ = new ReplaySubject(1);
        var defaultContainer = _this.getDefaultContainer();
        _this.m_container = defaultContainer;
        return _this;
    }
    Object.defineProperty(StaffingPredictorManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorManagementService.prototype, "containerInfo", {
        get: function () {
            return this.m_containerInfo;
        },
        enumerable: true,
        configurable: true
    });
    StaffingPredictorManagementService.prototype.clearCache = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.dataControlKey, { value: null }, this.resetBy);
    };
    StaffingPredictorManagementService.prototype.loadRatingList = function (startDate, endDate, organizationIds, five_star_v2_enabled, five_star_goal_section_enabled, isOrgChanged) {
        var _this = this;
        if (organizationIds === void 0) { organizationIds = null; }
        if (isOrgChanged === void 0) { isOrgChanged = false; }
        this.onLoadStatusChanged(true);
        var request = new StaffingRatingRequest();
        request.startDate = startDate;
        request.endDate = endDate;
        request.organizationIds = organizationIds != null && organizationIds.length > 0 ? organizationIds : null;
        if (five_star_v2_enabled) {
            var cacheData = this.storageService.getControlState(this.stateManagement.componentKey, this.dataControlKey);
            var ratingCacheData = _.get(cacheData.value, 'data', null);
            if (ratingCacheData && !isOrgChanged) {
                this.fivestar_container = ratingCacheData;
                if (organizationIds.length > 0)
                    this.fivestar_container.organizations = ratingCacheData.organizations.filter(function (item) { return organizationIds.includes(item.organization.id); });
                this.onLoaded(this.fivestar_container);
                this.onLoadStatusChanged(false);
            }
            else {
                this.api.getFiveStarRatingList(request)
                    .then(function (data) {
                    _this.fivestar_container = data;
                    _this.storageService.setControlState(_this.stateManagement.componentKey, _this.dataControlKey, { value: { data: data } }, _this.resetBy);
                    _this.onLoaded(_this.fivestar_container);
                    _this.onLoadStatusChanged(false);
                })
                    .catch(function (err) {
                    _this.container.hasError = true;
                    _this.onLoaded(_this.fivestar_container);
                    _this.onError(err);
                    _this.onLoadStatusChanged(false);
                });
            }
        }
        else {
            this.api.getRatingList(request)
                .then(function (data) {
                _this.m_container = data;
                _this.onLoaded(_this.container);
                _this.onLoadStatusChanged(false);
            })
                .catch(function (err) {
                _this.onError(err);
                _this.onLoadStatusChanged(false);
            });
        }
    };
    StaffingPredictorManagementService.prototype.loadOrganizationInfo = function (organizationId, startDate, endDate, five_star_v2_enabled, five_star_goal_section_enabled) {
        var _this = this;
        if (five_star_v2_enabled === void 0) { five_star_v2_enabled = false; }
        if (five_star_goal_section_enabled === void 0) { five_star_goal_section_enabled = false; }
        this.onLoadStatusChanged(true);
        this.organizationInfos[organizationId] = null;
        var req = new StaffingOrganizationInfoRequest();
        req.organizationId = organizationId;
        req.startDate = startDate;
        req.endDate = endDate;
        // if (five_star_v2_enabled && five_star_goal_section_enabled) {
        //     this.api.loadFiveStarOrganizationInfo(req)
        //         .then((data: FiveStarPredictorOrganizationInfo) => {
        //             this.m_containerInfo = data;
        //             this.onLoadStatusChanged(false);
        //             this.onOrganizationInfoLoaded$.next(this.m_containerInfo);
        //             this.organizationInfos[organizationId] = this.m_containerInfo;
        //         })
        //         .catch((err: any) => {
        //             this.onError(err);
        //             this.onLoadStatusChanged(false);
        //         });
        // } else 
        {
            this.api.loadOrganizationInfo(req)
                .then(function (data) {
                _this.m_containerInfo = data;
                _this.onLoadStatusChanged(false);
                _this.onOrganizationInfoLoaded$.next(_this.m_containerInfo);
                _this.organizationInfos[organizationId] = _this.m_containerInfo;
            })
                .catch(function (err) {
                _this.onError(err);
                _this.onLoadStatusChanged(false);
            });
        }
    };
    StaffingPredictorManagementService.prototype.loadGoalsSectionInfo = function (five_star_goal_section_enabled, starRating) {
        var _this = this;
        if (five_star_goal_section_enabled === void 0) { five_star_goal_section_enabled = false; }
        this.onLoadStatusChanged(true);
        var goalReq = new GoalSettingRequest();
        goalReq.startDate = this.setStartDate;
        goalReq.endDate = this.setEndDate;
        goalReq.numberOfStars = starRating;
        goalReq.organizationId = this.organizationId;
        this.goalReqObj.next(goalReq);
        if (five_star_goal_section_enabled) {
            this.api.loadGoalSectionInfo(goalReq).then(function (data) {
                _this.onLoadStatusChanged(false);
                _this.getAllGoalInfo$.next(data);
            });
        }
    };
    StaffingPredictorManagementService.prototype.showDetails = function (organizationId) {
        var options = new StaffingPredictorMatrixDialogOptions();
        options.staffingPointsTables = this.container.pointTables;
        options.organizationInfo = this.organizationInfos[organizationId];
        PredictorMatrixDialogComponent.openDialog(options, this.modalService, function (result) {
            _.noop();
        });
    };
    StaffingPredictorManagementService.prototype.showDetailsFiveStar = function () {
        var options = new StaffingPredictorMatrixDialogOptions();
        options.staffingPointsTables = this.container.pointTables;
        options.organizationInfo = new StaffingPredictorOrganizationInfo();
        options.width = 500;
        options.height = 360;
        options.five_star_v2_enabled = true;
        PredictorMatrixDialogComponent.openDialog(options, this.modalService, function (result) {
            _.noop();
        });
    };
    StaffingPredictorManagementService.prototype.getDefaultContainer = function () {
        var defaultContainer = new StaffingRatingContainer();
        defaultContainer.organizations = [];
        defaultContainer.ratingMaxValue = 5;
        var quarter = this.getCurrentQuarter();
        defaultContainer.startDate = quarter.startDate;
        defaultContainer.endDate = quarter.endDate;
        this.m_container = defaultContainer;
        return defaultContainer;
    };
    StaffingPredictorManagementService.prototype.getCurrentQuarter = function () {
        var now = moment();
        var quarter = now.quarter();
        return {
            index: quarter,
            startDate: moment().quarter(quarter).startOf('quarter').toDate(),
            endDate: moment().quarter(quarter).endOf('quarter').toDate()
        };
    };
    return StaffingPredictorManagementService;
}(ManagementBaseService));
export { StaffingPredictorManagementService };
