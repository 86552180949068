import { EventEmitter, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { LookupService } from '../../services/lookup/lookup.service';
import { EmployeeShift, LookupType } from '../../models/index';
import { DateRange } from '../../../core/models';
var EmployeeShiftEditorComponent = /** @class */ (function () {
    function EmployeeShiftEditorComponent(lookupService) {
        this.loadHomeValues = false;
        this.isReadOnly = false;
        this.onRequiredFieldsPopulated = new EventEmitter();
        this.lookupService = lookupService;
        this.state = {
            isPositionLoading: false,
            isShiftLoading: false,
            isUnitLoading: false,
            isConstraintLoading: false,
            isAbsenceLoading: false,
        };
        this.useAbsence = false;
        this.useConstraint = true;
        this.canChangePosition = true;
    }
    Object.defineProperty(EmployeeShiftEditorComponent.prototype, "positionDateRange", {
        set: function (positionPeriod) {
            if (this.positionPeriod === positionPeriod) {
                return;
            }
            this.positionPeriod = positionPeriod;
            if (!(_.isNil(this.employeeId))) {
                this.loadPositionLookup();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeShiftEditorComponent.prototype, "employee", {
        set: function (employeeId) {
            if (this.employeeId === employeeId) {
                return;
            }
            this.employeeId = employeeId;
            this.loadPositionLookup();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeShiftEditorComponent.prototype, "position", {
        set: function (value) {
            this.employeeShift.position = value;
            this.selectedPositionChanged(this.employeeShift.position);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeShiftEditorComponent.prototype.ngOnChanges = function () {
        if (this.loadHomeValues) {
            this.setHomePostion();
            this.setHomeShift();
            this.setHomeUnit();
            this.employeeShift.constraint = null;
        }
    };
    EmployeeShiftEditorComponent.prototype.selectedPositionChanged = function (selectedPosition) {
        this.modelChanged();
        if (selectedPosition && selectedPosition.orgLevelId && this.selectedPositionOrgLevelId !== selectedPosition.orgLevelId) {
            this.selectedPositionOrgLevelId = selectedPosition.orgLevelId;
            this.loadShiftDefinitionsLookup(this.employeeId, this.selectedPositionOrgLevelId, true);
            this.loadLocationUnitsLookup(this.employeeId, this.selectedPositionOrgLevelId);
            this.loadConstraintDefinitionsLookup(this.employeeId, this.selectedPositionOrgLevelId);
            this.loadScheduleAbsenceLookup(this.selectedPositionOrgLevelId);
        }
        else {
            this.setHomeShift();
        }
    };
    EmployeeShiftEditorComponent.prototype.modelChanged = function () {
        if ((!this.canChangePosition || this.employeeShift.position)
            && this.employeeShift.shift && this.employeeShift.unit) {
            this.onRequiredFieldsPopulated.emit();
        }
    };
    EmployeeShiftEditorComponent.prototype.loadPositionLookup = function () {
        var _this = this;
        this.state.isPositionLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.position, employeeId: this.employeeId, isActive: _.isNil(this.positionPeriod) })
            .then(function (positionLookup) {
            _this.positionLookup = positionLookup;
            if (!_.isNil(_this.positionPeriod)) {
                _this.positionLookup.items = _.filter(_this.positionLookup.items, function (x) { return x.startDate <= _this.positionPeriod.endDate && x.endDate >= _this.positionPeriod.startDate; });
            }
            if (_this.positionLookup && _this.positionLookup.items) {
                var positionToSelect = null;
                if (_this.employeeShift.position) {
                    positionToSelect = _this.findPositionById(_this.employeeShift.position.id);
                }
                if (!positionToSelect && _this.homePositionId) {
                    positionToSelect = _this.findPositionById(_this.homePositionId);
                }
                if (!positionToSelect) {
                    positionToSelect = _.first(_this.positionLookup.items);
                }
                _this.position = positionToSelect;
            }
            _this.state.isPositionLoading = false;
        })
            .catch(function () {
            _this.state.isPositionLoading = false;
        });
    };
    EmployeeShiftEditorComponent.prototype.findPositionById = function (positionId) {
        return _.find(this.positionLookup.items, function (item) {
            return item.id === positionId;
        });
    };
    EmployeeShiftEditorComponent.prototype.loadShiftDefinitionsLookup = function (employeeId, orgLevelId, isPositionDropDownChange) {
        var _this = this;
        if (isPositionDropDownChange === void 0) { isPositionDropDownChange = false; }
        this.state.isShiftLoading = true;
        this.employeeShift.shift = null;
        this.lookupService.getLookup({ lookupType: LookupType.shift, employeeId: employeeId, orgLevelId: orgLevelId })
            .then(function (shiftLookup) {
            if (_this.selectedPositionOrgLevelId === orgLevelId) {
                _this.shiftLookup = shiftLookup;
            }
            if (_this.shiftLookup && _this.shiftLookup.items) {
                _this.setHomeShift();
            }
            if (!_this.employeeShift.shift && isPositionDropDownChange) {
                _this.employeeShift.shift = _.first(_this.shiftLookup.items);
            }
            _this.modelChanged();
            _this.state.isShiftLoading = false;
        })
            .catch(function () {
            _this.state.isShiftLoading = false;
        });
    };
    EmployeeShiftEditorComponent.prototype.loadLocationUnitsLookup = function (employeeId, orgLevelId) {
        var _this = this;
        this.state.isUnitLoading = true;
        this.employeeShift.unit = null;
        this.lookupService.getLookup({ lookupType: LookupType.locationUnit, employeeId: employeeId, orgLevelId: orgLevelId })
            .then(function (unitLookup) {
            if (_this.selectedPositionOrgLevelId === orgLevelId) {
                _this.unitLookup = unitLookup;
            }
            if (_this.unitLookup && _this.unitLookup.items) {
                _this.setHomeUnit();
            }
            _this.modelChanged();
            _this.state.isUnitLoading = false;
        })
            .catch(function () {
            _this.state.isUnitLoading = false;
        });
    };
    EmployeeShiftEditorComponent.prototype.loadConstraintDefinitionsLookup = function (employeeId, orgLevelId) {
        var _this = this;
        if (!this.useConstraint) {
            return;
        }
        this.state.isConstraintLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.constraintDefinition, employeeId: employeeId, orgLevelId: orgLevelId })
            .then(function (constraintLookup) {
            if (_this.selectedPositionOrgLevelId === orgLevelId) {
                _this.constraintLookup = constraintLookup;
            }
            _this.state.isConstraintLoading = false;
        })
            .catch(function () {
            _this.state.isConstraintLoading = false;
        });
    };
    EmployeeShiftEditorComponent.prototype.loadScheduleAbsenceLookup = function (orgLevelId) {
        var _this = this;
        if (!this.useAbsence) {
            return;
        }
        this.state.isAbsenceLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.scheduleAbsence, orgLevelId: orgLevelId })
            .then(function (scheduleAbsenceLookup) {
            if (_this.selectedPositionOrgLevelId === orgLevelId) {
                _this.scheduleAbsenceLookup = scheduleAbsenceLookup;
            }
            _this.state.isAbsenceLoading = false;
        })
            .catch(function () {
            _this.state.isAbsenceLoading = false;
        });
    };
    EmployeeShiftEditorComponent.prototype.setHomeUnit = function () {
        var _this = this;
        if (this.homeUnitId) {
            var homeUnit = _.find(this.unitLookup.items, function (item) {
                return item.id === _this.homeUnitId;
            });
            if (homeUnit) {
                this.employeeShift.unit = homeUnit;
            }
        }
        if (!this.employeeShift.unit) {
            this.employeeShift.unit = _.first(this.unitLookup.items);
        }
    };
    EmployeeShiftEditorComponent.prototype.setHomeShift = function () {
        var _this = this;
        if (this.homeShiftId) {
            var homeShift = _.find(this.shiftLookup.items, function (item) {
                return item.id === _this.homeShiftId;
            });
            if (homeShift) {
                this.employeeShift.shift = homeShift;
            }
        }
    };
    EmployeeShiftEditorComponent.prototype.setHomePostion = function () {
        var positionToSelect = null;
        if (this.homePositionId) {
            positionToSelect = this.findPositionById(this.homePositionId);
        }
        if (!positionToSelect) {
            positionToSelect = _.first(this.positionLookup.items);
        }
        this.position = positionToSelect;
    };
    return EmployeeShiftEditorComponent;
}());
export { EmployeeShiftEditorComponent };
