import { OrgLevelType } from '../../state-model/models/index';

export interface IScreenAvailabilityState {
  isReady: boolean;
  isAvailable: boolean;
}

export class ScreenAvailabilityState {
  public isReady: boolean;
  public isAvailable: boolean;
  public supportedOrgTypes: OrgLevelType[];

  constructor() {
    this.isReady = false;
    this.isAvailable = false;
    this.supportedOrgTypes = [];
  }
}
