import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MomentModule } from 'angular2-moment';
import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { NgxPopperModule } from 'ngx-popper';
import { ColorPickerModule } from 'ngx-color-picker';
import { ComponentsLibraryModule } from '../../components-library/index';
import { EmployeeModule } from '../../employee/employee/index';

import { lmComponents, lmEntryComponents, lmExportComponents } from './components/index';
import { services } from './services/index';


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    MomentModule,
    GridModule,
    ChartsModule,
    PDFModule,
    ExcelModule,
    DropDownsModule,
    LayoutModule,
    OrganizationModule,
    CommonAppModule,
    NgxPopperModule,
    ColorPickerModule,
    ComponentsLibraryModule,
    EmployeeModule
  ],
  declarations: [
    ...lmComponents,
  ],
  entryComponents: [
    ...lmEntryComponents
  ],
  providers: [
    ...services,
  ],
  exports: [
    ...lmExportComponents
  ]
})
export class LeaveManagementModule { }
