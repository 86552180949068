import { MasterScheduleGroupingType } from './../../models/master-schedule/master-schedule-grouping-type';

import { Injectable, Inject } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/skip';
import { mutableSelect, unsubscribe, unsubscribeInService } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
import { ManagementBaseService } from '../../../core/services/index';
import { ScheduleApiService } from './schedule-api.service';
import { ScheduleMapService } from './schedule-map.service';
import { Position, ScheduleCycle, EmployeeScheduleDefinition, ScheduleCycleSummaryMessage, GenerateScheduleSummary, IScheduleActions, BudgetedPosition } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { MasterScheduleActions } from '../../store/master-schedule/master-schedule.actions';
import {
  IMasterScheduleRow, MasterScheduleData, EmployeeGridData, ScheduleTotal, ScheduleSubtotal, IMasterScheduleEntryRow, IMasterScheduleTotalRow,
  MasterScheduleEntryCell, MasterScheduleShiftInfo, DayTotal, MasterScheduleEntryRow, ISchedulePosition, ScheduleEntry, IMasterScheduleSubtotalRow,
  MasterScheduleFilters, TotalsDescription, IMasterScheduleCell, MasterScheduleRecordType, MasterScheduleSubtotalRow, MasterScheduleGroupingTypes,
  IMasterScheduleGroupingType
} from '../../models/index';
import { EmployeesStateSelector, EmployeesSelector } from '../../store/index';
import { IUser } from '../../../authentication/store/index';
import { TOOLBAR_SERVICE } from '../../../core/services/index';
import { MasterScheduleToolbarService } from './master-schedule-toolbar.service';
import { MasterScheduleFilterActions } from '../../store/master-schedule/master-schedule-filter.actions';
import { LookupApiService, ScheduleCycleHelperService, MenuAccessibleProviderService } from '../../../organization/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { BudgetedParsDetailsContainer } from '../../models/budgeted-pars/budgeted-pars-details-container';

@Injectable()
export class MasterScheduleManagementService extends ManagementBaseService<MasterScheduleData, any> {

  static firstLoad: boolean = true;
  public onRecalculated$: ReplaySubject<EmployeeGridData>;
  public filtersRestored$: ReplaySubject<MasterScheduleFilters>;
  public filtersChanged$: ReplaySubject<MasterScheduleFilters>;
  public onStartCycleSelect$: Subject<Date>;
  public onNonStartCycleSelect$: Subject<Date>;
  public scheduleCyclesLoaded$: Subject<ScheduleCycle[]>;
  public currentFilters: MasterScheduleFilters;
  public currentOrgLevelId: number;
  public currentOrgLevelParentId: number;
  public cycles: ScheduleCycle[];

  @mutableSelect(['session', 'user'])
  public sessionUser$: Observable<IUser>;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @mutableSelect(['masterSchedule', 'employees'])
  public employees$: Observable<IMasterScheduleRow[]>;

  @mutableSelect(['masterSchedule', 'totals'])
  public totals$: Observable<IMasterScheduleRow[]>;

  @mutableSelect(['masterSchedule', 'subtotals'])
  public subtotals$: Observable<IMasterScheduleRow[]>;

  @mutableSelect(['masterSchedule', 'actions'])
  public actions$: Observable<IScheduleActions>;

  @mutableSelect(['masterSchedule', 'isLoading'])
  public isLoading$: Observable<boolean>;

  @mutableSelect(['masterSchedule', 'isLoadingTotals'])
  public isLoadingTotals$: Observable<boolean>;

  @mutableSelect(['masterSchedule', 'hasError'])
  public hasError$: Observable<boolean>;

  @mutableSelect(['masterSchedule', 'errorMessage'])
  public errorMessage$: Observable<string>;

  public onLoadTotalsStatus$: ReplaySubject<boolean>;
  public onTotalsLoaded$: ReplaySubject<MasterScheduleData>;
  public onEmployeeCellSelected$: ReplaySubject<IMasterScheduleCell>;
  public filterSettings: any;

  public msData: MasterScheduleData;
  public firstCycleStart: moment.Moment;
  public dateFrom: Date;
  public endDate: Date;
  public totalsAlreadyLoaded: boolean;

  private cycleWeeks: number;
  private cycleDays: number;

  @unsubscribeInService()
  private dataSubscription: Subscription;
  @unsubscribeInService()
  private totalsSubscription: Subscription;
  @unsubscribeInService()
  private filterSubscription: Subscription;
  @unsubscribeInService()
  private statusSubscription: Subscription;
  @unsubscribeInService()
  private statusTotalsSubscription: Subscription;
  @unsubscribeInService()
  private errorSubscription: Subscription;
  @unsubscribeInService()
  private filterEchoSubscription: Subscription;
  @unsubscribeInService()
  private orgLevelSubscription: Subscription;

  private selectedGrouping: IMasterScheduleGroupingType;

  constructor(
    private api: ScheduleApiService,
    private mapService: ScheduleMapService,
    private masterScheduleActions: MasterScheduleActions,
    private masterScheduleFilterActions: MasterScheduleFilterActions,
    private employeesSelector: EmployeesSelector,
    private lookupApiService: LookupApiService,
    private scheduleCycleHelperService: ScheduleCycleHelperService,
    private menuAccessibleProviderService: MenuAccessibleProviderService,
    @Inject(TOOLBAR_SERVICE) private masterScheduleToolbarService: MasterScheduleToolbarService,
  ) {
    super();
    this.onRecalculated$ = new ReplaySubject(1);
    this.filtersChanged$ = new ReplaySubject(1);
    this.filtersRestored$ = new ReplaySubject(1);
    this.onLoadTotalsStatus$ = new ReplaySubject(1);
    this.onTotalsLoaded$ = new ReplaySubject(1);
    this.onEmployeeCellSelected$ = new ReplaySubject(1);

    this.onStartCycleSelect$ = new Subject();
    this.onNonStartCycleSelect$ = new Subject();
    this.scheduleCyclesLoaded$ = new Subject<ScheduleCycle[]>();
    this.statusSubscription = this.isLoading$.subscribe((isLoading: boolean) => {
      this.onLoadStatusChanged(isLoading);
    });

    this.statusTotalsSubscription = this.isLoadingTotals$.subscribe((isLoading: boolean) => {
      this.onLoadTotalsStatus$.next(isLoading);
    });

    this.orgLevelSubscription = this.orgLevel$
      .combineLatest(this.filtersRestored$)
      .subscribe(([orgLevel, filters]: [OrgLevel, MasterScheduleFilters]) => {
        if (!orgLevel || !orgLevel.id) {
          return;
        }
        this.lookupApiService.getScheduleCycles(orgLevel.id)
          .then((cycles: ScheduleCycle[]) => {
            this.scheduleCyclesLoaded$.next(this.cycles = cycles);
            this.dateFrom = moment(filters.dateFrom).startOf('day').toDate();
            if (this.cycles.length > 0) {
              const today = moment().startOf('day');
              let firstCycle = this.findPayCycle(this.cycles, today.toDate());
              this.firstCycleStart = firstCycle ? firstCycle.startDate : today;
              if (!filters.dateFrom || !dateTimeUtils.validateDate(filters.dateFrom) || (this.currentOrgLevelParentId > 0 && this.currentOrgLevelParentId !== orgLevel.parentId)) {
                this.dateFrom = this.firstCycleStart.toDate();
                filters.dateFrom = null;
              }
              //this.updateStartOfCycleSubscription(this.dateFrom);
            }
            this.cycleWeeks = this.getWeeksByDate(this.dateFrom);
            this.cycleDays = this.cycleWeeks * 7;
            this.endDate = moment(this.dateFrom).add(this.cycleWeeks, 'weeks').subtract(1, 'day').toDate();
            if (filters.weekNumber !== this.cycleWeeks || !filters.dateFrom) {
              filters.dateFrom = this.dateFrom;
              filters.weekNumber = this.getWeeksByDate(this.dateFrom);
              //this.actions.setFilters({ dateFrom: this.dateFrom, scheduleDisplay: this.getWeeksByDate(this.dateFrom) });
            }
            this.masterScheduleToolbarService.onFiltersChanged(filters);
          });
      });

    this.filterEchoSubscription = this.masterScheduleToolbarService.onFiltersChanged$
      .subscribe((filters: MasterScheduleFilters) => {
        this.filtersChanged$.next(filters);
        this.filterSettings = filters;
        const ids = filters.filters.getIds();
        this.selectedGrouping = filters.groupBy;
        this.masterScheduleFilterActions.setFilters({
          dateFrom: filters.dateFrom,
          weekNumber: filters.weekNumber,
          positionGroup: ids.positionGroupId,
          position: ids.positionId,
          employeeType: ids.employeeTypeNames,
          unit: ids.unitId,
          shiftGroup: ids.shiftGroupId,
          groupBy: filters.groupBy,
          showTotalsFTEs: filters.showTotalsFTEs,
        });
      });

    this.filterSubscription = this.masterScheduleToolbarService.onFiltersChanged$
      .withLatestFrom(this.orgLevel$)
      .withLatestFrom(this.sessionUser$)
      .subscribe(([[masterScheduleFilters, orgLevel], user]: [[MasterScheduleFilters, OrgLevel], IUser]) => {
        this.currentOrgLevelId = orgLevel.id;
        this.currentOrgLevelParentId = orgLevel.parentId;
        this.reload(masterScheduleFilters, orgLevel, user);
      });
    this.masterScheduleActions.completeLoading();

    let firstRequest: boolean = true;

    this.dataSubscription = this.employees$
      .skip(1)
      .subscribe((rows: IMasterScheduleRow[]) => {
        if (!this.totalsAlreadyLoaded) {
          this.msData = new MasterScheduleData();
          this.msData.employees = this.employeesSelector.getEmployeeRows(rows);
          this.msData.totals = [
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Total', cell: {} },
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Budgeted PARs', cell: {} },
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'PAR Levels / Ideal Schedule', cell: {} },
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Open Shifts', cell: {} },
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Difference', cell: {} },
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Census', cell: {} },
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'PPD', cell: {} },
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Staffing Ratio', cell: {} },
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Actual Hours', cell: {} },
            { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Actual PPD', cell: {} }
          ];
          this.msData.subtotals = this.getFakeSubtotals(this.msData.employees, this.currentOrgLevelId, this.selectedGrouping);
        } else {
          this.msData.employees = this.employeesSelector.getEmployeeRows(rows);
        }
        this.onLoaded(this.msData);
      });

    this.totalsSubscription = this.totals$
      .skip(1)
      .withLatestFrom(this.subtotals$, this.employees$)
      .subscribe(([totals, subtotals, rows]: [IMasterScheduleRow[], IMasterScheduleRow[], IMasterScheduleRow[]]) => {
        this.totalsAlreadyLoaded = true;
        this.msData = new MasterScheduleData();
        this.msData.employees = this.employeesSelector.getEmployeeRows(rows);
        this.msData.totals = this.employeesSelector.getTotals(totals, this.getUnAccessibleTotals());
        this.msData.subtotals = this.employeesSelector.getSubtotals(subtotals);
        this.onTotalsLoaded$.next(this.msData);
      });

    this.errorSubscription = this.hasError$
      .withLatestFrom(this.errorMessage$)
      .skip(1)
      .subscribe(([hasError, errorMessage]: [boolean, string]) => {
        if (hasError) {
          console.error(`The error occured during fetch Master Schedule data ${errorMessage}`);
        }
      });
  }

  public reloadIndividualSchedule(hasChanges: boolean = false) {
    if (hasChanges) {
      this.masterScheduleToolbarService.onFiltersChanged(this.filterSettings);
    }
  }

  public getUnAccessibleTotals(): string[] {
    const unAccessibleTotals = [];
    const timeApp = this.menuAccessibleProviderService.isAccessible('Time');
    if (!timeApp) {
      unAccessibleTotals.push(...[TotalsDescription.totalActualHours, TotalsDescription.totalActualPPD]);
    }
    return unAccessibleTotals;
  }

  public onFiltersRestored(filters: MasterScheduleFilters): void {
    this.filtersRestored$.next(filters);
  }
  public getWeeksByDate(dateFrom: Date): number {
    return this.scheduleCycleHelperService.getWeeksByDate(this.cycles, dateFrom);
  }

  public generateEmpSchedule(employeeId: number, start: Date, end: Date): Promise<GenerateScheduleSummary> {
    this.masterScheduleActions.generateSchedule();
    return this.api.generateEmpScheduleAtBackend(this.currentOrgLevelId, start, employeeId)
      .then((result: GenerateScheduleSummary) => {
        this.masterScheduleActions.generateScheduleSuccess(_.first(result.schedule));
        return result;
      })
      .catch((error: any) => {
        this.masterScheduleActions.generateScheduleError(error);
        return error;
      });
  }

  public reload(masterScheduleFilters: MasterScheduleFilters, orgLevel: OrgLevel, user: IUser): void {
    if (!orgLevel || !orgLevel.id || orgLevel.type !== OrgLevelType.department) {
      return;
    }
    if (!masterScheduleFilters.dateFrom || !user) {
      return;
    }
    this.currentFilters = _.clone(masterScheduleFilters);
    this.currentOrgLevelId = orgLevel.id;
    //this.masterScheduleActions.fetchMasterScheduleData();
    this.totalsAlreadyLoaded = false;
    this.masterScheduleActions.fetchEmployeesSchedule();
    this.masterScheduleActions.fetchTotals();
  }

  public deleteScheduleForEmployee(orgLevelId: number, employeeId: number, startDate: Date, endDate: Date): void {
    this.masterScheduleActions.deleteEmployeesSchedule();
    this.api.deleteScheduleForEmployee(orgLevelId, employeeId, startDate, endDate).then((value: any) => {
      //this.masterScheduleActions.fetchMasterScheduleData();
      this.totalsAlreadyLoaded = false;
      this.masterScheduleActions.fetchEmployeesSchedule();
      this.masterScheduleActions.fetchTotals();

    }).catch((reason: any) => {
      this.masterScheduleActions.deleteEmployeesScheduleError();
      this.onError(reason);
    });
  }

  public createRotationFromSchedule(orgLevelId: number, employeeId: number, startDate: Date, weeks: number): void {
    this.masterScheduleActions.createEmployeeRotationFromSchedule();
    this.api.createRotationFromSchedule(orgLevelId, employeeId, startDate, weeks).then((value: any) => {
      //this.masterScheduleActions.fetchMasterScheduleData();
      this.totalsAlreadyLoaded = false;
      this.masterScheduleActions.fetchEmployeesSchedule();
      this.masterScheduleActions.fetchTotals();

    }).catch((reason: any) => {
      this.masterScheduleActions.createEmployeeRotationFromScheduleError();
      this.onError(reason);
    });
  }

  public updateDatesSubscription(dateFrom: Date): void {
    this.dateFrom = dateFrom;
    const cycle: ScheduleCycle = this.scheduleCycleHelperService.findScheduleCycleByDate(this.cycles, dateFrom);
    if (cycle) {
      this.firstCycleStart = cycle.startDate;
    }
    this.endDate = cycle.endDate.toDate();
    if (this.isStartCycle(dateFrom)) {
      this.onStartCycleSelect$.next(dateFrom);
    } else {
      this.onNonStartCycleSelect$.next(dateFrom);
    }
  }

  public isStartCycle(date: Date): boolean {
    const diff: number = moment(date).diff(this.firstCycleStart, 'days');
    if (diff % this.cycleDays === 0)
      return true;
    return false;
  }

  public getScheduleCycle(date: Date): ScheduleCycle {
    return this.findPayCycle(this.cycles, date);
  }

  public findPayCycle(cycles: ScheduleCycle[], date: Date): ScheduleCycle {
    const currentDate: moment.Moment = moment(date).startOf('day');
    let selectedCycle: ScheduleCycle = _.find(cycles, (cycle: ScheduleCycle) => {
      return currentDate.isSameOrAfter(cycle.startDate) && currentDate.isSameOrBefore(cycle.endDate);
    });
    return selectedCycle;
  }

  public calculateDataRow(entryRow: IMasterScheduleEntryRow): void {
    const orgLevelId: number = this.currentOrgLevelId;
    if (entryRow.primaryPosition.orgLevelId !== orgLevelId) {
      entryRow.isFromDifferntDepartment = true;
      let firstPosInDepartment = _.find(entryRow.secondaryPositions, (p: ISchedulePosition) => p.orgLevelId === orgLevelId);
      entryRow.position = EmployeesStateSelector.mapPosition(firstPosInDepartment);
      entryRow.positionGroupName = entryRow.position.positionGroupName;
    }
    if (entryRow.isFromDifferntDepartment) {
      entryRow.secondarySort = 1;
    } else {
      entryRow.secondarySort = 0;
    }
    entryRow.weeklyTotals = {};

    if (entryRow && entryRow.hireDateStr) {
      entryRow.hireDate = new Date(entryRow.hireDateStr.replace(/-/g, '/'));
    }

    /*
    if (!subtotals[entryRow.position.name]) {
      subtotals[entryRow.position.name] = new ScheduleSubtotal();
      subtotals[entryRow.position.name].position = new Position();
      subtotals[entryRow.position.name].position.id = entryRow.position.id;
      subtotals[entryRow.position.name].position.name = entryRow.position.name;
      subtotals[entryRow.position.name].position.orgLevelId = entryRow.position.orgLevelId;
      subtotals[entryRow.position.name].dayTotals = {};
    }*/

    _.forEach(entryRow.cell, (cellDay: MasterScheduleEntryCell) => {
      let d = moment(cellDay.dateOn);
      const dayName = d.format('dd');
      if (dayName === 'Sat' || dayName === 'Sun') {
        cellDay.holidayDayCode = dayName;
      } else {
        cellDay.holidayDayCode = null;
      }
      if (cellDay.isPosted) {
        entryRow.isPosted = true;
      }
      // let dateOn: string = EmployeesStateSelector.getDateKey(cellDay.dateOn);
      cellDay.isScheduledToMultipleShifts = cellDay.shiftsInfo.length > 1;

      _.forEach(cellDay.shiftsInfo, (shiftInfo: MasterScheduleShiftInfo) => {
        //badges
        if (shiftInfo.position.id !== entryRow.primaryPosition.id
          && _.some(entryRow.secondaryPositions, x => x.id === shiftInfo.position.id)
          && !shiftInfo.absenceCode) {
          cellDay.isScheduledToSecondaryPosition = true;
        }
        if (shiftInfo.constraintId) {
          cellDay.hasConstraint = true;
        }
        if (orgLevelId === shiftInfo.position.orgLevelId) {
          if (entryRow.isFromDifferntDepartment && entryRow.position.orgLevelId !== orgLevelId) {
            //need to select first another position from current department
            entryRow.position = EmployeesStateSelector.mapPosition(shiftInfo.position);
          }
          /*
          if (!subtotals[shiftInfo.position.name]) {
            subtotals[shiftInfo.position.name] = new ScheduleSubtotal();
            subtotals[shiftInfo.position.name].position = new Position();
            subtotals[shiftInfo.position.name].position.id = shiftInfo.position.id;
            subtotals[shiftInfo.position.name].position.name = shiftInfo.position.name;
            subtotals[shiftInfo.position.name].position.orgLevelId = shiftInfo.position.orgLevelId;
            subtotals[shiftInfo.position.name].dayTotals = {};
          }
          //subtotals and totals
          if (!subtotals[shiftInfo.position.name].dayTotals[dateOn]) {
            subtotals[shiftInfo.position.name].dayTotals[dateOn] = new DayTotal();
            subtotals[shiftInfo.position.name].dayTotals[dateOn].date = cellDay.dateOn;
            subtotals[shiftInfo.position.name].dayTotals[dateOn].value = 0;
          }
          subtotals[shiftInfo.position.name].dayTotals[dateOn].value += (shiftInfo.duration / 3600000);
          if (!totals.dayTotals[dateOn]) {
            totals.dayTotals[dateOn] = new DayTotal();
            totals.dayTotals[dateOn].date = cellDay.dateOn;
            totals.dayTotals[dateOn].value = 0;
          }
          totals.dayTotals[dateOn].value += (shiftInfo.duration / 3600000);
          */
        } else {
          cellDay.scheduledInDifferntDepartment = true;
        }
      });
      //weekly totals
      let week: number = Math.floor(moment(cellDay.dateOn).diff(moment(this.dateFrom), 'weeks'));
      if (!entryRow.weeklyTotals[week]) {
        entryRow.weeklyTotals[week] = 0;
      }
      const dayTotal: number = _.reduce(cellDay.shiftsInfo, (total: number, shiftInfo: MasterScheduleShiftInfo): number => {
        let countAs: number = shiftInfo.constraintId > 0 ? shiftInfo.constraintCountAs : 1;
        return total + (shiftInfo.duration / 3600000) * countAs;
      }, 0);
      entryRow.weeklyTotals[week] = entryRow.weeklyTotals[week] + dayTotal;
    });
  }
  public calculateData(data: MasterScheduleData, separateSecondaryPositions: boolean, viewTotalsFTEs: boolean, posGroupingType: IMasterScheduleGroupingType): EmployeeGridData {
    let gridData: EmployeeGridData = new EmployeeGridData();
    gridData.rows = [];
    gridData.subtotals = [];
    gridData.totals = [];
    let rowsExpanded: IMasterScheduleEntryRow[] = [];
    //if (separateSecondaryPositions) {
    //  rowsExpanded = this.expandByPosition(data.employees);
    //} else {
    rowsExpanded = data.employees;
    //}
    //calc subtotals and badges flags
    _.forEach(rowsExpanded, (entryRow: IMasterScheduleEntryRow) => {
      this.calculateDataRow(entryRow);
      gridData.rows.push(entryRow);
      if (entryRow.isPosted) {
        gridData.isPosted = true;
      }
    });

    _.forEach(data.subtotals, (subtotal: IMasterScheduleSubtotalRow) => {
      let subTotalVal = this.employeesSelector.getSubtotalAfterViewChange(subtotal, viewTotalsFTEs);
      gridData.subtotals = _.concat(gridData.subtotals, subTotalVal);
    });

    _.forEach(data.totals, (total: IMasterScheduleEntryRow) => {
      let totalVal = this.employeesSelector.getTotalAfterViewChange(total, this.getUnAccessibleTotals(), viewTotalsFTEs);
      gridData.totals = _.concat(gridData.totals, totalVal);
    });

    /*
    _.forIn(subtotals, (subtotal: ScheduleSubtotal) => {
      let subTotalRow = EmployeesStateSelector.mapMasterScheduleSubtotalRow(subtotal);
      gridData.rows.push(subTotalRow);
    });
    let totalRow = EmployeesStateSelector.mapMasterScheduleTotalRow(totals);
    gridData.totals = _.concat(data.totals, totalRow);
    */

    let eligibleSubtotals: IMasterScheduleSubtotalRow[];
    if (!posGroupingType || posGroupingType.id === MasterScheduleGroupingType.BYPOSITION) {
      eligibleSubtotals = _.filter(gridData.subtotals, (subTotalRow: IMasterScheduleSubtotalRow) => {
        return (!subTotalRow.position) || _.findIndex(gridData.rows, (row: IMasterScheduleEntryRow) => {
          return row.position.name === subTotalRow.position.name;
        }) !== -1;
      });
    } else {
      eligibleSubtotals = _.filter(gridData.subtotals, (subTotalRow: IMasterScheduleSubtotalRow) => {
        return (!subTotalRow.positionGroupName) || _.findIndex(gridData.rows, (row: IMasterScheduleEntryRow) => {
          return row.positionGroupName === subTotalRow.positionGroupName;
        }) !== -1;
      });
    }
    gridData.rows = _.concat(gridData.rows, eligibleSubtotals);
    // gridData.totals = _.concat(data.totals);
    this.onRecalculated$.next(gridData);
    return gridData;
  }

  public getBudgetedParsDetails(dateOn: Date): Promise<BudgetedParsDetailsContainer> {
    const posIds: number[] = this.currentFilters.filters.position ? [this.currentFilters.filters.position.id] : [];
    const detailsPromise: Promise<BudgetedParsDetailsContainer> = this.api.getBudgetedParsDetails(this.currentOrgLevelId, dateOn, posIds);
    const positionsPromise: Promise<BudgetedPosition[]> = this.lookupApiService.getBudgetedPositions(0, this.currentOrgLevelId);
    return Promise.all([detailsPromise, positionsPromise])
      .then((value: [BudgetedParsDetailsContainer, BudgetedPosition[]]) => {
        const container: BudgetedParsDetailsContainer = value[0];
        const posDict: NumberMap<string> = {};
        _.each(value[1], pos => posDict[pos.id] = pos.description);
        _.each(container.details, row => row.positionName = posDict[row.positionId]);
        return container;
      });
  }

  public selectEmployeeCell(cellInfo: IMasterScheduleCell) {
    this.onEmployeeCellSelected$.next(cellInfo);
  }

  private getFakeSubtotals(employeeScheduleRows: IMasterScheduleEntryRow[], orgLevelId: number, grouping: IMasterScheduleGroupingType): IMasterScheduleSubtotalRow[] {
    let allEmployeePrimaryPositions: any[] = _.filter(_.map(employeeScheduleRows, (row: any) => {
      let positionGroupPair: any = {};
      positionGroupPair.position = row.position;
      positionGroupPair.positionGroupName = row.positionGroupName;
      positionGroupPair.row = row;
      return positionGroupPair;
    }), (pos: any) => pos.position.orgLevelId === orgLevelId);
    let allSecondaryPositions: any[] =
      _.filter(_.flatten(
        _.map(employeeScheduleRows, toFlat =>
          _.map(toFlat.secondaryPositions, (secPos => {
            let positionGroupPair: any = {};
            positionGroupPair.position = secPos;
            positionGroupPair.positionGroupName = toFlat.positionGroupName;
            return positionGroupPair;
          }))
        )
      ), (pos: any) => pos.position.orgLevelId === orgLevelId);
    let allEmployeePositions: any[] = [].concat(allEmployeePrimaryPositions, allSecondaryPositions);

    let groupedEmployeePositions: any;
    if (grouping.id === MasterScheduleGroupingTypes.ByPositionGroup.id) {
      groupedEmployeePositions = _.groupBy(allEmployeePositions, positionGroupPair => positionGroupPair.positionGroupName);
    } else {
      groupedEmployeePositions = _.groupBy(allEmployeePositions, positionGroupPair => positionGroupPair.position.id);
    }

    let uniqueEmployeePositions: any = _.map(groupedEmployeePositions, (positions, positionId) => positions[0]);

    let rows: IMasterScheduleSubtotalRow[] = [];
    let row: IMasterScheduleSubtotalRow;
    _.forIn(uniqueEmployeePositions, (e: any, key: string) => {
      row = this.getFakeSubtotalRow(e);
      rows.push(row);
    });
    return rows;
  }

  public getFakeSubtotalRow(e: any): MasterScheduleSubtotalRow {
    let row = new MasterScheduleSubtotalRow();
    row.id = 0;
    row.name = 'Subtotal';
    row.recordType = MasterScheduleRecordType.Subtotal;
    row.sortSequence = 0;
    row.positionGroupName = e.positionGroupName;
    row.position = e.position ? EmployeesStateSelector.mapPosition(e.position) : null;
    row.cell = {};
    return row;
  }
  private expandByPosition(rows: IMasterScheduleEntryRow[]): IMasterScheduleEntryRow[] {
    let rowsExpandedMap: NumberMap<NumberMap<MasterScheduleEntryRow>> = {};
    _.forEach(rows, (entryRow: IMasterScheduleEntryRow) => {
      if (!rowsExpandedMap[entryRow.id]) {
        rowsExpandedMap[entryRow.id] = {};
      }
      rowsExpandedMap[entryRow.id][entryRow.position.id] = EmployeesSelector.restoreMasterScheduleEntryRow(entryRow);
      rowsExpandedMap[entryRow.id][entryRow.position.id].secondarySort = 0;
      _.forEach(entryRow.cell, (cellDay: MasterScheduleEntryCell) => {
        let cellMap: NumberMap<MasterScheduleEntryCell> = {};
        _.forEach(cellDay.shiftsInfo, (shiftInfo: MasterScheduleShiftInfo) => {
          if (!rowsExpandedMap[entryRow.id][shiftInfo.position.id]) {
            rowsExpandedMap[entryRow.id][shiftInfo.position.id] = EmployeesSelector.restoreMasterScheduleEntryRow(entryRow);
            rowsExpandedMap[entryRow.id][shiftInfo.position.id].position = EmployeesStateSelector.mapPosition(shiftInfo.position);
            rowsExpandedMap[entryRow.id][shiftInfo.position.id].secondarySort = 1;
          }
          let day: string = EmployeesStateSelector.getDateKey(cellDay.dateOn);
          if (!cellMap[shiftInfo.position.id]) {
            cellMap[shiftInfo.position.id] = EmployeesSelector.restoreMasterScheduleEntryCell(cellDay);
            rowsExpandedMap[entryRow.id][shiftInfo.position.id].cell[day] = cellMap[shiftInfo.position.id];
            if (shiftInfo.position.id !== entryRow.position.id && _.some(entryRow.secondaryPositions, x => x.id === shiftInfo.position.id)) {
              //if employee scheduled on secondary position we should add cell to show budge
              if (!rowsExpandedMap[entryRow.id][entryRow.position.id].cell[day]) {
                rowsExpandedMap[entryRow.id][entryRow.position.id].cell[day] = EmployeesSelector.restoreMasterScheduleEntryCell(cellDay);
                rowsExpandedMap[entryRow.id][entryRow.position.id].cell[day].shiftsInfo = [];
              }
              rowsExpandedMap[entryRow.id][entryRow.position.id].cell[day].isScheduledToSecondaryPosition = true;
            }
          }
          let info = EmployeesSelector.restoreMasterScheduleShiftInfo(shiftInfo);
          rowsExpandedMap[entryRow.id][shiftInfo.position.id].cell[day].shiftsInfo.push(info);
        });
      });
    });
    let rowsExpanded: IMasterScheduleEntryRow[] = [];
    _.forIn(rowsExpandedMap, (em: NumberMap<IMasterScheduleEntryRow>) => {
      _.forIn(em, (r: IMasterScheduleEntryRow) => {
        rowsExpanded.push(r);
      });
    });
    return rowsExpanded;
  }

}
