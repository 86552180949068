import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeSectionsPersonalMapService } from './employee-section-personal-map.service';
import { EmployeeSectionsPersonal, EmployeeSectionsProfile, EmployeeSectionsContacts, EmployeeSectionsLicense, IEmployeeSectionsPersonal, IEmployeeSectionsProfile, IEmployeeSectionsContacts, IEmployeeSectionsLicense, IEmployeeSectionsLicenses, EmployeeSectionsLicenses, EmployeeSectionsPhysicals, IEmployeeSectionsPhysicalGroup, IEmployeeSectionsPhysicals, IEmployeeSectionsDocuments, EmployeeSectionsDocuments, EmployeeDocument, ISaveEmployeeDocumentResponse, IEmployeeSectionsBackgroundChecks, EmployeeSectionsBackgroundCheck, EmployeeSectionsCertification, IEmployeeSectionsCertifications, IEmployeeSectionsEducations, EmployeeSectionsEducation, IEmployeeSubsectionWarnings, EmployeeSubsectionWarnings, IEmployeeWarningExtended, EmployeeWarningExtended } from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { LookupApiService } from '../../../../organization/services';
import { AgencyCertificate, IAgencyCertificates } from '../../models/employee-sections-personal/employee-sections-agency-certificate';
var EmployeeSectionsPersonalApiService = /** @class */ (function () {
    function EmployeeSectionsPersonalApiService(lookupService, employeeSectionsPersonalMapService, apiUtilService, urlParamsService) {
        this.lookupService = lookupService;
        this.employeeSectionsPersonalMapService = employeeSectionsPersonalMapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    EmployeeSectionsPersonalApiService.prototype.getPersonalSection = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, veteranStatuses, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Assert.isNotNull(employeeId, 'employeeId');
                        url = "" + this.getPersonalApiRoot(employeeId);
                        request = new HttpRequest('GET', url);
                        return [4 /*yield*/, this.lookupService.getVeteranStatuses()];
                    case 1:
                        veteranStatuses = _a.sent();
                        promise = this.apiUtilService.request(request)
                            .then(function (response) {
                            return _this.employeeSectionsPersonalMapService.mapToSectionsPersonal(response.data, veteranStatuses);
                        });
                        return [2 /*return*/, promise];
                }
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.getPersonalPhysicals = function (employeeId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.physicals;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            return _this.employeeSectionsPersonalMapService.mapToPhysicals(response.data, response.meta);
        });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.setPersonalPhysicals = function (employeeId, physicals, effectiveDate) {
        Assert.isNotNull(employeeId, 'employeeId');
        Assert.isNotNull(physicals, 'physicals');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.physicals;
        var dphysicals = this.employeeSectionsPersonalMapService.mapToPhysicalsDto(physicals);
        var body = {
            physicalsSubsection: dphysicals,
            effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate),
            metadata: physicals.fieldsMeta
        };
        var params = this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
        var request = new HttpRequest('PUT', url, body, {
            params: params
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.status; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.getPersonalLicenses = function (employeeId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.licenses;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            return _this.employeeSectionsPersonalMapService.mapToLicenses(response.data, response.meta);
        });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.setPersonalLicenses = function (employeeId, licenses, fieldsMeta) {
        Assert.isNotNull(employeeId, 'employeeId');
        Assert.isNotNull(licenses, 'licenses');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.licenses;
        var dlicenses = this.employeeSectionsPersonalMapService.mapToLicensesDto(licenses);
        var body = {
            licensesSubsection: { licenses: dlicenses },
            metadata: fieldsMeta
        };
        var request = new HttpRequest('PUT', url, body);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.status; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.getPersonalEmergencyContacts = function (employeeId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.emergencyContacts;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            return _this.employeeSectionsPersonalMapService.mapToEmergencyContacts(response.data, response.meta);
        });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.getAgencyCertificateDetails = function (employeeId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.dnr;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            return _this.employeeSectionsPersonalMapService.mapToAgencyCertificate(response.data);
        });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.setPersonalEmergencyContacts = function (employeeId, emergencyContacts) {
        Assert.isNotNull(employeeId, 'employeeId');
        Assert.isNotNull(emergencyContacts, 'emergencyContacts');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.emergencyContacts;
        var demergencyContacts = this.employeeSectionsPersonalMapService.mapToEmergencyContactsDto(emergencyContacts);
        var request = this.urlParamsService.createPutRequest(url, {
            emergencyContactsSubsection: demergencyContacts,
            metadata: emergencyContacts.fieldsMeta
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.status; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.getPersonalContacts = function (employeeId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.contacts;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            return _this.employeeSectionsPersonalMapService.mapToContacts(response.data, response.meta);
        });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.setPersonalContacts = function (employeeId, contacts, effectiveDate) {
        Assert.isNotNull(employeeId, 'employeeId');
        Assert.isNotNull(contacts, 'contacts');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.contacts;
        var dcontacts = this.employeeSectionsPersonalMapService.mapToContactsDto(contacts);
        var body = {
            contactsInformationSubsection: dcontacts,
            metadata: contacts.fieldsMeta
        };
        var params = this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
        var request = new HttpRequest('PUT', url, body, {
            params: params
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.status; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.getPersonalProfile = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, veteranStatuses, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Assert.isNotNull(employeeId, 'employeeId');
                        url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.profile;
                        request = new HttpRequest('GET', url);
                        return [4 /*yield*/, this.lookupService.getVeteranStatuses()];
                    case 1:
                        veteranStatuses = _a.sent();
                        promise = this.apiUtilService.request(request)
                            .then(function (response) {
                            return _this.employeeSectionsPersonalMapService.mapToProfile(response.data, response.meta, veteranStatuses);
                        });
                        return [2 /*return*/, promise];
                }
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.setPersonalProfile = function (employeeId, profile, effectiveDate) {
        Assert.isNotNull(employeeId, 'employeeId');
        Assert.isNotNull(profile, 'profile');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.profile;
        var dprofile = this.employeeSectionsPersonalMapService.mapToProfileDto(profile);
        var body = {
            profileSubsection: dprofile,
            metadata: profile.fieldsMeta
        };
        var params = this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
        var request = new HttpRequest('PUT', url, body, {
            params: params
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.status; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.getPersonalDocuments = function (employeeId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.documents;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            return _this.employeeSectionsPersonalMapService.mapToDocuments(response.data, response.meta);
        });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.getDocumentFile = function (documentId) {
        Assert.isNotNull(documentId, 'documentId');
        var url = this.getDocumentApiRoot() + "/" + documentId;
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.requestForFile(request)
            .then(function (response) { return response; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.addDocument = function (document, employeeId) {
        Assert.isNotNull(document, 'document');
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getDocumentApiRoot(employeeId);
        var formData = this.employeeSectionsPersonalMapService.mapToDocumentDtoFormData(document);
        var request = this.urlParamsService.requestPost(url, formData);
        request.autoContentType = true;
        var promise = this.apiUtilService.requestNew(request, false)
            .then(function (response) { return response.data; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.editDocument = function (document, employeeId) {
        Assert.isNotNull(document, 'document');
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getDocumentApiRoot(employeeId);
        var formData = this.employeeSectionsPersonalMapService.mapToDocumentDtoFormData(document);
        var request = this.urlParamsService.requestPut(url, formData);
        request.autoContentType = true;
        var promise = this.apiUtilService.requestNew(request, false)
            .then(function (response) { return response.data; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.deleteDocument = function (documentId, employeeId) {
        Assert.isNotNull(documentId, 'documentId');
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getDocumentApiRoot(employeeId) + "/" + documentId;
        var request = this.urlParamsService.createDeleteRequest(url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.status; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.getBackgroundChecks = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getBackgroundCheckApiRoot(employeeId);
                request = new HttpRequest('GET', url);
                promise = this.apiUtilService.request(request)
                    .then(function (response) {
                    return _this.employeeSectionsPersonalMapService.mapToBackgroundChecks(response.data, response.meta);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.setBackgroundCheck = function (backgroundCheck, employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, certification, request, promise;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(backgroundCheck, 'backgroundCheck');
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getBackgroundCheckApiRoot(employeeId);
                certification = this.employeeSectionsPersonalMapService.mapToBackgroundCheck(backgroundCheck);
                request = new HttpRequest('PUT', url, certification);
                promise = this.apiUtilService.request(request)
                    .then(function (response) { return response.status; });
                return [2 /*return*/, promise];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.deleteBackgroundCheck = function (backgroundCheck, employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(backgroundCheck, 'certificate');
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getBackgroundCheckApiRoot(employeeId);
                request = this.urlParamsService.createDeleteRequest(url, undefined, backgroundCheck);
                return [2 /*return*/, this.apiUtilService.request(request)];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.getCertifications = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getCertificationsApiRoot(employeeId);
                request = new HttpRequest('GET', url);
                promise = this.apiUtilService.request(request)
                    .then(function (response) {
                    return _this.employeeSectionsPersonalMapService.mapToCertifications(response.data, response.meta);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.setCertificate = function (certificate, employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, certification, request, promise;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(certificate, 'certificate');
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getCertificationsApiRoot(employeeId);
                certification = this.employeeSectionsPersonalMapService.mapToCertificate(certificate);
                request = new HttpRequest('PUT', url, certification);
                promise = this.apiUtilService.request(request)
                    .then(function (response) { return response.status; });
                return [2 /*return*/, promise];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.deleteCertificate = function (certificate, employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(certificate, 'certificate');
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getCertificationsApiRoot(employeeId);
                request = this.urlParamsService.createDeleteRequest(url, undefined, certificate);
                return [2 /*return*/, this.apiUtilService.request(request)];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.getEducations = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getEducationsApiRoot(employeeId);
                request = new HttpRequest('GET', url);
                promise = this.apiUtilService.request(request)
                    .then(function (response) {
                    return _this.employeeSectionsPersonalMapService.mapToEducations(response.data, response.meta);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.setEducation = function (education, employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, edu, request, promise;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(education, 'education');
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getEducationsApiRoot(employeeId);
                edu = this.employeeSectionsPersonalMapService.mapToEducation(education);
                request = new HttpRequest('PUT', url, edu);
                promise = this.apiUtilService.request(request)
                    .then(function (response) { return response.status; });
                return [2 /*return*/, promise];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.deleteEducation = function (education, employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(education, 'education');
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getEducationsApiRoot(employeeId);
                request = this.urlParamsService.createDeleteRequest(url, undefined, education);
                return [2 /*return*/, this.apiUtilService.request(request)];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.getEmployeeWarnings = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(employeeId, 'employeeId');
                url = this.getEmpWarningApiRoot(employeeId);
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return _this.employeeSectionsPersonalMapService.mapToSubsectionWarnings(response.data, response.meta);
                    })];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.getEmployeeWarning = function (warningId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getWarningApiRoot(warningId);
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return _this.employeeSectionsPersonalMapService.mapToWarningExtended(response.data);
                    })];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.getEmployeeWarningTemplate = function (empId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getNewEmpWarningApiRoot(empId);
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return _this.employeeSectionsPersonalMapService.mapToWarningExtended(response.data);
                    })];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.downloadWarning = function (warningId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getDownloadWarningApiRoot(warningId);
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.requestForFile(request)
                        .then(function (file) { return file; })];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.addEmployeeWarning = function (warning, empId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getEmpWarningApiRoot(empId);
                request = this.urlParamsService.createPostRequest(url, this.employeeSectionsPersonalMapService.mapToDtoWarningExtended(warning));
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return _this.employeeSectionsPersonalMapService.mapToWarningExtended(response.data);
                    })];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.editEmployeeWarning = function (warning, empId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getEmpWarningApiRoot(empId);
                request = this.urlParamsService.createPutRequest(url, this.employeeSectionsPersonalMapService.mapToDtoWarningExtended(warning));
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return _this.employeeSectionsPersonalMapService.mapToWarningExtended(response.data);
                    })];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.saveAgencyCertificate = function (agencyCertificate, employeeId) {
        var url = this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.dnr;
        var request = new HttpRequest('POST', url, agencyCertificate);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
        return promise;
    };
    EmployeeSectionsPersonalApiService.prototype.deleteEmployeeWarning = function (warningId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getWarningApiRoot(warningId);
                request = this.urlParamsService.createDeleteRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return response.data; })];
            });
        });
    };
    EmployeeSectionsPersonalApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version + "/" + employeeSectionsConfig.api.employees.root;
    };
    EmployeeSectionsPersonalApiService.prototype.getSectionsApiRoot = function (employeeId) {
        return this.getApiRoot() + "/" + employeeId + "/" + employeeSectionsConfig.api.employees.sections.root;
    };
    EmployeeSectionsPersonalApiService.prototype.getPersonalApiRoot = function (employeeId) {
        return this.getSectionsApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.root;
    };
    EmployeeSectionsPersonalApiService.prototype.getBackgroundCheckApiRoot = function (employeeId) {
        return this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.backgroundcheck;
    };
    EmployeeSectionsPersonalApiService.prototype.getDocumentApiRoot = function (employeeId) {
        if (employeeId === void 0) { employeeId = null; }
        var url = "" + this.getApiRoot();
        if (employeeId) {
            url += "/" + employeeId;
        }
        url += "/" + employeeSectionsConfig.api.employees.documents;
        return url;
    };
    EmployeeSectionsPersonalApiService.prototype.getCertificationsApiRoot = function (employeeId) {
        return this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.certifications;
    };
    EmployeeSectionsPersonalApiService.prototype.getEducationsApiRoot = function (employeeId) {
        return this.getPersonalApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.personal.education;
    };
    EmployeeSectionsPersonalApiService.prototype.getWarningApiRoot = function (warningId) {
        return this.getApiRoot() + "/" + employeeSectionsConfig.api.employees.sections.personal.warnings + "/" + warningId;
    };
    EmployeeSectionsPersonalApiService.prototype.getEmpWarningApiRoot = function (empId) {
        return this.getApiRoot() + "/" + empId + "/" + employeeSectionsConfig.api.employees.sections.personal.warnings;
    };
    EmployeeSectionsPersonalApiService.prototype.getNewEmpWarningApiRoot = function (empId) {
        return this.getApiRoot() + "/" + empId + "/" + employeeSectionsConfig.api.employees.sections.personal.warnings + "/" + employeeSectionsConfig.api.employees.sections.personal.newWarning;
    };
    EmployeeSectionsPersonalApiService.prototype.getDownloadWarningApiRoot = function (warningId) {
        return this.getWarningApiRoot(warningId) + "/" + employeeSectionsConfig.api.employees.sections.personal.downloadWarning;
    };
    return EmployeeSectionsPersonalApiService;
}());
export { EmployeeSectionsPersonalApiService };
