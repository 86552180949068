import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const SSN_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SsnValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[ssn][formControlName],[ssn][formControl],[ssn][ngModel]',
  /* tslint:enable */
  providers: [SSN_VALIDATOR]
})
export class SsnValidator implements Validator {
  public validate(c: AbstractControl): {[key: string]: any} {
    return CommonValidators.ssn(c);
  }
}
