import { IBaseSelectableModel } from '../../../common/models/kendo-grid-helpers/base-selectable-model';

export interface IRoleDefinition {
  id: number;
  name: string;
  assignedCount: number;
  lastUpdateDate: string;
  lastUpdateUsername: string;
}

export class RoleDefinition implements IBaseSelectableModel {

  public id: number;
  public name: string;
  public assignedCount: number = 0;
  public lastUpdateDate: Date;
  public lastUpdateUsername: string;

  public isSelected: boolean;
  public selectable: boolean = true;
}
