import {
    EmployeeDefinition, IEmployeeDefinition, Position, IPosition
} from '../../../organization/models/index';

export interface IDepartureDetails {
  employee: IEmployeeDefinition;
  position: IPosition;
  scheduleStart: string;
  scheduleEnd: string;
  actualStart: string;
  actualEnd: string;
  isScheduled: boolean;
  isOut: boolean;
  isDiff: boolean;
  isInOvertime: boolean;
}

export class DepartureDetails {
  public employee: EmployeeDefinition;
  public position: Position;
  public scheduleStart: Date;
  public scheduleEnd: Date;
  public actualStart: Date;
  public actualEnd: Date;
  public isScheduled: boolean;
  public isOut: boolean;
  public isDiff: boolean;
  public isInOvertime: boolean;
}
