import { WcCodeDefinition } from './wc-code-definition';

export class ReportCommonFields {

    // injury <> osha
    public whatDoingDuringInjury: string;
    public howInjuryOccur: string;

    // osha <> compensations
    public caseNumber: string;
    public bodyPart: WcCodeDefinition;
    public empBeganWork: Date;
    public injuryOccured: WcCodeDefinition;
    public dateOfDeath: Date;
    public dateErNotifiedDeath: Date;
    public whatDoingBefore: string;
    public activityDescription: string;
    public whatHarmed: string;
    public wasTreatedToEmergencyRoom: boolean;
    public wasHospitalized: boolean;
    public awayTreatment: string;

    // view model helpers
    public isDead: boolean;

}