import { TimeclockAssignmentRestriction } from '../../../../employee/employee-sections/models/index';
import { Component, OnInit, OnDestroy, Input, Provider, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';

import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimeclockAssignmentManagementService } from '../../../services/index';

import {
  EmployeeDefinition, TimeclockDefinition, ITimeclockRestrictionDefinition
} from '../../../../organization/models/index';

import {
  TimeclockAssignmentEmployee, TimeclockAssignment, TimeclockRestriction
} from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-assignment-timeclocks',
  templateUrl: 'timeclock-assignment-timeclocks.component.html',
  styleUrls: ['timeclock-assignment-timeclocks.component.scss']
})
export class TimeclockAssignmentTimeclocksComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public records: TimeclockAssignmentRestriction[];
  @Input()
  public employee: TimeclockAssignmentEmployee;
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<TimeclockAssignmentRestriction>;
  public prevRestrictionId: number;
  public restrictionChanged: boolean;
  public get isEditMode(): boolean {
    return _.isNumber(this.gridState.editedRowIndex);
  }

  private timeclockAssignmentManagementService: TimeclockAssignmentManagementService;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;

  constructor(timeclockAssignmentManagementService: TimeclockAssignmentManagementService) {
    this.timeclockAssignmentManagementService = timeclockAssignmentManagementService;
    this.gridState = new KendoGridStateHelper<TimeclockAssignmentRestriction>();
    this.restrictionChanged = false;
    this.prevRestrictionId = null;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['records']) {
      this.refreshGrid();
    }
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
    this.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<TimeclockAssignmentRestriction>): void => {
      const item: TimeclockAssignmentRestriction = event.dataItem;
      const assignment: TimeclockAssignment[] = this.makeTimeclocksAssignment(this.employee.employee, item.timeclock, item.restriction);
      this.timeclockAssignmentManagementService.onReAssign(assignment);
      this.restrictionChanged = false;
    });
    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<TimeclockAssignmentRestriction>): void => {
      const item: TimeclockAssignmentRestriction = event.dataItem;
      const assignment: TimeclockAssignment[] = this.makeTimeclocksAssignment(this.employee.employee, item.timeclock, item.restriction);
      this.timeclockAssignmentManagementService.onUnAssign(assignment);
      _.remove(this.records, (record: TimeclockAssignmentRestriction) => record.timeclock.id === item.timeclock.id);
    });
    this.gridEditSubscription = this.gridState.onEdit$.subscribe((item: TimeclockAssignmentRestriction): number => this.prevRestrictionId = item.restriction.id);
    this.gridCancelSubscription = this.gridState.onCancel$.subscribe((): boolean => this.restrictionChanged = false);
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onRestrictionChanged(restriction: ITimeclockRestrictionDefinition): void {
    this.restrictionChanged = restriction.id !== this.prevRestrictionId;
  }

  public makeTimeclocksAssignment(employee: EmployeeDefinition, timeclock: TimeclockDefinition, restriction: ITimeclockRestrictionDefinition): TimeclockAssignment[] {
    const timeclockRestriction: TimeclockRestriction = new TimeclockRestriction();
    timeclockRestriction.timeclock = timeclock;
    timeclockRestriction.restriction = restriction;

    const assignment: TimeclockAssignment = new TimeclockAssignment();
    assignment.employee = employee;
    assignment.timeclockRestrictions = [timeclockRestriction];

    return [assignment];
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}

