var RoleField = /** @class */ (function () {
    function RoleField() {
    }
    Object.defineProperty(RoleField.prototype, "isDirty", {
        get: function () {
            return this.initial_hidden !== this.hidden ||
                this.initial_editable !== this.editable ||
                this.initial_masked !== this.masked;
        },
        enumerable: true,
        configurable: true
    });
    RoleField.prototype.setInitialState = function () {
        this.initial_hidden = this.hidden;
        this.initial_editable = this.editable;
        this.initial_masked = this.masked;
    };
    return RoleField;
}());
export { RoleField };
