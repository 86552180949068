import { ReportParameter, IReportParameter } from './report-parameter';
import { ReportExportType } from './report-export-type';

export interface IReportDefinition {
  group: string;
  folder: string;
  name: string;
  displayName: string;
  pageSize: string;
  pageLayout: string;
  reportConfigId: number;
  parameters: IReportParameter[];
  exportType: ReportExportType;
}

export class ReportDefinition {
  group: string;
  folder: string;
  name: string;
  displayName: string;
  pageSize: string;
  pageLayout: string;
  reportConfigId: number;
  parameters: ReportParameter[];
  exportType: ReportExportType;
}
export class ConfigForceLoad {
  dailyAttendance: boolean;
  todaysStaffing: boolean;
  dailyStaffingPosting: boolean;
  midnightToMidnightReportConfig: boolean;
}

export class IGetBIPAReportDefinition {
  showShiftsOnBipaReportKey: string;
  showUnitsOnBipaReportKey: string;
  showShiftsOnBipaReportValue: string;
  showUnitsOnBipaReportValue: string;
  shiftgroups: BIPAShiftGroupTime[];
  slxCmsPositions: BIPATableData[];
}

export class GetBIPAReportDefinition {
  showShiftsOnBipaReportKey: string;
  showUnitsOnBipaReportKey: string;
  showShiftsOnBipaReportValue: boolean;
  showUnitsOnBipaReportValue: boolean;
  shiftgroups: BIPAShiftGroupTime[];
  slxCmsPositions: BIPATableData[];
}

export class BIPAShiftGroupTime {
  shiftType: string;
  shiftStartTime: string;
}

export class BIPATableData {
  slxPosition: string;
  cmsJobDescription: string;
}

export class BIPAReportDefinition {
  unitShiftData: any[];
  shiftGroupTimingS: any[];
  gridData: any[];
}

export class BIPAUnitShiftData {
  showUnitsOnBipaReport: boolean;
  showShiftsOnBipaReport: boolean;
  showShiftsOnBipaReportKey: string;
  showShiftsOnBipaReportValue: string;
  showUnitsOnBipaReportKey: string;
  showUnitsOnBipaReportValue: string;
}

export class BIPAReportData {
  showShiftsOnBipaReport: boolean;
  showUnitsOnBipaReport: boolean;
  shiftgroups: ShiftGroups[];
  slxCMSPositions: SlxCmsPosition[];
}

export class ShiftGroups {
  shiftType: string;
  shiftStartTime: string;
}

export class SlxCmsPosition {
  slxPosition: string;
  cmsJobDescription: string;
}

export class PostBIPAReportData {
  orgLevelId: number;
  showShiftsOnBipaReport: boolean;
  showUnitsOnBipaReport: boolean;
  ShowShiftsOnBipaReportKey: string;
  ShowShiftsOnBipaReportValue: string;
  ShowUnitsOnBipaReportKey: string;
  ShowUnitsOnBipaReportValue: string;
}

export class MidnightToMidnightReportData {
  orgLevelId: number;
  gridData: MidnightToMidnightReport[];
}

export interface IMidnightToMidnightReport{
  jobcode: number;
  departmentId: number;
  department: string;
  position: string;
  budgetedPosition: string;
  hourPercentage: number;
}

export class MidnightToMidnightReport{
  id?: string;
  jobcode: number;
  departmentId: number;
  department: string;
  position: string;
  budgetedPosition: string;
  hourPercentage: number;
  editPermission: boolean;
}

export interface IMidnightToMidnightRequestData {
  jobcode: number;
  value: number;
}