export interface IOrganization {
  id: number;
  orgLevelId: number;
  name: string;
}

export class Organization {
  public id: number;
  public orgLevelId: number;
  public name: string;
}

