/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./client-message.component";
import * as i4 from "../../services/client-message/client-message-management.service";
var styles_ClientMessageComponent = [i0.styles];
var RenderType_ClientMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientMessageComponent, data: {} });
export { RenderType_ClientMessageComponent as RenderType_ClientMessageComponent };
function View_ClientMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "client-message"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "client-message-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "client-message-text"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "close-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeMessage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-exclamation-triangle client-message-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "span", [["class", "client-message-icon-border"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "client-message"; var currVal_1 = _v.context.$implicit.level; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.text; _ck(_v, 6, 0, currVal_3); }); }
export function View_ClientMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "client-messages-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientMessageComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ClientMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-client-message", [], null, null, null, View_ClientMessageComponent_0, RenderType_ClientMessageComponent)), i1.ɵdid(1, 114688, null, 0, i3.ClientMessageComponent, [i4.ClientMessageManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientMessageComponentNgFactory = i1.ɵccf("slx-client-message", i3.ClientMessageComponent, View_ClientMessageComponent_Host_0, {}, {}, []);
export { ClientMessageComponentNgFactory as ClientMessageComponentNgFactory };
