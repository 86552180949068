import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Assert } from '../../../../framework/assert/assert';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { CalendarDataService } from '../../../../core/services/index';
import { DailyData, WeeklyData } from '../../../../core/models/index';

import {
  OpenShiftShortDetailsSetWrapper, OpenShiftShortDetailsWrapper,
  OpenShiftShortDetails, IOpenShiftCountChangedEventArgs,
  IOpenShiftMessageCountChangedEventArgs, IOpenShiftDailySDetailsSelectedArgs, IPeriodLength, IPartnerShiftCountChangedEventArgs
} from '../../../models/index';
import { OpenShiftManagementApiService, OpenShiftManagementManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-open-shift-management-schedule-cycle',
  templateUrl: 'open-shift-management-schedule-cycle.component.html',
  styleUrls: ['open-shift-management-schedule-cycle.component.scss']
})
export class OpenShiftManagementScheduleCycleComponent implements OnInit, OnDestroy {
  @Input()
  public set summaryDetailsSet(value: OpenShiftShortDetailsSetWrapper) {
    this.m_summaryDetailsSet = value;
    this.initializeView();
  }

  public get summaryDetailsSet(): OpenShiftShortDetailsSetWrapper {
    return this.m_summaryDetailsSet;
  }

  @Output()
  public onSummaryItemSelected: EventEmitter<OpenShiftShortDetailsWrapper>;

  @Output()
  public isPartnerShiftCountUpdated:EventEmitter<boolean>;

  public appConfig: IApplicationConfig;
  public filterString: string;
  public filter: string;
  public selectedPeriod: IPeriodLength;
  public set dayColumns(value: DailyData<any>[]) {
    this.m_dayColumns = value;
  }

  public get dayColumns(): DailyData<any>[] {
    return this.m_dayColumns;
  }

  public state: {
    isLoading: boolean;
  };

  private m_summaryDetailsSet: OpenShiftShortDetailsSetWrapper;
  private m_dayColumns: DailyData<any>[];
  private selectedSummaryDetailsItem: OpenShiftShortDetailsWrapper;
  private onMessageCountChanged: Subscription;
  private onShiftCountChanged: Subscription;
  private onSelectedDailyDetailsChnged: Subscription;
  private onPartnerShiftCountChanged: Subscription;

  constructor(
    private calendarDataService: CalendarDataService,
    private openShiftManagementManagementService: OpenShiftManagementManagementService) {
    this.onSummaryItemSelected = new EventEmitter<OpenShiftShortDetailsWrapper>();
    this.isPartnerShiftCountUpdated= new EventEmitter<boolean>();
    this.onMessageCountChanged = this.openShiftManagementManagementService.onMessageCountChanged$.subscribe(
      (args: IOpenShiftMessageCountChangedEventArgs) => this.onMessageActionExecuted(args));
    this.onShiftCountChanged = this.openShiftManagementManagementService.onOpenShiftCountChanged$.subscribe(
      (args: IOpenShiftCountChangedEventArgs) => this.onOpenShiftsCountChanged(args));
    this.onSelectedDailyDetailsChnged = this.openShiftManagementManagementService.selectOpenShiftsDailyDetails$.subscribe(
      (args: IOpenShiftDailySDetailsSelectedArgs) => this.onOpenShiftSelectedDailyDetailsChnged(args));
    this.onPartnerShiftCountChanged = this.openShiftManagementManagementService.onPartnerShiftCountChanged$.subscribe(
        (args: IPartnerShiftCountChangedEventArgs) => this.onPartnerShiftActionExecuted(args));
      
    this.state = {
      isLoading: false
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public ngOnDestroy(): void {
    this.onMessageCountChanged.unsubscribe();
    this.onShiftCountChanged.unsubscribe();
  }

  public onSummaryItemSelectedHandler(event: OpenShiftShortDetailsWrapper): void {
    if (!event) return;
    if (this.selectedSummaryDetailsItem)
      this.selectedSummaryDetailsItem.selected = false;

    this.selectedSummaryDetailsItem = event;
    this.selectedSummaryDetailsItem.selected = true;
    this.emitRequest(this.selectedSummaryDetailsItem);
  }

  private initializeView(): void {
    if (this.summaryDetailsSet && this.summaryDetailsSet.weeklyData) {
      let firstWeek: WeeklyData<OpenShiftShortDetailsWrapper> = _.first(this.summaryDetailsSet.weeklyData);
      if (firstWeek && firstWeek.days) {
        let firstDayOfTheWeek: DailyData<any> = firstWeek.days[0];
        if (firstDayOfTheWeek) {
          this.dayColumns = this.calendarDataService.ExtendRangeToDailyData(moment(firstDayOfTheWeek.startOfDay), 7, null);
        }
      }
    }
  }


  private emitRequest(summaryWrap: OpenShiftShortDetailsWrapper): void {
    this.onSummaryItemSelected.emit(summaryWrap);
  }

  private onMessageActionExecuted(args: IOpenShiftMessageCountChangedEventArgs): any {
    Assert.isNotNull(args, 'args');
    let detailsItem: OpenShiftShortDetails = this.getDailyDetailsByDate(args.dateOn);

    if (detailsItem) {
      detailsItem.messageCount -= args.messageCountDiff;
    }
  }

  private onPartnerShiftActionExecuted(args: IPartnerShiftCountChangedEventArgs): any {
    Assert.isNotNull(args, 'args');
    this.isPartnerShiftCountUpdated.emit(true);
    let detailsItem: OpenShiftShortDetails = this.getDailyDetailsByDate(args.dateOn);
    this.selectedSummaryDetailsItem.selected = true;   
    this.emitRequest(this.selectedSummaryDetailsItem);
    if (detailsItem) {
      detailsItem.partnerShiftCount -= args.partnerShiftCountDiff;
    }
  }

  private onOpenShiftSelectedDailyDetailsChnged(args: IOpenShiftDailySDetailsSelectedArgs): any {
    Assert.isNotNull(args, 'args');
    this.onSummaryItemSelectedHandler(args.dailySummaryDetails);
  }

  private onOpenShiftsCountChanged(args: IOpenShiftCountChangedEventArgs): any {
    Assert.isNotNull(args, 'args');
    let detailsItem: OpenShiftShortDetails = this.getDailyDetailsByDate(args.dateOn);

    if (detailsItem) {
      detailsItem.openShiftCount -= args.shiftCountDiff;
    }
  }

  private getDailyDetailsByDate(dateOn: Date): OpenShiftShortDetails {
    let details: OpenShiftShortDetails[] = this.flatEntries();
    let detailsItem: OpenShiftShortDetails = _.find(details, (item: OpenShiftShortDetails) => {
      return item.dateOn === dateOn;
    });
    return detailsItem;
  }

  private flatEntries(): OpenShiftShortDetails[] {
    if (!this.summaryDetailsSet) return [];
    let rawEntries: OpenShiftShortDetails[] =
      _.reduce(this.summaryDetailsSet.weeklyData, (dailyRecordsResult: OpenShiftShortDetails[], weeklyData: WeeklyData<OpenShiftShortDetailsWrapper>) => {
        let days: OpenShiftShortDetails[] = _.map(_.values<DailyData<OpenShiftShortDetailsWrapper>>(weeklyData.days), (day: DailyData<OpenShiftShortDetailsWrapper>) => {
          return day && day.data ? day.data.details : null;
        });
        return (dailyRecordsResult || []).concat(days);
      }, []);
    let entries: OpenShiftShortDetails[] = _.filter(rawEntries, (entry: OpenShiftShortDetails) => { return !!entry; });
    return entries;
  }
}
