import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import 'moment-range';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { orderBy, groupBy, process } from '@progress/kendo-data-query';
import { ModalService, StateManagementService } from '../../../../common/services/index';
import { appConfig } from '../../../../app.config';
import { ScheduleEntryApiService, ShiftReplacementApiService, ShiftReplacementManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { mutableSelect } from '../../../../core/decorators/redux-decorators';
import { ShiftReplacementSettings, ShiftReplacementInfo, ShiftEligibleEmployee, ScheduleFilterConfig } from '../../../models/index';
import { screenUtils } from '../../../../common/utils/index';
import { NotificationsApiService } from '../../../../portal/services/notifications/notifications-api.service';
var ShiftReplacementGridComponent = /** @class */ (function () {
    function ShiftReplacementGridComponent(modalService, shiftReplacementApiService, changeDetector, stateManagement, shiftRepService, scheduleEntryApiService, notificationApiService) {
        var _this = this;
        this.modalService = modalService;
        this.shiftReplacementApiService = shiftReplacementApiService;
        this.changeDetector = changeDetector;
        this.stateManagement = stateManagement;
        this.shiftRepService = shiftRepService;
        this.scheduleEntryApiService = scheduleEntryApiService;
        this.notificationApiService = notificationApiService;
        this.isModifyPayperiodEnabled = false;
        this.dataLoaded = false;
        this.isMessageCenterEnabled = false;
        this.gridState = {
            skip: undefined,
            take: Infinity,
            filter: undefined,
            sort: [],
            group: undefined
        };
        this.columnsSettings = {};
        this.selectionRowChanged = new EventEmitter();
        this.screenUtils = screenUtils;
        this.appConfig = appConfig;
        this.sendSmsModeOn = false;
        this.selectedEmployees = [];
        this.checkedEmployees = [];
        this.shiftRepService.isModifyApprovedPayperiod$.subscribe(function (result) {
            _this.isModifyPayperiodEnabled = result;
        });
    }
    Object.defineProperty(ShiftReplacementGridComponent.prototype, "shiftReplacementInfo", {
        set: function (shiftReplacementInfo) {
            this.gridData = shiftReplacementInfo;
            if (this.gridData) {
                this.generateFilters(this.gridData);
                this.refreshGrid();
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftReplacementGridComponent.prototype, "replacementSettings", {
        set: function (settings) {
            this.settings = settings;
            this.columnsSettings = _.keyBy(settings.columns, function (column) {
                return column.name;
            });
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    ShiftReplacementGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkedEmployeesSubscription = this.shiftRepService
            .subscribeToCheckedEmployees(function (checkedEmployees) {
            var hasChanged = !(_.get(_this.checkedEmployees, 'length') === _.get(checkedEmployees, 'length'));
            if (hasChanged) {
                _this.updateCheckedEmployees(checkedEmployees);
            }
        });
        this.changedSmsModeSubscription = this.shiftRepService
            .subscribeToChangeSmsMode(function (smsModeOn) {
            _this.sendSmsModeOn = smsModeOn;
            _this.changeDetector.detectChanges();
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.checkMessageCenterAccess();
            }
        });
    };
    ShiftReplacementGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ShiftReplacementGridComponent.prototype.refresh = function () {
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ShiftReplacementGridComponent.prototype.sortChange = function (sort) {
        this.gridState.sort = sort;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ShiftReplacementGridComponent.prototype.onPutCheck = function (isCheckedAll) {
        var _a;
        var _this = this;
        var checkedEmployees = _.filter(this.gridView.data, function (record) {
            if (isCheckedAll) {
                record.isChecked = _this.isAllSelected;
            }
            return record.isChecked;
        });
        this.checkedEmployees.length = 0;
        (_a = this.checkedEmployees).push.apply(_a, checkedEmployees);
        this.shiftRepService.changeCheckedEmployees(this.checkedEmployees);
    };
    ShiftReplacementGridComponent.prototype.onCellClick = function (clickEvent) {
        var _this = this;
        var clickedRecord = this.gridView.data[clickEvent.rowIndex];
        if (this.isModifyPayperiodEnabled)
            this.shiftRepService.replaceEmployee.selectedEmployee = clickedRecord;
        this.shiftRepService.replaceEmployeeId = clickedRecord.employee.id;
        var startDate = moment(this.shiftRepService.replaceEmployee.dateOn).format("MM-DD-YYYY");
        this.scheduleEntryApiService.checkApprovedPayperiod(this.shiftRepService.replaceEmployeeId.toString(), startDate, startDate).then(function (data) {
            _this.shiftRepService.replaceApprovedResult.push(data);
        });
        if (_.isObject(clickedRecord)) {
            if (clickedRecord.isSelected) {
                var index = _.findIndex(this.selectedEmployees, function (record) { return record.employee.id === clickedRecord.employee.id; });
                clickedRecord.isSelected = false;
                this.selectedEmployees.splice(index, 1);
            }
            else {
                if (!this.settings.isMultipleSelectionMode) {
                    this.selectedEmployees.length = 0;
                    _.forEach(this.gridView.data, function (record) { record.isSelected = false; });
                }
                clickedRecord.isSelected = true;
                this.selectedEmployees.push(clickedRecord);
            }
            this.shiftRepService.changeSelectedEmployees(this.selectedEmployees, this.settings.isMultipleSelectionMode);
        }
    };
    ShiftReplacementGridComponent.prototype.isRowSelected = function () {
        var _this = this;
        return function (row) { return (_.findIndex(_this.selectedEmployees, function (record) { return record.employee.id === row.dataItem.employee.id; }) !== -1); };
    };
    ShiftReplacementGridComponent.prototype.dataStateChange = function (state) {
        this.gridState = state;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ShiftReplacementGridComponent.prototype.getColor = function (item) {
        if (this.settings.request.showAgencyEmployees && item.isAgency)
            return { 'color': '#c32813', 'font-weight': 'bold' }; // '$theme-red'
        if (this.settings.request.showAvailabilityRecords && item.isAvailable)
            return { 'color': '#1cac75', 'font-weight': 'bold' }; //$theme-saved-text'
        if (this.settings.request.showEmployeesOtherShifts && item.isWorkingOtherShift)
            return { 'color': '#0047bb', 'font-weight': 'bold' }; //'$theme-blue'
        return { 'color': '#736969' }; //$theme-black
    };
    ShiftReplacementGridComponent.prototype.onSettingsChanged = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ShiftReplacementGridComponent.prototype.isDisplayed = function (columnName) {
        var prop = columnName + ".displayed";
        return this.dataLoaded && !!_.get(this.columnsSettings, prop, false);
    };
    ShiftReplacementGridComponent.prototype.isNumber = function (value) {
        var parsed = parseFloat(value);
        return parsed >= 0;
    };
    ShiftReplacementGridComponent.prototype.updateCheckedEmployees = function (checkedEmployees) {
        if (this.gridView && this.gridView.data) {
            _.forEach(this.gridView.data, function (record) {
                var employee = _.find(checkedEmployees, function (emp) { return record.employee.id === emp.employee.id; });
                record.isChecked = _.isObject(employee);
            });
            this.changeDetector.detectChanges();
        }
    };
    ShiftReplacementGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.gridData) {
            this.gridView = null;
            return;
        }
        var filteredRecords = _.filter(this.gridData.records, function (record) {
            return _this.settings.request.showDayOffEmployees && record.isDayOff ||
                _this.settings.request.showAgencyEmployees && record.isAgency ||
                _this.settings.request.showAvailabilityRecords && record.isAvailable ||
                _this.settings.request.showEmployeesOtherShifts && record.isWorkingOtherShift;
        });
        this.removeSelectedIfNotInFilteredRecords(filteredRecords);
        var tempData = process(filteredRecords, this.gridState);
        var sortedRecords = orderBy(tempData.data, this.gridState.sort);
        var groupedRecords = groupBy(sortedRecords, this.gridState.group);
        this.gridView = {
            data: groupedRecords,
            total: groupedRecords.length
        };
        this.dataLoaded = true;
    };
    ShiftReplacementGridComponent.prototype.generateFilters = function (shiftReplacementInfo) {
        if (shiftReplacementInfo) {
            this.typeFilters = ScheduleFilterConfig.optInStatusFilters();
        }
    };
    ShiftReplacementGridComponent.prototype.getOptInTooltip = function (empOptIn) {
        if (empOptIn === 0)
            return 'Opted Out';
        if (empOptIn === 1)
            return 'Opted In';
        if (empOptIn === 2)
            return 'Not Responded to the Opt In Request';
        return null;
    };
    ShiftReplacementGridComponent.prototype.checkMessageCenterAccess = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var access;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.notificationApiService.getIconAccessCheck(this.orgLevel.id)];
                    case 1:
                        access = _a.sent();
                        this.isMessageCenterEnabled = access.isMessageCenterEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    ShiftReplacementGridComponent.prototype.validate = function (dataItem) {
        if (!this.isMessageCenterEnabled)
            return false;
        if (!dataItem.canSendSms)
            return true;
        if (dataItem.canSendSms)
            return false;
        if (dataItem.empOptIn === 1)
            return false;
        return true;
    };
    ShiftReplacementGridComponent.prototype.removeSelectedIfNotInFilteredRecords = function (filteredRecords) {
        var selectedRecords = _.intersectionBy(this.selectedEmployees, filteredRecords, function (x) { return x.employee.id; });
        this.selectedEmployees = selectedRecords;
        this.shiftRepService.changeSelectedEmployees(this.selectedEmployees, this.settings.isMultipleSelectionMode);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementGridComponent.prototype, "checkedEmployeesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementGridComponent.prototype, "changedSmsModeSubscription", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ShiftReplacementGridComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementGridComponent.prototype, "orgLevelSubscription", void 0);
    return ShiftReplacementGridComponent;
}());
export { ShiftReplacementGridComponent };
