import {
  IEmployeePositionDefinition, EmployeePositionDefinition,
  IUserProfileDefinition, UserProfileDefinition,
  ISupervisorGroupDefinition, SupervisorGroupDefinition

} from '../../../organization/index';

export interface IEligibleSupervisor {
  role: string;
  user: IUserProfileDefinition;
  employee: IEmployeePositionDefinition;
  groups: ISupervisorGroupDefinition[];
}

export class EligibleSupervisor {

  public get userName(): string {
    let name: string = '';
    if (this.user && this.user.name) {
      name += this.user.name;
    }
    if (this.user && this.user.login) {
      name += '(' + this.user.login + ')';
    }
    return name;
  }

  public get employeeName(): string {
    return this.employee ? this.employee.employee.name : '';
  }

  public get centerName(): string {
    if (this.employee && this.employee.positionDepartment) {
      return this.employee.positionDepartment.name;
    }
    return '';
  }

  public get positionName(): string {
    if (this.employee && this.employee.position) {
      return this.employee.position.name;
    }
    return '';
  }

  public role: string;
  public user: UserProfileDefinition;
  public employee: EmployeePositionDefinition;
  public groups: ISupervisorGroupDefinition[];
  public isSelected: boolean;
  public selectable: boolean = true;
}
