import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import { FieldsMeta } from '../../../core/models/index';
import { IAccrualPolicy, AccrualPolicy, IDynamicAccrualPolicies, DynamicAccrualPolicies, IAccrualPolicyRule, AccrualPolicyRule } from '../../models/accruals/index';
@Injectable()
export class AccrualMapService {
  constructor(
  ) { } 

  public mapAccrualPolicy(dto: IAccrualPolicy): AccrualPolicy {
    const data: AccrualPolicy = new AccrualPolicy();
    data.id = dto.id;
    data.policyName = dto.policyName;
    data.accrualType = dto.accrualType;
    data.rules = _.map(dto.rules, item => this.mapAccrualPolicyRules(item));

    return data;
  }
  public mapAccrualPolicyRules(dto: IAccrualPolicyRule): AccrualPolicyRule {
    let rule: AccrualPolicyRule = new AccrualPolicyRule();
    rule.id = dto.id;
    rule.ruleName = dto.ruleName;
    rule.accrualTypeName = dto.accrualTypeName;

    return rule;
  }
  public mapAccrualsPoliciesRecords(dto: IAccrualPolicy[]): AccrualPolicy[] {
    
    return _.map(dto, (dto: IAccrualPolicy) => this.mapAccrualPolicy(dto));
  }

  public mapToDynamicPolicy(dpolicy: any, meta: FieldsMeta): boolean {
    Assert.isNotNull(dpolicy, 'dpolicy');
    let dynamicPolicy: DynamicAccrualPolicies = new DynamicAccrualPolicies();
    _.forEach(dpolicy, (recordDto: any) => {
      dynamicPolicy.key = recordDto.key;
      dynamicPolicy.value = recordDto.value == 'true' ? true : false;
    });
    let isDynamicPolicy: boolean = false;
    isDynamicPolicy = dynamicPolicy.value;
    return isDynamicPolicy;
  }

  
}
