var RoleMenu = /** @class */ (function () {
    function RoleMenu() {
    }
    Object.defineProperty(RoleMenu.prototype, "isDirty", {
        get: function () {
            return this.isEnabled !== this.initial_isEnabled;
        },
        enumerable: true,
        configurable: true
    });
    RoleMenu.prototype.setInitialState = function () {
        this.initial_isEnabled = this.isEnabled;
    };
    return RoleMenu;
}());
export { RoleMenu };
