import { Component, OnInit, Provider } from '@angular/core';
import { IDialog, DialogOptions } from '../../../../../../../../app/common/models/index';
import { ModalService } from '../../../../../../../../app/common/services/modal/modal.service';
import * as _ from 'lodash';

@Component({
  selector: 'slx-wc-create-osha-form-excel-dialog',
  templateUrl: './wc-create-osha-form-excel-dialog.component.html',
  styleUrls: ['./wc-create-osha-form-excel-dialog.component.scss']
})
export class WcCreateOshaFormExcelDialogComponent implements IDialog {

  public dialogResult: boolean;
  public isLoading: boolean = true;

  private options: DialogOptions;
  private modalService: ModalService;

  public static openDialog(modalService: ModalService, callback?: (result: boolean) => void): WcCreateOshaFormExcelDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 450;
    dialogOptions.height = 160;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.showCloseButton = false;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];

    return modalService.globalAnchor.openDialog(
      WcCreateOshaFormExcelDialogComponent,
      'Create Osha Excel Report',
      dialogOptions,
      resolvedProviders,
      callback
    );
  }

  constructor(
    options: DialogOptions,
    modalService: ModalService,
  ) {
    this.options = options;
    this.modalService = modalService;
  }

  public create(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
