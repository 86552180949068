<slx-spinner [show]="state.isLoading">
  <div class="holder slx-full-height">
    <div class="report-groups">
      <div
        class="report-group"
        [class.half]="reportGroups.length === 2 || reportGroups.length === 4"
        *ngFor="let reportGroup of reportGroups"
      >
        <slx-report-group [reportGroup]="reportGroup"></slx-report-group>
      </div>
    </div>
  </div>
</slx-spinner>
