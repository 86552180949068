import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSubsectionEnrollment = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSubsectionEnrollment, _super);
    function EmployeeSubsectionEnrollment() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSubsectionEnrollment;
}(EmployeeSectionsBase));
export { EmployeeSubsectionEnrollment };
