import { ScheduleEntryDefinition, EmployeeDefinition } from '../../../../organization/models/index';
import {
  Position,
  LocationUnit,
  ShiftDefinition,
  ScheduleAbsence,
  ConstraintDefinition,
  Department
} from '../../../../organization/models/index';

export class ScheduleQuickEditConfiguration {
  public department: Department;
  public unit: LocationUnit;
  public shift: ShiftDefinition;
  public absence: ScheduleAbsence;
  public constraint: ConstraintDefinition;
  public replaceAlways: boolean;
}
