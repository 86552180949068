import * as tslib_1 from "tslib";
import { PayrollExportNavigationService } from './../../../../common/services/navigation/payroll-export-navigation.service';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { PayCycle } from '../../../../organization/models/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { StateResetTypes } from '../../../../core/models/index';
import { TimecardsSummary, TimecardsAction, TimecardsActionCmd } from '../../../models/index';
import { TimecardsDisplayManagementService } from '../../../services/index';
import { mutableSelect, unsubscribe, destroyService } from '../../../../core/decorators/index';
import { TimecardsActions } from '../../../store/index';
import { ModalService } from '../../../../common/index';
import { FileService, TimecardsSummaryNavigationService, ComponentStateStorageService } from '../../../../common/services/index';
import { StateManagementService } from '../../../../common/services/index';
import { AppSettingsManageService } from '../../../../app-settings/services/app-settings-manage.service';
import { PayrollExportResultDialogComponent } from '../payroll-export-result-dialog/payroll-export-result-dialog.component';
import { MessagesService } from '../../../../components-library/services/index';
var TimecardsDisplayComponent = /** @class */ (function () {
    function TimecardsDisplayComponent(managementService, timecardDiplayActions, modalService, fileService, orgLevelsService, route, router, stateManagement, compStorageService, appSettingsManageService, messagesService) {
        this.orgLevelsService = orgLevelsService;
        this.route = route;
        this.router = router;
        this.stateManagement = stateManagement;
        this.compStorageService = compStorageService;
        this.appSettingsManageService = appSettingsManageService;
        this.messagesService = messagesService;
        this.payCycleKey = 'payCycleKey';
        this.resetBy = StateResetTypes.SessionEnd;
        this.managementService = managementService;
        this.timecardDiplayActions = timecardDiplayActions;
        this.modalService = modalService;
        this.fileService = fileService;
        this.state = {
            isLoading: true,
        };
        this.orgLevelIdForPayCycles = 0;
        this.isOrganizationOrgLevel = false;
        this.isShowPayRates = false;
        this.m_hideRates = false;
        this.isFinishedPayCycle = false;
        this.canMassApprove = false;
    }
    Object.defineProperty(TimecardsDisplayComponent.prototype, "hideRates", {
        get: function () {
            return this.m_hideRates;
        },
        set: function (value) {
            this.m_hideRates = value;
            if (!!this.m_hideRates) {
                this.setIsShowPayRate(false);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardsDisplayComponent.prototype, "hasParentOrganizationId", {
        get: function () {
            return !!this.managementService.currentOrganizationId;
        },
        enumerable: true,
        configurable: true
    });
    TimecardsDisplayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.managementService.init();
        this.stateManagement.init('TimecardsDisplayComponent', true);
        this.routeSubscripion = this.route.queryParams
            .combineLatest(this.managementService.onOrgLevelChanged$, this.stateManagement.onInit$)
            .subscribe(function (_a) {
            var queryParams = _a[0], orgLevel = _a[1], onInit = _a[2];
            _this.state.isLoading = true;
            _this.setOrgLevel(orgLevel);
            _this.setPayCycle(queryParams);
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.loadedSubscription = this.managementService.onLoaded$
            .subscribe(function (container) {
            _this.messagesService.resetClosed();
            _this.container = container;
            _this.hideRates = _this.container.hideRates;
            _this.hideCostCenterCode = _this.container.hideCostCenter;
            _this.hasRulesDefinitions = _this.container.usedRulesDefinitions && _this.container.usedRulesDefinitions.length > 0;
            _this.isShowHideEmptyTimecard = _this.container.hideEmptyTimeCards;
        });
        this.stateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.globalState = state;
            _this.isShowPayRates = !!_this.hideRates ? false : _this.globalState.isShowPayRates;
            _this.showHighPrecision = !!state.isShowHighPrecision;
        });
        this.recordsSelectedSubscription = this.managementService.onRecordsSelected$
            .subscribe(function (records) {
            _this.selectedRecords = records;
            _this.selectedEmpIds = _.map(_.filter(_this.selectedRecords, function (r) { return r.isSelected; }), function (r) { return r.employeePosition.employee.id; });
            _this.canMassApprove = _this.checkToMassApprove(records);
            _this.changeSelectedEntries(records);
        });
        this.exportTimecardsSubscription = this.managementService.onExportTimecards$
            .subscribe(function (file) { return _this.fileService.saveToFileSystem(file.blob, file.file); });
        this.selectedEmployeeSubsription = this.managementService.lastSelectionState$
            .subscribe(function (state) { return _this.changeLastSelectionState(state); });
        this.appSettingsManageService.getAppServerConfig()
            .then(function (conf) {
            _this.payrollManagementEnabed = conf.payrollExpManagementEnabled;
        });
    };
    TimecardsDisplayComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(TimecardsDisplayComponent.prototype, "iconClass", {
        get: function () {
            var iconClass = 'fa ';
            if (!this.orgLevel) {
                return '';
            }
            switch (this.orgLevel.type) {
                case OrgLevelType.department:
                    iconClass += 'fa-users';
                    break;
                case OrgLevelType.organization:
                    iconClass += 'fa-building';
                    break;
                case OrgLevelType.corporation:
                    iconClass += 'fa-globe';
                    break;
                default:
                    iconClass += 'fa-globe';
            }
            return iconClass;
        },
        enumerable: true,
        configurable: true
    });
    TimecardsDisplayComponent.prototype.onPayCycleSelected = function (payCycle) {
        this.currentPayCycle = payCycle;
        this.payCycle = payCycle;
        this.isFinishedPayCycle = payCycle.endDate.getTime() < Date.now();
        this.managementService.onPayCycleChanged(payCycle);
        if (this.globalState) {
            this.globalState.lastViewedEmployee = 0;
            this.globalState.lastSelectedEntries = [];
        }
        this.timecardDiplayActions.clearTimecardsDisplaySelectionSettings();
        this.timecardDiplayActions.changeTimecardsDisplayPayCycle(payCycle);
        this.savePayCycle(payCycle);
    };
    TimecardsDisplayComponent.prototype.onPayCyclesLoaded = function (payCycles) {
        if (!payCycles || payCycles.length === 0) {
            this.container = new TimecardsSummary();
            this.container.records = [];
            this.container.rulesDefinitions = [];
            this.managementService.onLoaded(this.container);
            this.state.isLoading = false;
        }
    };
    TimecardsDisplayComponent.prototype.onVisibilityPayRatesChanged = function ($event) {
        this.setIsShowPayRate($event);
    };
    TimecardsDisplayComponent.prototype.onVisibilityHighPrecisionChanged = function ($event) {
        this.setIsShowHighPrecision($event);
    };
    TimecardsDisplayComponent.prototype.onVisibilityHideEmptyTimecardChanged = function ($event) {
        this.setIsShowHideEmptyTimecard($event);
    };
    TimecardsDisplayComponent.prototype.onToggleView = function (mode) {
        this.globalState.flatMode = mode;
        this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
    };
    TimecardsDisplayComponent.prototype.onExportToExcel = function () {
        var action = new TimecardsAction();
        action.cmd = TimecardsActionCmd.excelExport;
        this.managementService.onActionCmd(action);
    };
    TimecardsDisplayComponent.prototype.onExportToPdf = function () {
        var action = new TimecardsAction();
        action.cmd = TimecardsActionCmd.pdfExport;
        this.managementService.onActionCmd(action);
    };
    TimecardsDisplayComponent.prototype.onExportToPayroll = function () {
        var navi = new PayrollExportNavigationService(this.router, this.route);
        navi.navigateToPayrollExport(this.payCycle.startDate, this.payCycle.endDate);
    };
    TimecardsDisplayComponent.prototype.onReleaseToPayroll = function () {
        var _this = this;
        this.managementService.onReleaseToPayroll().then(function (res) {
            if (!res) {
                return;
            }
            PayrollExportResultDialogComponent.openDialog(res, _this.modalService, function () {
                // nothing to do
            });
        });
    };
    TimecardsDisplayComponent.prototype.onViewSummary = function () {
        var navService = new TimecardsSummaryNavigationService(this.router, this.route);
        var orgLevel = this.managementService.currentOrgLevel;
        var organizationId;
        if (orgLevel.type === OrgLevelType.department) {
            var orgLevelId = orgLevel.parentId;
            if (!_.isNumber(orgLevelId)) {
                orgLevelId = this.orgLevelsService.getOrgLevelById(orgLevel.id).parentId;
            }
            organizationId = this.getOrganizationId(orgLevelId);
            if (_.isNull(organizationId)) {
                organizationId = orgLevel.organizationId;
            }
        }
        else {
            organizationId = orgLevel.relatedItemId;
            if (!_.isNumber(organizationId)) {
                organizationId = this.getOrganizationId(orgLevel.id);
            }
        }
        navService.NavigateToTimecardSummary(organizationId, this.managementService.currentPayCycle.startDate, this.managementService.currentPayCycle.endDate);
    };
    TimecardsDisplayComponent.prototype.getOrganizationId = function (orgLevelId) {
        var orgLevel = this.orgLevelsService.getOrgLevelById(orgLevelId);
        return _.get(orgLevel, 'relatedItemId', null);
    };
    TimecardsDisplayComponent.prototype.onRecalculateTimecards = function () {
        this.managementService.recalculateTimecards(this.orgLevel.id, this.payCycle);
    };
    TimecardsDisplayComponent.prototype.onApproveTimecards = function () {
        this.managementService.checkTimecardsApproval(this.orgLevel, this.payCycle);
    };
    TimecardsDisplayComponent.prototype.onUnapproveTimecards = function () {
        this.managementService.unapproveTimecards(this.orgLevel.id, this.payCycle);
    };
    TimecardsDisplayComponent.prototype.toggleColumnsMenu = function () {
        this.columnsMenuOpened = !this.columnsMenuOpened;
    };
    TimecardsDisplayComponent.prototype.changeLastSelectionState = function (state) {
        this.globalState.lastViewedEmployee = state.selectedEmployee;
        this.globalState.lastViewedPage = state.skipRecords;
        this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
    };
    TimecardsDisplayComponent.prototype.changeSelectedEntries = function (entries) {
        this.globalState.lastSelectedEntries = _.map(entries, function (entry) { return entry.employeePosition.employee.id; });
        this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
    };
    TimecardsDisplayComponent.prototype.selectAll = function (event) {
        _.forEach(this.globalState.empColumns.columns, function (column) {
            column.displayed = true;
        });
        this.managementService.changeEmpColumns();
    };
    TimecardsDisplayComponent.prototype.isColumnVisible = function (columnName) {
        var columnState = this.globalState.empColumns;
        var column = columnState.columnsMap[columnName];
        if (!this.globalState.isShowPayRates && column.payload && column.payload.payRateRelated) {
            return false;
        }
        if (column.name === 'costCenterCode' && this.hideCostCenterCode) {
            return false;
        }
        if (column.name === 'shiftCodeName' && (!this.container || !this.container.shiftDiffBasedOnTime)) {
            return false;
        }
        return true;
    };
    TimecardsDisplayComponent.prototype.clearAll = function () {
        _.forEach(this.globalState.empColumns.columns, function (column) {
            column.displayed = false;
        });
        this.managementService.changeEmpColumns();
    };
    TimecardsDisplayComponent.prototype.selectAllPayCodes = function (event) {
        _.forEach(this.globalState.payCodeColumns.columns, function (column) {
            column.displayed = true;
        });
        this.managementService.changePayColumns();
    };
    TimecardsDisplayComponent.prototype.clearAllPayCodes = function () {
        _.forEach(this.globalState.payCodeColumns.columns, function (column) {
            column.displayed = false;
        });
        this.managementService.changePayColumns();
    };
    TimecardsDisplayComponent.prototype.onChangeEmpColumns = function (event, column) {
        this.managementService.changeEmpColumns();
    };
    TimecardsDisplayComponent.prototype.onChangePayCodeColumns = function (event, column) {
        this.managementService.changePayColumns();
    };
    TimecardsDisplayComponent.prototype.onTimecardQueueClicked = function () {
        this.managementService.showTimecardQueueDialog(this.orgLevel, this.payCycle);
    };
    TimecardsDisplayComponent.prototype.setOrgLevel = function (orgLevel) {
        var prevOrgLevelId = _.get(this.orgLevel, 'id');
        var newOrgLevelId = _.get(orgLevel, 'id');
        if (prevOrgLevelId !== newOrgLevelId) {
            this.isOrganizationOrgLevel = orgLevel.type === OrgLevelType.organization;
            this.isDepartmentOrOrganizationOrgLevel = this.isOrganizationOrgLevel || orgLevel.type === OrgLevelType.department;
            this.orgLevel = orgLevel;
            this.orgLevelIdForPayCycles = this.orgLevel.id;
        }
        else {
            this.state.isLoading = false;
        }
    };
    TimecardsDisplayComponent.prototype.setPayCycle = function (queryParams) {
        var sDate = queryParams['startDate'];
        var eDate = queryParams['endDate'];
        var payCycle = null;
        if (sDate || eDate) {
            payCycle = new PayCycle();
            payCycle.startDate = moment(sDate, appConfig.linkDateFormat).toDate();
            payCycle.endDate = moment(eDate, appConfig.linkDateFormat).toDate();
        }
        else if (!this.currentPayCycle) {
            payCycle = this.restorePayCycle();
        }
        if (payCycle && !this.isSamePayCycle(this.currentPayCycle, payCycle)) {
            this.currentPayCycle = payCycle;
            this.isFinishedPayCycle = this.currentPayCycle.endDate.getTime() < Date.now();
            this.timecardDiplayActions.changeTimecardsDisplayPayCycle(this.currentPayCycle);
        }
        else {
            this.state.isLoading = false;
        }
    };
    TimecardsDisplayComponent.prototype.isSamePayCycle = function (pCycle1, pCycle2) {
        var sDate1 = _.get(pCycle1, 'startDate');
        var eDate1 = _.get(pCycle1, 'endDate');
        var sDate2 = _.get(pCycle2, 'startDate');
        var eDate2 = _.get(pCycle2, 'endDate');
        return moment(sDate1).isSame(sDate2) && moment(eDate1).isSame(eDate2);
    };
    TimecardsDisplayComponent.prototype.setIsShowPayRate = function (value) {
        this.isShowPayRates = !!this.hideRates ? false : value;
        if (!!this.globalState) {
            this.globalState.isShowPayRates = this.isShowPayRates;
        }
        this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
    };
    TimecardsDisplayComponent.prototype.setIsShowHighPrecision = function (value) {
        if (!!this.globalState) {
            this.globalState.isShowHighPrecision = value;
        }
        this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
    };
    TimecardsDisplayComponent.prototype.getShowHideToolTip = function () {
        if (this.isShowHideEmptyTimecard)
            return 'With this option enabled, the Timecards grid will exclude any timecards that contain none of the following: actual punches, regular time, productive hours, non-productive hours, pay codes, or absence codes.';
        return 'With this option disabled, the grid will display all timecards for the selected time period, including empty timecards, which may negatively impact system performance.';
    };
    TimecardsDisplayComponent.prototype.setIsShowHideEmptyTimecard = function (value) {
        this.isShowHideEmptyTimecard = value;
        this.managementService.showHideEmptyTimeCards(value);
        this.managementService.onPayCycleChanged(this.payCycle);
        if (this.globalState) {
            this.globalState.lastViewedEmployee = 0;
            this.globalState.lastSelectedEntries = [];
        }
    };
    TimecardsDisplayComponent.prototype.checkToMassApprove = function (records) {
        var hasMiss = _.find(records, function (rec) {
            return rec.isError;
        });
        return !hasMiss;
    };
    TimecardsDisplayComponent.prototype.savePayCycle = function (payCycle) {
        var startDate = payCycle.startDate.toISOString();
        var endDate = payCycle.endDate.toISOString();
        var isApproved = payCycle.isApproved;
        this.compStorageService.setControlState(this.stateManagement.componentKey, this.payCycleKey, { value: { startDate: startDate, endDate: endDate, isApproved: isApproved } }, this.resetBy);
    };
    TimecardsDisplayComponent.prototype.restorePayCycle = function () {
        var state = this.compStorageService.getControlState(this.stateManagement.componentKey, this.payCycleKey);
        var value = _.get(state, 'value');
        if (_.isObject(value)) {
            var payCycle = new PayCycle();
            payCycle.startDate = new Date(value.startDate);
            payCycle.endDate = new Date(value.endDate);
            payCycle.isApproved = value.isApproved;
            return payCycle;
        }
        return null;
    };
    tslib_1.__decorate([
        mutableSelect(['timecards', 'timecardsDisplay', 'payCycle']),
        tslib_1.__metadata("design:type", Observable)
    ], TimecardsDisplayComponent.prototype, "timecardsPayCycle$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "timecardsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "recordsSelectedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "exportTimecardsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "selectedEmployeeSubsription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayComponent.prototype, "loadingChangedSubsription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", TimecardsDisplayManagementService)
    ], TimecardsDisplayComponent.prototype, "managementService", void 0);
    return TimecardsDisplayComponent;
}());
export { TimecardsDisplayComponent };
