<form class="dialog-form" #dialogForm="ngForm">
  <div class="modal-content">
    <div class="modal-body">
      <div class="info-block">
        <div class="info-item">
            <span class="info-title">Ideal</span>
            <span>Count: <strong>{{openShiftDetails.idealShiftCount}}</strong></span>
            <span>Hours: <strong>{{openShiftDetails.idealHours}}</strong></span>
        </div>
        <div class="info-item">
            <span class="info-title">Scheduled</span>
            <span>Count: <strong class='good-label' [ngClass]="{'bad-label': currenOpenShiftCount < 0 }">{{scheduledShiftCount}}</strong></span>
            <span>Hours: <strong class='good-label' [ngClass]="{'bad-label': currenOpenShiftCount < 0 }">{{scheduledHours}}</strong></span>
        </div>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator>
          <slx-kendo-number slx-input
            [(ngModel)]="adjustedShiftCount"
            [min]="0"
            [slxMin]="0"
            [max]="99"
            [slxMax]="99"
            [required]="true"
            placeholder="Adjusted Original/Ideal Open Shift Count"
            name="openShiftCount"
          ></slx-kendo-number>
          <span errorMessage for="min">Minimum value is 0.</span>
          <span errorMessage for="max">Maximum value is 99.</span>
          <span errorMessage for="required">Field is required.</span>
        </slx-input-decorator>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
      <button type="button" (click)="onOk()" [disabled]="!dialogForm.valid" class="theme-button-apply">OK</button>
    </div>
  </div>
</form>
