<div class="comparison-content" [ngSwitch]="rangeType" [ngClass]="{ 'plate': rangeType === 'Day' }">
  <ng-container *ngSwitchCase="'Day'">
    <ng-container *ngFor="let comparison of comparisons">
      <ng-container *ngIf="!comparison.isEmpty">
        <h4 class="comparison-content__header">{{ comparisonName }}</h4>
        <slx-schedule-console-graphic-comparison-day
          slxElementCreatedSelector [emitEvent]="itWasClicked(comparison)" (onCreated)="onCreated($event)"
          class="comparison-content__body"
          [comparison]="comparison"
          [widgetConfig]="widgetConfig"
          [chartInput]="getChartInput(comparison)"
          [chart]="chart"
          (onClickChart)="handleOnClick(comparison)"
          (onClickPair1)="handleOnClick(comparison)"
          (onClickPair2)="handleOnClick(comparison)"
          (onClickBadgeMessages)="handleOnClick(comparison)"
          (onClickBadgeOvertime)="handleOnClick(comparison)"
          (onClickBadgePto)="handleBadgePtoClick(comparison)"
          (onClickBadgeLate)="handleOnClick(comparison)"
          (onClickWidget)="handleOnClick(comparison)"
        ></slx-schedule-console-graphic-comparison-day>
      </ng-container>
    </ng-container>
  </ng-container>

  <slx-schedule-console-graphic-comparison-week
    *ngSwitchCase="'Week'"
    [comparisons]="comparisons"
    [widgetConfig]="widgetConfig"
    [chart]="chart"
    [consoleConfig]="consoleConfig"
    (onClickChart)="handleOnClick($event)"
    (onClickPair1)="handleOnClick($event)"
    (onClickPair2)="handleOnClick($event)"
    (onClickBadgeMessages)="handleOnClick($event)"
    (onClickBadgeOvertime)="handleOnClick($event)"
    (onClickBadgePto)="handleBadgePtoClick($event)"
    (onClickBadgeLate)="handleOnClick($event)"
    (onClickWidget)="handleOnClick($event)"
  ></slx-schedule-console-graphic-comparison-week>

  <div *ngSwitchDefault>Loading...</div>
</div>
