import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { IServerValidatorAdapter, IServerValidationResult } from '../../../common/validators/common-validators-models';
import { PbjOrganizationsApiService } from './pbj-organizations-api.service';

@Injectable()
export class CCNNumberValidatorAdapter implements IServerValidatorAdapter {

    public static ccnNumberValidation: string = 'ccnNumberValidation';

    constructor(private pbjOrganizationsApiService: PbjOrganizationsApiService) { }

    public validate(validationName: string, value: any, ...params: any[]): Promise<IServerValidationResult> {
        let promise: Promise<IServerValidationResult>;
        let defPromise: Promise<IServerValidationResult> = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
        switch (validationName) {
            case CCNNumberValidatorAdapter.ccnNumberValidation:
                promise = this.pbjOrganizationsApiService.validateCCN(value, params[0]);
                break;
        }
        return promise;
    }
}
