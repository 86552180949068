import { Injectable } from '@angular/core';
import { GridComponent, CellClickEvent, ColumnBase, ColumnComponent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import { ITimeAndAttendanceConfig, timeAndAttendanceConfig } from '../../time-and-attendance.config';
import * as _ from 'lodash';
import { Workbook, WorkbookSheetRow } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { PayRuleDefinition } from '../../../../app/organization/models/employee/pay-rule-definition';
import { aggregateBy } from '@progress/kendo-data-query';

export class TimecardDisplayCommonService {
  public taConfig: ITimeAndAttendanceConfig;
  public aggregates: any = [];
  public total: any = [];
  public subTotal: any = [];

  constructor() {
    this.taConfig = timeAndAttendanceConfig;
  }

  public makeXlsExport(gridState: any, grid: any, group: any, headInfo: any, fileName: string, payRuleDef?: any): void {

    let exportFileName = fileName ? fileName : 'Timecards';
    let headerInfo: string = headInfo ? headInfo : '';
    let groups = group ? group : [];
    let rows: any[] = [];
    let columns = grid.columnList.rootColumns();
    let visibleColumns: ColumnComponent[] = [];
    this.aggregates = [];

    _.each(columns, (column: ColumnBase) => {
      let colComponent = (column as ColumnComponent);
      let groupForColumn: any = _.find(groups, (g) => {
        return g.field === colComponent.field;
      });
      if (!colComponent.hidden && !_.isNil(colComponent.displayTitle)) {
        visibleColumns.push(colComponent);
      }
    });

    _.forEach(payRuleDef, (rule: PayRuleDefinition) => {
      this.aggregates.push({ field: rule.uniqName, aggregate: 'sum' });
    });

    this.aggregates.push({ field: 'workedHours', aggregate: 'sum' });
    this.aggregates.push({ field: 'totalHours', aggregate: 'sum' });
    this.aggregates.push({ field: 'regularPay', aggregate: 'sum' });
    this.aggregates.push({ field: 'overtimeAndExtraPay', aggregate: 'sum' });
    this.aggregates.push({ field: 'totalPay', aggregate: 'sum' });

    this.total = aggregateBy(gridState.view.data, this.aggregates);

    const hasGroups = groups && groups.length > 0;
    if (hasGroups) {
      rows = this.buildXlsRowsForGroups(gridState.view.data, visibleColumns, groups, exportFileName, 0);
    } else {
      _.each(gridState.view.data, (item: any) => {
        let cells: any[] = [];
        _.each(visibleColumns, (column: ColumnComponent) => {
          let cell: any = {
            value: _.get(item, column.field)
          };
          this.payRateDollar(item, cell, column);
          cells.push(cell);
        });
        rows.push({ cells: cells });
      });
    }

    let headers: any[] = this.getEmptyHeaderCellsIfGroups(groups);

    let startIndex = groups.length;
    _.each(visibleColumns, (column: ColumnBase) => {
      let cell: any = {
        value: column.displayTitle,
        fontSize: this.taConfig.settings.export.xlsx.headerCell.fontSize,
        color: this.taConfig.settings.export.xlsx.headerCell.color,
        background: this.taConfig.settings.export.xlsx.headerCell.background,
        width: column.width,
        index: startIndex,
      };
      startIndex = startIndex + 1;
      headers.push(cell);
    });

    rows.unshift({ cells: headers });

    if (groups && groups.length === 0) {
      let Footer: any[] = [];

      _.each(visibleColumns, (column: ColumnBase) => {
        let cell: any = {
          value: '',
          fontSize: this.taConfig.settings.export.xlsx.footerCell.fontSize,
          color: this.taConfig.settings.export.xlsx.footerCell.color,
          background: this.taConfig.settings.export.xlsx.footerCell.background,
          width: column.width
        };
        this.totalValCal(cell, column);
        Footer.push(cell);
      });

      rows.push({ cells: Footer });
    }

    const additionalDataRowColSpan = visibleColumns.length + (hasGroups ? groups.length : 0);
    let additionalDataRow: any = {
      cells: [
        {
          value: headerInfo,
          colSpan: additionalDataRowColSpan,
          fontSize: this.taConfig.settings.export.xlsx.titleCell.fontSize,
          borderBottom: this.taConfig.settings.export.xlsx.titleCell.borderBottom,
        },
      ],
    };
    rows.unshift(additionalDataRow);

    let sheetColumns: any[] = [];
    for (let i = 0; i < groups.length; i++) {
      let colComponent = { width: this.taConfig.settings.export.xlsx.minColumnWidth };
      sheetColumns.push(colComponent);
    }
    sheetColumns = sheetColumns.concat(visibleColumns);
    const workbook = new Workbook({
      sheets: [
        {
          name: exportFileName,
          columns: sheetColumns,
          rows: rows,
        },
      ],
    });
    workbook.toDataURL().then((dataUrl) => {
      saveAs(dataUrl, exportFileName + '.xlsx');
    });
  }

  private buildXlsRowsForGroups(
    gridStateViewData: any,
    visibleColumns: any,
    groups: any,
    exportFileName: string,
    cellIndex: number
  ): WorkbookSheetRow[] {
    let rows: WorkbookSheetRow[] = [];

    _.each(gridStateViewData, (group: any) => {
      if (group.value && group.items) {
        const groupRowCells = this.getGroupRowCells(visibleColumns, group, groups.length, cellIndex);
        rows.push({ cells: groupRowCells });
        const isGroupHasInnerGroups: boolean = group.items.length > 0 && group.items[0].value && !_.isNull(group.items[0].items);
        if (isGroupHasInnerGroups) {
          const innerRows = this.buildXlsRowsForGroups(
            group.items,
            visibleColumns,
            groups,
            exportFileName,
            cellIndex + 1
          );
          rows = rows.concat(innerRows);
          return rows;
        }
      }
      const dataRows = this.getDataRows(visibleColumns, group, cellIndex);
      rows = rows.concat(dataRows);

      if (exportFileName !== 'Timecards') {
        const footer = this.getTotalValuesFooter(visibleColumns, groups);
        rows.push({ cells: footer });
      }
    });
    return rows;
  }

  public payRateDollar(item: any, cell: any, column: ColumnComponent) {
    if (item.earning) {
      let emprule = item.earning.rules;
      for (let i = 0; i < emprule.length; i++) {
        if (column.field === `rulesMap.${emprule[i].payRule.name}.value`) {
          if(emprule[i].value && _.isNumber(emprule[i].value)){
            //accumulate the rule values in case of multiple rules for the same earning
            cell.value = _.toNumber(emprule[i].value) + (_.isNumber(cell.value) ? _.toNumber(cell.value) : 0);
          } else {
            cell.value = '';
          }
        }
      }
    }
  }

  public punchSecTOHour(cell: any, column: ColumnComponent) {
    if (column.field === 'day.punchInSeconds' || column.field === 'day.punchOutSeconds') {
      if (cell.value === 0) {
        return (cell.value = '12:00AM');
      }
      cell.value = cell.value ? moment.utc(cell.value * 1000).format('LT') : null;
    }
  }

  public totalValCal(cell: any, column: ColumnBase) {
    _.each(this.aggregates, (val: any) => {
      if (val['field'] === 'workedHours' && column.title === 'Worked Hours') {
        cell.value = this.total ? this.total[val['field']].sum : null;
      } else if (val['field'] === 'totalHours' && column.title === 'Total Hours') {
        cell.value = this.total ? this.total[val['field']].sum : null;
      }else if (val['field'] === 'regularPay' && column.title === 'Regular Pay') {
        cell.value = this.total ? this.total[val['field']].sum : null;
      } else if (val['field'] === 'overtimeAndExtraPay' && column.title === 'OT and Other Pay') {
        cell.value = this.total ? this.total[val['field']].sum : null;
      } else if (val['field'] === 'totalPay' && column.title === 'Total Pay') {
        cell.value = this.total ? this.total[val['field']].sum : null;
      } else if (`uniq${column.title}` === val['field']) {
        cell.value = this.total ? this.total[val['field']].sum : null;
      }
    });
  }

  public subTotalValCal(cell: any, column: ColumnComponent) {
    _.each(this.aggregates, (val: any) => {
      if (val['field'] === 'workedHours' && column.title === 'Worked Hours') {
        cell.value = this.subTotal ? this.subTotal[val['field']].sum : null;
      } else if (val['field'] === 'totalHours' && column.title === 'Total Hours') {
        cell.value = this.subTotal ? this.subTotal[val['field']].sum : null;
      }else if (val['field'] === 'regularPay' && column.title === 'Regular Pay') {
        cell.value = this.subTotal ? this.subTotal[val['field']].sum : null;
      } else if (val['field'] === 'overtimeAndExtraPay' && column.title === 'OT and Other Pay') {
        cell.value = this.subTotal ? this.subTotal[val['field']].sum : null;
      } else if (val['field'] === 'totalPay' && column.title === 'Total Pay') {
        cell.value = this.subTotal ? this.subTotal[val['field']].sum : null;
      } else if (`uniq${column.title}` === val['field']) {
        cell.value = this.subTotal ? this.subTotal[val['field']].sum : null;
      }
    });
  }

  private getGroupRowCells(
    visibleColumns: ColumnComponent[],
    group: any,
    groupsAmount: number,
    cellIndex: number
  ): any[] {

    const groupRowCells = [];
    for (let i = 0; i < cellIndex; i++) {
      groupRowCells.push({ index: i, background: this.taConfig.settings.export.xlsx.footerCell.background });
    }

    const colSpan = visibleColumns.length + groupsAmount - cellIndex;
    groupRowCells.push({
      value: group.value,
      index: cellIndex,
      colSpan: colSpan,
      background: this.taConfig.settings.export.xlsx.footerCell.background,
      textAlign: 'left'
    });
    return groupRowCells;
  }

  private getDataRows(
    visibleColumns: ColumnComponent[],
    group: any,
    cellIndex: number
  ): WorkbookSheetRow[] {
    let rows: WorkbookSheetRow[] = [];

    _.each(group.items, (item: any) => {
      const cells: any[] = [];
      this.subTotal = aggregateBy(group.items, this.aggregates);

      let innerIndex = cellIndex + 1;
      _.each(visibleColumns, (column: ColumnComponent) => {
        const cell: any = {
          value: _.get(item, column.field),
          index: innerIndex,
        };
        this.punchSecTOHour(cell, column);
        this.payRateDollar(item, cell, column);

        cells.push(cell);
        innerIndex = innerIndex + 1;
      });

      rows.push({ cells: cells });
    });

    return rows;
  }

  private getTotalValuesFooter(visibleColumns: ColumnComponent[], groups: any[]): any[] {
    const footer: any[] = [];
    for(let i = 0; i < groups.length; i++) {
      const cell = this.getFooterDefaultCell(this.taConfig.settings.export.xlsx.minColumnWidth, i);
      footer.push(cell);
    }

    let startIndex: number = groups.length;
    _.each(visibleColumns, (column: ColumnComponent) => {
      const cell = this.getFooterDefaultCell(column.width, startIndex);
      this.subTotalValCal(cell, column);
      footer.push(cell);
      startIndex = startIndex + 1;
    });

    return footer;
  }

  private getFooterDefaultCell(columnWidth: number, index: number) {
    const cell: any = {
      value: '',
      fontSize: this.taConfig.settings.export.xlsx.footerCell.fontSize,
      color: this.taConfig.settings.export.xlsx.footerCell.color,
      background: this.taConfig.settings.export.xlsx.footerCell.background,
      width: columnWidth,
      index: index
    };
    return cell;
  }

  private getEmptyHeaderCellsIfGroups(groups: any): any[] {
    const headers: any[] = [];

    for (let i = 0; i < groups.length; i++) {
      const cell: any = {
        value: '',
        fontSize: this.taConfig.settings.export.xlsx.headerCell.fontSize,
        color: this.taConfig.settings.export.xlsx.headerCell.color,
        background: this.taConfig.settings.export.xlsx.headerCell.background,
        index: i,
      };
      headers.push(cell);
    }
    return headers;
  }
}
