import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, NgZone } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { ModalService } from '../../../../../common/services/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/unsubscribe-decorator';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/kendo-grid-helpers';
import { PmCreateReviewPopupComponent } from '../../../../../app-modules/performance-management/components';
import { process, State } from '@progress/kendo-data-query';
import { Employee } from '../../../../../organization/models';
import { EmployeeSectionsReviews } from '../../../models/employee-sections-employment/employee-sections-reviews';
var EmployeeSectionPerformanceManagementComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionPerformanceManagementComponent, _super);
    function EmployeeSectionPerformanceManagementComponent(modalService, apiService, decorator, ngZone) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.modalService = modalService;
        _this.apiService = apiService;
        _this.pageSize = 5;
        _this.ratingTypeFilters = [];
        _this.reviewTypeFilters = [];
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.skip = 0;
        _this.gridState.state.take = _this.pageSize;
        _this.gridState.state.sort = [{ field: 'reviewRecord.reviewDate', dir: 'desc' }];
        return _this;
    }
    Object.defineProperty(EmployeeSectionPerformanceManagementComponent.prototype, "reviews", {
        get: function () {
            return this.m_reviews;
        },
        set: function (c) {
            this.m_reviews = c;
            if (this.m_reviews && this.m_reviews.records) {
                this.generateFilters(this.m_reviews.records);
            }
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionPerformanceManagementComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionPerformanceManagementComponent.prototype, "employeeId", {
        get: function () {
            return this.m_employeeId;
        },
        set: function (value) {
            this.m_employeeId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionPerformanceManagementComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionPerformanceManagementComponent.prototype.ngOnInit = function () {
        this.createSubscriptions();
        this.refreshGrid();
    };
    EmployeeSectionPerformanceManagementComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionPerformanceManagementComponent.prototype.getSubsectionModel = function () {
        return this.m_reviews;
    };
    EmployeeSectionPerformanceManagementComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    EmployeeSectionPerformanceManagementComponent.prototype.onStartAdd = function () {
        this.openNewReportDialog();
    };
    EmployeeSectionPerformanceManagementComponent.prototype.onSelectItem = function (item) {
        this.openEditReportDialog(item.reviewRecord);
    };
    EmployeeSectionPerformanceManagementComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    EmployeeSectionPerformanceManagementComponent.prototype.openNewReportDialog = function () {
        var _this = this;
        var info = this.employeeShortInfo;
        var employee = new Employee();
        employee.id = info.id;
        employee.name = info.name;
        employee.organization = info.organization;
        employee.department = info.department;
        employee.position = info.position;
        PmCreateReviewPopupComponent.openDialog('Create New Review', { review: null, employee: employee }, this.modalService, function () {
            _this.loadSubsection();
        });
    };
    EmployeeSectionPerformanceManagementComponent.prototype.openEditReportDialog = function (review) {
        var _this = this;
        PmCreateReviewPopupComponent.openDialog('Edit Review', { review: review, employee: null }, this.modalService, function () {
            _this.loadSubsection();
        });
    };
    EmployeeSectionPerformanceManagementComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.apiService.getEmployeePerformanceReviews(this.employeeId)
            .then(function (subsectionData) {
            _this.reviews = subsectionData;
            _this.refreshGrid();
            _this.stopProgress();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionPerformanceManagementComponent.prototype.generateFilters = function (records) {
        var ratingTypes = [];
        _.map(records, function (item) {
            if (item.rating) {
                ratingTypes.push({ text: item.rating.name, value: item.rating.id });
            }
        });
        ratingTypes = _.uniqBy(ratingTypes, 'value');
        this.ratingTypeFilters = ratingTypes;
        var reviewTypes = [];
        _.each(records, function (item) {
            if (item.reviewType) {
                reviewTypes.push({ text: item.reviewType.name, value: item.reviewType.id });
            }
        });
        reviewTypes = _.uniqBy(reviewTypes, 'value');
        this.reviewTypeFilters = reviewTypes;
    };
    EmployeeSectionPerformanceManagementComponent.prototype.refreshGrid = function () {
        if (!this.m_reviews || !this.m_reviews.records) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.m_reviews.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionPerformanceManagementComponent.prototype, "gridRefreshSubscription", void 0);
    return EmployeeSectionPerformanceManagementComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionPerformanceManagementComponent };
