import { DialogModeSize } from './../../../../common/models/dialog-options';
import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, DialogOptions2, IDialog } from '../../../../common/index';
//import { ScheduleDailyDetailsComponent } from '../schedule-daily-details/schedule-daily-details.component';
import { ModalService } from '../../../../common/services/index';
import { ScheduleDailyDetailsRequest } from '../../../models/index';
import { screenUtils, IScreenUtils } from '../../../../common/utils/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-daily-details-dialog',
  templateUrl: 'schedule-daily-details-dialog.component.html',
  styleUrls: ['schedule-daily-details-dialog.component.scss']
})
export class ScheduleDailyDetailsDialogComponent implements IDialog {

  public options: DialogOptions;
  public dialogResult: boolean;
  public request: ScheduleDailyDetailsRequest;

  private modalService: ModalService;
  private screenUtils: IScreenUtils;



  public static openDialog(request: ScheduleDailyDetailsRequest, modalService: ModalService, callback: (result: boolean) => void): ScheduleDailyDetailsDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ScheduleDailyDetailsRequest,
        useValue: request
      }
    ];

    let dialog: ScheduleDailyDetailsDialogComponent = modalService.globalAnchor
      .openDialog2(ScheduleDailyDetailsDialogComponent, moment(request.dateOn).format(appConfig.dateFormat), dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    request: ScheduleDailyDetailsRequest
  ) {
    this.modalService = modalService;
    this.options = options;
    this.request = request;
  }

  public onDua(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
