import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { Position, Department, ShiftDiffPolicy, PayPolicy } from '../../../organization/models/index';
import { TimecardsEmployee, TimecardsEarning, TimecardsEarningPayRule } from './index';

export class TimecardFlatRecord {
  public emp: TimecardsEmployee;
  public earning: TimecardsEarning;
  public rulesMap: Dictionary<TimecardsEarningPayRule[]>;
  public productiveHours: number;
  public nonProductiveHours: number;
  public totalHours: number;
  public regularHours: number;
  public regularPay: number;
  public overtimeAndExtraHours: number;
  public overtimeAndExtraPay: number;
  public overTimePay:number;
  public totalAbsencePay:number;
  public totalOtherPay:number;
  public recordTotalPay: number;
  public totalPay: number;
  public isFirst: boolean;
  public rulesValues: StringMap<any>;
  public shiftDiffPolicy: ShiftDiffPolicy;
  public standardPayRate: number;
  public payPolicy: PayPolicy;
  public isError: boolean;
  public isWarning: boolean;
  public isTimecardLocked: boolean;
  public isPayCycleLocked: boolean;
  public shiftCodeName: string;
  public isEdited: boolean;

  public get isLocked(): boolean {
    return this.isTimecardLocked || this.isPayCycleLocked;
  }
  public get departmentName(): string {
    if (this.earning) {
      return this.earning.department.name;
    }
    if (this.emp && this.emp.employeePosition) {
      return this.emp.employeePosition.positionDepartment.name;
    }
    return null;
  }

  public get organizationName(): string {
    if (this.earning) {
      return this.earning.organization.name;
    }
    if (this.emp) {
      return this.emp.empOrganization;
    }
    return null;
  }

  public get positionName(): string {
    if (this.earning) {
      return this.earning.position.name;
    }
    if (this.emp && this.emp.employeePosition) {
      return this.emp.employeePosition.position.name;
    }
    return null;
  }

  public get shiftDiffPolicyName(): string {
    if (this.shiftDiffPolicy) {
      return this.shiftDiffPolicy.name;
    }
    if (this.emp && this.emp.shiftDiffPolicy) {
      return this.emp.shiftDiffPolicy.name;
    }
    return null;
  }

  public get costCenterCode(): string {
    if (this.earning) {
      return this.earning.costCenterCode;
    }
    return null;
  }

  public get payRate(): number {
    if (this.earning) {
      return this.earning.payRate;
    }
    return this.standardPayRate;
  }

  public get payPolicyName(): string {
    if (this.payPolicy) {
      return this.payPolicy.name;
    }
    return null;
  }

  public getRuleValue(name: string): any {
    let rules: TimecardsEarningPayRule[] = this.rulesMap[name] || this.rulesMap[_.toUpper(name)] || this.rulesMap[_.toLower(name)];
    if (rules) {
      return _.sumBy(rules, r=> r.value ? r.value : 0 )
    }
    return null;
  }

  public isMoneyRule(name: string): boolean {
    const rules: TimecardsEarningPayRule[] = this.rulesMap[name];
    return rules && _.every(rules, r => r.isMoneyAmountRule);
  }
}
