import { DayTotal, IDayTotal } from './day-total';

export class IScheduleTotal {
  public totalName: string;
  public dayTotals: IDayTotal[];
}

export class ScheduleTotal {
  public totalName: string;
  public dayTotals: StringMap<DayTotal>;
}



export class Total {
  public name: string;
  public type: string = 'total';
  public shchedule: StringMap<number>;
}
