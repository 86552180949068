<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents">
      <slx-aca-1095-c-toolbar></slx-aca-1095-c-toolbar>
    </div>
    <div class="insight_section">
      <slx-aca-1095-c-insights></slx-aca-1095-c-insights>
    </div>
    <div class="slx-high-box__body slx-main-content-indents grid-1095c">
      <slx-aca-1095-c-grid></slx-aca-1095-c-grid>
    </div>
  </div>
</slx-spinner>