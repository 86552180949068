import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../common/services/index';
import { BenefitDetailsManagementService, BenefitDetailsPermissionService } from '../../../services/index';
var BenefitDetailsToolbarComponent = /** @class */ (function () {
    function BenefitDetailsToolbarComponent(manService, permissionService, activatedRoute, router) {
        this.manService = manService;
        this.permissionService = permissionService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.enabledExpire = false;
        this.enabledExtend = false;
        this.enabledRenew = false;
        this.canExpire = false;
        this.canExtend = false;
        this.canRenew = false;
        this.benefitDeduction2 = false;
        this.subscriptions = {};
        this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
    }
    BenefitDetailsToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.manService
            .subscribeToOrgLevel(function (v) { return (_this.orgLevel = v); });
        this.subscriptions.selectProviderLine = this.manService
            .subscribeToSelectProviderLine(function (pl) {
            _this.selectedProviderLine = pl.current;
            _this.nextLineDetails = pl.nextLineDetails;
        });
        this.subscriptions.userActions = this.permissionService
            .subscribeToUserActions(function (v) {
            _this.enabledExpire = v.enabledExpire;
            _this.enabledExtend = v.enabledExtend;
            _this.enabledRenew = v.enabledRenew;
            _this.canExpire = v.canExpire;
            _this.canExtend = v.canExtend;
            _this.canRenew = v.canRenew;
        });
        this.subscriptions.edit = this.permissionService.subscribeToEditMode(function (mode) {
            _this.isEditMode = mode.benefitInfo || mode.coverageOptions || mode.providerInfo;
        });
    };
    BenefitDetailsToolbarComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(BenefitDetailsToolbarComponent.prototype, "canMakeExpire", {
        get: function () {
            return this.enabledExpire && this.canExpire;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsToolbarComponent.prototype, "canMakeExtend", {
        get: function () {
            return this.enabledExtend && this.canExtend;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsToolbarComponent.prototype, "canMakeRenew", {
        get: function () {
            return this.enabledRenew && this.canRenew;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsToolbarComponent.prototype, "isShowingExpiredPlans", {
        get: function () {
            return this.manService.isLoadingExpiredPlans;
        },
        set: function (v) {
            this.manService.isLoadingExpiredPlans = v;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsToolbarComponent.prototype.onExpirePlan = function (date) {
        if (this.canExpire) {
            this.manService.expirePlan(date);
        }
    };
    BenefitDetailsToolbarComponent.prototype.onExtendPlan = function (date) {
        if (this.canExtend) {
            this.manService.extendPlan(date);
        }
    };
    BenefitDetailsToolbarComponent.prototype.onRenewPlan = function (date) {
        if (this.canRenew) {
            this.manService.renewPlan(date);
        }
    };
    BenefitDetailsToolbarComponent.prototype.onClickOverview = function () {
        this.navService.navigateToConsole(this.orgLevel.id);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsToolbarComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsToolbarComponent;
}());
export { BenefitDetailsToolbarComponent };
