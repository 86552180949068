import { Component, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';

import { unsubscribe } from '../../../../core/decorators/index';
import { ArrivalsDeparturesTimelineDetailsManagementService } from '../../../services/index';
import { Lookup, LookupType, LocationUnit, EmployeeDefinition } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';
import { ArrivalDeparturesTimelineFilter, ArrivalDeparturesVariances, ArrivalsDeparturesDetails, ArrivalsDeparturesDetailsRecord } from '../../../models/index';
import { ToolbarSectionTypes } from '../../../../common/models/index';

export type variancesValue = { value: ArrivalDeparturesVariances };

@Component({
  moduleId: module.id,
  selector: 'slx-arrivals-departures-timeline-details-toolbar',
  templateUrl: 'arrivals-departures-timeline-details-toolbar.component.html',
  styleUrls: ['arrivals-departures-timeline-details-toolbar.component.scss']
})
export class ArrivalsDeparturesTimelineDetailsToolbarComponent implements OnChanges, OnInit {
  @Input()
  public orgLevelId: number;

  public unitFilter: LocationUnit[];
  public variancesFilter: variancesValue[];
  public isShowOverages: boolean;
  public appliedCommonFilterItems: EmployeeDefinition[];

  public commonFilterItems: EmployeeDefinition[];
  public units: LocationUnit[];
  public variances: variancesValue[];
  public filter: ArrivalDeparturesTimelineFilter;
  public isExpanded: boolean;


  @unsubscribe()
  private onFilterChangedSubscription: Subscription;
  @unsubscribe()
  private onLoadSubscription: Subscription;
  @unsubscribe()
  private toggleOverrageSubscription: Subscription;

  public constructor(private managementService: ArrivalsDeparturesTimelineDetailsManagementService, private lookupService: LookupService) {
    this.variances = [
      { value: ArrivalDeparturesVariances.late },
      { value: ArrivalDeparturesVariances.approachingOT },
      { value: ArrivalDeparturesVariances.arrivals },
      { value: ArrivalDeparturesVariances.departures }
    ];
  }

  public ngOnInit(): void {
    this.onLoadSubscription = this.managementService.onLoaded$.subscribe((details: ArrivalsDeparturesDetails) => {
      this.commonFilterItems = _.map(details.records, (r: ArrivalsDeparturesDetailsRecord) => r.employee);
      this.onLoadSubscription.unsubscribe();
    });

    this.toggleOverrageSubscription = this.managementService.onToogleShowOverragesView$.subscribe((value: boolean) => {
      this.isShowOverages = value;
    });

    this.onFilterChangedSubscription = this.managementService.onFilterChanged$.subscribe((filter: ArrivalDeparturesTimelineFilter) => {
      this.filter = filter;
      this.unitFilter = filter.units;
      this.appliedCommonFilterItems = filter.empFilter;
      this.variancesFilter = _.map(filter.variances, (v: variancesValue) => { return { value: v }; });
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.orgLevelId) {
      this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: this.orgLevelId })
        .then((locationUnitLookup: Lookup) => {
          this.units = locationUnitLookup.items;
        });
    }
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onUnitChanged(): void {
    this.filter.units = this.unitFilter;
    this.managementService.onFilterChanged(this.filter);
  }
  public onVariancesChanged(): void {
    this.filter.variances = _.map(this.variancesFilter, (v: variancesValue) => v.value);
    this.managementService.onFilterChanged(this.filter);
  }
  public onShowOveragesChanged(): void {
    this.managementService.toggleShowOverrages(this.isShowOverages);
  }

  public onCommonFilterChanged(): void {
    this.filter.empFilter = this.appliedCommonFilterItems;
    this.managementService.onFilterChanged(this.filter);
  }

  public onModeChanged(): void {
    this.managementService.toggleTimeline(this.isExpanded ? 'Simple' : 'GroupByShift');
  }

  public isVisibleSection(sectionType: ToolbarSectionTypes): boolean {
    return sectionType === ToolbarSectionTypes.VISIBLE;
  }
}
