<div class="ip-desktop" *slxHiddenOn="['tablet', 'mobile']">
    <div class="ip-desktop-header">
        <div class="headder-switchs">
            <button        
                type="button" (click)="onStartAdd()" [disabled]="!isCanEdit"
                class="slx-button slx-blue slx-margin-r slx-mobile-adapted">
                <span class="slx-button__text"><i class="fa fa-plus" aria-hidden="true"> </i> Add </span>
            </button>

            <slx-checkbox-toggle class="active-only-field" className="toggle-right slx-green-toolbar-switch"
              [isDisabled]="!isCanEdit" 
              [(ngModel)]="enforceWhitelistRestrictions" 
              (ngModelChange)="onWhitelistChanged($event)"
              caption="Enforce Allowlist Restrictions"
              checkedText="Yes"
              uncheckedText="No"></slx-checkbox-toggle>

            <slx-checkbox-toggle class="active-only-field" className="toggle-right slx-green-toolbar-switch"
            [isDisabled]="!isCanEdit" 
            [(ngModel)]="enforceBlacklistRestrictions" 
            (ngModelChange)="onBlacklistChanged($event)"
            caption="Enforce Denylist Restrictions"
            checkedText="Yes"
            uncheckedText="No"></slx-checkbox-toggle>
        </div>
        <div class="headder-action-btn"> 
          <slx-action-list class="headder-action-drop-btn">
                <slx-action-button [dropDownIcon]="'fas fa-caret-down'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
                <popper-content #popperContent>
                <slx-action-list-item (onClick)="onClickExport(false)" [disabled]="!isCanExport">Export to Excel</slx-action-list-item>
                <slx-action-list-item (onClick)="onClickExport(true)" [disabled]="!isCanExport">Export to PDF</slx-action-list-item>
                </popper-content>
            </slx-action-list>
        </div>
    </div>

<div class="ip-desktop-body" >
    <div class="ip-tabs flex">

        <div class="ip-tabs__slider">
          <ul class="ip-tabs__list flex flex-direction-row">
            <li class="ip-tabs__item" [class.active]="isActiveMenu(0)" (click)="onClickTab(0)"><span class="header-title__text">Allowlist</span>
            </li>
            <li class="ip-tabs__item" [class.active]="isActiveMenu(1)" (click)="onClickTab(1)"><span class="header-title__text">Denylist</span></li>
          </ul>
        </div>
    
        <ng-container *ngIf="!activeTab">
          <slx-ip-whitelisting></slx-ip-whitelisting>
        </ng-container>
        <ng-container *ngIf="activeTab">
            <slx-ip-blacklisting></slx-ip-blacklisting>
        </ng-container>
      </div>
</div>
</div>
