import * as _ from 'lodash';
import { Component, Input, Host } from '@angular/core';
import { PopperContent } from 'ngx-popper';

@Component({
  moduleId: module.id,
  selector: 'slx-action-list',
  templateUrl: 'action-list.component.html',
})
export class ActionListComponent {
  @Input('className')
  public customClassName: string;

  @Input()
  public fullyCustomStyle: boolean = false;

  private defaultCssClasse: string = 'slx-action-list';

  public get className(): string {
    const classes: string[] = [];
    if (_.isString(this.customClassName) && _.size(this.customClassName) > 0) {
      classes.push(this.customClassName);
    }
    if (!this.fullyCustomStyle) {
      classes.push(this.defaultCssClasse);
    }

    return classes.join(' ');
  }
}
