/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./predictor-matrix-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../predictor-matrix/predictor-matrix.component.ngfactory";
import * as i3 from "../predictor-matrix/predictor-matrix.component";
import * as i4 from "./predictor-matrix-dialog.component";
import * as i5 from "../../../../common/models/dialog-options";
import * as i6 from "../../../../common/services/modal/modal.service";
var styles_PredictorMatrixDialogComponent = [i0.styles];
var RenderType_PredictorMatrixDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PredictorMatrixDialogComponent, data: {} });
export { RenderType_PredictorMatrixDialogComponent as RenderType_PredictorMatrixDialogComponent };
export function View_PredictorMatrixDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { matrixComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "headless-modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-staffing-predictor-matrix", [], null, null, null, i2.View_PredictorMatrixComponent_0, i2.RenderType_PredictorMatrixComponent)), i1.ɵdid(4, 114688, null, 0, i3.PredictorMatrixComponent, [], { organizationInfo: [0, "organizationInfo"], staffingPointsTables: [1, "staffingPointsTables"], five_star_v2_enabled: [2, "five_star_v2_enabled"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "modal-footer center-align"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "btn theme-button-apply bolder-btn-text"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.organizationInfo; var currVal_1 = _co.staffingPointsTables; var currVal_2 = _co.five_star_v2_enabled; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PredictorMatrixDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-matrix-dialog", [], null, null, null, View_PredictorMatrixDialogComponent_0, RenderType_PredictorMatrixDialogComponent)), i1.ɵdid(1, 49152, null, 0, i4.PredictorMatrixDialogComponent, [i5.DialogOptions, i6.ModalService], null, null)], null, null); }
var PredictorMatrixDialogComponentNgFactory = i1.ɵccf("slx-matrix-dialog", i4.PredictorMatrixDialogComponent, View_PredictorMatrixDialogComponent_Host_0, {}, {}, []);
export { PredictorMatrixDialogComponentNgFactory as PredictorMatrixDialogComponentNgFactory };
