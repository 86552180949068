import { Injectable } from '@angular/core';
import { IAppState } from '../../store';
import { NgRedux } from '@angular-redux/store';
import { OrganizationTreeContext } from '../../organization/models/index';
import { OrgLevel } from '../../state-model/models/index';

@Injectable()
export class OrgLevelActions {
  public static DESELECT_ORG_LEVEL: string = 'DESELECT_ORG_LEVEL';
  public static SELECT_ORG_LEVEL: string = 'SELECT_ORG_LEVEL';
  public static SELECT_ORG_LEVEL_BYID: string = 'SELECT_ORG_LEVEL_BYID';
  public static SELECT_ORG_LEVEL_SUCCESS: string = 'SELECT_ORG_LEVEL_SUCCESS';

  private ngRedux: NgRedux<IAppState>;

  constructor(ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }

  public select(context: OrganizationTreeContext, orgLevel: OrgLevel): void {
    this.ngRedux.dispatch({
      type: OrgLevelActions.SELECT_ORG_LEVEL,
      payload: {
        orgLevel: orgLevel,
        context: context
      }
    });
  }

  public deselect(): void {
    this.ngRedux.dispatch({
      type: OrgLevelActions.DESELECT_ORG_LEVEL,
      payload: null
    });
  }
}

export class OrgLevelActionsMock {
  public static GET_LAST_SELECTED_ORG_LEVEL: string = 'GET_LAST_SELECTED_ORG_LEVEL';
  public static GET_LAST_SELECTED_ORG_LEVEL_SUCCESS: string = 'GET_LAST_SELECTED_ORG_LEVEL_SUCCESS';
  public static GET_LAST_SELECTED_ORG_LEVEL_ERROR: string = 'GET_LAST_SELECTED_ORG_LEVEL_ERROR';
  public static SELECT_ORG_LEVEL: string = 'SELECT_ORG_LEVEL';
  public static ORG_LEVEL_SELECTED: string = 'SELECT_ORG_LEVEL_SUCCESS';

  public select(orgLevel: any): void {
    /*nothing to do*/
  }
}
