import { Injectable } from '@angular/core';
import { JwtService } from '../../../core/services/index';
import { Assert } from '../../../framework/index';
import { UserApplication, User, Session, AuthResponse } from '../../../state-model/models/index';
import * as _ from 'lodash';
import { ForgotPassword, ResetPassword } from '../../models/password-reset';

export interface IAuthenticationDataContainer {
  response: IAuthenticationData;
  status: {
    code: number;
    description: string;
  };
}

export interface IAuthenticationData {
  token: string;
  userApplications: IUserApplication[];
  userInfo: IUserInfo;
  isIPAllowed :boolean;
  isSmartAdmin:boolean;
}

export interface IAuthenticationTokenData {
  alias: string;
  sub: string;
  given_name: string;
  user_id: string;
  email: string;
  role: string | string[];
  linked_emp_id: string;
}

export interface IUserApplication {
  id: number;
  name: string;
  displayName: string;
}

export interface IForgotPassword {
  code: number;
  message: string;
  userMaskedEmail: string;
  adminNames: string[];
}

export interface IResetPassword {
  code: number;
  message: string;
}

export interface IUserInfo {
  userId: number;
  messagesUnread: number;
  remidersUnread: number;
  isSmartAdmin: boolean;
  password: string;
}

@Injectable()
export class AuthenticationMapService {
  private jwtService: JwtService;

  constructor(jwtService: JwtService) {
    Assert.isNotNull(jwtService, 'jwtService');
    this.jwtService = jwtService;
  }

  public mapToAuthResponse(container: IAuthenticationDataContainer): AuthResponse {
    let authResponse: AuthResponse = new AuthResponse();
    authResponse.session = container.response ? this.mapToModel(container.response) : null;
    authResponse.statusCode = container.status.code;
    authResponse.description = container.status.description;
    return authResponse;
  }

  public mapToModel(data: IAuthenticationData): Session {
    Assert.isNotNull(data, 'data');
    Assert.isNotNull(data.token, 'data.token');

    let tokenData: IAuthenticationTokenData = this.jwtService.decodeToken<IAuthenticationTokenData>(data.token);
    let session: Session = new Session(data.token);
    session.isIPAllowed=data.isIPAllowed;    
    session.clientTimeShift = this.jwtService.getClientTimeShift(this.jwtService.getTokenIssuedDate(session.token));

    session = this.mapTokenToSessionData(session, tokenData);
    session.user.applications = _.map(data.userApplications || [], (applicationData: IUserApplication) => this.mapDataToUserApplication(applicationData));
    if (data.userInfo) {
      session.user.messagesUnread = data.userInfo.messagesUnread;
      session.user.remidersUnread = data.userInfo.remidersUnread;
      session.user.isSmartAdmin = data.userInfo.isSmartAdmin;
    }
    return session;
  }

  public mapForgotPassword(data: IForgotPassword): ForgotPassword {
    Assert.isNotNull(data, 'data');
    Assert.isNotNull(data.code, 'data.code');

    let forgotPassword: ForgotPassword = new ForgotPassword();

    forgotPassword.adminNames = data.adminNames;
    forgotPassword.code = data.code;
    forgotPassword.message = data.message;
    forgotPassword.userMaskedEmail = data.userMaskedEmail;

    return forgotPassword;
  }

  public mapResetPassword(data: IResetPassword): ResetPassword {
    Assert.isNotNull(data, 'data');
    Assert.isNotNull(data.code, 'data.code');

    let resetPassword: ResetPassword = new ResetPassword();

    resetPassword.code = data.code;
    resetPassword.message = data.message;

    return resetPassword;
  }

  private mapTokenToSessionData(session: Session, data: IAuthenticationTokenData): Session {
    Assert.isNotNull(session, 'session');
    Assert.isNotNull(data, 'data');
    Assert.isNotNull(data.user_id, 'data.user_id');
    Assert.isNotNull(data.sub, 'data.sub');
    Assert.isNotNull(data.given_name, 'data.given_name ');

    session.alias = data.alias;

    session.user = new User();
    session.user.id = +data.user_id;
    session.user.username = data.sub;
    session.user.name = data.given_name;
    session.user.linkedEmpId = +data.linked_emp_id;
    session.user.email = data.email;

    if (_.isString(data.role)) {
      session.user.roles = [data.role];
    } else if (_.isArray(data.role)) {
      session.user.roles = data.role;
    }

    return session;
  }

  private mapDataToUserApplication(data: IUserApplication): UserApplication {
    Assert.isNotNull(data, 'data');
    Assert.isNotNull(data.id, 'data.id');
    Assert.isNotNull(data.name, 'data.name');

    let application: UserApplication = new UserApplication(data.id);
    application.name = data.name;
    application.title = data.displayName;
    application.link = data.name.toLowerCase();

    return application;
  }
}
