import * as _ from 'lodash';
import { Component, Provider } from '@angular/core';

import { DialogOptions, IDialog } from '../../../common/models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { AccrualBalanceDialogOptions } from '../../../organization/models/index';
import { AppServerConfig } from './../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../app-settings/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-accrual-balance-dialog',
  templateUrl: 'accrual-balance-dialog.component.html',
  styleUrls: ['accrual-balance-dialog.component.scss']
})
export class AccrualBalanceDialogComponent implements IDialog {
  public employeeAccruals: AccrualBalanceDialogOptions;
  public dialogResult: boolean;
  private options: DialogOptions;
  private modalService: ModalService;
  public EnableRate4DecimalPlaces: boolean;

  public static openDialog(data: AccrualBalanceDialogOptions, modalService: ModalService): AccrualBalanceDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 400;
    dialogOptions.width = 400;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: AccrualBalanceDialogOptions,
        useValue: data
      }
    ];
    let dialog: AccrualBalanceDialogComponent = modalService.globalAnchor.openDialog(
      AccrualBalanceDialogComponent,
      'Accrual balance',
      dialogOptions,
      resolvedProviders);

    return dialog;
  }

  constructor(
    employeeAccruals: AccrualBalanceDialogOptions,
    options: DialogOptions,
    modalService: ModalService,
    private appSettingsManageService: AppSettingsManageService
  ) {
    this.employeeAccruals = employeeAccruals;
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public ngOnInit(): void {
    this.getSettings();
  }

  public get hasAccruals(): boolean {
    return _.isArray(this.employeeAccruals.accruals) && _.size(this.employeeAccruals.accruals) > 0;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.EnableRate4DecimalPlaces =  config.EnableRate4DecimalPlaces;
  }

  public get isShowHighPrecision(): boolean {
    return this.EnableRate4DecimalPlaces;
  }
}
