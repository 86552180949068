export interface IAgencyDTO {
    id: number;
    name: string;
    address: string;
    state: string;
    city: string;
    zip: string;
    employeeCount: number;
    lastUpdateDate: string;
    lastUpdateName: string;
}

export class AgencyModel {
    public id: number;
    public name: string;
    public address: string;
    public state: string;
    public city: string;
    public zip: string;
    public employeeCount: number = 0;
    public lastUpdateDate: Date;
    public lastUpdateName: string;
}
