import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { DayOfWeek, Month } from '../../../../common/models/index';
import { LookupService } from '../../../../organization/services/index';
import { Lookup, LookupType } from '../../../../organization/models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { Holiday, HolidayRule } from '../../../models/index';
import { ModalService } from '../../../../common/services/index';
import { NgForm } from '@angular/forms';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { HolidaysConfigurationManagementService } from '../../../services/holidays/holidays-configuration-management.service';

@Component({
  moduleId: module.id,
  selector: 'slx-holidays-editor',
  templateUrl: 'holidays-editor.component.html',
  styleUrls: ['holidays-editor.component.scss'],
})

export class HolidaysEditorComponent implements OnInit, OnDestroy {

  @Input()
  public holidays: Holiday[];

  @Input()
  public set item(value: Holiday) {
    this.m_item = value;
    if (this.m_item) {
      this.editingItem = new Holiday();
      this.copyFields(value, this.editingItem);
    } else {
      this.editingItem = new Holiday();
    }
  }

  @Input()
  public set year(value: number) {
    this.m_year = value;
  }

  @Output()
  public save: EventEmitter<boolean>;

  @Output()
  public cancel: EventEmitter<boolean>;

  public get holiday(): Holiday {
    return this.editingItem;
  }

  public get minDate(): Date {
    return this.m_minDate;
  }

  public get maxDate(): Date {
    return this.m_maxDate;
  }

  public appConfig: IApplicationConfig = appConfig;

  public dialogResult: boolean;
  public prohibitedNameValues: string[] = [];


  @ViewChild('editForm', { static: false })
  private set mainForm(value: NgForm) {
    this.m_mainForm = value;
    if (this.m_mainForm && !this.mainFormSubscription) {
      this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
        if (this.mainForm.dirty) {
          this.management.markAsDirty();
        }
      });
    }
  }

  private get mainForm() {
    return this.m_mainForm;
  }

  private m_mainForm: NgForm;

  @unsubscribe()
  private mainFormSubscription: Subscription;

  private lookupService: LookupService;

  private holidayOrigins: any[];
  private holidayDayPatterns: any[];
  private days: DayOfWeek[];
  private months: Month[];
  private m_year: number;
  private m_minDate: Date;
  private m_maxDate: Date;

  private m_item: Holiday;
  private editingItem: Holiday;

  constructor(lookupService: LookupService, private modalService: ModalService, private management: HolidaysConfigurationManagementService) {
    this.lookupService = lookupService;
    this.save = new EventEmitter<boolean>();
    this.cancel = new EventEmitter<boolean>();
  }

  public ngOnInit(): void {

    this.lookupService.getLookup({ lookupType: LookupType.holidayOrigins })
      .then((holidayOrigins: Lookup) => {
        this.holidayOrigins = holidayOrigins.items;
      });

    this.lookupService.getLookup({ lookupType: LookupType.holidayDayPatterns })
      .then((holidayDayPatterns: Lookup) => {
        this.holidayDayPatterns = holidayDayPatterns.items;
      });

    this.lookupService.getLookup({ lookupType: LookupType.daysOfWeek })
      .then((days: Lookup) => {
        this.days = days.items;
      });

    this.lookupService.getLookup({ lookupType: LookupType.months })
      .then((months: Lookup) => {
        this.months = months.items;
      });

    this.m_minDate = new Date(this.m_year - 1, 11, 31);
    this.m_maxDate = new Date(this.m_year + 1, 0, 1);

    if (!this.editingItem.id) {
      this.editingItem.date.setFullYear(this.m_year);
      this.editingItem.start.setFullYear(this.m_year);
      this.editingItem.end.setFullYear(this.m_year);
    }
  }

  public ngOnDestroy(): void {
    if (this.mainFormSubscription) {
      this.mainFormSubscription.unsubscribe();
      this.mainFormSubscription = null;
    }
  }

  public onSave(e: any): void {
    e.preventDefault();
    if (this.holidays) {
      let existingDate;
      if (this.editingItem.id) {
        existingDate = _.find(this.holidays, (h: Holiday) => {
          return this.editingItem.id !== h.id && moment(h.date).isSame(this.editingItem.date, 'day');
        });
      } else {
        existingDate = _.find(this.holidays, (h: Holiday) => {
          return moment(h.date).isSame(this.editingItem.date, 'day');
        });
      }
      if (existingDate) {
        this.modalService.globalAnchor.openInfoDialog('Warning', `The date ${dateTimeUtils.convertToDtoString(existingDate.date)} has already been entered into the calendar`);
        return;
      }
    }
    this.copyFields(this.editingItem, this.m_item);
    this.save.next(true);
  }

  public onCancel(e: any): void {
    e.preventDefault();
    this.cancel.next(false);
  }

  public get origin(): any {
    if (this.holiday) {
      return _.find(this.holidayOrigins, (value: any) => value.id === this.holiday.origin);
    }
    return null;
  }

  public set origin(value: any) {
    this.holiday.origin = value.id;
  }


  public get ruleDayDefiningPattern(): any {
    if (this.rule) {
      return _.find(this.holidayDayPatterns, (value: any) => value.id === this.rule.dayDefiningPattern);
    }
    return null;
  }

  public set ruleDayDefiningPattern(value: any) {
    if (!this.rule)
      this.rule = new HolidayRule();
    this.rule.dayDefiningPattern = value.name;
  }

  public get ruleDayOfWeek(): DayOfWeek {
    if (this.rule) {
      return _.find(this.days, (value: DayOfWeek) => value.name === this.rule.dayOfWeek);
    }
    return null;
  }

  public set ruleDayOfWeek(value: DayOfWeek) {
    if (!this.rule)
      this.rule = new HolidayRule();
    this.rule.dayOfWeek = value.name;
  }

  public get ruleMonth(): Month {
    if (this.rule) {
      return _.find(this.months, (value: Month) => value.id === this.rule.month);
    }
    return null;
  }

  public set ruleMonth(value: Month) {
    if (!this.rule)
      this.rule = new HolidayRule();
    this.rule.month = value.id;
  }

  public holidayDateChanged(e: any): void {
    if (this.holiday.date) {
      let d: number = this.holiday.date.getTime();
      this.holiday.start = new Date(d);
      this.holiday.end = new Date(d);
    }
  }

  public autoPopulateDate(): void {
    if (this.ruleDayDefiningPattern && this.ruleDayOfWeek && this.ruleMonth) {
      let weekOfMonth: number = this.ruleDayDefiningPattern.momentId;
      let dayNumber: number = this.ruleDayOfWeek.dayNumber - 1;
      let month: number = this.ruleMonth.id - 1;
      let control: Date = this.getPureDateFromRule(0, dayNumber, month, this.m_year);
      if (control.getMonth() !== month) {
        weekOfMonth++;
      }
      let date: Date = this.getPureDateFromRule(weekOfMonth, dayNumber, month, this.m_year);
      if (date.getMonth() !== month) {
        date = this.getPureDateFromRule(weekOfMonth - 1, dayNumber, month, this.m_year);
      }
      this.holiday.date = date;
      this.holidayDateChanged(null);
      this.holiday.end.setDate(this.holiday.end.getDate() + 1);
      this.m_minDate = new Date(this.m_year - 1, 11, 31);
      this.m_maxDate = new Date(this.m_year + 1, 0, 1);
    }
  }

  private getPureDateFromRule(weekOfMonth: number, dayNumber: number, month: number, year: number): Date {
    return moment().year(year).month(month).date(1).day(dayNumber + weekOfMonth * 7).toDate();
  }

  private get rule(): HolidayRule {
    if (!this.holiday)
      return null;
    return this.holiday.rule;
  }

  private set rule(value: HolidayRule) {
    this.holiday.rule = value;
  }

  private copyFields(from: Holiday, to: Holiday): void {
    to.id = from.id;
    to.date = moment(from.date).toDate();
    to.start = moment(from.start).toDate();
    to.end = moment(from.end).toDate();
    to.code = from.code;
    to.type = from.type;
    to.origin = from.origin;
    to.name = from.name;
    if (from.rule) {
      to.rule = new HolidayRule();
      to.rule.month = from.rule.month;
      to.rule.dayOfWeek = from.rule.dayOfWeek;
      to.rule.dayDefiningPattern = from.rule.dayDefiningPattern;
    }
  }

}
