import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-button-collapse-panel',
  templateUrl: 'button-collapse-panel.component.html',
  styleUrls: ['button-collapse-panel.component.scss']
})
export class ButtonCollapsePaneComponent {
  @Input()
  public hideButton: boolean;

  @Input()
  public title: string;

  @Input()
  public collapsedTitle: string;

  @Input()
  public expandedTitle: string;

  @Input()
  public set collapsed(val: boolean) {
    if (val) {
      this.onCollapse();
    } else {
      this.onExpand();
    }
  }

  public isCollapsed: boolean;
  public isExpanding: boolean;

  public onCollapse(): void {
    this.isExpanding = false;
    this.isCollapsed = true;
    if (this.collapsedTitle)
      this.title = this.collapsedTitle;
  }

  public onExpand(): void {
    this.isExpanding = true;
    this.isCollapsed = false;
    setTimeout(() => {
      this.isExpanding = false;
    }, 1000);
    if (this.expandedTitle)
      this.title = this.expandedTitle;
  }

  public onToggle(): void {
    if (!this.isCollapsed) {
      this.onCollapse();
    } else {
      this.onExpand();
    }
  }
}
