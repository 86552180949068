import * as _ from 'lodash';
var LeaveRequestEntries = /** @class */ (function () {
    function LeaveRequestEntries() {
    }
    return LeaveRequestEntries;
}());
export { LeaveRequestEntries };
var LeaveRequestEntry = /** @class */ (function () {
    function LeaveRequestEntry() {
    }
    return LeaveRequestEntry;
}());
export { LeaveRequestEntry };
var LeaveRequestDetailEntries = /** @class */ (function () {
    function LeaveRequestDetailEntries() {
    }
    return LeaveRequestDetailEntries;
}());
export { LeaveRequestDetailEntries };
var LeaveRequestDetailEntry = /** @class */ (function () {
    function LeaveRequestDetailEntry() {
    }
    Object.defineProperty(LeaveRequestDetailEntry.prototype, "repStatus", {
        get: function () {
            return _.isString(this.replacement) ? this.replacement : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaveRequestDetailEntry.prototype, "hasReplacedEmployee", {
        get: function () {
            return _.isObject(this.replacedEmployee);
        },
        enumerable: true,
        configurable: true
    });
    return LeaveRequestDetailEntry;
}());
export { LeaveRequestDetailEntry };
