import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../app/app.config';
import { screenUtils } from '../../../../../app/common/utils/screenUtils';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { InfoDialogComponent } from '../../../../common/components/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../common/services/index';
import { WorkCompTableTypeModel, WcCodeDefinition } from '../../models/index';
import { WorkersCompensationTableApiService } from '../../services/index';
import { Lookup } from '../../../../organization/models/lookup/lookup-definition';
var WorkersCompensationTableComponent = /** @class */ (function () {
    function WorkersCompensationTableComponent(apiService, stateManagement, columnManagementService, modalService) {
        this.apiService = apiService;
        this.stateManagement = stateManagement;
        this.columnManagementService = columnManagementService;
        this.modalService = modalService;
        this.pageSize = 50;
        this.columnGroup = 'WorkerCompensation';
        this.prohibitedCodeValues = [];
        this.codeTypeLookupValues = [];
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.appConfig = appConfig;
        this.screenUtils = screenUtils;
        this.canEdit = true;
    }
    WorkersCompensationTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('WorkersCompensationTableComponent');
        this.columnManagementService.init('WorkersCompensationTableComponent');
        this.columnManagementService.initGroup(this.columnGroup, 6);
        this.subscribeToOrgLevel();
        this.loadCodeTypes();
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            _this.isLoading = true;
            if (event.isNew) {
                _this.doAdd(event.dataItem);
                return;
            }
            _this.doEdit(event.dataItem, event.rowIndex);
        });
        this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            _this.doRemove(event.dataItem, event.rowIndex);
        });
    };
    WorkersCompensationTableComponent.prototype.doAdd = function (dataItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.createcodeDefinition(dataItem)
                        .then(function (res) {
                        if (res.isSuccess) {
                            _this.records.push(res.item);
                            _this.prohibitedCodeValues = _.map(_this.records, function (m) { return m.codeDefinition.code; });
                            _this.reload();
                        }
                        else {
                            InfoDialogComponent.OpenDialog('Warning', res.message, _this.modalService);
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    WorkersCompensationTableComponent.prototype.doEdit = function (dataItem, rowIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.modifycodeDefinition(dataItem)
                        .then(function (res) {
                        if (res.isSuccess) {
                            _this.records.splice(rowIndex, 1, res.item);
                            _this.prohibitedCodeValues = _.map(_this.records, function (m) { return m.codeDefinition.code; });
                            _this.reload();
                        }
                        else {
                            _this.reload();
                            InfoDialogComponent.OpenDialog('Warning', res.message, _this.modalService);
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    WorkersCompensationTableComponent.prototype.doRemove = function (dataItem, rowIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                return [2 /*return*/, this.apiService.deleteCodeDefinition(dataItem.codeDefinition.id)
                        .then(function (res) {
                        if (res.isSuccess) {
                            _this.records.splice(rowIndex, 1);
                            _this.prohibitedCodeValues = _.map(_this.records, function (m) { return m.codeDefinition.code; });
                            _this.reload();
                        }
                        else {
                            InfoDialogComponent.OpenDialog('Warning', res.message, _this.modalService);
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    WorkersCompensationTableComponent.prototype.loadCodeTypes = function () {
        var _this = this;
        return this.apiService.getCodeTypes()
            .then(function (container) {
            _this.codeTypeLookupValues = container;
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    WorkersCompensationTableComponent.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id)
                    return [2 /*return*/];
                this.orgLevel = orgLevel;
                this.reload();
                return [2 /*return*/];
            });
        }); });
    };
    WorkersCompensationTableComponent.prototype.reload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                return [2 /*return*/, this.apiService.getWrkrsCompTableModels(this.orgLevel.id)
                        .then(function (container) {
                        _this.records = container;
                        _this.prohibitedCodeValues = _.map(_this.records, function (m) { return m.codeDefinition.code; });
                        _this.refreshGrid();
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    WorkersCompensationTableComponent.prototype.addType = function () {
        var model = new WorkCompTableTypeModel();
        model.codeDefinition = new WcCodeDefinition();
        this.gridState.closeEditor(this.kendoGrid);
        this.kendoGrid.addRow(model);
    };
    WorkersCompensationTableComponent.prototype.onCodeTypeChange = function (codeType, codeDefinition) {
        this.prohibitedCodeValues = [];
        for (var i in this.records) {
            if (this.records[i].codeDefinition.codeType == codeType) {
                this.prohibitedCodeValues.push(this.records[i].codeDefinition.code);
            }
        }
    };
    WorkersCompensationTableComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    WorkersCompensationTableComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], WorkersCompensationTableComponent.prototype, "orgLevel$", void 0);
    return WorkersCompensationTableComponent;
}());
export { WorkersCompensationTableComponent };
