import { LocationUnit, ShiftDefinition, ShiftGroupDefinition } from '../../../organization/models/index';
import { WeeklyDayCounter } from './weekly-day-counter';
import { WeekDaysWithEmpty } from '../../../common/models/index';
import * as _ from 'lodash';

export class ParLevels {
  public id: number;
  public uniqueId: string;
  public shiftGroup: ShiftGroupDefinition;
  public shift: ShiftDefinition;
  public unit: LocationUnit;
  public isDailyVariance: boolean = false;

  public days: NumberMap<WeeklyDayCounter> = [];

  public get group(): string {
    return this.shiftGroup ? this.shiftGroup.name : '';
  }

  public getDayCounter(dayN: number): number {
    return this.days[dayN].counter;
  }

  public setDayCounter(dayN: number, counter: number): void {
    this.days[dayN].counter = _.round(counter, 2);
  }

  public getDayHours(dayN: number): number {
    return this.days[dayN].hours;
  }

  public setDayHours(dayN: number, hours: number): void {
    this.days[dayN].hours = _.round(hours, 2);
  }

  public isUnused() : boolean {
    for (let dayN: number = 0; dayN <= WeekDaysWithEmpty.length; dayN++) {
      if(this.days[dayN].hours) {
        return false;
      }
    }
    return true;
  }

  public constructor(init?: Partial<ParLevels>) {
    Object.assign(this, init);

    for (let dayN: number = 0; dayN <= WeekDaysWithEmpty.length; dayN++) {
      this.days[dayN] = new WeeklyDayCounter(WeekDaysWithEmpty[dayN], 0, 0);
    }
  }
}
