<div class="w2s-toolbar">
  <slx-toolbar class="w2s-toolbar__helper" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate alignMode="left" [width]="325">
      <span class="w2s-toolbar__control">
        <slx-dropdown-list name="yearDropdown"
        class="slx-wide slx-short"
        [valuePrimitive]="true"
        [options]="yearOptions"        
        [(ngModel)]="selectedYear"        
        placeholder="Payroll Year"
        (ngModelChange)="onYearChange($event)"
        >
        </slx-dropdown-list>
      </span>
      <span class="w2s-toolbar__control w2s-toolbar-label">Companies: <span class="w2s-toolbar-label-blue">{{companyCounts ? companyCounts : 0 }}</span></span>
      <span class="w2s-toolbar__control w2s-toolbar-label">Finalized: <span class="w2s-toolbar-label-green">{{finalizedCounts ? finalizedCounts : 0 }}</span></span>
      <span class="w2s-toolbar__control w2s-toolbar-label">In Progress: <span class="w2s-toolbar-label-red">{{inProgressCounts ? inProgressCounts : 0 }}</span></span>
    </ng-template>


    <ng-template slxToolbarSectionTemplate alignMode="right" [width]="325">
      <div class="right_section">
        <button type="button" class="slx-button theme-button-apply" (click)="onUnPublish()" *ngIf="isAllowedUnublish && isApproved">
          <span class="slx-button__text"> Unpublish all Form W-2 Statements</span>
        </button>
        <div class="spacer"></div>
        <button type="button" class="slx-button theme-button-apply" (click)="onPublish()" *ngIf="isAllowedPublish && isUnApproved">
          <span class="slx-button__text"> Publish all Form W-2 Statements</span>
        </button>
      </div>
    </ng-template> 
  </slx-toolbar>
</div>

