import { EmployeeSectionsBase } from '../employee-sections-base';
import { FieldData, FieldsMeta } from '../../../../core/models/index';

export interface ITelepunchSubsection {
    phonepunchEnabled: string;
    phonepunchRestrictions: IPhonePunchRestrictions[];
}

export class TelepunchSubsection extends EmployeeSectionsBase {
    public phonepunchEnabled: FieldData<boolean>;
    public restrictionId:string;
    public phonepunchRestrictions: FieldData<PhonePunchRestrictions[]>;
}  

export interface IPhonePunchRestrictions {
    id: string;
    name: string;
}

export class PhonePunchRestrictions {
    public id: string;
    public name: string;
} 