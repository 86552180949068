<ng-container *ngIf="isMinMaxValue">
  <div class="slx-input-range">
    <slx-number
      #numericValue="ngModel"
      class="slx-wide slx-input-number slx-rule-input slx-input-num__width"
      [autoCorrect]="false"
      [(ngModel)]="minValue"
      (ngModelChange)="onValueChanged($event)"
      name="numeric-min-value-{{ uniqueComponentId }}"
      [required]="true"
      [decimals]="2"
      [slxMax]="maxLimitForMinValue"
    ></slx-number>
    <slx-number
      #numericValue="ngModel"
      class="slx-wide slx-input-number slx-rule-input slx-input-num__width"
      [autoCorrect]="false"
      [(ngModel)]="maxValue"
      (ngModelChange)="onValueChanged($event)"
      name="numeric-max-value-{{ uniqueComponentId }}"
      [required]="true"
      [decimals]="2"
      [slxMin]="minLimitForMaxValue"
    ></slx-number>
  </div>
  <span class="slx-error-block error-box" *ngIf="numericValue.errors?.max || numericValue.errors?.min" errorMessage
    >Min value should be less than max value</span
  >
</ng-container>
<ng-container *ngIf="!isMinMaxValue">
  <slx-number
    #numericValue="ngModel"
    class="slx-wide slx-input-number slx-rule-input"
    [autoCorrect]="false"
    [(ngModel)]="innerValue"
    (ngModelChange)="onValueChanged($event)"
    name="numeric-value-{{ uniqueComponentId }}"
    [required]="true"
    [decimals]="2"
  ></slx-number>
</ng-container>
