<div class="header-list-container">
    <div class="header-list-body">
        <div class="header-list-toolbar">
            <ng-content select=".notification-bar"></ng-content>
        </div>
        <div class="header-list-list">
            <ng-content select=".notification-list"></ng-content>
        </div>
    </div>
    <div x-arrow class="header-list-arrow"></div>
</div>