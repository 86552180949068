import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DialogOptions, IDialog, ModalService } from '../../../../common';
import { NotificationsService } from '../../../../core/components';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { OrgLevel } from '../../../../state-model/models';
import { OpenConfigOrgLevelType, OpenShiftPostionsOrgDetails, openPositionControlConfig } from '../../../models';
import { OpenPositionControlManagementService, OpenPositionsControlApiService } from '../../../services';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ftMultiplicationValueValidator, ptMultiplicationValueValidator, ptMinValueValidator } from '../../../pipes/opc-config-value-validator';
import { OpenPositionControlHelper } from '../open-position-control-helper';

@Component({
  moduleId: module.id,
  selector: 'slx-open-position-control-configuration',
  templateUrl: 'open-position-control-configuration.html',
  styleUrls: ['open-position-control-configuration.scss']
})

export class OpenPositionControlConfigurationComponent implements IDialog, OnInit {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public currentOrgLevel: OrgLevel;
  public isLoading: boolean = true;
  public dialogResult: boolean;
  public modalService: ModalService;
  private options: DialogOptions;
  public configData: openPositionControlConfig;
  private m_initialized: boolean;
  public openPositionConfigForm: FormGroup;
  public orgLevelType: OpenConfigOrgLevelType;
  public deptDetails: OpenShiftPostionsOrgDetails;

  public get initialized(): boolean {
    return this.m_initialized;
  }

  @unsubscribe()
  private orglevelSubscription: Subscription;

  constructor(options: DialogOptions, modalService: ModalService, orgLevelType: OpenConfigOrgLevelType,
    private openPositionsControlApiService: OpenPositionsControlApiService,
    public notificationService: NotificationsService, public fb: FormBuilder) {
    this.options = options;
    this.modalService = modalService;
    this.orgLevelType = orgLevelType;
    this.newOpenPositionConfigForm();
  }

  ngOnInit(): void {
    this.m_initialized = true;
  }

  public newOpenPositionConfigForm() {
    this.openPositionConfigForm = this.fb.group({
      ftMaxWeeklyHours: new FormControl(),
      ftMaxHoursPerCalenderDay: new FormControl(),
      ftMaxNoOfDaysPerCalenderWeek: new FormControl(),
      ptMaxWeeklyHours: new FormControl(),
      ptMaxHoursPerCalenderDay: new FormControl(),
      ptMaxNoOfDaysPerCalenderWeek: new FormControl(),
      ftMinWeeklyHours: new FormControl(),
      ptMinWeeklyHours: new FormControl(),
    }, { validator: [ftMultiplicationValueValidator, ptMultiplicationValueValidator, ptMinValueValidator] })
  }

  public setValue() {
    this.openPositionConfigForm = this.fb.group({
      ftMaxWeeklyHours: new FormControl(this.configData.ftMaxWeeklyHours, [Validators.required, Validators.min(30), Validators.max(65)]),
      ftMaxHoursPerCalenderDay: new FormControl(this.configData.ftMaxHoursPerCalenderDay, [Validators.required, Validators.min(4), Validators.max(24)]),
      ftMaxNoOfDaysPerCalenderWeek: new FormControl(this.configData.ftMaxNoOfDaysPerCalenderWeek, [Validators.required, Validators.min(2), Validators.max(7)]),
      ptMaxWeeklyHours: new FormControl(this.configData.ptMaxWeeklyHours, [Validators.required, Validators.min(5), Validators.max(45)]),
      ptMaxHoursPerCalenderDay: new FormControl(this.configData.ptMaxHoursPerCalenderDay, [Validators.required, Validators.min(5), Validators.max(24)]),
      ptMaxNoOfDaysPerCalenderWeek: new FormControl(this.configData.ptMaxNoOfDaysPerCalenderWeek, [Validators.required, Validators.min(1), Validators.max(7)]),
      ftMinWeeklyHours: new FormControl(this.configData.ftMinWeeklyHours, [Validators.required, Validators.min(4), Validators.max(30)]),
      ptMinWeeklyHours: new FormControl(this.configData.ptMinWeeklyHours, [Validators.required, Validators.min(5), Validators.max(45)]),
    }, { validator: [ftMultiplicationValueValidator, ptMultiplicationValueValidator, ptMinValueValidator] })
  }

  LoadData() {
    this.isLoading = true;
    this.orglevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
      if (this.orgLevelType.orgLevelType === 'Organization') {
        this.openPositionsControlApiService.getOpenPositionControlConfig(this.currentOrgLevel.id).then(data => {
          this.configData = data;
          this.setValue();
          this.isLoading = false;
        });
      }
      if (this.orgLevelType.orgLevelType === 'Department') {
        this.openPositionsControlApiService.getOpenPositionControlConfig(this.orgLevelType.orgLevelId).then(data => {
          this.configData = data;
          this.setValue();
          this.isLoading = false;
        });
      }
    });
  }

  resetValuesToDefault() {
    this.openPositionConfigForm.controls['ftMaxWeeklyHours'].setValue(this.configData.ftMaxWeeklyHoursDefault);
    this.openPositionConfigForm.controls['ftMaxHoursPerCalenderDay'].setValue(this.configData.ftMaxHoursPerCalenderDayDefault);
    this.openPositionConfigForm.controls['ftMaxNoOfDaysPerCalenderWeek'].setValue(this.configData.ftMaxNoOfDaysPerCalenderWeekDefault);
    this.openPositionConfigForm.controls['ptMaxWeeklyHours'].setValue(this.configData.ptMaxWeeklyHoursDefault);
    this.openPositionConfigForm.controls['ptMaxHoursPerCalenderDay'].setValue(this.configData.ptMaxHoursPerCalenderDayDefault);
    this.openPositionConfigForm.controls['ptMaxNoOfDaysPerCalenderWeek'].setValue(this.configData.ptMaxNoOfDaysPerCalenderWeekDefault);
    this.openPositionConfigForm.controls['ftMinWeeklyHours'].setValue(30);
    this.openPositionConfigForm.controls['ptMinWeeklyHours'].setValue(10);
  }

  onKeydown(value: any) {
    if (!((value.keyCode > 95 && value.keyCode < 106)
      || (value.keyCode > 47 && value.keyCode < 58)
      || value.keyCode == 8)) {
      return false;
    }
  }

  discard() {
    this.modalService.closeWindow(this.options.windowUniqueId);
    this.openPositionsControlApiService.isLoadPositionGroupDetails$.next(true);
  }

  save() {
    let data: openPositionControlConfig = new openPositionControlConfig();
    data.orgLevelId = this.configData.orgLevelId;
    data.configurationId = this.configData.configurationId;
    data.ftMaxWeeklyHours = this.openPositionConfigForm.value.ftMaxWeeklyHours;
    data.ftMaxHoursPerCalenderDay = this.openPositionConfigForm.value.ftMaxHoursPerCalenderDay;
    data.ftMaxNoOfDaysPerCalenderWeek = this.openPositionConfigForm.value.ftMaxNoOfDaysPerCalenderWeek;
    data.ptMaxWeeklyHours = this.openPositionConfigForm.value.ptMaxWeeklyHours;
    data.ptMaxHoursPerCalenderDay = this.openPositionConfigForm.value.ptMaxHoursPerCalenderDay;
    data.ptMaxNoOfDaysPerCalenderWeek = this.openPositionConfigForm.value.ptMaxNoOfDaysPerCalenderWeek;
    data.ftMinWeeklyHours = this.openPositionConfigForm.value.ftMinWeeklyHours;
    data.ptMinWeeklyHours = this.openPositionConfigForm.value.ptMinWeeklyHours;

    this.openPositionsControlApiService.saveOpenPositionConfiguration(data).then(data => {
      this.notificationService.success('Configuration saved successfully');
      this.discard();
    });
  }

  public get isFormValid(): boolean {
    return this.openPositionConfigForm.valid;
  }

}

