<slx-spinner [show]="state.isLoading">
  <kendo-grid #kendoGrid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" scrollable="none" [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort" [groupable]="{ showFooter: true }" [group]="gridState.state.group" [filterable]="true" [filter]="gridState.state.filter"
    selectable="true" (selectionChange)="gridState.selectionChange($event)">

    <kendo-grid-column title="Budgeted Group" field="budgetedGroup.description">
      <ng-template kendoGridHeaderTemplate>
        Budgeted Group
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a> {{dataItem?.budgetedGroup?.description}} </a>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Budgeted Position" field="budgetedPosition.description">
      <ng-template kendoGridHeaderTemplate>
        Budgeted Position
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a> {{dataItem?.budgetedPosition?.description}} </a>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Weekday Hours" field="weekdayHours">
      <ng-template kendoGridHeaderTemplate>
        Weekday Hours
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.weekdayHours | number: ".2-2"}}
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
        Sum: {{aggregates['weekdayHours'].sum}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Weekend Hours" field="weekendHours">
      <ng-template kendoGridHeaderTemplate>
        Weekend Hours
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.weekendHours | number: ".2-2"}}
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
        Sum: {{aggregates['weekendHours'].sum}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Pay Rate" field="payRate">
      <ng-template kendoGridHeaderTemplate>
        Pay Rate
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.payRate}}
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
        Sum: {{aggregates['payRate'].sum}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Census Adjust" field="censusAdjust">
      <ng-template kendoGridHeaderTemplate>
        Census Adjust
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value | boolyn}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.censusAdjust | boolyn }}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-boolean-filter [column]="column" [filter]="filter">
        </slx-kendo-grid-boolean-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Last Updated By" field="lastUpdatedBy">
      <ng-template kendoGridEditTemplate let-dataItem>
      </ng-template>
      <ng-template kendoGridHeaderTemplate>
        Last Updated By
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.lastUpdatedBy}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column  title="Last Updated Date" field="lastUpdatedDate">
      <ng-template kendoGridEditTemplate let-dataItem>
      </ng-template>
      <ng-template kendoGridHeaderTemplate>
        Last Updated By
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value | amDateFormat: appConfig.dateFormat}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.lastUpdatedDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-date-filter>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</slx-spinner>
