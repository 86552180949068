import * as tslib_1 from "tslib";
import { DialogOptions2, DialogModeSize } from './../../../../common/models/dialog-options';
import { OnInit, OnDestroy, Provider } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { ShiftReplacementManagementService } from '../../../services/index';
import { DialogOptions, ColumnSettingsDefinitions } from '../../../../common/index';
import { ModalService, ColumnSettingsStorageService } from '../../../../common/services/index';
import { LookupApiService } from '../../../../organization/services/index';
import { ShiftReplacementSettings, ShiftEligibleEmployee, ShiftAddEmployeeCmd, ShiftReplacementSms, GroupDetailsData } from '../../../models/index';
import { appConfig } from '../../../../app.config';
import { NotificationsService } from '../../../../../app/core/components';
import { ShiftOverlapHelper } from '../shift-overlap-helper';
var EmployeeAddShiftOpenComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeAddShiftOpenComponent, _super);
    function EmployeeAddShiftOpenComponent(options, modalService, lookupApiService, data, columnSettingsStorageService, shiftManagementService, notificationService) {
        var _this = _super.call(this) || this;
        _this.modalService = modalService;
        _this.options = options;
        _this.lookupApiService = lookupApiService;
        _this.notificationService = notificationService;
        _this.columnSettingsStorageService = columnSettingsStorageService;
        _this.shiftManagementService = shiftManagementService;
        _this.smsData = new ShiftReplacementSms();
        _this.settings = new ShiftReplacementSettings();
        _this.settings.createColumns();
        _this.settings.request = data.request;
        _this.groupData = data.groups;
        _this.previousDayGroupData = data.previousDayGroups;
        _this.nextDayGroupData = data.nextDayGroups;
        _this.settings.isMultipleSelectionMode = false;
        _this.settings.context = ColumnSettingsDefinitions.SHIFT_REPLACEMENT_ADD_EMPLOYEE;
        _this.settings.columns = _this.columnSettingsStorageService.getColumnsState(_this.settings.context.toString(), undefined, _this.settings.columns);
        return _this;
    }
    EmployeeAddShiftOpenComponent.openDialog = function (data, unitId, modalService, callback) {
        var request = data.request;
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.minHegiht = 550;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: GroupDetailsData,
                useValue: data
            }
        ];
        var header = "Add employee to shift " + request.shiftName + " " + moment(request.date).format(appConfig.serverDateFormat);
        var dialog = modalService.globalAnchor
            .openDialog2(EmployeeAddShiftOpenComponent, header, dialogOptions, resolvedProviders, function (result, uniqueId) {
            var cmd;
            if (result) {
                cmd = new ShiftAddEmployeeCmd();
                cmd.selectedEmployee = _.head(dialog.selectedEmployees);
                cmd.dateOn = request.date;
                cmd.shiftId = request.shiftId;
                cmd.positionId = cmd.selectedEmployee.position.id;
                cmd.unitId = unitId;
            }
            callback(result, cmd);
        });
        return dialog;
    };
    EmployeeAddShiftOpenComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.selectedEmployeesSubscription = this.shiftManagementService.subscribeToSelectedEmployees(function (selected) {
            _this.selectedEmployees = selected;
        });
        this.smsModeChangeSubscription = this.shiftManagementService.subscribeToChangeSmsMode(function (sendSmsOn) {
            _this.sendSmsModeOn = sendSmsOn;
        });
        this.availabilityToSendSmsSubscription = this.shiftManagementService
            .subscribeToChangeAvailabilityToSendSms(function (smsData) {
            _this.smsData = smsData;
        });
    };
    EmployeeAddShiftOpenComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        console.info('Destroy Add Employee popup');
    };
    Object.defineProperty(EmployeeAddShiftOpenComponent.prototype, "isAddDisabled", {
        get: function () {
            return !(this.selectedEmployees && this.selectedEmployees.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeAddShiftOpenComponent.prototype.onCancelSend = function () {
        this.shiftManagementService.changeSmsMode(false);
    };
    EmployeeAddShiftOpenComponent.prototype.onSend = function () {
        this.shiftManagementService.sendSms(this.smsData);
    };
    EmployeeAddShiftOpenComponent.prototype.onCancelAdd = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeAddShiftOpenComponent.prototype.onAdd = function () {
        if (this.selectedEmployees) {
            if (this.isCurrentDayShiftOverlapped()
                || this.isPreviousDayShiftOverlapped()
                || this.isNextDayShiftOverlapped()
                || this.isPrimarySecondaryShiftsOverlap()) {
                this.notificationService.warn('Overlap', 'This employee has an overlap shift');
                this.dialogResult = false;
                this.modalService.closeWindow(this.options.windowUniqueId);
                return;
            }
            else {
                this.dialogResult = true;
                this.modalService.closeWindow(this.options.windowUniqueId);
            }
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeAddShiftOpenComponent.prototype, "smsModeChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeAddShiftOpenComponent.prototype, "selectedEmployeesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeAddShiftOpenComponent.prototype, "availabilityToSendSmsSubscription", void 0);
    return EmployeeAddShiftOpenComponent;
}(ShiftOverlapHelper));
export { EmployeeAddShiftOpenComponent };
