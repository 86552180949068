import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { NgForm, FormControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { organizationConfig, IOrganizationConfig } from '../../../organization/organization.config';
import { appConfig, IApplicationConfig } from '../../../app.config';

import { ConfirmDialog2Component, ConfirmOptions, ScheduleEntryNavigationService } from '../../../common/index';

import { ScheduleEntry, ScheduleEntryShift, ScheduleEntryContainer, PayPeriodTimeCard } from '../../models/index';
import { MasterScheduleManagementService, ScheduleEntryApiService, ScheduleEntryManagementService } from '../../services/index';

import { unsubscribe } from '../../../core/decorators/unsubscribe-decorator';
import { ModalService } from '../../../common/services/modal/modal.service';

import { ShiftReplaceCmd } from '../../models/shift-replacement/shift-replace-cmd';
import { ShiftReplacementReplaceComponent } from '../shift-replacement/shift-replacement-replace/shift-replacement-replace.component';
import { EmployeeTALogComponent } from '../../../employee/employee/components/employee-ta-log/employee-ta-log.component';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../app/app-settings/services/app-settings-manage.service';
import { IScheduleActions, ScheduleActions } from '../../../../app/organization';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-entry',
  templateUrl: 'schedule-entry.component.html',
  styleUrls: ['schedule-entry.component.scss']
})
export class ScheduleEntryComponent implements OnInit, OnDestroy {

  public scheduleEntryContainer: ScheduleEntryContainer;
  public organizationConfig: IOrganizationConfig;
  public appConfig: IApplicationConfig;

  public state: {
    isLoading: boolean;
    isOverlaping: boolean;
    hasDuplicates: boolean;
  };

  @unsubscribe()
  public initSubscription: Subscription;
  @unsubscribe()
  public loadStateSubscription: Subscription;
  @unsubscribe()
  public overlapStateSubscription: Subscription;
  @unsubscribe()
  public duplicateStateSubscription: Subscription;
  @unsubscribe()
  public replacedStateSubscription: Subscription;
  @unsubscribe()
  public routeSubscription: Subscription;
  @unsubscribe()
  public actionsSubscription: Subscription;

  @ViewChild('form', { static: false })
  public form: NgForm;
  public employeeId: number;
  public dateOn: Date;
  public orgLevelId: number;
  public approvedPayPeriod:any;
  public isModifyPayPeriodApproved: boolean;
  public actions: ScheduleActions;

  public get shiftCount(): number {
    if (this.scheduleEntryContainer && this.scheduleEntryContainer.shifts) {
      return this.scheduleEntryContainer.shifts.length;
    }

    return 0;
  }

  private scheduleEntryNavigationService: ScheduleEntryNavigationService;

  constructor(public management: ScheduleEntryManagementService, 
    private activatedRoute: ActivatedRoute, 
    private managementService: MasterScheduleManagementService,
    private router: Router, private location: Location, 
    private appSettingsManageService: AppSettingsManageService,
    private modalService: ModalService, 
    private scheduleEntryApiService: ScheduleEntryApiService, 
    private datepipe:DatePipe) {
    this.scheduleEntryNavigationService = new ScheduleEntryNavigationService(this.router, this.activatedRoute);
    this.organizationConfig = organizationConfig;
    this.appConfig = appConfig;
    this.state = {
      isLoading: false,
      isOverlaping: false,
      hasDuplicates: false
    };
  }

  public ngOnInit(): void {

    this.initSubscription = this.management.onInitialized$.subscribe((entryContainer: ScheduleEntryContainer) => {
      this.scheduleEntryContainer = entryContainer;
    });

    this.loadStateSubscription = this.management.onLoadState$.subscribe((isLoading: boolean) => {
      this.state.isLoading = isLoading;
    });

    this.overlapStateSubscription = this.management.onOverlapState$.subscribe((isOverlapping: boolean) => {
      this.state.hasDuplicates = false;
      this.state.isOverlaping = isOverlapping;
    });

    this.duplicateStateSubscription = this.management.onDuplicateState$.subscribe((hasDuplicates: boolean) => {
      this.state.isOverlaping = false;
      this.state.hasDuplicates = hasDuplicates;
    });

    this.replacedStateSubscription = this.management.onReplaceShift$.subscribe((request: any) => {

      let replacementComponent: ShiftReplacementReplaceComponent = ShiftReplacementReplaceComponent.openDialog(request, this.modalService, (result: boolean, cmd: ShiftReplaceCmd) => {
        if (result && cmd) {
          this.management.doReplace(cmd);
        }
      });

    });

    this.initialize(this.activatedRoute, this.router);
    this.getSettings();
    this.actionsSubscription = this.managementService.actions$.subscribe((data: IScheduleActions) => {
      this.actions = data;
    });
  }

  public initialize(activatedRoute: ActivatedRoute, router: Router): void {

    this.activatedRoute = activatedRoute;
    this.router = router;

    this.routeSubscription = this.activatedRoute.params.subscribe((params: Params) => {
      let onDate: Date = moment(params.date, appConfig.linkDateFormat).toDate();
      // Setting the flag to false, as it raised Bug-147183, Bug-147681
      // With the above changes: 145753 will be unfixed.
      // 145753 - Passing this condition to Position API to fetch only Active positions. 
      const showOnlyActivePositions = false;
      this.employeeId = params.employeeId;
      this.dateOn = onDate;
      this.orgLevelId= params.orgLevelId;
      this.management.changeScheduleEntryDate(params.employeeId, onDate, showOnlyActivePositions);

    });

  }
  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    console.debug('destroy');
  }

  public save(form: NgForm): void {
    let EmpId = String(this.employeeId);
    let StartDate = moment(this.dateOn).format("YYYY-MM-DD HH:mm:ss");
    var date = moment(this.dateOn).add(23, 'hours').add(59, 'minutes').add(59, 'seconds');
    var EndDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
    this.scheduleEntryApiService.checkApprovedPayperiod(EmpId, StartDate, EndDate).then((data: any) => {
      this.approvedPayPeriod = data;
      if (this.isModifyPayPeriodApproved && this.approvedPayPeriod) {
        const message = this.actions.canEditForApprovedPayPeriod ?
        `This will modify a schedule in an approved pay period and impact the PBJ Calculation for the 
        employee, are you sure you want to continue?`: `You do not have permissions to modify a 
        schedule in an approved pay period`;        
        let popupOptions: ConfirmOptions = new ConfirmOptions();
        popupOptions.showCancel = true;
        popupOptions.showOK = this.actions.canEditForApprovedPayPeriod ? true : false;
        popupOptions.buttonOKtext = 'Ok';
        popupOptions.buttonCanceltext = this.actions.canEditForApprovedPayPeriod ? 'Cancel':'Ok';
        ConfirmDialog2Component.openDialog(
          'Warning',
          message,
          this.modalService,
          (result: boolean) => {
            if (result) {
              if (!this.state.isOverlaping && form.valid) {
                this.management.save(this.scheduleEntryContainer);
              }
            }
          },popupOptions);
      }
      else {
        if (!this.state.isOverlaping && form.valid) {
          this.management.save(this.scheduleEntryContainer);
        }
      }
    });
  }

  public discard(): void {
    this.management.discard();
  }

  public cancel(): void {
    this.router.navigate(['apps', 'scheduler', 'master_schedule']);
  }

  public changeDateOn(dateOn: Date): void {
    this.scheduleEntryNavigationService.NavigateToScheduleEntry(this.scheduleEntryContainer.employeeId, dateOn);
  }

  public addNewShift(): void {
    this.management.addNewShift();
  }

  public requestTALog(): void {
    
    //this.shiftRequestService.setGridData(OpenShiftData);
    EmployeeTALogComponent.openDialog(this.modalService, this.employeeId, this.dateOn, this.orgLevelId);
    //ShiftRequestPopupComponent.openDialog(OpenShiftData, this.modalService, orgLevel, this.dateOn, this.agencyListData);
  }
  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.isModifyPayPeriodApproved =  config.ModifySchedulesApprovedinPayPeriods;
  }
}
