import * as tslib_1 from "tslib";
import { OrgLevelType } from './../../../../state-model/models/index';
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { appConfig } from '../../../../app.config';
import { TimeCardModel } from '../../../models/index';
import { DailyTimecardManagementService, resetAction } from '../../../services/index';
import { EmployeeDefinitionsApiService, OrgLevelWatchService } from '../../../../organization/services/index';
var TimeCardShiftsListComponent = /** @class */ (function () {
    function TimeCardShiftsListComponent(managementService, employeeApiService, orgLevelWatchService) {
        this.managementService = managementService;
        this.employeeApiService = employeeApiService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.appConfig = appConfig;
    }
    Object.defineProperty(TimeCardShiftsListComponent.prototype, "model", {
        get: function () {
            return this.m_model;
        },
        set: function (value) {
            if (!value) {
                return;
            }
            this.m_model = value;
            this.employeeId = value.employee.id;
            this.dateOn = value.date;
            this.records = value.schedule;
            this.loadEmployeeInfo();
        },
        enumerable: true,
        configurable: true
    });
    TimeCardShiftsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resetSubscription = this.managementService.resetSection$.filter(function (m) { return m.target === 'schedule'; }).subscribe(function (m) {
            _this.records = m.model.schedule;
        });
        this.canEditChangedSubscription = this.managementService.canEditChanged$
            .subscribe(function (canEdit) {
            _this.canEditTimecard = canEdit;
        });
        this.optionsChangedSubscription = this.managementService.displayOptionSelected$
            .subscribe(function (options) {
            _this.displayOptions = options;
        });
    };
    TimeCardShiftsListComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeCardShiftsListComponent.prototype.loadEmployeeInfo = function () {
        var _this = this;
        this.employeeDepartmentId = 0;
        this.employeeApiService.getEmployeeShortInfoCached(this.employeeId).then(function (info) {
            if (info.department) {
                var orgLevel = _this.orgLevelWatchService.getOrgLevelByRelatedItemId(info.department.id, OrgLevelType.department);
                if (orgLevel) {
                    _this.employeeDepartmentId = orgLevel.id;
                }
            }
        }).catch(function (reason) {
            _this.employeeDepartmentId = 0;
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardShiftsListComponent.prototype, "resetSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardShiftsListComponent.prototype, "canEditChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardShiftsListComponent.prototype, "optionsChangedSubscription", void 0);
    return TimeCardShiftsListComponent;
}());
export { TimeCardShiftsListComponent };
