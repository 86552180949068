import * as _ from "lodash";

import { Component, Host, Input, ViewChild } from "@angular/core";
import { process, SortDescriptor, State } from "@progress/kendo-data-query";
import {
  GridDataResult,
  GridComponent,
} from "@progress/kendo-angular-grid";
import {
  KendoGridStateHelper,
  ConfirmDialog2Component,
  ModalService,
  saveEvent,
  removeEvent,
  ConfirmOptions,
  cancelEvent,
} from "../../../../../common/index";
import { appConfig, IApplicationConfig } from "../../../../../app.config";
import {
  EmployeeSectionsEducation,
  EmployeeSectionsEducations,
} from "../../../models/employee-sections-personal/employee-sections-educations";
import { unsubscribe } from "../../../../../core/decorators/index";
import { Subscription } from "rxjs";
import { EmployeeSectionsPersonalApiService } from "../../../services";
import { EmployeeSectionsBase } from "../../../models/employee-sections-base";
import { EmployeeSubSectionsDecoratorComponent } from "../../employee-subsection-decorator/employee-subsection-decorator.component";

@Component({
  moduleId: module.id,
  selector: "slx-employee-sections-educations",
  templateUrl: "employee-sections-educations.component.html",
  styleUrls: ["employee-sections-educations.component.scss"],
})
export class EmployeeSectionsEducationsComponent {
  public editingItem: EmployeeSectionsEducation;
  public newEmpEducation: EmployeeSectionsEducation = new EmployeeSectionsEducation();

  public isLoading;

  public canAddEducation;
  public enableAddEducation = true;
  public canEditEducation;
  public canDeleteEducation;

  public addMode: boolean;

  public gridState: KendoGridStateHelper<EmployeeSectionsEducation>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;

  public appConfig: IApplicationConfig;
  public rawEducations: EmployeeSectionsEducation[];
  public educations: EmployeeSectionsEducation[];
  public enableSave = false;
  public addNewRow: EmployeeSectionsEducation = new EmployeeSectionsEducation();

  public skip = 0;
  public degreeSize = 50;
  public schoolCollegeSize = 255;
  public minDate: Date = new Date(1899, 12, 1);
  public maxDate: Date = new Date(2079, 5, 6);
  public isvalidDate;

  @Input('employeeSectionsEducations')
  public set educationList (employeeSectionsEducations: EmployeeSectionsEducations) {
    if(employeeSectionsEducations != null){
      let actions = employeeSectionsEducations.fieldsMeta.actions;
      let edu =[];
      _.forEach(actions, (v) => {
        if (v === "add") this.canAddEducation = true;
        if (v === "edit") this.canEditEducation = true;
        if (v === "delete") this.canDeleteEducation = true;
      });
      this.rawEducations = employeeSectionsEducations.education;
      _.forEach(this.rawEducations,(e)=>{
        if(e.degree !== '[blank]'){
          edu.push(e);
        }
      });
      ( edu.length <= 1 )? this.enableAddEducation = true : this.enableAddEducation = false ;
      this.educations=edu;
      this.refreshGrid();
    }
  }


  @Input()
  public employeeId: number;

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  @ViewChild("kendoGridEducation", { static: true })
  private grid: GridComponent;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;
  @unsubscribe()
  private formValueChangeSubscription: Subscription;

  constructor(
    private modalService: ModalService,
    private apiService: EmployeeSectionsPersonalApiService,
    @Host() private decorator: EmployeeSubSectionsDecoratorComponent
  ) {
    this.gridState = new KendoGridStateHelper<
      EmployeeSectionsEducation
    >();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: "graduationYear", dir: "asc" }];
    this.createSubscriptions();
    
    this.decorator.isEditableByConfiguration = false;
  }


  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public createSubscriptions(): void {
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(
      (v: State): void => {
        this.refreshGrid();
      }
    );

    this.gridEditSubscription = this.gridState.onEdit$.subscribe(
      (item: EmployeeSectionsEducation): void => {
        this.editingItem = item;
        this.addMode = false;
        if(item.graduationYear){
          this.isvalidDate = true;
        }
      }
    );

    this.gridCancelSubscription = this.gridState.onCancel$.subscribe(
      (item: cancelEvent<EmployeeSectionsEducation>): void => {
        this.editingItem = null;
        this.addMode = false;
        this.enableSave = false;
      }
    );

    this.gridSaveSubscription = this.gridState.onSave$.subscribe(
      (item: saveEvent<EmployeeSectionsEducation>): void => {
        if (item.isNew) {
          this.addMode = true;
        } 
        this.enableSave = false;
        this.doUpdate(item.dataItem);
      }
    );

    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(
      (item: removeEvent<EmployeeSectionsEducation>): void => {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog(
          "Delete Education",
          "Are you sure that you want to remove this Education?",
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.doRemove(item.dataItem);
            }
          },
          options
        );
      }
    );
  }

  public onAddEducation(): void {
    this.enableSave = false;
    this.addMode = true;
    this.newEmpEducation.degree = null;
    this.newEmpEducation.schoolCollege = null;
    this.newEmpEducation.graduationYear = null;
    this.grid.addRow(this.newEmpEducation);
  }

  protected doUpdate(item: EmployeeSectionsEducation): void {
    if(this.addMode){
      if(this.rawEducations[0].sequenceId === "first" && this.rawEducations[0].degree === "[blank]"){
        item.sequenceId = "first"
      }
      else if(this.rawEducations[1].sequenceId === "second"  && this.rawEducations[1].degree === "[blank]" ){
        item.sequenceId = "second"
      }
  }
    item.graduationYear =  new Date(item.graduationYear).toDateString();
    this.isLoading = true;
    this.apiService.setEducation(item, this.employeeId).then((status) => {
      if (status) {
        this.loadList();
      }
    });
  }

  protected doRemove(item: EmployeeSectionsEducation): void {
    this.educations = _.without(this.educations, item);
    this.isLoading = true;
    this.apiService
      .deleteEducation(item, this.employeeId)
      .then((status) => {
        if (status) {
          this.loadList();
        }
      });
  }

  private refreshGrid(): void {
    if (!this.educations) {
      this.gridView = null;
      return;
    }
    this.gridState.view = process(this.educations, this.gridState.state);
  }

  public onChangeValue(item: EmployeeSectionsEducation) {
    let isDegree = this.validateField(item.degree, this.degreeSize);
    let isSchoolCollege = this.validateField(item.schoolCollege,this.schoolCollegeSize);
    let isDate = this.validateDate(item.graduationYear);
    if (isDegree && isSchoolCollege && isDate && this.isvalidDate) {
      this.enableSave = true;
    } else {
      this.enableSave = false;
    }
  }
  public validityChanged(validity: string, item: EmployeeSectionsEducation): void {
   let date = new Date(validity);
   if (date <= this.maxDate && date >= this.minDate){
    this.isvalidDate = true;
   }
   else {
     this.isvalidDate = false;
   }
   this.onChangeValue(item)
  }

  public validateField(value: string, size: number) {
    let validate;
    if (value == null || value == "") {
      validate = false;
    } else {
      validate = true;
    }
    if (value != null && value != "") {
      if (value.length > size) {
        validate = false;
      } else {
        validate = true;
      }
    }
    return validate;
  }

  public validateDate(value: string) {
    let validate;
    if (value != null) {
        validate = true;
    } else {
      validate = false;
    }
    return validate;
  }

  public loadList(){
    this.apiService
    .getEducations(this.employeeId)
    .then((educations) => {
      let educ =[];
      this.rawEducations = educations.education;
      _.forEach(this.rawEducations,(education)=>{
        if(education.degree !== '[blank]'){
          educ.push(education);
        }
      });
      ( educ.length <= 1 )? this.enableAddEducation = true : this.enableAddEducation = false ;
      this.educations=educ;
      this.refreshGrid();
      this.isLoading = false;
      this.addMode = false;
    });
  }

  
}
