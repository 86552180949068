import { Directive, OnInit, Host, Input, ChangeDetectorRef } from '@angular/core';
import { GridComponent, CellClickEvent, DetailExpandEvent } from '@progress/kendo-angular-grid';
import * as domUtils from '../../../utils/domUtils';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';

@Directive({
  selector: '[slxKendoGridRowExpand]',
})
export class KendoGridRowExpandDirective implements OnInit {

  @Input('slxKendoGridRowExpand')
  public mode: 'expand' | 'collapse' | 'both' = 'expand';

  @unsubscribe()
  private cellClickSubscription: Subscription;
  @unsubscribe()
  private expandChangeSubscription: Subscription;
  @unsubscribe()
  private collapseChangeSubscription: Subscription;

  private expandedRows: NumberMap<boolean>;

  constructor(@Host() private grid: GridComponent, private changeDetector: ChangeDetectorRef) {
    this.expandedRows = {};
  }
  public ngOnInit(): void {
    this.cellClickSubscription = this.grid.cellClick.asObservable().subscribe((clickEvent: CellClickEvent) => {
      if (clickEvent.type !== 'click') {
        return;
      }
      if (!this.expandedRows[clickEvent.rowIndex]) {
        if (!this.mode || this.mode === 'expand' || this.mode === 'both') {
          this.grid.expandRow(clickEvent.rowIndex);
          const ev = new DetailExpandEvent(undefined);
          ev.dataItem = (<any>clickEvent).dataItem;
          ev.index = clickEvent.rowIndex;
          this.grid.detailExpand.emit(ev);
          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        }
      } else {
        if (this.mode === 'collapse' || this.mode === 'both') {
          this.grid.collapseRow(clickEvent.rowIndex);
          this.changeDetector.markForCheck();
          this.changeDetector.detectChanges();
        }
      }
    });

    this.expandChangeSubscription = this.grid.detailExpand.asObservable().subscribe((event: { index: number, dataItem: any }) => {
      this.expandedRows[event.index] = true;
    });

    this.collapseChangeSubscription = this.grid.detailCollapse.asObservable().subscribe((event: { index: number, dataItem: any }) => {
      this.expandedRows[event.index] = false;
    });

  }
}
