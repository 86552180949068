export class ImportedFiles {
    public importId: number;
    public uniqueId: string;
    public orgLevelId: number;
    public orgLevelName: string;
    public facilityName: string;
    public departmentId: number;
    public departmentName: string;
    public moduleName: string;
    public status: string;
    public importedBy: string;
    public importedDateTime: Date;
    public importedFile: string;
    public get getStatus(): string {
        switch (this.status) {
            case 'In-Progress':
                return 'In Process';
            case 'Success':
                return 'Uploaded';
            case 'Failed':
                return 'Failed';
            default:
                return this.status;
        }
    }
}

export interface IImportedFiles {
    importId: number;
    uniqueId: string;
    orgLevelId: number;
    departmentId: number;
    moduleName: string;
    status: string;
    importedBy: string;
    importedDateTime: Date;
    importedFile: string;
}

export interface IExcelData {
    facility: string;
    department: string;
    configurationType: string;
    censusType: string;
    position: string;
    acuityTypes: string;
    shiftGroup: string;
    shift: string;
    unit: string;
    censusRangeFrom: number;
    censusRangeTo: number;
    sunday: number;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
}

export class ExcelData {
    public Facility: string;
    public Department: string;
    public ConfigurationType: string;
    public CensusType: string;
    public Position: string;
    public AcuityTypes: string;
    public ShiftGroup: string;
    public Shift: string;
    public Unit: string;
    public CensusRangeFrom: number;
    public CensusRangeTo: number;
    public Sunday: number;
    public Monday: number;
    public Tuesday: number;
    public Wednesday: number;
    public Thursday: number;
    public Friday: number;
    public Saturday: number;
}