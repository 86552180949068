import * as moment from 'moment';
import { appConfig } from '../../../app.config';
var BudgetDefinition = /** @class */ (function () {
    function BudgetDefinition() {
    }
    Object.defineProperty(BudgetDefinition.prototype, "description", {
        get: function () {
            return moment(this.startDate).format(appConfig.dateFormat) + " - " + (this.endDate ? moment(this.endDate).format(appConfig.dateFormat) : '');
        },
        enumerable: true,
        configurable: true
    });
    return BudgetDefinition;
}());
export { BudgetDefinition };
