<div class="pbj-rec-toolbar__toggler benefit_tog">
  <slx-switcher [(ngModel)]="isShowingExpired" (ngModelChange)="onTogglerChanged($event)" label="Show All"
    labelOn="Yes" labelOff="No" id="console-switcher"></slx-switcher>
    <button
      *ngIf="canMapPayroll"
      type="button"
      (click)="showBenefitPayrollMappingDialog()"
      class="slx-button slx-blue slx-margin-r slx-mobile-adapted mapping-btn">
        <span class="slx-button__text">Payroll Mapping</span>
    </button>
</div>
<hr id="console-hr"/>

<div class="ben-console-desktop" *slxHiddenOn="['tablet', 'mobile']">
  <div class="providers-tabs flex left">

    <div class="providers-tabs__slider">
      <ul #list class="providers-tabs__list flex flex-direction-row">
        <li class="providers-tabs__item" [class.active]="isActiveMenu(0)" (click)="onClickTab(0)"><em
            class="fas fa-calendar slx-line-tabs__icon"></em><span class="header-title__text">Recent Activity</span>
        </li>
        <li class="providers-tabs__item" [class.active]="isActiveMenu(1)" (click)="onClickTab(1)"><em
            class="fas fa-calendar-times slx-line-tabs__icon"></em><span class="header-title__text">Plans by Expiration
            Date </span></li>
      </ul>
    </div>

    <ng-container *ngIf="!activeTab">
      <benefit-recent-activity></benefit-recent-activity>
    </ng-container>
    <ng-container *ngIf="activeTab">
      <benefit-by-expiration-date></benefit-by-expiration-date>
    </ng-container>
  </div>

  <div class="ben-console_gridAlignemnt right" [ngClass]="{ 'benefit-plan-no-data': planTypes.length === 0 }">
    <slx-spinner [show]="benefitPlansLoading">
      <ng-container *ngIf="planTypes.length > 0">
        <benefit-plan-types></benefit-plan-types>
      </ng-container>
      <ng-container *ngIf="planTypes.length === 0 && !benefitPlansLoading">
        No benefit groups available
      </ng-container>
    </slx-spinner>
  </div>

</div>

<div class="ben-console-mobile" *slxHiddenOn="['desktop']">

  <div class="report-section white">
    <div class="report-section-header">
      <div class="report-section-header-content">
        <div class="header-item header-title">
          <em class="fas fa-calendar"></em>
          <span class="header-title__text">Plans</span>
        </div>
      </div>
      <div class="report-section-header-button-right">

        <slx-collapse-button [isCollapsed]="false" className="slx-with-icon" [slxCollapseByCss]="collapseContentPlans">
        </slx-collapse-button>

      </div>
    </div>
    <div #collapseContentPlans class="report-section-content mobile-inner-sec">
      <div class="panel-content">
        <slx-dropdown-list class="slx-wide slx-short slx-middle-label" name="benefitPlanTypeChange"
          [options]="BenefitPlansList" [valuePrimitive]="true" [(ngModel)]="selectedBenefitPlan"
          (ngModelChange)="benefitPlanChange($event)"></slx-dropdown-list>

        <benefit-recent-activity *ngIf="tabSwitch"></benefit-recent-activity>
        <benefit-by-expiration-date *ngIf="!tabSwitch"></benefit-by-expiration-date>
      </div>
    </div>
  </div>

  <div class="report-section white">
    <div class="report-section-header">
      <div class="report-section-header-content">
        <div class="header-item header-title">
          <em class="fas fa-calendar-check"></em>
          <span class="header-title__text">Benefits</span>
        </div>
      </div>
      <div class="report-section-header-button-right">
        <slx-collapse-button [isCollapsed]="true" className="slx-with-icon"
          [slxCollapseByCss]="collapseContentbenefits">

        </slx-collapse-button>

      </div>
    </div>
    <div #collapseContentbenefits class="report-section-content mobile-inner-sec">
      <div class="panel-content" [ngClass]="{ 'benefit-plan-no-data': planTypes.length === 0 }">
        <slx-spinner [show]="benefitPlansLoading">
          <ng-container *ngIf="planTypes.length > 0">
            <benefit-plan-types></benefit-plan-types>
          </ng-container>
          <ng-container *ngIf="planTypes.length === 0 && !benefitPlansLoading">
            No benefit groups available
          </ng-container>
        </slx-spinner>
      </div>
    </div>
  </div>

</div>
