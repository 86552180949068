var PayRuleDefinition = /** @class */ (function () {
    function PayRuleDefinition() {
    }
    Object.defineProperty(PayRuleDefinition.prototype, "uniqName", {
        get: function () {
            return "uniq" + this.name;
        },
        enumerable: true,
        configurable: true
    });
    return PayRuleDefinition;
}());
export { PayRuleDefinition };
