import * as _ from 'lodash';

import { Component, Provider, OnInit } from '@angular/core';

import { IDialog, ModalService, DialogOptions, ConfirmDialogComponent, ConfirmOptions } from '../../../../common/index';
import { LookupService, Lookup, LookupType, BenefitClassDefinition, EmployeeBenefitClass, EmployeeDefinitionsApiService } from '../../../../organization/index';

import { BenefitClassDialogReq } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-benefit-class-dialog',
  templateUrl: 'benefit-class-dialog.component.html',
  styleUrls: ['benefit-class-dialog.component.scss']
})
export class BenefitClassDialogComponent implements IDialog, OnInit {

  public dialogResult: boolean;
  public isLoading: boolean = false;
  public disabledBenifitFooterBtn: boolean = false;
  
  public benefitClass: BenefitClassDefinition;
  public benefitClassEffectiveDate: Date = null;
  public benefitClassesLookup: Lookup<BenefitClassDefinition>;

  public employeeBenefitClass: EmployeeBenefitClass;

  public static OpenDialog(
    request: BenefitClassDialogReq,
    modalService: ModalService,
    callback: (result: boolean) => void
  ): BenefitClassDialogComponent {
    const dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 300;
    dialogOptions.width = 400;

    const resolvedProviders: Provider[] = [{
      provide: DialogOptions,
      useValue: dialogOptions
    }, {
      provide: BenefitClassDialogReq,
      useValue: request
    }];

    const dialog = modalService.globalAnchor.openDialog(
      BenefitClassDialogComponent,
      'Benefit Class',
      dialogOptions,
      resolvedProviders,
      (result: boolean) => {
        callback(result);
      }
    );

    return dialog;
  }

  constructor(
    private options: DialogOptions,
    private modalService: ModalService,
    private lookupService: LookupService,
    public request: BenefitClassDialogReq,
    private employeeDefinitionsApiService: EmployeeDefinitionsApiService
  ) {
    this.employeeBenefitClass = this.request.employeeBenefitClass || new EmployeeBenefitClass();

    if (this.employeeBenefitClass.startDate) {
      this.benefitClassEffectiveDate = this.employeeBenefitClass.startDate;
    }
  }

  public ngOnInit(): void {
    this.load();
  }

  public async onOk(): Promise<void> {
    if (!this.benefitClass || !this.benefitClassEffectiveDate) {
      return;
    }

    try {
      this.dialogResult = true;

      if (!this.request.employeeBenefitClass ||
        this.benefitClass.id !== this.request.employeeBenefitClass.benefitClassId ||
        this.benefitClassEffectiveDate !== this.request.employeeBenefitClass.startDate
      ) {
        this.employeeBenefitClass.id = 0;
        this.employeeBenefitClass.empId = this.request.employeeId;
        this.employeeBenefitClass.benefitClass = this.benefitClass;
        this.employeeBenefitClass.benefitClassId = this.benefitClass.id;
        this.employeeBenefitClass.startDate = this.benefitClassEffectiveDate;
        this.employeeBenefitClass.endDate = null;
        this.employeeBenefitClass.dateHired = this.request.hireDate;

        this.isLoading = true;
        await this.employeeDefinitionsApiService.postBenefitsClass(this.request.employeeId, this.employeeBenefitClass);
      }
    } catch (e) {
      this.dialogResult = false;
    }

    this.isLoading = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.disabledBenifitFooterBtn = true;
    const confirmOptions = new ConfirmOptions();
    confirmOptions.showOK = true;
    confirmOptions.showCancel = true;
    confirmOptions.buttonOKtext = 'Yes';
    confirmOptions.buttonCanceltext = 'No';

    ConfirmDialogComponent.openDialog(
      'Confirmation',
      'Are you sure you want to discard the changes that you made?',
      this.modalService,
      (result: boolean) => {
        this.disabledBenifitFooterBtn = false;
        if (result) {
          this.dialogResult = false;
          this.modalService.closeWindow(this.options.windowUniqueId);
        }
      },
      confirmOptions
    );
  }

  private async load(): Promise<void> {
    if (!this.request || !this.request.employeeId) {
      return;
    }

    const benefitClassesLookup = await this.lookupService.getLookup({
      lookupType: LookupType.benefitClasses,
      employeeId: this.request.employeeId
    });

    this.benefitClassesLookup = benefitClassesLookup;

    if (this.request.employeeBenefitClass && this.benefitClassesLookup) {
      const benefitClassDefinition: BenefitClassDefinition =
        _.find(this.benefitClassesLookup.items, benefitClass => benefitClass.id === this.request.employeeBenefitClass.benefitClassId);

      if (benefitClassDefinition) {
        this.benefitClass = benefitClassDefinition;
      }
    }
  }
}
