import { GenericFieldDefinition, IGenericFieldDefinition } from './generic-field-definition';

export interface IGenericListDefinition {
  id: number;
  listName: string;
  primaryKey: string;
  fields: IGenericFieldDefinition[];
}

export class GenericListDefinition {
  public id: number;
  public listName: string;
  public primaryKey: string;
  public fields: StringMap<GenericFieldDefinition>;
}
