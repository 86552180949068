import * as _ from 'lodash';
import { Assert } from '../../../framework/index';
var DetailRow = /** @class */ (function () {
    function DetailRow() {
    }
    DetailRow.prototype.getFieldValue = function (fieldName) {
        var foundField = _.find(this.fields, function (field) { return field.name.toLowerCase() === fieldName.toLocaleLowerCase(); });
        if (!foundField) {
            Assert.isNotOwnProperty(fieldName, fieldName);
            return undefined;
        }
        return foundField.value;
    };
    Object.defineProperty(DetailRow.prototype, "isEmpty", {
        get: function () {
            return +this.getFieldValue('EmpId') === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailRow.prototype, "isOutOfPar", {
        get: function () {
            return this.getFieldValue('InPar').toLowerCase() === 'false';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailRow.prototype, "isOvertime", {
        get: function () {
            return this.getFieldValue('IsOvertime').toLowerCase() === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailRow.prototype, "messageCount", {
        get: function () {
            return +this.getFieldValue('MessageCount');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailRow.prototype, "key", {
        get: function () {
            return this.getFieldValue('JobCode').toLowerCase() + "_" + this.getFieldValue('ShiftId').toLowerCase() + "_" + this.getFieldValue('UnitId').toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailRow.prototype, "isSlxpartnerdata", {
        get: function () {
            if (this.getFieldValue('SlxpartnerdataId') === 0) {
                return true;
            }
            else {
                if (this.getFieldValue('RequeststatusId') === 0 || this.getFieldValue('RequeststatusId') === 3) {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailRow.prototype, "shiftStatus", {
        get: function () {
            if (this.getFieldValue('RequeststatusId') === '1') {
                return 'Pending Agency Assignment';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailRow.prototype, "isPartnerEmployee", {
        get: function () {
            if (this.getFieldValue('EmpId') !== 0 && this.getFieldValue('RequeststatusId') === 2) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetailRow.prototype, "isPendingShift", {
        get: function () {
            if (this.getFieldValue('RequeststatusId') === "1" || this.getFieldValue('RequeststatusId') === "4") {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    return DetailRow;
}());
export { DetailRow };
