import { Component, Input, ViewChild, AfterContentInit, ElementRef } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-collapsible-panel',
  templateUrl: 'collapsible-panel.component.html',
  styleUrls: ['collapsible-panel.component.scss']
})
export class CollapsiblePanelComponent implements AfterContentInit, ICollapssiblePanel {

  @Input()
  public hideButton: boolean;

  @Input()
  public title: string;

  @Input()
  public set collapsed(val: boolean) {
    if (val) {
      this.onCollapse();
    } else {
      this.onExpand();
    }
  }

  @Input ()
  public locked: boolean;

  @ViewChild('barContent', {static: true})
  public barContent: ElementRef;

  public isCollapsed: boolean = true;
  public isExpanding: boolean;

  public defaultHeader: boolean = true;

  public ngAfterContentInit(): void {
    this.defaultHeader = this.barContent.nativeElement.children.length === 0;
  }

  public onCollapse(): void {
    this.isExpanding = false;
    this.isCollapsed = true;
  }

  public onExpand(): void {
    this.isExpanding = true;
    this.isCollapsed = false;
    setTimeout(() => {
      this.isExpanding = false;
    }, 1000);
  }

  public onToggle(): void {
    if (!this.isCollapsed) {
      this.onCollapse();
    } else {
      this.onExpand();
    }
  }
}


export interface ICollapssiblePanel {
  isCollapsed: boolean;
  locked: boolean;
  hideButton: boolean;
  title: string;
  onToggle (): void;
}
