<div>
  <form #gridForm="ngForm">

    <kendo-grid #kendoGrid scrollable="'scrollable'" [data]="gridState.view"
                (dataStateChange)="gridState.dataStateChange($event)" [sortable]="true" [sort]="gridState.state.sort"
                [pageable]="true" [pageSize]="gridState.state.take" [skip]="gridState.state.skip" [groupable]="false"
                [selectable]="false" (selectionChange)="gridState.selectionChange($event)"
                [slxKendoGridState]="gridState.gridDefinition" (stateRestored)="gridState.dataStateChange($event)"
                [filterable]="false" [filter]="gridState.state.filter" (edit)="gridState.editHandler($event)"
                (save)="gridState.saveHandler($event)" (cancel)="gridState.cancelHandler($event)">
      <ng-template kendoGridToolbarTemplate position="top">

        <div class="col-xs-12">
          <div class="row pull-right">
            <button *ngIf="canEdit && isEditable && isAddNewAccessible" title="Create New" class="theme-iconed-accent-button pull-left " (click)="onAddNew()"
                    [disabled]="editingItem">
              <span class="icon-button-content">
                <span>
                  <i class="fa fa-plus slx-button__icon" aria-hidden="true"></i>
                  <span class="slx-button__text marl5"> Create New </span>
                </span>
              </span>
            </button>&nbsp;
          </div>
        </div>
      </ng-template>
      <kendo-grid-column *ngIf="canEdit && isEditable && isAddNewAccessible" title="" [sortable]="false" [filterable]="false" [width]="100">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <div class="flex">

            <button kendoGridEditCommand type="button" class="theme-iconed-accent-button grid-btn" [disabled]="addMode"
                   >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button type="button" class="theme-iconed-accent-button grid-btn" (click)="onDelete(dataItem)"
                    *ngIf="!dataItem.isAddEditMode" [disabled]="addMode || dataItem.isDisabledDel" [title]="getDeleteTooltip(dataItem.isDisabledDel)">
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
            </button>

            <button kendoGridSaveCommand type="button" class="theme-iconed-accent-button grid-btn slx-margin-r"
                    [disabled]="!valid">
              <i class="fas fa-save" aria-hidden="true"></i>
            </button>
            <button *ngIf="dataItem.isAddEditMode" type="button" class="theme-iconed-accent-button grid-btn" (click)="onCancel(dataItem, rowIndex)">
              <i class="fas fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="startDate" title="Effective Start Date" [width]="180" editable="true">
        <ng-template kendoGridHeaderTemplate>
          <span> Effective Start Date</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [title]="dataItem.startDate | amDateFormat: appConfig.dateFormat">
            {{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}
          </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

          <slx-input-decorator class="row-item">
            <slx-date-picker-ngx slx-input name="startDate{{rowIndex}}" [(ngModel)]="dataItem.startDate"
                                 [acceptNullDate]="false" [minDate]="minDateLimit(dataItem)" (ngModelChange)="onChangedDate(dataItem, true)"
                                 [required]="true">
            </slx-date-picker-ngx>

            <div errorMessage for="required"></div>
            <div errorMessage for="date"></div>
            <div errorMessage for="minDate">Date is invalid or out of valid range</div>
            <div class="slx-error-block" *ngIf="overlapStartDate">Effective Dates may not overlap existing records.</div>
            <div class="slx-error-block" *ngIf="gapStartDate">Gaps not allowed.</div>

          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="endDate" title="Effective End Date" [width]="180" editable="true">
        <ng-template kendoGridHeaderTemplate>
          <span>Effective End Date</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [title]="dataItem.endDate | amDateFormat: appConfig.dateFormat">
            {{ dataItem.endDate | amDateFormat: appConfig.dateFormat }}
          </span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator *ngIf="!addMode && isEndDateRequired(dataItem)" class="row-item">
            <slx-date-picker-ngx slx-input name="endDate{{rowIndex}}" [(ngModel)]="dataItem.endDate"
                                 [acceptNullDate]="!isRequiredEndDate(dataItem)" [minDate]="dataItem.startDate"
                                 (ngModelChange)="onChangedDate(dataItem, false)" [required]="isRequiredEndDate(dataItem)">
            </slx-date-picker-ngx>
            <div errorMessage for="required"></div>
            <div errorMessage for="date"></div>
            <div errorMessage for="minDate">Date is invalid or out of valid range</div>
            <div class="slx-error-block" *ngIf="dateError">End Date should greater than Start Date</div>
            <div class="slx-error-block" *ngIf="overlapEndDate">Effective Dates may not overlap existing records.</div>
            <div class="slx-error-block" *ngIf="gapEndDate">Gaps not allowed.</div>

          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="nonEmployee" title="Non-Employee" [width]="180" editable="false">
        <ng-template kendoGridHeaderTemplate>
          <span class="pendo-aca-employee-section-nonemployee">Non-Employee</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [title]="dataItem.nonEmployee">{{ dataItem.nonEmployee }}</span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-dropdown-list name="nonEmployee{{rowIndex}}"
                             class="slx-wide slx-short"
                             [valuePrimitive]="true"
                             [options]="nonEmployeeOptions"
                             [(ngModel)]="dataItem.nonEmployee">
          </slx-dropdown-list>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="incomeCalc" title="Income Calc" [width]="180">
        <ng-template kendoGridHeaderTemplate>
          <span class="pendo-aca-employee-section-incomecalc">Income Calc</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [title]="dataItem.incomeCalculationType">{{ dataItem.incomeCalculationType }}</span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-dropdown-list name="incomeCalc{{rowIndex}}"
                             class="slx-wide slx-short"
                             [options]="incomeCalcOptions"
                             [valuePrimitive]="true"
                             [(ngModel)]="dataItem.incomeCalculationType"
                             (ngModelChange)="onSelectedIncomeCalc(dataItem)">
          </slx-dropdown-list>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="annualIncome" title="Annual Household Income" [width]="220">
        <ng-template kendoGridHeaderTemplate>
          <span>Annual Household Income</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-grid-cell slx-transparent-textbox">
          <slx-money-input slx-input   [required]="false" [allowNegative]="false" [readonly]="true"
          [decimalLimit]="2" [requireDecimal]="false"  name="annualIncome{{idx}}"   min="0" slxMin="0" number
           [inputPolicy]="dataItem.annualIncome.Policy" [(ngModel)]="dataItem.annualIncome.fieldValue">
          </slx-money-input>
        </slx-input-decorator>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <div *ngIf="dataItem.isSystemCalculated">
            <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-grid-cell slx-transparent-textbox">
              <slx-money-input slx-input   [required]="false" [allowNegative]="false" [readonly]="true"
              [decimalLimit]="2" [requireDecimal]="false"  name="annualIncome{{idx}}"   min="0" slxMin="0" number  [(ngModel)]="dataItem.annualIncome.fieldValue">
              </slx-money-input>
            </slx-input-decorator>
          </div>
          <slx-input-decorator *ngIf="!dataItem.isSystemCalculated" class="flex-item">
            <slx-money-input slx-input [decimalLimit]="2" [requireDecimal]="false" [allowNegative]="true"
                             [required]="true" name="annualIncome{{idx}}" min="0" [slxMax]="annualIncomeLimit" number
                             [(ngModel)]="dataItem.annualIncome.fieldValue">
            </slx-money-input>
            <div errorMessage for="min">The value should be greater than 0</div>
            <span errorMessage for="number"></span>
            <span errorMessage for="max">Should not exceed {{annualIncomeLimit}}</span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="declinedOffer" title="Declined Offer" [width]="180" editable="false">
        <ng-template kendoGridHeaderTemplate>
          <span class="pendo-aca-employee-section-declinedoffer">Declined Offer</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [title]="dataItem.declinedOffer">{{ dataItem.declinedOffer }}</span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-dropdown-list name="declinedOffer{{rowIndex}}"
                             class="slx-wide slx-short"
                             [valuePrimitive]="true"
                             [options]="declinedOfferOptions"
                             [(ngModel)]="dataItem.declinedOffer">
          </slx-dropdown-list>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="marketplacePurchase" title="Marketplace Purchase" [width]="220" editable="false">
        <ng-template kendoGridHeaderTemplate>
          <span class="pendo-aca-employee-section-marketplace">Marketplace Purchase</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [title]="dataItem.marketplacePurchase">{{ dataItem.marketplacePurchase }}</span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-dropdown-list name="marketplacePurchase{{rowIndex}}"
                             class="slx-wide slx-short"
                             [valuePrimitive]="true"
                             [options]="marketplacePurchaseOptions"
                             [(ngModel)]="dataItem.marketplacePurchase">
          </slx-dropdown-list>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
  </div>
