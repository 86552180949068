import { PayrollExportNavigationService } from './../../../../common/services/navigation/payroll-export-navigation.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig } from '../../../../app.config';
import { PayCycle } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { IColumnSettings, StateResetTypes, IControlState } from '../../../../core/models/index';
import {
  TimecardsSummary,
  TimecardsEmployee,
  TimecardsState,
  TimecardsAction,
  TimecardsActionCmd,
  PayrollExportSubmitResults,
  TimecardsColumnState,
  ITimecardsLastSelectionState
} from '../../../models/index';
import { TimecardsDisplayManagementService } from '../../../services/index';
import { mutableSelect, unsubscribe, destroyService } from '../../../../core/decorators/index';
import { TimecardsActions } from '../../../store/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../../common/components/index';
import { ModalService } from '../../../../common/index';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { FileService, TimecardsSummaryNavigationService, ComponentStateStorageService } from '../../../../common/services/index';
import { StateManagementService } from '../../../../common/services/index';
import { AppSettingsManageService } from '../../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';
import { PayrollExportResultDialogComponent } from '../payroll-export-result-dialog/payroll-export-result-dialog.component';
import { MessagesService } from '../../../../components-library/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-timecards-display',
  templateUrl: 'timecards-display.component.html',
  styleUrls: ['timecards-display.component.scss'],
  providers: [TimecardsDisplayManagementService, StateManagementService, MessagesService]
})
export class TimecardsDisplayComponent implements OnInit, OnDestroy {

  public state: {
    isLoading: boolean;
  };

  public get hideRates(): boolean {
    return this.m_hideRates;
  }

  public set hideRates(value: boolean) {
    this.m_hideRates = value;
    if (!!this.m_hideRates) {
      this.setIsShowPayRate(false);
    }
  }

  public get hasParentOrganizationId(): boolean {
    return !!this.managementService.currentOrganizationId;
  }

  @mutableSelect(['timecards', 'timecardsDisplay', 'payCycle'])
  public timecardsPayCycle$: Observable<PayCycle>;
  public selectedRecords: TimecardsEmployee[];
  public selectedEmpIds: number[];
  public container: TimecardsSummary;
  public globalState: TimecardsState;
  public isOrganizationOrgLevel: boolean;
  public isDepartmentOrOrganizationOrgLevel: boolean;
  public isShowPayRates: boolean;
  public canMassApprove: boolean;
  public isFinishedPayCycle: boolean;
  public hasRulesDefinitions: boolean;
  public currentPayCycle: PayCycle;
  public orgLevel: OrgLevel;
  public orgLevelIdForPayCycles: number;
  public payrollManagementEnabed: boolean;
  public showHighPrecision: boolean;
  public hideCostCenterCode: boolean;
  public isShowHideEmptyTimecard: boolean;

  @unsubscribe()
  private routeSubscripion: Subscription;
  @unsubscribe()
  private timecardsSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private recordsSelectedSubscription: Subscription;
  @unsubscribe()
  private exportTimecardsSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private selectedEmployeeSubsription: Subscription;
  @unsubscribe()
  private loadingChangedSubsription: Subscription;

  @destroyService()
  private managementService: TimecardsDisplayManagementService;
  private columnsMenuOpened: boolean;
  private timecardDiplayActions: TimecardsActions;
  private payCycle: PayCycle;
  private m_hideRates: boolean;
  private modalService: ModalService;
  private fileService: FileService;
  private payCycleKey: string = 'payCycleKey';
  private resetBy: StateResetTypes = StateResetTypes.SessionEnd;

  constructor(
    managementService: TimecardsDisplayManagementService,
    timecardDiplayActions: TimecardsActions,
    modalService: ModalService,
    fileService: FileService,
    private orgLevelsService: OrgLevelWatchService,
    private route: ActivatedRoute,
    private router: Router,
    private stateManagement: StateManagementService,
    private compStorageService: ComponentStateStorageService,
    private appSettingsManageService: AppSettingsManageService,
    private messagesService: MessagesService
  ) {
    this.managementService = managementService;
    this.timecardDiplayActions = timecardDiplayActions;
    this.modalService = modalService;
    this.fileService = fileService;
    this.state = {
      isLoading: true,
    };
    this.orgLevelIdForPayCycles = 0;
    this.isOrganizationOrgLevel = false;
    this.isShowPayRates = false;
    this.m_hideRates = false;
    this.isFinishedPayCycle = false;
    this.canMassApprove = false;
  }

  public ngOnInit(): void {
    this.managementService.init();
    this.stateManagement.init('TimecardsDisplayComponent', true);

    this.routeSubscripion = this.route.queryParams
      .combineLatest(this.managementService.onOrgLevelChanged$, this.stateManagement.onInit$)
      .subscribe(([queryParams, orgLevel, onInit]: [Params, OrgLevel, any]) => {
        this.state.isLoading = true;
        this.setOrgLevel(orgLevel);
        this.setPayCycle(queryParams);
      });

    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });

    this.loadedSubscription = this.managementService.onLoaded$
      .subscribe((container: TimecardsSummary) => {
        this.messagesService.resetClosed();
        this.container = container;
        this.hideRates = this.container.hideRates;
        this.hideCostCenterCode = this.container.hideCostCenter;
        this.hasRulesDefinitions = this.container.usedRulesDefinitions && this.container.usedRulesDefinitions.length > 0;
        this.isShowHideEmptyTimecard = this.container.hideEmptyTimeCards;
      });

    this.stateSubscription = this.managementService.onStateChanged$
      .subscribe((state: TimecardsState) => {
        this.globalState = state;
        this.isShowPayRates = !!this.hideRates ? false : this.globalState.isShowPayRates;
        this.showHighPrecision = !!state.isShowHighPrecision;
      });

    this.recordsSelectedSubscription = this.managementService.onRecordsSelected$
      .subscribe((records: TimecardsEmployee[]) => {
        this.selectedRecords = records;
        this.selectedEmpIds = _.map(_.filter(this.selectedRecords, (r) => r.isSelected), (r) =>  r.employeePosition.employee.id);
        this.canMassApprove = this.checkToMassApprove(records);
        this.changeSelectedEntries(records);
      });

    this.exportTimecardsSubscription = this.managementService.onExportTimecards$
      .subscribe((file: FileBlobResponse) => this.fileService.saveToFileSystem(file.blob, file.file));

    this.selectedEmployeeSubsription = this.managementService.lastSelectionState$
      .subscribe((state: ITimecardsLastSelectionState) => this.changeLastSelectionState(state));

    this.appSettingsManageService.getAppServerConfig()
      .then((conf: AppServerConfig) => {
        this.payrollManagementEnabed = conf.payrollExpManagementEnabled;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get iconClass(): string {
    let iconClass: string = 'fa ';
    if (!this.orgLevel) {
      return '';
    }
    switch (this.orgLevel.type) {
      case OrgLevelType.department:
        iconClass += 'fa-users';
        break;
      case OrgLevelType.organization:
        iconClass += 'fa-building';
        break;
      case OrgLevelType.corporation:
        iconClass += 'fa-globe';
        break;
      default:
        iconClass += 'fa-globe';
    }

    return iconClass;
  }

  public onPayCycleSelected(payCycle: PayCycle): void {
    this.currentPayCycle = payCycle;
    this.payCycle = payCycle;
    this.isFinishedPayCycle = payCycle.endDate.getTime() < Date.now();

    this.managementService.onPayCycleChanged(payCycle);
    if (this.globalState) {
      this.globalState.lastViewedEmployee = 0;
      this.globalState.lastSelectedEntries = [];
    }
    this.timecardDiplayActions.clearTimecardsDisplaySelectionSettings();
    this.timecardDiplayActions.changeTimecardsDisplayPayCycle(payCycle);
    this.savePayCycle(payCycle);
  }

  public onPayCyclesLoaded(payCycles: PayCycle[]): void {
    if(!payCycles || payCycles.length === 0) {
      this.container = new TimecardsSummary();
      this.container.records =[];
      this.container.rulesDefinitions =[];
      this.managementService.onLoaded(this.container);
      this.state.isLoading = false;
    }
  }

  public onVisibilityPayRatesChanged($event: boolean): void {
    this.setIsShowPayRate($event);
  }

  public onVisibilityHighPrecisionChanged($event: boolean): void {
    this.setIsShowHighPrecision($event);
  }
  public onVisibilityHideEmptyTimecardChanged($event: boolean): void {
    this.setIsShowHideEmptyTimecard($event);
  }
  public onToggleView(mode: boolean): void {
    this.globalState.flatMode = mode;
    this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
  }

  public onExportToExcel(): void {
    let action: TimecardsAction = new TimecardsAction();
    action.cmd = TimecardsActionCmd.excelExport;
    this.managementService.onActionCmd(action);
  }

  public onExportToPdf(): void {
    let action: TimecardsAction = new TimecardsAction();
    action.cmd = TimecardsActionCmd.pdfExport;
    this.managementService.onActionCmd(action);
  }

  public onExportToPayroll(): void {
    const navi: PayrollExportNavigationService = new PayrollExportNavigationService(this.router, this.route);
    navi.navigateToPayrollExport(this.payCycle.startDate, this.payCycle.endDate);
  }

  public onReleaseToPayroll(): void {
    this.managementService.onReleaseToPayroll().then((res: PayrollExportSubmitResults) => {
      if (!res) {
        return;
      }
      PayrollExportResultDialogComponent.openDialog(res, this.modalService, () => {
        // nothing to do
      });
    });
  }

  public onViewSummary(): void {
    const navService: TimecardsSummaryNavigationService = new TimecardsSummaryNavigationService(this.router, this.route);
    const orgLevel: OrgLevel = this.managementService.currentOrgLevel;
    let organizationId: number;
    if (orgLevel.type === OrgLevelType.department) {
      let orgLevelId: number = orgLevel.parentId;
      if (!_.isNumber(orgLevelId)) {
        orgLevelId = this.orgLevelsService.getOrgLevelById(orgLevel.id).parentId;
      }
      organizationId = this.getOrganizationId(orgLevelId);
      if (_.isNull(organizationId)) {
        organizationId = orgLevel.organizationId;
      }
    } else {
      organizationId = orgLevel.relatedItemId;
      if (!_.isNumber(organizationId)) {
        organizationId = this.getOrganizationId(orgLevel.id);
      }
    }
    navService.NavigateToTimecardSummary(organizationId, this.managementService.currentPayCycle.startDate, this.managementService.currentPayCycle.endDate);
  }

  public getOrganizationId(orgLevelId: number): number {
    const orgLevel: OrgLevel = this.orgLevelsService.getOrgLevelById(orgLevelId);
    return _.get(orgLevel, 'relatedItemId', null);
  }

  public onRecalculateTimecards(): void {
    this.managementService.recalculateTimecards(this.orgLevel.id, this.payCycle);
  }

  public onApproveTimecards(): void {
    this.managementService.checkTimecardsApproval(this.orgLevel, this.payCycle);
  }

  public onUnapproveTimecards(): void {
    this.managementService.unapproveTimecards(this.orgLevel.id, this.payCycle);
  }

  public toggleColumnsMenu(): void {
    this.columnsMenuOpened = !this.columnsMenuOpened;
  }

  public changeLastSelectionState(state: ITimecardsLastSelectionState): void {
    this.globalState.lastViewedEmployee = state.selectedEmployee;
    this.globalState.lastViewedPage = state.skipRecords;
    this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
  }

  public changeSelectedEntries(entries: TimecardsEmployee[]): void {
    this.globalState.lastSelectedEntries = _.map(entries, (entry: TimecardsEmployee) => entry.employeePosition.employee.id);
    this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
  }

  public selectAll(event: MouseEvent): void {
    _.forEach(this.globalState.empColumns.columns, (column: IColumnSettings) => {
      column.displayed = true;
    });
    this.managementService.changeEmpColumns();
  }

  public isColumnVisible(columnName: string): boolean {
    let columnState: TimecardsColumnState = this.globalState.empColumns;
    let column: IColumnSettings = columnState.columnsMap[columnName];
    if (!this.globalState.isShowPayRates && column.payload && column.payload.payRateRelated) {
      return false;
    }
    if (column.name === 'costCenterCode' && this.hideCostCenterCode) {
      return false;
    }
    if (column.name === 'shiftCodeName' && (!this.container || !this.container.shiftDiffBasedOnTime)) {
      return false;
    }
    return true;
  }

  public clearAll(): void {
    _.forEach(this.globalState.empColumns.columns, (column: IColumnSettings) => {
      column.displayed = false;
    });
    this.managementService.changeEmpColumns();
  }

  public selectAllPayCodes(event: MouseEvent): void {
    _.forEach(this.globalState.payCodeColumns.columns, (column: IColumnSettings) => {
      column.displayed = true;
    });
    this.managementService.changePayColumns();
  }

  public clearAllPayCodes(): void {
    _.forEach(this.globalState.payCodeColumns.columns, (column: IColumnSettings) => {
      column.displayed = false;
    });
    this.managementService.changePayColumns();
  }

  public onChangeEmpColumns(event: MouseEvent, column: IColumnSettings): any {
    this.managementService.changeEmpColumns();
  }
  public onChangePayCodeColumns(event: MouseEvent, column: IColumnSettings): any {
    this.managementService.changePayColumns();
  }

  public onTimecardQueueClicked(): void {
    this.managementService.showTimecardQueueDialog(this.orgLevel, this.payCycle);
  }

  private setOrgLevel(orgLevel: OrgLevel): void {
    const prevOrgLevelId: number = _.get(this.orgLevel, 'id');
    const newOrgLevelId: number = _.get(orgLevel, 'id');
    if (prevOrgLevelId !== newOrgLevelId) {
      this.isOrganizationOrgLevel = orgLevel.type === OrgLevelType.organization;
      this.isDepartmentOrOrganizationOrgLevel = this.isOrganizationOrgLevel || orgLevel.type === OrgLevelType.department;
      this.orgLevel = orgLevel;
      this.orgLevelIdForPayCycles = this.orgLevel.id;
    } else {
      this.state.isLoading = false;
    }
  }

  private setPayCycle(queryParams: Params): void {
    const sDate: string = queryParams['startDate'];
    const eDate: string = queryParams['endDate'];
    let payCycle: PayCycle = null;
    if (sDate || eDate) {
      payCycle = new PayCycle();
      payCycle.startDate = moment(sDate, appConfig.linkDateFormat).toDate();
      payCycle.endDate = moment(eDate, appConfig.linkDateFormat).toDate();
    } else if (!this.currentPayCycle) {
      payCycle = this.restorePayCycle();
    }
    if (payCycle && !this.isSamePayCycle(this.currentPayCycle, payCycle)) {
      this.currentPayCycle = payCycle;
      this.isFinishedPayCycle = this.currentPayCycle.endDate.getTime() < Date.now();
      this.timecardDiplayActions.changeTimecardsDisplayPayCycle(this.currentPayCycle);
    } else {
      this.state.isLoading = false;
    }
  }

  private isSamePayCycle(pCycle1: PayCycle, pCycle2: PayCycle): boolean {
    const sDate1: Date = _.get(pCycle1, 'startDate');
    const eDate1: Date = _.get(pCycle1, 'endDate');
    const sDate2: Date = _.get(pCycle2, 'startDate');
    const eDate2: Date = _.get(pCycle2, 'endDate');

    return moment(sDate1).isSame(sDate2) && moment(eDate1).isSame(eDate2);
  }

  private setIsShowPayRate(value: boolean): void {
    this.isShowPayRates = !!this.hideRates ? false : value;

    if (!!this.globalState) {
      this.globalState.isShowPayRates = this.isShowPayRates;
    }

    this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
  }

  private setIsShowHighPrecision(value: boolean): void {
    if (!!this.globalState) {
      this.globalState.isShowHighPrecision = value;
    }

    this.timecardDiplayActions.changeTimecardsDisplaySettings(this.globalState);
  }
  public getShowHideToolTip(): string {
    if(this.isShowHideEmptyTimecard)
       return 'With this option enabled, the Timecards grid will exclude any timecards that contain none of the following: actual punches, regular time, productive hours, non-productive hours, pay codes, or absence codes.' ;
    return 'With this option disabled, the grid will display all timecards for the selected time period, including empty timecards, which may negatively impact system performance.';
  }
  public setIsShowHideEmptyTimecard(value: boolean): void {
    this.isShowHideEmptyTimecard = value;
    this.managementService.showHideEmptyTimeCards(value);
    this.managementService.onPayCycleChanged(this.payCycle);
    if (this.globalState) {
      this.globalState.lastViewedEmployee = 0;
      this.globalState.lastSelectedEntries = [];
    }
  }
  private checkToMassApprove(records: TimecardsEmployee[]): boolean {
    let hasMiss: TimecardsEmployee = _.find(records, (rec: TimecardsEmployee) => {
      return rec.isError;
    });
    return !hasMiss;
  }

  private savePayCycle(payCycle: PayCycle): void {
    const startDate: string = payCycle.startDate.toISOString();
    const endDate: string = payCycle.endDate.toISOString();
    const isApproved: boolean = payCycle.isApproved;

    this.compStorageService.setControlState(
      this.stateManagement.componentKey,
      this.payCycleKey,
      { value: { startDate, endDate, isApproved } },
      this.resetBy
    );
  }

  private restorePayCycle(): PayCycle {
    let state: IControlState = this.compStorageService.getControlState(this.stateManagement.componentKey, this.payCycleKey);
    const value: { startDate: Date, endDate: Date, isApproved: boolean } = _.get(state, 'value');
    if (_.isObject(value)) {
      const payCycle: PayCycle = new PayCycle();
      payCycle.startDate = new Date(value.startDate);
      payCycle.endDate = new Date(value.endDate);
      payCycle.isApproved = value.isApproved;

      return payCycle;
    }

    return null;
  }
}
