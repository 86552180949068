import * as _ from 'lodash';

export class ArrayUtils {

    /**
     * Remove item from array.
     * @param {T[]} array - Source array.
     * @param {T} item - Item to remove.
     * @returns {Boolean} Returns true if item was removed from array
     */
    public static without<T>(array: T[], item: T): boolean {
        let index: number = array.indexOf(item);
        if (index !== -1) {
            array.splice(index, 1);
            return true;
        }
        return false;
    }

    /**
     * Replace source item in array with target item.
     * @param {array} array - Array.
     * @param {T} source - Source item.
     * @param {T} target - Target item.
     * @returns {Boolean} Returns true if item was replaced
     */
    public static replace<T>(array: T[], source: T, target: T): boolean {
        let index: number = array.indexOf(source);
        if (index !== -1) {
            array[index] = target;
            return true;
        }
        return false;
    }

    /**
     * Get next item in array.
     * @param {array} array - Array.
     * @param {T} item - Target item.
     * @returns {T} Next item in array or undefined.
     */
    public static getNextItem<T>(array: T[], item: T): T {
        let index: number = array.indexOf(item);
        if (index === -1 || index === array.length - 1) {
            return undefined;
        }

        let result: T = array[index + 1];
        return result;
    }

    /**
     * Get next item in array.
     * @param {array} array - Array.
     * @param {T} item - Target item.
     * @returns {T} Next item in array or undefined.
     */
    public static getPreviousItem<T>(array: T[], item: T): T {
        let index: number = array.indexOf(item);
        if (index < 1) {
            return undefined;
        }

        let result: T = array[index - 1];
        return result;
    }

    public static groupObjects<T>(sourceCollection: T[], keys: string[]) : T[][] {
        let getGroupedItems = (item) : T[] => {
            let result = [];
            if(keys.length === 0) {
                result.push('');
            }
            for (let i = 0; i < keys.length; i++) {
                result.push(_.get(item, keys[i]));
            }
            return result;
        };
      
        if(sourceCollection && keys) {
            let groups = {};
            for (let i = 0; i < sourceCollection.length; i++) {
                let arrayRecord = sourceCollection[i];
                let group = JSON.stringify(getGroupedItems(arrayRecord));
                groups[group] = groups[group] || [];
                groups[group].push(arrayRecord);
            }
            return _.keys(groups).map((group): T[] => {
                return groups[group];
            });
        }
      };
}
