<div *ngIf="isInsightVisible">
  <slx-spinner [show]="isLoading">
    <form novalidate #insightsform="ngForm">
      <slx-collapsible-section id="slx-Collapse" [title]="insightHeading" [expanded]="true" mode="blue">
        <em class="fa fa-info-circle" aria-hidden="true"></em>
        <ng-container body>
          <slx-spinner [show]="isLoading">
            <div class="aca-insights-container">
              <div class="month-container">
                <div>
                  <span class="month-container-label">Ended This Month</span>
                </div>  
                <div class="period-container mp-border-color">
                  <div class="period-header mp-bg-color">
                    <em class="fas fa-ruler icon-align"></em>
                    <span class="header-label">Measurement Period</span>
                  </div>
                  <div class="acacontainer">
                    <div class="acacontainer-left filter" [class.selected]="buttonNum==1" (click)="filterAcaMeasurementDetails(true,true,'TDE',1)">
                      <div class="acacontainer-header pad2">Determined Eligible</div>

                      <div class="acacontainer-left-header_val mp-fg-color">
                        <span class="value-hyper-link">{{insightsRecord?.currentMonthMP?.totalDeterminedEligibleCount}}</span>
                      </div>
                    </div>
                    <div class="seprator"></div>
                    <div class="acacontainer-right">

                      <div class="acacontainer-right-content filter" [class.selected]="buttonNum==2" (click)="filterAcaMeasurementDetails(true,true,'PTE',2)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">PT Determined Eligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val mp-fg-color">
                          <span class="value-hyper-link"
                                >{{insightsRecord?.currentMonthMP?.ptDeterminedEligibleCount}}</span>
                        </div>
                      </div>
                      <div class="acacontainer-right-content filter" [class.selected]="buttonNum==3" (click)="filterAcaMeasurementDetails(true,true,'FTE',3)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">FT Determined Eligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val mp-fg-color">
                          <span class="value-hyper-link">{{insightsRecord?.currentMonthMP?.ftDeterminedEligibleCount}}</span>
                        </div>
                      </div>
                      <div class="acacontainer-right-content ineligible-txt filter" [class.selected]="buttonNum==4" (click)="filterAcaMeasurementDetails(true,true,'FTI',4)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">FT Determined Ineligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val ">
                          <span class="value-hyper-link">{{insightsRecord?.currentMonthMP?.ftDeterminedIneligibleCount}}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="period-container ap-border-color">
                  <div class="period-header ap-bg-color">
                    <em class="fas fa-file-edit icon-align"></em>
                    <span class="header-label">Administrative Period</span>
                  </div>
                  <div class="acacontainer">
                    <div class="acacontainer-left filterap" [class.selected]="buttonNum==5" (click)="filterAcaMeasurementDetails(true,false,'TDE',5)">
                      <div class="acacontainer-header pad2">Determined Eligible</div>

                      <div class="acacontainer-left-header_val ap-fg-color">
                        <span class="value-hyper-link">{{insightsRecord?.currentMonthAP?.totalDeterminedEligibleCount}}</span>
                      </div>
                    </div>
                    <div class="seprator"></div>
                    <div class="acacontainer-right">

                      <div class="acacontainer-right-content filterap" [class.selected]="buttonNum==6" (click)="filterAcaMeasurementDetails(true,false,'PTE',6)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">PT Determined Eligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val ap-fg-color">
                          <span class="value-hyper-link">{{insightsRecord?.currentMonthAP?.ptDeterminedEligibleCount}}</span>
                        </div>
                      </div>
                      <div class="acacontainer-right-content filterap" [class.selected]="buttonNum==7" (click)="filterAcaMeasurementDetails(true,false,'FTE',7)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">FT Determined Eligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val ap-fg-color">
                          <span class="value-hyper-link">{{insightsRecord?.currentMonthAP?.ftDeterminedEligibleCount}}</span>
                        </div>
                      </div>
                      <div class="acacontainer-right-content ineligible-txt filterap" [class.selected]="buttonNum==8" (click)="filterAcaMeasurementDetails(true,false,'FTI',8)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">FT Determined Ineligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val ">
                          <span class="value-hyper-link">{{insightsRecord?.currentMonthAP?.ftDeterminedIneligibleCount}}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="month-container mar-l-10">
                <div>
                  <span class="month-container-label">Ending by Next Month</span>
                </div>
                <div class="period-container mp-border-color">
                  <div class="period-header mp-bg-color">
                    <em class="fas fa-ruler icon-align"></em>
                    <span class="header-label">Measurement Period</span>
                  </div>
                  <div class="acacontainer">
                    <div class="acacontainer-left filter" [class.selected]="buttonNum==9">
                      <div class="acacontainer-header pad2">Trending Eligible</div>

                      <div class="acacontainer-left-header_val mp-fg-color">
                        <span class="value-hyper-link"
                              (click)="filterAcaMeasurementDetails(false,true,'TDE',9)">{{insightsRecord?.nextMonthMP?.totalDeterminedEligibleCount}}</span>
                      </div>
                    </div>
                    <div class="seprator"></div>
                    <div class="acacontainer-right">

                      <div class="acacontainer-right-content filter" [class.selected]="buttonNum==10" (click)="filterAcaMeasurementDetails(false,true,'PTE',10)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">PT Trending Eligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val mp-fg-color">
                          <span class="value-hyper-link">{{insightsRecord?.nextMonthMP?.ptDeterminedEligibleCount}}</span>
                        </div>
                      </div>
                      <div class="acacontainer-right-content filter" [class.selected]="buttonNum==11" (click)="filterAcaMeasurementDetails(false,true,'FTE',11)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">FT Trending Eligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val mp-fg-color">
                          <span class="value-hyper-link">{{insightsRecord?.nextMonthMP?.ftDeterminedEligibleCount}}</span>
                        </div>
                      </div>
                      <div class="acacontainer-right-content ineligible-txt filter" [class.selected]="buttonNum==12" (click)="filterAcaMeasurementDetails(false,true,'FTI',12)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">FT Trending Ineligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val">
                          <span class="value-hyper-link">{{insightsRecord?.nextMonthMP?.ftDeterminedIneligibleCount}}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="period-container ap-border-color">
                  <div class="period-header ap-bg-color">
                    <em class="fas fa-file-edit icon-align"></em>
                    <span class="header-label">Administrative Period</span>
                  </div>
                  <div class="acacontainer">
                    <div class="acacontainer-left filterap" [class.selected]="buttonNum==13" (click)="filterAcaMeasurementDetails(false,false,'TDE',13)">
                      <div class="acacontainer-header pad2">Trending Eligible</div>

                      <div class="acacontainer-left-header_val ap-fg-color">
                        <span class="value-hyper-link">{{insightsRecord?.nextMonthAP?.totalDeterminedEligibleCount}}</span>
                      </div>
                    </div>
                    <div class="seprator"></div>
                    <div class="acacontainer-right">

                      <div class="acacontainer-right-content filterap" [class.selected]="buttonNum==14" (click)="filterAcaMeasurementDetails(false,false,'PTE',14)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">PT Trending Eligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val ap-fg-color">
                          <span class="value-hyper-link">{{insightsRecord?.nextMonthAP?.ptDeterminedEligibleCount}}</span>
                        </div>
                      </div>
                      <div class="acacontainer-right-content filterap" [class.selected]="buttonNum==15" (click)="filterAcaMeasurementDetails(false,false,'FTE',15)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">FT Trending Eligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val ap-fg-color">
                          <span class="value-hyper-link">{{insightsRecord?.nextMonthAP?.ftDeterminedEligibleCount}}</span>
                        </div>
                      </div>
                      <div class="acacontainer-right-content ineligible-txt filterap" [class.selected]="buttonNum==16" (click)="filterAcaMeasurementDetails(false,false,'FTI',16)">
                        <div class="acacontainer-header-content">
                          <div class="acacontainer-header">FT Trending Ineligible:</div>
                        </div>
                        <div class="acacontainer-right-content-val">
                          <span class="value-hyper-link">{{insightsRecord?.nextMonthAP?.ftDeterminedIneligibleCount}}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </slx-spinner>
        </ng-container>
      </slx-collapsible-section>
    </form>
  </slx-spinner>
</div>
