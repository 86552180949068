import * as _ from 'lodash';
import { Component, EventEmitter, Input, Output, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import { ScheduleConsoleDayIndicator } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-weekly-indicator',
  templateUrl: 'schedule-console-weekly-indicator.component.html',
  styleUrls: ['schedule-console-weekly-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleConsoleWeeklyIndicatorComponent {
  @Input()
  public set days(value: ScheduleConsoleDayIndicator[]) {
    if (!_.isEqual(this.week, value)) {
      this.week = value;
      this.changeDetectorRef.detectChanges();
    }
  }
  public week: ScheduleConsoleDayIndicator[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public get hasDays(): boolean {
    return this.week && this.week.length > 0;
  }
}
