<form #form="ngForm">
    <div class="editor-container">

        <div class="field-container wrapped">
            <div class="col">
                <slx-input-decorator>
                    <slx-dropdown-input slx-input [options]="units" titleField="description" name="unitSelector" placeholder="Select new Unit for Employees"
                        [(ngModel)]="unit" [required]="true" valueField="id">
                    </slx-dropdown-input>
                    <span errorMessage for="required">Please, select unit</span>
                </slx-input-decorator>
            </div>
        </div>

        <div class="actions-bar">
            <div class="col"></div>
            <button class="action-button save-btn" (click)="onOk ()" [disabled]="form.invalid">Reassign</button>
            <button class="action-button cancel-btn" (click)="onCancel ()">Cancel</button>
        </div>

    </div>
</form>