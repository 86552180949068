/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./star-rating.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./star-rating.component";
var styles_StarRatingComponent = [i0.styles];
var RenderType_StarRatingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StarRatingComponent, data: {} });
export { RenderType_StarRatingComponent as RenderType_StarRatingComponent };
function View_StarRatingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "rating-star fas fa-star ", _co.filledItemClassName, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_StarRatingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "rating-empty-star fa-star ", _co.emptyItemClassName, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_StarRatingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "rating-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StarRatingComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StarRatingComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.isSelected; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.isSelected; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_StarRatingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "star-rating-conent"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StarRatingComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ratingData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_StarRatingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-star-rating", [], null, null, null, View_StarRatingComponent_0, RenderType_StarRatingComponent)), i1.ɵdid(1, 114688, null, 0, i3.StarRatingComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StarRatingComponentNgFactory = i1.ɵccf("slx-star-rating", i3.StarRatingComponent, View_StarRatingComponent_Host_0, { filledItemClassName: "filledItemClassName", emptyItemClassName: "emptyItemClassName", readonly: "readonly", rating: "rating", maxValue: "maxValue" }, { ratingChange: "ratingChange" }, []);
export { StarRatingComponentNgFactory as StarRatingComponentNgFactory };
