<form #injuryReport="ngForm">
    <div class="incident-report-body" *ngIf="injury && lookups">
        <div class="incident-report-item actions-bar">
            <span>
                <button *ngIf="!isCreatingNew && report.canDelete" [popover]="confirmPopover"
                    class="disable-btn slx-button slx-with-icon slx-margin-r" type="button"
                    [disabled]="!report.editMode">
                    <i aria-hidden="true" class="fas fa-trash-alt slx-button__icon"></i>
                    <span class="slx-button__text">Delete Report</span>
                </button>
                <popover-content #confirmPopover title="Are you sure?" placement="auto bottom">
                    <span class="disable-message">
                        Are you sure you want to delete this incident report?
                        Deleting this incident report will also delete any associated OSHA and WC Records.
                    </span>
                    <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r"
                        (click)="onClickDelete(confirmPopover)">Yes</button>
                    <button type="button" class="slx-button slx-narrow"
                        (click)="onClickCancel(confirmPopover)">Cancel</button>
                </popover-content>
            </span>
        </div>

        <div class="incident-report-item report-category">
            <span class="blue-title">
                <i class="title-icon far fa-file-medical"></i>
                <span class="title-text">Report Category</span>
            </span>
            <div class="report-category-items">
                <div class="settings-row osha">
                    <div class="left-col">
                        <span class="report-control-label ">OSHA Recordable?</span>
                    </div>
                    <div class="right-col">
                      <slx-radio-button class="p-left-0" name="isOSHARecordableYes" fieldName="isOSHARecordableYes"
                                        [option]="true" caption="Yes" [(ngModel)]="injury.isOSHARecordable"
                                        [readonly]="isOshaSwitchEnabled"
                                        (ngModelChange)="onChangeOsha(true)">
                      </slx-radio-button>
                      <slx-radio-button class="p-left-0" name="isOSHARecordableNo" fieldName="isOSHARecordableNo"
                                        [option]="false" caption="No" [(ngModel)]="injury.isOSHARecordable"
                                        [readonly]="isOshaSwitchDisabled"
                                        (ngModelChange)="onChangeOsha(false)">
                      </slx-radio-button>
                    </div>
                </div>
                <div class="settings-row worker-comp">
                    <div class="left-col">
                        <span class="report-control-label ">Workers' Comp Case?</span>
                    </div>
                    <div class="right-col">
                      <slx-radio-button class="p-left-0" name="isWorkersCompCaseYes" fieldName="isWorkersCompCaseYes"
                                        [option]="true" caption="Yes" [(ngModel)]="injury.isWorkersCompCase"
                                        [readonly]="isWCSwitchEnabled"
                                        (ngModelChange)="onChangeWCCase(true)">
                      </slx-radio-button>
                      <slx-radio-button class="p-left-0" name="isWorkersCompCaseNo" fieldName="isWorkersCompCaseNo"
                                        [option]="false" caption="No" [(ngModel)]="injury.isWorkersCompCase"
                                        [readonly]="isWCSwitchDisabled"
                                        (ngModelChange)="onChangeWCCase(false)">
                      </slx-radio-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="incident-report-item injury-details">
            <slx-wc-report-section title="Injury Details" icon="far fa-band-aid">
                <div class="panel-content">
                    <div class="injury-details-section">
                        <p class="flex report-control">
                            <label class="report-control-label min-width-110">Injury Type</label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list name="injuryType" class="slx-wide slx-short" valueField="id"
                                    titleField="description" [options]="lookups.illnesses" [(ngModel)]="injury.injuryType"
                                    [readonly]="!report.editMode">
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.illnesses" class="slx-error-block">
                                    There is no injury types configured in system
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-110">Location of Injury</label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list name="injuryLocation" class="slx-wide slx-short" valueField="id"
                                    titleField="description" [options]="lookups.bodySides"
                                    [(ngModel)]="injury.injuryLocation" [readonly]="!report.editMode">
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.bodySides" class="slx-error-block">
                                    There is no injury locations configured in system
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow">Has the employee had a prior injury to this
                                part of their body?</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0" name="hasPriorInjuryYes" fieldName="hasPriorInjury"
                                    [option]="true" caption="Yes" [(ngModel)]="injury.hasPriorInjury"
                                    [readonly]="!report.editMode">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="hasPriorInjuryNo" fieldName="hasPriorInjury"
                                    [option]="false" caption="No" [(ngModel)]="injury.hasPriorInjury"
                                    [readonly]="!report.editMode">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow">
                                Was the employee exposed to another person's blood or bodily fluid?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0" name="exposedFluidToAnotherYes"
                                    fieldName="exposedFluidToAnother" [option]="true" caption="Yes"
                                    [(ngModel)]="injury.exposedFluidToAnother" [readonly]="!report.editMode">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="exposedFluidToAnotherNo"
                                    fieldName="exposedFluidToAnother" [option]="false" caption="No"
                                    [(ngModel)]="injury.exposedFluidToAnother" [readonly]="!report.editMode">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow">
                                In addition to the injury sustained, was there a loss of consciousness?
                            </label>
                            <span class="flex__shrink  min-width-150">
                                <slx-radio-button class="p-left-0" name="lossConsciousnessYes"
                                    fieldName="lossConsciousnessYes" [option]="true" caption="Yes"
                                    [(ngModel)]="injury.lossConsciousness" [readonly]="!report.editMode">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="lossConsciousnessNo"
                                    fieldName="lossConsciousnessYes" [option]="false" caption="No"
                                    [(ngModel)]="injury.lossConsciousness" [readonly]="!report.editMode">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-250">
                                What medical treatment was received?
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list class="slx-wide slx-short" name="medicalTreatments" valueField="id"
                                    titleField="name" [options]="lookups.medicalTreatments"
                                    [(ngModel)]="injury.medicalTreatment" [readonly]="!report.editMode">
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.medicalTreatments" class="slx-error-block">
                                    There is no medical treatments configured insystem
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top"
                            *ngIf="injury.medicalTreatment?.name === 'Other Medical Treatment' ">
                            <label class="report-control-label flex-full-line-label">
                                Describe Other Medical Treatment
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="otherTreatmentDescription"
                                    [(ngModel)]="injury.otherTreatmentDescription" [readonly]="!report.editMode"
                                    maxlength="1500" spellcheck="false"></textarea>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-250">
                                Exact location of incident/accident
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list class="slx-wide slx-short" name="exactLocation" valueField="id"
                                    titleField="description" [options]="lookups.incidentLocations"
                                    [(ngModel)]="injury.exactLocation" [readonly]="!report.editMode">
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.incidentLocations" class="slx-error-block">
                                    There is no incident locations configured insystem
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-250">
                                Medical Cost
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-number class="slx-wide slx-input-number" [(ngModel)]="injury.medicalCost" [min]="min" [max]="max" #medicalCost="ngModel" 
                                    [readonly]="!report.editMode" name="medicalCost" [decimals]="2" [autoCorrect]=' false'>
                                </slx-number>
                                <span class="slx-error-block error-box"  *ngIf="medicalCost.errors?.min" errorMessage for="min">should be greater than or equal {{min}}</span>
                                <span  class="slx-error-block error-box"  *ngIf="medicalCost.errors?.max" errorMessage for="max">should not exceed {{max}}</span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-250">
                                Indemnity Cost
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-number class="slx-wide slx-input-number" [(ngModel)]="injury.indemnityCost"[min]="min" [max]="max" #indemnityCost="ngModel"
                                     [readonly]="!report.editMode"  name="indemnityCost" [decimals]="2" [autoCorrect]='false'>
                                </slx-number>
                                <span class="slx-error-block error-box"  *ngIf="indemnityCost.errors?.min" errorMessage for="min">should be greater than or equal {{min}}</span>
                                <span  class="slx-error-block error-box"  *ngIf="indemnityCost.errors?.max" errorMessage for="max">should not exceed {{max}}</span>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                What was employee doing at the time of injury?
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="whatDoingDuringInjury"
                                    [(ngModel)]="report.common.whatDoingDuringInjury"
                                    [readonly]="!report.editMode"
                                     maxlength="255" spellcheck="false"></textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">How did the injury occur?</label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="howInjuryOccur"
                                    [(ngModel)]="report.common.howInjuryOccur"
                                    [readonly]="!report.editMode" maxlength="255"></textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                What was the primary cause of the incident?
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="primaryCause"
                                    [(ngModel)]="injury.primaryCause"
                                    [readonly]="!report.editMode" maxlength="255"></textarea>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow">
                                Was the employee using proper safety equipment?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0" name="usedProperSafetyYes"
                                    fieldName="usedProperSafety" [option]="true" caption="Yes"
                                    [(ngModel)]="injury.usedProperSafety" [readonly]="!report.editMode">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="usedProperSafetyNo"
                                    fieldName="usedProperSafety" [option]="false" caption="No"
                                    [(ngModel)]="injury.usedProperSafety" [readonly]="!report.editMode">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-110">Equipment Type</label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list name="equipmentCode" class="slx-wide slx-short" valueField="id"
                                    titleField="description" [options]="lookups.equipmentTypes"
                                    [(ngModel)]="injury.equipmentCode" [readonly]="!report.editMode">
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.equipmentTypes" class="slx-error-block">
                                    There is no equipment types configured insystem
                                </span>
                            </span>
                        </p>
                        <p *ngIf="isOtherEquipment" class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Describe other equipment
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="primaryCause"
                                    [(ngModel)]="injury.equipmentText"
                                    [readonly]="!report.editMode" maxlength="255"></textarea>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-110">Report Status</label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list name="reportStatus" class="slx-wide slx-short" valueField="id"
                                    titleField="name" [options]="lookups.reportStatuses"
                                    [(ngModel)]="injury.reportStatus" [readonly]="!report.editMode">
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.reportStatuses" class="slx-error-block">
                                    There is no report statuses configured in system
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item witness-details">
            <slx-wc-report-section title="Witness Details" icon="far fa-eye">
                <div class="panel-content">
                    <div class="witness-details-section">
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow">Is witness an employee?</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0" name="isWitnessEmployeeYes"
                                    fieldName="isWitnessEmployee" [option]="true" caption="Yes"
                                    [(ngModel)]="injury.isWitnessEmployee" [readonly]="!report.editMode"
                                    (ngModelChange)="onWitnessIsEmployeeChanged($event)">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="isWitnessEmployeeNo"
                                    fieldName="isWitnessEmployee" [option]="false" caption="No"
                                    [(ngModel)]="injury.isWitnessEmployee" [readonly]="!report.editMode"
                                    (ngModelChange)="onWitnessIsEmployeeChanged($event)">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="!injury.isWitnessEmployee" class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-110">Witness Name</label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field" maxlength="255" name="witnessName"
                                    [(ngModel)]="injury.witnessName" autocomplete="off" type="text"
                                    [readonly]="!report.editMode" />
                            </span>
                        </p>
                        <p *ngIf="injury.isWitnessEmployee" class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-110">Witness Name</label>
                            <span class="flex__grow max-width-250">
                                <slx-autocomplete class="slx-wide" name="witnessEmployeeObj" [lookup]="employeesLookup"
                                    [(ngModel)]="injury.witnessEmployeeObj"
                                    (ngModelChange)="onWitnessEmployeeChanged($event)" [strictSearch]="false"
                                    rightIcon="fas fa-search" [readonly]="!report.editMode">
                                    <ng-template slxAutocompleteItem let-item="item">
                                        <p class="emp-item flex align-items__center">
                                            <img class="emp-item__icon" [employeeThumbinalSrc]="item.id"
                                                alt="Employee Photo" />
                                            <span class="emp-item__info flex__grow">
                                                <strong class="emp-item__name">{{ item.name }}</strong>
                                                <strong class="emp-item__field">Position:&nbsp;</strong>
                                                <span class="emp-item__value">{{ item.position?.name }}</span>
                                                <strong class="emp-item__field">Department:&nbsp;</strong>
                                                <span class="emp-item__value">{{ item.department?.name }}</span>
                                                <strong class="emp-item__field">Facility:&nbsp;</strong>
                                                <span class="emp-item__value">{{ item.organization?.name }}</span>
                                            </span>
                                        </p>
                                    </ng-template>
                                </slx-autocomplete>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-110">Witness Phone</label>
                            <span class="flex__grow max-width-250">
                                <input #witnessPhone="ngModel" class="report-control-field"
                                    [(ngModel)]="injury.witnessPhone" [readonly]="!report.editMode" phone
                                    name="witnessPhone" [textMask]="phoneMaskConf" />
                                <span *ngIf="witnessPhone.invalid" errorMessage for="phone"
                                    class="slx-error-block"></span>
                            </span>
                        </p>
                        <p *ngIf="!injury.isWitnessEmployee" class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                If witness is not an employee, provide complete address
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="witnessAddress"
                                    [(ngModel)]="injury.witnessAddress"
                                    [readonly]="!report.editMode" maxlength="255"></textarea>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item signatures">
            <slx-wc-report-section title="Signatures" icon="far fa-file-signature">
                <div class="panel-content">
                    <div class="signatures-section">
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-350">
                                Did the employee sign the  incident report?
                            </label>
                            <span class="flex__shrink">
                                <slx-radio-button class="p-left-0" name="hasEmployeeSignYes"
                                    fieldName="hasEmployeeSignYes" [option]="true" caption="Yes"
                                    [(ngModel)]="injury.hasEmployeeSign" [readonly]="!report.editMode"
                                    (ngModelChange)="onHasEmployeeSignChanged($event)">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="hasEmployeeSignNo"
                                    fieldName="hasEmployeeSignNo" [option]="false" caption="No"
                                    [(ngModel)]="injury.hasEmployeeSign" [readonly]="!report.editMode"
                                    (ngModelChange)="onHasEmployeeSignChanged($event)">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="injury.hasEmployeeSign" class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">If yes, date signed</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker 
                                    name="employeeSignDate" 
                                    class="slx-wide slx-short"
                                    [(ngModel)]="injury.employeeSignDate" 
                                    [readonly]="!report.editMode"
                                    #employeeSignDate="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="employeeSignDate.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-350">
                                Has the direct supervisor signed the incident report?
                            </label>
                            <span class="flex__shrink">
                                <slx-radio-button class="p-left-0" name="hasSupervisorSignYes"
                                    fieldName="hasSupervisorSignYes" [option]="true" caption="Yes"
                                    [(ngModel)]="injury.hasSupervisorSign"
                                    (ngModelChange)="onHasSupervisorSignChanged($event)" [readonly]="!report.editMode">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="hasSupervisorSignNo"
                                    fieldName="hasSupervisorSignNo" [option]="false" caption="No"
                                    [(ngModel)]="injury.hasSupervisorSign"
                                    (ngModelChange)="onHasSupervisorSignChanged($event)" [readonly]="!report.editMode">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="injury.hasSupervisorSign" class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">
                                If yes, date signed
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker 
                                    name="supervisorSignDate" 
                                    class="slx-wide slx-short"
                                    [(ngModel)]="injury.supervisorSignDate" 
                                    [readonly]="!report.editMode"
                                    #supervisorSignDate="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"                                    
                                    >
                                </slx-datepicker>
                                <span *ngIf="supervisorSignDate.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-350">
                                Has the executive director signed the incident report?
                            </label>
                            <span class="flex__shrink">
                                <slx-radio-button class="p-left-0" name="hasDirectorSignYes"
                                    fieldName="hasDirectorSignYes" [option]="true" caption="Yes"
                                    [(ngModel)]="injury.hasDirectorSign"
                                    (ngModelChange)="onHasDirectorSignChanged($event)" [readonly]="!report.editMode">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="hasDirectorSignNo"
                                    fieldName="hasDirectorSignNo" [option]="false" caption="No"
                                    [(ngModel)]="injury.hasDirectorSign"
                                    (ngModelChange)="onHasDirectorSignChanged($event)" [readonly]="!report.editMode">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="injury.hasDirectorSign" class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">If yes, date signed</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker 
                                    name="exDirectorSignDate" 
                                    class="slx-wide slx-short"
                                    [(ngModel)]="injury.exDirectorSignDate" 
                                    [readonly]="!report.editMode"
                                    #exDirectorSignDate="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate" 
                                    >
                                </slx-datepicker>
                                <span *ngIf="exDirectorSignDate.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink">
                                Name of person entering this report
                            </label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field" maxlength="255" [ngModel]="authorName"
                                    type="text" name="userName" readonly />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow">
                                Was the original accident/injury form completed by the employee?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0" name="isFormCompletedByEmployeeYes"
                                    fieldName="isFormCompletedByEmployeeYes" [option]="true" caption="Yes"
                                    [(ngModel)]="injury.isFormCompletedByEmployee" [readonly]="!report.editMode">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="isFormCompletedByEmployeeNo"
                                    fieldName="isFormCompletedByEmployeeNo" [option]="false" caption="No"
                                    [(ngModel)]="injury.isFormCompletedByEmployee" [readonly]="!report.editMode">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="!injury.isFormCompletedByEmployee" class="flex report-control">
                            <label class="report-control-label min-width-110">
                                If not, who completed the form?
                            </label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field" name="whoCompletedForm" autocomplete="off"
                                    type="text" maxlength="255" [(ngModel)]="injury.whoCompletedForm"
                                    [readonly]="!report.editMode" />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-110">Carrier Name</label>
                            <span class="flex__grow">
                                <input class="report-control-field" name="carrierName" [(ngModel)]="injury.carrierName"
                                    maxlength="255" autocomplete="off" type="text" [readonly]="!report.editMode" />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow">
                                Has a copy of the original form, or a summarized account been provided to the safety committee?
                            </label>
                            <span class="flex__shrink min-width-110">
                                <slx-radio-button class="p-left-0" name="hasCopyFormYes" fieldName="hasCopyFormYes"
                                    [option]="true" caption="Yes" [(ngModel)]="injury.hasCopyForm"
                                    [readonly]="!report.editMode">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0" name="hasCopyForm" fieldName="hasCopyFormNo"
                                    [option]="false" caption="No" [(ngModel)]="injury.hasCopyForm"
                                    [readonly]="!report.editMode">
                                </slx-radio-button>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item comments">
            <slx-wc-report-section title="Comments" icon="far fa-comment-alt" [notifications]="commentsCount">
                <div class="panel-content">
                    <div class="comments-section">
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Additional comments added by employee
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="employeeComment"
                                    [(ngModel)]="injury.employeeComment"
                                    [readonly]="!report.editMode" maxlength="255"></textarea>
                                    <span *ngIf="injury.employeeComment" class="comment-field-note">{{injury.employeeComment.length}}/255</span>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Additional comments added by supervisor
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="supervisorComment"
                                    [(ngModel)]="injury.supervisorComment"
                                    [readonly]="!report.editMode" maxlength="255"></textarea>
                                    <span *ngIf="injury.supervisorComment" class="comment-field-note">{{injury.supervisorComment.length}}/255</span>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Additional comments added by executive director
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="exDirectorComment"
                                    [(ngModel)]="injury.exDirectorComment" 
                                    [readonly]="!report.editMode" maxlength="255"></textarea>
                                    <span *ngIf="injury.exDirectorComment" class="comment-field-note">{{injury.exDirectorComment.length}}/255</span>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Additional comments from person entering this report
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea" name="userComment"
                                    [(ngModel)]="injury.userComment"
                                    [readonly]="!report.editMode" maxlength="255"></textarea>
                                    <span *ngIf="injury.userComment" class="comment-field-note">{{injury.userComment.length}}/255</span>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>
    </div>
</form>
