<kendo-grid #kendoGrid [data]="gridState?.view" (excelExport)="onExcelExport($event)">

    <kendo-grid-column title="Name" field="employee.name">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <a [employeeSectionLink]="dataItem?.employee.id">{{dataItem?.employee.name}}</a>
        </ng-template>
        <ng-template kendoGridFooterTemplate ><strong>Total</strong></ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Payroll No." field="employee.payrollNumber" width="100"></kendo-grid-column>

    <kendo-grid-column title="Primary Position" field="position.name">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem?.position | slxPositionPath: dataItem?.organization: dataItem?.department}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Secondary Position" field="secondaryPosition.name">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem?.secondaryPosition | slxPositionPath: dataItem?.secondaryOrganization: dataItem?.secondaryDepartment}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Rate" width="80" field="rate">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align dollar-sign">{{dataItem.rate | number:'.2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align dollar-sign">
                <strong>{{ totals['rate']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Hours" width="80" field="hours">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{dataItem.hours | number:'.2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align">
                <strong>{{ totals['hours']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-excel fileName="secondary-positions.xlsx">
      <kendo-excelexport-column title="Name" field="employee.name">
        <ng-template kendoExcelExportFooterTemplate>Total</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column title="Payroll No." field="employee.payrollNumber"></kendo-excelexport-column>
      <kendo-excelexport-column title="Primary Position" field="positionPath"></kendo-excelexport-column>
      <kendo-excelexport-column title="Secondary Position" field="secondaryPositionPath"></kendo-excelexport-column>
      <kendo-excelexport-column
        title="Rate"
        field="rate"
        [cellOptions]="{ format: dollarFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['rate']?.sum }}</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Hours"
        field="hours"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right' }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['hours']?.sum }}</ng-template>
      </kendo-excelexport-column>
    </kendo-grid-excel>

    <kendo-grid-pdf fileName="secondary-positions.pdf" [allPages]="true" paperSize="A4" [landscape]="true" [repeatHeaders]="true" [scale]="0.7">
      <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
      <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <div class="page-template">
           <div class="header">
            <div class="title">Secondary Positions - {{ payrollCycle | slxPeriod }}; {{ orgLevelName }}; {{ todayDateTime }}</div>
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
       </ng-template>
    </kendo-grid-pdf>

</kendo-grid>
