import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgModel } from '@angular/forms';
import { dateTimeUtils } from '../../../../common/utils/index';
import { RangeDates } from '../../../models/range-dates';
import { appConfig } from '../../../../app.config';
var DateRangeNgxComponent = /** @class */ (function () {
    function DateRangeNgxComponent() {
        this.format = 'MM/dd/yyyy';
        this.startDatePlaceholder = 'From';
        this.endDatePlaceholder = 'To';
        this.dates = new RangeDates();
        this.dates.startDate = null;
        this.dates.endDate = null;
        this.rangeDateChanged = new EventEmitter();
        this.appConfig = appConfig;
        this.isValidRange = true;
    }
    Object.defineProperty(DateRangeNgxComponent.prototype, "datesValid", {
        get: function () {
            return this.isValidRange && this.isValidDate(this.dates.startDate) && this.isValidDate(this.dates.startDate) && this.isValidFields;
        },
        enumerable: true,
        configurable: true
    });
    DateRangeNgxComponent.prototype.ngOnChanges = function (changes) {
        var startDate = _.get(changes, 'startDate.currentValue', null);
        var endDate = _.get(changes, 'endDate.currentValue', null);
        if (this.isValidDate(startDate)) {
            this.dates.startDate = moment(startDate).toDate();
        }
        if (this.isValidDate(endDate)) {
            this.dates.endDate = moment(endDate).toDate();
        }
        this.checkValidRange();
    };
    DateRangeNgxComponent.prototype.onRangeDateChanged = function (startDate, endDate) {
        var _this = this;
        Promise.resolve(null)
            .then(function () {
            if (_this.isValidDate(startDate) && _this.isValidDate(endDate)) {
                _this.dates.startDate = startDate;
                _this.dates.endDate = endDate;
                _this.checkValidRange();
                if (_this.isValidRange) {
                    _this.emitRangeDateChanged(true);
                }
            }
        });
    };
    DateRangeNgxComponent.prototype.onStartDateChanged = function (date) {
        var _this = this;
        Promise.resolve(null)
            .then(function () {
            if (_this.isValidDate(date)) {
                _this.dates.startDate = date;
                _this.checkValidRange();
                if (_this.isValidFields) {
                    _this.emitRangeDateChanged(false);
                }
            }
        });
    };
    DateRangeNgxComponent.prototype.onEndDateChanged = function (date) {
        var _this = this;
        Promise.resolve(null)
            .then(function () {
            if (_this.isValidDate(date)) {
                _this.dates.endDate = date;
                _this.checkValidRange();
                if (_this.isValidFields) {
                    _this.emitRangeDateChanged(false);
                }
            }
        });
    };
    DateRangeNgxComponent.prototype.onApply = function () {
        if (this.datesValid) {
            this.emitRangeDateChanged(true);
        }
    };
    Object.defineProperty(DateRangeNgxComponent.prototype, "isValidFields", {
        get: function () {
            return this.startDatePicker.valid && this.endDatePicker.valid;
        },
        enumerable: true,
        configurable: true
    });
    DateRangeNgxComponent.prototype.emitRangeDateChanged = function (force) {
        if (force || !this.hasApplyButton) {
            this.rangeDateChanged.emit(this.dates);
        }
    };
    DateRangeNgxComponent.prototype.isValidDate = function (date) {
        return (!_.isNull(date) && !_.isUndefined(date)) && dateTimeUtils.validateDate(date);
    };
    DateRangeNgxComponent.prototype.checkValidRange = function () {
        if (!this.maxSecRange || this.maxSecRange <= 0 || !this.isValidFields) {
            this.isValidRange = true;
            return;
        }
        this.isValidRange = moment(this.dates.endDate).diff(this.dates.startDate, 's') < this.maxSecRange;
    };
    return DateRangeNgxComponent;
}());
export { DateRangeNgxComponent };
