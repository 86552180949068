import { Organization, IOrganization, IDepartment, Department, IPosition, Position, PayType } from '../lookup/index';

export interface IEmployeeShortInfo {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  organization: IOrganization;
  department: IDepartment;
  position: IPosition;
  effectiveDate: string;
  effectiveEndDate: string;
  terminationDate: string;
  activationDate: string;
  isAgency: boolean;
  ssn: string;
  dateOfBirth: Date;
  email: string;
  dateHired: string;
  status: string;
  payrollNumber: string;
  type: string;
  transferDepartmentId: number;
  payType:string;
  //bannerInfoLicense: number;
}

export class EmployeeShortInfo {
  public id: number;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public fullName: string;
  public organization: Organization;
  public department: Department;
  public position: Position;
  public effectiveDate: Date;
  public effectiveEndDate: Date;
  public terminationDate: Date;
  public activationDate: Date;
  public isAgency: boolean;
  public ssn: string;
  public dateOfBirth: Date;
  public dateHired: Date;
  public email: string;
  public payType:PayType;
  public get name(): string {
    if (!this.lastName && !this.firstName) {
      return this.fullName;
    }
    return `${this.lastName}, ${this.firstName}`;
  }
  public status: string;
  public payrollNumber: string;
  public transferDepartmentId: number
  type: string;

  public get avoidsBswiftChecks(): boolean {
    return this.isAgency || this.type === 'AFT' || this.type === 'APT';
  }
  //public bannerInfoLicense: number;
}
