import * as tslib_1 from "tslib";
import { Provider } from '@angular/core';
import { IDialog, DialogOptions2, DialogModeSize, } from '../../../../../../app/common/models/index';
import { ModalService } from './../../../../../common/services/index';
import { BenefitPayrollMappingManagementService } from '../../../services/benefit-console/benefit-payroll-mapping-management.service';
var BenefitPayrollExistingMappingDialogComponent = /** @class */ (function () {
    function BenefitPayrollExistingMappingDialogComponent(options, modalService, manService) {
        this.manService = manService;
        this.benefitPayrollExistingMap = [];
        this.isLoading = false;
        this.subscriptions = {};
        this.options = options;
        this.modalService = modalService;
        this.getExistingMappingData();
    }
    BenefitPayrollExistingMappingDialogComponent.openDialog = function (modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.modeSize = DialogModeSize.grid;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions,
            }
        ];
        var dialog = modalService.globalAnchor.openDialog2(BenefitPayrollExistingMappingDialogComponent, 'Payroll/Benefits Existing Mapping', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result);
        });
        return dialog;
    };
    BenefitPayrollExistingMappingDialogComponent.prototype.getExistingMappingData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.manService.getpayrollExistingMapping()];
                    case 1:
                        _a.sent();
                        this.subscriptions.onLoaded = this.manService
                            .subscribeToBenefitPayrollExistingMapping(function (v) {
                            _this.benefitPayrollExistingMap = v;
                            _this.isLoading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitPayrollExistingMappingDialogComponent.prototype.close = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return BenefitPayrollExistingMappingDialogComponent;
}());
export { BenefitPayrollExistingMappingDialogComponent };
