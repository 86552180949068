import { EmployeeDefinition } from '../../../organization/models/employee/index';
import { ScheduleEntryShift } from './schedule-entry-shift';

export class ScheduleEntry {

  public date: Date;

  /*
  public name: string;
  public payrollNumber: string;
  public employeeId: number;
  */
  public employee: EmployeeDefinition;

  public shifts: ScheduleEntryShift[];

  public defaultPositionId: number;
  public defaultUnitId: number;
  public defaultShiftId: number;

  public nextDayShiftStart: Date;
  public previousDayShiftEnd: Date;
  public canReplaceEmployee: Boolean;

}
