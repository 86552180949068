export class ArrivalsDeparturesAnalytics {
  public static in: ArrivalsDeparturesAnalyticsDefinition = 'In';
  public static out: ArrivalsDeparturesAnalyticsDefinition = 'Out';
  public static scheduled: ArrivalsDeparturesAnalyticsDefinition = 'Scheduled';
  public static unscheduled: ArrivalsDeparturesAnalyticsDefinition = 'Unscheduled';
  public static late: ArrivalsDeparturesAnalyticsDefinition = 'Late';
  public static overtime: ArrivalsDeparturesAnalyticsDefinition = 'Overtime';
  public static difference: ArrivalsDeparturesAnalyticsDefinition = 'Difference';
}

export type ArrivalsDeparturesAnalyticsDefinition = 'In'
  | 'Out'
  | 'Scheduled'
  | 'Unscheduled'
  | 'Late'
  | 'Overtime'
  | 'Difference'
;
