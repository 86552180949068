import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { StateNavigationService, ChangeManagementService, RestoreQueryParamsService } from '../../services/index';

@Directive({
  selector: '[slxHomeLink]',
})
export class HomeLinkDirective {
  private router: Router;
  private route: ActivatedRoute;
  private changeManagement: ChangeManagementService;
  private restoreQueryParamsService: RestoreQueryParamsService;
  constructor(router: Router, route: ActivatedRoute, changeManagement: ChangeManagementService, restoreQueryParamsService: RestoreQueryParamsService) {
    this.router = router;
    this.route = route;
    this.changeManagement = changeManagement;
    this.restoreQueryParamsService = restoreQueryParamsService;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: StateNavigationService = new StateNavigationService(this.router, this.route, this.changeManagement, this.restoreQueryParamsService);
    navService.navigateToHome();
  }
}
