import { EventEmitter, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { LookupMultiselectModel } from '../../../common/models/index';
import { LookupService } from '../../services/index';
var LookupMultiSelectComponent = /** @class */ (function () {
    function LookupMultiSelectComponent(lookupService) {
        this.dropDownWidth = '100%';
        this.lookupService = lookupService;
        this.onFilterChange = new EventEmitter();
    }
    LookupMultiSelectComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe();
    };
    LookupMultiSelectComponent.prototype.hasSelected = function () {
        return _.some(this.lookup, function (item) { return item.isSelected === true; });
    };
    Object.defineProperty(LookupMultiSelectComponent.prototype, "selectedCount", {
        get: function () {
            return _.filter(this.lookup, function (item) { return item.isSelected === true; }).length;
        },
        enumerable: true,
        configurable: true
    });
    LookupMultiSelectComponent.prototype.checkAll = function () {
        _.forEach(this.lookup, function (item) { return item.isSelected = true; });
    };
    LookupMultiSelectComponent.prototype.uncheckAll = function () {
        _.forEach(this.lookup, function (item) {
            item.isSelected = false;
        });
    };
    LookupMultiSelectComponent.prototype.itemIsSelected = function (item) {
        return _.some(this.lookup, function (p) { return p.id === item.id; });
    };
    LookupMultiSelectComponent.prototype.onChange = function (event, item) {
        if (+item.id === 0) {
            if (item.isSelected) {
                this.checkAll();
            }
            else {
                this.uncheckAll();
            }
        }
        var selected = this.lookup.filter(function (x) { return x.isSelected; });
        this.onFilterChange.emit(selected);
    };
    LookupMultiSelectComponent.prototype.chunk = function (arr, size) {
        var newArr = [];
        for (var i = 0; i < arr.length; i += size) {
            newArr.push(arr.slice(i, i + size));
        }
        return newArr;
    };
    Object.defineProperty(LookupMultiSelectComponent.prototype, "lookupName", {
        get: function () {
            return this.lookupNameValue;
        },
        set: function (value) {
            this.lookupNameValue = value;
            this.loadList();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LookupMultiSelectComponent.prototype, "orgLevelId", {
        get: function () {
            return this.orgLevelIdValue;
        },
        set: function (value) {
            this.orgLevelIdValue = value;
            this.loadList();
        },
        enumerable: true,
        configurable: true
    });
    LookupMultiSelectComponent.prototype.loadList = function () {
        var _this = this;
        if (!this.lookupName || !this.orgLevelId)
            return;
        var promise = this.lookupService.getLookup({ lookupType: this.lookupName, orgLevelId: this.orgLevelId, employeeId: undefined });
        promise.then(function (value) {
            _this.lookup = _this.mapModels(value);
        });
    };
    LookupMultiSelectComponent.prototype.mapModels = function (data) {
        var models = [];
        var model = new LookupMultiselectModel();
        model = new LookupMultiselectModel();
        model.id = '0';
        model.name = 'All';
        model.isSelected = false;
        models.push(model);
        _.each(data.items, function (dto) {
            model = new LookupMultiselectModel();
            model.id = dto[data.valueField];
            model.name = dto[data.titleField];
            model.isSelected = false;
            models.push(model);
        });
        return models;
    };
    LookupMultiSelectComponent.prototype.unsubscribe = function () {
        if (this.lookupSubscription) {
            this.lookupSubscription.unsubscribe();
        }
    };
    return LookupMultiSelectComponent;
}());
export { LookupMultiSelectComponent };
