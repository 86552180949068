<div>
    <div class="content">
        <h4>Are you sure you want to restart:<b>{{timeClock.clockName}} </b>?</h4>
        <br>
        <h5>This could take a few minutes and your staff may not be able to use the clock until the restart has completed.</h5>
    </div>
    <div class="footer-btns-container">
        <button type="button" class="discard-btn" (click)="cancel()">
        Discard
        </button>
        <button type="button" class="save-btn" (click)="save()">
        Yes
        </button>
    </div>
</div>
