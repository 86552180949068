import { FieldAccessType } from './field-access-type';
var FieldSecurityAttribute = /** @class */ (function () {
    function FieldSecurityAttribute() {
    }
    Object.defineProperty(FieldSecurityAttribute.prototype, "editable", {
        get: function () {
            return this.access === FieldAccessType.full;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FieldSecurityAttribute.prototype, "masked", {
        get: function () {
            return this.access === FieldAccessType.masked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FieldSecurityAttribute.prototype, "hidden", {
        get: function () {
            return this.access === FieldAccessType.hidden;
        },
        enumerable: true,
        configurable: true
    });
    return FieldSecurityAttribute;
}());
export { FieldSecurityAttribute };
