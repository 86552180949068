/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./daily-timecard-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../components-library/components/buttons/collapse-button/collapse-button.component.ngfactory";
import * as i4 from "../../../../components-library/components/buttons/collapse-button/collapse-button.component";
import * as i5 from "../../../../components-library/models/collapse-component";
import * as i6 from "../../../../components-library/directives/collapse/collapse-by-css.directive";
import * as i7 from "./daily-timecard-section.component";
var styles_DailyTimecardSectionComponent = [i0.styles];
var RenderType_DailyTimecardSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DailyTimecardSectionComponent, data: {} });
export { RenderType_DailyTimecardSectionComponent as RenderType_DailyTimecardSectionComponent };
export function View_DailyTimecardSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "section-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "height": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "section-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "section-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [["class", "section-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "section-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "slx-collapse-button", [], null, [[null, "isCollapsedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isCollapsedChange" === en)) {
        var pd_0 = ((_co.isCollapsed = $event) !== false);
        ad = (pd_0 && ad);
    } if (("isCollapsedChange" === en)) {
        var pd_1 = (_co.collapsedChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_CollapseButtonComponent_0, i3.RenderType_CollapseButtonComponent)), i1.ɵdid(12, 49152, null, 0, i4.CollapseButtonComponent, [], { isCollapsed: [0, "isCollapsed"] }, { isCollapsedChange: "isCollapsedChange" }), i1.ɵprd(2048, null, i5.COLLAPSE_COMPONENT, null, [i4.CollapseButtonComponent]), i1.ɵdid(14, 4341760, null, 0, i6.CollapseByCssDirective, [i5.COLLAPSE_COMPONENT, i1.Renderer2], { elementToCollapse: [0, "elementToCollapse"] }, null), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(16, 0, [["collapseContent", 1]], null, 4, "div", [["class", "section-content"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(18, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(19, { "locked-state": 0 }), i1.ɵncd(null, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.heightValue); _ck(_v, 2, 0, currVal_0); var currVal_3 = _co.isCollapsed; _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵnov(_v, 16); _ck(_v, 14, 0, currVal_4); var currVal_5 = "section-content"; var currVal_6 = _ck(_v, 19, 0, _co.isLocked); _ck(_v, 18, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "section-main-icon ", _co.iconClass, ""); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.title; _ck(_v, 8, 0, currVal_2); }); }
export function View_DailyTimecardSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-daily-timecard-section", [], null, null, null, View_DailyTimecardSectionComponent_0, RenderType_DailyTimecardSectionComponent)), i1.ɵdid(1, 49152, null, 0, i7.DailyTimecardSectionComponent, [], null, null)], null, null); }
var DailyTimecardSectionComponentNgFactory = i1.ɵccf("slx-daily-timecard-section", i7.DailyTimecardSectionComponent, View_DailyTimecardSectionComponent_Host_0, { title: "title", iconClass: "iconClass", isLocked: "isLocked", lockedText: "lockedText", sectionHeight: "sectionHeight", isCollapsed: "isCollapsed" }, { isCollapsedChange: "isCollapsedChange" }, ["[slxDailyTimecardSectionHeaderLeft]", "[slxDailyTimecardSectionHeaderRight]", "[slxDailyTimecardSectionContent]"]);
export { DailyTimecardSectionComponentNgFactory as DailyTimecardSectionComponentNgFactory };
