import { OnInit, EventEmitter } from '@angular/core';
import { TaConsoleToolbarService } from '../../../services/ta-console/ta-console-toolbar.service';
import { screenUtils } from '../../../../common/utils/index';
import { ToolbarSectionTypes } from '../../../../common/models/index';
import { appConfig } from '../../../../app.config';
var TaConsoleToolbarComponent = /** @class */ (function () {
    function TaConsoleToolbarComponent(consoleToolbarService) {
        this.consoleToolbarService = consoleToolbarService;
        this.onDateChanged = new EventEmitter();
        this.onNavigateToMissingPunches = new EventEmitter();
        this.onNavigateToExceptions = new EventEmitter();
        this.appConfig = appConfig;
    }
    Object.defineProperty(TaConsoleToolbarComponent.prototype, "dateFormat", {
        get: function () {
            return screenUtils.isMobile ? 'MM/dd/yy' : 'MMMM dd, yyyy';
        },
        enumerable: true,
        configurable: true
    });
    TaConsoleToolbarComponent.prototype.ngOnInit = function () {
        //
    };
    TaConsoleToolbarComponent.prototype.onFilterDateChanged = function (date) {
        this.dateOn = date;
        this.onDateChanged.emit(this.dateOn);
    };
    TaConsoleToolbarComponent.prototype.navigateToPunchMissing = function () {
        this.onNavigateToMissingPunches.emit();
    };
    TaConsoleToolbarComponent.prototype.navigateToTimeException = function () {
        this.onNavigateToExceptions.emit();
    };
    TaConsoleToolbarComponent.prototype.isVisibleSection = function (sectionType) {
        return sectionType === ToolbarSectionTypes.VISIBLE;
    };
    TaConsoleToolbarComponent.prototype.isAllSectionShown = function (sections) {
        return sections[ToolbarSectionTypes.COLLAPSED_BY_RESIZE] === 0;
    };
    return TaConsoleToolbarComponent;
}());
export { TaConsoleToolbarComponent };
