<kendo-grid class="agency-details" [data]="gridState.view">
  <kendo-grid-column title="" width="700">
  </kendo-grid-column>
  <kendo-grid-column title="Incentive Pay Details" width="200">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{getIncentivePayDetails(dataItem.attributes)}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Agency Name" width="200">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.name }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>