import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { IColumnSettings } from '../../../../core/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper } from '../../../../common/models/index';
import { SupervisedEmployee } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-eligible-supervised-employee-grid',
  templateUrl: 'eligible-supervised-employee-grid.component.html',
  styleUrls: ['eligible-supervised-employee-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EligibleSupervisedEmployeesGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public eligibleEmployees: SupervisedEmployee[];
  @Output()
  public selectionChanged: EventEmitter<SupervisedEmployee[]>;

  public appConfig: IApplicationConfig;
  public selectedRecords: SupervisedEmployee[];
  public gridState: KendoGridStateHelper<SupervisedEmployee>;
  public selectionHelper: KendoGridCustomSelectionHelper<SupervisedEmployee>;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  constructor(private changeDetector: ChangeDetectorRef) {
    this.gridState = new KendoGridStateHelper<SupervisedEmployee>();
    this.selectionHelper = new KendoGridCustomSelectionHelper<SupervisedEmployee>(this.gridState.view, false);
    this.selectionChanged = new EventEmitter<SupervisedEmployee[]>();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }


  public allSelectionChange(): void {
    this.selectionHelper.onToggleAllSelected();
    this.selectedRecords = this.selectionHelper.selection.slice(0);
    this.selectionChanged.emit(this.selectedRecords);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public selectionChange(supervisor: SupervisedEmployee): void {
    this.selectionHelper.onItemSelectionChange(supervisor);
    this.selectedRecords = this.selectionHelper.selection.slice(0);
    this.selectionChanged.emit(this.selectedRecords);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private refreshGrid(): void {
    if (!this.eligibleEmployees) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.eligibleEmployees, this.gridState.state);
    this.selectionHelper.view = this.gridState.view;
  }
}

