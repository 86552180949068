import * as tslib_1 from "tslib";
import { Provider, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DialogOptions2, } from '../../../../common/models/index';
import { ModalService } from './../../../../common/services/index';
import { EmployeeSendSms, SlateMessageSendDTO, MessageSendDTO } from '../../models/index';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { Subscription } from 'rxjs/Subscription';
import { EmployeeSmsManagementService } from '../../../../../app/configuration/services/employee-sms-management/employee-sms-management.service';
import { Subject, Observable } from 'rxjs';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { SegmentedMessage } from '../../utils/segments-calculate';
import { NotificationsService } from '../../../../core/components';
import { MessagesManagementService } from '../../services/messages-management.service';
import { SlateMessagesApiService } from '../../services/slate-messages-api.service';
import { SlateMessagesManagementService } from '../../services/slate-messages-management.service';
var SmsComposeNewDialogComponent = /** @class */ (function () {
    function SmsComposeNewDialogComponent(dialogOptions, notificationsService, modalService, employeeManService, appSettingsManageService, changeDetectorRef, messagesManagementService, slateMessagesApiService, slateMessagesManagementService) {
        this.dialogOptions = dialogOptions;
        this.notificationsService = notificationsService;
        this.modalService = modalService;
        this.employeeManService = employeeManService;
        this.appSettingsManageService = appSettingsManageService;
        this.changeDetectorRef = changeDetectorRef;
        this.messagesManagementService = messagesManagementService;
        this.slateMessagesApiService = slateMessagesApiService;
        this.slateMessagesManagementService = slateMessagesManagementService;
        this.isDisabled = false;
        this.activeTab = 0;
        this.isExpanded = false;
        this.canSendSMS = false;
        this.canSendSlate = false;
        this.subject = '';
        this.message = '';
        this.message1 = "";
        this.mobileNoParseRegex = /[\(\)\-\s']+/g;
        this.maxCharPerSms = 320;
        this.selectEmployeeSMSContacts = [];
        this.selectEmployeeSlateContacts = [];
        this.subscriptions = {};
        this.messageCount = 0;
        this.employeeSMSContacts = [];
        this.searchTerm$ = new Subject();
        this.orgLevelID = null;
        this.searchedValue = '';
    }
    SmsComposeNewDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.employeeManService.searchEmployee(this.searchTerm$)
            .subscribe(function (v) {
            var _loop_1 = function (e) {
                v = v.filter(function (val) { return e.employeeId != val.employeeId; });
            };
            for (var _i = 0, _a = _this.selectEmployeeSMSContacts; _i < _a.length; _i++) {
                var e = _a[_i];
                _loop_1(e);
            }
            if (_this.searchedValue !== '' && _this.searchedValue.length >= 2) {
                _this.employeeSMSContacts = v;
            }
        });
        this.getAppSettings();
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id) {
                _this.orgLevelID = orgLevel.id;
            }
        });
        this.messagesManagementService.getComposeNewTabsPermissions(this.orgLevelID).then(function (cNewTabs) {
            _this.canSendSMS = cNewTabs.canSendSMS;
            _this.canSendSlate = cNewTabs.canSendSlate;
            if (!_this.canSendSMS && _this.canSendSlate) {
                _this.activeTab = 1;
            }
            _this.multiselectText.toggle(false);
        });
    };
    SmsComposeNewDialogComponent.prototype.getAppSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        _a.appSettings = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SmsComposeNewDialogComponent.openDialog = function (modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.height = "70%";
        dialogOptions.width = "50%";
        dialogOptions.minWidth = 300;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions,
            }
        ];
        var dialog = modalService.globalAnchor.openDialog2(SmsComposeNewDialogComponent, 'Compose New Message', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result);
        });
        return dialog;
    };
    SmsComposeNewDialogComponent.prototype.handleFilter = function (value) {
        value = value.trim(' ');
        this.searchedValue = value;
        this.employeeSMSContacts = [];
        if (value != '' && value.length < 100 && value.length >= 2) {
            this.searchTerm$.next(value);
        }
        else {
            this.searchTerm$.next(null);
        }
    };
    SmsComposeNewDialogComponent.prototype.itemDisabled = function (itemArgs) {
        if (itemArgs.dataItem.mobilePhoneNumber === "(___) ___-____")
            itemArgs.dataItem.mobilePhoneNumber = null;
        if (itemArgs.dataItem.mobilePhoneNumber != null && itemArgs.dataItem.empOptIn == 1)
            return false;
        else
            return true;
    };
    SmsComposeNewDialogComponent.prototype.slateEmployeeDisabled = function (itemArgs) {
        if (itemArgs.dataItem.mobilePhoneNumber === "(___) ___-____")
            itemArgs.dataItem.mobilePhoneNumber = null;
        if (itemArgs.dataItem.badgeId != null && itemArgs.dataItem.badgeId > 0) {
            return false;
        }
        return true;
    };
    SmsComposeNewDialogComponent.prototype.validationMessage = function () {
        this.changeDetectorRef.detectChanges();
        if (this.message1.length > this.maxCharPerSms) {
            this.message1 = this.message1.substring(0, this.maxCharPerSms);
        }
        this.messageCount = this.message1.length;
        this.changeDetectorRef.markForCheck();
    };
    SmsComposeNewDialogComponent.prototype.validateSlateMessage = function () {
        this.changeDetectorRef.detectChanges();
        if (this.message.length > this.maxCharPerSms) {
            this.message = this.message.substring(0, this.maxCharPerSms);
        }
        this.messageCount = this.message.length;
        this.changeDetectorRef.markForCheck();
    };
    SmsComposeNewDialogComponent.prototype.sendSms = function () {
        var _this = this;
        var employeeSendSms = new EmployeeSendSms();
        employeeSendSms.messages = [];
        for (var _i = 0, _a = this.selectEmployeeSMSContacts; _i < _a.length; _i++) {
            var emp = _a[_i];
            if (emp.empOptIn != 1 || !emp.mobilePhoneNumber) {
                throw new Error("Error in Employee Object. either empOptIn or mobilePhoneNumber values are incorrect. empOptIn: " + emp.empOptIn + ", mobilePhoneNumber: " + emp.mobilePhoneNumber);
            }
            employeeSendSms.orgLevelId = this.orgLevelID;
            employeeSendSms.accountSid = this.appSettings.TwilioAccountSid;
            employeeSendSms.authToken = this.appSettings.TwilioAuthToken;
            employeeSendSms.messages.push({
                body: this.message1,
                fullName: emp.employeeName,
                to: emp.countryCode + emp.mobilePhoneNumber.replace(this.mobileNoParseRegex, ""),
                from: this.appSettings.TwilioSenderPhone,
                read: true,
                employeeId: emp.employeeId,
                isOptInRequest: false
            });
        }
        if (employeeSendSms.accountSid == null && employeeSendSms.authToken == null) {
            this.isLoading = false;
            this.notificationsService.error('Authorization token not found, Please contact support.');
            this.dialogResult = false;
            this.closeWindow();
            return;
        }
        this.isLoading = true;
        this.employeeManService.sendSmsToEmployee(employeeSendSms).then(function () {
            _this.isLoading = false;
            _this.notificationsService.success('SMS message sent', 'SMS message was sent successfully.');
            _this.dialogResult = true;
            _this.closeWindow();
        })
            .catch(function () {
            _this.isLoading = false;
            _this.notificationsService.error('Unexpected error', 'Please try again or contact support.');
            _this.dialogResult = false;
            _this.closeWindow();
        });
    };
    SmsComposeNewDialogComponent.prototype.sendSlateMessage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var slateMessageRequest, exeption_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.subject.trim().length > 0 && this.message.trim().length > 0 && this.selectEmployeeSlateContacts.length > 0)) return [3 /*break*/, 4];
                        slateMessageRequest = new SlateMessageSendDTO();
                        slateMessageRequest.message = new MessageSendDTO();
                        slateMessageRequest.message.messageContent = this.message;
                        slateMessageRequest.message.subject = this.subject;
                        slateMessageRequest.employeeIds = this.selectEmployeeSlateContacts.map(function (contact) {
                            return contact.employeeId;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.isLoading = true;
                        return [4 /*yield*/, this.slateMessagesApiService.sendSlateMessage(this.orgLevelID, slateMessageRequest)];
                    case 2:
                        _a.sent();
                        this.slateMessagesManagementService.updateMessagesData();
                        this.notificationsService.success('Slate message sent', 'Slate message was sent successfully.');
                        this.dialogResult = true;
                        this.closeWindow();
                        this.isLoading = false;
                        return [3 /*break*/, 4];
                    case 3:
                        exeption_1 = _a.sent();
                        this.dialogResult = false;
                        this.closeWindow();
                        this.isLoading = false;
                        this.notificationsService.error('Unexpected error', 'Please try again or contact support.');
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    SmsComposeNewDialogComponent.prototype.sendMessage = function () {
        this.activeTab == 0 ? this.sendSms() : this.sendSlateMessage();
    };
    SmsComposeNewDialogComponent.prototype.cancel = function () {
        this.closeWindow();
    };
    SmsComposeNewDialogComponent.prototype.closeWindow = function () {
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    SmsComposeNewDialogComponent.prototype.getSegmentCount = function () {
        var encoding = "auto";
        var segmentedMessage = new SegmentedMessage(this.activeTab == 0 ? this.message1 : this.message, encoding);
        return segmentedMessage.segments.length == 1 ? "1 Segment" : segmentedMessage.segments.length + " Segments";
    };
    SmsComposeNewDialogComponent.prototype.onClickTab = function (tab) {
        if (this.activeTab != tab) {
            this.clearData();
        }
        if (this.activeTab === tab || this.isDisabled)
            return;
        this.activeTab = tab;
        this.isActiveMenu(this.activeTab);
    };
    SmsComposeNewDialogComponent.prototype.isActiveMenu = function (tabOrder) {
        return this.activeTab === tabOrder;
    };
    SmsComposeNewDialogComponent.prototype.clearData = function () {
        this.message = '';
        this.message1 = '';
        this.subject = '';
        this.selectEmployeeSlateContacts = [];
        this.selectEmployeeSMSContacts = [];
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], SmsComposeNewDialogComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SmsComposeNewDialogComponent.prototype, "orgLevelSubscription", void 0);
    return SmsComposeNewDialogComponent;
}());
export { SmsComposeNewDialogComponent };
