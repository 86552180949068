/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-sections-warnings-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../employee-sections-warnings-entity/employee-sections-warnings-entity.component.ngfactory";
import * as i3 from "../employee-sections-warnings-entity/employee-sections-warnings-entity.component";
import * as i4 from "../../../../services/employee-section-personal/employee-section-warnings-management.service";
import * as i5 from "./employee-sections-warnings-dialog.component";
import * as i6 from "../../../../models/employee-sections-personal/employee-sections-warnings";
import * as i7 from "../../../../../../common/models/dialog-options";
import * as i8 from "../../../../../../common/services/modal/modal.service";
var styles_EmployeeSectionsWarningsDialogComponent = [i0.styles];
var RenderType_EmployeeSectionsWarningsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeSectionsWarningsDialogComponent, data: {} });
export { RenderType_EmployeeSectionsWarningsDialogComponent as RenderType_EmployeeSectionsWarningsDialogComponent };
export function View_EmployeeSectionsWarningsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-employee-sections-warnings-entity", [], null, [[null, "warningEdited"], [null, "changeWarning"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("warningEdited" === en)) {
        var pd_0 = (_co.onEditedWarning($event) !== false);
        ad = (pd_0 && ad);
    } if (("changeWarning" === en)) {
        var pd_1 = (_co.onChangedWarning($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EmployeeSectionsWarningsEntityComponent_0, i2.RenderType_EmployeeSectionsWarningsEntityComponent)), i1.ɵdid(2, 245760, null, 0, i3.EmployeeSectionsWarningsEntityComponent, [i4.EmployeeSectionWarningsManagementService, i1.ChangeDetectorRef], { employeeWarning: [0, "employeeWarning"] }, { changeWarning: "changeWarning", warningEdited: "warningEdited" }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUserAction(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUserAction(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Save "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.warning; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.canSave; _ck(_v, 7, 0, currVal_1); }); }
export function View_EmployeeSectionsWarningsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-sections-warnings-dialog", [], null, null, null, View_EmployeeSectionsWarningsDialogComponent_0, RenderType_EmployeeSectionsWarningsDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.EmployeeSectionsWarningsDialogComponent, [i6.EmployeeWarningBasic, i7.DialogOptions2, i8.ModalService], null, null)], null, null); }
var EmployeeSectionsWarningsDialogComponentNgFactory = i1.ɵccf("slx-employee-sections-warnings-dialog", i5.EmployeeSectionsWarningsDialogComponent, View_EmployeeSectionsWarningsDialogComponent_Host_0, {}, {}, []);
export { EmployeeSectionsWarningsDialogComponentNgFactory as EmployeeSectionsWarningsDialogComponentNgFactory };
