import * as _ from 'lodash';
import { IAccrualRecalculate, AccrualRecalculate } from './accruals-recalculate';

export interface IAccrualRecalculateDialogOptions {
    accrual: IAccrualRecalculate[]
}
export class AccrualRecalculateDialogOptions {
    accrual: AccrualRecalculate[]

    constructor() {
        this.accrual = [];
    }
}
