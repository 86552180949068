export interface IEmployeeSendSms {
    orgLevelId: number;
    accountSid: string;
    authToken: string;
    messages: IMessage[];
}

class IMessage {
    public body: string;
    public to: string;
    public from: string;
    public fullName: string;
    public employeeId: number;
    public read: boolean;
    public isOptInRequest: boolean;
}

class Message {
    public body: string;
    public to: string;
    public from: string;
    public fullName: string;
    public employeeId: number;
    public read: boolean;
    public isOptInRequest: boolean;
}

export class EmployeeSendSms {
    orgLevelId: number;
    accountSid: string;
    authToken: string;
    messages: Message[];
}

export interface IEmployeeSendSmsResponse {
    id: string;
    body: string;
    recipients: string;
    phone: string;
    sender: string;
    fullName: string;
    userId: number;
    alias: string;
    replied: boolean;
    read: boolean;
    archived: boolean;
    parentId: string;
    smsSid: string;
    messageSid: string;
    employeeId: number;
    accountSid: string;
    authToken: string;
    status: string;
    created: string;
    modified: string;
    errorCode: string;
    isOptInRequest: boolean;
    userName: string;
}

export class EmployeeSendSmsResponse {
    public id: string;
    public body: string;
    public recipients: string;
    public phone: string;
    public sender: string;
    public fullName: string;
    public userId: number;
    public alias: string;
    public replied: boolean;
    public read: boolean;
    public archived: boolean;
    public parentId: string;
    public smsSid: string;
    public messageSid: string;
    public employeeId: number;
    public accountSid: string;
    public authToken: string;
    public status: string;
    public created: string;
    public modified: string;
    public errorCode: string;
    public isOptInRequest: boolean;
    public userName: string;
}

export interface ISlateMessageSendDTO {
  message: IMessageSendDTO;
  employeeIds: number[];
}

export class SlateMessageSendDTO {
  public message: MessageSendDTO;
  public employeeIds: number[];
}

export interface IMessageSendDTO {
  messageContent: string;
  subject: string;
}

export class MessageSendDTO {
 public messageContent: string;
 public subject: string;
}
