import { OnInit, OnDestroy, NgZone } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { EmployeeSectionsBase } from '../../models/index';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../../framework/index';
import { EmployeeSectionsTemporalModel } from '../../models/index';
import { FieldData } from '../../../../core/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { EmployeeSubSectionsDecoratorComponent } from '../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeShortInfo } from '../../../../organization/models/index';

export abstract class EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  public abstract employeeId: number;
  public state: {
    isEditMode: boolean;
    isLoaded: boolean;
  };
  public employeeSectionsTemporalModel: EmployeeSectionsTemporalModel;
  public abstract get form(): AbstractControl;
  protected appConfig: IApplicationConfig;
  protected decorator: EmployeeSubSectionsDecoratorComponent;
  protected ngZone: NgZone;
  protected isDestroyed: boolean;
  protected isDirty: boolean;
  private onStableSubscription: Subscription;

  constructor(decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone) {
    Assert.isNotNull(decorator, 'decorator');
    this.appConfig = appConfig;
    this.ngZone = ngZone;
    this.decorator = decorator;
    this.decorator.registerSubsectionComponent(this);
    this.state = {
      isEditMode: false,
      isLoaded: false
    };
    this.isDestroyed = false;
  }

  public abstract getSubsectionModel(): EmployeeSectionsBase;


  public get employeeShortInfo(): EmployeeShortInfo {
    return this.decorator.employeeShortInfo;
  }

  public get isEditable(): boolean {
    return this.decorator.isEditable;
  }

  public get hasActiveState(): boolean {
    return this.decorator.hasActiveState;
  }

  public get hasAccessToEdit(): boolean {
    return this.decorator.hasAccessToEdit;
  }
  public get hasAccessToAdd(): boolean {
    return this.decorator.hasAccessToAdd;
  }
  public get hasAccessToDelete(): boolean {
    return this.decorator.hasAccessToDelete;
  }

  public ngOnInit(): void {
    this.onStableSubscription = this.ngZone.onStable.subscribe((arg: any) => {
      this.checkIsTemporalDirty();
      if (!this.isDirty && this.form && this.form.dirty && this.state.isEditMode) {
        this.setDirty();
      }
    });
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;
    if (this.onStableSubscription) {
      this.onStableSubscription.unsubscribe();
    }
  }

  public load(force: boolean): void {
    if (!force && this.state.isLoaded) return;
    this.startProgress();
    this.loadSubsection();
    this.clearDirty();
  }

  public startProgress(): void {
    this.decorator.startProgress();
  }

  public stopProgress(): void {
    this.decorator.stopProgress();
  }

  public setEditState(editState: boolean): void {
    this.state.isEditMode = editState;
    if (!editState) {
      this.clearDirty();
    }
  }

  public Save(effectiveDate: Date): void {
    this.doSave(effectiveDate);
  }

  public canSave(): Promise<boolean> {
    return Promise.resolve(true);
  }

  protected checkIsTemporalDirty(): void {
    if (!this.state.isEditMode) return;
    this.employeeSectionsTemporalModel = this.checkTemporalDirty();
    this.decorator.setTemporalModel(this.employeeSectionsTemporalModel);
  }

  protected metaFieldsTemporalDirtyArrayChecker(models: any[]): EmployeeSectionsTemporalModel {
    let temporalModel: EmployeeSectionsTemporalModel;
    _.forEach(models, (model: any) => {
      let tm: EmployeeSectionsTemporalModel = this.metaFieldsTemporalDirtyChecker(model);
      temporalModel = this.metaConcatTemporalModels(temporalModel, tm);
    });
    return temporalModel;
  }

  protected metaFieldsTemporalDirtyChecker(model: any): EmployeeSectionsTemporalModel {
    let temporalModel: EmployeeSectionsTemporalModel = new EmployeeSectionsTemporalModel();
    if (!model) return temporalModel;
    if ((model.isOnlyPayRate && model.isNotOnlyPayRate) && (model.isOnlyPayRate() || model.isNotOnlyPayRate())) {
      temporalModel.isOnlyPayRateDirty = model.isOnlyPayRate();
      temporalModel.isNotOnlyPayRateDirty = model.isNotOnlyPayRate();
      temporalModel.payrollEffectiveDateSetting = model.effectiveDateSetting;
    }
    _.forOwn(model, (value: any, key: string) => {
      if (!_.has(value, 'isTemporal') || value.isTemporal !== true) return;
      let fieldData: FieldData<any> = <FieldData<any>>value;
      this.metaFieldTemporalDirtyChecker(temporalModel, fieldData);
    });
    return temporalModel;
  }

  protected metaFieldTemporalDirtyChecker(temporalModel: EmployeeSectionsTemporalModel, fieldData: FieldData<any>): void {
    temporalModel.isTemporalDirty = temporalModel.isTemporalDirty || fieldData.isDirty;
    if (!temporalModel.minEffectiveData) {
      temporalModel.minEffectiveData = fieldData.temporalMinDate;
    } else {
      temporalModel.minEffectiveData = moment.max(moment(temporalModel.minEffectiveData), moment(fieldData.temporalMinDate)).toDate();
    }
  }

  protected metaConcatTemporalModels(temporalModel1: EmployeeSectionsTemporalModel, temporalModel2: EmployeeSectionsTemporalModel): EmployeeSectionsTemporalModel {
    let temporalModel: EmployeeSectionsTemporalModel;
    if (!temporalModel1 && !temporalModel2) {
      return new EmployeeSectionsTemporalModel();
    }
    if (!temporalModel1) {
      return temporalModel2;
    }
    if (!temporalModel2) {
      return temporalModel1;
    }
    temporalModel = temporalModel1;
    temporalModel.isTemporalDirty = temporalModel2.isTemporalDirty || temporalModel.isTemporalDirty;
    if (!temporalModel.minEffectiveData) {
      temporalModel.minEffectiveData = temporalModel2.minEffectiveData;
    } else {
      temporalModel.minEffectiveData = moment.max(moment(temporalModel.minEffectiveData), moment(temporalModel2.minEffectiveData)).toDate();
    }
    return temporalModel;
  }

  protected onActionComplete(reload: boolean): void {
    this.load(reload);
    this.clearDirty();
  }

  protected onActionError(reason: any): void {
    //TODO error handler (show or log message)
    this.stopProgress();
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    return null;
  }

  protected doSave(effectiveDate: Date): void {
    //virtual
  }

  public showCurrentPayCycleWarning(): void {
    //virtual
  }
  public disabledDate(date:Date): any {
    //virtual
  }
  public isValidEffectiveDate(date: Date): any {

  }
  public resetCurrentPayCycleWarning(): void { }

  protected abstract loadSubsection(): void;

  protected setDirty(): void {
    this.isDirty = true;
    this.decorator.setChanges();
  }

  protected clearDirty(): void {
    if (this.form) {
      this.form.markAsPristine();
    }
    this.isDirty = false;
    this.decorator.clearChanges();
  }
}
