import { OpenShiftDetails } from './../../../models/open-shift-management/open-shift-details';
import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import { EmployeeMessagesApiService } from './../../../../employee/employee/services/employee-messages/employee-messages-api.service';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { appConfig, IApplicationConfig } from './../../../../app.config';
import { EmployeeMessage, EmployeeMessageState } from './../../../models/open-shift-management/employee-message';
import { KendoGridStateHelper } from './../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { OpenShiftManagementApiService } from '../../../../../app/scheduler/services';
import { mutableSelect } from '../../../../core/decorators';
import { Observable } from 'rxjs-compat';
import { ISession } from '../../../../authentication/store';
import { OrgLevel } from '../../../../../app/state-model/models';
import { NotificationsService } from '../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-shift-messages-grid',
  templateUrl: 'daily-shift-messages-grid.component.html',
  styleUrls: ['daily-shift-messages-grid.component.scss'],
})
export class DailyShiftMessagesGridComponent implements OnDestroy {
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<EmployeeMessage>;
  public partnerShiftId: number;
  public isPartnerShift:boolean = false;
  public hasPartialShift:boolean = false;

  @Input()
  public set summary(value: OpenShiftDetails) {
      this.m_summary = value;
      if (value) {
        this.messages = value.messages;
        this.partnerShiftId = value.partnerShiftId;
        if(this.partnerShiftId){
          this.messages = value.partnerMessages;
          this.isPartnerShift = true;
        }
      }
      
  }
  public get summary(): OpenShiftDetails {
    return this.m_summary;
  }
  @Input()
  public pageSize: number;

  @Output() itemRemove = new EventEmitter<EmployeeMessage>();

  public set messages(value: EmployeeMessage[]) {
    this.m_messages = value;
    this.refreshGrid();
  }

  public get messages(): EmployeeMessage[] {
    return this.m_messages;
  }

  public get requireMinHeight(): boolean {
    return this.m_messages && this.m_messages.length < 2;
  }
  
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  public orgLevelSubscription: Subscription;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
    
  @unsubscribe()
  private userSubscription: Subscription;
  
  @unsubscribe()
  private smsAccessSubscription: Subscription;
  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  private m_messages: EmployeeMessage[];
  private m_summary: OpenShiftDetails;
  public addAgency:boolean;
  public currentOrgLevel: OrgLevel;
  public records:EmployeeMessage[]=[];
  public alias:string;

  constructor(private employeeMessagesApiService: EmployeeMessagesApiService,
    private openShiftManagementManagementService: OpenShiftManagementManagementService,
    private appSettingsManageService: AppSettingsManageService,
    private openShiftAPi: OpenShiftManagementApiService,
    private notificationsService: NotificationsService) {
    this.gridState = new KendoGridStateHelper();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];

    this.pageSize = 100;

    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: any): void => {
      this.refreshGrid();
    });
  }
  public ngOnInit(): void {
    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        this.alias = session.alias;
      }
    });
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
    });    
    this.getSettings();

    if(this.summary.hasPartialShift || this.summary.parentShiftId!==-1) {      //if parent shift is configured to have artial shifts, refresh
      this.hasPartialShift = true;
    }
  }
  public async getSettings():Promise<void>{
    await this.appSettingsManageService.getAppServerConfig()
    .then((Config)=> {
      if(Config.duagrid && Config.partnerintegrations){
        this.addAgency=true;
      }
      else{
        this.addAgency=false;
      }
    });
  }
  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onApproveClicked(message: EmployeeMessage): any {
    if(message.partnerShiftId > 0){
      this.openShiftManagementManagementService.changeLoadingState(true);
      this.employeeMessagesApiService.ApprovePartnerShift(message.id, message.partnerId, this.currentOrgLevel.id).then((result: any) => {
        message.state = EmployeeMessageState.scheduled;
        message.stateName = this.parseEmployeState(EmployeeMessageState.scheduled);
        this.summary.openShiftCount = this.summary.openShiftCount - 1;
        this.openShiftManagementManagementService.onPartnerShiftCountChanged({ dateOn: this.summary.dateOn, partnerShiftCountDiff: 1});
        this.openShiftManagementManagementService.changeLoadingState(false);
        if(result.data && result.data.hasErrors){
          this.notificationsService.error('Error', result.data.errors && result.data.errors.length>0 ? result.data.errors[0] : 'Something went wrong, please try later');
        }
      }).catch((error: any) => {
        this.openShiftManagementManagementService.changeLoadingState(false);
      });
    }
    else{     
      this.openShiftManagementManagementService.changeLoadingState(true);
      this.employeeMessagesApiService.approveShift(message.id, this.summary.dateOn, message.senderId, this.summary.shift.id, this.summary.unit.id, this.summary.position.id)
      .then((result: any) => {
        this.removeMeassage(message);
        message.state = EmployeeMessageState.scheduled;
        message.stateName = this.parseEmployeState(EmployeeMessageState.scheduled);
        this.summary.openShiftCount = this.summary.openShiftCount - 1;
        this.summary.calculatedOpenShiftCount = this.summary.calculatedOpenShiftCount - 1;
        this.openShiftManagementManagementService.onMessageCountChanged({ dateOn: this.summary.dateOn, messageCountDiff: 1 });
        this.openShiftManagementManagementService.onOpenShiftCountChanged({ dateOn: this.summary.dateOn, shiftCountDiff: 1 });
        //if partial shift approval, reload left and right panes
        if(this.hasPartialShift) {
          this.openShiftManagementManagementService.onOpenShiftCountChangedByPartialShift({ dateOn: this.summary.dateOn, shiftCountDiff: 1 });
        }
        this.openShiftManagementManagementService.changeLoadingState(false);
      })
      .catch((error: any) => {
        this.openShiftManagementManagementService.changeLoadingState(false);
      });
  }
}

  public onDenyClicked(message: EmployeeMessage): any {
    if(message.partnerShiftId > 0) {  
      this.openShiftManagementManagementService.changeLoadingState(true);
      this.employeeMessagesApiService.denyPartnerShift(message.id, message.partnerId, this.currentOrgLevel.id).then((result: any) => {
        message.state = EmployeeMessageState.closed;
        message.stateName = this.parseEmployeState(EmployeeMessageState.closed);
        this.openShiftManagementManagementService.onPartnerShiftCountChanged({ dateOn: this.summary.dateOn, partnerShiftCountDiff: 1});
        this.openShiftManagementManagementService.changeLoadingState(false);
      })
      .catch((error: any) => {
        this.openShiftManagementManagementService.changeLoadingState(false);
      });
    }
    else  {        
      this.openShiftManagementManagementService.changeLoadingState(true);
      this.employeeMessagesApiService.denyShift(message.id, this.summary.dateOn, message.senderId, this.summary.shift.id, this.summary.unit.id, this.summary.position.id).then((result: any) => {
        this.removeMeassage(message);
        message.state = EmployeeMessageState.closed;
        message.stateName = this.parseEmployeState(EmployeeMessageState.closed);
        this.openShiftManagementManagementService.onMessageCountChanged({ dateOn: this.summary.dateOn, messageCountDiff: 1 });
        this.openShiftManagementManagementService.changeLoadingState(false);
      })
      .catch((error: any) => {
        this.openShiftManagementManagementService.changeLoadingState(false);
      });
     }
  }

  private removeMeassage(message: EmployeeMessage){
    this.itemRemove.emit(message);
    this.messages = this.messages.filter(x=> x!== message);
    this.refreshGrid();
  }

  private refreshGrid(): void {
     if (!this.messages) {
      this.gridState.view = null;
      return;
    }
    this.gridState.state.take = this.pageSize;
    this.gridState.view = process(this.messages, this.gridState.state);
  }

  private parseEmployeState(state: EmployeeMessageState): string {

    if (state === EmployeeMessageState.new) {
        return 'New';
    }
    if (state === EmployeeMessageState.read) {
      return 'Read';
    }
    if (state === EmployeeMessageState.closed) {
      return 'Closed';
    }
    if (state === EmployeeMessageState.reopened) {
      return 'Reopened';
    }
    if (state === EmployeeMessageState.requested) {
      return 'Requested';
    }
    if (state === EmployeeMessageState.scheduled) {
      return 'Scheduled';
    }
    if (state === EmployeeMessageState.ignored) {
      return 'Ignored';
    }
    return null;
  }
}