import * as _ from 'lodash';
import { StateManagementService } from './../../../../../common/services/state-management/state-management.service';
import { ColumnManagementService } from './../../../../../common/services/column-settings/column-management.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PmEmployeeRosterManagementService } from '../../../services/pm-employee-roster-management.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribeAll } from '../../../../../core/decorators';
import { KendoGridColumnsGroupDirective } from '../../../../../common';

@Component({
  selector: 'slx-pm-employee-roster',
  templateUrl: './pm-employee-roster.component.html',
  styleUrls: ['./pm-employee-roster.component.scss'],
  providers: [
    PmEmployeeRosterManagementService, ColumnManagementService, StateManagementService
  ]
})
export class PmEmployeeRosterComponent implements OnInit, OnDestroy {

  public isLoading: boolean;

  private employeeId: number;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription>;

  constructor(
    private manService: PmEmployeeRosterManagementService,
    private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.subscriptions = {};
  }

  public ngOnInit(): void {

    this.subscriptions.loading = this.manService.subscribeToLoading((isLoading) => {
      this.isLoading = isLoading;

    });

    this.subscriptions.routeSubscription = this.activatedRoute.params.subscribe((params: Params) => {
      const employeeId = parseInt(params.employeeId, 10);
      if (_.isNumber(employeeId)) {
        this.manService.setEmployeeId(employeeId);
      }
    });

    this.manService.init();
  }

  public ngOnDestroy(): void { }



}
