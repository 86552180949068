import { Component, Input, OnInit, Provider, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../core/decorators/index';
import { DialogOptions2, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { TimecardPredefinedComment } from '../../../../organization/models/index';
import { Lookup } from '../../../../organization/models/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { TimecardAddCommentsReq, ITimecardAddCommentsResult } from '../../../models/daily-timecard/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-add-comments-dialog',
  templateUrl: 'add-comments-dialog.component.html',
  styleUrls: ['add-comments-dialog.component.scss']
})
export class TimecardAddCommentsDialogComponent implements IDialog, OnDestroy {


  public options: DialogOptions2;
  public dialogResult: boolean;
  public appConfig: IApplicationConfig;
  public selectedComment: TimecardPredefinedComment;
  public timecardPredefinedComments: Lookup<TimecardPredefinedComment>
  public selectedMultipleComments = [];
  public isLoading: boolean = true;
  private addedComment: TimecardPredefinedComment;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private modalService: ModalService;
  public gridState: KendoGridStateHelper<TimecardPredefinedComment>;
  public static dialogMode: string;

  public static openDialog(header: string, req: TimecardAddCommentsReq, modalService: ModalService, mode: string): Promise<ITimecardAddCommentsResult> {

    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.height = 500;
    dialogOptions.width = 500;
    dialogOptions.fullHeightOnMobile = true;
    this.dialogMode = mode;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: TimecardAddCommentsReq,
        useValue: req
      }
    ];

    return new Promise((resolve) => {
      let dialog: TimecardAddCommentsDialogComponent = modalService.globalAnchor
        .openDialog2(TimecardAddCommentsDialogComponent, header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
          resolve({ comment: dialog.selectedComment ? dialog.selectedComment.description : '', isApply: result });
        });
    });
  }


  constructor(options: DialogOptions2,
    modalService: ModalService,
    public req: TimecardAddCommentsReq
  ) {
    
    this.modalService = modalService;
    this.options = options;
    this.timecardPredefinedComments = req.timecardPredefinedComments;
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<TimecardPredefinedComment>();
    this.selectedMultipleComments = [];
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.subscriptions.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: TimecardPredefinedComment[]): void => {
      this.selectedComment = _.first(records);
    });
    this.refreshGrid();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private refreshGrid(): void {

    if (!this.timecardPredefinedComments) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.timecardPredefinedComments.items, this.gridState.state);
    this.isLoading = false;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
  public onOk(): void {
    if (this.selectedMultipleComments.length > 0) {
      var description = this.selectedMultipleComments.map(function (elem) {
        if (elem) {
          return elem.description;
        }
      }).join("\n\n");
      
      this.selectedComment = new TimecardPredefinedComment();
      this.selectedComment.description = description;
    }
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  selectionChange(event) {
    if (event.selectedRows.length > 0) {
      this.addedComment = event.selectedRows[0].dataItem;
      this.selectedMultipleComments.push(this.addedComment);
    }

    if (event.deselectedRows.length > 0) {
      this.addedComment = event.deselectedRows[0].dataItem;
      this.selectedMultipleComments = this.selectedMultipleComments.filter((item) => item.id !== this.addedComment.id);
    }
  }
  get commentSelection() {
    return TimecardAddCommentsDialogComponent.dialogMode;
  }
}
