import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApplicationInsights, SeverityLevel, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '../../components/angular-plugin/angular-plugin.component';
import { appConfig } from '../../../app.config';
import { IErrorScopeData } from '../../models/index';
import { User, AppVersionInfo } from '../../../state-model/models/index';
import { SessionService, ErrorHandlingService, IErrorEvent } from '../../../core/services/index';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
import { VersionSubscribeService } from '../../../common/services/index';

export class ApplicationInsightsProvider {
  public static getApplicationInsights(): any {
    return ApplicationInsights;
  }

  public static getAngularPlugin(): any {
    return AngularPlugin;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  private appInsights: ApplicationInsights;
  private scopeDataInitialized: boolean;
  private scopeData: IErrorScopeData;
  constructor(
    private applicationStateBusService: ApplicationStateBusService,
    private sessionService: SessionService,
    private versionSubscribeService: VersionSubscribeService,
    private errorHandlingService: ErrorHandlingService,
    private router: Router
  ) {
    this.initialize();
  }

  public initialize(): void {
    if (!appConfig.appInsightInstrumentationKey) {
      return;
    }
    const angularPlugin = new (ApplicationInsightsProvider.getAngularPlugin())();
    this.appInsights = new (ApplicationInsightsProvider.getApplicationInsights())({
      config: {
        instrumentationKey: appConfig.appInsightInstrumentationKey,
        isCookieUseDisabled: true,
        enableAutoRouteTracking: false,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        distributedTracingMode: DistributedTracingModes.AI,
        extensions: [angularPlugin],
        extensionConfig: {
            [angularPlugin.identifier]: { router: this.router }
        },
        correlationHeaderExcludedDomains: ['ekr.zdassets.com']
      }
    });
    this.appInsights.loadAppInsights();
    this.telemetryInitializer();

    this.checkAndInitilizeScope();

    this.applicationStateBusService.login$.subscribe(() => {
      this.scopeData = undefined;
      this.checkAndInitilizeScope();
    });

    this.errorHandlingService.exception$.subscribe((e: IErrorEvent) => {
      switch (e.severity) {
        case 'fault': this.trackFaultEvent(e.error);
          break;
        case 'warning': this.trackWarningEvent(e.error);
          break;
        default: this.trackErrorEvent(e.error);
          break;
      }
    });
  }

  public async checkAndInitilizeScope(): Promise<any> {
    if (!this.scopeData) {
      await this.getScopeData();
      this.appInsights.setAuthenticatedUserContext(this.scopeData.user.id.toString(), this.scopeData.alias, false);
    }
  }

  public telemetryInitializer() {
    const telemetryInitializer = (envelope: any) => {
      if (!this.scopeData) {
        return true;
      }
      if (envelope.baseType === 'PageviewData') {
        // https://github.com/microsoft/ApplicationInsights-JS/issues/726
        // issue with hash urls
        if (envelope.baseData) {
          envelope.baseData.refUri = envelope.baseData.uri;
        }
        if (envelope.ext && envelope.ext.trace) {
          envelope.ext.trace.name = envelope.baseData.uri;
        }
      }
      envelope.data.userid = this.scopeData.user.id;
      envelope.data.username = this.scopeData.user.name;
      envelope.data.alias = this.scopeData.alias;
      envelope.data.app_version = this.scopeData.version.appVersion;
      envelope.data.service_version = this.scopeData.version.serviceVersion;
      envelope.data.db_version = this.scopeData.version.dbVersion;
      return true;
    };
    this.appInsights.addTelemetryInitializer(telemetryInitializer);
  }

  public trackFaultEvent(error): void {
    if (!appConfig.appInsightInstrumentationKey) {
      return;
    }
    this.appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Critical, properties: this.createProperties() });
  }

  public trackErrorEvent(error): void {
    if (!appConfig.appInsightInstrumentationKey) {
      return;
    }
    this.appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Error, properties: this.createProperties() });
  }

  public trackWarningEvent(error): void {
    if (!appConfig.appInsightInstrumentationKey) {
      return;
    }
    this.appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Warning, properties: this.createProperties() });
  }

  public createProperties(): { [key: string]: any } {
    const properties: any = {};
    if (this.scopeData) {
      properties.userid = this.scopeData.user.id;
      properties.username = this.scopeData.user.name;
      properties.alias = this.scopeData.alias;
      properties.app_version = this.scopeData.version.appVersion;
      properties.service_version = this.scopeData.version.serviceVersion;
      properties.db_version = this.scopeData.version.dbVersion;
    }
    return properties;
  }

  public trackEvent(eventName: string): void {
    if (!appConfig.appInsightInstrumentationKey) {
      return;
    }
    this.appInsights.trackEvent({ name: eventName });
  }

  logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  private async getScopeData(): Promise<IErrorScopeData> {
    if (this.scopeData && this.scopeDataInitialized) {
      return this.scopeData;
    }
    let version;
    try {
      version = await this.versionSubscribeService.getVersion();
    } catch (e) {
      //nothing to do
    }
    if (!version) {
      version = new AppVersionInfo();
    }

    const isSessionExpired = this.sessionService.isExpired();
    if (isSessionExpired) {
      this.scopeData = {
        user: new User(0),
        version: version,
        alias: ''
      };
      return this.scopeData;
    }
    const user: User = this.sessionService.getUser();
    const alias: string = this.sessionService.getAlias();
    this.scopeData = {
      user: user,
      version: version,
      alias: alias
    };
    this.scopeDataInitialized = true;
    return this.scopeData;
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }

    return snapshot.component;
  }
}
