
export class EmpScrollEvent {
  public event: MouseEvent;
  public scrollTop: number;
  public clientHeight: number;

  constructor(event: MouseEvent) {
    this.event = event;
  }
}

export class EmpScrollSection {
  public element: HTMLElement;
  public id: string;
  public start: number;
  public end: number;
  public height: number;
  public sectionsLength: number;
  public active: boolean;
  public preactive: boolean;
  public displayed: boolean;

  constructor() {
    this.active = false;
    this.preactive = false;
    this.displayed = false;
  }

  public update(): void {
    this.active = this.displayed = this.preactive = false;
    if (this.element) {
      this.height = this.element.offsetHeight;
      this.start = this.element.offsetTop;
      this.end = this.start + this.height;
    }
  }
}
