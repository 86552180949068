import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { appConfig, IApplicationConfig } from '../../../app.config';
import { ColorUtil } from '../../../common/utils/index';

import { PayCodeGridModel } from '../../models/index';
import { PayRuleDefinition } from '../../../organization/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-pay-codes-grid',
  templateUrl: 'pay-codes-grid.component.html',
  styleUrls: ['pay-codes-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayCodesGridComponent implements OnInit, OnDestroy {
  @Input('payCodeModel')
  public set setPayCodeModel(value: PayCodeGridModel) {
    this.payCodeModel = value;
    this.refreshGrid();
  }
  @Input('ruleList')
  public set setRuleList(value: PayRuleDefinition[]) {
    this.ruleList = value;
    this.refreshGrid();
  }

  public payCodeModel: PayCodeGridModel;
  public ruleList: PayRuleDefinition[];
  public gridData: PayRuleDefinition[];
  public appConfig: IApplicationConfig;

  private changeDetector: ChangeDetectorRef;

  constructor(changeDetector: ChangeDetectorRef) {
    this.changeDetector = changeDetector;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public getColor(color: number): string {
    return ColorUtil.DecToHexString(color, true);
  }

  private refreshGrid(): void {
    const noRuleList: boolean = !_.isArray(this.ruleList);
    if (!_.isObject(this.payCodeModel) || noRuleList) {
      if (noRuleList) this.gridData = null;

      return;
    }


    this.gridData = _.cloneDeep(this.ruleList);
  }
}

