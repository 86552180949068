import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';

export class PostScheduleNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public NavigateToPostSchedule(date: Date): void {
    this.router.navigate(['master_schedule/post', moment(date).format(appConfig.linkDateFormat)], { relativeTo: this.route.pathFromRoot[2]  });
  }

  public updateRoute (date: Date): void {
    this.router.navigate (['post/'+moment (date).format(appConfig.linkDateFormat)], {relativeTo: this.route.parent});
  }

  public navigateToOpenShiftManagement(date: Date): void {
    let dateParam: string = moment(date).format(appConfig.linkDateFormat);
    this.router.navigate(['open_shift_management'], { relativeTo: this.route.pathFromRoot[2], queryParams: {date: dateParam} });
  }
}
