export class OpenPositionControlSlideOutExcel {
    public PositionGroup: string;
    public Position: string;
    public Shifts: number;
    public ShiftGroup: string;
    public Scheduled: number;
    public hoursGap : string;
    public ftEsNeeded: string;
    public ftHeadCount: number;
    public ptHeadCount: number;
}