import { ColorUtil } from './../../../../../../common/utils/color-util';
import { EmployeeSectionsAttendance } from '../../../../models/index';
import * as moment from 'moment';
var EmployeeSectionsAttendanceDayComponent = /** @class */ (function () {
    function EmployeeSectionsAttendanceDayComponent() {
    }
    Object.defineProperty(EmployeeSectionsAttendanceDayComponent.prototype, "attendanceSection", {
        get: function () {
            return this.m_attendanceSection;
        },
        set: function (value) {
            this.m_attendanceSection = value;
            this.recalculateData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendanceDayComponent.prototype, "selectedYear", {
        get: function () {
            return this.m_selectedYear;
        },
        set: function (value) {
            this.m_selectedYear = value;
            this.recalculateData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendanceDayComponent.prototype, "month", {
        get: function () {
            return this.m_month;
        },
        set: function (value) {
            this.m_month = value;
            this.recalculateData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendanceDayComponent.prototype, "day", {
        get: function () {
            return this.m_day;
        },
        set: function (value) {
            this.m_day = value;
            this.recalculateData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendanceDayComponent.prototype, "prefix", {
        get: function () {
            return this.m_prefix;
        },
        set: function (value) {
            this.m_prefix = value;
            this.recalculateData();
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAttendanceDayComponent.prototype.calcCellRenderer = function () {
        var className;
        if (this.isHoliday) {
            className = 'square';
        }
        else {
            className = 'circle';
        }
        if (this.aDay && this.aDay.getMarkedDay(this.prefix))
            className += ' work-offset';
        return className;
    };
    EmployeeSectionsAttendanceDayComponent.prototype.calcCellColor = function () {
        if (!this.aDay)
            return '';
        var style = { 'background-color': '#FFFFFF' };
        if (this.isHoliday) {
            style['background-color'] = '#CCCCCC';
        }
        var absence = this.aDay.getAbsence(this.prefix);
        if (absence) {
            style['background-color'] = '#' + this.displayInHex(absence.color);
        }
        return style;
    };
    EmployeeSectionsAttendanceDayComponent.prototype.calcCellPlannerIndicator = function () {
        if (!this.aDay)
            return '';
        var absence = this.aDay.getAbsence(this.prefix);
        if (absence && absence.code) {
            return absence.code.charAt(0);
        }
        return '';
    };
    EmployeeSectionsAttendanceDayComponent.prototype.calcWorkday = function () {
        return this.aDay && this.aDay.getMarkedDay(this.prefix);
    };
    EmployeeSectionsAttendanceDayComponent.prototype.recalculateData = function () {
        if (!this.attendanceSection || !this.selectedYear || !this.month || !this.day || !this.prefix)
            return;
        this.cellColor = { 'background-color': '#FFFFFF' };
        if (this.calcDateParams()) {
            this.isWorkday = this.calcWorkday();
            this.cellPlannerIndicator = this.calcCellPlannerIndicator();
            this.cellColor = this.calcCellColor();
            this.cellRenderer = this.calcCellRenderer();
        }
    };
    EmployeeSectionsAttendanceDayComponent.prototype.displayInHex = function (c) {
        return ColorUtil.DecToHexString(c);
    };
    EmployeeSectionsAttendanceDayComponent.prototype.calcDateParams = function () {
        var dt = this.selectedYear + '-' + this.month + '-' + this.day.toString();
        var m = moment(dt, 'YYYY-MMM-D', true);
        if (m.isValid()) {
            var dow = m.day();
            this.isHoliday = dow > 5 || dow === 0;
            this.aDay = this.getAttendanceDay(m);
            return true;
        }
        return false;
    };
    EmployeeSectionsAttendanceDayComponent.prototype.getAttendanceDay = function (date) {
        var dayOfTheYear = parseInt(date.format('DDD'), 10) - 1;
        if (this.attendanceSection.dates[dayOfTheYear] !== undefined) {
            return this.attendanceSection.dates[dayOfTheYear];
        }
        return null;
    };
    return EmployeeSectionsAttendanceDayComponent;
}());
export { EmployeeSectionsAttendanceDayComponent };
