import * as _ from 'lodash';
import { IGridStates } from './grid-states';
import { StateResetTypes } from './reset-types';


export interface IControlState {
  type?: 'date' | 'string' | 'number' | 'boolean' | 'array' | 'moment' | 'dateRange';
  value?: any;
}

export interface IControlStates {
  resetType: StateResetTypes;
  states: StringMap<IControlState>;
}

export interface IControlStateStorage {
  controls: StringMap<IControlStates>;
  grids: StringMap<IGridStates>;
}

export interface IComponentStateStorage {
  components: StringMap<IControlStateStorage>;
}

export class ControlStateKey {
  public static DEFAULT_KEY: string = 'default';
  private keys: StringMap<any>;
  constructor(keys: StringMap<any>) {
    this.keys = keys;
  }

  public createKeyString(): string {
    let obj: any = _.transform(this.keys, (result: any, value: any, key: string) => {
      result.key = result.key ? `${result.key}${key}${value}` : `${key}${value}`;
    }, {});
    return obj.key ? obj.key : ControlStateKey.DEFAULT_KEY;
  }
}

export function isIControlStates(source: any): source is IControlStates {
  return !!source.resetType;
}
