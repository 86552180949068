import * as tslib_1 from "tslib";
import { OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { BudgetApiService } from '../../../services/index';
import { BudgetDefinition } from '../../../../organization/models/index';
var BudgetHistoryDetailComponent = /** @class */ (function () {
    function BudgetHistoryDetailComponent(budgetApiService, changeDetector) {
        var _this = this;
        this.aggregates = [
            { field: 'weekendHours', aggregate: 'sum' },
            { field: 'weekdayHours', aggregate: 'sum' },
            { field: 'payRate', aggregate: 'sum' }
        ];
        this.budgetApiService = budgetApiService;
        this.changeDetector = changeDetector;
        this.state = {
            isLoading: false
        };
        this.gridState = new KendoGridStateHelper();
        var aggregates = [
            { field: 'weekendHours', aggregate: 'sum' },
            { field: 'weekdayHours', aggregate: 'sum' },
            { field: 'payRate', aggregate: 'sum' }
        ];
        this.gridState.state.group = [{ field: 'budgetedGroup.description', aggregates: aggregates }];
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.appConfig = appConfig;
    }
    Object.defineProperty(BudgetHistoryDetailComponent.prototype, "budgetDefinition", {
        set: function (def) {
            if (!def) {
                return;
            }
            this.loadBudget(def);
        },
        enumerable: true,
        configurable: true
    });
    BudgetHistoryDetailComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    BudgetHistoryDetailComponent.prototype.loadBudget = function (def) {
        var _this = this;
        if (this.currentBudget && this.currentBudget.id === def.id) {
            return;
        }
        this.state.isLoading = true;
        this.budgetApiService.getBudget(def.orgLevelId, def.id)
            .then(function (result) {
            _this.currentBudget = result;
            _this.state.isLoading = false;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        })
            .catch(function () {
            _this.state.isLoading = false;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    BudgetHistoryDetailComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.currentBudget || !this.currentBudget.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.state.group.forEach(function (group) { return group.aggregates = _this.aggregates; });
        this.gridState.view = process(this.currentBudget.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHistoryDetailComponent.prototype, "gridRefreshSubscription", void 0);
    return BudgetHistoryDetailComponent;
}());
export { BudgetHistoryDetailComponent };
