import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SignalrHub2Service } from '../../../../channel/services/signalr/signalr-hub2.service';
import { appConfig } from '../../../../app.config';
import { payrollConfig } from '../../payroll.config';
import { SessionService, ThrottlingService } from '../../../../core/services';

@Injectable()
export class PayrollExportSignalrService {
    public payrollExportedStatus$ = new Subject<any>();

  constructor(
    private signalRService: SignalrHub2Service,
    private throttlingService: ThrottlingService,
    private sessionService: SessionService
    ) {
    this.signalRService = new SignalrHub2Service(this.throttlingService, this.sessionService);
  }

  public init(){
    this.signalRService.init({ url: this.getApiRoot(), hubName: payrollConfig.api.signalR.signalRHub });
    this.payrollExportedStatus$ = this.signalRService.subscribe('PayrollExportStatusGroupName');
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version2}/${payrollConfig.api.signalR.root}/signalr`;
  }
}