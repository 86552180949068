import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { RolesWithUsersContainerDecorator } from '../../../models/index';
import { ActivatedRoute } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
var UserRolesAssignementGridComponent = /** @class */ (function () {
    function UserRolesAssignementGridComponent(activatedRoute) {
        this.activatedRoute = activatedRoute;
        this.gridState = new KendoGridStateHelper();
    }
    Object.defineProperty(UserRolesAssignementGridComponent.prototype, "rolesDecorator", {
        set: function (value) {
            this.m_rolesDecorator = value;
            this.setFilter(this.filterString);
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    UserRolesAssignementGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.refreshGrid();
        this.routeSubscription = this.activatedRoute.params
            .combineLatest(this.activatedRoute.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this.setFilter(queryParams['search']);
            _this.refreshGrid();
        });
    };
    UserRolesAssignementGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    UserRolesAssignementGridComponent.prototype.setFilter = function (filter) {
        this.filterString = filter;
        if (this.m_rolesDecorator) {
            this.m_rolesDecorator.setFilter(filter);
        }
    };
    UserRolesAssignementGridComponent.prototype.refreshGrid = function () {
        if (!this.m_rolesDecorator) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_rolesDecorator.roles, this.gridState.state);
        for (var i = 0; i < this.m_rolesDecorator.roles.length; i++) {
            this.kendoGrid.collapseRow(i);
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserRolesAssignementGridComponent.prototype, "routeSubscription", void 0);
    return UserRolesAssignementGridComponent;
}());
export { UserRolesAssignementGridComponent };
