import { Component, Input, forwardRef, OnInit, ViewChild } from '@angular/core';
import { Unit } from '../../../models/index';
import { CustomListActorBase } from '../../../components/editableList/custom-list-actor.base';
import { EditableListActionKind } from '../../../models/index';
import * as _ from 'lodash';
import { appMessages, IApplicationMessages } from '../../../../app.messages';
import { UnitsConfigurationManagementService } from '../../../services/index';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-location-unit-editor',
  templateUrl: 'location-unit-editor.component.html',
  styleUrls: ['location-unit-editor.component.scss'],
  providers: [
    {
      provide: CustomListActorBase,
      useExisting: forwardRef(() => LocationUnitEditorComponent)
    }
  ]

})

export class LocationUnitEditorComponent extends CustomListActorBase implements OnInit {

  @Input()
  public rowIndex: number;

  @Input()
  public set item(value: Unit) {
    this.origItem = value;
    this.m_editItem = new Unit();
    this.copyFields(value, this.m_editItem);
  }

  @Input()
  public prohibitedDescriptionValues: string[] = [];
  @Input()
  public prohibitedNameValues: string[] = [];

  public get item(): Unit {
    return this.origItem;
  }

  public appMessages: IApplicationMessages = appMessages;

  public get editItem(): Unit {
    return this.m_editItem;
  }
  private m_editItem: Unit;

  private origItem: Unit;
  private unitsValue: Unit[];

  @ViewChild('form', { static: true })
  private mainForm: NgForm;

  @unsubscribe()
  private mainFormSubscription: Subscription;

  constructor(private management: UnitsConfigurationManagementService) {
    super();
  }

  public ngOnInit(): void {
    this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
      if (this.mainForm.dirty) {
        this.management.markAsDirty();
      }
    });
  }

  public onSaveClick(event: MouseEvent): void {
    event.preventDefault();
    this.copyFields(this.m_editItem, this.origItem);
    if (this.mainFormSubscription) {
      this.mainFormSubscription.unsubscribe();
    }
    this.actionEmitter.emit(EditableListActionKind.COMPLETE_EDIT);
  }

  public onCancelClick(event: MouseEvent): void {
    event.preventDefault();
    if (this.mainFormSubscription) {
      this.mainFormSubscription.unsubscribe();
    }
    this.actionEmitter.emit(EditableListActionKind.CANCEL_EDIT);
  }

  private copyFields(from: Unit, to: Unit): void {
    to.name = from.name;
    to.description = from.description;
  }

}
