<form #form="ngForm" class="content-form">
<div class="toolbar-indents">
      <span class="bene-toolbar__control group-name as-flex-start mt-5" title="{{groupName}}">
        <strong>{{groupName}}</strong>
      </span>
  
      <span class="bene-toolbar__control flex-width-dt as-flex-start">
        <label class="form__label control-label">Effective Date : </label>
        <label class="form__label date-label">{{effectiveDate | amDateFormat: appConfig.dateFormat}}</label>
      </span>
    <div class="bene-toolbar__control flex-width-dt as-flex-start" *ngIf="canMapPayroll && enableBenefitDeduction">
      <label class="control-label">Deduction Start Date : </label>
      <span class="flex control-width">
        <slx-datepicker class="slx-wide" name="DedStartDate" #dedStartDateModel="ngModel"
          [minDate]="minDedDate" [maxDate]="maxDedDate" [(ngModel)]="payrollDedStartDate"
          (ngModelChange)="onChangeStartDate()">
        </slx-datepicker>
        <span *ngIf="dedStartDateModel?.errors" class="slx-error-block">
          <span *ngIf="dedStartDateModel?.errors?.date" errorMessage for="date">Incorrect Date</span>
          <span *ngIf="dedStartDateModel?.errors?.maxDate" errorMessage for="maxDate"> The value should be less than {{
            maxDedDate | amDateFormat: appConfig.dateFormat }}</span>
          <span *ngIf="dedStartDateModel?.errors?.minDate" errorMessage for="minDate">Deduction Start Date cannot be
            before the Deduction Start Date on the Deduction Master List in Payroll {{ minDedDate | amDateFormat:
            appConfig.dateFormat }}</span>
        </span>
        <span *ngIf="form.valid && isDedBeforeBeneStartDt && canMapPayroll" class="warning-message">
          Payroll Deduction Start Date is set before the Benefit Coverage Start Date.
        </span>
      </span>
    </div>
    <div class="bene-toolbar__control flex-width-dt as-flex-end" *ngIf="canMapPayroll && enableBenefitDeduction">
      <label class="control-label">Deduction End Date : </label>
      <span class="flex control-width">
        <slx-datepicker class="slx-wide" name="DedEndDate" #dedEndDateModel="ngModel" [minDate]="payrollDedStartDate"
          [maxDate]="maxDedDate" [(ngModel)]="payrollDedEndDate" (ngModelChange)="onChangeDedEndDate()">
        </slx-datepicker>
        <span *ngIf="dedEndDateModel?.errors" class="slx-error-block">
          <span *ngIf="dedEndDateModel?.errors?.date" errorMessage for="date">Incorrect Date</span>
          <span *ngIf="dedEndDateModel?.errors?.maxDate" errorMessage for="maxDate">Deduction End Date cannot be after
            the Deduction End Date on the Deduction Master List in Payroll {{ maxDedDate | amDateFormat:
            appConfig.dateFormat }}</span>
          
        </span>
        <span *ngIf="form.valid && isDedAfterBeneEndDt && canMapPayroll" class="warning-message">
          Payroll Deduction End Date is set after the Benefit Coverage End Date.
        </span>
      </span>
    </div>

    
</div>
</form>