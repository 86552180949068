import * as _ from 'lodash';
import { process, State } from '@progress/kendo-data-query';

import { NgForm, NgModel } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { InfoDialogComponent } from './../../../../../common/components/index';
import { Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { EmployeeBenefitsEditResult, BenefitEnrolledEmployeesRequest, BenefitEnrolledEmployeePreview } from '../../../models/index';
import { unsubscribeAll, mutableSelect } from '../../../../../core/decorators/index';
import { StateManagementService } from '../../../../../common/services/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { BenefitEnrolledEmployee } from '../../../models/index';
import { BenefitEmployeesApiService } from '../../../services/index';
import { KendoGridStateHelper, saveEvent, removeEvent, DialogOptions } from '../../../../../common/models/index';
import { ModalService, ModalAnchorDirective } from '../../../../../common/index';
import { subscribeToOrgLevel } from '../../../../../organization/selectors/index';
import { IScreenUtils, screenUtils } from '../../../../../common/utils/index';
import { EmployeeBenefitDropCoverageDialogComponent } from './../benefit-drop-coverage-dialog/benefit-drop-coverage-dialog.component';
import { BenefitDetailsCalcMethod, BenefitDetailsTier, BenefitDetailsLineStandart, BenefitDetailsOption, BenefitPayrollDeduction } from './../../../models/benefit-details/index';
import { DropCoverageRequest } from './../../../models/benefit-employees/benefit-employee-benefits';
import { GridComponent } from '@progress/kendo-angular-grid';
import { PagingData } from '../../../../../core/models/index';
import { CalculationCommonService } from '../../../services/benefit-details/calculation-common.service';
import { BenefitEnrollmentCommonService } from '../../../services/index';
import * as moment from 'moment';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/components/index';
import { AppServerConfig } from '../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app-settings/services/app-settings-manage.service';


@Component({
  selector: 'slx-benefit-employees-enrolled',
  templateUrl: './benefit-employees-enrolled.component.html',
  styleUrls: ['./benefit-employees-enrolled.component.scss'],
  providers: [StateManagementService, CalculationCommonService]
})
export class BenefitEmployeesEnrolledComponent implements OnInit, OnDestroy {

  @Input()
  public tierDetails: BenefitDetailsTier;

  @Input()
  public date: Date;

  @Input()
  public canEnroll: boolean;

  @Input()
  public lineId: number;

  @Input()
  public method: string;

  @Input()
  public planName: string;

  @Input()
  public multiplier: number;

  @Input()
  public maxCap: number;

  @Input()
  public lowLevelOrgId: number;

  @Input()
  public selectedProviderLine: BenefitDetailsLineStandart;

  @Input()
  public canMapPayroll: boolean;

  @Input()
  mappedDeduction: BenefitPayrollDeduction;
  
  public pagingData: PagingData;
  public pageSize: number = 50;

  @Input()
  public set skip(value: number) {
    if (value === undefined) {
      return;
    }
    this.gridState.state.skip = value;
  }

  public options: DialogOptions;
  public screenUtils: IScreenUtils;
  public isLoading: boolean;
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<BenefitEnrolledEmployee>;
  public selectedEmployees = new Map<number, BenefitEnrolledEmployee>();
  public calcMethod: BenefitDetailsCalcMethod;
  public records: BenefitEnrolledEmployee[];
  public filteredRecords: BenefitEnrolledEmployee[] = [];
  public coverageMultipllierOptions: { name: string, value: number }[];
  public editedItemCoverage: { name: string, value: number };
  public columnGroup = 'BenefitEnrolledEmployees';
  public planDates: Array<Date> = [];
  public planEffectiveStartDate: Date;
  public planEffectiveEndDate: Date;
  public dropCoverageRequest: DropCoverageRequest;
  public request: BenefitEnrolledEmployeesRequest;
  public coverage: any = 0;
  public employeeContributionAmount: any = 0;
  public employerContributionAmount: any = 0;
  public empContrError: boolean = false;
  public empContrPercentError: boolean = false;
  public hasFormulaError: boolean = false;
  public maxEmployeeContribution: number = 100;
  public benefitOptionData: BenefitDetailsOption[];
  public enableBenefitDeduction:boolean = false;

  public currencyFormat = 'c2';
  public minAmt: number = 0;
  public minFormulaAmt: number = -1;
  public maxAmt: number = 99999999.99;
  public maxEmpAmt: number = 999999999.99;
  public stepcurrency = 0.01;

  public isCheckedAll = false;
  public payrollDeductionEndDate: Date;

  @ViewChild('kendoGrid', { static: true })
  public kendoGrid: GridComponent;

  @ViewChild(ModalAnchorDirective, { static: true })
  private modalAnchor: ModalAnchorDirective;

  @ViewChild('form', { static: true })
  private form: NgForm;

  @ViewChild('coverageFormula', { static: false })
  public covFormulaOverrideControl: NgModel;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  public selectedRowIndex: number;

  public get canEdit(): boolean {
    return _.isObjectLike(this.calcMethod) && _.isObjectLike(this.tierDetails)
      && (
        this.calcMethod.isFormula && !this.tierDetails.coverageFormula.isFixedCalcType
        || !this.calcMethod.isFormula
      );
  }

  public get showEditColumn(): boolean {
    return !(this.calcMethod.isOptionsRates ||
      ((this.calcMethod.isFormulaWithOption || this.calcMethod.isFormula) && this.tierDetails.coverageFormula.isFixedCalcType))
  }

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private orgLevel: OrgLevel;

  constructor(private apiService: BenefitEmployeesApiService,
    private modalService: ModalService,
    options: DialogOptions,
    request: BenefitEnrolledEmployeesRequest,
    private calculationCommonService: CalculationCommonService,
    private commonValidationService: BenefitEnrollmentCommonService,
    private appSettingsManageService: AppSettingsManageService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitEnrolledEmployee>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = 50;
    this.appConfig = appConfig;
    this.screenUtils = screenUtils;
    this.options = options;
    this.request = request;
  }

  public ngOnInit(): void {

    this.getSettings();
    this.benefitOptionData = this.tierDetails.options;
    this.calcMethod = new BenefitDetailsCalcMethod(this.method, this.method, this.method);
    this.subscriptions.orgLevel = subscribeToOrgLevel(this.orgLevel$, () => this.orgLevel, (orgLevel: OrgLevel) => this.reload(orgLevel));

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
      .subscribe(() => {
        this.refreshGrid();
      });

    this.subscriptions.gridSaveSubscription = this.gridState.onSave$
      .subscribe((event: saveEvent<BenefitEnrolledEmployee>) => {
        this.isLoading = true;
        this.doEdit(event.dataItem, event.rowIndex);
      });

    this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$
      .subscribe((event: removeEvent<BenefitEnrolledEmployee>) => {
        this.doRemove(event.dataItem, event.rowIndex);
      });

    this.subscriptions.editSubscription = this.gridState.onEdit$
      .subscribe((event: BenefitEnrolledEmployee) => {
        this.selectedRowIndex = this.gridState.editedRowIndex;

        let coverage = _.find(this.coverageMultipllierOptions, (o => {
          return o.value === event.coverage;
        }));
        this.editedItemCoverage = coverage;
        this.validateFormulaEvaluation(event, false);
      });

    this.planEffectiveStartDate = this.request.startDate;
    this.planEffectiveEndDate = this.request.endDate;
    this.planDates = [this.planEffectiveStartDate, this.planEffectiveEndDate];
    this.payrollDeductionEndDate = this.selectedProviderLine.line.payrollDeductionEndDate;
    this.payrollDeductionEndDate = !_.isNull(this.payrollDeductionEndDate) ? this.payrollDeductionEndDate : this.planEffectiveEndDate;
    if (this.tierDetails) {
      if (this.tierDetails.options && this.tierDetails.options.length > 0) {
        if (this.tierDetails.options[0].maxEmpContribution == null) {
          this.maxEmployeeContribution = 100;
        } else {
          this.maxEmployeeContribution = this.tierDetails.options[0].maxEmpContribution;
        }
        this.maxEmpAmt =(this.maxEmpAmt && this.tierDetails.options[0].limitAmount) > 0 ? this.tierDetails.options[0].limitAmount : this.maxEmpAmt;
      }
    }
    this.loadCovergae();
    this.getCoverage();
    this.getEmployeeContribution();
    this.getEmployerContribution();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public isCheckedEmp(emp: BenefitEnrolledEmployee): boolean {
    return this.selectedEmployees.has(emp.employeeId);
  }

  public loadCovergae(): void {

    if (this.tierDetails.coverageFormula.isMultiplierCalcType) {

      let multiplier = this.tierDetails.coverageFormula.multipler;
      let maxCap = this.tierDetails.coverageFormula.maxCap;
      multiplier = _.isNumber(multiplier) ? multiplier : 500;
      maxCap = _.isNumber(maxCap) ? maxCap : 10000;
      const range = _.range(multiplier, maxCap + multiplier, multiplier);
      const opts = _.map(range, value => ({ name: _.toString(value), value }));
      this.coverageMultipllierOptions = opts;
    }
  }

  public getCoverage() {
    if (this.tierDetails && this.tierDetails.coverageFormula) {
      if (this.tierDetails.coverageFormula.isFixedCalcType) {
        this.coverage = this.tierDetails.coverageFormula.fixedAmount;
      } else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
        this.coverage = 0;
        // this.coverage = this.tierDetails.coverageFormula.expression;
      } else {
        this.coverage = null;
      }
    }
  }

  public empContrPercentageChange(dataItem?: any, rowIndex?: number) {
    if (dataItem) {
      if (this.tierDetails.options[0].maxEmpContribution) {
        if (dataItem.employeeContributionPercentage > this.tierDetails.options[0].maxEmpContribution) {
          this.empContrError = true;
          return;
        }
      }
      if(dataItem.employeeContributionAmount === null && dataItem.employeeContributionPercentage === null) 
        return this.empContrPercentError = true;
    }
    this.empContrPercentError = false;
    this.empContrError = false;
  }

  public getEmployeeContribution() {
    if (this.tierDetails && this.tierDetails.empFormula) {
      if (this.tierDetails.empFormula.isFixedCalcType) {
        this.employeeContributionAmount = this.tierDetails.empFormula.fixedAmount;
      } else if (this.tierDetails.empFormula.isFormulaCalcType) {
        this.employeeContributionAmount = 0;
        // this.employeeContributionAmount = this.tierDetails.empFormula.expression;
      } else {
        this.employeeContributionAmount = null;
      }
    }
  }

  public getEmployerContribution() {
    if (this.tierDetails && this.tierDetails.erFormula) {
      if (this.tierDetails.erFormula.isFixedCalcType) {
        this.employerContributionAmount = this.tierDetails.erFormula.fixedAmount;
      } else if (this.tierDetails.erFormula.isFormulaCalcType) {
        this.employerContributionAmount = 0;
        // this.employerContributionAmount = this.tierDetails.erFormula.expression;
      } else {
        this.employerContributionAmount = null;
      }
    }
  }

  public async doEdit(dataItem: BenefitEnrolledEmployee, rowIndex: number): Promise<any> {
    let tierId = this.tierDetails.tier.id;
    return this.apiService.updateEmployeeBenefitEnrollment(dataItem, this.lineId, tierId, this.date, this.calcMethod.id)
      .then((res: EmployeeBenefitsEditResult) => {
        if (res.isSuccess) {
          this.reload(this.orgLevel);
        } else {
          this.reload(this.orgLevel);
          InfoDialogComponent.OpenDialog('Warning', 'Something went wrong', this.modalService);
        }
      }).finally(() => {
        this.editedItemCoverage = null;
        this.isLoading = false;
      });
  }

  public async doRemove(dataItem: BenefitEnrolledEmployee, rowIndex: number): Promise<any> {
    return this.apiService.deleteEmployeeBenefitEnrollment([dataItem.id])
      .then((res: any) => {
        if (res) {
          this.records.splice(rowIndex, 1);
        } else {
          this.reload(this.orgLevel);
          InfoDialogComponent.OpenDialog('Warning', 'Something went wrong', this.modalService);
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public costCalculation(): void {
    if (this.calcMethod.isOptionsRates) {
      this.records.forEach(employee => {
        this.onChangeContribution(employee);
      });
    }
  }

  public async reload(orgLevel: OrgLevel): Promise<any> {
    this.isLoading = true;
    this.orgLevel = orgLevel;
    let tierId = this.tierDetails.tier.id;
    return this.apiService.getBenefitEnrolledEmployees(this.lowLevelOrgId, this.lineId, tierId, this.date)
      .then((records: BenefitEnrolledEmployee[]) => {
        this.selectedEmployees.clear();
        this.isCheckedAll = false;
        this.records = records;
        this.updateOptionViewData();
        this.costCalculation();
        this.refreshGrid();
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public exportToExcel(): void {
    this.kendoGrid.saveAsExcel();
  }

  public exportToPdf(): void {
    this.kendoGrid.saveAsPDF();
  }

  public onChangeContribution(dataItem: BenefitEnrolledEmployee) {
    let employeeContribution = 0;
    if (dataItem.employeeContributionAmount > 0) {
      employeeContribution = this.calculationCommonService.getConvertedEmployeeContribution(this.selectedProviderLine.costFreq, this.selectedProviderLine.empContFreq, dataItem.employeeContributionAmount);
    }
    dataItem.cost = dataItem.employerContributionAmount + employeeContribution;
    this.maxEmpAmt =(this.maxEmpAmt && dataItem.limit) > 0 ? dataItem.limit : this.maxEmpAmt;
    if (dataItem.employeeContributionAmount === null && dataItem.employeeContributionPercentage === null) {
      return this.empContrPercentError = true;
    }
    this.empContrPercentError = false;
  }

  public onSelectAll(): void {
    if (this.isCheckedAll) {
      this.selectedEmployees.clear();
      this.isCheckedAll = false;
    } else {
      _.forEach(this.filteredRecords, e => {
        this.selectedEmployees.set(e.employeeId, e);
      });
      this.isCheckedAll = true;
    }
  }

  public onSelectSingle(emp: BenefitEnrolledEmployee): void {
    if (this.selectedEmployees.has(emp.employeeId)) {
      this.selectedEmployees.delete(emp.employeeId);
      this.isCheckedAll = false;
    } else {
      this.selectedEmployees.set(emp.employeeId, emp);
      if (_.size(this.filteredRecords) === this.selectedEmployees.size) {
        this.isCheckedAll = true;
      }
    }
  }

  public dropEmployeeBenefit(): void {
    this.dropCoverageRequest = new DropCoverageRequest(
      this.planEffectiveStartDate,
      this.planEffectiveEndDate,
      this.payrollDeductionEndDate,
      this.canMapPayroll,
      this.mappedDeduction,
      Array.from(this.selectedEmployees.values())
    );
    EmployeeBenefitDropCoverageDialogComponent.openDialog(
      this.planName,
      this.modalAnchor,
      this.modalService,
      this.apiService,
      this.dropCoverageRequest,
      (hasChanges: any) => {
        this.reload(this.orgLevel);
      }
    );
  }

  public onOptionValueChange(dataItem: BenefitEnrolledEmployee) {
    if (dataItem.optionCode && dataItem.code != dataItem.optionCode.code) {
      dataItem.benefitTierOptionId = dataItem.optionCode.id;
      dataItem.code = dataItem.optionCode.code;
      dataItem.optionType = dataItem.optionCode.type;
      this.calculateFormula(dataItem);
    }
  }


  public onChangeCoverageWithMultiplier(value: { name: string, value: number }, dataItem: BenefitEnrolledEmployee, hasError: boolean): void {
    if (!hasError) {
      this.editedItemCoverage = value;
      let newValue = _.isObjectLike(value) && _.isFinite(value.value) ? value.value : null;
      if(dataItem.coverage !== newValue){
        dataItem.coverage = newValue;
        this.calculateFormula(dataItem);
      }
    }
  }

  public onCoverageFormulaValueChange(value: number, dataItem: BenefitEnrolledEmployee): void {
    if (this.covFormulaOverrideControl && this.covFormulaOverrideControl.valid) {
      if (dataItem.originalCoverage !== value) {
        dataItem.calculatedCoverage = value;
        dataItem.coverage = value;
        this.calculateFormula(dataItem);
      }
    }
  }

  public calculateFormula(dataItem: BenefitEnrolledEmployee) {
    if ((this.tierDetails.coverageFormula.isFormulaCalcType ||
      this.tierDetails.erFormula.isFormulaCalcType ||
      this.tierDetails.empFormula.isFormulaCalcType)
      && ((!this.tierDetails.coverageFormula.isFormulaCalcType && dataItem.coverage && this.form.valid) || this.tierDetails.coverageFormula.isFormulaCalcType)) {

      this.isLoading = true;
      this.apiService.getBenefitEnrolledPreviewEmployeesWithOptionCode(this.orgLevel.id, this.tierDetails.id, this.date, dataItem.coverage, dataItem.optionCode.code, [dataItem])
        .then((records: BenefitEnrolledEmployeePreview[]) => {
          const employeeMap = new Map(records.map(employee => [employee.employeeId, employee]));
          _.map([dataItem], employee => {
            const record = employeeMap.get(employee.employeeId);
            if (record) {
              dataItem.employeeContributionAmount = record.employeeContributionAmount;
              dataItem.employerContributionAmount = record.employerContributionAmount;
              dataItem.calculatedCoverage = record.coverage;
              dataItem.originalCoverage = record.coverage;
            }
          });
        }).finally(() => {
          this.isLoading = false;
          this.validateFormulaEvaluation(dataItem, true);
        });
    }
  }

  public onCoverageAnyValueChange(value: number, dataItem: BenefitEnrolledEmployee, hasError: boolean): void {
    if (!hasError) {
      this.calculateFormula(dataItem);
    }
  }

  private refreshGrid(): void {
    this.isLoading = false;
    this.isCheckedAll = false;
    if (!this.records) {
      this.gridState.view = null;
      return;
    }

    this.filteredRecords = process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data;
    this.gridState.view = process(this.records, this.gridState.state);
  }

  public onCancel(): void {
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }
  public validateFormulaEvaluation(dataItem: BenefitEnrolledEmployee, showWarning: boolean): void {

    let hasFormula = (this.tierDetails.coverageFormula.isFormulaCalcType ||
      this.tierDetails.erFormula.isFormulaCalcType ||
      this.tierDetails.empFormula.isFormulaCalcType);

    if (hasFormula) {
      let errorMessage = this.commonValidationService.validateFormulaResult(
        dataItem.employeeContributionAmount,
        dataItem.employerContributionAmount,
        dataItem.calculatedCoverage
      );
      this.hasFormulaError = (errorMessage && errorMessage != '');
      if (this.hasFormulaError && showWarning) {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialog2Component.openDialog(
          'Warning',
          errorMessage,
          this.modalService,
          (result: boolean) => { },
          options);
      }
    }
  }

  public updateOptionViewData(): void {
    this.records.map((each: BenefitEnrolledEmployee) => {
      each.optionViewText = this.calcMethod.isOptionsRates ? each.optionRateCode : each.optionType;
    });
  }

  public isEmpContribSelected(entry: BenefitEnrolledEmployee): boolean {
    return !!_.get(entry, 'employeeContributionPercentage', null) || (entry.employeeContributionPercentage === 0);
  }

  public isEmpPercentSelected(entry: BenefitEnrolledEmployee): boolean {
    return !!_.get(entry, 'employeeContributionAmount', null) || (entry.employeeContributionAmount === 0);
  }
  private async getSettings(): Promise<void> {
    const config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.enableBenefitDeduction = config.isBenefitDeductionEnabled;
  }
}
