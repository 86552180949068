import { Provider } from '@angular/core';
import { BenefitListApiService } from './benefit-list-api.service';
import { BenefitListMapService } from './benefit-list-map.service';
import { BenefitListManagementService } from './benefit-list-management.service';
import { BenefitConsoleCommonService } from './benefit-console-common.service';
import { BenefitConsoleDetailsCommonService } from './benefit-console-details-common.service';

export * from './benefit-list-map.service';
export * from './benefit-list-api.service';
export * from './benefit-list-management.service';
export * from './benefit-console-common.service';
export * from './benefit-console-details-common.service';
export * from './benefit-payroll-mapping-management.service';

export const benefitListservices: Provider[] = [
  BenefitListApiService,
  BenefitListMapService,
  BenefitListManagementService,
  BenefitConsoleCommonService,
  BenefitConsoleDetailsCommonService
];
