<slx-spinner [show]="state.isLoading">
  <div>
    <kendo-grid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" [sortable]="{ mode: 'single' }"
      [sort]="gridState.state.sort" [filter]="gridState.state.filter" [groupable]="false" [group]="gridState.state.group"
      [filterable]="true" [filter]="gridState.state.filter" scrollable="none" selectable="true" (selectionChange)="gridState.selectionChange($event)"
      [pageable]="false">
      <kendo-grid-column title="Org Level Name" field="orgLevel.name" width="200">
        <ng-template kendoGridHeaderTemplate>
          Org Level Name
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.orgLevel?.name}}
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Org Level Type" field="orgLevel.type" width="150">
        <ng-template kendoGridHeaderTemplate>
          Org Level Type
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.orgLevel?.type}}
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Parent Level" field="parentName" width="150">
        <ng-template kendoGridHeaderTemplate>
          Parent Level
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.parentName}}
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</slx-spinner>
