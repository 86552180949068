<div>
  <div class="group-header">
    <span class="app__logo">
      <i class="fa {{reportGroup.icon}}" aria-hidden="true"></i>
    </span>
    <div class="app__content">
      <span class="app__url">{{ reportGroup.name }}</span>
    </div>
  </div>
  <div class="report-list">
    <span class="report-list-title">Report Name</span>
    <slx-report-list [reports]="reportGroup.reports"></slx-report-list>
  </div>
</div>
