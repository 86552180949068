import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { ReportParameter } from '../../../models/index';
import { ILookupEntity, LookupDefinition, ILookupRequest, Lookup } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';

@Component({
  selector: 'slx-radio-parameter',
  templateUrl: './radio-parameter.component.html',
  styleUrls: ['./radio-parameter.component.scss']
})

export class RadioParameterComponent implements OnInit {
  public options: any[];
  @Input()
  public set parameter(value: ReportParameter) {
    this.m_parameter = value;
    this.processParameter(this.m_parameter);
  }

  public get parameter(): ReportParameter {
    return this.m_parameter;
  }

  public get hasDefaultValue(): boolean {
    return _.size(this.parameter.defaultValue) > 0
  }

  public get lookupName(): LookupDefinition {
    if (this.parameter && this.parameter.dataType)
      return this.parameter.dataType.lookupName;

    return undefined;
  }

  private m_parameter: ReportParameter;
  constructor(private lookupService: LookupService) {
  }
  public ngOnInit(): void {
    let lookupPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: this.lookupName
    });

    lookupPromise.then((lookup: Lookup) => {
      this.options = lookup.items;
    });

  }
  private processParameter(parameter: ReportParameter): void {
    if (this.hasDefaultValue) {
      parameter.value = parameter.defaultValue;
    }
    else {
      parameter.value = '0';
    }
  }
}