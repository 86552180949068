import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { UserActivityService } from '../user-activity/user-activity.service';
import { Assert } from '../../../framework/index';

import { unsubscribeInService } from '../../decorators/index';
import { IDestroyService } from '../../../core/models/index';
import { TokenValidityService } from '../token-validity/token-validity.service';

export class PollingPeriodService implements IDestroyService {
  public onHeartbeat: Subject<any>;

  @unsubscribeInService()
  private intervalSubscription: Subscription;

  @unsubscribeInService()
  private onInactivityTimeoutSubscription: Subscription;

  @unsubscribeInService()
  private onInactivityResetedSubscription: Subscription;

  private period: number;

  private state: serviceState = serviceState.initial;

  constructor(private userActivityService: UserActivityService, private tokenValidityService?: TokenValidityService) {
    this.onInactivityTimeoutSubscription = this.userActivityService.onInactivityTimedOut.subscribe(() => this.subscribeToInactivityTimedOut());
    this.onInactivityResetedSubscription = this.userActivityService.onIncativityReseted.subscribe(() => this.subscribeToIncativityReseted());

    if (this.tokenValidityService) {
      this.tokenValidityService.onTokenRenewed.subscribe(() => this.subscribeToTokenRenewed());
      this.tokenValidityService.onTokenExpired.subscribe(() => this.subscribeToTokenExpired());
    }

    this.onHeartbeat = new Subject();
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public listen(each: number): void {
    try {
      Assert.isTrue(!!each && each > 0, `You cant listen so! Set 'each' parameter greater than 0.`);

      this.period = each;

      if (this.state === serviceState.started && this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
      }


      this.intervalSubscription = Observable.interval(each)
        .subscribe(() => {
          if (this.state === serviceState.started) {
            this.makeBeat();
          }
        });

      this.state = serviceState.started;

    } catch (error) {
      this.state = serviceState.failed;
      throw error;
    }
  }

  public stop(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.state = serviceState.stoped;
  }

  private makeBeat(): void {
    this.onHeartbeat.next(1);
  }

  private subscribeToInactivityTimedOut(): void {
    if (this.state === serviceState.started) {
      this.state = serviceState.freezed;
    }
  }

  private subscribeToIncativityReseted(): void {
    if (this.state === serviceState.freezed) {
      this.state = serviceState.started;
    }
  }

  private subscribeToTokenRenewed(): void {
    if (!this.userActivityService.isActivityTimeoutReached) {
      if (this.state === serviceState.freezed) {
        this.state = serviceState.started;
      }
    }
  }

  private subscribeToTokenExpired(): void {
    if (this.state === serviceState.started) {
      this.state = serviceState.freezed;
    }
  }
}

enum serviceState {
  initial = 0,
  started = 1,
  freezed = 3,
  stoped = 2,
  failed = -1
}
