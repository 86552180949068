/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./staffing-predictor-rating-badge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../star-rating/star-rating.component.ngfactory";
import * as i3 from "../star-rating/star-rating.component";
import * as i4 from "@angular/common";
import * as i5 from "./staffing-predictor-rating-badge.component";
import * as i6 from "../../services/staffing-predictor-management.service";
var styles_StaffingPredictorRatingBadgeComponent = [i0.styles];
var RenderType_StaffingPredictorRatingBadgeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StaffingPredictorRatingBadgeComponent, data: {} });
export { RenderType_StaffingPredictorRatingBadgeComponent as RenderType_StaffingPredictorRatingBadgeComponent };
function View_StaffingPredictorRatingBadgeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "slx-button slx-with-icon show-details-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowDetails() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Show Details "]))], null, null); }
export function View_StaffingPredictorRatingBadgeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "badge-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "container-child badge-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your Rating"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "container-child badge-note"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This value is approximation based on a combination of current and historical staffing hours"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-star-rating", [["class", "container-child"]], null, null, null, i2.View_StarRatingComponent_0, i2.RenderType_StarRatingComponent)), i1.ɵdid(6, 114688, null, 0, i3.StarRatingComponent, [i1.ChangeDetectorRef], { filledItemClassName: [0, "filledItemClassName"], emptyItemClassName: [1, "emptyItemClassName"], readonly: [2, "readonly"], rating: [3, "rating"], maxValue: [4, "maxValue"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "container-child badge-bottom-bar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffingPredictorRatingBadgeComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "full-yellow-big-star"; var currVal_1 = "far empty-grey-big-star"; var currVal_2 = true; var currVal_3 = _co.model.rating.value; var currVal_4 = _co.model.rating.maxValue; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.showDetails; _ck(_v, 9, 0, currVal_5); }, null); }
export function View_StaffingPredictorRatingBadgeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-staffing-predictor-rating-badge", [], null, null, null, View_StaffingPredictorRatingBadgeComponent_0, RenderType_StaffingPredictorRatingBadgeComponent)), i1.ɵdid(1, 49152, null, 0, i5.StaffingPredictorRatingBadgeComponent, [i6.StaffingPredictorManagementService], null, null)], null, null); }
var StaffingPredictorRatingBadgeComponentNgFactory = i1.ɵccf("slx-staffing-predictor-rating-badge", i5.StaffingPredictorRatingBadgeComponent, View_StaffingPredictorRatingBadgeComponent_Host_0, { model: "model", showDetails: "showDetails" }, {}, []);
export { StaffingPredictorRatingBadgeComponentNgFactory as StaffingPredictorRatingBadgeComponentNgFactory };
