<slx-spinner [show]="isLoading">
  <kendo-grid #kendoGrid scrollable="'scrollable'" [data]="gridState?.view" [sortable]="true"
    class='slx-benefit-mgmt-attachment-grid' [sort]="gridState.state.sort" [skip]="gridState.state.skip"
    [pageable]="true" [pageSize]="pageSize" (pageChange)="gridState.pageChange($event)">
    <ng-template kendoGridToolbarTemplate position="top">
      <div class="col-xs-12">
        <div class="row">
          <span>Benefits Attachments:</span>
          <button *ngIf="isEditable" class="theme-iconed-accent-button pull-right" slxAttachDocumentsDialog (attachDocument)="onAddFile($event)">
            <span class="icon-button-content">
              <span>Add New</span>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </span>
          </button>
        </div>
      </div>
    </ng-template>
    <kendo-grid-command-column width="80" [locked]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="flex">
          <button *ngIf="isEditable" type="button" kendoGridRemoveCommand class="slx-button slx-only-icon slx-toolbar slx-red-white-text" (click)="onClickDelete(dataItem)" >
          <i class="fas fa-trash-alt" title="Delete File" aria-hidden="true"></i>
          </button>
          
        </div>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-column field="name" title="Name" width="200">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Name</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.name}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="category" title="Category" width="200">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Category</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.category}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="fileName" title="File" width="200">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>File</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="blue-link" (click)="onDocumentFileClick(dataItem)">{{dataItem.fileName}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="addedOn" title="Added On" width="200">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Added On</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.addedOn | amDateFormat: appConfig.dateTimeFormatUS  }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="addedBy" title="Added By" width="200">
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>Added By</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{dataItem.addedBy}}</span>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>
</slx-spinner>
