import * as tslib_1 from "tslib";
import { LookupEntity } from './lookup-entity';
var Employee = /** @class */ (function (_super) {
    tslib_1.__extends(Employee, _super);
    function Employee() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Employee.prototype, "positionName", {
        get: function () {
            return this.position ? this.position.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Employee.prototype, "departmentName", {
        get: function () {
            return this.department ? this.department.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Employee.prototype, "organizationName", {
        get: function () {
            return this.organization ? this.organization.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Employee.prototype, "empStatus", {
        get: function () {
            return this.terminationDate ? 'Terminated' : 'Active';
        },
        enumerable: true,
        configurable: true
    });
    return Employee;
}(LookupEntity));
export { Employee };
