/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./daily-unit-router.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../daily-unit/daily-unit.component.ngfactory";
import * as i3 from "../../../common/services/state-management/state-management.service";
import * as i4 from "../../../common/services/component-state/component-state-storage.service";
import * as i5 from "../../../common/services/column-settings/column-settings-storage.service";
import * as i6 from "../daily-unit/daily-unit.component";
import * as i7 from "../../services/schedule/schedule-api.service";
import * as i8 from "../../services/detail-screen/detail-screen-api.service";
import * as i9 from "../../services/detail-screen/detail-screen-map.service";
import * as i10 from "../../../common/services/modal/modal.service";
import * as i11 from "@angular/router";
import * as i12 from "../../services/shift-replacement/shift-replacement-api.service";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../organization/services/schedule/schedule-cycle-helper.service";
import * as i15 from "../../../organization/services/lookup/lookup.service";
import * as i16 from "../../../organization/services/org-level/org-level-watch.service";
import * as i17 from "../daily-unit-grid/daily-unit-grid.component.ngfactory";
import * as i18 from "../daily-unit-grid/daily-unit-grid.component";
import * as i19 from "../../services/schedule-entry/schedule-entry-api.service";
import * as i20 from "../../services/shift-replacement/shift-replacement-management.service";
import * as i21 from "../../services/schedule/shift-request.service";
import * as i22 from "../../services/schedule-entry/schedule-entry-management.service";
import * as i23 from "../../../configuration/services/agencies/agencies-api.service";
import * as i24 from "../../../app-settings/services/app-settings-manage.service";
import * as i25 from "../../../configuration/services/integrations/partner-config-management.service";
import * as i26 from "../../../configuration/services/roles/roles-api.service";
import * as i27 from "@angular/common";
import * as i28 from "./daily-unit-router.component";
var styles_DailyUnitRouterComponent = [i0.styles];
var RenderType_DailyUnitRouterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DailyUnitRouterComponent, data: {} });
export { RenderType_DailyUnitRouterComponent as RenderType_DailyUnitRouterComponent };
function View_DailyUnitRouterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "slx-daily-unit", [], null, null, null, i2.View_DailyUnitComponent_0, i2.RenderType_DailyUnitComponent)), i1.ɵprd(512, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵdid(3, 245760, null, 0, i6.DailyUnitComponent, [i7.ScheduleApiService, i8.DetailScreenService, i9.DetailScreenMapService, i10.ModalService, i11.ActivatedRoute, i11.Router, i12.ShiftReplacementApiService, i13.DomSanitizer, i1.ChangeDetectorRef, i14.ScheduleCycleHelperService, i15.LookupService, i3.StateManagementService, i4.ComponentStateStorageService, i16.OrgLevelWatchService, i1.ElementRef], { dateOn: [0, "dateOn"], shiftGroup: [1, "shiftGroup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.m_dateOn; var currVal_1 = _co.shiftGroup; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_DailyUnitRouterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "slx-daily-unit-grid", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).getScreenSize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_DailyUnitGridComponent_0, i17.RenderType_DailyUnitGridComponent)), i1.ɵprd(512, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵdid(3, 245760, null, 0, i18.DailyUnitGridComponent, [i19.ScheduleEntryApiService, i7.ScheduleApiService, i8.DetailScreenService, i10.ModalService, i11.ActivatedRoute, i11.Router, i20.ShiftReplacementManagementService, i12.ShiftReplacementApiService, i14.ScheduleCycleHelperService, i15.LookupService, i3.StateManagementService, i4.ComponentStateStorageService, i16.OrgLevelWatchService, i21.ShiftRequestService, i22.ScheduleEntryManagementService, i23.AgenciesApiService, i24.AppSettingsManageService, i25.PartnerConfigManagementService, i26.RolesApiService], { dateOn: [0, "dateOn"], shiftGroup: [1, "shiftGroup"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.m_dateOn; var currVal_1 = _co.shiftGroup; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_DailyUnitRouterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DailyUnitRouterComponent_2)), i1.ɵdid(2, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DailyUnitRouterComponent_3)), i1.ɵdid(4, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isGridView; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isGridView; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DailyUnitRouterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DailyUnitRouterComponent_1)), i1.ɵdid(1, 16384, null, 0, i27.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showGridView(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DailyUnitRouterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-daily-unit-router", [], null, null, null, View_DailyUnitRouterComponent_0, RenderType_DailyUnitRouterComponent)), i1.ɵprd(512, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵdid(2, 245760, null, 0, i28.DailyUnitRouterComponent, [i3.StateManagementService, i24.AppSettingsManageService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var DailyUnitRouterComponentNgFactory = i1.ɵccf("slx-daily-unit-router", i28.DailyUnitRouterComponent, View_DailyUnitRouterComponent_Host_0, { dateOn: "dateOn", shiftGroup: "shiftGroup" }, {}, []);
export { DailyUnitRouterComponentNgFactory as DailyUnitRouterComponentNgFactory };
