var BenefitDependentRelation = /** @class */ (function () {
    function BenefitDependentRelation() {
    }
    return BenefitDependentRelation;
}());
export { BenefitDependentRelation };
var BenefitEmployeeDependent = /** @class */ (function () {
    function BenefitEmployeeDependent() {
    }
    Object.defineProperty(BenefitEmployeeDependent.prototype, "relationText", {
        get: function () {
            return this.relation && this.relation.name || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeeDependent.prototype, "fullName", {
        get: function () {
            return this.lastName + ", " + this.firstName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeeDependent.prototype, "genderText", {
        get: function () {
            return this.gender && this.gender.name || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeeDependent.prototype, "stateText", {
        get: function () {
            return this.state && this.state.name || '';
        },
        enumerable: true,
        configurable: true
    });
    return BenefitEmployeeDependent;
}());
export { BenefitEmployeeDependent };
