import { Injectable } from '@angular/core';
import { AcaExportMapService } from '../aca-export/aca-export-map.service';
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { exportDataConfig } from '../../export-data.config';
import * as _ from 'lodash';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { AcaExportExecutionItem, IAcaExportExecutionItem } from '../../models/aca-export/aca-export-execution';
import { IAcaExportParameter } from '../../models/aca-export/aca-export-parameter';
import { AcaExportExecutionStatus, IAcaExportExecutionStatus } from '../../models/aca-export/aca-export-execution-status';
import { appConfig } from '../../../../app.config';
import { AcaExportConfiguration } from '../../models/aca-export/aca-export-configuration';
import { AcaExportCorrected1095C, IAcaExportCorrected1095C } from '../../models/aca-export/aca-export-corrected-1095c';
import { AcaExportReplace1095C, IAcaExportReplace1095C } from '../../models/aca-export/aca-export-replace-1095c';
import { AcaExportSupplemental1095C, IAcaExportSupplemental1095C } from '../../models/aca-export/aca-export-supplemental-1095c';
import { AcaExportCorrection1094CCountContainer, IAcaExportCorrection1094CCountContainer } from '../../models/aca-export/aca-export-correction-1094c-counts';
import { AcaExportCorrectionParameter } from '../../models/aca-export/aca-export-correction-parameter';

@Injectable()
export class AcaExportApiService {
  constructor(
    private mapService: AcaExportMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService
  ) { }

  public async getAcaExportConfigurationList(): Promise<AcaExportConfiguration[]> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.latest}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<IAcaExportExecutionItem[], Meta>(request)
      .then((response: ResponseBody<IAcaExportExecutionItem[], Meta>) => this.mapService.mapToExportDataConfigurationList(<any>response));
  }

  public async getAcaExportHistory(configurationId: number): Promise<AcaExportExecutionItem[]> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.exportDataHistory}/${configurationId}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<IAcaExportExecutionItem[], Meta>(request)
      .then((response: ResponseBody<IAcaExportExecutionItem[], Meta>) => this.mapService.mapToAcaExportHistoryList(<any>response));
  }

  public async getAcaExportStatuses(ids: string[]): Promise<AcaExportExecutionStatus[]> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.exportDataStatuses}`;
    const request = this.urlParamsService.createPostRequest(url, { executionIds: ids });

    return this.apiUtilService.request<IAcaExportExecutionStatus[], Meta>(request)
      .then((response: ResponseBody<IAcaExportExecutionStatus[], Meta>) => this.mapService.mapToAcaExportStatusList(<any>response));

  }

  public async generateAcaExport(req: IAcaExportParameter): Promise<AcaExportExecutionItem> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.generate}`;
    const request = this.urlParamsService.createPostRequest(url, req);

    return this.apiUtilService.request<IAcaExportExecutionItem, Meta>(request)
      .then((response: ResponseBody<IAcaExportExecutionItem, Meta>) => this.mapService.mapToAcaExportHistory(<any>response));
  }

  public async downloadAcaExport(type: number, executionId: string): Promise<FileBlobResponse> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.download}/${type}/${executionId}`;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public async cancelAcaExport(type: number, executionId: string): Promise<AcaExportExecutionStatus> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.cancel}/${type}/${executionId}`;
    const request = this.urlParamsService.createDeleteRequest(url);
    return this.apiUtilService.request<IAcaExportExecutionStatus, Meta>(request)
      .then((response: ResponseBody<IAcaExportExecutionStatus, Meta>) => this.mapService.mapToAcaExportStatus(<any>response));
  }

  public async getAcaCorrected1095CData(executionId: string): Promise<AcaExportCorrected1095C[]> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.correction.root}/${exportDataConfig.api.aca.correction.employees}`;
    const request = this.urlParamsService.createGetRequest(url, { executionId });
    return this.apiUtilService.
      request<IAcaExportCorrected1095C[], Meta>(request)
      .then((response: ResponseBody<IAcaExportCorrected1095C[], Meta>) =>this.mapService.mapToAcaCorrected1095C(<any>response));
  }

  public async getReplace1095CData(executionId: string): Promise<AcaExportReplace1095C[]> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.replacement.root}/${exportDataConfig.api.aca.replacement.employees}`;
    const request = this.urlParamsService.createGetRequest(url, { executionId });
    return this.apiUtilService.
      request<IAcaExportReplace1095C[], Meta>(request)
      .then((response: ResponseBody<IAcaExportReplace1095C[], Meta>) => this.mapService.mapToReplace1095C(<any>response));
  }

  public async getSupplemental1095CData(executionId: string): Promise<AcaExportSupplemental1095C[]> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.supplemental.root}/${exportDataConfig.api.aca.supplemental.employees}`;
    const request = this.urlParamsService.createGetRequest(url, { executionId });
    return this.apiUtilService.
      request<IAcaExportSupplemental1095C[], Meta>(request)
      .then((response: ResponseBody<IAcaExportSupplemental1095C[], Meta>) => this.mapService.mapToAcaSupplemental1095C(<any>response));
  }

  public async getAcaExport1094cCount(item: AcaExportExecutionItem, correctionParameters: AcaExportCorrectionParameter): Promise<AcaExportCorrection1094CCountContainer> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca.correction.root}/${exportDataConfig.api.aca.correction.correction1094c}`;
    const body = this.mapService.mapTo1094CCorrectionDto(item, correctionParameters);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService.request<IAcaExportCorrection1094CCountContainer, Meta>(request)
      .then((response: ResponseBody<AcaExportCorrection1094CCountContainer, Meta>) => this.mapService.mapToExportCorrection1094CCount(<any>response));
  }

  private getApiRoot(): string {
   return `${appConfig.api.url}/${appConfig.api.version2}/${exportDataConfig.api.root}/${exportDataConfig.api.aca.root}`;
  }
}
