/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard-alerts-select-popup-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../dashboard-alerts-select-popup/dashboard-alerts-select-popup.component.ngfactory";
import * as i5 from "../../../../configuration/services/manage-groups/manage-groups-management.service";
import * as i6 from "../../../../organization/services/lookup/lookup.service";
import * as i7 from "../../../../configuration/services/manage-groups/manage-groups-api.service";
import * as i8 from "../../../../configuration/services/manage-groups/manage-groups-map.service";
import * as i9 from "../dashboard-alerts-select-popup/dashboard-alerts-select-popup.component";
import * as i10 from "../../../services/applications-dashboard/application-dashboard-management.service";
import * as i11 from "../../../../core/services/session/session.service";
import * as i12 from "./dashboard-alerts-select-popup-dialog.component";
import * as i13 from "../../../../common/models/dialog-options";
import * as i14 from "../../../../common/services/modal/modal.service";
import * as i15 from "../../../models/dashboard-alerts/dashboard-alerts-config";
var styles_DashboardAlertsSelectPopupDialogComponent = [i0.styles];
var RenderType_DashboardAlertsSelectPopupDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardAlertsSelectPopupDialogComponent, data: {} });
export { RenderType_DashboardAlertsSelectPopupDialogComponent as RenderType_DashboardAlertsSelectPopupDialogComponent };
export function View_DashboardAlertsSelectPopupDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "slx-dashboard-alerts-select-popup", [], null, [[null, "onLoading"], [null, "onHasChanges"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLoading" === en)) {
        var pd_0 = (_co.onLoading($event) !== false);
        ad = (pd_0 && ad);
    } if (("onHasChanges" === en)) {
        var pd_1 = (_co.onHasChanges($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_DashboardAlertsSelectPopupComponent_0, i4.RenderType_DashboardAlertsSelectPopupComponent)), i1.ɵprd(4608, null, i5.ManageGroupsManagementService, i5.ManageGroupsManagementService, [i6.LookupService, i7.ManageGroupsApiService, i8.ManageGroupsMapService]), i1.ɵdid(5, 245760, null, 0, i9.DashboardAlertsSelectPopupComponent, [i10.ApplicationDashboardManagementService, i11.SessionService], { modelData: [0, "modelData"] }, { onLoading: "onLoading", onHasChanges: "onHasChanges" }), (_l()(), i1.ɵeld(6, 0, null, 0, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.modelData; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_DashboardAlertsSelectPopupDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-dashboard-alerts-select-popup-dialog", [], null, null, null, View_DashboardAlertsSelectPopupDialogComponent_0, RenderType_DashboardAlertsSelectPopupDialogComponent)), i1.ɵdid(1, 49152, null, 0, i12.DashboardAlertsSelectPopupDialogComponent, [i13.DialogOptions, i14.ModalService, i15.DashBoardAlertsModel], null, null)], null, null); }
var DashboardAlertsSelectPopupDialogComponentNgFactory = i1.ɵccf("slx-dashboard-alerts-select-popup-dialog", i12.DashboardAlertsSelectPopupDialogComponent, View_DashboardAlertsSelectPopupDialogComponent_Host_0, {}, {}, []);
export { DashboardAlertsSelectPopupDialogComponentNgFactory as DashboardAlertsSelectPopupDialogComponentNgFactory };
