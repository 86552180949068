import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ApiUtilService } from '../../../common/services/index';
import { ResponseBody } from '../../../core/models/index';
import { WindowRef } from '../../../core/services/window/window-ref.model';

@Injectable()
export class SsoNavigationService {
  constructor(
    private apiUtilService: ApiUtilService,
    private winRef: WindowRef) {
  }

  public navigationToV5(url: string): void {
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    this.apiUtilService.request<any, any>(request)
      .then((response: ResponseBody<any, any>) => {
        this.winRef.nativeWindow.open(response.data.url, '_blank');
      });
  }
}
