import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { employeeConfig } from '../../employee.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeTerminateMapService } from './employee-terminate-map.service';
import { EmployeeTerminate, IEmployeeTerminate } from '../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Injectable()
export class EmployeeTerminateApiService {
  constructor(
    private employeeTerminateMapService: EmployeeTerminateMapService,
    private employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public terminateEmployee(req: EmployeeTerminate): Promise<any> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getTerminationApiRoot()}/${req.employeeId}/${employeeConfig.api.employees.terminate.root}`;
    let modelDTO: IEmployeeTerminate = this.employeeTerminateMapService.mapToTerminateEmployeeDto(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.employeeDefinitionsApiService.clearEmployeeShortInfoCache(req.employeeId);
        return response.data
      });
    return promise;
  }

  public cancelEmployeeTermination(employeeId: number): Promise<any> {
    const url: string = `${this.getTerminationApiRoot()}/${employeeId}/${employeeConfig.api.employees.terminate.cancelterminate}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.employeeDefinitionsApiService.clearEmployeeShortInfoCache(employeeId);
        return response.data;
      });
    return promise;
  }

  public changeTerminationDate(employeeId: number, newDate: Date): Promise<any> {
    let dateStr: string = moment(newDate).format(appConfig.requestDate);
    const url: string = `${this.getTerminationApiRoot()}/${employeeId}/${employeeConfig.api.employees.terminate.changeTermDate}/${dateStr}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.employeeDefinitionsApiService.clearEmployeeShortInfoCache(employeeId);
        return response.data;
      });
    return promise;
  }

  private getTerminationApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.employees.root}`;
  }
}
