import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { PayrollExportManagementService } from '../../../services/index';
var PayrollExportComponent = /** @class */ (function () {
    function PayrollExportComponent(managementService, changeDetector) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
    }
    PayrollExportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadedSubscription = this.managementService.onLoaded$.subscribe(function (data) {
            _this.data = data;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.statusSubscription = this.managementService.onLoadStatus$.subscribe(function (isLoading) {
            _this.isLoading = isLoading;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    PayrollExportComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayrollExportComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayrollExportComponent.prototype, "statusSubscription", void 0);
    return PayrollExportComponent;
}());
export { PayrollExportComponent };
