import { Component, OnDestroy, OnInit, Input, NgZone, Host, ViewChild } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import { Assert } from '../../../../../framework/index';
import {
  EmployeeSectionsPhysicals, EmployeeSectionsPhysicalGroup, EmployeeSectionsTemporalModel, EmployeeSectionsBase
} from '../../../models/index';
import { EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import * as _ from 'lodash';
import { dateTimeUtils } from '../../../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-physicals',
  templateUrl: 'employee-sections-physicals.component.html',
  styleUrls: ['employee-sections-physicals.component.scss']
})
export class EmployeeSectionsPhysicalsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input()
  public employeeSectionsPhysicals: EmployeeSectionsPhysicals;
  @Input() public employeeId: number;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form', {static: false})
  public ngForm: NgForm;

  private employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService;

  constructor(employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsPersonalApiService, 'employeeSectionsPersonalApiService');
    this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsPhysicals;
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.getPersonalPhysicals(this.employeeId)
      .then((employeeSectionsPhysicals: EmployeeSectionsPhysicals) => {
        this.employeeSectionsPhysicals = employeeSectionsPhysicals;
        this.stopProgress();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  protected doSave(effectiveDate: Date): void {
    this.employeeSectionsPersonalApiService.setPersonalPhysicals(this.employeeId, this.employeeSectionsPhysicals, effectiveDate)
      .then((status: any) => {
        this.onActionComplete(true);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    if (!this.employeeSectionsPhysicals) return null;
    let temporalModel: EmployeeSectionsTemporalModel;
    _.forEach(this.employeeSectionsPhysicals.groups, (group: EmployeeSectionsPhysicalGroup) => {
      let tm: EmployeeSectionsTemporalModel = this.metaFieldsTemporalDirtyArrayChecker(group.fields);
      temporalModel = this.metaConcatTemporalModels(temporalModel, tm);
    });
    return temporalModel;
  }

  public isValidEffectiveDate(date: Date): any {
    let isValid = true;
    if (_.isNull(date) || _.isUndefined(date)) {
      isValid = false;
    }
    if (!dateTimeUtils.validateDate(date) && isValid) {
      isValid = false;
    }
    return isValid;
  }
}
