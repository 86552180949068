
<div class="orglevel-breadcrumb {{ mode }}"
  (click)="onToggleOrgTree($event, isMenuMode())"
  [ngClass]="{ 'disabled': !canChangeOrgLevel }"
  title="{{ !canChangeOrgLevel ? userCannotChangeOrglevelMessage : '' }}"
>
<ng-container *ngIf="isMenuMode()">
  <span class="js-icon orglevel-breadcrumb__icon">
    <i class="fas fa-map-marker-alt" aria-hidden="true"></i>
  </span>
  <span class="orglevel-breadcrumb__corp" title="{{ getCorpName(true) }}">
    <span>{{ getCorpName(false) }}</span>
    <i class="fa fa-chevron-right orglevel-breadcrumb__next" aria-hidden="true"></i>
  </span>
  <span class="orglevel-breadcrumb__dep" title="{{ getLastOrgName(true) }}">
    <span class="orglevel-breadcrumb__dep-text">{{ getLastOrgName(false) }}</span>
    <i class="fa orglevel-breadcrumb__state" [ngClass]="getStateIconClass()" aria-hidden="true"></i>
  </span>
</ng-container>
<ng-container *ngIf="!isMenuMode()">
  <span class="js-icon orglevel-breadcrumb__icon">
    <i class="fas fa-map-marker-alt" aria-hidden="true"></i>
  </span>
  <ng-container *ngFor="let orgLevel of currentPath; let last = last">
    <span *ngIf="!last" class="orglevel-breadcrumb__corp">
      <span class="orglevel-breadcrumb__corp-text">{{ orgLevel.name }}</span>
      <i class="fa fa-chevron-right orglevel-breadcrumb__next" aria-hidden="true"></i>
    </span>
    <span *ngIf="last" class="orglevel-breadcrumb__dep">
      <span class="orglevel-breadcrumb__dep-text">{{ orgLevel.name }}</span>
      <i class="fa orglevel-breadcrumb__state" [ngClass]="getStateIconClass()" aria-hidden="true"></i>
    </span>
  </ng-container>
</ng-container>
</div>
<slx-organization-structure #orgTree [show]="showOrgTree" [top]="posTop" [left]="posLeft" [pointerIconShift]="iconShift"></slx-organization-structure>
