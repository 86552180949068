import {
  AddEmployeeWizardStatus
} from './add-employee-wizard.types';

import { Map } from 'immutable';
import { makeTypedMapFactory } from '../../../../framework/immutable-utils/index';
import { AddEmployeeModel } from '../../models/employee-activities/add-employee';

export const addEmployeeModelStateFactory: (val?: any) => Map<string, AddEmployeeModel> = makeTypedMapFactory({
  firstName: null,
  lastName: null,
  middleName: null,
  suffix: null,
  employeeType: null,
  organization: null,
  department: null,
  position: null,

  payrollNumber: null,
  defaultPayrollNumber: null,
  ssn: null,
  hireDate: null,
  birthDate: null,
  payPolicy: null,
  shiftDiffPolicy: null,
  payType: null,
  exemptStatus: null,
  workWeekHours: null,
  dailyBenefitHours: null,
  hourlyRate: null,
  hourlyRate2: null,
  hourlyRate3: null,
  payClass: null,
  companyCode: null,
  fileNumber: null,

  address: null,
  city: null,
  state: null,
  zipCode: null,
  phone: null,
  mobilePhone: null,
  altPhone: null,
  email1: null,
  email2: null,
  gender: null,
  maritalStatus: null,
  race: null,

  badgeId: null,
  enableTimesheetsAccess: false,
  accessType: null,
  timeCard: false,
  schedule: false,
  availability: false,
  departmentSchedule: false,
  inservice: false,
  payStubs: false,
  allowPasswordChange: false,
  allowAddressChange: false,

  password: null,
  ignoreValidation: null
});

export const INITIAL_ADD_EMPLOYEE_MODEL_STATE: Map<string, AddEmployeeModel> = addEmployeeModelStateFactory();

export const addEmployeeWizardStateFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  model: INITIAL_ADD_EMPLOYEE_MODEL_STATE,
  status: AddEmployeeWizardStatus.initial,
  newEmpId: 0
});

export const INITIAL_ADD_EMPLOYEE_WIZARD_STATE: Map<string, any> = addEmployeeWizardStateFactory();
