
export enum EditableListActionKind {
  PROP_CHANGE,
  SELECTION_CHANGE,
  SELECT_ALL,
  START_EDIT,
  CANCEL_EDIT,
  COMPLETE_EDIT,
  CLOSE_EDITOR,
  ADD_ITEM,
  REMOVE
}

export enum EditableListProps {
  EDITABLE,
  SELECTABLE,
  MULTISELECT,
  EDIT_MODE
}

export class EditableListActionModel {
  public items: any[];
  public kind: EditableListActionKind;
  public changes: {
    prop: EditableListProps;
    value: any;
  };

  constructor (kind: EditableListActionKind, items?: any[], changes?: any) {
    this.items = items;
    this.kind = kind;
    this.changes = changes;
  }
}
