import { ShiftSwapStatus } from './shift-swap-status';
import { ShiftSwapType } from './shift-swap-type';

export class ShiftSwapInfo {
    public shiftSwapId: number;
    public createdOn: Date;
    public submittedBy: ShiftSwapProfile;
    public submittedTo: ShiftSwapProfile;
    public status: ShiftSwapStatus;
    public type: ShiftSwapType;
    public canApprove: boolean;

    public get hasSubmittedTo(): boolean {
        return this.submittedTo.id > 0;
    }

    public get canAction(): boolean {
        return this.hasSubmittedTo
            && this.canApprove
            && (this.status === ShiftSwapStatus.Accepted
            || this.status === ShiftSwapStatus.Pending);
    }
}

export interface IShiftSwapContainer {
    shiftSwaps: IShiftSwapInfo[];
    canApprove: boolean;
}

export interface IShiftSwapInfo {
    shiftSwapId: number;
    createdOn: string;
    submittedBy: number;
    submittedTo: number;
    submittedByHireDate: string;
    submittedToHireDate: string;
    submittedByFullName: string;
    submittedToFullName: string;
    submittedByShiftDate: string;
    submittedToShiftDate: string;
    submittedByShiftName: string;
    submittedToShiftName: string;
    submittedByUnitName: string;
    submittedToUnitName: string;
    status: string;
    type: string;
}

export class ShiftSwapProfile {
    public id: number;
    public name: string;
    public hireDate: Date;
    public shiftName: string;
    public shiftDate: Date;
    public unitName: string;
}
