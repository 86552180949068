<form novalidate #loginForm="ngForm">
  <div class="col-xs-12 login-text">
    Username
  </div>
  <div class="col-xs-12 login-control-group">
    <input slx-input type="text" class="login-control" [readonly]="isLockedByUser" [disabled]="state.isLoading" placeholder="Username@Alias" id="username" name="username" [(ngModel)]="credentials.username"
      [ngModelOptions]="{updateOn: 'blur'}"
      (input)="loginChanged()"
      #username="ngModel" required tabindex="1">
    <div class="slx-error-block" *ngIf="username.invalid && username.dirty">
      <span errorMessage for="required">Username is required</span>
    </div>
  </div>
  <div class="col-xs-12 login-text">
    Password
  </div>
  <div class="col-xs-12 login-control-group">
    <input [type]="state.showPassword ? 'text' : 'password'" class="login-control" placeholder="Password" id="password" name="password" [(ngModel)]="credentials.password" #password="ngModel" required tabindex="2" [disabled]="state.isLoading">
    <a class="toggle-password" (click)="togglePasswordVisibility()" [title]="!state.showPassword ? 'Show Password' : 'Hide Password'" tabindex="3">
      <i class="fa" [ngClass]="{ 'fa-eye': !state.showPassword, 'fa-eye-slash': state.showPassword }" aria-hidden="true"></i>
    </a>
    <div class="slx-error-block" *ngIf="password.invalid && password.dirty">
      <span>Password is required</span>
    </div>
  </div>
  <div class="col-xs-12 forgot-password">
    <a routerLink="/login/forgot_password">Forgot Password</a>
  </div>
  <div class="col-xs-12">
    <button type="submit" class="theme-button-apply login-button" [disabled]="!loginForm.form.valid || state.isLoading" (click)="onLogin()" tabindex="4">
      <i class="fal fa-spinner-third fa-spin" *ngIf="state.isLoading" aria-hidden="true"></i>
      <i class="fa fa-lock" aria-hidden="true"></i>
      Login
    </button>
  </div>
  <div class="col-xs-12">
    <button type="button" *ngIf="showLogoutButton" class="theme-button-cancel logout-button" (click)="onLogout()">Logout</button>
  </div>
  <div class="col-xs-12 alerts-area">
    <div class="alert alert-danger alert-dismissible" *ngIf="state.isInvalidCredentials">
      <button class="close" (click)="hideInvalidCredentialsErrorMessage()"><span aria-hidden="true">&times;</span>
      </button>
      <span>Invalid username or password.</span>
    </div>
    <div class="alert alert-danger alert-dismissible" *ngIf="state.isInvalidLogin">
      <button class="close" (click)="hideInvalidLoginErrorMessage()"><span aria-hidden="true">&times;</span></button>
      <span>Cannot find user '{{credentials.username}}'.</span>
    </div>
    <div class="alert alert-danger alert-dismissible" *ngIf="state.isConflictError">
        <button class="close" (click)="hideConflictErrorMessage()"><span aria-hidden="true">&times;</span></button>
        <span>{{responseErrorMessage}}</span>
    </div>
    <div class="alert alert-danger alert-dismissible" *ngIf="state.isUnknownError">
      <button class="close" (click)="hideUnknownErrorMessage()"><span aria-hidden="true">&times;</span></button>
      <span>Unexpected error, please try again or contact support.</span>
    </div>
  </div>
</form>
