import * as tslib_1 from "tslib";
import { ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ValuePairChartSeriesDataService } from '../../../../../common/services/value-pair-chart-data/value-pair-chart-series-data.service';
import { unsubscribe } from '../../../../../core/decorators/index';
import * as _ from 'lodash';
var ScheduleConsoleOverviewDayComponent = /** @class */ (function () {
    function ScheduleConsoleOverviewDayComponent(changeDetector, dataService) {
        this.changeDetector = changeDetector;
        this.dataService = dataService;
        this.getLabel = function (e) {
            var dataItem = e.dataItem;
            if (!dataItem.model) {
                return e.value;
            }
            else if (dataItem.model.rawValue1 !== dataItem.model.rawValue2) {
                var difference = dataItem.model.rawValue1 - dataItem.model.rawValue2;
                return difference > 0 ? '+' + difference : difference.toString();
            }
            return String.fromCharCode(0xf00c);
        };
        this.getLegend = function (e) {
            return e.dataItem.model.label;
        };
    }
    ScheduleConsoleOverviewDayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.configLoaded = this.dataService.onConfigLoaded.subscribe(function (config) {
            _this.config = config;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.modelsLoaded = this.dataService.onModelsLoaded.subscribe(function (seriesModels) {
            var validModels = [];
            _.forEach(seriesModels, function (seriesModel) {
                if (!seriesModel || !seriesModel.models.length ||
                    (seriesModel.models[0].rawValue1 === 0 && seriesModel.models[0].rawValue2 === 0)) {
                    return;
                }
                validModels.push(seriesModel);
            });
            _this.models = validModels;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    ScheduleConsoleOverviewDayComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ScheduleConsoleOverviewDayComponent.prototype.getLegendData = function (model) {
        return {
            model: model,
            value: 1,
        };
    };
    ScheduleConsoleOverviewDayComponent.prototype.getStackedLeftData = function (seriesModel) {
        var model = seriesModel.models[0];
        if (!model) {
            return null;
        }
        var value;
        if (model.input.value1 === model.input.value2) {
            value = model.input.value1;
        }
        else {
            value = Math.min(model.input.value1, model.input.value2);
        }
        return {
            model: model,
            value: value,
            color: model.color.backgroundColor1,
        };
    };
    ScheduleConsoleOverviewDayComponent.prototype.getStackedRightData = function (seriesModel) {
        var model = seriesModel.models[0];
        if (!model) {
            return null;
        }
        var value;
        if (model.input.value1 === model.input.value2) {
            value = 0;
        }
        else {
            value = Math.abs(model.input.value2 - model.input.value1);
        }
        return {
            model: model,
            value: value,
            color: model.color.backgroundColor2,
        };
    };
    ScheduleConsoleOverviewDayComponent.prototype.getLabelFont = function (seriesModel) {
        var font;
        var model = seriesModel.models[0];
        if (!model || model.rawValue1 !== model.rawValue2) {
            return 'bold 16px "Helvetica Neue", Helvetica, Arial, sans-serif';
        }
        return '16px Font Awesome\\ 5 Pro';
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleOverviewDayComponent.prototype, "configLoaded", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleOverviewDayComponent.prototype, "modelsLoaded", void 0);
    return ScheduleConsoleOverviewDayComponent;
}());
export { ScheduleConsoleOverviewDayComponent };
