/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./arrivals-departures-timeline-details-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components-library/components/multiselect/multiselect.component.ngfactory";
import * as i3 from "../../../../components-library/components/multiselect/multiselect.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../common/directives/control-state/control-init-state.directive";
import * as i6 from "../../../../common/services/component-state/component-state-storage.service";
import * as i7 from "../../../../common/services/state-management/state-management.service";
import * as i8 from "../../../../components-library/components/switcher/switcher.component.ngfactory";
import * as i9 from "../../../../components-library/components/switcher/switcher.component";
import * as i10 from "../../../../common/components/toolbar/toolbar.component.ngfactory";
import * as i11 from "../../../../common/components/toolbar/toolbar.component";
import * as i12 from "../../../../core/services/toolbar/toolbar-base.service";
import * as i13 from "../../../../common/directives/toolbar/toolbar-section-template.directive";
import * as i14 from "./arrivals-departures-timeline-details-toolbar.component";
import * as i15 from "../../../services/arrivals-departures/arrivals-departures-timeline-details.service";
import * as i16 from "../../../../organization/services/lookup/lookup.service";
var styles_ArrivalsDeparturesTimelineDetailsToolbarComponent = [i0.styles];
var RenderType_ArrivalsDeparturesTimelineDetailsToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArrivalsDeparturesTimelineDetailsToolbarComponent, data: {} });
export { RenderType_ArrivalsDeparturesTimelineDetailsToolbarComponent as RenderType_ArrivalsDeparturesTimelineDetailsToolbarComponent };
function View_ArrivalsDeparturesTimelineDetailsToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "slx-toolbar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "slx-multiselect", [["class", "slx-wide"], ["externalPlaceholderText", "Units"], ["name", "units"], ["placeholder", "All Units"], ["slxControlInitState", ""], ["titleField", "name"], ["valueField", "id"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.unitFilter = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onUnitChanged() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MultiselectComponent_0, i2.RenderType_MultiselectComponent)), i1.ɵdid(2, 49152, null, 0, i3.MultiselectComponent, [], { valueField: [0, "valueField"], titleField: [1, "titleField"], options: [2, "options"], placeholder: [3, "placeholder"], externalPlaceholderText: [4, "externalPlaceholderText"], externalPlaceholder: [5, "externalPlaceholder"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MultiselectComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 81920, null, 0, i5.ControlInitStateDirective, [i4.NgControl, i6.ComponentStateStorageService, i7.StateManagementService], { resetType: [0, "resetType"], restoreManually: [1, "restoreManually"] }, null), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "id"; var currVal_8 = "name"; var currVal_9 = _co.units; var currVal_10 = "All Units"; var currVal_11 = "Units"; var currVal_12 = true; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = "units"; var currVal_14 = _co.unitFilter; _ck(_v, 4, 0, currVal_13, currVal_14); var currVal_15 = ""; var currVal_16 = true; _ck(_v, 6, 0, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ArrivalsDeparturesTimelineDetailsToolbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "slx-toolbar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "slx-multiselect", [["class", "slx-wide"], ["externalPlaceholderText", "Variances"], ["name", "variances"], ["placeholder", "All"], ["slxControlInitState", ""], ["titleField", "value"], ["valueField", "value"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.variancesFilter = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onVariancesChanged() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MultiselectComponent_0, i2.RenderType_MultiselectComponent)), i1.ɵdid(2, 49152, null, 0, i3.MultiselectComponent, [], { valueField: [0, "valueField"], titleField: [1, "titleField"], options: [2, "options"], placeholder: [3, "placeholder"], externalPlaceholderText: [4, "externalPlaceholderText"], externalPlaceholder: [5, "externalPlaceholder"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MultiselectComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 81920, null, 0, i5.ControlInitStateDirective, [i4.NgControl, i6.ComponentStateStorageService, i7.StateManagementService], { resetType: [0, "resetType"], restoreManually: [1, "restoreManually"] }, null), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "value"; var currVal_8 = "value"; var currVal_9 = _co.variances; var currVal_10 = "All"; var currVal_11 = "Variances"; var currVal_12 = true; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = "variances"; var currVal_14 = _co.variancesFilter; _ck(_v, 4, 0, currVal_13, currVal_14); var currVal_15 = ""; var currVal_16 = true; _ck(_v, 6, 0, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ArrivalsDeparturesTimelineDetailsToolbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "slx-toolbar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "toggler-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "slx-switcher", [["label", "Show Overages"], ["labelOff", "No"], ["labelOn", "Yes"], ["name", "isShowOverages"], ["slxControlInitState", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.isShowOverages = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onShowOveragesChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_SwitcherComponent_0, i8.RenderType_SwitcherComponent)), i1.ɵdid(3, 49152, null, 0, i9.SwitcherComponent, [], { label: [0, "label"], labelOn: [1, "labelOn"], labelOff: [2, "labelOff"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.SwitcherComponent]), i1.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(7, 81920, null, 0, i5.ControlInitStateDirective, [i4.NgControl, i6.ComponentStateStorageService, i7.StateManagementService], { resetType: [0, "resetType"], restoreManually: [1, "restoreManually"] }, null), i1.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "Show Overages"; var currVal_8 = "Yes"; var currVal_9 = "No"; _ck(_v, 3, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "isShowOverages"; var currVal_11 = _co.isShowOverages; _ck(_v, 5, 0, currVal_10, currVal_11); var currVal_12 = ""; var currVal_13 = true; _ck(_v, 7, 0, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ArrivalsDeparturesTimelineDetailsToolbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "slx-toolbar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "toggler-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "slx-switcher", [["label", "Expand timeline"], ["labelOff", "No"], ["labelOn", "Yes"], ["name", "isExpanded"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.isExpanded = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onModeChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_SwitcherComponent_0, i8.RenderType_SwitcherComponent)), i1.ɵdid(3, 49152, null, 0, i9.SwitcherComponent, [], { label: [0, "label"], labelOn: [1, "labelOn"], labelOff: [2, "labelOff"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.SwitcherComponent]), i1.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "Expand timeline"; var currVal_8 = "Yes"; var currVal_9 = "No"; _ck(_v, 3, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "isExpanded"; var currVal_11 = _co.isExpanded; _ck(_v, 5, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ArrivalsDeparturesTimelineDetailsToolbarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "slx-toolbar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "slx-multiselect", [["class", "slx-wide"], ["name", "empFilter"], ["placeholder", "Filter..."], ["slxControlInitState", ""], ["titleField", "name"], ["valueField", "id"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.appliedCommonFilterItems = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onCommonFilterChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_MultiselectComponent_0, i2.RenderType_MultiselectComponent)), i1.ɵdid(2, 49152, null, 0, i3.MultiselectComponent, [], { valueField: [0, "valueField"], titleField: [1, "titleField"], options: [2, "options"], placeholder: [3, "placeholder"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MultiselectComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 81920, null, 0, i5.ControlInitStateDirective, [i4.NgControl, i6.ComponentStateStorageService, i7.StateManagementService], { resetType: [0, "resetType"], restoreManually: [1, "restoreManually"] }, null), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "id"; var currVal_8 = "name"; var currVal_9 = _co.commonFilterItems; var currVal_10 = "Filter..."; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = "empFilter"; var currVal_12 = _co.appliedCommonFilterItems; _ck(_v, 4, 0, currVal_11, currVal_12); var currVal_13 = ""; var currVal_14 = true; _ck(_v, 6, 0, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ArrivalsDeparturesTimelineDetailsToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "slx-toolbar", [["class", "slx-ta-timeline-toolbar-container"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_ToolbarComponent_0, i10.RenderType_ToolbarComponent)), i1.ɵdid(1, 1228800, null, 1, i11.ToolbarComponent, [i1.ElementRef, [2, i12.TOOLBAR_SERVICE], i1.ChangeDetectorRef], { alignExpandButtonRight: [0, "alignExpandButtonRight"] }, null), i1.ɵqud(603979776, 1, { contentChildren: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ArrivalsDeparturesTimelineDetailsToolbarComponent_1)), i1.ɵdid(4, 16384, [[1, 4]], 0, i13.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { width: [0, "width"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_ArrivalsDeparturesTimelineDetailsToolbarComponent_2)), i1.ɵdid(6, 16384, [[1, 4]], 0, i13.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { width: [0, "width"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_ArrivalsDeparturesTimelineDetailsToolbarComponent_3)), i1.ɵdid(8, 16384, [[1, 4]], 0, i13.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { width: [0, "width"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_ArrivalsDeparturesTimelineDetailsToolbarComponent_4)), i1.ɵdid(10, 16384, [[1, 4]], 0, i13.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { width: [0, "width"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_ArrivalsDeparturesTimelineDetailsToolbarComponent_5)), i1.ɵdid(12, 16384, [[1, 4]], 0, i13.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { alignMode: [0, "alignMode"], width: [1, "width"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = "170"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "130"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "100"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "100"; _ck(_v, 10, 0, currVal_4); var currVal_5 = "right"; var currVal_6 = "150"; _ck(_v, 12, 0, currVal_5, currVal_6); }, null); }
export function View_ArrivalsDeparturesTimelineDetailsToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-arrivals-departures-timeline-details-toolbar", [], null, null, null, View_ArrivalsDeparturesTimelineDetailsToolbarComponent_0, RenderType_ArrivalsDeparturesTimelineDetailsToolbarComponent)), i1.ɵdid(1, 770048, null, 0, i14.ArrivalsDeparturesTimelineDetailsToolbarComponent, [i15.ArrivalsDeparturesTimelineDetailsManagementService, i16.LookupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArrivalsDeparturesTimelineDetailsToolbarComponentNgFactory = i1.ɵccf("slx-arrivals-departures-timeline-details-toolbar", i14.ArrivalsDeparturesTimelineDetailsToolbarComponent, View_ArrivalsDeparturesTimelineDetailsToolbarComponent_Host_0, { orgLevelId: "orgLevelId" }, {}, []);
export { ArrivalsDeparturesTimelineDetailsToolbarComponentNgFactory as ArrivalsDeparturesTimelineDetailsToolbarComponentNgFactory };
