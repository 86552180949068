<div *ngIf="wizardError">
  <div class="col-md-12 text-center">
    <h1 class="error">During add new employee the error has occurred! Contact your system administrator.</h1>
  </div>
  <div class="col-md-2 col-md-offset-2">
  </div>
  <div class="col-md-2 col-md-offset-1">
      <button type=button class="btn btn-default" (click)="prevStep()">
            Return to wizard
      </button>
  </div>
 </div>

 <div class="emp_success_conatiner">
  <div class="col-md-12 text-center">
    <h1>Congratulations!</h1>
  </div>
  <div class="col-md-12 text-center">
      <h2>You have successfully added employee {{addEmployeeModel.lastName}}, {{addEmployeeModel.firstName}}</h2>
  </div>
  <div class="col-md-12 text-center payrollContainer" *ngIf="isNgpUser">
    <h2 class="payroll-Border">Payroll Number: {{payrollNumber}}</h2>
  </div>
  <div class="col-md-12 text-center">
      <h2>What would you like to do next?</h2>
  </div>
  <div class="col-md-12">
    <hr>
  </div> 
  <div class="btn_container">
    <div *ngIf="isNgpUser">
      <button type=button class="theme-iconed-accent-button finish-button payroleBtn" (click)="activateNGPSSO()">
        Payroll set up for this employee
      </button>
    </div>
    <div>
        <button type=button class="theme-iconed-accent-button finish-button" (click)="goEmployeeList()">
          Go to employee list
        </button>
    </div>
    <div>
        <button type=button class="theme-iconed-accent-button finish-button" (click)="goThisEmployee()">
          Go to this employee
        </button>
    </div>
    <div>
        <button type=button class="theme-iconed-accent-button finish-button" (click)="addAnotherEmployee()">
          Add another employee
        </button>
    </div>
  </div>
</div>

