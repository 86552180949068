import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef, AfterViewChecked, QueryList } from '@angular/core';
import { Assert } from '../../../../../framework/index';
import { AppSettingsManageService } from './../../../../../app-settings/services/app-settings-manage.service';
import { orderBy, process } from '@progress/kendo-data-query';
import { BoolYNPipe } from '../../../../../common/pipes/boolyn';
import * as _ from 'lodash';
import { KendoGridStateHelper } from '../../../../../common/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import * as moment from 'moment';
import { appConfig } from '../../../../../app.config';
import { RangeDates } from '../../../../../common/models/range-dates';
import { RolesApiService } from '../../../../../configuration/services';
import { EmployeeSectionsPositionHistory } from '../../../models/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { SessionService } from '../../../../../core/services';
var EmployeeSectionsPositionHistoryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPositionHistoryComponent, _super);
    function EmployeeSectionsPositionHistoryComponent(employeeSectionsEmploymentApiService, decorator, ngZone, changeDetector, appSettingsManageService, rolesApiService, sessionService, boolPipe) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.changeDetector = changeDetector;
        _this.appSettingsManageService = appSettingsManageService;
        _this.rolesApiService = rolesApiService;
        _this.sessionService = sessionService;
        _this.boolPipe = boolPipe;
        _this.createdDateTime = moment().format(appConfig.militaryDateTimeFormat);
        _this.subscriptions = {};
        _this.sort = [];
        _this.primarySort = [];
        _this.secondarySort = [];
        _this.hiddenColumns = [];
        _this.pageSize = 10;
        _this.isPositionHistoryWithAttributeEnabled = false;
        _this.isAuthorizedUser = false;
        _this.pdfTemplate = {
            allPages: true,
            landscape: true,
            paperSize: 'A4',
            scale: 0.7,
            repeatHeaders: true,
            margin: '0.25in',
            marginTop: '0.675in',
            marginBottom: '0.5in',
            marginRight: '0.25in',
            marginLeft: '0.25in'
        };
        Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.primaryGridState = new KendoGridStateHelper();
        _this.secondaryGridState = new KendoGridStateHelper();
        _this.primaryGridState.state.take = _this.pageSize;
        _this.secondaryGridState.state.take = _this.pageSize;
        _this.primaryGridState.state.skip = 0;
        _this.secondaryGridState.state.skip = 0;
        _this.sort = [{ field: 'archivedDate.fieldValue', dir: 'desc' }];
        _this.primarySort = [{ field: 'archivedDate.fieldValue', dir: 'desc' }];
        _this.secondarySort = [{ field: 'archivedDate.fieldValue', dir: 'desc' }];
        _this.primaryGridState.state.sort = _this.primarySort;
        _this.secondaryGridState.state.sort = _this.secondarySort;
        var endDate = moment().endOf('day');
        _this.endDate = endDate.toDate();
        _this.startDate = endDate.subtract(1, 'year').startOf('day').toDate();
        _this.selectedRange = new RangeDates();
        _this.selectedRange.startDate = _this.startDate;
        _this.selectedRange.endDate = _this.endDate;
        _this.employeeName = _this.employeeShortInfo.fullName;
        _this.employeePosition = _this.employeeShortInfo.position.name;
        _this.employeeType = _this.employeeShortInfo.type;
        _this.employeePayRollNumber = _this.employeeShortInfo.payrollNumber;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsPositionHistoryComponent.prototype, "positionHistory", {
        set: function (employeeSectionsPositionHistory) {
            this.employeeSectionsPositionHistory = employeeSectionsPositionHistory;
            this.refreshGrid();
            this.primaryRefreshGrid();
            this.secondaryRefreshGrid();
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPositionHistoryComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsPositionHistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSettings();
        this.subscriptions.primaryGridRefreshSubscription = this.primaryGridState.onRefreshGrid.subscribe(function (v) {
            _this.primaryRefreshGrid();
        });
        this.subscriptions.SecondaryGridRefreshSubscription = this.secondaryGridState.onRefreshGrid.subscribe(function (v) {
            _this.secondaryRefreshGrid();
        });
        var roles, userData, loginRole;
        userData = this.sessionService.getUser();
        loginRole = userData.roles[0];
        this.rolesApiService.getRoleAccessTable().then(function (res) {
            roles = res.roles;
            roles.forEach(function (ele) {
                if (loginRole.toLowerCase() === ele.name.toLowerCase() && ele.profile) {
                    ele.profile.sections.forEach(function (section) {
                        if (section.name.toLowerCase() === 'employee') {
                            section.subsections.forEach(function (subsection) {
                                if (subsection.name.toLowerCase() === 'position history') {
                                    subsection.fields.forEach(function (field) {
                                        _this.isPositionHistoryDataExists(field);
                                    });
                                }
                            });
                        }
                    });
                }
            });
        });
    };
    EmployeeSectionsPositionHistoryComponent.prototype.ngAfterViewChecked = function () {
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsPositionHistoryComponent.prototype.isPositionHistoryDataExists = function (field) {
        if (field.name.toLowerCase() === 'positionhistorydata' && field.editable)
            this.isAuthorizedUser = true;
    };
    EmployeeSectionsPositionHistoryComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsPositionHistory;
    };
    EmployeeSectionsPositionHistoryComponent.prototype.sortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeeSectionsPositionHistoryComponent.prototype.sortChangeWithGrid = function (sort, gridType) {
        if (gridType.toLowerCase() === "primary") {
            this.primarySort = sort;
            this.primaryRefreshGrid();
        }
        else {
            this.secondarySort = sort;
            this.secondaryRefreshGrid();
        }
    };
    EmployeeSectionsPositionHistoryComponent.prototype.loadSubsection = function (showpositionattribute) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmploymentPositionHistory(this.employeeId, showpositionattribute)
            .then(function (employeeSectionsPositionHistory) {
            _this.employeeSectionsPositionHistory = employeeSectionsPositionHistory;
            _this.primaryRefreshGrid();
            _this.secondaryRefreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
            _this.stopProgress();
        }).catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsPositionHistoryComponent.prototype.refreshGrid = function () {
        if (!this.employeeSectionsPositionHistory) {
            this.gridView = null;
            return;
        }
        this.gridView = {
            data: orderBy(this.employeeSectionsPositionHistory.records, this.sort),
            total: this.employeeSectionsPositionHistory.records.length
        };
    };
    EmployeeSectionsPositionHistoryComponent.prototype.primaryRefreshGrid = function () {
        if (!this.employeeSectionsPositionHistory) {
            this.primaryGridState.view = null;
            return;
        }
        this.primaryfilteredRecords = _.filter(this.employeeSectionsPositionHistory.primaryRecords);
        var sortedRecords = orderBy(this.primaryfilteredRecords, this.sort);
        this.primaryGridState.view = process(sortedRecords, this.primaryGridState.state);
    };
    EmployeeSectionsPositionHistoryComponent.prototype.secondaryRefreshGrid = function () {
        if (!this.employeeSectionsPositionHistory) {
            this.secondaryGridState.view = null;
            return;
        }
        this.secondaryfilteredRecords = _.filter(this.employeeSectionsPositionHistory.secondaryRecords);
        var sortedsecondaryRecords = orderBy(this.secondaryfilteredRecords, this.sort);
        this.secondaryGridState.view = process(sortedsecondaryRecords, this.secondaryGridState.state);
    };
    EmployeeSectionsPositionHistoryComponent.prototype.dataStateChange = function (state) {
        this.primaryGridState.state = state;
        this.primaryRefreshGrid();
        this.secondaryRefreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsPositionHistoryComponent.prototype.hideColumns = function () {
        if (this.showPositionAttributes) {
            if (this.showLatestUpdates) {
                this.showLatestUpdates = false;
            }
            this.loadSubsection('positionAttributes');
            this.hiddenColumns.push('payPolicy', 'shiftDiffPolicy', 'costCenter');
        }
        else {
            if (!this.showLatestUpdates) {
                this.loadSubsection('default');
                this.hiddenColumns = [];
            }
        }
    };
    EmployeeSectionsPositionHistoryComponent.prototype.OnLatestUpdates = function () {
        if (this.showLatestUpdates) {
            if (this.showPositionAttributes) {
                this.showPositionAttributes = false;
            }
            this.hiddenColumns = [];
            this.loadSubsection('latestUpdates');
        }
        else {
            if (!this.showPositionAttributes) {
                this.loadSubsection('default');
                this.hiddenColumns = [];
            }
        }
    };
    EmployeeSectionsPositionHistoryComponent.prototype.onClickExportPdf = function (position) {
        if (position.toLowerCase() === 'pdf') {
            this.kendoGrid.forEach(function (e) {
                e.saveAsPDF();
            });
        }
    };
    EmployeeSectionsPositionHistoryComponent.prototype.onClickExportExcel = function (position) {
        if (position.toLowerCase() === 'excel') {
            this.kendoGrid.forEach(function (e) {
                e.saveAsExcel();
            });
        }
    };
    EmployeeSectionsPositionHistoryComponent.prototype.getPositionHistoryFileName = function (exportType, gridType) {
        if (exportType.toLowerCase() === 'pdf' && gridType.toLowerCase() === 'primary') {
            return this.employeeName + ' Primary_Position_History_From ' + this.getPositionHistoryDates() + '.pdf';
        }
        else if (exportType.toLowerCase() === 'pdf' && gridType.toLowerCase() === 'secondary') {
            return this.employeeName + ' Secondary_Position_History_From ' + this.getPositionHistoryDates() + '.pdf';
        }
        else if (exportType.toLowerCase() === 'excel' && gridType.toLowerCase() === 'primary') {
            return this.employeeName + ' Primary_Position_History_From ' + this.getPositionHistoryDates() + '.xlsx';
        }
        else {
            return this.employeeName + ' Secondary_Position_History_From ' + this.getPositionHistoryDates() + '.xlsx';
        }
    };
    EmployeeSectionsPositionHistoryComponent.prototype.getPositionHistoryTitle = function (gridType) {
        if (gridType.toLowerCase() === "primary") {
            return 'Primary Position History for the Period ' + this.getPositionHistoryDates();
        }
        else {
            return 'Secondary Position History for the Period ' + this.getPositionHistoryDates();
        }
    };
    EmployeeSectionsPositionHistoryComponent.prototype.getPositionHistoryDates = function () {
        return moment(this.selectedRange.startDate).format(appConfig.dateFormat) + ' to ' + moment(this.selectedRange.endDate).format(appConfig.dateFormat);
    };
    EmployeeSectionsPositionHistoryComponent.prototype.getPositionHistoryEmployeeInfo = function () {
        return this.employeeName + '   ' + this.employeePosition + '   ' + this.employeeType + '   ' + this.employeePayRollNumber;
    };
    EmployeeSectionsPositionHistoryComponent.prototype.retriveAllPages = function (gridType) {
        var _this = this;
        if (gridType.toLowerCase() === 'primary') {
            return function () { return ({
                data: process(_this.primaryfilteredRecords, { sort: _this.primaryGridState.state.sort, filter: _this.primaryGridState.state.filter }).data
            }); };
        }
        else {
            return function () { return ({
                data: process(_this.secondaryfilteredRecords, { sort: _this.secondaryGridState.state.sort, filter: _this.secondaryGridState.state.filter }).data
            }); };
        }
    };
    EmployeeSectionsPositionHistoryComponent.prototype.onClickExportPositionHistory = function (e) {
        var _this = this;
        var sheets = _.head(_.get(e, 'workbook.sheets'));
        _.forEach(sheets.rows, function (row) {
            if (row.type.toLowerCase() === 'data') {
                _.forEach(row.cells, function (cell) {
                    if (_.isBoolean(cell.value)) {
                        cell.value = _this.boolPipe.transform(cell.value);
                    }
                });
            }
        });
    };
    EmployeeSectionsPositionHistoryComponent.prototype.getSettings = function () {
        var _this = this;
        this.appSettingsManageService.getAppServerConfig()
            .then(function (conf) {
            _this.isPositionHistoryWithAttributeEnabled = conf.ispositionHistoryWithAttributeEnabled;
        });
    };
    EmployeeSectionsPositionHistoryComponent.prototype.onRefreshClicked = function () {
        if (this.showPositionAttributes) {
            this.loadSubsection('positionAttributes');
        }
        else {
            this.loadSubsection('default');
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsPositionHistoryComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsPositionHistoryComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsPositionHistoryComponent };
