import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridComponent, DataStateChangeEvent, RowArgs, CellClickEvent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { EmployeeColumnsDefinition } from '../../models/index';
import { employeeListConfig } from '../../employee-list.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { EmployeeListCommonService } from '../../services/employee-list/employee-list-common.service';
import { LocalStorageService } from '../../../../core/services/local-storage/local-storage.service';
import { Router } from '@angular/router';
var EmployeeGridComponent = /** @class */ (function () {
    function EmployeeGridComponent(changeDetector, employeeListCommonService, localStorage, router) {
        var _this = this;
        this.changeDetector = changeDetector;
        this.employeeListCommonService = employeeListCommonService;
        this.localStorage = localStorage;
        this.router = router;
        this.isGroupable = true;
        this.isPaging = true;
        this.columnsGroupName = 'EmployeeList';
        this.isOptInStatus = false;
        this.isOptInEmail = false;
        this.pageSize = 50;
        this.useNameRestriction = false;
        this.onEmployeesSelect = new EventEmitter();
        this.onEmployeeOpen = new EventEmitter();
        this.onFiltered = new EventEmitter();
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.gridState.state.take = _this.pageSize;
            _this.refreshGrid();
        });
        this.gridSelectionSubscription = this.gridState.onSelectionChanged.subscribe(function (selectedItems) {
            _this.onEmployeesSelect.emit(selectedItems);
        });
        this.appConfig = appConfig;
        this.employeeListConfig = employeeListConfig;
        this.selectedEmployees = [];
        this.empIdKey = employeeListConfig.employeeIdentifierName;
    }
    Object.defineProperty(EmployeeGridComponent.prototype, "fieldDefinition", {
        set: function (fieldDefinition) {
            this.currentFieldDefinition = fieldDefinition;
            this.updateFields();
            this.useNameRestriction = fieldDefinition && fieldDefinition.listName === 'Physicals';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeGridComponent.prototype, "employees", {
        set: function (employees) {
            this.currentEmployees = employees || [];
            this.updateEmployeeData();
            this.getAgencyCertificateDisplayName();
            this.generateFilters(employees);
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    EmployeeGridComponent.prototype.getfilteredEmployeeList = function () {
        var employees = process(this.currentEmployees, { filter: this.gridState.state.filter }).data;
        return employees;
    };
    EmployeeGridComponent.prototype.ngOnInit = function () {
        // this.refreshGrid();
    };
    EmployeeGridComponent.prototype.isColumnSuppressed = function (fieldata) {
        return !this.employeeListCommonService.isSpecColumn(fieldata);
    };
    EmployeeGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeGridComponent.prototype.sortByNameInAscending = function () {
        this.gridState.state.sort = [
            { field: 'EmpName', dir: 'asc' }
        ];
    };
    EmployeeGridComponent.prototype.refreshGrid = function () {
        if (!this.currentEmployees) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.currentEmployees, this.gridState.state);
    };
    EmployeeGridComponent.prototype.getFilter = function (type) {
        if (type === 'int')
            return 'numeric';
        if (type === 'boolean')
            return 'boolean';
        if (type === 'date')
            return 'date';
        if (type === 'datetime')
            return 'date';
        return null;
    };
    EmployeeGridComponent.prototype.setEmployeesToSelect = function (employeeIds) {
        var _a;
        this.selectedEmployees.length = 0;
        (_a = this.selectedEmployees).push.apply(_a, employeeIds);
        var selectedEmployees = this.extractItems(this.selectedEmployees);
        _.forEach(selectedEmployees, function (emp) { emp.isSelected = true; });
    };
    EmployeeGridComponent.prototype.onToggleAllSelected = function () {
        var _a;
        var _this = this;
        var employees = process(this.currentEmployees, { filter: this.gridState.state.filter }).data;
        var selectedEmployees = _.filter(employees, function (employee) {
            employee.isSelected = _this.isAllSelected && employee.Selectable;
            return employee.isSelected;
        });
        this.selectedEmployees.length = 0;
        (_a = this.selectedEmployees).push.apply(_a, this.extractIds(selectedEmployees));
        this.onEmployeesSelect.emit(selectedEmployees);
    };
    EmployeeGridComponent.prototype.selectionChange = function () {
        var _a;
        var selectedEmployees = _.filter(this.extractEmployees(), function (emp) { return emp.isSelected; });
        this.selectedEmployees.length = 0;
        (_a = this.selectedEmployees).push.apply(_a, this.extractIds(selectedEmployees));
        this.setCheckBoxState();
        this.onEmployeesSelect.emit(selectedEmployees);
    };
    EmployeeGridComponent.prototype.setCheckBoxState = function () {
        var disabledEmployee = _.filter(this.extractEmployees(), function (employee) { return (!employee.Selectable); });
        if ((this.selectedEmployees.length + disabledEmployee.length) !== this.extractEmployees().length) {
            this.isAllSelected = false;
        }
        else {
            this.isAllSelected = true;
        }
    };
    EmployeeGridComponent.prototype.onCellClick = function (clickEvent) {
        var extractedEmps = this.extractEmployees();
        var employee = _.first(extractedEmps.filter(function (x) { return x.EmpId === clickEvent.dataItem.EmpId; }));
        if (_.isObject(employee) && employee.Selectable) {
            if (employee.isSelected) {
                var index = _.indexOf(this.selectedEmployees, this.empId(employee));
                this.selectedEmployees.splice(index, 1);
                employee.isSelected = false;
            }
            else {
                if (!this.isMultiselect) {
                    this.selectedEmployees.length = 0;
                    _.forEach(extractedEmps, function (emp) { emp.isSelected = false; });
                }
                employee.isSelected = true;
                this.selectedEmployees.push(this.empId(employee));
            }
            this.onEmployeesSelect.emit(this.extractItems(this.selectedEmployees));
            this.setCheckBoxState();
        }
    };
    EmployeeGridComponent.prototype.isRowSelected = function () {
        var _this = this;
        return function (row) { return _.indexOf(_this.selectedEmployees, _this.empId(row.dataItem)) !== -1; };
    };
    EmployeeGridComponent.prototype.refreshColumns = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeGridComponent.prototype.extractEmployees = function () {
        var extractedEmp = [];
        _.forEach(this.currentEmployees, function (obj) {
            if (obj.hasOwnProperty('items')) {
                extractedEmp = extractedEmp.concat(obj.items);
            }
            else {
                extractedEmp.push(obj);
            }
        });
        return extractedEmp;
    };
    EmployeeGridComponent.prototype.empId = function (employee) {
        return employee[this.empIdKey];
    };
    EmployeeGridComponent.prototype.extractIds = function (employees) {
        var _this = this;
        return _.map(employees, function (emp) { return _this.empId(emp); });
    };
    EmployeeGridComponent.prototype.extractItems = function (empIds) {
        var _this = this;
        return _.filter(this.extractEmployees(), function (emp) { return _.includes(empIds, _this.empId(emp)); });
    };
    EmployeeGridComponent.prototype.exportToExcel = function () {
        this.grid.saveAsExcel();
    };
    EmployeeGridComponent.prototype.exportToPdf = function () {
        var _this = this;
        var selection = this.selectedEmployees;
        _.forEach(this.selectedEmployees, function (emp) {
            _this.selectedEmployees.pop();
        });
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.grid.saveAsPDF();
        _.forEach(selection, function (emp) {
            _this.selectedEmployees.push(emp);
        });
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.currentEmployees, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    EmployeeGridComponent.prototype.onExcelExport = function (e) {
        var sheets = _.head(_.get(e, 'workbook.sheets'));
        _.forEach(sheets.rows, function (row) {
            if (row.type === 'data') {
                _.forEach(row.cells, function (cell) {
                    if (_.isBoolean(cell.value)) {
                        cell.value = cell.value ? 'Yes' : 'No';
                    }
                });
            }
        });
    };
    EmployeeGridComponent.prototype.notBlank = function (dataItem, fieldName) {
        var showDollar = false;
        if (fieldName === 'Employee Contribution') {
            if (!_.isNull(dataItem.BenefitEmployeeContributionAmt)) {
                showDollar = true;
            }
        }
        else if (fieldName === 'Employer Contribution') {
            if (!_.isNull(dataItem.BenefitEmployerContributionAmt)) {
                showDollar = true;
            }
        }
        else if (fieldName === 'Cost') {
            if (!_.isNull(dataItem.BenefitCost)) {
                showDollar = true;
            }
        }
        return showDollar;
    };
    EmployeeGridComponent.prototype.generateFilters = function (employees) {
        if (employees) {
            this.typeFilters = this.optInStatusFilters();
        }
    };
    EmployeeGridComponent.prototype.optInStatusFilters = function () {
        return [
            {
                text: 'Opted Out',
                value: 'Opted Out'
            },
            {
                text: 'Opted In',
                value: 'Opted In'
            },
            {
                text: 'Not Responded',
                value: 'Not Responded to the Opt In Request'
            }
        ];
    };
    EmployeeGridComponent.prototype.getFormat = function (format) {
        return _.size(format) > 0 ? format : '';
    };
    EmployeeGridComponent.prototype.updateEmployeeData = function () {
        this.currentEmployees.map(function (each) {
            each['Covid_19VaccinationReceived_CBA_Vac_1'] = each['Covid-19VaccinationReceived@CBA-Vac.1'];
            each['Covid_19VaccinationReceived_CBA_Vac_2'] = each['Covid-19VaccinationReceived@CBA-Vac.2'];
            each['Covid_19VaccinationReceivedOutsideofCBA_Vac_2'] = each['Covid-19VaccinationReceivedOutsideofCBA-Vac.2'];
            each['Covid_19VaccinationReceivedOutsideofCBA_Vac_1'] = each['Covid-19VaccinationReceivedOutsideofCBA-Vac.1'];
            each['Covid_19VaccinationRefused'] = each['Covid-19VaccinationRefused'];
            each['Covid_19Status'] = each['Covid-19Status'];
            each['Covid_19ExamDate'] = each['Covid-19ExamDate'];
            each['FluVaccineReceived_CBK'] = each['FluVaccineReceived@CBK'];
            each['ChestX_RayStatus'] = each['ChestX-RayStatus'];
            each['ChestX_RayExamDate'] = each['ChestX-RayExamDate'];
        });
    };
    EmployeeGridComponent.prototype.updateFields = function () {
        var _this = this;
        this.currentFieldDefinition.fields.map(function (each) {
            each.fieldName = _this.renameFieldName(each.fieldName);
        });
    };
    EmployeeGridComponent.prototype.renameFieldName = function (fieldName) {
        switch (fieldName) {
            case 'Covid-19VaccinationReceived@CBA-Vac.1':
                return 'Covid_19VaccinationReceived_CBA_Vac_1';
            case 'Covid-19VaccinationReceived@CBA-Vac.2':
                return 'Covid_19VaccinationReceived_CBA_Vac_2';
            case 'Covid-19VaccinationReceivedOutsideofCBA-Vac.2':
                return 'Covid_19VaccinationReceivedOutsideofCBA_Vac_2';
            case 'Covid-19VaccinationReceivedOutsideofCBA-Vac.1':
                return 'Covid_19VaccinationReceivedOutsideofCBA_Vac_1';
            case 'Covid-19VaccinationRefused':
                return 'Covid_19VaccinationRefused';
            case 'Covid-19Status':
                return 'Covid_19Status';
            case 'Covid-19ExamDate':
                return 'Covid_19ExamDate';
            case 'FluVaccineReceived@CBK':
                return 'FluVaccineReceived_CBK';
            case 'ChestX-RayStatus':
                return 'ChestX_RayStatus';
            case 'ChestX-RayExamDate':
                return 'ChestX_RayExamDate';
        }
        return fieldName;
    };
    EmployeeGridComponent.prototype.getAgencyCertificateDisplayName = function () {
        this.currentEmployees.forEach(function (element) {
            if (element.Preferred != null || element.DNR != null) {
                element.Preferred = element.Preferred == "True" ? 'Yes' : 'No';
                element.DNR = element.DNR == "True" ? 'Yes' : 'No';
            }
        });
    };
    EmployeeGridComponent.prototype.dataStateChange = function (state) {
        var _this = this;
        var gridState = this.localStorage.get('GridSettings');
        var hitFromEmpSection = localStorage.getItem('hitFromEmpSection');
        var backButtonClick = this.localStorage.get('backButtonClicked');
        if (gridState && hitFromEmpSection === 'yes') {
            this.convertToDate(gridState);
            this.updateGridState(gridState);
            this.localStorage.remove('hitFromEmpSection');
        }
        else if (gridState && backButtonClick === 'yes') {
            this.convertToDate(gridState);
            this.updateGridState(gridState);
            this.localStorage.remove('backButtonClicked');
        }
        else {
            this.localStorage.set('GridSettings', state);
            this.updateGridState(state);
            this.router.events.subscribe(function (event) {
                if (event.navigationTrigger == "popstate") {
                    var currentUrls = void 0, splitUrls = void 0, currentUrl = void 0, empId = "d001"; //To compare URL against any employee, replaced employee id in URL with this value for comparing
                    if (_this.router.url.startsWith("/apps/common/employee/") && _this.router.url.includes("employee_sections")) {
                        currentUrls = _this.router.url.split('?');
                        if (currentUrls[0]) {
                            splitUrls = currentUrls[0].split('/');
                            if (splitUrls.length > 5) {
                                splitUrls[4] = empId;
                            }
                        }
                        currentUrl = splitUrls.join('/');
                        if (currentUrl === "/apps/common/employee/" + empId + "/employee_sections") {
                            _this.localStorage.set('backButtonClicked', 'yes');
                        }
                    }
                }
            });
        }
    };
    EmployeeGridComponent.prototype.updateGridState = function (state) {
        this.gridState.state = state;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeGridComponent.prototype.convertToDate = function (state) {
        var _this = this;
        if (state.filter) {
            state.filter.filters.forEach(function (item) {
                item.filters.forEach(function (item) {
                    if (_this.isIsoDate(item.value))
                        item["value"] = new Date(item.value);
                });
            });
        }
    };
    EmployeeGridComponent.prototype.isIsoDate = function (str) {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str))
            return false;
        var d = new Date(str);
        return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === str; // valid date 
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeGridComponent.prototype, "gridSelectionSubscription", void 0);
    return EmployeeGridComponent;
}());
export { EmployeeGridComponent };
