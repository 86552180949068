export interface IUserLoginSessionInfo {
    userId: number,
    userName: string,
    userLogin: string,
    email: string,
    lastUpdateOn: string,
    ipAddress: string,
    lastLogin: string,
    closeTime: string,
    closed: boolean,
    status: boolean,
}

export class UserLoginSessionInfo {
    public userId: number;
    public userName: string;
    public userLogin: string;
    public email: string;
    public lastUpdateOn: Date;
    public ipAddress: string;
    public lastLogin: Date;
    public closeTime: Date;
    public closed: boolean;
    public status: boolean;
    //calculated properties
    public sessionDuration: number = 0;
    public formattedSessionDuration: string;
    public daySinceLastLogin: number = 0;
    public loggedIn: boolean;

    public isSelected: boolean = false;
    public selectable: boolean = true;
}