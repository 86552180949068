<div class="h-box flex align-items__center">
  <slx-input-decorator class="emp-list">
    <slx-dropdown-input slx-input type="text"  [lookup]="lookupRequest"
    [placeholder]="parameter.displayName" [(ngModel)]="lookupValue"
      emptyOption="All"
    ></slx-dropdown-input>
    <span errorMessage for="required">Report parameter is required.</span>
  </slx-input-decorator>
  <slx-checkbox-toggle class="toggler-is-active" className="toggle-right" [(ngModel)]="isActive" (ngModelChange)="createRequest()"
  caption="Active only" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
</div>
