import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { Assert } from '../../../framework/index';

export class TimecardsSummaryNavigationService {
    private router: Router;
    private route: ActivatedRoute;

    constructor(router: Router, route: ActivatedRoute) {
        this.router = router;
        this.route = route;
    }

    public NavigateToTimecardSummary(organizationId: number, startDate: Date, endDate: Date): void {
      Assert.isNotNull(organizationId, 'organizationId');
      Assert.isNotNull(startDate, 'startDate');
      Assert.isNotNull(endDate, 'endDate');

      this.router.navigate(['timecards', 'summary'], {
        relativeTo: this.route.pathFromRoot[2],
        queryParams: {
          startDate: moment(startDate).format(appConfig.linkDateFormat),
          endDate: moment(endDate).format(appConfig.linkDateFormat),
          organization: organizationId,
          hideLeftSideBar: true
         }
      });
    }

    public NavigateToIndividualTimecardSummary(employeeId: number, startDate: Date, endDate: Date): void {
      Assert.isNotNull(employeeId, 'employeeId');
      Assert.isNotNull(startDate, 'startDate');
      Assert.isNotNull(endDate, 'endDate');

      this.router.navigate(['timecards', 'summary'], {
        relativeTo: this.route.pathFromRoot[2],
        queryParams: {
          startDate: moment(startDate).format(appConfig.linkDateFormat),
          endDate: moment(endDate).format(appConfig.linkDateFormat),
          employeeId: employeeId,
          hideLeftSideBar: true
        }
      });
    }
}
