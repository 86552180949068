/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo-maker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./photo-maker.component";
var styles_PhotoMakerComponent = [i0.styles];
var RenderType_PhotoMakerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoMakerComponent, data: {} });
export { RenderType_PhotoMakerComponent as RenderType_PhotoMakerComponent };
export function View_PhotoMakerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { videoElem: 0 }), i1.ɵqud(402653184, 2, { canvasElem: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "photo-maker"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[2, 0], ["canvas", 1]], null, 0, "canvas", [["class", "hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["video", 1]], null, 0, "video", [["autoplay", ""], ["class", "video"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "take-photo"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onTakePhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-camera"]], null, null, null, null, null))], null, null); }
export function View_PhotoMakerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-photo-maker", [], null, null, null, View_PhotoMakerComponent_0, RenderType_PhotoMakerComponent)), i1.ɵdid(1, 114688, null, 0, i2.PhotoMakerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoMakerComponentNgFactory = i1.ɵccf("slx-photo-maker", i2.PhotoMakerComponent, View_PhotoMakerComponent_Host_0, { options: "options" }, { makePhoto: "onPhoto", failed: "onError" }, []);
export { PhotoMakerComponentNgFactory as PhotoMakerComponentNgFactory };
