import { Injectable, EventEmitter } from '@angular/core';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { OrgLevel, User, OrgLevelType } from '../../../state-model/models/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { ChangeManagementService } from '../../../common/index';
import { TelepunchLocation } from '../../models/telepunch-locations/telepunch-location';
import { TelepunchLocationsApiService } from './telepunch-locations-api.service';
import { TelepunchLocationsMapService } from './telepunch-locations-map.service';
import { TelepunchLocationsContainer } from '../../models/telepunch-locations/telepunch-location-container';
import { IUser } from '../../../authentication/store/index';
import { ManagementBaseService } from '../../../core/services/index';
import * as _ from 'lodash';

@Injectable()
export class TelepunchLocationsManagementService extends ManagementBaseService<TelepunchLocationsContainer, any> implements IConfigurationManagementService {
    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    @mutableSelect(['session', 'user'])
    public user$: Observable<IUser>;

    public isEditingNewItem: boolean;
    public editingItem: any;

    public addItemCmd$: ReplaySubject<any>;
    public editItemCmd$: ReplaySubject<any>;
    public viewRefresh$: Subject<boolean>;
    public removeItemsCmd$: ReplaySubject<any>;
    public onItemSaved$: ReplaySubject<TelepunchLocation>;

    public addItemClicked$: Subject<any>;
    public deleteItemClicked$: Subject<any>;

    public state: {
        isLoading: boolean;
        bulkEditMode: boolean;
        canBulkEdit: boolean;
        canEdit: boolean;
        canAdd: boolean;
        canDelete: boolean;
    };

    private m_container: TelepunchLocationsContainer;

    @unsubscribeInService()
    private appDataSubscription: Subscription;

    private currentOrgLevel: OrgLevel;
    private currentUser: User;

    private originalCollection: TelepunchLocation[];

    constructor(public access: AccessManagementService, public changeService: ChangeManagementService, private api: TelepunchLocationsApiService, private map: TelepunchLocationsMapService) {
        super();
        this.removeItemsCmd$ = new ReplaySubject<TelepunchLocation>();
        this.addItemCmd$ = new ReplaySubject<TelepunchLocation>();
        this.editItemCmd$ = new ReplaySubject<TelepunchLocation>();
        this.onItemSaved$ = new ReplaySubject<TelepunchLocation>();
        this.viewRefresh$ = new Subject<boolean>();

        this.addItemClicked$ = new Subject();
        this.deleteItemClicked$ = new Subject();

        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: false,
            canEdit: true,
            canAdd: true,
            canDelete: true
        };
    }

    public init(): void {
        this.changeService.setCurrentComponentId('configure_telepunch_locations');

        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = true;

        this.appDataSubscription = this.orgLevel$.combineLatest(this.user$).subscribe((value: [OrgLevel, User]) => {
            let [orgLevel, user]: [OrgLevel, User] = value;
            if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
                return;
            }
            this.currentOrgLevel = orgLevel;
            this.currentUser = user;
            this.access.orgLevelType = this.currentOrgLevel.type;
            if (this.m_container) {
                this.m_container.records = [];
                this.onLoaded$.next(this.m_container);
            }
            if (this.currentOrgLevel.type === OrgLevelType.department || this.currentOrgLevel.type === OrgLevelType.organization) {
                this.state.bulkEditMode = false;
                this.state.canBulkEdit = false;
                this.onStateChanged$.next({ orgLevelChanged: true, bulkEditMode: false, canBulkEdit: false });
                this.fetchRecords(this.currentOrgLevel.id);
            } else {
                this.state.bulkEditMode = false;
                this.state.canBulkEdit = false;
                this.onStateChanged$.next({ orgLevelChanged: true, bulkEditMode: false, canBulkEdit: false });
            }
        });

    }

    public markAsDirty(): void {
        this.changeService.changeNotify();
    }

    public addItemClicked(): void {
        this.markAsDirty();
        this.addItemClicked$.next();
    }

    public deleteItemClicked(): void {
        this.deleteItemClicked$.next();
    }

    public getProhibitedPhones(loc: TelepunchLocation): string[] {
        let filtered: TelepunchLocation[] = _.filter(this.m_container.records, tp => tp !== loc);
        return _.map(filtered, tp => tp.phone);
    }

    public getProhibitedNames(loc: TelepunchLocation): string[] {
        let filtered: TelepunchLocation[] = _.filter(this.m_container.records, tp => tp !== loc);
        return _.map(filtered, tp => tp.locationName);
    }

    //#region IConfigurationManagementService implementation

    public onRemoveItem(itemToDelete: TelepunchLocation): void {
        if(!itemToDelete) return;
        this.removeItemsCmd$.next(itemToDelete);
    }

    public onAddItem(item: any): void {
        this.markAsDirty();
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    }

    public setSelectedCount(count: number): void {
        this.access.selectedItemsCount = count;
    }

    public onEditItem(item: any): void {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    }

    public onCancelEditItem(): void {
        this.changeService.clearChanges();
        this.editingItem = null;
        this.editItemCmd$.next(null);
    }

    public onSaveItem(info: { dataItem: TelepunchLocation, isNew: boolean }): void {
        this.updateItem(info.dataItem);
    }

    public doRemoveItem(dataItem: TelepunchLocation): void {
        this.state.isLoading = true;
        this.api.deleteTelepunchLocation(dataItem).then(() => {
            _.remove(this.m_container.records, tp => tp.id === dataItem.id);
            this.changeService.clearChanges();
            this.update();
        })
        .catch(() => this.update());
    }

    public createEmptyItem(): number {
        this.m_container.records.push(new TelepunchLocation());
        return this.m_container.records.length;
    }

    public removeEmptyItem(dataItem: TelepunchLocation): void {
        _.remove(this.m_container.records, tp => tp === dataItem);
    }

    //#endregion IConfigurationManagementService implementation

    //#region bulk actions

    public setItemDirty(item: TelepunchLocation): void {
        item.isDirty = true;
    }

    public doBulkEdit(): void {
        this.originalCollection = this.map.cloneCollection(this.m_container.records);
        this.changeService.changeNotify();
        this.state.bulkEditMode = true;
        this.onStateChanged$.next({ bulkEditMode: true });
    }

    public doBulkSave(): void {

        this.originalCollection = null;
        this.state.isLoading = true;
        this.onStateChanged$.next({ isLoading: true });
        let dirtyRecords: TelepunchLocation[] = [];
        _.each(this.m_container.records, (model: TelepunchLocation) => {
            if (model.isDirty) {
                dirtyRecords.push(model);
            }
            model.isDirty = false;
        });

        if (dirtyRecords.length > 0) {
            this.api.saveTelepunchLocations(dirtyRecords)
                .then(() => {
                    this.changeService.clearChanges();
                    this.viewRefresh$.next(false);
                    this.state.isLoading = false;
                    this.state.bulkEditMode = false;
                    this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
                })
                .catch(() => this.update());
        } else {
            this.state.isLoading = false;
            this.state.bulkEditMode = false;
            this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
        }
    }

    public doBulkDiscard(): void {
        this.m_container.records = this.originalCollection;
        this.changeService.clearChanges();
        this.state.bulkEditMode = false;
        this.onStateChanged$.next({ bulkEditMode: false });
        this.onLoaded$.next(this.m_container);
    }

    public get records(): TelepunchLocation[] {
        return this.m_container ? this.m_container.records : [];
    }

    //#endregion bulk actions

    protected fetchRecords(orgLevelId: number): void {
        this.state.isLoading = true;
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.getTelepunchLocations().
            then((result: TelepunchLocationsContainer) => {
                this.changeService.clearChanges();
                this.access.lockActions = false;

                this.m_container = result;
                this.editingItem = null;
                this.isEditingNewItem = false;

                if (this.m_container.actions) this.access.actions = this.m_container.actions;

                this.onLoaded$.next(this.m_container);
                let state: any = {
                    isLoading: false,
                    bulkEditMode: false,
                    canBulkEdit: this.m_container.actions.canEdit,
                    canEdit: this.m_container.actions.canEdit,
                    canAdd: this.m_container.actions.canAdd,
                    canDelete: this.m_container.actions.canDelete
                };
                this.state.isLoading = false;
                this.state.bulkEditMode = false;
                this.state.canBulkEdit = false;
                this.state.canEdit = this.m_container.actions.canEdit;
                this.state.canAdd = this.m_container.actions.canAdd;
                this.state.canDelete = this.m_container.actions.canDelete;
                this.onStateChanged$.next(state);
            }).catch((e: Error) => {
                this.m_container = new TelepunchLocationsContainer();
                this.m_container.records = [];
                this.onLoaded$.next(this.m_container);
                this.access.lockActions = false;
                this.state.isLoading = false;
                this.onStateChanged$.next({ isLoading: false });
            });

    }

    protected updateItem(item: TelepunchLocation): void {

        this.onStateChanged$.next({ isLoading: true });
        this.api.saveTelepunchLocations([item])
            .then(() => {
                this.changeService.clearChanges();
                if (this.isEditingNewItem) {
                    this.fetchRecords(this.currentOrgLevel.id);
                    return;
                }
                this.onItemSaved$.next(item);
                this.editingItem = null;
                this.update();
            })
            .catch(() => this.update());
    }

    private update(): void {
      this.viewRefresh$.next(false);
      this.state.isLoading = false;
      this.onStateChanged$.next({ isLoading: false });
    }
}
