<slx-spinner [show]="state.isLoading" class="attendance-points-config">
  <form novalidate #form="ngForm" class="slx-full-height slx-main-content-indents no-padding">

    <kendo-grid #kendoGrid class="slx-blue-grid slx-full-height no-border"
      [data]="gridData"
      [sortable]="{ mode: 'multiple' }"
      [sort]="sort"
      (sortChange)="sortChangeHandler($event)"
      (add)="addHandler()"
      (edit)="editHandler($event)"
      (remove)="removeHandler($event)"
      (save)="saveHandler()"
      (cancel)="cancelHandler()"
    >
      <ng-template kendoGridToolbarTemplate position="top">
        <button (click)="addHandler()" type="button" class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted">
          <i class="fas fa-plus-circle slx-button__icon icon-padding" aria-hidden="true"></i>
          Add New</button>
      </ng-template>
      <kendo-grid-command-column title="Command" width="120">
        <ng-template kendoGridCellTemplate>
          <div class="flex-horizontal">
            <button kendoGridEditCommand [disabled]="isAdding" type="button" class="button-margin slx-button slx-only-icon slx-toolbar">
              <i class="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
            <button kendoGridRemoveCommand [disabled]="isAdding" [popover]="acceptPopover" type="button" class="button-margin slx-button slx-only-icon slx-toolbar">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
            <popover-content #acceptPopover title="Are you sure?" placement="auto">
                <div style="width:70px">
                  <button type="button" (click)="onPopoverAction(acceptPopover, false)" class="button-margin slx-button slx-only-icon slx-toolbar">
                    <i class="fas fa-ban" aria-hidden="true"></i>
                  </button>
                  <button type="button" (click)="onPopoverAction(acceptPopover, true)" class="button-margin slx-button slx-only-icon slx-toolbar">
                      <i class="fas fa-check-circle" aria-hidden="true"></i>
                  </button>
                </div>
            </popover-content>
            <button kendoGridSaveCommand [disabled]="!form.valid" type="button" class="button-margin slx-button slx-only-icon slx-toolbar">
              <i class="far fa-check-circle" aria-hidden="true"></i>
            </button>
              <button kendoGridCancelCommand type="button"  class="button-margin slx-button slx-only-icon slx-toolbar">
              <i class="fa fa-ban" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column title="Category" field="category.name">
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.category.name }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem>
          <slx-input-decorator>
             <slx-autocomplete-input
              slx-input
              name="categories"
              [required]="true"
              placeholder="Category"
              [options]="categoriesList"
              [createIfNotFound]="true"
              [(ngModel)]="selectedCategory"
            >
            </slx-autocomplete-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Definition" field="definition">
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.definition }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem>
           <slx-input-decorator>
              <input slx-input required="true" name="definitionName" placeholder="Definition name" [(ngModel)]="editedEntry.definition">
              <span errorMessage for="required"></span>
           </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Pay Codes" field="exceptionNames">
        <ng-template kendoGridCellTemplate let-dataItem>{{ getPayCodesNames(dataItem) }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem>
          <slx-input-decorator class="element width-40 auto-height" >
            <slx-dropdown-multiselect slx-input [options]="payCodes"
                valueField="description" titleField="description"
                [(ngModel)]="editedEntry.exceptions"
                name="optionsInput"
                placeholder="Pay Codes">
            </slx-dropdown-multiselect>
            <span errorMessage for="required"></span>
        </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Points" field="points" width="150">
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.points }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem>
           <slx-input-decorator>
              <slx-kendo-number slx-input
                [(ngModel)]="editedEntry.points"
                [decimals]="2"
                [step]="0.5"
                [required]="true"
                placeholder="Points amount"
                name="pointsAmount"
              ></slx-kendo-number>
              <span errorMessage for="required"></span>
           </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</slx-spinner>
