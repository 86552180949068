import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { IUser } from '../../../authentication/store/index';
import { OrgLevel, User, OrgLevelType } from '../../../state-model/models/index';
import { ManagementBaseService } from '../../../core/services/management/management-base.service';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';
import { CostCenterModel } from '../../models/index';
import { CostCentersConfigurationContainer } from '../../models/cost-centers/cost-centers-configuration-container';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { ChangeManagementService } from '../../../common/services/change-management/change-management.service';
import { CostCentersApiService } from './cost-centers-api.service';
import { CostCentersMapService } from './cost-centers-map.service';
import { Department, LocationUnit, Position } from '../../../organization/models/index';
import { LookupApiService } from '../../../organization/services/index';
import { ICostGridState } from '../../models/cost-centers/cost-grid-state.interface';

@Injectable()
export class CostCentersConfigurationManagementService extends ManagementBaseService<CostCentersConfigurationContainer, ICostGridState> implements IConfigurationManagementService {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    @mutableSelect(['session', 'user'])
    public user$: Observable<IUser>;

    public get container(): CostCentersConfigurationContainer {
        return this.m_container;
    }

    public isEditingNewItem: boolean;
    public editingItem: any;

    public addItemCmd$: ReplaySubject<any>;
    public editItemCmd$: ReplaySubject<any>;
    public viewRefresh$: Subject<boolean>;
    public removeItemsCmd$: ReplaySubject<any>;
    public removeItemsStartCmd$: ReplaySubject<any>;
    public noSelectedItem$: Subject<any>;
    public confirmDelete$: Subject<CostCenterModel>;
    public onItemSaved$: ReplaySubject<CostCenterModel>;
    public defaultsInitialized$: ReplaySubject<any>;
    public currentOrgLevel: OrgLevel;    
    public departments: Department[];
    public positions: Position[];
    public units: LocationUnit[];

    private m_container: CostCentersConfigurationContainer;

    @unsubscribeInService()
    private appDataSubscription: Subscription;

    private currentUser: User;

    private originalCollection: CostCenterModel[];

    constructor(
        public access: AccessManagementService,
        public changeService: ChangeManagementService,
        private api: CostCentersApiService,
        private map: CostCentersMapService,
        private lookupApiService: LookupApiService
    ) {
        super();
        this.removeItemsStartCmd$ = new ReplaySubject<CostCenterModel>();
        this.removeItemsCmd$ = new ReplaySubject<any>();
        this.noSelectedItem$ = new Subject<any>();
        this.confirmDelete$ = new Subject<CostCenterModel>();
        this.addItemCmd$ = new ReplaySubject<CostCenterModel>();
        this.editItemCmd$ = new ReplaySubject<CostCenterModel>();
        this.onItemSaved$ = new ReplaySubject<CostCenterModel>();
        this.defaultsInitialized$ = new ReplaySubject();
        this.viewRefresh$ = new Subject<boolean>();

        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: false,
            canEdit: false,
            canAdd: false,
            canDelete: false,
            orgLevelChanged: false
        };
    }

    public async init(): Promise<void> {

        this.changeService.setCurrentComponentId('configure_cost_centers');

        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = true;

        this.appDataSubscription = this.orgLevel$.combineLatest(this.user$).subscribe((value: [OrgLevel, User]) => {
            let [orgLevel, user]: [OrgLevel, User] = value;
            if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
                return;
            }
            this.currentOrgLevel = orgLevel;
            this.currentUser = user;
            this.access.orgLevelType = this.currentOrgLevel.type;

            this.initDefaults();

        });

    }

    public markAsDirty(): void {
        this.changeService.changeNotify();
    }

    public onDeleteItemStart(): any {
        this.removeItemsStartCmd$.next(null);
    }

    public onRemoveItem(itemToDelete: CostCenterModel): void {
        if (itemToDelete.employeeCount > 0) {
            this.confirmDelete$.next(itemToDelete);
        } else {
            this.deleteItem(itemToDelete);
        }
    }

    public noSelectedItem(): void {
        this.noSelectedItem$.next(null);
    }

    public onAddItem(item: any): void {
        this.markAsDirty();
        if(this.currentOrgLevel.type == OrgLevelType.department) {            
           let currentDepartment = this.departments.filter(x => x.id = this.currentOrgLevel.relatedItemId)[0];
           item.department = currentDepartment;
        }
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    }

    public setSelectedCount(count: number): void {
        this.access.selectedItemsCount = count;
    }

    public onEditItem(item: any): void {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    }

    public onCancelEditItem(): void {
        this.editingItem = null;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges();
    }

    public onSaveItem(info: { dataItem: CostCenterModel, isNew: boolean }): void {
        if (info.isNew) {
            this.createItem(info.dataItem);
        } else {
            this.updateItem(info.dataItem);
        }
    }

    //#endregion IConfigurationManagementService implementation

    public deleteItem(item: CostCenterModel): void {

        this.onLoadStatusChanged(true);
        this.api.deleteRecord(item.id)
            .then(() => {
                this.changeService.clearChanges();
                this.editingItem = null;
                this.loadRecords();
            })
            .catch(() => this.loadRecords());
    }

    public reloadRecords(): void {
        this.onLoaded$.next(null);
        this.fetchRecords(this.currentOrgLevel.id);
    }

    protected async initDefaults(): Promise<void> {
        let departmentsPromise: Promise<Department[]> = this.lookupApiService.getDepartments(null, this.currentOrgLevel.id);
        let positionPromise: Promise<Position[]> = this.lookupApiService.getPositions(null, this.currentOrgLevel.id, true, false);
        let unitPromise: Promise<LocationUnit[]> = this.lookupApiService.getLocationUnits(null, this.currentOrgLevel.id);

        let results: any[] = await Promise.all([unitPromise, departmentsPromise, positionPromise]);

        this.units = results[0];
        this.departments = results[1];
        this.positions = results[2] ? _.uniqBy(results[2], (position: Position) => position.id) : [];

        this.map.departments = this.departments;
        this.map.positions = this.positions;
        this.map.units = this.units;

        this.defaultsInitialized$.next(null);

        if (this.m_container) {
            this.m_container.records = [];
            this.onLoaded$.next(this.m_container);
        }
        if (this.currentOrgLevel.type === OrgLevelType.department || this.currentOrgLevel.type === OrgLevelType.organization) {
            this.onStateChanged$.next(
                {
                    isLoading: false,
                    bulkEditMode: false,
                    canBulkEdit: false,
                    canEdit: false,
                    canAdd: false,
                    canDelete: false,
                    orgLevelChanged: true
                }
            );
            this.fetchRecords(this.currentOrgLevel.id);
        } else {
            this.onStateChanged$.next({
                isLoading: false,
                bulkEditMode: false,
                canBulkEdit: false,
                canEdit: false,
                canAdd: false,
                canDelete: false,
                orgLevelChanged: true
            });
        }
    }

    protected fetchRecords(orgLevelId: number): void {

        this.onLoadStatusChanged(true);
        this.access.lockActions = true;
        this.api.getRecords(orgLevelId).
            then((result: CostCentersConfigurationContainer) => {

                this.changeService.clearChanges();

                this.access.lockActions = false;

                this.m_container = result;
                this.editingItem = null;
                this.isEditingNewItem = false;

                if (this.m_container.actions) this.access.actions = this.m_container.actions;

                this.onLoaded$.next(this.m_container);
                let state: any = {
                    isLoading: false,
                    bulkEditMode: false,
                    canBulkEdit: false,
                    canEdit: this.access.actions.canEdit,
                    canAdd: this.access.actions.canAdd,
                    canDelete: this.access.actions.canDelete
                };
                this.state = state;
                this.onStateChanged$.next(state);
                this.onLoadStatusChanged(false);
            }).catch((e: Error) => {

                this.m_container = new CostCentersConfigurationContainer();
                this.m_container.records = [];
                this.onLoaded$.next(this.m_container);
                this.access.lockActions = false;
                this.onLoadStatusChanged(false);
            });

    }

    protected updateItem(item: CostCenterModel): void {

        this.onLoadStatusChanged(true);
        this.api.updateRecord(item)
            .then(() => {
              this.changeService.clearChanges();
              this.update();
            })
            .catch(() => this.loadRecords());
    }


    protected createItem(item: CostCenterModel): void {

        this.onLoadStatusChanged(true);
        this.api.createRecord(item)
            .then(() => {
              this.changeService.clearChanges();
              this.update();
            })
            .catch(() => this.loadRecords());
    }

    private update(): void {
      this.onItemSaved$.next(this.editingItem);
      this.editingItem = null;
      this.loadRecords();
    }

    private loadRecords(): void {
      this.viewRefresh$.next(false);
      this.onLoadStatusChanged (false);
      this.fetchRecords(this.currentOrgLevel.id);
    }

}
