<div *ngIf="!isPositionHistoryWithAttributeEnabled">
  <kendo-grid height="300"
              [data]="gridView"
              [sortable]="{ mode: 'multiple' }"
              [sort]="sort"
              scrollable="'scrollable'"
              (sortChange)="sortChange($event)">
    <kendo-grid-column field="archivedDate.fieldValue" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Start Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.archivedDate.fieldValue | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="endDate.fieldValue" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        End Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.endDate.fieldValue | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Job Description
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.jobDescription.fieldValue }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Primary
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.isPrimary.fieldValue | boolyn }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Pay Policy
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.payPolicy.fieldValue.name}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Shift Diff Policy
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.shiftDiffPolicy.fieldValue.name }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Cost Center
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.costCenter.fieldValue}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Employee Type
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.employeeType.fieldValue.name}}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
<div *ngIf="isPositionHistoryWithAttributeEnabled">
  <div class="position-toggles">
    <div class="position-toggles-first" *ngIf="isAuthorizedUser">
      <slx-checkbox-toggle className="toggle-left" [(ngModel)]="showPositionAttributes" (ngModelChange)="hideColumns()"
        caption="Hide Position Attributes" checkedText="Yes" uncheckedText="No">
      </slx-checkbox-toggle>
    </div>
    <span class="export-button">
      <slx-action-list>
        <slx-action-button style="margin-right: 50px;" [popperContent]="popperContent" [popperPosition]="'bottom-end'">
          Export</slx-action-button>
        <popper-content #popperContent>
          <slx-action-list-item (click)="onClickExportExcel('excel')">Export to Excel
          </slx-action-list-item>
          <slx-action-list-item (click)="onClickExportPdf('pdf')">Export to PDF
          </slx-action-list-item>         
        </popper-content>
      </slx-action-list>
    </span>
    <button class="refresh-button pull-right" type="button">
      <span class="text-button-content" (click)="onRefreshClicked()">Refresh</span>
    </button>
  </div>
  <p class="primary-title">Primary Position</p>
  <ng-template [ngTemplateOutlet]="positionHistory" [ngTemplateOutletContext]="{gridState:primaryGridState, gridType: 'primary'}"></ng-template>

  <p class="secondary-title">Secondary Positions</p>
  <ng-template [ngTemplateOutlet]="positionHistory" [ngTemplateOutletContext]="{gridState:secondaryGridState, gridType: 'secondary'}"></ng-template>

  <ng-template #positionHistory let-gridState='gridState' let-gridType='gridType'>
    <kendo-grid #kendoGrid
    [data]="gridState.view" 
    [sortable]="{ mode: 'multiple' }" [sort]="(gridType.toLowerCase() === 'primary') ? primarySort : secondarySort"
    scrollable="'scrollable'" 
    (sortChange)="sortChangeWithGrid($event, gridType)" 
    [filterable]="'menu'"
    [filter]="gridState.state.filter" 
    (excelExport)="onClickExportPositionHistory($event)"
    (dataStateChange)="gridState.dataStateChange($event)"
    (stateRestored)="gridState.dataStateChange($event)"
    [skip]="gridState.state.skip" 
    [pageable]="true" [pageSize]="pageSize" >
    <kendo-grid-column field="archivedDate.fieldValue" filter="date" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Start Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.archivedDate.fieldValue | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="endDate.fieldValue" filter="date" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        End Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>
        {{(dataItem.endDate.fieldValue | amDateFormat: appConfig.dateFormat) != '01/01/1900' ? (dataItem.endDate.fieldValue | amDateFormat: appConfig.dateFormat) : ''}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="jobDescription.fieldValue" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Job Description
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.jobDescription.fieldValue }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="organizationName.fieldValue" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Organization
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.organizationName.fieldValue}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="departmentName.fieldValue" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Department
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.departmentName.fieldValue}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="employeeType.fieldValue.name" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Employee Type
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.employeeType.fieldValue.name}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="payPolicy.fieldValue.name" [width]="100"
      [hidden]="hiddenColumns.indexOf('payPolicy') > -1">
      <ng-template kendoGridHeaderTemplate>
        Pay Policy
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.payPolicy.fieldValue.name}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="shiftDiffPolicy.fieldValue.name" [width]="100"
      [hidden]="hiddenColumns.indexOf('shiftDiffPolicy') > -1">
      <ng-template kendoGridHeaderTemplate>
        Shift Diff Policy
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.shiftDiffPolicy.fieldValue.name }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="gridType.toLowerCase() === 'primary'" field="costCenter.fieldValue" filter="numeric" [width]="100"
      [hidden]="hiddenColumns.indexOf('costCenter') > -1">
      <ng-template kendoGridHeaderTemplate>
        Cost Center
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.costCenter.fieldValue ? dataItem.costCenter.fieldValue:''}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-excel [fileName]="getPositionHistoryFileName('excel', gridType)" [fetchData]="retriveAllPages(gridType)">
      <kendo-excelexport-column-group [title]="getPositionHistoryTitle(gridType)" [headerCellOptions]="{ textAlign:'center' }">
        <kendo-excelexport-column-group [title]="getPositionHistoryEmployeeInfo()"
          [headerCellOptions]="{ textAlign: 'center' }">
          <kendo-excelexport-column title="Start Date" field="startDateFormatForExcel" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
            <ng-template kendoExcelExportFooterTemplate>Created on</ng-template>
          </kendo-excelexport-column>
          <kendo-excelexport-column title="End Date" field="endDateFormatForExcel" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
            <ng-template kendoExcelExportFooterTemplate>{{createdDateTime}}</ng-template>
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Job Description" field="jobDescription.fieldValue" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Organization" field="organizationName.fieldValue" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Department" field="departmentName.fieldValue" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Employee Type" field="employeeType.fieldValue.name" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Pay Policy" field="payPolicy.fieldValue.name"
            [hidden]="hiddenColumns.indexOf('payPolicy') > -1" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column title="Shift Diff Policy" field="shiftDiffPolicy.fieldValue.name"
            [hidden]="hiddenColumns.indexOf('shiftDiffPolicy') > -1" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>
          <kendo-excelexport-column *ngIf="gridType.toLowerCase() === 'primary'" title="Cost Center" field="verifyCostCenterCalue"
            [hidden]="hiddenColumns.indexOf('costCenter') > -1" [width]="130"
            [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
          </kendo-excelexport-column>

        </kendo-excelexport-column-group>
      </kendo-excelexport-column-group>
    </kendo-grid-excel>
    <kendo-grid-pdf [fileName]="getPositionHistoryFileName('pdf', gridType)" [allPages]="pdfTemplate.allPages"
      [landscape]="pdfTemplate.landscape" [paperSize]="pdfTemplate.paperSize" [margin]="pdfTemplate.margin"
      [scale]="pdfTemplate.scale" [repeatHeaders]="pdfTemplate.repeatHeaders">
      <kendo-grid-pdf-margin [top]="pdfTemplate.marginTop" [bottom]="pdfTemplate.marginBottom"
        [right]="pdfTemplate.marginRight" [left]="pdfTemplate.marginLeft">
      </kendo-grid-pdf-margin>
      <ng-template kendoGridPDFTemplate>
        <div class="pdf-page-template">
          <div class="pdf-header">
            <div class="pdf-title">{{getPositionHistoryTitle(gridType)}}</div>
            <div class="pdf-title1">
              <span class="pdf-text-container">{{employeeName}}</span>
              <span class="pdf-text-container">{{employeePosition}}</span>
              <span class="pdf-text-container">{{employeeType}}</span>
              <span class="pdf-text-container">{{employeePayRollNumber}}</span>
            </div>
          </div>
          <div class="pdf-footer">
            <div class="pdf-footer-title">Created on : {{createdDateTime}}</div>
          </div>
        </div>
      </ng-template>
      <kendo-grid-column field="archivedDate" width="100">
        <ng-template kendoGridHeaderTemplate>
          Start Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{dataItem.archivedDate.fieldValue | amDateFormat: appConfig.dateFormat}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="endDate" width="100">
        <ng-template kendoGridHeaderTemplate>
          End Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span>{{(dataItem.endDate.fieldValue | amDateFormat: appConfig.dateFormat) != '01/01/1900' ? (dataItem.endDate.fieldValue | amDateFormat: appConfig.dateFormat) : ''}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="Job Description" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Job Description
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.jobDescription.fieldValue }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="Organization" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Organization
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.organizationName.fieldValue }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="Department" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Department
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.departmentName.fieldValue }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="Employee Type" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Employee Type
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.employeeType.fieldValue.name }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="Pay Policy" [hidden]="hiddenColumns.indexOf('shiftDiffPolicy') > -1" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Pay Policy
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.payPolicy.fieldValue.name }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="Shift Diff Policy" [hidden]="hiddenColumns.indexOf('shiftDiffPolicy') > -1" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Shift Diff Policy
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.shiftDiffPolicy.fieldValue.name }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column *ngIf="gridType.toLowerCase() === 'primary'" field="Cost Center" [hidden]="hiddenColumns.indexOf('costCenter') > -1" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Cost Center
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.costCenter.fieldValue ? dataItem.costCenter.fieldValue:''}}
        </ng-template>
      </kendo-grid-column>

    </kendo-grid-pdf>
  </kendo-grid>
  </ng-template>
</div>