import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { AppSettingsManageService } from '../../../../../app-settings/services/app-settings-manage.service';
import { BenefitDetailsCalcMethod, BenefitDetailsLine, BenefitDetailsTier, BenefitPayrollDeductionDates, BenefitPayrollDeduction } from '../../../models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitEmployeesApiService, BenefitEmployeesMapService } from './../../../services/benefit-employees/index';
import { BenefitEmployeeManagementService } from '../../../services/benefit-employees/benefit-employees-management.service';
import { InfoDialogComponent, ConfirmOptions, ConfirmDialog2Component } from './../../../../../common/components/index';
import { NotificationsService } from '../../../../../core/components/index';
import * as moment from 'moment';
var BenefitEmployeesEnrollmentComponent = /** @class */ (function () {
    function BenefitEmployeesEnrollmentComponent(modalService, apiService, manService, notificationService, mapService, appSettingsManageService) {
        this.modalService = modalService;
        this.apiService = apiService;
        this.manService = manService;
        this.notificationService = notificationService;
        this.mapService = mapService;
        this.appSettingsManageService = appSettingsManageService;
        this.isOptions = true;
        this.hasError = true;
        this.activeTab = 0;
        this.appConfig = appConfig;
        this.selectedEmployeesList = [];
        this.reasonList = [];
        this.data = [];
        this.updatedEmployeeInfo = [];
        this.isLoading = false;
        this.maxDate = new Date(appConfig.maxCorrectDate);
        this.minDate = new Date(appConfig.minCorrectDate);
        this.isDedBeforeBeneStartDt = false;
        this.isDedAfterBeneEndDt = false;
        this.warningBeforeStart = "Payroll Deduction Start Date is set before the Benefit Coverage Start Date";
        this.warningAfterEnd = "Payroll Deduction End Date is set after the Benefit Coverage End Date";
        this.enableBenefitDeduction = false;
        this.subscriptions = {};
        this.onEnrollmentCancel = new EventEmitter();
    }
    Object.defineProperty(BenefitEmployeesEnrollmentComponent.prototype, "hasValidPlan", {
        get: function () {
            if (this.calcMethod.isFlat) {
                return this.hasFlatEmpContribution && this.hasFlatErContribution;
            }
            else if (this.calcMethod.is401K) {
                return this.hasOptions;
            }
            else if (this.calcMethod.isFormula) {
                return this.hasFormula;
            }
            else if (this.calcMethod.isOptionsRates) {
                return this.hasOptions;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeesEnrollmentComponent.prototype, "hasFlatEmpContribution", {
        get: function () {
            return _.isNumber(this.tierDetails.empContribution);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeesEnrollmentComponent.prototype, "hasFlatErContribution", {
        get: function () {
            return _.isNumber(this.tierDetails.erContribution);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeesEnrollmentComponent.prototype, "hasOptions", {
        get: function () {
            return !_.isNil(this.tierDetails.options) && _.size(this.tierDetails.options) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeesEnrollmentComponent.prototype, "hasFormula", {
        get: function () {
            return this.coverageDefined && this.formulaEmployeeContributionDefined && this.formulaEmployerContributionDefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeesEnrollmentComponent.prototype, "formulaEmployeeContributionDefined", {
        get: function () {
            if (this.tierDetails && this.tierDetails.empFormula) {
                if (this.tierDetails.empFormula.isFixedCalcType && _.gte(this.tierDetails.empFormula.fixedAmount, 0)) {
                    return true;
                }
                else if (this.tierDetails.empFormula.isFormulaCalcType) {
                    return !_.isNil(this.tierDetails.empFormula.expression) && _.size(this.tierDetails.empFormula.expression) > 0;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeesEnrollmentComponent.prototype, "formulaEmployerContributionDefined", {
        get: function () {
            if (this.tierDetails && this.tierDetails.erFormula) {
                if (this.tierDetails.erFormula.isFixedCalcType && _.gte(this.tierDetails.erFormula.fixedAmount, 0)) {
                    return true;
                }
                else if (this.tierDetails.erFormula.isFormulaCalcType) {
                    return !_.isNil(this.tierDetails.erFormula.expression) && _.size(this.tierDetails.erFormula.expression) > 0;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeesEnrollmentComponent.prototype, "coverageDefined", {
        get: function () {
            if (this.tierDetails && this.tierDetails.coverageFormula) {
                if (this.tierDetails.coverageFormula.isFixedCalcType && _.gte(this.tierDetails.coverageFormula.fixedAmount, 0)) {
                    return true;
                }
                else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
                    return !_.isNil(this.tierDetails.coverageFormula.expression) && _.size(this.tierDetails.coverageFormula.expression) > 0;
                }
                else if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
                    return true;
                }
                else if (this.tierDetails.coverageFormula.isAnyValueMaxCapCalcType && _.gt(this.tierDetails.coverageFormula.maxCap, 0)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return false;
            ;
        },
        enumerable: true,
        configurable: true
    });
    BenefitEmployeesEnrollmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSettings();
        this.manService.changeMode(false);
        this.calcMethod = new BenefitDetailsCalcMethod(this.method, this.method, this.method);
        this.setBenefitPayrollDedDates();
        this.updatedEmployeeInfo = this.mapService.mapEligibleToEnrolledEmployees(this.selectedEmployees);
        this.subscriptions.saveButtonState = this.manService.subscribeToIsChangeOccured(function (hasError) {
            _this.hasError = hasError;
        });
        this.subscriptions.emloyeeDetails = this.manService.subscribeToUpdatedEmployeeData(function (updatedEmployees) {
            _this.updatedEmployeeInfo = updatedEmployees;
        });
    };
    BenefitEmployeesEnrollmentComponent.prototype.ngOnDestroy = function () {
    };
    BenefitEmployeesEnrollmentComponent.prototype.onCancel = function (askConfirmation) {
        var _this = this;
        if (!askConfirmation) {
            this.onEnrollmentCancel.emit();
            this.manService.getBackToDetailsPage();
        }
        else if (askConfirmation) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            options.buttonOKtext = 'Yes';
            options.buttonCanceltext = 'No';
            ConfirmDialog2Component.openDialog('Confirmation', 'Are you sure you want to cancel? You will lose all unsaved selections.', this.modalService, function (result) {
                if (result) {
                    _this.onEnrollmentCancel.emit();
                }
            }, options);
        }
    };
    BenefitEmployeesEnrollmentComponent.prototype.setBenefitPayrollDedDates = function () {
        this.benfitPayrollDeductionDates = new BenefitPayrollDeductionDates();
        var _a = this.benefitDetailsLine || {}, _b = _a.startDate, startDate = _b === void 0 ? null : _b, _c = _a.endDate, endDate = _c === void 0 ? null : _c, _d = _a.payrollDeductionEndDate, payrollDeductionEndDate = _d === void 0 ? null : _d;
        this.benfitPayrollDeductionDates.benefitDetailsLineStartDate = startDate;
        this.benfitPayrollDeductionDates.benefitDetailsLineEndDate = endDate;
        this.benfitPayrollDeductionDates.payrollDeductionStartDate = this.date;
        this.benfitPayrollDeductionDates.payrollDeductionEndDate = moment(_.isNull(this.benfitPayrollDeductionDates.payrollDeductionEndDate) ? this.benfitPayrollDeductionDates.payrollDeductionEndDate : endDate).startOf('day').toDate();
        this.isDedBeforeBeneStartDt = !!_.lt(this.benfitPayrollDeductionDates.payrollDeductionStartDate, this.date);
        this.isDedAfterBeneEndDt = !!_.gt(this.benfitPayrollDeductionDates.payrollDeductionEndDate, this.benefitDetailsLine.endDate);
        if (_.isObjectLike(this.mappedDeduction)) {
            this.minDedDate = this.mappedDeduction.startDate;
            this.maxDedDate = moment(this.mappedDeduction.endDate ? this.mappedDeduction.endDate : this.benefitDetailsLine.endDate).startOf('day').toDate();
        }
        if (_.gt(this.benfitPayrollDeductionDates.payrollDeductionEndDate, this.maxDedDate)) {
            this.benfitPayrollDeductionDates.payrollDeductionEndDate = this.maxDedDate;
        }
    };
    BenefitEmployeesEnrollmentComponent.prototype.onChangeStartDate = function () {
        this.isDedBeforeBeneStartDt = !!_.lt(this.benfitPayrollDeductionDates.payrollDeductionStartDate, this.benefitDetailsLine.startDate);
    };
    BenefitEmployeesEnrollmentComponent.prototype.onChangeEndDate = function () {
        this.isDedAfterBeneEndDt = !!_.gt(this.benfitPayrollDeductionDates.payrollDeductionEndDate, this.benefitDetailsLine.endDate);
    };
    BenefitEmployeesEnrollmentComponent.prototype.costCalculation = function () {
        var _this = this;
        if (this.calcMethod.isOptionsRates) {
            this.updatedEmployeeInfo.forEach(function (employee) {
                employee.employeeContributionAmount = employee.optionCode.empContribution;
                employee.employerContributionAmount = employee.optionCode.erContribution;
            });
        }
        if (this.calcMethod.isFlat && _.isObjectLike(this.tierDetails)) {
            this.updatedEmployeeInfo.forEach(function (employee) {
                if (!_.isFinite(employee.employeeContributionAmount)) {
                    employee.employeeContributionAmount = _this.tierDetails.empContribution;
                }
                if (!_.isFinite(employee.employerContributionAmount)) {
                    employee.employerContributionAmount = _this.tierDetails.erContribution;
                }
            });
        }
    };
    BenefitEmployeesEnrollmentComponent.prototype.setOptionCode = function () {
        if (this.calcMethod.isFormulaWithOption) {
            this.updatedEmployeeInfo.forEach(function (employee) {
                employee.optionCode = employee.optionCode;
            });
        }
    };
    BenefitEmployeesEnrollmentComponent.prototype.setformulaValues = function () {
        if ((this.calcMethod.isFormula || this.calcMethod.isFormulaWithOption) && _.isObjectLike(this.tierDetails)) {
            var _a = this.tierDetails, empFormula_1 = _a.empFormula, erFormula_1 = _a.erFormula, coverageFormula_1 = _a.coverageFormula;
            if (empFormula_1.isFixedCalcType || erFormula_1.isFixedCalcType || coverageFormula_1.isFixedCalcType) {
                _.forEach(this.updatedEmployeeInfo, function (employee) {
                    if (empFormula_1.isFixedCalcType) {
                        employee.employeeContributionAmount = empFormula_1.fixedAmount;
                    }
                    if (erFormula_1.isFixedCalcType) {
                        employee.employerContributionAmount = erFormula_1.fixedAmount;
                    }
                    if (coverageFormula_1.isFixedCalcType) {
                        employee.coverage = coverageFormula_1.fixedAmount;
                    }
                });
            }
        }
    };
    BenefitEmployeesEnrollmentComponent.prototype.setPayrollDeductionDates = function () {
        var _this = this;
        _.forEach(this.updatedEmployeeInfo, function (employee) {
            employee.payrollDeductionStartDate = _this.benfitPayrollDeductionDates.payrollDeductionStartDate;
            employee.payrollDeductionEndDate = _this.benfitPayrollDeductionDates.payrollDeductionEndDate;
        });
    };
    BenefitEmployeesEnrollmentComponent.prototype.enrollEmployees = function () {
        var _this = this;
        this.isLoading = true;
        this.setOptionCode();
        this.costCalculation();
        this.setformulaValues();
        this.setPayrollDeductionDates();
        this.apiService.enrollEmployeesBenefit(this.updatedEmployeeInfo, this.notesText, this.date, this.lineId, this.tierDetails.id, this.calcMethod.id)
            .then(function (res) {
            if (res.isSuccess) {
                _this.notificationService.success('Benefit Enrollment', 'Record saved successfully');
                _this.onCancel(false);
            }
            else {
                InfoDialogComponent.OpenDialog('Warning', 'Something went wrong', _this.modalService);
            }
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    BenefitEmployeesEnrollmentComponent.prototype.isActiveMenu = function (activeTab) {
        return (this.activeTab === activeTab) ? true : false;
    };
    BenefitEmployeesEnrollmentComponent.prototype.onClickTab = function (activeTab) {
        var prevActiveTab = this.activeTab;
        this.activeTab = activeTab;
        this.isOptions = this.isOptionDetails = this.isNotes = false;
        switch (activeTab) {
            case 0: {
                this.isOptions = true;
                break;
            }
            case 1: {
                this.isOptionDetails = true;
                break;
            }
            case 2: {
                this.isNotes = true;
                break;
            }
            default: {
                this.isOptions = true;
                break;
            }
        }
        if (prevActiveTab !== activeTab) {
            this.manService.changeMode(true);
        }
    };
    BenefitEmployeesEnrollmentComponent.prototype.hasCoverageChanges = function (res) {
        this.showCalculate = res;
    };
    BenefitEmployeesEnrollmentComponent.prototype.calculateOrEnroll = function () {
        if (this.showCalculate) {
            this.manService.calculateCoverage();
            this.showCalculate = false;
        }
        else {
            this.enrollEmployees();
        }
    };
    BenefitEmployeesEnrollmentComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.enableBenefitDeduction = config.isBenefitDeductionEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitEmployeesEnrollmentComponent.prototype, "subscriptions", void 0);
    return BenefitEmployeesEnrollmentComponent;
}());
export { BenefitEmployeesEnrollmentComponent };
