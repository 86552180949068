/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resend-opt-sms.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "@angular/forms";
import * as i5 from "../employee-grid/employee-grid.component.ngfactory";
import * as i6 from "../employee-grid/employee-grid.component";
import * as i7 from "../../services/employee-list/employee-list-common.service";
import * as i8 from "../../../../core/services/local-storage/local-storage.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../../common/services/column-settings/column-management.service";
import * as i11 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i12 from "./resend-opt-sms.component";
import * as i13 from "../../services/group-activities/group-activities-api.service";
import * as i14 from "../../../../organization/services/employee/employee-definitions-api.service";
import * as i15 from "../../../../common/services/modal/modal.service";
import * as i16 from "../../models/employeeActionDefinition";
import * as i17 from "../../../../state-model/models/org-level/org-level";
import * as i18 from "../../../../common/models/dialog-options";
import * as i19 from "../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i20 from "../../../../core/models/tokens";
var styles_ResendOptSmsComponent = [i0.styles];
var RenderType_ResendOptSmsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResendOptSmsComponent, data: {} });
export { RenderType_ResendOptSmsComponent as RenderType_ResendOptSmsComponent };
export function View_ResendOptSmsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { employeeGrid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 24, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 15, "slx-spinner", [["novalidate", ""]], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(4, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 10, "div", [["class", "header-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "header-opt-in-caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This is the message the employee will see."])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "header-opt-in-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 6, "textarea", [["class", "form-control text-area-sms"], ["cols", "40"], ["name", "smsText"], ["rows", "2"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.optInSMSText = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(12, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(14, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵted(-1, null, ["          "])), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "div", [["class", "employees-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "slx-employee-grid", [], null, [[null, "onEmployeesSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEmployeesSelect" === en)) {
        var pd_0 = (_co.employeesSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EmployeeGridComponent_0, i5.RenderType_EmployeeGridComponent)), i1.ɵdid(18, 245760, [[1, 4]], 0, i6.EmployeeGridComponent, [i1.ChangeDetectorRef, i7.EmployeeListCommonService, i8.LocalStorageService, i9.Router], { isMultiselect: [0, "isMultiselect"], fieldDefinition: [1, "fieldDefinition"], employees: [2, "employees"], isOptInStatus: [3, "isOptInStatus"] }, { onEmployeesSelect: "onEmployeesSelect" }), (_l()(), i1.ɵeld(19, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel "])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSend() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-paper-plane slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Send "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 4, 0, currVal_0); var currVal_8 = "smsText"; var currVal_9 = true; var currVal_10 = _co.optInSMSText; _ck(_v, 12, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = true; var currVal_12 = _co.fieldDefinition; var currVal_13 = _co.actionEmployees; var currVal_14 = true; _ck(_v, 18, 0, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 14).ngClassValid; var currVal_6 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_15 = !_co.canSend; _ck(_v, 23, 0, currVal_15); }); }
export function View_ResendOptSmsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-resend-opt-sms", [["class", "component"]], null, null, null, View_ResendOptSmsComponent_0, RenderType_ResendOptSmsComponent)), i1.ɵprd(4608, null, i10.ColumnManagementService, i10.ColumnManagementService, [i11.ColumnSettingsStorageService]), i1.ɵdid(2, 114688, null, 0, i12.ResendOptSmsComponent, [i13.GroupActivitiesApiService, i14.EmployeeDefinitionsApiService, i15.ModalService, i16.EmployeeActionDefinition, i17.OrgLevel, i18.DialogOptions2, i19.NotificationsService, i20.EMPLOYEES_ID_TOKEN, i20.FILTERED_EMPLOYEES_TOKEN], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ResendOptSmsComponentNgFactory = i1.ɵccf("slx-resend-opt-sms.component", i12.ResendOptSmsComponent, View_ResendOptSmsComponent_Host_0, {}, {}, []);
export { ResendOptSmsComponentNgFactory as ResendOptSmsComponentNgFactory };
