<div *ngIf="!miniProfile" class="swap-flex-horizontal swap-profile-container">
    <div *ngIf="profileExists" class="image-holder">
        <img class="rounded-image avatar" [employeeThumbinalSrc]="profile.id" alt="Profile photo" />
    </div>
    <div *ngIf="profileExists" class="swap-profile-text swap-flex-vertical">
        <div class="swap-profile-name">{{profile.name}}</div>
        <div class="swap-date-container swap-flex-horizontal">
            <i class="fas fa-calendar" aria-hidden="true"></i>
            <div class="swap-profile-date">
              <span class="shift-label">Shift: </span>
              <span class="shift-date">{{profile.shiftDate | amDateFormat: appConfig.dateFullMonthFormat}} </span>
              <span class="shift-name">({{profile.shiftName}}) </span>
              <span class="unit-name">({{profile.unitName}})</span>
            </div>
        </div>
    </div>

    <div *ngIf="!profileExists" [ngClass]="colorStyle" class="swap-no-profile swap-flex-horizontal">
        <span class="swap-profile-icon fa-stack fa-2x">
            <i class="fas fa-users fa-stack-1x" aria-hidden="true"></i>
            <i class="fal fa-circle fa-stack-2x" aria-hidden="true"></i>
        </span>
        <div class="swap-profile-name swap-no-profile-label">Waiting for someone to accept</div>
    </div>
</div>

<div *ngIf="miniProfile" class="swap-flex-vertical swap-profile-mini-container">
    <div *ngIf="profileExists">
        <img class="rounded-image mini-avatar" [employeeThumbinalSrc]="profile.id" alt="Profile photo"/>
    </div>
    <div *ngIf="profileExists" class="swap-profile-mini-name">
        {{profile.name}}
    </div>

    <div *ngIf="!profileExists" [ngClass]="colorStyle" class="swap-no-profile swap-flex-vertical">
        <span class="swap-profile-mini-icon fa-stack fa-2x">
            <i class="fas fa-users fa-stack-1x" aria-hidden="true"></i>
            <i class="fal fa-circle fa-stack-2x" aria-hidden="true"></i>
        </span>
        <div class="swap-profile-mini-name">Waiting for someone to accept</div>
    </div>
</div>
