
<slx-spinner [show]="isLoading">
  <div class="slx-high-box leave-management">
    <div class="slx-content-toolbar-indents">
      <slx-wc-roster-toolbar></slx-wc-roster-toolbar>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <slx-wc-osha-budget-tracker-grid></slx-wc-osha-budget-tracker-grid>
    </div>
  </div>
</slx-spinner>
