import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LookupApiService, LookupService, LookupType } from './../../../../app/organization';
import * as moment from 'moment';
import { ScheduleRotationsManagementService } from '../../services/scheduled-rotation-template/schedule-rotations-management.service';
import { mutableSelect, unsubscribe } from './../../../../app/core/decorators';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
var ScheduledRotationsComponent = /** @class */ (function () {
    function ScheduledRotationsComponent(lookupApi, manService, lookupService) {
        this.lookupApi = lookupApi;
        this.manService = manService;
        this.lookupService = lookupService;
        this.date = new Date();
        this.cycles = [];
        this.dates = [];
        this.dateFrom = moment().toDate();
    }
    ScheduledRotationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
            _this.orgLevel = orgLevel;
            _this.getScheduleCycles();
            _this.getScheduleRotationPermission();
            _this.loadFilterLookups(_this.orgLevelId);
            _this.getDefaultFilters();
        });
    };
    ScheduledRotationsComponent.prototype.getScheduleRotationPermission = function () {
        this.manService.getScheduleRotationPermission(this.orgLevelId);
    };
    ScheduledRotationsComponent.prototype.getScheduleCycles = function () {
        var _this = this;
        this.lookupApi.getScheduleCycles(this.orgLevel.id).then(function (data) {
            _this.manService.cycles = data;
            if (_this.manService.cycles.length > 0) {
                var today = moment().startOf('day');
                _this.manService.firstCycle = _this.findPayCycle(_this.manService.cycles, today.toDate());
                _this.firstCycle = _this.findPayCycle(_this.manService.cycles, today.toDate());
                _this.manService.dates = _this.manService.enumerateDaysBetweenDates(_this.firstCycle.startDate, _this.firstCycle.endDate);
                _this.firstCycleStart = _this.firstCycle ? _this.firstCycle.startDate : today;
                _this.manService.dateFrom = _this.firstCycleStart.toDate();
                _this.startDate = moment(_this.firstCycle.startDate).format('MM/DD/YYYY');
                _this.endDate = moment(_this.firstCycle.endDate).format('MM/DD/YYYY');
                _this.manService.payCycle.next(_this.firstCycle);
            }
        });
    };
    ScheduledRotationsComponent.prototype.findPayCycle = function (cycles, date) {
        var currentDate = moment(date).startOf('day');
        var selectedCycle = _.find(cycles, function (cycle) {
            return currentDate.isSameOrAfter(cycle.startDate) && currentDate.isSameOrBefore(cycle.endDate);
        });
        return selectedCycle;
    };
    ScheduledRotationsComponent.prototype.loadFilterLookups = function (selectedOrgLevelId) {
        var _this = this;
        var positionGroupsPromise = this.lookupService.getLookup({
            lookupType: LookupType.positionGroups,
            orgLevelId: selectedOrgLevelId,
        });
        var positionsPromise = this.lookupService.getLookup({
            lookupType: LookupType.position,
            orgLevelId: selectedOrgLevelId,
        });
        var unitsPromise = this.lookupService.getLookup({
            lookupType: LookupType.locationUnit,
            orgLevelId: selectedOrgLevelId,
        });
        var shiftsPromise = this.lookupService.getLookup({
            lookupType: LookupType.shiftGroupDefinition,
            orgLevelId: selectedOrgLevelId,
        });
        Promise.all([positionGroupsPromise, positionsPromise, unitsPromise, shiftsPromise]).then(function (values) {
            _this.positionGroupLookup = values[0], _this.positionLookup = values[1], _this.unitsLookup = values[2], _this.shiftsLookup = values[3];
            _this.getUnitsLookupData(_this.unitsLookup);
            _this.manService.positionLookup = _this.positionLookup;
            _this.manService.positionGroupLookup = _this.positionGroupLookup;
        });
    };
    ScheduledRotationsComponent.prototype.getUnitsLookupData = function (data) {
        var samData = [];
        data.items.forEach(function (x, ind) {
            samData.push({
                id: x.name === 'FL' ? 100001 : x.id,
                name: x.name
            });
        });
        this.unitsData = samData;
    };
    ScheduledRotationsComponent.prototype.getDefaultFilters = function () {
        this.manService.filterRotationsRecord = this.manService.scheduleRotationApiService.getSRTDefaultFilterOption();
        this.manService.filterRotationsRecord$.next(this.manService.scheduleRotationApiService.getSRTDefaultFilterOption());
        return this.manService.filterRotationsRecord;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduledRotationsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduledRotationsComponent.prototype, "orgLevelSubscription", void 0);
    return ScheduledRotationsComponent;
}());
export { ScheduledRotationsComponent };
