<div class="attachments">
  <div class="flex align-items__center">
    <label class="attachments__label">Upload Files</label>
    <span class="attachments__btn-box">
      <button class="slx-button slx-with-icon" type="button" [disabled]="!canEditRequest">
        <i aria-hidden="true" class="fal fa-paperclip slx-button__icon"></i>
        <span class="slx-button__text">Browse...</span>
      </button>
      <input class="attachments__input" type="file" *ngIf="canEditRequest" (change)="onFileChange($event)"
        (click)="onResetErrors($event)" [accept]="acceptedFileTypesInputFormat" [disabled]="!canEditRequest" multiple />
    </span>
    <span class="flex__grow attachments__types">Allowed file types: {{ acceptedFileTypesReadable }}. {{ maxFileSize }}
      max</span>
  </div>
  <p class="slx-error-block attachments__error" *ngIf="errors">
    <span *ngIf="errors.maxFiles">You selected more than {{ maxFiles }} files</span>
    <span *ngIf="errors.maxFileSize">You exceeded maximum limit for file</span>
    <span *ngIf="errors.fileType">Selected file has unsupported extension</span>
  </p>
  <ng-container *ngIf="hasFiles">
    <div class="attachments__attached">
      <p class="flex align-items__center attachments__file" *ngFor="let file of files">
        <i class="fal attachments__i-file" [class.fa-file-download]="isDownloadable(file)"
          [class.fa-file]="!isDownloadable(file)" (click)="onDownloadFile(file)" aria-hidden="true"></i>
        <span class="flex__grow attachments__name" [class.download-link]="isDownloadable(file)"
          (click)="onDownloadFile(file)">{{ file.name }}</span>
        <i class="fal fa-trash-alt attachments__i-delete" *ngIf="canEditRequest" title="Delete file immediately"
          aria-hidden="true" [popover]="confirmDeleting"></i>
        <popover-content #confirmDeleting title="Are you sure?" placement="auto bottom">
          <div class="align-position">
            <button type="button" [disabled]="!canEditRequest" class="slx-button slx-blue slx-narrow slx-margin-r"
              (click)="onClickDelete(true, confirmDeleting, file)">Delete</button>
            <button type="button" class="slx-button slx-narrow"
              (click)="onClickDelete(false, confirmDeleting, file)">Cancel</button>
          </div>

        </popover-content>
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="!hasFiles">
    <p class="attachments__no-files">No attached files</p>
  </ng-container>
</div>
