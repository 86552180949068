/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roles-menu-access-toggler.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/directives/slx-tooltip/slx-tooltip.directive";
import * as i3 from "@angular/common";
import * as i4 from "./roles-menu-access-toggler.component";
var styles_RolesMenuAccessTogglerComponent = [i0.styles];
var RenderType_RolesMenuAccessTogglerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RolesMenuAccessTogglerComponent, data: {} });
export { RenderType_RolesMenuAccessTogglerComponent as RenderType_RolesMenuAccessTogglerComponent };
function View_RolesMenuAccessTogglerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["tipPosition", "bottom"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "Menu Item is linked to Action '", _co.linkedRightName, "'. This Action will be edited also."); var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_RolesMenuAccessTogglerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "toggler"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAccessClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesMenuAccessTogglerComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "i", [["aria-hidden", "true"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(6, { "far": 0, "fa-check-square": 1, "fa-square": 2, "fab fa-delicious": 3, "dirty": 4 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkedRightName; _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, ((_co.rowItem.mapByRole[_co.roleId].access === "enabled") || (_co.rowItem.mapByRole[_co.roleId].access === "disabled")), (_co.rowItem.mapByRole[_co.roleId].access === "enabled"), (_co.rowItem.mapByRole[_co.roleId].access === "disabled"), (_co.rowItem.mapByRole[_co.roleId].access === "mixed"), _co.rowItem.dirtyByRole[_co.roleId]); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_RolesMenuAccessTogglerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-roles-menu-access-toggler", [], null, null, null, View_RolesMenuAccessTogglerComponent_0, RenderType_RolesMenuAccessTogglerComponent)), i1.ɵdid(1, 573440, null, 0, i4.RolesMenuAccessTogglerComponent, [], null, null)], null, null); }
var RolesMenuAccessTogglerComponentNgFactory = i1.ɵccf("slx-roles-menu-access-toggler", i4.RolesMenuAccessTogglerComponent, View_RolesMenuAccessTogglerComponent_Host_0, { rowItem: "rowItem", roleId: "roleId" }, { roleToggled: "roleToggled" }, []);
export { RolesMenuAccessTogglerComponentNgFactory as RolesMenuAccessTogglerComponentNgFactory };
