import { Injectable, Optional } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody, Meta, FieldsMeta, SlxHttpRequest } from '../../../../core/models/index';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeSectionsPersonalMapService } from './employee-section-personal-map.service';
import {
  EmployeeSectionsPersonal,
  EmployeeSectionsProfile,
  EmployeeSectionsContacts,
  EmployeeSectionsLicense,
  IEmployeeSectionsPersonal,
  IEmployeeSectionsProfile,
  IEmployeeSectionsContacts,
  IEmployeeSectionsLicense,
  IEmployeeSectionsLicenses,
  EmployeeSectionsLicenses,
  EmployeeSectionsPhysicals,
  IEmployeeSectionsPhysicalGroup,
  IEmployeeSectionsPhysicals,
  IEmployeeSectionsDocuments,
  EmployeeSectionsDocuments,
  EmployeeDocument,
  IEmployeeSectionsDocument,
  ISaveEmployeeDocumentResponse,
  IEmployeeSectionsBackgroundChecks,
  EmployeeSectionsBackgroundCheck,
  IEmployeeSectionsCertification,
  EmployeeSectionsCertification,
  IEmployeeSectionsCertifications,
  IEmployeeSectionsEducations,
  EmployeeSectionsEducation,
  IEmployeeSubsectionWarnings,
  EmployeeSubsectionWarnings,
  IEmployeeWarningExtended,
  EmployeeWarningExtended
} from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { EmployeeSectionsEmergencyContacts, IEmployeeSectionsEmergencyContacts } from '../../models/employee-sections-personal/employee-sections-emergency-contacts';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { IServerValidationResult } from '../../../../common/validators/common-validators-models';
import { LookupApiService } from '../../../../organization/services';
import { AgencyCertificate, AgencyCertificates, IAgencyCertificate, IAgencyCertificates } from '../../models/employee-sections-personal/employee-sections-agency-certificate';

@Injectable()
export class EmployeeSectionsPersonalApiService {
  private apiUtilService: ApiUtilService;
  private urlParamsService: UrlParamsService;

  constructor(
    private lookupService: LookupApiService,
    private employeeSectionsPersonalMapService: EmployeeSectionsPersonalMapService,
    @Optional() apiUtilService: ApiUtilService,
    @Optional() urlParamsService: UrlParamsService) {
    this.apiUtilService = apiUtilService;
    this.urlParamsService = urlParamsService;
  }

  public async getPersonalSection(employeeId: number): Promise<EmployeeSectionsPersonal> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getPersonalApiRoot(employeeId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let veteranStatuses = await this.lookupService.getVeteranStatuses();

    let promise: Promise<EmployeeSectionsPersonal> = this.apiUtilService.request<IEmployeeSectionsPersonal, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsPersonal, Meta>) => {
        return this.employeeSectionsPersonalMapService.mapToSectionsPersonal(response.data,  veteranStatuses);
      });

    return promise;
  }

  public getPersonalPhysicals(employeeId: number): Promise<EmployeeSectionsPhysicals> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.physicals}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsPhysicals> = this.apiUtilService.request<IEmployeeSectionsPhysicalGroup[], FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsPhysicalGroup[], FieldsMeta>) =>
        this.employeeSectionsPersonalMapService.mapToPhysicals(response.data, response.meta)
      );
    return promise;
  }

  public setPersonalPhysicals(employeeId: number, physicals: EmployeeSectionsPhysicals, effectiveDate?: Date): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(physicals, 'physicals');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.physicals}`;
    let dphysicals: IEmployeeSectionsPhysicals = this.employeeSectionsPersonalMapService.mapToPhysicalsDto(physicals);
    let body: any = {
      physicalsSubsection: dphysicals,
      effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate),
      metadata: physicals.fieldsMeta
    };
    let params: HttpParams = this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body, {
      params: params
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public getPersonalLicenses(employeeId: number): Promise<EmployeeSectionsLicenses> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.licenses}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsLicenses> = this.apiUtilService.request<IEmployeeSectionsLicenses, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsLicenses, FieldsMeta>) =>
        this.employeeSectionsPersonalMapService.mapToLicenses(response.data, response.meta)
      );
    return promise;
  }

  public setPersonalLicenses(employeeId: number, licenses: EmployeeSectionsLicense[], fieldsMeta: FieldsMeta): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(licenses, 'licenses');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.licenses}`;
    let dlicenses: IEmployeeSectionsLicense[] = this.employeeSectionsPersonalMapService.mapToLicensesDto(licenses);
    let body: any = {
      licensesSubsection: { licenses: dlicenses },
      metadata: fieldsMeta
    };

    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public getPersonalEmergencyContacts(employeeId: number): Promise<EmployeeSectionsEmergencyContacts> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.emergencyContacts}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsEmergencyContacts> = this.apiUtilService.request<IEmployeeSectionsEmergencyContacts, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsEmergencyContacts, FieldsMeta>) =>
        this.employeeSectionsPersonalMapService.mapToEmergencyContacts(response.data, response.meta)
      );
    return promise;
  }
  public getAgencyCertificateDetails(employeeId: number): Promise<AgencyCertificate[]> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.dnr}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<AgencyCertificate[]> = this.apiUtilService.request<IAgencyCertificates, FieldsMeta>(request)
      .then((response: ResponseBody<IAgencyCertificates, FieldsMeta>) =>
        this.employeeSectionsPersonalMapService.mapToAgencyCertificate(response.data)
      );
    return promise;
    
  }
  public setPersonalEmergencyContacts(employeeId: number, emergencyContacts: EmployeeSectionsEmergencyContacts): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(emergencyContacts, 'emergencyContacts');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.emergencyContacts}`;
    let demergencyContacts: IEmployeeSectionsEmergencyContacts = this.employeeSectionsPersonalMapService.mapToEmergencyContactsDto(emergencyContacts);

    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, {
      emergencyContactsSubsection: demergencyContacts,
      metadata: emergencyContacts.fieldsMeta
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);

    return promise;
  }

  public getPersonalContacts(employeeId: number): Promise<EmployeeSectionsContacts> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.contacts}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsContacts> = this.apiUtilService.request<IEmployeeSectionsContacts, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsContacts, FieldsMeta>) =>
        this.employeeSectionsPersonalMapService.mapToContacts(response.data, response.meta)
      );
    return promise;
  }

  public setPersonalContacts(employeeId: number, contacts: EmployeeSectionsContacts, effectiveDate?: Date): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(contacts, 'contacts');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.contacts}`;
    let dcontacts: IEmployeeSectionsContacts = this.employeeSectionsPersonalMapService.mapToContactsDto(contacts);
    let body: any = {
      contactsInformationSubsection: dcontacts,
      metadata: contacts.fieldsMeta
    };
    let params: HttpParams = this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body, {
      params: params
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public async getPersonalProfile(employeeId: number): Promise<EmployeeSectionsProfile> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.profile}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let veteranStatuses = await this.lookupService.getVeteranStatuses();

    let promise: Promise<EmployeeSectionsProfile> = this.apiUtilService.request<IEmployeeSectionsProfile, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsProfile, FieldsMeta>) =>
        this.employeeSectionsPersonalMapService.mapToProfile(response.data, response.meta, veteranStatuses)
      );
    return promise;
  }

  public setPersonalProfile(employeeId: number, profile: EmployeeSectionsProfile, effectiveDate?: Date): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(profile, 'profile');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.profile}`;
    let dprofile: IEmployeeSectionsProfile = this.employeeSectionsPersonalMapService.mapToProfileDto(profile);
    let body: StringMap<any> = {
      profileSubsection: dprofile,
      metadata: profile.fieldsMeta
    };
    let params: HttpParams = this.urlParamsService.convertToParams({ effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body, {
      params: params
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public getPersonalDocuments(employeeId: number): Promise<EmployeeSectionsDocuments> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.documents}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<EmployeeSectionsDocuments> = this.apiUtilService.request<IEmployeeSectionsDocuments, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsDocuments, FieldsMeta>) =>
        this.employeeSectionsPersonalMapService.mapToDocuments(response.data, response.meta)
      );
    return promise;
  }

  public getDocumentFile(documentId: number): Promise<FileBlobResponse> {
    Assert.isNotNull(documentId, 'documentId');

    const url: string = `${this.getDocumentApiRoot()}/${documentId}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<FileBlobResponse> = this.apiUtilService.requestForFile(request)
      .then((response: FileBlobResponse) => response);
    return promise;
  }

  public addDocument(document: EmployeeDocument, employeeId: number): Promise<any> {
    Assert.isNotNull(document, 'document');
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = this.getDocumentApiRoot(employeeId);
    let formData: FormData = this.employeeSectionsPersonalMapService.mapToDocumentDtoFormData(document);
    let request: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, formData);
    request.autoContentType = true;

    let promise: Promise<any> = this.apiUtilService.requestNew<ISaveEmployeeDocumentResponse, FieldsMeta>(request, false)
      .then((response: ResponseBody<ISaveEmployeeDocumentResponse, Meta>) => response.data);
    return promise;
  }

  public editDocument(document: EmployeeDocument, employeeId: number): Promise<any> {
    Assert.isNotNull(document, 'document');
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = this.getDocumentApiRoot(employeeId);
    let formData: FormData = this.employeeSectionsPersonalMapService.mapToDocumentDtoFormData(document);
    let request: SlxHttpRequest<any> = this.urlParamsService.requestPut(url, formData);
    request.autoContentType = true;

    let promise: Promise<any> = this.apiUtilService.requestNew<ISaveEmployeeDocumentResponse, FieldsMeta>(request, false)
      .then((response: ResponseBody<ISaveEmployeeDocumentResponse, Meta>) => response.data);
    return promise;
  }

  public deleteDocument(documentId: number, employeeId: number): Promise<any> {
    Assert.isNotNull(documentId, 'documentId');
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getDocumentApiRoot(employeeId)}/${documentId}`;
    let request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public async getBackgroundChecks(employeeId) : Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = this.getBackgroundCheckApiRoot(employeeId);
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<any> = this.apiUtilService.request<IEmployeeSectionsBackgroundChecks, FieldsMeta>(request)
    .then((response: ResponseBody<IEmployeeSectionsBackgroundChecks, FieldsMeta>) =>
      this.employeeSectionsPersonalMapService.mapToBackgroundChecks(response.data,response.meta)
      );
      return promise;
    }

  public async setBackgroundCheck(backgroundCheck: EmployeeSectionsBackgroundCheck, employeeId: number): Promise<any> {
    Assert.isNotNull(backgroundCheck, 'backgroundCheck');
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = this.getBackgroundCheckApiRoot(employeeId);
    let certification : EmployeeSectionsBackgroundCheck = this.employeeSectionsPersonalMapService.mapToBackgroundCheck(backgroundCheck);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, certification);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public async deleteBackgroundCheck(backgroundCheck: EmployeeSectionsBackgroundCheck, employeeId: number ): Promise<any> {
    Assert.isNotNull(backgroundCheck, 'certificate');
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = this.getBackgroundCheckApiRoot(employeeId);
    const request = this.urlParamsService.createDeleteRequest(url,undefined, backgroundCheck);
    return this.apiUtilService.request<EmployeeSectionsBackgroundCheck, Meta>(request);
  }

  public async getCertifications(employeeId) : Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = this.getCertificationsApiRoot(employeeId);
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<any> = this.apiUtilService.request<IEmployeeSectionsCertifications, FieldsMeta>(request)
    .then((response: ResponseBody<IEmployeeSectionsCertifications, FieldsMeta>) =>
      this.employeeSectionsPersonalMapService.mapToCertifications(response.data,response.meta)
    );
    return promise;
  }

  public async setCertificate(certificate: EmployeeSectionsCertification, employeeId: number): Promise<any> {
    Assert.isNotNull(certificate, 'certificate');
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = this.getCertificationsApiRoot(employeeId);
    let certification : EmployeeSectionsCertification = this.employeeSectionsPersonalMapService.mapToCertificate(certificate);

    let request: HttpRequest<any> = new HttpRequest('PUT', url, certification);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;

  }

  public async deleteCertificate(certificate: EmployeeSectionsCertification, employeeId: number ): Promise<any> {
    Assert.isNotNull(certificate, 'certificate');
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = this.getCertificationsApiRoot(employeeId);
    const request = this.urlParamsService.createDeleteRequest( url, undefined, certificate);

    return this.apiUtilService.request<EmployeeSectionsCertification, Meta>(request);
  }

  public async getEducations(employeeId) : Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = this.getEducationsApiRoot(employeeId);
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<any> = this.apiUtilService.request<IEmployeeSectionsEducations, FieldsMeta>(request)
    .then((response: ResponseBody<IEmployeeSectionsEducations, FieldsMeta>) =>
      this.employeeSectionsPersonalMapService.mapToEducations(response.data,response.meta)
      );
      return promise;
    }

  public async setEducation(education: EmployeeSectionsEducation, employeeId: number): Promise<any> {
    Assert.isNotNull(education, 'education');
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = this.getEducationsApiRoot(employeeId);
    let edu : EmployeeSectionsEducation = this.employeeSectionsPersonalMapService.mapToEducation(education);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, edu);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  public async deleteEducation(education: EmployeeSectionsEducation, employeeId: number ): Promise<any> {
    Assert.isNotNull(education, 'education');
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = this.getEducationsApiRoot(employeeId);
    const request = this.urlParamsService.createDeleteRequest(url,undefined, education);
    return this.apiUtilService.request<EmployeeSectionsEducation, Meta>(request);
  }

  public async getEmployeeWarnings(employeeId: number): Promise<EmployeeSubsectionWarnings> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url = this.getEmpWarningApiRoot(employeeId);
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<IEmployeeSubsectionWarnings, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSubsectionWarnings, FieldsMeta>) =>
         this.employeeSectionsPersonalMapService.mapToSubsectionWarnings(response.data, response.meta)
       );
  }

  public async getEmployeeWarning(warningId: number): Promise<EmployeeWarningExtended> {
    const url = this.getWarningApiRoot(warningId);
    const request = this.urlParamsService.createGetRequest(url);

     return this.apiUtilService.request<IEmployeeWarningExtended, Meta>(request)
       .then((response: ResponseBody<IEmployeeWarningExtended, Meta>) =>
         this.employeeSectionsPersonalMapService.mapToWarningExtended(response.data)
     );
  }

  public async getEmployeeWarningTemplate(empId: number): Promise<EmployeeWarningExtended> {
    const url = this.getNewEmpWarningApiRoot(empId);
    const request = this.urlParamsService.createGetRequest(url);

     return this.apiUtilService.request<IEmployeeWarningExtended, Meta>(request)
       .then((response: ResponseBody<IEmployeeWarningExtended, Meta>) =>
         this.employeeSectionsPersonalMapService.mapToWarningExtended(response.data)
       );
  }

  public async downloadWarning(warningId: number): Promise<FileBlobResponse> {
    const url = this.getDownloadWarningApiRoot(warningId);
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public async addEmployeeWarning(warning: EmployeeWarningExtended, empId: number): Promise<EmployeeWarningExtended> {
    const url = this.getEmpWarningApiRoot(empId);
    const request = this.urlParamsService.createPostRequest(url, this.employeeSectionsPersonalMapService.mapToDtoWarningExtended(warning));

    return this.apiUtilService.request<IEmployeeWarningExtended, Meta>(request)
      .then((response: ResponseBody<IEmployeeWarningExtended, Meta>) =>
        this.employeeSectionsPersonalMapService.mapToWarningExtended(response.data)
      );
  }

  public async editEmployeeWarning(warning: EmployeeWarningExtended, empId: number): Promise<EmployeeWarningExtended> {
    const url = this.getEmpWarningApiRoot(empId);
    const request = this.urlParamsService.createPutRequest(url, this.employeeSectionsPersonalMapService.mapToDtoWarningExtended(warning));

    return this.apiUtilService.request<IEmployeeWarningExtended, Meta>(request)
      .then((response: ResponseBody<IEmployeeWarningExtended, Meta>) =>
        this.employeeSectionsPersonalMapService.mapToWarningExtended(response.data)
      );
  }
  public saveAgencyCertificate(agencyCertificate, employeeId) : Promise<any> {
    const url: string = `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.dnr}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, agencyCertificate);
    let promise: Promise<AgencyCertificate> = this.apiUtilService.request<AgencyCertificate, Meta>(request)
      .then((response: ResponseBody<AgencyCertificate, Meta>) => response.data);
      return promise;
  }

  public async deleteEmployeeWarning(warningId: number): Promise<EmployeeWarningExtended> {
    const url = this.getWarningApiRoot(warningId);
    const request = this.urlParamsService.createDeleteRequest(url);

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.employees.root}`;
  }

  private getSectionsApiRoot(employeeId: number): string {
    return `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.sections.root}`;
  }

  private getPersonalApiRoot(employeeId: number): string {
    return `${this.getSectionsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.root}`;
  }

  private getBackgroundCheckApiRoot(employeeId: number): string {
    return `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.backgroundcheck}`;
  }

  private getDocumentApiRoot(employeeId: number = null): string {
    let url = `${this.getApiRoot()}`;

    if (employeeId) {
      url += `/${employeeId}`;
    }

    url += `/${employeeSectionsConfig.api.employees.documents}`;

    return url;
  }

  private getCertificationsApiRoot(employeeId: number): string {
    return `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.certifications}`;
  }

  private getEducationsApiRoot(employeeId: number): string {
    return `${this.getPersonalApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.personal.education}`;
  }

  private getWarningApiRoot(warningId: number): string {
    return `${this.getApiRoot()}/${employeeSectionsConfig.api.employees.sections.personal.warnings}/${warningId}`;
  }

  private getEmpWarningApiRoot(empId: number): string {
    return `${this.getApiRoot()}/${empId}/${employeeSectionsConfig.api.employees.sections.personal.warnings}`;
  }

  private getNewEmpWarningApiRoot(empId: number): string {
    return `${this.getApiRoot()}/${empId}/${employeeSectionsConfig.api.employees.sections.personal.warnings}/${employeeSectionsConfig.api.employees.sections.personal.newWarning}`;
  }

  private getDownloadWarningApiRoot(warningId: number): string {
    return `${this.getWarningApiRoot(warningId)}/${employeeSectionsConfig.api.employees.sections.personal.downloadWarning}`;
  }

}
