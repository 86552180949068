import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { Subscription } from 'rxjs/Subscription';
import { process, orderBy } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { unsubscribe } from '../../../../../app/core/decorators/index';
import { IdealScheduleConfig } from '../../../models/ideal-schedule/index';
import { IdealScheduleHelperService } from '../../../services/ideal-schedule/ideal-schedule.helper.service';
import { IdealScheduleStateService } from '../../../services/ideal-schedule/ideal-schedule.state.service';
import { CalendarDataService } from '../../../../core/services/index';
import { IdealSchedulePositionRange } from '../../../models/ideal-schedule/ideal-schedule-position-range';
import { screenUtils } from '../../../../common/utils/index';
var IdealScheduleGridControlComponent = /** @class */ (function () {
    function IdealScheduleGridControlComponent(calendarDataService, scheduleHelper, stateService, changeDetector) {
        this.calendarDataService = calendarDataService;
        this.scheduleHelper = scheduleHelper;
        this.stateService = stateService;
        this.changeDetector = changeDetector;
        this.state = {
            showShiftColumn: false,
            showUnitColumn: false,
            isShiftGroup: false,
            maxHourLimit: IdealScheduleConfig.ShiftGroupInputMaxHours,
            filterGroup: null
        };
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [];
    }
    Object.defineProperty(IdealScheduleGridControlComponent.prototype, "showShiftColumn", {
        set: function (show) {
            this.state.showShiftColumn = show;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleGridControlComponent.prototype, "showUnitColumn", {
        set: function (show) {
            this.state.showUnitColumn = show;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleGridControlComponent.prototype, "isShiftGroup", {
        set: function (isShiftGroup) {
            this.state.isShiftGroup = isShiftGroup;
            this.updateHourlyMaxLimit(isShiftGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleGridControlComponent.prototype, "filterGroup", {
        set: function (group) {
            this.state.filterGroup = group;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleGridControlComponent.prototype, "position", {
        get: function () {
            return this.stateService.selectedPosition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleGridControlComponent.prototype, "originalPosition", {
        get: function () {
            return this.stateService.originalPosition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleGridControlComponent.prototype, "weekDays", {
        get: function () {
            return this.calendarDataService.getWeekDaysList;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleGridControlComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gridState.state.sort = this.createSortState();
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.stateServiceChangedSubscription = this.stateService.changed$.subscribe(function () {
            _this.refreshGrid();
            _this.detectChanges();
        });
        this.stateServiceTotalHoursSubscription = this.stateService.totalHoursChanged$.subscribe(function () {
            _this.detectChanges();
        });
        this.refreshGrid();
    };
    IdealScheduleGridControlComponent.prototype.ngOnChanges = function (changes) {
        var rangeChange = changes['range'];
        if (rangeChange && !rangeChange.firstChange) {
            this.refreshGrid();
        }
    };
    IdealScheduleGridControlComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IdealScheduleGridControlComponent.prototype.isMobile = function () {
        return screenUtils.isMobile;
    };
    IdealScheduleGridControlComponent.prototype.detectChanges = function () {
        var _this = this;
        setTimeout(function () {
            _this.changeDetector.markForCheck();
        }, 0);
    };
    IdealScheduleGridControlComponent.prototype.createSortState = function () {
        var result = [];
        if (this.state.showShiftColumn) {
            result.push({ field: 'shift.startDate', dir: 'asc' });
            result.push({ field: 'shift.endDate', dir: 'asc' });
        }
        // if(this.state.showUnitColumn) {
        //   result.push({ field: 'unit.name', dir: 'asc' });
        // }
        return result;
    };
    IdealScheduleGridControlComponent.prototype.refreshGrid = function () {
        this.gridState.state.sort = this.createSortState();
        var result = this.getParLevels();
        this.gridState.view = process(orderBy(result, this.gridState.state.sort), {});
        this.detectChanges();
    };
    IdealScheduleGridControlComponent.prototype.getParLevels = function () {
        var _this = this;
        var result = _.values(this.range.parLevels);
        if (this.stateService.hideUnused) {
            result = _.filter(result, function (item) { return !item.isUnused(); });
        }
        if (this.state.filterGroup) {
            result = _.filter(result, function (item) { return item.shiftGroup.name === _this.state.filterGroup; });
        }
        return result;
    };
    IdealScheduleGridControlComponent.prototype.updateHourlyMaxLimit = function (isShiftGroup) {
        this.state.maxHourLimit = isShiftGroup ? IdealScheduleConfig.ShiftGroupInputMaxHours : IdealScheduleConfig.NonShiftGroupInputMaxHours;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleGridControlComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleGridControlComponent.prototype, "stateServiceChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleGridControlComponent.prototype, "stateServiceTotalHoursSubscription", void 0);
    return IdealScheduleGridControlComponent;
}());
export { IdealScheduleGridControlComponent };
