import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
var UserInfoComponent = /** @class */ (function () {
    function UserInfoComponent() {
    }
    UserInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSubscription = this.user$.subscribe(function (user) {
            if (user) {
                _this.userName = user.name;
            }
        });
    };
    UserInfoComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], UserInfoComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], UserInfoComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserInfoComponent.prototype, "userSubscription", void 0);
    return UserInfoComponent;
}());
export { UserInfoComponent };
