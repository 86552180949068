import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribe } from '../../../../../core/decorators/index';
import { OrganizationTreeContext, } from '../../../../../organization/models/index';
import { OrgLevelActions } from '../../../../../organization/actions/index';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/index';
import { EmployeeSectionNavigationService } from '../../../../../common/services/index';
import { OperationalConsoleApiService } from '../../../../services/index';
import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';
import { OperationalConsoleField, OperationalConsoleDialogParameters } from '../../../../../employee/employee-list/models/index';
import { OperationalConsoleDialogComponent } from '../../../../../employee/employee-list/components/index';
import { OperationalConsoleStateService } from '../../../../services/operational-console/operational-console-state.service';
import { ReportExportType } from '../../../../../reports/models/report-export-type';
var OperationalConsoleGridComponent = /** @class */ (function () {
    function OperationalConsoleGridComponent(stateService, apiService, modalService, orgLevelWatchService, orgLevelActions, router, route) {
        this.stateService = stateService;
        this.apiService = apiService;
        this.modalService = modalService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.orgLevelActions = orgLevelActions;
        this.router = router;
        this.route = route;
        this.pageSize = 30;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [{ field: 'date', dir: 'desc' }];
        this.employeeLinkClick = new EventEmitter();
    }
    OperationalConsoleGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filtersChangedSubscription = this.stateService.filtersChanged$.subscribe(function (value) {
            _this.refreshGrid(value);
        });
        this.actionExportSubscription = this.stateService.exportAction$.subscribe(function (exportType) {
            switch (exportType) {
                case ReportExportType.Excel:
                    _this.grid.saveAsExcel();
                    break;
                case ReportExportType.Pdf:
                    _this.grid.saveAsPDF();
                    break;
            }
            _this.refreshGrid(_this.stateService.entries);
        });
        this.employeeLinkClickSubscription = this.employeeLinkClick.subscribe(function (empId) {
            var navService = new EmployeeSectionNavigationService(_this.router, _this.route);
            navService.NavigateToEmployeeSections(empId, true);
        });
    };
    OperationalConsoleGridComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    OperationalConsoleGridComponent.prototype.onEntryClick = function (entry, field) {
        if (this.isActiveEntry(field)) {
            var dialogOptions = new OperationalConsoleDialogParameters(this.stateService.currentDate, field, entry.position, entry.orgLevel, this.stateService.application, this.employeeLinkClick);
            OperationalConsoleDialogComponent.openDialog(dialogOptions, this.modalService);
        }
    };
    OperationalConsoleGridComponent.prototype.onExportToExcel = function () {
        this.grid.saveAsExcel();
    };
    OperationalConsoleGridComponent.prototype.onExportToPdf = function () {
        this.grid.saveAsPDF();
    };
    OperationalConsoleGridComponent.prototype.onOrgLevelClick = function (entry) {
        if (this.canChangeOrgLevel()) {
            var context = new OrganizationTreeContext();
            context.applicationId = this.stateService.application.id;
            var orgLevel = this.orgLevelWatchService.getOrgLevelById(entry.orgLevel.id);
            if (orgLevel) {
                this.orgLevelActions.select(context, orgLevel);
            }
        }
    };
    OperationalConsoleGridComponent.prototype.canChangeOrgLevel = function () {
        return this.stateService.orgLevel.type !== OrgLevelType.department;
    };
    OperationalConsoleGridComponent.prototype.getOrgLevelTitle = function () {
        var title = '';
        var orgLevelTitle = _.get(this.stateService.orgLevel, 'type');
        switch (orgLevelTitle) {
            case OrgLevelType.department:
                title = 'Position';
                break;
            case OrgLevelType.organization:
                title = 'Department';
                break;
            case OrgLevelType.corporation:
                title = 'Organization';
                break;
            default:
                title = 'Organization';
        }
        return title;
    };
    OperationalConsoleGridComponent.prototype.getTitle = function (field) {
        var index = field.indexOf('.');
        var keyName = field;
        if (index !== -1) {
            keyName = field.slice(0, index);
        }
        return OperationalConsoleField.getTitle(keyName);
    };
    OperationalConsoleGridComponent.prototype.isActiveEntry = function (field) {
        return field.value !== 0;
    };
    OperationalConsoleGridComponent.prototype.refreshGrid = function (entries) {
        if (entries.length === 0) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(entries, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleGridComponent.prototype, "orglevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleGridComponent.prototype, "employeeLinkClickSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleGridComponent.prototype, "filtersChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleGridComponent.prototype, "actionExportSubscription", void 0);
    return OperationalConsoleGridComponent;
}());
export { OperationalConsoleGridComponent };
