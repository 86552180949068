import { SeniorityRules, ISeniorityRules } from './accrual-seniority-rules';

export interface IRules {
  id: number;
  ruleName: string;
  accrualTypeId: number;
  accrualTypeName: string;
  startDate: string;
  endDate: string;
  anchorDateSource: string;
  accrualCalcType: number;
  accrualCalcTypeName: string;
  accruedOnPeriod: string;
  maxBasisHours: number;
  minimumRequiredHours: number;
  deferralPeriodValue: number;
  deferralPeriodType: string;
  probationValue: number;
  probationType: string;
  maxAnnualEarnedType: string;
  rollOverMax: number;
  deleted: boolean;
  rateFormula: string;
  useRateFormula: boolean;
  carryover: string;
  carryoverType: string;
  carryoverValue: number;
  carryoverValueType: string;
  carryoverDay: string;
  carryoverExpirationDays: number;
  carryoverToAccrualTypeName: string;
  seniorityRules: ISeniorityRules[];
  oldPolicyName: string;
  oldBenefitName: string;
  rdoAccrualsTypePeriod: number;
  rdoAccrualsTypeDates: number;
}

export class Rules {
  public id: number;
  public ruleName: string;
  public accrualTypeId: number;
  public accrualTypeName: string;
  public startDate: string;
  public endDate: string;
  public anchorDateSource: string;
  public accrualCalcType: number;
  public accrualCalcTypeName: string;
  public accruedOnPeriod: string;
  public maxBasisHours: number;
  public minimumRequiredHours: number;
  public deferralPeriodValue: number;
  public deferralPeriodType: string;
  public probationValue: number;
  public probationType: string;
  public maxAnnualEarnedType: string;
  public rollOverMax: number;
  public deleted: boolean;
  public rateFormula: string;
  public useRateFormula: boolean;
  public carryover: string;
  public carryoverType: string;
  public carryoverValue: number;
  public carryoverValueType: string;
  public carryoverDay: string;
  public carryoverExpirationDays: number;
  public carryoverToAccrualTypeName: string;
  public seniorityRules: SeniorityRules[];

  public isError: boolean;
  public oldPolicyName: string;
  public oldBenefitName: string;
  public rdoAccrualsTypePeriod: number;
  public rdoAccrualsTypeDates: number;
}

