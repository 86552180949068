import { AttributesModel } from "./attributes-model";

export class IDetailsAgencyModel {
    public partnerId: string;
    public name: string;
    public isChecked:boolean;
    public shiftStatus:number;
    public agencyId: number;
    public attributes: AttributesModel[];
    public partner_attributes: any;
    public agencyOrgLevelId:number;
    public partnerExpanded:boolean = false;
      // 2 is hours
    public agency_grace_time: number = 2;
}

export class DetailsAgencyModel {
    public partnerId: string;
    public name: string;
    public isChecked:boolean;
    public shiftStatus:number;
    public agencyId: number;
    public attributes: AttributesModel[];
    public partner_attributes: any;
    public agencyOrgLevelId:number;
    public partnerExpanded:boolean = false;
      // 2 is hours
    public sub_type_id: number;
    public sub_type_name: string;
    public agency_grace_time: number = 2;
}