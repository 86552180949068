import { Component, Input } from '@angular/core';
import { StaffingPredictorManagementService } from '../../services/index';
import { StaffingPredictorOrganizationInfo, StaffingRatingOrganization } from '../../models/index';
import { PbjNavigationService } from '../../../../common/services/index';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    moduleId: module.id,
    selector: 'slx-staffing-predictor-star-rating',
    templateUrl: 'staffing-predictor-star-rating.component.html',
    styleUrls: ['staffing-predictor-star-rating.component.scss']
})
export class StaffingPredictorStarRatingComponent {

    @Input()
    public organization: StaffingRatingOrganization;
    @Input()
    public dataModel: StaffingPredictorOrganizationInfo;
    @Input()
    public isLoading: boolean = true;
    @Input()
    public sectionIndex: number;
    public get hasError(): boolean {
        return !this.hasData || this.noCcn || this.noCensus || this.noCmsData || this.noHoursData || this.rnZeroDays;
    }

    public get hasData(): boolean {
        return this.dataModel != null;
    }

    public get noCcn(): boolean {
        return this.hasData ? this.dataModel.organization.noCcn : false;
    }

    public get noCensus(): boolean {
        return this.hasData && !this.noCcn ? this.dataModel.organization.noCensus : false;
    }

    public get noHoursData(): boolean {
        return this.hasData && !this.noCcn && !this.noCensus ? this.dataModel.organization.noHoursData : false;
    }

    public get noCmsData(): boolean {
        return this.hasData && !this.noCcn && !this.noCensus && !this.noHoursData ? this.dataModel.organization.noCmsData : false;
    }

    public get rnZeroDays(): boolean {
        return this.hasData && !this.noCcn && !this.noCensus && !this.noHoursData ? this.dataModel.organization.rnZeroDays : false;
    }

    public get outdatedData(): boolean {
        return this.hasData && !this.noCcn ? this.dataModel.organization.outdatedData : false;
    }


    constructor(
        private managementService: StaffingPredictorManagementService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    public goToConfigScreen(): void {
        const navigation: PbjNavigationService = new PbjNavigationService(this.router, this.route);
        navigation.NavigateToPbjOrganizationsConfiguration();
    }
}
