import { IFieldData, FieldData, Actions, IFieldPolicy } from '../index';

export interface IMetadataInfo {
  orgLevelId: number;
  entityName: string;
  fields: IFieldData[];
  actions: string[];
}

export class MetadataInfo {
  public orgLevelId: number;
  public entityName: string;
  public fields: FieldData<any>[];
  public fieldsMap: StringMap<FieldData<any>>;
  public actions: Actions;
  public getPolicy(field: string): IFieldPolicy {
    if (!field) {
      return null;
    }
    let key: string = field.toLowerCase();
    if (!this.fieldsMap[key]) {
      return null;
    }
    return this.fieldsMap[key].Policy;
  }
}
