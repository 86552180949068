import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { appConfig } from '../../../../app.config';
import { pbjConfig } from '../../pbj.config';
import { DateRange } from '../../../../core/models/index';
import { BaseResolver, ComponentStateStorageService, StateManagementService } from '../../../../common/index';
var PbjReconciliationResolver = /** @class */ (function (_super) {
    tslib_1.__extends(PbjReconciliationResolver, _super);
    function PbjReconciliationResolver(router, storageService, stateManagement) {
        var _this = _super.call(this, router) || this;
        _this.storageService = storageService;
        _this.stateManagement = stateManagement;
        _this.componentId = 'PbjReconciliationComponent';
        _this.reportFrom = 'StartDate';
        _this.reportTo = 'EndDate';
        _this.maxDaysRange = pbjConfig.settings.reconciliation.maxDaysRange;
        return _this;
    }
    PbjReconciliationResolver.prototype.resolve = function (route, state) {
        var startDate = route.queryParamMap.get('startDate');
        var endDate = route.queryParamMap.get('endDate');
        var orgLevelId = route.queryParamMap.get('orgLevelId');
        var range = null;
        if (startDate && endDate) {
            var sDate = moment(startDate, appConfig.linkDateFormat).toDate();
            var eDate = moment(endDate, appConfig.linkDateFormat).toDate();
            range = new DateRange(sDate, eDate);
        }
        else {
            var dates = this.getRangeFromQuery();
            if (_.size(dates.s) > 0 && _.size(dates.e) > 0) {
                var sDate = moment(dates.s, appConfig.linkDateFormat).toDate();
                var eDate = moment(dates.e, appConfig.linkDateFormat).toDate();
                range = new DateRange(sDate, eDate);
            }
            else {
                range = this.restoreDates();
            }
            var params = this.createQueryParams(route, state);
            if (_.size(orgLevelId) > 0) {
                params.orgLevelId = orgLevelId;
            }
            params.startDate = moment(range.startDate).format(appConfig.linkDateFormat);
            params.endDate = moment(range.endDate).format(appConfig.linkDateFormat);
            this.navigateToPath(route, params);
        }
        return range;
    };
    PbjReconciliationResolver.prototype.getRangeFromQuery = function () {
        var urls = window.location.hash.split('?');
        var sDate = '';
        var eDate = '';
        if (urls.length > 0) {
            var queryUrl = _.last(urls);
            var queryParams = queryUrl.split('&');
            _.forEach(queryParams, function (param) {
                var _a = param.split('='), key = _a[0], value = _a[1];
                if (key === 'startDate' && _.size(value) > 0) {
                    sDate = value;
                }
                if (key === 'endDate' && _.size(value) > 0) {
                    eDate = value;
                }
            });
        }
        return { s: sDate, e: eDate };
    };
    PbjReconciliationResolver.prototype.getInitRange = function () {
        var halfPeriod = this.maxDaysRange / 2;
        var prevDays = Math.round(halfPeriod) === halfPeriod ? halfPeriod : Math.floor(halfPeriod);
        var nextDays = Math.round(halfPeriod) === halfPeriod ? halfPeriod - 1 : Math.floor(halfPeriod);
        var today = moment();
        var start = today.clone().subtract(prevDays, 'days');
        var end = today.clone().add(nextDays, 'days');
        return new DateRange(start.toDate(), end.toDate());
    };
    PbjReconciliationResolver.prototype.restoreDates = function () {
        var today1 = new Date();
        var quarter = Math.floor((today1.getMonth() / 3));
        var startDate = new Date(new Date().getFullYear(), quarter * 3 - 3, 1);
        var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);
        this.stateManagement.init(this.componentId, true);
        var state = this.storageService.getControlState(this.componentId, this.reportFrom);
        var value = state && state.value && _.isDate(new Date(state.value)) && moment(new Date(state.value), appConfig.linkDateFormat).toDate() > moment('1/1/1970', appConfig.linkDateFormat).toDate() ? new Date(state.value) : startDate;
        var state1 = this.storageService.getControlState(this.componentId, this.reportTo);
        var value1 = state1 && state1.value && _.isDate(new Date(state1.value)) && moment(new Date(state1.value), appConfig.linkDateFormat).toDate() > moment('1/1/1970', appConfig.linkDateFormat).toDate() ? new Date(state1.value) : endDate;
        return new DateRange(value, value1);
    };
    return PbjReconciliationResolver;
}(BaseResolver));
export { PbjReconciliationResolver };
