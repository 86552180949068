import * as _ from 'lodash';
import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ACAConfigurationManagementService } from '../../../services/aca-config/aca-manage-management.service';
import { ManageACAPeriods, ManageACAPeriodsContainer } from '../../../../configuration/models';
import { unsubscribe } from '../../../../core/decorators/index';
import { IApplicationConfig, appConfig } from '../../../../app.config';
import { ModalService, KendoGridStateHelper, ConfirmOptions, ConfirmDialogComponent, saveEvent, ChangeManagementService, cancelEvent, DeviceDetectorService } from '../../../../common/index';

@Component({
  moduleId: module.id,
  selector: 'slx-manage-aca-periods',
  templateUrl: 'manage-aca-periods.component.html',
  styleUrls: ['manage-aca-periods.component.scss'],
  providers: [ACAConfigurationManagementService]
})
export class ManageAcaPeriodsComponent implements OnInit {

  public get isLockedColumn(): boolean {
    return this.devDetector.isDesktop;
  }

  @ViewChild('kendoGrid', { static: true })
  public grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  public ngForm: NgForm;

  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private onloadedSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;

  constructor(
    public management: ACAConfigurationManagementService,
    private modalService: ModalService,
    private changeService: ChangeManagementService, private devDetector: DeviceDetectorService
  ) {
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<ManageACAPeriods>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'employer.name', dir: 'asc' }];
  }

  public isLoading: boolean;
  public canEdit: boolean;
  public canExportToExcel: boolean;
  public canExportToPdf: boolean;
  public records: ManageACAPeriods[];
  private subscriptions: StringMap<Subscription> = {};
  private m_container: ManageACAPeriodsContainer;
  public originalRecords: ManageACAPeriodsContainer;
  public gridState: KendoGridStateHelper<ManageACAPeriods>;
  public gridView: GridDataResult;
  public pageSize = 50;
  public appConfig: IApplicationConfig;
  public m_minimumDate: Date = new Date("1/1/2014");
  public newHireMeasMax: boolean = false;
  public newHireAdminMax: boolean = false;
  public newMaxHireStabPer: boolean = false;
  public stdConfigMeasMax: boolean = false;
  public stdConfigAdminMax: boolean = false;
  public stdConfigStabPer: boolean = false;
  public isValidate: boolean = false;
  public isEditMode: boolean = false;

  public ngOnInit(): void {
    this.isLoading = false;
    this.onloadedSubscription = this.management.subscribeToOnLoaded((state: ManageACAPeriodsContainer) => {
      this.canEdit = state.actions.canEdit;
      this.canExportToExcel = state.actions.canExportToExcel;
      this.canExportToPdf = state.actions.canExportToPdf;
      this.m_container = state;
      this.originalRecords = _.cloneDeep(state);
      this.refreshGrid();
    });

    this.stateSubscription = this.management.subscribeToOnStateChanged((state: any) => {
      this.isLoading = state.isLoading;
    });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(() => this.refreshGrid());

    this.subscriptions.gridEditSubscription = this.gridState.onEdit$.subscribe((item: ManageACAPeriods): void => {
      this.onEditItems(item);
    });

    this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<ManageACAPeriods>) => {
      this.gridSaveSubscribe(event.dataItem);
    });

    this.subscriptions.gridCancelSubscription = this.gridState.onCancel$.subscribe((item: cancelEvent<ManageACAPeriods>): void => {
      this.cancelSubscription();
    });

    this.subscriptions.export = this.management.subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.management.init();
  }

  public gridSaveSubscribe(dataItem: ManageACAPeriods): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.height = 230;
    options.showCancel = true;
    options.showOK = true;
    options.buttonCanceltext = "No";
    options.buttonOKtext = "Yes";
    ConfirmDialogComponent.openDialog(
      'Warning',
      'Modifying ACA Measurement settings will require ACA measurements, stability periods, and ACA status determinations to be recalculated, which may result in changes to Form 1095-C indicator code suggestions for any years that are currently unlocked. Are you sure you want to proceed with this change?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.doSave(dataItem);
        } else {
          this.resetPreviousRecord(dataItem);
        }
      },
      options);
  }

  public resetPreviousRecord(item: ManageACAPeriods): void {

    let orgPA = _.find(this.originalRecords.records, ['id', item.id]);

    item.newHireFirstOfTheMonth = orgPA.newHireFirstOfTheMonth;
    item.newHireAllowOverlap = orgPA.newHireAllowOverlap;
    item.newHireMeasurementPeriod = orgPA.newHireMeasurementPeriod;
    item.newHireAdministrativePeriod = orgPA.newHireAdministrativePeriod;
    item.newHireStabilityPeriod = orgPA.newHireStabilityPeriod;

    item.standardConfigFirstDayOfMeasurement = orgPA.standardConfigFirstDayOfMeasurement;
    item.standardConfigMeasurementPeriod = orgPA.standardConfigMeasurementPeriod;
    item.standardConfigAdministrativePeriod = orgPA.standardConfigAdministrativePeriod;
    item.standardConfigStabilityPeriod = orgPA.standardConfigStabilityPeriod;
    this.isEditMode = false;
  }

  public doSave(item: ManageACAPeriods): void {
    this.isLoading = true;
    this.isEditMode = false;
    this.management.postManageACARecord(item)
    this.changeService.clearChanges();
  }

  public cancelSubscription(): void {
    this.isEditMode = false;
    this.stdConfigMeasMax = false;
    this.stdConfigAdminMax = false;
    this.stdConfigStabPer = false;
    this.newHireMeasMax = false;
    this.newHireAdminMax = false;
    this.newMaxHireStabPer = false;
    this.changeService.clearChanges();
  }

  public onClickExport(isPDF: boolean): void {
    this.management.exportTo(isPDF);
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  public getGridData(): () => ExcelExportData {
    return (): ExcelExportData => {
      return {
        data: _.cloneDeep(this.gridState.view.data),
      };
    };
  }

  public minDateLimit(): Date {
    return this.m_minimumDate;
  }

  public onChangeValue(): void {
    this.validationCheck();
  }

  public onChangeNewHireMeasPer(item: ManageACAPeriods): void {
    this.newHireMeasMax = false;
    this.newHireAdminMax = false;
    this.newMaxHireStabPer = false;

    let result: number;
    result = (item.newHireAdministrativePeriod / 30) + item.newHireMeasurementPeriod;

    if (_.isNil(item.newHireMeasurementPeriod) || item.newHireMeasurementPeriod <= 3) {
      this.newHireMeasMax = false;
    } else if (result > 13.99) {
      this.newHireMeasMax = true;
    }
    else {
      this.newHireMeasMax = false;
    }
    this.onChangeNewHireStabPer(item);
    this.validationCheck();
  }

  public onChangeNewHireAdminPer(item: ManageACAPeriods): void {
    this.newHireMeasMax = false;
    this.newHireAdminMax = false;

    let result: number;
    result = (item.newHireAdministrativePeriod / 30) + item.newHireMeasurementPeriod;

    if (_.isNil(item.newHireMeasurementPeriod) || item.newHireMeasurementPeriod <= 3) {
      this.newHireAdminMax = false;
    } else if (result > 13.99) {
      this.newHireAdminMax = true;
    }
    else {
      this.newHireAdminMax = false;
    }
    this.validationCheck();
  }

  public onChangeNewHireStabPer(item: ManageACAPeriods): void {
    if (item.newHireMeasurementPeriod > item.newHireStabilityPeriod) {
      this.newMaxHireStabPer = true;
    } else {
      this.newMaxHireStabPer = false;
    }
    this.validationCheck();
  }

  public onChangeStdConfigMeasPer(item: ManageACAPeriods): void {
    this.stdConfigMeasMax = false;
    this.stdConfigAdminMax = false;
    this.stdConfigStabPer = false;

    let result: number;
    result = (item.standardConfigAdministrativePeriod / 30) + item.standardConfigMeasurementPeriod;

    if (_.isNil(item.standardConfigMeasurementPeriod) || item.standardConfigMeasurementPeriod <= 3) {
      this.stdConfigMeasMax = false;
    } else if (result > 13.99) {
      this.stdConfigMeasMax = true;
    }
    else {
      this.stdConfigMeasMax = false;
    }
    this.onChangeStdConfigStabPer(item);
    this.validationCheck();
  }

  public onChangeStdConfigAdminPer(item: ManageACAPeriods): void {
    this.stdConfigMeasMax = false;
    this.stdConfigAdminMax = false;

    let result: number;
    result = (item.standardConfigAdministrativePeriod / 30) + item.standardConfigMeasurementPeriod;

    if (_.isNil(item.standardConfigMeasurementPeriod) || item.standardConfigMeasurementPeriod <= 3) {
      this.stdConfigAdminMax = false;
    } else if (result > 13.99) {
      this.stdConfigAdminMax = true;
    }
    else {
      this.stdConfigAdminMax = false;
    }
    this.validationCheck();
  }

  public onChangeStdConfigStabPer(item: ManageACAPeriods): void {
    if (item.standardConfigMeasurementPeriod > item.standardConfigStabilityPeriod) {
      this.stdConfigStabPer = true;
    } else {
      this.stdConfigStabPer = false;
    }
    this.validationCheck();
  }

  public validationCheck(): void {
    if (this.newHireMeasMax || this.newHireAdminMax || this.newMaxHireStabPer || this.stdConfigMeasMax || this.stdConfigAdminMax || this.stdConfigStabPer) {
      this.isValidate = true;
    } else {
      this.isValidate = false;
    }
  }

  public onEditItems(manageACARecord: ManageACAPeriods): void {
    this.isValidate = true;
    this.isEditMode = true;
    if (_.isNull(manageACARecord.newHireFirstOfTheMonth)) {
      manageACARecord.newHireFirstOfTheMonth = false;
    }
    if (_.isNull(manageACARecord.newHireAllowOverlap)) {
      manageACARecord.newHireAllowOverlap = false;
    }
    if (_.isNull(manageACARecord.newHireMeasurementPeriod)) {
      manageACARecord.newHireMeasurementPeriod = 12;
    }
    if (_.isNull(manageACARecord.newHireAdministrativePeriod)) {
      manageACARecord.newHireAdministrativePeriod = 30;
    }
    if (_.isNull(manageACARecord.newHireStabilityPeriod)) {
      manageACARecord.newHireStabilityPeriod = 12;
    }
    if (_.isNull(manageACARecord.standardConfigMeasurementPeriod)) {
      manageACARecord.standardConfigMeasurementPeriod = 12;
    }
    if (_.isNull(manageACARecord.standardConfigAdministrativePeriod)) {
      manageACARecord.standardConfigAdministrativePeriod = 30;
    }
    if (_.isNull(manageACARecord.standardConfigStabilityPeriod)) {
      manageACARecord.standardConfigStabilityPeriod = 12;
    }
    this.changeService.changeNotify();
  }

  public refreshGrid(): void {
    if (!this.m_container) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.m_container.records, this.gridState.state);
  }
}
