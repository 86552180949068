import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { StateManagementService } from '../../../../common/services/index';
import { AccrualsBalancesManagementService } from '../../services/index';
var AccrualsBalancesComponent = /** @class */ (function () {
    function AccrualsBalancesComponent(stateManagement, accrualsManagementService) {
        this.stateManagement = stateManagement;
        this.accrualsManagementService = accrualsManagementService;
        this.isLoading = true;
        this.subscriptions = {};
    }
    AccrualsBalancesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('AccrualsBalancesComponent');
        this.accrualsManagementService.init();
        this.subscriptions.loading = this.accrualsManagementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    AccrualsBalancesComponent.prototype.ngOnDestroy = function () {
        this.accrualsManagementService.destroy();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AccrualsBalancesComponent.prototype, "subscriptions", void 0);
    return AccrualsBalancesComponent;
}());
export { AccrualsBalancesComponent };
