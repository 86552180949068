import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GridDataResult, SelectionEvent, RowArgs } from '@progress/kendo-angular-grid';

import * as moment from 'moment';
import * as _ from 'lodash';

import { Assert } from '../../../../framework/index';
import {
  Arrival
} from '../../../models/index';
@Component({
  moduleId: module.id,
  selector: 'slx-arrivals-grid',
  templateUrl: 'arrivals-grid.component.html',
  styleUrls: ['arrivals-grid.component.scss']
})
export class ArrivalsGridComponent {
  @Output()
  public onInFieldClick: EventEmitter<Arrival>;
  @Output()
  public onScheduledFieldClick: EventEmitter<Arrival>;
  @Output()
  public onLateFieldClick: EventEmitter<Arrival>;
  @Output()
  public onDifferenceFieldClick: EventEmitter<Arrival>;
  @Output()
  public onRowSelected: EventEmitter<Arrival[]>;

  @Input()
  public set records(data: Arrival[]) {
    if (data) {
      this.gridView = {
        data: data,
        total: data.length
      };
    }
  }
  public gridView: GridDataResult;

  constructor() {
    this.onInFieldClick = new EventEmitter<Arrival>();
    this.onScheduledFieldClick = new EventEmitter<Arrival>();
    this.onLateFieldClick = new EventEmitter<Arrival>();
    this.onDifferenceFieldClick = new EventEmitter<Arrival>();
    this.onRowSelected = new EventEmitter<Arrival[]>();
  }

  public selectionChange(selection: SelectionEvent): void {
    const lastRow = _.maxBy(selection.selectedRows, (r: RowArgs) => r.index);
    if (lastRow) {
      const records = _.slice(this.gridView.data, 0, lastRow.index + 1);
      const filtered = _.filter(records, (r: Arrival) => !!r.arrivalTime);
      this.onRowSelected.emit(filtered);
    } else {
      this.onRowSelected.emit([]);
    }
  }

  public onInFieldClickHandler(arrival: Arrival): void {
    this.onInFieldClick.emit(arrival);
  }

  public onScheduledFieldClickHandler(arrival: Arrival): void {
    this.onScheduledFieldClick.emit(arrival);
  }

  public onLateFieldClickHandler(arrival: Arrival): void {
    this.onLateFieldClick.emit(arrival);
  }

  public onDifferencceFieldClickHandler(arrival: Arrival): void {
    this.onDifferenceFieldClick.emit(arrival);
  }
}
