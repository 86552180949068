import * as tslib_1 from "tslib";
import { AppSettingsManageService } from './../../../../../app-settings/services/index';
import { OnInit, OnDestroy, NgZone, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { Assert } from '../../../../../framework/index';
import { LookupService, EmployeeValidatorAdapter, EmployeeDefinitionsApiService } from '../../../../../organization/services/index';
import { Lookup, LookupType, ILookupRequest, Position, LookupEntity, Supervisor } from '../../../../../organization/models/index';
import { EmployeePayrollEffectiveDateSettings, EmployeeSectionsPayCycleRecord, EmployeeSectionsPayCycles, EmployeeSectionsPayroll, EmployeeSectionsTemporalModel, EmployeeSectionsBase, BenefitClassDialogReq } from '../../../models/index';
import { EmployeeSectionBridgeService, EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { PbjModes } from '../../../../../app-settings/model/app-setting-keys';
import { ModalService } from '../../../../../common/services/index';
import { ConfirmDialogComponent, InfoDialogComponent } from '../../../../../common/components/index';
import { BenefitClassDialogComponent } from '../../benefit-class-dialog/benefit-class-dialog.component';
import * as moment from 'moment';
import { dateTimeUtils } from '../../../../../common/utils/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { employeeConfig } from '../../../../employee/employee.config';
var EmployeeSectionsPayrollComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPayrollComponent, _super);
    function EmployeeSectionsPayrollComponent(employeeSectionsEmploymentApiService, lookupService, employeeValidatorAdapter, decorator, ngZone, appSettingsManageService, modalService, employeeDefinitionApi, changeDetector, employeeDefinitionsApiService, employeeSectionBridgeService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.appSettingsManageService = appSettingsManageService;
        _this.modalService = modalService;
        _this.employeeDefinitionApi = employeeDefinitionApi;
        _this.changeDetector = changeDetector;
        _this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        _this.employeeSectionBridgeService = employeeSectionBridgeService;
        _this.hourlyRateLimit = 9999.99;
        _this.weeklyRateLimit = 399999.99;
        _this.hourlyRateWarningLimit = 299.99;
        _this.weeklyRateWarningLimit = 11999.99;
        _this.pbjEnabled = false;
        _this.pbjIsManual = false;
        _this.bswiftEnabled = false;
        _this.isShowCurrentPayCycleWarning = true;
        _this.isPbjIdLoading = false;
        _this.decimalLimit = null;
        _this.isNgpUser = false;
        Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.lookupService = lookupService;
        _this.employeeValidatorAdapter = employeeValidatorAdapter;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "employeeSectionsPayroll", {
        get: function () {
            return this.m_employeeSectionsPayroll;
        },
        set: function (value) {
            var l;
            if (!_.isNil(this.m_employeeSectionsPayroll) && !_.isNil(this.m_employeeSectionsPayroll.effectiveDateSetting)) {
                l = this.m_employeeSectionsPayroll.effectiveDateSetting;
            }
            this.m_employeeSectionsPayroll = value;
            if (value) {
                if (this.increaseReasons) {
                    this.increaseReason = this.increaseReasons.items.find(function (x) { return x.id == value.icnreaseReason; });
                }
                else {
                    this.increaseReason = null;
                }
                this.selectedSupervisor = value.supervisor;
                this.originalPbjId = value.pbjId.fieldValue;
                this.originalSSN = value.ssn.fieldValue;
                this.employeePayRateComponent = value.payRateComponents.fieldValue;
                this.maxHireDate = value.maxAllowableHireDate;
                this.m_employeeSectionsPayroll.effectiveDateSetting = l;
                this.setDecimalLimit(value.isEnableRate4DecimalPlaces);
                this.employeeSectionBridgeService.changePayRateFormat(value.isEnableRate4DecimalPlaces);
            }
            else {
                this.increaseReason = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "employeePayCycles", {
        get: function () {
            return this.employeeSectionsPayCycles;
        },
        set: function (value) {
            if (value) {
                this.employeeSectionsPayCycles = value;
                this.setPayCycleData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "pbjIdMaxSymbols", {
        get: function () {
            return this.originalPbjId === this.employeeSectionsPayroll.pbjId.fieldValue ? 30 : 9;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "payrollNumberValidation", {
        get: function () {
            return EmployeeValidatorAdapter.payrollNumberValidation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "ssnValidation", {
        get: function () {
            return EmployeeValidatorAdapter.ssnValidation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "pbjIdValidation", {
        get: function () {
            return EmployeeValidatorAdapter.pbjIdValidation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "isSalaryPayType", {
        get: function () {
            return this.employeeSectionsPayroll &&
                this.employeeSectionsPayroll.payType &&
                this.employeeSectionsPayroll.payType.fieldValue.isSalaryPayType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "getRateLimit", {
        get: function () {
            return this.isSalaryPayType ? this.weeklyRateLimit : this.hourlyRateLimit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "getRateWarningLimit", {
        get: function () {
            return this.isSalaryPayType ? this.weeklyRateWarningLimit : this.hourlyRateWarningLimit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "getRateTitle", {
        get: function () {
            return this.isSalaryPayType ? 'Weekly Rate' : 'Hourly Rate';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsPayrollComponent.prototype.setPayCycleData = function () {
        this.employeePayrollEffectiveDateSettings = new EmployeePayrollEffectiveDateSettings();
        var currentDate = moment();
        var allPayCyclesSorted = _.orderBy(this.employeeSectionsPayCycles.records, function (c) { return c.startDate; }, ['asc']);
        var currentPayCycles = _.filter(allPayCyclesSorted, function (cycle) {
            var currStartDate = moment(cycle.startDate).add(-1, 'days');
            ;
            var currEndDate = moment(cycle.endDate).add(1, 'days');
            ;
            return moment(currentDate).isBetween(currStartDate, currEndDate);
        });
        if (currentPayCycles.length > 0) {
            var payCycle = _.first(_.orderBy(currentPayCycles, function (c) { return moment(c.endDate); }, ['desc']));
            this.employeePayrollEffectiveDateSettings.effectiveDateData = payCycle.startDate;
        }
        var lockedPayCycles = _.filter(allPayCyclesSorted, function (cycle) {
            return (cycle.approved == true || cycle.locked == true);
        });
        this.disabledDatesData = lockedPayCycles;
        var unlockedPayCycles = _.filter(allPayCyclesSorted, function (cycle) {
            return (cycle.locked == false && cycle.approved == false);
        });
        this.enabledDatesData = unlockedPayCycles;
        this.enabledDates = _.map(unlockedPayCycles, function (cycle) { return cycle.startDate; });
        if (this.employeeStatus.toLowerCase() === "future rehire") {
            this.employeePayrollEffectiveDateSettings.minDate2 = this.m_employeeSectionsPayroll.rehireDate;
            this.employeePayrollEffectiveDateSettings.minDate = this.m_employeeSectionsPayroll.rehireDate;
        }
        else {
            var lastLockedPayCycleIndex = _.findLastIndex(allPayCyclesSorted, function (c) { return (c.approved == true || c.locked == true); });
            if (lastLockedPayCycleIndex != -1) {
                var minDateAllPayCycle = allPayCyclesSorted[lastLockedPayCycleIndex + 1];
                if (minDateAllPayCycle) {
                    this.employeePayrollEffectiveDateSettings.minDate2 = minDateAllPayCycle.startDate;
                    this.employeePayrollEffectiveDateSettings.minDate = minDateAllPayCycle.startDate;
                }
                else {
                    var lastLockedPayCycle = allPayCyclesSorted[lastLockedPayCycleIndex];
                    var minDate = moment(lastLockedPayCycle.endDate).add(1, 'days');
                    this.employeePayrollEffectiveDateSettings.minDate2 = minDate.toDate();
                    this.employeePayrollEffectiveDateSettings.minDate = minDate.toDate();
                }
            }
        }
        var maxDateAllPayCycle = _.first(_.orderBy(unlockedPayCycles, function (c) { return moment(c.endDate); }, ['desc']));
        if (maxDateAllPayCycle) {
            this.employeePayrollEffectiveDateSettings.maxDate2 = moment(maxDateAllPayCycle.endDate).add(1, 'years').toDate();
            this.employeePayrollEffectiveDateSettings.maxDate = moment(maxDateAllPayCycle.endDate).add(1, 'years').toDate();
            var projectedPayCycleStart = moment(maxDateAllPayCycle.endDate).add(1, 'days').toDate();
            var paycycleInterval = moment.duration(moment(projectedPayCycleStart).diff(moment(maxDateAllPayCycle.startDate))).asDays();
            this.enabledDates = this.addProjectedDates(this.enabledDates, projectedPayCycleStart, this.employeePayrollEffectiveDateSettings.maxDate2, paycycleInterval);
        }
        this.m_employeeSectionsPayroll.effectiveDateSetting = this.employeePayrollEffectiveDateSettings;
    };
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "isOverSalary", {
        get: function () {
            return this.state.isEditMode &&
                this.employeeSectionsPayroll &&
                ((this.employeeSectionsPayroll.payRate &&
                    this.employeeSectionsPayroll.payRate.fieldValue) ||
                    (this.employeeSectionsPayroll.payRate2 &&
                        this.employeeSectionsPayroll.payRate2.fieldValue) ||
                    (this.employeeSectionsPayroll.payRate3 &&
                        this.employeeSectionsPayroll.payRate3.fieldValue)) > this.getRateWarningLimit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "ftDateNeeded", {
        get: function () {
            if (!this.employeeSectionsPayroll)
                return false;
            return _.toLower(_.trim(this.employeeSectionsPayroll.empType.fieldValue.fullTimeIndex)) === 'y'; //<--- TBD implement this logic on backend
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "allocationsSize", {
        get: function () {
            if (_.isObjectLike(this.employeeSectionsPayroll)
                && _.isObjectLike(this.employeeSectionsPayroll.costCenterAllocations)
                && _.isArray(this.employeeSectionsPayroll.costCenterAllocations.fieldValue)) {
                var allocations = _.filter(this.employeeSectionsPayroll.costCenterAllocations.fieldValue, function (a) { return _.isObjectLike(a.costCenter); });
                return _.size(allocations);
            }
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "displayMaxDateTip", {
        get: function () {
            return _.isDate(this.maxHireDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayrollComponent.prototype, "isSalariedWithoutHours", {
        get: function () {
            return this.state.isEditMode &&
                this.employeeSectionsPayroll &&
                this.employeeSectionsPayroll.standardWeeklyHours &&
                this.employeeSectionsPayroll.standardWeeklyHours.fieldValue === 0 &&
                this.employeeSectionsPayroll.payType.fieldValue &&
                this.employeeSectionsPayroll.payType.fieldValue.name == 'Salaried';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsPayrollComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsPayroll;
    };
    EmployeeSectionsPayrollComponent.prototype.ngOnChanges = function (changes) {
        if (changes['employeeSectionsPayroll']) {
            if (this.employeeSectionsPayroll) {
                this.positionLookupRequest = { lookupType: 'position', orgLevelId: this.employeeSectionsPayroll.homeOrgLevelId };
                this.payPolicyLookupRequest = { lookupType: 'payPolicy', orgLevelId: this.employeeSectionsPayroll.homeOrgLevelId };
                this.shiftDiffPolicyLookupRequest = { lookupType: 'shiftDiffPolicy', orgLevelId: this.employeeSectionsPayroll.homeOrgLevelId };
            }
        }
    };
    EmployeeSectionsPayrollComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.lookupService.getLookup({ lookupType: LookupType.payType, employeeId: undefined, orgLevelId: undefined })
            .then(function (payTypeLookup) { _this.payTypeLookup = payTypeLookup; });
        this.lookupService.getLookup({ lookupType: LookupType.increaseReason, employeeId: undefined, orgLevelId: undefined })
            .then(function (increaseReasons) {
            _this.increaseReasons = increaseReasons;
            if (_this.m_employeeSectionsPayroll) {
                _this.increaseReason = _this.increaseReasons.items.find(function (x) { return x.id == _this.m_employeeSectionsPayroll.icnreaseReason; });
            }
        });
        this.getSettings();
        this.birthDateSubscriptions = this.employeeSectionBridgeService.subscribeToChangeBirthDate(function (birthDate) {
            _this.minHireDate = moment(birthDate).add(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
        });
    };
    EmployeeSectionsPayrollComponent.prototype.positionChanged = function (position) {
        if (!position) {
            return;
        }
        this.payPolicyLookupRequest = { lookupType: 'payPolicy', orgLevelId: position.orgLevelId };
        this.shiftDiffPolicyLookupRequest = { lookupType: 'shiftDiffPolicy', orgLevelId: position.orgLevelId };
    };
    EmployeeSectionsPayrollComponent.prototype.showCurrentPayCycleWarning = function () {
        if (this.isShowCurrentPayCycleWarning) {
            var message = 'No Current Payroll Cycles have been found. Be sure to select the appropriate effective date as additional validation cannot be provided.';
            InfoDialogComponent.OpenDialog('Warning', message, this.modalService);
            this.isShowCurrentPayCycleWarning = false;
        }
    };
    EmployeeSectionsPayrollComponent.prototype.resetCurrentPayCycleWarning = function () {
        this.isShowCurrentPayCycleWarning = true;
    };
    EmployeeSectionsPayrollComponent.prototype.generatePbjId = function () {
        var _this = this;
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to generate a PBJ ID?', this.modalService, function (result) {
            if (result) {
                _this.isPbjIdLoading = true;
                _this.employeeDefinitionApi.generatePbjId()
                    .then(function (value) {
                    _this.employeeSectionsPayroll.pbjId.fieldValue = value;
                    _this.isPbjIdLoading = false;
                    _this.updateData();
                });
            }
        });
    };
    EmployeeSectionsPayrollComponent.prototype.checkSalariedHours = function () {
        var standardWeeklyHoursControl = this.form.get('standardWeeklyHours');
        var errors = standardWeeklyHoursControl.errors || {};
        if (!this.isSalariedWithoutHours) {
            delete errors.isSalariedWithoutHours;
            if (_.size(errors) === 0) {
                errors = null;
            }
        }
        else {
            errors.isSalariedWithoutHours = true;
        }
        standardWeeklyHoursControl.setErrors(errors);
    };
    EmployeeSectionsPayrollComponent.prototype.onCostCenterChanged = function (isValid) {
        var costCenterCodeControl = this.form.get('costCenterCode');
        var errors = costCenterCodeControl.errors || {};
        if (isValid) {
            delete errors.costCenterAllocations;
            if (_.size(errors) === 0) {
                errors = null;
            }
        }
        else {
            errors.costCenterAllocations = true;
        }
        costCenterCodeControl.setErrors(errors);
    };
    EmployeeSectionsPayrollComponent.prototype.onPayRateChanged = function (controlName) {
        var payRateControl = this.form.get(controlName);
        if (_.isNaN(payRateControl.value)) {
            payRateControl.setValue(null);
            payRateControl.setErrors(null);
            if (controlName === 'payRate2' && this.employeeSectionsPayroll && this.employeeSectionsPayroll.payRate2) {
                this.employeeSectionsPayroll.payRate2.fieldValue = null;
            }
            else if (controlName === 'payRate3' && this.employeeSectionsPayroll && this.employeeSectionsPayroll.payRate3) {
                this.employeeSectionsPayroll.payRate3.fieldValue = null;
            }
        }
    };
    EmployeeSectionsPayrollComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmploymentPayroll(this.employeeId)
            .then(function (employeeSectionsPayroll) {
            _this.employeeSectionsPayroll = employeeSectionsPayroll;
            _this.selectedSupervisor = employeeSectionsPayroll.supervisor;
            _this.updateRequiredPolicyField();
            _this.stopProgress();
            _this.updateData();
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
        this.lookupService.getLookup({ lookupType: LookupType.supervisorList, employeeId: this.employeeId, orgLevelId: this.employeeSectionsPayroll.homeOrgLevelId })
            .then(function (splist) {
            _this.supervisorOptions = splist;
        });
    };
    EmployeeSectionsPayrollComponent.prototype.updateData = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsPayrollComponent.prototype.updatePayType = function (event) {
        var _this = this;
        this.employeeSectionsPayroll.payType.fieldValue = event;
        setTimeout(function () {
            _this.updateData();
            _this.checkSalariedHours();
            _this.updateData();
        }, 0);
    };
    EmployeeSectionsPayrollComponent.prototype.checkTemporalDirty = function () {
        return this.metaFieldsTemporalDirtyChecker(this.employeeSectionsPayroll);
    };
    EmployeeSectionsPayrollComponent.prototype.doSave = function (effectiveDate) {
        if (this.increaseReason) {
            this.m_employeeSectionsPayroll.icnreaseReason = this.increaseReason.id;
        }
        else {
            this.m_employeeSectionsPayroll.icnreaseReason = null;
        }
        if (this.m_employeeSectionsPayroll.payrollNumber.fieldValue) {
            this.m_employeeSectionsPayroll.payrollNumber.fieldValue = _.trim(this.m_employeeSectionsPayroll.payrollNumber.fieldValue);
        }
        this.m_employeeSectionsPayroll.supervisor = this.selectedSupervisor;
        if (this.bswiftEnabled &&
            !this.employeeShortInfo.avoidsBswiftChecks &&
            this.m_employeeSectionsPayroll.areBenefitsFieldsDirty()) {
            this.triggerBenefitClassPopup(effectiveDate);
        }
        else {
            this.saveEmploymentPayroll(effectiveDate);
        }
    };
    EmployeeSectionsPayrollComponent.prototype.saveEmploymentPayroll = function (effectiveDate) {
        var _this = this;
        return this.employeeSectionsEmploymentApiService.setEmploymentPayroll(this.employeeId, this.employeeSectionsPayroll, effectiveDate)
            .then(function (status) {
            _this.onActionComplete(true);
            _this.employeeSectionBridgeService.changeHireDate(_this.employeeSectionsPayroll.hireDate.fieldValue);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsPayrollComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.pbjEnabled = config.PbjEnabled;
                        this.isSupervisorEnabled = config.isSupervisorEnabled;
                        this.pbjIsManual = config.PbjMode === PbjModes.Manual;
                        this.updateRequiredPolicyField();
                        this.bswiftEnabled = config.bswiftIntegrationEnabled;
                        this.isNgpUser = config.IsNextgenPayrollEnabled || false;
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsPayrollComponent.prototype.updateRequiredPolicyField = function () {
        if (this.employeeSectionsPayroll) {
            if (!this.employeeSectionsPayroll.pbjId.Policy.isVisible || this.employeeSectionsPayroll.pbjId.Policy.isMasked || !this.employeeSectionsPayroll.pbjId.Policy.isEditable) {
                this.employeeSectionsPayroll.pbjId.isRequired = false;
            }
            else {
                this.employeeSectionsPayroll.pbjId.isRequired = this.employeeSectionsPayroll.pbjId.isRequired || this.pbjIsManual;
            }
        }
    };
    EmployeeSectionsPayrollComponent.prototype.disabledDate = function (date) {
        if (this.m_employeeSectionsPayroll.isOnlyPayRate()) {
            var unlockedPayCycles = _.filter(this.enabledDatesData, function (cycle) {
                var currStartDate = moment(cycle.startDate).add(-1, 'days');
                var currEndDate = moment(cycle.endDate).add(1, 'years').add(1, 'days');
                return moment(date).isBetween(currStartDate, currEndDate);
            });
            if (this.employeeSectionsPayroll.payType.fieldValue.description.toLowerCase() === 'salaried') {
                return this.enableStartDate(date);
            }
            else {
                return (unlockedPayCycles.length > 0) ? false : true;
            }
        }
        else if (this.m_employeeSectionsPayroll.isNotOnlyPayRate()) {
            if (this.employeeSectionsPayroll.payType.fieldValue.description.toLowerCase() === 'hourly') {
                return false;
            }
            else {
                return this.enableStartDate(date);
            }
        }
    };
    EmployeeSectionsPayrollComponent.prototype.enableStartDate = function (date) {
        if (this.enabledDates.length > 0) {
            var result = _.find(this.enabledDates, function (n) {
                if (moment(n).isSame(date)) {
                    return true;
                }
            });
            if (!_.isNil(result)) {
                return false;
            }
            else {
                return true;
            }
        }
    };
    EmployeeSectionsPayrollComponent.prototype.triggerBenefitClassPopup = function (effectiveDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var canEditBenefitClass, request, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.employeeDefinitionsApiService.getCanEditBenefitClass(this.employeeId)];
                    case 1:
                        canEditBenefitClass = _b.sent();
                        if (!canEditBenefitClass) return [3 /*break*/, 3];
                        request = new BenefitClassDialogReq();
                        request.employeeId = this.employeeId;
                        request.hireDate = this.employeeSectionsPayroll.hireDate.fieldValue;
                        _a = request;
                        return [4 /*yield*/, this.employeeDefinitionsApiService.getEmployeeBenefitClass(this.employeeId, moment().startOf('day').toDate())];
                    case 2:
                        _a.employeeBenefitClass = _b.sent();
                        if (this.employeeStatus.toLowerCase() === "future rehire") {
                            request.rehireDate = this.employeeSectionsPayroll.rehireDate;
                        }
                        BenefitClassDialogComponent.OpenDialog(request, this.modalService, function (result) {
                            if (result) {
                                _this.saveEmploymentPayroll(effectiveDate);
                            }
                            else {
                                _this.loadSubsection();
                            }
                        });
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.saveEmploymentPayroll(effectiveDate)];
                    case 4:
                        _b.sent();
                        InfoDialogComponent.OpenDialog('Warning', 'The changes you have made to the employee record could prompt a change to the employee Benefit Class. Please contact your Benefits Administrator and advise them of the changes.', this.modalService);
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsPayrollComponent.prototype.isValidEffectiveDate = function (date) {
        var minDate;
        var maxDate;
        var isValid = true;
        if (_.isNull(date) || _.isUndefined(date)) {
            isValid = false;
        }
        if (!dateTimeUtils.validateDate(date) && isValid) {
            isValid = false;
        }
        if ((this.m_employeeSectionsPayroll.isNotOnlyPayRate() || this.m_employeeSectionsPayroll.isOnlyPayRate()) && this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.effectiveDateData) {
            if (this.m_employeeSectionsPayroll.isOnlyPayRate()) {
                if (this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.minDate) {
                    minDate = this.m_employeeSectionsPayroll.effectiveDateSetting.minDate;
                }
                if (this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.maxDate) {
                    maxDate = this.m_employeeSectionsPayroll.effectiveDateSetting.maxDate;
                }
            }
            if (this.m_employeeSectionsPayroll.isNotOnlyPayRate()) {
                if (this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.minDate2) {
                    minDate = this.m_employeeSectionsPayroll.effectiveDateSetting.minDate2;
                }
                if (this.m_employeeSectionsPayroll.effectiveDateSetting && this.m_employeeSectionsPayroll.effectiveDateSetting.maxDate2) {
                    maxDate = this.m_employeeSectionsPayroll.effectiveDateSetting.maxDate2;
                }
            }
            if (isValid && minDate && !this.isValidMinDate(date, minDate)) {
                isValid = false;
            }
            if (isValid && maxDate && !this.isValidMaxDate(date, maxDate)) {
                isValid = false;
            }
            if (isValid && this.disabledDate(date)) {
                isValid = false;
            }
        }
        return isValid;
    };
    EmployeeSectionsPayrollComponent.prototype.isValidMinDate = function (date, minDate) {
        if (!_.isDate(minDate)) {
            return true;
        }
        var momentDate = moment(date);
        return moment(minDate).startOf('day').isSameOrBefore(momentDate.startOf('day'));
    };
    EmployeeSectionsPayrollComponent.prototype.isValidMaxDate = function (date, maxDate) {
        if (!_.isDate(maxDate)) {
            return true;
        }
        var momentDate = moment(date);
        return moment(maxDate).startOf('day').isSameOrAfter(momentDate.startOf('day'));
    };
    EmployeeSectionsPayrollComponent.prototype.addProjectedDates = function (enabledDates, startDate, endDate, paycycleInterval) {
        while (moment(startDate) <= moment(endDate)) {
            enabledDates.push(startDate);
            startDate = moment(startDate).add(paycycleInterval, 'days').toDate();
        }
        return enabledDates;
    };
    EmployeeSectionsPayrollComponent.prototype.payRateChange = function (value) {
        var payRate = this.decimalLimit === 4 ? value.toFixed(4) : value.toFixed(2);
        if (!_.isEqual(this.m_employeeSectionsPayroll.payRate.fieldValue, payRate)) {
            this.m_employeeSectionsPayroll.payRate.fieldValue = payRate;
            this.m_employeeSectionsPayroll.payRate.isDirty = true;
        }
    };
    EmployeeSectionsPayrollComponent.prototype.setDecimalLimit = function (isEnableRate4DecimalPlaces) {
        if (isEnableRate4DecimalPlaces) {
            this.decimalLimit = 4;
        }
        else
            this.decimalLimit = 2;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsPayrollComponent.prototype, "birthDateSubscriptions", void 0);
    return EmployeeSectionsPayrollComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsPayrollComponent };
