export enum AppSettingKey {
  thresholdSeconds = 'thresholdSeconds', //number
  timeoutSeconds = 'timeoutSeconds', //number
  PbjEnabled = 'PbjEnabled', //'True'|'False'
  PbjMode = 'PbjMode', //'Manual'|'Auto'
  bswiftIntegrationEnabled = 'bswiftIntegrationEnabled'
}

export enum PbjModes {
  Manual = 'Manual',
  Auto = 'Auto'
}
