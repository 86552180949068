export interface IExportPayrollTimeInterval {
    organizationPayrollId: number;
    intervalSeconds: number;
    payrollExportId: number;
}

export class ExportPayrollTimeInterval { 
    public organizationPayrollId: number;
    public intervalSeconds: number;
    public payrollExportId: number;
}