import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { LookupApiService } from '../lookup/lookup-api.service';
import { PayCycle } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';

@Injectable()
export class PayCycleHelperService {
  constructor(private lookupService: LookupApiService) { }

  public getPayCycleByDate(date: Date, orgLevelId: number, employeeId?: number, updateCacheForced: boolean = false): Promise<PayCycle> {
    return this.lookupService.getPayCyles(orgLevelId, employeeId, undefined, updateCacheForced)
      .then((cycles: PayCycle[]) => {
        return this.findPayCycleByDate(cycles, date);
      });
  }

  public getLastPayCycleByDate(date: Date, orgLevelId: number, employeeId?: number, updateCacheForced: boolean = false): Promise<PayCycle> {
    return this.lookupService.getPayCyles(orgLevelId, employeeId, undefined, updateCacheForced)
      .then((cycles: PayCycle[]) => {
        return this.findLastPayCycleByDate(cycles, date);
      });
  }

  public getUnApprovedOrCurrentPayCycleByDate(date: Date, orgLevelId: number, employeeId?: number): Promise<PayCycle> {
    return this.lookupService.getPayCyles(orgLevelId, employeeId)
      .then((cycles: PayCycle[]) => {
        return this.findUnApprovedOrCurrentPayCycleByDate(cycles, date);
      });
  }

  public findPayCycleByDate(cycles: PayCycle[], date: Date): PayCycle {
    let selectedCycle: PayCycle = _.find(_.orderBy(cycles, (p: PayCycle) => p.startDate, 'asc'), (cycle: PayCycle) => {
      return moment(date).isSameOrAfter(cycle.startDate) && moment(date).isSameOrBefore(cycle.endDate);
    });
    return selectedCycle;
  }

  public findLastPayCycleByDate(cycles: PayCycle[], date: Date): PayCycle {
    const ordered = _.orderBy(cycles, (p: PayCycle) => p.startDate, 'asc');
    let selectedCycle: PayCycle = _.find(ordered, (cycle: PayCycle) => {
      return moment(date).isSameOrAfter(cycle.startDate) && moment(date).isSameOrBefore(cycle.endDate);
    });
    if(!selectedCycle) {
      return _.last(ordered);
    }
    return selectedCycle;
  }

  public findUnApprovedOrCurrentPayCycleByDate(cycles: PayCycle[], date: Date): PayCycle {
    let ordered = _.orderBy(cycles, (p: PayCycle) => p.startDate, 'asc');
    let index = _.findIndex(ordered, (cycle: PayCycle) => {
      return moment(date).isSameOrAfter(cycle.startDate) && moment(date).isSameOrBefore(cycle.endDate);
    });
    if (index < 0) {
      return null;
    }
    if (index > 1) {
      let prevPayCycle = ordered[index - 1];
      if (!prevPayCycle.isApproved) {
        return prevPayCycle;
      }
    }
    return ordered[index];
  }

  public async getEffectivePunchesDateRange(selectedDate: Date, orgLevelId: number): Promise<any> {
    const allPayCycles = await this.lookupService.getPayCyles(orgLevelId, null);
    let payCycles = _.filter(allPayCycles, pc => moment(selectedDate).isSameOrAfter(pc.startDate) &&  moment(selectedDate).isSameOrBefore(pc.endDate));
    let outOfPayCycle = false;
    if(payCycles.length === 0 ) {
      outOfPayCycle = true;
    }

    let effectiveStartDate: Date = _.min(_.map(payCycles, (payCycle: PayCycle) => payCycle.startDate));
    let effectiveEndDate: Date = _.max(_.map(payCycles, (payCycle: PayCycle) => payCycle.endDate));
    let yesterday = moment(new Date()).startOf('day').subtract(1, 'days');
    if (outOfPayCycle || moment(effectiveEndDate).isSameOrAfter(yesterday)) {
      effectiveEndDate = yesterday.toDate();
      let alternativeStartLimit = moment(effectiveEndDate).subtract(30, 'days');
      let alternativePayCycles: PayCycle[] = _.filter(allPayCycles, (payCycle: PayCycle) => {
        return moment(payCycle.startDate).isSameOrAfter(alternativeStartLimit) && moment(payCycle.startDate).isSameOrBefore(effectiveEndDate);
      });
      effectiveStartDate = _.min(_.map(alternativePayCycles, (payCycle: PayCycle) => payCycle.startDate));
    }
    return { startDate: _.min([effectiveStartDate, effectiveEndDate]), endDate: _.max([effectiveStartDate, effectiveEndDate]) };

  }

}
