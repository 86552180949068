<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  (dataStateChange)="gridState.dataStateChange($event)"

  [slxKendoGridFiltersState]="gridState"
  (kendoGridStateChanged)="onGridStateChanged($event)"
>
  <kendo-grid-column
    title="Employee Name"
    field="employee.name"
    width="150"
    [locked]="isLocked"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="wc-emp" [title]="dataItem.employee?.name">
        <img class="wc-emp__img" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
        <span class="wc-emp__text">{{ dataItem.employee?.name }}</span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    title="Facility"
    field="organization.name"
    width="190"
    filter="text"
    [hidden]="!isVisible('organization')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Facility</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Position"
    field="position.name"
    width="180"
    filter="text"
    [hidden]="!isVisible('position')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['mobile']"
    title="Department"
    field="department.name"
    width="180"
    filter="text"
    [hidden]="!isVisible('department')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Department</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Hire Date"
    field="dateHired"
    width="110"
    filter="date"
    [hidden]="!isVisible('dateHired')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Hire Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.dateHired | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Date of Birth"
    field="dateOfBirth"
    width="110"
    filter="date"
    [hidden]="!isVisible('dateOfBirth')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Date of Birth</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.dateOfBirth | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Age"
    field="empAge"
    width="90"
    filter="numeric"
    [hidden]="!isVisible('empAge')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Age</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.empAge }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Date of Incident"
    field="incidentDateTime"
    width="120"
    filter="date"
    [hidden]="!isVisible('incidentDate')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Date of Incident</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="wc-link" (click)="onOpenReport(dataItem)">
        {{ dataItem.incidentDateTime | amDateFormat: appConfig.dateFormat }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Time of Incident"
    field="incidentDateTime"
    width="100"
    filter="date"
    [hidden]="!isVisible('incidentTime')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Time of Incident</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.incidentDateTime | amDateFormat: appConfig.timeFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Report Status"
    field="reportStatus.name"
    width="120"
    filter="text"
    [hidden]="!isVisible('reportStatus')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Report Status</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.reportStatus?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Injury Type"
    field="injuryType.description"
    width="120"
    filter="text"
    [hidden]="!isVisible('injuryType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Injury Type</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.injuryType?.description }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Loss of Consciousness"
    field="isLossConsciousness"
    width="90"
    filter="boolean"
    [hidden]="!isVisible('isLossConsciousness')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Loss of Consciousness</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isLossConsciousness }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Carrier Name"
    field="carrierName"
    width="120"
    filter="text"
    [hidden]="!isVisible('carrierName')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Carrier Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.carrierName }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Location of Injury"
    field="injuryLocation.name"
    width="120"
    filter="text"
    [hidden]="!isVisible('injuryLocation')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Location of Injury</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.injuryLocation?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="What Was the Co-Worker Doing At the Time of Incident"
    field="whatDoingDuringInjury"
    width="200"
    filter="text"
    [hidden]="!isVisible('whatDoingDuringInjury')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>What Was the Co-Worker Doing At the Time of Incident</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.whatDoingDuringInjury }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Workers' Comp"
    field="isWorkersComp"
    width="90"
    filter="boolean"
    [hidden]="!isVisible('isWorkersComp')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Workers' Comp</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isWorkersComp }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Workers' Comp Case #"
    field="isWorkersComp"
    width="120"
    filter="text"
    [hidden]="!isVisible('workersCompCase')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Workers' Comp Case #</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem></ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="OSHA"
    field="isOshaRecordable"
    width="90"
    filter="text"
    [hidden]="!isVisible('isOshaRecordable')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>OSHA</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isOshaRecordable }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="OSHA Case #"
    field="oshaCaseNumber"
    width="120"
    filter="text"
    [hidden]="!isVisible('oshaCaseNumber')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>OSHA Case #</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.oshaCaseNumber }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Proper Safety Equipment"
    field="isUsedProperSafety"
    width="90"
    filter="boolean"
    [hidden]="!isVisible('isUsedProperSafety')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Proper Safety Equipment</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isUsedProperSafety }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Prior Injury"
    field="isPriorInjury"
    width="90"
    filter="boolean"
    [hidden]="!isVisible('isPriorInjury')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Prior Injury</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isPriorInjury }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Exposed to Bodily Fluids"
    field="isExposedToFluids"
    width="90"
    filter="boolean"
    [hidden]="!isVisible('isExposedToFluids')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Exposed to Bodily Fluids</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isExposedToFluids }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Medical Treatment Received"
    field="medicalTreatment.name"
    width="130"
    filter="text"
    [hidden]="!isVisible('medicalTreatment')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Medical Treatment Received</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.medicalTreatment?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']"
    title="Privacy Case"
    field="isPrivacyCaseInjury"
    width="90"
    filter="boolean"
    [hidden]="!isVisible('isPrivacyCaseInjury')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Privacy Case</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.isPrivacyCaseInjury }}</ng-template>
  </kendo-grid-column>


  <kendo-grid-column *slxHiddenOn="['desktop']"
    title=""
    class="overflow-visible-cell"
    [width]="50"
    [filterable]="false"
    [sortable]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="m-incident">
        <span class="m-incident__btn"
          [popper]="incidentInto"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-end'"
          [popperDisableStyle]="'true'"
        >
          <i class="fas fa-list-ul" aria-hidden="true"></i>
        </span>
        <popper-content #incidentInto class="slx-popper">
          <section class="m-incident__popper">
            <div class="flex flex-direction-row m-incident__group" *slxHiddenOn="['tablet']">
              <p class="m-incident__field">
                <span class="m-incident__title">Position</span>
                <span class="m-incident__value">{{ dataItem.position?.name }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Department</span>
                <span class="m-incident__value">{{ dataItem.department?.name }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">Hire Date</span>
                <span class="m-incident__value">{{ dataItem.dateHired | amDateFormat: appConfig.dateFormat }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Date of Birth</span>
                <span class="m-incident__value">{{ dataItem.dateOfBirth | amDateFormat: appConfig.dateFormat }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">Age</span>
                <span class="m-incident__value">{{ dataItem.empAge }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Date of Incident</span>
                <span class="m-incident__value">
                  <span class="wc-link" (click)="onOpenReport(dataItem)">{{ dataItem.incidentDateTime | amDateFormat: appConfig.dateFormat }}</span>
                </span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">Time of Incident</span>
                <span class="m-incident__value">{{ dataItem.incidentDateTime | amDateFormat: appConfig.timeFormat }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Report Status</span>
                <span class="m-incident__value">{{ dataItem.reportStatus?.name }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">Injury Type</span>
                <span class="m-incident__value">{{ dataItem.injuryType?.description }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Loss of Consciousness</span>
                <span class="m-incident__value">{{ dataItem.isLossConsciousness }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">Carrier Name</span>
                <span class="m-incident__value">{{ dataItem.carrierName }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Location of Injury</span>
                <span class="m-incident__value">{{ dataItem.injuryLocation?.name }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">What Was the Co-Worker Doing At the Time of Incident</span>
                <span class="m-incident__value">{{ dataItem.whatDoingDuringInjury }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Workers' Comp</span>
                <span class="m-incident__value">{{ dataItem.isWorkersComp }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">Workers' Comp Case #</span>
                <span class="m-incident__value"></span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">OSHA</span>
                <span class="m-incident__value">{{ dataItem.isOshaRecordable }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">OSHA Case #</span>
                <span class="m-incident__value">{{ dataItem.oshaCaseNumber }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Proper Safety Equipment</span>
                <span class="m-incident__value">{{ dataItem.isUsedProperSafety }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">Prior Injury</span>
                <span class="m-incident__value">{{ dataItem.isPriorInjury }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Exposed to Bodily Fluids</span>
                <span class="m-incident__value">{{ dataItem.isExposedToFluids }}</span>
              </p>
            </div>
            <div class="flex flex-direction-row m-incident__group">
              <p class="m-incident__field">
                <span class="m-incident__title">Medical Treatment Received</span>
                <span class="m-incident__value">{{ dataItem.medicalTreatment?.name }}</span>
              </p>
              <p class="m-incident__field">
                <span class="m-incident__title">Privacy Case</span>
                <span class="m-incident__value">{{ dataItem.isPrivacyCaseInjury }}</span>
              </p>
            </div>
          </section>
        </popper-content>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel fileName="Workers' Comp Incident Tracker.xlsx" [fetchData]="retriveAllPages()"></kendo-grid-excel>
  <kendo-grid-pdf fileName="Workers' Comp Incident Tracker.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>
</kendo-grid>
