import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Assert } from '../../../framework/index';
import { AuthApiService } from '../auth-api/auth-api.service';
import { appConfig } from '../../../app.config';

@Injectable()
export class ErrorHandlingApiService {

  public constructor(private authApiService: AuthApiService) {
  }

  public sendToServer(title: string, message: string, details?: string|any): Promise<any> {
    Assert.isNotNull(message, 'message');

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/technical/logger/error`;

    let request: HttpRequest<any> = new HttpRequest('POST', url, {
      title: title,
      message: message,
      details: this.convertToJson(details || '')
    });

    return this.authApiService.request(request)
              .catch((ex: any) => console.error(ex));
  }

  private convertToJson(details: any): string {
    // Note: cache should not be re-used by repeated calls to JSON.stringify.
    let cache: any = [];
    let res: string;
    try {
      res = JSON.stringify(details, function(key, value): any {
        if (typeof value === 'object' && value !== null) {
          if (cache.indexOf(value) !== -1) {
            // Circular reference found, discard key
            return;
          }
          // Store value in our collection
          cache.push(value);
        }
        return value;
      });
    } catch (ex) {
      console.log(ex);
      res = details.message;
    }
    cache = null; // Enable garbage collection
    return res;
  }

}
