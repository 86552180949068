import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../framework/index';
import { mutableSelect } from '../../../core/decorators/index';
import { ManagementBaseService } from '../../../core/services/index';
import { ModalService } from '../../../common/services/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { LoaRequest, LoaRequestDialogOptions, LoaRequestTabs } from '../models/index';
import { LMCreationDialogComponent } from '../components/lm-creation-absence/lm-creation-dialog/lm-creation-dialog.component';
import { LoaRequestDropDownItem, loaDropdownItems, LoaRequestClass } from '../../../common/models';

@Injectable()
export class LMManagementService extends ManagementBaseService<any, any> {
  public static leaveTypeMobileWidth = 30;
  public static leaveTypeDesktopWidth = 130;

  public canAddEdit: boolean;
  public canDelete: boolean;

  public orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  private loadRequests$ = new Subject<void>();
  private navButton$ = new Subject<boolean>();
  private orgLevel: OrgLevel;
  private leaveTypesMap: StringMap<LoaRequestDropDownItem>;
  private initialized: boolean = false;

  constructor(
    private modalService: ModalService
  ) {
    super();
  }

  public init(): void {
    if (!this.initialized) {
      this.initialized = true;

      this.leaveTypesMap = _.keyBy(this.getRequestTypes(), (v: LoaRequestDropDownItem) => v.id.toString());

      this.subscriptions.orgLevel = this.orgLevel$
        .filter((o: OrgLevel) => o && _.isFinite(o.id))
        .subscribe((orgLevel: OrgLevel) => {
          if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;

          this.orgLevel = orgLevel;
          this.orgLevelChanged$.next(orgLevel);
        });
    }
  }

  public getRequestTypes(): LoaRequestDropDownItem[] {
    return loaDropdownItems;
  }

  public getIcon(classId: LoaRequestClass): string {
    if(!classId) {
      return null;
    }
    const desc = this.leaveTypesMap[classId];
    if(!desc) {
      return null;
    }
    return desc.icon;
  }

  public openCreationDialog(requestClass: LoaRequestClass, req: LoaRequest = null, loaRequestTabs: LoaRequestTabs = LoaRequestTabs.Dates): void {
    let loaRequest = req;
    if (!_.isObjectLike(loaRequest)) {
      loaRequest = new LoaRequest();
      loaRequest.requestClass = requestClass;
      loaRequest.requestDate = new Date();
    }
    loaRequest.loaRequestTabs = loaRequestTabs;
    const options = new LoaRequestDialogOptions(this.orgLevel.id, loaRequest);
    LMCreationDialogComponent.openDialog(options, this.modalService);
  }

  public modifyRequest(request: LoaRequest): void {
      window.alert('Will be opened dialog for edit Leave Request ' + request.id);
  }

  public loadRequests(): void {
    this.loadRequests$.next();
  }

  public subscribeToLoadRequests(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadRequests$.subscribe(callback);
  }

  public navButtonClick(isNext: boolean): void {
    this.navButton$.next(isNext);
  }

  public subscribeToNavButtonClick(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.navButton$.subscribe(callback);
  }
}
