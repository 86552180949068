import { Injectable } from '@angular/core';
import {
  IPBJExportLogRecord, PBJExportLogRecord, IPBJExportLogCensusData, PBJExportLogCensusData,
  IPBJExportLogEmployeeData, PBJExportLogEmployeeData,
  IPBJExportLogError, PBJExportLogError,
  IPBJExportLogStaffingHours, PBJExportLogStaffingHours,
  IPBJExportLogHeader, PBJExportLogHeader,
  IPBJExportResult, PBJExportResult,
  IPBJExportRequest, PBJExportRequest,
  IPBJExportContainer, PBJExportContainer, PBJBatchExport, IPBJBatchExport
} from '../../models/index';
import { LookupMapService } from '../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import * as _ from 'lodash';
import { IPBJExportParentGrid, PBJExportParentGrid } from '../../models/pbj-export/pbj-export-parent-grid';
import { IPBJExportResultFlagEnabled, PBJExportResultFlagEnabled } from '../../models/pbj-export/pbj-export-result-flag-enabled';
import { IPBJExportChildGrid, PBJExportChildGrid } from '../../models/pbj-export/pbj-export-child-grid';
import { IPBJExportChildResult, PBJExportChildResult } from '../../models/pbj-export/pbj-export-child-result';
import { IPBJExportCSV, PBJExportCSV } from '../../models/pbj-export/pbj-export-csv';
import { IPBJDuplicateWorkers, PBJDuplicateWorkers } from '../../models/pbj-duplicate-workers/pbj-duplicate-workers';

@Injectable()
export class PbjExportMapService {

  constructor(private lookupMapService: LookupMapService) { }
  public mapPBJExportLogRecords(recordsDto: IPBJExportLogRecord[]): PBJExportLogRecord[] {
    return _.map(recordsDto, (dto: IPBJExportLogRecord) => this.mapPBJExportLogRecord(dto));
  }

  public mapPBJExportLogRecord(dto: IPBJExportLogRecord): PBJExportLogRecord {
    let rec: PBJExportLogRecord = new PBJExportLogRecord();
    rec.exportId = dto.exportId;
    rec.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    rec.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    rec.exportDate = dateTimeUtils.convertFromDtoString(dto.exportDate);
    rec.organizationName = dto.organizationName;
    rec.organization = this.lookupMapService.mapOrganization({ id: dto.organizationId, orgLevelId: dto.orgLevelId, name: dto.organizationName });
    rec.staffingType = dto.staffingType;
    rec.censusType = dto.censusType;
    rec.containsEmployeeInfo = dto.containsEmployeeInfo;
    rec.containsStaffingInfo = dto.containsStaffingInfo;
    rec.containsCensusInfo = dto.containsCensusInfo;
    rec.details = dto.details;
    rec.exportedBy = dto.exportedBy;
    return rec;
  }

  public mapPBJExportLogCensusData(dto: IPBJExportLogCensusData): PBJExportLogCensusData {
    let rec: PBJExportLogCensusData = new PBJExportLogCensusData();
    rec.exportId = dto.exportId;
    rec.organizationId = dto.organizationId;
    rec.monthEndDate = dateTimeUtils.convertFromDtoString(dto.monthEndDate);
    rec.medicaid = dto.medicaid;
    rec.medicare = dto.medicare;
    rec.other = dto.other;
    return rec;
  }

  public mapPBJExportLogEmployeeData(dto: IPBJExportLogEmployeeData): PBJExportLogEmployeeData {
    let rec: PBJExportLogEmployeeData = new PBJExportLogEmployeeData();
    rec.exportId = dto.exportId;
    rec.pbjId = dto.pbjId;
    rec.employeeMasterId = dto.employeeMasterId;
    rec.employeeName = dto.employeeName;
    rec.payrollNumber = dto.payrollNumber;
    return rec;
  }

  public mapPBJExportLogError(dto: IPBJExportLogError): PBJExportLogError {
    let rec: PBJExportLogError = new PBJExportLogError();
    rec.exportId = dto.exportId;
    rec.exportedBy = dto.exportedBy;
    rec.employeeMasterId = dto.employeeMasterId;
    rec.pbjId = dto.pbjId;
    rec.employeeId = dto.employeeId;
    rec.employeeName = dto.employeeName;
    rec.employeePayrollNumber = dto.employeePayrollNumber;
    rec.errorId = dto.errorId;
    rec.section = dto.section;
    rec.field = dto.field;
    rec.errorMessage = dto.errorMessage;
    rec.referenceDate = dateTimeUtils.convertFromDtoString(dto.referenceDate);
    rec.entryType = dto.entryType;
    return rec;
  }

  public mapPBJExportLogStaffingHours(dto: IPBJExportLogStaffingHours): PBJExportLogStaffingHours {
    let rec: PBJExportLogStaffingHours = new PBJExportLogStaffingHours();
    rec.exportId = dto.exportId;
    rec.employeeMasterId = dto.employeeMasterId;
    rec.pbjId = dto.pbjId;
    rec.employeeId = dto.employeeId;
    rec.organizationName = dto.organizationName;
    rec.organization = this.lookupMapService.mapOrganization({ id: dto.organizationId, orgLevelId: 0, name: dto.organizationName });
    rec.employeeName = dto.employeeName;
    rec.payrollNumber = dto.payrollNumber;
    rec.workDate = dateTimeUtils.convertFromDtoString(dto.workDate);
    rec.workHours = dto.workHours;
    rec.cmsJobCode = dto.cmsJobCode;
    rec.cmsJobNameTitle = dto.cmsJobNameTitle;
    rec.exemptType = dto.exemptType;
    return rec;
  }

  public mapPBJExportLogHeader(dto: IPBJExportLogHeader): PBJExportLogHeader {
    let rec: PBJExportLogHeader = new PBJExportLogHeader();
    rec.exportId = dto.exportId;
    rec.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    rec.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    rec.exportDate = dateTimeUtils.convertFromDtoDateTimeString(dto.exportDate);

    rec.employeesCount = dto.employeesCount;
    rec.staffingHoursCount = dto.staffingHoursCount;
    rec.censusDataCount = dto.censusDataCount;
    rec.errorsCount = dto.errorsCount;
    rec.warningsCount = dto.warningsCount;
    rec.organization = this.lookupMapService.mapOrganization(dto.organization);
    rec.pbjIdConfigEnabled = dto.pbjidConfigEnabled;
    return rec;
  }

  public mapPBJExportResult(dto: IPBJExportResult): PBJExportResult {
    let rec: PBJExportResult = new PBJExportResult();
    rec.exportId = dto.exportId;
    rec.isSuccess = dto.isSuccess;
    rec.errorMessage = dto.errorMessage;
    return rec;
  }

  public mapPBJExportResultFlagEnabled(dto: IPBJExportResultFlagEnabled): PBJExportResultFlagEnabled {
    let rec: PBJExportResultFlagEnabled = new PBJExportResultFlagEnabled();
    rec.exportId = dto.exportId;
    rec.isSuccess = dto.isSuccess;
    rec.errorMessage = dto.errorMessage;
    rec.batchId = dto.batchId;
    rec.xMLData = dto.xMLData;
    rec.organizationId = dto.organizationId;
    rec.organizationName = dto.organizationName;
    rec.errorNumber = dto.errorNumber;
    return rec;
  }

  public mapPBJExportResultFlagEnabledRecords(recordsDto: IPBJExportResultFlagEnabled[]): IPBJExportResultFlagEnabled[] {
    return _.map(recordsDto, (dto: IPBJExportResultFlagEnabled) => this.mapPBJExportResultFlagEnabled(dto));
  }

  public mapPbjExportCSVRecords(recordsDto: IPBJExportResultFlagEnabled[]): IPBJExportCSV[] {
    return _.map(recordsDto, (dto: IPBJExportResultFlagEnabled) => this.mapPbjExportCSV(dto));
  }

  public mapPbjExportCSV(dto: IPBJExportResultFlagEnabled): PBJExportCSV {
    let rec: PBJExportCSV = new PBJExportCSV();
    rec.Error_ID = dto.errorNumber;
    rec.Error_Description = dto.errorMessage;
    rec.Location = dto.organizationName;
    return rec;
  }

  public mapPBJExportContainer(dto: IPBJExportContainer): PBJExportContainer {
    let rec: PBJExportContainer = new PBJExportContainer();
    rec.exportId = dto.exportId;
    rec.fileName = dto.fileName;
    rec.data = dto.data;
    return rec;
  }

  public mapPBJExportRequestDto(data: PBJExportRequest): IPBJExportRequest {
    let dto: IPBJExportRequest = {
      fiscalYear: data.fiscalYear,
      exportRange: data.exportRange.toString(),
      startDate: dateTimeUtils.convertToDtoString(data.startDate),
      endDate: dateTimeUtils.convertToDtoString(data.endDate),
      includeEmployee: data.includeEmployee,
      includeStaffingHours: data.includeStaffingHours,
      staffingHoursExportType: data.staffingHoursExportType.toString(),
      orgLevelId: data.orgLevelId,
      parentId: data.parentId
    };
    return dto;
  }

  public mapPBJBatchExportDto(data: PBJBatchExport): IPBJBatchExport {
    let dto: IPBJBatchExport = {
      startDate: dateTimeUtils.convertToDtoString(data.startDate),
      endDate: dateTimeUtils.convertToDtoString(data.endDate),
      orgLevelId: data.orgLevelId
    };
    return dto;
  }

  public mapPBJParentGridExportRecords(recordsDto: IPBJExportParentGrid[]): PBJExportParentGrid[] {
    return _.map(recordsDto, (dto: IPBJExportParentGrid) => this.mapPBJParentGridExportDto(dto));
  }
  public mapPBJParentGridExportDto(data:IPBJExportParentGrid): PBJExportParentGrid {
    let dto: PBJExportParentGrid = new PBJExportParentGrid();
      dto.batchId = data.batchId,
      dto.parentOrgName = data.parentOrgName,
      dto.exportDate = new Date(data.exportDate),
      dto.startDate = new Date(data.startDate),
      dto.endDate = new Date(data.endDate),
      dto.staffingType = data.staffingType,
      dto.employeeInfo = data.employeeInfo === 'True' ? 'Y' : 'N',
      dto.staffingInfo = data.staffingInfo === 'True' ? 'Y' : 'N',
      dto.userName = data.userName,
      dto.batchStatus =  (data.batchStatus!='SUCCESS' && data.batchStatus!='WAITING' && data.batchStatus!='EXPIRED' && data.batchStatus!='CANCELLED' && data.batchStatus!='FAILED' && data.batchStatus!='COMPLETED WITH ERRORS' && JSON.parse(data.batchStatus) > 0 && JSON.parse(data.batchStatus) < 100) ? 'Inprogress' : data.batchStatus=='SUCCESS' ? 'Completed' : data.batchStatus,
      dto.batchProgress = data.batchProgress === null ? 0 : data.batchProgress
    return dto;
  }

  public mapPBJEmployeeDuplicates(recordsDto: IPBJDuplicateWorkers[]): PBJDuplicateWorkers[] {
    return _.map(recordsDto, (dto: IPBJDuplicateWorkers) => this.mapPBJEmployeeDuplicateDto(dto));
  }
  
  public mapPBJEmployeeDuplicateDto(data: IPBJDuplicateWorkers): PBJDuplicateWorkers{
    let dto: PBJDuplicateWorkers = new PBJDuplicateWorkers();
    dto.location = data.location,
    dto.pbJ_ID_1 =data.pbJ_ID_1,
    dto.first_Name_1 =data.first_Name_1,
    dto.last_name_1 =data.last_name_1,
    dto.position_1 = data.position_1,
    dto.email_1 = data.email_1,
    dto.birthday_1 = new Date(data.birthday_1),
    dto.phone_1 = data.phone_1,
    dto.ssN_1 = data.ssN_1,
    dto.emp_id_1 = data.emp_id_1,
    dto.primary_Location_1 = data.primary_Location_1,
    dto.emp_dt_hire_1 = data.emp_dt_hire_1,
    dto.pbJ_ID_2 =data.pbJ_ID_2,
    dto.first_Name_2 =data.first_Name_2,
    dto.last_name_2 =data.last_name_2,
    dto.position_2 = data.position_2,
    dto.email_2 = data.email_2,
    dto.phone_2 = data.phone_2,
    dto.birthday_2 = new Date(data.birthday_2),
    dto.ssN_2 = data.ssN_2,
    dto.emp_id_2 = data.emp_id_2,
    dto.primary_Location_2 = data.primary_Location_2,
    dto.emp_dt_hire_2 = data.emp_dt_hire_2,
    dto.name_Matching = data.name_Matching,
    dto.phone_Matching = data.phone_Matching,
    dto.email_Matching = data.email_Matching,
    dto.birthday_matching = data.birthday_matching,
    dto.matching_score = data.matching_score,
    dto.ssN_Matching = data.ssN_Matching,
    dto.disposition = data.disposition,
    dto.disposition_Date = data.disposition_Date!=null ? new Date(data.disposition_Date) : null,
    dto.primary_org_level_id = data.primary_org_level_id,
    dto.matches_Export_Date = data.matches_Export_Date
    return dto;
  }
  public mapPBJChildExportDto(data: PBJExportChildGrid): IPBJExportChildGrid {
    let dto: IPBJExportChildGrid = {
      batchID: data.batchID,
      orgLevelId: data.orgLevelId ,
      startDate: data.startDate,
     endDate : data.endDate
    };
    return dto;
  }

  public mapPBJChildGridExportRecords(recordsDto: IPBJExportChildResult[]): PBJExportChildResult[] {
    return _.map(recordsDto, (dto: IPBJExportChildResult) => this.mapPBJChildGridSingleRecord(dto));
  }
  public mapPBJChildGridSingleRecord(data:IPBJExportChildResult): PBJExportChildResult {
    let dto: PBJExportChildResult = new PBJExportChildResult();
      dto.exportStatus = data.exportStatus == 'INITIATED' ? 'Inprogress': data.exportStatus=='SUCCESS' ? 'Completed' : data.exportStatus,
      dto.exportID = data.exportID,
      dto.organizationName = data.organizationName,
      dto.processStartDate = data.processStartDate ? new Date(data.processStartDate): null,
      dto.processEndDate = data.processEndDate ? new Date(data.processEndDate): null,
      dto.processingTime = this.getProcessingTime(data.processStartDate, data.processEndDate)
    dto.details = data.details
    dto.exportProgress = data.exportProgress === null ? 0 : data.exportProgress
    return dto;
  }

  getProcessingTime(startDate, endDate){
    if(startDate==undefined || endDate==undefined){
      return null;
    }

    let start = new Date(startDate);
    let end = new Date(endDate);
    var diff:any;
    diff = end.valueOf() - start.valueOf();
    return(diff/1000/60);
  }
}

