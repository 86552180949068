
export enum EmployeeWarningSections {
  MainSection = 'MainSection',
  StandardViolations = 'StandardViolations',
  StandardActions = 'StandardActions',
  StandardCompanyRemarks = 'StandardCompanyRemarks',
  StandardEmployeeRemarks = 'StandardEmployeeRemarks',
  CustomViolationsAB = 'ViolationsAB',
  CustomIncidentDescription = 'IncidentDescription'
}

export interface IEmployeeWarningSectionBasic {
  type: EmployeeWarningSections;
  label: string;
}

export class EmployeeWarningSectionBasic {
  constructor(public type: EmployeeWarningSections, public label: string) {}

  public get isStandardViolations(): boolean {
    return this.type === EmployeeWarningSections.StandardViolations;
  }

  public get isStandardActions(): boolean {
    return this.type === EmployeeWarningSections.StandardActions;
  }

  public get isStandardCompanyRemarks(): boolean {
    return this.type === EmployeeWarningSections.StandardCompanyRemarks;
  }

  public get isStandardEmployeeRemarks(): boolean {
    return this.type === EmployeeWarningSections.StandardEmployeeRemarks;
  }

  public get isCustomViolationsAB(): boolean {
    return this.type === EmployeeWarningSections.CustomViolationsAB;
  }

  public get isCustomIncidentDescription(): boolean {
    return this.type === EmployeeWarningSections.CustomIncidentDescription;
  }
}


export interface IEmployeeWarningViolation {
  id: number;
  label: string;
  selected: boolean;
}

export class EmployeeWarningViolation {
  constructor(public id: number, public label: string, public selected: boolean) {}
}

export interface IEmployeeWarningAction {
  id: number;
  label: string;
  selected: boolean;
  notes: string;
}

export class EmployeeWarningAction {
  constructor(public id: number, public label: string, public selected: boolean, public notes: string) {}
}


export interface IEmployeeWarningSectionViolations extends IEmployeeWarningSectionBasic {
  data: {
    dateOfViolation: string;
    violations: Array<IEmployeeWarningViolation>;
  };
}

export class EmployeeWarningSectionViolations extends EmployeeWarningSectionBasic {
  public data: {
    dateOfViolation: Date;
    violations: Array<EmployeeWarningViolation>;
  };
}

export interface IEmployeeWarningSectionActions extends IEmployeeWarningSectionBasic {
  data: {
    actions: Array<IEmployeeWarningAction>;
    numberOfDays: string;
    actionNotes: string;
    approveDate: string;
    actionDate: string;
    approveTitle: string;
    approvedBy: string;
    employeeSignature: string;
    issuerTitle: string;
    issuerSignature: string;
    witnessSignature: string;
    supervisorSignature: string;
    employeeSignDate: string;
    issuerSignDate: string;
    witnessSignDate: string;
    supervisorSignDate: string;
  };
}

export class EmployeeWarningSectionActions extends EmployeeWarningSectionBasic {
  public data: {
    actions: Array<EmployeeWarningAction>;
    numberOfDays: string;
    commentForNumberOfDays: string;
    actionDate: Date;
    actionTitle: string;
    approvedBy: string;
    approveDate: Date;
    employeeSignature: string;
    employeeTitle: string;
    creatorSignature: string;
    witnessSignature: string;
    supervisorSignature: string;
    employeeSignDate: Date;
    issuerSignDate: Date;
    witnessSignDate: Date;
    supervisorSignDate: Date;
  };
}

export interface IEmployeeWarningSectionCompanyRemarks extends IEmployeeWarningSectionBasic {
  data: {
    companyRemarks: string;
  };
}

export class EmployeeWarningSectionCompanyRemarks extends EmployeeWarningSectionBasic {
  public data: {
    companyRemarks: string;
  };
}

export interface IEmployeeWarningSectionEmployeeRemarks extends IEmployeeWarningSectionBasic {
  data: {
    employeeRemarks: string;
    employeeSign: string;
    employeeSignDate: string;
  };
}

export class EmployeeWarningSectionEmployeeRemarks extends EmployeeWarningSectionBasic {
  public data: {
    employeeRemarks: string;
    employeeSign: string;
    employeeSignDate: Date;
  };
}

export interface IEmployeeWarningSectionViolationsAB extends IEmployeeWarningSectionBasic {
  data: {
    violationNumber: string;
    violationType: boolean;
    absence: string;
    tardiness: string;
    violationDate: string;
  };
}

export class EmployeeWarningSectionViolationsAB extends EmployeeWarningSectionBasic {
  public data: {
    violationNumber: string;
    isPartAType: boolean;
    violationAbsence: string;
    violationTardiness: string;
    violationDate: Date;
  };
}

export interface IEmployeeWarningSectionIncidentDescription extends IEmployeeWarningSectionBasic {
  data: {
    description: string;
  };
}

export class EmployeeWarningSectionIncidentDescription extends EmployeeWarningSectionBasic {
  public data: {
    incidentDescription: string;
  };
}
