import { CustomPayCycleDropdownComponent } from './custom-paycycle-dropdown/custom-paycycle-dropdown.component';
export * from './organization-structure/organization-structure.component';
export * from './navigation-menu/navigation-menu.component';
export * from './schedule-cycle-slider/schedule-cycle-slider.component';
export * from './employee-shift-editor/employee-shift-editor.component';
export * from './orglevel-shift-editor/orglevel-shift-editor.component';
export * from './budget-dropdown-selector/budget-dropdown-selector.component';
export * from './org-level-breadcrumb/org-level-breadcrumb.component';
export * from './pay-cycle-dropdown-selector/pay-cycle-dropdown-selector.component';
export * from './paycycle-dropdown/paycycle-dropdown.component';
export * from './widgets/index';
export * from './org-level-search/org-level-search.component';
export * from './org-level-search-dialog/org-level-search-dialog.component';
export * from './schedule-cycle-date-picker/schedule-cycle-date-picker.component';
export * from './schedule-cycle-calendar/schedule-cycle-calendar.component';
export * from './schedule-cycle-calendar-slider/schedule-cycle-calendar-slider.component';
export * from './kendo-ui-extensions/index';
export * from './bottom-menu/bottom-menu.component';
export * from './lookup-multiselect/lookup-multiselect.component';
export * from './generic-list/index';
export * from './schedule-cycle-summary/index';
export * from './accrual-balance-dialog/accrual-balance-dialog.component';
export * from './favorites-menu/favorites-menu.component';
export * from './angular-plugin/angular-plugin.component';

import { OrganizationStructureComponent } from './organization-structure/organization-structure.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { ScheduleCycleSliderComponent } from './schedule-cycle-slider/schedule-cycle-slider.component';
import { EmployeeShiftEditorComponent } from './employee-shift-editor/employee-shift-editor.component';
import { OrglevelShiftEditorComponent } from './orglevel-shift-editor/orglevel-shift-editor.component';
import { BudgetSelectorDropdownComponent } from './budget-dropdown-selector/budget-dropdown-selector.component';
import { OrgLevelBreadcrumbComponent } from './org-level-breadcrumb/org-level-breadcrumb.component';
import { PayCycleDropdownSelectorComponent } from './pay-cycle-dropdown-selector/pay-cycle-dropdown-selector.component';
import { PayCycleDropdownComponent } from './paycycle-dropdown/paycycle-dropdown.component';
import { EmployeeDetailsComponent, EmployeeDetailsListComponent, EmployeeDetailsTableListComponent } from './widgets/index';
import { OrgLevelSearchComponent } from './org-level-search/org-level-search.component';
import { OrgLevelSearchDialogComponent } from './org-level-search-dialog/org-level-search-dialog.component';
import { ScheduleCycleDatePickerComponent } from './schedule-cycle-date-picker/schedule-cycle-date-picker.component';
import { ScheduleCycleCalendarComponent } from './schedule-cycle-calendar/schedule-cycle-calendar.component';
import { ScheduleCycleCalendarSliderComponent } from './schedule-cycle-calendar-slider/schedule-cycle-calendar-slider.component';
import { kendoComponents } from './kendo-ui-extensions/index';
import { BottomMenuComponent } from './bottom-menu/bottom-menu.component';
import { LookupMultiSelectComponent } from './lookup-multiselect/lookup-multiselect.component';
import { genericListComponents } from './generic-list/index';
import { generateScheduleSummaryComponents, entryGenerateSummaryComponents } from './schedule-cycle-summary/index';
import { AccrualBalanceDialogComponent } from './accrual-balance-dialog/accrual-balance-dialog.component';
import { FavoritesMenuComponent } from './favorites-menu/favorites-menu.component';
import { AngularPlugin } from './angular-plugin/angular-plugin.component';

export const entryComponents: any[] = [
  EmployeeDetailsListComponent,
  OrgLevelSearchDialogComponent,
  EmployeeDetailsTableListComponent,
  AccrualBalanceDialogComponent,
  ...entryGenerateSummaryComponents
];

export const exportComponents: any[] = [
  OrganizationStructureComponent,
  NavigationMenuComponent,
  ScheduleCycleSliderComponent,
  EmployeeShiftEditorComponent,
  OrglevelShiftEditorComponent,
  BudgetSelectorDropdownComponent,
  OrgLevelBreadcrumbComponent,
  PayCycleDropdownSelectorComponent, // should be removed after all use will be removed
  PayCycleDropdownComponent, // new design (should use this)
  EmployeeDetailsComponent,
  OrgLevelSearchComponent,
  ScheduleCycleDatePickerComponent,
  ScheduleCycleCalendarComponent,
  ScheduleCycleCalendarSliderComponent,
  BottomMenuComponent,
  LookupMultiSelectComponent,
  AccrualBalanceDialogComponent,
  CustomPayCycleDropdownComponent,
  FavoritesMenuComponent,
  ...genericListComponents,
  ...kendoComponents,
  ...entryComponents,
  ...generateScheduleSummaryComponents
];

export const components: any[] = [
  AngularPlugin,
  ...exportComponents
];
