import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';

import { appConfig } from '../../../../app.config';

import { unsubscribe, destroyService, mutableSelect } from './../../../../core/decorators/index';
import { ExceptionConsoleRollupManagementService } from './../../../services/exception-console/exception-console-rollup-management.service';
import { ExceptionConsoleRollupRecord, ExceptionConsoleRollupState } from './../../../models/index';
import { KendoGridStateHelper } from './../../../../common/models/index';
import { IControlState, IColumnSettings, StateResetTypes } from '../../../../core/models/index';

import { RangeDates, IRangeDates } from '../../../../common/models/range-dates';
import { StateManagementService, ComponentStateStorageService, ExceptionConsoleNavigationService } from '../../../../common/services/index';
import { PayCycle } from '../../../../organization/models/index';
import { OrgLevelWatchService } from '../../../../organization/services/org-level/org-level-watch.service';
import { OrgLevelType } from '../../../../state-model/models/org-level/org-level-type';
import { PayCycleHelperService } from '../../../../organization/services/index';
import { OrgLevel } from '../../../../state-model/models/org-level/org-level';
import { ExceptionConsoleRouteData } from '../../../models/exception-console/exception-console-route-data';

@Component({
  moduleId: module.id,
  selector: 'slx-exception-console-rollup',
  templateUrl: 'exception-console-rollup.component.html',
  styleUrls: ['exception-console-rollup.component.scss'],
  providers: [ExceptionConsoleRollupManagementService, StateManagementService]
})
export class ExceptionConsoleRollupComponent implements OnInit, OnDestroy {

  public gridState: KendoGridStateHelper<ExceptionConsoleRollupRecord>;
  public globalState: ExceptionConsoleRollupState;

  public state: {
    isLoading: boolean;
  };

  public usePayCycle: boolean = false;
  public currentRange: RangeDates;
  public oneMonthInSec: number = 60 * 60 * 24 * 31;
  public selectedPayCycle: PayCycle;
  public setDefaultCycle: boolean;
  public isAboveOrganizationLevel: boolean;

  @destroyService()
  public managementService: ExceptionConsoleRollupManagementService;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  private payCycles: PayCycle[];

  @unsubscribe()
  private initSubscription: Subscription;
  @unsubscribe()
  private loadStateSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;

  private resetBy: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private records: ExceptionConsoleRollupRecord[];
  private readonly componentId: string = 'ExceptionConsoleRollupComponent';

  private readonly payCycleControlName: string = 'payCycle';
  private readonly dateRangeControlName: string = 'dateRange';

  constructor(
    private stateService: StateManagementService,
    private storageService: ComponentStateStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orgLevelService: OrgLevelWatchService,
    private payCycleHelper: PayCycleHelperService,
    managementService: ExceptionConsoleRollupManagementService
  ) {
    this.managementService = managementService;
    this.gridState = new KendoGridStateHelper<ExceptionConsoleRollupRecord>();
    this.state = {
      isLoading: false,
    };
  }

  public ngOnInit(): void {

    this.initSubscription = this.activatedRoute.data
      .combineLatest(this.stateService.onInit$, this.orgLevelService.orgLevelTreeLoaded$, this.orgLevel$)
      .subscribe(([data, init, orgtree, orgLvl]: [any, any, any, OrgLevel]) => {

        let routeData: ExceptionConsoleRouteData = data.exceptionConsoleData;

        let usePayCycle = routeData.usePayCycle;
        let payCycle: PayCycle = routeData.payCycle;
        let range: RangeDates = routeData.range;

        this.selectedPayCycle = null;
        this.setDefaultCycle = false;

        this.isAboveOrganizationLevel = orgLvl.type !== OrgLevelType.organization;

        if (this.isAboveOrganizationLevel) {
          usePayCycle = false;
        }

        if (!this.isAboveOrganizationLevel && usePayCycle) {
          if (payCycle) {
            this.setDefaultCycle = false;
            this.selectedPayCycle = payCycle;
            range = new RangeDates();
            range.startDate = payCycle.startDate;
            range.endDate = payCycle.endDate;
          } else {
            this.setDefaultCycle = true;
          }
        }

        this.currentRange = range;
        if (this.currentRange) this.managementService.setDateRange(this.currentRange);
        this.usePayCycle = usePayCycle;
      });

    this.loadStateSubscription = this.managementService.onStateChanged$
      .subscribe((state: ExceptionConsoleRollupState) => {
        this.globalState = state;
      });

    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });

    this.loadedSubscription = this.managementService.onLoaded$
      .subscribe((value: any) => {
        let col: IColumnSettings = _.first(this.globalState.columns);
        if (col) {
          col.description = this.managementService.getOrgLevelTitle();
        }
      });

    this.stateService.init(this.componentId);
  }

  public ngOnDestroy(): void {
    //AOT
  }

  public onSwitchRangeMode(mode: boolean): void {

    this.usePayCycle = mode;
    const navigation: ExceptionConsoleNavigationService = new ExceptionConsoleNavigationService(this.router, this.activatedRoute);
    if (!this.currentRange) {
      navigation.navigateToExceptionConsole(this.managementService.currentOrgLevel.id, this.usePayCycle);
    } else {
      navigation.navigateToExceptionConsoleDates(this.managementService.currentOrgLevel.id, this.currentRange.startDate, this.currentRange.endDate, this.usePayCycle);
    }
  }

  public onPayCycleSelected(payCycle: PayCycle): void {
    let range: RangeDates = new RangeDates();
    range.startDate = payCycle.startDate;
    range.endDate = payCycle.endDate;
    this.currentRange = range;
    this.managementService.navigateToDateRange(range, true);
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    let range: RangeDates = new RangeDates();
    range.startDate = startDate;
    range.endDate = endDate;
    this.currentRange = range;
    this.managementService.navigateToDateRange(range, false);
  }

  public onChangeColumn(event: MouseEvent, column: IColumnSettings): any {
    this.managementService.onStateChanged(this.globalState);
  }

  public selectAll(event: MouseEvent): void {
    _.forEach(this.globalState.allColumns, (column: IColumnSettings) => {
      if (!column.readonly) {
        column.displayed = true;
      }
    });
    this.managementService.onStateChanged(this.globalState);
  }

  public clearAll(event: MouseEvent): void {
    _.forEach(this.globalState.allColumns, (column: IColumnSettings) => {
      if (!column.readonly) {
        column.displayed = false;
      }
    });
    this.managementService.onStateChanged(this.globalState);
  }

}
