import { Component, OnInit } from '@angular/core';
import { WFMFlagsSyncStatus, WFMStopSyncDetails } from '../../../../configuration/models/wfm-sync';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';

@Component({
  selector: 'slx-sync-dashboard',
  templateUrl: './sync-dashboard.component.html',
  styleUrls: ['./sync-dashboard.component.scss']
})
export class SyncDashboardComponent implements OnInit {

  constructor(private wfmsyncService: WfmSyncService, appSettingManageService: AppSettingsManageService) {
        
    this.appSettingManageService = appSettingManageService;
  }

  public onGoing: boolean = false;
  public migration: boolean = false;
  public pause: boolean = false;
  public Data: WFMFlagsSyncStatus;
  public stop:boolean=false;
  public customerId:string;
  public stopSync:WFMStopSyncDetails;
  private appSettingManageService: AppSettingsManageService;
  public nextgenPhasedRollout: boolean = false;
  public isNextgenPayrollEnabled: boolean = false;

  ngOnInit() {
    this.getSettings();
    
    this.res();
    this.getStopData();
  }

  public res() {
    this.wfmsyncService.getSyncStatus().then((value2: any) => {
      this.Data = value2.data;
      this.onGoing = this.Data.ongoingSync;
      // this.migration = false;
      this.pause = this.Data.pauseSync;
    });
  }

  get isMigrationDisable() {
    if ( this.onGoing == true) {
      return true;
    }
    return true;
  }

  public post() {
    let req: WFMFlagsSyncStatus=new WFMFlagsSyncStatus();
    if (this.Data.migrationSync == false && this.migration == true && this.onGoing == true) {
      this.migration = false;
      return;
    }

    if (this.onGoing == true ) {
      req.ongoingSync=true;
      req.migrationSync=false;
      
    }
      else{
        req.ongoingSync=true;
        req.migrationSync=false;
    }
    req.pauseSync=this.pause
    this.wfmsyncService.postSyncStatus(req).then((value2: any) => {
      this.res();
    }).catch((reason: any) => {
      this.res();
    });
  }
  public getStopData() {
    this.wfmsyncService.getStopSyncStatus().then((value2: any) => {
      this.stopSync = value2.data;
     this.customerId=this.stopSync.customerId;
     this.stop=this.stopSync.stopSync;
    });

  }

  public stopData() {
    let req1: WFMStopSyncDetails=new WFMStopSyncDetails();
    req1.customerId=this.customerId;
    req1.stopSync=this.stop;
    this.wfmsyncService.stopSyncStatus(req1).then((value2: any) => {
      this.getStopData()
    }).catch((reason: any) => {
      this.res();
    });
  }
        
  public async getSettings(): Promise<void> {
      let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
      this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
      this.isNextgenPayrollEnabled = appServerConfig.IsNextgenPayrollEnabled;
  }
}
