import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  ViewChild,
  HostListener,
  HostBinding,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import * as _ from 'lodash';

import { CustomDomEvents } from '../../models/index';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';

import { createValuAccessor } from '../../utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-duration-input',
  templateUrl: 'duration-input.component.html',
  styleUrls: ['duration-input.component.scss'],
  providers: [createValuAccessor(DurationInputComponent)]
})
export class DurationInputComponent implements ControlValueAccessor, OnChanges {
  @Input() public className: string;
  @Input() public placeholder: string;
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }

  public durationMask: any;
  public inEdit: boolean;
  public internalValue: any = '';
  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  @HostBinding('class.edited') private hasFocus: boolean = false;

  constructor(elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.hasFocus = false;
    this.durationMask = {
      mask: (value: string) => {
        let numberMatch: RegExpMatchArray = value.match(/\d/g);
        let numberLength: number = numberMatch ? numberMatch.join('').length : 0;
        console.log(numberLength);
        if (numberLength === 2) {
          return [/[1-9]/, ':', /[0-5]/, /[0-9]/];
        } else if (numberLength >= 5) {
          return [/(0|1)/, /[0-2]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
        } else if (numberLength === 4) {
          return [/(0|1)/, /[0-2]/, ':', /[0-5]/, /[0-9]/];
        }

        return [/[0-2]/, ':', /[0-5]/, /[0-9]/];
      },
      guide: true
    };
  }

  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }

  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
    this.onTouchedCallback();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !myattr;
  }

  public writeValue(value?: any): void {
    this.internalValue = value;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public valueChanged(value: any): void {
    this.onChangeCallback(value);
  }
}
