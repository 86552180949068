/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editable-list-row.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../listEditor/editable-list-editor.component.ngfactory";
import * as i4 from "../listEditor/editable-list-editor.component";
import * as i5 from "../custom-list-actor.base";
import * as i6 from "../../../directives/editablList/list-actions.directive";
import * as i7 from "../../../services/editableList/list-actions.service";
import * as i8 from "./editable-list-row.component";
var styles_EditableListRowComponent = [i0.styles];
var RenderType_EditableListRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditableListRowComponent, data: {} });
export { RenderType_EditableListRowComponent as RenderType_EditableListRowComponent };
function View_EditableListRowComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_EditableListRowComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_EditableListRowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_EditableListRowComponent_4)), i1.ɵdid(1, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { $implicit: 0, item: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.item, _co.item); var currVal_1 = _co.editorTemplateRef; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EditableListRowComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "slx-editable-list-editor", [["slx-list-actions", ""]], [[2, "selected-row", null]], [[null, "actionEmitter"]], function (_v, en, $event) { var ad = true; if (("actionEmitter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).itemAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_EditableListEditorComponent_0, i3.RenderType_EditableListEditorComponent)), i1.ɵdid(2, 245760, null, 0, i4.EditableListEditorComponent, [], { item: [0, "item"] }, { actionEmitter: "actionEmitter" }), i1.ɵprd(2048, null, i5.CustomListActorBase, null, [i4.EditableListEditorComponent]), i1.ɵdid(4, 81920, null, 0, i6.ListActionsDirective, [i7.ListActionsService, [4, i5.CustomListActorBase]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item; _ck(_v, 2, 0, currVal_1); _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).selection; _ck(_v, 1, 0, currVal_0); }); }
function View_EditableListRowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableListRowComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableListRowComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editorTemplateRef; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.editorTemplateRef; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EditableListRowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "inactive": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableListRowComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableListRowComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.state.inactive); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.state.editing; _ck(_v, 5, 0, currVal_1); var currVal_2 = false; _ck(_v, 7, 0, currVal_2); var currVal_3 = true; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_EditableListRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-editable-list-row", [], null, null, null, View_EditableListRowComponent_0, RenderType_EditableListRowComponent)), i1.ɵdid(1, 245760, null, 0, i8.EditableListRowComponent, [i7.ListActionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditableListRowComponentNgFactory = i1.ɵccf("slx-editable-list-row,[slx-editable-list-row]", i8.EditableListRowComponent, View_EditableListRowComponent_Host_0, { index: "index", item: "item", editorTemplateRef: "editorTemplateRef" }, {}, ["*"]);
export { EditableListRowComponentNgFactory as EditableListRowComponentNgFactory };
