export interface IDailyUnitGridEmployee {
  InPar: string;
  EmpId: string;
  RecordNumber: string;
  EmpName: string;
  DepartmentId: string;
  PositionGroupId: string;
  PositionGroupName: string;
  JobCode: string;
  JobDescription: string;
  ShiftGroupId: string;
  ShiftGroup: string;
  IsConstraint: string;
  ParentShiftId: string;
  HasPartialShift: boolean;
  ParentShiftName: string;
  ExcludeFromCount: string;
  ShiftId: string;
  ShiftName: string;
  ShiftStart: string;
  ShiftEnd: string;
  UnitId: string;
  UnitName: string;
  SchedHours: string;
  Hours: string;
  IsOvertime: string;
  PPD: string;
  SlxpartnerdataId: string;
  RequeststatusId: string;
  Shifttype: string;
  SlxagencyId: string;
  MessageCount: string;
  PartnerId: string;
  ShiftRequestDate: string;
  IsSwitchShift: boolean;
  IsOpenShift: boolean;
  IsOTShift: boolean;
  IsPendingShift: boolean;
  PartialShiftDailyUnitGridEmployee: DailyUnitGridEmployee[];
}

export class DailyUnitGridEmployee implements IDailyUnitGridEmployee {
  InPar: string;
  EmpId: string;
  RecordNumber: string;
  EmpName: string;
  DepartmentId: string;
  PositionGroupId: string;
  PositionGroupName: string;
  JobCode: string;
  JobDescription: string;
  ShiftGroupId: string;
  ShiftGroup: string;
  IsConstraint: string;
  ParentShiftId: string;
  HasPartialShift: boolean;
  ParentShiftName: string;
  ExcludeFromCount: string;
  ShiftId: string;
  ShiftName: string;
  ShiftStart: string;
  ShiftEnd: string;
  actualShiftStart: string;
  actualShiftEnd: string;
  UnitId: string;
  UnitName: string;
  SchedHours: string;
  Hours: string;
  IsOvertime: string;
  PPD: string;
  SlxpartnerdataId: string;
  RequeststatusId: string;
  Shifttype: string;
  SlxagencyId: string;
  AgencyName : string;
  MessageCount: string;
  PartnerId: string;
  ShiftRequestDate: string;
  IsSwitchShift: boolean;
  IsOpenShift: boolean;
  IsOTShift: boolean;
  IsPendingShift: boolean;
  [key: string]: any;
  PartialShiftDailyUnitGridEmployee: DailyUnitGridEmployee[];
}
