export interface IIconAccess {
    isAdminUser: boolean;
    isMessageCenterEnabled: boolean;
    isUserAllowed: boolean;
}

export class IconAccess {
    public isAdminUser: boolean;
    public isMessageCenterEnabled: boolean;
    public isUserAllowed: boolean;
}
