import { ChangeDetectorRef, OnInit } from '@angular/core';
import { KendoGridStateHelper } from '../../../../../common';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { PbjExportLogManagementService, PbjExportMapService } from '../../../services';
import { PBJExportParentGrid } from '../../../models/pbj-export/pbj-export-parent-grid';
import { PBJExportChildGrid } from '../../../models/pbj-export/pbj-export-child-grid';
import { PBJChildDownload, PBJDownloadExcelPdf } from '../../../models/pbj-export/pbjChildGridDownload';
import { SessionService } from '../../../../../core/services';
import { PbjSignalrService } from '../../../../../portal/services/pbj-signalr/pbj-signalr.service';
var PbjExportGridChildComponent = /** @class */ (function () {
    function PbjExportGridChildComponent(managementService, changeDetector, sessionService, mapService, pbjSignalRService) {
        var _this = this;
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.sessionService = sessionService;
        this.mapService = mapService;
        this.pbjSignalRService = pbjSignalRService;
        this.records = [];
        this.mySelection = [];
        this.data = [
            {
                text: "Download Excel",
                value: "excel"
            },
            {
                text: "Download PDF",
                value: "pdf"
            },
            {
                text: "Download XML",
                value: "xml"
            }
        ];
        this.isAllSelected = false;
        this.isLoading = false;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'exportID', dir: 'desc' }];
        this.managementService.sort = this.gridState.state.sort;
        this.gridState.state.filter = null;
        this.gridState.state.group = [];
        this.pbjSignalRService.pbjExportRealtimeFeedback$.subscribe(function (args) {
            var childRealTimeUpdate = args && args.length > 2 ? JSON.parse(args[2]) : null;
            if (_this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase()) {
                _this.updateChildProgressBar(childRealTimeUpdate);
            }
        });
    }
    PbjExportGridChildComponent.prototype.ngOnInit = function () {
        this.getGridRecords();
    };
    PbjExportGridChildComponent.prototype.updateChildProgressBar = function (exportChildItem) {
        var _this = this;
        this.records.map(function (item) {
            if (item.exportID === exportChildItem.ExportId) {
                item.exportProgress = exportChildItem.ExportIdProgress;
                item.exportStatus = exportChildItem.ExportIdStatus == 'SUCCESS' ? 'Completed' : exportChildItem.ExportIdStatus;
                if (exportChildItem.ExportIdProcessEndDate != null) {
                    item.processEndDate = exportChildItem.ExportIdProcessEndDate;
                    item.processingTime = _this.mapService.getProcessingTime(item.processStartDate, exportChildItem.ExportIdProcessEndDate);
                    ;
                }
                if (exportChildItem.ExportIdDetails != null) {
                    item.details = exportChildItem.ExportIdDetails;
                }
            }
        });
    };
    PbjExportGridChildComponent.prototype.isCheckboxDisabled = function (dataItem) {
        if (!(dataItem.exportProgress === 100 && dataItem.exportStatus === 'Completed')) {
            return true;
        }
        return false;
    };
    PbjExportGridChildComponent.prototype.selectAllItems = function (checked) {
        var _this = this;
        this.isAllSelected = checked;
        var totalCheckableRecords = this.records
            .map(function (item) {
            if (item.exportProgress === 100 && item.exportStatus === 'Completed') {
                return item.exportID;
            }
            else {
                return null;
            }
        })
            .filter(function (item) { return item !== null; });
        var checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(function (checkbox) {
            if (!checkbox.disabled && totalCheckableRecords.includes(parseInt(checkbox.id))) {
                checkbox.checked = checked;
            }
        });
        if (checked) {
            checkboxes.forEach(function (checkbox) {
                if (checkbox.checked && totalCheckableRecords.includes(parseInt(checkbox.id))) {
                    if (_this.mySelection.includes(parseInt(checkbox.id))) {
                        return;
                    }
                    else {
                        _this.mySelection.push(parseInt(checkbox.id));
                    }
                }
            });
        }
        else {
            this.mySelection = [];
        }
    };
    PbjExportGridChildComponent.prototype.selectItem = function (dataItem, checked) {
        var totalCheckable = this.records
            .map(function (item) {
            if (item.exportProgress === 100 && item.exportStatus === 'Completed') {
                return item.exportID;
            }
            else {
                return null;
            }
        })
            .filter(function (item) { return item !== null; });
        if (checked) {
            if (this.mySelection.includes(dataItem.exportID)) {
                return;
            }
            else {
                this.mySelection.push(dataItem.exportID);
            }
        }
        else {
            var index = this.mySelection.indexOf(dataItem.exportID);
            if (index !== -1) {
                this.mySelection.splice(index, 1);
            }
            if (totalCheckable.length != this.mySelection.length) {
                this.isAllSelected = false;
            }
        }
        if (totalCheckable.length == this.mySelection.length) {
            this.isAllSelected = true;
        }
    };
    PbjExportGridChildComponent.prototype.getGridRecords = function () {
        var _this = this;
        this.isLoading = true;
        var reqObj = new PBJExportChildGrid();
        reqObj.batchID = this.childRequestObj.batchId;
        reqObj.orgLevelId = this.orgLevelId;
        reqObj.startDate = this.childRequestObj.startDate;
        reqObj.endDate = this.childRequestObj.endDate;
        this.managementService.getChildExportData(reqObj).then(function (val) {
            _this.records = val;
            _this.isLoading = false;
            _this.refreshGrid();
        }).catch(function (reason) {
            _this.managementService.onError(reason);
            _this.isLoading = false;
        });
        this.mySelection = this.records.filter(function (id) {
            return _this.gridView.data.find(function (item) { return item['exportID'] === id; });
        });
    };
    PbjExportGridChildComponent.prototype.downloadFiles = function (item) {
        var fileType = item.value.toLowerCase();
        if (fileType === 'xml') {
            var exportObj = new PBJChildDownload();
            exportObj.exportIds = this.mySelection.toString(),
                exportObj.batchId = this.childRequestObj.batchId,
                exportObj.orgLevelId = this.orgLevelId;
            this.managementService.downloadChildExportData(exportObj);
        }
        else if (fileType === 'pdf' || fileType === 'excel') {
            this.pbjSignalRService.isPDFDownloadInitiated$.next(true);
            var pdfExcelObj = new PBJDownloadExcelPdf();
            pdfExcelObj.type = fileType;
            pdfExcelObj.exportId = this.mySelection.toString();
            pdfExcelObj.batchId = this.childRequestObj.batchId;
            pdfExcelObj.alias = null;
            pdfExcelObj.userId = null;
            pdfExcelObj.orgLevelId = this.orgLevelId;
            this.managementService.downloadExcelPDF(pdfExcelObj);
        }
    };
    Object.defineProperty(PbjExportGridChildComponent.prototype, "isChecked", {
        get: function () {
            return this.mySelection.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    PbjExportGridChildComponent.prototype.filterChangeHandler = function (event) {
        this.gridState.state.filter = event;
        this.refreshGrid();
    };
    PbjExportGridChildComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.refreshGrid();
    };
    PbjExportGridChildComponent.prototype.refreshGrid = function () {
        if (this.records === undefined || this.records.length == 0) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
        var checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(function (checkbox) {
            checkbox.checked = false;
        });
        this.mySelection = [];
    };
    return PbjExportGridChildComponent;
}());
export { PbjExportGridChildComponent };
