
export interface ITimeclockSlateProfileDefinition {
    id: number;
    name: string;
  }
  
  export class TimeclockSlateProfileDefinition {
    constructor(public id: number, public name: string) {}
  }
  
  
  