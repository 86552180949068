import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { IEmployeeSMSContacts, EmployeeSMSContacts } from '../../../app-modules/message-center/models';
import { EmployeeSendSms, IEmployeeSendSms, IEmployeeSendSmsResponse, EmployeeSendSmsResponse } from '../../../app-modules/message-center/models/employee-send-sms';

@Injectable()
export class EmployeeSmsManagementMapService {

  constructor() { }
  public mapToEmployeeSmsContactList(dtos: IEmployeeSMSContacts[]): EmployeeSMSContacts[] {
    return _.map(dtos, (dto: IEmployeeSMSContacts) => this.mapToEmployeeSmsContact(dto));
  }

  public mapToEmployeeSmsContact(dto: IEmployeeSMSContacts): EmployeeSMSContacts {
    const employeeSmsContact = new EmployeeSMSContacts();
    employeeSmsContact.employeeId = dto.employeeId;
    employeeSmsContact.employeeName = dto.employeeName;
    employeeSmsContact.firstName = dto.firstName;
    employeeSmsContact.lastName = dto.lastName;
    employeeSmsContact.middleName = dto.middleName;
    employeeSmsContact.phoneNumber = dto.phoneNumber;
    employeeSmsContact.mobilePhoneNumber = dto.mobilePhoneNumber;
    employeeSmsContact.alternativePhoneNumber = dto.alternativePhoneNumber;
    employeeSmsContact.departmentId = dto.departmentId;
    employeeSmsContact.departmentName = dto.departmentName;
    employeeSmsContact.jobCode = dto.jobCode;
    employeeSmsContact.jobDescription = dto.jobDescription;
    employeeSmsContact.organizationId = dto.organizationId;
    employeeSmsContact.organizationName = dto.organizationName;
    employeeSmsContact.empOptIn = dto.empOptIn;
    employeeSmsContact.profilePicture = dto.profilePicture;
    employeeSmsContact.countryCode = dto.countryCode;
    employeeSmsContact.badgeId = dto.badgeId;
    return employeeSmsContact;
  }
  public mapToSendSmsContactList(dtos: IEmployeeSendSmsResponse[]): IEmployeeSendSmsResponse[] {
    return _.map(dtos, (dto: IEmployeeSendSmsResponse) => this.mapToEmployeeSendSmsContact(dto));
  }

  public mapToEmployeeSendSmsContact(dto: IEmployeeSendSmsResponse): IEmployeeSendSmsResponse {
    const employeeSendSms = new EmployeeSendSmsResponse();
    employeeSendSms.body = dto.body;
    employeeSendSms.fullName = dto.fullName;

    return employeeSendSms;
  }
}
