import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';
import { ModalService } from '../../../common/services/modal/modal.service';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import { ChangeManagementService } from '../../../common/services/change-management/change-management.service';
import { ChangesDialogOptions } from '../../../common/models/index';
import { IndividualScheduleApiService } from '../individual-schedule/individual-schedule-api.service';
import { ScheduleMapService } from './schedule-map.service';
import { MasterScheduleManagementService } from './master-schedule-management.service';
import { MasterScheduleQuickEditPromptDialogComponent } from '../../components/master-shedule/master-schedule-quick-edit-prompt-dialog/master-schedule-quick-edit-prompt-dialog.component';
import { EmployeesStateSelector } from '../../store/selectors/index';
import { AppSettingsManageService } from '../../../app-settings/services/index';
import { OrgLevelWatchService, LookupApiService } from '../../../organization/services/index';
import { ScheduleCycleMessages, ShiftGroupOrder, Organization } from '../../../organization/models/index';
import { MasterScheduleShiftInfo, ScheduleEntryEditItem, QuickEditOvelapDecisionRequest, QuickEditOvelapDecision, QuickEditOvelapStatus, QuickEditOvelapState, QuickEditOvelap, QuickEditOvelapDecisionState, ScheduleEntryEditShift } from '../../models/index';
var MasterScheduleQuickEditService = /** @class */ (function () {
    function MasterScheduleQuickEditService(individualScheduleApiService, scheduleMapService, masterScheduleManagementService, changeManagementService, modalService, appSettingsService, orgLevelWatchService, lookupApiService) {
        this.individualScheduleApiService = individualScheduleApiService;
        this.scheduleMapService = scheduleMapService;
        this.masterScheduleManagementService = masterScheduleManagementService;
        this.changeManagementService = changeManagementService;
        this.modalService = modalService;
        this.appSettingsService = appSettingsService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.lookupApiService = lookupApiService;
        this.items = [];
        this.changeGroupId = 'quickEdit';
        this.maxQuickEditCount = 100;
        this.quickEditListChanged$ = new Subject();
        this.quickEditStatusChanged$ = new Subject();
        this.quickSaveResult$ = new Subject();
        this.quickEditUndo$ = new Subject();
        this.currentStatus = { isActive: false, isStarted: false, isConfigurationError: false };
        this.getAppSettings();
    }
    MasterScheduleQuickEditService.prototype.setEditConfiguration = function (configuration, hasErrors) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.configuration = configuration;
                        this.currentStatus.isConfigurationError = hasErrors;
                        return [4 /*yield*/, this.obtainNightShiftFirstShiftForOrg(configuration)];
                    case 1:
                        _a.sent();
                        this.quickEditStatusChanged(this.currentStatus);
                        return [2 /*return*/];
                }
            });
        });
    };
    MasterScheduleQuickEditService.prototype.getOverlapDecision = function (row, cell, shiftInfo) {
        var _this = this;
        var res = new QuickEditOvelapDecisionState();
        res.overlap = this.getOvelapState(row, cell, shiftInfo);
        if (res.overlap.canReplace && (this.configuration.replaceAlways)) {
            res.decision = QuickEditOvelapDecision.Replace;
            return Promise.resolve(res);
        }
        if (this.CheckOverlapShiftsNotExists(res.overlap.currentDayState) && this.CheckOverlapShiftsNotExists(res.overlap.nextDayState) && this.CheckOverlapShiftsNotExists(res.overlap.prevDayState)) {
            res.decision = QuickEditOvelapDecision.Append;
            return Promise.resolve(res);
        }
        var req = new QuickEditOvelapDecisionRequest();
        req.overlap = res.overlap;
        req.canCancel = true;
        var promise = new Promise(function (resolve, reject) {
            MasterScheduleQuickEditPromptDialogComponent.openDialog(req, _this.modalService, function (result) {
                res.decision = result;
                resolve(res);
            });
        });
        return promise;
    };
    MasterScheduleQuickEditService.prototype.CheckOverlapShiftsNotExists = function (dayShifts) {
        return dayShifts.leftOverlapShifts.length === 0 && dayShifts.rightOverlapShifts.length === 0 && dayShifts.status === 0;
    };
    MasterScheduleQuickEditService.prototype.quickEditCommand = function (modalService, row, existCell) {
        var _this = this;
        if (this.items.length > this.maxQuickEditCount) {
            return new Promise(function (resolve, reject) {
                _this.modalService.globalAnchor.openInfoDialog('Information', "You can not edit more then " + _this.maxQuickEditCount + " items!", function (result) {
                    resolve();
                });
            });
        }
        if (!existCell.shiftsInfo || existCell.shiftsInfo.length === 0) {
            existCell.shiftsInfo = [];
        }
        if (existCell.isInQuickEdit) {
            var item = _.find(this.items, function (i) {
                return moment(i.date).isSame(existCell.dateOn) && i.employeeId === row.id;
            });
            if (item) {
                //remove prev quick edit changes
                var dateOn = EmployeesStateSelector.getDateKey(item.date);
                existCell = row.cell[dateOn] = _.cloneDeep(item.cellForUndo);
            }
        }
        var shiftInfo = this.createShiftInfo(row, existCell);
        return this.getOverlapDecision(row, existCell, shiftInfo)
            .then(function (decision) {
            if (decision.decision === QuickEditOvelapDecision.Cancel) {
                _this.quickEditListChanged$.next(_this.items);
                return;
            }
            _this.applyShiftChanges(decision, row, existCell, shiftInfo);
            _this.changeManagementService.changeNotify(_this.changeGroupId);
            _this.masterScheduleManagementService.calculateDataRow(row);
            if (shiftInfo.absenceCode) {
                existCell.hasAbsence = true;
            }
            existCell.clearNames();
            _this.quickEditListChanged$.next(_this.items);
        });
    };
    MasterScheduleQuickEditService.prototype.applyShiftChanges = function (decision, row, cell, shiftInfo) {
        var item = _.find(this.items, function (i) {
            return moment(i.date).isSame(cell.dateOn) && i.employeeId === row.id;
        });
        if (!item) {
            item = new ScheduleEntryEditItem();
            this.items.unshift(item);
        }
        item.date = cell.dateOn;
        item.employeeId = row.id;
        item.employeeName = row.name;
        item.shift = shiftInfo;
        item.shiftsToModify = [];
        if (!cell.isInQuickEdit) {
            item.cellForUndo = _.cloneDeep(cell);
            cell.isInQuickEdit = true;
        }
        switch (decision.decision) {
            case QuickEditOvelapDecision.Replace:
                item.shiftsToRemove = _.map(cell.shiftsInfo);
                cell.shiftsInfo = [shiftInfo];
                break;
            case QuickEditOvelapDecision.Append:
                this.makeAppend(row, cell, shiftInfo, decision.overlap);
                break;
            case QuickEditOvelapDecision.Override:
                item.shiftsToModify = this.makeOverride(row, cell, shiftInfo, decision.overlap);
                break;
            case QuickEditOvelapDecision.Cancel:
                break;
            default:
                throw new Error('unknown overlap decision');
        }
    };
    MasterScheduleQuickEditService.prototype.makeAppend = function (row, cell, shiftInfo, overlap) {
        if (overlap.prevDayState.status !== QuickEditOvelapStatus.NoOverlap) {
            var lastPrevSchedule = _.maxBy(overlap.prevDayState.rightOverlapShifts, function (s) { return moment(s.shiftEnd).unix(); });
            shiftInfo.shiftStart = moment(lastPrevSchedule.shiftEnd).add(1, 's').toDate();
        }
        if (overlap.nextDayState.status !== QuickEditOvelapStatus.NoOverlap) {
            var firstNextSchedule = _.minBy(overlap.nextDayState.leftOverlapShifts, function (s) { return moment(s.shiftStart).unix(); });
            shiftInfo.shiftEnd = moment(firstNextSchedule.shiftStart).subtract(1, 's').toDate();
        }
        if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapLeft) {
            var lastLeftSchedule = _.maxBy(overlap.currentDayState.leftOverlapShifts, function (s) { return moment(s.shiftEnd).unix(); });
            shiftInfo.shiftStart = moment(lastLeftSchedule.shiftEnd).add(1, 's').toDate();
        }
        if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapRight) {
            var firstRightSchedule = _.minBy(overlap.currentDayState.rightOverlapShifts, function (s) { return moment(s.shiftStart).unix(); });
            shiftInfo.shiftEnd = moment(firstRightSchedule.shiftStart).subtract(1, 's').toDate();
        }
        shiftInfo.duration = moment(shiftInfo.shiftEnd).diff(shiftInfo.shiftStart) - shiftInfo.lunchDuration;
        cell.shiftsInfo = cell.shiftsInfo.concat([shiftInfo]);
    };
    MasterScheduleQuickEditService.prototype.makeOverride = function (row, cell, shiftInfo, overlap) {
        var changedShifts = [];
        if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapLeft) {
            _.forEach(overlap.currentDayState.leftOverlapShifts, function (s) {
                var ch = new ScheduleEntryEditShift();
                ch.scheduledShift = _.cloneDeep(s);
                ch.endDate = s.shiftEnd = moment(shiftInfo.shiftStart).subtract(1, 's').toDate();
                ch.startDate = s.shiftStart;
                ch.duration = moment(ch.endDate).diff(ch.startDate);
                changedShifts.push(ch);
            });
        }
        if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapRight) {
            _.forEach(overlap.currentDayState.leftOverlapShifts, function (s) {
                var ch = new ScheduleEntryEditShift();
                ch.scheduledShift = _.cloneDeep(s);
                ch.endDate = s.shiftEnd;
                ch.startDate = s.shiftStart = moment(shiftInfo.shiftEnd).subtract(1, 's').toDate();
                ch.duration = moment(ch.endDate).diff(ch.startDate);
                changedShifts.push(ch);
            });
        }
        cell.shiftsInfo = cell.shiftsInfo.concat([shiftInfo]);
        return changedShifts;
    };
    MasterScheduleQuickEditService.prototype.undoEditCommand = function (items) {
        this.quickEditUndo$.next(items);
        _.remove(this.items, function (item) { return _.includes(items, item); });
        if (this.items.length === 0) {
            this.changeManagementService.clearChanges(this.changeGroupId);
        }
        this.quickEditListChanged$.next(this.items);
    };
    MasterScheduleQuickEditService.prototype.undoEditCommandFromId = function (itemId, dateOn) {
        var item = _.find(this.items, function (i) {
            return moment(i.date).isSame(dateOn) && i.employeeId === itemId;
        });
        if (!item) {
            return;
        }
        this.undoEditCommand([item]);
    };
    MasterScheduleQuickEditService.prototype.saveChanges = function () {
        var _this = this;
        this.masterScheduleManagementService.onLoadStatusChanged(true);
        var req = this.scheduleMapService.mapQuickEditToRequest(this.items);
        this.individualScheduleApiService.saveScheduleDefinitions(req)
            .then(function (res) {
            _this.masterScheduleManagementService.onLoadStatusChanged(false);
            _this.quickSaveResult$.next(res);
            _this.changeManagementService.clearChanges(_this.changeGroupId);
            _this.deactivateQuickEdit();
        }).catch(function (err) {
            _this.masterScheduleManagementService.onLoadStatusChanged(false);
            _this.changeManagementService.clearChanges(_this.changeGroupId);
            _this.deactivateQuickEdit();
            throw err;
        });
    };
    MasterScheduleQuickEditService.prototype.quickEditStatusChanged = function (status) {
        this.quickEditStatusChanged$.next(status);
    };
    MasterScheduleQuickEditService.prototype.activateQuickEdit = function () {
        this.items = [];
        this.currentStatus.isActive = true;
        this.quickEditStatusChanged(this.currentStatus);
    };
    MasterScheduleQuickEditService.prototype.deactivateQuickEdit = function () {
        var _this = this;
        this.canClose().then(function (res) {
            if (res) {
                _this.undoEditCommand(_this.items);
                _this.items = [];
                _this.currentStatus.isActive = false;
                _this.currentStatus.isStarted = false;
                _this.changeManagementService.clearChanges(_this.changeGroupId);
                _this.quickEditStatusChanged(_this.currentStatus);
            }
        });
    };
    MasterScheduleQuickEditService.prototype.startQuickEdit = function () {
        this.currentStatus.isActive = true;
        this.currentStatus.isStarted = true;
        this.quickEditStatusChanged(this.currentStatus);
    };
    MasterScheduleQuickEditService.prototype.stopQuickEdit = function () {
        this.saveChanges();
        this.currentStatus.isActive = true;
        this.currentStatus.isStarted = false;
        this.quickEditStatusChanged(this.currentStatus);
    };
    MasterScheduleQuickEditService.prototype.obtainNightShiftFirstShiftForOrg = function (configuration) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var organizationId, orglevel, _a, organization, shiftSetting;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.departmentOrglevelId !== configuration.department.orgLevelId)) return [3 /*break*/, 7];
                        this.departmentOrglevelId = configuration.department.orgLevelId;
                        return [4 /*yield*/, this.orgLevelWatchService.getOrgLevelByIdSafeAsync(this.departmentOrglevelId)];
                    case 1:
                        orglevel = _b.sent();
                        if (!orglevel) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.orgLevelWatchService.getOrgLevelByIdSafeAsync(orglevel.parentId)];
                    case 2:
                        orglevel = _b.sent();
                        if (!(orglevel && orglevel.relatedItemId)) return [3 /*break*/, 3];
                        organizationId = orglevel.relatedItemId;
                        return [3 /*break*/, 6];
                    case 3:
                        if (!!this.organizations) return [3 /*break*/, 5];
                        _a = this;
                        return [4 /*yield*/, this.lookupApiService.getOrganizationsAsync()];
                    case 4:
                        _a.organizations = _b.sent();
                        _b.label = 5;
                    case 5:
                        organization = this.getOrganizationByOrgLevel(orglevel.parentId);
                        if (organization) {
                            organizationId = organization.id;
                        }
                        _b.label = 6;
                    case 6:
                        if (organizationId) {
                            shiftSetting = this.getNightShiftSettingForOrganization(organizationId);
                            if (!_.isNil(shiftSetting)) {
                                this.isNightShiftFirstShiftForOrganization = shiftSetting.enabled;
                            }
                        }
                        else {
                            throw new Error('Could not obtain night shift first shift setting for organization.');
                        }
                        _b.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    MasterScheduleQuickEditService.prototype.createShiftInfo = function (row, cell) {
        var shiftInfo = new MasterScheduleShiftInfo();
        shiftInfo.empoyeeId = row.id;
        shiftInfo.position = { id: row.position.id, name: row.position.name, orgLevelId: row.position.orgLevelId };
        shiftInfo.shiftName = this.configuration.shift.name;
        shiftInfo.shiftId = this.configuration.shift.id;
        shiftInfo.shiftDefStart = shiftInfo.shiftStart = dateTimeUtils.getDateTimeFromTime(cell.dateOn, this.configuration.shift.start);
        shiftInfo.shiftDefEnd = shiftInfo.shiftEnd = dateTimeUtils.getDateTimeFromTime(cell.dateOn, this.configuration.shift.end);
        shiftInfo.duration = this.configuration.shift.durationHours * 3600000;
        shiftInfo.lunchDuration = dateTimeUtils.convertFromDtoDurationStringToNumber(this.configuration.shift.lunchDuration, 'ms');
        if (this.configuration.unit) {
            shiftInfo.unitId = this.configuration.unit.id;
            shiftInfo.unitName = this.configuration.unit.name;
        }
        if (this.configuration.absence) {
            shiftInfo.absenceCode = this.configuration.absence.code;
            shiftInfo.absenceName = this.configuration.absence.description;
        }
        if (this.configuration.constraint) {
            shiftInfo.constraintId = this.configuration.constraint.id;
            shiftInfo.constraintCode = this.configuration.constraint.code;
        }
        shiftInfo.partialAbsenceCode = null;
        shiftInfo.partialAbsenceName = null;
        shiftInfo.partialAbsenceStart = null;
        shiftInfo.partialAbsenceEnd = null;
        shiftInfo.partialAbsenceDuration = null;
        shiftInfo.departmentId = this.configuration.department.id;
        shiftInfo.departmentName = this.configuration.department.name;
        shiftInfo.departmentOrgLevelId = this.configuration.department.orgLevelId;
        this.updateTimeByNightShiftSetting(shiftInfo, this.configuration.shift.group, this.configuration.department.orgLevelId);
        return shiftInfo;
    };
    MasterScheduleQuickEditService.prototype.updateTimeByNightShiftSetting = function (shift, shiftGroup, orglevelId) {
        if (shift.shiftDefStart > shift.shiftDefEnd) {
            if (!this.isNightShiftFirstShiftForOrganization || shiftGroup.groupOrder !== ShiftGroupOrder.Night) {
                shift.shiftDefEnd = shift.shiftEnd = moment(shift.shiftDefEnd).add(1, 'days').toDate();
            }
            else {
                shift.shiftDefStart = shift.shiftStart = moment(shift.shiftDefStart).subtract(1, 'days').toDate();
            }
        }
    };
    MasterScheduleQuickEditService.prototype.getOrganizationByOrgLevel = function (orgLevelId) {
        return _.find(this.organizations, function (o) { return o.orgLevelId === orgLevelId; });
    };
    MasterScheduleQuickEditService.prototype.getNightShiftSettingForOrganization = function (organizationId) {
        var setting = _.find(this.appSettings.nightShiftSettings, function (s) { return s.organizationId === organizationId; });
        if (_.isNil(setting)) {
            setting = _.find(this.appSettings.nightShiftSettings, function (s) { return s.organizationId === 0; });
        }
        return setting;
    };
    MasterScheduleQuickEditService.prototype.getAppSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.appSettings) {
                            return [2 /*return*/];
                        }
                        _a = this;
                        return [4 /*yield*/, this.appSettingsService.getAppServerConfig()];
                    case 1:
                        _a.appSettings = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MasterScheduleQuickEditService.prototype.getOvelapState = function (row, cell, shiftInfo) {
        var overlap = new QuickEditOvelap();
        overlap.canAppend = true;
        overlap.canOverride = true;
        overlap.canReplace = true;
        overlap.messages = [];
        var prevDay = moment(cell.dateOn).subtract(1, 'day').toDate();
        var nextDay = moment(cell.dateOn).add(1, 'day').toDate();
        var prevDayKey = EmployeesStateSelector.getDateKey(prevDay);
        var nextDayKey = EmployeesStateSelector.getDateKey(nextDay);
        var prevCell = row.cell[prevDayKey];
        var nextCell = row.cell[nextDayKey];
        overlap.prevDayState = this.checkCellOvelap(prevCell, shiftInfo, true);
        overlap.nextDayState = this.checkCellOvelap(nextCell, shiftInfo, false);
        overlap.currentDayState = this.checkCellOvelap(cell, shiftInfo, false);
        if (overlap.prevDayState.status !== QuickEditOvelapStatus.NoOverlap) {
            overlap.canOverride = false;
            overlap.canReplace = false;
            overlap.messages.push('Has overlapping with previous day schedule');
            if (this.hasOvelap(overlap.prevDayState.rightOverlapShifts, overlap.currentDayState.leftOverlapShifts) ||
                this.hasOvelap(overlap.prevDayState.rightOverlapShifts, overlap.currentDayState.rightOverlapShifts)) {
                overlap.canAppend = false;
                overlap.messages.push('No free space between previous day schedule and current day schedule');
            }
            if (overlap.prevDayState.status & QuickEditOvelapStatus.OverlapMiddleInside) {
                overlap.canAppend = false;
                overlap.messages.push('New shift inside previous day shift schedule');
            }
            if (overlap.prevDayState.status & QuickEditOvelapStatus.OverlapMiddleCover) {
                overlap.canAppend = false;
                overlap.messages.push('New shift cover previous day shift schedule');
            }
        }
        if (overlap.nextDayState.status !== QuickEditOvelapStatus.NoOverlap) {
            overlap.canOverride = false;
            overlap.canReplace = false;
            overlap.messages.push('Has overlapping with next day schedule');
            if (this.hasOvelap(overlap.nextDayState.leftOverlapShifts, overlap.currentDayState.rightOverlapShifts) ||
                this.hasOvelap(overlap.nextDayState.leftOverlapShifts, overlap.currentDayState.leftOverlapShifts)) {
                overlap.canAppend = false;
                overlap.messages.push('No free space between next day schedule and current day schedule');
            }
            if (overlap.nextDayState.status & QuickEditOvelapStatus.OverlapMiddleInside) {
                overlap.canAppend = false;
                overlap.messages.push('New shift inside next day shift schedule');
            }
            if (overlap.nextDayState.status & QuickEditOvelapStatus.OverlapMiddleCover) {
                overlap.canAppend = false;
                overlap.messages.push('New shift cover next day shift schedule');
            }
        }
        if (overlap.currentDayState.status === QuickEditOvelapStatus.NoOverlap) {
            overlap.canOverride = false;
        }
        if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapMiddleInside) {
            overlap.canAppend = false;
            overlap.canOverride = false;
            overlap.messages.push('New shift inside current day shift schedule');
        }
        if (overlap.currentDayState.status & QuickEditOvelapStatus.OverlapMiddleCover) {
            overlap.canAppend = false;
            overlap.canOverride = false;
            overlap.messages.push('New shift cover current day shift schedule');
        }
        return overlap;
    };
    MasterScheduleQuickEditService.prototype.checkCellOvelap = function (cell, shiftInfo, prevDay) {
        var state = new QuickEditOvelapState();
        state.status = QuickEditOvelapStatus.NoOverlap;
        if (!cell) {
            return state;
        }
        _.forEach(cell.shiftsInfo, function (s) {
            if (moment(s.shiftStart).isSameOrBefore(shiftInfo.shiftStart) && moment(s.shiftEnd).isBefore(shiftInfo.shiftEnd) && moment(s.shiftEnd).isAfter(shiftInfo.shiftStart)) {
                if (prevDay) {
                    state.status = state.status | QuickEditOvelapStatus.OverlapRight;
                    state.rightOverlapShifts.push(s);
                }
                else {
                    state.status = state.status | QuickEditOvelapStatus.OverlapLeft;
                    state.leftOverlapShifts.push(s);
                }
            }
            if (moment(s.shiftStart).isAfter(shiftInfo.shiftStart) && moment(s.shiftEnd).isSameOrAfter(shiftInfo.shiftEnd) && moment(s.shiftStart).isBefore(shiftInfo.shiftEnd)) {
                if (prevDay) {
                    state.status = state.status | QuickEditOvelapStatus.OverlapLeft;
                    state.leftOverlapShifts.push(s);
                }
                else {
                    state.status = state.status | QuickEditOvelapStatus.OverlapRight;
                    state.rightOverlapShifts.push(s);
                }
            }
            if (moment(s.shiftStart).isSameOrBefore(shiftInfo.shiftStart) && moment(s.shiftEnd).isSameOrAfter(shiftInfo.shiftEnd)) {
                state.status = state.status | QuickEditOvelapStatus.OverlapMiddleInside;
            }
            if (moment(s.shiftStart).isSameOrAfter(shiftInfo.shiftStart) && moment(s.shiftEnd).isSameOrBefore(shiftInfo.shiftEnd)) {
                state.status = state.status | QuickEditOvelapStatus.OverlapMiddleCover;
            }
        });
        return state;
    };
    MasterScheduleQuickEditService.prototype.hasOvelap = function (list1, list2) {
        var hasOverlap = false;
        _.forEach(list1, function (s1) {
            _.forEach(list2, function (s2) {
                if (moment(s1.shiftStart).isSameOrBefore(s2.shiftEnd) && moment(s1.shiftEnd).isSameOrAfter(s2.shiftStart)) {
                    hasOverlap = true;
                }
                return !hasOverlap;
            });
            return !hasOverlap;
        });
        return hasOverlap;
    };
    MasterScheduleQuickEditService.prototype.canClose = function () {
        return this.changeManagementService.checkPristine(undefined, ChangesDialogOptions.DOWORK, this.changeGroupId);
    };
    return MasterScheduleQuickEditService;
}());
export { MasterScheduleQuickEditService };
