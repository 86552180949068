import { StringUtils } from '../../../framework/index';

export class EmployeeSubSection {
  public id: string;
  public displayName: string;
  public dataExists: boolean;

  public get anchor(): string {
    return StringUtils.format('#{0}', this.id);
  }

  public get contentElementId(): string {
    return StringUtils.format('contentElement{0}', this.id);
  }

  public isCollapsed: boolean;
  public isInEdit: boolean;

  public isEditableByConfiguration: boolean;
  public isEditableWhenEmpTerminated: boolean;
  public isNotEditableWhenEmpFutureRehired: boolean;


  constructor() {
    this.isCollapsed = false;
    this.isInEdit = false;
    this.isEditableByConfiguration = true;
    this.isEditableWhenEmpTerminated = false;
    this.isNotEditableWhenEmpFutureRehired = false;
  }
}
