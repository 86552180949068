<form #form="ngForm" novalidate class="violation">
  <div class="flex justify-content__space-between violation__control">
    <p class="flex align-items__center violation__control width-50">
      <label class="violation__c-label">Date of Violation</label>
      <span class="flex__grow">
        <slx-datepicker class="slx-wide"
          [(ngModel)]="violationDate"
          [acceptNullDate]="true"
          (ngModelChange)="onChangeDate()"
          name="violationDate"
          #vioDate="ngModel"
        ></slx-datepicker>
        <span *ngIf="vioDate.errors" class="slx-error-block error-box">
          <span *ngIf="vioDate.errors.date" errorMessage></span>
        </span>
      </span>
    </p>
    <p class="flex align-items__center violation__control width-50">
      <label class="violation__c-label">Time of Violation</label>
      <span class="flex__grow">
        <slx-timepicker class="slx-wide slx-short"
          [(ngModel)]="violationDate"
          (ngModelChange)="onChangeDate()"
          name="violationTime"
        ></slx-timepicker>
      </span>
    </p>
  </div>
  <p class="violation__control">
    <label class="violation__c-label block">Nature of Violation</label>
    <span class="violation__checkbox" *ngFor="let violation of violationsList; index as i">
      <slx-checkbox-button
        class="slx-no-indents"
        [(ngModel)]="violation.selected"
        (ngModelChange)="onChangeViolation(violation)"
        [placeholder]="violation.label"
        name="violation_{{ i }}"
      ></slx-checkbox-button>
    </span>
  </p>
</form>
