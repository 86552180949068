import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribe } from '../../../../core/decorators/index';

import { ArrivalsDeparturesTimelineDetailsManagementService } from '../../../services/index';
import { ArrivalsDeparturesDetails } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-arrivals-departures-timeline-details-grid',
  templateUrl: 'arrivals-departures-timeline-details-grid.component.html',
  styleUrls: ['arrivals-departures-timeline-details-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrivalsDeparturesTimelineDetailsGridComponent implements OnDestroy, OnInit {

  public workDate: Date;
  public details: ArrivalsDeparturesDetails;
  @unsubscribe()
  private loadedSubscription: Subscription;
  constructor(private changeDetector: ChangeDetectorRef, private managementService: ArrivalsDeparturesTimelineDetailsManagementService) {

  }

  public changeDetection(): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }
  public ngOnInit(): void {
    this.loadedSubscription = this.managementService.onLoaded$.subscribe((details: ArrivalsDeparturesDetails) => {
      this.details = details;
      this.workDate = this.managementService.workDate;
      this.changeDetection();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }


}
