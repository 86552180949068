export interface IProductModule {
  id: number;
  name: string;
  type: ProductModuleType;
  applicationId: number;
  menuId: number;
  isDisabled: boolean;
  isEditable: boolean;
}

export class ProductModule {
  public id: number;
  public name: string;
  public type: ProductModuleType;
  public applicationId: number;
  public menuId: number;
  public isDisabled: boolean;
  public isEditable: boolean;
  public isDisabledInitial: boolean;

  public get isEnabled(): boolean {
    return !this.isDisabled;
  }

  public set isEnabled(value: boolean) {
    this.isDisabled = !value;
  }

  public get isDirty(): boolean {
    return this.isDisabled !== this.isDisabledInitial;
  }
}


export enum ProductModuleType {
  Application = 'Application', Menu = 'Menu'
}
