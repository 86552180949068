import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { appConfig } from '../../../app.config';

type MapTypes = 'roadmap' | 'satellite' | 'hybrid' | 'terrain';

@Component({
  moduleId: module.id,
  selector: 'slx-static-map',
  templateUrl: 'static-map.component.html',
  styleUrls: ['static-map.component.scss']
})
export class StaticMapComponent implements OnInit, OnDestroy {
  @Input()
  public latitude: number;
  @Input()
  public longitude: number;
  @Input()
  public mapWidth: number;
  @Input()
  public mapHeight: number;
  @Input()
  public mapCenterLatitude: number;
  @Input()
  public mapCenterLongitude: number;
  @Input()
  public mapZoom: number;
  @Input()
  public mapType: MapTypes;

  public urlMap: SafeResourceUrl;
  public currentMapWidth: number;
  public currentMapHeight: number;
  public hasError: boolean;

  private defaults: {
    mapWidth: number;
    mapHeight: number;
    mapZoom: number;
    mapType: MapTypes
  };
  private readonly googleMapsApiKey: string = appConfig.licenses.googleMapsApi;
  private readonly googleStaticMapsApiUrl: string = 'https://maps.googleapis.com/maps/api/staticmap';

  constructor(private sanitizer: DomSanitizer) {
    this.defaults = {
      mapWidth: 300,
      mapHeight: 200,
      mapZoom: 10,
      mapType: 'roadmap'
    };
    this.hasError = false;
  }

  public ngOnInit(): void {
    this.checkRequiredOptions();
    if (!this.hasError) {
      const urlMap = this.buildUrl();
      this.urlMap = this.sanitizer.bypassSecurityTrustResourceUrl(urlMap);
    }
  }

  public ngOnDestroy(): void {
    // Must be, see #issueWithAOTCompiler
  }

  public onMapFailed(): void {
    this.hasError = true;
  }

  private checkRequiredOptions(): void {
    this.latitude = parseFloat(this.latitude as any);
    this.longitude = parseFloat(this.longitude as any);
    if (!_.isFinite(this.latitude) || !_.isFinite(this.longitude)) {
      this.hasError = true;
      console.error(`Parameters "latitude" and "longitude" have incorrect data type. lat: ${JSON.stringify(this.latitude)}, lng: ${JSON.stringify(this.longitude)}`);
    }
  }

  private buildUrl(): string {
    const marker = this.getMarkerOption('blue', 'S', this.latitude, this.longitude);
    const mapCenter = this.getMapCenter();
    const mapSize = this.getMapSize();
    const mapType = this.getMapType();
    const mapZoom = this.getMapZoom();
    const key = this.getSimpleOption('key', this.googleMapsApiKey);

    return `${this.googleStaticMapsApiUrl}?${marker}&${mapCenter}&${mapSize}&${mapType}&${mapZoom}&${key}`;
  }

  private getMapCenter(): string {
    let lat = this.latitude;
    let long = this.longitude;
    if (_.isFinite(+this.mapCenterLatitude) && _.isFinite(+this.mapCenterLongitude)) {
      lat = +this.mapCenterLatitude;
      long = +this.mapCenterLongitude;
    }
    return this.getCenterOption(lat, long);
  }

  private getMapSize(): string {
    let width = this.defaults.mapWidth;
    let height = this.defaults.mapHeight;
    if (_.isFinite(+this.mapWidth) && _.isFinite(+this.mapHeight)) {
      width = +this.mapWidth;
      height = +this.mapHeight;
    }
    this.currentMapWidth = width;
    this.currentMapHeight = height;

    return this.getSizeOption(width, height);
  }

  private getMapType(): string {
    let mapType = this.defaults.mapType;
    if (_.isString(this.mapType) && _.size(this.mapType) > 0) {
      mapType = this.mapType;
    }
    return this.getSimpleOption('maptype', mapType);
  }

  private getMapZoom(): string {
    let zoom = this.defaults.mapZoom;
    if (_.isFinite(+this.mapZoom)) {
      zoom = this.mapZoom;
    }
    return this.getSimpleOption('zoom', zoom);
  }

  private getMarkerOption(color: string, label: string, latitude: number, longitude: number): string {
    return `markers=color:${color}|label:${label}|${latitude},${longitude}`;
  }

  private getCenterOption(latitude: number, longitude: number): string {
    return `center=${latitude},${longitude}`;
  }

  private getSizeOption(width: number, height: number): string {
    return `size=${width}x${height}`;
  }

  private getSimpleOption(key: string, val: any): string {
    return `${key}=${val}`;
  }
}
