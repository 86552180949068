export interface IAccrualRecalculate {
    requestId: number;
    orgLevelId: string;
    organizationName: string;
    requestDate: Date;
    startDate: Date;
    endDate: Date;
    createdBy: string;
    employeeCount: number;
}

export class AccrualRecalculate {
    public requestId: number;
    public orgLevelId: string;
    public organizationName: string;
    public requestDate: Date;
    public startDate: Date;
    public endDate: Date;
    public createdBy: string;
    public employeeCount: number;
}
