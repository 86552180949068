import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { PbjNavigationService } from '../../services/index';

@Directive({
  selector: '[slxPbjExportDetailsLink]',
})
export class PbjExportDetailsLinkDirective {
  @Input('slxPbjExportDetailsLink')
  public exportId: number;
  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: PbjNavigationService = new PbjNavigationService(this.router, this.route);
    navService.NavigateToExportLogDetails(this.exportId);
  }
}
