import { Component, OnInit, ViewChild, Inject, Provider } from '@angular/core';
import { EmployeeGridComponent } from '../employee-grid/employee-grid.component';
import { Employee, EmployeeActionDefinition } from '../../models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN, FILTERED_EMPLOYEES_TOKEN } from '../../../../core/models/index';
import * as _ from 'lodash';
import { ModalService, ConfirmOptions, ColumnManagementService, IDialog, DialogOptions2, DialogModeSize, ConfirmDialog2Component } from '../../../../common/index';
import { employeeListConfig } from '../../employee-list.config';
import { NotificationsService } from '../../../../core/components';

@Component({
  moduleId: module.id,
  selector: 'slx-send-email-instructions.component',
  templateUrl: 'send-email-instructions.component.html',
  styleUrls: ['send-email-instructions.component.scss'],
  providers: [ColumnManagementService]
})

export class SendEmailInstructionsComponent implements OnInit, IDialog {
  @ViewChild(EmployeeGridComponent, { static: true })
  private employeeGrid: EmployeeGridComponent;
  public actionEmployees: Employee[];
  public employees: Employee[];
  public fieldDefinition: EmployeeActionDefinition;
  public orgLevel: OrgLevel;
  public optInEmailTemplate: string = '';
  public filteredEmployee: Employee[] = [];
  public state: {
    isLoading: boolean;
  };
  public dialogResult: boolean;
  public canSend: boolean;
  private selectedEmployees: Employee[];
  private options: DialogOptions2;
  private m_initialized: boolean = false;
  private modalService: ModalService;
  private groupActivitiesService: GroupActivitiesApiService;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;

  public static openDialog(
    modalService: ModalService, actionDefinition: Provider[],
    callback: (result: boolean) => void
  ): SendEmailInstructionsComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions,

      }
    ];
    resolvedProviders = [...resolvedProviders, ...actionDefinition];
    let dialog: SendEmailInstructionsComponent = modalService.globalAnchor.openDialog2(
      SendEmailInstructionsComponent,
      'Email Opt In Instructions',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
      }
    );
    return dialog;
  }

  constructor(groupActivitiesService: GroupActivitiesApiService,
    employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    modalService: ModalService,
    fieldDefinition: EmployeeActionDefinition,
    orgLevel: OrgLevel,
    options: DialogOptions2,
    private notificationsService: NotificationsService,
    @Inject(EMPLOYEES_ID_TOKEN) employees: Employee[],
    @Inject(FILTERED_EMPLOYEES_TOKEN) filteredEmployee: Employee[]

  ) {
    this.groupActivitiesService = groupActivitiesService;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.modalService = modalService;
    this.fieldDefinition = fieldDefinition;
    this.orgLevel = orgLevel;
    this.options = options;
    this.employees = employees;
    this.state = {
      isLoading: false,
    };
    this.filteredEmployee = filteredEmployee;
    this.dialogResult = false;
  }

  public ngOnInit(): void {
    this.m_initialized = true;
    this.loadEmployeeData();
    this.getEmailTemplate();
  }

  public loadEmployeeData() {
    this.state.isLoading = true;
    let employeeIds: string[] = _.map(this.filteredEmployee, (employee: Employee) => employee[employeeListConfig.employeeIdentifierName]);
    this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, true, employeeIds, this.fieldDefinition).then((actionEmployees: Employee[]) => {
      this.validateEmployee(actionEmployees);
    });
    this.state.isLoading = false;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public employeesSelect(selectedEmployees: Employee[]): void {
    this.selectedEmployees = selectedEmployees;
    this.canSend = this.selectedEmployees && this.selectedEmployees.length > 0;
  }

  public validateEmployee(actionEmployees: Employee[]): void {
    const selectedEmployees: Employee[] = _.filter(actionEmployees, (employee: Employee) => {
      if (employee.Email1 && _.trim(employee.Email1) != '') {
        if (employee.OptIn == 'Opted Out') {
          employee.Selectable = true;
          employee.Tooltip = '';
        } else {
          employee.Selectable = false;
          employee.isSelected = false;
        }
      } else {
        employee.Selectable = false;
        employee.isSelected = false;
        employee.Tooltip = `Employee doesn't have email id`;
      }
      return (employee);
    });

    this.actionEmployees = selectedEmployees;
  }

  public onSend(): void {
    this.state.isLoading = true;
    const employeeIds = _.map(this.selectedEmployees, item => item.EmpId);
    this.employeeDefinitionsApiService.resendOptInEmail(employeeIds)
      .then(() => {
        this.state.isLoading = false;
        this.canSend = false;
        this.notificationsService.success('Email sent', 'Email opt in instructions were sent successfully');
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
      }).catch(() => {
        this.state.isLoading = false;
      });
  }

  public getEmailTemplate(): void {
    this.state.isLoading = true;
    this.employeeDefinitionsApiService.getEmailTemplate()
      .then(template => {
        this.state.isLoading = false;
        this.optInEmailTemplate = template;
      }).catch(() => {
        this.state.isLoading = false;
      });
  }
}
