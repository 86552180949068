import { Directive } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

@Directive({
    selector: '[provide-parent-form]',
    providers: [
        {
            provide: ControlContainer,
            useFactory: profideForm,
            deps: [NgForm]
        }
    ]
})
export class ProvideParentFormDirective {}

export function profideForm(form: NgForm): NgForm {
    return form;
}
