<slx-spinner [show]="state.isLoading">
  <form novalidate #groupForm="ngForm" class="slx-full-height">
    <kendo-grid #grid class="slx-full-height"
      [data]="gridState.view"
      [selectable]="false"
      (cancel)="gridState.cancelHandler($event)"
      (edit)="gridState.editHandler($event)"
      (remove)="gridState.removeHandler($event)"
      (save)="gridState.saveHandler($event)"
    >

      <ng-template kendoGridToolbarTemplate>
        <button type="button" (click)="onAddGroup(grid)" class="theme-button-apply add-group">Add Group</button>
        <button *ngIf="isAnySelected" type="button" (click)="onDeleteGroup()" class="theme-button-apply">Delete Group</button>
        <button type="button" (click)="onEditGroupsDiscard()" class="theme-button-cancel return-budget">Return to Budget editing</button>
      </ng-template>

      <kendo-grid-command-column title="Сommand" width="100" class="overflow-visible-cell">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
          <div class="flex-horizontal">
            <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button">
             <i class="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
            <div class="theme-tooltip">
              <span *ngIf="dataItem.group.assignedPositionCount>0" class="theme-tooltiptext right remove-tooltip">You cannot delete assigned to any position Budget Group</span>
              <button kendoGridRemoveCommand type="button" [disabled]="dataItem.group.assignedPositionCount>0" class="theme-icon-button theme-inline-apply-button">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
            <button kendoGridSaveCommand type="button" [disabled]="!groupForm.valid" class="theme-icon-button theme-inline-apply-button">
          <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
         </button>
            <button kendoGridCancelCommand type="button" class="theme-icon-button theme-inline-cancel-button">
          <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
         </button>
          </div>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-column media="xs" title="Items" headerClass="detail-header" class="detail-cell">
        <ng-template kendoGridCellTemplate let-dataItem>
          <!-- template for mobile -->
          <h4> Budgeted Group</h4>
          <dl>
            <dt>Name</dt>
            <dd>{{dataItem?.group.description}}</dd>

            <dt>Position assigned</dt>
            <dd>{{dataItem?.group.assignedPositionCount}}</dd>
          </dl>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <!-- template for mobile -->
          <h4> Budgeted Group</h4>
          <dl>
            <dt>Name</dt>
            <dd>
              <slx-input-decorator className="slx-no-label">
                <input slx-input required="true" (keyup)="onKeyName($event, dataItem, nameField)" #nameField="ngModel" name="group{{idx}}"
                  placeholder="Budgeted group" [(ngModel)]="dataItem.groupName">
                <span errorMessage for="required"></span>
                <span errorMessage for="unique">Group Name should be unique</span>
              </slx-input-decorator>
            </dd>
            <dt>Position assigned</dt>
            <dd>{{dataItem?.group.assignedPositionCount}}</dd>
          </dl>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Timeclock" media="sm" field="timeclock.name">
        <ng-template kendoGridHeaderTemplate>
          Budgeted Group
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem?.group.description}}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <slx-input-decorator className="slx-no-label">
            <input slx-input required="true" (keyup)="onKeyName($event, dataItem, nameField)" #nameField="ngModel" name="group{{idx}}"
              placeholder="Budgeted group" [(ngModel)]="dataItem.groupName">
            <span errorMessage for="required"></span>
            <span errorMessage for="unique">Group Name should be unique</span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Restriction" media="sm" field="restriction.name">
        <ng-template kendoGridHeaderTemplate>
          Position assigned
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem?.group.assignedPositionCount}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</slx-spinner>
