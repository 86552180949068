
<div class="providers-tabs flex left">

    <div class="providers-tabs__slider">
      <ul #list class="providers-tabs__list flex flex-direction-row">
        <li class="providers-tabs__item" [class.active]="activeTab0" (click)="onClickTab(0)">
            <span class="header-title__text">Organization</span>
        </li>
        <li class="providers-tabs__item" [class.active]="activeTab1" (click)="onClickTab(1)">
             <span class="header-title__text">Department</span></li>
            <li class="providers-tabs__item" [class.active]="activeTab2" (click)="onClickTab(2)">
                     <span class="header-title__text">Position</span>
              </li>
       
      </ul>
    </div>
  
    <ng-container *ngIf="activeTab0">
      <slx-work-location-migration></slx-work-location-migration>
    </ng-container>
    <ng-container *ngIf="activeTab1">
      <slx-department></slx-department>
      
    </ng-container>
    <ng-container *ngIf="activeTab2">
    <slx-position></slx-position>
  </ng-container>
  </div>
  
  