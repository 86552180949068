import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { EmployeeDetails } from '../../../organization/models/index';
import { Assert } from '../../../framework/index';
import { ModalService } from '../../../common/index';
import { unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';
import { mutableSelect } from '../../../core/decorators/index';
import { ArrivalDetails, DepartureDetails, AttendanceType, AttendanceTypeDefinition, ArrivalsDeparturesAnalytics, ArrivalsDeparturesAnalyticsDefinition } from '../../models/index';
import { ArrivalsDeparturesApiService } from './arrivals-departures-api.service';
import { ArrivalsDeparturesMapService } from './arrivals-departures-map.service';
var ArrivalDeparturesDetailsManagementService = /** @class */ (function () {
    function ArrivalDeparturesDetailsManagementService(arrivalsDeparturesApiService, arrivalsDeparturesMapService, modalService) {
        var _this = this;
        this.arrivalsDeparturesApiService = arrivalsDeparturesApiService;
        this.arrivalsDeparturesMapService = arrivalsDeparturesMapService;
        this.modalService = modalService;
        this.selectedDate = moment().toDate();
        this.arrivalsDeparturesApiService = arrivalsDeparturesApiService;
        this.arrivalsDeparturesMapService = arrivalsDeparturesMapService;
        this.onLoaded$ = new ReplaySubject(1);
        this.onLoadStarted$ = new ReplaySubject(1);
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            if (o.id) {
                _this.selectedOrgLevel = o;
                _this.loadData(_this.selectedOrgLevel, _this.selectedDate, _this.selectedTime, _this.attendanceType, _this.analytics);
            }
        });
    }
    ArrivalDeparturesDetailsManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadDetails = function (dateOn, timeOn, attendanceType, analytics) {
        this.attendanceType = attendanceType;
        this.analytics = analytics;
        this.selectedDate = dateOn;
        this.selectedTime = timeOn;
        this.loadData(this.selectedOrgLevel, dateOn, timeOn, attendanceType, analytics);
    };
    ArrivalDeparturesDetailsManagementService.prototype.changeSelectedDate = function (selectedDate) {
        this.selectedDate = selectedDate;
        this.loadData(this.selectedOrgLevel, this.selectedDate, this.selectedTime, this.attendanceType, this.analytics);
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadData = function (orgLevel, dateOn, timeOn, attendanceType, analytics) {
        Assert.isNotNull(this.selectedOrgLevel, 'orgLevel');
        this.onLoadStarted$.next(null);
        if (attendanceType === AttendanceType.arrivals) {
            if (analytics === ArrivalsDeparturesAnalytics.in) {
                this.loadArrivalInEmployees(this.selectedDate, this.selectedTime);
                return;
            }
            else if (analytics === ArrivalsDeparturesAnalytics.scheduled) {
                this.loadArrivalScheduledEmployees(this.selectedDate, this.selectedTime);
                return;
            }
            else if (analytics === ArrivalsDeparturesAnalytics.unscheduled) {
                this.loadArrivalUnscheduledEmployees(this.selectedDate);
                return;
            }
            else if (analytics === ArrivalsDeparturesAnalytics.difference) {
                this.loadArrivalDifferenceEmployees(this.selectedDate, this.selectedTime);
                return;
            }
            else if (analytics === ArrivalsDeparturesAnalytics.late) {
                this.loadArrivalLateEmployees(this.selectedDate, this.selectedTime);
                return;
            }
        }
        else if (attendanceType === AttendanceType.departures) {
            if (analytics === ArrivalsDeparturesAnalytics.out) {
                this.loadDepartureOutEmployees(this.selectedDate, this.selectedTime);
                return;
            }
            else if (analytics === ArrivalsDeparturesAnalytics.scheduled) {
                this.loadDepartureScheduledEmployees(this.selectedDate, this.selectedTime);
                return;
            }
            else if (analytics === ArrivalsDeparturesAnalytics.unscheduled) {
                this.loadDepartureUnscheduledEmployees(this.selectedDate);
                return;
            }
            else if (analytics === ArrivalsDeparturesAnalytics.difference) {
                this.loadDepartureDifferenceEmployees(this.selectedDate, this.selectedTime);
                return;
            }
            else if (analytics === ArrivalsDeparturesAnalytics.overtime) {
                this.loadDepartureOvertimeEmployees(this.selectedDate, this.selectedTime);
                return;
            }
            throw new Error("Can't load data for attendanceType='" + attendanceType + "' analytics='" + analytics + ".'");
        }
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadArrivalScheduledEmployees = function (scheduledDate, scheduledTime) {
        return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, scheduledTime, function (employeeDetailsItem) { return employeeDetailsItem.isScheduled; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadArrivalUnscheduledEmployees = function (scheduledDate) {
        return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, null, function (employeeDetailsItem) { return !employeeDetailsItem.isScheduled; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadArrivalInEmployees = function (scheduledDate, scheduledTime) {
        return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, scheduledTime, function (employeeDetailsItem) { return employeeDetailsItem.isIn; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadArrivalLateEmployees = function (scheduledDate, scheduledTime) {
        return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, scheduledTime, function (employeeDetailsItem) { return employeeDetailsItem.isLate; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadArrivalDifferenceEmployees = function (scheduledDate, scheduledTime) {
        return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, scheduledTime, function (employeeDetailsItem) { return employeeDetailsItem.isDiff; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadDepartureScheduledEmployees = function (scheduledDate, scheduledTime) {
        return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, scheduledTime, function (employeeDetailsItem) { return employeeDetailsItem.isScheduled; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadDepartureUnscheduledEmployees = function (scheduledDate) {
        return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, null, function (employeeDetailsItem) { return !employeeDetailsItem.isScheduled; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadDepartureOutEmployees = function (scheduledDate, scheduledTime) {
        return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, scheduledTime, function (employeeDetailsItem) { return employeeDetailsItem.isOut; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadDepartureOvertimeEmployees = function (scheduledDate, scheduledTime) {
        return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, scheduledTime, function (employeeDetailsItem) { return employeeDetailsItem.isInOvertime; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadDepartureDifferenceEmployees = function (scheduledDate, scheduledTime) {
        return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, scheduledTime, function (employeeDetailsItem) { return employeeDetailsItem.isDiff; });
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadEmployeeDetailsByArrivals = function (orgLevel, scheduledDate, scheduledTime, filterEmployee) {
        var _this = this;
        Assert.isNotNull(this.selectedOrgLevel, 'orgLevel');
        this.onLoadStarted$.next(null);
        if (scheduledTime) {
            this.arrivalsDeparturesApiService.getArrivalsDetails(this.selectedOrgLevel.id, scheduledDate, scheduledTime).then(function (details) {
                var scheduledEmployees = _.filter(details, filterEmployee);
                var employeeDetails = _this.arrivalsDeparturesMapService.mapArrivalsDetailsToEmployeeDetails(scheduledEmployees);
                _this.onLoaded$.next(employeeDetails);
            });
        }
        else {
            this.arrivalsDeparturesApiService.getArrivalsUnscheduledDetails(this.selectedOrgLevel.id, scheduledDate).then(function (details) {
                var scheduledEmployees = _.filter(details, filterEmployee);
                var employeeDetails = _this.arrivalsDeparturesMapService.mapArrivalsDetailsToEmployeeDetails(scheduledEmployees);
                _this.onLoaded$.next(employeeDetails);
            });
        }
    };
    ArrivalDeparturesDetailsManagementService.prototype.loadEmployeeDetailsByDepartures = function (orgLevel, scheduledDate, scheduledTime, filterEmployee) {
        var _this = this;
        Assert.isNotNull(this.selectedOrgLevel, 'orgLevel');
        this.onLoadStarted$.next(null);
        if (scheduledTime) {
            this.arrivalsDeparturesApiService.getDeparturesDetails(this.selectedOrgLevel.id, scheduledDate, scheduledTime).then(function (details) {
                var scheduledEmployees = _.filter(details, filterEmployee);
                var employeeDetails = _this.arrivalsDeparturesMapService.mapDeparturesDetailsToEmployeeDetails(scheduledEmployees);
                _this.onLoaded$.next(employeeDetails);
            });
        }
        else {
            this.arrivalsDeparturesApiService.getDeparturesUnscheduledDetails(this.selectedOrgLevel.id, scheduledDate).then(function (details) {
                var scheduledEmployees = _.filter(details, filterEmployee);
                var employeeDetails = _this.arrivalsDeparturesMapService.mapDeparturesDetailsToEmployeeDetails(scheduledEmployees);
                _this.onLoaded$.next(employeeDetails);
            });
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ArrivalDeparturesDetailsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalDeparturesDetailsManagementService.prototype, "orgLevelSubscription", void 0);
    return ArrivalDeparturesDetailsManagementService;
}());
export { ArrivalDeparturesDetailsManagementService };
