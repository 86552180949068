import { Provider } from '@angular/core';
import { DialogOptions } from '../../models/index';
import { ModalService } from '../../services/modal/modal.service';
var ConfirmOptions3 = /** @class */ (function () {
    function ConfirmOptions3() {
    }
    return ConfirmOptions3;
}());
export { ConfirmOptions3 };
var ConfirmDialog3Component = /** @class */ (function () {
    function ConfirmDialog3Component(options, modalService, confirmOptions) {
        this.options = options;
        this.modalService = modalService;
        this.confirmOptions = confirmOptions;
        this.dialogResult = false;
    }
    Object.defineProperty(ConfirmDialog3Component.prototype, "okButtonText", {
        get: function () {
            if (!this.confirmOptions || !this.confirmOptions.buttonOKtext) {
                return 'OK';
            }
            return this.confirmOptions.buttonOKtext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmDialog3Component.prototype, "cancelButtonText", {
        get: function () {
            if (!this.confirmOptions || !this.confirmOptions.buttonCanceltext) {
                return 'Cancel';
            }
            return this.confirmOptions.buttonCanceltext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmDialog3Component.prototype, "itemData", {
        get: function () {
            return this.confirmOptions.itemData;
        },
        enumerable: true,
        configurable: true
    });
    ConfirmDialog3Component.openDialog = function (title, message, modalService, callback, options) {
        var dialogOptions = new DialogOptions();
        dialogOptions.message = message;
        var confirmOptions = options;
        if (!options) {
            confirmOptions = new ConfirmOptions3();
            confirmOptions.showOK = true;
            confirmOptions.showCancel = true;
        }
        dialogOptions.width = confirmOptions.width || 350;
        dialogOptions.height = confirmOptions.height || 250;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: ConfirmOptions3,
                useValue: confirmOptions
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(ConfirmDialog3Component, title, dialogOptions, resolvedProviders, callback);
        return dialog;
    };
    ConfirmDialog3Component.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ConfirmDialog3Component.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return ConfirmDialog3Component;
}());
export { ConfirmDialog3Component };
