import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { TimecardsEarningPayRule } from '../index';

export class PayCodeGridModel {
  public positionName: string;
  public rulesMap: Dictionary<TimecardsEarningPayRule[]>;

  public getRuleValue(name: string): any {
    let rules: TimecardsEarningPayRule[] = this.rulesMap[name];
    if (rules) {
      return _.first(rules).value;
    }
    return null;
  }

  public isMoneyRule(name: string): boolean {
    const rules: TimecardsEarningPayRule[] = this.rulesMap[name];
    return rules && _.first(rules).isMoneyAmountRule;
  }
}
