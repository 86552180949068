import { Component, OnInit, OnChanges, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { IFilteredItems, PagingData } from '../../../../../core/models/index';
import { PbjExportApiService } from '../../../services/index';
import { PBJExportLogEmployeeData } from '../../../models/index';
import { ServerFilterService } from '../../../../../core/services/index';
import { PbjDetailsBasicGridComponent } from '../pbj-details-basic-grid/pbj-details-basic-grid.component';
import { GridExportType } from '../../../models/pbj-export/grid-export-type';
import { StateManagementService } from '../../../../../common/index';
import { PbjExportColumn } from '../../../models/pbj-export/pbj-export-column';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-details-emp-data-grid',
  templateUrl: 'pbj-details-emp-data-grid.component.html',
  styleUrls: ['pbj-details-emp-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerFilterService, StateManagementService]
})
export class PbjDetailsEmpDataGridComponent extends PbjDetailsBasicGridComponent<PBJExportLogEmployeeData> implements OnInit, OnChanges {

  @Input()
  public exportId: number;
  @Output()
  public loadStatus: EventEmitter<boolean>;

  public get exportFilename(): string {
    return `export-${this.exportId}-employee-data`;
  }

  protected readonly controlKey = 'PbjExportEmpDataComponent';

  constructor(apiService: PbjExportApiService, serverFilterService: ServerFilterService, changeDetector: ChangeDetectorRef, stateManagement: StateManagementService) {
    super(apiService, serverFilterService, changeDetector, stateManagement);
  }

  public refreshData(): void {
    this.onLoadStatus(true);
    this.apiService.getPBJExportLogEmployeeData(this.serverFilterService.createQuery(this.pagingData, this.gridState.state.sort), this.exportId)
      .then((val: IFilteredItems<PBJExportLogEmployeeData>) => {
        this.records = val;
        this.onLoadStatus(false);
        this.showChanges();
      }).catch((reason: any) => {
        this.onLoadStatus(false);
      });
  }

  public loadAndExport(type: GridExportType): void {
    this.onLoadStatus(true);
    let zeroPaging: PagingData = { skip: 0 };
    this.apiService.getPBJExportLogEmployeeData(this.serverFilterService.createQuery(zeroPaging, this.gridState.state.sort), this.exportId)
      .then((val: IFilteredItems<PBJExportLogEmployeeData>) => {
        this.processExportData(type, val);
        this.onLoadStatus(false);
      }).catch((reason: any) => {
        this.exportRecords = null;
        this.onLoadStatus(false);
      });
  }

  protected applyDefaultSort(): void {
    this.gridState.state.sort = [{ field: 'employeeName', dir: 'asc' }];
  }

  protected updateColumnsConfig(): void {
    super.updateColumnsConfig();
    const specificColumns: PbjExportColumn[] = [
      { field: 'employeeName', displayTitle: 'Name', index: 1, width: 150 },
      { field: 'payrollNumber', displayTitle: 'Payroll No', index: 2, width: 150 }
    ];
    this.xlsExportColumns.push(...specificColumns);
  }

}

