import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../app.config';
import { ModalService, StateManagementService } from '../../../../common/services/index';
import { ShiftReplacementApiService, ShiftReplacementManagementService } from '../../../services/index';
import { IColumnSettings } from '../../../../core/models/index';
import { destroyService, mutableSelect } from '../../../../core/decorators/index';
import {
  ShiftReplacementSettings,
  ShiftReplacementInfo,
  ShiftEligibleEmployee,
  ShiftSmsNotificationResponse,
  ShiftReplacementRequest,
  ShiftPhoneCall,
  ShiftNotificationRequest,
  ShiftNotificationResponse,
  ShiftReplacementSms
} from '../../../models/index';
import { ShiftReplacementPhoneCallComponent } from '../shift-replacement-phone-call/shift-replacement-phone-call.component';
import { ShiftReplacementMsgComponent } from '../shift-replacement-msg/shift-replacement-msg.component';
import { ShiftReplacementGridComponent } from '../shift-replacement-grid/shift-replacement-grid.component';
import { screenUtils, IScreenUtils } from '../../../../common/utils/index';
import { PollingPeriodService } from '../../../../core/services/polling-period/polling-period.service';
import { UserActivityService } from '../../../../core/services/user-activity/user-activity.service';
import { unsubscribe } from '../../../../core/decorators/index';
import { IControlState, StateResetTypes } from '../../../../core/models/index';
import { TokenValidityService } from '../../../../core/services/index';
import { ScheduleAbsence } from '../../../../organization/models/lookup/index';
import { NotificationsService } from '../../../../core/components';
import { ScheduleCycle, ScheduleCycleHelperService } from './../../../../../app/organization';
import { OrgLevel } from './../../../../../app/state-model/models';

@Component({
  moduleId: module.id,
  selector: 'slx-shift-replacement-selector',
  templateUrl: 'shift-replacement-selector.component.html',
  styleUrls: ['shift-replacement-selector.component.scss'],
  providers: [StateManagementService]
})
export class ShiftReplacementSelectorComponent implements OnInit, OnDestroy {

  @Input()
  public settings: ShiftReplacementSettings;

  @Input('scheduleAbsences')
  public set initialScheduleAbsences(value: ScheduleAbsence[]) {
    if (_.isArray(value) && _.size(value) > 0) {
      this.scheduleAbsences = value;
      this.scheduleAbsence = this.settings.request.scheduleAbsence;
    }
  }

  @Output()
  public changedAbsences: EventEmitter<ScheduleAbsence>;

  public shiftReplacementInfo: ShiftReplacementInfo;
  public state: {
    isLoading: boolean;
  };

  public get hasAbcenses(): boolean {
    return _.isArray(this.scheduleAbsences) && _.size(this.scheduleAbsences) > 0;
  }

  public screenUtils: IScreenUtils;
  public scheduleAbsences: ScheduleAbsence[];
  public sendSmsModeOn: boolean;
  public canSendSms: boolean;

  @destroyService()
  private shiftManagementService: ShiftReplacementManagementService;

  private filtersControlKey: string = 'filter';
  private scheduleAbsence: ScheduleAbsence;

  @ViewChild(ShiftReplacementGridComponent, {static: true})
  private replacementGrid: ShiftReplacementGridComponent;
  private applicationHeartbeatService: PollingPeriodService;
  @unsubscribe()
  private poolingSubscription: Subscription;
  @unsubscribe()
  private contextChangeSubscription: Subscription;
  @unsubscribe()
  private initSubscription: Subscription;
  @unsubscribe()
  private changedSmsModeSubscription: Subscription;
  @unsubscribe()
  private sendSmsSubscription: Subscription;

  @unsubscribe()
  private orgLevelSubscripion: Subscription;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;

  constructor(
    private shiftReplacementApiService: ShiftReplacementApiService,
    private modalService: ModalService,
    private userActivityService: UserActivityService,
    private stateManagement: StateManagementService,
    private tokenValidity: TokenValidityService,
    shiftManagementService: ShiftReplacementManagementService,
    private notificationsService: NotificationsService,
    private scheduleCycleHelperService: ScheduleCycleHelperService
  ) {
    this.applicationHeartbeatService = new PollingPeriodService(this.userActivityService, this.tokenValidity);
    this.changedAbsences = new EventEmitter<ScheduleAbsence>();
    this.shiftManagementService = shiftManagementService;
    this.sendSmsModeOn = false;
    this.state = {
      isLoading: true
    };
    this.shiftManagementService.init();
    this.poolingSubscription = this.applicationHeartbeatService.onHeartbeat.subscribe(() => {
      this.refreshNotificationResponses();
    });
    this.contextChangeSubscription = this.stateManagement.onComponentActiveStateChanged$.subscribe((key: StringMap<any>) => {
      this.stateManagement.loadData();
    });
    this.initSubscription = this.stateManagement.onInit$.subscribe(() => {
      this.restoreFilters();
    });

    this.changedSmsModeSubscription = this.shiftManagementService.subscribeToChangeSmsMode((smsModeOn: boolean) => {
      this.sendSmsModeOn = smsModeOn;
    });
    this.sendSmsSubscription = this.shiftManagementService
      .subscribeToSendSms((smsData: ShiftReplacementSms) => {
        this.sendSms(smsData);
      });
      this.orgLevelSubscripion = this.orgLevel$.subscribe((selectedOrgLevel: OrgLevel) => {
        this.orgLevel = selectedOrgLevel;
      });
  }

  public ngOnInit(): void {
    this.screenUtils = screenUtils;
    this.applicationHeartbeatService.listen(appConfig.notifyPoolingInterval);
    this.stateManagement.init('ShiftReplacementSelectorComponent');
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.applicationHeartbeatService.stop();
  }

  public onColumnsChanged(): void {
    this.replacementGrid.onSettingsChanged();
  }

  public onSettingsChanged(settings: ShiftReplacementSettings): void {
    this.loadReplacementInfo();
  }

  public onAbsenceChanged(absence: ScheduleAbsence): void {
    this.shiftManagementService.replaceEmployee.scheduleAbsence = absence;
    this.shiftManagementService.replaceEmployee.absenceCode = absence.code;
    this.changedAbsences.emit(absence);
  }

  public onRefreshByCheck(): void {
    this.saveFilters();
    this.replacementGrid.refresh();
  }

  public onTurnOnSmsMode(): void {
    this.shiftManagementService.changeSmsMode(true);
  }

  public sendSms(smsData: ShiftReplacementSms): void {
    let req: ShiftNotificationRequest = new ShiftNotificationRequest();
    req.employeeIds = _.map(smsData.recipients, (record: ShiftEligibleEmployee) => { return record.employee.id; });
    req.positionId = this.settings.request.positionId;
    req.date = this.settings.request.date;
    req.unitId = this.settings.request.unitId;
    req.text = smsData.message;
    this.state.isLoading = true;
    this.shiftReplacementApiService
      .sendSms(this.settings.request.shiftId, req)
      .then((res: any) => {
        this.state.isLoading = false;
        _.forEach(smsData.recipients, (record: ShiftEligibleEmployee) => {
          record.isSmsSent = true;
          record.smsResponse = new ShiftSmsNotificationResponse();
          record.smsResponse.messageText = smsData.message;
          record.smsResponse.dateSent = new Date();
          this.notificationsService.success('SMS message sent', 'SMS message was sent successfully');
        });
        this.shiftManagementService.changeSmsMode(false);
      })
      .catch(() => {
      this.state.isLoading = false;
      });
  }

  /* public onPhoneCall(): void {
    if (this.isAnyEmployeeRowSelected) {
      let employee: ShiftEligibleEmployee = this.selectedRowRecords[0];
      ShiftReplacementPhoneCallComponent.openDialog(this.modalService, employee, (result: boolean, phoneCall: ShiftPhoneCall) => {
        if (result) {
          this.shiftReplacementApiService.addPhoneCallInfo(this.settings.request.shiftId, employee.employee.id, this.settings.request.positionId, this.settings.request.date, phoneCall).then((res: any) => {
            //TBD
            employee.phoneCall = phoneCall;
            employee.isPhoneCalled = true;
          });
        }
      });
    }
  } */

 /*  public onPostText(): void {
    if (this.isAnyEmployeeSelected) {
      ShiftReplacementMsgComponent.openDialog(this.modalService, 'Open Shift', this.generatePostText(), (result: boolean, textMsg: string) => {
        if (result) {
          let req: ShiftNotificationRequest = new ShiftNotificationRequest();
          req.employeeIds = _.map(this.selectedRecords, (record: ShiftEligibleEmployee) => { return record.employee.id; });
          req.positionId = this.settings.request.positionId;
          req.date = this.settings.request.date;
          req.text = textMsg;
          this.shiftReplacementApiService.sendAppNotification(this.settings.request.shiftId, req)
            .then((res: any) => {
              //TBD
              _.forEach(this.selectedRecords, (record: ShiftEligibleEmployee) => {
                record.isAppNotificationSent = true;
              });

            });
        }
      });
    }
  } */

  private refreshNotificationResponses(): void {
    if (!this.settings) {
      return;
    }
    this.shiftReplacementApiService.getShiftNotifications(this.settings.request.shiftId, this.settings.request.positionId, this.settings.request.date)
      .then((responses: ShiftNotificationResponse[]) => {
        this.mapResponsesToRecords(this.shiftReplacementInfo, responses);
      });
  }

  private mapResponsesToRecords(shiftReplacementInfo: ShiftReplacementInfo, responses: ShiftNotificationResponse[]): void {
    let groupByEmp: _.Dictionary<ShiftEligibleEmployee[]> = _.groupBy(shiftReplacementInfo.records, (record: ShiftEligibleEmployee) => {
      return record.employee.id;
    });
    _.forEach(responses, (response: ShiftNotificationResponse) => {
      let group: ShiftEligibleEmployee[] = groupByEmp[response.employeeId];
      if (group) {
        _.forEach(group, (emp: ShiftEligibleEmployee) => {
          emp.appResponse = response.appResponse;
          emp.smsResponse = response.smsResponse;
        });
      }
    });
  }

  private get smsText(): string {
    const req: ShiftReplacementRequest = _.get(this.settings, 'request') || {} as ShiftReplacementRequest;

    return `Open Shift opportunity for position:`+
      `${req.positionName}, ${moment(req.date).format(appConfig.dateFormat)} (${req.shiftName}) in ${req.unitName} Unit at ${req.organizationName}.`;
  }

  private loadReplacementInfo(): void {
    if (!this.settings) {
      return;
    }
    this.state.isLoading = true;
    this.scheduleCycleHelperService.getScheduleCycleByDate(this.settings.request.date, this.orgLevel.id)
    .then(({ startDate }: ScheduleCycle) => {
      this.shiftReplacementApiService.getShiftEligibleEmployees(this.settings.request, startDate)
      .then((shiftReplacementInfo: ShiftReplacementInfo) => {
        this.shiftReplacementInfo = shiftReplacementInfo;
        this.canSendSms = shiftReplacementInfo.canSendSms;
        this.state.isLoading = false;
        this.stateManagement.loadedData({});
      });
    })
  }

  private saveFilters(): void {
    this.stateManagement.setControlState(this.filtersControlKey,
      {
        value: {
          showDayOffEmployees: this.settings.request.showDayOffEmployees,
          showAgencyEmployees: this.settings.request.showAgencyEmployees,
          showAvailabilityRecords: this.settings.request.showAvailabilityRecords,
          showEmployeesOtherShifts: this.settings.request.showEmployeesOtherShifts,

        }
      }, StateResetTypes.None);
  }

  private restoreFilters(): void {
    let state: IControlState = this.stateManagement.getControlState(this.filtersControlKey);
    if (state.value) {
      this.settings.request.showDayOffEmployees = state.value.showDayOffEmployees;
      this.settings.request.showAgencyEmployees = state.value.showAgencyEmployees;
      this.settings.request.showAvailabilityRecords = state.value.showAvailabilityRecords;
      this.settings.request.showEmployeesOtherShifts = state.value.showEmployeesOtherShifts;
    }
    this.loadReplacementInfo();
  }
}
