import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import * as _ from 'lodash';
import * as moment from 'moment';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/filter';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../app.config';
import { ConfirmDialog2Component, ConfirmOptions, PositionMappingNavigationService, PostScheduleNavigationService, SoConsoleNavigationService, ToolbarSectionTypes, } from '../../../common/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { screenUtils } from '../../../common/utils';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { LookupType } from '../../../organization/models/index';
import { LookupService, ScheduleCycleHelperService } from '../../../organization/services/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { DetailGroupViewSettingsTypes, ShiftReplacementRequest, } from '../../models/index';
import { DetailScreenService, ScheduleApiService, ScheduleEntryApiService, ScheduleEntryManagementService, ShiftReplacementApiService, ShiftReplacementManagementService, } from '../../services/index';
import { ShiftRequestService } from '../../services/schedule/shift-request.service';
import { EmployeeAddShiftOpenComponent, ShiftReplacementReplaceComponent } from '../shift-replacement/index';
import { ComponentStateStorageService } from '../../../common/services/component-state/component-state-storage.service';
import { DailyUnitAssignmentNavigationService } from '../../../common/services/navigation/daily-unit-assignment-navigation.service';
import { IndividualScheduleNavigationService } from '../../../common/services/navigation/individual-schedule-navigation.service';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { StateResetTypes } from '../../../core/models/settings/index';
import { OrgLevelWatchService } from '../../../organization/services/org-level/org-level-watch.service';
import { DailyUnitGridEmployeesComponent } from './daily-unit-grid-employees/daily-unit-grid-employees.component';
import { AgenciesApiService, RolesApiService, PartnerConfigManagementService } from '../../../configuration/services';
import { AppSettingsManageService } from '../../../app-settings/services';
import { CancelTypeList } from '../../models/detail-screen/cancel-type';
import { ConfirmDialog3Component, ConfirmOptions3 } from '../../../common/components/confirm-dialog3/confirm-dialog3.component';
import { PendingShiftValue } from '../../models/daily-unit-assignment/add-open-shift';
import { DailyUnitViewSettingsPopupComponent } from './daily-unit-view-settings-popup/daily-unit-view-settings-popup.component';
import { scheduleMicrotask } from '../../../core/utils';
import { DailyUnitFilterDialogComponent } from './daily-unit-filter-dialog/daily-unit-filter-dialog.component';
import { DailyUnitFilterOptions } from '../../models/daily-unit-assignment/daily-unit-filter-options';
var imageCache = {};
var VIEW_MODE_KEY = 'view_mode';
var FILTERS = 'filters';
var DATE = 'date';
var DailyUnitGridComponent = /** @class */ (function () {
    function DailyUnitGridComponent(scheduleEntryApiService, scheduleApiService, detailScreenService, modalService, activatedRoute, router, shiftReplacementService, shiftReplacementApiService, scheduleCycleHelperService, lookupService, stateManagement, storageService, orgLevelService, shiftRequestService, management, agenciesApiService, appSettingsManageService, partnerConfigManagementService, rolesApiService) {
        var _this = this;
        this.scheduleEntryApiService = scheduleEntryApiService;
        this.shiftReplacementService = shiftReplacementService;
        this.lookupService = lookupService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.orgLevelService = orgLevelService;
        this.shiftRequestService = shiftRequestService;
        this.management = management;
        this.agenciesApiService = agenciesApiService;
        this.appSettingsManageService = appSettingsManageService;
        this.partnerConfigManagementService = partnerConfigManagementService;
        this.rolesApiService = rolesApiService;
        this.isLoading = false;
        this.requestShift = false;
        this.isEditOpenShift = false;
        this.enableopenshiftonfly = false;
        this.pendingShiftValue = [];
        this.loading = false;
        this.approvedPayPeriod = false;
        this.isModifyPayPeriodApproved = false;
        this.isOrglevelPayPeriodApproved = false;
        this.filtersApplied = false;
        this.onLoad = false;
        this.positionTabs = [];
        this.loadDetailsEvent = new Subject();
        this.scrollToValue = 0;
        this.IsEnabled = false;
        this.agencyListData = [];
        this.requestShiftIsEnabled = false;
        this.schedTotal = 0;
        this.schedIdealTotal = 0;
        this.dayTotal = 0;
        this.dayIdealTotal = 0;
        this.eveningTotal = 0;
        this.eveningIdealTotal = 0;
        this.nightTotal = 0;
        this.nightIdealTotal = 0;
        this.agencyTotalShifts = 0;
        this.agencyNightShifts = 0;
        this.agencyEveningShifts = 0;
        this.agencyDayShifts = 0;
        this.switchTotalShift = 0;
        this.switchDayShift = 0;
        this.switchEveningShift = 0;
        this.switchNightShift = 0;
        this.openTotalShifts = 0;
        this.openDayShifts = 0;
        this.openEveningShifts = 0;
        this.openNightShifts = 0;
        this.overTimeTotalShifts = 0;
        this.overTimeDayShifts = 0;
        this.overTimeEveningShifts = 0;
        this.overTimeNightShifts = 0;
        this.openShiftToggle = false;
        this.pendingShiftToggle = false;
        this.switchShiftToggle = false;
        this.overTimeShiftToggle = false;
        this.buttonNum = 0;
        this.isPositionMapped = true;
        this.isPartnerIntegrations = false;
        this.isTablet();
        this.isMobile();
        this.getScreenSize();
        this.scheduleEntryApiService = scheduleEntryApiService;
        this.scheduleApiService = scheduleApiService;
        this.detailScreenService = detailScreenService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.shiftReplacementApiService = shiftReplacementApiService;
        this.scheduleCycleHelperService = scheduleCycleHelperService;
        this.restrictedInfo = ['ShiftGroup'];
        this.selectedPositionGroupIds = [];
        this.selectedPositionIds = [];
        this.selectedShiftIds = [];
        this.selectedUnitIds = [];
        this.agencyMap = [];
        this.agencyListData = [];
        this.navigateService = new DailyUnitAssignmentNavigationService(this.router, this.activatedRoute);
        this.individualNavService = new IndividualScheduleNavigationService(this.router, this.activatedRoute);
        this.soNavService = new SoConsoleNavigationService(this.router, this.activatedRoute);
        this.positionMappingNavigationService = new PositionMappingNavigationService(this.router, this.activatedRoute);
        this.postScheduleNavigationService = new PostScheduleNavigationService(this.router, this.activatedRoute);
        this.state = {
            isLoading: false,
            isSelectPropagated: true,
            switchShiftMode: false,
            isLeftSidebarOpen: false,
        };
        this.eventSubscription = this.shiftRequestService.getClickEvent().subscribe(function () {
            _this.state.isLoading = true;
            _this.detailScreenService
                .getDailyUnits(_this.orgLevel, _this.dateOn)
                .then(function (response) {
                _this.details = response;
                _this.applyFilters();
                _this.categoriesDetails();
                _this.state.isLoading = false;
            })
                .catch(function (err) {
                _this.state.isLoading = false;
            });
        });
        this.buttonNum = 0;
    }
    Object.defineProperty(DailyUnitGridComponent.prototype, "dateOn", {
        get: function () {
            return this.m_dateOn;
        },
        set: function (value) {
            this.m_dateOn = value;
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitGridComponent.prototype.getScreenSize = function (event) {
        this.screenWidth = window.innerWidth;
    };
    DailyUnitGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.screenWidth = window.innerWidth;
        this.buttonNum = 0;
        this.getUserRoles();
        this.userSubscription = this.user$.subscribe(function (session) {
            if (session) {
                _this.alias = session.alias;
            }
        });
        this.stateManagement.init('DailyUnitComponent', true);
        this.state.isLoading = true;
        this.shiftGroup = true;
        var dateOnEvent$ = this.activatedRoute.params.map(function (_a) {
            var dateOn = _a.date;
            var d = moment(dateOn, appConfig.linkDateFormat).toDate();
            if (!dateOn) {
                return _this.getSavedDate();
            }
            else {
                _this.saveDate(d);
            }
            return d;
        });
        this.routeSubscripion = this.activatedRoute.params
            .combineLatest(this.activatedRoute.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            var posGroupIds = queryParams['positionGroupId'];
            if (posGroupIds)
                _this.pathPositionGroupIds = _.map(posGroupIds.split(','), function (item) { return +item; });
            var posIds = queryParams['positionId'];
            if (posIds)
                _this.pathPositionIds = _.map(posIds.split(','), function (item) { return +item; });
            var unitIds = queryParams['unitId'];
            if (unitIds)
                _this.unitIds = _.map(unitIds.split(','), function (item) { return +item; });
            var shiftIds = queryParams['shiftId'];
            if (shiftIds)
                _this.shiftIds = _.map(shiftIds.split(','), function (item) { return +item; });
            _this.setViewMode(queryParams[VIEW_MODE_KEY]);
        });
        this.dateSubscription = dateOnEvent$.subscribe(function (dateOn) { return (_this.dateOn = dateOn); });
        this.orgLevelSubscripion = this.orgLevel$.subscribe(function (selectedOrgLevel) {
            _this.orgLevel = selectedOrgLevel;
            // get application settings
            _this.appSettingsManageService.getAppServerConfig()
                .then(function (appConfig) {
                _this.isPartnerIntegrations = appConfig.partnerintegrations;
                _this.enableopenshiftonfly = appConfig.enableOpenShiftOnFly;
                // Update agency information
                if (_this.isPartnerIntegrations) {
                    _this.getPartnerAgenciesPositions(_this.orgLevel);
                }
            });
        });
        var orgLevelChangeEvent$ = this.orgLevel$.filter(function (selectedOrgLevel) { return selectedOrgLevel.type === OrgLevelType.department; });
        var detailsEvent$ = Observable.combineLatest(orgLevelChangeEvent$, dateOnEvent$, this.loadDetailsEvent).do(function () { return (_this.state.isLoading = true); });
        this.detailsSubscription = detailsEvent$.subscribe(function (_a) {
            var selectedOrgLevel = _a[0], dateOn = _a[1];
            if (!moment(dateOn).isValid()) {
                return;
            }
            _this.isLoading = true;
            _this.requestShiftIsEnabled = true;
            var currentDate = moment().format(appConfig.dateFormat);
            var seletedDate = moment(dateOn).format(appConfig.dateFormat);
            if (!moment(currentDate).isAfter(seletedDate)) {
                _this.requestShiftIsEnabled = false;
            }
            _this.loading = true;
            _this.loadDetails(selectedOrgLevel, dateOn)
                .then(function (d) {
                _this.details = d;
                if (_this.details) {
                    _this.previousGroups = _.cloneDeep(_this.details.groups);
                }
                _this.currentOrgLevel = selectedOrgLevel;
                _this.getShiftState(d);
                _this.applyFilters();
                _this.categoriesDetails();
                _this.restoreGroupsStates(_this.previousGroups); //always after filters!
                _this.isLoading = false;
            })
                .catch(function (error) { return console.log(error); })
                .then(function () { return (_this.state.isLoading = false, _this.loading = false,
                _this.isLoading = false); });
            _this.loadDetails(selectedOrgLevel, moment(dateOn).subtract(1, 'day').toDate())
                .then(function (d) {
                _this.previousDayDetails = _.cloneDeep(d.groups);
            }).catch(function (error) { return console.log(error); });
            _this.loadDetails(selectedOrgLevel, moment(dateOn).add(1, 'day').toDate())
                .then(function (d) {
                _this.nextDayDetails = _.cloneDeep(d.groups);
            }).catch(function (error) { return console.log(error); });
            _this.loadFilterLookups(selectedOrgLevel.id);
        });
        this.isLoading = false;
        this.notDepartmentSubscription = this.orgLevel$
            .filter(function (o) { return o.type !== OrgLevelType.department; })
            .subscribe(function () {
            _this.soNavService.navigateToSoConsole();
        });
        this.loadDetailsEvent.next();
        this.routeSubscripion = this.shiftRequestService.redirectPositionMappingEvent().subscribe(function () {
            _this.positionMappingNavigationService.navigateToPositionMapping(_this.currentOrgLevel.id);
        });
        this.shiftRequestService.getopenShiftWindowStatus().subscribe(function (e) {
            _this.loadDetailsEvent.next();
        });
        this.getSettings();
    };
    DailyUnitGridComponent.prototype.getShiftState = function (details) {
        var _this = this;
        this.pendingShiftValue = [];
        details.groups.forEach(function (group) {
            group.rows.forEach(function (element) {
                element.fields.forEach(function (el) {
                    if (el.name == 'PartnerId' && el.value != '') {
                        var isAssigned = element.fields.filter(function (e) { return e.name == 'EmpName' && e.value == ''; });
                        if (isAssigned.length > 0) {
                            _this.getValues(element.fields);
                        }
                    }
                });
            });
        });
        this.shiftRequestService.pendingShiftDetails = this.pendingShiftValue;
        this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe(function (value) {
            scheduleMicrotask(function () { _this.state.isLeftSidebarOpen = value; });
        });
    };
    DailyUnitGridComponent.prototype.getValues = function (element) {
        var pendingShift = new PendingShiftValue;
        var position, shift, unit;
        element.forEach(function (el) {
            if (el.name == "JobCode") {
                position = el.value;
            }
            else if (el.name == "ShiftName") {
                shift = el.value;
            }
            else if (el.name == "UnitName") {
                unit = el.value ? el.value.trim() : el.value;
            }
        });
        pendingShift.key = position + '_' + shift + '_' + unit;
        pendingShift.value = 1;
        var isExist = false;
        this.pendingShiftValue.forEach(function (element) {
            if (element.key == pendingShift.key) {
                element.value += 1;
                isExist = true;
            }
        });
        if (!isExist) {
            this.pendingShiftValue.push(pendingShift);
        }
    };
    DailyUnitGridComponent.prototype.getPartnerAgenciesPositions = function (selectedOrgLevel) {
        var _this = this;
        this.isLoading = true;
        this.agenciesApiService.getAgencies(selectedOrgLevel.id).then(function (mappings) {
            _this.agencyMap = mappings.reduce(function (map, obj) {
                map[obj.agency.id] = obj.agency.name;
                return map;
            }, {});
            _this.agencyDetails(selectedOrgLevel).then(function (res) {
                _this.IsEnabled = false;
                _this.agencyListData = [];
                if (res == null || res.length == 0) {
                    return;
                }
                // filter and for active agencies, then call async calls positionmap for
                var filteredAgencies = res.filter(function (o) { return o.status == 'active'; });
                if (filteredAgencies == null || filteredAgencies.length == 0) {
                    return;
                }
                _this.getPositions(filteredAgencies);
                _this.IsEnabled = true;
                _this.requestShiftIsEnabled = true;
                var currentDate = moment().format(appConfig.dateFormat);
                var seletedDate = moment(_this.dateOn).format(appConfig.dateFormat);
                if (!moment(currentDate).isAfter(seletedDate)) {
                    _this.requestShiftIsEnabled = false;
                }
                _this.cancelTypeListData = [];
                res.forEach(function (e) {
                    if (e.cancelType != null) {
                        var cancelTypeObj = new CancelTypeList();
                        cancelTypeObj.partnerId = e.partner_id;
                        cancelTypeObj.types = e.cancelType;
                        _this.cancelTypeListData.push(cancelTypeObj);
                    }
                });
                _this.isLoading = false;
            });
        });
    };
    DailyUnitGridComponent.prototype.getPositions = function (filteredAgencies) {
        var _this = this;
        this.agencyListData = [];
        filteredAgencies.forEach(function (element) {
            _this.agencyData = element;
            _this.agencyData.agency_name = _this.agencyMap[_this.agencyData.agency_id];
            _this.agencyListData.push(_this.agencyData);
            var checkPositions = _this.shiftRequestService.getPartnerPositionMapData().get(_this.agencyData.partner_id);
            if (checkPositions == null) {
                _this.getPartnerPositions(_this.alias, _this.agencyData.partner_id);
            }
        });
        this.shiftRequestService.setAgencies(this.agencyListData);
    };
    DailyUnitGridComponent.prototype.getPartnerPositions = function (customer, partnerId) {
        var _this = this;
        this.management.getAgencyPostions(customer, partnerId).then(function (response) {
            _this.partnerPositions = response;
            _this.shiftRequestService.setPartnerPositionMapData(partnerId, _this.partnerPositions);
        });
    };
    DailyUnitGridComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    DailyUnitGridComponent.prototype.setViewMode = function (routeMode) {
        if (routeMode && routeMode in DetailGroupViewSettingsTypes) {
            this.currentViewMode = routeMode;
        }
        else {
            this.restoreViewMode();
        }
    };
    DailyUnitGridComponent.prototype.loadFilterLookups = function (selectedOrgLevelId) {
        var _this = this;
        var positionGroupsPromise = this.lookupService.getLookup({
            lookupType: LookupType.positionGroups,
            orgLevelId: selectedOrgLevelId,
        });
        var positionsPromise = this.lookupService.getLookup({
            lookupType: LookupType.position,
            orgLevelId: selectedOrgLevelId,
        });
        var unitsPromise = this.lookupService.getLookup({
            lookupType: LookupType.locationUnit,
            orgLevelId: selectedOrgLevelId,
        });
        var shiftsPromise = this.lookupService.getLookup({
            lookupType: LookupType.shift,
            orgLevelId: selectedOrgLevelId,
        });
        Promise.all([positionGroupsPromise, positionsPromise, unitsPromise, shiftsPromise]).then(function (values) {
            var duaOptions = new DailyUnitFilterOptions();
            _this.positionGroupLookup = values[0], _this.positionLookup = values[1], _this.unitsLookup = values[2], _this.shiftsLookup = values[3];
            duaOptions.positionGroupLookup = _this.positionGroupLookup;
            duaOptions.positionLookup = _this.positionLookup;
            duaOptions.unitsLookup = _this.unitsLookup;
            duaOptions.shiftsLookup = _this.shiftsLookup;
            _this.management.duaOptions = duaOptions;
            _this.restoreFilters();
        });
    };
    Object.defineProperty(DailyUnitGridComponent.prototype, "dateFormat", {
        get: function () {
            return screenUtils.isMobile ? 'MM/dd/yy' : 'MMMM dd, yyyy';
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitGridComponent.prototype.loadDetails = function (orgLevel, dateOn) {
        return this.detailScreenService.getDailyUnits(orgLevel, dateOn);
    };
    DailyUnitGridComponent.prototype.shiftOpen = function (row) {
        if (row.EmpId !== "" && row.EmpId === "0") {
            return;
        }
        this.navigateService.navigateToMasterScheduleOpenShifts(this.dateOn, row.EmpId);
    };
    DailyUnitGridComponent.prototype.onFillShift = function (row) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shiftIdField, shiftNameField, positionIdField, positionNameField, unitIdField, unitNameField, orgLevel, request, GroupData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shiftIdField = row.ShiftId;
                        shiftNameField = row.ShiftName;
                        positionIdField = row.JobCode;
                        positionNameField = row.JobDescription;
                        unitIdField = row.UnitId;
                        unitNameField = row.UnitName;
                        if (!shiftIdField || !positionIdField || !unitIdField) {
                            return [2 /*return*/];
                        }
                        if (!(this.orgLevel.parentId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.orgLevelService.getOrgLevelByIdSafe(this.orgLevel.parentId)];
                    case 1:
                        orgLevel = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        orgLevel = this.orgLevel;
                        _a.label = 3;
                    case 3:
                        request = new ShiftReplacementRequest();
                        request.shiftId = +shiftIdField;
                        request.shiftName = shiftNameField;
                        request.positionId = +positionIdField;
                        request.positionName = positionNameField;
                        request.organizationName = orgLevel.name;
                        request.unitId = +unitIdField;
                        request.unitName = unitNameField;
                        request.date = this.dateOn;
                        request.showDayOffEmployees = true;
                        request.showSendSmsButton = true;
                        request.shiftStart = new Date(new Date().toISOString().slice(0, 10) + " " + row.ShiftStart);
                        request.shiftEnd = new Date(new Date().toISOString().slice(0, 10) + " " + row.ShiftEnd);
                        request.momentShiftStart = moment(row.ShiftStart);
                        request.momentShiftEnd = moment(row.ShiftEnd);
                        request.momentActualShiftStart = moment(row.actualShiftStart);
                        request.momentActualShiftEnd = moment(row.actualShiftEnd);
                        GroupData = {
                            request: request,
                            groups: this.previousGroups,
                            previousDayGroups: this.previousDayDetails,
                            nextDayGroups: this.nextDayDetails
                        };
                        EmployeeAddShiftOpenComponent.openDialog(GroupData, +unitIdField, this.modalService, function (result, cmd) {
                            if (result && cmd) {
                                _this.addShiftEmployee = cmd;
                                _this.state.isLoading = true;
                                _this.openDialogForPayPeriod(row, cmd.selectedEmployee.employee.id, 'add');
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DailyUnitGridComponent.prototype.onOpenIndividual = function (row) {
        var _this = this;
        var employeeId = row.EmpId;
        var departamentId = row.DepartmentId;
        var posOrgLevel = this.orgLevelService.getOrgLevelByRelatedItemId(+departamentId, OrgLevelType.department);
        if (!posOrgLevel)
            return;
        var orgLevelId = posOrgLevel.id;
        this.state.isLoading = true;
        this.scheduleCycleHelperService
            .getScheduleCycleByDate(this.dateOn, orgLevelId)
            .then(function (_a) {
            var startDate = _a.startDate, endDate = _a.endDate;
            _this.state.isLoading = false;
            _this.individualNavService.NavigateToIndividualScheduleEmp(+employeeId, startDate.toDate(), endDate.toDate(), _this.dateOn);
        });
    };
    DailyUnitGridComponent.prototype.onReplaceEmployee = function (row) {
        var _this = this;
        var employeeName = row.EmpName;
        var employeeId = row.EmpId;
        var shiftIdField = row.ShiftId;
        var shiftStartField = row.ShiftStart;
        var shiftEndField = row.ShiftEnd;
        var shiftNameField = row.ShiftName;
        var positionIdField = row.JobCode;
        var unitIdField = row.UnitId;
        if (!shiftIdField || !positionIdField || !unitIdField) {
            return;
        }
        if (this.isModifyPayPeriodApproved) {
            if (!this.details.canEditScheduledApprovePayPeriod && this.details.canEditScheduledApprovePayPeriod != undefined && this.details) {
                this.openDialogForPayPeriod(row, employeeId, 'replace');
                return;
            }
            this.shiftReplacementService.replaceEmployee.dateOn = this.dateOn;
            this.shiftReplacementService.replaceEmployee.shiftId = parseInt(row.ShiftId);
            this.shiftReplacementService.isModifyApprovedPayperiod$.next(this.isModifyPayPeriodApproved);
            this.checkReplaceEmployeeApprovedStatus(row, row.EmpId);
        }
        var request = new ShiftReplacementRequest();
        request.replacedEmployeeName = employeeName;
        request.replacedEmployeeId = +employeeId;
        request.shiftId = +shiftIdField;
        request.shiftName = shiftNameField;
        request.positionId = +positionIdField;
        request.date = this.dateOn;
        request.shiftStart = moment(shiftStartField).toDate();
        request.shiftEnd = moment(shiftEndField).toDate();
        request.showDayOffEmployees = true;
        request.orgLevelId = this.currentOrgLevel.id;
        request.momentShiftStart = moment(row.ShiftStart);
        request.momentShiftEnd = moment(row.ShiftEnd);
        request.momentActualShiftStart = moment(row.actualShiftStart);
        request.momentActualShiftEnd = moment(row.actualShiftEnd);
        request.currentDayDUAData = this.previousGroups;
        request.previousDayDUAData = this.previousDayDetails;
        request.nextDayDUAData = this.nextDayDetails;
        ShiftReplacementReplaceComponent.openDialog(request, this.modalService, function (result, cmd) {
            if (result && cmd) {
                var isApprovedLength = _this.shiftReplacementService.replaceApprovedResult.filter(function (x) { return x == true; });
                if (_this.isModifyPayPeriodApproved && isApprovedLength.length > 0) {
                    _this.shiftReplacementService.replaceEmployee = cmd;
                    _this.checkReplaceEmployeeApprovedStatus(row, _this.shiftReplacementService.replaceEmployee.replacedEmployeeId);
                }
                else {
                    _this.shiftReplacementService.replaceApprovedResult = [];
                    _this.state.isLoading = true;
                    _this.shiftReplacementApiService
                        .replaceEmployee(cmd)
                        .catch(function (error) {
                        _this.state.isLoading = false;
                    })
                        .then(function (status) {
                        _this.state.isLoading = false;
                        _this.loadDetailsEvent.next();
                    });
                }
            }
        });
    };
    DailyUnitGridComponent.prototype.hideTable = function (groupTable) {
        $(groupTable).toggle();
    };
    DailyUnitGridComponent.prototype.changeDateOn = function (dateOn, flag) {
        this.isLoading = true;
        if (flag) {
            this.clearFilters();
        }
        this.navigateService.navigateToDailyUnitAssignmentDate(dateOn, this.pathPositionIds, this.currentViewMode, false, this.unitIds, this.shiftIds);
        this.getPositions(this.agencyListData);
        this.isLoading = false;
    };
    DailyUnitGridComponent.prototype.onFilterChange = function (filterName, filter) {
        this.state.isSelectPropagated = false;
        var ids = _.map(filter, function (m) { return m.id; });
        if (filterName === 'unit') {
            this.selectedUnitIds = ids;
        }
        else if (filterName === LookupType.position) {
            this.selectedPositionIds = ids;
        }
        else if (filterName === LookupType.shift) {
            this.selectedShiftIds = ids;
        }
        else if (filterName === LookupType.positionGroups) {
            this.selectedPositionGroupIds = ids;
        }
    };
    DailyUnitGridComponent.prototype.calcAppliedFilters = function () {
        this.filtersApplied =
            Boolean(this.selectedUnitIds && this.selectedUnitIds.length) ||
                Boolean(this.selectedPositionIds && this.selectedPositionIds.length) ||
                Boolean(this.selectedShiftIds && this.selectedShiftIds.length) ||
                Boolean(this.selectedPositionGroupIds && this.selectedPositionGroupIds.length);
    };
    DailyUnitGridComponent.prototype.filterRows = function (rows) {
        var filtered = this.filterRowsBy(rows, 'UnitId', this.selectedUnitIds);
        filtered = this.filterRowsBy(filtered, 'ShiftId', this.selectedShiftIds);
        filtered = this.filterRowsBy(filtered, 'JobCode', this.selectedPositionIds);
        filtered = this.filterRowsBy(filtered, 'PositionGroupId', this.selectedPositionGroupIds);
        return filtered;
    };
    DailyUnitGridComponent.prototype.filterRowsBy = function (rows, fieldName, filteredItemIds) {
        if (!filteredItemIds || filteredItemIds.length === 0)
            return rows;
        var filtered = _.filter(rows, function (row) {
            var field = _.find(row.fields, function (f) { return f.name === fieldName; });
            var id = _.find(filteredItemIds, function (itemId) { return +itemId === +field.value; });
            return !!id;
        });
        return filtered;
    };
    DailyUnitGridComponent.prototype.stopPropagation = function ($event) {
        if (this.state.isSelectPropagated) {
            $event.stopPropagation();
        }
        else {
            this.state.isSelectPropagated = true;
        }
    };
    DailyUnitGridComponent.prototype.employeeDelete = function (row) {
        var shiftIdField = row.ShiftId;
        var employeeId = row.EmpId;
        this.openDialogForPayPeriod(row, employeeId, 'delete');
    };
    DailyUnitGridComponent.prototype.employeeCancel = function (details) {
        var _this = this;
        this.isLoading = true;
        var shiftIdField = details.row.ShiftId;
        var employeeId = details.row.EmpId;
        var requestId = details.row.SlxpartnerdataId;
        var partnerId = details.row.PartnerId;
        var cancelType = details.option;
        this.detailScreenService
            .cancelDailyUnitEmployee(this.dateOn, +shiftIdField, +employeeId, partnerId, +requestId, cancelType).then(function () {
            _this.isLoading = false;
            _this.loadDetailsEvent.next();
        });
    };
    DailyUnitGridComponent.prototype.onSwitchEmployeeShift = function (row) {
        this.rowForShiftChange = row;
        this.openDialogForPayPeriod(row, row.EmpId, 'switch');
    };
    DailyUnitGridComponent.prototype.discardShiftSwitch = function () {
        this.rowForShiftChange = null;
        this.state.switchShiftMode = false;
    };
    DailyUnitGridComponent.prototype.performShiftSwitch = function (item) {
        var field;
        var empId;
        empId = parseInt(this.rowForShiftChange.EmpId);
        this.openDialogForPayPeriod(item, empId, 'switchShift');
    };
    Object.defineProperty(DailyUnitGridComponent.prototype, "censusCount", {
        get: function () {
            return this.details ? this.details.censusCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitGridComponent.prototype.onCensusEdit = function () {
        this.navigateService.navigateToCensusEditor(this.dateOn);
    };
    DailyUnitGridComponent.prototype.savePreviousFilters = function () {
        this.prePositionGroupFilter = this.positionGroupFilter ? this.positionGroupFilter.concat([]) : [];
        this.prevPosFilter = this.posFilter ? this.posFilter.concat([]) : [];
        this.prevUnitFilter = this.unitFilter ? this.unitFilter.concat([]) : [];
        this.prevShiftFilter = this.shiftFilter ? this.shiftFilter.concat([]) : [];
    };
    DailyUnitGridComponent.prototype.applyFilters = function () {
        var _this = this;
        this.state.isLoading = true;
        if (this.details) {
            this.previousGroups = _.cloneDeep(this.details.groups);
        }
        _.each(this.previousGroups, function (group) {
            group.rows = _this.filterRows(group.rows);
            group.recalculateFilteredValues();
        });
        this.calcAppliedFilters();
        this.state.isLoading = false;
    };
    DailyUnitGridComponent.prototype.clickEventForOpenTile = function (toggleFlag) {
        var _this = this;
        if (this.openTotalShifts > 0) {
            this.openShiftToggle = (toggleFlag === false);
            _.each(this.previousGroups, function (group) {
                group.isEmptySlotFilterSelected = _this.openShiftToggle;
                group.recalculateFilteredValues();
            });
        }
    };
    DailyUnitGridComponent.prototype.clickEventForPendingTile = function (toggleFlag) {
        var _this = this;
        if (this.agencyTotalShifts > 0) {
            this.pendingShiftToggle = (toggleFlag === false);
            _.each(this.previousGroups, function (group) {
                group.isPendingSlotFilterSelected = _this.pendingShiftToggle;
                group.recalculateFilteredValues();
            });
        }
    };
    DailyUnitGridComponent.prototype.clickEventForSwitchTile = function (toggleFlag) {
        var _this = this;
        if (this.switchTotalShift > 0) {
            this.switchShiftToggle = (toggleFlag === false);
            _.each(this.previousGroups, function (group) {
                group.isOutOfParFilterSelected = _this.switchShiftToggle;
                group.recalculateFilteredValues();
            });
        }
    };
    DailyUnitGridComponent.prototype.clickEventForOverTimeTile = function (toggleFlag) {
        var _this = this;
        if (this.overTimeTotalShifts > 0) {
            this.overTimeShiftToggle = (toggleFlag === false);
            _.each(this.previousGroups, function (group) {
                group.isOvertimeFilterSelected = _this.overTimeShiftToggle;
                group.recalculateFilteredValues();
            });
        }
    };
    DailyUnitGridComponent.prototype.cancelFilters = function () {
        this.positionGroupFilter = this.prePositionGroupFilter;
        this.posFilter = this.prevPosFilter;
        this.unitFilter = this.prevUnitFilter;
        this.shiftFilter = this.prevShiftFilter;
    };
    DailyUnitGridComponent.prototype.clearFilters = function () {
        this.isActionsOpened = false;
        this.buttonNum = 0;
        this.pathPositionGroupIds = [];
        this.pathPositionIds = [];
        this.unitIds = [];
        this.shiftIds = [];
        this.positionGroupFilter = [];
        this.posFilter = [];
        this.unitFilter = [];
        this.shiftFilter = [];
        this.prePositionGroupFilter = [];
        this.prevPosFilter = [];
        this.prevUnitFilter = [];
        this.prevShiftFilter = [];
        this.selectedPositionGroupIds = [];
        this.selectedPositionIds = [];
        this.selectedShiftIds = [];
        this.selectedUnitIds = [];
        this.saveFilters();
        this.savePreviousFilters();
        this.applyFilters();
        this.restoreGroupsStates(this.previousGroups);
        this.restoreFilters();
        this.openShiftToggle = false;
        this.pendingShiftToggle = false;
        this.switchShiftToggle = false;
        this.overTimeShiftToggle = false;
    };
    DailyUnitGridComponent.prototype.viewSettingsChanged = function (event) {
        this.currentViewMode = event;
        this.saveViewMode();
        this.categoriesDetails();
    };
    DailyUnitGridComponent.prototype.saveFilters = function () {
        var filters = {
            positionGroup: this.positionGroupFilter,
            positions: this.posFilter,
            units: this.unitFilter,
            shifts: this.shiftFilter,
        };
        this.storageService.setControlState(this.stateManagement.componentKey, FILTERS, { value: filters }, StateResetTypes.SessionEnd |
            StateResetTypes.OrgLevelChange |
            StateResetTypes.MenuChange |
            StateResetTypes.ParameterInRoute);
        if (this.selectedPositionGroupIds && this.selectedPositionGroupIds.length) {
            this.pathPositionGroupIds = this.selectedPositionGroupIds.concat([]);
        }
        else {
            this.pathPositionGroupIds = [];
        }
        if (this.selectedPositionIds && this.selectedPositionIds.length) {
            this.pathPositionIds = this.selectedPositionIds.concat([]);
        }
        else {
            this.pathPositionIds = [];
        }
        if (this.selectedUnitIds && this.selectedUnitIds.length) {
            this.unitIds = this.selectedUnitIds.concat([]);
        }
        else {
            this.unitIds = [];
        }
        if (this.selectedShiftIds && this.selectedShiftIds.length) {
            this.shiftIds = this.selectedShiftIds.concat([]);
        }
        else {
            this.shiftIds = [];
        }
        this.changeDateOn(this.dateOn, false);
    };
    DailyUnitGridComponent.prototype.groupContainerCreated = function (item) {
        this.groupContainer = item;
        this.scrollGroupContainer();
    };
    DailyUnitGridComponent.prototype.scrollTo = function (value) {
        this.scrollToValue = value;
        this.scrollGroupContainer();
    };
    Object.defineProperty(DailyUnitGridComponent.prototype, "totalEmptySlotCount", {
        get: function () {
            if (!this.previousGroups)
                return 0;
            var mapped = _.map(this.previousGroups, 'emptySlotCount');
            return _.sum(mapped);
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitGridComponent.prototype.scrollGroupContainer = function () {
        if (this.groupContainer && this.scrollToValue > 0) {
            this.groupContainer.scrollTop = this.scrollToValue - this.groupContainer.offsetTop;
            this.scrollToValue = 0;
            this.groupContainer = null;
        }
    };
    DailyUnitGridComponent.prototype.saveGroupState = function (group, prop, propValue) {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, group.name);
        if (!state || !state.value) {
            state = { value: {} };
        }
        state.value[prop] = propValue;
        this.storageService.setControlState(this.stateManagement.componentKey, group.name, state, StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange);
    };
    DailyUnitGridComponent.prototype.saveViewMode = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, VIEW_MODE_KEY, { value: this.currentViewMode }, StateResetTypes.None);
    };
    DailyUnitGridComponent.prototype.saveDate = function (date) {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, DATE);
        if (!state || !state.value) {
            state = { value: {} };
        }
        state.value = date;
        this.storageService.setControlState(this.stateManagement.componentKey, DATE, state, StateResetTypes.SessionEnd | StateResetTypes.MenuChange);
    };
    DailyUnitGridComponent.prototype.getSavedDate = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, DATE);
        if (state.value)
            return state.value;
        return new Date();
    };
    DailyUnitGridComponent.prototype.restoreViewMode = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, VIEW_MODE_KEY);
        if (state.value && state.value in DetailGroupViewSettingsTypes) {
            this.currentViewMode = state.value;
        }
        else {
            this.currentViewMode = DetailGroupViewSettingsTypes.hours;
        }
    };
    DailyUnitGridComponent.prototype.restoreFilters = function () {
        var _this = this;
        var positionGroupToSelect = [];
        if (this.pathPositionGroupIds && this.pathPositionGroupIds.length) {
            if (this.positionGroupLookup && this.positionGroupLookup.items) {
                _.forEach(this.pathPositionGroupIds, function (id) {
                    var pos = _.find(_this.positionGroupLookup.items, function (item) {
                        return item.id === id;
                    });
                    if (pos)
                        positionGroupToSelect.push(pos);
                });
                if (positionGroupToSelect && positionGroupToSelect.length) {
                    this.positionGroupFilter = positionGroupToSelect;
                    this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
                }
            }
        }
        var positionToSelect = [];
        if (this.pathPositionIds && this.pathPositionIds.length) {
            if (this.positionLookup && this.positionLookup.items) {
                _.forEach(this.pathPositionIds, function (id) {
                    var pos = _.find(_this.positionLookup.items, function (item) {
                        return item.id === id;
                    });
                    if (pos)
                        positionToSelect.push(pos);
                });
                if (positionToSelect && positionToSelect.length) {
                    this.posFilter = positionToSelect;
                    this.onFilterChange(LookupType.position, this.posFilter);
                }
            }
        }
        var unitToSelect = [];
        if (this.unitIds && this.unitIds.length) {
            if (this.unitsLookup && this.unitsLookup.items) {
                _.forEach(this.unitIds, function (id) {
                    var unit = _.find(_this.unitsLookup.items, function (item) {
                        return item.id === id;
                    });
                    if (unit)
                        unitToSelect.push(unit);
                });
                if (unitToSelect && unitToSelect.length) {
                    this.unitFilter = unitToSelect;
                    this.onFilterChange('unit', this.unitFilter);
                }
            }
        }
        var shiftsToSelect = [];
        if (this.shiftIds && this.shiftIds.length) {
            if (this.shiftsLookup && this.shiftsLookup.items) {
                _.forEach(this.shiftIds, function (id) {
                    var shift = _.find(_this.shiftsLookup.items, function (item) {
                        return item.id === id;
                    });
                    if (shift)
                        shiftsToSelect.push(shift);
                });
                if (shiftsToSelect && shiftsToSelect.length) {
                    this.shiftFilter = shiftsToSelect;
                    this.onFilterChange(LookupType.shift, this.shiftFilter);
                }
            }
        }
        var state = this.storageService.getControlState(this.stateManagement.componentKey, FILTERS);
        var filters = state.value;
        if (filters) {
            if (!positionGroupToSelect || !positionGroupToSelect.length) {
                this.positionGroupFilter = filters.positionGroup;
            }
            if (!positionToSelect || !positionToSelect.length) {
                this.posFilter = filters.positions;
            }
            if (!unitToSelect || !unitToSelect.length) {
                this.unitFilter = filters.units;
            }
            if (!shiftsToSelect || !shiftsToSelect.length) {
                this.shiftFilter = filters.shifts;
            }
            this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
            this.onFilterChange(LookupType.position, this.posFilter);
            this.onFilterChange(LookupType.shift, this.shiftFilter);
            this.onFilterChange('unit', this.unitFilter);
        }
        else {
            if (!positionGroupToSelect || !positionGroupToSelect.length) {
                this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
            }
            if (positionToSelect && positionToSelect.length) {
                this.onFilterChange(LookupType.position, this.posFilter);
            }
            if (unitToSelect || unitToSelect.length) {
                this.onFilterChange('unit', this.unitFilter);
            }
            if (shiftsToSelect || shiftsToSelect.length) {
                this.onFilterChange(LookupType.shift, this.shiftFilter);
            }
        }
        this.applyFilters();
    };
    DailyUnitGridComponent.prototype.restoreGroupsStates = function (groups) {
        var _this = this;
        _.forEach(groups, function (group) {
            var state = _this.storageService.getControlState(_this.stateManagement.componentKey, group.name);
            if (state && state.value) {
                var val = state.value;
                group.isEmptySlotFilterSelected = val.isEmptySlotFilterSelected && group.emptySlotCount > 0;
                group.isOutOfParFilterSelected = val.isOutOfParFilterSelected && group.outOfParCount > 0;
                group.isMessageFilterSelected = val.isMessageFilterSelected && group.messageCount > 0;
                group.isOvertimeFilterSelected = val.isOvertimeFilterSelected && group.overtimeCount > 0;
                group.isPendingSlotFilterSelected = val.isPendingSlotFilterSelected && group.pendingShiftCount > 0;
                group.recalculateFilteredValues();
            }
        });
    };
    DailyUnitGridComponent.prototype.AddOpenShifts = function () {
        if (this.isModifyPayPeriodApproved) {
            this.approvedPayperiodForOrgLevel();
        }
        else
            this.router.navigateByUrl(this.getOpenShiftUrlTree());
    };
    DailyUnitGridComponent.prototype.getOpenShiftUrlTree = function () {
        var params = {
            keepOrgLevelBreadcrmb: true,
            isAgencyEnabled: this.IsEnabled,
            dateOn: moment(this.dateOn).format(appConfig.linkDateFormat),
        };
        return this.router.createUrlTree(['daily_unit_assignment', 'add_open_shifts'], {
            relativeTo: this.activatedRoute.pathFromRoot[2],
            queryParams: params
        });
    };
    DailyUnitGridComponent.prototype.requestAgencyStaff = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var agencyName, requestdata, OpenShiftData, requestAgencygridData, partnerPositionMapData, currentAgency, _i, _a, agency, currentPositionsData, checkMinimalPositionMapped, _loop_1, _b, _c, position, state_1, orgLevel;
            return tslib_1.__generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.isLoading = true;
                        this.shiftRequestService.isRequestFromDua = true;
                        this.requestShift = true;
                        requestdata = [];
                        OpenShiftData = [];
                        this.previousGroups.forEach(function (el) {
                            requestdata.push(el.rows);
                        });
                        if (requestdata.length > 0) {
                            requestdata.forEach(function (e) {
                                e.forEach(function (child) {
                                    OpenShiftData.push(child);
                                });
                            });
                        }
                        this.shiftRequestService.setGridData(OpenShiftData);
                        requestAgencygridData = this.shiftRequestService.filterOpenShiftsData(this.shiftRequestService.getGridData());
                        partnerPositionMapData = this.shiftRequestService.getPartnerPositionMapData();
                        _i = 0, _a = this.agencyListData;
                        _d.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        agency = _a[_i];
                        currentPositionsData = partnerPositionMapData.get(agency.partner_id).positions;
                        this.isPositionMapped = true;
                        // Partner position each agency 
                        this.positionTabs = [];
                        return [4 /*yield*/, this.getPartnerPositionTab(agency.partner_id)];
                    case 2:
                        _d.sent();
                        if (currentPositionsData.length > 0) {
                            checkMinimalPositionMapped = false;
                            _loop_1 = function (position) {
                                var currentPosition = currentPositionsData.find(function (e) { return e.partner_position_name == position && e.position_name != null && e.position_name != ''; });
                                if (currentPosition != null) {
                                    checkMinimalPositionMapped = true;
                                    return "break";
                                }
                            };
                            for (_b = 0, _c = this.positionTabs; _b < _c.length; _b++) {
                                position = _c[_b];
                                state_1 = _loop_1(position);
                                if (state_1 === "break")
                                    break;
                            }
                            if (!checkMinimalPositionMapped) {
                                this.isPositionMapped = false;
                                return [3 /*break*/, 4];
                            }
                        }
                        else {
                            this.isPositionMapped = false;
                        }
                        if (!this.isPositionMapped) {
                            currentAgency = agency;
                            return [3 /*break*/, 4];
                        }
                        _d.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        ;
                        if (this.isPositionMapped) {
                            orgLevel = void 0;
                            orgLevel = this.orgLevel;
                            this.shiftRequestService.setGridData(OpenShiftData);
                            this.NavigateToRequestAgencyStaff();
                        }
                        else {
                            this.positionNotMappedDialogue(currentAgency.agency_name);
                        }
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DailyUnitGridComponent.prototype.getPartnerPositionTab = function (partnerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var positionDefination;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.positionTabs = [];
                        positionDefination = [];
                        return [4 /*yield*/, this.partnerConfigManagementService.getPartnerPositionsDefinitions(partnerId).then(function (response) {
                                _this.isLoading = true;
                                response.forEach(function (element) {
                                    positionDefination.push(element.partnerPositionName);
                                });
                                _this.positionTabs = positionDefination;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DailyUnitGridComponent.prototype.positionNotMappedDialogue = function (agencyName) {
        var _this = this;
        var options = new ConfirmOptions3();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = "Go to Position Mapping";
        options.buttonCanceltext = 'Cancel';
        options.note = "There are missing Position Mappings against (" + agencyName + ") that must be fixed before you can proceed.";
        ConfirmDialog3Component.openDialog('Position Mapping ', '', this.modalService, function (result) {
            if (result) {
                _this.isLoading = true;
                _this.shiftRequestService.positionsClickEvent();
                _this.isLoading = false;
            }
        }, options);
        this.isLoading = false;
    };
    DailyUnitGridComponent.prototype.NavigateToRequestAgencyStaff = function () {
        this.router.navigateByUrl(this.getUrlTree());
    };
    DailyUnitGridComponent.prototype.getUrlTree = function () {
        var params = {
            keepOrgLevelBreadcrmb: true,
            dateOn: moment(this.dateOn).format(appConfig.linkDateFormat)
        };
        return this.router.createUrlTree(['daily_unit_assignment', 'request_agency_staff', moment(this.dateOn).format(appConfig.linkDateFormat)], {
            relativeTo: this.activatedRoute.pathFromRoot[2],
            queryParams: params
        });
    };
    DailyUnitGridComponent.prototype.agencyDetails = function (orgLevel) {
        return this.detailScreenService.getAgencyDetails(orgLevel, this.alias);
    };
    DailyUnitGridComponent.prototype.categoriesDetails = function () {
        var _this = this;
        this.schedTotal = 0;
        this.schedIdealTotal = 0;
        this.dayTotal = 0;
        this.dayIdealTotal = 0;
        this.eveningTotal = 0;
        this.eveningIdealTotal = 0;
        this.nightTotal = 0;
        this.nightIdealTotal = 0;
        this.agencyTotalShifts = 0;
        this.agencyNightShifts = 0;
        this.agencyEveningShifts = 0;
        this.agencyDayShifts = 0;
        this.openTotalShifts = 0;
        this.openDayShifts = 0;
        this.openEveningShifts = 0;
        this.openNightShifts = 0;
        this.overTimeTotalShifts = 0;
        this.overTimeDayShifts = 0;
        this.overTimeEveningShifts = 0;
        this.switchTotalShift = 0;
        this.switchDayShift = 0;
        this.switchEveningShift = 0;
        this.switchNightShift = 0;
        this.details && this.details.groups && this.details.groups.forEach(function (groupsData) {
            groupsData.recalculateFilteredValues();
            if (groupsData.shiftGroupName === 'Day') {
                if (_this.currentViewMode === 'hours') {
                    _this.dayTotal += groupsData.filteredScheduledHours;
                    _this.dayIdealTotal += groupsData.filteredTotalIdealHours;
                }
                else if (_this.currentViewMode === 'slots') {
                    _this.dayTotal += groupsData.filteredFilledSlots;
                    _this.dayIdealTotal += groupsData.filteredTotalSlots;
                }
                else if (_this.currentViewMode === 'ppd') {
                    _this.dayTotal += groupsData.filteredFilledPpd;
                    _this.dayIdealTotal += groupsData.filteredTotalPpd;
                }
                _this.agencyDayShifts = groupsData.pendingShiftCount;
                _this.openDayShifts = groupsData.emptySlotCount - groupsData.pendingShiftCount;
                _this.overTimeDayShifts = groupsData.overtimeCount;
                _this.switchDayShift = groupsData.filterSwitchShiftCount;
            }
            if (groupsData.shiftGroupName === 'Evening') {
                if (_this.currentViewMode === 'hours') {
                    _this.eveningTotal += groupsData.filteredScheduledHours;
                    _this.eveningIdealTotal += groupsData.filteredTotalIdealHours;
                }
                else if (_this.currentViewMode === 'slots') {
                    _this.eveningTotal += groupsData.filteredFilledSlots;
                    _this.eveningIdealTotal += groupsData.filteredTotalSlots;
                }
                else if (_this.currentViewMode === 'ppd') {
                    _this.eveningTotal += groupsData.filteredFilledPpd;
                    _this.eveningIdealTotal += groupsData.filteredTotalPpd;
                }
                _this.agencyEveningShifts = groupsData.pendingShiftCount;
                _this.openEveningShifts = groupsData.emptySlotCount - groupsData.pendingShiftCount;
                _this.overTimeEveningShifts = groupsData.overtimeCount;
                _this.switchEveningShift = groupsData.filterSwitchShiftCount;
            }
            if (groupsData.shiftGroupName === 'Night') {
                if (_this.currentViewMode === 'hours') {
                    _this.nightTotal += groupsData.filteredScheduledHours;
                    _this.nightIdealTotal += groupsData.filteredTotalIdealHours;
                }
                else if (_this.currentViewMode === 'slots') {
                    _this.nightTotal += groupsData.filteredFilledSlots;
                    _this.nightIdealTotal += groupsData.filteredTotalSlots;
                }
                else if (_this.currentViewMode === 'ppd') {
                    _this.nightTotal += groupsData.filteredFilledPpd;
                    _this.nightIdealTotal += groupsData.filteredTotalPpd;
                }
                _this.agencyNightShifts = groupsData.pendingShiftCount;
                _this.openNightShifts = groupsData.emptySlotCount - groupsData.pendingShiftCount;
                _this.overTimeNightShifts = groupsData.overtimeCount;
                _this.switchNightShift = groupsData.filterSwitchShiftCount;
            }
        });
        this.overTimeTotalShifts = this.overTimeDayShifts + this.overTimeEveningShifts + this.overTimeNightShifts;
        this.openTotalShifts = this.openDayShifts + this.openEveningShifts + this.openNightShifts;
        this.schedTotal = this.dayTotal + this.eveningTotal + this.nightTotal;
        this.schedIdealTotal = this.dayIdealTotal + this.eveningIdealTotal + this.nightIdealTotal;
        this.agencyTotalShifts = this.agencyDayShifts + this.agencyEveningShifts + this.agencyNightShifts;
        this.switchTotalShift = ((this.openTotalShifts - this.agencyTotalShifts) > 0) ? (this.switchDayShift + this.switchEveningShift + this.switchNightShift) : 0;
    };
    DailyUnitGridComponent.prototype.isVisibleSection = function (sectionType) {
        return sectionType === ToolbarSectionTypes.VISIBLE;
    };
    DailyUnitGridComponent.prototype.isAllSectionShown = function (sections) {
        return sections[ToolbarSectionTypes.COLLAPSED_BY_RESIZE] === 0;
    };
    DailyUnitGridComponent.prototype.captureScreen = function () {
        var data = document.getElementById('contentToConvert');
        html2canvas(data).then(function (canvas) {
            // Few necessary setting options
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var heightLeft = imgHeight;
            var contentDataURL = canvas.toDataURL('image/png');
            var pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
            var position = 10;
            var _selectedDateVal = document.getElementById('_selectedDateVal').innerText;
            var current = new Date(_selectedDateVal);
            var cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            var cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
            var dateTime = cDate + ' ' + cTime;
            pdf.setFontSize(10);
            pdf.text(dateTime, 7, 7);
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            pdf.save('Daily_Unit_Assignment_' + cDate + '.pdf'); // Generated PDF
        });
    };
    DailyUnitGridComponent.prototype.getUserRoles = function () {
        var _this = this;
        this.isLoading = true;
        var rightGroup, userData, loginRole;
        userData = JSON.parse(localStorage.getItem("slx-worklinx-v6-app"));
        loginRole = userData.session.user.roles[0];
        this.rolesApiService.getRoleAccessTable().then(function (res) {
            rightGroup = res.rightGroupRows;
            var userRole = rightGroup[0].roles.filter(function (e) { return e.name == loginRole; });
            var roleRights = userRole[0].rights;
            var userRight = roleRights.filter(function (e) { return e.name == "Schedule Optimizer"; });
            var userRoleRight = userRight[0].rights;
            var isEditOpenShift = userRoleRight.filter(function (e) { return e.name == "Edit Open Shift Count"; });
            _this.isEditOpenShift = isEditOpenShift[0].isEnabled;
            _this.isLoading = false;
        });
    };
    DailyUnitGridComponent.prototype.addShift = function (addEmployeeCmdObj) {
        var _this = this;
        this.shiftReplacementApiService
            .addToShift(addEmployeeCmdObj)
            .catch(function (error) {
            _this.state.isLoading = false;
        })
            .then(function (status) {
            _this.state.isLoading = false;
            _this.loadDetailsEvent.next();
        });
    };
    DailyUnitGridComponent.prototype.openDialogForPayPeriod = function (row, empId, actionType) {
        var _this = this;
        var shiftName = actionType == 'switchShift' ? row.shift.name : row.ShiftName;
        var shiftEndDate = shiftName.split('-')[0].includes('p') && shiftName.split('-')[1].includes('a') ? moment(this.dateOn).add(1, 'day') : this.dateOn;
        var startDate = this.setDateFormat(this.dateOn);
        var endDate = this.setDateFormat(this.dateOn);
        var empid = empId;
        this.scheduleEntryApiService.checkApprovedPayperiod(empid, startDate, endDate).then(function (data) {
            _this.approvedPayPeriod = data;
            if (_this.approvedPayPeriod && _this.isModifyPayPeriodApproved) {
                var message = _this.details.canEditScheduledApprovePayPeriod ? "This will modify a schedule in an approved pay period and impact the PBJ Calculation for the employee, are you sure you want to continue?" : 'You do not have permissions to modify a schedule in an approved pay period';
                var popupOptions = new ConfirmOptions();
                popupOptions.showCancel = true;
                popupOptions.showOK = _this.details.canEditScheduledApprovePayPeriod ? true : false;
                ;
                popupOptions.buttonOKtext = 'Ok';
                popupOptions.buttonCanceltext = _this.details.canEditScheduledApprovePayPeriod ? 'Cancel' : 'Ok';
                ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                    if (result) {
                        _this.actionsBasedOnPayPeriod(row, empId, actionType);
                    }
                }, popupOptions);
            }
            else {
                _this.actionsBasedOnPayPeriod(row, empId, actionType);
            }
        });
    };
    DailyUnitGridComponent.prototype.actionsBasedOnPayPeriod = function (row, empId, actionType) {
        var _this = this;
        switch (actionType) {
            case 'add':
                this.addShift(this.addShiftEmployee);
                break;
            case 'delete':
                this.detailScreenService.deleteDailyUnitEmployee(this.dateOn, row.ShiftId, row.EmpId).then(function () { return _this.loadDetailsEvent.next(); });
                break;
            case 'switch':
                this.state.switchShiftMode = true;
                break;
            case 'switchShift':
                var originalId = void 0;
                var replaceId = void 0;
                var replaceUnitId = void 0;
                originalId = parseInt(this.rowForShiftChange.ShiftId);
                replaceId = parseInt(row.shift.id.toString());
                replaceUnitId = parseInt(row.unit.id.toString());
                this.state.isLoading = true;
                this.scheduleApiService.changeOpenShift(this.dateOn, empId, originalId, replaceId, replaceUnitId)
                    .then(function () {
                    _this.state.isLoading = false;
                    _this.rowForShiftChange = null;
                    _this.state.switchShiftMode = false;
                    _this.loadDetailsEvent.next();
                })
                    .catch(function (err) {
                    _this.state.isLoading = false;
                });
        }
    };
    DailyUnitGridComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
                        return [2 /*return*/];
                }
            });
        });
    };
    DailyUnitGridComponent.prototype.checkReplaceEmployeeApprovedStatus = function (row, empId) {
        var _this = this;
        var shiftEndDate = row.ShiftName.split('-')[0].includes('p') && row.ShiftName.split('-')[1].includes('a') ? moment(this.dateOn).add(1, 'day') : this.dateOn;
        var startDate = this.setDateFormat(this.dateOn);
        var endDate = this.setDateFormat(this.dateOn);
        this.scheduleEntryApiService.checkApprovedPayperiod(empId, startDate, endDate).then(function (data) {
            _this.shiftReplacementService.replaceApprovedResult.push(data);
        });
    };
    DailyUnitGridComponent.prototype.approvedPayperiodForOrgLevel = function () {
        var _this = this;
        this.startDate = this.setDateFormat(this.dateOn);
        this.scheduleEntryApiService.checkApprovedPayperiodForOrgLevel(this.orgLevel.id, this.startDate, this.startDate).then(function (data) {
            _this.isOrglevelPayPeriodApproved = data;
            if (_this.isOrglevelPayPeriodApproved) {
                var message = _this.details.canEditScheduledApprovePayPeriod ? "This will modify a schedule in an approved pay period and impact the PBJ Calculation for the employee, are you sure you want to continue?" : 'You do not have permissions to modify a schedule in an approved pay period';
                var popupOptions = new ConfirmOptions();
                popupOptions.showCancel = true;
                popupOptions.showOK = _this.details.canEditScheduledApprovePayPeriod ? true : false;
                ;
                popupOptions.buttonOKtext = 'Ok';
                popupOptions.buttonCanceltext = _this.details.canEditScheduledApprovePayPeriod ? 'Cancel' : 'Ok';
                ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                    if (result) {
                        _this.router.navigateByUrl(_this.getOpenShiftUrlTree());
                    }
                }, popupOptions);
            }
            else
                _this.router.navigateByUrl(_this.getOpenShiftUrlTree());
        });
    };
    DailyUnitGridComponent.prototype.setDateFormat = function (date) {
        return moment(date).format("MM-DD-YYYY");
    };
    DailyUnitGridComponent.prototype.isTablet = function () {
        return this.screenWidth < 1023 ? true : false;
    };
    DailyUnitGridComponent.prototype.isMobile = function () {
        return this.screenWidth < 768 ? true : false;
    };
    DailyUnitGridComponent.prototype.getPopperContentClass = function () {
        if (this.screenWidth < 1023 && this.screenWidth > 768)
            return 'customPopperContentClass';
        if (this.screenWidth < 768)
            return 'mobilePopperContentClass';
    };
    DailyUnitGridComponent.prototype.openModal = function () {
        var _this = this;
        this.isActionsOpened = false;
        var options = new DailyUnitFilterOptions();
        this.management.duaOptions.positionGroupFilter = this.positionGroupFilter;
        this.management.duaOptions.posFilter = this.posFilter;
        this.management.duaOptions.unitFilter = this.unitFilter;
        this.management.duaOptions.shiftFilter = this.shiftFilter;
        var dialog = DailyUnitFilterDialogComponent.openDialog(options, this.modalService, function (result) {
            if (result) {
                _this.selectedUnitIds = _this.management.duaOptions.selectedUnitIds;
                _this.selectedPositionGroupIds = _this.management.duaOptions.selectedPositionGroupIds;
                _this.selectedShiftIds = _this.management.duaOptions.selectedShiftIds;
                _this.selectedPositionIds = _this.management.duaOptions.selectedPositionIds;
                _this.posFilter = _this.management.duaOptions.posFilter;
                _this.unitFilter = _this.management.duaOptions.unitFilter;
                _this.positionGroupFilter = _this.management.duaOptions.positionGroupFilter;
                _this.shiftFilter = _this.management.duaOptions.shiftFilter;
                _this.applyFilters();
                _this.saveFilters();
                _this.state.isLeftSidebarOpen = false;
            }
        });
    };
    DailyUnitGridComponent.prototype.openViewSettingsModal = function () {
        var _this = this;
        this.isActionsOpened = false;
        this.management.duaOptions.settingsType = this.currentViewMode;
        var options = new DailyUnitFilterOptions();
        var dialog = DailyUnitViewSettingsPopupComponent.openDialog(options, this.modalService, function (result) {
            if (result) {
                _this.currentViewMode = _this.management.duaOptions.settingsType;
                _this.saveViewMode();
                _this.categoriesDetails();
            }
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitGridComponent.prototype, "isLeftSidebarOpenSubscripion", void 0);
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], DailyUnitGridComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitGridComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], DailyUnitGridComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], DailyUnitGridComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitGridComponent.prototype, "detailsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitGridComponent.prototype, "dateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitGridComponent.prototype, "notDepartmentSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitGridComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitGridComponent.prototype, "orgLevelSubscripion", void 0);
    return DailyUnitGridComponent;
}());
export { DailyUnitGridComponent };
