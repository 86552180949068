import { Injectable } from '@angular/core';
import { IAppState } from '../../store';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class SidebarActions {
  public static SET_RIGHT_SIDEBAR: string = 'SET_RIGHT_SIDEBAR';
  public static SET_LEFT_SIDEBAR: string = 'SET_LEFT_SIDEBAR';
  public static SET_LEFT_SIDEBAR_HIDDEN: string = 'SET_LEFT_SIDEBAR_HIDDEN';

  private ngRedux: NgRedux<IAppState>;

  constructor(ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }

  public setRightSidebar(isOpen: boolean): void {
    this.ngRedux.dispatch({
      type: SidebarActions.SET_RIGHT_SIDEBAR,
      payload: isOpen
    });
  }

  public setLeftSidebar(isOpen: boolean): void {
    this.ngRedux.dispatch({
      type: SidebarActions.SET_LEFT_SIDEBAR,
      payload: isOpen
    });
  }
  public setLeftSidebarHidden(isHidden: boolean): void {
    this.ngRedux.dispatch({
      type: SidebarActions.SET_LEFT_SIDEBAR_HIDDEN,
      payload: isHidden
    });
  }
}
