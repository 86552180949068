
<div class="col-sm-12 filter-options">
    <div class="row">
      <div class="col-xs-12">
        Events to display
      </div>
      <div class="col-xs-12">
          <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
            <slx-checkbox-input slx-input name="empPunch" fieldName="empPunch" caption="Valid Punch" [(ngModel)]="filter.empPunch"
              (ngModelChange)="onFilterChanged()">
            </slx-checkbox-input>
          </slx-input-decorator>
      </div>
      <div class="col-xs-12">
          <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
            <slx-checkbox-input slx-input name="editPunch" fieldName="editPunch" caption="Punch Edit" [(ngModel)]="filter.editPunch"
              (ngModelChange)="onFilterChanged()">
            </slx-checkbox-input>
          </slx-input-decorator>
      </div>
      <div class="col-xs-12">
          <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
            <slx-checkbox-input slx-input name="essRequest" fieldName="essRequest" caption="Emp Request" [(ngModel)]="filter.essRequest"
              (ngModelChange)="onFilterChanged()">
            </slx-checkbox-input>
          </slx-input-decorator>
        </div>

      <div class="col-xs-12">
          <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
            <slx-checkbox-input slx-input name="invalidPunch" fieldName="invalidPunch" caption="Invalid Punch" [(ngModel)]="filter.invalidPunch"
              (ngModelChange)="onFilterChanged()">
            </slx-checkbox-input>
          </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="invalidLogin" fieldName="invalidLogin" caption="Invalid Login" [(ngModel)]="filter.invalidLogin"
            (ngModelChange)="onFilterChanged()">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
        <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="schedule" fieldName="schedule" caption="Schedule" [(ngModel)]="filter.schedule" (ngModelChange)="onFilterChanged()">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>     
    </div>
  </div>
