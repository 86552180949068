/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pm-employee-roster.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../../common/components/spinner/spinner.component";
import * as i4 from "../pm-employee-roster-toolbar/pm-employee-roster-toolbar.component.ngfactory";
import * as i5 from "../pm-employee-roster-toolbar/pm-employee-roster-toolbar.component";
import * as i6 from "../../../services/pm-employee-roster-management.service";
import * as i7 from "../../../../../common/services/column-settings/column-management.service";
import * as i8 from "../../../../../common/services/state-management/state-management.service";
import * as i9 from "../../../../../common/services/device-detector/device-detector.service";
import * as i10 from "../pm-employee-roster-grid/pm-employee-roster-grid.component.ngfactory";
import * as i11 from "../pm-employee-roster-grid/pm-employee-roster-grid.component";
import * as i12 from "../../../../../common/services/modal/modal.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../../../common/services/column-settings/column-settings-storage.service";
import * as i15 from "../../../../../common/services/component-state/component-state-storage.service";
import * as i16 from "../../../services/performance-management-api.service";
import * as i17 from "../../../services/pm-management.service";
import * as i18 from "./pm-employee-roster.component";
var styles_PmEmployeeRosterComponent = [i0.styles];
var RenderType_PmEmployeeRosterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PmEmployeeRosterComponent, data: {} });
export { RenderType_PmEmployeeRosterComponent as RenderType_PmEmployeeRosterComponent };
export function View_PmEmployeeRosterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-pm-employee-roster-toolbar", [], null, null, null, i4.View_PmEmployeeRosterToolbarComponent_0, i4.RenderType_PmEmployeeRosterToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i5.PmEmployeeRosterToolbarComponent, [i6.PmEmployeeRosterManagementService, i7.ColumnManagementService, i8.StateManagementService, i9.DeviceDetectorService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-pm-employee-roster-grid", [], null, null, null, i10.View_PmEmployeeRosterGridComponent_0, i10.RenderType_PmEmployeeRosterGridComponent)), i1.ɵdid(8, 245760, null, 0, i11.PmEmployeeRosterGridComponent, [i9.DeviceDetectorService, i6.PmEmployeeRosterManagementService, i12.ModalService, i13.ActivatedRoute, i13.Router], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_PmEmployeeRosterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-pm-employee-roster", [], null, null, null, View_PmEmployeeRosterComponent_0, RenderType_PmEmployeeRosterComponent)), i1.ɵprd(4608, null, i7.ColumnManagementService, i7.ColumnManagementService, [i14.ColumnSettingsStorageService]), i1.ɵprd(4608, null, i8.StateManagementService, i8.StateManagementService, [i15.ComponentStateStorageService, i14.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.PmEmployeeRosterManagementService, i6.PmEmployeeRosterManagementService, [i16.PerformanceManagementApiService, i17.PmManagementService]), i1.ɵdid(4, 245760, null, 0, i18.PmEmployeeRosterComponent, [i6.PmEmployeeRosterManagementService, i13.ActivatedRoute, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var PmEmployeeRosterComponentNgFactory = i1.ɵccf("slx-pm-employee-roster", i18.PmEmployeeRosterComponent, View_PmEmployeeRosterComponent_Host_0, {}, {}, []);
export { PmEmployeeRosterComponentNgFactory as PmEmployeeRosterComponentNgFactory };
