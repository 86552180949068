import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { LookupApiService, LookupService, LookupType } from '../../../organization';

@Injectable()
export class PunchAttestationResolver implements Resolve<Promise<any>> {
  constructor(private lookupApiService: LookupApiService,
    private lookupService : LookupService) {}

  resolve(): Promise<any> {
    return Promise.all([this.lookupApiService.getAttestationTypes(),this.lookupApiService.getRestrictedAnswers(),this.lookupService.getLookup({ lookupType: LookupType.punchAttestationAnswerCodes }),this.lookupService.getLookup({ lookupType: LookupType.punchAttestationPunchType })]);
  }
}