import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { configurationConfig } from '../../../configuration/configuration.config';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { IdealScheduleMapService } from './ideal-schedule-map.service';
import { dateTimeUtils } from '../../../common/utils/index';
import { LookupType } from './../../../organization/models/lookup/lookup-definition';
import { LookupService } from './../../../organization/services/lookup/lookup.service';
import { MetaMapService } from '../../../core/services/index';
import * as _ from 'lodash';
import { IImportException, IdealScheduleImportTemplate, ImportException } from '../../models/ideal-schedule/ideal-schedule-import-template';
var IdealScheduleApiService = /** @class */ (function () {
    function IdealScheduleApiService(apiUtilService, urlParamsService, idealScheduleMapService, metaMapService, lookupService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.idealScheduleMapService = idealScheduleMapService;
        this.metaMapService = metaMapService;
        this.lookupService = lookupService;
    }
    IdealScheduleApiService.prototype.getIdealScheduleOverview = function (orgLevelId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["", "/", "/", "/", "/", ""], ["", "/", "/", "/", "/", ""])), configurationConfig.api.configuration.root, configurationConfig.api.configuration.orglevel.root, orgLevelId, configurationConfig.api.configuration.configuration.idealSchedule.root, configurationConfig.api.configuration.configuration.idealSchedule.overview);
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            return _this.idealScheduleMapService.mapIdealScheduleOverview(response.data);
        });
        return promise;
    };
    IdealScheduleApiService.prototype.getIdealSchedule = function (orgLevelId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["", "/", "/", "/", ""], ["", "/", "/", "/", ""])), configurationConfig.api.configuration.root, configurationConfig.api.configuration.orglevel.root, orgLevelId, configurationConfig.api.configuration.configuration.idealSchedule.root);
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            var actions = response.meta ? _this.metaMapService.mapActions(response.meta) : null;
            return { actions: actions, records: _this.idealScheduleMapService.mapIdealScheduleList(response.data) };
        });
        return promise;
    };
    IdealScheduleApiService.prototype.getIdealSchedulePosition = function (orgLevelId, positionId, startDate, endDate) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["", "/", "/", "/", "/position/", ""], ["", "/", "/", "/", "/position/", ""])), configurationConfig.api.configuration.root, configurationConfig.api.configuration.orglevel.root, orgLevelId, configurationConfig.api.configuration.configuration.idealSchedule.root, positionId);
        var request = this.urlParamsService.createGetRequest(url, {
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });
        var promise = this.apiUtilService.request(request);
        var shiftDefinitionPromise = this.lookupService.getLookup({ lookupType: LookupType.shiftDefinition, orgLevelId: orgLevelId });
        var shiftGroupDefinitionPromise = this.lookupService.getLookup({ lookupType: LookupType.shiftGroupDefinition, orgLevelId: orgLevelId });
        return Promise.all([promise, shiftDefinitionPromise, shiftGroupDefinitionPromise])
            .then(function (values) {
            var positionResponse = values[0];
            var shifts = values[1];
            var groups = values[2];
            return _this.idealScheduleMapService.mapIdealSchedulePosition(positionResponse.data, shifts, groups);
        });
    };
    IdealScheduleApiService.prototype.getIdealSchedulePositionHistory = function (orgLevelId, positionId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["", "/", "/", "/", "/position/", "/history"], ["", "/", "/", "/", "/position/", "/history"])), configurationConfig.api.configuration.root, configurationConfig.api.configuration.orglevel.root, orgLevelId, configurationConfig.api.configuration.configuration.idealSchedule.root, positionId);
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.request(request);
        return promise.then(function (response) {
            return _this.idealScheduleMapService.mapIdealSchedulePositionsHistory(response.data);
        });
    };
    IdealScheduleApiService.prototype.saveIdealSchedulePosition = function (scheduleId, orgLevelId, census, startDate, endDate, position) {
        var url = this.apiUtilService.apiRoot(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["", "/", "/", "/", "/", ""], ["", "/", "/", "/", "/", ""])), configurationConfig.api.configuration.root, configurationConfig.api.configuration.orglevel.root, orgLevelId, configurationConfig.api.configuration.configuration.idealSchedule.root, scheduleId);
        position.startDate = startDate;
        position.endDate = endDate;
        position.idealScheduleCensus = census;
        var body = this.idealScheduleMapService.reverseMapIdealSchedulePosition(position);
        var request = new HttpRequest('POST', url, body);
        return this.apiUtilService.request(request);
    };
    IdealScheduleApiService.prototype.saveIdealScheduleConfiguration = function (scheduleId, orgLevelId, startDate, endDate, positionId, targetHours) {
        var url = this.apiUtilService.apiRoot(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["", "/", "/", "/", "/", "/", ""], ["", "/", "/", "/", "/", "/", ""])), configurationConfig.api.configuration.root, configurationConfig.api.configuration.orglevel.root, orgLevelId, configurationConfig.api.configuration.configuration.idealSchedule.root, scheduleId, configurationConfig.api.configuration.root);
        var configuration = this.idealScheduleMapService.mapIdealScheduleConfiguration(orgLevelId, startDate, endDate, positionId, targetHours);
        var request = new HttpRequest('POST', url, configuration);
        return this.apiUtilService.request(request);
    };
    IdealScheduleApiService.prototype.GetIdealShiftValidation = function (orgLevelId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["", "/", "/", "/", ""], ["", "/", "/", "/", ""])), configurationConfig.api.configuration.root, configurationConfig.api.configuration.orglevel.root, orgLevelId, configurationConfig.api.configuration.configuration.idealScheduleShiftGroup);
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.request(request).then(function (response) {
            return _this.idealScheduleMapService.mapidealScheduleShiftGroup(response.data);
        });
        return promise;
    };
    IdealScheduleApiService.prototype.getOrganizationsList = function (orgLevelId) {
        var _this = this;
        var orgLevelDetails = JSON.parse(sessionStorage.getItem('Organizations'));
        var orgLevelData = _.filter(orgLevelDetails, function (item) { return item.id !== orgLevelId && item.orgLevelType === 'Organization'; });
        var promise = new Promise(function (resolve) { return resolve(_this.idealScheduleMapService.mapOrgLevelDetails(orgLevelData)); });
        return promise;
    };
    IdealScheduleApiService.prototype.importTemplatePositionsValidation = function (orgLevelId, selectedPositions) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["", "/", "/", "/", "/", ""], ["", "/", "/", "/", "/", ""])), configurationConfig.api.configuration.root, orgLevelId, configurationConfig.api.configuration.configuration.importTemplate.root, configurationConfig.api.configuration.configuration.importTemplate.export.root, configurationConfig.api.configuration.configuration.importTemplate.export.selectedPositionValidation.root);
        var body = this.idealScheduleMapService.reverseMapSelectPositionData(selectedPositions);
        var request = this.urlParamsService.createPostRequest(url, body);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.idealScheduleMapService.mapValidationException(response.data); });
        return promise;
    };
    IdealScheduleApiService.prototype.downloadImportTemplate = function (orgLevelId, selectedPositions) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_9 || (templateObject_9 = tslib_1.__makeTemplateObject(["", "/", "/", "/", "/", ""], ["", "/", "/", "/", "/", ""])), configurationConfig.api.configuration.root, orgLevelId, configurationConfig.api.configuration.configuration.importTemplate.root, configurationConfig.api.configuration.configuration.importTemplate.export.root, configurationConfig.api.configuration.configuration.importTemplate.export.excel.root);
        var body = this.idealScheduleMapService.mapImportTemplateData(selectedPositions);
        var request = this.urlParamsService.createPostRequest(url, body);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.idealScheduleMapService.mapDownloadedData(response.data); });
        return promise;
    };
    IdealScheduleApiService.prototype.saveIdealScheduleExcel = function (orgLevelId, excelData, startDate, endDate) {
        var url = this.apiUtilService.apiRoot(templateObject_10 || (templateObject_10 = tslib_1.__makeTemplateObject(["", "/", "/", "/", "/", "?startDate=", "&endDate=", ""], ["", "/", "/", "/", "/", "?startDate=", "&endDate=", ""])), configurationConfig.api.configuration.root, orgLevelId, configurationConfig.api.configuration.configuration.importTemplate.root, configurationConfig.api.configuration.configuration.importTemplate.export.root, configurationConfig.api.configuration.configuration.importTemplate.export.saveIdealScheduleExcel.root, startDate.format('MM-DD-YYYY'), endDate.format('MM-DD-YYYY'));
        var body = this.idealScheduleMapService.reverseMapResultData(excelData);
        var request = new HttpRequest('POST', url, body);
        return this.apiUtilService.request(request);
    };
    return IdealScheduleApiService;
}());
export { IdealScheduleApiService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
