import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnDestroy, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { appConfig } from '../../../../app.config';
import { RestoreBudgetReq } from '../../../models/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { BudgetApiService } from '../../../services/index';
import { BudgetRestoreDialogComponent } from '../budget-restore-dialog/budget-restore-dialog.component';
import { IRangeDates } from '../../../../common/models/range-dates';
var BudgetHistoryComponent = /** @class */ (function () {
    function BudgetHistoryComponent(budgetApiService, modalService) {
        var _this = this;
        this.finishEditing = new EventEmitter();
        this.modalService = modalService;
        this.budgetApiService = budgetApiService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'budgetDefinition.lastUpdatedDate', dir: 'asc' }];
        //this.gridState.state.group = [{ field: 'budgetDefinition.startDate' }];
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.selectedRecord = _.first(records);
        });
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            isCorporation: false
        };
        this.startDate = moment().startOf('year').toDate();
        this.endDate = moment().endOf('year').toDate();
    }
    BudgetHistoryComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    BudgetHistoryComponent.prototype.onDiscard = function () {
        this.finishEditing.emit(null);
    };
    BudgetHistoryComponent.prototype.onRestore = function () {
        var _this = this;
        var req = new RestoreBudgetReq();
        req.orgLevelId = this.orgLevelId;
        req.sourceBudgetId = this.selectedRecord ? this.selectedRecord.budgetDefinition.id : 0;
        BudgetRestoreDialogComponent.openDialog(req, this.modalService, function (result, cmd) {
            if (result) {
                _this.state.isLoading = true;
                if (cmd.id === 0) {
                    _this.budgetApiService.cloneBudget(req.sourceBudgetId, cmd)
                        .then(function (addedBudget) {
                        _this.finishEditing.emit(addedBudget);
                    });
                }
                else {
                    _this.budgetApiService.restoreBudget(req.sourceBudgetId, cmd.id)
                        .then(function (restoredBudget) {
                        _this.state.isLoading = false;
                        _this.finishEditing.emit(restoredBudget);
                    });
                }
            }
        });
    };
    BudgetHistoryComponent.prototype.doLoad = function () {
        var _this = this;
        this.state.isLoading = true;
        this.budgetApiService.getBudgetHistory(this.orgLevelId, this.startDate, this.endDate)
            .then(function (records) {
            _this.budgetHistory = records;
            _this.refreshGrid();
            _this.state.isLoading = false;
        });
    };
    BudgetHistoryComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.doLoad();
    };
    BudgetHistoryComponent.prototype.ngOnChanges = function (changes) {
        if (changes['orgLevelId']) {
            this.doLoad();
        }
    };
    BudgetHistoryComponent.prototype.refreshGrid = function () {
        if (!this.budgetHistory) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.budgetHistory, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHistoryComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetHistoryComponent.prototype, "gridSelectSubscription", void 0);
    return BudgetHistoryComponent;
}());
export { BudgetHistoryComponent };
