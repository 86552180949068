import { Component, OnInit } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../common/models/index';
import {  Provider } from '@angular/core';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { appConfig, IApplicationConfig } from '../../../../app.config'; 
import { IndividualQuickEditOvelapDecision,IndividualQuickEditOvelapDecisionRequest,QuickEditOvelapDecision, QuickEditOvelapDecisionRequest } from '../../../../scheduler/models/master-schedule/quick-edit/quick-edit-overlap-decision';

export class ConfirmOptions {
  public showOK: boolean;
  public showCancel: boolean;
  public buttonOKtext: string;
  public buttonCanceltext: string;
  public className: string;
  public width: number;
  public height: number;
  public notes: string;
}

@Component({
  moduleId: module.id,
  selector: 'slx-overlapping-schedule-popup',
  templateUrl: './overlapping-schedule-popup.component.html',
  styleUrls: ['./overlapping-schedule-popup.component.scss']
})
export class OverlappingSchedulePopupComponent implements IDialog  {

  public dialogResult: boolean;
  public decision: IndividualQuickEditOvelapDecision;

  public static openDialog(
    request: IndividualQuickEditOvelapDecisionRequest,
    title: string,
    modalService: ModalService,
 
    callback: (result: IndividualQuickEditOvelapDecision) => void): OverlappingSchedulePopupComponent{
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = request.isMobile ? 300 : 560;
    dialogOptions.height =   300;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: IndividualQuickEditOvelapDecisionRequest,
          useValue: request
      }
    ];
       
    let dialog: OverlappingSchedulePopupComponent = modalService.globalAnchor.openDialog(OverlappingSchedulePopupComponent,`Please select an option`,
      dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
          callback(dialog.decision);
      });
      return dialog;
  }

  constructor(private options: DialogOptions  ,private modalService: ModalService,public request: IndividualQuickEditOvelapDecisionRequest ) {
  this.options =options;
  }
  public onCancel(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
  public onOverride(): void {
    this.dialogResult = true;
    this.decision = IndividualQuickEditOvelapDecision.Override;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onAppend(): void {
    this.dialogResult = true;
    this.decision = IndividualQuickEditOvelapDecision.Append;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onReplace(): void {
    this.dialogResult = true;
    this.decision = IndividualQuickEditOvelapDecision.Replace;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  
}
