/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wc-incident-report-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../wc-incident-report-container/wc-incident-report-container.component.ngfactory";
import * as i3 from "../../../services/crud/wc-report-management.service";
import * as i4 from "../../../services/crud/wc-report-api.service";
import * as i5 from "../../../services/crud/wc-report-map.service";
import * as i6 from "../../../../../organization/services/lookup/lookup.service";
import * as i7 from "../../../../../common/services/modal/modal.service";
import * as i8 from "../../../../../common/services/change-management/change-management.service";
import * as i9 from "../../../../../core/services/session/session.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i12 from "../../../../../common/services/file/file.service";
import * as i13 from "../wc-incident-report-container/wc-incident-report-container.component";
import * as i14 from "./wc-incident-report-dialog.component";
import * as i15 from "../../../models/incident-report-dialog-options";
import * as i16 from "../../../../../common/models/dialog-options";
var styles_WCIncidentReportDialogComponent = [i0.styles];
var RenderType_WCIncidentReportDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WCIncidentReportDialogComponent, data: {} });
export { RenderType_WCIncidentReportDialogComponent as RenderType_WCIncidentReportDialogComponent };
export function View_WCIncidentReportDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { containerElem: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 4, "div", [["class", "modal-window"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "slx-wc-incident-report-container", [], null, [[null, "action"], [null, "employeeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.onAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("employeeSelected" === en)) {
        var pd_1 = (_co.onEmployeeSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_WCIncidentReportContainerComponent_0, i2.RenderType_WCIncidentReportContainerComponent)), i1.ɵprd(512, null, i3.WCReportManagementService, i3.WCReportManagementService, [i4.WCReportApiService, i5.WCReportMapService, i6.LookupService, i7.ModalService, i8.ChangeManagementService, i9.SessionService, i10.Router, i10.ActivatedRoute, i11.NotificationsService, i12.FileService]), i1.ɵdid(5, 245760, null, 0, i13.WCIncidentReportContainerComponent, [i3.WCReportManagementService], { options: [0, "options"] }, { action: "action", employeeSelected: "employeeSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogOptions; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_WCIncidentReportDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-wc-incident-report-dialog", [], null, null, null, View_WCIncidentReportDialogComponent_0, RenderType_WCIncidentReportDialogComponent)), i1.ɵdid(1, 4243456, null, 0, i14.WCIncidentReportDialogComponent, [i15.IncidentReportDialogOptions, i16.DialogOptions, i7.ModalService], null, null)], null, null); }
var WCIncidentReportDialogComponentNgFactory = i1.ɵccf("slx-wc-incident-report-dialog", i14.WCIncidentReportDialogComponent, View_WCIncidentReportDialogComponent_Host_0, {}, {}, []);
export { WCIncidentReportDialogComponentNgFactory as WCIncidentReportDialogComponentNgFactory };
