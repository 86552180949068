/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./master-schedule-quick-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../components-library/components/check-box/check-box.component.ngfactory";
import * as i4 from "../../../../components-library/components/check-box/check-box.component";
import * as i5 from "@angular/forms";
import * as i6 from "angular2-moment/date-format.pipe";
import * as i7 from "../../../../organization/components/orglevel-shift-editor/orglevel-shift-editor.component.ngfactory";
import * as i8 from "../../../../organization/components/orglevel-shift-editor/orglevel-shift-editor.component";
import * as i9 from "../../../../organization/services/lookup/lookup.service";
import * as i10 from "./master-schedule-quick-edit.component";
import * as i11 from "../../../services/schedule/master-schedule-quick-edit.service";
import * as i12 from "../../../../common/services/modal/modal.service";
import * as i13 from "../../../services/schedule/master-schedule-management.service";
import * as i14 from "../../../../app-settings/services/app-settings-manage.service";
import * as i15 from "../../../services/schedule-entry/schedule-entry-api.service";
var styles_MasterScheduleQuickEditComponent = [i0.styles];
var RenderType_MasterScheduleQuickEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MasterScheduleQuickEditComponent, data: {} });
export { RenderType_MasterScheduleQuickEditComponent as RenderType_MasterScheduleQuickEditComponent };
function View_MasterScheduleQuickEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "message-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "message1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quick Edit Active!"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "message2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click a cell in the schedule to edit shift."]))], null, null); }
function View_MasterScheduleQuickEditComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "message-line error-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "message1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quick Edit Error!"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "message2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Not all required fields populated."]))], null, null); }
function View_MasterScheduleQuickEditComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "start-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startQuickEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Start"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isAllFieldsPopulated; _ck(_v, 0, 0, currVal_0); }); }
function View_MasterScheduleQuickEditComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "stop-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stopQuickEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Complete"]))], null, null); }
function View_MasterScheduleQuickEditComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "show-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showChangesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Show Changes"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasChanges; _ck(_v, 0, 0, currVal_0); }); }
function View_MasterScheduleQuickEditComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "hide-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideChangesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Hide Changes"]))], null, null); }
function View_MasterScheduleQuickEditComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "buttons-holder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_7)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "slx-check-box", [["caption", "Replace always"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.configuration.replaceAlways = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onReplaceAlwaysChanged() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_CheckBoxComponent_0, i3.RenderType_CheckBoxComponent)), i1.ɵdid(10, 49152, null, 0, i4.CheckBoxComponent, [], { caption: [0, "caption"] }, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.CheckBoxComponent]), i1.ɵdid(12, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(14, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isQuickEditStarted; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isQuickEditStarted; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.showChanges; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.showChanges; _ck(_v, 8, 0, currVal_3); var currVal_11 = "Replace always"; _ck(_v, 10, 0, currVal_11); var currVal_12 = _co.configuration.replaceAlways; _ck(_v, 12, 0, currVal_12); }, function (_ck, _v) { var currVal_4 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 14).ngClassValid; var currVal_9 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
function View_MasterScheduleQuickEditComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "change-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "change-emp-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "change-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "change-shift"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "change-unit"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " ", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "undo-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.undoEdit(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-undo-alt"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.employeeName; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.date, _co.appConfig.dateFormatShortYear)); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.shift.shiftName; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.shift.unitName; var currVal_4 = _v.parent.context.$implicit.shift.absenceCode; _ck(_v, 9, 0, currVal_3, currVal_4); }); }
function View_MasterScheduleQuickEditComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.index < 10); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MasterScheduleQuickEditComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "changes-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "changes-holder-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Last 10 Changes"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_9)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_MasterScheduleQuickEditComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.DateFormatPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "shift-editor-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "slx-orglevel-shift-editor", [], null, [[null, "onShiftTemplateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onShiftTemplateChanged" === en)) {
        var pd_0 = (_co.configurationChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_OrglevelShiftEditorComponent_0, i7.RenderType_OrglevelShiftEditorComponent)), i1.ɵdid(7, 49152, null, 0, i8.OrglevelShiftEditorComponent, [i9.LookupService], { orgLevelId: [0, "orgLevelId"] }, { onShiftTemplateChanged: "onShiftTemplateChanged" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleQuickEditComponent_8)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isQuickEditStarted && _co.isAllFieldsPopulated); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.isQuickEditStarted && !_co.isAllFieldsPopulated); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.orgLevelId; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.disablepopup; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.showChanges; _ck(_v, 11, 0, currVal_4); }, null); }
export function View_MasterScheduleQuickEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-master-schedule-quick-edit", [], null, null, null, View_MasterScheduleQuickEditComponent_0, RenderType_MasterScheduleQuickEditComponent)), i1.ɵdid(1, 638976, null, 0, i10.MasterScheduleQuickEditComponent, [i11.MasterScheduleQuickEditService, i9.LookupService, i12.ModalService, i13.MasterScheduleManagementService, i14.AppSettingsManageService, i15.ScheduleEntryApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MasterScheduleQuickEditComponentNgFactory = i1.ɵccf("slx-master-schedule-quick-edit", i10.MasterScheduleQuickEditComponent, View_MasterScheduleQuickEditComponent_Host_0, { orgLevelId: "orgLevelId" }, {}, []);
export { MasterScheduleQuickEditComponentNgFactory as MasterScheduleQuickEditComponentNgFactory };
