import { Routes, RouterModule } from '@angular/router';
import { LogiHostComponent } from '../app-modules/logi-integration/components/logi-host.component';

import {
  ApplicationContainerComponent
} from './components/index';
import { CanLeaveGuard } from '../common/services';
import { OrgLevelResolver } from './services/index';
import { StaffingPredictorComponent } from '../app-modules/staffing-predictor/components';
import { EliteDashboardComponent } from '../app-modules/ElieteDashboard/components/elite-dashboard.component';


export const logiRoutes: Routes = [
  {
    path: '',
    component: ApplicationContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'bi_dashboards',
        pathMatch: 'full'
      },
      {
        path: 'bi_dashboards',
        component: LogiHostComponent,
        data: { logiComponentId: 'dashboard', componentId: 'bi_dashboards' }
      },
      {
        path: 'bi_ss_create_report',
        component: LogiHostComponent,
        data: { logiComponentId: 'analysis', componentId: 'bi_ss_create_report' }
      },
      {
        path: 'bi_ss_view_reports',
        component: LogiHostComponent,
        data: { logiComponentId: 'explorer', componentId: 'bi_ss_view_reports' }
      },
      {
        path: 'cms_insights_dashboard',
        component: EliteDashboardComponent,
        data: { logiComponentId: 'explorer', componentId: 'cms_insights_dashboard' }
      },
      {
        path: 'staffing_predictor',
        canDeactivate: [CanLeaveGuard],
        resolve: [OrgLevelResolver],
        component: StaffingPredictorComponent,
        data: { componentId: 'staffing_predictor', accessContext: 'OrgLevel' }
      }
    ]
  },
];


