<div>
    <div *ngIf="currentWeather" class="info" [class.wide]="isWideLayout">
      <div class="current">
        <weather-icon
          class="big"
          [iconImageUrl]="currentWeather?.iconUrl"
          [iconClass]="currentWeather?.iconClass"></weather-icon>
        <weather-location [place]="location"></weather-location>
        <weather-current-temperature
          class="big"
          [temp]="currentWeather?.temp"
          [scaleLabel]="scaleLabel">
        </weather-current-temperature>
        <weather-current-details
          *ngIf="settings.showDetails"
          [maxTemp]="currentWeather?.maxTemp"
          [minTemp]="currentWeather?.minTemp"
          [pressure]="currentWeather?.pressure"
          [humidity]="currentWeather?.humidity"
          [scaleLabel]="scaleLabel">
        </weather-current-details>
      </div>
    </div>
    <div *ngIf="!currentWeather" class="info empty">
      <i class="wi wi-sunrise"></i>
      No weather data...
    </div>
</div>
