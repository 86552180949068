import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../app.config';
import { timeAndAttendanceConfig } from '../../../time-and-attendance.config';
import { unsubscribe } from '../../../../core/decorators/index';
import { DailyPayRule, TimeCardModel, DailyPayRuleStatus } from '../../../models/index';
import { DailyTimecardManagementService, resetAction } from '../../../services/index';
import { ChangeManagementService, ModalService } from '../../../../common/services/index';
import { KendoGridStateHelper, DialogOptions } from '../../../../common/models/index';
import { PayRule, PayRuleType, ScheduledShiftDefinition, Position } from '../../../../organization/models/index';
import { LookupApiService } from '../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { PayCodesAdditionalDataComponent } from '../pay-codes-additional-data/pay-codes-additional-data.component';
var TimeCardPaycodesListComponent = /** @class */ (function () {
    function TimeCardPaycodesListComponent(managementService, lookupApiService, changeManagementService, modalService) {
        this.managementService = managementService;
        this.lookupApiService = lookupApiService;
        this.changeManagementService = changeManagementService;
        this.modalService = modalService;
        this.retroField = false;
        this.isFormValid = false;
        this.maxAmt = 999999.99;
        this.changeGroup = 'TimeCardPaycodesListComponent';
        this.gridState = new KendoGridStateHelper();
        this.hoursType = PayRuleType.hours;
        this.moneyType = PayRuleType.dollars;
        this.appConfig = appConfig;
        this.moneyCodesValidationConig = { values: [], propertyName: 'id', noMore: 1, value: undefined };
        this.modalService = modalService;
    }
    Object.defineProperty(TimeCardPaycodesListComponent.prototype, "model", {
        get: function () {
            return this.m_model;
        },
        set: function (value) {
            if (!value) {
                return;
            }
            this.m_model = value;
            this.records = value.payRules;
            if (!this.employeeId || this.employeeId !== value.employee.id) {
                this.employeeId = value.employee.id;
                this.loadPositionsLookup();
                this.getPayRules();
            }
            this.maxDateLimit = moment(value.date).add(1, 'd').toDate();
            this.minDateLimit = moment(value.date).subtract(1, 'd').toDate();
            this.filterPositionLookup();
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeCardPaycodesListComponent.prototype, "isRetroField", {
        get: function () {
            return (_.filter(this.records, function (item) { return (!_.isNull(item.ruleDefinition.additionalVariable)); }).length > 0 || this.retroField);
        },
        enumerable: true,
        configurable: true
    });
    TimeCardPaycodesListComponent.prototype.isRowValid = function (item) {
        return ((item.ruleDefinition && _.isEqual(item.ruleDefinition.additionalVariable, timeAndAttendanceConfig.retroFieldValue)) && (_.isNull(item.additionalVariable) || _.isEmpty(item.additionalVariable))) ? false : true;
    };
    TimeCardPaycodesListComponent.prototype.isRetroFieldRow = function (item, isValueRequired) {
        if (isValueRequired) {
            return ((item.ruleDefinition && _.isEqual(item.ruleDefinition.additionalVariable, timeAndAttendanceConfig.retroFieldValue)) && (!_.isNull(item.additionalVariable) && !_.isEmpty(item.additionalVariable)));
        }
        else {
            return ((item.ruleDefinition && _.isEqual(item.ruleDefinition.additionalVariable, timeAndAttendanceConfig.retroFieldValue)) && (_.isNull(item.additionalVariable) || _.isEmpty(item.additionalVariable)));
        }
    };
    TimeCardPaycodesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resetSubscription = this.managementService.resetSection$.filter(function (m) { return m.target === 'paycodes'; }).subscribe(function (m) {
            _this.records = m.model.payRules;
            _this.refreshGrid();
        });
        this.canEditChangedSubscription = this.managementService.canEditChanged$
            .subscribe(function (canEdit) {
            _this.canEditTimecard = canEdit;
        });
        this.optionsChangedSubscription = this.managementService.displayOptionSelected$
            .subscribe(function (options) {
            _this.displayOptions = options;
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.editMode = false;
            _this.refreshGrid();
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            if (event.isNew) {
                _this.records.push(event.dataItem);
            }
            _this.managementService.onPayCodesEditAction(false);
            _this.editMode = false;
            _this.retroField = false;
            _this.changeManagementService.changeNotify(_this.changeGroup);
        });
        this.gridCancelSubscription = this.gridState.onCancel$.subscribe(function () {
            _this.managementService.onPayCodesEditAction(false);
            _this.editMode = false;
            _this.retroField = false;
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function (editedRecord) {
            _this.managementService.onPayCodesEditAction(true);
            _this.editMode = true;
            _this.fillSelectedMoneyCodeRules(editedRecord);
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            if (event.dataItem.status === DailyPayRuleStatus.deleted) {
                _this.records.splice(event.rowIndex, 1);
            }
            else {
                event.dataItem.status = DailyPayRuleStatus.deleted;
            }
            _this.refreshGrid();
            _this.changeManagementService.changeNotify(_this.changeGroup);
        });
    };
    TimeCardPaycodesListComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeCardPaycodesListComponent.prototype.loadPositionsLookup = function () {
        var _this = this;
        this.lookupApiService.getPositions(this.employeeId, null, null, true)
            .then(function (positions) {
            _this.positionsOriginLookup = positions;
            _this.filterPositionLookup();
        });
    };
    TimeCardPaycodesListComponent.prototype.onChangeRuleType = function (payCode) {
        this.calculateTimes(payCode, payCode.ruleDefinition);
        this.fillSelectedMoneyCodeRules(payCode);
        if (_.isEqual(payCode.ruleDefinition.additionalVariable, timeAndAttendanceConfig.retroFieldValue)) {
            this.retroField = true;
        }
        else {
            this.retroField = false;
        }
    };
    TimeCardPaycodesListComponent.prototype.fillSelectedMoneyCodeRules = function (payCode) {
        var moneyRules = _.filter(this.records, function (d) { return d.type === PayRuleType.dollars; });
        if (payCode) {
            moneyRules = _.without(moneyRules, payCode);
        }
        this.moneyCodesValidationConig.values = _.map(moneyRules, function (d) { return d.ruleDefinition; });
    };
    TimeCardPaycodesListComponent.prototype.calculateTimes = function (payCode, payRule) {
        if (payCode.start && payCode.end) {
            return;
        }
        var scheduleStart = this.model.date;
        var lastPunchTime = this.model.date;
        var firstPunchTime = this.model.date;
        var scheduleStartDef = _.first(_.orderBy(this.model.schedule, function (shift) {
            return shift.start;
        }));
        if (scheduleStartDef) {
            scheduleStart = scheduleStartDef.start;
        }
        var orderedPunches = _.orderBy(this.model.dailyPunches, function (punch) {
            return punch.time;
        });
        var lastPunch = _.last(orderedPunches);
        if (lastPunch) {
            lastPunchTime = _.isNil(lastPunch.roundedTime) ? lastPunch.time : lastPunch.roundedTime;
        }
        var firstPunch = _.first(orderedPunches);
        if (firstPunch) {
            firstPunchTime = _.isNil(firstPunch.roundedTime) ? firstPunch.time : firstPunch.roundedTime;
        }
        if (!payCode.start) {
            if (payRule.startTimeOffset === 'Schedule Start') {
                payCode.start = scheduleStart;
            }
            else if (payRule.startTimeOffset === 'Last Punch') {
                payCode.start = lastPunchTime;
            }
            else {
                payCode.start = firstPunchTime;
            }
        }
        if (!payCode.end) {
            if (payRule.endTimeOffset === 'Schedule Start') {
                payCode.end = scheduleStart;
            }
            else if (payRule.endTimeOffset === 'First Punch') {
                payCode.end = firstPunchTime;
            }
            else {
                payCode.end = lastPunchTime;
            }
        }
        this.recalculateInterval(payCode);
    };
    TimeCardPaycodesListComponent.prototype.addHoursCode = function () {
        if (!this.hourCodeRulesExists || this.editMode || !this.canEditTimecard) {
            this.modalService.globalAnchor.openInfoDialog("Warning", 'This timecard can`t be Edited.');
            return;
        }
        var code = new DailyPayRule();
        code.type = this.hoursType;
        code.start = null;
        code.end = null;
        code.interval = 0;
        code.status = DailyPayRuleStatus.adjusted;
        code.isNew = true;
        this.recalculateInterval(code);
        this.gridState.closeEditor(this.kendoGrid);
        this.kendoGrid.addRow(code);
        this.managementService.onPayCodesEditAction(true);
        this.editMode = true;
    };
    TimeCardPaycodesListComponent.prototype.isValidDate = function (date) {
        return moment(date).isValid();
    };
    TimeCardPaycodesListComponent.prototype.onStartDateChanged = function (dataItem) {
        if (!this.isValidDate(dataItem.start)) {
            dataItem.start = null;
            return;
        }
        this.recalculateInterval(dataItem);
    };
    TimeCardPaycodesListComponent.prototype.onEndDateChanged = function (dataItem) {
        if (!this.isValidDate(dataItem.end)) {
            dataItem.end = null;
            return;
        }
        this.recalculateInterval(dataItem);
    };
    TimeCardPaycodesListComponent.prototype.onIntervalChanged = function (dataItem, hours) {
        var ms = hours * 60 * 60 * 1000;
        dataItem.interval = ms;
        this.calculateEndDate(dataItem);
    };
    TimeCardPaycodesListComponent.prototype.onIntervalHMChanged = function (dataItem, value) {
        var interval = dateTimeUtils.getIntervalFromIntervalTime(value, 'h');
        this.onIntervalChanged(dataItem, interval);
    };
    TimeCardPaycodesListComponent.prototype.addMoneyCode = function () {
        if (!this.moneyCodeRulesExists || this.editMode || !this.canEditTimecard) {
            this.modalService.globalAnchor.openInfoDialog("Warning", 'This timecard can`t be Edited.');
            return;
        }
        var code = new DailyPayRule();
        code.type = this.moneyType;
        code.start = this.model.date;
        code.end = this.model.date;
        code.interval = 0;
        code.moneyAmount = 0;
        code.status = DailyPayRuleStatus.adjusted;
        code.isNew = true;
        this.gridState.closeEditor(this.kendoGrid);
        this.kendoGrid.addRow(code);
        this.managementService.onPayCodesEditAction(true);
        this.editMode = true;
        this.fillSelectedMoneyCodeRules(code);
    };
    TimeCardPaycodesListComponent.prototype.discardChanges = function () {
        this.filterPositionLookup();
        this.changeManagementService.clearChanges(this.changeGroup);
        this.managementService.onPayCodesDiscardAction();
    };
    Object.defineProperty(TimeCardPaycodesListComponent.prototype, "hourCodeRulesExists", {
        get: function () {
            return this.hourCodeRules && this.hourCodeRules.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeCardPaycodesListComponent.prototype, "moneyCodeRulesExists", {
        get: function () {
            return this.moneyCodeRules && this.moneyCodeRules.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    TimeCardPaycodesListComponent.prototype.filterPositionLookup = function () {
        var _this = this;
        if (!this.positionsOriginLookup) {
            return;
        }
        this.positionsLookup = _.cloneDeep(this.positionsOriginLookup);
        this.positionsLookup = _.filter(this.positionsLookup, function (p) {
            var res = (moment(_this.model.date).isSameOrAfter(p.startDate) && (!p.endDate || moment(_this.model.date).isSameOrBefore(p.endDate)));
            return res;
        });
        _.each(this.records, function (r) {
            if (r.position && r.position.id) {
                var index = _.findIndex(_this.positionsLookup, function (p) {
                    return r.position.id == p.id;
                });
                if (index == -1) {
                    _this.positionsLookup.push(r.position);
                }
            }
        });
    };
    TimeCardPaycodesListComponent.prototype.calculateEndDate = function (item) {
        if (item.interval < 0) {
            item.start = moment(this.model.date).startOf('day').toDate();
            item.end = item.start;
        }
        else {
            item.end = new Date(item.interval + item.start.getTime());
        }
        this.recalculateInterval(item);
    };
    TimeCardPaycodesListComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
        this.fillSelectedMoneyCodeRules();
    };
    TimeCardPaycodesListComponent.prototype.filterTypeOptions = function (forHours) {
        var _this = this;
        var options = [];
        var lookup = this.payRulesLookup;
        if (lookup) {
            options = _.filter(lookup, function (rule) {
                if (forHours) {
                    return rule.ruleType === _this.hoursType;
                }
                return rule.ruleType === _this.moneyType;
            });
        }
        return options;
    };
    TimeCardPaycodesListComponent.prototype.getPayRules = function () {
        var _this = this;
        this.lookupApiService.getPayRules(this.employeeId)
            .then(function (codes) {
            _this.payRulesLookup = codes;
            _this.hourCodeRules = _this.filterTypeOptions(true);
            _this.moneyCodeRules = _this.filterTypeOptions(false);
        });
    };
    TimeCardPaycodesListComponent.prototype.recalculateInterval = function (item) {
        DailyPayRule.calculateInterval(item);
    };
    TimeCardPaycodesListComponent.prototype.setAdditionalData = function (item) {
        this.openAdditionalDataDialog(item);
    };
    TimeCardPaycodesListComponent.prototype.openAdditionalDataDialog = function (item) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 250;
        dialogOptions.width = 350;
        dialogOptions.hideTitleBar = true;
        var dialog = this.modalService.globalAnchor.openDialog(PayCodesAdditionalDataComponent, '', dialogOptions, undefined, function (result) {
            if (result) {
                item.additionalVariable = dialog.retroDate ? moment(dialog.retroDate).format(appConfig.dateFormat) : '';
            }
        });
        if (dialog && !dialog.isLoading && dialog.initialized) {
            dialog.load(new Date(item.additionalVariable));
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardPaycodesListComponent.prototype, "resetSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardPaycodesListComponent.prototype, "canEditChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardPaycodesListComponent.prototype, "optionsChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardPaycodesListComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardPaycodesListComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardPaycodesListComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardPaycodesListComponent.prototype, "gridCancelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardPaycodesListComponent.prototype, "gridRemoveSubscription", void 0);
    return TimeCardPaycodesListComponent;
}());
export { TimeCardPaycodesListComponent };
