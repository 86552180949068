import { BenefitDetailsPayrollGroup } from './benefit-details-payroll-group';

export interface IBenefitDetailsPayrollDeductions {
  id: number;
  name: string;
  description: string;
  isIgnored: boolean;
  benefit?: BenefitDetailsPayrollGroup;
}

export class BenefitDetailsPayrollDeductions {
  public id: number;
  public name: string;
  public description: string;
  public isIgnored: boolean;
  public benefit?: BenefitDetailsPayrollGroup;
  public initialIsIgnored: boolean;
}
