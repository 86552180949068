import { Router, ActivatedRoute, UrlTree, NavigationExtras, Params } from '@angular/router';
import { appConfig, IApplicationConfig } from '../../../app.config';
import * as moment from 'moment';

export class ApprovalPayCodesNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToPayCodesApproval(startDate: Date, endDate: Date, orgLevelId: number = 0): void {
    let params: Params = {
      startDate: moment(startDate).format(appConfig.linkDateFormat),
      endDate: moment(endDate).format(appConfig.linkDateFormat)
    };

    if (orgLevelId > 0) {
      params.orgLevelId = orgLevelId;
    }
    this.router.navigate(['approval_required_codes'], {
      relativeTo: this.route.pathFromRoot[2],
      queryParams: params
    });
  }
}
