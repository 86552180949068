import { Component, Provider } from '@angular/core';
import { ConfirmOptions } from '../confirm-dialog/confirm-dialog.component';
import { DialogOptions2 } from '../../models/index';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  moduleId: module.id,
  selector: 'slx-confirm-dialog2',
  templateUrl: 'confirm-dialog2.component.html',
  styleUrls: ['confirm-dialog2.component.scss']
})
export class ConfirmDialog2Component {

  public options: DialogOptions2;
  public dialogResult: boolean;
  public confirmOptions: ConfirmOptions;
  private modalService: ModalService;
  public get okButtonText(): string {
    if (!this.confirmOptions || !this.confirmOptions.buttonOKtext) {
      return 'OK';
    }
    return this.confirmOptions.buttonOKtext;
  }
  public get cancelButtonText(): string {
    if (!this.confirmOptions || !this.confirmOptions.buttonCanceltext) {
      return 'Cancel';
    }
    return this.confirmOptions.buttonCanceltext;
  }


  public static openDialog(
    title: string,
    message: string,
    modalService: ModalService,
    callback: (result: boolean) => void, options?: ConfirmOptions): ConfirmDialog2Component {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.topmost = true;
    dialogOptions.zIndexForTopMost = 102;
    dialogOptions.message = message;
    let confirmOptions: ConfirmOptions = options;
    if (!options) {
      confirmOptions = new ConfirmOptions();
      confirmOptions.showOK = true;
      confirmOptions.showCancel = true;
    }
    dialogOptions.width = confirmOptions.width || 350;
    dialogOptions.height = confirmOptions.height || 200;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: ConfirmOptions,
        useValue: confirmOptions
      }
    ];
    let dialog: ConfirmDialog2Component = modalService.globalAnchor
      .openDialog2(ConfirmDialog2Component, title, dialogOptions, resolvedProviders, callback);
    return dialog;
  }
   
  constructor(options: DialogOptions2, modalService: ModalService, confirmOptions: ConfirmOptions) {
    this.options = options;
    this.modalService = modalService;
    this.confirmOptions = confirmOptions;
    this.dialogResult = false;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId); 
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
