import * as tslib_1 from "tslib";
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ManagementBaseService } from '../../../core/services/index';
import { LookupApiService } from '../../../organization/services/index';
import { PayCycle } from '../../../organization/models/index';
import { PunchesApiService } from './punches-api.service';
import { PunchesMapService } from './punches-map.service';
import { LinePunch, EmployeeDailyPunchesStatus, EmployeeDailyPunchesState, EmployeesPunchesHeaderContainer, EmployeesPunchesContainer, EmployeeDailyPunches, PunchesDisplaySettings, DailyPunchesShift, PunchesEventFilter, PunchStatus } from '../../models/index';
import { PunchesFilterHelper } from './punches-filter-helper';
import { ConstraintsApiService } from '../../../organization/services/index';
import { debounce } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common/services/index';
import { StateResetTypes } from '../../../core/models/index';
var PunchesManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PunchesManagementService, _super);
    function PunchesManagementService(apiService, mapService, lookupApiService, constraintsApiService, stateManagement) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.mapService = mapService;
        _this.lookupApiService = lookupApiService;
        _this.constraintsApiService = constraintsApiService;
        _this.stateManagement = stateManagement;
        _this.filterControlKey = 'EmployeePunchesFilters';
        _this.onSaveAllChanges$ = new Subject();
        _this.onSavedEmpPunches$ = new Subject();
        _this.onFilterApplied$ = new Subject();
        _this.onFilterSet$ = new ReplaySubject();
        _this.onOrgLevelLoaded$ = new ReplaySubject(1);
        _this.onLoadedHeaderPunches$ = new Subject();
        _this.onLoadHeaderPunchesStatus$ = new ReplaySubject();
        _this.saveFilters$ = new Subject();
        _this.isPaidRestBreakEnabled$ = new ReplaySubject(1);
        _this.orgLevelSubscription = _this.orgLevel$
            .filter(function (o) { return !_this.currentOrgLevel || o && _this.currentOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            if (!o || !o.id) {
                return;
            }
            _this.onOrgLevelChanged(o);
        });
        return _this;
    }
    PunchesManagementService.prototype.setOnlyMissingNextFilter = function () {
        this.onlyMissingNextFilter = true;
    };
    PunchesManagementService.prototype.saveFilters = function () {
        this.saveFilters$.next();
    };
    PunchesManagementService.prototype.setOnlyMissingFilter = function (filter) {
        filter.empInvalidPunches = false;
        filter.empMissingPunches = true;
        filter.empNoPunches = false;
        filter.empScheduleOnly = false;
        filter.empValid = false;
        filter.empRequest = false;
    };
    Object.defineProperty(PunchesManagementService.prototype, "empRequestFilterSelected", {
        get: function () {
            return this.filter.empRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchesManagementService.prototype, "empMissingPunchesFilterSelected", {
        get: function () {
            return this.filter.empMissingPunches;
        },
        enumerable: true,
        configurable: true
    });
    PunchesManagementService.prototype.loadOrgLevelPunches = function (orgLevelId, startDate, endDate) {
        var _this = this;
        this.startDate = startDate;
        this.endDate = endDate;
        this.onLoadStatusChanged(true);
        this.apiService.getOrgLevelDailyPunchesHeader(orgLevelId, startDate, endDate)
            .then(function (headersContainer) {
            var container = _this.mapService.transformContainer(headersContainer);
            container.filteredEntities = _this.filterPunches(container.entities, _this.filter);
            _this.onLoaded(container);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    PunchesManagementService.prototype.loadPunches = function (empPunches) {
        var _this = this;
        this.onLoadHeaderPunchesStatus$.next({ value: true, employeeId: empPunches.header.employee.id, date: empPunches.header.date });
        this.apiService.getOrgLevelLinePunches(this.currentOrgLevel.id, empPunches.header.date, empPunches.header.employee.id)
            .then(function (p) {
            empPunches.isLocked = p.header && (p.header.isTimecardLocked || p.header.isPayCycleLocked || p.header.isOrganizationPayrollLocked || p.header.isTimecardApproved);
            _this.isPaidRestBreakEnabled$.next(p.header.isPaidRestBreakEnabled);
            if (empPunches.header && p.header) {
                empPunches.header.comment = p.header.comment;
                empPunches.header.hasLicenseRestriction = p.header.hasLicenseRestriction;
                empPunches.header.isPaidRestBreakEnabled = p.header.isPaidRestBreakEnabled;
            }
            empPunches.punches = p.punches;
            var sorted = _.orderBy(p.punches, function (p) { return moment(p.time).unix; }, 'asc');
            empPunches.firstIn = _.find(sorted, function (p) { return p.type && (p.punchStatus === PunchStatus.Valid || p.punchStatus === PunchStatus.Edited) && p.type.isIn; });
            empPunches.lastOut = _.findLast(sorted, function (p) { return p.type && (p.punchStatus === PunchStatus.Valid || p.punchStatus === PunchStatus.Edited) && p.type.isOut; });
            _this.constraintsApiService.isPayrollSubmittedForEmployee(empPunches.header.employee.id, empPunches.header.date, empPunches.header.date)
                .then(function (res) {
                empPunches.isPayrollCycleSubmitted = res;
                _this.onLoadedHeaderPunches$.next(empPunches);
                _this.onLoadHeaderPunchesStatus$.next({ value: false, employeeId: empPunches.header.employee.id, date: empPunches.header.date });
            });
        })
            .catch(function (reason) {
            _this.onLoadHeaderPunchesStatus$.next({ value: false, employeeId: empPunches.header.employee.id, date: empPunches.header.date });
        });
    };
    PunchesManagementService.prototype.saveAllChanges = function () {
        this.onSaveAllChanges$.next();
    };
    PunchesManagementService.prototype.saveEmployeePunches = function (empPunchesList, container) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.saveDailyPunches(this.currentOrgLevel.id, empPunchesList)
            .then(function (res) {
            if (res.length === 0 && empPunchesList.length === 1) {
                _.remove(container.entities, function (edp) { return edp.header === empPunchesList[0].header; });
            }
            _this.onSavedEmpPunches$.next(res);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    PunchesManagementService.prototype.applyFilter = function (container, filter) {
        if (filter.hasOwnProperty('filter')) {
            this.filter = filter.filter;
        }
        else {
            this.filter = filter;
        }
        this.setFilter(this.filter);
        if (container) {
            container.filteredEntities = this.filterPunches(container.entities, this.filter);
            this.onFilterApplied$.next(container.filteredEntities);
        }
    };
    PunchesManagementService.prototype.applyCurrentFilter = function (container) {
        this.filter = this.restoreFilters(this.filter);
        if (container) {
            container.filteredEntities = this.filterPunches(container.entities, this.filter);
            this.onFilterApplied$.next(container.filteredEntities);
        }
    };
    PunchesManagementService.prototype.setFilter = function (filter) {
        if (!this.filter) {
            this.filter = filter;
        }
        this.onFilterSet$.next(this.filter);
        this.stateManagement.setControlState(this.filterControlKey, {
            value: this.filter
        }, StateResetTypes.None);
    };
    PunchesManagementService.prototype.filterPunches = function (entities, filter) {
        if (!filter) {
            return entities;
        }
        if (this.onlyMissingNextFilter) {
            this.setOnlyMissingFilter(filter);
            this.onlyMissingNextFilter = false;
            this.saveFilters();
        }
        var filteredList = entities;
        if (_.isObject(filter.shiftFilter) && !_.isArray(filter.shiftFilter)) {
            var shiftsFilter_1 = filter.shiftFilter[this.currentOrgLevel.id];
            if (_.isArray(shiftsFilter_1) && _.size(shiftsFilter_1) > 0) {
                filteredList = _.filter(filteredList, function (record) {
                    var res = _.filter(record.header.scheduledShifts, function (ds) { return _.includes(shiftsFilter_1, ds.shift.id); });
                    return _.size(res) > 0;
                });
            }
        }
        if (_.isObject(filter.positionFilter) && !_.isArray(filter.positionFilter)) {
            var posFilter_1 = filter.positionFilter[this.currentOrgLevel.id];
            if (_.isArray(posFilter_1) && _.size(posFilter_1) > 0) {
                filteredList = _.filter(filteredList, function (record) {
                    return _.includes(posFilter_1, record.header.position.id);
                });
            }
        }
        filteredList = _.filter(filteredList, function (record) {
            var res = false;
            res = res || (filter.empMissingPunches && (record.header.state & EmployeeDailyPunchesState.missing) === EmployeeDailyPunchesState.missing);
            res = res || (filter.empInvalidPunches && (record.header.state & EmployeeDailyPunchesState.invalid) === EmployeeDailyPunchesState.invalid);
            res = res || (filter.empScheduleOnly && (record.header.state & EmployeeDailyPunchesState.scheduledonly) === EmployeeDailyPunchesState.scheduledonly);
            res = res || (filter.empValid && record.header.status === EmployeeDailyPunchesStatus.valid);
            res = res || (filter.empNoPunches && (record.header.state & EmployeeDailyPunchesState.nopunches) === EmployeeDailyPunchesState.nopunches);
            res = res || (filter.empRequest && (record.header.state & EmployeeDailyPunchesState.emprequest) === EmployeeDailyPunchesState.emprequest);
            res = res || (filter.invalidLogin && (record.header.state & EmployeeDailyPunchesState.invalidlogin) === EmployeeDailyPunchesState.invalidlogin);
            return res;
        });
        var filterHelper = new PunchesFilterHelper();
        _.forEach(filteredList, function (record) {
            filterHelper.filter(record.punches, filter.eventFilter);
        });
        /*
        filteredList = _.filter(filteredList, (record: EmployeeDailyPunches) => {
          let showedPunch: LinePunch = _.find(record.punches, (punch: LinePunch) => punch.show);
          return !!showedPunch;
        });*/
        return filteredList;
    };
    PunchesManagementService.prototype.restoreFilters = function (filter) {
        var state = this.stateManagement.getControlState(this.filterControlKey);
        if (state && state.value !== undefined) {
            filter = state.value;
            this.restoreEventFilters(filter);
            return filter;
        }
        if (filter === undefined || !filter) {
            filter = new PunchesDisplaySettings();
            filter.empMissingPunches = true;
            filter.empInvalidPunches = true;
            filter.empScheduleOnly = true;
            filter.empValid = true;
            filter.empNoPunches = true;
        }
        this.restoreEventFilters(filter);
        return filter;
    };
    PunchesManagementService.prototype.restoreEventFilters = function (filters) {
        if (!filters.eventFilter) {
            filters.eventFilter = new PunchesEventFilter();
            filters.eventFilter.empPunch = true;
            filters.eventFilter.editPunch = true;
            filters.eventFilter.essRequest = true;
            filters.eventFilter.invalidPunch = true;
            filters.eventFilter.invalidLogin = true;
            filters.eventFilter.schedule = true;
            filters.eventFilter.deleted = false;
        }
    };
    PunchesManagementService.prototype.onOrgLevelChanged = function (o) {
        if (this.currentOrgLevel && this.currentOrgLevel.id === o.id) {
            return;
        }
        this.currentOrgLevel = o;
        this.onOrgLevelLoaded$.next(o);
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PunchesManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementService.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        debounce(1000),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Number, Date, Date]),
        tslib_1.__metadata("design:returntype", void 0)
    ], PunchesManagementService.prototype, "loadOrgLevelPunches", null);
    return PunchesManagementService;
}(ManagementBaseService));
export { PunchesManagementService };
