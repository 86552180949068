import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { ControlStateKey, StateResetTypes } from '../../../core/models/index';
import { ComponentStateStorageService, StateManagementService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';
var ControlLoadedStateDirective = /** @class */ (function () {
    function ControlLoadedStateDirective(ngControl, storageService, stateManagement) {
        var _this = this;
        this.ngControl = ngControl;
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.accessor = ngControl.valueAccessor;
        this.m_resetType = StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.loadedSubscription = this.stateManagement.loadedData$.subscribe(function (key) {
            _this.switchState(key);
        });
    }
    Object.defineProperty(ControlLoadedStateDirective.prototype, "resetType", {
        set: function (type) {
            if (!type) {
                return;
            }
            this.m_resetType = type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ControlLoadedStateDirective.prototype, "controlId", {
        get: function () {
            return this.m_controlId;
        },
        enumerable: true,
        configurable: true
    });
    ControlLoadedStateDirective.prototype.ngOnInit = function () {
        this.m_controlId = this.ngControl.name;
        if (!this.m_controlId) {
            throw Error('State directive can be used only with control which has a name');
        }
    };
    ControlLoadedStateDirective.prototype.valueChanged = function (val) {
        this.storageService.mapValueToState(val, this.state);
        this.storageService.setControlState(this.stateManagement.componentKey, this.controlId, this.state, this.m_resetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.controlId);
    };
    ControlLoadedStateDirective.prototype.loadState = function () {
        var _this = this;
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
        this.state = this.storageService.getControlState(this.stateManagement.componentKey, this.controlId, this.stateKey);
        if (this.state.value !== undefined) {
            var value = this.storageService.mapValueFromState(this.state);
            this.accessor.writeValue(value);
            this.ngControl.viewToModelUpdate(value);
        }
        this.valueChangesSubscription = this.ngControl.valueChanges.subscribe(function (x) {
            _this.valueChanged(x);
        });
    };
    ControlLoadedStateDirective.prototype.switchState = function (context) {
        this.stateKey = new ControlStateKey(context);
        this.loadState();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ControlLoadedStateDirective.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ControlLoadedStateDirective.prototype, "valueChangesSubscription", void 0);
    return ControlLoadedStateDirective;
}());
export { ControlLoadedStateDirective };
