import { NotificationEventType, INotificationEventType } from "./notifications-event-type";

export interface INotificationGroupEvent {
    eventGroupId: number;
    name: string;
    description: string;
    events: INotificationEventType [];
}
export class NotificationGroupEvent {
    public eventGroupId: number;
    public name: string;
    description: string;
    events: NotificationEventType [];
}