import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService, CacheUtilService } from '../../../common/services/index';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { configurationConfig } from '../../configuration.config';

import { CostCentersConfigurationContainer, CostCenterModel, ICostCenterDTO } from '../../models/index';
import { CostCentersMapService } from './cost-centers-map.service';
import { Meta, FieldsMeta, ResponseBody } from '../../../core/models/index';


@Injectable()
export class CostCentersApiService {

    constructor(private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private mapService: CostCentersMapService
    ) {
    }

    public getRecords(orgLevelId: number): Promise<CostCentersConfigurationContainer> {
        const url: string = this.getCostCentersRoot(orgLevelId);
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService.request<ICostCenterDTO[], Meta>(request).then((response: ResponseBody<ICostCenterDTO[], Meta>) => {
            let fieldsMeta: FieldsMeta = response.meta as FieldsMeta;
            let container: CostCentersConfigurationContainer = this.mapService.mapToContainer(response.data, fieldsMeta);
            return container;
        });
    }

    public updateRecords(centers: CostCenterModel[]): Promise<CostCenterModel[]> {
        const url: string = `${this.getCostCentersRoot()}`;
        const body: any = {
            list: this.mapService.mapToDtos(centers)
        };
        let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, body);
        return this.apiUtilService.request<ICostCenterDTO[], Meta>(request)
            .then((response: ResponseBody<ICostCenterDTO[], Meta>) => {
                return this.mapService.mapToModels(response.data, null);
            });
    }

    public updateRecord(center: CostCenterModel): Promise<CostCenterModel> {
        const url: string = this.getCostCentersRoot();
        const body: any = this.mapService.mapToDto(center);
        let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, body);
        return this.apiUtilService.request<ICostCenterDTO, Meta>(request)
            .then((response: ResponseBody<ICostCenterDTO, Meta>) => {
                return this.mapService.mapToModel(response.data, null);
            });
    }

    public createRecord(center: CostCenterModel): Promise<CostCenterModel> {
        const url: string = this.getCostCentersRoot();
        const body: any = this.mapService.mapToDto(center);
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
        return this.apiUtilService.request<ICostCenterDTO, Meta>(request)
            .then((response: ResponseBody<ICostCenterDTO, Meta>) => {
                return this.mapService.mapToModel(response.data, null);
            });
    }

    public deleteRecord(recordId: number): Promise<any> {
        const url: string = `${this.getCostCentersRoot()}/${recordId}`;
        let request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url);
        return this.apiUtilService.request<any[], Meta>(request);
    }

    private getCostCentersRoot(orgLevelId?: number): string {
        if (orgLevelId !== undefined) {
            return `${this.getConfigurationApiRoot()}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.costCenters.root}`;
        }
        return `${this.getConfigurationApiRoot()}/${configurationConfig.api.configuration.costCenters.root}`;
    }

    private getConfigurationApiRoot(): string {
        return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}`;
    }

    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }

}
