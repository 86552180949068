import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { pmConfig } from '../performance-management.config';
import { ReadFile } from '../../../organization/models/index';
import { PmFile } from '../models/index';
import { SessionService } from '../../../core/services/session/session.service';
var PmAttachmentManagementService = /** @class */ (function () {
    function PmAttachmentManagementService(sessionService) {
        this.sessionService = sessionService;
        this.acceptedFileTypes = pmConfig.files.acceptedTypes.concat();
        this.fileTypesInputFormat = pmConfig.files.acceptedTypes.join(',');
        this.fileTypesReadable = pmConfig.files.acceptedTypes.join(', ');
        this.mFileSize = this.getReadableBytes(this.maxFileSizeBytes);
    }
    Object.defineProperty(PmAttachmentManagementService.prototype, "maxFiles", {
        get: function () {
            return pmConfig.files.maxFiles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmAttachmentManagementService.prototype, "maxFileSizeBytes", {
        get: function () {
            return pmConfig.files.maxFileSizeBytes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmAttachmentManagementService.prototype, "acceptedFileTypesInputFormat", {
        get: function () {
            return this.fileTypesInputFormat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmAttachmentManagementService.prototype, "acceptedFileTypesReadable", {
        get: function () {
            return this.fileTypesReadable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmAttachmentManagementService.prototype, "maxFileSize", {
        get: function () {
            return this.mFileSize;
        },
        enumerable: true,
        configurable: true
    });
    PmAttachmentManagementService.prototype.mapToFiles = function (files) {
        var userName = this.getUserName();
        return _.map(files, function (f) { return new PmFile(f, userName); });
    };
    PmAttachmentManagementService.prototype.readAddedFiles = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promises, readedFiles, binaryData, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promises = _.map(files, function (f) { return _this.readFileData(f); });
                        readedFiles = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2:
                        binaryData = _a.sent();
                        readedFiles = this.mapDateToReadFiles(files, binaryData);
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 5];
                    case 4: return [2 /*return*/, readedFiles];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PmAttachmentManagementService.prototype.readFileData = function (file) {
        return new Promise(function (resolve, reject) {
            var fr = new FileReader();
            fr.onloadend = function () {
                var buffer = fr.result;
                resolve(buffer);
            };
            fr.onerror = function () {
                reject(fr.error);
            };
            fr.readAsArrayBuffer(file);
        });
    };
    PmAttachmentManagementService.prototype.mapDateToReadFiles = function (files, binaryData) {
        return _.map(files, function (file, i) {
            var index = file.name.lastIndexOf('.');
            var name = file.name.slice(0, index);
            var ext = file.name.slice(index + 1);
            var array = binaryData[i];
            var data = new Blob([array]);
            return new ReadFile(name, file.size, file.type, ext, data);
        });
    };
    PmAttachmentManagementService.prototype.resetInput = function (elem) {
        elem.value = '';
    };
    PmAttachmentManagementService.prototype.getUserName = function () {
        var user = this.sessionService.getUser();
        return user.username;
    };
    PmAttachmentManagementService.prototype.validateFiles = function (fileList, event) {
        var errors = {};
        var files = [];
        if (fileList.length > this.maxFiles) {
            errors.maxFiles = true;
        }
        for (var i = 0, length_1 = fileList.length; i < length_1; i++) {
            var file = fileList.item(i);
            if (file.size > this.maxFileSizeBytes) {
                errors.maxFileSize = true;
                break;
            }
            var fileExt = "." + _.last(file.name.split('.'));
            fileExt = fileExt.toLowerCase();
            if (!_.includes(this.acceptedFileTypes, fileExt)) {
                errors.fileType = true;
                break;
            }
            files.push(file);
        }
        this.resetInput(event.target);
        if (_.size(errors) > 0) {
            files.length = 0;
        }
        return { files: files, errors: errors };
    };
    PmAttachmentManagementService.prototype.getReadableBytes = function (bytes) {
        var i = Math.floor(Math.log(bytes) / Math.log(1024));
        var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var res = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
        return res * 1 + " " + sizes[i];
    };
    return PmAttachmentManagementService;
}());
export { PmAttachmentManagementService };
