import { appConfig } from '../../app.config';

export interface IScreenUtils {
  isMobile: boolean;
  getKendoMediaForDesktop(): string;
  getKendoMediaForMobile(): string;
}
export const screenUtils: IScreenUtils = {
  get isMobile(): boolean {
    return screen.width <= appConfig.mobileMaxWidth;
  },
  getKendoMediaForDesktop(): string {
    return `(min-width: ${appConfig.mobileMaxWidth + 1}px)`;
  },
  getKendoMediaForMobile(): string {
    return `(max-width: ${appConfig.mobileMaxWidth}px)`;
  }
};
