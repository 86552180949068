import { Injectable } from '@angular/core';
import * as moment from 'moment';

import {
  IShiftDiffPolicy,
  ShiftDiffPolicy,
  IOrganization,
  Organization,
  IPosition,
  Position,
  IPayPolicy,
  PayPolicy,
  IExemptStatus,
  ExemptStatus,
  IEmployeeDefinition,
  EmployeeDefinition,
  ScheduledShiftDefinition,
  IScheduledShiftDefinition,
} from '../../../organization/models/index';
import { IDailyPayRule, DailyPayRule } from './daily-pay-rule';
import { IDailyAbsence, DailyAbsence, DailyAllAbsence, IDailyAllAbsence } from './daily-absence';
import { LinePunch, ILinePunch } from '../punches/index';
import { IEarningStatistic, EarningStatistic } from './earning-statistic';

export interface ITimeCardModel {
  id: number;
  date: string;
  employee: IEmployeeDefinition;
  exemptStatus: IExemptStatus;
  payPolicy: IPayPolicy;
  shiftDifferentialPolicy: IShiftDiffPolicy;
  primaryPosition: IPosition;
  lastUpdateDate: string;
  lastUpdateUsername: string;
  schedule: IScheduledShiftDefinition[];
  payRules: IDailyPayRule[];
  absences: IDailyAbsence[];
  allabsences: IDailyAllAbsence[];
  dailyPunches: ILinePunch[];
  punchDisplayTags: string[];
  comment: string;
  earningStatistics: IEarningStatistic[];
  minPunchDate: string;
  maxPunchDate: string;
  isApproved: boolean;
  canDebug: boolean;
  canApprove: boolean;
  canUnapprove: boolean;
  canEdit: boolean;
  isOutOfPaycycle: boolean;
  unApprovedPayCodesCount: number;
  unApprovedOTCount: number;
  isPayrollCycleSubmitted: boolean;
  isLocked: boolean;
  isPayrollCycleLocked: boolean;
  isOrganizationPayrollLocked: boolean;
  canUnlock: boolean;
  canLock: boolean;
  shiftDiffBasedOnTime: boolean;
  checkLicenseRestriction: number;
  canEditSchedule: boolean;
  isPaidRestBreakEnabled:boolean;
}

export enum TimeCardDisplayOptions {
  showIntervalsInDecimal = 'number',
  showIntervalsInTime = 'hmstring',
}

export class TimeCardModel {
  public id: number;
  public date: Date;
  public employee: EmployeeDefinition;
  public exemptStatus: ExemptStatus;
  public payPolicy: PayPolicy;
  public shiftDifferentialPolicy: ShiftDiffPolicy;
  public primaryPosition: Position;
  public lastUpdateDate: Date;
  public lastUpdateUsername: string;
  public schedule: ScheduledShiftDefinition[];
  public payRules: DailyPayRule[];
  public absences: DailyAbsence[];
  public allabsences: DailyAllAbsence[];
  public dailyPunches: LinePunch[];
  public punchDisplayTags: string[];
  public comment: string;
  public earningStatistics: EarningStatistic[];
  public minPunchDate: Date;
  public maxPunchDate: Date;
  public isApproved: boolean;
  public canDebug: boolean;
  public canApprove: boolean;
  public canUnapprove: boolean;
  public canEdit: boolean;
  public isOutOfPaycycle: boolean;
  public unApprovedPayCodesCount: number;
  public unApprovedOTCount: number;
  public isPayrollCycleSubmitted: boolean;
  public isLocked: boolean;
  public isPayrollCycleLocked: boolean;
  public isOrganizationPayrollLocked: boolean;
  public canUnlock: boolean;
  public canLock: boolean;
  public shiftDiffBasedOnTime: boolean;
  public hasLicenseRestriction: boolean;

  public isLoadedApproved: boolean;

  public rawData: ITimeCardModel;

  public terminationDate: Date;
  public transferDepartmentId: number;
  public activationDate: Date;

  public hasMissingPunches: boolean;
  public hasMissingPunchesAlert: boolean;
  public recalculated: boolean;
  public canModifyOwnTimecard: boolean;
  public canEditOwnTimecard: boolean;
  public canEditSchedule: boolean;
  public isPaidRestBreakEnabled:boolean;
  
  public get canEditTimecard(): boolean {
    return (
      this.canEdit &&
      !this.isLoadedApproved &&
      !this.afterTermination &&
      !this.beforeHire &&
      !this.isOutOfPaycycle &&
      !this.isPayrollCycleSubmitted &&
      !this.locked &&
      !this.hasLicenseRestriction
    );
  }

  public get locked(): boolean {
    return this.isLocked || this.isPayrollCycleLocked || this.isOrganizationPayrollLocked;
  }

  public get afterTermination(): boolean {
    return this.terminationDate && moment(this.date).isAfter(this.terminationDate);
  }

  public get afterTransfer(): boolean {
    return this.terminationDate && moment(this.date).isAfter(this.terminationDate) && this.transferDepartmentId > 0;
  }

  public get beforeHire(): boolean {
    const maxValidationDate = this.employee.dateActivation ? this.employee.dateActivation : this.employee.dateHired;
    return maxValidationDate && moment(this.date).isBefore(maxValidationDate);
  }
}
