import { Component, Input, OnInit, Provider } from '@angular/core';
import { DialogOptions, IDialog, ModalService } from './../../../../../../app/common';
import { TimeClock } from './../../../../../../app/time-and-attendance/models';

@Component({
  selector: 'slx-timeclock-reboot-dialog',
  templateUrl: './timeclock-reboot-dialog.component.html',
  styleUrls: ['./timeclock-reboot-dialog.component.scss']
})
export class TimeclockRebootDialogComponent implements IDialog, OnInit {

  public dialogResult: boolean;
  public timeClock: TimeClock;
  public modalService: ModalService;
  public dialogOption: DialogOptions;

  constructor(timeClock: TimeClock, modalService: ModalService,dialogOptions: DialogOptions) {
    this.timeClock = timeClock;
    this.modalService = modalService;
    this.dialogOption = dialogOptions;
  }

  ngOnInit() {
  }

  public static openDialog(timeClock: TimeClock, modalService: ModalService, callback: (result: boolean, timeClock: TimeClock) => void): TimeclockRebootDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 500;
    dialogOptions.height = 250;
    dialogOptions.className = 'slx-timeclock-reboot-dialog-custom';
    dialogOptions.showCloseButton = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: TimeClock,
        useValue: timeClock
      }
    ];
    let dialog: TimeclockRebootDialogComponent = modalService.globalAnchor
      .openDialog(TimeclockRebootDialogComponent, 'Clock Restart', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, timeClock);
      });
      
    return dialog;
  }


  save() {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOption.windowUniqueId);
  }

  cancel() {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOption.windowUniqueId);
  }

}
