import { RolesAccess } from './role-access-definition';

export interface IRoleField {
  id: number;
  name: string;
  displayName: string;
  hidden: boolean;
  editable: boolean;
  masked: boolean;
  maskRules: string;
}

export class RoleField {

  public id: number;
  public name: string;
  public displayName: string;
  public hidden: boolean;
  public editable: boolean;
  public masked: boolean;
  public maskRules: string;
  public access: RolesAccess;

  public get isDirty(): boolean {
    return this.initial_hidden !== this.hidden ||
      this.initial_editable !== this.editable ||
      this.initial_masked !== this.masked;
  }

  private initial_hidden: boolean;
  private initial_editable: boolean;
  private initial_masked: boolean;

  public setInitialState(): void {
    this.initial_hidden = this.hidden;
    this.initial_editable = this.editable;
    this.initial_masked = this.masked;
  }
}
