import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { process, orderBy } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { unsubscribe } from '../../../../../core/decorators/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSectionsAccrualsApiService } from '../../../services/index';
import { EmployeeSectionsPolicyAssignments, EmployeeSectionsBase, EmployeePolicyAssignment, EndingBalanceDialogOptions, AccrualPolicy, DynamicAccrualPolicy } from '../../../models';
import { Assert } from '../../../../../framework/index';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper, ModalService, ConfirmOptions, ConfirmDialogComponent, DialogOptions } from '../../../../../common/index';
import { EndingBalanceDialogComponent } from './ending-balance-dialog/ending-balance-dialog.component';
import { mutableSelect } from '../../../../../core/decorators/index';
import { DynamicPolicyDialogComponent } from './dynamic-policy-dialog/dynamic-policy-dialog.component';
var EmployeeSectionsPolicyAssignmentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPolicyAssignmentsComponent, _super);
    function EmployeeSectionsPolicyAssignmentsComponent(modalService, employeeSectionsAccrualsApiService, decorator, ngZone, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.modalService = modalService;
        _this.changeDetector = changeDetector;
        _this.sort = [];
        _this.accrualPolicyLookup = [];
        _this.minDate = new Date(1900, 1, 1);
        _this.now = moment().startOf('day').toDate();
        _this.dPolicyError = false;
        _this.dateError = false;
        _this.addCounter = 0;
        _this.decorator.isEditableByConfiguration = false;
        Assert.isNotNull(employeeSectionsAccrualsApiService, 'employeeSectionsAccrualsApiService');
        _this.employeeSectionsAccrualsApiService = employeeSectionsAccrualsApiService;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.skip = 0;
        _this.gridState.state.sort = [{ field: 'startDate', dir: 'desc' }];
        _this.gridState.state.take = 5;
        _this.createSubscriptions();
        return _this;
    }
    Object.defineProperty(EmployeeSectionsPolicyAssignmentsComponent.prototype, "policyAssignments", {
        set: function (employeeSectionsPolicyAssignments) {
            this.employeeSectionsPolicyAssignments = employeeSectionsPolicyAssignments;
            var canEdit = _.get(employeeSectionsPolicyAssignments, 'actions.canEdit', null);
            if (_.isBoolean(canEdit)) {
                this.canEdit = canEdit;
            }
            this.refreshGrid();
            this.updateLookups();
            this.originalPolicyAssignments = _.cloneDeep(employeeSectionsPolicyAssignments);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPolicyAssignmentsComponent.prototype, "employeeId", {
        set: function (value) {
            this.m_employeeId = value;
            this.updateLookups();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPolicyAssignmentsComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPolicyAssignmentsComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsPolicyAssignmentsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.checkAllowDynamicPolicy();
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.ngOnDestroy = function () {
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.checkAllowDynamicPolicy = function () {
        var _this = this;
        this.employeeSectionsAccrualsApiService.getAllowDynamicAccrualPolicies()
            .then(function (allowDynamicPolicy) {
            _this.allowDynamicPolicy = allowDynamicPolicy;
        });
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function (item) {
            _this.editingItem = item;
            _this.addMode = false;
        });
        this.gridCancelSubscription = this.gridState.onCancel$.subscribe(function (item) {
            _this.resetAddMode();
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (item) {
            _this.gridSaveSubscribe(item.dataItem, item.isNew);
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (item) {
            _this.gridRemoveSubscribe(item.dataItem);
        });
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.gridSaveSubscribe = function (item, isNew) {
        var _this = this;
        item.lastUpdatedDate = moment().startOf('day').toDate();
        var selPolicy = '';
        var policy = _.without(this.employeeSectionsPolicyAssignments.records, item);
        _.forEach(policy, function (el) {
            if (item.endDate) {
                if (el.startDate >= item.startDate && el.startDate <= item.endDate && el.endDate <= item.endDate && el.endDate != null) {
                    selPolicy = selPolicy == '' ? el.policyName : selPolicy + ', ' + el.policyName;
                }
            }
            else {
                if (el.startDate >= item.startDate) {
                    selPolicy = selPolicy == '' ? el.policyName : selPolicy + ', ' + el.policyName;
                }
            }
        });
        if (selPolicy != '') {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialogComponent.openDialog('Warning', 'These policy (' + selPolicy + ') will be deleted, Would you like to continue?', this.modalService, function (result) {
                if (result) {
                    _this.saveChanges(item, isNew);
                }
                else {
                    _this.resetPreviousDates(item);
                }
            }, options);
        }
        else {
            if (isNew) {
                var source = this.employeeSectionsPolicyAssignments.records;
                this.employeeSectionsPolicyAssignments.records = _.concat(source, [item]);
            }
            this.confirmSaveChanges(item);
        }
        this.resetAddMode();
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.resetPreviousDates = function (item) {
        if (item.id != 0) {
            var orgPA = _.find(this.originalPolicyAssignments.records, ['id', item.id]);
            item.startDate = new Date(orgPA.startDate);
            if (item.endDate) {
                item.endDate = new Date(orgPA.endDate);
            }
            this.sortChange(this.gridState.state.sort);
            this.changeDetected();
        }
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.resetAddMode = function () {
        this.addMode = false;
        this.editingItem = null;
        this.dateError = false;
        this.changeDetected();
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.saveChanges = function (item, isNew) {
        if (isNew) {
            var source = this.employeeSectionsPolicyAssignments.records;
            this.employeeSectionsPolicyAssignments.records = _.concat(source, [item]);
        }
        this.confirmSaveChanges(item);
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.changeDetected = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.gridRemoveSubscribe = function (item) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.notes = "Note that as a result of this transaction, \n    the policy name will be permanently associated with this employee\u2019s accrual transaction history.  \n    This will ensure completeness in the audit trail history for all policy deletions.";
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure that you want to remove this policy?', this.modalService, function (result) {
            if (result) {
                _this.doRemove(item);
                _this.changeDetected();
            }
        }, options);
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.confirmSaveChanges = function (item) {
        var _this = this;
        this.startProgress();
        item.empId = this.m_employeeId;
        if (!this.allowDynamicPolicy) {
            item.policyIdList = _.find(this.accrualPolicyLookup, ['policyName', item.policyName]).id.toString();
        }
        else {
            var policyNameList = _.split(item.policyName, '-');
            item.policyIdList = _.join(_.map(_(this.accrualPolicyLookup).keyBy('policyName').at(policyNameList).value(), 'id'), '-');
        }
        this.employeeSectionsAccrualsApiService.postAccrualsPolicyAssignments(this.m_employeeId, item)
            .then(function (employeeSectionsPolicyAssignments) {
            _this.stopProgress();
            _this.employeeSectionsPolicyAssignments = employeeSectionsPolicyAssignments;
            _this.originalPolicyAssignments = _.cloneDeep(employeeSectionsPolicyAssignments);
            _this.state.isLoaded = true;
            _this.gridState.state.skip = 0;
            _this.canEdit = _.get(_this.employeeSectionsPolicyAssignments, 'actions.canEdit', false);
            _this.refreshGrid();
            _this.changeDetected();
        }).catch(function (reason) {
            _this.stopProgress();
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.doRemove = function (item) {
        item.deleted = true;
        this.deletePolicyAssignment(item);
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.deletePolicyAssignment = function (assignment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var employeeSectionsPolicyAssignments, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.startProgress();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.employeeSectionsAccrualsApiService.deletePolicyAssignment(this.m_employeeId, assignment.id)];
                    case 2:
                        employeeSectionsPolicyAssignments = _a.sent();
                        this.employeeSectionsPolicyAssignments = employeeSectionsPolicyAssignments;
                        this.originalPolicyAssignments = _.cloneDeep(employeeSectionsPolicyAssignments);
                        this.state.isLoaded = true;
                        this.gridState.state.skip = 0;
                        this.canEdit = _.get(this.employeeSectionsPolicyAssignments, 'actions.canEdit', false);
                        this.refreshGrid();
                        this.changeDetected();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.onActionError(error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.stopProgress();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.minDateLimit = function (item) {
        if (_.isNil(item)) {
            return this.minDate;
        }
        if (this.employeeShortInfo && this.employeeShortInfo.dateHired) {
            return this.employeeShortInfo.dateHired;
        }
        return this.now;
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsPolicyAssignments;
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsAccrualsApiService.getAccrualsPolicyAssignments(this.m_employeeId)
            .then(function (employeeSectionsPolicyAssignments) {
            _this.employeeSectionsPolicyAssignments = employeeSectionsPolicyAssignments;
            _this.state.isLoaded = true;
            _this.gridState.state.skip = 0;
            _this.canEdit = _.get(_this.employeeSectionsPolicyAssignments, 'actions.canEdit', false);
            _this.refreshGrid();
            _this.originalPolicyAssignments = _.cloneDeep(employeeSectionsPolicyAssignments);
            _this.stopProgress();
            _this.changeDetected();
        }).catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.sortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.pageChange = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.refreshGrid = function () {
        if (!this.employeeSectionsPolicyAssignments) {
            this.gridView = null;
            return;
        }
        var filteredRecords = this.employeeSectionsPolicyAssignments.records;
        var sortedRecords = orderBy(filteredRecords, this.sort);
        var pagedRecords = sortedRecords.slice(this.gridState.state.skip, this.gridState.state.skip + this.gridState.state.take);
        this.gridView = {
            data: pagedRecords,
            total: sortedRecords.length
        };
        this.gridState.view = process(this.employeeSectionsPolicyAssignments.records, this.gridState.state);
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.onShowEndingBalance = function (item) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsAccrualsApiService.getAccrualsPolicyBalance(this.m_employeeId, item.id)
            .then(function (accrualBalance) {
            EndingBalanceDialogComponent.openDialog(accrualBalance, _this.modalService);
            _this.stopProgress();
            _this.changeDetected();
        }).catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.isEndDateRequired = function (item) {
        var isRequired = false;
        var employeePolicyAssignment = _.find(this.originalPolicyAssignments.records, ['id', item.id]);
        if (!_.isNull(employeePolicyAssignment.endDate)) {
            isRequired = true;
        }
        return isRequired;
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.updateLookups = function () {
        var _this = this;
        if (this.m_employeeId && this.employeeSectionsPolicyAssignments) {
            this.employeeSectionsAccrualsApiService.getAccrualsPolicyList(this.m_employeeId, this.now.toDateString())
                .then(function (accrualPolicy) {
                _this.accrualPolicyLookup = accrualPolicy;
            });
        }
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.onAddNew = function () {
        this.addMode = true;
        this.grid.addRow(this.addNewRow());
        if (this.allowDynamicPolicy) {
            this.dPolicyError = true;
        }
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.addNewRow = function () {
        var newEmpPolicyAssignments = new EmployeePolicyAssignment();
        newEmpPolicyAssignments.startDate = moment().startOf('day').toDate();
        newEmpPolicyAssignments.endDate = null;
        newEmpPolicyAssignments.id = 0;
        newEmpPolicyAssignments.policyIdList = "";
        newEmpPolicyAssignments.policyName = "";
        return newEmpPolicyAssignments;
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.openDynamicPolicyDialog = function (item) {
        var _this = this;
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 450;
        dialogOptions.width = 350;
        var dialog = this.modalService.globalAnchor.openDialog(DynamicPolicyDialogComponent, '', dialogOptions, undefined, function (result) {
            if (result) {
                item.policyIdList = dialog.SelectedPolicyIds;
                item.policyName = dialog.SelectedPolicyNames;
                if (dialog.SelectedPolicyIds != '') {
                    _this.dPolicyError = false;
                }
                _this.changeDetected();
            }
        });
        dialog.employeeId = this.m_employeeId;
        dialog.dynamicAccrualPolicy = this.setDynamicAccrualPolicy(item);
        dialog.accrualPolicyLookup = this.accrualPolicyLookup;
        if (dialog.initialized) {
            dialog.addCounter = this.addCounter;
            if (dialog.dynamicAccrualPolicy && dialog.dynamicAccrualPolicy.length == 0) {
                dialog.onAddPolicy();
            }
        }
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.setDynamicAccrualPolicy = function (item) {
        var dAccrualPolicy = [];
        if (item.policyName != "") {
            if (item.policyName.indexOf("-") != -1) {
                var policyNameArr = item.policyName.split('-');
                if (policyNameArr && policyNameArr.length > 0) {
                    for (var i = 0; i < policyNameArr.length; i++) {
                        this.addCounter++;
                        var accrualPolicy = new DynamicAccrualPolicy();
                        accrualPolicy.id = this.addCounter;
                        accrualPolicy.policyName = policyNameArr[i].trim();
                        accrualPolicy.isError = false;
                        var acType = _.find(this.accrualPolicyLookup, ['policyName', policyNameArr[i].trim()]);
                        accrualPolicy.accrualType = _.first(acType.rules).accrualTypeName;
                        accrualPolicy.policyId = acType.id.toString();
                        dAccrualPolicy.push(accrualPolicy);
                    }
                }
            }
            else {
                var accrualPolicy = new DynamicAccrualPolicy();
                accrualPolicy.id = 1;
                accrualPolicy.policyName = item.policyName;
                accrualPolicy.isError = false;
                var acType = _.find(this.accrualPolicyLookup, ['policyName', item.policyName.trim()]);
                accrualPolicy.accrualType = _.first(acType.rules).accrualTypeName;
                accrualPolicy.policyId = acType.id.toString();
                dAccrualPolicy.push(accrualPolicy);
            }
        }
        return dAccrualPolicy;
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.onChangedDate = function (item) {
        var _this = this;
        if (this.employeeSectionsPolicyAssignments.effectiveDate) {
            var range = moment.range(item.startDate, this.employeeSectionsPolicyAssignments.effectiveDate);
            var dateDiff = range.diff('days');
            if (dateDiff > 0) {
                var message = '';
                if (item.id == 0) {
                    message = 'Assigning a new policy with start date prior to the last manual transaction date "' + moment(this.employeeSectionsPolicyAssignments.effectiveDate).format(appConfig.dateFormat) + '", may have an impact on accrual calculations. Would you like to continue?';
                }
                else {
                    message = 'Editing an existing policy with start date prior to the last manual transaction date "' + moment(this.employeeSectionsPolicyAssignments.effectiveDate).format(appConfig.dateFormat) + '", may have an impact on accrual calculations. Would you like to continue?';
                }
                var options = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                ConfirmDialogComponent.openDialog('Warning', message, this.modalService, function (result) {
                    if (result) {
                        _this.validateGapExists(item, true);
                    }
                    else {
                        _this.setPreviousDates(item);
                    }
                }, options);
            }
            else {
                this.validateGapExists(item, true);
            }
        }
        else {
            this.validateGapExists(item, true);
        }
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.setPreviousDates = function (item) {
        var orgPA = _.find(this.originalPolicyAssignments.records, ['id', item.id]);
        item.startDate = new Date(orgPA.startDate);
        if (item.endDate) {
            item.endDate = new Date(orgPA.endDate);
            if (item.startDate > item.endDate) {
                this.dateError = true;
            }
            else {
                this.dateError = false;
            }
        }
        this.refreshGrid();
        this.changeDetected();
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.onChangedEndDateEvent = function (item, endDate) {
        var date = moment(endDate, 'MM/DD/YYYY');
        if (date.isValid()) {
            item.endDate = new Date(endDate);
            this.validateGapExists(item, false);
        }
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.onChangedEndDate = function (item) {
        this.validateGapExists(item, false);
    };
    EmployeeSectionsPolicyAssignmentsComponent.prototype.validateGapExists = function (item, isStartDate) {
        var _this = this;
        this.dateError = false;
        if (item.endDate) {
            if (item.startDate > item.endDate) {
                this.dateError = true;
                return;
            }
        }
        var fromDate = new Date();
        var toDate = new Date();
        var currIndex = _.findIndex(this.employeeSectionsPolicyAssignments.records, ['id', item.id]);
        if (isStartDate) {
            fromDate = this.employeeSectionsPolicyAssignments.records[currIndex + 1].endDate;
            toDate = item.startDate;
        }
        else {
            fromDate = this.employeeSectionsPolicyAssignments.records[currIndex - 1].startDate;
            toDate = item.endDate;
        }
        fromDate = new Date(moment(fromDate).format(appConfig.dateFormat));
        toDate = new Date(moment(toDate).format(appConfig.dateFormat));
        var range;
        if (isStartDate)
            range = moment.range(fromDate, toDate);
        else
            range = moment.range(toDate, fromDate);
        var dateDiff = range.diff('days');
        if ((dateDiff > 1 && isStartDate) || (dateDiff > 1 && !isStartDate)) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            var message = '';
            if (isStartDate) {
                message = 'Please review your changes. A gap exists from(' + moment(fromDate).format(appConfig.dateFormat) + ') to (' + moment(toDate).format(appConfig.dateFormat) + '). Do you wish to continue?';
            }
            else {
                message = 'Please review your changes. A gap exists from(' + moment(toDate).format(appConfig.dateFormat) + ') to (' + moment(fromDate).format(appConfig.dateFormat) + '). Do you wish to continue?';
            }
            ConfirmDialogComponent.openDialog('Warning', message, this.modalService, function (result) {
                if (!result) {
                    _this.setPreviousDates(item);
                }
            }, options);
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeSectionsPolicyAssignmentsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsPolicyAssignmentsComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsPolicyAssignmentsComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsPolicyAssignmentsComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsPolicyAssignmentsComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsPolicyAssignmentsComponent.prototype, "gridCancelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsPolicyAssignmentsComponent.prototype, "formValueChangeSubscription", void 0);
    return EmployeeSectionsPolicyAssignmentsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsPolicyAssignmentsComponent };
