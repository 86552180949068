import * as _ from 'lodash';
import { ComparisonItem } from './comparison-item';
var Comparison = /** @class */ (function () {
    function Comparison() {
    }
    Object.defineProperty(Comparison.prototype, "uniqId", {
        get: function () {
            return [this.orgLevel.id,
                this.unit ? this.unit.id : 0,
                this.shiftGroup ? this.shiftGroup.id : 0
            ].join('_');
        },
        enumerable: true,
        configurable: true
    });
    Comparison.mergeComparisons = function () {
        var comparisons = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            comparisons[_i] = arguments[_i];
        }
        var mergedComparison = new Comparison();
        mergedComparison.pendingMessagesCount = 0;
        mergedComparison.absentCount = 0;
        mergedComparison.ptoRequests = 0;
        mergedComparison.overtimeEmployees = 0;
        mergedComparison.lateCount = 0;
        mergedComparison.idealPPD = new ComparisonItem(0, 0);
        mergedComparison.idealHours = new ComparisonItem(0, 0);
        mergedComparison.budgetedPPD = new ComparisonItem(0, 0);
        mergedComparison.budgetedHours = new ComparisonItem(0, 0);
        _.forEach(comparisons, function (comparison) {
            mergedComparison.date = comparison.date;
            mergedComparison.orgLevel = comparison.orgLevel;
            mergedComparison.lateCount += comparison.lateCount;
            mergedComparison.absentCount += comparison.absentCount;
            mergedComparison.ptoRequests = Math.max(comparison.ptoRequests, mergedComparison.ptoRequests);
            mergedComparison.overtimeEmployees += comparison.overtimeEmployees;
            mergedComparison.pendingMessagesCount += comparison.pendingMessagesCount;
            mergedComparison.idealPPD.limit += comparison.idealPPD.limit;
            mergedComparison.idealPPD.value += comparison.idealPPD.value;
            mergedComparison.idealHours.limit += comparison.idealHours.limit;
            mergedComparison.idealHours.value += comparison.idealHours.value;
            mergedComparison.budgetedPPD.limit += comparison.budgetedPPD.limit;
            mergedComparison.budgetedPPD.value += comparison.budgetedPPD.value;
            mergedComparison.budgetedHours.limit += comparison.budgetedHours.limit;
            mergedComparison.budgetedHours.value += comparison.budgetedHours.value;
        });
        return mergedComparison;
    };
    return Comparison;
}());
export { Comparison };
