/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./percent-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "angular2-text-mask";
import * as i4 from "../../directives/blur-forwarder/blur-forwarder.directive";
import * as i5 from "../../directives/focus-forwarder/focus-forwarder.directive";
import * as i6 from "./percent-input.component";
var styles_PercentInputComponent = [i0.styles];
var RenderType_PercentInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PercentInputComponent, data: {} });
export { RenderType_PercentInputComponent as RenderType_PercentInputComponent };
export function View_PercentInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "input", [["type", "text"]], [[1, "class", 0], [8, "readOnly", 0], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 2).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i1.ɵnov(_v, 2)._onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 7).onBlur($event) !== false);
        ad = (pd_6 && ad);
    } if (("focus" === en)) {
        var pd_7 = (i1.ɵnov(_v, 8).onFocus($event) !== false);
        ad = (pd_7 && ad);
    } if (("ngModelChange" === en)) {
        var pd_8 = ((_co.internalValue = $event) !== false);
        ad = (pd_8 && ad);
    } if (("ngModelChange" === en)) {
        var pd_9 = (_co.valueChanged($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(2, 81920, null, 0, i3.MaskedInputDirective, [i1.Renderer, i1.ElementRef], { textMaskConfig: [0, "textMaskConfig"] }, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i3.MaskedInputDirective]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(7, 16384, null, 0, i4.BlurForwarderDirective, [i1.ElementRef, i1.Renderer], null, null), i1.ɵdid(8, 16384, null, 0, i5.FocusForwarderDirective, [i1.ElementRef, i1.Renderer], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.percentMask; _ck(_v, 2, 0, currVal_10); var currVal_11 = _co.internalValue; _ck(_v, 4, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; var currVal_1 = (!_co.inEdit ? true : null); var currVal_2 = _co.placeholder; var currVal_3 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 6).ngClassValid; var currVal_8 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_PercentInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-percent-input", [], [[2, "edited", null]], [[null, "slx-focus"], [null, "slx-blur"]], function (_v, en, $event) { var ad = true; if (("slx-focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onCustomFocus() !== false);
        ad = (pd_0 && ad);
    } if (("slx-blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onCustomBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_PercentInputComponent_0, RenderType_PercentInputComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.PercentInputComponent]), i1.ɵdid(2, 573440, null, 0, i6.PercentInputComponent, [i1.ElementRef], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).hasFocus; _ck(_v, 0, 0, currVal_0); }); }
var PercentInputComponentNgFactory = i1.ɵccf("slx-percent-input", i6.PercentInputComponent, View_PercentInputComponent_Host_0, { className: "className", placeholder: "placeholder", readonly: "readonly" }, {}, []);
export { PercentInputComponentNgFactory as PercentInputComponentNgFactory };
