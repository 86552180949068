import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { TimecardsNavigationService } from '../../services/index';

@Directive({
  selector: '[timecardsLink]',
})
export class TimecardsLinkDirective {
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;

  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: TimecardsNavigationService = new TimecardsNavigationService(this.router, this.route);
    navService.NavigateToTimecards(this.startDate, this.endDate);
  }
}
