var BenefitEligibleEmployeesInfo = /** @class */ (function () {
    function BenefitEligibleEmployeesInfo(_a) {
        var _b = _a === void 0 ? {} : _a, count = _b.count, errorMessage = _b.errorMessage, hasError = _b.hasError;
        this.count = count || 0;
        this.errorMessage = errorMessage || '';
        this.hasError = hasError || false;
    }
    return BenefitEligibleEmployeesInfo;
}());
export { BenefitEligibleEmployeesInfo };
