import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { Component, OnInit, Input } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { UnassignedEmployee } from '../../../models/index';
import { SupervisorAssignmentManagementService } from '../../../services/supervisor-assignment/supervisor-assignment-management.service';

@Component({
    moduleId: module.id,
    selector: 'slx-unassigned-employees',
    templateUrl: 'unassigned-employees-grid.component.html',
    styleUrls: ['unassigned-employees-grid.component.scss']
})
export class UnassignedEmployeesGridComponent implements OnInit {

    @Input()
    public set employees(value: UnassignedEmployee[]) {
        this.m_employees = value;
        this.refreshGrid();
    }

    public gridState: KendoGridStateHelper<UnassignedEmployee>;

    private m_employees: UnassignedEmployee[];

    //private managementService: SupervisorAssignmentManagementService

    constructor() {
        this.gridState = new KendoGridStateHelper<UnassignedEmployee>();
    }

    public ngOnInit(): void {

        this.gridState.onRefreshGrid.subscribe((state: State) => {
            this.refreshGrid();
        });

        // TODO: maybe later we will use it internally
        /*
        this.managementService.loadedUnassignedEmployeesCmd$.subscribe((employees: UnassignedEmployee[]) => {
            this.employees = employees;
            this.refreshGrid();
        });

        this.managementService.loadUnassignedForCurrentOrgLevel();
        */
    }

    protected refreshGrid(): void {

        if (!this.m_employees) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_employees, this.gridState.state);
    }
}
