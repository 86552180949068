<div class="charts-container">
  <div class="row dat">
    <!-- <div class="col-2 dis">
      <label class="name">
        <span>Department:</span>  
      </label>
      <label class="count">
        <span>{{TotalCount}}</span>
      </label>
      <label class="sync">
        <span>Sync Completion:</span>  
      </label>
      <label class="count">
        <span>{{sync}}%</span>  
      </label>
    </div> -->
    <div class="col-6 chart">
    <kendo-chart>
      <!-- <kendo-chart-legend></kendo-chart-legend> -->
      <kendo-chart-series >
        <kendo-chart-series-item
          [autoFit]="autofit"
          type="donut"
          [data]="gridData"
          field="statusCount"
          categoryField="statusMessage"
          zoomable="true"
          [color]="color"
        >
          <kendo-chart-series-item-labels class="def" color="#ffff"  background="none" > </kendo-chart-series-item-labels>
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
  </div>
    <!-- <label>
        <input type="checkbox" [(ngModel)]="autofit" />
        <span>Toggle Autofit</span>
      </label> -->
  </div>
</div>
