import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { UserProfileSectionBaseComponent } from '../user-profile-sections/user-profile-section-base.component';
import { OrgLevelSelectorComponent } from '../org-levels-selector/org-levels-selector.component';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { UserProfileSectionType } from '../../../models/users/models/user-profile-section-type';
import { UserRoleRelationModel, UserRoleRecordWrapper } from '../../../models/index';
import { UserProfileManagementService } from '../../../services/index';
import { UserRolesSaveData } from '../../../models/users/models/user-roles-save-data';
var UserProfileSectionRolesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserProfileSectionRolesComponent, _super);
    function UserProfileSectionRolesComponent(management, orgLevelWatchService) {
        var _this = _super.call(this, management) || this;
        _this.management = management;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.records = [];
        _this.roles = [];
        _this.tempRoles = [];
        _this.orgLevelSeparator = ' > ';
        _this.selectedRecords = [];
        _this.m_type = UserProfileSectionType.ROLES;
        _this.gridState = new KendoGridStateHelper();
        _this.clearSelection();
        return _this;
    }
    Object.defineProperty(UserProfileSectionRolesComponent.prototype, "isValid", {
        get: function () {
            return this.tempRoles ? this.tempRoles.length > 0 : false;
        },
        enumerable: true,
        configurable: true
    });
    UserProfileSectionRolesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isEditMode: false,
            isLoading: false,
            addMode: false
        };
        _super.prototype.ngOnInit.call(this);
        this.orgLevelTreeSubscription = this.orgLevelWatchService.orgLevelTreeLoaded$.subscribe(function () {
            var flatList = _this.orgLevelWatchService.getFlatList();
            var groupedByType = _.keyBy(flatList, function (flatLvl) { return flatLvl.orgLevel.type; });
            _this.orgLevelTypesList = _.map(_.keys(groupedByType), function (key) { return { id: key, name: key }; });
            _this.selectedOrgLevelType = _.first(_this.orgLevelTypesList);
        });
        this.userProfileSubscription = this.management.onProfileLoaded$.subscribe(function (userProfile) {
            _this.userProfile = userProfile;
            if (_this.userProfile.isNew) {
                _this.management.editSection(_this);
            }
        });
        this.userRolesSubscription = this.management.onUserRolesLoaded$.subscribe(function (roles) {
            _this.roles = roles;
            _this.tempRoles = _this.management.cloneRoles(roles);
            _this.updateRecords();
            _this.refreshGrid();
        });
        this.allRolesSubscription = this.management.onAllRolesLoaded$.subscribe(function (roles) {
            _this.allRolesList = roles;
        });
        this.addRolesModeSubscription = this.management.onAddRoleModeSwitch$.subscribe(function (state) {
            _this.state.addMode = state;
            _this.selectedRoleChanged();
        });
        this.saveAddedSubscription = this.management.onSaveSelectedRole$.subscribe(function () {
            _this.onSaveAddedRole();
        });
        this.cancelRolesModeSubscription = this.management.onCancelSelectedRole$.subscribe(function () {
            _this.onCancelAdd();
        });
        this.onDeleteRolesSubscription = this.management.onDeleteRoles$.subscribe(function () {
            _this.onDeleteRole();
        });
        this.onResetRolesSubscription = this.management.onResetRoles$.subscribe(function () {
            _this.onResetRoles();
        });
        this.clearSelection();
    };
    UserProfileSectionRolesComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        _super.prototype.ngOnDestroy.call(this);
    };
    UserProfileSectionRolesComponent.prototype.getSaveData = function () {
        var saveData = new UserRolesSaveData();
        saveData.addMode = this.state.addMode;
        if (!saveData.addMode) {
            saveData.tempRoles = this.tempRoles;
        }
        return saveData;
    };
    UserProfileSectionRolesComponent.prototype.onChangesSaved = function () {
        this.state.isLoading = false;
        this.state.isEditMode = false;
        this.clearSelection();
        if (this.state.addMode) {
            this.state.addMode = false;
        }
    };
    UserProfileSectionRolesComponent.prototype.discardChanges = function () {
        this.tempRoles = this.management.cloneRoles(this.roles);
        this.updateRecords();
        this.refreshGrid();
        this.state.isEditMode = false;
        this.state.addMode = false;
        this.clearSelection();
    };
    UserProfileSectionRolesComponent.prototype.onToggleAllSelected = function () {
        var _this = this;
        _.forEach(this.records, function (record) {
            record.isSelected = _this.isAllSelected && Boolean(record.selectable);
        });
        this.selectionChange();
    };
    UserProfileSectionRolesComponent.prototype.selectionChange = function () {
        this.selectedRecords = _.filter(this.records, function (record) {
            return Boolean(record.isSelected) === true;
        });
        this.hasSelectedRoles = this.selectedRecords.length > 0;
    };
    UserProfileSectionRolesComponent.prototype.onAddClick = function () {
        this.hasSelectedOrgLevels = false;
        this.management.onAddRole();
    };
    UserProfileSectionRolesComponent.prototype.onDeleteClick = function () {
        this.onDeleteRole();
    };
    UserProfileSectionRolesComponent.prototype.onSaveAddedClick = function () {
        this.onSaveAddedRole();
    };
    UserProfileSectionRolesComponent.prototype.onCancelAddClick = function () {
        this.management.onCancelAddedRole();
        this.hasSelectedOrgLevels = false;
    };
    UserProfileSectionRolesComponent.prototype.selectedRoleChanged = function () {
        var _this = this;
        var relationModel;
        if (this.selectedRole) {
            relationModel = _.find(this.tempRoles, function (roleRelation) {
                return roleRelation.roleDefinition.id === _this.selectedRole.id;
            });
        }
        this.selectedRoleRelation = relationModel;
    };
    UserProfileSectionRolesComponent.prototype.onOrgLevelSelectedCountChange = function (count) {
        var _this = this;
        Promise.resolve(null).then(function () {
            _this.hasSelectedOrgLevels = count > 0;
        });
    };
    UserProfileSectionRolesComponent.prototype.clearSelection = function () {
        _.each(this.records, function (record) {
            record.isSelected = false;
        });
        this.selectionChange();
    };
    UserProfileSectionRolesComponent.prototype.onResetRoles = function () {
        this.selectedRecords = _.filter(this.records, function (record) { return Boolean(record.selectable); });
        this.onDeleteRole();
    };
    UserProfileSectionRolesComponent.prototype.onDeleteRole = function () {
        var _this = this;
        var removedRoles = [];
        _.each(this.tempRoles, function (role) {
            role.orgLevels = _.filter(role.orgLevels, function (lvl) {
                var selectedRecord = _.find(_this.selectedRecords, function (record) {
                    return record.orglevel.id === lvl.id && record.role.id === role.roleDefinition.id;
                });
                return !selectedRecord;
            });
            if (role.orgLevels.length === 0)
                removedRoles.push(role);
        });
        this.tempRoles = _.without.apply(_, [this.tempRoles].concat(removedRoles));
        this.isAllSelected = false;
        this.clearSelection();
        this.updateRecords();
        this.refreshGrid();
    };
    UserProfileSectionRolesComponent.prototype.onSaveAddedRole = function () {
        var _this = this;
        if (this.selector) {
            var relationModel = _.find(this.tempRoles, function (roleRelation) {
                return roleRelation.roleDefinition.id === _this.selectedRole.id;
            });
            if (!relationModel) {
                // adding new role
                relationModel = new UserRoleRelationModel();
                relationModel.roleDefinition = this.selectedRole;
                relationModel.userId = this.userProfile.id;
                this.tempRoles.push(relationModel);
            }
            // add levels
            var allRecords = this.selector.records;
            var roleOrgLevels_1 = [];
            var typesByDepth_1 = [
                OrgLevelType.corporation,
                OrgLevelType.region,
                OrgLevelType.organization,
                OrgLevelType.department
            ];
            var editDepth_1 = _.findIndex(typesByDepth_1, function (type) {
                return _this.selectedOrgLevelType.name === type;
            });
            var recordByOrgLevelId_1 = [];
            _.each(allRecords, function (r) {
                var itemDepth = _.findIndex(typesByDepth_1, function (type) {
                    return r.orgLevel.orgLevel.type === type;
                });
                r.depth = itemDepth;
                recordByOrgLevelId_1[r.orgLevel.orgLevel.id] = r;
            });
            _.sortBy(allRecords, ['depth']);
            var currentDepth_1 = typesByDepth_1.length - 1;
            while (currentDepth_1 >= 0) {
                _.each(allRecords, function (r) {
                    if (r.depth === currentDepth_1) {
                        if (r.depth !== editDepth_1) {
                            if (r.orgLevel.orgLevel.hasChilds) {
                                var childCount = _.size(r.orgLevel.orgLevel.childs);
                                if (childCount > 1) {
                                    var notSelectedChild = _.find(r.orgLevel.orgLevel.childs, function (o) {
                                        return recordByOrgLevelId_1[o.id].isSelected === false;
                                    });
                                    if (notSelectedChild) {
                                        r.isSelected = false;
                                    }
                                    else {
                                        r.isSelected = true;
                                        var flatChildList = _this.orgLevelWatchService.getFlatChildOrglevels(r.orgLevel.orgLevel.id);
                                        _.each(flatChildList, function (o) {
                                            recordByOrgLevelId_1[o.id].isSelected = false;
                                        });
                                    }
                                }
                            }
                        }
                        else {
                            if (r.isSelected && r.orgLevel.orgLevel.hasChilds) {
                                var flatChildList = _this.orgLevelWatchService.getFlatChildOrglevels(r.orgLevel.orgLevel.id);
                                _.each(flatChildList, function (o) {
                                    recordByOrgLevelId_1[o.id].isSelected = false;
                                });
                            }
                        }
                    }
                });
                currentDepth_1--;
            }
            _.each(allRecords, function (r) {
                if (r.isSelected) {
                    roleOrgLevels_1.push(r.orgLevel.orgLevel);
                }
            });
            relationModel.orgLevels = roleOrgLevels_1;
        }
        // regenerate flat list
        this.state.addMode = false;
        this.updateRecords();
        this.refreshGrid();
        this.management.onSaveAddedRole();
    };
    UserProfileSectionRolesComponent.prototype.onCancelAdd = function () {
        this.state.addMode = false;
        this.management.onCancelAddedRole();
        this.management.onCancelAddedRole();
    };
    UserProfileSectionRolesComponent.prototype.updateRecords = function () {
        var records = [];
        _.each(this.tempRoles, function (role) {
            _.each(role.orgLevels, function (orgLevel) {
                var record = new UserRoleRecordWrapper();
                record.role = role.roleDefinition;
                record.orglevel = orgLevel;
                records.push(record);
            });
        });
        this.records = records;
    };
    UserProfileSectionRolesComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionRolesComponent.prototype, "userProfileSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionRolesComponent.prototype, "userRolesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionRolesComponent.prototype, "allRolesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionRolesComponent.prototype, "addRolesModeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionRolesComponent.prototype, "saveAddedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionRolesComponent.prototype, "cancelRolesModeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionRolesComponent.prototype, "onDeleteRolesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionRolesComponent.prototype, "onResetRolesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionRolesComponent.prototype, "orgLevelTreeSubscription", void 0);
    return UserProfileSectionRolesComponent;
}(UserProfileSectionBaseComponent));
export { UserProfileSectionRolesComponent };
