import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { Assert } from '../../../../framework/index';
import { appConfig } from '../../../../app.config';
import { pbjConfig } from '../../pbj.config';
import { ManagementBaseService } from '../../../../core/services/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { PbjNavigationService } from '../../../../common/services/index';
import { PbjReconciliationApiService } from './pbj-reconciliation-api.service';
import { EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { PbjReconciliationEmployee, PbjReconciliationOrglevel } from '../../models/index';
import { ComponentStateStorageService, StateManagementService } from '../../../../common/services/index';
import { StateResetTypes } from '../../../../core/models/index';
var PbjReconciliationManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PbjReconciliationManagementService, _super);
    function PbjReconciliationManagementService(apiService, employeeDefinitionsApiService, orgLevelWatchService, storageService, stateManagement) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.storageService = storageService;
        _this.stateManagement = stateManagement;
        _this.resetBy = StateResetTypes.SessionEnd;
        _this.dayInSec = 60 * 60 * 24;
        _this.componentId = 'PbjReconciliationComponent';
        _this.reportFrom = 'StartDate';
        _this.reportTo = 'EndDate';
        _this.quarter = Math.floor((new Date().getMonth() / 3));
        _this.maxDaysRange = pbjConfig.settings.reconciliation.maxDaysRange;
        return _this;
    }
    PbjReconciliationManagementService.prototype.destroy = function () {
        _super.prototype.destroy.call(this);
        this.expandedDetailsChanged$.complete();
        this.orgLevelChanged$.complete();
        this.loadedOrgEntries$.complete();
        this.loadedDepEntries$.complete();
        this.loadedEmpEntries$.complete();
        this.loadedDailyEntries$.complete();
        this.reconEmployeeChanged$.complete();
        this.reconOrglevelChanged$.complete();
        this.exportToPdf$.complete();
        this.exportToExcel$.complete();
        this.passStartDate$.complete();
        this.passEndDate$.complete();
    };
    PbjReconciliationManagementService.prototype.init = function (router, route, expanded) {
        this.pbjNavService = new PbjNavigationService(router, route);
        this.createEmitters();
        this.changeExpandedDetails(expanded);
        this.initSubscriptionToOrgLevel();
        var firstDate = new Date(new Date().getFullYear(), this.quarter * 3 - 3, 1);
        var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
        this.restoreDates(firstDate, endDate);
        this.initSubscriptionToParams(route);
    };
    PbjReconciliationManagementService.prototype.createEmitters = function () {
        this.expandedDetailsChanged$ = new ReplaySubject(1);
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.reconEmployeeChanged$ = new ReplaySubject(1);
        this.reconOrglevelChanged$ = new ReplaySubject(1);
        this.loadedOrgEntries$ = new Subject();
        this.loadedDepEntries$ = new Subject();
        this.loadedEmpEntries$ = new Subject();
        this.loadedDailyEntries$ = new Subject();
        this.exportToPdf$ = new Subject();
        this.exportToExcel$ = new Subject();
        this.passStartDate$ = new Subject();
        this.passEndDate$ = new Subject();
        this.subscriptions = {};
        this.startDate = null;
        this.endDate = null;
    };
    PbjReconciliationManagementService.prototype.changeOrgLevel = function (orgLevelId, orgLevelType) {
        if (_.isNumber(orgLevelId)) {
            var orgLevel = this.orgLevelWatchService.getOrgLevelByRelatedItemId(orgLevelId, orgLevelType);
            if (orgLevel) {
                this.pbjNavService.NavigateToReconciliation(orgLevel.id, null, null);
            }
        }
    };
    PbjReconciliationManagementService.prototype.navigateToReconciliation = function (orgLevelId, sDate, eDate) {
        this.pbjNavService.NavigateToReconciliation(orgLevelId, sDate, eDate);
    };
    PbjReconciliationManagementService.prototype.navigateToReconciliationEmployee = function (empId, sDate, eDate) {
        this.pbjNavService.NavigateToReconciliationEmployee(empId, sDate, eDate);
    };
    PbjReconciliationManagementService.prototype.storeDates = function (sDate, eDate) {
        this.storageService.setControlState(this.componentId, this.reportFrom, { value: sDate }, this.resetBy);
        this.storageService.setControlState(this.componentId, this.reportTo, { value: eDate }, this.resetBy);
    };
    PbjReconciliationManagementService.prototype.changeExpandedDetails = function (isOn) {
        this.expandedDetailsChanged$.next(isOn);
    };
    PbjReconciliationManagementService.prototype.exportToPdf = function () {
        this.exportToPdf$.next(null);
    };
    PbjReconciliationManagementService.prototype.subscribeToExportToPdf = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportToPdf$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.exportToExcel = function () {
        this.exportToExcel$.next(null);
    };
    PbjReconciliationManagementService.prototype.subscribeToExportToExcel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportToExcel$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.subscribeToExpandedDetails = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.expandedDetailsChanged$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.subscribeToLoadedOrgEntries = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadedOrgEntries$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.subscribeToLoadedDepEntries = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadedDepEntries$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.subscribeToLoadedEmpEntries = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadedEmpEntries$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.subscribeToLoadedDailyEntries = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadedDailyEntries$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.subscribeToReconEmployee = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.reconEmployeeChanged$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.subscribeToReconOrglevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.reconOrglevelChanged$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.loadOrgEntries = function (orgLevelId, startDate, endDate) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getOrgEntries(orgLevelId, startDate, endDate)
            .then(function (entries) {
            _this.loadedOrgEntries$.next(entries);
            _this.onLoadStatusChanged(false);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjReconciliationManagementService.prototype.loadDepEntries = function (orgLevelId, startDate, endDate, isChangeLoadingState) {
        var _this = this;
        if (isChangeLoadingState === void 0) { isChangeLoadingState = true; }
        if (isChangeLoadingState)
            this.onLoadStatusChanged(true);
        this.apiService.getDepEntries(orgLevelId, startDate, endDate)
            .then(function (entries) {
            _this.loadedDepEntries$.next({ orgLevelId: orgLevelId, entries: entries });
            if (isChangeLoadingState)
                _this.onLoadStatusChanged(false);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjReconciliationManagementService.prototype.loadEmpEntries = function (orgLevelId, startDate, endDate) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getEmpEntries(orgLevelId, startDate, endDate)
            .then(function (entries) {
            _this.loadedEmpEntries$.next(entries);
            _this.onLoadStatusChanged(false);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjReconciliationManagementService.prototype.loadDailyEntries = function (orgLevelId, empId, startDate, endDate) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getDailyEntries(orgLevelId, empId, startDate, endDate)
            .then(function (entries) {
            _this.loadedDailyEntries$.next(entries);
            _this.onLoadStatusChanged(false);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjReconciliationManagementService.prototype.loadEmployeeInfo = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.employeeDefinitionsApiService.getEmployeeShortInfo(employeeId)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    PbjReconciliationManagementService.prototype.initSubscriptionToOrgLevel = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$.subscribe(function (orgLevel) {
            if (_.isNumber(_.get(orgLevel, 'id'))) {
                _this.orgLevelChanged$.next(orgLevel);
            }
        });
    };
    PbjReconciliationManagementService.prototype.initSubscriptionToParams = function (route) {
        var _this = this;
        this.subscriptions.params = route.params
            .combineLatest(route.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var empId, startDate, endDate, empInfo;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            empId = +params.employeeId;
                            startDate = moment(route.snapshot.queryParams.startDate, appConfig.linkDateFormat).toDate();
                            endDate = moment(route.snapshot.queryParams.endDate, appConfig.linkDateFormat).toDate();
                            if (!_.isFinite(empId)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.loadEmployeeInfo(empId)];
                        case 1:
                            empInfo = _b.sent();
                            this.reconEmployeeChanged$.next(new PbjReconciliationEmployee(empInfo.id, empInfo.name, startDate, endDate, this.maxDaysRange * this.dayInSec));
                            return [3 /*break*/, 3];
                        case 2:
                            if (!this.startDate || !this.endDate || this.startDate.getTime() !== startDate.getTime() || this.endDate.getTime() !== endDate.getTime()) {
                                this.startDate = startDate;
                                this.endDate = endDate;
                                this.reconOrglevelChanged$.next(new PbjReconciliationOrglevel(startDate, endDate, this.maxDaysRange * this.dayInSec));
                            }
                            _b.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        });
    };
    PbjReconciliationManagementService.prototype.restoreDates = function (startDate, endDate) {
        var _this = this;
        this.stateManagement.init(this.componentId, true);
        this.subscriptions.dates = this.stateManagement.onInit$
            .subscribe(function () {
            var state = _this.storageService.getControlState(_this.componentId, _this.reportFrom);
            var value = state && state.value && _.isDate(new Date(state.value)) && moment(new Date(state.value), appConfig.linkDateFormat).toDate() > moment('1/1/1970', appConfig.linkDateFormat).toDate() ? new Date(state.value) : startDate;
            _this.passStartDate$.next(value);
            var state1 = _this.storageService.getControlState(_this.componentId, _this.reportTo);
            var value1 = state1 && state1.value && _.isDate(new Date(state1.value)) && moment(new Date(state1.value), appConfig.linkDateFormat).toDate() > moment('1/1/1970', appConfig.linkDateFormat).toDate() ? new Date(state1.value) : endDate;
            _this.passEndDate$.next(value1);
        });
    };
    PbjReconciliationManagementService.prototype.subscribeToPassStartDate = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.passStartDate$.subscribe(callback);
    };
    PbjReconciliationManagementService.prototype.subscribeToPassEndDate = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.passEndDate$.subscribe(callback);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PbjReconciliationManagementService.prototype, "orgLevel$", void 0);
    return PbjReconciliationManagementService;
}(ManagementBaseService));
export { PbjReconciliationManagementService };
