<slx-spinner [show]="isLoading" novalidate>
    <div class="modal-body">
        <slx-employee-search-field *ngIf="!hasEmployee && !hasReportId && !hasError" [orgLevelId]="orgLevelId"
            [instructions]="'To create new incident report, search for the employee.
            Use the employee\'s name to search, then select the employee to begin.'"
            (loadStatusChange)="onSearchLoadStatusChange($event)" (employeeSelected)="onEmployeeSelected($event)">
        </slx-employee-search-field>

        <slx-wc-incident-report *ngIf="hasEmployee"></slx-wc-incident-report>

        <div *ngIf="hasError" class="error-box flex flex-direction__column justify-content__center">
            <i class="fal fa-exclamation-triangle error-box__icon" aria-hidden="true"></i>
            <p class="error-box__text">{{ errorMessage }}</p>
        </div>
    </div>

    <div class="modal-footer">

        <button type="button" *ngIf="!hasEmployee" (click)="onCancel()" class="theme-button-cancel">Close</button>

        <p *ngIf="hasEmployee && report" class="flex justify-content__flex-end modal-buttons">

            <button type="button" class="theme-button-cancel margin-r" (click)="onCancel()">Cancel</button>

            <button *ngIf="isCreatingNew || editMode" type="button" class="theme-button-apply" (click)="onSave()"
                [disabled]="!canSave || hasError || !formValid">{{ isCreatingNew ? 'Save' : 'Update' }}</button>

            <button *ngIf="!isCreatingNew && !editMode" [disabled]="!canEdit" type="button" (click)="onEdit()" class="theme-button-apply">Edit</button>
        </p>

    </div>

</slx-spinner>
