import * as _ from 'lodash';
import * as moment from 'moment';
import { Router, ActivatedRoute, UrlTree, Params } from '@angular/router';

export class WcNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateByPath(orgLevelId: number, path: string): void {
    this.router.navigateByUrl(this.getUrlTree(orgLevelId, path));
  }

  private getUrlTree(orgLevelId: number, path: string): UrlTree {
    return this.router.createUrlTree([path], { relativeTo: this.route.pathFromRoot[2], queryParams: { orgLevelId } });
  }
  
}
