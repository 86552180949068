var State;
(function (State) {
    State["created"] = "created";
    State["added"] = "added";
    State["updated"] = "updated";
    State["deleted"] = "deleted";
})(State || (State = {}));
var BenefitDetailsState = /** @class */ (function () {
    function BenefitDetailsState() {
        this.state = State.created;
    }
    BenefitDetailsState.prototype.setStateAdded = function () {
        this.state = State.added;
    };
    BenefitDetailsState.prototype.setStateUpdated = function () {
        this.state = State.updated;
    };
    BenefitDetailsState.prototype.setStateDeleted = function () {
        this.state = State.deleted;
    };
    Object.defineProperty(BenefitDetailsState.prototype, "stateIsAdded", {
        get: function () {
            return this.state === State.added;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsState.prototype, "stateIsUpdated", {
        get: function () {
            return this.state === State.updated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsState.prototype, "stateIsDeleted", {
        get: function () {
            return this.state === State.deleted;
        },
        enumerable: true,
        configurable: true
    });
    return BenefitDetailsState;
}());
export { BenefitDetailsState };
