import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { orderBy } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { mutableSelect } from '../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { AttendancePointsDefinition } from '../../../organization/models/lookup/index';
import { AttendancePointsConfigCategory, PayCodesContainer, PayCode } from '../../models/index';
import { LookupApiService } from '../../../organization/services/lookup/lookup-api.service';
import { AttendancePointsConfigApiService, AttendancePointsConfigMapService, PayCodesApiService } from '../../services/index';
import { ChangeManagementService } from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';
import { NgForm } from '@angular/forms';
var AttendancePointsConfigComponent = /** @class */ (function () {
    function AttendancePointsConfigComponent(lookupApiService, attendancePointsConfigApiService, attendancePointsConfigMapService, changeService, payCodesApi) {
        this.lookupApiService = lookupApiService;
        this.attendancePointsConfigApiService = attendancePointsConfigApiService;
        this.attendancePointsConfigMapService = attendancePointsConfigMapService;
        this.changeService = changeService;
        this.payCodesApi = payCodesApi;
        this.sort = [];
        this.state = {
            isLoading: false
        };
    }
    Object.defineProperty(AttendancePointsConfigComponent.prototype, "selectedCategory", {
        get: function () {
            return this.m_selectedCategory;
        },
        set: function (value) {
            this.m_selectedCategory = value;
            if (this.editedEntry && this.m_selectedCategory) {
                this.editedEntry.category.name = value.name;
            }
        },
        enumerable: true,
        configurable: true
    });
    AttendancePointsConfigComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
            _this.getDefinitions();
            _this.getPayCodes();
        });
    };
    AttendancePointsConfigComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    AttendancePointsConfigComponent.prototype.addHandler = function () {
        this.closeEditor();
        var definition = new AttendancePointsDefinition();
        definition.id = 0;
        definition.category = { name: '' };
        definition.definition = '';
        this.isAdding = true;
        this.editedEntry = definition;
        this.grid.addRow(this.editedEntry);
        this.changeService.changeNotify();
    };
    AttendancePointsConfigComponent.prototype.editHandler = function (event) {
        var _this = this;
        this.closeEditor();
        this.isAdding = false;
        this.editedEntry = Object.assign({}, event.dataItem);
        this.editedRowIndex = event.rowIndex;
        this.setSelectedCategory();
        this.grid.editRow(this.editedRowIndex);
        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(function () {
            if (_this.mainForm.dirty) {
                _this.changeService.changeNotify();
            }
        });
    };
    AttendancePointsConfigComponent.prototype.saveHandler = function () {
        if (this.isAdding) {
            this.addEntry(this.editedEntry);
        }
        else {
            var editedEntry = _.find(this.definitionsList, { 'id': this.editedEntry.id });
            _.assign(editedEntry, this.editedEntry);
            this.saveEntry(this.editedEntry);
        }
        this.closeEditor();
        this.refreshGrid();
    };
    AttendancePointsConfigComponent.prototype.removeHandler = function (event) {
        this.deletingEntryId = event.dataItem.id;
    };
    AttendancePointsConfigComponent.prototype.cancelHandler = function () {
        this.closeEditor();
        this.unsubscribeMainForm();
        this.changeService.clearChanges();
    };
    AttendancePointsConfigComponent.prototype.sortChangeHandler = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    AttendancePointsConfigComponent.prototype.closeEditor = function () {
        this.grid.closeRow(this.editedRowIndex);
        this.unsetSelectedCategory();
        this.isAdding = false;
        this.editedEntry = undefined;
        this.editedRowIndex = undefined;
    };
    AttendancePointsConfigComponent.prototype.onPopoverAction = function (acceptPopover, isDelete) {
        if (isDelete) {
            var index = _.findIndex(this.definitionsList, { 'id': this.deletingEntryId });
            this.definitionsList.splice(index, 1);
            this.deleteEntry(this.deletingEntryId);
            this.refreshGrid();
        }
        this.deletingEntryId = 0;
        acceptPopover.hide();
    };
    AttendancePointsConfigComponent.prototype.getPayCodesNames = function (points) {
        if (!points || !points.exceptions)
            return '';
        return _.map(points.exceptions, function (pc) { return pc.description; }).join(', ');
    };
    AttendancePointsConfigComponent.prototype.addEntry = function (entry) {
        var _this = this;
        this.state.isLoading = true;
        this.attendancePointsConfigApiService.addEntry(entry, this.orgLevelId)
            .then(function (addedEntry) {
            _this.definitionsList.push(addedEntry);
            _this.handleApiCallCompletion();
        })
            .catch(function () {
            _this.handleApiCallCompletion();
        });
    };
    AttendancePointsConfigComponent.prototype.saveEntry = function (entry) {
        var _this = this;
        this.state.isLoading = true;
        this.attendancePointsConfigApiService.saveEntry(entry, this.orgLevelId)
            .then(function () {
            _this.handleApiCallCompletion();
        }).catch(function () {
            _this.handleApiCallCompletion();
        });
    };
    AttendancePointsConfigComponent.prototype.deleteEntry = function (entryId) {
        var _this = this;
        this.state.isLoading = true;
        this.attendancePointsConfigApiService.deleteEntry(entryId)
            .then(function () {
            _this.stopLoading();
        })
            .catch(function () {
            _this.stopLoading();
        });
    };
    AttendancePointsConfigComponent.prototype.handleApiCallCompletion = function () {
        this.stopLoading();
        this.unsubscribeMainForm();
        this.changeService.clearChanges();
    };
    AttendancePointsConfigComponent.prototype.unsubscribeMainForm = function () {
        if (this.mainFormSubscription) {
            this.mainFormSubscription.unsubscribe();
        }
    };
    AttendancePointsConfigComponent.prototype.stopLoading = function () {
        this.state.isLoading = false;
    };
    AttendancePointsConfigComponent.prototype.setSelectedCategory = function () {
        var name = this.editedEntry.category.name;
        var selectedCategory = new AttendancePointsConfigCategory();
        selectedCategory.id = name;
        selectedCategory.name = name;
        this.selectedCategory = selectedCategory;
    };
    AttendancePointsConfigComponent.prototype.unsetSelectedCategory = function () {
        if (this.selectedCategory) {
            this.selectedCategory = null;
        }
    };
    AttendancePointsConfigComponent.prototype.getPayCodes = function () {
        var _this = this;
        this.state.isLoading = true;
        this.payCodes = [];
        this.payCodesApi.getPayCodes(this.orgLevelId)
            .then(function (value) {
            _this.payCodes = _.map(value.records, function (model) { return model.payCode; });
            _this.state.isLoading = false;
        }).catch(function (reason) {
            _this.payCodes = [];
            _this.state.isLoading = false;
        });
    };
    AttendancePointsConfigComponent.prototype.getDefinitions = function () {
        this.state.isLoading = true;
        this.lookupApiService.getAttendancePointsDefinitions(this.orgLevelId)
            .then(this.definitionsReady.bind(this))
            .catch(this.definitionsFailed.bind(this));
    };
    AttendancePointsConfigComponent.prototype.definitionsReady = function (definitions) {
        this.definitionsList = definitions;
        this.state.isLoading = false;
        this.categoriesList = this.attendancePointsConfigMapService.mapAttendancePointsCategories(this.definitionsList);
        this.refreshGrid();
    };
    AttendancePointsConfigComponent.prototype.definitionsFailed = function (reason) {
        this.state.isLoading = false;
        this.definitionsList = null;
    };
    AttendancePointsConfigComponent.prototype.refreshGrid = function () {
        if (!this.definitionsList) {
            this.gridData = null;
            return;
        }
        this.gridData = {
            data: orderBy(this.definitionsList, this.sort),
            total: this.definitionsList.length
        };
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AttendancePointsConfigComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AttendancePointsConfigComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AttendancePointsConfigComponent.prototype, "mainFormSubscription", void 0);
    return AttendancePointsConfigComponent;
}());
export { AttendancePointsConfigComponent };
