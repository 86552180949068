import { Directive, Host, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ChangeDetectorRef } from '@angular/core';
import { PDFComponent } from '@progress/kendo-angular-grid';
import { SpinnerComponent } from '../../../components/index';
import { ModalService } from '../../../services/index';

@Directive({
  selector: '[slxKendoGridPdfSuppressIndicator]',
})
export class KendoGridPdfSuppressIndicatorDirective {
  constructor( @Host() pdfComponent: PDFComponent, changeDetectorRef: ChangeDetectorRef ) {
    let target: any = pdfComponent;
    target['initProgress'] = function (): void {
      let wrapperElement = this.component.wrapper.nativeElement;
      let progress = this.progress = document.createElement('div');
      this.progress.className = 'k-loading-pdf-mask';
      let overlay = wrapperElement.cloneNode(true);
      progress.appendChild(overlay);
      let spinnerContainer = document.createElement('div');
      spinnerContainer.className = 'spinner-container kendo-grid';
      progress.appendChild(spinnerContainer);
      let spinner = document.createElement('div');
      spinner.className = 'spinner';
      spinnerContainer.appendChild(spinner);

      this.originalHeight = wrapperElement.style.height;
      this.originalOverflow = wrapperElement.style.overflow;
      wrapperElement.style.height = wrapperElement.offsetHeight + 'px';
      wrapperElement.style.overflow = 'hidden';
      wrapperElement.appendChild(progress);
      this.applyScroll(overlay);

      changeDetectorRef.markForCheck();
      changeDetectorRef.detectChanges();
    };
  }
}
