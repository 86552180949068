export class FileBlobResponse {
  blob: Blob;
  filename: string;
  fileExtension: string;
  public get file(): string {
    if (!this.fileExtension) {
      return this.filename;
    }
    return `${this.filename}.${this.fileExtension}`;
  }
}
