export interface IBenefitEligibleEmployeesInfo {
  count: number;
  errorMessage: string;
  hasError: boolean;
}

export class BenefitEligibleEmployeesInfo {
  readonly count: number;
  readonly errorMessage: string;
  readonly hasError: boolean;

  constructor({ count, errorMessage, hasError }: Partial<BenefitEligibleEmployeesInfo> = {}) {
    this.count = count || 0;
    this.errorMessage = errorMessage || '';
    this.hasError = hasError || false;
  }
}
