import { Injectable, Inject } from '@angular/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

import { Assert } from '../../../framework/index';
import { SignalrWindow, SignalrHubConfig, SignalrHubEvent, SignalrHubGroup,
  SignalrHubGroupObservable, SignalrConnectionState, SignalrConnectionStateDefinition } from '../../models/index';
import { SessionService } from '../../../core/services/session/session.service';
import { AuthError } from '../../../core/models/index';

export class SignalrHubService {
  public starting$: Observable<any>;
  public connectionState$: Observable<SignalrConnectionState>;
  public error$: Observable<string>;

  private window: SignalrWindow;
  private channelConfig: SignalrHubConfig;
  private connectionStateSubject: Subject<SignalrConnectionState>;
  private startingSubject: Subject<any>;
  private errorSubject: Subject<any>;
  private connectionState: SignalrConnectionStateDefinition;
  private hubConnection: any;
  private hubProxy: any;
  private sessionService: SessionService;
  private subjects: StringMap<SignalrHubEvent<any>> = {};

  constructor(
    window: SignalrWindow,
    signalrHubConfig: SignalrHubConfig,
    sessionService: SessionService
  ) {
    this.sessionService = sessionService;
    this.window = window;
    this.channelConfig = signalrHubConfig;
    this.connectionStateSubject = new Subject<SignalrConnectionState>();
    this.startingSubject = new Subject<any>();
    this.errorSubject = new Subject<any>();
    this.connectionState$ = this.connectionStateSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
    this.starting$ = this.startingSubject.asObservable();
    this.hubConnection = this.window.$.hubConnection();
    this.hubConnection.url = signalrHubConfig.url;
    this.hubConnection.qs = { access_token: `${this.getToken()}` };
    this.hubProxy = this.hubConnection.createHubProxy(signalrHubConfig.hubName);
    if (this.window.$ === undefined || this.window.$.hubConnection === undefined) {
      throw new Error(`The variable '$' or the .hubConnection() function are not defined...please check the SignalR scripts have been loaded properly`);
    }
    this.hubConnection.stateChanged((state: any) => {
      let newState: SignalrConnectionStateDefinition = SignalrConnectionState.connecting;
      switch (state.newState) {
        case this.window.$.signalR.connectionState.connecting:
          newState = SignalrConnectionState.connecting;
          break;
        case this.window.$.signalR.connectionState.connected:
          newState = SignalrConnectionState.connected;
          break;
        case this.window.$.signalR.connectionState.reconnecting:
          newState = SignalrConnectionState.reconnecting;
          break;
        case this.window.$.signalR.connectionState.disconnected:
          newState = SignalrConnectionState.disconnected;
          break;
      }
      this.connectionState = newState;
      this.connectionStateSubject.next(newState);
    });

    this.hubConnection.error((error: any) => {
      this.errorSubject.next(error);
    });

    this.starting$.subscribe(() => {
      this.joinExistGroups();
    });
  }

  public start(): void {
    this.hubConnection.start()
      .done(() => {
        this.startingSubject.next();
      })
      .fail((error: any) => {
        this.startingSubject.error(error);
      });
  }

  public registerEvent<T>(event: string): void {
    let eventSub: SignalrHubEvent<T> = this.subjects[event];
    if (eventSub === undefined) {
      eventSub = new SignalrHubEvent();
      eventSub.groupSubjects = {};
      eventSub.event = event;
      this.subjects[event] = eventSub;
      this.hubProxy.on(event, (group: string, payload: T) => {
        let groupSubToInvoke: SignalrHubEvent<T> = this.subjects[event];
        if (groupSubToInvoke) {
          let eventSubjectToInvoke: SignalrHubGroup<T> = groupSubToInvoke.groupSubjects[group];
          if (eventSubjectToInvoke) {
            return eventSubjectToInvoke.subject.next(payload);
          }
        }
      });
    }
  }

  public subscribe<T>(event: string, group: string): SignalrHubGroupObservable<T> {
    let eventSub: SignalrHubEvent<T> = this.subjects[event];
    Assert.isNotNull(eventSub, 'event not registered');
    let groupSubject: SignalrHubGroup<T> = eventSub.groupSubjects[event];
    if (groupSubject === undefined) {
      groupSubject = new SignalrHubGroup<T>();
      groupSubject.subject = new Subject<T>();
      groupSubject.group = group;
      groupSubject.event = event;
      eventSub.groupSubjects[group] = groupSubject;
      this.joinGroup<T>(event, group);
    }
    return groupSubject.toObservable();
  }

  public unsubscribeAll<T>(): void {
    let eventSubject: SignalrHubEvent<T>[] = _.values<SignalrHubEvent<T>>(this.subjects);
    _.forEach(eventSubject, (subject: SignalrHubEvent<T>) => {
      if (subject) {
        this.unsubscribe(subject.event);
      }
    });
  }

  public unsubscribe<T>(event: string): void {
    let eventSub: SignalrHubEvent<T> = this.subjects[event];
    if (eventSub !== undefined) {
      let subjects: SignalrHubGroup<T>[] = _.values<SignalrHubGroup<T>>(eventSub.groupSubjects);
      _.forEach(subjects, (subject: SignalrHubGroup<T>) => {
        this.leaveGroup(event, subject.group);
        subject.subject.unsubscribe();
        eventSub.groupSubjects[subject.group] = undefined;
      });
    }
  }

  public joinExistGroups<T>(): void {
    let existEvents: SignalrHubEvent<T>[] = _.values<SignalrHubEvent<T>>(this.subjects);
    _.forEach(existEvents, (eventSub: SignalrHubEvent<T>) => {
      let groupSubjects: SignalrHubGroup<T>[] = _.values<SignalrHubGroup<T>>(eventSub.groupSubjects);
      _.forEach(groupSubjects, (groupSub: SignalrHubGroup<T>) => {
        if (groupSub) {
          this.joinGroup(groupSub.event, groupSub.group);
        }
      });
    });
  }

  public joinGroup<T>(event: string, group: string): void {
    let eventSub: SignalrHubEvent<T> = this.subjects[event];
    if (eventSub !== undefined && this.connectionState === SignalrConnectionState.connected) {
      let groupSubject: SignalrHubGroup<T> = eventSub.groupSubjects[group];
      this.hubProxy.invoke('JoinGroup', group)
        .done(() => {
          console.log(`Successfully subscribed from ${group} channel`);
        })
        .fail((error: any) => {
          groupSubject.subject.error(error);
        });
    }
  }

  public leaveGroup<T>(event: string, group: string): void {
    let eventSub: SignalrHubEvent<T> = this.subjects[event];
    if (eventSub !== undefined && this.connectionState === SignalrConnectionState.connected) {
      let groupSubject: SignalrHubGroup<T> = eventSub.groupSubjects[group];
      this.hubProxy.invoke('LeaveGroup', group)
        .done(() => {
          console.log(`Successfully unsubscribed from ${group} channel`);
        })
        .fail((error: any) => {
          groupSubject.subject.error(error);
        });
    }
  }

  public publish<T>(ev: T): void {
    this.hubProxy.invoke('Publish', ev);
  }

  private invokeEventErrors<T>(eventSub: SignalrHubEvent<T>, error: any): void {
    let subjects: SignalrHubGroup<T>[] = _.values<SignalrHubGroup<T>>(eventSub.groupSubjects);
    _.forEach(subjects, (subject: SignalrHubGroup<T>) => {
      if (subject && subject.subject) {
        subject.subject.error(error);
      }
    });
  }

  private getToken(): string {
    if (this.sessionService.isExpired()) {
      throw new AuthError('User not authenticated.');
    }
    return this.sessionService.getToken();
  }
}
