import * as _ from 'lodash';
import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { StateManagementService, ColumnManagementService, ModalService, DeviceDetectorService } from '../../../../../common/services';
import { CfgPmCode } from '../../../models';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../common/models';
import { SortDescriptor } from '@progress/kendo-data-query';
import { GridDataResult, GridComponent, PageChangeEvent, EditEvent } from '@progress/kendo-angular-grid';
import { NgForm } from '@angular/forms';
import { screenUtils, IScreenUtils } from '../../../../../common/utils';
import { process, State } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../../../core/decorators';
import { Subscription } from 'rxjs/Subscription';
import { IApplicationConfig, appConfig } from '../../../../../../app/app.config';
import { LookupService } from '../../../../../organization/services';
import { Lookup, LookupType, PerformanceReviewCodeType } from '../../../../../organization';
import { PmConfigurationManagementService } from '../../../services';
import { ConfirmOptions, ConfirmDialogComponent } from '../../../../../common';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'slx-pm-codes-grid',
  templateUrl: './pm-codes-grid.component.html',
  styleUrls: ['./pm-codes-grid.component.scss'],
  providers: [
    StateManagementService, ColumnManagementService
  ]
})
export class PmCodesGridComponent implements OnInit, OnDestroy {

  @Input()
  public set codes(c: CfgPmCode[]) {
    this.m_codes = c;
    this.prohibitedCodeValues = [];
    this.prohibitedCodeValuesByType = {};
    _.each(this.m_codes, (c: CfgPmCode) => {
      if (!this.prohibitedCodeValuesByType[c.type.id]) this.prohibitedCodeValuesByType[c.type.id] = [];
      this.prohibitedCodeValuesByType[c.type.id].push(c.name);
    });
    this.refreshGrid();
  }

  public get codes(): CfgPmCode[] {
    return this.m_codes;
  }

  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }


  public readonly columnsGroupName: string = 'PerformanceManagementConfigureCodes';
  public readonly columnsStateName: string = 'PerformanceManagementConfigureCodes';

  public canCreateNew = true;
  public canEdit = true;

  public gridState: KendoGridStateHelper<CfgPmCode>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public pageSize: number = 50;

  public screenUtils: IScreenUtils;
  public appConfig: IApplicationConfig;

  public prohibitedCodeValuesByType: StringMap<string[]> = {};
  public prohibitedCodeValues: string[] = [];
  public originalSelfValue: string;
  public showCodeNameControl: boolean = true;


  public readonly itemClass: any = CfgPmCode;

  public xlsxName;
  public pdfName;

  private readonly xlsxNameConst = 'Performance_Codes_';
  private readonly pdfNameConst = 'Performance_Codes_';

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  private ngFormChild: NgForm;

  private m_codes: CfgPmCode[];

  private subscriptions: StringMap<Subscription> = {};

  private codeTypesLookup: Lookup;

  constructor (
    private management: PmConfigurationManagementService,
    private stateManagement: StateManagementService,
    private columnManagementService: ColumnManagementService,
    private lookupService: LookupService,
    private modalService: ModalService,
    private devDetector: DeviceDetectorService
  ) {
    this.appConfig = appConfig;
    this.screenUtils = screenUtils;

    this.gridState = new KendoGridStateHelper<CfgPmCode>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'modifiedDate', dir: 'desc' },
      { field: 'typeName', dir: 'asc' },
      { field: 'name', dir: 'asc' }
    ];

  }

  public ngOnInit(): void {

    this.initServices();

    this.createSubscriptions();
    this.refreshGrid();
    this.loadLookups();
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  private createSubscriptions(): void {

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<CfgPmCode>) => {
      if (event.isNew) {
        this.doAdd(event.dataItem);
        return;
      }
      this.doEdit(event.dataItem, event.rowIndex);
    });

    this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<CfgPmCode>) => {
      this.startRemove(event.dataItem, event.rowIndex);
    });

    this.subscriptions.addNewCodeSubscription = this.management.subscribeToAddNewCode((newCode: CfgPmCode) => {
      this.gridState.closeEditor(this.grid);
      this.grid.addRow(newCode);
    });

    this.subscriptions.exportSubscription = this.management.subscribeToExportCodes((isPdf: boolean) => {

      let date = new Date();
      this.xlsxName = this.xlsxNameConst + `${date}.xlsx`;
      this.pdfName = this.pdfNameConst + `${date}.pdf`;

      if (isPdf) {
        this.grid.saveAsPDF();
      } else {
        this.grid.saveAsExcel();
      }
    });

  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.m_codes, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  public editHandler(event: EditEvent): void {
    if (event.dataItem) {
      if (event.dataItem.type) {
          this.prohibitedCodeValues = this.prohibitedCodeValuesByType[event.dataItem.type.id];
      }
      this.originalSelfValue = event.dataItem.name;
    }
    this.gridState.editHandler(event);
  }

  public onCodeTypeChange(item: PerformanceReviewCodeType, dataItem: CfgPmCode): void {
    this.showCodeNameControl = false;
    if (item) {
      if (dataItem.id && dataItem.type && dataItem.type.id !== item.id) {
          const index: number = _.findIndex(this.prohibitedCodeValuesByType[dataItem.type.id], n => n === this.originalSelfValue);
          this.prohibitedCodeValuesByType[dataItem.type.id].splice(index, 1);
          if (!this.prohibitedCodeValuesByType[item.id]) {
            this.prohibitedCodeValuesByType[item.id] = [];
          }
          this.prohibitedCodeValuesByType[item.id].push(this.originalSelfValue);
      }
      dataItem.type = item;
      this.prohibitedCodeValues = this.prohibitedCodeValuesByType[item.id];
    } else {
      this.prohibitedCodeValues = [];
    }
    _.delay(() => {
      this.showCodeNameControl = true;
    }, 100);
  }

  protected startRemove(dataItem: CfgPmCode, rowIndex: number) {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialogComponent.openDialog(
      'Confirmation',
      'Are you sure?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.doRemove(dataItem, rowIndex);
        }
      }, options);
  }

  protected doRemove(dataItem: CfgPmCode, rowIndex: number) {
    this.management.removeCode(dataItem);
  }

  protected doEdit(dataItem: CfgPmCode, rowIndex: number) {
    this.management.updateCode(dataItem)
      .then(() => {
        this.gridState.closeEditor(this.grid);
      });
  }

  protected doAdd(dataItem: CfgPmCode) {
    this.management.addNewCode(dataItem)
      .then(() => {
        this.gridState.closeEditor(this.grid);
      });
  }

  protected loadLookups() {
    this.lookupService.getLookup({ lookupType: LookupType.performanceReviewCodeTypes })
      .then((codeTypesLookup: Lookup) => {
        this.codeTypesLookup = codeTypesLookup;
      });
  }

  private initServices(): void {
    this.stateManagement.init('PerformanceManagementConfigureCodes');
    this.columnManagementService.init('PerformanceManagementConfigureCodes');
    this.columnManagementService.initGroup(this.columnsStateName, 6);
  }

  private refreshGrid(): void {
    if (!this.m_codes) {
      this.gridView = null;
      return;
    }

    this.gridState.view = process(this.m_codes, this.gridState.state);
  }

}
