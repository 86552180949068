import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../../framework/index';
import {
  BenefitDetailsUserActions,
  BenefitDetailsEditModes,
} from '../../models/index';

@Injectable()
export class BenefitDetailsPermissionService {
  private actions$ = new ReplaySubject<BenefitDetailsUserActions>();
  private saveStateOnly$ = new ReplaySubject<boolean>();
  private editMode$ = new ReplaySubject<BenefitDetailsEditModes>(1);
  private actions = new BenefitDetailsUserActions();
  private editMode = new BenefitDetailsEditModes();
  private restrictions = {
    canAdd: false,
    canEdit: false,
    canDelete: false,
    сanEditFormula: false,
    providerHasSelectedOrglevel: false,
    nonDepartmentOrglevel: false,
    canAddCovOptions: false,
    canExpire: false,
    canExtend: false,
    canRenew: false,
    canEnroll: false,
    canMapPayroll: false,
    benefitDeduction2:false
  };
  private validation = {
    canCreate: true,
    canEdit: true,
    canDelete: true,
    canSave: false,
    canExpire: false,
    canExtend: false,
    canRenew: false
  };

  public destroy(): void {
    this.actions$.complete();
    this.editMode$.complete();
  }

  public setExternalPermissions(canAdd: boolean, canEdit: boolean, canDelete: boolean, сanEditFormula: boolean,
    canRenew: boolean, canExpire: boolean, canExtend: boolean, canEnroll: boolean, canMapPayroll: boolean,benefitDeductionEnabled2: boolean): void {
    this.restrictions.canAdd = canAdd;
    this.restrictions.canEdit = canEdit;
    this.restrictions.canDelete = canDelete;
    this.restrictions.сanEditFormula = сanEditFormula;
    this.restrictions.canRenew = canRenew;
    this.restrictions.canExpire = canExpire;
    this.restrictions.canExtend = canExtend;
    this.restrictions.canEnroll = canEnroll;
    this.restrictions.canMapPayroll = canMapPayroll;
    this.restrictions.benefitDeduction2 = benefitDeductionEnabled2;
    
    this.emitActions();
  }

  public setOrgLevelPermissions(providerHasSelectedOrglevel: boolean, nonDepartmentOrglevel: boolean): void {
    if (_.isBoolean(providerHasSelectedOrglevel)) {
      this.restrictions.providerHasSelectedOrglevel = providerHasSelectedOrglevel;
    }
    if (_.isBoolean(nonDepartmentOrglevel)) {
      this.restrictions.nonDepartmentOrglevel = nonDepartmentOrglevel;
    }
    this.emitActions();
  }

  public subscribeToUserActions(callback: (v: BenefitDetailsUserActions) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.actions$.subscribe(callback);
  }

  public subscribeToSaveState(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.saveStateOnly$.subscribe(callback);
  }

  public subscribeToEditMode(callback: (v: BenefitDetailsEditModes) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.editMode$.subscribe(callback);
  }

  public setCanSave(canSave: boolean): void {
    if (_.isBoolean(canSave)) {
      this.validation.canSave = canSave;
    }
    this.saveStateOnly$.next(this.validation.canSave);
  }

  public toggleCanCreateEditDeleteSave(create: boolean, edit: boolean, del: boolean): void {
    if (_.isBoolean(create)) {
      this.validation.canCreate = create;
    }
    if (_.isBoolean(edit)) {
      this.validation.canEdit = edit;
    }
    if (_.isBoolean(del)) {
      this.validation.canDelete = del;
    }
    this.emitActions();
  }

  public toggleCanExpireExtendRenew(expire: boolean, extend: boolean, renew: boolean): void {
    if (_.isBoolean(expire)) {
      this.validation.canExpire = expire;
    }
    if (_.isBoolean(extend)) {
      this.validation.canExtend = extend;
    }
    if (_.isBoolean(renew)) {
      this.validation.canRenew = renew;
    }
    this.emitActions();
  }

  public toggleEditModeForProviderBenefitCoverageInfo(provider: boolean, benefit: boolean, coverage: boolean): void {
    if (_.isBoolean(provider)) {
      this.editMode.providerInfo = provider;
    }
    if (_.isBoolean(benefit)) {
      this.editMode.benefitInfo = benefit;
    }
    if (_.isBoolean(coverage)) {
      this.editMode.coverageOptions = coverage;
    }
    this.editMode$.next(_.cloneDeep(this.editMode));
  }

  public getEditMode (): BenefitDetailsEditModes {
    return _.cloneDeep(this.editMode);
  }

  public toggleCanAddCovOptions(canAdd: boolean): void {
    if (_.isBoolean(canAdd)) {
      this.restrictions.canAddCovOptions = canAdd;
    }
    this.emitActions();
  }


  private emitActions(): void {
    this.actions.canAdd = this.restrictions.canAdd && this.restrictions.nonDepartmentOrglevel && this.validation.canCreate;
    this.actions.canEdit = this.restrictions.canEdit && this.restrictions.providerHasSelectedOrglevel && this.validation.canEdit;
    this.actions.canDelete = this.restrictions.canDelete && this.restrictions.providerHasSelectedOrglevel && this.validation.canDelete;
    this.actions.canSave = this.validation.canSave;
    this.actions.enabledExpire = this.restrictions.canExpire;
    this.actions.enabledExtend = this.restrictions.canExtend;
    this.actions.enabledRenew = this.restrictions.canRenew;
    this.actions.canExpire = this.validation.canExpire;
    this.actions.canExtend = this.validation.canExtend;
    this.actions.canRenew = this.validation.canRenew;
    this.actions.сanEditFormula = this.restrictions.сanEditFormula;
    this.actions.canAddCovOptions = this.restrictions.canAddCovOptions;
    this.actions.canEnroll = this.restrictions.canEnroll;
    this.actions.canMapPayroll = this.restrictions.canMapPayroll;
    this.actions$.next(_.cloneDeep(this.actions));
  }
}
