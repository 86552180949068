import { WcCodeDefinition, IWcCodeDefinitionDto } from '../wc-code-definition';


export interface IWorkerCompTableModel {
  codeDefinition: IWcCodeDefinitionDto;
  modifiedBy: string;
  modifiedAt: string;
}

export class WorkCompTableTypeModel {
  public codeDefinition: WcCodeDefinition;
  public modifiedBy: string;
  public modifiedAt: Date;
}

export interface IWorkerCompTableModelContainer {
  items: IWorkerCompTableModel[];
  //canEdit: boolean;
}

export class WorkCompTableModelContainer {
  public static mock: IWorkerCompTableModelContainer = {
    //canEdit: true,
    items: [
      {
        codeDefinition: { id: 1, code: 'Type1', codeType: 'Model1', description: 'description1' },
        modifiedAt: '2019-07-11',
        modifiedBy: 'user1'
      },
      {
        codeDefinition: { id: 1, code: 'Type1', codeType: 'Model1', description: 'description1'},
        modifiedAt: '2019-07-11',
        modifiedBy: 'user1'
      },
      {
        codeDefinition: { id: 1, code: 'Type1', codeType: 'Model1', description: 'description1' },
        modifiedAt: '2019-07-11',
        modifiedBy: 'user1'
      },
      {
        codeDefinition: { id: 1, code: 'Type1', codeType: 'Model1', description: 'description1' },
        modifiedAt: '2019-07-11',
        modifiedBy: 'user1'
      }
    ]
  };
  public items: WorkCompTableTypeModel[];
  //public canEdit: boolean;

}

export interface IWorkCompTableEditResult {
  isSuccess: boolean;
  message?: string;
  item: IWorkerCompTableModel;
}

export class WorkCompTableEditResult {
  public isSuccess: boolean;
  public message?: string;
  public item: WorkCompTableTypeModel;
}
