import { IBudgetedParsDetailsContainer, BudgetedParsDetailsContainer } from './../../models/budgeted-pars/budgeted-pars-details-container';
import { OrgLevel } from '../../../state-model/models/index';
import { PostScheduleSettings, IPostScheduleSettings } from './../../models/open-shift-management/post-schedule-settings';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../../common/utils/index';
import { ScheduleDefinitionsMapService, EmployeeDefinitionsMapService, LookupMapService } from '../../../organization/services/index';
import { CalendarDataService } from '../../../core/services/index';

import {
  SummaryGridRecord, PostScheduleData, ISummaryRecord, IPostScheduleData, EmployeeScheduleCycleBackupDTO, Backup,
  PostedSchedule, IPostedSchedule, IScheduleTotalSummary, ScheduleTotalSummary, ScheduleTotal, IScheduleTotal, IDayTotal, DayTotal,
  IGetEmployeeSchedulesRequest, ScheduleSubtotal, IScheduleSubtotal, ISaveScheduleForPostingRequest, MasterScheduleFilters,
  ScheduleEntryEditItem, MasterScheduleShiftInfo, ScheduleEntryEditShift, IAutoSchedulingConfig, AutoSchedulingConfig,
  AutoSchedulingState, IAutoSchedulingState
} from '../../models/index';

import {
  IEmployeeScheduleDefinition, EmployeeScheduleDefinition,
  IScheduleEntryDefinition, ScheduleEntryDefinition,
  IScheduledShiftDefinition, ScheduledShiftDefinition, GenerateScheduleSummary, IGenerateScheduleSummary,
  ISaveMultipleIndividualScheduleRequest,
  Department, Position, ConstraintDefinition, LocationUnit, ScheduleAbsence, ShiftDefinition,
  SchedulePartialAbsence, PrescheduledOvertime, IModifyIndividualScheduleRequest, IChangedScheduledEntry, IChangedScheduledShiftDefinition, EmployeeScheduleDefinitionContainer, ScheduleActions, ScheduleActionsType, ScheduleFields
} from '../../../organization/index';
import { EmployeesStateSelector, EmployeesSelector } from '../../store/index';
import { IMasterScheduleFilters } from '../../store/master-schedule/master-schedule.types';
import { FieldsMeta, IFieldData, FieldAccessType } from '../../../core/models/index';
import { IBudgetedParsDetailsRow, BudgetedParsDetailsRow } from '../../models/budgeted-pars/budgeted-pars-details-row';
import { IScheduleNotPosted, ScheduleNotPosted } from '../../models/schedules-not-posted/schedule-not-posted';

@Injectable()
export class ScheduleMapService {

  constructor(private employeeDefinitionsMapService: EmployeeDefinitionsMapService, private lookupMapService: LookupMapService,
    private scheduleDefinitionsMapService: ScheduleDefinitionsMapService,
    private calendarDataService: CalendarDataService
  ) {
  }

  public mapToRequest(filters: IMasterScheduleFilters): IGetEmployeeSchedulesRequest {
    let req: IGetEmployeeSchedulesRequest = {
      startDate: dateTimeUtils.convertToDtoString(filters.dateFrom),
      weeksCount: filters.weekNumber,
      employeeTypes: filters.employeeType ? _.map(filters.employeeType, (t: any) => t) : [],
      shiftGroupIds: filters.shiftGroup ? [filters.shiftGroup] : [],
      positionGroupIds: filters.positionGroup ? [filters.positionGroup] : [],
      positionIds: filters.position ? [filters.position] : [],
      unitIds: filters.unit ? [filters.unit] : [],
      groupBy: (filters.groupBy && filters.groupBy.id) ? filters.groupBy.id : 1,
      showTotalsFTEs: filters.showTotalsFTEs || false,
    };
    return req;
  }

  public mapGenerateScheduleSummary(dto: IGenerateScheduleSummary): GenerateScheduleSummary {
    return this.scheduleDefinitionsMapService.mapGenerateScheduleSummary(dto);
  }

  public mapEmployeeScheduleContainerActions(meta: FieldsMeta): ScheduleActions {
    const actions = new ScheduleActions();

    _.forEach(meta.actions, (action: string) => {
      switch(action) {
        case ScheduleActionsType.backupSchedule:
          actions.canBackup = true;
          break;
        case ScheduleActionsType.deleteSchedule:
          actions.canDelete = true;
          break;
        case ScheduleActionsType.generateSchedule:
          actions.canGenerate = true;
          break;
        case ScheduleActionsType.postSchedule:
          actions.canPost = true;
          break;
        case ScheduleActionsType.restoreSchedule:
          actions.canRestore = true;
          break;
        case ScheduleActionsType.editSchedule:
          actions.canEdit = true;
          break;
          case ScheduleActionsType.editScheduleForApprovedPayPeriod:
            actions.canEditForApprovedPayPeriod = true;
            break;
          case ScheduleActionsType.accessEmployeeRecord:
          actions.canAccessEmployeeRecord = true;
          break;
      }
    });

    actions.canEditRotations = _.some(meta.fields, (field: IFieldData) => {
      return field.fieldName === ScheduleFields.rotationsDataFieldName &&
        field.access === FieldAccessType.full;
    });

    return actions;
  }

  public mapEmployeeScheduleDefinitionContainer(records: IEmployeeScheduleDefinition[], meta: FieldsMeta): EmployeeScheduleDefinitionContainer {
    const container = new EmployeeScheduleDefinitionContainer();

    container.actions = this.mapEmployeeScheduleContainerActions(meta);
    container.definitions = this.mapEmployeeScheduleDefinitions(records);

    return container;
  }

  public mapEmployeeScheduleDefinitions(records: IEmployeeScheduleDefinition[]): EmployeeScheduleDefinition[] {
    return _.map(records, (record: IEmployeeScheduleDefinition) => {
      return this.mapEmployeeScheduleDefinition(record);
    });
  }

  public mapEmployeeScheduleDefinition(record: IEmployeeScheduleDefinition): EmployeeScheduleDefinition {
    return this.scheduleDefinitionsMapService.mapToEmployeeScheduleDefinition(record);
  }

  mapScheduleTotalSummary(dto: IScheduleTotalSummary): ScheduleTotalSummary {
    let data: ScheduleTotalSummary = new ScheduleTotalSummary();
    data.scheduleSubtotals = _.map(dto.scheduleSubtotals, (d: IScheduleSubtotal) => this.mapScheduleSubtotal(d));
    data.scheduleTotals = _.map(dto.scheduleTotals, (d: IScheduleTotal) => this.mapScheduleTotal(d));

    let totals: ScheduleTotal = new ScheduleTotal();
    totals.totalName = 'Total';
    totals.dayTotals = {};

    let total: ScheduleTotal = _.reduce(data.scheduleSubtotals, (acc: ScheduleTotal, sub: ScheduleSubtotal) => {
      _.forIn(sub.dayTotals, (day: DayTotal) => {
        let dateOn = EmployeesStateSelector.getDateKey(day.date);
        if (!acc.dayTotals[dateOn]) {
          acc.dayTotals[dateOn] = new DayTotal();
          acc.dayTotals[dateOn].date = day.date;
          acc.dayTotals[dateOn].value = 0;
          acc.dayTotals[dateOn].fteValue = 0;
        }
        acc.dayTotals[dateOn].value += day.value;
        acc.dayTotals[dateOn].fteValue += day.fteValue;
      });
      return acc;
    }, totals);
    data.scheduleTotals.push(total);
    return data;
  }

  mapScheduleTotal(dto: IScheduleTotal): ScheduleTotal {
    let data: ScheduleTotal = new ScheduleTotal();
    data.totalName = dto.totalName;
    data.dayTotals = _.keyBy(_.map(dto.dayTotals, (day: IDayTotal) => this.mapDayTotal(day)), (day: DayTotal) => moment(day.date).format(appConfig.dateFormat));
    return data;
  }

  mapScheduleSubtotal(dto: IScheduleSubtotal): ScheduleSubtotal {
    let data: ScheduleSubtotal = new ScheduleSubtotal();
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.dayTotals = _.keyBy(_.map(dto.dayTotals, (day: IDayTotal) => this.mapDayTotal(day)), (day: DayTotal) => moment(day.date).format(appConfig.dateFormat));
    return data;
  }

  mapDayTotal(dto: IDayTotal): DayTotal {
    let data: DayTotal = new DayTotal();
    data.date = dateTimeUtils.convertFromDtoString(dto.date);
    data.value = dto.value;
    data.fteValue = dto.fteValue;
    return data;
  }

  public mapSummaryRecord(raw: ISummaryRecord): SummaryGridRecord {

    let record: SummaryGridRecord = new SummaryGridRecord();
    record.approaching = raw.approaching;
    record.date = raw.date;
    record.openShifts = raw.openShifts;
    record.par = raw.par;
    record.scheduled = raw.scheduled;
    record.shift = raw.shift;
    record.position = raw.position;
    record.unit = raw.unit;

    return record;
  }

  public mapPostScheduleData(raw: IPostScheduleData): PostScheduleData {

    if (!raw) return undefined;

    let record: PostScheduleData = new PostScheduleData();

    record.startDate = moment(raw.startDate).toDate();
    record.endDate = moment(raw.endDate).toDate();
    record.status = raw.status;

    record.entities = [];

    _.forEach(raw.entities, (item: ISummaryRecord) => {
      let r: SummaryGridRecord = this.mapSummaryRecord(item);
      record.entities.push(r);
    });

    return record;
  }

  public mapToBackup(data: EmployeeScheduleCycleBackupDTO): Backup {
    let backup: Backup = new Backup();
    backup.id = data.id;
    backup.username = data.username;
    backup.createdAt = moment(data.creationDate);
    backup.comment = data.comment;
    backup.startDate = moment(data.startDate);
    backup.endDate = moment(data.endDate);
    backup.employee = data.employee ? this.employeeDefinitionsMapService.mapToEmployeeDefinition(data.employee) : null;
    backup.numberOfRecords = data.numberOfRecords;
    backup.isFull = data.isFull;
    return backup;
  }

  public mapPostedSchedule(dto: IPostedSchedule, ind: number): PostedSchedule {
    let data: PostedSchedule = new PostedSchedule();
    data.organization = this.lookupMapService.mapOrganization(dto.organization);
    data.department = this.lookupMapService.mapDepartment(dto.department);
    data.scheduleCycle = this.lookupMapService.mapScheduleCycle(dto.scheduleCycle, ind);
    data.postedDate = dateTimeUtils.getUtcDateTime(dto.postedDate);
    data.openShifts = dto.openShifts;
    data.username = dto.username;
    return data;
  }

  public mapPostedSchedules(schedules: IPostedSchedule[]): PostedSchedule[] {
    let ind = 0;
    return _.map(schedules, (dto: IPostedSchedule) => {
      ind++;
      return this.mapPostedSchedule(dto, ind);
    });
  }
  public mapSchedulesNotPosted(schedules: IScheduleNotPosted[]): ScheduleNotPosted[] {
    let ind = 0;
    return _.map(schedules, (dto: IScheduleNotPosted) => {
      ind++;
      return this.mapPostednotSchedule(dto, ind);
    });
  }
  public mapPostednotSchedule(dto: IScheduleNotPosted, ind: number): ScheduleNotPosted {
    let data: ScheduleNotPosted = new ScheduleNotPosted();
    data.organization = this.lookupMapService.mapOrganization(dto.organization);
    data.department = this.lookupMapService.mapDepartment(dto.department);
    data.scheduleCycle = this.lookupMapService.mapScheduleCycle(dto.scheduleCycle, ind);
    data.schedulecyclestart = dto.schedulecyclestart;
    data.countOfEmployees = dto.countOfEmployees;
    data.countOfshifts = dto.countOfshifts;
    return data;
  }

  public mapSaveScheduleForPostingRequestDTO(orgLevel: OrgLevel, startDate: Date, settings: PostScheduleSettings): ISaveScheduleForPostingRequest {
    let dto: ISaveScheduleForPostingRequest = {
      startDate: dateTimeUtils.convertToDtoString(startDate),
      orgLevelId: orgLevel.id,
      postingScheduleOptions: this.mapPostScheduleSettingsDTO(settings)
    };
    return dto;
  }

  public mapPostScheduleSettings(dto: IPostScheduleSettings): PostScheduleSettings {
    let settings: PostScheduleSettings = new PostScheduleSettings();
    settings.offerToApproachingOT = dto.offerToApproachingOT;
    settings.offerToSameDay = dto.offerToSameDay;
    settings.offerToSecondaryPositions = dto.offerToSecondaryPositions;
    settings.allowAutoShiftPickUpByEmployee=dto.allowAutoShiftPickUpByEmployee;
    settings.notifyOnFirstPost = dto.notifyOnFirstPost;
    settings.notifyOnAvailability = dto.notifyOnAvailability;
    settings.notifyWeekly = dto.notifyWeekly;
    settings.notifyDaysAndWeekly=dto.notifyDaysAndWeekly;
    settings.reminderCount = dto.reminderCount;
    settings.notifySenioritiesFirst = dto.notifySenioritiesFirst;
    settings.startDate = moment(dto.startDate).toDate();
    settings.endDate = moment(dto.endDate).toDate();
    settings.offerToPositionGrouping = dto.offerToPositionGrouping;
    return settings;
  }

  public mapPostScheduleSettingsDTO(settings: PostScheduleSettings): IPostScheduleSettings {
    return {
      offerToApproachingOT: settings.offerToApproachingOT,
      offerToSameDay: settings.offerToSameDay,
      offerToSecondaryPositions: settings.offerToSecondaryPositions,
      allowAutoShiftPickUpByEmployee:settings.allowAutoShiftPickUpByEmployee,
      notifyOnFirstPost: settings.notifyOnFirstPost,
      notifyOnAvailability: settings.notifyOnAvailability,
      notifyWeekly: settings.notifyWeekly,
      reminderCount : settings.reminderCount,
      notifySenioritiesFirst: settings.notifySenioritiesFirst,
      startDate: dateTimeUtils.convertToDtoString(settings.startDate),
      endDate: dateTimeUtils.convertToDtoString(settings.endDate),
      notifyDaysAndWeekly: settings.notifyDaysAndWeekly,
      offerToPositionGrouping : settings.offerToPositionGrouping
    };
  }

  public mapQuickEditToRequest(items: ScheduleEntryEditItem[]): ISaveMultipleIndividualScheduleRequest {
    const itemsByEmployee = _.groupBy(items, (item: ScheduleEntryEditItem) => item.employeeId);
    const requests = _.mapValues(itemsByEmployee, (empItems: ScheduleEntryEditItem[]) => {
      const first = _.first(empItems);
      if (first) {
        return this.mapEmpQuickEditToRequest(first.employeeId, empItems);
      }
      return null;
    });
    const req: ISaveMultipleIndividualScheduleRequest = {
      entries: _.values(requests)
    };
    return req;
  }
  public mapEmpQuickEditToRequest(employeeId: number, items: ScheduleEntryEditItem[]): IModifyIndividualScheduleRequest {
    const req: IModifyIndividualScheduleRequest = {
      employeeId: employeeId,
      changedScheduleEntries: _.map(items, (item: ScheduleEntryEditItem) => this.mapToChangedScheduledEntry(item)),
      datesToClear: []
    };
    return req;
  }

  public mapToChangedScheduledEntry(item: ScheduleEntryEditItem): IChangedScheduledEntry {
    const entry: IScheduleEntryDefinition = this.mapEmpScheduleQuickEditToScheduleEntry(item);
    const shiftsToRemove = _.map(item.shiftsToRemove, (shiftInfo: MasterScheduleShiftInfo) => this.mapShiftInfoToScheduledShiftDefinition(shiftInfo));
    const changedEntry: IChangedScheduledEntry = {
      entry: entry,
      addedShifts: entry.shifts,
      removedShifts: _.map(shiftsToRemove, (shift: ScheduledShiftDefinition) => this.scheduleDefinitionsMapService.mapToScheduledShiftDefinitionDto(shift)),
      modifiedShifts: _.map(item.shiftsToModify, (s: ScheduleEntryEditShift) => this.mapToChangedScheduledShift(s)),
    };
    return changedEntry;
  }

  public mapToChangedScheduledShift(changedScheduledShift: ScheduleEntryEditShift): IChangedScheduledShiftDefinition {
    const dto: IChangedScheduledShiftDefinition = {
      scheduledShift: this.scheduleDefinitionsMapService.mapToScheduledShiftDefinitionDto(this.mapShiftInfoToScheduledShiftDefinition(changedScheduledShift.scheduledShift)),
      startDate:  dateTimeUtils.convertToDtoDateTimeString(changedScheduledShift.startDate),
      endDate:  dateTimeUtils.convertToDtoDateTimeString(changedScheduledShift.endDate),
      duration: dateTimeUtils.convertFromNumberToDtoDurationString(changedScheduledShift.duration, 's')
    };
    return dto;
  }
  public mapEmpScheduleQuickEditToScheduleEntry(item: ScheduleEntryEditItem): IScheduleEntryDefinition {
    const scheduleDefinition: ScheduleEntryDefinition = new ScheduleEntryDefinition();
    scheduleDefinition.dateOn = item.date;
    const shifts = [item.shift];
    scheduleDefinition.shifts = _.map(shifts, (shiftInfo: MasterScheduleShiftInfo) => this.mapShiftInfoToScheduledShiftDefinition(shiftInfo));
    return this.scheduleDefinitionsMapService.mapToScheduleEntryDefinitionDto(scheduleDefinition);
  }

  public mapShiftInfoToScheduledShiftDefinition(shiftInfo: MasterScheduleShiftInfo): ScheduledShiftDefinition {
    const shiftDefinition: ScheduledShiftDefinition = new ScheduledShiftDefinition();
    shiftDefinition.department = new Department();
    shiftDefinition.department.id = shiftInfo.departmentId;
    shiftDefinition.department.name = shiftInfo.departmentName;
    shiftDefinition.department.orgLevelId = shiftInfo.departmentOrgLevelId;
    shiftDefinition.position = new Position();
    shiftDefinition.position.id = shiftInfo.position.id;
    shiftDefinition.position.name = shiftInfo.position.name;
    shiftDefinition.shift = new ShiftDefinition(shiftInfo.shiftId, shiftInfo.shiftName,
      dateTimeUtils.convertToDtoHourMinutesString(shiftInfo.shiftDefStart),
      dateTimeUtils.convertToDtoHourMinutesString(shiftInfo.shiftDefEnd),
      dateTimeUtils.convertFromNumberToDtoDurationString(shiftInfo.duration, 's')
    );
    if (shiftInfo.unitId) {
      shiftDefinition.unit = new LocationUnit();
      shiftDefinition.unit.id = shiftInfo.unitId;
      shiftDefinition.unit.name = shiftInfo.unitName;
    }

    if (shiftInfo.constraintId) {
      shiftDefinition.constraint = new ConstraintDefinition();
      shiftDefinition.constraint.id = shiftInfo.constraintId;
      shiftDefinition.constraint.name = shiftInfo.constraintCode;
    }

    if (shiftInfo.absenceCode) {
      shiftDefinition.absence = new ScheduleAbsence();
      shiftDefinition.absence.code = shiftInfo.absenceCode;
      shiftDefinition.absence.description = shiftInfo.absenceName;
    }

    if (shiftInfo.partialAbsenceCode) {
      shiftDefinition.partialAbsence = new SchedulePartialAbsence();
      shiftDefinition.partialAbsence.code = shiftInfo.partialAbsenceCode;
      shiftDefinition.partialAbsence.description = shiftInfo.partialAbsenceName;
      shiftDefinition.partialAbsence.start = shiftInfo.partialAbsenceStart;
      shiftDefinition.partialAbsence.end = shiftInfo.partialAbsenceEnd;
      shiftDefinition.partialAbsence.duration = shiftInfo.partialAbsenceDuration;
    }

    if (shiftInfo.prescheduledOvertimeDuration) {
      shiftDefinition.prescheduledOvertime = new PrescheduledOvertime();
      shiftDefinition.prescheduledOvertime.start = shiftInfo.prescheduledOvertimeStart;
      shiftDefinition.prescheduledOvertime.end = shiftInfo.prescheduledOvertimeEnd;
      shiftDefinition.prescheduledOvertime.duration = dateTimeUtils.convertFromNumberToDtoDurationString(shiftInfo.prescheduledOvertimeDuration, 'ms');
    }
    shiftDefinition.start = shiftInfo.shiftStart;
    shiftDefinition.end = shiftInfo.shiftEnd;
    shiftDefinition.duration = shiftInfo.duration;
    return shiftDefinition;
  }

  public mapAutoSchedulingConfig(configDTO: IAutoSchedulingConfig): AutoSchedulingConfig {
    return new AutoSchedulingConfig(configDTO);
  }

  public mapAutoSchedulingState(dto: IAutoSchedulingState): AutoSchedulingState {
    const data = new AutoSchedulingState();
    data.config = this.mapAutoSchedulingConfig(dto.config);
    data.lastScheduleState = dto.lastScheduleState;
    data.lastScheduleTime = dateTimeUtils.convertFromDtoDateTimeString(dto.lastScheduleTime);
    data.nextSchedule = dateTimeUtils.convertFromDtoDateTimeString(dto.nextSchedule);
    data.userHasAccess = dto.userHasAccess;
    return data;
  }

  public mapToDTOAutoSchedulingConfig(config: AutoSchedulingConfig): IAutoSchedulingConfig {
    const settingsDTO: IAutoSchedulingConfig = {
      daysBeforeGenerating: config.daysBeforeGenerating,
      daysBeforePosting: config.daysBeforePosting,
    };

    return settingsDTO;
  }

  public mapBudgetedParsDetailsContainer(dto: IBudgetedParsDetailsContainer): BudgetedParsDetailsContainer {
    const container: BudgetedParsDetailsContainer = new BudgetedParsDetailsContainer();
    container.details = _.map(dto.details, d => this.mapBudgetedParsDetailsRow(d));
    return container;
  }

  public mapBudgetedParsDetailsRow(dto: IBudgetedParsDetailsRow): BudgetedParsDetailsRow {
    const row: BudgetedParsDetailsRow = new BudgetedParsDetailsRow();
    row.budgetedHours = _.round(dto.budgetedHours, 2);
    row.scheduledHours = _.round(dto.scheduledHours, 2);
    row.positionId = dto.positionId;
    row.discrepency = _.round(row.scheduledHours - row.budgetedHours, 2);
    if (row.discrepency === 0) {
      row.comment = 'At Par';
    } else {
      if (row.discrepency < 0) {
        row.comment = 'Under Scheduled';
      } else {
        row.comment = 'Over Scheduled';
      }
    }
    return row;
  }
}
