import { AccrualPolicy, AccrualTypes } from '../../models/index';
export class PayCode {
    public id: number;
    public description: string;
    public department: string;
    public color: number;
    public isPaid: Boolean;
    public isPbj: Boolean;
    public isAca: Boolean;
    public isException: Boolean;
    public payDifferential: string;
    public amount: string;
    public group: string;
    public allocationType: string;
    public interfaceIndicator: string;
    public organizationId: number;
    public isPbjApplicable: boolean;
    public useInTimesheets: boolean;
    public isUsedInAccrualCalc: Boolean;
    public accrualPolicies: string[];
    public accrualType: string;
    public accrualPolicyList: AccrualPolicy[];
    public accrualPolicyNames: string;
    public accrualTypes: AccrualTypes;
    public allocationTypeId :any ;
    public groupId : any;
}

export interface IPayCodeDTO {
    id: number;
    description: string;
    department: string;
    color: number;
    isPaid: Boolean;
    isPbj: Boolean;
    isAca: Boolean;
    isException: Boolean;
    payDifferential: string;
    amount: string;
    group: string;
    allocationType: string;
    interfaceIndicator: string;
    organizationId: number;
    isPbjApplicable: boolean;
    useInTimesheets: boolean;
    isUsedInAccrualCalc: Boolean;
    accrualPolicies: string[];
    accrualType: string;
    allocationTypeId :any ;
    groupId : any;
}
