import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { initialAcaC1095AuditState, AcaC1095AuditFilterState } from '../../models/aca-c1095-audit/aca-c1095-audit-state';
import { AcaC1095AuditApiService } from './aca-c1095-audit-api.service';
import { StateManagementService } from '../../../common';
import { StateResetTypes } from '../../../core/models';
import { EmployeeFilterRecord, EmployeeFilter } from '../../models/index';
var AcaC1095AuditManagementService = /** @class */ (function () {
    function AcaC1095AuditManagementService(apiService, stateManagement) {
        this.apiService = apiService;
        this.stateManagement = stateManagement;
        this.expandedEmployeeList = [];
        this.acaFilterState = new AcaC1095AuditFilterState();
        this.loading$ = new Subject();
        this.recordsLoaded$ = new Subject();
        this.exportTo$ = new Subject();
        this.expandAll$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.employeeFilter$ = new Subject();
        this.employeeFilterRecords$ = new ReplaySubject(1);
        this.filter = [];
        this.stateKey = 'AcaC1095AuditState';
        this.subscriptions = {};
    }
    AcaC1095AuditManagementService.prototype.init = function () {
        this.subscribeToOrgLevelChanges();
    };
    AcaC1095AuditManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.exportTo$.complete();
        this.expandAll$.complete();
        this.orgLevelChanged$.complete();
        this.employeeFilterRecords$.complete();
    };
    AcaC1095AuditManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    AcaC1095AuditManagementService.prototype.expandAll = function (isExpand) {
        this.expandAll$.next(isExpand);
    };
    AcaC1095AuditManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    AcaC1095AuditManagementService.prototype.subscribeToExpandAll = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.expandAll$.subscribe(callback);
    };
    AcaC1095AuditManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    AcaC1095AuditManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    AcaC1095AuditManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    AcaC1095AuditManagementService.prototype.subscribeToEmployeeFilter = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.employeeFilter$.subscribe(callback);
    };
    AcaC1095AuditManagementService.prototype.subscribeToEmpFilterRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.employeeFilterRecords$.subscribe(callback);
    };
    AcaC1095AuditManagementService.prototype.loadAcaC1095AuditRecords = function (year, acaFilterState) {
        var _this = this;
        if (!_.isFinite(_.get(this.orgLevel, 'id'))) {
            return;
        }
        this.loading$.next(true);
        this.apiService.getC1095AuditRecords(this.orgLevel.id, year)
            .then(function (container) {
            if (acaFilterState && acaFilterState.employeeIdList) {
                var filteredRecords = _.forEach(container.records, function (r) {
                    _.forEach(acaFilterState.employeeIdList, function (k) {
                        if (k.employeeId === r.employeeId) {
                            r.expand = k.expand;
                            r.expandMonth = k.expandMonth;
                            return false;
                        }
                    });
                });
                container.records = filteredRecords;
            }
            if (acaFilterState && (acaFilterState.employeeIdList == null || acaFilterState.employeeIdList.length == 0)) {
                var filteredRecords = _.forEach(container.records, function (r) {
                    r.expand = acaFilterState.isExpandAll;
                    r.expandMonth = acaFilterState.isExpandAll;
                });
                container.records = filteredRecords;
            }
            _this.originalContainer = container;
            _this.recordsLoaded$.next(container);
            var employeeFilter = new EmployeeFilter();
            employeeFilter.employeeFilterRecord = [];
            employeeFilter.filter = [];
            if (container && container.records) {
                _.forEach(container.records, function (item) {
                    var empItem = new EmployeeFilterRecord();
                    empItem.id = item.employeeId;
                    empItem.name = item.employeeName;
                    employeeFilter.employeeFilterRecord.push(empItem);
                });
                if (acaFilterState && acaFilterState.employeeListFilter) {
                    employeeFilter.filter = acaFilterState.employeeListFilter;
                }
                _this.employeeFilter$.next(employeeFilter);
            }
            _this.loading$.next(false);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    AcaC1095AuditManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
            if (_this.selectedYear) {
                _this.restoreState();
            }
        });
    };
    AcaC1095AuditManagementService.prototype.setEmployeeIdList = function (item) {
        if (this.expandedEmployeeList.indexOf(item) === -1) {
            if (item.expand || item.expandMonth) {
                this.expandedEmployeeList.push(item);
            }
            else {
                var list = _.find(this.expandedEmployeeList, { 'employeeId': item.employeeId });
                this.expandedEmployeeList = _.without(this.expandedEmployeeList, list);
            }
            this.setC1095AuditState();
        }
    };
    AcaC1095AuditManagementService.prototype.setEmployeeGridExpandCollapse = function (isExpand) {
        this.isExpandAll = isExpand;
        this.expandedEmployeeList = [];
        this.setC1095AuditState();
    };
    AcaC1095AuditManagementService.prototype.setSelectedYear = function (selYear) {
        this.selectedYear = selYear;
        this.restoreState();
    };
    AcaC1095AuditManagementService.prototype.setC1095AuditState = function () {
        if (!this.selectedYear) {
            return;
        }
        var state = _.clone(initialAcaC1095AuditState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        var acaState = new AcaC1095AuditFilterState();
        acaState.year = this.selectedYear;
        acaState.orgLevelId = this.orgLevel.id;
        if (this.expandedEmployeeList && this.expandedEmployeeList.length > 0) {
            acaState.employeeIdList = this.expandedEmployeeList;
        }
        if (this.expandedEmployeeList && this.expandedEmployeeList.length == 0) {
            acaState.isExpandAll = this.isExpandAll;
        }
        if (state && state.acaC1095AuditStateList && state.acaC1095AuditStateList.length > 0) {
            var index = _.findIndex(state.acaC1095AuditStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
            if (index >= 0) {
                var item = _.find(state.acaC1095AuditStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
                state.acaC1095AuditStateList = _.without(state.acaC1095AuditStateList, item);
                state.acaC1095AuditStateList.push(acaState);
            }
            else {
                state.acaC1095AuditStateList.push(acaState);
            }
        }
        else {
            state.acaC1095AuditStateList = [];
            state.acaC1095AuditStateList = [acaState];
        }
        this.saveState(state);
    };
    AcaC1095AuditManagementService.prototype.saveState = function (state) {
        this.stateManagement.setControlState(this.stateKey, {
            value: state
        }, StateResetTypes.None);
    };
    AcaC1095AuditManagementService.prototype.restoreState = function () {
        if (!this.selectedYear) {
            return;
        }
        var state = _.clone(initialAcaC1095AuditState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        if (state && state.acaC1095AuditStateList) {
            if (_.findIndex(state.acaC1095AuditStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id }) >= 0) {
                this.acaFilterState = _.find(state.acaC1095AuditStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
            }
            else {
                this.acaFilterState = null;
            }
        }
        else {
            this.acaFilterState = null;
        }
        this.loadAcaC1095AuditRecords(this.selectedYear, this.acaFilterState);
    };
    AcaC1095AuditManagementService.prototype.setEmployeeFilterRecords = function (empFilter) {
        this.employeeFilter$.next(empFilter);
    };
    AcaC1095AuditManagementService.prototype.changeEmployeeFilter = function (filters) {
        this.employeeFilterRecords$.next(filters);
    };
    AcaC1095AuditManagementService.prototype.setEmployeeFilter = function (filters) {
        this.filter = filters;
        this.setC1095AuditState();
        this.changeEmployeeFilter(filters);
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], AcaC1095AuditManagementService.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], AcaC1095AuditManagementService.prototype, "orgLevel$", void 0);
    return AcaC1095AuditManagementService;
}());
export { AcaC1095AuditManagementService };
