import * as tslib_1 from "tslib";
import * as _ from 'lodash';
var AccrualPolicyAssignment = /** @class */ (function () {
    function AccrualPolicyAssignment() {
    }
    return AccrualPolicyAssignment;
}());
export { AccrualPolicyAssignment };
var AccrualType = /** @class */ (function () {
    function AccrualType() {
    }
    return AccrualType;
}());
export { AccrualType };
var AccrualTypeBalance = /** @class */ (function (_super) {
    tslib_1.__extends(AccrualTypeBalance, _super);
    function AccrualTypeBalance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AccrualTypeBalance;
}(AccrualType));
export { AccrualTypeBalance };
var AccrualBalanceRecord = /** @class */ (function () {
    function AccrualBalanceRecord() {
        this.accrualTypes = [];
    }
    AccrualBalanceRecord.prototype.isNegative = function (accrualTypeName, property) {
        return _.get(this.accrualTypesMap[accrualTypeName], "" + property, 0) < 0;
    };
    return AccrualBalanceRecord;
}());
export { AccrualBalanceRecord };
var AccrualBalances = /** @class */ (function () {
    function AccrualBalances() {
    }
    return AccrualBalances;
}());
export { AccrualBalances };
