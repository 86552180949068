import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { AcaMeasurement, EmployeeFilterRecord, AcaMeasurementRecord, EmployeeFilter, PeriodsPopupModel, filterAcaMeasurementRecord, IAcaMeasurement } from '../../models/index';
import { IAcaMeasurementState, initialAcaMeasurementState, EmployeeIdList, AcaMeasurementFilterState } from '../../models/aca-measurement/aca-measurement-state';
import { AcaMeasurementApiService } from './aca-measurement-api.service';
import { AcaMeasurementSettings, AcaMeasurementColumnsSettings } from '../../models/aca-measurement/aca-measurement-settings';
import { ColumnManagementService, ColumnsChangedEvent, StateManagementService } from '../../../common';
import { AcaMeasurementInsights } from '../../models';
import { StateResetTypes } from '../../../core/models';

@Injectable()
export class AcaMeasurementManagementService {

  private orgLevel: OrgLevel;
  private selectedYear: number;
  private acaInsightFilter: filterAcaMeasurementRecord;
  private originalContainer: AcaMeasurement;
  public filter: EmployeeFilterRecord[] = [];
  public employeeIdList: EmployeeIdList[] = [];
  public expandAll: boolean = false;
  public acaFilterState: AcaMeasurementFilterState = new AcaMeasurementFilterState();

  private loading$ = new Subject<boolean>();
  private recordsLoaded$ = new Subject<AcaMeasurement>();
  private employeeFilter$ = new Subject<EmployeeFilter>();
  private employeeFilterRecords$= new ReplaySubject<EmployeeFilterRecord[]>(1);
  private exportTo$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private stateChanged$ = new BehaviorSubject<IAcaMeasurementState>(initialAcaMeasurementState);
  private employeeIdList$ = new ReplaySubject<EmployeeIdList>(1);
  private togglePopper$ = new ReplaySubject<PeriodsPopupModel>(1);
  private currentYear$ = new ReplaySubject<number>(1);
  private insightsLoaded$ = new Subject<AcaMeasurementInsights>();
  public onSettingsChanged$: ReplaySubject<AcaMeasurementSettings>;
  public acaFilterRecord$ = new Subject<filterAcaMeasurementRecord>();
  private readonly stateKey: string = 'AcaMeasurementState';
  public state: IAcaMeasurementState;
  private isExpandAll$ = new Subject<boolean>();


  @unsubscribeAll('destroy')
  public subscriptions: StringMap<Subscription> = {};

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  constructor(
    private apiService: AcaMeasurementApiService,
    private columnManagement: ColumnManagementService,
    private stateManagement: StateManagementService
  ) {
    this.onSettingsChanged$ = new ReplaySubject(1);
  }

  public init(): void {
    this.subscribeToOrgLevelChanges();
    this.columnSettingsChanged();
  }

  public destroy(): void {
    this.orgLevel = null;
    this.loading$.complete();
    this.recordsLoaded$.complete();
    this.employeeFilterRecords$.complete();
    this.exportTo$.complete();
    this.orgLevelChanged$.complete();
    this.stateChanged$.complete();
    this.employeeIdList$.complete();
    this.togglePopper$.complete();
    this.currentYear$.complete();
    this.insightsLoaded$.complete();
    this.acaFilterRecord$.complete();
    this.employeeFilter$.complete();
    this.isExpandAll$.complete();
  }

  public updateState(state: IAcaMeasurementState): void {
    this.stateChanged$.next(state);
  }

  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }

  public isExpandAll(isExpand: boolean) : void {
    this.expandAll = isExpand;
    this.setMeasurementState();
    this.restoreState();
    this.isExpandAll$.next(isExpand);
  }

  public subscribeToState(callback: (state: IAcaMeasurementState) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.stateChanged$.subscribe(callback);
  }

  public subscribeToExport(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportTo$.subscribe(callback);
  }

  public subscribeToIsExpandAll(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.isExpandAll$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (b: AcaMeasurement) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recordsLoaded$.subscribe(callback);
  }

  public subscribeToEmpFilterRecords(callback: (b: EmployeeFilterRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.employeeFilterRecords$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public subscribeToEmployeeFilter(callback: (f: EmployeeFilter) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.employeeFilter$.subscribe(callback);
  }

  public subscribeToEmployeeIdList(callback: (f: EmployeeIdList) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.employeeIdList$.subscribe(callback);
  }

  public subscribeToTogglePopper(callback: (f: PeriodsPopupModel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.togglePopper$.subscribe(callback);
  }

  public subscribeToCurrentYear(callback: (f: number) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.currentYear$.subscribe(callback);
  }

  public subscribeToLoadedInsights(callback: (b: AcaMeasurementInsights) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.insightsLoaded$.subscribe(callback);
  }

  public loadAcaMeasurement(year: number, acaFilterState: AcaMeasurementFilterState): void {
    if (!_.isFinite(_.get(this.orgLevel, 'id'))) {
      return;
    }

    this.loading$.next(true);
    this.apiService.getAcaMeasurement(this.orgLevel.id, year)
      .then((container: AcaMeasurement) => {
        if (acaFilterState ) {
            let filteredRecords: AcaMeasurementRecord[] = _.forEach(container.records, (r: AcaMeasurementRecord) => {
              if(acaFilterState.isExpandAll)
              {
                r.expand = true;
              }
              else
              {
                r.expand = false;
                if (acaFilterState.employeeIdList) {
                  _.forEach(acaFilterState.employeeIdList, (k) => {
                    if (k.employeeId === r.empId) {
                      r.expand = true;
                      return false;
                    }
                  });
                }
              }
            });
            container.records = filteredRecords
          }
        this.originalContainer = container;
        this.recordsLoaded$.next(container);
        this.insightsLoaded$.next(container.insightSummary);

        let employeeFilter: EmployeeFilter = new EmployeeFilter();
          employeeFilter.employeeFilterRecord = [];
          employeeFilter.filter = [];
          if (container && container.records) {
            _.forEach(container.records, function (item) {
              let empItem: EmployeeFilterRecord = new EmployeeFilterRecord();
              empItem.id = item.empId;
              empItem.name = item.empName;
              employeeFilter.employeeFilterRecord.push(empItem);
            });

          if (acaFilterState && acaFilterState.employeeListFilter) {
            employeeFilter.filter = acaFilterState.employeeListFilter;
          }

          this.employeeFilter$.next(employeeFilter);
        }

        this.loading$.next(false);
      })
      .catch(() => {
        this.loading$.next(false);
      });
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe((orgLevel: OrgLevel) => {
          if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        if (this.selectedYear) {
          this.restoreState();
        }
      });
  }

  public settingsChanged(columnsSettings: AcaMeasurementSettings): void {
    this.onSettingsChanged$.next(columnsSettings);
  }

  private columnSettingsChanged():void {
    this.subscriptions.changedColumns = this.columnManagement.columnsChanged$.filter((event: ColumnsChangedEvent) => event.group === 'AcaMeasurement')
      .subscribe((event: ColumnsChangedEvent) => {
        let columnsSettings: AcaMeasurementSettings = new AcaMeasurementSettings();
        columnsSettings.columns = new AcaMeasurementColumnsSettings();
        columnsSettings.columns.columns = [];
        columnsSettings.columns.columns = event.columns;
        columnsSettings.columns.mapColumns();
        this.onSettingsChanged$.next(columnsSettings);
      });
  }

  public changeEmployeeFilter(filters: EmployeeFilterRecord[]): void {
    this.employeeFilterRecords$.next(filters);
  }

  public setEmployeeIdList(item: EmployeeIdList): void {
    if (this.employeeIdList.indexOf(item) === -1) {
      if (item.expand) {
        this.employeeIdList.push(item);
      }
      else {
        let list = _.find(this.employeeIdList, { 'employeeId': item.employeeId });
        this.employeeIdList = _.without(this.employeeIdList, list);
      }
      this.setMeasurementState();
    }
  }

  public togglePopper(state: PeriodsPopupModel): void {
    this.togglePopper$.next(state);
  }

  public setCurrentYear(state: number): void {
    this.currentYear$.next(state);
  }

  public filterAcaRecords(acaFilterRecord: filterAcaMeasurementRecord): void {
    this.acaFilterRecord$.next(acaFilterRecord);
  }

  public setEmployeeFilterRecords(empFilter: EmployeeFilter): void {
    this.employeeFilter$.next(empFilter);
  }

  public setSelectedYear(selYear: number) {
    this.selectedYear = selYear;
    this.isExpandAll$.next(this.expandAll);
    this.setCurrentYear(selYear);
    this.restoreState();

  }

  public setInsightsFilter(insightsFilter: filterAcaMeasurementRecord) {
    this.acaInsightFilter = insightsFilter;
    this.applyInsightFilter();
  }

  public setEmployeeFilter(filters: EmployeeFilterRecord[]) {
    this.filter = filters;
    this.setMeasurementState();
    this.changeEmployeeFilter(filters);
  }

  private applyInsightFilter(): void {
    let container: AcaMeasurement = _.clone(this.originalContainer);

    let records: any = container.records;
    if (this.acaInsightFilter && this.acaInsightFilter.isClicked) {
      records = _.filter(records, (r: AcaMeasurementRecord) => {
        let res = false;

        if (this.acaInsightFilter.isCurrentMonth && this.acaInsightFilter.isMP && !_.isNull(r.currentMP)) {
          res = (this.acaInsightFilter.type == "TDE" && (r.currentMP.ptDeterminedEligible || r.currentMP.ftDeterminedEligible))
            || (this.acaInsightFilter.type == "PTE" && r.currentMP.ptDeterminedEligible)
            || (this.acaInsightFilter.type == "FTE" && r.currentMP.ftDeterminedEligible)
            || (this.acaInsightFilter.type == "FTI" && r.currentMP.ftDeterminedIneligible)
        }

        else if (this.acaInsightFilter.isCurrentMonth && !this.acaInsightFilter.isMP && !_.isNull(r.currentAP)) {
          res = (this.acaInsightFilter.type == "TDE" && (r.currentAP.ptDeterminedEligible || r.currentAP.ftDeterminedEligible))
            || (this.acaInsightFilter.type == "PTE" && r.currentAP.ptDeterminedEligible)
            || (this.acaInsightFilter.type == "FTE" && r.currentAP.ftDeterminedEligible)
            || (this.acaInsightFilter.type == "FTI" && r.currentAP.ftDeterminedIneligible)
        }

        else if (!this.acaInsightFilter.isCurrentMonth && this.acaInsightFilter.isMP && !_.isNull(r.nextMP)) {
          res = (this.acaInsightFilter.type == "TDE" && (r.nextMP.ptDeterminedEligible || r.nextMP.ftDeterminedEligible))
            || (this.acaInsightFilter.type == "PTE" && r.nextMP.ptDeterminedEligible)
            || (this.acaInsightFilter.type == "FTE" && r.nextMP.ftDeterminedEligible)
            || (this.acaInsightFilter.type == "FTI" && r.nextMP.ftDeterminedIneligible)
        }

        else if (!this.acaInsightFilter.isCurrentMonth && !this.acaInsightFilter.isMP && !_.isNull(r.nextAP)) {
          res = (this.acaInsightFilter.type == "TDE" && (r.nextAP.ptDeterminedEligible || r.nextAP.ftDeterminedEligible))
            || (this.acaInsightFilter.type == "PTE" && r.nextAP.ptDeterminedEligible)
            || (this.acaInsightFilter.type == "FTE" && r.nextAP.ftDeterminedEligible)
            || (this.acaInsightFilter.type == "FTI" && r.nextAP.ftDeterminedIneligible)
        }

        return res;
      });

    }

    container.records = records;
    this.recordsLoaded$.next(container);
    let employeeFilter: EmployeeFilter = new EmployeeFilter();
    employeeFilter.employeeFilterRecord = [];
    employeeFilter.filter = [];
    this.employeeFilterRecords$.next(employeeFilter.employeeFilterRecord);
    if (container && container.records) {
      _.forEach(container.records, function (item) {
        let empItem: EmployeeFilterRecord = new EmployeeFilterRecord();
        empItem.id = item.empId;
        empItem.name = item.empName;
        employeeFilter.employeeFilterRecord.push(empItem);
      });

      employeeFilter.filter = [];
      this.filter = [];
      this.setMeasurementState();

      this.employeeFilter$.next(employeeFilter);
    }
  }

  private setMeasurementState(): void {
    if (!this.selectedYear) {
      return;
    }
    let state: IAcaMeasurementState = _.clone(initialAcaMeasurementState);
    const controlState = this.stateManagement.getControlState(this.stateKey);

    if (controlState && controlState.value) {
      state = controlState.value;
    }

    let acaState: AcaMeasurementFilterState = new AcaMeasurementFilterState();
    acaState.year = this.selectedYear;
    acaState.orgLevelId = this.orgLevel.id;

    acaState.employeeListFilter = this.filter;
    if (this.employeeIdList && this.employeeIdList.length > 0) {
      acaState.employeeIdList = this.employeeIdList;
    }
    acaState.isExpandAll = this.expandAll;
    if (state && state.acaMeasurementStateList && state.acaMeasurementStateList.length > 0) {
      let index = _.findIndex(state.acaMeasurementStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
      if (index >= 0) {
        let item = _.find(state.acaMeasurementStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
        state.acaMeasurementStateList = _.without(state.acaMeasurementStateList, item);
        state.acaMeasurementStateList.push(acaState);
      }
      else {
        state.acaMeasurementStateList.push(acaState);
      }
    }
    else {
      state.acaMeasurementStateList = [acaState];
    }
    this.saveState(state);
  }

  private saveState(state: IAcaMeasurementState): void {
    this.stateManagement.setControlState(this.stateKey, {
      value: state
    }, StateResetTypes.None);
  }

  private restoreState(): void {
    if (!this.selectedYear) {
      return;
    }

    let state: IAcaMeasurementState = _.clone(initialAcaMeasurementState);
    const controlState = this.stateManagement.getControlState(this.stateKey);

    if (controlState && controlState.value) {
      state = controlState.value;
    }
    this.expandAll = false;
    this.employeeIdList = [];
    if (state && state.acaMeasurementStateList) {
      if (_.findIndex(state.acaMeasurementStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id }) >= 0) {
        this.acaFilterState = _.find(state.acaMeasurementStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
        this.expandAll = this.acaFilterState.isExpandAll;
      }
      else {
        this.acaFilterState = null;
      }
    }
    else {
      this.acaFilterState = null;
    }
    this.loadAcaMeasurement(this.selectedYear, this.acaFilterState);
  }


}
