/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-details-benefit-info-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./benefit-details-benefit-info-menu.component";
import * as i4 from "../../../../../common/services/modal/modal.service";
import * as i5 from "../../../services/benefit-details/benefit-details-permission.service";
import * as i6 from "../../../services/benefit-details/benefit-details-standart-management.service";
var styles_BenefitDetailsBenefitInfoMenuComponent = [i0.styles];
var RenderType_BenefitDetailsBenefitInfoMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitDetailsBenefitInfoMenuComponent, data: {} });
export { RenderType_BenefitDetailsBenefitInfoMenuComponent as RenderType_BenefitDetailsBenefitInfoMenuComponent };
function View_BenefitDetailsBenefitInfoMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "tiers-tabs__item-icon"], ["title", "Changes have been made to the benefit tier. Changes will be saved when Save button is clicked"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-exclamation-triangle"]], null, null, null, null, null))], null, null); }
function View_BenefitDetailsBenefitInfoMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["class", "tiers-tabs__item"]], [[8, "title", 0], [2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTier(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsBenefitInfoMenuComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = (_v.context.$implicit.stateIsAdded || _v.context.$implicit.stateIsUpdated); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.name; var currVal_1 = _co.isActiveMenu(_v.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.shortTitle; _ck(_v, 2, 0, currVal_2); }); }
export function View_BenefitDetailsBenefitInfoMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "tiers-tabs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsBenefitInfoMenuComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "tiers-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "slx-button slx-blue slx-with-icon slx-margin-r slx-mobile-adapted"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddNew() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-plus slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add New"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "slx-button slx-red slx-with-icon slx-margin-r slx-mobile-adapted"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-trash-alt slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tiers; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.canAdd; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.canDelete; _ck(_v, 8, 0, currVal_2); }); }
export function View_BenefitDetailsBenefitInfoMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-benefit-details-benefit-info-menu", [], null, null, null, View_BenefitDetailsBenefitInfoMenuComponent_0, RenderType_BenefitDetailsBenefitInfoMenuComponent)), i1.ɵdid(1, 245760, null, 0, i3.BenefitDetailsBenefitInfoMenuComponent, [i4.ModalService, i5.BenefitDetailsPermissionService, i6.BenefitDetailsStandartManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BenefitDetailsBenefitInfoMenuComponentNgFactory = i1.ɵccf("slx-benefit-details-benefit-info-menu", i3.BenefitDetailsBenefitInfoMenuComponent, View_BenefitDetailsBenefitInfoMenuComponent_Host_0, {}, {}, []);
export { BenefitDetailsBenefitInfoMenuComponentNgFactory as BenefitDetailsBenefitInfoMenuComponentNgFactory };
