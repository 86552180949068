import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import { ServerFilterService } from '../../../../core/services/index';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var KendoGridServerFilterMenuDirective = /** @class */ (function () {
    function KendoGridServerFilterMenuDirective(filterService, serverFilterService) {
        var _this = this;
        this.filterService = filterService;
        this.serverFilterService = serverFilterService;
        this.filterSubscription = filterService.changes.subscribe(function (filter) {
            _this.filterChanged(filter);
        });
    }
    KendoGridServerFilterMenuDirective.prototype.filterChanged = function (filter) {
        if (!filter || !filter.filters || !filter.filters.length) {
            this.removeFilter(this.column.field);
            return;
        }
        var serverFilter = kendoUtils.convertFromKendoComposeFilter(filter);
        this.serverFilterService.removeFilter(this.column.field);
        this.serverFilterService.addFilter(serverFilter);
        this.serverFilterService.applyFilter();
    };
    KendoGridServerFilterMenuDirective.prototype.removeFilter = function (filter) {
        this.serverFilterService.removeFilter(filter);
        this.serverFilterService.applyFilter();
    };
    KendoGridServerFilterMenuDirective.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridServerFilterMenuDirective.prototype, "filterSubscription", void 0);
    return KendoGridServerFilterMenuDirective;
}());
export { KendoGridServerFilterMenuDirective };
