import { FieldData } from './../../../../../core/models/field/field-data';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, Input, NgZone, Host, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { process, SortDescriptor, State, orderBy } from '@progress/kendo-data-query';
import { GridDataResult, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { unsubscribe } from '../../../../../core/decorators/index';
import { Assert } from '../../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { KendoGridStateHelper, ModalService, ConfirmOptions, ConfirmDialogComponent, removeEvent, saveEvent, cancelEvent, ChangeManagementService } from '../../../../../common/index';
import { mutableSelect } from '../../../../../core/decorators/index';

import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBase } from '../../../models';
import { OrgLevel } from '../../../../../state-model/models/index';
import { EmployeeSectionsACA, EmployeeACA } from '../../../models/employee-sections-employment/employee-sections-aca';

@Component({
  selector: 'slx-employee-sections-aca',
  templateUrl: './employee-sections-aca.component.html',
  styleUrls: ['./employee-sections-aca.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class EmployeeSectionsAcaComponent extends EmployeeSectionsBasicComponent implements OnInit {

  @Input('employeeSectionsACA')
  public set aca(employeeSectionsACA: EmployeeSectionsACA) {
    this.employeeSectionsACA = employeeSectionsACA;

    const canEdit: boolean = _.get(employeeSectionsACA, 'actions.canEdit', null);
    if (_.isBoolean(canEdit)) {
      this.canEdit = canEdit;
    }
    this.originalACA = _.cloneDeep(employeeSectionsACA);
    this.refreshGrid();
  }

  @Input() public employeeId: number;
  @Input() public employeeRehireDate : Date;
  @Input() public employeeStatus: string;

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }

  public addMode: boolean;
  public gridState: KendoGridStateHelper<EmployeeACA>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public appConfig: IApplicationConfig;
  public canEdit: boolean;
  public employeeSectionsACA: EmployeeSectionsACA;
  public editingItem: EmployeeACA;
  public minDate: Date = new Date(1900, 1, 1);
  public now: Date = moment().startOf('day').toDate();
  public overlapStartDate: boolean = false;
  public overlapEndDate: boolean = false;
  public gapStartDate: boolean = false;
  public gapEndDate: boolean = false;
  public dateError: boolean = false;
  public isAnnualIncomeRequired: boolean = false;
  public originalACA: EmployeeSectionsACA;
  public nonEmployeeOptions: string[] = ['Yes', 'No'];
  public incomeCalcOptions: string[] = ['System-Calculated', 'Employee-Provided'];
  public declinedOfferOptions: string[] = ['Yes', 'No'];
  public marketplacePurchaseOptions: string[] = ['Yes', 'No'];
  public annualIncomeLimit: number = 9999999999.99;
  public deleteBtnTooltip: string = 'Deleting this record is not allowed as it will create a gap.';
  public isAddNewAccessible: boolean = true;
  
  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  private ngFormChild: NgForm;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;
  @unsubscribe()
  private formValueChangeSubscription: Subscription;

  private employeeSectionsEmployementApiService: EmployeeSectionsEmploymentApiService;

  constructor(
    private modalService: ModalService,
    employeeSectionsEmployementApiService: EmployeeSectionsEmploymentApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private changeService: ChangeManagementService) {
    super(decorator, ngZone);
    this.decorator.isEditableByConfiguration = false;
    Assert.isNotNull(employeeSectionsEmployementApiService, 'employeeSectionsAccrualsApiService');
    this.employeeSectionsEmployementApiService = employeeSectionsEmployementApiService;
    this.gridState = new KendoGridStateHelper<EmployeeACA>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'startDate', dir: 'desc' }];
    this.gridState.state.take = 5;
    this.createSubscriptions();
    
  }
  public ngOnInit(): void {
    super.ngOnInit();
  }
  
  public createSubscriptions(): void {

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.gridEditSubscription = this.gridState.onEdit$.subscribe((item: EmployeeACA): void => {
      this.editingItem = item;
      this.addMode = false;
      item.isAddEditMode = true;
      this.changeService.changeNotify();
    });

    this.gridCancelSubscription = this.gridState.onCancel$.subscribe((item: cancelEvent<EmployeeACA>): void => {
      this.resetAddMode();
    });

    this.gridSaveSubscription = this.gridState.onSave$.subscribe((item: saveEvent<EmployeeACA>): void => {
      this.gridSaveSubscribe(item.dataItem, item.isNew);
    });
    
  }

  public onDelete(item: EmployeeACA): void {
    this.gridRemoveSubscribe(item);
  }

  public onCancel(item: EmployeeACA, rowIndex: number): void {
    item.isAddEditMode = false;
    let cancelEvent = { sender: this.grid, rowIndex: rowIndex, dataItem: item, isNew: false };
    this.gridState.cancelHandler(cancelEvent);
  }

  private gridSaveSubscribe(item: EmployeeACA, isNew: boolean): void {
    let selAcaDates: string = '';
    let acaList = _.without(this.employeeSectionsACA.records, item);

    _.forEach(acaList, function (el) {
      let startDate: string = 'Start Date: ' + (moment(el.startDate).format(appConfig.dateFormat));
      let endDate: string = 'End Date: ' + (el.endDate ? (moment(el.endDate).format(appConfig.dateFormat)) : '');
      if (item.endDate) {
        if (el.startDate >= item.startDate && el.startDate <= item.endDate && el.endDate <= item.endDate && el.endDate != null) {
          selAcaDates = selAcaDates == '' ? (startDate + ' - ' + endDate) : selAcaDates + ', ' + (startDate + ' - ' + endDate);
        }
      }
      else {
        if (el.startDate >= item.startDate) {
          selAcaDates = selAcaDates == '' ? (startDate + ' - ' + endDate) : selAcaDates + ', ' + (startDate + ' - ' + endDate);
        }
      }
    })

    if (selAcaDates != '') {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      ConfirmDialogComponent.openDialog(
        'Warning',
        'These records (' + selAcaDates + ') will be deleted, Would you like to continue?',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.saveChanges(item, isNew);
          }
          else {
            this.resetPreviousDates(item);
          }
        }, options);
    }
    else {
      this.saveChanges(item, isNew);
    }
    this.resetAddMode();
  }

  private resetPreviousDates(item: EmployeeACA): void {
    if (item.id != 0) {
      let orgPA = _.find(this.originalACA.records, ['id', item.id]);
      item.startDate = new Date(orgPA.startDate);
      if (item.endDate) {
        item.endDate = new Date(orgPA.endDate);
      }
      this.changeDetected();
    }
  }

  public resetAddMode(): void {
    this.addMode = false;
    this.editingItem = null;
    this.dateError = false;
    this.overlapStartDate = false;
    this.overlapEndDate = false;
    this.gapStartDate = false;
    this.gapEndDate = false;
    this.changeDetected();
  }

  private saveChanges(item: EmployeeACA, isNew: boolean): void {
    if (isNew) {
      let source = this.employeeSectionsACA.records;
      this.employeeSectionsACA.records = _.concat(source, [item]);
    }
    this.confirmSaveChanges(item);
  }

  private changeDetected(): void {
    this.changeService.clearChanges();
    this.changeService.clearCurrentComponentId();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private gridRemoveSubscribe(item: EmployeeACA): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialogComponent.openDialog(
      'Confirmation',
      'Are you sure that you want to remove this?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.doRemove(item);
          this.changeDetected();
        }
      }, options);
  }

  protected confirmSaveChanges(item: EmployeeACA): void {
    this.startProgress();
    item.empId = this.employeeId;
    this.employeeSectionsEmployementApiService.postEmployeeACA(this.employeeId, item)
      .then((employeeSectionsACA: any) => {
        this.stopProgress();
        this.loadSubsection();
      }).catch((reason: any) => {
        this.stopProgress();
        this.loadSubsection();
        this.onActionError(reason);
      });
  }

  protected doRemove(item: EmployeeACA): void {
    item.deleted = true;

    this.confirmSaveChanges(item);
  }

  public minDateLimit(item: EmployeeACA): Date {
    if (_.isNil(item)) {
      return this.minDate;
    }
    if (this.employeeStatus.toLowerCase() === "future rehire" ) {
      return this.employeeRehireDate;
    }
    if (this.employeeShortInfo && this.employeeShortInfo.dateHired) {
      return this.employeeShortInfo.dateHired;
    }
    return this.now;
  }


  public isRequiredEndDate(item: EmployeeACA): boolean {
    
    if (item && item.id==0) {
      return false;
    }
    return true;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsACA;
  }

  public loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmployementApiService.getEmployeeACA(this.employeeId)
      .then((employeeSectionsACA: EmployeeSectionsACA) => {
        this.employeeSectionsACA = employeeSectionsACA;
        this.state.isLoaded = true;
        this.gridState.state.skip = 0;
        this.canEdit = _.get(this.employeeSectionsACA, 'actions.canEdit', false);
        this.refreshGrid();
        this.originalACA = _.cloneDeep(employeeSectionsACA);
        this.stopProgress();
        this.changeDetected();
      }).catch(() => {
        this.stopProgress();
      });

  }

  public get valid(): boolean {
    if ((this.ngFormChild && !this.ngFormChild.valid) || this.overlapStartDate || this.overlapEndDate || this.gapStartDate || this.gapEndDate || this.dateError) {
      return false;
    }
    else {
      return true;
    }
  }

  public refreshGrid(): void {
    if (!this.employeeSectionsACA) {
      this.gridView = null;
      return;
    }

    if (this.employeeSectionsACA && this.employeeSectionsACA.records && _.size(this.employeeSectionsACA)>0) {
      let sortedList = _.orderBy(this.employeeSectionsACA.records, ['startDate'], ['desc']);
      let count = 0;
      _.forEach(sortedList, function (el) {
        if (count == 0) {
          el.isDisabledDel = false;
        }
        else {
          el.isDisabledDel = true;
        }
        count++;
      });
      if (!_.isNull(this.employeeSectionsACA.records) && _.size(this.employeeSectionsACA.records) > 0) {
        this.isAddNewAccessible = _.first(this.employeeSectionsACA.records).annualIncome.securityAttribute.editable;
      }
    }
    this.gridState.view = process(this.employeeSectionsACA.records, this.gridState.state);
  }

  public onAddNew(): void {  
    this.grid.addRow(this.addNewRow());
    this.changeService.changeNotify();
  }

  private addNewRow(): EmployeeACA {
    this.addMode = true;
    
    const annualIncome=new FieldData<number>()
    annualIncome.fieldValue = 0;
    const newEmpACA: EmployeeACA = new EmployeeACA();
    let defaultDate: Date = moment().startOf('year').toDate();
    if (this.employeeShortInfo && this.employeeShortInfo.dateHired && this.employeeShortInfo.dateHired > defaultDate) {
      newEmpACA.startDate = this.employeeShortInfo.dateHired;
    }
    else {
      newEmpACA.startDate = defaultDate;
    }
    newEmpACA.endDate = null;
    newEmpACA.id = 0;
    newEmpACA.incomeCalculationType = 'System-Calculated';
    newEmpACA.isEmployee = false;
    newEmpACA.isSystemCalculated = true;
    newEmpACA.nonEmployee = 'No';
    newEmpACA.annualIncome = annualIncome;
    newEmpACA.isAddEditMode = true;
    newEmpACA.declinedOffer = 'No';
    newEmpACA.marketplacePurchase = 'No';
    return newEmpACA;
  }

  public onChangedDate(item: EmployeeACA, isStartDate: boolean): void {
    let overlap = this.validateOverlaps(item, isStartDate);
    if (!overlap) {
      this.validateGapExists(item, isStartDate);
    }
  }
  
  private validateOverlaps(item: EmployeeACA, isStartDate: boolean): boolean {
    
    let isOverlap: boolean = false;
    if (item.id != 0) {
      let acaList = _.without(this.employeeSectionsACA.records, item);
      _.forEach(acaList, function (el) {
        const range = moment.range(el.startDate, el.endDate);
        isOverlap = range.contains(isStartDate ? item.startDate : item.endDate);
        if (isOverlap) {
          return false;
        }
      });
      if (isStartDate) {
        this.overlapStartDate = isOverlap;
      }
      else {
        this.overlapEndDate = isOverlap;
      }
    }
    return isOverlap;
  }
  
  private validateGapExists(item: EmployeeACA, isStartDate: boolean): void {
    this.dateError = false;
    if (item.endDate) {
      if (item.startDate > item.endDate) {
        this.dateError = true;
        return;
      }
    }
    if (item.id != 0 || (isStartDate && item.id == 0)) {
      let fromDate = new Date();
      let toDate = new Date();
      let sortedList = _.orderBy(this.employeeSectionsACA.records, ['startDate'], ['desc']);
      let currIndex = _.findIndex(sortedList, ['id', item.id]);
      if (isStartDate) {
        fromDate = sortedList[currIndex + 1].endDate
        toDate = item.startDate;
      }
      else {
        fromDate = sortedList[currIndex - 1].startDate
        toDate = item.endDate;
      }
      fromDate = new Date(moment(fromDate).format(appConfig.dateFormat));
      toDate = new Date(moment(toDate).format(appConfig.dateFormat));

      let range: any;
      if (isStartDate)
        range = moment.range(fromDate, toDate);
      else
        range = moment.range(toDate, fromDate);
      let dateDiff = range.diff('days')
      if ((dateDiff > 1 && isStartDate)) {
         this.gapStartDate = true;
      }
      else if (dateDiff > 1 && !isStartDate)
      {
        this.gapEndDate = true;
      }
      else {
        if (isStartDate) {
          this.gapStartDate = false;
        }
        else {
          this.gapEndDate = false;
        }
      }
    }
  }

  public isEndDateRequired(item: EmployeeACA): boolean {
    let isRequired = false;
    let employeeACA: EmployeeACA = _.find(this.originalACA.records, ['id', item.id]);
    if (!_.isNull(employeeACA.endDate)) {
      isRequired = true;
    }
    return isRequired;
  }

  public onSelectedIncomeCalc(item: EmployeeACA): void {
    if (item.incomeCalculationType == 'System-Calculated') {
      item.isSystemCalculated = true;
      if (item.id != 0 && this.originalACA) {
        let employeeACA: EmployeeACA = _.find(this.originalACA.records, ['id', item.id]);
        item.annualIncome = employeeACA.annualIncome;
      }
      else {
        item.annualIncome.fieldValue = 0;
      }

    }
    else {
      item.isSystemCalculated = false;
    }
  }

  public getDeleteTooltip(isDisabledDel: boolean): string {
    let tooltipMsg = '';
    if (isDisabledDel) {
      tooltipMsg = 'Deleting this record is not allowed as it will create a gap.';
    }
    return tooltipMsg;
  }
  
}
