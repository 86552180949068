import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Organization } from '../../../organization/models/lookup/index';
import { OrgLevelMapService } from '../../../organization/services/org-level/org-level-map.service';

import { IGeolocationEntityDTO, INewGeolocationEntityDTO, GeolocationEntity } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class GeolocationMapService {
  private orgLevelsList: Map<number, string>;

  public setOrgLevelsList(map: Map<number, string>): void {
    this.orgLevelsList = map;
  }

  public mapEntities(dtos: IGeolocationEntityDTO[]): GeolocationEntity[] {
    const hasList = !!this.orgLevelsList;
    return _.map(dtos, (dto: IGeolocationEntityDTO) => {
      const entity = this.mapEntity(dto);
      if (hasList) {
        const orgLevelName = this.orgLevelsList.get(entity.orgLevelId);
        entity.orgLevelName = orgLevelName || '';
      }
      return entity;
    });
  }

  public mapEntity(dto: IGeolocationEntityDTO): GeolocationEntity {
    let entity = new GeolocationEntity();
      entity.id = dto.id;
      entity.orgLevelId = dto.orgLevelId;
      entity.description = dto.description;
      entity.address = dto.address;
      entity.city = dto.city;
      entity.zip = dto.zip;
      entity.state = dto.state;
      entity.isActive = dto.isActive;
      entity.latitude = dto.latitude;
      entity.longitude = dto.longitude;
      entity.distance = dto.distance;
      entity.unitOfMeasure = dto.unitOfMeasure;
      entity.overriddenLatitude = dto.overriddenLatitude;
      entity.overriddenLongitude = dto.overriddenLongitude;
      entity.overriddenDistance = dto.overriddenDistance;
      entity.overriddenUnitOfMeasure = dto.overriddenUnitOfMeasure;
      entity.isOverridden = dto.isOverridden;
      entity.createdBy = dto.createdBy;
      entity.createdAt = dateTimeUtils.convertFromDtoDateTimeString(dto.createdAt);
      entity.isAllowPunchWhenLocationServiceDisabled = dto.isAllowPunchWhenLocationServiceDisabled;

      return entity;
  }

  public mapNewEntityToDTO(entity: GeolocationEntity): INewGeolocationEntityDTO {
    const dto: INewGeolocationEntityDTO = {
      orgLevelId: entity.orgLevelId,
      description: entity.description,
      address: entity.address,
      city: entity.city,
      zip: entity.zip,
      state: entity.state,
      isActive: entity.isActive,
      latitude: entity.latitude,
      longitude: entity.longitude,
      distance: entity.distance,
      unitOfMeasure: entity.unitOfMeasure,
      overriddenLatitude: entity.overriddenLatitude,
      overriddenLongitude: entity.overriddenLongitude,
      overriddenDistance: entity.overriddenDistance,
      overriddenUnitOfMeasure: entity.overriddenUnitOfMeasure,
      isOverridden: entity.isOverridden,
      isAllowPunchWhenLocationServiceDisabled: entity.isAllowPunchWhenLocationServiceDisabled
    };

    return dto;
  }

  public mapExistingEntityToDTO(entity: GeolocationEntity): IGeolocationEntityDTO {
    const dto: IGeolocationEntityDTO = {
      id: entity.id,
      orgLevelId: entity.orgLevelId,
      description: entity.description,
      address: entity.address,
      city: entity.city,
      zip: entity.zip,
      state: entity.state,
      isActive: entity.isActive,
      latitude: entity.latitude,
      longitude: entity.longitude,
      distance: entity.distance,
      unitOfMeasure: entity.unitOfMeasure,
      overriddenLatitude: entity.overriddenLatitude,
      overriddenLongitude: entity.overriddenLongitude,
      overriddenDistance: entity.overriddenDistance,
      overriddenUnitOfMeasure: entity.overriddenUnitOfMeasure,
      isOverridden: entity.isOverridden,
      createdBy: entity.createdBy,
      createdAt: dateTimeUtils.convertToDtoDateTimeString(entity.createdAt),
      isAllowPunchWhenLocationServiceDisabled: entity.isAllowPunchWhenLocationServiceDisabled
    };

    return dto;
  }
}
