export interface IBenefitPayrollDeduction {
  id: number;
  name: string;
  description: string;
  isIgnored: boolean;
  startDate: string;
  endDate: string;
}

export class BenefitPayrollDeduction {
  public id: number;
  public name: string;
  public description: string;
  public isIgnored: boolean;
  public startDate: Date;
  public endDate: Date;
}
