import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';

import { NotificationMessage } from '../../../app.messages';
import { ErrorHandlingApiService } from './error-handling-api.service';

export interface IErrorEvent {
  severity: 'fault' | 'error' | 'warning';
  error: any;
}

@Injectable()
export class ErrorHandlingService {
  public error$: Subject<NotificationMessage>;
  public exception$: Subject<IErrorEvent>;

  constructor(
    private notificationsService: NotificationsService,
    private errorHandlingApiService: ErrorHandlingApiService
  ) {
    this.error$ = new Subject<NotificationMessage>();
    this.exception$ = new Subject<IErrorEvent>();
  }

  public error(message: NotificationMessage, error: any, suppress: boolean = false): void {
    Assert.isNotNull(message, 'message');

    this.error$.next(message);
    this.exception$.next({ error, severity: 'error' });
    if (!suppress) {
      this.logError(message, error);
    }
  }

  public errorWithoutLog(message: NotificationMessage, error: any, suppress: boolean = false): void {
    Assert.isNotNull(message, 'message');

    this.error$.next(message);
    this.exception$.next({ error, severity: 'error' });
    if (!suppress) {
      this.sendToConsole(error);
      // send to UI
      this.notificationsService.error(message.title, message.message);
    }
  }

  public fault(message: NotificationMessage, error: any): void {
    Assert.isNotNull(message, 'message');

    this.error$.next(message);
    this.exception$.next({ error, severity: 'fault' });
    this.sendToConsole(error);

    // disable logging frontend only errors on backend, see task #45305
    //this.errorHandlingApiService.sendToServer(message.title, message.message, error);
    throw error;
  }

  public warning(message: NotificationMessage, details?: string, suppress: boolean = false): void {
    Assert.isNotNull(message, 'message');

    let logMessage: string = this.formatMessage(message, details);
    if (!suppress) {
      this.notificationsService.warn(message.title, message.message);
    }
    console.warn(logMessage);
    this.exception$.next({ error: logMessage, severity: 'warning' });
  }

  public info(message: NotificationMessage, details?: string | any): void {
    Assert.isNotNull(message, 'message');

    let logMessage: string = this.formatMessage(message, details);
    this.notificationsService.info(message.title, message.message);
    console.info(logMessage);
  }

  public success(message: NotificationMessage, details?: string | any): void {
    Assert.isNotNull(message, 'message');

    this.notificationsService.success(
      message.title,
      message.message,
      {
        timeOut: 500
      });
  }

  private formatMessage(message: NotificationMessage, details?: string | any): string {
    Assert.isNotNull(message, 'message');

    let detailsMessage: string = _.isString(details) ? <string>details : JSON.stringify(details || '');
    let logMessage: string = `${message.title}. ${message.message}`;
    if (detailsMessage) {
      logMessage = logMessage + ` - ${detailsMessage}`;
    }
    return logMessage;
  }

  private logError(message: NotificationMessage, error: any): void {
    try {
      this.sendToConsole(error);
      // send to UI
      this.notificationsService.error(message.title, message.message);
      // send to server
      this.errorHandlingApiService.sendToServer(message.title, message.message, error);

    } catch (ex) {
      console.error(ex);
    }
  }


  private sendToConsole(error: any): void {
    if (console && console.group && console.error) {
      console.group('Error Log Service');
      console.error(error);
      console.error(error.message);
      console.error(error.stack);
      console.groupEnd();
    }
  }

}
