import { OnDestroy, ViewContainerRef, TemplateRef } from '@angular/core';
import { AccessProviderType } from '../../models/index';
import { MenuAccessibleProviderService } from '../../services/index';
var AccessibleDirective = /** @class */ (function () {
    function AccessibleDirective(templateRef, viewContainer, menuAccessibleProviderService) {
        this.templateRef = templateRef;
        this.viewContainer = viewContainer;
        this.menuAccessibleProviderService = menuAccessibleProviderService;
    }
    Object.defineProperty(AccessibleDirective.prototype, "slxAccessible", {
        set: function (v) {
            if (!v) {
                return;
            }
            this.options = v;
            this.subscribeToProvider();
            this.updateView();
        },
        enumerable: true,
        configurable: true
    });
    AccessibleDirective.prototype.ngOnDestroy = function () {
        this.unsubscribe();
    };
    AccessibleDirective.prototype.getProvider = function () {
        switch (this.options.provider) {
            case AccessProviderType.Menu:
                return this.menuAccessibleProviderService;
            default:
                throw Error('Unknown AccessProviderType');
        }
    };
    AccessibleDirective.prototype.isAccessible = function () {
        var provider = this.getProvider();
        return provider.isAccessible(this.options.permission);
    };
    AccessibleDirective.prototype.subscribeToProvider = function () {
        var _this = this;
        this.unsubscribe();
        var provider = this.getProvider();
        this.changeSubscription = provider.subscribeToAccessibleChanged(function () { return _this.updateView(); });
    };
    AccessibleDirective.prototype.unsubscribe = function () {
        if (this.changeSubscription) {
            this.changeSubscription.unsubscribe();
            this.changeSubscription = undefined;
        }
    };
    AccessibleDirective.prototype.updateView = function () {
        this.viewContainer.clear();
        if (this.isAccessible()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    };
    return AccessibleDirective;
}());
export { AccessibleDirective };
