var BenefitEligibilityRuleStatement = /** @class */ (function () {
    function BenefitEligibilityRuleStatement(_a) {
        var ruleId = _a.ruleId, variableCode = _a.variableCode, predicate = _a.predicate, value = _a.value, groupId = _a.groupId, sequence = _a.sequence;
        this.ruleId = ruleId;
        this.variableCode = variableCode;
        this.predicate = predicate;
        this.value = value;
        this.groupId = groupId;
        this.sequence = sequence;
    }
    return BenefitEligibilityRuleStatement;
}());
export { BenefitEligibilityRuleStatement };
