import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import * as moment from 'moment';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../app.config';
import { createValuAccessor } from '../../utils/index';

const defaultInterval: number = 7;

@Component({
  moduleId: module.id,
  selector: 'slx-date-cycle',
  templateUrl: 'date-cycle.component.html',
  styleUrls: ['date-cycle.component.scss'],
  providers: [createValuAccessor(DateCycleComponent)]
})
export class DateCycleComponent implements ControlValueAccessor {
  @Input()
  public interval: number;

  public appConfig: IApplicationConfig;
  public internalValue: moment.Range;

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;


  constructor() {
    this.appConfig = appConfig;
  }

  public writeValue(value: moment.Range): void {
    this.internalValue = value;
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public nextRange(): void {
    let newStartDate: moment.Moment = moment(this.internalValue.end).add(1, 'day');
    let newEndDate: moment.Moment = moment(this.internalValue.end).add((this.interval || defaultInterval), 'day');
    this.internalValue = moment.range(newStartDate, newEndDate);
    this.onChangeCallback(this.internalValue);
  }

  public prevRange(): void {
    let newStartDate: moment.Moment = moment(this.internalValue.start).subtract((this.interval || defaultInterval), 'day');
    let newEndDate: moment.Moment = moment(this.internalValue.start).subtract(1, 'day');
    this.internalValue = moment.range(newStartDate, newEndDate);
    this.onChangeCallback(this.internalValue);
  }
}
