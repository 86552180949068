import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsPhysicalGroup = /** @class */ (function () {
    function EmployeeSectionsPhysicalGroup() {
        this.fields = [];
    }
    return EmployeeSectionsPhysicalGroup;
}());
export { EmployeeSectionsPhysicalGroup };
var EmployeeSectionsPhysicalField = /** @class */ (function () {
    function EmployeeSectionsPhysicalField() {
    }
    return EmployeeSectionsPhysicalField;
}());
export { EmployeeSectionsPhysicalField };
var EmployeeSectionsPhysicals = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPhysicals, _super);
    function EmployeeSectionsPhysicals() {
        var _this = _super.call(this) || this;
        _this.groups = [];
        return _this;
    }
    EmployeeSectionsPhysicals.dataMock = [
        {
            fields: [
                {
                    id: 3,
                    name: 'physicalDate',
                    displayName: 'Physical date value',
                    value: new Date('11/4/2017'),
                    fieldType: 'Date',
                    fieldLength: '10',
                    renderType: 'Date',
                    seq: 3
                },
                {
                    id: 4,
                    name: 'physicalNumberValue',
                    displayName: 'Physical number value',
                    value: 10,
                    fieldType: 'number',
                    fieldLength: '2',
                    renderType: 'number',
                    seq: 4
                },
                {
                    id: 1,
                    name: 'physicalString',
                    displayName: 'Physical string value',
                    value: 'string',
                    fieldType: 'string',
                    fieldLength: '1',
                    renderType: 'string',
                    seq: 1
                },
                {
                    id: 2,
                    name: 'physicalString2',
                    displayName: 'Physical string2 value',
                    value: 'string2',
                    fieldType: 'string',
                    fieldLength: '1',
                    renderType: 'string',
                    seq: 2
                }
            ],
            seq: 1
        },
        {
            fields: [
                {
                    id: 1,
                    name: 'physicalDropDown',
                    displayName: 'Physical date value',
                    value: false,
                    fieldType: 'yesno',
                    fieldLength: '1',
                    renderType: 'yesno',
                    seq: 1
                },
                {
                    id: 2,
                    name: 'physicalNumber2Value',
                    displayName: 'Physical number2 value',
                    value: 10,
                    fieldType: 'number',
                    fieldLength: '3',
                    renderType: 'number',
                    seq: 2
                }
            ],
            seq: 2
        }
    ];
    return EmployeeSectionsPhysicals;
}(EmployeeSectionsBase));
export { EmployeeSectionsPhysicals };
