import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../../../app.config';
import { IFilteredItems } from '../../../../../core/models/index';
import { orderBy, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { ExportDataConfigurationInfo } from '../../../models';
import { ExportDataManagementService } from '../../../services';
import { ExportDataStatus } from '../../../enums/export-data-status';
import { InfoDialogComponent, ModalService } from '../../../../../common';
import { screenUtils } from '../../../../../common/utils';
var ExportDataHistoryComponent = /** @class */ (function () {
    function ExportDataHistoryComponent(manService, changeDetector, modalService) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.modalService = modalService;
        this.pageSize = 50;
        this.subscriptions = {};
        this.messages = {
            downloadNotAvailable: 'Exports completed more than 24 hours can not be downloaded'
        };
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'startedOn', dir: 'desc' }];
        this.gridState.state.group = [];
    }
    Object.defineProperty(ExportDataHistoryComponent.prototype, "exportDataStatus", {
        get: function () { return ExportDataStatus; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportDataHistoryComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    ExportDataHistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.subscriptions.loadedHistory = this.manService.subscribeToLoadedHistory(function (data) {
            data = _.filter(data, function (x) { return x.configurationId === _this.configuration.id; });
            if (_this.configuration.history) {
                data = _.concat(data, _this.configuration.history);
            }
            if (data.length === 0) {
                return;
            }
            _this.configuration.history = data;
            _this.records = {
                items: data,
                skiped: 0,
                taked: data.length,
                count: data.length,
            };
            _this.refreshGrid();
        });
        this.subscriptions.loadedStatuses = this.manService.subscribeToLoadedStatuses(function (data) {
            if (!_this.configuration.history) {
                return;
            }
            data = _.filter(data, function (x) { return x.configurationId === _this.configuration.id; });
            _.each(data, function (x) {
                var historyItem = _.find(_this.configuration.history, function (y) { return y.id === x.executionId; });
                if (historyItem) {
                    historyItem.status = x.status;
                    historyItem.reason = x.reason;
                    if (x.status === ExportDataStatus.Completed || x.status === ExportDataStatus.Expired) {
                        historyItem.completedOn = x.changedOn;
                    }
                    if (_this.configuration.lastExecuted && _this.configuration.lastExecuted.id === x.executionId) {
                        _this.configuration.lastExecuted.status = x.status;
                        _this.configuration.lastExecuted.reason = x.reason;
                    }
                }
            });
            _this.refreshGrid();
        });
    };
    ExportDataHistoryComponent.prototype.ngOnDestroy = function () {
        this.records = null;
        this.configuration.history = null;
        this.changeDetector.detach();
    };
    ExportDataHistoryComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ExportDataHistoryComponent.prototype.filterChange = function (filter) {
        this.gridState.state.filter = filter;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ExportDataHistoryComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = { data: null, total: null };
        var filtered = filterBy(this.records.items, this.gridState.state.filter);
        this.gridState.view = { data: orderBy(filtered, this.gridState.state.sort), total: this.records.count };
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ExportDataHistoryComponent.prototype.downloadIsAvailable = function (data) {
        return data.status === ExportDataStatus.Completed && data.completedOn > moment().subtract(1, 'days').toDate();
    };
    ExportDataHistoryComponent.prototype.downloadFile = function (data) {
        this.manService.downloadExportData(data.id);
    };
    ExportDataHistoryComponent.prototype.showReasonInfoPopup = function (data) {
        if (this.isMobile && data.reason) {
            InfoDialogComponent.OpenDialog('Information', data.reason, this.modalService);
        }
    };
    ExportDataHistoryComponent.prototype.showDownloadInfoPopup = function (data) {
        if (this.isMobile && !this.downloadIsAvailable(data)) {
            InfoDialogComponent.OpenDialog('Information', this.messages.downloadNotAvailable, this.modalService);
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], ExportDataHistoryComponent.prototype, "subscriptions", void 0);
    return ExportDataHistoryComponent;
}());
export { ExportDataHistoryComponent };
