export class MessageNotificationModel {
    public from_name: string;
    public from_phone: string;
    public date: string;
    public time: string;
    public message: string;
    public read: boolean;
    public id: string;
    public parentId:  string;
}

export interface IMessageNotificationModel {
    sender: string;
    recipients: string;
    created: string;
    date: string;
    body: string;
    read: boolean;
    id: string;
    phone: string;
    fullName: string;
    parentId: string;
}
