<form #form="ngForm" id="Section-{{sectionIndex}}" novalidate class="slx-full-height">
<div class="badge-container badge-container-blue badge-border">
    <span class="container-child badge-title badge-title-black">Goal Rating Tool <i class="fa fa-info-circle"
 aria-hidden="true" (click)="onShowDetails()"></i></span>
    <slx-star-rating [readonly]="false" [rating]="selectedRating" maxValue="5"
        [filledItemClassName]="'full-yellow-big-star'" [emptyItemClassName]="'far empty-blue-big-star'"
        class="container-child star-rating-margin" (ratingChange)="onPredictedRatingChange($event)">
    </slx-star-rating>
    <div class="container-child badge-note badge-note-blue">
        <div class="points-box">{{ isGoalsSectionDetails ?(goalsSectionDetails.adjustedTotalNurseHRDPoints + goalsSectionDetails.adjustedRnHRDPoints + goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints + goalsSectionDetails.totalNursingTurnOverPoints + goalsSectionDetails.rnTurnOverPoints + goalsSectionDetails.administratorDeparturesPoints) : ''}}</div>
        out of 380 points
    </div>
    <div class="container-child badge-note badge-note-blue">Click the star rating you want to acheive to see the
 values change below.
        This will indicate the needed values for your staffing levels for that reflected rating. You can also adjust
 the values below to see the goal star rating.
        <br/><span class="badge-fb">Note:</span> These values are an approximation based on a combination of
 current and historical hours.
    </div>
</div>
<div class="badge-container badge-border badge-block badge-container-1">
    <div class="badge-main badge-block">AVG DAILY CENSUS</div>
    <div class="badge-side badge-fb">
        <!--
          [readonly]="!isDateAndEmpSelected(dataItem)"
          placeholder="Hours"
          [min]="hours.min"
          [max]="hours.max"
          [slxMin]="hours.min"
          [slxMax]="hours.max"
          [required]="true"
          [decimals]="2"
          (ngModelChange)="onChangeEmpContribution()
        -->
        <div class="badge-fbr">
        <slx-number #avgCensus="ngModel" class="daily__number"
 [ngClass]="state.isAvgCensusDisabled ? 'disabled' : 'enabled'" [step]="1" min="1"
 name="avgDailyCensus" required="true" [decimals]="2" [readonly]="state.isAvgCensusDisabled"
 (ngModelChange)="handleGoalRatingInputs($event, 'avgCensus', sectionIndex)"
 [(ngModel)]=goalsSectionDetails.avgDailyCensus (input)="checkForNegativeVal($event, 'avgCensus')"
 (keypress)="preventNegative($event, 'avgCensus')" [title]="state.isAvgCensusDisabled ?  'Select star rating first to adjust this parameter' : ''"></slx-number>
                <div *ngIf="avgCensus.errors || state.isNegativeCensus" class="slx-error-block set-width">
                    <span
 *ngIf="avgCensus.errors?.touched && avgCensus.errors?.required && !state.isAvgCensusDisabled">This
 field cannot be blank</span>
                <span *ngIf="!avgCensus.errors?.required && state.isNegativeCensus">This field requires a value
 greater than zero</span>
                </div>

        </div>
    </div>
    <div class="badge-main badge-center-max"></div>
</div>
<div class="badge-container badge-border badge-block badge-container-1">
    <div class="badge-main badge-block">AVG DAILY CENSUS FOR WEEKENDS</div>
    <div class="badge-side badge-fb">
        <div class="badge-fbr">
            <slx-number #censusWeekend="ngModel" class="daily__number"
                    [ngClass]="state.isCensusWeekendDisabled ? 'disabled' : 'enabled'" [step]="1" min="1" required="true" (input)="checkForNegativeVal($event, 'avgCensusWeekend')"
                    name="avgCensusWeekend" (ngModelChange)="handleGoalRatingInputs($event, 'avgCensusWeekend', sectionIndex)" (keypress)="preventNegative($event, 'avgCensusWeekend')" [title]="state.isCensusWeekendDisabled ?  'Select star rating first to adjust this parameter' : ''"
                    [readonly]="state.isCensusWeekendDisabled" [decimals]="2" [(ngModel)]=goalsSectionDetails.avgDailyCensusWeekEnd></slx-number>
                <div *ngIf="censusWeekend.errors || state.isNegCensusWeekend" class="slx-error-block set-width">
                    <span *ngIf=" censusWeekend.errors?.touched && censusWeekend.errors?.required && !state.isCensusWeekendDisabled">This field cannot be blank</span>
                    <span *ngIf="!censusWeekend.errors?.required && state.isNegCensusWeekend ">This field requires a value greater than zero</span>
                </div>
        </div>
    </div>
    <div class="badge-main badge-center-max"></div>
</div>
<div class="badge-container badge-border badge-block badge-container-2">
    <div class="badge-header">TOTAL NURSING HRD</div>
    <div class="badge-header position-align">ADJUSTED HRD</div>
    <div class="badge-main badge-fb set-align badge-line">
        <span>
            <slx-number #nursingHrd="ngModel" [ngClass]="state.isNurseHrdDisabled ? 'disabled' : 'enabled'" [title]="state.isNurseHrdDisabled ?  'Select star rating first to adjust this parameter' : ''"
                    class="daily__number" min="0" [readonly]="state.isNurseHrdDisabled" required="true" [step]="1"
 (input)="checkForNegativeVal($event, 'nursingHrd')"
                    (ngModelChange)="handleGoalRatingInputs($event, 'nursingHrd', sectionIndex)" name="hours2"
 [decimals]="2" (keydown.Tab)="setValue('nursingHrd',$event.target)"
 (focusout)="setValue('nursingHrd',$event.target)" (keypress)="preventNegative($event, 'nursingHrd')"
                [(ngModel)]=goalsSectionDetails.totalNursingAvgDailyHours></slx-number>
            </span>
            <span [ngClass]="{'spacious-sym': !hideToPrint}">/</span>
            <span>{{goalsSectionDetails.avgDailyCensus}}</span>

            <span class="spacious-sym">=</span>
        </div>
        <div class="badge-side badge-fb">
            <div>{{goalsSectionDetails.reportedTotalNursingHRD | number: ".2-2"}}</div>
        </div>
        <div class="badge-side">
            <div class="text-size">{{goalsSectionDetails.adjustedTotalNurseHRD | number: ".2-2"}}</div>
        </div>
        <div class="badge-main badge-center-max">
            <div class="small-points-box">{{goalsSectionDetails.adjustedTotalNurseHRDPoints}}</div> out of 100 points
        </div>

        <div class="badge-footer">
            <div *ngIf="nursingHrd.errors || state.isNurseHrsNeg" class="slx-error-block set-width h-1">
                <span *ngIf="nursingHrd.errors?.touched && nursingHrd.errors?.required">This field cannot be blank</span>
                <span *ngIf="!nursingHrd.errors?.required && state.isNurseHrsNeg">This field requires a value greater than or equal to zero</span> 
            </div>
            <span> HOURS / CENSUS = HRD</span>
        </div>
    </div>
    <div class="badge-container badge-border badge-block badge-container-2">
        <div class="badge-header">RN HRD</div>
        <div class="badge-main badge-fb set-align badge-line">
            <span>
                <slx-number #rnHrd="ngModel" class="daily__number" [ngClass]="state.isRnDisabled ? 'disabled' : 'enabled'" required="true" [title]="state.isRnDisabled ?  'Select star rating first to adjust this parameter' : ''"
                    [step]="1" min="0" [readonly]="state.isRnDisabled" name="hours3" (keydown.Tab)="setValue('rnHrd',$event.target)" (input)="checkForNegativeVal($event,'rnHrd')"
                    (ngModelChange)="handleGoalRatingInputs($event,'rnHrd', sectionIndex)" [decimals]="2" [(ngModel)]=goalsSectionDetails.rnAvgDailyHours (keypress)="preventNegative($event, 'rnHrd')">
                </slx-number>
               
        </span>
        <span [ngClass]="{'spacious-sym': !hideToPrint}">/</span>
        <span>{{goalsSectionDetails.avgDailyCensus}}</span>
        <span class="spacious-sym">=</span>
    </div>
    <div class="badge-side badge-fb">
<div>{{goalsSectionDetails.reportedRnHRD | number: ".2-2"}}</div>
</div>
    <div class="badge-side">
<div class="text-size">{{goalsSectionDetails.adjustedRnHRD | number: ".2-2"}}</div>
</div>
        <div class="badge-main badge-center-max">
<div class="small-points-box">{{goalsSectionDetails.adjustedRnHRDPoints}}</div> out of 100 points
</div>
    <div class="badge-footer">
            <div *ngIf="rnHrd.errors" class="slx-error-block set-width">
                <span *ngIf="rnHrd.errors?.touched && rnHrd.errors?.required">This field cannot be blank</span>
                <span *ngIf="rnHrd.errors?.min">This field requires a value greater than or equal to zero</span> 
            </div>
            <span>HOURS / CENSUS = HRD</span></div>
    </div>
    <div class="badge-container badge-border badge-block badge-container-2">
    <div class="badge-header">TOTAL NURSING WEEKEND HRD</div>
    <div class="badge-main badge-fb set-align-l badge-line">
        <span>
            <slx-number #nursingWeekend="ngModel"  class="daily__number" required="true"
                    [readonly]="state.isNurseWeekendDisabled" [step]="1" min="0" 
                    [ngClass]="state.isNurseWeekendDisabled ? 'disabled' : 'enabled'" name="hours4"
                    (ngModelChange)="handleGoalRatingInputs($event,'nursingWeekend', sectionIndex)" [decimals]="2" [title]=" state.isNurseWeekendDisabled ?  'Select star rating first to adjust this parameter' : ''"
                    [(ngModel)]=goalsSectionDetails.totalNursingAvgDailyHoursWeekEnd 
                    (keydown.Tab)="setValue('nursingWeekend',$event.target)" (input)="checkForNegativeVal($event, 'nursingWeekend')" (keypress)="preventNegative($event, 'nursingWeekend')"></slx-number>
                  </span>
        <span [ngClass]="{'spacious-sym': !hideToPrint}">/</span>
        <span>{{goalsSectionDetails.avgDailyCensusWeekEnd}}</span>
        <span class="spacious-sym">=</span>
    </div>
    <div class="badge-side badge-fb">
<div>{{goalsSectionDetails.reportedTotalNursingHRDWeekEnd | number: ".2-2"}}</div>
</div>
    <div class="badge-side">
<div class="text-size">{{goalsSectionDetails.adjustedTotalNurseOnWeekendHRD | number: ".2-2"}}</div>
</div>
    <div class="badge-main badge-center-max">
<div class="small-points-box">{{goalsSectionDetails.adjustedTotalNurseOnWeekendHRDPoints | number: ".0-0"}}</div> out of 50 points
</div>
    <div class="badge-footer">
            <div *ngIf="nursingWeekend.errors || state.isNurseWeekendNeg" class="slx-error-block set-width">
                <span *ngIf="nursingWeekend.errors?.touched && nursingWeekend.errors?.required">This field cannot be blank</span>
                <span *ngIf=" !nursingWeekend.errors?.required && state.isNurseWeekendNeg">This field requires a value greater than or equal to
                    zero</span>
            </div>
            <span>HOURS / CENSUS = HRD</span></div>
    </div>
    <div class="badge-container badge-border badge-block badge-container-3">
    <div class="badge-main badge-block">TOTAL NURSING TURNOVER</div>
    <div class="badge-side badge-fb">
        <div class="badge-fbr">
            <slx-number #nurseTurnover="ngModel" class="daily__number"
                    [ngClass]="state.isNurseTurnoverDisabled ? 'disabled' : 'enabled'" [step]="1" name="hours5" min="0" (keydown.Tab)="setValue('nurseTurnover',$event.target)"
                    [readonly]="state.isNurseTurnoverDisabled"  [decimals]="2" (input)="checkForNegativeVal($event, 'nurseTurnover')" required="true"
                    [(ngModel)]=goalsSectionDetails.totalNursingTurnOver (ngModelChange)="handleGoalRatingInputs($event,'nurseTurnover', sectionIndex)" (keypress)="preventNegative($event, 'nurseTurnover')" [title]="state.isNurseTurnoverDisabled ?  'Select star rating first to adjust this parameter' : ''">
                </slx-number>
                <div *ngIf=" nurseTurnover.errors || state.isNurseTurnOverNeg" class="slx-error-block set-width">
                    <span *ngIf="nurseTurnover.errors?.touched && nurseTurnover.errors?.required">This field cannot be blank</span>
                    <span *ngIf="!nurseTurnover.errors?.required && state.isNurseTurnOverNeg">This field requires a value greater than or equal to
                        zero</span>
                </div>
              
            </div>
        </div>
        <div class="badge-main badge-center-max">
            <div class="small-points-box">{{goalsSectionDetails.totalNursingTurnOverPoints| number: ".0-0"}}</div> out of 50 points
        </div>
    </div>
    <div class="badge-container badge-border badge-block badge-container-3">
        <div class="badge-main badge-block">RN TURNOVER</div>
        <div class="badge-side badge-fb">
            <div class="badge-fbr">
                <slx-number #rnTurnover="ngModel" class="daily__number"
                    [ngClass]="state.isRnTurnoverDisabled ? 'disabled' : 'enabled'" [step]="1"
                    [readonly]="state.isRnTurnoverDisabled" name="hours6" min="0"
                    [decimals]="2" [title]="state.isRnTurnoverDisabled ?  'Select star rating first to adjust this parameter' : ''"
                    (keydown.Tab)="setValue('rnTurnover',$event.target)" (input)="checkForNegativeVal($event, 'rnTurnover')" required="true"
                    [(ngModel)]=goalsSectionDetails.rnTurnOver (ngModelChange)="handleGoalRatingInputs($event, 'rnTurnover', sectionIndex)" (keypress)="preventNegative($event, 'rnTurnover')"></slx-number>
                <div *ngIf="rnTurnover.errors || state.isRnTurnoverNeg" class="slx-error-block set-width">
                    <span *ngIf="rnTurnover.errors?.touched && rnTurnover.errors?.required">This field cannot be blank</span>
                    <span *ngIf="!rnTurnover.errors?.required && state.isRnTurnoverNeg ">This field requires a value greater than or equal to
                        zero</span>
                </div>
            </div>
        </div>
        <div class="badge-main badge-center-max">
            <div class="small-points-box">{{goalsSectionDetails.rnTurnOverPoints | number: ".0-0"}}</div> out of 50 points
        </div>
    </div>
    <div class="badge-container badge-border badge-block badge-container-3"
        [ngClass]="{'badge-container-last':hideToPrint}">
        <div class="badge-main badge-block">ADMINISTRATOR DEPARTURES</div>
        <div class="badge-side badge-fb">
            <div class="badge-fbr">
                <slx-number #adminDep="ngModel" class="daily__number"  [step]="1"
                    [ngClass]="state.isAdminDepDisabled ? 'disabled' : 'enabled'" name="hours7" min="0" [title]="state.isAdminDepDisabled ?  'Select star rating first to adjust this parameter' : ''"
                    [readonly]="state.isAdminDepDisabled" [decimals]="2" (keydown.Tab)="setValue('adminDep',$event.target)" [(ngModel)]=goalsSectionDetails.administratorDepartures
                    (ngModelChange)="handleGoalRatingInputs($event, 'adminDep', sectionIndex)" (input)="checkForNegativeVal($event, 'adminDep')" required="true"(keypress)="preventNegative($event, 'adminDep')"></slx-number>
                <div *ngIf="adminDep.errors || state.isAdminDepNeg" class="slx-error-block set-width">
                    <span *ngIf=" adminDep.errors?.touched && adminDep.errors?.required">This field cannot be blank</span>
                    <span *ngIf="!adminDep.errors?.required && state.isAdminDepNeg">This field requires a value greater than or equal to zero</span>
                </div>
              
            </div>
        </div>
        <div class="badge-main badge-center-max">
            <div class="small-points-box">{{goalsSectionDetails.administratorDeparturesPoints | number: ".0-0"}}</div> out of 30 points
        </div>
    </div>
    <div class="badge-container badge-container-footer badge-border badge-block" *ngIf="!hideToPrint">
        <div class="badge-main">You can save the values in the predictor tool for later review.</div>
        <div class="badge-buttons">
            <button type="button" class="btn btn-default" (click)="clearAllFields()">Clear</button>
            <button type="button" class="btn btn-primary" [disabled]="isSaveDisabled" (click)="saveGoal()">Save Results</button>
        </div>
    </div>
</form>

