<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham"
                 [gridOptions]="gridOptions"
                 [columnDefs]="gridHelper.columnDefs"
                 [defaultColDef]="gridHelper.defaultColumnDefs"
                 [rowData]="rowData"
                 [getRowHeight]="getRowHeight"
                 (gridReady)="onGridReady($event)"
                 [suppressContextMenu]="true"
                 (cellClicked)="onCellClicked($event)">
</ag-grid-angular>

<div *ngIf="periodModel.isVisible" class="period"
     [style.left.px]="periodModel.posLeft"
     [style.top.px]="periodModel.posTop"
     style="position:fixed;z-index:1000;">
  <div class="popperbox" [style.height.px]="periodModel?.acaPeriod.periodSortName === 'MP'?'115':periodModel?.acaPeriod.periodSortName === 'SP'?'63':'54'">
    <div class="popperbox-header" [ngStyle]="{'background-color': periodModel?.acaPeriod.colorCode}">
      <span>
        <em *ngIf="periodModel?.acaPeriod.periodSortName === 'SP'" class="fa fa-balance-scale" aria-hidden="true"></em>
        <em *ngIf="periodModel?.acaPeriod.periodSortName === 'AP'" class="fas fa-file-edit"></em>
        <em *ngIf="periodModel?.acaPeriod.periodSortName === 'MP'" class="fas fa-ruler"></em>
        {{periodModel?.acaPeriod.periodName}}
      </span>
    </div>
    <div class="popperbox-content-section">
      <div *ngIf="periodModel?.acaPeriod.periodSortName === 'SP'" class="popper-content" [style.padding-top.px]="4">
        <span *ngIf="periodModel?.acaPeriod.empAvgHours >= 30">Determined Full Time</span>
        <span *ngIf="periodModel?.acaPeriod.empAvgHours < 30">Determined Part Time</span>
      </div>
      <div *ngIf="periodModel?.acaPeriod.periodSortName === 'MP'" class="popper-content">
        <div class="content-item">Weekly Avg Hours</div>
        <div class="content-number">{{periodModel?.acaPeriod.empAvgHours}}<span class="content-bar"> / </span>  {{periodModel?.acaPeriod.weeklyAvgHours}}</div>
        <div class="content-item">
          <span *ngIf="periodModel?.acaPeriod.empAvgHours >= 30">Trending Full Time</span>
          <span *ngIf="periodModel?.acaPeriod.empAvgHours < 30">Trending Part Time</span>
        </div>
      </div>
      <div class="popper-content-det" [style.padding-top.px]="periodModel?.acaPeriod.periodSortName === 'MP'?'2':periodModel?.acaPeriod.periodSortName === 'SP'?'4':'8'">
        <span>Start: {{periodModel?.acaPeriod.startFrom | amDateFormat: appConfig.dateFormat}} | End: {{periodModel?.acaPeriod.endTo | amDateFormat: appConfig.dateFormat}}</span>
      </div>
    </div>
  </div>
</div>

<ag-grid-angular #agGrid
                 style="width: 100%; height: 100%;"
                 id="myGrid"
                 class="ag-theme-balham"
                 [hidden]="true"
                 [columnDefs]="gridHelper.columnDefs"
                 [rowData]="exportRow"
                 (gridReady)="onGridReadyExport($event)"></ag-grid-angular>
