import { IOpenShiftPostionsOrgDetails, OpenShiftPostionsOrgDetails } from "./open-shift-positions-org-details"

export interface IOpenShiftPostionsDetails {
    orgLevelId: number
    orgName: string
    totalHoursGap: number
    totalOpenShifts: number
    totalFTENeeded: number
    orgDepartmentDetails: IOpenShiftPostionsOrgDetails[]
    isExpand: boolean
  }

  export class OpenShiftPostionsDetails {
    public orgLevelId: number
    public orgName: string;
    public totalHoursGap: number;
    public totalOpenShifts: number;
    public totalFTENeeded: number;
    public orgDepartmentDetails: OpenShiftPostionsOrgDetails[];
    public isExpand: boolean;
  }

  export class OpenConfigOrgLevelType {
    public orgLevelType: string;
    public orgLevelId: number;
    public orgLevelName: string;
  }