import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ElementRef, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { MessagesManagementService } from '../../services/messages-management.service';
import { EmployeeSmsManagementService } from '../../../../../app/configuration/services/employee-sms-management/employee-sms-management.service';
import { EmployeeSendSms } from '../../models';
import { ErrorCodeMessageCenter } from '../../constants/error-code.constants';
import { Router } from '@angular/router';
import { SessionService } from '../../../../../app/core/services';
import { unsubscribeAll } from '../../../../core/decorators';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { AccessibleApiService } from '../../../../organization/services/accessible/accessible-api.service';
import { AccessibleService } from '../../../../organization/services/accessible/accessible.service';
import { MenuAccessibleProviderService, LookupApiService } from '../../../../organization';
import * as moment from 'moment';
import { SegmentedMessage } from '../../utils/segments-calculate';
var MessageContentComponent = /** @class */ (function () {
    function MessageContentComponent(messageManService, employeeManService, route, sessionService, appSettingsManageService, elementRef, changeDetectorRef, accessibleApiService, accessibleService, menuAccessibleProviderService, lookupApi) {
        this.messageManService = messageManService;
        this.employeeManService = employeeManService;
        this.route = route;
        this.sessionService = sessionService;
        this.appSettingsManageService = appSettingsManageService;
        this.elementRef = elementRef;
        this.changeDetectorRef = changeDetectorRef;
        this.accessibleApiService = accessibleApiService;
        this.accessibleService = accessibleService;
        this.menuAccessibleProviderService = menuAccessibleProviderService;
        this.lookupApi = lookupApi;
        this.messageConversation = [];
        this.message1 = '';
        this.maxCharPerSms = 320;
        this.isMyMessage = true;
        this.subscriptions = {};
        this.searchText = '';
        this.employeeId = null;
        this.profileUrl = '';
        this.errorCodes = ErrorCodeMessageCenter;
        this.timeCardUrl = '';
        this.scheduleLinkUrl = '';
        this.employeeStatus = '';
        this.employeeLeave = false;
        this.empOptInStatus = null;
        this.loggedInUser = '';
        this.loggedInUserId = null;
        this.employeeNumber = null;
        this.markAsRead = true;
        this.completeThread = [];
        this.getArchiveReadUnreadCount = new EventEmitter();
        this.usersPicture = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.messageConversation = [];
    }
    Object.defineProperty(MessageContentComponent.prototype, "rawEmployeeNumber", {
        get: function () {
            if (!this.employeeNumber)
                return null;
            return "+1" + this.employeeNumber.replace(/[\(\)\-\s']+/g, '');
        },
        enumerable: true,
        configurable: true
    });
    MessageContentComponent.prototype.appendColdThread = function () {
        this.messageManService.appendColdMessageThread(this.selectedMessage, this.selectedMessage.orgLevelId, true, this.isMyMessage);
    };
    MessageContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getAppSettings();
        var constUserDetails = this.sessionService.getUser();
        this.loggedInUser = constUserDetails.name;
        this.loggedInUserId = constUserDetails.id;
        this.subscriptions.subscribeToGetMessageId = this.messageManService.subscribeToGetMessageId(function (v) {
            _this.isLoading = true;
        });
        this.subscriptions.messageFilter = this.messageManService.subscribeToswitchMessageFilter(function (res) {
            _this.isMyMessage = res;
        });
        this.subscriptions.coldMessageThread = this.messageManService.subscribeToGetColdMessageThread(function (v) {
            var data = v.conversation;
            if (data.length > 0)
                _this.messageConversation = _this.messageConversation.concat(JSON.parse(JSON.stringify(data)));
            if (_this.messageConversation.length > 1) {
                _this.messageConversation = _this.messageConversation.sort(function (a, b) {
                    return a.time.localeCompare(b.time);
                });
            }
            var userIds = [];
            for (var _i = 0, _a = _this.messageConversation; _i < _a.length; _i++) {
                var c = _a[_i];
                if (c.userName && c.userId != 0) {
                    if (!userIds.includes(c.userId))
                        userIds.push(c.userId);
                }
            }
            _this.usersPicture = {};
            _this.messageManService.getEmployeePicsByUserIds(userIds);
            _this.employeeId = _this.messageConversation[0].employeeId;
            if (_this.markAsRead) {
                _this.messageManService.updateReadStatusOfthread(_this.employeeId);
            }
            _this.refreshGrid();
            setTimeout(function () {
                _this.scrollBottom();
            }, 200);
        });
        this.subscriptions.messageThread = this.messageManService
            .subscribeToGetMessageThread(function (v) {
            _this.renderConversation(v.conversation);
            _this.timeCardUrl = "#/apps/time/timecards/employee/" + v.employeeData.employeeId + "?keepOrgLevelBreadcrmb=true&orgLevelId=" + v.employeeData.orgLevelId;
            _this.profileUrl = "#/apps/common/employee/" + v.employeeData.employeeId + "/employee_sections?orgLevelId=" + v.employeeData.orgLevelId;
            _this.employeeStatus = v.employeeData.status;
            _this.selectedMessage = v.employeeData;
            _this.employeeLeave = v.employeeData.isOnLeave;
            _this.empOptInStatus = v.employeeData.optIn;
            _this.employeeNumber = v.employeeData.mobilePhoneNumber;
            _this.appendColdThread();
            _this.message1 = '';
            _this.isLoading = false;
            _this.checkAccessForEmployeeRecord(v.employeeData.employeeId);
            if (v.employeeData.orgLevelId) {
                _this.checkAccessForIndividualTimeCard(v.employeeData.orgLevelId);
                _this.checkAccessForIndividualScheduler(v.employeeData.orgLevelId);
            }
            if (_this.selectedMessage.archived) {
                _this.getArchiveReadUnreadCount.emit(true);
            }
            _this.markAsRead = !v.hasOwnProperty('markAsRead') || v.markAsRead;
            setTimeout(function () {
                _this.scrollBottom();
            }, 200);
        });
        this.subscriptions.emptyThread = this.messageManService.subscribeEmptyMessageThread(function (v) {
            _this.completeThread = [];
            _this.messageConversation = [];
        });
        this.refreshGrid();
        this.subscriptions.userPictures = this.messageManService.subscribeToUserPicturesLoaded((function (res) {
            for (var _i = 0, res_1 = res; _i < res_1.length; _i++) {
                var data = res_1[_i];
                _this.usersPicture[data['userId']] = data['profilePicture'];
            }
        }));
        this.subscriptions.latestScheduleCycle = this.messageManService.subscribeToLatestScheduleCyleLoaded(function (res) {
            if (_this.selectedMessage.status != 'Active') {
                _this.scheduleLinkUrl = "#/apps/scheduler/individual_schedule?empID=" + _this.selectedMessage.employeeId + "&orgLevelId=" + _this.selectedMessage.orgLevelId + "&startDate=" + res.startDate + "&endDate=" + res.endDate;
            }
            else {
                _this.scheduleLinkUrl = "#/apps/scheduler/individual_schedule?empID=" + _this.selectedMessage.employeeId + "&orgLevelId=" + _this.selectedMessage.orgLevelId;
            }
            window.open(_this.scheduleLinkUrl, '_blank');
        });
    };
    MessageContentComponent.prototype.ngOnDestroy = function () { };
    MessageContentComponent.prototype.checkAccessForEmployeeRecord = function (employeeId) {
        var _this = this;
        var employeeList = this.menuAccessibleProviderService.getMenuItem("employee_list");
        _.forEach(employeeList.childs, function (m) {
            if (m.displayName == 'Employee List') {
                _this.employeeRecordConversionRestriction = false;
                _this.employeeRecordRightsRestriction = false;
            }
        });
    };
    MessageContentComponent.prototype.checkAccessForIndividualTimeCard = function (orgLevelId) {
        var _this = this;
        this.accessibleApiService.getComponentAccessibleByOrgLevel(this.accessibleService.applicationId, 'individual_timecards', orgLevelId)
            .then(function (res) {
            _this.timeCardConversionRestriction = res.conversionRestriction;
            _this.timeCardRightsRestriction = res.rightsRestriction;
        });
    };
    MessageContentComponent.prototype.checkAccessForIndividualScheduler = function (orgLevelId) {
        var _this = this;
        this.accessibleApiService.getComponentAccessibleByOrgLevel(this.accessibleService.applicationId, 'individual_schedule', orgLevelId)
            .then(function (res) {
            _this.schedulerConversionRestriction = res.conversionRestriction;
            _this.schedulerRightsRestriction = res.rightsRestriction;
        });
    };
    MessageContentComponent.prototype.renderConversation = function (v) {
        this.messageConversation = [];
        if (v.length > 1) {
            v = v.sort(function (a, b) {
                return a.time.localeCompare(b.time);
            });
        }
        this.messageConversation = JSON.parse(JSON.stringify(v));
        this.completeThread = JSON.parse(JSON.stringify(v));
        this.refreshGrid();
    };
    MessageContentComponent.prototype.getAppSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        _a.appSettings = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MessageContentComponent.prototype.setMessageStatus = function (type) {
        if (type == "incomming") {
            return false;
        }
        else {
            return true;
        }
    };
    MessageContentComponent.prototype.sendMessage = function () {
        var _this = this;
        if (this.isLoading)
            return;
        this.isLoading = true;
        var employeeSendSms = new EmployeeSendSms();
        employeeSendSms.messages = [];
        employeeSendSms.orgLevelId = this.orgLevel.id;
        employeeSendSms.accountSid = this.appSettings.TwilioAccountSid;
        employeeSendSms.authToken = this.appSettings.TwilioAuthToken;
        employeeSendSms.messages.push({
            body: this.message1,
            fullName: this.selectedMessage.fullName,
            to: this.rawEmployeeNumber,
            from: this.appSettings.TwilioSenderPhone,
            read: true,
            employeeId: this.selectedMessage.employeeId,
            isOptInRequest: false
        });
        this.employeeManService.sendSmsToEmployee(employeeSendSms).then(function (res) {
            _this.selectedMessage.body = _this.message1;
            _this.messageManService.refreshMessageList(_this.selectedMessage);
            _this.message1 = '';
            if (!_this.selectedMessage.archived) {
                _this.messageManService.updateMessageThread(_this.selectedMessage, _this.orgLevel.id, true, _this.isMyMessage);
            }
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    MessageContentComponent.prototype.refreshGrid = function () {
        if (!this.messageConversation) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.messageConversation, this.gridState.state);
    };
    MessageContentComponent.prototype.onFilter = function (val) {
        this.searchText = val;
    };
    MessageContentComponent.prototype.getFailedStatus = function (item) {
        return this.errorCodes[item];
    };
    MessageContentComponent.prototype.navigateToTimeCard = function () {
        var _this = this;
        this.lookupApi.getPayCyles(this.selectedMessage.orgLevelId, this.selectedMessage.employeeId).then(function (res) {
            var startDate = moment((res[res.length - 1].startDate)).format('MM-DD-YYYY').toString().replace(/-/g, '');
            var endDate = moment((res[res.length - 1].endDate)).format('MM-DD-YYYY').toString().replace(/-/g, '');
            _this.timeCardUrl = "#/apps/time/timecards/employee/" + _this.selectedMessage.employeeId + "?keepOrgLevelBreadcrmb=true&orgLevelId=" + _this.selectedMessage.orgLevelId + "&startDate=" + startDate + "&endDate=" + endDate + "&employeeId=" + _this.selectedMessage.employeeId;
            window.open(_this.timeCardUrl, '_blank');
        });
    };
    MessageContentComponent.prototype.navigateToProfile = function () {
        window.open(this.profileUrl, '_blank');
    };
    MessageContentComponent.prototype.navigateToschedule = function () {
        this.messageManService.getLatestScheduleCycleByEmployeeId(this.selectedMessage.employeeId);
    };
    MessageContentComponent.prototype.scrollBottom = function () {
        if (!this.elementRef.nativeElement) {
            return;
        }
        var msgListElements = this.elementRef.nativeElement.querySelectorAll('#msg-list');
        if (msgListElements && msgListElements[msgListElements.length - 1]) {
            msgListElements[msgListElements.length - 1].scrollIntoView({
                block: 'nearest'
            });
        }
    };
    MessageContentComponent.prototype.isMessageOptIn = function (message) {
        return this.isMessageOptInOut(message, [
            'accept', 'start'
        ]);
    };
    MessageContentComponent.prototype.isMessageOptOut = function (message) {
        return this.isMessageOptInOut(message, [
            'stop'
        ]);
    };
    MessageContentComponent.prototype.isMessageOptInOut = function (message, options) {
        if (typeof message !== 'string') {
            return false;
        }
        var parsedMessage = message.toLowerCase().trim();
        return _.includes(options, parsedMessage);
    };
    MessageContentComponent.prototype.convertHyperLink = function (body) {
        return body.replace(/(http.*?\s)/g, "<a target='_blank' href=\"$1\">$1</a>");
    };
    MessageContentComponent.prototype.getSegmentCount = function () {
        var encoding = "auto";
        var segmentedMessage = new SegmentedMessage(this.message1, encoding);
        return segmentedMessage.segments.length == 1 ? "1 Segment" : segmentedMessage.segments.length + " Segments";
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], MessageContentComponent.prototype, "subscriptions", void 0);
    return MessageContentComponent;
}());
export { MessageContentComponent };
