export enum IdealScheduleConfigTypeDefinition {
  Shift,
  ShiftGroup,
  ShiftUnit
}

export interface IdealScheduleConfigType {
  id: number;
  name: string;
}

export const IdealScheduleConfigTypes: IdealScheduleConfigType[] = [{
  id: IdealScheduleConfigTypeDefinition.Shift,
  name: 'Shift'
}, {
  id: IdealScheduleConfigTypeDefinition.ShiftUnit,
  name: 'Shift & Unit'
},
{
  id: IdealScheduleConfigTypeDefinition.ShiftGroup,
  name: 'Shift Group'
}];
