/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-center-notification-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./message-center-notification-content.component";
var styles_MessageCenterNotificationContentComponent = [i0.styles];
var RenderType_MessageCenterNotificationContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageCenterNotificationContentComponent, data: {} });
export { RenderType_MessageCenterNotificationContentComponent as RenderType_MessageCenterNotificationContentComponent };
export function View_MessageCenterNotificationContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "header-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "header-list-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "tab-headers"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "header-list-list"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "header-list-toolbar"]], null, null, null, null, null)), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "header-list-arrow"], ["x-arrow", ""]], null, null, null, null, null))], null, null); }
export function View_MessageCenterNotificationContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-message-center-notification-content", [], null, null, null, View_MessageCenterNotificationContentComponent_0, RenderType_MessageCenterNotificationContentComponent)), i1.ɵdid(1, 49152, null, 0, i2.MessageCenterNotificationContentComponent, [], null, null)], null, null); }
var MessageCenterNotificationContentComponentNgFactory = i1.ɵccf("slx-message-center-notification-content", i2.MessageCenterNotificationContentComponent, View_MessageCenterNotificationContentComponent_Host_0, {}, {}, [".tab-header", ".notification-list", ".notification-footer"]);
export { MessageCenterNotificationContentComponentNgFactory as MessageCenterNotificationContentComponentNgFactory };
