import { DialogOptions2, DialogModeSize } from './../../../../../common/models/dialog-options';
import { Component, Input, OnInit, Provider, Inject } from '@angular/core';
import * as _ from 'lodash';

import { IDialog } from '../../../../../common/index';

import {
  ModalService,
} from '../../../../../common/index';
import { BenefitEmployeeDependentsEnrollments } from '../../../../../app-modules/benefits/models/index';
import { EmployeeDependentBenefitsOptions } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-dependent-benefits-dialog',
  templateUrl: 'employee-dependent-benefits-dialog.component.html'
})
export class EmployeeDependentBenefitsDialogComponent implements IDialog {
  public dialogOptions: DialogOptions2;
  public dialogResult: boolean;
  public options: EmployeeDependentBenefitsOptions;
  public dependentsEnrollments: BenefitEmployeeDependentsEnrollments;

  public get canEnroll(): boolean {
    return !this.isEnrolling
      && _.isObjectLike(this.benefits)
      && _.isArray(this.benefits.benefits)
      && _.size(this.benefits.benefits) > 0
      && _.isArray(this.benefits.dependents)
      && _.size(this.benefits.dependents) > 0
      && _.isDate(this.benefits.startDate)
      && _.isDate(this.benefits.endDate)
  }

  public get canCancel(): boolean {
    return !this.isEnrolling;
  }

  private modalService: ModalService;
  private benefits: BenefitEmployeeDependentsEnrollments;
  private isEnrolling = false;

  public static openDialog(
    data: EmployeeDependentBenefitsOptions,
    modalService: ModalService,
    callback: (result: boolean) => void
  ): EmployeeDependentBenefitsDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: EmployeeDependentBenefitsOptions,
        useValue: data
      }
    ];
    const title = 'Add Benefit(s) for Dependent(s)';

    return modalService.globalAnchor.openDialog2(
      EmployeeDependentBenefitsDialogComponent,
      title,
      dialogOptions,
      resolvedProviders,
      (result: boolean) => {
        callback(result);
      }
    );
  }

  constructor(
    dialogOptions: DialogOptions2,
    modalService: ModalService,
    options: EmployeeDependentBenefitsOptions,
  ) {
    this.modalService = modalService;
    this.dialogOptions = dialogOptions;
    this.options = options;
  }

  public onSelectBenefits(benefits: BenefitEmployeeDependentsEnrollments): void {
    this.benefits = benefits;
  }

  public onEnroll(): void {
    this.isEnrolling = true;
    this.dependentsEnrollments = this.benefits;
  }

  public onCompleteEnrollment(isSuccessful: boolean): void {
    if (isSuccessful) {
      this.dialogResult = true
      this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    } else {
      this.isEnrolling = false;
    }
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }
}
