<kendo-grid class="slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [groupable]="false"
  [group]="gridState.state.group"
  [skip]="gridState.state.skip"
  (dataStateChange)="gridState.dataStateChange($event)"
  (groupChange)="gridState.groupChange($event)"
>

  <kendo-grid-column field="Hours" title="Ideal Hours" width="140px"> 
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.Hours | number : '1.2-2'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="JobDescription" title="Position" width="140px"> 
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.JobDescription | removeAsterisk}} 
      <span [posGroupIcon]="dataItem.JobDescription"></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="SchedHours" title="Scheduled Hours" width="160px">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.SchedHours | number : '1.2-2'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="ShiftName" title="Shift" width="120px">
    <ng-template kendoGridCellTemplate let-dataItem>
      <a *ngIf="!isEmptyEmployee(dataItem)" (click)="openShift(dataItem)" class="gridShiftName">{{dataItem.ShiftName}}</a>
      <span *ngIf="isEmptyEmployee(dataItem)">{{dataItem.ShiftName}}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="UnitName" title="Unit" width="100px"> </kendo-grid-column>
  <kendo-grid-column field="AgencyName" title="Agency" width="120px" *ngIf="IsEnabled">
    <ng-template kendoGridCellTemplate let-dataItem>
      <span  *ngIf="!isEmptyEmployee(dataItem)">{{dataItem.AgencyName}}</span>
      <span  *ngIf="isEmptyEmployee(dataItem)"></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="isInPar" title="Actions" width="100px" [filterable]="false">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Actions</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="group-column">
        <span
          class="clickable fa-stack fa-2x switch-employee-action actionIcon"
          *ngIf="!isEmptyEmployee(dataItem) && !isInPar(dataItem) && !checkPartnerData(dataItem)"
          (click)="switchEmployee(dataItem)"
        >
          <i class="fa fa-circle fa-stack-2x" aria-hidden="true"></i>
          <i
            class="fas fa-exchange-alt fa-rotate-90 fa-stack-1x fa-inverse"
            aria-hidden="true"
            slxTooltip="Switch Employee Shift"
            tipPosition="left"
          ></i>
        </span>
        <span
          class="clickable fa-stack fa-2x add-employee-action actionIcon"
          *ngIf="isEmptyEmployee(dataItem) && isNoPatner(dataItem)"
          (click)="fillShift(dataItem)"
        >
          <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
          <i class="fa fa-plus fa-stack-1x" aria-hidden="true" slxTooltip="Add Employee" tipPosition="left"></i>
        </span>
        <span
          class="clickable fa-stack fa-2x remove-employee-action actionIcon"
          *ngIf="!isEmptyEmployee(dataItem) && !checkPartnerData(dataItem)"
          (click)="removeEmployee(dataItem)"
        >
          <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
          <i
            class="fa fa-minus fa-stack-1x"
            aria-hidden="true"
            [slxTooltip]="getRemoveTip(dataItem)"
            tipPosition="left"
          ></i>
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>
