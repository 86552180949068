import * as tslib_1 from "tslib";
import { AcaExportMapService } from '../aca-export/aca-export-map.service';
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { exportDataConfig } from '../../export-data.config';
import { appConfig } from '../../../../app.config';
var AcaExportApiService = /** @class */ (function () {
    function AcaExportApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    AcaExportApiService.prototype.getAcaExportConfigurationList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.latest;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToExportDataConfigurationList(response); })];
            });
        });
    };
    AcaExportApiService.prototype.getAcaExportHistory = function (configurationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.exportDataHistory + "/" + configurationId;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToAcaExportHistoryList(response); })];
            });
        });
    };
    AcaExportApiService.prototype.getAcaExportStatuses = function (ids) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.exportDataStatuses;
                request = this.urlParamsService.createPostRequest(url, { executionIds: ids });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToAcaExportStatusList(response); })];
            });
        });
    };
    AcaExportApiService.prototype.generateAcaExport = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.generate;
                request = this.urlParamsService.createPostRequest(url, req);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToAcaExportHistory(response); })];
            });
        });
    };
    AcaExportApiService.prototype.downloadAcaExport = function (type, executionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.download + "/" + type + "/" + executionId;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.requestForFile(request)
                        .then(function (file) { return file; })];
            });
        });
    };
    AcaExportApiService.prototype.cancelAcaExport = function (type, executionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.cancel + "/" + type + "/" + executionId;
                request = this.urlParamsService.createDeleteRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToAcaExportStatus(response); })];
            });
        });
    };
    AcaExportApiService.prototype.getAcaCorrected1095CData = function (executionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.correction.root + "/" + exportDataConfig.api.aca.correction.employees;
                request = this.urlParamsService.createGetRequest(url, { executionId: executionId });
                return [2 /*return*/, this.apiUtilService.
                        request(request)
                        .then(function (response) { return _this.mapService.mapToAcaCorrected1095C(response); })];
            });
        });
    };
    AcaExportApiService.prototype.getReplace1095CData = function (executionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.replacement.root + "/" + exportDataConfig.api.aca.replacement.employees;
                request = this.urlParamsService.createGetRequest(url, { executionId: executionId });
                return [2 /*return*/, this.apiUtilService.
                        request(request)
                        .then(function (response) { return _this.mapService.mapToReplace1095C(response); })];
            });
        });
    };
    AcaExportApiService.prototype.getSupplemental1095CData = function (executionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.supplemental.root + "/" + exportDataConfig.api.aca.supplemental.employees;
                request = this.urlParamsService.createGetRequest(url, { executionId: executionId });
                return [2 /*return*/, this.apiUtilService.
                        request(request)
                        .then(function (response) { return _this.mapService.mapToAcaSupplemental1095C(response); })];
            });
        });
    };
    AcaExportApiService.prototype.getAcaExport1094cCount = function (item, correctionParameters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca.correction.root + "/" + exportDataConfig.api.aca.correction.correction1094c;
                body = this.mapService.mapTo1094CCorrectionDto(item, correctionParameters);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToExportCorrection1094CCount(response); })];
            });
        });
    };
    AcaExportApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version2 + "/" + exportDataConfig.api.root + "/" + exportDataConfig.api.aca.root;
    };
    return AcaExportApiService;
}());
export { AcaExportApiService };
