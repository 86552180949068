import { Component, ViewEncapsulation } from '@angular/core';
import { TreeComponent } from '../../../tree/index';

@Component({
  moduleId: module.id,
  selector: 'slx-query-builder-component',
  encapsulation: ViewEncapsulation.Emulated,
  templateUrl: 'query-builder.component.html'
})
export class QueryBuilderComponent extends TreeComponent { }
