import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common';
import { destroyService, mutableSelect, unsubscribe } from '../../../../core/decorators';
import { NotificationGroup } from '../../../models/index';
import { NotificationsManagementService, UserRolesApiService, NotificationsApiService } from '../../../services/index';
import { NotificationRole } from "../../../models/notifications/notifications-role";
import { NotificationGroupEvent } from '../../../models/notifications/notifications-group-event';
var NotificationsGridComponent = /** @class */ (function () {
    function NotificationsGridComponent(managementService, changeDetector, userService, apiService) {
        this.userService = userService;
        this.apiService = apiService;
        this.defaultItem = { id: 0, name: 'Email' };
        this.uniqueError = false;
        this.notificationRole = [];
        this.bindNotificationEvent = [];
        this.isLoading = false;
        this.deliveryMethodsList = ["Email"];
        this.notificationEvents = [];
        this.notificationGroupEvent = [];
        this.rolesMapData = new Map();
        this.eventMapData = new Map();
        this.eventGroupMapData = new Map();
        this.eventGroupEventsMapData = new Map();
        this.eventDisplayName = "";
        this.roleDisplayName = "";
        this.isInitial = true;
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
    }
    NotificationsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.userSubscription = this.user$.subscribe(function (session) {
            if (session) {
                _this.alias = session.alias;
                if (session.user) {
                    _this.userName = session.user.name;
                    _this.login = session.user.username;
                }
            }
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.closeEditor();
                _this.getRoles(_this.orgLevel.id);
            }
        });
        this.addCmdSubscription = this.managementService.addCmd$.subscribe(function (value) {
            _this.addHandler();
        });
        this.groupSavedSubscription = this.managementService.onGroupSaved$.subscribe(function (result) {
            if (result.index !== -1 && result.savedRole) {
                _this.records[result.index] = result.savedRole;
                _this.refreshGrid();
                _this.changeDetector.markForCheck();
                _this.changeDetector.detectChanges();
            }
        });
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (records) {
            _this.mapNotificationGroup(records);
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        if (this.isInitial) {
            this.isInitial = false;
            this.getRoles(this.orgLevel.id);
        }
    };
    NotificationsGridComponent.prototype.ngOnDestroy = function () { };
    NotificationsGridComponent.prototype.addHandler = function () {
        this.closeEditor();
        this.isNewMode = true;
        var newGroup = new NotificationGroup();
        var roles = [];
        newGroup.groupRoles = roles;
        var events = [];
        newGroup.groupEvents = new NotificationGroupEvent();
        newGroup.groupEvents.events = events;
        this.grid.addRow(newGroup);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    NotificationsGridComponent.prototype.editHandler = function (event) {
        this.isNewMode = false;
        this.closeEditor();
        this.editedRowIndex = event.rowIndex;
        this.editedRecord = event.dataItem;
        event.dataItem.modifiedBy = this.userName;
        this.savedEditedRole = Object.assign({}, event.dataItem);
        this.grid.editRow(this.editedRowIndex);
    };
    NotificationsGridComponent.prototype.cancelHandler = function () {
        this.closeEditor();
        this.isLoading = true;
        // Deep cloning is required to merge saved and edit records. It is better to call get again.
        this.managementService.loadNotificationGroups(this.orgLevel.id);
    };
    NotificationsGridComponent.prototype.removeHandler = function (event) {
        this.isLoading = true;
        this.managementService.onRemoveRecord(this.orgLevel.id, event.dataItem);
    };
    NotificationsGridComponent.prototype.closeEditor = function () {
        this.grid.closeRow(this.editedRowIndex);
        this.isNewMode = false;
        this.editedRowIndex = undefined;
        this.editedRecord = undefined;
        this.savedEditedRole = undefined;
    };
    NotificationsGridComponent.prototype.saveHandler = function (event) {
        this.closeEditor();
        this.isLoading = true;
        if (event.isNew) {
            event.dataItem.modifiedBy = this.userName;
            this.managementService.onAddRecord(this.orgLevel.id, event.dataItem);
        }
        else {
            this.managementService.onSaveRecord(this.orgLevel.id, event.dataItem);
        }
    };
    NotificationsGridComponent.prototype.onKeyName = function (event, dataItem, nameField) {
        this.uniqueError = false;
        if (!dataItem.groupName) {
            return;
        }
        var name = dataItem.groupName;
        var existGroup = _.find(this.records, function (record) { return record.groupName === name && record.groupId !== dataItem.groupId; });
        if (existGroup) {
            this.uniqueError = true;
        }
    };
    NotificationsGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    NotificationsGridComponent.prototype.mapNotificationGroup = function (records) {
        var _this = this;
        this.records = records;
        if (this.records != null && this.records.length > 0) {
            this.records.forEach(function (element) {
                element.groupEvents.name = _this.eventGroupMapData.get(element.groupEvents.eventGroupId);
                element.groupRoles.forEach(function (role) { return role.name = _this.rolesMapData.get(role.roleId).name; });
                if (element.groupEvents.events.length > 0) {
                    // Remove old mapping if there are associated events
                    element.groupEvents.events = element.groupEvents.events.filter(function (event) { return _this.eventMapData.get(event.eventId) != null; });
                    element.groupEvents.events.forEach(function (event) {
                        if (_this.eventMapData.get(event.eventId) != null) {
                            event.name = _this.eventMapData.get(event.eventId).name;
                        }
                    });
                }
                // get display name
                if (element.groupEvents != null) {
                    element.displayEvents = _this.getDisplayNames(element.groupEvents.events);
                }
                element.displayRoles = _this.getDisplayNames(element.groupRoles);
            });
        }
        this.isLoading = false;
    };
    NotificationsGridComponent.prototype.getDisplayNames = function (data) {
        var displayName = "";
        if (data != null && data.length > 0) {
            try {
                displayName = data[0].name;
                if (data.length > 1) {
                    displayName += " & " + (data.length - 1 == 1 ? " 1 other" : data.length - 1 + " others");
                }
            }
            catch (error) {
                // Kendo break if there is one excption.
                console.log(error);
            }
        }
        return displayName;
    };
    NotificationsGridComponent.prototype.checkRequiredFields = function (data) {
        /// find a way to  restrit for new and edit row
        if (data != null && (data.groupName == undefined || data.groupName == '' || data.groupEvents == null || data.groupEvents.events == null ||
            data.groupEvents.events.length == 0 || data.groupRoles == null || data.groupRoles.length == 0 ||
            data.deliveryMethod == undefined || data.deliveryMethod == '')) {
            return true;
        }
        return false;
    };
    NotificationsGridComponent.prototype.getRoles = function (orgLevelId) {
        var _this = this;
        this.userService.getUsersWithRolesOrglevel(orgLevelId).then(function (userRoles) {
            _this.userRoles = userRoles.roles.filter(function (user) { return user.id != -1; });
            _this.notificationRole = [];
            _this.userRoles.forEach(function (element) {
                // This is for drop down to show all
                var currentRole = new NotificationRole(element.id, element.name);
                if (!_this.notificationRole.includes(currentRole)) {
                    _this.notificationRole.push(currentRole);
                    // This is to display view part
                    _this.rolesMapData.set(element.id, currentRole);
                }
            });
            _this.notificationRole.sort(function (role1, role2) { return role1.name.localeCompare(role2.name); });
            _this.getEvents();
        }).catch(function (res) {
            console.log("error while getting user roles");
        });
    };
    NotificationsGridComponent.prototype.getEvents = function () {
        var _this = this;
        this.apiService.getEventGroups().then(function (notificationGroup) {
            _this.notificationGroupEvent = notificationGroup;
            // This is for drop down to show all
            _this.notificationGroupEvent.forEach(function (element) {
                _this.eventGroupMapData.set(element.eventGroupId, element.name);
                var notificationEvents = element.events;
                notificationEvents.sort(function (event1, event2) { return event1.name.localeCompare(event2.name); });
                _this.eventGroupEventsMapData.set(element.eventGroupId, notificationEvents);
                // This is to display view part
                _this.notificationEvents = notificationEvents;
                notificationEvents.forEach(function (event) {
                    _this.eventMapData.set(event.eventId, event);
                });
            });
            _this.loadNotificationGroups();
        });
    };
    NotificationsGridComponent.prototype.loadNotificationGroups = function () {
        var _this = this;
        this.apiService.getNotificationGroups(this.orgLevel.id)
            .then(function (records) {
            _this.records = records;
            _this.mapNotificationGroup(records);
            _this.refreshGrid();
            _this.managementService.onLoadStatusChanged(false);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        })
            .catch(function (reason) {
            console.log("error getting notification groups");
        });
    };
    NotificationsGridComponent.prototype.getSelectionEvents = function (dataItem) {
        var currentList = [];
        if (dataItem.groupEvents != null && dataItem.groupEvents.eventGroupId > 0) {
            currentList = this.eventGroupEventsMapData.get(dataItem.groupEvents.eventGroupId);
            if (this.isNewMode) {
                dataItem.groupEvents.events = currentList;
            }
        }
        return currentList;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], NotificationsGridComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsGridComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsGridComponent.prototype, "addCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsGridComponent.prototype, "cloneCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsGridComponent.prototype, "groupSavedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsGridComponent.prototype, "selectionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsGridComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], NotificationsGridComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", NotificationsManagementService)
    ], NotificationsGridComponent.prototype, "managementService", void 0);
    return NotificationsGridComponent;
}());
export { NotificationsGridComponent };
