import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
var KendoGridBasicFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridBasicFilterComponent, _super);
    function KendoGridBasicFilterComponent(filterService, localization) {
        var _this = _super.call(this, filterService) || this;
        _this.localization = localization;
        return _this;
    }
    Object.defineProperty(KendoGridBasicFilterComponent.prototype, "currentFilter", {
        get: function () {
            return this.filterByField(this.column.field);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridBasicFilterComponent.prototype, "currentOperator", {
        get: function () {
            return this.currentFilter ? this.currentFilter.operator : this.operator;
        },
        enumerable: true,
        configurable: true
    });
    KendoGridBasicFilterComponent.prototype.localizeOperators = function (operators) {
        return function (localization) {
            var res = _.map(_.keys(operators), function (key) {
                var slxLocal = kendoUtils.slxFiltersMap[key];
                if (slxLocal) {
                    return { text: slxLocal.text, value: operators[key] };
                }
                return { text: localization.get(key), value: operators[key] };
            });
            return res;
        };
    };
    KendoGridBasicFilterComponent.prototype.operatorChanged = function (operator) {
        this.readOnly = kendoUtils.isBlankOperator(operator);
    };
    return KendoGridBasicFilterComponent;
}(BaseFilterCellComponent));
export { KendoGridBasicFilterComponent };
