<div class="height-100 chart-container">
    <div class="height-100 chart-item" [ngClass]="{notfull: !isFull}">
        <kendo-chart class="height-100" [seriesColors]="seriesColors" (seriesClick)="handleClick($event)">
            <kendo-chart-area background="#fff" [margin]="-10" >
                </kendo-chart-area>
            <kendo-chart-axis-defaults [majorGridLines]="{ visible : false }"></kendo-chart-axis-defaults>

            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [line]="{ visible: false }"
                    [min]="0" [max]="maxValue" labels="false">
                </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [line]="{ visible: false }"
                    [min]="0" labels="false">
                </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>

            <kendo-chart-series>
                <kendo-chart-series-item [gap]="0" stack="{ type: '100%' }" [data]="[displayValue1]">
                </kendo-chart-series-item>
                <kendo-chart-series-item [data]="[displayValue2]">
                </kendo-chart-series-item>
            </kendo-chart-series>
        </kendo-chart>
    </div>
    <div class="chart-item text-items" (click)="handleClick($event)">
        <span class="label-text" *ngIf="!hideLabel && !isFull" [ngStyle]="{'color': fontColor1 }">{{labelValue | number : '1.0-2' | slxPlus }}</span>
        <i class="fa fa-check full-check" aria-hidden="true" *ngIf="isFull"></i>
    </div>
</div>
