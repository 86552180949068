var UnprocessedTimecardStats = /** @class */ (function () {
    function UnprocessedTimecardStats() {
    }
    return UnprocessedTimecardStats;
}());
export { UnprocessedTimecardStats };
var TimecardQueueDialogRequest = /** @class */ (function () {
    function TimecardQueueDialogRequest() {
    }
    Object.defineProperty(TimecardQueueDialogRequest.prototype, "isValid", {
        get: function () {
            return !!this.orgLevel && !!this.payCycle.startDate && !!this.payCycle.endDate;
        },
        enumerable: true,
        configurable: true
    });
    return TimecardQueueDialogRequest;
}());
export { TimecardQueueDialogRequest };
