<div class="lm-toolbar">
  <slx-toolbar class="slx-so-toolbar-container" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate [width]="getLeaveTypeWidth()">
      <span *ngIf="canAdd" class="lm-toolbar__control as-flex-end">
        <slx-static-dropdown-list
          class="slx-wide slx-short"
          [options]="leaveTypes"
          (selectItem)="onCreate($event)"
          controlIcon="fal fa-file-plus"
          placeholder="Create New"
        ></slx-static-dropdown-list>
      </span>
    </ng-template>



    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" width="130">
      <span class="lm-toolbar__control as-flex-end" *ngIf="!isCollapsed">
        <button class="slx-button slx-with-icon slx-margin-r" type="button" (click)="onClickConsole()">
          <i aria-hidden="true" class="fas fa-list slx-button__icon"></i>
          <span class="slx-button__text">Roster</span>
        </button>
      </span>
    </ng-template>

  </slx-toolbar>
</div>
