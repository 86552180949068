import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { Assert } from '../../../framework/index';
import { ResponseBody, Meta, FieldsMeta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';



import {
  LeaveRequestEntries, LeaveRequestDetailEntries, ILeaveRequestEntries, LeaveRequestActionPayload, ILeaveRequestDetailEntries, LeaveRequestDetailEntry
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { Subject, Subscription } from 'rxjs';
import { schedulerConfig } from '../../scheduler.config';
import { IOpenShiftPositions, OpenShiftPositions } from '../../models/open-position-control/open-shift-positions';
import { OpenPositionControlMapService } from './open-position-control-map.service';
import { IOpenShiftPositionGroupDetails, OpenShiftPositionGroupDetails } from '../../models/open-position-control/open-shift-position-group-details';
import { IopenPositionControlConfig, openPositionControlConfig } from '../../models/open-position-control/open-position-control-config';

@Injectable()
export class OpenPositionsControlApiService {

  private exportTo$ = new Subject<boolean>();
  public isLoadPositionGroupDetails$ = new Subject<boolean>();

  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private openPositionControlMapService: OpenPositionControlMapService) {
  }

  public getOpenPositions(orgLevelId: number, startDate: Date, endDate: Date): Promise<OpenShiftPositions> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = `${this.getLeaveRequestApiRoot(orgLevelId, startDate, endDate)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<IOpenShiftPositions, FieldsMeta>(request)
      .then((response: ResponseBody<IOpenShiftPositions, FieldsMeta>) => this.openPositionControlMapService.mapOpenShiftPositions(response.data, response.meta));
  }


  public getOpenPositionControlConfig(orgLevelId: number): Promise<any>{
    const url: string = this.getOpenPositionControlConfigAPIRoot(orgLevelId);
    let request : HttpRequest<any> = new HttpRequest('GET',url);
    return this.apiUtilService.request<any, FieldsMeta>(request)
    .then((response:ResponseBody<any, FieldsMeta>)=> this.openPositionControlMapService.mapOpenPositionControlData(response.data, response.meta));
  }

  public saveOpenPositionConfiguration(config: openPositionControlConfig ) {
    const url: string = this.getOpenPositionControlConfigSaveAPIRoot();
    //let body = new openPositionControlConfig();
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, config);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  public async getPositionGroupDetails(orglevelId: number, startDate: Date, endDate: Date, shiftGroup: string): Promise<OpenShiftPositionGroupDetails[]> {
    const url: string = this.getPositionGroupDetailsAPIRoot(orglevelId, startDate, endDate, shiftGroup);
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<IOpenShiftPositionGroupDetails[], FieldsMeta>(request)
      .then((response: ResponseBody<IOpenShiftPositionGroupDetails[], FieldsMeta>) => this.openPositionControlMapService.mapOpenShiftGroupDetails(response.data, response.meta));
  }
   
  private getLeaveRequestApiRoot(orgLevelId: number, startDate: Date, endDate: Date): string {
    const startDateStr = dateTimeUtils.convertToDtoString(startDate);
    const endDateStr =  dateTimeUtils.convertToDtoString(endDate);

    return `${this.getOpenPositionApiRoot()}/${startDateStr}/${endDateStr}/orglevel/${orgLevelId}/openShiftDetails`;
  }

  private getPositionGroupDetailsAPIRoot(orgLevelId: number, startDate: Date, endDate: Date, shiftGroup: string): string {
    const startDateStr = dateTimeUtils.convertToDtoString(startDate);
    const endDateStr = dateTimeUtils.convertToDtoString(endDate);

    return `${this.getOpenPositionApiRoot()}/${startDateStr}/${endDateStr}/orglevel/${orgLevelId}/shiftgroup/${shiftGroup}/positionGroupDetails`;
  }
  private getOpenPositionApiRoot(): string {
    return `${this.getApiRoot()}/${schedulerConfig.api.schedule.openPosition}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}`;
  }

  private getOpenPositionControlConfigAPIRoot(orgLevelId: number): string{

    return `${this.getOpenPositionApiRoot()}/config/${orgLevelId}`;
  }

  private getOpenPositionControlConfigSaveAPIRoot(): string {

    return `${this.getOpenPositionApiRoot()}/config/save`;
  }
 
}
