import { ScheduleAbsence, IScheduleAbsence } from '../../../organization/models/index';

export interface IVacationPlanEmployeeRecordDetails {
  date: string;
  isScheduled: boolean;
  isUnpaidAbsence: boolean;
  isRotationDay: boolean;
  scheduleAbsence: IScheduleAbsence;
}

export class VacationPlanEmployeeRecordDetails {
  public date: Date;
  public isScheduled: boolean;
  public isUnpaidAbsence: boolean;
  public isRotationDay: boolean;
  public scheduleAbsence: ScheduleAbsence;
  public scheduleAbsenceStatic: ScheduleAbsence;
  public isActive: boolean;
}
