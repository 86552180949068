import { Directive, Input, Host, OnDestroy, Renderer2, Inject, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { ICollapseComponent, COLLAPSE_COMPONENT } from '../../models/index';

@Directive({
  selector: '[slxCollapseByCss]'
})
export class CollapseByCssDirective implements OnDestroy, AfterViewInit {

  @Input('slxCollapseByCss')
  public elementToCollapse: HTMLElement;
  public isCollapsed: boolean;

  @unsubscribe()
  private collapseSuscription: Subscription;
  @unsubscribe()
  private collapseModifySuscription: Subscription;

  constructor(@Inject(COLLAPSE_COMPONENT) public collapseComponent: ICollapseComponent, private renderer: Renderer2) {
    this.collapseSuscription = collapseComponent.isCollapsedChange.subscribe((isCollapsed: boolean) => {
      this.isCollapsed = isCollapsed;
      this.collapseHandler();
    });
    this.collapseModifySuscription = collapseComponent.isCollapsedModify.subscribe((isCollapsed: boolean) => {
      this.isCollapsed = isCollapsed;
      this.collapseHandler();
    });
  }

  public ngAfterViewInit(): void {
    scheduleMicrotask(() => {
      this.collapseHandler();
    });
  }

  public collapseHandler(): void {
    if (!this.elementToCollapse) {
      return;
    }
    if (this.isCollapsed) {
      this.renderer.addClass(this.elementToCollapse, 'slx-theme-collapsed');
    } else {
      this.renderer.removeClass(this.elementToCollapse, 'slx-theme-collapsed');
    }
  }
  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }
}
