import * as tslib_1 from "tslib";
import * as _ from "lodash";
import { process } from "@progress/kendo-data-query";
import { GridComponent, } from "@progress/kendo-angular-grid";
import { KendoGridStateHelper, ConfirmDialog2Component, ModalService, ConfirmOptions, } from "../../../../../common/index";
import { appConfig } from "../../../../../app.config";
import { EmployeeSectionsBackgroundCheck, EmployeeSectionsBackgroundChecks, } from "../../../models/employee-sections-personal/employee-sections-background-check";
import { unsubscribe } from "../../../../../core/decorators/index";
import { Subscription } from "rxjs";
import { EmployeeSectionsPersonalApiService } from "../../../services";
import { EmployeeSubSectionsDecoratorComponent } from "../../employee-subsection-decorator/employee-subsection-decorator.component";
var EmployeeSectionsBackgroundCheckComponent = /** @class */ (function () {
    function EmployeeSectionsBackgroundCheckComponent(modalService, apiService, decorator) {
        this.modalService = modalService;
        this.apiService = apiService;
        this.decorator = decorator;
        this.newEmpBackgroundCheck = new EmployeeSectionsBackgroundCheck();
        this.isSequenceError = false;
        this.sort = [];
        this.enableSave = false;
        this.addNewRow = new EmployeeSectionsBackgroundCheck();
        this.skip = 0;
        this.pageSize = 5;
        this.subjectSize = 50;
        this.descriptionSize = 255;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: "addedOn", dir: "desc" }];
        this.gridState.state.take = this.pageSize;
        this.createSubscriptions();
        this.decorator.isEditableByConfiguration = false;
    }
    Object.defineProperty(EmployeeSectionsBackgroundCheckComponent.prototype, "backgroundChecklist", {
        set: function (employeeSectionsBackgroundCheck) {
            var _this = this;
            if (employeeSectionsBackgroundCheck != null) {
                var actions = employeeSectionsBackgroundCheck.fieldsMeta.actions;
                _.forEach(actions, function (v) {
                    if (v === "add")
                        _this.canAddCheck = true;
                    if (v === "edit")
                        _this.canEditCheck = true;
                    if (v === "delete")
                        _this.canDeleteCheck = true;
                });
                this.backgroundChecks = employeeSectionsBackgroundCheck.backgroundChecks;
                this.refreshGrid();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBackgroundCheckComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsBackgroundCheckComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function (item) {
            _this.isSequenceError = false;
            _this.editingItem = item;
            _this.addMode = false;
        });
        this.gridCancelSubscription = this.gridState.onCancel$.subscribe(function (item) {
            _this.editingItem = null;
            _this.addMode = false;
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (item) {
            if (item.isNew) {
                item.dataItem.recordId = null;
                _this.addMode = false;
            }
            _this.doUpdate(item.dataItem);
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (item) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialog2Component.openDialog("Delete Background Check", "Are you sure that you want to remove this Background Check?", _this.modalService, function (result) {
                if (result) {
                    _this.doRemove(item.dataItem);
                }
            }, options);
        });
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.onAddCheck = function () {
        this.enableSave = false;
        this.addMode = true;
        this.newEmpBackgroundCheck.subject = null;
        this.newEmpBackgroundCheck.description = null;
        this.grid.addRow(this.newEmpBackgroundCheck);
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.doUpdate = function (item) {
        var _this = this;
        this.isLoading = true;
        this.apiService.setBackgroundCheck(item, this.employeeId).then(function (status) {
            if (status) {
                _this.loadList();
            }
        });
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.doRemove = function (item) {
        var _this = this;
        this.backgroundChecks = _.without(this.backgroundChecks, item);
        this.isLoading = true;
        this.apiService
            .deleteBackgroundCheck(item, this.employeeId)
            .then(function (status) {
            if (status) {
                _this.refreshGrid();
                _this.isLoading = false;
            }
        });
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.refreshGrid = function () {
        if (!this.backgroundChecks) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.backgroundChecks, this.gridState.state);
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.onChangeValue = function (item) {
        var isSubject = this.validateRequiredField(item.subject, this.subjectSize);
        var isDescription = this.validateRequiredField(item.description, this.descriptionSize);
        if (isSubject && isDescription) {
            this.enableSave = true;
        }
        else {
            this.enableSave = false;
        }
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.validateRequiredField = function (value, size) {
        var validate;
        if (value == null || value == "") {
            validate = false;
        }
        else {
            validate = true;
        }
        if (value != null && value != "") {
            if (value.length > size) {
                validate = false;
            }
            else {
                validate = true;
            }
        }
        return validate;
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.loadList = function () {
        var _this = this;
        this.apiService
            .getBackgroundChecks(this.employeeId)
            .then(function (backgroundChecks) {
            _this.backgroundChecks = backgroundChecks.backgroundChecks;
            _this.refreshGrid();
            _this.isLoading = false;
        });
    };
    EmployeeSectionsBackgroundCheckComponent.prototype.pageChange = function (event) {
        this.skip = event.skip;
        this.refreshGrid();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBackgroundCheckComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBackgroundCheckComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBackgroundCheckComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBackgroundCheckComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBackgroundCheckComponent.prototype, "gridCancelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBackgroundCheckComponent.prototype, "formValueChangeSubscription", void 0);
    return EmployeeSectionsBackgroundCheckComponent;
}());
export { EmployeeSectionsBackgroundCheckComponent };
