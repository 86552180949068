import { OnInit, OnDestroy, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Assert } from '../../../framework/index';
import * as moment from 'moment';
import { dateTimeUtils } from '../../utils/index';
var DateTimePickerComponent = /** @class */ (function () {
    function DateTimePickerComponent(elementRef, ngControl) {
        this.placeholder = '';
        this.format = 'MM/dd/yyyy hh:mm tt';
        this.interval = 15;
        this.dateInput = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.ngControl = ngControl;
        ngControl.valueAccessor = this;
        this.datePickerEnabled = true;
    }
    Object.defineProperty(DateTimePickerComponent.prototype, "readonly", {
        set: function (ro) {
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
            this.checkReadonly();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePickerComponent.prototype, "minDate", {
        //TODO: Need to incapsulate this property into directive
        set: function (value) {
            this.minDateInt = value;
            if (this.kendoDateTimePicker) {
                this.kendoDateTimePicker.min(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePickerComponent.prototype, "maxDate", {
        //TODO: Need to incapsulate this property into directive
        set: function (value) {
            this.maxDateInt = value;
            if (this.kendoDateTimePicker) {
                this.kendoDateTimePicker.max(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    DateTimePickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var kendoDateTimePickerOptions = this.kendoDateTimePickerOptions();
        this.$element.kendoDateTimePicker(kendoDateTimePickerOptions);
        this.kendoDateTimePicker = this.$element.data('kendoDateTimePicker');
        this.checkReadonly();
        this.kendoDateTimePicker.min(this.minDateInt);
        this.kendoDateTimePicker.max(this.maxDateInt);
        this.$element.on('input', function () { _this.valueChange(_this.$element.val()); });
    };
    DateTimePickerComponent.prototype.ngOnChanges = function (changes) {
        this.checkReadonly();
    };
    DateTimePickerComponent.prototype.ngOnDestroy = function () {
        if (this.kendoDateTimePicker) {
            this.kendoDateTimePicker.destroy();
        }
    };
    DateTimePickerComponent.prototype.writeValue = function (value) {
        var date = moment(value);
        if (this.kendoDateTimePicker) {
            if (value === null || value === undefined) {
                this.kendoDateTimePicker.value(null);
                return;
            }
            this.kendoDateTimePicker.value(date.toDate());
        }
        if (value) {
            this.lastValue = date;
        }
        else {
            this.lastValue = null;
        }
    };
    DateTimePickerComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    DateTimePickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DateTimePickerComponent.prototype.checkReadonly = function () {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.datePickerEnabled = !(Boolean(myattr) || myattr === 'readonly');
        if (this.kendoDateTimePicker) {
            this.kendoDateTimePicker.readonly(!this.datePickerEnabled);
        }
    };
    DateTimePickerComponent.prototype.kendoDateTimePickerOptions = function () {
        var _this = this;
        var options = {
            format: this.format,
            interval: this.interval,
            dateInput: this.dateInput,
            change: function (e) {
                _this.valueChanged(e);
            }
        };
        if (this.lastValue) {
            options.value = this.lastValue.toDate();
            this.lastValue = undefined;
        }
        return options;
    };
    DateTimePickerComponent.prototype.valueChanged = function (e) {
        var value = this.kendoDateTimePicker.value();
        if (!value) {
            return;
        }
        if (!this.lastValue || !this.lastValue.isSame(moment(value))) {
            this.onChangeCallback(value);
        }
        this.lastValue = moment(value);
    };
    DateTimePickerComponent.prototype.valueChange = function (value) {
        if (dateTimeUtils.validateDate(value)) {
            var date = moment(value);
            if (this.minDateInt && date.isBefore(this.minDateInt)) {
                this.ngControl.control.setErrors({ 'minDate': true });
                return;
            }
            if (this.maxDateInt && date.isAfter(this.maxDateInt)) {
                this.ngControl.control.setErrors({ 'maxDate': true });
                return;
            }
            if (!value) {
                this.lastValue = null;
                this.onChangeCallback(null);
            }
            else {
                this.lastValue = moment(value);
                this.onChangeCallback(this.lastValue.toDate());
            }
            return;
        }
        this.ngControl.control.setErrors({ 'datetime': true });
    };
    Object.defineProperty(DateTimePickerComponent.prototype, "$element", {
        get: function () {
            return $(this.elementRef.nativeElement);
        },
        enumerable: true,
        configurable: true
    });
    return DateTimePickerComponent;
}());
export { DateTimePickerComponent };
