<div class="modal-content">
  <div class="modal-body">
    <slx-pbj-new-export [showConfigWarning]="showConfigWarning" [IsPBJExportCorpLevelEnabled]="IsPBJExportCorpLevelEnabled" [canGeneratePBJFromAgency]="canGeneratePBJFromAgency" (getDateRangeError)="getDateRangeError($event)"  (changeExportRequest)="onChangedExportRequest($event)"></slx-pbj-new-export>
  </div>
  <div class="modal-footer" *ngIf="!IsPBJExportCorpLevelEnabled">
    <button type="button" (click)="onCancel()" class="btn btn-default">
      <i aria-hidden="true" class="fa fa-ban"></i>
      Cancel
    </button>
    <button type="button" (click)="onOk()" [disabled]="!canGenerate || showConfigWarning" class="btn btn-primary">
      <i aria-hidden="true" class="fas fa-retweet"></i>
      Generate
    </button>
  </div>
  <div class="row m-top" *ngIf="IsPBJExportCorpLevelEnabled">
    <div class="col-sm-6">
    <button type="button" class="btn btn-danger bg-red wh-align" (click)="onCancel()">Discard</button></div>
    <div class="col-sm-6" style="padding: 0;">
    <button type="button" class="btn btn-success bg-green wh-align" [disabled]=" !dateRangeCorrect || isDisabledButton || !selCheckbox" (click)="onOk()">Generate</button></div>
  </div>
</div>
