import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
import { appConfig } from '../../../../../app/app.config';
import * as moment from 'moment';
var EmployeeSectionsPayCycleRecord = /** @class */ (function () {
    function EmployeeSectionsPayCycleRecord() {
    }
    Object.defineProperty(EmployeeSectionsPayCycleRecord.prototype, "startDateFormatForExcel", {
        get: function () {
            return moment(this.startDate).format(appConfig.dateFormat);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayCycleRecord.prototype, "endDateFormatForExcel", {
        get: function () {
            return moment(this.endDate).format(appConfig.dateFormat);
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeSectionsPayCycleRecord;
}());
export { EmployeeSectionsPayCycleRecord };
var EmployeeSectionsPayCycleCalcConf = /** @class */ (function () {
    function EmployeeSectionsPayCycleCalcConf() {
    }
    return EmployeeSectionsPayCycleCalcConf;
}());
export { EmployeeSectionsPayCycleCalcConf };
var EmployeeSectionsPayCycles = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPayCycles, _super);
    function EmployeeSectionsPayCycles() {
        var _this = _super.call(this) || this;
        _this.records = [];
        return _this;
    }
    return EmployeeSectionsPayCycles;
}(EmployeeSectionsBase));
export { EmployeeSectionsPayCycles };
