import { Component, Input, Provider, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { GridDataResult, SelectionEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, State, orderBy } from '@progress/kendo-data-query';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { Assert } from '../../../../framework/index';
import * as _ from 'lodash';

import {
  EmployeeDetails, OpenEmplyeesDetails
} from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-details-table-list',
  templateUrl: 'employee-details-table-list.component.html',
  styleUrls: ['employee-details-table-list.component.scss']
})

export class EmployeeDetailsTableListComponent implements IDialog, OnInit {

  @Input()
  public set employeeDetails(details: EmployeeDetails[]) {
    this.gridData = details;
    this.refreshGrid();
  }

  @Input()
  public dateOn: Date;

  public dialogResult: boolean;
  public gridView: GridDataResult;
  public gridState: State;
  public appConfig: IApplicationConfig;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;

  private gridData: EmployeeDetails[];

  constructor() {
    this.gridState = {
      skip: undefined,
      take: undefined,
      filter: undefined,
      sort: [{ field: 'scheduleStart', dir: 'asc' }],
      group: undefined
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public exportToExcel(): void {
    this.grid.saveAsExcel();
  }

  public exportToPdf(): void {
    this.grid.saveAsPDF();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.sort = sort;
    this.refreshGrid();
  }

  private refreshGrid(): void {
    if (!this.gridData) {
      this.gridView = null;
      return;
    }
    let sortedRecords: EmployeeDetails[] = orderBy(this.gridData, this.gridState.sort);
    this.gridView = {
      data: sortedRecords,
      total: sortedRecords.length
    };
  }
}
