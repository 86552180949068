import { Component, Input, Provider, OnInit } from '@angular/core';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { Assert } from '../../../../framework/index';

import {
  EmployeeDetails, OpenEmplyeesDetails
} from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-details-list',
  templateUrl: 'employee-details-list.component.html',
  styleUrls: ['employee-details-list.component.scss']
})

export class EmployeeDetailsListComponent implements IDialog, OnInit {

  @Input()
  public employeeDetails: EmployeeDetails[];

  @Input()
  public dateOn: Date;

  public dialogResult: boolean;

  private appConfig: IApplicationConfig;

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }
}
