/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benfit-plan-notes-dialog-box.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular2-moment/date-format.pipe";
import * as i3 from "@angular/forms";
import * as i4 from "./benfit-plan-notes-dialog-box";
import * as i5 from "../../../../../../common/models/dialog-options";
import * as i6 from "../../../../../../common/services/modal/modal.service";
import * as i7 from "../../../../models/employee-sections-benefits-management/employee-enrollment-history-record";
var styles_BenefitPlanSaveNotesDialogComponent = [i0.styles];
var RenderType_BenefitPlanSaveNotesDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitPlanSaveNotesDialogComponent, data: {} });
export { RenderType_BenefitPlanSaveNotesDialogComponent as RenderType_BenefitPlanSaveNotesDialogComponent };
export function View_BenefitPlanSaveNotesDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DateFormatPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [["class", "col-xs-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enrollment Dates:"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "col-xs-7"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", "-", ""])), i1.ɵppd(8, 2), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 11, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 10, "div", [["class", "notes"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "label", [["class", "notes__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notes"])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "textarea", [["class", "notes__textarea"], ["maxlength", "2500"]], [[1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.notes = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(17, 540672, null, 0, i3.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.MaxLengthValidator]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(20, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(22, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_10 = "2500"; _ck(_v, 17, 0, currVal_10); var currVal_11 = _co.notes; _ck(_v, 20, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.enrollmentStartDate, _co.appConfig.dateFormat)); var currVal_1 = i1.ɵunv(_v, 7, 1, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.enrollmentEndDate, _co.appConfig.dateFormat)); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 17).maxlength ? i1.ɵnov(_v, 17).maxlength : null); var currVal_3 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 22).ngClassValid; var currVal_8 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 15, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_12 = _co.hasNotes; _ck(_v, 24, 0, currVal_12); }); }
export function View_BenefitPlanSaveNotesDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "benefit-plan-notes-dialog", [], null, null, null, View_BenefitPlanSaveNotesDialogComponent_0, RenderType_BenefitPlanSaveNotesDialogComponent)), i1.ɵdid(1, 49152, null, 0, i4.BenefitPlanSaveNotesDialogComponent, [i5.DialogOptions, i6.ModalService, i7.EnrollmentHistoryRecord], null, null)], null, null); }
var BenefitPlanSaveNotesDialogComponentNgFactory = i1.ɵccf("benefit-plan-notes-dialog", i4.BenefitPlanSaveNotesDialogComponent, View_BenefitPlanSaveNotesDialogComponent_Host_0, {}, {}, []);
export { BenefitPlanSaveNotesDialogComponentNgFactory as BenefitPlanSaveNotesDialogComponentNgFactory };
