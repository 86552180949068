export interface IExportDataConfigurationParameterType {
  type: string;
  required: Boolean;
  min: number;
  max: number;
  minlength: number;
  maxlength: number;
}

export class ExportDataConfigurationParameterType {
  public type: string;
  public required: Boolean;
  public min: number;
  public max: number;
  public minlength: number;
  public maxlength: number;
}

export interface IExportDataConfigurationParameter {
  name: string;
  displayName: string;
  dataType: IExportDataConfigurationParameterType;
  sourceType: string;
  lookupType: string;
  source: string;
}

export class ExportDataConfigurationParameter {
  public name: string;
  public displayName: string;
  public dataType: ExportDataConfigurationParameterType;
  public sourceType: string;
  public lookupType: string;
  public source: string;
  public isValid: boolean;
  public value: any;
}
