import * as _ from 'lodash';
import {
  ScheduleAbsence, LocationUnit,
  ShiftDefinition, ConstraintDefinition, Position, Department
} from '../../../organization/models/index';
import { ScheduleEntryShift } from './schedule-entry-shift';
import { IScheduleEntryShiftMainContainer } from './ischedule-entry-shift-main-container';

export class ScheduleEntryShiftContainer implements IScheduleEntryShiftMainContainer {

  public get position(): Position {
    return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.position : null;
  }

  public get department(): Department {
    return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.department : null;
  }

  public get selectedShiftDefinition(): ShiftDefinition {
    return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.shift : null;
  }

  public get unit(): LocationUnit {
    return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.unit : null;
  }

  public get absence(): ScheduleAbsence {
    return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.scheduleAbsence : null;
  }

  public get constraint(): ConstraintDefinition {
    return this.m_scheduleEntryShift ? this.m_scheduleEntryShift.constraint : null;
  }


  public get shiftHoursInMinutes(): number {
    return this.shiftHours * 60;
  }

  public set scheduleEntryShift(value: ScheduleEntryShift) {
    this.m_scheduleEntryShift = value;
    this.shiftStartDate = value.startDate;
    this.shiftEndDate = value.endDate;
    this.shiftHours = value.hours;
    if (this.m_scheduleEntryShift && this.m_scheduleEntryShift.position) {
      this.previousPositionOrgLevelId = this.m_scheduleEntryShift.position.orgLevelId;
    }
  }

  public get scheduleEntryShift(): ScheduleEntryShift {
    return this.m_scheduleEntryShift;
  }

  public get fullTime(): number {
    if (!this.scheduleEntryShift) return 0;
    var partialAbsenseHours = this.scheduleEntryShift.partialAbsence ? this.scheduleEntryShift.partialAbsence.hours : 0;
    return this.unpaidHours + this.scheduleEntryShift.hours + partialAbsenseHours;
  }

  public set constraints(value: ConstraintDefinition[]) {
    this.m_constarints = value;
    this.hasConstraints = _.isArray (value) && !_.isEmpty (value);
  }

  public get constraints(): ConstraintDefinition[] {
    return this.m_constarints;
  }

  public minDate: Date;
  public maxDate: Date;
  public positions: Position[];
  public disabledPosition: Position;

  public previousPositionOrgLevelId: number;

  public hasOverlap: boolean;
  public hasDuplicate: boolean;
  public hasScheduleAbsence: boolean;
  public absences: ScheduleAbsence[];
  public units: LocationUnit[];
  public shifts: ShiftDefinition[];
  public hasConstraints: boolean;

  public endDateLocked: boolean;
  public startDateLocked: boolean;

  public scheduleEntryDate: Date;
  public shiftStartDate: Date;
  public shiftEndDate: Date;
  public shiftHours: number = 0;
  public partialAbsToEnd: boolean;
  public unpaidHours: number = 0;

  private m_scheduleEntryShift: ScheduleEntryShift;
  private m_constarints: ConstraintDefinition[];
}
