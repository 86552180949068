import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { ChangeManagementService, StateManagementService, } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { StateResetTypes } from '../../../core/models/index';
import { ManagementBaseService } from '../../../core/services/index';
import { LookupApiService } from '../../../organization/services/index';
import { RoleColumnsState } from '../../models/index';
import { RolesApiService } from '../roles/roles-api.service';
import { PartnerConfigApiService } from './partner-config-api.service';
var PartnerConfigManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PartnerConfigManagementService, _super);
    function PartnerConfigManagementService(apiService, lookupApiService, changeService, stateService, partnerConfigApiService) {
        var _this = _super.call(this) || this;
        _this.changeService = changeService;
        _this.stateService = stateService;
        _this.partnerConfigApiService = partnerConfigApiService;
        _this.componentId = 'UserRolesComponent';
        _this.controlName = 'rolesColumns';
        _this.resetBy = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        _this.clearChanges$ = new Subject();
        _this.roleRightFilterChanged$ = new ReplaySubject(1);
        _this.rolesColumnsStateChanged$ = new BehaviorSubject(new RoleColumnsState());
        _this.apiService = apiService;
        _this.lookupApiService = lookupApiService;
        _this.stateService.init(_this.componentId);
        return _this;
    }
    PartnerConfigManagementService.prototype.getPartnerPositions = function (customerId, partnerId) {
        var promise = this.partnerConfigApiService
            .getPartnerPositions(customerId, partnerId)
            .then(function (response) {
            return response;
        });
        return promise;
    };
    PartnerConfigManagementService.prototype.getPartnerPositionsDefinitions = function (partnerId) {
        var promise = this.partnerConfigApiService
            .getPartnerPositionsDefinitions(partnerId)
            .then(function (response) {
            return response;
        });
        return promise;
    };
    PartnerConfigManagementService.prototype.addPartnerPosition = function (customerId, partnerId, partnerPositionsList) {
        var promise = this.partnerConfigApiService
            .addPartnerPosition(customerId, partnerId, partnerPositionsList)
            .then(function (response) {
            return response;
        });
        return promise;
    };
    PartnerConfigManagementService.prototype.getPartnersList = function (subscriptionId) {
        var promise = this.partnerConfigApiService.getPartnerDefinitions(subscriptionId).then(function (response) {
            return response;
        });
        return promise;
    };
    PartnerConfigManagementService.prototype.deletePosotionMapping = function (customerId, partnerId, mapId) {
        var promise = this.partnerConfigApiService
            .DeletePartnerPositionMapping(customerId, partnerId, mapId)
            .then(function (response) {
            console.log(response);
            return response;
        });
        return promise;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PartnerConfigManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PartnerConfigManagementService.prototype, "loadSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PartnerConfigManagementService.prototype, "initSubscription", void 0);
    return PartnerConfigManagementService;
}(ManagementBaseService));
export { PartnerConfigManagementService };
