import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DateRange, IDateRange } from '../../../../core/models/index';
import { DeviceDetectorService } from '../../../../common/services/index';
import { ColumnManagementService, StateManagementService } from '../../../../common/services/index';
import { AccrualsTransactionsManagementService } from '../../services/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { LookupApiService } from '../../../../organization/services/index';
import { RecalculateDialogComponent } from '../recalculate-dialog/recalculate-dialog.component';
import { ModalService, DialogOptions } from '../../../../common/index';
var AccrualsTransactionsToolbarComponent = /** @class */ (function () {
    function AccrualsTransactionsToolbarComponent(modalService, accrualsManagementService, columnManagementService, stateManagement, devDetector, lookupApiService) {
        this.modalService = modalService;
        this.accrualsManagementService = accrualsManagementService;
        this.columnManagementService = columnManagementService;
        this.stateManagement = stateManagement;
        this.devDetector = devDetector;
        this.lookupApiService = lookupApiService;
        this.dateRange = new DateRange(null, null);
        this.dayInSec = 60 * 60 * 24;
        this.maxSecondsRange = 366 * this.dayInSec;
        this.maxRangeError = 'The dates range cannot be more than 366 days';
        this.calculateAccrualsAction = false;
        this.columnsStateName = 'AccrualsTransaction';
        this.subscriptions = {};
    }
    Object.defineProperty(AccrualsTransactionsToolbarComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccrualsTransactionsToolbarComponent.prototype, "dateRangeWidth", {
        get: function () {
            return this.isDesktop ? 325 : 246;
        },
        enumerable: true,
        configurable: true
    });
    AccrualsTransactionsToolbarComponent.prototype.ngOnInit = function () {
        this.subscribeToOrgLevel();
        this.initServices();
        this.defineDateRange();
        this.subscribeTocalculateAccrualsAction();
    };
    AccrualsTransactionsToolbarComponent.prototype.subscribeTocalculateAccrualsAction = function () {
        var _this = this;
        this.subscriptions.onLoaded = this.accrualsManagementService
            .subscribeTocalculateAccrualsAction(function (r) {
            _this.calculateAccrualsAction = r;
        });
    };
    AccrualsTransactionsToolbarComponent.prototype.ngOnDestroy = function () { };
    AccrualsTransactionsToolbarComponent.prototype.onChangeDates = function (range) {
        this.accrualsManagementService.changeDateRange(range);
    };
    AccrualsTransactionsToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.accrualsManagementService.exportTo(isPDF);
    };
    AccrualsTransactionsToolbarComponent.prototype.initServices = function () {
        this.stateManagement.init('AccrualsTransactionsComponent');
        this.columnManagementService.init('AccrualsTransactionsComponent');
        this.columnManagementService.initGroup(this.columnsStateName, 11);
    };
    AccrualsTransactionsToolbarComponent.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
        });
    };
    AccrualsTransactionsToolbarComponent.prototype.defineDateRange = function () {
        var _this = this;
        var promise = this.lookupApiService.getPayCyles(this.orgLevel.id);
        promise.then(function (cycles) {
            var startDate = moment().subtract(30, 'days').toDate();
            var currentDate = moment();
            var currentCycles = _.filter(cycles, function (cycle) {
                var currStartDate = moment(cycle.startDate);
                var currEndDate = moment(cycle.endDate);
                return moment(currentDate).isBetween(currStartDate, currEndDate);
            });
            var payCycle = _.first(_.orderBy(currentCycles, function (c) { return moment(c.endDate); }, ['desc']));
            _this.dateRange = new DateRange(startDate, payCycle ? payCycle.endDate : new Date());
            _this.onChangeDates(_this.dateRange);
        });
    };
    AccrualsTransactionsToolbarComponent.prototype.onClickRecalcAccruals = function () {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 950;
        dialogOptions.height = 480;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.hideTitleBar = true;
        var dialog = this.modalService.globalAnchor.openDialog(RecalculateDialogComponent, 'Confirmation', dialogOptions, undefined);
        dialog.orgLevelId = this.orgLevel.id;
        if (dialog.initialized) {
            dialog.onLoadRecalculateData();
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AccrualsTransactionsToolbarComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AccrualsTransactionsToolbarComponent.prototype, "subscriptions", void 0);
    return AccrualsTransactionsToolbarComponent;
}());
export { AccrualsTransactionsToolbarComponent };
