import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { LookupMapService, EmployeeDefinitionsMapService } from '../../../../organization/services/index';
import { IWcReportDto, WcReport, IWcEmployeeInfoDto, WcEmployeeInfo, WCCompensationReport, IWCCompensationReportDto, WcInjuryReport, IWcInjuryReportDto, IWcOSHAReportDto, WcOSHAReport, IWcCodeDefinitionDto, WcCodeDefinition, IWcMedicalTreatmentDefinitionDto, WcMedicalTreatmentDefinition, IWcReportStatusDefinitionDto, WcReportStatusDefinition, WcWorkersCompensationCase, IWcWorkersCompensationCaseDto, IAttachmentDefinitionDto, AttachmentDefinition, ReportCommonFields } from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils';
import { ReadFile, Employee, IUserProfileDefinition, UserProfileDefinition } from '../../../../organization/models';
import { FieldsMeta, IFieldData, FieldAccessType } from '../../../../core/models';
import { MetaMapService } from '../../../../core/services';
import { ThreeChoiceEnum } from '../../models/three-choice.enum';
import { WcSecurityActions } from '../../models/wc-security-actions';

@Injectable()
export class WCReportMapService {

    constructor(private lookupMapService: LookupMapService, private metaMapService: MetaMapService, private empDefMapSerice: EmployeeDefinitionsMapService) {
    }

    public mapToWcReport(dto: IWcReportDto, meta?: FieldsMeta): WcReport {

        let model = new WcReport();

        if (meta) {
            this.setActions(model, meta.actions);
        }

        model.id = dto.id;
        model.addedBy = dto.addedBy;
        model.addedDate = this.toDate(dto.addedDate);
        model.attachments = this.mapAttachments(dto.attachments);
        model.employee = this.mapToEmployeeInfo(dto.employee, meta);
        model.compReport = dto.compReport ? this.mapToCompReport(dto.compReport) : null;
        model.incidentDateTime = this.toDate(dto.incidentDateTime);
        model.injuryReport = this.mapToInjuryReport(dto.injuryReport);
        model.loaRequestId = dto.loaRequestId;
        model.oshaReport = dto.oshaReport ? this.mapToOshaReport(dto.oshaReport) : null;
        model.reportDateTime = this.toDate(dto.reportDateTime);
        model.updatedDate = this.toDate(dto.updatedDate);
        model.updatedBy = dto.updatedBy;

        model.common = new ReportCommonFields();

        model.common.howInjuryOccur = model.injuryReport.howInjuryOccur;
        model.common.whatDoingDuringInjury = model.injuryReport.whatDoingDuringInjury;

        if (model.oshaReport) {
            model.common.caseNumber = model.oshaReport.caseNumber;
            model.common.bodyPart = model.oshaReport.bodyPart;
            model.common.empBeganWork = model.oshaReport.empBeganWork;
            model.common.injuryOccured = model.oshaReport.injuryOccured;
            model.common.isDead = _.isDate(model.oshaReport.dateOfDeath);
            model.common.dateOfDeath = model.oshaReport.dateOfDeath;
            model.common.dateErNotifiedDeath = model.oshaReport.dateErNotifiedDeath;
            model.common.whatDoingBefore = model.oshaReport.whatDoingBefore;
            model.common.activityDescription = model.oshaReport.activityDescription;
            model.common.whatHarmed = model.oshaReport.whatHarmed;
            model.common.wasTreatedToEmergencyRoom = model.oshaReport.wasTreatedToEmergencyRoom;
            model.common.wasHospitalized = model.oshaReport.wasHospitalized;
            model.common.awayTreatment = model.oshaReport.awayTreatment;
        }
        return model;
    }

    private setActions(model: WcReport, actions: string[]): void {
        model.canDelete = _.findIndex(actions, x => x == WcSecurityActions.canDelete) >= 0;
        model.canAdd = model.canEdit = _.findIndex(actions, x => x == WcSecurityActions.canAddEdit) >= 0;
    }

    public mapToInjuryReport(dto: IWcInjuryReportDto): WcInjuryReport {
        let model = new WcInjuryReport();
        model.carrierName = dto.carrierName;
        model.employeeComment = dto.employeeComment;
        model.employeeSignDate = this.toDate(dto.employeeSignDate);
        model.hasEmployeeSign = !_.isNil(model.employeeSignDate);
        model.equipmentCode = this.mapToWcCodeDefinition(dto.equipmentCode);
        model.equipmentText = dto.equipmentText;
        model.exDirectorComment = dto.exDirectorComment;
        model.exDirectorSignDate = this.toDate(dto.exDirectorSignDate);
        model.hasDirectorSign = !_.isNil(model.exDirectorSignDate);
        model.exactLocation = this.mapToWcCodeDefinition(dto.exactLocation);
        model.exposedFluidToAnother = dto.exposedFluidToAnother;
        model.hasCopyForm = dto.hasCopyForm;
        model.hasPriorInjury = dto.hasPriorInjury;
        model.howInjuryOccur = dto.howInjuryOccur;
        model.injuryLocation = this.mapToWcCodeDefinition(dto.injuryLocation);
        model.injuryType = this.mapToWcCodeDefinition(dto.injuryType);
        model.isFormCompletedByEmployee = dto.isFormCompletedByEmployee;
        model.isOSHARecordable = dto.isOSHARecordable;
        model.isWorkersCompCase = dto.isWorkersCompCase;
        model.lossConsciousness = dto.lossConsciousness;
        model.medicalTreatment = this.mapToMedicalTreatment(dto.medicalTreatment);
        model.primaryCause = dto.primaryCause;
        model.reportStatus = this.mapToReportStatus(dto.reportStatus);
        model.supervisorComment = dto.supervisorComment;
        model.supervisorSignDate = this.toDate(dto.supervisorSignDate);
        model.hasSupervisorSign = !_.isNil(model.supervisorSignDate);
        model.usedProperSafety = dto.usedProperSafety;
        model.user = this.mapWcUser(dto.user);
        model.userComment = dto.userComment;
        model.whatDoingDuringInjury = dto.whatDoingDuringInjury;
        model.whoCompletedForm = dto.whoCompletedForm;
        model.isWitnessEmployee = dto.isWitnessEmployee;
        model.witnessName = dto.witnessName;
        model.witnessPhone = dto.witnessPhone;
        model.witnessAddress = dto.witnessAddress;
        model.witnessEmployee = dto.witnessEmployee;
        model.medicalCost = dto.medicalCost;
        model.indemnityCost = dto.indemnityCost;
        model.otherTreatmentDescription = dto.otherTreatmentDescription;
        return model;
    }

    public mapToOshaReport(dto: IWcOSHAReportDto): WcOSHAReport {
        let model = new WcOSHAReport();
        model.activityDescription = dto.activityDescription;
        model.admittedHospitalDaysNum = dto.admittedHospitalDaysNum;
        model.awayTreatment = dto.awayTreatment;
        model.bodyPart = this.mapToWcCodeDefinition(dto.bodyPart);
        model.caseNumber = dto.caseNumber;
        model.dateErNotifiedDeath = this.toDate(dto.dateErNotifiedDeath);
        model.dateOfDeath = this.toDate(dto.dateOfDeath);
        model.empBeganWork = this.toDate(dto.empBeganWork);
        model.empRestriction = dto.empRestriction;
        model.injuryOccured = this.mapToWcCodeDefinition(dto.injuryOccured);
        model.isLostDays = dto.isLostDays;
        model.isPrivacyCase = dto.isPrivacyCase;
        model.isShiftFinished = dto.isShiftFinished;
        model.lostDaysEndDate = this.toDate(dto.lostDaysEndDate);
        model.lostDaysStartDate = this.toDate(dto.lostDaysStartDate);
        model.lostDaysCount = dto.lostDaysCount;
        model.oshaInjuryType = this.mapToWcCodeDefinition(dto.oshaInjuryType);
        model.otherComments = dto.otherComments;
        model.physicianName = dto.physicianName;
        model.restrictedDutyDaysNum = dto.restrictedDutyDaysNum;
        model.isRestrictedDuty = dto.restrictedDutyDaysNum > 0;
        model.wasEmpRestriction = dto.wasEmpRestriction;
        model.wasHospitalized = dto.wasHospitalized;
        model.wasPhysTherapyOrdered = dto.wasPhysTherapyOrdered;
        model.wasTreatedToEmergencyRoom = dto.wasTreatedToEmergencyRoom;
        model.whatDoingBefore = dto.whatDoingBefore;
        model.whatHarmed = dto.whatHarmed;
        model.workersCompCase = this.mapToWorkesComCase(dto.workersCompCase);
        return model;
    }

    public mapToCompReport(dto: IWCCompensationReportDto): WCCompensationReport {

        let model = new WCCompensationReport();
        model.accidentCause = this.mapToWcCodeDefinition(dto.accidentCause);
        model.addressServiceAgency = dto.addressServiceAgency;

        model.accidentState = dto.accidentState ? this.lookupMapService.mapState(dto.accidentState) : null;

        model.bodySide = dto.bodySide;

        model.comments = dto.comments;
        model.dateErNotified = this.toDate(dto.dateErNotified);
        model.dateLoseBegan = this.toDate(dto.dateLoseBegan);
        model.dateLoseEnd = this.toDate(dto.dateLoseEnd);
        model.dateTreatmentReceived = this.toDate(dto.dateTreatmentReceived);
        model.daysWorkedPerWeek = dto.daysWorkedPerWeek;
        model.didRequireTreatment = dto.didRequireTreatment;
        model.empTrDutyEnd = this.toDate(dto.empTrDutyEnd);
        model.empTrDutyStart = this.toDate(dto.empTrDutyStart);
        model.erPhoneNumber = dto.erPhoneNumber;
        model.expectReturnDate = this.toDate(dto.expectReturnDate);
        model.fullPayForInjuryDate = this.mapThreeChoiceEnum(dto.fullPayForInjuryDate);
        model.hoursWorkedPerDay = dto.hoursWorkedPerDay;
        model.injuryByNature = this.mapToWcCodeDefinition(dto.injuryByNature);
        model.isDrugsInvolved = dto.isDrugsInvolved;
        model.isDuringEmployment = dto.isDuringEmployment;
        model.isEmpQualifyFLMA = dto.isEmpQualifyFLMA;
        model.isErQuestionOnClime = dto.isErQuestionOnClime;
        model.isGivenMedicalProviders = dto.isGivenMedicalProviders;
        model.isGivenNotifyForm = dto.isGivenNotifyForm;
        model.isLosingTime = dto.isLosingTime;
        model.isMPPutInWcFile = dto.isMPPutInWcFile;
        model.isNFInWcFile = dto.isNFInWcFile;
        model.isOccurEmpOnPremises = dto.isOccurEmpOnPremises;
        model.isRecordOnly = dto.isRecordOnly;
        model.isRepresByAttorney = dto.isRepresByAttorney;
        model.isSafeguardsProvided = dto.isSafeguardsProvided;
        model.isWcExplained = dto.isWcExplained;
        model.lastDateWorked = this.toDate(dto.lastDateWorked);
        model.natureOfInjuryDesc = dto.natureOfInjuryDesc;
        model.numberOfWeeksUsed = dto.numberOfWeeksUsed;
        model.occupation = dto.occupation;
        model.occurenceAddress = dto.occurenceAddress;
        model.occurenceCity = dto.occurenceCity;
        model.occurenceState = dto.occurenceState;
        model.occurenceZip = dto.occurenceZip;
        model.paymentFreq = dto.paymentFreq;
        model.preparedDate = this.toDate(dto.preparedDate);
        model.preparerName = dto.preparerName;
        model.preparerPhoneNumber = dto.preparerPhoneNumber;
        model.preparerSignature = dto.preparerSignature;
        model.salaryContinued = this.mapThreeChoiceEnum(dto.salaryContinued);
        model.totalGrossWeekly = dto.totalGrossWeekly;
        model.valOfDiscountedFringes = dto.valOfDiscountedFringes;
        model.wasCertified = dto.wasCertified;
        model.wasEmpPlacedOnTrDuty = dto.wasEmpPlacedOnTrDuty;
        model.wasVolunteer = dto.wasVolunteer;
        return model;
    }

    public mapToEmployeeInfo(dto: IWcEmployeeInfoDto, meta: FieldsMeta): WcEmployeeInfo {


        let model: WcEmployeeInfo = new WcEmployeeInfo();
        model.id = dto.id;
        model.age = dto.age;
        model.department = this.lookupMapService.mapDepartment(dto.department);
        model.dependents = dto.dependens;
        model.employee = this.empDefMapSerice.mapToEmployeeDefinition(dto.employee);
        model.organization = this.lookupMapService.mapOrganization(dto.organization);
        model.position = this.lookupMapService.mapPosition(dto.position);

        if (meta) {
            let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
            const zip = this.metaMapService.mapMeta<string>(metaMap, 'empZip', dto.zip);
            model.zip = zip.fieldValue;

            const state = this.metaMapService.mapMeta<string>(metaMap, 'empState', dto.state);
            model.state = state.fieldValue;

            const city = this.metaMapService.mapMeta<string>(metaMap, 'empCity', dto.city);
            model.city = city.fieldValue;

            const address = this.metaMapService.mapMeta<string>(metaMap, 'empAddress', dto.address);
            model.address = address.fieldValue;

            const hireDate = this.metaMapService.mapMeta<Date>(metaMap, 'empDtHire',
                this.toDate(dto.dateHired), dto.dateHired);
            model.dateHired = hireDate.securityAttribute.access === FieldAccessType.full ? hireDate.fieldValue : hireDate.rawValue;

            const birthDate = this.metaMapService.mapMeta<Date>(metaMap, 'empDOB',
                this.toDate(dto.dateOfBirth), dto.dateOfBirth);
            model.dateOfBirth = birthDate.securityAttribute.access === FieldAccessType.full ? birthDate.fieldValue : birthDate.rawValue;

            const ssn = this.metaMapService.mapMeta<string>(metaMap, 'empSsn', dto.ssn);
            model.ssn = ssn.fieldValue;

            const empType = this.metaMapService.mapMeta<string>(metaMap, 'empType', dto.empType);
            model.empType = empType.fieldValue;

            const fullName = this.metaMapService.mapMeta<string>(metaMap, 'empName', dto.employee.name);
            model.employee.name = fullName.fieldValue;

            const phone = this.metaMapService.mapMeta<string>(metaMap, 'empPhone', dto.employee.phoneNumber);
            model.employee.phoneNumber = phone.fieldValue;

            const mobilePhone = this.metaMapService.mapMeta<string>(metaMap, 'cellPhoneNo', dto.employee.mobilePhoneNumber);
            model.employee.mobilePhoneNumber = mobilePhone.fieldValue;

            const firstName = this.metaMapService.mapMeta<string>(metaMap, 'empFirstName', dto.firstName);
            model.firstName = firstName.fieldValue;

            const middleName = this.metaMapService.mapMeta<string>(metaMap, 'empMiddleName', dto.middleName);
            model.middleName = middleName.fieldValue;

            const lastName = this.metaMapService.mapMeta<string>(metaMap, 'empLastName', dto.lastName);
            model.lastName = lastName.fieldValue;

            const gender = this.metaMapService.mapMeta<string>(metaMap, 'empSex', dto.gender);
            model.gender = gender.fieldValue;

            const race = this.metaMapService.mapMeta<string>(metaMap, 'empRace', dto.race);
            model.race = race.fieldValue;

            const maritalStatus = this.metaMapService.mapMeta<string>(metaMap, 'maritalStatus', dto.maritalStatus);
            model.maritalStatus = maritalStatus.fieldValue;

            model.metaMap = metaMap;

        } else {
            model.zip = dto.zip;
            model.state = dto.state;
            model.city = dto.city;
            model.address = dto.address;
            model.dateHired = this.isValidDateString(dto.dateHired) ? this.toDate(dto.dateHired) : dto.dateHired;
            model.dateOfBirth = this.isValidDateString(dto.dateOfBirth) ? this.toDate(dto.dateOfBirth) : dto.dateOfBirth;
            model.firstName = dto.firstName;
            model.middleName = dto.middleName;
            model.lastName = dto.lastName;
            model.gender = dto.gender;
            model.race = dto.race;
            model.maritalStatus = dto.maritalStatus;
        }

        return model;
    }

    public mapToReportStatuses(dtos: IWcReportStatusDefinitionDto[]): WcReportStatusDefinition[] {
        return _.map(dtos, t => this.mapToReportStatus(t));
    }

    public mapToReportStatus(dto: IWcReportStatusDefinitionDto): WcReportStatusDefinition {
        if (dto) {
            let model = new WcReportStatusDefinition;
            model.id = dto.id;
            model.name = dto.name;
            return model;
        }
        return null;
    }

    public maptoMedicalTreatments(dtos: IWcMedicalTreatmentDefinitionDto[]): WcMedicalTreatmentDefinition[] {
        return _.map(dtos, t => this.mapToMedicalTreatment(t));
    }

    public mapToMedicalTreatment(dto: IWcMedicalTreatmentDefinitionDto): WcMedicalTreatmentDefinition {
        if (dto) {
            let model = new WcMedicalTreatmentDefinition();
            model.id = dto.id;
            model.name = dto.name;
            return model;
        }
        return null;
    }

    public mapToWcCodeDefinitions(dtos: IWcCodeDefinitionDto[]): WcCodeDefinition[] {
        return _.map(dtos, code => this.mapToWcCodeDefinition(code));
    }

    public mapToWcCodeDefinition(dto: IWcCodeDefinitionDto): WcCodeDefinition {
        if (dto) {
            let model: WcCodeDefinition = new WcCodeDefinition();
            model.id = dto.id;
            model.codeType = dto.codeType;
            model.code = dto.code;
            model.description = dto.description;
            return model;
        }
        return null;
    }

    public mapWcUser(dto: IUserProfileDefinition): UserProfileDefinition {
        if (_.isObjectLike(dto)) {
            return this.lookupMapService.mapUserProfileDefinition(dto);
        }
        return null;
    }

    public mapToWorkesComCases(dtos: IWcWorkersCompensationCaseDto[]): WcWorkersCompensationCase[] {
        return _.map(dtos, t => this.mapToWorkesComCase(t));
    }

    public mapToWorkesComCase(dto: IWcWorkersCompensationCaseDto): WcWorkersCompensationCase {
        if (dto) {
            let model = new WcWorkersCompensationCase();
            model.id = dto.id;
            model.name = dto.name;
            return model;
        }
        return null;
    }

    public mapAttachments(attachments: IAttachmentDefinitionDto[]): AttachmentDefinition[] {
        return _.map(attachments, a => this.mapAttachment(a));
    }

    public mapAttachment(a: IAttachmentDefinitionDto): AttachmentDefinition {
        if (a) {
            let model = new AttachmentDefinition();
            model.id = a.id;
            model.name = a.name;
            model.fileName = a.fileName;
            model.employeeId = a.employeeId;
            model.addedBy = a.addedBy;
            model.addedOn = this.toDate(a.addedOn);
            return model;
        }
        return null;
    }

    public mapToWcReportDto(model: WcReport): IWcReportDto {

        // map common fields to appropriate models
        model.injuryReport.whatDoingDuringInjury = model.common.whatDoingDuringInjury;
        model.injuryReport.howInjuryOccur = model.common.howInjuryOccur;

        if (model.oshaReport) { // should we add same to comp report?
            model.oshaReport.caseNumber = model.common.caseNumber;
            model.oshaReport.bodyPart = model.common.bodyPart;
            model.oshaReport.empBeganWork = model.common.empBeganWork;
            model.oshaReport.injuryOccured = model.common.injuryOccured;
            model.oshaReport.dateOfDeath = model.common.dateOfDeath;
            model.oshaReport.dateErNotifiedDeath = model.common.dateErNotifiedDeath;
            model.oshaReport.whatDoingBefore = model.common.whatDoingBefore;
            model.oshaReport.activityDescription = model.common.activityDescription;
            model.oshaReport.whatHarmed = model.common.whatHarmed;
            model.oshaReport.wasTreatedToEmergencyRoom = model.common.wasTreatedToEmergencyRoom;
            model.oshaReport.wasHospitalized = model.common.wasHospitalized;
            model.oshaReport.awayTreatment = model.common.awayTreatment;
        }

        return {
            id: model.id,
            addedBy: model.addedBy,
            addedDate: this.toDateTimeStr(model.addedDate),
            attachments: this.mapAttachmentDtos(model.attachments),
            employee: this.mapToEmployeeInfoDto(model.employee),
            injuryReport: this.mapToInjuryReportDto(model.injuryReport),
            compReport: _.isObjectLike(model.compReport) && _.size(model.compReport) > 0 ? this.mapToCompReportDto(model.compReport) : null,
            oshaReport: _.isObjectLike(model.oshaReport) && _.size(model.oshaReport) ? this.mapToOshaReportDto(model.oshaReport) : null,
            incidentDateTime: this.toDateTimeStr(model.incidentDateTime),
            reportDateTime: this.toDateTimeStr(model.reportDateTime),
            loaRequestId: model.loaRequestId,
            updatedBy: model.updatedBy,
            updatedDate: this.toDateTimeStr(model.updatedDate)
        };
    }

    public mapToEmployeeInfoDto(model: WcEmployeeInfo): IWcEmployeeInfoDto {
        return {
            zip: model.zip,
            state: model.state,
            city: model.city,
            address: model.address,
            age: model.age,
            dateHired: _.isDate(model.dateHired) ? this.toDateStr(model.dateHired) : model.dateHired as string,
            dateOfBirth: _.isDate(model.dateOfBirth) ? this.toDateStr(model.dateOfBirth) : model.dateOfBirth as string,
            department: this.lookupMapService.mapDepartmentDto(model.department),
            dependens: model.dependents,
            empType: model.empType,
            employee: this.empDefMapSerice.mapToEmployeeDefinitionDto(model.employee),
            firstName: model.firstName,
            gender: model.gender,
            id: model.id,
            lastName: model.lastName,
            maritalStatus: model.maritalStatus,
            middleName: model.middleName,
            organization: this.lookupMapService.mapOrganizationDto(model.organization),
            position: this.lookupMapService.mapPositionDto(model.position),
            race: model.race,
            ssn: model.ssn
        };
    }

    public mapToInjuryReportDto(model: WcInjuryReport): IWcInjuryReportDto {
        return {
            carrierName: model.carrierName,
            employeeComment: model.employeeComment,
            employeeSignDate: model.hasEmployeeSign ? this.toDateStr(model.employeeSignDate) : null,
            equipmentCode: model.equipmentCode ? this.mapToWcCodeDefinitionDto(model.equipmentCode) : null,
            equipmentText: model.equipmentText,
            exDirectorComment: model.exDirectorComment,
            exDirectorSignDate: model.hasDirectorSign ? this.toDateStr(model.exDirectorSignDate) : null,
            exactLocation: model.exactLocation ? this.mapToWcCodeDefinitionDto(model.exactLocation) : null,
            exposedFluidToAnother: model.exposedFluidToAnother,
            hasCopyForm: model.hasCopyForm,
            hasPriorInjury: model.hasPriorInjury,
            howInjuryOccur: model.howInjuryOccur,
            injuryLocation: model.injuryLocation ? this.mapToWcCodeDefinitionDto(model.injuryLocation) : null,
            injuryType: model.injuryType ? this.mapToWcCodeDefinitionDto(model.injuryType) : null,
            isFormCompletedByEmployee: model.isFormCompletedByEmployee,
            isOSHARecordable: model.isOSHARecordable,
            isWorkersCompCase: model.isWorkersCompCase,
            lossConsciousness: model.lossConsciousness,
            medicalTreatment: model.medicalTreatment ? this.mapToMedicalTreatmentDto(model.medicalTreatment) : null,
            primaryCause: model.primaryCause,
            reportStatus: model.reportStatus ? this.mapToReportStatusDto(model.reportStatus) : null,
            supervisorComment: model.supervisorComment,
            supervisorSignDate: model.hasSupervisorSign ? this.toDateStr(model.supervisorSignDate) : null,
            usedProperSafety: model.usedProperSafety,
            user: model.user ? this.lookupMapService.mapUserProfileDefinitionDto(model.user) : null,
            userComment: model.userComment,
            whatDoingDuringInjury: model.whatDoingDuringInjury,
            whoCompletedForm: !model.isFormCompletedByEmployee ? model.whoCompletedForm : null,
            isWitnessEmployee: model.isWitnessEmployee,
            witnessName: model.witnessName,
            witnessPhone: model.witnessPhone,
            witnessAddress: model.witnessAddress,
            witnessEmployee: model.witnessEmployee,
            medicalCost: model.medicalCost,
            indemnityCost: model.indemnityCost,
            otherTreatmentDescription: model.otherTreatmentDescription
        };
    }

    public mapToOshaReportDto(model: WcOSHAReport): IWcOSHAReportDto {
        return {
            activityDescription: model.activityDescription,
            admittedHospitalDaysNum: model.admittedHospitalDaysNum,
            awayTreatment: model.awayTreatment,
            bodyPart: model.bodyPart ? this.mapToWcCodeDefinitionDto(model.bodyPart) : null,
            caseNumber: model.caseNumber,
            dateErNotifiedDeath: this.toDateStr(model.dateErNotifiedDeath),
            dateOfDeath: this.toDateStr(model.dateOfDeath),
            empBeganWork: this.toDateTimeStr(model.empBeganWork),
            empRestriction: model.empRestriction,
            injuryOccured: model.injuryOccured ? this.mapToWcCodeDefinitionDto(model.injuryOccured) : null,
            isLostDays: model.isLostDays,
            isPrivacyCase: model.isPrivacyCase,
            isShiftFinished: model.isShiftFinished,
            lostDaysEndDate: this.toDateStr(model.lostDaysEndDate),
            lostDaysStartDate: this.toDateStr(model.lostDaysStartDate),
            lostDaysCount: model.lostDaysCount,
            oshaInjuryType: model.oshaInjuryType ? this.mapToWcCodeDefinitionDto(model.oshaInjuryType) : null,
            otherComments: model.otherComments,
            physicianName: model.physicianName,
            restrictedDutyDaysNum: model.restrictedDutyDaysNum,
            wasEmpRestriction: model.wasEmpRestriction,
            wasHospitalized: model.wasHospitalized,
            wasPhysTherapyOrdered: model.wasPhysTherapyOrdered,
            wasTreatedToEmergencyRoom: model.wasTreatedToEmergencyRoom,
            whatDoingBefore: model.whatDoingBefore,
            whatHarmed: model.whatHarmed,
            workersCompCase: model.workersCompCase
        };
    }

    public mapToCompReportDto(model: WCCompensationReport): IWCCompensationReportDto {

        let state = model.accidentState ? this.lookupMapService.mapStateDto(model.accidentState) : null;

        return {
            accidentCause: model.accidentCause ? this.mapToWcCodeDefinitionDto(model.accidentCause) : null,
            accidentState: state ? state : null,
            addressServiceAgency: model.addressServiceAgency,
            bodySide: model.bodySide,
            comments: model.comments,
            dateErNotified: this.toDateStr(model.dateErNotified),
            dateLoseBegan: this.toDateStr(model.dateLoseBegan),
            dateLoseEnd: this.toDateStr(model.dateLoseEnd),
            dateTreatmentReceived: this.toDateStr(model.dateTreatmentReceived),
            daysWorkedPerWeek: model.daysWorkedPerWeek,
            didRequireTreatment: model.didRequireTreatment,
            empTrDutyEnd: this.toDateStr(model.empTrDutyEnd),
            empTrDutyStart: this.toDateStr(model.empTrDutyStart),
            erPhoneNumber: model.erPhoneNumber,
            expectReturnDate: this.toDateStr(model.expectReturnDate),
            fullPayForInjuryDate: model.fullPayForInjuryDate ? this.mapThreeChoiceEnumToValue(model.fullPayForInjuryDate.id) : null,
            hoursWorkedPerDay: model.hoursWorkedPerDay,
            injuryByNature: model.injuryByNature ? this.mapToWcCodeDefinitionDto(model.injuryByNature) : null,
            isDrugsInvolved: model.isDrugsInvolved,
            isDuringEmployment: model.isDuringEmployment,
            isEmpQualifyFLMA: model.isEmpQualifyFLMA,
            isErQuestionOnClime: model.isErQuestionOnClime,
            isGivenMedicalProviders: model.isGivenMedicalProviders,
            isGivenNotifyForm: model.isGivenNotifyForm,
            isLosingTime: model.isLosingTime,
            isMPPutInWcFile: model.isMPPutInWcFile,
            isNFInWcFile: model.isNFInWcFile,
            isOccurEmpOnPremises: model.isOccurEmpOnPremises,
            isRecordOnly: model.isRecordOnly,
            isRepresByAttorney: model.isRepresByAttorney,
            isSafeguardsProvided: model.isSafeguardsProvided,
            isWcExplained: model.isWcExplained,
            lastDateWorked: this.toDateStr(model.lastDateWorked),
            natureOfInjuryDesc: model.natureOfInjuryDesc,
            numberOfWeeksUsed: model.numberOfWeeksUsed,
            occupation: model.occupation,
            occurenceAddress: model.occurenceAddress,
            occurenceCity: model.occurenceCity,
            occurenceState: model.occurenceState,
            occurenceZip: model.occurenceZip,
            paymentFreq: model.paymentFreq,
            preparedDate: this.toDateStr(model.preparedDate),
            preparerName: model.preparerName,
            preparerPhoneNumber: model.preparerPhoneNumber,
            preparerSignature: model.preparerSignature,
            salaryContinued: model.salaryContinued ? this.mapThreeChoiceEnumToValue(model.salaryContinued.id) : null,
            totalGrossWeekly: model.totalGrossWeekly,
            valOfDiscountedFringes: model.valOfDiscountedFringes,
            wasCertified: model.wasCertified,
            wasEmpPlacedOnTrDuty: model.wasEmpPlacedOnTrDuty,
            wasVolunteer: model.wasVolunteer
        };
    }

    public mapAttachmentDtos(attachments: AttachmentDefinition[]): IAttachmentDefinitionDto[] {
        return _.map(attachments, a => this.mapAttachmentDto(a));
    }

    public mapAttachmentDto(a: AttachmentDefinition): IAttachmentDefinitionDto {
        return {
            id: a.id,
            employeeId: a.employeeId,
            fileName: a.fileName,
            name: a.name,
            addedBy: a.addedBy,
            addedOn: this.toDateTimeStr(a.addedOn)
        };
    }

    public mapToReportStatusDto(model: WcReportStatusDefinition): IWcReportStatusDefinitionDto {
        return {
            id: model.id,
            name: model.name
        };
    }

    public mapToMedicalTreatmentDto(model: WcMedicalTreatmentDefinition): IWcMedicalTreatmentDefinitionDto {
        return {
            id: model.id,
            name: model.name
        };
    }

    public mapToWcCodeDefinitionDto(model: WcCodeDefinition): IWcCodeDefinitionDto {
        return {
            id: model.id,
            code: model.code,
            codeType: model.codeType,
            description: model.description
        };
    }

    public mapToWorkesComCaseDto(model: WcWorkersCompensationCase): IWcWorkersCompensationCaseDto {
        return {
            id: model.id,
            name: model.name
        };
    }

    public mapFilesToFormData(files: ReadFile[]): FormData {
        const formData: FormData = new FormData();
        let index = 0;
        _.forEach(files, (file: ReadFile) => {
            formData.append(this.mapFormDataPropName('name', index), file.name);
            formData.append(this.mapFormDataPropName('fileName', index), file.fileName);
            formData.append(this.mapFormDataPropName('category', index), file.mimeType);
            formData.append(this.mapFormDataPropName('file', index), file.data, file.fileName);
            index++;
        });
        return formData;
    }

    public mapFormDataPropName(prop: string, index: number): string {
        return `i${index}_${prop}`;
    }


    public mapWcEmployeeInfoToEmployeeLookup(wcInfo: WcEmployeeInfo): Employee {
        let employee: Employee = new Employee();
        employee.id = wcInfo.id;
        employee.name = wcInfo.employee.name;
        return employee;
    }

    private toDate(str: string): Date {
        return dateTimeUtils.convertFromDtoString(str);
    }

    private toDateStr(date: Date): string {
        if (_.isNil(date)) {
            return null;
        }
        return dateTimeUtils.convertToDtoString(date);
    }
    private toDateTimeStr(date: Date): string {
        if (_.isNil(date)) {
            return null;
        }
        return dateTimeUtils.convertToDtoDateTimeString(date);
    }

    private isValidDateString(str) {
        const d = moment(str);
        if (d == null || !d.isValid()) return false;
        return true;
    }

    private mapThreeChoiceEnum(value: true | false | null): { id: ThreeChoiceEnum, name: string } {
        if (value === true) {
            return { id: ThreeChoiceEnum.Yes, name: 'Yes' };
        } else if (value === false) {
            return { id: ThreeChoiceEnum.No, name: 'No' };
        }
        return { id: ThreeChoiceEnum.Unknown, name: 'Unknown' };
    }

    private mapThreeChoiceEnumToValue(value: ThreeChoiceEnum): true | false | null {
        if (value === ThreeChoiceEnum.Yes) {
            return true;
        } else if (value === ThreeChoiceEnum.No) {
            return false;
        }
        return null;
    }
}
