export class ReminderModel {
    public createdOn: Date;
    public dueDate: Date;
    public details: string;
    public isRead: boolean;
    public reminderId: string;
    public subject: string;
    public type: string;

    public percent: number;
    public isGreen: boolean;
    public isOrange: boolean;
    public isRed: boolean;
}

export interface IReminderModel {
    createdOn: string;
    dueDate: string;
    details: string;
    isRead: boolean;
    reminderId: string;
    subject: string;
    type: string;
}
