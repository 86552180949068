import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  ViewChild,
  HostListener,
  HostBinding,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import * as _ from 'lodash';
import { CustomDomEvents } from '../../models/index';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';

export const PHONE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneInputComponent),
  multi: true
};


@Component({
  moduleId: module.id,
  selector: 'slx-phone-input',
  templateUrl: 'phone-input.component.html',
  styleUrls: ['phone-input.component.scss'],
  providers: [PHONE_VALUE_ACCESSOR]
})
export class PhoneInputComponent implements ControlValueAccessor, OnChanges {
  @Input() public tabindex: number = 0;
  @Input() public className: string;
  @Input() public placeholder: string = 'Phone';
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }

  public phoneMaskConf: any;
  public innerValue: any = '';
  public inEdit: boolean;
  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  @ViewChild('phoneInput', {static: true}) private inputComponent: ElementRef;

  @HostBinding('class.edited') private hasFocus: boolean = false;
  constructor(elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.hasFocus = false;
    this.phoneMaskConf = {
      mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      guide: true
    };
  }
  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }
  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !myattr;
  }


  public writeValue(value?: any): void {
    this.innerValue = value;
    this.inputComponent.nativeElement.value = value ? value : null;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public valueChanged(value: any): void {
    this.onChangeCallback(value);
  }
}
