import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IPayloadAction } from '../../state-model/models/index';
import { ApplicationActions } from '../actions/index';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class ApplicationEpics {
  private router: Router;

  constructor(router: Router) {
    this.router = router;
  }

  public selectApplication = (action$: Observable<IPayloadAction>): Observable<any> => {
    return action$.filter(({ type }: { type: string }) => type === ApplicationActions.SELECT_APPLICATION)
      .mergeMap((action: IPayloadAction) => {

        return Observable.of(
          {
            type: ApplicationActions.APPLICATION_SELECTED,
            payload: action.payload
          });
      });
  }

}
