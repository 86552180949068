import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/index';
import { MealDeducitonsDialogOptions } from '../../../models/meal-deductions/meal-deductions-dialog-options';
import { MealDeductionConfigurationComponent } from '../meal-deduction-configuration/meal-deduction-configuration.component';
var MealDeductionConfigurationDialogComponent = /** @class */ (function () {
    function MealDeductionConfigurationDialogComponent(mealDeductionOptions, dialogOptions, modalService) {
        this.mealDeductionOptions = mealDeductionOptions;
        this.dialogOptions = dialogOptions;
        this.modalService = modalService;
    }
    Object.defineProperty(MealDeductionConfigurationDialogComponent.prototype, "hideDescription", {
        get: function () {
            if (this.mealDeductionOptions) {
                return this.mealDeductionOptions.hideDescription;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    MealDeductionConfigurationDialogComponent.openDialog = function (options, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 700;
        dialogOptions.height = 600;
        dialogOptions.fullHeightOnMobile = true;
        var providers = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: MealDeducitonsDialogOptions,
                useValue: options
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(MealDeductionConfigurationDialogComponent, options.title, dialogOptions, providers, function (result) {
            callback(result);
        });
        return dialog;
    };
    MealDeductionConfigurationDialogComponent.prototype.onClose = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    MealDeductionConfigurationDialogComponent.prototype.closeAfterSave = function (result) {
        this.dialogResult = result;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    MealDeductionConfigurationDialogComponent.prototype.onSave = function () {
        if (!this.mealDeductionOptions.skipSave) {
            this.configEditorComponent.save();
        }
        else {
            this.closeAfterSave(true);
        }
    };
    return MealDeductionConfigurationDialogComponent;
}());
export { MealDeductionConfigurationDialogComponent };
