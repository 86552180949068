import { IndSchOrgLevelEmployee } from './../../models/individual-schedule/ind-sch-org-level-employee';
import { Injectable, Input } from '@angular/core';
import { EmployeeSearchBase } from '../../../employee/employee/services/index';
import { Lookup, LookupType } from '../../../organization/models/index';
import { LookupService } from '../../../organization/services/index';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';

@Injectable()
export class PayCycleEmployeeProvider extends EmployeeSearchBase {
    public m_startDate: Date;
    public m_endDate: Date;
    public m_orgLevelEmployees: IndSchOrgLevelEmployee[];

    private employees$ = new Subject<Lookup>();

    constructor(private lookupService: LookupService) {
        super();
    }

    public set startDate(v: Date) {
        if (_.isDate(v)) {
            this.m_startDate = v;
        }
    }

    public get startDate(): Date {
        return this.m_startDate;
    }

    public set endDate(v: Date) {
        if (_.isDate(v)) {
            this.m_endDate = v;
        }
    }

    public set orgLevelEmployees(orgLevelEmployees: IndSchOrgLevelEmployee[]) {
        this.m_orgLevelEmployees = orgLevelEmployees;
    }	

    public get orgLevelEmployees(): IndSchOrgLevelEmployee[] {
        return  this.m_orgLevelEmployees;
    }
    public get endDate(): Date {
        return this.m_endDate;
    }

    public Load() : void {
        this.runLoadingEmployees();
    }

    private requestsParams = new Map<number, { pendingEmployeesPromise: Promise<Lookup>, resolveEmployeesPromise: (v: any) => void }>();

    public subscribeToEmployees(callback: (v: Lookup) => void): Subscription {
        return this.employees$.subscribe(callback);
    }

    public async getEmployees(orgLevelId: number, isActive: boolean): Promise<Lookup> {
        if (_.isDate(this.startDate) && _.isDate(this.endDate)) {
            this.loadEmployees(orgLevelId, this.startDate, this.endDate);
        }
        return this.saveRequestParams(orgLevelId);
    }

    private async loadEmployees(orgLevelId: number, startDate: Date, endDate: Date): Promise<void> {
        if ( _.size(this.orgLevelEmployees) >0) {
            const lookupvalues = this.createLookup(this.orgLevelEmployees, 'name', 'id');
            this.employees$.next(lookupvalues);
        } else {
            const lookup = await this.lookupService.getLookup({
                lookupType: LookupType.employeesListByActiveDateRange,
                orgLevelId,
                dateRange: { startDate, endDate }
            });
            this.employees$.next(lookup);
        }
    }

    private saveRequestParams(orgLevelId: number): Promise<Lookup> {
        if (this.requestsParams.has(orgLevelId)) {
            const params = this.requestsParams.get(orgLevelId);
            return params.pendingEmployeesPromise;
        }

        const params = { pendingEmployeesPromise: null, resolveEmployeesPromise: null };
        params.pendingEmployeesPromise = new Promise((res) => (params.resolveEmployeesPromise = res));
        this.requestsParams.set(orgLevelId, params);
        return params.pendingEmployeesPromise;
    }

    private createLookup(items: any[], titleField: string, valueField: string): Lookup {
        let lookup: Lookup = new Lookup();
        lookup.titleField = titleField;
        lookup.valueField = valueField;
        lookup.items = items;
        return lookup;
      }

    private runLoadingEmployees(): void {
        if (_.isDate(this.startDate) && _.isDate(this.endDate) && this.requestsParams.size > 0) {
            this.requestsParams.forEach((params, orgLevelId) => {
                const promise = this.loadEmployees(orgLevelId, this.startDate, this.endDate);
                params.resolveEmployeesPromise(promise);
            });
            this.requestsParams.clear();
        }
    }
}
