import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Provider } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import { DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { DropCoverageRequest } from '../../../models/index';
import { BenefitEmployeesApiService } from './../../../services/benefit-employees/index';
import * as moment from 'moment';
import { AppSettingsManageService } from '../../../../../app-settings/services/app-settings-manage.service';
var EmployeeBenefitDropCoverageDialogComponent = /** @class */ (function () {
    function EmployeeBenefitDropCoverageDialogComponent(options, modalService, apiService, dropCoverageRequest, appSettingsManageService) {
        this.appSettingsManageService = appSettingsManageService;
        this.dialogResult = false;
        this.appConfig = appConfig;
        this.maxDedDate = new Date(appConfig.maxCorrectDate);
        this.minDedDate = new Date(appConfig.minCorrectDate);
        this.selectedEmployeesList = [];
        this.datesError = true;
        this.enableBenefitDeduction = false;
        this.oneDayValue = 60 * 60 * 24 * 1000;
        this.title = 'Benefit end date must be within the selected range and Reason should be selected.';
        this.warningMsg = "Payroll Deduction End Date is set after the Benefit Coverage End Date.";
        this.options = options;
        this.modalService = modalService;
        this.apiService = apiService;
        this.selectedEmployees = dropCoverageRequest.selectedEmployees;
        this.planEffectiveStartDate = dropCoverageRequest.startDate;
        this.planEffectiveEndDate = dropCoverageRequest.endDate;
        this.canMapPayroll = dropCoverageRequest.canMapPayroll;
        this.minDedDate = dropCoverageRequest.mappedDeduction.startDate;
        this.maxDedDate = dropCoverageRequest.mappedDeduction.endDate;
        this.loadReasons();
        this.loadDates();
    }
    EmployeeBenefitDropCoverageDialogComponent.openDialog = function (planName, anchor, modalService, apiService, dropCoverageRequest, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.width = 600;
        dialogOptions.height = 350;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.custom;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: DropCoverageRequest,
                useValue: dropCoverageRequest
            },
            {
                provide: BenefitEmployeesApiService,
                useValue: apiService
            }
        ];
        var title = "Drop Benefit Coverage - Enrolled Employees - " + planName;
        return anchor.openDialog2(EmployeeBenefitDropCoverageDialogComponent, title, dialogOptions, resolvedProviders, callback);
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.ngOnInit = function () {
        this.getSettings();
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.setStartEndDates = function (startDate, endDate) {
        this.employeeStartDate = new Date(startDate);
        this.employeeEndDate = new Date(endDate);
        this.eventEmployeeStartDate = new Date(this.planEffectiveStartDate);
        this.eventEmployeeEndDate = new Date(this.planEffectiveEndDate);
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.loadDates = function () {
        var empStartDates = [];
        var empEndDates = [];
        var empDedStartDates = [];
        var empDedEndDates = [];
        _.forEach(this.selectedEmployees, function (employee) {
            if (employee.endDate && employee.startDate) {
                empStartDates.push(employee.startDate);
                empEndDates.push(employee.endDate);
                empDedStartDates.push(employee.payrollDeductionStartDate);
                empDedEndDates.push(employee.payrollDeductionEndDate);
            }
        });
        var startDate = _.max(empStartDates);
        var endDate = _.min(empEndDates);
        var dedStartDate = _.max(empDedStartDates);
        var dedEndDate = _.min(empDedEndDates);
        this.minDedDate = dedStartDate;
        this.maxDedDate = dedEndDate;
        this.eventDisplayDate = startDate;
        this.benefitEndDate = endDate;
        if (_.gt(dedEndDate, this.maxDedDate)) {
            this.payrollDeductionEndDate = this.maxDedDate;
        }
        else {
            this.payrollDeductionEndDate = dedEndDate;
        }
        this.benefitDisplayEndDate = endDate;
        if (this.selectedEmployees[0].dropEventDate == null) {
            this.eventDate = null;
        }
        else {
            this.eventDate = this.selectedEmployees[0].dropEventDate;
        }
        this.setStartEndDates(startDate, endDate);
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.onHasChanges = function (hasChanges) {
        this.dialogResult = hasChanges;
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.onCancel = function () {
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.loadReasons = function () {
        var _this = this;
        this.apiService.getReasonsList()
            .then(function (reasons) {
            _this.reasonList = reasons;
        }).catch(function (reason) {
            _this.reasonList = null;
        });
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.dropBenefits = function () {
        var _this = this;
        this.isLoading = true;
        _.forEach(this.selectedEmployees, function (record) {
            _this.selectedEmployeesList.push(record.id);
        });
        this.apiService.dropEmployeeBenefits(this.selectedEmployeesList, this.benefitEndDate, this.payrollDeductionEndDate, this.eventDate, this.reason)
            .then(function (drop) {
            _this.isLoading = false;
            _this.onCancel();
        }).catch(function (reason) {
            _this.reasonList = null;
            _this.isLoading = false;
        });
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.onChangeBenefitEndDate = function (value) {
        if (value) {
            this.benefitEndDate = value;
        }
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.onChangeDedEndDate = function () {
        this.isDedAfterBeneEndDt = _.gt(this.payrollDeductionEndDate, this.benefitEndDate);
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.onChangeEventdDate = function (value) {
        if (value) {
            this.eventDate = value;
        }
    };
    // this will set dates error to false so save is enabled
    EmployeeBenefitDropCoverageDialogComponent.prototype.onReasonChange = function (value) {
        this.datesError = false;
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.getEndDateToolTip = function () {
        return "The Benefit End Date must be between\n        " + moment(this.eventDisplayDate).format(appConfig.dateFormat) + " and\n        " + moment(this.benefitDisplayEndDate).format(appConfig.dateFormat) + ",\n        which represents the common effective date range for the selected employees.";
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.getEventDateToolTip = function () {
        return "The event date is intended to reflect the date associated with the reason. In situations where a date\n      is not applicable, leave it blank.";
    };
    EmployeeBenefitDropCoverageDialogComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.enableBenefitDeduction = config.isBenefitDeductionEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    return EmployeeBenefitDropCoverageDialogComponent;
}());
export { EmployeeBenefitDropCoverageDialogComponent };
