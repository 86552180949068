import * as _ from 'lodash';

import {
  IOrganization, Organization,
  IPosition, Position,
  Department, IDepartment,
  IEmployeeShortInfo, EmployeeShortInfo
} from '../../../organization/index';

export interface IAccrualPolicyAssignment {
  id: number;
  policyName: string;
  policyIdList: string;
  startDate: string;
  endDate: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
}

export class AccrualPolicyAssignment {
  public id: number;
  public policyName: string;
  public policyIdList: number[];
  public startDate: Date;
  public endDate: Date;
  public lastUpdatedBy: string;
  public lastUpdatedDate: Date;
}

export interface IAccrualType {
  id: number;
  name: string;
}

export class AccrualType {
  public id: number;
  public name: string;
  public color: string;
}

export interface IAccrualTypeBalance extends IAccrualType {
  hourBalance: number;
  dollarBalance: number;
}

export class AccrualTypeBalance extends AccrualType {
  public hourBalance: number;
  public dollarBalance: number;
}

export interface IAccrualBalanceRecord {
  employee: IEmployeeShortInfo;
  department: IDepartment;
  position: IPosition;
  organization: IOrganization;
  accrualPolicy: IAccrualPolicyAssignment;
  accrualTypes: IAccrualTypeBalance[];
}

export class AccrualBalanceRecord {
  public employee: EmployeeShortInfo;
  public department: Department;
  public position: Position;
  public organization: Organization;
  public accrualPolicy: AccrualPolicyAssignment;
  public accrualTypes: AccrualTypeBalance[] = [];
  public accrualTypesMap: StringMap<AccrualTypeBalance>;

  public isNegative(accrualTypeName: string, property: 'hourBalance' | 'dollarBalance'): boolean {
    return _.get(this.accrualTypesMap[accrualTypeName], `${property}`, 0) < 0;
  }
}

export interface IAccrualBalances {
  hideRates: boolean;
  records: IAccrualBalanceRecord[];
}

export class AccrualBalances {
  public hideRates: boolean;
  public records: AccrualBalanceRecord[];
  public accrualTypes: AccrualType[];
  public calculateAccruals: boolean;
}
