/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boolean-report-parameter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/checkbox-toggle/checkbox-toggle.component.ngfactory";
import * as i3 from "../../../../common/components/checkbox-toggle/checkbox-toggle.component";
import * as i4 from "@angular/forms";
import * as i5 from "./boolean-report-parameter.component";
import * as i6 from "../../../services/reports-management.service";
var styles_ReportBooleanParameterComponent = [i0.styles];
var RenderType_ReportBooleanParameterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportBooleanParameterComponent, data: {} });
export { RenderType_ReportBooleanParameterComponent as RenderType_ReportBooleanParameterComponent };
export function View_ReportBooleanParameterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "slx-checkbox-toggle", [["checkedText", "Yes"], ["className", "toggle-left"], ["uncheckedText", "No"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.parameter.value = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.getChecked($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CheckboxToggleComponent_0, i2.RenderType_CheckboxToggleComponent)), i1.ɵdid(2, 49152, null, 0, i3.CheckboxToggleComponent, [i1.ElementRef], { className: [0, "className"], checkedText: [1, "checkedText"], uncheckedText: [2, "uncheckedText"], caption: [3, "caption"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CheckboxToggleComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "toggle-left"; var currVal_8 = "Yes"; var currVal_9 = "No"; var currVal_10 = _co.parameter.displayName; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.parameter.value; _ck(_v, 4, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ReportBooleanParameterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-boolean-report-parameter", [], null, null, null, View_ReportBooleanParameterComponent_0, RenderType_ReportBooleanParameterComponent)), i1.ɵdid(1, 4308992, null, 0, i5.ReportBooleanParameterComponent, [i6.ReportsManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportBooleanParameterComponentNgFactory = i1.ɵccf("slx-boolean-report-parameter", i5.ReportBooleanParameterComponent, View_ReportBooleanParameterComponent_Host_0, { parameter: "parameter" }, {}, []);
export { ReportBooleanParameterComponentNgFactory as ReportBooleanParameterComponentNgFactory };
