import * as tslib_1 from "tslib";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { ChangeManagementService } from "../../../common";
import { ManagementBaseService } from "../../../core/services/management/management-base.service";
import { LookupApiService } from "../../../organization";
import { NotificationGroup } from "../../models";
import { NotificationRole } from "../../models/notifications/notifications-role";
import { NotificationsApiService } from "./notifications-api.service";
var NotificationsManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(NotificationsManagementService, _super);
    function NotificationsManagementService(lookupApiService, apiService, changeService) {
        var _this = _super.call(this) || this;
        _this.changeService = changeService;
        _this.selectRecord$ = new Subject();
        _this.addCmd$ = new Subject();
        _this.loadPage$ = new Subject();
        _this.clearChanges$ = new Subject();
        _this.onGroupSaved$ = new Subject();
        _this.apiService = apiService;
        _this.lookupApiService = lookupApiService;
        return _this;
    }
    NotificationsManagementService.prototype.loadNotificationGroups = function (orgLevelId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getNotificationGroups(orgLevelId)
            .then(function (records) {
            _this.records = records;
            _this.onLoaded(records);
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        });
    };
    NotificationsManagementService.prototype.onRemoveRecord = function (orgLevelId, notificationGroup) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.removeGroup(orgLevelId, notificationGroup.groupId)
            .then(function (res) {
            _this.records = _.filter(_this.records, function (r) { return r.groupId !== notificationGroup.groupId; });
            _this.onLoaded(_this.records);
            _this.onLoadStatusChanged(false);
            _this.clearChanges$.next(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            _this.clearChanges$.next(false);
        });
    };
    NotificationsManagementService.prototype.onAddRecord = function (orgLevelId, notificationGroup) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.addGroup(notificationGroup, orgLevelId)
            .then(function (res) {
            _this.loadNotificationGroups(orgLevelId);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            _this.clearChanges$.next(false);
        });
    };
    NotificationsManagementService.prototype.onSaveRecord = function (orgLevelId, notificationGroup) {
        var _this = this;
        this.onLoadStatusChanged(true);
        // We cannot simply update the row, it needs delete and then add because of event and roles
        this.apiService.removeGroup(orgLevelId, notificationGroup.groupId)
            .then(function (res) {
            _this.onAddRecord(orgLevelId, notificationGroup);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            _this.clearChanges$.next(false);
        });
    };
    NotificationsManagementService.prototype.onEditItem = function (item) {
        this.changeService.changeNotify();
        this.changeNotify$.next();
    };
    NotificationsManagementService.prototype.onCancelEdit = function (item) {
        this.changeService.clearChanges();
        this.clearChanges$.next(false);
    };
    NotificationsManagementService.prototype.addCmd = function () {
        this.changeService.changeNotify();
        this.changeNotify$.next();
        this.addCmd$.next(null);
    };
    return NotificationsManagementService;
}(ManagementBaseService));
export { NotificationsManagementService };
