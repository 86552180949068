export class IPartnerPositionDefinition {
    public id: number;
    public partnerId: string;
    public partnerPositionName: string;
}

export class PartnerPositionDefinition {
    public id: number;
    public partnerId: string;
    public partnerPositionName: string;
  }

