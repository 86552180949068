/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./action-list.component";
var styles_ActionListComponent = [];
var RenderType_ActionListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionListComponent, data: {} });
export { RenderType_ActionListComponent as RenderType_ActionListComponent };
export function View_ActionListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵncd(null, 0), i0.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ActionListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-action-list", [], null, null, null, View_ActionListComponent_0, RenderType_ActionListComponent)), i0.ɵdid(1, 49152, null, 0, i2.ActionListComponent, [], null, null)], null, null); }
var ActionListComponentNgFactory = i0.ɵccf("slx-action-list", i2.ActionListComponent, View_ActionListComponent_Host_0, { customClassName: "className", fullyCustomStyle: "fullyCustomStyle" }, {}, ["*", "*"]);
export { ActionListComponentNgFactory as ActionListComponentNgFactory };
