/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./notifications-grid/notifications-grid.component.ngfactory";
import * as i5 from "./notifications-grid/notifications-grid.component";
import * as i6 from "../../services/notifications/notifications-management.service";
import * as i7 from "../../services/users/user-roles-api.service";
import * as i8 from "../../services/notifications/notifications-api.service";
import * as i9 from "../../../common/services/component-state/component-state-storage.service";
import * as i10 from "../../../core/services/user-settings/user-settings.service";
import * as i11 from "../../../common/services/app-user-settings/app-user-settings.service";
import * as i12 from "./notifications.component";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 10, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "slx-content-toolbar-indents roles-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "flex-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "slx-button slx-blue slx-with-icon"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddDefinition() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-plus-circle slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0Create New Group "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "slx-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "slx-notifications-grid", [], null, null, null, i4.View_NotificationsGridComponent_0, i4.RenderType_NotificationsGridComponent)), i1.ɵdid(12, 245760, null, 0, i5.NotificationsGridComponent, [i6.NotificationsManagementService, i1.ChangeDetectorRef, i7.UserRolesApiService, i8.NotificationsApiService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 12, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isDataLoaded; _ck(_v, 6, 0, currVal_1); }); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵprd(4608, null, i9.ComponentStateStorageService, i9.ComponentStateStorageService, [i10.UserSettingsService, i11.AppUserSettingsService]), i1.ɵdid(2, 245760, null, 0, i12.NotificationsComponent, [i6.NotificationsManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var NotificationsComponentNgFactory = i1.ɵccf("slx-notifications", i12.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
