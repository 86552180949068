import { Directive, ElementRef, Renderer2 } from '@angular/core';
@Directive({
  selector: '[slxLeftToolbarItem]'
})
export class LeftToolbarItemDirective {
  public nativeElement: HTMLElement;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.nativeElement = elementRef.nativeElement;
  }

  public moveContent(oldParent: HTMLElement, newParent: HTMLElement): void {
    this.renderer.removeChild(oldParent, this.nativeElement);
    this.renderer.appendChild(newParent, this.nativeElement);
  }
}
