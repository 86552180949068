import * as _ from 'lodash';
var AcuityConfiguration = /** @class */ (function () {
    function AcuityConfiguration() {
        this.copyToFutureDates = false;
        this.canEditCensus = false;
    }
    return AcuityConfiguration;
}());
export { AcuityConfiguration };
var Acuity = /** @class */ (function () {
    function Acuity() {
        this.m_capacity = 0;
    }
    Acuity.prototype.getField = function (name) {
        var value = _.find(this.entries, function (item) {
            return item.category === name;
        }).value;
        return value;
    };
    Acuity.prototype.setField = function (name, value) {
        _.find(this.entries, function (item) {
            return item.category === name;
        }).value = value;
        this.recalcCapacity();
    };
    Object.defineProperty(Acuity.prototype, "capacity", {
        get: function () {
            return this.m_capacity;
        },
        enumerable: true,
        configurable: true
    });
    Acuity.prototype.recalcCapacity = function () {
        if (!this.entries)
            return;
        this.m_capacity = this.entries.map(function (e) { return e.value; })
            .reduce(function (prev, current) { return prev + current; }, 0);
    };
    return Acuity;
}());
export { Acuity };
