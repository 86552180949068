import { Component, Injectable, OnInit } from '@angular/core';
import { WfmSyncService } from '../../services/wfm/wfm-sync.service';
import { DeviceDetectorService } from '../../../common/services/index';
import { WfmSync, WfmSynDetails, WfnSyncResponse } from '../../models/wfm-sync';
import { OrgLevel } from '../../../state-model/models';
import { LookupApiService, Organization } from '../../../organization';
import { DateRange } from './../../../core/models/index';
import * as moment from 'moment';
import * as _ from 'lodash';
import { NotificationsService } from '../../../../app/core/components';
import { RowArgs } from '@progress/kendo-angular-grid';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import { ActivatedRoute, Router } from '@angular/router';
import { WfmResponseNavigationService } from '../../services/wfm-response-navigation.service';
export type rangeItem = { icon: string, seconds: number, text: string };
@Injectable()
@Component({
  selector: 'slx-wfm-sync',
  templateUrl: './wfm-sync.component.html',
  styleUrls: ['./wfm-sync.component.scss']
})

export class WfmSyncComponent implements OnInit {
  public gridData: WfnSyncResponse[] = [];
  public dataView: WfmSynDetails[] = [];
  public returnResponse : WfmSynDetails[] =[];
  public columns: any[] = [{ field: "totalCount", title: "Total Count" },
  { field: "activeCount", title: "Active Count" }, { field: "currentCount", title: "Current Count" },
  { field: "successCount", title: "Success Count" }, { field: "errorCount", title: "Error Count" },{ field: "addedEmpCount", title: "Added Count" },
  { field: "terminateCount", title: "Terminate Count" },];
  public viewCol: any[] = [{ field: "syncId" }, { field: "errorMessage" }, { field: "status" }, { field: "employeeId" }, { field: "StartDate" },
  { field: "endDate" }, { field: "syncType" },{field:"orgName"}]
  public state: { isLoading: boolean };
  public websiteList: any = ['facility1', 'facility2', 'facility3'];
  public allEmployee: boolean = true;
  public specificFacility: boolean;
  public employeeOnDemand: boolean;
  public facilityid: Organization;
  public order: string = "all";
  public orgs: Organization[];
  public employeeid: string = '';
  public facility: Organization[];
  public org: Organization[];
  public type: string = '';
  public orgLevelId: number = 0;
  public orgName: string = '';
  public employeeIds: any = [];
  public partnerId: string = ''; customerId: string = ''; companyCode: string = '';
  public status: string = 'A';
  public termination: boolean = false;
  public active: boolean = true;
  public previousYear: boolean = false;
  public currentYear: boolean = false;
  public syncNow : boolean =false;
  public error: string = '';
  public dateError: boolean = false;
  public attributes: WfmSync[] = [];
  private selectedLink: string = "AllEmployees";
  public orders = [];
  public orgLevel: OrgLevel;
  public effectiveDate: Date = null;
  public startDate: string = '';
  public selectedRange: rangeItem;
  private onChangeCallback: (val: any) => void = _.noop;
  public endDate: string = '';
  public checkStartDate: Date;
  public startDateVal: string;
  public endtDateVal: string;
  public dateNull: boolean = false;
  public strDate: Date;
  public enDate: Date;
  public postEmployeeData: WfmSync = new WfmSync();
  public year: number = new Date().getFullYear();
  public refreshdisable :boolean = true;
  constructor(
    private wfmsyncService: WfmSyncService,
    private lookup: LookupApiService,
    private devDetector: DeviceDetectorService,
    public notificationService: NotificationsService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.state = {
      isLoading: false,
    };
    let orgLevelId = 1;
    this.lookup.getOrganizations(undefined, orgLevelId).then((orgs: Organization[]) => {
      this.facility = orgs;
    });
    this.response();
  }


  public onChangeStartDate(startDate: Date): void {
    if (startDate != null) {
      this.checkStartDate = startDate;
      this.startDate = startDate ? dateTimeUtils.convertToDtoString(startDate) : '';
    }
    else
      this.startDate = '';
  }

  public onChangeEndDate(endDate: Date): void {
    if (endDate != null) {
      if (moment(endDate).isBefore(moment(this.checkStartDate))) {
        this.endDate = '';
        this.dateError = true;
      }
      else {
        this.endDate = endDate ? dateTimeUtils.convertToDtoString(endDate) : '';
        this.dateError = false;
      }
    }
    else {
      this.endDate = '';
    }
  }
  public validation() {
    if (this.order.toLowerCase() == 'organization') {
      if (this.orgLevelId == null || this.orgLevelId == 0) {
        return 'Select Facility';
      }
    }
    else if (this.order.toLowerCase() == 'ondemand') {
      if (this.employeeid == null || this.employeeid == '') {
        return 'Please enter employeeId"s';
      }
      else if (this.employeeIds.count <= 10)
        return 'Have to enter only 10 EmployeeIDs';
    }
    else if (this.startDate != null && this.startDate != '') {
      if (this.endDate == null || this.endDate == "") {
        this.dateNull = true;
        return 'Both Dates are to be there or Both to be null';
      }
      else {
        this.dateNull = false;
        return '';
      }
    }
    else if (this.endDate != null && this.endDate != '') {
      if (this.startDate == null || this.startDate == "") {
        this.dateNull = true;
        return 'Both Dates are to be there or Both to be null';
      }
      else {
        this.dateNull = false;
        return '';
      }
    }
    else {
      return null;
    }
  }

  public msg: string;

  public allowNumericDigitsOnlyOnKeyUp(e) {
    const charCode = e.which ? e.which : e.keyCode;

    if (charCode != 44) {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
        this.msg = 'Only numeric values or digits allowed';
      }
      else {
        this.msg = "";
      }
    }
    else {
      this.msg = "";
    }
  }
public sycId : string;
  public employeesyncabc(e: any) {
    this.msg='';
    
    this.type = this.order;
    this.orgName = '';
    this.startDateVal = this.startDate;
    this.endtDateVal = this.endDate;
    this.partnerId = '';
    this.customerId = '';
    this.companyCode = '';
    this.type = this.order;
    if (this.termination == true) {
      this.status = 'T';
    }
    if (this.facilityid != null) {
      this.orgLevelId = this.facilityid.orgLevelId;
    }
    if (this.employeeid != null && this.employeeid != '') {
      this.employeeIds = this.employeeid.split(',');
    }
    else {
      this.employeeIds = [];
    }
    this.error = this.validation();
    if (this.error == null && !this.dateError && !this.dateNull) {
      this.wfmsyncService.employeeSync(this.policyData()).then((value: any) => {
       this.syncNow =true;
       this.specificFacility = false;
        this.allEmployee = false;
        this.employeeOnDemand = false;
        this.refreshdisable = false;
        this.sycId =value.data.syncId;
        this.notificationService.success('Successful');
        this.response();
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
    }
  }
  public policyData(): any {
    this.postEmployeeData.type = this.type;
    this.postEmployeeData.companyCode = this.companyCode;
    this.postEmployeeData.customerId = this.customerId;
    if (this.previousYear == true && this.currentYear == true) {
      this.strDate = new Date((this.year - 1), 0, 1);
      this.enDate = new Date(this.year, 11, 31);
      this.postEmployeeData.startDate = this.strDate ? dateTimeUtils.convertToDtoString(this.strDate) : '';
      this.postEmployeeData.endDate = this.enDate ? dateTimeUtils.convertToDtoString(this.enDate) : '';
    }
    else if (this.previousYear == false && this.currentYear == true) {
      this.strDate = new Date(this.year, 0, 1);
      this.enDate = new Date(this.year, 11, 31);
      this.postEmployeeData.startDate = this.strDate ? dateTimeUtils.convertToDtoString(this.strDate) : '';
      this.postEmployeeData.endDate = this.enDate ? dateTimeUtils.convertToDtoString(this.enDate) : '';
    }
    else if (this.previousYear == true && this.currentYear == false) {
      this.strDate = new Date((this.year - 1), 0, 1);
      this.enDate = new Date((this.year - 1), 11, 31);
      this.postEmployeeData.startDate = this.strDate ? dateTimeUtils.convertToDtoString(this.strDate) : '';
      this.postEmployeeData.endDate = this.enDate ? dateTimeUtils.convertToDtoString(this.enDate) : '';
    }
    else if (this.previousYear == false && this.currentYear == false) {
      this.strDate = null;
      this.enDate = null;
      this.postEmployeeData.startDate = this.strDate ? dateTimeUtils.convertToDtoString(this.strDate) : '';
      this.postEmployeeData.endDate = this.enDate ? dateTimeUtils.convertToDtoString(this.enDate) : '';
    }
    this.postEmployeeData.orgLevelId = this.orgLevelId;
    this.postEmployeeData.orgName = this.orgName;
    this.postEmployeeData.employeeIds = this.employeeIds;
    this.postEmployeeData.partnerId = this.partnerId;
    this.postEmployeeData.status = this.status;
    return this.postEmployeeData;
  }

  public onDisplaySelectChanged(type, value): void {
    let attrValues: WfmSync = new WfmSync();
    attrValues.type = value;
  }

  public setradio(e: string): void {
    this.selectedLink = e;
  }

  public isSelected(name: string): boolean {
    return !this.selectedLink ? false : (this.selectedLink === name);
  }

  public getOrders() {
    return [
      { id: 1, name: 'All Employees' },
      { id: 2, name: 'Specific Facility' },
      { id: 3, name: 'Employee On Demand' },
    ];
  }
  public onInputInActiveCheckChange(e: any) {
    if (this.termination == true) {
      this.currentYear = true;
      this.active = false;
    }
    if (this.termination == false) {
      this.currentYear = false;
      this.previousYear = false;
      this.active = true;
    }
  }
  public onInputActiveCheckChange(e: any) {
    if (this.active == true) {
      this.termination = false;
      this.previousYear = false;
      this.currentYear = false;
    }
    if (this.active == false) {
      this.termination = true;
      this.previousYear = false;
      this.currentYear = true;
    }
  }
  public onInputChange(e: any) {
    switch (this.order) {
      case "all":
        this.allEmployee = true;
        this.specificFacility = false;
        this.employeeOnDemand = false;
        this.syncNow =false;
        break;
      case "organization":
        this.specificFacility = true;
        this.allEmployee = false;
        this.employeeOnDemand = false;
        this.syncNow =false;
        break;
      case "onDemand":
        this.employeeOnDemand = true;
        this.allEmployee = false;
        this.specificFacility = false;
        this.syncNow =false;
        break;
    }
  }

  public response() {
    this.wfmsyncService.employeeSyncResponse().then((value1: any) => {
      this.gridData = value1.data;
    });
  }
  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }
  public employeeViewDetails(e: any) {
    let syncId: string = e.syncId;
    let navService: WfmResponseNavigationService = new WfmResponseNavigationService(this.router, this.route);
    navService.NavigateToWfmResponse(syncId);
  }

  public rowSelectionKey(context: RowArgs): string {
    return context.dataItem;
  }
 public refreshync(e:any)
 {
  window.location.reload();
 }
}

