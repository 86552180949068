import { InjectionToken } from '@angular/core';
var BenefitsEmployeeDependentsEnrollmentValidity = /** @class */ (function () {
    function BenefitsEmployeeDependentsEnrollmentValidity(startDate, endDate, maxDate, minDate) {
        if (startDate === void 0) { startDate = null; }
        if (endDate === void 0) { endDate = null; }
        if (maxDate === void 0) { maxDate = null; }
        if (minDate === void 0) { minDate = null; }
        this.startDate = startDate;
        this.endDate = endDate;
        this.maxDate = maxDate;
        this.minDate = minDate;
        this.latestDepDOB = null;
        this.error = new Set();
    }
    Object.defineProperty(BenefitsEmployeeDependentsEnrollmentValidity.prototype, "hasError", {
        get: function () {
            return this.error.size > 0;
        },
        enumerable: true,
        configurable: true
    });
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.setErrorInvalidBenefits = function (isAdd) {
        if (isAdd) {
            this.error.add('invalidBenefits');
        }
        else {
            this.error.delete('invalidBenefits');
        }
    };
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.isErrorInvalidBenefits = function () {
        return this.error.has('invalidBenefits');
    };
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.setErrorInvalidDependents = function (isAdd) {
        if (isAdd) {
            this.error.add('invalidDependents');
        }
        else {
            this.error.delete('invalidDependents');
        }
    };
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.isErrorInvalidDependents = function () {
        return this.error.has('invalidDependents');
    };
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.setErrorNoBenefits = function (isAdd) {
        if (isAdd) {
            this.error.add('noBenefits');
        }
        else {
            this.error.delete('noBenefits');
        }
    };
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.isErrorNoBenefits = function () {
        return this.error.has('noBenefits');
    };
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.setErrorNoDependents = function (isAdd) {
        if (isAdd) {
            this.error.add('noDependents');
        }
        else {
            this.error.delete('noDependents');
        }
    };
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.isErrorNoDependents = function () {
        return this.error.has('noDependents');
    };
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.setErrorInvalidStartOrEndDate = function (isAdd) {
        if (isAdd) {
            this.error.add('invalidStartOrEndDate');
        }
        else {
            this.error.delete('invalidStartOrEndDate');
        }
    };
    BenefitsEmployeeDependentsEnrollmentValidity.prototype.isErrorInvalidStartOrEndDate = function () {
        return this.error.has('invalidStartOrEndDate');
    };
    return BenefitsEmployeeDependentsEnrollmentValidity;
}());
export { BenefitsEmployeeDependentsEnrollmentValidity };
export var BENEFITS_EMPLOYEE_DEPENDENTS_ENROLLMENT = new InjectionToken('BENEFITS_EMPLOYEE_DEPENDENTS_ENROLLMENT');
