import { BenefitEnrolledEmployee, BenefitPayrollDeduction } from '../../models/index';

export interface IDropEmployeeBenefits {
    employee2BenefitIds: number[];
    benefitEndDate: Date;
    eventDate: Date;
    reason: IBenefitTerminationReason;
  }

export class DropEmployeeBenefits {
    public employee2BenefitIds: number[];
    public benefitEndDate: Date;
    public payrollDeductionEndDate: Date;
    public eventDate: Date;
    public reason: BenefitTerminationReason;
  }

export interface IBenefitTerminationReason  {
    id: string;
    name: string;
  }

export class BenefitTerminationReason  {
    constructor(
      public id: string,
      public name: string
    ) {}
}

export class DropCoverageRequest {
  public startDate: Date;
  public endDate: Date;
  public payrollDeductionEndDate: Date;
  public canMapPayroll: boolean;
  public mappedDeduction: BenefitPayrollDeduction;

  public selectedEmployees: BenefitEnrolledEmployee[];
  constructor(sDate, eDate, payrollDeductionEndDate, canMapPayroll, mappedDeduction, sEmployees) {
    this.startDate = sDate;
    this.endDate = eDate;
    this.payrollDeductionEndDate = payrollDeductionEndDate;
    this.canMapPayroll = canMapPayroll;
    this.mappedDeduction = mappedDeduction;
    this.selectedEmployees = sEmployees;
  }
}
