import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { IndividualTimecardsDay, IndividualTimecardsState } from '../../../models/index';
var IndividualTimecardsDetailGridComponent = /** @class */ (function () {
    function IndividualTimecardsDetailGridComponent() {
        this.gridState = new KendoGridStateHelper();
    }
    IndividualTimecardsDetailGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    IndividualTimecardsDetailGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualTimecardsDetailGridComponent.prototype.ngOnChanges = function (change) {
        var _this = this;
        if (change['day']) {
            this.records = _.reduce(this.day.earnings, function (result, earning) {
                var first = true;
                var rules = _.map(earning.rules, function (rule) {
                    var record = { earning: earning, payRule: rule, first: first };
                    _this.empEarning = earning;
                    first = false;
                    return record;
                });
                return (result || []).concat(rules);
            }, []);
            this.refreshGrid();
        }
    };
    IndividualTimecardsDetailGridComponent.prototype.getUnit = function (field, value) {
        if (!value) {
            return null;
        }
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].unit : null;
    };
    IndividualTimecardsDetailGridComponent.prototype.isVisible = function (field, payCode) {
        if (!this.state) {
            return false;
        }
        var columnState = payCode ? this.state.payCodeColumns : this.state.empColumns;
        if (!columnState || !columnState.columnsMap[field]) {
            return false;
        }
        var column = columnState.columnsMap[field];
        if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
            return false;
        }
        return column.displayed;
    };
    IndividualTimecardsDetailGridComponent.prototype.getWidth = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 50;
    };
    IndividualTimecardsDetailGridComponent.prototype.getFilter = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
    };
    IndividualTimecardsDetailGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsDetailGridComponent.prototype, "gridRefreshSubscription", void 0);
    return IndividualTimecardsDetailGridComponent;
}());
export { IndividualTimecardsDetailGridComponent };
