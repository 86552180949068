import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TreeNodeModel } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-tree-node-children-component',
  encapsulation: ViewEncapsulation.Emulated,
  templateUrl: 'tree-node-children.component.html',
  styleUrls: ['tree-node-children.component.scss']
})
export class TreeNodeChildrenComponent {
  @Input() public node: TreeNodeModel;
  @Input() public templates: any;
}
