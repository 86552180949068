<kendo-grid #kendoGrid class="SummaryGrid" [data]="gridState?.view" (excelExport)="onExcelExport($event)">
    <kendo-grid-column title="Pay Description" field="payDescription">
        <ng-template kendoGridFooterTemplate ><strong>Total</strong></ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Pay Code" field="payCode"></kendo-grid-column>
    <kendo-grid-column-group title="Current Cycle">
        <kendo-grid-column title="Hours" field="currentCycleHours">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="right-align">{{dataItem.currentCycleHours | number:'.2'}}</span>
            </ng-template>
            <ng-template kendoGridFooterTemplate >
                <span class="right-align">
                    <strong>{{ totals['currentCycleHours']?.sum | number: ".2-2" }}</strong>
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Hourly Pay" field="currentCycleHourlyPay">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="right-align dollar-sign">{{dataItem.currentCycleHourlyPay | number:'.2'}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align dollar-sign">
                  <strong>{{ totals['currentCycleHourlyPay']?.sum | number: ".2-2" }}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Other Pay" field="currentCycleOtherPay">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align dollar-sign">{{dataItem.currentCycleOtherPay | number:'.2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align dollar-sign">
                <strong>{{ totals['currentCycleOtherPay']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>
        <kendo-grid-column title="All Paid" field="currentCycleAmount">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="right-align dollar-sign">{{dataItem.currentCycleAmount | number:'.2'}}</span>
            </ng-template>

            <ng-template kendoGridFooterTemplate >
                <span class="right-align dollar-sign">
                    <strong>{{ totals['currentCycleAmount']?.sum | number: ".2-2" }}</strong>
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column-group title="Previous Cycle" [locked]="false">
        <kendo-grid-column title="Hours" field="previousCycleHours">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="right-align">{{dataItem.previousCycleHours | number:'.2'}}</span>
            </ng-template>
            <ng-template kendoGridFooterTemplate >
                <span class="right-align">
                    <strong>{{ totals['previousCycleHours']?.sum | number: ".2-2" }}</strong>
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Hourly Pay" field="previousCycleHourlyPay">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="right-align dollar-sign">{{dataItem.previousCycleHourlyPay | number:'.2'}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align dollar-sign">
                  <strong>{{ totals['previousCycleHourlyPay']?.sum | number: ".2-2" }}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Other Pay" field="previousCycleOtherPay">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align dollar-sign">{{dataItem.previousCycleOtherPay | number:'.2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align dollar-sign">
                <strong>{{ totals['previousCycleOtherPay']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>

        <kendo-grid-column title="All Paid" field="previousCycleAmount">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="right-align dollar-sign">{{dataItem.previousCycleAmount | number:'.2'}}</span>
            </ng-template>
            <ng-template kendoGridFooterTemplate >
                <span class="right-align dollar-sign">
                    <strong>{{ totals['previousCycleAmount']?.sum | number: ".2-2" }}</strong>
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>
    <kendo-grid-column-group title="Difference" [locked]="false">
        <kendo-grid-column title="Hours" field="differenceCycleHours">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span [ngClass]="checkDifference(dataItem, management.diffPercent, 'hours') ? 'highlight' : ''" class="right-align">{{dataItem.differenceCycleHours | number:'.2'}}</span>
            </ng-template>
            <ng-template kendoGridFooterTemplate >
                <span class="right-align">
                    <strong>{{ totals['differenceCycleHours']?.sum | number: ".2-2" }}</strong>
                </span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Hourly Pay" field="differenceCycleHourlyPay">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span [ngClass]="checkDifference(dataItem, management.diffPercent, 'hourlyamounts') ? 'highlight' : ''" class="right-align dollar-sign">{{dataItem.differenceCycleHourlyPay | number:'.2'}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align dollar-sign">
                  <strong>{{ totals['differenceCycleHourlyPay']?.sum | number: ".2-2" }}</strong>
              </span>
          </ng-template>
        </kendo-grid-column>

      <kendo-grid-column title="Other Pay" field="differenceCycleOtherPay">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span [ngClass]="checkDifference(dataItem, management.diffPercent, 'otherpayamounts') ? 'highlight' : ''" class="right-align dollar-sign">{{dataItem.differenceCycleOtherPay | number:'.2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align dollar-sign">
                <strong>{{ totals['differenceCycleOtherPay']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>

        <kendo-grid-column title="All Paid" field="differenceCycleAmount">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span [ngClass]="checkDifference(dataItem, management.diffPercent, 'amount') ? 'highlight' : ''" class="right-align dollar-sign">{{dataItem.differenceCycleAmount | number:'.2'}}</span>
            </ng-template>
            <ng-template kendoGridFooterTemplate >
                <span class="right-align dollar-sign">
                    <strong>{{ totals['differenceCycleAmount']?.sum | number: ".2-2" }}</strong>
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid-column-group>

    <kendo-grid-excel fileName="paid-hours.xlsx">
      <kendo-excelexport-column title="Pay Description" field="payDescription">
        <ng-template kendoExcelExportFooterTemplate>Total</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Pay Code"
        field="payCode"
        [footerCellOptions]="{ textAlign: 'right' }"
      ></kendo-excelexport-column>
      <kendo-excelexport-column-group title="Current Cycle">
        <kendo-excelexport-column
          title="Hours"
          field="currentCycleHours"
          [cellOptions]="{ format: numberFormat }"
          [footerCellOptions]="{ textAlign: 'right', format: numberFormat }"
        >
          <ng-template kendoExcelExportFooterTemplate>{{ totals['currentCycleHours']?.sum }}</ng-template>
        </kendo-excelexport-column>
        <kendo-excelexport-column
          title="Hourly Pay"
          field="currentCycleHourlyPay"
          [cellOptions]="{ format: numberFormat }"
          [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
        >
          <ng-template kendoExcelExportFooterTemplate>{{ totals['currentCycleHourlyPay']?.sum }}</ng-template>
        </kendo-excelexport-column>
         <kendo-excelexport-column
          title="Other Pay"
          field="currentCycleOtherPay"
          [cellOptions]="{ format: numberFormat }"
          [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
        >
          <ng-template kendoExcelExportFooterTemplate>{{ totals['currentCycleOtherPay']?.sum }}</ng-template>
        </kendo-excelexport-column>
        <kendo-excelexport-column
          title="All Paid"
          field="currentCycleAmount"
          [cellOptions]="{ format: dollarFormat }"
          [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
        >
          <ng-template kendoExcelExportFooterTemplate>{{ totals['currentCycleAmount']?.sum }}</ng-template>
        </kendo-excelexport-column>
      </kendo-excelexport-column-group>
      <kendo-excelexport-column-group title="Previous Cycle">
        <kendo-excelexport-column
          title="Hours"
          field="previousCycleHours"
          [cellOptions]="{ format: numberFormat }"
          [footerCellOptions]="{ textAlign: 'right', format: numberFormat }"
        >
          <ng-template kendoExcelExportFooterTemplate>{{ totals['previousCycleHours']?.sum }}</ng-template>
        </kendo-excelexport-column>
        <kendo-excelexport-column
        title="Hourly Pay"
        field="previousCycleHourlyPay"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['previousCycleHourlyPay']?.sum }}</ng-template>
      </kendo-excelexport-column>
       <kendo-excelexport-column
        title="Other Pay"
        field="previousCycleOtherPay"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['previousCycleOtherPay']?.sum }}</ng-template>
      </kendo-excelexport-column>
        <kendo-excelexport-column
          title="All Paid"
          field="previousCycleAmount"
          [cellOptions]="{ format: dollarFormat }"
          [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
        >
          <ng-template kendoExcelExportFooterTemplate>{{ totals['previousCycleAmount']?.sum }}</ng-template>
        </kendo-excelexport-column>
      </kendo-excelexport-column-group>
      <kendo-excelexport-column-group title="Difference">
        <kendo-excelexport-column
          title="Hours"
          field="differenceCycleHours"
          [cellOptions]="{ format: numberFormat }"
          [footerCellOptions]="{ textAlign: 'right', format: numberFormat }"
        >
          <ng-template kendoExcelExportFooterTemplate>{{ totals['differenceCycleHours']?.sum }}</ng-template>
        </kendo-excelexport-column>
        <kendo-excelexport-column
        title="Hourly Pay"
        field="differenceCycleHourlyPay"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['differenceCycleHourlyPay']?.sum }}</ng-template>
      </kendo-excelexport-column>
       <kendo-excelexport-column
        title="Other Pay"
        field="differenceCycleOtherPay"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['differenceCycleOtherPay']?.sum }}</ng-template>
      </kendo-excelexport-column>
        <kendo-excelexport-column
          title="All paid"
          field="differenceCycleAmount"
          [cellOptions]="{ format: dollarFormat }"
          [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
        >
          <ng-template kendoExcelExportFooterTemplate>{{ totals['differenceCycleAmount']?.sum }}</ng-template>
        </kendo-excelexport-column>
      </kendo-excelexport-column-group>
    </kendo-grid-excel>

    <kendo-grid-pdf fileName="paid-hours.pdf" [allPages]="true" paperSize="A4"  [landscape]="true" [repeatHeaders]="true" [scale]="0.7">
      <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
      <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <div class="page-template">
           <div class="header">
            <div class="title">Summary of Paid Hours - {{ payrollCycle | slxPeriod }}; {{ orgLevelName }}; {{ todayDateTime }}</div>
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
       </ng-template>
    </kendo-grid-pdf>
</kendo-grid>
