import { Component, Input, Output, EventEmitter } from '@angular/core';

import { MasterScheduleColumnsSettings } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-master-schedule-columns-settings',
  templateUrl: 'master-schedule-columns-settings.component.html',
  styleUrls: ['master-schedule-columns-settings.component.scss']
})
export class MasterScheduleColumnsSettingsComponent {
  @Input()
  public columns: MasterScheduleColumnsSettings;
}
