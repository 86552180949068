import * as tslib_1 from "tslib";
import { IMessageList, ILatestScheduleCycle, ISectionMessagesRequest, IMessageCountResponse, IMessageCountRequest, MessageCountResponse } from '../models/message-list';
import * as _ from 'lodash';
import { MessagemapService } from '../messagemap.service';
import { UserPicture, IUserPicture } from '../models';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { ApiService } from '../../../core/services/api/api.service';
import { HttpRequest } from '@angular/common/http';
import { SessionService } from '../../../../app/core/services';
import { appConfig } from '../../../app.config';
import { configurationConfig } from '../../../configuration/configuration.config';
var MessageApiService = /** @class */ (function () {
    function MessageApiService(apiService, apiUtilService, urlParamsService, messagemapService, sessionService) {
        this.apiService = apiService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.messagemapService = messagemapService;
        this.sessionService = sessionService;
    }
    MessageApiService.prototype.getMessages = function (searchText, dateOrderByAscending, nameOrderByAscending, pageno, orgLevelId, isArchived, includeCold, pageSize, isMyMessage) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, obj, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getMessageByUserIdUrl() + "?" + (configurationConfig.api.configuration.messageCenter.apiVersion + "=") + this.getCommunicationApiRootVersion();
                obj = {
                    page: pageno,
                    pageSize: pageSize,
                    employees: [],
                    isArchived: isArchived,
                    searchText: searchText,
                    orgLevelId: orgLevelId,
                    dateOrderByAscending: dateOrderByAscending,
                    nameOrderByAscending: nameOrderByAscending,
                    includeCold: includeCold,
                    isMyMessage: isMyMessage
                };
                request = new HttpRequest('POST', url, obj);
                promise = this.apiUtilService.request(request)
                    .then(function (response) {
                    return _this.messagemapService.mapToMessasgeListRecords(response['results']);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    MessageApiService.prototype.getTabMessages = function (iSectionMessagesRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getTabMessageByUserId() + "?" + (configurationConfig.api.configuration.messageCenter.apiVersion + "=") + this.getCommunicationApiRootVersion();
                request = new HttpRequest('POST', url, iSectionMessagesRequest);
                promise = this.apiUtilService.request(request)
                    .then(function (response) {
                    return _this.messagemapService.mapToMessasgeListRecords(response['results']);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    MessageApiService.prototype.getTabsMessagesCount = function (messageCountRequest, term) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!_.isUndefined(term)) {
                    messageCountRequest.searchText = term;
                }
                url = this.getMessageCountApiUrl() + "?" + (configurationConfig.api.configuration.messageCenter.apiVersion + "=") + this.getCommunicationApiRootVersion();
                request = new HttpRequest('POST', url, messageCountRequest);
                promise = this.apiUtilService.request(request)
                    .then(function (response) {
                    return _this.messagemapService.mapToTabCountRecords(response);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    MessageApiService.prototype.updateArchiveMessages = function (obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.archive.configuration + "?" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + this.getCommunicationApiRootVersion();
                request = this.urlParamsService.createPutRequest(url, obj, null, null);
                return [2 /*return*/, this.apiUtilService.request(request)];
            });
        });
    };
    MessageApiService.prototype.updateUnarchiveMessages = function (obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.unArchive.configuration + "?" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + this.getCommunicationApiRootVersion();
                request = this.urlParamsService.createPutRequest(url, obj, null, null);
                return [2 /*return*/, this.apiUtilService.request(request)];
            });
        });
    };
    MessageApiService.prototype.updateReadMessages = function (obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.read.configuration + "?" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + this.getCommunicationApiRootVersion();
                request = this.urlParamsService.createPutRequest(url, obj, null, null);
                return [2 /*return*/, this.apiUtilService.request(request)];
            });
        });
    };
    MessageApiService.prototype.updateUnreadMessages = function (obj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.unRead.configuration + "?" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + this.getCommunicationApiRootVersion();
                ;
                request = this.urlParamsService.createPutRequest(url, obj, null, null);
                return [2 /*return*/, this.apiUtilService.request(request)];
            });
        });
    };
    MessageApiService.prototype.getMessageByUserIdUrl = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.paged;
    };
    MessageApiService.prototype.getArchiveMessagesUrl = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.archived;
    };
    MessageApiService.prototype.getCommunicationApiRoot = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.root;
    };
    MessageApiService.prototype.getCommunicationApiRootVersion = function () {
        return "" + this.apiUtilService.getCommunicationApiRootVersion();
    };
    MessageApiService.prototype.getMessageThread = function (employeeId, orgLevelId, isColdData, isMyMessage) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getMessageThreadUrl() + "?" + configurationConfig.api.configuration.messageCenter.employeeId + "=" + employeeId + "&isColdData=" + isColdData + "&isMyMessage=" + isMyMessage + ("&" + configurationConfig.api.configuration.messageCenter.orgLevelId + "=") + orgLevelId + ("&" + configurationConfig.api.configuration.messageCenter.apiVersion + "=") + this.getCommunicationApiRootVersion();
                        request = new HttpRequest('GET', url);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.messagemapService.mapToMessageThread(response)];
                }
            });
        });
    };
    MessageApiService.prototype.getColdDataExists = function (employeeId, orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var getColdStorageFlagUrl, url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                getColdStorageFlagUrl = this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.cold + "/" + configurationConfig.api.configuration.messageCenter.exists;
                url = getColdStorageFlagUrl + "?" + configurationConfig.api.configuration.messageCenter.employeeId + "=" + employeeId + "&" + configurationConfig.api.configuration.messageCenter.orgLevelId + "=" + orgLevelId + "&" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + this.getCommunicationApiRootVersion();
                request = new HttpRequest('GET', url);
                return [2 /*return*/, this.apiUtilService.request(request).then(function (res) { return _this.messagemapService.mapToColdStorageFlag(res); })];
            });
        });
    };
    MessageApiService.prototype.getMessageThreadUrl = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.thread;
    };
    MessageApiService.prototype.getMessageStatusUpdateMultiple = function (array) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var obj, url, request;
            return tslib_1.__generator(this, function (_a) {
                obj = {
                    "employees": [array],
                    "excludeEmployees": [],
                    "all": false,
                    "orgLevelId": null,
                    "isArchived": null,
                    "allUnRead": false,
                    "includeCold": true
                };
                url = "" + this.getMessageUpdateByUserIdUrl() + ("?" + configurationConfig.api.configuration.messageCenter.apiVersion + "=") + this.getCommunicationApiRootVersion();
                request = new HttpRequest('PUT', url, obj);
                return [2 /*return*/, this.apiUtilService.request(request).then(function (response) { return response; })];
            });
        });
    };
    MessageApiService.prototype.getMessageUpdateByUserIdUrl = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.read.configuration;
    };
    MessageApiService.prototype.getEmployeeSpecificData = function (empID) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getEmployeeSpecificDataUrl() + "/" + empID + ("/" + configurationConfig.api.configuration.messageCenter.getEmployeeStatus);
                request = new HttpRequest('GET', url);
                return [2 /*return*/, this.apiUtilService.request(request).then(function (response) { return response; })];
            });
        });
    };
    MessageApiService.prototype.getEmployeeSpecificDataUrl = function () {
        return appConfig.api.url + "/" + configurationConfig.api.configuration.messageCenter.v1 + "/" + configurationConfig.api.configuration.messageCenter.messagecenter;
    };
    MessageApiService.prototype.getRecentMessageByEmployeeId = function (employeeId, isMyMessage) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.recentMessage + "?" + configurationConfig.api.configuration.messageCenter.employeeId + "=" + employeeId + "&isMyMessage=" + isMyMessage + ("&" + configurationConfig.api.configuration.messageCenter.apiVersion + "=") + this.getCommunicationApiRootVersion();
                request = new HttpRequest('GET', url);
                promise = this.apiUtilService.request(request)
                    .then(function (res) {
                    return _this.messagemapService.mapToRecentMessage(res);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    MessageApiService.prototype.getComposeNewTabsPermissions = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messagecenter + "/" + configurationConfig.api.configuration.messageCenter.getComposeNewTabsPermissions + "/" + orgLevelId;
                request = new HttpRequest('GET', url);
                promise = this.apiUtilService.request(request).
                    then(function (res) { return _this.messagemapService.mapToComposeNewTabsPermissions(res); });
                return [2 /*return*/, promise];
            });
        });
    };
    MessageApiService.prototype.getQuery = function (query, searchText, dateOrderByAscending, nameOrderByAscending, includeCold) {
        if (searchText !== '' && searchText !== null) {
            query = query + ("&" + configurationConfig.api.configuration.messageCenter.searchText + "=") + searchText;
        }
        if (dateOrderByAscending !== null) {
            query = query + ("&" + configurationConfig.api.configuration.messageCenter.dateOrderByAscending + "=") + dateOrderByAscending;
        }
        if (nameOrderByAscending !== null) {
            query = query + ("&" + configurationConfig.api.configuration.messageCenter.nameOrderByAscending + "=") + nameOrderByAscending;
        }
        if (includeCold) {
            query = query + ("&" + configurationConfig.api.configuration.messageCenter.includeCold + "=") + includeCold;
        }
        return query;
    };
    MessageApiService.prototype.getEmployeePicsByUserIds = function (userIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getEmployeePicsByUsersUrl();
                request = new HttpRequest('POST', url, userIds);
                return [2 /*return*/, this.apiUtilService.request(request).then(function (response) { return _this.messagemapService.mapToUserPictures(response.data); })];
            });
        });
    };
    MessageApiService.prototype.getLatestScheduleCycleByEmployeeId = function (empId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getLatestScheduleCycleUrl(empId);
                request = new HttpRequest('GET', url);
                return [2 /*return*/, this.apiUtilService.request(request).then(function (response) { return _this.messagemapService.mapToLatestScheduleCycle(response.data); })];
            });
        });
    };
    MessageApiService.prototype.getLatestScheduleCycleUrl = function (empId) {
        return this.getApiRoot() + "/" + (configurationConfig.api.configuration.messageCenter.messagecenter + '/' + configurationConfig.api.configuration.messageCenter.getLatestScheduleCycle) + "?" + configurationConfig.api.configuration.messageCenter.employeeId + "=" + empId;
    };
    MessageApiService.prototype.getEmployeePicsByUsersUrl = function () {
        return "" + this.getApiRoot() + ("/" + configurationConfig.api.configuration.messageCenter.user + "/" + configurationConfig.api.configuration.messageCenter.thubmnails);
    };
    MessageApiService.prototype.getTabMessageByUserId = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.pageData;
    };
    MessageApiService.prototype.getMessageCountApiUrl = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.messageCount;
    };
    MessageApiService.prototype.getApiRoot = function () {
        return "" + this.apiUtilService.getApiRoot();
    };
    MessageApiService.prototype.getColdStorageExist = function (orgLevelId, isArchived) {
        var _this = this;
        var getColdStorageFlagUrl = this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.cold + "/" + configurationConfig.api.configuration.messageCenter.exists;
        var url = getColdStorageFlagUrl + "?" + configurationConfig.api.configuration.messageCenter.isArchived + "=" + isArchived + "&" + configurationConfig.api.configuration.messageCenter.orgLevelId + "=" + orgLevelId + "&" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + this.getCommunicationApiRootVersion();
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request).then(function (res) { return _this.messagemapService.mapToColdStorageFlag(res); });
    };
    return MessageApiService;
}());
export { MessageApiService };
