import { AcaExportExecutionItem } from './aca-export-execution';
import { AcaExportCorrectionType } from './aca-export-correctiontype';
import { AcaExportCorrected1095C } from './aca-export-corrected-1095c';
import { AcaExportReplace1095C, MatchEmp } from './aca-export-replace-1095c';
import { AcaExportSupplemental1095C } from './aca-export-supplemental-1095c';
import { AcaExportCorrectionParameter } from './aca-export-correction-parameter';

export class AcaExportDialogOptions {
  public dialogType: number;
  public acaExportExecutionItem: AcaExportExecutionItem;
  public acaExportCorrected1095CContainer: AcaExportCorrected1095C[];
  public acaExportReplace1095CContainer: AcaExportReplace1095C[];
  public acaExportSupplemental1095C: AcaExportSupplemental1095C[];
  public acaReplace1095C: AcaExportCorrected1095C;
  public matchEmployeeList: MatchEmp[];
  public acaExportCorrectionType: AcaExportCorrectionType;
  public isBack: boolean;
  public acaExportCorrectionParameter: AcaExportCorrectionParameter;
}
