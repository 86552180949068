import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { reportsConfig } from '../reports.config';
import { UrlParamsService, ApiUtilService } from '../../common/services/index';
import { Assert } from '../../framework/index';
import { ReportsMapService } from './reports-map.service';
import { IReportDefinition, ReportGroup, GenerateReportRequest, ReportStaffingSetting, IReportStaffingSetting, ReportAttendanceSetting, IReportAttendanceSetting, BIPAUnitShiftData, GetBIPAReportDefinition, IGetBIPAReportDefinition, MidnightToMidnightReport, IMidnightToMidnightReport } from '../models/index';
import { CacheType } from '../../common/models/cache/cache-definition';
var ReportsApiService = /** @class */ (function () {
    function ReportsApiService(mapService, authApiService, urlParamsService, http) {
        this.http = http;
        this.mapService = mapService;
        this.apiUtilsService = authApiService;
        this.urlParamsService = urlParamsService;
    }
    ReportsApiService.prototype.generateReport = function (generateReportRequest) {
        Assert.isNotNull(generateReportRequest, 'reportDefinition');
        //let reportRequest: IReportDefinition = this.mapService.mapReportDefinitionToRequest(generateReportRequest);
        var url = "" + this.getGenearteReportApiRoot();
        var request = this.urlParamsService.createPostRequest(url, generateReportRequest);
        var promise = this.apiUtilsService.requestForFile(request, true)
            .then(function (response) { return response; });
        return promise;
    };
    ReportsApiService.prototype.getReportsList = function (orgLevelId) {
        var _this = this;
        Assert.isNotNull(orgLevelId, 'orgLevelId');
        var url = "" + this.getReportListApiRoot(orgLevelId);
        var request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
        var promise = this.apiUtilsService.request(request)
            .then(function (response) {
            return _this.mapService.mapReportsDtoToReportGroups(response.data);
        });
        return promise;
    };
    ReportsApiService.prototype.getStaffingSettings = function (orgLevelId, departmentId, forcedLoad) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(orgLevelId, 'orgLevelId');
                Assert.isNotNull(departmentId, 'departmentId');
                url = "" + this.getReportStaffingSettingsApiRoot(orgLevelId);
                request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, departmentId: departmentId });
                promise = this.apiUtilsService.cachedRequest(request, CacheType.shortTerm, forcedLoad)
                    .then(function (response) {
                    return _this.mapService.mapSettingsDTOReportStaffingSettings(response.data);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    ReportsApiService.prototype.saveStaffingSettings = function (orgLevelId, departmentId, reportStaffingSetting) {
        Assert.isNotNull(orgLevelId, 'orgLevelId');
        Assert.isNotNull(departmentId, 'departmentId');
        var url = "" + this.getReportStaffingSettingsApiRoot(orgLevelId);
        var body = this.mapService.mapToReportStaffingSettingsDTO(reportStaffingSetting);
        var request = this.urlParamsService.createPostRequest(url, body, { orgLevelId: orgLevelId, departmentId: departmentId });
        var promise = this.apiUtilsService.request(request);
        return promise;
    };
    ReportsApiService.prototype.getAttendanceSettings = function (orgLevelId, departmentId, forcedLoad) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(orgLevelId, 'orgLevelId');
                Assert.isNotNull(departmentId, 'departmentId');
                url = "" + this.getReportAttendanceSettingsApiRoot(orgLevelId);
                request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, departmentId: departmentId });
                promise = this.apiUtilsService.cachedRequest(request, CacheType.shortTerm, forcedLoad)
                    .then(function (response) {
                    return _this.mapService.mapSettingsDTOReportAttendanceSettings(response.data);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    ReportsApiService.prototype.saveAttendanceSettings = function (orgLevelId, departmentId, reportAttendanceSetting) {
        Assert.isNotNull(orgLevelId, 'orgLevelId');
        Assert.isNotNull(departmentId, 'departmentId');
        var url = "" + this.getReportAttendanceSettingsApiRoot(orgLevelId);
        var body = this.mapService.mapToReportAttendanceSettingsDTO(reportAttendanceSetting);
        var request = this.urlParamsService.createPostRequest(url, body, { orgLevelId: orgLevelId, departmentId: departmentId });
        var promise = this.apiUtilsService.request(request);
        return promise;
    };
    ReportsApiService.prototype.getDailyStaffingSettings = function (orgLevelId, forcedLoad) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(orgLevelId, 'orgLevelId');
                url = this.getReportsApiRoot() + "/" + reportsConfig.api.settings.root + "/" + reportsConfig.api.settings.dailyStaffing + "/" + orgLevelId;
                request = this.urlParamsService.createGetRequest(url);
                promise = this.apiUtilsService.cachedRequest(request, CacheType.shortTerm, forcedLoad)
                    .then(function (response) {
                    return _this.mapService.mapBIPAReportData(response.data);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    ReportsApiService.prototype.postDailyStaffingSettings = function (orgLevelId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, promise;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(orgLevelId, 'orgLevelId');
                url = this.getReportsApiRoot() + "/" + reportsConfig.api.settings.root + "/" + reportsConfig.api.settings.dailyStaffing + "/" + orgLevelId;
                body = data;
                request = this.urlParamsService.createPostRequest(url, body, { orgLevelId: orgLevelId });
                promise = this.apiUtilsService.request(request);
                return [2 /*return*/, promise];
            });
        });
    };
    ReportsApiService.prototype.getMidnightToMidnightConfigData = function (orgLevelId, forcedLoad) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(orgLevelId, 'orgLevelId');
                url = this.getReportsApiRoot() + "/" + reportsConfig.api.settings.root + "/" + reportsConfig.api.settings.midnight + "/" + orgLevelId;
                request = this.urlParamsService.createGetRequest(url);
                promise = this.apiUtilsService.cachedRequest(request, CacheType.shortTerm, forcedLoad)
                    .then(function (response) {
                    return _this.mapService.mapMidnightToMidnightConfigDetails(response.data, response.meta);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    ReportsApiService.prototype.postMidnightToMidnightConfigData = function (orgLevelId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, promise;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(orgLevelId, 'orgLevelId');
                url = this.getReportsApiRoot() + "/" + reportsConfig.api.settings.root + "/" + reportsConfig.api.settings.midnight + "/" + reportsConfig.api.settings.saveMidnightConfig.root + "/" + orgLevelId;
                body = this.mapService.reverseMapMidnightToMidnightConfigData(data);
                request = this.urlParamsService.createPutRequest(url, body, { orgLevelId: orgLevelId });
                promise = this.apiUtilsService.request(request);
                return [2 /*return*/, promise];
            });
        });
    };
    ReportsApiService.prototype.getReportListApiRoot = function (orgLevelId) {
        return this.getReportsApiRoot() + "/" + reportsConfig.api.orglevel.root + "/" + orgLevelId + "/" + reportsConfig.api.orglevel.list;
    };
    ReportsApiService.prototype.getReportsApiRoot = function () {
        return this.apiUtilsService.getApiRoot() + "/" + reportsConfig.api.root;
    };
    ReportsApiService.prototype.getGenearteReportApiRoot = function () {
        return this.apiUtilsService.getApiRoot() + "/" + reportsConfig.api.root + "/" + reportsConfig.api.generate;
    };
    ReportsApiService.prototype.getReportStaffingSettingsApiRoot = function (orgLevelId) {
        return this.getReportsApiRoot() + "/" + reportsConfig.api.settings.root + "/" + reportsConfig.api.settings.staffing + "/" + orgLevelId;
    };
    ReportsApiService.prototype.getReportAttendanceSettingsApiRoot = function (orgLevelId) {
        return this.getReportsApiRoot() + "/" + reportsConfig.api.settings.root + "/" + reportsConfig.api.settings.attendance + "/" + orgLevelId;
    };
    return ReportsApiService;
}());
export { ReportsApiService };
