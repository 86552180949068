/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pm-create-review-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../employee/employee/components/employee-search/employee-search-field.component.ngfactory";
import * as i3 from "../../../../../employee/employee/components/employee-search/employee-search-field.component";
import * as i4 from "../../../../../employee/employee/services/employee-search-base/employee-search-base";
import * as i5 from "../pm-review-form/pm-review-form.component.ngfactory";
import * as i6 from "../pm-review-form/pm-review-form.component";
import * as i7 from "../../../services/pm-creation-management.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../../../core/services/window/window-ref.model";
import * as i10 from "@angular/common";
import * as i11 from "../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i12 from "../../../../../common/components/spinner/spinner.component";
import * as i13 from "../../../services/pm-management.service";
import * as i14 from "../../../services/performance-management-api.service";
import * as i15 from "../../../../../organization/services/lookup/lookup.service";
import * as i16 from "../../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i17 from "../../../../../common/services/file/file.service";
import * as i18 from "../../../../../common/services/change-management/change-management.service";
import * as i19 from "./pm-create-review-container.component";
var styles_PmCreateReviewContainerComponent = [i0.styles];
var RenderType_PmCreateReviewContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PmCreateReviewContainerComponent, data: {} });
export { RenderType_PmCreateReviewContainerComponent as RenderType_PmCreateReviewContainerComponent };
function View_PmCreateReviewContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-search-field", [], null, [[null, "loadStatusChange"], [null, "employeeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadStatusChange" === en)) {
        var pd_0 = (_co.onSearchLoadStatusChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("employeeSelected" === en)) {
        var pd_1 = (_co.onEmployeeSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EmployeeSearchFieldComponent_0, i2.RenderType_EmployeeSearchFieldComponent)), i1.ɵdid(1, 245760, null, 0, i3.EmployeeSearchFieldComponent, [i4.EmployeeSearchBase], { instructions: [0, "instructions"], orgLevelId: [1, "orgLevelId"] }, { loadStatusChange: "loadStatusChange", employeeSelected: "employeeSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "To create new review, begin by finding an employee. Use the employee's name to search and select an employee to begin."; var currVal_1 = ((_co.orgLevel == null) ? null : _co.orgLevel.id); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PmCreateReviewContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-pm-review-form", [], null, null, null, i5.View_PmReviewFormComponent_0, i5.RenderType_PmReviewFormComponent)), i1.ɵdid(1, 245760, null, 0, i6.PmReviewFormComponent, [i7.PmCreationManagementService, i8.Router, i8.ActivatedRoute, i9.WindowRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_PmCreateReviewContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "error-box flex flex-direction__column justify-content__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-exclamation-triangle error-box__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "error-box__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 3, 0, currVal_0); }); }
function View_PmCreateReviewContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, null); }
function View_PmCreateReviewContainerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.canEdit; _ck(_v, 0, 0, currVal_0); }); }
function View_PmCreateReviewContainerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.hasError || !_co.formValid) || !_co.hasChanges); _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.isNew ? "Save" : "Update"); _ck(_v, 1, 0, currVal_1); }); }
function View_PmCreateReviewContainerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "flex justify-content__flex-end modal-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmCreateReviewContainerComponent_6)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmCreateReviewContainerComponent_7)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isEditMode && !_co.isComplete); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.isEditMode; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_PmCreateReviewContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "slx-spinner", [["novalidate", ""]], null, null, null, i11.View_SpinnerComponent_0, i11.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i12.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmCreateReviewContainerComponent_1)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmCreateReviewContainerComponent_2)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmCreateReviewContainerComponent_3)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmCreateReviewContainerComponent_4)), i1.ɵdid(11, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmCreateReviewContainerComponent_5)), i1.ɵdid(13, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loadingReview || _co.loadingEmployees); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.hasEmployee; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.hasEmployee; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.hasError; _ck(_v, 8, 0, currVal_3); var currVal_4 = !_co.hasEmployee; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.hasEmployee; _ck(_v, 13, 0, currVal_5); }, null); }
export function View_PmCreateReviewContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-pm-create-review-container", [], null, null, null, View_PmCreateReviewContainerComponent_0, RenderType_PmCreateReviewContainerComponent)), i1.ɵprd(512, null, i7.PmCreationManagementService, i7.PmCreationManagementService, [i13.PmManagementService, i14.PerformanceManagementApiService, i15.LookupService, i16.NotificationsService, i17.FileService, i18.ChangeManagementService, i8.Router, i8.ActivatedRoute]), i1.ɵdid(2, 245760, null, 0, i19.PmCreateReviewContainerComponent, [i7.PmCreationManagementService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PmCreateReviewContainerComponentNgFactory = i1.ɵccf("slx-pm-create-review-container", i19.PmCreateReviewContainerComponent, View_PmCreateReviewContainerComponent_Host_0, { options: "options" }, { action: "action", employeeSelected: "employeeSelected" }, []);
export { PmCreateReviewContainerComponentNgFactory as PmCreateReviewContainerComponentNgFactory };
