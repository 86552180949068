import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { CacheType } from '../../../../app/common/models/cache/cache-definition';
import { ApiUtilService, CacheUtilService, UrlParamsService } from '../../../common/services/index';
import { PartnerConfigMapService } from './partner-config-map.service';
var PartnerConfigApiService = /** @class */ (function () {
    function PartnerConfigApiService(apiUtilService, urlParamService, cacheUtilService, partnerMapService) {
        this.apiUtilService = apiUtilService;
        this.urlParamService = urlParamService;
        this.cacheUtilService = cacheUtilService;
        this.partnerMapService = partnerMapService;
    }
    PartnerConfigApiService.prototype.getPartnerPositions = function (customerId, partnerId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["lookup/customer/", "/partner/", "/positions"], ["lookup/customer/", "/partner/", "/positions"])), customerId, partnerId);
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .request(request)
            .then(function (response) {
            return _this.partnerMapService.mapToPartnerPositions(response.data);
        });
        return promise;
    };
    PartnerConfigApiService.prototype.getPartnerPositionsDefinitions = function (partnerId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["lookup/customer/partner/", "/positions"], ["lookup/customer/partner/", "/positions"])), partnerId);
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .request(request)
            .then(function (response) {
            return _this.partnerMapService.mapToPartnerPostionsDefinitions(response.data);
        });
        return promise;
    };
    PartnerConfigApiService.prototype.addPartnerPosition = function (customerId, partnerId, partnerPositionsList) {
        var url = this.apiUtilService
            .apiRoot(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["configuration/partner/", "/customer/", "/positions"], ["configuration/partner/", "/customer/", "/positions"])), partnerId, customerId);
        var request = this.urlParamService.createPostRequest(url, partnerPositionsList);
        // clear cache
        this.cacheUtilService.delete({ key: url }, '');
        var promise = this.apiUtilService
            .request(request)
            .then(function (response) {
            return response.data;
        });
        return promise;
    };
    PartnerConfigApiService.prototype.DeletePartnerPositionMapping = function (customerId, partnerId, mapId) {
        var url = this.apiUtilService
            .apiRoot(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["configuration/partner/", "/customer/", "/positions"], ["configuration/partner/", "/customer/", "/positions"])), partnerId, customerId);
        var request = this.urlParamService.requestDelete(url + ("/" + mapId));
        // clear GET cache
        this.cacheUtilService.delete({ key: url }, '');
        return this.apiUtilService.requestNew(request);
    };
    PartnerConfigApiService.prototype.getPartnerDefinitions = function (subscriptionId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["schedule/partner/subscriptions/", ""], ["schedule/partner/subscriptions/", ""])), subscriptionId);
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) {
            return _this.partnerMapService.mapToPartnerDefinitions(response.data);
        });
        return promise;
    };
    PartnerConfigApiService.prototype.getPartnerClientActivationStatus = function () {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["schedule/partner/wfm/status"], ["schedule/partner/wfm/status"])));
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService
            .request(request)
            .then(function (response) {
            return _this.partnerMapService.mapToPartnerClientActivationStatus(response.data);
        });
        return promise;
    };
    return PartnerConfigApiService;
}());
export { PartnerConfigApiService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
