import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ColumnSettingsStorageService, ColumnManagementService, StateManagementService, ComponentStateStorageService } from '../../../../../common/services/index';
import { WcCommonManagementService,  WcRosterToolbarService, WcCreateOshaFormService} from '../../../services/index';

import { unsubscribeAll } from '../../../../../core/decorators/index';
@Component({
  selector: 'slx-wc-create-osha-rk-forms',
  templateUrl: './wc-create-osha-rk-forms.component.html',
  styleUrls: ['./wc-create-osha-rk-forms.component.scss'],
  providers: [WcCommonManagementService,
    WcCreateOshaFormService,
    WcRosterToolbarService,
    ColumnManagementService,
    ColumnSettingsStorageService,
    StateManagementService,
    ComponentStateStorageService]

})
export class WcCreateOshaRkFormsComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private oshaService: WcCreateOshaFormService) {}

  public ngOnInit() {
    this.oshaService.init('WcCreateOshaFormRoster');

    this.subscriptions.onLoad = this.oshaService
      .subscribeToLoading((value: boolean) => (this.isLoading = value));
  }

  public ngOnDestroy(): void {
  this.oshaService.destroy();
  }
}

