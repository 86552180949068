import * as tslib_1 from "tslib";
import { OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { EssTemplateOption, EssTemplate } from '../../models/index';
import { AppSettingsManageService } from '../../../../app-settings/services';
var EssTemplateOptionComponent = /** @class */ (function () {
    function EssTemplateOptionComponent(changeDetectorRef, appSettingsManageService) {
        this.changeDetectorRef = changeDetectorRef;
        this.appSettingsManageService = appSettingsManageService;
        this.isGoLiteTemplateOptions = false;
        this.valueChange = new EventEmitter();
    }
    EssTemplateOptionComponent.prototype.ngOnInit = function () {
        this.load();
    };
    EssTemplateOptionComponent.prototype.load = function () {
        this.getGoClientsetting();
    };
    EssTemplateOptionComponent.prototype.getGoClientsetting = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isGoClient = config.IsGOClient;
                        if (this.template.essTemplateDefinition !== null && this.template.essTemplateDefinition !== undefined) {
                            this.isGoLiteTemplateOptions = this.template.essTemplateDefinition.name == 'Go Lite' && !this.isGoClient;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EssTemplateOptionComponent.prototype.valueChanged = function () {
        if (!this.option.value) {
            _.forEach(this.option.childs, function (child) {
                child.value = false;
            });
        }
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
        this.valueChange.emit();
    };
    EssTemplateOptionComponent.prototype.childValueChange = function () {
        this.valueChange.emit();
    };
    EssTemplateOptionComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    return EssTemplateOptionComponent;
}());
export { EssTemplateOptionComponent };
