import * as tslib_1 from "tslib";
import { screenUtils } from './../../../../common/utils/screenUtils';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { StateResetTypes } from '../../../../core/models/settings/reset-types';
import { mutableSelect } from './../../../../core/decorators/redux-decorators';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { GenericListManagementService } from '../../../../organization/services/generic-list/generic-list-management.service';
import { GenericListRequest, GenericList, GenericRow, GenericField, GenericListDefinition } from '../../../../organization/models/generic-list/index';
import { OvertimeApprovalsApiService } from '../../services/overtime-approvals/overtime-approvals-api.service';
import { genericGridConfig } from '../../../../organization/components/generic-list/generic-grid/generic-grid.config';
import { GenericGridComponent } from '../../../../organization/components/generic-list/generic-grid/generic-grid.component';
import { LookupApiService, ApplicationStateBusService } from '../../../../organization/services/index';
import { appConfig } from './../../../../app.config';
import { genericListConfig } from '../../../../organization/generic-list.config';
import { StateManagementService } from './../../../../common/services/state-management/state-management.service';
import { A4 } from '../../../../common/models/media/paper-sizes';
import { TimecardsApiService } from '../../../../time-and-attendance/services/timecards/timecards-api.service';
var OvertimeApprovalsComponent = /** @class */ (function () {
    function OvertimeApprovalsComponent(genericListManagementService, apiService, lookupApiService, stateManagement, storageService, activatedRoute, router, appBusService, timecardApiService) {
        this.genericListManagementService = genericListManagementService;
        this.apiService = apiService;
        this.lookupApiService = lookupApiService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.appBusService = appBusService;
        this.timecardApiService = timecardApiService;
        this.m_dateFiltersControlId = 'DateFiltersNgx';
        this.m_dateFiltersResetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.empIdsFilter = null;
        this.pdfColorClass = 'timecard-pdf-grid';
        var mom = moment();
        this.endDate = moment().toDate();
        this.startDate = moment().subtract(30, 'days').toDate();
        this.isLoading = true;
        this.selectedApprove = [];
        this.selectedUnapprove = [];
        this.gridConfig = _.assign({}, genericGridConfig);
        this.gridConfig.pdfExport.fileName = 'OvertimeApprovals.pdf';
        this.gridConfig.pdfExport.autoscale = true;
        this.gridConfig.pdfExport.paperSize = A4.name;
        this.gridConfig.pdfExport.landscape = true;
        this.gridConfig.excelExport.fileName = 'OvertimeApprovals.xlsx';
    }
    Object.defineProperty(OvertimeApprovalsComponent.prototype, "canMakeApproveAction", {
        get: function () {
            return this.selectedApprove.length > 0 &&
                _.every(this.selectedApprove, function (fields) { return !fields.IsPayCycleLocked.value.value && !fields.IsTimecardLocked.value.value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OvertimeApprovalsComponent.prototype, "canMakeUnapproveAction", {
        get: function () {
            return this.selectedUnapprove.length > 0 &&
                _.every(this.selectedUnapprove, function (fields) { return !fields.IsPayCycleLocked.value.value && !fields.IsTimecardLocked.value.value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OvertimeApprovalsComponent.prototype, "someOvertimesAreLocked", {
        get: function () {
            return _.some(this.selectedApprove, function (fields) { return fields.IsPayCycleLocked.value.value || fields.IsTimecardLocked.value.value; }) ||
                _.some(this.selectedUnapprove, function (fields) { return fields.IsPayCycleLocked.value.value || fields.IsTimecardLocked.value.value; });
        },
        enumerable: true,
        configurable: true
    });
    OvertimeApprovalsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('OvertimeApprovalsComponent', true);
        this.appConfig = appConfig;
        this.loadApp();
        this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe(function (list) {
            _this.isLoading = false;
            _this.stateManagement.loadedData({});
            _this.grid.gridState.state.take = _this.grid.pageSize;
        });
    };
    OvertimeApprovalsComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    OvertimeApprovalsComponent.prototype.onSelectionChanged = function (items) {
        var _this = this;
        this.selectedApprove.length = 0;
        this.selectedUnapprove.length = 0;
        _.forEach(items, function (item) {
            if (item.fields.IsOvertimeApproved.value.value === 'Yes') {
                _this.selectedUnapprove.push(item.fields);
            }
            else {
                _this.selectedApprove.push(item.fields);
            }
        });
    };
    OvertimeApprovalsComponent.prototype.onExportToExcel = function () {
        this.grid.exportToExcel();
    };
    OvertimeApprovalsComponent.prototype.onExportToPdf = function () {
        this.grid.exportToPdf();
    };
    OvertimeApprovalsComponent.prototype.onApprove = function () {
        this.isLoading = true;
        this.apiService.approve(this.selectedApprove)
            .then(this.success.bind(this))
            .catch(this.failed.bind(this));
    };
    OvertimeApprovalsComponent.prototype.onUnapprove = function () {
        this.isLoading = true;
        this.apiService.unapprove(this.selectedUnapprove)
            .then(this.success.bind(this))
            .catch(this.failed.bind(this));
    };
    OvertimeApprovalsComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        if (this.startDate.getTime() !== startDate.getTime() || this.endDate.getTime() !== endDate.getTime()) {
            this.startDate = startDate;
            this.endDate = endDate;
            this.saveFilters();
            this.loadValues();
            this.resetStateSkipValue(this.grid.gridKey, this.stateKey);
        }
    };
    Object.defineProperty(OvertimeApprovalsComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    OvertimeApprovalsComponent.prototype.loadApp = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.appBusService.appSelected$, this.activatedRoute.queryParams)
            .subscribe(function (value) {
            var orgLevel = value[0], application = value[1], queryParams = value[2];
            if (!orgLevel || !orgLevel.id || !application.id) {
                return;
            }
            var queryOrgLevelId = Number(queryParams['orgLevelId']);
            if (queryOrgLevelId > 0 && queryOrgLevelId !== orgLevel.id) {
                return;
            }
            _this.orgLevel = orgLevel;
            _this.application = application;
            var startDate = queryParams['startDate'];
            var endDate = queryParams['endDate'];
            if (queryParams['empIds']) {
                _this.empIdsFilter = _.concat([], queryParams['empIds']);
            }
            if (startDate && endDate) {
                _this.startDate = moment(startDate, appConfig.linkDateFormat).toDate();
                _this.endDate = moment(endDate, appConfig.linkDateFormat).toDate();
            }
            else {
                _this.restoreFilters();
            }
            if (_this.startDate && _this.endDate) {
                _this.loadData();
            }
            else {
                _this.loadDataWithPayCycles();
            }
        });
    };
    OvertimeApprovalsComponent.prototype.loadData = function () {
        var _this = this;
        this.definitionsLoadedSubscription = this.loadDefinitions()
            .subscribe(function () { return _this.load(); });
    };
    OvertimeApprovalsComponent.prototype.loadDataWithPayCycles = function () {
        var _this = this;
        this.definitionsLoadedSubscription = this.loadDefinitions()
            .combineLatest(this.loadPayCycles())
            .subscribe(function () { return _this.load(); });
    };
    OvertimeApprovalsComponent.prototype.load = function () {
        this.stateManagement.onComponentActiveStateChanged({});
        this.loadValues();
        this.definitionsLoadedSubscription.unsubscribe();
    };
    OvertimeApprovalsComponent.prototype.success = function (error) {
        this.timecardApiService.clearIndividualTimecardsCacheData();
        this.isLoading = false;
        this.loadValues();
    };
    OvertimeApprovalsComponent.prototype.failed = function (error) {
        this.isLoading = false;
    };
    OvertimeApprovalsComponent.prototype.loadValues = function () {
        this.isLoading = true;
        this.selectedApprove.length = 0;
        this.selectedUnapprove.length = 0;
        var req = new GenericListRequest();
        req.startDate = this.startDate;
        req.endDate = this.endDate;
        if (this.empIdsFilter && this.empIdsFilter.length > 0) {
            req.ids = this.empIdsFilter;
        }
        this.genericListManagementService.loadListValues(this.orgLevel.id, req);
    };
    OvertimeApprovalsComponent.prototype.loadDefinitions = function () {
        this.isLoading = true;
        this.genericListManagementService.loadListDefinition(this.application.id, this.orgLevel.id, genericListConfig.overtimeApprovals);
        return this.genericListManagementService.onDefinitionsLoaded$;
    };
    OvertimeApprovalsComponent.prototype.loadPayCycles = function () {
        var _this = this;
        var promise = this.lookupApiService.getPayCyles(this.orgLevel.id);
        promise.then(function (cycles) { return _this.setPayCycle(cycles); });
        return Observable.fromPromise(promise);
    };
    OvertimeApprovalsComponent.prototype.setPayCycle = function (cycles) {
        var currentDate = moment();
        var currentCycles = _.filter(cycles, function (cycle) {
            var currStartDate = moment(cycle.startDate);
            var currEndDate = moment(cycle.endDate);
            return moment(currentDate).isBetween(currStartDate, currEndDate);
        });
        var startDate = _.first(currentCycles);
        if (startDate) {
            this.startDate = startDate.startDate;
            this.endDate = startDate.endDate;
        }
        this.saveFilters();
    };
    OvertimeApprovalsComponent.prototype.saveFilters = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId, {
            value: { startDate: this.startDate, endDate: this.endDate }
        }, this.m_dateFiltersResetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
    };
    OvertimeApprovalsComponent.prototype.restoreFilters = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
        if (state && state.value) {
            if (state.value.startDate)
                this.startDate = moment(state.value.startDate).toDate();
            if (state.value.endDate)
                this.endDate = moment(state.value.endDate).toDate();
        }
    };
    OvertimeApprovalsComponent.prototype.resetStateSkipValue = function (gridKey, stateKey) {
        var state = this.stateManagement.getGridState(gridKey, stateKey);
        state.skip = 0;
        this.stateManagement.setGridState(gridKey, state, stateKey);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], OvertimeApprovalsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OvertimeApprovalsComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OvertimeApprovalsComponent.prototype, "definitionsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OvertimeApprovalsComponent.prototype, "loadSubscription", void 0);
    return OvertimeApprovalsComponent;
}());
export { OvertimeApprovalsComponent };
