import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from './../../../../app-settings/model/app-server-config';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../core/decorators/index';
import { StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';
import { BenefitDetailsManagementService, BenefitDetailsPermissionService, BenefitConsoleDetailsCommonService } from '../../services/index';
import { CalculationCommonService } from '../../services/benefit-details/calculation-common.service';


@Component({
  selector: 'slx-benefit-details',
  templateUrl: 'benefit-details.component.html',
  styleUrls: ['benefit-details.component.scss'],
  providers: [StateManagementService, ComponentStateStorageService,
              BenefitDetailsPermissionService, BenefitDetailsManagementService,
              BenefitConsoleDetailsCommonService, CalculationCommonService]
})
export class BenefitDetailsComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public enableBenefitDeduction2:boolean = false;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private manService: BenefitDetailsManagementService,
    private permissionService: BenefitDetailsPermissionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appSettingsManageService: AppSettingsManageService
  ) { }

  public ngOnInit() {

    this.manService.init('benefits_management_details', this.router, this.activatedRoute);
    this.getSettings();
    this.subscriptions.onLoad = this.manService
      .subscribeToLoading((value: boolean) => Promise.resolve().then(() => (this.isLoading = value)));
  }

  public ngOnDestroy(): void {
    this.manService.destroy();
    this.permissionService.destroy();
  }

  private async getSettings(): Promise<void> {
    const config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.enableBenefitDeduction2 = config.isBenefitDeductionEnabled2;
  }
}
