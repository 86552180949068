import { IOrganization, Organization } from './../../../../organization/models/lookup/organization';
import { OrgPayrollExportState } from './org-payroll-export-state';
import { OrganizationPayrollStates } from './organization-payroll-states';

export interface IOrganizationPayroll {
    canExportToPayroll: boolean;
    organizationPayrollId: number;
    organization: IOrganization;
    state: number;
    alertCount: number;
    failCount: number;
    runAt: string;
    runBy: number;
    runByUser: string;
    isLocked: boolean;
    hasReleasedExports: boolean;
    isCheckingExportStatus: boolean;
    payrollExportId: number;
    status: OrgPayrollExportState;
    payrollAppStatus: string;
}

export class OrganizationPayroll {
    public canExportToPayroll: boolean;
    public organizationPayrollId: number;
    public organization: Organization;
    public state: OrganizationPayrollStates;
    public alertCount: number;
    public failCount: number;
    public runAt: Date;
    public runBy: number;
    public runByUser: string;
    public isLocked: boolean;
    public hasReleasedExports: boolean;
    public isCheckingExportStatus: boolean;
    public payrollExportId: number;
    public status: OrgPayrollExportState;
    public payrollAppStatus: string;

    public get isExported(): boolean {
        return this.state === OrganizationPayrollStates.EXPORTED;
    }
}

