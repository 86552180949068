<div class="client-messages-container">
  <ng-container *ngFor="let message of messages">
    <div class="client-message" [ngClass]="message.level">
      <div class="client-message-title">{{message.title}}</div>
      <div class="client-message-text" [innerHTML]="message.text"></div>
      <button type="button" class="close-button" (click)="closeMessage(message)">
          <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <i class="fa fa-exclamation-triangle client-message-icon" aria-hidden="true"></i>
      <span class="client-message-icon-border"></span>
      </div>
  </ng-container>
</div>
