import * as moment from 'moment';

export interface IScheduleCycle {
  startDate: moment.Moment;
  endDate: moment.Moment;
  departmentId: number;
}

export class ScheduleCycle {
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public departmentId: number;
  public id: number;
  public name: string = '';
}
