/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./current-temperature.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./current-temperature.component";
var styles_WeatherCurrentTempComponent = [i0.styles];
var RenderType_WeatherCurrentTempComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WeatherCurrentTempComponent, data: {} });
export { RenderType_WeatherCurrentTempComponent as RenderType_WeatherCurrentTempComponent };
function View_WeatherCurrentTempComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "deg"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\u00B0 ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.scaleLabel; _ck(_v, 1, 0, currVal_0); }); }
export function View_WeatherCurrentTempComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "title-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current Temp: "])), (_l()(), i1.ɵted(2, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeatherCurrentTempComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.temp; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.temp == null) ? null : _co.temp.toFixed()); _ck(_v, 2, 0, currVal_0); }); }
export function View_WeatherCurrentTempComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "weather-current-temperature", [], null, null, null, View_WeatherCurrentTempComponent_0, RenderType_WeatherCurrentTempComponent)), i1.ɵdid(1, 49152, null, 0, i3.WeatherCurrentTempComponent, [], null, null)], null, null); }
var WeatherCurrentTempComponentNgFactory = i1.ɵccf("weather-current-temperature", i3.WeatherCurrentTempComponent, View_WeatherCurrentTempComponent_Host_0, { temp: "temp", scaleLabel: "scaleLabel" }, {}, []);
export { WeatherCurrentTempComponentNgFactory as WeatherCurrentTempComponentNgFactory };
