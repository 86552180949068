import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { appConfig } from '../../../../../app.config';

import { DateRange, IDateRange, IColumnSettings } from '../../../../../core/models/index';

import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';
import { LmNavigationService } from '../../../../../common/services/navigation/index';

import { LMManagementService, LMRosterManagementService } from '../../../services/index';
import { LoaRequestDropDownItem, DropDownItem, LoaCategoryDropDownItem, LoaCategory } from '../../../../../common/models';
import { LoaRosterState, LoaRequestContainer } from '../../../models';
import { ILoaFilter } from '../../../models/loa-filter';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-roster-toolbar',
  templateUrl: 'lm-roster-toolbar.component.html',
  styleUrls: ['lm-roster-toolbar.component.scss']
})
export class LMRosterToolbarComponent implements OnInit, OnDestroy {
  public leaveTypes: LoaRequestDropDownItem[];
  public calendarModes: DropDownItem[];
  public calendarMode: DropDownItem;
  public filters: LoaCategoryDropDownItem[];
  public filter: LoaCategoryDropDownItem[];
  public sDate: Date;
  public eDate: Date;
  public state: LoaRosterState;

  public get canAddEdit(): boolean {
    return this.manService.canAddEdit;
  }

  public get canDelete(): boolean {
    return this.manService.canDelete;
  }

  public get isListView(): boolean {
    return this.listViewOn;
  }
  private get isDayMode(): boolean {
    return this.calendarMode.name === _.head(this.calendarModes).name;
  }

  private subscriptions: StringMap<Subscription> = {};
  private listViewOn = true;
  private hrNavService: LmNavigationService;
  private orgLevel: OrgLevel;
  private m_canAdd: boolean;
  private m_canDelete: boolean;

  constructor(
    private manService: LMManagementService,
    private rosterManService: LMRosterManagementService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.leaveTypes = manService.getRequestTypes();

    this.filters = _.reduce(LoaCategory, (accum: LoaCategoryDropDownItem[], key: string) => {
      if (key !== LoaCategory.None) {
        accum.push(this.createLoaCategoryDropDownItem(LoaCategory[key]));
      }
      return accum;
    }, []);
    this.filter = [];

    const calendarModes = ['Day', 'Week'];
    this.calendarModes = _.map(calendarModes, (n: string, i: number) => new DropDownItem(i + 1, n));
    this.calendarMode = _.last(this.calendarModes);

    this.hrNavService = new LmNavigationService(this.router, this.activatedRoute);
  }

  public ngOnInit(): void {

    this.subscriptions.viewSate = this.rosterManService
      .subscribeToChangeListViewState((isList: boolean) => this.listViewOn = isList);

    this.subscriptions.viewSate = this.rosterManService
      .subscribeToChangeDayViewState((isDay: boolean) => {
        if (isDay) {
          this.calendarMode = _.head(this.calendarModes);
        }
        else {
          this.calendarMode = _.last(this.calendarModes);
        }
    });

    this.subscriptions.routeFilters = this.rosterManService
      .subscribeToRouteFilter((filter: ILoaFilter) => this.applyFilterFromRoute(filter));

    this.subscriptions.orgLevel = this.rosterManService
      .subscribeToOrgLevelChanged((orgLevel: OrgLevel) => {
        this.orgLevel = orgLevel;
      });

    this.subscriptions.state = this.rosterManService
      .subscribeToChangeState((s: LoaRosterState) => {
        this.state = s;
      });

    this.subscriptions.dateRange = this.rosterManService
      .subscribeToDateRange((r: IDateRange) => this.assignDateRanges(r));

    this.subscriptions.navButton = this.manService
      .subscribeToNavButtonClick((isNext: boolean) => this.onNavButtonClick(isNext));

  
  }

  public applyFilterFromRoute(filter: ILoaFilter): void {
    this.filter = [];
    if (_.size(filter.category) > 0) {
      this.filter = [this.createLoaCategoryDropDownItem(LoaCategory[filter.category])];
    }
    this.onChangeFilter(this.filter);
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public createLoaCategoryDropDownItem(c: LoaCategory): LoaCategoryDropDownItem {
    if (!c) {
      return null;
    }
    const item = new LoaCategoryDropDownItem();
    item.id = c;
    switch (c) {
      case LoaCategory.OnLeave:
        item.name = 'On Leave';
        break;
      case LoaCategory.PastDue:
        item.name = 'Past Due';
        break;
      default:
        item.name = c.toString();
    }

    return item;
  }

  public onClickNewRequest(item: LoaRequestDropDownItem): void {
    this.manService.openCreationDialog(item.id);
  }

  public onNavButtonClick(isNext: boolean): void {
    const days = this.isDayMode ? 1 : 7;
    const startDate = new Date(this.sDate.getTime());
    const endDate = new Date(this.eDate.getTime());
    const newStartDate = new Date(startDate.setDate(isNext ? (startDate.getDate() + days) : (startDate.getDate() - days)));
    const newEndDate = new Date(endDate.setDate(isNext ? (endDate.getDate() + days) : (endDate.getDate() - days)));
    this.sDate = newStartDate;
    this.eDate = newEndDate;
    this.rosterManService.changeDateRange(new DateRange(this.sDate, this.eDate), true);
  }

  public assignDateRanges(range: IDateRange): void {
    this.sDate = range.startDate;
    this.eDate = range.endDate;
  }

  public onChangeDates(range: IDateRange): void {
    if (moment(range.startDate).isSame(this.sDate) && moment(range.endDate).isSame(this.eDate)) return;

    this.sDate = range.startDate;
    this.eDate = range.endDate;
    this.rosterManService.changeDateRange(new DateRange(this.sDate, this.eDate), true);
  }

  public onClickConsole(): void {

    this.hrNavService.navigateToConsole(this.orgLevel.id);
  }

  public onClickViewMode(isListView: boolean): void {
    this.listViewOn = isListView;
    this.rosterManService.changeViewMode(this.listViewOn);
  }

  public onChangeCalendarMode(): void {
    this.rosterManService.changeCalenderMode(this.isDayMode);
  }

  public onChangeFilter(filters: LoaCategoryDropDownItem[]): void {
    if (_.isArray(filters) && _.size(filters) > 0) {
      this.rosterManService.changeCategoryFilter(_.map(filters, (f: LoaCategoryDropDownItem) => f.id));
      return;
    }
    this.rosterManService.changeCategoryFilter([]);
  }

  public onClickExport(isPDF: boolean): void {
    this.rosterManService.exportTo(isPDF);
  }

  public onClickToggleSelection(isSelectAll: boolean): void {
    _.forEach(this.state.columns, (column: IColumnSettings) => {
      column.displayed = isSelectAll;
    });
    this.rosterManService.saveState();
  }

  public onChangeColumn(): void {
    this.rosterManService.saveState();
  }

  public getLeaveTypeWidth(): number {
    return (screen.width <= appConfig.mobileMaxWidth) ? 30 : 130;
  }
}
