import * as moment from 'moment';

import { IDatedObject, WeeklyData } from '../../../core/models/index';
import { ScheduleEntryDefinition, EmployeeDefinition, Position, ShiftDefinition, LocationUnit } from '../../../organization/models/index';
import { IndSchEntryWrapper } from './index';

export class IndSchDefWrapper {
  public employee: EmployeeDefinition;
  public position: Position;
  public homeShift: ShiftDefinition;
  public homeUnit: LocationUnit;
  public weeklyData: WeeklyData<IndSchEntryWrapper>[];
  public canEditScheduleApprovedPayPeriod ? : boolean;
}
