import * as moment from 'moment';
import { appConfig } from '../../../app.config';

export interface IBudgetDefinition {
  id: number;
  orgLevelId: number;
  startDate: Date;
  endDate?: Date;
}

export class BudgetDefinition {
  public id: number;
  public orgLevelId: number;
  public isActive: boolean;
  public startDate: Date;
  public endDate?: Date;
  public get description(): string {
    return `${moment(this.startDate).format(appConfig.dateFormat)} - ${this.endDate ? moment(this.endDate).format(appConfig.dateFormat) : ''}`;
  }
}
