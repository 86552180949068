import * as _ from 'lodash';

import { Role } from '../role-models/index';
import { ProductModule } from '../../../../organization/models/index';
//import { RolesRightGroupRow } from './roles-right-row';
//import { RolesProfileRow } from './roles-profile-row';

export type RolesRowDefinition =
  'RolesRightGroupRow' |
  'RolesOtherRightGroupRow'|
  'RolesRightModuleRow' |
  'RolesRightRow' |
  'RolesProfileRow' |
  'RolesSectionRow' |
  'RolesSubsectionRow' |
  'RolesFieldRow' |
  'RolesSubmenuRow' |
  'RolesMenuRow' |
  'RolesMenuModuleRow' |
  'RolesMenuModuleGroupRow' |
  'RolesComponentRow' |
  'RolesComponentsModuleRow' |
  'RolesComponentsModuleGroupRow'
  ;

export interface IRolesRow<T> {
  roles: Role[];
  productModule?: ProductModule;
  type: RolesRowDefinition;
  description: string;
  mapByRole: NumberMap<T>;
  dirtyByRole: NumberMap<boolean>;
  recalcStatus(roleId: number): void;
  setNextStatus(roleId: number): void;
}
export interface ILinkedToRightRolesRow<T> extends IRolesRow<T> {
  linkedRightName: string;
}

export function isILinkedToRightRolesRow(source: any): source is ILinkedToRightRolesRow<any> {
  return !!source.linkedRightName;
}
