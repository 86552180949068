import * as _ from 'lodash';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';

import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmDialogComponent, StateManagementService, ColumnManagementService, ConfirmOptions, ColumnsChangedEvent } from '../../../common/index';
import { destroyService, unsubscribe, mutableSelect } from '../../../core/decorators/index';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { appConfig, IApplicationConfig } from '../../../app.config';

import { PayCodesConfigurationManagementService, AccessManagementService, AccrualBalanceNavigationService } from '../../services/index';
import { PayCodesContainer, PayCodeModel, PayCode, AccrualTypes, AccrualPolicy } from '../../models/index';
import { ColorUtil } from '../../../common/utils/index';
import { OrgLevel } from '../../../state-model/models/index';
import { IColumnSettings } from '../../../core/models/index';
import { configurationConfig } from '../../configuration.config';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../app-settings/services';

@Component({
  moduleId: module.id,
  selector: 'slx-paycode-configuration',
  templateUrl: 'pay-code-configuration.component.html',
  styleUrls: ['pay-code-configuration.component.scss'],
  providers: [
    AccessManagementService,
    PayCodesConfigurationManagementService,
    ColumnManagementService,
    StateManagementService
  ]
})
export class PayCodeConfigurationComponent implements OnInit {
  public get container(): PayCodesContainer {
    return this.m_container;
  }

  public onAddItem: () => void;
  public onDeletedItems: () => void;

  public crudHelper: ConfigurationComponentHelper<PayCodeModel>;
  public gridState: KendoGridStateHelper<PayCodeModel>;
  public selectionHelper: KendoGridCustomSelectionHelper<PayCodeModel>;

  public readonly columnGroup: string = 'PayCode';

  private subscriptions: StringMap<Subscription> = {};

  public accrualTypesLookup: AccrualTypes[] = [];
  public accrualPoliciesLookup: AccrualPolicy[] = [];
  public isNextgenPayrollEnabled: boolean = false;
  private appSettingManageService: AppSettingsManageService;
  public state: {
    isLoading: boolean;
    bulkEditMode: boolean;
    canBulkEdit: boolean;
    canEdit: boolean;
    canAdd: boolean;
    canDelete: boolean;
    canEditExceptionFlag: boolean;
    canEditPBJFlag: boolean;
    canEditUseInTimesheets: boolean;
    canEditAca: boolean;
    canEditPaycodestoAccrualMappingFlag: boolean;
    isSmartAdmin: boolean;
  };

  public appConfig: IApplicationConfig;

  public skip = 0;

  private m_container: PayCodesContainer;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  public orgLevelId: number;

  @ViewChild('kendoGrid', { static: true })
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }

  private get grid(): GridComponent {
    return this.m_grid;
  }

  private m_grid: GridComponent;
  public columnSettings: IColumnSettings[] = [];

  @destroyService()
  private management: PayCodesConfigurationManagementService;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private savedSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;

  @unsubscribe()
  private columnStateChangeSubscription: Subscription;

  constructor(
    management: PayCodesConfigurationManagementService,
    private stateManagement: StateManagementService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private columnManagementService: ColumnManagementService,
    appSettingManageService: AppSettingsManageService
  ) {
    this.management = management;
    this.appSettingManageService = appSettingManageService;
    this.gridState = new KendoGridStateHelper<PayCodeModel>();
    this.gridState.state.skip = 0;

    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

    this.crudHelper = new ConfigurationComponentHelper<PayCodeModel>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;

    // directive intercepted methods (scope bug)
    this.onAddItem = () => {
      let code: PayCodeModel = new PayCodeModel();
      code.payCode = new PayCode();
      this.crudHelper.addItem(code);
    };

    this.onDeletedItems = () => {
      this.crudHelper.deleteSelected();
    };
  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
    });
    this.getSettings();
    this.bindPageLoadData();
    this.state = {
      isLoading: false,
      bulkEditMode: false,
      canBulkEdit: true,
      canEdit: true,
      canAdd: true,
      canDelete: true,
      canEditExceptionFlag: false,
      canEditPBJFlag: false,
      canEditUseInTimesheets: false,
      canEditAca:false,
      canEditPaycodestoAccrualMappingFlag: false,
      isSmartAdmin: false
    };

    this.stateSubscription = this.management.onStateChanged$.subscribe((state: {
      isLoading: false,
      bulkEditMode: false,
      canBulkEdit: true,
      canEdit: false,
      canAdd: false,
      canDelete: false,
      orgLevelChanged: false,
      canEditExceptionFlag: false,
      canEditPBJFlag: false,
      canEditUseInTimesheets: false,
      canEditAca:false,
      canEditPaycodestoAccrualMappingFlag: false,
      isSmartAdmin:false
    }) => {
      if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
      if (_.has(state, 'bulkEditMode')) {
        if (state.bulkEditMode) {
          if (this.grid) this.gridState.closeEditor(this.grid);
        }
        this.state.bulkEditMode = state.bulkEditMode;
      }
      if (_.has(state, 'canBulkEdit')) this.state.canBulkEdit = state.canBulkEdit;
      if (_.has(state, 'canEdit')) this.state.canEdit = state.canEdit;
      if (_.has(state, 'canAdd')) this.state.canAdd = state.canAdd;
      if (_.has(state, 'canDelete')) this.state.canDelete = state.canDelete;
      if (_.has(state, 'orgLevelChanged')) {
        if (this.grid) this.gridState.closeEditor(this.grid);
      }
      if (_.has(state, 'canEditExceptionFlag')) this.state.canEditExceptionFlag = state.canEditExceptionFlag;
      if (_.has(state, 'canEditPaycodestoAccrualMappingFlag')) this.state.canEditPaycodestoAccrualMappingFlag = state.canEditPaycodestoAccrualMappingFlag;
      if (_.has(state, 'canEditExceptionFlag')) this.state.canEditPBJFlag = state.canEditPBJFlag;
      if (_.has(state, 'canEditUseInTimesheets')) this.state.canEditUseInTimesheets = state.canEditUseInTimesheets;
      if (_.has(state, 'canEditAca')) this.state.canEditAca = state.canEditAca;
      if (_.has(state, 'isSmartAdmin')) this.state.isSmartAdmin = state.isSmartAdmin;
    });

    // Later we will add updating per item, adding new pay codes and removing

    this.savedSubscription = this.management.onItemSaved$.subscribe((item: PayCodeModel) => {
      this.gridState.editedRecord = null;
      this.gridState.savedEditedRecord = null;
    });

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: PayCodeModel[]): void => {
      this.crudHelper.selectionChange(_.first(records), 0);
    });

    /*
    this.removeSubscription = this.management.removeItemsCmd$.subscribe((request: RemovePositionRequest) => {
        if (request.requireReassign) {

            ConfigurePositionsReassignPopupComponent.openDialog(request, this.modalService, (result: boolean, reassingRequest: RemovePositionRequest) => {
                if (result) {
                    this.management.doRemoveItem(request.itemToDelete, reassingRequest.ressignedPositionId, reassingRequest.effectiveDate);
                }
            });

        } else {

            let options: ConfirmOptions = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialogComponent.openDialog(
                'Confirmation',
                'Do you want to delete the position?',
                this.modalService,
                (result: boolean) => {
                    if (result) {
                        this.management.doRemoveItem(request.itemToDelete);
                    }
                }, options);
        }
    });
    */

    this.subscriptions.export = this.management
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
    this.management.init();


    this.columnStateChangeSubscription = this.columnManagementService.columnsChanged$.filter((event: ColumnsChangedEvent) => event.group === this.columnGroup)
      .subscribe((event: ColumnsChangedEvent) => {
        this.columnSettings = event.columns;
      });
  }
  public isExport(property: string): boolean {
    if (this.columnSettings && this.columnSettings.length > 0) {
      let state: IColumnSettings = _.find(this.columnSettings, (c: IColumnSettings) => c.name == property);
      return state.displayed;
    }
    else {
      return true;
    }
  }
  public bindPageLoadData(): void {
    this.getAccrualPolicy();
    this.getAccrualTypes();
    this.initServices();

    this.appConfig = appConfig;
    this.management.subscribeToBulkSaveResponse((redirectToAccrualBalances: boolean) => {
      if (redirectToAccrualBalances) {
        this.redirectToAccrualBalances();
      }
    });
  }

  public onBulkEditClick(): void {
    this.management.doBulkEdit();
  }

  public onBulkSaveClick(): void {
    this.management.doBulkSave();
  }

  public onBulkDiscardClick(): void {
    this.management.doBulkDiscard();
  }

  handlePayCodeClick(event: MouseEvent, id: string): void {
    event.preventDefault();
    /*
    const url = `#`; //todo: put pay codes detail url when User Story 304135 is completed
    window.location.href = url;
    */
  }

  public onItemFieldChange(item: PayCodeModel, field: string, value: any): void {
    switch (field) {
      case 'isAca':
        item.payCode.isAca = value;
        break;
      case 'isPaid':
        item.payCode.isPaid = value;
        break;
      case 'isPbj':
        item.payCode.isPbj = value;
        break;
      case 'isException':
        item.payCode.isException = value;
        break;
      case 'isUsedInAccrualCalc':
        item.payCode.isUsedInAccrualCalc = value;
        break;
      case 'useInTimesheets':
        item.payCode.useInTimesheets = value;
        break;
    }
    this.management.setItemDirty(item);
  }

  public onChangeMultiselectValue(item: PayCodeModel, evnt): void {
    let optionsIds = _.map(evnt, (opt: AccrualPolicy) => opt.policyName);
    item.payCode.accrualPolicies = optionsIds;
    item.payCode.accrualPolicyNames = ((_.isNull(optionsIds) || (optionsIds && optionsIds.length == 0)) && item.payCode.isUsedInAccrualCalc) ? 'All' : _.join(optionsIds, ', ');
    item.payCode.accrualPolicyList = evnt;
    item.isDirty = true;
  }

  public onChangeAccrualType(item: PayCodeModel, evnt): void {
    if (item.payCode.accrualType != evnt.accrualTypeName) {
      item.payCode.accrualType = evnt.accrualTypeName;
      item.isDirty = true;
    }
  }

  public onColorPickerChange(payCode: PayCodeModel, colorCode: string): void {
    if (payCode) payCode.payCode.color = ColorUtil.HexToDec(colorCode);
  }

  public getColor(payCode: PayCodeModel): string {
    return payCode && payCode.payCode ? ColorUtil.DecToHexString(payCode.payCode.color, true) : '#ffffff';
  }

  public rowCallback(context: RowClassArgs): any {
    if (context.dataItem.isDirty) {
      return { 'dirty-item': true };
    }
    return {};
  }

  public onClickExport(isPDF: boolean): void {
    this.management.exportTo(isPDF);
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  private initServices(): void {
    this.stateManagement.init('PayCodeConfigurationComponent');
    this.columnManagementService.init('PayCodeConfigurationComponent');
    this.columnManagementService.initGroup(this.columnGroup, 12);
  }

  public redirectToAccrualBalances(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showOK = true;
    options.showCancel = true;
    options.buttonOKtext = 'Yes';
    options.buttonCanceltext = 'No';
    ConfirmDialogComponent.openDialog(
      'Warning',
      'The changes made could impact the accrual balances of your employees. Would you like to proceed to the accrual balances screen and recalculate the balances?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          let navService: AccrualBalanceNavigationService = new AccrualBalanceNavigationService(this.router, this.route);
          navService.NavigateToAccrualBalance();
        }
      }, options);
  }

  public getAccrualPolicy(): void {
    if (this.orgLevelId) {
      this.management.getAccrualPolicies(this.orgLevelId)
        .then((accrualPolicies: AccrualPolicy[]) => {
          this.accrualPoliciesLookup = accrualPolicies;
        });
    }
  }

  public getAccrualTypes(): void {
    if (this.orgLevelId) {
      this.management.getAccrualTypes(this.orgLevelId)
        .then((accrualTypes: AccrualTypes[]) => {
          const accrualEmptyType: AccrualTypes[] = [{ accrualTypeId: null, accrualTypeName: '' }];
          this.accrualTypesLookup = [ ...accrualEmptyType, ...accrualTypes] ;
        });
    }
  }
  public canEditAcaCheck( dataItem:PayCodeModel):any{
    if((dataItem.payCode.allocationTypeId == 1  ||  dataItem.payCode.allocationTypeId == 4 ) && dataItem.payCode.isPaid ==true)
    {      
         return  true;     
    }  
    else if( dataItem.payCode.groupId == 4 && dataItem.payCode.isPaid ==true) {
        return true;
    }
    else{
      return  false;
    }
  }
  public canDeductFrom(payCode: PayCodeModel): boolean {
    return _.some(configurationConfig.deductFromGroups, group => payCode.payCode.group === group);
  }

  private getSettings() {
    this.appSettingManageService.getAppServerConfig().then((conf: AppServerConfig) => {
      this.isNextgenPayrollEnabled = conf.IsNextgenPayrollEnabled;
    });
  }
}
