/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-left-sidebar-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header-left-sidebar-button.component";
import * as i3 from "../../actions/sidebar.actions";
var styles_HeaderLeftSidebarButtonComponent = [i0.styles];
var RenderType_HeaderLeftSidebarButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderLeftSidebarButtonComponent, data: {} });
export { RenderType_HeaderLeftSidebarButtonComponent as RenderType_HeaderLeftSidebarButtonComponent };
export function View_HeaderLeftSidebarButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "hamburger-container slx-navigation-menu-ignore"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleLeftSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-bars"]], null, null, null, null, null))], null, null); }
export function View_HeaderLeftSidebarButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-header-left-sidebar-button", [], null, null, null, View_HeaderLeftSidebarButtonComponent_0, RenderType_HeaderLeftSidebarButtonComponent)), i1.ɵdid(1, 245760, null, 0, i2.HeaderLeftSidebarButtonComponent, [i3.SidebarActions], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderLeftSidebarButtonComponentNgFactory = i1.ɵccf("slx-header-left-sidebar-button", i2.HeaderLeftSidebarButtonComponent, View_HeaderLeftSidebarButtonComponent_Host_0, {}, {}, []);
export { HeaderLeftSidebarButtonComponentNgFactory as HeaderLeftSidebarButtonComponentNgFactory };
