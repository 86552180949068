export * from './add-agency-employee/add-agency-employee.component';
export * from './add-agency-employee/schedule-agency-employee.component';

import { AddAgencyEmployeeComponent } from './add-agency-employee/add-agency-employee.component';
import { ScheduleAgencyEmployeeComponent } from './add-agency-employee/schedule-agency-employee.component';

export const agencyEmployeesComponents: any[] = [
  AddAgencyEmployeeComponent,
  ScheduleAgencyEmployeeComponent
];
