import { OnInit } from '@angular/core';
import { DialogOptions } from '../../../../common/models/dialog-options';
import { Assert } from '../../../../framework/assert/assert';
import { ModalService } from '../../../../common/services/modal/modal.service';
var UnassignedEmployeesPopupComponent = /** @class */ (function () {
    function UnassignedEmployeesPopupComponent(options, modalService) {
        this.options = options;
        this.modalService = modalService;
        this.m_employees = null;
        Assert.isNotNull(this.modalService, 'modalService');
        Assert.isNotNull(this.options, 'options');
    }
    Object.defineProperty(UnassignedEmployeesPopupComponent.prototype, "employees", {
        get: function () {
            return this.m_employees;
        },
        set: function (value) {
            this.m_employees = value;
            if (this.state)
                this.state.isLoading = this.m_employees === null;
        },
        enumerable: true,
        configurable: true
    });
    UnassignedEmployeesPopupComponent.prototype.ngOnInit = function () {
        this.state = {
            isLoading: this.m_employees === null
        };
    };
    UnassignedEmployeesPopupComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    UnassignedEmployeesPopupComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return UnassignedEmployeesPopupComponent;
}());
export { UnassignedEmployeesPopupComponent };
