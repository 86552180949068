export interface IPBJExportParentGrid {
  batchId: number;
  parentOrgName: string;
  exportDate: Date;
  startDate: Date;
  endDate: Date;
  staffingType: string;
  employeeInfo: string;
  staffingInfo: string;
  userName: string;
  batchStatus: string;
  batchProgress:number;
}

export class PBJExportParentGrid {
   public batchId: number;
   public parentOrgName: string;
   public exportDate: Date;
   public startDate: Date;
   public endDate: Date;
   public staffingType: string;
   public employeeInfo: string;
   public staffingInfo: string;
   public userName: string;
   public batchStatus: string;
   public batchProgress:number;
  }
