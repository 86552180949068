import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, Input, OnDestroy, Output, EventEmitter, Inject } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';
import { ScheduleConsoleToolbarService } from '../../services/index';
import { ToolbarSectionTypes } from '../../../../common/models/index';
import { appConfig } from '../../../../app.config';

import { unsubscribe, debounce } from '../../../../core/decorators/index';
import { DisplayDefinition, ScheduleConsoleFilterItem, ConsoleConfig, DisplayType, ScheduleConsoleFilterChangedEvent } from '../../models/index';
import { DateRangeWithDate, RangeType } from '../../../../components-library/models/index';
import { StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';
import { IControlState, StateResetTypes } from '../../../../core/models/index';
import { CollapsibleSectionService } from '../../../../components-library/services/index';
import { PopperContent } from 'ngx-popper';

import { ChartMenuItem, ScheduleConsoleGroupBy, ScheduleConsoleGroupByType } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-toolbar',
  templateUrl: 'schedule-console-toolbar.component.html',
  styleUrls: ['schedule-console-toolbar.component.scss'],
})
export class ScheduleConsoleToolbarComponent implements OnDestroy {
  @Input()
  public consoleConfig: ConsoleConfig;

  @Input()
  public selectedRangeType: string;

  @Input()
  public startOfWeek: string;

  @Input()
  public selectedDate: Date;

  @Input()
  public groupBy: ScheduleConsoleGroupBy;

  @Input('groupByList')
  public origGroupByList: ScheduleConsoleGroupBy[];

  @Input('orgLevel')
  public set setOrgLevel(value: OrgLevel) {
    if (value && value.id) {
      this.orgLevel = value;
      if (!this.isDepartment && this.showOnlyDirectCare) {
        this.showOnlyDirectCare = false;
      }
      this.filterGroupByOptions();
    }
  }

  @Input()
  public filtersList: ScheduleConsoleFilterItem[];

  @Input()
  public charts: ChartMenuItem[];

  @Input()
  public shownChart: ChartMenuItem;

  @Output()
  public onDateRangeChange: EventEmitter<DateRangeWithDate>;

  @Output()
  public onDateRangeTypeChange: EventEmitter<RangeType>;

  @Output()
  public onConfigChange: EventEmitter<ConsoleConfig>;

  @Output()
  public onGrouppingChange: EventEmitter<ScheduleConsoleGroupBy>;

  @Output()
  public onFilterChange: EventEmitter<ScheduleConsoleFilterChangedEvent>;

  @Output()
  public onShownChartChange: EventEmitter<ChartMenuItem>;

  public groupByModel: ScheduleConsoleGroupBy;
  public isShowLimits: boolean;
  public isShowMobileFilter: boolean;
  public m_appliedFilters: ScheduleConsoleFilterItem[];
  public groupByList: ScheduleConsoleGroupBy[];
  public showOnlyDirectCare: boolean;

  @unsubscribe()
  private contextChangeSubscription: Subscription;
  @unsubscribe()
  private initSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  private selectedFilterItems: ScheduleConsoleFilterItem[];
  private filtersControlKey: string = 'filter';
  private orgLevel: OrgLevel;
  private lockUpdateDirectCare: boolean;

  constructor(
    @Inject(TOOLBAR_SERVICE) private consoleToolbarService: ScheduleConsoleToolbarService,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private sectionService: CollapsibleSectionService
  ) {
    this.onDateRangeChange = new EventEmitter<DateRangeWithDate>();
    this.onDateRangeTypeChange = new EventEmitter<RangeType>();
    this.onConfigChange = new EventEmitter<ConsoleConfig>();
    this.onFilterChange = new EventEmitter<ScheduleConsoleFilterChangedEvent>();
    this.onGrouppingChange = new EventEmitter<ScheduleConsoleGroupBy>();
    this.onShownChartChange = new EventEmitter<ChartMenuItem>();

    this.selectedDate = moment().toDate();
    this.startOfWeek = 'Sunday';
    this.selectedRangeType = 'Day';
    this.isShowLimits = false;
    this.isShowMobileFilter = false;

    this.initSubscription = this.stateManagement.onInit$
      .subscribe(() => {
        this.restoreFilters();
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public set appliedFilters(value: ScheduleConsoleFilterItem[]) {
    this.m_appliedFilters = value;
    let selectedFilters: ScheduleConsoleFilterItem[] = [];
    let isAllDirectCare: boolean;
    if (_.isArray(value) && _.size(value) > 0) {
      selectedFilters = value.slice(0);
    }

    if (!this.lockUpdateDirectCare) {
      if (_.size(selectedFilters) > 0) {
        let directCareSelectedCount = 0;
        let directCareCount = 0;
        _.each(this.filtersList, (filter: ScheduleConsoleFilterItem) => {
          if (filter.isDirectCare) {
            directCareCount++;
            let itemSelected: boolean = _.indexOf(selectedFilters, filter) !== -1;
            if (itemSelected) {
              directCareSelectedCount++;
            }
          }
        });
        isAllDirectCare = (directCareCount === directCareSelectedCount) && (_.size(selectedFilters) === directCareSelectedCount);
      } else {
        isAllDirectCare = false;
      }
      this.showOnlyDirectCare = isAllDirectCare;
    } else {
      this.lockUpdateDirectCare = false;
    }

    this.saveFilters();
    let event: ScheduleConsoleFilterChangedEvent = new ScheduleConsoleFilterChangedEvent();
    event.filters = selectedFilters;
    event.isDirectCare = this.showOnlyDirectCare;
    this.onFilterChange.emit(event);
  }

  public get appliedFilters(): ScheduleConsoleFilterItem[] {
    return this.m_appliedFilters;
  }

  public get isDepartment(): boolean {
    return this.orgLevel && this.orgLevel.type === OrgLevelType.department;
  }

  public get isOrganization(): boolean {
    return this.orgLevel && this.orgLevel.type === OrgLevelType.organization;
  }

  public onRangeChanged(range: DateRangeWithDate): void {
    this.onDateRangeChange.next(range);
  }

  public onRangeTypeChanged(range: RangeType): void {
    this.onDateRangeTypeChange.next(range);
  }

  public onGrouppingChanged(groupBy: ScheduleConsoleGroupBy): void {
    this.onGrouppingChange.next(groupBy);
  }

  @debounce(500)
  public onConfigChanged(): void {
    this.onConfigChange.emit(this.consoleConfig);
  }

  public onChartItemClick(chart: ChartMenuItem, popper: PopperContent): void {
    const index: number = _.indexOf(this.charts, chart);
    if (index !== -1) {
      const currentChart: ChartMenuItem = this.charts[index];
      this.shownChart.isShown = false;
      currentChart.isShown = true;

      this.shownChart = currentChart;
      this.onShownChartChange.emit(currentChart);
    }
    if (popper) {
      popper.hide();
    }
  }

  public onToggleSection(isExpand: boolean): void {
    this.sectionService.toggleAllSections(isExpand);
  }

  public isVisibleSection(sectionType: ToolbarSectionTypes): boolean {
    return sectionType === ToolbarSectionTypes.VISIBLE;
  }

  public hasCollapsedByResize(counts: StringMap<number>): boolean {
    return counts[ToolbarSectionTypes.COLLAPSED_BY_RESIZE] > 0;
  }

  public getDateRangeWidth(): number {
    return (screen.width <= appConfig.mobileMaxWidth) ? 260 : 425;
  }

  public isDesktop(): boolean {
    return screen.width > appConfig.mobileMaxWidth;
  }

  public onChangeShowDirectCare(): void {
    this.lockUpdateDirectCare = true;
    if (!this.showOnlyDirectCare) {
      this.appliedFilters = [];
    } else {
      let directCareItems: ScheduleConsoleFilterItem[] = [];
      _.each(this.filtersList, (item: ScheduleConsoleFilterItem) => {
        if (item.isDirectCare) {
          directCareItems.push(item);
        }
      });
      this.appliedFilters = directCareItems;
    }
  }

  private filterGroupByOptions(): void {
    this.groupByList = _.filter(this.origGroupByList, (groupBy: ScheduleConsoleGroupBy) => {
      if (groupBy.name === ScheduleConsoleGroupByType.UNIT) {
        return this.isDepartment;
      }
      if (groupBy.name === ScheduleConsoleGroupByType.SHIFT) {
        return this.isDepartment || this.isOrganization;
      }

      return true;
    });

    const hasCurrentGroupping: ScheduleConsoleGroupBy = _.find(this.groupByList, { name: this.groupBy.name });
    if (!hasCurrentGroupping) {
      this.groupBy = this.origGroupByList[0];
      this.onGrouppingChanged(this.groupBy);
    }
  }

  private saveFilters(): void {
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      this.filtersControlKey,
      { value: { items: this.appliedFilters || [], isDirectCare: this.showOnlyDirectCare } },
      StateResetTypes.All
    );
  }

  private restoreFilters(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.filtersControlKey);
    if (state.value) {
      let items: ScheduleConsoleFilterItem[] = state.value.items;
      if (_.isArray(items) && _.size(items) > 0) {
        this.appliedFilters = items;
      } else {
        this.appliedFilters = [];
      }
      this.showOnlyDirectCare = state.value.isDirectCare;
    } else {
      this.showOnlyDirectCare = false;
      this.appliedFilters = [];
    }
  }
}
