import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { mutableSelect } from '../../../core/decorators/index';
var PortalEmployeeComponent = /** @class */ (function () {
    function PortalEmployeeComponent() {
    }
    PortalEmployeeComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], PortalEmployeeComponent.prototype, "isLeftSidebarOpen", void 0);
    return PortalEmployeeComponent;
}());
export { PortalEmployeeComponent };
