<div class="attachments">
  <div class="attachments__buttons flex" *ngIf="canUpload">
    <label *ngIf="showTitle" class="attachments__label">Upload Files</label>
    <span class="attachments__btn-box">
      <button class="slx-button slx-with-icon" type="button" [disabled]="disabled">
        <i aria-hidden="true" class="fal fa-paperclip slx-button__icon"></i>
        <span class="slx-button__text">Browse...</span>
      </button>
      <input #inputFile class="attachments__input"
             type="file"
             [disabled]="disabled"
             (change)="onFileChange($event)"
             (click)="onResetErrors($event)"
             [accept]="acceptedFileTypesInputFormat"
             multiple />
    </span>
    <span class="attachments__types" *ngIf="showFileTypeAsTooltip">
      <i aria-hidden="true" class="box__icon fas fa-info"><span [slxTooltip]="getTooltip()" tipPosition="right"></span></i>
    </span>
    <span class="flex__grow attachments__types" *ngIf="!showFileTypeAsTooltip">
      <span class="block attachments__break-words">Allowed file types: {{ acceptedFileTypesReadable }}</span>
      <span class="block">Max {{ maxFileSize }}</span>
    </span>
</div>

  <p class="slx-error-block attachments__error" *ngIf="errors">
    <span *ngIf="errors.maxFiles">You selected more than {{ maxFiles }} files</span>
    <span *ngIf="errors.maxFileSize">Unable to upload file. Max size for files is {{ maxFileSize }}.</span>
    <span *ngIf="errors.fileType">Unable to upload file. Allowed types: Word, Excel, PDF and image files.</span>
  </p>
  <ng-container *ngIf="hasFiles">
    <div class="attachments__attached">
      <p class="flex">
        <span class="flex__grow attachments__file-name-title">File Name</span>
        <span class="flex__shrink added-col attachments__added-title">Added By</span>
      </p>
      <p class="attachments__file-wrapper flex align-items__center" *ngFor="let file of files">
        <span class="flex flex__grow attachments__file">
          <i class="fal attachments__i-file"
            [class.fa-file-download]="isDownloadable(file)"
            [class.fa-file]="!isDownloadable(file)"
            (click)="onDownloadFile(file)"
            aria-hidden="true"
          ></i>
          <span class="tooltip-wrapper">
            <span class="flex__grow attachments__name"
              [class.download-link]="isDownloadable(file)"
              [slxTooltip]="file.name"
              (click)="onDownloadFile(file)"
              tipPosition="top"
            >{{ file.fileName }}</span>
          </span>
          <i *ngIf="!disabled && canDelete"
            class="fal fa-trash-alt attachments__i-delete"
            title="Delete file immediately"
            aria-hidden="true"
            (click)="onClickDelete(file)"
          ></i>
        </span>
        <span class="flex__shrink added-col added-by">{{ file.addedBy }}</span>
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="!hasFiles">
    <p class="attachments__no-files">No attached files</p>
  </ng-container>
</div>
