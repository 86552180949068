import {
  IEmployeeScheduleDefinition, EmployeeScheduleDefinition
} from '../../../organization/index';
import { IScheduleCycleSummaryMessage, ScheduleCycleSummaryMessage } from './schedule-cycle-summary-message';

export interface IScheduleCycleMessages {
  scheduleCycleStartDate: string;
  scheduleCycleEndDate: string;
  messages: IScheduleCycleSummaryMessage[];
}

export interface IGenerateScheduleSummary extends IScheduleCycleMessages {
  orgLevelId: number;
  schedule: IEmployeeScheduleDefinition[];
}

export class ScheduleCycleMessages {
  public scheduleCycleStartDate: Date;
  public scheduleCycleEndDate: Date;
  public messages: ScheduleCycleSummaryMessage[];
}

export class GenerateScheduleSummary extends ScheduleCycleMessages {
  public orgLevelId: number;
  public schedule: EmployeeScheduleDefinition[];
}
