import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, Input, Output, OnDestroy, ChangeDetectionStrategy, OnChanges, EventEmitter } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { dateTimeUtils } from '../../../../../common/utils/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { DateRange, IDateRange } from '../../../../../core/models/index';

import { LoaMappedRequest } from '../../../models/index';

export class AbsenceDay {
  constructor(
    public day: IDateRange = null,
    public left: string = '',
    public right: string = '',
    public cssClass: string = '',
    public hasBorder: boolean = true
  ) {}

  public get isEmpty(): boolean {
    return this.left === '' && this.right === '';
  }
}

@Component({
  moduleId: module.id,
  selector: 'slx-lm-schedule-line',
  templateUrl: 'lm-schedule-line.component.html',
  styleUrls: ['lm-schedule-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LMScheduleLineComponent implements OnDestroy, OnChanges {
  @Input()
  public loaRequest: LoaMappedRequest;
  @Input()
  public dateRange: IDateRange = new DateRange(null, null);
  @Input()
  public daysLength: number = 1;
  @Input()
  public disableToClickOnDay: boolean = false;
  @Output()
  public clickOnDay = new EventEmitter<Date>();

  public weekDays: Array<AbsenceDay[]> = [];
  public appConfig: IApplicationConfig = appConfig;

  private subscriptions: StringMap<Subscription> = {};
  private dayMs = 1000 * 60 * 60 * 24;

  public ngOnChanges(): void {
    const hasDaysLength = _.isFinite(this.daysLength);
    const hasRange = _.isObjectLike(this.dateRange) && _.isDate(this.dateRange.startDate);
    const hasRequest = _.isObjectLike(this.loaRequest);
    if (hasDaysLength && hasRange && hasRequest) {
      this.renderScheduleLine(this.dateRange.startDate, this.daysLength, this.loaRequest);
    }
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onDayClick(day: AbsenceDay[]): void {
    const isClickable = this.isClickable(day);
    if (isClickable) {
      const date = _.head(day).day.startDate;
      this.clickOnDay.emit(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    }
  }

  public isClickable(day: AbsenceDay[]): boolean {
    return !_.every(day, (piece) => piece.isEmpty) && !this.disableToClickOnDay;
  }

  public getBorder(days: AbsenceDay[]): string {
    const noBorder = _.some(days, (d) => !d.hasBorder);

    return noBorder ? '0px' : '';
  }

  public renderScheduleLine(sDate: Date, daysLength: number, loaRequest: LoaMappedRequest): void {
    const weekDays = this.getWeekDays(sDate, daysLength);
    const absenceDays = this.getAbsenceDays(sDate, daysLength, loaRequest);
    this.weekDays = this.combineWeekAndAbsenceDays(weekDays, absenceDays);
  }

  private getAbsenceDays(sDate: Date, length: number, req: LoaMappedRequest): StringMap<IDateRange[]> {
    if (_.size(req.absenceDays) > 0) {
      const startDateTime = dateTimeUtils.copyDate(sDate).setHours(0, 0, 0, 0);
      let counter = 1;
      const absenceDaysWithinRange = _.reduce(req.absenceDays, (accum: StringMap<IDateRange[]>, days: IDateRange[], startDayMs: string) => {
        const sDayMs = +startDayMs;
        const absenceEndDayMs = new Date(sDayMs).setHours(23, 59, 59, 999);
        if ((sDayMs >= startDateTime || absenceEndDayMs >= startDateTime) && counter <= length) {
          (accum[sDayMs] = accum[sDayMs] || []).push(...days);
          counter++;
        }

        return accum;
      }, {});
      return _.size(_.values(absenceDaysWithinRange)) > 0 ? absenceDaysWithinRange : {};
    }
    return {};
  }

  private combineWeekAndAbsenceDays(weekDays: IDateRange[], absenceDays: StringMap<IDateRange[]>): Array<AbsenceDay[]> {
    return _.reduce(weekDays, (accum: Array<AbsenceDay[]>, weekDay: IDateRange, dayIndex: number) => {
      const weekDayMs = String(weekDay.startDate.getTime());
      const absenceDay = absenceDays[weekDayMs];
      if (_.isObjectLike(absenceDay)) {
        const prevAbsenceDay = dayIndex > 0 ? accum[dayIndex - 1] : [];
        accum.push(this.getAbsenceDay(absenceDay, prevAbsenceDay));
      } else {
        accum.push([new AbsenceDay()]);
      }

      return accum;
    }, []);
  }

  private getAbsenceDay(absenceDay: IDateRange[], prevAbsenceDay: AbsenceDay[]): AbsenceDay[] {
    const prevLastAbsencePiece = _.last(prevAbsenceDay) || new AbsenceDay();
    return _.map(absenceDay, (r: IDateRange) => {
      const { startDate, endDate } = r;
      const { startDate: min, endDate: max } = this.getMinMaxOfDay(startDate);
      let cssClasses = '';

      const start = parseFloat(((dateTimeUtils.getTime(startDate) - dateTimeUtils.getTime(min)) * 100 / this.dayMs).toFixed(2));
      const end = parseFloat(((dateTimeUtils.getTime(endDate) - dateTimeUtils.getTime(max)) * 100 / this.dayMs).toFixed(2));
      const currentLeft = `${Math.abs(start)}%`;
      const currentRight = `${Math.abs(end)}%`;
      let hasBorder = true;
      if (currentRight === '0%') {
        hasBorder = false;
      }

      if (prevLastAbsencePiece.right === '0%' && currentLeft === '0%') {
        cssClasses = 'no-left';
        prevLastAbsencePiece.cssClass += ' no-right';
      }

      return new AbsenceDay(r, currentLeft, currentRight, cssClasses, hasBorder);
    });
  }

  private getWeekDays(startDate: Date, daysLength: number): IDateRange[] {
    const weekDays: IDateRange[] = [];
    let prevDate: IDateRange = null;
    for (let i = 1; daysLength >= i; i++) {
      let date: IDateRange = null;
      if (i === 1) {
        date = this.getMinMaxOfDay(startDate);
      } else {
        date = this.getMinMaxOfDay(dateTimeUtils.getTime(prevDate.endDate) + this.dayMs);
      }
      prevDate = date;
      weekDays.push(date);
    }
    return weekDays;
  }

  private getMinMaxOfDay(date: Date | number): IDateRange {
    const d = moment(dateTimeUtils.copyDate(date));

    return new DateRange(d.startOf('day').toDate(), d.endOf('day').toDate());
  }
}
