import { Component, Input } from '@angular/core';

import { UserRecord } from '../../../models/index';
import { UserProfileWithOrgLevelDecorator } from '../../../models/users/decorators/user-profile-with-orglevel.decorator';

@Component({
    moduleId: module.id,
    selector: 'slx-role-users-grid',
    templateUrl: 'role-users-grid.component.html',
    styleUrls: ['role-users-grid.component.scss']
})

export class RoleUsersGridComponent {
  @Input()
  public rowIndex: number;

  public get users(): UserProfileWithOrgLevelDecorator[] {
    return this.m_users;
  }
  @Input()
  public set users(value: UserProfileWithOrgLevelDecorator[]) {
    this.m_users = value;
  }

  @Input()
  public set filter(value: string) {
    this.m_filter = value;
  }

  private m_filter: string;
  private m_users: UserProfileWithOrgLevelDecorator[];

  public rowHasDetails(dataItem: any, index: number): boolean {
      return dataItem.orgLevels && dataItem.orgLevels.length > 0;
  }
}
