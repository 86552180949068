import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from './../../../../../core/decorators/unsubscribe-decorator';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsFutureUpdates } from '../../../models/index';
import { EmployeeSectionsAuditApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { ConfirmDialogComponent } from '../../../../../common/components/index';
import { orderBy, groupBy } from '@progress/kendo-data-query';
var EmployeeSectionsFutureUpdatesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsFutureUpdatesComponent, _super);
    function EmployeeSectionsFutureUpdatesComponent(employeeSectionsAuditApiService, modalService, decorator, ngZone, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeSectionsAuditApiService = employeeSectionsAuditApiService;
        _this.modalService = modalService;
        _this.changeDetector = changeDetector;
        _this.now = new Date();
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.sort = [{ field: 'scheduledFor', dir: 'asc' }];
        _this.gridState.itemKey = 'id';
        _this.gridEventSubscription = _this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        return _this;
    }
    Object.defineProperty(EmployeeSectionsFutureUpdatesComponent.prototype, "futureUpdates", {
        set: function (employeeSectionsFutureUpdates) {
            this.employeeSectionsFutureUpdates = employeeSectionsFutureUpdates;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsFutureUpdatesComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsFutureUpdatesComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsFutureUpdates;
    };
    EmployeeSectionsFutureUpdatesComponent.prototype.onStartRemove = function (dataItem) {
        var _this = this;
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure?', this.modalService, function (result) {
            if (result) {
                _this.doRemove(dataItem);
            }
        });
    };
    EmployeeSectionsFutureUpdatesComponent.prototype.doRemove = function (dataItem) {
        var _this = this;
        var itemId = dataItem.id;
        this.startProgress();
        this.employeeSectionsAuditApiService.deleteFutureUpdate(this.employeeId, itemId)
            .then(function (status) {
            _this.loadSubsection();
        }).catch(function () {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsFutureUpdatesComponent.prototype.onRefreshClicked = function () {
        this.loadSubsection();
    };
    EmployeeSectionsFutureUpdatesComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsAuditApiService.getFutureUpdates(this.employeeId)
            .then(function (employeeSectionsFutureUpdates) {
            _this.employeeSectionsFutureUpdates = employeeSectionsFutureUpdates;
            _this.refreshGrid();
            _this.stopProgress();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        }).catch(function () {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsFutureUpdatesComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        if (this.gridEventSubscription) {
            this.gridEventSubscription.unsubscribe();
        }
    };
    EmployeeSectionsFutureUpdatesComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.employeeSectionsFutureUpdates) {
            this.gridState.view = null;
            return;
        }
        var sortedRecords = orderBy(this.employeeSectionsFutureUpdates.updates, this.gridState.state.sort);
        var groupedRecords = groupBy(sortedRecords, this.gridState.state.group);
        this.gridState.view = {
            data: groupedRecords,
            total: groupedRecords.length
        };
        if (this.gridState.selectedRowsIds.length === 0) {
            setTimeout(function () { _this.gridState.selectFirstRow(); }, 1);
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsFutureUpdatesComponent.prototype, "gridEventSubscription", void 0);
    return EmployeeSectionsFutureUpdatesComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsFutureUpdatesComponent };
