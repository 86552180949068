import * as _ from 'lodash';
var SlxHttpRequest = /** @class */ (function () {
    function SlxHttpRequest(httpRequest, isBackgroundRequest, autoContentType) {
        if (isBackgroundRequest === void 0) { isBackgroundRequest = false; }
        if (autoContentType === void 0) { autoContentType = false; }
        this.httpRequest = httpRequest;
        this.isBackgroundRequest = isBackgroundRequest;
        this.autoContentType = autoContentType;
    }
    Object.defineProperty(SlxHttpRequest.prototype, "url", {
        get: function () {
            var url = _.get(this.httpRequest, 'url') || '';
            return url;
        },
        enumerable: true,
        configurable: true
    });
    return SlxHttpRequest;
}());
export { SlxHttpRequest };
