import { IEmpType, EmpType, IPosition, Position, IDepartment, Department } from '../../../organization/models/lookup/index';
import { IEmployeeDefinition, EmployeeDefinition } from '../../../organization/models/employee/index';

export interface IIndSchOrgLevelEmployee {
  employee: IEmployeeDefinition;
  position: IPosition;
  totalScheduleHours: number;
}

export class IndSchOrgLevelEmployee {
  public employee: EmployeeDefinition;
  public position: Position;
  public totalScheduleHours: number;
  public get id(): number {
    if (!this.employee) {
      return 0;
    }
    return this.employee.id;
  }
  public get name(): string {
    if(!this.employee) {
      return '';
    }
    return this.employee.name;
  }
}
