import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'slxEmployeeStatus' })
export class SlxEmployeeStatusPipe implements PipeTransform {

    public transform(value: string): string {
        if (value === 'Terminated') return 'T';
        if (value === 'Transferred') return 'X';
        if (value === 'Future Rehire') return 'F';
        return '';
    }
}
