import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { dateTimeUtils } from '../../../common/utils/index';

import { Position, IPositionGroup, PositionGroup, Lookup } from '../../../organization/models/index';
import { IPositionGroupView, PositionGroupView } from '../../models/index';

@Injectable()
export class ManageGroupsMapService {
  public mapToPositionGroupView(
    positions: Lookup<Position>,
    groups: Lookup<PositionGroup>,
    defaultGroupId: number,
    defaultGroupName: string
  ): PositionGroupView[] {
    const groupsList = new Map<number, PositionGroupView>();
    this.addGroupViews(groups, groupsList, defaultGroupId, defaultGroupName);
    this.addPositionsToGroups(positions, groupsList, defaultGroupId);

    return Array.from(groupsList.values());
  }

  public mapToGroupsWithPositions(group: PositionGroupView, position: Position, defaultGroupId: number, defaultGroupName: string): IPositionGroupView[] {
    const isDefaultGroup = defaultGroupId === group.id && defaultGroupName === group.name;
    const groupDto: IPositionGroupView = {
      groupId: isDefaultGroup ? null : group.id,
      positionIds: [position.id]
    };
    return [groupDto] as IPositionGroupView[];
  }

  public createGroupView(id: number = null, name: string = '', depId: number = null, isEditing: boolean = false): PositionGroupView {
    const group = new PositionGroupView();
    group.id = id;
    group.name = name;
    group.departmentId = depId;
    group.positions = [];
    group.isEditing = isEditing;

    return group;
  }

  public makePosViewOfPosGroup(g: PositionGroup, isEditing: boolean = false): PositionGroupView {
    if (_.isObjectLike(g)) {
      return this.createGroupView(g.id, g.name, g.departmentId, isEditing);
    }
    return null;
  }

  private addGroupViews(
    groups: Lookup<PositionGroup>,
    groupsList: Map<number, PositionGroupView>,
    defaultGroupId: number,
    defaultGroupName: string
  ): void {
    const defaultGroup = this.createGroupView(defaultGroupId, defaultGroupName, -1);
    groupsList.set(defaultGroup.id, defaultGroup);

    _.forEach(groups.items, (group) => {
      const groupView = this.createGroupView(group.id, group.name, group.departmentId);
      groupsList.set(groupView.id, groupView);
    });
  }

  private addPositionsToGroups(positions: Lookup<Position>, groups: Map<number, PositionGroupView>, defaultGroupId: number): void {
    _.forEach(positions.items, (position) => {
      if (_.isFinite(position.positionGroupId) && groups.has(position.positionGroupId)) {
        groups.get(position.positionGroupId).positions.push(position);
      } else if (!_.isFinite(position.positionGroupId) && groups.has(defaultGroupId)) {
        groups.get(defaultGroupId).positions.push(position);
      }
    });
  }
}
