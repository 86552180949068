import { Pipe, PipeTransform } from '@angular/core';

import * as _ from 'lodash';

import { Assert } from '../../framework/index';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  public transform(items: any[], filters: StringMap<string | string[]> | string | string[], filterBy: string): any {
    Assert.isNotNull(items);
    Assert.isNotNull(filters);
    Assert.isArray(items);

    if (_.isString(filters)) {
      return _.filter(items, (item: any) => item[filters] === filterBy);
    }

    if (_.isArray(filters)) {
      return _.filter(items, (item: any) => _.includes(filters, item));
    }

    if (_.isObject(filters)) {
      let filteredItems: any[] = items;
      _.each(_.keys(filters), (key: string) => {
        filteredItems = _.filter(filteredItems, (item: any) => {
          if (_.isArray(filters[key])) {
            return _.includes(filters[key], item[key]);
          }
          return item[key] === filters[key];
        });
      });
      return filteredItems;
    }

    return items;
  }
}
