import { Provider } from '@angular/core';

export * from './base-resolver';
export * from './start-end-date-resolver/start-end-date-resolver.service';

import { StartEndDateResolver } from './start-end-date-resolver/start-end-date-resolver.service';

export const resolvers: Provider[] = [
    StartEndDateResolver
];
