import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SummarySectionBase } from '../summary-section-base/summary-section.base';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { TimecardSummaryPaidHours } from '../../../models/index';
import { aggregateBy } from '@progress/kendo-data-query';
var HoursSummaryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HoursSummaryComponent, _super);
    function HoursSummaryComponent(management) {
        var _this = _super.call(this, management) || this;
        _this.dollarFormat = '$0.00';
        _this.numberFormat = '0.00';
        _this.aggregates = [
            ,
            { field: 'currentCycleHours', aggregate: 'sum' },
            { field: 'currentCycleHourlyPay', aggregate: 'sum' },
            { field: 'currentCycleOtherPay', aggregate: 'sum' },
            { field: 'currentCycleAmount', aggregate: 'sum' },
            { field: 'previousCycleHours', aggregate: 'sum' },
            { field: 'previousCycleHourlyPay', aggregate: 'sum' },
            { field: 'previousCycleOtherPay', aggregate: 'sum' },
            { field: 'previousCycleAmount', aggregate: 'sum' },
            { field: 'differenceCycleHours', aggregate: 'sum' },
            { field: 'differenceCycleHourlyPay', aggregate: 'sum' },
            { field: 'differenceCycleOtherPay', aggregate: 'sum' },
            { field: 'differenceCycleAmount', aggregate: 'sum' }
        ];
        return _this;
    }
    HoursSummaryComponent.prototype.ngOnInit = function () {
        this.setupManagementService();
    };
    HoursSummaryComponent.prototype.ngOnDestroy = function () {
        this.destroy();
    };
    HoursSummaryComponent.prototype.checkDifference = function (item, diff, field) {
        var percent;
        if (field === 'hours') {
            if (item.differenceCycleHours === 0)
                return false;
            percent = Math.abs(((item.currentCycleHours - item.previousCycleHours) / item.currentCycleHours) * 100);
        }
        else if (field === 'amount') {
            if (item.differenceCycleAmount === 0)
                return false;
            percent = Math.abs(((item.currentCycleAmount - item.previousCycleAmount) / item.currentCycleAmount) * 100);
        }
        else if (field === 'hourlyamounts') {
            if (item.differenceCycleHourlyPay === 0)
                return false;
            percent = Math.abs(((item.currentCycleHourlyPay - item.previousCycleHourlyPay) / item.currentCycleHourlyPay) * 100);
        }
        else if (field === 'otherpayamounts') {
            if (item.differenceCycleOtherPay === 0)
                return false;
            percent = Math.abs(((item.currentCycleOtherPay - item.previousCycleOtherPay) / item.currentCycleOtherPay) * 100);
        }
        else {
            return false;
        }
        if (percent === 0)
            return false;
        return percent > diff;
    };
    HoursSummaryComponent.prototype.onDataLoaded = function () {
        _.noop();
    };
    HoursSummaryComponent.prototype.refreshGrid = function () {
        _super.prototype.refreshGrid.call(this);
        this.totals = aggregateBy(this.container.records, this.aggregates);
    };
    HoursSummaryComponent.prototype.onExcelExport = function (e) {
        var rows = e.workbook.sheets[0].rows;
        var cells = _.filter(rows, { type: 'footer' });
        _.forEach(_.head(cells).cells, function (cell) {
            var value = parseFloat(cell.value);
            if (_.isFinite(value)) {
                cell.value = value;
            }
        });
    };
    return HoursSummaryComponent;
}(SummarySectionBase));
export { HoursSummaryComponent };
