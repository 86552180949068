import { ElementRef, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export var LOOKUP_DDN_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return LookupDropdownInputComponent; }),
    multi: true
};
var LookupDropdownInputComponent = /** @class */ (function () {
    function LookupDropdownInputComponent(elementRef) {
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.innerValue = {};
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
    }
    Object.defineProperty(LookupDropdownInputComponent.prototype, "readonly", {
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LookupDropdownInputComponent.prototype, "lookups", {
        get: function () {
            return this.lookupList;
        },
        set: function (lookupList) {
            this.lookupList = lookupList;
            if (this.lookupList && this.staticValue && !this.innerValue) {
                var selectedLookup = this.getValueFromLookup(this.staticValue);
                this.innerValue = selectedLookup;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LookupDropdownInputComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.staticValue = v;
                this.onChangeCallback(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LookupDropdownInputComponent.prototype, "_elementRef", {
        get: function () {
            return this.elementRef;
        },
        enumerable: true,
        configurable: true
    });
    LookupDropdownInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !Boolean(myattr);
    };
    LookupDropdownInputComponent.prototype.getValueFromLookup = function (value) {
        var _this = this;
        if (!value)
            return null;
        if (!this.lookupList)
            return null;
        if (!value.hasOwnProperty(this.valueMemberPath))
            return null;
        var val = value[this.valueMemberPath];
        var selectedLookup = _.find(this.lookupList, function (lookup) {
            return lookup[_this.valueMemberPath] === val;
        });
        return selectedLookup;
    };
    LookupDropdownInputComponent.prototype.writeValue = function (value) {
        var selectedLookup = this.getValueFromLookup(value);
        if (!selectedLookup) {
            if (!this.staticValue || this.lookupList) {
                this.staticValue = value;
            }
            this.innerValue = null;
        }
        else {
            this.innerValue = selectedLookup;
            this.staticValue = selectedLookup;
        }
    };
    LookupDropdownInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    LookupDropdownInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    return LookupDropdownInputComponent;
}());
export { LookupDropdownInputComponent };
