import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const PASSWORD_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SlxPasswordValidator),
    multi: true
};

@Directive({
    /* tslint:disable */
    selector: '[slxPassword][formControlName],[slxPassword][formControl],[slxPassword][ngModel]',
    /* tslint:enable */
    providers: [PASSWORD_VALIDATOR]
})
export class SlxPasswordValidator implements Validator {
    public validate(c: AbstractControl): { [key: string]: any } {
        return CommonValidators.password(c);
    }
}
