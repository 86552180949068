<div class="badge-container" *ngIf="model">
    <span class="container-child badge-title">Choose a rating to reach</span>
    <span class="container-child badge-note">Click the star rating you want to achive to see details below of what your staffing levels
        will need to reflect for that rating.</span>
    <slx-star-rating class="container-child" [readonly]="false" [rating]="model.organization?.rating.value"
        [maxValue]="model?.organization?.rating.maxValue" [filledItemClassName]="'full-yellow-big-star'"
        [emptyItemClassName]="'far empty-light-blue-big-star'" (ratingChange)="onPredictedRatingChange($event)">
    </slx-star-rating>
    <div class="container-child badge-bottom-bar">
        <div class="options-table">
            <div class="option-row headers">
                <div class="option-col">Options</div>
                <div class="option-col">Minimum RN Hours</div>
                <div class="option-col">Minimum Total Nurse Staffing Hours</div>
            </div>
            <ol class="options-table-body options-list-type">
                <li *ngFor="let option of possibleOptions; let i = index" class="options-table-item">
                    <span class="option-row">
                        <span class="option-col"></span>
                        <span class="option-col">{{option.rnHoursString}}</span>
                        <span class="option-col">{{option.totalHoursString}}</span>
                    </span>
                </li>
            </ol>
        </div>
        <ol class="options-list-type options-list">
            <li *ngFor="let option of possibleOptions; let i = index" class="option-list-row">
                <div class="option rn"><span class="option-label">Minimum Rn Hours</span>{{option.rnHoursString}}</div>
                <div class="option total"><span class="option-label">Minimum Total Nurse Staffing Hours</span>{{option.totalHoursString}}</div>
            </li>
        </ol>
    </div>
</div>
