<form #form="ngForm" novalidate class="content-form">
  <div class="flex content-holder flex-direction__column">
    <div class="ddl-section">
        <slx-dropdown-list class="slx-wide slx-bold-label" [options]="terminationReasons" [ngModel]="terminationReason"
          (ngModelChange)="onChangedTermReason($event)" name="terminationReasonField"
          #terminationReasonField="ngModel" required placeholder="Reason for terminating coverage">
        </slx-dropdown-list>

        <span *ngIf="terminationReasonField.errors" class="slx-error-block error-box">
          <span *ngIf="terminationReasonField.errors.required" errorMessage for="required"></span>
        </span>
    </div>

    <div class="flex flex-direction__row">
      <span class="pad-5 flex__grow">
        <slx-datepicker class="slx-wide" #eventDatePicker="ngModel" name="eventDatePicker"
          [ngModel]="eventDate" (ngModelChange)="onChangedEventDate($event)" placeholder="Event Date"
          (valueChanged)="onChangedEventDate($event)" [required]="true" [acceptNullDate]="false"
          [constantValidation]="true">
        </slx-datepicker>

        <span *ngIf="eventDatePicker.errors" class="slx-error-block  error-box">
          <span *ngIf="eventDatePicker.errors.required" errorMessage for="required"></span>
          <span *ngIf="eventDatePicker.errors.date" errorMessage for="date"></span>
        </span>
      </span>
      <span class="flex__grow">
        <slx-datepicker class="slx-wide" #coverageEndDatePicker="ngModel" [ngModel]="coverageDate"
          (ngModelChange)="onChangedCovDate($event)" (valueChanged)="onChangedCovDate($event)"
          name="coverageEndDatePicker" [slxMinDate]="restrictions.startDate" [minDate]="restrictions.startDate"
          [maxDate]="restrictions.endDate" [slxMaxDate]="restrictions.endDate" [required]="true"
          [acceptNullDate]="false" [constantValidation]="true" placeholder="Coverage End Date">
        </slx-datepicker>

        <span *ngIf="coverageEndDatePicker.errors" class="slx-error-block  error-box">
          <span *ngIf="coverageEndDatePicker.errors.required" errorMessage for="required"></span>
          <span *ngIf="coverageEndDatePicker.errors.date" errorMessage for="date"></span>
          <span *ngIf="coverageEndDatePicker.errors.maxDate" errorMessage for="maxDate">The value should be lower
            than {{ restrictions.endDate | amDateFormat: appConfig.dateFormat }}</span>
          <span *ngIf="coverageEndDatePicker.errors.minDate" errorMessage for="minDate">The value should be
            greater than {{ restrictions.startDate | amDateFormat: appConfig.dateFormat }}</span>
        </span>
      </span>
    </div>

  </div>
</form>
