import * as _ from 'lodash';
import { IPosition, Position } from '../../../organization/models/index';
import { Assert } from '../../../framework/index';
import { UserApplication, OrgLevel } from '../../../state-model/models/index';
import { EventEmitter } from '@angular/core';

export class OperationalConsoleField {
  private static readonly dataMap: StringMap<StringMap<string>> = {
    scheduledEmployees: { colName: 'SO_Console_ScheduledEmployees', title: 'Emps' },
    scheduledHours: { colName: 'SO_Console_ScheduledHours', title: 'Sched' },
    par: { colName: 'SO_Console_ScheduledVsPar', title: 'Ideal' },
    diff: { colName: 'SO_Console_ScheduledVsPar', title: 'Diff' },
    absentEmployees: { colName: 'SO_Console_Absent', title: 'Abs Emps' },
    absent: { colName: 'SO_Console_Absent', title: 'Abs Hrs' },
    replaced: { colName: 'SO_Consele_AbsencesReplaced', title: 'Replaced Emps' },
    notReplaced: { colName: 'SO_Consele_AbsencesNotReplaced', title: 'Not replaced' },
    diffR: { colName: 'SO_Consele_AbsencesNotReplaced', title: 'Not Replaced' },
    lateEmployees: { colName: 'SO_Console_LateEmployees', title: 'Late' },
    punchedEmployees: { colName: 'SO_Console_PunchedEmployees', title: 'Punched In' },
    notPunchedEmployees: { colName: 'SO_Console_NotPunchedEmployees', title: 'Not Punched' },
    overtime: { colName: 'SO_Console_ApproachingOT', title: 'Approaching OT' }
  };

  private m_value: number;
  private m_colName: string;
  private m_keyName: string;

  public static getTitle(keyName: string): string {
    return _.get(OperationalConsoleField.dataMap[keyName], 'title', '');
  }

  constructor(keyName: string, value: number) {
    Assert.isNotNull(keyName, 'keyName');
    Assert.isNotNull(value, 'value');

    this.m_keyName = keyName;
    this.m_value = value;
  }

  public get value(): number {
    return this.m_value;
  }

  public get title(): string {
    return OperationalConsoleField.dataMap[this.m_keyName].title;
  }

  public get colName(): string {
    return OperationalConsoleField.dataMap[this.m_keyName].colName;
  }
}

export class IOperationalConsoleEntry {
  public position: IPosition;
  public organizationLevel: OrgLevel;
  public scheduledEmployees: number;
  public absentEmployees: number;
  public lateEmployees: number;
  public punchedEmployees: number;
  public notPunchedEmployees: number;
  public scheduledHours: number;
  public par: number;
  public diff: number;
  public absent: number;
  public replaced: number;
  public diffR: number;
  public overtime: number;
}

export class OperationalConsoleEntry {
  public position: Position;
  public orgLevel: OrgLevel;
  public scheduledEmployees: OperationalConsoleField;
  public absentEmployees: OperationalConsoleField;
  public lateEmployees: OperationalConsoleField;
  public punchedEmployees: OperationalConsoleField;
  public notPunchedEmployees: OperationalConsoleField;
  public scheduledHours: OperationalConsoleField;
  public par: OperationalConsoleField;
  public diff: OperationalConsoleField;
  public absent: OperationalConsoleField;
  public replaced: OperationalConsoleField;
  public diffR: OperationalConsoleField;
  public overtime: OperationalConsoleField;
  public aca: OperationalConsoleField;
}

export class OperationalConsoleDialogParameters {
  public date: Date;
  public field: OperationalConsoleField;
  public position: Position;
  public orgLevel: OrgLevel;
  public application: UserApplication;
  public employeeLinkClick: EventEmitter<number>;

  constructor(date: Date, field: OperationalConsoleField, position: Position, orgLevel: OrgLevel, application: UserApplication, employeeLinkClick: EventEmitter<number>) {
    Assert.isNotNull(date, 'date');
    Assert.isNotNull(field, 'field');
    Assert.isNotNull(orgLevel, 'orgLevel');
    Assert.isNotNull(application, 'application');

    this.date = date;
    this.field = field;
    this.position = position;
    this.orgLevel = orgLevel;
    this.application = application;
    this.employeeLinkClick = employeeLinkClick;
  }
}
