import * as tslib_1 from "tslib";
import { InfoPreloaderDialogComponent } from './../../common/components/info-preloader-dialog/info-preloader-dialog.component';
import { InfoPreloaderParams } from './../../common/models/info-preloader-model/info-preloader-params';
import { OnDestroy } from '@angular/core';
import { unsubscribe, destroyService } from '../../core/decorators/index';
import { ReportsManagementService } from '../services/index';
import { Subscription } from 'rxjs/Subscription';
import { NotificationsService } from '../../core/components/index';
import { FileService, ModalService } from '../../common/services/index';
import { Assert } from '../../framework/index';
var ReportsComponent = /** @class */ (function () {
    function ReportsComponent(reportsManagementService, fileService, modalService, notificationService) {
        var _this = this;
        this.fileService = fileService;
        this.modalService = modalService;
        this.notificationService = notificationService;
        // private reportData: FileBlobResponse;
        this.m_reportGroups = [];
        this.reportsManagementService = reportsManagementService;
        this.state = {
            isLoading: false,
        };
        this.loadStartedSubscription = this.reportsManagementService.onReportsLoadStarted$.subscribe(function () {
            _this.state.isLoading = true;
        });
        this.saveConfigSettingSubscription = this.reportsManagementService.onReportConfigSettingSave$.subscribe(function (isLoading) {
            _this.state.isLoading = isLoading;
        });
        this.reportGenerationStartedSubscription = this.reportsManagementService.onReportGenerationStarted$.subscribe(function (report) {
            //this.state.isLoading = true;
            Assert.isNotNull(report, 'report');
            var selectedReport = _this.reportsManagementService.selectReport(report);
            var params = new InfoPreloaderParams();
            params.buttonLabel = 'Cancel Report';
            params.title = 'Running Report';
            params.firstMessage = 'Please be patient as the: ';
            params.styleMessage = selectedReport.displayName;
            params.lastMessage = ' is being run. When the report is complete - it will open in a new window';
            _this.generateReportPreloader = InfoPreloaderDialogComponent.openDialog(params, _this.modalService, function (result) {
                if (!result) {
                    // report canceled
                    _this.ignoreNextReportGenerated = true;
                }
            });
        });
        this.loadedSubscription = this.reportsManagementService.onReportsLoaded$.subscribe(function (groups) {
            _this.m_reportGroups = groups;
            _this.state.isLoading = false;
        });
        this.errorOccuredSubscription = this.reportsManagementService.onErrorOccured$.subscribe(function (error) {
            _this.state.isLoading = false;
            _this.ignoreNextReportGenerated = false;
            if (_this.generateReportPreloader)
                _this.generateReportPreloader.onCancel();
            var errorMessage = null;
            if (error.error && error.error.status === 0) {
                errorMessage = 'Report execution timeout has occured.';
            }
            else {
                errorMessage = !!error ? error.message : 'Internal error has occured.';
            }
            _this.notificationService.error(errorMessage);
            throw new Error(errorMessage);
        });
        this.reportGeneratedSubscription = this.reportsManagementService.onReportGenerated$.subscribe(function (data) {
            if (_this.ignoreNextReportGenerated) {
                _this.ignoreNextReportGenerated = false;
                return;
            }
            if (data) {
                _this.fileService.saveToFileSystem(data.blob, data.file);
            }
            _this.generateReportPreloader.onOk();
            _this.state.isLoading = false;
        });
    }
    Object.defineProperty(ReportsComponent.prototype, "reportGroups", {
        get: function () {
            return this.m_reportGroups;
        },
        enumerable: true,
        configurable: true
    });
    ReportsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ReportsComponent.prototype, "loadStartedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ReportsComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ReportsComponent.prototype, "reportGeneratedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ReportsComponent.prototype, "reportGenerationStartedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ReportsComponent.prototype, "errorOccuredSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ReportsComponent.prototype, "saveConfigSettingSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", ReportsManagementService)
    ], ReportsComponent.prototype, "reportsManagementService", void 0);
    return ReportsComponent;
}());
export { ReportsComponent };
