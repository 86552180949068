export interface IAcaExport1094CCorrection {
  executionId: string;
  qualifiedOfferMethod: boolean;
  offerMethod98: boolean;
  memberOfAleGroup: number[];
  offeredMec: number[];
}

export class AcaExport1094CCorrection {
  public executionId: string;
  public qualifiedOfferMethod: boolean;
  public offerMethod98: boolean;
  public memberOfAleGroup: number[];
  public offeredMec: number[];
}
