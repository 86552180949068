import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { PayrollExportSubmitResults } from '../../../models/index';

import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-payroll-export-result-dialog',
  templateUrl: 'payroll-export-result-dialog.component.html',
  styleUrls: ['payroll-export-result-dialog.component.scss']
})
export class PayrollExportResultDialogComponent implements IDialog {

  public options: DialogOptions;
  public dialogResult: boolean;
  public result: PayrollExportSubmitResults;
  private modalService: ModalService;

  public static openDialog(result: PayrollExportSubmitResults, modalService: ModalService, callback: () => void): PayrollExportResultDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 350;
    dialogOptions.width = 600;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: PayrollExportSubmitResults,
        useValue: result
      }
    ];

    let dialog: PayrollExportResultDialogComponent = modalService.globalAnchor
      .openDialog(PayrollExportResultDialogComponent,
      `Payrol Export Result`,
      dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
          callback();
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    result: PayrollExportSubmitResults
  ) {
    this.modalService = modalService;
    this.options = options;
    this.result = result;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
