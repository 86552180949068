import * as tslib_1 from "tslib";
import { OnDestroy } from "@angular/core";
import { PeriodsPopupModel } from '../../../models';
import { AcaMeasurementManagementService } from '../../../services/aca-measurement/aca-measurement-management.service';
import { EmployeeIdList } from '../../../models/aca-measurement/aca-measurement-state';
import * as moment from 'moment';
import { unsubscribeAll } from '../../../../core/decorators';
var ProgressbarComponent = /** @class */ (function () {
    function ProgressbarComponent(acaManagementService) {
        this.acaManagementService = acaManagementService;
        this.periodTypes = [];
        this.isExpand = false;
        this.isTodayBarShow = true;
        this.isTodayShow = false;
        this.subscriptions = {};
    }
    ProgressbarComponent.prototype.agInit = function (params) {
        this.params = params;
        this.periodTypes = params.data.periodTypes;
        this.isExpand = this.params.data.expand;
    };
    ProgressbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.state = this.acaManagementService.subscribeToCurrentYear(function (year) {
            _this.selectedYear = year;
            _this.setTodayBar();
        });
    };
    ProgressbarComponent.prototype.ngOnDestroy = function () { };
    ProgressbarComponent.prototype.refresh = function () {
        return false;
    };
    ProgressbarComponent.prototype.onExpandCollapse = function (state) {
        this.setColumnState(state);
        this.setEmployeeIdList(state);
    };
    ProgressbarComponent.prototype.setEmployeeIdList = function (state) {
        var employeeIdModel = new EmployeeIdList();
        employeeIdModel.employeeId = this.params.data.empId;
        employeeIdModel.expand = state;
        this.acaManagementService.setEmployeeIdList(employeeIdModel);
    };
    ProgressbarComponent.prototype.setColumnState = function (state) {
        this.isExpand = state;
        this.params.data.expand = state;
        if (this.params.api) {
            this.params.api.resetRowHeights();
        }
    };
    ProgressbarComponent.prototype.togglePopper = function (item, val, event) {
        var periods = new PeriodsPopupModel();
        periods.isVisible = val;
        var boxHeight = item.periodSortName === 'AP' ? 55 : item.periodSortName === 'SP' ? 65 : 115;
        var boxWidth = 200;
        periods.posLeft = (event.view.outerWidth - event.screenX > boxWidth) ? event.pageX + 5 : event.pageX - boxWidth;
        periods.posTop = (event.view.outerHeight - event.screenY > boxHeight) ? event.pageY + 5 : event.pageY - boxHeight;
        periods.acaPeriod = item;
        this.acaManagementService.togglePopper(periods);
    };
    ProgressbarComponent.prototype.setTodayBar = function () {
        var today = moment().startOf('day').toDate();
        var currentYear = today.getFullYear();
        if (currentYear == this.selectedYear) {
            this.isTodayShow = this.params.rowIndex == 0 ? true : false;
            this.isTodayBarShow = true;
            var daysInYear = moment(currentYear + "-02", "YYYY-MM").daysInMonth() == 29 ? 366 : 365;
            this.todayBarWidth = (Math.round((100 / daysInYear) * ((moment().startOf('day').dayOfYear()))));
        }
        else {
            this.isTodayShow = false;
            this.isTodayBarShow = false;
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], ProgressbarComponent.prototype, "subscriptions", void 0);
    return ProgressbarComponent;
}());
export { ProgressbarComponent };
