
export interface IAleGroup  {
  groupName: string;
  groupId: number;
  orgLevelId: number;
  deleted: boolean;
}
export class AleGroup{
  groupName: string;
  groupId: number;
  orgLevelId: number;
  deleted: boolean;
}
