import { CalendarDataService } from '../../../../core/services/index';
import { Injectable } from '@angular/core';
import { Assert } from '../../../../framework/index';
import {
  IRotationTemplateRecord,
  RotationTemplateRecord,
  IRotationTemplate,
  RotationTemplate,
  IWeeklyRotation,
  WeeklyRotation,
  IEmployeeRotation,
  EmployeeRotation,
  IEmployeeRotationRecord,
  EmployeeRotationRecord,
  IEmployeeSectionsRotations,
  EmployeeSectionsRotations,
  IEmployeeSectionsSchedule,
  EmployeeSectionsSchedule,
  IEmployeeSectionsAvailability,
  IEmployeeAvailabilityDay,
  EmployeeSectionsAvailability,
  EmployeeAvailabilityHour,
  EmployeeAvailabilityDay,
  EmployeeWeeklyAvailability,
  IEmployeeWeeklyAvailability,
  IRotationModifyRequest, RotationModifyRequest,
  IRotationModifyResponse, RotationModifyResponse
} from '../../models/index';
import {
  ShiftDefinition,
  IEmployeeShift,
  EmployeeShift
} from '../../../../organization/models/index';
import { FieldsMeta } from '../../../../core/models/index';
import { MetaMapService } from '../../../../core/services/index';
import { LookupMapService, EmployeeDefinitionsMapService, ScheduleDefinitionsMapService } from '../../../../organization/services/index';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IEmployeePositionDefinition, EmployeePositionDefinition } from '../../../../organization/models/employee/employee-position-definition';
import { WeekDayService } from './schedule-service';
import { appConfig } from '../../../../app.config';
import { dateTimeUtils } from './../../../../common/utils/dateTimeUtils';
import { DayOfWeek } from './../../../../common/models/index';


@Injectable()
export class EmployeeSectionsScheduleMapService {

  constructor(private metaMapService: MetaMapService, private lookupMapService: LookupMapService,
    private employeeDefinitionsMapService: EmployeeDefinitionsMapService, private scheduleDefinitionsMapService: ScheduleDefinitionsMapService,
    private calendarDataService: CalendarDataService) {
    Assert.isNotNull(metaMapService, 'metaMapService');
    Assert.isNotNull(lookupMapService, 'lookupMapService');
    Assert.isNotNull(employeeDefinitionsMapService, 'employeeDefinitionsMapService');
  }

  public mapToRotationTemplateDto(rotationTemplate: RotationTemplate): IRotationTemplate {
    Assert.isNotNull(rotationTemplate, 'rotationTemplate');
    return {
      rotationsCount: rotationTemplate.rotationsCount,
      rotationsStartDate: rotationTemplate.rotationsStartDate ? moment(rotationTemplate.rotationsStartDate).format(appConfig.requestDate) : null,
      employeeShift: this.mapToEmployeeShiftDto(rotationTemplate.employeeShift),
      records: rotationTemplate.records !== null && rotationTemplate.records !== undefined ? this.mapToRotationTemplateRecords(rotationTemplate.records) : null
    };
  }

  public mapToRotationTemplateRecords(templateRecords: RotationTemplateRecord[]): IRotationTemplateRecord[] {
    Assert.isNotNull(templateRecords, 'templateRecords');
    let records: IRotationTemplateRecord[] = [];
    templateRecords.forEach((r: RotationTemplateRecord) => {
      records.push(this.mapToRotationTemplateRecord(r));
    });
    return records;
  }

  public mapToRotationTemplateRecord(templateRecord: RotationTemplateRecord): IRotationTemplateRecord {
    Assert.isNotNull(templateRecord, 'templateRecord');
    return {
      daysOff: templateRecord.daysOff,
      daysOn: templateRecord.daysOn
    };
  }

  public mapModifyRotationResponse(response: IRotationModifyResponse): RotationModifyResponse {
    const data = new RotationModifyResponse();
    data.generateScheduleMessages = response.generateScheduleMessages ? this.scheduleDefinitionsMapService.mapGenerateScheduleMessages(response.generateScheduleMessages) : null;
    data.reGenerated = response.reGenerated;
    return data;
  }

  public mapModifyRotationRequestDTO(request: RotationModifyRequest): IRotationModifyRequest {
    const dto: IRotationModifyRequest = {
      rotation: this.mapToRotationsDto(request.rotation),
      reGenerateSchedule: request.reGenerateSchedule,
      regenerateScope: request.regenerateScope
    };
    return dto;
  }
  public mapToRotationsDto(rotation: EmployeeRotation): IEmployeeRotation {
    let weeklyRotations: IWeeklyRotation[] = this.mapToRotationWeeklyRecordsDto(rotation.weeklyRotations);

    return {
      archivedDate: undefined,
      avgWeeklyHours: undefined,
      isCurrent: true,
      weeklyRotations: weeklyRotations,
      rotationsCount: weeklyRotations.length
    };
  }

  public mapToRotationWeeklyRecordsDto(weeklyRotations: WeeklyRotation[]): IWeeklyRotation[] {
    Assert.isNotNull(weeklyRotations, 'rotations');

    let empRotationRecords: IWeeklyRotation[] = [];

    _.forEach(weeklyRotations, (rotation: WeeklyRotation) => {
      empRotationRecords.push(this.mapToRotationWeeklyRecordDto(rotation));
    });

    return empRotationRecords;
  }

  public mapToRotationWeeklyRecordDto(weeklyRotation: WeeklyRotation): IWeeklyRotation {
    Assert.isNotNull(weeklyRotation, 'weeklyRotation');
    let empRotationRecords: IEmployeeRotationRecord[] = this.mapToRotationRecordsDto(weeklyRotation.dailyRecords);
    return {
      isCurrentWeek: weeklyRotation.isCurrent,
      weekNum: weeklyRotation.weekNumber,
      weekStartDate: weeklyRotation.weekStartDate,
      records: empRotationRecords
    };
  }


  public mapToRotationRecordsDto(rotations: EmployeeRotationRecord[]): IEmployeeRotationRecord[] {
    Assert.isNotNull(rotations, 'rotations');

    let empRotationRecords: IEmployeeRotationRecord[] = [];

    _.forEach(rotations, (rotation: EmployeeRotationRecord) => {
      empRotationRecords.push(this.mapToRotationRecordDto(rotation));
    });

    return empRotationRecords;
  }

  public mapToRotationRecordDto(rotation: EmployeeRotationRecord): IEmployeeRotationRecord {
    Assert.isNotNull(rotation, 'rotation');

    let employeeShifts: IEmployeeShift[] = [];

    _.forEach(rotation.shifts , (shift: EmployeeShift) => {
      employeeShifts.push(this.mapToEmployeeShiftDto(shift));
    });

    return {
      dayNo: rotation.dayNumber,
      weekdayName: rotation.dayOfWeek,
      shifts: employeeShifts
    };
  }

  public mapToEmployeeShiftDto(empShift: EmployeeShift): IEmployeeShift {
    let dto: IEmployeeShift = {
      constraint: !_.isNil(empShift.constraint) ? empShift.constraint : undefined,
      position: !_.isNil(empShift.position) ? this.lookupMapService.mapPositionDto(empShift.position) : undefined,
      unit: !_.isNil(empShift.unit) ? empShift.unit : undefined,
      shift: !_.isNil(empShift.shift) ? this.lookupMapService.mapShiftDefinitionDto(empShift.shift) : undefined,
      absence: !_.isNil(empShift.absence) ? this.lookupMapService.mapScheduleAbsenceDto(empShift.absence) : undefined,
      isDirty:false
    };
    return dto;
  }

  public mapToEmployeePositions(positionsDto: IEmployeePositionDefinition[], meta: FieldsMeta): EmployeePositionDefinition[] {
    Assert.isNotNull(positionsDto, 'positionsDto');
    return this.employeeDefinitionsMapService.mapToEmployeePositions(positionsDto);
  }

  public mapAvailabilityDto(availability: EmployeeSectionsAvailability): IEmployeeSectionsAvailability {
    Assert.isNotNull(availability, 'availability');
    let availabilitySubsection: IEmployeeSectionsAvailability = {
      availabilityDateRange: this.lookupMapService.mapEmployeeAvailabilityRangeDto(availability.availabilityDateRange),
      employeeId: availability.employeeId,
      weeklyAvailability: this.mapWeeklyAvailabilityDto(availability.weeklyAvailability)
    };
    return availabilitySubsection;
  }

  public mapWeeklyAvailabilityDto(weeklyAvailability: EmployeeWeeklyAvailability): IEmployeeWeeklyAvailability {
    let employeeWeeklyAvailability: IEmployeeWeeklyAvailability = {
      monday: undefined,
      friday: undefined,
      saturday: undefined,
      sunday: undefined,
      thursday: undefined,
      tuesday: undefined,
      wednesday: undefined
    };
    let weekDays: DayOfWeek[] = this.calendarDataService.getWeekDaysList;
    _.forEach(weekDays, (day: DayOfWeek): void => {
      let employeeAvailabilityDay: EmployeeAvailabilityDay = new EmployeeAvailabilityDay();
      employeeAvailabilityDay.day = day;
      let employeeWeeklyAvailabilityDay: EmployeeAvailabilityDay = _.find(weeklyAvailability.days, (availabilityDay: EmployeeAvailabilityDay) => availabilityDay.day === day);
      this.setAvailabilityDay(employeeWeeklyAvailability, employeeWeeklyAvailabilityDay);
    });
    return employeeWeeklyAvailability;
  }

  public mapAvailability(availabilityDto: IEmployeeSectionsAvailability, meta: FieldsMeta): EmployeeSectionsAvailability {
    Assert.isNotNull(availabilityDto, 'availabilityDto');
    let availabilitySubsection: EmployeeSectionsAvailability = new EmployeeSectionsAvailability();

    availabilitySubsection.availabilityDateRange = availabilityDto.availabilityDateRange ?
      this.lookupMapService.mapEmployeeAvailabilityRange(availabilityDto.availabilityDateRange) : undefined;
    availabilitySubsection.employeeId = availabilityDto.employeeId;
    availabilitySubsection.weeklyAvailability = this.mapWeeklyAvailability(availabilityDto.weeklyAvailability);
    availabilitySubsection.actions = this.metaMapService.mapActions(meta);
    return availabilitySubsection;
  }

  public mapWeeklyAvailability(weeklyAvailability: IEmployeeWeeklyAvailability): EmployeeWeeklyAvailability {
    let employeeWeeklyAvailability: EmployeeWeeklyAvailability = new EmployeeWeeklyAvailability();
    let weekDays: DayOfWeek[] = this.calendarDataService.getWeekDaysList;
    _.forEach(weekDays, (day: DayOfWeek): void => {
      let employeeAvailabilityDay: EmployeeAvailabilityDay = new EmployeeAvailabilityDay();
      employeeAvailabilityDay.day = day;
      let availabilityDay: IEmployeeAvailabilityDay = this.getAvailabilityDay(weeklyAvailability, day);
      let hours: number[] = availabilityDay ? availabilityDay.hours : [];
      employeeAvailabilityDay.hoursAM = this.mapAvailabilityHoursAM(day, hours);
      employeeAvailabilityDay.hoursPM = this.mapAvailabilityHoursPM(day, hours);
      employeeWeeklyAvailability.days.push(employeeAvailabilityDay);
    });
    return employeeWeeklyAvailability;
  }

  public getAvailabilityDay(weeklyAvailability: IEmployeeWeeklyAvailability, day: DayOfWeek): IEmployeeAvailabilityDay {
    let propertyName = day.name.toLowerCase();
    let availabilityDay: IEmployeeAvailabilityDay = (weeklyAvailability as any)[propertyName];
    return availabilityDay;
  }

  public setAvailabilityDay(weeklyAvailability: IEmployeeWeeklyAvailability, day: EmployeeAvailabilityDay): void {
    Assert.isNotNull(weeklyAvailability, 'weeklyAvailability');
    Assert.isNotNull(day, 'day');

    let hours: number[] = this.mapHours([...day.hoursAM, ...day.hoursPM]);
    let propertyName = day.day.name.toLowerCase();
    (weeklyAvailability as any)[propertyName] = { day: day.day.name, hours: [...hours] };
  }

  public mapHours(hours: EmployeeAvailabilityHour[]): number[] {
    let mappedHours: number[] = [];
    _.forEach(hours, hour => {
      if (hour.isAvailable) {
        mappedHours.push(hour.hour);
      }
    });
    return mappedHours;
  }

  public mapAvailabilityHoursAM(day: DayOfWeek, availabilityHours: number[]): EmployeeAvailabilityHour[] {
    let mappedHours: EmployeeAvailabilityHour[] = [];
    for (let hour: number = 0; hour < 12; hour++) {
      let mappedHour: EmployeeAvailabilityHour = this.mapAvailabilityHour(day, hour, availabilityHours);
      mappedHours.push(mappedHour);
    }
    return mappedHours;
  }

  public mapAvailabilityHoursPM(day: DayOfWeek, availabilityHours: number[]): EmployeeAvailabilityHour[] {
    let mappedHours: EmployeeAvailabilityHour[] = [];
    for (let hour: number = 12; hour < 24; hour++) {
      let mappedHour: EmployeeAvailabilityHour = this.mapAvailabilityHour(day, hour, availabilityHours);
      mappedHours.push(mappedHour);
    }
    return mappedHours;
  }

  public mapAvailabilityHour(day: DayOfWeek, hour: number, availabilityHours: number[]): EmployeeAvailabilityHour {
    let mappedHour: EmployeeAvailabilityHour = new EmployeeAvailabilityHour();
    mappedHour.day = day;
    mappedHour.hour = hour;
    mappedHour.isAvailable = _.some(availabilityHours, availabilityHour => availabilityHour === hour);
    return mappedHour;
  }

  public mapToRotations(rotationsDto: IEmployeeSectionsRotations, meta: FieldsMeta): EmployeeSectionsRotations {
    Assert.isNotNull(rotationsDto, 'rotationsDto');
    let rotationsSubsection: EmployeeSectionsRotations = new EmployeeSectionsRotations();

    rotationsSubsection.homePositionId = rotationsDto.homePositionId;
    rotationsSubsection.homeShiftId = rotationsDto.homeShiftId;
    rotationsSubsection.homeUnitId = rotationsDto.homeUnitId;
    if (rotationsDto.rotations !== null && rotationsDto.rotations !== undefined) {

      let rotations: EmployeeRotation[] = [];
      let rotationInternalIdCounter: number = 0;
      rotationsDto.rotations.forEach((rotationDto: IEmployeeRotation) => {

        let rotation: EmployeeRotation = new EmployeeRotation();
        rotation.archivedDate = dateTimeUtils.convertFromDtoString(rotationDto.archivedDate);
        rotation.avgWeeklyHours = rotationDto.avgWeeklyHours;
        rotation.isCurrent = rotationDto.isCurrent;
        rotation.rotationsCount = rotationDto.rotationsCount;
        rotation.id = rotationInternalIdCounter;

        rotationDto.weeklyRotations.forEach((w: IWeeklyRotation) => {
          let weeklyRotation: WeeklyRotation = this.mapToWeeklyRotation(w);
          rotation.weeklyRotations.push(weeklyRotation);

        });
        rotations.push(rotation);
        rotationInternalIdCounter++;
      });
      rotationsSubsection.rotations = rotations;
    }
    rotationsSubsection.actions = this.metaMapService.mapActions(meta);
    return rotationsSubsection;
  }


  public mapToWeeklyRotation(weeklyRotationDto: IWeeklyRotation): WeeklyRotation {
    let weeklyRotation: WeeklyRotation = new WeeklyRotation();
    weeklyRotation.isCurrent = weeklyRotationDto.isCurrentWeek;
    weeklyRotation.weekNumber = weeklyRotationDto.weekNum;
    weeklyRotation.weekStartDate = new Date(weeklyRotationDto.weekStartDate);
    let dailyRecords: EmployeeRotationRecord[] = [];
    _.forEach(weeklyRotationDto.records, (record: IEmployeeRotationRecord) => {
      let rotation: EmployeeRotationRecord = this.mapToEmployeeRotationRecord(record, weeklyRotation);
      dailyRecords.push(rotation);
    });
    weeklyRotation.dailyRecords = dailyRecords;
    return weeklyRotation;
  }

  public mapToEmployeeRotationRecord(employeeRotationRecordDto: IEmployeeRotationRecord, weeklyRotation: WeeklyRotation): EmployeeRotationRecord {
    let employeeShifts: EmployeeShift[] = [];
    employeeRotationRecordDto.shifts.forEach((empShift: IEmployeeShift) => {
      let employeeShift: EmployeeShift = new EmployeeShift();
      employeeShift.constraint = empShift.constraint;
      employeeShift.unit = empShift.unit;
      employeeShift.shift = _.isObject(empShift.shift) ? new ShiftDefinition(empShift.shift.id, empShift.shift.name, empShift.shift.start, empShift.shift.end, empShift.shift.duration, '', null, 0) : null;
      employeeShift.position = _.isObject(empShift.position) ? this.lookupMapService.mapPosition(empShift.position) : null;
      employeeShifts.push(employeeShift);
    });
    let employeeRotationRecord: EmployeeRotationRecord = new EmployeeRotationRecord(weeklyRotation);
    employeeRotationRecord.dayNumber = employeeRotationRecordDto.dayNo;
    employeeRotationRecord.dayOfWeek = employeeRotationRecordDto.weekdayName;
    employeeRotationRecord.shifts = employeeShifts;
    return employeeRotationRecord;
  }

  public mapToSection(data: IEmployeeSectionsSchedule): EmployeeSectionsSchedule {
    let schedule: EmployeeSectionsSchedule = new EmployeeSectionsSchedule();
    schedule.rotationsSubsection = this.mapToRotations(data.rotationsSubsection.data, data.rotationsSubsection.metadata);
    schedule.availabilitySubsection = this.mapAvailability(data.availabilitySubsection.data, data.availabilitySubsection.metadata);
    return schedule;
  }
}

