export * from './timecard-paycodes-list.component';

import { TimeCardPaycodesListComponent } from './timecard-paycodes-list.component';

export const paycodesListComponents: any = [
    TimeCardPaycodesListComponent
];

export const exportPaycodesListComponents: any = [
    TimeCardPaycodesListComponent
];
