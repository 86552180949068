import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';

import { Router, ActivatedRoute } from '@angular/router';

import { appConfig, IApplicationConfig } from '../../../app.config';
import { unsubscribe } from '../../../core/decorators/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { AppSettingsManageService } from '../../../app-settings/services/index';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { LeaveRequestEntry, LeaveRequestActionPayload, LeaveRequestFilter } from '../../models/index';
import { LeaveRequestApiService } from '../../services/index';
import { UserService } from '../../../core/services/index';
import { Actions } from '../../../core/models/index';
import { OrgLevel } from '../../../state-model/models/index';

import { KendoGridStateHelper } from '../../../common/models/index';
import { IndividualScheduleNavigationService } from './../../../common/services/index';
import { LeaveRequestConfirmComponent } from '../leave-request-confirm/leave-request-confirm.component';
import { ScheduleCycleHelperService } from '../../../organization/services/index';
import { ScheduleAbsence, ScheduleCycle, AccrualBalanceDialogOptions } from '../../../organization/models/index';
import { AccrualBalanceDialogComponent } from '../../../organization/components/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

export enum ConfirmActions {
  approve = 'approve',
  deny = 'deny',
  submit = 'submit',
}

@Component({
  moduleId: module.id,
  selector: 'slx-leave-request-grid',
  templateUrl: 'leave-request-grid.component.html',
  styleUrls: ['leave-request-grid.component.scss'],
})
export class LeaveRequestGridComponent implements OnInit, OnDestroy {
  @Input('leaveRequests')
  public set incomingLeaveRequests(value: LeaveRequestEntry[]) {
    if (_.isArray(value)) {
      this.detailsEditMode = false;
      this.leaveRequests = value.slice(0);
      this.applyFiltering();
    }
  }

  @Input()
  public set filters(value: LeaveRequestFilter[]) {
    if (_.isArray(value)) {
      this.currentFilters = value;
      this.applyFiltering();
    }
  }

  @Input()
  public absencesCodes: ScheduleAbsence[];

  @Input()
  public orgLevel: OrgLevel;

  @Input()
  public actions: Actions;

  @Output()
  public onLoading: EventEmitter<boolean>;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  public currentFilters: LeaveRequestFilter[];
  public gridState: KendoGridStateHelper<LeaveRequestEntry>;
  public pageSize: number;
  public detailsEditMode: boolean;
  public actionsDefinition: StringMap<string>;
  public userCanApproveOwnLeaveRequests: boolean;
  public appConfig: IApplicationConfig;
  public xlsxName = 'Leave_Requests_';
  public pdfName = 'Leave_Requests_';
  @unsubscribe()
  private gridSubscription: Subscription;
  private leaveRequests: LeaveRequestEntry[];
  private records: LeaveRequestEntry[];
  private navService: IndividualScheduleNavigationService;
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private leaveRequestApiService: LeaveRequestApiService,
    private scheduleCycleHelperService: ScheduleCycleHelperService,
    private appSettingsManageService: AppSettingsManageService,
    private modalService: ModalService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.subscriptions.export = this.leaveRequestApiService.subscribeToExport((isPDF: boolean) => {
      this.exportTo(isPDF);
    });

    this.appConfig = appConfig;
    this.navService = new IndividualScheduleNavigationService(this.router, this.route);
    this.onLoading = new EventEmitter<boolean>();

    this.actionsDefinition = {
      [ConfirmActions.approve]: ConfirmActions.approve,
      [ConfirmActions.deny]: ConfirmActions.deny,
      [ConfirmActions.submit]: ConfirmActions.submit,
    };

    this.pageSize = 20;
    this.detailsEditMode = false;

    this.gridState = new KendoGridStateHelper<LeaveRequestEntry>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'employee.name', dir: 'asc' }];

    this.currentFilters = [];
  }

  public ngOnInit(): void {
    this.gridSubscription = this.gridState.onRefreshGrid.subscribe(() => this.refreshGrid());
    this.appSettingsManageService.getAppServerConfig().then((conf: AppServerConfig) => {
      this.userCanApproveOwnLeaveRequests = conf.userPermissions.canApproveOwnESSRequest;
    });
    const date = new Date().toLocaleDateString();
    this.xlsxName += `${date}.xlsx`;
    this.pdfName += `${date}.pdf`;
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public get dateFormat(): string {
    return '{0:MM/dd/yyyy}';
  }

  public get dateWithTimeFormat(): string {
    return '{0:MM/dd/yyyy HH:mm a}';
  }

  public getIconClass(item: LeaveRequestEntry): string {
    let cssClass: string = '';
    switch (item.status) {
      case 'Approved':
        cssClass = 'green';
        break;
      case 'Denied':
        cssClass = 'red';
        break;
      case 'Pending Approval':
        cssClass = 'yellow';
        break;
      case 'Pending Replacement':
        cssClass = 'orange';
        break;
    }

    return cssClass;
  }

  public canMakeAction(item: LeaveRequestEntry): boolean {
    return (
      item.status === 'Pending Approval' || item.status === 'Pending Replacement' || item.status === 'Submit for Review'
    );
  }

  public canApprove(item: LeaveRequestEntry): boolean {
    return this.canMakeAction(item) && this.canApproveLeaveRequest(item) && this.canApproveMyOwnLeaveRequests(item);
  }

  public canApproveLeaveRequest(item: LeaveRequestEntry): boolean {
    return item.detailsLength > 0;
  }

  public canApproveMyOwnLeaveRequests(item: LeaveRequestEntry): boolean {
    if (this.userService.isEmployeeLinkedToStoredUser(item.employee.id)) {
      return this.userCanApproveOwnLeaveRequests;
    }
    return true;
  }

  public approveRequest(item: LeaveRequestEntry, payload: LeaveRequestActionPayload): void {
    this.onLoading.emit(true);
    this.leaveRequestApiService
      .approveLeaveRequest(item.department.orgLevelId, item.id, payload)
      .then((value: number) => {
        this.onLoading.emit(false);
      })
      .catch(() => {
        this.onLoading.emit(false);
      });
  }

  public rejectRequest(item: LeaveRequestEntry, payload: LeaveRequestActionPayload): void {
    this.onLoading.emit(true);
    this.leaveRequestApiService
      .denyLeaveRequest(item.department.orgLevelId, item.id, payload)
      .then((value: number) => {
        this.onLoading.emit(false);
      })
      .catch(() => {
        this.onLoading.emit(false);
      });
  }

  public submitRequest(item: LeaveRequestEntry, payload: LeaveRequestActionPayload): void {
    this.onLoading.emit(true);
    this.leaveRequestApiService
      .submitForReviewRequest(item.department.orgLevelId, item.id, payload)
      .then((value: number) => {
        this.onLoading.emit(false);
      })
      .catch(() => {
        this.onLoading.emit(false);
      });
  }

  public onMakeRequest(item: LeaveRequestEntry, actionType: ConfirmActions): void {
    let payload: LeaveRequestActionPayload = new LeaveRequestActionPayload();
    payload.comment = item.comment;
    switch (actionType) {
      case this.actionsDefinition.approve:
        this.approveRequest(item, payload);
        break;
      case this.actionsDefinition.deny:
        this.rejectRequest(item, payload);
        break;
      case this.actionsDefinition.submit:
        this.submitRequest(item, payload);
        break;
    }
  }

  public onConfirmDialog(item: LeaveRequestEntry, actionType: ConfirmActions): void {
    let callback: (i: LeaveRequestEntry) => void = null;

    switch (actionType) {
      case this.actionsDefinition.approve:
        item.buttonText = 'Approve';
        item.header = 'Approve request';
        break;
      case this.actionsDefinition.deny:
        item.buttonText = 'Deny';
        item.header = 'Deny request';
        break;
      case this.actionsDefinition.submit:
        item.buttonText = 'Submit';
        item.header = 'Submit for review';
        break;
    }

    LeaveRequestConfirmComponent.openDialog(item, this.modalService, (result: boolean) => {
      if (result) {
        this.onMakeRequest(item, actionType);
      }

      item.comment = '';
    });
  }

  public onForwadTo(item: LeaveRequestEntry): void {
    this.onLoading.emit(true);
    const date: moment.Moment = moment(item.start);
    this.scheduleCycleHelperService
      .getScheduleCycleByDate(date.toDate(), item.department.orgLevelId)
      .then(({ startDate, endDate }: ScheduleCycle) => {
        this.navService.NavigateToIndividualScheduleEmp(
          item.employee.id,
          startDate.toDate(),
          endDate.toDate(),
          date.toDate()
        );
      });
  }

  public onShowAccruals(item: LeaveRequestEntry): void {
    const accrualEmployee: AccrualBalanceDialogOptions = new AccrualBalanceDialogOptions(
      item.employee.name,
      item.position.name,
      item.accruals
    );
    AccrualBalanceDialogComponent.openDialog(accrualEmployee, this.modalService);
  }

  public onUpdatedDetails(): void {
    this.onLoading.emit(false);
  }

  public onChangedEditState(isEdit: boolean): void {
    this.detailsEditMode = isEdit;
  }

  private applyFiltering(): void {
    if (_.isArray(this.leaveRequests) && _.isArray(this.currentFilters)) {
      this.userService.saveUser();
      this.filterRecords();
      this.refreshGrid();
    }
  }

  private filterRecords(): void {
    if (this.currentFilters.length === 0) {
      this.records = this.leaveRequests.slice(0);

      return;
    }

    const filterNames: string[] = _.map(this.currentFilters, (filter: LeaveRequestFilter) => filter.name);
    this.records = _.filter(this.leaveRequests, (record: LeaveRequestEntry) => {
      return _.indexOf(filterNames, record.status) !== -1;
    });
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.records, this.gridState.state);
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  public retriveAllPages(): () => ExcelExportData {
    return () =>
      ({
        data: process(this.getFilteredRecords(), {
          sort: this.gridState.state.sort,
          filter: this.gridState.state.filter,
        }).data,
      } as ExcelExportData);
  }

  private getFilteredRecords(): LeaveRequestEntry[] {
    let filteredRecords = this.records;
    return filteredRecords;
  }
}
