export interface IEmployeeDocumentCategory {
  description: string;
}

export class EmployeeDocumentCategory {
  public description: string;

  constructor(description?: string) {
    this.description = description;
  }
}
