import { Injectable } from '@angular/core';

import {
  IAcaReportEmployerStatus,
  AcaReportEmployerStatus
} from '../../models/index';

@Injectable()
export class AcaReportMapService {
  public mapToAcaReportEmployerStatus(dto: IAcaReportEmployerStatus): AcaReportEmployerStatus {
    const data = new AcaReportEmployerStatus();

    if (!dto) {
      return data;
    }

    data.confirmedEmployees = dto.confirmedEmployees;
    data.areAllEmployeesConfirmed = dto.areAllEmployeesConfirmed;

    return data;
  }
}
