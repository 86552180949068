import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';

import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { PayrollExportGroup, PayrollExportFacility } from '../../../models/index';
import { KendoGridStateHelper, ScrollWatchEvent } from '../../../../../common/models/index';
import { PayrollExportManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-payroll-export-grid',
  templateUrl: 'payroll-export-grid.component.html',
  styleUrls: ['payroll-export-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayrollExportGridComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public group: PayrollExportGroup;

  public appConfig: IApplicationConfig;

  public gridState: KendoGridStateHelper<PayrollExportFacility>;
  public records: PayrollExportFacility[];

  public get startDate(): Date {
    return this.managementService.startDate;
  }
  public get endDate(): Date {
    return this.managementService.endDate;
  }

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  constructor(private managementService: PayrollExportManagementService, private changeDetector: ChangeDetectorRef) {
    this.gridState = new KendoGridStateHelper<PayrollExportFacility>();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['group']) {
      this.records = this.group.facilities;
      this.refreshGrid();
    }
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}
