import { IEmployeeRosterRecord, EmployeeRosterRecord } from '../../../../app-modules/performance-management/models';
import { IEmployee, Employee } from '../../../../organization/models';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionsReviews {
    employee: IEmployee;
    canCreateNewReview: boolean;
    records: IEmployeeRosterRecord[];
}

export class EmployeeSectionsReviews extends EmployeeSectionsBase {
    public employee: Employee;
    public canCreateNewReview: boolean;
    public records: EmployeeRosterRecord[];
}
