import { OrgLevelResolver } from './services/index';
import { LogiHostComponent } from '../app-modules/logi-integration/components/logi-host.component';
import { ExportDataComponent } from '../app-modules/export-data/components/index';
import { W2sListComponent } from '../reports/components/w2s/w2s-list/w2s-list.component';
import { ApplicationContainerComponent, AuthorizessoComponent } from './components/index';
import { ReportsComponent } from '../reports/components/index';
var ɵ0 = { componentId: 'reports', accessContext: 'OrgLevel' }, ɵ1 = { componentId: 'reports', accessContext: 'OrgLevel' }, ɵ2 = { logiComponentId: 'analysis_adh', componentId: 'bi_adhoc_create_report' }, ɵ3 = { logiComponentId: 'explorer_adh', componentId: 'bi_adhoc_view_reports' }, ɵ4 = { componentId: 'export_data', accessContext: 'OrgLevel' }, ɵ5 = { componentId: 'w2_management', accessContext: 'OrgLevel' }, ɵ6 = { componentId: 'authorize' };
export var reportsRoutes = [
    {
        path: '',
        component: ApplicationContainerComponent,
        children: [
            {
                path: '',
                component: ReportsComponent,
                data: ɵ0
            },
            {
                path: 'standard_reports',
                component: ReportsComponent,
                data: ɵ1
            },
            {
                path: 'bi_adhoc_create_report',
                component: LogiHostComponent,
                data: ɵ2
            },
            {
                path: 'bi_adhoc_view_reports',
                component: LogiHostComponent,
                data: ɵ3
            },
            {
                path: 'export_data',
                resolve: [OrgLevelResolver],
                component: ExportDataComponent,
                data: ɵ4
            },
            {
                path: 'w2_management',
                resolve: [OrgLevelResolver],
                component: W2sListComponent,
                data: ɵ5
            },
            {
                path: 'sso',
                component: AuthorizessoComponent,
                data: ɵ6
            },
        ]
    },
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
