var BenefitEligibilityRulesChangeRequest = /** @class */ (function () {
    function BenefitEligibilityRulesChangeRequest(_a) {
        var rulesToAdd = _a.rulesToAdd, rulesToUpdate = _a.rulesToUpdate, rulesToDelete = _a.rulesToDelete;
        this.rulesToAdd = rulesToAdd || [];
        this.rulesToUpdate = rulesToUpdate || [];
        this.rulesToDelete = rulesToDelete || [];
    }
    return BenefitEligibilityRulesChangeRequest;
}());
export { BenefitEligibilityRulesChangeRequest };
