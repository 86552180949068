<div class="payroll-export-section">
  <div class="payroll-export-section-header">
    <div class="payroll-export-section-header-button-left">
      <slx-collapse-button [(isCollapsed)]="isCollapsed" (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent"></slx-collapse-button>
    </div>
    <div class="payroll-export-section-header-content" [ngClass]="{'submitted': group?.isAllFacilitiesRequested}">
      <div class="header-item header-title">
        <i class="fal fa-building header-title__icon regular-icon" aria-hidden="true"></i>
        <i class="fas fa-check-circle header-title__icon submitted-icon" aria-hidden="true"></i>
        <span class="header-title__text">{{group?.name}}</span>
      </div>
      <div class="header-item request-status" >
        <div class="request-status-bar-header">
          <div>
            <span *ngIf="!group?.isAllFacilitiesRequested">Export Requested</span>
            <span *ngIf="group?.isAllFacilitiesRequested" class="request-status-bar-header__desktop">All Exports Submitted</span>
            <span *ngIf="group?.isAllFacilitiesRequested" class="request-status-bar-header__mobile">All Submitted</span>
            <i class="far fa-check request-status-check" aria-hidden="true"></i>
          </div>
          <div>
            <span>{{group?.requestStatus}}</span>&nbsp;/&nbsp;<span class="facilities-length">{{group?.facilities?.length}}</span>

          </div>

        </div>
        <kendo-chart class="request-status-bar">
          <kendo-chart-axis-defaults [majorGridLines]="{ visible : false }"></kendo-chart-axis-defaults>
          <kendo-chart-area background="#dadada" [margin]="-10"></kendo-chart-area>
          <kendo-chart-value-axis>
            <kendo-chart-value-axis-item [line]="{ visible: false }" [min]="0" [max]="group?.facilities?.length" labels="false">
            </kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
          <kendo-chart-series>
            <kendo-chart-series-item type="bar" [gap]="0" [color]="getRequestColor()" [data]="[group?.requestStatus]">
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
      </div>
      <div class="header-item release-status">
        <div class="release-status__uncheck" *ngIf="!group?.isAllFacilitiesReleased">
          Pending
        </div>
        <div class="release-status__check" *ngIf="group?.isAllFacilitiesReleased">
          <span class="release-status__check_text">File Released</span>
          <i class="fas fa-check-circle" aria-hidden="true"></i>
        </div>
      </div>
      <div class="header-item release-button">
        <button type="button" class="slx-button slx-with-icon" [disabled]="!isExportAvailable()" (click)="onReleaseToPayroll()">
          <i class="far fa-paper-plane slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Release To Payroll</span>
        </button>
        <button *ngIf="group?.canCancelRelease && group?.isAllFacilitiesReleased" type="button" class="slx-button slx-with-icon"
          (click)="onCancelRelease()">
          <i class="far fa-paper-plane slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Cancel Release</span>
        </button>
      </div>
    </div>
    <div class="payroll-export-section-header-button-right">
      <slx-collapse-button [(isCollapsed)]="isCollapsed" (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent"></slx-collapse-button>
    </div>
  </div>
  <div #collapseContent class="payroll-export-section-content">
    <slx-payroll-export-grid *ngIf="!isMobile" [group]="group"></slx-payroll-export-grid>
    <slx-payroll-export-mobile-list *ngIf="isMobile" [group]="group"></slx-payroll-export-mobile-list>

  </div>
</div>
