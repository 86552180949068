import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges, DoCheck, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TreeNodeComponent } from '../../../tree/index';
import { ComparisionOperator, DataType } from '../../models/logical-expression.model';
import { QueryModel } from '../../models/query-model';
import { SearchOperator } from '../../models/search-operator.model';
import * as _ from 'lodash';
import * as moment from 'moment';
var ExpressionSelectorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ExpressionSelectorComponent, _super);
    function ExpressionSelectorComponent(changeDetector) {
        var _this = _super.call(this) || this;
        _this.changeDetector = changeDetector;
        return _this;
    }
    ExpressionSelectorComponent.prototype.ngOnInit = function () {
        var nodeData = this.node.data;
        var queryModel = this.node.tree;
        this.currentCategories = queryModel.categories;
        this.formGroup = nodeData.formGroup;
        var expression = nodeData.expression;
        var formGroupCategory = this.formGroup.get('selectedCategory').value;
        if (!formGroupCategory) {
            this.initFromExpression(expression);
        }
        else {
            this.setSelectedCategory(formGroupCategory.categoryFieldName);
            this.updateExpression();
            this.initFromExpression(expression);
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ExpressionSelectorComponent.prototype.ngOnChanges = function (changes) {
        //
    };
    ExpressionSelectorComponent.prototype.ngDoCheck = function () {
        var queryModel = this.node.tree;
        if (queryModel.categories !== this.currentCategories) {
            this.currentCategories = queryModel.categories;
            var nodeData = this.node.data;
            var expression = nodeData.expression;
            var formGroupCategory = this.formGroup.get('selectedCategory').value;
            if (!formGroupCategory) {
                this.initFromExpression(expression);
            }
            else {
                this.setSelectedCategory(formGroupCategory.categoryFieldName);
                this.updateExpression();
                this.initFromExpression(expression);
            }
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        }
    };
    ExpressionSelectorComponent.prototype.onCategoryChanged = function (value) {
        var nodeData = this.node.data;
        var expression = nodeData.expression;
        var queryModel = this.node.tree;
        this.selectedCategory = value;
        if (this.selectedCategory) {
            this.setOperatorsFromCategory();
            this.setSelectedOperator(QueryModel.getDefaultOperator(this.selectedCategory.operators));
            this.formGroup.get('selectedOperator').setValue(this.selectedOperator);
            this.setDefaultValues();
            this.updateFormGroupValues();
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ExpressionSelectorComponent.prototype.onOperatorChanged = function (value) {
        var nodeData = this.node.data;
        var expression = nodeData.expression;
        this.selectedOperator = value;
        this.setDefaultValues();
        this.updateFormGroupValues();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ExpressionSelectorComponent.prototype.onValueChange = function () {
        this.updateExpression();
    };
    ExpressionSelectorComponent.prototype.initFromExpression = function (expression) {
        if (expression && expression.searchPredicate && expression.searchPredicate.searchCategoryName) {
            this.setSelectedCategory(expression.searchPredicate.searchCategoryName);
        }
        else if (this.currentCategories) {
            this.selectedCategory = this.currentCategories[0];
        }
        if (this.selectedCategory) {
            this.setOperatorsFromCategory();
            this.setSelectedOperator(expression.searchPredicate.searchOperator || QueryModel.getDefaultOperator(this.selectedCategory.operators));
            this.firstValue = null;
            this.secondValue = null;
            if (expression.searchPredicate.values) {
                if (expression.searchPredicate.values.length > 0)
                    this.firstValue = expression.searchPredicate.values[0];
                if (expression.searchPredicate.values.length > 1)
                    this.secondValue = expression.searchPredicate.values[1];
            }
            this.firstDate = false;
            this.secondDate = false;
            if (!this.firstValue) {
                if (this.checkDataType(this.selectedCategory.dataType, DataType.Date)) {
                    this.firstValue = moment().startOf('day').toDate();
                    this.firstDate = true;
                }
            }
            else {
                this.firstDate = this.checkDataType(this.selectedCategory.dataType, DataType.Date);
            }
            if (!this.secondValue) {
                if (this.checkOperator(this.selectedOperator, ComparisionOperator.Between) && this.checkDataType(this.selectedCategory.dataType, DataType.Date)) {
                    this.secondValue = moment().startOf('day').toDate();
                    this.secondDate = true;
                }
            }
            else {
                this.secondDate = this.checkDataType(this.selectedCategory.dataType, DataType.Date);
            }
            this.hasSecondValue = this.secondValue !== null;
            this.updateFormGroupCategory();
            this.updateFormGroupValues();
        }
    };
    ExpressionSelectorComponent.prototype.setSelectedCategory = function (searchCategoryName) {
        this.selectedCategory = _.find(this.currentCategories, function (category) {
            return category.categoryFieldName === searchCategoryName;
        });
        if (!this.selectedCategory && this.currentCategories) {
            this.selectedCategory = this.currentCategories[0];
        }
    };
    ExpressionSelectorComponent.prototype.setOperatorsFromCategory = function () {
        var operators = [];
        _.each(this.selectedCategory.operators, function (operator) {
            var o = new SearchOperator();
            o.displayName = operator;
            var operatorEnum = ComparisionOperator;
            o.operatorId = operatorEnum[operator];
            operators.push(o);
        });
        this.operators = operators;
    };
    ExpressionSelectorComponent.prototype.setSelectedOperator = function (searchOperator) {
        this.selectedOperator = _.find(this.operators, function (operator) {
            return operator.displayName === searchOperator;
        });
    };
    ExpressionSelectorComponent.prototype.checkOperator = function (value, operator) {
        return value.operatorId === operator;
    };
    ExpressionSelectorComponent.prototype.checkDataType = function (value, dataType) {
        return value === DataType[dataType];
    };
    ExpressionSelectorComponent.prototype.updateFormGroupCategory = function () {
        this.formGroup.get('selectedCategory').setValue(this.selectedCategory);
        this.formGroup.get('selectedOperator').setValue(this.selectedOperator);
    };
    ExpressionSelectorComponent.prototype.updateFormGroupValues = function () {
        if (this.selectedCategory) {
            this.formGroup.get('firstValue').setValue(this.firstValue);
            if (this.hasSecondValue) {
                this.formGroup.get('secondValue').setValue(this.secondValue);
            }
            else {
                this.formGroup.get('secondValue').setValue(null);
            }
        }
        else {
            this.formGroup.get('firstValue').setValue(null);
            this.formGroup.get('secondValue').setValue(null);
        }
    };
    ExpressionSelectorComponent.prototype.updateExpression = function () {
        var nodeData = this.node.data;
        var expression = nodeData.expression;
        var firstValue;
        var secondValue;
        if (this.selectedCategory) {
            expression.searchPredicate.searchCategoryName = this.selectedCategory.categoryFieldName;
            var selectedOperator = this.formGroup.get('selectedOperator').value;
            expression.searchPredicate.searchOperator = selectedOperator.displayName;
            firstValue = this.formGroup.get('firstValue').value;
            if (this.firstDate) {
                firstValue = moment(firstValue).startOf('day').toDate();
            }
            expression.searchPredicate.values = [firstValue];
            secondValue = this.formGroup.get('secondValue').value;
            if (this.hasSecondValue) {
                if (this.secondDate) {
                    secondValue = moment(secondValue).startOf('day').toDate();
                }
                expression.searchPredicate.values.push(secondValue);
            }
        }
    };
    ExpressionSelectorComponent.prototype.setDefaultValues = function () {
        if (this.checkDataType(this.selectedCategory.dataType, DataType.Date)) {
            this.firstValue = moment().startOf('day').toDate();
            this.firstDate = true;
        }
        else {
            this.firstValue = null;
            this.firstDate = false;
        }
        if (this.checkOperator(this.selectedOperator, ComparisionOperator.Between)) {
            this.hasSecondValue = true;
        }
        else {
            this.hasSecondValue = false;
        }
        if (this.hasSecondValue) {
            if (this.checkDataType(this.selectedCategory.dataType, DataType.Date)) {
                this.secondValue = moment().startOf('day').toDate();
                this.secondDate = true;
            }
            else {
                this.secondValue = null;
                this.secondDate = false;
            }
        }
        else {
            this.secondValue = null;
            this.secondDate = false;
        }
    };
    return ExpressionSelectorComponent;
}(TreeNodeComponent));
export { ExpressionSelectorComponent };
