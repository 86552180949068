import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { LookupMapService } from '../../../organization/services/index';
import { IStaffingRatingContainerDTO, StaffingRatingContainer, FiveStarRatingContainer, IFiveStarRatingContainerDTO } from '../models/staffing-rating-container';
import { StaffingRatingOrganization, IStaffingRatingOrganizationDTO, IFiveStarRatingOrganizationDTO, FiveStarRatingOrganization } from '../models/staffing-rating-organization';
import { StaffingRating } from '../models/staffing-rating';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import {
    IStaffingPredictorOrganizationInfoDTO, StaffingPredictorOrganizationInfo,
    StaffingOptionsTable, IStaffingOptionsTableDTO, IStaffingPredictorRatingOptionDTO,
    StaffingPredictorRatingOption, StaffingRatingRequest, IStaffingRatingRequest, StaffingOrganizationInfoRequest,
    IStaffingOrganizationInfoRequest, IStaffingPointsTableDto, StaffingPointsTable, StaffingPointsTableRow,
    IStaffingPointsTableRowDto, StaffingPointsTableCell, IStaffingPointsTableCellDto, FiveStarPredictorOrganizationInfo, IFiveStarPredictorOrganizationInfoDTO
} from '../models/index';
import { appConfig } from '../../../app.config';
import {  GoalSettingRequest, GoalSettingResponse, IGoalSettingRequest } from '../models/goal-setting';
import { ISaveGoalRequest, SaveGoalRequest ,SaveGoalResponse} from '../models/save-goal';

@Injectable()
export class StaffingPredictorMapService {

    constructor (private lookupMapService: LookupMapService) { }

    public mapToOrganizationInfo(dto: IStaffingPredictorOrganizationInfoDTO): StaffingPredictorOrganizationInfo {
        const info: StaffingPredictorOrganizationInfo = new StaffingPredictorOrganizationInfo();
        info.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
        info.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
        info.organization = this.mapToStaffingRatingOranization(dto.organization, dto.maxRatingValue);
        info.optionsTable = dto.optionsTable != null ? this.mapToStaffingOptionsTable(dto.optionsTable, dto.maxRatingValue) : null;
        return info;
    }

    public mapToFiveStarOrganizationInfo(dto: IFiveStarPredictorOrganizationInfoDTO): FiveStarPredictorOrganizationInfo {
        const info: FiveStarPredictorOrganizationInfo = new FiveStarPredictorOrganizationInfo();
        info.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
        info.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
        info.organization = this.mapToFiveStarRatingOranization(dto.organization, dto.maxRatingValue);
        return info;
    }

    public mapToStaffingOptionsTable(dto: IStaffingOptionsTableDTO, maxValue: number): StaffingOptionsTable {
        const table: StaffingOptionsTable = new StaffingOptionsTable();
        table.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
        table.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
        let daysCount = moment(table.endDate).diff(table.startDate, 'days');
        table.organizationId = dto.organizationId;
        table.options = _.map(dto.options, (optionDto: IStaffingPredictorRatingOptionDTO) => {
            return this.mapToStaffinPredictorRatingOption(optionDto, maxValue, daysCount);
        });
        return table;
    }

    public mapToStaffinPredictorRatingOption(dto: IStaffingPredictorRatingOptionDTO, maxValue: number, daysCount: number): StaffingPredictorRatingOption {
        const option: StaffingPredictorRatingOption = new StaffingPredictorRatingOption();
        const rating: StaffingRating = new StaffingRating();
        rating.value = dto.rating;
        rating.maxValue = maxValue;
        option.rating = rating;
        let totalsDailyAvg = dto.totalHours / daysCount;
        let rnDailyAvg = dto.rnHours / daysCount;
        option.rnHours = _.clamp(rnDailyAvg, 8, Number.MAX_VALUE);
        option.rnHoursString = (option.rnHours).toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })
        option.totalHours = _.clamp(totalsDailyAvg > option.rnHours ? totalsDailyAvg : option.rnHours, 8, Number.MAX_VALUE);
        option.totalHoursString = option.totalHours.toLocaleString(undefined, { 'minimumFractionDigits': 2, 'maximumFractionDigits': 2 })
        return option;
    }

    public mapToStaffingRatingContainer(dto: IStaffingRatingContainerDTO): StaffingRatingContainer {
        const container = new StaffingRatingContainer();
        container.ratingMaxValue = dto.maxRatingValue;
        container.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
        container.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
        container.organizations = this.mapToStaffingRatingOranizations(dto.organizations, container.ratingMaxValue);
        container.organizations = _.sortBy(container.organizations, org => org.organization.name.toUpperCase());
        container.pointTables = this.mapToStaffingRatingPointTables(dto.pointTables);
        return container;
    }

    public mapToFiveStarRatingContainer(dto: IFiveStarRatingContainerDTO): FiveStarRatingContainer {
        const container = new FiveStarRatingContainer();
        container.ratingMaxValue = dto.maxRatingValue;
        container.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
        container.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
        container.organizations = this.mapToFiveStarRatingOranizations(dto.organizations, container.ratingMaxValue);
        container.organizations = _.sortBy(container.organizations, org => org.organization.name.toUpperCase());
        return container;
    }

    public mapToStaffingRatingPointTables(dtos: IStaffingPointsTableDto[]): StaffingPointsTable[] {
        return _.map(dtos, dto => this.mapStaffingPointsTable(dto));
    }

    public mapStaffingPointsTable(dto: IStaffingPointsTableDto): StaffingPointsTable {
        const table = new StaffingPointsTable();
        table.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
        table.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
        table.rows = this.mapStaffingPointsTableRows(dto.rows);
        let startDateStr = table.startDate ? moment(table.startDate).format(appConfig.fullMonthYearFormat) : '';
        let endDateStr = table.endDate ? moment(table.startDate).format(appConfig.fullMonthYearFormat) : 'Current';
        table.label = `${startDateStr} - ${endDateStr}`;
        return table;
    }

    public mapStaffingPointsTableRows(dtos: IStaffingPointsTableRowDto[]): StaffingPointsTableRow[] {
        return _.map(dtos, dto => this.mapStaffingPointsTableRow(dto));
    }

    public mapStaffingPointsTableRow(dto: IStaffingPointsTableRowDto): StaffingPointsTableRow {
        const row = new StaffingPointsTableRow();
        row.lowLimitRn = dto.lowLimitRn;
        row.highLimitRn = dto.highLimitRn;
        let displayedHighValue = row.lowLimitRn > 0 ? row.highLimitRn : row.highLimitRn + 0.001;
        row.rangeString = this.mapValuesToRangeStrting(row.lowLimitRn, displayedHighValue);
        row.cells = this.mapStaffingPointsTableCells(dto.cells);
        return row;
    }

    public mapStaffingPointsTableCells(dtos: IStaffingPointsTableCellDto[]): StaffingPointsTableCell[] {
        return _.map(dtos, dto => this.mapStaffingPointsTableCell(dto));
    }

    public mapStaffingPointsTableCell(dto: IStaffingPointsTableCellDto): StaffingPointsTableCell {

        const cell = new StaffingPointsTableCell();
        cell.lowLimitTotal = dto.lowLimitTotal;
        cell.highLimitTotal = dto.highLimitTotal;
        cell.rating = dto.rating;

        let displayedHighValue = cell.lowLimitTotal > 0 ? cell.highLimitTotal : cell.highLimitTotal + 0.001;
        cell.rangeString = this.mapValuesToRangeStrting(cell.lowLimitTotal, displayedHighValue);
        cell.ratingArray = Array(cell.rating).fill(1);

        return cell;
    }

    public mapValuesToRangeStrting(low: number, high: number): string {
        let lowStr = low > 0 ? this.toDecimalsStr(low, 3) : '';
        let hightStr = high > 0 ? this.toDecimalsStr(high, 3) : '';
        let str: string = '';
        if (lowStr !== '' && hightStr !== '') {
            str = `${lowStr} - ${hightStr}`;
        } else if (lowStr !== '') {
            str = `>= ${lowStr}`;
        } else if (hightStr !== '') {
            str = `< ${hightStr}`;
        } else {
            str = ' - ';
        }
        return str;
    }

    public mapToStaffingRatingOranizations(dtos: IStaffingRatingOrganizationDTO[], maxValue: number): StaffingRatingOrganization[] {
        return _.map(dtos, (dto: IStaffingRatingOrganizationDTO) => {
            return this.mapToStaffingRatingOranization(dto, maxValue);
        });
    }

    public mapToFiveStarRatingOranizations(dtos: IFiveStarRatingOrganizationDTO[], maxValue: number): FiveStarRatingOrganization[] {
        return _.map(dtos, (dto: IFiveStarRatingOrganizationDTO) => {
            return this.mapToFiveStarRatingOranization(dto, maxValue);
        });
    }

    public mapToFiveStarRatingOranization(dto: IFiveStarRatingOrganizationDTO, maxValue: number): FiveStarRatingOrganization {
        const organization: FiveStarRatingOrganization = new FiveStarRatingOrganization();
        organization.organization = this.lookupMapService.mapOrganization(dto.fiveStarOrganization);
        const rating: StaffingRating = new StaffingRating();
        rating.maxValue = maxValue;
        organization.rating = rating;
        organization.noCcn = dto.noCcn;
        organization.noCensus = dto.noCensus;
        organization.noCmsData = dto.noCmsData;
        organization.noHoursData = dto.noHoursData;
        organization.rnZeroDays = dto.rnZeroDays;
        organization.adjustedTotalNurseHRD = dto.adjustedTotalNurseHRD;
        organization.adjustedRnHRD = dto.adjustedRnHRD;
        organization.adjustedTotalNurseOnWeekendHRD = dto.adjustedTotalNurseOnWeekendHRD;
        organization.noStaffingRatingPastRatedPeriodInfo = dto.noStaffingRatingPastRatedPeriodInfo;
        organization.noCaseMixTotalNurseHoursInfo = dto.noCaseMixTotalNurseHoursInfo;
        organization.noCaseMixRNHoursInfo = dto.noCaseMixRNHoursInfo;
        organization.noRnTurnoverInfo = dto.noRnTurnoverInfo;
        organization.noTotalNurseTurnoverInfo = dto.noTotalNurseTurnoverInfo;
        organization.noAdministratorDeparturesInfo = dto.noAdministratorDeparturesInfo;
        // if (organization.noCcn || organization.noCensus || organization.noCmsData || organization.noHoursData) {
        //     rating.value = 0;
        // } else if (organization.rnZeroDays) {
        //     rating.value = 1;
        // } else {
             rating.value = dto.staffingRating;
        // }

        // organization.lastUpdateFromCms = dateTimeUtils.convertFromDtoString(dto.lastUpdateFromCms);
        // organization.rnHoursPoints = dto.rnHoursPoints;
        // organization.totalHoursPoints = dto.totalHoursPoints;
        // organization.outdatedData = dto.outdatedData;

        organization.avgDailyCensus = dto.avgDailyCensus;
        organization.avgDailyCensusWeekEnd = dto.avgDailyCensusWeekEnd;
        organization.totalNursingHRD = dto.reportedTotalNursingHRD;
        organization.adjustedTotalNurseHRDPoints = dto.adjustedTotalNurseHRDPoints;
        organization.adjustedRnHRDPoints = dto.adjustedRnHRDPoints;
        organization.adjustedTotalNurseOnWeekendHRDPoints = dto.adjustedTotalNurseOnWeekendHRDPoints;
        organization.administratorDeparturesPoints = dto.administratorDeparturesPoints;
        organization.totalNursingTurnOverPoints = dto.totalNursingTurnOverPoints;
        organization.rnHRD = dto.reportedRnHRD;
        organization.totalNursingHRDWeekEnd = dto.reportedTotalNursingHRDWeekEnd;
        organization.totalNursingTurnOver = dto.totalNursingTurnOver;
        organization.rnTurnOver = dto.rnTurnOver;
        organization.rnTurnOverPoints = dto.rnTurnOverPoints;
        organization.administratorDepartures = dto.administratorDepartures;
        organization.totalNursingAvgDailyHours = dto.totalNursingAvgDailyHours;
        organization.totalNursingAvgDailyHoursWeekEnd = dto.totalNursingAvgDailyHoursWeekEnd;
        organization.rNAvgDailyHours = dto.rnAvgDailyHours;
        return organization;
    }

    public mapToStaffingRatingOranization(dto: IStaffingRatingOrganizationDTO, maxValue: number): StaffingRatingOrganization {
        const organization: StaffingRatingOrganization = new StaffingRatingOrganization();
        organization.organization = this.lookupMapService.mapOrganization(dto.organization);
        const rating: StaffingRating = new StaffingRating();
        rating.maxValue = maxValue;
        organization.rating = rating;
        organization.noCcn = dto.noCcn;
        organization.noCensus = dto.noCensus;
        organization.noCmsData = dto.noCmsData;
        organization.noHoursData = dto.noHoursData;
        organization.rnZeroDays = dto.rnZeroDays;

        if (organization.noCcn || organization.noCensus || organization.noCmsData || organization.noHoursData) {
            rating.value = 0;
        } else if (organization.rnZeroDays) {
            rating.value = 1;
        } else {
            rating.value = dto.rating;
        }

        organization.lastUpdateFromCms = dateTimeUtils.convertFromDtoString(dto.lastUpdateFromCms);
        organization.rnHoursPoints = dto.rnHoursPoints;
        organization.totalHoursPoints = dto.totalHoursPoints;
        organization.outdatedData = dto.outdatedData;
        return organization;
    }

    public mapToStaffinRatingRequestDto(r: StaffingRatingRequest): IStaffingRatingRequest {
        return {
            startDate: dateTimeUtils.convertToDtoString(r.startDate),
            endDate: dateTimeUtils.convertToDtoString(r.endDate),
            organizationIds: r.organizationIds
        };
    }

    public mapToStaffinRatingOrganizationInfoRequestDto(r: StaffingOrganizationInfoRequest): IStaffingOrganizationInfoRequest {
        return {
            startDate: dateTimeUtils.convertToDtoString(r.startDate),
            endDate: dateTimeUtils.convertToDtoString(r.endDate),
            organizationId: r.organizationId
        };
    }

    private toDecimalsStr(n: number, d: number): string {
        if ((typeof n !== 'number') || (typeof d !== 'number')) return '';
        n = parseFloat(n.toString()) || 0;
        return n.toFixed(d);
    }

    public mapGoalSectionReq( req: GoalSettingRequest): IGoalSettingRequest{
        return {
            startDate: dateTimeUtils.convertToDtoString(req.startDate),
            endDate: dateTimeUtils.convertToDtoString(req.endDate),
            organizationId: req.organizationId,
            numberOfStars : req.numberOfStars,

        };
    }
    public mapGoalSectionResponse(response: GoalSettingResponse){
        const goalSettingResult = new GoalSettingResponse();
        goalSettingResult.avgDailyCensus    = response.avgDailyCensus;
        goalSettingResult.avgDailyCensusWeekEnd= response.avgDailyCensusWeekEnd;
        goalSettingResult.totalNursingAvgDailyHours = response.totalNursingAvgDailyHours;
        goalSettingResult.adjustedTotalNurseHRD = response.adjustedTotalNurseHRD;
        goalSettingResult.rnAvgDailyHours = response.rnAvgDailyHours;
        goalSettingResult.totalNursingAvgDailyHoursWeekEnd = response.totalNursingAvgDailyHoursWeekEnd;
        goalSettingResult.adjustedTotalNurseHRDPoints =response.adjustedTotalNurseHRDPoints; 
        goalSettingResult.adjustedRnHRD = response.adjustedRnHRD;
        goalSettingResult.adjustedRnHRDPoints = response.adjustedRnHRDPoints;
        goalSettingResult.adjustedTotalNurseOnWeekendHRDPoints = response.adjustedTotalNurseOnWeekendHRDPoints;
        goalSettingResult.reportedTotalNursingHRD = response.reportedTotalNursingHRD;
        goalSettingResult.reportedTotalNursingHRDWeekEnd=response.reportedTotalNursingHRDWeekEnd;
        goalSettingResult.reportedRnHRD = response.reportedRnHRD;
        goalSettingResult.adjustedTotalNurseOnWeekendHRD = response.adjustedTotalNurseOnWeekendHRD;
        goalSettingResult.totalNursingTurnOver = response.totalNursingTurnOver;
        goalSettingResult.totalNursingTurnOverPoints = response.totalNursingTurnOverPoints;
        goalSettingResult.rnTurnOver = response.rnTurnOver;
        goalSettingResult.administratorDepartures = response.administratorDepartures;
        goalSettingResult.rnTurnOverPoints =response.rnTurnOverPoints;
        goalSettingResult.administratorDeparturesPoints = response.administratorDeparturesPoints;
        goalSettingResult.caseMixTotalNurse = response.caseMixTotalNurse;
        goalSettingResult.caseMixRN = response.caseMixRN;
        goalSettingResult.caseMixTotalNurseWeekend = response.caseMixTotalNurseWeekend;
        goalSettingResult.nationalCaseMixTotalNurse = response.nationalCaseMixTotalNurse;
        goalSettingResult.nationalCaseMixRN = response.nationalCaseMixRN;
        goalSettingResult.nationalCaseMixTotalNurseWeekend = response.nationalCaseMixTotalNurseWeekend;
        goalSettingResult.staffingRating=response.staffingRating;
        return goalSettingResult;
    }

    public mapGoalresponse(response: SaveGoalResponse) {
        const result= response;
        return result;
    }
    public mapSaveGoalReq(req: SaveGoalRequest): ISaveGoalRequest {
        let goalReq: ISaveGoalRequest = <ISaveGoalRequest>{};
        goalReq.startDate = moment(req.startDate).format('MM/DD/YYYY').toString();
        goalReq.endDate = moment(req.endDate).format('MM/DD/YYYY').toString();
        goalReq.organizationId = req.organizationId;
        goalReq.numberOfStars = req.numberOfStars;
        goalReq.avgDailyCensus= req.avgDailyCensus;
        goalReq.avgDailyCensusWeekEnd =req.avgDailyCensusWeekEnd;
        goalReq.totalNursingAvgDailyHours= req.totalNursingAvgDailyHours;
        goalReq.adjustedTotalNurseHRD = req.adjustedTotalNurseHRD;
        goalReq.adjustedTotalNurseHRDPoints= req.adjustedTotalNurseHRDPoints ;
        goalReq.rnAvgDailyHours= req.
rnAvgDailyHours; 
        goalReq.adjustedRnHRD= req.adjustedRnHRD ;
        goalReq.adjustedRnHRDPoints= req.adjustedRnHRDPoints;
        goalReq.totalNursingAvgDailyHoursWeekEnd = req.totalNursingAvgDailyHoursWeekEnd;
        goalReq.adjustedTotalNurseOnWeekendHRD = req.adjustedTotalNurseOnWeekendHRD;
        goalReq.adjustedTotalNurseOnWeekendHRDPoints = req.adjustedTotalNurseOnWeekendHRDPoints;
        goalReq.totalNursingTurnOver = req.totalNursingTurnOver;
        goalReq.rnTurnOver= req.rnTurnOver;
        goalReq.administratorDepartures= req.administratorDepartures;
        goalReq.totalNursingTurnOverPoints = req.totalNursingTurnOverPoints;
        goalReq.rnTurnOverPoints = req.rnTurnOverPoints;
        goalReq.administratorDeparturesPoints = req.administratorDeparturesPoints;
        goalReq.caseMixRN = req.caseMixRN ;
        goalReq.caseMixTotalNurseWeekend = req.caseMixTotalNurseWeekend;
        goalReq.caseMixTotalNurse = req.caseMixTotalNurse;
        return goalReq;
    }
}
