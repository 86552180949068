import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { ApiUtilService, UrlParamsService } from '../../../common/services/index';

import { appConfig } from '../../../app.config';
import { W2sMapService } from './w2s-map.service';
import { reportsConfig } from '../../reports.config';
import { FieldsMeta, ResponseBody } from '../../../../app/core/models';
import { FileBlobResponse } from '../../../../app/core/models/api/file-blob-response';
import { ICompanyCounts } from '../../models/w2s/w2s-model';



@Injectable()
export class W2sApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private mapService: W2sMapService,
    private urlParamsService: UrlParamsService
  ) { }

    public async publishRecords(orgLevelId: number,year:number,Ids:number[]): Promise<boolean> 
    {
        const url: string = `${this.getW2sRootApi()}/${reportsConfig.api.w2document.root}/orglevel/${orgLevelId}/year/${year}`;
        const request = this.urlParamsService.createPutRequest(url, Ids);
        
        return this.apiUtilService
          .request<any, FieldsMeta>(request)
          .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapW2sPublishRecords(response.data));
    }

    public async getW2sRecords(orgLevelId: number,year:number): Promise<any> {       

    const url: string = `${this.getW2sRootApi()}/${reportsConfig.api.w2document.root}/orglevel/${orgLevelId}/year/${year}/all`;
    const request = this.urlParamsService.createGetRequest(url, {
     
    });

    return this.apiUtilService
      .request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapW2sRecords(response.data, response.meta));
  }

  public async unPublishRecords(orgLevelId: number,year:number,ids:number[]): Promise<boolean> 
  {
      const url: string = `${this.getW2sRootApi()}/${reportsConfig.api.w2document.root}/unpublish/orglevel/${orgLevelId}/year/${year}`;
      const request = this.urlParamsService.createPutRequest(url, ids);
      
      return this.apiUtilService
        .request<any, FieldsMeta>(request)
        .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapW2sPublishRecords(response.data));
  }
  public downloadW2Document(exportId: number): Promise<FileBlobResponse> {
    const url = `${this.getW2sRootApi()}/${reportsConfig.api.w2document.root}/export/${exportId}`;
    const request = this.urlParamsService.createGetRequest(url, { });
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }
  
  public async getCompanyCounts(orgLevelId: number,year:number): Promise<any> 
  {
      const url: string = `${this.getW2sRootApi()}/${reportsConfig.api.w2document.root}/companycounts/orglevel/${orgLevelId}/year/${year}`;
      const request = this.urlParamsService.createGetRequest(url, {});
      
      return this.apiUtilService
      .request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapCompanyCounts(response.data, response.meta));
  }
  
  private getW2sRootApi(): string {
    return `${this.getApiRoot()}/${reportsConfig.api.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
