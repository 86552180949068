<table class="table" aria-describedby="employeeShiftDetails">
  <thead>
    <tr>
      <th class="user-image" id="employeeName"></th>
      <th *ngFor="let column of columns | filter:{'access':'read', name: displayFileds }" id="employeeShiftDetails" class="column-header">{{column.displayName}}</th>
      <th id="addRemoveEmployee"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of filteredRows" slxElementCreatedSelector [emitEvent]="isSelectedEmployee(row)" (onCreated)="onTRCreated($event)">
      <td class="user-image">
        <div class="circular-portrait" *ngIf="!isEmptyEmployee(row)" [ngClass]="{'employee-ot-image-border': isOvertime(row) && !isEmptyEmployee(row)}">
          <img class="rounded-image" [employeeThumbinalSrc]="getEmployeeId(row)" alt="Employee Photo" />
        </div>
        <span class="clickable fa-stack fa employee-ot-icon" *ngIf="isOvertime(row) && !isEmptyEmployee(row)">
            <i class="fa fa-circle fa-stack-2x employee-ot-icon-back" aria-hidden="true"></i>
            <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
            <span class="fa-stack-1x overtime-criteria">OT</span>
        </span>
      </td>
      <td *ngFor="let column of columns | filter:{'access':'read', name: displayFileds}" class="desktop">
        <span *ngIf="column.name !== 'EmpName' && (column.name !== 'ShiftName' || isEmptyEmployee(row))">{{getFieldValue(row, column)}}</span>
        <a *ngIf="column.name === 'EmpName'" [employeeSectionLink]="getEmployeeId(row)" (click)="selectEmployee(row)">{{getFieldValue(row, column)}}</a>
        <a *ngIf="column.name === 'ShiftName' && !isEmptyEmployee(row)" (click)="openShift(row)">{{getFieldValue(row, column)}}</a>
      </td>
      <td class="mobile">
        <div *ngFor="let column of columns | filter:{name: restrcitedMobileFields}" [ngClass]="{'name-label':column.name === 'EmpName'}">
          <span *ngIf="column.name !== 'EmpName' && (column.name !== 'ShiftName' || isEmptyEmployee(row))">{{getFieldValue(row, column)}}</span>
          <a *ngIf="column.name === 'EmpName'" [employeeSectionLink]="getEmployeeId(row)" (click)="selectEmployee(row)" class="short-name">{{getFieldValue(row, column)}}</a>
          <a *ngIf="column.name === 'ShiftName' && !isEmptyEmployee(row)" (click)="openShift(row)">{{getFieldValue(row, column)}}</a>
        </div>
      </td>
      <td>
        <div class="actions">
          <div *ngIf="!isEmptyEmployee(row)" class="dropdown icon-action-menu btn-sizes">
            <button class="btn btn-default dropdown-toggle elipsis-button" type="button" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="true">
                        <i class="fa fa-ellipsis-h fa-2x" aria-hidden="true"></i>
                      </button>
            <ul class="dropdown-menu" aria-labelledby="group-activities">
              <li *ngIf="canReplaceEmployee" (click)="replaceEmployee(row)"><a><i class="fas fa-exchange-alt" aria-hidden="true"></i> Replace</a></li>
              <li *ngIf="!isMobile" (click)="openIndividual(row)"><a><i class="far fa-calendar-check" aria-hidden="true"></i> Individual Schedule</a></li>
             <!--<li><a><i class="fas fa-sync" aria-hidden="true"></i> Swap</a></li>-->
            </ul>
          </div>

          <div class="message-badge-host">
            <slx-pending-messages-badge *ngIf="isEmptyEmployee(row) && getMessageCount(row) > 0" [zoom]="true"
              [messageCount]="getMessageCount(row)"
              slxTooltip="Open Shift Requests" tipPosition="left"></slx-pending-messages-badge>
          </div>

          <span class="clickable fa-stack fa-2x add-employee-action" *ngIf="isEmptyEmployee(row)" (click)="fillShift(row)">
                        <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                        <i class="fa fa-plus fa-stack-1x" aria-hidden="true" slxTooltip="Add Employee" tipPosition="left"></i>
                      </span>


          <span class="clickable fa-stack fa-2x switch-employee-action" *ngIf="!isEmptyEmployee(row) && !isInPar(row) && isEmptyExists" (click)="switchEmployee(row)">
                        <i class="fa fa-circle fa-stack-2x" aria-hidden="true"></i>
                        <i class="fas fa-exchange-alt fa-rotate-90 fa-stack-1x fa-inverse" aria-hidden="true" slxTooltip="Switch Employee Shift" tipPosition="left"></i>
                      </span>

          <span class="clickable fa-stack fa-2x remove-employee-action" [ngClass]="{'remove-employee-action-out-of-par': !isInPar(row) || isOvertime(row)}"
            *ngIf="!isEmptyEmployee(row)" (click)="removeEmployee(row)">
                        <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                        <i class="fa fa-minus fa-stack-1x" aria-hidden="true" [slxTooltip]="getRemoveTip (row)" tipPosition="left"></i>
                      </span>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<!--<span class="fa-stack fa-lg">
  <i class="fa fa-circle fa-stack-2x" aria-hidden="true"></i>
  <i class="fas fa-exchange-alt fa-rotate-90 fa-stack-1x fa-inverse" aria-hidden="true"></i>
</span>-->
