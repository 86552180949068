import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ZonedDate } from '@progress/kendo-date-math';
import '@progress/kendo-date-math/tz/America/New_York';
import { parseRule, serializeRule, RecurrenceRule } from '@progress/kendo-recurrence';

import {
  RecurrenceFrequencyDefinition,
  RecurrenceFrequencies,
  RecurrenceFrequency,
  WeekDaysDefinition,
  WeekDays,
  WeekDay,
  OffsetPositionDefinition,
  OffsetPositions,
  OffsetPosition,
  RecurrenceRuleOption
} from '../../models/index';

@Injectable()
export class LMRecurrenceService {
  private rrule: RecurrenceRule = {};
  private firstDayOfWeek: number = 0;

  private frequenciesNames = _.values(RecurrenceFrequencies).slice(0, -1);
  private recurrenceFrequencies: RecurrenceFrequency[] = [];

  private daysNames = _.values(WeekDays);
  private weekDays: WeekDay[];

  private offsetsNames = _.values(OffsetPositions);
  private offsetPositions: OffsetPosition[];

  public setRule(freq: RecurrenceFrequencyDefinition, weekDayIndex: number): string {
    if (_.isString(freq) && _.size(freq) > 0 && _.isFinite(weekDayIndex)) {
      return this.createRRule(freq, weekDayIndex);
    }

    throw new Error(
      `Error in Recurrence service. Incorrect instantiating of recurrence rule. freq: ${freq}, weekDayIndex: ${weekDayIndex}`
    );
  }

  public parseRule(rrule: string): string {
    if (_.isString(rrule)) {
      this.rrule = parseRule({
        recurrenceRule: rrule,
        weekStart: this.firstDayOfWeek
      });
      this.updateInnerLists();
      return this.getRuleStr();
    }

    throw new Error(
      `Error in Recurrence service. Cannot parse recurrence rule, rrule has incorrect data type. rrule: ${rrule}`
    );
  }

  public getRecurrenceFrequencies(): RecurrenceFrequency[] {
    return this.recurrenceFrequencies.concat();
  }

  public getWeekDays(): WeekDay[] {
    return this.weekDays.concat();
  }

  public getOffsetPositions(): OffsetPosition[] {
    return this.offsetPositions.concat();
  }

  public createRRule(freq: RecurrenceFrequencyDefinition, weekDayIndex: number): string {
    this.rrule = {};
    this.rrule.freq = freq;
    this.rrule.interval = 1;
    if (freq === RecurrenceFrequencies.weekly) {
      this.rrule.byWeekDay = [{
        day: weekDayIndex,
        offset: 0
      }];
    }
    if (freq === RecurrenceFrequencies.monthly) {
      this.rrule.byMonthDay = [weekDayIndex];
    }
    this.updateInnerLists();
    return this.getRuleStr();
  }

  public setInterval(interval: number) {
    if (_.isFinite(interval)) {
      this.rrule.interval = interval;
      return this.getRuleStr();
    }
    return '';
  }

  public getInterval(): number {
    return this.rrule.interval;
  }

  public setRecurrence(freq: RecurrenceFrequencyDefinition, data: RecurrenceRuleOption): string {
    const { interval, count, until, byWeekDay, byMonthDay } = data;
    if (_.isFinite(interval)) {
      this.rrule.interval = interval;
    }
    this.rrule.count = count;
    this.rrule.until = _.isDate(until) ? ZonedDate.fromLocalDate(new Date(until.getTime())) : null;
    switch(freq) {
      case RecurrenceFrequencies.daily:
        break;
      case RecurrenceFrequencies.weekly:
        this.rrule.byWeekDay = _.size(byWeekDay) > 0 ? byWeekDay : null;
        break;
      case RecurrenceFrequencies.monthly:
        this.rrule.byWeekDay = _.size(byWeekDay) > 0 ? byWeekDay : null;
        this.rrule.byMonthDay = _.isFinite(byMonthDay) ? [byMonthDay] : null;
        break;
      default:
        throw new TypeError(`Can't set recurrency rule object, frequency type is unknown. freq: ${freq}`);
    }
    this.updateInnerLists();

    return this.getRuleStr();
  }

  public getRecurrence(): RecurrenceRuleOption {
    const { interval, count, until, byWeekDay, byMonthDay } = this.rrule;
    const endOnDate = _.isObjectLike(until) ? until.toLocalDate() : null;
    return new RecurrenceRuleOption(interval, count, endOnDate, byWeekDay, _.head(byMonthDay));
  }

  public getRuleStr(): string {
    return serializeRule(this.rrule);
  }

  private updateInnerLists(): void {
    this.recurrenceFrequencies = this.createFrequencies();
    this.weekDays = this.createDays();
    this.offsetPositions = this.createOffsets();
  }

  private createFrequencies(): RecurrenceFrequency[] {
    const freq = _.get(this.rrule, 'freq');
    return _.map(this.frequenciesNames, (f: RecurrenceFrequencyDefinition) => {
      const r = new RecurrenceFrequency(f, _.capitalize(f));
      if (r.id === freq) {
        r.isActive = true;
      }
      return r;
    });
  }

  private createDays(): WeekDay[] {
    const byWeekDay = _.get(this.rrule, 'byWeekDay');
    return _.map(this.daysNames, (d: WeekDaysDefinition, index: number) => {
      const day = new WeekDay(index, d, _.capitalize(d));
      const dayRule = _.find(byWeekDay, (dayRule) => dayRule.day === index);
      day.isActive = _.isObjectLike(dayRule) ? true : false;

      return day;
    });
  }

  private createOffsets(): OffsetPosition[] {
    const values = [1, 2, 3, 4, -1];
    return _.map(this.offsetsNames, (o: OffsetPositionDefinition, i: number) => new OffsetPosition(values[i], o, _.capitalize(o)));
  }
}
