import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../../common';
import { process, State } from '@progress/kendo-data-query';
import { EmployeeSectionsBenefitsManagementApiService } from '../../../../services';
import { EmpBeneficiaryRelation } from '../../../../models/employee-sections-benefits-management/beneficiary-relation';
import { Beneficiary } from '../../../../models/employee-sections-benefits-management/beneficiary';
import { unsubscribeAll } from '../../../../../../core/decorators';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
var BeneficiaryEditorComponent = /** @class */ (function () {
    function BeneficiaryEditorComponent(modalService, apiService) {
        this.modalService = modalService;
        this.apiService = apiService;
        this.defaultItem = { fullName: "", id: null };
        // rights
        this.canAdd = true;
        this.canEdit = true;
        this.canDelete = true;
        this.pageSize = 5;
        this.namePattern = /[^\w\s]|[_]/gi;
        this.addressPattern = /[^a-zA-Z\d\s:]/gi;
        this.isLoading = false;
        this.incompleteWarning = 'Total for each type of beneficiaries must add up to 100%.';
        this.noPrimaryWarning = 'Primary beneficiary is required';
        this.primaryTotal = 0;
        this.primCount = 0;
        this.contTotal = 0;
        this.contCount = 0;
        this.beneficiariesInitCount = 0;
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [
            { field: 'startDate', dir: 'desc' }
        ];
    }
    Object.defineProperty(BeneficiaryEditorComponent.prototype, "enrollmentId", {
        set: function (value) {
            this.m_enrollmentId = value;
            if (this.m_enrollmentId) {
                this.loadBeneficiaries(this.m_enrollmentId);
            }
            else {
                this.beneficiaries = [];
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BeneficiaryEditorComponent.prototype, "canSave", {
        get: function () {
            return (!this.isLoading && !this.editedItem && this.primaryTotal == 100 && (this.contCount > 0 ? this.contTotal == 100 : true)
                || (this.primCount == 0 && this.contCount == 0 && this.beneficiariesInitCount > 0));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BeneficiaryEditorComponent.prototype, "canCancel", {
        get: function () {
            return !this.isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BeneficiaryEditorComponent.prototype, "isEditing", {
        get: function () {
            return (this.editedItem != null);
        },
        enumerable: true,
        configurable: true
    });
    BeneficiaryEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.gridEdit = this.gridState.onEdit$
            .subscribe(function (record) {
            _this.editedItem = record;
        });
        this.subscriptions.gridCancel = this.gridState.onCancel$
            .subscribe(function () {
            _this.editedItem = null;
        });
        this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            if (event.isNew) {
                _this.doAdd(event.dataItem);
                return;
            }
            _this.doEdit(event.dataItem, event.rowIndex);
        });
        this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            options.buttonOKtext = 'Yes';
            ConfirmDialog2Component.openDialog('Delete Beneficiary', 'Are you sure you want to delete the beneficiary?', _this.modalService, function (isDelete) {
                if (isDelete) {
                    _this.doRemove(event.dataItem, event.rowIndex);
                }
            }, options);
        });
    };
    BeneficiaryEditorComponent.prototype.ngOnDestroy = function () { };
    BeneficiaryEditorComponent.prototype.doRemove = function (dataItem, rowIndex) {
        this.beneficiaries = _.without(this.beneficiaries, dataItem);
        this.updateTotals();
        this.refreshGrid();
    };
    BeneficiaryEditorComponent.prototype.doEdit = function (dataItem, rowIndex) {
        this.updateTotals();
        this.editedItem = null;
    };
    BeneficiaryEditorComponent.prototype.doAdd = function (dataItem) {
        this.beneficiaries.push(dataItem);
        this.updateTotals();
        this.editedItem = null;
    };
    BeneficiaryEditorComponent.prototype.loadBeneficiaries = function (enrollmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var beneficiaryPromise, prevBeneficiaryPromise, results, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        beneficiaryPromise = this.apiService.getBeneficiaries(enrollmentId);
                        prevBeneficiaryPromise = this.apiService.getBeneficiariesPrev(enrollmentId);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, Promise.all([beneficiaryPromise, prevBeneficiaryPromise])];
                    case 2:
                        results = _a.sent();
                        this.beneficiaries = results[0].beneficiaries;
                        this.loadedBeneficiaries = results[0].beneficiaries;
                        this.prevBeneficiaries = results[1].beneficiaries;
                        this.relations = results[0].relations;
                        this.beneficiariesInitCount = _.size(results[0].beneficiaries);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        this.beneficiaries = [];
                        this.prevBeneficiaries = [];
                        this.relations = [];
                        return [3 /*break*/, 5];
                    case 4:
                        this.isLoading = false;
                        this.refreshGrid();
                        return [7 /*endfinally*/];
                    case 5:
                        this.updateTotals();
                        return [2 /*return*/];
                }
            });
        });
    };
    BeneficiaryEditorComponent.prototype.onChangePrevBeneficiary = function (b) {
        if (b.id != null) {
            this.selectedPrevBeneficiary = b;
        }
        else {
            this.selectedPrevBeneficiary = null;
        }
    };
    BeneficiaryEditorComponent.prototype.isEditingRow = function (b) {
        if (_.isObjectLike(this.editedItem) && _.isObjectLike(b)) {
            return _.isEqualWith(this.editedItem, b, function (value, other) {
                return value.id == other.id
                    && value.empToBenefitsId == other.empToBenefitsId;
            });
        }
        return false;
    };
    BeneficiaryEditorComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    BeneficiaryEditorComponent.prototype.addPrimary = function () {
        var newBeneficiary = new Beneficiary();
        newBeneficiary.isPrimary = true;
        newBeneficiary.empToBenefitsId = this.m_enrollmentId;
        if (this.selectedPrevBeneficiary) {
            newBeneficiary.lastName = this.selectedPrevBeneficiary.lastName;
            newBeneficiary.firstName = this.selectedPrevBeneficiary.firstName;
            newBeneficiary.birthDate = this.selectedPrevBeneficiary.birthDate;
            newBeneficiary.address = this.selectedPrevBeneficiary.address;
            newBeneficiary.ssn = this.selectedPrevBeneficiary.ssn;
            newBeneficiary.relation = this.selectedPrevBeneficiary.relation;
            newBeneficiary.primaryPercentage = this.selectedPrevBeneficiary.primaryPercentage;
            newBeneficiary.contigentPercentage = 0;
        }
        if (!newBeneficiary.birthDate) {
            newBeneficiary.birthDate = new Date();
        }
        if (!newBeneficiary.relation) {
            var relation = new EmpBeneficiaryRelation();
            relation.id = 'Child';
            relation.name = 'Child';
            newBeneficiary.relation = relation;
        }
        newBeneficiary.id = null;
        this.editedItem = newBeneficiary;
        this.grid.addRow(newBeneficiary);
    };
    BeneficiaryEditorComponent.prototype.addContingent = function () {
        var newBeneficiary = new Beneficiary();
        newBeneficiary.isPrimary = false;
        newBeneficiary.empToBenefitsId = this.m_enrollmentId;
        if (this.selectedPrevBeneficiary) {
            newBeneficiary.lastName = this.selectedPrevBeneficiary.lastName;
            newBeneficiary.firstName = this.selectedPrevBeneficiary.firstName;
            newBeneficiary.birthDate = this.selectedPrevBeneficiary.birthDate;
            newBeneficiary.address = this.selectedPrevBeneficiary.address;
            newBeneficiary.ssn = this.selectedPrevBeneficiary.ssn;
            newBeneficiary.relation = this.selectedPrevBeneficiary.relation;
            newBeneficiary.contigentPercentage = this.selectedPrevBeneficiary.contigentPercentage;
            newBeneficiary.primaryPercentage = 0;
        }
        if (!newBeneficiary.birthDate) {
            newBeneficiary.birthDate = new Date();
        }
        if (!newBeneficiary.relation) {
            var relation = new EmpBeneficiaryRelation();
            relation.id = 'Child';
            relation.name = 'Child';
            newBeneficiary.relation = relation;
        }
        newBeneficiary.id = null;
        this.editedItem = newBeneficiary;
        this.grid.addRow(newBeneficiary);
    };
    BeneficiaryEditorComponent.prototype.onStartEditPrimary = function (item) {
        item.contigentPercentage = 0;
    };
    BeneficiaryEditorComponent.prototype.onStartEditCont = function (item) {
        item.primaryPercentage = 0;
    };
    BeneficiaryEditorComponent.prototype.onPrimaryChange = function (item) {
        this.updateTotals();
    };
    BeneficiaryEditorComponent.prototype.onContChange = function (item) {
        this.updateTotals();
    };
    BeneficiaryEditorComponent.prototype.updateTotals = function () {
        var _this = this;
        this.primaryTotal = 0;
        this.contTotal = 0;
        this.contCount = 0;
        this.primCount = 0;
        _.each(this.beneficiaries, function (b) {
            _this.primaryTotal += b.primaryPercentage ? b.primaryPercentage : 0;
            _this.contTotal += b.contigentPercentage ? b.contigentPercentage : 0;
            if (!b.isPrimary) {
                _this.contCount++;
            }
            else {
                _this.primCount++;
            }
        });
    };
    BeneficiaryEditorComponent.prototype.saveBeneficiaries = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var toAdd, toUpdate, toDelete, toSave, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if ((this.primaryTotal > 0 && this.primaryTotal != 100) && (this.contTotal > 0 && this.contTotal != 100)) {
                            return [2 /*return*/, false];
                        }
                        this.isLoading = true;
                        toAdd = _.filter(this.beneficiaries, function (b) { return _.isNil(b.id); });
                        toUpdate = _.without.apply(_, [this.beneficiaries].concat(toAdd));
                        toDelete = _.without.apply(_, [this.loadedBeneficiaries].concat(this.beneficiaries));
                        toDelete = _.filter(toDelete, function (d) { return !_.isNil(d.id); });
                        _.each(toDelete, function (b) { return b.isDeleted = true; });
                        toSave = _.concat(toUpdate, toAdd, toDelete);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService.updateBeneficiaries(toSave)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        this.isLoading = false;
                        return [2 /*return*/, false];
                    case 4:
                        this.isLoading = false;
                        return [2 /*return*/, true];
                }
            });
        });
    };
    BeneficiaryEditorComponent.prototype.refreshGrid = function () {
        if (!this.beneficiaries) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.beneficiaries, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BeneficiaryEditorComponent.prototype, "subscriptions", void 0);
    return BeneficiaryEditorComponent;
}());
export { BeneficiaryEditorComponent };
