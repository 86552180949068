/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./locker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./locker.component";
var styles_LockerComponent = [i0.styles];
var RenderType_LockerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LockerComponent, data: {} });
export { RenderType_LockerComponent as RenderType_LockerComponent };
function View_LockerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "blur-container"]], null, null, null, null, null))], null, null); }
export function View_LockerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "locker-container"]], [[2, "shown", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LockerComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "locker-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.show; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 0, 0, currVal_0); }); }
export function View_LockerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-locker", [], null, null, null, View_LockerComponent_0, RenderType_LockerComponent)), i1.ɵdid(1, 49152, null, 0, i3.LockerComponent, [], null, null)], null, null); }
var LockerComponentNgFactory = i1.ɵccf("slx-locker", i3.LockerComponent, View_LockerComponent_Host_0, { show: "show" }, {}, ["*"]);
export { LockerComponentNgFactory as LockerComponentNgFactory };
