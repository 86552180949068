import * as tslib_1 from "tslib";
import { LookupEntity } from './lookup-entity';
var ConstraintDefinition = /** @class */ (function (_super) {
    tslib_1.__extends(ConstraintDefinition, _super);
    function ConstraintDefinition() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ConstraintDefinition;
}(LookupEntity));
export { ConstraintDefinition };
