import * as _ from 'lodash';
import * as FullStory from '@fullstory/browser';

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribe } from '../../../core/decorators/index';

import { environment as envConfig } from '../../../../environments/environment';
import { ILoginEvent } from '../../../authentication/models/index';
import { AuthenticationService } from '../../../authentication/services/index';
import { IUser } from '../../../authentication/store/index';
import { DeviceDetectorService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-init-fullstory',
  templateUrl: 'init-fullstory.component.html',
})
export class InitFullstoryComponent implements OnInit, AfterViewInit {
  @unsubscribe()
  private loginSubscription: Subscription;

  @unsubscribe()
  private logoutSubscription: Subscription;

  private isIdentified: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    let devMode = true;
    const envStr = _.toLower(envConfig.ENV);

    if (envStr === 'release') {
      devMode = false;
    }

    FullStory.init({
      orgId: '10A1MQ',
      devMode,
    });
  }

  public ngOnInit(): void {
    this.loginSubscription = this.authenticationService.login$.subscribe((event: ILoginEvent) => {
      if (!event.isRenewAuthentication) {
        this.onLogin(event.alias, event.username, event.email, event.roles);
      }
    });

    this.logoutSubscription = this.authenticationService.onLogout.subscribe(() => {
      this.onLogout();
    });
  }

  public ngAfterViewInit(): void {
    if (!this.isIdentified) {
      const alias: string = this.authenticationService.getAlias();
      const user: IUser = this.authenticationService.getUser();

      if (this.authenticationService.isAuthenticated()) {
        this.onLogin(alias, user.username, user.email, user.roles);
      }
    }
  }

  private onLogin(userAlias: string, username: string, userEmail: string, userRoles: string[]): void {
    this.isIdentified = true;

    const alias: string = _.toUpper(_.size(userAlias) > 0 ? userAlias : 'default');
    const email: string = _.size(userEmail) > 0 ? userEmail : null;
    const roles: string = _.size(userRoles) > 0 ? userRoles.join(',') : '';
    const accountId = _.toUpper(`${username}@${alias}`);
    const device = this.deviceDetectorService.isDesktop ? 'desktop' : 'mobile';

    const userVars: FullStory.UserVars = {
      displayName: accountId,
      alias,
      roles,
      device,
    };

    if (email) {
      userVars.email = email;
    }

    FullStory.identify(accountId, userVars);
  }

  private onLogout(): void {
    this.isIdentified = false;
    FullStory.anonymize();
  }
}
