import * as moment from 'moment';
import * as _ from 'lodash';

import { appConfig } from '../../app.config';

export interface IDateTimeUtils {
  setTime(date: Date, localTime: string, protectDate?: boolean): Date;
  getTime(date: Date | number): number;
  copyDate(date: Date | number): Date;
  setTimeToMoment(mnt: moment.Moment, localTime: string): void;
  getUtcDateTime(date: Date): Date;
  convertToDtoUTC(date: Date): Date;
  convertToDtoString(date: Date): string;
  convertToDtoDateTimeString(date: Date): string;
  convertToDtoDateStringWithMilliseconds(date: Date): string;
  convertToDtoHourMinutesString(date: Date): string;
  convertFromDtoString(date: string): Date;
  convertFromDtoDateTimeString(date: string): Date;
  convertFromDtoDateString(date: string): Date;
  convertFromDtoDurationString(time: string): moment.Duration;
  convertFromDtoDurationStringToNumber(time: string, basis: 's' | 'h' | 'ms'): number;
  convertFromDtoDurationNumber(time: number, basis: 's' | 'h' | 'ms'): moment.Duration;
  convertToDtoDurationString(time: moment.Duration): string;
  convertToDtoDurationNumber(time: moment.Duration, basis: 's' | 'h' | 'ms'): number;
  convertFromNumberToDtoDurationString(time: number, basis: 's' | 'h' | 'ms'): string;
  isInValidPeriod(date: Date): boolean;
  validateDate(date: Date): boolean;
  isDateInPeriod(start: Date, end: Date, date: Date): boolean;
  getTimeTotalSeconds(time: string | Date): number;
  getTimeTotalSecondsWithUTC(time: string): number;
  setTimeTotalSeconds(seconds: number, format?: string): string;
  getDurationTotalHours(time: moment.Duration): number;
  getDurationDiffHours(start: Date, end: Date): number;
  pad(n: number, width: number, z?: string): string;
  formatDurationDecimal(d: moment.Duration, precision?: number): string;
  formatDuration(d: moment.Duration, skipSeconds?: boolean): string;
  getDateTimeFromTime(refDate: Date | moment.Moment, militaryTime: string): Date;
  getDateRangeFromTimeRange(refDate: Date | moment.Moment, startTime: string, endTime: string): { start: Date, end: Date };
  getIntervalTimeFromInterval(refDate: Date | moment.Moment, interval: number, basis: 's' | 'h' | 'ms'): Date;
  getIntervalFromIntervalTime(intervalTime: Date | moment.Moment, basis: 's' | 'h' | 'ms'): number;
  isBeforeMaxSQLDate(d: Date): boolean;
  getTimespanDto(diff: number): string;
  getIntervalFromTimespanDto(timespan: string): number;
  isOverlapping(start: Date, end: Date, testStart: Date, testEnd: Date): boolean;
  convertFromDtoLocalTimeZone(date: string, serverUtcOffsetSec: number): Date;
  convertFromDtoToLocalDateTimeString(date: string): Date;
}
export const dateTimeUtils: IDateTimeUtils = {
  setTime(date: Date, localTime: string, protectDate?: boolean): Date {
    if (!date) {
      return null;
    }
    let time: moment.Moment = moment(localTime, appConfig.timeFormat);
    if (!protectDate) {
      return moment(date).hour(time.hour()).minute(time.minute()).second(time.second()).toDate();
    }
    date.setHours(time.hour());
    date.setMinutes(time.minute());
    date.setSeconds(time.second());
    return date;
  },

  getTime(date: Date | number): number {
    if (_.isDate(date)) return date.getTime();

    return date;
  },

  copyDate(date: Date | number): Date {
    return new Date(this.getTime(date));
  },

  setTimeToMoment(mnt: moment.Moment, localTime: string): void {
    let h: number = 0, m: number = 0, s: number = 0;
    if (localTime && localTime !== '') {
      let time: moment.Moment = moment(localTime, appConfig.timeFormat);
      h = time.hour();
      m = time.minute();
      s = time.second();
    }
    mnt.set({ 'hour': h, 'minute': m, 'second': s });
  },

  getUtcDateTime(date: Date): Date {
    if (!date) { return null; }
    return moment(date).toDate();
  },

  convertToDtoUTC(date: Date): Date {
    if (!date) { return null; }
    if (date instanceof String) {
      date = new Date(date);
    }
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), 0));
  },

  convertToDtoDateTimeString(date: Date): string {
    if (!date) { return null; }
    return moment(date).format(appConfig.requestDateTime);
  },

  convertToDtoDateStringWithMilliseconds(date: Date): string {
    if (!date) { return null; }
    return moment(date).format(appConfig.requestDateTimeWithMilliSeconds);
  },

  convertToDtoHourMinutesString(date: Date): string {
    if (!date) { return null; }
    return moment(date).format(appConfig.militaryTimeFormat);
  },

  convertToDtoString(date: Date): string {
    if (!date) { return null; }
    return moment(date).format(appConfig.requestDate);
  },

  convertFromDtoString(date: string): Date {
    if (!date) { return null; }
    return moment(date).toDate();
  },

  convertFromDtoDateTimeString(date: string): Date {
    if (!date) { return null; }
    return moment(date).toDate();
  },

  convertFromDtoDateString(date: string): Date {
    if (!date) { return null; }
    let dateTime = moment(date).toDate();
    return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
  },

  convertToDtoDurationString(arg: moment.Duration): string {

    const time = arg.clone();
    let res: string = moment('1970-01-01', 'YYYY-MM-DD').startOf('day').add(time.abs()).format(appConfig.militaryTimeFormat);
    const days = Math.abs(time.days());
    if (days > 0) {
      res = days + '.' + res;
    }
    const ms = arg.asMilliseconds();
    if (ms < 0) {
      res = `-${res}`;
    }
    return res;
  },

  convertToDtoDurationNumber(time: moment.Duration, basis: 's' | 'h' | 'ms'): number {
    let seconds: number = time.days() * 24 * 3600 + time.hours() * 3600 + time.minutes() * 60 + time.seconds();
    switch (basis) {
      case 's': return seconds;
      case 'h': return seconds / 3600;
      case 'ms': return seconds * 1000;
    }
    throw new Error(`unknown basis ${basis}`);
  },

  convertFromDtoDurationString(time: string): moment.Duration {
    if (!time) { return null; }
    return moment.duration(time);
  },
  convertFromDtoDurationNumber(time: number, basis: 's' | 'h' | 'ms'): moment.Duration {
    return moment.duration(time, basis);
  },

  convertFromNumberToDtoDurationString(time: number, basis: 's' | 'h' | 'ms'): string {
    const duration: moment.Duration = moment.duration(time, basis);
    return this.convertToDtoDurationString(duration);
  },

  convertFromDtoDurationStringToNumber(time: string, basis: 's' | 'h' | 'ms'): number {
    const duration: moment.Duration = this.convertFromDtoDurationNumber(time);
    return this.convertToDtoDurationNumber(duration, basis);
  },

  isInValidPeriod(date: Date): boolean {
    if (moment(appConfig.minCorrectDate).isSameOrBefore(moment(date))
      && moment(appConfig.maxCorrectDate).isSameOrAfter(moment(date))) {
      return true;
    }

    return false;
  },

  isBeforeMaxSQLDate(date: Date): boolean {
    if (moment(date).isBefore(moment(appConfig.maxCorrectDate))) {
      return true;
    }
    return false;
  },

  validateDate(date: Date): boolean {
    if (!date) {
      return true;
    }
    if (date instanceof Date) {
      if (isNaN(date.getTime())) return false;
      if (dateTimeUtils.isInValidPeriod(date)) {
        return true;
      }
    } else {
      if (moment(date).isValid()) {
        if (dateTimeUtils.isInValidPeriod(moment(date).toDate())) {
          return true;
        }
      }
    }
    return false;
  },
  isDateInPeriod(start: Date, end: Date, date: Date): boolean {
    return moment(start).isSameOrBefore(moment(date)) && moment(end).isSameOrAfter(moment(date));
  },

  getTimeTotalSeconds(time: string | Date): number {
    let t: moment.Moment;
    if (!time) {
      return null;
    }
    if (time instanceof Date) {
      t = moment(time);
    } else {
      t = moment(time, appConfig.timeFormat);
    }
    if (!t.isValid()) {
      return null;
    }
    let start: moment.Moment = moment(t).startOf('day');
    let seconds: number = moment(t).unix() - start.unix();
    return seconds;
  },

  getTimeTotalSecondsWithUTC(time: string): number {
    let t: moment.Moment;
    if (!time) {
      return null;
    }
    t = moment.utc(time);
    if (!t.isValid()) {
      return null;
    }
    let start: number = moment.utc(t).startOf('day').unix();
    let end: number = moment.utc(t).unix();
    return end-start;
  },

  setTimeTotalSeconds(seconds: number, format?: string): string {
    let start: moment.Moment = moment().startOf('day');
    let time: moment.Moment = start.add('seconds', seconds);
    return time.format(format ? format : appConfig.militaryTimeFormat);
  },

  getDurationTotalHours(time: moment.Duration): number {
    let res: number = time.days() * 24 + time.hours() + time.minutes() / 60 + time.seconds() / 3600;
    return res;
  },

  getDurationDiffHours(start: Date, end: Date): number {
    let duration: number = 0;
    let startDateMoment: moment.Moment = moment(start, appConfig.militaryTimeFormat);
    let endDateMoment: moment.Moment = moment(end, appConfig.militaryTimeFormat);
    if (startDateMoment <= endDateMoment) {
      let duration1: moment.Duration = moment.duration(endDateMoment.diff(startDateMoment));
      duration = Math.round(duration1.asMinutes()) / 60;
    } else {
      let zeroDateMoment: moment.Moment = moment().startOf('day');
      let enddayDateMoment: moment.Moment = moment().endOf('day');
      let duration1: moment.Duration = moment.duration(enddayDateMoment.diff(startDateMoment));
      let duration2: moment.Duration = moment.duration(endDateMoment.diff(zeroDateMoment));
      let durationMins1: number = Math.round(duration1.add(1, 'millisecond').asMinutes());
      let durationMins2: number = Math.round(duration2.asMinutes());
      duration = durationMins1 / 60 + durationMins2 / 60;
    }
    return duration;
  },

  pad(n: number, width: number = 2, z?: string): string {
    z = z || '0';
    let nStr = n + '';
    return nStr.length >= width ? nStr : new Array(width - nStr.length + 1).join(z) + nStr;
  },

  formatDurationDecimal(arg: moment.Duration, precision: number = 2): string {
    let fmtStr: string;
    let hh: number = 0;
    const d = arg.clone();
    d.abs();
    const days = Math.abs(d.days());
    if (days > 0) {
      hh = days * 24;
    }
    hh += d.hours();
    let mm: number = d.minutes();
    let ss: number = d.seconds();
    fmtStr = (hh + mm / 60 + ss / 3600).toFixed(precision);
    if (arg.asMilliseconds() < 0) {
      fmtStr = `-${fmtStr}`;
    }
    return fmtStr;
  },

  formatDuration(d: moment.Duration, skipSeconds?: boolean): string {
    let fmtStr: string;
    let hhNum: number = 0;
    if (d.days() > 0) {
      hhNum = d.days() * 24;
    }
    let hh: string = this.pad(hhNum + d.hours());
    let mm: string = this.pad(d.minutes());
    let ss: string = this.pad(d.seconds());
    if (!skipSeconds) {
      fmtStr = `${hh}:${mm}:${ss}`;
    } else {
      fmtStr = `${hh}:${mm}`;
    }
    return fmtStr;
  },

  getIntervalTimeFromInterval(refDate: Date | moment.Moment, interval: number, basis: 's' | 'h' | 'ms'): Date {
    let date: moment.Moment = moment(refDate).startOf('day');
    date.add(Math.abs(interval), basis);
    return date.toDate();
  },
  getIntervalFromIntervalTime(intervalTime: Date | moment.Moment, basis: 's' | 'h' | 'ms'): number {
    let start: moment.Moment = moment(intervalTime).startOf('day');
    let seconds: number = moment(intervalTime).unix() - start.unix();
    if (basis === 'h') {
      return seconds / (3600);
    }
    if (basis === 's') {
      return seconds / 1000;
    }
    return seconds * 1000;
  },

  getDateTimeFromTime(refDate: Date | moment.Moment, militaryTime: string): Date {
    return moment(`${moment(refDate).format(appConfig.dateFormat)} ${militaryTime}`, `${appConfig.dateFormat}  ${appConfig.militaryTimeFormat}`).toDate();
  },
  getDateRangeFromTimeRange(refDate: Date | moment.Moment, startTime: string, endTime: string): { start: Date, end: Date } {
    let startDate: moment.Moment = moment(this.getDateTimeFromTime(refDate, startTime));
    let endDate: moment.Moment = moment(this.getDateTimeFromTime(refDate, endTime));
    if (startDate.isAfter(endDate)) {
      endDate.add(1, 'day');
    }
    return { start: startDate.toDate(), end: endDate.toDate() };
  },
  getTimespanDto(diff: number) {
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    diff -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(diff / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    const mins = Math.floor(diff / (1000 * 60));
    diff -= mins * (1000 * 60);

    const seconds = Math.floor(diff / (1000));
    diff -= seconds * (1000);
    let res: string;
    if (days > 0) {
      res = `${days}.${_.padStart(hours.toString(), 2, '0')}:${_.padStart(mins.toString(), 2, '0')}:${_.padStart(seconds.toString(), 2, '0')}`;
    } else {
      res = `${_.padStart(hours.toString(), 2, '0')}:${_.padStart(mins.toString(), 2, '0')}:${_.padStart(seconds.toString(), 2, '0')}`;
    }
    return res;
  },
  getIntervalFromTimespanDto(timespan: string): number {
    if (!timespan) {
      return 0;
    }
    let seconds = 0;
    let minutes = 0;
    let hours = 0;
    let days = 0;
    const values = _.split(timespan, ':').reverse();

    if (values.length === 0) {
      seconds = parseInt(timespan.trim());
    }
    if (values.length >= 1) {
      seconds = parseInt(values[0].trim());
    }
    if (values.length >= 2) {
      minutes = parseInt(values[1].trim());
    }
    if (values.length >= 3) {
      const daysValues = values[2].split('.').reverse();
      if (daysValues.length === 0) {
        hours = parseInt(values[2].trim());
      }
      if (daysValues.length >= 1) {
        hours = parseInt(daysValues[0].trim());
      }
      if (daysValues.length >= 2) {
        days = parseInt(daysValues[1].trim());
      }
    }
    const diff = (1000 * 60 * 60 * 24) * days + (1000 * 60 * 60) * hours + (1000 * 60) * minutes + (1000) * seconds;
    return diff;
  },
  isOverlapping(start: Date, end: Date, testStart: Date, testEnd: Date): boolean {
    return !(moment(end).isSameOrBefore(testStart) || moment(start).isSameOrAfter(testEnd));
  },

  convertFromDtoLocalTimeZone(dateStr: string, serverUtcOffsetSec: number): Date {
    if (!dateStr) {
      return null;
    }
    let dt = moment(dateStr).toDate();
    const serverOffset = serverUtcOffsetSec / 3600;
    const localMOffset = moment(dt).utcOffset() % 60;
    const localHOffset = (moment(dt).utcOffset() - localMOffset) / 60;
    dt = new Date(dt.getFullYear(), dt.getMonth(),
      dt.getDate(), serverOffset - localHOffset + dt.getHours(), dt.getMinutes() - localMOffset, dt.getSeconds());
    return dt;
  },
  convertFromDtoToLocalDateTimeString(date: string): Date {
    if (!date) { return null; }
    return moment.utc(date).local().toDate();
  }
};
