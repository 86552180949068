import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { timeAndAttendanceConfig } from '../../../time-and-attendance/time-and-attendance.config';
import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { Meta, ResponseBody } from '../../../core/models/index';

import { HolidaysMapService } from './holidays-map.service';
import { Holiday, IHoliday } from '../../models/index';

@Injectable()
export class HolidaysApiService {

  constructor(private apiUtilService: ApiUtilService,
              private urlParamsService: UrlParamsService,
              private holidaysMapService: HolidaysMapService,
              private cacheUtilService: CacheUtilService) {
  }

  public getHolidays(organizationId: number, year: number): Promise<Holiday[]> {
    const url: string = this.getHolidaysApiRoot();

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({organizationId: organizationId, year: year})
    });

    let promise: Promise<Holiday[]> = this.apiUtilService.request<IHoliday[], Meta>(request)
      .then((response: ResponseBody<IHoliday[], Meta>) =>
        this.holidaysMapService.mapHolidays(response.data));
    return promise;
  }

  public addHoliday(holiday: Holiday, organizationId: number, year: number): Promise<Holiday> {
    const url: string = this.getHolidaysApiRoot();

    let request: HttpRequest<any> = new HttpRequest('POST', url, this.holidaysMapService.mapHolidayDto(holiday), {
      params: this.urlParamsService.convertToParams({organizationId: organizationId, year: year})
    });

    let promise: Promise<Holiday> = this.apiUtilService.request<IHoliday, Meta>(request)
      .then((response: ResponseBody<IHoliday, Meta>) => {
        this.cacheUtilService.clear();
        return this.holidaysMapService.mapHoliday(response.data);
      });
    return promise;
  }

  public editHoliday(holiday: Holiday): Promise<Holiday> {
    const url: string = `${this.getHolidaysApiRoot()}/${holiday.id}`;

    let request: HttpRequest<any> = new HttpRequest('PUT', url, this.holidaysMapService.mapHolidayDto(holiday));

    let promise: Promise<Holiday> = this.apiUtilService.request<IHoliday, Meta>(request)
      .then((response: ResponseBody<IHoliday, Meta>) => {
        this.cacheUtilService.clear();
        return this.holidaysMapService.mapHoliday(response.data);
      });
    return promise;
  }

  public removeHolidays(holidayId: number): Promise<any> {
    const url: string = `${this.getHolidaysApiRoot()}/${holidayId}`;
    const request = this.urlParamsService.createDeleteRequest(url);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.cacheUtilService.clear();
        return response.status;
      });
    return promise;
  }

  public copyHolidays(holidaysIds: number[], destOrganizationId: number, destYear: number): Promise<any> {
    const url: string = this.getHolidaysApiCopy();

    let request: HttpRequest<any> = new HttpRequest('POST', url, holidaysIds, {
      params: this.urlParamsService.convertToParams({organizationId: destOrganizationId, year: destYear})
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.cacheUtilService.clear();
        return response.status;
      });
    return promise;
  }

  private getHolidaysApiCopy(): string {
    return `${this.getHolidaysApiRoot()}/${timeAndAttendanceConfig.api.configuration.holidays.copy}`;
  }

  private getHolidaysApiRoot(): string {
    return `${this.getTaApiRoot()}/${timeAndAttendanceConfig.api.configuration.root}/${timeAndAttendanceConfig.api.configuration.holidays.root}`;
  }

  private getTaApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${timeAndAttendanceConfig.api.root}`;
  }
}
