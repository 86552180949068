import { EmployeeDefinition } from './../../../organization/models/employee/employee-definition';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';

import { schedulerConfig } from '../../scheduler.config';
import { VacationPlannerMapService } from './vacation-planner-map.service';
import {
  VacationPlan, IVacationPlan, VacationPlanEmployeeDetails,
  IVacationPlanEmployeeDetails, VacationPlanEmployeeSummary,
  IVacationPlanEmployeeSummary, VacationPlanEmployeeRecordDetails,
  IVacationPlanEmployeeDetailsSaveRequest
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class VacationPlannerApiService {
  public onSaveDailyDetailsStarted$: ReplaySubject<any>;
  public onSaveDailyDetailsFinished$: ReplaySubject<any>;

  constructor(
    private apiUtilService: ApiUtilService,
    private vacationPlannerMapService: VacationPlannerMapService,
    private urlParamsService: UrlParamsService) {
    this.onSaveDailyDetailsStarted$ = new ReplaySubject(1);
    this.onSaveDailyDetailsFinished$ = new ReplaySubject(1);
  }

  public getVacationPlan(orgLevelId: number, year: number): Promise<VacationPlan> {
    const url: string = `${this.getApiRoot()}/${schedulerConfig.api.vacations.yearVacationPlan}`;
    let params: HttpParams = this.urlParamsService.convertToParams({ orgLevelId: orgLevelId.toString(), year: year.toString() });

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });
    /*
        let promise: Promise<VacationPlan> = Promise.resolve(
          this.vacationPlannerMapService.mapToVacationPlan(VacationPlan.mock)
        );*/

    let promise: Promise<VacationPlan> = this.apiUtilService.request<IVacationPlan, Meta>(request)
      .then((response: ResponseBody<IVacationPlan, Meta>) => this.vacationPlannerMapService.mapToVacationPlan(response.data));
    return promise;
  }

  public getVacationPlanEmployeeDetails(employee: EmployeeDefinition, date: Date): Promise<VacationPlanEmployeeDetails> {
    const url: string = `${this.getEmployeesApiRoot(employee.id)}/${schedulerConfig.api.employees.vacationPlanner}`;
    let params: HttpParams = this.urlParamsService.convertToParams({ date: dateTimeUtils.convertToDtoString(date) });

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<VacationPlanEmployeeDetails> = this.apiUtilService.request<IVacationPlanEmployeeDetails, Meta>(request)
      .then((response: ResponseBody<IVacationPlanEmployeeDetails, Meta>) => this.vacationPlannerMapService.mapToVacationPlanEmployeeDetails(response.data, employee));

    return promise;
  }

  public getVacationPlanEmployeeSummary(employeeId: number, year: number): Promise<VacationPlanEmployeeSummary> {
    const url: string = `${this.getEmployeesApiRoot(employeeId)}/${schedulerConfig.api.employees.vacationPlannerYearSummary}`;
    let params: HttpParams = this.urlParamsService.convertToParams({ year: year });

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<VacationPlanEmployeeSummary> = this.apiUtilService.request<IVacationPlanEmployeeSummary, Meta>(request)
      .then((response: ResponseBody<IVacationPlanEmployeeSummary, Meta>) => this.vacationPlannerMapService.mapToVacationPlanEmployeeSummary(response.data));

    return promise;
  }

  public saveVacationPlanEmployeeDetails(employeeId: number, records: VacationPlanEmployeeRecordDetails[]): Promise<boolean> {
    Assert.isNotNull(records, 'records');
    Assert.isNotNull(employeeId, 'employeeId');
    this.onSaveDailyDetailsStarted$.next();
    const url: string = `${this.getEmployeesApiRoot(employeeId)}/${schedulerConfig.api.employees.vacationPlanner}`;
    let modelDTO: IVacationPlanEmployeeDetailsSaveRequest = this.vacationPlannerMapService.mapToVacationPlanSaveRequest(records);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.onSaveDailyDetailsFinished$.next();
        return true;
      })
      .catch((reason: any) => {
        this.onSaveDailyDetailsFinished$.next();
        return false;
      });
    return promise;
  }

  private getEmployeesApiRoot(employeeId: number): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${schedulerConfig.api.employees.root}/${employeeId}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${schedulerConfig.api.vacations.root}`;
  }

}
