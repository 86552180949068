import { OnInit } from '@angular/core';
import { LMCreationAbsenceManagementService } from '../../../services/index';
var LMAbsenceNotesTabComponent = /** @class */ (function () {
    function LMAbsenceNotesTabComponent(manService) {
        this.manService = manService;
    }
    Object.defineProperty(LMAbsenceNotesTabComponent.prototype, "canEditRequest", {
        get: function () {
            return this.manService.canChange;
        },
        enumerable: true,
        configurable: true
    });
    LMAbsenceNotesTabComponent.prototype.ngOnInit = function () {
        this.notes = this.manService.getNotes();
    };
    LMAbsenceNotesTabComponent.prototype.onChangeNotes = function (note) {
        this.manService.setNotes(note);
    };
    return LMAbsenceNotesTabComponent;
}());
export { LMAbsenceNotesTabComponent };
