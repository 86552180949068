import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, RouterStateSnapshot, Router, Params, Resolve
} from '@angular/router';

import { PayCycle } from '../../../organization/models/lookup/index';
import { PayCycleHelperService, ApplicationStateBusService } from '../../../organization/services/index';
import { OrgLevel } from '../../../state-model/models/index';
import { appConfig } from '../../../app.config';
import { RangeDates } from '../../../common/models/range-dates';
import { BaseResolver } from '../../../common/index';
import { ExceptionConsoleRouteData } from '../../../time-and-attendance/models/exception-console/exception-console-route-data';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';

@Injectable()
export class ExceptionConsoleResolver extends BaseResolver<ExceptionConsoleRouteData> implements Resolve<ExceptionConsoleRouteData> {

    constructor(router: Router,
        private payCycleHelper: PayCycleHelperService,
        private busService: ApplicationStateBusService) {
        super(router);
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ExceptionConsoleRouteData | Promise<ExceptionConsoleRouteData> {

        let usePayCycle: boolean = _.toString(route.queryParamMap.get('usePayCycle')) === 'true';

        let range: RangeDates = this.getRangeDates(route);
        let routeData: ExceptionConsoleRouteData = new ExceptionConsoleRouteData();
        routeData.range = range;


        if (range.startDate && range.endDate) {
            if (!moment(range.endDate).isAfter(range.startDate)) {
                range.endDate = moment(range.startDate).add(1, 'day').toDate();
            }
        } else if (range.startDate) {
            range.endDate = moment(range.startDate).add(29, 'day').toDate();
        } else if (range.endDate) {
            range.startDate = moment(range.endDate).subtract(29, 'day').toDate();
        } else {
            let today: Date = moment().startOf('day').toDate();
            range.endDate = moment(today).subtract(1, 'day').toDate();
            range.startDate = moment(range.endDate).subtract(29, 'day').toDate();
            const params: Params = this.createQueryParams(route, state);
            params.startDate = dateTimeUtils.convertToDtoString(range.startDate);
            params.endDate = dateTimeUtils.convertToDtoString(range.endDate);
            this.navigateToPath(route, params);
            return routeData;
        }

        if(usePayCycle && moment(range.startDate).isValid() && moment(range.endDate).isValid()) {
          routeData.usePayCycle = usePayCycle;
          routeData.payCycle = new PayCycle();
          routeData.payCycle.startDate = range.startDate;
          routeData.payCycle.endDate = range.endDate;
          return routeData;
        }

        if (!this.busService.isInitialized) {
          return routeData;
        }
        return this.getOrgLevel().then((orgLevel: OrgLevel) => {

            if (!usePayCycle) {
                return routeData;
            }

            return this.payCycleHelper.getPayCycleByDate(range.startDate, orgLevel.id).then((payCycle: PayCycle) => {
                if (payCycle) {

                    if (moment(payCycle.startDate).isBefore(range.endDate)) {
                        if (moment(range.endDate).diff(payCycle.startDate, 'day') > 29) {
                            range.startDate = moment(range.endDate).subtract(29, 'day').toDate();
                        } else {
                            range.startDate = payCycle.startDate;
                        }
                    } else {
                        range.startDate = moment(range.endDate).startOf('day').subtract(1, 'day').toDate();
                    }
                }

                routeData.range = range;
                routeData.payCycle = payCycle;
                routeData.usePayCycle = true;

                return routeData;
            });
        });
    }

    protected getOrgLevel(): Promise<OrgLevel> {
        let promise: Promise<OrgLevel> = this.busService.orgLevelSelected$.first().toPromise();
        return promise;
    }

    protected getRangeDates(route: ActivatedRouteSnapshot): RangeDates {

        let startDateParam: string = _.toString(route.queryParamMap.get('startDate'));
        let endDateParam: string = _.toString(route.queryParamMap.get('endDate'));

        let startDate: Date = startDateParam ? moment(startDateParam, appConfig.linkDateFormat).toDate() : null;
        let endDate: Date = endDateParam ? moment(endDateParam, appConfig.linkDateFormat).toDate() : null;

        let range: RangeDates = new RangeDates();
        range.startDate = startDate;
        range.endDate = endDate;

        return range;
    }

}
