import { Component, OnInit, Provider } from '@angular/core';
import { BIPAReportDefinition, ConfigForceLoad } from '../../models';
import { ConfirmDialog2Component, ConfirmOptions, DialogModeSize, DialogOptions, DialogOptions2, ModalService } from '../../../common';
import { Assert } from '../../../framework';
import * as _ from 'lodash';

@Component({
  selector: 'slx-bipa-report-dialog',
  templateUrl: './bipa-report-dialog.component.html',
  styleUrls: ['./bipa-report-dialog.component.scss']
})
export class BipaReportDialogComponent {

  public hasValueChange: boolean = false;
  public dialogResult: boolean;
  public reportDefinition: BIPAReportDefinition;
  public forceLoad: boolean = false;

  constructor(reportDefinition: BIPAReportDefinition, forceLoad: ConfigForceLoad, private options: DialogOptions,
    private modalService: ModalService) {
    Assert.isNotNull(reportDefinition, 'reportDefinition');
    this.reportDefinition = reportDefinition;
    this.forceLoad = forceLoad.dailyStaffingPosting;
    if (_.isEmpty(this.reportDefinition)) {
      this.reportDefinition = this.setDefault(reportDefinition);
    }
  }

  public static openDialog(request: BIPAReportDefinition, configForceLoad: ConfigForceLoad, modalService: ModalService, callback: (result: boolean, request: BIPAReportDefinition) => void): BipaReportDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.width = 767;
    dialogOptions.height = 557;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: BIPAReportDefinition,
        useValue: request
      },
      {
        provide: ConfigForceLoad,
        useValue: configForceLoad
      }
    ];
    const title = `Daily Staffing Settings`;
    let component = modalService.globalAnchor.openDialog2(
      BipaReportDialogComponent,
      title,
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId: string) => {
        callback(result, request);
      }
    );

    return component;
  }
  
  public onSave(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
  public onCancel(): void {
    if (this.hasValueChange) {
      this.displayConfirm();
    }
    else {
      this.closeWindow();
    }
  }

  private closeWindow() {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  private displayConfirm() {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Discard Changes',
      'Are you sure you want to cancel? You will lose all unsaved data.',
      this.modalService,
      (isCancel: boolean) => {
        if (isCancel) {
          this.closeWindow();
        }
      },
      options);
  }

  public onValueChanged(hasValueChange: boolean) {
    this.hasValueChange = hasValueChange;
  }

  public setDefault(gridData: BIPAReportDefinition) {
    gridData.unitShiftData = [false, false];
    gridData.shiftGroupTimingS = ["1900-01-01T07:00:00", "1900-01-01T15:00:00", "1900-01-01T23:00:00"];
    gridData.gridData = [];
    return gridData;
  }

}
