<!-- #bugInIE11WithEmployeeRecord (ngIf) -->
<div *ngIf="employeeSectionTimeclockContainer">
  <kendo-grid #kendoGrid class="employee-timeclock"
    scrollable="'scrollable'"
    [data]="gridView"
    [sortable]="{ mode: 'multiple' }"
    [sort]="sort"
    (sortChange)="onSortChange($event)"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="skip"
    (pageChange)="onPageChange($event)"
  >
    <ng-template kendoGridToolbarTemplate position="top">
      <form novalidate #form="ngForm">
        <div class="col-sm-6 col-lg-2" *ngIf="hasAgencyPrefix">
          <slx-input-decorator>
            <input slx-input type="text" number
            [readonly]="true"  name="agencyPrefix" placeholder="Agency Prefix" [(ngModel)]="agencyPrefix">
          </slx-input-decorator>
        </div>
        <div class="col-sm-6 col-lg-2">
          <slx-input-decorator>
            <input slx-input number notLeadZeroes
              [(ngModel)]="badge"
              [server]="{validationName: badgeIdValidation, parameters: [employeeId], validationAdapter: employeeValidatorAdapter}"
              [inputPolicy]="timeclockContainer?.badgeId?.Policy"
              [readonly]="!state.isEditMode"
              [required]="'true'"
              min="0"
              max="99999999"
              maxlength="8"
              placeholder="Badge Id"
              type="text"
              name="badgeField"
            >
            <span errorMessage for="required"></span>
            <span errorMessage for="number"></span>
            <span errorMessage [for]="badgeIdValidation">msg from server</span>
            <span errorMessage for="notLeadZeroes">The leading zeroes is not supported</span>
            <span errorMessage for="max">Maximum value for badge id is 99999999 (8 numbers)</span>
          </slx-input-decorator>
          <button *ngIf="state.isEditMode && timeclockContainer?.badgeId?.isEditable"
            type="button"
            title="Generate Badge ID"
            class="bt-generate"
            (click)="generateBageId()"
          >
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          </button>
        </div>
        <div class="col-sm-6 col-lg-2">
          <slx-input-decorator>
            <input slx-input number
              [(ngModel)]="pin"
              [inputPolicy]="timeclockContainer?.pinNumber?.Policy"
              [readonly]="!state.isEditMode"
              slxMinLength="4"
              slxMaxLength="4"
              placeholder="PIN Number"
              type="text"
              name="pinField"
            >
            <span errorMessage for="number"></span>
            <span errorMessage for="slxMinLength">PIN must have minimum 4 digits</span>
            <span errorMessage for="slxMaxLength">PIN must have maximum 4 digits</span>
          </slx-input-decorator>
        </div>
        <div class="col-sm-3 col-lg-2">
          <slx-input-decorator>
            <slx-dropdown-input slx-input
              lookup="timeclockRestrictionDefinition"
              [(ngModel)]="restriction"
              (ngModelChange)="onRestrictionChanged($event)"
              [inputPolicy]="timeclockContainer?.restriction?.Policy"
              [readonly]="!state.isEditMode || !timeclockContainer?.timeclockAssignments?.isEditable"
              placeholder="Restriction"
              required="true"
              name="restrictField"
            >
            </slx-dropdown-input>
          </slx-input-decorator>
        </div>
        <div class="col-sm-3 col-lg-2">
          <slx-input-decorator className="slx-no-border  slx-no-error-block multiPositionPunch" *ngIf="timeclockContainer.isPositionsMultiPunchEnabled">
            <slx-checkbox-input [(ngModel)]="multiPositionPunch" [readonly]="!state.isEditMode" slx-input
              name="multiPositionPunch" fieldName="multiPositionPunch"  placeholder="Multi-Position Punch">
            </slx-checkbox-input>
          </slx-input-decorator>
        </div>
        <div class="col-sm-3 col-lg-2" *ngIf="timeclockContainer.slateProfileEnabled">
          <slx-input-decorator>
            <slx-dropdown-input slx-input
              lookup="timeclockSlateProfileDefinition"
              [(ngModel)]="slateProfile"
              [inputPolicy]="timeclockContainer?.slateProfile?.Policy"
              [readonly]="!state.isEditMode || !timeclockContainer?.timeclockAssignments?.isEditable"
              placeholder="SLATE Profile"
              required="true"
              name="slateProfile"
            >
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>

        <div class="col-sm-3 col-lg-2 pull-right">
          <div class="row">
            <button type="button" class="resubmit-button pull-right" [disabled]="!hasActiveState ||state.isEditMode|| !timeclockContainer?.timeclockAssignments?.isEditable" (click)="resubmit()">
              <span class="text-button-content">Re-Submit</span></button>
          </div>
        </div>
      </form>
    </ng-template>

    <kendo-grid-column [width]="50" class="flag-cell">
      <ng-template kendoGridHeaderTemplate>

      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [slxTooltip]="dataItem.lastCommunicationDate" tipPosition="right">
          <span class="circle">
            <em class="fa fa-circle bad-status" [ngClass]="{'good-status': dataItem.communicationStatus > 0}"></em>
          </span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="assign" [sortable]="false" [width]="30" class="checkbox-cell">
      <ng-template kendoGridHeaderTemplate>
        <slx-input-decorator *ngIf="state.isEditMode && timeclockContainer?.timeclockAssignments?.isEditable" className="slx-no-label slx-no-border slx-no-error-block">
          <slx-checkbox-input slx-input
            [(ngModel)]="assignedAll"
            (ngModelChange)="selectAll()"
            caption=""
            placeholder=""
            name="assignedCheck0"
            fieldName="assignedCheck0"
          ></slx-checkbox-input>
        </slx-input-decorator>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <slx-input-decorator class="slx-special-decorator">
          <slx-checkbox-input slx-input
            [(ngModel)]="dataItem.assigned"
            (ngModelChange)="onItemRestrictionChanged(dataItem, $event)"
            [readonly]="!state.isEditMode || !timeclockContainer?.timeclockAssignments?.isEditable"
            name="assignedCheck{{dataItem.id}}"
            fieldName="assignedCheck{{dataItem.id}}"
            caption=""
            placeholder=""
          ></slx-checkbox-input>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="physicalId" [sortable]="false" [width]="100">
      <ng-template kendoGridHeaderTemplate>
        Clock ID
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.physicalId }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="timeclockName" [width]="130">
      <ng-template kendoGridHeaderTemplate>
        Clock
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.timeclockName }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="clockOrganization" [width]="130">
      <ng-template kendoGridHeaderTemplate>
        Organization
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.clockOrganization  }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="departmentName" [width]="130">
      <ng-template kendoGridHeaderTemplate>
        Department
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.departmentName }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="restrictionName" [width]="150">
      <ng-template kendoGridHeaderTemplate>
        Restriction
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.restrictionName }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <slx-input-decorator className="slx-no-label slx-no-error-block">
          <slx-dropdown-input slx-input
            lookup="timeclockRestrictionDefinition"
            [(ngModel)]="dataItem.restriction"
            [readonly]="!timeclockContainer?.timeclockAssignments?.isEditable"
            name="restrictField"
            placeholder=" "
          >
          </slx-dropdown-input>
        </slx-input-decorator>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="lastPunch" [sortable]="false" [width]="120">
      <ng-template kendoGridHeaderTemplate>
        Last Punch
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.lastPunch | amDateFormat: appConfig.militaryDateTimeFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="lastUpdateRestriction" filter="date" [width]="120">
      <ng-template kendoGridHeaderTemplate>
        Last Assigned
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.lastUpdateRestriction  | amDateFormat: appConfig.militaryDateTimeFormat}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="lastDelivery" [sortable]="false" [width]="120">
      <ng-template kendoGridHeaderTemplate>
        Last Delivery
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.lastDelivery | amDateFormat: appConfig.militaryDateTimeFormat}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="lastDeliveryFP" [sortable]="false" [width]="150">
      <ng-template kendoGridHeaderTemplate>
        Last Delivery FP
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.lastDeliveryFP   | amDateFormat: appConfig.militaryDateTimeFormat}}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
