<div class="details-view">
  <div class="col-lg-4">
    <div class="emp-photo-box">
      <img class="box-image" [employeeThumbinalSrc]="employeeDetails.employee.id" alt="Employee Photo" />
    </div>
  </div>
  <div class="col-lg-8">
    <div>
      <button type="button" class="button-link" [employeeSectionLink]="employeeDetails?.employee?.id">
        {{employeeDetails?.employee?.name}}
      </button>
    </div>
    <div>
      {{employeeDetails.position.name}}
    </div>
    <div *ngIf="employeeDetails.scheduleStart||employeeDetails.scheduleEnd">
      <button type="button" class="button-link" scheduleEntryLink [employeeId]="employeeDetails.employee.id" [dateOn]="dateOn">
        Shift: {{employeeDetails.scheduleStart | amDateFormat: appConfig.timeFormat}}-{{employeeDetails.scheduleEnd | amDateFormat: appConfig.timeFormat}}
      </button>
    </div>
    <div>
      <button type="button" class="button-link" [dailyTimecardsLink]="employeeDetails.employee?.id" [timecardDate]="employeeDetails.actualStart ? employeeDetails.actualStart : dateOn">
      Punches: {{employeeDetails.actualStart | amDateFormat: appConfig.timeFormat}}-{{employeeDetails.actualEnd | amDateFormat: appConfig.timeFormat}}
      </button>
    </div>
    <div>
      Phone: {{employeeDetails.employee.phoneNumber}}
    </div>
    <div>
      Mobile: {{employeeDetails.employee.mobilePhoneNumber}}
    </div>
    <!--<div>
        Unit: {{employeeDetails.scheduleStart-employeeDetails.scheduleEnd}}
      </div>-->
  </div>
</div>
