import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { LookupApiService } from '../lookup/lookup-api.service';
var PayCycleHelperService = /** @class */ (function () {
    function PayCycleHelperService(lookupService) {
        this.lookupService = lookupService;
    }
    PayCycleHelperService.prototype.getPayCycleByDate = function (date, orgLevelId, employeeId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        return this.lookupService.getPayCyles(orgLevelId, employeeId, undefined, updateCacheForced)
            .then(function (cycles) {
            return _this.findPayCycleByDate(cycles, date);
        });
    };
    PayCycleHelperService.prototype.getLastPayCycleByDate = function (date, orgLevelId, employeeId, updateCacheForced) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        return this.lookupService.getPayCyles(orgLevelId, employeeId, undefined, updateCacheForced)
            .then(function (cycles) {
            return _this.findLastPayCycleByDate(cycles, date);
        });
    };
    PayCycleHelperService.prototype.getUnApprovedOrCurrentPayCycleByDate = function (date, orgLevelId, employeeId) {
        var _this = this;
        return this.lookupService.getPayCyles(orgLevelId, employeeId)
            .then(function (cycles) {
            return _this.findUnApprovedOrCurrentPayCycleByDate(cycles, date);
        });
    };
    PayCycleHelperService.prototype.findPayCycleByDate = function (cycles, date) {
        var selectedCycle = _.find(_.orderBy(cycles, function (p) { return p.startDate; }, 'asc'), function (cycle) {
            return moment(date).isSameOrAfter(cycle.startDate) && moment(date).isSameOrBefore(cycle.endDate);
        });
        return selectedCycle;
    };
    PayCycleHelperService.prototype.findLastPayCycleByDate = function (cycles, date) {
        var ordered = _.orderBy(cycles, function (p) { return p.startDate; }, 'asc');
        var selectedCycle = _.find(ordered, function (cycle) {
            return moment(date).isSameOrAfter(cycle.startDate) && moment(date).isSameOrBefore(cycle.endDate);
        });
        if (!selectedCycle) {
            return _.last(ordered);
        }
        return selectedCycle;
    };
    PayCycleHelperService.prototype.findUnApprovedOrCurrentPayCycleByDate = function (cycles, date) {
        var ordered = _.orderBy(cycles, function (p) { return p.startDate; }, 'asc');
        var index = _.findIndex(ordered, function (cycle) {
            return moment(date).isSameOrAfter(cycle.startDate) && moment(date).isSameOrBefore(cycle.endDate);
        });
        if (index < 0) {
            return null;
        }
        if (index > 1) {
            var prevPayCycle = ordered[index - 1];
            if (!prevPayCycle.isApproved) {
                return prevPayCycle;
            }
        }
        return ordered[index];
    };
    PayCycleHelperService.prototype.getEffectivePunchesDateRange = function (selectedDate, orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var allPayCycles, payCycles, outOfPayCycle, effectiveStartDate, effectiveEndDate, yesterday, alternativeStartLimit_1, alternativePayCycles;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.lookupService.getPayCyles(orgLevelId, null)];
                    case 1:
                        allPayCycles = _a.sent();
                        payCycles = _.filter(allPayCycles, function (pc) { return moment(selectedDate).isSameOrAfter(pc.startDate) && moment(selectedDate).isSameOrBefore(pc.endDate); });
                        outOfPayCycle = false;
                        if (payCycles.length === 0) {
                            outOfPayCycle = true;
                        }
                        effectiveStartDate = _.min(_.map(payCycles, function (payCycle) { return payCycle.startDate; }));
                        effectiveEndDate = _.max(_.map(payCycles, function (payCycle) { return payCycle.endDate; }));
                        yesterday = moment(new Date()).startOf('day').subtract(1, 'days');
                        if (outOfPayCycle || moment(effectiveEndDate).isSameOrAfter(yesterday)) {
                            effectiveEndDate = yesterday.toDate();
                            alternativeStartLimit_1 = moment(effectiveEndDate).subtract(30, 'days');
                            alternativePayCycles = _.filter(allPayCycles, function (payCycle) {
                                return moment(payCycle.startDate).isSameOrAfter(alternativeStartLimit_1) && moment(payCycle.startDate).isSameOrBefore(effectiveEndDate);
                            });
                            effectiveStartDate = _.min(_.map(alternativePayCycles, function (payCycle) { return payCycle.startDate; }));
                        }
                        return [2 /*return*/, { startDate: _.min([effectiveStartDate, effectiveEndDate]), endDate: _.max([effectiveStartDate, effectiveEndDate]) }];
                }
            });
        });
    };
    return PayCycleHelperService;
}());
export { PayCycleHelperService };
