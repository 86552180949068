
export interface ISchedulePartialAbsence {
  code: string;
  description: string;
  start: Date;
  end: Date;
  duration: number;
}

export class SchedulePartialAbsence implements ISchedulePartialAbsence {
  public code: string;
  public description: string;
  public start: Date;
  public end: Date;
  public duration: number;

  public get codeDescription(): string {
    return `[${this.code}] ${this.description}`;
  }
}
