import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { process, aggregateBy } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { DateRange } from '../../../../../core/models/index';
import { PbjReconciliationManagementService } from '../../../services/index';
import { PBJRecColumns } from '../../../models/index';
var PbjReconciliationDailyGridComponent = /** @class */ (function () {
    function PbjReconciliationDailyGridComponent(managementService) {
        this.managementService = managementService;
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'dateOn', dir: 'asc' }];
        var rbjRecCols = new PBJRecColumns();
        this.columns = rbjRecCols.makeColumns();
        this.aggregates = rbjRecCols.makeAggregates();
        this.pbjRecDaily = null;
        this.totals = {};
    }
    PbjReconciliationDailyGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.managementService.subscribeToOrgLevel(function (orgLevel) {
            _this.orgLevel = orgLevel;
            _this.loadData();
        });
        this.reconSubscription = this.managementService.subscribeToReconEmployee(function (data) {
            _this.currentEmpId = data.employeeId;
            _this.currentEmpName = data.employeeName;
            _this.dateRange = new DateRange(data.startDate, data.endDate);
            _this.loadData();
        });
        this.expandedDetailsSubscription = this.managementService.subscribeToExpandedDetails(function (isOn) {
            _this.showDetails = isOn;
        });
        this.loadedEntriesSubscription = this.managementService.subscribeToLoadedDailyEntries(function (entries) {
            _this.pbjRecDaily = entries;
            _this.refreshGrid();
        });
        this.exportToPdfSubscription = this.managementService.subscribeToExportToPdf(function () {
            _this.grid.saveAsPDF();
        });
        this.exportToExcelSubscription = this.managementService.subscribeToExportToExcel(function () {
            _this.grid.saveAsExcel();
        });
        this.refreshGridSubscription = this.gridState.onRefreshGrid.subscribe(function () {
            _this.refreshGrid();
        });
    };
    PbjReconciliationDailyGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(PbjReconciliationDailyGridComponent.prototype, "fileName", {
        get: function () {
            return this.currentEmpName + " Pbj Reconciliation.pdf";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjReconciliationDailyGridComponent.prototype, "xlsxFileName", {
        get: function () {
            return this.currentEmpName + " Pbj Reconciliation.xlsx";
        },
        enumerable: true,
        configurable: true
    });
    PbjReconciliationDailyGridComponent.prototype.defineRowClass = function () {
        return function (row) {
            return +row.dataItem.reconciliation.variance === 0 ? 'reconcil-row' : 'reconcil-row reconcil-highlighted';
        };
    };
    PbjReconciliationDailyGridComponent.prototype.loadData = function () {
        if (_.isNumber(this.currentEmpId) &&
            _.isDate(_.get(this.dateRange, 'startDate')) &&
            _.isDate(_.get(this.dateRange, 'endDate'))) {
            this.managementService.loadDailyEntries(this.orgLevel.id, this.currentEmpId, this.dateRange.startDate, this.dateRange.endDate);
        }
    };
    PbjReconciliationDailyGridComponent.prototype.refreshGrid = function () {
        if (!this.pbjRecDaily) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.pbjRecDaily, this.gridState.state);
        this.totals = aggregateBy(this.gridState.view.data, this.aggregates);
    };
    PbjReconciliationDailyGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.gridState.view.data, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationDailyGridComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationDailyGridComponent.prototype, "loadedEntriesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationDailyGridComponent.prototype, "refreshGridSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationDailyGridComponent.prototype, "reconSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationDailyGridComponent.prototype, "expandedDetailsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationDailyGridComponent.prototype, "exportToPdfSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationDailyGridComponent.prototype, "exportToExcelSubscription", void 0);
    return PbjReconciliationDailyGridComponent;
}());
export { PbjReconciliationDailyGridComponent };
