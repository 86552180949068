import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { appConfig, IApplicationConfig } from '../../../../../../app.config';

import { EmployeeWarningSections, EmployeeWarningExtended, EmployeeWarningSectionIncidentDescription } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-warnings-incident-description',
  templateUrl: 'employee-sections-warnings-incident-description.component.html',
  styleUrls: ['employee-sections-warnings-incident-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsWarningsIncidentDescriptionComponent implements OnInit, OnDestroy {
  public incidentDescription: EmployeeWarningSectionIncidentDescription;
  public incidentText: string;

  @unsubscribeAll()
  public subscriptions: StringMap<Subscription> = {};
  @ViewChild('form', { static: true })
  public form: NgForm;
  public appConfig: IApplicationConfig = appConfig;

  constructor(
    private readonly manService: EmployeeSectionWarningsManagementService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.incidentDescription = this.manService.getIncidentDescriptionSection();

    this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(warning => {
      this.assignIncident(warning);
      this.assignIncidentData();
      this.updateView();
    });

    this.subscriptions.statusChanges = this.form.statusChanges.subscribe(() => {
      this.manService.changeSectionValidity(EmployeeWarningSections.CustomIncidentDescription, this.form.valid);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onChangeIncidentDescription(): void {
    this.incidentDescription.data.incidentDescription = this.incidentText;
    this.manService.markWarningAsEdited(true);
  }

  private updateView(): void {
    Observable.of(true)
      .delay(200)
      .subscribe(() => this.changeDetector.detectChanges());
  }

  private assignIncident(warning: EmployeeWarningExtended): void {
    const incidentDescription = _.find(warning.sections, s => s.isCustomIncidentDescription);
    if (_.isObjectLike(incidentDescription) && _.isObjectLike(incidentDescription.data)) {
      this.incidentDescription = incidentDescription as EmployeeWarningSectionIncidentDescription;
    }
  }

  private assignIncidentData(): void {
    this.incidentText = this.incidentDescription.data.incidentDescription;
  }
}
