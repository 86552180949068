import * as _ from 'lodash';
import { Component, Input, forwardRef, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { appMessages, IApplicationMessages } from '../../../../app.messages';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';
import { ListActionsService, ConstraintsConfigurationManagementService } from '../../../services/index';

import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';

import { ConstraintModel } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';

@Component({
    moduleId: module.id,
    selector: 'slx-configure-constraint-editor',
    templateUrl: 'configure-constraint-editor.component.html',
    styleUrls: ['configure-constraint-editor.component.scss'],
    providers: [
        {
            provide: CustomListActorBase,
            useExisting: forwardRef(() => ConfigureConstraintsEditorComponent)
        }
    ]
})
export class ConfigureConstraintsEditorComponent extends EditableListEditorComponent implements OnInit {

    @Input()
    public rowIndex: number;

    @Input()
    public prohibitedNameValues: string[] = [];

    @Input()
    public prohibitedCodeValues: string[] = [];

    @Input()
    public set item(value: ConstraintModel) {
        this.privateItem = value;
    }

    public get item(): ConstraintModel {
        return this.privateItem;
    }

    public appMessages: IApplicationMessages = appMessages;

    private privateItem: ConstraintModel;

    @unsubscribe()
    private mainFormSubscription: Subscription;

    constructor(private management: ConstraintsConfigurationManagementService) {
        super();
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.mainFormSubscription = this.formGroup.statusChanges.subscribe(() => {
            if (this.formGroup.dirty) {
                this.management.markAsDirty();
            }
        });
    }

    protected createFormGroup(): FormGroup {
        let group: FormGroup = new FormGroup({
            code: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            countAs: new FormControl('', Validators.required)
        });
        return group;
    }

    protected updateFormGroup(): void {
        this.formGroup.get('name').setValue(this.item.name);
        this.formGroup.get('code').setValue(this.item.code);
        this.formGroup.get('countAs').setValue(this.item.countAs);
    }


    protected updateItem(): void {
        this.item.name = this.formGroup.get('name').value;
        this.item.code = this.formGroup.get('code').value;
        this.item.countAs = this.formGroup.get('countAs').value;
    }

}
