import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../../app.config';
import { StateResetTypes } from '../../../../../core/models/settings/reset-types';
import { KendoGridStateHelper, LoaActions, LoaRequestClass } from '../../../../../common/models/index';
import { LoaRequest, LoaRequestTabs } from '../../../models/index';
import { LMRosterManagementService, LMManagementService } from '../../../services/index';
import * as filterUtils from '../../../../../core/utils/kendo-ui-utils';
var LMRosterListComponent = /** @class */ (function () {
    function LMRosterListComponent(manService, manRosterService) {
        this.manService = manService;
        this.manRosterService = manRosterService;
        this.pageSize = 30;
        this.typeFilter = [];
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [{ field: 'estStartDate', dir: 'desc' }];
        this.gridState.gridComponentKey = 'LMGrid';
        this.gridState.gridControlStateKey = 'GridFiltersState';
        this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.gridState.assignRestoredState = true;
        this.gridState.refreshGridAfterRestoreState = true;
        this.appConfig = appConfig;
        this.loaTypes = [];
    }
    LMRosterListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.loaded = this.manRosterService
            .subscribeToRequestsLoaded(function (container) { return _this.assignRecords(container.requests); });
        this.subscriptions.categoryFilter = this.manRosterService
            .subscribeToCategoryFilter(function (categories) { return _this.applyFilter(categories); });
        this.subscriptions.export = this.manRosterService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.state = this.manRosterService
            .subscribeToChangeState(function (s) { return _this.applyState(s); });
        this.subscriptions.newLoaRequest = this.manService
            .subscribeToLoadRequests(function () { return _this.manRosterService.loadLoaRequests(); });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
        this.subscriptions.routeFilters = this.manRosterService
            .subscribeToRouteFilter(function (filter) { return _this.applyFilterFromRoute(filter); });
    };
    LMRosterListComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMRosterListComponent.prototype.onClickAttachaments = function (request) {
        this.manService.openCreationDialog(null, request, LoaRequestTabs.Attachments);
    };
    LMRosterListComponent.prototype.getWidth = function (field) {
        return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].width : 100;
    };
    LMRosterListComponent.prototype.getFilter = function (field) {
        return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].filter : null;
    };
    LMRosterListComponent.prototype.isVisible = function (field) {
        return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].displayed : false;
    };
    LMRosterListComponent.prototype.getMediaForDesktop = function () {
        return "(min-width: " + (this.appConfig.mobileMaxWidth + 1) + "px)";
    };
    LMRosterListComponent.prototype.getMediaForMobile = function () {
        return "(max-width: " + this.appConfig.mobileMaxWidth + "px)";
    };
    LMRosterListComponent.prototype.onClickEmployee = function (req) {
        this.manService.openCreationDialog(null, req);
    };
    LMRosterListComponent.prototype.onChangeLoaTypeFilter = function (values, filterService) {
        filterService.filter({
            filters: _.map(values, function (val) { return ({
                field: 'type.name',
                operator: 'eq',
                value: val.name
            }); }),
            logic: 'or'
        });
    };
    LMRosterListComponent.prototype.categoryFilters = function (filter) {
        var _a;
        (_a = this.typeFilter).splice.apply(_a, [0, this.typeFilter.length].concat(filterUtils.flatten(filter).map(function (_a) {
            var value = _a.value;
            return { name: value, color: null };
        })));
        return this.typeFilter;
    };
    LMRosterListComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    LMRosterListComponent.prototype.assignRecords = function (records) {
        this.records = records;
        this.storedRecords = _.clone(records);
        this.loaTypes = _.uniqBy(_.map(this.storedRecords, function (r) { return r.type; }), function (l) { return l.name; });
        this.refreshGrid();
    };
    LMRosterListComponent.prototype.applyFilter = function (categories) {
        this.selectedCategories = categories;
        this.gridState.assignRestoredState = _.size(categories) === 0;
        this.refreshGrid();
    };
    LMRosterListComponent.prototype.applyState = function (s) {
        this.state = s;
    };
    LMRosterListComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    LMRosterListComponent.prototype.applyFilterFromRoute = function (filter) {
        var _this = this;
        this.selectedloaTypes = [];
        var gridValid = true;
        if (_.size(filter.loaType) > 0) {
            this.selectedloaTypes = [filter.loaType];
            this.gridState.state.filter = filterUtils.composeFieldToKendoFilter(this.gridState.state.filter, 'type.name', 'eq', filter.loaType);
            gridValid = false;
            this.gridState.assignRestoredState = false;
        }
        this.selectedEmpId = null;
        if (_.isFinite(filter.empId)) {
            this.selectedEmpId = filter.empId;
            this.gridState.state.filter = filterUtils.composeFieldToKendoFilter(this.gridState.state.filter, 'employee.id', 'eq', filter.empId);
            gridValid = false;
            this.gridState.assignRestoredState = false;
        }
        if (!gridValid) {
            this.refreshGrid();
        }
        if (_.size(filter.action) > 0) {
            switch (filter.action) {
                case LoaActions.CreateNewContinuous:
                    if (!this.windowOpenedByLoaAction) {
                        this.windowOpenedByLoaAction = true;
                        _.delay(function () {
                            var req = new LoaRequest();
                            req.requestClass = LoaRequestClass.Continuous;
                            req.requestDate = new Date();
                            req.employee = { id: filter.empId, name: null };
                            _this.manService.openCreationDialog(null, req);
                        }, 1000);
                    }
                    break;
                case LoaActions.CreateNewIntermittent:
                    if (!this.windowOpenedByLoaAction) {
                        this.windowOpenedByLoaAction = true;
                        _.delay(function () {
                            var req = new LoaRequest();
                            req.requestClass = LoaRequestClass.Intermittent;
                            req.requestDate = new Date();
                            req.employee = { id: filter.empId, name: null };
                            _this.manService.openCreationDialog(null, req);
                        }, 1000);
                    }
                    break;
            }
        }
    };
    LMRosterListComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.selectedCategories || this.selectedCategories.length === 0) {
            this.records = _.clone(this.storedRecords);
        }
        else {
            this.records = _.filter(this.storedRecords, function (r) {
                var res = false;
                _.forEach(_this.selectedCategories, function (category) {
                    res = res || _.includes(r.loaCategories, category);
                });
                return res;
            });
        }
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.records.map(function (each) {
            each.daysOnLeaveText = each.isIntermittent ? 0 : each.daysOnLeave;
            each.daysRemainingText = each.isIntermittent ? 0 : each.daysRemaining;
        });
        this.gridState.view = process(this.records, this.gridState.state);
    };
    return LMRosterListComponent;
}());
export { LMRosterListComponent };
