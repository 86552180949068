import { WcViewList } from './wc-view-list';
var WcViewItem = /** @class */ (function () {
    function WcViewItem(id, name, path) {
        this.id = id;
        this.name = name;
        this.path = path;
    }
    Object.defineProperty(WcViewItem.prototype, "isIncidentRoster", {
        get: function () {
            return this.id === WcViewList.IncidentRoster;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcViewItem.prototype, "isComparisons", {
        get: function () {
            return this.id === WcViewList.Comparisons;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcViewItem.prototype, "isSummary", {
        get: function () {
            return this.id === WcViewList.Summary;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcViewItem.prototype, "isRepeatInjuryList", {
        get: function () {
            return this.id === WcViewList.RepeatInjuryList;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcViewItem.prototype, "isOshaBudgetTracker", {
        get: function () {
            return this.id === WcViewList.OshaBudgetTracker;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcViewItem.prototype, "isCreateOshaRkForms", {
        get: function () {
            return this.id === WcViewList.CreateOshaRkForms;
        },
        enumerable: true,
        configurable: true
    });
    return WcViewItem;
}());
export { WcViewItem };
