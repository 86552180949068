import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'slx-pending-messages-badge',
  templateUrl: 'pending-messages-badge.component.html',
  styleUrls: ['pending-messages-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PendingMessagesBadgeComponent implements OnChanges {
  @Input()
  public messageCount: number;

  @Input()
  public zoom: boolean;

  private router: Router;
  private activatedRoute: ActivatedRoute;

  constructor(router: Router, activatedRoute: ActivatedRoute) {
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.messageCount = 10;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['messageCount']) {
      //so mething
    }
  }

  public isEmpty(): boolean {
    return this.messageCount === 0;
  }
}
