import { EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
var OrgStructureCodesTabComponent = /** @class */ (function () {
    function OrgStructureCodesTabComponent() {
        var _this = this;
        this.tabName = '';
        this.tabData = [];
        this.saveDataHandler = new EventEmitter();
        this.invalidField = false;
        this.isReadonly = false;
        this.errorText = '';
        this.debouncedSearch = function (event) { return _this.search(event.target.value); };
        this.trimChar = function (event) {
            if (event.target.value.trim().length === 0) {
                _this.invalidField = true;
            }
            else {
                _this.invalidField = false;
            }
        };
    }
    OrgStructureCodesTabComponent.prototype.ngOnInit = function () {
        this.columnName = this.tabName;
    };
    OrgStructureCodesTabComponent.prototype.editHandler = function (_a) {
        var sender = _a.sender, rowIndex = _a.rowIndex, dataItem = _a.dataItem;
        this.closeEditor(sender);
        this.invalidField = false;
        this.errorText = '';
        this.formGroup = new FormGroup({
            Description: new FormControl(dataItem.description),
            ShortCode: new FormControl(dataItem.shortCode, Validators.compose([
                Validators.required,
                Validators.pattern(this.tabName === 'Positions'
                    ? '^[0-9]{1,4}'
                    : this.tabName === 'Organization'
                        ? '^(?!0)[0-9]{5}'
                        : this.tabName === 'Departments'
                            ? '^[0-9]{1,4}'
                            : '^(?!0)[0-9]{2}'),
            ])),
            PayrollDescription: new FormControl(dataItem.payrollDescription, Validators.compose(this.tabName === 'Positions' ? [Validators.required] : [])),
        });
        this.editedRowIndex = rowIndex;
        // put the row in edit mode, with the `FormGroup` build above
        this.formGroup.updateValueAndValidity();
        sender.editRow(rowIndex, this.formGroup);
    };
    OrgStructureCodesTabComponent.prototype.cancelHandler = function (args) {
        // close the editor for the given row
        this.invalidField = false;
        this.closeEditor(args.sender, args.rowIndex);
    };
    OrgStructureCodesTabComponent.prototype.saveHandler = function (_a) {
        var sender = _a.sender, rowIndex = _a.rowIndex, formGroup = _a.formGroup, isNew = _a.isNew;
        var shortCode = formGroup.value.ShortCode;
        if (_.toInteger(shortCode) === 0 || _.toInteger(shortCode) < 0) {
            this.invalidField = true;
            this.errorText = "Error: The value entered is invalid for " + this.tabName + ".";
            return;
        }
        else {
            this.invalidField = false;
            this.errorText = '';
        }
        if (this.tabName === 'Departments' || this.tabName === 'Positions') {
            var validateScode = shortCode.padStart(4, '0');
            this.formGroup.controls.ShortCode.setValue(validateScode);
        }
        if (this.tabName === 'Positions') {
            var description = formGroup.value.PayrollDescription.trim();
            this.formGroup.controls.PayrollDescription.setValue(description);
        }
        this.formGroup.updateValueAndValidity();
        var checkDuplicate = _.findIndex(this.tabData, function (el) { return _.toInteger(el.shortCode) === _.toInteger(shortCode); });
        if (checkDuplicate !== -1 && this.editedRowIndex !== checkDuplicate) {
            this.invalidField = true;
            this.errorText = "Error: The value entered is already in use for a different " + this.tabName + ".";
            return;
        }
        else {
            this.invalidField = false;
            this.errorText = '';
        }
        this.saveDataHandler.emit({
            rowIndex: rowIndex,
            formGroup: formGroup,
            isNew: isNew,
            tabName: this.tabName
        });
        sender.closeRow(rowIndex);
    };
    OrgStructureCodesTabComponent.prototype.closeEditor = function (grid, rowIndex) {
        if (rowIndex === void 0) { rowIndex = this.editedRowIndex; }
        grid.closeRow(rowIndex);
        this.editedRowIndex = undefined;
        this.formGroup = undefined;
    };
    OrgStructureCodesTabComponent.prototype.trackByItem = function (index, item) {
        return item.data['shortCode'];
    };
    OrgStructureCodesTabComponent.prototype.search = function (value) {
        if (value.length === 0) {
            this.invalidField = false;
            return;
        }
        if (isNaN(value)) {
            this.invalidField = true;
            this.errorText = "Error: The Code should be numeric only.";
            return;
        }
        this.invalidField = false;
    };
    OrgStructureCodesTabComponent.prototype.getMaxLength = function () {
        return this.tabName === 'Positions'
            ? 4
            : this.tabName === 'Organization'
                ? 5
                : this.tabName === 'Departments'
                    ? 4
                    : 2;
    };
    return OrgStructureCodesTabComponent;
}());
export { OrgStructureCodesTabComponent };
