/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vacation-planner-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/actions-list/actions-list-item/actions-list-item.component.ngfactory";
import * as i3 from "../../../../common/components/actions-list/actions-list-item/actions-list-item.component";
import * as i4 from "ngx-popper";
import * as i5 from "../../../../common/components/input-decorator/input-decorator.component.ngfactory";
import * as i6 from "../../../../common/components/input-decorator/input-decorator.component";
import * as i7 from "../../../../common/components/checkbox-input/checkbox-input.component.ngfactory";
import * as i8 from "../../../../common/components/checkbox-input/checkbox-input.component";
import * as i9 from "@angular/forms";
import * as i10 from "../../../../common/components/actions-list/actions-list.component.ngfactory";
import * as i11 from "../../../../common/components/actions-list/actions-list.component";
import * as i12 from "../../../../common/components/actions-list/actions-button/actions-button.component.ngfactory";
import * as i13 from "../../../../common/components/actions-list/actions-button/actions-button.component";
import * as i14 from "../../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i15 from "@angular/common";
import * as i16 from "../../../../common/services/state-management/state-management.service";
import * as i17 from "../../../../common/services/component-state/component-state-storage.service";
import * as i18 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i19 from "./vacation-planner-header.component";
import * as i20 from "../../../../common/services/modal/modal.service";
var styles_VacationPlannerHeaderComponent = [i0.styles];
var RenderType_VacationPlannerHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VacationPlannerHeaderComponent, data: {} });
export { RenderType_VacationPlannerHeaderComponent as RenderType_VacationPlannerHeaderComponent };
function View_VacationPlannerHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-actions-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onSelectYear(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ActionsListItemComponent_0, i2.RenderType_ActionsListItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.ActionsListItemComponent, [i4.PopperContent], { className: [0, "className"] }, { onClick: "onClick" }), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.settings.year === _v.context.$implicit) ? "active" : ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
function View_VacationPlannerHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "slx-input-decorator", [["className", "slx-no-border slx-no-label slx-no-error-block slx-small-font"]], null, null, null, i5.View_InputDecoratorComponent_0, i5.RenderType_InputDecoratorComponent)), i1.ɵdid(2, 311296, null, 4, i6.InputDecoratorComponent, [i1.ElementRef, i1.Renderer2], { className: [0, "className"] }, null), i1.ɵqud(603979776, 1, { fieldChild1: 0 }), i1.ɵqud(603979776, 2, { fieldChild2: 0 }), i1.ɵqud(603979776, 3, { inputPolicyDirective: 0 }), i1.ɵqud(603979776, 4, { errorMessages: 1 }), (_l()(), i1.ɵeld(7, 0, null, 0, 6, "slx-checkbox-input", [["slx-input", ""]], [[8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; if (("ngModelChange" === en)) {
        var pd_0 = ((_v.context.$implicit.displayed = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_CheckboxInputComponent_0, i7.RenderType_CheckboxInputComponent)), i1.ɵdid(8, 49152, null, 0, i8.CheckboxInputComponent, [i1.ElementRef, i1.ChangeDetectorRef], { fieldName: [0, "fieldName"], caption: [1, "caption"] }, null), i1.ɵprd(1024, null, i9.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.CheckboxInputComponent]), i1.ɵdid(10, 671744, null, 0, i9.NgModel, [[8, null], [8, null], [8, null], [6, i9.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i9.NgControl, null, [i9.NgModel]), i1.ɵdid(12, 540672, [[2, 4]], 0, i6.SlxInputAltDirective, [[2, i9.NgControl], i1.ElementRef], null, null), i1.ɵdid(13, 16384, null, 0, i9.NgControlStatus, [[4, i9.NgControl]], null, null)], function (_ck, _v) { var currVal_0 = "slx-no-border slx-no-label slx-no-error-block slx-small-font"; _ck(_v, 2, 0, currVal_0); var currVal_9 = i1.ɵinlineInterpolate(1, "column", _v.context.index, ""); var currVal_10 = _v.context.$implicit.description; _ck(_v, 8, 0, currVal_9, currVal_10); var currVal_11 = i1.ɵinlineInterpolate(1, "column", _v.context.index, ""); var currVal_12 = _v.context.$implicit.displayed; _ck(_v, 10, 0, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 12).className; var currVal_2 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 13).ngClassValid; var currVal_7 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_VacationPlannerHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Choose a year:"])), (_l()(), i1.ɵted(-1, null, ["\u00A0\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "slx-actions-list", [], null, null, null, i10.View_ActionsListComponent_0, i10.RenderType_ActionsListComponent)), i1.ɵdid(4, 49152, null, 0, i11.ActionsListComponent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 1, 3, "slx-actions-button", [], null, null, null, i12.View_ActionsButtonComponent_0, i12.RenderType_ActionsButtonComponent)), i1.ɵdid(6, 49152, null, 0, i13.ActionsButtonComponent, [], { popperContent: [0, "popperContent"], popperPosition: [1, "popperPosition"] }, null), (_l()(), i1.ɵted(7, 0, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, 0, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, 1, 3, "popper-content", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_PopperContent_0, i14.RenderType_PopperContent)), i1.ɵdid(10, 180224, [["popperContent", 4]], 0, i4.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VacationPlannerHeaderComponent_1)), i1.ɵdid(12, 278528, null, 0, i15.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 19, "div", [["class", "dropdown"]], null, null, null, null, null)), i1.ɵprd(512, null, i15.ɵNgClassImpl, i15.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i15.NgClass, [i15.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { "open": 0 }), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["aria-expanded", "true"], ["aria-haspopup", "true"], ["class", "theme-icon-button dropdown-toggle"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleColumnsMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-cog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 13, "div", [["aria-labelledby", "group-activities"], ["class", "theme-action-button theme-accent-men dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Columns to display"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VacationPlannerHeaderComponent_2)), i1.ɵdid(24, 278528, null, 0, i15.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 4, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "button", [["class", "theme-button-cancel btn-small margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Select All "])), (_l()(), i1.ɵeld(28, 0, null, null, 1, "button", [["class", "theme-button-cancel btn-small"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearAll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Clear All "])), (_l()(), i1.ɵeld(30, 0, null, null, 2, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "button", [["class", "theme-button-apply btn-small"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.columnSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Apply "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 10); var currVal_1 = "bottom-start"; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_3 = _co.yearItems; _ck(_v, 12, 0, currVal_3); var currVal_4 = "dropdown"; var currVal_5 = _ck(_v, 16, 0, _co.columnsMenuOpened); _ck(_v, 15, 0, currVal_4, currVal_5); var currVal_6 = _co.settings.columns; _ck(_v, 24, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.settings.year; _ck(_v, 7, 0, currVal_2); }); }
export function View_VacationPlannerHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-vacation-planner-header", [], null, null, null, View_VacationPlannerHeaderComponent_0, RenderType_VacationPlannerHeaderComponent)), i1.ɵprd(512, null, i16.StateManagementService, i16.StateManagementService, [i17.ComponentStateStorageService, i18.ColumnSettingsStorageService]), i1.ɵdid(2, 245760, null, 0, i19.VacationPlannerHeaderComponent, [i20.ModalService, i16.StateManagementService, i17.ComponentStateStorageService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var VacationPlannerHeaderComponentNgFactory = i1.ɵccf("slx-vacation-planner-header", i19.VacationPlannerHeaderComponent, View_VacationPlannerHeaderComponent_Host_0, {}, { settingsChanged: "settingsChanged" }, []);
export { VacationPlannerHeaderComponentNgFactory as VacationPlannerHeaderComponentNgFactory };
