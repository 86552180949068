import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, HostBinding, Self } from '@angular/core';
import { ControlValueAccessor, NgControl, Validator, ValidationErrors, AbstractControl } from '@angular/forms';

import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor, createValidators, dateTimeUtils } from '../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-datetimepicker',
  templateUrl: 'datetimepicker.component.html',
  providers: [
    createValuAccessor(DateTimePickerInputComponent),
    createValidators(DateTimePickerInputComponent)
  ]
})
export class DateTimePickerInputComponent implements ControlValueAccessor, Validator {
  @Input()
  public set minDateTime(v: Date) {
    if (_.isDate(v) || _.isNull(v)) {
      this.validateMinDate(v);
    }
  }
  @Input()
  public set maxDateTime(v: Date) {
    if (_.isDate(v) || _.isNull(v)) {
      this.validateMaxDate(v);
    }
  }
  @Input()
  public readonly: boolean = false;
  @Input()
  public placeholder: string = '';
  @Input()
  public dateFormat: string = 'MM/dd/yyyy';
  @Input()
  public timeFormat: string = 'hh:mm a';
  @Input()
  public readonlyOnlyTime: boolean = false;
  @Input()
  public readonlyTimeWithoutDate: boolean = false;
  @Input()
  public set watermarkStyleUnlessTouched(v: boolean) {
    this.watermarkClass = !!v;
  }

  @HostBinding('class.slx-watermark')
  public watermarkClass: boolean;

  public get hasPlaceholder(): boolean {
    return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
  }

  public get isReadonlyUntilDateInput(): boolean {
    return this.readonlyTimeWithoutDate && !_.isDate(this.date);
  }
  public date: Date;
  public time: Date;
  public minTime: Date;
  public maxTime: Date;
  public minDate: Date;
  public maxDate: Date;

  @HostBinding('class.slx-datetimepicker')
  private mainCssClass = true;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private onValidationChange: () => void = _.noop;
  private limitMinDate: Date;
  private limitMaxDate: Date;

  constructor() {}

  public onChangeDate(date: Date): void {
    this.date = date;
    const hasTime = _.isDate(this.time);
    const dateIsMinDate = this.isSameDay(this.date, this.limitMinDate);
    const dateIsMaxDate = this.isSameDay(this.date, this.limitMaxDate);
    if (dateIsMinDate || dateIsMaxDate) {
      if (dateIsMinDate) {
        this.minTime = this.cloneDate(this.limitMinDate);
      }
      if (dateIsMaxDate) {
        this.maxTime = this.cloneDate(this.limitMaxDate);
      }
      if (!hasTime) {
        this.time = this.cloneDate(this.date);
      }
    } else {
      this.minTime = this.setTimeToStartDay(this.limitMinDate);
      this.maxTime = this.setTimeToEndDay(this.limitMaxDate);
      if (!hasTime) {
        this.time = this.cloneDate(this.date);
      }
    }

    if (hasTime) {
      this.time = this.copyDate(this.date, this.time);
      if (this.isSourceLess(this.time, this.limitMinDate)) {
        this.time = this.cloneDate(this.limitMinDate);
        this.date = this.copyTime(this.time, this.date);
      } else if (this.isSourceGreater(this.time, this.limitMaxDate)) {
        this.time = this.cloneDate(this.limitMaxDate);
        this.date = this.copyTime(this.time, this.date);
      }
    }

    this.onValidationChange();
    this.onChangeCallback(this.date);
  }

  public onChangeTime(time: Date): void {
    this.time = time;
    this.date = this.copyTime(this.time, this.date);

    this.onValidationChange();
    this.onChangeCallback(this.date);
  }

  public writeValue(date: Date): void {
    if (_.isDate(date) || _.isNull(date)) {
      this.date = this.cloneDate(date);
      this.time = this.cloneDate(date);
    }

    this.onValidationChange();
    this.onChangeCallback(this.date);
  }

  public onBlur(): void {
    this.onTouchedCallback();
  }

  public registerOnChange(fn: () => void): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  public validate(control: AbstractControl): ValidationErrors {
    const isValid = this.isValidDate(this.date);
    if (!isValid) {
      return { date: true };
    }

    if (this.isSourceLess(this.date, this.limitMinDate)) {
      return { minDateTime: true };
    }

    if (this.isSourceGreater(this.date, this.limitMaxDate)) {
      return { maxDateTime: true };
    }

    return null;
  }


  private validateMinDate(minDate: Date) {
    this.limitMinDate = this.cloneDate(minDate);
    this.minDate = this.cloneDate(this.limitMinDate);

    this.onValidationChange();
  }

  private validateMaxDate(maxDate: Date) {
    this.limitMaxDate = this.cloneDate(maxDate);
    this.maxDate = this.cloneDate(this.limitMaxDate);

    this.onValidationChange();
  }

  private cloneDate(d: Date): Date {
    if (_.isDate(d)) {
      return new Date(d.getTime());
    }
    return null;
  }

  private isSameDay(s: Date, t: Date): boolean {
    if (_.isDate(s) && _.isDate(t)) {
      return this.getDayStr(s) === this.getDayStr(t);
    }
    return false;
  }

  private setTimeToStartDay(d: Date): Date {
    if (_.isDate(d)) {
      const date = this.cloneDate(d);
      date.setHours(0, 0, 0, 0);

      return date;
    }
    return null;
  }

  private setTimeToEndDay(d: Date): Date {
    if (_.isDate(d)) {
      const date = this.cloneDate(d);
      date.setHours(23, 59, 59, 59);

      return date;
    }
    return null;
  }

  private isSourceLess(source: Date, target: Date): boolean {
    if (_.isDate(source) && _.isDate(target)) {
      return source.getTime() < target.getTime();
    }
    return false;
  }

  private isSourceGreater(source: Date, target: Date): boolean {
    if (_.isDate(source) && _.isDate(target)) {
      return source.getTime() > target.getTime();
    }
    return false;
  }

  private getDayStr(d: Date): string {
    if (_.isDate(d)) {
      return `${d.getDate()}-${d.getMonth()}-${d.getFullYear()}`;
    }
    return '';
  }

  private copyDate(source: Date, target: Date): Date {
    if (_.isDate(source) && _.isDate(target)) {
      const tDate = this.cloneDate(target);
      tDate.setDate(source.getDate());
      tDate.setMonth(source.getMonth());
      tDate.setFullYear(source.getFullYear());

      return tDate;
    }
    return null;
  }

  private copyTime(source: Date, target: Date): Date {
    if (_.isDate(source) && _.isDate(target)) {
      const tDate = this.cloneDate(target);
      tDate.setHours(source.getHours(), source.getMinutes(), source.getSeconds(), 0);

      return tDate;
    }
    return null;
  }

  private isValidDate(currentValue: Date): boolean {
    return dateTimeUtils.validateDate(currentValue);
  }
}
