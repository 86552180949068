import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit, ElementRef, QueryList, Renderer2 } from '@angular/core';
import { dateTimeUtils } from '../../../../common/utils/index';
import { ArrivalsDeparturesTimelineDetailsManagementService } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/bufferCount';
import * as moment from 'moment';
import * as _ from 'lodash';
import { appConfig } from '../../../../app.config';
import { ArrivalsDeparturesDetails, ArrivalsDeparturesDetailsRecord, ArrivalsDeparturesTimelineItem, ArrivalsDeparturesTimelineItemType, LinePunch, ArrivalDeparturesContainer, Arrival, Departure, ArrivalsDeparturesTimelineViewModeDefinition } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimelineService } from '../../../../common/services/index';
var ArrivalsDeparturesTimelineComponent = /** @class */ (function () {
    function ArrivalsDeparturesTimelineComponent(changeDetector, managementService, timelineService, renderer) {
        var _this = this;
        this.changeDetector = changeDetector;
        this.managementService = managementService;
        this.timelineService = timelineService;
        this.renderer = renderer;
        this.scheduleStart = ArrivalsDeparturesTimelineItemType.scheduleStart;
        this.scheduleEnd = ArrivalsDeparturesTimelineItemType.scheduleEnd;
        this.punchIn = ArrivalsDeparturesTimelineItemType.punchIn;
        this.punchOut = ArrivalsDeparturesTimelineItemType.punchOut;
        this.late = ArrivalsDeparturesTimelineItemType.late;
        this.ot = ArrivalsDeparturesTimelineItemType.ot;
        this.viewMode = 'GroupByShift';
        this.changeSubscription = this.timelineService.changeDetection.subscribe(function () {
            _this.changeDetection();
        });
        this.appConfig = appConfig;
    }
    Object.defineProperty(ArrivalsDeparturesTimelineComponent.prototype, "eventsWrapperWidth", {
        get: function () {
            return this.timelineService.eventsWrapperWidth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalsDeparturesTimelineComponent.prototype, "loaded", {
        get: function () {
            return this.timelineService.loaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalsDeparturesTimelineComponent.prototype, "prevLinkInactive", {
        get: function () {
            return this.timelineService.prevLinkInactive;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArrivalsDeparturesTimelineComponent.prototype, "nextLinkInactive", {
        get: function () {
            return this.timelineService.nextLinkInactive;
        },
        enumerable: true,
        configurable: true
    });
    ArrivalsDeparturesTimelineComponent.prototype.changeDetection = function () {
        this.changeDetector.detectChanges();
    };
    ArrivalsDeparturesTimelineComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onStatusSubscription = this.managementService.onLoadStatus$.subscribe(function (isLock) {
            if (isLock) {
                _this.unfilteredItems = null;
                _this.details = null;
                _this.container = null;
            }
        });
        this.toggleOverrageSubscription = this.managementService.onToogleShowOverragesView$.subscribe(function (value) {
            _this.isShowOverages = value;
            _this.changeDetection();
        });
        this.onToogleTimelineViewSubscription = this.managementService.onToogleTimelineView$.subscribe(function (mode) {
            if (!_this.config) {
                return;
            }
            if (_this.viewMode !== mode) {
                _this.viewMode = mode;
                if (!_this.items || !_this.unfilteredItems) {
                    return;
                }
                _this.setViewMode();
            }
        });
        this.loadedSubscription = this.managementService.onLoaded$
            .subscribe(function (details) {
            _this.details = details;
            _this.process();
        });
        this.loadedSubscription2 = this.managementService.onArrivalDeparturesLoaded$
            .subscribe(function (container) {
            _this.container = container;
            _this.process();
        });
    };
    ArrivalsDeparturesTimelineComponent.prototype.process = function () {
        if (!this.details || !this.container) {
            return;
        }
        this.workDate = this.managementService.workDate;
        if (!this.unfilteredItems) {
            this.unfilteredItems = this.createItems(this.details.records);
            if (this.unfilteredItems.length > 0) {
                this.startDate = _.first(this.unfilteredItems).date;
                this.endDate = _.last(this.unfilteredItems).date;
            }
        }
        this.setViewMode();
    };
    ArrivalsDeparturesTimelineComponent.prototype.setViewMode = function () {
        if (this.viewMode === 'Simple') {
            this.items = this.createItems(this.details.filteredRecords);
            if (this.container) {
                this.mergeArrivalsDetails(this.container);
            }
            this.timelineService.initItems(this.items, { startDate: this.startDate, endDate: this.endDate, fitToWidth: false });
        }
        if (this.viewMode === 'GroupByShift') {
            this.items = this.createShiftGroupingItems(this.details.filteredRecords);
            if (this.container) {
                this.mergeArrivalsDetails(this.container);
            }
            this.timelineService.initItems(this.items, { startDate: this.startDate, endDate: this.endDate, fitToWidth: false, eventsMinDistance: 40 });
        }
        if (this.viewMode === 'FitToWidth') {
            this.items = this.createItems(this.details.filteredRecords);
            if (this.container) {
                this.mergeArrivalsDetails(this.container);
            }
            this.timelineService.initItems(this.items, { startDate: this.startDate, endDate: this.endDate, fitToWidth: true });
        }
        if (this.managementService.filter.arrivalsRange && this.managementService.filter.arrivalsRange.endDate) {
            this.timelineService.updateFillingStartByDate(this.managementService.filter.arrivalsRange.endDate);
        }
        if (this.managementService.filter.departuresRange && this.managementService.filter.departuresRange.startDate) {
            this.timelineService.updateFillingEndByDate(this.managementService.filter.departuresRange.startDate);
        }
    };
    ArrivalsDeparturesTimelineComponent.prototype.createItems = function (records) {
        var items = [];
        var shiftStarts = {};
        var shiftEnds = {};
        var punchInMap = {};
        var punchOutMap = {};
        _.forEach(records, function (record) {
            if (record.entry) {
                _.forEach(record.entry.shifts, function (s) {
                    if (!dateTimeUtils.isInValidPeriod(s.start) || !dateTimeUtils.isInValidPeriod(s.end)) {
                        return;
                    }
                    var startIndex = moment(s.start).unix();
                    if (!shiftStarts[startIndex]) {
                        var shiftStart = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.scheduleStart);
                        shiftStart.records = [];
                        shiftStart.date = s.start;
                        shiftStart.minDistanceRelated = true;
                        items.push(shiftStart);
                        shiftStarts[startIndex] = shiftStart;
                    }
                    shiftStarts[startIndex].records.push(record);
                    var endIndex = moment(s.end).unix();
                    if (!shiftEnds[endIndex]) {
                        var shiftEnd = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.scheduleEnd);
                        shiftEnd.records = [];
                        shiftEnd.date = s.end;
                        shiftEnd.minDistanceRelated = true;
                        items.push(shiftEnd);
                        shiftEnds[endIndex] = shiftEnd;
                    }
                    shiftEnds[endIndex].records.push(record);
                });
            }
            _.forEach(record.punches, function (p) {
                if (p.type.isBreak || p.type.isLunch) {
                    return;
                }
                if (p.type.isIn) {
                    var punchInMapIndex = moment(p.time).unix();
                    if (!punchInMap[punchInMapIndex]) {
                        var punchIn = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchIn);
                        punchIn.records = [];
                        punchIn.date = p.time;
                        punchIn.minDistanceRelated = true;
                        items.push(punchIn);
                        punchInMap[punchInMapIndex] = punchIn;
                    }
                    punchInMap[punchInMapIndex].records.push(record);
                }
                if (p.type.isOut) {
                    var punchOutMapIndex = moment(p.time).unix();
                    if (!punchOutMap[punchOutMapIndex]) {
                        var punchOut = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchOut);
                        punchOut.records = [];
                        punchOut.date = p.time;
                        punchOut.minDistanceRelated = true;
                        items.push(punchOut);
                        punchOutMap[punchOutMapIndex] = punchOut;
                    }
                    punchOutMap[punchOutMapIndex].records.push(record);
                }
            });
        });
        return _.orderBy(items, function (item) { return item.date; });
    };
    ArrivalsDeparturesTimelineComponent.prototype.mergeArrivalsDetails = function (container) {
        var _this = this;
        _.forEach(container.arrivals, function (record) {
            if (record.late > 0 && record.arrivalTime) {
                var late = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.late);
                late.date = moment(record.arrivalTime).add('m', 5).toDate();
                late.arrival = record;
                var index = _.sortedIndexBy(_this.items, late, function (item) { return item.date; });
                _this.items.splice(index, 0, late);
                _this.suppresNeighborsTitles(late, index);
            }
        });
        _.forEach(container.departures, function (record) {
            if (record.overtime > 0 && record.departureTime) {
                var ot = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.ot);
                ot.date = moment(record.departureTime).add('m', 5).toDate();
                ot.departure = record;
                var index = _.sortedIndexBy(_this.items, ot, function (item) { return item.date; });
                _this.items.splice(index, 0, ot);
                _this.suppresNeighborsTitles(ot, index);
            }
        });
    };
    ArrivalsDeparturesTimelineComponent.prototype.suppresNeighborsTitles = function (target, index) {
        var neighbors = _.slice(this.items, index - 1, index + 2);
        var neighborsToSuppress = _.filter(neighbors, function (item) { return item !== target && Math.abs(item.date.getTime() - target.date.getTime()) < 1200000; });
        _.forEach(neighborsToSuppress, function (item) {
            if (target.type === ArrivalsDeparturesTimelineItemType.late) {
                item.lateBageNeighbor = true;
            }
            if (target.type === ArrivalsDeparturesTimelineItemType.ot) {
                item.otBageNeighbor = true;
            }
        });
    };
    ArrivalsDeparturesTimelineComponent.prototype.createShiftGroupingItems = function (records) {
        var items = [];
        var shifts = {};
        var punchInMap = {};
        var punchOutMap = {};
        var offsetInGroup = 600000;
        _.forEach(records, function (record) {
            if (record.entry) {
                _.forEach(record.entry.shifts, function (s) {
                    if (!dateTimeUtils.isInValidPeriod(s.start) || !dateTimeUtils.isInValidPeriod(s.end)) {
                        return;
                    }
                    var startIndex = Math.floor(moment(s.start).unix() / 600);
                    if (!shifts[startIndex]) {
                        var shiftStart = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.scheduleStart);
                        shiftStart.records = [];
                        shiftStart.date = s.start;
                        shiftStart.minDistanceRelated = true;
                        items.push(shiftStart);
                        shifts[startIndex] = shiftStart;
                    }
                    shifts[startIndex].records.push(record);
                    var endIndex = Math.floor(moment(s.end).unix() / 600);
                    if (!shifts[endIndex]) {
                        var shiftEnd = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.scheduleEnd);
                        shiftEnd.records = [];
                        shiftEnd.date = s.end;
                        shiftEnd.minDistanceRelated = true;
                        items.push(shiftEnd);
                        shifts[endIndex] = shiftEnd;
                    }
                    shifts[endIndex].records.push(record);
                    var inPunches = _.find(record.punches, function (p) { return !p.type.isBreak && !p.type.isLunch && p.type.isIn; });
                    var outPunches = _.find(record.punches, function (p) { return !p.type.isBreak && !p.type.isLunch && p.type.isOut; });
                    if (inPunches) {
                        var punchInMapIndex = startIndex + offsetInGroup;
                        if (!punchInMap[punchInMapIndex]) {
                            var punchIn = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchIn);
                            punchIn.records = [];
                            punchIn.date = moment(s.start).add('ms', -offsetInGroup).toDate();
                            //punchIn.minDistanceRelated = true;
                            items.push(punchIn);
                            punchInMap[punchInMapIndex] = punchIn;
                        }
                        punchInMap[punchInMapIndex].records.push(record);
                    }
                    if (outPunches) {
                        var punchOutMapIndex = startIndex + offsetInGroup;
                        if (!punchOutMap[punchOutMapIndex]) {
                            var punchOut = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchOut);
                            punchOut.records = [];
                            punchOut.date = moment(s.end).add('ms', offsetInGroup).toDate();
                            //punchOut.minDistanceRelated = true;
                            items.push(punchOut);
                            punchOutMap[punchOutMapIndex] = punchOut;
                        }
                        punchOutMap[punchOutMapIndex].records.push(record);
                    }
                });
            }
            else {
                _.forEach(record.punches, function (p) {
                    if (p.type.isBreak || p.type.isLunch) {
                        return;
                    }
                    if (p.type.isIn) {
                        var punchInMapIndex = moment(p.time).unix();
                        if (!punchInMap[punchInMapIndex]) {
                            var punchIn = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchIn);
                            punchIn.records = [];
                            punchIn.date = p.time;
                            items.push(punchIn);
                            punchInMap[punchInMapIndex] = punchIn;
                        }
                        punchInMap[punchInMapIndex].records.push(record);
                    }
                    if (p.type.isOut) {
                        var punchOutMapIndex = moment(p.time).unix();
                        if (!punchOutMap[punchOutMapIndex]) {
                            var punchOut = new ArrivalsDeparturesTimelineItem(ArrivalsDeparturesTimelineItemType.punchOut);
                            punchOut.records = [];
                            punchOut.date = p.time;
                            items.push(punchOut);
                            punchOutMap[punchOutMapIndex] = punchOut;
                        }
                        punchOutMap[punchOutMapIndex].records.push(record);
                    }
                });
            }
        });
        return _.orderBy(items, function (item) { return item.date; });
    };
    ArrivalsDeparturesTimelineComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ArrivalsDeparturesTimelineComponent.prototype.ngAfterViewInit = function () {
        this.changeDetector.detach();
        this.config = {
            eventsWrapper: this.eventsWrapper,
            fillingLineStart: this.fillingLineStart,
            fillingLineEnd: this.fillingLineEnd,
            timelineEvents: this.timelineEvents,
            renderer: this.renderer,
            eventsMinDistance: 1
        };
        this.timelineService.configireAfterViewInit(this.config);
        this.changeDetection();
    };
    ArrivalsDeparturesTimelineComponent.prototype.onScrollClick = function (event, forward) {
        event.preventDefault();
        this.timelineService.onScrollClick(event, forward);
    };
    ArrivalsDeparturesTimelineComponent.prototype.onEventClick = function (event, selectedItem) {
        event.preventDefault();
        //this.timelineService.onEventClick(event, selectedItem);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineComponent.prototype, "loadedSubscription2", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineComponent.prototype, "changeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineComponent.prototype, "onToogleTimelineViewSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineComponent.prototype, "onStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineComponent.prototype, "toggleOverrageSubscription", void 0);
    return ArrivalsDeparturesTimelineComponent;
}());
export { ArrivalsDeparturesTimelineComponent };
