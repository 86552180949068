import { DialogOptions2, DialogModeSize } from './../../../../../common/models/dialog-options';
import { Component, Input, OnInit, Provider, ViewChild, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

import { DialogOptions, IDialog } from '../../../../../common/index';

import { ModalService } from '../../../../../common/services/index';
import { BenefitEligibleEmployeesRequest } from '../../../models/index';
import { BenefitEmployeeManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-benefit-employees-unenrolled-dialog',
  templateUrl: 'benefit-employees-unenrolled-dialog.component.html',
  styleUrls: ['benefit-employees-unenrolled-dialog.component.scss']
})
export class BenefitEmployeesDialogComponent implements IDialog, OnInit, OnDestroy {


  public options: DialogOptions;
  public dialogResult: boolean;
  public request: BenefitEligibleEmployeesRequest;
  public manService: BenefitEmployeeManagementService;

  private modalService: ModalService;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public static getTitle(mode: string, planName: string): string {
    if (mode === 'eligible') {
      return `Eligible - Not Enrolled Employees - ${planName}`;
    } else {
      return `Eligible - Employees - Enroll in ${planName}`;
    }
  }

  public static openDialog(request: BenefitEligibleEmployeesRequest, modalService: ModalService, callback: (result: boolean, cmd: any) => void): BenefitEmployeesDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: BenefitEligibleEmployeesRequest,
        useValue: request
      }
    ];
    const title = BenefitEmployeesDialogComponent.getTitle('eligible', request.planName);
    let dialog: BenefitEmployeesDialogComponent = modalService.globalAnchor
      .openDialog2(BenefitEmployeesDialogComponent, title, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, undefined);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    request: BenefitEligibleEmployeesRequest,
    manService: BenefitEmployeeManagementService
  ) {
    this.modalService = modalService;
    this.options = options;
    this.request = request;
    this.manService = manService;
  }

  public ngOnInit() {
    this.subscriptions.emloyeeDetails = this.manService.subscribeTogetToDetailsPage((value: boolean) => {
      if (value) {
        this.onCancel();
      }
    });
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public modeChanged(mode: string): void {
    let title = BenefitEmployeesDialogComponent.getTitle(mode, this.request.planName);
    this.modalService.setTitle(title, this.options.windowUniqueId);
  }

  public ngOnDestroy() { }
}
