import { Provider } from '@angular/core';

export * from './app-user-settings-api.service';
export * from './app-user-settings-map.service';
export * from './app-user-settings.service';

import { AppUserSettingsApiService } from './app-user-settings-api.service';
import { AppUserSettingsMapService } from './app-user-settings-map.service';
import { AppUserSettingsService } from './app-user-settings.service';

export const appSettingsServices: Provider[] = [
  AppUserSettingsApiService,
  AppUserSettingsMapService,
  AppUserSettingsService
];
