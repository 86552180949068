import * as tslib_1 from "tslib";
import { ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { AcceptableControlValueAccessor } from '../../../core/models/index';
var SsnInputComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SsnInputComponent, _super);
    function SsnInputComponent(elementRef, ngControl) {
        var _this = _super.call(this) || this;
        _this.elementRef = elementRef;
        _this.ngControl = ngControl;
        _this.placeholder = 'SSN';
        _this.innerValue = '';
        _this.hasFocus = false;
        ngControl.valueAccessor = _this;
        _this.ssnMaskConf = {
            mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            guide: true
        };
        _this.hasFocus = false;
        return _this;
    }
    Object.defineProperty(SsnInputComponent.prototype, "readonly", {
        get: function () {
            return !this.inEdit;
        },
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    SsnInputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    SsnInputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
    };
    SsnInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !myattr;
    };
    SsnInputComponent.prototype.writeValue = function (value) {
        this.innerValue = value;
        this.inputComponent.nativeElement.value = value ? value : null;
    };
    SsnInputComponent.prototype.valueChanged = function (value) {
        this.changeValue(value);
    };
    return SsnInputComponent;
}(AcceptableControlValueAccessor));
export { SsnInputComponent };
