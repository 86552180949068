import { AttributeValue } from "./attribute-value";

export class DetailsPartnerAttributes {
  public name: string;
  public display_name: string;
  public type: string;
  public attr_values: AttributeValue[];
  public attr_default_values: string;
  public order: number;
}


export class IDetailsPartnerAttributes {
  public name: string;
  public display_name: string;
  public type: string;
  public attr_values: AttributeValue[];
  public attr_default_values: string;
  public order: number;
}
export class InstantAccept {
  public partnerId:string;
  public value:any[] =[];
}