import * as _ from 'lodash';
import * as moment from 'moment';
import { wcConfig } from '../../workers-compensation.config';
var ReportFormBase = /** @class */ (function () {
    function ReportFormBase() {
        this.paymentFreqOptions = [
            'Weekly', 'Semi-Monthly', 'Every Two Weeks', 'Monthly'
        ];
        this.phoneMaskConf = wcConfig.settings.phoneMask;
    }
    Object.defineProperty(ReportFormBase.prototype, "isCreatingNew", {
        get: function () {
            return this.report && !_.isFinite(this.report.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportFormBase.prototype, "common", {
        get: function () {
            return this.report ? this.report.common : null;
        },
        enumerable: true,
        configurable: true
    });
    ReportFormBase.prototype.onIsDeadChanged = function (enable) {
        if (this.common) {
            this.common.dateOfDeath = enable ? moment().toDate() : null;
        }
    };
    return ReportFormBase;
}());
export { ReportFormBase };
