import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import * as _ from 'lodash';

import { ResponseBody, Meta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { logiIntegrationConfig } from '../logi-integration.config';

@Injectable()
export class LogiApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public requestSecureKey(orgLevelId: number, menuItemCode: string): Promise<any> {
    let url: string = `${this.getApiRoot()}/${logiIntegrationConfig.api.logi.secureKey}`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      orgLevelId: orgLevelId,
      menuItemCode: menuItemCode
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${logiIntegrationConfig.api.logi.root}`;
  }
}
