import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TelepunchLocation } from '../../../models/telepunch-locations/telepunch-location';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper } from '../../../../common/models/index';
import { ConfigurationComponentHelper } from '../../../utils/configuration-component-helper';
import { GridComponent } from '@progress/kendo-angular-grid';
import { TelepunchLocationsManagementService } from '../../../services/telepunch-locations/telepunch-locations-management.service';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../app.config';
import * as _ from 'lodash';
import { ModalService } from '../../../../common/services/index';
import { ConfirmOptions, ConfirmDialogComponent } from '../../../../common/index';
import { NgForm } from '@angular/forms';
var TelepunchLocationsGridComponent = /** @class */ (function () {
    function TelepunchLocationsGridComponent(management, modalService) {
        this.modalService = modalService;
        this.management = management;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
    }
    Object.defineProperty(TelepunchLocationsGridComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    TelepunchLocationsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.addItemSubscription = this.management.addItemClicked$.subscribe(function () {
            var tp = new TelepunchLocation();
            _this.crudHelper.addItem(tp);
        });
        this.deleteItemSubscription = this.management.deleteItemClicked$.subscribe(function () {
            _this.crudHelper.deleteSelected();
        });
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'bulkEditMode')) {
                if (state.bulkEditMode) {
                    if (_this.grid)
                        _this.gridState.closeEditor(_this.grid);
                }
            }
            if (_.has(state, 'orgLevelChanged')) {
                if (_this.grid)
                    _this.gridState.closeEditor(_this.grid);
            }
        });
        this.savedSubscription = this.management.onItemSaved$.subscribe(function (item) {
            _this.gridState.editedRecord = null;
            _this.gridState.savedEditedRecord = null;
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.removeSubscription = this.management.removeItemsCmd$.subscribe(function (itemToDelete) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialogComponent.openDialog('Confirmation', 'Do you want to delete the Telepunch Location?', _this.modalService, function (result) {
                if (result) {
                    _this.management.doRemoveItem(itemToDelete);
                }
            }, options);
        });
        this.editSubscription = this.management.editItemCmd$.subscribe(function (item) {
            if (item) {
                _this.mainFormSubscription = _this.mainForm.statusChanges.subscribe(function () {
                    if (_this.mainForm.dirty) {
                        _this.management.markAsDirty();
                    }
                });
            }
            else {
                if (_this.mainFormSubscription) {
                    _this.mainFormSubscription.unsubscribe();
                }
            }
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
    };
    TelepunchLocationsGridComponent.prototype.onItemFieldChange = function (item, field, value) {
        item[field] = value;
        this.management.setItemDirty(item);
    };
    TelepunchLocationsGridComponent.prototype.rowCallback = function (context) {
        if (context.dataItem.isDirty) {
            return { 'dirty-item': true };
        }
        return {};
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsGridComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsGridComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsGridComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsGridComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsGridComponent.prototype, "addItemSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsGridComponent.prototype, "deleteItemSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsGridComponent.prototype, "editSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsGridComponent.prototype, "mainFormSubscription", void 0);
    return TelepunchLocationsGridComponent;
}());
export { TelepunchLocationsGridComponent };
