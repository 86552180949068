import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, Provider } from '@angular/core';
import { appConfig } from '../../../../../../app.config';
import { KendoGridStateHelper, DialogOptions, ModalService, IDialog, DialogOptions2, DialogModeSize } from '../../../../../../common';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { BenefitEmployeesApiService } from '../../../../../../app-modules/benefits/services';
var DropCoveragePopupComponent = /** @class */ (function () {
    function DropCoveragePopupComponent(options, modalService, benefitsApiService) {
        this.options = options;
        this.modalService = modalService;
        this.benefitsApiService = benefitsApiService;
        this.showEnrollments = true;
        //#endregion
        //#region properties
        this.isLoading = false;
        this.maxDedDate = new Date(appConfig.maxCorrectDate);
        this.minDedDate = new Date(appConfig.minCorrectDate);
        this.selectedEnrollmentIds = [];
        this.selectAllState = 'unchecked';
        this.selectedEnrollments = [];
        this.hasSelected = false;
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
    }
    Object.defineProperty(DropCoveragePopupComponent.prototype, "enrollments", {
        set: function (value) {
            this.m_enrollments = value;
            _.each(this.m_enrollments, function (e) { return e.isSelected = false; });
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    DropCoveragePopupComponent.openDialog = function (showEnrollments, enrollments, canMapPayroll, effectiveDate, modalService, apiService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.height = showEnrollments ? 450 : 310;
        dialogOptions.width = 600;
        dialogOptions.modeSize = DialogModeSize.custom;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: BenefitEmployeesApiService,
                useValue: apiService
            }
        ];
        var title = "Drop Benefit Coverage";
        var component = modalService.globalAnchor.openDialog2(DropCoveragePopupComponent, title, dialogOptions, resolvedProviders, callback);
        component.showEnrollments = showEnrollments;
        component.enrollments = enrollments;
        component.canMapPayroll = canMapPayroll;
        if (showEnrollments) {
            component.coverageEndDate = effectiveDate;
            component.payrollDeductionEndDate = effectiveDate;
        }
        else {
            component.selectedEnrollments = enrollments;
            component.hasSelected = true;
            var enrollment = _.first(enrollments);
            component.coverageEndDate = enrollment.endDate;
            component.payrollDeductionEndDate = moment(_.isNull(enrollment.payrollDeductionEndDate) ? enrollment.payrollDeductionEndDate : enrollment.endDate).startOf('day').toDate();
            component.minDedDate = _.isNull(enrollment.dedStartDate) ? enrollment.startDate : enrollment.dedStartDate;
            component.maxDedDate = _.isNull(enrollment.dedEndDate) ? enrollment.endDate : enrollment.dedEndDate;
            if (_.gt(component.payrollDeductionEndDate, component.maxDedDate)) {
                component.payrollDeductionEndDate = component.maxDedDate;
            }
        }
        return component;
    };
    DropCoveragePopupComponent.prototype.ngOnInit = function () {
        this.eventDate = new Date();
        this.updateCoverageMinDateRange();
        this.loadReasons();
    };
    DropCoveragePopupComponent.prototype.loadReasons = function () {
        var _this = this;
        this.isLoading = true;
        this.benefitsApiService.getReasonsList()
            .then(function (reasons) {
            _this.isLoading = false;
            _this.terminationReasons = reasons;
        }).catch(function (error) {
            _this.terminationReasons = null;
            _this.isLoading = false;
        });
    };
    DropCoveragePopupComponent.prototype.onCancel = function () {
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    DropCoveragePopupComponent.prototype.onSelectionChange = function (event) {
        this.gridState.selectionChange(event);
        var selectedRecords = _.map(event.selectedRows, function (args) { return args.dataItem; });
        var deselectedRecords = _.map(event.deselectedRows, function (args) { return args.dataItem; });
        this.selectedEnrollments = _.without.apply(_, [this.selectedEnrollments].concat(deselectedRecords));
        this.selectedEnrollments = _.uniqBy(_.concat(this.selectedEnrollments, selectedRecords), function (r) { return r.empToBenefitsId; });
        this.hasSelected = _.size(this.selectedEnrollments) > 0;
        this.updateCoverageMinDateRange();
        this.validateCoverageEndDate();
    };
    DropCoveragePopupComponent.prototype.onChangeTerminationReason = function (reason) {
        this.terminationReason = reason;
        this.validateCoverageEndDate();
    };
    DropCoveragePopupComponent.prototype.onChangeEventDate = function (value) {
        this.eventDate = value;
    };
    DropCoveragePopupComponent.prototype.onChangeCoverageEndDate = function (value) {
        this.coverageEndDate = value;
        this.updateCoverageMinDateRange();
        this.validateCoverageEndDate();
    };
    DropCoveragePopupComponent.prototype.onChangePayrollDedEndDate = function () {
        this.isDedAfterBeneEndDt = !!_.gt(this.payrollDeductionEndDate, this.coverageEndDate);
        this.updateCoverageMinDateRange();
        this.validateCoverageEndDate();
    };
    DropCoveragePopupComponent.prototype.dropBenefits = function () {
        var _this = this;
        if (_.isNil(this.selectedEnrollments) || _.size(this.selectedEnrollments) === 0)
            return;
        this.isLoading = true;
        var ids = _.map(this.selectedEnrollments, function (e) { return e.empToBenefitsId; });
        this.benefitsApiService.dropEmployeeBenefits(ids, this.coverageEndDate, this.payrollDeductionEndDate, this.eventDate, this.terminationReason)
            .then(function (drop) {
            _this.isLoading = false;
            _this.dialogResult = true;
            _this.modalService.closeWindow(_this.options.windowUniqueId);
        }).catch(function (reason) {
            _this.dialogResult = false;
            _this.terminationReason = null;
            _this.isLoading = false;
        });
    };
    DropCoveragePopupComponent.prototype.updateCoverageMinDateRange = function () {
        if (_.size(this.selectedEnrollments) > 0) {
            var startDateEnrollment = _.minBy(this.selectedEnrollments, 'startDate');
            var endDateEnrollment = _.maxBy(this.selectedEnrollments, 'endDate');
            this.minCoverageEndDate = startDateEnrollment.startDate;
            this.maxCoverageEndDate = endDateEnrollment.endDate;
            var minDedEndDate = _.minBy(this.selectedEnrollments, 'payrollDeductionEndDate');
            var maxDedEndDate = _.maxBy(this.selectedEnrollments, 'payrollDeductionStartDate');
            if (!_.isNil(minDedEndDate) && !_.isNil(maxDedEndDate)) {
                this.minDedDate = minDedEndDate.payrollDeductionEndDate ? this.minCoverageEndDate : minDedEndDate.payrollDeductionEndDate;
                this.maxDedDate = maxDedEndDate.payrollDeductionStartDate ? this.maxCoverageEndDate : maxDedEndDate.payrollDeductionStartDate;
            }
            else {
                this.minDedDate = this.minCoverageEndDate;
                this.maxDedDate = this.maxCoverageEndDate;
            }
        }
        else {
            this.minCoverageEndDate = null;
            this.maxCoverageEndDate = null;
            this.maxDedDate = null;
            this.minDedDate = null;
        }
    };
    DropCoveragePopupComponent.prototype.validateCoverageEndDate = function () {
        var _this = this;
        if (this.coverageEndDate) {
            var isValid_1 = this.hasSelected;
            _.each(this.selectedEnrollments, function (r) {
                if (!moment(_this.coverageEndDate).isBetween(r.startDate, r.endDate, null, '[]')) {
                    isValid_1 = false;
                }
            });
            this.coverageEndDateValid = isValid_1;
            this.isDedAfterBeneEndDt = !!_.gt(this.payrollDeductionEndDate, this.coverageEndDate);
        }
        else {
            this.coverageEndDateValid = false;
        }
    };
    DropCoveragePopupComponent.prototype.refreshGrid = function () {
        if (!this.m_enrollments) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.m_enrollments, this.gridState.state);
    };
    return DropCoveragePopupComponent;
}());
export { DropCoveragePopupComponent };
