import { IdealScheduleAcuity } from './ideal-schedule-acuity';
import { ParLevels } from './par-levels';

export class IdealSchedulePositionRange {
  public id: number = -1;
  public acuity: IdealScheduleAcuity = new IdealScheduleAcuity();

  public parLevels: StringMap<ParLevels> = {};
  public counters: NumberMap<number> = [];

  public hasChanges: boolean;
  public isEmpty: boolean;
  public totalHours: number;

  public get name(): string {
    const acuityName = this.acuity.acuity ? this.acuity.acuity.name : 'Default';
    return `Range - ${acuityName} (${this.acuity.minValue || 0} - ${this.acuity.maxValue || 0})`;
  }
}
