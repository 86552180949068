export interface IGenericListConfig {
  api: {
    applications: {
      root: string;
      orgLevel: {
        root: string;
        listDefinitions: string;
        lists: string;
      }
    }
  };
  latenesses: string;
  directCareHours: string;
  scheduleComments: string;
  projectedHours: string;
  approvalRequiredCodes: string;
  attendancePoints: string;
  overtimeApprovals: string;
}

export const genericListConfig: IGenericListConfig = {
  api: {
    applications: {
      root: 'applications',
      orgLevel: {
        root: 'orgLevel',
        listDefinitions: 'listDefinitions',
        lists: 'lists'
      }
    }
  },
  latenesses: 'latenesses',
  directCareHours: 'directCareHours',
  scheduleComments: 'scheduleComments',
  projectedHours: 'projectedHours',
  approvalRequiredCodes: 'ApprovalRequiredCodes',
  attendancePoints: 'attendancePoints',
  overtimeApprovals: 'overtimeApprovals',
};
