import * as _ from 'lodash';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ISelectableItemContainer, SelectableItemsProducer, IDialog, ModalService, DialogOptions, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { Assert } from '../../../../../framework/index';
import { LookupApiService } from '../../../../../organization/services/lookup/lookup-api.service';
import { orderBy } from '@progress/kendo-data-query';
import { EmployeeSectionsPerformanceApiService } from '../../../services/employee-section-performance/employee-section-performance-api.service';
import * as moment from 'moment';
var EmployeeSectionsAttendancePointsDefinitionComponent = /** @class */ (function () {
    function EmployeeSectionsAttendancePointsDefinitionComponent(lookupApiService, selectableItemsProducer, options, modalService, employeeSectionsPerformanceApiService, changeDetector) {
        this.changeDetector = changeDetector;
        this.isAnyItemSelected = false;
        this.sort = [];
        this.hasAssignedBuyBack = false;
        this.hasSelectedBuyback = false;
        this.buyBack = "Buy Back";
        Assert.isNotNull(selectableItemsProducer, 'selectableItemsProducer');
        this.selectableItemsProducer = selectableItemsProducer;
        Assert.isNotNull(options, 'options');
        this.options = options;
        Assert.isNotNull(modalService, 'modalService');
        this.lookupApiService = lookupApiService;
        this.modalService = modalService;
        this.dialogResult = false;
        this.employeeSectionsPerformanceApiService = employeeSectionsPerformanceApiService;
        this.selectedDate = new Date();
        this.isLoading = false;
    }
    Object.defineProperty(EmployeeSectionsAttendancePointsDefinitionComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
        if (!_.isNil(this.empId)) {
            this.loadSubsection();
        }
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.onFilterDateChanged = function (startDate) {
        this.selectableAttendancePoints.filter(function (x) { return x.selected === true; }).forEach(function (item) { item.selected = false; });
        this.startDate = moment(startDate).startOf('day').toDate();
        this.hasAssignedBuyBack = false;
        this.hasSelectedBuyback = false;
        this.isAnyItemSelected = false;
        this.loadAssignedDefintionData();
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.loadAssignedDefintionData = function () {
        var _this = this;
        if (this.startDate == undefined) {
            this.startDate = moment(new Date()).startOf('day').toDate();
        }
        this.categoryName = 'Buy Back';
        this.employeeSectionsPerformanceApiService.getAssignedDefinitions(this.empId, this.startDate, this.categoryName).then(function (res) {
            _this.attendanceassignedsDefinitions(res);
        });
    };
    Object.defineProperty(EmployeeSectionsAttendancePointsDefinitionComponent.prototype, "selectedIds", {
        get: function () {
            var pointIds = [];
            this.selectableAttendancePoints.forEach(function (i) {
                if (i.selected)
                    pointIds.push(i.item.id);
            });
            return pointIds;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendancePointsDefinitionComponent.prototype, "attendancePointsDefinitions", {
        set: function (attendancePointsDefinitions) {
            if (attendancePointsDefinitions !== null) {
                this.selectableAttendancePoints = this.selectableItemsProducer.produceSelectable(attendancePointsDefinitions);
                this.refreshGrid();
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.attendanceassignedsDefinitions = function (attendanceassignedsDefinitions) {
        if (attendanceassignedsDefinitions.length > 0) {
            for (var i = 0; i < attendanceassignedsDefinitions.length; i++) {
                if (attendanceassignedsDefinitions[i].categoryName == 'Buy Back' && attendanceassignedsDefinitions[i].dateOn.valueOf() == this.startDate.valueOf()) {
                    this.hasAssignedBuyBack = true;
                }
                else {
                    this.hasAssignedBuyBack = false;
                }
            }
        }
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.onItemSelectionChanged = function (e, dataitem) {
        dataitem.selected = e.currentTarget.checked;
        this.isAnyItemSelected = this.selectableAttendancePoints.some(function (i) { return i.selected; });
        if (!this.hasSelectedBuyback && dataitem.item.category.name == this.buyBack) {
            this.hasSelectedBuyback = true;
        }
        else if (this.hasSelectedBuyback && dataitem.item.category.name == this.buyBack) {
            this.hasSelectedBuyback = false;
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.isCheckboxEnabled = function (dataItem) {
        if (this.hasAssignedBuyBack && dataItem.item.category.name == this.buyBack) {
            return true;
        }
        else if (dataItem.selected) {
            return null;
        }
        else {
            return (dataItem.selected === false && this.hasSelectedBuyback && dataItem.item.category.name == this.buyBack) ? true : null;
        }
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.isMouseover = function (dataitem) {
        var _this = this;
        var isValid = this.isCheckboxEnabled(dataitem) ? true : false;
        if (isValid) {
            if (this.hasSelectedBuyback && !this.hasAssignedBuyBack) {
                var options = new ConfirmOptions();
                options.showCancel = false;
                options.showOK = false;
                ConfirmDialog2Component.openDialog('Warning', 'Please note that only one Buy Back transaction can be added per day.', this.modalService, function (result) {
                    if (result) {
                        _this.isLoading = false;
                    }
                }, options);
            }
            else if (this.hasAssignedBuyBack) {
                var options = new ConfirmOptions();
                options.showCancel = false;
                options.showOK = false;
                ConfirmDialog2Component.openDialog('Warning', 'Only one BuyBack transaction per day is supported. A BuyBack transaction already exists for this day.', this.modalService, function (result) {
                    if (result) {
                        _this.isLoading = false;
                    }
                }, options);
            }
        }
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.sortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.lookupApiService.getAttendancePointsDefinitions(null, this.empId)
            .then(function (attendancePointsDefinitions) {
            _this.attendancePointsDefinitions = attendancePointsDefinitions;
            _this.stopProgress();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
        this.loadAssignedDefintionData();
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.startProgress = function () {
        this.isLoading = true;
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.stopProgress = function () {
        this.isLoading = false;
    };
    EmployeeSectionsAttendancePointsDefinitionComponent.prototype.refreshGrid = function () {
        if (!this.selectableAttendancePoints) {
            this.gridView = null;
            return;
        }
        this.gridView = {
            data: orderBy(this.selectableAttendancePoints, this.sort),
            total: this.selectableAttendancePoints.length
        };
    };
    return EmployeeSectionsAttendancePointsDefinitionComponent;
}());
export { EmployeeSectionsAttendancePointsDefinitionComponent };
