import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
var UserProfileSectionBaseComponent = /** @class */ (function () {
    function UserProfileSectionBaseComponent(management) {
        this.management = management;
    }
    Object.defineProperty(UserProfileSectionBaseComponent.prototype, "isValid", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileSectionBaseComponent.prototype, "type", {
        get: function () {
            return this.m_type;
        },
        enumerable: true,
        configurable: true
    });
    UserProfileSectionBaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.state) {
            this.state = {
                isEditMode: false,
                isLoading: false
            };
        }
        this.enterEditStateSubscription = this.management.onSectionEnteredEditState$.subscribe(function (section) {
            if (_this === section) {
                _this.state.isEditMode = true;
            }
        });
        this.sectionDiscardSubscription = this.management.onSectionDiscarded$.subscribe(function (section) {
            if (_this === section) {
                _this.discardChanges();
            }
        });
        this.sectionSaveSubscription = this.management.onSectionSave$.subscribe(function (section) {
            if (section === _this) {
                _this.state.isLoading = true;
            }
        });
        this.sectionSavedSubscription = this.management.onSectionSaved$.subscribe(function (section) {
            if (_this === section) {
                _this.onChangesSaved();
            }
        });
        this.sectionErrorSubscription = this.management.onSectionServiceError$.subscribe(function (type) {
            if (type === _this.type) {
                _this.state.isLoading = false;
            }
        });
    };
    UserProfileSectionBaseComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionBaseComponent.prototype, "enterEditStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionBaseComponent.prototype, "sectionDiscardSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionBaseComponent.prototype, "sectionSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionBaseComponent.prototype, "sectionSavedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionBaseComponent.prototype, "sectionErrorSubscription", void 0);
    return UserProfileSectionBaseComponent;
}());
export { UserProfileSectionBaseComponent };
