import * as tslib_1 from "tslib";
import { DialogOptions2 } from './../../../../../../common/models/dialog-options';
import { OnInit, Provider } from '@angular/core';
import { EmployeeSectionsBenefitsManagementApiService } from '../../../../services';
import { ModalService } from '../../../../../../common/services';
import { LookupService } from '../../../../../../organization/services';
import { IDialog } from '../../../../../../common/models';
import { BeneficiaryEditorComponent } from '../beneficiary-editor/beneficiary-editor.component';
var BeneficiaryEditorPopupComponent = /** @class */ (function () {
    function BeneficiaryEditorPopupComponent(options, modalService, lookupService) {
        this.options = options;
        this.modalService = modalService;
        this.lookupService = lookupService;
    }
    BeneficiaryEditorPopupComponent.openDialog = function (enrollmentId, apiService, modalService, lookupService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.width = '85%';
        dialogOptions.height = 560;
        dialogOptions.minWidth = 700;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: LookupService,
                useValue: lookupService
            },
            {
                provide: ModalService,
                useValue: modalService
            },
            {
                provide: EmployeeSectionsBenefitsManagementApiService,
                useValue: apiService
            }
        ];
        var title = "Your Beneficiaries";
        var component = modalService.globalAnchor.openDialog2(BeneficiaryEditorPopupComponent, title, dialogOptions, resolvedProviders, callback);
        component.enrollmentId = enrollmentId;
        return component;
    };
    Object.defineProperty(BeneficiaryEditorPopupComponent.prototype, "canSave", {
        get: function () {
            return this.editor ? this.editor.canSave : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BeneficiaryEditorPopupComponent.prototype, "canCancel", {
        get: function () {
            return this.editor ? this.editor.canCancel : true;
        },
        enumerable: true,
        configurable: true
    });
    BeneficiaryEditorPopupComponent.prototype.ngOnInit = function () {
    };
    BeneficiaryEditorPopupComponent.prototype.onSave = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.editor.saveBeneficiaries()];
                    case 1:
                        result = _a.sent();
                        if (result) {
                            this.dialogResult = true;
                            this.modalService.closeWindow(this.options.windowUniqueId);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BeneficiaryEditorPopupComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return BeneficiaryEditorPopupComponent;
}());
export { BeneficiaryEditorPopupComponent };
