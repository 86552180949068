import { appCommonRoutes, employeeSectionRoutes } from './portal.common.routes';
import { ApplicationContainerComponent, } from './components/index';
import { OrgLevelResolver } from './services/index';
import { ArrivalsDeparturesDetailsComponent } from '../time-and-attendance/components/index';
export var timeRoutes = [
    {
        path: '',
        component: ApplicationContainerComponent,
        children: [
            {
                path: '',
                redirectTo: 'ta_console',
                pathMatch: 'full'
            },
            {
                path: 'arrivals_departures_details',
                resolve: [OrgLevelResolver],
                component: ArrivalsDeparturesDetailsComponent
            }
        ].concat(appCommonRoutes)
    }
].concat(employeeSectionRoutes);
