<kendo-grid #employeesGrid kendoGridFixFreezeHeader slxKendoGridMobileManager slxKendoGridRowExpand
  class="slx-full-height daily-punches-grid slx-punches-employees-grid"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [slxGroupable]="{ showFooter: true }"
  [slxGroupableMobile]="false"
  [slxKendoGridColumnsGroup]="groupName"
  [group]="gridState.state.group"
  [filterable]="true"
  [filter]="gridState.state.filter"
  [filtersManagement]="true"
  keyId="header.employee.id"
  (dataStateChange)="gridState.dataStateChange($event)"
  slxKendoGridState="PUNCHES_EMPLOYEES_GRID"
>

  <kendo-grid-column media="xs" title="Employees Punches" width="250">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="employee-container">
        <a [employeeSectionLink]="dataItem?.header?.employee.id">
          <h4>
            {{dataItem?.header?.employee.name}}
          </h4>
        </a>
      </div>
      <div class="status-container">
        <div class="display-container">
          <i *ngIf="dataItem?.header?.dirty" class="dirty-indicator fa fa-exclamation fa-2x" aria-hidden="true"></i>
          <span class="{{getExceptionStyle(dataItem?.header)}}">{{ getExceptionLabel(dataItem?.header) }}</span>
        </div>
        <a scheduleEntryLink [employeeId]="dataItem?.header?.employee.id" [dateOn]="dataItem?.header?.date">
            {{ dataItem.header.date | amDateFormat: appConfig.dateFormat }}
        </a>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Exceptions" width="150" media="sm" class="overflow-visible-cell" field="header.status" [filterable]="false" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      Exceptions
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="display-container">
        <i *ngIf="dataItem?.header?.dirty" class="dirty-indicator fa fa-exclamation fa-2x" aria-hidden="true"></i>
        <span class="{{getExceptionStyle(dataItem?.header)}}">{{ getExceptionLabel(dataItem?.header) }}</span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" width="150" media="sm" field="header.employee.name">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Name</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [employeeSectionLink]="dataItem?.header.employee.id">
        {{dataItem?.header.employee.name}}
      </a>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Date" width="150" media="sm" field="header.date" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      Date
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
       <a [dailyTimecardsLink]="dataItem?.header.employee?.id" [timecardDate]="dataItem?.header.date"> {{ dataItem.header.date | amDateFormat: appConfig.dateFormat }}</a>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Position" width="250" media="sm" field="header.position.name" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Position</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.header.position?.name}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Badge" width="150" media="sm" field="header.badgeId" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Badge</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.header.badgeId}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="In" width="100" media="sm" field="header.firstIn" slxKendoGridColumnHiddenState [filterable]="false" [sortable]="false">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">In</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.header.firstIn | amDateFormat: appConfig.timeFormat}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Out" width="100" media="sm" field="header.lastOut" slxKendoGridColumnHiddenState [filterable]="false" [sortable]="false">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Out</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.header.lastOut | amDateFormat: appConfig.timeFormat}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Work Time" width="100" media="sm" field="header.workTime" slxKendoGridColumnHiddenState [filterable]="false">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Work Time</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="number-value">{{dataItem?.header.workTime  | slxDuration : "hours" | number: "1.2-2"}}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Paid Time" width="100" media="sm" field="header.paidTime" slxKendoGridColumnHiddenState [filterable]="false">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Paid Time</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="number-value"> {{dataItem?.header.paidTime  | slxDuration : "hours": "0.0" | number: "1.2-2" }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Shift(s)" width="150" media="sm" field="header.scheduledShiftsString" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Shift(s)</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.header.scheduledShiftsString}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Absence" width="150" media="sm" field="header.scheduledAbsencesString" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Absence</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.header.scheduledAbsencesString}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Schedule Time" width="150" media="sm" field="header.scheduledTimesString" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Schedule Time</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [slxAccessibleSwitch]="{provider: 'Menu', permission: 'Scheduler'}">
            <ng-template slxAccessibleCase>
                <a scheduleEntryLink [employeeId]="dataItem?.header.employee.id" [dateOn]="dataItem?.header.date">
                    {{dataItem?.header.scheduledTimesString}}
                </a>
           </ng-template>
            <ng-template slxNonAccessibleCase>
              0
            </ng-template>
        </ng-container>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Unit" width="150" media="sm" field="header.scheduledUnitsString" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Unit</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.header.scheduledUnitsString}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Organization" width="250" media="sm" field="header.organization.name" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Organization</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.header.organization?.name}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Department" width="250" media="sm" field="header.department.name" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Department</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.header.department?.name}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Notes (from schedule)" width="250" media="sm" field="header.scheduledCommentsString" slxKendoGridColumnHiddenState>
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Notes (from schedule)</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [slxAccessibleSwitch]="{provider: 'Menu', permission: 'Scheduler'}">
            <ng-template slxAccessibleCase>
                {{dataItem?.header.scheduledCommentsString}}
           </ng-template>
            <ng-template slxNonAccessibleCase>
            </ng-template>
        </ng-container>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <div *kendoGridDetailTemplate="let dataItem" class="punches-details">
    <slx-daily-punches-management
      [employeeId]="dataItem.header.employee.id"
      [employeeName]="dataItem.header.employee.name"
      [empPunches]="dataItem"
      [punches]="dataItem.header.dirty ? changes[dataItem.header.uniqueKey]: dataItem.punches"
      [defaultPunchTime]="dataItem.header.date"
      [minPunchDate]="dataItem.header.minPunchDate"
      [maxPunchDate]="dataItem.header.maxPunchDate"
      [userActions]="container.actions"
      (applyChanges)="applyPanchesChanges($event, dataItem)"
      (changingPunches)="onChangingPunches($event, dataItem)"
      (discardChanges)="onDiscardChanges(dataItem)"
    ></slx-daily-punches-management>
  </div>

</kendo-grid>
