import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { EssTemplateManagementService } from '../../services/index';
import { Observable } from 'rxjs';
import { PunchAttestationManagementService } from '../../../punch-attestation/services';
var EssTemplatesComponent = /** @class */ (function () {
    function EssTemplatesComponent(managementService, changeDetector, punchAttestationManagementService) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.punchAttestationManagementService = punchAttestationManagementService;
    }
    EssTemplatesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadedSubscription = this.managementService.onLoaded$.subscribe(function (data) {
            _this.data = data;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.statusSubscription = this.managementService.onLoadStatus$.subscribe(function (isLoading) {
            _this.isLoading = isLoading;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.punchAttestationManagementService.onLoaded$.subscribe(function (groups) {
            _this.punchAttestationGroups = groups;
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.loadGoGroups();
            }
        });
    };
    EssTemplatesComponent.prototype.loadGoGroups = function () {
        if (!this.orgLevel.id) {
            return;
        }
        this.punchAttestationManagementService.loadGoGroups(this.orgLevel);
    };
    EssTemplatesComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], EssTemplatesComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EssTemplatesComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EssTemplatesComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EssTemplatesComponent.prototype, "statusSubscription", void 0);
    return EssTemplatesComponent;
}());
export { EssTemplatesComponent };
