<slx-spinner [show]="isLoading">
  <form novalidate
    #gridForm="ngForm">

    <div *ngIf="gridForm" class="entollment-grid-holder">

      <kendo-grid #kendoGrid
        class="kendo-grid slx-benefit-management-grid"
        scrollable="'scrollable'"
        [data]="gridState?.view"
        [sortable]="true"
        [sort]="gridState.state.sort"
        [skip]="gridState.state.skip"
        [pageable]="true"
        [pageSize]="pageSize"
        [filterable]="false"
        [filter]="gridState.state.filter"
        [groupable]="false"
        [selectable]="false"
        [slxKendoGridState]="gridState.gridDefinition"
        (dataStateChange)="gridState.dataStateChange($event)"
        (selectionChange)="gridState.selectionChange($event)"
        (stateRestored)="gridState.dataStateChange($event)"
        (edit)="gridState.editHandler($event)"
        (save)="gridState.saveHandler($event)"
        (cancel)="gridState.cancelHandler($event)"
        (remove)="gridState.removeHandler($event)"
        (pageChange)="gridState.pageChange($event)"
        [resizable]="true">

        <ng-template kendoGridToolbarTemplate
          position="top">
          <div class="enrollment-toolbar-container">

            <button *ngIf="canCreateNewEnrollment && isEditable" [disabled]="!applicableByEffectiveDate" class="theme-iconed-accent-button enroll-btn" (click)="onStartEnrollment()">
                <span>Enroll Employee</span>
            </button>

            <div class="datepicker-control">
              <span class="field-label">Effective Date:</span>
              <span>
                <slx-datepicker #effDate="ngModel"
                  class="slx-wide"
                  [minDate]="minEffectiveDate"
                  [ngModel]="effectiveDate"
                  (ngModelChange)="onChangeEffectiveDate($event)"
                  [acceptNullDate]="false"
                  [emitValueAnyway]="true"
                  placeholder=""
                  name="effectiveDate"
                ></slx-datepicker>
                <span *ngIf="effDate.errors" class="slx-error-block error-box">
                  <span *ngIf="effDate.errors.required" errorMessage for="required"></span>
                  <span *ngIf="effDate.errors.date" errorMessage>Date is out of valid range</span>
                  <span *ngIf="effDate.errors.minDate" errorMessage>Cannot be less than {{ minEffectiveDate | amDateFormat: appConfig.dateFormat }}</span>
                </span>
              </span>
            </div>

            <div class="spacer"></div>

            <button *ngIf="(canEdit && isEditable) && !isFutureRehired" class="theme-iconed-accent-button  drop-btn" (click)="onDropCoverage()">
              <span>Drop Coverage</span>
            </button>
          </div>
        </ng-template>

        <kendo-grid-column *ngIf="canEdit || canDelete" title=""
          [sortable]="false"
          [filterable]="false"
          [width]="90"
          class="overflow-visible-cell"
          [locked]="true">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex">
              <div [ngClass]="{ 'hide-tip': !isEditingRowDiabled(dataItem)}">
                <button kendoGridEditCommand type="button"
                  class="slx-button slx-only-icon slx-toolbar slx-blue-white-text" *ngIf="canEdit"
                  [disabled]="isEditingRowDiabled(dataItem)" [slxTooltip]="getDisabledEditTooltip(dataItem)"
                  tipPosition="right">
                  <i class="fas fa-edit" aria-hidden="true"></i>
                </button>
              </div>
              <div>
                <button kendoGridRemoveCommand *ngIf="!isEditingRow(dataItem)" type="button"
                  class="slx-button slx-only-icon slx-toolbar slx-red-white-text" [disabled]="!canDelete"><i
                    class="fas fa-trash-alt" aria-hidden="true"></i>
                </button>
              </div>
              <div>
                <button type="button" class="slx-button slx-only-icon slx-toolbar slx-blue-white-text"
                  *ngIf="!isCovCalculated && isEditingRow(dataItem)" slxTooltip="Click to calculate Coverage"
                  tipPosition="right" (click)="calculateCoverage(dataItem, false);">
                  <i class="fas fa-calculator" aria-hidden="true"></i>
                </button>
              </div>
              <button kendoGridSaveCommand type="button"
                class="slx-button slx-only-icon slx-toolbar slx-blue-white-text"
                [disabled]="hasFormulaError || !gridForm.valid || empContrPercentError" *ngIf="isCovCalculated">
                <i class="fas fa-save" aria-hidden="true"></i>
              </button>
              <button kendoGridCancelCommand type="button"
                class="slx-button slx-only-icon slx-toolbar slx-red-white-text">
                <i class="fas fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="plan"
          title="Plan"
          [width]="200"
          [locked]="true">
          <ng-template kendoGridHeaderTemplate>
            Plan
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>
            <span class="edit-link" slxSaveNotesDialog [selectedRecord]="dataItem" [employeeName]="empName"
            (saveNotes)="onPlanClick($event)">{{ dataItem.plan }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="startDate"
          title="Start Date"
          [width]="120">
          <ng-template kendoGridHeaderTemplate>
            Start Date
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>
            {{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="endDate"
          title="End Date"
          [width]="120">
          <ng-template kendoGridHeaderTemplate>
            End Date
          </ng-template>
          <ng-template kendoGridEditTemplate
            let-dataItem="dataItem"
            let-rowIndex="rowIndex">
            <span [ngClass]="{'edit-link': canEdit}">{{ dataItem.endDate | amDateFormat: appConfig.dateFormat  | slxHideMaxDate }}</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>
            <span [ngClass]="{'edit-link': canEdit}" (click)="onChangeEndDate(dataItem)">{{ dataItem.endDate | amDateFormat: appConfig.dateFormat  | slxHideMaxDate}}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="employeeContribution"
          title="Employee Contribution"
          [width]="210" class="numeric-cell">
          <ng-template kendoGridHeaderTemplate>
            Employee Contribution
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <ng-container *ngIf="canEditEmpContribution">
              <slx-number slx-input
                #empCont="ngModel"
                class="slx-wide slx-input-number"
                [min]="fieldSettings.empContribution.min"
                [max]="dataItem.coverageLimit"
                [decimals]='fieldSettings.empContribution.decimals'
                [autoCorrect]='dataItem.employeeContribution !=null ? false : true'
                [format]="fieldSettings.empContribution.format"
                [step]="fieldSettings.empContribution.step"
                [(ngModel)]="dataItem.employeeContribution"
                (ngModelChange)="onChangeContribution(dataItem)"
                name="empCont{{rowIndex}}"
                [readonly]="isEmpContribSelected(dataItem) && (dataItem.calculationMethod == calculationMethod.C401K)"
              ></slx-number>
              <span *ngIf="empCont.errors && !isEmpContribSelected(dataItem)" class="slx-error-block">
                <span *ngIf="empCont.errors?.required" errorMessage for="required"></span>
                <span *ngIf="empCont.errors?.min" errorMessage for="min">Employee contribution have to be greater or equal to {{ fieldSettings.empContribution.min }}</span>
                <span *ngIf="empCont.errors?.max" errorMessage for="max">Employee contribution have to be less or equal to {{ dataItem.coverageLimit }}</span>

              </span>
            </ng-container>

            <span
              *ngIf="(dataItem.calculationMethod == calculationMethod.Formula || dataItem.calculationMethod == calculationMethod.FormulaWithOption) && !canEditEmpContribution">
              {{ dataItem.employeeContribution | number: ".2-2"| slxMoney }}
              <i *ngIf="dataItem.employeeContribution==-1"
                 title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                 aria-hidden="true"
                 class="fa fa-exclamation-triangle formula-warning"></i>
            </span>

          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span
              *ngIf="dataItem.calculationMethod == calculationMethod.Formula || dataItem.calculationMethod == calculationMethod.FormulaWithOption ||
              dataItem.calculationMethod === calculationMethod.Flat || dataItem.calculationMethod === calculationMethod.OptionsRates ||
              (dataItem.calculationMethod === calculationMethod.C401K)">
              {{ dataItem.employeeContribution | number: ".2-2"| slxMoney }}
              <i *ngIf="dataItem.employeeContribution==-1"
                 title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                 aria-hidden="true"
                 class="fa fa-exclamation-triangle formula-warning"></i>
            </span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>
            <span *ngIf="dataItem.calculationMethod == calculationMethod.C401K">
              {{ dataItem.employeeContribution }}
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="cost"
          title="Cost"
          [width]="100" class="numeric-cell">
          <ng-template kendoGridHeaderTemplate>
            Cost
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.calculationMethod == calculationMethod.OptionsRates">{{ dataItem.cost | number: ".2-2"| slxMoney }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="option"
                           title="Option"
                           [width]="250">
          <ng-template kendoGridHeaderTemplate>
            Option
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.calculationMethod == calculationMethod.OptionsRates">{{ dataItem.option }}</span>
            <span *ngIf="dataItem.calculationMethod == calculationMethod.FormulaWithOption">{{ dataItem.optionType }}</span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <ng-container *ngIf="dataItem.calculationMethod == calculationMethod.FormulaWithOption">
              <slx-dropdown-list class="slx-wide"
                                 #option="ngModel"
                                 [options]="dataItem.benefitOptions"
                                 [(ngModel)]="dataItem.selectedOption"
                                 (ngModelChange)="onOptionValueChange(dataItem)"
                                 valueField="id"
                                 titleField="type"
                                 name="option{{rowIndex}}"
                                 [required]="true">
              </slx-dropdown-list>
              <div *ngIf="option.errors" class="slx-error-block pull-left">
                <span *ngIf="option.errors.required" errorMessage for="required"></span>
              </div>
            </ng-container>
            <ng-container *ngIf="dataItem.calculationMethod == calculationMethod.OptionsRates">
              <span>{{ dataItem.option }}</span>
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="coverage" title="Coverage" [width]="180" class="numeric-cell">
          <ng-template kendoGridHeaderTemplate>
            Coverage
          </ng-template>
          <ng-template kendoGridEditTemplate  let-dataItem="dataItem" let-rowIndex="rowIndex">
            <span *ngIf="dataItem.calculationMethod == calculationMethod.Formula || dataItem.calculationMethod == calculationMethod.FormulaWithOption">
              <span *ngIf="!anyWithMaxCap && !multiplerMaxCap && !isFormula">{{ dataItem.coverage | number: ".2-2"| slxMoney }}</span>
              <span *ngIf="anyWithMaxCap">
                <slx-number slx-input
                            class="slx-wide slx-input-number"
                            [min]="fieldSettings.coverage.min"
                            [slxMin]="fieldSettings.coverage.min"
                            [max]="fieldSettings.coverage.max"
                            [slxMax]="fieldSettings.coverage.max"
                            [step]="fieldSettings.coverage.step"
                            [format]="fieldSettings.coverage.format"
                            [autoCorrect]='fieldSettings.coverage.autoCorrect'
                            [decimals]='fieldSettings.coverage.decimals'
                            [(ngModel)]="dataItem.coverage"
                            (ngModelChange)="onCoverageValueChange($event, dataItem)"
                            [required]="true"
                            name="covAnyValue{{rowIndex}}"
                            #covAnyValueField="ngModel">
                </slx-number>
                <span *ngIf="covAnyValueField.errors" class="slx-error-block pull-left">
                  <span *ngIf="covAnyValueField.errors.required" errorMessage for="required"></span>
                  <span *ngIf="covAnyValueField.errors.min || covAnyValueField.errors.max" errorMessage>Incorrect value</span>
                </span>
              </span>
              <span *ngIf="isFormula">
                <slx-number slx-input
                            class="slx-wide slx-input-number"
                            [min]="fieldSettings.coverageFormula.min"
                            [slxMin]="fieldSettings.coverageFormula.min"
                            [max]="fieldSettings.coverageFormula.max"
                            [slxMax]="fieldSettings.coverageFormula.max"
                            [step]="fieldSettings.coverageFormula.step"
                            [format]="fieldSettings.coverageFormula.format"
                            [autoCorrect]="fieldSettings.coverageFormula.autoCorrect"
                            [decimals]="fieldSettings.coverageFormula.decimals"
                            [ngModel]="dataItem.calculatedCoverage"
                            name="covFormulaValue{{rowIndex}}"
                            #covFormulaValueField="ngModel"
                            [required]="true"
                            (ngModelChange)="onCoverageValueChange($event, dataItem)">
                </slx-number>
                <span *ngIf="covFormulaValueField.errors" class="slx-error-block pull-left">
                  <span *ngIf="covFormulaValueField.errors.min || covFormulaValueField.errors.max" errorMessage>Incorrect value</span>
                  <span *ngIf="covFormulaValueField.errors.required" errorMessage for="required"></span>
                </span>
              </span>
              <span *ngIf="multiplerMaxCap">
                <slx-autocomplete #covMultiplierValue="ngModel"
                                  slx-input
                                  class="slx-wide"
                                  [options]="coverageMultipllierOptions"
                                  [ngModel]="editedItemCoverage"
                                  [strictSearch]="true"
                                  rightIcon="fas fa-search"
                                  name="coverageMult{{rowIndex}}"
                                  [virtual]="virtual"
                                  [required]="true"
                                  titleField="name"
                                  (valueChange)="onChangeCoverageWithMultiplier($event, dataItem)">
                  <ng-template slxAutocompleteItem let-item="item">
                    {{item.name}}
                  </ng-template>
                </slx-autocomplete>
                <span *ngIf="covMultiplierValue.errors" class="slx-error-block pull-left">
                  <span *ngIf="covMultiplierValue.errors.required" errorMessage for="required"></span>
                </span>
              </span>
            </span>
          </ng-template>

          <ng-template kendoGridCellTemplate let-dataItem>
            <p *ngIf="dataItem.calculationMethod == calculationMethod.Formula || dataItem.calculationMethod == calculationMethod.FormulaWithOption">
              <span>{{ dataItem.calculatedCoverage | number: ".2-2"| slxMoney }}</span>
              <i *ngIf="dataItem.calculatedCoverage==-1"
                     title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                     aria-hidden="true" class="fa fa-exclamation-triangle formula-warning"></i>
            </p>
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column field="employeePercentageContribution"
          title="Employee % Contribution"
          [width]="220" class="numeric-cell">
          <ng-template kendoGridHeaderTemplate>
            Employee % Contribution
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <span  *ngIf="dataItem.calculationMethod == calculationMethod.C401K">
              <slx-number
                slx-input
                class="slx-wide slx-input-number"
                [min]="fieldSettings.empPercentContribution.min"
                [slxMin]="fieldSettings.empPercentContribution.min"
                [max]="fieldSettings.empPercentContribution.max"
                [slxMax]="fieldSettings.empPercentContribution.max"
                [step]="fieldSettings.empPercentContribution.step"
                [format]="fieldSettings.empPercentContribution.format"
                [decimals]='fieldSettings.empPercentContribution.decimals'
                [autoCorrect]='dataItem.employeePercentageContribution !=null ? false : true'
                [(ngModel)]="dataItem.employeePercentageContribution"
                (ngModelChange)="onChangePercentContribution(dataItem)"
                name="empPctCont{{rowIndex}}"
                [readonly]="isEmpPercentSelected(dataItem) && (dataItem.calculationMethod == calculationMethod.C401K)"
                #empPctContField="ngModel"
                >
              </slx-number>
              <span *ngIf="empPctContField && empPctContField.errors && !isEmpPercentSelected(dataItem)" class="slx-error-block pull-left">
                <span *ngIf="empPctContField.errors.min || empPctContField.errors.max" errorMessage>Incorrect value</span>
                <span *ngIf="empPctContField.errors.required" errorMessage for="required"></span>
              </span>
          </span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>
            <span *ngIf="dataItem.calculationMethod == calculationMethod.C401K">
              {{ dataItem.employeePercentageContribution }}
            </span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="limitAmount"
          title="Limit Amount"
          [width]="150" class="numeric-cell">
          <ng-template kendoGridHeaderTemplate>
            Limit Amount
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>
            <span *ngIf="dataItem.calculationMethod == calculationMethod.C401K">{{ dataItem.limitAmount | number: ".2-2"| slxMoney }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Beneficiary"
          [width]="150">
          <ng-template kendoGridHeaderTemplate>
            Beneficiary
          </ng-template>
          <ng-template kendoGridEditTemplate
            let-dataItem="dataItem"
            let-rowIndex="rowIndex">
            <span class="edit-link" *ngIf="dataItem.hasBeneficiaries">View</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>
            <span class="edit-link" *ngIf="dataItem.hasBeneficiaries"
              (click)="onViewBeneficiaries(dataItem)">View</span>
          </ng-template>
        </kendo-grid-column>


      </kendo-grid>
    </div>
  </form>
</slx-spinner>
