<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells slx-cell-padding"
  slxKendoGridState="ACCRUALS_BALANCES"
  [slxKendoGridColumnsGroup]="columnsGroupName"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  [reorderable]="isReordable"
  (dataStateChange)="gridState.dataStateChange($event)"
>
  <kendo-grid-column title="Employee Name"
    field="employee.fullName"
    width="250"
    filter="text"
    [locked]="isLockedColumn"
    [reorderable]="false"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
  >
    <ng-template kendoGridHeaderTemplate>
      <span>Employee Name</span>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="accruals-emp" [title]="dataItem.employee?.fullName" (click)="onEmpNameClick(dataItem.employee?.id)">
        <img class="accruals-emp__img" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
        <span class="accruals-emp__text accruals-link">{{ dataItem.employee?.fullName }}</span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Payroll Number"
    field="employee.payrollNumber"
    width="140"
    filter="text"
    [hidden]="!isVisible('payrollNumber')"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
   
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll Number</span>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.employee?.payrollNumber }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Employee Status"
    field="employee.status"
    width="140"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    [hidden]="!isVisible('employeeStatus')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Status</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.employee?.status }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Policy Name"
    field="accrualPolicy.policyName"
    width="250"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    [hidden]="!isVisible('policyName')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Policy Name</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.accrualPolicy?.policyName }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Policy Assignment Date"
    field="accrualPolicy.startDate"
    width="150"
    filter="date"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    [hidden]="!isVisible('policyAssignmentDate')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Policy Assignment Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.accrualPolicy?.startDate | amDateFormat: appConfig.dateFormat }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Organization"
    field="organization.name"
    width="170"
    filter="text"
    class="slx-nowrap-text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    [hidden]="!isVisible('organization')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Organization</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Department"
    field="department.name"
    width="170"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    [hidden]="!isVisible('department')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Department</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Position"
    field="position.name"
    width="230"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    [hidden]="!isVisible('position')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Employee Type"
    field="employee.type"
    width="150"
    filter="text"
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    [hidden]="!isVisible('employeeType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Type</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.employee?.type }}</ng-template>
  </kendo-grid-column>

  <ng-container *ngFor="let accrualType of container?.accrualTypes">
    <kendo-grid-column
      width="150"
      title="{{ accrualType.name }}"
      [field]="getFieldPath(accrualType.name, 'hourBalance')"
      filter="numeric"
      [reorderable]="false"
      [hidden]="!isVisible(accrualType.name)"
    >
      <ng-template kendoGridHeaderTemplate let-dataItem>
        <i class="pay-code-highlighter" aria-hidden="true" [style.border-color]="accrualType.color"></i>
        <span slxKendoGridHeaderTooltip>{{ accrualType.name }}</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <span [class.negative]="dataItem.isNegative(accrualType.name, 'hourBalance')">{{
          dataItem.accrualTypesMap[accrualType.name]?.hourBalance | slxDecimal24Switch : isShowHighPrecision }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="state?.isDollarValueActive && !hideRates"
      width="150"
      title="{{ accrualType.name }} ($)"
      [field]="getFieldPath(accrualType.name, 'dollarBalance')"
      filter="numeric"
      [reorderable]="false"
      [hidden]="!isVisible(accrualType.name) || !state?.isDollarValueActive"
    >      
    <ng-template kendoGridHeaderTemplate let-dataItem>
        <i class="pay-code-highlighter" aria-hidden="true" [style.border-color]="accrualType.color"></i>
        <span slxKendoGridHeaderTooltip>{{ accrualType.name }} ($)</span>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <span [class.negative]="dataItem.isNegative(accrualType.name, 'dollarBalance')">{{
          dataItem.accrualTypesMap[accrualType.name]?.dollarBalance | slxDecimal24Switch : isShowHighPrecision }}</span>
      </ng-template>
    </kendo-grid-column>
  </ng-container>

  <kendo-grid-excel [fileName]="xlsxName" [fetchData]="retriveAllPages()">
    <kendo-excelexport-column title="Employee Name" field="employee.fullName" width="250"></kendo-excelexport-column>
    <kendo-excelexport-column title="Payroll Number" field="employee.payrollNumber" width="140"></kendo-excelexport-column>
    <kendo-excelexport-column title="Employee Status" field="employee.status" width="140"></kendo-excelexport-column>
    <kendo-excelexport-column title="Policy Name" field="accrualPolicy.policyName" width="250"></kendo-excelexport-column>
    <kendo-excelexport-column title="Policy Assignment Date" field="accrualPolicy.startDate" width="150"></kendo-excelexport-column>
    <kendo-excelexport-column title="Organization" field="organization.name" width="170"></kendo-excelexport-column>
    <kendo-excelexport-column title="Department" field="department.name" width="170"></kendo-excelexport-column>
    <kendo-excelexport-column title="Position" field="position.name" width="230"></kendo-excelexport-column>
    <kendo-excelexport-column title="Employee Type" field="employee.type" width="150"></kendo-excelexport-column>
    <ng-container *ngFor="let accrualType of container?.accrualTypes">
      <kendo-excelexport-column title="{{ accrualType.name }}" 
                                [field]="getFieldPath(accrualType.name, 'hourBalance')" 
                                width="150" 
                                [hidden]="!isVisible(accrualType.name)"
                                [cellOptions]="cells"></kendo-excelexport-column>
      <kendo-excelexport-column *ngIf="state?.isDollarValueActive && !hideRates" 
                                title="{{ accrualType.name }} ($)"
                                [field]="getFieldPath(accrualType.name, 'dollarBalance')" 
                                width="150" 
                                [hidden]="!isVisible(accrualType.name)"
                                [cellOptions]="cells"></kendo-excelexport-column>
    </ng-container>
  </kendo-grid-excel>
  <kendo-grid-pdf [fileName]="pdfName" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>
</kendo-grid>