import { EmployeeSectionsBase } from '../employee-sections-base';
import { WcIncidentRosterEntry, IWcIncidentRosterEntry } from '../../../../app-modules/workers-compensation/models';

export interface IEmployeeIncidents {
  records: IWcIncidentRosterEntry[];
}

export class EmployeeIncidents extends EmployeeSectionsBase {

  public records: WcIncidentRosterEntry[];

  constructor() {
    super();
    this.records = [];
  }
}
