/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./geolocation-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../common/pipes/slx-url-safe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./geolocation-map.component";
var styles_GeolocationMapComponent = [i0.styles];
var RenderType_GeolocationMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeolocationMapComponent, data: {} });
export { RenderType_GeolocationMapComponent as RenderType_GeolocationMapComponent };
function View_GeolocationMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "height: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["iframe", 1]], null, 1, "iframe", [["frameborder", "0"], ["height", "100%"], ["loading", "lazy"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.url)); _ck(_v, 1, 0, currVal_0); }); }
function View_GeolocationMapComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "g-map__error-msg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Internal error. Missing the App URL. Please contact your system administrator."]))], null, null); }
function View_GeolocationMapComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "g-map__error-msg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Found multiple locations. Update the address and try again."]))], null, null); }
function View_GeolocationMapComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "g-map__error-msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No results found for \""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notFoundFor; _ck(_v, 3, 0, currVal_0); }); }
function View_GeolocationMapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "g-map__error-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "g-map__error-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeolocationMapComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeolocationMapComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeolocationMapComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.errorCode == 1); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.errorCode == 2); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.errorCode == 3); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_GeolocationMapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SlxUrlSafePipe, [i4.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "g-map"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeolocationMapComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeolocationMapComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isUrl && !_co.hasError); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.hasError; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_GeolocationMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-geolocation-map", [], null, null, null, View_GeolocationMapComponent_0, RenderType_GeolocationMapComponent)), i1.ɵdid(1, 245760, null, 0, i5.GeolocationMapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GeolocationMapComponentNgFactory = i1.ɵccf("slx-geolocation-map", i5.GeolocationMapComponent, View_GeolocationMapComponent_Host_0, { viewModeSettings: "viewModeSettings", activeModeSettings: "activeModeSettings", overrideBy: "overrideBy" }, { radiusChanged: "radiusChanged", centerChange: "centerChange", addressNotFound: "addressNotFound" }, []);
export { GeolocationMapComponentNgFactory as GeolocationMapComponentNgFactory };
