import { Injectable } from '@angular/core';
import { Assert } from '../../../../framework';
import { ApiUtilService, UrlParamsService } from '../../../../common/services';
import { employeeListConfig } from '../../employee-list.config';
import { HttpRequest } from '@angular/common/http';

@Injectable()
export class SlateProfileService {

  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService
  ) { }

  public async assignProfile (employeeIds:string[], profileId: string, orgLevelId: number):Promise<any> {

    Assert.isNotNull(employeeIds);
    Assert.isNotNull(profileId);

    const url: string = `${this.apiUtilService.getApiRoot()}/${employeeListConfig.api.employees.root}/${employeeListConfig.api.employees.actions.root}/${employeeListConfig.api.employees.actions.group.assignSlateProfile}`;

    const body: any = { employeeIds: employeeIds, profileId: profileId };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body, {params: this.urlParamsService.convertToParams({orgLevelId: orgLevelId})});

    let promise: Promise<any> = this.apiUtilService.request(request);
      
    return promise;
  }

}
