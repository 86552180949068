var Beneficiary = /** @class */ (function () {
    function Beneficiary() {
    }
    Object.defineProperty(Beneficiary.prototype, "fullName", {
        get: function () {
            return this.firstName + ' ' + this.lastName;
        },
        enumerable: true,
        configurable: true
    });
    return Beneficiary;
}());
export { Beneficiary };
