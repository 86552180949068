import { AfterViewInit, ElementRef, Renderer2, RendererStyleFlags2, OnDestroy } from '@angular/core';
var SlxTooltipDirective = /** @class */ (function () {
    function SlxTooltipDirective(elementRef, renderer) {
        this.elementRef = elementRef;
        this.renderer = renderer;
    }
    Object.defineProperty(SlxTooltipDirective.prototype, "slxTooltip", {
        get: function () {
            return this.m_slxTooltip;
        },
        set: function (value) {
            this.m_slxTooltip = value;
            this.updateTooltip();
        },
        enumerable: true,
        configurable: true
    });
    SlxTooltipDirective.prototype.ngAfterViewInit = function () {
        var parentNode = this.renderer.parentNode(this.elementRef.nativeElement);
        this.parentNode = parentNode;
        this.renderer.setStyle(parentNode, 'overflow', 'visible', RendererStyleFlags2.Important);
        this.renderer.addClass(parentNode, 'theme-tooltip');
        if (this.tipClass) {
            this.renderer.addClass(parentNode, this.tipClass);
        }
        this.tooltip = this.renderer.createElement('span');
        this.renderer.addClass(this.tooltip, 'theme-tooltiptext');
        this.renderer.addClass(this.tooltip, this.tipPosition ? this.tipPosition : 'top');
        this.renderer.setProperty(this.tooltip, 'innerHTML', this.slxTooltip);
        this.renderer.appendChild(parentNode, this.tooltip);
    };
    SlxTooltipDirective.prototype.ngOnDestroy = function () {
        this.renderer.removeChild(this.parentNode, this.tooltip);
        this.renderer.removeStyle(this.parentNode, 'overflow');
        this.renderer.removeClass(this.parentNode, 'theme-tooltip');
    };
    SlxTooltipDirective.prototype.updateTooltip = function () {
        if (this.tooltip) {
            this.renderer.setProperty(this.tooltip, 'innerHTML', this.slxTooltip);
        }
    };
    return SlxTooltipDirective;
}());
export { SlxTooltipDirective };
