/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../pipes/property";
import * as i4 from "../../directives/blur-forwarder/blur-forwarder.directive";
import * as i5 from "../../directives/focus-forwarder/focus-forwarder.directive";
import * as i6 from "@angular/common";
import * as i7 from "./dropdown-input.component";
var styles_DropdownInputComponent = [i0.styles];
var RenderType_DropdownInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownInputComponent, data: {} });
export { RenderType_DropdownInputComponent as RenderType_DropdownInputComponent };
function View_DropdownInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "input", [["readonly", ""], ["type", "text"]], [[1, "class", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).onBlur($event) !== false);
        ad = (pd_4 && ad);
    } if (("focus" === en)) {
        var pd_5 = (i1.ɵnov(_v, 8).onFocus($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, null), i1.ɵpid(0, i3.PropertyPipe, []), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(7, 16384, null, 0, i4.BlurForwarderDirective, [i1.ElementRef, i1.Renderer], null, null), i1.ɵdid(8, 16384, null, 0, i5.FocusForwarderDirective, [i1.ElementRef, i1.Renderer], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.selectedValue, _co.titleField)); _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_DropdownInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emptyOption; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.emptyOption; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.emptyOption[_co.titleField]; _ck(_v, 3, 0, currVal_2); }); }
function View_DropdownInputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[8, "disabled", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { ngValue: [0, "ngValue"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { ngValue: [0, "ngValue"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.$implicit[_co.valueField]; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit[_co.valueField]; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabledOption === _v.context.$implicit[_co.valueField]); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.context.$implicit[_co.titleField]; _ck(_v, 3, 0, currVal_3); }); }
function View_DropdownInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["selectElement", 1]], null, 11, "select", [], [[1, "class", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onBlur($event) !== false);
        ad = (pd_2 && ad);
    } if (("focus" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).onFocus($event) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.internalValue = $event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = (_co.change($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(6, 16384, null, 0, i4.BlurForwarderDirective, [i1.ElementRef, i1.Renderer], null, null), i1.ɵdid(7, 16384, null, 0, i5.FocusForwarderDirective, [i1.ElementRef, i1.Renderer], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownInputComponent_3)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownInputComponent_4)), i1.ɵdid(11, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.disabled; var currVal_9 = _co.internalValue; _ck(_v, 3, 0, currVal_8, currVal_9); var currVal_10 = _co.showEmptyOption; _ck(_v, 9, 0, currVal_10); var currVal_11 = _co.items; _ck(_v, 11, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; var currVal_1 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 5).ngClassValid; var currVal_6 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_DropdownInputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { select: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownInputComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DropdownInputComponent_2)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.readonly; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.readonly; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DropdownInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-dropdown-input", [], null, null, null, View_DropdownInputComponent_0, RenderType_DropdownInputComponent)), i1.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DropdownInputComponent]), i1.ɵdid(2, 49152, null, 0, i7.DropdownInputComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var DropdownInputComponentNgFactory = i1.ɵccf("slx-dropdown-input", i7.DropdownInputComponent, View_DropdownInputComponent_Host_0, { options: "options", autoSelectFirst: "autoSelectFirst", disabledOption: "disabledOption", valueField: "valueField", titleField: "titleField", readonly: "readonly", disabled: "disabled", placeholder: "placeholder" }, {}, []);
export { DropdownInputComponentNgFactory as DropdownInputComponentNgFactory };
