import * as _ from 'lodash';
import { OnInit, EventEmitter } from '@angular/core';
import { ShiftLate } from '../../../../models/index';
import { appConfig } from '../../../../../app.config';
var ScheduledShiftLatenessSectionComponent = /** @class */ (function () {
    function ScheduledShiftLatenessSectionComponent() {
        this.index = -1;
        this.appConfig = appConfig;
        this.onLateStartDateChanged = new EventEmitter();
        this.onLateIntervalChanged = new EventEmitter();
    }
    Object.defineProperty(ScheduledShiftLatenessSectionComponent.prototype, "late", {
        get: function () {
            return this.m_late;
        },
        set: function (value) {
            this.m_late = value;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledShiftLatenessSectionComponent.prototype.ngOnInit = function () {
        _.noop();
    };
    ScheduledShiftLatenessSectionComponent.prototype.lateDateChanged = function () {
        this.onLateStartDateChanged.next(this.late);
    };
    ScheduledShiftLatenessSectionComponent.prototype.lateIntervalChanged = function () {
        this.onLateIntervalChanged.next(this.late);
    };
    return ScheduledShiftLatenessSectionComponent;
}());
export { ScheduledShiftLatenessSectionComponent };
