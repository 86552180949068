import { Component, OnInit, Provider, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DialogOptions, IDialog, DialogOptions2, DialogModeSize } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';

@Component({
  selector: 'slx-applications-config-dialog',
  templateUrl: './applications-config-dialog.component.html',
  styleUrls: ['./applications-config-dialog.component.scss']
})
export class ApplicationsConfigDialogComponent implements IDialog {
  public dialogResult: boolean = false;
  public isLoading: boolean = false;
  private dialogOptions: DialogOptions;
  private modalService: ModalService;

  public static showDialog(modalService: ModalService, 
    callback?: (result: boolean) => void): ApplicationsConfigDialogComponent { 
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];

    return modalService.globalAnchor.openDialog2(
      ApplicationsConfigDialogComponent,
      `Dashboards`,
      dialogOptions,
      resolvedProviders,
      callback
    );
  }

  constructor (
    options: DialogOptions,
    modalService: ModalService
  ) {
    this.dialogOptions = options;
    this.modalService = modalService
  }

  public onLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public onHasChanges(hasChanges: boolean): void {
    this.dialogResult = hasChanges;
  }

  public onCancel(): void {
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

}
