import { Injectable } from '@angular/core';

import { ResponseBody, Meta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { SlxHttpRequest } from '../../../core/models/index';
import { organizationConfig } from '../../organization.config';

@Injectable()
export class ConstraintsApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public isPayrollSubmittedForOrganization(orgLevelId: number, startDate: Date, endDate: Date ): Promise<boolean> {

    const url: string = `${this.getPayrollApiUrl()}/${organizationConfig.api.payroll.exports}/${organizationConfig.api.payroll.submitted}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, {orgLevelId, startDate, endDate});

    return this.apiUtilService.requestNew<boolean, Meta>(request)
      .then((response: ResponseBody<boolean, Meta>) =>response.data);
  }

  public isPayrollSubmittedForEmployee(employeeId: number, startDate: Date, endDate: Date ): Promise<boolean> {

    const url: string = `${this.getPayrollApiUrl()}/${organizationConfig.api.payroll.exports}/${organizationConfig.api.payroll.submitted}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, {empId: employeeId, startDate: startDate, endDate: endDate});

    return this.apiUtilService.requestNew<boolean, Meta>(request)
      .then((response: ResponseBody<boolean, Meta>) =>response.data);
  }

  public getPayrollApiUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.payroll.ta}/${organizationConfig.api.payroll.root}`;
  }

}
