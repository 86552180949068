<div class="headless-modal-content">
  <div class="modal-header">
    <div class="txt-pad10">
      <span class="pull-left">Additional Data</span>
      <span class="pull-right btn-close" (click)="onCancel()"><span><i class="fa fa-times" aria-hidden="true"></i></span></span>
    </div>
  </div>
  
  <div class="modal-body">
    <form class="form-horizontal" #form="ngForm">
      <div class="field-align">  
       
        <slx-datepicker  #retroDateField="ngModel"
          class="slx-wide"
          name="retroDateField"
          placeholder="Retro Date"
          format="MM/dd/yyyy"            
          [acceptNullDate]="false"                               
          [required]="true"   
          [constantValidation]="true"   
          [(ngModel)]="retroDate"
          [maxDate]="maxDate">
        </slx-datepicker> 
    
        <span *ngIf="retroDateField.errors" class="slx-error-block error-box">
          <span *ngIf="retroDateField.errors.required" errorMessage for="required">Invalid Date</span>
          <span *ngIf="retroDateField.errors.date" errorMessage  for="date">Invalid Date</span>
        </span>
        
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn red" (click)="onCancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
    <button type="button" (click)="onOk()" class="btn green"  [disabled]="!form.valid"><i class="fa fa-check slx-button__icon" aria-hidden="true"></i>Save</button>
  </div>
</div>


