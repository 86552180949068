export * from './external-link.directive';
export * from './daily-unit-assignment-link.directive';

import { ExternalLinkDirective } from './external-link.directive';
import { DailyUnitAssignmentLinkDirective } from './daily-unit-assignment-link.directive';

export const navigationDirectives: any[] = [
  ExternalLinkDirective,
  DailyUnitAssignmentLinkDirective
];

