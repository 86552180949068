import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { mutableSelect, unsubscribeAll } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { BenefitEmployeesApiService } from '../../../services/index';
import { subscribeToOrgLevel } from '../../../../../organization/selectors/index';
import { screenUtils } from '../../../../../common/utils/index';
import { BenefitDetailsTier } from './../../../models/index';
var BenefitEmployeesEligibleComponent = /** @class */ (function () {
    function BenefitEmployeesEligibleComponent(apiService) {
        this.apiService = apiService;
        this.data = [];
        this.filteredRecords = [];
        this.selectedEmployees = new Map();
        this.pageSize = 50;
        this.isCheckedAll = false;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'employeeName', dir: 'asc' }];
        this.appConfig = appConfig;
        this.screenUtils = screenUtils;
        this.selectEmployees = new EventEmitter();
    }
    Object.defineProperty(BenefitEmployeesEligibleComponent.prototype, "skip", {
        set: function (value) {
            if (value === undefined) {
                return;
            }
            this.gridState.state.skip = value;
        },
        enumerable: true,
        configurable: true
    });
    BenefitEmployeesEligibleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.orgLevel = subscribeToOrgLevel(this.orgLevel$, function () { return _this.orgLevel; }, function (orgLevel) { return _this.reload(orgLevel); });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        if (this.canEnroll) {
            this.title = 'You must save or cancel changes on Plan Details page before enrolling employees.';
        }
    };
    BenefitEmployeesEligibleComponent.prototype.isCheckedEmp = function (emp) {
        return this.selectedEmployees.has(emp.employeeId);
    };
    BenefitEmployeesEligibleComponent.prototype.reload = function (orgLevel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orgLevelId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                this.orgLevel = orgLevel;
                orgLevelId = this.lowLevelOrgId ? this.lowLevelOrgId : this.orgLevel.id;
                return [2 /*return*/, this.apiService.getBenefitEligibleEmployees(orgLevelId, this.tierId, this.date)
                        .then(function (records) {
                        _this.data = records;
                        _this.refreshGrid();
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    BenefitEmployeesEligibleComponent.prototype.onSelectAll = function () {
        var _this = this;
        if (this.isCheckedAll) {
            this.selectedEmployees.clear();
            this.isCheckedAll = false;
        }
        else {
            _.forEach(this.filteredRecords, function (e) {
                _this.selectedEmployees.set(e.employeeId, e);
            });
            this.isCheckedAll = true;
        }
    };
    BenefitEmployeesEligibleComponent.prototype.onSelectSingle = function (emp) {
        if (this.selectedEmployees.has(emp.employeeId)) {
            this.selectedEmployees.delete(emp.employeeId);
            this.isCheckedAll = false;
        }
        else {
            this.selectedEmployees.set(emp.employeeId, emp);
            if (_.size(this.filteredRecords) === this.selectedEmployees.size) {
                this.isCheckedAll = true;
            }
        }
    };
    BenefitEmployeesEligibleComponent.prototype.enrollEmployees = function () {
        this.selectEmployees.emit(Array.from(this.selectedEmployees.values()));
    };
    BenefitEmployeesEligibleComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    BenefitEmployeesEligibleComponent.prototype.refreshGrid = function () {
        if (!this.data) {
            this.gridState.view = null;
            return;
        }
        this.filteredRecords = process(this.data, { filter: this.gridState.state.filter, sort: this.gridState.state.sort }).data;
        this.gridState.view = process(this.data, this.gridState.state);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BenefitEmployeesEligibleComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitEmployeesEligibleComponent.prototype, "subscriptions", void 0);
    return BenefitEmployeesEligibleComponent;
}());
export { BenefitEmployeesEligibleComponent };
