import { Component, OnInit, OnDestroy, Inject, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import * as _ from 'lodash';

import { IdealScheduleStateService, IdealScheduleToolbarService, IdealScheduleHelperService } from '../../../services/index';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { LookupService, Lookup, LookupType } from '../../../../organization/index';
import { ParLevels } from '../../../models/index';
import { IdealScheduleConfigTypeDefinition } from '../../../models/ideal-schedule/ideal-schedule-config-type';
import { LookupMultiselectModel, ModalService, ConfirmDialogComponent } from '../../../../common/index';
import { IdealSchedulePositionRange } from '../../../models/ideal-schedule/ideal-schedule-position-range';

@Component({
  moduleId: module.id,
  selector: 'slx-ideal-schedule-range-toolbar',
  templateUrl: 'ideal-schedule-range-toolbar.component.html',
  styleUrls: ['ideal-schedule-range-toolbar.component.scss']
})
export class IdealScheduleRangeToolbarComponent implements OnInit, OnDestroy {

  public maximumRangeValue = Infinity;
  public IdealScheduleConfigTypeDefinition = IdealScheduleConfigTypeDefinition;
  @Input()
  public range: IdealSchedulePositionRange;

  @Input()
  public rangeIndex: number;

  @Input()
  public canDelete: boolean;

  @Input()
  public showAcuitySelector: boolean;

  @mutableSelect()
  public orgLevel$: Observable<OrgLevel>;

  public lookups: {
    acuityLookup: Lookup;
    shiftLookup: Lookup;
    unitLookup: Lookup;
    shiftModels: LookupMultiselectModel[];
    unitModels: LookupMultiselectModel[];
  };

  public selectedShifts: LookupMultiselectModel[];
  public selectedUnits: LookupMultiselectModel[];

  public discarding: boolean;

  public get currentOrgLevel(): OrgLevel {
    return this.m_currentOrgLevel;
  }
  private m_currentOrgLevel: OrgLevel;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  @unsubscribe()
  private stateServiceChangedSubscription: Subscription;

  constructor(
    @Inject(TOOLBAR_SERVICE) private idealScheduleToolbarService: IdealScheduleToolbarService,
    public stateService: IdealScheduleStateService,
    public lookupService: LookupService,
    public scheduleService: IdealScheduleHelperService,
    public modalService: ModalService
  ) {
    this.lookups = {
      acuityLookup: null,
      shiftLookup: null,
      unitLookup: null,
      shiftModels: [],
      unitModels: []
    };

    this.selectedShifts = [];
    this.selectedUnits = [];
  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.m_currentOrgLevel = orgLevel;
      this.orgLevelUpdated();
    });

    this.stateServiceChangedSubscription = this.stateService.poolChanged$.subscribe( () => {
      this.updateLookups();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onClone(): void {
    this.stateService.cloneRange(this.range);
  }

  public onDelete(): void {
    this.stateService.deleteRange(this.rangeIndex);
  }

  public filtersChanged(data?: LookupMultiselectModel[]): void {
    if (this.discarding || !data) {
      return;
    }

    let promise = Promise.resolve(true);

    const deselectedUnits = _.filter(this.stateService.getSelectedUnits(this.range), unit => {
      return unit.isSelected && !_.some(this.selectedUnits, selectedUnit => selectedUnit.object.id === unit.object.id);
    });

    const deselectedShifts = _.filter(this.stateService.getSelectedShifts(this.range), shift => {
      return shift.isSelected && !_.some(this.selectedShifts, selectedShift => selectedShift.object.id === shift.object.id);
    });

    const hasHours = this.positionHasHours(deselectedUnits, deselectedShifts);

    if (hasHours) {
      promise = this.showDiscardHoursDialog();
    }

    promise.then((replaceFilters: boolean) => {
      this.discarding = false;

      if (replaceFilters) {
        this.stateService.setSelectedUnits(_.map(this.selectedUnits, selectedUnit => {
          selectedUnit.isSelected = true;
          return selectedUnit;
        }), this.range);

        this.stateService.setSelectedShifts(_.map(this.selectedShifts, selectedShift => {
          selectedShift.isSelected = true;
          return selectedShift;
        }), this.range);
      } else {
        this.selectedUnits = _.map(this.stateService.getSelectedUnits(this.range), selectedUnit => {
          selectedUnit.isSelected = true;
          return selectedUnit;
        });

        this.selectedShifts = _.map(this.stateService.getSelectedShifts(this.range), selectedShift => {
          selectedShift.isSelected = true;
          return selectedShift;
        });
      }

      this.stateService.reconfigureRangeParLevels(this.range);
    });
  }

  private showDiscardHoursDialog(): Promise<boolean> {
    this.discarding = true;

    return new Promise((resolve) => {
      ConfirmDialogComponent.openDialog(
        'Discard changes',
        'The deselected unit/shift has been used in this Ideal Schedule. Are you sure you wish to proceed?',
        this.modalService,
        (result: boolean) => {
          resolve(result);

          if (result) {
            this.stateService.rangeChanged();
          }
        }
      );
    });
  }

  private positionHasHours(units: LookupMultiselectModel[], shifts: LookupMultiselectModel[]): boolean {
    let hasHours = false;

    if (!this.range) {
      return false;
    }

    if ((!units || !units.length) && (!shifts || !shifts.length)) {
      return false;
    }

    const parLevels = _.values(this.range.parLevels);

    if (units.length) {
      const unitFilteredParLevels = _.filter(parLevels, parLevel => _.some(units, unit => parLevel.unit && parLevel.unit.id === unit.object.id));
      hasHours = hasHours || _.some(unitFilteredParLevels, parLevel => !parLevel.isUnused());
    }

    if (shifts.length) {
      const shiftFilteredParLevels = _.filter(parLevels, parLevel => _.some(shifts, shift => parLevel.shift && parLevel.shift.id === shift.object.id));
      hasHours = hasHours || _.some(shiftFilteredParLevels, parLevel => !parLevel.isUnused());
    }

    return hasHours;
  }

  private orgLevelUpdated(): void {
    let acuityPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.acuity,
      orgLevelId: this.m_currentOrgLevel.id,
      employeeId: undefined
    });

    let shiftPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.shiftDefinition,
      orgLevelId: this.m_currentOrgLevel.id,
      employeeId: undefined
    });

    let unitPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.locationUnit,
      orgLevelId: this.m_currentOrgLevel.id,
      employeeId: undefined
    });

    Promise.all([acuityPromise, shiftPromise, unitPromise]).then((arrays: Lookup[]) => {
      this.lookups.acuityLookup = arrays[0];
      this.lookups.shiftLookup = arrays[1];
      this.lookups.unitLookup = arrays[2];

      this.updateLookups(true);
    });
  }

  private updateLookups(updateFilters: boolean = false): void {
    if (!this.stateService.selectedPosition || !this.range) {
      return;
    }

    let array: ParLevels[] = _.values(this.range.parLevels);

    if (this.lookups.shiftLookup) {
      this.lookups.shiftModels = this.scheduleService.filterShiftLookup(array, this.lookups.shiftLookup);
      this.selectedShifts = _.filter(this.lookups.shiftModels, shiftModel => shiftModel.isSelected);
    }

    if (this.lookups.unitLookup) {
      this.lookups.unitModels = this.scheduleService.filterUnitLookup(array, this.lookups.unitLookup);
      this.selectedUnits = _.filter(this.lookups.unitModels, unitModel => unitModel.isSelected);
    }

    if (this.lookups.acuityLookup && !this.range.acuity.acuity) {
      this.range.acuity.acuity = _.first(this.lookups.acuityLookup.items);
    }

    if (updateFilters) {
      this.filtersChanged();
    }
  }
}
