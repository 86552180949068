import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import { UrlParamsService, ApiUtilService, DateTimeService } from '../../../common/services/index';
import { dateTimeUtils } from '../../../common/utils';
import { Assert } from '../../../framework/index';
import { organizationConfig } from '../../organization.config';
import { appConfig } from '../../../app.config';
import { ResponseBody, FieldsMeta } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { EmployeeDefinitionsMapService } from './employee-definitions-map.service';
import {
  EmployeePhoto, IEmployeePhoto, EmployeeShortInfo, IEmployeeShortInfo,
  EmployeeBadge, IEmployeeBadge, IEmployeeFieldValidation, EmployeeFieldValidation,
  IEmployeeBenefitClass, EmployeeBenefitClass, IEmployeeResendOptInStatus, EmployeeResendOptInStatus
} from '../../models/index';
import { configurationConfig } from '../../../configuration/configuration.config';
import { url } from 'inspector';

@Injectable()
export class EmployeeDefinitionsApiService {
  public employeeShortInfoResponseCache = new Map();
  constructor(
    private apiUtilService: ApiUtilService,
    private employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    private urlParamService: UrlParamsService,
    private dateTimeService: DateTimeService) {
  }

  public getEmployeePhoto(employeeId: number): Promise<EmployeePhoto> {
    Assert.isNotNull(employeeId);

    const url: string = `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.employee.root}/${employeeId}/${organizationConfig.api.employee.photo}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeePhoto> = this.apiUtilService.request<IEmployeePhoto, Meta>(request)
      .then((response: ResponseBody<IEmployeePhoto, Meta>) => this.employeeDefinitionsMapService.mapToEmployeePhoto(response.data));
    return promise;
  }

  public setEmployeePhoto(employeeId: number, dataUrl: string): Promise<EmployeePhoto> {
    Assert.isNotNull(employeeId);

    const url: string = `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.employee.root}/${employeeId}/${organizationConfig.api.employee.photo}`;

    let request: HttpRequest<any> = new HttpRequest('PUT', url, { data: dataUrl });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
    return promise;
  }

  public getEmployeeThumbnails(employeeIds: number[]): Promise<EmployeePhoto[]> {
    Assert.isNotNull(employeeIds);

    const url: string = `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.employee.root}/${organizationConfig.api.employee.thumbnails}`;

    let request: HttpRequest<any> = this.urlParamService.createPostRequest(url, employeeIds);

    let promise: Promise<EmployeePhoto[]> = this.apiUtilService.request<IEmployeePhoto[], Meta>(request)
      .then((response: ResponseBody<IEmployeePhoto[], Meta>) => this.employeeDefinitionsMapService.mapToEmployeePhotos(response.data));
    return promise;
  }

  public getEmployeeShortInfoCached(employeeId: number): Promise<EmployeeShortInfo> {
    Assert.isNotNull(employeeId);

    if (this.employeeShortInfoResponseCache && this.employeeShortInfoResponseCache.get(employeeId)) {    
      const shortInfo = this.employeeShortInfoResponseCache.get(employeeId);
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(shortInfo.timeStamp), new Date());
      if (seconds < appConfig.cacheInvalidateEmployeShortInfo) {
        return new Promise((resolve) => {
          resolve(shortInfo.data);
        });
      } else {
        this.clearEmployeeShortInfoCache(employeeId);
      }
    }

    const url: string = `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.employees.root}/${employeeId}/${
      organizationConfig.api.employees.shortInfo
    }`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let mappedEmployeShortInfo;
    let promise: Promise<EmployeeShortInfo> = this.apiUtilService
      .request<IEmployeeShortInfo, Meta>(request)
      .then((response: ResponseBody<IEmployeeShortInfo, Meta>) => {
        mappedEmployeShortInfo = this.employeeDefinitionsMapService.mapToEmployeeShortInfo(response.data);
        this.employeeShortInfoResponseCache.set(employeeId, { data: mappedEmployeShortInfo, timeStamp: new Date().getTime()});
        return mappedEmployeShortInfo;
      });
    return promise;
  }

  public clearAllEmployeeshortInfoCache() {
    this.employeeShortInfoResponseCache.clear()
  }

  public clearEmployeeShortInfoCache(employeeId: number) {
    if(this.employeeShortInfoResponseCache.has(employeeId)) {
      this.employeeShortInfoResponseCache.delete(employeeId)
    }
  }
  

  public getEmployeeShortInfo(employeeId: number): Promise<EmployeeShortInfo> {
    Assert.isNotNull(employeeId);

    const url: string = `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.employees.root}/${employeeId}/${organizationConfig.api.employees.shortInfo}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeShortInfo> = this.apiUtilService.request<IEmployeeShortInfo, Meta>(request)
      .then((response: ResponseBody<IEmployeeShortInfo, Meta>) => this.employeeDefinitionsMapService.mapToEmployeeShortInfo(response.data));
    return promise;
  }

  public generateBadges(employeeIds: number[]): Promise<EmployeeBadge[]> {
    Assert.isNotNull(employeeIds);

    const url: string = `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.employee.root}/${organizationConfig.api.employee.generateBadges}`;

    let request: HttpRequest<any> = new HttpRequest('POST', url, employeeIds);

    let promise: Promise<EmployeeBadge[]> = this.apiUtilService.request<IEmployeeBadge[], Meta>(request)
      .then((response: ResponseBody<IEmployeeBadge[], Meta>) => this.employeeDefinitionsMapService.mapToEmployeeBadges(response.data));
    return promise;
  }

  public validateSsn(ssn: string, orgLevelId: number, employeeId: number): Promise<EmployeeFieldValidation> {
    Assert.isNotNull(ssn, 'ssn');
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getApiValidation()}/${organizationConfig.api.employees.validation.ssn}`;
    let request: HttpRequest<any> = this.urlParamService.createGetRequest(url, { ssn: ssn, organizationId: orgLevelId, employeeId: employeeId });
    let promise: Promise<EmployeeFieldValidation> = this.apiUtilService.request<IEmployeeFieldValidation, Meta>(request)
      .then((response: ResponseBody<IEmployeeFieldValidation, Meta>) =>
        this.employeeDefinitionsMapService.mapEmployeeValidation(response.data)
      );
    return promise;
  }

  public validatePbjId(pbjId: string, employeeId: number): Promise<EmployeeFieldValidation> {
    Assert.isNotNull(pbjId, 'pbjId');
    const url: string = `${this.getApiValidation()}/${organizationConfig.api.employees.validation.pbjId}`;
    let request: HttpRequest<any> = this.urlParamService.createGetRequest(url, { pbjId: pbjId, employeeId: employeeId });
    let promise: Promise<EmployeeFieldValidation> = this.apiUtilService.request<IEmployeeFieldValidation, Meta>(request)
      .then((response: ResponseBody<IEmployeeFieldValidation, Meta>) =>
        this.employeeDefinitionsMapService.mapEmployeeValidation(response.data)
      );
    return promise;
  }
  
  public validatePayrollNumber(payrollNumber: string, orgLevelId: number, employeeId: number): Promise<EmployeeFieldValidation> {
    Assert.isNotNull(payrollNumber, 'payrollNumber');
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getApiValidation()}/${organizationConfig.api.employees.validation.payrollNumber}`;

    let request: HttpRequest<any> = this.urlParamService.createGetRequest(url, {
      payrollNumber: payrollNumber,
      orgLevelId: orgLevelId,
      employeeId: employeeId
    });
    let promise: Promise<EmployeeFieldValidation> = this.apiUtilService.request<IEmployeeFieldValidation, Meta>(request)
      .then((response: ResponseBody<IEmployeeFieldValidation, Meta>) =>
        this.employeeDefinitionsMapService.mapEmployeeValidation(response.data)
      );
    return promise;
  }

  public validateNames(firstName: string, lastName: string, middleName: string, prefix: string, departmentOrgLevelId: number): Promise<EmployeeFieldValidation> {
    Assert.isNotNull(firstName, 'firstName');
    Assert.isNotNull(lastName, 'lastName');
    Assert.isNotNull(departmentOrgLevelId, 'departamentOrgLevelId');
    const url: string = `${this.getApiValidation()}/${organizationConfig.api.employees.validation.names}`;

    let request: HttpRequest<any> = this.urlParamService.createGetRequest(url, { firstName: firstName, lastName: lastName, middleName: middleName, prefix: prefix, departmentOrgLevelId: departmentOrgLevelId });
    let promise: Promise<EmployeeFieldValidation> = this.apiUtilService.request<IEmployeeFieldValidation, Meta>(request)
      .then((response: ResponseBody<IEmployeeFieldValidation, Meta>) =>
        this.employeeDefinitionsMapService.mapEmployeeValidation(response.data)
      );
    return promise;
  }

  public validateBadgeId(badgeId: string, employeeId: number): Promise<EmployeeFieldValidation> {
    Assert.isNotNull(badgeId, 'badgeId');
    const url: string = `${this.getApiValidation()}/${organizationConfig.api.employees.validation.badgeId}`;
    let request: HttpRequest<any> = this.urlParamService.createGetRequest(url, {
      badgeId: badgeId,
      employeeId: employeeId
    });

    let promise: Promise<EmployeeFieldValidation> = this.apiUtilService.request<IEmployeeFieldValidation, Meta>(request)
      .then((response: ResponseBody<IEmployeeFieldValidation, Meta>) =>
        this.employeeDefinitionsMapService.mapEmployeeValidation(response.data)
      );
    return promise;
  }

  public isValidEmail(email: string, employeeId: number): Promise<EmployeeFieldValidation> {
    Assert.isNotNull(email, 'email');
    const url: string = `${this.getApiValidation()}/${organizationConfig.api.employees.validation.email}`;
    let request: HttpRequest<any> = this.urlParamService.createGetRequest(url, { email: email, employeeId: employeeId });
    let promise: Promise<EmployeeFieldValidation> = this.apiUtilService.request<IEmployeeFieldValidation, Meta>(request)
      .then((response: ResponseBody<IEmployeeFieldValidation, Meta>) =>
        this.employeeDefinitionsMapService.mapEmployeeValidation(response.data)
      );
    return promise;
  }

  public generateBadgeId(organizationId: number): Promise<string> {
    const url: string = `${this.getApiActions()}/${organizationConfig.api.employees.actions.generateBadgeId}`;
    let params: HttpParams = this.urlParamService.convertToParams({ organizationId: organizationId });
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<string> = this.apiUtilService.request<string, Meta>(request)
      .then((response: ResponseBody<any, Meta>) =>
        response.data
      );
    return promise;
  }

  public generatePbjId(): Promise<string> {
    const url: string = `${this.getApiPbj()}/${organizationConfig.api.pbj.id}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<string> = this.apiUtilService.request<string, Meta>(request)
      .then((response: ResponseBody<any, Meta>) =>
        response.data.value
      );
    return promise;
  }

  public generatePassword(): Promise<number> {
    const url: string = `${this.getApiActions()}/${organizationConfig.api.employees.actions.generatePassword}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<number> = this.apiUtilService.request<number, Meta>(request)
      .then((response: ResponseBody<any, Meta>) =>
        response.data.password
      );
    return promise;
  }

  public getEmployeeBenefitClass(employeeId: number, effectiveDate: Date = null): Promise<EmployeeBenefitClass> {
    Assert.isNotNull(employeeId);

    const url: string = `${this.getApiBenefitClass(employeeId)}`;
    const params: HttpParams = this.urlParamService.convertToParams({
      effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate)
    });
    const request: HttpRequest<any> = new HttpRequest('GET', url, { params });

    let promise: Promise<EmployeeBenefitClass> = this.apiUtilService.request<IEmployeeBenefitClass, Meta>(request)
      .then((response: ResponseBody<IEmployeeBenefitClass, Meta>) => this.employeeDefinitionsMapService.mapToEmployeeBenefitClass(response.data));
    return promise;
  }

  public getCanEditBenefitClass(employeeId: number): Promise<boolean> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url = `${this.getApiBenefitClass(employeeId)}/${organizationConfig.api.employees.benefitsManagement.benefitClass.canEdit}`;
    const request = this.urlParamService.createGetRequest(url);

    return this.apiUtilService.request<boolean, Meta>(request)
      .then((response: ResponseBody<boolean, Meta>) => !!response.data);
  }

  public postBenefitsClass(employeeId: number, benefitsClass: EmployeeBenefitClass): Promise<any>  {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(benefitsClass, 'benefitsClass');

    const url = `${this.getApiBenefitClass(employeeId)}`;
    const body = this.employeeDefinitionsMapService.mapToEmployeeBenefitClassDTO(benefitsClass);
    const request = this.urlParamService.createPostRequest(url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }

  private getApiBenefitsManagement(employeeId: number): string {
    return `${this.getEmployeesApi()}/${employeeId}/${organizationConfig.api.employees.benefitsManagement.root}`;
  }

  private getApiBenefitClass(employeeId: number): string {
    return `${this.getApiBenefitsManagement(employeeId)}/${organizationConfig.api.employees.benefitsManagement.benefitClass.root}`;
  }

  public assignAccrualsPolicies(orgLevelId: number, massPolicyAssignments: any): Promise<any> {
    const url: string = `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.accruals.root}/${orgLevelId}/${organizationConfig.api.accruals.policy.root}/${organizationConfig.api.accruals.policy.massPolicyAssignment}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, massPolicyAssignments);
    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<number, FieldsMeta>) => response.status
      );
    return promise;
  }

  public resendOptInSms(employeeIds: number[], orgLevelId: number): Promise<EmployeeResendOptInStatus[]> {
    Assert.isNotNull(orgLevelId);
    const url = this.getSmsOptinApi();

    const request = this.urlParamService.createPostRequest(url, { employeeIds, orgLevelId });
    const promise = this.apiUtilService
      .request<IEmployeeResendOptInStatus[], FieldsMeta>(request)
      .then(response => this.employeeDefinitionsMapService.mapToEmployeeResendOptInStatuses(response.data));

    return promise;
  }

  public resendOptInEmail(employeeIds: number[]): Promise<EmployeeResendOptInStatus[]> {
    const url = this.getEmailOptinApi();

    const request = this.urlParamService.createPostRequest(url, { employeeIds });
    const promise = this.apiUtilService
      .request<IEmployeeResendOptInStatus[], FieldsMeta>(request)
      .then(response => this.employeeDefinitionsMapService.mapToEmployeeResendOptInStatuses(response.data));

    return promise;
  }

  public getEmailTemplate(): Promise<string> {
    const url = `${this.getEmailOptinApi()}/${organizationConfig.api.employees.optin.template}`;
    const request = this.urlParamService.createGetRequest(url);

    return this.apiUtilService
      .request<string, Meta>(request)
      .then(response => response.data);
  }

  public getSmsTemplate(orgLevelId: number): Promise<string> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url = `${this.getSmsOptinApi()}/${orgLevelId}/${organizationConfig.api.employees.optin.template}`;
    const request = this.urlParamService.createGetRequest(url);

    return this.apiUtilService
      .request<string, Meta>(request)
      .then(response => response.data);
  }

  public getNextPayrollNumber(orgLevelId: number, employeeId: number): Promise<string> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getEmployeesApi()}/${organizationConfig.api.employees.actions.getNextPayrollNumber}`;

    const request: HttpRequest<any> = this.urlParamService.createGetRequest(url, {
      orgLevelId: orgLevelId,
      employeeId: employeeId
    });
    const promise: Promise<string> = this.apiUtilService.request<string, Meta>(request)
      .then((response: ResponseBody<string, Meta>) => response.data);
    return promise;
  }

  private getApiValidation(): string {
    return `${this.getEmployeesApi()}/${organizationConfig.api.employees.validation.root}`;
  }

  private getApiActions(): string {
    return `${this.getEmployeesApi()}/${organizationConfig.api.employees.actions.root}`;
  }

  private getEmailOptinApi(): string {
    return `${this.getOptinApi()}/${organizationConfig.api.employees.optin.email}`;
  }

  private getSmsOptinApi(): string {
    return `${this.getOptinApi()}/${organizationConfig.api.employees.optin.sms}`;
  }

  private getOptinApi(): string {
    return `${this.getEmployeesApi()}/${organizationConfig.api.employees.optin.root}`;
  }

  private getEmployeesApi(): string {
    return `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.employees.root}`;
  }

  private getApiPbj(): string {
    return `${this.apiUtilService.getApiRoot()}/${organizationConfig.api.pbj.root}`;
  }

  public getPayrollNumberInfo(emporgId:number,orgId :number): Promise<boolean>
  { 
    const url: string = `${this.getPayrollNumberInfoUrl()}`;
    const request: HttpRequest<any> = this.urlParamService.createGetRequest(url,
      {
        emporgId:emporgId,
        orgId: orgId
    });
    const promise: Promise<boolean> = this.apiUtilService.request<boolean, Meta>(request)
      .then((response: ResponseBody<boolean, Meta>) => response.data);
    return promise;
      
  }

  private getPayrollNumberInfoUrl():string
  {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.orglevels.root}/${configurationConfig.api.orglevels.transferPayrollInfo}`;
  }
}
