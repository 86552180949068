import * as tslib_1 from "tslib";
import { appConfig } from './../../../../app.config';
import { PayCycleHelperService } from './../../../../organization/services/pay-cycle-helper/pay-cycle-helper.service';
import * as _ from 'lodash';
import { EventEmitter, OnInit } from '@angular/core';
import { StyledUserApplication } from '../../../../organization/models/index';
import { TaDashboardCounters, AlertConfig } from '../../../models/index';
import { DashboardTAAlertEnum } from '../../../../portal/models/index';
import * as moment from 'moment';
import { OrgLevel } from '../../../../state-model/models/index';
import { ApplicationDashboardManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';
var TaDashboardComponent = /** @class */ (function () {
    function TaDashboardComponent(payCycleHelperService, appDashboardMngtService) {
        this.payCycleHelperService = payCycleHelperService;
        this.appDashboardMngtService = appDashboardMngtService;
        this.onLoading = new EventEmitter();
        this.links = {
            missingPunch: 'daily_punches',
            overtimeApproval: 'overtime_approvals',
            timecardException: 'exception_console'
        };
        this.onLoading.emit(true);
    }
    Object.defineProperty(TaDashboardComponent.prototype, "orgLevel", {
        set: function (value) {
            if (!value)
                return;
            this.calcDatesParams(value.id);
        },
        enumerable: true,
        configurable: true
    });
    TaDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.countersSubscription = this.appDashboardMngtService
            .subscribeToCountersLoaded(function (counters) {
            _this.mapCounters(counters['Time']);
            _this.setCounter();
        });
    };
    TaDashboardComponent.prototype.mapCounters = function (counters) {
        this.counters = _.isObject(counters) && _.size(counters) > 0 ? counters : new TaDashboardCounters();
        this.loadAlerts();
    };
    TaDashboardComponent.prototype.loadAlerts = function () {
        var _this = this;
        this.appDashboardMngtService.getDashboardAppAlerts(this.application.id)
            .then(function (data) {
            _this.alertConfigOrderAndVisibilty = _.filter(data, function (t) { return t.visible; });
            _this.setCounter();
            _this.onLoading.emit(false);
        });
    };
    TaDashboardComponent.prototype.onHasChanges = function (hasChanges) {
        if (hasChanges) {
            this.loadAlerts();
        }
    };
    TaDashboardComponent.prototype.getRouterLink = function (path) {
        return [this.application.link, path];
    };
    TaDashboardComponent.prototype.calcDatesParams = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dateRange;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.payCycleHelperService.getEffectivePunchesDateRange(new Date(), orgLevelId)];
                    case 1:
                        dateRange = _a.sent();
                        this.datesParams = {
                            startDate: moment(dateRange.startDate).format(appConfig.linkDateFormat),
                            endDate: moment(dateRange.endDate).format(appConfig.linkDateFormat)
                        };
                        this.orgLevelId = orgLevelId;
                        this.startDate = moment().subtract(14, 'days').toDate();
                        this.endDate = dateRange.endDate;
                        return [2 /*return*/];
                }
            });
        });
    };
    TaDashboardComponent.prototype.setCounter = function () {
        var _this = this;
        var appAlerts = this.alertConfigOrderAndVisibilty;
        _.forEach(appAlerts, function (record) {
            if (record.alertId == DashboardTAAlertEnum.missingPunchCount) {
                record.counter = _this.counters.missingPunch;
            }
            else if (record.alertId == DashboardTAAlertEnum.overtimeApprovals) {
                record.counter = _this.counters.overtimeApproval;
            }
            else if (record.alertId == DashboardTAAlertEnum.timecardExceptions) {
                record.counter = _this.counters.timecardException;
            }
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TaDashboardComponent.prototype, "countersSubscription", void 0);
    return TaDashboardComponent;
}());
export { TaDashboardComponent };
