import { Injectable } from '@angular/core';

@Injectable()
export class Redirect2PageService {

    public RedirectByGet(url: string, target: string): void {
        window.open(url, target);
    }

    /*
    // this method was used originaly.
    // added some code to remove form on next click,
    // but that was not tested for cross-browser conditions
    // commenting it for now and will improve in future, may be with jquery selectors
    // if we will need redirect with post request

    public RedirectByPost(obj: any, url: string): void {

        let oldForm = document.querySelector ('form[action="'+url+'"]');
        if (oldForm != null) {
            document.body.removeChild (oldForm);
        }
        let mapForm = document.createElement('form');
        mapForm.id = 'redirectForm';
        mapForm.target = '_blank';
        mapForm.method = 'POST'; // or "post" if appropriate
        mapForm.action = url;
        if (obj !== null && obj !== undefined) {
            Object.keys(obj).forEach(function (param): void {
                let mapInput = document.createElement('input');
                mapInput.type = 'hidden';
                mapInput.name = param;
                mapInput.setAttribute('value', obj[param]);
                mapForm.appendChild(mapInput);
            });
        }
        document.body.appendChild(mapForm);
        mapForm.submit();
    }
    */
}
