import {
  EmpType,
  Department,
  Organization,
  Position,
  PayPolicy,
  ShiftDiffPolicy,
  PayType,
  ExemptStatus,
  State,
  MaritalStatus,
  Gender,
  Race,
  IEmpType,
  IDepartment,
  IOrganization,
  IPosition,
  IPayPolicy,
  IShiftDiffPolicy,
  EssEntity,
  BenefitClassDefinition
} from '../../../../organization/models/lookup/index';
import { ISelectableItemContainer } from '../../../../common/index';
import { TimeclockDefinition } from '../../../../organization/models/index';

export interface IAddEmployeeModel {
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  employeeType: IEmpType;
  position: IPosition;

  payrollNumber: string;
  ssn: string;
  hireDate: string;
  birthDate: string;
  payPolicy: IPayPolicy;
  shiftDiffPolicy: IShiftDiffPolicy;
  payType: string;
  exemptStatus: string;
  workWeekHours: number;
  dailyBenefitHours: number;
  hourlyRate: number;
  hourlyRate2: number;
  hourlyRate3: number;
  payClass: string;
  companyCode: string;
  fileNumber: string;
  pbjId: string;

  address: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  mobilePhone: string;
  altPhone: string;
  email1: string;
  email2: string;
  gender: string;
  maritalStatus: string;
  race: string;

  badgeId: string;
  timeclockIds: number[];
  enableTimesheetsAccess: boolean;
  accessType: string;
  timeCard: boolean;
  schedule: boolean;
  availability: boolean;
  departmentSchedule: boolean;
  inservice: boolean;
  payStubs: boolean;
  allowPasswordChange: boolean;
  allowAddressChange: boolean;

  password: string;

  benefitClassId: number;
  benefitClassEffectiveDate: string;
  optInRequest: boolean
}
export class AddEmployeeModel {
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public suffix: string;
  public employeeType: EmpType;
  public organization: Organization;
  public department: Department;
  public position: Position;

  public payrollNumber: string;
  public defaultPayrollNumber: string;
  public ssn: string;
  public hireDate: Date;
  public birthDate: Date;
  public payPolicy: PayPolicy;
  public shiftDiffPolicy: ShiftDiffPolicy;
  public payType: PayType;
  public exemptStatus: ExemptStatus;
  public workWeekHours: number;
  public dailyBenefitHours: number;
  public hourlyRate: number;
  public hourlyRate2: number;
  public hourlyRate3: number;
  public payClass: string;
  public companyCode: string;
  public fileNumber: string;
  public pbjId: string;

  public address: string;
  public city: string;
  public state: State;
  public zipCode: string;
  public phone: string;
  public mobilePhone: string;
  public altPhone: string;
  public email1: string;
  public email2: string;
  public gender: Gender;
  public maritalStatus: MaritalStatus;
  public race: Race;

  public badgeId: string;
  public timeclockDefinitions: ISelectableItemContainer<TimeclockDefinition>[];
  public enableTimesheetsAccess: boolean;
  public accessType: EssEntity;
  public timeCard: boolean;
  public schedule: boolean;
  public availability: boolean;
  public departmentSchedule: boolean;
  public inservice: boolean;
  public payStubs: boolean;
  public allowPasswordChange: boolean;
  public allowAddressChange: boolean;

  public password: string;
  public ignoreValidation: boolean;

  public benefitClass: BenefitClassDefinition;
  public benefitClassEffectiveDate: Date = null;
  public optInRequest: boolean;

  constructor() {
    this.timeclockDefinitions = [];
  }
}
