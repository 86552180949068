import * as _ from 'lodash';
import * as moment from 'moment';
import '@progress/kendo-date-math/tz/America/New_York';
import { parseRule, RecurrenceRule } from '@progress/kendo-recurrence';
import { dateTimeUtils } from '../../../../common/utils/index';
import { LoaRequest, LoaMappedRequest, RecurrenceFrequencies } from '../../models/index';
import { DateRange } from '../../../../core/models/index';
var LMRequestMapperService = /** @class */ (function () {
    function LMRequestMapperService() {
        this.firstDayOfWeek = 0;
        this.dayMs = 1000 * 60 * 60 * 24;
    }
    LMRequestMapperService.prototype.mapRequest = function (req) {
        if (req.isContinuous) {
            return this.mapContinuous(req);
        }
        else if (req.isIntermittent) {
            if (req.isDaily || req.isWeekly || req.isMonthly) {
                return this.mapIntermittentRepeatable(req);
            }
            else {
                return this.mapIntermittentCustom(req);
            }
        }
        else {
            console.error('Cannot map request, LOA request has unknown type', req);
            return new LoaMappedRequest(req, {});
        }
    };
    LMRequestMapperService.prototype.mapContinuous = function (req) {
        var _a = this.getLoaDates(req), startDate = _a.startDate, endDate = _a.endDate;
        var exceptions = this.getExceptions(req);
        var exceptionsDays = this.createExceptionDays(exceptions);
        var absenceDays = this.createDays(startDate, endDate);
        var clearAbsenceDays = this.excludeExceptions(absenceDays, exceptionsDays);
        return new LoaMappedRequest(req, clearAbsenceDays);
    };
    LMRequestMapperService.prototype.mapIntermittentCustom = function (req) {
        var customDates = this.getCustomDates(req);
        var exceptions = this.getExceptions(req);
        var exceptionsDays = this.createExceptionDays(exceptions);
        var absenceDays = this.createDaysFromRanges(customDates);
        var clearAbsenceDays = this.excludeExceptions(absenceDays, exceptionsDays);
        return new LoaMappedRequest(req, clearAbsenceDays);
    };
    LMRequestMapperService.prototype.mapIntermittentRepeatable = function (req) {
        var absenceDays = this.buildAbsenceDays(req);
        var exceptions = this.getExceptions(req);
        var exceptionsDays = this.createExceptionDays(exceptions);
        var clearAbsenceDays = this.excludeExceptions(absenceDays, exceptionsDays);
        return new LoaMappedRequest(req, clearAbsenceDays);
    };
    LMRequestMapperService.prototype.buildAbsenceDays = function (req) {
        var _a = this.getLoaDates(req), startDate = _a.startDate, endDate = _a.endDate;
        var rrule = this.getRecurrenceRule(req);
        var absenceDays = this.buildAbsenceDaysFromRule(rrule, startDate);
        return absenceDays;
    };
    LMRequestMapperService.prototype.evaluateClearAbsencePeriod = function (sDate, rrule) {
        var rule = this.parseRule(rrule, this.firstDayOfWeek);
        var absenceDays = this.buildAbsenceDaysFromRule(rule, sDate);
        var range = new DateRange(null, null);
        if (_.size(absenceDays) > 0) {
            var hasUntil = _.isObjectLike(rule.until);
            var hasCount = _.isFinite(rule.count) && rule.count > 0;
            range = new DateRange(_.head(absenceDays).startDate, _.last(absenceDays).endDate);
            if (!hasUntil && !hasCount) {
                range.endDate = null;
            }
        }
        return range;
    };
    LMRequestMapperService.prototype.getRecurrenceRule = function (req) {
        var rrule = req.loaRepeat.recurrenceRule;
        return this.parseRule(rrule, this.firstDayOfWeek);
    };
    LMRequestMapperService.prototype.buildAbsenceDaysFromRule = function (rrule, startDate) {
        var freq = (rrule || {}).freq;
        switch (freq) {
            case RecurrenceFrequencies.daily:
                return this.getDailyAbsenceDays(rrule, startDate);
            case RecurrenceFrequencies.weekly:
                return this.getWeeklyAbsenceDays(rrule, startDate);
            default:
                return [];
        }
    };
    LMRequestMapperService.prototype.getDailyAbsenceDays = function (rrule, sDate) {
        var interval = rrule.interval, count = rrule.count, until = rrule.until;
        var hasCount = _.isFinite(count) && count > 0;
        var absenceRanges = [];
        var _a = this.evaluateAbsenceRange(sDate, interval, count, until), startDate = _a.startDate, endDate = _a.endDate;
        var days = this.createDays(startDate, endDate);
        for (var i = 0, iteration = 0, length_1 = days.length; i < length_1; i++) {
            var dayIndex = i + 1;
            if (dayIndex % interval !== 0)
                continue;
            absenceRanges.push(days[i]);
            iteration++;
            if (hasCount && count === iteration)
                break;
        }
        return absenceRanges;
    };
    LMRequestMapperService.prototype.getWeeklyAbsenceDays = function (rrule, sDate) {
        var interval = rrule.interval, count = rrule.count, until = rrule.until, byWeekDay = rrule.byWeekDay;
        var hasCount = _.isFinite(count) && count > 0;
        var absenceRanges = [];
        var _a = this.evaluateAbsenceRange(sDate, interval, count, until), startDate = _a.startDate, endDate = _a.endDate;
        var weekLength = 7;
        var daysRanges = this.createDays(startDate, endDate);
        var _loop_1 = function (index, day, iteration, week, length_2) {
            var weekNumber = Math.max(Math.ceil(day / weekLength), 1);
            if (weekNumber % interval !== 0)
                return out_iteration_1 = iteration, out_week_1 = week, "continue";
            var dayRange = daysRanges[index];
            var startIndex = dayRange.startDate.getDay();
            var hasThisDayInConfig = _.some(byWeekDay, function (d) { return d.day === startIndex; });
            if (hasThisDayInConfig) {
                absenceRanges.push(dayRange);
            }
            if (week !== weekNumber) {
                week = weekNumber;
                iteration++;
            }
            if (hasCount && iteration === count && (day / weekNumber) === weekLength)
                return out_iteration_1 = iteration, out_week_1 = week, "break";
            out_iteration_1 = iteration;
            out_week_1 = week;
        };
        var out_iteration_1, out_week_1;
        for (var index = 0, day = 1, iteration = 0, week = 0, length_2 = daysRanges.length; index < length_2; index++, day++) {
            var state_1 = _loop_1(index, day, iteration, week, length_2);
            iteration = out_iteration_1;
            week = out_week_1;
            if (state_1 === "break")
                break;
        }
        return absenceRanges;
    };
    LMRequestMapperService.prototype.evaluateAbsenceRange = function (sDate, interval, count, until) {
        var hasUntil = _.isObjectLike(until);
        var hasCount = _.isFinite(count) && count > 0;
        var startDate = dateTimeUtils.copyDate(sDate);
        var endDate = null;
        switch (true) {
            case hasUntil:
                endDate = until.toLocalDate();
                break;
            case hasCount:
                var weekMs = this.dayMs * 7;
                var minTimeRange = weekMs * interval * count;
                endDate = new Date(startDate.getTime() + minTimeRange);
                break;
            default:
                endDate = new Date(new Date().getFullYear(), 11, 31, 23, 59, 59, 999);
        }
        return new DateRange(startDate, endDate);
    };
    LMRequestMapperService.prototype.excludeExceptions = function (absenceDays, exceptionsDays) {
        var _this = this;
        if (_.size(_.keys(exceptionsDays)) === 0) {
            return _.reduce(absenceDays, function (accum, exc) {
                var dateMs = dateTimeUtils.copyDate(exc.startDate).setHours(0, 0, 0, 0);
                (accum[dateMs] = accum[dateMs] || []).push(exc);
                return accum;
            }, {});
        }
        var clearedAbsenceDays = _.reduce(absenceDays, function (accum, absenceDay) {
            var _a;
            var dateInMs = dateTimeUtils.copyDate(absenceDay.startDate).setHours(0, 0, 0, 0);
            var filteredExceptions = exceptionsDays[dateInMs];
            if (_.size(filteredExceptions) > 0) {
                var clearedAbsenceDay = _this.clearAbsenceDay(absenceDay, filteredExceptions);
                if (_.keys(clearedAbsenceDay).length > 0) {
                    var dayDateInMs_1 = _.head(_.keys(clearedAbsenceDay));
                    (_a = (accum[dayDateInMs_1] = accum[dayDateInMs_1] || [])).push.apply(_a, clearedAbsenceDay[dayDateInMs_1]);
                }
                return accum;
            }
            var dayDateInMs = dateTimeUtils.copyDate(absenceDay.startDate).setHours(0, 0, 0, 0);
            (accum[dayDateInMs] = accum[dayDateInMs] || []).push(absenceDay);
            return accum;
        }, {});
        return clearedAbsenceDays;
    };
    LMRequestMapperService.prototype.clearAbsenceDay = function (absenceDay, exceptionsForDay) {
        var _this = this;
        var exceptionsLength = _.size(exceptionsForDay);
        return _.reduce(exceptionsForDay, function (accum, excepDay, i) {
            var _a;
            var absences = [];
            var nextExceptionStart = _.get(exceptionsForDay[i + 1], 'startDate');
            if (i === 0 && dateTimeUtils.getTime(absenceDay.startDate) !== dateTimeUtils.getTime(excepDay.startDate)) {
                absences.push(_this.makeRange(absenceDay.startDate, excepDay.startDate));
            }
            if (_.isDate(nextExceptionStart)) {
                var startAbsence = dateTimeUtils.copyDate(excepDay.endDate);
                var endAbsence = dateTimeUtils.copyDate(nextExceptionStart);
                absences.push(_this.makeRange(startAbsence, endAbsence));
            }
            if (i === exceptionsLength - 1 && dateTimeUtils.getTime(excepDay.endDate) !== dateTimeUtils.getTime(absenceDay.endDate)) {
                absences.push(_this.makeRange(excepDay.endDate, absenceDay.endDate));
            }
            if (_.size(absences) > 0) {
                var dayDateInMs = dateTimeUtils.copyDate(absenceDay.startDate).setHours(0, 0, 0, 0);
                (_a = (accum[dayDateInMs] = accum[dayDateInMs] || [])).push.apply(_a, absences);
            }
            return accum;
        }, {});
    };
    LMRequestMapperService.prototype.createDaysFromRanges = function (ranges) {
        var _this = this;
        return _.reduce(ranges, function (accum, r) {
            accum.push.apply(accum, _this.createDays(r.startDate, r.endDate));
            return accum;
        }, []);
    };
    LMRequestMapperService.prototype.createExceptionDays = function (exceptionsRanges) {
        var _this = this;
        return _.reduce(exceptionsRanges, function (accum, r) {
            var groupedExceptions = _this.createGroupedDays(r.startDate, r.endDate);
            _.forEach(groupedExceptions, function (e, dateInMs) {
                (accum[dateInMs] = accum[dateInMs] || []).push(e);
            });
            return accum;
        }, {});
    };
    LMRequestMapperService.prototype.createDays = function (sDate, eDate) {
        var groupedDays = this.createGroupedDays(sDate, eDate);
        return _.values(groupedDays);
    };
    LMRequestMapperService.prototype.createGroupedDays = function (sDate, eDate) {
        var groupedDays = {};
        for (var index = 1, currentDay = dateTimeUtils.copyDate(sDate); currentDay < eDate; index++) {
            if (index > 1) {
                currentDay = new Date(currentDay.setDate(currentDay.getDate() + 1));
            }
            var day = this.getMinMaxOfDay(currentDay);
            var hasOverflow = day.endDate > eDate;
            if (day.startDate < sDate) {
                day.startDate = dateTimeUtils.copyDate(sDate);
            }
            if (hasOverflow) {
                day.endDate = dateTimeUtils.copyDate(eDate);
            }
            var endDateCopy = dateTimeUtils.copyDate(day.endDate);
            if (endDateCopy.setHours(23, 59, 0, 0) === dateTimeUtils.getTime(day.endDate)) {
                day.endDate = new Date(endDateCopy.setHours(23, 59, 59, 999));
            }
            if (dateTimeUtils.getTime(day.startDate) !== dateTimeUtils.getTime(day.endDate)) {
                var dateInMs = dateTimeUtils.copyDate(day.startDate).setHours(0, 0, 0, 0);
                groupedDays[dateInMs] = day;
            }
            if (hasOverflow)
                break;
        }
        return groupedDays;
    };
    LMRequestMapperService.prototype.getLoaDates = function (req) {
        var sDate = req.estStartDate;
        var eDate = req.estEndDate;
        if (_.isDate(req.actStartDate)) {
            sDate = req.actStartDate;
        }
        if (_.isDate(req.actEndDate)) {
            eDate = req.actEndDate;
        }
        return new DateRange(sDate, eDate);
    };
    LMRequestMapperService.prototype.getExceptions = function (req) {
        var exceptionDates = req.exceptionDates;
        return this.sortRange(exceptionDates);
    };
    LMRequestMapperService.prototype.getCustomDates = function (req) {
        var customDates = req.loaRepeat.customDates;
        return this.sortRange(customDates);
    };
    LMRequestMapperService.prototype.parseRule = function (rrule, firstDayOfWeek) {
        return parseRule({
            recurrenceRule: rrule,
            weekStart: firstDayOfWeek
        });
    };
    LMRequestMapperService.prototype.sortRange = function (r) {
        if (_.isArray(r) && _.size(r) > 0) {
            return _.sortBy(r, function (range) { return dateTimeUtils.getTime(range.startDate); });
        }
        return [];
    };
    LMRequestMapperService.prototype.getMinMaxOfDay = function (date) {
        var d = moment(dateTimeUtils.copyDate(date));
        return new DateRange(d.startOf('day').toDate(), d.endOf('day').toDate());
    };
    LMRequestMapperService.prototype.makeRange = function (s, e) {
        return new DateRange(dateTimeUtils.copyDate(s), dateTimeUtils.copyDate(e));
    };
    return LMRequestMapperService;
}());
export { LMRequestMapperService };
