import { BudgetDefinition, IBudgetDefinition } from '../../../organization/models/index';

export interface IBudgetHistory {
  budgetDefinition: IBudgetDefinition;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
}

export class BudgetHistory {
  public static mock: IBudgetHistory[] = [
    {
      budgetDefinition: {
        id: 8,
        orgLevelId: 200023,
        startDate: new Date(),
        endDate: new Date()
      },
      lastUpdatedBy: 'ttt',
      lastUpdatedDate: new Date()
    },
    {
      budgetDefinition: {
        id: 8,
        orgLevelId: 200023,
        startDate: new Date(),
        endDate: new Date()
      },
      lastUpdatedBy: 'ttt',
      lastUpdatedDate: new Date()
    }
  ];
  public budgetDefinition: BudgetDefinition;
  public lastUpdatedBy: string;
  public lastUpdatedDate: Date;
}
