export interface IDepartment {
  id: number;
  orgLevelId: number;
  name: string;
  organizationId?: number;
}

export class Department {
  public id: number;
  public orgLevelId: number;
  public name: string;
  public parentOrganizationId?: number;
}
