import { ApplicationService } from './../../portal/services/application/application.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { ReportsApiService } from './reports-api.service';
import { LookupType, PayCycle, PayPolicy, LookupEntity, StyledUserApplication, Position } from '../../organization/models/index';
import { OrgLevel } from '../../state-model/models/index';
import { unsubscribeInService } from '../../core/decorators/index';
import { Assert } from '../../framework/index';
import { FileBlobResponse } from '../../core/models/api/file-blob-response';
import * as _ from 'lodash';
import { SessionService } from '../../core/services/index';
import { dateTimeUtils } from './../../common/utils/dateTimeUtils';
import { mutableSelect } from '../../core/decorators/index';
import * as moment from 'moment';
import { OrgLevelWatchService, LookupApiService } from '../../organization/services/index';

import {
  ReportDefinition, IReportDefinition,
  ReportParameter, IReportParameter,
  ReportGroup, ReportExportType,
  GenerateReportRequest, GenerateReportParameter, ReportStaffingSetting, ReportAttendanceSetting, GetBIPAReportDefinition
} from '../models/index';
import { IDestroyService } from '../../core/models/index';
import { AuthApiService } from '../../core/services/index';
import { WindowRef } from '../../core/services/window/window-ref.model';
import { reportsConfig } from '../reports.config';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';

@Injectable()
export class ReportsManagementService implements IDestroyService {

  public onReportsLoaded$: ReplaySubject<ReportGroup[]>;
  public onReportsLoadStarted$: ReplaySubject<any>;
  public onReportConfigSettingSave$: ReplaySubject<boolean>;
  public onReportGenerated$: ReplaySubject<FileBlobResponse>;
  public onReportGenerationStarted$: ReplaySubject<ReportDefinition>;
  public onErrorOccured$: ReplaySubject<any>;
  public selectedOrgLevel: OrgLevel;
  public onReportStaffingSettingsLoaded$: ReplaySubject<ReportStaffingSetting>;
  public onReportAttendanceSettingsLoaded$: ReplaySubject<ReportAttendanceSetting>;
  public onReportDailyStaffingPostLoadeded$: ReplaySubject<GetBIPAReportDefinition>;
  public positions: Position[];
  public isZeroHrsSelected$: Subject<boolean>;
  public isRNHrsEntered : boolean = false;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  private styledAppDictionary: StringMap<StyledUserApplication>;

  private readonly reportParameterAllValuesMarker: string = 'All';

  constructor(private reportsApiService: ReportsApiService, private sessionService: SessionService,
    private authService: AuthApiService, private winRef: WindowRef,
    private applicationService: ApplicationService,
    private lookupApiService: LookupApiService) {
    this.onReportsLoaded$ = new ReplaySubject(1);
    this.onReportConfigSettingSave$ = new ReplaySubject(1);
    this.onReportsLoadStarted$ = new ReplaySubject(1);
    this.onReportGenerated$ = new ReplaySubject(1);
    this.onReportGenerationStarted$ = new ReplaySubject(1);
    this.onErrorOccured$ = new ReplaySubject(1);
    this.isZeroHrsSelected$ = new Subject();
    this.onReportStaffingSettingsLoaded$ = new ReplaySubject(1);
    this.onReportAttendanceSettingsLoaded$ = new ReplaySubject(1);
    this.onReportDailyStaffingPostLoadeded$ = new ReplaySubject(1);
    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) =>
        !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id)
      .subscribe((o: OrgLevel) => {
        if (o.id) {
          this.selectedOrgLevel = o;
          this.loadData(this.selectedOrgLevel);
        }
      });

    this.applicationService.getStyledApplications().then((value: StyledUserApplication[]) => {
      this.styledAppDictionary = _.mapKeys(value, (app: StyledUserApplication) => app.title);
    });
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public onReportsLoaded(reports: ReportGroup[]): void {
    this.onReportsLoaded$.next(reports);
  }

  public onReportConfigSettingSave(isSave: boolean): void {
    this.onReportConfigSettingSave$.next(isSave);
  }

  public onReportsStaffingSettingsLoaded(reportStaffingSetting: ReportStaffingSetting): void {
    this.onReportStaffingSettingsLoaded$.next(reportStaffingSetting);
  }
  public onReportAttendanceSettingsLoaded(reportAttendanceSetting: ReportAttendanceSetting): void {
    this.onReportAttendanceSettingsLoaded$.next(reportAttendanceSetting);
  }

  public onReportDailyStaffingPostLoaded(reportDailyStaffingPost: GetBIPAReportDefinition): void {
    this.onReportDailyStaffingPostLoadeded$.next(reportDailyStaffingPost);
  }


  public selectReport(report: ReportDefinition): ReportDefinition {
    Assert.isNotNull(report, 'report');
    let preparedReport: ReportDefinition = Object.assign(report, {});
    preparedReport.parameters = _.map(report.parameters, (parameter: ReportParameter): ReportParameter => Object.assign(parameter, {}));
    preparedReport.exportType = ReportExportType.Excel;
    this.prepareParameters(report.parameters);
    return preparedReport;
  }

  public generateReport(report: ReportDefinition): void {
    Assert.isNotNull(report, 'report');
    this.onReportsGenerationStarted(report);
    let generateReportRequest: GenerateReportRequest = this.createGenerateReportRequest(report);
    this.reportsApiService.generateReport(generateReportRequest).then((data: FileBlobResponse): void => {
      this.onReportGenerated$.next(data);
    }).catch((error: any): void => {
      this.onErrorOccured$.next(error);
    });
  }

  public refreshReportsList(): void {
    this.loadData(this.selectedOrgLevel);
  }

  public previewReport(report: ReportDefinition): void {
    let request = this.createGenerateReportRequest(report);
    let url = `${environment.API}/${reportsConfig.api.root}/${reportsConfig.api.template}?reportRequest=${encodeURIComponent(JSON.stringify(request))}&token=${this.authService.getToken()}`;
    this.winRef.nativeWindow.open(url, '_blank');
  }

  private loadData(orgLevel: OrgLevel): void {
    Assert.isNotNull(orgLevel, 'orgLevel');

    this.onReportsLoadStarted();
    this.reportsApiService.getReportsList(orgLevel.id).then((reports: ReportGroup[]): void => {
      reports = this.postProcess(reports);
      this.onReportsLoaded(reports);
    }).catch((error: any): void => {
      this.onErrorOccured$.next(error);
    });
  }

  public loadStaffingSettingsData(forcedLoad: boolean): void {
    this.reportsApiService.getStaffingSettings(this.selectedOrgLevel.id, this.selectedOrgLevel.relatedItemId, forcedLoad).then((reportStaffingSetting: ReportStaffingSetting): void => {
      this.onReportsStaffingSettingsLoaded(reportStaffingSetting);
    }).catch((error: any): void => {
      this.onErrorOccured$.next(error);
    });
  }
  public loadAttendanceSettingsData(forcedLoad: boolean): void {
    this.reportsApiService.getAttendanceSettings(this.selectedOrgLevel.id, this.selectedOrgLevel.relatedItemId, forcedLoad).then((reportAttendanceSetting: ReportAttendanceSetting): void => {
      this.onReportAttendanceSettingsLoaded(reportAttendanceSetting);
    }).catch((error: any): void => {
      this.onErrorOccured$.next(error);
    });
  }

  public saveStaffingSettingsData(reportStaffingSetting: ReportStaffingSetting): void {
    this.onReportConfigSettingSave(true);
    this.reportsApiService.saveStaffingSettings(this.selectedOrgLevel.id, this.selectedOrgLevel.relatedItemId, reportStaffingSetting).then(() => {
      this.onReportConfigSettingSave(false);
    }).catch((error: any): void => {
      this.onErrorOccured$.next(error);
    });
  }

  public saveAttendanceSettingsData(reportAttendanceSetting: ReportAttendanceSetting): void {
    this.onReportConfigSettingSave(true);
    this.reportsApiService.saveAttendanceSettings(this.selectedOrgLevel.id, this.selectedOrgLevel.relatedItemId, reportAttendanceSetting).then(() => {
      this.onReportConfigSettingSave(false);
    }).catch((error: any): void => {
      this.onErrorOccured$.next(error);
    });
  }

  private postProcess(reports: ReportGroup[]): ReportGroup[] {
    _.forEach(reports, (report: ReportGroup) => {
      const app: StyledUserApplication = this.styledAppDictionary[report.name];
      if (app) {
        report.icon = app.icon;
        report.appId = app.id;
      }  else if (report.name.toLowerCase() === 'employee') {
        report.icon = 'fa-user';
      }  else {
        report.icon = 'fa-chart-line';
      }
    });
    let isHrms = reports.filter((report: ReportGroup) => report.appId === 6).length;
    if(!isHrms){
      reports.map( report => {
        if (report.name.toLowerCase() === 'reports') {
          report.icon = 'fa-user';
          report.name = 'Employee';
        }
      })
    }
    return _.sortBy(reports, (r: ReportGroup) => r.appId);
  }

  private onReportsLoadStarted(): void {
    this.onReportsLoadStarted$.next(null);
  }

  private onReportsGenerationStarted(report: ReportDefinition): void {
    this.onReportGenerationStarted$.next(report);
  }

  private prepareParameters(parameters: ReportParameter[]): void {
    let loggedUser = this.sessionService.getUser();
    _.forEach(parameters, (parameter: ReportParameter): void => {
      if (parameter.name && parameter.name.toLocaleLowerCase() === 'org_level_id') {
        parameter.value = this.selectedOrgLevel.id;
      } else if (parameter.name && parameter.name.toLocaleLowerCase() === 'org_level_name') {
        parameter.value = this.selectedOrgLevel.name;
      } else if (parameter.name && parameter.name.toLocaleLowerCase() === 'user_id') {
        parameter.value = loggedUser.id;
      }
      if (!parameter.value && !parameters.find(x=> x.name==("Show_dates")) && !parameters.find(x=> x.name==("Zero_hrs")) && !parameters.find(x=> x.name==("Total_hrs"))) {
        parameter.value = parameter.defaultValue;
      }
    });
  }

  private createGenerateReportRequest(report: ReportDefinition): GenerateReportRequest {
    let request: GenerateReportRequest = new GenerateReportRequest();
    request.exportType = report.exportType;
    request.parameters = this.createGenerateReportParameters(report.parameters),
      request.name = report.name;
    request.pageLayout = report.pageLayout;
    request.folderName = report.folder;

    return request;
  }

  private createGenerateReportParameters(parameters: ReportParameter[]): GenerateReportParameter[] {
    let preapredParameters: GenerateReportParameter[] =
      _.map(parameters, (parameter: ReportParameter): GenerateReportParameter => this.createGenerateReportParameter(parameter));

    let payCycleParameter: GenerateReportParameter =
      _.find(preapredParameters, (parameter: GenerateReportParameter): boolean => parameter.name === 'pay_cycle');

    if (payCycleParameter) {
      let replaceWithParameters: GenerateReportParameter[] = this.preparePaycycleParameter(payCycleParameter);
      let indexOfPayCycleParameter: number = preapredParameters.indexOf(payCycleParameter);
      preapredParameters.splice(indexOfPayCycleParameter, 1);
      _.forEach(replaceWithParameters, (parameterToAdd: GenerateReportParameter): void => {
        preapredParameters.push(parameterToAdd);
      });
    }
    return preapredParameters;
  }

  private createGenerateReportParameter(parameter: ReportParameter): GenerateReportParameter {
    let reportParameter: GenerateReportParameter = new GenerateReportParameter();
    reportParameter.name = parameter.name;
    reportParameter.value = this.processReportParameterValue(parameter);
    reportParameter.parameterType = parameter.dataType.name;
    return reportParameter;
  }

  private preparePaycycleParameter(parameter: GenerateReportParameter): GenerateReportParameter[] {
    Assert.isNotNull(parameter, 'parameter');
    let parameters: GenerateReportParameter[] = [];
    if (parameter.value instanceof PayCycle && !!parameter.value) {
      let generateReportParameter: GenerateReportParameter = new GenerateReportParameter();
      generateReportParameter.name = 'start_date';
      generateReportParameter.value = dateTimeUtils.convertToDtoString(parameter.value.startDate);

      parameters.push(generateReportParameter);

      generateReportParameter = new GenerateReportParameter();
      generateReportParameter.name = 'end_date';
      generateReportParameter.value = dateTimeUtils.convertToDtoString(parameter.value.endDate);

      parameters.push(generateReportParameter);
    }
    return parameters;
  }

  private processReportParameterValue(reportParameter: ReportParameter): any {
    let result: any = undefined;

    if ((reportParameter.value !== undefined && reportParameter.value !== null)) {
      if (reportParameter.dataType.isMultipleValues && reportParameter.value !== this.reportParameterAllValuesMarker) {
        result = _.join(_.map(reportParameter.value, (item: any) => this.convertReportParameterValue(item)), ',');
      } else {
        result = this.convertReportParameterValue(reportParameter.value);
      }
    } else {
      if (reportParameter.dataType.isLookup) {
        if (!!reportParameter.defaultValue) {
          result = reportParameter.defaultValue;
        } else {
          result = this.reportParameterAllValuesMarker;
        }
      } else if (reportParameter.dataType.name === 'bit') {
        result = false;
      }
    }
    return result;
  }

  private convertReportParameterValue(value: any): any {
    if (value instanceof Date) {
      return dateTimeUtils.convertToDtoString(value);
    } else if (moment.isMoment(value)) {
      return dateTimeUtils.convertToDtoString(value.toDate());
    } else if (value instanceof LookupEntity) {
      return !!value ? value.id : undefined;
    } else if (value instanceof PayPolicy) {
      return !!value ? value.name : undefined;
    } else {
      return value;
    }
  }

  public loadDailyStaffPostingData(forcedLoad: boolean): void {
    this.reportsApiService.getDailyStaffingSettings(this.selectedOrgLevel.id, forcedLoad).then((dailyStaffingSetting: GetBIPAReportDefinition): void => {
      this.onReportDailyStaffingPostLoaded(dailyStaffingSetting);
    }).catch((error: any): void => {
      this.onErrorOccured$.next(error);
    });
  }
}
