import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { GridModule, PDFModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MomentModule } from 'angular2-moment';

import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { ComponentsLibraryModule } from '../../components-library/index';
import { NgxPopperModule } from 'ngx-popper';

import { services } from './services/index';
import { components, entryComponents } from './components/index';
import { directives } from './directives/index';
import { NoSpecialCharsDirective } from './directives/no-special-chars.directive';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    HttpClientModule,
    CommonAppModule,
    CommonModule,
    OrganizationModule,
    MomentModule,
    GridModule,
    PDFModule,
    ExcelModule,
    LayoutModule,
    NgxPopperModule,
    ComponentsLibraryModule,
    ReactiveFormsModule,
  ],
  entryComponents: [...entryComponents],
  declarations: [
    ...components,
    ...directives,
    NoSpecialCharsDirective
  ],
  providers: [...services],
  exports: [...components],
})
export class BenefitsModule {}
