import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { unsubscribe, destroyService, mutableSelect } from './../../../../core/decorators/index';
import { ExceptionConsoleRollupManagementService } from './../../../services/exception-console/exception-console-rollup-management.service';
import { KendoGridStateHelper } from './../../../../common/models/index';
import { IColumnSettings, StateResetTypes } from '../../../../core/models/index';
import { RangeDates } from '../../../../common/models/range-dates';
import { StateManagementService, ComponentStateStorageService, ExceptionConsoleNavigationService } from '../../../../common/services/index';
import { OrgLevelWatchService } from '../../../../organization/services/org-level/org-level-watch.service';
import { OrgLevelType } from '../../../../state-model/models/org-level/org-level-type';
import { PayCycleHelperService } from '../../../../organization/services/index';
var ExceptionConsoleRollupComponent = /** @class */ (function () {
    function ExceptionConsoleRollupComponent(stateService, storageService, router, activatedRoute, orgLevelService, payCycleHelper, managementService) {
        this.stateService = stateService;
        this.storageService = storageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.orgLevelService = orgLevelService;
        this.payCycleHelper = payCycleHelper;
        this.usePayCycle = false;
        this.oneMonthInSec = 60 * 60 * 24 * 31;
        this.resetBy = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.componentId = 'ExceptionConsoleRollupComponent';
        this.payCycleControlName = 'payCycle';
        this.dateRangeControlName = 'dateRange';
        this.managementService = managementService;
        this.gridState = new KendoGridStateHelper();
        this.state = {
            isLoading: false,
        };
    }
    ExceptionConsoleRollupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initSubscription = this.activatedRoute.data
            .combineLatest(this.stateService.onInit$, this.orgLevelService.orgLevelTreeLoaded$, this.orgLevel$)
            .subscribe(function (_a) {
            var data = _a[0], init = _a[1], orgtree = _a[2], orgLvl = _a[3];
            var routeData = data.exceptionConsoleData;
            var usePayCycle = routeData.usePayCycle;
            var payCycle = routeData.payCycle;
            var range = routeData.range;
            _this.selectedPayCycle = null;
            _this.setDefaultCycle = false;
            _this.isAboveOrganizationLevel = orgLvl.type !== OrgLevelType.organization;
            if (_this.isAboveOrganizationLevel) {
                usePayCycle = false;
            }
            if (!_this.isAboveOrganizationLevel && usePayCycle) {
                if (payCycle) {
                    _this.setDefaultCycle = false;
                    _this.selectedPayCycle = payCycle;
                    range = new RangeDates();
                    range.startDate = payCycle.startDate;
                    range.endDate = payCycle.endDate;
                }
                else {
                    _this.setDefaultCycle = true;
                }
            }
            _this.currentRange = range;
            if (_this.currentRange)
                _this.managementService.setDateRange(_this.currentRange);
            _this.usePayCycle = usePayCycle;
        });
        this.loadStateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.globalState = state;
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.loadedSubscription = this.managementService.onLoaded$
            .subscribe(function (value) {
            var col = _.first(_this.globalState.columns);
            if (col) {
                col.description = _this.managementService.getOrgLevelTitle();
            }
        });
        this.stateService.init(this.componentId);
    };
    ExceptionConsoleRollupComponent.prototype.ngOnDestroy = function () {
        //AOT
    };
    ExceptionConsoleRollupComponent.prototype.onSwitchRangeMode = function (mode) {
        this.usePayCycle = mode;
        var navigation = new ExceptionConsoleNavigationService(this.router, this.activatedRoute);
        if (!this.currentRange) {
            navigation.navigateToExceptionConsole(this.managementService.currentOrgLevel.id, this.usePayCycle);
        }
        else {
            navigation.navigateToExceptionConsoleDates(this.managementService.currentOrgLevel.id, this.currentRange.startDate, this.currentRange.endDate, this.usePayCycle);
        }
    };
    ExceptionConsoleRollupComponent.prototype.onPayCycleSelected = function (payCycle) {
        var range = new RangeDates();
        range.startDate = payCycle.startDate;
        range.endDate = payCycle.endDate;
        this.currentRange = range;
        this.managementService.navigateToDateRange(range, true);
    };
    ExceptionConsoleRollupComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        var range = new RangeDates();
        range.startDate = startDate;
        range.endDate = endDate;
        this.currentRange = range;
        this.managementService.navigateToDateRange(range, false);
    };
    ExceptionConsoleRollupComponent.prototype.onChangeColumn = function (event, column) {
        this.managementService.onStateChanged(this.globalState);
    };
    ExceptionConsoleRollupComponent.prototype.selectAll = function (event) {
        _.forEach(this.globalState.allColumns, function (column) {
            if (!column.readonly) {
                column.displayed = true;
            }
        });
        this.managementService.onStateChanged(this.globalState);
    };
    ExceptionConsoleRollupComponent.prototype.clearAll = function (event) {
        _.forEach(this.globalState.allColumns, function (column) {
            if (!column.readonly) {
                column.displayed = false;
            }
        });
        this.managementService.onStateChanged(this.globalState);
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", ExceptionConsoleRollupManagementService)
    ], ExceptionConsoleRollupComponent.prototype, "managementService", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ExceptionConsoleRollupComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleRollupComponent.prototype, "initSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleRollupComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleRollupComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleRollupComponent.prototype, "loadedSubscription", void 0);
    return ExceptionConsoleRollupComponent;
}());
export { ExceptionConsoleRollupComponent };
