import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor, dateTimeUtils } from '../../../common/utils/index';

import { appConfig, IApplicationConfig } from '../../../app.config';
import { DateRange, IDateRange } from '../../../core/models/index';
import { IDateRangeListItem, IDateRangeListOption } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-daterange-list',
  templateUrl: 'daterange-list.component.html',
  providers: [createValuAccessor(DateRangeListComponent)]

})
export class DateRangeListComponent implements ControlValueAccessor {
  @Input()
  public set listRanges(ranges: IDateRangeListItem[]) {
    if (_.isArray(ranges)) {
      this.prepareOptions(ranges);
    }
  }
  @Input()
  public format = 'MM/DD/YYYY';
  @Input()
  public placeholder: string = '';
  @Input()
  public readonly = false;
  @Input()
  public valueIconClass: string;
  @Output()
  public valueChanged = new EventEmitter<DateRange>();

  public appConfig: IApplicationConfig = appConfig;
  public options: IDateRangeListOption[];
  public ranges: IDateRangeListItem[];

  public get selectedRange(): IDateRangeListOption {
    return this.m_selectedValue;
  }

  public set selectedRange(v: IDateRangeListOption) {
    if (!_.isEqual(v, this.m_selectedValue)) {
      this.m_selectedValue = _.isNil(v) ? null : v;
      if (!this.readonly && !_.isNil(v)) {
        this.onChangeCallback(this.getItem(v));
      }
    }
  }

  private m_selectedValue: IDateRangeListOption = null;
  private separator = ' — ';
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  constructor() {}

  public writeValue(v: IDateRangeListItem): void {
    this.selectedRange = _.isNil(v) ? null : this.getOption(v);
  }

  public registerOnChange(fn: () => void): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  private prepareOptions(ranges: IDateRangeListItem[]): void {
    this.ranges = ranges;
    this.options = _.map(this.ranges, r => this.getOption(r));
  }

  private getOption(v: IDateRangeListItem): IDateRangeListOption {
    return {
      id: v.id,
      name: `${moment(v.startDate).format(this.format)}${this.separator}${moment(v.endDate).format(this.format)}`
    };
  }

  private getItem(v: IDateRangeListOption): IDateRangeListItem {
    const item = _.find(this.ranges, r => r.id === v.id);
    if (_.isObjectLike(item)) {
      return item;
    }

    return null;
  }
}
