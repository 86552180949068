import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { AgencyMapping, MappingRequest } from '../../models/index';
import { AgenciesApiService } from './agencies-api.service';
import { AgenciesMapService } from './agencies-map.service';
var AgencyDetailsManagementService = /** @class */ (function () {
    function AgencyDetailsManagementService(api, mapService) {
        this.api = api;
        this.mapService = mapService;
        this.m_onAdd = new ReplaySubject();
        this.m_onAdded = new ReplaySubject();
        this.m_onAddError = new ReplaySubject();
        this.m_onRemove = new ReplaySubject();
        this.m_onRemoved = new ReplaySubject();
        this.m_onRemoveError = new ReplaySubject();
        this.m_onUpdate = new ReplaySubject();
        this.m_onUpdated = new ReplaySubject();
        this.m_onUpdateError = new ReplaySubject();
    }
    Object.defineProperty(AgencyDetailsManagementService.prototype, "onRemove$", {
        get: function () {
            return this.m_onRemove;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsManagementService.prototype, "onRemoved$", {
        get: function () {
            return this.m_onRemoved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsManagementService.prototype, "onRemoveError$", {
        get: function () {
            return this.m_onRemoveError;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsManagementService.prototype, "onAdd$", {
        get: function () {
            return this.m_onAdd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsManagementService.prototype, "onAdded$", {
        get: function () {
            return this.m_onAdded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsManagementService.prototype, "onAddError$", {
        get: function () {
            return this.m_onAddError;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsManagementService.prototype, "onUpdate$", {
        get: function () {
            return this.m_onUpdate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsManagementService.prototype, "onUpdated$", {
        get: function () {
            return this.m_onUpdated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsManagementService.prototype, "onUpdateError$", {
        get: function () {
            return this.m_onUpdateError;
        },
        enumerable: true,
        configurable: true
    });
    AgencyDetailsManagementService.prototype.saveAgencyInfo = function (orglLevelId, mapping) {
        var _this = this;
        this.m_onUpdate.next(mapping);
        this.api.updateAgency(orglLevelId, mapping.agency).then(function () {
            _this.m_onUpdated.next(mapping);
        }).catch(function (result) {
            _this.m_onUpdateError.next(mapping);
        });
    };
    AgencyDetailsManagementService.prototype.cloneAgencyMapping = function (mapping) {
        return this.mapService.cloneAgencyMapping(mapping);
    };
    AgencyDetailsManagementService.prototype.removeOrganization = function (agencyMapping, organization) {
        var _this = this;
        var request = new MappingRequest();
        request.isRemove = true;
        request.mapping = agencyMapping;
        request.organization = organization;
        this.m_onRemove.next(request);
        this.api.unassignOrganizations(agencyMapping.agency, [organization]).then(function (any) {
            agencyMapping.organizations = _.without(agencyMapping.organizations, organization);
            _this.onRemoved$.next(request);
        }).catch(function (result) {
            _this.onRemoveError$.next(request);
        });
    };
    AgencyDetailsManagementService.prototype.addOrganization = function (agencyMapping, organization) {
        var _this = this;
        var request = new MappingRequest();
        request.mapping = agencyMapping;
        request.organization = organization;
        this.m_onRemove.next(request);
        this.api.assignOrganizations(agencyMapping.agency, [organization]).then(function (any) {
            agencyMapping.organizations.push(organization);
            _this.onAdded$.next(request);
        }).catch(function (result) {
            _this.onAddError$.next(request);
        });
    };
    return AgencyDetailsManagementService;
}());
export { AgencyDetailsManagementService };
