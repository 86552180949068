import { Component, OnInit } from '@angular/core';
import { ModalService, ConfirmOptions, ConfirmDialogComponent } from '../../../../../common/index';
import { OrgStructureCodeService } from '../../../../services/payroll/code-mapping/org-structure-code.service';
import { ItabDataModel, saveDataModel } from '../../../../services/payroll/code-mapping/org-structure-model';
import { unsubscribe, mutableSelect } from '../../../../../core/decorators/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'slx-org-structure-code',
  templateUrl: './org-structure-code.component.html',
  styleUrls: ['./org-structure-code.component.scss'],
})
export class OrgStructureCodeComponent implements OnInit {  
  public tabData  = {
    Organization: [],
    Departments: [],
    Positions: [],
    userActionsForCodeMapping: {
      "canEditCodeMapping": false,
      "canViewCodeMapping": false
     }
  };  
  public saveRequestBody : saveDataModel = {
    Organization: [],
    Departments: [],
    Positions: []
  };
  public tempRequestBody : saveDataModel = {
    Organization: [],
    Departments: [],
    Positions: []
  };
  public isGlobalSaveEnable: boolean = false;
  public parentLevelName: string = '';
  public isReadOnly: boolean = false;
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  public orgLevelId: number;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  constructor(private modalService: ModalService, public orgStructureCodeService: OrgStructureCodeService) { }

  ngOnInit() {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
    });
    this.getOrgStructureCodes('select');
    this.orgStructureCodeService.getCodeMappingData().subscribe((res : ItabDataModel) => {
      this.tabData = res;
    })
  }
  getOrgStructureCodes(type: string) {
    this.orgStructureCodeService
      .getOrgStructureCodes(type, this.orgLevelId)
      .then((res) => {
        if (res && res['data']) {
          this.tabData.Organization = res['data']['organization'] || [];
          this.tabData.Departments = res['data']['departments'] || [];
          this.tabData.Positions = res['data']['positions'] || [];
          this.isReadOnly = res['data']['userActionsForCodeMapping']['canEditCodeMapping'] ? true : false;
        }
      })
      .catch((err) => console.error(err));
  }

  saveDataHandler(data) {
    const { formGroup, rowIndex, tabName } = data;
    let updatedVal = {};
    if (data && this.tabData[tabName].length) {
      if (tabName === 'Positions') {
        this.tabData[tabName][rowIndex]['shortCode'] = formGroup.value.ShortCode;
        this.tabData[tabName][rowIndex]['payrollDescription'] = formGroup.value.PayrollDescription;
        this.tabData[tabName][rowIndex]['isSaved'] = false;
      }else {
        this.tabData[tabName][rowIndex]['shortCode'] = formGroup.value.ShortCode;
      }
      updatedVal = this.tabData[tabName][rowIndex];
      updatedVal['isActive'] = true;
    
      const checkDuplicate = _.findIndex(this.tempRequestBody[tabName], (el: any) => el.rowIndex === rowIndex);
      if (checkDuplicate !== -1 ) {
        this.tempRequestBody[tabName].splice(checkDuplicate, 1, {...updatedVal,'rowIndex': rowIndex});
        this.saveRequestBody[tabName].splice(checkDuplicate, 1, updatedVal);
      }else{
        this.tempRequestBody[tabName].push({...updatedVal,'rowIndex': rowIndex});
        this.saveRequestBody[tabName].push(updatedVal);
      }
    }
  }
  
  public saveShortCode() {
    if(this.saveRequestBody.Departments.length === 0 && this.saveRequestBody.Organization.length === 0 && this.saveRequestBody.Positions.length === 0)
     return;
     let options: ConfirmOptions = new ConfirmOptions();
      options.height = 230;
      options.showCancel = true;
      options.showOK = true;
      options.buttonCanceltext = 'Discard';
      options.buttonOKtext = 'Yes';
      ConfirmDialogComponent.openDialog(
        'Confirmation',
        'The changes you are about to make are permanent and can’t be updated without reaching out to Smartlinx Support.',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.saveData(this.saveRequestBody);
          }
        },
        options
      );    
  }

  public saveData(reqestBody : any) {
    this.orgStructureCodeService.saveDataHandler(reqestBody).then(res=>{
      if(res && res['data']){
        this.getOrgStructureCodes('select');
        this.saveRequestBody = {
          Organization: [],
          Departments: [],
          Positions: []
        };
        this.tempRequestBody = {
          Organization: [],
          Departments: [],
          Positions: []
        }
      }
    })
  }
}