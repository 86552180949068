import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { BenefitDetailsState } from './benefit-details-state';
var BenefitDetailsProviderEntity = /** @class */ (function (_super) {
    tslib_1.__extends(BenefitDetailsProviderEntity, _super);
    function BenefitDetailsProviderEntity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectedLineId = null;
        _this.requireUpdate = true;
        _this.stored = null;
        _this.draft = false;
        return _this;
    }
    Object.defineProperty(BenefitDetailsProviderEntity.prototype, "id", {
        get: function () {
            return this.current.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsProviderEntity.prototype, "providerName", {
        get: function () {
            return this.current.shortName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsProviderEntity.prototype, "storedProviderName", {
        get: function () {
            return this.current.storedShortName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsProviderEntity.prototype, "isDraft", {
        get: function () {
            return this.draft;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsProviderEntity.prototype, "isStored", {
        get: function () {
            return _.isObjectLike(this.stored);
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsProviderEntity.prototype.markAsDraft = function () {
        this.draft = true;
    };
    BenefitDetailsProviderEntity.prototype.storeEntity = function () {
        this.stored = _.cloneDeep(this.current);
        return this;
    };
    BenefitDetailsProviderEntity.prototype.dropStored = function () {
        this.stored = null;
    };
    BenefitDetailsProviderEntity.prototype.restoreEntity = function () {
        this.current = _.cloneDeep(this.stored);
        this.stored = null;
        return this;
    };
    return BenefitDetailsProviderEntity;
}(BenefitDetailsState));
export { BenefitDetailsProviderEntity };
