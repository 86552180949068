import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-static-dropdown-list',
  templateUrl: 'static-dropdown-list.component.html',
})
export class StaticDropdownListComponent {
  @Input()
  public set options(items: any[]) {
    this.items = items;
  }

  @Input()
  public valueField: string;

  @Input()
  public titleField: string;

  @Input()
  public iconField: string;

  @Input()
  public readonly: boolean;

  @Input()
  public placeholder: string;

  @Input()
  public controlIcon: string;

  @Output()
  public selectItem: EventEmitter<StringMap<any>>;

  public get arrowIcon(): string {
    return this.listIsOpen ? 'fa-angle-up' : 'fa-angle-down';
  }

  public get listClass(): string {
    return this.listIsOpen ? 'open' : 'hide';
  }

  public get disabledClass(): string {
    return this.readonly ? 'disabled' : '';
  }

  public items: any[];

  private listIsOpen: boolean;

  constructor() {
    this.valueField = 'id';
    this.titleField = 'name';
    this.iconField = 'icon';

    this.selectItem = new EventEmitter<StringMap<any>>();
  }

  public onClickLabel(): void {
    if (!this.readonly) {
      this.listIsOpen = !this.listIsOpen;
    }
  }

  public onClickItem(item: StringMap<any>): void {
    if (!this.readonly) {
      this.selectItem.emit(item);
      if (this.listIsOpen) {
        this.listIsOpen = false;
      }
    }
  }
}
