import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { process } from '@progress/kendo-data-query';
import { LookupService } from '../../../../organization/services/index';
import { LookupType } from '../../../../organization/models/index';
import { Organization } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../app.config';
import { ConfirmDialogComponent, ModalService } from '../../../../common/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { HolidaysApiService } from '../../../services/index';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper } from '../../../../common/models/index';
var HolidaysCopyComponent = /** @class */ (function () {
    function HolidaysCopyComponent(lookupService, holidaysApiService, modalService) {
        this.lookupService = lookupService;
        this.holidaysApiService = holidaysApiService;
        this.modalService = modalService;
        this.appConfig = appConfig;
        this.cancel = new EventEmitter();
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
    }
    Object.defineProperty(HolidaysCopyComponent.prototype, "holidays", {
        set: function (value) {
            this.m_holidays = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    HolidaysCopyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = { isLoading: false, allowCopy: false };
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
            _this.currentOrganizationId = orgLevel.relatedItemId;
            _this.updateCopyToOrg();
        });
        this.emptyOrganization = new Organization();
        this.state.isLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.organization }).then(function (value) {
            _this.organizations = value.items;
            _this.updateCopyToOrg();
            _this.checkCopyConditions();
            _this.state.isLoading = false;
        });
        this.selectionSubscription = this.selectionHelper.onSelectionChanged.subscribe(function () {
            if (_this.selectionHelper.selectionLength > 0) {
                _this.state.allowCopy = true;
            }
            else {
                _this.state.allowCopy = false;
            }
            _this.checkCopyConditions();
        });
        this.gridStateSubscription = this.gridState.onRefreshGrid.subscribe(function (state) {
            _this.refreshGrid();
        });
        this.selectionHelper.clearSelection();
        this.refreshGrid();
    };
    HolidaysCopyComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    Object.defineProperty(HolidaysCopyComponent.prototype, "needOrganization", {
        get: function () {
            return this.currentOrgLevel.type !== 'Organization';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysCopyComponent.prototype, "copyFromYear", {
        get: function () {
            return this.m_copyFromYear;
        },
        set: function (value) {
            var _this = this;
            this.m_copyFromYear = value;
            var currentYear = moment().year();
            var years = _.range(currentYear, currentYear + 5, 1);
            this.yearItems = [];
            if (currentYear !== value)
                this.yearItems.push({ id: value, name: value });
            _.forEach(years, function (year) {
                _this.yearItems.push({ id: year, name: year });
            });
            this.copyToYear = this.yearItems[0];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysCopyComponent.prototype, "copyToCenter", {
        get: function () {
            return this.m_copyToCenter;
        },
        set: function (value) {
            this.m_copyToCenter = value;
        },
        enumerable: true,
        configurable: true
    });
    HolidaysCopyComponent.prototype.onDoCopy = function () {
        var _this = this;
        ConfirmDialogComponent.openDialog('Warning', 'All items in the destination will be replaced with selected items', this.modalService, function (result) {
            if (result) {
                _this.state.isLoading = true;
                _this.holidaysApiService.copyHolidays(_this.getSelectedIds(), _this.getSelectedOrganizationId(), _this.copyToYear.id).then(function (value) {
                    _this.state.isLoading = false;
                    _.forEach(_this.holidays, function (item) {
                        item.isSelected = false;
                    });
                    _this.selectionHelper.clearSelection();
                    _this.cancel.emit(false);
                }).catch(function (reason) {
                    _this.state.isLoading = false;
                });
            }
        });
    };
    HolidaysCopyComponent.prototype.onDiscardCopy = function () {
        this.selectionHelper.clearSelection();
        this.cancel.emit(false);
    };
    HolidaysCopyComponent.prototype.onTargetDepartmentChanged = function () {
        var _this = this;
        this.holidaysApiService.getHolidays(this.getSelectedOrganizationId(), this.copyToYear.id)
            .then(function (records) {
            _.each(_this.m_holidays, function (item) {
                item.selectable = true;
            });
        });
        this.checkCopyConditions();
    };
    HolidaysCopyComponent.prototype.checkCopyConditions = function () {
        var allowCopy = true;
        if (this.selectionHelper.selectionLength === 0) {
            allowCopy = false;
        }
        if (!this.form.valid) {
            allowCopy = false;
        }
        if (this.getSelectedOrganizationId() === this.currentOrganizationId && this.copyToYear.id === this.copyFromYear) {
            allowCopy = false;
        }
        this.state.allowCopy = allowCopy;
    };
    HolidaysCopyComponent.prototype.getSelectedIds = function () {
        var selected = [];
        _.forEach(this.m_holidays, function (item) {
            if (item.isSelected)
                selected.push(item.id);
        });
        return selected;
    };
    HolidaysCopyComponent.prototype.getSelectedOrganizationId = function () {
        if (this.copyToCenter && this.copyToCenter.id)
            return this.copyToCenter.id;
        return this.currentOrganizationId;
    };
    HolidaysCopyComponent.prototype.updateCopyToOrg = function () {
        if (this.currentOrgLevel) {
            this.copyToCenter = _.find(this.organizations, { id: this.currentOrganizationId });
        }
    };
    HolidaysCopyComponent.prototype.refreshGrid = function () {
        if (!this.m_holidays) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_holidays, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], HolidaysCopyComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysCopyComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysCopyComponent.prototype, "selectionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysCopyComponent.prototype, "gridStateSubscription", void 0);
    return HolidaysCopyComponent;
}());
export { HolidaysCopyComponent };
