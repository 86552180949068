export interface IEmployeeRotationsUiState {
  currentRotationViewState: string;
  historicalViewState: string;
  editSettingsState: string;
  editShiftTemplateState: string;
  editRotationsState: string;
}

export const employeeRotationsUiState: IEmployeeRotationsUiState = {
  currentRotationViewState: 'currentRotationViewState',
  historicalViewState: 'historicalViewState',
  editSettingsState: 'editSettingsState',
  editShiftTemplateState: 'editShiftTemplateState',
  editRotationsState: 'editRotationsState'
};
