import * as _ from 'lodash';
import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';

@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-multiselect-filter',
  templateUrl: 'kendo-grid-multiselect-filter.component.html',
  styleUrls: ['kendo-grid-multiselect-filter.component.scss'],
})
export class KendoGridMultiselectFilterComponent extends KendoGridBasicFilterComponent {

  @Input()
  public showOperators: boolean;
  @Input()
  public column: ColumnComponent;
  @Input()
  public filter: CompositeFilterDescriptor;
  @Input()
  public operator: string;
  @Input()
  public items: any[];
  public defaultItem: any;

  @ViewChild('multiselect', {static: true})
  public multiselect: MultiSelectComponent;

  constructor(filterService: FilterService, localization: LocalizationService) {
    super(filterService, localization);
    this.operator = 'slxEqualsArray';
    this.showOperators = false;
    this.operators = [];
  }

  public getSizes() : any {
    if (!this.multiselect) return {};
    let w: number = this.multiselect.wrapper.nativeElement.clientWidth;
    return {'width' : w + 'px', 'max-width': this.multiselect.width.min };
  }

  public get selectedCount(): number {
    return this.multiselect ? this.multiselect.value.length : 0;
  }

}
