import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { appConfig } from '../../../app.config';
import { SidebarActions } from '../../actions/index';


@Injectable()
export class LeftSidebarManagementService {
  public leftSidebarMode$: ReplaySubject<boolean>;
  public isAlwaysShowMode: boolean;
  private sidebarActions: SidebarActions;

  constructor(sidebarActions: SidebarActions) {
    this.sidebarActions = sidebarActions;
    this.isAlwaysShowMode = false;
    this.leftSidebarMode$ = new ReplaySubject<boolean>(1);
  }

  public switchMode(isAlwaysShowMode: boolean): void {
    this.isAlwaysShowMode = isAlwaysShowMode;
    this.leftSidebarMode$.next(isAlwaysShowMode);
  }

  public updateState(isHidden: boolean, isMobile: boolean): void {
    this.sidebarActions.setLeftSidebarHidden(isHidden);
    if (isMobile) {
      this.sidebarActions.setLeftSidebar(false);
    }
  }
}
