import * as _ from 'lodash';
import { Component, OnInit,Input, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { AbstractControl, NgForm } from '@angular/forms';
import { OrgLevel } from '../../../../state-model/models';
import { Aca1095cRecord ,AcaMonthRecord,acaQuickPopupState} from '../../../models/aca-1095-c';
import { AcaOfferOfCoverage,AcaSafeHarbor,AcaReason,IAcaSafeHarbor,IAcaOfferOfCoverage,IAcaReason} from '../../../../organization/models/index';
import { Aca1095cManagementService } from '../../../services/aca-1095-c/aca-1095-c-management.service';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-aca-1095c-quick-confirm',
  templateUrl: 'aca-1095c-quick-confirm.component.html',
  styleUrls: ['aca-1095c-quick-confirm.component.scss']
})
export class QuickConfirm1095CPopup implements  OnInit {
  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }
 
  public expandedItem:Aca1095cRecord;
  public items:Aca1095cRecord[]; 
  public showChanges: boolean = false;
  public isQuickConfirmStarted: boolean = false;
  

  
  @Input()
  public isResetTab: boolean;

  @ViewChild('form', { static: false })
  public ngForm: NgForm;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};


  public get hasChanges(): boolean {
    return this.items && this.items.length > 0;    
  }
  constructor(private acaManagementService: Aca1095cManagementService) {
    
  }

  public ngOnInit(): void {
    this.subscriptions.onMarkItemForQickConfirm=this.acaManagementService.subscribeToQuickConfirmRecords((items:Aca1095cRecord[])=>{
      this.items=items;  
      this.markChanges();    
    });     
  }
  public markChanges():void{
    if(this.items && this.items.length>0)
      {
        this.acaManagementService.markAsDirty();
      }
      else
      {
       this.acaManagementService.clearChanges();
      }   
  }
  public undoEdit(item:Aca1095cRecord,monthItem:AcaMonthRecord): void {
    let undoItem:Aca1095cRecord;
    if(monthItem && item.months.length>1)
    {
      undoItem=_.clone(item);
      undoItem.months=[monthItem];    
   
    }
    else
    {
      undoItem=_.clone(item);

    }    
    if(undoItem)
    {      
      this.acaManagementService.setUndoQuickRecord(undoItem);
    }
  }

  public showChangesClick(): void {
    this.showChanges = true;
  }
  
  public hideChangesClick(): void {
    this.showChanges = false;
  }

  public startQuickConfirm(): void {
    if(!this.isQuickConfirmStarted)
    {
    this.isQuickConfirmStarted = true;
    this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmStart);
    }    
  }
  public saveQuickConfirm(): void {
    if(this.isQuickConfirmStarted)
    {
    this.items=[];
    this.isQuickConfirmStarted = false;   
    this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmSave );
    this.acaManagementService.clearChanges();
    }
  }
  public ngOnDestroy(): void {
    // Must be, see #issueWithAOTCompiler
  }

  public setExpandedItem(item:Aca1095cRecord):void{
    if(_.isEqual(this.expandedItem,item))
    {
      this.expandedItem=null; 
    }
    else
    {
      this.expandedItem=item; 
    }   
  }
}
