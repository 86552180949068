import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { W2sManagementService } from '../../../services/w2s/w2s-management.service';
import { appConfig } from '../../../../app.config';
import { screenUtils } from '../../../../common/utils';
import { InfoDialogComponent, ModalService } from '../../../../common';
import { PdfDataStatus } from '../../../../reports/enums/pdf-data-status';
var W2sGridComponent = /** @class */ (function () {
    function W2sGridComponent(w2sManagementService, modalService) {
        this.w2sManagementService = w2sManagementService;
        this.modalService = modalService;
        this.records = [];
        this.pageSize = 50;
        this.columnsGroupName = 'W2sRecords';
        this.isReordable = false;
        this.w2sState = 0;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [{ field: 'fullName', dir: 'desc' }];
        this.appConfig = appConfig;
    }
    Object.defineProperty(W2sGridComponent.prototype, "pdfDataStatus", {
        get: function () { return PdfDataStatus; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(W2sGridComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(W2sGridComponent.prototype, "failedReason", {
        get: function () {
            return "This Form W-2 did not publish correctly and is therefore not visible to the employee. Please try publishing again at your earliest convenience. If issues persist, please contact the Smartlinx Support team.";
        },
        enumerable: true,
        configurable: true
    });
    W2sGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onLoaded = this.w2sManagementService
            .subscribeToLoadedRecords(function (r) { return _this.setRecords(r.records); });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
    };
    W2sGridComponent.prototype.setRecords = function (records) {
        this.records = records;
        var selectedRecords = _.filter(this.records, function (item) { return (item.w2Document.isApproved == 0); });
        if (this.records.length == 0) {
            this.w2sState = 0;
        }
        else {
            if (selectedRecords.length == 0) {
                this.w2sState = 1;
            }
            else {
                this.w2sState = 2;
            }
        }
        this.refreshGrid();
    };
    W2sGridComponent.prototype.ngOnDestroy = function () { };
    W2sGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    W2sGridComponent.prototype.downloadW2SFile = function (exportId, fileName) {
        this.w2sManagementService.downloadW2sDocument(exportId, fileName);
    };
    W2sGridComponent.prototype.showReasonInfoPopup = function (data) {
        if (this.isMobile && data.w2Document.status.toLowerCase() === PdfDataStatus.FAILED.toLowerCase()) {
            InfoDialogComponent.OpenDialog('Information', this.failedReason, this.modalService);
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], W2sGridComponent.prototype, "subscriptions", void 0);
    return W2sGridComponent;
}());
export { W2sGridComponent };
