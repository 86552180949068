import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { DateRange } from '../../../../../core/models/index';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { RecurrenceFrequency } from '../../../models/index';
var LMRecurrenceFrequencyComponent = /** @class */ (function () {
    function LMRecurrenceFrequencyComponent(manService) {
        this.manService = manService;
        this.absenceFrequencies = [];
        this.exceptionsDates = [];
    }
    Object.defineProperty(LMRecurrenceFrequencyComponent.prototype, "hasExceptions", {
        get: function () {
            return _.size(this.exceptionsDates) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMRecurrenceFrequencyComponent.prototype, "canEditRequest", {
        get: function () {
            return this.manService.canChange;
        },
        enumerable: true,
        configurable: true
    });
    LMRecurrenceFrequencyComponent.prototype.ngOnInit = function () {
        this.absenceFrequencies = this.manService.recurrenceFrequencies;
        this.exceptionsDates = this.manService.getExceptions();
    };
    LMRecurrenceFrequencyComponent.prototype.onClickFrequency = function (freq) {
        this.setActive(freq);
        this.setFrequency(freq);
    };
    LMRecurrenceFrequencyComponent.prototype.setActive = function (freq) {
        var frequencies = this.getCleanFrequencies();
        _.forEach(frequencies, function (f) {
            if (f.id === freq.id) {
                f.isActive = true;
            }
        });
        this.absenceFrequencies = frequencies;
    };
    LMRecurrenceFrequencyComponent.prototype.setFrequency = function (freq) {
        this.manService.setFrequency(freq.id);
    };
    LMRecurrenceFrequencyComponent.prototype.getCleanFrequencies = function () {
        return _.map(this.absenceFrequencies, function (f) { return new RecurrenceFrequency(f.id, f.name); });
    };
    return LMRecurrenceFrequencyComponent;
}());
export { LMRecurrenceFrequencyComponent };
