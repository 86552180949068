import { IEmployee, Employee, IOrganization, Organization, IPosition, Position, Department, IDepartment } from '../../../organization/index';
import { IPerformanceReviewCode, PerformanceReviewCode } from '../../../organization/models/index';


export interface IPmRosterRecord {
  employee: IEmployee;
  organization: IOrganization;
  department: IDepartment;
  position: IPosition;
  reviewRecord: IPmReviewRecord;
  reviewType: IPerformanceReviewCode;
  rating: IPerformanceReviewCode;
  hireDate: string;
  terminationDate: string;
  activationDate: string;
}
export interface IPmReviewRecord {

  id: number;
  employeeId: number;
  organizationId: number;
  departmentId: number;
  positionId: number;
  employeeStatus: string;
  reviewDate: string;
  reviewType: number;
  rating: number;
  startDate: string;
  completionDate: string;
  status: boolean;
  reviewedBy: string;
  comment: string;
}


export interface IPmReviewRecords {
  employee: IEmployee,
  canCreateNewReview: boolean;
  canDeleteReview: boolean;
  records: IPmRosterRecord[];
  startDate: string;
  endDate: string;
}


export class PmReviewRecord {

  public id: number;
  public employee = new Employee();
  public organization = new Organization();
  public position = new Position();
  public department = new Department();
  public hideDate: Date;
  public terminationDate: Date;
  public employeeStatus: string;
  public reviewDate: Date;
  public reviewType: PerformanceReviewCode;
  public startDate: Date;
  public completionDate: Date;
  public status: string;
  public reviewedBy: string;
  public comment: string;
  public reviewRating: string;
}

export class PmReviewRecords<T> {
  constructor (
    public employee: Employee,
    public startDate: Date,
    public endDate: Date,
    public canCreateNewReview: boolean,
    public canDeleteReview: boolean,
    public records: T[],
  ) { }
}
