import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Provider } from '@angular/core';
import { DialogOptions2, KendoGridStateHelper, DialogModeSize, } from '../../../../../../app/common/models/index';
import { ModalService } from './../../../../../common/services/index';
import { BenefitPayrollExistingMappingDialogComponent } from '../benefit-payroll-existing-mapping-dialog/benefit-payroll-existing-mapping-dialog.component';
import { process } from '@progress/kendo-data-query';
import { BenefitPayrollCreateMapping } from '../../../models/benefit-details/index';
import { BenefitPayrollMappingManagementService } from '../../../services/benefit-console/benefit-payroll-mapping-management.service';
var BenefitPayrollMappingDialogComponent = /** @class */ (function () {
    function BenefitPayrollMappingDialogComponent(dialogOptions, modalService, manService) {
        this.dialogOptions = dialogOptions;
        this.modalService = modalService;
        this.manService = manService;
        this.payrollDeductions = [];
        this.records = [];
        this.benefitGroups = [];
        this.isAllDeductionSelected = false;
        this.isAnyDeductionSelected = false;
        this.ignoredDeductions = false;
        this.benefitPayrollExistingMapping = [];
        this.benefitPayrollCreateMapping = [];
        this.changedIgnorePayrollDeductions = {};
        this.hasIgnoreChanges = false;
        this.isLoading = false;
        this.defaultGroupItem = { id: 0, name: 'Select Group', type: null };
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.getBenefitPayrollMappingData();
    }
    BenefitPayrollMappingDialogComponent.openDialog = function (modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.modeSize = DialogModeSize.grid;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions,
            }
        ];
        var dialog = modalService.globalAnchor.openDialog2(BenefitPayrollMappingDialogComponent, 'Benefits Payroll Mapping', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result);
        });
        return dialog;
    };
    BenefitPayrollMappingDialogComponent.prototype.getBenefitPayrollMappingData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.manService.getBenefitPayRollMapping(this.ignoredDeductions)];
                    case 1:
                        _a.sent();
                        this.subscriptions.onLoadedMapping = this.manService
                            .subscribeToBenefitPayrollMapping(function (v) {
                            _this.benefitPayrollMapping = v;
                            _this.setData();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitPayrollMappingDialogComponent.prototype.setData = function () {
        var _this = this;
        this.payrollDeductions = this.benefitPayrollMapping.payrollDeductions;
        this.benefitGroups = this.benefitPayrollMapping.benefitGroups;
        this.records = this.payrollDeductions;
        this.refreshGrid();
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function () {
            _this.refreshGrid();
        });
    };
    BenefitPayrollMappingDialogComponent.prototype.onChangeIsAllDeductionSelected = function () {
        var _this = this;
        _.forEach(this.payrollDeductions, function (deduction) {
            deduction.isIgnored = _this.isAllDeductionSelected;
            _this.checkDeductionIgnore(deduction);
        });
        this.recalcDeductionIgnored();
    };
    BenefitPayrollMappingDialogComponent.prototype.onChangeDeductionisIgnored = function (payrollDeduction) {
        this.checkDeductionIgnore(payrollDeduction);
        this.recalcDeductionIgnored();
        this.isAllDeductionSelected = !_.find(this.payrollDeductions, function (d) { return !d.isIgnored; });
    };
    BenefitPayrollMappingDialogComponent.prototype.onChangebenefitGroups = function (payrollDeduction) {
        if (payrollDeduction.benefit && payrollDeduction.benefit.id === 0) {
            var selected = this.benefitPayrollCreateMapping.findIndex(function (e) { return e.payrollDeductionId === payrollDeduction.id; });
            if (selected !== -1) {
                this.benefitPayrollCreateMapping.splice(selected, 1);
                return;
            }
        }
        if (!payrollDeduction.isIgnored) {
            var selectedDate = this.benefitPayrollCreateMapping.findIndex(function (e) { return e.payrollDeductionId === payrollDeduction.id; });
            if (selectedDate === -1) {
                this.benefitPayrollCreateMapping.push({ payrollDeductionId: payrollDeduction.id, benefitGroupId: payrollDeduction.benefit.id });
            }
            else {
                this.benefitPayrollCreateMapping[selectedDate].benefitGroupId = payrollDeduction.benefit.id;
            }
        }
    };
    BenefitPayrollMappingDialogComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var benefitPayrollCreateMapping, changes;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        benefitPayrollCreateMapping = new BenefitPayrollCreateMapping();
                        benefitPayrollCreateMapping.mapping = this.benefitPayrollCreateMapping;
                        changes = _.values(this.changedIgnorePayrollDeductions);
                        benefitPayrollCreateMapping.ignorePayrollDeductions = _.map(_.filter(changes, function (c) { return !c.initialIsIgnored && c.isIgnored; }), function (c) { return c.id; });
                        benefitPayrollCreateMapping.resetIgnorePayrollDeductions = _.map(_.filter(changes, function (c) { return c.initialIsIgnored && !c.isIgnored; }), function (c) { return c.id; });
                        this.dialogResult = false;
                        if (!(this.benefitPayrollCreateMapping.length > 0 || changes.length > 0)) return [3 /*break*/, 2];
                        this.isLoading = true;
                        return [4 /*yield*/, this.manService.benefitPayRollCreateMapping(benefitPayrollCreateMapping)];
                    case 1:
                        _a.sent();
                        this.isLoading = false;
                        this.dialogResult = true;
                        _a.label = 2;
                    case 2:
                        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitPayrollMappingDialogComponent.prototype.cancel = function () {
        this.isAllDeductionSelected = false;
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    BenefitPayrollMappingDialogComponent.prototype.showBenefitPayrollExistingMappingDialog = function () {
        BenefitPayrollExistingMappingDialogComponent.openDialog(this.modalService, function (result) { });
    };
    BenefitPayrollMappingDialogComponent.prototype.onTogglerChanged = function (ignoredDeductions) {
        this.isLoading = true;
        this.gridState.view = null;
        this.manService.getBenefitPayRollMapping(this.ignoredDeductions);
    };
    BenefitPayrollMappingDialogComponent.prototype.checkDeductionIgnore = function (payrollDeduction) {
        if (payrollDeduction.isIgnored !== payrollDeduction.initialIsIgnored) {
            this.changedIgnorePayrollDeductions[payrollDeduction.id] = payrollDeduction;
        }
        else {
            delete this.changedIgnorePayrollDeductions[payrollDeduction.id];
        }
        if (payrollDeduction.isIgnored) {
            payrollDeduction.benefit = null;
            if (this.benefitPayrollCreateMapping.length > 0) {
                var selectedDate = this.benefitPayrollCreateMapping.findIndex(function (e) { return e.payrollDeductionId === payrollDeduction.id; });
                if (selectedDate !== -1) {
                    this.benefitPayrollCreateMapping.splice(selectedDate, 1);
                }
            }
        }
        return;
    };
    BenefitPayrollMappingDialogComponent.prototype.recalcDeductionIgnored = function () {
        var changes = _.values(this.changedIgnorePayrollDeductions);
        this.hasIgnoreChanges = changes.length > 0;
    };
    BenefitPayrollMappingDialogComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            this.isLoading = false;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
        this.isLoading = false;
    };
    return BenefitPayrollMappingDialogComponent;
}());
export { BenefitPayrollMappingDialogComponent };
