export * from './individual-schedule-employee-grid/individual-schedule-employee-grid.component';
export * from './individual-schedule-employee-list/individual-schedule-employee-list.component';
export * from './individual-schedule-entries-editor/individual-schedule-entries-editor.component';
export * from './individual-schedule-employee/individual-schedule-employee.component';
export * from './individual-schedule.component';

import { IndividualScheduleEmployeeGridComponent } from './individual-schedule-employee-grid/individual-schedule-employee-grid.component';
import { IndividualScheduleEmployeeListComponent } from './individual-schedule-employee-list/individual-schedule-employee-list.component';
import { IndividualScheduleEntriesEditorComponent } from './individual-schedule-entries-editor/individual-schedule-entries-editor.component';
import { IndividualScheduleEntryEditorComponent } from './individual-schedule-entry-editor/individual-schedule-entry-editor.component';
import { IndividualScheduleEmployeeComponent } from './individual-schedule-employee/individual-schedule-employee.component';
import { IndividualScheduleComponent } from './individual-schedule.component';
import { OverlappingSchedulePopupComponent } from './overlapping-schedule-popup/overlapping-schedule-popup.component';
export const individualScheduleComponents: any = [
  IndividualScheduleEmployeeGridComponent,
  IndividualScheduleEmployeeListComponent,
  IndividualScheduleEntriesEditorComponent,
  IndividualScheduleEntryEditorComponent,
  IndividualScheduleEmployeeComponent,
  IndividualScheduleComponent,
  OverlappingSchedulePopupComponent
];
