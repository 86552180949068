/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payroll-export-test-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../organization/components/custom-paycycle-dropdown/custom-paycycle-dropdown.component.ngfactory";
import * as i3 from "../../../../../organization/components/custom-paycycle-dropdown/custom-paycycle-dropdown.component";
import * as i4 from "@angular/common";
import * as i5 from "./payroll-export-test-toolbar.component";
import * as i6 from "../../../services/payroll-export-test/payroll-export-test-management.service";
import * as i7 from "../../../../../common/services/modal/modal.service";
var styles_PayrollExportTestToolbarComponent = [i0.styles];
var RenderType_PayrollExportTestToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PayrollExportTestToolbarComponent, data: {} });
export { RenderType_PayrollExportTestToolbarComponent as RenderType_PayrollExportTestToolbarComponent };
function View_PayrollExportTestToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "slx-button slx-with-icon add-template-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "icon slx-button__icon fas fa-cog"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Configure"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.manageService.canChangeConfig; _ck(_v, 0, 0, currVal_0); }); }
export function View_PayrollExportTestToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "flex-horizontal payroll-export-management-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-custom-paycycle-dropdown", [["class", "paycycle-selector"]], null, [[null, "payCycleSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("payCycleSelected" === en)) {
        var pd_0 = (_co.onPayCycleSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CustomPayCycleDropdownComponent_0, i2.RenderType_CustomPayCycleDropdownComponent)), i1.ɵdid(2, 245760, null, 0, i3.CustomPayCycleDropdownComponent, [], { setPayCycle: [0, "setPayCycle"], setDefault: [1, "setDefault"], payCycles: [2, "payCycles"] }, { payCycleSelected: "payCycleSelected" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayrollExportTestToolbarComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentPayCycle; var currVal_1 = true; var currVal_2 = _co.currentPayCycles; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.manageService.settingsAvailable; _ck(_v, 4, 0, currVal_3); }, null); }
export function View_PayrollExportTestToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-payroll-export-test-toolbar", [], null, null, null, View_PayrollExportTestToolbarComponent_0, RenderType_PayrollExportTestToolbarComponent)), i1.ɵdid(1, 180224, null, 0, i5.PayrollExportTestToolbarComponent, [i6.PayrollExportTestManagementService, i7.ModalService], null, null)], null, null); }
var PayrollExportTestToolbarComponentNgFactory = i1.ɵccf("slx-payroll-export-test-toolbar", i5.PayrollExportTestToolbarComponent, View_PayrollExportTestToolbarComponent_Host_0, {}, {}, []);
export { PayrollExportTestToolbarComponentNgFactory as PayrollExportTestToolbarComponentNgFactory };
