import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { MessagesMappingService } from '../services/messages-mapping.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/messages-mapping.service";
var date1 = new Date();
var date2 = new Date();
date1.setDate(date1.getDate() + 1);
date2.setDate(date2.getDate() + 2);
var mockData = {
    benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
    endDate: new Date(),
    id: 132,
    createdAt: moment(date1).format('YYYY-MM-DD'),
    modifiedBy: 'system',
    name: 'Abhishek',
    phone: '(000)-123 4567 890',
    photoPresent: false
};
var mockData1 = {
    benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
    endDate: new Date(),
    id: 132,
    createdAt: moment(date2).format('YYYY-MM-DD'),
    modifiedBy: 'system',
    name: 'Selvendran',
    phone: '(000)-999 9999 999',
    photoPresent: false
};
var mockData2 = {
    benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
    endDate: new Date(),
    id: 132,
    createdAt: moment(new Date()).format('YYYY-MM-DD'),
    modifiedBy: 'system',
    name: 'Abhishek',
    phone: '(000)-123 4567 890',
    photoPresent: false
};
var mockData3 = {
    benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    id: 132,
    createdAt: moment(new Date()).format('YYYY-MM-DD'),
    modifiedBy: 'system',
    name: 'Abhishek',
    phone: '(000)-123 4567 890',
    photoPresent: false
};
var mockData4 = {
    benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    id: 132,
    createdAt: moment(new Date()).format('YYYY-MM-DD'),
    modifiedBy: 'system',
    name: 'Abhishek',
    phone: '(000)-123 4567 890',
    photoPresent: false
};
var mockData5 = {
    benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    id: 132,
    createdAt: moment(new Date()).format('YYYY-MM-DD'),
    modifiedBy: 'system',
    name: 'Ashok',
    phone: '(000)-123 4567 890',
    photoPresent: true
};
var mockData6 = {
    benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    id: 132,
    createdAt: moment(new Date()).format('YYYY-MM-DD'),
    modifiedBy: 'system',
    name: 'Anil',
    phone: '(000)-123 4567 890',
    photoPresent: false
};
var mockData7 = {
    benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    id: 132,
    createdAt: moment(new Date()).format('YYYY-MM-DD'),
    modifiedBy: 'system',
    name: 'Doolchand',
    phone: '(000)-555 4567 890',
    photoPresent: false
};
var mockData8 = {
    benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
    endDate: moment(new Date()).format('YYYY-MM-DD'),
    id: 132,
    createdAt: moment(new Date()).format('YYYY-MM-DD'),
    modifiedBy: 'system',
    name: 'Pavel',
    phone: '(000)-123 4567 890',
    photoPresent: false
};
var data = [mockData, mockData1, mockData2, mockData3, mockData4, mockData5, mockData6, mockData7, mockData8];
var MessagesApiService = /** @class */ (function () {
    function MessagesApiService(mapService) {
        this.mapService = mapService;
    }
    MessagesApiService.prototype.getSMSMessagesInfo = function (orgLevelId, tierId, employee) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, data]; // this.mapService.mapToSmsMessagesInfo(data);
            });
        });
    };
    MessagesApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagesApiService_Factory() { return new MessagesApiService(i0.ɵɵinject(i1.MessagesMappingService)); }, token: MessagesApiService, providedIn: "root" });
    return MessagesApiService;
}());
export { MessagesApiService };
