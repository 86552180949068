import { Pipe, Injectable, PipeTransform } from '@angular/core';
@Pipe({
  name: 'slxPercent',
  pure: true
})
@Injectable()
export class SlxPercentPipe implements PipeTransform {
  public transform(obj?: any): any {
    if (obj === null || obj === undefined) return null;
    return `${obj}%` ;
  }
}
