import * as _ from 'lodash';
var StaffingPointsTable = /** @class */ (function () {
    function StaffingPointsTable() {
    }
    Object.defineProperty(StaffingPointsTable.prototype, "firstRow", {
        get: function () {
            return _.first(this.rows);
        },
        enumerable: true,
        configurable: true
    });
    return StaffingPointsTable;
}());
export { StaffingPointsTable };
