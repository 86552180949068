<div class="modal-content">
  <div class="modal-body"  [ngClass]="approveTimecards ? 'approveTimecards-modal-body' : ''">
    <div class="header-message">
        {{orgLevelName}}
    </div>
    <hr/>
    <div class="result-message flex-vertical">
      <div class="flex-horizontal row-offset">
        <div class="form-item flex-vertical">
          <span class="label-item">From:</span>
          <span class="value-item">{{stats?.startDate | amDateFormat: appConfig.dateFormat}}</span>
        </div>
        <div class="form-item flex-vertical">
          <span class="label-item">To:</span>
          <span class="value-item">{{stats?.endDate | amDateFormat: appConfig.dateFormat}}</span>
        </div>
      </div>
      <div class="flex-horizontal">
        <div class="form-item flex-vertical">
          <span class="label-item">Total Records:</span>
          <span class="value-item">{{stats?.totalRecords}}</span>
        </div>
        <div class="form-item flex-vertical">
          <span class="label-item">Pending Records:</span>
          <span class="value-item">{{stats?.pendingRecords}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" [ngClass]="approveTimecards ? 'approveTimecards-modal-footer' : ''">
    <div *ngIf="approveTimecards" class="recalc-msg">
      <p *ngIf="!canApprove">Timecards must complete recalculation and save before they can be approved.</p>
      <p *ngIf="canApprove">Timecards recalculation complete. Timecards can now be approved.</p>
    </div>
    <div>
      <button type="button" (click)="onCancel()" class="slx-button">Cancel</button>
      <button [disabled]="!canRefresh" type="button" (click)="onRefresh()" class="slx-button slx-blue">Refresh</button>
      <button *ngIf="approveTimecards" [disabled]="!canApprove" type="button" (click)="onApprove()" class="slx-button slx-blue">Approve</button>
    </div>
  </div>
</div>
