import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MassAssignPayUnitsRequest } from '../../models/index';
import { ModalService } from '../../../../common/services/index';
import { MassAssignmentPayUnitsDialogComponent } from '../../components/mass-assignment-payunits-dialog/mass-assignment-payunits-dialog.component';

@Directive({
  selector: '[slxMassAssignPayUnitsModal]',
})
export class MassAssignPayUnitsModalModalDirective {
  constructor(private modalService: ModalService) {
  }
  @Input()
  public slxMassAssignPayUnitsModal: MassAssignPayUnitsRequest;

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    MassAssignmentPayUnitsDialogComponent.openDialog(this.slxMassAssignPayUnitsModal, this.modalService, (result: boolean, cmd: any) => {

    });
  }
}
