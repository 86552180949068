import * as moment from 'moment';
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { EmployeeShortInfo } from '../../../../organization/models/index';
import { EmployeeSubSection } from '../../models/index';
import { ChangeManagementService } from '../../../../common/services/index';
import { EmployeeSectionBridgeService } from '../../services/index';
var EmployeeSubSectionsDecoratorComponent = /** @class */ (function () {
    function EmployeeSubSectionsDecoratorComponent(changeService, bridge) {
        var _this = this;
        this.changeService = changeService;
        this.bridge = bridge;
        this.canTerminateEmp = false;
        this.isResetEffectiveDate = false;
        this.payRateDirtyState = [false, false];
        this.dateError = false;
        this.isValidSelectedDate = true;
        this.disabledDates = function (date) {
            return _this.subsectionComponent.disabledDate(date);
        };
        this.state = {
            isLoading: false,
            isLoaded: false,
            isEditMode: false
        };
    }
    Object.defineProperty(EmployeeSubSectionsDecoratorComponent.prototype, "isLoadingSection", {
        set: function (loading) {
            if (loading) {
                this.startProgress();
            }
            else {
                this.stopProgress();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSubSectionsDecoratorComponent.prototype, "hasActiveState", {
        get: function () {
            return !this.employeeShortInfo.terminationDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSubSectionsDecoratorComponent.prototype, "isFutureRehired", {
        get: function () {
            return this.employeeStatus ? this.employeeStatus.toLowerCase() === "future rehire" : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSubSectionsDecoratorComponent.prototype, "hasAccessToAdd", {
        get: function () {
            this.updateModel();
            if (!this.subsectionModel) {
                return false;
            }
            return this.subsectionModel.actions ? this.subsectionModel.actions.canAdd : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSubSectionsDecoratorComponent.prototype, "hasAccessToDelete", {
        get: function () {
            this.updateModel();
            if (!this.subsectionModel) {
                return false;
            }
            return this.subsectionModel.actions ? this.subsectionModel.actions.canDelete : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSubSectionsDecoratorComponent.prototype, "hasAccessToEdit", {
        get: function () {
            this.updateModel();
            if (!this.subsectionModel) {
                return false;
            }
            return this.subsectionModel.actions ? this.subsectionModel.actions.canEdit : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSubSectionsDecoratorComponent.prototype, "isEditable", {
        get: function () {
            if (!this.hasActiveState && this.isTermHistoryEditEndabled && this.isEditableWhenEmpTerminated)
                return true;
            if (this.isFutureRehired && this.isNotEditableWhenEmpFutureRehired)
                return false;
            return (this.hasActiveState || this.isEditableWhenEmpTerminated) && this.hasAccessToEdit && this.isEditableByConfiguration;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSubSectionsDecoratorComponent.prototype, "isSubsectionEditable", {
        get: function () {
            if (this.isFutureRehired && this.isNotEditableWhenEmpFutureRehired)
                return false;
            if (this.hasActiveState || this.isEditableWhenEmpTerminated)
                return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSubSectionsDecoratorComponent.prototype, "valid", {
        get: function () {
            var formValid = this.subsectionComponent ? !this.subsectionComponent.form.invalid : false;
            return formValid;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSubSectionsDecoratorComponent.prototype.ngOnInit = function () {
        //todo
    };
    EmployeeSubSectionsDecoratorComponent.prototype.ngOnChanges = function (changes) {
        //todo
    };
    EmployeeSubSectionsDecoratorComponent.prototype.startProgress = function () {
        this.state.isLoading = true;
    };
    EmployeeSubSectionsDecoratorComponent.prototype.stopProgress = function () {
        this.state.isLoading = false;
    };
    EmployeeSubSectionsDecoratorComponent.prototype.onEdit = function () {
        this.state.isEditMode = true;
        this.setEditState(true);
    };
    EmployeeSubSectionsDecoratorComponent.prototype.onSave = function () {
        var _this = this;
        this.subsectionComponent.canSave().then(function (canSave) {
            if (canSave) {
                if (!_this.temporalModel || !_this.temporalModel.isTemporalDirty) {
                    _this.effectiveDate = null;
                    _this.isResetEffectiveDate = true;
                    _this.subsectionComponent.resetCurrentPayCycleWarning();
                }
                _this.state.isEditMode = false;
                _this.temporalModel = null;
                _this.setEditState(false);
                _this.subsectionComponent.Save(_this.effectiveDate);
            }
        }).catch(function (error) {
            throw error;
        });
    };
    EmployeeSubSectionsDecoratorComponent.prototype.onCancel = function () {
        this.state.isEditMode = false;
        this.temporalModel = null;
        this.setEditState(true);
        this.isResetEffectiveDate = true;
        if (this.subsectionComponent)
            this.subsectionComponent.resetCurrentPayCycleWarning();
    };
    EmployeeSubSectionsDecoratorComponent.prototype.registerSubsectionComponent = function (subsectionComponent) {
        this.subsectionComponent = subsectionComponent;
    };
    EmployeeSubSectionsDecoratorComponent.prototype.setTemporalModel = function (temporalModel) {
        if (temporalModel && (temporalModel.isOnlyPayRateDirty || temporalModel.isNotOnlyPayRateDirty)) {
            var newPayRateDirtyState = [temporalModel.isOnlyPayRateDirty, temporalModel.isNotOnlyPayRateDirty];
            if (!_.isEqual(this.payRateDirtyState, newPayRateDirtyState)) {
                this.isResetEffectiveDate = true;
            }
            if (this.isResetEffectiveDate) {
                if (temporalModel.payrollEffectiveDateSetting) {
                    if (!temporalModel.payrollEffectiveDateSetting.effectiveDateData) {
                        if (this.subsectionComponent)
                            this.subsectionComponent.showCurrentPayCycleWarning();
                        this.effectiveDate = new Date();
                    }
                    else {
                        this.effectiveDate = temporalModel.payrollEffectiveDateSetting.effectiveDateData;
                    }
                    this.isResetEffectiveDate = false;
                    this.payRateDirtyState = newPayRateDirtyState;
                }
            }
        }
        else {
            if (!this.effectiveDate && this.isValidSelectedDate) {
                this.effectiveDate = moment().add(-2, 'days').toDate();
            }
            if (temporalModel && moment(temporalModel.minEffectiveData).isAfter(this.effectiveDate)) {
                this.effectiveDate = temporalModel.minEffectiveData;
            }
            if (this.isFutureRehired) {
                this.minEffectiveDate = this.employeeRehireDate;
            }
            else {
                if (temporalModel && !moment(this.minEffectiveDate).isSame(temporalModel.minEffectiveData)) {
                    this.minEffectiveDate = temporalModel.minEffectiveData;
                }
            }
        }
        this.temporalModel = temporalModel;
    };
    EmployeeSubSectionsDecoratorComponent.prototype.isCurrentPayCycle = function () {
        if (this.temporalModel && this.temporalModel.payrollEffectiveDateSetting) {
            if (this.temporalModel.payrollEffectiveDateSetting.effectiveDateData) {
                return true;
            }
        }
        return false;
    };
    EmployeeSubSectionsDecoratorComponent.prototype.setChanges = function () {
        this.changeService.changeNotify(this.subsection.id);
    };
    EmployeeSubSectionsDecoratorComponent.prototype.clearChanges = function () {
        this.changeService.clearChanges(this.subsection.id);
    };
    EmployeeSubSectionsDecoratorComponent.prototype.setEditState = function (reload) {
        if (this.subsectionComponent) {
            this.subsectionComponent.setEditState(this.state.isEditMode);
            this.bridge.notifyEditStateChange(this.state.isEditMode, this.subsection);
            if (reload) {
                this.subsectionComponent.load(true);
            }
        }
    };
    EmployeeSubSectionsDecoratorComponent.prototype.updateModel = function () {
        this.subsectionModel = this.subsectionComponent ? this.subsectionComponent.getSubsectionModel() : null;
    };
    EmployeeSubSectionsDecoratorComponent.prototype.onEffectiveDateChange = function (date) {
        var momentDate = moment(date, 'MM/DD/YYYY');
        this.isValidSelectedDate = momentDate.isValid();
        if (this.isValidSelectedDate && this.subsectionComponent) {
            this.dateError = !this.subsectionComponent.isValidEffectiveDate(date);
        }
        else {
            this.dateError = true;
        }
    };
    return EmployeeSubSectionsDecoratorComponent;
}());
export { EmployeeSubSectionsDecoratorComponent };
