import * as tslib_1 from "tslib";
import { lmConfig } from '../../leave-management.config';
import { ApiUtilService } from '../../../../common/index';
import { UrlParamsService } from '../../../../common/services/url-params/url-params.service';
import { LMMapService } from '../lm-map.service';
var LMTypeApiService = /** @class */ (function () {
    function LMTypeApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    LMTypeApiService.prototype.getLoaTypeModels = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getLoaTypeApi();
                request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
                // return Promise.resolve(this.mapService.mapLoaTypeModelContainer(LoaTypeModelContainer.mock));
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapLoaTypeModelContainer(response.data); })];
            });
        });
    };
    LMTypeApiService.prototype.createLoaType = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getLoaTypeApi();
                body = this.mapService.mapLoaTypeModelToDto(req);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapLoaTypeEditResult(response.data); })];
            });
        });
    };
    LMTypeApiService.prototype.modifyLoaType = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getLoaTypeApi();
                body = this.mapService.mapLoaTypeModelToDto(req);
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapLoaTypeEditResult(response.data); })];
            });
        });
    };
    LMTypeApiService.prototype.deleteLoaType = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getLoaTypeApi();
                request = this.urlParamsService.createDeleteRequest(url, { id: id });
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapLoaTypeEditResult(response.data); })];
            });
        });
    };
    LMTypeApiService.prototype.getLoaTypeApi = function () {
        return this.apiUtilService.getApiRoot() + "/" + lmConfig.api.loa.root + "/" + lmConfig.api.loa.typeModels.root;
    };
    return LMTypeApiService;
}());
export { LMTypeApiService };
