import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { DialogOptions } from '../../../common/models/dialog-options';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { ManagementBaseService } from '../../../core/services/index';
import { Observable } from 'rxjs/Observable';
import { ChangeManagementService } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { organizationConfig } from '../../../organization/organization.config';
import { LookupApiService } from '../../../organization/services/lookup/lookup-api.service';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { ShiftsContainer } from '../../models/shifts/shifts-container';
import { ShiftsApiService } from './shifts-api.service';
import { ShiftSetting } from '../../models/shifts/shift-setting';
var ShiftsManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(ShiftsManagementService, _super);
    function ShiftsManagementService(access, changeService, api, lookup) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.lookup = lookup;
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.savedItemCmd$ = new ReplaySubject();
        _this.dataChanged$ = new Subject();
        _this.viewRefresh$ = new Subject();
        _this.errorNotify$ = new Subject();
        return _this;
    }
    Object.defineProperty(ShiftsManagementService.prototype, "groups", {
        get: function () {
            return this.m_groups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftsManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    ShiftsManagementService.prototype.init = function () {
        var _this = this;
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = false;
        this.access.allowDepartmentLevel = true;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (_.isNumber(orgLevel.id)) {
                _this.currentOrgLevel = orgLevel;
                _this.access.orgLevelType = _this.currentOrgLevel.type;
                _this.onStateChanged$.next({ orgLevelChanged: true, configureMode: true, copyMode: false });
                _this.fetchRecords();
                _this.getGroups();
            }
        });
    };
    ShiftsManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    ShiftsManagementService.prototype.openCopyItems = function () {
        this.onStateChanged$.next({ configureMode: false, copyMode: true });
    };
    ShiftsManagementService.prototype.closeCopyItems = function () {
        this.onStateChanged$.next({ configureMode: true, copyMode: false });
    };
    ShiftsManagementService.prototype.setSelectedCount = function (count) {
        this.access.selectedItemsCount = count;
    };
    ShiftsManagementService.prototype.onAddItem = function (item) {
        this.changeService.changeNotify();
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    };
    ShiftsManagementService.prototype.onEditItem = function (item) {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    };
    ShiftsManagementService.prototype.onCancelEditItem = function () {
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges();
    };
    ShiftsManagementService.prototype.onRemoveItem = function (itemToDelete) {
        if (itemToDelete.employeesCount > 0) {
            var dialogOptions = new DialogOptions();
            dialogOptions.width = 370;
            dialogOptions.height = 190;
            this.removeItemsCmd$.next({ dialogOptions: dialogOptions, itemToDelete: itemToDelete });
        }
        else {
            this.removeItemsCmd$.next({ dialogOptions: null, itemToDelete: itemToDelete });
        }
    };
    ShiftsManagementService.prototype.doRemoveItem = function (item, reassignTo) {
        var _this = this;
        this.api.removeShift(item, this.currentOrgLevel.id, reassignTo)
            .then(function (items) {
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () {
            _this.access.lockActions = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    ShiftsManagementService.prototype.onSaveFormExternalEditor = function () {
        this.onSaveItem({ dataItem: this.editingItem, isNew: false });
    };
    ShiftsManagementService.prototype.onCancelFormExternalEditor = function () {
        this.editingItem = null;
        this.isEditingNewItem = false;
    };
    ShiftsManagementService.prototype.onSaveItem = function (info) {
        var preventSaveError;
        _.each(this.m_container.records, function (shift) {
            if (shift !== info.dataItem) {
                var startSame = moment(shift.start).isSame(info.dataItem.start);
                var endSame = moment(shift.end).isSame(info.dataItem.end);
                var durationSame = shift.duration === info.dataItem.duration;
                if (shift.name === info.dataItem.name) {
                    preventSaveError = 'Attempting to save duplicate shift description';
                }
                if (startSame && endSame && durationSame) {
                    preventSaveError = 'Attempting to save duplicate shift';
                }
            }
        });
        if (preventSaveError) {
            this.errorNotify$.next(preventSaveError);
            return;
        }
        if (info.isNew) {
            this.addItem(info.dataItem);
        }
        else {
            this.updateItem(info.dataItem);
        }
    };
    ShiftsManagementService.prototype.addItem = function (item) {
        var _this = this;
        _.each(this.m_container.records, function (p) {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.saveShift(item, this.currentOrgLevel.id)
            .then(function (items) {
            _this.savedItemCmd$.next(_this.editingItem);
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () {
            _this.access.lockActions = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    ShiftsManagementService.prototype.updateItem = function (item) {
        var _this = this;
        _.each(this.m_container.records, function (p) {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        //merging partials shifts 
        if (item.hasPartialShift || item.deletedPartials.length > 0) {
            item.partialShiftList = _.concat(item.partialShiftList, item.deletedPartials);
        }
        this.api.saveShift(item, this.currentOrgLevel.id)
            .then(function (items) {
            _this.savedItemCmd$.next(_this.editingItem);
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () {
            _this.onStateChanged$.next({ isLoading: false });
            _this.access.lockActions = false;
            _this.viewRefresh$.next(false);
        });
    };
    ShiftsManagementService.prototype.fetchRecords = function () {
        var _this = this;
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getShiftsList(this.currentOrgLevel.id).
            then(function (result) {
            _this.changeService.clearChanges();
            _this.m_container = new ShiftsContainer();
            _this.m_container.records = result.records;
            _this.access.actions = result.actions;
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.onLoaded$.next(_this.m_container);
            _this.onStateChanged$.next({ isLoading: false });
        }).catch(function () {
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    ShiftsManagementService.prototype.getGroups = function () {
        var _this = this;
        var orgLevelId = this.currentOrgLevel.id;
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.lookup.getLookup(organizationConfig.lookup.shiftGroup, orgLevelId)
            .then(function (value) {
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
            _this.m_groups = value;
        })
            .catch(function (reason) {
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
            _this.m_groups = null;
        });
    };
    ShiftsManagementService.prototype.getShiftSetting = function (shiftId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.access.lockActions = true;
                        this.onStateChanged$.next({ isLoading: true });
                        return [4 /*yield*/, this.api.getShiftSetting(this.currentOrgLevel.id, shiftId).then(function (res) { return res; }).finally(function () {
                                _this.access.lockActions = false;
                                _this.onStateChanged$.next({ isLoading: false });
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ShiftsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftsManagementService.prototype, "orgLevelSubscription", void 0);
    return ShiftsManagementService;
}(ManagementBaseService));
export { ShiftsManagementService };
