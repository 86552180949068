import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Subscription } from 'rxjs';
import { KendoGridStateHelper, ModalService, PbjNavigationService } from '../../../../common';
import { unsubscribe } from '../../../../core/decorators';
import * as _ from 'lodash';
import { PBJDuplicateWorkers } from '../../models/pbj-duplicate-workers/pbj-duplicate-workers';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { PbjExportApiService } from '../../services/pbj-export/pbj-export-api.service';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ColumnReorderEvent, GridComponent } from '@progress/kendo-angular-grid';
import { DispositionState } from '../../models/pbj-duplicate-workers/pbj-disposition';
import { PbjDuplicateMatchesDialogOptions } from '../../models/pbj-duplicate-workers/pbj-duplicate-matches-dialog-options';
import { PbjDuplicateMatchesDialogComponent } from './pbj-duplicate-matches-dialog/pbj-duplicate-matches-dialog.component';
import { ExcelExportData, Workbook, WorkbookSheet } from '@progress/kendo-angular-excel-export';
import { ExcelExportEvent } from '@progress/kendo-angular-grid/dist/es2015/excel/excel-export-event';
import * as kendo from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';

@Component({
  selector: 'slx-pbj-duplicate-workers',
  templateUrl: './pbj-duplicate-workers.component.html',
  styleUrls: ['./pbj-duplicate-workers.component.scss']
})
export class PbjDuplicateWorkersComponent implements OnInit {
  @unsubscribe()
  private routeSubscripion: Subscription;
  private orgLevelId: number = null;
  public data: PBJDuplicateWorkers[] = [];
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<PBJDuplicateWorkers>;
  @ViewChild('exportKendoGrid', { static: true })
  private grid: GridComponent;

  isLoading: boolean;
  colNames: string[];
  hiddenColumns: string[] = [];
  public recordControls: DispositionState[] = [
    {
      id: 0,
      text: "Pending",
      value: "Pending"
    },
    {
      id: 1,
      text: "Match",
      value: "Match"
    },
    {
      id: 3,
      text: "Not a Match",
      value: "Not a Match"
    },
    {
      id: 4,
      text: "All Records",
      value: "All Records"
    }
  ];
  public dispoOptions : DispositionState[] = [
    {
      id: 0,
      text: "Pending",
      value: "Pending"
    },
    {
      id: 1,
      text: "Match",
      value: "Match"
    },
    {
      id: 3,
      text: "Not a Match",
      value: "Not a Match"
    },
  ];
  xlsxName: string;
  dispoState = new DispositionState();
  allRecords: PBJDuplicateWorkers[];
  selectedDateForMatches:Date;
  exportMatches:boolean =false;
  constructor(private route: ActivatedRoute, private apiService: PbjExportApiService, private modalService: ModalService) {
    this.gridState = new KendoGridStateHelper<PBJDuplicateWorkers>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.filter = null;
    this.gridState.state.group = [];
    this.appConfig = appConfig;
    this.dispoState = this.recordControls[0];
    this.gridState.view = { data: this.data, total: this.data.length };
    if(this.orgLevelId !=null && this.orgLevelId !=undefined) this.getDuplicateRecords();
    this.getExportData = this.getExportData.bind(this);
  }

  ngOnInit() {
    this.isLoading = true;
    this.colNames = ["Location", "PBJ ID 1", "Last Name 1", "First Name 1", "Position 1", "Email 1", "Phone 1", "Birthdate 1", "SSN 1", "Primary Location 1", "PBJ ID 2", "Last Name 2", "First Name 2", "Position 2", "Email 2", "Phone 2", "Birthdate 2", "SSN 2", "Primary Location 2", "Match Score"];
    this.routeSubscripion = this.route.params
      .combineLatest(this.route.queryParams)
      .subscribe(([params, queryParams]) => {
        const orgLevelId = +queryParams['orgLevelId'];
        if (_.isFinite(orgLevelId) && this.orgLevelId !== orgLevelId) {
          this.orgLevelId = orgLevelId;
          this.hiddenColumns=[];
          this.dispoState = this.recordControls[0];
          this.getDuplicateRecords();
          this.getExportMatchesDefaultDate();
        }
        if (!_.isFinite(this.orgLevelId) && _.isFinite(orgLevelId)) {
          this.orgLevelId = orgLevelId;
        }

      });
  }

  public getDuplicateRecords(){
    this.isLoading = true; 
    this.apiService.performDuplicateExport(this.orgLevelId).then((val: PBJDuplicateWorkers[]) => {
      this.loadDataToGrid(val);
    })
  }

  public loadDataToGrid(data: PBJDuplicateWorkers[]){
    this.allRecords = data;
    for(let i =0; i< this.allRecords.length; i++){
      if(this.allRecords[i].disposition == null) this.allRecords[i].disposition = "Pending";
    }
    this.data = this.dispoState.value!="All Records" ? data.filter(x=> x.disposition == this.dispoState.value) : this.allRecords; 
    this.isLoading = false;
    this.refreshGrid();
  }

  public refreshGrid() {
    if (this.data === undefined || this.data.length == 0) this.gridState.view = null;
    if (this.data.length > 0) this.gridState.view = process(this.data, this.gridState.state);

  }

  public hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.hiddenColumns.push(columnName);
    } else {
      this.hiddenColumns.splice(this.hiddenColumns.indexOf(columnName), 1);
    }
  }

  public isHidden(columnName: string): boolean {
    return this.hiddenColumns.indexOf(columnName) > -1;
  }

  public restrictReorder(event:ColumnReorderEvent) {
    if (event.newIndex === 0 || 
        event.newIndex === 1 ||event.column.title === "Disposition" || 
        event.column.title === "Disposition Date"){
      event.preventDefault();
    }
  }

  public handleDisposition(event, colDetails) {
    if(event.value!= colDetails.disposition && colDetails.disposition!=null){
    colDetails.disposition = event.text;
    colDetails.primary_org_level_id = this.orgLevelId;
    if(event.value == "Pending") colDetails.disposition_Date = null;
    this.isLoading = true;
    this.apiService.changeDisposition(colDetails).then((val: PBJDuplicateWorkers[]) => {
      this.loadDataToGrid(val);
    });
   }
  }

  public filterDuplicates(event) {
    this.gridState.state.filter = event;
    this.refreshGrid();
  }

  public setSelectedPosition(dispoOption: string) {
    let obj = this.dispoOptions.find(e => e.value === dispoOption);
    if (obj) {
      return obj;
    }
    else {
      return this.dispoOptions[0];
    }
  }

  public selectClearCols(type){
   if(type == 'select') this.hiddenColumns=[];
   if(type == 'clear') this.hiddenColumns=["Location", "PBJ ID 1", "Last Name 1", "First Name 1", "Position 1", "Email 1", "Phone 1", "Birthdate 1", "SSN 1", "Primary Location 1", "PBJ ID 2", "Last Name 2", "First Name 2", "Position 2", "Email 2", "Phone 2", "Birthdate 2", "SSN 2", "Primary Location 2", "Match Score"];
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.refreshGrid();
  }

  public exportExcel(){
    this.exportMatches = false;
    this.xlsxName = `EeDupes_${this.getDate()}.xlsx`;
    this.grid.saveAsExcel();
  } 

  public getFilteredRecords(target){
    if(target!=undefined) {
      this.gridState.state.filter={filters:[], logic:'and'};
      if(target.value == 'Pending') this.gridState.state.sort = []
      this.dispoState = this.recordControls.find(x=> x.value == target.value);
     if(this.allRecords != undefined) this.data = (target.value!= "All Records") ? this.allRecords.filter(x=> x.disposition == target.value) : this.allRecords;
    this.refreshGrid();
    }
  }

  public async getExportMatchesDefaultDate(){
  this.isLoading = true;
   await this.apiService.getExportMatchesDefaultDate(this.orgLevelId).then((val:any)=>{
      this.selectedDateForMatches = new Date(val.data);
    })
    this.isLoading = false;
  }

  public updateExportMatchesDefaultDate(pbjDuplicateWorkers:PBJDuplicateWorkers[]){
    this.apiService.updateExportMatchesDefaultDate(pbjDuplicateWorkers, this.orgLevelId).then((val:any)=>{
      this.isLoading = false;
    })
  }

  public async openExportMatchesDialog(matchingDupesFound:boolean){
    await this.getExportMatchesDefaultDate();
    const options: PbjDuplicateMatchesDialogOptions = new PbjDuplicateMatchesDialogOptions(this.selectedDateForMatches, matchingDupesFound);
    let dialogref = PbjDuplicateMatchesDialogComponent.openDialog(options, this.modalService, 
      (result: boolean) => {
        if(result){
          this.isLoading = true;
          this.selectedDateForMatches = dialogref.selectedDate;
          this.exportMatches = true;
          this.grid.saveAsExcel();
        }
      }
    );
  }

  public getExportData(): ExcelExportData {
    const result: ExcelExportData = {
        data: this.exportMatches ? process(this.allRecords.filter(x=> (x.disposition == 'Match' && (x.disposition_Date >=this.selectedDateForMatches || x.matches_Export_Date === null))), this.gridState.state).data : process(this.data, this.gridState.state).data,
    };
    return result;
  }

  public getDate():string{
    let date = new Date();
    let month = (date.getMonth()+1) < 10 ? `0${date.getMonth()+1}` : `${date.getMonth()+1}`;
    let todayDate = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    let year = date.getFullYear().toString();
    let dateFormat = month+ todayDate + year; 
    return dateFormat;
  }

  public async onExcelExport(e:ExcelExportEvent){
    if(this.exportMatches){
      e.preventDefault();
      let fileName = `EeDupeMatches_${this.getDate()}.xlsx`;
      let workbook:Workbook = e.workbook;
      workbook.sheets[0].name = "All Data";
      let filteredData:ExcelExportData = this.getExportData();
      let filteredRows:any = [
        { cells: 
          [ 
            {background: '#7a7a7a', color: '#fff', value: 'oldEmployeeId', colSpan: 1, rowSpan: 1},
            {background: '#7a7a7a', color: '#fff', value: 'newEmployeeId', colSpan: 1, rowSpan: 1} 
          ],
          type: 'header'
        }
      ];
      filteredData.data.map((item:PBJDuplicateWorkers)=>{
        if(item.disposition_Date >= this.selectedDateForMatches){
          let row:any = {
            type: 'data',
            cells: [
              { value: item.pbJ_ID_1 }, { value: item.pbJ_ID_2 }
            ]
          }
          filteredRows.push(row);
        }
      })
      if(filteredRows.length > 1 ){
        let sheet:WorkbookSheet = {
          columns: [ { width: 150, autoWidth: false }, {width: 150, autoWidth: false} ],
          name: "EmployeeLink Data",
          rows: filteredRows
        }
        workbook.sheets.unshift(sheet);
        kendo.toDataURL(workbook).then((val:string)=>{
          saveAs(val,fileName);
        });
        let matchesExportData:PBJDuplicateWorkers[] = this.allRecords.filter(x=> x.disposition == 'Match' && (x.disposition_Date >=this.selectedDateForMatches || x.matches_Export_Date === null));
        this.updateExportMatchesDefaultDate(matchesExportData);
      } else {
        this.isLoading=false;
        this.openExportMatchesDialog(false);
      }
    }
  }
}
    