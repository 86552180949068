import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { EmployeeLeaveManagement } from '../../../models/employee-sections-employment/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { KendoGridStateHelper, ModalService, loaDropdownItems } from '../../../../../common/index';
import { unsubscribe, mutableSelect } from '../../../../../core/decorators/index';
import { LMCreationDialogComponent } from '../../../../../app-modules/leave-management/components/index';
import { LMManagementService } from '../../../../../app-modules/leave-management/services';
var EmployeeSectionsLeaveManagementComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsLeaveManagementComponent, _super);
    function EmployeeSectionsLeaveManagementComponent(employeeSectionsEmploymentApiService, decorator, ngZone, changeDetector, modalService, managementService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.changeDetector = changeDetector;
        _this.modalService = modalService;
        _this.managementService = managementService;
        _this.leaveTypes = loaDropdownItems;
        _this.pageSize = 5;
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.take = _this.pageSize;
        _this.gridState.state.skip = 0;
        _this.gridState.state.sort = [{ field: 'estStartDate', dir: 'desc' }];
        _this.gridRefreshSubscription = _this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        return _this;
    }
    Object.defineProperty(EmployeeSectionsLeaveManagementComponent.prototype, "canAddEdit", {
        get: function () {
            return this.leaveManagement ? this.leaveManagement.canAddEdit : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsLeaveManagementComponent.prototype, "canDelete", {
        get: function () {
            return this.leaveManagement ? this.leaveManagement.canDelete : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsLeaveManagementComponent.prototype, "leaveManagement", {
        get: function () {
            return this.empLeaveManagement;
        },
        set: function (leaveManagementObject) {
            this.empLeaveManagement = leaveManagementObject;
            if (!this.empLeaveManagement) {
                return;
            }
            this.updateUserRights();
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsLeaveManagementComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsLeaveManagementComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsLeaveManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.loadSubsection();
        this.orgLevelSubscription = this.orgLevel$
            .subscribe(function (orgLevel) { return _this.orgLevel = orgLevel; });
    };
    EmployeeSectionsLeaveManagementComponent.prototype.getSubsectionModel = function () {
        return this.empLeaveManagement;
    };
    EmployeeSectionsLeaveManagementComponent.prototype.createNewRequest = function (item) {
        if (!this.orgLevel) {
            return;
        }
        var dialog = LMCreationDialogComponent.openDialogForEmployee(this.employeeShortInfo, this.orgLevel.id, item.id, this.modalService);
        this.subscribeToDialog(dialog);
    };
    EmployeeSectionsLeaveManagementComponent.prototype.openRequest = function (item) {
        if (!this.orgLevel) {
            return;
        }
        var dialog = LMCreationDialogComponent.openDialogForRequest(this.orgLevel.id, item, this.modalService);
        this.subscribeToDialog(dialog);
    };
    EmployeeSectionsLeaveManagementComponent.prototype.subscribeToDialog = function (dialog) {
        var _this = this;
        this.modalService.subscribeOnClose(function () {
            if (dialog.dialogResult) {
                _this.loadSubsection();
            }
        });
    };
    EmployeeSectionsLeaveManagementComponent.prototype.updateUserRights = function () {
        this.managementService.canAddEdit = this.leaveManagement.canAddEdit;
        this.managementService.canDelete = this.leaveManagement.canDelete;
    };
    EmployeeSectionsLeaveManagementComponent.prototype.loadSubsection = function () {
        var _this = this;
        if (!this.empLeaveManagement) {
            return;
        }
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmployeeLeaveManagement(this.employeeId)
            .then(function (data) {
            _this.leaveManagement = data;
            _this.updateUserRights();
            _this.refreshGrid();
            _this.stopProgress();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        }).catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsLeaveManagementComponent.prototype.refreshGrid = function () {
        if (!this.empLeaveManagement) {
            return;
        }
        this.empLeaveManagement.records.map(function (each) {
            each.daysOnLoaText = each.isIntermittent ? '' : String(each.daysOnLoa);
        });
        this.gridState.view = process(this.empLeaveManagement.records, this.gridState.state);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeSectionsLeaveManagementComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsLeaveManagementComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsLeaveManagementComponent.prototype, "orgLevelSubscription", void 0);
    return EmployeeSectionsLeaveManagementComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsLeaveManagementComponent };
