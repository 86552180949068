export class DetailsAgencyPosition {
    public map_id: number;
    public position_name: string;
    public partner_position_name: string;
  }

  export class IDetailsAgencyPosition {
    public map_id: number;
    public position_name: string;
    public partner_position_name: string;
  }