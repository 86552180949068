import { EmpBeneficiaryRelation, IEmpBeneficiaryRelation } from './beneficiary-relation';

export interface IBeneficiary {
    id: number;
    empToBenefitsId: number;
    firstName: string;
    secondName: string;
    lastName: string;
    address: string;
    relation: IEmpBeneficiaryRelation;
    birthDate: string;
    ssn: string;
    primaryPercentage: number;
    contigentPercentage: number;
    addedDate: string;
    addedBy: string;
    modifiedDate: string;
    modifiedBy: string;
    isDeleted: boolean;
}

export class Beneficiary {
    
    public get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }


    public id: number;
    public empToBenefitsId: number;
    public firstName: string;
    public secondName: string;
    public lastName: string;
    public address: string;
    public relation: EmpBeneficiaryRelation;
    public birthDate: Date;
    public ssn: string;
    public primaryPercentage: number;
    public contigentPercentage: number;
    public addedDate: Date;
    public addedBy: string;
    public modifiedDate: Date;
    public modifiedBy: string;
    public isDeleted: boolean;

    public isPrimary: boolean;
}

