import { ElementRef, AfterContentInit } from '@angular/core';
import { Validators, NgModel } from '@angular/forms';
import { CommonValidators } from '../../validators/common-validators';
import * as _ from 'lodash';
//TODO remove after sections refactoring
var FieldPolicyDirective = /** @class */ (function () {
    function FieldPolicyDirective(elementRef) {
        this.elementRef = elementRef;
        this.staticValidators = [];
    }
    Object.defineProperty(FieldPolicyDirective.prototype, "fieldPolicy", {
        set: function (policy) {
            if (!policy) {
                return;
            }
            this.setNativePolicies(policy);
        },
        enumerable: true,
        configurable: true
    });
    FieldPolicyDirective.prototype.ngAfterContentInit = function () {
        var _this = this;
        var m = this.model;
        this.staticValidators = [];
        _.forEach(m._rawValidators, function (v) {
            _this.staticValidators.push(v);
        });
    };
    FieldPolicyDirective.prototype.setNativePolicies = function (policy) {
        var validators = [];
        _.forEach(this.staticValidators, function (v) {
            validators.push(v);
        });
        if (!this.elementRef || !this.elementRef.nativeElement) {
            return;
        }
        if (policy.isEditable) {
            this.elementRef.nativeElement.removeAttribute('readonly');
        }
        else {
            this.elementRef.nativeElement.setAttribute('readonly', true);
        }
        if (policy.isRequired) {
            this.elementRef.nativeElement.setAttribute('required', true);
            validators.push(Validators.required);
        }
        else {
            this.elementRef.nativeElement.removeAttribute('required');
        }
        if (policy.minValue) {
            validators.push(CommonValidators.min(policy.minValue));
        }
        if (policy.maxValue) {
            validators.push(CommonValidators.max(policy.maxValue));
        }
        this.model.control.setValidators(null);
        if (validators.length > 0) {
            this.model.control.setValidators(validators);
        }
    };
    return FieldPolicyDirective;
}());
export { FieldPolicyDirective };
