<slx-spinner [show]="isLoading">
  <form #form="ngForm" novalidate class="slx-full-height">
 
    <div class="modal-body with-spinner" id="enroll-modal-body">
      <div class="enroll-container">
      <div class="enroll-toolbar-indents">
        <div class="bene-toolbar__control as-flex-start mt-5">
          <strong>Employees Selected : <span class="selected-count">{{selectedEmployees.length}}</span></strong>
        </div>
        <div class="bene-toolbar__control bene-space-toolbar__control as-flex-start mt-5">
          <strong>Effective Date : </strong> <strong
            title="The Effective Date is the As of Date from the plan details page. If you would like the Effective Date to be something other than what is displayed here, close this window and change the As of Date.">
            {{date | amDateFormat: appConfig.dateFormat }}</strong>
        </div>
        <div *ngIf="canMapPayroll && enableBenefitDeduction" class="bene-toolbar__control bene-space-toolbar__control as-flex-start">
          <label class="control-label">Deduction Start Date : </label>
          <div class="flex control-width"> 
            <slx-datepicker class="slx-wide" 
            #dedStartDatePicker="ngModel"
            name="DedStartDate" 
            [minDate]="minDedDate"
            [maxDate]="maxDedDate" 
            [(ngModel)]="benfitPayrollDeductionDates.payrollDeductionStartDate"
            (ngModelChange)="onChangeStartDate()">
            </slx-datepicker>
            <span *ngIf="dedStartDatePicker?.errors" class="slx-error-block">
                <span *ngIf="dedStartDatePicker?.errors?.required" errorMessage for="required"></span>
                <span *ngIf="dedStartDatePicker?.errors?.date" errorMessage for="date"></span>
                <span *ngIf="dedStartDatePicker?.errors?.maxDate" errorMessage for="maxDate"> The value should be less than {{ maxDedDate | amDateFormat: appConfig.dateFormat }}</span>
                <span *ngIf="dedStartDatePicker?.errors?.minDate" errorMessage for="minDate">Deduction Start Date cannot be before the Deduction Start Date on the Deduction Master List in Payroll {{ minDedDate | amDateFormat: appConfig.dateFormat }}</span>
            </span>
          </div>
          <div *ngIf="form.valid && isDedBeforeBeneStartDt && canMapPayroll" class="warning-message">
            {{ warningBeforeStart }}
          </div>
        </div>
        <div *ngIf="canMapPayroll && enableBenefitDeduction" class="bene-toolbar__control bene-space-toolbar__control as-flex-start">
          <label class="control-label">Deduction End Date : </label>
          <div class="control-width">
            <slx-datepicker class="bene-date"
            #dedEndDatePicker="ngModel"
            name="DedEndDate" 
            [minDate]="benfitPayrollDeductionDates.payrollDeductionStartDate"
            [maxDate]="maxDedDate" 
            [required]="true"
            [(ngModel)]="benfitPayrollDeductionDates.payrollDeductionEndDate"
            (ngModelChange)="onChangeEndDate()">
            </slx-datepicker>
            <span *ngIf="dedEndDatePicker?.errors" class="slx-error-block">
              <span *ngIf="dedEndDatePicker?.errors?.required" errorMessage for="required"></span>
              <span *ngIf="dedEndDatePicker?.errors?.date" errorMessage for="date"></span>
              <span *ngIf="dedEndDatePicker?.errors?.maxDate" errorMessage for="maxDate">Deduction End Date cannot be after the Deduction End Date on the Deduction Master List in Payroll {{ maxDedDate | amDateFormat: appConfig.dateFormat }}</span>
            </span>
          </div>
          <div *ngIf="form.valid && isDedAfterBeneEndDt && canMapPayroll" class="warning-message">
          {{ warningAfterEnd }}
          </div>
        </div>
      </div>
   

        <div class="benefit-options flex left">

          <div class="providers-tabs__slider">
            <ul #list class="providers-tabs__list flex flex-direction-row">
              <li class="providers-tabs__item" [class.active]="isActiveMenu(0)" (click)="onClickTab(0)">
                <span class="header-title__text">Choose Options</span>
              </li>
              <li class="providers-tabs__item" [class.active]="isActiveMenu(1)" (click)="onClickTab(1)">
                <span class="header-title__text">Option Details
                </span>
              </li>
              <li class="providers-tabs__item" [class.active]="isActiveMenu(2)" (click)="onClickTab(2)">
                <span class="header-title__text">Notes</span>
              </li>
            </ul>
          </div>

          <ng-container *ngIf="isOptions">
            <benefit-option-options-grid class="enroll-page" *ngIf="calcMethod.isOptionsRates" [benefitOptionData]="tierDetails.options"
              [selectedEmployees]="selectedEmployees" (onEnrollmentCancel)="onCancel(false)">
            </benefit-option-options-grid>
            <benefit-option-formula-grid class="enroll-page"  *ngIf="calcMethod.isFormula" [selectedEmployees]="selectedEmployees"
              [tierDetails]="tierDetails" (onEnrollmentCancel)="onCancel(false)"
              (showCalculate)="hasCoverageChanges($event)" [date]="date">
            </benefit-option-formula-grid>
            <benefit-option-flat-grid class="enroll-page" *ngIf="calcMethod.isFlat" [selectedEmployees]="selectedEmployees"
                                      [tierDetails]="tierDetails"></benefit-option-flat-grid>
            <benefit-option-401k-grid class="enroll-page" *ngIf="calcMethod.is401K" [selectedEmployees]="selectedEmployees"
              [tierDetails]="tierDetails" (onEnrollmentCancel)="onCancel($event)"></benefit-option-401k-grid>
            <benefit-option-formula-with-option-grid class="enroll-page" *ngIf="calcMethod.isFormulaWithOption" [selectedEmployees]="selectedEmployees"
                                         [tierDetails]="tierDetails" [benefitOptionData]="tierDetails.options" (onEnrollmentCancel)="onCancel(false)" [date]="date">
            </benefit-option-formula-with-option-grid>
          </ng-container>

          <ng-container *ngIf="isOptionDetails">
            <benefit-option-details-options-grid class="slx-full-height" *ngIf="calcMethod.isOptionsRates"
              [benefitOptionData]="tierDetails.options"></benefit-option-details-options-grid>
            <benefit-option-details-formula-grid class="slx-full-height" *ngIf="calcMethod.isFormula" [tierDetails]="tierDetails">
            </benefit-option-details-formula-grid>
            <benefit-option-detail-flat-grid class="slx-full-height" *ngIf="calcMethod.isFlat" [tierDetails]="tierDetails">
            </benefit-option-detail-flat-grid>
            <benefit-option-details-401k-grid class="slx-full-height" *ngIf="calcMethod.is401K" [benefitOptionData]="tierDetails.options">
            </benefit-option-details-401k-grid>
            <benefit-option-details-formula-with-option-grid class="slx-full-height" *ngIf="calcMethod.isFormulaWithOption" [benefitOptionData]="tierDetails.options" [tierDetails]="tierDetails">
            </benefit-option-details-formula-with-option-grid>
          </ng-container>

          <ng-container *ngIf="isNotes">
            <textarea [(ngModel)]="notesText" name="notesText"  class="ben-notes slx-full-height" maxlength="2500">
            </textarea>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="modal-footer">
        <span id="enroll-button" class="footer-btn-holder" [ngClass]="{'hide_item_tip':hasValidPlan}">
          <button type="button" (click)="calculateOrEnroll()" class="btn green"
            [disabled]="hasError || !hasValidPlan || form.invalid"
            title="{{ showCalculate ? 'Click to calculate Coverage' : '' }}">
            <i *ngIf="!showCalculate" class="fas fa-save slx-button__icon" aria-hidden="true"></i>
            <i *ngIf="showCalculate" class="fas fa-calculator slx-button__icon" aria-hidden="true"></i>
            {{ showCalculate ? 'Calculate' : 'Save' }}
          </button>
        </span>
        <button type="button" class="btn red" (click)="onCancel(true);"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
    </div>
  </form>
</slx-spinner>
