import * as tslib_1 from "tslib";
import { BIPAReportDefinition, BIPAUnitShiftData, ConfigForceLoad, ReportDefinition } from '../../models';
import { ModalService } from '../../../common';
import { BipaReportDialogComponent } from '../../components/bipa-report-dialog/bipa-report-dialog.component';
import { mutableSelect, unsubscribe } from './../../../../app/core/decorators';
import { Observable, Subscription } from 'rxjs';
import { ReportsApiService } from '../../services/reports-api.service';
import * as _ from 'lodash';
import { NotificationsService } from './../../../../app/core/components';
var BIPAReportDirective = /** @class */ (function () {
    function BIPAReportDirective(modalService, reportApi, notificationService) {
        var _this = this;
        this.modalService = modalService;
        this.reportApi = reportApi;
        this.notificationService = notificationService;
        this.configForceLoad = null;
        this.configForceLoad = new ConfigForceLoad();
        this.configForceLoad.dailyStaffingPosting = false;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (data) {
            _this.orgLevelId = data.id;
        });
    }
    BIPAReportDirective.prototype.onClick = function (e) {
        if (this.slxBIPAReport.reportConfigId) {
            this.getApiData();
        }
    };
    BIPAReportDirective.prototype.getApiData = function () {
        var _this = this;
        // To Be Done once API work completed
        var gridData = new BIPAReportDefinition();
        this.reportApi.getDailyStaffingSettings(this.orgLevelId, true).then(function (data) {
            if (data) {
                gridData.unitShiftData = [data.showUnitsOnBipaReportValue, data.showShiftsOnBipaReportValue];
                gridData.shiftGroupTimingS = _this.setShiftGroup(data.shiftgroups);
                gridData.gridData = _this.setGridData(data.slxCmsPositions);
                BipaReportDialogComponent.openDialog(gridData, _this.configForceLoad, _this.modalService, function (isSave, result) {
                    if (isSave) {
                        _this.configForceLoad.dailyStaffingPosting = true;
                        var data_1 = new BIPAUnitShiftData();
                        data_1.showUnitsOnBipaReportKey = 'ShowUnitsOnBipaReport';
                        data_1.showUnitsOnBipaReportValue = "" + result.unitShiftData[0];
                        data_1.showShiftsOnBipaReportKey = 'ShowShiftsOnBipaReport';
                        data_1.showShiftsOnBipaReportValue = "" + result.unitShiftData[1];
                        _this.reportApi.postDailyStaffingSettings(_this.orgLevelId, data_1).then(function (data) {
                            if (data.data === 'Settings Saved successfully') {
                                _this.notificationService.success('Success', data.data);
                            }
                        });
                    }
                    else {
                        _this.configForceLoad.dailyStaffingPosting = false;
                    }
                });
            }
        });
    };
    BIPAReportDirective.prototype.setShiftGroup = function (data) {
        var dataOutput = [];
        if (data.length > 0) {
            _.forEach(data, function (item) {
                if (item.shiftType === 'Day') {
                    dataOutput.push(item.shiftStartTime);
                }
                if (item.shiftType === 'Evening') {
                    dataOutput.push(item.shiftStartTime);
                }
                if (item.shiftType === 'Night') {
                    dataOutput.push(item.shiftStartTime);
                }
            });
        }
        return dataOutput;
    };
    BIPAReportDirective.prototype.setGridData = function (data) {
        var outputData = [];
        if (data.length > 0) {
            _.map(data, function (item) {
                outputData.push({
                    smartlinxPosition: item.slxPosition,
                    cmsJobDescription: item.cmsJobDescription
                });
            });
        }
        return outputData;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BIPAReportDirective.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BIPAReportDirective.prototype, "orgLevelSubscription", void 0);
    return BIPAReportDirective;
}());
export { BIPAReportDirective };
