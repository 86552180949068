import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MessagesMappingService } from '../services/messages-mapping.service';

let date1 = new Date();
let date2 = new Date();
date1.setDate(date1.getDate() + 1);
date2.setDate(date2.getDate() + 2);

export interface Message {
  benefitGroupName: any;
  endDate: any;
  id: number;
  createdAt: any;
  modifiedBy: any;
  name: any;
  phone: any;
  photoPresent: boolean;
}

let mockData: Message = {
  benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
  endDate: new Date(),
  id: 132,
  createdAt: moment(date1).format('YYYY-MM-DD'),
  modifiedBy: 'system',
  name: 'Abhishek',
  phone: '(000)-123 4567 890',
  photoPresent: false
};

let mockData1: Message = {
  benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
  endDate: new Date(),
  id: 132,
  createdAt: moment(date2).format('YYYY-MM-DD'),
  modifiedBy: 'system',
  name: 'Selvendran',
  phone: '(000)-999 9999 999',
  photoPresent: false
};

let mockData2: Message = {
  benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
  endDate: new Date(),
  id: 132,
  createdAt: moment(new Date()).format('YYYY-MM-DD'),
  modifiedBy: 'system',
  name: 'Abhishek',
  phone: '(000)-123 4567 890',
  photoPresent: false
};

let mockData3: Message = {
  benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  id: 132,
  createdAt: moment(new Date()).format('YYYY-MM-DD'),
  modifiedBy: 'system',
  name: 'Abhishek',
  phone: '(000)-123 4567 890',
  photoPresent: false
};

let mockData4: Message = {
  benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  id: 132,
  createdAt: moment(new Date()).format('YYYY-MM-DD'),
  modifiedBy: 'system',
  name: 'Abhishek',
  phone: '(000)-123 4567 890',
  photoPresent: false
};

let mockData5: Message = {
  benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  id: 132,
  createdAt: moment(new Date()).format('YYYY-MM-DD'),
  modifiedBy: 'system',
  name: 'Ashok',
  phone: '(000)-123 4567 890',
  photoPresent: true
};

let mockData6: Message = {
  benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  id: 132,
  createdAt: moment(new Date()).format('YYYY-MM-DD'),
  modifiedBy: 'system',
  name: 'Anil',
  phone: '(000)-123 4567 890',
  photoPresent: false
};

let mockData7: Message = {
  benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  id: 132,
  createdAt: moment(new Date()).format('YYYY-MM-DD'),
  modifiedBy: 'system',
  name: 'Doolchand',
  phone: '(000)-555 4567 890',
  photoPresent: false
};

let mockData8: Message = {
  benefitGroupName: 'Open Shift Opportunity For position: CNA this rest will be ellipsis',
  endDate: moment(new Date()).format('YYYY-MM-DD'),
  id: 132,
  createdAt: moment(new Date()).format('YYYY-MM-DD'),
  modifiedBy: 'system',
  name: 'Pavel',
  phone: '(000)-123 4567 890',
  photoPresent: false
};

const data = [mockData, mockData1, mockData2, mockData3, mockData4, mockData5, mockData6, mockData7, mockData8];

@Injectable({
  providedIn: 'root'
})
export class MessagesApiService {

  constructor(private mapService: MessagesMappingService) { }

  public async getSMSMessagesInfo(orgLevelId?: number, tierId?: number, employee?: any): Promise<any> {
    return data; // this.mapService.mapToSmsMessagesInfo(data);
  }
}
