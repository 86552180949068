import { EventEmitter } from '@angular/core';
import * as _ from 'lodash';
var AutocompleteComboboxInputComponent = /** @class */ (function () {
    function AutocompleteComboboxInputComponent() {
        this.tabindex = 0;
        this.placeholder = 'Start input...';
        this.createIfNotFound = false;
        this.caseSensitive = false;
        this.popupSettings = {
            popupClass: 'slx-autocomplete'
        };
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.titleField = 'name';
        this.valueChanged = new EventEmitter();
    }
    Object.defineProperty(AutocompleteComboboxInputComponent.prototype, "options", {
        set: function (items) {
            this.items = items;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComboboxInputComponent.prototype, "items", {
        get: function () {
            return this.m_items;
        },
        set: function (vals) {
            var _this = this;
            this.m_items = vals;
            this.dataItems = _.map(this.m_items, function (item) { return item[_this.titleField]; });
            this.internalValue = this.selectedValue ? this.selectedValue[this.titleField] : null;
            if (this.internalValue) {
                this.selectedValue = this.findValue(this.internalValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteComboboxInputComponent.prototype.writeValue = function (value) {
        if (value) {
            if (typeof value !== 'string') {
                var title = value[this.titleField];
                this.internalValue = title ? title : null;
            }
            else {
                this.internalValue = value;
            }
        }
        else {
            this.internalValue = null;
        }
        if (this.m_items) {
            this.selectedValue = this.findValue(this.internalValue);
        }
        else {
            this.selectedValue = value;
        }
    };
    AutocompleteComboboxInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = function (value) {
            Promise.resolve(null).then(function () {
                return fn(value);
            });
        };
    };
    AutocompleteComboboxInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    AutocompleteComboboxInputComponent.prototype.change = function (selectChange) {
        this.internalValue = selectChange ? selectChange.trim() : null;
        var value = this.findValue(selectChange);
        if (!value) {
            if (this.createIfNotFound && this.internalValue) {
                var obj = {};
                obj[this.titleField] = selectChange;
                value = obj;
            }
            else {
                this.internalValue = value = null;
            }
        }
        this.onChangeCallback(value);
        this.valueChanged.emit(this.internalValue);
    };
    AutocompleteComboboxInputComponent.prototype.findValue = function (strVal) {
        var _this = this;
        var value;
        if (_.isNil(strVal) || _.isNaN(strVal))
            return null;
        if (this.caseSensitive) {
            value = _.find(this.items, function (v) { return v[_this.titleField] === strVal; });
        }
        else {
            strVal = strVal.toLowerCase();
            value = _.find(this.items, function (v) {
                var str = v[_this.titleField];
                if (str) {
                    str = str.toLowerCase();
                }
                return str === strVal;
            });
        }
        return value;
    };
    return AutocompleteComboboxInputComponent;
}());
export { AutocompleteComboboxInputComponent };
