<div class="inactivity-container">
  <div class="notification-header">
    <div class="col-xs-9 theme-md-text">Inactivity Warning</div>
    <div class="col-xs-3">{{remainingTime}}</div>
    <hr>
  </div>
  <div class="notification-body">
    <span class="theme-sm-text">You have been inactive for {{inactivityThresholdMinutes}} minute(s). For security reasons, you will be logged out in {{timeoutAfterMinutes}}
      minute(s) if you remain inactive.</span>
    <span class="theme-sm-text bold-message-text">Click anywhere to reset your session.</span>
  </div>
  <div class="col-xs-12">
    <button type="button" class="theme-button-apply pull-right">I'm here</button>
  </div>
</div>
