import { screenUtils } from './../../../../common/utils/screenUtils';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateResetTypes } from '../../../../core/models/settings/index';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { employeeListConfig } from '../../../../employee/employee-list/employee-list.config';
var SELECTED_EMP_ID = 'selectedEmpId';
var DailyUnitEmployeesComponent = /** @class */ (function () {
    function DailyUnitEmployeesComponent(domSanitizer, router, activatedRoute, stateManagement, storageService) {
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.isEmptyExists = false;
        this.domSanitizer = domSanitizer;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.onEmployeeDelete = new EventEmitter();
        this.onFillShift = new EventEmitter();
        this.onReplaceEmployee = new EventEmitter();
        this.onShiftOpen = new EventEmitter();
        this.onSwitchEmployeeShift = new EventEmitter();
        this.onOpenIndividual = new EventEmitter();
        this.onNeedScroll = new EventEmitter();
        this.restrcitedMobileFields = ['EmpName', 'DepartmentName', 'ShiftName', 'UnitName'];
        this.displayFileds = ['EmpName', 'Hours', 'JobDescription', 'SchedHours', 'ShiftName', 'UnitName'];
        this.restoreSelectedEmp();
    }
    Object.defineProperty(DailyUnitEmployeesComponent.prototype, "rows", {
        set: function (value) {
            this.m_originalRows = value;
            this.refreshView();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyUnitEmployeesComponent.prototype, "showEmptySlots", {
        get: function () {
            return this.m_showEmptySlots;
        },
        set: function (value) {
            this.m_showEmptySlots = value;
            this.refreshView();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyUnitEmployeesComponent.prototype, "showOvertimes", {
        get: function () {
            return this.m_showOvertimes;
        },
        set: function (value) {
            this.m_showOvertimes = value;
            this.refreshView();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyUnitEmployeesComponent.prototype, "showMessages", {
        get: function () {
            return this.m_showMessages;
        },
        set: function (value) {
            this.m_showMessages = value;
            this.refreshView();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyUnitEmployeesComponent.prototype, "showOutOfPar", {
        get: function () {
            return this.m_showOutOfPar;
        },
        set: function (value) {
            this.m_showOutOfPar = value;
            this.refreshView();
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitEmployeesComponent.prototype.removeEmployee = function (row) {
        this.onEmployeeDelete.emit(row);
    };
    DailyUnitEmployeesComponent.prototype.switchEmployee = function (row) {
        this.selectEmployee(row);
        this.onSwitchEmployeeShift.emit(row);
    };
    DailyUnitEmployeesComponent.prototype.getEmployeeId = function (row) {
        return row.getFieldValue(employeeListConfig.employeeIdentifierName);
    };
    DailyUnitEmployeesComponent.prototype.isEmptyEmployee = function (row) {
        return row.isEmpty;
    };
    DailyUnitEmployeesComponent.prototype.getFieldValue = function (row, column) {
        var field = _.find(row.fields, function (f) { return f.name === column.name; });
        return field ? field.value : '';
    };
    DailyUnitEmployeesComponent.prototype.fillShift = function (row) {
        this.onFillShift.emit(row);
    };
    DailyUnitEmployeesComponent.prototype.replaceEmployee = function (row) {
        this.onReplaceEmployee.emit(row);
    };
    DailyUnitEmployeesComponent.prototype.openIndividual = function (row) {
        this.onOpenIndividual.emit(row);
        this.selectEmployee(row);
    };
    DailyUnitEmployeesComponent.prototype.openShift = function (row) {
        this.onShiftOpen.emit(row);
        this.selectEmployee(row);
    };
    DailyUnitEmployeesComponent.prototype.isInPar = function (row) {
        return !row.isOutOfPar;
    };
    DailyUnitEmployeesComponent.prototype.isOvertime = function (row) {
        return row.isOvertime;
    };
    DailyUnitEmployeesComponent.prototype.getMessageCount = function (row) {
        return row.messageCount;
    };
    DailyUnitEmployeesComponent.prototype.selectEmployee = function (row) {
        var empId = +this.getEmployeeId(row);
        this.storageService.setControlState(this.stateManagement.componentKey, SELECTED_EMP_ID, { value: empId }, StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange);
    };
    DailyUnitEmployeesComponent.prototype.isSelectedEmployee = function (row) {
        return this.selectedEmpId && +this.getEmployeeId(row) === this.selectedEmpId;
    };
    DailyUnitEmployeesComponent.prototype.onTRCreated = function (item) {
        this.onNeedScroll.emit(item.offsetTop + item.offsetParent.offsetTop);
    };
    DailyUnitEmployeesComponent.prototype.getRemoveTip = function (row) {
        return this.isOvertime(row) ? 'Projected for OT' : 'Remove Employee';
    };
    Object.defineProperty(DailyUnitEmployeesComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitEmployeesComponent.prototype.restoreSelectedEmp = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, SELECTED_EMP_ID);
        if (state.value > 0) {
            this.selectedEmpId = state.value;
        }
    };
    DailyUnitEmployeesComponent.prototype.refreshView = function () {
        this.filteredRows = this.filterRows(this.m_originalRows);
    };
    DailyUnitEmployeesComponent.prototype.filterRows = function (rows) {
        var _this = this;
        if (!this.showEmptySlots && !this.showMessages && !this.showOutOfPar && !this.showOvertimes) {
            return rows.concat([]);
        }
        var filteredRows = [];
        _.forEach(rows, function (row) {
            var rowToAdd = undefined;
            if (_this.showEmptySlots && row.isEmpty)
                rowToAdd = row;
            if (!rowToAdd && _this.showMessages && row.messageCount > 0)
                rowToAdd = row;
            if (!rowToAdd && _this.showOutOfPar && row.isOutOfPar && !row.isEmpty)
                rowToAdd = row;
            if (!rowToAdd && _this.showOvertimes && row.isOvertime)
                rowToAdd = row;
            if (rowToAdd) {
                filteredRows.push(rowToAdd);
            }
        });
        return filteredRows;
    };
    DailyUnitEmployeesComponent.inParFieldName = 'InPar';
    DailyUnitEmployeesComponent.isOvertimeFieldName = 'IsOvertime';
    DailyUnitEmployeesComponent.messageCountFieldName = 'MessageCount';
    return DailyUnitEmployeesComponent;
}());
export { DailyUnitEmployeesComponent };
