export interface IPBJExportLogCensusData {
  exportId: number;
  organizationId: number;
  monthEndDate: string;
  medicaid: number;
  medicare: number;
  other: number;
}

export class PBJExportLogCensusData {
  public exportId: number;
  public organizationId: number;
  public monthEndDate: Date;
  public medicaid: number;
  public medicare: number;
  public other: number;
}

