<div class="ta-console flex-col">

  <div class="header slx-content-toolbar-indents">
    <slx-ta-console-toolbar
      [dateNow]="dateNow" [dateOn]="selectedDate"
      [timecardExceptionCount]="timecardExceptionCount"
      [missingPunchesCount]="missingPunchesCount"
      (onDateChanged)="onFilterDateChanged($event)"
      (onNavigateToMissingPunches)="navigateToPunchMissing()"
      (onNavigateToExceptions)="navigateToTimeException()"
    ></slx-ta-console-toolbar>
  </div>

  <div class="body">
    <div class="flex-row">
      <div class="widget-host">
        <slx-arrivals-widget [dateOn]="selectedDate"></slx-arrivals-widget>
      </div>
      <div class="widget-host">
        <slx-departures-widget [dateOn]="selectedDate"></slx-departures-widget>
      </div>
    </div>
      <div class="main-content">
        <slx-arrivals-departures-timeline-details [orgLevelId]="orgLevelId" [date]="selectedDate"></slx-arrivals-departures-timeline-details>
      </div>
  </div>
</div>
