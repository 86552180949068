<slx-spinner [show]="state.isLoading">
    <div class="main-buttons-container">
        <button type="button" *ngIf="!addMode  && userProfile?.isActive" class="theme-button-apply" [disabled]="state?.hasEditingSections" (click)="disableUser ()">Disable User</button>
        <button type="button" *ngIf="!addMode && !userProfile?.isActive" class="theme-button-apply" (click)="activateUser ()">Activate User</button>
        <button type="button" *ngIf="addMode" class="theme-button-apply" [disabled]="!profileSectionValid || !rolesSection?.isValid" (click)="onAddUser()">Add User</button>
    </div>

    <slx-collapsible-panel #profilePanel title="General" [collapsed]="false" [locked]="state.infoLocked">
        <div class="panel-header">
            <slx-user-profile-panel-header [addNewUserMode]="addMode" [disableActions]="!userProfile?.isActive || !userProfile?.isEditable || userProfile?.isNew || addRolesMode"
            [panel]="profilePanel" [section]="profileSection"></slx-user-profile-panel-header>
        </div>
        <div class="panel-content" title="">
            <slx-user-profile-section-profile #profileSection (validStateUpdated)="profileValidChange ($event)"></slx-user-profile-section-profile>
        </div>
    </slx-collapsible-panel>

    <slx-collapsible-panel #rolesPanel title="Role Assignment" [collapsed]="false" [locked]="state.rolesLocked">
        <div class="panel-header">
            <slx-user-profile-panel-header [addNewUserMode]="addMode" [disableActions]="!userProfile?.isActive || userProfile?.isNew || addRolesMode"
            [panel]="rolesPanel" [forRolesSection]="true" [section]="rolesSection"></slx-user-profile-panel-header>
        </div>
        <div class="panel-content">
            <slx-user-profile-section-roles #rolesSection></slx-user-profile-section-roles>
        </div>
    </slx-collapsible-panel>

</slx-spinner>
