import { IOrganization, Organization } from '../../../organization/models/lookup/index';

export interface ITimeclockDailySummary {
    id: number;
    name: string;
    isVirtual: boolean;
    organization: IOrganization;
    enrolledCount: number;
    assignedCount: number;
    lastCommunicationDate: Date;
    dailyRestrictionsPercent: number;
    dailyFPErrorsPercent: number;
    communicationStatus: number;
    rebootStatus:string;
    isTimeClockRebootStarted: boolean;
}

export class TimeclockDailySummary {
    public id: number;
    public name: string;
    public isVirtual: boolean;
    public organization: Organization;
    public enrolledCount: number;
    public assignedCount: number;
    public lastCommunicationDate: Date;
    public dailyRestrictionsPercent: number;
    public dailyFPErrorsPercent: number;
    public communicationStatus: number;
    public rebootStatus: string;
    public get sortName(): string {
        return this.name ? this.name.toLowerCase() : '';
    }
    public isTimeClockRebootStarted: boolean;
}
