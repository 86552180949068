import { Component, OnInit } from '@angular/core';
import { NotificationsApiService } from '../../services/index';
import { mutableSelect } from '../../../core/decorators/redux-decorators';
import { unsubscribe } from '../../../core/decorators/unsubscribe-decorator';
import { IUser } from '../../../authentication/store/session/session.types';
import { MessageModel } from '../../models/index';
@Component({
    moduleId: module.id,
    selector: 'slx-header-messages-list',
    templateUrl: 'header-messages-list.component.html',
    styleUrls: [
        'header-messages-list.component.scss'
    ]
})

export class HeaderMessagesListComponent implements OnInit {

    public messages: MessageModel[];
    public isLoading: boolean;

    constructor(private api: NotificationsApiService) { }

    public ngOnInit(): void {
        this.isLoading = true;
        this.api.getMessages().then((result: any[]) => {
            this.messages = result;
            this.isLoading = false;
        });
    }
}
