import * as _ from 'lodash';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
var MultiselectComponent = /** @class */ (function () {
    function MultiselectComponent() {
        this.valuePrimitive = false;
        this.valueField = 'id';
        this.titleField = 'name';
        this.label = 'Filtering';
        this.virtual = {
            itemHeight: 28
        };
        this.onChangeCallback = _.noop;
        this.onTouchedCallback = _.noop;
        this.needRestore = false;
        this.isOpen = false;
        this.staticPopup = false;
        this.unifiedOptionValue = -1;
    }
    Object.defineProperty(MultiselectComponent.prototype, "options", {
        get: function () {
            return this.m_options;
        },
        set: function (options) {
            if (!_.isArray(options)) {
                this.m_options = [];
            }
            else {
                this.m_options = options;
            }
            this.originalOptions = _.cloneDeep(this.m_options);
            this.updateMultiselect(true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiselectComponent.prototype, "staticOpened", {
        set: function (value) {
            if (_.isBoolean(value)) {
                this.staticPopup = true;
                if (this.kendoMultiselect && this.kendoMultiselect.toggle) {
                    this.kendoMultiselect.toggle(value);
                    if (value) {
                        this.onOpen();
                    }
                    else {
                        this.close();
                    }
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MultiselectComponent.prototype, "value", {
        get: function () {
            return this.m_value;
        },
        set: function (value) {
            if (!_.isArray(value)) {
                this.m_value = [];
            }
            else {
                this.m_value = value;
            }
            this.onChangeCallback(this.m_value);
            this.updateMultiselect(false);
        },
        enumerable: true,
        configurable: true
    });
    MultiselectComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    MultiselectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    MultiselectComponent.prototype.writeValue = function (value) {
        if (!_.isArray(value)) {
            this.m_value = [];
            return;
        }
        this.m_value = value;
    };
    MultiselectComponent.prototype.onFilterChange = function (userQuery) {
        var _this = this;
        if (userQuery.length > 0) {
            var options_1 = [];
            _.forEach(this.originalOptions, function (opt) {
                var optionText = String(opt[_this.titleField]);
                if (_.includes(optionText.toLowerCase(), userQuery.toLowerCase())) {
                    options_1.push(_.cloneDeep(opt));
                }
            });
            this.m_options = options_1;
            this.needRestore = true;
        }
        else {
            this.restoreOptionList();
        }
    };
    MultiselectComponent.prototype.onOpen = function () {
        var _this = this;
        if (this.value.length > 0) {
            var values_1 = _.map(this.value, function (option) { return option[_this.valueField]; });
            var mainOptions_1 = [];
            var otherOptions_1 = [];
            _.forEach(this.m_options, function (option) {
                if (_.indexOf(values_1, option[_this.valueField]) !== -1) {
                    mainOptions_1.push(option);
                }
                else {
                    otherOptions_1.push(option);
                }
            });
            mainOptions_1.push.apply(mainOptions_1, otherOptions_1);
            this.m_options = mainOptions_1;
            this.needRestore = true;
        }
        else {
            this.restoreOptionList();
        }
        this.isOpen = true;
    };
    MultiselectComponent.prototype.onClose = function (event) {
        if (this.staticPopup) {
            event.preventDefault();
            return;
        }
        this.close();
    };
    MultiselectComponent.prototype.getSelectedOptionsMapper = function () {
        var _this = this;
        return function (selectedOptions) {
            if (_.isArray(selectedOptions) && selectedOptions.length > 0) {
                return _this.createUnifiedOption(selectedOptions.length);
            }
            return [];
        };
    };
    MultiselectComponent.prototype.onRemoveUnifiedOption = function () {
        this.updateMultiselect(true);
        this.needRestore = true;
        this.restoreOptionList();
    };
    MultiselectComponent.prototype.close = function () {
        this.restoreOptionList();
        this.isOpen = false;
    };
    MultiselectComponent.prototype.updateMultiselect = function (isReset) {
        if (this.kendoMultiselect) {
            if (isReset) {
                this.kendoMultiselect.reset();
                this.kendoMultiselect.blur();
            }
            else {
                this.kendoMultiselect.blur();
            }
        }
    };
    MultiselectComponent.prototype.createUnifiedOption = function (amountOfSelected) {
        var _a;
        var option = (_a = {},
            _a[this.valueField] = this.unifiedOptionValue,
            _a[this.titleField] = this.label + " (" + amountOfSelected + ")",
            _a);
        return [option];
    };
    MultiselectComponent.prototype.restoreOptionList = function () {
        if (this.needRestore) {
            this.needRestore = false;
            this.m_options = _.cloneDeep(this.originalOptions);
        }
    };
    return MultiselectComponent;
}());
export { MultiselectComponent };
