import * as tslib_1 from "tslib";
import { TimecardQueueDialogRequest } from './../../models/timecards/unprocessed-timecard-stats';
import { ModalService } from './../../../common/services/modal/modal.service';
import { TimecardQueueDialogComponent } from './../../components/timecards-display/timecard-queue-dialog/timecard-queue-dialog.component';
import { NotificationMessage } from './../../../app.messages';
import { ErrorHandlingService } from './../../../core/services/error-handling/error-handling.service';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/first';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';
import { TimecardsState } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { TimecardsApiService } from './timecards-api.service';
import { StateManagementService, ColumnSettingsStorageService } from '../../../common/services/index';
import { ConstraintsApiService } from '../../../organization/services/index';
import { PayCycleApprovalRequest, TimecardApprovalRequest } from '../../models/timecards/timecards-approval-request';
import { forkJoin } from 'rxjs';
import { AppUserSettingsService } from '../../../common/services/app-user-settings/app-user-settings.service';
var TimecardsDisplayManagementService = /** @class */ (function () {
    function TimecardsDisplayManagementService(constraintService, orgLevelWatchService, stateManagementService, columnSettingsStorageService, errorService, modalService, apiService, appUserSettingsService) {
        this.constraintService = constraintService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.stateManagementService = stateManagementService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.errorService = errorService;
        this.modalService = modalService;
        this.apiService = apiService;
        this.appUserSettingsService = appUserSettingsService;
        this.empColumnsKey = 'empColumns';
        this.payColumnsKey = 'payColumns';
        this.hideShowEmptyTimeCardKey = 'hideEmptyTimeCard';
        this.onActionCmd$ = new Subject();
        this.onExportTimecards$ = new Subject();
        this.onLoadStatus$ = new ReplaySubject(1);
        this.onRecordsSelected$ = new ReplaySubject(1);
        this.onLoaded$ = new ReplaySubject(1);
        this.onStateChanged$ = new ReplaySubject(1);
        this.onOrgLevelChanged$ = new ReplaySubject(1);
        this.lastSelectionState$ = new ReplaySubject(1);
    }
    Object.defineProperty(TimecardsDisplayManagementService.prototype, "componentKey", {
        get: function () {
            return this.stateManagementService.componentKey;
        },
        enumerable: true,
        configurable: true
    });
    TimecardsDisplayManagementService.prototype.init = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.currentOrgLevel || o && _this.currentOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            _this.handleOrgChange(o);
        });
        this.timecardsDisplaySubscription = this.timecardsDisplay$
            .combineLatest(this.orgLevel$, this.stateManagementService.onInit$)
            .subscribe(function (_a) {
            var settings = _a[0], o = _a[1], p = _a[2];
            _this.handleStateChange(settings);
        });
    };
    TimecardsDisplayManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    TimecardsDisplayManagementService.prototype.handleOrgChange = function (o) {
        var _this = this;
        if (!o || !o.id) {
            return;
        }
        if (o.type === OrgLevelType.organization) {
            this.currentOrganizationId = o.relatedItemId;
        }
        else if (o.type === OrgLevelType.department) {
            this.orgLevelWatchService.getOrgLevelByIdSafe(o.parentId)
                .then(function (parent) {
                _this.currentOrganizationId = parent ? parent.relatedItemId : 0;
                _this.currentOrgLevel = o;
                _this.onOrgLevelChanged$.next(o);
            });
            return;
        }
        else {
            this.currentOrganizationId = 0;
        }
        this.currentOrgLevel = o;
        this.onOrgLevelChanged$.next(o);
    };
    TimecardsDisplayManagementService.prototype.handleStateChange = function (settings) {
        var _this = this;
        if (this.timeoutStateChange) {
            clearTimeout(this.timeoutStateChange);
            this.timeoutStateChange = null;
        }
        // Adding this timeout to execute state change functionality only once if its called multiple times within 200 ms
        this.timeoutStateChange = setTimeout(function () {
            _this.settings = settings;
            var state = new TimecardsState();
            _this.state = state;
            state.createEmpColumns();
            _this.columnSettingsStorageService.getColumnsState(_this.componentKey, _this.empColumnsKey, state.empColumns.columns);
            state.empColumns.mapColumns();
            state.flatMode = settings.flatMode;
            state.isShowPayRates = settings.isShowPayRates;
            state.isShowHighPrecision = settings.isShowHighPrecision;
            state.lastViewedEmployee = settings.lastViewedEmployee;
            state.lastViewedPage = settings.lastViewedPage;
            state.lastSelectedEntries = settings.lastSelectedEntries;
            state.gridState = settings.gridState;
            state.selectedEmployeeId = settings.selectedEmployeeId;
            if (_this.container) {
                _this.recreatePayCodeColumns(state, _this.container.usedRulesDefinitions);
            }
            _this.onStateChanged(state);
        }, 200);
    };
    TimecardsDisplayManagementService.prototype.loadTimecardsSummary = function (orgLevelId, payCycle) {
        var _this = this;
        this.onLoadStatusChanged(true);
        var timecardPromise = this.apiService.getTimecardsSummary(orgLevelId, payCycle);
        var isPayrollSubmittedPromise = this.constraintService.isPayrollSubmittedForOrganization(this.currentOrgLevel.id, payCycle.startDate, payCycle.endDate);
        forkJoin([timecardPromise, isPayrollSubmittedPromise])
            .subscribe(function (results) {
            // results[0] will be timecardsSummary
            // results[1] will be the isPayrollSubmitted
            _this.container = results[0];
            _this.container.isPayCycleSubmitted = results[1];
            _this.recreatePayCodeColumns(_this.state, _this.container.usedRulesDefinitions);
            _this.onStateChanged$.first() // await until state will be loaded or take first if already has been loaded
                .subscribe(function (state) {
                _this.onLoaded(_this.container);
                _this.onLoadStatusChanged(false);
            });
        }, function (error) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimecardsDisplayManagementService.prototype.checkTimecardsApproval = function (orgLevel, payCycle) {
        var _this = this;
        var empIds = _.map(this.selectedRecords, function (timecardsEmp) { return timecardsEmp.employeePosition.employee.id; });
        this.apiService.getPendingTimecards(orgLevel.id, payCycle.startDate, payCycle.endDate, empIds)
            .then(function (stats) {
            if (stats.pendingRecords > 0) {
                _this.showTimecardQueueDialog(orgLevel, payCycle, true, empIds, stats);
            }
            else {
                _this.approveTimecards(orgLevel.id, payCycle);
            }
        }).catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimecardsDisplayManagementService.prototype.approveTimecards = function (orgLevelId, payCycle) {
        var _this = this;
        var ids = this.extractIdsForModification('approve');
        if (ids.length === 0)
            return;
        var request = new TimecardApprovalRequest();
        var payCycleReq = new PayCycleApprovalRequest();
        payCycleReq.startDate = dateTimeUtils.convertToDtoString(payCycle.startDate);
        payCycleReq.endDate = dateTimeUtils.convertToDtoString(payCycle.endDate);
        payCycleReq.employeeIds = ids;
        request.payCycles.push(payCycleReq);
        this.onLoadStatusChanged(true);
        this.apiService.approveTimecards(this.currentOrgLevel.id, request)
            .then(function (result) {
            _this.loadTimecardsSummary(orgLevelId, payCycle);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimecardsDisplayManagementService.prototype.unapproveTimecards = function (orgLevelId, payCycle) {
        var _this = this;
        var ids = this.extractIdsForModification('unapprove');
        if (ids.length === 0)
            return;
        this.onLoadStatusChanged(true);
        this.apiService.unapproveTimecards(this.currentOrgLevel.id, ids, this.currentPayCycle)
            .then(function (result) {
            _this.loadTimecardsSummary(orgLevelId, payCycle);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimecardsDisplayManagementService.prototype.recalculateTimecards = function (orgLevelId, payCycle) {
        var _this = this;
        this.onLoadStatusChanged(true);
        var ids = _.map(this.selectedRecords, function (emp) { return emp.employeePosition.employee.id; });
        this.apiService.recalculateTimecards(ids, this.currentPayCycle)
            .then(function (result) {
            _this.loadTimecardsSummary(orgLevelId, payCycle);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimecardsDisplayManagementService.prototype.exportTimecards = function (organizationId, startDate, endDate) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getExportedTimecards(organizationId, startDate, endDate)
            .then(function (file) {
            _this.onExportTimecards$.next(file);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    TimecardsDisplayManagementService.prototype.showTimecardQueueDialog = function (orgLevel, payCycle, approveTimecards, empIds, unprocessedTimecardStats) {
        var _this = this;
        if (approveTimecards === void 0) { approveTimecards = false; }
        if (empIds === void 0) { empIds = null; }
        if (unprocessedTimecardStats === void 0) { unprocessedTimecardStats = null; }
        var request = new TimecardQueueDialogRequest();
        request.orgLevel = orgLevel;
        request.payCycle = payCycle;
        request.approveTimeCards = approveTimecards;
        request.empIds = empIds;
        request.unprocessedTimecardStats = unprocessedTimecardStats;
        TimecardQueueDialogComponent.openDialog(this.modalService, this.apiService, request, function (result) {
            if (result) {
                _this.approveTimecards(orgLevel.id, payCycle);
            }
        });
    };
    TimecardsDisplayManagementService.prototype.onRecordsSelected = function (records) {
        this.selectedRecords = records;
        this.onRecordsSelected$.next(records);
    };
    TimecardsDisplayManagementService.prototype.onStateChanged = function (state) {
        this.state = state;
        if (!this.state) {
            this.state = new TimecardsState();
            this.state.createEmpColumns();
        }
        this.onStateChanged$.next(state);
    };
    TimecardsDisplayManagementService.prototype.onActionCmd = function (cmd) {
        this.onActionCmd$.next(cmd);
    };
    TimecardsDisplayManagementService.prototype.onLoadStatusChanged = function (isLoading) {
        this.onLoadStatus$.next(isLoading);
    };
    TimecardsDisplayManagementService.prototype.onLoaded = function (container) {
        this.onLoaded$.next(container);
    };
    TimecardsDisplayManagementService.prototype.onSaveLastSelectionState = function (state) {
        this.lastSelectionState$.next(state);
    };
    TimecardsDisplayManagementService.prototype.onPayCycleChanged = function (payCycle) {
        this.currentPayCycle = payCycle;
        if (this.currentOrgLevel && this.currentPayCycle) {
            this.loadTimecardsSummary(this.currentOrgLevel.id, this.currentPayCycle);
        }
    };
    TimecardsDisplayManagementService.prototype.changeEmpColumns = function () {
        if (!this.state) {
            return;
        }
        this.columnSettingsStorageService.setColumnsState(this.componentKey, this.empColumnsKey, this.state.empColumns.columns);
        this.stateManagementService.changes(null);
        this.onStateChanged(this.state);
    };
    TimecardsDisplayManagementService.prototype.changePayColumns = function () {
        if (!this.state) {
            return;
        }
        this.columnSettingsStorageService.setColumnsState(this.componentKey, this.payColumnsKey, this.state.payCodeColumns.columns);
        this.stateManagementService.changes(null);
        this.onStateChanged(this.state);
    };
    TimecardsDisplayManagementService.prototype.showHideEmptyTimeCards = function (value) {
        var conf = this.appUserSettingsService.getConfigurationByLocalNames(this.componentKey, this.hideShowEmptyTimeCardKey);
        this.appUserSettingsService.saveToServer([{ settingsGroup: this.appUserSettingsService.getServerGroupName(this.componentKey), name: conf.serverName, value: value }]);
    };
    TimecardsDisplayManagementService.prototype.onReleaseToPayroll = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        return this.apiService.submitPayroll(this.currentOrganizationId, this.currentPayCycle.startDate, this.currentPayCycle.endDate)
            .then(function (r) {
            _this.onLoadStatusChanged(false);
            return r;
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            return null;
        });
    };
    TimecardsDisplayManagementService.prototype.recreatePayCodeColumns = function (state, usedPayRules) {
        if (!state) {
            return;
        }
        state.createPayCodeColumns(usedPayRules);
        this.columnSettingsStorageService.getColumnsState(this.componentKey, this.payColumnsKey, state.payCodeColumns.columns);
        state.payCodeColumns.mapColumns();
        this.changePayColumns();
    };
    TimecardsDisplayManagementService.prototype.extractIdsForModification = function (operation) {
        var filtered = _.filter(this.selectedRecords, function (rec) { return rec.canModifyOwnTimecard; });
        var ids = _.map(filtered, function (emp) { return emp.employeePosition.employee.id; });
        if (filtered.length < this.selectedRecords.length) {
            this.errorService.info(new NotificationMessage('Timecard is readonly.', "You are not authorized to " + operation + " your own timecard"));
        }
        return ids;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], TimecardsDisplayManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['timecards', 'timecardsDisplay']),
        tslib_1.__metadata("design:type", Observable)
    ], TimecardsDisplayManagementService.prototype, "timecardsDisplay$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayManagementService.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayManagementService.prototype, "timecardsDisplaySubscription", void 0);
    return TimecardsDisplayManagementService;
}());
export { TimecardsDisplayManagementService };
