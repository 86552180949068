import { Organization, Department, Employee, Position, IEmployeeShortInfo } from '../../../organization';

export interface IAttendancePointsTotalsData {
    records: IAttendancePointsTotals[];
    buyBackEnabled: boolean;
}
export interface IAttendancePointsTotals {
    employee: IEmployeeShortInfo;
    totalAccumulatedPoints: number;
    totalBuyBackPoints: number;
    totalPoints: number;
}

export class AttendancePointsTotalsData {
    public records: AttendancePointsTotals[];
    public buyBackEnabled: boolean;
}
export class AttendancePointsTotals {
    public employee: Employee;
    public status: string;
    public organization: Organization;
    public department: Department;
    public position: Position;
    public totalAccumulatedPoints: number;
    public totalBuyBackPoints: number;
    public totalPoints: number;
}