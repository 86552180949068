export class MealDeducitonsDialogOptions {
    public title: string;
    public hideDescription: boolean;
    public skipSave: boolean;
    constructor(title: string, hideDescription: boolean, skipSave: boolean) {
        this.title = title;
        this.hideDescription = hideDescription;
        this.skipSave = skipSave;
    }
}
