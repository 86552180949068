import { EventEmitter } from '@angular/core';
var StaticDropdownListComponent = /** @class */ (function () {
    function StaticDropdownListComponent() {
        this.valueField = 'id';
        this.titleField = 'name';
        this.iconField = 'icon';
        this.selectItem = new EventEmitter();
    }
    Object.defineProperty(StaticDropdownListComponent.prototype, "options", {
        set: function (items) {
            this.items = items;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaticDropdownListComponent.prototype, "arrowIcon", {
        get: function () {
            return this.listIsOpen ? 'fa-angle-up' : 'fa-angle-down';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaticDropdownListComponent.prototype, "listClass", {
        get: function () {
            return this.listIsOpen ? 'open' : 'hide';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaticDropdownListComponent.prototype, "disabledClass", {
        get: function () {
            return this.readonly ? 'disabled' : '';
        },
        enumerable: true,
        configurable: true
    });
    StaticDropdownListComponent.prototype.onClickLabel = function () {
        if (!this.readonly) {
            this.listIsOpen = !this.listIsOpen;
        }
    };
    StaticDropdownListComponent.prototype.onClickItem = function (item) {
        if (!this.readonly) {
            this.selectItem.emit(item);
            if (this.listIsOpen) {
                this.listIsOpen = false;
            }
        }
    };
    return StaticDropdownListComponent;
}());
export { StaticDropdownListComponent };
