import { Injectable } from '@angular/core';
import { IAppState } from '../../store';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class SessionActions {
  public static USER_LOGGED_IN: string = 'USER_LOGGED_IN';
  public static USER_LOGGED_OUT: string = 'USER_LOGGED_OUT';
  public static SAVE_SESSION: string = 'SAVE_SESSION';
  public static CLEAR_SESSION: string = 'CLEAR_SESSION';
  public static START_CLEAR_SESSION: string = 'START_CLEAR_SESSION';

  private ngRedux: NgRedux<IAppState>;

  constructor(ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }

  public saveSession(session: any): void {
    this.ngRedux.dispatch({
      type: SessionActions.SAVE_SESSION,
      payload: session
    });
  }

  public startClearSession(): void {
    this.ngRedux.dispatch({
      type: SessionActions.START_CLEAR_SESSION
    });
  }

  public clearSession(): void {
    this.ngRedux.dispatch({
      type: SessionActions.CLEAR_SESSION
    });
  }

  public userLoggedIn(): void {
    this.ngRedux.dispatch({
      type: SessionActions.USER_LOGGED_IN,
    });
  }

  public userLoggedOut(willReturnedAfterLogin: boolean): void {
    this.ngRedux.dispatch({
      type: SessionActions.USER_LOGGED_OUT,
      payload: willReturnedAfterLogin
    });
  }
}
