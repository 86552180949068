<slx-spinner [show]="isLoading">

  <div class="model-header">
    <div class="col-xs-12 ">
      <button type="button" class="slx-button pull-right slx-blue generate-button" (click)="generate1094cXml()" [disabled]="!valid">
        <span class="slx-button__text">Generate Corrected XML</span>
      </button>
      <button type="button" class="slx-button pull-right slx-blue generate-button" (click)="generate1094cPdf()" [disabled]="!valid">
        <span class="slx-button__text">Generate PDF</span>
      </button>
    </div>
  </div>
  <div id="acaExportCount" class="modal-body">
    <form class="form-horizontal" #correctionForm="ngForm">
      <div class="row count-grid">
        <kendo-grid #kendoGrid class="slx-full-height slx-blue-grid"
                    [data]="gridState.view"
                    [sortable]="{ mode: 'multiple' }"
                    [sort]="gridState.state.sort"
                    [filterable]="'menu'"
                    [filter]="gridState.state.filter"
                    [skip]="gridState.state.skip"
                    [scrollable]="true"
                    [pageable]="false">

          <kendo-grid-column title="Month"
                             field="month"
                             width="100"
                             filter="text">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Month</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.month}}</ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="FT EE Count"
                             field="ftEECount"
                             width="100"
                             filter="text">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>FT EE Count</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <slx-kendo-number slx-input
                                name="{{rowIndex}}_ftEECount" min="0" [slxMax]="9999" number
                                [(ngModel)]="dataItem.ftCount">
              </slx-kendo-number>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Total EE Count"
                             field="totalEECount"
                             width="100"
                             filter="text">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Total EE Count</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <slx-kendo-number slx-input
                                name="{{rowIndex}}_totalEECount" min="0" [slxMax]="9999" number
                                [(ngModel)]="dataItem.totalCount">
              </slx-kendo-number>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
      <div class="flex" *ngIf="container?.totalFormsSubmitted">
        <div class="form-submit-margin">
          <span class="form-submit">Total Forms Submitted:<span class="slx-error-block">*</span></span>
        </div>
        <div class="padl5">
          <slx-input-decorator>
            <slx-kendo-number slx-input class="submit-width"
                              [required]="true" name="totalFormSubmitted" min="0" [slxMax]="9999" number
                              [(ngModel)]="container.totalFormsSubmitted">
            </slx-kendo-number>
            <div errorMessage for="required"></div>
          </slx-input-decorator>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="onBack()" class="btn grey"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
    <button type="button" (click)="onCancel()" class="btn red"><i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
  </div>
</slx-spinner>
