import { IEmployeeDefinition, EmployeeDefinition } from '../../../../organization/models/index';

export interface IUserProfileDTO {
    id: number;
    /*
    firstName: string;
    middleName: string;
    lastName: string;
    */
    name: string;
    login: string;
    password: string;
    resetPasswordOnNextLogin: boolean;
    workPhone: string;
    ext: string;
    fax: string;
    workAddress: string;
    comments: string;
    email: string;
    employee: IEmployeeDefinition;
    isLocked: boolean;
    isEditable: boolean;
    orgLevelDescription: string;
    orgLevelId: number;
}

export class UserProfileModel {
    public id: number;
    /*
    public firstName: string;
    public middleName: string;
    public lastName: string;
    */
    public name: string;
    public login: string;
    public password: string;
    public resetPasswordOnNextLogin: boolean;
    public workPhone: string;
    public ext: string;
    public fax: string;
    public workAddress: string;
    public comments: string;
    public email: string;
    public employee: EmployeeDefinition;

    public isNew: boolean;
    public isActive: boolean;
    public isEditable: boolean;
    public orgLevelDescription: string;
    public orgLevelId: number;
}
