import * as moment from 'moment';
import { appConfig } from '../../../app.config';

export interface IShiftGroupDefinition {
  id: number;
  name: string;
  startTime: string;
  endTime: string;
}

export class ShiftGroupDefinition implements IShiftGroupDefinition {
  private idValue: number;
  private nameValue: string;
  private startTimeValue: Date;
  private endTimeValue: Date;
  private durationHoursValue: number;

  constructor(id: number, name: string, startTime: string, endTime: string) {
    this.startTimeValue = new Date(`${new Date().toDateString()} ${startTime}`);
    this.endTimeValue = new Date(`${new Date().toDateString()} ${endTime}`);
    this.idValue = id;
    this.nameValue = name;

    let startDateMoment: moment.Moment = moment(this.startTime, appConfig.militaryTimeFormat);
    let endDateMoment: moment.Moment = moment(this.endTime, appConfig.militaryTimeFormat);
    if(startDateMoment <= endDateMoment) {
      this.durationHoursValue = endDateMoment.diff(startDateMoment, 'h');
    } else {
      let zeroDateMoment: moment.Moment = moment().startOf('day');
      let enddayDateMoment: moment.Moment = moment().endOf('day');
      this.durationHoursValue = enddayDateMoment.diff(startDateMoment, 'h') + endDateMoment.diff(zeroDateMoment, 'h');
    }
  }

  public get id(): number {
    return this.idValue;
  }

  public get name(): string {
    return this.nameValue;
  }

  public get start(): Date {
    return this.startTimeValue;
  }

  public get end(): Date {
    return this.endTimeValue;
  }

  public get startTime(): string {
    return moment(this.startTimeValue).format(appConfig.militaryTimeFormat);
  }

  public get endTime(): string {
    return moment(this.endTimeValue).format(appConfig.militaryTimeFormat);
  }

  public get durationHours(): number {
    return this.durationHoursValue;
  }
}
