import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { NotificationsService } from '../../../../../core/components/index';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { LoaRequestDialogOptions, LoaRequest } from '../../../models/index';
import { dateTimeUtils } from '../../../../../common/utils';
var ErrorCreationLoaRequest = /** @class */ (function (_super) {
    tslib_1.__extends(ErrorCreationLoaRequest, _super);
    function ErrorCreationLoaRequest(message, error) {
        var _this = _super.call(this) || this;
        _this.message = message;
        _this.error = error;
        return _this;
    }
    return ErrorCreationLoaRequest;
}(Error));
var LMCreationContainerComponent = /** @class */ (function () {
    function LMCreationContainerComponent(manService, notificationService) {
        this.manService = manService;
        this.notificationService = notificationService;
        this.action = new EventEmitter();
        this.requestChange = new EventEmitter();
        this.isLoading = false;
        this.errorMessage = '';
        this.subscriptions = {};
    }
    Object.defineProperty(LMCreationContainerComponent.prototype, "options", {
        set: function (v) {
            if (_.isObjectLike(v)) {
                this.m_orgLevelId = v.orgLevelId;
                this.loaRequest = v.loaRequest;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationContainerComponent.prototype, "hasEmployee", {
        get: function () {
            return _.isFinite(_.get(this.loaRequest, 'empId'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationContainerComponent.prototype, "canEditRequest", {
        get: function () {
            return this.manService.canEditRequest;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationContainerComponent.prototype, "editModeOn", {
        get: function () {
            return this.manService.editMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationContainerComponent.prototype, "isCreatingNew", {
        get: function () {
            return this.manService.isCreatingNew;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationContainerComponent.prototype, "canSave", {
        get: function () {
            return (_.get(this.loaRequest, 'readyToSave') && !(_.isEmpty(this.manService.getAbsenceReason())) && this.isValidDates());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationContainerComponent.prototype, "hasError", {
        get: function () {
            return _.size(this.errorMessage) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationContainerComponent.prototype, "orgLevelId", {
        get: function () {
            return this.m_orgLevelId;
        },
        enumerable: true,
        configurable: true
    });
    LMCreationContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.init(this.orgLevelId, this.loaRequest);
        this.subscriptions.loading = this.manService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
        this.subscriptions.loading = this.manService.subscribeToClosePopup(function () {
            _this.action.emit(true);
        });
        this.subscriptions.request = this.manService.subscribeToChangedRequest(function (r) {
            _this.loaRequest = r;
            _this.requestChange.emit(r);
        });
    };
    LMCreationContainerComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
        this.manService.destroy();
    };
    LMCreationContainerComponent.prototype.onSave = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        if (!this.isCreatingNew) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.saveRequest()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.updateRequest()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LMCreationContainerComponent.prototype.onCancel = function () {
        this.action.emit(false);
    };
    LMCreationContainerComponent.prototype.onEdit = function () {
        this.manService.toggleEditMode(true);
    };
    LMCreationContainerComponent.prototype.onSearchLoadStatusChange = function (isLoading) {
        this.manService.changeLoading(isLoading);
    };
    LMCreationContainerComponent.prototype.onEmployeeSelected = function (emp) {
        this.manService.setEmployee(emp);
    };
    LMCreationContainerComponent.prototype.saveRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loa, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.saveLoaRequest()];
                    case 1:
                        loa = _a.sent();
                        return [4 /*yield*/, this.attachSavedFiles(loa)];
                    case 2:
                        _a.sent();
                        this.manService.loadRequests();
                        this.notificationService.success('Request created', 'Leave of Absence Request created successfully');
                        this.action.emit(true);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.errorMessage = err_1 && err_1.message || 'An error has occurred, can\'t create Absence request';
                        console.error(err_1 instanceof ErrorCreationLoaRequest ? err_1.error : err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LMCreationContainerComponent.prototype.updateRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loa, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, this.manService.updateLoaRequest()];
                    case 1:
                        loa = _a.sent();
                        return [4 /*yield*/, this.attachSavedFiles(loa)];
                    case 2:
                        _a.sent();
                        this.manService.loadRequests();
                        this.notificationService.success('Request updated', 'Leave of absence request updated successfully');
                        this.action.emit(true);
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        this.errorMessage = err_2 && err_2.message || 'An error has occurred, can\'t update Absence request';
                        console.error(err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LMCreationContainerComponent.prototype.saveLoaRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var loa, err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loa = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.manService.createLoaRequest()];
                    case 2:
                        loa = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        throw new ErrorCreationLoaRequest('An error has occurred during creating Absence request', err_3);
                    case 4: return [2 /*return*/, loa];
                }
            });
        });
    };
    LMCreationContainerComponent.prototype.attachSavedFiles = function (loa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newLoa, err_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newLoa = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.manService.attachSavedFiles(loa)];
                    case 2:
                        newLoa = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_4 = _a.sent();
                        throw new ErrorCreationLoaRequest('An error has occurred during saving attachments of Absence request', err_4);
                    case 4: return [2 /*return*/, newLoa];
                }
            });
        });
    };
    LMCreationContainerComponent.prototype.isValidDates = function () {
        return this.isValidDate(this.loaRequest.estStartDate) && this.isValidDate(this.loaRequest.estEndDate) && this.isValidDate(this.loaRequest.actStartDate) && this.isValidDate(this.loaRequest.actEndDate);
    };
    LMCreationContainerComponent.prototype.isValidDate = function (date) {
        return dateTimeUtils.validateDate(date);
    };
    return LMCreationContainerComponent;
}());
export { LMCreationContainerComponent };
