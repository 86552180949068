import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, asyncScheduler } from 'rxjs';
import { distinctUntilChanged, finalize, observeOn, scan, shareReplay, take } from 'rxjs/operators';

export interface BusyState {
  /** True if is busy */
  isBusy: boolean;
}

export const notBusy: BusyState = { isBusy: false };

@Injectable({ providedIn: 'root' })
export class BusyService {
  busyState$: Observable<BusyState>;

  private _busyState$: Observable<BusyState>;
  private busyCounter = 0;
  private busySubject = new BehaviorSubject(notBusy);

  constructor() {
    this._busyState$ = this.busySubject.pipe(
      scan(
        (oldValue, value) =>
          oldValue.isBusy === value.isBusy
            ? oldValue
            : { ...value },
        { ...notBusy } as BusyState
      ),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.busyState$ = this._busyState$.pipe(observeOn(asyncScheduler));
  }

  /**
   * Indicate busy until the source observable completes.
   *
   * @param source Observable that the busy service should watch
   * @return piped continuation of the source observable. Caller should subscribe to this.
   * Warning: busy and live forever if observable fails to terminate
   */
  busy$<T>(source: Observable<T>): Observable<T> {
    this.increment();
    return source.pipe(finalize(() => this.decrement()));
  }

  /**
   * Indicate busy until the source promise completes.
   * Display spinner (and message) after a delay if promise has not yet completed.
   *
   * @param source Promise that the busy service should watch
   * @return source Promise that the caller can use for continuation
   */
   busy<T>(source: Promise<T>): Promise<T> {
     this.increment();
     return source.finally(() => this.decrement());
   }

  /**
   * Increment the count of busy processes and set current message if no message pending.
   * Causes isBusy$ to emit true.
   */
  increment(): void {
    this.busyCounter++;
    let oldState: BusyState;
    this._busyState$.pipe(take(1)).subscribe((state) => (oldState = state));

    // change message with caller's if provided and either there is no current message or should override it
    this.busySubject.next({ isBusy: true });
  }

  /**
   * Decrement the count of busy processes.
   * If no more busy processes, clear the current message and indicate no longer busy
   * (isBusy$ emits false).
   */
  decrement(): void {
    if (this.busyCounter > 0) {
      this.busyCounter--;
    }
    if (this.busyCounter === 0) {
      this.busySubject.next(notBusy);
    }
  }
}
