<div class="flex-horizontal payroll-export-management-toolbar">
    <slx-custom-paycycle-dropdown class="paycycle-selector" 
        [setDefaultIfNotSelected]="true"
        [selectedPayCycle]="currentPayCycle" 
        [payCycles]="currentPayCycles"
        (payCycleSelected)="onPayCycleSelected($event)"
        >
    </slx-custom-paycycle-dropdown>

    <button class="slx-button slx-with-icon add-template-btn"
        [disabled]="!manageService.canChangeConfig"
        *ngIf="manageService.settingsAvailable"
        (click)="showSettings()">
        <i class="icon slx-button__icon fas fa-cog"  aria-hidden="true"></i>
        Configure</button>
</div>