
export class CacheType {
  public static default: CacheDefinition = 'default';
  public static shortTerm: CacheDefinition = 'shortTerm';
  public static longTerm: CacheDefinition = 'longTerm';
}

export type CacheDefinition = 'default'
  | 'shortTerm'
  | 'longTerm'
;
