import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsAuditTrail = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAuditTrail, _super);
    function EmployeeSectionsAuditTrail() {
        var _this = _super.call(this) || this;
        _this.records = [];
        return _this;
    }
    EmployeeSectionsAuditTrail.dataMock = {
        records: [
            {
                id: 124,
                type: 'note',
                subject: 'subject1',
                detail: 'detail1',
                user: 'user1',
                changedOn: new Date('2016-09-01')
            }
        ]
    };
    return EmployeeSectionsAuditTrail;
}(EmployeeSectionsBase));
export { EmployeeSectionsAuditTrail };
var EmployeeSectionsAuditTrailRecord = /** @class */ (function () {
    function EmployeeSectionsAuditTrailRecord() {
    }
    return EmployeeSectionsAuditTrailRecord;
}());
export { EmployeeSectionsAuditTrailRecord };
var EmployeeSectionsAuditTrailNoteRecord = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAuditTrailNoteRecord, _super);
    function EmployeeSectionsAuditTrailNoteRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsAuditTrailNoteRecord;
}(EmployeeSectionsAuditTrailRecord));
export { EmployeeSectionsAuditTrailNoteRecord };
var EmployeeSectionsAuditTrailJournalRecord = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAuditTrailJournalRecord, _super);
    function EmployeeSectionsAuditTrailJournalRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsAuditTrailJournalRecord;
}(EmployeeSectionsAuditTrailRecord));
export { EmployeeSectionsAuditTrailJournalRecord };
var EmployeeSectionsAuditTrailEditRecord = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAuditTrailEditRecord, _super);
    function EmployeeSectionsAuditTrailEditRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsAuditTrailEditRecord;
}(EmployeeSectionsAuditTrailRecord));
export { EmployeeSectionsAuditTrailEditRecord };
