
enum State {
  created = 'created',
  added = 'added',
  updated = 'updated',
  deleted = 'deleted'
}

export class BenefitDetailsState {
  private state = State.created;

  public setStateAdded(): void {
    this.state = State.added;
  }

  public setStateUpdated(): void {
    this.state = State.updated;
  }

  public setStateDeleted(): void {
    this.state = State.deleted;
  }

  public get stateIsAdded(): boolean {
    return this.state === State.added;
  }

  public get stateIsUpdated(): boolean {
    return this.state === State.updated;
  }

  public get stateIsDeleted(): boolean {
    return this.state === State.deleted;
  }
}
