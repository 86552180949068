import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { DateRangeWithDate } from '../../../components-library/models/index';
import { appConfig } from '../../../app.config';
import { PopperContent } from 'ngx-popper';
import { ControlValueAccessorBase } from '../../../core/models/index';
var ScheduleCycleCalendarSliderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ScheduleCycleCalendarSliderComponent, _super);
    function ScheduleCycleCalendarSliderComponent() {
        var _this = _super.call(this) || this;
        _this.appConfig = appConfig;
        _this.isShown = false;
        _this.rangeChanged = new EventEmitter();
        return _this;
    }
    Object.defineProperty(ScheduleCycleCalendarSliderComponent.prototype, "cycles", {
        get: function () {
            return this.m_cycles;
        },
        set: function (value) {
            this.m_cycles = value;
            this.recalcCycle();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleCycleCalendarSliderComponent.prototype, "currentDate", {
        get: function () {
            return this.m_currentDate;
        },
        set: function (value) {
            if (_.isDate(this.m_currentDate) && !moment(this.m_currentDate).isSame(value)) {
                this.m_currentDate = value;
                if (this.popper instanceof PopperContent) {
                    this.popper.hide();
                }
                this.onChangeCallback(this.m_currentDate);
                this.recalcCycle();
            }
        },
        enumerable: true,
        configurable: true
    });
    ScheduleCycleCalendarSliderComponent.prototype.writeValue = function (value) {
        if (!_.isUndefined(value) && !_.isNull(value)) {
            this.m_currentDate = value;
            this.recalcCycle();
        }
    };
    ScheduleCycleCalendarSliderComponent.prototype.recalcCycle = function () {
        if (!this.currentDate || !this.cycles || this.cycles.length === 0) {
            return;
        }
        var days = this.calculateDays(this.currentDate);
        var end = moment(this.currentDate).add(days, 'day').toDate();
        this.currentRange = new DateRangeWithDate(this.currentDate, this.currentDate, end);
        this.rangeChanged.next(this.currentRange);
    };
    ScheduleCycleCalendarSliderComponent.prototype.onNavButtonClick = function (event, isNext) {
        if (this.readonly)
            return;
        var days = this.calculateDays(this.currentDate) + 1;
        if (isNext) {
            this.currentDate = moment(this.currentDate).add(days, 'day').toDate();
        }
        else {
            this.currentDate = moment(this.currentDate).subtract(days, 'day').toDate();
        }
    };
    // See #issueWithPopperVisibility
    ScheduleCycleCalendarSliderComponent.prototype.onToggleVisibility = function (isShown) {
        this.isShown = isShown;
    };
    ScheduleCycleCalendarSliderComponent.prototype.calculateDays = function (date) {
        var currentDate = moment(date).startOf('day').toDate();
        var selectedCycle = this.findPayCycle(this.cycles, currentDate);
        var days = moment(selectedCycle.endDate).diff(selectedCycle.startDate, 'days');
        return days;
    };
    ScheduleCycleCalendarSliderComponent.prototype.findPayCycle = function (cycles, date) {
        var selectedCycle = _.find(cycles, function (cycle) {
            return moment(date).isSameOrAfter(cycle.startDate) && moment(date).isSameOrBefore(cycle.endDate);
        });
        return selectedCycle;
    };
    return ScheduleCycleCalendarSliderComponent;
}(ControlValueAccessorBase));
export { ScheduleCycleCalendarSliderComponent };
