import * as tslib_1 from "tslib";
import { OnDestroy, Renderer2, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { ICollapseComponent } from '../../models/index';
var CollapseByCssDirective = /** @class */ (function () {
    function CollapseByCssDirective(collapseComponent, renderer) {
        var _this = this;
        this.collapseComponent = collapseComponent;
        this.renderer = renderer;
        this.collapseSuscription = collapseComponent.isCollapsedChange.subscribe(function (isCollapsed) {
            _this.isCollapsed = isCollapsed;
            _this.collapseHandler();
        });
        this.collapseModifySuscription = collapseComponent.isCollapsedModify.subscribe(function (isCollapsed) {
            _this.isCollapsed = isCollapsed;
            _this.collapseHandler();
        });
    }
    CollapseByCssDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        scheduleMicrotask(function () {
            _this.collapseHandler();
        });
    };
    CollapseByCssDirective.prototype.collapseHandler = function () {
        if (!this.elementToCollapse) {
            return;
        }
        if (this.isCollapsed) {
            this.renderer.addClass(this.elementToCollapse, 'slx-theme-collapsed');
        }
        else {
            this.renderer.removeClass(this.elementToCollapse, 'slx-theme-collapsed');
        }
    };
    CollapseByCssDirective.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CollapseByCssDirective.prototype, "collapseSuscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CollapseByCssDirective.prototype, "collapseModifySuscription", void 0);
    return CollapseByCssDirective;
}());
export { CollapseByCssDirective };
