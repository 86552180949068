import * as _ from 'lodash';
export var requireNumberMultiValidationKey = 'requireNumberMulti';
export function requireNumberMultiValidator() {
    var validationPattern = new RegExp(/^\d+(([\w\s]?),([\w\s]?)\d+)*$/);
    var validator = function (control) {
        var _a;
        var value = control.value ? _.trim(control.value) : control.value;
        var isValid = validationPattern.test(value);
        return !isValid ? (_a = {}, _a[requireNumberMultiValidationKey] = true, _a) : null;
    };
    return validator;
}
