
<kendo-grid #kendoGrid class="slx-full-height slx-pbj-rec-grid slx-blue-grid no-border"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [rowClass]="defineRowClass()"
  (dataStateChange)="gridState.dataStateChange($event)"
>
  <kendo-grid-column media="(max-width: 780px)"
    [title]="columns.depName.title"
    [field]="columns.depName.field"
    [width]="columns.depName.mobileWidth"
    [filterable]="false"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.depName.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="pbj-rec-link slx-pbj-rec-grid__text-overflow" (click)="onDepClick(dataItem)">
        <i class="slx-pbj-rec-grid__icon fa fa-building" aria-hidden="true"></i>
        <span>{{ dataItem.depName }}</span>
      </p>
    </ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text no-align">Grand Total</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(max-width: 780px)"
    [title]="columns.timecardHours.title"
    [field]="columns.timecardHours.field"
    [width]="columns.timecardHours.mobileWidth"
    [filterable]="false"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.timecardHours.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.timecardHours | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.timecardHours?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(max-width: 780px)"
    [title]="columns.expectedPbjHours.title"
    [field]="columns.expectedPbjHours.field"
    [width]="columns.expectedPbjHours.mobileWidth"
    [filterable]="false"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.expectedPbjHours.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.expectedPbjHours | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.expectedPbjHours?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(max-width: 780px)"
    [title]="columns.variance.title"
    [field]="columns.variance.field"
    [width]="columns.variance.mobileWidth"
    [filterable]="false"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.variance.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.variance | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.variance?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(max-width: 780px)"
    [sortable]="false"
    [filterable]="false"
    width="40"
    class="overflow-visible-cell"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="slx-pbj-rec-grid__details">
        <span class="slx-pbj-rec-grid__link"
          [popper]="detailsContent"
          [popperTrigger]="'click'"
          [popperPlacement]="'left-start'"
          [popperDisableStyle]="'true'"
        >
          <i class="fas fa-list-alt" aria-hidden="true"></i>
        </span>
        <popper-content #detailsContent class="slx-popper slx-pbj-rec-popper">
          <div class="slx-pbj-rec-grid__list pbj-rec-details-list">
            <p class="slx-pbj-rec-grid__item pbj-rec-details-list__item">
              <span class="slx-pbj-rec-grid__item-text pbj-rec-details-list__text">{{ columns.startOfPeriod.title }}:</span>
              <span class="slx-pbj-rec-grid__item-value pbj-rec-details-list__value">{{ dataItem.startOfPeriod }}</span>
            </p>
            <p class="slx-pbj-rec-grid__item pbj-rec-details-list__item">
              <span class="slx-pbj-rec-grid__item-text pbj-rec-details-list__text">{{ columns.endOfPeriod.title }}:</span>
              <span class="slx-pbj-rec-grid__item-value pbj-rec-details-list__value">{{ dataItem.endOfPeriod }}</span>
            </p>
            <p class="slx-pbj-rec-grid__item pbj-rec-details-list__item">
              <span class="slx-pbj-rec-grid__item-text pbj-rec-details-list__text">{{ columns.excludedPayCodes.title }}:</span>
              <span class="slx-pbj-rec-grid__item-value pbj-rec-details-list__value">{{ dataItem.excludedPayCodes }}</span>
            </p>
            <p class="slx-pbj-rec-grid__item pbj-rec-details-list__item">
              <span class="slx-pbj-rec-grid__item-text pbj-rec-details-list__text">{{ columns.unmappedPositions.title }}:</span>
              <span class="slx-pbj-rec-grid__item-value pbj-rec-details-list__value">{{ dataItem.unmappedPositions }}</span>
            </p>
            <p class="slx-pbj-rec-grid__item pbj-rec-details-list__item">
              <span class="slx-pbj-rec-grid__item-text pbj-rec-details-list__text">{{ columns.manualPbjEntries.title }}:</span>
              <span class="slx-pbj-rec-grid__item-value pbj-rec-details-list__value">{{ dataItem.manualPbjEntries }}</span>
            </p>
            <p class="slx-pbj-rec-grid__item pbj-rec-details-list__item">
              <span class="slx-pbj-rec-grid__item-text pbj-rec-details-list__text">{{ columns.unapprovedHours.title }}:</span>
              <span class="slx-pbj-rec-grid__item-value pbj-rec-details-list__value">{{ dataItem.unapprovedHours }}</span>
            </p>
          </div>
        </popper-content>
      </div>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column media="(min-width: 781px)"
    [title]="columns.depName.title"
    [field]="columns.depName.field"
    [width]="columns.depName.width"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.depName.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="pbj-rec-link slx-pbj-rec-grid__text-overflow" (click)="onDepClick(dataItem)">
        <i class="slx-pbj-rec-grid__icon fa fa-building" aria-hidden="true"></i>
        <span>{{ dataItem.depName }}</span>
      </p>
    </ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text no-align">Grand Total</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    [title]="columns.timecardHours.title"
    [field]="columns.timecardHours.field"
    [width]="columns.timecardHours.width"
    filter="numeric"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.timecardHours.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.timecardHours | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.timecardHours?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    *ngIf="showDetails"
    [title]="columns.startOfPeriod.title"
    [field]="columns.startOfPeriod.field"
    [width]="columns.startOfPeriod.width"
    filter="numeric"
    headerClass="slx-detailed-hd"
    class="slx-detailed-cell"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.startOfPeriod.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.startOfPeriod | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.startOfPeriod?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    *ngIf="showDetails"
    [title]="columns.endOfPeriod.title"
    [field]="columns.endOfPeriod.field"
    [width]="columns.endOfPeriod.width"
    filter="numeric"
    headerClass="slx-detailed-hd"
    class="slx-detailed-cell"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.endOfPeriod.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.endOfPeriod | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.endOfPeriod?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    *ngIf="showDetails"
    [title]="columns.excludedPayCodes.title"
    [field]="columns.excludedPayCodes.field"
    [width]="columns.excludedPayCodes.width"
    filter="numeric"
    headerClass="slx-detailed-hd"
    class="slx-detailed-cell"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.excludedPayCodes.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.excludedPayCodes | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.excludedPayCodes?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    *ngIf="showDetails"
    [title]="columns.unmappedPositions.title"
    [field]="columns.unmappedPositions.field"
    [width]="columns.unmappedPositions.width"
    filter="numeric"
    headerClass="slx-detailed-hd"
    class="slx-detailed-cell"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.unmappedPositions.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.unmappedPositions | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.unmappedPositions?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    *ngIf="showDetails"
    [title]="columns.manualPbjEntries.title"
    [field]="columns.manualPbjEntries.field"
    [width]="columns.manualPbjEntries.width"
    filter="numeric"
    headerClass="slx-detailed-hd"
    class="slx-detailed-cell"
  >
     <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.manualPbjEntries.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.manualPbjEntries | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.manualPbjEntries?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    *ngIf="showDetails"
    [title]="columns.unapprovedHours.title"
    [field]="columns.unapprovedHours.field"
    [width]="columns.unapprovedHours.width"
    filter="numeric"
    headerClass="slx-detailed-hd"
    class="slx-detailed-cell"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.unapprovedHours.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.unapprovedHours | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.unapprovedHours?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    [title]="columns.expectedPbjHours.title"
    [field]="columns.expectedPbjHours.field"
    [width]="columns.expectedPbjHours.width"
    filter="numeric"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.expectedPbjHours.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.expectedPbjHours | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.expectedPbjHours?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    [title]="columns.totalPbjHours.title"
    [field]="columns.totalPbjHours.field"
    [width]="columns.totalPbjHours.width"
    filter="numeric"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.totalPbjHours.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.totalPbjHours | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.totalPbjHours?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)"
    [title]="columns.variance.title"
    [field]="columns.variance.field"
    [width]="columns.variance.width"
    filter="numeric"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>{{ columns.variance.title }}</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.variance | slxPbjRecNumber }}</p></ng-template>
    <ng-template kendoGridFooterTemplate><strong class="slx-pbj-rec-grid__text">{{ totals.variance?.sum | slxPbjRecNumber }}</strong></ng-template>
  </kendo-grid-column>

  <kendo-grid-excel [fileName]="xlsxFileName" [fetchData]="retriveAllPages()"></kendo-grid-excel>
  <kendo-grid-pdf [fileName]="fileName" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>
</kendo-grid>
