import * as moment from 'moment';

import {
  IOrganization, Organization,
  IDepartment, Department, 
  IPosition, Position, IEmployeeDefinition, EmployeeDefinition
} from '../../../organization/models/index';

export interface ITempSensorReading {
  employee: IEmployeeDefinition;
  organization: IOrganization;
  department: IDepartment;
  position: IPosition;
  clock: string;
  dateTime: string;
  tempReading: string;
  temperatureWithDec: number;
  thresholdWithDec: number;
}

export class TempSensorReading {
  public employee: EmployeeDefinition;
  public organization: Organization;
  public department: Department;
  public position: Position;
  public clock: string;
  public eventDate: Date;
  public timeStamp: Date;
  public tempReading: string;
  public temperatureWithDec: string;
  public thresholdWithDec: string;
}

