import { Provider } from '@angular/core';

export * from './benefit-class/index';
export * from './benefit-details/index';
export * from './benefit-console/index';
export * from './benefit-employees/index';

import { benefitClassservices } from './benefit-class/index';
import { benefitPlanServices } from './benefit-details/index';
import { benefitListservices } from './benefit-console/index';
import { benefitEmployeesServices } from './benefit-employees/index';
import { benefitEligibilityRulesServices } from './benefit-eligibility-rules/index';

export const services: Provider[] = [
  ...benefitClassservices,
  ...benefitPlanServices,
  ...benefitEmployeesServices,
  ...benefitListservices,
  ...benefitEligibilityRulesServices
];
