/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-rehire-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../../../common/components/spinner/spinner.component";
import * as i4 from "../employee-rehire/employee-rehire.component.ngfactory";
import * as i5 from "../employee-rehire/employee-rehire.component";
import * as i6 from "../../../../../../organization/services/lookup/lookup.service";
import * as i7 from "../../../../../../app-settings/services/app-settings-manage.service";
import * as i8 from "../../../../../../organization/services/employee/employee-definitions-api.service";
import * as i9 from "../../../../../../portal/services/notifications/notifications-api.service";
import * as i10 from "../../../../../../organization/services/employee/employee-validator-adapter";
import * as i11 from "./employee-rehire-dialog.component";
import * as i12 from "../../../../../../common/models/dialog-options";
import * as i13 from "../../../../../../common/services/modal/modal.service";
import * as i14 from "../../../../models/employee-activities/employee-to-rehire";
var styles_EmployeeRehireDialogComponent = [i0.styles];
var RenderType_EmployeeRehireDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeRehireDialogComponent, data: {} });
export { RenderType_EmployeeRehireDialogComponent as RenderType_EmployeeRehireDialogComponent };
export function View_EmployeeRehireDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { employeeRehire: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(4, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "slx-employee-rehire", [], null, null, null, i4.View_EmployeeRehireComponent_0, i4.RenderType_EmployeeRehireComponent)), i1.ɵdid(6, 114688, [[1, 4], ["employeeRehire", 4]], 0, i5.EmployeeRehireComponent, [i6.LookupService, i7.AppSettingsManageService, i8.EmployeeDefinitionsApiService, i9.NotificationsApiService, i10.EmployeeValidatorAdapter], { emp: [0, "emp"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.employee; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.isEmployeeRehireFormValid; _ck(_v, 11, 0, currVal_2); }); }
export function View_EmployeeRehireDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-rehire-dialog", [], null, null, null, View_EmployeeRehireDialogComponent_0, RenderType_EmployeeRehireDialogComponent)), i1.ɵdid(1, 49152, null, 0, i11.EmployeeRehireDialogComponent, [i12.DialogOptions, i13.ModalService, i14.EmployeeToRehire], null, null)], null, null); }
var EmployeeRehireDialogComponentNgFactory = i1.ɵccf("slx-employee-rehire-dialog", i11.EmployeeRehireDialogComponent, View_EmployeeRehireDialogComponent_Host_0, {}, {}, []);
export { EmployeeRehireDialogComponentNgFactory as EmployeeRehireDialogComponentNgFactory };
