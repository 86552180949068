import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy, ElementRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../utils/index';
var DateTimeInputComponent = /** @class */ (function () {
    function DateTimeInputComponent(elementRef) {
        var _this = this;
        this.dateFormat = 'MM/dd/yyyy';
        this.placeholder = 'Time';
        this.onDateChanged = new EventEmitter();
        this.inEdit = true;
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.dateChangeHandler = function (e) {
            var m = moment(_this.kendoDatePicker.value());
            _this.calendarDateChanged(m);
        };
    }
    Object.defineProperty(DateTimeInputComponent.prototype, "minDateLimit", {
        set: function (value) {
            if (value) {
                this.internalMinMoment = moment(value);
            }
            else {
                this.internalMinMoment = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeInputComponent.prototype, "maxDateLimit", {
        set: function (value) {
            if (value) {
                this.internalMaxMoment = moment(value);
            }
            else {
                this.internalMaxMoment = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeInputComponent.prototype, "readonly", {
        get: function () {
            return !this.inEdit;
        },
        set: function (ro) {
            this.inEdit = !ro;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeInputComponent.prototype, "required", {
        get: function () {
            return this.requiredInternal;
        },
        set: function (value) {
            this.requiredInternal = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeInputComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (!(v instanceof Date))
                throw new Error('This control requres Date as value');
            if (v !== this.innerValue) {
                this.innerValue = v ? v : null;
                this.internalMoment = v ? moment(v) : null;
                this.onChangeCallback(this.innerValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    DateTimeInputComponent.prototype.writeValue = function (v) {
        if (v === this.innerValue)
            return;
        this.innerValue = v ? v : null;
        if (v === null || v === undefined) {
            this.internalMoment = null;
            return;
        }
        if (!(v instanceof Date))
            throw new Error('DateTimeInput requires Date as value');
        this.internalMoment = moment(v);
    };
    DateTimeInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    DateTimeInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DateTimeInputComponent.prototype.ngOnInit = function () {
        this.initialized = true;
    };
    DateTimeInputComponent.prototype.ngOnDestroy = function () {
        this.initialized = false;
        if (this.kendoDatePicker) {
            this.kendoDatePicker.destroy();
        }
    };
    DateTimeInputComponent.prototype.toggleCalendar = function () {
        if (!this.kendoDatePicker) {
            this.createDatePicker();
        }
        this.kendoDatePicker.open();
    };
    DateTimeInputComponent.prototype.onTimeChanged = function (d) {
        //this.updateTime(moment(d));
        this.onDateChanged.emit(this.innerValue);
    };
    DateTimeInputComponent.prototype.createDatePicker = function () {
        if (this.$datepickerInput && !this.kendoDatePicker) {
            var kendoDatePickerOptions = this.kendoDatePickerOptions();
            var input = this.$datepickerInput;
            input.kendoDatePicker(kendoDatePickerOptions);
            this.kendoDatePicker = input.data('kendoDatePicker');
            this.kendoDatePicker.close();
        }
    };
    DateTimeInputComponent.prototype.calendarDateChanged = function (moment) {
        this.updateDate(moment);
        this.onDateChanged.emit(this.innerValue);
    };
    DateTimeInputComponent.prototype.updateTime = function (moment) {
        dateTimeUtils.setTimeToMoment(this.internalMoment, moment.format(appConfig.timeFormat));
        this.innerValue = this.internalMoment.toDate();
        if (this.model && this.field) {
            this.model[this.field] = this.innerValue;
        }
    };
    DateTimeInputComponent.prototype.updateDate = function (moment) {
        this.internalMoment.year(moment.year()).dayOfYear(moment.dayOfYear());
        this.innerValue = this.internalMoment.toDate();
        if (this.onChangeCallback)
            this.onChangeCallback(this.innerValue);
        if (this.model && this.field) {
            this.model[this.field] = this.innerValue;
        }
    };
    Object.defineProperty(DateTimeInputComponent.prototype, "$element", {
        get: function () {
            return $(this.elementRef.nativeElement);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeInputComponent.prototype, "$calendarBtn", {
        get: function () {
            return this.$element.find('.button.calendar');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeInputComponent.prototype, "$datepickerInput", {
        get: function () {
            return this.$element.find('input.date-picker-field');
        },
        enumerable: true,
        configurable: true
    });
    DateTimeInputComponent.prototype.kendoDatePickerOptions = function () {
        var options = {
            format: this.dateFormat,
            change: this.dateChangeHandler
        };
        if (this.internalMinMoment) {
            options.min = this.internalMinMoment.toDate();
        }
        if (this.internalMaxMoment) {
            options.max = this.internalMaxMoment.toDate();
        }
        return options;
    };
    return DateTimeInputComponent;
}());
export { DateTimeInputComponent };
