/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./section-punches-management.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../punches/daily-line-punch-grid/daily-line-punch-grid.component.ngfactory";
import * as i3 from "../../punches/daily-line-punch-grid/daily-line-punch-grid.component";
import * as i4 from "../../../services/punches/daily-punches-management.service";
import * as i5 from "../../../../organization/services/lookup/lookup.service";
import * as i6 from "../../../../common/services/column-settings/column-management.service";
import * as i7 from "../../../../common/services/modal/modal.service";
import * as i8 from "../../../services/timecards/timecards-check-rights.service";
import * as i9 from "../../../../app-settings/services/app-settings-manage.service";
import * as i10 from "../../../../core/services/error-handling/error-handling.service";
import * as i11 from "../../../services/daily-timecard/daily-timecard-management.service";
import * as i12 from "../../../services/punches/punches-api.service";
import * as i13 from "./section-punches-management.component";
import * as i14 from "../../../../common/services/change-management/change-management.service";
var styles_SectionPunchesManagementComponent = [i0.styles];
var RenderType_SectionPunchesManagementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SectionPunchesManagementComponent, data: {} });
export { RenderType_SectionPunchesManagementComponent as RenderType_SectionPunchesManagementComponent };
export function View_SectionPunchesManagementComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-daily-line-punch-grid", [], null, null, null, i2.View_DailyLinePunchGridComponent_0, i2.RenderType_DailyLinePunchGridComponent)), i1.ɵdid(1, 770048, null, 0, i3.DailyLinePunchGridComponent, [i4.DailyPunchesManagementService, i1.ChangeDetectorRef, i5.LookupService, i6.ColumnManagementService, i7.ModalService, i8.TimecardsCheckRightsService, i9.AppSettingsManageService, i10.ErrorHandlingService, i11.DailyTimecardManagementService], { employeeId: [0, "employeeId"], employeeName: [1, "employeeName"], defaultPunchTime: [2, "defaultPunchTime"], minPunchDate: [3, "minPunchDate"], maxPunchDate: [4, "maxPunchDate"], userActions: [5, "userActions"], displayOptions: [6, "displayOptions"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.timecard == null) ? null : _co.timecard.employee.id); var currVal_1 = ((_co.timecard == null) ? null : _co.timecard.employee.name); var currVal_2 = ((_co.timecard == null) ? null : _co.timecard.date); var currVal_3 = ((_co.timecard == null) ? null : _co.timecard.minPunchDate); var currVal_4 = ((_co.timecard == null) ? null : _co.timecard.maxPunchDate); var currVal_5 = _co.userActions; var currVal_6 = _co.displayOptions; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_SectionPunchesManagementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-section-punches-management", [], null, null, null, View_SectionPunchesManagementComponent_0, RenderType_SectionPunchesManagementComponent)), i1.ɵprd(512, null, i4.DailyPunchesManagementService, i4.DailyPunchesManagementService, [i12.PunchesApiService]), i1.ɵdid(2, 770048, null, 0, i13.SectionPunchesManagementComponent, [i11.DailyTimecardManagementService, i4.DailyPunchesManagementService, i1.ChangeDetectorRef, i14.ChangeManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SectionPunchesManagementComponentNgFactory = i1.ɵccf("slx-section-punches-management", i13.SectionPunchesManagementComponent, View_SectionPunchesManagementComponent_Host_0, { timecard: "timecard" }, { applyChanges: "applyChanges", changingPunches: "changingPunches" }, []);
export { SectionPunchesManagementComponentNgFactory as SectionPunchesManagementComponentNgFactory };
