<section class="cd-horizontal-timeline" [ngClass]="{'loaded': loaded}">
  <div class="timeline">
    <div class="events-wrapper">
      <div class="events" #eventsWrapper [style.width.px]="eventsWrapperWidth">
        <ol>
          <li *ngFor="let item of items; let index = index">
            <a #timelineEvents href="#" [ngClass]="{
              'shift': item.type===scheduleStart||item.type===scheduleEnd,
              'punch': item.type===punchIn || item.type===punchOut,
              'badge-point': item.type===late || item.type===ot,
              'selected': item.selected, 'older-event': index < selectedIndex}"
               (click)="onEventClick($event, item)">
               <ng-container *ngIf="!item.lateBageNeighbor && (!item.otBageNeighbor||!isShowOverages) && (item.type===scheduleStart||item.type===scheduleEnd)">
                {{ item.date | amDateFormat: appConfig.timeFormatTight}}
               </ng-container>
               <i *ngIf="item.type===punchIn" class="punch-icon punch-in fas fa-arrow-from-bottom" aria-hidden="true"></i>
               <i *ngIf="item.type===punchOut" class="punch-icon punch-out fas fa-arrow-from-top" aria-hidden="true"></i>
               <span class="badge-late" *ngIf="item.type===late">{{item.arrival?.late}}</span>
               <span class="badge-ot" *ngIf="item.type===ot && isShowOverages">{{item.departure?.overtime}}</span>
              </a>
            <span>{{item.caption}}</span>
          </li>
        </ol>
        <span class="filling-line left" aria-hidden="true" #fillingLineStart></span>
        <span class="filling-line right" aria-hidden="true" #fillingLineEnd></span>
      </div>
    </div>

    <ul class="cd-timeline-navigation">
      <li>
        <a href="#" (click)="onScrollClick($event, false)" class="prev" [ngClass]="{'inactive':prevLinkInactive}">
          <i class="far fa-angle-left" aria-hidden="true"></i>
        </a>
      </li>
      <li>
        <a href="#" (click)="onScrollClick($event, true)" class="next" [ngClass]="{'inactive':nextLinkInactive}">
          <i class="far fa-angle-right" aria-hidden="true"></i>
        </a>
      </li>
    </ul>
  </div>
