import { MasterScheduleContextMenuRequest } from './../../../models/master-schedule/master-schedule-context-menu-request';
import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, IDialog, ColumnSettingsDefinitions, PopoverContentComponent, ConfirmOptions, ConfirmDialog2Component } from '../../../../common/index';
import { ModalService, ColumnSettingsStorageService } from '../../../../common/services/index';
import { LookupApiService } from '../../../../organization/services/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { screenUtils, IScreenUtils } from '../../../../common/utils/index';
import { MasterScheduleContextMenuRotation } from '../../../models/index';
import { ScheduleEntryApiService } from '../../../services/schedule-entry/schedule-entry-api.service';
import { AppServerConfig } from '../../../../../app/app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app/app-settings/services/app-settings-manage.service';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../../app/core/decorators';
import { IScheduleActions, ScheduleActions } from '../../../../../app/organization';
import { MasterScheduleManagementService } from '../../../../../app/scheduler/services';

@Component({
  moduleId: module.id,
  selector: 'slx-master-schedule-options-dialog',
  templateUrl: 'master-schedule-options-dialog.component.html',
  styleUrls: ['master-schedule-options-dialog.component.scss']
})
export class MasterScheduleOptionsDialogComponent implements IDialog, OnInit {

  @unsubscribe()
  public actionsSubscription: Subscription;
  
  public actions: ScheduleActions;
  public options: DialogOptions;
  public dialogResult: boolean;
  public selectedCommand: string;
  public request: MasterScheduleContextMenuRequest;

  public empId: string;
  public startDate: Date;
  public endDate: Date;
  public isModifyPayPeriodApproved: boolean;
  public createRotation: MasterScheduleContextMenuRotation = new MasterScheduleContextMenuRotation();
  public createRotationFromScheduleSection: boolean;
  public approvedPayPeriod: any;
  
  public disableButtons : any = {
    disableGeneratebtn :false,
    disableDeletebtn :false
  }
  

  public get canSave(): boolean {
    return this.createRotationFromScheduleSection;
  }

  public get rotationMinDate(): Date {
    return this.startDate;
  }

  public get rotationMaxDate(): Date {
    return this.endDate ? moment(this.endDate).subtract(6, 'd').toDate() : null;
  }

  public get rotationWeeks(): number[] {
    if (!this.endDate || !this.createRotation.startDate) {
      return [];
    }

    const endDate = moment(this.endDate);
    const startDate = moment(this.createRotation.startDate);

    const weeksDifference = Math.floor((endDate.diff(startDate, 'd') + 1) / 7);

    if (weeksDifference === 1) {
      return [1];
    }

    return _.range(1, weeksDifference + 1);
  }

  private modalService: ModalService;
  private appConfig: IApplicationConfig;
  private screenUtils: IScreenUtils;
  private lookupApiService: LookupApiService;
  private columnSettingsStorageService: ColumnSettingsStorageService;


  public static openDialog(request: MasterScheduleContextMenuRequest, modalService: ModalService, callback: (result: boolean, cmd: string, payload: any) => void): MasterScheduleOptionsDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 480;
    dialogOptions.width = 340;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: MasterScheduleContextMenuRequest,
        useValue: request
      }
    ];

    let header: string = request.empName;

    let dialog: MasterScheduleOptionsDialogComponent = modalService.globalAnchor
      .openDialog(MasterScheduleOptionsDialogComponent, header, dialogOptions, resolvedProviders, (result: boolean, cmd?: string, payload?: any) => {
        if (result) {
          cmd = request.selectedCommand;
        }
        switch(cmd) {
          case 'CreateRotationFromSchedule':
            payload = request.selectedCommandPayload;
            break;
        }
        callback(result, cmd, payload);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    lookupApiService: LookupApiService,
    private managementService: MasterScheduleManagementService,
    private appSettingsManageService: AppSettingsManageService,
    request: MasterScheduleContextMenuRequest,
    columnSettingsStorageService: ColumnSettingsStorageService,
    private ScheduleEntryApiService:ScheduleEntryApiService

  ) {
    this.modalService = modalService;
    this.request = request;
    this.empId = request.empId;
    this.startDate = request.startDate;
    this.endDate = request.endDate;
    this.options = options;
    this.lookupApiService = lookupApiService;
    this.columnSettingsStorageService = columnSettingsStorageService;
    let isMobile: boolean = screenUtils.isMobile;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.screenUtils = screenUtils;
    this.selectedCommand = '';
    this.getSettings();
    this.actionsSubscription = this.managementService.actions$.subscribe((data: IScheduleActions) => {
      this.actions = data;
    });
  }

  public getEmployeeId(): string {
    return this.empId;
  }

  public getDates(): string {
    return moment(this.startDate).format('MM/DD/YYYY') + ' - ' + moment(this.endDate).format('MM/DD/YYYY');
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public disableBtnOnClick(command:string){
    this.disableButtons.disableDeletebtn = command == 'DeleteSchedule' ? true : false;
    this.disableButtons.disableGeneratebtn = command == 'GenerateSchedule' ? true : false;
  }
  public enableBtnOnClick(command:string){
    command == 'DeleteSchedule' ? this.disableButtons.disableDeletebtn = false : this.disableButtons.disableGeneratebtn = false;
  }
  public onSelect(command: string): void {
    if (command == 'DeleteSchedule' || 'GenerateSchedule') {
      this.disableBtnOnClick(command);
      if (command == 'DeleteSchedule' && _.isEmpty(this.request.rowData.cell)) {
        this.request.selectedCommand = command;
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
        this.enableBtnOnClick(command);
      } else {
        let EmpId = this.empId;
        let StartDate = moment(this.startDate).format("MM-DD-YYYY");
        let EndDate = moment(this.endDate).format("MM-DD-YYYY");
        this.ScheduleEntryApiService.checkApprovedPayperiod(EmpId, StartDate, EndDate).then((data: any) => {
          this.approvedPayPeriod = data;
          if (this.approvedPayPeriod == true && this.isModifyPayPeriodApproved) {
            const message = this.actions.canEditForApprovedPayPeriod ?
              `This will modify a schedule in an approved pay period and impact the PBJ Calculation for the 
              employee, are you sure you want to continue?`: 
              `You do not have permissions to modify a schedule in an approved pay period`;
            let popupOptions: ConfirmOptions = new ConfirmOptions();
            popupOptions.showCancel = true;
            popupOptions.showOK = this.actions.canEditForApprovedPayPeriod ? true : false;
            popupOptions.buttonOKtext = 'Ok';
            popupOptions.buttonCanceltext = this.actions.canEditForApprovedPayPeriod ? 'Cancel' : 'Ok';
            ConfirmDialog2Component.openDialog(
              'Warning',
              message,
              this.modalService,
              (result: boolean) => {
                if (result) {
                  this.request.selectedCommand = command;
                  this.dialogResult = true;
                  this.modalService.closeWindow(this.options.windowUniqueId);
                }
                this.enableBtnOnClick(command);
              }, popupOptions
            );
          }
          else {
            this.request.selectedCommand = command;
            this.dialogResult = true;
            this.modalService.closeWindow(this.options.windowUniqueId);
            this.enableBtnOnClick(command);
          }
        });
      }
    }
  }

  public onGenerateScheduleAction(acceptPopover: PopoverContentComponent, result: boolean): void {
    acceptPopover.hide();
    if (result) {
      this.onSelect('GenerateSchedule');
    }
  }

  public toggleCreateRotationFromSchedule(): void {
      this.createRotationFromScheduleSection = !this.createRotationFromScheduleSection;
  }

  public onSaveAction(popover: PopoverContentComponent, confirmed: boolean): void {
    popover.hide();

    if (confirmed) {
      this.request.selectedCommandPayload = this.createRotation;
      this.onSelect('CreateRotationFromSchedule');
    }
  }

  public onCreateRotationStartDateChange(): void {
    this.createRotation.weeks = 1;
  }
  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.isModifyPayPeriodApproved =  config.ModifySchedulesApprovedinPayPeriods;
  }
}
