import * as _ from 'lodash';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ColumnManagementService, StateManagementService, ModalService } from '../../../../../common/services';
import { PmConfigurationManagementService } from '../../../services';
import { LookupService } from '../../../../../organization/services';
import { IScreenUtils, screenUtils } from '../../../../../common/utils';
import { IApplicationConfig, appConfig } from '../../../../../app.config';
import { PmTemplateDefinition } from '../../../../../organization/models';
import { KendoGridStateHelper, ConfirmOptions, ConfirmDialogComponent, saveEvent, removeEvent } from '../../../../../common';
import { SortDescriptor, process, State } from '@progress/kendo-data-query';
import { GridDataResult, EditEvent, PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  selector: 'slx-pm-templates-grid',
  templateUrl: './pm-templates-grid.component.html',
  styleUrls: ['./pm-templates-grid.component.scss'],
  providers: [
    StateManagementService, ColumnManagementService
  ]
})
export class PmTemplatesGridComponent implements OnInit {

  @Input()
  public set templates(c: PmTemplateDefinition[]) {
    this.m_templates = c;
    this.refreshGrid();
  }

  public get templates(): PmTemplateDefinition[] {
    return this.m_templates;
  }

  public canCreateNew = true;
  public canEdit = true;

  public gridState: KendoGridStateHelper<PmTemplateDefinition>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public pageSize: number = 50;

  public readonly columnsGroupName: string = 'PerformanceManagementConfigureTpl';
  public readonly columnsStateName: string = 'PerformanceManagementConfigureTpl';
  public screenUtils: IScreenUtils;
  public appConfig: IApplicationConfig;


  public xlsxName;
  public pdfName;

  public readonly itemClass: any = PmTemplateDefinition;

  private readonly xlsxNameConst = 'Performance_Templates_';
  private readonly pdfNameConst = 'Performance_Templates_';

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  private ngFormChild: NgForm;


  private m_templates: PmTemplateDefinition[];

  private subscriptions: StringMap<Subscription> = {};

  constructor (
    private management: PmConfigurationManagementService,
    private stateManagement: StateManagementService,
    private columnManagementService: ColumnManagementService,
    private lookupService: LookupService,
    private modalService: ModalService
  ) {
    this.appConfig = appConfig;
    this.screenUtils = screenUtils;

    this.gridState = new KendoGridStateHelper<PmTemplateDefinition>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'modifiedDate', dir: 'desc' }
    ];

  }

  public ngOnInit(): void {
    this.initServices();
    this.createSubscriptions();
    this.refreshGrid();
  }

  public createSubscriptions(): void {

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<PmTemplateDefinition>) => {
      if (event.isNew) {
        this.doAdd(event.dataItem);
        return;
      }
      this.doEdit(event.dataItem, event.rowIndex);
    });

    this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<PmTemplateDefinition>) => {
      this.startRemove(event.dataItem, event.rowIndex);
    });

    this.subscriptions.addNewTplSubscription = this.management.subscribeToAddNewTemplate((newTpl: PmTemplateDefinition) => {
      this.grid.addRow(newTpl);
    });

    this.subscriptions.exportSubscription = this.management.subscribeToExportTemplates((isPdf: boolean) => {
      let date = new Date();
      this.xlsxName = this.xlsxNameConst + `${date}.xlsx`;
      this.pdfName = this.pdfNameConst + `${date}.pdf`;
      if (isPdf) {
        this.grid.saveAsPDF();
      } else {
        this.grid.saveAsExcel();
      }
    });

  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.m_templates, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  public editHandler(event: EditEvent): void {
    this.gridState.editHandler(event);
  }

  protected startRemove(dataItem: PmTemplateDefinition, rowIndex: number) {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialogComponent.openDialog(
      'Confirmation',
      'Are you sure you want to delete? This template will also be deleted from the  template section of employee',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.doRemove(dataItem, rowIndex);
        }
      }, options);
  }

  protected doRemove(dataItem: PmTemplateDefinition, rowIndex: number) {
    this.management.removeTemplate(dataItem);
  }

  protected doEdit(dataItem: PmTemplateDefinition, rowIndex: number) {
    this.management.updateTemplate(dataItem)
      .then(() => {
        this.gridState.closeEditor(this.grid);
      });
  }

  protected doAdd(dataItem: PmTemplateDefinition) {
    this.management.addNewTemplate(dataItem)
      .then(() => {
        this.gridState.closeEditor(this.grid);
      });
  }

  private initServices(): void {
    this.stateManagement.init('PerformanceManagementConfigureTpl');
    this.columnManagementService.init('PerformanceManagementConfigureTpl');
    this.columnManagementService.initGroup(this.columnsStateName, 7);
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  private refreshGrid(): void {
    if (!this.m_templates) {
      this.gridView = null;
      return;
    }

    this.gridState.view = process(this.m_templates, this.gridState.state);
  }
}
