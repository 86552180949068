import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/do';
import { GridDataResult, SelectionEvent, RowArgs } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../app.config';

import { IDialog, DialogOptions, ModalService } from '../../../common/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';

import { ScheduleApiService } from '../../services/index';
import { Backup } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-restore-schedule',
  templateUrl: 'restore-schedule.component.html',
  styleUrls: ['restore-schedule.component.scss']
})
export class RestoreScheduleComponent implements OnInit, OnDestroy, IDialog {
  public dialogResult: boolean;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @Output()
  public onCancel: EventEmitter<any>;

  public appConfig: IApplicationConfig;

  public state: {
    isLoading: boolean;
  };
  public backupGridData: GridDataResult;
  public selectedBackup: Backup;

  @unsubscribe()
  private backupSubscription: Subscription;
  private currentOrgLevel: OrgLevel;

  constructor(
    private scheduleApiService: ScheduleApiService,
    private options: DialogOptions,
    private modalService: ModalService) {
    this.appConfig = appConfig;
    this.state = {
      isLoading: false
    };

    this.onCancel = new EventEmitter<any>();
  }

  public ngOnInit(): void {
    this.state.isLoading = true;
    this.backupSubscription = this.orgLevel$
      .do((orgLevel: OrgLevel) => this.currentOrgLevel = orgLevel)
      .switchMap((orgLevel: OrgLevel) => this.scheduleApiService.getBackups(orgLevel))
      .subscribe((backups: Backup[]) => {
        this.backupGridData = {
          data: backups,
          total: backups.length
        };
        this.state.isLoading = false;
      });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onSelectedChanged(selection: SelectionEvent): void {

    this.selectedBackup = null;
    _.forEach(selection.selectedRows, (args: RowArgs) => {
      this.selectedBackup = this.backupGridData.data[args.index];
    });

    /* Prior version
      if ($event.selected) {
        this.selectedBackup = this.backupGridData.data[$event.index];
      } else {
        this.selectedBackup = null;
      }
    */
  }

  public restoreBackup(): void {
    if (this.selectedBackup) {
      this.state.isLoading = true;
      this.scheduleApiService.backupApply(this.currentOrgLevel, this.selectedBackup.id)
        .then(() => {
          this.state.isLoading = false;
          this.dialogResult = true;
          this.modalService.closeWindow(this.options.windowUniqueId);
        })
        .catch(() => {
          this.state.isLoading = false;
        });
    }
  }

  public cancelRestoring(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
