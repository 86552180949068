import { NotificationGroupEvent, INotificationGroupEvent } from "./notifications-group-event";
import { INotificationRole, NotificationRole } from "./notifications-role";

export interface INotificationGroup {

    groupId: number;
    orgLevelId:number;
    groupName: string;
    modifiedBy: string;
    modifiedOn: Date;
    deliveryMethod: string;
    groupEvents: INotificationGroupEvent; 
    groupRoles : INotificationRole [];
    displayRoles : string;
    displayEvents : string;
}

export class NotificationGroup {
    
    public groupId: number;
    public orgLevelId:number;
    public groupName: string;
    public modifiedBy: string;
    public modifiedOn: Date;
    public deliveryMethod: string;
    public groupEvents: NotificationGroupEvent; 
    public groupRoles : NotificationRole [];
    // useful for filter in view mode
    public displayRoles : string;
    public displayEvents : string;
 
}
