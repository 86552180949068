<slx-spinner [show]="isLoading">
<div id="mapping-modal-body" class="modal-body">
  <div class="slx-full-height">
    <kendo-grid
      #kendoGrid
      class="slx-blue-grid slx-grid-slim-rows slx-full-height existing-mapping-grid__height"
      slxKendoGridState="BENEFITS_PAYROLL_EXISTING_MAP"
      [data]="benefitPayrollExistingMap"
    >
      <kendo-grid-column
        title="HR Benefits"
        width="150"
        field="benefitName"
      >
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>HR Benefits</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.benefitName }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="Payroll Deduction"
        width="150"
        field="payrollDeduction.description"
      >
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Payroll Deduction</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.payrollDeduction?.description }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn gray"
    (click)="close()"
  >
    <i class="fa fa-times" aria-hidden="true"></i>
    <span class="btn-close__text">Close</span>
  </button>
</div>
</slx-spinner>
