import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { UserProfileManagementService } from '../../../services/index';
import { UserProfileSectionBaseComponent } from '../user-profile-sections/user-profile-section-base.component';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var UserProfilePanelHeaderComponent = /** @class */ (function () {
    function UserProfilePanelHeaderComponent(management) {
        this.management = management;
    }
    UserProfilePanelHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isEditMode: false,
            addSelectedRole: false
        };
        this.onSectionEnteredEditState = this.management.onSectionEnteredEditState$.subscribe(function (section) {
            if (_this.section === section) {
                _this.state.isEditMode = true;
            }
            else {
                _this.otherSectionEditing = true;
            }
        });
        this.sectionDiscardedSubscription = this.management.onSectionDiscarded$.subscribe(function (section) {
            if (_this.section === section) {
                _this.state.isEditMode = false;
            }
            else {
                _this.otherSectionEditing = false;
            }
        });
        this.sectionSavedSubscription = this.management.onSectionSaved$.subscribe(function (section) {
            if (_this.section === section) {
                _this.state.isEditMode = false;
            }
            else {
                _this.otherSectionEditing = false;
            }
        });
        this.addRolesModeSubscription = this.management.onAddRoleModeSwitch$.subscribe(function (state) {
            _this.state.addSelectedRole = state;
        });
    };
    UserProfilePanelHeaderComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    UserProfilePanelHeaderComponent.prototype.onEditClick = function () {
        this.management.editSection(this.section);
    };
    UserProfilePanelHeaderComponent.prototype.onSaveClick = function () {
        this.management.saveSection(this.section);
    };
    UserProfilePanelHeaderComponent.prototype.onDiscardClick = function () {
        this.management.discardSection(this.section);
    };
    UserProfilePanelHeaderComponent.prototype.onAddRoleClick = function () {
        this.management.onResetRoles();
        this.management.onAddRole();
    };
    UserProfilePanelHeaderComponent.prototype.onDeleteRoleClick = function () {
        this.management.onDeleteRole();
    };
    UserProfilePanelHeaderComponent.prototype.onSaveAddedClick = function () {
        this.management.addSelectedRole();
    };
    UserProfilePanelHeaderComponent.prototype.onCancelAddClick = function () {
        this.management.cancelAddSelectedRole();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfilePanelHeaderComponent.prototype, "sectionSavedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfilePanelHeaderComponent.prototype, "sectionDiscardedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfilePanelHeaderComponent.prototype, "onSectionEnteredEditState", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfilePanelHeaderComponent.prototype, "addRolesModeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfilePanelHeaderComponent.prototype, "profileLoadedSubscription", void 0);
    return UserProfilePanelHeaderComponent;
}());
export { UserProfilePanelHeaderComponent };
