import * as _ from 'lodash';

import { OrgLevelType } from '../../state-model/models/index';
import { IMenuItemMapping } from './index';

export class NavigationMenuType {
  public static application: NavigationMenuTypeDefinition = 'Application';
  public static menuItem: NavigationMenuTypeDefinition = 'MenuItem';
}

export type NavigationMenuTypeDefinition = 'Application' | 'MenuItem';

export interface INavigationMenuItem {
  id: number;
  name: string;
  displayName: string;
  type: NavigationMenuTypeDefinition;
  order: number;
  parentId: number;
  applicationId: number;
  supportedOrgTypes: OrgLevelType[];
  isAvailableOnMobile: boolean;
  isGlobal: boolean;
  childLevelsCount?: number;
  allChildrenDisabled?: boolean;
}

export class NavigationMenuItem {
  public id: number;
  public name: string;
  public appLink: string;
  public link: string;
  public displayName: string;
  type: NavigationMenuTypeDefinition;
  public order: number = 1000;
  public parentId: number;
  public applicationId: number;
  public supportedOrgTypes: OrgLevelType[];
  public childs: NavigationMenuItem[];
  public menuItemMapping: IMenuItemMapping;
  public isExpanded: boolean;
  public isHovered: boolean;
  public isSelected: boolean;
  public isAvailableOnMobile: boolean;
  public isGlobal: boolean;
  public isFavorite: boolean;
  public childLevelsCount?: number;
  public allChildrenDisabled?: boolean;
}


export class NavigationMenuEventType {
  public static new: NavigationMenuEventDefinition = 'New';
  public static reselect: NavigationMenuEventDefinition = 'Reselect';
  public static notexist: NavigationMenuEventDefinition = 'NotExist';
  public static empty: NavigationMenuEventDefinition = 'Empty';
}
export type NavigationMenuEventDefinition = 'New' | 'NotExist' | 'Empty' | 'Reselect';


export interface INavigationMenuEvent {
  type: NavigationMenuEventType;
  menuItem?: NavigationMenuItem;
}

export function flattenMenu(items: NavigationMenuItem[]): NavigationMenuItem[] {
  let flatItems = _.reduce(items, (result: NavigationMenuItem[], curr: NavigationMenuItem) => {
    result = result.concat(curr);
    if (curr.childs && curr.childs.length > 0) {
      result = result.concat(flattenMenu(curr.childs));
    }
    return result;
  }
    , []);
  return flatItems;
}
