import { CensusEntry, ICensusEntry } from './census-entry';
import * as _ from 'lodash';
export interface IAcuityConfiguration {
  date: Date;
  capacity: number;
  items: IAcuity[];
  budgetedCensus: number;
  canEditCensus: boolean;
}

export class AcuityConfiguration {
  public date: Date;
  public capacity: number;
  public items: Acuity[];
  public budgetedCensus: number;
  public copyToFutureDates: boolean = false;
  public canEditCensus: boolean = false;
}

export interface IAcuity {
  type: string;
  entries: ICensusEntry[];
}

export class Acuity {
  public type: string;
  public entries: CensusEntry[];
  private m_capacity: number = 0;

  public getField(name: string): any {
    let value: any = _.find(this.entries, (item: CensusEntry) => {
      return item.category === name;
    }).value;

    return value;
  }

  public setField(name: string, value: any): any {
    _.find(this.entries, (item: CensusEntry) => {
      return item.category === name;
    }).value = value;
    this.recalcCapacity();
  }

  public get capacity(): number {
    return this.m_capacity;
  }

  public recalcCapacity(): void {
    if (!this.entries) return;
    this.m_capacity = this.entries.map((e: CensusEntry) => e.value)
      .reduce((prev: number, current: number) => prev + current, 0);
  }
}
