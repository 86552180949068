export interface IEmployeeSMSContacts {
    employeeId:number;
    employeeName: string;
    firstName: string;
    lastName: string;
    middleName : string;
    phoneNumber : string;
    mobilePhoneNumber : string;
    alternativePhoneNumber : string;
    departmentId:number;
    departmentName: string;
    jobCode:number;
    jobDescription: string;
    organizationId:number;
    organizationName: string;
    empOptIn: number; 
    profilePicture: string;
    countryCode: string;
    badgeId: number;
    }
    
export class EmployeeSMSContacts {
    public employeeId:number;
    public employeeName: string;
    public firstName: string;
    public lastName: string;
    public middleName : string;
    public phoneNumber : string;
    public mobilePhoneNumber : string;
    public alternativePhoneNumber : string;
    public departmentId:number;
    public departmentName: string;
    public jobCode:number;
    public jobDescription: string;
    public organizationId:number;
    public organizationName: string;
    public empOptIn: number; 
    public profilePicture: string;
    public countryCode: string;
    public badgeId: number;
    }
