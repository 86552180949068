import * as tslib_1 from "tslib";
import { FavoriteManagementService } from './../../../configuration/services/favorites/favorites-management.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { ApplicationService, ApplicationDashboardManagementService } from '../../services/index';
import { Assert } from '../../../framework/index';
import { OrgLevel, OrgLevelLocation } from '../../../state-model/models/index';
import { ApplicationActions } from '../../actions/index';
import { StyledUserApplication, DashboardApplication, DashboardApplications } from '../../../organization/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { Router } from '@angular/router';
import { SoDashboardCounters, TaDashboardCounters } from '../../models/index';
import { ClientMessageManagementService } from '../../../authentication/services/client-message/client-message-management.service';
import { appConfig } from '../../../app.config';
import { screenUtils } from '../../../common/utils/index';
var ApplicationsDashboardComponent = /** @class */ (function () {
    function ApplicationsDashboardComponent(applicationService, appManagementService, applicationActions, clientMessageManagementService, orgLevelWatchService, router, route, favoriteService) {
        this.applicationService = applicationService;
        this.appManagementService = appManagementService;
        this.applicationActions = applicationActions;
        this.clientMessageManagementService = clientMessageManagementService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.router = router;
        this.route = route;
        this.favoriteService = favoriteService;
        this.maxHeight = 400;
        this.favoriteIsEmpty = true;
        Assert.isNotNull(applicationService, 'applicationService');
        Assert.isNotNull(applicationActions, 'applicationActions');
        this.isLoading = true;
        this.user = null;
        this.counters = null;
        this.appConfig = appConfig;
        this.calcMaxHeight();
    }
    ApplicationsDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.applicationUpdateSubscription = this.applicationService.applicationsUpdated$
            .subscribe(function (apps) {
            _this.applications = apps;
            _this.loadDashboardApps(apps);
            _this.calcMaxHeight();
            if (!_this.dataSubscription) {
                _this.dataSubscription = _this.orgLevel$
                    .subscribe(function (orgLevel) {
                    if (!orgLevel || !orgLevel.id)
                        return;
                    _this.orgLevel = orgLevel;
                    _this.clientMessageManagementService.loadMessages(_this.orgLevel.id);
                    _this.isLoading = true;
                    _this.orgLevelWatchService.getLocation(_this.orgLevel)
                        .then(function (location) {
                        _this.orgLevelLocation = location;
                    });
                });
            }
        });
        this.dashboardAppSubscription = this.appManagementService.dashboardApps$.subscribe(function (apps) {
            if (apps.applications) {
                _this.mapDashboardAppToStyledApp(apps);
                if (!_this.orgLevel || !_this.orgLevel.id)
                    return;
                _this.isLoading = true;
                _this.appManagementService.loadAppCounters(_this.applications, _this.orgLevel);
            }
        });
        this.userSubscription = this.user$.subscribe(function (user) {
            if (user && _.isNumber(user.id)) {
                _this.user = user;
            }
        });
        this.favoriteSubscription = this.favoriteService.favoriteItemsUpdated$.subscribe(function (favorites) {
            _this.favoriteList = favorites;
            _this.favoriteIsEmpty = favorites.length === 0;
        });
    };
    ApplicationsDashboardComponent.prototype.onLoading = function (isLoading) {
        this.isLoading = isLoading;
    };
    ApplicationsDashboardComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ApplicationsDashboardComponent.prototype.getCounters = function (appName) {
        return this.counters && this.counters[appName] ? this.counters[appName] : null;
    };
    ApplicationsDashboardComponent.prototype.hasUnreadMessages = function () {
        return _.isObject(this.user) && _.isNumber(this.user.messagesUnread);
    };
    ApplicationsDashboardComponent.prototype.isHidden = function (app) {
        return this.isMobile() && !app.isAvailableOnMobile;
    };
    ApplicationsDashboardComponent.prototype.isMobile = function () {
        return screenUtils.isMobile;
    };
    ApplicationsDashboardComponent.prototype.calcMaxHeight = function () {
        if (this.isMobile()) {
            this.maxHeight = 1000;
            return;
        }
        var expandedDashboards = 0;
        _.forEach(this.applications, function (app) {
            if (app.loadCounters) {
                expandedDashboards++;
            }
            expandedDashboards = expandedDashboards + (app.dashboards ? app.dashboards.length : 0);
        });
        if (expandedDashboards > 2) {
            this.maxHeight = 600;
        }
    };
    ApplicationsDashboardComponent.prototype.openFavoritesConfig = function () {
        this.favoriteService.showConfigDialog();
    };
    ApplicationsDashboardComponent.prototype.updateItemsToReview = function () {
        var _this = this;
        var result = false;
        _.each(this.applications, function (app) {
            var counters = _this.getCounters(app.name);
            if (counters && counters.total > 0) {
                result = true;
            }
        });
        return result;
    };
    ApplicationsDashboardComponent.prototype.openAppConfig = function () {
        this.appManagementService.showConfigDialog();
    };
    ApplicationsDashboardComponent.prototype.loadDashboardApps = function (appList) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var apps;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appManagementService.loadApps(true)];
                    case 1:
                        apps = _a.sent();
                        this.mapDashboardAppToStyledApp(apps);
                        return [2 /*return*/];
                }
            });
        });
    };
    ApplicationsDashboardComponent.prototype.mapDashboardAppToStyledApp = function (apps) {
        var _this = this;
        this.userAppsPref = [];
        _.forEach(this.applications, function (app) {
            var result = _.find(apps.applications, ['appId', app.id]);
            if (_.isUndefined(result) || (!_.isUndefined(result) && (_.isUndefined(result.visible) || result.visible)) || app.id == appConfig.moreV5ItemsApplication.id) {
                app.index = _.isUndefined(result) ? 999 : result.displayOrder;
                _this.userAppsPref.push(app);
            }
        });
        this.userAppsPref = _.sortBy(this.userAppsPref, 'index');
        if (this.userAppsPref.length == 0) {
            this.appsIsEmpty = true;
        }
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], ApplicationsDashboardComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], ApplicationsDashboardComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ApplicationsDashboardComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApplicationsDashboardComponent.prototype, "countersSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApplicationsDashboardComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApplicationsDashboardComponent.prototype, "dataSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApplicationsDashboardComponent.prototype, "applicationUpdateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApplicationsDashboardComponent.prototype, "favoriteSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApplicationsDashboardComponent.prototype, "dashboardAppSubscription", void 0);
    return ApplicationsDashboardComponent;
}());
export { ApplicationsDashboardComponent };
