export interface IWcMedicalTreatmentDefinitionDto {
    id: number;
    name: string;
}

export enum WcMedicalTreatmentEnum {
    None, FirstAidReceivedAtWork, WcPanelPhysician, Other
}

export class WcMedicalTreatmentDefinition {
    public id: WcMedicalTreatmentEnum;
    public name: string;
}
