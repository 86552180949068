import * as tslib_1 from "tslib";
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';
var KendoGridBooleanFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridBooleanFilterComponent, _super);
    function KendoGridBooleanFilterComponent(filterService, localization) {
        var _this = _super.call(this, filterService, localization) || this;
        _this.operator = 'eq';
        _this.items = [
            { text: 'Y', value: true },
            { text: 'N', value: false }
        ];
        _this.defaultItem = { text: 'All', value: null };
        _this.showOperators = false;
        _this.operators = [];
        return _this;
    }
    KendoGridBooleanFilterComponent.prototype.onClearFilters = function () {
        if (this.dropdown) {
            this.dropdown.reset();
        }
    };
    return KendoGridBooleanFilterComponent;
}(KendoGridBasicFilterComponent));
export { KendoGridBooleanFilterComponent };
