import * as tslib_1 from "tslib";
import { BusyService } from "../../../common/services";
import { NotificationsService } from '../../../core/components';
import { WfmSyncService } from '../../services/wfm/wfm-sync.service';
import { BaseSyncComponent } from './wfm-sync-base.component';
var BaseFilterSyncComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BaseFilterSyncComponent, _super);
    function BaseFilterSyncComponent(wfmSyncService, notificationService, busyService) {
        var _this = _super.call(this, notificationService, busyService) || this;
        _this.wfmSyncService = wfmSyncService;
        _this.canSelectPosition = false;
        return _this;
    }
    BaseFilterSyncComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.fetchOrganizationLookup();
    };
    BaseFilterSyncComponent.prototype.onOrganizationFilterChanged = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.departmentLookup = null;
                        this.positionLookup = null;
                        this.selectedDepartment = null;
                        this.selectedPosition = null;
                        return [4 /*yield*/, this.fetchDepartmentLookup()];
                    case 1:
                        _a.sent();
                        this.setGlobalFilter();
                        return [2 /*return*/];
                }
            });
        });
    };
    BaseFilterSyncComponent.prototype.onDepartmentFilterChanged = function (dep) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.positionLookup = null;
                        this.selectedPosition = null;
                        if (dep.name == "ALL") {
                            this.canSelectPosition = true;
                        }
                        else {
                            this.canSelectPosition = false;
                        }
                        return [4 /*yield*/, this.fetchPositionLookup()];
                    case 1:
                        _a.sent();
                        this.setGlobalFilter();
                        return [2 /*return*/];
                }
            });
        });
    };
    BaseFilterSyncComponent.prototype.onPositionFilterChanged = function () {
        this.setGlobalFilter();
    };
    BaseFilterSyncComponent.prototype.fetchOrganizationLookup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.orgDataDetails(0, true))];
                    case 1:
                        response = _a.sent();
                        this.organizationLookup = response.data;
                        return [2 /*return*/];
                }
            });
        });
    };
    BaseFilterSyncComponent.prototype.fetchDepartmentLookup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.depDataDetails(null, this.selectedOrganization.orgLevelId, true))];
                    case 1:
                        response = _a.sent();
                        this.departmentLookup = response.data;
                        return [2 /*return*/];
                }
            });
        });
    };
    BaseFilterSyncComponent.prototype.fetchPositionLookup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.posDataDetails(null, this.selectedDepartment.orgLevelId, true))];
                    case 1:
                        response = _a.sent();
                        this.positionLookup = response.data;
                        return [2 /*return*/];
                }
            });
        });
    };
    return BaseFilterSyncComponent;
}(BaseSyncComponent));
export { BaseFilterSyncComponent };
