export * from './wc-report-attachments/wc-report-attacments.component';
export * from './wc-report-section/wc-report-section.component';
export * from './wc-compensation-report/wc-worker-compensation-report.component';
export * from './wc-osha-report/wc-osha-report.component';
export * from './wc-injury-report/wc-injury-report.component';
export * from './wc-incident-report-header/wc-incident-report-header.component';
export * from './wc-incident-report/wc-incident-report.component';
export * from './wc-incident-report-container/wc-incident-report-container.component';
export * from './wc-incident-report-dialog/wc-incident-report-dialog.component';

import { WcReportAttachmentsComponent } from './wc-report-attachments/wc-report-attacments.component';
import { WcReportSectionComponent } from './wc-report-section/wc-report-section.component';
import { WcWorkerCompensationReportComponent } from './wc-compensation-report/wc-worker-compensation-report.component';
import { WcOshaReportComponent } from './wc-osha-report/wc-osha-report.component';
import { WcInjuryReportComponent } from './wc-injury-report/wc-injury-report.component';
import { WcIncidentReportHeaderComponent } from './wc-incident-report-header/wc-incident-report-header.component';
import { WcIncidentReportComponent } from './wc-incident-report/wc-incident-report.component';
import { WCIncidentReportContainerComponent } from './wc-incident-report-container/wc-incident-report-container.component';
import { WCIncidentReportDialogComponent } from './wc-incident-report-dialog/wc-incident-report-dialog.component';

export const crudComponents: any[] = [
    WcReportAttachmentsComponent,
    WcReportSectionComponent,
    WcWorkerCompensationReportComponent,
    WcOshaReportComponent,
    WcInjuryReportComponent,
    WcIncidentReportComponent,
    WcIncidentReportHeaderComponent,
    WCIncidentReportContainerComponent,
    WCIncidentReportDialogComponent
];