import { Injectable } from '@angular/core';
import { IServerValidatorAdapter, IServerValidationResult } from '../../../common/validators/common-validators-models';
import { EmployeeDefinitionsApiService } from './employee-definitions-api.service';
import { INamesValidation } from '../../models/index';
import * as _ from 'lodash';
import { EmployeeCostCenterAllocation } from '../../../employee/employee';

@Injectable()
export class EmployeeValidatorAdapter implements IServerValidatorAdapter {
  public static payrollNumberValidation: string = 'payrollNumberValidation';
  public static ssnValidation: string = 'ssnValidation';
  public static namesValidation: string = 'namesValidation';
  public static badgeIdValidation: string = 'badgeIdValidation';
  public static pbjIdValidation: string = 'pbjIdValidation';
  public static emailValidation: string = 'emailValidation';
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  constructor(employeeDefinitionsApiService: EmployeeDefinitionsApiService) {
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
  }
  public validate(validationName: string, value: any, ...params: any[]): Promise<IServerValidationResult> {
    let promise: Promise<IServerValidationResult>;
    let defPromise: Promise<IServerValidationResult> = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
    switch (validationName) {
      case EmployeeValidatorAdapter.payrollNumberValidation:
        promise = this.employeeDefinitionsApiService.validatePayrollNumber(value, params[0], params[1]);
        break;
      case EmployeeValidatorAdapter.ssnValidation:
        if (value === null || value === undefined || value === '') {
          return defPromise;
        }
        promise = this.employeeDefinitionsApiService.validateSsn(value, params[0], params[1]);
        break;
      case EmployeeValidatorAdapter.badgeIdValidation:
        promise = this.employeeDefinitionsApiService.validateBadgeId(value, params[0]);
        break;
      case EmployeeValidatorAdapter.namesValidation:
        let model: INamesValidation = params[0];
        let firstName: string = model.firstName;
        let lastName: string = model.lastName;
        let middleName: string = model.middleName;
        let suffix: string = model.suffix;
        let departmentOrgLevelId: number = model.departmentOrgLevelId;
        if (_.isString(firstName) && _.isString(lastName) && _.isNumber(departmentOrgLevelId)) {
          promise = this.employeeDefinitionsApiService.validateNames(firstName, lastName, middleName, suffix, departmentOrgLevelId);
        } else {
          promise = Promise.resolve({ isValid: false, isReadyForValidation: false, errorMessage: 'Not all required fields are filled' });
        }
        break;
      case EmployeeValidatorAdapter.pbjIdValidation:
        if (value === null || value === undefined || value === '') {
          return defPromise;
        }
        promise = this.employeeDefinitionsApiService.validatePbjId(value, params[0]);
        break;
      case EmployeeValidatorAdapter.emailValidation:
        if (value === null || value === undefined || value === '') {
          return defPromise;
        }
        promise = this.employeeDefinitionsApiService.isValidEmail(value, params[0]);
        break;
      default:
        promise = defPromise;
    }
    return promise;
  }
}
