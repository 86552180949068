import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { dateTimeUtils } from '../../../../common/utils/index';
import { MetaMapService } from '../../../../core/services/index';
import { FieldsMeta, Actions, ActionsType } from '../../../../core/models/index';
import { Assert } from '../../../../framework/index';
import {
  AccrualPolicy,
  IAccrualPolicy,
  Rules,
  IRules,
  SeniorityRules,
  ISeniorityRules,
  AccrualPolicyDefinitions

} from '../../models/index';
import { AppSettingsManageService } from './../../../../../app/app-settings/services';
import * as moment from 'moment';
import { DeletePolicy, IDeletePolicy } from '../../models/accruals-policy/delete-accrual-policy';
import { IUpdatePolicyName, UpdatePolicyName } from '../../models/accruals-policy/update-policy-name';
import { DatePipe } from '@angular/common';
import { AccrualOrgLevels, AccrualPolicyDetails, CopyAccrualPolicyResult, IAccrualOrgLevels, ICopyAccrualPolicyResult } from '../../models/accruals-policy/copy-accruals-policy';

@Injectable()
export class AccrualsPolicyMapService {

  constructor(
    private metaMapService: MetaMapService, private datePipe: DatePipe
  ) { }

  public mapAccrualPolicyDefinitions(dto: any, meta: FieldsMeta, isDetail: boolean): AccrualPolicyDefinitions {
    const data: AccrualPolicyDefinitions = new AccrualPolicyDefinitions();
    data.accrualPolicy = this.mapAccrualPolicy(dto, isDetail);
    data.actions = meta == null ? null : this.metaMapService.mapActions(meta);
    data.CreateorEditAccruals = meta ? this.mapCreateorEditAccrualsAccrualsAction(meta) : null;
    data.DeleteAccruals = meta ? this.mapDeleteAccrualsAction(meta) : null;
    data.CopyAccrualPolicy=meta ?this.mapCopyAccrualsAction(meta):null;
    return data;
  }

  public mapCreateorEditAccrualsAccrualsAction(meta: FieldsMeta): boolean {
    let bool: boolean = false;
    _.forEach(meta.actions, (act: string) => {
      if (act == 'CreateorEditAccruals') {
        bool = true;
      }
    });
    return bool;
  }

  public mapDeleteAccrualsAction(meta: FieldsMeta): boolean {
    let bool: boolean = false;
    _.forEach(meta.actions, (act: string) => {
      if (act == 'DeleteAccruals') {
        bool = true;
      }
    });
    return bool;
  }
  public mapCopyAccrualsAction(meta: FieldsMeta): boolean {
    let bool: boolean = false;
    _.forEach(meta.actions, (act: string) => {
      if (act == 'CopyAccrualPolicy') {
        bool = true;
      }
    });
    return bool;
  }
  public mapAccrualPolicy(data: IAccrualPolicy[], isDetail: boolean): AccrualPolicy[] {
    return _.map(data, item => this.mapAccrualPolicyList(item, isDetail));
  }

  public mapAccrualPolicyList(dto: IAccrualPolicy, isDetail: boolean): AccrualPolicy {
    const data: AccrualPolicy = new AccrualPolicy(dto.policyName);
    data.id = dto.id;
    data.policyName = dto.policyName;
    data.orgLevelId = dto.orgLevelId;
    data.lastUpdateId = dto.lastUpdateId;
    data.lastUpdateDate = dto.lastUpdateDate;
    data.policyStartDate = dto.policyStartDate;
    data.policyEndDate = dto.policyEndDate;

    if (isDetail) {
      data.rules = _.map(dto.rules, rule => this.mapAccrualRules(rule));
    }
    else {
      data.rules = [];
    }
    return data;
  }

  public mapAccrualRules(dtos: IRules): Rules {
    const data: Rules = new Rules();

    data.id = dtos.id;
    data.ruleName = dtos.ruleName;
    data.accrualTypeId = dtos.accrualTypeId;
    data.accrualTypeName = dtos.accrualTypeName;
    data.startDate = dtos.startDate ? dtos.startDate : null;
    data.endDate = dtos.endDate ? dtos.endDate : null;
    data.anchorDateSource = dtos.anchorDateSource;
    data.accrualCalcType = dtos.accrualCalcType;
    data.accrualCalcTypeName = dtos.accrualCalcTypeName;
    data.accruedOnPeriod = dtos.accruedOnPeriod;
    data.maxBasisHours = dtos.maxBasisHours;
    data.minimumRequiredHours = dtos.minimumRequiredHours;
    data.deferralPeriodValue = dtos.deferralPeriodValue;
    data.deferralPeriodType = dtos.deferralPeriodType;
    data.probationValue = dtos.probationValue;
    data.probationType = dtos.probationType;
    data.maxAnnualEarnedType = dtos.maxAnnualEarnedType;
    data.rollOverMax = dtos.rollOverMax;
    data.deleted = dtos.deleted;
    data.rateFormula = dtos.rateFormula;
    data.useRateFormula = dtos.useRateFormula;
    data.carryover = dtos.carryover;
    data.carryoverType = dtos.carryoverType;
    data.carryoverValue = dtos.carryoverValue;
    data.carryoverValueType = dtos.carryoverValueType;
    data.carryoverDay = dtos.carryoverDay;
    data.carryoverExpirationDays = dtos.carryoverExpirationDays;
    data.carryoverToAccrualTypeName = dtos.carryoverToAccrualTypeName;
    data.seniorityRules = _.map(dtos.seniorityRules, dto => this.mapAccrualSeniorityRules(dto));
    return data;

  }

  public mapAccrualSeniorityRules(dto: ISeniorityRules): SeniorityRules {
    const data: SeniorityRules = new SeniorityRules();
    data.id = dto.id;
    data.ruleId = dto.ruleId;
    data.periodFromValue = dto.periodFromValue;
    data.periodFromType = dto.periodFromType;
    data.periodToValue = dto.periodToValue;
    data.periodToType = dto.periodToType;
    data.accrualRate = dto.accrualRate;
    data.rateUnit = dto.rateUnit;
    data.basisUnit = dto.basisUnit;
    data.minRequiredHours = dto.minRequiredHours;
    data.accruedOnDay = dto.accruedOnDay;
    data.maxAccruedAnnual = dto.maxAccruedAnnual;
    data.maxAccruedBalance = dto.maxAccruedBalance;
    data.carryoverValue = dto.carryoverValue;
    data.carryoverType = dto.carryoverType;
    data.carryoverDay = dto.carryoverDay;
    data.carryoverValueType = dto.carryoverValueType;
    data.carryoverExpirationDays = dto.carryoverExpirationDays;
    data.ruleCalcType = dto.ruleCalcType;
    data.rateFormula = dto.rateFormula;
    data.accruedOnDates = dto.accruedOnDates;
    data.carryoverToAccrualTypeId = dto.carryoverToAccrualTypeId;
    data.carryoverToAccrualTypeName = dto.carryoverToAccrualTypeName;
    data.rollOverMax = dto.rollOverMax;
    data.accruedOn_Seniority = dto.accruedOn_Seniority;
    data.basisHour_Seniority = dto.basisHour_Seniority;
    return data;
  }


  public reverseMapAccrualPolicy(item: AccrualPolicy): IAccrualPolicy {
    let dto: IAccrualPolicy = <IAccrualPolicy>{};
    dto.id = item.id;
    dto.orgLevelId = item.orgLevelId;
    dto.policyName = item.policyName;
    dto.policyStartDate = this.datePipe.transform(item.policyStartDate, 'MM/dd/yyyy');
    dto.policyEndDate = this.datePipe.transform(item.policyEndDate, 'MM/dd/yyyy');
    dto.lastUpdateDate = item.lastUpdateDate;
    dto.lastUpdateId = item.lastUpdateId;
    dto.rules = _.map(item.rules, dto => this.reverseMapAccrualPolicyRules(dto));
    return dto;
  }

  public reverseMapAccrualPolicyRules(item: Rules): IRules {
    let dto: IRules = <IRules>{};
    dto.id = item.id;
    dto.ruleName = item.ruleName;
    dto.accrualTypeId = item.accrualTypeId;
    dto.accrualTypeName = item.accrualTypeName;
    dto.startDate = this.datePipe.transform(item.startDate, 'MM/dd/yyyy');
    dto.endDate = this.datePipe.transform(item.endDate, 'MM/dd/yyyy');
    dto.anchorDateSource = item.anchorDateSource;
    dto.accrualCalcType = item.accrualCalcType;
    dto.accrualCalcTypeName = item.accrualCalcTypeName;
    dto.accruedOnPeriod = item.accruedOnPeriod;
    dto.maxBasisHours = item.maxBasisHours;
    dto.minimumRequiredHours = item.minimumRequiredHours;
    dto.deferralPeriodValue = item.deferralPeriodValue;
    dto.deferralPeriodType = item.deferralPeriodType;
    dto.probationValue = item.probationValue;
    dto.probationType = item.probationType;
    dto.maxAnnualEarnedType = item.maxAnnualEarnedType;
    dto.rollOverMax = item.rollOverMax;
    dto.deleted = item.deleted;
    dto.rateFormula = item.rateFormula;
    dto.useRateFormula = item.useRateFormula;
    dto.carryover = item.carryover;
    dto.carryoverType = item.carryoverType;
    dto.carryoverValue = item.carryoverValue;
    dto.carryoverValueType = item.carryoverValueType;
    dto.carryoverDay = item.carryoverDay;
    dto.carryoverExpirationDays = item.carryoverExpirationDays;
    dto.carryoverToAccrualTypeName = item.carryoverToAccrualTypeName;
    dto.seniorityRules = _.map(item.seniorityRules, dto => this.reverseMapapAccrualSeniorityRules(dto));
    dto.oldPolicyName = item.oldPolicyName;
    dto.oldBenefitName = item.oldBenefitName;
    dto.rdoAccrualsTypePeriod = item.rdoAccrualsTypePeriod;
    dto.rdoAccrualsTypeDates = item.rdoAccrualsTypeDates;
    return dto;
  }

  public reverseMapapAccrualSeniorityRules(dto: ISeniorityRules): SeniorityRules {
    const data: SeniorityRules = new SeniorityRules();
    data.id = dto.id;
    data.ruleId = dto.ruleId;
    data.periodFromValue = dto.periodFromValue;
    data.periodFromType = dto.periodFromType;
    data.periodToValue = dto.periodToValue;
    data.periodToType = dto.periodToType;
    data.accrualRate = dto.accrualRate;
    data.rateUnit = dto.rateUnit;
    data.basisUnit = dto.basisUnit;
    data.minRequiredHours = dto.minRequiredHours;
    data.accruedOnDay = dto.accruedOnDay;
    data.maxAccruedAnnual = dto.maxAccruedAnnual;
    data.maxAccruedBalance = dto.maxAccruedBalance;
    data.carryoverValue = dto.carryoverValue;
    data.carryoverType = dto.carryoverType;
    data.carryoverDay = dto.carryoverDay;
    data.carryoverValueType = dto.carryoverValueType;
    data.carryoverExpirationDays = dto.carryoverExpirationDays;
    data.ruleCalcType = dto.ruleCalcType;
    data.rateFormula = dto.rateFormula;
    data.accruedOnDates = dto.accruedOnDates;
    data.carryoverToAccrualTypeId = dto.carryoverToAccrualTypeId;
    data.carryoverToAccrualTypeName = dto.carryoverToAccrualTypeName;
    data.rollOverMax = dto.rollOverMax;
    data.accruedOn_Seniority = dto.accruedOn_Seniority;
    data.basisHour_Seniority = dto.basisHour_Seniority;
    return data;
  }

  public deletePolicy(dto: IDeletePolicy): DeletePolicy {
    const data: DeletePolicy = new DeletePolicy();
    data.policyName = dto.policyName;
    return data;
  }

  public updatePolicyName(dto: IUpdatePolicyName): UpdatePolicyName {
    const data: UpdatePolicyName = new UpdatePolicyName();
    data.oldPolicyName = dto.oldPolicyName;
    data.newPolicyName = dto.policyName ? dto.policyName : dto.newPolicyName;
    return data;
  }

  public accrualsDetails(dto: AccrualPolicyDetails[]) {
    return _.map(dto, (item) => this.mapAccrualsDetails(item));
  }

  public mapAccrualsDetails(data: AccrualPolicyDetails) {
    let dto: AccrualPolicyDetails = new AccrualPolicyDetails();
    dto.id = data.id;
    dto.policyName =data.policyName;
    dto.orgLevelId = data.orgLevelId;
    dto.isChecked = false;
    return dto;
  }

  public accrualOrgLevelDetails(dto: any[]) {
    return _.map(dto, (item) => this.mapOrgLevels(item));
  }

  public mapOrgLevels(data: any) {
    let orgLevelDto: AccrualOrgLevels = new AccrualOrgLevels();
    orgLevelDto.organizationId = data.organizationId;
    orgLevelDto.organizationName = data.name;
    orgLevelDto.orgLevelId = data.id;
    orgLevelDto.isChecked = false;
    return orgLevelDto;
  }

  public mapAccrualCopiedPolicyStatus(dto: ICopyAccrualPolicyResult[], meta: FieldsMeta) {
    return _.map(dto, (item) => this.mapAccrualCopiedPolicy(item));
  }

  public mapAccrualCopiedPolicy(data: CopyAccrualPolicyResult) {
    let policyStatusDto: CopyAccrualPolicyResult = new CopyAccrualPolicyResult();
    policyStatusDto.organizationName = data.organizationName;
    policyStatusDto.policyName = data.policyName;
    policyStatusDto.statusMessage = data.statusMessage;
    return policyStatusDto;
  }

}
