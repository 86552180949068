import { Pipe, PipeTransform } from '@angular/core';

import { OrgLevel } from '../../state-model/models/index';

@Pipe({ name: 'orgLevelPath' })
export class OrgLevelPathPipe implements PipeTransform {
  transform(value: OrgLevel, separator: string): string {
    if (!value) return '';
      let path: string [] = value.treeViewNamePath ? value.treeViewNamePath.slice (1) : [];
      path.push (value.name);
      return path.join (separator);
  }
}
