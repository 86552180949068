import * as tslib_1 from "tslib";
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { OrgLevelType } from '../../../state-model/models/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { ChangeManagementService } from '../../../common/index';
import { TelepunchLocation } from '../../models/telepunch-locations/telepunch-location';
import { TelepunchLocationsApiService } from './telepunch-locations-api.service';
import { TelepunchLocationsMapService } from './telepunch-locations-map.service';
import { TelepunchLocationsContainer } from '../../models/telepunch-locations/telepunch-location-container';
import { ManagementBaseService } from '../../../core/services/index';
import * as _ from 'lodash';
var TelepunchLocationsManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(TelepunchLocationsManagementService, _super);
    function TelepunchLocationsManagementService(access, changeService, api, map) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.map = map;
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.onItemSaved$ = new ReplaySubject();
        _this.viewRefresh$ = new Subject();
        _this.addItemClicked$ = new Subject();
        _this.deleteItemClicked$ = new Subject();
        _this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: false,
            canEdit: true,
            canAdd: true,
            canDelete: true
        };
        return _this;
    }
    TelepunchLocationsManagementService.prototype.init = function () {
        var _this = this;
        this.changeService.setCurrentComponentId('configure_telepunch_locations');
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = true;
        this.appDataSubscription = this.orgLevel$.combineLatest(this.user$).subscribe(function (value) {
            var orgLevel = value[0], user = value[1];
            if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentUser = user;
            _this.access.orgLevelType = _this.currentOrgLevel.type;
            if (_this.m_container) {
                _this.m_container.records = [];
                _this.onLoaded$.next(_this.m_container);
            }
            if (_this.currentOrgLevel.type === OrgLevelType.department || _this.currentOrgLevel.type === OrgLevelType.organization) {
                _this.state.bulkEditMode = false;
                _this.state.canBulkEdit = false;
                _this.onStateChanged$.next({ orgLevelChanged: true, bulkEditMode: false, canBulkEdit: false });
                _this.fetchRecords(_this.currentOrgLevel.id);
            }
            else {
                _this.state.bulkEditMode = false;
                _this.state.canBulkEdit = false;
                _this.onStateChanged$.next({ orgLevelChanged: true, bulkEditMode: false, canBulkEdit: false });
            }
        });
    };
    TelepunchLocationsManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    TelepunchLocationsManagementService.prototype.addItemClicked = function () {
        this.markAsDirty();
        this.addItemClicked$.next();
    };
    TelepunchLocationsManagementService.prototype.deleteItemClicked = function () {
        this.deleteItemClicked$.next();
    };
    TelepunchLocationsManagementService.prototype.getProhibitedPhones = function (loc) {
        var filtered = _.filter(this.m_container.records, function (tp) { return tp !== loc; });
        return _.map(filtered, function (tp) { return tp.phone; });
    };
    TelepunchLocationsManagementService.prototype.getProhibitedNames = function (loc) {
        var filtered = _.filter(this.m_container.records, function (tp) { return tp !== loc; });
        return _.map(filtered, function (tp) { return tp.locationName; });
    };
    //#region IConfigurationManagementService implementation
    TelepunchLocationsManagementService.prototype.onRemoveItem = function (itemToDelete) {
        if (!itemToDelete)
            return;
        this.removeItemsCmd$.next(itemToDelete);
    };
    TelepunchLocationsManagementService.prototype.onAddItem = function (item) {
        this.markAsDirty();
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    };
    TelepunchLocationsManagementService.prototype.setSelectedCount = function (count) {
        this.access.selectedItemsCount = count;
    };
    TelepunchLocationsManagementService.prototype.onEditItem = function (item) {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    };
    TelepunchLocationsManagementService.prototype.onCancelEditItem = function () {
        this.changeService.clearChanges();
        this.editingItem = null;
        this.editItemCmd$.next(null);
    };
    TelepunchLocationsManagementService.prototype.onSaveItem = function (info) {
        this.updateItem(info.dataItem);
    };
    TelepunchLocationsManagementService.prototype.doRemoveItem = function (dataItem) {
        var _this = this;
        this.state.isLoading = true;
        this.api.deleteTelepunchLocation(dataItem).then(function () {
            _.remove(_this.m_container.records, function (tp) { return tp.id === dataItem.id; });
            _this.changeService.clearChanges();
            _this.update();
        })
            .catch(function () { return _this.update(); });
    };
    TelepunchLocationsManagementService.prototype.createEmptyItem = function () {
        this.m_container.records.push(new TelepunchLocation());
        return this.m_container.records.length;
    };
    TelepunchLocationsManagementService.prototype.removeEmptyItem = function (dataItem) {
        _.remove(this.m_container.records, function (tp) { return tp === dataItem; });
    };
    //#endregion IConfigurationManagementService implementation
    //#region bulk actions
    TelepunchLocationsManagementService.prototype.setItemDirty = function (item) {
        item.isDirty = true;
    };
    TelepunchLocationsManagementService.prototype.doBulkEdit = function () {
        this.originalCollection = this.map.cloneCollection(this.m_container.records);
        this.changeService.changeNotify();
        this.state.bulkEditMode = true;
        this.onStateChanged$.next({ bulkEditMode: true });
    };
    TelepunchLocationsManagementService.prototype.doBulkSave = function () {
        var _this = this;
        this.originalCollection = null;
        this.state.isLoading = true;
        this.onStateChanged$.next({ isLoading: true });
        var dirtyRecords = [];
        _.each(this.m_container.records, function (model) {
            if (model.isDirty) {
                dirtyRecords.push(model);
            }
            model.isDirty = false;
        });
        if (dirtyRecords.length > 0) {
            this.api.saveTelepunchLocations(dirtyRecords)
                .then(function () {
                _this.changeService.clearChanges();
                _this.viewRefresh$.next(false);
                _this.state.isLoading = false;
                _this.state.bulkEditMode = false;
                _this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
            })
                .catch(function () { return _this.update(); });
        }
        else {
            this.state.isLoading = false;
            this.state.bulkEditMode = false;
            this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
        }
    };
    TelepunchLocationsManagementService.prototype.doBulkDiscard = function () {
        this.m_container.records = this.originalCollection;
        this.changeService.clearChanges();
        this.state.bulkEditMode = false;
        this.onStateChanged$.next({ bulkEditMode: false });
        this.onLoaded$.next(this.m_container);
    };
    Object.defineProperty(TelepunchLocationsManagementService.prototype, "records", {
        get: function () {
            return this.m_container ? this.m_container.records : [];
        },
        enumerable: true,
        configurable: true
    });
    //#endregion bulk actions
    TelepunchLocationsManagementService.prototype.fetchRecords = function (orgLevelId) {
        var _this = this;
        this.state.isLoading = true;
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.getTelepunchLocations().
            then(function (result) {
            _this.changeService.clearChanges();
            _this.access.lockActions = false;
            _this.m_container = result;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            if (_this.m_container.actions)
                _this.access.actions = _this.m_container.actions;
            _this.onLoaded$.next(_this.m_container);
            var state = {
                isLoading: false,
                bulkEditMode: false,
                canBulkEdit: _this.m_container.actions.canEdit,
                canEdit: _this.m_container.actions.canEdit,
                canAdd: _this.m_container.actions.canAdd,
                canDelete: _this.m_container.actions.canDelete
            };
            _this.state.isLoading = false;
            _this.state.bulkEditMode = false;
            _this.state.canBulkEdit = false;
            _this.state.canEdit = _this.m_container.actions.canEdit;
            _this.state.canAdd = _this.m_container.actions.canAdd;
            _this.state.canDelete = _this.m_container.actions.canDelete;
            _this.onStateChanged$.next(state);
        }).catch(function (e) {
            _this.m_container = new TelepunchLocationsContainer();
            _this.m_container.records = [];
            _this.onLoaded$.next(_this.m_container);
            _this.access.lockActions = false;
            _this.state.isLoading = false;
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    TelepunchLocationsManagementService.prototype.updateItem = function (item) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.api.saveTelepunchLocations([item])
            .then(function () {
            _this.changeService.clearChanges();
            if (_this.isEditingNewItem) {
                _this.fetchRecords(_this.currentOrgLevel.id);
                return;
            }
            _this.onItemSaved$.next(item);
            _this.editingItem = null;
            _this.update();
        })
            .catch(function () { return _this.update(); });
    };
    TelepunchLocationsManagementService.prototype.update = function () {
        this.viewRefresh$.next(false);
        this.state.isLoading = false;
        this.onStateChanged$.next({ isLoading: false });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], TelepunchLocationsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], TelepunchLocationsManagementService.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TelepunchLocationsManagementService.prototype, "appDataSubscription", void 0);
    return TelepunchLocationsManagementService;
}(ManagementBaseService));
export { TelepunchLocationsManagementService };
