import * as tslib_1 from "tslib";
import { OrgLevelApiService } from './org-level-api.service';
import { OrgLevelMapService } from './org-level-map.service';
import { OrgLevelWatchService } from './org-level-watch.service';
import * as _ from 'lodash';
import { ManagementBaseService, UserService } from '../../../core/services/index';
import { ComponentStateStorageService } from '../../../common/services/index';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
import { StateResetTypes } from '../../../core/models/settings/reset-types';
import { IControlState } from '../../../core/models/settings/component-states';
var OrgLevelManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(OrgLevelManagementService, _super);
    function OrgLevelManagementService(orgLevelApiService, orgLevelWatchService, userService, stateManagement, appStateBusService, orgLevelMapService) {
        var _this = _super.call(this) || this;
        _this.orgLevelApiService = orgLevelApiService;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.userService = userService;
        _this.stateManagement = stateManagement;
        _this.appStateBusService = appStateBusService;
        _this.orgLevelMapService = orgLevelMapService;
        _this.selectedOrganizationLevel = null;
        _this.selectedOrgLevelGroup = 'LastOrgLevel';
        _this.componentId = 'OrganizationStructureComponent';
        _this.selectedOrgLevelResetType = StateResetTypes.None;
        _this.stateManagement.restoreServerControlsStorage(_this.componentId).then(function () {
            _this.onReloadOrgLevels();
            _this.orgLevelSelectedByIdSubscription = _this.appStateBusService.orgLevelIdSelected$.subscribe(function (id) {
                _this.orgLevelWatchService.getOrgLevelByIdSafe(id)
                    .then(function (ogLevel) {
                    _this.onOrganizationLevelChanged(ogLevel);
                });
            });
        });
        _this.applicationSubscription = _this.appStateBusService.reloadOrgLevels$.subscribe(function () {
            _this.onReloadOrgLevels();
        });
        _this.loginSubscription = _this.appStateBusService.login$.subscribe(function (isAliasChanged) {
            _this.orgLevelWatchService.resetOrgLevelTree();
            _this.stateManagement.restoreServerControlsStorage(_this.componentId, true).then(function () {
                _this.onReloadOrgLevels();
            });
        });
        return _this;
    }
    OrgLevelManagementService.prototype.onReloadOrgLevels = function () {
        this.refresh();
    };
    OrgLevelManagementService.prototype.refresh = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.orgLevelApiService.getOrganizationTree()
            .then(function (data) {
            _this.orgTreeLoaded = true;
            _this.onLoadStatusChanged(false);
            _this.noData = (!data || data.length === 0);
            if (!_this.noData) {
                _this.orgLevelWatchService.setOrgLevelTree(data);
                _this.appStateBusService.loadedOrgLevels(data);
                _this.orgLevelsLoaded(data);
            }
            else {
                _this.appStateBusService.loadedOrgLevels(data);
            }
        });
    };
    OrgLevelManagementService.prototype.onOrganizationLevelChanged = function (orgLevel) {
        this.onOrganizationLevelChangedInternal(orgLevel, true);
    };
    OrgLevelManagementService.prototype.getLastSelectedOrgLevel = function () {
        var state = this.stateManagement.getControlState(this.componentId, this.selectedOrgLevelGroup);
        if (state && state.value) {
            var orgLevel = this.orgLevelMapService.mapToOrgLevel(state.value);
            return orgLevel;
        }
        return null;
    };
    OrgLevelManagementService.prototype.onOrganizationLevelChangedInternal = function (orgLevel, save) {
        if (save &&
            (!this.selectedOrganizationLevel && orgLevel
                || this.selectedOrganizationLevel && !orgLevel
                || (this.selectedOrganizationLevel && orgLevel && this.selectedOrganizationLevel.id !== orgLevel.id))) {
            this.setLastSelectedOrgLevel(orgLevel);
        }
        this.selectedOrganizationLevel = orgLevel;
        this.orgLevelWatchService.selectOrgLevel(this.selectedOrganizationLevel);
        this.appStateBusService.selectOrgLevel(this.selectedOrganizationLevel);
    };
    OrgLevelManagementService.prototype.orgLevelsLoaded = function (orgLevelTree) {
        // TODO if selected orglevel should be global across applications - we need implement restore orglevel here
        var lastOrgLevel = this.getLastSelectedOrgLevel();
        if (lastOrgLevel && lastOrgLevel.id) {
            lastOrgLevel = this.orgLevelWatchService.getOrgLevelById(lastOrgLevel.id);
        }
        else {
            lastOrgLevel = _.first(orgLevelTree);
        }
        this.appStateBusService.restoredLastOrgLevel(lastOrgLevel);
        /*
        this.orgLevelApiService.getLastSelectedLevel(this.organizationTreeContext)
          .then((result: OrgLevel) => {
            this.onLoadStatusChanged(false);
            this.onOrganizationLevelChanged(result);
          }).catch((error: any) => {
            this.onLoadStatusChanged(false);
          });*/
    };
    OrgLevelManagementService.prototype.setLastSelectedOrgLevel = function (orgLevel) {
        this.stateManagement.setControlState(this.componentId, this.selectedOrgLevelGroup, {
            value: this.orgLevelMapService.mapOrgLevelDTO(orgLevel)
        }, this.selectedOrgLevelResetType);
    };
    return OrgLevelManagementService;
}(ManagementBaseService));
export { OrgLevelManagementService };
