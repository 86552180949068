import { Injectable } from '@angular/core';
import { ISelectableItemContainer } from '../../models/selectable-item-container';
import { Assert } from '../../../framework/index';
@Injectable()
export class SelectableItemsProducer {
  public produceSelectable<T>(items: T[]): ISelectableItemContainer<T>[] {
    Assert.isNotNull(items, 'items');
    let selectableItems: ISelectableItemContainer<T>[] = [];
    items.forEach((i: T) => { selectableItems.push({ selected: false, item: i }); });
    return selectableItems;
  }
}
