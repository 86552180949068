import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const TIME_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => TimeValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[time][formControlName],[time][formControl],[time][ngModel]',
  /* tslint:enable */
  providers: [TIME_VALIDATOR]
})
export class TimeValidator implements Validator {
  public validate(c: AbstractControl): {[key: string]: any} {
    const res: any = CommonValidators.time(c);
    return res;
  }
}
