<div class="header-container">

    <div class="col title-col">
        <span [ngClass]="{'section-title':true, 'section-collapsed-title': panel?.isCollapsed, 'section-expanded-title': !panel?.isCollapsed}">{{panel.title}}</span>
    </div>

    <div class="col line-col">
        <hr class="section-separator" />
    </div>

    <div class="buttons-col" *ngIf="!panel?.hideButton">
        <button type="button" class="collapse-button marg-r" title="Export to Excel" (click)="onExportExel()">
            <i class="far fa-file-excel" aria-hidden="true"></i>
        </button>
        <button type="button" class="collapse-button" title="Export to PDF" (click)="onExportPdf()">
            <i class="far fa-file-pdf" aria-hidden="true"></i>
        </button>
    </div>

    <div class="col buttons-col" *ngIf="!panel?.hideButton">
        <button type="button" (click)="panel?.onToggle()" class="collapse-button open-btn" *ngIf="panel?.isCollapsed">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                </button>
        <button type="button" (click)="panel?.onToggle()" class="collapse-button close-btn" *ngIf="!panel?.isCollapsed">
                    <i class="fa fa-caret-up" aria-hidden="true"></i>
                </button>
    </div>

</div>
