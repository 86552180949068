/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./census-entries-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../census-entries/census-entries.component.ngfactory";
import * as i3 from "../census-entries/census-entries.component";
import * as i4 from "../../services/schedule/census-entry-api.service";
import * as i5 from "./census-entries-page.component";
import * as i6 from "@angular/router";
var styles_CensusEntriesPageComponent = [i0.styles];
var RenderType_CensusEntriesPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CensusEntriesPageComponent, data: {} });
export { RenderType_CensusEntriesPageComponent as RenderType_CensusEntriesPageComponent };
export function View_CensusEntriesPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "census-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-census-entries", [], null, null, null, i2.View_CensusEntriesComponent_0, i2.RenderType_CensusEntriesComponent)), i1.ɵdid(2, 245760, [["census", 4]], 0, i3.CensusEntriesComponent, [i4.CensusEntryApiService], { entryDate: [0, "entryDate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entryDate; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CensusEntriesPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-census-entries-page", [], null, null, null, View_CensusEntriesPageComponent_0, RenderType_CensusEntriesPageComponent)), i1.ɵdid(1, 49152, null, 0, i5.CensusEntriesPageComponent, [i6.ActivatedRoute], null, null)], null, null); }
var CensusEntriesPageComponentNgFactory = i1.ɵccf("slx-census-entries-page", i5.CensusEntriesPageComponent, View_CensusEntriesPageComponent_Host_0, {}, {}, []);
export { CensusEntriesPageComponentNgFactory as CensusEntriesPageComponentNgFactory };
