<kendo-grid #kendoGrid [data]="gridState?.view" class="slx-full-height">
  <kendo-grid-column title="Role">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [ngClass]="{'no-users-foreground': dataItem.filteredCount === 0,
                       'users-foreground' : dataItem.filteredCount !== 0}">
        {{dataItem.name}}
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="User count" width="120" >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [ngClass]="{'no-users-foreground': dataItem.filteredCount === 0,
                       'users-foreground' : dataItem.filteredCount !== 0}" >
        <span *ngIf="filterString">{{dataItem.filteredCount}} of</span>
        {{dataItem.assignedCount}}
      </div>
    </ng-template>
  </kendo-grid-column>

  <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex>
      <slx-role-users-grid [rowIndex]="rowIndex" [users]="dataItem.users" [filter]="filterString"></slx-role-users-grid>
  </ng-template>
</kendo-grid>
