import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { appConfig } from '../../../app.config';
import { ColorUtil } from '../../../common/utils/index';
import { PayCodeGridModel } from '../../models/index';
var PayCodesGridComponent = /** @class */ (function () {
    function PayCodesGridComponent(changeDetector) {
        this.changeDetector = changeDetector;
    }
    Object.defineProperty(PayCodesGridComponent.prototype, "setPayCodeModel", {
        set: function (value) {
            this.payCodeModel = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCodesGridComponent.prototype, "setRuleList", {
        set: function (value) {
            this.ruleList = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    PayCodesGridComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    PayCodesGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PayCodesGridComponent.prototype.getColor = function (color) {
        return ColorUtil.DecToHexString(color, true);
    };
    PayCodesGridComponent.prototype.refreshGrid = function () {
        var noRuleList = !_.isArray(this.ruleList);
        if (!_.isObject(this.payCodeModel) || noRuleList) {
            if (noRuleList)
                this.gridData = null;
            return;
        }
        this.gridData = _.cloneDeep(this.ruleList);
    };
    return PayCodesGridComponent;
}());
export { PayCodesGridComponent };
