import { Injectable } from '@angular/core';

import { Assert } from '../../../framework/index';
import { LookupApiService } from './lookup-api.service';
import { LookupMapService } from './lookup-map.service';
import {
  LookupType, Lookup, ILookupRequest, EssDefaultPassword, EssTimeEntryMethod, IPerformanceReviewCode, PerformanceReviewCode, PerformanceReviewCodeTypes,
  ScheduleCycle
} from '../../models/index';

import { WeekDays, Months } from '../../../common/models/index';
import * as _ from 'lodash';
import { ReplaySubject, Subscription } from 'rxjs';

//TODO cache ability
@Injectable()
export class LookupService {
  private lookupApiService: LookupApiService;
  private lookupMapService: LookupMapService;

  constructor(lookupApiService: LookupApiService, lookupMapService: LookupMapService) {
    Assert.isNotNull(lookupApiService, 'lookupMapService');
    this.lookupApiService = lookupApiService;
    this.lookupMapService = lookupMapService;
  }

  public getLookup({ lookupType, orgLevelId, employeeId, updateCacheForced, dateRange, isActive }: ILookupRequest): Promise<Lookup> {
    Assert.isNotNull(lookupType);
    let promise: Promise<Lookup>;
    switch (lookupType) {
      case LookupType.effectivePositions:
        promise = this.lookupApiService.getEffectivePositions(employeeId, dateRange.startDate).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
      break;
      case LookupType.accrualPolicy:
        promise = this.lookupApiService.getAccrualPolicies(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'name'));
        break;
      case LookupType.acuity:
        promise = this.lookupApiService.getAcuities(orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items));
        break;
      case LookupType.attendancePointsDefinitions:
        promise = this.lookupApiService.getAccrualPolicies(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'definition'));
        break;
      case LookupType.budgetedPosition:
        promise = this.lookupApiService.getBudgetedPositions(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'description'));
        break;
      case LookupType.licenseType:
        promise = this.lookupApiService.getLicenseType(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'licenseTypeID', 'licenseTypeName'));
        break;
      case LookupType.constraintDefinition:
        promise = this.lookupApiService.getConstraintDefinitions(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items));
        break;
      case LookupType.costCenter:
        promise = this.lookupApiService.getCostCenters(employeeId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'code', 'description'));
        break;
      case LookupType.organization:
        promise = this.lookupApiService.getOrganizations(employeeId, orgLevelId, isActive).then(
          (items: any[]) => this.mapToUnified(lookupType, items));
        break;
      case LookupType.userAccesibleOrganizations:
        promise = this.lookupApiService.getUserAccesibleOrganizations(orgLevelId, false).then(
          (items: any[]) => this.mapToUnified(lookupType, items));
        break;
      case LookupType.userAccesibleOrganizationsCrossCorpActionCheck:
        promise = this.lookupApiService.getUserAccesibleOrganizations(orgLevelId, true).then(
          (items: any[]) => this.mapToUnified(lookupType, items));
        break;
      case LookupType.userAccessibleDepartments:
        promise = this.lookupApiService.getUserAccessibleDepartments(orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items));
        break;
      case LookupType.department:
        promise = this.lookupApiService.getDepartments(employeeId, orgLevelId, isActive).then(
          (items: any[]) => this.mapToUnified(lookupType, items));
        break;
      case LookupType.companies:
        promise = this.lookupApiService.getCompanies().then(
          (items: any[]) => this.mapToUnified(lookupType, items));
        break;
      case LookupType.empGender:
        promise = this.lookupApiService.getGenders().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'description'));
        break;
      case LookupType.empRace:
        promise = this.lookupApiService.getRaces().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'description'));
        break;
      case LookupType.empType:
        promise = this.lookupApiService.getEmpTypes().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'name'));
        break;
      case LookupType.position:
        promise = this.lookupApiService.getPositions(employeeId, orgLevelId, isActive, updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.pbjPosition:
        promise = this.lookupApiService.getPbjPosition(employeeId, orgLevelId, isActive, updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.locationUnit:
        promise = this.lookupApiService.getLocationUnits(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.maritalStatus:
        promise = this.lookupApiService.getMaritalStatuses().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'description'));
        break;
      case LookupType.shiftGroupDefinition:
        promise = this.lookupApiService.getShiftGroupDefinition(orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.shift:
        promise = this.lookupApiService.getShiftDefinitions(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.state:
        promise = this.lookupApiService.getStates().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'name'));
        break;
      case LookupType.payPolicy:
        promise = this.lookupApiService.getPayPolicies(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'description'));
        break;
      case LookupType.shiftDiffPolicy:
        promise = this.lookupApiService.getShiftDiffPolicies(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'description'));
        break;
      case LookupType.payType:
        promise = this.lookupApiService.getPayTypes().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'description'));
        break;
      case LookupType.exemptStatus:
        promise = this.lookupApiService.getExemptStatuses().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'name'));
        break;
      case LookupType.scheduleAbsence:
        promise = this.lookupApiService.getScheduleAbsences(orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'code', 'description'));
        break;
      case LookupType.searchCategory:
        promise = this.lookupApiService.getSearchCategories().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.employeeTerminationReason:
        promise = this.lookupApiService.getEmployeeTerminationReasons().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'reason'));
        break;
      case LookupType.employeeTerminationReason2:
          promise = this.lookupApiService.getEmployeeTerminationReasons().then(
            (items: any[]) => this.mapToUnified(lookupType, _.sortBy(_.uniqBy(items,'reason'),'reason'), 'reason', 'reason'));
          break;
      case LookupType.employeeDocumentCategories:
        promise = this.lookupApiService.getEmployeeDocumentCategories().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'description', 'description'));
        break;
      case LookupType.shiftDefinition:
        promise = this.lookupApiService.getShiftDefinitions(employeeId, orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.budgetedGroup:
        promise = this.lookupApiService.getBudgetedGroups(orgLevelId, updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'description'));
        break;
      case LookupType.budgetDefinition:
        promise = this.lookupApiService.getBudgetDefinitions(orgLevelId, updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'description'));
        break;
      case LookupType.availabilityDefinition:
        promise = this.lookupApiService.getAvailabilityRanges(employeeId, updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'name'));
        break;
      case LookupType.pbjPositions:
        promise = this.lookupApiService.getPbjPositions().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'title'));
        break;
      case LookupType.timeclockDefinition:
        promise = this.lookupApiService.getTimeclockDefinitions(orgLevelId, employeeId, updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.timeclockRestrictionDefinition:
        promise = this.lookupApiService.getTimeclockRestrictionDefinitions(orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.timeclockModelDefinition:
        promise = this.lookupApiService.getTimeclockModelDefinitions(orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.timezoneDefinition:
        promise = this.lookupApiService.getTimezoneDefinitions().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.payRules:
        promise = this.lookupApiService.getPayRules(employeeId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.holidayOrigins:
        promise = new Promise((resolve: any, reject: any) => {
          let origins: any[] = [{ id: 'SpecificDate', name: 'Specific Date' }, { id: 'RuleBased', name: 'Rule Based' }];
          resolve(this.mapToUnified(lookupType, origins, 'id', 'name'));
        });
        break;
      case LookupType.holidayDayPatterns:
        promise = new Promise((resolve: any, reject: any) => {
          let patterns: any[] = [{ id: 'First', name: 'First', momentId: 0 }, { id: 'Second', name: 'Second', momentId: 1 }, { id: 'Third', name: 'Third', momentId: 2 }, { id: 'Fourth', name: 'Fourth', momentId: 3 }, { id: 'Last', name: 'Last', momentId: 4 }];
          resolve(this.mapToUnified(lookupType, patterns, 'id', 'name'));
        });
        break;
      case LookupType.daysOfWeek:
        promise = new Promise((resolve: any, reject: any) => {
          resolve(this.mapToUnified(lookupType, WeekDays, 'dayNumber', 'name'));
        });
        break;
      case LookupType.months:
        promise = new Promise((resolve: any, reject: any) => {
          resolve(this.mapToUnified(lookupType, Months, 'id', 'name'));
        });
        break;
      case LookupType.auditTrailAreas:
        promise = new Promise((resolve: any, reject: any) => {
          let origins: any[] = [{ code: 'All' }, { code: 'Employee' }, { code: 'Timecard' }, { code: 'Schedule' }];
          resolve(this.mapToUnified(lookupType, origins, 'code', 'code'));
        });
        break;
      case LookupType.auditTrailConfigAreas:
        promise = new Promise((resolve: any, reject: any) => {
          let origins: any[] = [{ code: 'All' }, { code: 'Configuration' }, { code: 'User' }];
          resolve(this.mapToUnified(lookupType, origins, 'code', 'code'));
        });
        break;
      case LookupType.employeeList:
        promise = this.lookupApiService.getEmployees(orgLevelId, isActive).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
        case LookupType.supervisorList:
          promise = this.lookupApiService.getSupervisors(orgLevelId,employeeId, isActive).then(
            (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
          break;
      case LookupType.employeesListBySecondaryPositions:
        promise = this.lookupApiService.getEmployeesBySecondaryPositions(orgLevelId, isActive).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.employeesListByActiveDateRange:
        promise = this.lookupApiService.getEmployeesByActiveDateRange(orgLevelId, dateRange.startDate, dateRange.endDate).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.manualEntryActiveEmployees:
        promise = this.lookupApiService.manualEntryActiveEmployees(orgLevelId, dateRange.startDate, dateRange.endDate).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.agencies:
        promise = this.lookupApiService.getAgencies(updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.agenciesReports:
        promise = this.lookupApiService.getAgenciesReports(updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'name'));
        break;
      case LookupType.punchTypes:
        promise = this.lookupApiService.getPunchTypes().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'description'));
        break;
      case LookupType.essPasswordTypesFull:
        promise = new Promise((resolve: any, reject: any) => {
          let origins: any[] = EssDefaultPassword.getList(true, true);
          resolve(this.mapToUnified(lookupType, origins, 'id', 'name'));
        });
        break;
      case LookupType.essPasswordTypes:
        promise = new Promise((resolve: any, reject: any) => {
          let origins: any[] = EssDefaultPassword.getList(false);
          resolve(this.mapToUnified(lookupType, origins, 'id', 'name'));
        });
        break;
      case LookupType.essEnterTimeMethods:
        promise = new Promise((resolve: any, reject: any) => {
          let origins: any[] = EssTimeEntryMethod.getList(false);
          resolve(this.mapToUnified(lookupType, origins, 'id', 'name'));
        });
        break;
      case LookupType.essEnterTimeMethodsFull:
        promise = new Promise((resolve: any, reject: any) => {
          let origins: any[] = EssTimeEntryMethod.getList(true);
          resolve(this.mapToUnified(lookupType, origins, 'id', 'name'));
        });
        break;

      case LookupType.essOptions:
        promise = this.lookupApiService.getEssOptions().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.payCyclePeriods:
        promise = this.lookupApiService.getPayCyclePeriods().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'name', 'description'));
        break;
      case LookupType.payCycles:
        promise = this.lookupApiService.getPayCyles(orgLevelId).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'description', 'description'));
        break;
      case LookupType.timecardPredefinedComment:
        promise = this.lookupApiService.getTimecardPredefinedComment().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'description', 'description'));
        break;
      case LookupType.employeeListByMasterId:
        promise = this.lookupApiService.getEmployees(orgLevelId, isActive).then(
          (items: any[]) => {
            items = this.removeDuplicatesBy(items, 'masterId');
            return this.mapToUnified(lookupType, items, 'id', 'name');
          });
        break;
      case LookupType.ptoPlannerAbsenceCodes:
        promise = this.lookupApiService.getPtoPlannerAbsenceCodes(orgLevelId, updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'code', 'code'));
        break;
      case LookupType.punchAttestationAnswerCodes:
        promise = new Promise(resolve => {
          let answerCodes: any[] = [{ id: 0, name: '' },{ id: 1, name: 'Yes' }, { id: 2, name: 'No' }];
          resolve(this.mapToUnified(lookupType, answerCodes, 'id', 'name'));
        });
        break;
      case LookupType.benefitClasses:
        promise = this.lookupApiService.getBenefitClassesDefinitions(employeeId, orgLevelId, updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.veteranStatus:
        promise = this.lookupApiService.getVeteranStatuses(employeeId, orgLevelId, updateCacheForced).then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.positionGroups:
        promise = this.lookupApiService.getPositionGroups(orgLevelId, updateCacheForced)
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.performanceReviewCodes:
        promise = this.lookupApiService.getPerformanceReviewCodes(orgLevelId, updateCacheForced)
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.performanceReviewTemplates:
        promise = this.lookupApiService.getPerformanceReviewTemplates(orgLevelId, updateCacheForced)
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.performanceReviewCodeTypes:
        promise = this.lookupApiService.getPerformanceReviewCodeTypes(updateCacheForced)
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.benefitGroups:
        promise = this.lookupApiService.getBenefitGroups(employeeId, isActive)
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.getObjAcc:
        promise = this.lookupApiService.getObjAcc(orgLevelId, isActive)
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'name', 'name'));
        break;
      case LookupType.retroReportOptions:
        promise = new Promise(resolve => {
          let reportOptions: any[] = [{ id: '1', name: 'Employee(s) with Retro Pay' }, { id: '0', name: 'All Employees' }];
          resolve(this.mapToUnified(lookupType, reportOptions, 'id', 'name'));
        });
        break;
      case LookupType.timeclockSlateProfileDefinition:
        promise = this.lookupApiService.getTimeclockSlateProfileDefinitions().then(
          (items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.payUnits:
        promise = this.lookupApiService.getPayUnits(orgLevelId, isActive)
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.increaseReason:
        promise = this.lookupApiService.getIncreaseReasons()
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'id', 'name'));
        break;
      case LookupType.punchProfile:
        promise = this.lookupApiService.getPunchProfile()
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'id', 'profileId'));
        break;
        case LookupType.punchAttestationPunchType:
          promise = new Promise(resolve => {
            let answerCodes: any[] = [{ id: 1, name: 'IN' }, { id: 2, name: 'OUT' }];
            resolve(this.mapToUnified(lookupType, answerCodes, 'id', 'name'));
          });
          break;
      case LookupType.attestationType:
        promise = this.lookupApiService.getAttestationTypes()
          .then((items: any[]) => this.mapToUnified(lookupType, items, 'attestationTypeId', 'attestationTypeName'));
        break;
      case LookupType.restrictedAnswer:
          promise = this.lookupApiService.getRestrictedAnswers()
            .then((items: any[]) => this.mapToUnified(lookupType, items, 'restrictedAnswerId', 'restrictedAnswerName'));
          break;
      case LookupType.getRestrictedNotify:
        promise = this.lookupApiService.getRestrictedNotify()
        .then((items: any[]) => this.mapToUnified(lookupType, items, 'notifyType', 'notifyId'));
      break;
          // case LookupType.isConditional:
          //   promise = new Promise(resolve => {
          //     let answerCodes: any[] = [{ id: 1, name: 'Yes' }, { id: 0, name: 'No' }];
          //     resolve(this.mapToUnified(lookupType, answerCodes, 'id', 'name'));
          //   });
          //   break;

      default:
        throw new Error(`LookupService unknown lookup: ${lookupType}`);
    }
    return promise;
  }

  public getDisabilityLookup(): Lookup {
    const statuses = this.lookupMapService.mapDisabilityStatuses();
    return this.mapToUnified(LookupType.generic, statuses, 'id', 'name');
  }

  public mapToUnified(type: LookupType, items: any[], valueField: string = 'id', titleField: string = 'name'): Lookup {
    let lookup: Lookup = new Lookup();
    lookup.type = type;
    lookup.items = items;
    lookup.valueField = valueField;
    lookup.titleField = titleField;
    return lookup;
  }

  private removeDuplicatesBy(items: any[], key: string = 'id'): any[] {
    return _.uniqBy(items, (item: any) => {
      return item[key];
    });
  }
}
