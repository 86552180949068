import * as tslib_1 from "tslib";
import { NgZone, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { KendoGridStateHelper } from '../../../../../common';
import { AgencyCertificate } from '../../../models/employee-sections-personal/employee-sections-agency-certificate';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { process } from '@progress/kendo-data-query';
import { EmployeeSectionsPersonalApiService } from '../../../services';
var EmployeeSectionsCertificateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsCertificateComponent, _super);
    function EmployeeSectionsCertificateComponent(employeeSectionsPersonalApiService, decorator, ngZone) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
        _this.pageSize = 10;
        _this.preferredEmp = false;
        _this.doNotReturn = false;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.take = _this.pageSize;
        _this.gridState.state.skip = 0;
        _this.gridState.state.sort = [{ field: 'changedOn', dir: 'desc' }];
        return _this;
    }
    Object.defineProperty(EmployeeSectionsCertificateComponent.prototype, "agencyCertificate", {
        set: function (value) {
            this.agencyCertificates = value;
            this.getCurrentStatus();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsCertificateComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsCertificateComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadCertificateHistroy();
    };
    EmployeeSectionsCertificateComponent.prototype.getSubsectionModel = function () {
        return this.agencyCertificates;
    };
    EmployeeSectionsCertificateComponent.prototype.loadSubsection = function () {
        this.startProgress();
        this.loadCertificateHistroy();
    };
    EmployeeSectionsCertificateComponent.prototype.getDisplayStatus = function (status) {
        if (status) {
            return 'Yes';
        }
        else {
            return 'No';
        }
    };
    EmployeeSectionsCertificateComponent.prototype.loadCertificateHistroy = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.details = '';
                        this.startProgress();
                        return [4 /*yield*/, this.employeeSectionsPersonalApiService.getAgencyCertificateDetails(this.employeeId)
                                .then(function (agencyCertificate) {
                                _this.refreshGrid(agencyCertificate);
                                _this.stopProgress();
                            })
                                .catch(function (res) {
                                _this.stopProgress();
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsCertificateComponent.prototype.getCurrentStatus = function () {
        if (this.agencyCertificates != null && (this.agencyCertificates.agencyCertificate.length > 0)) {
            this.doNotReturn = this.agencyCertificates.agencyCertificate[0].dnr;
            this.preferredEmp = this.agencyCertificates.agencyCertificate[0].preferred;
        }
    };
    EmployeeSectionsCertificateComponent.prototype.refreshGrid = function (agencyCertificate) {
        if (agencyCertificate.length > 0) {
            this.doNotReturn = agencyCertificate[0].dnr;
            this.preferredEmp = agencyCertificate[0].preferred;
        }
        else {
            this.doNotReturn = false;
            this.preferredEmp = false;
        }
        this.gridState.view = process(agencyCertificate, this.gridState.state);
    };
    EmployeeSectionsCertificateComponent.prototype.verifyDNRStateChange = function (value) {
        this.details = '';
        this.doNotReturn = value;
        this.preferredEmp = this.doNotReturn == true ? false : this.preferredEmp;
    };
    EmployeeSectionsCertificateComponent.prototype.verifyPreferredStateChange = function (value) {
        this.details = '';
        this.preferredEmp = value;
        this.doNotReturn = this.preferredEmp == true ? false : this.doNotReturn;
    };
    EmployeeSectionsCertificateComponent.prototype.verifyDisabledState = function () {
        if (this.doNotReturn) {
            return false;
        }
        return true;
    };
    EmployeeSectionsCertificateComponent.prototype.doSave = function () {
        var _this = this;
        this.startProgress();
        var certificate = new AgencyCertificate();
        certificate.dnr = this.doNotReturn;
        certificate.preferred = this.preferredEmp;
        certificate.reason = this.details;
        this.employeeSectionsPersonalApiService.saveAgencyCertificate(certificate, this.employeeId)
            .then(function (status) {
            _this.onActionComplete(true);
            _this.stopProgress();
        })
            .catch(function (reason) {
            _this.onActionError(reason);
            _this.stopProgress();
        });
    };
    EmployeeSectionsCertificateComponent.prototype.checkTemporalDirty = function () {
        return this.metaFieldsTemporalDirtyChecker(this.agencyCertificate);
    };
    EmployeeSectionsCertificateComponent.prototype.verifyDnrState = function () {
        if (!this.state.isEditMode) {
            return true;
        }
        return false;
    };
    EmployeeSectionsCertificateComponent.prototype.getMinLength = function () {
        if (this.doNotReturn) {
            return '50';
        }
        else {
            return '0';
        }
    };
    return EmployeeSectionsCertificateComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsCertificateComponent };
