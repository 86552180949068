import * as tslib_1 from "tslib";
import { OnInit, Provider } from '@angular/core';
import { DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { BenefitEligibilityRulesApiService, BenefitEligibilityRulesMapService, } from '../../../services/benefit-eligibility-rules';
import * as _ from 'lodash';
import { BenefitEligibilityRuleModel, BenefitEligibilityRuleStatementGroupItem, BenefitEligibilityRuleVariable, BenefitEligibilityRuleStatus, } from '../../../models/benefit-eligibility-rules/index';
import { BenefitEligibilityRule } from '../../../models';
import * as uuid from 'uuid';
import { BenefitClassApiService } from '../../../services';
var BenefitDetailsEligibilityRuleDialogRequest = /** @class */ (function () {
    function BenefitDetailsEligibilityRuleDialogRequest(_a) {
        var benefitRulesAlreadyUsedNames = _a.benefitRulesAlreadyUsedNames, orgLevelId = _a.orgLevelId, isReadonlyMode = _a.isReadonlyMode;
        this.benefitRulesAlreadyUsedNames = benefitRulesAlreadyUsedNames;
        this.orgLevelId = orgLevelId;
        this.isReadonlyMode = isReadonlyMode;
    }
    return BenefitDetailsEligibilityRuleDialogRequest;
}());
export { BenefitDetailsEligibilityRuleDialogRequest };
var BenefitDetailsEligibilityRuleDialogComponent = /** @class */ (function () {
    function BenefitDetailsEligibilityRuleDialogComponent(dialogOptions, modalService, apiService, benefitClassApiService, mapService, request, ruleToUpdate) {
        this.apiService = apiService;
        this.benefitClassApiService = benefitClassApiService;
        this.mapService = mapService;
        this.request = request;
        this.ruleName = '';
        this.description = '';
        this.ruleToUpdate = ruleToUpdate;
        this.isNewRule = false;
        this.isEditMode = !this.request.isReadonlyMode;
        this.ruleModel = null;
        this.modalService = modalService;
        this.dialogOptions = dialogOptions;
        this.state = {
            isLoading: false,
            isSaving: false,
            isEditing: false,
        };
    }
    Object.defineProperty(BenefitDetailsEligibilityRuleDialogComponent.prototype, "benefitRulesAlreadyUsedNames", {
        get: function () {
            return this.request ? this.request.benefitRulesAlreadyUsedNames : [];
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsEligibilityRuleDialogComponent.openDialog = function (modalService, benefitRulesAlreadyUsedNames, orgLevelId, ruleToUpdate, isReadonlyMode, callback) {
        var dialogOptions = new DialogOptions2();
        // dialogOptions.height = 800;
        dialogOptions.width = 950;
        dialogOptions.modeSize = DialogModeSize.custom;
        var request = new BenefitDetailsEligibilityRuleDialogRequest({
            benefitRulesAlreadyUsedNames: benefitRulesAlreadyUsedNames,
            orgLevelId: orgLevelId,
            isReadonlyMode: isReadonlyMode,
        });
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions,
            },
            {
                provide: BenefitDetailsEligibilityRuleDialogRequest,
                useValue: request,
            },
            {
                provide: BenefitEligibilityRule,
                useValue: ruleToUpdate,
            },
        ];
        var dialog = modalService.globalAnchor.openDialog2(BenefitDetailsEligibilityRuleDialogComponent, 'Eligibility Rule', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, dialog.rule);
        });
        return dialog;
    };
    BenefitDetailsEligibilityRuleDialogComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ruleStatements;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isNewRule =
                            !this.ruleToUpdate || (this.ruleToUpdate && this.ruleToUpdate.status === BenefitEligibilityRuleStatus.Added);
                        if (!this.ruleToUpdate) {
                            this.state.isEditing = true;
                        }
                        return [4 /*yield*/, this.loadConfigurations()];
                    case 1:
                        _a.sent();
                        if (!this.ruleToUpdate) {
                            this.ruleModel = new BenefitEligibilityRuleModel({
                                ruleStatements: [
                                    new BenefitEligibilityRuleStatementGroupItem({
                                        id: uuid.v4(),
                                        variable: new BenefitEligibilityRuleVariable(),
                                    }),
                                ],
                            });
                        }
                        else {
                            this.ruleName = this.ruleToUpdate.name;
                            this.description = this.ruleToUpdate.description;
                            ruleStatements = this.mapService.mapResponseToGroups(this.ruleToUpdate.rule);
                            this.ruleModel = new BenefitEligibilityRuleModel({
                                id: this.ruleToUpdate.id,
                                name: this.ruleToUpdate.name,
                                description: this.ruleToUpdate.description,
                                ruleStatements: ruleStatements,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsEligibilityRuleDialogComponent.prototype.onRuleStatementsChanged = function (ruleStatements) {
        this.ruleModel = new BenefitEligibilityRuleModel(tslib_1.__assign({}, this.ruleModel, { ruleStatements: ruleStatements }));
    };
    BenefitDetailsEligibilityRuleDialogComponent.prototype.save = function () {
        this.dialogResult = true;
        var groups = this.mapService.mapGroupsToJsonRuleObject(this.ruleModel.ruleStatements);
        this.rule = new BenefitEligibilityRule({
            id: this.ruleToUpdate ? this.ruleToUpdate.id : uuid.v4(),
            name: this.ruleName,
            description: this.description,
            rule: JSON.stringify(groups),
            status: this.isNewRule ? BenefitEligibilityRuleStatus.Added : BenefitEligibilityRuleStatus.Updated,
        });
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    BenefitDetailsEligibilityRuleDialogComponent.prototype.edit = function () {
        this.state.isEditing = true;
    };
    BenefitDetailsEligibilityRuleDialogComponent.prototype.cancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    BenefitDetailsEligibilityRuleDialogComponent.prototype.onValueChanged = function (rule) {
        this.ruleName = _.trim(this.ruleName);
    };
    BenefitDetailsEligibilityRuleDialogComponent.prototype.loadConfigurations = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var ruleConfigurationDataPromise, benefitClassesPromise, values, ruleConfigurationData, benefitClasses, ex_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.state.isLoading = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        ruleConfigurationDataPromise = this.apiService.getBenefitEligibilityConfiguration();
                        benefitClassesPromise = this.benefitClassApiService.getBenefitClasses(this.request.orgLevelId);
                        return [4 /*yield*/, Promise.all([ruleConfigurationDataPromise, benefitClassesPromise])];
                    case 2:
                        values = _a.sent();
                        ruleConfigurationData = values[0];
                        benefitClasses = values[1];
                        this.configurationVariables = ruleConfigurationData.variables;
                        this.configurationPredicates = ruleConfigurationData.predicates;
                        this.benefitClasses = benefitClasses;
                        return [3 /*break*/, 5];
                    case 3:
                        ex_1 = _a.sent();
                        // todo catch
                        console.log(ex_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.state.isLoading = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return BenefitDetailsEligibilityRuleDialogComponent;
}());
export { BenefitDetailsEligibilityRuleDialogComponent };
