var EmployeeShift = /** @class */ (function () {
    function EmployeeShift() {
        this.isDirty = false;
    }
    Object.defineProperty(EmployeeShift.prototype, "isValid", {
        get: function () {
            if (this.position && this.shift && this.unit) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeShift;
}());
export { EmployeeShift };
