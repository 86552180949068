import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../app.config';
import { mutableSelect } from '../../../core/decorators/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { PollingPeriodService } from '../../../core/services/polling-period/polling-period.service';
import { TimeclockApiService } from './time-clock-api.service';
import { RebootClockContainer } from '../../models/index';
import { TokenValidityService } from '../../../core/services/token-validity/token-validity.service';
import { NotificationsService } from '../../../core/components';
import { BehaviorSubject } from 'rxjs';
import { TaSignalrService } from '../../../../app/time-and-attendance/services/signalR/ta-signalr-service';
var TimeclockDataService = /** @class */ (function () {
    function TimeclockDataService(timeclockApiService, userActivityService, tokenValidity, notificationService, taSignalrService) {
        this.timeclockApiService = timeclockApiService;
        this.userActivityService = userActivityService;
        this.tokenValidity = tokenValidity;
        this.notificationService = notificationService;
        this.taSignalrService = taSignalrService;
        // public rebootApiStatus: any;
        this.rebootApiStatus$ = new BehaviorSubject(false);
        this.rebootApiStatus = this.rebootApiStatus$.asObservable();
        this.applicationHeartbeatService = new PollingPeriodService(userActivityService, this.tokenValidity);
        this.onLoaded$ = new ReplaySubject(1);
        this.onError$ = new ReplaySubject(1);
    }
    TimeclockDataService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeclockDataService.prototype.init = function () {
        this.subscribeToOrgLevel();
        this.subscribeToHeartbeat();
    };
    TimeclockDataService.prototype.dispose = function () {
        this.unsubscribeOrgLevel();
        this.unsubscribeHeartbeat();
    };
    TimeclockDataService.prototype.loadTimeclocks = function () {
        if (this.orgLevel && _.isNumber(this.orgLevel.id)) {
            this.loadData(this.orgLevel.id, false);
        }
    };
    TimeclockDataService.prototype.rebootClock = function (orgLevelId, clockId) {
        var _this = this;
        var rebootObj = new RebootClockContainer;
        rebootObj.RealpostId = clockId;
        rebootObj.Status = 'Pending';
        this.taSignalrService.isRebootInitiated$.next(true);
        this.timeclockApiService.rebootClock(orgLevelId, rebootObj).then(function (result) {
            if (result) {
                if (result.isSuccess) {
                    _this.rebootApiStatus$.next(true);
                    _this.taSignalrService.isRebootInitiated$.next(true);
                    _this.notificationService.success('Sucess', "Rebooting Initialized");
                }
                else if (!result.isSuccess) {
                    _this.rebootApiStatus$.next(false);
                    _this.notificationService.error('Request Error', "Unable to connect to clock, please reboot from the clock directly");
                }
            }
            return result;
        }).catch(function (err) {
            _this.notificationService.error('Request Failed', "Something went wrong!");
            _this.rebootApiStatus$.next(false);
            return err;
        });
    };
    TimeclockDataService.prototype.loadData = function (orgLevelId, isPolling) {
        var _this = this;
        if (!this.isLoading) {
            this.isLoading = true;
            this.timeclockApiService.getTimeclockDailySummaryContainer(orgLevelId, isPolling)
                .then(function (result) {
                _this.onLoaded$.next(result);
                _this.isLoading = false;
            }).catch(function (error) {
                _this.onError$.next(error);
                _this.isLoading = false;
            });
        }
    };
    TimeclockDataService.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        if (!this.orgLevelSubscription) {
            this.orgLevelSubscription = this.orgLevel$
                .filter(function (o) { return o && _.isNumber(o.id) && (!_this.orgLevel || _this.orgLevel.id !== o.id); })
                .subscribe(function (o) {
                _this.orgLevel = o;
                _this.loadData(_this.orgLevel.id, false);
            });
        }
    };
    TimeclockDataService.prototype.subscribeToHeartbeat = function () {
        var _this = this;
        if (!this.heartbeatSubscription) {
            this.heartbeatSubscription = this.applicationHeartbeatService.onHeartbeat.subscribe(function () { return _this.loadData(_this.orgLevel.id, true); });
            this.applicationHeartbeatService.listen(appConfig.notifyPoolingInterval);
        }
    };
    TimeclockDataService.prototype.unsubscribeOrgLevel = function () {
        if (this.orgLevelSubscription) {
            this.orgLevelSubscription.unsubscribe();
            this.orgLevelSubscription = null;
        }
    };
    TimeclockDataService.prototype.unsubscribeHeartbeat = function () {
        if (this.heartbeatSubscription) {
            this.heartbeatSubscription.unsubscribe();
            this.heartbeatSubscription = null;
        }
        this.applicationHeartbeatService.stop();
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], TimeclockDataService.prototype, "orgLevel$", void 0);
    return TimeclockDataService;
}());
export { TimeclockDataService };
