import * as tslib_1 from "tslib";
import { ExceptionConsoleNavigationService } from './../../../../common/services/index';
import { OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { ExceptionConsoleRollupRecord, ExceptionConsoleRollupState, ExceptionConsoleRecord } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ExceptionConsoleRollupManagementService } from '../../../services/exception-console/exception-console-rollup-management.service';
import { Router, ActivatedRoute } from '@angular/router';
var ExceptionConsoleGridRollupComponent = /** @class */ (function () {
    function ExceptionConsoleGridRollupComponent(managementService, changeDetector, elementRef, router, route) {
        this.total = {};
        this.aggregates = [
            { field: 'totalExceptionsCount', aggregate: 'sum' },
            { field: 'pendingMissingPunchesCount', aggregate: 'sum' },
            { field: 'approvedMissingPunchesCount', aggregate: 'sum' }
        ];
        this.navService = new ExceptionConsoleNavigationService(router, route);
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.elementRef = elementRef;
    }
    ExceptionConsoleGridRollupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (container) {
            _this.container = container;
            _this.orgLevelTitle = _this.managementService.getOrgLevelTitle();
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.loadStateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.state = state;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    ExceptionConsoleGridRollupComponent.prototype.onPaycycleClicked = function (item) {
        // rollup grid component always used only in date ranges mode
        var orgLevelId = this.managementService.needShowDepartments ? item.department.orgLevelId : item.organization.orgLevelId;
        this.navService.navigateToExceptionConsoleDates(orgLevelId, item.startDate, item.endDate, false);
    };
    ExceptionConsoleGridRollupComponent.prototype.onOrgLevelClicked = function (item) {
        // rollup grid component always used only in date ranges mode
        var orgLevelId = this.managementService.needShowDepartments ? item.department.orgLevelId : item.organization.orgLevelId;
        this.navService.navigateToExceptionConsole(orgLevelId, false);
    };
    ExceptionConsoleGridRollupComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(ExceptionConsoleGridRollupComponent.prototype, "orgColumnField", {
        get: function () {
            return this.managementService.needShowDepartments ? 'depName' : 'orgName';
        },
        enumerable: true,
        configurable: true
    });
    ExceptionConsoleGridRollupComponent.prototype.getOrgLevelName = function (item) {
        if (this.managementService.needShowDepartments) {
            if (item.department)
                return item.department.name;
        }
        else {
            if (item.organization)
                return item.organization.name;
        }
        return '';
    };
    ExceptionConsoleGridRollupComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.container) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.container.records, this.gridState.state);
        this.total = aggregateBy(this.gridState.view.data, this.aggregates);
        if (this.state.dynamicColumns && this.state.dynamicColumns.length > 0) {
            _.each(this.state.dynamicColumns, function (c) {
                _this.total[c.name] = 0;
                _.each(_this.gridState.view.data, function (r) {
                    _this.total[c.name] += r.exceptionsMap[c.name].exceptionCount;
                });
            });
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleGridRollupComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleGridRollupComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleGridRollupComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleGridRollupComponent.prototype, "recordsSelectedSubscription", void 0);
    return ExceptionConsoleGridRollupComponent;
}());
export { ExceptionConsoleGridRollupComponent };
