import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, Provider, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import { Assert } from '../../../../../framework/index';
import { process, orderBy } from '@progress/kendo-data-query';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsPayCycles, EmployeeSectionsPayCycleCalcConf } from '../../../models/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSectionsPayCycleConfComponent } from '../employee-sections-pay-cycle-conf/employee-sections-pay-cycle-conf.component';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogOptions } from '../../../../../common/models/dialog-options';
import * as moment from 'moment';
import { PayCyclePeriod, PayCyclePeriodType } from '../../../../../organization/models/lookup/pay-cycle-period';
import { appConfig } from '../../../../../app.config';
import { RangeDates } from '../../../../../common/models/range-dates';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { KendoGridStateHelper } from '../../../../../../app/common';
import { BoolYNPipe } from '../../../../../common/pipes/boolyn';
var EmployeeSectionsPayCyclesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPayCyclesComponent, _super);
    function EmployeeSectionsPayCyclesComponent(employeeSectionsEmploymentApiService, modalService, decorator, ngZone, changeDetector, boolPipe) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.boolPipe = boolPipe;
        _this.createdDateTime = moment().format(appConfig.militaryDateTimeFormat);
        _this.pageSize = 5;
        _this.skip = 0;
        _this.init = false;
        _this.employeeActionToolBar = true;
        _this.pdfTemplate = {
            allPages: true,
            landscape: true,
            paperSize: 'A4',
            scale: 0.7,
            repeatHeaders: true,
            margin: '0.25in',
            marginTop: '0.675in',
            marginBottom: '0.5in',
            marginRight: '0.25in',
            marginLeft: '0.25in'
        };
        Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
        _this.appConfig = appConfig;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.skip = 0;
        _this.gridState.state.take = _this.pageSize;
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.modalService = modalService;
        _this.changeDetector = changeDetector;
        _this.canEdit = false;
        _this.sort = [{ field: 'startDate', dir: 'desc' }];
        var endDate = moment().endOf('day');
        _this.endDate = endDate.toDate();
        _this.startDate = endDate.subtract(1, 'year').startOf('day').toDate();
        _this.selectedRange = new RangeDates();
        _this.selectedRange.startDate = _this.startDate;
        _this.selectedRange.endDate = _this.endDate;
        _this.employeeName = _this.employeeShortInfo.fullName;
        _this.employeePosition = _this.employeeShortInfo.position.name;
        _this.employeeType = _this.employeeShortInfo.type;
        _this.employeePayRollNumber = _this.employeeShortInfo.payrollNumber;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsPayCyclesComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayCyclesComponent.prototype, "payCycles", {
        set: function (employeeSectionsPayCycles) {
            this.employeeSectionsPayCycles = employeeSectionsPayCycles;
            var canEdit = _.get(employeeSectionsPayCycles, 'actions.canEdit', null);
            if (_.isBoolean(canEdit)) {
                this.canEdit = canEdit;
            }
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPayCyclesComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsPayCyclesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return _.isNumber(o.id); })
            .subscribe(function (o) {
            _this.orgLevelId = o.id;
        });
    };
    EmployeeSectionsPayCyclesComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.filteredRecords, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    EmployeeSectionsPayCyclesComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    EmployeeSectionsPayCyclesComponent.prototype.onClickExportPayCycle = function (isPDF) {
        this.exportTo(isPDF);
    };
    EmployeeSectionsPayCyclesComponent.prototype.getPayCycleFileName = function (isPDF) {
        if (isPDF) {
            return this.employeeName + ' Pay Cycles History From ' + this.getPayCycleDates() + '.pdf';
        }
        else {
            return this.employeeName + ' Pay Cycles History From ' + this.getPayCycleDates() + '.xlsx';
        }
    };
    EmployeeSectionsPayCyclesComponent.prototype.getPayCycleTitle = function () {
        return 'Pay Cycles TimeCard History for the Period ' + this.getPayCycleDates();
    };
    EmployeeSectionsPayCyclesComponent.prototype.getPayCycleDates = function () {
        return moment(this.selectedRange.startDate).format(appConfig.dateFormat) + ' to ' + moment(this.selectedRange.endDate).format(appConfig.dateFormat);
    };
    EmployeeSectionsPayCyclesComponent.prototype.getPayCycleEmployeeInfo = function () {
        return this.employeeName + '   ' + this.employeePosition + '   ' + this.employeeType + '   ' + this.employeePayRollNumber;
    };
    EmployeeSectionsPayCyclesComponent.prototype.onExcelExportPayCycle = function (e) {
        var _this = this;
        var sheets = _.head(_.get(e, 'workbook.sheets'));
        _.forEach(sheets.rows, function (row) {
            if (row.type === 'data') {
                _.forEach(row.cells, function (cell) {
                    if (_.isBoolean(cell.value)) {
                        cell.value = _this.boolPipe.transform(cell.value);
                    }
                });
            }
        });
    };
    EmployeeSectionsPayCyclesComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsPayCycles;
    };
    EmployeeSectionsPayCyclesComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.selectedRange = { startDate: startDate, endDate: endDate };
        this.skip = 0;
        this.refreshGrid();
    };
    EmployeeSectionsPayCyclesComponent.prototype.onStartModify = function () {
        var _this = this;
        var conf = new EmployeeSectionsPayCycleCalcConf();
        var yesterday = moment().startOf('day').subtract(1, 'days').toDate();
        conf.effectiveDate = yesterday;
        var ordered = _.orderBy(this.employeeSectionsPayCycles.records, function (r) {
            return r.endDate;
        }, 'desc');
        var filtered = _.filter(ordered, function (r) {
            return r.approved;
        });
        if (this.employeeStatus.toLowerCase() === "future rehire") {
            conf.minEffectiveDate = this.findPayCycle(this.employeeSectionsPayCycles.records, this.employeeRehireDate);
            conf.effectiveDate = this.findPayCycle(this.employeeSectionsPayCycles.records, this.employeeRehireDate);
        }
        else {
            conf.minEffectiveDate = filtered && filtered.length > 0 ? filtered[0].endDate : null;
        }
        conf.payCycle = this.getDefaultPayCyclePeriod();
        conf.startDay1 = 1;
        conf.startDay2 = 16;
        var options = new DialogOptions();
        options.height = 370;
        options.width = 600;
        var resolvedProvidersConf = [
            { provide: DialogOptions, useValue: options },
            { provide: EmployeeSectionsPayCycleCalcConf, useValue: conf },
        ];
        this.modalService.globalAnchor.openDialog(EmployeeSectionsPayCycleConfComponent, 'Assign Pay Cycle', options, resolvedProvidersConf, function (result) {
            if (result)
                _this.doModify(conf);
        });
    };
    EmployeeSectionsPayCyclesComponent.prototype.dataStateChange = function (state) {
        this.gridState.state = state;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsPayCyclesComponent.prototype.doModify = function (conf) {
        var _this = this;
        Assert.isNotNull(conf, 'EmployeeSectionsPayCycleCalcConf');
        this.startProgress();
        this.employeeSectionsEmploymentApiService.setEmploymentPayCyclesConf(this.orgLevelId, this.employeeId, conf)
            .then(function (status) {
            _this.onActionComplete(true);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsPayCyclesComponent.prototype.getDefaultPayCyclePeriod = function () {
        var period = new PayCyclePeriod();
        period.name = PayCyclePeriodType.weekly;
        period.description = 'Weekly';
        return period;
    };
    EmployeeSectionsPayCyclesComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmploymentPayCycles(this.employeeId)
            .then(function (employeeSectionsPayCycles) {
            _this.employeeSectionsPayCycles = employeeSectionsPayCycles;
            _this.canEdit = _.get(employeeSectionsPayCycles, 'actions.canEdit', false);
            _this.state.isLoaded = true;
            _this.skip = 0;
            _this.refreshGrid();
            _this.stopProgress();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        }).catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsPayCyclesComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.employeeSectionsPayCycles) {
            this.gridState.view = null;
            return;
        }
        this.filteredRecords = _.filter(this.employeeSectionsPayCycles.records, function (record) {
            return moment(record.startDate).isBefore(_this.endDate) && moment(record.endDate).isAfter(_this.startDate);
        });
        var sortedRecords = orderBy(this.filteredRecords, this.sort);
        this.gridState.view = process(sortedRecords, this.gridState.state);
    };
    EmployeeSectionsPayCyclesComponent.prototype.findPayCycle = function (cycles, date) {
        var currentDate = moment(date).startOf('day');
        var selectedCycle = _.find(cycles, function (cycle) {
            return currentDate.isSameOrAfter(cycle.startDate) && currentDate.isSameOrBefore(cycle.endDate);
        });
        return selectedCycle.startDate;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeSectionsPayCyclesComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsPayCyclesComponent.prototype, "orgLevelSubscription", void 0);
    return EmployeeSectionsPayCyclesComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsPayCyclesComponent };
