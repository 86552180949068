import { IWcWorkersCompensationCaseDto, WcWorkersCompensationCase } from './wc-workers-compensation-case';
import { IWcCodeDefinitionDto, WcCodeDefinition } from './wc-code-definition';

export interface IWcOSHAReportDto {

    //osha details
    caseNumber: string;
    workersCompCase: IWcWorkersCompensationCaseDto;
    oshaInjuryType: IWcCodeDefinitionDto;
    isPrivacyCase: boolean;

    //injury details
    isLostDays: boolean;
    lostDaysStartDate: string;
    lostDaysEndDate: string;
    lostDaysCount: number;

    restrictedDutyDaysNum: number;

    dateOfDeath: string;
    dateErNotifiedDeath: string;

    empBeganWork: string;
    injuryOccured: IWcCodeDefinitionDto;

    isShiftFinished: boolean;

    admittedHospitalDaysNum: number;
    wasPhysTherapyOrdered: boolean;

    wasEmpRestriction: boolean;
    empRestriction: string;
    whatHarmed: string;
    bodyPart: IWcCodeDefinitionDto;
    whatDoingBefore: string;
    activityDescription: string;
    physicianName: string;
    wasTreatedToEmergencyRoom: boolean;
    wasHospitalized: boolean;
    awayTreatment: string;
    otherComments: string;
}


export class WcOSHAReport {
    //osha details
    public caseNumber: string;
    public workersCompCase: WcWorkersCompensationCase;
    public oshaInjuryType: WcCodeDefinition;
    public isPrivacyCase: boolean;

    //injury details
    public isLostDays: boolean;
    public lostDaysStartDate: Date;
    public lostDaysEndDate: Date;
    public lostDaysCount: number;

    public restrictedDutyDaysNum: number;

    public dateOfDeath: Date;
    public dateErNotifiedDeath: Date;

    public empBeganWork: Date;
    public injuryOccured: WcCodeDefinition;

    public isShiftFinished: boolean;

    public admittedHospitalDaysNum: number;
    public wasPhysTherapyOrdered: boolean;

    public wasEmpRestriction: boolean;
    public empRestriction: string;
    public whatHarmed: string;
    public bodyPart: WcCodeDefinition;
    public whatDoingBefore: string;
    public activityDescription: string;
    public physicianName: string;
    public wasTreatedToEmergencyRoom: boolean;
    public wasHospitalized: boolean;
    public awayTreatment: string;
    public otherComments: string;

    // view model fields
    public isRestrictedDuty: boolean;
}
