import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import * as moment from 'moment';

import { appConfig } from '../../../app.config';
import { ResponseBody, Meta, FieldsMeta } from '../../../core/models/index';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { ArrivalsDeparturesMapService } from './arrivals-departures-map.service';
import {
  ArrivalDeparturesContainer, IArrivalDeparturesContainer,
  DepartureDetails, IDepartureDetails,
  ArrivalDetails, IArrivalDetails,
  ArrivalsDeparturesDetails, IArrivalsDeparturesDetails
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';


@Injectable()
export class ArrivalsDeparturesApiService {
  constructor(
    private arrivalsDeparturesMapService: ArrivalsDeparturesMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getArrivalsDepartures(orgLevelId: number, dateOn: Date): Promise<ArrivalDeparturesContainer> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');

    const url: string = this.getArrivalsDeparturesApiRoot(orgLevelId, dateOn);
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<ArrivalDeparturesContainer> = this.apiUtilService.request<IArrivalDeparturesContainer, Meta>(request)
      .then((response: ResponseBody<IArrivalDeparturesContainer, Meta>) => this.arrivalsDeparturesMapService.mapArrivalsDeparturesContainer(response.data));
    return promise;
  }

  public getArrivalsUnscheduledDetails(orgLevelId: number, dateOn: Date): Promise<ArrivalDetails[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');

    const url: string = this.getArrivalsUnscheduledDetailsApiRoot(orgLevelId, dateOn);
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<ArrivalDetails[]> = this.apiUtilService.request<IArrivalDetails[], Meta>(request)
      .then((response: ResponseBody<IArrivalDetails[], Meta>) => this.arrivalsDeparturesMapService.mapArrivalsDetails(response.data));
    return promise;
  }

  public getArrivalsDetails(orgLevelId: number, dateOn: Date, timeOn: Date): Promise<ArrivalDetails[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');

    const url: string = this.getArrivalsDetailsApiRoot(orgLevelId, dateOn, timeOn);
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<ArrivalDetails[]> = this.apiUtilService.request<IArrivalDetails[], Meta>(request)
      .then((response: ResponseBody<IArrivalDetails[], Meta>) => this.arrivalsDeparturesMapService.mapArrivalsDetails(response.data));
    return promise;
  }

  public getDeparturesUnscheduledDetails(orgLevelId: number, dateOn: Date): Promise<DepartureDetails[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');

    const url: string = this.getDeparturesUnscheduledDetailsApiRoot(orgLevelId, dateOn);
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<DepartureDetails[]> = this.apiUtilService.request<IDepartureDetails[], Meta>(request)
      .then((response: ResponseBody<IDepartureDetails[], Meta>) => this.arrivalsDeparturesMapService.mapDeparturesDetails(response.data));
    return promise;
  }

  public getDeparturesDetails(orgLevelId: number, dateOn: Date, timeOn: Date): Promise<DepartureDetails[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');

    const url: string = this.getDeparturesDetailsApiRoot(orgLevelId, dateOn, timeOn);
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<DepartureDetails[]> = this.apiUtilService.request<IDepartureDetails[], Meta>(request)
      .then((response: ResponseBody<IDepartureDetails[], Meta>) => this.arrivalsDeparturesMapService.mapDeparturesDetails(response.data));
    return promise;
  }

  public getArrivalsDeparturesDetails(orgLevelId: number, dateOn: Date, timeOn: Date): Promise<ArrivalsDeparturesDetails> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');

    const url: string = `${this.getArrivalsDeparturesApiRoot(orgLevelId, dateOn)}/${timeAndAttendanceConfig.api.arrivalsDepartures.details}/${moment(timeOn).format(appConfig.requestDateTime)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<ArrivalsDeparturesDetails> = this.apiUtilService.request<IArrivalsDeparturesDetails, FieldsMeta>(request)
      .then((response: ResponseBody<IArrivalsDeparturesDetails, FieldsMeta>) => this.arrivalsDeparturesMapService.mapArrivalsDeparturesDetails(response.data, response.meta));
    return promise;
  }

  private getApiRoot(): string {
    return this.apiUtilService.getApiRoot();
  }

  private getArrivalsUnscheduledDetailsApiRoot(orgLevelId: number, dateOn: Date): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${dateTimeUtils.convertToDtoString(dateOn)}/${timeAndAttendanceConfig.api.arrivalsDepartures.root}/${timeAndAttendanceConfig.api.arrivalsDetails.orgLevel.root}/${orgLevelId}/${timeAndAttendanceConfig.api.arrivalsDetails.root}/${timeAndAttendanceConfig.api.arrivalsDetails.unscheduled}`;
  }

  private getDeparturesUnscheduledDetailsApiRoot(orgLevelId: number, dateOn: Date): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${dateTimeUtils.convertToDtoString(dateOn)}/${timeAndAttendanceConfig.api.arrivalsDepartures.root}/${timeAndAttendanceConfig.api.departuresDetails.orgLevel.root}/${orgLevelId}/${timeAndAttendanceConfig.api.departuresDetails.root}/${timeAndAttendanceConfig.api.departuresDetails.unscheduled}`;
  }

  private getArrivalsDeparturesApiRoot(orgLevelId: number, dateOn: Date): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${dateTimeUtils.convertToDtoString(dateOn)}/${timeAndAttendanceConfig.api.arrivalsDepartures.root}/${timeAndAttendanceConfig.api.arrivalsDepartures.orgLevel.root}/${orgLevelId}`;
  }

  private getArrivalsDetailsApiRoot(orgLevelId: number, dateOn: Date, timeOn: Date): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${dateTimeUtils.convertToDtoString(dateOn)}/${timeAndAttendanceConfig.api.arrivalsDepartures.root}/${timeAndAttendanceConfig.api.arrivalsDetails.orgLevel.root}/${orgLevelId}/${timeAndAttendanceConfig.api.arrivalsDetails.root}/${moment(timeOn).format(appConfig.requestDateTime)}`;
  }

  private getDeparturesDetailsApiRoot(orgLevelId: number, dateOn: Date, timeOn: Date): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${dateTimeUtils.convertToDtoString(dateOn)}/${timeAndAttendanceConfig.api.arrivalsDepartures.root}/${timeAndAttendanceConfig.api.departuresDetails.orgLevel.root}/${orgLevelId}/${timeAndAttendanceConfig.api.departuresDetails.root}/${moment(timeOn).format(appConfig.requestDateTime)}`;
  }
}
