import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { appConfig } from '../../../../../app.config';

import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';

import { DeviceDetectorService } from '../../../../../common/services/index';
import { ColumnManagementService, StateManagementService } from '../../../../../common/services/index';
import { DateRange, IDateRange, IColumnSettings } from '../../../../../core/models/index';

import { PmRosterManagementService } from '../../../services/index';


@Component({
  selector: 'slx-pm-roster-toolbar',
  templateUrl: './pm-roster-toolbar.component.html',
  styleUrls: ['./pm-roster-toolbar.component.scss'],
})
export class PmRosterToolbarComponent implements OnInit, OnDestroy {

  public yearInSeconds: number = 31622400;
  public dateRange: DateRange = new DateRange(null, null);
  public maxRangeError: string = 'The dates range cannot be more than 366 days';

  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }

  public get dateRangeWidth(): number {
    return this.isDesktop ? 325 : 246;
  }

  public get newReviewBtnWidth(): number {
    return this.isDesktop ? 175 : 35;
  }

  public canCreateNewReview = false;
  public readonly columnsStateName: string = 'PerformanceManagementRoster';

  private orgLevel: OrgLevel;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private pmManagementService: PmRosterManagementService,
    private columnManagementService: ColumnManagementService,
    private stateManagement: StateManagementService,
    private devDetector: DeviceDetectorService
  ) {}

  public ngOnInit(): void {

    this.initServices();
    this.defineDateRange();

    this.subscriptions.abilityToCreateNewReview = this.pmManagementService
      .subscribeToCanCreateNewReview((canCreate: boolean) => (this.canCreateNewReview = canCreate));
  }

  public ngOnDestroy(): void {}

  public onClickCreate(): void {
    this.pmManagementService.openReviewPopup(null);
  }

  public onChangeDates(range: IDateRange): void {
    this.pmManagementService.changeDateRange(range);
  }

  public onClickExport(isPDF: boolean): void {
    this.pmManagementService.exportTo(isPDF);
  }

  public defineDateRange(): void {
    this.dateRange = this.pmManagementService.getDefaultDateRange();
    this.onChangeDates(this.dateRange);
  }

  private initServices(): void {
    this.stateManagement.init('PerformanceManagementRosterComponent');
    this.columnManagementService.init('PerformanceManagementRosterComponent');
    this.columnManagementService.initGroup(this.columnsStateName, 9);
  }
}
