import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { dateTimeUtils } from '../../../../app/common/utils';
import { IPartnerClientStatusData, PartnerClientStatusData } from '../../../../app/common/models/integrations/partner-client-status.model';
import { IPartnerData, PartnerData } from '../../../../app/common/models/integrations/partner-data.model';
import { LookupMapService, ProductModuleMapService } from '../../../organization/services/index';
import {
  IPartnerPosition,
  IPartnerPositionDefinition,
  PartnerPosition,
  PartnerPositionDefinition,
} from '../../models/index';

@Injectable()
export class PartnerConfigMapService {
  constructor(private lookupMapService: LookupMapService, private productModuleMapService: ProductModuleMapService) {}

  public mapToPartnerPositions(data: PartnerPosition[]): IPartnerPosition[] {
    let positions: PartnerPosition[] = [];
    _.each(data, (dto: IPartnerPosition) => {
      let position: PartnerPosition = this.mapToPartnerPosistion(dto);
      positions.push(position);
    });
    return positions;
  }
  public mapToPartnerPosistion(data: any): PartnerPosition {
    let postions: PartnerPosition = new PartnerPosition();
    postions.map_id = data.map_id || null;
    postions.partner_position_name = data.partner_position_name || null;
    postions.position_name = data.position_name || null;
    return postions;
  }

  public mapToPartnerPostionsDefinitions(data: PartnerPositionDefinition[]): IPartnerPositionDefinition[] {
    let positions: PartnerPositionDefinition[] = [];
    _.each(data, (dto: IPartnerPositionDefinition) => {
      let position: PartnerPositionDefinition = this.mapToPartnerPosistionDefinition(dto);
      positions.push(position);
    });
    return positions;
  }
  public mapToPartnerPosistionDefinition(data: any): PartnerPositionDefinition {
    let postions: PartnerPositionDefinition = new PartnerPositionDefinition();
    postions.id = data.id || null;
    postions.partnerPositionName = data.partnerPositionName || null;
    postions.partnerId = data.partnerId || null;
    return postions;
  }

  public mapToPartnerDefinitions(data: PartnerData[]): IPartnerData[] {
    let partners: PartnerData[] = [];
    _.each(data, (dto: IPartnerData) => {
      let partner: PartnerData = this.mapToPartnerDefinition(dto);
      partners.push(partner);
    });
    return partners;
  }

  public mapToPartnerDefinition(data: any): PartnerData {
    let partner: PartnerData = new PartnerData();
    partner.id = data.id || null;
    partner.name = data.name || null;
    partner.description = data.description || null;
    return partner;
  }

  public mapToPartnerClientActivationStatus(data: any): IPartnerClientStatusData {
    let partnerClientStatusData: IPartnerClientStatusData = new PartnerClientStatusData();
    if (data) {
      partnerClientStatusData.version = data.version || null;
      partnerClientStatusData.status = data.status || null;
      partnerClientStatusData.partnerName = data.partnerName || null;
      let authorizedDate = data.authorizedDate || null
      partnerClientStatusData.authorizedDate = dateTimeUtils.convertFromDtoString(authorizedDate);
      partnerClientStatusData.initiateUrl = data.initiateUrl || null;
    }
    return partnerClientStatusData;
    
  }
}
