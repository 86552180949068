import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, Provider } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, DialogOptions } from '../../../common/index';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
import { appMessages } from '../../../app.messages';
import { AccessManagementService, UnitsConfigurationManagementService } from '../../services/index';
import { Unit, EditableListActionKind } from '../../models/index';
import { UnitRemoveDialogComponent } from './location-unit-remove-dialog/location-unit-remove-dialog.component';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { NgForm } from '@angular/forms';
var UnitsComponent = /** @class */ (function () {
    function UnitsComponent(management, access, modalService) {
        var _this = this;
        this.access = access;
        this.modalService = modalService;
        this.prohibitedNameValues = [];
        this.prohibitedDescriptionValues = [];
        this.management = management;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        // directive intercepted methods (scope bug)
        this.onAddItem = function () {
            var item = new Unit();
            _this.crudHelper.addItem(item);
            _this.updateProhibitedNameValues(item);
        };
        this.onDeletedItems = function () {
            _this.crudHelper.deleteSelected();
        };
    }
    Object.defineProperty(UnitsComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    UnitsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appMessages = appMessages;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            configureMode: true,
            copyMode: false
        };
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
            if (_.has(state, 'configureMode'))
                _this.state.configureMode = state.configureMode;
            if (_.has(state, 'copyMode'))
                _this.state.copyMode = state.copyMode;
        });
        this.editSubscription = this.management.editItemCmd$.subscribe(function (item) {
            if (item) {
                _this.updateProhibitedNameValues(item);
                _this.mainFormSubscription = _this.mainForm.statusChanges.subscribe(function () {
                    if (_this.mainForm.dirty) {
                        _this.management.markAsDirty();
                    }
                });
            }
            else {
                if (_this.mainFormSubscription) {
                    _this.mainFormSubscription.unsubscribe();
                }
            }
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.removeSubscription = this.management.removeItemsCmd$.subscribe(function (item) {
            if (item.employeesCount > 0) {
                var dialogOptions = new DialogOptions();
                dialogOptions.height = 190;
                dialogOptions.width = 350;
                var resolvedProviders = [
                    {
                        provide: DialogOptions,
                        useValue: dialogOptions
                    },
                    {
                        provide: ModalService,
                        useValue: _this.modalService
                    }
                ];
                var dialog_1 = _this.modalService.globalAnchor.openDialog(UnitRemoveDialogComponent, 'Remove Unit', dialogOptions, resolvedProviders, function (result, uniqueId) {
                    if (result) {
                        _this.management.doRemoveItem(item, dialog_1.unit);
                    }
                });
                dialog_1.units = _this.management.container.records.filter(function (obj, index, array) {
                    return !(obj === item || obj.id === 0);
                });
            }
            else {
                _this.management.doRemoveItem(item, null);
            }
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
    };
    // Must be, see #issueWithAOTCompiler
    UnitsComponent.prototype.ngOnDestroy = function () {
        if (this.crudHelper) {
            this.crudHelper.destroy();
        }
    };
    UnitsComponent.prototype.updateProhibitedNameValues = function (item) {
        if (item) {
            var values_1 = ['FL'];
            var descValues_1 = [];
            _.each(this.management.container.records, function (s) {
                if (item.id !== s.id) {
                    values_1.push(s.name);
                    descValues_1.push(s.description);
                }
            });
            this.prohibitedDescriptionValues = descValues_1;
            this.prohibitedNameValues = values_1;
        }
    };
    UnitsComponent.prototype.onMobileRendererEvent = function (action, item, index) {
        if (action === EditableListActionKind.SELECTION_CHANGE) {
            this.crudHelper.selectionChange(item, index);
        }
        else if (action === EditableListActionKind.START_EDIT) {
            this.crudHelper.startEdit(item, index);
        }
    };
    UnitsComponent.prototype.onMobileEditorEvent = function (action, item, index) {
        if (action === EditableListActionKind.COMPLETE_EDIT) {
            this.crudHelper.completeEdit(item, index);
        }
        else if (action === EditableListActionKind.CANCEL_EDIT) {
            this.crudHelper.cancelEdit(index);
        }
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", UnitsConfigurationManagementService)
    ], UnitsComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UnitsComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UnitsComponent.prototype, "editSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UnitsComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UnitsComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UnitsComponent.prototype, "mainFormSubscription", void 0);
    return UnitsComponent;
}());
export { UnitsComponent };
