import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';

import { schedulerConfig } from '../../scheduler.config';
import { OperationalConsoleMapService } from './operational-console-map.service';
import { dateTimeUtils } from '../../../common/utils/index';

import { IOperationalConsoleEntry, OperationalConsoleEntry } from '../../../employee/employee-list/models/index';

@Injectable()
export class OperationalConsoleApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: OperationalConsoleMapService,
  ) {
  }

  public getOperationalConsole(orgLevelId: number, date: Date, unitIds: number[] = null, shiftIds: number[] = null): Promise<OperationalConsoleEntry[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(date, 'data');

    const url: string = `${this.getApiRoot()}/${schedulerConfig.api.orglevel}/${orgLevelId}/dateOn/${dateTimeUtils.convertToDtoString(date)}/rows`;
    let params: StringMap<any> = {};
    if(unitIds && unitIds.length > 0) {
      params.unitIds = unitIds;
    }
    if(shiftIds && shiftIds.length > 0) {
      params.shiftIds = shiftIds;
    }
    const request = this.urlParamsService.createGetRequest(url, params, true);

    return this.apiUtilService.request<IOperationalConsoleEntry[], Meta>(request)
      .then((response: ResponseBody<IOperationalConsoleEntry[], Meta>) => this.mapService.mapToConsole(response.data));
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.soConsole}`;
  }
}
