import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { TempSensorReading, TempSensorReadingEventCmd, TempSensorReadingEvent } from '../../../../../configuration/models';
import { TempSensorReadingManagementService } from '../../../../../configuration/services';
import { OrgLevel } from '../../../../../state-model/models';
import { DeviceDetectorService } from '../../../../..//common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'slx-temp-readings-grid',
  templateUrl: 'temp-readings-grid.component.html',
  styleUrls: ['temp-readings-grid.component.scss'],
})
export class TempReadingsGridComponent implements OnInit, OnDestroy {
  public records: TempSensorReading[];
  public gridState: KendoGridStateHelper<TempSensorReading[]>;
  public appConfig: IApplicationConfig;
  public orgLevel: OrgLevel;
  public pageSize: number = 50;
  public canEdit: boolean;
  public xlsxName = 'Temp_Sensor_Readings_';
  public pdfName = 'Temp_Sensor_Readings_';
  public columnsGroupName: string = 'TempReadings';

  public get isLockedColumn(): boolean {
    return this.devDetector.isDesktop;
  }

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private managementService: TempSensorReadingManagementService,
              private devDetector: DeviceDetectorService, 
              private changeDetector: ChangeDetectorRef,
              private router: Router, 
              private route: ActivatedRoute
  ) {
    this.gridState = new KendoGridStateHelper<TempSensorReading[]>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'eventDate', dir: 'desc' },
      { field: 'employee.name', dir: 'asc' }
    ];
    this.appConfig = appConfig;
  }

  public ngOnInit() {
    const date = new Date().toLocaleDateString();
    this.xlsxName += `${date}.xlsx`;
    this.pdfName += `${date}.pdf`;

    this.subscriptions.onLoaded = this.managementService
      .subscribeToLoadedRecords((r: TempSensorReading[]) => this.assignRecords(r));

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());

    this.subscriptions.export = this.managementService.subscribeToExport((event: TempSensorReadingEvent): void => {
      this.exportTo(event);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  private assignRecords(records: TempSensorReading[]): void {
    this.records = records;
    this.refreshGrid();
  }

  private exportTo(event: TempSensorReadingEvent): void {
    if (event.cmd === TempSensorReadingEventCmd.excelExport) {
      this.grid.saveAsExcel();
    } else if (event.cmd === TempSensorReadingEventCmd.pdfExport) {
      this.grid.saveAsPDF();
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
    this.changeDetector.detectChanges();
  }
}
