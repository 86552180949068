import { ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
var DurationInputComponent = /** @class */ (function () {
    function DurationInputComponent(elementRef) {
        this.internalValue = '';
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.hasFocus = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.hasFocus = false;
        this.durationMask = {
            mask: function (value) {
                var numberMatch = value.match(/\d/g);
                var numberLength = numberMatch ? numberMatch.join('').length : 0;
                console.log(numberLength);
                if (numberLength === 2) {
                    return [/[1-9]/, ':', /[0-5]/, /[0-9]/];
                }
                else if (numberLength >= 5) {
                    return [/(0|1)/, /[0-2]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
                }
                else if (numberLength === 4) {
                    return [/(0|1)/, /[0-2]/, ':', /[0-5]/, /[0-9]/];
                }
                return [/[0-2]/, ':', /[0-5]/, /[0-9]/];
            },
            guide: true
        };
    }
    Object.defineProperty(DurationInputComponent.prototype, "readonly", {
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    DurationInputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    DurationInputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
        this.onTouchedCallback();
    };
    DurationInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !myattr;
    };
    DurationInputComponent.prototype.writeValue = function (value) {
        this.internalValue = value;
    };
    DurationInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    DurationInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DurationInputComponent.prototype.valueChanged = function (value) {
        this.onChangeCallback(value);
    };
    return DurationInputComponent;
}());
export { DurationInputComponent };
