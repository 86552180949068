<slx-spinner [show]="state.isLoading">
    <div class="slx-high-box">
      <form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
          <kendo-grid #kendoGrid class="slx-full-height"
            [data]="gridState?.view"
            [selectable]="{ mode: 'single' }"
            [filterable]="true"
            [filter]="gridState.state.filter"
            [sortable]="{ mode: 'multiple' }"
            [sort]="gridState?.state?.sort"
            (selectionChange)="gridState.selectionChange($event)"
            (dataStateChange)="gridState.dataStateChange($event)"
            (cancel)="gridState.cancelHandler($event)"
            (edit)="gridState.editHandler($event)"
            (remove)="gridState.removeHandler($event)"
            (save)="gridState.saveHandler($event)"
          >

            <!-- desktop view -->
            <kendo-grid-command-column *ngIf="!access.restrictedByOrgLevel" width="80" [locked]="true" media="(min-width: 450px)">
                <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
                    <div class="command-container">
                        <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button">
                            <i class="fas fa-pencil-alt"  aria-hidden="true"></i>
                        </button>
                        <button kendoGridSaveCommand type="button" [disabled]="!templateForm.valid" class="theme-icon-button theme-inline-apply-button">
                            <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
                        </button>
                        <button kendoGridCancelCommand type="button" class="theme-icon-button theme-inline-cancel-button">
                            <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
                        </button>
                    </div>
                </ng-template>
            </kendo-grid-command-column>

              <kendo-grid-column title="Name" field="name" [filterable]="true" width="200" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="left-align">{{ dataItem.name }}</span>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-input-decorator>
                          <input class="form-control" [prohibitedValues]="prohibitedNameValues" slx-input type="text" [(ngModel)]="dataItem.name" [required]="true" [placeholder]="''" name="nameInput{{rowIndex}}">
                          <span errorMessage for="required"></span>
                          <span errorMessage for="prohibitedValues">{{appMessages.validation.prohibitedDescriptionValidation.message}}</span>
                      </slx-input-decorator>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-string-filter>
                  </ng-template>
              </kendo-grid-column>

            <kendo-grid-column title="Code" field="code" [filterable]="true" width="150" media="(min-width: 450px)">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span class="left-align">{{ dataItem.code }}</span>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <slx-input-decorator>
                      <input class="form-control" slx-input type="text"
                             [(ngModel)]="dataItem.code"
                             [required]="true"
                             [placeholder]="''"
                             [prohibitedValues]="prohibitedCodeValues"
                             name="codeInput{{rowIndex}}" slxMaxLength="1">
                      <span errorMessage for="required"></span>
                      <span errorMessage for="slxMaxLength">Code should be a maximum length of '1'</span>
                      <span errorMessage for="prohibitedValues">{{appMessages.validation.prohibitedDescriptionValidation.message}}</span>
                    </slx-input-decorator>
                </ng-template>
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                  <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                  </slx-kendo-grid-string-filter>
                </ng-template>
            </kendo-grid-column>

              <kendo-grid-column title="Count as" field="countAs" [filterable]="true" width="150" media="(min-width: 450px)">
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter" [step]="0.1" [min]="0" [max]="1">
                      </slx-kendo-grid-number-filter>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="right-align">{{ dataItem.countAs }}</span>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-input-decorator>
                          <slx-kendo-number slx-input
                            [(ngModel)]="dataItem.countAs"
                            [decimals]="2"
                            [step]="0.1"
                            [min]="0"
                            [max]="1"
                            [slxMin]="0"
                            [slxMax]="1"
                            [slxMinStrict]="false"
                            [slxMaxStrict]="false"
                            [required]="'true'"
                            [autoCorrect]="true"
                            name="count{{rowIndex}}"
                          ></slx-kendo-number>
                          <span errorMessage for="required"></span>
                          <span errorMessage for="max">Max value is 1</span>
                          <span errorMessage for="min">Min value should be greater than 0</span>
                      </slx-input-decorator>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-number-filter>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Used Count" field="usedCount" [filterable]="true" width="150" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="right-align">{{ dataItem.usedCount }}</span>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-number-filter>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Updated By" field="lastUpdateUsername" [filterable]="true" width="200" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="left-align">{{ dataItem.lastUpdateUsername }}</span>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-string-filter>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Last Updated" field="lastUpdateDate" filter="date" [filterable]="true" width="200" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="left-align" *ngIf="dataItem.lastUpdateDate">{{ dataItem.lastUpdateDate | amDateFormat: appConfig.dateTimeFormat }}</span>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-date-filter>
                  </ng-template>
              </kendo-grid-column>

              <!-- mobile view -->
              <kendo-grid-column media="(max-width: 450px)" width="100%">

                  <ng-template kendoGridHeaderTemplate>Constraints</ng-template>

                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <slx-configure-constraint-renderer [rowIndex]="rowIndex" [item]="dataItem" (actionEmitter)="onMobileRendererEvent ($event, dataItem, rowIndex)"></slx-configure-constraint-renderer>
                  </ng-template>

                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-configure-constraint-editor [item]="dataItem" [rowIndex]="rowIndex" [prohibitedCodeValues]="prohibitedCodeValues" [prohibitedNameValues]="prohibitedNameValues" (actionEmitter)="onMobileEditorEvent ($event, dataItem, rowIndex)"></slx-configure-constraint-editor>
                  </ng-template>
              </kendo-grid-column>
          </kendo-grid>
      </form>

      <div class="button-box" [ngClass]="{'inactive': access.lockActions}">
        <div class="col-xs-12 col-sm-9 col-md-9">
          <span *ngIf="access.restrictedByOrgLevel" class="edit-info-warning">Constraints are editable only at Department level</span>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3">
          <div class="buttons">
            <button class="action-button" [slx-list-delete-operation]="onDeletedItems">Delete</button>
            <button class="action-button" [slx-list-add-operation]="onAddItem">Add</button>
          </div>
        </div>
      </div>
    </div>

</slx-spinner>
