import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import {
  ModalService,
  KendoGridStateHelper,
  KendoGridCustomSelectionHelper,
  ConfirmOptions,
  ConfirmDialogComponent
} from '../../../common/index';
import {
  mutableSelect,
  destroyService,
  unsubscribe
} from '../../../core/decorators/index';

import { OrgLevel } from '../../../state-model/models/index';
import { ColorUtil } from '../../../common/utils/color-util';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { appMessages, IApplicationMessages } from '../../../app.messages';

import { AccessManagementService, ConstraintsConfigurationManagementService } from '../../services/index';
import { ConstraintModel, EditableListActionKind } from '../../models/index';
import { EditableListComponent } from '../../components/index';
import { NotificationsService } from '../../../core/components/index';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { NgForm } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-constraints',
  templateUrl: 'configure-constraints.component.html',
  styleUrls: ['configure-constraints.component.scss'],
  providers: [ConstraintsConfigurationManagementService, AccessManagementService]
})
export class ConfigureConstraintsComponent implements OnInit, OnDestroy {

  public onAddItem: () => void;
  public onDeletedItems: () => void;

  public crudHelper: ConfigurationComponentHelper<ConstraintModel>;
  public gridState: KendoGridStateHelper<ConstraintModel>;
  public selectionHelper: KendoGridCustomSelectionHelper<ConstraintModel>;

  public state: {
    isLoading: boolean;
    configureMode: boolean;
    copyMode: boolean;
  };

  public appConfig: IApplicationConfig;
  public appMessages: IApplicationMessages;

  public prohibitedNameValues: string[] = [];
  public prohibitedCodeValues: string[] = [];

  @ViewChild('kendoGrid', {static: true})
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }

  private get grid(): GridComponent {
    return this.m_grid;
  }

  private m_grid: GridComponent;

  @ViewChild('templateForm', { static: true })
  private mainForm: NgForm;

  @destroyService()
  private management: ConstraintsConfigurationManagementService;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private editSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private mainFormSubscription: Subscription;

  constructor(management: ConstraintsConfigurationManagementService,
    public access: AccessManagementService,
    private modalService: ModalService) {

    this.management = management;

    this.gridState = new KendoGridStateHelper<ConstraintModel>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

    this.crudHelper = new ConfigurationComponentHelper<ConstraintModel>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;

    // directive intercepted methods (scope bug)
    this.onAddItem = () => {
      let item: ConstraintModel = new ConstraintModel();
      this.crudHelper.addItem(item);
      this.updateProhibitedNameValues (item);
      this.updateProhibitedCodeValues (item);
    };

    this.onDeletedItems = () => {
      this.crudHelper.deleteSelected();
    };
  }

  public ngOnInit(): void {

    this.appMessages = appMessages;
    this.appConfig = appConfig;

    this.state = {
      isLoading: false,
      configureMode: true,
      copyMode: false
    };

    this.stateSubscription = this.management.onStateChanged$.subscribe((state: { isLoading: boolean, configureMode: boolean, copyMode: boolean }) => {
      if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
      if (_.has(state, 'configureMode')) this.state.configureMode = state.configureMode;
      if (_.has(state, 'copyMode')) this.state.copyMode = state.copyMode;
    });

    this.editSubscription = this.management.editItemCmd$.subscribe((item: ConstraintModel) => {

      if (item) {
        this.updateProhibitedNameValues(item);
        this.updateProhibitedCodeValues(item);
        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
          if (this.mainForm.dirty) {
            this.management.markAsDirty();
          }
        });
      } else {
        if (this.mainFormSubscription) {
          this.mainFormSubscription.unsubscribe();
        }
      }
    });

    this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: ConstraintModel[]): void => {
      this.crudHelper.selectionChange(_.first(records), 0);
    });

    this.removeSubscription = this.management.removeItemsCmd$.subscribe((item: ConstraintModel) => {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      ConfirmDialogComponent.openDialog(
        'Confirmation',
        'Do you want to delete the constraint?',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.management.doRemoveItem(item);
          }
        }, options);
    });

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
    this.management.init();
  }

  // Must be, see #issueWithAOTCompiler
  public ngOnDestroy(): void {
    if (this.crudHelper) {
      this.crudHelper.destroy();
    }
  }

  public updateProhibitedNameValues(item: ConstraintModel): void {
    if (item) {
      let values: string[] = [];
      _.each(this.management.container.records, (s: ConstraintModel) => {
        if (item.name !== s.name) {
          values.push(s.name);
        }
      });
      this.prohibitedNameValues = values;
    }
  }

  public updateProhibitedCodeValues(item: ConstraintModel): void {
    if (item) {
      let values: string[] = [];
      _.each(this.management.container.records, (s: ConstraintModel) => {
        if (item.code !== s.code) {
          values.push(s.code);
        }
      });
      this.prohibitedCodeValues = values;
    }
  }


  public onMobileRendererEvent(action: EditableListActionKind, item: ConstraintModel, index: number): void {
    if (action === EditableListActionKind.SELECTION_CHANGE) {
      this.crudHelper.selectionChange(item, index);
    } else if (action === EditableListActionKind.START_EDIT) {
      this.crudHelper.startEdit(item, index);
    }
  }

  public onMobileEditorEvent(action: EditableListActionKind, item: ConstraintModel, index: number): void {
    if (action === EditableListActionKind.COMPLETE_EDIT) {
      this.crudHelper.completeEdit(item, index);
    } else if (action === EditableListActionKind.CANCEL_EDIT) {
      this.crudHelper.cancelEdit(index);
    }
  }
}

