import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { Assert } from '../../../framework/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { Subject } from 'rxjs';
import { schedulerConfig } from '../../scheduler.config';
import { OpenPositionControlMapService } from './open-position-control-map.service';
import { openPositionControlConfig } from '../../models/open-position-control/open-position-control-config';
var OpenPositionsControlApiService = /** @class */ (function () {
    function OpenPositionsControlApiService(apiUtilService, urlParamsService, openPositionControlMapService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.openPositionControlMapService = openPositionControlMapService;
        this.exportTo$ = new Subject();
        this.isLoadPositionGroupDetails$ = new Subject();
    }
    OpenPositionsControlApiService.prototype.getOpenPositions = function (orgLevelId, startDate, endDate) {
        var _this = this;
        Assert.isNotNull(orgLevelId, 'orgLevelId');
        var url = "" + this.getLeaveRequestApiRoot(orgLevelId, startDate, endDate);
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.openPositionControlMapService.mapOpenShiftPositions(response.data, response.meta); });
    };
    OpenPositionsControlApiService.prototype.getOpenPositionControlConfig = function (orgLevelId) {
        var _this = this;
        var url = this.getOpenPositionControlConfigAPIRoot(orgLevelId);
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.openPositionControlMapService.mapOpenPositionControlData(response.data, response.meta); });
    };
    OpenPositionsControlApiService.prototype.saveOpenPositionConfiguration = function (config) {
        var url = this.getOpenPositionControlConfigSaveAPIRoot();
        //let body = new openPositionControlConfig();
        var request = this.urlParamsService.createPutRequest(url, config);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
        return promise;
    };
    OpenPositionsControlApiService.prototype.getPositionGroupDetails = function (orglevelId, startDate, endDate, shiftGroup) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getPositionGroupDetailsAPIRoot(orglevelId, startDate, endDate, shiftGroup);
                request = new HttpRequest('GET', url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.openPositionControlMapService.mapOpenShiftGroupDetails(response.data, response.meta); })];
            });
        });
    };
    OpenPositionsControlApiService.prototype.getLeaveRequestApiRoot = function (orgLevelId, startDate, endDate) {
        var startDateStr = dateTimeUtils.convertToDtoString(startDate);
        var endDateStr = dateTimeUtils.convertToDtoString(endDate);
        return this.getOpenPositionApiRoot() + "/" + startDateStr + "/" + endDateStr + "/orglevel/" + orgLevelId + "/openShiftDetails";
    };
    OpenPositionsControlApiService.prototype.getPositionGroupDetailsAPIRoot = function (orgLevelId, startDate, endDate, shiftGroup) {
        var startDateStr = dateTimeUtils.convertToDtoString(startDate);
        var endDateStr = dateTimeUtils.convertToDtoString(endDate);
        return this.getOpenPositionApiRoot() + "/" + startDateStr + "/" + endDateStr + "/orglevel/" + orgLevelId + "/shiftgroup/" + shiftGroup + "/positionGroupDetails";
    };
    OpenPositionsControlApiService.prototype.getOpenPositionApiRoot = function () {
        return this.getApiRoot() + "/" + schedulerConfig.api.schedule.openPosition;
    };
    OpenPositionsControlApiService.prototype.getApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + schedulerConfig.api.schedule.root;
    };
    OpenPositionsControlApiService.prototype.getOpenPositionControlConfigAPIRoot = function (orgLevelId) {
        return this.getOpenPositionApiRoot() + "/config/" + orgLevelId;
    };
    OpenPositionsControlApiService.prototype.getOpenPositionControlConfigSaveAPIRoot = function () {
        return this.getOpenPositionApiRoot() + "/config/save";
    };
    return OpenPositionsControlApiService;
}());
export { OpenPositionsControlApiService };
