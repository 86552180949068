import {
  Component,
  Input,
  ElementRef,
  forwardRef
} from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export const RADIO_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioInputComponent),
  multi: true
};

@Component({
  moduleId: module.id,
  selector: 'slx-radio-input',
  templateUrl: 'radio-input.component.html',
  styleUrls: ['radio-input.component.scss'],
  providers: [RADIO_VALUE_ACCESSOR]
})
export class RadioInputComponent implements ControlValueAccessor {
  @Input()
  public fieldName: string;
  @Input()
  public placeholder: string;
  @Input()
  public caption: string;
  @Input()
  public className: string;
  @Input()
  public option: any;
  @Input()
  public valueField: string;
  @Input()
  public readonly: boolean;

  public get value(): any {
    return this.innerValue;
  }

  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(this.innerValue);
    }
  }

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private innerValue: any = {};

  public writeValue(value?: any): void {
    if (!_.isUndefined(value) && !_.isNull(value)) {
      this.innerValue = value;
    }
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }
}
