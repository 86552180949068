import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitDetailsTier } from '../../../../models/index';
var BenefitOptionDetailsFormulaWithOptionGridComponent = /** @class */ (function () {
    function BenefitOptionDetailsFormulaWithOptionGridComponent() {
        this.data = [];
        this.isLoading = true;
        this.isShowingExpired = false;
        this.employeeContribution = 0;
        this.employerContribution = 0;
        this.coverage = 0;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.formulaGridState = new KendoGridStateHelper();
        this.formulaGridState.view = null;
        this.formulaGridState.state.skip = 0;
        this.appConfig = appConfig;
    }
    BenefitOptionDetailsFormulaWithOptionGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.data = [this.tierDetails];
        this.populateEmployeeContribution();
        this.populateEmployerContribution();
        this.populateCoverage();
        this.refreshGrid();
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
    };
    BenefitOptionDetailsFormulaWithOptionGridComponent.prototype.populateEmployeeContribution = function () {
        if (this.tierDetails && this.tierDetails.empFormula) {
            if (this.tierDetails.empFormula.isFixedCalcType) {
                this.employeeContribution = this.tierDetails.empFormula.fixedAmount;
            }
            else if (this.tierDetails.empFormula.isFormulaCalcType) {
                this.employeeContribution = 0;
            }
            else {
                this.employeeContribution = 0;
            }
        }
    };
    BenefitOptionDetailsFormulaWithOptionGridComponent.prototype.populateEmployerContribution = function () {
        if (this.tierDetails && this.tierDetails.erFormula) {
            if (this.tierDetails.erFormula.isFixedCalcType) {
                this.employerContribution = this.tierDetails.erFormula.fixedAmount;
            }
            else if (this.tierDetails.erFormula.isFormulaCalcType) {
                this.employerContribution = 0;
            }
            else {
                this.employerContribution = 0;
            }
        }
    };
    BenefitOptionDetailsFormulaWithOptionGridComponent.prototype.populateCoverage = function () {
        if (this.tierDetails && this.tierDetails.coverageFormula) {
            if (this.tierDetails.coverageFormula.isFixedCalcType) {
                this.coverage = this.tierDetails.coverageFormula.fixedAmount;
            }
            else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
                this.coverage = 0;
            }
            else {
                this.coverage = 0;
            }
        }
    };
    BenefitOptionDetailsFormulaWithOptionGridComponent.prototype.ngOnDestroy = function () {
    };
    BenefitOptionDetailsFormulaWithOptionGridComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        if (this.benefitOptionData) {
            this.gridState.view = process(this.benefitOptionData, this.gridState.state);
        }
        else {
            this.gridState.view = null;
        }
        if (this.data) {
            this.formulaGridState.view = process(this.data, this.formulaGridState.state);
        }
        else {
            this.formulaGridState.view = null;
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitOptionDetailsFormulaWithOptionGridComponent.prototype, "subscriptions", void 0);
    return BenefitOptionDetailsFormulaWithOptionGridComponent;
}());
export { BenefitOptionDetailsFormulaWithOptionGridComponent };
