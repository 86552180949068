/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./actions-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-popper";
import * as i4 from "./actions-button.component";
var styles_ActionsButtonComponent = [i0.styles];
var RenderType_ActionsButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionsButtonComponent, data: {} });
export { RenderType_ActionsButtonComponent as RenderType_ActionsButtonComponent };
export function View_ActionsButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "button", [["tabindex", "1"], ["type", "button"]], null, [[null, "keyup"], [null, "popperOnShown"], [null, "popperOnHidden"], [null, "touchstart"], [null, "click"], [null, "mousedown"], [null, "mouseenter"], [null, "touchend"], [null, "touchcancel"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).showOrHideOnMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).showOrHideOnClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).showOrHideOnMouseOver($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).showOnHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 3).hideOnLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("touchcancel" === en)) {
        var pd_5 = (i1.ɵnov(_v, 3).hideOnLeave($event) !== false);
        ad = (pd_5 && ad);
    } if (("mouseleave" === en)) {
        var pd_6 = (i1.ɵnov(_v, 3).hideOnLeave($event) !== false);
        ad = (pd_6 && ad);
    } if (("keyup" === en)) {
        var pd_7 = (_co.onKeyup($event) !== false);
        ad = (pd_7 && ad);
    } if (("popperOnShown" === en)) {
        var pd_8 = (_co.onShown($event) !== false);
        ad = (pd_8 && ad);
    } if (("popperOnHidden" === en)) {
        var pd_9 = (_co.onHidden($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 737280, null, 0, i3.PopperController, [i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i1.Renderer2], { content: [0, "content"], placement: [1, "placement"], showTrigger: [2, "showTrigger"], disableStyle: [3, "disableStyle"] }, { popperOnShown: "popperOnShown", popperOnHidden: "popperOnHidden" }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.popperContent; var currVal_2 = _co.popperPosition; var currVal_3 = "click"; var currVal_4 = "true"; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ActionsButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-actions-button", [], null, null, null, View_ActionsButtonComponent_0, RenderType_ActionsButtonComponent)), i1.ɵdid(1, 49152, null, 0, i4.ActionsButtonComponent, [], null, null)], null, null); }
var ActionsButtonComponentNgFactory = i1.ɵccf("slx-actions-button", i4.ActionsButtonComponent, View_ActionsButtonComponent_Host_0, { popperContent: "popperContent", popperPosition: "popperPosition", customClassName: "className", fullyCustomStyle: "fullyCustomStyle" }, { popperOnShow: "popperOnShow", popperOnHide: "popperOnHide" }, ["*"]);
export { ActionsButtonComponentNgFactory as ActionsButtonComponentNgFactory };
