import { Assert } from '../../../framework/index';
import * as moment from 'moment';
import * as _ from 'lodash';
import { EmployeeBannerInfo } from '../../employee/models/index';
var EmployeeSectionManagementService = /** @class */ (function () {
    function EmployeeSectionManagementService(employeeSectionsContext, employeeSectionBridgeService, employeeSectionApiService, dateTimeService) {
        this.employeeSectionBridgeService = employeeSectionBridgeService;
        this.employeeSectionsContext = employeeSectionsContext;
        this.dateTimeService = dateTimeService;
        this.employeeSectionApiService = employeeSectionApiService;
        this.loadedSectionsMap = {};
    }
    Object.defineProperty(EmployeeSectionManagementService.prototype, "employeeShortInfo", {
        get: function () {
            return this.m_employeeShortInfo;
        },
        set: function (value) {
            this.m_employeeShortInfo = value;
            if (this.personalSection && this.personalSection.profile) {
                this.setEmployeeBannerInfo(this.personalSection.profile);
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionManagementService.prototype.getSectionStateBySubsectionId = function (id) {
        var section = this.getSectionBySubsectionId(id);
        if (!section) {
            return false;
        }
        if (section.id === 'Personal') {
            return this.isLoadingPersonal;
        }
        if (section.id === 'Employment') {
            return this.isLoadingEmployment;
        }
        if (section.id === 'Custom') {
            return this.isLoadingCustom;
        }
        if (section.id === 'Audit') {
            return this.isLoadingAudit;
        }
        if (section.id === 'Performance') {
            return this.isLoadingPerformance;
        }
        if (section.id === 'Schedule') {
            return this.isLoadingSchedule;
        }
        if (section.id === 'Accruals') {
            return this.isLoadingAccruals;
        }
        if (section.id === 'BenefitsManagement') {
            return this.isLoadingBenefitsManagement;
        }
        return false;
    };
    EmployeeSectionManagementService.prototype.getSectionById = function (id) {
        if (!this.sectionsMap) {
            return null;
        }
        return this.sectionsMap[id];
    };
    EmployeeSectionManagementService.prototype.getSubSectionById = function (id) {
        if (!this.subsectionsMap) {
            return null;
        }
        return this.subsectionsMap[id];
    };
    EmployeeSectionManagementService.prototype.getSectionBySubsectionId = function (id) {
        if (!this.sectionsTosubsectionsMap) {
            return null;
        }
        return this.sectionsTosubsectionsMap[id];
    };
    EmployeeSectionManagementService.prototype.getDefaultSection = function () {
        return this.getSectionBySubsectionId('Profile');
    };
    EmployeeSectionManagementService.prototype.getDefaultSubSection = function () {
        return this.getSubSectionById('Profile');
    };
    EmployeeSectionManagementService.prototype.getEmployeeName = function () {
        return this.empName;
    };
    EmployeeSectionManagementService.prototype.createSectionsMapping = function (sections) {
        var _this = this;
        this.subsectionsMap = {};
        this.sectionsMap = {};
        this.sectionsTosubsectionsMap = {};
        _.forEach(sections, function (section) {
            _this.sectionsMap[section.id] = section;
            _.forEach(section.subsections, function (subsection) {
                _this.subsectionsMap[subsection.id] = subsection;
                _this.sectionsTosubsectionsMap[subsection.id] = section;
            });
        });
    };
    EmployeeSectionManagementService.prototype.setEmployeeBannerInfo = function (profile) {
        this.employeeBannerInfo = new EmployeeBannerInfo();
        this.employeeBannerInfo.empoyeeId = this.employeeSectionsContext.employeeId;
        this.employeeBannerInfo.firstName = profile.firstName.fieldValue;
        this.employeeBannerInfo.lastName = profile.lastName.fieldValue;
        this.employeeBannerInfo.middleName = profile.middleName.fieldValue;
        this.employeeBannerInfo.fullName = profile.fullName.fieldValue;
        this.employeeBannerInfo.suffix = profile.suffix.fieldValue;
        this.employeeBannerInfo.employeeType = profile.employeeType.fieldValue;
        this.employeeBannerInfo.position = profile.position.fieldValue;
        this.employeeBannerInfo.status = profile.status;
        this.employeeBannerInfo.isTerminated = profile.isTerminated;
        this.employeeBannerInfo.isTransferred = profile.isTransferred;
        this.employeeBannerInfo.isFutureRehire = profile.isFutureRehire;
        this.employeeBannerInfo.isTransferPending = profile.isTransferPending;
        this.employeeBannerInfo.isLatestTerminate = profile.isLatestTerminate;
        this.employeeBannerInfo.canTerminate = profile.canTerminate;
        this.employeeBannerInfo.canCancelTerminate = profile.canCancelTerminate;
        this.employeeBannerInfo.canTransfer = profile.canTransfer;
        this.employeeBannerInfo.canRehire = profile.canRehire;
        this.employeeBannerInfo.isRehired = profile.isRehired;
        this.employeeBannerInfo.rehireDate = profile.rehireDate;
        this.employeeBannerInfo.createdThroughTransfer = profile.createdThroughTransfer;
        this.employeeBannerInfo.isPayrollVisible = profile.isPayrollVisible;
        if (this.employeeShortInfo) {
            this.employeeBannerInfo.isAgency = this.employeeShortInfo.isAgency;
            this.employeeBannerInfo.payrollNumber = this.employeeShortInfo.payrollNumber;
            //this.employeeBannerInfo.bannerInfoLicense = this.employeeShortInfo.bannerInfoLicense;
        }
    };
    EmployeeSectionManagementService.prototype.loadSection = function (section) {
        var _this = this;
        Assert.isNotNull(section, 'section');
        var promise = {};
        promise.loadingState = new Promise(function (res, rej) { return (promise.resolve = res, promise.reject = rej); });
        var loaded = this.loadedSectionsMap[section.id];
        if (!loaded) {
            this.loadedSectionsMap[section.id] = section;
            switch (section.id) {
                case 'Personal':
                    this.isLoadingPersonal = true;
                    this.employeeSectionBridgeService.getPersonalSection(this.employeeSectionsContext.employeeId)
                        .then(function (personalSection) {
                        _this.personalSection = personalSection;
                        _this.isLoadingPersonal = false;
                        if (personalSection.profile) {
                            _this.empName = personalSection.profile.fullName.fieldValue;
                            _this.setEmployeeBannerInfo(personalSection.profile);
                            return Promise.resolve(_this.employeeBannerInfo);
                        }
                        return Promise.resolve(_this.employeeBannerInfo);
                    })
                        .then(function () { return promise.resolve(true); })
                        .catch(function (reason) {
                        _this.isLoadingPersonal = false;
                        promise.reject(reason);
                        throw new Error(reason);
                    });
                    break;
                case 'Employment':
                    this.isLoadingEmployment = true;
                    this.employeeSectionBridgeService.getEmploymentSection(this.employeeSectionsContext.employeeId)
                        .then(function (employmentSection) {
                        _this.employmentSection = employmentSection;
                        _this.isLoadingEmployment = false;
                    })
                        .then(function () { return promise.resolve(true); })
                        .catch(function (reason) {
                        _this.isLoadingEmployment = false;
                        promise.reject(reason);
                        throw new Error(reason);
                    });
                    break;
                case 'Custom':
                    this.isLoadingCustom = true;
                    this.employeeSectionBridgeService.getCustomSection(this.employeeSectionsContext.employeeId)
                        .then(function (customSection) {
                        _this.customSection = customSection;
                        _this.isLoadingCustom = false;
                    })
                        .then(function () { return promise.resolve(true); })
                        .catch(function (reason) {
                        _this.isLoadingCustom = false;
                        promise.reject(reason);
                        throw new Error(reason);
                    });
                    break;
                case 'Audit':
                    this.isLoadingAudit = true;
                    this.employeeSectionBridgeService.getAuditSection(this.employeeSectionsContext.employeeId)
                        .then(function (auditSection) {
                        _this.auditSection = auditSection;
                        _this.isLoadingAudit = false;
                    })
                        .then(function () { return promise.resolve(true); })
                        .catch(function (reason) {
                        _this.isLoadingAudit = false;
                        promise.reject(reason);
                        throw new Error(reason);
                    });
                    break;
                case 'Performance':
                    this.isLoadingPerformance = true;
                    this.attendancePointsStartDate = moment().subtract(1, 'year').startOf('day').toDate();
                    this.attendancePointsEndDate = moment().endOf('day').toDate();
                    this.attendanceStartDate = moment().startOf('year').toDate();
                    this.attendanceEndDate = moment().endOf('day').toDate();
                    //let attendancePointsDaysCount: number = this.dateTimeService.GetDiffDays(this.attendanceStartDate, this.attendanceEndDate);
                    this.employeeSectionBridgeService.getPerformanceSection(this.employeeSectionsContext.employeeId, this.attendancePointsStartDate, this.attendancePointsEndDate, this.attendanceStartDate, this.attendanceEndDate)
                        .then(function (performanceSection) {
                        _this.performanceSection = performanceSection;
                        _this.isLoadingPerformance = false;
                    })
                        .then(function () { return promise.resolve(true); })
                        .catch(function (reason) {
                        _this.isLoadingPerformance = false;
                        promise.reject(reason);
                        throw new Error(reason);
                    });
                    break;
                case 'Schedule':
                    this.isLoadingSchedule = true;
                    this.employeeSectionBridgeService.getScheduleSection(this.employeeSectionsContext.employeeId)
                        .then(function (scheduleSection) {
                        _this.scheduleSection = scheduleSection;
                        _this.isLoadingSchedule = false;
                    })
                        .then(function () { return promise.resolve(true); })
                        .catch(function (reason) {
                        _this.isLoadingSchedule = false;
                        promise.reject(reason);
                        throw new Error(reason);
                    });
                    break;
                case 'BenefitsManagement':
                    this.isLoadingBenefitsManagement = true;
                    this.effectiveDate = moment().toDate();
                    this.employeeSectionBridgeService.getBenefitsManagementSection(this.employeeSectionsContext.employeeId, this.effectiveDate)
                        .then(function (benefitsSection) {
                        _this.benefitManagementSection = benefitsSection;
                        _this.isLoadingBenefitsManagement = false;
                    })
                        .then(function () { return promise.resolve(true); })
                        .catch(function (reason) {
                        _this.isLoadingBenefitsManagement = false;
                        promise.reject(reason);
                        throw new Error(reason);
                    });
                    break;
                case 'Accruals':
                    this.isLoadingAccruals = true;
                    var accrualsEndDate = moment().endOf('day');
                    var accrualsStartDate = moment().endOf('day');
                    this.accrualsEndDate = accrualsEndDate.add(10, 'day').startOf('day').toDate();
                    this.accrualsStartDate = accrualsStartDate.subtract(90, 'day').startOf('day').toDate();
                    this.employeeSectionBridgeService.getAccrualsSection(this.employeeSectionsContext.employeeId, this.accrualsStartDate, this.accrualsEndDate)
                        .then(function (accrualsSection) {
                        _this.accrualsSection = accrualsSection;
                        _this.isLoadingAccruals = false;
                    })
                        .then(function () { return promise.resolve(true); })
                        .catch(function (reason) {
                        _this.isLoadingAccruals = false;
                        promise.reject(reason);
                        throw new Error(reason);
                    });
                    break;
                default:
            }
        }
        else {
            promise.resolve(false);
        }
        return promise.loadingState;
    };
    EmployeeSectionManagementService.prototype.loadWorkplace = function () {
        return this.employeeSectionApiService.getWorkplace();
    };
    return EmployeeSectionManagementService;
}());
export { EmployeeSectionManagementService };
