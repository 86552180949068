
export * from './employee-sections-attendance/employee-sections-attendance.component';
export * from './employee-sections-attendance-points/employee-sections-attendance-points.component';
export * from './employee-sections-attendance-buybacks/employee-sections-attendance-buybacks.component';
export * from './employee-sections-attendance-points-definition/employee-sections-attendance-points-definition.component';
export * from './employee-sections-attendance/employee-sections-attendance-stats-grid/employee-sections-attendance-stats-grid.component';
export * from './employee-sections-attendance/employee-sections-attendance-day/employee-sections-attendance-day.component';
export * from './employee-sections-attendance/employee-sections-attendance-absences-grid/employee-sections-attendance-absences-grid.component';

import { EmployeeSectionsAttendanceComponent } from './employee-sections-attendance/employee-sections-attendance.component';
import { EmployeeSectionsAttendancePointsComponent } from './employee-sections-attendance-points/employee-sections-attendance-points.component';
import { EmployeeSectionsAttendancePointsDefinitionComponent } from './employee-sections-attendance-points-definition/employee-sections-attendance-points-definition.component';
import { EmployeeSectionsAttendanceStatsGridComponent } from './employee-sections-attendance/employee-sections-attendance-stats-grid/employee-sections-attendance-stats-grid.component';
import { EmployeeSectionsAttendanceDayComponent } from './employee-sections-attendance/employee-sections-attendance-day/employee-sections-attendance-day.component';
import { EmployeeSectionsAttendanceAbsencesGridComponent } from './employee-sections-attendance/employee-sections-attendance-absences-grid/employee-sections-attendance-absences-grid.component';
import { EmployeeSectionsAttendanceBuybacksComponent } from './employee-sections-attendance-buybacks/employee-sections-attendance-buybacks.component';

export const componentsPerformanceSection: any[] = [
  EmployeeSectionsAttendancePointsComponent,
  EmployeeSectionsAttendancePointsDefinitionComponent,
  EmployeeSectionsAttendanceComponent,
  EmployeeSectionsAttendanceStatsGridComponent,
  EmployeeSectionsAttendanceDayComponent,
  EmployeeSectionsAttendanceAbsencesGridComponent,
  EmployeeSectionsAttendanceBuybacksComponent
];
