import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { PayCycle } from '../../../organization/models/lookup/index';
import { PayCycleHelperService, ApplicationStateBusService } from '../../../organization/services/index';
import { appConfig } from '../../../app.config';
import { RangeDates } from '../../../common/models/range-dates';
import { BaseResolver } from '../../../common/index';
import { ExceptionConsoleRouteData } from '../../../time-and-attendance/models/exception-console/exception-console-route-data';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
var ExceptionConsoleResolver = /** @class */ (function (_super) {
    tslib_1.__extends(ExceptionConsoleResolver, _super);
    function ExceptionConsoleResolver(router, payCycleHelper, busService) {
        var _this = _super.call(this, router) || this;
        _this.payCycleHelper = payCycleHelper;
        _this.busService = busService;
        return _this;
    }
    ExceptionConsoleResolver.prototype.resolve = function (route, state) {
        var _this = this;
        var usePayCycle = _.toString(route.queryParamMap.get('usePayCycle')) === 'true';
        var range = this.getRangeDates(route);
        var routeData = new ExceptionConsoleRouteData();
        routeData.range = range;
        if (range.startDate && range.endDate) {
            if (!moment(range.endDate).isAfter(range.startDate)) {
                range.endDate = moment(range.startDate).add(1, 'day').toDate();
            }
        }
        else if (range.startDate) {
            range.endDate = moment(range.startDate).add(29, 'day').toDate();
        }
        else if (range.endDate) {
            range.startDate = moment(range.endDate).subtract(29, 'day').toDate();
        }
        else {
            var today = moment().startOf('day').toDate();
            range.endDate = moment(today).subtract(1, 'day').toDate();
            range.startDate = moment(range.endDate).subtract(29, 'day').toDate();
            var params = this.createQueryParams(route, state);
            params.startDate = dateTimeUtils.convertToDtoString(range.startDate);
            params.endDate = dateTimeUtils.convertToDtoString(range.endDate);
            this.navigateToPath(route, params);
            return routeData;
        }
        if (usePayCycle && moment(range.startDate).isValid() && moment(range.endDate).isValid()) {
            routeData.usePayCycle = usePayCycle;
            routeData.payCycle = new PayCycle();
            routeData.payCycle.startDate = range.startDate;
            routeData.payCycle.endDate = range.endDate;
            return routeData;
        }
        if (!this.busService.isInitialized) {
            return routeData;
        }
        return this.getOrgLevel().then(function (orgLevel) {
            if (!usePayCycle) {
                return routeData;
            }
            return _this.payCycleHelper.getPayCycleByDate(range.startDate, orgLevel.id).then(function (payCycle) {
                if (payCycle) {
                    if (moment(payCycle.startDate).isBefore(range.endDate)) {
                        if (moment(range.endDate).diff(payCycle.startDate, 'day') > 29) {
                            range.startDate = moment(range.endDate).subtract(29, 'day').toDate();
                        }
                        else {
                            range.startDate = payCycle.startDate;
                        }
                    }
                    else {
                        range.startDate = moment(range.endDate).startOf('day').subtract(1, 'day').toDate();
                    }
                }
                routeData.range = range;
                routeData.payCycle = payCycle;
                routeData.usePayCycle = true;
                return routeData;
            });
        });
    };
    ExceptionConsoleResolver.prototype.getOrgLevel = function () {
        var promise = this.busService.orgLevelSelected$.first().toPromise();
        return promise;
    };
    ExceptionConsoleResolver.prototype.getRangeDates = function (route) {
        var startDateParam = _.toString(route.queryParamMap.get('startDate'));
        var endDateParam = _.toString(route.queryParamMap.get('endDate'));
        var startDate = startDateParam ? moment(startDateParam, appConfig.linkDateFormat).toDate() : null;
        var endDate = endDateParam ? moment(endDateParam, appConfig.linkDateFormat).toDate() : null;
        var range = new RangeDates();
        range.startDate = startDate;
        range.endDate = endDate;
        return range;
    };
    return ExceptionConsoleResolver;
}(BaseResolver));
export { ExceptionConsoleResolver };
