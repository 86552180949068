import { Component, OnInit, Provider, ViewChild } from '@angular/core';
import { DialogOptions, IDialog } from '../../../common/models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { OrgLevelFlat } from '../../models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { OrgLevelSearchComponent } from '../org-level-search/org-level-search.component';

@Component({
  moduleId: module.id,
  selector: 'slx-org-level-search-dialog',
  templateUrl: 'org-level-search-dialog.component.html',
  styleUrls: ['org-level-search-dialog.component.scss']
})
export class OrgLevelSearchDialogComponent implements IDialog {
  public options: DialogOptions;
  public dialogResult: boolean;
  public get selectedOrgLevel(): OrgLevelFlat {
    return this.orgLevelSearch.selectedOrgLevel;
  }

  public state: {
    isLoading: boolean;
  };
  private modalService: ModalService;
  private orgLevelId: number;
  @ViewChild('orgLevelSearch', {static: true})
  private orgLevelSearch: OrgLevelSearchComponent;

  public static openDialog(modalService: ModalService, callback: (result: boolean, selected: OrgLevel) => void): OrgLevelSearchDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 500;
    dialogOptions.width = 850;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];
    let dialog: OrgLevelSearchDialogComponent = modalService.globalAnchor
      .openDialog(OrgLevelSearchDialogComponent, 'Search Org Level', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.selectedOrgLevel? dialog.selectedOrgLevel.orgLevel: null);
      });
    return dialog;
  }


  constructor(options: DialogOptions,
    modalService: ModalService) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
