import { Component, OnDestroy, OnInit } from "@angular/core";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { destroyService, unsubscribe } from "../../../core/decorators";
import { ComponentStateStorageService, ModalService } from "../../../common";
import { NotificationsManagementService } from "../../services/notifications/notifications-management.service";
import { NotificationGroup } from "../../models";

@Component({
    moduleId: module.id,
    selector: 'slx-notifications',
    templateUrl: 'notifications.component.html',
    styleUrls: ['notifications.component.scss'],
    providers: [ComponentStateStorageService]
})
export class NotificationsComponent implements OnInit, OnDestroy {
   
  public state: {
    isLoading: boolean;
  };
  public selectedRole: NotificationGroup;
  public hasChanges: boolean;
  public manageModeHasChanges: boolean;
  public loadPage:boolean=false;
  public canSave: boolean = true;

  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription2: Subscription;
  @unsubscribe()
  private selectDefinitionSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private changesNotifySubscription: Subscription;
  @unsubscribe()
  private changesClearSubscription: Subscription;
  @unsubscribe()
  private managementModeChangesSubscription: Subscription;
  @unsubscribe()
  private managementModeChangesClearSubscription: Subscription;

  @destroyService()
  private managementService: NotificationsManagementService;
  
  private maxVisibleColumnsToSave: number = 5;

  isDataLoaded : boolean = false;
  constructor(managementService: NotificationsManagementService) {
    this.managementService = managementService;
    this.state = {
      isLoading: false
    };
  }

  public ngOnInit(): void {
    
    this.loadStatusSubscription2 = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.isDataLoaded = true;
      });
    this.selectDefinitionSubscription = this.managementService.selectRecord$
      .subscribe((group: NotificationGroup) => {
        this.selectedRole = group;
      });


    this.changesNotifySubscription = this.managementService.changeNotify$.subscribe((source: string) => {
      this.hasChanges = true;
    });

    this.changesClearSubscription = this.managementService.clearChanges$.subscribe(() => {
      this.hasChanges = false;
    });
    
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onAddDefinition(): void {
    this.loadPage=true;
    this.managementService.addCmd();
  }
 
}