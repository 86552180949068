var UserProfileWithOrgLevelDecorator = /** @class */ (function () {
    function UserProfileWithOrgLevelDecorator(userProfile) {
        this.m_orgLevels = [];
        this.userProfile = userProfile;
    }
    Object.defineProperty(UserProfileWithOrgLevelDecorator.prototype, "orgLevels", {
        get: function () {
            return this.m_orgLevels;
        },
        enumerable: true,
        configurable: true
    });
    UserProfileWithOrgLevelDecorator.prototype.addOrgLevel = function (user) {
        this.m_orgLevels.push(user);
    };
    return UserProfileWithOrgLevelDecorator;
}());
export { UserProfileWithOrgLevelDecorator };
