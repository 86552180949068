export class VacationPlanSettings {
  public reload: boolean;
  public year: number;
  public columns: IVacationPlanColumn[];
}

export interface IVacationPlanColumn {
  name: VacationPlanColumnDefinition;
  description: string;
  displayed: boolean;
  sortField: string;
}
export type VacationPlanColumnDefinition = 'position' | 'type' | 'dateOfHire' | 'unit' | 'shift' | 'group' | 'days' | 'hours';

