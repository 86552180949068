import { EmployeeSectionsEmploymentMapService } from '../../../employee/employee-sections/services/index';
import { ITimeclockAssignmentRestriction, TimeclockAssignmentRestriction } from '../../../employee/employee-sections/models/index';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Assert } from '../../../framework/index';
import { LookupMapService, EmployeeDefinitionsMapService } from '../../../organization/services/index';
import { dateTimeUtils } from '../../../common/utils/index';

import { EmployeeDefinition, TimeclockDefinition, TimeclockRestrictionDefinition } from '../../../organization/models/index';

import {
  ITimeclockAssignmentContainer, TimeclockAssignmentContainer,
  TimeclockAssignmentEmployee, ITimeclockAssignmentEmployee,
  TimeclockAssignment, ITimeclockAssignment, TimeclockRestriction, ITimeclockRestriction,
  ITimeclockUnAssignment
} from '../../models/index';

@Injectable()
export class TimeclockAssignmentMapService {
  private lookupMapService: LookupMapService;
  private employeeDefinitionsMapService: EmployeeDefinitionsMapService;
  constructor(lookupMapService: LookupMapService, employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    private employeeSectionMapService: EmployeeSectionsEmploymentMapService) {
    this.lookupMapService = lookupMapService;
    this.employeeDefinitionsMapService = employeeDefinitionsMapService;
  }

  public mapTimeclockAssignmentRestriction(dto: ITimeclockAssignmentRestriction): TimeclockAssignmentRestriction {
    return this.employeeSectionMapService.mapTimeclockAssignmentRestriction(dto);
  }

  public mapTimeclockAssignmentEmployee(dto: ITimeclockAssignmentEmployee): TimeclockAssignmentEmployee {
    Assert.isNotNull(dto, 'ITimeclockAssignmentEmployee');
    let data: TimeclockAssignmentEmployee = new TimeclockAssignmentEmployee();
    data.badge = dto.badge;
    data.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    data.organization = this.lookupMapService.mapOrganization(dto.organization);
    data.department = this.lookupMapService.mapDepartment(dto.department);
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.assignments = _.map(dto.assignments, (dtoAssignment: ITimeclockAssignmentRestriction) => this.mapTimeclockAssignmentRestriction(dtoAssignment));
    return data;
  }

  public mapTimeclockAssignmentContainer(dto: ITimeclockAssignmentContainer): TimeclockAssignmentContainer {
    Assert.isNotNull(dto, 'ITimeclockAssignmentContainer');
    let data: TimeclockAssignmentContainer = new TimeclockAssignmentContainer();
    data.employees = _.map(dto.employees, (empoyee: ITimeclockAssignmentEmployee) => this.mapTimeclockAssignmentEmployee(empoyee));
    return data;
  }

  public mapTimeclockAssignment(data: TimeclockAssignment[]): ITimeclockAssignment[] {
    Assert.isNotNull(data, 'TimeclockAssignment');
    return _.map(data, (assignment: TimeclockAssignment) => {
      const timeclockRestrictions: ITimeclockRestriction[] = _.map(assignment.timeclockRestrictions, (entry: TimeclockRestriction) => {
        const timeclockRestriction: ITimeclockRestriction = {
          timeclockId: entry.timeclock.id,
          restrictionId: entry.restriction.id
        };

        return timeclockRestriction;
      });

      const dto: ITimeclockAssignment = {
        employeeId: assignment.employee.id,
        timeclockRestrictions
      };

      return dto;
    });
  }

  public mapTimeclockUnAssignment(data: TimeclockAssignment[]): ITimeclockUnAssignment {
    Assert.isNotNull(data, 'TimeclockAssignment');
    const timeclockUnAssignment: ITimeclockUnAssignment = {
      employeeIds: [],
      timeclockIds: []
    };
    return _.reduce(data, (collector: ITimeclockUnAssignment, assignment: TimeclockAssignment) => {
      const timeclocks: number[] = _.map(assignment.timeclockRestrictions, (entry: TimeclockRestriction) => entry.timeclock.id);
      collector.employeeIds.push(assignment.employee.id);
      collector.timeclockIds.push(...timeclocks);

      return collector;
    }, timeclockUnAssignment);
  }
}

