import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
var ScheduleConsoleFiltersService = /** @class */ (function () {
    function ScheduleConsoleFiltersService() {
        this.currentConsoleFilters = [];
        this.onClearFilters = new Subject();
        this.onFiltersApplied = new Subject();
        this.onApplyOverviewFilters = new Subject();
        this.onApplyConsoleFilters = new Subject();
    }
    Object.defineProperty(ScheduleConsoleFiltersService.prototype, "isDisabledGrouping", {
        get: function () {
            return this.m_isDisabledGrouping;
        },
        set: function (value) {
            this.m_isDisabledGrouping = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleFiltersService.prototype, "isGroupedByUnit", {
        get: function () {
            return this.m_isGroupedByUnit;
        },
        set: function (value) {
            this.m_isGroupedByUnit = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleFiltersService.prototype, "isGroupedByShiftGroup", {
        get: function () {
            return this.m_isGroupedByShiftGroup;
        },
        set: function (value) {
            this.m_isGroupedByShiftGroup = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleFiltersService.prototype, "consoleData", {
        set: function (data) {
            this.m_scheduleConsoleData = data;
            if (this.currentConsoleFilters.length > 0) {
                this.consoleFilters();
            }
        },
        enumerable: true,
        configurable: true
    });
    ScheduleConsoleFiltersService.prototype.overviewFiltersChanged = function (unitFilter, shiftFilter) {
        this.applyFilters();
    };
    ScheduleConsoleFiltersService.prototype.consoleFiltersChanged = function (filters, isDirectCare) {
        this.isDirectCare = isDirectCare;
        if (filters) {
            this.currentConsoleFilters = filters;
        }
        else {
            this.currentConsoleFilters = [];
        }
        this.applyFilters();
    };
    ScheduleConsoleFiltersService.prototype.applyFilters = function () {
        if (!this.m_scheduleConsoleData)
            return;
        // reset all filters
        this.resetFilters();
        // apply overview filters first
        this.overviewFilters();
        // apply common console filters
        this.consoleFilters();
        this.onFiltersApplied.next();
    };
    ScheduleConsoleFiltersService.prototype.resetFilters = function (resetState) {
        if (resetState === void 0) { resetState = false; }
        // reset all hidden flags
        if (!this.m_scheduleConsoleData)
            return;
        _.forEach(this.m_scheduleConsoleData.groupByPosition, function (position) {
            position.hidden = resetState;
        });
        _.forEach(this.m_scheduleConsoleData.groupByUnit, function (unit) {
            _.forEach(unit.values, function (shift) {
                _.forEach(shift.values, function (position) {
                    position.hidden = resetState;
                });
                shift.hidden = resetState;
            });
            unit.hidden = resetState;
        });
        _.forEach(this.m_scheduleConsoleData.groupByShift, function (shift) {
            _.forEach(shift.values, function (unit) {
                _.forEach(unit.values, function (position) {
                    position.hidden = resetState;
                });
                unit.hidden = resetState;
            });
            shift.hidden = resetState;
        });
        this.onClearFilters.next();
    };
    ScheduleConsoleFiltersService.prototype.overviewFilters = function () {
        this.applyOverviewFilters(this.unitFilter, this.shiftFilter);
        this.onApplyOverviewFilters.next();
    };
    ScheduleConsoleFiltersService.prototype.consoleFilters = function () {
        this.applyConsoleFilters(this.currentConsoleFilters);
        this.onApplyConsoleFilters.next(this.currentConsoleFilters);
    };
    ScheduleConsoleFiltersService.prototype.applyOverviewFilters = function (unitFilter, shiftFilter) {
        var _this = this;
        if (!this.m_scheduleConsoleData)
            return;
        _.forEach(this.m_scheduleConsoleData.groupByPosition, function (entry) {
            _this.processEntry(entry, unitFilter, shiftFilter);
        });
    };
    ScheduleConsoleFiltersService.prototype.processEntry = function (entry, unitFilter, shiftFilter) {
        var hasGroup = false;
        var hasUnit = false;
        _.forEach(entry.values, function (c) {
            _.each(shiftFilter, function (shiftFilteritem) {
                if (c.shiftGroup.id === shiftFilteritem.id) {
                    hasGroup = true;
                }
            });
            _.each(unitFilter, function (unitFilteritem) {
                if (c.unit.id === unitFilteritem.id) {
                    hasUnit = true;
                }
            });
        });
        if (!(shiftFilter && shiftFilter.length > 0)) {
            hasGroup = true;
        }
        if (!(unitFilter && unitFilter.length > 0)) {
            hasUnit = true;
        }
        entry.hidden = !(hasUnit && hasGroup);
    };
    ScheduleConsoleFiltersService.prototype.applyConsoleFilters = function (filters) {
        if (!this.m_scheduleConsoleData)
            return;
        var hasFilter = filters.length > 0;
        if (!this.isDirectCare && !hasFilter)
            return;
        if (this.isDirectCare && !hasFilter) {
            this.resetFilters(true);
            return;
        }
        var filtersNames = _.map(filters, function (filter) { return filter.name; });
        _.forEach(this.m_scheduleConsoleData.groupByUnit, function (unit) {
            if (!unit.hidden) {
                var shownShifts_1 = [];
                _.forEach(unit.values, function (shift) {
                    if (!shift.hidden) {
                        var shownPositions_1 = [];
                        _.forEach(shift.values, function (position) {
                            if (!position.hidden) {
                                position.hidden = _.indexOf(filtersNames, position.name) === -1;
                                if (!position.hidden)
                                    shownPositions_1.push(true);
                            }
                        });
                        shift.hidden = shownPositions_1.length === 0;
                        if (!shift.hidden)
                            shownShifts_1.push(true);
                    }
                });
                unit.hidden = shownShifts_1.length === 0;
            }
        });
        _.forEach(this.m_scheduleConsoleData.groupByShift, function (shift) {
            if (!shift.hidden) {
                var shownUnits_1 = [];
                _.forEach(shift.values, function (unit) {
                    if (!unit.hidden) {
                        var shownPositions_2 = [];
                        _.forEach(unit.values, function (position) {
                            position.hidden = _.indexOf(filtersNames, position.name) === -1;
                            if (!position.hidden)
                                shownPositions_2.push(true);
                        });
                        unit.hidden = shownPositions_2.length === 0;
                        if (!unit.hidden)
                            shownUnits_1.push(true);
                    }
                });
                shift.hidden = shownUnits_1.length === 0;
            }
        });
        _.forEach(this.m_scheduleConsoleData.groupByPosition, function (entry) {
            if (!entry.hidden) {
                entry.hidden = _.indexOf(filtersNames, entry.name) === -1;
            }
        });
    };
    return ScheduleConsoleFiltersService;
}());
export { ScheduleConsoleFiltersService };
