<slx-toolbar class="ta-console-toolbar" [alignExpandButtonRight]="true">

  <ng-template slxToolbarSectionTemplate let-counts="counts" [width]="200">
    <div class="slx-toolbar-section slx-no-padd-l date-section" [ngClass]="{ 'slx-border': isAllSectionShown(counts) }">
      <slx-date-navigator className="slx-mobile-navigator"
        [selectedDate]="dateOn"
        [maxDate]="dateNow"
        [format]="dateFormat"
        (dateChanged)="onFilterDateChanged($event)"
      ></slx-date-navigator>
    </div>
  </ng-template>

  <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" [width]="600">
    <div class="slx-toolbar-section slx-no-padd-r issues-section flex-row" [ngClass]="{ 'slx-no-padd-r': isVisibleSection(sectionType) }">
      <div class="col col-label">
          <span>Open Payroll Issues:</span>
      </div>

      <div class="col col-punch">
        <button class="tool-bar-button" (click)="navigateToPunchMissing()">
          <i class="toolbar-button-icon fal fa-2x fa-calendar-times" aria-hidden="true" [ngClass]="{'accent': missingPunchesCount>0}"></i>
          <span class="items-count">{{missingPunchesCount}}</span>
          <span>Missing Punches</span>
        </button>
      </div>

      <div class="col col-exception">
        <button class="tool-bar-button" (click)="navigateToTimeException()">
          <i class="toolbar-button-icon fas fa-2x fa-exclamation-circle" aria-hidden="true" [ngClass]="{'accent': timecardExceptionCount>0}"></i>
          <span class="items-count">{{timecardExceptionCount}}</span>
          <span>Timecard Exception(s)</span>
        </button>
      </div>
    </div>
  </ng-template>

</slx-toolbar>
