import {
  NgModule,
  Component,
  forwardRef,
  ViewChild,
  AfterContentChecked
} from '@angular/core';
import { NgModel } from '@angular/forms';

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';
import * as moment from 'moment';

import { Assert } from '../../../../framework/assert/assert';
import { createValuAccessor } from '../../../utils/index';
import { CustomDomEvents } from '../../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { appConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-time-input-filter-wrapper',
  templateUrl: 'time-input-filter-wrapper.component.html',
  styleUrls: ['time-input-filter-wrapper.component.scss'],
  providers: [createValuAccessor(TimeInputFilterWrapperComponent)]
})
export class TimeInputFilterWrapperComponent implements ControlValueAccessor, AfterContentChecked {

  public value: string = '';

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private durationValue: number;
  @ViewChild('timeInput', {static: true})
  private timeInput: NgModel;
  private lastValue: string;

  public ngAfterContentChecked(): void {
    if (this.value === this.lastValue) {
      return;
    }
    this.lastValue = this.value;
    if (this.timeInput.valid) {
      this.durationValue = dateTimeUtils.getTimeTotalSeconds(this.value);
      if (this.durationValue !== null) {
        this.onChangeCallback(this.durationValue);
      }
    }
  }

  public writeValue(v?: any): void {
    if (this.durationValue === v) {
      return;
    }
    this.lastValue = this.value = (v || v === 0) ? dateTimeUtils.setTimeTotalSeconds(v, appConfig.timeFormat) : null;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = (value: any) => { Promise.resolve(null).then(() => fn(value)); };
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }
}
