import { Component } from '@angular/core';
import { TimecardsActions } from '../../../time-and-attendance/store/index';

@Component({
  moduleId: module.id,
  selector: 'slx-application',
  templateUrl: 'application.component.html',
  styleUrls: ['application.component.scss']
})
export class ApplicationComponent {
  private timecardsActions: TimecardsActions;
  constructor(timecardsActions: TimecardsActions) {
    //reset current payCycle everytime when we start application or change current
    //timecardsActions.timecardsDisplayClearPayCycle();
  }
}
