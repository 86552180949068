import * as moment from 'moment';
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../framework/index';
import { ShiftEligibleEmployee, ShiftReplaceCmd, ShiftReplacementSms } from '../../models/index';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ShiftReplacementManagementService {
  private selectedEmployees$: ReplaySubject<ShiftEligibleEmployee[]>;
  private checkedEmployees$: ReplaySubject<ShiftEligibleEmployee[]>;
  private smsModeChanged$: ReplaySubject<boolean>;
  private availabilityToSendSms$: Subject<ShiftReplacementSms>;
  private sendSms$: Subject<ShiftReplacementSms>;
  private isSmsMode: boolean;
  private message: string;
  private currentCheckedEmployees: ShiftEligibleEmployee[];
  private currentSelectedEmployees: ShiftEligibleEmployee[];
  public isModifyApprovedPayperiod$ = new BehaviorSubject<boolean>(false);
  public replaceEmployee = new ShiftReplaceCmd();
  public replaceEmployeeId :number;
  public replaceApprovedResult=[]

  public init(): void {
    this.selectedEmployees$ = new ReplaySubject<ShiftEligibleEmployee[]>(1);
    this.checkedEmployees$ = new ReplaySubject<ShiftEligibleEmployee[]>(1);
    this.smsModeChanged$ = new ReplaySubject<boolean>(1);
    this.availabilityToSendSms$ = new Subject<ShiftReplacementSms>();
    this.sendSms$ = new Subject<ShiftReplacementSms>();
    this.message = '';
    this.isSmsMode = false;
    this.currentCheckedEmployees = [];
    this.currentSelectedEmployees = [];
  }

  public destroy(): void {
    this.selectedEmployees$.complete();
    this.checkedEmployees$.complete();
    this.smsModeChanged$.complete();
    this.availabilityToSendSms$.complete();
    this.sendSms$.complete();

    this.currentCheckedEmployees = null;
    this.currentSelectedEmployees = null;
    this.isSmsMode = false;
    this.message = '';
  }

  public changeCheckedEmployees(checkedEmployees: ShiftEligibleEmployee[]): void {
    if (_.isArray(checkedEmployees)) {
      const hasChanged: boolean = !(_.get(this.currentCheckedEmployees, 'length') === _.get(checkedEmployees, 'length'));
      if (hasChanged) {
        this.currentCheckedEmployees = checkedEmployees.concat();
        this.checkedEmployees$.next(checkedEmployees.concat());
      }
    }
  }

  public subscribeToCheckedEmployees(callback: (checkedEmployees: ShiftEligibleEmployee[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.checkedEmployees$.subscribe(callback);
  }

  public changeSelectedEmployees(selectedEmployees: ShiftEligibleEmployee[], isMultipleSelectionMode: boolean): void {
    if (_.isArray(selectedEmployees) && isMultipleSelectionMode) {
      const hasChanged: boolean = !(_.get(this.currentSelectedEmployees, 'length') === _.get(selectedEmployees, 'length'));
      if (hasChanged) {
        this.currentSelectedEmployees = selectedEmployees.concat();
        this.selectedEmployees$.next(selectedEmployees.concat());
      }
      return;
    }
    if(!isMultipleSelectionMode) {
      this.currentSelectedEmployees = selectedEmployees;
      this.selectedEmployees$.next(selectedEmployees);
    }
  }

  public subscribeToSelectedEmployees(callback: (selectedEmployees: ShiftEligibleEmployee[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.selectedEmployees$.subscribe(callback);
  }

  public changeSmsMode(smsModeOn: boolean): void {
    if (this.isSmsMode !== smsModeOn) {
      this.isSmsMode = smsModeOn;
      this.smsModeChanged$.next(this.isSmsMode);
    }
  }

  public subscribeToChangeSmsMode(callback: (smsModeOn: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.smsModeChanged$.subscribe(callback);
  }

  public changeAvailabilityToSendSms(data: ShiftReplacementSms): void {
    this.availabilityToSendSms$.next(data);
  }

  public subscribeToChangeAvailabilityToSendSms(callback: (data: ShiftReplacementSms) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.availabilityToSendSms$.subscribe(callback);
  }

  public sendSms(smsData: ShiftReplacementSms): void {
    if (smsData.canSend) {
      this.sendSms$.next(smsData);
    }
  }

  public subscribeToSendSms(callback: (smsData: ShiftReplacementSms) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.sendSms$.subscribe(callback);
  }
}
