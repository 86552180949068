import { WeatherContainerComponent, WeatherIconComponent,
  WeatherCurrentTempComponent, WeatherLocationComponent,
  WeatherCurrentDetailsComponent } from './components/index';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {
  WeatherApiService
} from './services/api/weather.api.service';

import { CommonModule } from '@angular/common';
import { OpenWeatherMapApiService } from './services/api/open-weather-map/open-weather-map.api.service';
import { WeatherHelpersService } from './services/weather-helpers.service';
export * from './weather.interfaces';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [
    WeatherCurrentTempComponent,
    WeatherIconComponent,
    WeatherLocationComponent,
    WeatherCurrentDetailsComponent,
    WeatherContainerComponent
  ],
  exports: [WeatherContainerComponent],
  providers: [WeatherHelpersService, WeatherApiService, OpenWeatherMapApiService]
})
export class AngularWeatherWidgetModule {
}
