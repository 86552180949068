<button *ngIf="!fullyCustomStyle && !hidden"
  type="button"
  [ngClass]="getClassName()"
  [disabled]="isDisabled"
  (mouseup)="onMouseup($event)"
  [title]="getTitle()"
>
  <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
</button>

<div *ngIf="fullyCustomStyle && !hidden"
  [ngClass]="getClassName()"
  [class.disabled]="isDisabled"
  (mouseup)="onMouseup($event)"
>
  <ng-container *ngTemplateOutlet="contentTmpl"></ng-container>
</div>

<ng-template #contentTmpl>
  <ng-content></ng-content>
</ng-template>
