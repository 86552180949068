import * as _ from 'lodash';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { CostCentersConfigurationManagementService } from '../../../services/index';
import { IApplicationConfig, appConfig } from '../../../../app.config';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { OrgLevel } from '../../../../state-model/models/index';
import { CostCentersConfigurationContainer, CostCenterModel } from '../../../models/index';
import { ICostGridState } from '../../../models/cost-centers/cost-grid-state.interface';
import { NgForm } from '@angular/forms';

@Component({
    moduleId: module.id,
    selector: 'slx-cost-centers-mobile-view',
    templateUrl: 'cost-centers-mobile-view.component.html',
    styleUrls: ['cost-centers-mobile-view.component.scss']
})
export class CostCentersMobileViewComponent implements OnInit {

    public get container(): CostCentersConfigurationContainer {
        return this.m_container;
    }

    public state: {
        isLoading: boolean;
        bulkEditMode: boolean;
        canBulkEdit: boolean;
        canEdit: boolean;
        canAdd: boolean;
        canDelete: boolean;
    };

    public currentOrgLevel: OrgLevel;
    public appConfig: IApplicationConfig;
    public collapsedSections: StringMap<boolean>;

    @ViewChild('templateForm', { static: true })
    private mainForm: NgForm;

    @unsubscribe()
    private stateSubscription: Subscription;
    @unsubscribe()
    private managementLoadedSubscription: Subscription;
    @unsubscribe()
    private managementRefreshSubscription: Subscription;
    @unsubscribe()
    private managementAddSubscription: Subscription;
    @unsubscribe()
    private mainFormSubscription: Subscription;

    private m_container: CostCentersConfigurationContainer;
    constructor(public management: CostCentersConfigurationManagementService, private changeDetector: ChangeDetectorRef) { }

    public ngOnInit(): void {

        this.appConfig = appConfig;
        this.collapsedSections = {};

        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: true,
            canEdit: true,
            canAdd: true,
            canDelete: true
        };

        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
            if (this.mainForm.dirty) {
                this.management.markAsDirty();
            }
        });

        this.stateSubscription = this.management.onStateChanged$.subscribe((state: ICostGridState) => {
            this.state = state;
            if (state) {
                this.currentOrgLevel = this.management.currentOrgLevel;
            }
        });

        this.managementLoadedSubscription = this.management.onLoaded$.subscribe((container: CostCentersConfigurationContainer) => {
            this.m_container = container;
            this.refreshView();
            this.management.changeService.clearChanges();
            this.management.setSelectedCount(0);
        });

        this.managementRefreshSubscription = this.management.viewRefresh$.subscribe(() => {
            this.refreshView();
        });

        this.managementAddSubscription = this.management.addItemCmd$.subscribe((item: CostCenterModel) => {
            this.container.records.push(item);
            this.management.changeService.changeNotify();
        });

    }

    public getCurrentState(...indexes: number[]): boolean {
        const key: string = _.map(indexes, (index: number) => String(index)).join('');
        return _.isBoolean(this.collapsedSections[key]) ? this.collapsedSections[key] : (this.collapsedSections[key] = true);
    }

    public onChangedState(...indexes: number[]): void {
        const key: string = _.map(indexes, (index: number) => String(index)).join('');
        this.collapsedSections[key] = !this.collapsedSections[key];
    }

    public save(center: CostCenterModel): void {
        if (_.isNil(center.id)) {
            this.management.onAddItem({ dataItem: center, isNew: true });
        } else {
            this.management.onSaveItem({ dataItem: center, isNew: false });
        }
    }

    public cancel(center: CostCenterModel): void {
        if (_.isNil(center.id)) {
            this.container.records = _.without(this.container.records, center);
        } else {
            this.mainForm.form.markAsPristine();
            this.management.onCancelEditItem();
            this.management.reloadRecords();
        }
    }

    public remove(center: CostCenterModel): void {
        if (_.isNil(center.id)) {
            this.container.records = _.without(this.container.records, center);
        } else {
            this.management.onRemoveItem(center);
        }
    }

    protected refreshView(): void {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    }
}
