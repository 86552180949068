import { appConfig } from './../../../../app.config';
import { PayCycleHelperService } from './../../../../organization/services/pay-cycle-helper/pay-cycle-helper.service';
import * as _ from 'lodash';
import { Component, Input, EventEmitter, Output, ViewEncapsulation, OnInit } from '@angular/core';
import { StyledUserApplication } from '../../../../organization/models/index';
import { TaDashboardCounters, AlertConfig, IDashboardAppAlertConfig } from '../../../models/index';
import { DashboardTAAlertEnum } from '../../../../portal/models/index';
import * as moment from 'moment';
import { OrgLevel } from '../../../../state-model/models/index';
import { ApplicationDashboardManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-ta-dashboard',
  templateUrl: 'ta-dashboard.component.html',
})

export class TaDashboardComponent implements OnInit {
  @Input()
  public application: StyledUserApplication;

  @Input()
  public progressbar: boolean;

  @Output()
  public onLoading = new EventEmitter<boolean>();

  public links: StringMap<string>;

  @Input()
  public set orgLevel(value: OrgLevel) {
    if (!value) return;
    this.calcDatesParams(value.id);
  }

  @unsubscribe()
  private countersSubscription: Subscription;

  public datesParams: any;
  public startDate: Date;
  public endDate: Date;
  public orgLevelId: number;
  public alertConfigOrderAndVisibilty: AlertConfig[];
  private counters: TaDashboardCounters;

  constructor(private payCycleHelperService: PayCycleHelperService,
    private appDashboardMngtService: ApplicationDashboardManagementService) {
    this.links = {
      missingPunch: 'daily_punches',
      overtimeApproval: 'overtime_approvals',
      timecardException: 'exception_console'
    };

    this.onLoading.emit(true);
  }

  public ngOnInit() {
    this.countersSubscription = this.appDashboardMngtService
    .subscribeToCountersLoaded((counters: StringMap<TaDashboardCounters>) => {
      this.mapCounters(counters['Time']);
      this.setCounter();
    });
  }

  public mapCounters(counters: TaDashboardCounters) {
    this.counters = _.isObject(counters) && _.size(counters) > 0 ? counters : new TaDashboardCounters();
    this.loadAlerts();
  }

  public loadAlerts(): void {
    this.appDashboardMngtService.getDashboardAppAlerts(this.application.id)
      .then(data => {
        this.alertConfigOrderAndVisibilty = _.filter(data, (t) => t.visible);
        this.setCounter();
        this.onLoading.emit(false);
      });
  }

  public onHasChanges(hasChanges: boolean): void {
    if (hasChanges) {
      this.loadAlerts();
    }
  }

  public getRouterLink(path: string): string[] {
    return [this.application.link, path];
  }

  public async calcDatesParams(orgLevelId: number): Promise<void> {
    let dateRange: any = await this.payCycleHelperService.getEffectivePunchesDateRange(new Date(), orgLevelId);
    this.datesParams = {
      startDate: moment(dateRange.startDate).format(appConfig.linkDateFormat),
      endDate: moment(dateRange.endDate).format(appConfig.linkDateFormat)
    };
    this.orgLevelId = orgLevelId;
    this.startDate = moment().subtract(14,'days').toDate();
    this.endDate = dateRange.endDate;
  }

  public setCounter() {
    let appAlerts = this.alertConfigOrderAndVisibilty;
    _.forEach(appAlerts, (record: AlertConfig) => {
      if (record.alertId == DashboardTAAlertEnum.missingPunchCount) {
        record.counter = this.counters.missingPunch;
      }
      else if (record.alertId == DashboardTAAlertEnum.overtimeApprovals) {
        record.counter = this.counters.overtimeApproval;
      }
      else if (record.alertId == DashboardTAAlertEnum.timecardExceptions) {
        record.counter = this.counters.timecardException;
      }
    });
  }

}
