import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Provider } from '@angular/core';
import { DialogOptions } from '../../../common/models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { AccrualBalanceDialogOptions } from '../../../organization/models/index';
import { AppSettingsManageService } from './../../../app-settings/services/index';
var AccrualBalanceDialogComponent = /** @class */ (function () {
    function AccrualBalanceDialogComponent(employeeAccruals, options, modalService, appSettingsManageService) {
        this.appSettingsManageService = appSettingsManageService;
        this.employeeAccruals = employeeAccruals;
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
    }
    AccrualBalanceDialogComponent.openDialog = function (data, modalService) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 400;
        dialogOptions.width = 400;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: AccrualBalanceDialogOptions,
                useValue: data
            }
        ];
        var dialog = modalService.globalAnchor.openDialog(AccrualBalanceDialogComponent, 'Accrual balance', dialogOptions, resolvedProviders);
        return dialog;
    };
    AccrualBalanceDialogComponent.prototype.ngOnInit = function () {
        this.getSettings();
    };
    Object.defineProperty(AccrualBalanceDialogComponent.prototype, "hasAccruals", {
        get: function () {
            return _.isArray(this.employeeAccruals.accruals) && _.size(this.employeeAccruals.accruals) > 0;
        },
        enumerable: true,
        configurable: true
    });
    AccrualBalanceDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    AccrualBalanceDialogComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.EnableRate4DecimalPlaces = config.EnableRate4DecimalPlaces;
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(AccrualBalanceDialogComponent.prototype, "isShowHighPrecision", {
        get: function () {
            return this.EnableRate4DecimalPlaces;
        },
        enumerable: true,
        configurable: true
    });
    return AccrualBalanceDialogComponent;
}());
export { AccrualBalanceDialogComponent };
