<div class="geo-punch-popup">
  <div class="modal-content">
    <div class="modal-body">
      <p *ngIf="geoPunchDetails.isInvalidPunch" class="punch-info">Punch is invalid</p>
      <slx-static-map
        [latitude]="geoPunchDetails.latitude"
        [longitude]="geoPunchDetails.longitude"
        [mapWidth]="geoPunchDetails.mapWidth"
        [mapHeight]="geoPunchDetails.mapHeight"
        [mapZoom]="geoPunchDetails.mapZoom"
      ></slx-static-map>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel">Close</button>
      </div>
  </div>
</div>
