import * as moment from 'moment';
var AcaMeasurementRecord = /** @class */ (function () {
    function AcaMeasurementRecord() {
        this.expand = false;
    }
    return AcaMeasurementRecord;
}());
export { AcaMeasurementRecord };
var ACAPeriodType = /** @class */ (function () {
    function ACAPeriodType() {
    }
    return ACAPeriodType;
}());
export { ACAPeriodType };
var ACAPeriod = /** @class */ (function () {
    function ACAPeriod() {
    }
    Object.defineProperty(ACAPeriod.prototype, "widthPercentage", {
        get: function () {
            var startDate = moment(this.startFrom).format('YYYY-MM-DD');
            var endDate = moment(this.endTo).format('YYYY-MM-DD');
            if (this.startFrom && this.endTo) {
                if (moment(this.startFrom).toDate().getFullYear() < this.currentSelectedYear) {
                    startDate = (this.currentSelectedYear + '-01-01');
                }
                if (moment(this.endTo).toDate().getFullYear() > this.currentSelectedYear) {
                    endDate = (this.currentSelectedYear + '-12-31');
                }
            }
            var daysInYear = moment(this.currentSelectedYear + "-02", "YYYY-MM").daysInMonth() == 29 ? 366 : 365;
            var width = this.startFrom && this.endTo ? (100 / daysInYear) * (moment(endDate).diff(startDate, 'days', true) + (1)) : 0;
            return width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ACAPeriod.prototype, "mspWidthPercentage", {
        get: function () {
            return this.totalAvgHours && this.weeklyAvgHours && this.empAvgHours ? (Math.round((100 / this.totalAvgHours) * this.empAvgHours)) : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ACAPeriod.prototype, "colorCode", {
        get: function () {
            return this.periodSortName ? (this.periodSortName == 'MP' ? '#0092CB' : (this.periodSortName == 'SP' ? '#8FAD15' : '#F68D2E')) : 'transparent';
        },
        enumerable: true,
        configurable: true
    });
    return ACAPeriod;
}());
export { ACAPeriod };
var AcaMeasurement = /** @class */ (function () {
    function AcaMeasurement() {
    }
    return AcaMeasurement;
}());
export { AcaMeasurement };
var PeriodsPopupModel = /** @class */ (function () {
    function PeriodsPopupModel() {
    }
    return PeriodsPopupModel;
}());
export { PeriodsPopupModel };
var filterAcaMeasurementRecord = /** @class */ (function () {
    function filterAcaMeasurementRecord() {
    }
    return filterAcaMeasurementRecord;
}());
export { filterAcaMeasurementRecord };
var AcaMeasurementRecordExport = /** @class */ (function () {
    function AcaMeasurementRecordExport() {
    }
    return AcaMeasurementRecordExport;
}());
export { AcaMeasurementRecordExport };
