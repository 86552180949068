<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents slx-auto-height">
      <slx-timeclock-assignment-header></slx-timeclock-assignment-header>
    </div>
    <div class="slx-high-box__body slx-main-content-indents slx-auto-height">
      <slx-timeclock-assignment-grid></slx-timeclock-assignment-grid>
    </div>
  </div>
</slx-spinner>
