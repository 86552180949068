export * from './manage-position-groups-dialog/manage-position-groups-dialog.component';
export * from './manage-position-groups/manage-position-groups.component';

import { ManagePositionGroupsDialogComponent } from './manage-position-groups-dialog/manage-position-groups-dialog.component';
import { ManagePositionGroupsComponent } from './manage-position-groups/manage-position-groups.component';

export const manageGroupsComponents: any[] = [
  ManagePositionGroupsDialogComponent,
  ManagePositionGroupsComponent
];
