import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';

import { ModalService } from '../../../common/services/index';

import { IDateRange } from '../../../core/models/index';
import { BenefitDependentDroppedEnrollment } from '../../../app-modules/benefits/models/index';
import { EmployeeDependentDropEnrollmentDialogComponent } from '../components/employee-sections-benefits-management/employee-dependent-drop-enrollment-dialog/employee-dependent-drop-enrollment-dialog.component';

@Directive({
  selector: '[slxEmployeeDependentDropEnrollmentModal]',
})
export class EmployeeDependentDropEnrollmentModalDirective {
  constructor(private modalService: ModalService) {}

  @Input()
  public slxEmployeeDependentDropEnrollmentModal: IDateRange;
  @Output()
  public dropEnrollment = new EventEmitter<BenefitDependentDroppedEnrollment>();

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    const dialog = EmployeeDependentDropEnrollmentDialogComponent.openDialog(
      this.slxEmployeeDependentDropEnrollmentModal,
      this.modalService,
      (result: boolean) => {
        if (result && dialog.canDrop) {
          this.dropEnrollment.emit(dialog.dropEnrollment);
        }
      }
    );
  }
}
