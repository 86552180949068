import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../app.config';

import {
    GridComponent,
    GridDataResult,
    DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';

import { NgForm } from '@angular/forms';

import { KendoGridStateHelper } from '../../../../common/models/index';
import { Organization } from '../../../../organization/models/index';

import { AgencyMapping, AgencyModel } from '../../../models/index';
import { AgenciesMapService } from '../../../services/index';

@Component({
    moduleId: module.id,
    selector: 'slx-agency-mappings',
    templateUrl: 'agency-mappings.component.html',
    styleUrls: ['agency-mappings.component.scss']
})
export class AgencyMappingsComponent implements OnInit {

    @Input()
    public orglevelId: number;

    @Input()
    public isOrganization: boolean;

    @Input()
    public set agencyMappings(value: AgencyMapping[]) {
        this.m_agencyMappings = value;
        this.closeEditor();
        this.cancelRemoveRequest();
        this.refreshGrid();
    }

    @Input()
    public organizations: Organization[];

    @Output()
    public onSaveAgency: EventEmitter<AgencyMapping>;
    @Output()
    public onRemoveAgency: EventEmitter<AgencyMapping>;

    public appConfig: IApplicationConfig;

    public editMode: boolean;

    public addNewMode: boolean;

    public editedRecord: AgencyMapping;

    public editedRowIndex: number;

    public prohibitedNameValues: string[] = [];

    public gridState: KendoGridStateHelper<AgencyMapping>;

    @ViewChild('form', {static: true})
    public form: NgForm;

    @ViewChild('kendoGrid', {static: true})
    private grid: GridComponent;

    private m_agencyMappings: AgencyMapping[];

    private mappingService: AgenciesMapService;

    private removingItem: AgencyMapping;

    constructor(mappingService: AgenciesMapService) {
        this.appConfig = appConfig;
        this.mappingService = mappingService;
        this.gridState = new KendoGridStateHelper<AgencyMapping>();
        this.onSaveAgency = new EventEmitter<AgencyMapping>();
        this.onRemoveAgency = new EventEmitter<AgencyMapping>();
    }

    public ngOnInit(): void {
        this.refreshGrid();
    }

    public toggleEditMode(): void {
        this.closeEditor();
        this.editMode = !this.editMode;
    }

    public addNewAgency(): void {
        this.addHandler();
    }

    public addHandler(): void {

        this.closeEditor();

        let record: AgencyMapping = new AgencyMapping();
        let agency: AgencyModel = new AgencyModel();
        agency.lastUpdateDate = new Date();
        record.agency = agency;
        record.organizations = [];
        if (this.isOrganization && this.orglevelId !== undefined && _.size(this.organizations) > 0) {
          const selectedOrganization = _.find(this.organizations, o => o.orgLevelId === this.orglevelId);
          if (_.isObjectLike(selectedOrganization)) {
            record.organizations = [selectedOrganization];
          }
        }
        this.addNewMode = true;
        this.editedRecord = record;
        this.grid.addRow(this.editedRecord);
        this.updateProhibitedNameValues(record);
    }


    public editHandler(sender: { dataItem: AgencyMapping, rowIndex: number }): void {
        this.closeEditor();
        this.editedRecord = this.mappingService.cloneAgencyMapping(sender.dataItem);
        this.editedRowIndex = sender.rowIndex;
        this.grid.editRow(this.editedRowIndex);
        this.updateProhibitedNameValues(sender.dataItem);
    }

    public updateProhibitedNameValues(mapping: AgencyMapping): void {
        if (mapping && this.m_agencyMappings) {
            let values: string[] = [];
            _.each(this.m_agencyMappings, (m: AgencyMapping) => {
                if (mapping.agency.name !== m.agency.name) {
                    values.push(m.agency.name);
                }
            });
            this.prohibitedNameValues = values;
        }
    }

    public closeEditor(): void {
        this.grid.closeRow(this.editedRowIndex);
        this.addNewMode = false;
        this.editedRowIndex = undefined;
        this.editedRecord = undefined;
    }

    public cancelHandler(): void {
        this.closeEditor();
        this.refreshGrid();
    }

    public removeHandler(event: { sender: any, rowIndex: number, dataItem: any, isNew: boolean }): void {
        if (!event.dataItem) {
            return;
        }
        this.editMode = false;
        this.removingItem = event.dataItem;
        this.onRemoveAgency.emit(event.dataItem);
    }

    public saveHandler(event: { sender: any, rowIndex: number, dataItem: any, toggleEditModeisNew: boolean }): void {
        this.onSaveAgency.emit(this.editedRecord);
    }

    public completeRemoveRequest(): void {
        _.remove(this.m_agencyMappings, (r: AgencyMapping) => {
            return r.uniqId === this.removingItem.uniqId;
        });
        this.refreshGrid();
        this.removingItem = null;
        this.editMode = true;
    }

    public cancelRemoveRequest(): void {
        this.removingItem = null;
        this.refreshGrid();
    }

    public completeSaveRequest(mapping: AgencyMapping): void {
        if (this.addNewMode) {
            if (mapping && mapping.agency) {
                this.editedRecord.agency.id = mapping.agency.id;
                this.editedRecord.agency.lastUpdateDate = mapping.agency.lastUpdateDate;
                this.editedRecord.agency.lastUpdateName = mapping.agency.lastUpdateName;
            }
            if (this.m_agencyMappings.length > 0) {
                this.m_agencyMappings.push(this.editedRecord);
                this.editedRecord.uniqId = _.maxBy(this.m_agencyMappings, a => a.uniqId).uniqId + 1;
            } else {
                this.m_agencyMappings.push(this.editedRecord);
                this.editedRecord.uniqId = 0;
            }
        } else {
            if (this.editedRecord) {

                let editedItem: AgencyMapping = _.find(this.m_agencyMappings, (r: AgencyMapping) => {
                    return r.uniqId === this.editedRecord.uniqId;
                });
                this.mappingService.updateAgencyMapping(mapping, editedItem);
                editedItem.uniqId = this.editedRecord.uniqId;
            }
        }
        this.closeEditor();
        this.refreshGrid();
        this.editMode = true;
    }

    private refreshGrid(): void {
        if (!this.m_agencyMappings) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_agencyMappings, this.gridState.state);
    }
}
