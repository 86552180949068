import { Injectable } from '@angular/core';
import { IAppState } from '../../../store';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class WizardActions {
  public static SELECT_NEXT_STEP: string = 'SELECT_NEXT_STEP';
  public static SELECT_PREV_STEP: string = 'SELECT_PREV_STEP';
  public static SET_STEP: string = 'SET_STEP';
  public static RESTORE_STEP: string = 'RESTORE_STEP';

  private ngRedux: NgRedux<IAppState>;
  constructor(ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }
  public nextStep(): void {
    this.ngRedux.dispatch({
      type: WizardActions.SELECT_NEXT_STEP,
    });
  }
  public prevStep(): void {
    this.ngRedux.dispatch({
      type: WizardActions.SELECT_PREV_STEP,
    });
  }
}
