import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { environment as envConfig } from '../../../../environments/environment';
import { AuthenticationService } from '../../../authentication/services/index';
import { DeviceDetectorService } from '../../services/index';
import { UserExtendedSettingsManagementService } from '../../../authentication/services/user-extended-settings/user-extended-settings-management.service';
var InitPendoComponent = /** @class */ (function () {
    function InitPendoComponent(authenticationService, deviceDetectorService, _userSettingsManagementService) {
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this._userSettingsManagementService = _userSettingsManagementService;
        this.pendoInit = false;
        this.zendeskInitialized = false;
        this.isLiveChatenabled = false;
    }
    InitPendoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authSubscription = this.authenticationService.login$.subscribe(function (event) {
            if (!event.isRenewAuthentication) {
                _this.initPendo(event.alias, event.username, event.email, event.roles, true);
            }
        });
    };
    InitPendoComponent.prototype.ngAfterViewInit = function () {
        if (!this.pendoInit) {
            var alias = this.authenticationService.getAlias();
            var user = this.authenticationService.getUser();
            if (this.authenticationService.isAuthenticated()) {
                this.initPendo(alias, user.username, user.email, user.roles, false);
            }
        }
    };
    InitPendoComponent.prototype.initPendo = function (alias, username, email, roles, onLogin) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pendo, correctAlias, correctEmail, correctRole, accountId, device, initData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pendo = _.get(window, 'pendo');
                        correctAlias = _.toUpper(_.size(alias) > 0 ? alias : 'default');
                        correctEmail = _.size(email) > 0 ? email : '';
                        correctRole = _.size(roles) > 0 ? roles.join(',') : '';
                        accountId = _.toUpper(username + "@" + correctAlias);
                        device = this.deviceDetectorService.isDesktop ? 'desktop' : 'mobile';
                        this.pendoInit = true;
                        initData = {
                            visitor: {
                                id: accountId,
                                email: correctEmail,
                                roles: correctRole,
                                device: device
                            },
                            account: {
                                id: correctAlias,
                                env: envConfig.ENV,
                                application: 'V6'
                            }
                        };
                        if (!(_.isObjectLike(pendo) && _.size(pendo) > 0)) return [3 /*break*/, 2];
                        if (_.isFunction(pendo.isReady) && pendo.isReady() && onLogin) {
                            console.log('Exec pendo.updateOptions(), after user logged in', initData);
                            pendo.updateOptions(initData);
                            return [2 /*return*/];
                        }
                        console.log("Exec pendo.initialize(), " + (onLogin ? 'after user logged in' : 'after user opened app (without logged in)'), initData);
                        pendo.initialize(initData);
                        return [4 /*yield*/, this._userSettingsManagementService.getLiveChatMenu()];
                    case 1:
                        _a.sent();
                        if (this._userSettingsManagementService.isLiveChatMenuenabled) {
                            if (envConfig.ZENDESK_APIKEY && !this.zendeskInitialized) {
                                setTimeout(function () {
                                    _this.zendeskInitialized = true;
                                    var y = document.createElement('script');
                                    y.id = 'ze-snippet';
                                    y.src = "https://static.zdassets.com/ekr/snippet.js?key=" + envConfig.ZENDESK_APIKEY;
                                    var z = document.getElementsByTagName('script')[0];
                                    z.parentNode.appendChild(y);
                                }, 100);
                            }
                        }
                        return [2 /*return*/];
                    case 2:
                        console.error("Pendo didn't load correctly. Pendo: ", pendo);
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], InitPendoComponent.prototype, "authSubscription", void 0);
    return InitPendoComponent;
}());
export { InitPendoComponent };
