import { OnInit, OnDestroy, EventEmitter, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Assert } from '../../../framework/index';
import * as moment from 'moment';
var TimePickerComponent = /** @class */ (function () {
    function TimePickerComponent(elementRef, ngControl) {
        this.placeholder = '';
        this.format = 'MM/dd/yyyy hh:mm tt';
        this.interval = 15;
        this.dateInput = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.ngControl = ngControl;
        ngControl.valueAccessor = this;
        this.datePickerEnabled = true;
        this.onDateChanged = new EventEmitter();
    }
    Object.defineProperty(TimePickerComponent.prototype, "readonly", {
        set: function (ro) {
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
            this.checkReadonly();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimePickerComponent.prototype, "minDate", {
        set: function (value) {
            this.minDateInt = value;
            this.minTimeCurrentDate = this.getTimeinCurrentDate(value);
            if (this.kendoTimePicker) {
                this.kendoTimePicker.min(this.minTimeCurrentDate);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimePickerComponent.prototype, "maxDate", {
        set: function (value) {
            this.maxDateInt = value;
            this.maxTimeCurrentDate = this.getTimeinCurrentDate(value);
            if (this.kendoTimePicker) {
                this.kendoTimePicker.max(this.maxTimeCurrentDate);
            }
        },
        enumerable: true,
        configurable: true
    });
    TimePickerComponent.prototype.ngOnInit = function () {
        var kendoTimePickerOptions = this.kendoTimePickerOptions();
        this.$element.kendoTimePicker(kendoTimePickerOptions);
        this.kendoTimePicker = this.$element.data('kendoTimePicker');
        this.checkReadonly();
        this.kendoTimePicker.min(this.minTimeCurrentDate);
        this.kendoTimePicker.max(this.maxTimeCurrentDate);
        //this.$element.on('input', () => { this.valueChange(this.$element.val()); });
    };
    TimePickerComponent.prototype.ngOnChanges = function (changes) {
        this.checkReadonly();
    };
    TimePickerComponent.prototype.ngOnDestroy = function () {
        if (this.kendoTimePicker) {
            this.kendoTimePicker.destroy();
        }
    };
    TimePickerComponent.prototype.writeValue = function (value) {
        var date = moment(value);
        var dateChanged = false;
        if (this.originalDate) {
            if (!date.isSame(this.originalDate)) {
                dateChanged = true;
            }
        }
        this.originalDate = value;
        if (value) {
            var timeInCurrentDate = this.getTimeinCurrentDate(value);
            this.lastValue = moment(timeInCurrentDate);
        }
        else {
            this.lastValue = null;
        }
        if (this.kendoTimePicker) {
            if (value === null || value === undefined) {
                this.kendoTimePicker.value(null);
                return;
            }
            this.kendoTimePicker.value(this.lastValue.toDate());
        }
    };
    TimePickerComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    TimePickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    TimePickerComponent.prototype.getTimeinCurrentDate = function (source) {
        var sourceMoment = moment(source);
        var currentMoment = moment();
        currentMoment.hours(sourceMoment.hours()).minutes(sourceMoment.minutes()).seconds(sourceMoment.seconds()).milliseconds(sourceMoment.milliseconds());
        return currentMoment.toDate();
    };
    TimePickerComponent.prototype.setTimeToMoment = function (source, target) {
        target.hours(source.hours()).minutes(source.minutes()).seconds(source.seconds()).milliseconds(source.milliseconds());
    };
    TimePickerComponent.prototype.checkReadonly = function () {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.datePickerEnabled = !(Boolean(myattr) || myattr === 'readonly');
        if (this.kendoTimePicker) {
            this.kendoTimePicker.readonly(!this.datePickerEnabled);
        }
    };
    TimePickerComponent.prototype.kendoTimePickerOptions = function () {
        var _this = this;
        var options = {
            format: this.format,
            interval: this.interval,
            dateInput: this.dateInput,
            change: function (e) {
                _this.valueChanged(e);
            }
        };
        if (this.minTimeCurrentDate) {
            options.min = this.minTimeCurrentDate;
        }
        if (this.maxTimeCurrentDate) {
            options.max = this.maxTimeCurrentDate;
        }
        if (this.lastValue) {
            options.value = this.lastValue.toDate();
            this.lastValue = undefined;
        }
        return options;
    };
    TimePickerComponent.prototype.valueChanged = function (e) {
        var value = this.kendoTimePicker.value();
        if (!value) {
            return;
        }
        var newTimeInCurrentMoment = moment(value);
        if (!this.lastValue || !this.lastValue.isSame(newTimeInCurrentMoment)) {
            // update time in original date
            var timeInOriginalMoment = moment(this.originalDate);
            this.setTimeToMoment(newTimeInCurrentMoment, timeInOriginalMoment);
            this.originalDate = timeInOriginalMoment.toDate();
            // send changes to model
            this.onChangeCallback(this.originalDate);
            this.onDateChanged.emit(this.originalDate);
        }
        this.lastValue = newTimeInCurrentMoment;
    };
    Object.defineProperty(TimePickerComponent.prototype, "$element", {
        get: function () {
            return $(this.elementRef.nativeElement);
        },
        enumerable: true,
        configurable: true
    });
    return TimePickerComponent;
}());
export { TimePickerComponent };
