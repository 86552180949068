<div class="detail-timeline-row" [ngClass]="{'expanded': expandedDetails, 'missing-punches': record.hasMissingPunches}">
  <div class="detail-timeline-column employee-column">
    <span class="employee-details-expander" (click)="toggleDetails()">
        <i class="fas" [ngClass]="{'fa-plus': !expandedDetails, 'fa-minus': expandedDetails}" aria-hidden="true"></i>
    </span>
    <a  class="employee-icon" [employeeSectionLink]="record.employee.id">
      <img class="img" [employeeThumbinalSrc]="record.employee.id" alt="Employee Photo" />
    </a>
    <div>
      <a [employeeSectionLink]="record.employee.id" class="column-title-text">
        {{record.employee.name}}
      </a>
      <span  class="column-regular-text text-block">{{record.positionStr}}</span>
      <span *ngIf="record.isLate" class="late-badge">Late</span>
      <span *ngIf="record.isApproachingOT" class="ot-badge">OT</span>
   </div>
  <div class="employee-menu">
      <span class="employee-menu-button" [ngClass]="{'expanded': expandedMenu}"
          [popper]="popperContent"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-start'"
          [popperDisableStyle]="'true'"
          (popperOnShown)="toggleMenu(true)"
          (popperOnHidden)="toggleMenu(false)"
          >
          <i class="fa fa-ellipsis-h" [ngClass]="{'fa-ellipsis-h': !expandedMenu, 'fa-ellipsis-v': expandedMenu}" aria-hidden="true"></i>
        </span>
        <popper-content #popperContent class="slx-popper slx-width-150">
            <button class="detail-timeline-button" [employeeSectionLink]="record.employee.id">
                <i class="fal fa-user-circle" aria-hidden="true"></i>
                Profile
            </button>
            <button class="detail-timeline-button" [dailyTimecardsLink]="record.employee.id" [timecardDate]="workDate">
                <i class="fal fa-id-card" aria-hidden="true"></i>
                Timecard
            </button>
            <button class="detail-timeline-button" scheduleEntryLink [employeeId]="record.employee.id" [dateOn]="workDate">
                <i class="fal fa-calendar-alt" aria-hidden="true"></i>
                Schedule
            </button>
        </popper-content>
  </div>
    </div>
  <div class="detail-timeline-column schedule-column">
    <i class="detail-timeline-column-icon fal fa-calendar-alt" aria-hidden="true"></i>
    <div>
      <div>
        <span class="column-title-text">Schedule: </span>
      </div>
      <div class="details-shift-name">
        <span class="column-regular-text bold">Shift:</span>
        <span class="column-regular-text">{{record.shiftsStr}}</span>
      </div>
      <div class="details-unit-name">
        <span class="column-regular-text details-unit-name-divider">&nbsp;/&nbsp;</span>
        <span class="column-regular-text bold">Unit:</span>
        <span class="column-regular-text">{{record.unitsStr}}</span>
      </div>
   </div>
  </div>
  <div class="detail-timeline-column punches-column">
    <i *ngIf="record.hasMissingPunches" class="detail-timeline-column-icon fas fa-exclamation-square" aria-hidden="true"></i>
    <i *ngIf="!record.hasMissingPunches" class="detail-timeline-column-icon fal fa-hand-paper" aria-hidden="true"></i>
    <div>
      <div>
        <span class="column-title-text">Punches: </span>
      </div>
      <div>
        <span class="column-regular-text bold">In:</span>
        <span class="column-regular-text">{{record.inTimeStr}}</span>
        <span class="column-regular-text">-</span>
        <span class="column-regular-text bold">Out:</span>
        <span class="column-regular-text">{{record.outTimeStr}}</span>
      </div>
      <div>
        <span class="column-regular-text bold">Lunch Out:</span>
        <span class="column-regular-text">{{record.lunchOutTimeStr}}</span>
        <span class="column-regular-text">-</span>
        <span class="column-regular-text bold">Lunch In:</span>
        <span class="column-regular-text">{{record.lunchInTimeStr}}</span>
      </div>
   </div>
  </div>
  <div class="detail-timeline-column contacts-column">
    <i class="detail-timeline-column-icon fal fa-phone" aria-hidden="true"></i>
    <div>
      <div>
        <span class="column-title-text">Contact Information: </span>
      </div>
      <div>
        <span class="column-regular-text bold">Phone:</span>
        <span class="column-regular-text" *ngIf="details.empPhoneMeta.Policy.isVisible">{{record.employee.phoneNumber}}</span>
      </div>
      <div>
        <span class="column-regular-text bold">Mobile:</span>
        <span class="column-regular-text" *ngIf="details.cellPhoneNoMeta.Policy.isVisible">{{record.employee.mobilePhoneNumber}}</span>
      </div>
   </div>
  </div>
  <div class="detail-timeline-column button-column">
    <button class="detail-timeline-button" [employeeSectionLink]="record.employee.id">
      <i class="fal fa-user-circle" aria-hidden="true"></i>
      Profile
    </button>
    <button class="detail-timeline-button" [dailyTimecardsLink]="record.employee.id" [timecardDate]="workDate">
      <i class="fal fa-id-card" aria-hidden="true"></i>
      Timecard
    </button>
    <button class="detail-timeline-button" scheduleEntryLink [employeeId]="record.employee.id" [dateOn]="workDate">
      <i class="fal fa-calendar-alt" aria-hidden="true"></i>
      Schedule
    </button>

  </div>
</div>
