import { LogiHostComponent } from '../app-modules/logi-integration/components/logi-host.component';
import { ApplicationContainerComponent } from './components/index';
import { CanLeaveGuard } from '../common/services';
import { OrgLevelResolver } from './services/index';
import { StaffingPredictorComponent } from '../app-modules/staffing-predictor/components';
import { EliteDashboardComponent } from '../app-modules/ElieteDashboard/components/elite-dashboard.component';
var ɵ0 = { logiComponentId: 'dashboard', componentId: 'bi_dashboards' }, ɵ1 = { logiComponentId: 'analysis', componentId: 'bi_ss_create_report' }, ɵ2 = { logiComponentId: 'explorer', componentId: 'bi_ss_view_reports' }, ɵ3 = { logiComponentId: 'explorer', componentId: 'cms_insights_dashboard' }, ɵ4 = { componentId: 'staffing_predictor', accessContext: 'OrgLevel' };
export var logiRoutes = [
    {
        path: '',
        component: ApplicationContainerComponent,
        children: [
            {
                path: '',
                redirectTo: 'bi_dashboards',
                pathMatch: 'full'
            },
            {
                path: 'bi_dashboards',
                component: LogiHostComponent,
                data: ɵ0
            },
            {
                path: 'bi_ss_create_report',
                component: LogiHostComponent,
                data: ɵ1
            },
            {
                path: 'bi_ss_view_reports',
                component: LogiHostComponent,
                data: ɵ2
            },
            {
                path: 'cms_insights_dashboard',
                component: EliteDashboardComponent,
                data: ɵ3
            },
            {
                path: 'staffing_predictor',
                canDeactivate: [CanLeaveGuard],
                resolve: [OrgLevelResolver],
                component: StaffingPredictorComponent,
                data: ɵ4
            }
        ]
    },
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
