<slx-spinner [show]="isLoading">

  <form #gridForm="ngForm" class="content-form">

    <div class="modal-body">
        <slx-benefit-payroll-deduction [groupName]="groupName"
          [canMapPayroll]="canMapPayroll"
          [effectiveDate]="effectiveDate" 
          [dedStartDate]="dedStartDate"
          [dedEndDate]="dedEndDate"
          [payrollDedStartDate]="payrollDedStartDate"
          [payrollDedEndDate]="payrollDedEndDate" 
          [startDate]="startDate"
          [endDate]="endDate"
          (dedEndDateChange)="OnDeductionEndDateChange($event)"
          (dedStartDateChange)="OnDeductionStartDateChange($event)"
        >
        </slx-benefit-payroll-deduction>
        <div class="controls-holder">
          <div class="controls-row">
          <p class="flex form__ctrl margin-right">
            <label class="form__label">Select a Benefit Tier</label>
            <span class="flex flex__grow form-dropdown-control">
              <slx-dropdown-list class="slx-wide" [options]="tiers" [ngModel]="selectedTier"
                (ngModelChange)="onChangeTier($event)" name="tiersList" required>
              </slx-dropdown-list>
            </span>
          </p>

          <p class="flex form__ctrl margin-left" *ngIf="hasSettings && isFormula">
            <label class="form__label">Coverage</label>
            <span class="flex flex__grow form-dropdown-control flex__wrap">
              <span class="flex flex__grow form-dropdown-control">
                <slx-number class="slx-wide slx-input-number" 
                            [step]="stepcurrency" 
                            [format]="'c2'" 
                            [decimals]="2"
                            [slxMin]="minAmt"
                            #covFormulaOverride="ngModel"
                            [required]="true"
                            [ngModel]="settings.calculatedCoverage" 
                            name="formulaValue"
                            (ngModelChange)="onCoverageValueChange($event, dataItem)">
                </slx-number>              
                <i *ngIf="settings.calculatedCoverage==-1"
                  title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                  aria-hidden="true"
                  class="fa fa-exclamation-triangle formula-warning"></i>
                </span>
                <span *ngIf="covFormulaOverride.errors?.min" class="slx-error-block pull-left" errorMessage>Value should be more than 0</span>
                <span *ngIf="covFormulaOverride.errors?.required" class="slx-error-block pull-left" errorMessage for="required"></span>
            </span>
          </p>

          <p class="flex form__ctrl margin-left" *ngIf="hasSettings && isFixed">
            <label class="form__label">Coverage</label>
            <span class="flex flex__grow form-dropdown-control">
              <slx-number class="slx-wide slx-input-number" [step]="stepcurrency"[format]="'c0'"
                [(ngModel)]="settings.fixedAmount" name="fixedAmount" [readonly]="true">
              </slx-number>
            </span>
          </p>

          <p class="flex form__ctrl margin-left" *ngIf="hasSettings && anyWithMaxCap">
            <label class="form__label">Coverage</label>
            <span class="flex flex__grow form-dropdown-control flex__wrap">
              <slx-number #covAnyField="ngModel"
                          class="slx-wide slx-input-number"
                          [min]="minAmt" [max]="maxAmt" 
                          [slxMin]="minAmt" [slxMax]="maxAmt" 
                          [step]="stepcurrency"
                          [format]="'c2'" 
                          [decimals]="2" 
                          [autoCorrect]="false"
                          [(ngModel)]="settings.formulaValue" 
                          name="anyWithMaxCap"
                          (ngModelChange)="onCoverageValueChange($event, dataItem)"
                          [required]="true">
              </slx-number>            
              <span *ngIf="covAnyField.errors?.min || covAnyField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
              <span *ngIf="covAnyField.errors?.required" class="slx-error-block pull-left" errorMessage for="required"></span>
            </span>
          </p>

          <p class="flex form__ctrl margin-left" *ngIf="hasSettings && multiplerMaxCap">
            <label class="form__label">Coverage</label>
            <span class="flex flex__grow form-dropdown-control flex__wrap">
              <slx-autocomplete class="slx-wide" 
                                #covMultiplier="ngModel"
                                slxAutocompleteInvalidValue
                [options]="coverageMultipllierOptions" 
                [ngModel]="editedItemCoverage"
                [strictSearch]="false"
                rightIcon="fas fa-search" 
                name="multiplerMaxCap" 
                [virtual]="virtual" titleField="name" 
                [required]="true"
                (valueChange)="onChangeCoverageWithMultiplier($event)">
                <ng-template slxAutocompleteItem let-item="item">
                  {{item.name}}
                </ng-template>
              </slx-autocomplete>
              <div *ngIf="covMultiplier.errors" class="slx-error-block">
                <span *ngIf="!covMultiplier.errors.autocompleteInvalidValue && covMultiplier.errors.required" errorMessage for="required" class="pull-left"></span>
                <span *ngIf="covMultiplier.errors.autocompleteInvalidValue" errorMessage class="slx-error-block pull-left">Invalid value</span>
              </div>
              <span *ngIf="!covMultiplier.errors && userCantEnroll" errorMessage class="slx-error-block pull-left">Invalid value</span>
            </span>
          </p>



        </div>

        <div class="controls-row contributions" *ngIf="hasSettings">


          <p class="flex form__ctrl margin-right  top-label">
            <label class="form__label">Employer Contribution</label>
            <span class="flex flex__grow form-dropdown-control">
              <slx-number class="slx-wide slx-input-number" [step]="stepcurrency"
                [format]="'c2'" [decimals]="2" [autoCorrect]="false" [ngModel]="settings.erContribution" name="erCont"
                [readonly]="true">
              </slx-number>
              <i *ngIf="isFormula && settings.erContribution==-1"
                 title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                 aria-hidden="true"
                 class="fa fa-exclamation-triangle formula-warning"></i>
            </span>
          </p>

          <p class="flex form__ctrl margin-left  top-label">
            <label class="form__label">Employee Contribution</label>
            <span class="flex flex__grow form-dropdown-control">
              <slx-number class="slx-wide slx-input-number" [step]="stepcurrency"
                [format]="'c2'" [decimals]="2" [autoCorrect]="false" [ngModel]="settings.empContribution"
                name="empCont" [readonly]="true">
              </slx-number>
              <i *ngIf="isFormula && settings.empContribution==-1"
                 title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                 aria-hidden="true"
                 class="fa fa-exclamation-triangle formula-warning"></i>
            </span>
          </p>

        </div>

      </div>
    </div>

    <div class="modal-footer">
      
      <span id="enroll-button" class="formula-info" [ngClass]="{'hide_item_tip': hasSelectedValidTierFormula}">
        <button [disabled]="gridForm.invalid || userCantEnroll || hasError" 
                type="button" 
                title="{{!isCovCalculated && !multiplerMaxCap ? 'Click to calculate Coverage': ''}}"
                (click)="onEnroll(multiplerMaxCap ? 'Enroll' : (isCovCalculated ? 'Enroll' : 'Calculate'))"
                class="btn green">
                <i *ngIf="isCovCalculated || multiplerMaxCap" class="fas fa-save slx-button__icon" aria-hidden="true"></i>
                <i *ngIf="!isCovCalculated && !multiplerMaxCap" class="fas fa-calculator slx-button__icon" aria-hidden="true"></i>
                {{ multiplerMaxCap ? 'Enroll' : (isCovCalculated ? 'Enroll' : 'Calculate') }}
        </button>
        <span class="formula__btn-box" *ngIf="hasSelected && !hasSelectedValidTierFormula"  [slxTooltip]="getFormulaToolTip()" tipPosition="top"></span>
        <div></div>
      </span>
      <button type="button" class="btn red" (click)="onCancel()" ><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
    </div>

  </form>

</slx-spinner>
