import { Injectable } from '@angular/core';
import { dateTimeUtils } from '../../../common/utils/index';

import * as _ from 'lodash';
import * as moment from 'moment';


import { LookupMapService, EmployeeDefinitionsMapService } from '../../../organization/services/index';

import {
  EmpType, EmployeeDefinition, IEmployeeDefinition,
} from '../../../organization/models/index';
import {
  VacationPlanEmployeeWeek, IVacationPlanEmployeeWeek,
  VacationPlanEmployeeRecord, IVacationPlanEmployeeRecord,
  VacationPlan, IVacationPlan,
  VacationPlanEmployeeRecordDetails, IVacationPlanEmployeeRecordDetails,
  VacationPlanEmployeeSummaryRecord, IVacationPlanEmployeeSummaryRecord,
  VacationPlanEmployeeDetails, IVacationPlanEmployeeDetails,
  VacationPlanEmployeeSummary, IVacationPlanEmployeeSummary,
  IVacationPlanEmployeeDetailsSaveRequest
} from '../../models/index';

@Injectable()
export class VacationPlannerMapService {
  private lookupMapService: LookupMapService;
  private employeeDefinitionsMapService: EmployeeDefinitionsMapService;

  constructor(lookupMapService: LookupMapService, employeeDefinitionsMapService: EmployeeDefinitionsMapService) {
    this.lookupMapService = lookupMapService;
    this.employeeDefinitionsMapService = employeeDefinitionsMapService;
  }

  public mapToVacationPlanEmployeeWeek(dto: IVacationPlanEmployeeWeek): VacationPlanEmployeeWeek {
    let data: VacationPlanEmployeeWeek = new VacationPlanEmployeeWeek();
    data.start = moment(dateTimeUtils.convertFromDtoDateTimeString(dto.start));
    data.days = dto.days;
    return data;
  }



  public mapToVacationPlanEmployeeRecordDetails(dto: IVacationPlanEmployeeRecordDetails): VacationPlanEmployeeRecordDetails {
    let data: VacationPlanEmployeeRecordDetails = new VacationPlanEmployeeRecordDetails();
    data.date = dateTimeUtils.convertFromDtoDateTimeString(dto.date);
    data.isUnpaidAbsence = dto.isUnpaidAbsence;
    data.isRotationDay = dto.isRotationDay;
    data.isScheduled = dto.isScheduled;
    if (dto.scheduleAbsence) {
     data.scheduleAbsence = this.lookupMapService.mapScheduleAbsence(dto.scheduleAbsence);
    }
    return data;
  }

  public mapToVacationPlanEmployeeRecordDetailsDto(data: VacationPlanEmployeeRecordDetails): IVacationPlanEmployeeRecordDetails {
    let dto: IVacationPlanEmployeeRecordDetails = {
      date: dateTimeUtils.convertToDtoDateTimeString(data.date),
      isUnpaidAbsence: data.isUnpaidAbsence,
      isRotationDay: data.isRotationDay,
      isScheduled: data.isScheduled,
      scheduleAbsence: this.lookupMapService.mapScheduleAbsenceDto(data.scheduleAbsence)
    };
    return dto;
  }

  public mapToVacationPlanEmployeeSummaryRecord(dto: IVacationPlanEmployeeSummaryRecord): VacationPlanEmployeeSummaryRecord {
    let data: VacationPlanEmployeeSummaryRecord = new VacationPlanEmployeeSummaryRecord();
    data.date = dateTimeUtils.convertFromDtoDateTimeString(dto.date);
    data.hours = dto.hours;
    if (dto.scheduleAbsence) {
      data.scheduleAbsence = this.lookupMapService.mapScheduleAbsence(dto.scheduleAbsence);
    }
    return data;
  }

  public mapToVacationPlanEmployeeRecord(dto: IVacationPlanEmployeeRecord, weeks: NumberMap<moment.Moment>): VacationPlanEmployeeRecord {
    let data: VacationPlanEmployeeRecord = new VacationPlanEmployeeRecord();
    data.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.shift = this.lookupMapService.mapShiftDefinition(dto.shift);
    data.unit = this.lookupMapService.mapLocationUnit(dto.unit);
    data.daysWorking = dto.daysWorking;
    data.vacationDays = dto.vacationDays;
    data.vacationHours = dto.vacationHours;
    data.weeks = {};
    let employeeWeeks: VacationPlanEmployeeWeek[] = _.map(dto.weeks, (dtoweek: IVacationPlanEmployeeWeek) => {
      return this.mapToVacationPlanEmployeeWeek(dtoweek);
    });
    _.forIn(weeks, (value: moment.Moment, key: string) => {
      let existWeek: VacationPlanEmployeeWeek = _.find(employeeWeeks, (employeeWeek: VacationPlanEmployeeWeek) => {
        return employeeWeek.start.isSame(value);
      });
      if (!existWeek) {
        existWeek = new VacationPlanEmployeeWeek();
        existWeek.days = 0;
        existWeek.start = value;
      }
      existWeek.isActive = moment(data.employee.dateHired).isSameOrBefore(moment(value).add(1, 'week'));
      if (!!data.employee.dateTerminated) {
        existWeek.isActive = existWeek.isActive && moment(data.employee.dateTerminated).isAfter(value);
      }
      data.weeks[+key] = existWeek;
    });
    return data;
  }

  public mapToVacationPlan(dto: IVacationPlan): VacationPlan {
    let data: VacationPlan = new VacationPlan();
    data.weeks = {};
    data.actions =dto.actions;
    let weekStart: moment.Moment = moment(dateTimeUtils.convertFromDtoDateTimeString(dto.firstWeekDate));
    let weeksInYear: number = dto.weeksInYear;
    let weeksNumbers: number[] = _.range(1, weeksInYear + 1, 1);
    _.forEach(weeksNumbers, (index: number) => {
      data.weeks[index] = weekStart.clone();
      weekStart = weekStart.add(1, 'w');
    });
    data.records = _.map(dto.records, (dtorecord: IVacationPlanEmployeeRecord) => {
      return this.mapToVacationPlanEmployeeRecord(dtorecord, data.weeks);
    });
    return data;
  }

  public mapToVacationPlanEmployeeDetails(dto: IVacationPlanEmployeeDetails, employee: EmployeeDefinition): VacationPlanEmployeeDetails {
    let data: VacationPlanEmployeeDetails = new VacationPlanEmployeeDetails();
    data.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    data.records = _.map(dto.records, (dtorecord: IVacationPlanEmployeeRecordDetails) => {
      const res: VacationPlanEmployeeRecordDetails = this.mapToVacationPlanEmployeeRecordDetails(dtorecord);
      if (!employee.dateTerminated) {
        res.isActive = moment(res.date).isSameOrAfter(employee.dateHired);
      } else {
        res.isActive = moment(res.date).isBetween(employee.dateHired, employee.dateTerminated, null, '[]');
      }
      return res;
    });
    return data;
  }

  public mapToVacationPlanEmployeeSummary(dto: IVacationPlanEmployeeSummary): VacationPlanEmployeeSummary {
    let data: VacationPlanEmployeeSummary = new VacationPlanEmployeeSummary();
    data.totalVacations = dto.totalVacations;
    data.totalVacationsHours = dto.totalVacationsHours;
    data.records = _.map(dto.records, (dtorecord: IVacationPlanEmployeeSummaryRecord) => {
      return this.mapToVacationPlanEmployeeSummaryRecord(dtorecord);
    });
    return data;
  }

  public mapToVacationPlanSaveRequest(records: VacationPlanEmployeeRecordDetails[]): IVacationPlanEmployeeDetailsSaveRequest {
    let dtoRecords: IVacationPlanEmployeeRecordDetails[] = _.map(records, (record: VacationPlanEmployeeRecordDetails) => {
      return this.mapToVacationPlanEmployeeRecordDetailsDto(record);
    });
    let dto: IVacationPlanEmployeeDetailsSaveRequest = {
      records: dtoRecords
    };
    return dto;
  }
}
