import * as _ from 'lodash';
var TimecardsEarningPayRule = /** @class */ (function () {
    function TimecardsEarningPayRule() {
    }
    Object.defineProperty(TimecardsEarningPayRule.prototype, "isMoneyAmountRule", {
        get: function () {
            return _.isNumber(this.moneyAmount) && this.moneyAmount !== 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardsEarningPayRule.prototype, "value", {
        get: function () {
            if (this.isMoneyAmountRule) {
                return this.moneyAmount;
            }
            return this.hours;
        },
        enumerable: true,
        configurable: true
    });
    return TimecardsEarningPayRule;
}());
export { TimecardsEarningPayRule };
