import { SessionActions } from '../../actions/index';
import { IPayloadAction } from '../../../state-model/models/index';
import {
  INITIAL_SESSION_STATE
} from './session.initial-state';

import { Map } from 'immutable';

export function sessionReducer(state: Map<string, any> = INITIAL_SESSION_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case SessionActions.CLEAR_SESSION:
      return INITIAL_SESSION_STATE;
    case SessionActions.SAVE_SESSION:
      return state.mergeDeep(action.payload);
    default:
      return state;
  }
}
