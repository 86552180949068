import * as tslib_1 from "tslib";
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { appConfig } from '../../../app.config';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { ShiftReplacementApiService } from '../shift-replacement/shift-replacement-api.service';
import { LookupApiService, OrgLevelWatchService } from '../../../organization/services/index';
import { DateTimeService } from '../../../common/services/date-time/date-time.service';
import { ScheduleEntryApiService } from './schedule-entry-api.service';
import { ScheduleApiService } from '../schedule/schedule-api.service';
import { ScheduleEntryShift, ShiftOvertime, ShiftPartialAbsence, ShiftLate, ShiftReplacementRequest, ScheduleEntryContainer, ScheduleEntryShiftContainer } from '../../models/index';
import { ScheduleAbsence, ShiftDefinition, LocationUnit, Department, Position, ConstraintDefinition, ScheduleCycle, ShiftGroupOrder, Organization } from '../../../organization/models/index';
import { MasterScheduleActions } from '../../store/master-schedule/master-schedule.actions';
import { ModalService, ChangeManagementService } from '../../../common/services/index';
import { ChangesDialogOptions } from '../../../common/models/index';
import { AppSettingsManageService } from '../../../app-settings/services/index';
import { DailyUnitFilterOptions } from '../../models/daily-unit-assignment/daily-unit-filter-options';
var ScheduleEntryManagementService = /** @class */ (function () {
    function ScheduleEntryManagementService(lookupApiService, scheduleEntryApiService, shiftReplacementApiService, dateTimeService, modalService, masterScheduleActions, scheduleApiService, changeManagementService, notificationsService, appSettingsService, orgLevelWatchService) {
        this.lookupApiService = lookupApiService;
        this.scheduleEntryApiService = scheduleEntryApiService;
        this.shiftReplacementApiService = shiftReplacementApiService;
        this.dateTimeService = dateTimeService;
        this.modalService = modalService;
        this.masterScheduleActions = masterScheduleActions;
        this.scheduleApiService = scheduleApiService;
        this.changeManagementService = changeManagementService;
        this.notificationsService = notificationsService;
        this.appSettingsService = appSettingsService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.hasChanges = false;
        this.duaOptions = new DailyUnitFilterOptions();
        this.overlapShown = false;
        this.duplicateShown = false;
        this.m_OnInitialized = new ReplaySubject(1);
        this.m_OnLoadState = new ReplaySubject(1);
        this.m_OnOverlapState = new ReplaySubject(1);
        this.m_OnDuplicateState = new ReplaySubject(1);
        this.m_OnReplaceShift = new Subject();
    }
    Object.defineProperty(ScheduleEntryManagementService.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryManagementService.prototype, "onInitialized$", {
        get: function () {
            return this.m_OnInitialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryManagementService.prototype, "onLoadState$", {
        get: function () {
            return this.m_OnLoadState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryManagementService.prototype, "onOverlapState$", {
        get: function () {
            return this.m_OnOverlapState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryManagementService.prototype, "onDuplicateState$", {
        get: function () {
            return this.m_OnDuplicateState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleEntryManagementService.prototype, "onReplaceShift$", {
        get: function () {
            return this.m_OnReplaceShift;
        },
        enumerable: true,
        configurable: true
    });
    ScheduleEntryManagementService.prototype.changeScheduleEntryDate = function (employeeId, dateOn, showOnlyActivePositions) {
        if (showOnlyActivePositions === void 0) { showOnlyActivePositions = false; }
        this.changeManagementService.clearChanges();
        this.m_OnLoadState.next(true);
        var onDate = moment(dateOn, appConfig.linkDateFormat).toDate();
        this.loadSchdeuleEntry(employeeId, onDate, showOnlyActivePositions);
    };
    ScheduleEntryManagementService.prototype.loadSchdeuleEntry = function (employeeId, onDate, showOnlyActivePositions) {
        if (showOnlyActivePositions === void 0) { showOnlyActivePositions = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.initDefaults(employeeId, onDate, showOnlyActivePositions)];
                    case 1:
                        _a.sent();
                        this.originalScheduleEntry = JSON.stringify(this.scheduleEntry);
                        this.scheduleEntryContainer = this.createVieModel(this.scheduleEntry);
                        this.sortShifts(this.scheduleEntryContainer);
                        this.checkChanges();
                        this.m_OnLoadState.next(false);
                        this.m_initialized = true;
                        this.m_OnInitialized.next(this.scheduleEntryContainer);
                        this.refreshEntry(this.scheduleEntryContainer);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ScheduleEntryManagementService.prototype.checkChanges = function () {
        this.hasChanges = this.compareEntry(this.scheduleEntry);
        if (this.hasChanges) {
            this.changeManagementService.changeNotify();
        }
        else {
            this.changeManagementService.clearChanges();
        }
    };
    ScheduleEntryManagementService.prototype.save = function (container) {
        var _this = this;
        this.m_OnLoadState.next(true);
        this.scheduleEntryApiService.saveScheduleEntry(this.scheduleEntry, this.defaultPosition.orgLevelId).then(function () {
            _this.updateMasterSchedule([{ date: _this.scheduleEntry.date, employeeId: _this.scheduleEntry.employee.id }], _this.defaultPosition.orgLevelId);
            _this.loadSchdeuleEntry(_this.scheduleEntry.employee.id, _this.scheduleEntry.date);
            _this.changeManagementService.clearChanges();
            _this.m_initialized = true;
            _this.m_OnInitialized.next(container);
            _this.m_OnLoadState.next(false);
        }).catch(function (e) {
            _this.m_initialized = true;
            _this.m_OnInitialized.next(container);
            _this.m_OnLoadState.next(false);
        });
    };
    ScheduleEntryManagementService.prototype.updateMasterSchedule = function (entry, orgLevelId) {
        this.masterScheduleActions.scheduleEntryChange(orgLevelId, entry);
    };
    ScheduleEntryManagementService.prototype.discard = function () {
        var entry = JSON.parse(this.originalScheduleEntry, this.reviveDateTime);
        this.scheduleEntry = entry;
        // this.fixPositions();
        this.discardViewModel(this.scheduleEntryContainer, this.scheduleEntry);
        this.checkChanges();
        this.changeManagementService.clearChanges();
        this.m_OnDuplicateState.next(false);
        this.m_OnOverlapState.next(false);
        this.m_OnLoadState.next(false);
        this.m_initialized = true;
        this.m_OnInitialized.next(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.initDefaults = function (employeeId, onDate, isActive) {
        if (isActive === void 0) { isActive = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var scheduleEntryPromise, departmentsPromise, positionPromise, shiftPromise, unitPromise, constraintsPromise, settingsPromise, organizationsPromise, results, _a, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.defaultShift = null;
                        this.defaultPosition = null;
                        this.defaultUnit = null;
                        scheduleEntryPromise = this.scheduleEntryApiService.getScheduleEntry(employeeId, onDate);
                        departmentsPromise = this.lookupApiService.getDepartments(employeeId, null, false, true);
                        positionPromise = this.lookupApiService.getPositions(employeeId, null, isActive, true);
                        shiftPromise = this.lookupApiService.getShiftDefinitions(employeeId);
                        unitPromise = this.lookupApiService.getLocationUnits(employeeId);
                        constraintsPromise = this.lookupApiService.getConstraintDefinitions(employeeId);
                        settingsPromise = this.appSettingsService.getAppServerConfig();
                        organizationsPromise = this.lookupApiService.getOrganizations();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, Promise.all([unitPromise, departmentsPromise, positionPromise, shiftPromise, constraintsPromise, settingsPromise, organizationsPromise])];
                    case 2:
                        results = _b.sent();
                        this.employeeUnits = results[0];
                        this.employeeDepartments = results[1];
                        this.employeeDepartmentsOrgLevels = [];
                        this.employeeShifts = results[3];
                        this.employeeConstraints = results[4];
                        this.appSettings = results[5];
                        this.organizations = results[6];
                        _.each(this.employeeDepartments, function (d) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var orgLevel;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.orgLevelWatchService.getOrgLevelByIdSafe(d.orgLevelId)];
                                    case 1:
                                        orgLevel = _a.sent();
                                        if (orgLevel) {
                                            this.employeeDepartmentsOrgLevels.push(orgLevel);
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        _a = this;
                        return [4 /*yield*/, scheduleEntryPromise];
                    case 3:
                        _a.scheduleEntry = _b.sent();
                        this.minDate = this.scheduleEntry.previousDayShiftEnd || moment(this.scheduleEntry.date).subtract(1, 'day').toDate();
                        this.maxDate = this.scheduleEntry.nextDayShiftStart || moment(this.scheduleEntry.date).add(1, 'day').toDate();
                        if (results[2]) {
                            this.employeePositions = results[2] ? this.filterAvailablePositionsByDate(results[2], this.scheduleEntry.date) : [];
                            this.employeePositions = _.uniqBy(this.employeePositions, function (position) { return position.id; });
                        }
                        _.forEach(this.scheduleEntry.shifts, function (shift) {
                            _this.addLookupValueIfDoesntContain('id', shift.position, _this.employeePositions);
                            // if (_.filter(this.employeePositions, (position: Position): boolean => position.id === shift.position.id)) {
                            //   this.employeePositions.push(shift.position);
                            // }
                        });
                        // if (this.employeePositions) {
                        //   this.fixPositions();
                        // }
                        if (this.employeeUnits) {
                            if (!this.defaultUnit) {
                                this.defaultUnit = _.find(this.employeeUnits, { 'id': this.scheduleEntry.defaultUnitId });
                                if (!this.defaultUnit) {
                                    this.defaultUnit = _.find(this.employeeUnits, { 'id': -1 });
                                }
                            }
                        }
                        if (this.scheduleEntry.defaultShiftId > 0) {
                            this.defaultShift = _.find(this.employeeShifts, { 'id': this.scheduleEntry.defaultShiftId });
                        }
                        if (this.scheduleEntry.defaultPositionId > 0) {
                            this.defaultPosition = _.find(this.employeePositions, { 'id': this.scheduleEntry.defaultPositionId });
                        }
                        if (this.defaultPosition && this.defaultPosition.orgLevelId) {
                            this.defaultDepratment = _.find(this.employeeDepartments, { 'orgLevelId': this.defaultPosition.orgLevelId });
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        error_2 = _b.sent();
                        throw error_2;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ScheduleEntryManagementService.prototype.addNewShift = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newShift, container, timeString, m;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newShift = new ScheduleEntryShift();
                        newShift.department = this.defaultDepratment;
                        newShift.position = this.defaultPosition;
                        newShift.unit = this.defaultUnit;
                        if (this.scheduleEntry.shifts.length === 0) {
                            newShift.shift = this.defaultShift;
                        }
                        this.scheduleEntry.shifts.push(newShift);
                        container = new ScheduleEntryShiftContainer();
                        container.scheduleEntryDate = this.scheduleEntryContainer.date;
                        container.minDate = this.minDate;
                        container.maxDate = this.maxDate;
                        container.positions = this.employeePositions;
                        container.shifts = this.employeeShifts;
                        container.units = this.employeeUnits;
                        if (newShift.shift) {
                            timeString = void 0;
                            m = void 0;
                            this.updateTimeByShift(newShift, container);
                        }
                        else {
                            newShift.startDate = moment(this.scheduleEntry.date).toDate();
                            newShift.endDate = moment(this.scheduleEntry.date).toDate();
                        }
                        container.scheduleEntryShift = newShift;
                        return [4 /*yield*/, this.refreshLookups(container)];
                    case 1:
                        _a.sent();
                        this.setUnpaidTime(container);
                        this.scheduleEntryContainer.shifts.push(container);
                        this.refreshEntry(this.scheduleEntryContainer);
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleEntryManagementService.prototype.removeShift = function (shift) {
        this.scheduleEntry.shifts = _.without(this.scheduleEntry.shifts, shift.scheduleEntryShift);
        this.scheduleEntryContainer.shifts = _.without(this.scheduleEntryContainer.shifts, shift);
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.shiftChange = function (shift, container) {
        var _this = this;
        this.removePartialAbsence(container);
        this.removeLateness(container);
        this.updateTimeByShift(shift, container);
        this.setUnpaidTime(container);
        container.hasDuplicate = this.checkDuplicatedShift(container);
        if (container.hasDuplicate) {
            this.m_OnDuplicateState.next(true);
        }
        else {
            container.hasOverlap = this.checkOverlapingOfShift(container) === 2 /* FATAL */;
            if (container.hasOverlap) {
                this.m_OnOverlapState.next(true);
            }
        }
        if (!container.hasDuplicate && !container.hasOverlap) {
            this.refreshEntry(this.scheduleEntryContainer);
            this.m_OnLoadState.next(true);
            _.delay(function () {
                _this.m_OnLoadState.next(false);
                _this.sortShifts(_this.scheduleEntryContainer);
            }, 600);
        }
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.unitChange = function (definition, container) {
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.absenceChange = function (definition, container) {
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.onShiftStartDateChanged = function (container) {
        if (container.scheduleEntryShift.partialAbsence) {
            if (!container.partialAbsToEnd) {
                container.scheduleEntryShift.partialAbsence.startDate = moment(container.shiftStartDate).toDate();
                this.calculatePartialAbsenceEndTime(container.scheduleEntryShift.partialAbsence);
            }
        }
        if (container.scheduleEntryShift.overtime) {
            container.scheduleEntryShift.overtime.startDate = moment(container.shiftStartDate).toDate();
        }
        if (container.scheduleEntryShift.late) {
            var arrivalDate = container.scheduleEntryShift.late.arrivalDate;
            var newStartDate = container.shiftStartDate;
            if (moment(arrivalDate).isBefore(newStartDate)) {
                container.scheduleEntryShift.late.arrivalDate = moment(container.shiftStartDate).toDate();
            }
            this.calculateLateTimeStamp(container);
        }
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.onShiftEndDateChanged = function (container) {
        if (container.scheduleEntryShift.partialAbsence) {
            if (container.partialAbsToEnd) {
                container.scheduleEntryShift.partialAbsence.endDate = moment(container.shiftEndDate).toDate();
                this.calculatePartialAbsenceStartTime(container.scheduleEntryShift.partialAbsence);
            }
        }
        if (container.scheduleEntryShift.overtime) {
            container.scheduleEntryShift.overtime.endDate = moment(container.shiftEndDate).toDate();
        }
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.shiftPaidTimeChanged = function (container) {
        if (_.isNaN(container.shiftHours)) {
            container.shiftHours = 0;
        }
        this.calculateShiftEndTime(container);
        this.onShiftEndDateChanged(container);
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.shiftUnpaidTimeChanged = function (container) {
        if (_.isNaN(container.unpaidHours)) {
            container.unpaidHours = 0;
        }
        this.calculateShiftEndTime(container);
        this.onShiftEndDateChanged(container);
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.onPositionChanged = function (container) {
        this.updatePositionRelatedInfo(container);
    };
    ScheduleEntryManagementService.prototype.onReplacement = function (shift) {
        var _this = this;
        var dialogOptions = new ChangesDialogOptions();
        this.changeManagementService.canMoveForward(undefined, ChangesDialogOptions.DOWORK)
            .then(function (canMove) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var unit, department, organizationName, request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!canMove) return [3 /*break*/, 2];
                        this.discard();
                        unit = _.find(shift.units, { id: shift.scheduleEntryShift.unit.id }) || new LocationUnit();
                        return [4 /*yield*/, this.orgLevelWatchService.getOrgLevelByIdSafe(shift.scheduleEntryShift.position.orgLevelId)];
                    case 1:
                        department = _a.sent();
                        organizationName = _.get(department, 'treeViewNamePath') || [''];
                        request = new ShiftReplacementRequest();
                        request.shiftId = shift.scheduleEntryShift.shift.id;
                        request.shiftName = shift.scheduleEntryShift.shift.name;
                        request.unitId = shift.scheduleEntryShift.unit.id;
                        request.unitName = unit.name || shift.scheduleEntryShift.unit.name;
                        request.organizationName = organizationName.slice(-1).join('');
                        request.positionId = shift.scheduleEntryShift.position.id;
                        request.positionName = shift.scheduleEntryShift.position.name;
                        request.date = this.scheduleEntry.date;
                        request.showDayOffEmployees = true;
                        request.shiftStart = shift.shiftStartDate;
                        request.shiftEnd = shift.shiftEndDate;
                        request.shiftName = shift.scheduleEntryShift.shift.name;
                        request.replacedEmployeeId = this.scheduleEntry.employee.id;
                        request.replacedEmployeeName = this.scheduleEntry.employee.name;
                        request.orgLevelId = shift.scheduleEntryShift.position.orgLevelId;
                        request.scheduleAbsence = shift.scheduleEntryShift.scheduleAbsence;
                        request.showSendSmsButton = true;
                        this.shiftToReplace = shift;
                        this.onReplaceShift$.next(request);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    ScheduleEntryManagementService.prototype.doReplace = function (cmd) {
        var _this = this;
        this.m_OnLoadState.next(true);
        this.shiftReplacementApiService.replaceEmployee(cmd)
            .then(function (status) {
            _this.m_OnLoadState.next(false);
            if (_this.shiftToReplace) {
                _this.shiftToReplace.scheduleEntryShift.replaced = cmd.selectedEmployee.employee;
                _this.shiftToReplace.scheduleEntryShift.scheduleAbsence = cmd.scheduleAbsence;
                _this.shiftToReplace.hasScheduleAbsence = cmd.scheduleAbsence !== null;
                _this.shiftToReplace = null;
                _this.updateMasterSchedule([
                    { date: _this.scheduleEntry.date, employeeId: _this.scheduleEntry.employee.id },
                    { date: _this.scheduleEntry.date, employeeId: cmd.selectedEmployee.employee.id },
                ], _this.defaultPosition.orgLevelId);
            }
            _this.notificationsService.info('Info', 'Replacement was successfully added. Schedule entry refreshed');
            _this.loadSchdeuleEntry(_this.scheduleEntry.employee.id, _this.scheduleEntry.date);
        }).catch(function (result) {
            _this.m_OnLoadState.next(false);
            if (_this.shiftToReplace) {
                _this.shiftToReplace.scheduleEntryShift.replaced = null;
                _this.shiftToReplace.scheduleEntryShift.scheduleAbsence = null;
                _this.shiftToReplace.hasScheduleAbsence = false;
                _this.shiftToReplace = null;
            }
            _this.modalService.globalAnchor.openInfoDialog('Error', "Error happened. Try again later");
        });
    };
    ScheduleEntryManagementService.prototype.addConstraint = function (container) {
        container.scheduleEntryShift.constraint = _.first(container.constraints);
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.removeConstraint = function (container) {
        container.scheduleEntryShift.constraint = null;
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.constraintChanged = function (container) {
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.addLate = function (container) {
        container.scheduleEntryShift.late = new ShiftLate();
        container.scheduleEntryShift.late.arrivalDate = moment(container.shiftStartDate).toDate();
        container.scheduleEntryShift.late.lateInterval = 0;
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.removeLate = function (container) {
        container.scheduleEntryShift.late = null;
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.lateDateChanged = function (container) {
        this.calculateLateInterval(container);
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.lateIntervalChanged = function (container) {
        this.calculateLateTimeStamp(container);
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.addOvertime = function (container) {
        container.scheduleEntryShift.overtime = new ShiftOvertime();
        container.scheduleEntryShift.overtime.startDate = moment(container.shiftStartDate).toDate();
        container.scheduleEntryShift.overtime.endDate = moment(container.shiftEndDate).toDate();
        container.scheduleEntryShift.overtime.duration = this.dateTimeService.durationToHours(container.scheduleEntryShift.shift.duration);
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.removeOvertime = function (container) {
        container.scheduleEntryShift.overtime = null;
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.overtimeDateChanged = function (container) {
        if (container.scheduleEntryShift.overtime)
            this.calculateOvertimeHours(container.scheduleEntryShift.overtime);
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.overtimeHoursChanged = function (container) {
        if (container.scheduleEntryShift.overtime) {
            container.scheduleEntryShift.overtime.endDate = this.calculateEndTime(container.scheduleEntryShift.overtime.startDate, container.scheduleEntryShift.overtime.duration);
        }
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.markAbsent = function (container) {
        this.removePartialAbsence(container);
        if (container.absences && container.absences.length > 0) {
            container.scheduleEntryShift.scheduleAbsence = container.absences[0];
            container.scheduleEntryShift.constraint = undefined;
        }
        this.setUnpaidTime(container);
        this.calculatePaidHours(container);
        container.hasScheduleAbsence = true;
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.markPresent = function (container) {
        container.scheduleEntryShift.scheduleAbsence = null;
        container.hasScheduleAbsence = false;
        this.setUnpaidTime(container);
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.addPartialAbsence = function (container) {
        container.scheduleEntryShift.partialAbsence = new ShiftPartialAbsence();
        if (container.absences && container.absences.length > 0) {
            container.scheduleEntryShift.partialAbsence.scheduleAbsence = container.absences[0];
        }
        var startMoment = moment(container.shiftStartDate);
        var endMoment = moment(container.shiftEndDate);
        container.scheduleEntryShift.partialAbsence.startDate = startMoment.toDate();
        container.scheduleEntryShift.partialAbsence.endDate = endMoment.toDate();
        container.scheduleEntryShift.partialAbsence.hours = 0;
        container.partialAbsToEnd = false;
        this.updatePartialAbsence(container);
        this.refreshScheduleEntryShift(container);
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.removePartialAbsence = function (container) {
        if (!container.scheduleEntryShift.partialAbsence)
            return;
        container.scheduleEntryShift.partialAbsence = null;
        this.refreshScheduleEntryShift(container);
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.removeLateness = function (container) {
        if (!container.scheduleEntryShift.late)
            return;
        container.scheduleEntryShift.late = null;
        this.refreshScheduleEntryShift(container);
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.shiftPartialAbsenceTimeChanged = function (container) {
        this.updatePartialAbsence(container);
        this.refreshScheduleEntryShift(container);
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.onPartialAbsenceAttachmentChanged = function (container) {
        var absence = container.scheduleEntryShift.partialAbsence;
        if (!container.partialAbsToEnd) {
            absence.startDate = moment(container.shiftStartDate).toDate();
        }
        else {
            absence.endDate = moment(container.shiftEndDate).toDate();
        }
        this.shiftPartialAbsenceTimeChanged(container);
    };
    ScheduleEntryManagementService.prototype.addNotes = function (shift) {
        shift.scheduleEntryShift.notes = '';
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.removeNotes = function (shift) {
        shift.scheduleEntryShift.notes = null;
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.updatePartialAbsence = function (container) {
        var hours = container.scheduleEntryShift.partialAbsence.hours;
        var absence = container.scheduleEntryShift.partialAbsence;
        var shiftDuration = this.getDurationMs(container.shiftStartDate, container.shiftEndDate);
        var absenceDuration;
        if (!container.partialAbsToEnd) {
            absence.endDate = moment(absence.startDate).add(hours, 'hour').toDate();
            absenceDuration = this.getDurationMs(absence.startDate, absence.endDate);
            if (absenceDuration > shiftDuration) {
                absence.endDate = moment(container.shiftEndDate).toDate();
                absence.hours = this.ms2Hours(this.getDurationMs(absence.startDate, absence.endDate));
            }
        }
        else {
            absence.startDate = moment(absence.endDate).subtract(hours, 'hour').toDate();
            absenceDuration = this.getDurationMs(absence.startDate, absence.endDate);
            if (absenceDuration > shiftDuration) {
                absence.startDate = moment(container.shiftStartDate).toDate();
                absence.hours = this.ms2Hours(this.getDurationMs(absence.startDate, absence.endDate));
            }
        }
    };
    ScheduleEntryManagementService.prototype.reviveDateTime = function (key, value) {
        if (typeof value === 'string') {
            var a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value);
            if (a) {
                return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
            }
        }
        return value;
    };
    ScheduleEntryManagementService.prototype.createVieModel = function (entry) {
        var scheduleEntryContainer = new ScheduleEntryContainer();
        scheduleEntryContainer.date = this.scheduleEntry.date;
        scheduleEntryContainer.employeeId = this.scheduleEntry.employee.id;
        scheduleEntryContainer.employeeName = this.scheduleEntry.employee.name;
        scheduleEntryContainer.employeePayrollNumber = this.scheduleEntry.employee.payrollNumber;
        scheduleEntryContainer.canReplaceEmployee = this.scheduleEntry.canReplaceEmployee;
        scheduleEntryContainer.shifts = this.createContainerShifts(entry);
        return scheduleEntryContainer;
    };
    ScheduleEntryManagementService.prototype.discardViewModel = function (model, entry) {
        model.shifts = this.createContainerShifts(entry);
    };
    ScheduleEntryManagementService.prototype.createContainerShifts = function (entry) {
        var _this = this;
        var shiftsContainers = [];
        _.each(entry.shifts, function (shift) {
            var container = new ScheduleEntryShiftContainer();
            _this.updateTimeByNightShiftSetting(shift);
            container.minDate = _this.minDate;
            container.maxDate = _this.maxDate;
            container.positions = _this.employeePositions;
            container.scheduleEntryShift = shift;
            container.scheduleEntryDate = entry.date;
            if (shift.position) {
                var employeePosition = _.find(_this.employeePositions, function (x) { return x.id === shift.position.id; });
                if (employeePosition.startDate > shift.startDate || employeePosition.endDate < shift.endDate) {
                    container.disabledPosition = employeePosition;
                }
            }
            if (shift.partialAbsence) {
                var shiftStart = moment(shift.startDate);
                var shiftEnd = moment(shift.endDate);
                var paStart = moment(shift.partialAbsence.startDate);
                var paEnd = moment(shift.partialAbsence.endDate);
                if (paStart.isSame(shiftEnd)) {
                    container.partialAbsToEnd = true;
                    container.shiftEndDate = paEnd.toDate();
                }
                else if (paEnd.isSame(shiftStart)) {
                    container.partialAbsToEnd = false;
                    container.shiftStartDate = paStart.toDate();
                }
                var paDurationHrs = _this.ms2Hours(_this.getDurationMs(shift.partialAbsence.startDate, shift.partialAbsence.endDate));
                container.shiftHours += paDurationHrs;
            }
            container.hasScheduleAbsence = !_.isNil(shift.scheduleAbsence);
            container.unpaidHours = _this.getUnpaidTime(container);
            _this.refreshLookups(container);
            shiftsContainers.push(container);
        });
        return shiftsContainers;
    };
    ScheduleEntryManagementService.prototype.updatePositionRelatedInfo = function (shift) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var canSetupDefaultShift, shiftChanged;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!!shift.previousPositionOrgLevelId && shift.previousPositionOrgLevelId !== shift.scheduleEntryShift.position.orgLevelId)) return [3 /*break*/, 2];
                        shift.scheduleEntryShift.shift = null;
                        shift.scheduleEntryShift.unit = null;
                        shift.scheduleEntryShift.constraint = null;
                        shift.scheduleEntryShift.scheduleAbsence = null;
                        if (shift.scheduleEntryShift.partialAbsence) {
                            shift.scheduleEntryShift.partialAbsence.scheduleAbsence = null;
                        }
                        if (this.employeeDepartments) {
                            shift.scheduleEntryShift.department = _.find(this.employeeDepartments, { orgLevelId: shift.scheduleEntryShift.position.orgLevelId });
                        }
                        return [4 /*yield*/, this.refreshLookups(shift)];
                    case 1:
                        _a.sent();
                        shift.previousPositionOrgLevelId = shift.scheduleEntryShift.position.orgLevelId;
                        _a.label = 2;
                    case 2:
                        if (shift.scheduleEntryShift.position.orgLevelId === this.defaultPosition.orgLevelId) {
                            canSetupDefaultShift = false;
                            canSetupDefaultShift = this.scheduleEntryContainer.shifts.length === 1
                                || !_.some(this.scheduleEntryContainer.shifts, function (scheduleShift) { return scheduleShift.scheduleEntryShift.shift.id === _this.defaultShift.id; });
                            shiftChanged = canSetupDefaultShift && !_.isNull(this.defaultShift) && !_.isNull(shift.scheduleEntryShift.shift) && shift.scheduleEntryShift.shift.id !== this.defaultShift.id;
                            shift.scheduleEntryShift.shift = canSetupDefaultShift ? this.defaultShift : null;
                            shift.scheduleEntryShift.unit = this.defaultUnit;
                            if (shiftChanged) {
                                this.shiftChange(shift.scheduleEntryShift, shift);
                            }
                        }
                        this.checkChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleEntryManagementService.prototype.refreshLookups = function (shift) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var positionOrgLevelId, unitPromise, shiftPromise, constraintsPromise, absencePromise, results, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        if (!shift.scheduleEntryShift.position)
                            return [2 /*return*/];
                        positionOrgLevelId = shift.scheduleEntryShift.position.orgLevelId;
                        unitPromise = this.lookupApiService.getLocationUnits(this.scheduleEntry.employee.id, positionOrgLevelId);
                        shiftPromise = this.lookupApiService.getShiftDefinitions(this.scheduleEntry.employee.id, positionOrgLevelId);
                        constraintsPromise = this.lookupApiService.getConstraintDefinitions(this.scheduleEntry.employee.id, positionOrgLevelId);
                        absencePromise = this.lookupApiService.getScheduleAbsences(positionOrgLevelId);
                        return [4 /*yield*/, Promise.all([unitPromise, shiftPromise, constraintsPromise, absencePromise])];
                    case 1:
                        results = _a.sent();
                        shift.units = results[0];
                        if (shift.scheduleEntryShift.unit) {
                            this.addLookupValueIfDoesntContain('id', shift.scheduleEntryShift.unit, shift.units);
                        }
                        shift.shifts = results[1];
                        if (shift.scheduleEntryShift.shift) {
                            this.addLookupValueIfDoesntContain('id', shift.scheduleEntryShift.shift, shift.shifts);
                        }
                        shift.constraints = results[2] || [];
                        if (shift.scheduleEntryShift.constraint) {
                            this.addLookupValueIfDoesntContain('code', shift.scheduleEntryShift.constraint, shift.constraints);
                        }
                        shift.absences = results[3];
                        if (shift.scheduleEntryShift.scheduleAbsence) {
                            this.addLookupValueIfDoesntContain('code', shift.scheduleEntryShift.scheduleAbsence, shift.absences);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        throw error_3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ScheduleEntryManagementService.prototype.updateTimeByShift = function (shift, container) {
        var dates = this.getShiftDefinitionDates(shift.shift, container.scheduleEntryDate);
        var startMoment = dates.start;
        var endMoment = dates.end;
        shift.startDate = startMoment.toDate();
        shift.endDate = endMoment.toDate();
        this.updateTimeByNightShiftSetting(shift);
        container.shiftStartDate = moment(shift.startDate).toDate();
        container.shiftEndDate = moment(shift.endDate).toDate();
        container.shiftHours = this.dateTimeService.durationToHours(shift.shift.duration);
    };
    ScheduleEntryManagementService.prototype.updateTimeByNightShiftSetting = function (shift) {
        var nightShiftPrimary = this.getNightShiftSettingByShift(shift);
        if (shift.startDate > shift.endDate) {
            if (!nightShiftPrimary || (shift.shift.group !== undefined && shift.shift.group.groupOrder !== ShiftGroupOrder.Night)) {
                shift.endDate = moment(shift.endDate).add(1, 'days').toDate();
            }
            else {
                shift.startDate = moment(shift.startDate).subtract(1, 'days').toDate();
            }
        }
    };
    ScheduleEntryManagementService.prototype.getNightShiftSettingByShift = function (shift) {
        var nightShiftPrimary = false;
        if (shift.department && !_.isNil(shift.department.parentOrganizationId)) {
            nightShiftPrimary = this.getNightShiftSettingFromDepartment(shift.department.parentOrganizationId);
        }
        else if (shift.position && !_.isNil(shift.position.orgLevelId)) {
            nightShiftPrimary = this.getNightShiftSetting(shift.position.orgLevelId);
        }
        else if (shift.department && !_.isNil(shift.department.orgLevelId)) {
            nightShiftPrimary = this.getNightShiftSetting(shift.department.orgLevelId);
        }
        return nightShiftPrimary;
    };
    ScheduleEntryManagementService.prototype.getNightShiftSettingFromDepartment = function (orgId) {
        var nightShiftPrimary = false;
        var organization = this.getOrganizationByParentOrgId(orgId);
        if (!_.isNil(organization)) {
            var shiftSetting = this.getNightShiftSettingForOrganization(organization.id);
            if (!_.isNil(shiftSetting)) {
                nightShiftPrimary = shiftSetting.enabled;
            }
        }
        return nightShiftPrimary;
    };
    ScheduleEntryManagementService.prototype.getNightShiftSetting = function (orgLevelId) {
        var nightShiftPrimary = false;
        var orgLevel = _.find(this.employeeDepartmentsOrgLevels, function (o) { return o.id === orgLevelId; });
        if (_.isNil(orgLevel)) {
            return false;
        }
        var organization = this.getOrganizationByOrgLevel(orgLevel.parentId);
        if (!_.isNil(organization)) {
            var shiftSetting = this.getNightShiftSettingForOrganization(organization.id);
            if (!_.isNil(shiftSetting)) {
                nightShiftPrimary = shiftSetting.enabled;
            }
        }
        return nightShiftPrimary;
    };
    ScheduleEntryManagementService.prototype.getShiftDefinitionDates = function (definition, dateOn) {
        var timeString;
        var startMoment;
        var endMoment;
        timeString = moment(definition.start, appConfig.militaryTimeFormat).format(appConfig.timeFormat);
        startMoment = moment(dateOn);
        this.dateTimeService.setTimeToMoment(startMoment, timeString);
        timeString = moment(definition.end, appConfig.militaryTimeFormat).format(appConfig.timeFormat);
        endMoment = moment(dateOn);
        this.dateTimeService.setTimeToMoment(endMoment, timeString);
        return { start: startMoment, end: endMoment };
    };
    ScheduleEntryManagementService.prototype.sortShifts = function (container) {
        container.shifts = _.sortBy(container.shifts, ['shiftStartDate']);
    };
    ScheduleEntryManagementService.prototype.refreshEntry = function (scheduleEntryContainer) {
        var _this = this;
        this.onOverlapState$.next(false);
        this.onDuplicateState$.next(false);
        _.each(scheduleEntryContainer.shifts, function (shift) {
            shift.hasOverlap = false;
            shift.hasDuplicate = false;
        });
        var shiftRefreshResult = 0; // 0 - skipped, 1- refreshed, 2 - stop refresh
        _.each(scheduleEntryContainer.shifts, function (shift) {
            if (shiftRefreshResult !== 2) {
                shiftRefreshResult = _this.refreshScheduleEntryShift(shift);
            }
        });
        this.checkChanges();
    };
    ScheduleEntryManagementService.prototype.refreshScheduleEntryShift = function (shift) {
        var _this = this;
        // if no shift selected. can't update anything;
        if (!shift.scheduleEntryShift.shift)
            return 0;
        // check for duplicates
        var duplicatesResult = this.checkDuplicatedShift(shift);
        if (duplicatesResult) {
            if (!this.duplicateShown) {
                this.duplicateShown = true;
                this.modalService.globalAnchor.openInfoDialog('Duplicated Shift', "The shift (" + shift.scheduleEntryShift.shift.name + ") used twice.", function () {
                    _this.duplicateShown = false;
                });
            }
            this.m_OnDuplicateState.next(true);
            return 2;
        }
        // check overlaping of shift before all calculations
        var overlapState = this.checkOverlapingOfShift(shift);
        // if has overlaps exiting. will refresh whole entry again.
        if (overlapState !== 0 /* NO_OVERLAPS */) {
            if (overlapState === 2 /* FATAL */) {
                this.onOverlapState$.next(true);
            }
            return 2;
        }
        if (shift.hasScheduleAbsence) {
            if (shift.scheduleEntryShift.scheduleAbsence) {
                _.noop();
            }
        }
        // calculate lateness
        if (shift.scheduleEntryShift.late)
            this.calculateLateInterval(shift);
        // check and adjust overtime
        if (shift.scheduleEntryShift.overtime) {
            this.calculateOvertimeHours(shift.scheduleEntryShift.overtime);
        }
        // calculate shift hours
        this.calculatePaidHours(shift);
        this.calculateUnpaidHours(shift);
        shift.scheduleEntryShift.hours = shift.shiftHours;
        shift.scheduleEntryShift.startDate = shift.shiftStartDate;
        shift.scheduleEntryShift.endDate = shift.shiftEndDate;
        // update partial absence
        if (shift.scheduleEntryShift.partialAbsence) {
            var shiftStart = moment(shift.shiftStartDate);
            var shiftEnd = moment(shift.shiftEndDate);
            var paStart = moment(shift.scheduleEntryShift.partialAbsence.startDate);
            var paEnd = moment(shift.scheduleEntryShift.partialAbsence.endDate);
            if (paStart.isSame(shiftStart)) {
                // from start
                shift.scheduleEntryShift.startDate = paEnd.toDate();
            }
            else if (paEnd.isSame(shiftEnd)) {
                // to end
                shift.scheduleEntryShift.endDate = paStart.toDate();
            }
            if (shift.shiftHours > shift.scheduleEntryShift.partialAbsence.hours) {
                shift.scheduleEntryShift.hours = shift.shiftHours - shift.scheduleEntryShift.partialAbsence.hours;
            }
            else {
                shift.scheduleEntryShift.hours = 0;
                var diff = Math.abs(shift.shiftHours - shift.scheduleEntryShift.partialAbsence.hours);
                shift.unpaidHours = diff > shift.unpaidHours ? 0 : shift.unpaidHours - diff;
            }
            shift.shiftHours = shift.scheduleEntryShift.hours;
        }
        return 1;
    };
    ScheduleEntryManagementService.prototype.checkDuplicatedShift = function (shiftToCheck) {
        if (!shiftToCheck.scheduleEntryShift.shift)
            return false;
        var duplicates = this.scheduleEntry.shifts.filter(function (shift) {
            if (shift !== shiftToCheck.scheduleEntryShift && shift.shift) {
                return shift.shift.id === shiftToCheck.scheduleEntryShift.shift.id;
            }
            return false;
        });
        if (duplicates.length > 0) {
            return true;
        }
        return false;
    };
    ScheduleEntryManagementService.prototype.checkOverlapingOfShift = function (shiftToCheck) {
        var _this = this;
        if (!shiftToCheck.scheduleEntryShift.shift)
            return 0 /* NO_OVERLAPS */;
        var rangeToCheck = moment.range(shiftToCheck.shiftStartDate, shiftToCheck.shiftEndDate);
        var fatalOverlaps = this.scheduleEntryContainer.shifts
            .filter(function (shift) { return shift.scheduleEntryShift.shift && shift.scheduleEntryShift !== shiftToCheck.scheduleEntryShift &&
            moment.range(shift.shiftStartDate, shift.shiftEndDate).contains(shiftToCheck.shiftStartDate) &&
            moment.range(shift.shiftStartDate, shift.shiftEndDate).contains(shiftToCheck.shiftEndDate); });
        // fatal situation. one shift contains another. we can't adjust it automatically. so asking user resolve it manually
        if (fatalOverlaps && fatalOverlaps.length > 0) {
            if (!this.overlapShown) {
                this.overlapShown = true;
                this.modalService.globalAnchor.openInfoDialog('Schedule overlaping', "The shift overlaps with the existing schedule." +
                    "The shift can not be adjusted to remove the overlap.", function (result) {
                    _this.overlapShown = false;
                });
            }
            shiftToCheck.hasOverlap = true;
            _.each(fatalOverlaps, function (overlapShift) { return overlapShift.hasOverlap = true; });
            return 2 /* FATAL */;
        }
        // searh for overlaping shifts that can be auto-adjusted
        var overlaps = this.scheduleEntryContainer.shifts
            .filter(function (shift) { return shift.scheduleEntryShift.shift && shift !== shiftToCheck && moment.range(shift.shiftStartDate, shift.shiftEndDate).overlaps(rangeToCheck); });
        if (overlaps && overlaps.length > 0) {
            if (!this.overlapShown) {
                this.overlapShown = true;
                this.modalService.globalAnchor.openInfoDialog('Schedule overlaping', "The shift overlaps with the existing schedule." +
                    "The shift being added will be adjusted to remove the overlap.", function (result) {
                    _this.removeOverlap(shiftToCheck, overlaps);
                    _this.overlapShown = false;
                });
            }
            return 1 /* ADJUSTABLE */;
        }
        return 0 /* NO_OVERLAPS */;
    };
    ScheduleEntryManagementService.prototype.removeOverlap = function (shiftToFix, overlaps) {
        _.forEach(overlaps, function (shift) {
            if (shiftToFix.shiftStartDate < shift.shiftStartDate) {
                shiftToFix.shiftEndDate = new Date(shift.shiftStartDate.getTime());
            }
            else {
                shiftToFix.shiftStartDate = new Date(shift.shiftEndDate.getTime());
            }
        });
        this.m_OnOverlapState.next(false);
        this.refreshEntry(this.scheduleEntryContainer);
    };
    ScheduleEntryManagementService.prototype.calculateUnpaidHours = function (container) {
        container.unpaidHours = this.getUnpaidTime(container);
    };
    ScheduleEntryManagementService.prototype.calculatePaidHours = function (container) {
        var diff = moment.range(container.shiftStartDate, container.shiftEndDate).diff();
        var duration = moment.duration(diff);
        var minutes = duration.asMinutes();
        var hours = Math.round((minutes / 60) * 100) / 100; //rounding to 2 decimals
        hours = hours - container.unpaidHours;
        if (hours < 0)
            hours = 0;
        container.scheduleEntryShift.hours = hours;
        container.shiftHours = hours;
    };
    ScheduleEntryManagementService.prototype.calculateShiftEndTime = function (container) {
        if (container.shiftStartDate) {
            var totalHours = container.shiftHours + container.unpaidHours;
            container.shiftEndDate = this.calculateEndTime(container.shiftStartDate, totalHours);
        }
    };
    ScheduleEntryManagementService.prototype.getUnpaidTime = function (shift) {
        var unpaidHours = 0;
        var startMoment = moment(shift.shiftStartDate);
        var endMoment = moment(shift.shiftEndDate);
        if (endMoment.isAfter(startMoment)) {
            var totalMs = endMoment.diff(startMoment);
            var paidTimeMs = this.hours2Ms(shift.shiftHours);
            unpaidHours = (totalMs - paidTimeMs);
            if (unpaidHours > 0) {
                unpaidHours = this.ms2Hours(unpaidHours);
                unpaidHours = Math.round(unpaidHours * 100) / 100; // rounding to 2 decimals
            }
            else {
                unpaidHours = 0;
            }
        }
        return unpaidHours;
    };
    ScheduleEntryManagementService.prototype.calculatePartialAbsenceStartTime = function (absence) {
        if (absence) {
            var endMoment = moment(absence.endDate);
            var startMoment = endMoment.add(-1 * absence.hours, 'hours');
            absence.startDate = startMoment.toDate();
        }
    };
    ScheduleEntryManagementService.prototype.calculatePartialAbsenceEndTime = function (absence) {
        if (absence) {
            var startMoment = moment(absence.startDate);
            var endMoment = startMoment.add(absence.hours, 'hours');
            absence.endDate = endMoment.toDate();
        }
    };
    ScheduleEntryManagementService.prototype.calculatePartialAbsenceHours = function (absence) {
        if (absence) {
            var hours = this.ms2Hours(moment(absence.endDate).diff(absence.startDate));
            hours = Math.round(hours * 100) / 100;
            absence.hours = hours;
        }
    };
    ScheduleEntryManagementService.prototype.calculateOvertimeHours = function (overtime) {
        if (overtime) {
            var diff = moment.range(overtime.startDate, overtime.endDate).diff();
            var duration = moment.duration(diff);
            var minutes = duration.asMinutes();
            var hours = Math.round((minutes / 60) * 100) / 100;
            overtime.duration = hours;
        }
    };
    ScheduleEntryManagementService.prototype.calculateLateTimeStamp = function (shift) {
        if (shift && shift.scheduleEntryShift.late && shift.scheduleEntryShift.late.lateInterval) {
            var date = moment(shift.shiftStartDate).add(shift.scheduleEntryShift.late.lateInterval, 'minutes').toDate();
            shift.scheduleEntryShift.late.arrivalDate = date;
        }
    };
    ScheduleEntryManagementService.prototype.calculateLateInterval = function (shift) {
        if (shift.scheduleEntryShift.late) {
            if (shift.scheduleEntryShift.late.arrivalDate) {
                var diff = moment.range(shift.shiftStartDate, shift.scheduleEntryShift.late.arrivalDate).diff();
                var duration = moment.duration(diff);
                var minutes = duration.asMinutes();
                shift.scheduleEntryShift.late.lateInterval = minutes;
            }
            else {
                shift.scheduleEntryShift.late.lateInterval = 0;
            }
        }
    };
    ScheduleEntryManagementService.prototype.ms2Hours = function (ms) {
        return ms / 1000 / 60 / 60;
    };
    ScheduleEntryManagementService.prototype.hours2Ms = function (hours) {
        return hours * 1000 * 60 * 60;
    };
    ScheduleEntryManagementService.prototype.calculateEndTime = function (start, hours) {
        var endDate;
        var milliseconds = this.hours2Ms(hours);
        endDate = new Date(milliseconds + start.getTime());
        return endDate;
    };
    ScheduleEntryManagementService.prototype.getDurationMs = function (startDate, endDate) {
        var duration = 0;
        var startMoment = moment(startDate);
        var endMoment = moment(endDate);
        if (endMoment.isAfter(startMoment)) {
            duration = endMoment.diff(startMoment);
        }
        return duration;
    };
    ScheduleEntryManagementService.prototype.compareEntry = function (entry) {
        var json = JSON.stringify(entry);
        return json !== this.originalScheduleEntry;
    };
    // private fixPositions(): void {
    //   if (this.scheduleEntry.defaultPositionId > 0) {
    //     this.defaultPosition = _.find(this.employeePositions, { 'id': this.scheduleEntry.defaultPositionId });
    //   }
    //   // backend bug. orglevel id not populated in position definition inside schedule entry shift
    //   _.each(this.scheduleEntry.shifts, (shift: ScheduleEntryShift) => {
    //     if (shift.position && shift.position.orgLevelId === null) {
    //       shift.position = _.find(this.employeePositions, { id: shift.position.id });
    //     }
    //   });
    // }
    // private fixShifts(shifts: ShiftDefinition[]): void {
    //   // full shift object needed for some calculations, so search in lookup and replace it
    //   if (this.scheduleEntry.defaultShiftId > 0) {
    //     this.defaultShift = _.find(shifts, { 'id': this.scheduleEntry.defaultShiftId });
    //   }
    //   _.each(this.scheduleEntry.shifts, (shift: ScheduleEntryShift) => {
    //     if (shift.shift) {
    //       shift.shift = _.find(shifts, { 'id': shift.shift.id });
    //       if (shift.shift) {
    //         let definition: ShiftDefinition = shift.shift;
    //         let dates: { start: moment.Moment, end: moment.Moment } = this.getShiftDefinitionDates(definition, shift);
    //         let startMoment: moment.Moment = dates.start;
    //         let endMoment: moment.Moment = dates.end;
    //         //shift.definitionStartDate = startMoment.toDate();
    //         //shift.definitionEndDate = endMoment.toDate();
    //       }
    //     }
    //   });
    // }
    ScheduleEntryManagementService.prototype.setUnpaidTime = function (container) {
        var definition = _.find(container.shifts, function (s) { return container.scheduleEntryShift.shift && (s.id === container.scheduleEntryShift.shift.id); });
        if (definition && definition.lunchDurationDate) {
            container.unpaidHours = moment(definition.lunchDurationDate).hours() + moment(definition.lunchDurationDate).minutes() / 60;
        }
        else {
            container.unpaidHours = 0;
        }
    };
    ScheduleEntryManagementService.prototype.addLookupValueIfDoesntContain = function (matchField, sourceObject, destinationLookup) {
        var foundValue = _.find(destinationLookup, function (item) { return item[matchField] === sourceObject[matchField]; });
        if (!foundValue) {
            destinationLookup.push(sourceObject);
        }
    };
    ScheduleEntryManagementService.prototype.filterAvailablePositionsByDate = function (positions, date) {
        return _.filter(positions, function (pos) {
            return pos.startDate <= date && (!pos.endDate || pos.endDate >= date);
        });
    };
    ScheduleEntryManagementService.prototype.getOrganizationByOrgLevel = function (orgLevelId) {
        return _.find(this.organizations, function (o) { return o.orgLevelId === orgLevelId; });
    };
    ScheduleEntryManagementService.prototype.getOrganizationByParentOrgId = function (orgId) {
        return _.find(this.organizations, function (o) { return o.id === orgId; });
    };
    ScheduleEntryManagementService.prototype.getNightShiftSettingForOrganization = function (organizationId) {
        var setting = _.find(this.appSettings.nightShiftSettings, function (s) { return s.organizationId === organizationId; });
        if (_.isNil(setting)) {
            setting = _.find(this.appSettings.nightShiftSettings, function (s) { return s.organizationId === 0; });
        }
        return setting;
    };
    ScheduleEntryManagementService.prototype.postRequestStaffing = function (dailyOpenShiftDetailsData, orgLevelId) {
        var _this = this;
        var promise = this.scheduleEntryApiService.saveShiftsEntry(dailyOpenShiftDetailsData, orgLevelId).then(function (response) {
            _this.notificationsService.success('success', 'Successfully submitted shift request');
            return response;
        });
        return promise;
    };
    ScheduleEntryManagementService.prototype.getAgencyPostions = function (customerId, partnerId) {
        var promise = this.scheduleEntryApiService.getAgencyPoistionsData(customerId, partnerId)
            .then(function (response) {
            return response;
        });
        return promise;
    };
    return ScheduleEntryManagementService;
}());
export { ScheduleEntryManagementService };
