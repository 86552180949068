import { Directive, Input, Output, ElementRef, Renderer2, EventEmitter, AfterViewInit } from '@angular/core';
@Directive({
    selector: '[slxElementCreatedSelector]',
})
export class SlxElementCreatedSelectorDirective implements AfterViewInit {
    @Input()
    public emitEvent: boolean = true;
    public selectedElement: Element;
    public renderer: Renderer2;
    @Output()
    public onCreated: EventEmitter<HTMLElement>;
    private elementRef: ElementRef;

    constructor(elementRef: ElementRef, renderer: Renderer2) {
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.onCreated = new EventEmitter<HTMLElement>();
    }

    public ngAfterViewInit(): void {
        let el: HTMLElement = this.elementRef.nativeElement;
        if (this.emitEvent) this.onCreated.emit(el);
    }
}
