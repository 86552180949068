import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, QueryList, ElementRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { GroupResult, orderBy, groupBy, State, filterBy } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { IndividualScheduleManagementService } from '../../../services/individual-schedule/individual-schedule-management.service';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { unsubscribe, mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { ActivatedRoute } from '@angular/router';
var IndividualScheduleEmployeeGridComponent = /** @class */ (function () {
    function IndividualScheduleEmployeeGridComponent(router, individualScheduleManagementService, modalService) {
        var _this = this;
        this.router = router;
        this.subscriptions = {};
        this.individualScheduleManagementService = individualScheduleManagementService;
        this.modalService = modalService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'employee.name', dir: 'asc' }];
        this.gridState.itemKey = 'id';
        this.gridEventSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (selected) {
            _this.selectionChange(_.first(selected));
        });
        this.employeeSubscription = this.individualScheduleManagementService.onSelectionChanged$.subscribe(function (emp) {
            _this.selectedEmployee = emp;
            //if (!this.selectedEmployee) {
            //  this.grid.selectable = false;
            //  setTimeout(() => { this.grid.selectable = true; }, 10);
            // }
        });
        this.restoreEmpSelectionSubscription = this.individualScheduleManagementService.onRestoreEmpSelection$.subscribe(function (args) {
            _this.selectedEmployeeId = args.employeeId;
        });
        this.onSearchEmpSelected = this.individualScheduleManagementService.subscribeToOnSearchEmpSelected(function (id) {
            var emp = _.find(_this.gridState.view.data, function (x) { return x.employee.id === id; });
            if (_.isObject(emp)) {
                _this.selectionChange(emp);
                _this.gridState.selectRowByKey(emp.id, true);
                _this.scrollToEmp(emp.id);
            }
        });
        this.subscriptions.orgLevel = this.orgLevel$.subscribe(function (o) {
            _this.selectedEmployee = null;
        });
    }
    Object.defineProperty(IndividualScheduleEmployeeGridComponent.prototype, "records", {
        set: function (orgLevelEmployees) {
            this.orgLevelEmployees = orgLevelEmployees;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleEmployeeGridComponent.prototype, "filter", {
        set: function (filter) {
            if (this.filterString === filter) {
                return;
            }
            this.filterString = filter;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    IndividualScheduleEmployeeGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.router.queryParams.subscribe(function (params) {
            if (params.empID) {
                _this.selectedRouteEmpId = JSON.parse(params.empID);
            }
        });
    };
    IndividualScheduleEmployeeGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualScheduleEmployeeGridComponent.prototype.selectionChange = function (selection) {
        var _this = this;
        if (this.individualScheduleManagementService.isDirty) {
            this.modalService.globalAnchor.openConfirmDialog('Confirmation', 'You have unsaved changes. Are you sure to change employee?', function (result) {
                if (result) {
                    _this.individualScheduleManagementService.onEmployeeSelected(selection);
                }
                else {
                    if (_this.selectedEmployee) {
                        _this.gridState.selectRowByKey(_this.selectedEmployee.id, true);
                        _this.scrollToEmp(_this.selectedEmployee.id);
                    }
                }
            });
        }
        else if (selection) {
            this.individualScheduleManagementService.onEmployeeSelected(selection);
        }
    };
    IndividualScheduleEmployeeGridComponent.prototype.restoreEmployee = function () {
        var _this = this;
        if (_.size(this.gridState.view.data) <= 0) {
            this.individualScheduleManagementService.onEmployeeSelected(null);
        }
        if (!this.selectedEmployee) {
            this.gridState.selectFirstRow();
        }
        else {
            var emp = _.find(this.gridState.view.data, function (x) { return x.employee.id === _this.selectedEmployee.id; });
            if (_.isObject(emp)) {
                this.gridState.selectRowByKey(emp.id);
                this.scrollToEmp(emp.id);
            }
            else {
                this.gridState.selectFirstRow();
            }
        }
    };
    IndividualScheduleEmployeeGridComponent.prototype.scrollToEmp = function (empId) {
        var link = _.find(this.employeeLinks.toArray(), function (e) { return e.nativeElement.id === "Employee_Id_" + empId; });
        if (_.isObjectLike(link)) {
            link.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    IndividualScheduleEmployeeGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.orgLevelEmployees) {
            this.gridState.view = null;
            return;
        }
        var filteredRecords;
        if (!this.filterString || this.filterString.length === 0) {
            filteredRecords = this.orgLevelEmployees;
        }
        else {
            filteredRecords = _.filter(this.orgLevelEmployees, function (record) {
                return _.includes(record.employee.name.toLowerCase(), _this.filterString.toLowerCase());
            });
        }
        var sortedRecords = orderBy(filteredRecords, this.gridState.state.sort);
        var filtered = filterBy(sortedRecords, this.gridState.state.filter);
        var groupedRecords = groupBy(filtered, this.gridState.state.group);
        this.gridState.view = {
            data: groupedRecords,
            total: groupedRecords.length
        };
        if (this.selectedRouteEmpId) {
            this.selectedEmployee = this.gridState.view.data.find(function (employee) { return employee.employee.id === _this.selectedRouteEmpId; });
        }
        setTimeout(function () { _this.restoreEmployee(); }, 10);
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleEmployeeGridComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeGridComponent.prototype, "gridEventSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeGridComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeGridComponent.prototype, "employeeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeGridComponent.prototype, "restoreEmpSelectionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeGridComponent.prototype, "onSearchEmpSelected", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], IndividualScheduleEmployeeGridComponent.prototype, "subscriptions", void 0);
    return IndividualScheduleEmployeeGridComponent;
}());
export { IndividualScheduleEmployeeGridComponent };
