import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { WcOSHAReport, WcReport, WcLookupsContainer } from '../../../models';
import { Lookup } from '../../../../../organization';
import { OrgLevel } from '../../../../../state-model/models';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { PopoverContentComponent } from '../../../../../common/components';
import { ReportFormBase } from '../report-form-base';
import { FormGroup } from '@angular/forms';
import { wcConfig } from '../../../workers-compensation.config';

@Component({
    moduleId: module.id,
    selector: 'slx-wc-osha-report',
    templateUrl: 'wc-osha-report.component.html',
    styleUrls: ['wc-osha-report.component.scss']
})

export class WcOshaReportComponent extends ReportFormBase implements OnInit, OnDestroy {

    public get osha(): WcOSHAReport {
        return this.report ? this.report.oshaReport : null;
    }

    public get minimumDate(): Date {
        return this.m_minimumDate;
    }

    @ViewChild('oshaReport', { static: true })
    public oshaReportForm: FormGroup;

    public employeesLookup: Lookup;
    public orgLevel: OrgLevel;

    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};

    public commentsCount: number = 0;
    public maxDiff: number;

    private m_minimumDate: Date;

    constructor(private management: WCReportManagementService) {
        super();
        this.m_minimumDate = moment(wcConfig.settings.minDateString).toDate();
    }

    public ngOnInit(): void {

        this.subscriptions.load = this.management.onLoaded$
            .combineLatest(this.management.wcLookupsLoaded$)
            .subscribe(async ([report, lookups]: [WcReport, WcLookupsContainer]) => {
                this.lookups = lookups;
                this.report = report;
                this.updateCommentsCount();
            });

        this.subscriptions.value = this.oshaReportForm.statusChanges.subscribe(x => {
            if (this.oshaReportForm.dirty && this.oshaReportForm.touched) {
                this.management.onChangeNotify();
            }
            this.management.setOshaFormValidity(this.oshaReportForm.valid);
            this.updateCommentsCount();
        });
    }

    public ngOnDestroy(): void { }

    public async onClickDisable(acceptPopover: PopoverContentComponent): Promise<void> {
        acceptPopover.hide();
        this.management.changeLoading(true);
        try {
            await this.management.disableOshaReport();
            this.management.changeLoading(false);
        } catch (e) {
            console.error(e);
        }
    }

    public onClickCancel(acceptPopover: PopoverContentComponent): void {
        acceptPopover.hide();
    }

    public onisRestrictedDutyChanged(enable: boolean): void {
        if (this.osha) {
            this.osha.restrictedDutyDaysNum = 0;
        }
    }

    public onwasInHospitalChanged(enable: boolean): void {
        if (this.osha) {
            this.osha.admittedHospitalDaysNum = 0;
        }
    }

    public onisLostDaysChanged(enable: boolean): void {
        if (this.osha) {
            if (enable) {
                this.osha.lostDaysStartDate = moment().toDate();
                this.osha.lostDaysEndDate = null;
                this.updateDays();
            } else {
                this.osha.lostDaysStartDate = null;
                this.osha.lostDaysEndDate = null;
                this.osha.lostDaysCount = 0;
            }
        }
    }

    public updateDays(): void {
        this.updateDiffValue();
        this.osha.lostDaysCount = this.maxDiff;
    }

    public updateDiff(days: number): void {
        if (this.osha) {
            if (this.osha.isLostDays) {
                if (!this.osha.lostDaysStartDate) {
                    this.osha.lostDaysStartDate = moment().toDate();
                }
                this.osha.lostDaysCount = days;
                this.updateDiffValue();
            }
        }
    }

    private updateDiffValue() {
        if (this.osha.lostDaysStartDate && this.osha.lostDaysEndDate) {
            let diff = Math.abs(moment(this.osha.lostDaysEndDate, 'YYYY-MM-DD').startOf('day').diff(moment(this.osha.lostDaysStartDate, 'YYYY-MM-DD').startOf('day'), 'days')) + 1;
            this.maxDiff = diff;
        }
        else {
            this.maxDiff = 0;
        }
    }

    private updateCommentsCount() {
        let commentsCount = !_.isNil(this.osha.otherComments) && _.size(this.osha.otherComments) > 0 ? 1 : 0;
        if (commentsCount !== this.commentsCount) {
            this.commentsCount = commentsCount;
        }
    }

    onKeydown(value: any) {
      if (!((value.keyCode > 95 && value.keyCode < 106)
        || (value.keyCode > 47 && value.keyCode < 58)
        || value.keyCode == 8)) {
          return false;
      }
    }
}
