import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ManagementBaseService, UserSettingsService } from '../../../core/services/index';
import { ClientMessageApiService } from '../client-message/client-message-api.service';
var ClientMessageManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(ClientMessageManagementService, _super);
    function ClientMessageManagementService(apiService, userSettingsService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.userSettingsService = userSettingsService;
        _this.messages = [];
        return _this;
    }
    ClientMessageManagementService.prototype.readMessage = function (msg) {
        var settings = this.userSettingsService.get();
        var readClientMessages = this.getReadClientMessages(settings);
        if (msg.id !== 0) {
            readClientMessages[msg.id.toString(10)] = true;
        }
        else {
            readClientMessages[msg.messageHash] = true;
        }
        this.userSettingsService.set(settings);
        var filteredMessages = this.filterRead(settings.readClientMessages, this.messages);
        this.onLoaded(filteredMessages);
    };
    ClientMessageManagementService.prototype.loadMessages = function (orglevelId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getMessages(orglevelId)
            .then(function (messages) {
            _this.messages = messages;
            var settings = _this.userSettingsService.get();
            var readClientMessages = _this.getReadClientMessages(settings);
            var filteredMessages = _this.filterRead(readClientMessages, _this.messages);
            _this.onLoaded(filteredMessages);
            _this.onLoadStatusChanged(true);
        })
            .catch(function (e) {
            _this.onLoadStatusChanged(true);
        });
    };
    ClientMessageManagementService.prototype.filterRead = function (read, messages) {
        return _.filter(messages, function (msg) {
            return msg.id !== 0 ? !read[msg.id] : !read[msg.messageHash];
        });
    };
    ClientMessageManagementService.prototype.getReadClientMessages = function (settings) {
        if (!settings.readClientMessages) {
            settings.readClientMessages = {};
        }
        return settings.readClientMessages;
    };
    return ClientMessageManagementService;
}(ManagementBaseService));
export { ClientMessageManagementService };
