/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-timeclocks-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../time-and-attendance/components/timeclock-review/timeclock-item/timeclock-item.component.ngfactory";
import * as i3 from "../../../../time-and-attendance/components/timeclock-review/timeclock-item/timeclock-item.component";
import * as i4 from "../../../../common/services/modal/modal.service";
import * as i5 from "../../../../organization/services/time-clock/time-clock-data.service";
import * as i6 from "../../../../time-and-attendance/services/signalR/ta-signalr-service";
import * as i7 from "../../../../core/services/session/session.service";
import * as i8 from "../../../directives/timeclocks-view-all.directive";
import * as i9 from "@angular/router";
import * as i10 from "../../../services/application/application.service";
import * as i11 from "@angular/common";
import * as i12 from "./header-timeclocks-list.component";
var styles_HeaderTimeclocksListComponent = [i0.styles];
var RenderType_HeaderTimeclocksListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderTimeclocksListComponent, data: {} });
export { RenderType_HeaderTimeclocksListComponent as RenderType_HeaderTimeclocksListComponent };
function View_HeaderTimeclocksListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-timeclock-item", [], null, null, null, i2.View_TimeclockItemComponent_0, i2.RenderType_TimeclockItemComponent)), i1.ɵdid(2, 114688, null, 0, i3.TimeclockItemComponent, [i4.ModalService, i5.TimeclockDataService, i6.TaSignalrService, i7.SessionService], { timeclockDailySummary: [0, "timeclockDailySummary"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HeaderTimeclocksListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "view-all-btn"], ["slxTimeclockViewAll", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onViewAllClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i8.TimeClockViewAllDirective, [i9.Router, i9.ActivatedRoute, i10.ApplicationService], null, null), (_l()(), i1.ɵted(-1, null, ["View All"]))], null, null); }
export function View_HeaderTimeclocksListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "header-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "header-list-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "header-list-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderTimeclocksListComponent_1)), i1.ɵdid(5, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "header-line"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderTimeclocksListComponent_2)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "header-list-arrow"], ["x-arrow", ""]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayedRecords; _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.totalRecordsCount > ((_co.displayedRecords == null) ? null : _co.displayedRecords.length)); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_HeaderTimeclocksListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-header-timeclocks-list", [], null, null, null, View_HeaderTimeclocksListComponent_0, RenderType_HeaderTimeclocksListComponent)), i1.ɵdid(1, 49152, null, 0, i12.HeaderTimeclocksListComponent, [], null, null)], null, null); }
var HeaderTimeclocksListComponentNgFactory = i1.ɵccf("slx-header-timeclocks-list", i12.HeaderTimeclocksListComponent, View_HeaderTimeclocksListComponent_Host_0, { displayedRecords: "displayedRecords", totalRecordsCount: "totalRecordsCount" }, { close: "close" }, []);
export { HeaderTimeclocksListComponentNgFactory as HeaderTimeclocksListComponentNgFactory };
