import { appCommonRoutes, employeeSectionRoutes, userProfileRoutes } from './portal.common.routes';
import { ApplicationContainerComponent, LeftSidebarComponent } from './components/index';
export var pbjRoutes = [
    {
        path: '',
        component: ApplicationContainerComponent,
        children: [
            {
                path: '',
                outlet: 'leftSidebar',
                component: LeftSidebarComponent
            },
            {
                path: '',
                redirectTo: 'pbj_exports',
                pathMatch: 'full'
            }
        ].concat(appCommonRoutes)
    }
].concat(employeeSectionRoutes, userProfileRoutes);
