import { IRouteInfo } from './route-info';

export interface ILogiRouteInfo extends IRouteInfo {
  logiComponentId: string;
}

export function isLogiRouteInfo(source: any): source is ILogiRouteInfo {
  return !!source.logiComponentId;
}

