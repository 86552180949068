import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { KendoGridStateHelper, saveEvent, removeEvent, cancelEvent } from '../../../../../common/models/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { ModalService } from '../../../../../common/services/index';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';

import { unsubscribeAll } from '../../../../../core/decorators/index';
import {
  BenefitDetailsOption,
  BenefitDetailsLineStandart,
  BenefitDetailsProviderLineStandartEntity,
  BenefitDetailsEditModes,
  BenefitDetailsBasic,
  BenefitDetailsUserActions,
  BenefitDetailsTier,
  BenefitDetailsFormula,
  BenefitFormulaCalculationType,
  CoverageOptionTypes,
  coverageOptionTypesList
} from '../../../models/index';
import { BenefitDetailsStandartManagementService, BenefitDetailsPermissionService, BenefitDetailsManagementService } from '../../../services/index';
import { CalculationCommonService } from '../../../services/benefit-details/calculation-common.service';

@Component({
  selector: 'slx-benefit-details-coverage-calc-formula-with-option',
  templateUrl: './benefit-details-coverage-calc-formula-with-option.component.html',
  styleUrls: ['./benefit-details-coverage-calc-formula-with-option.component.scss'],
})
export class BenefitDetailsCoverageCalcFormulaWithOptionComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<BenefitDetailsOption>;
  public records: BenefitDetailsOption[] = [];
  public selectedProvidedTier: BenefitDetailsTier;
  public prohibitedCodeValues: string[];
  public prohibitedTypeValues: string[];
  public selectedProviderLine: BenefitDetailsLineStandart;
  public coverageOptionTypes: CoverageOptionTypes[] = coverageOptionTypesList;

  public calculationTypesShort: BenefitDetailsBasic<number, BenefitFormulaCalculationType>[] = [];
  public calculationTypes: BenefitDetailsBasic<number, BenefitFormulaCalculationType>[] = [];

  public empFormula = new BenefitDetailsFormula();
  public empContribution = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
  public empFormulaText = '';
  public empFixed = 100.00;

  public emprFormula = new BenefitDetailsFormula();
  public emprContribution = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
  public emprFormulaText = '';
  public emprFixed = 150.00;

  public covFormula = new BenefitDetailsFormula();
  public coverage = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
  public covFormulaText = '';
  public covFixed = 200.00;
  public covMultiplier = 250.00;
  public covMaxCap = 280.00;

  public canAddCovOptions = false;
  public isEditMode = false;
  public min: number = 0;
  public max: number = 99999999.99;
  public multiplierMax = 100;
  public step = 1;
  public format = 'c2';
  public multiplierFormat = 'n2';
  public multiplierMaxValues: number[];
  public hasEnrolledEmpTierLevel: boolean = false;

  @ViewChild('kendoGrid', { static: true })
  public kendoGrid: GridComponent;

  private pageSize = 50;

  public get isRowEditing(): boolean {
    return _.isObjectLike(this.gridState.editedRecord);
  }

  public get isFormulaEditable(): boolean {
    return this.isEditMode && this.сanEditFormula;
  }

  public get isEmpFixedCalcType(): boolean {
    return this.empContribution.name === this.empFormula.calculationType && this.empFormula.isFixedCalcType;
  }

  public get isEmpFormulaCalcType(): boolean {
    return this.empContribution.name === this.empFormula.calculationType && this.empFormula.isFormulaCalcType;
  }

  public get isEmprFixedCalcType(): boolean {
    return this.emprContribution.name === this.emprFormula.calculationType && this.emprFormula.isFixedCalcType;
  }

  public get isEmprFormulaCalcType(): boolean {
    return this.emprContribution.name === this.emprFormula.calculationType && this.emprFormula.isFormulaCalcType;
  }

  public get isCovFormulaCalcType(): boolean {
    return this.coverage.name === this.covFormula.calculationType && this.covFormula.isFormulaCalcType;
  }

  public get isCovFixedCalcType(): boolean {
    return this.coverage.name === this.covFormula.calculationType && this.covFormula.isFixedCalcType;
  }

  public get isCovMultiplierCalcType(): boolean {
    return this.coverage.name === this.covFormula.calculationType && this.covFormula.isMultiplierCalcType;
  }

  public get isShownFormula(): boolean {
    return this.tierIsDraft || !this.tierIsDraft && this.showFormula;
  }

  public get isCovAnyValueCalcType(): boolean {
    return this.coverage.name === this.covFormula.calculationType && this.covFormula.isAnyValueMaxCapCalcType;
  }

  public get isMaxCapLessThanMultiplier(): boolean {
    if (this.covFormula.isMultiplierCalcType && !_.isNull(this.covMaxCap) && !_.isNull(this.covMultiplier)) {
      return _.lt(this.covMaxCap, this.covMultiplier);
    }
  }

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private сanEditFormula: boolean = false;
  private showFormula: boolean = false;
  private tierIsDraft: boolean = false;

  private loadedEmpsFor = { orgLevelId: null, tierId: null, date: null };
  
  constructor(
    private modalService: ModalService,
    private commonManService: BenefitDetailsManagementService,
    private manService: BenefitDetailsStandartManagementService,
    private permissionService: BenefitDetailsPermissionService
  ) {
    this.calculationTypes = this.manService.getFormulaTypes();
    this.calculationTypesShort = this.calculationTypes.slice(0, 2);
    this.multiplierMaxValues = [500, 1000, 2000, 5000, 10000];

    this.gridState = new KendoGridStateHelper<BenefitDetailsOption>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'type', dir: 'asc' }];
    this.gridState.state.take = this.pageSize;
  }

  public ngOnInit() {

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.subscriptions.onEdit = this.gridState.onEdit$
      .subscribe((option: BenefitDetailsOption) => {
        this.permissionService.toggleCanAddCovOptions(false);
      });

    this.subscriptions.onCancel = this.gridState.onCancel$
      .subscribe(() => {
        this.permissionService.toggleCanAddCovOptions(true);
      });

    this.subscriptions.onSave = this.gridState.onSave$
      .subscribe((event: saveEvent<BenefitDetailsOption>) => {
        this.permissionService.toggleCanAddCovOptions(true);
        if (event.isNew) {
          this.addOption(event.dataItem);
        } else {
          this.updateOption(event.dataItem);
        }
      });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());

    this.subscriptions.providerLine = this.commonManService
      .subscribeToSelectProviderLine((pl: BenefitDetailsProviderLineStandartEntity) => {
        this.selectedProviderLine = pl.current;
        this.refreshGrid();
      });

    this.subscriptions.editMode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => {
        this.isEditMode = v.providerInfo;
        this.recalculateProhibitedValues();
        this.permissionService.toggleCanAddCovOptions(this.isEditMode);
        if (!this.isEditMode) {
          this.gridState.closeEditor(this.kendoGrid);
        }
      });

    this.subscriptions.actions = this.permissionService
      .subscribeToUserActions((v: BenefitDetailsUserActions) => {
        this.сanEditFormula = v.сanEditFormula;
        this.canAddCovOptions = v.canAddCovOptions;
      });

    this.subscriptions.state = this.manService
      .subscribeToChangeState((v: boolean) => (this.showFormula = v));

    this.subscriptions.coverageOption = this.manService
      .subscribeToAddNewCoverageOption((o: BenefitDetailsOption) => this.addNewOption(o));

    this.subscriptions.providerTier = this.manService
      .subscribeToSelectProviderTier((v: BenefitDetailsTier) => {
        this.hasEnrolledEmpTierLevel = false;
        this.selectedProvidedTier = v;
        this.gridState.closeEditor(this.kendoGrid);
        this.filterOutRecords();
        this.permissionService.toggleCanAddCovOptions(true);

        const { empFormula, erFormula, coverageFormula } = v;
        this.tierIsDraft = v.isDraft;
        this.empFormula = empFormula;
        this.empFormulaText = empFormula.expression || '';
        this.empFixed = _.isFinite(empFormula.fixedAmount) ? empFormula.fixedAmount : null;

        this.emprFormula = erFormula;
        this.emprFormulaText = erFormula.expression || '';
        this.emprFixed = _.isFinite(erFormula.fixedAmount) ? erFormula.fixedAmount : null;

        this.covFormula = coverageFormula;
        this.covFormulaText = coverageFormula.expression || '';
        this.covFixed = _.isFinite(coverageFormula.fixedAmount) ? coverageFormula.fixedAmount : null;
        this.covMultiplier = coverageFormula.multipler || null;
        this.covMaxCap =  _.isFinite(coverageFormula.maxCap) ? coverageFormula.maxCap : null;
        this.setDropdownValues();

      });


    this.subscriptions.benefitenrolledInfo = this.manService
      .subscribeToBenefitEnrolledEmployeesChanged((hasEnrollEmp: boolean) => {
        this.hasEnrolledEmpTierLevel = !this.tierIsDraft && hasEnrollEmp;
      });

  }

  public ngOnDestroy(): void {
  }
  public isCurrentRowEditing(option: BenefitDetailsOption): boolean {
    return this.isRowEditing && this.gridState.editedRecord.id === option.id;
  }

  public addNewOption(option: BenefitDetailsOption): void {
    this.gridState.closeEditor(this.kendoGrid);
    this.kendoGrid.addRow(option);
    this.gridState.editedRecord = option;
    this.permissionService.toggleCanAddCovOptions(false);
  }

  public addOption(option: BenefitDetailsOption): void {
    this.manService.saveCoverageOption(option, false);
    this.filterOutRecords();
  }

  public updateOption(option: BenefitDetailsOption): void {
    this.manService.updateCoverageOption(option);
  }

  public removeOption(option: BenefitDetailsOption): void {
    this.manService.removeCoverageOption(option);
    this.filterOutRecords();
  }

  public recalculateProhibitedValues(): void {
    const opts: BenefitDetailsOption[] = this.records; 
    if (opts) {
      this.prohibitedCodeValues = opts.map(o => o.code);
      this.prohibitedTypeValues = opts.map(o => o.type);
    } else {
      this.prohibitedCodeValues = [];
      this.prohibitedTypeValues = [];
    }
  }

  private filterOutRecords(): void {
    this.records = _.filter(this.selectedProvidedTier.options, (o: BenefitDetailsOption) => !o.stateIsDeleted);
    this.refreshGrid();
  }

  private refreshGrid(): void {
    this.recalculateProhibitedValues();
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  public onAdd() {
    this.manService.addNewCoverageOption();
  }

  public onDeleteOption(dataItem: BenefitDetailsOption) {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    let message = (this.hasEnrolledEmpTierLevel) ?
      'Are you sure you want to remove selected coverage option? This will remove this plan for all enrolled employees.' :
      'Are you sure you want to remove selected coverage option? This will have no effect on enrolled employees.';
    ConfirmDialog2Component.openDialog(
      'Delete Coverage Option',
      message,
      this.modalService,
      (isDelete: boolean) => {
        if (isDelete) {
          this.removeOption(dataItem);
          this.permissionService.toggleCanAddCovOptions(true);
        }
      },
      options);
  }

  public onChangeEmpContributionType(): void {
    if (this.empFormula.calculationType !== this.empContribution.name) {
      this.empFormula.calculationType = this.empContribution.name;
      this.manService.updateTierEmployeeFormula(this.empContribution.name, this.empFixed, this.empFormulaText);
    }
  }

  public onChangeEmpContribution(): void {
    if (_.isString(this.empContribution.name)) {
      this.manService.updateTierEmployeeFormula(this.empContribution.name, this.empFixed, this.empFormulaText);
    }
  }

  public onChangeEmprContributionType(): void {
    if (this.emprFormula.calculationType !== this.emprContribution.name) {
      this.emprFormula.calculationType = this.emprContribution.name;
      this.manService.updateTierEmployerFormula(this.emprContribution.name, this.emprFixed, this.emprFormulaText);
    }
  }

  public onChangeEmprContribution(): void {
    if (_.isString(this.emprContribution.name)) {
      this.manService.updateTierEmployerFormula(this.emprContribution.name, this.emprFixed, this.emprFormulaText);
    }
  }

  public onChangeCoverageType(): void {
    if (this.covFormula.calculationType !== this.coverage.name) {
      this.covFormula.calculationType = this.coverage.name;
      this.manService.updateTierCoverageFormula(
        this.coverage.name,
        this.covFixed,
        this.covFormulaText,
        this.covMultiplier,
        this.covMaxCap
      );
    }
  }

  public onChangeCoverage(): void {
    if (_.isString(this.coverage.name)) {
      this.manService.updateTierCoverageFormula(
        this.coverage.name,
        this.covFixed,
        this.covFormulaText,
        this.covMultiplier,
        this.covMaxCap
      );
    }
  }

  private setDropdownValues(): void {
    this.empContribution = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
    this.emprContribution = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
    this.coverage = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);

    let empContr = null;
    let emprContr = null;
    let cov = null;

    _.forEach(this.calculationTypes, ct => {
      if (_.isNil(empContr) && ct.name === this.empFormula.calculationType) {
        this.empContribution = empContr = ct;
      }
      if (_.isNil(emprContr) && ct.name === this.emprFormula.calculationType) {
        this.emprContribution = emprContr = ct;
      }
      if (_.isNil(cov) && ct.name === this.covFormula.calculationType) {
        this.coverage = cov = ct;
      }
    });
  }

  public get isTypeReadOnly(): boolean {
    return !this.isEditMode || this.hasEnrolledEmpTierLevel;
  }
}
