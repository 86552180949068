import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../../app.config';
import { unsubscribe, destroyService } from '../../../../../core/decorators/index';
import { PbjReconciliationManagementService } from '../../../services/index';
var PbjReconciliationEmployeeComponent = /** @class */ (function () {
    function PbjReconciliationEmployeeComponent(managementService, router, route, cdr) {
        this.router = router;
        this.route = route;
        this.cdr = cdr;
        this.appConfig = appConfig;
        this.managementService = managementService;
        this.currentEmpId = 0;
        this.isLoading = true;
    }
    PbjReconciliationEmployeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.managementService.init(this.router, this.route, this.expanded = true);
        this.reconSubscription = this.managementService.subscribeToReconEmployee(function (data) {
            _this.currentEmpId = data.employeeId;
            _this.currentEmpName = data.employeeName;
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$.subscribe(function (state) {
            _this.isLoading = state;
            _this.cdr.detectChanges();
        });
        this.orgLevelSubscription = this.managementService.subscribeToOrgLevel(function (orgLevel) {
            _this.orgLevel = orgLevel;
            _this.cdr.detectChanges();
        });
    };
    PbjReconciliationEmployeeComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.managementService.destroy();
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PbjReconciliationManagementService)
    ], PbjReconciliationEmployeeComponent.prototype, "managementService", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationEmployeeComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationEmployeeComponent.prototype, "reconSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationEmployeeComponent.prototype, "loadStatusSubscription", void 0);
    return PbjReconciliationEmployeeComponent;
}());
export { PbjReconciliationEmployeeComponent };
