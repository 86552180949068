import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { ResponseBody } from '../../../core/models/api/response-body';

import { FieldsMeta } from '../../../core/models/index';

import { configurationConfig } from '../../configuration.config';
import { PositionsConfigurationMapService } from './positions-configuration-map.service';

import { IPositionDTO, PositionModel, ConfOrganization, PositionsActions } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class PositionsConfigurationApiService {
  constructor(private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: PositionsConfigurationMapService,
    private cacheUtilService: CacheUtilService) {
  }

  public getPositions(orgLevelId: number): Promise<{ actions: PositionsActions, positions: PositionModel[] }> {
    const url: string = this.getOrgLevelApiRoot(orgLevelId);

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<any[], Meta>(request).then((response: ResponseBody<IPositionDTO[], Meta>) => {
      const actions = this.mapService.mapPositionsActions(response.meta as FieldsMeta);
      const positions = this.mapService.mapPositions(response.data);
      return { actions, positions };
    });
  }

  public savePosition(orgLevelId: number, position: PositionModel): Promise<PositionModel> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${position.id}`;

    let body: any = this.mapService.reverseMapPosition(position);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    return this.apiUtilService.request<IPositionDTO, Meta>(request)
      .then((response: ResponseBody<IPositionDTO, Meta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapPosition(response.data);
      });
  }

  public addPosition(orgLevelId: number, position: PositionModel): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}`;

    let body: any = this.mapService.reverseMapPosition(position);
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any[], Meta>) => {
        this.cacheUtilService.clear();
        return response;
      });
  }

  public deletePosition(orgLevelId: number, position: PositionModel, reassignToPosition?: number, effectiveDate?: Date): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${position.id}`;
    let params: HttpParams;

    if (reassignToPosition !== undefined && effectiveDate !== undefined) {
      params = this.urlParamsService.convertToParams({
        reassignedPositionId: reassignToPosition,
        effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate)
      });
    }

    const request: HttpRequest<any> = new HttpRequest('DELETE', url, {
      params: params
    });
    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.cacheUtilService.clear();
        return response;
      });
  }

  public getCopyPositionsConfiguration(orgLevelId: number): Promise<ConfOrganization[]> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.configuration.positions.copyConfiguration}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return this.mapService.mapOrganizations(response.data.organizations);
      });
  }

  public copyPositions(sourceDepartmentId: number, targetDepartmentId: number, postiondIds: number[]): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(sourceDepartmentId)}/${configurationConfig.api.configuration.positions.copy}`;

    let body: any = postiondIds;
    const request: HttpRequest<any> = new HttpRequest('POST', url, body, {
      params: this.urlParamsService.convertToParams({
        sourceDepartmentId: sourceDepartmentId,
        targetDepartmentId: targetDepartmentId
      })
    });

    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.cacheUtilService.clear();
        return response;
      });
  }

  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}
/${configurationConfig.api.configuration.root}
/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}
/${configurationConfig.api.configuration.positions.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }

}
