import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { OrgLevel } from '../../../../state-model/models/index';
import { FileService } from '../../../../common/services/index';

import { WcCreateOshaRosterState, WcIncidentRosterContainer, WcViewList, WcIncidentRosterEntry, IWcGenerateOsha301APDF, IWcGenerateOsha300APDF, CreateOsha300AFormDialogOptions, ICreateOsha300AFormDialogOptions } from '../../models/index';
import { WcCommonManagementService } from './wc-common-management.service';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { WcRosterApiService } from './wc-roster-api.service';
import { DateRange, IDateRange, ResponseBody } from '../../../../core/models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { WcRosterToolbarService } from './wc-roster-toolbar.service';
import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';


@Injectable()
export class WcCreateOshaFormService {
  private loading$ = new ReplaySubject<boolean>(1);
  private establishmentSize$ = new ReplaySubject<CreateOsha300AFormDialogOptions>(1);
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(private commonService: WcCommonManagementService, private fileService: FileService,
    private apiService: WcRosterApiService, private wcRosterToolbarService: WcRosterToolbarService) { }

  public init(componentId: string): void {
    this.commonService.init(componentId, 'columnsVisibility', WcViewList.CreateOshaRkForms);
    this.subscribeToToolbarService();
    this.subscribeToOrgLevelChanges();
    this.restoreState();
  }

  public destroy(): void {
    this.commonService.destroy();
    this.loading$.complete();
    this.orgLevelChanged$.complete();
    this.establishmentSize$.complete();
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    return this.commonService.subscribeToOrgLevel(callback);
  }

  public subscribeToExport(callback: (isPDF: boolean) => void): Subscription {
    return this.commonService.subscribeToExport(callback);
  }

  public subscribeToChangeState(callback: (s: WcCreateOshaRosterState) => void): Subscription {
    return this.commonService.subscribeToChangeState(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    return this.commonService.subscribeToLoading(callback);
  }

  public subscribeToLoadedRecords(callback: (v: WcIncidentRosterContainer) => void): Subscription {
    return this.commonService.subscribeToLoadedRecords(callback);
  }

  public openEditReportDialog(reportId: number): void {
    this.commonService.openEditReportDialog(reportId);
  }

  private subscribeToToolbarService(): void {
    this.commonService.subscribeToToolbarService();
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscribeToOrgLevelChangesFromComparisons();
    this.commonService.subscribeToOrgLevelChanges();
  }

  public subscribeToestablishmentSize(callback: (v: CreateOsha300AFormDialogOptions) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.establishmentSize$.subscribe(callback);
  }

  private restoreState(): void {
    const state = new WcCreateOshaRosterState();
    state.createColumns();
    this.commonService.restoreState(state);
  }

  public subscribeToGenerateOSHAForm300A(callback: (action: string) => void): Subscription {
    return this.wcRosterToolbarService.subscribeToGenerateOSHAForm300A(callback);
  }

  public subscribeToDateRange(callback: (r: DateRange) => void): Subscription {
    return this.wcRosterToolbarService.subscribeToDateRange(callback);
  }


  public subscribeToInitDateRange(callback: (r: DateRange) => void): Subscription {
    return this.wcRosterToolbarService.subscribeToInitDateRange(callback);
  }

  public subscribeToOrgLevelChangesFromComparisons(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe(async (orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;

        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        if (this.orgLevel.relatedItemId && this.orgLevel.type == "Organization") {
          this.getEstablimentSize();
        }
      });
  }

  public generateOshaExcel300(orgLevel: number, date: number, records: Array<WcIncidentRosterEntry>) {
    this.commonService.setLoading(true);
    let InjuryIds = records.map(a => a.id);

    this.apiService.generateOshaExcel(orgLevel, date, InjuryIds)
      .then((fileBlobResponse: FileBlobResponse) => {
        this.fileService.saveToFileSystem(fileBlobResponse.blob, fileBlobResponse.file);
        this.commonService.setLoading(false);
      }).catch(error => {
        if (error) {
          this.commonService.setLoading(false);
        }
      });
  }

  public generateOshaPDF300(orgLevel: number, sDate: Date, eDate: Date, records: Array<WcIncidentRosterEntry>) {
    this.commonService.setLoading(true);
    let InjuryIds = records.map(a => a.id);
    let data: IWcGenerateOsha300APDF = {
      orgLevelId: orgLevel,
      formName: 'Osha 300',
      startDate: dateTimeUtils.convertToDtoString(sDate),
      endDate: dateTimeUtils.convertToDtoString(eDate),
      recordIds: InjuryIds,
    }
    this.apiService.generateOshaPDF300(data)
      .then((fileBlobResponse: FileBlobResponse) => {
        this.fileService.openOrSavePdf(fileBlobResponse);
        this.commonService.setLoading(false);
      }).catch(error => {
        if (error) {
          this.commonService.setLoading(false);
        }
      });
  }

  public generateOshaPDF301(orgLevel: number, records: Array<WcIncidentRosterEntry>) {
    this.commonService.setLoading(true);
    let InjuryIds = records.map(a => a.id);
    let data: IWcGenerateOsha301APDF = {
      orgLevelId: orgLevel,
      formName: 'Osha 301',
      recordIds: InjuryIds,
    }
    this.apiService.generateOshaPDF301(data)
      .then((fileBlobResponse: FileBlobResponse) => {
        this.fileService.openOrSavePdf(fileBlobResponse);
        this.commonService.setLoading(false);
      }).catch(error => {
        if (error) {
          this.commonService.setLoading(false);
        }
      });
  }

  public getEstablimentSize() {
    this.loading$.next(true);
    this.apiService.getEstablismentSize(this.orgLevel.relatedItemId).then((value: CreateOsha300AFormDialogOptions) => {
      this.establishmentSize$.next(value);
      this.loading$.next(false);
    }).catch(error => {
      if (error) {
        this.loading$.next(false);
      }
    });
  }


  public async updateEstablismentSize(orgLevelRelatedId: number, value: CreateOsha300AFormDialogOptions) {
    this.loading$.next(true);
    this.establishmentSize$.next(value);
    const size: number = value.establishmentSize;
    return this.apiService.updateEstablismentSize(orgLevelRelatedId, size).catch(error => {
      if (error) {
        this.establishmentSize$.next({ establishmentSize: null });
        this.loading$.next(false);
      }
    });
  }


}
