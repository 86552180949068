export * from './timecard-shifts-list.component';

import { TimeCardShiftsListComponent } from './timecard-shifts-list.component';

export const shitsListComponents: any = [
    TimeCardShiftsListComponent
];

export const exportShiftsListComponents: any = [
    TimeCardShiftsListComponent
];
