
import { GenericListDefinition } from './generic-list-definition';
import { GenericFieldDefinition } from './generic-field-definition';
import { GenericFieldValue } from './generic-field-value';

export class GenericList {
  public listDefinition: GenericListDefinition;
  public rows: GenericRow[];
  public selectable: boolean;
}

export class GenericRow {
  public fields: StringMap<GenericField>;
  public selectable: boolean;
  public selected: boolean;
  public index: number;
}

export class GenericField {
  public field: GenericFieldDefinition;
  public value: GenericFieldValue;
}
