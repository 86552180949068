export * from './benefit-console.component';
export * from './../benefit-console/benefit-list/benefit-recent-activity/benefit-recent-activity-component';
export * from './../benefit-console/benefit-list/benefit-expiration-date/benefit-expiration-date-component';
export * from './../benefit-console/benefit-plan-types/benefit-type-section/benefit-plan-type.component';
export * from './../benefit-console/benefit-plan-types/benefit-type-section-grid/benefit-type-section-grid.component';
export * from './../benefit-console/benefit-payroll-mapping-dialog/benefit-payroll-mapping-dialog.component';
export * from './../benefit-console/benefit-payroll-existing-mapping-dialog/benefit-payroll-existing-mapping-dialog.component';

import { BenefitConsoleComponent } from './benefit-console.component';
import { BenefitsRecentActivityComponent } from './../benefit-console/benefit-list/benefit-recent-activity/benefit-recent-activity-component';
import { BenefitsByExpirationDateComponent } from './../benefit-console/benefit-list/benefit-expiration-date/benefit-expiration-date-component';
import { BenefitPlanTypesComponent } from './benefit-plan-types/benefit-plan-types.component';
import { BenefitPlanTypeComponent } from './../benefit-console/benefit-plan-types/benefit-type-section/benefit-plan-type.component';
import { BenefitTypeSectionGridComponent } from './../benefit-console/benefit-plan-types/benefit-type-section-grid/benefit-type-section-grid.component';
import { BenefitPayrollMappingDialogComponent } from './../benefit-console/benefit-payroll-mapping-dialog/benefit-payroll-mapping-dialog.component';
import { BenefitPayrollExistingMappingDialogComponent } from './../benefit-console/benefit-payroll-existing-mapping-dialog/benefit-payroll-existing-mapping-dialog.component';

export const benefitConsoleComponents: any[] = [
  BenefitConsoleComponent,
  BenefitsRecentActivityComponent,
  BenefitsByExpirationDateComponent,
  BenefitPlanTypesComponent,
  BenefitPlanTypeComponent,
  BenefitTypeSectionGridComponent,
  BenefitPayrollMappingDialogComponent,
  BenefitPayrollExistingMappingDialogComponent
];
