import { EmployeeSectionsBase } from '../employee-sections-base';
import { IEmployeeSectionsSubsectionContainer } from '../index';

import {
  IEmployeeSubsectionEnrollment,
  EmployeeSubsectionEnrollment,
  IEmployeeSubsectionDependents,
  EmployeeSubsectionDependents,
  EmployeeSubsectionBenefitClasses,
  IEmployeeSubsectionBenefitClasses
} from './index';

export interface IEmployeeSectionsBenefitsManagement {
  canMapPayroll: boolean;
  benefitsEnrollmentHistory: IEmployeeSectionsSubsectionContainer<IEmployeeSubsectionEnrollment>;
  benefitEmpDependents: IEmployeeSectionsSubsectionContainer<IEmployeeSubsectionDependents>;
  benefitClass: IEmployeeSectionsSubsectionContainer<IEmployeeSubsectionBenefitClasses>;
}

export class EmployeeSectionsBenefitsManagement extends EmployeeSectionsBase {
  public canMapPayroll: boolean;
  public enrollmentSubsection: EmployeeSubsectionEnrollment;
  public dependentsSubsection: EmployeeSubsectionDependents;
  public benefitClassSubSection: EmployeeSubsectionBenefitClasses;
}
