<slx-checkbox-button className="slx-middle-label" [placeholder]="option.essOption.description"
  [(ngModel)]="option.value" (ngModelChange)="valueChanged()" fieldName="option{{option.id}}" name="option{{option.id}}"
  [readonly]="readonly || isGoLiteTemplateOptions "></slx-checkbox-button>

<div class="textarea-position" *ngIf="option.essOption.name === 'TimecardSignoff' && option.value">
  <textarea class="timecard-signoff"
            name="certifyTimecardMessage"
            [(ngModel)]="template.certifyTimecardMessage"
            [readonly]="readonly || !option.value"
            maxlength="500">
  </textarea>
  <span class="char-count-alignment">{{500-template.certifyTimecardMessage.length}}/500</span>
</div>

<div class="child-options" *ngIf="option.childs && option.childs.length > 0">
  <ng-container *ngFor="let child of option.childs">
    <slx-ess-template-option [template]="template" [option]="child" [readonly]="readonly || !option.value"
                             (valueChange)="childValueChange()"></slx-ess-template-option>
  </ng-container>
</div>
