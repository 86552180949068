import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { dateTimeUtils } from '../../../../common/utils/index';
import { UrlParamsService, ApiUtilService, OdataApiService } from '../../../../common/services/index';
import { Meta, ResponseBody, IFilteredItems, ServerQuery } from '../../../../core/models/index';
//import { AuditTrailActionList, IAuditTrailActionList, AuditTrailAction } from '../../models/index';
import { AuditTrailEmployeeAction, IAuditTrailEmployeeAction, IAuditTrailConfigAction, AuditTrailConfigAction } from '../../models/index';
import { AuditTrailMapService } from './audit-trail-map.service';
import { auditTrailConfig } from '../../audit-trail.config';

@Injectable()
export class AuditTrailApiService {

  constructor(private apiUtilService: ApiUtilService, private odataApiService: OdataApiService, private urlParamsService: UrlParamsService, private mapService: AuditTrailMapService) {
  }

  public getEmployeeActions(query: ServerQuery, orgLevelId: number, isGridFilter: boolean): Promise<IFilteredItems<AuditTrailEmployeeAction>> {
    let promise: Promise<IFilteredItems<AuditTrailEmployeeAction>> = this.odataApiService.getData<AuditTrailEmployeeAction>('AuditViewRecord', query,
      (dto: IAuditTrailEmployeeAction) => { return this.mapService.mapAuditTrailEmployeeAction(dto); }, { orgLevelId: orgLevelId, isGridFilter: isGridFilter });
    return promise;
  }

  public getEmployeeActionsForEffectiveDate(query: ServerQuery, orgLevelId: number, isGridFilter: boolean, exactEffectiveDate: Date): Promise<IFilteredItems<AuditTrailEmployeeAction>> {
    let promise: Promise<IFilteredItems<AuditTrailEmployeeAction>> = this.odataApiService.getData<AuditTrailEmployeeAction>('AuditViewRecord', query,
      (dto: IAuditTrailEmployeeAction) => { return this.mapService.mapAuditTrailEmployeeAction(dto); }, { orgLevelId: orgLevelId, isGridFilter: isGridFilter, exactEffectiveDate: dateTimeUtils.convertToDtoDateTimeString(exactEffectiveDate) });
    return promise;
  }

  public getNonEmployeeActions(query: ServerQuery, orgLevelId: number, isGridFilter: boolean): Promise<IFilteredItems<AuditTrailConfigAction>> {
    let promise: Promise<IFilteredItems<AuditTrailConfigAction>> = this.odataApiService.getData<AuditTrailConfigAction>('AuditView2Record', query,
      (dto: IAuditTrailConfigAction) => { return this.mapService.mapAuditTrailConfigAction(dto); }, { orgLevelId: orgLevelId, isGridFilter: isGridFilter });
    return promise;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${auditTrailConfig.api.core.root}/${auditTrailConfig.api.core.audit.root}/${auditTrailConfig.api.core.audit.auditTrail.root}`;
  }
}
