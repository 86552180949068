<div class="slx-high-box">
  <div>
    <p>
      Total PTO in the year {{year}}: {{this.gridData?.totalVacations}}
    </p>
    <p>
      Total PTO hours in the year {{year}}: {{this.gridData?.totalVacationsHours}}
    </p>
  </div>
   <div class="slx-high-box__body">
    <kendo-grid class="slx-full-height" [data]="gridView" [scrollable]="'scrollable'" [sortable]="{ mode: 'multiple' }"
      [sort]="gridState.sort" (sortChange)="sortChange($event)">
      <kendo-grid-column title="Date" field="date">
        <ng-template kendoGridHeaderTemplate>
          Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.date | amDateFormat: appConfig.dateFormat}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Code" field="scheduleAbsence.code">
        <ng-template kendoGridHeaderTemplate>
          Code
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.scheduleAbsence.code}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Hours" field="hours">
        <ng-template kendoGridHeaderTemplate>
          Hours
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.hours | number: ".2-2"}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
   </div>
</div>
