import * as _ from 'lodash';
import { OnInit, EventEmitter } from '@angular/core';
import { appConfig } from '../../../../app.config';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { AgencyMapping, AgencyModel } from '../../../models/index';
import { AgenciesMapService } from '../../../services/index';
var AgencyMappingsComponent = /** @class */ (function () {
    function AgencyMappingsComponent(mappingService) {
        this.prohibitedNameValues = [];
        this.appConfig = appConfig;
        this.mappingService = mappingService;
        this.gridState = new KendoGridStateHelper();
        this.onSaveAgency = new EventEmitter();
        this.onRemoveAgency = new EventEmitter();
    }
    Object.defineProperty(AgencyMappingsComponent.prototype, "agencyMappings", {
        set: function (value) {
            this.m_agencyMappings = value;
            this.closeEditor();
            this.cancelRemoveRequest();
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    AgencyMappingsComponent.prototype.ngOnInit = function () {
        this.refreshGrid();
    };
    AgencyMappingsComponent.prototype.toggleEditMode = function () {
        this.closeEditor();
        this.editMode = !this.editMode;
    };
    AgencyMappingsComponent.prototype.addNewAgency = function () {
        this.addHandler();
    };
    AgencyMappingsComponent.prototype.addHandler = function () {
        var _this = this;
        this.closeEditor();
        var record = new AgencyMapping();
        var agency = new AgencyModel();
        agency.lastUpdateDate = new Date();
        record.agency = agency;
        record.organizations = [];
        if (this.isOrganization && this.orglevelId !== undefined && _.size(this.organizations) > 0) {
            var selectedOrganization = _.find(this.organizations, function (o) { return o.orgLevelId === _this.orglevelId; });
            if (_.isObjectLike(selectedOrganization)) {
                record.organizations = [selectedOrganization];
            }
        }
        this.addNewMode = true;
        this.editedRecord = record;
        this.grid.addRow(this.editedRecord);
        this.updateProhibitedNameValues(record);
    };
    AgencyMappingsComponent.prototype.editHandler = function (sender) {
        this.closeEditor();
        this.editedRecord = this.mappingService.cloneAgencyMapping(sender.dataItem);
        this.editedRowIndex = sender.rowIndex;
        this.grid.editRow(this.editedRowIndex);
        this.updateProhibitedNameValues(sender.dataItem);
    };
    AgencyMappingsComponent.prototype.updateProhibitedNameValues = function (mapping) {
        if (mapping && this.m_agencyMappings) {
            var values_1 = [];
            _.each(this.m_agencyMappings, function (m) {
                if (mapping.agency.name !== m.agency.name) {
                    values_1.push(m.agency.name);
                }
            });
            this.prohibitedNameValues = values_1;
        }
    };
    AgencyMappingsComponent.prototype.closeEditor = function () {
        this.grid.closeRow(this.editedRowIndex);
        this.addNewMode = false;
        this.editedRowIndex = undefined;
        this.editedRecord = undefined;
    };
    AgencyMappingsComponent.prototype.cancelHandler = function () {
        this.closeEditor();
        this.refreshGrid();
    };
    AgencyMappingsComponent.prototype.removeHandler = function (event) {
        if (!event.dataItem) {
            return;
        }
        this.editMode = false;
        this.removingItem = event.dataItem;
        this.onRemoveAgency.emit(event.dataItem);
    };
    AgencyMappingsComponent.prototype.saveHandler = function (event) {
        this.onSaveAgency.emit(this.editedRecord);
    };
    AgencyMappingsComponent.prototype.completeRemoveRequest = function () {
        var _this = this;
        _.remove(this.m_agencyMappings, function (r) {
            return r.uniqId === _this.removingItem.uniqId;
        });
        this.refreshGrid();
        this.removingItem = null;
        this.editMode = true;
    };
    AgencyMappingsComponent.prototype.cancelRemoveRequest = function () {
        this.removingItem = null;
        this.refreshGrid();
    };
    AgencyMappingsComponent.prototype.completeSaveRequest = function (mapping) {
        var _this = this;
        if (this.addNewMode) {
            if (mapping && mapping.agency) {
                this.editedRecord.agency.id = mapping.agency.id;
                this.editedRecord.agency.lastUpdateDate = mapping.agency.lastUpdateDate;
                this.editedRecord.agency.lastUpdateName = mapping.agency.lastUpdateName;
            }
            if (this.m_agencyMappings.length > 0) {
                this.m_agencyMappings.push(this.editedRecord);
                this.editedRecord.uniqId = _.maxBy(this.m_agencyMappings, function (a) { return a.uniqId; }).uniqId + 1;
            }
            else {
                this.m_agencyMappings.push(this.editedRecord);
                this.editedRecord.uniqId = 0;
            }
        }
        else {
            if (this.editedRecord) {
                var editedItem = _.find(this.m_agencyMappings, function (r) {
                    return r.uniqId === _this.editedRecord.uniqId;
                });
                this.mappingService.updateAgencyMapping(mapping, editedItem);
                editedItem.uniqId = this.editedRecord.uniqId;
            }
        }
        this.closeEditor();
        this.refreshGrid();
        this.editMode = true;
    };
    AgencyMappingsComponent.prototype.refreshGrid = function () {
        if (!this.m_agencyMappings) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_agencyMappings, this.gridState.state);
    };
    return AgencyMappingsComponent;
}());
export { AgencyMappingsComponent };
