
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../authentication/services/authentication/authentication.service';
import { OAuth2AuthorizeRequest } from '../../../authentication/models';
import { Meta, ResponseBody } from '../../../core/models';

@Component({
    moduleId: module.id,
    selector: 'slx-authorizesso',
    templateUrl: 'authorizesso.component.html',
    styleUrls: ['authorizesso.component.scss']
  })

  export class AuthorizessoComponent implements OnInit {

    constructor(  private router: Router,
      private route: ActivatedRoute,
      private authenticationService: AuthenticationService,) {

      }

    ngOnInit() 
    {

        let state:string = "";
        let clientId:string = "";
        let redirectUri:string = "";
        let responseType:string = "";
        let scope:string = "";

        this.route.queryParams.forEach(param => {
            state = param["state"];
            clientId = param["client_id"];
            redirectUri = param["redirect_uri"];
            responseType = param["response_type"];
            scope = param["scope"];
        });

        let oAuth2AuthorizeRequest : OAuth2AuthorizeRequest = {
          clientId: clientId,
          redirectUri: redirectUri,
          responseType: responseType,
          scope: scope,
          state:state
        }

        console.log(oAuth2AuthorizeRequest);

        this.authenticationService.getAuthorizationCode(oAuth2AuthorizeRequest)
            .then((authResponse: any) => {
              console.log(authResponse);
              window.location.href = authResponse.data;
            })
            .catch((error: ResponseBody<any, Meta>) =>
            console.log(error) 
            );
    }
   
  }
  