import * as _ from 'lodash';
import { OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
var StarRatingComponent = /** @class */ (function () {
    function StarRatingComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.ratingChange = new EventEmitter();
        this.m_maxValue = 5;
        this.m_rating = 0;
    }
    Object.defineProperty(StarRatingComponent.prototype, "rating", {
        get: function () {
            return this.m_rating;
        },
        set: function (value) {
            this.m_rating = value;
            if (!this.ratingData) {
                this.createRatingData();
            }
            this.updateRatingData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StarRatingComponent.prototype, "maxValue", {
        get: function () {
            return this.m_maxValue;
        },
        set: function (value) {
            this.m_maxValue = value;
            this.createRatingData();
        },
        enumerable: true,
        configurable: true
    });
    StarRatingComponent.prototype.ngOnInit = function () {
        if (!this.ratingData) {
            this.createRatingData();
        }
        this.updateRatingData();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    StarRatingComponent.prototype.onItemClick = function (item) {
        if (this.readonly) {
            return;
        }
        if (item.isSelected) {
            this.rating = _.clamp(item.index - 1, 1, this.m_maxValue);
        }
        else {
            this.rating = item.index;
        }
        this.ratingChange.next(this.m_rating);
    };
    StarRatingComponent.prototype.createRatingData = function () {
        var _this = this;
        var values = [];
        _.times(this.m_maxValue, function (value) {
            values.push({
                index: value + 1,
                isSelected: (value + 1) <= _this.m_rating
            });
        });
        this.ratingData = values;
    };
    StarRatingComponent.prototype.updateRatingData = function () {
        var _this = this;
        _.each(this.ratingData, function (value) {
            value.isSelected = value.index <= _this.m_rating;
        });
    };
    return StarRatingComponent;
}());
export { StarRatingComponent };
