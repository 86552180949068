export * from './payroll-export/payroll-export.component';
export * from './payroll-export-header/payroll-export-header.component';
export * from './payroll-export-grid/payroll-export-grid.component';
export * from './payroll-export-section/payroll-export-section.component';
export * from './payroll-export-mobile-list/payroll-export-mobile-list.component';

import { PayrollExportComponent } from './payroll-export/payroll-export.component';
import { PayrollExportHeaderComponent } from './payroll-export-header/payroll-export-header.component';
import { PayrollExportGridComponent } from './payroll-export-grid/payroll-export-grid.component';
import { PayrollExportSectionComponent } from './payroll-export-section/payroll-export-section.component';
import { PayrollExportMobileListComponent } from './payroll-export-mobile-list/payroll-export-mobile-list.component';

export const payrollExportComponents: any[] = [
  PayrollExportComponent,
  PayrollExportHeaderComponent,
  PayrollExportGridComponent,
  PayrollExportSectionComponent,
  PayrollExportMobileListComponent
];
