export interface IGenericFieldList {
  items: IGenericFieldRecord[];
}

export interface IGenericFieldRecord {
  fields: IGenericFieldValue[];
}

export interface IGenericFieldValue {
  fieldName: string;
  value: any;
}

export class GenericFieldValue {
  public fieldName: string;
  public value: any;
}
