<button class="slx-button slx-with-icon slateclock" (click)="addGroup()" [disabled]="disableSalteButton">
  <i class="slx-slate-button_icon far fa-plus"></i>
  Add question group
  <span *ngIf="disableSalteButton" 
  slxTooltip="You are not an authorized user to add groups" 
  tipPosition="bottom"></span>
</button>

<button class="slx-button slx-with-icon legacyclock" (click)="addLegacyGroup()" [disabled]="disableLegacyButton" 
*ngIf="isLegacy | async">
  <i class="slx-legacy-button_icon far fa-plus"></i>
  Legacy clock question group
  <span *ngIf="isConfigure == false"
  slxTooltip="You are not an authorized user to add legacy clock group" 
  tipPosition="bottom"></span>
</button>
