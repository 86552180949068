import * as _ from 'lodash';
export function hasClass(el: any, className: string): boolean { return _.includes(el.className, className); }

export function isChildOfElement(el: any, element: any): boolean {
  while (el && el.parentElement) {
    if (el.parentElement === element) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
}

export function isChildOf(el: any, className: string, element?: any): boolean {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      if(!element) {
        return true;
      }
      return isChildOfElement(el.parentElement, element);
    }
    el = el.parentElement;
  }
  return false;
}

export function eq(s1: string, s2: string): boolean { return s1.toLowerCase() === s2.toLowerCase(); }

export function closest(el: any, nodeName: string): any {
  while (el && el.parentElement) {
    if (eq(el.nodeName, nodeName) || eq(el.parentElement.nodeName, nodeName)) {
      return el.parentElement;
    }
    el = el.parentElement;
  }
  return null;
}


export function parentElementByTag(element: any, tagName?: string): any {
  if (!element || !element.parentNode) {
    return null;
  }

  let parent = element.parentNode;
  while (true) {
    if (!tagName || parent.tagName.toLowerCase() === tagName) {
      return parent;
    }
    parent = parent.parentNode;
    if (!parent) {
      return null;
    }
  }
}

export function parentElementByClass(element: any, className?: string): any {
  if (!element || !element.parentNode) {
    return null;
  }

  let parent = element.parentNode;
  while (true) {
    if (!className || hasClass(parent, className)) {
      return parent;
    }
    parent = parent.parentNode;
    if (!parent) {
      return null;
    }
  }
}
