/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tree-node-children.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tree-node/tree-node.component.ngfactory";
import * as i3 from "../tree-node/tree-node.component";
import * as i4 from "@angular/common";
import * as i5 from "./tree-node-children.component";
var styles_TreeNodeChildrenComponent = [i0.styles];
var RenderType_TreeNodeChildrenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TreeNodeChildrenComponent, data: {} });
export { RenderType_TreeNodeChildrenComponent as RenderType_TreeNodeChildrenComponent };
function View_TreeNodeChildrenComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-tree-node-component", [], null, null, null, i2.View_TreeNodeComponent_0, i2.RenderType_TreeNodeComponent)), i1.ɵdid(1, 49152, null, 0, i3.TreeNodeComponent, [], { node: [0, "node"], index: [1, "index"], templates: [2, "templates"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.index; var currVal_2 = _co.templates; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_TreeNodeChildrenComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeNodeChildrenComponent_3)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.node.children; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TreeNodeChildrenComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[2, "tree-children", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeNodeChildrenComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.node.children; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
function View_TreeNodeChildrenComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_TreeNodeChildrenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeNodeChildrenComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_TreeNodeChildrenComponent_4)), i1.ɵdid(3, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(4, { $implicit: 0, node: 1, templates: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.node.isExpanded && !_co.templates.treeNodeChildrenTemplate); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 4, 0, _co.node, _co.node, _co.templates); var currVal_2 = _co.templates.treeNodeChildrenTemplate; _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
export function View_TreeNodeChildrenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-tree-node-children-component", [], null, null, null, View_TreeNodeChildrenComponent_0, RenderType_TreeNodeChildrenComponent)), i1.ɵdid(1, 49152, null, 0, i5.TreeNodeChildrenComponent, [], null, null)], null, null); }
var TreeNodeChildrenComponentNgFactory = i1.ɵccf("slx-tree-node-children-component", i5.TreeNodeChildrenComponent, View_TreeNodeChildrenComponent_Host_0, { node: "node", templates: "templates" }, {}, []);
export { TreeNodeChildrenComponentNgFactory as TreeNodeChildrenComponentNgFactory };
