/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aca-export.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./aca-export-section/aca-export-section.component.ngfactory";
import * as i3 from "./aca-export-section/aca-export-section.component";
import * as i4 from "../../services/aca-export/aca-export-management.service";
import * as i5 from "../../../../organization/services/lookup/lookup-api.service";
import * as i6 from "../../../../common/services/modal/modal.service";
import * as i7 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i8 from "../../../../common/components/spinner/spinner.component";
import * as i9 from "@angular/common";
import * as i10 from "../../../../common/services/state-management/state-management.service";
import * as i11 from "../../../../common/services/component-state/component-state-storage.service";
import * as i12 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i13 from "../../../../common/services/aca-export/aca-export-event.service";
import * as i14 from "../../../../core/services/throttling/throttling.service";
import * as i15 from "../../../../core/services/session/session.service";
import * as i16 from "../../services/aca-export/aca-export-api.service";
import * as i17 from "../../services/aca-export/aca-export-map.service";
import * as i18 from "../../../../common/services/file/file.service";
import * as i19 from "../../../../core/services/user-activity/user-activity.service";
import * as i20 from "../../../../core/services/token-validity/token-validity.service";
import * as i21 from "../../services/aca-report/aca-report-api.service";
import * as i22 from "./aca-export.component";
var styles_AcaExportComponent = [i0.styles];
var RenderType_AcaExportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AcaExportComponent, data: {} });
export { RenderType_AcaExportComponent as RenderType_AcaExportComponent };
function View_AcaExportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "aca-export-data-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-aca-export-section", [["style", "width: 100%"]], null, null, null, i2.View_AcaExportSectionComponent_0, i2.RenderType_AcaExportSectionComponent)), i1.ɵdid(2, 245760, null, 0, i3.AcaExportSectionComponent, [i4.AcaExportManagementService, i1.ChangeDetectorRef, i5.LookupApiService, i6.ModalService], { configuration: [0, "configuration"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AcaExportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "slx-spinner", [], null, null, null, i7.View_SpinnerComponent_0, i7.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i8.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "div", [["class", "slx-high-box__body slx-main-content-indents aca-export-data-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "aca-export-data-content_header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "header-item group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-building"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "header-item__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Export Name"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "header-item request-status-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-thermometer-three-quarters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "header-item__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Status"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AcaExportComponent_1)), i1.ɵdid(13, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.exportConfigurations; _ck(_v, 13, 0, currVal_1); }, null); }
export function View_AcaExportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-aca-export", [], null, null, null, View_AcaExportComponent_0, RenderType_AcaExportComponent)), i1.ɵprd(512, null, i10.StateManagementService, i10.StateManagementService, [i11.ComponentStateStorageService, i12.ColumnSettingsStorageService]), i1.ɵprd(131584, null, i13.AcaExportEventService, i13.AcaExportEventService, [i14.ThrottlingService, i15.SessionService]), i1.ɵprd(131584, null, i4.AcaExportManagementService, i4.AcaExportManagementService, [i16.AcaExportApiService, i17.AcaExportMapService, i18.FileService, i19.UserActivityService, i20.TokenValidityService, i13.AcaExportEventService, i10.StateManagementService, i21.AcaReportApiService]), i1.ɵdid(4, 245760, null, 0, i22.AcaExportComponent, [i10.StateManagementService, i4.AcaExportManagementService, i1.ChangeDetectorRef, i6.ModalService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var AcaExportComponentNgFactory = i1.ɵccf("slx-aca-export", i22.AcaExportComponent, View_AcaExportComponent_Host_0, {}, {}, []);
export { AcaExportComponentNgFactory as AcaExportComponentNgFactory };
