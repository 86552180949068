<div class="wizard-container">
  <form class="form-horizontal" #personalForm="ngForm">
    <div class="col-lg-3">
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.address.isVisible">
        <slx-input-decorator>
          <input slx-input type="text"
                 [inputPolicy]="addEmployeeRestrictions?.address.Policy"
                 name="address"
                 maxlength="255"
                 placeholder="Address"
                 [(ngModel)]="addEmployeeModel.address">
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.city.isVisible">
        <slx-input-decorator>
          <input slx-input type="text"
                 [inputPolicy]="addEmployeeRestrictions?.city.Policy"
                 name="City"
                 maxlength="50"
                 placeholder="City"
                 [(ngModel)]="addEmployeeModel.city">
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.state.isVisible">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [lookup]="{lookupType: 'state'}"
                              [inputPolicy]="addEmployeeRestrictions?.state.Policy"
                              name="state"
                              placeholder="State"
                              [(ngModel)]="addEmployeeModel.state">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.zipCode.isVisible">
        <slx-input-decorator>
          <slx-zip-input slx-input
                         [inputPolicy]="addEmployeeRestrictions?.zipCode.Policy"
                         name="zip"
                         placeholder="Zip"
                         [(ngModel)]="addEmployeeModel.zipCode">
          </slx-zip-input>
          <span errorMessage for="required"></span>
          <span errorMessage for="min">Min length is 5</span>
          <span errorMessage for="max">Max length is 10</span>
        </slx-input-decorator>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.phone.isVisible">
        <slx-input-decorator>
          <slx-phone-input slx-input
                           [inputPolicy]="addEmployeeRestrictions?.phone.Policy"
                           phone
                           name="phone"
                           placeholder="Phone"
                           [(ngModel)]="addEmployeeModel.phone">
          </slx-phone-input>
          <span errorMessage for="required"></span>
          <span errorMessage for="phone"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.mobilePhone.isVisible">
        <slx-input-decorator>
          <span class="slx-caption-block" *ngIf="!mobileRef.errors && isMessageCenterEnabled">Required for Smartlinx Go</span>      
          <slx-phone-input slx-input
                           [inputPolicy]="addEmployeeRestrictions?.mobilePhone.Policy"
                           phone
                           #mobileRef="ngModel"
                           name="mobilePhone"
                           placeholder="Mobile Phone"
                           [(ngModel)]="addEmployeeModel.mobilePhone"
                           (change)="onMobileNoChange()">
          </slx-phone-input>
          <span errorMessage for="required"></span>
          <span errorMessage for="phone"></span>
        </slx-input-decorator>
        <div *ngIf="isMessageCenterEnabled">
          <slx-checkbox-button class="si-checkbox padt0"
                               [readonly]="!isOptInCheckboxEnabled"
                               [(ngModel)]="addEmployeeModel.optInRequest"
                               placeholder="Automatically send Opt in request"
                               name="optInRequest">
          </slx-checkbox-button>
          <span class="checkbox-label padl10">
            <i class="fa fa-info-circle infoCircleAlgin" aria-hidden="true"><span [slxTooltip]="optInTemplateText" tipPosition="right"></span></i>
          </span>
        </div>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.altPhone.isVisible">
        <slx-input-decorator>
          <slx-phone-input slx-input
                           [inputPolicy]="addEmployeeRestrictions?.altPhone.Policy"
                           phone
                           name="altPhone"
                           placeholder="Alt Phone"
                           [(ngModel)]="addEmployeeModel.altPhone">
          </slx-phone-input>
          <span errorMessage for="required"></span>
          <span errorMessage for="phone"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.email1.isVisible">
        <slx-input-decorator>
          <slx-email-input slx-input
                           [inputPolicy]="addEmployeeRestrictions?.email1.Policy"
                           slx-email
                           name="email1"
                           placeholder="Email 1"
                           [(ngModel)]="addEmployeeModel.email1"
                           [server]="{validationName: emailValidation, parameters: 0, validationAdapter: employeeActivitiesValidatorAdapter}"
                           >
          </slx-email-input>
          <span errorMessage for="required"></span>
          <span errorMessage for="email"></span>
          <span errorMessage [for]="emailValidation"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.email2.isVisible">
        <slx-input-decorator>
          <slx-email-input slx-input
                           [inputPolicy]="addEmployeeRestrictions?.email2.Policy"
                           slx-email
                           name="email2"
                           placeholder="Email 2"
                           [(ngModel)]="addEmployeeModel.email2">
          </slx-email-input>
          <span errorMessage for="required"></span>
          <span errorMessage for="email"></span>
        </slx-input-decorator>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.gender.isVisible">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [lookup]="{lookupType: 'empGender', employeeId: 0}"
                              [inputPolicy]="addEmployeeRestrictions?.gender.Policy"
                              name="gender"
                              placeholder="Gender"
                              [(ngModel)]="addEmployeeModel.gender">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.maritalStatus.isVisible">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [lookup]="{lookupType: 'maritalStatus'}"
                              [inputPolicy]="addEmployeeRestrictions?.maritalStatus.Policy"
                              name="maritalStatus"
                              placeholder="Marital Status"
                              [(ngModel)]="addEmployeeModel.maritalStatus">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.race.isVisible">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [lookup]="{lookupType: 'empRace'}"
                              [inputPolicy]="addEmployeeRestrictions?.race.Policy"
                              name="race"
                              placeholder="Race"
                              [(ngModel)]="addEmployeeModel.race">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
  
    </div>
  </form>
  <div class="wizard-footer">
     <div class="wizard-buttons">
      <button type="button" slxBackLink class="theme-button-cancel margin-r">Cancel</button>
      <button class="theme-iconed-accent-button prev-step" (click)="prevStep()"><i class="far fa-arrow-alt-circle-left" aria-hidden="true"></i>Back</button>
      <button class="theme-iconed-accent-button next-step" (click)="nextStep()" [disabled]="!personalForm.valid && !ignoreValidation">
        Next<i class="far fa-arrow-alt-circle-right"  aria-hidden="true"></i>
       </button>
     </div>
  </div>
  </div>
