import * as tslib_1 from "tslib";
import { EventReport } from './../../../models/wfm-sync';
import { OnInit } from '@angular/core';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
import { process } from '@progress/kendo-data-query';
import { NotificationsService } from '../../../../../app/core/components';
import * as moment from 'moment';
import { AppSettingsManageService } from '../../../../../app/app-settings/services';
import { KendoGridStateHelper } from '../../../../common/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var EventReportComponent = /** @class */ (function () {
    function EventReportComponent(wfmsyncService, notificationService, appSettingManageService) {
        this.wfmsyncService = wfmsyncService;
        this.notificationService = notificationService;
        this.dataView = [];
        this.endDate = '';
        this.isLoading = false;
        this.startDate = '';
        this.dateError = false;
        this.endDateValue = new Date();
        this.today = new Date();
        this.disable = false;
        this.month = (this.endDateValue.getMonth());
        // new Date(now.setDate(now.getDate() - 30))
        this.day = (this.endDateValue.getDay() - 53);
        this.year = (this.endDateValue.getFullYear());
        // public startDateValue: Date = new Date(this.year, this.month, this.day, 0, 0, 0, 0);
        this.startDateValue = moment(new Date()).subtract(7, 'days').toDate();
        this.isNgpEnabled = false;
        this.pageSize = 50;
        this.allData = this.allData.bind(this);
        this.isLoading = true;
        this.appSettingManageService = appSettingManageService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
    }
    EventReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.startDate = this.startDateValue ? dateTimeUtils.convertToDtoString(this.startDateValue) : '';
        this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';
        this.res();
        this.calculateInitialDates();
        this.getNGPEnabledFlag();
        this.fetchData();
        this.refreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    EventReportComponent.prototype.res = function () {
        var _this = this;
        this.isLoading = true;
        this.wfmsyncService.eventReport(this.startDate, this.endDate, true).then(function (value2) {
            _this.dataView = value2.data;
            _this.gridState.state.take = _this.pageSize;
            _this.gridState.state.skip = 0;
            _this.refreshGrid();
            _this.isLoading = false;
        }).catch(function () {
            _this.isLoading = false;
        });
    };
    EventReportComponent.prototype.exportToExcel = function (grid) {
        grid.saveAsExcel();
    };
    EventReportComponent.prototype.calculateInitialDates = function () {
        this.endDateValue = new Date();
        this.startDateValue = moment(this.endDateValue).subtract(2, 'months').toDate();
    };
    EventReportComponent.prototype.fetchData = function () {
        var _this = this;
        this.isLoading = true;
        this.wfmsyncService.eventReport(this.startDate, this.endDate, true)
            .then(function (value2) {
            _this.dataView = value2.data;
            _this.gridState.state.take = _this.pageSize;
            _this.gridState.state.skip = 0;
            _this.refreshGrid();
            _this.isLoading = false;
        })
            .catch(function (error) {
            _this.isLoading = false;
            // Handle error
        });
    };
    EventReportComponent.prototype.onChangeStartDate = function (dateSent) {
        this.dateError = false;
        this.startDateMessage = '';
        var value = Math.floor((Date.UTC(this.endDateValue.getFullYear(), this.endDateValue.getMonth(), this.endDateValue.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
        if (value >= 62 || value < 0) {
            this.dateError = true;
            this.disable = true;
            this.startDateMessage = 'Difference between Start date and End date should not be greater than two months';
        }
        else {
            this.disable = false;
        }
        this.startDateValue = dateSent;
        this.startDate = this.startDateValue ? dateTimeUtils.convertToDtoString(this.startDateValue) : '';
        this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';
    };
    EventReportComponent.prototype.onEndDateChange = function (dateSent) {
        this.dateError = false;
        this.endDateMessage = '';
        var value = Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(this.startDateValue.getFullYear(), this.startDateValue.getMonth(), this.startDateValue.getDate())) / (1000 * 60 * 60 * 24));
        if (value >= 62 || value < 0) {
            this.dateError = true;
            this.disable = true;
            this.endDateMessage = 'Difference between Start date and End date should not be greater than two months';
        }
        else {
            this.disable = false;
        }
        this.endDateValue = dateSent;
        this.startDate = this.startDate;
        this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';
    };
    EventReportComponent.prototype.apply = function () {
        // this.isLoading=true;
        this.res();
        // this.isLoading=false;
    };
    EventReportComponent.prototype.allData = function () {
        var result = {
            data: process(this.dataView, {
                sort: [{ field: 'transactionId', dir: 'asc' }],
            }).data
        };
        return result;
    };
    EventReportComponent.prototype.getNGPEnabledFlag = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.isNgpEnabled = appServerConfig.IsNextgenPayrollEnabled;
                        if (this.isNgpEnabled)
                            this.startDateValue = moment(new Date()).subtract(7, 'days').toDate();
                        return [2 /*return*/];
                }
            });
        });
    };
    EventReportComponent.prototype.refreshGrid = function () {
        if (!this.dataView) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.dataView, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EventReportComponent.prototype, "refreshSubscription", void 0);
    return EventReportComponent;
}());
export { EventReportComponent };
