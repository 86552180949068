import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
import { appConfig } from '../../../../../../../app/app.config';
import { BenefitListManagementService } from './../../../../services/benefit-console';
var BenefitsByExpirationDateComponent = /** @class */ (function () {
    function BenefitsByExpirationDateComponent(activatedRoute, router, manService) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.manService = manService;
        this.isLoading = true;
        this.data = [];
        this.isShowingExpired = false;
        this.columnGroup = 'BenefitsByExpirationDate';
        this.isPageable = false;
        this.pageSize = 50;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [
            { field: 'endDate', dir: 'desc' },
            { field: 'benefitGroupName', dir: 'asc' },
            { field: 'name', dir: 'asc' }
        ];
        this.gridState.gridComponentKey = 'BenefitsExpirationGrid';
        this.gridState.gridControlStateKey = 'GridFiltersState';
        this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.gridState.assignRestoredState = true;
        this.gridState.refreshGridAfterRestoreState = true;
        this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
        this.appConfig = appConfig;
    }
    BenefitsByExpirationDateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onLoad = this.manService
            .subscribeToLoading(function (value) { return (_this.isLoading = value); });
        this.subscriptions.onLoaded = this.manService
            .subscribeToLoadedRecords(function (r) {
            _this.data = r;
            _this.isPageable = _.size(_this.data) > _this.pageSize;
            _this.refreshGrid();
        });
        this.subscriptions.orgLevel = this.manService
            .subscribeToOrgLevel(function (v) {
            _this.orgLevel = v;
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
    };
    BenefitsByExpirationDateComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    BenefitsByExpirationDateComponent.prototype.onClickBenefit = function (providerLine) {
        var benefitGroupId = providerLine.benefitGroupId, benefitProviderId = providerLine.benefitProviderId, id = providerLine.id;
        this.navService.navigateToDetails(this.orgLevel.id, benefitGroupId, benefitProviderId, id);
    };
    BenefitsByExpirationDateComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        if (!this.data) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.data, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitsByExpirationDateComponent.prototype, "subscriptions", void 0);
    return BenefitsByExpirationDateComponent;
}());
export { BenefitsByExpirationDateComponent };
