import * as tslib_1 from "tslib";
import { OnInit, NgZone, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { EmployeeSubSectionsDecoratorComponent } from '../../../employee-subsection-decorator/employee-subsection-decorator.component';
import { BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { EmployeeSectionsBasicComponent } from '../../../employee-sections/employee-sections-basic.component';
import { EmployeeSubsectionEnrollment } from '../../../../models';
var BenefitEnrollmentSectionComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BenefitEnrollmentSectionComponent, _super);
    function BenefitEnrollmentSectionComponent(decorator, ngZone, managementService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.managementService = managementService;
        _this.subscriptions = {};
        return _this;
    }
    Object.defineProperty(BenefitEnrollmentSectionComponent.prototype, "employeeId", {
        get: function () {
            return this.m_employeeId;
        },
        set: function (value) {
            this.m_employeeId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEnrollmentSectionComponent.prototype, "benefitEnrollment", {
        get: function () {
            return this.m_benefitEnrollment;
        },
        set: function (c) {
            this.m_benefitEnrollment = c;
            if (this.m_benefitEnrollment) {
                this.managementService.setSectionInfo(this.m_benefitEnrollment);
                this.managementService.getAttachmentsData(this.m_employeeId);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEnrollmentSectionComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEnrollmentSectionComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    BenefitEnrollmentSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.loading = this.managementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
        this.subscriptions.effectiveDate = this.managementService.subscribeToEffectiveDate(function (effectiveDate) {
            _this.effectiveDate = effectiveDate;
        });
    };
    BenefitEnrollmentSectionComponent.prototype.getSubsectionModel = function () {
        return this.m_benefitEnrollment;
    };
    BenefitEnrollmentSectionComponent.prototype.loadSubsection = function () {
        this.managementService.getBenefitEnrollmentData(this.employeeId, this.effectiveDate);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitEnrollmentSectionComponent.prototype, "subscriptions", void 0);
    return BenefitEnrollmentSectionComponent;
}(EmployeeSectionsBasicComponent));
export { BenefitEnrollmentSectionComponent };
