import { Component, Input, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../../../../common/components/kendo-ui-extensions/kendo-grid-basic-filter/kendo-grid-basic-filter.component';
import { KendoGridFilterInputDirective } from '../../../../common/directives/index';
@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-autocomplete-lookup-filter',
  templateUrl: 'kendo-grid-autocomplete-lookup-filter.component.html',
  styleUrls: ['kendo-grid-autocomplete-lookup-filter.component.scss'],
})
export class KendoGridAutocompleteLookupFilterComponent extends KendoGridBasicFilterComponent {

  @Input()
  public filterField: string;
  @Input()
  public showOperators: boolean;
  @Input()
  public column: ColumnComponent;
  @Input()
  public filter: CompositeFilterDescriptor;
  @Input()
  public operator: string;
  @Input()
  public lookupConf: any;

  public value: any = null;

  @ViewChild(KendoGridFilterInputDirective, {static: true})
  private input: KendoGridFilterInputDirective;

  constructor(filterService: FilterService, localization: LocalizationService) {
    super(filterService, localization);
    this.localization = localization;

    let autocompleteOperators: any = this.localizeOperators(
      Object.assign({}, {
        'filterContainsOperator': 'contains',
        'filterNotContainsOperator': 'doesnotcontain',
        'filterEqOperator': 'eq',
        'filterNotEqOperator': 'neq',
        'filterStartsWithOperator': 'startswith',
        'filterEndsWithOperator': 'endswith',
        'filterIsNullOperator': 'isnull',
        'filterIsNotNullOperator': 'isnotnull',
        'filterIsEmptyOperator': 'isempty',
        'filterIsNotEmptyOperator': 'isnotempty'
      }));
    this.showOperators = true;
    this.operator = 'contains';
    this.defaultOperators = autocompleteOperators(this.localization);
  }

  public valueChanged(value: any): void {
    if (!value) {
      this.input.valueChanged(null);
      return;
    }
    if (value instanceof Object) {
      this.input.valueChanged(value[this.filterField]);
      return;
    }
    this.input.valueChanged(value);
  }
}
