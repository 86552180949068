import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
var SupervisedEmployeeGridComponent = /** @class */ (function () {
    function SupervisedEmployeeGridComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
        this.selectionChanged = new EventEmitter();
    }
    SupervisedEmployeeGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    SupervisedEmployeeGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    SupervisedEmployeeGridComponent.prototype.ngOnChanges = function (changes) {
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisedEmployeeGridComponent.prototype.allSelectionChange = function () {
        this.selectionHelper.onToggleAllSelected();
        this.selectedRecords = this.selectionHelper.selection.slice(0);
        this.selectionChanged.emit(this.selectedRecords);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisedEmployeeGridComponent.prototype.selectionChange = function (supervisor) {
        this.selectionHelper.onItemSelectionChange(supervisor);
        this.selectedRecords = this.selectionHelper.selection.slice(0);
        this.selectionChanged.emit(this.selectedRecords);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisedEmployeeGridComponent.prototype.refreshGrid = function () {
        if (!this.employees) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.employees, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisedEmployeeGridComponent.prototype, "gridRefreshSubscription", void 0);
    return SupervisedEmployeeGridComponent;
}());
export { SupervisedEmployeeGridComponent };
