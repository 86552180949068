import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { dateTimeUtils } from '../../../../common/utils/index';
import { LookupMapService } from '../../../../organization/services/index';
import { BenefitDetailsMapService } from '../benefit-details/benefit-details-map.service';
import { BenefitDetailsCalcMethod } from '../../models/benefit-details/benefit-details-calc-method';
import {
  BenefitEnrolledEmployee,
  BenefitEmpoyeesInfo,
  IBenefitEmpoyeesInfo,
  IBenefitEnrolledEmployee,
  IEmpBenefitNotes,
  EmpBenefitNotes,
  BenefitTerminationReason,
  IBenefitTerminationReason,
  DropEmployeeBenefits,
  IDropEmployeeBenefits,
  IEmployeeBenefitsEditResult,
  EmployeeBenefitsEditResult,
  EmployeeBenefitsEditModel,
  IBenefitDependentRelation,
  BenefitDependentRelation,
  IBenefitEmployeeDependent,
  BenefitEmployeeDependent,
  IBenefitEmpDependentEnrollment,
  BenefitEmpDependentEnrollment,
  IBenefitEligibleDependentBenefit,
  BenefitEligibleDependentBenefit,
  IBenefitDependentDroppedEnrollment,
  BenefitDependentDroppedEnrollment,
  BenefitEligibleEmployee,
  IBenefitEligibleEmployee,
  IBenefitEnrolledEmployeePreview,
  BenefitEnrolledEmployeePreview,
  BenefitEmployeeFormulaRequest,
  BenefitEligibleEmployeesInfo,
  IBenefitEligibleEmployeesInfo,
  BenefitDetailsOption,
  BenefitEmployeeDependentsEnrollments,
  IEmployeeBenefitsEditModel
} from '../../models/index';

@Injectable()
export class BenefitEmployeesMapService {
  constructor(
    private lookupMapService: LookupMapService,
    private detailsMapService: BenefitDetailsMapService
  ) { }

  public mapToBenefitEligibleEmployees(dtos: IBenefitEligibleEmployee[]): BenefitEligibleEmployee[] {
    return _.map(dtos, v => this.mapToBenefitEligibleEmployee(v));
  }

  public mapToBenefitEligibleEmployee(dto: IBenefitEligibleEmployee): BenefitEligibleEmployee {
    const emp = new BenefitEligibleEmployee();
    emp.employeeId = dto.employeeId;
    emp.employeeName = dto.employeeName;
    emp.organization = dto.organization ? this.lookupMapService.mapOrganization(dto.organization) : null;
    emp.department = dto.department ? this.lookupMapService.mapDepartment(dto.department) : null;
    emp.position = dto.position ? this.lookupMapService.mapPosition(dto.position) : null;
    emp.type = dto.type ? this.lookupMapService.mapEmpType(dto.type) : null;
    return emp;
  }

  public mapToBenefitEnrolledEmployeesPreview(dtos: IBenefitEnrolledEmployeePreview[]): BenefitEnrolledEmployeePreview[] {
    return _.map(dtos, v => this.mapToBenefitEnrolledEmployeePreview(v));
  }

  public mapToBenefitEnrolledEmployeePreview(dto: IBenefitEnrolledEmployeePreview): BenefitEnrolledEmployeePreview {
    const emp = new BenefitEnrolledEmployeePreview();
    emp.employeeId = dto.employeeId;
    emp.employeeName = dto.employeeName;
    emp.employeeContributionAmount = dto.employeeContributionAmount;
    emp.employerContributionAmount = dto.employerContributionAmount;
    emp.coverage = dto.coverage;
    return emp;
  }

  public mapToBenefitEligibleEmployeesInfo(dto: IBenefitEligibleEmployeesInfo): BenefitEligibleEmployeesInfo {
    const eligibleEmployeesInfo = new BenefitEligibleEmployeesInfo({
      count: dto.count,
      errorMessage: dto.errorMessage,
      hasError: dto.hasError
    });

    return eligibleEmployeesInfo;
  }

  public mapToBenefitEnrolledEmployeesInfo(dto: number): number {
    const enrolledEmployeesCount = dto;
    return enrolledEmployeesCount;
  }

  public mapToBenefitEnrolledEmployees(dtos: IBenefitEnrolledEmployee[]): BenefitEnrolledEmployee[] {
    return _.map(dtos, v => this.mapToBenefitEnrolledEmployee(v));
  }

  public mapToBenefitEnrolledEmployee(dto: IBenefitEnrolledEmployee): BenefitEnrolledEmployee {
    const emp = new BenefitEnrolledEmployee();
    emp.id = dto.id;
    emp.employeeId = dto.employeeId;
    emp.benefitLineId = dto.benefitLineId;
    emp.benefitTierId = dto.benefitTierId;
    emp.benefitTierOptionId = dto.benefitTierOptionId;
    emp.startDate = dateTimeUtils.convertFromDtoDateTimeString(dto.startDate);
    emp.endDate = dateTimeUtils.convertFromDtoDateTimeString(dto.endDate);
    emp.payrollDeductionStartDate = dateTimeUtils.convertFromDtoDateTimeString(dto.payrollDeductionStartDate);
    emp.payrollDeductionEndDate = dateTimeUtils.convertFromDtoDateTimeString(dto.payrollDeductionEndDate);
    emp.employeeContributionPercentage = dto.employeeContributionPercentage;
    emp.employeeContributionAmount = dto.employeeContributionAmount;
    emp.employerContributionPercentage = dto.employerContributionPercentage;
    emp.employerContributionAmount = dto.employerContributionAmount;
    emp.coverage = dto.inputCoverage ? dto.inputCoverage : dto.coverage;
    emp.calculatedCoverage = dto.coverage;
    emp.originalCoverage = dto.coverage;
    emp.limit = dto.limit;
    emp.cost = dto.cost;
    emp.dropReason = dto.dropReason;
    emp.dropEventDate = dateTimeUtils.convertFromDtoDateTimeString(dto.dropEventDate);
    emp.coverageEndDate = dateTimeUtils.convertFromDtoDateTimeString(dto.coverageEndDate);
    emp.modifiedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.modifiedAt);
    emp.modifiedBy = dto.modifiedBy;
    emp.addedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.addedAt);
    emp.addedBy = dto.addedBy;
    emp.isDeleted = dto.isDeleted;
    emp.isOverridden = dto.isOverridden;
    emp.benefitLineName = dto.benefitLineName;
    emp.employeeName = dto.employeeName;
    emp.department = _.isObjectLike(dto.department) ? this.lookupMapService.mapDepartment(dto.department) : null;
    emp.age = dto.age;
    emp.dateOfBirth = dateTimeUtils.convertFromDtoDateTimeString(dto.dateOfBirth);
    emp.employeeType = dto.employeeType;
    emp.sex = dto.sex;
    emp.organization = _.isObjectLike(dto.organization) ? this.lookupMapService.mapOrganization(dto.organization) : null;
    emp.position = _.isObjectLike(dto.position) ? this.lookupMapService.mapPosition(dto.position) : null;
    emp.optionRateCode = dto.optionRateCode;
    emp.code = dto.optionRateCode;
    emp.optionType = dto.optionType;
    emp.employeeToBenefitNotes = _.isObjectLike(dto.employeeToBenefitNotes) ? this.mapEmployeeNotes(dto.employeeToBenefitNotes) : null;
    emp.optionCode = this.mapBenefitDetailsOption(dto);

    return emp;
  }

  public mapBenefitDetailsOption(dto: IBenefitEnrolledEmployee): BenefitDetailsOption {
    const option = new BenefitDetailsOption();
    option.id = dto.benefitTierOptionId;
    option.code = dto.optionRateCode;
    option.type = dto.optionType;
    return option;
  }

  public mapEligibleToEnrolledEmployees(dtos: BenefitEligibleEmployee[]): BenefitEnrolledEmployee[] {
    return _.map(dtos, v => this.mapEligibleToEnrolledEmployee(v));
  }

  public mapEligibleToEnrolledEmployee(dto: BenefitEligibleEmployee): BenefitEnrolledEmployee {
    const emp = new BenefitEnrolledEmployee();
    emp.employeeId = dto.employeeId;
    emp.employeeName = dto.employeeName;
    emp.department = _.isObjectLike(dto.department) ? this.lookupMapService.mapDepartment(dto.department) : null;
    emp.employeeType = dto.type.name;
    emp.organization = _.isObjectLike(dto.organization) ? this.lookupMapService.mapOrganization(dto.organization) : null;
    emp.position = dto.position;

    return emp;
  }

  public mapEmployeeNotes(dto: IEmpBenefitNotes): EmpBenefitNotes {
    const emp = new EmpBenefitNotes();
    emp.id = dto.id;
    emp.notes = dto.notes;
    return emp;
  }

  public mapBenefitTerminationReasons(dtos: IBenefitTerminationReason[]): BenefitTerminationReason[] {
    return _.map(dtos, v => this.mapBenefitTerminationReason(v));
  }

  public mapBenefitTerminationReason(dto: IBenefitTerminationReason): BenefitTerminationReason {
    const { id, name } = dto || { id: '', name: '' };
    return new BenefitTerminationReason(id, name);
  }

  public mapDropToDto(selectedEmployee: number[], benefitEndDate: Date, payrollDeductionEndDate: Date, eventDate: Date, reason: BenefitTerminationReason): DropEmployeeBenefits {
    const drop = new DropEmployeeBenefits();
    drop.employee2BenefitIds = selectedEmployee;
    // TODO: These dates should be converted to string before sending them to request
    drop.benefitEndDate = benefitEndDate;
    drop.payrollDeductionEndDate = payrollDeductionEndDate;
    drop.eventDate = eventDate;
    drop.reason = this.mapBenefitTerminationReason(reason);

    return drop;
  }

  public mapToDropBenefits(dto: IDropEmployeeBenefits): DropEmployeeBenefits {
    // TODO: This mapping should be done correctly
    const drop = new DropEmployeeBenefits();

    return drop;
  }

  public mapEmployeeBenefitsEditResult(dto: IEmployeeBenefitsEditResult): EmployeeBenefitsEditResult {
    const editRes = new EmployeeBenefitsEditResult();
    editRes.isSuccess = dto && !dto.isSuccess? false : true;
    editRes.message = dto && dto.message ? dto.message : '';
    return editRes;
  }

  public mapBenefitsEnrollOptionsToDto(benefitReq: BenefitEnrolledEmployee[], notesTex: string, effectiveDate: any, lineId: number, tierId: number, calculationMethod: string): IEmployeeBenefitsEditModel {
    const dto = {} as IEmployeeBenefitsEditModel;
    if (_.isObjectLike(benefitReq)) {
      dto.benefitTierId = tierId;
      dto.benefitLineId = lineId;
      dto.effectiveDate = effectiveDate;
      dto.employeeToBenefitRecords =this.mapEmployeesToBenefitDto(benefitReq);
      dto.notes = notesTex;
      dto.calculationMethod = calculationMethod;
    }
    return dto;
  }

  public mapEmployeesToBenefitDto(enrollment: BenefitEnrolledEmployee[]): IBenefitEnrolledEmployee[] {
    return _.map(enrollment, e => this.mapEmployeeToBenefitDto(e));
  }

  public mapEmployeeToBenefitDto(enroll: BenefitEnrolledEmployee): IBenefitEnrolledEmployee {
    const dto = {} as IBenefitEnrolledEmployee;
    if (_.isObjectLike(dto)) {
      dto.id = enroll.id;
      dto.employeeId = enroll.employeeId;
      dto.benefitLineId = enroll.benefitLineId;
      dto.benefitTierId = enroll.benefitTierId;
      dto.benefitTierOptionId = enroll.benefitTierOptionId;
      dto.startDate = dateTimeUtils.convertToDtoString(enroll.startDate);
      dto.endDate = dateTimeUtils.convertToDtoString(enroll.endDate);
      dto.payrollDeductionStartDate = dateTimeUtils.convertToDtoString(enroll.payrollDeductionStartDate);
      dto.payrollDeductionEndDate = dateTimeUtils.convertToDtoString(enroll.payrollDeductionEndDate);
      dto.employeeContributionPercentage = enroll.employeeContributionPercentage;
      dto.employeeContributionAmount = enroll.employeeContributionAmount;
      dto.employerContributionPercentage = enroll.employerContributionPercentage;
      dto.employerContributionAmount = enroll.employerContributionAmount;
      dto.coverage = enroll.coverage;
      dto.calculatedCoverage = enroll.calculatedCoverage;
      dto.limit = enroll.limit;
      dto.cost = enroll.cost;
      dto.dropReason = enroll.dropReason;
      dto.dropEventDate = dateTimeUtils.convertToDtoString(enroll.dropEventDate);
      dto.coverageEndDate = dateTimeUtils.convertToDtoString(enroll.coverageEndDate);
      dto.modifiedAt = dateTimeUtils.convertToDtoString(enroll.modifiedAt);
      dto.modifiedBy = enroll.modifiedBy;
      dto.addedAt = dateTimeUtils.convertToDtoString(enroll.addedAt);
      dto.addedBy = enroll.addedBy;
      dto.isDeleted = enroll.isDeleted;
      dto.isOverridden = enroll.isOverridden;
      dto.benefitLineName = enroll.benefitLineName;
      dto.employeeName = enroll.employeeName;
      dto.department =_.isObjectLike(enroll.department) ? this.lookupMapService.mapDepartmentDto(enroll.department) : null;
      dto.age = enroll.age;
      dto.dateOfBirth = dateTimeUtils.convertToDtoString(enroll.dateOfBirth);
      dto.employeeType = enroll.employeeType;
      dto.sex = enroll.sex;
      dto.organization = _.isObjectLike(enroll.organization) ? this.lookupMapService.mapOrganizationDto(enroll.organization) : null;
      dto.position = _.isObjectLike(enroll.position) ? this.lookupMapService.mapPositionDto(enroll.position) : null;
      dto.optionRateCode = enroll.optionRateCode;
      dto.employeeToBenefitNotes =_.isObjectLike(enroll.employeeToBenefitNotes) ? this.mapEmployeeNotes(enroll.employeeToBenefitNotes) : null;
      dto.maxMatchGrant = enroll.maxMatchGrant;
      dto.optionCode = enroll.optionCode;
      dto.maxEmpContribution = enroll.maxEmpContribution;
      dto.type = enroll.type;
      dto.editedItemCoverage = enroll.editedItemCoverage;
    }
    return dto;
  }

  public mapToEmployeeBenefitFormulaContributionsRequest(orgLevelId: number, tierId: number, refDate: Date, coverage:number, selectedEmployee: IBenefitEnrolledEmployeePreview[]): BenefitEmployeeFormulaRequest {
    return this.mapToEmployeeBenefitFormulaContributionsRequest2(orgLevelId, tierId, refDate, coverage, null, selectedEmployee);
  }

  public mapToEmployeeBenefitFormulaContributionsRequest2(orgLevelId: number, tierId: number, refDate: Date, coverage: number, optionCode: string, selectedEmployee: IBenefitEnrolledEmployeePreview[]): BenefitEmployeeFormulaRequest {
    const request = new BenefitEmployeeFormulaRequest();
    request.orgLevelId = orgLevelId;
    request.benefitTierId = tierId;
    request.employeeIds = _.map(selectedEmployee, function (e) { return e.employeeId; });
    request.effectiveDate = dateTimeUtils.convertToDtoString(refDate);
    request.coverage = coverage;
    request.optionRateCode = optionCode;
    return request;
  }

  public mapDtoToEmployeeInformationUpdate(req: BenefitEnrolledEmployee, lineId: number, tierId: number, effectiveDate: Date, method: string): any {
    const benefitEmployeeEdit = new EmployeeBenefitsEditModel();
    benefitEmployeeEdit.benefitLineId = lineId;
    benefitEmployeeEdit.benefitTierId = tierId;
    benefitEmployeeEdit.calculationMethod = method;
    benefitEmployeeEdit.effectiveDate = effectiveDate;
    benefitEmployeeEdit.employeeToBenefitRecords = [req];

    return benefitEmployeeEdit;
  }

  public mapEmployeeBenefitsDeleteResult(dto: any): Object {
    // TODO: This method have to be removed
    const delRes = {};
    return delRes;
  }

  public mapDtoToEmployeeDependent(dto: IBenefitEmployeeDependent): BenefitEmployeeDependent {
    const rel = new BenefitEmployeeDependent();
    if (_.isObjectLike(dto)) {
      rel.id = dto.id;
      rel.employeeId = dto.employeeId;
      rel.firstName = dto.firstName;
      rel.lastName = dto.lastName;
      rel.middleName = dto.middleName;
      rel.suffix = dto.suffix;
      rel.address = dto.address;
      rel.street = dto.street;
      rel.city = dto.city;
      rel.state = this.lookupMapService.mapState(dto.state);
      rel.zipcode = dto.zipcode;
      rel.hasBenefits = dto.hasBenefits;
      rel.relation = this.mapEmployeeDependentRelation(dto.relation);
      rel.gender = this.lookupMapService.mapGender(dto.gender);
      rel.birthDate = dateTimeUtils.convertFromDtoDateString(dto.birthDate);
      rel.ssn = dto.ssn;
      rel.isDeleted = dto.isDeleted;
      rel.modifiedAt = dateTimeUtils.convertFromDtoDateString(dto.modifiedAt);
      rel.modifiedBy = dto.modifiedBy;
      rel.addedAt = dateTimeUtils.convertFromDtoDateString(dto.addedAt);
      rel.addedBy = dto.addedBy;
    }
    return rel;
  }

  public mapEmployeeDependentToDto(dependent: BenefitEmployeeDependent): IBenefitEmployeeDependent {
    const dto = {} as IBenefitEmployeeDependent;
    if (_.isObjectLike(dependent)) {
      dto.id = dependent.id;
      dto.employeeId = dependent.employeeId;
      dto.firstName = dependent.firstName;
      dto.lastName = dependent.lastName;
      dto.middleName = dependent.middleName;
      dto.suffix = dependent.suffix;
      dto.address = dependent.address;
      dto.street = dependent.street;
      dto.city = dependent.city;
      dto.state = this.lookupMapService.mapStateDto(dependent.state);
      dto.zipcode = dependent.zipcode;
      dto.relation = this.mapEmployeeDependentRelation(dependent.relation);
      dto.gender = this.lookupMapService.mapGenderDto(dependent.gender) || { value: null };
      dto.birthDate = dateTimeUtils.convertToDtoString(dependent.birthDate);
      dto.ssn = dependent.ssn;
      dto.isDeleted = dependent.isDeleted;
      dto.modifiedAt = dateTimeUtils.convertToDtoString(dependent.modifiedAt);
      dto.modifiedBy = dependent.modifiedBy;
      dto.addedAt = dateTimeUtils.convertToDtoString(dependent.addedAt);
      dto.addedBy = dependent.addedBy;
    }
    return dto;
  }

  public mapDtoToEmpDependentEnrollments(dtos: IBenefitEmpDependentEnrollment[]): BenefitEmpDependentEnrollment[] {
    return _.map(dtos, v => this.mapDtoToEmpDependentEnrollment(v));
  }

  public mapDtoToEmpDependentEnrollment(dto: IBenefitEmpDependentEnrollment): BenefitEmpDependentEnrollment {
    const enrollment = new BenefitEmpDependentEnrollment();
    if (_.isObjectLike(dto)) {
      enrollment.id = dto.id;
      enrollment.dependentId = dto.dependentId;
      enrollment.empEnrollmentId = dto.empEnrollmentId;
      enrollment.benefitName = dto.benefitName;
      enrollment.tierName = dto.tierName;
      enrollment.benefitOption = this.detailsMapService.mapToBenefitPlanOption(dto.benefitOption);
      enrollment.benefitCalcMethod = this.detailsMapService.mapToBenefitDetailsCalcMethod(dto.benefitCalcMethod);
      enrollment.startDate = dateTimeUtils.convertFromDtoDateString(dto.startDate);
      enrollment.endDate = dateTimeUtils.convertFromDtoDateString(dto.endDate);
      enrollment.empBenefitStartDate = dateTimeUtils.convertFromDtoDateString(dto.empBenefitStartDate);
      enrollment.empBenefitEndDate = dateTimeUtils.convertFromDtoDateString(dto.empBenefitEndDate);
      enrollment.dropEventDate = dateTimeUtils.convertFromDtoDateString(dto.dropEventDate);
    }
    return enrollment;
  }

  public mapEmpDependentEnrollmentToDto(e: BenefitEmpDependentEnrollment): IBenefitEmpDependentEnrollment {
    let dto = {} as IBenefitEmpDependentEnrollment;
    if (_.isObjectLike(e)) {
      dto = this.createDependentEnrollmentDto(e.id, e.dependentId, e.empEnrollmentId, e.startDate, e.endDate);
    }
    return dto;
  }

  public mapEmpDependentsEnrollmentsToDto(data: BenefitEmployeeDependentsEnrollments): Array<IBenefitEmpDependentEnrollment> {
    let dto: Array<IBenefitEmpDependentEnrollment> = [];
    if (_.isObjectLike(data)) {
      dto = _.reduce(data.benefits, (accum, ben) => {
        const list = _.map(data.dependents, dep => this.createDependentEnrollmentDto(null, dep.id, ben.empEnrollmentId, data.startDate,ben.empBenefitEndDate));
        accum.push(...list);

        return accum;
      }, [] as Array<IBenefitEmpDependentEnrollment>)
    }
    return dto;
  }

  public mapDtoToEmpDependentEligibleBenefits(dtos: IBenefitEligibleDependentBenefit[]): BenefitEligibleDependentBenefit[] {
    return _.map(dtos, v => this.mapDtoToEmpDependentEligibleBenefit(v));
  }

  public mapDtoToEmpDependentEligibleBenefit(dto: IBenefitEligibleDependentBenefit): BenefitEligibleDependentBenefit {
    const benefit = new BenefitEligibleDependentBenefit();
    if (_.isObjectLike(dto)) {
      benefit.empEnrollmentId = dto.empEnrollmentId;
      benefit.benefitName = dto.benefitName;
      benefit.benefitCalcMethod = this.detailsMapService.mapToBenefitDetailsCalcMethod(dto.benefitCalcMethod);
      benefit.benefitOption = this.detailsMapService.mapToBenefitPlanOption(dto.benefitOption);
      benefit.empBenefitStartDate = dateTimeUtils.convertFromDtoDateString(dto.empBenefitStartDate);
      benefit.empBenefitEndDate = dateTimeUtils.convertFromDtoDateString(dto.empBenefitEndDate);
    }
    return benefit;
  }

  public mapEmpDependentDroppedEnrollmentToDto(dropEnrollment: BenefitDependentDroppedEnrollment): IBenefitDependentDroppedEnrollment {
    const dto = {} as IBenefitDependentDroppedEnrollment;
    if (_.isObjectLike(dropEnrollment) && _.size(dropEnrollment) > 0) {
      dto.empDependentEnrollmentId = dropEnrollment.empDependentEnrollmentId;
      dto.eventDate = dateTimeUtils.convertToDtoString(dropEnrollment.eventDate);
      dto.coverageEndDate = dateTimeUtils.convertToDtoString(dropEnrollment.coverageEndDate);
      dto.reason = {
        id: dropEnrollment.reason.id,
        name: dropEnrollment.reason.name
      };
    }
    return dto;
  }

  public mapEmployeeDependentRelation(dto: IBenefitDependentRelation): BenefitDependentRelation {
    const rel = new BenefitDependentRelation();
    if (_.isObjectLike(dto)) {
      rel.id = dto.id;
      rel.name = dto.name;
    }

    return _.size(rel) > 0 ? rel : null;
  }

  public createDependentEnrollmentDto(id: number, depId: number, empEnrollmentId: number, sDate: Date, eDate: Date): IBenefitEmpDependentEnrollment {
    const dto = {} as IBenefitEmpDependentEnrollment;
    dto.id = id;
    dto.dependentId = depId;
    dto.empEnrollmentId = empEnrollmentId;
    dto.startDate = dateTimeUtils.convertToDtoString(sDate);
    dto.endDate = dateTimeUtils.convertToDtoString(eDate);

    return dto;
  }
}
