<div>
  <form #gridForm="ngForm">
    <kendo-grid #kendoGrid scrollable="'scrollable'" [data]="gridState.view"
      (dataStateChange)="gridState.dataStateChange($event)" [sortable]="true" [sort]="gridState.state.sort"
      [pageable]="true" [pageSize]="gridState.state.take" [skip]="gridState.state.skip" (pageChange)="pageChange($event)"
      (sortChange)="sortChange($event)" [groupable]="false" [selectable]="false"
      (selectionChange)="gridState.selectionChange($event)" [slxKendoGridState]="gridState.gridDefinition"
      (stateRestored)="gridState.dataStateChange($event)" [filterable]="false" [filter]="gridState.state.filter"
      (edit)="gridState.editHandler($event)" (save)="gridState.saveHandler($event)"
      (cancel)="gridState.cancelHandler($event)" (remove)="gridState.removeHandler($event)">
      <ng-template kendoGridToolbarTemplate position="top">
        <div class="col-xs-12">
          <div class="row">
            <button *ngIf="canEdit && isEditable" class="theme-iconed-accent-button pull-left " (click)="onAddNew()"
              [disabled]="editingItem">
              <span class="icon-button-content">
                <span>Add New</span>
              </span>
            </button>
            <label class="pull-right"><span *ngIf="employeeSectionsPolicyAssignments?.effectiveDate">Effective Date :
                {{employeeSectionsPolicyAssignments.effectiveDate | amDateFormat: appConfig.dateFormat}}</span></label>
          </div>
        </div>
      </ng-template>
      <kendo-grid-column *ngIf="canEdit && isEditable" title="" [sortable]="false" [filterable]="false" width="90">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex">

            <button kendoGridEditCommand type="button" class="theme-iconed-accent-button grid-btn" [disabled]="addMode"
             >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button kendoGridRemoveCommand type="button" class="theme-iconed-accent-button grid-btn"
              [disabled]="addMode">
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
            </button>
            <button kendoGridSaveCommand type="button" class="theme-iconed-accent-button grid-btn slx-margin-r"
              [disabled]="!gridForm.valid || dPolicyError || dateError">
              <i class="fas fa-save" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="theme-iconed-accent-button grid-btn">
              <i class="fas fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="policyName" title="Policy Name" width="200">
        <ng-template kendoGridHeaderTemplate>
          Policy Name
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.policyName }}
        </ng-template>

        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-dropdown-input class="dvhide" [(ngModel)]="dataItem.accrualPolicy" name="accrualPolicyInput{{rowIndex}}">
          </slx-dropdown-input>
          <div *ngIf="allowDynamicPolicy">
            <span> {{ dataItem.policyName }}</span>
            <span class="pull-right fs30 lblPolicy" (click)="openDynamicPolicyDialog(dataItem)">
              <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
            </span>

          </div>

          <slx-input-decorator *ngIf="!allowDynamicPolicy" class="row-item">
            <select slx-input required class="form-control" name="accrualPolicy{{rowIndex}}"
              [(ngModel)]="dataItem.policyName" placeholder="Accrual Policy" [required]="true">
              <option *ngFor="let acc of accrualPolicyLookup" value={{acc.policyName}}>
                {{acc.policyName}}
              </option>
            </select>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="startDate" title="Effective Start Date" width="150" editable="true">
        <ng-template kendoGridHeaderTemplate>
          Effective Start Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

          <slx-input-decorator class="row-item">
            <slx-date-picker-ngx slx-input placeholder="Start Date" name="startDate{{rowIndex}}"
              [(ngModel)]="dataItem.startDate" [acceptNullDate]="false" [minDate]="minDateLimit(dataItem)"
              (ngModelChange)="onChangedDate(dataItem)" [required]="true">
            </slx-date-picker-ngx>

            <div errorMessage for="required"></div>
            <div errorMessage for="date"></div>
            <div errorMessage for="minDate">Date is invalid or out of valid range</div>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="endDate" title="Effective End Date" width="150" editable="true">
        <ng-template kendoGridHeaderTemplate>
          Effective End Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.endDate | amDateFormat: appConfig.dateFormat }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator *ngIf="!addMode && isEndDateRequired(dataItem)" class="row-item">
            <slx-date-picker-ngx slx-input placeholder="End Date" name="endDate{{rowIndex}}"
              [(ngModel)]="dataItem.endDate" [acceptNullDate]="false"
              (change)="onChangedEndDateEvent(dataItem, $event.target.value)"
              (ngModelChange)="onChangedEndDate(dataItem)" [minDate]="dataItem.startDate" [required]="true">
            </slx-date-picker-ngx>
            <div *ngIf="!dateError" errorMessage for="required"></div>
            <div *ngIf="!dateError" errorMessage for="date"></div>
            <div for="dateError" class="slx-error-block" *ngIf="dateError">End Date should greater-than Start Date</div>
            <div for="dateError" class="slx-error-block" *ngIf="dateOverlapError">Policy Dates can not overlap</div>

          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="lastUpdatedDate" title="Assigned Date" width="170" editable="false">
        <ng-template kendoGridHeaderTemplate>
          Assigned Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.lastUpdatedDate | amDateFormat: appConfig.dateTimeFormatUS }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="lastUpdatedBy" title="Assigned By" width="120">
        <ng-template kendoGridHeaderTemplate>
          Assigned By
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.lastUpdatedBy }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="endingBalance" [sortable]="false" title="Ending Balance" width="130">
        <ng-template kendoGridHeaderTemplate>
          Ending Balance
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <a *ngIf="!addMode" (click)="onShowEndingBalance(dataItem)"><i class="fal fa-external-link"
              aria-hidden="true"></i></a>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</div>
