import { Component, Input, ViewChild, OnInit, OnChanges, SimpleChanges, ElementRef } from '@angular/core';
import Croppie from 'croppie/croppie';
import {CroppieOptions, ResultOptions } from 'croppie/croppie';
@Component({
  moduleId: module.id,
  selector: 'slx-photo-cropper',
  templateUrl: 'photo-cropper.component.html',
  styleUrls: ['photo-cropper.component.scss']
})
export class PhotoCropperComponent implements OnInit, OnChanges {
  @ViewChild('targetElem', {static: true})
  public targetElem: ElementRef;
  @Input()
  public cropperOptions: CroppieOptions;
  @Input()
  public imageUrl: string;

  private croppie: Croppie;

  public ngOnInit(): void {
    this.croppie = new Croppie(this.targetElem.nativeElement, this.cropperOptions);
    this.updateImage(this.imageUrl);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const imageUrlProp = changes.imageUrl;
    if (!imageUrlProp.firstChange && imageUrlProp.previousValue !== imageUrlProp.currentValue) {
      this.updateImage(imageUrlProp.currentValue);
    }
  }

  public getCroppedImage(options: ResultOptions = { size: 'viewport', type: 'base64' }): Promise<string> {
    return this.croppie.result(options);
  }

  private updateImage(url: string): void {
    this.imageUrl = url;
    this.croppie.bind({ url });
  }
}
