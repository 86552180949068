/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-sections-accruals.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/pipes/slx-decimal24-switch";
import * as i3 from "@angular/forms";
import * as i4 from "@angular/common";
import * as i5 from "./employee-sections-accruals.component";
import * as i6 from "../../../services/employee-section-accruals/employee-section-accruals-api.service";
import * as i7 from "../../../../../app-settings/services/app-settings-manage.service";
import * as i8 from "../../employee-subsection-decorator/employee-subsection-decorator.component";
var styles_EmployeeSectionsAccrualsComponent = [i0.styles];
var RenderType_EmployeeSectionsAccrualsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeSectionsAccrualsComponent, data: {} });
export { RenderType_EmployeeSectionsAccrualsComponent as RenderType_EmployeeSectionsAccrualsComponent };
function View_EmployeeSectionsAccrualsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col-sm-6 col-lg-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.benefitType.fieldValue; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.accruedHours.fieldValue, _co.isShowHighPrecision)); _ck(_v, 4, 0, currVal_1); }); }
export function View_EmployeeSectionsAccrualsComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.SlxDecimal24Switch, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { ngForm: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(4, 4210688, [[1, 4], ["form", 4]], 0, i3.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(6, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h4", [["class", "columnHeader"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current Balance"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "hr", [["class", "columnHeader"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeeSectionsAccrualsComponent_1)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = ((_co.accruals == null) ? null : _co.accruals.records); _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_EmployeeSectionsAccrualsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-sections-accruals", [], null, null, null, View_EmployeeSectionsAccrualsComponent_0, RenderType_EmployeeSectionsAccrualsComponent)), i1.ɵdid(1, 245760, null, 0, i5.EmployeeSectionsAccrualsComponent, [i6.EmployeeSectionsAccrualsApiService, i7.AppSettingsManageService, i8.EmployeeSubSectionsDecoratorComponent, i1.NgZone, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeSectionsAccrualsComponentNgFactory = i1.ɵccf("slx-employee-sections-accruals", i5.EmployeeSectionsAccrualsComponent, View_EmployeeSectionsAccrualsComponent_Host_0, { accruals: "accruals", employeeId: "employeeId" }, {}, []);
export { EmployeeSectionsAccrualsComponentNgFactory as EmployeeSectionsAccrualsComponentNgFactory };
