import { Injectable } from '@angular/core';
import * as _ from 'lodash';


export class TreeNodeModel {

  public tree: TreeModel;
  public data: any;
  public parent: TreeNodeModel;
  public children: TreeNodeModel[] = [];
  public depth: number = 0;

  constructor (data: any, parent: TreeNodeModel, tree: TreeModel) {
      this.data = data;
      this.parent = parent;
      this.tree = tree;
  }

  public hasChildren (): boolean {
      return this.children.length > 0;
  }

  public get isExpanded(): boolean {
      if (this.tree.isExpanded(this)) return true;
      return false;
  }

  public mouseAction(actionName: string, $event: any, data: any = null): void {
      this.tree.processMouseAction (actionName, $event, this, data);
  }
}


@Injectable()
export class TreeModel {

    public showRoot: Boolean;
    public root: TreeNodeModel;
    private  expandedNodes: TreeNodeModel[];

    constructor () {
        this.root = new TreeNodeModel (null, null, this);
        this.expandedNodes = new Array<TreeNodeModel> ();
    }

    public isExpanded (node: TreeNodeModel): TreeNodeModel {
        return _.find (this.expandedNodes, (item: TreeNodeModel) => {
            return node === item;
        });
    }

    public setExpanded (node: TreeNodeModel): void {
        if (!this.isExpanded (node)) {
            this.expandedNodes.push (node);
        } else {
            this.expandedNodes = _.without(this.expandedNodes, node);
        }
    }

    public processMouseAction (actionName: string, $event: any, node: TreeNodeModel, data: any = null): void {

        switch (actionName) {
            case 'expanderClick':
                this.setExpanded (node);
            break;
        }
    }

    public getDepth (node: TreeNodeModel): number {
        let depth: number = 0;
        let parent: TreeNodeModel;
        parent = node.parent;
        while (parent) {
            depth++;
            parent = parent.parent;
        }

        return depth;
    }


}
