import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Meta, ResponseBody } from '../../../../core/models/index';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { configurationConfig } from '../../../configuration.config';
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { ItabDataModel, OrgStructureCode } from './org-structure-model';


@Injectable({
  providedIn: 'root',
})
export class OrgStructureCodeService {
  newTabData = {
    // ParentLevel: [],
    Organizations: [],
    Positions: [],
    Departments: [],
  };
  public codeMappingData = new Subject();
  constructor(private apiUtilService: ApiUtilService, private urlParamsService: UrlParamsService) {}

  public getCodeMappingData(): Observable<{}> {
    return this.codeMappingData.asObservable();
  }
  public editDataHandler(data) {
    this.codeMappingData.next(data);
  }
  public saveDataHandler(data: ItabDataModel): Promise<any> {
    const url: string = `${this.getConfigurationApiRoot()}/orgLevel/orgStructureCodes`;
    const body: ItabDataModel = {
      ...data,
    };
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    let promise: Promise<any> = this.apiUtilService
      .request<any[], Meta>(request)
      .then((response: ResponseBody<[], Meta>) => {
        return response;
      });
    return promise;
  }
  public getOrgStructureCodes(orgLevelType: string, orgLevelId: number): Promise<OrgStructureCode[]> {
    const url: string = `${this.getConfigurationApiRoot()}/orgLevel/${orgLevelType}/${orgLevelId}/orgStructureCodes`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<any> = this.apiUtilService
      .request<any[], Meta>(request)
      .then((response: ResponseBody<[], Meta>) => {
        return response;
      });
    return promise;
  }
  public getOrgStructureCodeByType(orgLevelType: string): Promise<OrgStructureCode[]> {
    const url: string = `${this.getConfigurationApiRoot()}/orgLevel/${orgLevelType}/orgStructureCodes`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<any> = this.apiUtilService
      .request<any[], Meta>(request)
      .then((response: ResponseBody<[], Meta>) => {
        return response;
      });
    return promise;
  }
  // public getOrgParentLevelOptions(orgLevelId: number): Promise<OrgStructureCode[]> {
  //   const url: string = `${this.getConfigurationApiRoot()}/orgLevel/${orgLevelId}/getParentLevel`;
  //   let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
  //   let promise: Promise<any> = this.apiUtilService
  //     .request<any[], Meta>(request)
  //     .then((response: ResponseBody<[], Meta>) => {
  //       return response;
  //     });
  //   return promise;
  // }
  // public getOrgParentLevelData(orgLevelType: string): Promise<OrgStructureCode[]> {
  //   const url: string =  `${this.getConfigurationApiRoot()}/orgLevel/${orgLevelType}/parentLevelCodesByType`;
  //   let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
  //   let promise: Promise<any> = this.apiUtilService
  //     .request<any[], Meta>(request)
  //     .then((response: ResponseBody<[], Meta>) => {
  //       return response;
  //     });
  //   return promise;
  // }
  private getConfigurationApiRoot(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}





