import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect } from '../../../core/decorators/index';
import { ManagementBaseService } from '../../../core/services/index';
import { ModalService } from '../../../common/services/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LoaRequest, LoaRequestDialogOptions, LoaRequestTabs } from '../models/index';
import { LMCreationDialogComponent } from '../components/lm-creation-absence/lm-creation-dialog/lm-creation-dialog.component';
import { loaDropdownItems } from '../../../common/models';
var LMManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(LMManagementService, _super);
    function LMManagementService(modalService) {
        var _this = _super.call(this) || this;
        _this.modalService = modalService;
        _this.orgLevelChanged$ = new ReplaySubject(1);
        _this.loadRequests$ = new Subject();
        _this.navButton$ = new Subject();
        _this.initialized = false;
        return _this;
    }
    LMManagementService.prototype.init = function () {
        var _this = this;
        if (!this.initialized) {
            this.initialized = true;
            this.leaveTypesMap = _.keyBy(this.getRequestTypes(), function (v) { return v.id.toString(); });
            this.subscriptions.orgLevel = this.orgLevel$
                .filter(function (o) { return o && _.isFinite(o.id); })
                .subscribe(function (orgLevel) {
                if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                    return;
                _this.orgLevel = orgLevel;
                _this.orgLevelChanged$.next(orgLevel);
            });
        }
    };
    LMManagementService.prototype.getRequestTypes = function () {
        return loaDropdownItems;
    };
    LMManagementService.prototype.getIcon = function (classId) {
        if (!classId) {
            return null;
        }
        var desc = this.leaveTypesMap[classId];
        if (!desc) {
            return null;
        }
        return desc.icon;
    };
    LMManagementService.prototype.openCreationDialog = function (requestClass, req, loaRequestTabs) {
        if (req === void 0) { req = null; }
        if (loaRequestTabs === void 0) { loaRequestTabs = LoaRequestTabs.Dates; }
        var loaRequest = req;
        if (!_.isObjectLike(loaRequest)) {
            loaRequest = new LoaRequest();
            loaRequest.requestClass = requestClass;
            loaRequest.requestDate = new Date();
        }
        loaRequest.loaRequestTabs = loaRequestTabs;
        var options = new LoaRequestDialogOptions(this.orgLevel.id, loaRequest);
        LMCreationDialogComponent.openDialog(options, this.modalService);
    };
    LMManagementService.prototype.modifyRequest = function (request) {
        window.alert('Will be opened dialog for edit Leave Request ' + request.id);
    };
    LMManagementService.prototype.loadRequests = function () {
        this.loadRequests$.next();
    };
    LMManagementService.prototype.subscribeToLoadRequests = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadRequests$.subscribe(callback);
    };
    LMManagementService.prototype.navButtonClick = function (isNext) {
        this.navButton$.next(isNext);
    };
    LMManagementService.prototype.subscribeToNavButtonClick = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.navButton$.subscribe(callback);
    };
    LMManagementService.leaveTypeMobileWidth = 30;
    LMManagementService.leaveTypeDesktopWidth = 130;
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], LMManagementService.prototype, "orgLevel$", void 0);
    return LMManagementService;
}(ManagementBaseService));
export { LMManagementService };
