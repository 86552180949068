import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { PunchAttestationManagementService } from '../../services/index';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
var PunchAttestationComponent = /** @class */ (function () {
    function PunchAttestationComponent(managementService, changeDetector, route) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.route = route;
        this.isLoading = true;
        this.groups$ = new BehaviorSubject([]);
    }
    PunchAttestationComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route.snapshot.data) {
            var lookupData = this.route.snapshot.data[1];
            this.attestationTypeLookup = lookupData[0];
            this.restrictedAnswerLookup = lookupData[1];
            this.punchAttestationAnswerCodes = lookupData[2].items;
            this.punchAttestationPunchType = lookupData[3].items;
        }
        this.load();
        this.loadedSubscription = this.managementService.onLoaded$.subscribe(function (groups) {
            _this.groups$.next(groups);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.statusSubscription = this.managementService.onLoadStatus$.subscribe(function (isLoading) {
            _this.isLoading = isLoading;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.load();
            }
        });
        this.managementService.scrollBottom$.subscribe(function (scrollBottom) {
            if (scrollBottom) {
                _this.scrollPosition = _this.scrollContainer.nativeElement.scrollHeight;
            }
            _this.scrollToPosition();
        });
        this.getPermissions();
    };
    PunchAttestationComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PunchAttestationComponent.prototype.load = function () {
        var org = new OrgLevel();
        org.id = 0;
        this.managementService.loadgroups(org);
    };
    PunchAttestationComponent.prototype.getPermissions = function () {
        this.managementService.getPermissions(this.orgLevelId);
    };
    PunchAttestationComponent.prototype.scrollToPosition = function () {
        this.scrollContainer.nativeElement.scrollTop = this.scrollPosition;
    };
    PunchAttestationComponent.prototype.onScroll = function (event) {
        this.scrollPosition = event.target.scrollTop;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PunchAttestationComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchAttestationComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchAttestationComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchAttestationComponent.prototype, "statusSubscription", void 0);
    return PunchAttestationComponent;
}());
export { PunchAttestationComponent };
