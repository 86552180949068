import { DetailsPartner } from "../detail-screen/details-partner";
import { DetailsAgencyModel } from "../detail-screen/details-agency-model";
export class DailyOpenShiftDetailsData {
  public Clientid?: string;
  public OrgLevelId?: number;
  public SubscriptionName?: string;
  public SlxshiftId?: string;
  public SlxshiftGroupId?: string;
  public PositionGroupId?: string;
  public JobCode?: string;
  public JobDesc?: string;
  public Shifttype?: string;
  public ShiftDate?: string;
  public ShiftStart?: string;
  public ShiftEnd?: string;
  public UnitId?: string;
  public DepartmentId?: string;
  public Hours?: string;
  public shiftRequestbyId?: number;
  public shiftRequestbyName?: string;
  public SubTypeId?: string;
  public PartnerId?: string;
  public parentId?: number;
  public AgencyId?: number;
  public PositionErrorMessage?: boolean;
  public detailsPartner?: DetailsPartner;
  public ShiftStartDateErrorMessage?: boolean;
  public AgencyList?: DetailsAgencyModel[];
}