import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { SummarySectionBase } from '../summary-section-base/summary-section.base';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { aggregateBy } from '@progress/kendo-data-query';
var PeriodsSummaryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PeriodsSummaryComponent, _super);
    function PeriodsSummaryComponent(management) {
        var _this = _super.call(this, management) || this;
        _this.percentFormat = '0.00"%"';
        _this.dollarFormat = '$0.00';
        _this.numberFormat = '0.00';
        _this.appConfig = appConfig;
        _this.aggregates = [
            { field: 'productiveHours', aggregate: 'sum' },
            { field: 'nonProductiveHours', aggregate: 'sum' },
            { field: 'totalHours', aggregate: 'sum' },
            { field: 'differenceHours', aggregate: 'sum' },
            { field: 'percentageHours', aggregate: 'sum' },
            { field: 'payrollAmount', aggregate: 'sum' },
            { field: 'differenceAmount', aggregate: 'sum' },
            { field: 'differencePercentage', aggregate: 'sum' }
        ];
        return _this;
    }
    PeriodsSummaryComponent.prototype.ngOnInit = function () {
        this.setupManagementService();
    };
    PeriodsSummaryComponent.prototype.ngOnDestroy = function () {
        this.destroy();
    };
    PeriodsSummaryComponent.prototype.checkHrsPtcDifference = function (item, diff) {
        return Math.abs(item.percentageHours) > diff;
    };
    PeriodsSummaryComponent.prototype.checkAmountsPtcDifference = function (item, diff) {
        return Math.abs(item.differencePercentage) > diff;
    };
    PeriodsSummaryComponent.prototype.onDataLoaded = function () {
        _.noop();
    };
    PeriodsSummaryComponent.prototype.refreshGrid = function () {
        _super.prototype.refreshGrid.call(this);
        this.totals = aggregateBy(this.container.records, this.aggregates);
    };
    PeriodsSummaryComponent.prototype.onExcelExport = function (e) {
        var rows = e.workbook.sheets[0].rows;
        var cells = _.filter(rows, { type: 'footer' });
        _.forEach(_.head(cells).cells, function (cell) {
            var value = parseFloat(cell.value);
            if (_.isFinite(value)) {
                cell.value = value;
            }
        });
    };
    return PeriodsSummaryComponent;
}(SummarySectionBase));
export { PeriodsSummaryComponent };
