import { IState, State } from '../../../organization/models/index';
import { IWcCodeDefinitionDto, WcCodeDefinition } from './wc-code-definition';
import { ThreeChoiceEnum } from './three-choice.enum';

export interface IWCCompensationReportDto {
    //injury details
    accidentState: IState;
    accidentCause: IWcCodeDefinitionDto;
    injuryByNature: IWcCodeDefinitionDto;

    bodySide: string;
    didRequireTreatment: boolean;

    dateErNotified: string;
    dateTreatmentReceived: string;

    //leave details
    lastDateWorked: string
    expectReturnDate: string;
    fullPayForInjuryDate: boolean;
    salaryContinued: boolean;
    hoursWorkedPerDay: number;
    daysWorkedPerWeek: number;
    paymentFreq: string;

    isLosingTime: boolean;
    dateLoseBegan: string;
    dateLoseEnd: string;

    isEmpQualifyFLMA: boolean;

    wasEmpPlacedOnTrDuty: boolean;
    empTrDutyStart: string;
    empTrDutyEnd: string;

    //Location details
    occurenceAddress: string;
    occurenceCity: string;
    occurenceState: string;
    occurenceZip: string;

    isOccurEmpOnPremises: boolean;
    isSafeguardsProvided: boolean;
    isErQuestionOnClime: boolean;
    isDuringEmployment: boolean;
    isDrugsInvolved: boolean;
    isRepresByAttorney: boolean;
    isWcExplained: boolean;
    isGivenMedicalProviders: boolean;
    isMPPutInWcFile: boolean;
    isGivenNotifyForm: boolean;
    isNFInWcFile: boolean;
    erPhoneNumber: string;
    comments: string;
    isRecordOnly: boolean;

    //injury details
    natureOfInjuryDesc: string;

    //emp details other
    totalGrossWeekly: number;
    numberOfWeeksUsed: number;
    valOfDiscountedFringes: number;
    occupation: string;
    wasVolunteer: boolean;
    wasCertified: boolean;
    addressServiceAgency: string;
    preparerName: string;
    preparerSignature: string;
    preparerPhoneNumber: string;
    preparedDate: string;
}

export class WCCompensationReport {

    // vm props
    public lostDaysCount: number;
    public stateCode: string;

    //injury details
    public accidentState: State;
    public accidentCause: WcCodeDefinition;
    public injuryByNature: WcCodeDefinition;

    public bodySide: string;

    public didRequireTreatment: boolean;

    public dateErNotified: Date;
    public dateTreatmentReceived: Date;
    public natureOfInjuryDesc: string;

    //leave details
    public lastDateWorked: Date;
    public expectReturnDate: Date;
    public fullPayForInjuryDate: { id: ThreeChoiceEnum, name: string };
    public salaryContinued: { id: ThreeChoiceEnum, name: string };
    public hoursWorkedPerDay: number;
    public daysWorkedPerWeek: number;
    public paymentFreq: string;

    public isLosingTime: boolean;
    public dateLoseBegan: Date;
    public dateLoseEnd: Date;

    public isEmpQualifyFLMA: boolean;

    public wasEmpPlacedOnTrDuty: boolean;
    public empTrDutyStart: Date;
    public empTrDutyEnd: Date;

    //Location details
    public occurenceAddress: string;
    public occurenceCity: string;
    public occurenceState: string;
    public occurenceZip: string;

    // worker comp details
    public isOccurEmpOnPremises: boolean;
    public isSafeguardsProvided: boolean;
    public isErQuestionOnClime: boolean;
    public isDuringEmployment: boolean;
    public isDrugsInvolved: boolean;
    public isRepresByAttorney: boolean;
    public isWcExplained: boolean;
    public isGivenMedicalProviders: boolean;
    public isMPPutInWcFile: boolean;
    public isGivenNotifyForm: boolean;
    public isNFInWcFile: boolean;

    //employer
    public erPhoneNumber: string;
    public comments: string;
    public isRecordOnly: boolean;


    //emp details other
    public totalGrossWeekly: number;
    public numberOfWeeksUsed: number;
    public valOfDiscountedFringes: number;
    public occupation: string;
    public wasVolunteer: boolean;
    public wasCertified: boolean;
    public addressServiceAgency: string;

    //signatures
    public preparerName: string;
    public preparerSignature: string;
    public preparerPhoneNumber: string;
    public preparedDate: Date;
}