import * as tslib_1 from "tslib";
import { LicenseType } from './../../../organization/models/lookup/license-type';
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { ChangeManagementService } from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';
import { NgForm } from '@angular/forms';
import { LicenseTypesManagementService } from '../../services/license-types/license-types-management.service';
var LicenseTypesComponent = /** @class */ (function () {
    function LicenseTypesComponent(manageService, changeService) {
        this.manageService = manageService;
        this.changeService = changeService;
    }
    LicenseTypesComponent.prototype.ngOnInit = function () {
        this.manageService.init();
    };
    LicenseTypesComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    LicenseTypesComponent.prototype.addHandler = function () {
        this.closeEditor();
        var type = new LicenseType();
        type.licenseTypeID = 0;
        this.isAdding = true;
        this.editedEntry = type;
        this.grid.addRow(this.editedEntry);
        this.changeService.changeNotify();
    };
    LicenseTypesComponent.prototype.editHandler = function (event) {
        var _this = this;
        this.closeEditor();
        this.isAdding = false;
        this.editedEntry = Object.assign({}, event.dataItem);
        this.editedRowIndex = event.rowIndex;
        this.grid.editRow(this.editedRowIndex);
        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(function () {
            if (_this.mainForm.dirty) {
                _this.changeService.changeNotify();
            }
        });
    };
    LicenseTypesComponent.prototype.saveHandler = function () {
        if (this.isAdding) {
            this.addType(this.editedEntry);
        }
        else {
            this.saveType(this.editedEntry);
        }
        this.closeEditor();
    };
    LicenseTypesComponent.prototype.removeHandler = function (event) {
        this.deletingEntryId = event.dataItem.licenseTypeID;
    };
    LicenseTypesComponent.prototype.cancelHandler = function () {
        this.closeEditor();
        this.unsubscribeMainForm();
        this.changeService.clearChanges();
    };
    LicenseTypesComponent.prototype.sortChangeHandler = function (sort) {
        this.manageService.setSort(sort);
    };
    LicenseTypesComponent.prototype.closeEditor = function () {
        this.grid.closeRow(this.editedRowIndex);
        this.isAdding = false;
        this.editedEntry = undefined;
        this.editedRowIndex = undefined;
    };
    LicenseTypesComponent.prototype.onPopoverAction = function (acceptPopover, isDelete) {
        if (isDelete) {
            this.manageService.delete(this.deletingEntryId);
        }
        this.deletingEntryId = 0;
        acceptPopover.hide();
    };
    LicenseTypesComponent.prototype.addType = function (type) {
        var _this = this;
        this.manageService.add(type).then(function () {
            _this.handleApiCallCompletion();
        });
    };
    LicenseTypesComponent.prototype.saveType = function (type) {
        var _this = this;
        this.manageService.save(type).then(function () {
            _this.handleApiCallCompletion();
        });
    };
    LicenseTypesComponent.prototype.handleApiCallCompletion = function () {
        this.unsubscribeMainForm();
        this.changeService.clearChanges();
    };
    LicenseTypesComponent.prototype.unsubscribeMainForm = function () {
        if (this.mainFormSubscription) {
            this.mainFormSubscription.unsubscribe();
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LicenseTypesComponent.prototype, "mainFormSubscription", void 0);
    return LicenseTypesComponent;
}());
export { LicenseTypesComponent };
