import * as _ from 'lodash';
var TimepickerInputComponent = /** @class */ (function () {
    function TimepickerInputComponent() {
        this.format = 'hh:mm a';
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.readonly = false;
    }
    Object.defineProperty(TimepickerInputComponent.prototype, "watermarkStyleUnlessTouched", {
        set: function (v) {
            this.watermarkClass = !!v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimepickerInputComponent.prototype, "hasPlaceholder", {
        get: function () {
            return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
        },
        enumerable: true,
        configurable: true
    });
    TimepickerInputComponent.prototype.onChangeValue = function (time) {
        this.value = time;
        this.onChangeCallback(time);
    };
    TimepickerInputComponent.prototype.writeValue = function (time) {
        if (_.isDate(time) || _.isNull(time)) {
            this.value = time;
        }
    };
    TimepickerInputComponent.prototype.onBlur = function () {
        this.onTouchedCallback();
    };
    TimepickerInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    TimepickerInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    return TimepickerInputComponent;
}());
export { TimepickerInputComponent };
