export interface IPayrollConfig {
  api: {
    ta: {
      root: string;
      orglevel: {
        root: string;
        payroll: {
          root: string;
          exports: string;
          release: string;
          cancel: string;
          tests: string;
          cycles: string;
          run: string;
          paycycles: string;
          config: string;
          global: string;
          lock: string;
          unlock: string;
          export: {
            root: string;
            status: string;
            inprogress: string;
          };
        }
      },
      accruals: {
        root: string,
        exists: string        
      }
    },
    signalR: {
      root: string;
      signalRHub: string;
      signalRApi: string;
      methodName:string;
    }
  };
}
export const payrollConfig: IPayrollConfig = {
  api: {
    ta: {
      root: 'ta',
      orglevel: {
        root: 'orgLevel',
        payroll: {
          root: 'payroll',
          exports: 'exports',
          release: 'release',
          cancel: 'cancel',
          tests: 'tests',
          cycles: 'cycles',
          run: 'run',
          paycycles: 'paycycles',
          config: 'config',
          global: 'global',
          lock: 'lock',
          unlock: 'unlock',
          export: {
            root: 'export',
            status: 'status',
            inprogress: 'inprogress'
          }
        },
      },
      accruals: {
        root: 'accruals',        
        exists: 'exists'
        
      }
    },
    signalR: {
      root: 'exports',
      signalRHub: 'payroll-export-data',
      signalRApi: 'signalr',
      methodName: 'PayrollExportStatusGroupName'
    }
  }
};

