import * as tslib_1 from "tslib";
import { IdealScheduleOverviewRequest } from './../../../models/ideal-schedule/ideal-schedule-overview-request';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import * as _ from 'lodash';
import { unsubscribe } from '../../../../core/decorators/index';
import { IdealSchedulePositionDefinition, IdealScheduleList, IdealSchedulePosition, IdealSchedulePositionPeriod } from '../../../models/index';
import { IdealScheduleStateService, IdealScheduleToolbarService } from '../../../services/index';
import { ModalService, StartEndDatesDialogComponent, ConfirmDialogComponent, ConfirmChangesDialogComponent, DialogOptions } from '../../../../common/index';
import { IdealScheduleOverviewDialogComponent } from '../ideal-schedule-overview-dialog/ideal-schedule-overview-dialog.component';
import { IdealScheduleComponent } from '../ideal-schedule.component';
import { IdealScheduleImportTemplate } from './../../../../configuration/models/ideal-schedule/ideal-schedule-import-template';
import { IdealScheduleImportTemplateDialogComponent } from '../ideal-schedule-import-template-dialog/ideal-schedule-import-template-dialog.component';
import { AppSettingsManageService } from './../../../../../app/app-settings/services';
var IdealScheduleToolbarComponent = /** @class */ (function () {
    function IdealScheduleToolbarComponent(idealScheduleToolbarService, stateService, changeDetector, modalService, appSettingsManageService) {
        this.idealScheduleToolbarService = idealScheduleToolbarService;
        this.stateService = stateService;
        this.changeDetector = changeDetector;
        this.modalService = modalService;
        this.appSettingsManageService = appSettingsManageService;
        this.isDisabled = false;
        this.isDownloadImportOptionEnabled = false;
        this.positions = [];
        this.positionDefinitions = [];
    }
    Object.defineProperty(IdealScheduleToolbarComponent.prototype, "selectedPosition", {
        get: function () {
            return this.m_selectedPosition;
        },
        set: function (value) {
            this.m_selectedPosition = value;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.positionDefinitionsSubscription = this.stateService.onIdealScheduleList$.subscribe(function (list) {
            if (!list) {
                return;
            }
            _this.positionDefinitions = list.positions;
            _this.positions = _.map(list.positions, function (positionDefinition) { return positionDefinition.position; });
            var firstPosition = _.first(_this.positionDefinitions);
            if (_this.stateService.selectedPositionDefinition) {
                _this.selectedPosition = _this.stateService.selectedPositionDefinition.position;
            }
            else if (firstPosition) {
                _this.selectedPosition = firstPosition.position;
                _this.stateService.setSelectedPositionDefinition(firstPosition);
            }
            if (_this.stateService.selectedPosition != undefined && _this.stateService.selectedPosition.ranges && _this.stateService.selectedPosition.ranges[0] == null)
                _this.isDisabled = true;
        });
        this.appSettingsManageService.getAppServerConfig().then(function (config) {
            _this.isDownloadImportOptionEnabled = config.isDownloadImportOptionEnabled;
        });
    };
    IdealScheduleToolbarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IdealScheduleToolbarComponent.prototype.onPositionChanged = function (position) {
        var _this = this;
        if (this.selectedPosition.id !== position.id && this.stateService.selectedPosition && this.stateService.selectedPosition.hasChanges) {
            ConfirmChangesDialogComponent.openDialog('Warning', this.modalService, function (result) {
                if (result) {
                    _this.changePositionInState(position);
                }
                else {
                    _this.selectedPosition = _.clone(_this.selectedPosition);
                }
            });
        }
        else {
            this.changePositionInState(position);
        }
        this.stateService.GetIdealShiftValidationData();
    };
    IdealScheduleToolbarComponent.prototype.changePositionInState = function (position) {
        this.selectedPosition = position;
        var positionDefinition = _.find(this.positionDefinitions, function (positionDefinition) {
            return positionDefinition.position.id === position.id;
        });
        if (this.stateService.selectedPositionDefinition &&
            this.stateService.selectedPositionDefinition.position.id == positionDefinition.position.id)
            return;
        this.stateService.setSelectedPositionDefinition(positionDefinition);
    };
    IdealScheduleToolbarComponent.prototype.onPeriodChanged = function (period) {
        if (this.stateService.selectedPositionDefinitionPeriod.name !== period.name) {
            this.stateService.selectedPositionPeriodChanged(period);
        }
    };
    IdealScheduleToolbarComponent.prototype.onSave = function () {
        var _this = this;
        var position = this.stateService.selectedPosition;
        var initDates = {
            startDate: moment(position.startDate),
            endDate: moment(position.endDate)
        };
        StartEndDatesDialogComponent.openDialog(initDates, this.modalService, function (result, resultDates) {
            if (result) {
                _this.stateService.savePosition(position, resultDates.startDate.toDate(), resultDates.endDate.toDate());
            }
        });
    };
    IdealScheduleToolbarComponent.prototype.onDiscard = function () {
        var _this = this;
        ConfirmDialogComponent.openDialog('Discard changes', 'There is unsaved data on this screen. If you proceed, the unsaved data will be lost. Are you sure you wish to proceed?', this.modalService, function (result) {
            if (result) {
                _this.stateService.discardChanges();
            }
        });
    };
    IdealScheduleToolbarComponent.prototype.onOverviewClick = function () {
        var request = new IdealScheduleOverviewRequest();
        request.overviewDate = this.stateService.selectedPositionDefinitionPeriod.endDate;
        IdealScheduleOverviewDialogComponent.openDialog(this.modalService, function (result) {
            _.noop();
        }, request);
    };
    IdealScheduleToolbarComponent.prototype.getActionCaption = function () {
        return this.isDisabled == true ? "Data cannot be exported when there are no records" : "";
    };
    IdealScheduleToolbarComponent.prototype.openDownloadImportTemplateModal = function (isDownloadTemplate) {
        var _this = this;
        // ImportTemplateRequest data should be load after api works done
        // Import Template Dialog and Download Import Template Dialog both are used by one component called IdealScheduleImportTemplateDialogComponent
        this.stateService.state.isLoading = true;
        var importTemplateRequest = new IdealScheduleImportTemplate();
        importTemplateRequest.header = isDownloadTemplate ? 'Ideal Schedule Import' : 'Import Ideal Schedule';
        importTemplateRequest.buttonName = isDownloadTemplate ? 'Download Template' : 'Apply Imported Ideal Schedule';
        importTemplateRequest.organizationList = this.stateService.organizationDetails;
        importTemplateRequest.positionList = this.stateService.currentRangeConfigPositions;
        importTemplateRequest.isDownloadTemplate = isDownloadTemplate;
        importTemplateRequest.exceptionData = [];
        importTemplateRequest.isDefaultShiftGroupEnabled = this.stateService.isDefaultShiftGroupFlagEnabled;
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 800;
        dialogOptions.height = 500;
        dialogOptions.showCloseButton = true;
        dialogOptions.className = 'slx-import-template-custom';
        setTimeout(function () {
            _this.stateService.state.isLoading = false;
            IdealScheduleImportTemplateDialogComponent.openDialog(importTemplateRequest, dialogOptions, _this.modalService, function (result, data) { return console.log(result); });
        }, 1000);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleToolbarComponent.prototype, "positionDefinitionsSubscription", void 0);
    return IdealScheduleToolbarComponent;
}());
export { IdealScheduleToolbarComponent };
