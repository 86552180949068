import { combineReducers, Reducer } from 'redux';
import * as application from './application/index';
import * as orgLevel from './org-level/index';
import * as sidebar from './sidebar/index';
import * as common from '../common/store/index';
import * as authentication from '../authentication/store/index';
import * as employeeList from '../employee/employee-list/store/index';
import * as employee from '../employee/employee/store/index';
import * as timecards from '../time-and-attendance/store/index';
import * as masterSchedule from '../scheduler/store/master-schedule/index';
import * as individualSchedule from '../scheduler/store/individual-schedule/index';

export interface IAppState {
  application?: application.IApplication;
  orgLevel?: orgLevel.IOrgLevel;
  sidebar?: sidebar.ISidebar;
  masterSchedule?: masterSchedule.IMasterSchedule;
  wizard?: common.IWizardState;
  addEmployeeWizard?: employee.IAddEmployeeWizardStateRecord;
  employeeList?: employeeList.IEmployeeListState;
  session?: authentication.ISession;
  timecards?: timecards.ITimecardsState;
  individualSchedule?: individualSchedule.IIndividualScheduleState;
}

export const rootReducer: Reducer<IAppState> = combineReducers<IAppState>({
  application: application.applicationReducer,
  orgLevel: <any>orgLevel.orgLevelReducer,
  sidebar: sidebar.sidebarReducer,
  masterSchedule: <any>masterSchedule.masterScheduleReducer,
  wizard: common.wizardStateReducer,
  addEmployeeWizard: <any>employee.addEmployeWizardStateReducer,
  employeeList: <any>employeeList.employeeListStateReducer,
  session: <any>authentication.sessionReducer,
  timecards: <any>timecards.timecardsStateReducer,
  individualSchedule: <any>individualSchedule.individualScheduleStateReducer
});

export function deimmutify(store: any): any {
  return {
    application: store.application.toJS(),
    orgLevel: store.orgLevel.toJS(),
    sidebar: store.sidebar.toJS(),
    masterSchedule: store.masterSchedule.toJS(),
    wizard: store.wizard.toJS(),
    addEmployeeWizard: store.addEmployeeWizard.toJS(),
    employeeList: store.employeeList.toJS(),
    session: store.session.toJS(),
    timecards: store.timecards.toJS(),
    individualSchedule: store.individualSchedule.toJS()
  };
}

export function reimmutify(plain: any): any {
  return {
    application: application.applicationFactory(plain.application),
    orgLevel: orgLevel.orgLevelFactory(plain.orgLevel),
    sidebar: sidebar.sidebarFactory(plain.sidebar),
    masterSchedule: masterSchedule.masterScheduleFactory(plain.masterSchedule),
    wizard: common.wizardStateFactory(plain.wizardState),
    addEmployeeWizard: employee.addEmployeeWizardStateFactory(plain.addEmployeeWizard),
    employeeList: employeeList.employeeListStateFactory(plain.employeeList),
    session: authentication.sessionFactory(plain.session),
    timecards: timecards.timecardsStateFactory(plain.timecards),
    individualSchedule: individualSchedule.individualScheduleStateFactory(plain.individualSchedule)
  };
}
