import { Provider } from '@angular/core';
import { DialogOptions } from '../../../common/models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { OrgLevelSearchComponent } from '../org-level-search/org-level-search.component';
var OrgLevelSearchDialogComponent = /** @class */ (function () {
    function OrgLevelSearchDialogComponent(options, modalService) {
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
    }
    Object.defineProperty(OrgLevelSearchDialogComponent.prototype, "selectedOrgLevel", {
        get: function () {
            return this.orgLevelSearch.selectedOrgLevel;
        },
        enumerable: true,
        configurable: true
    });
    OrgLevelSearchDialogComponent.openDialog = function (modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 500;
        dialogOptions.width = 850;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(OrgLevelSearchDialogComponent, 'Search Org Level', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, dialog.selectedOrgLevel ? dialog.selectedOrgLevel.orgLevel : null);
        });
        return dialog;
    };
    OrgLevelSearchDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    OrgLevelSearchDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return OrgLevelSearchDialogComponent;
}());
export { OrgLevelSearchDialogComponent };
