import { Component, OnInit, OnDestroy, Input, Output, Host, ViewChild, NgZone, Provider, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';

import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsAttendancePointsDefinitionComponent } from '../employee-sections-attendance-points-definition/employee-sections-attendance-points-definition.component';
import { ScrollWatchService, ISelectableItemContainer, SelectableItemsProducer, DateTimeService, DialogOptions } from '../../../../../common/index';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsAttendancePoints, EmployeeSectionsAttendancePointsEntry, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsPerformanceApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';

import { RangeDates, IRangeDates } from '../../../../../common/models/range-dates';

import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy
} from '@progress/kendo-data-query';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-attendance-points',
  templateUrl: 'employee-sections-attendance-points.component.html',
  styleUrls: ['employee-sections-attendance-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsAttendancePointsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input()
  public set employeeSubsectionAttendancePoints(attendancePoints: EmployeeSectionsAttendancePoints) {
    this.attendancePoints = attendancePoints;
    if (attendancePoints !== null) {
      this.selectableAttendancePoints = this.selectableItemsProducer.produceSelectable<EmployeeSectionsAttendancePointsEntry>(attendancePoints.points);
      this.refreshGrid();
      this.isAnyItemSelected = false;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;
  @Input()
  public employeeId: number;

  public get form(): AbstractControl {
    return null;
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }
  
  public isAnyItemSelected: boolean;
  public selectableAttendancePoints: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>[];
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public totalPoints: number = 0;
  public totalCurrentPoints: number = 0;
  public pageSize: number = 10;
  public skip: number = 0;

  private attendancePoints: EmployeeSectionsAttendancePoints;
  private modalService: ModalService;
  private dateTimeService: DateTimeService;
  private employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService;
  private selectableItemsProducer: SelectableItemsProducer;
  private changeDetector: ChangeDetectorRef;

  constructor(
    employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService,
    dateTimeService: DateTimeService,
    selectableItemsProducer: SelectableItemsProducer,
    modalService: ModalService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    changeDetector: ChangeDetectorRef
  ) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsPerformanceApiService, 'employeeSectionsPerformanceApiService');
    Assert.isNotNull(dateTimeService, 'dateTimeService');
    this.employeeSectionsPerformanceApiService = employeeSectionsPerformanceApiService;
    this.modalService = modalService;
    this.dateTimeService = dateTimeService;
    this.selectableItemsProducer = selectableItemsProducer;
    this.changeDetector = changeDetector;
    this.sort = [{ field: 'item.dateOn.fieldValue', dir: 'desc' }];
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.attendancePoints;
  }

  protected loadSubsection(): void {
    let daysCount: number = this.dateTimeService.GetDiffDays(this.startDate, this.endDate);
    if (!daysCount || daysCount === 0) {
      return;
    }
    this.startProgress();
    this.skip = 0;
    this.employeeSectionsPerformanceApiService.getPerformanceAttendancePoints(this.employeeId, this.startDate, daysCount)
      .then((attendancePoints: EmployeeSectionsAttendancePoints) => {
        this.employeeSubsectionAttendancePoints = attendancePoints;
        this.stopProgress();
      })
      .catch((error: any) => {
        this.stopProgress();
      });
  }

  public onSortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public onPageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.refreshGrid();
  }

  public onRemovePointsClicked(): void {
    this.modalService.globalAnchor.openConfirmDialog('Confirmation', 'Are you sure that you wish to remove this Attendance Points?', (result: boolean) => {
      if (result) {
        let pointIds: number[] = [];
        this.selectableAttendancePoints.forEach((i: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>) => { if (i.selected) pointIds.push(i.item.id); });
        this.doRemove(pointIds);
      }
    });
  }

  public onAddPointsClicked(): void {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 600;
    dialogOptions.width = 800;
    let dialog: EmployeeSectionsAttendancePointsDefinitionComponent = this.modalService.globalAnchor.openDialog(
      EmployeeSectionsAttendancePointsDefinitionComponent,
      'Select one or more entries and an applicable date.',
      dialogOptions, undefined, (result: boolean) => {
        if (result) {
          let selectedDate: Date = dialog.selectedDate;
          let selectedIds: number[] = dialog.selectedIds;
          this.doAdd(selectedDate, selectedIds);
        }
      });

      dialog.empId = this.employeeId;
      
      if (!dialog.isLoading && dialog.initialized) {
        dialog.loadSubsection ();
      }
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadSubsection();
  }

  public onItemSelectionChanged(): void {
    this.isAnyItemSelected = this.selectableAttendancePoints.some((i: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>) => i.selected);
  }

  private doAdd(dateOn: Date, pointsDefinitionIds: number[]): void {
    this.startProgress();
    this.employeeSectionsPerformanceApiService.addAttendancePoints(this.employeeId, dateOn, pointsDefinitionIds)
      .then((result: any) => {
        this.state.isLoaded = true;
        this.stopProgress();
        this.loadSubsection();
      })
      .catch((error: any) => {
        this.stopProgress();
      });
  }

  private doRemove(pointIds: number[]): void {
    this.startProgress();
    this.employeeSectionsPerformanceApiService.deleteAttendancePoints(this.employeeId, pointIds)
      .then((result: any) => {
        this.stopProgress();
        this.loadSubsection();
      })
      .catch((error: any) => {
        this.stopProgress();
      });
  }

  private refreshGrid(): void {
    if (!this.selectableAttendancePoints) {
      this.gridView = null;
      return;
    }
    this.totalPoints = this.getTotalPoints();
    this.totalCurrentPoints = this.getTotalCurrentPoints();
    let sortedRecords: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>[] = orderBy(this.selectableAttendancePoints, this.sort);
    let pagedRecords: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>[] = sortedRecords.slice(this.skip, this.skip + this.pageSize);
    this.gridView = {
      data: pagedRecords,
      total: this.selectableAttendancePoints.length
    };
  }

  private getTotalPoints(): number {
    let total: number = 0;
    _.forEach(this.selectableAttendancePoints, (cont: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>) => {
      if (cont.item && cont.item.value) {
        total += cont.item.value.fieldValue;
      }
    });
    return total;
  }

  private getTotalCurrentPoints(): number {
    let total: number = 0;
    _.forEach(this.selectableAttendancePoints, (cont: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>) => {
      if (cont.item && cont.item.value2) {
        total += cont.item.value2.fieldValue;
      }
    });
    return total;
  }

}

