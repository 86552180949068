<div class="modal-body-grid">
    <slx-report-daily-today-staff-config (hasValueChanged)="onValueChanged($event)"
        (reportConfigChanged)="onReportConfigChanged($event)" [forcedLoad]="forcedLoad">
    </slx-report-daily-today-staff-config>
</div>
<div class="modal-footer wrapper">
    <div class="btn red pad-top-10" (click)="onCancel()"><i class="fas fa-times slx-button__icon"
            aria-hidden="true"></i>Cancel
    </div>
    <div class="btn green pad-top-10" (click)="onSave()" [ngClass]="{ 'disabled-content': !hasValueChange }"><i
            class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save</div>
</div>