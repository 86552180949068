import { Directive, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[slxDailyTimecardSectionLeft]',
})
export class DailyTimecardSectionLeftDirective {
}

@Directive({
  selector: '[slxDailyTimecardSectionRight]',
})
export class DailyTimecardSectionRightDirective {
}

@Directive({
  selector: '[slxDailyTimecardSectionContent]',
})
export class DailyTimecardSectionContentDirective {
}
