/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-roles-assignement.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../user-roles-assignement-action-bar/user-roles-assignement-action-bar.component.ngfactory";
import * as i5 from "../user-roles-assignement-action-bar/user-roles-assignement-action-bar.component";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "../user-roles-assignement-grid/user-roles-assignement-grid.component.ngfactory";
import * as i9 from "../user-roles-assignement-grid/user-roles-assignement-grid.component";
import * as i10 from "../../../services/users/user-roles-management.service";
import * as i11 from "../../../services/users/user-roles-api.service";
import * as i12 from "./user-roles-assignement.component";
var styles_UserRolesAssignementComponent = [i0.styles];
var RenderType_UserRolesAssignementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserRolesAssignementComponent, data: {} });
export { RenderType_UserRolesAssignementComponent as RenderType_UserRolesAssignementComponent };
export function View_UserRolesAssignementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-user-roles-assignement-action-bar", [["class", "slx-content-toolbar-indents"]], null, null, null, i4.View_UserRolesAssignementActionBarComponent_0, i4.RenderType_UserRolesAssignementActionBarComponent)), i1.ɵdid(4, 114688, null, 0, i5.UserRolesAssignementActionBarComponent, [i6.Router, i6.ActivatedRoute, i7.Location], { rolesDecorator: [0, "rolesDecorator"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-user-roles-assignement-grid", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, i8.View_UserRolesAssignementGridComponent_0, i8.RenderType_UserRolesAssignementGridComponent)), i1.ɵdid(6, 245760, null, 0, i9.UserRolesAssignementGridComponent, [i6.ActivatedRoute], { rolesDecorator: [0, "rolesDecorator"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.rolesDecorator; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.rolesDecorator; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_UserRolesAssignementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-user-roles-assignement", [], null, null, null, View_UserRolesAssignementComponent_0, RenderType_UserRolesAssignementComponent)), i1.ɵprd(512, null, i10.UserRolesManagementService, i10.UserRolesManagementService, [i11.UserRolesApiService]), i1.ɵdid(2, 245760, null, 0, i12.UserRolesAssignementComponent, [i10.UserRolesManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var UserRolesAssignementComponentNgFactory = i1.ɵccf("slx-user-roles-assignement", i12.UserRolesAssignementComponent, View_UserRolesAssignementComponent_Host_0, {}, {}, []);
export { UserRolesAssignementComponentNgFactory as UserRolesAssignementComponentNgFactory };
