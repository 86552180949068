import { DialogOptions2, DialogModeSize } from './../../../../common/models/dialog-options';
import { Component, Input, OnInit, Provider, ViewChild, OnDestroy } from '@angular/core';
import * as _ from 'lodash';

import { DialogOptions, IDialog } from '../../../../common/index';

import { ModalService } from '../../../../common/services/index';
import { MassAssignPayUnitsRequest } from '../../models/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-mass-assignment-payunits-dialog',
  templateUrl: 'mass-assignment-payunits-dialog.component.html',
  styleUrls: ['mass-assignment-payunits-dialog.component.scss']
})
export class MassAssignmentPayUnitsDialogComponent implements IDialog, OnInit, OnDestroy {


  public options: DialogOptions;
  public dialogResult: boolean;
  public request: MassAssignPayUnitsRequest;

  private modalService: ModalService;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};


  public static openDialog(request: MassAssignPayUnitsRequest, modalService: ModalService, callback: (result: boolean, cmd: any) => void): MassAssignmentPayUnitsDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: MassAssignPayUnitsRequest,
        useValue: request
      }
    ];
    const title = 'Mass Assign Pay Units';
    let dialog: MassAssignmentPayUnitsDialogComponent = modalService.globalAnchor
      .openDialog2(MassAssignmentPayUnitsDialogComponent, title, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, undefined);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    request: MassAssignPayUnitsRequest
  ) {
    this.modalService = modalService;
    this.options = options;
    this.request = request;
  }

  public ngOnInit() {
  }

  public onClose(res: boolean): void {
    this.dialogResult = res;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public ngOnDestroy() { }
}
