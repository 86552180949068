import * as tslib_1 from "tslib";
import { ModalService } from './../../../../../../common/services/modal/modal.service';
import { ConfirmDialog2Component } from './../../../../../../common/components/confirm-dialog2/confirm-dialog2.component';
import { ConfirmOptions } from './../../../../../../common/components/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../../app/app.config';
import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitEnrolledEmployee, BenefitDetailsTier } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/index';
import { NgForm } from '@angular/forms';
var BenefitOption401kGridComponent = /** @class */ (function () {
    function BenefitOption401kGridComponent(manService, modalService, changeDetector) {
        this.manService = manService;
        this.modalService = modalService;
        this.changeDetector = changeDetector;
        this.columnGroup = 'BenefitsOptionGrid';
        this.data = [];
        this.isLoading = true;
        this.editedRowsCount = 0;
        this.minEmployeeContribution = 0;
        this.currencyFormat = 'c2';
        this.stepcurrency = 1;
        this.pageSize = 50;
        this.minAmt = 0;
        this.maxAmt = 999999999.99;
        this.onEnrollmentCancel = new EventEmitter();
        this.subscriptions = {};
        this.isFormValid = true;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.selectableSettings = {
            checkboxOnly: true
        };
        this.appConfig = appConfig;
    }
    BenefitOption401kGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.tierDetails && _.size(this.tierDetails.options) > 0) {
            var option_1 = _.head(this.tierDetails.options);
            this.maxEmployeeContribution = _.isFinite(option_1.maxEmpContribution) ? option_1.maxEmpContribution : 100;
            _.forEach(this.selectedEmployees, function (item) {
                item['maxEmpContribution'] = option_1.maxEmpContribution;
                item['maxMatchGrant'] = option_1.maxMatchGrant;
                item['limit'] = option_1.limitAmount;
            });
            this.maxAmt = ((this.maxAmt) && option_1.limitAmount > 0) ? option_1.limitAmount : this.maxAmt;
        }
        else {
            this.showNoOptionsDialog();
        }
        this.refreshGrid();
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(function () {
            _this.isFormValid = _this.ngForm.valid;
            _this.manService.benefitsOptionTabDetailsChanged(!_this.isFormValid);
        });
    };
    BenefitOption401kGridComponent.prototype.updateRowsData = function () {
        this.manService.updateEmployeesUpdatedInfo(this.selectedEmployees);
        if (_.size(this.selectedEmployees) > 0) {
            var employeePercentage = _.some(this.selectedEmployees, function (employee) { return !_.isFinite(employee.employeeContributionPercentage >= 0); }) ? false : true;
            var employeeContribution = _.some(this.selectedEmployees, function (employee) { return !_.isFinite(employee.employeeContributionAmount >= 0); }) ? false : true;
            var hasNoContribution = !this.isFormValid || (employeePercentage || employeeContribution);
            this.manService.benefitsOptionTabDetailsChanged(hasNoContribution);
        }
    };
    BenefitOption401kGridComponent.prototype.empContrPercentageChange = function (dataItem) {
        if (_.isObjectLike(dataItem)
            && ((dataItem.employeeContributionPercentage < this.minEmployeeContribution || dataItem.employeeContributionPercentage > this.maxEmployeeContribution)
                || (dataItem.employeeContributionAmount < this.minAmt || dataItem.employeeContributionAmount > this.maxAmt))) {
            this.manService.benefitsOptionTabDetailsChanged(true);
            return;
        }
        this.updateRowsData();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    BenefitOption401kGridComponent.prototype.hasValue = function (empContribution) {
        return _.isFinite(empContribution);
    };
    BenefitOption401kGridComponent.prototype.ngOnDestroy = function () { };
    BenefitOption401kGridComponent.prototype.showNoOptionsDialog = function () {
        var _this = this;
        this.isLoading = true;
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('Warning', 'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.', this.modalService, function (result) {
            if (result) {
                _this.isLoading = false;
                _this.onEnrollmentCancel.emit(false);
            }
        }, options);
    };
    BenefitOption401kGridComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        if (!this.selectedEmployees) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.selectedEmployees, this.gridState.state);
    };
    BenefitOption401kGridComponent.prototype.isEmpContribSelected = function (entry) {
        return !!_.get(entry, 'employeeContributionPercentage', null) || (entry.employeeContributionPercentage === 0);
    };
    BenefitOption401kGridComponent.prototype.isEmpPercentSelected = function (entry) {
        return !!_.get(entry, 'employeeContributionAmount', null) || (entry.employeeContributionAmount === 0);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitOption401kGridComponent.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BenefitOption401kGridComponent.prototype, "orgLevel$", void 0);
    return BenefitOption401kGridComponent;
}());
export { BenefitOption401kGridComponent };
