import { OnInit, EventEmitter } from '@angular/core';
import { ModalService, DialogOptions } from '../../../../common/index';
import { Assert } from '../../../../framework/index';
import { AbstractControl, NgForm } from '@angular/forms';
var Edit1095CPopup = /** @class */ (function () {
    function Edit1095CPopup(options, modalService) {
        this.hasEmployee = false;
        this.currencyFormat = 'c2';
        this.minAmt = 0.0;
        this.maxAmt = 99999999.99;
        this.stepcurrency = 0.1;
        this.isEnrolled = false;
        Assert.isNotNull(options, 'options');
        this.options = options;
        Assert.isNotNull(modalService, 'modalService');
        this.modalService = modalService;
        this.dialogResult = false;
        this.isLoading = false;
        this.loadStatusChange = new EventEmitter();
    }
    Object.defineProperty(Edit1095CPopup.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Edit1095CPopup.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Edit1095CPopup.prototype.ngOnInit = function () {
        this.m_initialized = true;
        this.loadSubsection();
        this.resonTooltip = "This value will not appear on any official IRS reporting documents. It is for reference within this platform to help track why a particular code was used.";
        this.coverageTooltip = "Per IRS instructions: The Code Series 1 indicator codes specify the type of coverage, if any, offered to an employee, the employee’s spouse, and the employee’s dependents. The term Dependent has the specific meaning set forth in the Definitions section of these instructions. In addition, for this purpose an offer of coverage is treated as made to an";
        this.coverageTooltip += " employee’s dependents only if the offer of coverage is made to an unlimited number of dependents regardless of the actual";
        this.coverageTooltip += " number of dependents, if any, an employee has during any particular calendar month.";
        this.costTooltip = "Per IRS instructions: Enter the amount of the Employee Required Contribution, which is, generally, the employee share of the monthly cost for the lowest-cost, self-only minimum essential coverage providing minimum value that is offered to the employee.";
        this.enrollTooltip = "This value will not appear on any official IRS reporting documents. It is for reference within this platform to help track enrollment.";
        this.safeHarborTooltip = "Per IRS instructions: Code Series 2—Section 4980H Safe Harbor Codes and Other Relief for ALE Members. An ALE Member enters the applicable Code Series 2 indicator code, if any, on line 16 to report for one or more months of the calendar year that one of the following situations applied to the employee.";
        this.safeHarborTooltip += "• The employee was not employed or was not a full-time employee,";
        this.safeHarborTooltip += "• The employee enrolled in the minimum essential coverage offered,";
        this.safeHarborTooltip += "• The employee was in a Limited Non-Assessment Period with respect to section 4980H(b),";
        this.safeHarborTooltip += "• The ALE Member met one of the section 4980H affordability safe harbors with respect to this employee, or";
        this.safeHarborTooltip += "• The ALE Member was eligible for multiemployer interim rule relief for this employee.If no indicator code applies, leave line 16 blank.";
    };
    Object.defineProperty(Edit1095CPopup.prototype, "populateRequireField", {
        get: function () {
            if (this.selectedCoverage && this.selectedCoverage.id) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Edit1095CPopup.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    Edit1095CPopup.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    Edit1095CPopup.prototype.loadSubsection = function () {
        if (this.orgLevel && this.orgLevel.id) {
        }
    };
    Edit1095CPopup.prototype.ngOnDestroy = function () {
        // Must be, see #issueWithAOTCompiler
    };
    return Edit1095CPopup;
}());
export { Edit1095CPopup };
