export type TextEditorThemesDefinitions = 'dark' | 'red' | 'gray' | 'royal';

export class TextEditorThemes {
  public static readonly dark: TextEditorThemesDefinitions = 'dark';
  public static readonly red: TextEditorThemesDefinitions = 'red';
  public static readonly gray: TextEditorThemesDefinitions = 'gray';
  public static readonly royal: TextEditorThemesDefinitions = 'royal';
}

export class TextEditorOptions {
  public key: string;
  public theme: TextEditorThemesDefinitions;
  public toolbarButtons: string[];
  public placeholderText: string;
  public charCounterMax: number;
  public charCounterCount: boolean;
  public height: number;
  public heightMin: number;
  public heightMax: number;
}
