import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { appConfig } from '../../../../../../app.config';
import { BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { AppSettingsManageService } from '../../../../../../app-settings/services/app-settings-manage.service';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
var BenefitPayrollDeductionComponent = /** @class */ (function () {
    function BenefitPayrollDeductionComponent(man, appSettingsManageService) {
        this.man = man;
        this.appSettingsManageService = appSettingsManageService;
        this.enableBenefitDeduction = false;
        this.subscriptions = {};
        this.maxDedDate = new Date(appConfig.maxCorrectDate);
        this.minDedDate = new Date(appConfig.minCorrectDate);
        this.isDedBeforeBeneStartDt = false;
        this.isDedAfterBeneEndDt = false;
        this.isFormValid = true;
        this.appConfig = appConfig;
        this.dedEndDateChange = new EventEmitter();
        this.dedStartDateChange = new EventEmitter();
    }
    BenefitPayrollDeductionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.payrollDedStartDate = this.effectiveDate;
        this.payrollDedEndDate = moment(_.isNull(this.payrollDedEndDate) ? this.payrollDedEndDate : this.endDate).startOf('day').toDate();
        this.isDedBeforeBeneStartDt = _.lt(this.payrollDedStartDate, this.startDate);
        this.isDedAfterBeneEndDt = _.gt(this.payrollDedEndDate, this.endDate);
        this.minDedDate = _.isNull(this.dedStartDate) ? this.minDedDate : this.dedStartDate;
        this.maxDedDate = _.isNull(this.dedEndDate) ? this.maxDedDate : this.dedEndDate;
        if (_.gt(this.payrollDedEndDate, this.maxDedDate)) {
            this.payrollDedEndDate = this.maxDedDate;
        }
        this.getSettings();
        this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(function () {
            _this.isFormValid = _this.ngForm.valid;
            _this.man.changePayrollDeductionDate(!_this.isFormValid);
        });
    };
    BenefitPayrollDeductionComponent.prototype.onChangeStartDate = function () {
        this.isDedBeforeBeneStartDt = _.lt(this.payrollDedStartDate, this.startDate);
        this.dedStartDateChange.emit(this.payrollDedStartDate);
    };
    BenefitPayrollDeductionComponent.prototype.onChangeDedEndDate = function () {
        this.isDedAfterBeneEndDt = _.gt(this.payrollDedEndDate, this.endDate);
        this.dedEndDateChange.emit(this.payrollDedEndDate);
    };
    BenefitPayrollDeductionComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.enableBenefitDeduction = config.isBenefitDeductionEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitPayrollDeductionComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitPayrollDeductionComponent.prototype, "subscriptions", void 0);
    return BenefitPayrollDeductionComponent;
}());
export { BenefitPayrollDeductionComponent };
