import { IBenefitDetailsProvider } from './benefit-details-provider';
import { BenefitDetailsProviderEntity } from './benefit-details-provider-entity';
import { BenefitDetailsProviderLineStandartEntity } from './benefit-details-provider-line-standart-entity';
import { IBenefitDetailsLineStandart } from './benefit-details-line-standart';

export interface IBenefitDetailsSavedProvider {
  benefitProvider: IBenefitDetailsProvider;
  firstLine: IBenefitDetailsLineStandart;
}

export class BenefitDetailsSavedProvider {
  constructor(
    public provider: BenefitDetailsProviderEntity = null,
    public providerLine: BenefitDetailsProviderLineStandartEntity = null
  ) {}
}
