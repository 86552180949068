import { ScheduleActions } from '../../../organization/index';

export class MasterScheduleContextMenuRequest {
  public empId: string;
  public empName: string;
  public startDate: Date;
  public endDate: Date;
  public showGenerateSchedule: boolean;
  public selectedCommand: string;
  public selectedCommandPayload?: any;
  public isFromDifferntDepartment: boolean;
  public isPosted: boolean;
  public isStartCycleDate: boolean;
  public hasRotations: boolean;
  public scheduleActions: ScheduleActions;
  public shiftsPerWeek?: number;
  public rowData? : any;
}
