import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { filterBy, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';

import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';

import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FilterService } from '@progress/kendo-angular-grid';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { StateResetTypes } from '../../../../../core/models/settings/reset-types';
import { KendoGridStateHelper, LoaCategory, LoaActions, LoaRequestClass } from '../../../../../common/models/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { LoaRequest, LoaRosterState, LoaType, LoaRequestTabs, LoaRequestContainer } from '../../../models/index';
import { LMRosterManagementService, LMManagementService } from '../../../services/index';
import * as filterUtils from '../../../../../core/utils/kendo-ui-utils';
import { ILoaFilter } from '../../../models/loa-filter';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-roster-list',
  templateUrl: 'lm-roster-list.component.html',
  styleUrls: ['lm-roster-list.component.scss']
})
export class LMRosterListComponent implements OnInit, OnDestroy {
  public records: LoaRequest[];
  public storedRecords: LoaRequest[];
  public gridState: KendoGridStateHelper<LoaRequest>;
  public isDisabled: boolean;
  public appConfig: IApplicationConfig;
  public state: LoaRosterState;
  public pageSize: number = 30;
  public loaTypes: LoaType[];
  public selectedloaTypes: string[];
  public selectedEmpId: number;
  public selectedCategories: LoaCategory[];
  private typeFilter: any[] = [];

  private windowOpenedByLoaAction: boolean;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private manService: LMManagementService,
    private manRosterService: LMRosterManagementService
  ) {
    this.gridState = new KendoGridStateHelper<LoaRequest>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'estStartDate', dir: 'desc' }];

    this.gridState.gridComponentKey = 'LMGrid';
    this.gridState.gridControlStateKey = 'GridFiltersState';
    this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
    this.gridState.assignRestoredState = true;
    this.gridState.refreshGridAfterRestoreState = true;
    this.appConfig = appConfig;
    this.loaTypes = [];
  }

  public ngOnInit(): void {
    this.subscriptions.loaded = this.manRosterService
      .subscribeToRequestsLoaded((container: LoaRequestContainer) => this.assignRecords(container.requests));

    this.subscriptions.categoryFilter = this.manRosterService
      .subscribeToCategoryFilter((categories: LoaCategory[]) => this.applyFilter(categories));

    this.subscriptions.export = this.manRosterService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.state = this.manRosterService
      .subscribeToChangeState((s: LoaRosterState) => this.applyState(s));

    this.subscriptions.newLoaRequest = this.manService
      .subscribeToLoadRequests(() => this.manRosterService.loadLoaRequests());

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());

    this.subscriptions.routeFilters = this.manRosterService
      .subscribeToRouteFilter((filter: ILoaFilter) => this.applyFilterFromRoute(filter));
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onClickAttachaments(request: LoaRequest): void {
    this.manService.openCreationDialog(null, request, LoaRequestTabs.Attachments);
  }

  public getWidth(field: string): number {
    return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].width : 100;
  }

  public getFilter(field: string): string {
    return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].filter : null;
  }

  public isVisible(field: string): boolean {
    return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].displayed : false;
  }

  public getMediaForDesktop(): string {
    return `(min-width: ${this.appConfig.mobileMaxWidth + 1}px)`;
  }

  public getMediaForMobile(): string {
    return `(max-width: ${this.appConfig.mobileMaxWidth}px)`;
  }

  public onClickEmployee(req: LoaRequest): void {
    this.manService.openCreationDialog(null, req);
  }

  public onChangeLoaTypeFilter(values: any, filterService: FilterService): void {
    filterService.filter({
      filters: _.map(values, (val: any) => ({
        field: 'type.name',
        operator: 'eq',
        value: val.name
      })),
      logic: 'or'
    });
  }

  public categoryFilters(filter: CompositeFilterDescriptor): LoaType[] {
    this.typeFilter.splice(
      0, this.typeFilter.length,
      ...filterUtils.flatten(filter).map(({ value }) => {
        return { name: value, color: null };
      })
    );
    return this.typeFilter;
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  private assignRecords(records: LoaRequest[]): void {
    this.records = records;
    this.storedRecords = _.clone(records);
    this.loaTypes = _.uniqBy(_.map(this.storedRecords, (r: LoaRequest) => r.type), (l: LoaType) => l.name);
    this.refreshGrid();
  }

  private applyFilter(categories: LoaCategory[]): void {
    this.selectedCategories = categories;
    this.gridState.assignRestoredState = _.size(categories) === 0;
    this.refreshGrid();
  }

  private applyState(s: LoaRosterState): void {
    this.state = s;
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  private applyFilterFromRoute(filter: ILoaFilter): void {

    this.selectedloaTypes = [];

    let gridValid = true;

    if (_.size(filter.loaType) > 0) {
      this.selectedloaTypes = [filter.loaType];
      this.gridState.state.filter = filterUtils.composeFieldToKendoFilter(this.gridState.state.filter, 'type.name', 'eq', filter.loaType);
      gridValid = false;
      this.gridState.assignRestoredState = false;
    }

    this.selectedEmpId = null;
    if (_.isFinite(filter.empId)) {
      this.selectedEmpId = filter.empId;
      this.gridState.state.filter = filterUtils.composeFieldToKendoFilter(this.gridState.state.filter, 'employee.id', 'eq', filter.empId);
      gridValid = false;
      this.gridState.assignRestoredState = false;
    }

    if (!gridValid) {
      this.refreshGrid();
    }

    if (_.size(filter.action) > 0) {
      switch (filter.action) {
        case LoaActions.CreateNewContinuous:
          if (!this.windowOpenedByLoaAction) {
            this.windowOpenedByLoaAction = true;
            _.delay(() => {
              let req: LoaRequest = new LoaRequest();
              req.requestClass = LoaRequestClass.Continuous;
              req.requestDate = new Date();
              req.employee = { id: filter.empId, name: null }
              this.manService.openCreationDialog(null, req);
            }, 1000);
  }
          break;
        case LoaActions.CreateNewIntermittent:
          if (!this.windowOpenedByLoaAction) {
            this.windowOpenedByLoaAction = true;
            _.delay(() => {
              let req: LoaRequest = new LoaRequest();
              req.requestClass = LoaRequestClass.Intermittent;
              req.requestDate = new Date();
              req.employee = { id: filter.empId, name: null }
              this.manService.openCreationDialog(null, req);
            }, 1000);
          }
          break;
      }
    }
  }

  private refreshGrid(): void {
    if (!this.selectedCategories || this.selectedCategories.length === 0) {
      this.records = _.clone(this.storedRecords);
    } else {
      this.records = _.filter(this.storedRecords, (r: LoaRequest) => {
        let res = false;
        _.forEach(this.selectedCategories, (category: LoaCategory) => {
          res = res || _.includes(r.loaCategories, category);
        });
        return res;
      });
    }
    if (!this.records) {
      this.gridState.view = null;
      return;
    }

    this.records.map((each)=>{
      each.daysOnLeaveText = each.isIntermittent ? 0 : each.daysOnLeave;
      each.daysRemainingText = each.isIntermittent ? 0 : each.daysRemaining;
    });

    this.gridState.view = process(this.records, this.gridState.state);
  }

}
