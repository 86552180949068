export interface ITaAbsenceCode {
    id: number;
    code: string;
    description: string;
}

export class TaAbsenceCode {
    public id: number;
    public code: string;
    public description: string;
}
