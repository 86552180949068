import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { EmployeeSectionsContext } from '../../../models/index';
import { EmployeeCallLogManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { mutableSelect } from '../../../../../core/decorators/index';
import { IRangeDates } from '../../../../../common/models/range-dates';
var EmployeeCallLogComponent = /** @class */ (function () {
    function EmployeeCallLogComponent(employeeSectionsContext, managementService) {
        this.onClose = new EventEmitter();
        this.employeeSectionsContext = employeeSectionsContext;
        this.managementService = managementService;
        this.state = {
            isLoading: false,
            startDate: moment().subtract(1, 'week').toDate(),
            endDate: moment().toDate()
        };
        this.pageSize = 15;
        this.employeeLink = false;
        this.employeesIds = [this.employeeSectionsContext.employeeId];
    }
    EmployeeCallLogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
            _this.managementService.orgLevelChanged(_this.currentOrgLevel);
            _this.managementService.employeesChanged(_this.employeesIds);
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$.subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.messageCenterEnabledSubscription = this.managementService.messageCenterEnabled$.subscribe(function () {
            _this.managementService.dateRangeChanged(_this.state.startDate, _this.state.endDate);
        });
    };
    EmployeeCallLogComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeCallLogComponent.prototype.onDataRangeChange = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.state.startDate = startDate;
        this.state.endDate = endDate;
        this.managementService.dateRangeChanged(this.state.startDate, this.state.endDate);
    };
    EmployeeCallLogComponent.prototype.onCloseClick = function (e) {
        e.preventDefault();
        this.onClose.next();
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeCallLogComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogComponent.prototype, "messageCenterEnabledSubscription", void 0);
    return EmployeeCallLogComponent;
}());
export { EmployeeCallLogComponent };
