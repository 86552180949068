import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { ColumnManagementService, StateManagementService } from '../../../common';
import { Aca1095cManagementService } from '../../services/aca-1095-c/aca-1095-c-management.service';

@Component({
  moduleId: module.id,
  selector: 'slx-aca-1095-c',
  templateUrl: 'aca-1095-c.component.html',
  styleUrls: ['aca-1095-c.component.scss'],
  providers: [ColumnManagementService, StateManagementService, Aca1095cManagementService]
})

export class Aca1095cComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;
  private readonly componentId = 'Aca1095cComponent';

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private acaManagementService: Aca1095cManagementService,
    private stateManagementService: StateManagementService,
  ) { }

  public ngOnDestroy(): void {
    this.acaManagementService.destroy();
  }

  public ngOnInit() {
    this.stateManagementService.init(this.componentId, true);
    this.acaManagementService.init();
    this.subscriptions.loading = this.acaManagementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }
}
