import * as tslib_1 from "tslib";
import { BenefitDetailsBasic } from './benefit-details-basic';
var BenefitDetailsGroupBasic = /** @class */ (function (_super) {
    tslib_1.__extends(BenefitDetailsGroupBasic, _super);
    function BenefitDetailsGroupBasic(id, name, title, type) {
        if (id === void 0) { id = null; }
        if (name === void 0) { name = null; }
        if (title === void 0) { title = null; }
        if (type === void 0) { type = null; }
        var _this = _super.call(this, id, name, title) || this;
        _this.id = id;
        _this.name = name;
        _this.title = title;
        _this.type = type;
        return _this;
    }
    return BenefitDetailsGroupBasic;
}(BenefitDetailsBasic));
export { BenefitDetailsGroupBasic };
