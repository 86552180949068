<div class="field-container table-record">
    <div class="col actions-column" *ngIf="editable">

        <div class="slx-form-group slx-no-border small-font">
            <div class="slx-input-group">
                <div class="slx-form-control">
                    <input [attr.id]="'selectRow'+rowIndex" type="checkbox" [checked]="item?.isSelected" (click)="onItemSelect()">
                    <label class="checkbox-label" [attr.for]="'selectRow'+rowIndex">
                                    <span class="check"></span>
                                    <span class="box"></span>
                                </label>
                </div>
            </div>
        </div>
    </div>
    <div class="col edit-column">
        <button class="edit-button" (click)="onEditClick ()"></button>
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Name:</span>{{ item.name }}
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Start Time:</span>{{ item.start | amDateFormat: appConfig.timeFormat }}
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Ent Time:</span>{{ item.end | amDateFormat: appConfig.timeFormat }}
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Work Hours:</span>{{ item.duration }}
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Lunch Duration:</span>{{ item.lunchDuration }}
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Group:</span>{{ item.groupName }}
    </div>
</div>