import { CalculationType } from './calculation-type.enum';
import { BenefitStatus } from './benefit-status.enum';

export interface IBenefitPlanShortInfo {
  id: number;
  name: string;
  benefitGroupId: number;
  status: number;
  calculationMethod: number;
  benefitLineId: number;
  enrolledOutEffectiveDate: boolean;
  startDate: string;
  endDate: string;
  payrollDeductionStartDate: string;
  payrollDeductionEndDate: string;
  dedStartDate: string;
  dedEndDate: string;
}

export class BenefitPlanShortInfo {
  public id: number;
  public name: string;
  public benefitGroupId: number;
  public status: BenefitStatus;
  public calculationMethod: CalculationType;
  public benefitLineId: number;
  public enrolledOutEffectiveDate: boolean;
  public startDate: Date;
  public endDate: Date;
  public payrollDeductionStartDate: Date;
  public payrollDeductionEndDate: Date;
  public dedStartDate: Date;
  public dedEndDate: Date;

}
