import { Component, OnInit, Provider, ViewChild } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/index';
import { MealDeducitonsDialogOptions } from '../../../models/meal-deductions/meal-deductions-dialog-options';
import { MealDeductionNewConfigurationComponent } from '../meal-deduction-new-configuration/meal-deduction-new-configuration.component';
import { PbjExportLocationsComponent } from '../pbj-export-locations/pbj-export-locations.component';
import { MealDeductionManagementService } from '../../../services';
import { ConfirmDialog2Component, ConfirmOptions } from '../../../../../../app/common';

@Component({
  moduleId: module.id,
  selector: 'slx-meal-deduction-new-configuration-dialog',
  templateUrl: './meal-deduction-new-configuration-dialog.component.html',
  styleUrls: ['./meal-deduction-new-configuration-dialog.component.scss']
})
export class MealDeductionNewConfigurationDialogComponent implements IDialog {
    orgIds = [];
  public dialogResult: boolean;
  public modified:boolean =false;
  public get hideDescription(): boolean {
      if (this.mealDeductionOptions) {
          return this.mealDeductionOptions.hideDescription;
      }
      return false;
  }

  @ViewChild('configEditorComponent', {static: true})
  private configEditorComponent: MealDeductionNewConfigurationComponent;

  @ViewChild('pbjLocationExportComponent', {static: true})
  private pbjLocationExportComponent: PbjExportLocationsComponent;

  public static openDialog(options: MealDeducitonsDialogOptions, modalService: ModalService, callback: (result: boolean) => void): MealDeductionNewConfigurationDialogComponent {
      let dialogOptions: DialogOptions = new DialogOptions();
      dialogOptions.width = 1000;
      dialogOptions.height = 600;
      dialogOptions.fullHeightOnMobile = true;
      dialogOptions.headerClassName = 'setBlueBg';
      let providers: Provider[] = [
          {
              provide: DialogOptions,
              useValue: dialogOptions
          },
          {
              provide: MealDeducitonsDialogOptions,
              useValue: options
          }
      ];
      let dialog: MealDeductionNewConfigurationDialogComponent = modalService.globalAnchor
          .openDialog(MealDeductionNewConfigurationDialogComponent, options.title, dialogOptions, providers, (result: boolean) => {
              callback(result);
          });
      return dialog;
  }

  constructor(
      private mealDeductionOptions: MealDeducitonsDialogOptions,
      private dialogOptions: DialogOptions,
      private modalService: ModalService,
      private managementService: MealDeductionManagementService
  ) {

  }

  public onClose(): void {
      this.dialogResult = false;
      this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public closeAfterSave(result: boolean): void {
      this.modified = false;
      this.dialogResult = result;
      this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

    public onSave(): void {
        const message = `The Meal Deduction Configuration will be applied to all selected organizations 
                        and used for this export and future exports`;
        let popupOptions: ConfirmOptions = new ConfirmOptions();
        popupOptions.showCancel = true;
        popupOptions.showOK = true;
        popupOptions.buttonCanceltext = 'Cancel';
        popupOptions.buttonOKtext = 'Save';
        ConfirmDialog2Component.openDialog(
            'Warning',
            message,
            this.modalService,
            (result: boolean) => {
                if (result) {
                    if (!this.mealDeductionOptions.skipSave) {
                        this.orgIds = [];
                        this.orgIds = this.managementService.getSelectedIds();
                        if (this.orgIds.length > 0) 
                        {
                            this.orgIds.forEach(org => {
                                this.configEditorComponent.saveByOrg(org);
                            });
                        } else {
                            this.configEditorComponent.save();
                        }
                    } else {
                        this.closeAfterSave(true);
                    }
                }
            }, popupOptions
        )
    }

  public onCancel(): void {
    this.modified = false;
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

//  get selCheckbox(){
//     let orgIds= this.managementService.getSelectedIds();
//    return (orgIds!=undefined && orgIds.length>0);
//   }

  public getIsMealChanged(event){
    this.modified= event;
  }
}
