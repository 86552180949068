import { Component, OnInit, OnDestroy, Input, Provider, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';

import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { ConfirmDialogComponent, ModalService } from '../../../../common/index';
import { unsubscribe } from '../../../../core/decorators/index';

import {
  BudgetRecord,
  Budget,
  BudgetCensus, AddRecordReq
} from '../../../models/index';

import { BudgetApiService, BudgetManagementService } from '../../../services/index';
@Component({
  moduleId: module.id,
  selector: 'slx-budget-grid',
  templateUrl: 'budget-grid.component.html',
  styleUrls: ['budget-grid.component.scss']
})
export class BudgetGridComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<BudgetRecord>;
  public editMode: boolean;
  public addNewMode: boolean;
  public editedRowIndex: number;

  public forceGroupsCache: boolean;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  @ViewChild('form', {static: true})
  private form: NgForm;

  private editedRecord: BudgetRecord;
  private editedOrgLevelId: number;
  private editCancelRecord: BudgetRecord;


  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private addedSubscription: Subscription;
  @unsubscribe()
  private addPositionSubscription: Subscription;
  @unsubscribe()
  private budgetSelectSubscription: Subscription;
  @unsubscribe()
  private budgetEditModeSubscription: Subscription;
  @unsubscribe()
  private groupsEditModeSubscription: Subscription;

  private budgetContainer: Budget;
  private aggregates: any = [
    { field: 'weekendHours', aggregate: 'sum' },
    { field: 'weekdayHours', aggregate: 'sum' },
    { field: 'payRate', aggregate: 'sum' }
  ];
  constructor(private budgetManagementService: BudgetManagementService, private modalService: ModalService) {
    Assert.isNotNull(budgetManagementService, 'budgetManagementService');
    this.budgetContainer = this.budgetManagementService.currentBudget;
    this.gridState = new KendoGridStateHelper<BudgetRecord>();
    let aggregates: any = [
      { field: 'weekendHours', aggregate: 'sum' },
      { field: 'weekdayHours', aggregate: 'sum' },
      { field: 'payRate', aggregate: 'sum' }
    ];
    this.gridState.state.group = [{ field: 'budgetedGroup.description', aggregates: aggregates }];
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.orgLevelSubscription = this.budgetManagementService.onOrgLevelChanged$
      .subscribe((orgLevelId: number) => {
        this.grid.closeRow(this.editedRowIndex);
        this.editMode = false;
        this.addNewMode = false;
        this.editedRowIndex = undefined;
        this.editedRecord = undefined;
        this.budgetContainer = null;
      });

    this.loadedSubscription = this.budgetManagementService.onLoaded$.subscribe(
      (container: Budget) => {
        this.budgetContainer = container;
        this.refreshGrid();
      });

    this.groupsEditModeSubscription = this.budgetManagementService.onGroupEdit$.subscribe((mode: boolean) => {
      if (!mode) {
        this.forceGroupsCache = true;
      }
    });

    this.addedSubscription = this.budgetManagementService.onAdded$.subscribe(
      (container: Budget) => {
        this.budgetContainer = container;
        this.refreshGrid();
      });

    this.addPositionSubscription = this.budgetManagementService.onAddPosition$.subscribe(
      (req: AddRecordReq) => {
        this.editMode = true;
        this.editedOrgLevelId = req.orgLevelId;
        let isIdExists = _.find(this.budgetContainer.records, (id) => id.uniqId === req.record.uniqId);
        if (isIdExists) {
          this.closeEditor();
        }
        else {
          this.addHandler(req.record);
          this.form.form.markAsDirty();
        }
      });

    this.budgetSelectSubscription = this.budgetManagementService.onBudgetSelect$.subscribe(
      (req: Budget) => {
        this.budgetContainer = req;
        this.refreshGrid();
      });

    this.budgetEditModeSubscription = this.budgetManagementService.onEditBudgetMode$.subscribe(
      (mode: boolean) => {
        this.editMode = mode;
        this.editedOrgLevelId = this.budgetManagementService.selectedOrganizationOrgLevelId;
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public editHandler(sender: { dataItem: BudgetRecord, rowIndex: number }): void {
    if (this.editedRecord) {
      ConfirmDialogComponent.openDialog(
        'Unsaved changes',
        'There is an unsaved record. If you proceed, the unsaved data will be lost. Are you sure you wish to proceed?',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.doEdit(sender.dataItem, sender.rowIndex);
          }
        });
      return;
    }
    this.doEdit(sender.dataItem, sender.rowIndex);
  }

  public addHandler(record: BudgetRecord): void {
    this.closeEditor();
    this.addNewMode = true;
    this.editedRecord = record;
    this.grid.addRow(this.editedRecord);
    this.budgetManagementService.onEditRecord(true);
  }

  public removeHandler(event: { sender: any, rowIndex: number, dataItem: any, isNew: boolean }): void {
    if (!event.dataItem) {
      return;
    }
    let removedRecords: BudgetRecord[] = _.remove(this.budgetContainer.records, (r: BudgetRecord) => {
      return r.uniqId === event.dataItem.uniqId;
    });
    this.refreshGrid();
  }

  public closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);
    this.addNewMode = false;
    this.editedRowIndex = undefined;
    this.editedRecord = undefined;
  }

  public cancelHandler(): void {
    let editedItem: BudgetRecord = _.find(this.budgetContainer.records, (r: BudgetRecord) => {
      return r.uniqId === this.editedRecord.uniqId;
    });
    if (this.editCancelRecord !== undefined && editedItem !== undefined && this.editCancelRecord.uniqId === editedItem.uniqId) {
      Object.assign(editedItem, this.editCancelRecord);
    }
    this.forceGroupsCache = false;
    this.closeEditor();
    this.budgetManagementService.onEditRecord(false);
    this.budgetManagementService.onPositionAdded(undefined);
    this.editCancelRecord = undefined;
  }

  public saveHandler(event: { sender: any, rowIndex: number, dataItem: any, isNew: boolean }): void {
    this.forceGroupsCache = false;
    if (this.addNewMode) {
      this.budgetContainer.records.push(this.editedRecord);
      this.budgetManagementService.onPositionAdded(this.editedRecord);
    } else {
      if (this.editedRecord) {
        let editedItem: BudgetRecord = _.find(this.budgetContainer.records, (r: BudgetRecord) => {
          return r.uniqId === this.editedRecord.uniqId;
        });
        Object.assign(editedItem, this.editedRecord);
      }
    }
    this.closeEditor();
    this.budgetManagementService.onEditRecord(false);
    this.refreshGrid();
  }

  private doEdit(dataItem: BudgetRecord, rowIndex: number): void {
    this.closeEditor();
    this.editedRecord = Object.assign({}, dataItem);
    this.editCancelRecord = _.cloneDeep(dataItem);
    this.editedRowIndex = rowIndex;
    this.grid.editRow(this.editedRowIndex);
    this.budgetManagementService.onEditRecord(true);
  }

  private refreshGrid(): void {
    if (!this.budgetContainer || !this.budgetContainer.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.state.group.forEach((group: any) => group.aggregates = this.aggregates);
    this.gridState.view = process(this.budgetContainer.records, this.gridState.state);
  }
}

