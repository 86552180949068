import { ValidatorFn, AsyncValidatorFn, AbstractControl, Validators } from '@angular/forms';

import * as _ from 'lodash';
import * as moment from 'moment';

import { commonConfig } from '../common.config';
import { appConfig } from '../../app.config';
import { IServerValidatorAdapter, IServerValidatorConf, IServerValidationResult, IProhibitedValidatorConf, IUniqueValidatorConf, INoMoreValidatorConf } from './common-validators-models';
import { dateTimeUtils } from '../utils/index';

export class CommonValidators {

  public static max(max: number, strict?: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control))) return null;

      let v: number = control.value;
      if (strict) return v < max ? null : { 'max': true };
      return v <= max ? null : { 'max': true };
    };
  }

  public static prohibitedValues(conf: any[] | IProhibitedValidatorConf): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let v: any;
      if (conf instanceof Array) {
        v = _.find(conf, (val: any) => { return control.value === val; });
      } else {
        let selfExcludedOnce: boolean = false;
        v = _.find(conf.values, (val: any) => {
          if (!control.value) {
            return false;
          }

          let selfValue = conf.selfValue;
          let controlValue = control.value;
          let currValue = val;


          if (conf.valuePropertyName) {
              controlValue = control.value[conf.valuePropertyName];
              currValue = val[conf.valuePropertyName];
          }

          if (!conf.caseSensitive) {
              controlValue = _.toLower(controlValue);
              currValue = _.toLower(currValue);
              selfValue = _.toLower(selfValue);
          }

          if (conf.trimValue) {
              controlValue = _.trim(controlValue);
              currValue = _.trim(currValue);
              selfValue = _.trim(selfValue);
          }

          if (conf.excludeSelf && !selfExcludedOnce) {
              if (controlValue === currValue) {
                  selfExcludedOnce = true;
              }
              if (conf.valuePropertyName) {
                  return val !== control.value && controlValue === currValue;
              } else {
                  return selfValue !== controlValue && controlValue === currValue;
              }
          } else {
              return controlValue === currValue;
          }
        });
      }
      return v ? { 'prohibitedValues': true } : null;
    };
  }

  public static noMore(conf: INoMoreValidatorConf): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let v: any[];
      if (conf.value === undefined) {
        v = _.filter(conf.values, (val: any) => {
          if (val) {
            return control.value ? control.value[conf.propertyName] === val[conf.propertyName] : false;
          } else {
            return false;
          }
        });
      } else {
        v = _.filter(conf.values, (val: any) => {
          return conf.value === val[conf.propertyName];
        });
      }
      return v.length >= conf.noMore ? { 'noMore': true } : null;
    };
  }

  public static unique(conf: IUniqueValidatorConf): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let v: any;
      v = _.find(conf.values, (val: any) => {
        return control.value ? control.value === val[conf.propertyName] && val[conf.idPropertyName] !== conf.idValue : false;
      });
      return v ? { 'unique': true } : null;
    };
  }

  public static min(min: number, strict?: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control))) return null;

      let v: number = control.value;
      if (strict) return v > min ? null : { 'min': true };
      return v >= min ? null : { 'min': true };
    };
  }

  public static slxMinLength(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control))) return null;

      let v: string = control.value || '';
      return v.length >= min ? null : { 'slxMinLength': true };
    };
  }

  public static slxMaxLength(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control))) return null;

      let v: string = control.value || '';
      return v.length <= max ? null : { 'slxMaxLength': true };
    };
  }

  public static notLeadZeroes(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.notLeadZeroes);
    return !validator(control) ? null : { 'notLeadZeroes': true };
  }


  public static number(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.numberPattern);
    return !validator(control) ? null : { 'number': true };
  }

  public static ssn(control: AbstractControl): { [key: string]: boolean } {
    if (control.value === '' || control.value === null || control.value === undefined) return null;
    let validator: ValidatorFn = Validators.pattern(commonConfig.ssnPattern);
    return !validator(control) ? null : { 'ssn': true };
  }

  public static email(control: AbstractControl): { [key: string]: boolean } {
    if (!control.value) {
      return null;
    }
    let validator: ValidatorFn = Validators.pattern(commonConfig.emailPattern);
    return !validator(control) ? null : { 'email': true };
  }

  public static phone(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.phonePattern);
    return !validator(control) ? null : { 'phone': true };
  }
  
  public static acaReceiptId(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.acaReceiptIdPattern);
    return !validator(control) ? null : { 'acaReceiptId': true };
  }


  public static ipv4(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.ipv4Pattern);
    return !validator(control) ? null : { 'ipv4': true };
  }
  public static pbjId(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.pbjIdPattern);
    return !validator(control) ? null : { 'pbjId': true };
  }


  public static datetime(control: AbstractControl): { [key: string]: boolean } {
    if (dateTimeUtils.validateDate(control.value)) {
      return null;
    }
    return { date: true };
  }

  public static date(control: AbstractControl): { [key: string]: boolean } {
    if (dateTimeUtils.validateDate(control.value)) {
      return null;
    }
    return { date: true };
  }

  public static time(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.timePattern);
    return !validator(control) ? null : { 'time': true };
  }

  public static filled(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.notEmptyPattern);
    return !validator(control) ? null : { 'filled': true };
  }

  public static alphanumeric(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.alphanumericPattern);
    return !validator(control) ? null : { 'alphanumeric': true };
  }

  public static password(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.passwordPattern);
    return !validator(control) ? null : { 'password': true };
  }

  public static duration(control: AbstractControl): { [key: string]: boolean } {
    let validator: ValidatorFn = Validators.pattern(commonConfig.durationPattern);
    return !validator(control) ? null : { 'time': true };
  }

  public static minDate(minDate: any): ValidatorFn {
    //if (!moment(minDate).isValid()) throw Error('minDate value must be a formatted date');

    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control))) return null;

      let d: moment.Moment = moment(control.value);

      if (!moment(minDate).isValid()) return { minDate: true };

      return moment(minDate).isSameOrBefore(d) ? null : { minDate: true };
    };
  }

  public static maxDate(maxDate: any): ValidatorFn {
    //if (!moment(maxDate).isValid()) throw Error('maxDate value must be a formatted date');

    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control))) return null;

      let d: moment.Moment = moment(control.value);

      if (!moment(maxDate).isValid()) return { maxDate: true };

      return moment(maxDate).isSameOrAfter(d) ? null : { maxDate: true };
    };
  }

  public static minTime(minTime: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control)) && !_.isNil(CommonValidators.time(control))) return null;

      let d: moment.Moment = moment(control.value, appConfig.timeFormat);

      if (!moment(minTime, appConfig.timeFormat).isValid) return { minDate: true };

      return moment(minTime, appConfig.timeFormat).isSameOrBefore(d) ? null : { minTime: true };
    };
  }

  public static maxTime(maxTime: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control)) && !_.isNil(CommonValidators.time(control))) return null;

      let d: moment.Moment = moment(control.value, appConfig.timeFormat);

      if (!moment(maxTime, appConfig.timeFormat).isValid) return { maxDate: true };

      return moment(maxTime, appConfig.timeFormat).isSameOrAfter(d) ? null : { maxTime: true };
    };
  }

  public static required(control: AbstractControl): { [key: string]: boolean } {
    let hasRequired: boolean = !_.isNil(Validators.required(control));
    return (hasRequired && !control.value) ? { 'required': true } : null;
  }

  public static equal(value: any): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control))) return null;

      let v: any = control.value;
      return v === value ? null : { 'equal': true };
    };
  }

  public static workHoursValidation(workHoursValidation: number, strict?: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!_.isNil(Validators.required(control))) return null;

      let v: number = control.value;
      if (strict) return v > workHoursValidation ? null : { 'workHoursValidation': true };
      return v >= workHoursValidation ? null : { 'workHoursValidation': true };
    };
  }

  public static serverValidator(conf: IServerValidatorConf): AsyncValidatorFn {
    return (control: AbstractControl): Promise<{ [key: string]: any }> => {
      if (!_.isNil(Validators.required(control))) return Promise.resolve(null);
      let v: any = control.value;
      let adapter: IServerValidatorAdapter = conf.validationAdapter;
      let name: string = conf.validationName;
      let result: Promise<{ [key: string]: any }> = adapter.validate(name, v, ...conf.parameters)
        .then((r: IServerValidationResult) => {
          let res: { [key: string]: any } = { [name]: r.errorMessage };
          return r.isValid ? null : res;
        });
      return result;
    };
  }

}
