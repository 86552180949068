export interface IAuditTrailConfig {
  api: {
    core: {
      root: string;
      audit: {
        root: string;
        auditTrail: {
          root: string;
        }
      }
    }
  };
}

export const auditTrailConfig: IAuditTrailConfig = {
  api: {
    core: {
      root: 'core',
      audit: {
        root: 'audit',
        auditTrail: {
          root: 'fullAuditTrail'
        }
      }
    }
  }
};
