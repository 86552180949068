import { EssEntity } from './../../models/index';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { LookupService } from './lookup.service';
import { Injectable } from '@angular/core';
import { LookupType, Lookup, EssDefaultPassword } from '../../models/index';

import * as _ from 'lodash';

@Injectable()
export class EssLookupManageService {

  public onLoaded$: ReplaySubject<any> = new ReplaySubject(1);
  public timeMethods: EssEntity[];

  private methodsDict: StringMap<EssEntity>;
  private passDict: StringMap<EssEntity>;
  private optionsDict: StringMap<EssEntity>;

  constructor(private lookupService: LookupService) {
    this.loadLookups();
  }

  public getOption(id: string): EssEntity {
    return this.optionsDict ? this.optionsDict[id] : null;
  }

  public getTimeMethod(id: string): EssEntity {
    return this.methodsDict ? this.methodsDict[id] : null;
  }

  public getPasswordType(id: string): EssEntity {
    return this.passDict ? this.passDict[id] : null;
  }

  public getOptionLabel(id: string): string {
    return this.optionsDict[id] ? this.optionsDict[id].name : null;
  }

  private loadLookups(): void {
    let promises: Promise<boolean>[] = [];

    promises.push(this.lookupService.getLookup({ lookupType: LookupType.essEnterTimeMethods }).then((value: Lookup) => {
      this.timeMethods = value.items;
      this.methodsDict = _.mapKeys(value.items, (item: EssEntity) => item.id);
      return true;
    }));

    promises.push(this.lookupService.getLookup({ lookupType: LookupType.essPasswordTypes }).then((value: Lookup) => {
      this.passDict = _.mapKeys(value.items, (item: EssEntity) => item.id);
      return true;
    }));

    promises.push(this.lookupService.getLookup({ lookupType: LookupType.essOptions }).then((value: Lookup) => {
      this.optionsDict = _.mapKeys(value.items, (item: EssEntity) => item.id);
      return true;
    }));

    Promise.all(promises).then((values: boolean[]) => {
      this.onLoaded$.next(true);
    });
  }
}
