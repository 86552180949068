import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { appConfig } from '../../app.config';
import { ResponseBody, Meta, FieldsMeta } from '../../core/models/index';
import { reportsConfig } from '../reports.config';
import { UrlParamsService, ApiUtilService } from '../../common/services/index';
import { Assert } from '../../framework/index';
import { ReportsMapService } from './reports-map.service';
import { FileBlobResponse } from '../../core/models/api/file-blob-response';


import {
  ReportDefinition, IReportDefinition,
  ReportParameter, IReportParameter,
  ReportGroup, GenerateReportRequest, ReportStaffingSetting, IReportStaffingSetting, ReportAttendanceSetting, IReportAttendanceSetting, BIPAUnitShiftData, BIPAReportData, PostBIPAReportData, GetBIPAReportDefinition, IGetBIPAReportDefinition, MidnightToMidnightReport, IMidnightToMidnightReport
} from '../models/index';
import { CacheType } from '../../common/models/cache/cache-definition';
import { anyChanged } from '@progress/kendo-angular-common';
import { AnonymousSubject } from 'rxjs-compat';


@Injectable()
export class ReportsApiService {
  private mapService: ReportsMapService;
  private apiUtilsService: ApiUtilService;
  private urlParamsService: UrlParamsService;

  constructor(mapService: ReportsMapService, authApiService: ApiUtilService, urlParamsService: UrlParamsService, private http :HttpClient) {
    this.mapService = mapService;
    this.apiUtilsService = authApiService;
    this.urlParamsService = urlParamsService;
  }

  public generateReport(generateReportRequest: GenerateReportRequest): Promise<FileBlobResponse> {
    Assert.isNotNull(generateReportRequest, 'reportDefinition');
    //let reportRequest: IReportDefinition = this.mapService.mapReportDefinitionToRequest(generateReportRequest);
    const url: string = `${this.getGenearteReportApiRoot()}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, generateReportRequest);
    let promise: Promise<FileBlobResponse> = this.apiUtilsService.requestForFile(request, true)
      .then((response: FileBlobResponse) => response);
    return promise;
  }

  public getReportsList(orgLevelId: number): Promise<ReportGroup[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = `${this.getReportListApiRoot(orgLevelId)}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
    let promise: Promise<ReportGroup[]> = this.apiUtilsService.request<IReportDefinition[], Meta>(request)
      .then((response: ResponseBody<IReportDefinition[], Meta>) => {
        return this.mapService.mapReportsDtoToReportGroups(response.data);
      });
    return promise;
  }

  public async getStaffingSettings(orgLevelId: number, departmentId: number, forcedLoad: boolean): Promise<ReportStaffingSetting> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(departmentId, 'departmentId');
    const url: string = `${this.getReportStaffingSettingsApiRoot(orgLevelId)}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, departmentId: departmentId });
    let promise: Promise<ReportStaffingSetting> = this.apiUtilsService.cachedRequest<IReportStaffingSetting, Meta>(request, CacheType.shortTerm, forcedLoad)
      .then((response: ResponseBody<IReportStaffingSetting, Meta>) => {
        return this.mapService.mapSettingsDTOReportStaffingSettings(response.data);
      });
    return promise;
  }

  public saveStaffingSettings(orgLevelId: number, departmentId: number, reportStaffingSetting: ReportStaffingSetting): Promise<any> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(departmentId, 'departmentId');
    const url: string = `${this.getReportStaffingSettingsApiRoot(orgLevelId)}`;
    const body = this.mapService.mapToReportStaffingSettingsDTO(reportStaffingSetting);
    const request = this.urlParamsService.createPostRequest(url, body, { orgLevelId: orgLevelId, departmentId: departmentId });
    let promise: Promise<any> = this.apiUtilsService.request<any, Meta>(request);
    return promise;
  }

  public async getAttendanceSettings(orgLevelId: number, departmentId: number, forcedLoad: boolean): Promise<ReportAttendanceSetting> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(departmentId, 'departmentId');
    const url: string = `${this.getReportAttendanceSettingsApiRoot(orgLevelId)}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, departmentId: departmentId });
    let promise: Promise<ReportAttendanceSetting> = this.apiUtilsService.cachedRequest<IReportAttendanceSetting, Meta>(request, CacheType.shortTerm, forcedLoad)
      .then((response: ResponseBody<IReportAttendanceSetting, Meta>) => {
        return this.mapService.mapSettingsDTOReportAttendanceSettings(response.data);
      });
    return promise;
  }

  public saveAttendanceSettings(orgLevelId: number, departmentId: number, reportAttendanceSetting: ReportAttendanceSetting): Promise<any> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(departmentId, 'departmentId');
    const url: string = `${this.getReportAttendanceSettingsApiRoot(orgLevelId)}`;
    const body = this.mapService.mapToReportAttendanceSettingsDTO(reportAttendanceSetting);
    const request = this.urlParamsService.createPostRequest(url, body, { orgLevelId: orgLevelId, departmentId: departmentId });
    let promise: Promise<any> = this.apiUtilsService.request<any, Meta>(request);
    return promise;
  }

  public async getDailyStaffingSettings(orgLevelId: number, forcedLoad: boolean): Promise<GetBIPAReportDefinition> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getReportsApiRoot()}/${reportsConfig.api.settings.root}/${reportsConfig.api.settings.dailyStaffing}/${orgLevelId}`;
    let request: HttpRequest<GetBIPAReportDefinition> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<GetBIPAReportDefinition> = this.apiUtilsService.cachedRequest<IGetBIPAReportDefinition, Meta>(request, CacheType.shortTerm, forcedLoad)
      .then((response: ResponseBody<IGetBIPAReportDefinition, Meta>) => {
        return this.mapService.mapBIPAReportData(response.data);
      });
    return promise;
  }

  public async postDailyStaffingSettings(orgLevelId: number, data: BIPAUnitShiftData): Promise<any> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getReportsApiRoot()}/${reportsConfig.api.settings.root}/${reportsConfig.api.settings.dailyStaffing}/${orgLevelId}`;
    const body = data;
    let request: HttpRequest<BIPAUnitShiftData> = this.urlParamsService.createPostRequest(url, body, { orgLevelId: orgLevelId });
    let promise: Promise<any> = this.apiUtilsService.request<BIPAUnitShiftData, Meta>(request);
    return promise;
  }

  public async getMidnightToMidnightConfigData(orgLevelId: number, forcedLoad: boolean): Promise<MidnightToMidnightReport[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getReportsApiRoot()}/${reportsConfig.api.settings.root}/${reportsConfig.api.settings.midnight}/${orgLevelId}`;
    let request: HttpRequest<MidnightToMidnightReport> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<MidnightToMidnightReport[]> = this.apiUtilsService.cachedRequest<IMidnightToMidnightReport[], Meta>(request, CacheType.shortTerm, forcedLoad)
      .then((response: ResponseBody<IMidnightToMidnightReport[], FieldsMeta>) => {
        return this.mapService.mapMidnightToMidnightConfigDetails(response.data, response.meta);
      });
    return promise;
  }

  public async postMidnightToMidnightConfigData(orgLevelId: number, data: MidnightToMidnightReport[]): Promise<any> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getReportsApiRoot()}/${reportsConfig.api.settings.root}/${reportsConfig.api.settings.midnight}/${reportsConfig.api.settings.saveMidnightConfig.root}/${orgLevelId}`;
    const body = this.mapService.reverseMapMidnightToMidnightConfigData(data);
    let request: HttpRequest<MidnightToMidnightReport[]> = this.urlParamsService.createPutRequest(url, body, { orgLevelId: orgLevelId });
    let promise: Promise<any> = this.apiUtilsService.request<MidnightToMidnightReport[], Meta>(request);
    return promise;
  }


  private getReportListApiRoot(orgLevelId: number): string {
    return `${this.getReportsApiRoot()}/${reportsConfig.api.orglevel.root}/${orgLevelId}/${reportsConfig.api.orglevel.list}`;
  }
  private getReportsApiRoot(): string {
    return `${this.apiUtilsService.getApiRoot()}/${reportsConfig.api.root}`;
  }
  private getGenearteReportApiRoot(): string {
    return `${this.apiUtilsService.getApiRoot()}/${reportsConfig.api.root}/${reportsConfig.api.generate}`;
  }

  private getReportStaffingSettingsApiRoot(orgLevelId: number): string {
    return `${this.getReportsApiRoot()}/${reportsConfig.api.settings.root}/${reportsConfig.api.settings.staffing}/${orgLevelId}`;
  }
  private getReportAttendanceSettingsApiRoot(orgLevelId: number): string {
    return `${this.getReportsApiRoot()}/${reportsConfig.api.settings.root}/${reportsConfig.api.settings.attendance}/${orgLevelId}`;
  }

}
