
export * from './payroll-export-test-section/payroll-export-test-section.component';
export * from './payroll-export-test-toolbar/payroll-export-test-toolbar.component';
export * from './payroll-export-test/payroll-export-test.component';
export * from './payroll-export-test-grid/payroll-export-test-grid.component';
export * from './payroll-export-config/payroll-export-config-popup/payroll-export-config-popup.component';
export * from './payroll-export-config/payroll-export-config.component';

import { PayrollExportTestSectionComponent } from './payroll-export-test-section/payroll-export-test-section.component';
import { PayrollExportTestToolbarComponent } from './payroll-export-test-toolbar/payroll-export-test-toolbar.component';
import { PayrollExportTestComponent } from './payroll-export-test/payroll-export-test.component';
import { PayrollExportTestGridComponent } from './payroll-export-test-grid/payroll-export-test-grid.component';
import { PayrollExportConfigPopupComponent } from './payroll-export-config/payroll-export-config-popup/payroll-export-config-popup.component';
import { PayrollExportConfigComponent } from './payroll-export-config/payroll-export-config.component';

export const payrollExportTestComponents: any[] = [
  PayrollExportTestComponent,
  PayrollExportTestToolbarComponent,
  PayrollExportTestSectionComponent,
  PayrollExportTestGridComponent,
  PayrollExportConfigComponent,
  PayrollExportConfigPopupComponent
];
