import * as _ from 'lodash';
import { AccrualTypes, IAccrualTypes } from './employee-accrual-types';

export interface IAccrualTypesDialogOptions {
  accrual: IAccrualTypes[];
  fromDate: Date;
  toDate: Date;
}
export class AccrualTypesDialogOptions {
  public empId: number;
  accrual: AccrualTypes[];
  public fromDate: Date;
  public toDate: Date;
  public hireDate :Date;

  constructor() {
    this.accrual = [];
  }
}
