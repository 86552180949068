import { EmployeeDailyPunchesState, EmployeeDailyPunchesStatusLabel, EmployeeDailyPunchesStatus } from './../../../models/punches/employee-daily-punches-status';
import { EmployeeDailyPunchesHeader } from './../../../models/punches/employee-daily-punches-header';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef,ElementRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/debounceTime';
import { process, State } from '@progress/kendo-data-query';
import {
  GridComponent
} from '@progress/kendo-angular-grid';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe, clearChanges } from '../../../../core/decorators/index';

import { EmployeesPunchesContainer, EmployeeDailyPunches, LinePunch, IApplyPunchesEvent } from '../../../models/index';
import { PunchesManagementService } from '../../../services/punches/punches-management.service';
import { ColumnManagementService, ColumnsChangedEvent, ChangeManagementService } from '../../../../common/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-punches-employees-grid',
  templateUrl: 'punches-employees-grid.component.html',
  styleUrls: ['punches-employees-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchesEmployeesGridComponent implements OnInit, OnDestroy,AfterViewInit {

  public appConfig: IApplicationConfig;
  public records: EmployeeDailyPunches[];
  public pagedRecords: EmployeeDailyPunches[];
  public container: EmployeesPunchesContainer;
  public gridState: KendoGridStateHelper<EmployeeDailyPunches>;
  public changes: StringMap<LinePunch[]>;
  public groupName: string = 'EmployeePunches';
  private scrollPosition : any;

  @unsubscribe()
  private loadStateSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private saveAllChangesSubscription: Subscription;
  @unsubscribe()
  private savedEmpPunchesSubscription: Subscription;
  @unsubscribe()
  private onFilterAppliedSubscription: Subscription;
  @unsubscribe()
  private changesSubscription: Subscription;

  @clearChanges()
  private changeManagementService: ChangeManagementService;

  @ViewChild('employeesGrid', {static: true})
  private grid: GridComponent;
  constructor(private managementService: PunchesManagementService, private changeDetector: ChangeDetectorRef,
    private columnManagementService: ColumnManagementService, changeManagementService: ChangeManagementService,
    private elRef: ElementRef) {
    this.changeManagementService = changeManagementService;
    this.gridState = new KendoGridStateHelper<EmployeeDailyPunches>();
    this.changes = {};
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (container: EmployeesPunchesContainer) => {
        this.container = container;
        this.records = container.filteredEntities;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.onFilterAppliedSubscription = this.managementService.onFilterApplied$.subscribe(
      (records: EmployeeDailyPunches[]) => {
        this.records = records;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        let gridContent = this.elRef.nativeElement.getElementsByClassName('k-grid-content')[0];
        if (gridContent) {
          gridContent.scrollTop = 0;
        }
      });

    this.savedEmpPunchesSubscription = this.managementService.onSavedEmpPunches$.subscribe(
      (empPunches: EmployeeDailyPunches[]) => {
        _.forEach(empPunches, (empPunch: EmployeeDailyPunches) => {
          const index: number = _.findIndex(this.records, (r: EmployeeDailyPunches) => {
            return moment(r.header.date).isSame(empPunch.header.date) && r.header.employee.id === empPunch.header.employee.id;
          });
          this.records.splice(index, 1, empPunch);
        });
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.managementService.applyCurrentFilter(this.container);
        this.scrollToPosition();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.changesSubscription = this.columnManagementService.columnsChanged$.filter((event: ColumnsChangedEvent) => event.group === this.groupName)
      .debounceTime(500)
      .subscribe((event: ColumnsChangedEvent) => {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.saveAllChangesSubscription = this.managementService.onSaveAllChanges$.subscribe((v: any): void => {
      let employeePunchesList: EmployeeDailyPunches[] = [];
      _.forIn(this.changes, (value: LinePunch[], key: string) => {
        let eP: EmployeeDailyPunches = _.find(this.records, (empPanches: EmployeeDailyPunches) => empPanches.header.uniqueKey === key);
        eP.punches = value;
        employeePunchesList.push(eP);
      });
      this.managementService.saveEmployeePunches(employeePunchesList, this.container);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  ngAfterViewInit(){
    document.querySelector('.k-grid .k-grid-content').addEventListener('scroll', (event:any)=>{
      this.scrollPosition = event.target.scrollTop;
    })
  }

  public toggleRows(): void {
    if (this.gridState.view) {
      _.times(this.gridState.view.total, (num: number) => this.grid.expandRow(num));
    }
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public applyPanchesChanges(changes: IApplyPunchesEvent, employeePunches: EmployeeDailyPunches): void {
    employeePunches.punches = changes.punches;
    employeePunches.header.comment = changes.comment;
    this.managementService.saveEmployeePunches([employeePunches], this.container);
    this.changes[employeePunches.header.uniqueKey] = undefined;
    this.clearChangesIfAllPristine();    
  }

  public onDiscardChanges(employeePunches: EmployeeDailyPunches): void {
    employeePunches.header.dirty = false;
    this.changes[employeePunches.header.uniqueKey] = undefined;
    this.clearChangesIfAllPristine();
  }


  public onChangingPunches(changes: LinePunch[], employeePunch: EmployeeDailyPunches): void {
    employeePunch.header.dirty = true;
    this.changes[employeePunch.header.uniqueKey] = changes;
    this.changeManagementService.changeNotify();
  }

  public getExceptionLabel(header: EmployeeDailyPunchesHeader): string {
    if (header.status !== EmployeeDailyPunchesStatus.missing && (header.state & EmployeeDailyPunchesState.emprequest) === EmployeeDailyPunchesState.emprequest) { //this.managementService.empRequestFilterSelected &&
      return EmployeeDailyPunchesStatusLabel.emprequest;
    }
    return EmployeeDailyPunchesStatusLabel[header.status];
  }

  public getExceptionStyle(header: EmployeeDailyPunchesHeader): string {
    if (header.status !== EmployeeDailyPunchesStatus.missing && (header.state & EmployeeDailyPunchesState.emprequest) === EmployeeDailyPunchesState.emprequest) {
      return 'emprequest-display-status';
    }
    else if(header.status == EmployeeDailyPunchesStatus.invalidlogin)
    {
      return 'invalid-display-status';
    }

    return `${header.status}-display-status`;
  }

  public scrollToPosition(){
    if(this.scrollPosition){
      let gridContent = this.elRef.nativeElement.getElementsByClassName('k-grid-content')[0];
      if (gridContent) {
        gridContent.scrollTop = this.scrollPosition;
      }
    }
  }

  private clearChangesIfAllPristine(): void {
    const firstDirtyKey: string = _.findKey(this.changes, (p: LinePunch[]) => {
      return p !== undefined;
    });
    if (!firstDirtyKey) {
      this.changeManagementService.clearChanges();
    }
  }
  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}

