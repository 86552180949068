import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AbsenceModel } from '../../../models/index';
import { ColorUtil } from '../../../../common/utils/index';
import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';
import { AbsencesConfigurationManagementService } from '../../../services/absences/absence-configuration-management.service';
import { unsubscribe } from '../../../../core/decorators/index';
var ConfigureAbsenceEditorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConfigureAbsenceEditorComponent, _super);
    function ConfigureAbsenceEditorComponent(management) {
        var _this = _super.call(this) || this;
        _this.management = management;
        _this.prohibitedNameValues = [];
        return _this;
    }
    ConfigureAbsenceEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.mainFormSubscription = this.formGroup.statusChanges.subscribe(function () {
            if (_this.formGroup.dirty) {
                _this.management.markAsDirty();
            }
        });
    };
    ConfigureAbsenceEditorComponent.prototype.onColorPickerChange = function (colorCode) {
        this.formGroup.get('color').setValue(colorCode);
    };
    ConfigureAbsenceEditorComponent.prototype.getColor = function () {
        return this.formGroup ? this.formGroup.get('color').value : '#ffffff';
    };
    ConfigureAbsenceEditorComponent.prototype.updateItem = function () {
        this.item.code = this.formGroup.get('code').value;
        this.item.description = this.formGroup.get('description').value;
        this.item.isPaid = this.formGroup.get('isPaid').value;
        this.item.ptoPlannerIndicator = this.formGroup.get('ptoPlannerIndicator').value;
        this.item.essPresent = this.formGroup.get('essPresent').value;
        this.item.color = ColorUtil.HexToDec(this.formGroup.get('color').value);
        this.item.loaIndicator = this.formGroup.get('loaIndicator').value;
    };
    ConfigureAbsenceEditorComponent.prototype.createFormGroup = function () {
        return new FormGroup({
            code: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            isPaid: new FormControl(false),
            ptoPlannerIndicator: new FormControl('', Validators.required),
            essPresent: new FormControl(false),
            color: new FormControl('', Validators.required),
            loaIndicator: new FormControl('', Validators.required),
        });
    };
    ConfigureAbsenceEditorComponent.prototype.updateFormGroup = function () {
        this.formGroup.get('code').setValue(this.item.code);
        this.formGroup.get('description').setValue(this.item.description);
        this.formGroup.get('isPaid').setValue(this.item.isPaid);
        this.formGroup.get('ptoPlannerIndicator').setValue(this.item.ptoPlannerIndicator);
        this.formGroup.get('essPresent').setValue(this.item.essPresent);
        this.formGroup.get('color').setValue(ColorUtil.DecToHexString(this.item.color, true));
        this.formGroup.get('loaIndicator').setValue(this.item.loaIndicator);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureAbsenceEditorComponent.prototype, "mainFormSubscription", void 0);
    return ConfigureAbsenceEditorComponent;
}(EditableListEditorComponent));
export { ConfigureAbsenceEditorComponent };
