var RecurrenceFrequencies = /** @class */ (function () {
    function RecurrenceFrequencies() {
    }
    RecurrenceFrequencies.custom = 'custom';
    RecurrenceFrequencies.daily = 'daily';
    RecurrenceFrequencies.weekly = 'weekly';
    RecurrenceFrequencies.monthly = 'monthly';
    return RecurrenceFrequencies;
}());
export { RecurrenceFrequencies };
var WeekDays = /** @class */ (function () {
    function WeekDays() {
    }
    WeekDays.sunday = 'sunday';
    WeekDays.monday = 'monday';
    WeekDays.tuesday = 'tuesday';
    WeekDays.wednesday = 'wednesday';
    WeekDays.thursday = 'thursday';
    WeekDays.friday = 'friday';
    WeekDays.saturday = 'saturday';
    return WeekDays;
}());
export { WeekDays };
var OffsetPositions = /** @class */ (function () {
    function OffsetPositions() {
    }
    OffsetPositions.first = 'first';
    OffsetPositions.second = 'second';
    OffsetPositions.third = 'third';
    OffsetPositions.fourth = 'fourth';
    OffsetPositions.last = 'last';
    return OffsetPositions;
}());
export { OffsetPositions };
var RecurrenceFrequency = /** @class */ (function () {
    function RecurrenceFrequency(id, name, isActive) {
        if (isActive === void 0) { isActive = false; }
        this.id = id;
        this.name = name;
        this.isActive = isActive;
    }
    return RecurrenceFrequency;
}());
export { RecurrenceFrequency };
var WeekDay = /** @class */ (function () {
    function WeekDay(id, name, text, isActive) {
        if (isActive === void 0) { isActive = false; }
        this.id = id;
        this.name = name;
        this.text = text;
        this.isActive = isActive;
    }
    Object.defineProperty(WeekDay.prototype, "shortText", {
        get: function () {
            return typeof (this.text) === 'string' ? this.text.slice(0, 3) : '';
        },
        enumerable: true,
        configurable: true
    });
    return WeekDay;
}());
export { WeekDay };
var OffsetPosition = /** @class */ (function () {
    function OffsetPosition(id, name, text) {
        this.id = id;
        this.name = name;
        this.text = text;
    }
    return OffsetPosition;
}());
export { OffsetPosition };
var RecurrenceRuleOption = /** @class */ (function () {
    function RecurrenceRuleOption(interval, count, until, byWeekDay, byMonthDay) {
        this.interval = interval;
        this.count = count;
        this.until = until;
        this.byWeekDay = byWeekDay;
        this.byMonthDay = byMonthDay;
    }
    return RecurrenceRuleOption;
}());
export { RecurrenceRuleOption };
