import { ShiftSwapManageService } from './../../../services/index';
import { ShiftSwapStatus } from './../../../models/shift-swap/shift-swap-status';
import { ShiftSwapInfo } from '../../../models/shift-swap/shift-swap-info';
var ShiftSwapListItemComponent = /** @class */ (function () {
    function ShiftSwapListItemComponent(manageService) {
        this.manageService = manageService;
    }
    Object.defineProperty(ShiftSwapListItemComponent.prototype, "statusStyle", {
        get: function () {
            if (!this.shiftSwap) {
                return 'status-pending';
            }
            return 'status-' + this.shiftSwap.status.toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftSwapListItemComponent.prototype, "showDenied", {
        get: function () {
            return this.shiftSwap && this.shiftSwap.status === ShiftSwapStatus.Denied;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftSwapListItemComponent.prototype, "showApproved", {
        get: function () {
            return this.shiftSwap && this.shiftSwap.status === ShiftSwapStatus.Approved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftSwapListItemComponent.prototype, "expired", {
        get: function () {
            return this.shiftSwap && this.shiftSwap.status === ShiftSwapStatus.Expired;
        },
        enumerable: true,
        configurable: true
    });
    ShiftSwapListItemComponent.prototype.seeDetailsClicked = function () {
        this.manageService.showDetails(this.shiftSwap);
    };
    ShiftSwapListItemComponent.prototype.approveClicked = function () {
        this.manageService.approve(this.shiftSwap);
    };
    ShiftSwapListItemComponent.prototype.denyClicked = function () {
        this.manageService.deny(this.shiftSwap);
    };
    return ShiftSwapListItemComponent;
}());
export { ShiftSwapListItemComponent };
