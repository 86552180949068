export class AcaExportSupplemental1095C {
    public employeeId: number;
    public employeeIdMasterId: number;
    public employeeName: string;
    public recordId: string;
    public ssn: string;
    public ssnStatus: number;
    public isSelected: boolean;
}

export class IAcaExportSupplemental1095C {
    employeeId: number;
    employeeIdMasterId: number;
    employeeName: string;
    recordId: string;
    ssn: string;
    ssnStatus: number;
}