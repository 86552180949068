import * as _ from 'lodash';
import { Role, RoleField, RolesAccess } from '../role-models/index';
import { RolesRowDefinition, IRolesRow } from './roles-row';


export class RolesFieldRow implements IRolesRow<RoleField> {
  public roles: Role[];
  public mapByRole: NumberMap<RoleField>;
  public dirtyByRole: NumberMap<boolean>;
  public type: RolesRowDefinition = 'RolesFieldRow';
  public description: string;
  public recalcStatus(roleId: number): void {
    let field: RoleField = this.mapByRole[roleId];
    if (field.hidden) {
      field.access = RolesAccess.none;
    } else if (field.masked) {
      field.access = RolesAccess.masked;
    } else if (field.editable) {
      field.access = RolesAccess.edit;
    } else {
      field.access = RolesAccess.view;
    }
    this.dirtyByRole[roleId] = field.isDirty;
  }

  public setNextStatus(roleId: number): void {
    let field: RoleField = this.mapByRole[roleId];
    if (field.hidden) {
      //go to editable
      field.hidden = false;
      field.masked = false;
      field.editable = true;
    } else if (field.masked) {
      //go to hidden
      field.hidden = true;
      field.masked = false;
      field.editable = false;
    } else if (field.editable) {
      //go to view
      field.hidden = false;
      field.masked = false;
      field.editable = false;
    } else {
      //go to masked
      field.hidden = false;
      field.masked = true;
      field.editable = false;
    }
    this.recalcStatus(roleId);
  }

  public setStatus(access: RolesAccess, roleId: number): void {
    let field: RoleField = this.mapByRole[roleId];
    if (access === RolesAccess.none) {
      //go to hidden
      field.hidden = true;
      field.masked = false;
      field.editable = false;
    } else if (access === RolesAccess.edit) {
      //go to editable
      field.hidden = false;
      field.masked = false;
      field.editable = true;
    } else if (access === RolesAccess.view) {
      //go to view
      field.hidden = false;
      field.masked = false;
      field.editable = false;
    } else {
      //go to masked
      field.hidden = false;
      field.masked = true;
      field.editable = false;
    }
    this.recalcStatus(roleId);
  }
}
