import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { ExportDataStatus } from '../../../enums/export-data-status';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../../../core/decorators';
import * as moment from 'moment';
import { AcaExportConfiguration } from '../../../models/aca-export/aca-export-configuration';
import { LookupApiService } from '../../../../../organization/services';
import { AcaEmployers } from '../../../../../organization/models/lookup';
import { AcaExportExecutionItem } from '../../../models/aca-export/aca-export-execution';
import { ConfirmDialog2Component, ConfirmOptions, ModalService } from '../../../../../common/index';
import { AcaExportType, getAcaRelatedExportTypes } from '../../../enums/aca-export-type';
import { receiptIdMaskRegExp } from '../../../export-data.config';

@Component({
  selector: 'slx-aca-export-section',
  templateUrl: './aca-export-section.component.html',
  styleUrls: ['./aca-export-section.component.scss']
})

export class AcaExportSectionComponent implements OnInit, OnDestroy {

  public isSubmitted: boolean;
  public confirmedEmployees: number;
  public areAllEmployeesConfirmed: boolean;

  public aleMembersCount: number;
  public employerTccCode: string;
  public selectedEmployer: AcaEmployers;
  public receiptIdMaskConf: any;

  public get isFormValid(): boolean {
    if (!this.configuration.exportParams.employerId || this.aleMembersInValid) {
      return false;
    }

    if (this.configuration.requiresAllEmployeesToBeConfirmed && !this.areAllEmployeesConfirmed) {
      return false;
    }

    if (this.configuration.requiresAtLeastOneEmployeeToBeConfirmed && this.confirmedEmployees < 1) {
      return false;
    }

    if (this.configuration.requiresTccCode && !this.employerTccCode) {
      return false;
    }

    return true;
  }

  public get aleMembersInValid(): boolean {
    if (this.configuration.exportParams.hidden) {
      return false;
    }

    if (this.aleMembersCount <= 1) {
      return false;
    }

    if (this.configuration.exportParams.memberOfAleGroup.length) {
      return false;
    }

    return true;
  }

  public get isExpired(): boolean {
    return this.configuration.lastExecuted &&
      (this.configuration.lastExecuted.completedOn && this.configuration.lastExecuted.completedOn < moment().subtract(1, 'years').toDate()
        || !this.configuration.lastExecuted.completedOn && this.configuration.lastExecuted.startedOn < moment().subtract(1, 'years').toDate());
  }

  @Input()
  public configuration: AcaExportConfiguration;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public yearOptions: number[] = [];
  public yearOptionsTestXml: number[] = [2023];
  public monthOptions: any[] = [];
  public qualifiedOfferOptions: string[] = ['Yes', 'No'];
  public nintyEightOfferOptions: string[] = ['Yes', 'No'];
  public replacementOptions: string[] = ['Yes', 'No'];
  public employersLookup: AcaEmployers[] = [];
  public currentYear: number = moment().startOf('day').toDate().getFullYear();

  constructor(
    private manService: AcaExportManagementService,
    private changeDetector: ChangeDetectorRef,
    private lookupApiService: LookupApiService,
    private modalService: ModalService
  ) {
    this.receiptIdMaskConf = receiptIdMaskRegExp;
  }

  public ngOnInit(): void {
    this.createYearOptions();
    this.createMonthOptions();
    this.bindEmployerLookup();
    this.configuration.exportParams.memberOfAleGroup = this.configuration.exportParams.memberOfAleGroup.length == 0 ? this.monthOptions : this.configuration.exportParams.memberOfAleGroup;
    this.configuration.exportParams.offeredMec = this.configuration.exportParams.offeredMec.length == 0 ? this.monthOptions : this.configuration.exportParams.offeredMec;

    const relatedExports = getAcaRelatedExportTypes(this.configuration.exportType);
   
    this.subscriptions.exportExecuted = this.manService.subscribeToExportExecuted((v: AcaExportExecutionItem) => {
      if (_.includes(relatedExports, v.type)) {
        this.configuration.lastExecuted = v;
      }
    });

    if (!this.configuration.isCollapsed) {
      this.manService.loadExportDataHistory(this.configuration.id);
    }
  }

  public ngOnDestroy(): void {
    this.configuration = null;
    this.isSubmitted = false;
    this.changeDetector.detach();
  }

  private bindEmployerLookup(): void {
    this.manService.setLoadingStatus(true);

    this.lookupApiService.getAcaEmployers().then(employers => {
      this.manService.setLoadingStatus(false);
      this.employersLookup = employers;

      if (this.configuration.exportParams.employerId !== 0) {
        this.selectedEmployer = _.find(this.employersLookup, [
          'companyId',
          this.configuration.exportParams.employerId
        ]);

        this.setEmployerDetails(this.selectedEmployer);
      }
    }).catch(() => {
      this.manService.setLoadingStatus(false);
    });
  }

  private createYearOptions(): void {
    this.yearOptions = _.reverse(_.values(_.range(2016, this.currentYear + 1)));
  }

  private createMonthOptions(): void {
    for (let month: number = 0; month < 12; month++) {
      this.monthOptions.push({
        id: (month + 1),
        name: moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM')
      });
    }
  }

  public get exportDataStatus() {
    return ExportDataStatus;
  }

  public get canGenerate(): boolean {
    return this.configuration && (!this.configuration.lastExecuted || (this.configuration.lastExecuted.status !== ExportDataStatus.Waiting &&
      this.configuration.lastExecuted.status !== ExportDataStatus.InProgress));
  }

  public get canCancel(): boolean {
    return this.configuration && !this.canGenerate;
  }

  public collapsedChange(isCollapsed: boolean): void {
    if (!isCollapsed && (!this.configuration.history ||(this.configuration.history && this.configuration.history.length==0))) {
      this.manService.loadExportDataHistory(this.configuration.id);
    }
  }

  public generate(): void {
    if (this.isSubmitted || !this.canGenerate) {
      return;
    }

    if (this.configuration.exportParams.replacement == 'Yes' && (this.configuration.exportParams.receiptId == null || this.configuration.exportParams.receiptId == '')) {
      this.showReceiptDialog();
    }
    else {

      const options = new ConfirmOptions();

      options.showOK = true;
      options.showCancel = true;
      options.height = 250;

      ConfirmDialog2Component.openDialog(
        'Warning',
        `The ${this.configuration.name} export will only include records for employer: ${this.configuration.exportParams.employerName} and will only include 1095-C records for which all 12 months have been confirmed. Please verify that your organization has reviewed and confirmed all suggested values as needed and that you wish to proceed with generating the export.`,
        this.modalService,
        (result => {
          if (!result) {
            return;
          }

          this.isSubmitted = true;
          this.manService.generateAcaExport(this.configuration.exportParams);
          this.isSubmitted = false;
        }), options
      );
    }
  }

  public cancelExecution(): void {
    this.manService.cancelAcaExport(this.configuration.id, this.configuration.lastExecuted.id);
  }

  public onYearChange(year: number): void {
    this.updateEmployerStatus();
  }

  public onEmployersChanged(item: AcaEmployers): void {
    this.aleMembersCount = 0;
    this.employerTccCode = null;
    this.confirmedEmployees = null;
    this.areAllEmployeesConfirmed = null;

    if (item) {
      this.setEmployerDetails(item);
    }
  }

  private async setEmployerDetails(item: AcaEmployers): Promise<void> {
    this.configuration.exportParams.employerId = item.companyId;
    this.configuration.exportParams.employerName = item.legalName;
    this.aleMembersCount = item.aleMembers;
    this.employerTccCode = item.tccCode;

    await this.updateEmployerStatus();

    if (this.configuration.requiresTccCode && !this.employerTccCode) {
      const options = new ConfirmOptions();

      options.showOK = true;
      options.showCancel = false;
      options.height = 250;

      ConfirmDialog2Component.openDialog(
        'Error',
        `The selected employer has no Transmitter Control Code (TCC) Configured. Please enter the appropriate code obtained directly from the IRS into the appropriate field on the Manage Employer screen of the ACA module to continue.`,
        this.modalService,
        () => { },
        options
      );
    }
  }

  private async updateEmployerStatus(): Promise<void> {
    if (!this.configuration.exportParams.employerId || !this.configuration.exportParams.year) {
      return;
    }

    if (this.configuration.requiresAllEmployeesToBeConfirmed || this.configuration.requiresAtLeastOneEmployeeToBeConfirmed) {
      const employerStatus =
        await this.manService.getEmployerStatus(this.configuration.exportParams.employerId, this.configuration.exportParams.year);

      if (employerStatus) {
        this.confirmedEmployees = employerStatus.confirmedEmployees;
        this.areAllEmployeesConfirmed = employerStatus.areAllEmployeesConfirmed;
      }
    }
  }

  public generateBtnTooltip(): string {
    let message = '';

    if (!this.configuration.exportParams.employerId) {
      message += 'Please select an employer.';
    }
    if (this.configuration.exportParams.employerId) {
      if (this.configuration.requiresAllEmployeesToBeConfirmed
        && !this.areAllEmployeesConfirmed) {
        if (message.length) {
          message += ' ';
        }

        message += 'Not all qualified employees have been confirmed.';
      }

      if (this.configuration.requiresAtLeastOneEmployeeToBeConfirmed
        && this.confirmedEmployees < 1) {
        if (message.length) {
          message += ' ';
        }

        if (this.configuration.exportType === AcaExportType.Export1095C) {
          message += 'At least one 1095-C record must be confirmed for all 12 months before generating 1095-C PDFs.';
        } else {
          message += 'At least one 1095-C record must be confirmed for all 12 months.';
        }
      }

      if (this.configuration.requiresTccCode
        && !this.employerTccCode) {
        if (message.length) {
          message += ' ';
        }

        message += 'The selected employer has no Transmitter Control Code (TCC) Configured.';
      }

      if (this.aleMembersInValid) {
        if (message.length) {
          message += ' ';
        }

        message += 'Please indicate which months this employer was a member before proceeding.';
      }
    }

    return message;
  }

  private showReceiptDialog(): void {
    const options = new ConfirmOptions();

    options.showOK = true;
    options.showCancel = false;
    options.height = 250;

    ConfirmDialog2Component.openDialog(
      'Warning',
      `"Replacement?" is marked Yes, but no Receipt ID is entered. A Receipt ID is required to link a replacement filing to the submission it is replacing. Please enter the appropriate Receipt ID from your ACA Information Returns (AIR) System acknowledgment prior to generating replacement XML.`,
      this.modalService,
      (result => {
        return;
      }), options
    );
  }

  public onReplacementChange(): void {
    if (this.configuration.exportParams.replacement !== 'Yes') {
      this.configuration.exportParams.receiptId = null;
    }
  }

}
