import * as _ from 'lodash';
import { Component, Input, Output, EventEmitter, Host } from '@angular/core';
import { PopperContent } from 'ngx-popper';

@Component({
  moduleId: module.id,
  selector: 'slx-actions-list-item',
  templateUrl: 'actions-list-item.component.html',
  styleUrls: ['actions-list-item.component.scss'],
})
export class ActionsListItemComponent {
  @Input('disabled')
  public isDisabled: boolean;

  @Input()
  public className: string;

  @Input()
  public hidden: boolean;

  @Input()
  public fullyCustomStyle: boolean;

  @Output()
  public onClick: EventEmitter<MouseEvent>;

  @Input()
  public title: string;

  constructor(@Host() private popper: PopperContent) {
    this.hidden = false;
    this.fullyCustomStyle = false;
    this.popper = popper;
    this.onClick = new EventEmitter<MouseEvent>();
  }

  public onMouseup(event: MouseEvent): void {
    this.onClick.emit(event);
    this.popper.hide();
  }

  public getClassName(): string {
    const standartCssClass: string = 'slx-actions-list-item';
    const finalCssClasses: string[] = [];
    if (!this.fullyCustomStyle) {
      finalCssClasses.push(standartCssClass);
    }
    if (_.isString(this.className) && _.size(this.className) > 0) {
      finalCssClasses.push(this.className);
    }

    return finalCssClasses.join(' ');
  }

  public getTitle(): string {
    if (_.isString(this.title) && _.size(this.title) > 0) {
      return this.title;
    }
    return "";
  }
}