import { IPosition, Position } from '../../../organization/models/index';

export interface IEssTemplateDeletionRespose {
  isSuccess: boolean;
  message: string;
  assignedPositions: IPosition[];
}

export class EssTemplateDeletionRespose {
  public isSuccess: boolean;
  public message: string;
  public assignedPositions: Position[];
}
