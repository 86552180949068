import * as moment from 'moment';
import { IBaseSelectableModel } from '../../../common/models/kendo-grid-helpers/base-selectable-model';

export interface IConstraintDTO {
  id: number;
  code: string;
  name: string;
  organizationId: number;
  countAs: number;
  usedCount: number;
  lastUpdateDate: string;
  lastUpdateUsername: string;
}

export class ConstraintModel implements IBaseSelectableModel {
  public id: number;
  public code: string;
  public name: string;
  public organizationId: number;
  public countAs: number;
  public usedCount: number;
  public lastUpdateDate: Date;
  public lastUpdateUsername: string;

  public isSelected: boolean;
  public selectable: boolean = true;
}
