import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/filter';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { AppSettingsManageService } from '../../../app-settings/services';
var imageCache = {};
var VIEW_MODE_KEY = 'view_mode';
var FILTERS = 'filters';
var DATE = 'date';
var DailyUnitRouterComponent = /** @class */ (function () {
    function DailyUnitRouterComponent(stateManagement, appSettingsManageService) {
        var _this = this;
        this.stateManagement = stateManagement;
        this.appSettingsManageService = appSettingsManageService;
        this.appSettingsManageService.getAppServerConfig()
            .then(function (appConfig) {
            _this.isGridView = appConfig.duagrid;
        });
    }
    Object.defineProperty(DailyUnitRouterComponent.prototype, "dateOn", {
        get: function () {
            return this.m_dateOn;
        },
        set: function (value) {
            this.m_dateOn = value;
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitRouterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSubscription = this.user$.subscribe(function (session) {
            if (session) {
                _this.alias = session.alias;
            }
        });
        this.stateManagement.init('DailyUnitRouterComponent', true);
    };
    DailyUnitRouterComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    DailyUnitRouterComponent.prototype.showGridView = function () {
        if (this.isGridView === undefined) {
            return false;
        }
        else {
            return true;
        }
    };
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], DailyUnitRouterComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitRouterComponent.prototype, "userSubscription", void 0);
    return DailyUnitRouterComponent;
}());
export { DailyUnitRouterComponent };
