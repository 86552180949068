import { Injectable, Type } from '@angular/core';
import {
  CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { ChangeManagementService } from './change-management.service';
import { IRouteInfo, isRouteInfo, IRouteDetails } from '../../../core/models/index';


@Injectable()
export class CanLeaveGuard implements CanDeactivate<any> {
  constructor(private changeManagementService: ChangeManagementService) {
  }
  public canDeactivate<T>(target: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    if (!route.data || !isRouteInfo(route.data)) {
      return true;
    }
    return this.changeManagementService.canMoveForward(route.data.componentId);
  }
}
