import { Router, ActivatedRoute } from '@angular/router';

export class AddEmployeeNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToAddEmployee(): void {
    this.router.navigate(['add_employee'], {relativeTo: this.route.pathFromRoot[2], queryParams: { hideLeftSideBar: true }});
  }
}
