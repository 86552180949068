/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slx-title-window.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./slx-title-window.component";
var styles_TitleWindowComponent = [i0.styles];
var RenderType_TitleWindowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TitleWindowComponent, data: {} });
export { RenderType_TitleWindowComponent as RenderType_TitleWindowComponent };
function View_TitleWindowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "title-label"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.title, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 1, 0, currVal_1); }); }
function View_TitleWindowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "close-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null))], null, null); }
export function View_TitleWindowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], [[8, "className", 0], [1, "modalId", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleWindowComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "action-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleWindowComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "window-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.title; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.showCloseButton; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "window-container ", _co.className, ""); var currVal_1 = _co.modalId; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "window-header ", _co.headerClassName, ""); _ck(_v, 1, 0, currVal_2); }); }
export function View_TitleWindowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-title-window", [], null, null, null, View_TitleWindowComponent_0, RenderType_TitleWindowComponent)), i1.ɵdid(1, 114688, null, 0, i3.TitleWindowComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TitleWindowComponentNgFactory = i1.ɵccf("slx-title-window", i3.TitleWindowComponent, View_TitleWindowComponent_Host_0, { title: "title", isModal: "isModal", showCloseButton: "showCloseButton", modalId: "modalId", className: "className", headerClassName: "headerClassName" }, { onClose: "onClose" }, ["*"]);
export { TitleWindowComponentNgFactory as TitleWindowComponentNgFactory };
