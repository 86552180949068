import { FieldAccessType } from './field-access-type';
export class FieldSecurityAttribute {
  public access: FieldAccessType;
  public get editable(): boolean {
    return this.access === FieldAccessType.full;
  }
  public get masked(): boolean {
    return this.access === FieldAccessType.masked;
  }
  public get hidden(): boolean {
    return this.access === FieldAccessType.hidden;
  }

}
