import { EventEmitter } from '@angular/core';
import { appConfig } from '../../../../app.config';
var DailyTimecardSectionComponent = /** @class */ (function () {
    function DailyTimecardSectionComponent() {
        this.isCollapsedChange = new EventEmitter();
    }
    Object.defineProperty(DailyTimecardSectionComponent.prototype, "isCollapsed", {
        get: function () {
            return this.m_isCollapsed;
        },
        set: function (value) {
            this.m_isCollapsed = value;
        },
        enumerable: true,
        configurable: true
    });
    DailyTimecardSectionComponent.prototype.isMobile = function () {
        return (screen.width <= appConfig.mobileMaxWidth);
    };
    Object.defineProperty(DailyTimecardSectionComponent.prototype, "heightValue", {
        get: function () {
            if (this.m_isCollapsed || this.isMobile()) {
                return 'initial';
            }
            return this.sectionHeight;
        },
        enumerable: true,
        configurable: true
    });
    DailyTimecardSectionComponent.prototype.collapsedChange = function (value) {
        this.isCollapsedChange.emit(value);
    };
    return DailyTimecardSectionComponent;
}());
export { DailyTimecardSectionComponent };
