
export interface IPunchLogEntry {
  id: number;
  punchTime: string;
  timeclock: string;
  type: string;
  errors: string;
  comments: string;
  isReverse: boolean;
}
export class PunchLogEntry {
  public id: number;
  public punchTime: Date;
  public timeclock: string;
  public type: string;
  public errors: string;
  public comments: string;
  public isReverse: boolean;
}

export class PunchLogRequest {
  employeeId: number;
  employeeName: string;
  startDate: Date;
  endDate: Date;
}
