import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { dateTimeUtils } from '../../../../common/utils/index';
import { AcceptableControlValueAccessor } from '../../../../core/models/index';
var DatePickerNgxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DatePickerNgxComponent, _super);
    function DatePickerNgxComponent(ngControl) {
        var _this = _super.call(this) || this;
        _this.constantValidation = false;
        _this.emitValueAnyway = false;
        _this.required = false;
        _this.format = 'MM/dd/yyyy';
        _this.readonly = false;
        _this.cssClass = '';
        _this.acceptNullDate = true;
        _this.getCalendarCellClass = function () { return ''; };
        _this.ngControl = ngControl;
        ngControl.valueAccessor = _this;
        _this.calendarOpen = new EventEmitter();
        _this.calendarClose = new EventEmitter();
        _this.valueChangeEvent = new EventEmitter();
        _this.hasClosed = false;
        _this.isIncorrectDate = false;
        return _this;
    }
    Object.defineProperty(DatePickerNgxComponent.prototype, "minDate", {
        get: function () {
            return this.m_minDate;
        },
        set: function (value) {
            this.m_minDate = value;
            this.minOrMaxChanged = true;
            this.validateDate();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerNgxComponent.prototype, "disabledDateArray", {
        get: function () {
            return this.m_disabledDateArray;
        },
        set: function (value) {
            this.m_disabledDateArray = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerNgxComponent.prototype, "maxDate", {
        get: function () {
            return this.m_maxDate;
        },
        set: function (value) {
            this.m_maxDate = value;
            this.minOrMaxChanged = true;
            this.validateDate();
        },
        enumerable: true,
        configurable: true
    });
    DatePickerNgxComponent.prototype.ngOnChanges = function (changes) {
        if (changes.calendarCellClass) {
            var calendarCellClass_1 = changes.calendarCellClass.currentValue;
            if (_.isString(calendarCellClass_1)) {
                this.getCalendarCellClass = function () { return calendarCellClass_1; };
            }
            if (_.isFunction(calendarCellClass_1)) {
                this.getCalendarCellClass = function (date) { return calendarCellClass_1(date); };
            }
        }
    };
    DatePickerNgxComponent.prototype.onMouseover = function (event) {
        if (event.keyCode === 13) {
            this.validateDate();
        }
    };
    DatePickerNgxComponent.prototype.onCalendarOpen = function () {
        this.calendarOpen.emit();
    };
    DatePickerNgxComponent.prototype.onCalendarClose = function () {
        this.hasClosed = true;
        this.calendarClose.emit();
    };
    DatePickerNgxComponent.prototype.onValueChanged = function (newDate) {
        this.currentValue = newDate;
        if (this.hasClosed || this.constantValidation) {
            this.resetError();
            this.validateDate();
            this.hasClosed = false;
        }
        this.valueChangeEvent.emit(newDate);
    };
    DatePickerNgxComponent.prototype.onBlurInput = function () {
        this.validateDate();
    };
    DatePickerNgxComponent.prototype.writeValue = function (value) {
        var _this = this;
        this.currentValue = this.prevValue = null;
        if (this.emitValueAnyway) {
            this.checkValidityWhenEmittingValueAnyway(value, function (data) {
                if (!data.error) {
                    _this.currentValue = _this.prevValue = data.value;
                }
            });
        }
        else {
            this.checkValidity(value, function (date) {
                _this.currentValue = _this.prevValue = date;
            });
        }
    };
    DatePickerNgxComponent.prototype.validateDate = function () {
        var _this = this;
        if (this.changesEmitted) {
            return;
        }
        if (this.isIncorrectDate || this.minOrMaxChanged || this.isValueChanged()) {
            this.minOrMaxChanged = false;
            if (this.emitValueAnyway) {
                this.checkValidityWhenEmittingValueAnyway(this.currentValue, function (data) {
                    if (_this.isValueChanged()) {
                        _this.changesEmitted = true;
                        _this.emitEventWhenEmittingValueAnyway(data.value);
                    }
                    else {
                        _this.resetError();
                    }
                });
            }
            else {
                this.checkValidity(this.currentValue, function (date) {
                    if (_this.isValueChanged()) {
                        _this.changesEmitted = true;
                        _this.emitEvent(date);
                    }
                    else {
                        _this.resetError();
                    }
                });
            }
        }
    };
    DatePickerNgxComponent.prototype.checkValidity = function (d, callback) {
        var date = moment(d);
        if (date.isValid()) {
            date = date.toDate();
            var isValidDate = this.isValidDate(date);
            var isValidMin = this.isValidMin(date);
            var isValidMax = this.isValidMax(date);
            switch (false) {
                case isValidDate:
                    this.setError('date');
                    break;
                case isValidMin:
                    this.setError('minDate');
                    break;
                case isValidMax:
                    this.setError('maxDate');
                    break;
                default:
                    callback(date);
                    break;
            }
        }
        else {
            if (this.acceptNullDate) {
                callback(null);
            }
            else {
                if (this.required !== false) {
                    this.setError('required');
                }
                else {
                    this.setError('date');
                }
            }
        }
    };
    DatePickerNgxComponent.prototype.checkValidityWhenEmittingValueAnyway = function (d, callback) {
        var date = moment(d);
        if (date.isValid()) {
            date = date.toDate();
            var isValidDate = this.isValidDate(date);
            var isValidMin = this.isValidMin(date);
            var isValidMax = this.isValidMax(date);
            switch (false) {
                case isValidDate:
                    this.setError('date');
                    callback({ value: null, error: true });
                    break;
                case isValidMin:
                    this.setError('minDate');
                    callback({ value: null, error: true });
                    break;
                case isValidMax:
                    this.setError('maxDate');
                    callback({ value: null, error: true });
                    break;
                default:
                    callback({ value: date, error: false });
                    break;
            }
        }
        else {
            if (this.acceptNullDate) {
                callback({ value: null, error: false });
            }
            else {
                if (this.required !== false) {
                    this.setError('required');
                    callback({ value: null, error: true });
                }
                else {
                    callback({ value: null, error: false });
                }
            }
        }
    };
    DatePickerNgxComponent.prototype.emitEvent = function (newValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.changeValue(newValue)];
                    case 1:
                        res = _a.sent();
                        this.changesEmitted = false;
                        this.resetError();
                        if (!res) {
                            this.currentValue = this.prevValue;
                            return [2 /*return*/];
                        }
                        this.prevValue = newValue;
                        return [2 /*return*/];
                }
            });
        });
    };
    DatePickerNgxComponent.prototype.emitEventWhenEmittingValueAnyway = function (newValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var errors, res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        errors = _.cloneDeep(this.ngControl.control.errors);
                        return [4 /*yield*/, this.changeValue(newValue)];
                    case 1:
                        res = _a.sent();
                        this.ngControl.control.setErrors(errors);
                        this.changesEmitted = false;
                        if (!res) {
                            this.currentValue = this.prevValue;
                            return [2 /*return*/];
                        }
                        this.prevValue = newValue === null ? undefined : newValue;
                        return [2 /*return*/];
                }
            });
        });
    };
    DatePickerNgxComponent.prototype.isValueChanged = function () {
        if (_.isDate(this.currentValue) && _.isDate(this.prevValue)) {
            return !moment(this.currentValue).isSame(moment(this.prevValue));
        }
        return this.currentValue !== this.prevValue;
    };
    DatePickerNgxComponent.prototype.isValidValue = function (currentValue) {
        return !_.isNull(currentValue) && !_.isUndefined(currentValue);
    };
    DatePickerNgxComponent.prototype.isValidDate = function (currentValue) {
        return dateTimeUtils.validateDate(currentValue);
    };
    DatePickerNgxComponent.prototype.isValidMin = function (date) {
        if (!_.isDate(this.minDate) || !_.isDate(date)) {
            return true;
        }
        var minDate = moment(date);
        return moment(this.minDate).startOf('day').isSameOrBefore(minDate.startOf('day'));
    };
    DatePickerNgxComponent.prototype.isValidMax = function (date) {
        if (!_.isDate(this.maxDate) || !_.isDate(date)) {
            return true;
        }
        var maxDate = moment(date);
        return moment(this.maxDate).startOf('day').isSameOrAfter(maxDate.startOf('day'));
    };
    DatePickerNgxComponent.prototype.setError = function (errorName) {
        var _a;
        this.isIncorrectDate = true;
        this.ngControl.control.setErrors((_a = {}, _a[errorName] = true, _a));
    };
    DatePickerNgxComponent.prototype.resetError = function () {
        this.isIncorrectDate = false;
        this.ngControl.control.setErrors(null);
    };
    return DatePickerNgxComponent;
}(AcceptableControlValueAccessor));
export { DatePickerNgxComponent };
