import { IBenefitDetailsBasic } from './benefit-details-basic';
import { BenefitDetailsCalcMethod } from './benefit-details-calc-method';
import { IBenefitDetailsGroupBasic, BenefitDetailsGroupBasic } from './benefit-details-group-basic';

export interface IBenefitDetailsGroup {
  groupDefinition: IBenefitDetailsGroupBasic;
  calcMethods: IBenefitDetailsBasic<string, string>[];
  allowMultipleEnrollment: boolean;
  isDeleted: boolean;
  modifiedAt: string;
  modifiedBy: string;
  addedAt: string;
  addedBy: string;
}

export class BenefitDetailsGroup {
  public group = new BenefitDetailsGroupBasic();
  public calcMethods: BenefitDetailsCalcMethod[] = [];
  public allowMultipleEnrollment: boolean;
  public isDeleted: boolean;
  public modifiedAt: Date;
  public modifiedBy: string;
  public addedAt: Date;
  public addedBy: string;

  public get id(): number {
    return this.group.id;
  }

  public get name(): string {
    return this.group.name;
  }

  public get title(): string {
    return this.group.title;
  }

  public get type(): string {
    return this.group.type;
  }
}

export interface BenefitGroupLoader {
  groupId: number;
  isLoading: boolean;
}
