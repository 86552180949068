<div class="slx-row slx-row__padding-bottom">
  <div id="ruless" class="slx-col-2">
    <slx-dropdown-list
      class="slx-wide slx-short"
      *ngIf="!isFirstItem"
      [(ngModel)]="condition"
      (ngModelChange)="onRuleConditionTypeChanged($event)"
      [disableEmittingIfReadonly]="state.isControlCreating"
      [readonly]="state.isControlCreating"
      [options]="benefitEligibilityRuleConditions"
      [valuePrimitive]="true"
      name="condition-{{ uniqueComponentId }}"
      [required]="!isFirstItem"
    >
    </slx-dropdown-list>
  </div>
  <div class="slx-col-3">
    <div class="selector-element">
      <slx-dropdown-list
        class="slx-wide slx-short"
        [(ngModel)]="variable"
        (ngModelChange)="onRuleConfigurationVariableChanged($event)"
        [disableEmittingIfReadonly]="state.isControlCreating"
        [readonly]="state.isControlCreating"
        [options]="configurationVariables"
        valueField="id"
        titleField="name"
        name="variable-{{ uniqueComponentId }}"
        required
      >
      </slx-dropdown-list>
    </div>
  </div>
  <div class="slx-col-2">
    <slx-dropdown-list
      class="slx-wide slx-short"
      [(ngModel)]="predicate"
      (ngModelChange)="onRuleConfigurationPredicateChanged($event)"
      [disableEmittingIfReadonly]="state.isControlCreating"
      [readonly]="state.isControlCreating"
      [options]="currentPredicates"
      name="predicate-{{ uniqueComponentId }}"
      required
    >
    </slx-dropdown-list>
  </div>

  <div class="slx-col-5 no-padding-right">
    <ng-container *ngIf="isInNotInPredicate">
      <ng-container [ngSwitch]="variable?.id">
        <ng-container *ngSwitchCase="BenefitEligibilityRuleVariableEnum.BenefitClass">
          <input
            #benefitClassInput="ngModel"
            class="slx-rule-input"
            type="text"
            name="default-value-{{ uniqueComponentId }}"
            [(ngModel)]="value"
            (blur)="multiBenefitClassValueChanged(value)"
            required
          />
          <span
            class="slx-error-block error-box"
            *ngIf="
              benefitClassInput &&
              (benefitClassInput.dirty || benefitClassInput.touched) &&
              benefitClassInput.errors?.required
            "
            errorMessage
            >The field should contain only string values ​​separated by comma</span
          >
        </ng-container>
        <ng-container *ngSwitchDefault>
          <slx-benefit-details-eligibilty-statement-multi-values
            [value]="value"
            [valueType]="variable.dataType"
            [uniqueComponentId]="uniqueComponentId"
            (valueChanged)="multiValuesChanged($event)"
            required
          >
          </slx-benefit-details-eligibilty-statement-multi-values>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!isInNotInPredicate">
      <div [ngSwitch]="variable?.id">
        <ng-container *ngSwitchCase="BenefitEligibilityRuleVariableEnum.PayType">
          <slx-dropdown-list
            class="slx-wide slx-short"
            [(ngModel)]="selectedBenefitRulePayType"
            [options]="benefitEligibilityRulePayTypes"
            name="payTypes-{{ uniqueComponentId }}"
            [disableEmittingIfReadonly]="state.isControlCreating"
            [readonly]="state.isControlCreating"
            (ngModelChange)="payTypeValueChanged($event)"
            [valuePrimitive]="false"
            required
          >
          </slx-dropdown-list>
        </ng-container>

        <ng-container *ngSwitchCase="BenefitEligibilityRuleVariableEnum.BenefitClass">
          <slx-dropdown-list
            class="slx-wide slx-short"
            [(ngModel)]="selectedBenefitDefinition"
            [options]="benefitClassDefinitions"
            name="benefitClass-{{ uniqueComponentId }}"
            [disableEmittingIfReadonly]="state.isControlCreating"
            [readonly]="state.isControlCreating"
            (ngModelChange)="benefitClassValueChanged($event)"
            [valuePrimitive]="false"
            required
          >
          </slx-dropdown-list>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div class="selector-element" [ngSwitch]="dataType">
            <ng-container *ngSwitchCase="BenefitEligibilityRuleDataType.Integer">
              <slx-benefit-details-eligibility-statement-number-values
                [uniqueComponentId]="uniqueComponentId"
                [isMinMaxValue]="isMinMaxPredicate"
                [value]="value"
                (valueChanged)="singleValueOrPairChanged($event)"
                required
              >
              </slx-benefit-details-eligibility-statement-number-values>
            </ng-container>

            <ng-container *ngSwitchCase="BenefitEligibilityRuleDataType.Decimal">
              <slx-benefit-details-eligibility-statement-number-values
                [uniqueComponentId]="uniqueComponentId"
                [isMinMaxValue]="isMinMaxPredicate"
                [value]="value"
                (valueChanged)="singleValueOrPairChanged($event)"
                required
              >
              </slx-benefit-details-eligibility-statement-number-values>
            </ng-container>

            <ng-container *ngSwitchCase="BenefitEligibilityRuleDataType.Boolean">
              <slx-dropdown-list
                class="slx-wide slx-short"
                [(ngModel)]="value"
                [options]="benefitEligibilityRuleBooleanTypes"
                name="boolean-{{ uniqueComponentId }}"
                [disableEmittingIfReadonly]="state.isControlCreating"
                [readonly]="state.isControlCreating"
                [valuePrimitive]="true"
                (ngModelChange)="singleValueOrPairChanged(value)"
                required
              >
              </slx-dropdown-list>
            </ng-container>

            <ng-container *ngSwitchCase="BenefitEligibilityRuleDataType.DateTime">
              <slx-benefit-details-eligibility-statement-date-values
                [uniqueComponentId]="uniqueComponentId"
                [isMinMaxValue]="isMinMaxPredicate"
                [value]="value"
                (valueChanged)="singleValueOrPairChanged($event)"
                required
              >
              </slx-benefit-details-eligibility-statement-date-values>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <input
                class="slx-rule-input"
                type="text"
                name="default-value-{{ uniqueComponentId }}"
                [required]="true"
                [(ngModel)]="value"
                (blur)="singleValueOrPairChanged(value)"
                required
              />
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
