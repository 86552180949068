import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';

import { OrgLevel } from '../../../state-model/models/index';

import { schedulerConfig } from '../../scheduler.config';
import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../../common/utils/index';

import { Census, ICensus, CensusSaveRequest, AcuityConfiguration, IAcuityConfiguration } from '../../models/index';
import { CensusEntryMapService } from './census-entry-map.service';
import { CensusCategoryConfig, ICensusCategoryConfig, sendCensusCategoryWithCensus } from '../../models/census/census-category';

@Injectable()
export class CensusEntryApiService {

  constructor(private apiUtilService: ApiUtilService, private censusEntryMapService: CensusEntryMapService, private urlParamsService: UrlParamsService) {
  }

  public getCensus(orgLevel: OrgLevel, date: Date): Promise<AcuityConfiguration> {
    Assert.isNotNull(orgLevel);

    const url: string = this.getCensusApiRoot(orgLevel.id, date);
    let params: HttpParams = this.urlParamsService.convertToParams({
      orgLevelId: orgLevel.id,
      date: dateTimeUtils.convertToDtoString(date)
    });


    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<AcuityConfiguration> = this.apiUtilService.request<AcuityConfiguration, Meta>(request)
      .then((response: ResponseBody<IAcuityConfiguration, Meta>) => this.censusEntryMapService.mapToAcuityConfiguration(response.data));
    return promise;
  }

  public saveCensus(orgLevel: OrgLevel, dateOn: Date, census: AcuityConfiguration): Promise<AcuityConfiguration> {
    Assert.isNotNull(orgLevel);
    Assert.isNotNull(census);

    const url: string = this.getCensusApiRoot(orgLevel.id, dateOn);

    let promise: Promise<AcuityConfiguration> = this.apiUtilService.request<AcuityConfiguration, Meta>(
      this.urlParamsService.createPutRequest(url, census, { orgLevelId: orgLevel.id })
    )
      .then((response: ResponseBody<IAcuityConfiguration, Meta>) => this.censusEntryMapService.mapToAcuityConfiguration(response.data));
    return promise;
  }

  public getCensusCategories(orgLevel: OrgLevel): Promise<CensusCategoryConfig> {
    Assert.isNotNull(orgLevel);

    const url: string = this.getCensusCategoriesApiRoot(orgLevel.id);
    let params: HttpParams = this.urlParamsService.convertToParams({
      orgLevelId: orgLevel.id
    });


    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<CensusCategoryConfig> = this.apiUtilService.request<CensusCategoryConfig, Meta>(request)
      .then((response: ResponseBody<ICensusCategoryConfig, Meta>) => this.censusEntryMapService.mapToCensusConfig(response.data));
    return promise;
  }

  public saveCensusCategories(orgLevel: OrgLevel, dateOn: Date, census: sendCensusCategoryWithCensus): Promise<any> {
    Assert.isNotNull(orgLevel);
    Assert.isNotNull(dateOn);
    Assert.isNotNull(census);

    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.census}/${schedulerConfig.api.censusCategories}?dateOn=${dateTimeUtils.convertToDtoString(dateOn)}&orgLevelId=${orgLevel.id}`;

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(
      this.urlParamsService.createPutRequest(url, census)
    )
    return promise;
  }

  private getCensusApiRoot(orgLevelId: number, dateOn: Date): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${dateTimeUtils.convertToDtoString(dateOn)}/${schedulerConfig.api.orglevel}/${orgLevelId}/${schedulerConfig.api.census}`;
  }

  private getCensusCategoriesApiRoot(orgLevelId?: number, dateOn?: Date): string {
    return orgLevelId ? `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.census}/${schedulerConfig.api.censusCategories}/${orgLevelId}` 
            : `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.census}/${schedulerConfig.api.censusCategories}`;
  }
}
