import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable, Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { ManagementBaseService } from '../../../core/services/index';

import { PunchesApiService } from './punches-api.service';
import { LinePunch, IApplyPunchesEvent, PunchesEventFilter, PunchesCheckResult, PunchStatus } from '../../models/index';
import { PunchesFilterHelper } from './punches-filter-helper';

export type PunchesView = 'Punches' | 'PunchPairs';

@Injectable()
export class DailyPunchesManagementService extends ManagementBaseService<LinePunch[], any> {

  public canEditChanged$: Subject<boolean>;
  public onChangeView$: Subject<PunchesView>;
  public onApplyChanges$: Subject<IApplyPunchesEvent>;
  public onStartChangingPunches$: Subject<any>;
  public onCancelChangingPunches$: Subject<any>;
  public onChangingPunches$: Subject<LinePunch[]>;
  public onDiscardChanges$: Subject<any>;
  public onLoadedPairs$: ReplaySubject<LinePunch[]>;
  public isPaidRestBreakEnabled$: Subject<boolean>;
  public onFilterSet$: Subject<PunchesEventFilter>;
  public onFilterApplied$: Subject<LinePunch[]>;

  private filter: PunchesEventFilter;
  constructor(private apiService: PunchesApiService) {
    super();
    this.canEditChanged$ = new ReplaySubject(1);
    this.onLoadedPairs$ = new ReplaySubject(1);
    this.onChangeView$ = new Subject();
    this.onApplyChanges$ = new Subject();
    this.onChangingPunches$ = new Subject();
    this.onStartChangingPunches$ = new Subject();
    this.onCancelChangingPunches$ = new Subject();
    this.onDiscardChanges$ = new Subject();
    this.onFilterSet$ = new Subject();
    this.onFilterApplied$ = new Subject();
    this.isPaidRestBreakEnabled$ = new ReplaySubject(1);
  }

  public markClosingPunches(records: LinePunch[]): void {
    let priorIsIn = false;
    const sorted = _.sortBy(records,'time');
    _.forEach(sorted, (punch: LinePunch) => {
      if (!punch.type || (punch.punchStatus !== PunchStatus.Edited && punch.punchStatus !== PunchStatus.Valid)) {
        punch.canBeClosing = false;
        return;
      }
      if ((punch.type.isOut && priorIsIn) || !!punch.closingPunch) {
        punch.canBeClosing = true;
      } else {
        punch.canBeClosing = false;
      }
      if (punch.type.isIn) {
        priorIsIn = true;
      }
    });
  }

  public validatePairs(records: LinePunch[]): PunchesCheckResult {
    let hasInMissingPunch = false;
    let hasOutMissingPunch = false;
    let expectedDirection = 'in';
    const exepmtPunches = _.filter(records, (punch: LinePunch) => !!punch.isExempt);
    if (exepmtPunches.length > 0) {
      hasInMissingPunch = !_.find(exepmtPunches, (punch: LinePunch) => punch.punchStatus === PunchStatus.Valid);
      return { hasInMissingPunch, hasOutMissingPunch };
    }
    const sorted = _.sortBy(records,'time');
    _.forEach(sorted, (punch: LinePunch) => {
      if (!punch.type || hasInMissingPunch || hasOutMissingPunch) {
        return;
      }

      if (punch.punchStatus !== PunchStatus.Edited && punch.punchStatus !== PunchStatus.Valid && !punch.isEditedAndValidated) {
        return;
      }

      if (punch.type.isIn) {
        if (expectedDirection === 'out') {
          hasOutMissingPunch = true;
        }
        expectedDirection = 'out';
      }
      if (punch.type.isOut) {
        if (expectedDirection === 'in') {
          hasInMissingPunch = true;
        }
        expectedDirection = 'in';
      }
    });
    if (expectedDirection === 'out') {
      hasOutMissingPunch = true;
    }
    return { hasInMissingPunch, hasOutMissingPunch };
  }

  public canEditChanged(canEdit: boolean): void {
    this.canEditChanged$.next(canEdit);
  }

  public changeView(view: PunchesView): void {
    this.onChangeView$.next(view);
  }

  public discardChanges(): void {
    this.onDiscardChanges$.next();
  }

  public applyChanges(changes: IApplyPunchesEvent): void {
    this.onApplyChanges$.next(changes);
  }

  public startChanges(): void {
    this.onStartChangingPunches$.next();
  }

  public cancelChanges(): void {
    this.onCancelChangingPunches$.next();
  }

  public emitChanges(pairs: LinePunch[]): void {
    this.onChangingPunches$.next(pairs);
  }

  public setPanches(pairs: LinePunch[]): void {
    if (this.filter) {
      const filterHelper: PunchesFilterHelper = new PunchesFilterHelper();
      filterHelper.filter(pairs, this.filter);
    }
    this.onLoadedPairs$.next(pairs);
    this.onFilterApplied$.next(pairs);
  }

  public applyCurrentFilter(records: LinePunch[]): void {
    let filteredEntities = this.filterPunches(records, this.filter);
    this.onFilterApplied$.next(filteredEntities);
  }

  public applyFilter(records: LinePunch[], filter: PunchesEventFilter): void {
    this.setFilter(filter);
    let filteredEntities = this.filterPunches(records, this.filter);
    this.onFilterApplied$.next(filteredEntities);
  }

  public setFilter(filter: PunchesEventFilter): void {
    this.filter = filter;
    this.onFilterSet$.next(filter);
  }

  private filterPunches(entities: LinePunch[], filter: PunchesEventFilter): LinePunch[] {
    if (!filter) {
      return entities;
    }
    let filteredList: LinePunch[] = entities;

    const filterHelper: PunchesFilterHelper = new PunchesFilterHelper();
    filterHelper.filter(filteredList, filter);
    return filteredList;
  }


  public removeInvalidLogin(date:Date,empId:number):Promise<boolean>{
   return this.apiService.removeInvalidLogin(date,empId);
  }

}
