import * as _ from 'lodash';
var ExceptionConsoleState = /** @class */ (function () {
    function ExceptionConsoleState() {
    }
    Object.defineProperty(ExceptionConsoleState.prototype, "allColumns", {
        get: function () {
            return this.dynamicColumns ? _.concat(this.columns, this.dynamicColumns) : this.columns;
        },
        enumerable: true,
        configurable: true
    });
    ExceptionConsoleState.prototype.mapColumns = function () {
        this.columnsMap = _.keyBy(this.allColumns, function (column) {
            return column.name;
        });
    };
    ExceptionConsoleState.prototype.createColumns = function () {
        this.columns = [
            {
                name: 'position',
                description: 'Position',
                readonly: true,
                displayed: true,
                width: 180,
                filter: null
            },
            {
                name: 'missingPunches',
                description: 'Pending/Approved Missing Punches',
                readonly: false,
                displayed: true,
                width: 170,
                filter: null
            },
            {
                name: 'totalExceptionsCount',
                description: 'Total Exceptions Count',
                readonly: false,
                displayed: true,
                width: 150,
                filter: 'numeric'
            }
        ];
    };
    ExceptionConsoleState.prototype.setDynamicColumns = function (cols) {
        var columns = _.map(cols, function (col) {
            return {
                name: col.exceptionId,
                description: col.exceptionName,
                readonly: false,
                displayed: true,
                width: 120,
                filter: 'numeric',
                payload: {
                    exceptionField: col.fieldName,
                    countAsException: true
                }
            };
        });
        this.dynamicColumns = columns;
        this.mapColumns();
    };
    ExceptionConsoleState.prototype.setState = function (initialState) {
        this.createColumns();
        _.forEach(this.columns, function (column) {
            column.displayed = initialState[column.name];
        });
    };
    return ExceptionConsoleState;
}());
export { ExceptionConsoleState };
