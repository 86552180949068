import { IPayloadAction } from '../../../state-model/models/index';
import { IndividualScheduleActions } from './individual-schedule.actions';
import { Map } from 'immutable';
import {
  INITIAL_INDIVIDUAL_SCHEDULE_STATE, INITIAL_BY_ORGLEVEL_INDIVIDUAL_SCHEDULE_STATE
} from './individual-schedule.initial-state';
import {
  IOrgLevelIndividualScheduleState, IIndividualScheduleState, IIndividualScheduleCycle
} from './individual-schedule.types';

export function individualScheduleOrgLevelStateReducer(state: Map<string, any> = INITIAL_BY_ORGLEVEL_INDIVIDUAL_SCHEDULE_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case IndividualScheduleActions.INDIVIDUAL_SCHEDULE_SELECT_ITEM:
      return state.merge({ employeeId: action.payload.employeeId });
    case IndividualScheduleActions.INDIVIDUAL_SCHEDULE_SELECT_CYCLE:
      return state.merge({ cycle: action.payload.cycle });
    default:
      return state;
  }
}

export function individualScheduleStateReducer(state: Map<string, any> = INITIAL_INDIVIDUAL_SCHEDULE_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case IndividualScheduleActions.INDIVIDUAL_SCHEDULE_SELECT_ITEM:
    case IndividualScheduleActions.INDIVIDUAL_SCHEDULE_SELECT_CYCLE:

      let orgState: Map<string, any> = state.getIn(['stateByOrglevel', action.payload.orgLevelId], INITIAL_BY_ORGLEVEL_INDIVIDUAL_SCHEDULE_STATE);
      return state.setIn(['stateByOrglevel', action.payload.orgLevelId],
        individualScheduleOrgLevelStateReducer(orgState, action)
      );
    default:
      return state;
  }
}
