import { Component } from '@angular/core';
import { TelepunchLocationsManagementService } from '../../services/telepunch-locations/telepunch-locations-management.service';
import { AccessManagementService } from '../../services/index';
import { IScreenUtils, screenUtils } from '../../../common/utils/index';

@Component({
    moduleId: module.id,
    selector: 'slx-telepunch-locations',
    templateUrl: 'telepunch-locations.component.html',
    styleUrls: ['telepunch-locations.component.scss'],
    providers: [AccessManagementService, TelepunchLocationsManagementService]
})
  
export class TelepunchLocationsComponent {
    public isLoading: boolean;
    public screenUtil: IScreenUtils;

    constructor() {
        this.screenUtil = screenUtils;
    }
}
