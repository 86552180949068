/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lm-recurrence-frequency.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./lm-recurrence-frequency.component";
import * as i4 from "../../../services/lm-creation-absence/lm-creation-absence.management.service";
var styles_LMRecurrenceFrequencyComponent = [i0.styles];
var RenderType_LMRecurrenceFrequencyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LMRecurrenceFrequencyComponent, data: {} });
export { RenderType_LMRecurrenceFrequencyComponent as RenderType_LMRecurrenceFrequencyComponent };
function View_LMRecurrenceFrequencyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "slx-line-buttons__btn"]], [[2, "active", null], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickFrequency(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.isActive; var currVal_1 = (!_co.canEditRequest || _co.hasExceptions); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_2); }); }
export function View_LMRecurrenceFrequencyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "label-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Repeat"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "slx-line-buttons buttons-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMRecurrenceFrequencyComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.absenceFrequencies; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_LMRecurrenceFrequencyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-lm-recurrence-frequency", [], null, null, null, View_LMRecurrenceFrequencyComponent_0, RenderType_LMRecurrenceFrequencyComponent)), i1.ɵdid(1, 114688, null, 0, i3.LMRecurrenceFrequencyComponent, [i4.LMCreationAbsenceManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LMRecurrenceFrequencyComponentNgFactory = i1.ɵccf("slx-lm-recurrence-frequency", i3.LMRecurrenceFrequencyComponent, View_LMRecurrenceFrequencyComponent_Host_0, {}, {}, []);
export { LMRecurrenceFrequencyComponentNgFactory as LMRecurrenceFrequencyComponentNgFactory };
