export * from './employee-sections-benefit-class/employee-sections-benefit-class.component';
export * from './employee-sections-benefit-class/confirm-benefit-changes-dialog/confirm-benefit-changes-dialog.component';
export * from './employee-sections-dependents/employee-sections-dependents.component';
export * from './employee-sections-dependents-grid/employee-sections-dependents-grid.component';
export * from './employee-sections-dependents-enrollment-grid/employee-sections-dependents-enrollment-grid.component';
export * from './employee-dependent-benefits-dialog/employee-dependent-benefits-dialog.component';
export * from './employee-dependent-drop-enrollment-dialog/employee-dependent-drop-enrollment-dialog.component';
export * from './employee-dependent-drop-enrollment/employee-dependent-drop-enrollment.component';
export * from './employee-dependent-benefits/employee-dependent-benefits.component';
export * from './employee-sections-enrollment/index';

import { EmployeeSectionsBenefitClassComponent } from './employee-sections-benefit-class/employee-sections-benefit-class.component';
import { ConfirmBenefitChangesDialogComponent } from './employee-sections-benefit-class/confirm-benefit-changes-dialog/confirm-benefit-changes-dialog.component';
import { EmployeeSectionsDependentsComponent } from './employee-sections-dependents/employee-sections-dependents.component';
import { EmployeeSectionsDependentsGridComponent } from './employee-sections-dependents-grid/employee-sections-dependents-grid.component';
import { EmployeeSectionsDependentsEnrollmentGridComponent } from './employee-sections-dependents-enrollment-grid/employee-sections-dependents-enrollment-grid.component';
import { EmployeeDependentBenefitsDialogComponent } from './employee-dependent-benefits-dialog/employee-dependent-benefits-dialog.component';
import { EmployeeDependentDropEnrollmentDialogComponent } from './employee-dependent-drop-enrollment-dialog/employee-dependent-drop-enrollment-dialog.component';
import { EmployeeDependentDropEnrollmentComponent } from './employee-dependent-drop-enrollment/employee-dependent-drop-enrollment.component';
import { EmployeeDependentBenefitsComponent } from './employee-dependent-benefits/employee-dependent-benefits.component';
import { BenefitAttachmentsComponent } from './employee-sections-enrollment/benefit-attachments/benefit-attachments.component';
import { BenefitEnrollmentHistoryComponent } from './employee-sections-enrollment/benefit-enrollment-history/benefit-enrollment-history.component';
import { BenefitEnrollmentComponent } from './employee-sections-enrollment/benefit-enrollment/benefit-enrollment.component';
import { BenefitEnrollmentSectionComponent } from './employee-sections-enrollment/benefit-enrollment-section/benefit-enrollment-section.component';
import { BenefitPlanSaveNotesDialogComponent } from './employee-sections-enrollment/benfit-plan-notes-dialog-box/benfit-plan-notes-dialog-box';
import { BenefitAddAttachmentsDialogComponent } from './employee-sections-enrollment/benefits-add-file-modal/benefits-add-file-modal.component';

import { DropCoveragePopupComponent } from './employee-sections-enrollment/drop-coverage-popup/drop-coverage-popup.component';
import { EnrollEmployeeSelectPlanPopupComponent } from './employee-sections-enrollment/enroll-employee-select-plan-popup/enroll-employee-select-plan-popup.component';
import { EnrollEmployeeSelectCoveragePopupComponent } from './employee-sections-enrollment/enroll-employee-select-coverage-popup/enroll-employee-select-coverage-popup.component';
import { EnrollEmployeeFlatComponent } from './employee-sections-enrollment/enroll-employee-flat/enroll-employee-flat.component';
import { EnrollEmployeeFormulaComponent } from './employee-sections-enrollment/enroll-employee-formula/enroll-employee-formula.component';
import { EnrollEmployeeFormulaWithOptionComponent } from './employee-sections-enrollment/enroll-employee-formula-with-option/enroll-employee-formula-with-option.component';
import { EnrollEmployee401kComponent } from './employee-sections-enrollment/enroll-employee-401k/enroll-employee-401k.component';
import { BeneficiaryEditorPopupComponent } from './employee-sections-enrollment/beneficiary-editor-popup/beneficiary-editor-popup.component';
import { BeneficiaryEditorComponent } from './employee-sections-enrollment/beneficiary-editor/beneficiary-editor.component';
import { BenefitPayrollDeductionComponent } from './employee-sections-enrollment/index';

export const componentsBenefitsManagementSection: any[] = [
  EmployeeSectionsBenefitClassComponent,
  ConfirmBenefitChangesDialogComponent,
  EmployeeSectionsDependentsComponent,
  EmployeeSectionsDependentsGridComponent,
  EmployeeSectionsDependentsEnrollmentGridComponent,
  EmployeeDependentBenefitsDialogComponent,
  EmployeeDependentDropEnrollmentDialogComponent,
  EmployeeDependentDropEnrollmentComponent,
  EmployeeDependentBenefitsComponent,
  BenefitAttachmentsComponent,
  BenefitEnrollmentHistoryComponent,
  BenefitEnrollmentComponent,
  BenefitEnrollmentSectionComponent,
  BenefitAddAttachmentsDialogComponent,
  BenefitPlanSaveNotesDialogComponent,
  BenefitEnrollmentSectionComponent,
  DropCoveragePopupComponent,
  EnrollEmployeeSelectPlanPopupComponent,
  EnrollEmployeeSelectCoveragePopupComponent,
  EnrollEmployeeFlatComponent,
  EnrollEmployeeFormulaComponent,
  EnrollEmployeeFormulaWithOptionComponent,
  EnrollEmployee401kComponent,
  BeneficiaryEditorPopupComponent,
  BeneficiaryEditorComponent,
  BenefitPayrollDeductionComponent
];

export const benefitmanagementEntryComponents: any[] = [
  DropCoveragePopupComponent,
  EnrollEmployeeSelectPlanPopupComponent,
  EnrollEmployeeSelectCoveragePopupComponent,
  EnrollEmployeeFlatComponent,
  EnrollEmployeeFormulaComponent,
  EnrollEmployeeFormulaWithOptionComponent,
  EnrollEmployee401kComponent,
  BeneficiaryEditorPopupComponent
];
