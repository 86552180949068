/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./application-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../common/components/unavailability-notification/unavailability-notification.component.ngfactory";
import * as i4 from "../../../common/components/unavailability-notification/unavailability-notification.component";
import * as i5 from "@angular/common";
import * as i6 from "./application-container.component";
import * as i7 from "../../services/application/application.service";
import * as i8 from "../../services/screen-availability/screen-availability.service";
var styles_ApplicationContainerComponent = [i0.styles];
var RenderType_ApplicationContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApplicationContainerComponent, data: {} });
export { RenderType_ApplicationContainerComponent as RenderType_ApplicationContainerComponent };
function View_ApplicationContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner-container flex-container shown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "blur-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "spinner"]], null, null, null, null, null))], null, null); }
function View_ApplicationContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ApplicationContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-unavailability-notification", [], null, null, null, i3.View_UnavailabilityNotificationComponent_0, i3.RenderType_UnavailabilityNotificationComponent)), i1.ɵdid(2, 114688, null, 0, i4.UnavailabilityNotificationComponent, [], { levels: [0, "levels"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.availability == null) ? null : _co.availability.supportedOrgTypes); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ApplicationContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "main", [["class", "main-box"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(5, { "right-sidebar-open": 0, "left-sidebar-open": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationContainerComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationContainerComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationContainerComponent_3)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "main-box"; var currVal_1 = _ck(_v, 5, 0, i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_co.isRightSidebarOpen)), i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform(_co.isLeftSidebarOpen))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !((_co.availability == null) ? null : _co.availability.isReady); _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.availability == null) ? null : _co.availability.isReady) && ((_co.availability == null) ? null : _co.availability.isAvailable)); _ck(_v, 9, 0, currVal_3); var currVal_4 = (((_co.availability == null) ? null : _co.availability.isReady) && !((_co.availability == null) ? null : _co.availability.isAvailable)); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_ApplicationContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-application-container", [], null, null, null, View_ApplicationContainerComponent_0, RenderType_ApplicationContainerComponent)), i1.ɵdid(1, 245760, null, 0, i6.ApplicationContainerComponent, [i2.Router, i2.ActivatedRoute, i7.ApplicationService, i8.ScreenAvailabilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApplicationContainerComponentNgFactory = i1.ɵccf("slx-application-container", i6.ApplicationContainerComponent, View_ApplicationContainerComponent_Host_0, {}, {}, []);
export { ApplicationContainerComponentNgFactory as ApplicationContainerComponentNgFactory };
