import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { ScheduleEntryManagementService } from '../../../services/index';
import { ScheduleEntryShiftContainer, ShiftLate, ShiftOvertime, ShiftPartialAbsence } from '../../../models/index';
import { appConfig } from '../../../../app.config';
var ScheduledShiftComponent = /** @class */ (function () {
    function ScheduledShiftComponent(management) {
        this.management = management;
        this.appConfig = appConfig;
    }
    Object.defineProperty(ScheduledShiftComponent.prototype, "shift", {
        get: function () {
            return this.m_shift;
        },
        set: function (value) {
            this.m_shift = value;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledShiftComponent.prototype.ngOnInit = function () {
        _.noop();
    };
    // --- menu hanlders ---
    ScheduledShiftComponent.prototype.removeShift = function () {
        this.management.removeShift(this.m_shift);
    };
    ScheduledShiftComponent.prototype.addConstraint = function () {
        if (this.m_shift && this.m_shift.hasConstraints) {
            this.management.addConstraint(this.m_shift);
        }
    };
    ScheduledShiftComponent.prototype.removeConstraint = function () {
        this.management.removeConstraint(this.m_shift);
    };
    ScheduledShiftComponent.prototype.addLate = function () {
        this.management.addLate(this.m_shift);
    };
    ScheduledShiftComponent.prototype.removeLate = function () {
        this.management.removeLate(this.m_shift);
    };
    ScheduledShiftComponent.prototype.addOvertime = function () {
        this.management.addOvertime(this.m_shift);
    };
    ScheduledShiftComponent.prototype.removeOvertime = function () {
        this.management.removeOvertime(this.m_shift);
    };
    ScheduledShiftComponent.prototype.markAbsent = function () {
        this.management.markAbsent(this.m_shift);
    };
    ScheduledShiftComponent.prototype.markPresent = function () {
        this.management.markPresent(this.m_shift);
    };
    ScheduledShiftComponent.prototype.addPartialAbsence = function () {
        this.management.addPartialAbsence(this.m_shift);
    };
    ScheduledShiftComponent.prototype.removePartialAbsence = function () {
        this.management.removePartialAbsence(this.m_shift);
    };
    ScheduledShiftComponent.prototype.addNotes = function () {
        this.management.addNotes(this.m_shift);
    };
    ScheduledShiftComponent.prototype.removeNotes = function () {
        this.management.removeNotes(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onReplacement = function () {
        this.management.onReplacement(this.m_shift);
    };
    //--- section hanlders
    ScheduledShiftComponent.prototype.onPositionChanged = function (newPosition) {
        this.m_shift.scheduleEntryShift.position = newPosition;
        this.management.onPositionChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onShiftChanged = function (definition) {
        this.m_shift.scheduleEntryShift.shift = definition;
        this.management.shiftChange(this.m_shift.scheduleEntryShift, this.m_shift);
    };
    ScheduledShiftComponent.prototype.onUnitChanged = function (unit) {
        this.m_shift.scheduleEntryShift.unit = unit;
        this.management.unitChange(unit, this.m_shift);
    };
    ScheduledShiftComponent.prototype.onAbsenceChanged = function (absence) {
        this.m_shift.scheduleEntryShift.scheduleAbsence = absence;
        this.management.absenceChange(absence, this.m_shift);
    };
    ScheduledShiftComponent.prototype.onConstraintChanged = function (constraint) {
        this.m_shift.scheduleEntryShift.constraint = constraint;
        this.management.constraintChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onStartDateChanged = function (date) {
        this.m_shift.shiftStartDate = date;
        this.management.onShiftStartDateChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onEndDateChanged = function (date) {
        this.m_shift.shiftEndDate = date;
        this.management.onShiftEndDateChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onShiftHoursChanged = function (value) {
        this.m_shift.shiftHours = value;
        this.management.shiftPaidTimeChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onUnpaidHoursChanged = function (value) {
        this.m_shift.unpaidHours = value;
        this.management.shiftUnpaidTimeChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onNoteChanged = function (notes) {
        this.m_shift.scheduleEntryShift.notes = notes;
        this.management.checkChanges();
    };
    ScheduledShiftComponent.prototype.lateDateChanged = function (late) {
        this.management.lateDateChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onLateIntervalChanged = function (late) {
        this.management.lateIntervalChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.overtimeDateChanged = function (overtime) {
        this.management.overtimeDateChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.overtimeHoursChanged = function (overtime) {
        this.management.overtimeHoursChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onPartialAbsenceTimeChange = function (pa) {
        this.management.shiftPartialAbsenceTimeChanged(this.m_shift);
    };
    ScheduledShiftComponent.prototype.onPartialAbsenceChange = function (pa) {
        this.management.checkChanges();
    };
    ScheduledShiftComponent.prototype.onPartialAbsenceAttachmentChange = function (toEnd) {
        this.m_shift.partialAbsToEnd = toEnd;
        this.management.onPartialAbsenceAttachmentChanged(this.m_shift);
    };
    return ScheduledShiftComponent;
}());
export { ScheduledShiftComponent };
