import * as _ from 'lodash';
import { SessionService } from '../../../app/core/services';
import { MessageList, EmployeeProfilePicture, UserPicture, LatestScheduleCycle, ColdStorageFlag, MessageCountResponse } from './models';
import { Assert } from '../../framework/index';
import { ComposeNewTabsPermissions } from './models/ComposeNewTabsPermissions';
import { SlateLatestMessageInfo, SlateMessageEmployeeByGroupIdInfo, SlateMessageEmployeeInfo, SlateMessageEmployeesInGroup, SlateMessageGroupInfo, SlateMessageGroupInfoOfGroupId, SlateMessageInfoOfGroup } from './models/slate-message-group-info';
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/session/session.service";
var MessagemapService = /** @class */ (function () {
    function MessagemapService(sessionService) {
        this.sessionService = sessionService;
    }
    MessagemapService.prototype.mapToMessages = function (dtos) {
        var _this = this;
        return _.map(dtos, function (v) { return _this.mapToMessageList(v); });
    };
    MessagemapService.prototype.mapToMessageList = function (dto) {
        return dto;
    };
    MessagemapService.prototype.mapToMessageThread = function (data) {
        var _this = this;
        var thread = [];
        _.forEach(data, function (record) {
            var r = {
                from: _this.getFrom(record),
                time: record.created !== null ? record.created : record.modified,
                message: record.body,
                replied: record.replied,
                phone: record.sender,
                status: record.status,
                id: record.id,
                read: record.read,
                employeeId: record.employeeId,
                errorCode: record.errorCode,
                isOptInRequest: record.isOptInRequest,
                userId: record.userId,
                userName: record.userName
            };
            thread.push(r);
        });
        return thread;
    };
    MessagemapService.prototype.getFrom = function (val) {
        var me = "Me";
        var from;
        if (val.userId !== this.sessionService.getUser().id) {
            from = val.userName;
        }
        else if (val.replied) {
            from = val.fullName;
        }
        else {
            from = me;
        }
        return from;
    };
    MessagemapService.prototype.mapToTabCountRecords = function (data) {
        var _this = this;
        var arr = [];
        _.forEach(data, function (record) {
            arr.push(_this.mapToTabCountRecord(record));
        });
        return arr;
    };
    MessagemapService.prototype.mapToTabCountRecord = function (data) {
        Assert.isNotNull(data, 'data');
        var messageCountResponse = new MessageCountResponse();
        messageCountResponse.sectionName = data.sectionName;
        messageCountResponse.messageCount = data.messageCount;
        return messageCountResponse;
    };
    MessagemapService.prototype.mapToMessasgeListRecords = function (data) {
        var _this = this;
        var arr = [];
        _.forEach(data, function (record) {
            arr.push(_this.mapToMessageListRecord(record));
        });
        return arr;
    };
    MessagemapService.prototype.mapToMessageListRecord = function (data) {
        Assert.isNotNull(data, 'data');
        var messageList = new MessageList();
        messageList.id = data.id;
        messageList.alias = data.alias;
        messageList.archived = data.archived;
        messageList.body = data.body;
        messageList.employeeId = data.employeeId;
        messageList.employeeName = data.employeeName;
        messageList.modified = data.created == null ? data.modified : data.created;
        messageList.read = data.read != null ? data.read : false;
        messageList.replyCount = data.replyCount;
        messageList.isOnLeave = data.isOnLeave;
        messageList.mobilePhoneNumber = data.mobilePhoneNumber;
        messageList.optIn = data.optIn;
        messageList.orgLevelId = data.orgLevelId;
        messageList.photo = data.photo;
        messageList.status = data.status;
        messageList.fullName = data.employeeName;
        return messageList;
    };
    MessagemapService.prototype.mapToRecentMessage = function (data) {
        Assert.isNotNull(data, 'data');
        var messageList = new MessageList();
        messageList.id = data.id;
        messageList.alias = data.alias;
        messageList.archived = data.archived;
        messageList.body = data.body;
        messageList.employeeId = data.employeeId;
        messageList.employeeName = data.employeeName;
        messageList.modified = data.modified == null ? data.created : data.modified;
        messageList.read = data.read != null ? data.read : false;
        messageList.replyCount = data.replyCount;
        messageList.isOnLeave = data.isOnLeave;
        messageList.mobilePhoneNumber = data.mobilePhoneNumber;
        messageList.optIn = data.optIn;
        messageList.orgLevelId = data.orgLevelId;
        messageList.photo = data.photo;
        messageList.status = data.employeeStatus;
        messageList.fullName = data.employeeName;
        return messageList;
    };
    MessagemapService.prototype.mapToEmployeeProfilePictures = function (data) {
        var _this = this;
        var arr = [];
        _.forEach(data, function (record) {
            arr.push(_this.mapToEmployeeProfilePicture(record));
        });
        return arr;
    };
    MessagemapService.prototype.mapToEmployeeProfilePicture = function (data) {
        Assert.isNotNull(data, 'data');
        var picture = new EmployeeProfilePicture();
        picture.employeeId = data.employeeId;
        picture.fullName = data.fullName;
        picture.isOnLeave = data.isOnLeave;
        picture.mobilePhoneNumber = data.mobilePhoneNumber;
        picture.optIn = data.optIn;
        picture.orgLevelId = data.orgLevelId;
        picture.photo = data.photo;
        picture.status = data.status;
        return picture;
    };
    MessagemapService.prototype.mapToUserPictures = function (data) {
        var _this = this;
        var arr = [];
        _.forEach(data, function (record) {
            arr.push(_this.mapToUserPicture(record));
        });
        return arr;
    };
    MessagemapService.prototype.mapToUserPicture = function (data) {
        Assert.isNotNull(data, 'data');
        var picture = new UserPicture();
        picture.userId = data.userId;
        picture.profilePicture = data.photo;
        return picture;
    };
    MessagemapService.prototype.mapToLatestScheduleCycle = function (data) {
        Assert.isNotNull(data, 'data');
        var latestCycle = new LatestScheduleCycle();
        latestCycle.startDate = data.startDate;
        latestCycle.endDate = data.endDate;
        return latestCycle;
    };
    MessagemapService.prototype.mapToColdStorageFlag = function (data) {
        Assert.isNotNull(data, 'data');
        var coldStorageFlag = new ColdStorageFlag();
        coldStorageFlag.hasMessages = data.hasMessages;
        return coldStorageFlag;
    };
    MessagemapService.prototype.mapToSlateMessageGroupInfo = function (slateMessageGroupInfosDto) {
        var _this = this;
        var slateMessageGroupInfos = new Array();
        slateMessageGroupInfosDto.forEach(function (dto) {
            var slateMessageGroupInfo = new SlateMessageGroupInfo();
            slateMessageGroupInfo.groupId = dto.groupId;
            slateMessageGroupInfo.createdByDisplayName = dto.createdByDisplayName;
            slateMessageGroupInfo.createdDate = dto.createdDate;
            slateMessageGroupInfo.employees = _this.mapToSlateMessageEmployeeInfo(dto.employees);
            slateMessageGroupInfo.latestMessage = _this.mapToSlateLatestMessageInfo(dto.latestMessage);
            slateMessageGroupInfo.subject = dto.subject;
            slateMessageGroupInfo.updatedDate = dto.updatedDate;
            slateMessageGroupInfos.push(slateMessageGroupInfo);
        });
        return slateMessageGroupInfos;
    };
    MessagemapService.prototype.mapToSlateMessageGroupInfoOfGroupId = function (SlateMessageGroupInfosOfGroupIdDto) {
        var _this = this;
        var slateMessageGroupInfos = new Array();
        SlateMessageGroupInfosOfGroupIdDto.forEach(function (dto) {
            var slateMessageGroupInfoOfGroupId = new SlateMessageGroupInfoOfGroupId();
            slateMessageGroupInfoOfGroupId.groupId = dto.groupId;
            slateMessageGroupInfoOfGroupId.subject = dto.subject;
            slateMessageGroupInfoOfGroupId.createdByDisplayName = dto.createdByDisplayName;
            slateMessageGroupInfoOfGroupId.messages = _this.mapToSlateMessageInfoOfGroup(dto.messages);
            slateMessageGroupInfoOfGroupId.employees = _this.mapToSlateMessageEmployeesInGroup(dto.employees);
            slateMessageGroupInfos.push(slateMessageGroupInfoOfGroupId);
        });
        return slateMessageGroupInfos;
    };
    MessagemapService.prototype.mapToSlateMessageEmployeesInGroup = function (slateMessageEmployeesInGroupDto) {
        var slateMessageEmployeesInGroupList = new Array();
        slateMessageEmployeesInGroupDto.forEach(function (dto) {
            var slateMessageEmployeesInGroup = new SlateMessageEmployeesInGroup();
            slateMessageEmployeesInGroup.employeeId = dto.employeeId;
            slateMessageEmployeesInGroup.employeeName = dto.employeeName;
            slateMessageEmployeesInGroup.read = dto.read;
            slateMessageEmployeesInGroup.readAt = dto.readAt;
            slateMessageEmployeesInGroupList.push(slateMessageEmployeesInGroup);
        });
        return slateMessageEmployeesInGroupList;
    };
    MessagemapService.prototype.mapToComposeNewTabsPermissions = function (data) {
        Assert.isNotNull(data, 'data');
        var composeNewTabsPermissions = new ComposeNewTabsPermissions();
        composeNewTabsPermissions.canSendSMS = data.data.canSendSMS;
        composeNewTabsPermissions.canSendSlate = data.data.canSendSlate;
        composeNewTabsPermissions.canViewSlateTab = data.data.canViewSlateTab;
        composeNewTabsPermissions.canViewSmsTab = data.data.canViewSmsTab;
        return composeNewTabsPermissions;
    };
    MessagemapService.prototype.mapToSlateMessageInfoOfGroup = function (slateMessageInfosOfGroupDto) {
        var _this = this;
        var slateMessageInfosOfGroupList = new Array();
        slateMessageInfosOfGroupDto.forEach(function (dto) {
            var slateMessageInfoOfGroup = new SlateMessageInfoOfGroup();
            slateMessageInfoOfGroup.createdByDisplayName = dto.createdByDisplayName;
            slateMessageInfoOfGroup.createdDate = dto.createdDate;
            slateMessageInfoOfGroup.messageId = dto.messageId;
            slateMessageInfoOfGroup.messageContent = dto.messageContent;
            slateMessageInfoOfGroup.employees = _this.mapToSlateMessageEmployeeByGroupIdInfo(dto.employees);
            slateMessageInfosOfGroupList.push(slateMessageInfoOfGroup);
        });
        return slateMessageInfosOfGroupList;
    };
    MessagemapService.prototype.mapToSlateMessageEmployeeByGroupIdInfo = function (slateMessageEmployeesByGroupIdInfoDto) {
        var slateMessageEmployeeByGroupIdInfoList = new Array();
        slateMessageEmployeesByGroupIdInfoDto.forEach(function (dto) {
            var slateMessageEmployeeByGroupIdInfo = new SlateMessageEmployeeByGroupIdInfo();
            slateMessageEmployeeByGroupIdInfo.employeeId = dto.employeeId;
            slateMessageEmployeeByGroupIdInfo.read = dto.read;
            slateMessageEmployeeByGroupIdInfo.readAt = dto.readAt;
            slateMessageEmployeeByGroupIdInfoList.push(slateMessageEmployeeByGroupIdInfo);
        });
        return slateMessageEmployeeByGroupIdInfoList;
    };
    MessagemapService.prototype.mapToSlateLatestMessageInfo = function (slateLatestMessageInfoDto) {
        var slateLatestMessageInfo = new SlateLatestMessageInfo();
        slateLatestMessageInfo.createdDate = slateLatestMessageInfoDto.createdDate;
        slateLatestMessageInfo.messageContent = slateLatestMessageInfoDto.messageContent;
        slateLatestMessageInfo.messageId = slateLatestMessageInfoDto.messageId;
        return slateLatestMessageInfo;
    };
    MessagemapService.prototype.mapToSlateMessageEmployeeInfo = function (SlateMessageEmployeeInfosDto) {
        var slateMessageEmployeesInfo = new Array();
        SlateMessageEmployeeInfosDto.forEach(function (dto) {
            var slateMessageEmployeeInfo = new SlateMessageEmployeeInfo();
            slateMessageEmployeeInfo.employeeId = dto.employeeId;
            slateMessageEmployeeInfo.employeeName = dto.employeeName;
            slateMessageEmployeesInfo.push(slateMessageEmployeeInfo);
        });
        return slateMessageEmployeesInfo;
    };
    MessagemapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagemapService_Factory() { return new MessagemapService(i0.ɵɵinject(i1.SessionService)); }, token: MessagemapService, providedIn: "root" });
    return MessagemapService;
}());
export { MessagemapService };
