import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/timer';
import { PopperContent } from 'ngx-popper';
import { appConfig } from '../../../app.config';
import { unsubscribe } from '../../../core/decorators/index';
import { mutableSelect } from '../../../core/decorators/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { ApplicationStateBusService, OrgLevelWatchService, TimeclockDataService } from '../../../organization/services/index';
var HeaderNavTimeElementComponent = /** @class */ (function () {
    function HeaderNavTimeElementComponent(timeclockDataService, orgLevelWatchService, stateBus) {
        this.timeclockDataService = timeclockDataService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.stateBus = stateBus;
        this.popperPosition = 'bottom-end';
        this.showTimeclocksCount = 5;
        this.popperModifiers = {
            arrow: {
                order: 500,
                enabled: true,
                element: '[x-arrow]'
            }
        };
        this.escCode = 27;
        this.isLocalTimeShown = true;
    }
    HeaderNavTimeElementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.timeclockDataService.init();
        this.appConfig = appConfig;
        this.currentTime = moment();
        this.stateBusSubscription = this.stateBus.orgLevelsLoaded$.subscribe(function (orgLevels) {
            _this.orgLevelSubscription = _this.orgLevel$.subscribe(function (orgLevel) {
                if (orgLevel && _.isNumber(orgLevel.id)) {
                    _this.orgLevel = orgLevel;
                    _this.getTimeStatus(orgLevel);
                    _this.updateTime();
                }
            });
        });
        this.timeSubscription = Observable.timer(1000, 1000).subscribe(function () {
            _this.updateTime();
        });
        this.errorSubscription = this.timeclockDataService.onError$.subscribe(function () {
            _this.loadError = true;
            _this.showExclamation = true;
        });
        this.loadedSubscription = this.timeclockDataService.onLoaded$
            .subscribe(function (timeclocksContainer) {
            _this.loadError = false;
            _this.timeclockDailySummaryContainer = timeclocksContainer;
            _this.getDisplayedRecords();
            _this.showExclamation = false;
            _.each(_this.displayedRecords, function (r) {
                if (r.communicationStatus === 0) {
                    _this.showExclamation = true;
                }
            });
        });
    };
    HeaderNavTimeElementComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.timeclockDataService.dispose();
    };
    Object.defineProperty(HeaderNavTimeElementComponent.prototype, "iconClass", {
        get: function () {
            return this.isLocalTimeShown ? 'fal fa-user ' : 'fal fa-building ';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderNavTimeElementComponent.prototype, "iconTitle", {
        get: function () {
            var msg;
            if (this.loadError) {
                msg = 'There may be a delay communicating with timeclocks. Timeclock data may not be up to date.';
            }
            else {
                msg = this.isLocalTimeShown ? 'User\'s Local Time' : 'Local Time at Facility';
            }
            return msg;
        },
        enumerable: true,
        configurable: true
    });
    HeaderNavTimeElementComponent.prototype.onKeyup = function (event) {
        if (event.keyCode === this.escCode) {
            this.popperContent.hide();
        }
    };
    HeaderNavTimeElementComponent.prototype.onCloseFromList = function () {
        if (this.popperContent)
            this.popperContent.hide();
    };
    HeaderNavTimeElementComponent.prototype.onShown = function (popper) {
        this.popupShown = true;
        //this.popperOnShow.emit(popper);
    };
    HeaderNavTimeElementComponent.prototype.onHidden = function (popper) {
        this.popupShown = false;
        //this.popperOnHide.emit(popper);
    };
    HeaderNavTimeElementComponent.prototype.getDisplayedRecords = function () {
        this.timeclockDailySummaryContainer.records = _.orderBy(this.timeclockDailySummaryContainer.records, ['isVirtual', 'communicationStatus', 'sortName']);
        if (this.timeclockDailySummaryContainer.records && this.timeclockDailySummaryContainer.records.length > 0) {
            this.totalRecordsCount = this.timeclockDailySummaryContainer.records.length;
            if (this.showTimeclocksCount > 0) {
                this.displayedRecords = this.timeclockDailySummaryContainer.records.slice(0, Math.min(this.showTimeclocksCount, this.timeclockDailySummaryContainer.records.length));
            }
        }
        else {
            this.displayedRecords = [];
            this.totalRecordsCount = 0;
        }
    };
    HeaderNavTimeElementComponent.prototype.getTimeStatus = function (orgLevel) {
        if (!orgLevel)
            return;
        this.isLocalTimeShown = !(orgLevel.type === OrgLevelType.organization || orgLevel.type === OrgLevelType.department);
        if (!this.isLocalTimeShown) {
            this.currentOffset = +_.get(orgLevel, 'location.timeZoneOffset') || 0;
        }
    };
    HeaderNavTimeElementComponent.prototype.updateTime = function () {
        if (this.orgLevel) {
            var locaTime = moment();
            if (this.isLocalTimeShown) {
                this.currentTime = locaTime;
            }
            else {
                if (this.currentOffset !== 0) {
                    locaTime = locaTime.utcOffset(this.currentOffset);
                }
                this.currentTime = locaTime;
            }
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], HeaderNavTimeElementComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderNavTimeElementComponent.prototype, "timeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderNavTimeElementComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderNavTimeElementComponent.prototype, "errorSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderNavTimeElementComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderNavTimeElementComponent.prototype, "stateBusSubscription", void 0);
    return HeaderNavTimeElementComponent;
}());
export { HeaderNavTimeElementComponent };
