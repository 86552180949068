<div class="staffing-predictor-header-content">
    <slx-daterange *ngIf="!five_star_v2_enabled"
    class="slx-width-320 indent-right"
    name="startEndDate"
    startDatePlaceholder="From"
    endDatePlaceholder="To"
    [hasApplyButton]="true"
    [startDate]="currentRange?.startDate"
    [endDate]="currentRange?.endDate"
    [maxSecRange]="7952400"
    [maxRangeErrorText]="maximumRangeError"
    (rangeDateChanged)="onRangeChanged($event)"
  ></slx-daterange>

  <div class="slx-datRange" *ngIf="five_star_v2_enabled">
    <section class="year slx-daterange__date">
      <label class="common-label">Fiscal year:</label>
      <div class="year_control">
        <slx-dropdown-list class="slx-wide"
          name="year"
          [options]="yearsList"
          [valueField]="'id'"
          [titleField]="'year'"
          [(ngModel)]="selectedYear"
          (ngModelChange)="onSelectYear($event)"
        ></slx-dropdown-list>
      </div>
    </section>

    <section class="period">
      <label class="common-label m-bottom">Period:</label>
      <div class="period__control">
        <slx-dropdown-list class="slx-wide slx-short"
          name="exportRange"
          [options]="exportRangeTypes"
          [valueField]="'id'"
          [titleField]="'text'"
          [(ngModel)]="exportRangeType"
          (ngModelChange)="onChangeExportRange($event)"
        ></slx-dropdown-list>
      </div>
    </section>
    
    <button type="button" (click)="applyFilter()" class="slx-button slx-min-w90 slx-margin-l slx-datrange__button" *ngIf="!hideOnPrint">Apply</button>
  </div>

  <div class="spacer"></div>

  <button type="button" (click)="onExport()" class="slx-button slx-min-w90 slx-margin-l slx-exprt__button" *ngIf="five_star_v2_enabled && !hideOnPrint">Generate PDF</button>

  <div *ngIf="container && !hideOnPrint" class="padd-r dropdown-container">
    <slx-multiselect
      [lookup]="organizationLookup"
      [(ngModel)]="selectedOrganizations"
      (ngModelChange)="filterOrgChanged($event)"
      titleField="organization.name"
      valueField="organization.id"
      [valuePrimitive]="false"
      placeholder="Filter"
      [externalPlaceholder]="false"
      name="organizationFilter">
    </slx-multiselect>
  </div>

</div>
