import { Router, ActivatedRoute, UrlTree, NavigationExtras, Params } from '@angular/router';

export class WfmResponseNavigationService {

  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }
  public NavigateToWfmResponse(syncId: string): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    const params: Params = {};
    params.orgLevelId = this.getOrglevelIdFromRoute();
    this.router.navigateByUrl(this.getUrlTree(syncId, params), extras);
  }
  private getUrlTree(syncId: string, params): UrlTree {
    return this.router.createUrlTree(['employee_sync', syncId, 'sync_response'], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
  }

  private getOrglevelIdFromRoute(): number {
    const id: string = this.route.snapshot.queryParamMap.get('orgLevelId');
    return +id;
  }
}