import { PayCycle } from '../../../organization';
import { OrgLevel } from './../../../state-model/models/org-level/org-level';
export interface IUnprocessedTimecardStats {
    pendingRecords: number;
    totalRecords: number;
    startDate: string;
    endDate: string;
}

export class UnprocessedTimecardStats {
    public pendingRecords: number;
    public totalRecords: number;
    public startDate: Date;
    public endDate: Date;
}

export class TimecardQueueDialogRequest {
    public orgLevel: OrgLevel;
    public payCycle: PayCycle;
    public approveTimeCards:boolean;
    public empIds:number[];
    public unprocessedTimecardStats:UnprocessedTimecardStats;

    public get isValid(): boolean {
        return !!this.orgLevel && !!this.payCycle.startDate && !!this.payCycle.endDate;
    }
}
