import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { ApplicationsDashboardMapService } from './application-dashboard-map.service';
import { ISoDashboardCounters, 
  SoDashboardCounters, 
  ITaDashboardCounters, 
  TaDashboardCounters, 
  LoaRequestCounters, 
  ILoaRequestCounters, 
  DashboardAppAlertConfig, 
  AlertConfig, 
  DashBoardAlertsModel, 
  IDashboardAppAlertConfig
} from '../../models/index';
import { portalConfig } from '../../portal.config';
import { DashboardApplication, DashboardApplications } from '../../../organization/models/index';
import { CacheType } from '../../../common/models/cache/cache-definition';

@Injectable()
export class ApplicationDashboardApiService {
  private mapService: ApplicationsDashboardMapService;
  private apiUtilsService: ApiUtilService;
  private urlParamsService: UrlParamsService;

  constructor(mapService: ApplicationsDashboardMapService, apiUtilsService: ApiUtilService, urlParamsService: UrlParamsService) {
    this.mapService = mapService;
    this.apiUtilsService = apiUtilsService;
    this.urlParamsService = urlParamsService;
  }

  public getTaCounters(orgLevelId: number): Promise<TaDashboardCounters> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = this.getTaApiRoot(orgLevelId);
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<TaDashboardCounters> = this.apiUtilsService.request<ITaDashboardCounters, Meta>(request)
      .then((response: ResponseBody<ITaDashboardCounters, Meta>) => this.mapService.mapTaCounters(response.data));

    return promise;
  }

  public getSoCounters(orgLevelId: number): Promise<SoDashboardCounters> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = this.getSoApiRoot(orgLevelId);
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<SoDashboardCounters> = this.apiUtilsService.request<ISoDashboardCounters, Meta>(request)
      .then((response: ResponseBody<ISoDashboardCounters, Meta>) => this.mapService.mapSoCounters(response.data));

    return promise;
  }

  public getLoaRequestCounters(orgLevelId: number): Promise<LoaRequestCounters> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = this.getLoaApiRoot(orgLevelId);
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<LoaRequestCounters> = this.apiUtilsService.request<ILoaRequestCounters, Meta>(request)
      .then((response: ResponseBody<ILoaRequestCounters, Meta>) => this.mapService.mapLoaRequestCounters(response.data));
    return promise;
  }

  public getDashboardUserConfig(orgLevelId: number): Promise<DashboardAppAlertConfig> {
    const url: string = this.getAlertApiRoot(orgLevelId);
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    return this.apiUtilsService.request<IDashboardAppAlertConfig, Meta>(request)
      .then((response: ResponseBody<IDashboardAppAlertConfig, Meta>) => {
        return this.mapService.mapDashboardUserConfig(response.data);
      });
  }

  public modifyAlerts(model: DashBoardAlertsModel[]): Promise<void> {
    const url = this.getPostAlertApiRoot();
    const body = model;
    const request = this.urlParamsService.createPostRequest(url,body);
    return this.apiUtilsService.request<any, Meta>(request)
    .then((res)=> { 
      return res.data;
    });
  }

  public loadApps(updateCacheForced: boolean = false): Promise<DashboardApplications> {
    const url: string = this.getAppApiRoot();
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    return this.apiUtilsService.cachedRequest<any, Meta>(request, CacheType.shortTerm, updateCacheForced)
    .then((res: ResponseBody<any, Meta>) => {
      return this.mapService.mapAppConfig(res.data);
    });
  }

  public modifyApps(model: DashboardApplication[]): Promise<void> {
    const url = this.getModifyAppApiRoot();
    const body = model;
    const request = this.urlParamsService.createPostRequest(url,body);
    return this.apiUtilsService.request<any, Meta>(request)
    .then((res)=> { 
      return res.data;
    });
  }

  private getAppApiRoot(): string {
    return `${this.getDashboardconfigApiRoot()}/${portalConfig.api.dashboardUserConfig.apps.getApps}`;
  }

  private getModifyAppApiRoot(): string {
    return `${this.getDashboardconfigApiRoot()}/${portalConfig.api.dashboardUserConfig.apps.modifyApps}`;
  }

  private getPostAlertApiRoot(): string {
    return `${this.getDashboardconfigApiRoot()}/${portalConfig.api.dashboardUserConfig.postAlert.alert}`;
  }

  private getAlertApiRoot(orgLevelId: number): string {
    return `${this.getDashboardconfigApiRoot()}/${portalConfig.api.dashboardUserConfig.getAlert.alert}/${orgLevelId}`;
  }
 
  private getDashboardconfigApiRoot(): string {
    return `${this.getApiRoot()}/${portalConfig.api.dashboardUserConfig.getAlert.root}`;
  }

  private getLoaApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${portalConfig.api.hr.loa}/${portalConfig.api.dashboard.root}/${orgLevelId}`;
  }

  private getTaApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${portalConfig.api.ta.root}/${portalConfig.api.dashboard.root}/${orgLevelId}`;
  }
  private getSoApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${portalConfig.api.so.root}/${portalConfig.api.dashboard.root}/${orgLevelId}`;
  }
  private getApiRoot(): string {
    return `${this.apiUtilsService.getApiRoot()}`;
  }
}
