import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { LookupMapService, EmployeeDefinitionsMapService } from '../../../organization/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { Employee } from '../../../organization/models/index';
import {
    TimecardSummaryRequest, ITimecardSummaryRequest, ITimecardIndividualSummaryRequest, TimecardIndividualSummaryRequest,
    TimecardSummaryContainerDTO, TimecardSummaryModel, HoursSummaryContainer, TimecardSummaryPaidHours, TimecardSummaryPaidHoursDto,
    TimecardSummaryPeriodCompareDto, TimecardSummaryPeriodCompare, PeriodsSummaryContainer,
    ExemptsSummaryContainer, TimecardSummaryExemptEmployee, TimecardSummaryExemptEmployeeDto,
    TimecardSummarySecondaryPositionDto, TimecardSummarySecondaryPosition, PositionsSummaryContainer
} from '../../models/index';

@Injectable()
export class TimecardSummaryMapService {

    constructor(private lookupMap: LookupMapService, private empMapService: EmployeeDefinitionsMapService) { }

    public mapRequestToDto(req: TimecardSummaryRequest): ITimecardSummaryRequest {
        return {
            organizationId: req.organizationId,
            departmentIds: req.departmentIds,
            employeeTypeNames: req.employeeTypeNames,
            payPolicyNames: req.payPolicyNames,
            positionIds: req.positionIds,
            shiftDifferentialPolicyNames: req.shiftDifferentialPolicyNames,
            startDate: moment(req.startDate).format(appConfig.requestDateTime),
            endDate: moment(req.endDate).format(appConfig.requestDateTime)
        };
    }

    public mapIndividualRequestToDto(req: TimecardIndividualSummaryRequest): ITimecardIndividualSummaryRequest {
        return {
            employeeId: req.employeeId,
            startDate: moment(req.startDate).format(appConfig.requestDate),
            endDate: moment(req.endDate).format(appConfig.requestDate)
        };
    }

    public mapTimecardSummary(dto: TimecardSummaryContainerDTO): TimecardSummaryModel {

        if (!dto) {
            return null;
        }

        let model: TimecardSummaryModel = new TimecardSummaryModel();
        model.corporation = this.lookupMap.mapOrganization(dto.corporation);
        model.organization = this.lookupMap.mapOrganization(dto.organization);
        model.payrollCycle = this.lookupMap.mapPayCycle(dto.payrollCycle);
        model.exportRequestedBy = dto.exportRequestedBy;
        model.releasedToPayrollBy = dto.releasedToPayrollBy;
        model.employeesCount = dto.employeesCount;
        model.manuallyModifiedTimecardCount = dto.manuallyModifiedTimecardCount;
        model.approvalRequiredTimecardCount = dto.approvalRequiredTimecardCount;
        model.pendingApprovalCount = dto.pendingApprovalCount;
        model.terminatedEmployeesCount = dto.terminatedEmployeesCount;
        model.paidHours = this.mapPaidHoursRecords(dto.paidHours);
        model.periodCompare = this.mapPeriodsRecords(dto.periodCompare);
        model.exemptEmployees = this.mapExemptEmployeesRecords(dto.exemptEmployee);
        model.secondaryPosition = this.mapSecondaryPositionRecords(dto.secondaryPosition);
        return model;
    }

    public mapSecondaryPositionRecords(dtos: TimecardSummarySecondaryPositionDto[]): PositionsSummaryContainer {
        let model: PositionsSummaryContainer = new PositionsSummaryContainer();
        let records: TimecardSummarySecondaryPosition[] = [];
        _.each(dtos, (dto: TimecardSummarySecondaryPositionDto) => {
            let record: TimecardSummarySecondaryPosition = this.mapSecondaryPositionRecord(dto);
            records.push(record);
        });
        model.records = records;
        return model;
    }

    public mapSecondaryPositionRecord(dto: TimecardSummarySecondaryPositionDto): TimecardSummarySecondaryPosition {
        let record: TimecardSummarySecondaryPosition = new TimecardSummarySecondaryPosition();
        record.employee = this.empMapService.mapToEmployeeDefinition(dto.employee);
        record.organization = this.lookupMap.mapOrganization(dto.organization);
        record.department = this.lookupMap.mapDepartment(dto.department);
        record.secondaryOrganization = this.lookupMap.mapOrganization(dto.secondaryOrganization);
        record.secondaryDepartment = this.lookupMap.mapDepartment(dto.secondaryDepartment);
        record.position = this.lookupMap.mapPosition(dto.position);
        record.secondaryPosition = this.lookupMap.mapPosition(dto.secondaryPosition);
        record.hours = dto.hours;
        record.rate = dto.rate;
        return record;
    }

    public mapExemptEmployeesRecords(dtos: TimecardSummaryExemptEmployeeDto[]): ExemptsSummaryContainer {
        let model: ExemptsSummaryContainer = new ExemptsSummaryContainer();
        let records: TimecardSummaryExemptEmployee[] = [];
        _.each(dtos, (dto: TimecardSummaryExemptEmployeeDto) => {
            let record: TimecardSummaryExemptEmployee = this.mapExtepmtEmployeeRecord(dto);
            records.push(record);
        });
        model.records = records;
        return model;
    }

    public mapExtepmtEmployeeRecord(dto: TimecardSummaryExemptEmployeeDto): TimecardSummaryExemptEmployee {
        let record: TimecardSummaryExemptEmployee = new TimecardSummaryExemptEmployee();
        record.employee = this.empMapService.mapToEmployeeDefinition(dto.employee);
        record.position = this.lookupMap.mapPosition(dto.position);
        record.currentCycleHours = dto.currentCycleHours;
        record.previousCycleHours = dto.previousCycleHours;
        record.exemptType = dto.exemptType;
        return record;
    }

    public mapPeriodsRecords(dtos: TimecardSummaryPeriodCompareDto[]): PeriodsSummaryContainer {
        let model: PeriodsSummaryContainer = new PeriodsSummaryContainer();
        let periods: TimecardSummaryPeriodCompare[] = [];
        _.each(dtos, (dto: TimecardSummaryPeriodCompareDto) => {
            let period: TimecardSummaryPeriodCompare = this.mapPeriodRecord(dto);
            periods.push(period);
        });
        model.records = periods;
        return model;
    }

    public mapPeriodRecord(dto: TimecardSummaryPeriodCompareDto): TimecardSummaryPeriodCompare {
        let period: TimecardSummaryPeriodCompare = new TimecardSummaryPeriodCompare();
        period.differenceAmount = dto.differenceAmount;
        period.differenceHours = dto.differenceHours;
        period.differencePercentage = dto.differencePercentage;
        period.nonProductiveHours = dto.nonProductiveHours;
        period.payCycleStartDate = dateTimeUtils.convertFromDtoString(dto.payCycleStartDate);
        period.payrollAmount = dto.payrollAmount;
        period.percentageHours = dto.percentageHours;
        period.productiveHours = dto.productiveHours;
        period.totalHours = dto.totalHours;
        return period;
    }

    public mapPaidHoursRecords(hourDtos: TimecardSummaryPaidHoursDto[]): HoursSummaryContainer {
        let container: HoursSummaryContainer = new HoursSummaryContainer();
        let hours: TimecardSummaryPaidHours[] = [];
        _.each(hourDtos, (hourDto: TimecardSummaryPaidHoursDto) => {
            let hour: TimecardSummaryPaidHours = this.mapPaidHours(hourDto);
            hours.push(hour);
        });
        container.records = hours;
        return container;
    }

    public mapPaidHours(dto: TimecardSummaryPaidHoursDto): TimecardSummaryPaidHours {
        let hour: TimecardSummaryPaidHours = new TimecardSummaryPaidHours();
        hour.currentCycleAmount = dto.currentCycleAmount;
        hour.currentCycleHourlyPay = dto.currentCycleHourlyPay;
        hour.currentCycleOtherPay = dto.currentCycleOtherPay;
        hour.currentCycleHours = dto.currentCycleHours;
        hour.differenceCycleAmount = dto.differenceCycleAmount;
        hour.previousCycleHourlyPay = dto.previousCycleHourlyPay;
        hour.previousCycleOtherPay = dto.previousCycleOtherPay;
        hour.differenceCycleHours = dto.differenceCycleHours;
        hour.payCode = dto.payCode;
        hour.payDescription = dto.payDescription;
        hour.previousCycleAmount = dto.previousCycleAmount;
        hour.differenceCycleHourlyPay = dto.differenceCycleHourlyPay;
        hour.differenceCycleOtherPay = dto.differenceCycleOtherPay;
        hour.previousCycleHours = dto.previousCycleHours;
        return hour;
    }

}
