<slx-spinner [show]="isLoading">
    <div class="content-grid">
        <kendo-grid #gridReportSettings class="slx-blue-grid slx-grid-slim-rows slx-full-height"
            [data]="gridState.view">
            <kendo-grid-column field='position' title='Position'>
                <ng-template kendoGridHeaderTemplate>
                    <span slxKendoGridHeaderTooltip>Position</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.position }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field='positionCategoryType' title="Attendance Report">
                <ng-template kendoGridHeaderTemplate>
                    <span slxKendoGridHeaderTooltip>Daily Attendance Report</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <slx-dropdown-list class="slx-wide" name="positionCategory{{rowIndex}}"
                        [options]="dailyAttendanceOptions"
                        [ngModel]="getPositionCategory(dataItem.positionCategoryType)" [valueField]="'id'"
                        [titleField]="'name'" [valuePrimitive]="false"
                        (ngModelChange)="setPositionCategory(dataItem,$event)">
                    </slx-dropdown-list>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</slx-spinner>