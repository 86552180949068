/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./left-sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../user-info/user-info.component.ngfactory";
import * as i4 from "../user-info/user-info.component";
import * as i5 from "../../../organization/components/org-level-breadcrumb/org-level-breadcrumb.component.ngfactory";
import * as i6 from "../../../organization/components/org-level-breadcrumb/org-level-breadcrumb.component";
import * as i7 from "../../../organization/services/org-level/org-level-watch.service";
import * as i8 from "../../../organization/services/application-state-bus/application-state-bus.service";
import * as i9 from "../../../organization/components/navigation-menu/navigation-menu.component.ngfactory";
import * as i10 from "../../../organization/services/navigation/navigation-menu-management.service";
import * as i11 from "../../../organization/services/navigation/navigation-menu-api.service";
import * as i12 from "../../../organization/components/navigation-menu/navigation-menu.component";
import * as i13 from "../../../organization/services/navigation/message-center-config.service";
import * as i14 from "../../../organization/services/navigation/ben-admin/ben-admin-menu-management.service";
import * as i15 from "../../../organization/services/navigation/navigation-menu-redirect.service";
import * as i16 from "../../../app-settings/services/app-settings-manage.service";
import * as i17 from "../../../organization/components/bottom-menu/bottom-menu.component.ngfactory";
import * as i18 from "../../../organization/components/bottom-menu/bottom-menu.component";
import * as i19 from "../../../authentication/services/authentication/authentication.service";
import * as i20 from "../../../common/services/change-management/change-management.service";
import * as i21 from "./left-sidebar.component";
import * as i22 from "../../../core/services/user-menu-pin/user-menu-pin.service";
import * as i23 from "../../services/application-state-management/application-state-management.service";
var styles_LeftSidebarComponent = [i0.styles];
var RenderType_LeftSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeftSidebarComponent, data: {} });
export { RenderType_LeftSidebarComponent as RenderType_LeftSidebarComponent };
function View_LeftSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unPinMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "pin fas fa-thumbtack"]], null, null, null, null, null))], null, null); }
function View_LeftSidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.pinMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "slx-rotate-90 fas fa-thumbtack"]], null, null, null, null, null))], null, null); }
export function View_LeftSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "left-sidebar"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "show": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "left-sidebar-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "left-sidebar-logo-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "pin-placement"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LeftSidebarComponent_2)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "user-info-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "slx-user-info", [], null, null, null, i3.View_UserInfoComponent_0, i3.RenderType_UserInfoComponent)), i1.ɵdid(13, 245760, null, 0, i4.UserInfoComponent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "slx-navigation-menu-ignore orglevel-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "slx-org-level-breadcrumb", [], null, [["document", "keyup"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:keyup" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).pressEscape($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).clickOutside($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_OrgLevelBreadcrumbComponent_0, i5.RenderType_OrgLevelBreadcrumbComponent)), i1.ɵdid(16, 245760, null, 0, i6.OrgLevelBreadcrumbComponent, [i7.OrgLevelWatchService, i8.ApplicationStateBusService, i1.ElementRef], { mode: [0, "mode"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "div", [["class", "left-sidebar-container slx-navigation-menu-ignore"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "slx-navigation-menu", [], null, [["window", "resize"], ["document", "keyup"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).getScreenSize($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keyup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).pressEscape($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 20).clickOutside($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i9.View_NavigationMenuComponent_0, i9.RenderType_NavigationMenuComponent)), i1.ɵprd(512, null, i10.NavigationMenuManagementService, i10.NavigationMenuManagementService, [i8.ApplicationStateBusService, i11.NavigationMenuApiService]), i1.ɵdid(20, 638976, null, 0, i12.NavigationMenuComponent, [i10.NavigationMenuManagementService, i13.MessageCenterConfigService, i8.ApplicationStateBusService, i14.BenAdminMenuManagementService, i15.NavigationMenuRedirectService, i1.ElementRef, i1.Renderer2, i16.AppSettingsManageService], { isOpened: [0, "isOpened"] }, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "slx-bottom-menu", [], null, null, null, i17.View_BottomMenuComponent_0, i17.RenderType_BottomMenuComponent)), i1.ɵdid(22, 114688, null, 0, i18.BottomMenuComponent, [i19.AuthenticationService, i20.ChangeManagementService, i8.ApplicationStateBusService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "left-sidebar"; var currVal_1 = _ck(_v, 3, 0, _co.isOpened); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.appStateManagementService.isMenuPinned; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.appStateManagementService.isMenuPinned; _ck(_v, 10, 0, currVal_3); _ck(_v, 13, 0); var currVal_4 = _co.orgLevelBreadcrumbsMenuMode; _ck(_v, 16, 0, currVal_4); var currVal_5 = _co.isOpened; _ck(_v, 20, 0, currVal_5); _ck(_v, 22, 0); }, null); }
export function View_LeftSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-left-sidebar", [], null, null, null, View_LeftSidebarComponent_0, RenderType_LeftSidebarComponent)), i1.ɵdid(1, 245760, null, 0, i21.LeftSidebarComponent, [i22.UserMenuPinService, i23.ApplicationStateManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeftSidebarComponentNgFactory = i1.ɵccf("slx-left-sidebar", i21.LeftSidebarComponent, View_LeftSidebarComponent_Host_0, {}, {}, []);
export { LeftSidebarComponentNgFactory as LeftSidebarComponentNgFactory };
