import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ExportDataConfigurationInfo, IExportDataConfigurationInfo, IExportDataExecutionItem, ExportDataExecutionItem, IExportDataExecutionStatus,
         ExportDataExecutionStatus, IExportDataConfigurationParameter, ExportDataConfigurationParameter, ExportDataConfigurationParameterType, IExportDataConfigurationParameterType } from '../models/index';
import { ExportDataStatus } from '../enums/export-data-status';
import { IExportDataNotification } from '../models/export-data-notification';
import { ExportDataRequest } from '../models/export-data-request';
import { dateTimeUtils } from '../../../common/utils';

@Injectable()
export class ExportDataMapService {
  constructor() {}

  public mapToExportDataConfigurationList(dtos: IExportDataConfigurationInfo[]): ExportDataConfigurationInfo[] {
    return _.map(dtos, (dto: IExportDataConfigurationInfo) => this.mapToExportDataConfiguration(dto));
  }

  public mapToExportDataConfiguration(dto: IExportDataConfigurationInfo): ExportDataConfigurationInfo {
    const item = new ExportDataConfigurationInfo();
    item.id = dto.id;
    item.name = dto.name;
    item.parameters = this.mapToExportDataConfigurationParameterList(dto.parameters);
    item.lastExecuted = dto.lastExecuted ? this.mapToExportDataHistory(dto.lastExecuted) : null;
    return item;
  }

  public mapToExportDataHistoryList(dtos: IExportDataExecutionItem[]): ExportDataExecutionItem[] {
    return _.map(dtos, (dto: IExportDataExecutionItem) => this.mapToExportDataHistory(dto));
  }

  public mapToExportDataHistory(dto: IExportDataExecutionItem): ExportDataExecutionItem {
    const item = new ExportDataExecutionItem();
    item.id = dto.executionId;
    item.configurationId = dto.configurationId;
    item.executedBy = dto.executedBy;
    item.startedOn = dto.startedOn ? moment(dto.startedOn).toDate() : null;
    item.completedOn = dto.completedOn ? moment(dto.completedOn).toDate() : null;
    item.status = dto.exportStatus;
    item.reason = dto.failReason;
    return item;
  }

  public mapToExportDataConfigurationParameterList(dtos: IExportDataConfigurationParameter[]): ExportDataConfigurationParameter[] {
    return _.map(dtos, (dto: IExportDataConfigurationParameter) => this.mapToExportDataConfigurationParameter(dto));
  }

  public mapToExportDataConfigurationParameter(dto: IExportDataConfigurationParameter): ExportDataConfigurationParameter {
    const item = new ExportDataConfigurationParameter();
    item.name = dto.name;
    item.displayName = dto.displayName;
    item.dataType = this.mapToExportDataConfigurationParameterType(dto.dataType);
    item.sourceType = dto.sourceType;
    item.lookupType = dto.lookupType;
    item.source = dto.source;
    return item;
  }

  public mapToExportDataConfigurationParameterType(dto: IExportDataConfigurationParameterType): ExportDataConfigurationParameterType {
    const item = new ExportDataConfigurationParameterType();
    item.type = dto.type;
    item.required = true;
    item.min = dto.min;
    item.max = dto.max;
    item.minlength = dto.minlength;
    item.maxlength = dto.maxlength;
    return item;
  }

  public mapToExportDataStatusList(dtos: IExportDataExecutionStatus[]): ExportDataExecutionStatus[] {
    return _.map(dtos, (dto: IExportDataExecutionStatus) => this.mapToExportDataStatus(dto));
  }

  public mapToExportDataStatus(dto: IExportDataExecutionStatus): ExportDataExecutionStatus {
    const item = new ExportDataExecutionStatus();
    item.configurationId = dto.configurationId;
    item.executionId = dto.executionId;
    item.status = dto.executionStatus as ExportDataStatus;
    item.changedOn = dto.changedOn ? moment(dto.changedOn).toDate() : null;
    item.reason = dto.reason;

    return item;
  }

  public mapNotificationToExportDataStatus(dto: IExportDataNotification): ExportDataExecutionStatus {
    const item: ExportDataExecutionStatus = { configurationId: dto.ConfigurationId,
                                              executionId: dto.Key,
                                              status: dto.Status,
                                              changedOn: dto.ChangedOn ? moment(dto.ChangedOn).toDate() : null,
                                              reason: dto.FailedReason };
    return item;
  }

  public mapToExportDataRequest(orgLevelId: number, configurationId: number, params: ExportDataConfigurationParameter[]): ExportDataRequest {
    const req = { orgLevelId: orgLevelId, configurationId: configurationId, parameters: _.map(params, x => {
      let paramValue = x.value;
      if (x.dataType.type === 'date') {
        paramValue = dateTimeUtils.convertToDtoString(x.value);
      }
      return { name: x.name, value: paramValue };
    })};
    return req;
  }
}
