export interface IUserProfileDefinition {
  id: number;
  name: string;
  login: string;
  email: string;
}

export class UserProfileDefinition {
  public id: number;
  public name: string;
  public login: string;
  public email: string;
}
