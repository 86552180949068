import { Injectable } from '@angular/core';
import { EmployeeSectionsBenefitsManagementApiService } from './employee-sections-benefits-management-api.service';
import { Subject } from 'rxjs/Subject';
import { unsubscribeAll } from '../../../../core/decorators';
import { Subscription, ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import { Assert } from '../../../../framework';
import { EmployeeSectionsBenefitsManagement, EmployeeSubsectionEnrollment, EnrollmentHistoryRecord, EmployeeInfo, EnrollementAttachmentFile, EmployeeSectionsEnrollmentAttachments } from '../../models';
import { FileService } from '../../../../common/services/index';
import { ReadFile, AttachmentFile, UiAttachmentFile } from '../../../../organization/models/index';
import { EmployeeSectionsBenefitsCommonService } from './employee-sections-benefits-common.service';

@Injectable()
export class BenefitsEnrollmentSectionManagementService {  
  private isDedDateChangeOccured$ = new Subject<boolean>();
  private loading$ = new Subject<boolean>();
  private effectiveDate$ = new Subject<Date>();
  private dataLoaded$ = new ReplaySubject<EmployeeSubsectionEnrollment>();
  private enrollmentsLoaded$ = new ReplaySubject<EnrollmentHistoryRecord[]>();
  private enrollmentsHistoryLoaded$ = new ReplaySubject<EnrollmentHistoryRecord[]>();
  private attachmentsLoaded$ = new ReplaySubject<EnrollementAttachmentFile[]>();
  private enrollmentEmployeeInfo$ = new ReplaySubject<EmployeeInfo>();

  private enrollmentUpdated$ = new ReplaySubject<EnrollmentHistoryRecord>();
  private enrollmentDeleted$ = new ReplaySubject<EnrollmentHistoryRecord>();

  private enrollments: EnrollmentHistoryRecord[] = [];
  private enrollmentsHistory: EnrollmentHistoryRecord[] = [];

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private commonManService: EmployeeSectionsBenefitsCommonService,
    private api: EmployeeSectionsBenefitsManagementApiService,
    private fileService: FileService
  ) { }

  public destroy(): void { }

  public subscribeToChangePayrollDeductionDate(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.isDedDateChangeOccured$.subscribe(callback);
  }

 public changePayrollDeductionDate(hasDedDateError: boolean = false) {
    this.isDedDateChangeOccured$.next(hasDedDateError);
  }

  public async getBenefitEnrollmentData(employeeId: number, effectiveDate: Date): Promise<void> {
    this.loading$.next(true);
    this.effectiveDate$.next(effectiveDate);

    let sectionData = await this.api.getBenefitsManagementSection(employeeId, effectiveDate);
    sectionData = sectionData || new EmployeeSectionsBenefitsManagement();

    const subSection = sectionData.enrollmentSubsection || new EmployeeSubsectionEnrollment();

    this.setSectionInfo(subSection);

    this.loading$.next(false);
  }

  public async getEmployeeEnrollments(employeeId: number, effectiveDate: Date, toggleDependentsSpinner = false): Promise<void> {
    this.updateSpinner(true);
    if (toggleDependentsSpinner) this.commonManService.toggleDependentsSpinner(true);
    try {
      let subSection = await this.api.getEmployeeEnrollments(employeeId, effectiveDate);
      subSection = subSection || new EmployeeSubsectionEnrollment();
      this.setSectionInfo(subSection);
      if (toggleDependentsSpinner) this.commonManService.updateDependents();
    } finally {
      this.updateSpinner(false);
      this.updateEffectiveDate(effectiveDate);
      if (toggleDependentsSpinner) this.commonManService.toggleDependentsSpinner(false);
    }

  }

  public async getAttachmentsData(employeeId: number): Promise<void> {
    this.updateSpinner(true);
    try {
      const attachmentSections = await this.api.getBenefitsEnrollmentAttachment(employeeId);
      this.setAttachmentSection(attachmentSections);
    } catch (e) {
      console.error(e);
    }
    finally {
      this.updateSpinner(false);
    }
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public subscribeToEffectiveDate(callback: (v: Date) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.effectiveDate$.subscribe(callback);
  }

  public subscribeToDataLoad(callback: (v: EmployeeSubsectionEnrollment) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.dataLoaded$.subscribe(callback);
  }

  public subscribeToEnrollmentsLoad(callback: (v: EnrollmentHistoryRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.enrollmentsLoaded$.subscribe(callback);
  }

  public subscribeToEnrollmentDeleted(callback: (v: EnrollmentHistoryRecord) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.enrollmentDeleted$.subscribe(callback);
  }

  public subscribeToEnrollmentsHistoryLoad(callback: (v: EnrollmentHistoryRecord[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.enrollmentsHistoryLoaded$.subscribe(callback);
  }

  public subscribeToEnrollmentUpdated(callback: (v: EnrollmentHistoryRecord) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.enrollmentUpdated$.subscribe(callback);
  }

  public subscribeToEmployeeInfo(callback: (v: EmployeeInfo) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.enrollmentEmployeeInfo$.subscribe(callback);
  }

  public subscribeToBenefitsAttachmentsLoad(callback: (v: EnrollementAttachmentFile[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.attachmentsLoaded$.subscribe(callback);
  }

  public setSectionInfo(subSection: EmployeeSubsectionEnrollment) {
    this.enrollmentsLoaded$.next(subSection.enrollment);
    this.enrollmentsHistoryLoaded$.next(subSection.enrollmentHistory);
    this.enrollmentEmployeeInfo$.next(subSection.employeeInfo);
    this.dataLoaded$.next(subSection);

    this.enrollments = subSection.enrollment;
    this.enrollmentsHistory = subSection.enrollmentHistory;
  }

  public setAttachmentSection(attachmentSection: EmployeeSectionsEnrollmentAttachments) {
    this.attachmentsLoaded$.next(attachmentSection.benefitsManagementAttachmentsDTOs);
  }

  public updateSpinner(isLoading: boolean): void {
    this.loading$.next(isLoading);
  }

  public updateEffectiveDate(effectiveDate: Date): void {
    this.effectiveDate$.next(effectiveDate);
  }

  public async planSelected(employeeId: number, selectedRecord: EnrollmentHistoryRecord): Promise<void> {
    this.updateSpinner(true);
    try {
      if (selectedRecord) {
        await this.api.saveNotes(employeeId, selectedRecord);
        const id = selectedRecord.empToBenefitsId;
        const notes = selectedRecord.notes;
        _.each(this.enrollments, e => {
          if (e.empToBenefitsId === id) e.notes = notes;
        });
        _.each(this.enrollmentsHistory, e => {
          if (e.empToBenefitsId === id) e.notes = notes;
        });
      }
    } catch (e) {
      console.error(e);
    }
    finally {
      this.updateSpinner(false);
    }
  }

  public async readAddedFiles(files: File[]): Promise<ReadFile[]> {
    const promises = _.map(files, (f: File) => this.readFileData(f));
    let readedFiles: ReadFile[] = [];
    try {
      const binaryData = await Promise.all(promises);
      readedFiles = this.mapDateToReadFiles(files, binaryData);
    } catch (err) {
      console.error(err);
    } finally {
      return readedFiles;
    }
  }

  private mapDateToReadFiles(files: File[], binaryData: ArrayBuffer[]): ReadFile[] {
    return _.map(files, (file: File, i: number) => {
      const index = file.name.lastIndexOf('.');
      const name = file.name.slice(0, index);
      const ext = file.name.slice(index + 1);
      const array = binaryData[i];
      const data = new Blob([array]);
      return new ReadFile(name, file.size, file.type, ext, data);
    });
  }

  private readFileData(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve: (v: ArrayBuffer) => void, reject: (r: any) => void) => {
      const fr: FileReader = new FileReader();
      fr.onloadend = (): void => {
        const buffer = fr.result as ArrayBuffer;
        resolve(buffer);
      };
      fr.onerror = (): void => {
        reject(fr.error);
      };
      fr.readAsArrayBuffer(file);
    });
  }

  public async addFilesToSave(data, employeeId: number): Promise<void> {
    this.updateSpinner(true);
    try {
      await this.api.addDocument(data.files, employeeId, data.name);
    } catch (e) {
      console.error(e);
    }
    finally {
      this.updateSpinner(false);
    }
  }

  public async deleteAttachment(documentId: number): Promise<void> {
    this.updateSpinner(true);
    try {
      await this.api.deleteAttachment(documentId);
    } catch (e) {
      console.error(e);
    }
    finally {
      this.updateSpinner(false);
    }
  }

  public async downloadAttachment(attachmentId: number): Promise<void> {
    this.updateSpinner(true);
    try {
      const file = await this.api.downloadAttachment(attachmentId);
      this.fileService.saveToFileSystem(file.blob, file.file);
    } catch (e) {
      console.error(e);
    } finally {
      this.updateSpinner(false);
    }
  }

  public async deleteEnrollment(enrollment: EnrollmentHistoryRecord): Promise<void> {
    this.updateSpinner(true);
    this.commonManService.toggleDependentsSpinner(true);
    try {
      await this.api.deleteEnrollment(enrollment.empToBenefitsId);
      this.commonManService.updateDependents();
      this.enrollmentDeleted$.next(enrollment);
    } catch (e) {
      // separate call needed to prevent hiding loader of next operation
      this.updateSpinner(false);
    } finally {
      this.commonManService.toggleDependentsSpinner(false);
    }
  }

  public async updateEnrollment(enrollment: EnrollmentHistoryRecord): Promise<void> {
    this.updateSpinner(true);
    try {
      const enroll = await this.api.updateEnrollment(enrollment);
      this.enrollmentUpdated$.next(enroll);
    } finally {
      this.updateSpinner(false);
    }
  }

}
