import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { process } from '@progress/kendo-data-query';
import { DialogOptions2, KendoGridStateHelper, ModalService } from '../../../../../common';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { MatchEmp } from '../../../models/aca-export/aca-export-replace-1095c';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { ChangeDetectorRef } from '@angular/core';
var AcaCorrectedSelectEmployeeComponent = /** @class */ (function () {
    function AcaCorrectedSelectEmployeeComponent(modalService, options, manService, changeDetector) {
        this.gridData = [''];
        this.dialogResult = false;
        this.isButtonEnable = false;
        this.isLoading = true;
        this.sort = [];
        this.isSsnVisible = false;
        this.modalService = modalService;
        this.options = options;
        this.modalService = modalService;
        this.changeDetector = changeDetector;
        this.manService = manService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
    }
    Object.defineProperty(AcaCorrectedSelectEmployeeComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaCorrectedSelectEmployeeComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    AcaCorrectedSelectEmployeeComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
        this.isLoading = false;
    };
    AcaCorrectedSelectEmployeeComponent.prototype.filterChange = function (filter) {
        this.gridState.state.filter = filter;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    AcaCorrectedSelectEmployeeComponent.prototype.getAcaReplace1095C = function (exectionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoading = true;
                        _a = this;
                        return [4 /*yield*/, this.manService.fetchReplace1095CRecords(exectionId)];
                    case 1:
                        _a.records = _b.sent();
                        this.refreshGrid();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    AcaCorrectedSelectEmployeeComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    AcaCorrectedSelectEmployeeComponent.prototype.onBack = function () {
        this.onCancel();
        var acaExportDialogOptions = new AcaExportDialogOptions();
        acaExportDialogOptions.dialogType = 2;
        acaExportDialogOptions.isBack = true;
        acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
        acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
        acaExportDialogOptions.acaExportCorrected1095CContainer = this.corrected109fCRecords;
        acaExportDialogOptions.matchEmployeeList = this.matchEmployeeList;
        this.manService.openAcaExportDialog(acaExportDialogOptions);
    };
    AcaCorrectedSelectEmployeeComponent.prototype.linkRecord = function () {
        this.onCancel();
        var acaExportDialogOptions = new AcaExportDialogOptions();
        acaExportDialogOptions.dialogType = 2;
        acaExportDialogOptions.isBack = false;
        acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
        acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
        acaExportDialogOptions.acaExportCorrected1095CContainer = this.corrected109fCRecords;
        if (this.matchEmployeeList == null) {
            this.matchEmployeeList = [];
        }
        this.matchEmployeeList.push(this.matchEmp);
        acaExportDialogOptions.matchEmployeeList = this.matchEmployeeList;
        this.manService.openAcaExportDialog(acaExportDialogOptions);
    };
    AcaCorrectedSelectEmployeeComponent.prototype.onConfigChanged = function (empRecord, event) {
        this.matchEmp = new MatchEmp();
        this.matchEmp.soureEmpID = this.acaReplace1095C.employeeIdMasterId;
        this.matchEmp.tragetEmpID = empRecord.employeeIdMasterId;
        if (event == true) {
            this.isButtonEnable = true;
        }
    };
    AcaCorrectedSelectEmployeeComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    AcaCorrectedSelectEmployeeComponent.prototype.toggleSsnVisibility = function () {
        this.isSsnVisible = !this.isSsnVisible;
    };
    AcaCorrectedSelectEmployeeComponent.prototype.getSSN = function (ssn) {
        try {
            if (this.isSsnVisible) {
                return ssn;
            }
            else {
                return "***-**-" + _.takeRight(ssn, 4).join('');
            }
        }
        catch (e) { }
        return 'Invalid SSN';
    };
    return AcaCorrectedSelectEmployeeComponent;
}());
export { AcaCorrectedSelectEmployeeComponent };
