import * as tslib_1 from "tslib";
import { OnDestroy, OnChanges, SimpleChanges, OnInit, AfterViewInit, EventEmitter, ChangeDetectorRef, Renderer2 } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { GridComponent, DataStateChangeEvent, GridDataResult, DetailExpandEvent } from '@progress/kendo-angular-grid';
import { ControlStateKey } from '../../../../core/models/index';
import { ScrollWatchEvent } from '../../../models/index';
import { ComponentStateStorageService, StateManagementService, FilterStateManagementService } from '../../../services/index';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
var KendoGridStateDirective = /** @class */ (function () {
    function KendoGridStateDirective(grid, changeDetector, storageService, stateManagement, filterState, renderer) {
        this.grid = grid;
        this.changeDetector = changeDetector;
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.renderer = renderer;
        this.slxKendoGridStatePageSize = 50;
        this.filterState = filterState;
        this.onScroll$ = new Subject();
        this.stateRestored = new EventEmitter();
        this.expandedRows = {};
    }
    KendoGridStateDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.stateManagement || !this.stateManagement.isInitialized) {
            return;
        }
        this.stateManagement.registerGrid(this.gridId);
        this.contextChangeSubscription = this.stateManagement.loadedData$.subscribe(function (key) {
            _this.switchState(key);
        });
        this.scrollSubscription = this.onScroll$
            .debounceTime(500)
            .subscribe(function (event) {
            _this.saveScrollState(event.scrollTop, event.scrollLeft);
        });
        this.filterStateChangeSubscription = this.filterState.filterStateSaved$.subscribe(function (event) {
            if (_this.gridId !== event.gridId) {
                return;
            }
            _this.state = event.state;
        });
    };
    KendoGridStateDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dataStaeChangeSubscription = this.grid.dataStateChange.asObservable().subscribe(function (value) {
            _this.saveState(value);
        });
        this.expandChangeSubscription = this.grid.detailExpand.asObservable().subscribe(function (event) {
            var key = _this.getRowKey(event.index, event.dataItem);
            if (key) {
                _this.expandedRows[key] = event.index;
            }
            _this.saveExpandedRowsState();
        });
        this.grid.detailCollapse.asObservable().subscribe(function (event) {
            var key = _this.getRowKey(event.index, event.dataItem);
            if (key) {
                _this.expandedRows[key] = undefined;
            }
            _this.saveExpandedRowsState();
        });
        var el = this.grid.wrapper.nativeElement;
        this.scrollElement = el.querySelector('.k-grid-content.k-virtual-content');
        if (this.scrollElement) {
            this.scrollistenerFn = this.renderer.listen(this.scrollElement, 'scroll', function (event) { return _this.scrollHandler(event); });
        }
    };
    KendoGridStateDirective.prototype.ngOnChanges = function (changes) {
        if (changes['gridId'] || changes['filtersManagement']) {
            if (!this.gridId) {
                return;
            }
            this.filterState.enabled = this.filtersManagement;
            this.filterState.setGridId(this.gridId);
        }
    };
    // Must be, see #issueWithAOTCompiler
    KendoGridStateDirective.prototype.ngOnDestroy = function () {
        if (this.stateManagement) {
            this.stateManagement.unregisterGrid(this.gridId);
        }
        if (this.scrollElement && this.scrollistenerFn) {
            this.scrollistenerFn();
        }
    };
    KendoGridStateDirective.prototype.setScrollPosition = function (top, left) {
        if (!this.scrollElement) {
            return;
        }
        this.scrollElement.scrollTop = top ? top : 0;
        this.scrollElement.scrollLeft = left ? left : 0;
    };
    KendoGridStateDirective.prototype.setState = function (state) {
        var ev = {
            skip: state.skip ? state.skip : 0,
            take: state.take ? state.take : this.slxKendoGridStatePageSize,
            sort: _.map(state.sort, function (st) {
                return { field: st.field, dir: st.dir };
            }),
            filter: this.filtersManagement ? null : this.grid.filter
        };
        if (this.grid && !this.grid.pageable) {
            ev.take = Infinity;
        }
        this.stateRestored.emit(ev);
    };
    KendoGridStateDirective.prototype.setExpandedRows = function (ids) {
        var _this = this;
        this.expandedRows = {};
        _.forEach(ids, function (id) {
            var index = null;
            if (!_this.keyId) {
                index = _.toInteger(id);
            }
            else {
                index = _this.findDataItemIndex(_this.keyId, id);
            }
            if (_.isNumber(index)) {
                _this.expandedRows[id] = index;
                _this.grid.expandRow(index);
                var dataItem = _this.getDataItem(index);
                if (dataItem) {
                    _this.grid.detailExpand.emit({ index: index, dataItem: _this.getDataItem(index) });
                }
            }
        });
    };
    KendoGridStateDirective.prototype.scrollHandler = function (event) {
        var ev = new ScrollWatchEvent(event);
        ev.scrollTop = this.scrollElement.scrollTop;
        ev.scrollLeft = this.scrollElement.scrollLeft;
        ev.scrollEvent = event;
        ev.clientHeight = this.scrollElement.clientHeight;
        ev.clientWidth = this.scrollElement.clientWidth;
        this.onScroll$.next(ev);
    };
    KendoGridStateDirective.prototype.loadState = function () {
        var _this = this;
        this.state = this.storageService.getGridState(this.stateManagement.componentKey, this.gridId, this.stateKey);
        this.setState(this.state);
        //let chance to render grid
        setTimeout(function () {
            _this.setExpandedRows(_this.state.expandedDetailsIds);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        }, 150);
        setTimeout(function () {
            _this.setScrollPosition(_this.state.scrollTop, _this.state.scrollLeft);
        }, 250);
    };
    KendoGridStateDirective.prototype.saveScrollState = function (top, left) {
        if (!this.stateManagement || !this.state)
            return;
        this.state.scrollTop = top;
        this.state.scrollLeft = left;
        this.storageService.setGridState(this.stateManagement.componentKey, this.gridId, this.state, this.stateKey);
    };
    KendoGridStateDirective.prototype.saveState = function (ev) {
        if (!this.stateManagement || !this.state) {
            return;
        }
        this.state.skip = ev.skip;
        this.state.take = ev.take;
        this.state.sort = _.map(ev.sort, function (st) {
            return { field: st.field, dir: st.dir };
        });
        this.storageService.setGridState(this.stateManagement.componentKey, this.gridId, this.state, this.stateKey);
    };
    KendoGridStateDirective.prototype.saveSortState = function (skip, take) {
        if (!this.stateManagement)
            return;
        this.state.skip = skip;
        this.state.take = take;
        this.storageService.setGridState(this.stateManagement.componentKey, this.gridId, this.state, this.stateKey);
    };
    KendoGridStateDirective.prototype.saveExpandedRowsState = function () {
        var _this = this;
        if (!this.stateManagement || !this.state)
            return;
        this.state.expandedDetailsIds = [];
        _.forIn(this.expandedRows, function (value, key) {
            if (value !== undefined) {
                _this.state.expandedDetailsIds.push(key);
            }
        });
        this.storageService.setGridState(this.stateManagement.componentKey, this.gridId, this.state, this.stateKey);
    };
    KendoGridStateDirective.prototype.switchState = function (context) {
        this.stateKey = new ControlStateKey(context);
        this.loadState();
    };
    KendoGridStateDirective.prototype.rowCounts = function () {
        if (this.grid.data.data) {
            return this.grid.data.data.length;
        }
        else {
            return this.grid.data.length;
        }
    };
    KendoGridStateDirective.prototype.findDataItemIndex = function (keyField, key) {
        var dataItems;
        if (!this.grid.data) {
            return null;
        }
        if (this.grid.data.data) {
            dataItems = this.grid.data.data;
        }
        else {
            dataItems = this.grid.data;
        }
        var index = _.findIndex(dataItems, function (item) {
            var id = _.get(item, keyField);
            if (!id) {
                return false;
            }
            return id.toString() === key;
        });
        return index;
    };
    KendoGridStateDirective.prototype.getDataItem = function (rowIndex) {
        var dataItem;
        if (this.grid.data.data) {
            dataItem = this.grid.data.data[rowIndex];
        }
        else {
            dataItem = this.grid.data[rowIndex];
        }
        return dataItem;
    };
    KendoGridStateDirective.prototype.getRowKey = function (index, dataItem) {
        if (this.keyId) {
            if (!dataItem) {
                return null;
            }
            return _.get(dataItem, this.keyId);
        }
        return index.toString();
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", FilterStateManagementService)
    ], KendoGridStateDirective.prototype, "filterState", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridStateDirective.prototype, "scrollSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridStateDirective.prototype, "contextChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridStateDirective.prototype, "expandChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridStateDirective.prototype, "dataStaeChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridStateDirective.prototype, "filterStateChangeSubscription", void 0);
    return KendoGridStateDirective;
}());
export { KendoGridStateDirective };
