import { IPosition, Position, IPayUnitDefinition, PayUnitDefinition  } from '../../../../organization/models/index';

export interface IEmpPayUnit {
  payUnit: IPayUnitDefinition;
  employeeId: number;
  position: IPosition;
  units: number;
  start: string;
  end: string;
  positionIdInitial: number;
}

export class EmpPayUnit {
  public payUnit: PayUnitDefinition;
  public employeeId: number;
  public position: Position;
  public units: number;
  public start: Date;
  public end: Date;

  public initialPositionId: number;
  public initialPayUnitId: number;
  public initialUnits: number;
}
