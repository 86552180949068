import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common';
import { Aca1095cManagementService } from '../../services/aca-1095-c/aca-1095-c-management.service';
var Aca1095cComponent = /** @class */ (function () {
    function Aca1095cComponent(acaManagementService, stateManagementService) {
        this.acaManagementService = acaManagementService;
        this.stateManagementService = stateManagementService;
        this.isLoading = false;
        this.componentId = 'Aca1095cComponent';
        this.subscriptions = {};
    }
    Aca1095cComponent.prototype.ngOnDestroy = function () {
        this.acaManagementService.destroy();
    };
    Aca1095cComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagementService.init(this.componentId, true);
        this.acaManagementService.init();
        this.subscriptions.loading = this.acaManagementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], Aca1095cComponent.prototype, "subscriptions", void 0);
    return Aca1095cComponent;
}());
export { Aca1095cComponent };
