import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { BenefitsEnrollmentSectionManagementService, EmployeeSectionsBenefitsManagementApiService, EmployeeSectionBridgeService } from '../../../../services';
import { EnrollmentHistoryRecord, EmployeeSubsectionEnrollment, BenefitEmpEnrollmentFormula } from '../../../../models';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { KendoGridStateHelper, removeEvent, saveEvent } from '../../../../../../common/models/kendo-grid-helpers';
import { GridDataResult, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { CalculationType } from '../../../../models/employee-sections-benefits-management/calculation-type.enum';
import { FormulaTypeEnum } from '../../../../models/employee-sections-benefits-management/formula-type.enum';
import { DropCoveragePopupComponent } from '../drop-coverage-popup/drop-coverage-popup.component';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common';
import { BenefitEnrolledEmployee } from '../../../../../../app-modules/benefits/models';
import { EnrollEmployeeSelectPlanPopupComponent } from '../enroll-employee-select-plan-popup/enroll-employee-select-plan-popup.component';
import { LookupService } from '../../../../../../organization/services';
import { NewEnrollmentModel } from '../../../../models/employee-sections-benefits-management/new-enrollment.model';
import { EnrollEmployeeSelectCoveragePopupComponent } from '../enroll-employee-select-coverage-popup/enroll-employee-select-coverage-popup.component';
import { EnrollEmployeeFlatComponent } from '../enroll-employee-flat/enroll-employee-flat.component';
import { EnrollEmployeeFormulaComponent } from '../enroll-employee-formula/enroll-employee-formula.component';
import { EnrollEmployeeFormulaWithOptionComponent } from '../enroll-employee-formula-with-option/enroll-employee-formula-with-option.component';
import { EnrollEmployee401kComponent } from '../enroll-employee-401k/enroll-employee-401k.component';
import { BeneficiaryEditorPopupComponent } from '../beneficiary-editor-popup/beneficiary-editor-popup.component';
import { unsubscribeAll } from '../../../../../../core/decorators';
import { CalculationCommonService } from '../../../../../../app-modules/benefits/services/benefit-details/calculation-common.service';
import { BenefitEmployeesApiService } from '../../../../../../app-modules/benefits/services';
import { BenefitEnrollmentCommonService } from '../../../../../../../app/app-modules/benefits/services';

@Component({
  moduleId: module.id,
  selector: 'slx-benefit-enrollment',
  templateUrl: './benefit-enrollment.component.html',
  styleUrls: ['./benefit-enrollment.component.scss'],
  providers: [CalculationCommonService]

})
export class BenefitEnrollmentComponent implements OnInit, OnDestroy {

  @Input()
  public isEditable: boolean;

  @Input()
  public canMapPayroll: boolean;

  @Input()
  public employeeStatus: string;
  // rights
  public canCreateNewEnrollment: boolean = true;
  public canEdit: boolean = true;
  public canDelete: boolean = true;

  // configuration
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<EnrollmentHistoryRecord>;
  public gridView: GridDataResult;
  public pageSize: number = 5;

  // dynamic settings
  public applicableByEffectiveDate: boolean = false;
  public minEffectiveDate: Date = new Date(1900, 1, 1);
  public maxEffectiveDate: Date = new Date(2079, 6, 6);
  public effectiveDate: Date = new Date();
  public canEditEmpContribution: boolean;
  public multiplerMaxCap: boolean;
  public anyWithMaxCap: boolean;
  public isFormula: boolean;
  public coverageMultipllierOptions: { name: string, value: number }[];
  public editedItemCoverage: { name: string, value: number };
  public lastCoverageValue: number;

  public calculationMethod: any;
  public isLoading: boolean;
  public hasFormulaError: boolean = false;
  public empContrPercentError: boolean = false;

  // fields configuration (dynamic)
  private fieldSettings: StringMap<{ min: number, max: number, step: number, format: string, autoCorrect: boolean, decimals: number }> = {};
  private defaultMultiplierValue: number = 500;
  private defaultMultiplierMaxCap: number = 10000;

  @ViewChild('kendoGrid', { static: false })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: false })
  public form: NgForm;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  private enrollments: EnrollmentHistoryRecord[];

  private editedItem: EnrollmentHistoryRecord;

  private employeeId: number;
  public empName: string;
  public isCovCalculated: boolean = true;
  private newEnrollment: NewEnrollmentModel;

  public get isFutureRehired(): boolean {
    return this.employeeStatus ? this.employeeStatus.toLowerCase() === "future rehire" : false;
  }

  constructor(
    private managementService: BenefitsEnrollmentSectionManagementService,
    private apiService: EmployeeSectionsBenefitsManagementApiService,
    private commonValidationService: BenefitEnrollmentCommonService,
    private modalService: ModalService,
    private lookupService: LookupService,
    private benefitsApiService: BenefitEmployeesApiService,
    private bridgeService: EmployeeSectionBridgeService,
    private calculationCommonService: CalculationCommonService
  ) {

    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<EnrollmentHistoryRecord>();
    this.resetGridState();

    this.fieldSettings.empPercentContribution = {
      min: 0,
      max: 100,
      step: 0.01,
      autoCorrect: true,
      format: 'n2',
      decimals: 2
    };

    this.fieldSettings.empContribution = {
      min: 0,
      max: 999999999.99,
      step: 0.01,
      autoCorrect: true,
      format: 'c2',
      decimals: 2
    };

    this.fieldSettings.coverage = {
      min: 0,
      max: 99999999.99,
      step: 0.01,
      autoCorrect: false,
      format: 'c2',
      decimals: 2
    };

    this.fieldSettings.coverageFormula = {
      min: -1,
      max: 99999999.99,
      step: 0.01,
      autoCorrect: false,
      format: 'c2',
      decimals: 2
    }

    this.calculationMethod = CalculationType;

  }

  public ngOnDestroy(): void {
  }

  public ngOnInit(): void {

    this.subscriptions.dataLoaded = this.managementService.subscribeToDataLoad((enrollments: EmployeeSubsectionEnrollment) => {
      this.employeeId = enrollments.employeeInfo.id;
      this.empName = enrollments.employeeInfo.fullName;
      this.minEffectiveDate = enrollments.employeeInfo.dateHired;
      this.maxEffectiveDate = enrollments.employeeInfo.terminationDate ? moment(enrollments.employeeInfo.terminationDate).toDate() : new Date(2079, 6, 6);
      if (enrollments.actions) {
        this.canDelete = enrollments.actions.canDelete;
        this.canEdit = enrollments.actions.canEdit;
        this.canCreateNewEnrollment = enrollments.actions.canAdd;
      }
      this.updateApplicability();
    });

    this.subscriptions.loadingState = this.managementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });

    this.subscriptions.enrollmentsLoaded = this.managementService.subscribeToEnrollmentsLoad((enrollments: EnrollmentHistoryRecord[]) => {
      _.map(enrollments, (data) => { data.coverageLimit = ((this.fieldSettings.empContribution.max) && data.limitAmount > 0) ? data.limitAmount : this.fieldSettings.empContribution.max });
      this.enrollments = enrollments;
      this.setCostValue();
      this.refreshGrid();
    });

    this.subscriptions.enrollmentsDeleted = this.managementService.subscribeToEnrollmentDeleted((enrollments: EnrollmentHistoryRecord) => {
      this.managementService.getEmployeeEnrollments(this.employeeId, this.effectiveDate);
    });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.subscriptions.gridEdit = this.gridState.onEdit$
      .subscribe((record: EnrollmentHistoryRecord) => {
        this.hasFormulaError = false;
        this.editedItem = record;
        let coverageValue = record.coverage || 0;
        this.editedItemCoverage = { name: '' + coverageValue, value: coverageValue };
        this.canEditEmpContribution = this.editedItem.calculationMethod === CalculationType.Flat || this.editedItem.calculationMethod === CalculationType.OptionsRates || this.editedItem.calculationMethod === CalculationType.C401K;

        this.fieldSettings.empPercentContribution.max = _.isNumber(this.editedItem.maxEmployeePercentageContribution) && this.editedItem.maxEmployeePercentageContribution > 0 ? this.editedItem.maxEmployeePercentageContribution : 100;
        this.coverageMultipllierOptions = [];

        this.multiplerMaxCap = this.editedItem.calculationType === FormulaTypeEnum.MultiplerMaxCap;
        this.anyWithMaxCap = this.editedItem.calculationType === FormulaTypeEnum.AnyWithMaxCap;
        this.isFormula = this.editedItem.calculationType === FormulaTypeEnum.Formula;
      
        if (this.multiplerMaxCap) {

          let mult = _.isNumber(this.editedItem.multiplier) ? this.editedItem.multiplier : this.defaultMultiplierValue;
          let cap = _.isNumber(this.editedItem.maxCap) ? this.editedItem.maxCap : this.defaultMultiplierMaxCap;
          const range = _.range(mult, cap + mult, mult);
          const opts = _.map(range, value => ({ name: _.toString(value), value }));

          this.coverageMultipllierOptions = opts;
        } else if (this.anyWithMaxCap) {
          this.fieldSettings.coverage.max = _.isNumber(this.editedItem.maxCap) ? this.editedItem.maxCap : this.fieldSettings.coverage.max;
          this.coverageMultipllierOptions = [];
        }
        this.validateFormulaEvaluation(record, false);
        this.isCovCalculated = true;
      });

    this.subscriptions.gridCancel = this.gridState.onCancel$
      .subscribe(() => {
        this.hasFormulaError = false;
        this.editedItem = null;
        this.isCovCalculated = true;
      });

    this.subscriptions.gridRemove = this.gridState.onRemove$
      .subscribe((event: removeEvent<EnrollmentHistoryRecord>) => {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Confirmation',
          'Are you sure you want to delete the selected benefit? This will terminate coverage for the enrolled employee and dependents.', this.modalService, (result: boolean) => {
            if (result) {
              this.managementService.deleteEnrollment(event.dataItem);
            } else {
              this.refreshGrid();
            }
          }, options);
      });

    this.subscriptions.gridSave = this.gridState.onSave$
      .subscribe(async (event: saveEvent<EnrollmentHistoryRecord>) => {
        this.hasFormulaError = false;
        this.gridState.closeEditor(this.grid);
        await this.managementService.updateEnrollment(event.dataItem);
        this.editedItem = null;
        await this.managementService.getEmployeeEnrollments(this.employeeId, this.effectiveDate);
        this.isCovCalculated = true;
      });

  }

  public updateApplicability() {
    if (this.maxEffectiveDate) {
      this.applicableByEffectiveDate = moment(this.effectiveDate).isSameOrBefore(this.maxEffectiveDate);
    } else {
      this.applicableByEffectiveDate = true;
    }
  }

  public onPlanClick(selectedRecord) {
    this.managementService.planSelected(this.employeeId, selectedRecord);
  }

  public onChangeEffectiveDate(date: Date): void {
    if (_.isDate(date) && !moment(this.effectiveDate).isSame(date)) {
      this.effectiveDate = date;
      this.resetGridState();
      this.managementService.getEmployeeEnrollments(this.employeeId, date);
    }
  }

  public isEditingRowDiabled(dep: EnrollmentHistoryRecord): boolean {
    return (dep.calcMethod.isOptionsRates ||
      ((dep.calcMethod.isFormulaWithOption || dep.calcMethod.isFormula) && dep.calculationType === 1))
  }

  public getDisabledEditTooltip(dep: EnrollmentHistoryRecord): string {
    if(this.isEditingRowDiabled(dep)){
      return "This benefit does not contain editable fields.";
    }
    return "";
  }

  public isEditingRow(dep: EnrollmentHistoryRecord): boolean {
    return _.isObjectLike(this.editedItem) && _.isObjectLike(dep) && this.editedItem.empToBenefitsId === dep.empToBenefitsId;
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  public onChangeEndDate(item: EnrollmentHistoryRecord): void {

    if (!this.canEdit) return;

    this.bridgeService.sectionsAreVisible = false;
    DropCoveragePopupComponent.openDialog(false, [item],this.canMapPayroll, this.effectiveDate, this.modalService, this.benefitsApiService, (result: boolean) => {
      if (result) {
        this.managementService.getEmployeeEnrollments(this.employeeId, this.effectiveDate, true);
      }
      this.bridgeService.sectionsAreVisible = true;
    });
  }

  public onViewBeneficiaries(item: EnrollmentHistoryRecord): void {
    this.bridgeService.sectionsAreVisible = false;
    BeneficiaryEditorPopupComponent.openDialog(item.empToBenefitsId, this.apiService, this.modalService, this.lookupService, (r: boolean) => {
      this.bridgeService.sectionsAreVisible = true;
    });
  }

  public onDropCoverage(): void {
    this.bridgeService.sectionsAreVisible = false;
    DropCoveragePopupComponent.openDialog(true, this.enrollments, this.canMapPayroll, this.effectiveDate, this.modalService, this.benefitsApiService, (result: boolean) => {
      if (result) {
        this.managementService.getEmployeeEnrollments(this.employeeId, this.effectiveDate, true);
      }
      this.bridgeService.sectionsAreVisible = true;
    });
  }

  public onChangeCoverageWithMultiplier(value: any, item: EnrollmentHistoryRecord, hasError: boolean): void {
    const numericValue = parseInt(value);
    if (hasError || isNaN(numericValue)) {
      item.coverage = null;
      this.editedItemCoverage = null;
      this.lastCoverageValue = null;
      this.hasFormulaError = true;
      return;
    }
    this.editedItemCoverage = _.find(this.coverageMultipllierOptions, x => x.value === numericValue);
    item.coverage = numericValue;
    if (item.coverage !== this.lastCoverageValue) {
      this.lastCoverageValue = item.coverage;
      item.calculatedCoverage = item.coverage;
      this.calculateFormula(item.coverage, item);
    }
  }

  public calculateCoverage(item: EnrollmentHistoryRecord, hasError: boolean): void {
    if (!hasError && item.coverage !== item.calculatedCoverage) {
      item.calculatedCoverage = item.coverage;
      this.calculateFormula(item.coverage, item);
    }
    this.isCovCalculated = true;
  }

  public onOptionValueChange(item) {
    if (item && item.selectedOption && item.option != item.selectedOption.code) {
      item.benefitTierOptionId = item.selectedOption.id;
      item.optionType = item.selectedOption.type;
      item.option = item.selectedOption.code;
      this.calculateFormula(item.coverage, item);
    }
  }

  public onCoverageValueChange(value: number, item: EnrollmentHistoryRecord, hasError: boolean): void {
    if (hasError || isNaN(value)) {
      item.coverage = null;
      this.editedItemCoverage = null;
      this.lastCoverageValue = null;
      this.hasFormulaError = true;
      return;
    }
    item.coverage = value;
    this.isCovCalculated = false;
  }

  private calculateFormula(coverage: number, item: EnrollmentHistoryRecord) {
    if ((this.editedItem.option || item.calculationMethod === CalculationType.Formula)
        && (this.isFormula || this.form.valid)) {
      item.coverage = coverage ? coverage : 0;
      this.isLoading = true;
      this.apiService.getBenefitPlanFormula(item.benefitTierId, this.employeeId, this.effectiveDate, item.coverage, this.editedItem.option)
        .then((record: BenefitEmpEnrollmentFormula) => {
          if (record) {
            item.employeeContribution = record.empContribution;
            item.employerContributionAmount = record.erContribution;
            item.calculatedCoverage = record.calculatedCoverage;
            if (this.multiplerMaxCap || this.anyWithMaxCap) {
              record.formulaValue = record.calculatedCoverage;
            }
            if (!_.isNil(record.formulaValue)) {
              this.hasFormulaError = false;
            } else {
              this.hasFormulaError = true;
            }
          }
        }).finally(() => {
          this.lastCoverageValue = null;
          this.isLoading = false;
          this.validateFormulaEvaluation(item, true);
        });
    }
  }

  public onStartEnrollment(): void {

    this.bridgeService.sectionsAreVisible = false;

    let ids = _.map(this.enrollments, e => e.empToBenefitsId);
    let newEnrollment = new NewEnrollmentModel(ids, this.effectiveDate, this.canMapPayroll);

    newEnrollment.empId = this.employeeId;
    this.newEnrollment = newEnrollment;
    EnrollEmployeeSelectPlanPopupComponent.openDialog(this.newEnrollment, this.apiService, this.modalService, this.lookupService, (result: boolean) => {
      if (result) {

        switch (this.newEnrollment.plan.calculationMethod) {
          case CalculationType.Flat:
            EnrollEmployeeFlatComponent.openDialog(this.newEnrollment, this.apiService, this.modalService, this.lookupService, (result: boolean) => {
              if (result) {
                this.createEnrollment(this.newEnrollment);
              } else {
                this.newEnrollment = null;
              }
              this.bridgeService.sectionsAreVisible = true;
            });
            break;
          case CalculationType.Formula:
            EnrollEmployeeFormulaComponent.openDialog(this.newEnrollment, this.apiService, this.modalService, this.lookupService, (result: boolean) => {
              if (result) {
                this.createEnrollment(this.newEnrollment);
              } else {
                this.newEnrollment = null;
              }
              this.bridgeService.sectionsAreVisible = true;
            });
            break;
          case CalculationType.FormulaWithOption:
            EnrollEmployeeFormulaWithOptionComponent.openDialog(this.newEnrollment, this.apiService, this.modalService, this.lookupService, (result: boolean) => {
              if (result) {
                this.createEnrollment(this.newEnrollment);
              } else {
                this.newEnrollment = null;
              }
              this.bridgeService.sectionsAreVisible = true;
            });
            break;
          case CalculationType.C401K:
            EnrollEmployee401kComponent.openDialog(this.newEnrollment, this.apiService, this.modalService, this.lookupService, (result: boolean) => {
              if (result) {
                this.createEnrollment(this.newEnrollment);
              } else {
                this.newEnrollment = null;
              }
              this.bridgeService.sectionsAreVisible = true;
            });
            break;
          case CalculationType.OptionsRates:

            EnrollEmployeeSelectCoveragePopupComponent.openDialog(
              this.newEnrollment,
              this.apiService,
              this.modalService,
              this.calculationCommonService,
              (result: boolean) => {
                if (result) {
                  if (this.newEnrollment.selectedCoverageOption) {
                    this.createEnrollment(this.newEnrollment);
                  }
                } else {
                  this.newEnrollment = null;
                }
                this.bridgeService.sectionsAreVisible = true;
              }
            );

            break;
        }

      } else {
        this.newEnrollment = null;
        this.bridgeService.sectionsAreVisible = true;
      }
    });

  }

  private setCostValue(): void {
    _.forEach(this.enrollments, (employee: EnrollmentHistoryRecord) => {
      if (employee.calcMethod.isOptionsRates) {
        this.onChangeContribution(employee);
      }
    });
  }

  public onChangePercentContribution(dataItem: EnrollmentHistoryRecord): any {
    this.empContrPercentError = (this.editedItem.employeeContribution === null && this.editedItem.employeePercentageContribution === null) ? true : false;
  }

  public onChangeContribution(dataItem: EnrollmentHistoryRecord): any {
    let employeeContribution = 0;
    if (dataItem.employeeContribution > 0) {
      employeeContribution = this.calculationCommonService.getConvertedEmployeeContribution(dataItem.costFreq, dataItem.empContFreq, dataItem.employeeContribution);
    }
    dataItem.cost = dataItem.employerContributionAmount + employeeContribution;
    this.empContrPercentError = (dataItem.employeeContribution === null && dataItem.employeePercentageContribution === null) ? true : false;
  }

  private async createEnrollment(newEnrollment: NewEnrollmentModel): Promise<boolean> {

    this.managementService.updateSpinner(true);

    let r: BenefitEnrolledEmployee = new BenefitEnrolledEmployee();
    let tierId: number;
    let lineId: number;
    let calculationStr: string;
    r.employeeId = newEnrollment.empId;
    r.payrollDeductionStartDate = newEnrollment.plan.payrollDeductionStartDate;
    r.payrollDeductionEndDate = newEnrollment.plan.payrollDeductionEndDate;

    if (newEnrollment) {
      if (newEnrollment.flatSettings) {
        tierId = newEnrollment.flatSettings.tierId;
        lineId = newEnrollment.flatSettings.lineId;
        calculationStr = CalculationType[CalculationType.Flat];
        r.employeeContributionAmount = newEnrollment.flatSettings.empContribution;
        r.employerContributionAmount = newEnrollment.flatSettings.erContribution;
      } else if (newEnrollment.formulaSettings && newEnrollment.selectedCoverageOption) {
        tierId = newEnrollment.formulaSettings.tierId;
        lineId = newEnrollment.formulaSettings.lineId;
        calculationStr = CalculationType[CalculationType.FormulaWithOption];
        r.coverage = newEnrollment.formulaSettings.getCoverage();
        r.employeeContributionAmount = newEnrollment.formulaSettings.empContribution;
        r.employerContributionAmount = newEnrollment.formulaSettings.erContribution;
        r.benefitTierOptionId = newEnrollment.selectedCoverageOption.id;
      } else if (newEnrollment.formulaSettings) {
        tierId = newEnrollment.formulaSettings.tierId;
        lineId = newEnrollment.formulaSettings.lineId;
        calculationStr = CalculationType[CalculationType.Formula];
        r.coverage = newEnrollment.formulaSettings.getCoverage();
        r.employeeContributionAmount = newEnrollment.formulaSettings.empContribution;
        r.employerContributionAmount = newEnrollment.formulaSettings.erContribution;
      } else if (newEnrollment.settings401k) {
        tierId = newEnrollment.settings401k.tierId;
        lineId = newEnrollment.settings401k.lineId;
        calculationStr = CalculationType[CalculationType.C401K];
        r.employeeContributionPercentage = newEnrollment.settings401k.employeePercentageContribution;
        r.employeeContributionAmount = newEnrollment.settings401k.employeeContribution;
        r.limit = newEnrollment.settings401k.limitAmount;
        this.fieldSettings.empContribution.max = newEnrollment.settings401k.limitAmount;
      } else if (newEnrollment.selectedCoverageOption) {

        tierId = newEnrollment.selectedCoverageOption.tierId;
        lineId = newEnrollment.selectedCoverageOption.lineId;
        calculationStr = CalculationType[CalculationType.OptionsRates];
        r.benefitTierOptionId = newEnrollment.selectedCoverageOption.id;
        r.employeeContributionAmount = newEnrollment.selectedCoverageOption.tierOptionEmpContribution;
        r.employerContributionAmount = newEnrollment.selectedCoverageOption.tierOptionErContribution;

      }

      try {
        await this.benefitsApiService.enrollEmployeesBenefit([r], '', newEnrollment.effectiveDate, lineId, tierId, calculationStr);
        this.managementService.getEmployeeEnrollments(this.employeeId, this.effectiveDate);

        return true;
      } catch (e) {
        console.error(e);
      }
    }

    return false;
  }

  private refreshGrid(): void {

    if (!this.enrollments) {
      this.gridView = null;
      return;
    }
    this.gridState.view = process(this.enrollments, this.gridState.state);

  }

  private resetGridState():void{

    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'startDate', dir: 'desc' }
    ];

  }

  public validateFormulaEvaluation(dataItem: EnrollmentHistoryRecord, showWarning: boolean): void {

    if (!this.isFormula && !this.multiplerMaxCap && this.anyWithMaxCap) return;

    let coverage = this.isFormula ? dataItem.calculatedCoverage : dataItem.coverage;
    let errorMessage = this.commonValidationService.validateFormulaResult(
      dataItem.employeeContribution,
      dataItem.employerContributionAmount,
      coverage
    );

    this.hasFormulaError = errorMessage && errorMessage != '';

    if (this.hasFormulaError && showWarning) {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = false;
      options.showOK = true;
      ConfirmDialog2Component.openDialog(
        'Warning',
        errorMessage,
        this.modalService,
        (result: boolean) => { },
        options);
    }
  }

   public isEmpContribSelected(dataItem: EnrollmentHistoryRecord): boolean {
     return !!_.get(dataItem, 'employeePercentageContribution', null) || (dataItem.employeePercentageContribution === 0);
   }
 
   public isEmpPercentSelected(dataItem: EnrollmentHistoryRecord): boolean {
     return !!_.get(dataItem, 'employeeContribution', null) || (dataItem.employeeContribution === 0);
   }
 

}
