import { FieldData } from './../../../../core/models/field/field-data';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionsACA {
  records: IEmployeeACA[];
  effectiveDate: string;
}

export class EmployeeSectionsACA extends EmployeeSectionsBase {
  public records: EmployeeACA[];
  public effectiveDate: Date;

  constructor() {
    super();
    this.records = [];
  }
}

export interface IEmployeeACA {
  id: number;
  empId: number;
  startDate: string;
  endDate: string;
  isEmployee: boolean;
  isSystemCalculated: boolean;
  annualIncome: number;
  modifiedBy: string;
  modifiedDate: string;
  deleted: boolean;
  isDeclinedOffer: boolean;
  isMarketplacePurchase: boolean;
}

export class EmployeeACA {
  public id: number;
  public empId: number;
  public startDate: Date;
  public endDate: Date;
  public isEmployee: boolean;
  public nonEmployee: string;
  public isSystemCalculated: boolean;
  public incomeCalculationType: string;
  public annualIncome: FieldData<number>;
  public modifiedBy: string;
  public modifiedDate: Date;
  public deleted: boolean;
  public isDisabledDel: boolean;
  public isAddEditMode: boolean;
  public isDeclinedOffer: boolean;
  public isMarketplacePurchase: boolean;
  public declinedOffer: string;
  public marketplacePurchase: string;
}

export class EmployeeAcaDto {
  public id: number;
  public empId: number;
  public startDate: string;
  public endDate: string;
  public isEmployee: boolean;
  public isSystemCalculated: boolean;
  public annualIncome: number;
  public deleted: boolean;
  public isDeclinedOffer: boolean;
  public isMarketplacePurchase: boolean;
}


