export type RolesAccessDefinition = 'none' | 'edit' | 'view' | 'masked' | 'mixed';
export class RolesAccess {
  public static none: RolesAccessDefinition = 'none';
  public static edit: RolesAccessDefinition = 'edit';
  public static view: RolesAccessDefinition = 'view';
  public static masked: RolesAccessDefinition = 'masked';
  public static mixed: RolesAccessDefinition = 'mixed';
}


export type RolesRightsAccessDefinition = 'enabled' | 'disabled' | 'mixed';
export class RolesRightsAccess {
  public static enabled: RolesRightsAccessDefinition = 'enabled';
  public static disabled: RolesRightsAccessDefinition = 'disabled';
  public static mixed: RolesRightsAccessDefinition = 'mixed';
}

export type RolesMenuAccessDefinition = 'enabled' | 'disabled' | 'mixed';
export class RolesMenuAccess {
  public static enabled: RolesMenuAccessDefinition = 'enabled';
  public static disabled: RolesMenuAccessDefinition = 'disabled';
  public static mixed: RolesMenuAccessDefinition = 'mixed';
}

export type RolesComponentAccessDefinition = 'enabled' | 'disabled' | 'mixed';
export class RolesComponentAccess {
  public static enabled: RolesComponentAccessDefinition = 'enabled';
  public static disabled: RolesComponentAccessDefinition = 'disabled';
  public static mixed: RolesComponentAccessDefinition = 'mixed';
}
