export interface IApprovalRequiredCodeRecord {
    employeeId: number;
    date: string;
    payCodeId: number;
}

export class ApprovalRequiredCodeRecord {
    public employeeId: number;
    public date: Date;
    public payCodeId: number;
    public isLocked: boolean;
}

