import { EmployeeSectionsPosition, IEmployeeSectionsPosition } from './../../../employee/employee-sections/models/employee-sections-employment/employee-sections-positions';
import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService } from '../../../common/index';
import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { ResponseBody } from '../../../core/models/api/response-body';
import { AgencyEmployeeMapService } from './agency-employee-map.service';
import { Assert } from '../../../framework/index';
import { organizationConfig } from '../../../organization/organization.config';
import { configurationConfig } from '../../configuration.config';
import { IServerValidationResult } from '../../../common/validators/common-validators-models';

import { IEmployeeShortInfo, AgencyDefinition } from '../../../organization/models/index';
import { IAgencyEmployee, AgencyEmployee, IAddAgencyEmployeeReq, AddAgencyEmployeeReq, ValidateAgencyEmployeeReq } from '../../models/index';
import { HttpRequest } from '@angular/common/http';

@Injectable()
export class AgencyEmployeeApiService {
  constructor(private apiUtilService: ApiUtilService, private urlParamsService: UrlParamsService, private mapService: AgencyEmployeeMapService) {
  }

  public getAgencyEmployees(agencyId: number): Promise<AgencyEmployee[]> {

    const url: string = `${this.getApiRoot()}/${agencyId}/${configurationConfig.api.configuration.agencies.employees}`;

    return this.apiUtilService.request<IAgencyEmployee[], Meta>(this.urlParamsService.createGetRequest(url))
      .then((response: ResponseBody<IAgencyEmployee[], Meta>) => {
        return this.mapService.mapAgencyEmployees(response.data);
      });
  }

  public addAgencyEmployee(req: AddAgencyEmployeeReq): Promise<AgencyEmployee> {
    Assert.isNotNull(req, 'addAgencyEmployee AddAgencyEmployeeReq');
    const url: string = `${this.getApiRoot()}/${configurationConfig.api.configuration.agencies.addAgencyEmployee}`;

    return this.apiUtilService.request<IAgencyEmployee, Meta>(this.urlParamsService.createPostRequest(url, this.mapService.mapAgencyEmployeeReqDto(req)))
      .then((response: ResponseBody<IAgencyEmployee, Meta>) => {
        return this.mapService.mapAgencyEmployee(response.data);
      });
  }

  public validateAddAgencyEmployee(req: ValidateAgencyEmployeeReq): Promise<IServerValidationResult> {
    Assert.isNotNull(req, 'addAgencyEmployee AddAgencyEmployeeReq');
    const url: string = `${this.getApiRoot()}/${configurationConfig.api.configuration.agencies.validateAddAgencyEmployee}`;

    return this.apiUtilService.request<IServerValidationResult, Meta>(this.urlParamsService.createPostRequest(url, this.mapService.mapValidateAgencyEmployeeReqDto(req)))
      .then((response: ResponseBody<IServerValidationResult, Meta>) => {
        return response.data;
      });
  }

  public sendTimeclocksEmailToAgencyEmployee (employeeId: number): Promise<any> {

    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getApiRoot()}/${configurationConfig.api.configuration.agencies.employees}/${employeeId}/${configurationConfig.api.configuration.agencies.resendTimeclocksEmail}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest (url);

    let promise: Promise<any> = this.apiUtilService.request(request);
    return promise;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.agencies.root}`;
  }
}
