import * as moment from 'moment';

export interface  ISummaryRecord {

  approaching: boolean;
  date: Date;
  openShifts: number;
  par: number;
  scheduled: number;

  shift: any;
  position: any;
  unit: any;
}

export interface  IPostScheduleData {

  startDate: Date;
  endDate: Date;
  entities: ISummaryRecord[];
  status: string;

}


export class SummaryGridRecord {

  public approaching: boolean;
  public date: Date;
  public openShifts: number;
  public par: number;
  public scheduled: number;

  public shift: any;
  public position: any;
  public unit: any;

  public editing: boolean = false;

}


export class PostScheduleData {

  public startDate: Date;
  public endDate: Date;
  public entities: SummaryGridRecord[];
  public status: string = 'Pending';

}
