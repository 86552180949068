import { Component, EventEmitter, Output, Input } from '@angular/core';
import { DetailGroupViewSettingsTypes } from '../../../models/index';
@Component({
    moduleId: module.id,
    selector: 'slx-grid-detail-group-view-settings',
    templateUrl: 'detail-group-view-settings.component.html',
    styleUrls: ['detail-group-view-settings.component.scss']
  })
export class DetailGroupGridViewSettingsComponent {
    @Output()
    public close: EventEmitter<DetailGroupViewSettingsTypes> = new EventEmitter();

    @Input()
    public settingsType: DetailGroupViewSettingsTypes;

    public closeClicked(): void {
        this.close.emit(this.settingsType);
    }
}
