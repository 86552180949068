import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { appConfig } from '../../../app.config';
import { configurationConfig } from '../../configuration.config';

import { Meta } from '../../../core/models/api/meta';
import { ApiUtilService } from '../../../common/index';
import { UrlParamsService } from '../../../common/services/url-params/url-params.service';

import { ResponseBody } from '../../../core/models/api/response-body';
import { IGeolocationEntityDTO, GeolocationEntity } from '../../models/index';
import { GeolocationMapService } from './geolocation-map.service';

@Injectable()
export class GeolocationApiService {
  constructor(
      private apiUtilService: ApiUtilService,
      private mapService: GeolocationMapService,
      private urlParamsService: UrlParamsService
    ) {
  }

  public async getEntities(orgLevelId: number): Promise<GeolocationEntity[]> {
    const url: string = `${this.getGeoApi()}?orgLevelId=${orgLevelId}`;
    const request =  this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .request<IGeolocationEntityDTO[], Meta>(request)
      .then((response: ResponseBody<IGeolocationEntityDTO[], Meta>) => this.mapService.mapEntities(response.data));
  }

  public async saveEntity(entity: GeolocationEntity): Promise<any> {
    const url: string = this.getGeoApi();
    const request =  this.urlParamsService.createPostRequest(url, this.mapService.mapNewEntityToDTO(entity));

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
  }

  public async udateEntity(entity: GeolocationEntity): Promise<any> {
    const url: string = this.getGeoApi();
    const request =  this.urlParamsService.createPutRequest(url, this.mapService.mapExistingEntityToDTO(entity));

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
  }

  public async deleteEntity(entity: GeolocationEntity): Promise<any> {
    const url: string = `${this.getGeoApi()}/${entity.id}`;
    const request =  this.urlParamsService.createDeleteRequest(url);

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
  }

  private getGeoApi(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}`+
    `/${configurationConfig.api.configuration.geolocation.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
