<div class="weekly">
  <p class="flex align-items__center weekly__section">
    <label class="weekly__label">Repeat every</label>
    <slx-number
      class="weekly__number"
      [min]="min"
      [step]="step"
      [decimals]="decimals"
      [autoCorrect]='true'
      [readonly]="!canEditRequest"
      [(ngModel)]="interval"
      (ngModelChange)="onChangeRecurrence()"
    ></slx-number>
    <span class="weekly__text">week(s)</span>
  </p>

  <p class="flex align-items__center weekly__section">
    <label class="weekly__label">Repeat on</label>
    <span class="slx-line-buttons slx-small flex__grow">
      <button class="slx-line-buttons__btn"
        *ngFor="let day of weekDays"
        [disabled]="!canEditRequest"
        [class.active]="day.isActive"
        (click)="onClickDay(day)"
      >{{ day.shortText }}</button>
    </span>
  </p>

  <p class="flex weekly__line">
    <label class="weekly__label">End</label>
    <span class="flex__grow">
      <span class="flex align-items__center weekly__line">
        <slx-radio-button class="weekly__radio"
          caption="After"
          [option]="endAfterOccurrence"
          [readonly]="!canEditRequest"
          [(ngModel)]="endMode"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-radio-button>
        <slx-number class="weekly__number"
          [min]="min"
          [step]="step"
          [decimals]="decimals"
          [autoCorrect]='true'
          [readonly]="!canEditRequest || countIsReadonly"
          [(ngModel)]="count"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-number>
        <span class="weekly__text">occurrence(s)</span>
      </span>
      <span class="flex align-items__center weekly__line">
        <slx-radio-button class="weekly__radio"
          caption="On"
          [option]="endOnDate"
          [readonly]="!canEditRequest"
          [(ngModel)]="endMode"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-radio-button>
        <slx-datepicker class="weekly__date"
          [minDate]="calculatedMaxDate || limitEndOnDate"
          [readonly]="!canEditRequest || untilIsReadonly"
          [(ngModel)]="until"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-datepicker>
      </span>
    </span>
  </p>
</div>
