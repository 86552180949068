import { ResponseBody } from './../../../core/models/api/response-body';
import { Meta } from './../../../core/models/api/meta';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from './../../../app.config';
import { configurationConfig } from './../../configuration.config';
import { LicenseType, ILicenseType } from './../../../organization/models/lookup/license-type';

import { UrlParamsService } from './../../../common/services/url-params/url-params.service';
import { ApiUtilService } from './../../../common/services/api/api-util.service';
import { Injectable } from '@angular/core';
import { LicenseTypesMapService } from './license-types-map.service';
@Injectable()
export class LicenseTypesApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: LicenseTypesMapService
  ) {}

  public getList(orgLevelId: number): Promise<LicenseType[]> {
    const url: string = this.getOrgLevelApiRoot(orgLevelId);

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<any[], Meta>(request).then((response: ResponseBody<ILicenseType[], Meta>) => {
      const list: LicenseType[] = this.mapService.mapLicenseTypes(response.data);
      return list;
    });
  }


  public save(orgLevelId: number, type: LicenseType): Promise<LicenseType> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${type.licenseTypeID}`;

    let body: any = this.mapService.mapLicenseTypeDto(type);
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService.request<ILicenseType, Meta>(request)
      .then((response: ResponseBody<ILicenseType, Meta>) => {
        return this.mapService.mapLicenseType(response.data);
      });
  }

  public add(orgLevelId: number, type: LicenseType): Promise<LicenseType> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}`;

    let body: any = this.mapService.mapLicenseTypeDto(type);
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService.request<ILicenseType, Meta>(request)
      .then((response: ResponseBody<ILicenseType, Meta>) => {
        return this.mapService.mapLicenseType(response.data);
      });
  }

  public delete(orgLevelId: number, typeId: number): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${typeId}`;

    const request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url);
    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }


  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiConfigRoot()}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.licenseTypes.root}`;
  }

  private getApiConfigRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${configurationConfig.api.configuration.root}`;
  }
}
