<slx-spinner [show]="isLoading">
    <div class="slx-high-box payroll payroll-export-test-component">
      <slx-payroll-export-test-toolbar></slx-payroll-export-test-toolbar>
      <div class="slx-high-box__body slx-main-content-indents payroll-export-content">
        <div class="payroll-export-content-header">
          <div class="header-item group-item">
            <i class="fas fa-building" aria-hidden="true"></i>
            <span class="header-item__text">Facility Name</span>
          </div>
          <div class="header-item group-item">
            <i class="fas fa-ellipsis-v" aria-hidden="true"></i>
            <span class="header-item__text">Actions</span>
          </div>
          <div class="header-item request-status-item">
            <i class="fas fa-thermometer-three-quarters" aria-hidden="true"></i>
            <span class="header-item__text header-item__text__desktop">Status</span>
            <span class="header-item__text header-item__text__mobile">Status</span>
          </div>
        </div>
        <div class="payroll-export-sections" *ngFor="let organization of manageService.orgList">
            <slx-payroll-export-test-section [organization]="organization"></slx-payroll-export-test-section>
        </div>
      </div>
    </div>
  </slx-spinner>