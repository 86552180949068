import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { DeviceDetectorService } from '../../../../../common/services/index';
import { ColumnManagementService, StateManagementService } from '../../../../../common/services/index';
import { DateRange, IDateRange } from '../../../../../core/models/index';
import { GenericListManagementService } from '../../../../../organization/services';
import { DirectCareHoursManagementService } from '../../../services';
var DirectCareHoursToolbarComponent = /** @class */ (function () {
    function DirectCareHoursToolbarComponent(managementService, genericListManagementService, columnManagementService, stateManagement, devDetector) {
        this.managementService = managementService;
        this.genericListManagementService = genericListManagementService;
        this.columnManagementService = columnManagementService;
        this.stateManagement = stateManagement;
        this.devDetector = devDetector;
        this.dateRange = new DateRange(null, null);
        this.canCreateNewReview = false;
        this.columnsStateName = 'genericGridGroup';
        this.subscriptions = {};
    }
    Object.defineProperty(DirectCareHoursToolbarComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DirectCareHoursToolbarComponent.prototype, "dateRangeWidth", {
        get: function () {
            return this.isDesktop ? 325 : 246;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DirectCareHoursToolbarComponent.prototype, "newReviewBtnWidth", {
        get: function () {
            return this.isDesktop ? 175 : 35;
        },
        enumerable: true,
        configurable: true
    });
    DirectCareHoursToolbarComponent.prototype.ngOnInit = function () {
        this.initServices();
        this.defineDateRange();
    };
    DirectCareHoursToolbarComponent.prototype.ngOnDestroy = function () { };
    DirectCareHoursToolbarComponent.prototype.onChangeDates = function (range) {
        this.managementService.changeDateRange(range);
    };
    DirectCareHoursToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.managementService.doExport(isPDF);
    };
    DirectCareHoursToolbarComponent.prototype.defineDateRange = function () {
        this.dateRange = this.managementService.getDefaultDateRange();
        this.onChangeDates(this.dateRange);
    };
    DirectCareHoursToolbarComponent.prototype.initServices = function () {
        var _this = this;
        this.stateManagement.init('DirectCareHoursComponent');
        this.columnManagementService.init('DirectCareHoursComponent');
        this.subscriptions.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe(function (defs) {
            var visibleFields = _.filter(_.values(defs.fields), function (f) { return !f.hidden; });
            _this.columnManagementService.initGroup(_this.columnsStateName, _.size(visibleFields));
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], DirectCareHoursToolbarComponent.prototype, "subscriptions", void 0);
    return DirectCareHoursToolbarComponent;
}());
export { DirectCareHoursToolbarComponent };
