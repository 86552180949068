<form novalidate #form="ngForm" *ngIf="employeeSectionsTelepunch">
    <div class="col-sm-2">
    <slx-input-decorator className="slx-no-border">
        <slx-checkbox-input slx-input  name="phonePunchOption" fieldName="phonepunchEnabled" [readonly]="!state.isEditMode" [caption]="'Enable Phone Punch'"
        [(ngModel)]="employeeSectionsTelepunch?.phonepunchEnabled.fieldValue" (ngModelChange)="updateData()"
        [inputPolicy]="employeeSectionsTelepunch?.phonepunchEnabled.Policy">
        </slx-checkbox-input>
    </slx-input-decorator>
    </div>
    <div class="col-sm-3">
        <slx-input-decorator class="element">
            <slx-dropdown-input slx-input valueField="id" titleField="name" name="restriction" [(ngModel)] = "phonePunchRestrictions"
            [options]="restrictionsLookup" placeholder="Phone Punch Restriction" [required]="employeeSectionsTelepunch?.phonepunchEnabled.fieldValue" [readonly]="!state.isEditMode || !employeeSectionsTelepunch?.phonepunchEnabled.fieldValue"
            [inputPolicy]="employeeSectionsTelepunch?.phonepunchRestrictions.Policy">
            </slx-dropdown-input>
        </slx-input-decorator>
    </div>
</form>