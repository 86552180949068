import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { BenefitEligibleEmployeesRequest } from '../../models/index';
import { ModalService } from '../../../../common/services/index';
import { BenefitEmployeesDialogComponent } from '../../components/benefit-employees/benefit-employees-unenrolled-dialog/benefit-employees-unenrolled-dialog.component';

@Directive({
  selector: '[slxBenefitEligibleEmployeesModal]',
})
export class BenefitEligibleEmployeesModalDirective {
  constructor(private modalService: ModalService) {
    this.refreshEmployees = new EventEmitter<boolean>();
  }
  @Input()
  public slxBenefitEligibleEmployeesModal: BenefitEligibleEmployeesRequest;
  @Output()
  public refreshEmployees: EventEmitter<any>;

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    BenefitEmployeesDialogComponent.openDialog(this.slxBenefitEligibleEmployeesModal, this.modalService, (result: boolean, cmd: any) => {
      this.refreshEmployees.emit(true);
    });
  }
}
