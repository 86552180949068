import * as _ from 'lodash';
import { Provider, AfterViewInit, ElementRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { DialogOptions2, LoaRequestClass, DialogModeSize } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { LoaRequestDialogOptions, LoaRequest, LoaRequestEmployee } from '../../../models/index';
import { screenUtils } from '../../../../../common/utils/index';
var LMCreationDialogComponent = /** @class */ (function () {
    function LMCreationDialogComponent(dialogOptions, options, modalService) {
        var _a;
        this.height = (_a = {},
            _a[LoaRequestClass.Intermittent] = 704,
            _a[LoaRequestClass.Continuous] = 564,
            _a);
        this.extraHeightIndent = 40;
        this.dialogOptions = dialogOptions;
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
    }
    LMCreationDialogComponent.openDialogForRequest = function (orgLevelId, loaRequest, modalService) {
        var options = new LoaRequestDialogOptions(orgLevelId, loaRequest);
        return LMCreationDialogComponent.openDialog(options, modalService);
    };
    LMCreationDialogComponent.openDialogForEmployee = function (employeeShortInfo, orgLevelId, requestClass, modalService) {
        var loaRequest = new LoaRequest();
        loaRequest.requestClass = requestClass;
        loaRequest.employee = new LoaRequestEmployee(employeeShortInfo.id, employeeShortInfo.name);
        loaRequest.organization = _.cloneDeep(employeeShortInfo.organization);
        loaRequest.department = _.cloneDeep(employeeShortInfo.department);
        loaRequest.position = _.cloneDeep(employeeShortInfo.position);
        var options = new LoaRequestDialogOptions(orgLevelId, loaRequest);
        return LMCreationDialogComponent.openDialog(options, modalService);
    };
    LMCreationDialogComponent.openDialog = function (options, modalService) {
        var title = (_.isFinite(_.get(options, 'loaRequest.id')) ? 'Edit' : 'Create New') + " Leave of Absence Request";
        var dialogOptions = new DialogOptions2();
        dialogOptions.width = 670;
        dialogOptions.height = 260;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.custom;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: LoaRequestDialogOptions,
                useValue: options
            }
        ];
        return modalService.globalAnchor.openDialog2(LMCreationDialogComponent, title, dialogOptions, resolvedProviders);
    };
    LMCreationDialogComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (_.isFinite(this.dialogOptions.loaRequest.empId)) {
            var subscr_1 = Observable.of(true)
                .delay(100)
                .subscribe(function () {
                subscr_1.unsubscribe();
                _this.updatePopupHeight(_this.dialogOptions.loaRequest);
            });
            return;
        }
        this.updatePopupHeight(this.dialogOptions.loaRequest);
    };
    LMCreationDialogComponent.prototype.onRequestChange = function (req) {
        this.updatePopupHeight(req);
    };
    LMCreationDialogComponent.prototype.onAction = function (isSave) {
        if (isSave) {
            this.save();
        }
        else {
            this.cancel();
        }
    };
    LMCreationDialogComponent.prototype.save = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    LMCreationDialogComponent.prototype.cancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    LMCreationDialogComponent.prototype.updatePopupHeight = function (req) {
        var empId = req.empId;
        var container = this.containerElem.nativeElement;
        if (_.isFinite(empId) && _.isElement(container) && !screenUtils.isMobile) {
            var popup = $(container).closest('kendo-popup');
            if (popup.length > 0) {
                $(popup).css('height', this.height[req.requestClass] + this.extraHeightIndent);
            }
        }
    };
    return LMCreationDialogComponent;
}());
export { LMCreationDialogComponent };
