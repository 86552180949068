import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmDialogComponent, StateManagementService, ColumnManagementService, ConfirmOptions } from '../../../common/index';
import { destroyService, unsubscribe, mutableSelect } from '../../../core/decorators/index';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { appConfig } from '../../../app.config';
import { PayCodesConfigurationManagementService, AccrualBalanceNavigationService } from '../../services/index';
import { PayCodeModel, PayCode } from '../../models/index';
import { ColorUtil } from '../../../common/utils/index';
import { configurationConfig } from '../../configuration.config';
import { AppSettingsManageService } from '../../../app-settings/services';
var PayCodeConfigurationComponent = /** @class */ (function () {
    function PayCodeConfigurationComponent(management, stateManagement, modalService, router, route, columnManagementService, appSettingManageService) {
        var _this = this;
        this.stateManagement = stateManagement;
        this.modalService = modalService;
        this.router = router;
        this.route = route;
        this.columnManagementService = columnManagementService;
        this.columnGroup = 'PayCode';
        this.subscriptions = {};
        this.accrualTypesLookup = [];
        this.accrualPoliciesLookup = [];
        this.isNextgenPayrollEnabled = false;
        this.skip = 0;
        this.columnSettings = [];
        this.management = management;
        this.appSettingManageService = appSettingManageService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        // directive intercepted methods (scope bug)
        this.onAddItem = function () {
            var code = new PayCodeModel();
            code.payCode = new PayCode();
            _this.crudHelper.addItem(code);
        };
        this.onDeletedItems = function () {
            _this.crudHelper.deleteSelected();
        };
    }
    Object.defineProperty(PayCodeConfigurationComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCodeConfigurationComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    PayCodeConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
        });
        this.getSettings();
        this.bindPageLoadData();
        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: true,
            canEdit: true,
            canAdd: true,
            canDelete: true,
            canEditExceptionFlag: false,
            canEditPBJFlag: false,
            canEditUseInTimesheets: false,
            canEditAca: false,
            canEditPaycodestoAccrualMappingFlag: false,
            isSmartAdmin: false
        };
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
            if (_.has(state, 'bulkEditMode')) {
                if (state.bulkEditMode) {
                    if (_this.grid)
                        _this.gridState.closeEditor(_this.grid);
                }
                _this.state.bulkEditMode = state.bulkEditMode;
            }
            if (_.has(state, 'canBulkEdit'))
                _this.state.canBulkEdit = state.canBulkEdit;
            if (_.has(state, 'canEdit'))
                _this.state.canEdit = state.canEdit;
            if (_.has(state, 'canAdd'))
                _this.state.canAdd = state.canAdd;
            if (_.has(state, 'canDelete'))
                _this.state.canDelete = state.canDelete;
            if (_.has(state, 'orgLevelChanged')) {
                if (_this.grid)
                    _this.gridState.closeEditor(_this.grid);
            }
            if (_.has(state, 'canEditExceptionFlag'))
                _this.state.canEditExceptionFlag = state.canEditExceptionFlag;
            if (_.has(state, 'canEditPaycodestoAccrualMappingFlag'))
                _this.state.canEditPaycodestoAccrualMappingFlag = state.canEditPaycodestoAccrualMappingFlag;
            if (_.has(state, 'canEditExceptionFlag'))
                _this.state.canEditPBJFlag = state.canEditPBJFlag;
            if (_.has(state, 'canEditUseInTimesheets'))
                _this.state.canEditUseInTimesheets = state.canEditUseInTimesheets;
            if (_.has(state, 'canEditAca'))
                _this.state.canEditAca = state.canEditAca;
            if (_.has(state, 'isSmartAdmin'))
                _this.state.isSmartAdmin = state.isSmartAdmin;
        });
        // Later we will add updating per item, adding new pay codes and removing
        this.savedSubscription = this.management.onItemSaved$.subscribe(function (item) {
            _this.gridState.editedRecord = null;
            _this.gridState.savedEditedRecord = null;
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        /*
        this.removeSubscription = this.management.removeItemsCmd$.subscribe((request: RemovePositionRequest) => {
            if (request.requireReassign) {
    
                ConfigurePositionsReassignPopupComponent.openDialog(request, this.modalService, (result: boolean, reassingRequest: RemovePositionRequest) => {
                    if (result) {
                        this.management.doRemoveItem(request.itemToDelete, reassingRequest.ressignedPositionId, reassingRequest.effectiveDate);
                    }
                });
    
            } else {
    
                let options: ConfirmOptions = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                ConfirmDialogComponent.openDialog(
                    'Confirmation',
                    'Do you want to delete the position?',
                    this.modalService,
                    (result: boolean) => {
                        if (result) {
                            this.management.doRemoveItem(request.itemToDelete);
                        }
                    }, options);
            }
        });
        */
        this.subscriptions.export = this.management
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
        this.columnStateChangeSubscription = this.columnManagementService.columnsChanged$.filter(function (event) { return event.group === _this.columnGroup; })
            .subscribe(function (event) {
            _this.columnSettings = event.columns;
        });
    };
    PayCodeConfigurationComponent.prototype.isExport = function (property) {
        if (this.columnSettings && this.columnSettings.length > 0) {
            var state = _.find(this.columnSettings, function (c) { return c.name == property; });
            return state.displayed;
        }
        else {
            return true;
        }
    };
    PayCodeConfigurationComponent.prototype.bindPageLoadData = function () {
        var _this = this;
        this.getAccrualPolicy();
        this.getAccrualTypes();
        this.initServices();
        this.appConfig = appConfig;
        this.management.subscribeToBulkSaveResponse(function (redirectToAccrualBalances) {
            if (redirectToAccrualBalances) {
                _this.redirectToAccrualBalances();
            }
        });
    };
    PayCodeConfigurationComponent.prototype.onBulkEditClick = function () {
        this.management.doBulkEdit();
    };
    PayCodeConfigurationComponent.prototype.onBulkSaveClick = function () {
        this.management.doBulkSave();
    };
    PayCodeConfigurationComponent.prototype.onBulkDiscardClick = function () {
        this.management.doBulkDiscard();
    };
    PayCodeConfigurationComponent.prototype.handlePayCodeClick = function (event, id) {
        event.preventDefault();
        /*
        const url = `#`; //todo: put pay codes detail url when User Story 304135 is completed
        window.location.href = url;
        */
    };
    PayCodeConfigurationComponent.prototype.onItemFieldChange = function (item, field, value) {
        switch (field) {
            case 'isAca':
                item.payCode.isAca = value;
                break;
            case 'isPaid':
                item.payCode.isPaid = value;
                break;
            case 'isPbj':
                item.payCode.isPbj = value;
                break;
            case 'isException':
                item.payCode.isException = value;
                break;
            case 'isUsedInAccrualCalc':
                item.payCode.isUsedInAccrualCalc = value;
                break;
            case 'useInTimesheets':
                item.payCode.useInTimesheets = value;
                break;
        }
        this.management.setItemDirty(item);
    };
    PayCodeConfigurationComponent.prototype.onChangeMultiselectValue = function (item, evnt) {
        var optionsIds = _.map(evnt, function (opt) { return opt.policyName; });
        item.payCode.accrualPolicies = optionsIds;
        item.payCode.accrualPolicyNames = ((_.isNull(optionsIds) || (optionsIds && optionsIds.length == 0)) && item.payCode.isUsedInAccrualCalc) ? 'All' : _.join(optionsIds, ', ');
        item.payCode.accrualPolicyList = evnt;
        item.isDirty = true;
    };
    PayCodeConfigurationComponent.prototype.onChangeAccrualType = function (item, evnt) {
        if (item.payCode.accrualType != evnt.accrualTypeName) {
            item.payCode.accrualType = evnt.accrualTypeName;
            item.isDirty = true;
        }
    };
    PayCodeConfigurationComponent.prototype.onColorPickerChange = function (payCode, colorCode) {
        if (payCode)
            payCode.payCode.color = ColorUtil.HexToDec(colorCode);
    };
    PayCodeConfigurationComponent.prototype.getColor = function (payCode) {
        return payCode && payCode.payCode ? ColorUtil.DecToHexString(payCode.payCode.color, true) : '#ffffff';
    };
    PayCodeConfigurationComponent.prototype.rowCallback = function (context) {
        if (context.dataItem.isDirty) {
            return { 'dirty-item': true };
        }
        return {};
    };
    PayCodeConfigurationComponent.prototype.onClickExport = function (isPDF) {
        this.management.exportTo(isPDF);
    };
    PayCodeConfigurationComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    PayCodeConfigurationComponent.prototype.initServices = function () {
        this.stateManagement.init('PayCodeConfigurationComponent');
        this.columnManagementService.init('PayCodeConfigurationComponent');
        this.columnManagementService.initGroup(this.columnGroup, 12);
    };
    PayCodeConfigurationComponent.prototype.redirectToAccrualBalances = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showOK = true;
        options.showCancel = true;
        options.buttonOKtext = 'Yes';
        options.buttonCanceltext = 'No';
        ConfirmDialogComponent.openDialog('Warning', 'The changes made could impact the accrual balances of your employees. Would you like to proceed to the accrual balances screen and recalculate the balances?', this.modalService, function (result) {
            if (result) {
                var navService = new AccrualBalanceNavigationService(_this.router, _this.route);
                navService.NavigateToAccrualBalance();
            }
        }, options);
    };
    PayCodeConfigurationComponent.prototype.getAccrualPolicy = function () {
        var _this = this;
        if (this.orgLevelId) {
            this.management.getAccrualPolicies(this.orgLevelId)
                .then(function (accrualPolicies) {
                _this.accrualPoliciesLookup = accrualPolicies;
            });
        }
    };
    PayCodeConfigurationComponent.prototype.getAccrualTypes = function () {
        var _this = this;
        if (this.orgLevelId) {
            this.management.getAccrualTypes(this.orgLevelId)
                .then(function (accrualTypes) {
                var accrualEmptyType = [{ accrualTypeId: null, accrualTypeName: '' }];
                _this.accrualTypesLookup = accrualEmptyType.concat(accrualTypes);
            });
        }
    };
    PayCodeConfigurationComponent.prototype.canEditAcaCheck = function (dataItem) {
        if ((dataItem.payCode.allocationTypeId == 1 || dataItem.payCode.allocationTypeId == 4) && dataItem.payCode.isPaid == true) {
            return true;
        }
        else if (dataItem.payCode.groupId == 4 && dataItem.payCode.isPaid == true) {
            return true;
        }
        else {
            return false;
        }
    };
    PayCodeConfigurationComponent.prototype.canDeductFrom = function (payCode) {
        return _.some(configurationConfig.deductFromGroups, function (group) { return payCode.payCode.group === group; });
    };
    PayCodeConfigurationComponent.prototype.getSettings = function () {
        var _this = this;
        this.appSettingManageService.getAppServerConfig().then(function (conf) {
            _this.isNextgenPayrollEnabled = conf.IsNextgenPayrollEnabled;
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodeConfigurationComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PayCodesConfigurationManagementService)
    ], PayCodeConfigurationComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodeConfigurationComponent.prototype, "columnStateChangeSubscription", void 0);
    return PayCodeConfigurationComponent;
}());
export { PayCodeConfigurationComponent };
