import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { UrlParamsService } from '../url-params/url-params.service';
import { ApiUtilService } from '../api/api-util.service';

import { ResponseBody, Meta, FieldsMeta } from '../../../core/models/index';
import { ReadFile } from '../../../organization/models/index';
import { SlxHttpRequest } from '../../../core/models/index';

@Injectable()
export class AttachmentsUploaderHelperService {
  constructor (
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService
  ) {}

  public async uploadAttachmentsAsFormData<D, M extends Meta>(url: string, files: ReadFile[], params?: StringMap<any>): Promise<ResponseBody<D, M>> {
    this.handleErrors(url, files);

    let promise: Promise<ResponseBody<D, M>> = Promise.resolve(null);

    _.forEach(files, (file) => {
      const request = this.createRequest(url, file, params);

      promise = promise
        .then(() => this.apiUtilService.requestNew<D, M>(request));
    });

    return promise;
  }

  private createRequest(url: string, file: ReadFile, params?: StringMap<any>): SlxHttpRequest<any> {
    const formData = this.mapFilesToFormData(file);
    const request = this.urlParamsService.requestPost(url, formData, params);
    request.autoContentType = true;

    return request;
  }

  private mapFilesToFormData(file: ReadFile): FormData {
    const formData: FormData = new FormData();
    formData.append('i0_name', file.name);
    formData.append('i0_fileName', file.fileName);
    formData.append('i0_category', file.mimeType);
    formData.append('i0_file', file.data, file.fileName);

    return formData;
  }

  private handleErrors(url: string, files: ReadFile[]): void {
    if (!_.isString(url) || _.size(url) === 0) {
      throw new TypeError(`AttachmentsUploaderHelperService: parameter "url" is not a string or empty string. url: ${ String(url) }`);
    }

    if (!_.isArray(files) || _.size(files) === 0) {
      throw new TypeError(`AttachmentsUploaderHelperService: parameter "files" is not an array or empty array. files: ${ String(files) }`);
    }
  }
}
