import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { DateRange } from '../../../core/models/index';
import { AccessibleApiService } from '../../../organization';
import { Subject, Observable } from 'rxjs';
import { Assert } from '../../../framework';
import { unsubscribeAll, mutableSelect } from '../../../core/decorators';
import { TempSensorReadingApiService } from './temp-sensor-reading-api.service';
import * as moment from 'moment';
import * as _ from 'lodash';
var TempSensorReadingManagementService = /** @class */ (function () {
    function TempSensorReadingManagementService(accessibleApiService, apiService) {
        this.accessibleApiService = accessibleApiService;
        this.apiService = apiService;
        this.loading$ = new Subject();
        this.recordsLoaded$ = new Subject();
        this.dateRange$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.userActonsChanged$ = new ReplaySubject(1);
        this.exportTo$ = new Subject();
        this.subscriptions = {};
    }
    TempSensorReadingManagementService.prototype.init = function () {
        this.subscribeToOrgLevelChanges();
    };
    TempSensorReadingManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.dateRange$.complete();
        this.exportTo$.complete();
        this.orgLevelChanged$.complete();
    };
    TempSensorReadingManagementService.prototype.getDefaultDateRange = function () {
        var d = new Date();
        return new DateRange(moment().add(-2, 'weeks').toDate(), moment().toDate());
    };
    TempSensorReadingManagementService.prototype.changeDateRange = function (r) {
        this.dateRange = r;
        this.dateRange$.next(r);
        this.loadTempReadings();
    };
    TempSensorReadingManagementService.prototype.subscribeToDateRange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.dateRange$.subscribe(callback);
    };
    TempSensorReadingManagementService.prototype.exportTo = function (event) {
        this.exportTo$.next(event);
    };
    TempSensorReadingManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    TempSensorReadingManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    TempSensorReadingManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    TempSensorReadingManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    TempSensorReadingManagementService.prototype.loadTempReadings = function () {
        var _this = this;
        if (!_.isDate(_.get(this.dateRange, 'startDate'))
            || !_.isDate(_.get(this.dateRange, 'endDate')))
            return;
        this.loading$.next(true);
        this.apiService.getTimeSensorReadings(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
            .then(function (tempReadings) {
            _this.recordsLoaded$.next(tempReadings);
            _this.loading$.next(false);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    TempSensorReadingManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
            _this.loadTempReadings();
            _this.loadUserActions(_this.orgLevel.id);
        });
    };
    TempSensorReadingManagementService.prototype.subscribeToUserActionsChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.userActonsChanged$.subscribe(callback);
    };
    TempSensorReadingManagementService.prototype.loadUserActions = function (orgLevelId) {
        var _this = this;
        this.accessibleApiService.getUserActions(orgLevelId, ['Export to Excel'])
            .then(function (data) {
            _this.userActonsChanged$.next(data);
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], TempSensorReadingManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], TempSensorReadingManagementService.prototype, "subscriptions", void 0);
    return TempSensorReadingManagementService;
}());
export { TempSensorReadingManagementService };
