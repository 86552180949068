/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-details-eligibility-statements-clause-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../benefit-details-eligibility-statements-clause/benefit-details-eligibility-statements-clause.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../benefit-details-eligibility-statements-clause/benefit-details-eligibility-statements-clause.component";
import * as i5 from "../../../services/benefit-eligibility-rules/benefit-eligibility-rule-statements-management.service";
import * as i6 from "./benefit-details-eligibility-statements-clause-group.component";
import * as i7 from "@angular/common";
var styles_BenefitDetailsEligibilityStatementsClauseGroupComponent = [i0.styles];
var RenderType_BenefitDetailsEligibilityStatementsClauseGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitDetailsEligibilityStatementsClauseGroupComponent, data: {} });
export { RenderType_BenefitDetailsEligibilityStatementsClauseGroupComponent as RenderType_BenefitDetailsEligibilityStatementsClauseGroupComponent };
function View_BenefitDetailsEligibilityStatementsClauseGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "slx-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "slx-p-0"]], [[2, "slx-tiers-count-1", null], [2, "slx-tiers-count-2", null], [2, "slx-tiers-count-3", null], [2, "slx-tiers-count-4", null], [2, "slx-tiers-count-5", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "slx-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "slx-benefit-details-eligibility-statements-clause", [], null, [[null, "ruleVariableChanged"], [null, "conditionTypeChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ruleVariableChanged" === en)) {
        var pd_0 = (_co.onRuleVariableChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("conditionTypeChanged" === en)) {
        var pd_1 = (_co.onConditionTypeChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_BenefitDetailsEligibilityStatementsClauseComponent_0, i2.RenderType_BenefitDetailsEligibilityStatementsClauseComponent)), i1.ɵprd(14336, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(5, 4767744, null, 0, i4.BenefitDetailsEligibilityStatementsClauseComponent, [i5.BenefitEligibilityRuleStatementsManagementService], { ruleStatementSequence: [0, "ruleStatementSequence"], ruleVariable: [1, "ruleVariable"], conditionType: [2, "conditionType"], configurationVariables: [3, "configurationVariables"], configurationPredicates: [4, "configurationPredicates"], benefitClasses: [5, "benefitClasses"], isFirstItem: [6, "isFirstItem"] }, { ruleVariableChanged: "ruleVariableChanged", conditionTypeChanged: "conditionTypeChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.ruleStatementGroupItem.sequence; var currVal_6 = _co.ruleStatementGroupItem.variable; var currVal_7 = _co.ruleStatementGroupItem.conditionType; var currVal_8 = _co.configurationVariables; var currVal_9 = _co.configurationPredicates; var currVal_10 = _co.benefitClasses; var currVal_11 = _co.isFirstItem; _ck(_v, 5, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tiersGroupCount === 1); var currVal_1 = (_co.tiersGroupCount === 2); var currVal_2 = (_co.tiersGroupCount === 3); var currVal_3 = (_co.tiersGroupCount === 4); var currVal_4 = (_co.tiersGroupCount === 4); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
function View_BenefitDetailsEligibilityStatementsClauseGroupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "slx-benefit-details-eligibility-statements-clause-group", [], null, [[null, "ruleStatementGroupItemChanged"], [null, "ruleStatementUngrouped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ruleStatementGroupItemChanged" === en)) {
        var pd_0 = (_co.onRuleStatementGroupItemChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("ruleStatementUngrouped" === en)) {
        var pd_1 = (_co.onRuleStatementUngrouped($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_BenefitDetailsEligibilityStatementsClauseGroupComponent_0, RenderType_BenefitDetailsEligibilityStatementsClauseGroupComponent)), i1.ɵprd(14336, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(3, 49152, null, 0, i6.BenefitDetailsEligibilityStatementsClauseGroupComponent, [], { ruleStatementGroupItem: [0, "ruleStatementGroupItem"], configurationVariables: [1, "configurationVariables"], configurationPredicates: [2, "configurationPredicates"], tiersGroupCount: [3, "tiersGroupCount"], currentGroupLevel: [4, "currentGroupLevel"], benefitClasses: [5, "benefitClasses"], isFirstItem: [6, "isFirstItem"] }, { ruleStatementGroupItemChanged: "ruleStatementGroupItemChanged", ruleStatementUngrouped: "ruleStatementUngrouped" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.configurationVariables; var currVal_2 = _co.configurationPredicates; var currVal_3 = (_co.tiersGroupCount - 1); var currVal_4 = (_co.currentGroupLevel - 1); var currVal_5 = _co.benefitClasses; var currVal_6 = (_co.isFirstItem && _v.context.first); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_BenefitDetailsEligibilityStatementsClauseGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "slx-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "slx-col-auto slx-p-0 slx-ungroup-icon slx-tiers-count-1"], ["title", "Ungroup clauses"]], [[4, "background-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRuleStatementUngrouped(_co.ruleStatementGroupItem) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A0 "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsEligibilityStatementsClauseGroupComponent_3)), i1.ɵdid(5, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.ruleStatementGroupItem.groups; var currVal_2 = _co.trackByItem; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getGroupColor(_co.currentGroupLevel); _ck(_v, 1, 0, currVal_0); }); }
export function View_BenefitDetailsEligibilityStatementsClauseGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsEligibilityStatementsClauseGroupComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsEligibilityStatementsClauseGroupComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.ruleStatementGroupItem == null) ? null : _co.ruleStatementGroupItem.variable); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.ruleStatementGroupItem == null) ? null : _co.ruleStatementGroupItem.groups); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BenefitDetailsEligibilityStatementsClauseGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-benefit-details-eligibility-statements-clause-group", [], null, null, null, View_BenefitDetailsEligibilityStatementsClauseGroupComponent_0, RenderType_BenefitDetailsEligibilityStatementsClauseGroupComponent)), i1.ɵprd(14336, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(2, 49152, null, 0, i6.BenefitDetailsEligibilityStatementsClauseGroupComponent, [], null, null)], null, null); }
var BenefitDetailsEligibilityStatementsClauseGroupComponentNgFactory = i1.ɵccf("slx-benefit-details-eligibility-statements-clause-group", i6.BenefitDetailsEligibilityStatementsClauseGroupComponent, View_BenefitDetailsEligibilityStatementsClauseGroupComponent_Host_0, { ruleStatementGroupItem: "ruleStatementGroupItem", configurationVariables: "configurationVariables", configurationPredicates: "configurationPredicates", tiersGroupCount: "tiersGroupCount", currentGroupLevel: "currentGroupLevel", benefitClasses: "benefitClasses", isFirstItem: "isFirstItem" }, { ruleStatementGroupItemChanged: "ruleStatementGroupItemChanged", ruleStatementUngrouped: "ruleStatementUngrouped" }, []);
export { BenefitDetailsEligibilityStatementsClauseGroupComponentNgFactory as BenefitDetailsEligibilityStatementsClauseGroupComponentNgFactory };
