var FileBlobResponse = /** @class */ (function () {
    function FileBlobResponse() {
    }
    Object.defineProperty(FileBlobResponse.prototype, "file", {
        get: function () {
            if (!this.fileExtension) {
                return this.filename;
            }
            return this.filename + "." + this.fileExtension;
        },
        enumerable: true,
        configurable: true
    });
    return FileBlobResponse;
}());
export { FileBlobResponse };
