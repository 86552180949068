export * from './projected-hours-toolbar/projected-hours-toolbar.component';
export * from './projected-hours.component';


import { ProjectedHoursToolbarComponent } from './projected-hours-toolbar/projected-hours-toolbar.component';
import { ProjectedHoursComponent } from './projected-hours.component';

export const projectedHoursComponents: any[] = [
    ProjectedHoursToolbarComponent,
    ProjectedHoursComponent
]