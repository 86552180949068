export * from './employee-list/employee-list.component';
export * from './group-sms/group-sms.component';
export * from './employee-grid/employee-grid.component';
export * from './latenesses/latenesses.component';

export * from './schedule-comments/schedule-comments.component';
export * from './projected-hours/index';
export * from './assign-pay-policy/assign-pay-policy.component';
export * from './assign-accruals-policy/assign-accruals-policy.component';
export * from './create-ess-policy/create-ess-policy.component';
export * from './assign-pay-cycles/assign-pay-cycles.component';
export * from './operational-console-dialog/operational-console-dialog.component';
export * from './direct-care-hours/index';
export * from './assign-accruals-policy/dynamic-policy-dialog/dynamic-policy-dialog.component';
export * from './resend-opt-sms/resend-opt-sms.component';
export * from './send-email-instructions/send-email-instructions.component';
export * from './slate-profile-assignment/slate-profile-assignment.component';
export * from './mass-rate-adjustments/mass-rate-adjustments.component';

import { EmployeeListComponent } from './employee-list/employee-list.component';
import { GroupSmsComponent } from './group-sms/group-sms.component';
import { EmployeeGridComponent } from './employee-grid/employee-grid.component';
import { LatenessesComponent } from './latenesses/latenesses.component';
import { ScheduleCommentsComponent } from './schedule-comments/schedule-comments.component';
import { ProjectedHoursComponent } from './projected-hours/projected-hours.component';
import { AssignPayPolicyComponent } from './assign-pay-policy/assign-pay-policy.component';
import { DynamicPolicyDialogComponent } from './assign-accruals-policy/dynamic-policy-dialog/dynamic-policy-dialog.component';
import { AssignAccrualsPolicyComponent } from './assign-accruals-policy/assign-accruals-policy.component';
import { CreateESSPolicyComponent } from './create-ess-policy/create-ess-policy.component';
import { AssignPayCyclesComponent } from './assign-pay-cycles/assign-pay-cycles.component';
import { OperationalConsoleDialogComponent } from './operational-console-dialog/operational-console-dialog.component';
import { directCareComponets } from './direct-care-hours/index';
import { projectedHoursComponents } from './projected-hours/index';
import { ResendOptSmsComponent } from './resend-opt-sms/resend-opt-sms.component';
import { SendEmailInstructionsComponent } from './send-email-instructions/send-email-instructions.component';
import { SlateProfileAssignmentComponent } from './slate-profile-assignment/slate-profile-assignment.component'
import { MassRateAdjustmentsDialogComponent } from './mass-rate-adjustments/mass-rate-adjustments.component';

export const components: any[] = [
  EmployeeListComponent,
  GroupSmsComponent,
  EmployeeGridComponent,
  LatenessesComponent,
  ScheduleCommentsComponent,
  ProjectedHoursComponent,
  AssignPayPolicyComponent,
  CreateESSPolicyComponent,
  AssignPayCyclesComponent,
  AssignAccrualsPolicyComponent,
  DynamicPolicyDialogComponent,
  OperationalConsoleDialogComponent,
  ResendOptSmsComponent,
  SendEmailInstructionsComponent,
  SlateProfileAssignmentComponent,
  MassRateAdjustmentsDialogComponent,
  ...directCareComponets,
  ...projectedHoursComponents
];

export const exportComponents: any[] = [
  EmployeeListComponent,
  GroupSmsComponent,
  LatenessesComponent,
  ScheduleCommentsComponent,
  ProjectedHoursComponent,
  AssignPayPolicyComponent,
  AssignAccrualsPolicyComponent,
  CreateESSPolicyComponent,
  AssignPayCyclesComponent,
  EmployeeGridComponent,
  DynamicPolicyDialogComponent,
  OperationalConsoleDialogComponent,
  ResendOptSmsComponent,
  SendEmailInstructionsComponent,
  SlateProfileAssignmentComponent,
  MassRateAdjustmentsDialogComponent,
  ...directCareComponets,
  ...projectedHoursComponents
];
