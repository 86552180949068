import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';

export const requireDecimalMultiValidationKey = 'requireDecimalMulti';

export function requireDecimalMultiValidator(): ValidatorFn {
  const validationPattern = new RegExp(/^\d+(\.\d+)?(([\w\s]?)[,]([\w\s]?)\d+(\.\d+)?)*$/);

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = _.trim(control.value);

    const isValid = validationPattern.test(value);
    return !isValid ? { [requireDecimalMultiValidationKey]: true } : null;
  };

  return validator;
}
