import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, Provider } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsRotationsSettingsComponent } from '../employee-sections-rotations-settings/employee-sections-rotations-settings.component';
import { ModalService, ScheduleEntryNavigationService, EmployeeSectionNavigationService } from '../../../../../common/services/index';
import { ApplicationStateBusService } from '../../../../../organization/services/index';
import { DialogOptions } from '../../../../../common/models/index';
import { EmployeeSectionsRotations, ConfigureRotationsRequest, RotationModifyRequest } from '../../../models/index';
import { EmployeeSectionsScheduleApiService, WeekDayService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { RotationModifyDialogComponent } from '../rotation-modify-dialog/rotation-modify-dialog.component';
import { LookupApiService } from '../../../../../organization/services/lookup/lookup-api.service';
import { employeeRotationsUiState } from './employee-sections-rotations.component.ui-state';
import { Position, LocationUnit, ShiftDefinition, ConstraintDefinition, EmployeeShift, EmployeePositionDefinition, ScheduleCycleScope } from '../../../../../organization/models/index';
import * as _ from 'lodash';
import { EmployeePositionSelectorComponent } from '../../../../employee/components/employee-position-selector/employee-position-selector.component';
import { ScheduleCycleSummaryDialogComponent } from '../../../../../organization/components/index';
var EmployeeSectionsRotationsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsRotationsComponent, _super);
    function EmployeeSectionsRotationsComponent(employeeSectionsScheduleApiService, router, activatedRoute, weekDayService, lookupApiService, applicationStateBusService, modalService, decorator, ngZone) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.router = router;
        _this.activatedRoute = activatedRoute;
        _this.applicationStateBusService = applicationStateBusService;
        _this.loadHomeValues = false;
        _this.shiftLookupMapByPositionId = {};
        _this.unitLookupMapByPositionId = {};
        _this.constraintLookupMapByPositionId = {};
        Assert.isNotNull(employeeSectionsScheduleApiService, 'employeeSectionsScheduleApiService');
        _this.employeeSectionsScheduleApiService = employeeSectionsScheduleApiService;
        Assert.isNotNull(weekDayService, 'weekDayService');
        _this.weekDayService = weekDayService;
        _this.modalService = modalService;
        _this.selectedEmployeeShift = {
            isValid: false,
            constraint: undefined,
            position: undefined,
            shift: undefined,
            unit: undefined,
            absence: undefined,
            isDirty: false
        };
        return _this;
    }
    Object.defineProperty(EmployeeSectionsRotationsComponent.prototype, "employeeSubsectionRotations", {
        set: function (rotationsSection) {
            var _this = this;
            this.rotationsExists = false;
            if (rotationsSection !== null && rotationsSection !== undefined) {
                this.rotationsSection = rotationsSection;
                this.rotations = rotationsSection.rotations;
                if (this.rotations !== null && this.rotations !== undefined) {
                    this.rotationsExists = this.rotations.length > 0;
                    this
                        .rotations
                        .forEach(function (r) { return _this.weekDayService.prepareRotation(r); });
                    this.selectedRotation = this
                        .rotationsSection
                        .rotations
                        .find(function (r) { return r.isCurrent; });
                    this.weekDaysRibbon = this
                        .weekDayService
                        .getWeekDaysByRotation(this.selectedRotation);
                }
                this.setCurrentRotationUiState();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsRotationsComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsRotationsComponent.prototype, "selectedRotation", {
        get: function () {
            return this.selectedRotationPrototype;
        },
        set: function (val) {
            this.selectedRotationPrototype = val;
            this.selectedRotationClone = _.cloneDeep(val);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsRotationsComponent.prototype, "isValid", {
        get: function () {
            if (this.selectedRotation !== null && this.selectedRotation !== undefined && this.selectedRotation.weeklyRotations !== null && this.selectedRotation.weeklyRotations !== undefined) {
                for (var i = 0; i < this.selectedRotation.weeklyRotations.length; i++) {
                    if (this.selectedRotation.weeklyRotations[i].dailyRecords !== null && this.selectedRotation.weeklyRotations[i].dailyRecords !== undefined) {
                        if (this.selectedRotation.weeklyRotations[i].dailyRecords.some(function (r) { return !r.isValid; }))
                            return false;
                    }
                }
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsRotationsComponent.prototype.getSubsectionModel = function () {
        return this.rotationsSection;
    };
    EmployeeSectionsRotationsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.uiStates = employeeRotationsUiState;
    };
    EmployeeSectionsRotationsComponent.prototype.onAcceptShiftTemplateClicked = function () {
        this.setEditRotationsUiState();
    };
    EmployeeSectionsRotationsComponent.prototype.selectedRotaionChanged = function () {
        if (this.selectedRotation.isCurrent) {
            this.setCurrentRotationUiState();
        }
        else {
            this.setHistoricalUiState();
        }
    };
    EmployeeSectionsRotationsComponent.prototype.onEditRotationConfigClicked = function () {
        var _this = this;
        this.setEditSettingsUiState();
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 420;
        dialogOptions.width = 650;
        var request = new ConfigureRotationsRequest();
        request.employeeId = this.employeeId;
        request.homePositionId = this.rotationsSection.homePositionId;
        request.homeShiftId = this.rotationsSection.homeShiftId;
        request.homeUnitId = this.rotationsSection.homeUnitId;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            }, {
                provide: ConfigureRotationsRequest,
                useValue: request
            }
        ];
        var dialog = this
            .modalService.globalAnchor
            .openDialog(EmployeeSectionsRotationsSettingsComponent, 'Rotation settings', dialogOptions, resolvedProviders, function (result, uniqueId) {
            if (result) {
                var rotationTemplate = dialog.selectedRotationTemplate;
                if (rotationTemplate) {
                    _this.selectedEmployeeShift = rotationTemplate.employeeShift;
                    _this.doGenerateRotations(_this.employeeId, rotationTemplate);
                }
            }
        });
    };
    EmployeeSectionsRotationsComponent.prototype.onEditShiftTemplateClicked = function () {
        this.setEditShiftTemplateUiState();
    };
    EmployeeSectionsRotationsComponent.prototype.onImportRotationsClicked = function () {
        var _this = this;
        if (!this.employeesWithSamePosition) {
            this
                .employeeSectionsScheduleApiService
                .getImportEmployees(this.employeeId)
                .then(function (positions) {
                _this.employeesWithSamePosition = positions;
                _this.openSelectEmployeePositionDialog(positions);
            });
        }
        else {
            this.openSelectEmployeePositionDialog(this.employeesWithSamePosition);
        }
    };
    EmployeeSectionsRotationsComponent.prototype.onCancelClicked = function () {
        var indexOfCurrent = this
            .rotations
            .indexOf(this.selectedRotation);
        this.rotations[indexOfCurrent] = this.selectedRotationClone;
        this.selectedRotation = this.selectedRotationClone;
        this.setCurrentRotationUiState();
    };
    EmployeeSectionsRotationsComponent.prototype.onRestoreRotationClicked = function () {
        this.doRestoreRotation(this.employeeId, this.selectedRotation);
    };
    EmployeeSectionsRotationsComponent.prototype.onAcceptClicked = function () {
        var _this = this;
        if (!this.isValid) {
            this.modalService.globalAnchor
                .openConfirmDialog('Confirmation', 'Some of rotation records have overlapped shifts. Are you sure wish to save this ' +
                'Rotations?', function (result) {
                if (result) {
                    _this.askAndModify(_this.employeeId, _this.selectedRotation);
                }
            });
        }
        else {
            this.askAndModify(this.employeeId, this.selectedRotation);
        }
    };
    EmployeeSectionsRotationsComponent.prototype.onRequiredFieldsPopulated = function () {
        this.setEditRotationsUiState();
    };
    EmployeeSectionsRotationsComponent.prototype.loadSubsection = function () {
        this.loadSubsectionDelegate();
    };
    EmployeeSectionsRotationsComponent.prototype.loadSubsectionDelegate = function () {
        var _this = this;
        this.startProgress();
        return this
            .employeeSectionsScheduleApiService
            .getRotations(this.employeeId)
            .then(function (rotationsSubsection) {
            _this.employeeSubsectionRotations = rotationsSubsection;
            _this.state.isLoaded = true;
            _this.stopProgress();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsRotationsComponent.prototype.openSelectEmployeePositionDialog = function (employeePositions) {
        var _this = this;
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 600;
        dialogOptions.width = 800;
        var dialog = this
            .modalService.globalAnchor
            .openDialog(EmployeePositionSelectorComponent, 'Select the employee rotation that you wish to import.', dialogOptions, undefined, function (result) {
            if (result) {
                var selectedItems = dialog.selectedItems;
                if (selectedItems.length !== 1) {
                    throw new Error('Employee positions count not equals to 1.');
                }
                var selectedEmployeePosition = selectedItems[0];
                _this.doImport(_this.employeeId, selectedEmployeePosition.employee.id);
            }
        });
        dialog.isSingleItemSelectable = true;
        dialog.employeePositions = employeePositions;
    };
    EmployeeSectionsRotationsComponent.prototype.setCurrentRotationUiState = function () {
        this.uiState = employeeRotationsUiState.currentRotationViewState;
    };
    EmployeeSectionsRotationsComponent.prototype.setHistoricalUiState = function () {
        this.uiState = employeeRotationsUiState.historicalViewState;
    };
    EmployeeSectionsRotationsComponent.prototype.setEditSettingsUiState = function () {
        this.uiState = employeeRotationsUiState.editSettingsState;
    };
    EmployeeSectionsRotationsComponent.prototype.setEditShiftTemplateUiState = function () {
        this.uiState = employeeRotationsUiState.editShiftTemplateState;
    };
    EmployeeSectionsRotationsComponent.prototype.setEditRotationsUiState = function () {
        this.uiState = employeeRotationsUiState.editRotationsState;
    };
    EmployeeSectionsRotationsComponent.prototype.askAndModify = function (employeeId, rotation) {
        var _this = this;
        var req = new RotationModifyRequest();
        req.rotation = rotation;
        req.regenerateScope = ScheduleCycleScope.currentScheduleCycle;
        req.homePositionOrgLevelId = this.employeeShortInfo.position.orgLevelId;
        RotationModifyDialogComponent.openDialog(req, this.modalService, function (result, res) {
            if (result) {
                _this.doModify(employeeId, res);
            }
        });
    };
    EmployeeSectionsRotationsComponent.prototype.doModify = function (employeeId, req) {
        var _this = this;
        this.startProgress();
        this
            .employeeSectionsScheduleApiService
            .setRotations(employeeId, req)
            .then(function (result) {
            _this.stopProgress();
            _this.loadSubsection();
            _this.generateScheduleSummary = null;
            if (result.reGenerated) {
                _this.applicationStateBusService.resetCache('TimecardsApiServiceCache');
                _this.generateScheduleSummary = result.generateScheduleMessages;
                if (_this.generateScheduleSummary.messages && _this.generateScheduleSummary.messages.length > 0) {
                    _this.showSummary();
                }
                else {
                    _this.modalService.globalAnchor
                        .openInfoDialog('Operation completed', 'Employee schedule was re-generated successfully', function (result) {
                        //do nothing
                    });
                }
            }
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsRotationsComponent.prototype.doRestoreRotation = function (employeeId, rotation) {
        var _this = this;
        this.startProgress();
        this
            .employeeSectionsScheduleApiService
            .restoreRotations(employeeId, rotation)
            .then(function (result) {
            _this.loadHomeValues = false;
            _this.stopProgress();
            _this.loadSubsection();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsRotationsComponent.prototype.doGenerateRotations = function (employeeId, rotationTemplate) {
        var _this = this;
        this.startProgress();
        this
            .employeeSectionsScheduleApiService
            .generateRotations(employeeId, rotationTemplate)
            .then(function (value) {
            _this.stopProgress();
            _this.loadSubsectionDelegate().then(function () {
                if (rotationTemplate.rotationsCount && rotationTemplate.rotationsCount > 0) {
                    _this.setEditShiftTemplateUiState();
                }
            });
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsRotationsComponent.prototype.doImport = function (employeeId, rotationsEmployeeId) {
        var _this = this;
        this.startProgress();
        this
            .employeeSectionsScheduleApiService
            .importRotations(employeeId, rotationsEmployeeId)
            .then(function (value) {
            _this.stopProgress();
            _this.loadSubsection();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsRotationsComponent.prototype.showSummary = function () {
        var _this = this;
        if (this.generateScheduleSummary) {
            ScheduleCycleSummaryDialogComponent.openDialog(this.generateScheduleSummary, this.modalService, function (action) {
                if (!action) {
                    return;
                }
                if (action.action === 'NavigateToEmployee') {
                    var es = new EmployeeSectionNavigationService(_this.router, _this.activatedRoute);
                    es.NavigateToEmployeeSections(action.employeeId, false);
                }
                if (action.action === 'NavigateToScheduleEntry') {
                    var se = new ScheduleEntryNavigationService(_this.router, _this.activatedRoute);
                    se.NavigateToScheduleEntry(action.employeeId, action.date);
                }
            });
        }
    };
    return EmployeeSectionsRotationsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsRotationsComponent };
