import { LoaAttachment } from '../../../organization/models/index';
var LoaFile = /** @class */ (function () {
    function LoaFile(sourceItem) {
        this.sourceItem = sourceItem;
        this.isAttachment = this.sourceItem instanceof LoaAttachment;
    }
    Object.defineProperty(LoaFile.prototype, "id", {
        get: function () {
            return this.isAttachment ? this.sourceItem.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaFile.prototype, "name", {
        get: function () {
            return this.sourceItem.fileName;
        },
        enumerable: true,
        configurable: true
    });
    return LoaFile;
}());
export { LoaFile };
