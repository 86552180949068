<kendo-tabstrip *ngIf="isDesktop" class="slx-line-tabs benefit-tabs" (tabSelect)="onSelectTab($event)">
  <kendo-tabstrip-tab
    *ngFor="let group of groups"
    [selected]="isSelectedGroup(group)"
    [disabled]="isEditMode"
  >
    <ng-template kendoTabTitle>
      <i aria-hidden="true" class="slx-line-tabs__icon" [ngClass]="getIconClass(group.type)"></i>
      <em class="slx-line-tabs__text">{{ group.title.replace('401k', '401(k)') }}</em>
      <i class="fas fa-sort-down selected-arrow" *ngIf="isSelectedGroup(group)"></i>
    </ng-template>
    <ng-template kendoTabContent>
      <slx-benefit-details-standart *ngIf="isStandartGroup(group)"></slx-benefit-details-standart>
      <!-- <slx-benefit-type-cobra *ngIf="isCobraGroup(group)"></slx-benefit-type-cobra>
      <slx-benefit-type-tuition *ngIf="isTuitionGroup(group)"></slx-benefit-type-tuition> -->
      <p *ngIf="isUndefinedGroup(group)">Benefit Group did not found</p>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>
<div class="benefit-tabs-mobile" *ngIf="!isDesktop" >
  <slx-dropdown-list class="slx-wide benefit-tabs-mobile__providers"
    valueField="id"
    titleField="name"
    [options]="groups"
    [readonly]="isEditMode"
    [(ngModel)]="selectedGroup"
    (ngModelChange)="onSelectedGroup()"
  ></slx-dropdown-list>
  <slx-benefit-details-standart *ngIf="isStandartGroup(selectedGroup)"></slx-benefit-details-standart>
  <p *ngIf="isUndefinedGroup(selectedGroup)">Benefit Group did not found</p>
</div>
