import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor } from '../../../common/utils/index';
import { TextEditorThemes, TextEditorOptions } from '../../models/index';
import { appConfig, IApplicationConfig } from '../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-text-editor',
  templateUrl: 'text-editor.component.html',
  providers: [createValuAccessor(TextEditorComponent)]
})
export class TextEditorComponent implements ControlValueAccessor {
  @Input('options')
  public set incomingOptions(value: TextEditorOptions) {
    if (_.isObject(value)) {
      this.options = _.assign(new TextEditorOptions(), this.defaultOptions, value);
    }
  }

  @Input()
  public disabled: boolean;

  @Output()
  public onTextChange: EventEmitter<string>;

  public options: TextEditorOptions;
  public defaultOptions: TextEditorOptions;

  private appConfig: IApplicationConfig;
  private m_innerHtml: string;
  private onChangeCallback: (val: any) => void = _.noop;
  private onTouchedCallback: () => void = _.noop;

  constructor() {
    this.appConfig = appConfig;
    this.onTextChange = new EventEmitter<string>();
    this.defaultOptions = this.createDefaultOptions();
  }

  public set innerHtml(value: string) {
    if (_.isString(value) && value !== this.innerHtml) {
      this.m_innerHtml = value;
      this.onTextChange.emit(value);
    }
  }

  public get innerHtml(): string {
    return this.m_innerHtml;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public writeValue(value?: any): void {
    this.innerHtml = value;
  }

  public onValueChanged(value: boolean): void {
    this.onChangeCallback(value);
  }

  private createDefaultOptions(): TextEditorOptions {
    const options: TextEditorOptions = new TextEditorOptions();
    options.key = this.appConfig.licenses.froalaEditor;
    options.theme = TextEditorThemes.gray;
    options.toolbarButtons = [
      'fontFamily', 'fontSize', 'color', '|',
      'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertImage', 'insertTable', '|',
      'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
      'clearFormatting', '|',
      'html'
    ];
    options.placeholderText = 'Create your message here!';
    options.charCounterMax = 500;
    options.charCounterCount = true;

    return options;
  }
}
