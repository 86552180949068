import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';

import { PunchAttestationManagementService } from '../../services/index';
import { PunchAttestationGroup } from '../../models/index';
import { AttestationType, RestrictedAnswer } from '../../../../../app/organization';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-punch-attestation',
  templateUrl: 'punch-attestation.component.html',
  styleUrls: ['punch-attestation.component.scss'],
  providers: [PunchAttestationManagementService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchAttestationComponent implements OnInit, OnDestroy {

  public isLoading: boolean = true;
  public orgLevel: OrgLevel;
  public attestationTypeLookup: AttestationType[];
  public orgLevelId: number;

  public restrictedAnswerLookup: RestrictedAnswer[];
  public punchAttestationAnswerCodes: any;
  public punchAttestationPunchType: any;
  public isConditional: any;
  private scrollPosition : any;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  @unsubscribe()
  private loadedSubscription: Subscription;

  @unsubscribe()
  private statusSubscription: Subscription;

  @ViewChild('groups',{static : false}) private scrollContainer: ElementRef;

  groups$ = new BehaviorSubject<PunchAttestationGroup[]>([]);

  constructor(
    private managementService: PunchAttestationManagementService,
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    if (this.route.snapshot.data) {
      const lookupData = this.route.snapshot.data[1];
      this.attestationTypeLookup = lookupData[0];
      this.restrictedAnswerLookup = lookupData[1];
      this.punchAttestationAnswerCodes = lookupData[2].items;
      this.punchAttestationPunchType = lookupData[3].items;
    }
      
    this.load();
    this.loadedSubscription = this.managementService.onLoaded$.subscribe((groups : PunchAttestationGroup[]) => {
      this.groups$.next(groups);
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.statusSubscription = this.managementService.onLoadStatus$.subscribe(isLoading => {
      this.isLoading = isLoading;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
      if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = orgLevel;
        this.load();
      }
    });

    this.managementService.scrollBottom$.subscribe((scrollBottom: Boolean) => {
      if (scrollBottom) {
        this.scrollPosition = this.scrollContainer.nativeElement.scrollHeight;
      }
      this.scrollToPosition();
    });
    this.getPermissions();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public load(): void {
    let org = new OrgLevel();
    org.id = 0;
    this.managementService.loadgroups(org);
  }

  public getPermissions() {
    this.managementService.getPermissions(this.orgLevelId);
  }

  scrollToPosition(){
    this.scrollContainer.nativeElement.scrollTop = this.scrollPosition;
  }

  onScroll(event){
    this.scrollPosition = event.target.scrollTop;
  }
}
