<div modalAnchor class="slx-local-anchor slx-light-overlay"></div>
<slx-spinner [show]="isLoading">
  <div class="slx-high-box mass-assignment-payunits ">
    <div class="slx-content-toolbar-indents flex alighn-items__center justify-content__flex-start">
        <slx-date-navigator class="slx-padd-r"
        name="selectedDate"
        [(ngModel)]="selectedDate"
        [minDate]="request.startDate"
        [maxDate]="request.endDate"
        [readOnlyInput]="true"
        [acceptNullDate]="false"
        format="MM/dd/yyyy"
        (ngModelChange)="changeDate()"
        >
        </slx-date-navigator>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
        <form #form="ngForm" novalidate class="slx-full-height">
          <kendo-grid #kendoGrid class="slx-blue-grid slx-transparent-selection slx-grid-slim-rows slx-full-height"
            [data]="gridState?.view"
            [filterable]="'menu'"
            [filter]="gridState.state.filter"
            [sortable]="true"
            [sort]="gridState.state.sort"
            [pageable]="false"
            [rowClass]="rowCallback"
            (dataStateChange)="gridState.dataStateChange($event)"
          >

            <kendo-grid-column title="Employee" width="150" field="employeeName" [sortable]="true" [locked]="true">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Name</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <a [employeeSectionNewWindowLink]="dataItem.employeeId">
                        {{dataItem.employeeName}}
                    </a>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Position" width="150" field="position.name" [sortable]="true" [locked]="true">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Position</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.position?.name}}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="DW" width="60" [sortable]="false"  [filterable]="false" [locked]="true" headerClass="slx-padding-l6r3">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>DW</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.dayTimecard[dateKey]?.date | amDateFormat: 'dd'}}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Date" width="100" [sortable]="false"  [filterable]="false" [locked]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Date</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                      {{dataItem.dayTimecard[dateKey]?.date | amDateFormat: appConfig.dateFormat}}
              </ng-template>
            </kendo-grid-column>

          <kendo-grid-column title="Start Time" width="100" [sortable]="false"  [filterable]="false" [locked]="true" headerClass="slx-padding-l6r3">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Start Time</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                      {{dataItem.dayTimecard[dateKey]?.start | amDateFormat: appConfig.timeFormat}}
              </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Interval" width="80" [sortable]="false"  [filterable]="false" [locked]="true" headerClass="slx-padding-l6r3">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Interval</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                      {{dataItem.dayTimecard[dateKey]?.workHours | number: "0.2-2"}}
              </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="A" width="60" [sortable]="false"  [filterable]="false" [locked]="true" headerClass="slx-padding-6">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>A</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                    {{dataItem.dayTimecard[dateKey]?.isApproved ? 'A': ''}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column-group title="Pay Unit1" [width]="400" [locked]="false" headerClass="slx-grid-small-header">
            <kendo-grid-column title="Select Code" width="150" [sortable]="false"  [filterable]="false" [locked]="false">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Select Code</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <slx-dropdown-list class="slx-wide" [lookup]="getPayunitsLookup(dataItem)" [readonly]="dataItem.dayTimecard[dateKey].lockDay" [ngModel]="dataItem.dayTimecard[dateKey].payUnits[0].payUnit" valueField="id"
                titleField="name" name="payUnit1_{{rowIndex}}" (ngModelChange)="changePayUnit($event,dataItem.dayTimecard[dateKey], dataItem.dayTimecard[dateKey].payUnits[0], 0)" >
              </slx-dropdown-list>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="#of Units" width="100" [sortable]="false"  [filterable]="false" [locked]="false" headerClass="slx-padding-6">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>#of Units</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <slx-number name="payUnit1units_{{rowIndex}}"
                    class="slx-wide slx-input-number"
                    [min]="0"
                    [slxMin]="0"
                    [step]="1"
                    [autoCorrect]='false' [decimals]="2"
                    [readonly]="!dataItem.dayTimecard[dateKey].payUnits[0].payUnit || dataItem.dayTimecard[dateKey].lockDay"
                    [ngModel]="dataItem.dayTimecard[dateKey].payUnits[0].units"
                    (ngModelChange)="changeUnits($event, dataItem.dayTimecard[dateKey].payUnits[0])"
                  >
                </slx-number>
                <span *ngIf="!!dataItem.dayTimecard[dateKey].payUnits[0].payUnit && !dataItem.dayTimecard[dateKey].payUnits[0].units" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Position" width="150" [sortable]="false"  [filterable]="false" [locked]="false">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Position</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <slx-dropdown-list
                [readonly]="!dataItem.dayTimecard[dateKey].payUnits[0].payUnit || dataItem.dayTimecard[dateKey].lockDay"
                class="slx-wide" [lookup]="getEmpPositions(dataItem)" [ngModel]="dataItem.dayTimecard[dateKey].payUnits[0].position" valueField="id"
                titleField="name" name="payUnit1position_{{rowIndex}}" (ngModelChange)="changePosition($event, dataItem.dayTimecard[dateKey].payUnits[0])">
              </slx-dropdown-list>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group title="Pay Unit2" [width]="400" [locked]="false" headerClass="slx-grid-small-header">
              <kendo-grid-column title="Select Code" width="150" [sortable]="false"  [filterable]="false" [locked]="false">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Select Code</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <slx-dropdown-list class="slx-wide" [lookup]="getPayunitsLookup(dataItem)" [ngModel]="dataItem.dayTimecard[dateKey].payUnits[1].payUnit" valueField="id"
                  titleField="name" name="payUnit2_{{rowIndex}}"
                  [readonly]="!dataItem.dayTimecard[dateKey].payUnits[0].payUnit || dataItem.dayTimecard[dateKey].lockDay"
                  (ngModelChange)="changePayUnit($event, dataItem.dayTimecard[dateKey], dataItem.dayTimecard[dateKey].payUnits[1], 1)" >
                </slx-dropdown-list>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="#of Units" width="100" [sortable]="false"  [filterable]="false" [locked]="false" headerClass="slx-padding-6">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>#of Units</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <slx-number name="payUnit2units_{{rowIndex}}"
                      class="slx-wide slx-input-number"
                      [min]="0"
                      [slxMin]="0"
                      [step]="1"
                      [autoCorrect]='false' [decimals]="2"
                      [readonly]="!dataItem.dayTimecard[dateKey].payUnits[1].payUnit || dataItem.dayTimecard[dateKey].lockDay"
                      [ngModel]="dataItem.dayTimecard[dateKey].payUnits[1].units"
                      (ngModelChange)="changeUnits($event, dataItem.dayTimecard[dateKey].payUnits[1])"
                    >
                  </slx-number>
                  <span *ngIf="!!dataItem.dayTimecard[dateKey].payUnits[1].payUnit && !dataItem.dayTimecard[dateKey].payUnits[1].units" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Position" width="150" [sortable]="false"  [filterable]="false" [locked]="false">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Position</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <slx-dropdown-list
                  [readonly]="!dataItem.dayTimecard[dateKey].payUnits[1].payUnit || dataItem.dayTimecard[dateKey].lockDay"
                  class="slx-wide" [lookup]="getEmpPositions(dataItem)" [ngModel]="dataItem.dayTimecard[dateKey].payUnits[1].position" valueField="id"
                  titleField="name" name="payUnit2position_{{rowIndex}}" (ngModelChange)="changePosition($event, dataItem.dayTimecard[dateKey].payUnits[1])">
                </slx-dropdown-list>
                </ng-template>
              </kendo-grid-column>
          </kendo-grid-column-group>
          <kendo-grid-column-group title="Pay Unit3" [width]="400" [locked]="false" headerClass="slx-grid-small-header">
              <kendo-grid-column title="Select Code" width="150" [sortable]="false"  [filterable]="false" [locked]="false">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Select Code</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <slx-dropdown-list class="slx-wide"
                  [readonly]="!dataItem.dayTimecard[dateKey].payUnits[1].payUnit || dataItem.dayTimecard[dateKey].lockDay"
                  [lookup]="getPayunitsLookup(dataItem)" [(ngModel)]="dataItem.dayTimecard[dateKey].payUnits[2].payUnit" valueField="id"
                  titleField="name" name="payUnit3_{{rowIndex}}" (ngModelChange)="changePayUnit($event, dataItem.dayTimecard[dateKey], dataItem.dayTimecard[dateKey].payUnits[2], 2)" >
                </slx-dropdown-list>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="#of Units" width="100" [sortable]="false"  [filterable]="false" [locked]="false" headerClass="slx-padding-6">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>#of Units</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <slx-number name="payUnit3units_{{rowIndex}}"
                      class="slx-wide slx-input-number"
                      [min]="0"
                      [slxMin]="0"
                      [step]="1"
                      [autoCorrect]='false' [decimals]="2"
                      [readonly]="!dataItem.dayTimecard[dateKey].payUnits[2].payUnit || dataItem.dayTimecard[dateKey].lockDay"
                      [ngModel]="dataItem.dayTimecard[dateKey].payUnits[2].units"
                      (ngModelChange)="changeUnits($event, dataItem.dayTimecard[dateKey].payUnits[2])"
                    >
                  </slx-number>
                  <span *ngIf="!!dataItem.dayTimecard[dateKey].payUnits[2].payUnit && !dataItem.dayTimecard[dateKey].payUnits[2].units" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Position" width="150" [sortable]="false"  [filterable]="false" [locked]="false">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Position</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <slx-dropdown-list
                  [readonly]="!dataItem.dayTimecard[dateKey].payUnits[2].payUnit || dataItem.dayTimecard[dateKey].lockDay"
                  class="slx-wide" [lookup]="getEmpPositions(dataItem)" [ngModel]="dataItem.dayTimecard[dateKey].payUnits[2].position" valueField="id"
                  titleField="name" name="payUnit3position_{{rowIndex}}" (ngModelChange)="changePosition($event, dataItem.dayTimecard[dateKey].payUnits[2])">
                </slx-dropdown-list>
                </ng-template>
              </kendo-grid-column>
          </kendo-grid-column-group>

        </kendo-grid>
        </form>
    </div>
</div>
<div class="modal-footer">
  <button class="btn red" (click)="cancel()">
    <i class="fa fa-times" aria-hidden="true"></i>
    <span class="btn-text__padding">Cancel</span>
  </button>

  <button class="btn green" (click)="save()" [disabled]="!form.valid">
    <span class="btn-text__padding"><i class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save</span>
  </button>
</div>
</slx-spinner>
