<div class="slx-schedule-cycle-calendar-slider" [ngClass]="{ 'disabled': readonly }">
  <span class="cmd-buttons">
    <i class="cmd-icon left fas fa-angle-left" aria-hidden="true" (click)="onNavButtonClick($event, false)"></i>
  </span>
  <div class="date-container">
    <div class="dates-container"
      [popper]="popperCalendar"
      [popperTrigger]="'click'"
      [popperDisableStyle]="true"
      [popperPlacement]="'bottom-start'"
      (popperOnShown)="onToggleVisibility(true)"
      (popperOnHidden)="onToggleVisibility(false)"
    >
      <span>{{ currentRange?.startDate | amDateFormat: appConfig.dateFullMonthFormat }}</span>
      <span>&ndash; {{ currentRange?.endDate | amDateFormat: appConfig.dateFullMonthFormat }}</span>
      <span class="calendar-icon">
        <i class="fal fa-calendar-alt" aria-hidden="true"></i>
      </span>
    </div>
    <!-- See #issueWithPopperVisibility -->
    <popper-content #popperCalendar [ngClass]="{ 'not-shown-fix-visibility-issue': !isShown }">
      <slx-schedule-cycle-calendar
        [cycles] = "cycles"
        [(ngModel)]="currentDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [readonly]="readonly"
      ></slx-schedule-cycle-calendar>
    </popper-content>
  </div>
  <span class="cmd-buttons">
    <i class="cmd-icon right fas fa-angle-right" aria-hidden="true" (click)="onNavButtonClick($event, true)"></i>
  </span>
</div>
