/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-replacement-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/input-decorator/input-decorator.component.ngfactory";
import * as i3 from "../../../../common/components/input-decorator/input-decorator.component";
import * as i4 from "../../../../common/components/checkbox-input/checkbox-input.component.ngfactory";
import * as i5 from "../../../../common/components/checkbox-input/checkbox-input.component";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./shift-replacement-header.component";
import * as i9 from "../../../../common/services/column-settings/column-settings-storage.service";
var styles_ShiftReplacementHeaderComponent = [i0.styles];
var RenderType_ShiftReplacementHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftReplacementHeaderComponent, data: {} });
export { RenderType_ShiftReplacementHeaderComponent as RenderType_ShiftReplacementHeaderComponent };
function View_ShiftReplacementHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "checkbox-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "slx-input-decorator", [["className", "slx-form-group slx-no-error slx-no-border slx-no-label slx-small-font"]], null, null, null, i2.View_InputDecoratorComponent_0, i2.RenderType_InputDecoratorComponent)), i1.ɵdid(2, 311296, null, 4, i3.InputDecoratorComponent, [i1.ElementRef, i1.Renderer2], { className: [0, "className"] }, null), i1.ɵqud(603979776, 1, { fieldChild1: 0 }), i1.ɵqud(603979776, 2, { fieldChild2: 0 }), i1.ɵqud(603979776, 3, { inputPolicyDirective: 0 }), i1.ɵqud(603979776, 4, { errorMessages: 1 }), (_l()(), i1.ɵeld(7, 0, null, 0, 6, "slx-checkbox-input", [["placeholder", ""], ["slx-input", ""]], [[1, "id", 0], [8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_v.context.$implicit.displayed = $event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onChange($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_CheckboxInputComponent_0, i4.RenderType_CheckboxInputComponent)), i1.ɵdid(8, 49152, null, 0, i5.CheckboxInputComponent, [i1.ElementRef, i1.ChangeDetectorRef], { placeholder: [0, "placeholder"], caption: [1, "caption"] }, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.CheckboxInputComponent]), i1.ɵdid(10, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(12, 540672, [[2, 4]], 0, i3.SlxInputAltDirective, [[2, i6.NgControl], i1.ElementRef], { placeholder: [0, "placeholder"] }, null), i1.ɵdid(13, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var currVal_0 = "slx-form-group slx-no-error slx-no-border slx-no-label slx-small-font"; _ck(_v, 2, 0, currVal_0); var currVal_10 = ""; var currVal_11 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.description, ""); _ck(_v, 8, 0, currVal_10, currVal_11); var currVal_12 = _v.context.$implicit.displayed; _ck(_v, 10, 0, currVal_12); var currVal_13 = ""; _ck(_v, 12, 0, currVal_13); }, function (_ck, _v) { var currVal_1 = _v.context.index; var currVal_2 = i1.ɵnov(_v, 12).className; var currVal_3 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 13).ngClassValid; var currVal_8 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_ShiftReplacementHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "dropdown"]], null, null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "open": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["aria-expanded", "true"], ["aria-haspopup", "true"], ["class", "slx-button slx-with-icon slx-mobile-adapted"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleColumnsMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-filter slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Columns"])), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["aria-labelledby", "group-activities"], ["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Columns to display"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftReplacementHeaderComponent_1)), i1.ɵdid(13, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Select All "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearAll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Clear All "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dropdown"; var currVal_1 = _ck(_v, 3, 0, _co.columnsMenuOpened); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.settings.columns; _ck(_v, 13, 0, currVal_2); }, null); }
export function View_ShiftReplacementHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-shift-replacement-header", [], null, null, null, View_ShiftReplacementHeaderComponent_0, RenderType_ShiftReplacementHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i8.ShiftReplacementHeaderComponent, [i9.ColumnSettingsStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftReplacementHeaderComponentNgFactory = i1.ɵccf("slx-shift-replacement-header", i8.ShiftReplacementHeaderComponent, View_ShiftReplacementHeaderComponent_Host_0, { settings: "settings" }, { settingsChanged: "settingsChanged", columnsChanged: "columnsChanged" }, []);
export { ShiftReplacementHeaderComponentNgFactory as ShiftReplacementHeaderComponentNgFactory };
