import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../app.config';
import { ScheduleCycleMessages, ScheduleCycleSummaryViewAction } from '../../../models/index';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
var ScheduleCycleSummaryGridComponent = /** @class */ (function () {
    function ScheduleCycleSummaryGridComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
        this.actionEvent = new EventEmitter();
    }
    Object.defineProperty(ScheduleCycleSummaryGridComponent.prototype, "summary", {
        set: function (value) {
            this.records = value.messages;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    ScheduleCycleSummaryGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    ScheduleCycleSummaryGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ScheduleCycleSummaryGridComponent.prototype.navigateToEmployee = function (item) {
        var action = new ScheduleCycleSummaryViewAction();
        action.employeeId = item.employeeId;
        action.action = 'NavigateToEmployee';
        this.actionEvent.emit(action);
    };
    ScheduleCycleSummaryGridComponent.prototype.navigateToScheduleEntry = function (item) {
        var action = new ScheduleCycleSummaryViewAction();
        action.employeeId = item.employeeId;
        action.date = item.workDate;
        action.action = 'NavigateToScheduleEntry';
        this.actionEvent.emit(action);
    };
    ScheduleCycleSummaryGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleCycleSummaryGridComponent.prototype, "gridRefreshSubscription", void 0);
    return ScheduleCycleSummaryGridComponent;
}());
export { ScheduleCycleSummaryGridComponent };
