var BenefitEligibilityRuleConfigurationVariable = /** @class */ (function () {
    function BenefitEligibilityRuleConfigurationVariable(_a) {
        var id = _a.id, name = _a.name, dataType = _a.dataType, operatorIds = _a.operatorIds, variableType = _a.variableType, predicates = _a.predicates;
        this.id = id;
        this.name = name;
        this.dataType = dataType;
        this.operatorIds = operatorIds;
        this.variableType = variableType;
        this.predicates = predicates;
    }
    return BenefitEligibilityRuleConfigurationVariable;
}());
export { BenefitEligibilityRuleConfigurationVariable };
var BenefitEligibilityRuleConfigurationPredicate = /** @class */ (function () {
    function BenefitEligibilityRuleConfigurationPredicate(_a) {
        var id = _a.id, name = _a.name, predicateEnum = _a.predicateEnum;
        this.id = id;
        this.name = name;
        this.predicateEnum = predicateEnum;
    }
    return BenefitEligibilityRuleConfigurationPredicate;
}());
export { BenefitEligibilityRuleConfigurationPredicate };
var BenefitEligibilityRuleConfigurationData = /** @class */ (function () {
    function BenefitEligibilityRuleConfigurationData(_a) {
        var variables = _a.variables, predicates = _a.predicates;
        this.variables = variables;
        this.predicates = predicates;
    }
    return BenefitEligibilityRuleConfigurationData;
}());
export { BenefitEligibilityRuleConfigurationData };
