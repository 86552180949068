/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reports.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./report-group/report-group.component.ngfactory";
import * as i3 from "./report-group/report-group.component";
import * as i4 from "../../common/components/spinner/spinner.component.ngfactory";
import * as i5 from "../../common/components/spinner/spinner.component";
import * as i6 from "@angular/common";
import * as i7 from "../services/reports-management.service";
import * as i8 from "../services/reports-api.service";
import * as i9 from "../../core/services/session/session.service";
import * as i10 from "../../core/services/auth-api/auth-api.service";
import * as i11 from "../../core/services/window/window-ref.model";
import * as i12 from "../../portal/services/application/application.service";
import * as i13 from "../../organization/services/lookup/lookup-api.service";
import * as i14 from "./reports.component";
import * as i15 from "../../common/services/file/file.service";
import * as i16 from "../../common/services/modal/modal.service";
import * as i17 from "../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
var styles_ReportsComponent = [i0.styles];
var RenderType_ReportsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportsComponent, data: {} });
export { RenderType_ReportsComponent as RenderType_ReportsComponent };
function View_ReportsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "report-group"]], [[2, "half", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-report-group", [], null, null, null, i2.View_ReportGroupComponent_0, i2.RenderType_ReportGroupComponent)), i1.ɵdid(2, 49152, null, 0, i3.ReportGroupComponent, [], { reportGroup: [0, "reportGroup"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.reportGroups.length === 2) || (_co.reportGroups.length === 4)); _ck(_v, 0, 0, currVal_0); }); }
export function View_ReportsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-spinner", [], null, null, null, i4.View_SpinnerComponent_0, i4.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i5.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "holder slx-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "report-groups"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsComponent_1)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.reportGroups; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ReportsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-reports", [], null, null, null, View_ReportsComponent_0, RenderType_ReportsComponent)), i1.ɵprd(512, null, i7.ReportsManagementService, i7.ReportsManagementService, [i8.ReportsApiService, i9.SessionService, i10.AuthApiService, i11.WindowRef, i12.ApplicationService, i13.LookupApiService]), i1.ɵdid(2, 180224, null, 0, i14.ReportsComponent, [i7.ReportsManagementService, i15.FileService, i16.ModalService, i17.NotificationsService], null, null)], null, null); }
var ReportsComponentNgFactory = i1.ɵccf("slx-reports", i14.ReportsComponent, View_ReportsComponent_Host_0, {}, {}, []);
export { ReportsComponentNgFactory as ReportsComponentNgFactory };
