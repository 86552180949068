import { EmpType, EmployeeDefinition } from './../../../organization/models/index';
import * as moment from 'moment';
import * as _ from 'lodash';

import { DateTimeService } from '../../../common/services/date-time/date-time.service';
import { Injectable } from '@angular/core';

import {
  ScheduleEntry, ScheduleEntryShift, ShiftLate, ShiftOvertime, ShiftPartialAbsence
} from '../../models/schedule-entry/index';

import {
  IScheduledShiftDto, IPrescheduledOvertimeDto, ILatencyDto, IShiftPartialAbsenceDTO, IScheduleEntryDto, IScheduleEntryContainerDto
} from '../../models/schedule-entry/dto/index';

import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../../common/utils/index';

import { LookupMapService, EmployeeDefinitionsMapService } from '../../../organization/services/index';
import { DetailsAgencyPosition, IDetailsAgencyPosition } from '../../models';

@Injectable()
export class ScheduleEntryMapService {

  constructor(private lookupMapService: LookupMapService, private dateTime: DateTimeService, private employeeMapService: EmployeeDefinitionsMapService) {
    _.noop();
  }

  public mapToScheduleEntry(data: IScheduleEntryContainerDto): ScheduleEntry {
    let scheduleDetails: ScheduleEntry = new ScheduleEntry();
    scheduleDetails.date = new Date(data.scheduleEntry.dateOn);
    scheduleDetails.employee = this.employeeMapService.mapToEmployeeDefinition(data.employee);
    scheduleDetails.shifts = this.mapShifts(data.scheduleEntry.scheduledShifts);
    scheduleDetails.defaultPositionId = data.homePositionId ? +data.homePositionId : 0;
    scheduleDetails.defaultShiftId = data.homeShiftId ? +data.homeShiftId : 0;
    scheduleDetails.defaultUnitId = data.homeUnitId ? +data.homeUnitId : 0;
    scheduleDetails.nextDayShiftStart = data.nextDayShiftStart ? moment(data.nextDayShiftStart).toDate() : undefined;
    scheduleDetails.previousDayShiftEnd = data.previousDayShiftEnd ? moment(data.previousDayShiftEnd).toDate() : undefined;
    scheduleDetails.canReplaceEmployee = data.canReplaceEmployee;
    return scheduleDetails;
  }

  public mapShifts(dtos: IScheduledShiftDto[]): ScheduleEntryShift[] {
    let shifts: ScheduleEntryShift[] = [];
    _.each(dtos, (dto: IScheduledShiftDto) => {
      let scheduleShift: ScheduleEntryShift = this.mapToScheduleEntryShift(dto);
      shifts.push(scheduleShift);
    });
    return shifts;
  }

  public mapToScheduleEntryShift(data: any): ScheduleEntryShift {
    let shift: ScheduleEntryShift = new ScheduleEntryShift();

    shift.department = data.department || null;
    shift.endDate = data.end ? dateTimeUtils.getUtcDateTime(data.end) : null;
    shift.startDate = data.start ? dateTimeUtils.getUtcDateTime(data.start) : null;
    shift.position = data.position || null;
    shift.shift = data.shift || null;
    shift.unit = data.unit || null;
    shift.late = data.latency ? this.mapToShiftLate(data.latency) : null;
    shift.overtime = data.overtime ? this.mapToShiftOvertime(data.overtime) : null;
    shift.partialAbsence = data.partialAbsence ? this.mapToShiftPartialAbsence(data.partialAbsence) : null;
    shift.constraint = data.constraint || null;
    shift.scheduleAbsence = null;
    if (data.absence && data.absence.code) {
      shift.scheduleAbsence = data.absence;
    }
    if (data.replaced) {
      shift.replaced = this.employeeMapService.mapToEmployeeDefinition(data.replaced);
    }
    if (data.replacing) {
      shift.replacing = this.employeeMapService.mapToEmployeeDefinition(data.replacing);
    }

    shift.hours = Math.round(data.totalHours * 100) / 100;
    /*
    if (shift.scheduleAbsence) {
      shift.hasScheduleAbsence = true;
    } else {
      shift.hasScheduleAbsence = false;
    }
    */

    // tslint:disable-next-line:triple-equals
    shift.notes = data.comments == null ? null : data.comments;

    return shift;
  }



  public mapToShiftLate(data: any): ShiftLate {
    let late: ShiftLate = new ShiftLate();
    late.arrivalDate = dateTimeUtils.convertFromDtoDateTimeString(data.timeStamp);
    late.lateInterval = data.minutesInterval;
    return late;
  }

  public mapToShiftOvertime(data: IPrescheduledOvertimeDto): ShiftOvertime {
    let overtime: ShiftOvertime = new ShiftOvertime();
    overtime.endDate = moment(data.end).toDate();
    overtime.startDate = moment(data.start).toDate();
    overtime.duration = this.dateTime.durationToHours(data.duration);

    return overtime;
  }

  public mapToShiftPartialAbsence(data: IShiftPartialAbsenceDTO): ShiftPartialAbsence {

    let partialAbsence: ShiftPartialAbsence = new ShiftPartialAbsence();

    partialAbsence.startDate = moment(data.startDate).toDate();
    partialAbsence.endDate = moment(data.endDate).toDate();
    partialAbsence.hours = moment (data.endDate).diff (data.startDate)/1000/60/60;
    partialAbsence.scheduleAbsence = this.lookupMapService.mapScheduleAbsence(data.scheduleAbsence);
    return partialAbsence;
  }

  public mapScheduleEntryContainerDto(entry: ScheduleEntry): IScheduleEntryContainerDto {
    let container: IScheduleEntryContainerDto = {
      employee: this.employeeMapService.mapToEmployeeDefinitionDto(entry.employee),
      homePositionId: entry.defaultPositionId,
      homeShiftId: entry.defaultShiftId,
      homeUnitId: entry.defaultUnitId,
      nextDayShiftStart: entry.nextDayShiftStart,
      previousDayShiftEnd: entry.previousDayShiftEnd,
      scheduleEntry: {
        dateOn: entry.date,
        scheduledShifts: this.mapToScheduleShiftDefinitionDtos(entry)
      },
      canReplaceEmployee: entry.canReplaceEmployee
    };
    return container;
  }

  public mapToScheduleShiftDefinitionDtos(data: ScheduleEntry): IScheduledShiftDto[] {

    let definitions: IScheduledShiftDto[] = data.shifts.map((shift: ScheduleEntryShift) => {

      let definition: IScheduledShiftDto = {
        position: shift.position,
        constraint: shift.constraint,
        department: shift.department,
        shift: {
          id: shift.shift.id,
          name: shift.shift.name,
        },
        unit: shift.unit,
        end: moment(shift.endDate).format(appConfig.dateMonthTextDayYearHourFormat),
        start: moment(shift.startDate).format(appConfig.dateMonthTextDayYearHourFormat),
        totalHours: shift.hours,
        absence: shift.scheduleAbsence,
        comments: shift.notes,
        latency: null,
        partialAbsence: null,
        overtime: null,
        replaced: null,
        replacing: null
      };


      if (!shift.scheduleAbsence && shift.late) {
        definition.latency = {
          minutesInterval: shift.late.lateInterval,
          timestamp: moment(shift.late.arrivalDate).format(appConfig.dateMonthTextDayYearHourFormat)
        };
      }

      if (shift.partialAbsence) {
        definition.partialAbsence = {
          startDate: moment(shift.partialAbsence.startDate).format(appConfig.dateMonthTextDayYearHourFormat),
          endDate: moment(shift.partialAbsence.endDate).format(appConfig.dateMonthTextDayYearHourFormat),
          scheduleAbsence: this.lookupMapService.mapScheduleAbsenceDto(shift.partialAbsence.scheduleAbsence)
        };
      }

      if (!shift.scheduleAbsence && shift.overtime) {
        definition.overtime = {
          duration: this.dateTime.hoursToDuration(shift.overtime.duration),
          end: moment(shift.overtime.endDate).format(appConfig.dateMonthTextDayYearHourFormat),
          start: moment(shift.overtime.startDate).format(appConfig.dateMonthTextDayYearHourFormat)
        };
      }

      if (shift.replaced) {
        definition.replaced = this.employeeMapService.mapToEmployeeDefinitionDto(shift.replaced);
      }

      if (shift.replacing) {
        definition.replacing = this.employeeMapService.mapToEmployeeDefinitionDto(shift.replacing);
      }
      return definition;
    });

    return definitions;
  }

  private cleanDto(dto: IScheduleEntryContainerDto): void {
    dto.employee = null;
    dto.homePositionId = 0;
    dto.homeShiftId = 0;
    dto.homeUnitId = 0;
    dto.nextDayShiftStart = null;
    dto.previousDayShiftEnd = null;
    dto.scheduleEntry.dateOn = null;
  }

  public mapToPostions(data: DetailsAgencyPosition[]): IDetailsAgencyPosition[] {
    let positions: DetailsAgencyPosition[] = [];
    _.each(data, (dto: IDetailsAgencyPosition) => {
      let position: DetailsAgencyPosition = this.mapToPartnerPosistion(dto);
      positions.push(position);
    });
    return positions;
  }

  public mapToPartnerPosistion(data: any): DetailsAgencyPosition {
    let postions: DetailsAgencyPosition = new DetailsAgencyPosition();
    postions.map_id = data.map_id || null;
    postions.partner_position_name = data.partner_position_name || null;
    postions.position_name = data.position_name || null;
    return postions
  }
}
