import {
  Component,
  Input,
  ContentChild,
  ContentChildren,
  ViewEncapsulation,
  QueryList,
  ElementRef
} from '@angular/core';
import { NgModel } from '@angular/forms';

import { ErrorMessageDirective } from '../../error-filters/errorMessage';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-field',
  templateUrl: 'employee-field.component.html',
  styleUrls: ['employee-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlxFieldComponent {
  @Input()
  public exForm: any;

  @Input()
  public exFieldName: string;

  @Input()
  public label: string;

  @ContentChild(NgModel, {static: true})
  public model: NgModel;

  @ContentChildren(ErrorMessageDirective)
  public errorMessages: QueryList<ErrorMessageDirective>;

  public get fieldName(): string {
    return this.model ? this.model.name : this.exFieldName;
  }

  public get form(): any {
    return this.model && this.model.formDirective ? this.model.formDirective.form : this.exForm;
  }

  public get modelElement(): Element {
    if (this.modelElementSngl) return this.modelElementSngl;
    let nativeElement: Element = this.elementRef.nativeElement;
    this.modelElementSngl = nativeElement.querySelector('[name=' + this.fieldName + ']');
    return this.modelElementSngl;
  }

  private modelElementSngl: Element;
  private elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }
}
