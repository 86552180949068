import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeEditPhoto } from '../../../models/employee-edit-photo';
import { PhotoCropperComponent } from '../../../../../common/components/photo-cropper/photo-cropper.component';
import { EmployeeEditPhotoDialogComponent } from '../employee-edit-photo-dialog/employee-edit-photo-dialog.component';
import { EmployeeImageSrcDirective } from '../../../../../organization/directives/index';
import { EmployeeDefinitionsApiService,EmployeeImagesService } from '../../../../../organization/services/index';
import { employeeConfig } from '../../../employee.config';
import { CroppieOptions } from 'croppie';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-editable-photo',
  templateUrl: 'employee-editable-photo.component.html',
  styleUrls: ['employee-editable-photo.component.scss']
})
export class EmployeeEditablePhotoComponent {
  @Input('employeeId')
  public employeeId: number;
  @Input('canEdit')
  public canEdit: boolean;

  @ViewChild('employeePhotoElem', {static: true})
  public employeePhotoElem: ElementRef;
  @ViewChild(EmployeeImageSrcDirective, {static: true})
  public employeeImageDirective: EmployeeImageSrcDirective;

  private modalService: ModalService;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  private employeeImagesService: EmployeeImagesService;

  constructor(
    modalService: ModalService,
    employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    employeeImagesService: EmployeeImagesService
  ) {
    this.modalService = modalService;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.employeeImagesService= employeeImagesService;
  }

  public onEditPhoto(): void {
    const employeePhoto: HTMLImageElement = this.employeePhotoElem.nativeElement;
    let req: EmployeeEditPhoto = new EmployeeEditPhoto();
    req.employeeId = this.employeeId;
    req.photo = {
      src: employeePhoto.src,
      width: employeePhoto.naturalWidth,
      height: employeePhoto.naturalHeight
    };
    EmployeeEditPhotoDialogComponent.openDialog(req, this.modalService, (result: boolean, res: Promise<string>) => {
       if (result && res) {
        res.then((dataUrl) => {
          this.employeeImageDirective.updateEmployeeImage(this.employeeId, dataUrl);
          this.employeeDefinitionsApiService.setEmployeePhoto(this.employeeId, dataUrl).then(() => {
            this.employeeImagesService.updateThumbinalsCache([this.employeeId]);
          });
        });
       }
    });
  }
}
