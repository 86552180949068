import { EmpSynDetails, IEmpSynDetails, IWfmSync, IWfmSynDetails, IWfmTransactionReport, IWfnSyncResponse, WfmSync, WfmSynDetails, WfmTransactionReport, WfnSyncResponse } from '../../models/wfm-sync';
import { Injectable } from "@angular/core";
import * as _ from 'lodash';
import { LookupMapService } from '../../../organization/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { Assert } from '../../../framework/index';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { FieldsMeta } from '../../../core/models';
@Injectable()
export class WFMSyncMapService {

  constructor(private lookupMapService: LookupMapService,private datePipe: DatePipe) {

  }


  public postEmployeeDetails(dto: IWfmSync): WfmSync {
    const data: WfmSync = new WfmSync();
    data.type = dto.type;
    data.companyCode = dto.companyCode;
    data.customerId = dto.customerId;
    data.employeeIds = dto.employeeIds;
    data.startDate = dto.startDate;
    data.endDate = dto.endDate;
    data.partnerId = dto.partnerId;
    data.orgLevelId = dto.orgLevelId;
    data.orgName = dto.orgName;
    data.status = dto.status;
    return data;
  }
  public getEmployeeDetails(dtos: IWfnSyncResponse[]): WfnSyncResponse[] {
    return _.map(dtos, (dto: IWfnSyncResponse) => this.employeeSyncClassDefinition(dto));
  }
  public employeeSyncClassDefinition(dto: IWfnSyncResponse): WfnSyncResponse {
    let model: WfnSyncResponse = new WfnSyncResponse();
    model.id = dto.id;
    model.syncId = dto.syncId;
    model.customerId = dto.customerId;
    model.companyId = dto.companyId;
    model.errorCount = dto.errorCount;
    model.successCount = dto.successCount;
    model.currentCount = dto.currentCount;
    model.totalCount = dto.totalCount;
    model.activeCount = dto.activeCount;
    model.terminateCount = dto.terminateCount;
    model.syncStartDate = dto.syncStartDate;
    model.syncEndDate = dto.syncEndDate;
    model.partnerId = dto.partnerId;
    return model;
  }
  public getEmployeeSyncDetails(dtos: IWfmSynDetails[]): WfmSynDetails[] {
    return _.map(dtos, (dto: IWfmSynDetails) => this.employeeSyncDetailsDefinition(dto));
  }
  public employeeSyncDetailsDefinition(dto: IWfmSynDetails): WfmSynDetails {
    let model: WfmSynDetails = new WfmSynDetails();
    model.syncId = dto.syncId;
    model.errorMessage = dto.errorMessage;
    model.status = dto.status;
    model.employeeId = dto.employeeId;
    model.StartDate = dto.StartDate;
    model.endDate = dto.endDate;
    model.syncType = dto.syncType;

    return model;
  }

  public getTransactionDetails(dtos: IWfmTransactionReport[]): WfmTransactionReport[] {
    return _.map(dtos, (dto: IWfmTransactionReport) => this.transactionDetails(dto));
  }
  public transactionDetails(dto: IWfmTransactionReport): WfmTransactionReport {
    let model: WfmTransactionReport = new WfmTransactionReport();
    model.transactionId=dto.transactionId;
    model.transactionDate=dto.transactionDate;
    model.transactionName=dto.transactionName
    model.transactionEndDate=dto.transactionEndDate;
    model.employeeName=dto.employeeName;
    model.transactionStatus=dto.transactionStatus;
    model.errorCode=dto.errorCode;
    model.employeeId=dto.employeeId;
    model.errorMessage=dto.errorMessage;
   
    return model;
  }

  public getEmployeeOrgSyncDetails(dtos: IEmpSynDetails[]): EmpSynDetails[] {
    return _.map(dtos, (dto: IEmpSynDetails) => this.postEmployeeSyncDetails(dto));
  }
  public postEmployeeSyncDetails(dto: IEmpSynDetails): EmpSynDetails {
    const model: EmpSynDetails = new EmpSynDetails();
    model.id=dto.id;
     model.employeeID=dto.employeeID;
     model.payRollNumber=dto.payRollNumber;
     model.employeeName=dto.employeeName;
     model.orgName=dto.orgName;
     model.depName=dto.depName;
     model.positionName=dto.positionName;
     model.positionId=dto.positionId;
     model.departmentId=dto.departmentId;
     model.organizationId=dto.organizationId;
     model.terminationDate=dto.terminationDate==null?dto.terminationDate:new Date(dto.terminationDate);
     model.transferDepartmentId=dto.transferDepartmentId;
     model.companyId=dto.companyId;
     model.coCode=dto.coCode;
     model.errorMessage=dto.errorMessage;
     model.empStatus=dto.empStatus;
     model.action=dto.action;
     model.status=dto.status;
     model.failedAt=dto.failedAt;
     model.ischecked=dto.ischecked;
     model.isDisabled=dto.isDisabled;
     model.paygroup=dto.paygroup;
     model.lastSyncDate=dto.lastSyncDate;
     model.dateReHired=dto.dateReHired;
      return model;
  }
}
