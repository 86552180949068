import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { WcIncidentTrackingManagementService } from '../../../services/index';
var WcIncidentTrackingComponent = /** @class */ (function () {
    function WcIncidentTrackingComponent(manService) {
        this.manService = manService;
        this.isLoading = true;
        this.subscriptions = {};
    }
    WcIncidentTrackingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.init('WcIncidentTracking');
        this.subscriptions.onLoad = this.manService
            .subscribeToLoading(function (value) { return (_this.isLoading = value); });
    };
    WcIncidentTrackingComponent.prototype.ngOnDestroy = function () {
        this.manService.destroy();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcIncidentTrackingComponent.prototype, "subscriptions", void 0);
    return WcIncidentTrackingComponent;
}());
export { WcIncidentTrackingComponent };
