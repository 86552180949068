import { IScheduleSubtotal, ScheduleSubtotal } from './subtotal';
import { IScheduleTotal, ScheduleTotal } from './total';

export class IScheduleTotalSummary {
  public scheduleSubtotals: IScheduleSubtotal[];
  public scheduleTotals: IScheduleTotal[];
}

export class ScheduleTotalSummary {
  public scheduleSubtotals: ScheduleSubtotal[];
  public scheduleTotals: ScheduleTotal[];
}
