import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgReduxModule } from '@angular-redux/store';

import { RecaptchaModule } from 'ng-recaptcha/index';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

import * as SlxCommon from '../common/index';
import { routesModule } from './authentication.routes';
import { components, entryComponents, exportComponents } from './components/index';
import { services } from './services/index';
import { ACTION_PROVIDERS } from './actions/index';
import { EPIC_PROVIDERS, SessionEpics } from './epics/index';
import { rootEpics } from '../state-model/state-model.module';
import { LoginIpRestrictionMessageComponent } from './components/login-ip-restriction-message/login-ip-restriction-message.component';


@NgModule({
  imports: [
    routesModule,
    FormsModule,
    CommonModule,
    SlxCommon.CommonModule,
    NgReduxModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule
  ],
  declarations: [
    ...components,
    LoginIpRestrictionMessageComponent
  ],
  providers: [
    ...services,
    ...ACTION_PROVIDERS,
    ...EPIC_PROVIDERS
  ],
  entryComponents: [
    ...entryComponents
  ],
  exports: [exportComponents]
})
export class AuthenticationModule {
  constructor(sessionEpics: SessionEpics) {
    rootEpics.push(sessionEpics.clearSession);
  }
}
