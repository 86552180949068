/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aca-1095c-quick-confirm-reset.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./aca-1095c-quick-confirm-reset.component";
import * as i4 from "../../../services/aca-1095-c/aca-1095-c-management.service";
var styles_QuickConfirmReset1095CPopup = [i0.styles];
var RenderType_QuickConfirmReset1095CPopup = i1.ɵcrt({ encapsulation: 0, styles: styles_QuickConfirmReset1095CPopup, data: {} });
export { RenderType_QuickConfirmReset1095CPopup as RenderType_QuickConfirmReset1095CPopup };
function View_QuickConfirmReset1095CPopup_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "message-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "message1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Quick Confirm Reset Active!"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "message2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click a cell in the grid to apply confirm reset."]))], null, null); }
function View_QuickConfirmReset1095CPopup_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "start-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startQuickConfirmReset() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Start"]))], null, null); }
function View_QuickConfirmReset1095CPopup_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "stop-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveQuickConfirmReset() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], null, null); }
function View_QuickConfirmReset1095CPopup_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "show-button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showChangesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Show Changes"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasChanges; _ck(_v, 0, 0, currVal_0); }); }
function View_QuickConfirmReset1095CPopup_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "hide-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideChangesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Hide Changes"]))], null, null); }
function View_QuickConfirmReset1095CPopup_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "change-child-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "change-month-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "change-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " $", " ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "undo-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.undoEdit(_v.parent.context.$implicit, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-undo-alt"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.monthName; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.selectedCode; var currVal_2 = _v.context.$implicit.selectedCost; var currVal_3 = _v.context.$implicit.selectedHarbor; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }); }
function View_QuickConfirmReset1095CPopup_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["ele", 1]], null, 18, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { hideChildren: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "change-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 9, "span", [["class", "change-emp-name"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ((i1.ɵnov(_v, 1).class = ((i1.ɵnov(_v, 1).class == "showChildren") ? "" : "showChildren")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "em", [["class", "fa fa-angle-down"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { hideArrow: 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 3, "em", [["class", "fa fa-angle-right"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(13, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(14, { hideArrow: 0 }), (_l()(), i1.ɵted(15, null, [" ", " "])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "undo-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.undoEdit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-undo-alt"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickConfirmReset1095CPopup_8)), i1.ɵdid(19, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, (i1.ɵnov(_v, 1).class !== "showChildren")); _ck(_v, 3, 0, currVal_0); var currVal_1 = "fa fa-angle-down"; var currVal_2 = _ck(_v, 10, 0, (i1.ɵnov(_v, 1).class !== "showChildren")); _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_3 = "fa fa-angle-right"; var currVal_4 = _ck(_v, 14, 0, (i1.ɵnov(_v, 1).class == "showChildren")); _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_6 = _v.context.$implicit.months; _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var currVal_5 = _v.context.$implicit.employeeName; _ck(_v, 15, 0, currVal_5); }); }
function View_QuickConfirmReset1095CPopup_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "changes-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "changes-holder-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Last Changes"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickConfirmReset1095CPopup_7)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_QuickConfirmReset1095CPopup_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ngForm: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickConfirmReset1095CPopup_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "shift-editor-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "buttons-holder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickConfirmReset1095CPopup_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickConfirmReset1095CPopup_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickConfirmReset1095CPopup_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickConfirmReset1095CPopup_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_QuickConfirmReset1095CPopup_6)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isQuickConfirmResetStarted; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isQuickConfirmResetStarted; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.isQuickConfirmResetStarted; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.showChanges; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.showChanges; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.showChanges; _ck(_v, 14, 0, currVal_5); }, null); }
export function View_QuickConfirmReset1095CPopup_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-aca-1095c-quick-confirm-reset", [], null, null, null, View_QuickConfirmReset1095CPopup_0, RenderType_QuickConfirmReset1095CPopup)), i1.ɵdid(1, 245760, null, 0, i3.QuickConfirmReset1095CPopup, [i4.Aca1095cManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuickConfirmReset1095CPopupNgFactory = i1.ɵccf("slx-aca-1095c-quick-confirm-reset", i3.QuickConfirmReset1095CPopup, View_QuickConfirmReset1095CPopup_Host_0, {}, {}, []);
export { QuickConfirmReset1095CPopupNgFactory as QuickConfirmReset1095CPopupNgFactory };
