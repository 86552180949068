import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { ArrivalsDeparturesAnalytics } from './arrivals-departures-analytics';
var Departure = /** @class */ (function () {
    function Departure() {
    }
    Object.defineProperty(Departure.prototype, "departureTimeTitle", {
        get: function () {
            if (this.departureTime)
                return "" + moment(this.departureTime).format(appConfig.timeFormat);
            return ArrivalsDeparturesAnalytics.unscheduled;
        },
        enumerable: true,
        configurable: true
    });
    return Departure;
}());
export { Departure };
