import { Directive, Input, forwardRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';

const MIN_DATE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MinDateValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: `[slxMinDate][formControlName],[slxMinDate][formControl],[slxMinDate][ngModel]`,
  /* tslint:enable */
  providers: [MIN_DATE_VALIDATOR]
})
export class MinDateValidator implements Validator, OnChanges {
  @Input()
  public minDate: string;
  @Input()
  public set slxMinDate(value: string) {
    this.minDate = value;
  }

  /* tslint:disable */
  private _validator: ValidatorFn;
  private _onChange: () => void;
  /* tslint:enable */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['slxMinDate']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): { [key: string]: any } {
    return !_.isNil(this.minDate) ? this._validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = () => { Promise.resolve(null).then(() => fn()); };
    //this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = CommonValidators.minDate(this.minDate);
  }
}
