import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Lookup } from '../../../../organization/models/index';
import { screenUtils } from '../../../../common/utils/index';
import { EmployeeSearchBase } from '../../services/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
var EmployeeSearchFieldComponent = /** @class */ (function () {
    function EmployeeSearchFieldComponent(employeeSearchService) {
        this.employeeSearchService = employeeSearchService;
        this.subscription = {};
        this.virtual = {
            itemHeight: 55
        };
        this.isShownEmpNameLabel = true;
        this.readonly = false;
        this.hideDetails = false;
        this.m_employeeIds = [];
        this.m_isActive = true;
        this.m_isInit = false;
        this.loadStatusChange = new EventEmitter();
        this.employeeSelected = new EventEmitter();
        this.employeesLookup = this.createLookup([], '', '');
    }
    Object.defineProperty(EmployeeSearchFieldComponent.prototype, "orgLevelId", {
        set: function (value) {
            if (value !== this.m_orglevelId) {
                this.m_orglevelId = value;
                this.employeesLookup = null;
                this.employee = null;
                if (this.m_orglevelId) {
                    this.loadLookup();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSearchFieldComponent.prototype, "employeeIds", {
        set: function (value) {
            if (value && value.length > 0 && value.sort().toString() !== this.m_employeeIds.sort().toString()) {
                this.m_employeeIds = value;
                this.employee = null;
                this.filterEmployeeByIds(this.employeesLookup);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSearchFieldComponent.prototype, "isActive", {
        set: function (value) {
            if (_.isBoolean(value) && this.m_isActive !== value) {
                this.m_isActive = value;
                this.employeesLookup = null;
                this.employee = null;
                if (this.m_orglevelId && this.m_isInit) {
                    this.loadLookup();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSearchFieldComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSearchFieldComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.m_isInit = true;
        this.subscription.employees = this.employeeSearchService.subscribeToEmployees(function (employeesLookup) {
            _this.employeesLookup = employeesLookup;
            _this.filterEmployeeByIds(_this.employeesLookup);
            _this.loadStatusChange.emit(false);
        });
        if (this.m_orglevelId) {
            this.loadLookup();
        }
    };
    EmployeeSearchFieldComponent.prototype.loadLookup = function () {
        if (!this.m_isInit)
            return;
        this.loadStatusChange.emit(true);
        this.employeeSearchService.getEmployees(this.m_orglevelId, this.m_isActive);
    };
    EmployeeSearchFieldComponent.prototype.ngOnDestroy = function () {
        // Must be, see #issueWithAOTCompiler
    };
    EmployeeSearchFieldComponent.prototype.onEmployeeChanged = function (emp) {
        this.employee = emp;
        this.employeeSelected.emit(emp);
    };
    EmployeeSearchFieldComponent.prototype.createLookup = function (items, titleField, valueField) {
        var lookup = new Lookup();
        lookup.titleField = titleField;
        lookup.valueField = valueField;
        lookup.items = items;
        return lookup;
    };
    EmployeeSearchFieldComponent.prototype.filterEmployeeByIds = function (employeesLookup) {
        var _this = this;
        if (employeesLookup && employeesLookup.items && this.m_employeeIds.length > 0) {
            employeesLookup.items = employeesLookup.items.filter(function (employee) { return _this.m_employeeIds.includes(employee.id); });
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSearchFieldComponent.prototype, "subscription", void 0);
    return EmployeeSearchFieldComponent;
}());
export { EmployeeSearchFieldComponent };
