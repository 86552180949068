<slx-spinner [show]="isLoading" novalidate>
    <kendo-grid class="slx-full-height" [data]="clocks">
      <ng-template kendoGridToolbarTemplate position="top">
        <form novalidate>
            <div class="hbox">
                <div class="vbox col-sm-4">
                    <div class="form-item">
                        <label for="msg-text">Message (128 characters)</label>
                    </div>
                    <div class="message-container" >
                        <textarea name="msg-text" class="message-field" [(ngModel)]="messageText" [maxlength]="128" required></textarea>
                    </div>
                    <div>
                        Message will display as a scrolling ticker at the bottom of the selected timeclocks.
                    </div>
                </div>

                <div class="vbox col-sm-4">
                    <div class="form-item">
                        <label for="msg-duration">Message duration</label>
                    </div>
                    <div class="hbox" name="msg-duration">
                        <slx-input-decorator class="offset">
                            <slx-date-time-input slx-input name="startDate" [required]="true" placeholder="Start Time" [(ngModel)]="startDate"
                                [minDateLimit]="minDate" [slxMaxDate]="endDate" field="startDate" [model]="this" [readonly]="false">
                            </slx-date-time-input>
                            <span errorMessage for="required">Start time is required</span>
                            <span errorMessage for="minDate">Start date should be smaller than end date</span>
                            <span slx-input-hint>{{startDate | amDateFormat: appConfig.dateFormat}}</span>
                        </slx-input-decorator>

                        <slx-input-decorator >
                            <slx-date-time-input slx-input name="endDate" [required]="true" placeholder="End Time" [(ngModel)]="endDate"
                                [minDateLimit]="minDate" [slxMinDate]="startDate"  field="endDate" [model]="this" [readonly]="false">
                            </slx-date-time-input>
                            <span errorMessage for="required">End time is required</span>
                            <span errorMessage for="minDate">End date should be greater than start date</span>
                            <span slx-input-hint>{{endDate | amDateFormat: appConfig.dateFormat}}</span>
                        </slx-input-decorator>
                    </div>
                </div>

                <div class="hbox col-sm-4 left-align">
                    <div>
                        <button type="button" class="btn action-button" (click)="stop()" [disabled]="!canStop">Stop</button>
                    </div>
                    <div>
                        <button type="button" class="btn action-button" (click)="send()" [disabled]="!canSend">Send</button>
                    </div>
                </div>
            </div>
        </form>

      </ng-template>
        <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" field="isSelected" [locked]="false"
                width="40px">
            <ng-template kendoGridHeaderTemplate>
                <input type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected()">
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <input type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange()">
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Timeclock Name" field="name">
            <ng-template kendoGridHeaderTemplate>
            Timeclock Name
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.name}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Last Message" field="lastMessageText">
            <ng-template kendoGridHeaderTemplate>
            Last Message
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.lastMessageText}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Message Start" field="messageDisplayStartDate">
            <ng-template kendoGridHeaderTemplate>
                Message Start
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.messageDisplayStartDate | amDateFormat: appConfig.dateTimeFormatUS}}
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Message End" field="messageDisplayEndDate">
            <ng-template kendoGridHeaderTemplate>
                Message End
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.messageDisplayEndDate | amDateFormat: appConfig.dateTimeFormatUS}}
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</slx-spinner>
