import { IEmployeeTerminationReason, EmployeeTerminationReason } from '../../../../organization/models/index';

export interface IEmployeeTerminate {
  date: Date;
  reasonId: number;
  voluntary: boolean;
  doNotRehire: boolean;
  notes: string;
  email: string;
}
export class EmployeeTerminate {
  public employeeId: number;
  public date: Date;
  public reason: EmployeeTerminationReason;
  public voluntary: boolean;
  public doNotRehire: boolean;
  public notes: string;
  public email: string;
}
