export interface IAttendancePointsConfigCategory {
  id: string;
  name: string;
}

export class AttendancePointsConfigCategory {
  public id: string;
  public name: string;
}

