import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ChangeManagementService, FileService } from '../../../common/services';
import { PerformanceManagementApiService } from './performance-management-api.service';
import { LookupService } from '../../../organization/services';
import { NotificationsService } from '../../../core/components';
import { unsubscribeAll, mutableSelect } from '../../../core/decorators';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { PmTemplateDefinition, ReadFile, PmAttachment } from '../../../organization';
import { CfgPmCode } from '../models';
import { Assert } from '../../../framework/assert/assert';
import { SessionService } from '../../../core/services';
var PmConfigurationManagementService = /** @class */ (function () {
    function PmConfigurationManagementService(apiService, lookupService, notificationService, changeService, fileService, sessionService) {
        this.apiService = apiService;
        this.lookupService = lookupService;
        this.notificationService = notificationService;
        this.changeService = changeService;
        this.fileService = fileService;
        this.sessionService = sessionService;
        this.loading$ = new Subject();
        this.closePopup$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.codesLoaded$ = new ReplaySubject(1);
        this.templatesLoaded$ = new ReplaySubject(1);
        this.exportCodesTo$ = new Subject();
        this.exportTemplatesTo$ = new Subject();
        this.addNewCode$ = new Subject();
        this.addNewTemplate$ = new Subject();
        this.changeGroupName = 'performance-management';
        this.changeManagementComponentId = 'configure-performance-management';
        this.subscriptions = {};
    }
    PmConfigurationManagementService.prototype.initialize = function () {
        this.changeService.setCurrentComponentId(this.changeManagementComponentId);
        this.subscribeToOrgLevelChanges();
    };
    PmConfigurationManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.closePopup$.complete();
        this.orgLevelChanged$.complete();
        this.codesLoaded$.complete();
        this.templatesLoaded$.complete();
        this.exportCodesTo$.complete();
        this.exportTemplatesTo$.complete();
        this.addNewCode$.complete();
        this.addNewTemplate$.complete();
    };
    PmConfigurationManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    PmConfigurationManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    PmConfigurationManagementService.prototype.subscribeToCodesLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.codesLoaded$.subscribe(callback);
    };
    PmConfigurationManagementService.prototype.subscribeToTemplatesLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.templatesLoaded$.subscribe(callback);
    };
    PmConfigurationManagementService.prototype.subscribeToAddNewCode = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.addNewCode$.subscribe(callback);
    };
    PmConfigurationManagementService.prototype.subscribeToAddNewTemplate = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.addNewTemplate$.subscribe(callback);
    };
    PmConfigurationManagementService.prototype.subscribeToExportCodes = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportCodesTo$.subscribe(callback);
    };
    PmConfigurationManagementService.prototype.subscribeToExportTemplates = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTemplatesTo$.subscribe(callback);
    };
    PmConfigurationManagementService.prototype.exportTemplates = function (isPdf) {
        this.exportTemplatesTo$.next(isPdf);
    };
    PmConfigurationManagementService.prototype.exportCodes = function (isPdf) {
        this.exportCodesTo$.next(isPdf);
    };
    PmConfigurationManagementService.prototype.startAddNewCode = function () {
        var newCode = new CfgPmCode();
        newCode.orgLevel = this.orgLevel;
        this.addNewCode$.next(newCode);
    };
    PmConfigurationManagementService.prototype.addNewCode = function (code) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 6]);
                        return [4 /*yield*/, this.apiService.createCode(code)];
                    case 2:
                        _a.sent();
                        this.loading$.next(false);
                        return [3 /*break*/, 6];
                    case 3:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.loadPerformanceReviewCodes()];
                    case 5:
                        _a.sent();
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PmConfigurationManagementService.prototype.updateCode = function (code) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 6]);
                        return [4 /*yield*/, this.apiService.updateCode(code)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.loadPerformanceReviewCodes()];
                    case 5:
                        _a.sent();
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PmConfigurationManagementService.prototype.removeCode = function (code) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 6]);
                        return [4 /*yield*/, this.apiService.removeCode(code.id)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3 /*break*/, 6];
                    case 4:
                        this.loading$.next(true);
                        return [4 /*yield*/, this.loadPerformanceReviewCodes()];
                    case 5:
                        _a.sent();
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PmConfigurationManagementService.prototype.addNewTemplate = function (tpl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var newTpl, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 9]);
                        if (!(this.templateFilesToUpload && _.size(this.templateFilesToUpload) > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.apiService.createTemplate(tpl)];
                    case 2:
                        newTpl = _a.sent();
                        return [4 /*yield*/, this.attachSavedFiles(newTpl.templateId, this.templateFilesToUpload)];
                    case 3:
                        _a.sent();
                        this.templateFilesToUpload = null;
                        return [3 /*break*/, 5];
                    case 4: throw new Error('Can not create template without file');
                    case 5: return [3 /*break*/, 9];
                    case 6:
                        e_4 = _a.sent();
                        console.log(e_4);
                        return [3 /*break*/, 9];
                    case 7:
                        this.loading$.next(true);
                        return [4 /*yield*/, this.loadPerformanceReviewTemplates()];
                    case 8:
                        _a.sent();
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    PmConfigurationManagementService.prototype.updateTemplate = function (tpl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 6]);
                        return [4 /*yield*/, this.apiService.updateTemplate(tpl)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        e_5 = _a.sent();
                        console.log(e_5);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.loadPerformanceReviewTemplates()];
                    case 5:
                        _a.sent();
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PmConfigurationManagementService.prototype.removeTemplate = function (tpl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 6]);
                        return [4 /*yield*/, this.apiService.removeTemplate(tpl.templateId)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        e_6 = _a.sent();
                        console.log(e_6);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.loadPerformanceReviewTemplates()];
                    case 5:
                        _a.sent();
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    PmConfigurationManagementService.prototype.startAddNewTemplate = function (files) {
        this.templateFilesToUpload = files;
        var tpl = new PmTemplateDefinition();
        tpl.orgLevel = this.orgLevel.id;
        tpl.orgLevelName = this.orgLevel.name;
        var file = _.first(this.templateFilesToUpload);
        tpl.attachment = new PmAttachment();
        tpl.attachment.fileName = file.fileName;
        var user = this.sessionService.getUser();
        tpl.addedBy = user.username;
        tpl.addedOn = new Date();
        tpl.modifiedBy = user.username;
        tpl.modifiedOn = new Date();
        this.addNewTemplate$.next(tpl);
    };
    PmConfigurationManagementService.prototype.attachSavedFiles = function (id, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tpl;
            return tslib_1.__generator(this, function (_a) {
                if (_.size(files) > 0) {
                    tpl = this.apiService.saveTemplateAttachments(id, files);
                    return [2 /*return*/, tpl];
                }
                return [2 /*return*/, null];
            });
        });
    };
    PmConfigurationManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return _.isFinite(_.get(o, 'id')); })
            .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id)
                            return [2 /*return*/];
                        this.orgLevel = orgLevel;
                        this.orgLevelChanged$.next(this.orgLevel);
                        return [4 /*yield*/, this.loadResources()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    PmConfigurationManagementService.prototype.loadResources = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        return [4 /*yield*/, this.loadPerformanceReviewCodes()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.loadPerformanceReviewTemplates()];
                    case 2:
                        _a.sent();
                        this.loading$.next(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    PmConfigurationManagementService.prototype.loadPerformanceReviewCodes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var codes;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.orgLevel) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apiService.getCodeDefinitions(this.orgLevel.id)];
                    case 1:
                        codes = _a.sent();
                        this.codesLoaded$.next(codes);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    PmConfigurationManagementService.prototype.loadPerformanceReviewTemplates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var templates;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.orgLevel) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.apiService.getTemplateDefinitions(this.orgLevel.id)];
                    case 1:
                        templates = _a.sent();
                        this.templatesLoaded$.next(templates);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PmConfigurationManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], PmConfigurationManagementService.prototype, "subscriptions", void 0);
    return PmConfigurationManagementService;
}());
export { PmConfigurationManagementService };
