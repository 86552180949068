<!-- #bugInIE11WithEmployeeRecord (ngIf) -->
<form novalidate #form="ngForm" *ngIf="licenses">
  <section class="licenses-container">
    <button type="button" class="add add-license-button" (click)="addNewLicense()" *ngIf="state.isEditMode && licenses?.actions.canAdd && !reachMaximum">
      <i class="fa fa-plus-circle" aria-hidden="true"></i> Add license
    </button>
    <div class="licenses">
      <div class="license form-inline" *ngFor="let license of actualLicenses; let idx = index;" [ngClass]="{'license-edit': state.isEditMode}">
        <button type="button" class="remove-license-button" (click)="removeLicense(license)" *ngIf="state.isEditMode && licenses?.actions.canDelete">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <section>
          <slx-input-decorator>
            <input slx-input type="text" required="true" [pattern]="commonConfig.notEmptyPattern" [readonly]="!state.isEditMode" 
              name="licenseNumber_{{license.seq}}_{{license.indexSeq}}"
              placeholder="License number" [inputPolicy]="license?.licenseNumber.Policy" [(ngModel)]="license?.licenseNumber.fieldValue">
            <span errorMessage for="required"></span>
            <span errorMessage for="pattern">Field must be not blank</span>
          </slx-input-decorator>
          <slx-input-decorator>
            <slx-date-picker-ngx slx-input [(ngModel)]="license?.expirationDate.fieldValue" required="true" [readonly]="!state.isEditMode"
              [inputPolicy]="license?.expirationDate.Policy"  [acceptNullDate]="false" placeholder="Expiration Date" name="expirationDate_{{license.seq}}_{{license.indexSeq}}"></slx-date-picker-ngx>
            <span errorMessage for="required"></span>
            <span errorMessage for="date"></span>
          </slx-input-decorator>
          <slx-input-decorator>
            <slx-dropdown-input slx-input type="text" [options]="budgetedPositionLookup" valueField="id" titleField="description" [readonly]="!state.isEditMode"
              name="budgetedPosition_{{license.seq}}_{{license.indexSeq}}" 
              placeholder="Budgeted position" [inputPolicy]="license?.budgetedPosition.Policy"
              [(ngModel)]="license?.budgetedPosition.fieldValue" emptyOption>
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
          <slx-input-decorator>
            <slx-dropdown-input slx-input type="text" [options]="licenseTypeLookup" valueField="licenseTypeID" titleField="licenseTypeName" [readonly]="!state.isEditMode"
              name="licenseType_{{license.seq}}_{{license.indexSeq}}" 
              placeholder="License Type" [inputPolicy]="license?.licenseType.Policy"
              [(ngModel)]="license?.licenseType.fieldValue" emptyOption>
            </slx-dropdown-input>
          </slx-input-decorator>
          <slx-input-decorator>
            <slx-date-picker-ngx slx-input
              [(ngModel)]="license?.issueDate.fieldValue" 
              [readonly]="!state.isEditMode"
              [inputPolicy]="license?.issueDate.Policy"
              [acceptNullDate]="true"
              placeholder="Issue Date"
              name="issueDate_{{license.seq}}_{{license.indexSeq}}"></slx-date-picker-ngx>
          </slx-input-decorator>
          <div class="slx-error-block" *ngIf="license.hasDuplicateAtSeq>-1">
            <span>License has duplicate on seq={{license.hasDuplicateAtSeq}} </span>
          </div>
        </section>
      </div>
    </div>
  </section>
</form>
