<div class="action-bar" *ngIf="standaloneMode && currentAgencyMapping">
    <button type="button" class="theme-button-apply edit-mode-btn" (click)="toggleEditMode()">
        <i class="fas fa-pencil-alt" aria-hidden="true"></i>Edit Mode
    </button>
</div>

<div *ngIf="standaloneMode && currentAgencyMapping">
    <form novalidate #form="ngForm">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-2">
                    <slx-input-decorator>
                        <input class="form-control" slx-input type="text" name="name" [(ngModel)]="tempAgencyMapping.agency.name" [readonly]="!editMode"
                            [required]="true" placeholder="Name">
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>
                </div>
                <div class="col-xs-12 col-md-2">
                    <slx-input-decorator>
                        <input class="form-control" slx-input type="text" name="address" [(ngModel)]="tempAgencyMapping.agency.address" [readonly]="!editMode"
                            placeholder="Address">
                    </slx-input-decorator>
                </div>

                <div class="col-xs-12 col-md-2">
                    <slx-input-decorator>
                        <input class="form-control" slx-input type="text" name="city" [(ngModel)]="tempAgencyMapping.agency.city" [readonly]="!editMode"
                            placeholder="City">
                    </slx-input-decorator>
                </div>

                <div class="col-xs-12 col-md-2">
                    <slx-input-decorator>
                        <input class="form-control" slx-input type="text" name="state" [(ngModel)]="tempAgencyMapping.agency.state" [readonly]="!editMode"
                            placeholder="State">
                    </slx-input-decorator>
                </div>

                <div class="col-xs-12 col-md-2">
                    <slx-input-decorator>
                        <slx-zip-input slx-input
                          name="zip"
                          placeholder="Zip code"
                          [readonly]="!editMode"
                          [(ngModel)]="tempAgencyMapping.agency.zip">
                        </slx-zip-input>
                        <span errorMessage for="min">Min length is 5</span>
                        <span errorMessage for="max">Max length is 10</span>
                    </slx-input-decorator>
                </div>

                <div *ngIf="editMode" class="col-xs-12 col-md-2 flex-horizontal command-column">
                    <button type="button" [disabled]="!form.valid" class="theme-icon-button apply-button" (click)="saveAgencyInfo ()">
                    <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="theme-icon-button cancel-button" (click)="discardAgencyInfo ()">
                    <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="title-bar">
    <span>Mapped to Organizations:</span>
</div>

<kendo-grid #kendoGrid [data]="currentAgencyMapping?.organizations" (remove)="removeHandler($event)">
    <kendo-grid-column title="Name" field="name"></kendo-grid-column>
    <kendo-grid-command-column title="Command" width="120" *ngIf="editMode">
        <ng-template kendoGridCellTemplate>
            <button kendoGridRemoveCommand [disabled]="addNewMode" type="button" class="theme-icon-button apply-button">
               <i class="fa fa-times" aria-hidden="true"></i>
            </button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>

<div *ngIf="editMode && currentAgencyMapping" class="action-bar flex-horizontal buttons-container">

    <div *ngIf="hasFreeOrganizationsForMapping" class="dropdown-container">
        <slx-input-decorator>
            <slx-dropdown-input [options]="filteredOrganizations" slx-input [required]="true" name="organization" placeholder="Select a Center"
                [(ngModel)]="organizationToAdd" [readonly]="!isAnyOrganizationWithoutAgencies">
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
        </slx-input-decorator>
    </div>
    <div *ngIf="!hasFreeOrganizationsForMapping" class="dropdown-container">
      All existing organizations have already been mapped.
    </div>

    <button type="button" [disabled]="!organizationToAdd" class="theme-button-cancel add-item-btn" (click)="addNewOrganization()">
        <i class="fa fa-plus" aria-hidden="true"></i>
        Add Organization
    </button>

</div>
