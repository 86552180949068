export class Filter {
  public fieldName: string;
  public value: any | any[];
}

export class Group {
  public fieldName: string;
}

export class ScheduleFilterConfig {
  static optInStatusFilters(): any {
    return [
      {
        text: 'Opt Out',
        value: '0'
      },
      {
        text: 'Opt In',
        value: '1'
      },
      {
        text: 'Not Responded',
        value: '2'
      }
    ]
  }
}
