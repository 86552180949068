import { Component, OnInit, Provider, ViewChild } from '@angular/core';
import { IDialog, ModalService, DialogOptions, KendoGridStateHelper } from '../../../../common/index';
import { AccrualRecalculate, AccrualRecalculateDialogOptions } from '../../models';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import { AccrualsApiService } from '../../services/accruals-api.service';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  selector: 'slx-recalculate-dialog',
  templateUrl: './recalculate-dialog.component.html',
  styleUrls: ['./recalculate-dialog.component.scss']
})

export class RecalculateDialogComponent implements IDialog, OnInit{
  public accrualRecalculate: AccrualRecalculateDialogOptions = new AccrualRecalculateDialogOptions();
  public gridState: KendoGridStateHelper<AccrualRecalculate>;
  public dialogResult: boolean;
  private options: DialogOptions;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public appConfig: IApplicationConfig;
  public orgLevelId: number;
  public isLoading: boolean = false;
  private m_initialized: boolean;
  public isRecalculateDisabled: boolean = false;
  public recalculateTooltip: string;
  public get initialized(): boolean {
    return this.m_initialized;
  }

  constructor(
    private dialogOptions: DialogOptions,
    private modalService: ModalService,
    private apiService: AccrualsApiService) {
    this.gridState = new KendoGridStateHelper<AccrualRecalculate>();
    this.gridState.state.sort = [{ field: 'requestDate', dir: 'desc' }];
    this.dialogResult = false;
    this.options = dialogOptions;
    this.appConfig = appConfig;
  }

  ngOnInit() {
    this.m_initialized = true;
  }

  public refreshGrid(): void {
    if (!this.accrualRecalculate) {
      this.gridView = null;
      return;
    }
    let filteredRecords: AccrualRecalculate[] = this.accrualRecalculate.accrual;
    let sortedRecords: AccrualRecalculate[] = orderBy(filteredRecords, this.sort);
   
    this.gridView = {
      data: sortedRecords,
      total: sortedRecords.length
    };

    this.gridState.view = process(this.accrualRecalculate.accrual, this.gridState.state);
    this.recalculateDisabled();
  }

  public recalculateDisabled(): void {
    if(_.findIndex(this.accrualRecalculate.accrual, ['startDate', null]) != -1) {
      this.isRecalculateDisabled = true;
      this.recalculateTooltip = 'Latest request has not yet finished processing';
    }
    else {
      this.isRecalculateDisabled = false;
      this.recalculateTooltip = 'Recalculate';
    }
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public onClose(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public onRefresh(): void {
    this.onLoadRecalculateData();
  }

  public onRecalculate(): void {
    if (this.orgLevelId) {
      this.isLoading = true;
      this.apiService.postAccrualsRecalculate(this.orgLevelId).
        then((status: any) => {
          this.isLoading = false;
          this.onLoadRecalculateData();
        }).catch((reason: any) => { this.isLoading = false; });
    }
  }

  public onLoadRecalculateData(): void {
    if (this.orgLevelId) {
      this.isLoading = true;
      this.apiService.getAccrualsRecalculateData(this.orgLevelId).
        then((accrualRecalculateDate: AccrualRecalculate[]) => {
          this.isLoading = false;
          this.accrualRecalculate.accrual = accrualRecalculateDate;
          this.refreshGrid();
        });
    }
  }


}
