export class TreeView {
    text: string;
    value: number;
    children: ChildTreeView[];
}

export class ChildTreeView {
    text: string;
    value: number;
    disabled: boolean;
    collapsed: boolean;
    checked: boolean;
    children: ChildTreeView[]
}