<form #form="ngForm" novalidate class="slx-full-height">
<div class="flat flex justify-content__space-between">
    <section class="slx-benefit-box flex__grow">
        <div class="slx-benefit-box__control flex align-items__center">
            <p class="slx-benefit-box__label flat__emp-label flex__width100">Default Employee Contribution</p>
            <p class="slx-benefit-box__value flex__grow contribution-section">
                <slx-number #employeeContributionField="ngModel" name="employeeContributionField"
                    class="slx-wide slx-input-number" 
                    [min]="min" [max]="max" 
                    [slxMin]="min" [slxMax]="max" 
                    [step]="step" [format]="format"
                    [autoCorrect]='false' [decimals]="2" [readonly]="!isEditMode" 
                    [(ngModel)]="employeeContribution"
                    (ngModelChange)="onChangeContribution()">
                </slx-number>
                <span *ngIf="employeeContributionField.errors?.min || employeeContributionField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
            </p>
        </div>
        <div class="slx-benefit-box__control flex align-items__center">
            <p class="slx-benefit-box__label provider__label flex__width100">Default Employer Contribution</p>
            <p class="slx-benefit-box__value flex__grow contribution-section">
                <slx-number #employerContributionField="ngModel"  name="employerContributionField"
                    class="slx-wide slx-input-number" 
                    [min]="min" [max]="max" 
                    [slxMin]="min" [slxMax]="max" 
                    [step]="step" [format]="format"
                    [autoCorrect]='false' [decimals]="2" 
                    [readonly]="!isEditMode" 
                    [(ngModel)]="employerContribution"
                    (ngModelChange)="onChangeContribution()">
                </slx-number>
                <span *ngIf="employerContributionField.errors?.min || employerContributionField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
            </p>
        </div>
    </section>
</div>
</form>