<slx-spinner [show]="state.isLoading">
  <div>
      <kendo-grid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" [sortable]="{ mode: 'single' }"
          [sort]="gridState.state.sort" [filter]="gridState.state.filter" [groupable]="false" [group]="gridState.state.group"
          [filterable]="true" [filter]="gridState.state.filter" scrollable="none" selectable="true"
          [pageable]="false">

          <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" field="isSelected" [locked]="false"
              width="35px">
              <ng-template kendoGridHeaderTemplate>
                  <input type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected()">
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                  <input [disabled]="!dataItem.selectable" type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange(dataItem)">
              </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Org Level Name" field="orgLevel.orgLevel.name" width="200">
              <ng-template kendoGridHeaderTemplate>
                  Org Level Name
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.orgLevel?.orgLevel?.name}}
              </ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                </slx-kendo-grid-string-filter>
              </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Parent Level" field="orgLevel.parentName" width="150">
              <ng-template kendoGridHeaderTemplate>
                  Parent Level
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem.orgLevel?.parentName}}
              </ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                </slx-kendo-grid-string-filter>
              </ng-template>
          </kendo-grid-column>
      </kendo-grid>
  </div>
</slx-spinner>
