import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';

import { LMCreationAbsenceManagementService } from '../../../services/index';
import { WeekDay, RecurrenceRuleOption, RecurrenceFrequencies, LMCommonRecurrence } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-weekly-recurrence',
  templateUrl: 'lm-weekly-recurrence.component.html',
  styleUrls: ['lm-weekly-recurrence.component.scss']
})
export class LMWeeklyRecurrenceComponent extends LMCommonRecurrence implements OnInit {
  public weekDays: WeekDay[];
  public limitEndOnDate: Date;
  public calculatedMaxDate: Date;

  public get canEditRequest(): boolean {
    return this.manService.canChange;
  }

  private weekLength = 7;

  constructor(private manService: LMCreationAbsenceManagementService) {
    super();
  }

  public ngOnInit(): void {
    const estimateEndDate = this.defineStartDate();
    const recurrence = this.manService.getRecurrence();

    this.defineDefaults(recurrence, estimateEndDate);
    this.defineMode(recurrence.count, recurrence.until);
    this.updatesRestrictionDates();

    this.subscriptions.loaRequest = this.manService
      .subscribeToChangedRequest(() => {
        this.defineStartDate();
        this.onChangeRecurrence();
      });
  }

  public onClickDay(day: WeekDay): void {
    const hasChangedActiveDay = this.updateActiveWeekDay(day);
    if (hasChangedActiveDay) {
      this.updateWeeklyRecurrence();
    }
  }

  public onChangeRecurrence() {
    this.updatesRestrictionDates();
    this.updateWeeklyRecurrence();
  }

  private updateWeeklyRecurrence(): void {
    const options = new RecurrenceRuleOption(this.interval, null, null);

    this.updatedRepeatMode(options);
    this.updateEndMode(options);

    this.manService.setRecurrence(RecurrenceFrequencies.weekly, options);
  }

  private updatedRepeatMode(options: RecurrenceRuleOption): void {
    const activeDays = _.reduce(this.weekDays, (accum: ({ day: number, offset: number })[], d: WeekDay) => {
      if (d.isActive) {
        accum.push({ day: d.id, offset: 0 });
      }
      return accum;
    }, []);
    options.byWeekDay = activeDays;
  }

  private updateEndMode(options: RecurrenceRuleOption): void {
    switch(this.endMode) {
      case this.endAfterOccurrence:
        options.count = this.count;
        break;
      case this.endOnDate:
        options.until = this.until;
        break;
    }
  }

  private updateActiveWeekDay(day: WeekDay): boolean {
    const weekDays = _.cloneDeep(this.weekDays);
    if (_.isObjectLike(weekDays[day.id])) {
      weekDays[day.id].isActive = !weekDays[day.id].isActive;
    }
    const activeWeekDays = _.filter(weekDays, (d) => d.isActive);
    if (activeWeekDays.length === 0) {
      return false;
    }
    this.weekDays = weekDays;
    return true;
  }

  private defineDefaults(recurrence: RecurrenceRuleOption, endDate: Date): void {
    const weekDays = _.cloneDeep(this.manService.weekDays);
    _.forEach(recurrence.byWeekDay, (wd) => {
      if (_.isObjectLike(weekDays[wd.day])) {
        weekDays[wd.day].isActive = true;
      }
    });
    this.weekDays = weekDays;
    this.interval = recurrence.interval || 1;
    this.count = recurrence.count || 1;
    this.until = recurrence.until || endDate;
  }

  private updatesRestrictionDates(): void {
    const copyOfMinDate = this.copyDate(this.limitEndOnDate);
    this.calculatedMaxDate = new Date(copyOfMinDate.getTime());
    if (!_.isDate(this.until) || this.until < this.calculatedMaxDate) {
      this.until = this.copyDate(this.calculatedMaxDate);
    }
  }

  private defineMode(count: number, until: Date): void {
    if (_.isFinite(count) && count > 0) {
      this.endMode = this.endAfterOccurrence;
    } else {
      this.endMode = this.endOnDate;
    }
  }

  private defineStartDate(): Date {
    const { endDate } = this.manService.getLoaDates();
    const { endDate: estimateEndDate } = this.manService.getEstamatedDates();
    this.limitEndOnDate = endDate || new Date();
    return estimateEndDate;
  }
}
