import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { ShiftModes } from '../../../models/index';
import { MealDeductionManagementService } from '../../../services/index';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { FormGroup } from '@angular/forms';
import { DropDownItem } from '../../../../../common/models';
var MealDeductionConfigurationComponent = /** @class */ (function () {
    function MealDeductionConfigurationComponent(managementService, orgLevelWatchService) {
        this.managementService = managementService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.shiftOneMin = 1;
        this.shiftOneMax = 7;
        this.shiftTwoMin = 9;
        this.shiftTwoMax = 15;
        this.step = 1;
        this.onSaved = new EventEmitter();
        this.maxTreshold = 1439;
        this.minTreshold = 1;
        this.state = {
            isLoading: false,
        };
        this.shiftModes = _.map(ShiftModes, function (n, i) { return new DropDownItem(i + 1, n); });
    }
    Object.defineProperty(MealDeductionConfigurationComponent.prototype, "isValid", {
        get: function () {
            return this.isFormValid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MealDeductionConfigurationComponent.prototype, "mealDeductionSettings", {
        get: function () {
            return this.m_mealDeductionSettings;
        },
        set: function (value) {
            var _this = this;
            this.m_mealDeductionSettings = value;
            this.shiftMode = _.first(this.shiftModes.filter(function (t) { return t.name == _this.m_mealDeductionSettings.shift_Type; }));
            this.updateFormValidity();
        },
        enumerable: true,
        configurable: true
    });
    MealDeductionConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formSubscription = this.form.valueChanges.subscribe(function () {
            _this.updateFormValidity();
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.loadedSubscription = this.managementService.onLoaded$
            .subscribe(function (value) {
            _this.mealDeductionSettings = value;
        });
        this.savedSubscription = this.managementService.onSaved$
            .subscribe(function (result) {
            _this.onSaved.emit(result);
        });
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$)
            .map(function (value) { return value[0]; })
            .subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id === null)
                return;
            if (!_this.currentOrgLevel || _this.currentOrgLevel.id !== orgLevel.id) {
                _this.currentOrgLevel = orgLevel;
                _this.managementService.onOrglevelChanged(_this.currentOrgLevel);
            }
        });
    };
    MealDeductionConfigurationComponent.prototype.onChangeShiftMode = function () {
        this.mealDeductionSettings.shift_Type = this.shiftMode.name;
    };
    Object.defineProperty(MealDeductionConfigurationComponent.prototype, "isMidShift", {
        get: function () {
            return this.shiftMode.name === _.last(this.shiftModes).name;
        },
        enumerable: true,
        configurable: true
    });
    MealDeductionConfigurationComponent.prototype.save = function () {
        this.managementService.saveConfiguration(this.mealDeductionSettings);
    };
    MealDeductionConfigurationComponent.prototype.updateFormValidity = function () {
        if (this.m_mealDeductionSettings) {
            this.isFormValid =
                !_.isNil(this.m_mealDeductionSettings.enableForAgency)
                    && !_.isNil(this.m_mealDeductionSettings.enableForExemptS)
                    && !_.isNil(this.m_mealDeductionSettings.enableForExemptT)
                    && !_.isNil(this.m_mealDeductionSettings.enableForExemptX)
                    && !_.isNil(this.m_mealDeductionSettings.enableForExemptY)
                    && !_.isNil(this.m_mealDeductionSettings.enableForNonExempt)
                    && !_.isNil(this.m_mealDeductionSettings.maxPunchTreshold)
                    && (!this.isMidShift
                        || this.m_mealDeductionSettings.maxPunchTreshold >= this.minTreshold
                            && this.m_mealDeductionSettings.maxPunchTreshold <= this.maxTreshold
                            && (!_.isNil(this.m_mealDeductionSettings.midShiftOne) && this.isMidShift)
                            && (!_.isNil(this.m_mealDeductionSettings.midShiftTwo) && this.isMidShift)
                            && (this.m_mealDeductionSettings.midShiftOne > 0 && this.m_mealDeductionSettings.midShiftOne < 9 && this.isMidShift)
                            && (this.m_mealDeductionSettings.midShiftTwo > 8 && this.m_mealDeductionSettings.midShiftTwo < 16 && this.isMidShift));
            return;
        }
        this.isFormValid = false;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], MealDeductionConfigurationComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MealDeductionConfigurationComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MealDeductionConfigurationComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MealDeductionConfigurationComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MealDeductionConfigurationComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MealDeductionConfigurationComponent.prototype, "formSubscription", void 0);
    return MealDeductionConfigurationComponent;
}());
export { MealDeductionConfigurationComponent };
