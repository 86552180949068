/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-messages-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../organization/directives/employee-image-src/employee-image-src.directive";
import * as i3 from "../../../organization/services/employee/employee-definitions-api.service";
import * as i4 from "../../../common/pipes/slx-time-to-now";
import * as i5 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i6 from "../../../common/components/spinner/spinner.component";
import * as i7 from "@angular/common";
import * as i8 from "./header-messages-list.component";
import * as i9 from "../../services/notifications/notifications-api.service";
var styles_HeaderMessagesListComponent = [i0.styles];
var RenderType_HeaderMessagesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderMessagesListComponent, data: {} });
export { RenderType_HeaderMessagesListComponent as RenderType_HeaderMessagesListComponent };
function View_HeaderMessagesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "list-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "user-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "circular-portrait"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["alt", "Employee Photo"], ["class", "rounded-image"]], null, null, null, null, null)), i1.ɵdid(4, 540672, null, 0, i2.EmployeeImageSrcDirective, [i1.ElementRef, i1.Renderer, i3.EmployeeDefinitionsApiService], { employeeImageSrc: [0, "employeeImageSrc"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "message-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "sender-name theme-exsm-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "moment-string theme-xs-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "msg-text theme-sm-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], function (_ck, _v) { var currVal_0 = 0; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.senderFullName; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.createdOn)); _ck(_v, 9, 0, currVal_2); var currVal_3 = _v.context.$implicit.text; _ck(_v, 12, 0, currVal_3); }); }
export function View_HeaderMessagesListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TimeToNowPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "slx-spinner", [], null, null, null, i5.View_SpinnerComponent_0, i5.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i6.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "div", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderMessagesListComponent_1)), i1.ɵdid(5, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.messages; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_HeaderMessagesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-header-messages-list", [], null, null, null, View_HeaderMessagesListComponent_0, RenderType_HeaderMessagesListComponent)), i1.ɵdid(1, 114688, null, 0, i8.HeaderMessagesListComponent, [i9.NotificationsApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderMessagesListComponentNgFactory = i1.ɵccf("slx-header-messages-list", i8.HeaderMessagesListComponent, View_HeaderMessagesListComponent_Host_0, {}, {}, []);
export { HeaderMessagesListComponentNgFactory as HeaderMessagesListComponentNgFactory };
