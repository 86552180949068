import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { IdealScheduleStateService, IdealScheduleToolbarService, IdealScheduleHelperService } from '../../../services/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { LookupService, LookupType } from '../../../../organization/index';
import { IdealScheduleConfigTypeDefinition } from '../../../models/ideal-schedule/ideal-schedule-config-type';
import { ModalService, ConfirmDialogComponent } from '../../../../common/index';
import { IdealSchedulePositionRange } from '../../../models/ideal-schedule/ideal-schedule-position-range';
var IdealScheduleRangeToolbarComponent = /** @class */ (function () {
    function IdealScheduleRangeToolbarComponent(idealScheduleToolbarService, stateService, lookupService, scheduleService, modalService) {
        this.idealScheduleToolbarService = idealScheduleToolbarService;
        this.stateService = stateService;
        this.lookupService = lookupService;
        this.scheduleService = scheduleService;
        this.modalService = modalService;
        this.maximumRangeValue = Infinity;
        this.IdealScheduleConfigTypeDefinition = IdealScheduleConfigTypeDefinition;
        this.lookups = {
            acuityLookup: null,
            shiftLookup: null,
            unitLookup: null,
            shiftModels: [],
            unitModels: []
        };
        this.selectedShifts = [];
        this.selectedUnits = [];
    }
    Object.defineProperty(IdealScheduleRangeToolbarComponent.prototype, "currentOrgLevel", {
        get: function () {
            return this.m_currentOrgLevel;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleRangeToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.m_currentOrgLevel = orgLevel;
            _this.orgLevelUpdated();
        });
        this.stateServiceChangedSubscription = this.stateService.poolChanged$.subscribe(function () {
            _this.updateLookups();
        });
    };
    IdealScheduleRangeToolbarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IdealScheduleRangeToolbarComponent.prototype.onClone = function () {
        this.stateService.cloneRange(this.range);
    };
    IdealScheduleRangeToolbarComponent.prototype.onDelete = function () {
        this.stateService.deleteRange(this.rangeIndex);
    };
    IdealScheduleRangeToolbarComponent.prototype.filtersChanged = function (data) {
        var _this = this;
        if (this.discarding || !data) {
            return;
        }
        var promise = Promise.resolve(true);
        var deselectedUnits = _.filter(this.stateService.getSelectedUnits(this.range), function (unit) {
            return unit.isSelected && !_.some(_this.selectedUnits, function (selectedUnit) { return selectedUnit.object.id === unit.object.id; });
        });
        var deselectedShifts = _.filter(this.stateService.getSelectedShifts(this.range), function (shift) {
            return shift.isSelected && !_.some(_this.selectedShifts, function (selectedShift) { return selectedShift.object.id === shift.object.id; });
        });
        var hasHours = this.positionHasHours(deselectedUnits, deselectedShifts);
        if (hasHours) {
            promise = this.showDiscardHoursDialog();
        }
        promise.then(function (replaceFilters) {
            _this.discarding = false;
            if (replaceFilters) {
                _this.stateService.setSelectedUnits(_.map(_this.selectedUnits, function (selectedUnit) {
                    selectedUnit.isSelected = true;
                    return selectedUnit;
                }), _this.range);
                _this.stateService.setSelectedShifts(_.map(_this.selectedShifts, function (selectedShift) {
                    selectedShift.isSelected = true;
                    return selectedShift;
                }), _this.range);
            }
            else {
                _this.selectedUnits = _.map(_this.stateService.getSelectedUnits(_this.range), function (selectedUnit) {
                    selectedUnit.isSelected = true;
                    return selectedUnit;
                });
                _this.selectedShifts = _.map(_this.stateService.getSelectedShifts(_this.range), function (selectedShift) {
                    selectedShift.isSelected = true;
                    return selectedShift;
                });
            }
            _this.stateService.reconfigureRangeParLevels(_this.range);
        });
    };
    IdealScheduleRangeToolbarComponent.prototype.showDiscardHoursDialog = function () {
        var _this = this;
        this.discarding = true;
        return new Promise(function (resolve) {
            ConfirmDialogComponent.openDialog('Discard changes', 'The deselected unit/shift has been used in this Ideal Schedule. Are you sure you wish to proceed?', _this.modalService, function (result) {
                resolve(result);
                if (result) {
                    _this.stateService.rangeChanged();
                }
            });
        });
    };
    IdealScheduleRangeToolbarComponent.prototype.positionHasHours = function (units, shifts) {
        var hasHours = false;
        if (!this.range) {
            return false;
        }
        if ((!units || !units.length) && (!shifts || !shifts.length)) {
            return false;
        }
        var parLevels = _.values(this.range.parLevels);
        if (units.length) {
            var unitFilteredParLevels = _.filter(parLevels, function (parLevel) { return _.some(units, function (unit) { return parLevel.unit && parLevel.unit.id === unit.object.id; }); });
            hasHours = hasHours || _.some(unitFilteredParLevels, function (parLevel) { return !parLevel.isUnused(); });
        }
        if (shifts.length) {
            var shiftFilteredParLevels = _.filter(parLevels, function (parLevel) { return _.some(shifts, function (shift) { return parLevel.shift && parLevel.shift.id === shift.object.id; }); });
            hasHours = hasHours || _.some(shiftFilteredParLevels, function (parLevel) { return !parLevel.isUnused(); });
        }
        return hasHours;
    };
    IdealScheduleRangeToolbarComponent.prototype.orgLevelUpdated = function () {
        var _this = this;
        var acuityPromise = this.lookupService.getLookup({
            lookupType: LookupType.acuity,
            orgLevelId: this.m_currentOrgLevel.id,
            employeeId: undefined
        });
        var shiftPromise = this.lookupService.getLookup({
            lookupType: LookupType.shiftDefinition,
            orgLevelId: this.m_currentOrgLevel.id,
            employeeId: undefined
        });
        var unitPromise = this.lookupService.getLookup({
            lookupType: LookupType.locationUnit,
            orgLevelId: this.m_currentOrgLevel.id,
            employeeId: undefined
        });
        Promise.all([acuityPromise, shiftPromise, unitPromise]).then(function (arrays) {
            _this.lookups.acuityLookup = arrays[0];
            _this.lookups.shiftLookup = arrays[1];
            _this.lookups.unitLookup = arrays[2];
            _this.updateLookups(true);
        });
    };
    IdealScheduleRangeToolbarComponent.prototype.updateLookups = function (updateFilters) {
        if (updateFilters === void 0) { updateFilters = false; }
        if (!this.stateService.selectedPosition || !this.range) {
            return;
        }
        var array = _.values(this.range.parLevels);
        if (this.lookups.shiftLookup) {
            this.lookups.shiftModels = this.scheduleService.filterShiftLookup(array, this.lookups.shiftLookup);
            this.selectedShifts = _.filter(this.lookups.shiftModels, function (shiftModel) { return shiftModel.isSelected; });
        }
        if (this.lookups.unitLookup) {
            this.lookups.unitModels = this.scheduleService.filterUnitLookup(array, this.lookups.unitLookup);
            this.selectedUnits = _.filter(this.lookups.unitModels, function (unitModel) { return unitModel.isSelected; });
        }
        if (this.lookups.acuityLookup && !this.range.acuity.acuity) {
            this.range.acuity.acuity = _.first(this.lookups.acuityLookup.items);
        }
        if (updateFilters) {
            this.filtersChanged();
        }
    };
    tslib_1.__decorate([
        mutableSelect(),
        tslib_1.__metadata("design:type", Observable)
    ], IdealScheduleRangeToolbarComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleRangeToolbarComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleRangeToolbarComponent.prototype, "stateServiceChangedSubscription", void 0);
    return IdealScheduleRangeToolbarComponent;
}());
export { IdealScheduleRangeToolbarComponent };
