import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ManagementBaseService } from '../../../core/services/index';

import { SupervisorGroupDefinition } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/index';
import { SupervisorAssignmentApiService } from './supervisor-assignment-api.service';
import { Supervisor, EligibleSupervisor, SupervisedEmployee, SupervisorGroup, SupervisorGroupsContainer, SupervisorAssignmentAction, UnassignedEmployee } from '../../models/index';

export type supervisorsGroup = { group: SupervisorGroup, supervisors: Supervisor[] };
export type employeesGroup = { group: SupervisorGroup, employees: SupervisedEmployee[] };

@Injectable()
export class SupervisorAssignmentManagementService extends ManagementBaseService<SupervisorGroupsContainer, any> {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public startAddGroupCmd$: Subject<any>;
  public startEditGroupCmd$: Subject<any>;
  public startAddSupervisorCmd$: Subject<any>;
  public startAddEmployeeCmd$: Subject<any>;

  public addedGroupCmd$: Subject<SupervisorGroup>;
  public editedGroupCmd$: Subject<SupervisorGroup>;
  public deletedGroupCmd$: Subject<SupervisorGroup>;
  public deletedSupervisorsCmd$: ReplaySubject<supervisorsGroup>;
  public deletedSupervisedEmployeesCmd$: ReplaySubject<employeesGroup>;

  public addedSupervisorsCmd$: ReplaySubject<supervisorsGroup>;
  public addedSupervisedEmployeesCmd$: ReplaySubject<employeesGroup>;

  public loadedUnassignedEmployeesCmd$: ReplaySubject<UnassignedEmployee[]>;

  public groupsSelected$: ReplaySubject<SupervisorGroup[]>;
  public action$: Subject<SupervisorAssignmentAction>;

  public currentOrgLevel: OrgLevel;
  public container: SupervisorGroupsContainer;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;

  constructor(private apiService: SupervisorAssignmentApiService) {
    super();
    this.groupsSelected$ = new ReplaySubject<SupervisorGroup[]>(1);
    this.action$ = new Subject<SupervisorAssignmentAction>();
    this.startAddGroupCmd$ = new Subject<any>();
    this.startEditGroupCmd$ = new Subject<any>();
    this.startAddSupervisorCmd$ = new Subject<SupervisorGroup>();
    this.startAddEmployeeCmd$ = new Subject<SupervisorGroup>();

    this.addedGroupCmd$ = new Subject<SupervisorGroup>();
    this.editedGroupCmd$ = new Subject<SupervisorGroup>();
    this.deletedGroupCmd$ = new Subject<SupervisorGroup>();

    this.addedSupervisorsCmd$ = new ReplaySubject<supervisorsGroup>(1);
    this.deletedSupervisorsCmd$ = new ReplaySubject<supervisorsGroup>(1);

    this.addedSupervisedEmployeesCmd$ = new ReplaySubject<employeesGroup>(1);
    this.deletedSupervisedEmployeesCmd$ = new ReplaySubject<employeesGroup>(1);

    this.loadedUnassignedEmployeesCmd$ = new ReplaySubject<UnassignedEmployee[]>(1);

    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) => !this.currentOrgLevel || o && this.currentOrgLevel.id !== o.id)
      .subscribe((o: OrgLevel) => {
        if (!o || !o.id) {
          return;
        }
        this.onOrgLevelChanged(o);
      });
  }

  public actionCmd(act: SupervisorAssignmentAction): void {
    this.action$.next(act);
  }
  public selectedGroups(group: SupervisorGroup[]): void {
    this.groupsSelected$.next(group);
  }

  public startAddGroupCmd(): void {
    this.startAddGroupCmd$.next(null);
  }

  public startEditGroupCmd(): void {
    this.startEditGroupCmd$.next(null);
  }

  public startAddSupervisorCmd(group: SupervisorGroup): void {
    this.startAddSupervisorCmd$.next(group);
  }

  public startAddEmployeeCmd(group: SupervisorGroup): void {
    this.startAddEmployeeCmd$.next(group);
  }

  public addGroupCmd(group: SupervisorGroup): void {
    this.onLoadStatusChanged(true);
    let grp: SupervisorGroupDefinition = new SupervisorGroupDefinition();
    grp.name = group.name;
    grp.orgLevelId = group.orgLevel.id;

    this.apiService.createGroup(grp)
      .then((res: SupervisorGroupDefinition) => {
        this.onLoadStatusChanged(false);
        group.id = res.id;
        this.addedGroupCmd$.next(group);
        this.onLoaded(this.container);
      })
      .catch((reason: any) => {
        this.onError(reason);
        this.startEditGroupCmd$.next(group);
      });
  }

  public editGroupCmd(group: SupervisorGroup): void {
    this.onLoadStatusChanged(true);
    let grp: SupervisorGroupDefinition = new SupervisorGroupDefinition();
    grp.id = group.id;
    grp.name = group.name;
    grp.orgLevelId = group.orgLevel.id;
    this.apiService.updateGroup(grp)
      .then((res: SupervisorGroupDefinition) => {
        this.onLoadStatusChanged(false);
        this.editedGroupCmd$.next(group);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public deleteGroupCmd(group: SupervisorGroup): void {
    this.onLoadStatusChanged(true);
    this.apiService.deleteGroup(this.currentOrgLevel.id, group)
      .then((res: any) => {
        this.onLoadStatusChanged(false);
        this.container.groups = _.without(this.container.groups, group);
        this.deletedGroupCmd$.next(group);
        this.onLoaded(this.container);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public addSupervisorsToGroupCmd(group: SupervisorGroup, esupervisors: EligibleSupervisor[]): void {
    this.onLoadStatusChanged(true);
    this.actionCmd(SupervisorAssignmentAction.saveSupervisorsToGroup);
    let supervisors: Supervisor[] = _.map(esupervisors, (esupervisor: EligibleSupervisor) => {
      let supervisor: Supervisor = new Supervisor();
      //supervisor.id = 0;
      supervisor.user = esupervisor.user ? esupervisor.user : undefined;
      if (esupervisor.employee && esupervisor.employee.employee && !isNaN(esupervisor.employee.employee.id) && esupervisor.employee.employee.id !== null) {
        supervisor.employee = esupervisor.employee;
      }
      supervisor.isPrimary = false;
      supervisor.approvePTO = false;
      supervisor.punchOverride = false;
      supervisor.groups = esupervisor.groups;
      return supervisor;
    });
    this.apiService.assignSupervisorsToGroup(this.currentOrgLevel.id, group.id, supervisors)
      .then((res: Supervisor[]) => {
        this.onLoadStatusChanged(false);
        this.addedSupervisorsCmd$.next({ group: group, supervisors: res });
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }


  public updateSupervisor(supervisor: Supervisor): void {
    this.onLoadStatusChanged(true);
    this.apiService.updateSupervisor(supervisor)
      .then((res: Supervisor) => {
        this.onLoadStatusChanged(false);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public deleteSupervisorsFromGroupCmd(group: SupervisorGroup, supervisors: Supervisor[]): void {
    this.onLoadStatusChanged(true);
    this.apiService.removeSupervisorsFromGroup(this.currentOrgLevel.id, group.id, supervisors)
      .then((res: Supervisor[]) => {
        this.onLoadStatusChanged(false);
        this.deletedSupervisorsCmd$.next({ group: group, supervisors: res });
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public addSupervisedEmployeesToGroupCmd(group: SupervisorGroup, employees: SupervisedEmployee[]): void {
    this.onLoadStatusChanged(true);
    this.actionCmd(SupervisorAssignmentAction.saveSupervisedEmployeesToGroup);
    this.apiService.assignEmployeesToGroup(this.currentOrgLevel.id, group.id, employees)
      .then((res: SupervisedEmployee[]) => {
        this.onLoadStatusChanged(false);
        this.addedSupervisedEmployeesCmd$.next({ group: group, employees: res });
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public deleteSupervisedEmployeesFromGroupCmd(group: SupervisorGroup, employees: SupervisedEmployee[]): void {
    this.onLoadStatusChanged(true);
    this.apiService.removeEmployeesFromGroup(this.currentOrgLevel.id, group.id, employees)
      .then((res: SupervisedEmployee[]) => {
        this.onLoadStatusChanged(false);
        this.deletedSupervisedEmployeesCmd$.next({ group: group, employees: res });
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public loadGroups(orgLevelId: number): void {
    this.onLoadStatusChanged(true);
    this.apiService.getSupervisorGroups(orgLevelId)
      .then((container: SupervisorGroupsContainer) => {
        this.container = container;
        this.onLoadStatusChanged(false);
        this.onLoaded(container);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public loadUnassignedForCurrentOrgLevel(): void {
    this.loadUnassignedEmployees(this.currentOrgLevel.id);
  }

  public loadUnassignedEmployees(orgLevelId: number): void {
    this.onLoadStatusChanged(true);
    this.apiService.getUnassignedEmployees(orgLevelId).then((employees: UnassignedEmployee[]) => {
      this.onLoadStatusChanged(false);
      this.loadedUnassignedEmployeesCmd$.next(employees);
    }).catch((reason: any) => {
      this.onError(reason);
    });
  }

  private onOrgLevelChanged(o: OrgLevel): void {
    if (this.currentOrgLevel && this.currentOrgLevel.id === o.id) {
      return;
    }
    this.currentOrgLevel = o;
    if (this.currentOrgLevel) {
      if (this.currentOrgLevel.type === OrgLevelType.corporation) {
        this.onStateChanged({ orgLvl: false });
      } else {
        this.onStateChanged({ orgLvl: true });
        this.loadGroups(this.currentOrgLevel.id);
      }
    }
  }
}
