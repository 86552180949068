import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { authenticationConfig } from '../../authentication.config';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { AuthApiService } from '../../../core/services/index';
import { AuthenticationMapService } from './authentication.map-service';
import { SessionService } from '../../../core/services/session/session.service';
import { StatusCodes } from '../../../core/models/api/status-codes';
var AuthenticationApiService = /** @class */ (function () {
    function AuthenticationApiService(apiService, authenticationMapService, sessionService) {
        Assert.isNotNull(apiService, 'apiService');
        Assert.isNotNull(authenticationMapService, 'authenticationMapService');
        Assert.isNotNull(sessionService, 'sessionService');
        this.apiService = apiService;
        this.authenticationMapService = authenticationMapService;
        this.sessionService = sessionService;
    }
    AuthenticationApiService.prototype.getAuthorizationCode = function (authorizationRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            return tslib_1.__generator(this, function (_a) {
                url = appConfig.api.url + "/" + appConfig.api.version + "/authentication/authorize";
                request = new HttpRequest('POST', url, authorizationRequest);
                promise = this.apiService.request(request)
                    .then(function (response) {
                    return response;
                });
                return [2 /*return*/, promise];
            });
        });
    };
    AuthenticationApiService.prototype.authenticate = function (username, password, alias) {
        var _this = this;
        Assert.isNotNull(username, 'username');
        Assert.isNotNull(password, 'password');
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.authenticate;
        var body = {
            username: username,
            password: password
        };
        var request = new HttpRequest('POST', url, body);
        var promise = this.apiService.requestUnauthorized(request, alias)
            .then(function (response) { return _this.authenticationMapService.mapToAuthResponse(response.data); });
        return promise;
    };
    AuthenticationApiService.prototype.validateAuthSession = function (state) {
        var _this = this;
        Assert.isNotNull(state, 'state');
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.validateAuthSession;
        var request = new HttpRequest('POST', url, JSON.stringify(state), { withCredentials: true });
        var promise = this.apiService.requestUnauthorizedNoAlias(request)
            .then(function (response) { return _this.authenticationMapService.mapToAuthResponse(response.data); });
        return promise;
    };
    AuthenticationApiService.prototype.logout = function () {
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.logout;
        var body = {
            token: this.sessionService.getToken(),
        };
        var request = new HttpRequest('POST', url, body);
        return this.apiService.request(request)
            .then(function (response) { return response.data; });
    };
    AuthenticationApiService.prototype.renew = function () {
        var _this = this;
        var isSessionExpired = this.sessionService.isExpired();
        if (isSessionExpired) {
            var meta = new Meta('Session has expired.');
            var response = new ResponseBody(StatusCodes.unauthorized, undefined, meta);
            var promise_1 = Promise.reject(response);
            return promise_1;
        }
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.renew;
        var body = {
            token: this.sessionService.getToken(),
        };
        var request = new HttpRequest('POST', url, body);
        var promise = this.apiService.request(request)
            .then(function (response) { return _this.authenticationMapService.mapToModel(response.data); });
        return promise;
    };
    AuthenticationApiService.prototype.requestResetPassword = function (usernameWithAlias, gRecaptchaResponse) {
        var _this = this;
        Assert.isNotNull(usernameWithAlias, 'usernameWithAlias');
        Assert.isNotNull(gRecaptchaResponse, 'gRecaptchaResponse');
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.forgotPassword;
        var userName;
        var alias = '';
        if (usernameWithAlias.includes('@')) {
            var values = usernameWithAlias.split('@');
            userName = values[0];
            alias = values[1];
        }
        else {
            userName = usernameWithAlias;
        }
        var body = {
            userName: userName,
            gRecaptchaResponse: gRecaptchaResponse
        };
        var request = new HttpRequest('POST', url, body);
        var promise = this.apiService.requestUnauthorized(request, alias)
            .then(function (response) { return _this.authenticationMapService.mapForgotPassword(response.data); });
        return promise;
    };
    AuthenticationApiService.prototype.resetPasswordByOldPassword = function (username, oldPassword, newPassword, alias) {
        var _this = this;
        Assert.isNotNull(username, 'username');
        Assert.isNotNull(oldPassword, 'oldPassword');
        Assert.isNotNull(newPassword, 'newPassword');
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.changePassword;
        var body = {
            username: username,
            oldPassword: oldPassword,
            newPassword: newPassword
        };
        var request = new HttpRequest('POST', url, body);
        return this.apiService.requestUnauthorized(request, alias)
            .then(function (response) { return _this.authenticationMapService.mapResetPassword(response.data); });
    };
    AuthenticationApiService.prototype.resetPasswordByToken = function (token, username, password, alias) {
        var _this = this;
        Assert.isNotNull(token, 'token');
        Assert.isNotNull(username, 'username');
        Assert.isNotNull(password, 'password');
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.resetPassword;
        var body = {
            token: token,
            username: username,
            password: password
        };
        var request = new HttpRequest('POST', url, body);
        var promise = this.apiService.requestUnauthorized(request, alias)
            .then(function (response) { return _this.authenticationMapService.mapResetPassword(response.data); });
        return promise;
    };
    AuthenticationApiService.prototype.validatePasswordUnsecure = function (alias, password) {
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.validatePassword;
        var body = {
            password: password
        };
        var request = new HttpRequest('POST', url, body);
        return this.apiService.requestUnauthorized(request, alias)
            .then(function (response) {
            return response.data;
        });
    };
    AuthenticationApiService.prototype.validatePassword = function (password) {
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.validatePassword;
        var body = {
            password: password
        };
        var request = new HttpRequest('POST', url, body);
        return this.apiService.request(request)
            .then(function (response) {
            return response.data;
        });
    };
    AuthenticationApiService.prototype.loadPasswordPolicyUnsecure = function (alias) {
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + authenticationConfig.api.passwordSettings;
        var request = new HttpRequest('GET', url);
        return this.apiService.requestUnauthorized(request, alias)
            .then(function (response) {
            return response.data;
        });
    };
    return AuthenticationApiService;
}());
export { AuthenticationApiService };
