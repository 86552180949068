import * as _ from 'lodash';
import * as moment from 'moment';
import { Router, ActivatedRoute, UrlTree, Params } from '@angular/router';

export class BenefitsNavigationService {
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToConsole(orgLevelId?: number): void {
    let queryParams: any = {};
    if (_.isNumber(orgLevelId)) {
      queryParams.orgLevelId = orgLevelId;
    }
    this.router.navigateByUrl(this.getConsoleUrlTree(queryParams));
  }

  public navigateToDetails(orgLevelId: number, groupId?: number, providerId?: number, providerLineId?: number): void {
    let queryParams: StringMap<any> = {};
    if (_.isNumber(orgLevelId)) {
      queryParams.orgLevelId = orgLevelId;
    }
    if (_.isNumber(groupId)) {
      queryParams.groupId = groupId;
    }
    if (_.isNumber(providerId)) {
      queryParams.providerId = providerId;
    }
    if (_.isNumber(providerLineId)) {
      queryParams.providerLineId = providerLineId;
    }
    this.router.navigateByUrl(this.getDetailsUrlTree(queryParams));
  }

  public updateQueryParams(gId: number, pId?: number, plId?: number): void {
    const params: Params = {};
    if (_.isFinite(gId)) {
      params.groupId = gId;
    }
    if (_.isFinite(pId)) {
      params.providerId = pId;
    }
    if (_.isFinite(plId)) {
      params.providerLineId = plId;
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: params,
        queryParamsHandling: 'merge'
      });
  }

  private getConsoleUrlTree(params: StringMap<any>): UrlTree {
    return this.getUrlTree('benefits_management_console', params);
  }

  private getDetailsUrlTree(params: StringMap<any>): UrlTree {
    return this.getUrlTree('benefits_management_details', params);
  }

  private getUrlTree(pageName: string, params: StringMap<any>): UrlTree {
    return this.router.createUrlTree([pageName], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
  }
}
