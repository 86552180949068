import { Component, Input } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { Shift } from '../../../models/shifts/shift';

@Component({
  moduleId: module.id,
  selector: 'slx-shift-remove-dialog',
  templateUrl: 'shift-remove-dialog.component.html',
  styleUrls: ['shift-remove-dialog.component.scss']
})

export class ShiftRemoveDialogComponent implements IDialog {

    public dialogResult: boolean;
    public shift: Shift;
    @Input()
    public shifts: Shift[] = [];

    private modalService: ModalService;
    private options: DialogOptions;

    constructor(options: DialogOptions, modalService: ModalService) {
        this.options = options;
        this.modalService = modalService;
    }

    public onOk(): void {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }

    public onCancel(): void {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }
}
