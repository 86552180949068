import { BenefitDetailsBasic } from './benefit-details-basic';
export var BenefitFormulaCalculationType;
(function (BenefitFormulaCalculationType) {
    BenefitFormulaCalculationType["Formula"] = "Formula";
    BenefitFormulaCalculationType["Fixed"] = "Fixed";
    BenefitFormulaCalculationType["MultiplerMaxCap"] = "MultiplerMaxCap";
    BenefitFormulaCalculationType["AnyValueMaxCap"] = "AnyValueMaxCap";
})(BenefitFormulaCalculationType || (BenefitFormulaCalculationType = {}));
export var BenefitExpressionType;
(function (BenefitExpressionType) {
    BenefitExpressionType["Sql"] = "Sql";
    BenefitExpressionType["NetExpression"] = "NetExpression";
})(BenefitExpressionType || (BenefitExpressionType = {}));
var BenefitDetailsFormula = /** @class */ (function () {
    function BenefitDetailsFormula() {
        this.formula = new BenefitDetailsBasic(null, null, null);
    }
    Object.defineProperty(BenefitDetailsFormula.prototype, "isFormulaCalcType", {
        get: function () {
            return this.calculationType === BenefitFormulaCalculationType.Formula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsFormula.prototype, "isFixedCalcType", {
        get: function () {
            return this.calculationType === BenefitFormulaCalculationType.Fixed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsFormula.prototype, "isMultiplierCalcType", {
        get: function () {
            return this.calculationType === BenefitFormulaCalculationType.MultiplerMaxCap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsFormula.prototype, "isAnyValueMaxCapCalcType", {
        get: function () {
            return this.calculationType === BenefitFormulaCalculationType.AnyValueMaxCap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsFormula.prototype, "isSqlExpressionType", {
        get: function () {
            return this.expressionType === BenefitExpressionType.Sql;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsFormula.prototype, "isNetExpressionType", {
        get: function () {
            return this.expressionType === BenefitExpressionType.NetExpression;
        },
        enumerable: true,
        configurable: true
    });
    return BenefitDetailsFormula;
}());
export { BenefitDetailsFormula };
