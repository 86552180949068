import * as tslib_1 from "tslib";
import { Provider, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { OpenShiftSummary, OpenShiftDetails } from '../../../models/index';
import { EditOpenShiftCountComponent } from '../edit-open-shift-count/edit-open-shift-count.component';
import { appConfig } from '../../../../app.config';
import { DialogOptions } from '../../../../common/models/dialog-options';
import { OpenShiftManagementManagementService, OpenShiftManagementApiService } from '../../../../scheduler/services/index';
import { OPEN_SHIFT_COUNT_TOKEN } from '../../../../core/models/index';
import { mutableSelect } from '../../../../core/decorators/index';
var DailyShiftMessagesContainerComponent = /** @class */ (function () {
    function DailyShiftMessagesContainerComponent(modalService, openShiftManagementManagementService, openShiftManagementApiService) {
        this.modalService = modalService;
        this.openShiftManagementApiService = openShiftManagementApiService;
        this.openShiftManagementManagementService = openShiftManagementManagementService;
    }
    DailyShiftMessagesContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            _this.selectedOrgLevel = o;
        });
    };
    DailyShiftMessagesContainerComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    DailyShiftMessagesContainerComponent.prototype.onEditOpenShiftsClick = function (event, groupDetails) {
        var _this = this;
        event.preventDefault();
        event.stopPropagation();
        var shiftCount = groupDetails.openShiftCount;
        var options = new DialogOptions();
        options.height = 150;
        options.width = 320;
        var resolvedProvidersConf = [
            { provide: DialogOptions, useValue: options },
            { provide: OPEN_SHIFT_COUNT_TOKEN, useValue: shiftCount },
        ];
        var modal = this.modalService.globalAnchor.openDialog(EditOpenShiftCountComponent, 'New Shift Count', options, resolvedProvidersConf, function (result, uniqueId) {
            if (result) {
                _this.changeOpenShiftCount(groupDetails, modal.adjustedShiftCount);
            }
        });
    };
    DailyShiftMessagesContainerComponent.prototype.changeOpenShiftCount = function (groupDetails, value) {
        var _this = this;
        this.openShiftManagementApiService.setOpenShiftScheduleCycleSummary(this.selectedOrgLevel.id, groupDetails.dateOn, { positionId: groupDetails.position.id, shiftId: groupDetails.shift.id, unitId: groupDetails.unit.id, originalShiftCount: groupDetails.openShiftCount, newShiftCount: value })
            .then(function (result) {
            var newOpen = value - groupDetails.scheduledShiftCount;
            newOpen = newOpen > 0 ? newOpen : 0;
            var shiftCountDiff = groupDetails.openShiftCount - value;
            groupDetails.openShiftCount = newOpen;
            groupDetails.calculatedOpenShiftCount = newOpen;
            _this.openShiftManagementManagementService.onOpenShiftCountChanged({ dateOn: groupDetails.dateOn, shiftCountDiff: shiftCountDiff });
        });
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], DailyShiftMessagesContainerComponent.prototype, "orgLevel$", void 0);
    return DailyShiftMessagesContainerComponent;
}());
export { DailyShiftMessagesContainerComponent };
