import * as tslib_1 from "tslib";
import { SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { unsubscribe } from '../../../../core/decorators/index';
import { ArrivalsDeparturesTimelineDetailsManagementService } from '../../../services/index';
import { LookupType } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';
import { ArrivalDeparturesVariances } from '../../../models/index';
import { ToolbarSectionTypes } from '../../../../common/models/index';
var ArrivalsDeparturesTimelineDetailsToolbarComponent = /** @class */ (function () {
    function ArrivalsDeparturesTimelineDetailsToolbarComponent(managementService, lookupService) {
        this.managementService = managementService;
        this.lookupService = lookupService;
        this.variances = [
            { value: ArrivalDeparturesVariances.late },
            { value: ArrivalDeparturesVariances.approachingOT },
            { value: ArrivalDeparturesVariances.arrivals },
            { value: ArrivalDeparturesVariances.departures }
        ];
    }
    ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onLoadSubscription = this.managementService.onLoaded$.subscribe(function (details) {
            _this.commonFilterItems = _.map(details.records, function (r) { return r.employee; });
            _this.onLoadSubscription.unsubscribe();
        });
        this.toggleOverrageSubscription = this.managementService.onToogleShowOverragesView$.subscribe(function (value) {
            _this.isShowOverages = value;
        });
        this.onFilterChangedSubscription = this.managementService.onFilterChanged$.subscribe(function (filter) {
            _this.filter = filter;
            _this.unitFilter = filter.units;
            _this.appliedCommonFilterItems = filter.empFilter;
            _this.variancesFilter = _.map(filter.variances, function (v) { return { value: v }; });
        });
    };
    ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (this.orgLevelId) {
            this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: this.orgLevelId })
                .then(function (locationUnitLookup) {
                _this.units = locationUnitLookup.items;
            });
        }
    };
    ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype.onUnitChanged = function () {
        this.filter.units = this.unitFilter;
        this.managementService.onFilterChanged(this.filter);
    };
    ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype.onVariancesChanged = function () {
        this.filter.variances = _.map(this.variancesFilter, function (v) { return v.value; });
        this.managementService.onFilterChanged(this.filter);
    };
    ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype.onShowOveragesChanged = function () {
        this.managementService.toggleShowOverrages(this.isShowOverages);
    };
    ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype.onCommonFilterChanged = function () {
        this.filter.empFilter = this.appliedCommonFilterItems;
        this.managementService.onFilterChanged(this.filter);
    };
    ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype.onModeChanged = function () {
        this.managementService.toggleTimeline(this.isExpanded ? 'Simple' : 'GroupByShift');
    };
    ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype.isVisibleSection = function (sectionType) {
        return sectionType === ToolbarSectionTypes.VISIBLE;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype, "onFilterChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype, "onLoadSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineDetailsToolbarComponent.prototype, "toggleOverrageSubscription", void 0);
    return ArrivalsDeparturesTimelineDetailsToolbarComponent;
}());
export { ArrivalsDeparturesTimelineDetailsToolbarComponent };
