export class EmployeeToTransfer {
  public employeeId: number;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public suffix: string;
  public organization: string;
  public organizationId: number;
  public department: string;
  public departmentId: number;
  public position: string;
  public positionId: number;
  public hireDate: Date;
  public fullName: string;
  public employeeType: string;
  public payrollNumber: string;
}
