import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'weather-current-temperature',
  styleUrls: ['current-temperature.component.scss'],
  templateUrl: 'current-temperature.component.html'
})
export class WeatherCurrentTempComponent {
  @Input() 
  public temp: number;

  @Input()
  public scaleLabel: string;
}
