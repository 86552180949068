var ScheduleNotPosted = /** @class */ (function () {
    function ScheduleNotPosted() {
    }
    Object.defineProperty(ScheduleNotPosted.prototype, "organizationName", {
        get: function () {
            return this.organization ? this.organization.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleNotPosted.prototype, "departmentName", {
        get: function () {
            return this.department ? this.department.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleNotPosted.prototype, "scheduleCycleStartDate", {
        get: function () {
            return this.scheduleCycle ? this.scheduleCycle.startDate.toDate() : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleNotPosted.prototype, "scheduleCycleEndDate", {
        get: function () {
            return this.scheduleCycle ? this.scheduleCycle.endDate.toDate() : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleNotPosted.prototype, "schedulecyclestartDay", {
        get: function () {
            return this.schedulecyclestart + " days";
        },
        enumerable: true,
        configurable: true
    });
    return ScheduleNotPosted;
}());
export { ScheduleNotPosted };
