<slx-spinner [show]="stateService.isLoading">
  <div class="ideal-schedule-container" *ngIf="stateService.state.isScheduleAvailable">
    <slx-ideal-schedule-toolbar
      [checkData]="checkData"
      [exportToExcel]="exportToExcel"
      [captureIdealScheduleScreen]="captureIdealScheduleScreen"
      class="slx-content-toolbar-indents slx-block ideal-schedule-header"
    ></slx-ideal-schedule-toolbar>

    <div *ngIf="stateService.selectedPositionDefinition" class="slx-main-content-indents" id="contentToConvert">
      <div class="position">
        <div class="position-name">
          {{ stateService.selectedPositionDefinition?.position.name }}
          <i class="fa fa-folder group-icon" aria-hidden="true">
            <span class="group-label">Group:</span>
          </i>
          <ng-container *ngIf="stateService.selectedPosition?.position.positionGroupName; else elseNotDone">
            <span class="group-name">{{ stateService.selectedPosition?.position.positionGroupName }}</span>
          </ng-container>
          <ng-template #elseNotDone>
            <span class="group-name">None</span>
          </ng-template>
        </div>

        <div class="grid-toolbar-container condensed">
          <slx-ideal-schedule-grid-toolbar></slx-ideal-schedule-grid-toolbar>
        </div>

        <div class="grid-container" [ngSwitch]="stateService.selectedConfigCensus?.id" id="exportAsExcel">
          <slx-ideal-schedule-fixed-grid
            [ranges]="ranges"
            *ngSwitchCase="IdealScheduleConfigCensusDefinition.Fixed"
          ></slx-ideal-schedule-fixed-grid>
          <slx-ideal-schedule-range-grid
            [ranges]="ranges"
            *ngSwitchCase="IdealScheduleConfigCensusDefinition.Range"
          ></slx-ideal-schedule-range-grid>
          <slx-ideal-schedule-total-grid
            [ranges]="ranges"
            *ngSwitchCase="IdealScheduleConfigCensusDefinition.Total"
          ></slx-ideal-schedule-total-grid>
        </div>
      </div>
    </div>
  </div>

  <div
    class="flex-center-page"
    *ngIf="
      stateService.state.isNoDepartmentLevel || stateService.state.isNoPositionsAvailable || stateService.state.isError
    "
  >
    <div *ngIf="stateService.state.isNoDepartmentLevel">
      The Ideal Schedule screen is only available at the department level. <br />Please change the selected organization
      level.
    </div>

    <div *ngIf="stateService.state.isNoPositionsAvailable">
      There are no valid positions detected for this department. <br />Please configure the required positions and try
      again.
    </div>

    <div *ngIf="stateService.state.isError">
      Sorry, something went wrong.<br />
      We're working on getting this fixed as soon as we can.
    </div>
  </div>
</slx-spinner>
