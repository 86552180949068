import { OnChanges, SimpleChanges, SimpleChange, EventEmitter, AfterViewInit, } from '@angular/core';
import * as _ from 'lodash';
import { BenefitEligibilityRuleVariable, BenefitEligibilityRuleConditionType, benefitEligibilityRuleConditions, benefitEligibilityRuleBooleanTypes, benefitEligibilityRulePayTypes, BenefitEligibilityRuleVariableEnum, BenefitEligibilityRulePredicateEnum, BenefitEligibilityRuleDataType, } from '../../../models/benefit-eligibility-rules/benefit-eligibility-rule-model';
import * as uuid from 'uuid';
import { BenefitEligibilityRuleStatementsManagementService } from '../../../services/benefit-eligibility-rules/index';
var BenefitDetailsEligibilityStatementsClauseComponent = /** @class */ (function () {
    function BenefitDetailsEligibilityStatementsClauseComponent(ruleStatementsManagementService) {
        this.ruleStatementsManagementService = ruleStatementsManagementService;
        this.uniqueComponentId = uuid.v4();
        this.variable = null;
        this.value = '';
        this.dataType = null;
        this.condition = this.benefitEligibilityRuleConditions[0];
        this.predicate = null;
        this.currentPredicates = [];
        this.benefitClassDefinitions = [];
        this.isInNotInPredicate = false;
        this.isMinMaxPredicate = false;
        this.state = {
            isControlCreating: true,
        };
        this.ruleVariableChanged = new EventEmitter();
        this.conditionTypeChanged = new EventEmitter();
    }
    Object.defineProperty(BenefitDetailsEligibilityStatementsClauseComponent.prototype, "benefitEligibilityRuleConditions", {
        get: function () {
            return benefitEligibilityRuleConditions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsEligibilityStatementsClauseComponent.prototype, "benefitEligibilityRuleBooleanTypes", {
        get: function () {
            return benefitEligibilityRuleBooleanTypes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsEligibilityStatementsClauseComponent.prototype, "benefitEligibilityRulePayTypes", {
        get: function () {
            return benefitEligibilityRulePayTypes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsEligibilityStatementsClauseComponent.prototype, "BenefitEligibilityRuleDataType", {
        get: function () {
            return BenefitEligibilityRuleDataType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsEligibilityStatementsClauseComponent.prototype, "BenefitEligibilityRuleVariableEnum", {
        get: function () {
            return BenefitEligibilityRuleVariableEnum;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // avoid - Expression has changed after it was checked
        setTimeout(function () {
            _this.state.isControlCreating = false;
        }, 0);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.ngOnChanges = function (changes) {
        var componentChanges = changes;
        var conditionTypeChange = componentChanges.conditionType;
        if (conditionTypeChange) {
            var conditionType = conditionTypeChange.currentValue;
            var previousConditionType = conditionTypeChange.previousValue;
            if (!_.isEqual(conditionType, previousConditionType)) {
                this.condition = conditionType;
            }
        }
        var configurationVariablesChange = componentChanges.configurationVariables;
        if (configurationVariablesChange) {
            var configurationVariables = configurationVariablesChange.currentValue;
            var previousConfigurationVariables = configurationVariablesChange.previousValue;
            if (!_.isEqual(configurationVariables, previousConfigurationVariables)) {
                var variableId = this.ruleVariable ? this.ruleVariable.variableId : null;
                this.variable = this.ruleStatementsManagementService.findConfigurationVariable(variableId, configurationVariables);
                this.setCurrentPredicates();
            }
        }
        var ruleVariableChange = componentChanges.ruleVariable;
        if (ruleVariableChange) {
            var currentVariable = ruleVariableChange.currentValue;
            var previousVariable = ruleVariableChange.previousValue;
            if (!_.isEqual(currentVariable, previousVariable)) {
                this.setRuleVariable(currentVariable);
            }
        }
        var configurationPredicatesChange = componentChanges.configurationPredicates;
        if (configurationPredicatesChange) {
            var configurationPredicates = configurationPredicatesChange.currentValue;
            var previousConfigurationPredicates = configurationPredicatesChange.previousValue;
            if (!_.isEqual(configurationPredicates, previousConfigurationPredicates)) {
                this.configurationPredicates = configurationPredicates;
            }
        }
        var benefitClassesChange = componentChanges.benefitClasses;
        if (benefitClassesChange) {
            var currentBenefitClassesChange = benefitClassesChange.currentValue;
            var previousBenefitClassesChange = benefitClassesChange.previousValue;
            if (!_.isEqual(currentBenefitClassesChange, previousBenefitClassesChange)) {
                this.benefitClassDefinitions = _.map(currentBenefitClassesChange, function (bc) { return bc.benefitClassDefinition; });
                if (this.ruleVariable) {
                    this.setRuleVariable(this.ruleVariable);
                }
            }
        }
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.setCurrentPredicates = function () {
        if (this.variable) {
            this.currentPredicates = this.variable.predicates;
        }
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.onRuleConfigurationVariableChanged = function (configurationVariable) {
        this.currentPredicates = configurationVariable ? configurationVariable.predicates : [];
        this.value = '';
        var dataType = configurationVariable ? configurationVariable.dataType : null;
        var variableId = configurationVariable ? configurationVariable.id : null;
        var valueType = configurationVariable ? configurationVariable.variableType : null;
        var predicateEnumValue = this.predicate ? this.predicate.predicateEnum : null;
        var newRule = new BenefitEligibilityRuleVariable({
            variableId: variableId,
            operatorType: predicateEnumValue,
            valueType: valueType,
            value: this.value,
            dataType: dataType,
        });
        this.ruleVariableChanged.next(newRule);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.onRuleConfigurationPredicateChanged = function (predicate) {
        this.predicate = predicate;
        this.isInNotInPredicate = this.isPredicateMulti(this.predicate);
        this.isMinMaxPredicate = this.isPredicateMinMax(this.predicate);
        var variableId = this.variable ? this.variable.id : null;
        var variableType = this.variable ? this.variable.variableType : null;
        var predicateEnumValue = predicate ? predicate.predicateEnum : null;
        var newRule = new BenefitEligibilityRuleVariable({
            variableId: variableId,
            operatorType: predicateEnumValue,
            valueType: variableType,
            value: this.value,
            dataType: this.dataType,
        });
        this.ruleVariableChanged.next(newRule);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.onRuleConditionTypeChanged = function () {
        this.conditionType = this.condition;
        this.conditionTypeChanged.next(this.condition);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.convertStringToArray = function (stringValue) {
        if (!stringValue) {
            return [];
        }
        var formattedValuesArray = _.chain(stringValue)
            .split(',')
            .map(function (item) { return _.trim(item); })
            .value();
        return formattedValuesArray;
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.convertBenefitClassNamesToIds = function (stringValue) {
        var _this = this;
        if (!stringValue) {
            return [];
        }
        var formattedIdsArray = _.chain(stringValue)
            .split(',')
            .map(function (item) { return _.trim(item); })
            .map(function (item) {
            var benefitClassDefinition = _.find(_this.benefitClassDefinitions, function (benefitClass) { return benefitClass.name.toLowerCase() === item.toLowerCase(); });
            return benefitClassDefinition ? benefitClassDefinition.id.toString() : null;
        })
            .filter(function (item) { return item !== null; })
            .value();
        return formattedIdsArray;
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.convertBenefitClassIdsToNames = function (idValues) {
        var _this = this;
        if (!idValues) {
            return '';
        }
        var formattedNamesArray = _.chain(idValues)
            .split(',')
            .map(function (stringId) { return +stringId; })
            .map(function (id) {
            var benefitClassDefinition = _.find(_this.benefitClassDefinitions, function (benefitClass) { return benefitClass.id === id; });
            return benefitClassDefinition ? benefitClassDefinition.name : null;
        })
            .filter(function (item) { return item !== null; })
            .join(',')
            .value();
        return formattedNamesArray;
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.singleValueOrPairChanged = function (newInputValue) {
        this.ruleVariableValueChanged(newInputValue);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.payTypeValueChanged = function (payType) {
        if (this.variable.id !== BenefitEligibilityRuleVariableEnum.PayType) {
            return;
        }
        var newValue = payType.id.toString();
        this.selectedBenefitRulePayType = payType;
        this.ruleVariableValueChanged(newValue);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.benefitClassValueChanged = function (benefitClass) {
        if (this.variable.id !== BenefitEligibilityRuleVariableEnum.BenefitClass) {
            return;
        }
        var newValue = benefitClass.id.toString();
        this.selectedBenefitDefinition = benefitClass;
        this.ruleVariableValueChanged(newValue);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.multiValuesChanged = function (newMultiValueInput) {
        var isMulti = this.isPredicateMulti(this.predicate);
        if (!isMulti) {
            return;
        }
        var newValue = this.convertStringToArray(newMultiValueInput);
        this.ruleVariableValueChanged(newValue);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.multiBenefitClassValueChanged = function (newInputType) {
        var benefitClassIds = this.convertBenefitClassNamesToIds(newInputType);
        this.value = this.convertBenefitClassIdsToNames(benefitClassIds);
        this.ruleVariableValueChanged(benefitClassIds);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.setSelectedBenefitClassDefinitionOrPayType = function (ruleVariable) {
        var _this = this;
        if (ruleVariable) {
            var ruleVariableStringValue_1 = _.isNull(ruleVariable.value) ? ruleVariable.value : ruleVariable.value.toString();
            if (ruleVariable.variableId === BenefitEligibilityRuleVariableEnum.BenefitClass) {
                _.forEach(this.benefitClassDefinitions, function (cd) {
                    if (cd.id.toString() === ruleVariableStringValue_1) {
                        _this.selectedBenefitDefinition = cd;
                    }
                });
            }
            if (ruleVariable.variableId === BenefitEligibilityRuleVariableEnum.PayType) {
                _.forEach(this.benefitEligibilityRulePayTypes, function (btype) {
                    if (btype.id.toString() === ruleVariableStringValue_1) {
                        _this.selectedBenefitRulePayType = btype;
                    }
                });
            }
        }
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.setRuleVariable = function (ruleVariable) {
        this.dataType = ruleVariable ? ruleVariable.dataType : null;
        var variableId = ruleVariable ? ruleVariable.variableId : null;
        this.variable = this.ruleStatementsManagementService.findConfigurationVariable(variableId, this.configurationVariables);
        var operatorType = ruleVariable ? ruleVariable.operatorType : null;
        this.setCurrentPredicates();
        if (this.currentPredicates && this.currentPredicates.length > 0) {
            this.predicate = _.find(this.currentPredicates, function (predicate) { return operatorType === predicate.predicateEnum; });
            this.isInNotInPredicate = this.isPredicateMulti(this.predicate);
            this.isMinMaxPredicate = this.isPredicateMinMax(this.predicate);
        }
        var value = ruleVariable ? ruleVariable.value : null;
        if (variableId === BenefitEligibilityRuleVariableEnum.BenefitClass && this.isInNotInPredicate) {
            this.value = this.convertBenefitClassIdsToNames(value);
        }
        else {
            this.value = value;
        }
        this.setSelectedBenefitClassDefinitionOrPayType(ruleVariable);
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.isPredicateMinMax = function (predicate) {
        var isMinMax = false;
        if (!predicate) {
            return isMinMax;
        }
        var predicateEnumValue = predicate.predicateEnum;
        if (predicateEnumValue === BenefitEligibilityRulePredicateEnum.Between) {
            isMinMax = true;
        }
        return isMinMax;
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.isPredicateMulti = function (predicate) {
        var inNotInPredicateUsed = false;
        if (!predicate) {
            return inNotInPredicateUsed;
        }
        var predicateEnumValue = predicate.predicateEnum;
        if (predicateEnumValue === BenefitEligibilityRulePredicateEnum.In ||
            predicateEnumValue === BenefitEligibilityRulePredicateEnum.NotIn) {
            inNotInPredicateUsed = true;
        }
        return inNotInPredicateUsed;
    };
    BenefitDetailsEligibilityStatementsClauseComponent.prototype.ruleVariableValueChanged = function (value) {
        var variableId = this.variable ? this.variable.id : null;
        var variableType = this.variable ? this.variable.variableType : null;
        var predicateEnumValue = this.predicate ? this.predicate.predicateEnum : null;
        var newRule = new BenefitEligibilityRuleVariable({
            variableId: variableId,
            operatorType: predicateEnumValue,
            valueType: variableType,
            value: value,
            dataType: this.dataType,
        });
        this.ruleVariableChanged.next(newRule);
    };
    return BenefitDetailsEligibilityStatementsClauseComponent;
}());
export { BenefitDetailsEligibilityStatementsClauseComponent };
