import {
  Component,
  Input,
  Output,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  OnDestroy, HostListener
} from '@angular/core';
import { PopoverDirective } from '../../directives/popover/popover.directive';

interface IPoint {
  top: number;
  left: number;
}
interface IRect {
  width: number;
  height: number;
  top: number;
  left: number;
}
@Component({
  moduleId: module.id,
  selector: 'popover-content',
  templateUrl: 'popover-content.component.html',
  styleUrls: ['popover-content.component.scss'],
})
export class PopoverContentComponent implements AfterViewInit {

  @Input()
  public placement: 'top' | 'bottom' | 'left' | 'right' | 'auto' | 'auto top' | 'auto bottom' | 'auto left' | 'auto right';
  @Input() public title: string;
  @Input() public topOffset: number;
  @Input() public leftOffset: number;
  @Input() public disableClickOutside: boolean = false;

  @Output() public onCloseFromOutside: EventEmitter<boolean>;
  @Output() public onShow: EventEmitter<boolean>;
  @Output() public onMouseLeaveMouseEnter: EventEmitter<boolean>; // true = mouseover, false = mouseout

  public popoverDir: PopoverDirective;
  public top: number;
  public left: number;
  public isIn: boolean;
  public effectivePlacement: string;
  public animation: boolean;

  @ViewChild('popoverDiv', { static: true })
  private popoverDiv: ElementRef;
  private elementRef: ElementRef;
  private cdr: ChangeDetectorRef;
  private displayType: string;
  private mouseDown: Function;

  constructor(elementRef: ElementRef, cdr: ChangeDetectorRef) {
    this.elementRef = elementRef;
    this.cdr = cdr;
    this.onCloseFromOutside = new EventEmitter<boolean>();
    this.onMouseLeaveMouseEnter = new EventEmitter<boolean>();
    this.onShow = new EventEmitter<boolean>();
    this.top = -10000;
    this.left = -10000;
    this.isIn = false;
    this.placement = 'bottom';
    this.displayType = 'none';
    this.topOffset = 0;
    this.leftOffset = 0;
  }

  public ngAfterViewInit(): void {
    this.show();
    this.cdr.detectChanges();
  }

  public show(): void {
    if (!this.popoverDir || !this.popoverDir.getElement()) {
      return;
    }
    const p: IPoint = this.positionElements(this.popoverDir.getElement(), this.popoverDiv.nativeElement, this.placement);
    this.displayType = 'block';
    this.top = p.top;
    this.left = p.left;
    this.isIn = true;
    this.onShow.emit(true);
  }

  public hide(): void {
    this.displayType = 'none';
    this.top = -10000;
    this.left = -10000;
    this.isIn = true;
    if (this.popoverDir) this.popoverDir.hide();

  }

  public hideFromPopover(): void {
    this.top = -10000;
    this.left = -10000;
    this.isIn = true;
  }



  @HostListener('mouseenter', ['$event'])
  private showOnMouseEnter(): void {
    this.onMouseLeaveMouseEnter.emit(true);
  }

  @HostListener('mouseleave', ['$event'])
  private hideOnMouseLeave(event: MouseEvent): void {
    if (this.disableClickOutside) return;
    this.onMouseLeaveMouseEnter.emit(false);
  }

  @HostListener('document:mousedown', ['$event'])
  private onDocumentMouseDown(event: MouseEvent): void {
    if (this.disableClickOutside) return;
    const element: Element = this.elementRef.nativeElement;
    if (!element || !this.popoverDiv || !this.popoverDir) {
      return;
    }
    let srcElement: Element = <any>event.srcElement || <Element>event.target;
    if (element.contains(srcElement) || this.popoverDir.getElement().contains(srcElement)) {
      return;
    }
    this.hide();
    this.onCloseFromOutside.emit(false);
  }


  private positionElements(hostEl: HTMLElement, targetEl: HTMLElement, positionStr: string, appendToBody: boolean = false): IPoint {
    let positionStrParts: string[] = positionStr.split('-');
    let pos0: string = positionStrParts[0];
    let pos1: string = positionStrParts[1] || 'center';

    let hostElPos: IRect = appendToBody ? this.offset(hostEl) : this.position(hostEl);
    let targetElWidth: number = targetEl.offsetWidth;
    let targetElHeight: number = targetEl.offsetHeight;

    this.effectivePlacement = this.getEffectivePlacement(pos0, hostEl, targetEl);

    let alignH: string = this.effectivePlacement;
    let alignW: string = pos1;

    let shiftWidth: any = {
      center: function (): number {
        return hostElPos.left + hostElPos.width / 2 - targetElWidth / 2;
      },
      left: function (): number {
        return hostElPos.left;
      },
      right: function (): number {
        return hostElPos.left + hostElPos.width;
      }
    };

    let shiftHeight: any = {
      center: function (): number {
        let desiredShift: number = hostElPos.top + hostElPos.height / 2 - targetElHeight / 2;
        return desiredShift > 0 ? desiredShift : 0;
      },
      top: function (): number {
        return hostElPos.top;
      },
      bottom: function (): number {
        return hostElPos.top + hostElPos.height;
      }
    };

    let targetElPos: IPoint;
    switch (this.effectivePlacement) {
      case 'right':
        targetElPos = {
          top: shiftHeight.top(),
          left: shiftWidth[alignH]()
        };
        break;

      case 'left':
        targetElPos = {
          top: shiftHeight.top(),
          left: hostElPos.left - targetElWidth
        };
        break;

      case 'bottom':
        targetElPos = {
          top: shiftHeight[alignH](),
          left: shiftWidth[alignW]()
        };
        break;

      default:
        targetElPos = {
          top: hostElPos.top - targetElHeight,
          left: shiftWidth[alignW]()
        };
        break;
    }
    targetElPos.left += +this.leftOffset;
    targetElPos.top += +this.topOffset;
    return targetElPos;
  }

  private position(nativeEl: HTMLElement): IRect {
    let offsetParentBCR: IPoint = { top: 0, left: 0 };
    const elBCR: IRect = this.offset(nativeEl);
    const offsetParentEl: any = this.parentOffsetEl(nativeEl);
    if (offsetParentEl !== window.document) {
      offsetParentBCR = this.offset(offsetParentEl);
      offsetParentBCR.top += offsetParentEl.clientTop - offsetParentEl.scrollTop;
      offsetParentBCR.left += offsetParentEl.clientLeft - offsetParentEl.scrollLeft;
    }

    const boundingClientRect: ClientRect = nativeEl.getBoundingClientRect();
    return {
      width: boundingClientRect.width || nativeEl.offsetWidth,
      height: boundingClientRect.height || nativeEl.offsetHeight,
      top: elBCR.top - offsetParentBCR.top,
      left: elBCR.left - offsetParentBCR.left
    };
  }

  private offset(nativeEl: any): IRect {
    const boundingClientRect: ClientRect = nativeEl.getBoundingClientRect();
    return {
      width: boundingClientRect.width || nativeEl.offsetWidth,
      height: boundingClientRect.height || nativeEl.offsetHeight,
      top: boundingClientRect.top + (window.pageYOffset || window.document.documentElement.scrollTop),
      left: boundingClientRect.left + (window.pageXOffset || window.document.documentElement.scrollLeft)
    };
  }

  private getStyle(nativeEl: HTMLElement, cssProp: string): string {
    if ((nativeEl as any).currentStyle) // IE
      return (nativeEl as any).currentStyle[cssProp];

    if (window.getComputedStyle)
      return (window.getComputedStyle as any)(nativeEl)[cssProp];

    return (nativeEl.style as any)[cssProp];
  }

  private isStaticPositioned(nativeEl: HTMLElement): boolean {
    return (this.getStyle(nativeEl, 'position') || 'static') === 'static';
  }

  private parentOffsetEl(nativeEl: HTMLElement): any {
    let offsetParent: any = nativeEl.offsetParent || window.document;
    while (offsetParent && offsetParent !== window.document && this.isStaticPositioned(offsetParent)) {
      offsetParent = offsetParent.offsetParent;
    }
    return offsetParent || window.document;
  }

  private getEffectivePlacement(placement: string, hostElement: HTMLElement, targetElement: HTMLElement): string {
    const placementParts: string[] = placement.split(' ');
    if (placementParts[0] !== 'auto') {
      return placement;
    }

    const hostElBoundingRect: ClientRect = hostElement.getBoundingClientRect();

    const desiredPlacement: string = placementParts[1] || 'bottom';

    if (desiredPlacement === 'top' && hostElBoundingRect.top - targetElement.offsetHeight < 0) {
      return 'bottom';
    }
    if (desiredPlacement === 'bottom' && hostElBoundingRect.bottom + targetElement.offsetHeight > window.innerHeight) {
      return 'top';
    }
    if (desiredPlacement === 'left' && hostElBoundingRect.left - targetElement.offsetWidth < 0) {
      return 'right';
    }
    if (desiredPlacement === 'right' && hostElBoundingRect.right + targetElement.offsetWidth > window.innerWidth) {
      return 'left';
    }

    return desiredPlacement;
  }
}
