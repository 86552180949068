import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
import { LookupApiService } from '../../../organization/services/index';
import { BudgetDefinition } from '../../../organization/models/index';
import { OrgLevelType } from '../../../state-model/models/index';
var BudgetSelectorDropdownComponent = /** @class */ (function () {
    function BudgetSelectorDropdownComponent(lookupApiService) {
        this.lookupApiService = lookupApiService;
        this.budgetDefinitionSelected = new EventEmitter();
        this.budgetDefinitionAdd = new EventEmitter();
        this.state = {
            isLoading: false,
            isCorporation: false
        };
        this.appConfig = appConfig;
    }
    BudgetSelectorDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.ignoreOrgLevelChange) {
            this.orgLevelSubscription = this.orgLevel$
                .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
                .subscribe(function (o) {
                _this.setOrglevel(o);
            });
        }
    };
    BudgetSelectorDropdownComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    BudgetSelectorDropdownComponent.prototype.setOrglevel = function (o) {
        if (o.type === OrgLevelType.department) {
            this.selectedOrganizationOrgLevelId = o.parentId;
            this.state.isCorporation = false;
            this.loadBudgetsDefinitions(this.selectedOrganizationOrgLevelId);
        }
        else if (o.type === OrgLevelType.organization) {
            this.selectedOrganizationOrgLevelId = o.id;
            this.state.isCorporation = false;
            this.loadBudgetsDefinitions(this.selectedOrganizationOrgLevelId, true);
        }
        else {
            this.selectedOrganizationOrgLevelId = 0;
            this.state.isCorporation = true;
        }
        this.selectedOrgLevel = o;
    };
    BudgetSelectorDropdownComponent.prototype.SelectBudgetDefinition = function (itemToSelect, riseEvent) {
        if (this.budgetDefinitions && this.budgetDefinitions.length > 0) {
            if (!!itemToSelect && !!this.budgetDefinitions) {
                this.selectedBudgetDefinition = _.find(this.budgetDefinitions, function (item) {
                    return item.id === itemToSelect.id;
                });
            }
            if (!this.selectedBudgetDefinition) {
                this.selectedBudgetDefinition = this.budgetDefinitions[0];
            }
            if (riseEvent) {
                this.onBudgetDefinitionSelect(this.selectedBudgetDefinition);
            }
        }
        else {
            if (riseEvent) {
                this.selectedBudgetDefinition = null;
                this.onBudgetDefinitionSelect(this.selectedBudgetDefinition);
            }
        }
    };
    BudgetSelectorDropdownComponent.prototype.onBudgetDefinitionAdd = function () {
        this.budgetDefinitionAdd.emit(null);
    };
    BudgetSelectorDropdownComponent.prototype.loadBudgetsDefinitions = function (orgLevelId, updateCacheForced, isDeleteBudget) {
        var _this = this;
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        if (isDeleteBudget === void 0) { isDeleteBudget = false; }
        this.lookupApiService.getBudgetDefinitions(orgLevelId, updateCacheForced)
            .then(function (budgetDefinitions) {
            _this.budgetDefinitions = _.orderBy(budgetDefinitions, function (item) { return moment(item.startDate).unix(); }, 'desc');
            if (_this.budgetDefinitions && _this.budgetDefinitions.length > 0 && !isDeleteBudget) {
                _this.onBudgetDefinitionSelect(_this.budgetDefinitions[0]);
            }
        });
    };
    BudgetSelectorDropdownComponent.prototype.onBudgetDefinitionSelect = function (item) {
        if (!this.selectedBudgetDefinition ||
            !!item &&
                (this.selectedBudgetDefinition.id != item.id
                    || this.selectedBudgetDefinition.startDate != item.startDate
                    || this.selectedBudgetDefinition.endDate != item.endDate)) {
            this.selectedBudgetDefinition = item;
            this.budgetDefinitionSelected.emit(this.selectedBudgetDefinition);
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], BudgetSelectorDropdownComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetSelectorDropdownComponent.prototype, "orgLevelSubscription", void 0);
    return BudgetSelectorDropdownComponent;
}());
export { BudgetSelectorDropdownComponent };
