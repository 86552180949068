import { PayCycle, IOrganization } from '../../../organization/models/index';
import { ExceptionColumn, IExceptionColumn } from './exception-column';
import { ExceptionConsoleRollupRecord, IExceptionConsoleRollupRecord } from './exception-console-rollup-record';
import { IOrganizationDefinition, Organization } from '../../../employee/employee-search/models';

export class ExceptionConsoleRollupContainer {
    public payCycle: PayCycle;
    public organization: Organization;
    public exceptionColumns: ExceptionColumn[];
    public records: ExceptionConsoleRollupRecord[];
}

export class IExceptionConsoleRollupContainer {
    public payCycle: PayCycle;
    public organization: IOrganizationDefinition;
    public exceptions: IExceptionColumn[];
    public records: IExceptionConsoleRollupRecord[];
}
