<div class="modal-body">
  <div class="row">
      <label class="col-xs-6">Start Date:</label>
      <div class="col-xs-6">
          <span>{{benefitDetailsLineStartDate | amDateFormat: appConfig.planDateTimeFormat }}</span>
      </div>
  </div>
  <form #form="ngForm" novalidate>
  <div class="row">
      <label class="col-xs-6">New End Date:</label>
      <div class="col-xs-6">
          <slx-datepicker class="slx-padd-r benefit-provider__date"
              name="dateField"
              [minDate]="minValidDate"
              [maxDate]="maxValidDate"
              [(ngModel)]="benefitDetailsLineEndDate"
              (ngModelChange)="onChangeEndDate()">
          </slx-datepicker>
      </div>
  </div>
  <div class="row" *ngIf="benefitdeduction2">
    <label class="col-xs-6">Deduction End Date:</label>
    <div class="col-xs-6">
        <slx-datepicker class="slx-padd-r benefit-provider__date"
            name="DedEndDate"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [(ngModel)]="beneDedDates.payrollDeductionEndDate"
            (ngModelChange)="onChangeDedEndDate()">
        </slx-datepicker>
    </div>
  </div>
  <div class="row" *ngIf="benefitdeduction2 && isAfterDeductionDate">
    <div class="col-xs-12 slx-error-block">
      Deduction End Date cannot be after the Deduction End Date on the Deduction Master List in Payroll {{ maxDedDate | amDateFormat:
        appConfig.dateFormat }}.
    </div>
  </div>
  <div class="row" *ngIf="form.valid && benefitdeduction2 && isDedAfterBeneEndDt && !isAfterDeductionDate">
    <div class="col-xs-12 warning-message">
      Payroll Deduction End Date is set after the Benefit Coverage End Date
    </div>
  </div>
  <div class="row" *ngIf="form.valid && benefitdeduction2 && isBeneAfterDedMasterDate && !isAfterDeductionDate">
    <div class="col-xs-12 warning-message">
      Benefits End Date is set after the Deduction End Date on the Deduction Master List in Payroll.
    </div>
  </div>
</form>
  <div class="row" *ngIf="canNotExtend">
    <div class="col-xs-12 warning-message">
        This timeline cannot be extended
    </div>
  </div>
  <div class="row">
      <div class="col-xs-12 warning-message" *ngIf="form.valid && !canNotExtend && !isAfterDeductionDate">
          This will automatically extend coverage for enrolled employees and dependents
      </div>
  </div>
  <div class="row" *ngIf="form.invalid && !isAfterDeductionDate">
    <div class="col-xs-12 warning-message">
        Select a date within {{this.minValidDate | amDateFormat: appConfig.dateFormat }} - {{ this.maxValidDate  | amDateFormat: appConfig.dateFormat }} to not overlap with an existing timeline.
    </div>
  </div>
</div>

<div class="modal-footer">
    <button type="button" (click)="save()" class="btn green" [disabled]="form.invalid || canNotExtend || isAfterDeductionDate"><i class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save</button>
    <button type="button" class="btn red" (click)="cancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
</div>
