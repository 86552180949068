/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-preloader-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./info-preloader-dialog.component";
import * as i3 from "../../models/info-preloader-model/info-preloader-params";
import * as i4 from "../../models/dialog-options";
import * as i5 from "../../services/modal/modal.service";
var styles_InfoPreloaderDialogComponent = [i0.styles];
var RenderType_InfoPreloaderDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoPreloaderDialogComponent, data: {} });
export { RenderType_InfoPreloaderDialogComponent as RenderType_InfoPreloaderDialogComponent };
export function View_InfoPreloaderDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "preloader-contaner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "text-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "line header-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "line message-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "report-display-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "line actions-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "discard-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(15, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "image-holder ", _co.className, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.params.title; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.params.firstMessage; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.params.styleMessage; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.params.lastMessage; _ck(_v, 11, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.params.buttonIconClass, ""); _ck(_v, 14, 0, currVal_5); var currVal_6 = _co.params.buttonLabel; _ck(_v, 15, 0, currVal_6); }); }
export function View_InfoPreloaderDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-info-preloader-dialog", [], null, null, null, View_InfoPreloaderDialogComponent_0, RenderType_InfoPreloaderDialogComponent)), i1.ɵdid(1, 114688, null, 0, i2.InfoPreloaderDialogComponent, [i3.InfoPreloaderParams, i4.DialogOptions, i5.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoPreloaderDialogComponentNgFactory = i1.ɵccf("slx-info-preloader-dialog", i2.InfoPreloaderDialogComponent, View_InfoPreloaderDialogComponent_Host_0, {}, {}, []);
export { InfoPreloaderDialogComponentNgFactory as InfoPreloaderDialogComponentNgFactory };
