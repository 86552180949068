import * as tslib_1 from "tslib";
import { LookupEntity } from './lookup-entity';
var Shift = /** @class */ (function (_super) {
    tslib_1.__extends(Shift, _super);
    function Shift() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Shift;
}(LookupEntity));
export { Shift };
