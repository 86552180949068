import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { appConfig } from '../../../../app.config';
import { wcConfig } from '../../workers-compensation.config';

import { FieldsMeta } from '../../../../core/models/index';
import { ApiUtilService } from '../../../../common/index';
import { UrlParamsService } from '../../../../common/services/url-params/url-params.service';

import { dateTimeUtils } from '../../../../common/utils/index';

import { ResponseBody } from '../../../../core/models/api/response-body';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { WcIncidentRosterContainer, IWcIncidentRosterContainer, IWcGenerateOsha301APDF, IWcGenerateOsha300APDF, WcComparisonsContainer, IWcComparisonsContainer, CreateOsha300AFormDialogOptions, ICreateOsha300AFormDialogOptions } from '../../models/index';
import { WcRosterMapService } from './wc-roster-map.service';

@Injectable()
export class WcRosterApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private mapService: WcRosterMapService,
    private urlParamsService: UrlParamsService
  ) {
  }

  public async getIncidentRoster(orgLevelId: number, sDate: Date, eDate, incidentsAmount: number = null): Promise<WcIncidentRosterContainer> {
    const url: string = this.getRosterApi();
    const params: StringMap<any> = {
      orgLevelId: orgLevelId,
      start: dateTimeUtils.convertToDtoString(sDate),
      end: dateTimeUtils.convertToDtoString(eDate)
    };
    if (_.isFinite(incidentsAmount)) {
      params.minCount = incidentsAmount;
    }
    const request = this.urlParamsService.createGetRequest(url, params);

    return this.apiUtilService
      .request<IWcIncidentRosterContainer, FieldsMeta>(request)
      .then((response: ResponseBody<IWcIncidentRosterContainer, FieldsMeta>) => this.mapService.mapInsidentRosterContainer(response.data, response.meta));
  }

  public async getOshaIncidentRoster(orgLevelId: number, sDate: Date, eDate): Promise<WcIncidentRosterContainer> {
    const url: string = this.getOshaApi();
    const params: StringMap<any> = {
      orgLevelId: orgLevelId,
      start: dateTimeUtils.convertToDtoString(sDate),
      end: dateTimeUtils.convertToDtoString(eDate)
    };
    const request = this.urlParamsService.createGetRequest(url, params);

    return this.apiUtilService
      .request<IWcIncidentRosterContainer, FieldsMeta>(request)
      .then((response: ResponseBody<IWcIncidentRosterContainer, FieldsMeta>) => this.mapService.mapInsidentRosterContainer(response.data, response.meta));
  }

  public generateOshaExcel(orgLevelId: number, year: number, InjuryIds: number[]): Promise<FileBlobResponse> {
    const url: string = `${this.getApiRoot()}/wc/generateOsha/${orgLevelId}/${year}`;
    const request = this.urlParamsService.createPostRequest(url, InjuryIds);

    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);

  }


  public generateOshaPDF300(data: IWcGenerateOsha300APDF): Promise<FileBlobResponse> {
    const url: string = `${this.getApiRoot()}/wcpdfform/osha300pdf`;
    const body = this.mapService.mapGenerateOshaPDF300TypeModelToDto(data)
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public generateOshaPDF301(data: IWcGenerateOsha301APDF): Promise<FileBlobResponse> {
    const url: string = `${this.getApiRoot()}/wcpdfform/osha301pdf`;
    const body = this.mapService.mapGenerateOshaPDF301TypeModelToDto(data)
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public async getComparisons(orgLevelId: number, sDate: Date, eDate: Date): Promise<WcComparisonsContainer> {
    const url: string = this.getComparisonsApi();
    const params: StringMap<any> = {
      orgLevelId: orgLevelId,
      start: dateTimeUtils.convertToDtoString(sDate),
      end: dateTimeUtils.convertToDtoString(eDate)
    };

    const request = this.urlParamsService.createGetRequest(url, params);
    return this.apiUtilService
      .request<IWcComparisonsContainer, FieldsMeta>(request)
      .then((response: ResponseBody<WcComparisonsContainer, FieldsMeta>) => this.mapService.mapComparisonsContainer(response.data));
  }

  public async getEstablismentSize(orgLevelId: number): Promise<CreateOsha300AFormDialogOptions> {
    const url: string = `${this.getApiRoot()}/configuration/organizations/${orgLevelId}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .request<ICreateOsha300AFormDialogOptions, FieldsMeta>(request)
      .then((response: ResponseBody<ICreateOsha300AFormDialogOptions, FieldsMeta>) => this.mapService.mapEstablishmentSize(response.data));
  }

  public async updateEstablismentSize(orgLevelId: number, size: number): Promise<any> {
    const url: string = `${this.getApiRoot()}/configuration/organizationsize/${orgLevelId}/${size}`;
    const request = this.urlParamsService.createPutRequest(url);
    
    const promise = await this.apiUtilService.request<ICreateOsha300AFormDialogOptions, FieldsMeta>(request);
    return promise;
  }

  private getOshaApi(): string {
    return `${this.getWcApi()}/${wcConfig.api.wc.osha.root}`;
  }

  private getRosterApi(): string {
    return `${this.getWcApi()}/${wcConfig.api.wc.roster.root}`;
  }

  private getComparisonsApi(): string {
    return `${this.getWcApi()}/${wcConfig.api.wc.comparisons.root}`;
  }

  private getWcApi(): string {
    return `${this.getApiRoot()}/${wcConfig.api.wc.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
