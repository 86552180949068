/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./weather-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../weather-current-details/weather-current-details.component.ngfactory";
import * as i3 from "../weather-current-details/weather-current-details.component";
import * as i4 from "../weather-icon/weather-icon.component.ngfactory";
import * as i5 from "../weather-icon/weather-icon.component";
import * as i6 from "../weather-location/weather-location.component.ngfactory";
import * as i7 from "../weather-location/weather-location.component";
import * as i8 from "../weather-current-temperature/current-temperature.component.ngfactory";
import * as i9 from "../weather-current-temperature/current-temperature.component";
import * as i10 from "@angular/common";
import * as i11 from "./weather-container.component";
import * as i12 from "../../services/api/weather.api.service";
var styles_WeatherContainerComponent = [i0.styles];
var RenderType_WeatherContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WeatherContainerComponent, data: {} });
export { RenderType_WeatherContainerComponent as RenderType_WeatherContainerComponent };
function View_WeatherContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "weather-current-details", [], null, null, null, i2.View_WeatherCurrentDetailsComponent_0, i2.RenderType_WeatherCurrentDetailsComponent)), i1.ɵdid(1, 49152, null, 0, i3.WeatherCurrentDetailsComponent, [], { maxTemp: [0, "maxTemp"], minTemp: [1, "minTemp"], pressure: [2, "pressure"], humidity: [3, "humidity"], scaleLabel: [4, "scaleLabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentWeather == null) ? null : _co.currentWeather.maxTemp); var currVal_1 = ((_co.currentWeather == null) ? null : _co.currentWeather.minTemp); var currVal_2 = ((_co.currentWeather == null) ? null : _co.currentWeather.pressure); var currVal_3 = ((_co.currentWeather == null) ? null : _co.currentWeather.humidity); var currVal_4 = _co.scaleLabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_WeatherContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "info"]], [[2, "wide", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "current"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "weather-icon", [["class", "big"]], null, null, null, i4.View_WeatherIconComponent_0, i4.RenderType_WeatherIconComponent)), i1.ɵdid(3, 49152, null, 0, i5.WeatherIconComponent, [], { iconClass: [0, "iconClass"], iconImageUrl: [1, "iconImageUrl"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "weather-location", [], null, null, null, i6.View_WeatherLocationComponent_0, i6.RenderType_WeatherLocationComponent)), i1.ɵdid(5, 49152, null, 0, i7.WeatherLocationComponent, [], { place: [0, "place"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "weather-current-temperature", [["class", "big"]], null, null, null, i8.View_WeatherCurrentTempComponent_0, i8.RenderType_WeatherCurrentTempComponent)), i1.ɵdid(7, 49152, null, 0, i9.WeatherCurrentTempComponent, [], { temp: [0, "temp"], scaleLabel: [1, "scaleLabel"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeatherContainerComponent_2)), i1.ɵdid(9, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.currentWeather == null) ? null : _co.currentWeather.iconClass); var currVal_2 = ((_co.currentWeather == null) ? null : _co.currentWeather.iconUrl); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_3 = _co.location; _ck(_v, 5, 0, currVal_3); var currVal_4 = ((_co.currentWeather == null) ? null : _co.currentWeather.temp); var currVal_5 = _co.scaleLabel; _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = _co.settings.showDetails; _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isWideLayout; _ck(_v, 0, 0, currVal_0); }); }
function View_WeatherContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "info empty"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "wi wi-sunrise"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No weather data... "]))], null, null); }
export function View_WeatherContainerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeatherContainerComponent_1)), i1.ɵdid(2, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeatherContainerComponent_3)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentWeather; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.currentWeather; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_WeatherContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "weather-widget", [], null, null, null, View_WeatherContainerComponent_0, RenderType_WeatherContainerComponent)), i1.ɵdid(1, 180224, null, 0, i11.WeatherContainerComponent, [i12.WeatherApiService, i1.ChangeDetectorRef, i1.Renderer2, i1.ElementRef], null, null)], null, null); }
var WeatherContainerComponentNgFactory = i1.ɵccf("weather-widget", i11.WeatherContainerComponent, View_WeatherContainerComponent_Host_0, { forecast: "forecast", currentWeather: "currentWeather", settings: "settings" }, {}, []);
export { WeatherContainerComponentNgFactory as WeatherContainerComponentNgFactory };
