import { Injectable } from '@angular/core';

import { Assert } from '../../../framework/index';
import { User } from '../../../state-model/models/index';
import { SessionService } from '../../../core/services/session/session.service';

@Injectable()
export class UserService {
  private sessionService: SessionService;
  private storedUser: User;

  constructor(sessionService: SessionService) {
    Assert.isNotNull(sessionService, 'sessionService');

    this.storedUser = null;
    this.sessionService = sessionService;
  }

  public isEmployeeLinkedToStoredUser(empId: number): boolean {
    if (this.storedUser) {
      return this.storedUser.linkedEmpId === empId;
    }
    return false;
  }

  public isEmployeeLinkedToCurrentUser(empId: number): boolean {
    let user: User = this.getUser();
    if (user) {
      return user.linkedEmpId === empId;
    }
    return false;
  }
  
  public saveUser(): void {
    this.storedUser = this.getUser();
  }

  public getLastUser(): User {
    return this.sessionService.lastUser;
  }

  public getUser(): User {
    let isSessionExpired: boolean = this.sessionService.isExpired();
    if (isSessionExpired) {
      return undefined;
    }
    return this.sessionService.getUser();
  }
}
