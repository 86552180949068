/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./favorite-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./favorite-item.component";
import * as i4 from "../../../../organization/services/navigation/navigation-menu-redirect.service";
var styles_FavoriteItemComponent = [i0.styles];
var RenderType_FavoriteItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FavoriteItemComponent, data: {} });
export { RenderType_FavoriteItemComponent as RenderType_FavoriteItemComponent };
export function View_FavoriteItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "app collapsed is-desktop"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "payroll": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["class", "app__logo fav-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "app__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["class", "app__url"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "is-mobile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "icon fa"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "app collapsed is-desktop"; var currVal_1 = _ck(_v, 3, 0, (_co.menuItem.id === 177)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "fa"; var currVal_3 = _co.icon; _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = "icon fa"; var currVal_6 = _co.icon; _ck(_v, 14, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.menuItem.displayName; _ck(_v, 10, 0, currVal_4); var currVal_7 = _co.menuItem.displayName; _ck(_v, 16, 0, currVal_7); }); }
export function View_FavoriteItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-favorite-item", [], null, null, null, View_FavoriteItemComponent_0, RenderType_FavoriteItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.FavoriteItemComponent, [i4.NavigationMenuRedirectService], null, null)], null, null); }
var FavoriteItemComponentNgFactory = i1.ɵccf("slx-favorite-item", i3.FavoriteItemComponent, View_FavoriteItemComponent_Host_0, { menuItem: "menuItem" }, {}, []);
export { FavoriteItemComponentNgFactory as FavoriteItemComponentNgFactory };
