<ul class="tiers-tabs">
  <li class="tiers-tabs__item"
    *ngFor="let tier of tiers"
    [title]="tier.name"
    [class.active]="isActiveMenu(tier)"
    (click)="onClickTier(tier)"
  >
  <span>{{ tier.shortTitle }}</span>
  <span *ngIf="tier.stateIsAdded || tier.stateIsUpdated" class="tiers-tabs__item-icon" title="Changes have been made to the benefit tier. Changes will be saved when Save button is clicked">
    <i aria-hidden="true" class="fas fa-exclamation-triangle"></i>
  </span>
</li>
</ul>
<div class="tiers-btn">
  <button type="button"
    class="slx-button slx-blue slx-with-icon slx-margin-r slx-mobile-adapted"
    [disabled]="!canAdd"
    (click)="onAddNew()"
  >
    <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
    <span class="slx-button__text">Add New</span>
  </button>
  <button type="button"
    class="slx-button slx-red slx-with-icon slx-margin-r slx-mobile-adapted"
    [disabled]="!canDelete"
    (click)="onDelete()"
  >
    <i aria-hidden="true" class="fas fa-trash-alt slx-button__icon"></i>
    <span class="slx-button__text">Delete</span>
  </button>
</div>
