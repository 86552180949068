import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import {
  Component,
  Input,
  Output,
  EventEmitter, OnInit
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { OpenShiftSummary, IOpenShiftMessageCountChangedEventArgs } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { StateManagementService } from '../../../../common';
import { IControlState, StateResetTypes } from '../../../../core/models';
@Component({
  moduleId: module.id,
  selector: 'slx-open-shift-management-daily-details',
  templateUrl: 'open-shift-management-daily-details.component.html',
  styleUrls: ['open-shift-management-daily-details.component.scss'],
  providers: [ StateManagementService ]
})
export class OpenShiftManagementDailyDetailsComponent implements OnInit {
  @Input()
  public details: OpenShiftSummary;
  public appConfig: IApplicationConfig;

  private readonly stateKey: string = 'OpenShiftManagementDailyDetailsState';

  constructor(public managementService: OpenShiftManagementManagementService,
              private stateManagement: StateManagementService) {
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.restoreStates();
  }

  public onShowFilledShifts() {
    this.saveStates();
  }

  private saveStates(): void {
    let state: IControlState = {
      value: { showFilledShifts: this.managementService.showFilledShifts }
    };
    this.stateManagement.setControlState(this.stateKey, state, StateResetTypes.None);
  }

  private restoreStates(): void {
    let state: IControlState = this.stateManagement.getControlState(this.stateKey);
    if (state && state.value) {
      if (state.value.showFilledShifts) {
        this.managementService.showFilledShifts = state.value.showFilledShifts;
      }
    }
  }
}
