import { LookupMapService } from './../../../organization/services/lookup/lookup-map.service';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import { AttendancePointsDefinition, IAttendancePointsDefinition } from '../../../organization/models/lookup/index';
import { AttendancePointsConfigCategory } from '../../models/attendance-points-config/attendance-points-config-category.model';

@Injectable()
export class AttendancePointsConfigMapService {
  constructor(private lookupMap: LookupMapService) {

  }
  public mapAttendancePoints(dto: IAttendancePointsDefinition): AttendancePointsDefinition {
    Assert.isNotNull(dto, 'IAttendancePointsDefinition');
    return this.lookupMap.mapAttendancePointsDefinition(dto);
  }

  public mapToAttendancePointsDto(data: AttendancePointsDefinition): IAttendancePointsDefinition {
    Assert.isNotNull(data, 'AttendancePointsDefinition');
    return this.lookupMap.mapAttendancePointsDefinitionDto(data);
  }

  public mapAttendancePointsCategories(definitions: AttendancePointsDefinition[]): AttendancePointsConfigCategory[] {
    Assert.isNotNull(definitions, 'IAttendancePointsDefinition');

    const categoriesNames: string[] = _.map(definitions, (def) => def.category.name);
    const uniqueCategoriesNames: string[] = Array.from(new Set(categoriesNames));
    const categoriesList: AttendancePointsConfigCategory[] = _.map(uniqueCategoriesNames, (catName) => {
      const category = new AttendancePointsConfigCategory();
      category.id = catName;
      category.name = catName;

      return category;
    });

    return categoriesList;
  }
}

