
<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <slx-leave-request-toolbar class="slx-content-toolbar-indents"
      [startDate]="startDate"
      [endDate]="endDate"
      (onDatesChanged)="onDatesChanged($event)"
      (onFiltersChanged)="onFiltersChanged($event)"
    ></slx-leave-request-toolbar>

    <slx-leave-request-grid class="slx-high-box__body slx-main-content-indents"
      [orgLevel]="currentOrgLevel"
      [filters]="filters"
      [actions]="actions"
      [leaveRequests]="leaveRequests"
      [absencesCodes]="absencesCodes"
      (onLoading)="onUpdateLoading($event)"
    ></slx-leave-request-grid>
  </div>
</slx-spinner>
