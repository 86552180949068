import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { orderBy, State, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { GridComponent, PageChangeEvent, } from '@progress/kendo-angular-grid';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import { IFilteredItems, ServerCompositeFilterDescriptor, PagingData } from '../../../../../core/models/index';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { A4 } from '../../../../../common/models/media/paper-sizes';
import { GridExportType } from '../../../models/pbj-export/grid-export-type';
import { PBJExportLogHeader } from '../../../models/index';
import { pbjConfig } from '../../../pbj.config';
var PbjDetailsBasicGridComponent = /** @class */ (function () {
    function PbjDetailsBasicGridComponent(apiService, serverFilterService, changeDetector, stateManagement) {
        var _this = this;
        this.apiService = apiService;
        this.serverFilterService = serverFilterService;
        this.changeDetector = changeDetector;
        this.stateManagement = stateManagement;
        this.pageSize = 50;
        this.m_pdfScale = 0;
        this.loadStatus = new EventEmitter();
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
        this.pagingData = { take: 50, skip: 0 };
        this.xlsExportType = GridExportType.XLS;
        this.filterChangeSubscription = this.serverFilterService.changes$.subscribe(function (filter) {
            _this.refreshData();
        });
    }
    Object.defineProperty(PbjDetailsBasicGridComponent.prototype, "pbjIdConfigEnabled", {
        get: function () {
            return this.m_pbjIdConfigEnabled;
        },
        set: function (value) {
            this.m_pbjIdConfigEnabled = value;
            this.updateColumnsConfig();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjDetailsBasicGridComponent.prototype, "pdfScale", {
        get: function () {
            return this.m_pdfScale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjDetailsBasicGridComponent.prototype, "exportGridChild", {
        set: function (el) {
            this.exportGrid = el;
            if (this.exportGrid && this.isExportInProgress) {
                if (this.currentExportType === GridExportType.PDF) {
                    this.finishPdfExport();
                }
                else if (this.currentExportType === GridExportType.XLS) {
                    this.finishXlsExport();
                }
                else {
                    this.cleanUpExportData();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    PbjDetailsBasicGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.pbjConfig = pbjConfig;
        this.stateManagement.init(this.controlKey, true);
        this.applyDefaultSort();
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid(_this.records, _this.gridState);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.stateManagement.loadedData({});
    };
    PbjDetailsBasicGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PbjDetailsBasicGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes['exportId']) {
            if (this.exportId) {
                this.refreshData();
            }
        }
    };
    PbjDetailsBasicGridComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.pagingData = { take: event.take, skip: event.skip };
        this.refreshData();
    };
    PbjDetailsBasicGridComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.refreshData();
    };
    PbjDetailsBasicGridComponent.prototype.filterChange = function (filter) {
        this.gridState.state.filter = filter;
        this.refreshGrid(this.records, this.gridState);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    PbjDetailsBasicGridComponent.prototype.exportToPdf = function () {
        this.currentExportType = GridExportType.PDF;
        this.loadAndExport(this.currentExportType);
    };
    PbjDetailsBasicGridComponent.prototype.exportToXls = function () {
        this.currentExportType = GridExportType.XLS;
        this.loadAndExport(this.currentExportType);
    };
    PbjDetailsBasicGridComponent.prototype.processExportData = function (type, val) {
        this.exportRecords = val;
        switch (type) {
            case GridExportType.PDF:
                this.startPdfExport();
                break;
            case GridExportType.XLS:
                this.startXlsExport();
                break;
        }
    };
    PbjDetailsBasicGridComponent.prototype.startXlsExport = function () {
        this.exportGridState = new KendoGridStateHelper();
        this.exportGridState.state.sort = this.gridState.state.sort;
        this.exportGridState.state.group = this.gridState.state.group;
        this.exportGridState.state.filter = this.gridState.state.filter;
        this.refreshGrid(this.exportRecords, this.exportGridState);
        this.isExportInProgress = true;
        // this.changeDetector.markForCheck();
        // this.changeDetector.detectChanges();
        this.finishXlsExport();
    };
    PbjDetailsBasicGridComponent.prototype.startPdfExport = function () {
        this.exportGridState = new KendoGridStateHelper();
        this.exportGridState.state.sort = this.gridState.state.sort;
        this.exportGridState.state.group = this.gridState.state.group;
        this.exportGridState.state.filter = this.gridState.state.filter;
        if (this.exportRecords.items.length > 2000) {
            this.exportRecords.items = _.take(this.exportRecords.items, 2000);
        }
        this.refreshGrid(this.exportRecords, this.exportGridState);
        this.isExportInProgress = true;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    PbjDetailsBasicGridComponent.prototype.finishPdfExport = function () {
        this.setupPdfTemplate();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.exportGrid.saveAsPDF();
        this.cleanUpExportData();
    };
    PbjDetailsBasicGridComponent.prototype.finishXlsExport = function () {
        var _this = this;
        var headerInfo = this.addHeaderInformation();
        var rows = [];
        _.each(this.exportGridState.view.data, function (item) {
            var cells = [];
            _.each(_this.xlsExportColumns, function (column) {
                var cell = {
                    value: _.get(item, column.field)
                };
                cells.push(cell);
            });
            rows.push({ cells: cells });
        });
        var headers = [];
        _.each(this.xlsExportColumns, function (column) {
            var cell = {
                value: column.displayTitle,
                fontSize: _this.pbjConfig.settings.export.xlsx.headerCell.fontSize,
                color: _this.pbjConfig.settings.export.xlsx.headerCell.color,
                background: _this.pbjConfig.settings.export.xlsx.headerCell.background,
                width: column.width
            };
            headers.push(cell);
        });
        rows.unshift({ cells: headers });
        var additionalDataRow = {
            cells: [
                {
                    value: headerInfo, colSpan: this.xlsExportColumns.length,
                    fontSize: this.pbjConfig.settings.export.xlsx.titleCell.fontSize,
                    borderBottom: this.pbjConfig.settings.export.xlsx.titleCell.borderBottom
                }
            ]
        };
        rows.unshift(additionalDataRow);
        var workbook = new Workbook({
            sheets: [
                {
                    name: this.pbjHeader.organization.name.slice(0, 31),
                    columns: this.xlsExportColumns,
                    rows: rows
                }
            ]
        });
        workbook.toDataURL().then(function (dataUrl) {
            saveAs(dataUrl, _this.exportFilename + '.xlsx');
        });
        this.cleanUpExportData();
    };
    PbjDetailsBasicGridComponent.prototype.addHeaderInformation = function () {
        var organization = this.pbjHeader.organization.name;
        var start = moment(this.pbjHeader.startDate).format(appConfig.dateFormat);
        var end = moment(this.pbjHeader.endDate).format(appConfig.dateFormat);
        var exported = moment(this.pbjHeader.exportDate).format(appConfig.dateTimeFormatUS);
        var sheetName = organization + " (" + start + "-" + end + ") Exported on " + exported;
        return sheetName;
    };
    PbjDetailsBasicGridComponent.prototype.cleanUpExportData = function () {
        // clean up
        this.exportRecords = null;
        this.exportGridState = null;
        this.isExportInProgress = false;
    };
    PbjDetailsBasicGridComponent.prototype.setupPdfTemplate = function () {
        var pdfMargins = this.pbjConfig.settings.export.pdf.margins;
        var unscaledGridColumnWidthMM = this.pbjConfig.settings.export.pdf.unscaledGridColumnWidthMM;
        var fieldCount = this.exportGrid.columnList.toArray().length;
        var scale = (A4.heightMM - pdfMargins.left - pdfMargins.right) / (unscaledGridColumnWidthMM * fieldCount);
        this.m_pdfScale = scale;
    };
    PbjDetailsBasicGridComponent.prototype.onLoadStatus = function (isLoading) {
        var _this = this;
        setTimeout(function () { return _this.loadStatus.next(isLoading); }, 1);
    };
    PbjDetailsBasicGridComponent.prototype.filterbyExportId = function (exportId) {
        this.serverFilterService.removeFilter('exportId');
        this.serverFilterService.composeFilter({ field: 'exportId', operator: 'eq', value: exportId });
        this.serverFilterService.applyFilter();
    };
    PbjDetailsBasicGridComponent.prototype.showChanges = function () {
        this.refreshGrid(this.records, this.gridState);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    PbjDetailsBasicGridComponent.prototype.refreshGrid = function (records, gridState) {
        if (!records) {
            gridState.view = null;
            return;
        }
        gridState.view = { data: null, total: null };
        var filtered = filterBy(records.items, gridState.state.filter);
        gridState.view = { data: orderBy(filtered, gridState.state.sort), total: records.count ? records.count : 0 };
    };
    PbjDetailsBasicGridComponent.prototype.updateColumnsConfig = function () {
        if (this.m_pbjIdConfigEnabled) {
            this.xlsExportColumns = [
                { field: 'pbjId', displayTitle: 'PBJ ID', index: 0, width: 150 }
            ];
        }
        else {
            this.xlsExportColumns = [
                { field: 'employeeMasterId', displayTitle: 'Emp Master ID', index: 0, width: 150 }
            ];
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjDetailsBasicGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjDetailsBasicGridComponent.prototype, "filterChangeSubscription", void 0);
    return PbjDetailsBasicGridComponent;
}());
export { PbjDetailsBasicGridComponent };
