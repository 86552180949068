import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { PunchLogRequest } from '../../../models/index';
import { screenUtils, IScreenUtils } from '../../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-punch-logs-dialog',
  templateUrl: 'punch-logs-dialog.component.html',
  styleUrls: ['punch-logs-dialog.component.scss']
})
export class PunchLogsDialogComponent implements IDialog {


  public options: DialogOptions;
  public dialogResult: boolean;
  public request: PunchLogRequest;

  private modalService: ModalService;

  public static openDialog(header: string, request: PunchLogRequest, modalService: ModalService, callback: (result: boolean, cmd: any) => void): PunchLogsDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 650;
    dialogOptions.width = 1140;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: PunchLogRequest,
        useValue: request
      }
    ];

    let dialog: PunchLogsDialogComponent = modalService.globalAnchor
      .openDialog(PunchLogsDialogComponent, header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.request);
      });
    return dialog;
  }


  constructor(options: DialogOptions,
    modalService: ModalService,
    request: PunchLogRequest
  ) {
    this.modalService = modalService;
    this.options = options;
    this.request = request;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
