import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { appConfig, IApplicationConfig } from '../../../app.config';
import * as moment from 'moment';

export class ScheduleEntryNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public NavigateToScheduleEntry(employeeId: number, dateOn: Date, orgLevelId: number = 0,ignoreSupportedOrgTypes:boolean =false): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false
    };
    this.router.navigateByUrl(this.getUrlTree(employeeId, dateOn, orgLevelId, ignoreSupportedOrgTypes), extras);
  }

  private getUrlTree(employeeId: number, dateOn: Date, orgLevelId: number = 0, ignoreSupportedOrgTypes:boolean=false): UrlTree {
    let qp: any = {
      hideLeftSideBar: true,
    };
    if (orgLevelId > 0) {
      qp.orgLevelId = orgLevelId;
    }
    qp.supportedOrgTypes=ignoreSupportedOrgTypes;
    return this.router.createUrlTree(['scheduler', 'master_schedule', 'employee', employeeId, 'shifts',
      moment(dateOn).format(appConfig.linkDateFormat)], { relativeTo: this.route.pathFromRoot[1], queryParams: qp });
  }
}
