import {  IColumnSettings } from '../../../core/models/index';
import { AggregateDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import * as moment from 'moment';


export enum MonthsList {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12
}

export class WCComparisonsState {
  public columns: IColumnSettings[] = [];
  public columnsMap: StringMap<IColumnSettings>;
  public dynamicColumnCount: any = [moment().year()];
  public monthlyColumns: IColumnSettings[] = [];

  public createColumns(): void {
    this.columns = [
      {
        name: 'organization',
        description: 'Facility',
        displayed: true,
      },
      {
        name: 'total',
        description: 'Total',
        displayed: true,
      }
    ];

    this.columns = this.addDynamicColumns(this.columns);
    this.mapColumns();
  }

  public addDynamicColumns(dynamicColumns) : Array<IColumnSettings> {
    let defaultColumns = this.dynamicColumn();
    dynamicColumns.splice.apply(dynamicColumns, [1, 0].concat(defaultColumns));
    return dynamicColumns;
  }

  public dynamicMonthCount (yearArray){
      if(yearArray != undefined){
        this.dynamicColumnCount = yearArray;
      }
  }

  public mapColumns(): void {
    this.columnsMap = {};
    this.columns.forEach((col: IColumnSettings) => {
      this.columnsMap[col.name] = col;
    });
  }

  public dynamicColumn(): any {
    let monthsList = [];
    let years = this.dynamicColumnCount;

    for (let year in this.dynamicColumnCount.sort()) {
      if(years[year] === moment().year()){
        this.monthLoops(moment().month(), moment().format('YY'), monthsList);
      } else {
        this.monthLoops(11, `${years[year] % 100}`, monthsList);
      }
    }
    this.monthlyColumns = monthsList;
    return this.monthlyColumns;
  }

  monthLoops(monthCount: number, yearVal: string, monthsList: any[]) {
    for(let i=0; i<= monthCount; i++) {
      monthsList.push({name: MonthsList[i+1] +''+ yearVal, description: `${MonthsList[i+1]} '${yearVal}`, displayed: true});
    }
  }

  public makeAggregates(): AggregateDescriptor[] {
    const aggregates: AggregateDescriptor[] = [];
    _.forEach(this.columns, (column: IColumnSettings) => {
      const field: string = column.name;
      if (field !== 'organization') {
        aggregates.push({ field: column.name, aggregate: 'sum' as any });
      }
    });
    return aggregates;
  }
}

