import * as _ from 'lodash';
import { AggregateDescriptor } from '@progress/kendo-data-query';
import { DateRange } from '../../../../core/models/calendar-data/date-range';

export abstract class PBJRecBase {
  public abstract reconciliation: PBJRecEntry;

  public get timecardHours(): number {
    return this.reconciliation.timecardHours;
  }
  public get startOfPeriod(): number {
    return this.reconciliation.startOfPeriod;
  }
  public get endOfPeriod(): number {
    return this.reconciliation.endOfPeriod;
  }
  public get excludedPayCodes(): number {
    return this.reconciliation.excludedPayCodes;
  }
  public get unmappedPositions(): number {
    return this.reconciliation.unmappedPositions;
  }
  public get manualPbjEntries(): number {
    return this.reconciliation.manualPbjEntries;
  }
  public get unapprovedHours(): number {
    return this.reconciliation.unapprovedHours;
  }
  public get expectedPbjHours(): number {
    return this.reconciliation.expectedPbjHours;
  }
  public get totalPbjHours(): number {
    return this.reconciliation.totalPbjHours;
  }
  public get variance(): number {
    return this.reconciliation.variance;
  }
}

export interface IPBJRecEntry {
  timecardHours: number;
  startOfPeriodAdjustments: number;
  endOfPeriodAdjustments: number;
  excludedPayCodes: number;
  unmappedPositions: number;
  manualPbjEntries: number;
  unapprovedHours: number;
  expectedPbjHours: number;
  totalPbjHours: number;
  variance: number;
}

export class PBJRecEntry {
  public timecardHours: number;
  public startOfPeriod: number;
  public endOfPeriod: number;
  public excludedPayCodes: number;
  public unmappedPositions: number;
  public manualPbjEntries: number;
  public unapprovedHours: number;
  public expectedPbjHours: number;
  public totalPbjHours: number;
  public variance: number;
}


export interface IPBJRecOrgEntry {
  organizationId: number;
  organizationName: string;
  reconciliationData: IPBJRecEntry;
}

export class PBJRecOrgEntry extends PBJRecBase {
  public orgId: number;
  public orgName: string;
  public reconciliation: PBJRecEntry;
}


export interface IPBJRecDepEntry {
  departmentId: number;
  departmentName: string;
  reconciliationData: IPBJRecEntry;
}

export class PBJRecDepEntry extends PBJRecBase {
  public depId: number;
  public depName: string;
  public reconciliation: PBJRecEntry;
}


export interface IPBJRecEmpEntry {
  employeeId: number;
  employeeName: string;
  reconciliationData: IPBJRecEntry;
}

export class PBJRecEmpEntry extends PBJRecBase {
  public empId: number;
  public empName: string;
  public reconciliation: PBJRecEntry;
}


export interface IPBJRecDailyEntry {
  dateOn: string;
  reconciliationData: IPBJRecEntry;
}

export class PBJRecDailyEntry extends PBJRecBase {
  public dateOn: Date;
  public reconciliation: PBJRecEntry;
}


export class PBJRecColumn {
  public title: string;
  public field: string;
  public width: number;
  public mobileWidth?: number;

  constructor(title: string, field: string, width: number, mobileWidth?: number) {
    this.title = title;
    this.field = field;
    this.width = width;
    if (_.isNumber(mobileWidth)) {
      this.mobileWidth = mobileWidth;
    }
  }
}

interface IPBJRecColumnDef {
  field: string;
  title: string;
  width: number;
  mobileWidth?: number;
}

export class PBJRecColumns {
  public columns: StringMap<PBJRecColumn> = {};
  private columnsDef: Array<IPBJRecColumnDef> = [
    { field: 'orgName', title: 'Facility', width: 240, mobileWidth: 100 },
    { field: 'depName', title: 'Facility', width: 240, mobileWidth: 100 },
    { field: 'empName', title: 'Employee Name', width: 240, mobileWidth: 100 },
    { field: 'dateOn', title: 'Employee Timecard Date', width: 240, mobileWidth: 100 },
    { field: 'timecardHours', title: 'Time Card Hours', width: 150, mobileWidth: 70 },
    { field: 'startOfPeriod', title: 'Start of Period Adjustments', width: 170 },
    { field: 'endOfPeriod', title: 'End of Period Adjustments', width: 170 },
    { field: 'excludedPayCodes', title: 'Excluded Pay Codes', width: 150 },
    { field: 'unmappedPositions', title: 'Unmapped Positions', width: 150 },
    { field: 'manualPbjEntries', title: 'Manual PBJ Entries', width: 150 },
    { field: 'unapprovedHours', title: 'Unapproved Hours', width: 150 },
    { field: 'expectedPbjHours', title: 'Expected PBJ Hours', width: 150, mobileWidth: 70 },
    { field: 'totalPbjHours', title: 'Actual PBJ Hours', width: 150 },
    { field: 'variance', title: 'Variance', width: 150, mobileWidth: 70 },
  ];

  public makeColumns(): StringMap<PBJRecColumn> {
    const columns: StringMap<PBJRecColumn> = {};
    _.forEach(this.columnsDef, (column: IPBJRecColumnDef) => {
      columns[column.field] = new PBJRecColumn(column.title, column.field, column.width);
    });

    return columns;
  }

  public makeAggregates(): AggregateDescriptor[] {
    const aggregates: AggregateDescriptor[] = [];
    _.forEach(this.columnsDef, (column: IPBJRecColumnDef) => {
      const field: string = column.field;
      if (field !== 'orgName' && field !== 'depName' && field !== 'empName' && field !== 'dateOn') {
        aggregates.push({ field: column.field, aggregate: 'sum' as any });
      }
    });

    return aggregates;
  }
}

export class PbjReconciliationOrglevel {
  public maxDaysRange: number;
  public startDate: Date;
  public endDate: Date;

  constructor(sDate: Date, eDate: Date, maxDaysRange: number) {
    this.startDate = sDate;
    this.endDate = eDate;
    this.maxDaysRange = maxDaysRange;
  }
}

export class PbjReconciliationEmployee extends PbjReconciliationOrglevel {
  public employeeId: number;
  public employeeName: string;

  constructor(empId: number, empName: string, sDate: Date, eDate: Date, maxDaysRange: number) {
    super(sDate, eDate, maxDaysRange);
    this.employeeId = empId;
    this.employeeName = empName;
  }
}
