/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./operational-console.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "./operation-console-header/operational-console-header.component.ngfactory";
import * as i5 from "./operation-console-header/operational-console-header.component";
import * as i6 from "../../../services/operational-console/operational-console-state.service";
import * as i7 from "./operation-console-grid/operational-console-grid.component.ngfactory";
import * as i8 from "./operation-console-grid/operational-console-grid.component";
import * as i9 from "../../../services/operational-console/operational-console-api.service";
import * as i10 from "../../../../common/services/modal/modal.service";
import * as i11 from "../../../../organization/services/org-level/org-level-watch.service";
import * as i12 from "../../../../organization/actions/org-level.actions";
import * as i13 from "@angular/router";
import * as i14 from "./operational-console.component";
var styles_OperationalConsoleComponent = [i0.styles];
var RenderType_OperationalConsoleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OperationalConsoleComponent, data: {} });
export { RenderType_OperationalConsoleComponent as RenderType_OperationalConsoleComponent };
export function View_OperationalConsoleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { grid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.SpinnerComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-operational-console-header", [["class", "slx-content-toolbar-indents"]], null, null, null, i4.View_OperationalConsoleHeaderComponent_0, i4.RenderType_OperationalConsoleHeaderComponent)), i1.ɵdid(5, 245760, null, 0, i5.OperationalConsoleHeaderComponent, [i6.OperationalConsoleStateService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "slx-operational-console-grid", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, i7.View_OperationalConsoleGridComponent_0, i7.RenderType_OperationalConsoleGridComponent)), i1.ɵdid(7, 245760, null, 0, i8.OperationalConsoleGridComponent, [i6.OperationalConsoleStateService, i9.OperationalConsoleApiService, i10.ModalService, i11.OrgLevelWatchService, i12.OrgLevelActions, i13.Router, i13.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_OperationalConsoleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-operational-console", [], null, null, null, View_OperationalConsoleComponent_0, RenderType_OperationalConsoleComponent)), i1.ɵdid(1, 180224, null, 0, i14.OperationalConsoleComponent, [i6.OperationalConsoleStateService], null, null)], null, null); }
var OperationalConsoleComponentNgFactory = i1.ɵccf("slx-operational-console", i14.OperationalConsoleComponent, View_OperationalConsoleComponent_Host_0, {}, {}, []);
export { OperationalConsoleComponentNgFactory as OperationalConsoleComponentNgFactory };
