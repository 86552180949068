import * as _ from 'lodash';
import * as moment from 'moment';

import { Router, ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { ColumnSettingsDefinitions } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ColumnSettingsStorageService, ExceptionConsoleNavigationService } from '../../../common/services/index';
import { ExceptionConsoleRollupRecord, ExceptionConsoleRollupState } from '../../models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { ExceptionConsoleApiService } from '../index';
import { IDestroyService } from '../../../core/models/index';
import { ExceptionConsoleRollupContainer } from '../../models/exception-console/exceptions-console-rollup-container';
import { RangeDates } from '../../../common/models/range-dates';

@Injectable()
export class ExceptionConsoleRollupManagementService implements IDestroyService {
  public onStateChanged$: ReplaySubject<ExceptionConsoleRollupState>;
  public onLoadStatus$: ReplaySubject<boolean>;
  public onLoaded$: ReplaySubject<ExceptionConsoleRollupContainer>;
  public currentOrgLevel: OrgLevel;
  public records: ExceptionConsoleRollupRecord[];
  public state: ExceptionConsoleRollupState;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  @unsubscribeInService()
  private routeSubscripion: Subscription;

  private currentRange: RangeDates;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ExceptionConsoleApiService,
    private columnSettingsStorageService: ColumnSettingsStorageService
  ) {

    this.onLoadStatus$ = new ReplaySubject(1);
    this.onStateChanged$ = new ReplaySubject(1);
    this.onLoaded$ = new ReplaySubject(1);

    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) => this.validateOrgLevel(o))
      .subscribe((o: OrgLevel) => {
        this.onOrgLevelChanged(o);
      });
    this.state = new ExceptionConsoleRollupState();
    this.state.createColumns();
    this.state.mapColumns();
    this.state.columns = this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE_ROLLUP.toString(), undefined, this.state.columns);
    this.onStateChanged(this.state);
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public onStateChanged(state: ExceptionConsoleRollupState): void {
    this.columnSettingsStorageService.setColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE_ROLLUP.toString(), undefined, this.state.columns);
    this.onStateChanged$.next(state);
  }

  public onLoadStatusChanged(isLoading: boolean): void {
    this.onLoadStatus$.next(isLoading);
  }

  public loadExceptionConsoleRecords(orgLevelId: number, startDate: Date, endDate: Date): void {
    this.onLoadStatusChanged(true);
    this.apiService.getExceptionConsoleRollupRecords(orgLevelId, startDate, endDate)
      .then((container: ExceptionConsoleRollupContainer) => {
        this.state.setDynamicColumns(container.exceptionColumns);
        this.state.columns = this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE.toString(), undefined, this.state.columns);
        this.onLoaded(container);
        this.onStateChanged(this.state);
        this.onLoadStatusChanged(false);
      })
      .catch((reason: any) => {
        this.onLoaded(null);
        this.onLoadStatusChanged(false);
      });
  }

  public onLoaded(container: ExceptionConsoleRollupContainer): void {
    this.onLoaded$.next(container);
  }

  public navigateToDateRange(range: RangeDates, usePayCycle: boolean): void {
    this.currentRange = range;
    if (this.currentOrgLevel) {
      let nav: ExceptionConsoleNavigationService = new ExceptionConsoleNavigationService(this.router, this.route);
      nav.navigateToExceptionConsoleDates (this.currentOrgLevel.id, range.startDate, range.endDate, usePayCycle);
    }
  }

  public setDateRange(range: RangeDates): void {
    this.currentRange = range;
    if (this.currentOrgLevel) {
      this.loadRecords();
    }
  }

  public getOrgLevelTitle(): string {
    if (this.needShowDepartments) {
      return 'Department';
    } else {
      return 'Organization';
    }
  }

  public get needShowDepartments(): boolean {
    return this.currentOrgLevel.type === OrgLevelType.organization;
  }

  private validateOrgLevel(o: OrgLevel): boolean {
    const orgLevelType: string = _.get(o, 'type');
    const isSameOrgLevel: boolean = _.get(this.currentOrgLevel, 'id') === _.get(o, 'id');

    return !isSameOrgLevel && orgLevelType !== OrgLevelType.department;
  }

  private onOrgLevelChanged(o: OrgLevel): void {
    this.currentOrgLevel = o;
    this.loadRecords();
  }

  private loadRecords(): void {
    if (this.currentOrgLevel && this.currentRange) {
      this.loadExceptionConsoleRecords(this.currentOrgLevel.id, this.currentRange.startDate, this.currentRange.endDate);
    }
  }
}
