export * from './employee-transfer/employee-transfer.component';
export * from './employee-transfer-dialog/employee-transfer-dialog.component';

import { EmployeeTransferComponent } from './employee-transfer/employee-transfer.component';
import { EmployeeTransferDialogComponent } from './employee-transfer-dialog/employee-transfer-dialog.component';

export const employeeTransferComponents: any[] = [
  EmployeeTransferComponent,
  EmployeeTransferDialogComponent
];
