import * as moment from 'moment';
import { appConfig } from '../../../app.config';
var IdealSchedulePositionPeriod = /** @class */ (function () {
    function IdealSchedulePositionPeriod() {
    }
    Object.defineProperty(IdealSchedulePositionPeriod.prototype, "name", {
        get: function () {
            var start = moment(this.startDate).format(appConfig.dateFormat);
            var end = moment(this.endDate).format(appConfig.dateFormat);
            return start + " - " + end;
        },
        enumerable: true,
        configurable: true
    });
    return IdealSchedulePositionPeriod;
}());
export { IdealSchedulePositionPeriod };
