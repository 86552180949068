import * as _ from 'lodash';
import { IPayloadAction } from '../../../state-model/models/index';
import { EmployeesStateSelector } from '../selectors/employees-state.selector';
import { MasterScheduleActions } from './master-schedule.actions';
import { MasterScheduleFilterActions } from './master-schedule-filter.actions';
import { OrgLevelActions } from '../../../organization/actions/org-level.actions';
import { EmployeeScheduleDefinition } from '../../../organization/models/index';

import {
  INITIAL_MASTER_SCHEDULE_STATE,
  INITIAL_MASTER_SCHEDULE_FILTERS_STATE,
  INITIAL_EMPLOYEE_STATE
} from './master-schedule.initial-state';

import { Map } from 'immutable';


function masterScheduleFiltersReducer(state: Map<string, any> = INITIAL_MASTER_SCHEDULE_FILTERS_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case MasterScheduleFilterActions.SET_FILTERS:
      return state.merge(action.payload);
    case MasterScheduleActions.MASTER_SCHEDULE_CLEAR_PAYCYCLE:
      return state.setIn(['dateFrom'], null);
    default:
      return state;
  }
}

export function masterScheduleReducer(state: Map<string, any> = INITIAL_MASTER_SCHEDULE_STATE, action: IPayloadAction): Map<string, any> {
  //let dateFrom: Date;

  switch (action.type) {
    case MasterScheduleFilterActions.SET_FILTERS:
      return state.merge({
        filters: masterScheduleFiltersReducer(state.get('filters'), action),
        selectedRow: null,
        isLoading: true,
        isLoadingTotals: true
      });
    case MasterScheduleActions.FETCH_TOTALS_SUCCESS:
      return state.merge({
        isLoading: false,
        isLoadingTotals: false,
        totals: EmployeesStateSelector.getTotals(action.payload),
        subtotals: EmployeesStateSelector.getSubtotals(action.payload),
      });
    case MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE:
    case MasterScheduleActions.FETCH_TOTALS:
    case MasterScheduleActions.GENERATE_SCHEDULE:
    case MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA:
    case MasterScheduleActions.DELETE_EMP_SCHEDULE:
    case MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE:
      return state.merge({
        isLoading: true,
        isLoadingData: true,
        hasError: false,
        errorMessage: null
      });
    case MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS:
      //dateFrom = state.getIn(['filters', 'dateFrom']);
      return state.merge({
        isLoading: false,
        isLoadingData: false,
        actions: action.payload.actions,
        employees: EmployeesStateSelector.getRows(action.payload.employees),
        hasError: false,
        errorMessage: null
      });
    case MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR:
      return state.merge({
        hasError: action.payload.hasError,
        errorMessage: action.payload.errorMessage
      });
    case MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS:
      return state.merge({
        actions: action.payload.actions,
        employees: EmployeesStateSelector.getRows(action.payload.employees),
        totals: EmployeesStateSelector.getTotals(action.payload.totals),
        subtotals: EmployeesStateSelector.getSubtotals(action.payload.totals),
        hasError: false,
        errorMessage: null
      });
    case MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR:
      return state.merge({
        hasError: action.payload.hasError,
        errorMessage: action.payload.errorMessage
      });
    case MasterScheduleActions.SET_SCROLL_POSITION:
      return state.setIn(['scrollPositions', action.payload.orgLevel.id], { top: action.payload.top, left: action.payload.left });
    case MasterScheduleActions.SET_SELECTED_ROW:
      return state.merge({ selectedRow: action.payload });
    case MasterScheduleActions.COMPLETE_DATA_LOADING:
        return state.merge({
          isLoading: false,
        });
    case MasterScheduleActions.COMPLETE_LOADING:
      return state.merge({
        isLoading: false,
        isLoadingTotals: false
      });
    case MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS:
      return state.setIn(['employees', action.payload.employee.id.toString()], EmployeesStateSelector.mapMasterScheduleEntryRow(action.payload));
    case MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED:
      let lState: Map<string, any> = state;
      _.forEach(action.payload, (entry: EmployeeScheduleDefinition) => {
        lState = lState.setIn(['employees', entry.employee.id.toString()], EmployeesStateSelector.mapMasterScheduleEntryRow(entry));
      });
      return lState;
    case MasterScheduleActions.MASTER_SCHEDULE_CLEAR_PAYCYCLE:
      return state.merge({
        filters: masterScheduleFiltersReducer(state.get('filters'), action),
      });
    default:
      return state;
  }
}
