import { StringUtils } from './string-utils/string-utils';

if (!String.prototype.format) {

    /**
     * Replaces the format item in a specified string with the string representation of a corresponding object in a specified array.
     * @param {array} args - An object array that contains zero or more objects to format.
     * @returns {string} A copy of format in which the format items have been replaced by the string representation of the corresponding objects in args.
     */
    String.prototype.format = function(...args: Object[]): string {
        args = Array.prototype.slice.call(args);

        let params: Object[] = [this];
        params = params.concat(args);

        return StringUtils.format.apply(this, params);
    };
} else {
    console.warn('"String.prototype.format" already defined.');
}

if (!String.prototype.formatWithMap) {

    /**
     * Replaces the format item in a specified string with the string representation of a corresponding object in a specified literal object.
     * @param {object} map - An object that contains zero or more objects to format.
     * @returns {string} A copy of format in which the format items have been replaced by the string representation of the corresponding objects in args.
     */
    String.prototype.formatWithMap = function(map: StringMap<any>): string {
        return StringUtils.formatWithMap(this, map);
    };
} else {
    console.warn('"String.prototype.formatWithMap" already defined.');
}
