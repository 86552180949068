import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../framework/index';

import { mutableSelect } from '../../../core/decorators/index';
import { LookupApiService } from '../../../organization/services/index';
import { State } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { OrgLevelFlat } from '../../../organization/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { GeolocationApiService } from './geolocation-api.service';
import { GeolocationMapService } from './geolocation-map.service';
import { IGeolocationEntityDTO, GeolocationEntity, IGeolocationEvent } from '../../models/index';

@Injectable()
export class GeolocationManagementService {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private subscriptions: StringMap<Subscription> = {};

  private orgLevelChanged$ = new ReplaySubject<OrgLevel>();
  private onEmitEvent$ = new Subject<IGeolocationEvent>();
  private onChangeToolbarMode$ = new Subject<boolean>();
  private onCloseForm$ = new Subject<null>();
  private loading$ = new Subject<boolean>();
  private entitiesLoaded$ = new Subject<GeolocationEntity[]>();
  private statesLoaded$ = new Subject<State[]>();
  private entitySelected$ = new Subject<GeolocationEntity>();
  private orgLevelsList: OrgLevelFlat[];
  private cachedResults = new Map<string, boolean>();

  constructor(
    private apiService: GeolocationApiService,
    private mapService: GeolocationMapService,
    private orgLevelWatchService: OrgLevelWatchService,
    private lookup: LookupApiService
  ) {

  }

  public init(): void {
    this.subscribeToOrgLevelTree();
  }

  public destroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public subscribeToOrgLevelChanged(callback: (e: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelChanged$.subscribe(callback);
  }

  public emitGeoEvent(e: IGeolocationEvent): void {
    this.onEmitEvent$.next(e);
  }

  public subscribeToGeoEvent(callback: (e: IGeolocationEvent) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.onEmitEvent$.subscribe(callback);
  }

  public changeToolbarMode(isActive: boolean): void {
    this.onChangeToolbarMode$.next(isActive);
  }

  public subscribeToChangeToolbarMode(callback: (isActive: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.onChangeToolbarMode$.subscribe(callback);
  }

  public closeForm(): void {
    this.onCloseForm$.next();
  }

  public subscribeToCloseForm(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.onCloseForm$.subscribe(callback);
  }

  public entitySelected(entity: GeolocationEntity): void {
    const dto = this.mapService.mapExistingEntityToDTO(entity);
    const copy = this.mapService.mapEntity(dto);
    copy.orgLevelName = entity.orgLevelName;
    this.entitySelected$.next(copy);
  }

  public subscribeToEntitySelected(callback: (e: GeolocationEntity) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.entitySelected$.subscribe(callback);
  }

  public subscribeToLoading(callback: (isLoading: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToEntitiesLoaded(callback: (r: GeolocationEntity[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.entitiesLoaded$.subscribe(callback);
  }

  public subscribeToStatesLoaded(callback: (s: State[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.statesLoaded$.subscribe(callback);
  }

  public async loadGeolocations(orgLevelId: number, emitLoading: boolean = true): Promise<GeolocationEntity[]> {
    if (emitLoading) {
      this.loading$.next(true);
    }
    return this.apiService.getEntities(orgLevelId)
      .then((entities: GeolocationEntity[]) => {
        this.entitiesLoaded$.next(entities);
        if (emitLoading) {
          this.loading$.next(false);
        }
        return entities;
      })
      .catch((err) => {
        if (emitLoading) {
          this.loading$.next(false);
        }
        console.error(err);

        return [];
      });
  }

  public async saveGeolocation(entity: GeolocationEntity): Promise<any> {
    this.loading$.next(true);
    return this.apiService.saveEntity(entity)
      .then(() => {
        this.loading$.next(false);
      })
      .catch((err) => {
        this.loading$.next(false);
        console.error(err);
      });
  }

  public async updateGeolocation(entity: GeolocationEntity): Promise<any> {
    this.loading$.next(true);
    return this.apiService.udateEntity(entity)
      .then(() => {
        this.loading$.next(false);
      })
      .catch((err) => {
        this.loading$.next(false);
        console.error(err);
      });
  }

  public async deleteGeolocation(entity: GeolocationEntity): Promise<any> {
    this.loading$.next(true);
    return this.apiService.deleteEntity(entity)
      .then(() => {
        this.loading$.next(false);
      })
      .catch((err) => {
        this.loading$.next(false);
        console.error(err);
      });
  }

  public isParentOrgLevel(currentOrgLevelId: number, entityOrgLevelId: number): boolean {
    if (!_.isArray(this.orgLevelsList) || !_.isFinite(currentOrgLevelId) || !_.isFinite(entityOrgLevelId)) return false;

    const key = `${currentOrgLevelId}_${entityOrgLevelId}`;
    if (this.cachedResults.has(key)) {
      return this.cachedResults.get(key);
    }
    let value = this.doesOrgLevelIsParent(currentOrgLevelId, entityOrgLevelId);
    this.cachedResults.set(key, value);

    return value;
  }

  private doesOrgLevelIsParent(currentOrgLevelId: number, entityOrgLevelId: number): boolean {
    const currentOrgLevel = _.find(this.orgLevelsList, (o: OrgLevelFlat) => o.orgLevel.id === currentOrgLevelId);
    const entityOrgLevel = _.find(this.orgLevelsList, (o: OrgLevelFlat) => o.orgLevel.id === entityOrgLevelId);
    let isParentOrgLevel: boolean = false;
    if (_.isObjectLike(currentOrgLevel) && _.isObjectLike(entityOrgLevel)) {
      switch(currentOrgLevel.orgLevel.type) {
        case OrgLevelType.department:
          isParentOrgLevel = entityOrgLevel.orgLevel.type === OrgLevelType.organization
            || entityOrgLevel.orgLevel.type === OrgLevelType.division
            || entityOrgLevel.orgLevel.type === OrgLevelType.region
            || entityOrgLevel.orgLevel.type === OrgLevelType.corporation;
          break;
        case OrgLevelType.organization:
          isParentOrgLevel = entityOrgLevel.orgLevel.type === OrgLevelType.division
            || entityOrgLevel.orgLevel.type === OrgLevelType.region
            || entityOrgLevel.orgLevel.type === OrgLevelType.corporation;
          break;
        case OrgLevelType.division:
          isParentOrgLevel = entityOrgLevel.orgLevel.type === OrgLevelType.region
            || entityOrgLevel.orgLevel.type === OrgLevelType.corporation;
          break;
        case OrgLevelType.region:
          isParentOrgLevel = entityOrgLevel.orgLevel.type === OrgLevelType.corporation;
          break;
        case OrgLevelType.corporation:
          isParentOrgLevel = false;
          break;
        default: isParentOrgLevel = false;
      }
    }
    return isParentOrgLevel;
  }

  private async loadStates(emitLoading: boolean = true): Promise<State[]> {
    if (emitLoading) {
      this.loading$.next(true);
    }
    return this.lookup.getStates()
      .then((states: State[]) => {
        this.statesLoaded$.next(states);
        if (emitLoading) {
          this.loading$.next(false);
        }
        return states;
      });
  }

  private subscribeToOrgLevelTree(): void {
    this.subscriptions.orgLevelTree = this.orgLevelWatchService.orgLevelTreeLoaded$
      .subscribe(() => {
        this.orgLevelsList = this.orgLevelWatchService.getFlatList();
        if (_.isArray(this.orgLevelsList) && _.size(this.orgLevelsList)> 0) {
          const map = _.reduce(this.orgLevelsList, (map: Map<number, string>, o: OrgLevelFlat) => {
            let orgLevelName = o.orgLevel.name;
            if (_.size(o.parentName) > 0) {
              orgLevelName = `${o.parentName} > ${o.orgLevel.name}`;
            }
            map.set(o.orgLevel.id, orgLevelName);

            return map;
          }, new Map<number, string>());
          this.mapService.setOrgLevelsList(map);
        }
        if (!this.subscriptions.orgLevel) {
          this.subscribeToOrgLevel();
        }
      });
  }

  private subscribeToOrgLevel(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe(async (orgLevel: OrgLevel) => {
        this.loading$.next(true);
        await this.loadGeolocations(orgLevel.id, false);
        await this.loadStates(false);
        this.orgLevelChanged$.next(orgLevel);
        this.loading$.next(false);
      });
  }
}
