import { Directive, ElementRef, Renderer, HostListener } from '@angular/core';
import { CustomDomEvents } from '../../models/index';
@Directive({
  selector: 'input,select',
})
export class FocusForwarderDirective {
  private elRef: ElementRef;
  private renderer: Renderer;
  constructor(elRef: ElementRef, renderer: Renderer) {
    this.elRef = elRef;
    this.renderer = renderer;
  }
  @HostListener('focus', ['$event'])
  public onFocus(event: Event): void {
    this.renderer.invokeElementMethod(this.elRef.nativeElement,
      'dispatchEvent',
      [new CustomEvent(CustomDomEvents.focus, { bubbles: true })]);
  }
}
