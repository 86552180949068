<slx-spinner [show]="management.state.isLoading">
    <form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">

        <kendo-grid #kendoGrid class="slx-full-height" [data]="gridState?.view" [filterable]="true"
            [selectable]="{ mode: 'single' }" [filter]="gridState.state.filter" [sort]="gridState.state.sort"
            [sortable]="{ mode: 'multiple' }" [slxKendoGridColumnsGroup]="columnsGroupName" [filtersManagement]="true"
            [slxKendoGridState]="gridState.gridDefinition" (selectionChange)="gridState.selectionChange($event)"
            (dataStateChange)="gridState.dataStateChange($event)" (stateRestored)="gridState.dataStateChange($event)"
            (cancel)="gridState.cancelHandler($event)" (edit)="gridState.editHandler($event)"
            (remove)="gridState.removeHandler($event)" (save)="gridState.saveHandler($event)">
    
            <kendo-grid-command-column [width]="15" *ngIf="!state.bulkEditMode && state.canEdit">
                <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
                    <div class="command-container">
                        <button kendoGridEditCommand type="button" class="slx-button slx-with-icon command-button">
                            <i class="fas fa-pencil-alt slx-button__icon" aria-hidden="true"></i>
                        </button>
                        <button kendoGridSaveCommand type="button" [disabled]="!templateForm.valid"
                            class="slx-button slx-with-icon command-button">
                            <i class="far fa-check-circle fa-2x slx-button__icon" aria-hidden="true"></i>
                        </button>
                        <button kendoGridCancelCommand type="button" class="slx-button slx-with-icon command-button">
                            <i class="fa fa-ban fa-2x slx-button__icon" aria-hidden="true"></i>
                        </button>
                    </div>
                </ng-template>
            </kendo-grid-command-column>
    
            <kendo-grid-column title="Label" field="name" [filterable]="true" [width]="50">
    
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span class="left-align">{{ dataItem.name }}</span>
                </ng-template>
    
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
                    </kendo-grid-string-filter-menu>
                </ng-template>
    
            </kendo-grid-column>
    
            <kendo-grid-column title="Group Start Time" field="startTime" [filterable]="true" [width]="50">
    
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span class="left-align">{{ dataItem.start | amDateFormat: appConfig.timeFormat }}</span>
                </ng-template>
    
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <slx-input-decorator className="slx-no-label slx-small-font">
                        <input slx-time-picker slx-input name="startTime{{rowIndex}}" required="true"
                            [(ngModel)]="dataItem.start" format="hh:mm tt" dateInput="true" (onDateChanged)="onShiftGroupDateChanged(dataItem)" />
                        <span errorMessage for="required">Start time is required</span>
                    </slx-input-decorator>
                </ng-template>
    
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-time-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-time-filter>
                </ng-template>
    
            </kendo-grid-column>
    
            <kendo-grid-column title="Group End Time" field="endTime" [filterable]="true" [width]="50">
    
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span class="left-align">{{ dataItem.end | amDateFormat: appConfig.timeFormat }}</span>
                </ng-template>
    
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <slx-input-decorator className="slx-no-label slx-small-font">
                        <input slx-time-picker slx-input name="endTime{{rowIndex}}" required="true"
                            [(ngModel)]="dataItem.end" format="hh:mm tt" dateInput="true" (onDateChanged)="onShiftGroupDateChanged(dataItem)" />
                        <span errorMessage for="required">Start time is required</span>
                    </slx-input-decorator>
                </ng-template>
    
                <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-time-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-time-filter>
                </ng-template>
    
            </kendo-grid-column>
    
            <kendo-grid-column title="Work Hours" field="shiftGroupWorkHours" [filterable]="true" [width]="50">
    
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span class="left-align">{{ dataItem.shiftGroupWorkHours }}</span>
                </ng-template>
    
                <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
                    <kendo-grid-string-filter-menu [column]="column" [filter]="filter" [filterService]="filterService">
                    </kendo-grid-string-filter-menu>
                </ng-template>
    
            </kendo-grid-column>
    
        </kendo-grid>
    
    </form>
</slx-spinner>
