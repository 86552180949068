import { Directive, OnInit, HostBinding, HostListener, Self } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { unsubscribe } from '../../../core/decorators/index';
import { CustomListActorBase } from '../../components/editableList/custom-list-actor.base';
import { ListActionsService } from '../../services/index';
import { EditableListActionModel, EditableListActionKind, EditableListProps } from '../../models/editableList/editable-list-action.model';

@Directive({
    selector: '[slx-list-actions]'
})
export class ListActionsDirective implements OnInit {

    @HostBinding('class.selected-row')
    private selection: boolean;

    @unsubscribe()
    private subscription: Subscription;

    constructor(private actionService: ListActionsService, @Self() private component: CustomListActorBase) {
    }

    public ngOnInit(): void {
        this.component.editable = this.actionService.editable;
        this.component.selectable = this.actionService.selectable;
        this.subscription = this.actionService.subscribe((msg: EditableListActionModel) => {
            this.onActionBroadcasted(msg);
        });
    }

    protected onActionBroadcasted(action: EditableListActionModel): void {
        if (action.kind === EditableListActionKind.SELECTION_CHANGE) {
            this.selection = this.actionService.isSelected(this.component.item);
            this.component.selected = this.selection;
        } else if (action.kind === EditableListActionKind.SELECT_ALL) {
            this.selection = this.actionService.allSelected;
            this.component.selected = this.selection;
        } else if (action.kind === EditableListActionKind.PROP_CHANGE) {
            if (action.changes) {
                if (action.changes.prop === EditableListProps.EDITABLE) {
                    this.component.editable = this.actionService.editable;
                }
                if (action.changes.prop === EditableListProps.SELECTABLE) {
                    this.component.selectable = this.actionService.selectable;
                }

                if (action.changes.prop === EditableListProps.EDIT_MODE) {
                    this.component.editMode = this.actionService.editMode;
                }
            }
        }
    }

    @HostListener('actionEmitter', ['$event'])
    private itemAction(kind: any): void {
        switch (kind) {
            case EditableListActionKind.SELECTION_CHANGE:
                this.actionService.toggleSelect(this.component.item);
                break;
            case EditableListActionKind.SELECT_ALL:
                this.actionService.selectAll();
                break;
            case EditableListActionKind.START_EDIT:
                this.actionService.startEditItem(this.component.item);
                break;
            case EditableListActionKind.CANCEL_EDIT:
                this.actionService.cancelEditItem(this.component.item);
                break;
            case EditableListActionKind.COMPLETE_EDIT:
                this.actionService.saveItem(this.component.item);
                break;
            case EditableListActionKind.REMOVE:
                this.actionService.removeItem (this.component.item);
                break;
        }
    }
}
