export interface ILogiIntegrationConfig {
  api: {
    logi: {
      root: string;
      secureKey: string;
    };
  };
}

export const logiIntegrationConfig: ILogiIntegrationConfig = {
  api: {
    logi: {
      root: 'logi',
      secureKey: 'key'
    }
  }
};
