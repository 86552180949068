import { StringUtils } from '../../../framework/index';
var EmployeeSubSection = /** @class */ (function () {
    function EmployeeSubSection() {
        this.isCollapsed = false;
        this.isInEdit = false;
        this.isEditableByConfiguration = true;
        this.isEditableWhenEmpTerminated = false;
        this.isNotEditableWhenEmpFutureRehired = false;
    }
    Object.defineProperty(EmployeeSubSection.prototype, "anchor", {
        get: function () {
            return StringUtils.format('#{0}', this.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSubSection.prototype, "contentElementId", {
        get: function () {
            return StringUtils.format('contentElement{0}', this.id);
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeSubSection;
}());
export { EmployeeSubSection };
