<div id="modal-content" class="modal-content">
  <div class="modal-body">
    <div id="message-block" class="message-block">Entered shift overlaps with an existing shifts!</div>
    <div class="option-block" *ngIf="request?.overlap.canReplace" ><span class="option-name">Replace</span> - Will replace the existing schedule with quick-edit shift</div>
    <div class="option-block" *ngIf="request?.overlap.canOverride" ><span class="option-name">Override</span> - Will adjust start/end time of the existing shift</div>
    <div class="option-block" *ngIf="request?.overlap.canAppend" ><span class="option-name">Append</span> - Will adjust start/end time of the quick-edit shift</div>
    <div class="option-block" *ngIf="request?.canCancel"  ><span class="option-name">Cancel</span> - Cancel current operation</div>
    <div class="exception-list">
       <div *ngFor="let msg of request?.overlap.messages">
          <span class="exception-text">{{msg}}</span>
       </div>
    </div>
  </div>
  <div class="modal-footer" name="modal-footer">
    <div class="buttons-container" name="buttons-container" [ngClass]="{'overlap-buttons' : request?.isMobile}">
      <button *ngIf="request?.overlap.canReplace" class="theme-button-cancel margin-r" [ngClass]="{'buttons-width' : request?.overlap.canReplace}" type="button" (click)="onReplace()" >Replace</button>
      <button *ngIf="request?.overlap.canOverride" class="theme-button-cancel margin-r" type="button" [ngClass]="{'overlap-button' : request?.overlap.canOverride}" (click)="onOverride()" >Override</button>
      <button *ngIf="request?.overlap.canAppend" class="theme-button-cancel margin-r"  [ngClass]="{'buttons-width' : request?.overlap.canAppend}"type="button" (click)="onAppend()" >Append</button>
      <button *ngIf="request?.canCancel" class="theme-button-cancel margin-r"   [ngClass]="{'buttons-width' : request?.canCancel}"type="button" (click)="onCancel()">Cancel</button>
   </div>
  </div>
</div>