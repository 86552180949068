import { Router, ActivatedRoute, Params } from '@angular/router';
import { appConfig } from '../../../app.config';
import * as moment from 'moment';

export class TimeclocksNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToTimeclocks(orgLevelId: number): void {
    let params: Params = {
        orgLevelId: orgLevelId
    };
    this.router.navigate(['time', 'review_timeclocks'], { relativeTo:   this.route.parent, queryParams: params });
  }

}
