
<span class="day"
  *ngFor="let day of weekDays"
  [class.cursor-pointer]="isClickable(day)"
  [style.borderRightWidth]="getBorder(day)"
  (click)="onDayClick(day)"
>
  <span class="day__line"
    *ngFor="let piece of day"
    [ngClass]="piece.cssClass"
    [style.left]="piece.left"
    [style.right]="piece.right"
    [style.backgroundColor]="loaRequest?.loaRequest?.typeColor"
  ></span>
</span>
