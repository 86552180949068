import { IndividualScheduleNightShiftService } from './../../../../../scheduler/services/individual-schedule/individual-schedule-night-shift.service';
import { ElementRef, OnInit } from '@angular/core';
import { Assert } from '../../../../../framework/index';
import { EmployeeRotation } from '../../../models/index';
import { WeekDayService } from '../../../services/index';
import { LookupApiService } from '../../../../../organization/services/lookup/lookup-api.service';
import { EmployeeShift, ShiftGroupOrder } from '../../../../../organization/models/index';
import * as _ from 'lodash';
import { appConfig } from '../../../../../app.config';
import * as moment from 'moment';
export var maximumWeeklyRotationsShown = 3;
var EmployeeRotationsComponent = /** @class */ (function () {
    function EmployeeRotationsComponent(weekDayService, lookupApiService, elementRef, nightShiftService) {
        this.nightShiftService = nightShiftService;
        Assert.isNotNull(weekDayService, 'weekDayService');
        this.weekDayService = weekDayService;
        this.selectedEmployeeShift = {
            isValid: false,
            constraint: undefined,
            position: undefined,
            shift: undefined,
            unit: undefined,
            absence: undefined,
            isDirty: false
        };
        this.isRotationsExpanded = false;
        this.isInEditMode = false;
    }
    Object.defineProperty(EmployeeRotationsComponent.prototype, "selectedRotation", {
        set: function (value) {
            this.selectedRotationValue = value;
            this.weekDaysRibbon = this.weekDayService.getWeekDaysByRotation(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRotationsComponent.prototype, "isExpandButtonVisible", {
        get: function () {
            return this.selectedRotationValue
                && this.selectedRotationValue.weeklyRotations
                && this.selectedRotationValue.weeklyRotations.length > maximumWeeklyRotationsShown;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeRotationsComponent.prototype.checkTemporalDirty = function () {
        return false;
    };
    EmployeeRotationsComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    Object.defineProperty(EmployeeRotationsComponent.prototype, "invalidRotations", {
        get: function () {
            var invalidRotations = [];
            if (this.selectedRotationValue !== null && this.selectedRotationValue !== undefined) {
                _.forEach(this.selectedRotationValue.weeklyRotations, function (w) {
                    _.forEach(w.dailyRecords, function (d) {
                        if (!d.isValid || d.showWarning) {
                            invalidRotations.push(d);
                        }
                    });
                });
            }
            return invalidRotations;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeRotationsComponent.prototype.onAddShiftClicked = function (dailyRecord) {
        this.addEmployeeShiftShift(dailyRecord, this.selectedEmployeeShift);
        this.validateRotation(this.selectedRotationValue);
    };
    EmployeeRotationsComponent.prototype.masterCellClick = function (dailyRecord) {
        if (this.isInEditMode) {
            var dateOn = moment(dailyRecord.weeklyRotation.weekStartDate).add(dailyRecord.dayNumber, 'days').toDate();
            this.selectedEmployeeShift.shift.startDate = this.copyTime(this.selectedEmployeeShift.shift.startDate, dateOn);
            this.selectedEmployeeShift.shift.endDate = this.copyTime(this.selectedEmployeeShift.shift.endDate, dateOn);
            if (moment(this.selectedEmployeeShift.shift.endDate).isBefore(this.selectedEmployeeShift.shift.startDate)) {
                this.selectedEmployeeShift.shift.endDate = moment(this.selectedEmployeeShift.shift.endDate).add(1, 'days').toDate();
            }
            this.addEmployeeShiftShift(dailyRecord, this.selectedEmployeeShift);
            this.validateRotation(this.selectedRotationValue);
        }
    };
    EmployeeRotationsComponent.prototype.copyTime = function (source, target) {
        return moment(target.setHours(source.getHours(), source.getMinutes(), source.getSeconds(), source.getMilliseconds())).toDate();
    };
    EmployeeRotationsComponent.prototype.onRemoveRotationShiftClicked = function (dailyRecord) {
        dailyRecord.clearShifts();
        this.validateRotation(this.selectedRotationValue);
    };
    EmployeeRotationsComponent.prototype.onRemoveShiftClicked = function (shift, dailyRecord, event) {
        this.removeEmployeeShift(dailyRecord, shift);
        this.validateRotation(this.selectedRotationValue);
        event.stopPropagation();
    };
    EmployeeRotationsComponent.prototype.onExpandCollapse = function () {
        this.isRotationsExpanded = !this.isRotationsExpanded;
    };
    EmployeeRotationsComponent.prototype.addEmployeeShiftShift = function (dailyRecord, employeeShift) {
        employeeShift.isDirty = true;
        dailyRecord.setNextShift(this.cloneEmployeeShift(employeeShift));
        this.validateRotation(this.selectedRotationValue);
    };
    EmployeeRotationsComponent.prototype.cloneEmployeeShift = function (employeeShift) {
        var cloned = _.cloneDeep(employeeShift);
        cloned.shift.fullStartDate = cloned.shift.startDate;
        cloned.shift.fullEndDate = cloned.shift.endDate;
        if (cloned.shift.isNextDayShift) {
            if (!this.nightShiftService.nightShiftPrimary || cloned.shift.group.groupOrder !== ShiftGroupOrder.Night) {
                cloned.shift.fullEndDate = moment(cloned.shift.endDate).add(1, 'days').toDate();
            }
            else {
                cloned.shift.fullStartDate = moment(cloned.shift.startDate).subtract(1, 'days').toDate();
            }
        }
        return cloned;
    };
    EmployeeRotationsComponent.prototype.removeEmployeeShift = function (dailyRecord, employeeShift) {
        dailyRecord.removeShift(_.clone(employeeShift));
        dailyRecord.showWarning = false;
    };
    EmployeeRotationsComponent.prototype.validateRotation = function (rotation) {
        var _this = this;
        var isValid = true;
        var rotationRecords = rotation.weeklyRotations
            .map(function (weeklyRotation) { return weeklyRotation.dailyRecords; })
            .reduce(function (dailyRecordsResult, dailyRecords) { return dailyRecordsResult.concat(dailyRecords); }, []);
        var _loop_1 = function (i) {
            var record = rotationRecords[i];
            var shifts = [];
            var errors = new Map();
            record.isValid = true;
            record.validationMessage = undefined;
            if (record.shifts !== null && record.shifts !== undefined) {
                _.forEach(record.shifts, function (shiftRecord) {
                    if (shiftRecord.shift.fullEndDate === undefined || shiftRecord.shift.fullStartDate === undefined) {
                        shiftRecord.shift.fullStartDate = shiftRecord.shift.startDate;
                        shiftRecord.shift.fullEndDate = shiftRecord.shift.endDate;
                        if (shiftRecord.shift.isNextDayShift) {
                            if (!_this.nightShiftService.nightShiftPrimary || shiftRecord.shift.group.groupOrder !== ShiftGroupOrder.Night) {
                                shiftRecord.shift.fullEndDate = moment(shiftRecord.shift.endDate).add(1, 'days').toDate();
                            }
                            else {
                                shiftRecord.shift.fullStartDate = moment(shiftRecord.shift.startDate).subtract(1, 'days').toDate();
                            }
                        }
                    }
                    shifts.push(shiftRecord.shift);
                });
            }
            if (i > 0) {
                var previousRecord = rotationRecords[i - 1];
                if (this_1.isRotationRecordsOverlapped(record, previousRecord)) {
                    errors.set('previousOverlapped', true);
                    record.isValid = false;
                    record.validationMessage = 'Shifts of this record are overlapped with previous one.';
                }
                if (i < rotationRecords.length - 1) {
                    var nextRecord = rotationRecords[i + 1];
                    if (nextRecord.shiftEnd !== undefined && nextRecord.shiftStart !== undefined
                        && record.shiftEnd !== undefined && record.shiftStart !== undefined
                        && moment(record.shiftStart).isBefore(nextRecord.shiftEnd)
                        && moment(record.shiftEnd).isAfter(nextRecord.shiftStart)) {
                        errors.set('Overlapped', true);
                        record.isValid = false;
                        record.validationMessage = 'Shifts of this record are overlapped with next one.';
                    }
                }
            }
            if (this_1.isShiftsOverlapped(shifts)) {
                errors.set('overlapped', true);
                record.showWarning = true;
                if (errors.has('previousOverlapped') || errors.has('lastOverlapped')) {
                    record.isValid = false;
                    record.validationMessage = record.validationMessage + ' Shifts of this record are overlapped.';
                }
                else {
                    record.isValid = false;
                    record.validationMessage = 'Shifts of this record are overlapped.';
                }
            }
            if (this_1.areShiftsNested(shifts)) {
                errors.set('nested', true);
                record.isValid = false;
                if (errors.has('overlapped')) {
                    record.validationMessage = record.validationMessage.replace('Shifts of this record are overlapped.', '');
                    record.validationMessage = record.validationMessage + ' Shifts of this record are nested.';
                }
                else {
                    record.validationMessage = 'Shifts of this record are nested.';
                }
            }
            if (this_1.areShiftsDuplicated(shifts)) {
                record.isValid = false;
                if (errors.has('overlapped')) {
                    if (errors.has('nested')) {
                        record.validationMessage = record.validationMessage.replace(' Shifts of this record are nested.', '');
                    }
                    record.validationMessage = record.validationMessage + ' Shifts of this record are duplicated.';
                }
                else {
                    record.validationMessage = 'Shifts of this record are duplicated.';
                }
            }
        };
        var this_1 = this;
        for (var i = 0; i < rotationRecords.length; i++) {
            _loop_1(i);
        }
    };
    EmployeeRotationsComponent.prototype.isRotationRecordsOverlapped = function (current, previous) {
        for (var i = 0; i < current.shifts.length; i++) {
            for (var j = 0; j < previous.shifts.length; j++) {
                return moment(current.shifts[i].shift.fullStartDate).isBefore(moment(previous.shifts[j].shift.fullEndDate))
                    && moment(current.shifts[i].shift.fullEndDate).isAfter(moment(previous.shifts[j].shift.fullStartDate));
            }
        }
    };
    EmployeeRotationsComponent.prototype.isShiftsOverlapped = function (shifts) {
        for (var i = 0; i < shifts.length; i++) {
            for (var j = i + 1; j < shifts.length; j++) {
                return moment(shifts[i].fullStartDate).isBefore(moment(shifts[j].fullEndDate))
                    && moment(shifts[i].fullEndDate).isAfter(moment(shifts[j].fullStartDate));
            }
        }
        return false;
    };
    EmployeeRotationsComponent.prototype.areShiftsDuplicated = function (shifts) {
        for (var i = 0; i < shifts.length; i++) {
            for (var j = i + 1; j < shifts.length; j++) {
                if ((shifts[i].startDate.getTime() === shifts[j].startDate.getTime()) && (shifts[i].endDate.getTime() === shifts[j].endDate.getTime())) {
                    return true;
                }
            }
        }
        return false;
    };
    EmployeeRotationsComponent.prototype.areShiftsNested = function (shifts) {
        for (var i = 0; i < shifts.length; i++) {
            for (var j = 0; j < shifts.length; j++) {
                if (i !== j && moment.range(shifts[i].fullStartDate, shifts[i].fullEndDate).contains(shifts[j].fullStartDate)
                    && moment.range(shifts[i].fullStartDate, shifts[i].fullEndDate).contains(shifts[j].fullEndDate)) {
                    return true;
                }
            }
        }
        return false;
    };
    EmployeeRotationsComponent.prototype.getWeekStartDate = function (weeklyRotation, dateIndex) {
        if (_.isObjectLike(weeklyRotation)) {
            var dayNumber = weeklyRotation.weekStartDate.getDate();
            var monthEnd = +moment(weeklyRotation.weekStartDate).endOf('month').format('D');
            if (dayNumber + dateIndex <= monthEnd) {
                return weeklyRotation.weekStartDate.getDate() + dateIndex;
            }
            else {
                return weeklyRotation.weekStartDate.getDate() + dateIndex - monthEnd;
            }
        }
    };
    return EmployeeRotationsComponent;
}());
export { EmployeeRotationsComponent };
