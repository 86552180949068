import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { IPayrollPayCode, PayrollPayCode } from "../../../../../app/configuration/models/payroll-pay-codes/payroll-pay-code";

@Injectable()
export class PayrollPayCodesMapService {

  public mapPayrollPayCodes(dtos: IPayrollPayCode[]): PayrollPayCode[] {
      return dtos.map(x => this.mapPayrollPayCode(x));
  }

  public mapPayrollPayCode(dto: IPayrollPayCode): PayrollPayCode {
      const payCodes: PayrollPayCode = new PayrollPayCode();
      payCodes.payNumber = dto.payNumber;
      payCodes.payrollPaycode = dto.payrollPaycode;
      return payCodes;
  }

  public mapPayrollPayCodesDto(payCodes: PayrollPayCode[]): any {
    const requestBody = {
        "List": _.map(payCodes, payCode => this.mapPayrollPayCodeDto(payCode))
    }
    return requestBody;
  }

  public mapPayrollPayCodeDto(payCode: PayrollPayCode): IPayrollPayCode {
    const dto: IPayrollPayCode = {
        payNumber: payCode.payNumber,
        payrollPaycode: payCode.payrollPaycode
    };
    return dto;
  }
}

