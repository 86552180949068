import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ColumnSettingsDefinitions } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ColumnSettingsStorageService, ExceptionConsoleNavigationService } from '../../../common/services/index';
import { ExceptionConsoleRollupState } from '../../models/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { ExceptionConsoleApiService } from '../index';
var ExceptionConsoleRollupManagementService = /** @class */ (function () {
    function ExceptionConsoleRollupManagementService(router, route, apiService, columnSettingsStorageService) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.apiService = apiService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.onLoadStatus$ = new ReplaySubject(1);
        this.onStateChanged$ = new ReplaySubject(1);
        this.onLoaded$ = new ReplaySubject(1);
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return _this.validateOrgLevel(o); })
            .subscribe(function (o) {
            _this.onOrgLevelChanged(o);
        });
        this.state = new ExceptionConsoleRollupState();
        this.state.createColumns();
        this.state.mapColumns();
        this.state.columns = this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE_ROLLUP.toString(), undefined, this.state.columns);
        this.onStateChanged(this.state);
    }
    ExceptionConsoleRollupManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    ExceptionConsoleRollupManagementService.prototype.onStateChanged = function (state) {
        this.columnSettingsStorageService.setColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE_ROLLUP.toString(), undefined, this.state.columns);
        this.onStateChanged$.next(state);
    };
    ExceptionConsoleRollupManagementService.prototype.onLoadStatusChanged = function (isLoading) {
        this.onLoadStatus$.next(isLoading);
    };
    ExceptionConsoleRollupManagementService.prototype.loadExceptionConsoleRecords = function (orgLevelId, startDate, endDate) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getExceptionConsoleRollupRecords(orgLevelId, startDate, endDate)
            .then(function (container) {
            _this.state.setDynamicColumns(container.exceptionColumns);
            _this.state.columns = _this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE.toString(), undefined, _this.state.columns);
            _this.onLoaded(container);
            _this.onStateChanged(_this.state);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (reason) {
            _this.onLoaded(null);
            _this.onLoadStatusChanged(false);
        });
    };
    ExceptionConsoleRollupManagementService.prototype.onLoaded = function (container) {
        this.onLoaded$.next(container);
    };
    ExceptionConsoleRollupManagementService.prototype.navigateToDateRange = function (range, usePayCycle) {
        this.currentRange = range;
        if (this.currentOrgLevel) {
            var nav = new ExceptionConsoleNavigationService(this.router, this.route);
            nav.navigateToExceptionConsoleDates(this.currentOrgLevel.id, range.startDate, range.endDate, usePayCycle);
        }
    };
    ExceptionConsoleRollupManagementService.prototype.setDateRange = function (range) {
        this.currentRange = range;
        if (this.currentOrgLevel) {
            this.loadRecords();
        }
    };
    ExceptionConsoleRollupManagementService.prototype.getOrgLevelTitle = function () {
        if (this.needShowDepartments) {
            return 'Department';
        }
        else {
            return 'Organization';
        }
    };
    Object.defineProperty(ExceptionConsoleRollupManagementService.prototype, "needShowDepartments", {
        get: function () {
            return this.currentOrgLevel.type === OrgLevelType.organization;
        },
        enumerable: true,
        configurable: true
    });
    ExceptionConsoleRollupManagementService.prototype.validateOrgLevel = function (o) {
        var orgLevelType = _.get(o, 'type');
        var isSameOrgLevel = _.get(this.currentOrgLevel, 'id') === _.get(o, 'id');
        return !isSameOrgLevel && orgLevelType !== OrgLevelType.department;
    };
    ExceptionConsoleRollupManagementService.prototype.onOrgLevelChanged = function (o) {
        this.currentOrgLevel = o;
        this.loadRecords();
    };
    ExceptionConsoleRollupManagementService.prototype.loadRecords = function () {
        if (this.currentOrgLevel && this.currentRange) {
            this.loadExceptionConsoleRecords(this.currentOrgLevel.id, this.currentRange.startDate, this.currentRange.endDate);
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ExceptionConsoleRollupManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleRollupManagementService.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleRollupManagementService.prototype, "routeSubscripion", void 0);
    return ExceptionConsoleRollupManagementService;
}());
export { ExceptionConsoleRollupManagementService };
