import { IEssTemplateDefinition, EssTemplateDefinition } from './ess-template-definition';
import { EssTimeEntryMethod, EssDefaultPassword } from '../../../organization/models/index';
import { IEssTemplateOptionGroup, EssTemplateOptionGroup } from './ess-template-option-group';

export interface IEssTemplate {
  essTemplateDefinition: IEssTemplateDefinition;
  groups: IEssTemplateOptionGroup[];
  defaultPwdTemplate: string;
  timeEntryMethod: string;
  certifyTimecardMessage: string;
}

export class EssTemplate {
  public essTemplateDefinition: EssTemplateDefinition;
  public groups: EssTemplateOptionGroup[];
  public defaultPwdTemplate: EssDefaultPassword;
  public timeEntryMethod: EssTimeEntryMethod;
  public certifyTimecardMessage: string;
}
