/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./punch-logs-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../punch-logs/punch-logs.component.ngfactory";
import * as i3 from "../../../services/punches/punch-logs-management.service";
import * as i4 from "../../../services/punches/punches-api.service";
import * as i5 from "../punch-logs/punch-logs.component";
import * as i6 from "./punch-logs-dialog.component";
import * as i7 from "../../../../common/models/dialog-options";
import * as i8 from "../../../../common/services/modal/modal.service";
import * as i9 from "../../../models/punches/punch-log";
var styles_PunchLogsDialogComponent = [i0.styles];
var RenderType_PunchLogsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PunchLogsDialogComponent, data: {} });
export { RenderType_PunchLogsDialogComponent as RenderType_PunchLogsDialogComponent };
export function View_PunchLogsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "slx-punch-logs", [], null, null, null, i2.View_PunchLogsComponent_0, i2.RenderType_PunchLogsComponent)), i1.ɵprd(512, null, i3.PunchLogsManagementService, i3.PunchLogsManagementService, [i4.PunchesApiService]), i1.ɵdid(4, 770048, null, 0, i5.PunchLogsComponent, [i3.PunchLogsManagementService], { employeeId: [0, "employeeId"], employeeName: [1, "employeeName"], date: [2, "date"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.request == null) ? null : _co.request.employeeId); var currVal_1 = ((_co.request == null) ? null : _co.request.employeeName); var currVal_2 = ((_co.request == null) ? null : _co.request.startDate); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PunchLogsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-punch-logs-dialog", [], null, null, null, View_PunchLogsDialogComponent_0, RenderType_PunchLogsDialogComponent)), i1.ɵdid(1, 49152, null, 0, i6.PunchLogsDialogComponent, [i7.DialogOptions, i8.ModalService, i9.PunchLogRequest], null, null)], null, null); }
var PunchLogsDialogComponentNgFactory = i1.ɵccf("slx-punch-logs-dialog", i6.PunchLogsDialogComponent, View_PunchLogsDialogComponent_Host_0, {}, {}, []);
export { PunchLogsDialogComponentNgFactory as PunchLogsDialogComponentNgFactory };
