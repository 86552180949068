import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DetailGroupViewSettingsTypes } from './../../../models/index';
import { Comparison, DisplayDefinition, DisplayType, SoValuePairWidgetConfig, ChartMenuItem, ChartType, ConsoleConfig, ScheduleConsoleViewItem } from '../../models/index';
import { ValuePairChartInput, ValuePairChartModel } from '../../../../common/models/index';
import { ValuePairChartDataService, SoConsoleNavigationService, DailyUnitAssignmentNavigationService, LeaveRequestsNavigationService, StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { ScheduleConsoleChartService } from '../../services/index';
import { IControlState, StateResetTypes } from '../../../../core/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-graphic-comparison',
  templateUrl: 'schedule-console-graphic-comparison.component.html',
  styleUrls: ['schedule-console-graphic-comparison.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleConsoleGraphicComparisonComponent {
  @Input()
  public comparisons: Comparison[];

  @Input()
  public comparisonName: string;

  @Input()
  public consoleConfig: ConsoleConfig;

  @Input()
  public chart: ChartMenuItem;

  @Input()
  public rangeType: string;
  public chartTypes: typeof ChartType = ChartType;
  public widgetConfig: SoValuePairWidgetConfig;

  @Output()
  public onNeedScroll: EventEmitter<HTMLElement>;

  private router: Router;
  private activatedRoute: ActivatedRoute;
  private soNavService: SoConsoleNavigationService;
  private lastClickedId: string;
  private lastClickedKey: string = 'LAST_CLICKED_ID';

  public positionId: number;
  public positionGroupId: number;

  constructor(router: Router, activatedRoute: ActivatedRoute,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService) {
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.onNeedScroll = new EventEmitter();
    this.widgetConfig = new SoValuePairWidgetConfig();
    this.soNavService = new SoConsoleNavigationService(this.router, this.activatedRoute);
    this.restoreLastClickedId();
  }

  public getChartInput(comparison: Comparison): ValuePairChartInput {
    const chartInput: ValuePairChartInput = ScheduleConsoleChartService.makeChartInput(comparison, this.consoleConfig);

    return chartInput;
  }

  public handleOnClick(comparison: Comparison): void {
    this.saveLastClicked(comparison);
    if (comparison.orgLevel.type === OrgLevelType.corporation
      || comparison.orgLevel.type === OrgLevelType.organization
      || comparison.orgLevel.type === OrgLevelType.department) {
      this.soNavService.navigateToSoConsole(comparison.orgLevel.id);
    } else if (comparison.orgLevel.type === 'Position' || comparison.orgLevel.type === 'PositionGroup') {
      const viewItem: ScheduleConsoleViewItem = ScheduleConsoleChartService.makeSCViewItem(comparison, this.consoleConfig.displayType);
      let navService: DailyUnitAssignmentNavigationService = new DailyUnitAssignmentNavigationService(this.router, this.activatedRoute);
      let unitId: number = comparison.unit ? comparison.unit.id : undefined;
      if (comparison.orgLevel.type === 'Position') { this.positionId = comparison.orgLevel.id; }
      if (comparison.orgLevel.type === 'PositionGroup') { this.positionGroupId = comparison.orgLevel.id; }
      navService.navigateToDailyUnitAssignment(comparison.date.toDate(), this.positionId, viewItem.viewMode, this.positionGroupId, unitId);
    }
  }

  public handleBadgePtoClick(comparison: Comparison): void {
    this.saveLastClicked(comparison);
    let navService: LeaveRequestsNavigationService = new LeaveRequestsNavigationService(this.router, this.activatedRoute);
    navService.NavigateToLeaveRequests();
  }

  public itWasClicked(comparison: Comparison): boolean {
    return this.lastClickedId && this.getComparisonId(comparison) === this.lastClickedId;
  }

  public onCreated(item: HTMLElement): void {
    this.onNeedScroll.emit(item);
  }

  private getComparisonId(comparison: Comparison): string {
    return this.comparisonName + '_' + comparison.uniqId;
  }

  private saveLastClicked(comparison: Comparison): void {
    let lastId: string = this.getComparisonId(comparison);
    this.storageService.setControlState(this.stateManagement.componentKey, this.lastClickedKey,
      { value: lastId }, StateResetTypes.SessionEnd);
  }

  private restoreLastClickedId(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.lastClickedKey);
    if (state && state.value) {
      this.lastClickedId = state.value;
    }
  }
}
