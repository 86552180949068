import { ModalService } from './../../../common/services/modal/modal.service';
import { BenAdminMenuManagementService } from './ben-admin/ben-admin-menu-management.service';
import { OrgLevel } from './../../../state-model/models/org-level/org-level';
import { ApplicationStateBusService } from './../application-state-bus/application-state-bus.service';
import { Subscription } from 'rxjs/Subscription';
import { RestoreQueryParamsService } from './../../../common/services/navigation/restore-query-params.service';
import { ChangeManagementService } from './../../../common/services/change-management/change-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StateNavigationService } from './../../../common/services/navigation/state-navigation.service';
import { GetHiredMenuManagementService } from './get-hired/get-hired-menu-managment.service';
import { SsoNavigationService } from './sso-navigation.service';
import { InfoDialogComponent } from './../../../common/components/info-dialog/info-dialog.component';
import { appConfig } from './../../../app.config';
import { NavigationMenuItem } from './../../models/navigation-menu-item';
import { Injectable } from '@angular/core';
import { unsubscribeInService } from './../../../core/decorators';
import { scheduleMicrotask } from './../../../core/utils';

@Injectable()
export class NavigationMenuRedirectService {
  private navService: StateNavigationService;
  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  private orgLevel: OrgLevel;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ssoService: SsoNavigationService,
    private getHiredService: GetHiredMenuManagementService,
    private changeManagement: ChangeManagementService,
    private restoreQueryParamsService: RestoreQueryParamsService,
    private appStateBusService: ApplicationStateBusService,
    private benAdminService: BenAdminMenuManagementService,
    private modalService: ModalService
    ) {

      this.navService = new StateNavigationService(router, route, changeManagement, restoreQueryParamsService);
      this.orgLevelSubscription = this.appStateBusService.subscribeToSelectOrgLevel((orgLevel: OrgLevel) => {
        scheduleMicrotask(() => {
          this.orgLevel = orgLevel;
        });
      });
    }

  public open(item: NavigationMenuItem): void {
    if (item.menuItemMapping.isV5App) {
      this.navigateSSO(item.link);
    } else {
      if (!this.openAlterPath(item.link)) {
        this.openByLink(item);
      }
    }
  }

  public openByLink(item: NavigationMenuItem): void {
    this.navService.navigateToMenu(item.appLink, item.link);
  }

  private navigateSSO(menuItem: string): void {
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${appConfig.api.sso}/${menuItem}?orglevel=${this.orgLevel.id}`;
    this.ssoService.navigationToV5(url);
  }

  private openAlterPath(link: string): boolean {

    if (this.benAdminService.isBenAdminItem(link)) {
      let menuItemState = this.benAdminService.getBenAdminMenuItemState();
      if (!menuItemState.isMenuAccessible) {
        InfoDialogComponent.OpenDialog('Warning', menuItemState.userMessage, this.modalService);
      } else {
        this.benAdminService.openBswift();
      }
      return true;
    }

    if (link === 'applicant_tracker') {
      this.getHiredService.openGetHired(this.orgLevel.id);
      return true;
    }

    return false;
  }
}
