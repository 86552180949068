import * as tslib_1 from "tslib";
import { Meta, EditResult } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { benefitsConfig } from '../../benefits.config';
import { BenefitClassMapService } from './benefit-class-map.service';
var BenefitClassApiService = /** @class */ (function () {
    function BenefitClassApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    BenefitClassApiService.prototype.getBenefitClasses = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.configuration.benefitClass;
                        request = this.urlParamsService.requestGet(url, { orgLevelId: orgLevelId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitClasses(response.data)];
                }
            });
        });
    };
    BenefitClassApiService.prototype.validateBenefitClass = function (id, value, field, orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.configuration.benefitClass + "/" + benefitsConfig.api.configuration.validate;
                        request = this.urlParamsService.requestGet(url, { id: id, value: value, field: field, orgLevelId: orgLevelId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    BenefitClassApiService.prototype.addBenefitClass = function (benefitClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.configuration.benefitClass;
                        request = this.urlParamsService.requestPost(url, this.mapService.mapToBenefitClassDto(benefitClass));
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapEditResult(response.data)];
                }
            });
        });
    };
    BenefitClassApiService.prototype.saveBenefitClass = function (benefitClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.configuration.benefitClass;
                        request = this.urlParamsService.requestPut(url, this.mapService.mapToBenefitClassDto(benefitClass));
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapEditResult(response.data)];
                }
            });
        });
    };
    BenefitClassApiService.prototype.deleteBenefitClass = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.configuration.benefitClass;
                        request = this.urlParamsService.requestDelete(url, { id: id });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapEditResult(response.data)];
                }
            });
        });
    };
    BenefitClassApiService.prototype.getApiUrl = function () {
        return this.apiUtilService.getApiRoot() + "/" + benefitsConfig.api.configuration.root;
    };
    return BenefitClassApiService;
}());
export { BenefitClassApiService };
