/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roles-management.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/directives/slx-tooltip/slx-tooltip.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../../../components-library/components/multiselect/multiselect.component.ngfactory";
import * as i5 from "../../../../components-library/components/multiselect/multiselect.component";
import * as i6 from "@angular/forms";
import * as i7 from "../roles-access-table-grid/roles-access-table-grid.component.ngfactory";
import * as i8 from "../roles-access-table-grid/roles-access-table-grid.component";
import * as i9 from "../../../services/roles/roles-management.service";
import * as i10 from "../roles-definition-grid/roles-definition-grid.component.ngfactory";
import * as i11 from "../roles-definition-grid/roles-definition-grid.component";
import * as i12 from "../../../services/roles/roles-definition-management.service";
import * as i13 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i14 from "../../../../common/components/spinner/spinner.component";
import * as i15 from "../../../../common/services/component-state/component-state-storage.service";
import * as i16 from "../../../../core/services/user-settings/user-settings.service";
import * as i17 from "../../../../common/services/app-user-settings/app-user-settings.service";
import * as i18 from "../../../../common/services/state-management/state-management.service";
import * as i19 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i20 from "../../../services/roles/roles-api.service";
import * as i21 from "../../../../organization/services/lookup/lookup-api.service";
import * as i22 from "../../../../organization/services/product-module/product-module-map.service";
import * as i23 from "../../../../common/services/change-management/change-management.service";
import * as i24 from "../../../../common/services/modal/modal.service";
import * as i25 from "./roles-management.component";
var styles_RolesManagementComponent = [i0.styles];
var RenderType_RolesManagementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RolesManagementComponent, data: {} });
export { RenderType_RolesManagementComponent as RenderType_RolesManagementComponent };
function View_RolesManagementComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fas fa-exclamation-triangle warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["slxTooltip", "Editing disabled when more than 5 roles are selected"], ["tipPosition", "right"]], null, null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i2.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null)], function (_ck, _v) { var currVal_0 = "Editing disabled when more than 5 roles are selected"; var currVal_1 = "right"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_RolesManagementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "roles-header__l-col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesManagementComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon slx-mobile-adapted slx-margin-r"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSaveChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save Changes"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon slx-mobile-adapted slx-margin-r"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDicardChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-ban slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Discard Changes"])), (_l()(), i1.ɵeld(12, 0, null, null, 12, "div", [["class", "roles-header__r-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "slx-button slx-min-w90 slx-margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPasswordSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Password Settings"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "button", [["class", "slx-button slx-min-w90 slx-margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowLegend() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Legend"])), (_l()(), i1.ɵeld(17, 0, null, null, 1, "button", [["class", "slx-button slx-min-w90 slx-margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onManagingRoles() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage Roles"])), (_l()(), i1.ɵeld(19, 0, null, null, 5, "slx-multiselect", [["class", "roles-header__filters"], ["name", "statusFilter"], ["placeholder", "Roles filter..."]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.shownColumns = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onFiltersChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MultiselectComponent_0, i4.RenderType_MultiselectComponent)), i1.ɵdid(20, 49152, null, 0, i5.MultiselectComponent, [], { options: [0, "options"], placeholder: [1, "placeholder"] }, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.MultiselectComponent]), i1.ɵdid(22, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(24, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.canSave; _ck(_v, 3, 0, currVal_0); var currVal_10 = _co.columnsList; var currVal_11 = "Roles filter..."; _ck(_v, 20, 0, currVal_10, currVal_11); var currVal_12 = "statusFilter"; var currVal_13 = _co.shownColumns; _ck(_v, 22, 0, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.hasChanges || !_co.canSave); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.hasChanges; _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 24).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 24).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 24).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 24).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 24).ngClassValid; var currVal_8 = i1.ɵnov(_v, 24).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 24).ngClassPending; _ck(_v, 19, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
function View_RolesManagementComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "flex-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["class", "slx-button slx-min-w90"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddDefinition() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Role"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "slx-button slx-min-w90"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloneDefinition() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Clone Role"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "slx-button slx-min-w90"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onManagingAccessTable() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage Access Table"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.manageModeHasChanges; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.selectedRole || _co.manageModeHasChanges); _ck(_v, 4, 0, currVal_1); }); }
function View_RolesManagementComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-roles-access-table-grid", [], null, null, null, i7.View_RolesAccessTableGridComponent_0, i7.RenderType_RolesAccessTableGridComponent)), i1.ɵdid(2, 245760, null, 0, i8.RolesAccessTableGridComponent, [i9.RolesManagementService, i1.ChangeDetectorRef, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_RolesManagementComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-roles-definition-grid", [], null, null, null, i10.View_RolesDefinitionGridComponent_0, i10.RenderType_RolesDefinitionGridComponent)), i1.ɵdid(2, 245760, null, 0, i11.RolesDefinitionGridComponent, [i12.RolesDefinitionManagementService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_RolesManagementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "slx-spinner", [], null, null, null, i13.View_SpinnerComponent_0, i13.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i14.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 10, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "slx-content-toolbar-indents roles-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesManagementComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesManagementComponent_3)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesManagementComponent_4)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RolesManagementComponent_5)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.state.isLoading || _co.state.isAccessTableLoading); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.state.isManageRoleMode; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.state.isManageRoleMode; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.state.isManageRoleMode; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.state.isManageRoleMode; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_RolesManagementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-roles-management", [], null, null, null, View_RolesManagementComponent_0, RenderType_RolesManagementComponent)), i1.ɵprd(512, null, i15.ComponentStateStorageService, i15.ComponentStateStorageService, [i16.UserSettingsService, i17.AppUserSettingsService]), i1.ɵprd(512, null, i18.StateManagementService, i18.StateManagementService, [i15.ComponentStateStorageService, i19.ColumnSettingsStorageService]), i1.ɵprd(512, null, i9.RolesManagementService, i9.RolesManagementService, [i20.RolesApiService, i21.LookupApiService, i22.ProductModuleMapService, i23.ChangeManagementService, i18.StateManagementService, i15.ComponentStateStorageService, i24.ModalService]), i1.ɵprd(512, null, i12.RolesDefinitionManagementService, i12.RolesDefinitionManagementService, [i20.RolesApiService, i21.LookupApiService, i23.ChangeManagementService]), i1.ɵdid(5, 245760, null, 0, i25.RolesManagementComponent, [i9.RolesManagementService, i12.RolesDefinitionManagementService, i24.ModalService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
var RolesManagementComponentNgFactory = i1.ɵccf("slx-roles-management", i25.RolesManagementComponent, View_RolesManagementComponent_Host_0, {}, {}, []);
export { RolesManagementComponentNgFactory as RolesManagementComponentNgFactory };
