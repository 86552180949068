export class ChangesDialogOptions {
  public static NAVIGATE: ChangesDialogOptions = {
    questionMessage: 'Do you want to leave this page?',
    warningMessage: 'The changes you made will not be saved.',
    cancelButtonText: 'Stay',
    continueButtonText: 'Leave',
  };
  public static DOWORK: ChangesDialogOptions = {
    questionMessage: 'Do you want to proceed this operation?',
    warningMessage: 'The changes you made will not be saved.',
    cancelButtonText: 'Cancel',
    continueButtonText: 'Continue',
  };

  public questionMessage: string;
  public warningMessage: string;
  public continueButtonText: string;
  public cancelButtonText: string;
}
