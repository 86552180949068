import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../app.config';
import { StateManagementService, ColumnManagementService } from '../../../../../../common/services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitDetailsTier } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/index';
var BenefitOptionDetailFlatGridComponent = /** @class */ (function () {
    function BenefitOptionDetailFlatGridComponent(manService, stateManagement, columnManagementService) {
        this.manService = manService;
        this.stateManagement = stateManagement;
        this.columnManagementService = columnManagementService;
        this.columnGroup = 'BenefitOptionDetailFlatGridComponent';
        this.data = [];
        this.coverageList = [];
        this.isLoading = true;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.selectableSettings = {
            checkboxOnly: true
        };
        this.appConfig = appConfig;
    }
    BenefitOptionDetailFlatGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('BenefitOptionDetailFlatGridComponent');
        this.columnManagementService.init('BenefitOptionDetailFlatGridComponent');
        this.columnManagementService.initGroup(this.columnGroup, 2);
        this.defaultContribution();
        this.refreshGrid();
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    BenefitOptionDetailFlatGridComponent.prototype.defaultContribution = function () {
        var item = new BenefitDetailsTier();
        item.empContribution = this.tierDetails.empContribution;
        item.erContribution = this.tierDetails.erContribution;
        this.data.push(item);
    };
    BenefitOptionDetailFlatGridComponent.prototype.ngOnDestroy = function () {
    };
    BenefitOptionDetailFlatGridComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        if (!this.data) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.data, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitOptionDetailFlatGridComponent.prototype, "subscriptions", void 0);
    return BenefitOptionDetailFlatGridComponent;
}());
export { BenefitOptionDetailFlatGridComponent };
