import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { organizationConfig } from '../../../organization/organization.config';
import { appConfig } from '../../../app.config';
import { ConfirmDialog2Component, ConfirmOptions, ScheduleEntryNavigationService } from '../../../common/index';
import { ScheduleEntryContainer } from '../../models/index';
import { MasterScheduleManagementService, ScheduleEntryApiService, ScheduleEntryManagementService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/unsubscribe-decorator';
import { ModalService } from '../../../common/services/modal/modal.service';
import { ShiftReplacementReplaceComponent } from '../shift-replacement/shift-replacement-replace/shift-replacement-replace.component';
import { EmployeeTALogComponent } from '../../../employee/employee/components/employee-ta-log/employee-ta-log.component';
import { AppSettingsManageService } from '../../../../app/app-settings/services/app-settings-manage.service';
var ScheduleEntryComponent = /** @class */ (function () {
    function ScheduleEntryComponent(management, activatedRoute, managementService, router, location, appSettingsManageService, modalService, scheduleEntryApiService, datepipe) {
        this.management = management;
        this.activatedRoute = activatedRoute;
        this.managementService = managementService;
        this.router = router;
        this.location = location;
        this.appSettingsManageService = appSettingsManageService;
        this.modalService = modalService;
        this.scheduleEntryApiService = scheduleEntryApiService;
        this.datepipe = datepipe;
        this.scheduleEntryNavigationService = new ScheduleEntryNavigationService(this.router, this.activatedRoute);
        this.organizationConfig = organizationConfig;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            isOverlaping: false,
            hasDuplicates: false
        };
    }
    Object.defineProperty(ScheduleEntryComponent.prototype, "shiftCount", {
        get: function () {
            if (this.scheduleEntryContainer && this.scheduleEntryContainer.shifts) {
                return this.scheduleEntryContainer.shifts.length;
            }
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    ScheduleEntryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initSubscription = this.management.onInitialized$.subscribe(function (entryContainer) {
            _this.scheduleEntryContainer = entryContainer;
        });
        this.loadStateSubscription = this.management.onLoadState$.subscribe(function (isLoading) {
            _this.state.isLoading = isLoading;
        });
        this.overlapStateSubscription = this.management.onOverlapState$.subscribe(function (isOverlapping) {
            _this.state.hasDuplicates = false;
            _this.state.isOverlaping = isOverlapping;
        });
        this.duplicateStateSubscription = this.management.onDuplicateState$.subscribe(function (hasDuplicates) {
            _this.state.isOverlaping = false;
            _this.state.hasDuplicates = hasDuplicates;
        });
        this.replacedStateSubscription = this.management.onReplaceShift$.subscribe(function (request) {
            var replacementComponent = ShiftReplacementReplaceComponent.openDialog(request, _this.modalService, function (result, cmd) {
                if (result && cmd) {
                    _this.management.doReplace(cmd);
                }
            });
        });
        this.initialize(this.activatedRoute, this.router);
        this.getSettings();
        this.actionsSubscription = this.managementService.actions$.subscribe(function (data) {
            _this.actions = data;
        });
    };
    ScheduleEntryComponent.prototype.initialize = function (activatedRoute, router) {
        var _this = this;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.routeSubscription = this.activatedRoute.params.subscribe(function (params) {
            var onDate = moment(params.date, appConfig.linkDateFormat).toDate();
            // Setting the flag to false, as it raised Bug-147183, Bug-147681
            // With the above changes: 145753 will be unfixed.
            // 145753 - Passing this condition to Position API to fetch only Active positions. 
            var showOnlyActivePositions = false;
            _this.employeeId = params.employeeId;
            _this.dateOn = onDate;
            _this.orgLevelId = params.orgLevelId;
            _this.management.changeScheduleEntryDate(params.employeeId, onDate, showOnlyActivePositions);
        });
    };
    ScheduleEntryComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        console.debug('destroy');
    };
    ScheduleEntryComponent.prototype.save = function (form) {
        var _this = this;
        var EmpId = String(this.employeeId);
        var StartDate = moment(this.dateOn).format("YYYY-MM-DD HH:mm:ss");
        var date = moment(this.dateOn).add(23, 'hours').add(59, 'minutes').add(59, 'seconds');
        var EndDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
        this.scheduleEntryApiService.checkApprovedPayperiod(EmpId, StartDate, EndDate).then(function (data) {
            _this.approvedPayPeriod = data;
            if (_this.isModifyPayPeriodApproved && _this.approvedPayPeriod) {
                var message = _this.actions.canEditForApprovedPayPeriod ?
                    "This will modify a schedule in an approved pay period and impact the PBJ Calculation for the \n        employee, are you sure you want to continue?" : "You do not have permissions to modify a \n        schedule in an approved pay period";
                var popupOptions = new ConfirmOptions();
                popupOptions.showCancel = true;
                popupOptions.showOK = _this.actions.canEditForApprovedPayPeriod ? true : false;
                popupOptions.buttonOKtext = 'Ok';
                popupOptions.buttonCanceltext = _this.actions.canEditForApprovedPayPeriod ? 'Cancel' : 'Ok';
                ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                    if (result) {
                        if (!_this.state.isOverlaping && form.valid) {
                            _this.management.save(_this.scheduleEntryContainer);
                        }
                    }
                }, popupOptions);
            }
            else {
                if (!_this.state.isOverlaping && form.valid) {
                    _this.management.save(_this.scheduleEntryContainer);
                }
            }
        });
    };
    ScheduleEntryComponent.prototype.discard = function () {
        this.management.discard();
    };
    ScheduleEntryComponent.prototype.cancel = function () {
        this.router.navigate(['apps', 'scheduler', 'master_schedule']);
    };
    ScheduleEntryComponent.prototype.changeDateOn = function (dateOn) {
        this.scheduleEntryNavigationService.NavigateToScheduleEntry(this.scheduleEntryContainer.employeeId, dateOn);
    };
    ScheduleEntryComponent.prototype.addNewShift = function () {
        this.management.addNewShift();
    };
    ScheduleEntryComponent.prototype.requestTALog = function () {
        //this.shiftRequestService.setGridData(OpenShiftData);
        EmployeeTALogComponent.openDialog(this.modalService, this.employeeId, this.dateOn, this.orgLevelId);
        //ShiftRequestPopupComponent.openDialog(OpenShiftData, this.modalService, orgLevel, this.dateOn, this.agencyListData);
    };
    ScheduleEntryComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleEntryComponent.prototype, "initSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleEntryComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleEntryComponent.prototype, "overlapStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleEntryComponent.prototype, "duplicateStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleEntryComponent.prototype, "replacedStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleEntryComponent.prototype, "routeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleEntryComponent.prototype, "actionsSubscription", void 0);
    return ScheduleEntryComponent;
}());
export { ScheduleEntryComponent };
