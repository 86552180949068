import { RolesComponentAccess } from './role-access-definition';

export interface IRoleComponent {
  id: number;
  name: string;
  isEnabled: boolean;
}

export class RoleComponent {
  public id: number;
  public name: string;
  public isEnabled: boolean;
  public access: RolesComponentAccess;

  public get isDirty(): boolean {
    return this.isEnabled !== this.initial_isEnabled;
  }
  private initial_isEnabled: boolean;

  public setInitialState(): void {
    this.initial_isEnabled = this.isEnabled;
  }
}
