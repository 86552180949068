<div class="buttons-bar">
  <button type="button"  class="slx-button slx-with-icon" [disabled]="(editable && addcomment) || (!editable && addcomment)" (click)="clearComment()">
      <i class="fal fa-eraser slx-button__icon" aria-hidden="true"></i>
      <span class="slx-button__text">Clear</span>
  </button>
  
  <button style="margin-right: 5px;" type="button" *ngIf="editable && isPredefinedCommentsExist" class="slx-button slx-with-icon" (click)="addComment()">
    <i class="fal fa-plus-circle slx-button__icon" aria-hidden="true"></i>
    <span class="slx-button__text">Add</span>
    </button>

  <slx-input-decorator class="comment-container">
    <slx-autocomplete-input slx-input [readonly]="addcomment" placeholder="Select Comment" [lookup]="{lookupType: 'timecardPredefinedComment'}"
      name="comments" [(ngModel)]="selectedComment" (ngModelChange)="commentSelected($event)" [createIfNotFound]="true">
    </slx-autocomplete-input>
  </slx-input-decorator>
</div>

<div>
     <textarea class="comment-textarea" 
    [readonly]="(editable && addcomment) || (!editable && addcomment)"  [placeholder]="(!editable && addcomment) || (editable && addcomment) ? '' : 'Please enter text here'"  
    [(ngModel)]="commentValue" (ngModelChange)="onCommentChanged()"
    maxlength="255" spellcheck="false"
    ></textarea>
</div>

