export interface ILoaType {
  id: number;
  name: string;
  description: string;
  color?: number;
}

export class LoaType {
  id: number;
  name: string;
  description: string;
  color?: string;
}
