<div class="toggler" (click)="onAccessClick()">
  <i [ngClass]="{
    'far': rowItem.mapByRole[roleId].access==='enabled' || rowItem.mapByRole[roleId].access==='disabled',
    'fa-check-square': rowItem.mapByRole[roleId].access==='enabled',
    'fa-square': rowItem.mapByRole[roleId].access==='disabled',
    'fab fa-delicious': rowItem.mapByRole[roleId].access==='mixed',
    'dirty': rowItem.dirtyByRole[roleId]
    }"
    aria-hidden="true"></i>
</div>
