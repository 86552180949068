/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../components-library/components/dropdown-list/dropdown-list.component.ngfactory";
import * as i2 from "../../../components-library/components/dropdown-list/dropdown-list.component";
import * as i3 from "@angular/forms";
import * as i4 from "./custom-paycycle-dropdown.component";
var styles_CustomPayCycleDropdownComponent = [];
var RenderType_CustomPayCycleDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomPayCycleDropdownComponent, data: {} });
export { RenderType_CustomPayCycleDropdownComponent as RenderType_CustomPayCycleDropdownComponent };
export function View_CustomPayCycleDropdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "slx-paycycle-dropdown"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "label", [["class", "slx-paycycle-dropdown__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Payroll Cycle"])), (_l()(), i0.ɵeld(3, 0, null, null, 5, "slx-dropdown-list", [["class", "slx-wide slx-short slx-paycycle-dropdown__year"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.currentYear = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DropdownListComponent_0, i1.RenderType_DropdownListComponent)), i0.ɵdid(4, 49152, null, 0, i2.DropdownListComponent, [], { options: [0, "options"], valueField: [1, "valueField"], titleField: [2, "titleField"] }, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DropdownListComponent]), i0.ɵdid(6, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(8, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 5, "slx-dropdown-list", [["class", "slx-wide slx-short slx-paycycle-dropdown__range"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.currentRange = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DropdownListComponent_0, i1.RenderType_DropdownListComponent)), i0.ɵdid(10, 49152, null, 0, i2.DropdownListComponent, [], { options: [0, "options"], valueField: [1, "valueField"], titleField: [2, "titleField"] }, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DropdownListComponent]), i0.ɵdid(12, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(14, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.yearList; var currVal_8 = "year"; var currVal_9 = "year"; _ck(_v, 4, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.currentYear; _ck(_v, 6, 0, currVal_10); var currVal_18 = _co.rangeList; var currVal_19 = "range"; var currVal_20 = "range"; _ck(_v, 10, 0, currVal_18, currVal_19, currVal_20); var currVal_21 = _co.currentRange; _ck(_v, 12, 0, currVal_21); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 8).ngClassValid; var currVal_5 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_11 = i0.ɵnov(_v, 14).ngClassUntouched; var currVal_12 = i0.ɵnov(_v, 14).ngClassTouched; var currVal_13 = i0.ɵnov(_v, 14).ngClassPristine; var currVal_14 = i0.ɵnov(_v, 14).ngClassDirty; var currVal_15 = i0.ɵnov(_v, 14).ngClassValid; var currVal_16 = i0.ɵnov(_v, 14).ngClassInvalid; var currVal_17 = i0.ɵnov(_v, 14).ngClassPending; _ck(_v, 9, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); }); }
export function View_CustomPayCycleDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-custom-paycycle-dropdown", [], null, null, null, View_CustomPayCycleDropdownComponent_0, RenderType_CustomPayCycleDropdownComponent)), i0.ɵdid(1, 245760, null, 0, i4.CustomPayCycleDropdownComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomPayCycleDropdownComponentNgFactory = i0.ɵccf("slx-custom-paycycle-dropdown", i4.CustomPayCycleDropdownComponent, View_CustomPayCycleDropdownComponent_Host_0, { setPayCycle: "selectedPayCycle", setDefault: "setDefaultIfNotSelected", payCycles: "payCycles" }, { payCycleSelected: "payCycleSelected", payCyclesLoaded: "payCyclesLoaded" }, []);
export { CustomPayCycleDropdownComponentNgFactory as CustomPayCycleDropdownComponentNgFactory };
