import * as _ from 'lodash';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';

export class EmployeeSectionNavigationService {

  private router: Router;
  private route: ActivatedRoute;

  constructor (router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public NavigateToEmployeeSections(employeeId: number, hideOrgLevelBreadcrumb: boolean): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(this.getUrlTreeFromRoot(employeeId, hideOrgLevelBreadcrumb), extras);
  }

  public getUrlTree(employeeId: number, hideOrgLevelBreadcrumb: boolean): UrlTree {
    return this.router.createUrlTree(
      ['employee', employeeId, 'employee_sections'],
      {
        relativeTo: this.route.pathFromRoot[2],
        queryParams: { keepOrgLevelBreadcrmb: _.isBoolean(hideOrgLevelBreadcrumb) ? hideOrgLevelBreadcrumb : true }
      });
  }

  public getUrlTreeFromRoot(employeeId: number, hideOrgLevelBreadcrumb: boolean): UrlTree {
    return this.router.createUrlTree(
      ['apps', 'common', 'employee', employeeId, 'employee_sections'],
      {
        relativeTo: this.route.pathFromRoot[0],
        queryParams: { keepOrgLevelBreadcrmb: _.isBoolean(hideOrgLevelBreadcrumb) ? hideOrgLevelBreadcrumb : true }
      });
  }

}
