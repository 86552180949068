<slx-dropdown-list
  class="slx-wide slx-short"
  valueField="id"
  [valueIconClass]="valueIconClass"
  titleField="name"
  [options]="options"
  [(ngModel)]="selectedRange"
  [placeholder]="placeholder"
  [readonly]="readonly"
  name="selectedRange"
></slx-dropdown-list>
