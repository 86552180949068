import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../../app.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { NotificationsService } from '../../../../../core/components/index';
import { KendoGridStateHelper, IBenefitsEmployeeDependentsEnrollment, BenefitsEmployeeDependentsEnrollmentValidity } from '../../../../../common/index';
import { EmployeeDependentBenefitsOptions } from '../../../models/index';
import { BenefitEmployeeDependentsEnrollments } from '../../../../../app-modules/benefits/models/index';
var EmployeeDependentBenefitsComponent = /** @class */ (function () {
    function EmployeeDependentBenefitsComponent(manService, notificationService, changeDetector) {
        this.manService = manService;
        this.notificationService = notificationService;
        this.changeDetector = changeDetector;
        this.selectBenefits = new EventEmitter();
        this.enrollmentDone = new EventEmitter();
        this.eligibleBenefits = [];
        this.selectedDependents = [];
        this.startDate = null;
        this.endDate = null;
        this.minStartDate = null;
        this.maxEndDate = null;
        this.pageSize = 50;
        this.benefitsError = false;
        this.dependentsError = false;
        this.isDisabledDates = true;
        this.isDisabledSelection = false;
        this.isLoadingBenefits = true;
        this.latestDepDOB = null;
        this.isSDateChanged = false;
        this.isEDateChanged = false;
        this.isCheckSelected = false;
        this.subscriptions = {};
        this.selectedBenefits = new Map();
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'benefitName', dir: 'asc' }, { field: 'empBenefitStartDate', dir: 'asc' }];
    }
    Object.defineProperty(EmployeeDependentBenefitsComponent.prototype, "options", {
        set: function (v) {
            if (_.isObjectLike(v)) {
                this.selectedDependents = v.dependents;
                this.loadEligibleBenefits();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeDependentBenefitsComponent.prototype, "dependentsEnrollments", {
        set: function (v) {
            if (_.isObjectLike(v)) {
                this.enrollDependents(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeDependentBenefitsComponent.prototype, "isShownSpinner", {
        get: function () {
            return this.isEnrolling || this.isLoadingBenefits;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeDependentBenefitsComponent.prototype, "isMultipleDependents", {
        get: function () {
            return _.size(this.selectedDependents) > 1;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeDependentBenefitsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function (v) {
            _this.updateBenefits();
        });
    };
    EmployeeDependentBenefitsComponent.prototype.ngOnDestroy = function () { };
    EmployeeDependentBenefitsComponent.prototype.isCheckedEmp = function (ben) {
        return this.selectedBenefits.has(ben.empEnrollmentId);
    };
    EmployeeDependentBenefitsComponent.prototype.onSelectAll = function () {
        var _this = this;
        if (this.isCheckedAll) {
            this.selectedBenefits.clear();
            this.isCheckedAll = false;
        }
        else {
            _.forEach(this.filteredRecords, function (e) {
                _this.selectedBenefits.set(e.empEnrollmentId, e);
            });
            this.isCheckedAll = true;
        }
        this.validateEnrollments();
        this.selectedControl(false, false, true);
    };
    EmployeeDependentBenefitsComponent.prototype.onSelectSingle = function (ben) {
        if (this.selectedBenefits.has(ben.empEnrollmentId)) {
            this.selectedBenefits.delete(ben.empEnrollmentId);
            this.isCheckedAll = false;
        }
        else {
            this.selectedBenefits.set(ben.empEnrollmentId, ben);
            if (_.size(this.filteredRecords) === this.selectedBenefits.size) {
                this.isCheckedAll = true;
            }
        }
        this.validateEnrollments();
        this.selectedControl(false, false, true);
    };
    EmployeeDependentBenefitsComponent.prototype.loadEligibleBenefits = function () {
        var _this = this;
        this.isLoadingBenefits = true;
        this.manService.getDependentsEligibleBenefits(_.map(this.selectedDependents, function (dep) { return dep.id; }))
            .then(function (eligibleBenefits) {
            _this.eligibleBenefits = eligibleBenefits;
            _this.isDisabledSelection = _.size(eligibleBenefits) === 0;
            _this.isLoadingBenefits = false;
            _this.updateBenefits();
        });
    };
    EmployeeDependentBenefitsComponent.prototype.onChangeStartDate = function () {
        this.selectedControl(true, false, false);
        this.validateDates();
    };
    EmployeeDependentBenefitsComponent.prototype.onChangeEndDate = function () {
        this.selectedControl(false, true, false);
        this.validateDates();
    };
    EmployeeDependentBenefitsComponent.prototype.selectedControl = function (isDate, isEdate, isCheck) {
        this.isSDateChanged = isDate;
        this.isEDateChanged = isEdate;
        this.isCheckSelected = isCheck;
    };
    EmployeeDependentBenefitsComponent.prototype.updateBenefits = function () {
        this.refreshGrid();
        this.changeDetector.markForCheck();
    };
    EmployeeDependentBenefitsComponent.prototype.validateEnrollments = function () {
        var selBenefits = Array.from(this.selectedBenefits.values());
        var benefits = _.map(selBenefits, function (b) { return [b.empBenefitStartDate, b.empBenefitEndDate]; });
        var dependents = _.map(this.selectedDependents, function (d) { return d.birthDate; });
        var validity = this.manService.validateSelection(benefits, dependents);
        this.startDate = validity.startDate;
        this.endDate = validity.endDate;
        this.minStartDate = validity.minDate;
        this.maxEndDate = validity.maxDate;
        this.latestDepDOB = validity.latestDepDOB;
        this.benefitsError = validity.isErrorInvalidBenefits();
        this.dependentsError = validity.isErrorInvalidDependents();
        this.isDisabledDates = this.benefitsError || this.dependentsError || validity.isErrorNoBenefits() || validity.isErrorNoDependents();
        this.emitDependentsEnrollments(validity.hasError, this.selectedDependents, selBenefits);
        this.detectChanges();
    };
    EmployeeDependentBenefitsComponent.prototype.validateDates = function () {
        var selBenefits = Array.from(this.selectedBenefits.values());
        var validity = this.manService.validateDates(this.startDate, this.endDate);
        this.latestDepDOB = validity.latestDepDOB;
        this.benefitsError = validity.isErrorInvalidBenefits();
        this.dependentsError = validity.isErrorInvalidDependents();
        this.isDisabledSelection = this.benefitsError || this.dependentsError || validity.isErrorInvalidStartOrEndDate();
        this.emitDependentsEnrollments(validity.hasError, this.selectedDependents, selBenefits);
        this.detectChanges();
    };
    EmployeeDependentBenefitsComponent.prototype.emitDependentsEnrollments = function (hasError, dependents, benefits) {
        var depsbenefits = null;
        if (!hasError) {
            depsbenefits = new BenefitEmployeeDependentsEnrollments(this.startDate, this.endDate, dependents, benefits);
        }
        this.selectBenefits.emit(depsbenefits);
    };
    EmployeeDependentBenefitsComponent.prototype.enrollDependents = function (v) {
        var _this = this;
        this.isEnrolling = true;
        this.manService.enrollDependents(v)
            .then(function () {
            _this.notificationService.success('Dependents enrolled', 'Selected dependents were successfully added to the benefits');
            _this.enrollmentDone.emit(true);
        })
            .catch(function () {
            _this.enrollmentDone.emit(false);
            _this.isEnrolling = false;
        });
    };
    EmployeeDependentBenefitsComponent.prototype.detectChanges = function () {
        var _this = this;
        Observable.of(true)
            .delay(25)
            .subscribe(function () { return _this.changeDetector.detectChanges(); });
    };
    EmployeeDependentBenefitsComponent.prototype.refreshGrid = function () {
        if (!this.eligibleBenefits) {
            return;
        }
        this.filteredRecords = process(this.eligibleBenefits, { filter: this.gridState.state.filter, sort: this.gridState.state.sort }).data;
        this.gridState.view = process(this.eligibleBenefits, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeDependentBenefitsComponent.prototype, "subscriptions", void 0);
    return EmployeeDependentBenefitsComponent;
}());
export { EmployeeDependentBenefitsComponent };
