<slx-spinner [show]="isLoaded">
  <form class="modal-content" #form="ngForm">
    <div>
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-6">
            <slx-input-decorator className="slx-form-group slx-no-border small-font">
              <slx-radio-input slx-input name="shiftsAreRepeatedEveryNWeeks" fieldName="shiftsAreRepeatedEveryNWeeks" option="shiftsAreRepeatedEveryNWeeks"
                caption="Schedule repeats every" [(ngModel)]="scheduleType" (ngModelChange)="onSettingsSelected(shiftsAreRepeatedEveryNWeeks)">
                </slx-radio-input>
            </slx-input-decorator>
          </div>
          <div class="col-sm-4">
            <slx-input-decorator className="slx-form-group small-font slx-no-label" class="weeks-count">
              <slx-kendo-number slx-input
                [(ngModel)]="shiftsAreRepeatedEveryNWeeks.rotationsCount"
                [min]="1"
                [max]="100"
                [autoCorrect]="true"
                [readonly]="selectedRotationTemplate!==shiftsAreRepeatedEveryNWeeks"
                placeholder="Weeks"
                name="points"
              ></slx-kendo-number>
            </slx-input-decorator>
          </div>
          <div class="col-sm-2 theme-exsm-text additional-text">
            weeks
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-8">
            <slx-input-decorator className="slx-form-group slx-no-border small-font">
              <slx-radio-input slx-input name="shifts4On2Off" fieldName="shifts4On2Off" option="shifts4On2Off" caption="4 days on - 2 days off rotation starts on:"
                [(ngModel)]="scheduleType" (ngModelChange)="onSettingsSelected(shifts4On2Off)">
                </slx-radio-input>
            </slx-input-decorator>
          </div>
          <div class="col-sm-4" *ngIf="selectedRotationTemplate===shifts4On2Off">
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                [(ngModel)]="shifts4On2Off.rotationsStartDate"
                [acceptNullDate]="false"
                placeholder="Starts on"
                name="rotation1StartDate"
              ></slx-date-picker-ngx>
              <span errorMessage for="date"></span>
            </slx-input-decorator>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-8">
            <slx-input-decorator className="slx-form-group slx-no-border small-font">
              <slx-radio-input slx-input name="shifts5On2Off5On3Off" fieldName="shifts5On2Off5On3Off" option="shifts5On2Off5On3Off" caption=" 5 days on - 2 days off
        / 5 days on - 3 days off rotation starts on:" [(ngModel)]="scheduleType" (ngModelChange)="onSettingsSelected(shifts5On2Off5On3Off)">
                </slx-radio-input>
            </slx-input-decorator>
          </div>
          <div class="col-sm-4" *ngIf="selectedRotationTemplate===shifts5On2Off5On3Off">
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                [(ngModel)]="shifts5On2Off5On3Off.rotationsStartDate"
                [acceptNullDate]="false"
                placeholder="Starts on"
                name="rotation2StartDate"
              ></slx-date-picker-ngx>
              <span errorMessage for="date"></span>
            </slx-input-decorator>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <slx-employee-shift-editor [homePositionId]="homePositionId" [homeShiftId]="homeShiftId"
      [homeUnitId]="homeUnitId" [canChangePosition]="canChangePosition" [employeeId]="employeeId"
      [employeeShift]="selectedEmployeeShift" [useConstraint]="useConstraint" (onRequiredFieldsPopulated)="onRequiredFieldsPopulated()">
      </slx-employee-shift-editor>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancelSettings()" class="theme-button-cancel margin-r">Cancel</button>
      <button [popover]="acceptPopover" type="button" [disabled]="!state.isValid || !form.valid" class="theme-button-apply">OK</button>
      <popover-content #acceptPopover title="Are you sure?" placement="auto top">
        <div style="width:110px">
          <button type="button" style="width:50px" (click)="acceptPopover.hide()" class="theme-button-cancel margin-r">No</button>
          <button type="button" style="width:50px" class="theme-button-apply" (click)="onAcceptSettings(acceptPopover)">Yes</button>.
        </div>
      </popover-content>
    </div>
  </form>
</slx-spinner>
<div modalAnchor></div>
