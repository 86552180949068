import * as tslib_1 from "tslib";
import { OnDestroy, ElementRef, ChangeDetectorRef, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { EssTemplate } from '../../models/index';
import { EssTimeEntryMethod, EssDefaultPassword } from '../../../../organization/models/index';
import { appConfig } from '../../../../app.config';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { EssTemplateManagementService } from '../../services/index';
import { ChangeManagementService } from '../../../../common/services/index';
var EssTemplateSectionComponent = /** @class */ (function () {
    function EssTemplateSectionComponent(managementService, changeDetector, changeManagementService) {
        var _this = this;
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.changeManagementService = changeManagementService;
        this.defaultItem = { groupName: "Select Attestation Group", id: null };
        this.prohibitedNameValues = [];
        this.timeEntryMethods = EssTimeEntryMethod.getList(true);
        this.defaultPasswords = EssDefaultPassword.getList(false);
        this.selectSubscription = this.managementService.selectTemplate$.subscribe(function (template) {
            if (!_this.template || _this.template.essTemplateDefinition.id !== template.essTemplateDefinition.id) {
                return;
            }
            _this.templateName.nativeElement.focus();
        });
        this.managementService.onLoaded$.subscribe(function (templates) {
            _this.calcProhibited();
        });
    }
    Object.defineProperty(EssTemplateSectionComponent.prototype, "hasChanges", {
        get: function () {
            var dirty = JSON.stringify(this.dirtyTemplate);
            var res = dirty !== this.serializedOriginTemplate;
            return res;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EssTemplateSectionComponent.prototype, "isMobile", {
        get: function () {
            return (screen.width <= appConfig.mobileMaxWidth);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EssTemplateSectionComponent.prototype, "changeGroupName", {
        get: function () {
            return this.template.essTemplateDefinition.id.toString();
        },
        enumerable: true,
        configurable: true
    });
    EssTemplateSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.managementService.addTemplateBtn$.subscribe(function (isCollapsed) {
            _this.isCollapsed = isCollapsed;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    EssTemplateSectionComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EssTemplateSectionComponent.prototype.ngOnChanges = function (changes) {
        if (changes['template']) {
            this.dirtyTemplate = _.cloneDeep(this.template);
            this.serializedOriginTemplate = JSON.stringify(this.template);
            this.resetEnums();
            this.notifyChanges();
            this.calcProhibited();
            this.setPunchAttestationGroupValue();
        }
    };
    EssTemplateSectionComponent.prototype.setPunchAttestationGroupValue = function () {
        var _this = this;
        if (!!this.punchAttestationGroups && this.punchAttestationGroups.length > 0) {
            this.selectedPunchAttestationGroup = this.punchAttestationGroups.find(function (group) { return group.id == _this.template.essTemplateDefinition.attestationGroupId; });
            this.dirtyTemplate.essTemplateDefinition.attestationGroupId = !!this.selectedPunchAttestationGroup ? this.selectedPunchAttestationGroup.id : null;
        }
    };
    EssTemplateSectionComponent.prototype.setTimeEntryMethod = function () {
        this.dirtyTemplate.timeEntryMethod = this.timeEntryMethod.id;
        this.notifyChanges();
    };
    EssTemplateSectionComponent.prototype.setDefaultPwdTemplate = function () {
        this.dirtyTemplate.defaultPwdTemplate = this.defaultPwdTemplate.id;
        this.notifyChanges();
    };
    EssTemplateSectionComponent.prototype.setPunchAttestationGroup = function () {
        this.dirtyTemplate.essTemplateDefinition.attestationGroupId = this.selectedPunchAttestationGroup.id;
        this.notifyChanges();
    };
    EssTemplateSectionComponent.prototype.notifyChanges = function () {
        if (this.hasChanges) {
            this.changeManagementService.changeNotify(this.changeGroupName);
        }
        else {
            this.changeManagementService.clearChanges(this.changeGroupName);
        }
    };
    EssTemplateSectionComponent.prototype.collapsedChange = function (isCollapsed) {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EssTemplateSectionComponent.prototype.discard = function () {
        var _this = this;
        this.dirtyTemplate = _.cloneDeep(this.template);
        this.resetEnums();
        this.notifyChanges();
        setTimeout(function () {
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        }, 100);
    };
    EssTemplateSectionComponent.prototype.save = function () {
        this.managementService.saveTemplate(this.dirtyTemplate);
    };
    EssTemplateSectionComponent.prototype.delete = function () {
        this.managementService.deleteTemplate(this.template);
    };
    EssTemplateSectionComponent.prototype.resetEnums = function () {
        var _this = this;
        this.timeEntryMethod = _.find(this.timeEntryMethods, function (opt) { return opt.id === _this.dirtyTemplate.timeEntryMethod; });
        if (!this.timeEntryMethod) {
            this.timeEntryMethod = _.first(this.timeEntryMethods);
        }
        this.defaultPwdTemplate = _.find(this.defaultPasswords, function (opt) { return opt.id === _this.dirtyTemplate.defaultPwdTemplate; });
        if (!this.defaultPwdTemplate) {
            this.defaultPwdTemplate = _.first(this.defaultPasswords);
        }
        this.setPunchAttestationGroupValue();
    };
    EssTemplateSectionComponent.prototype.calcProhibited = function () {
        var _this = this;
        if (!this.template) {
            return;
        }
        var otherTemplates = _.filter(this.managementService.templates, function (t) { return t.essTemplateDefinition.id !== _this.template.essTemplateDefinition.id; });
        this.prohibitedNameValues = _.map(otherTemplates, function (t) { return t.essTemplateDefinition.name; });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EssTemplateSectionComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EssTemplateSectionComponent.prototype, "selectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EssTemplateSectionComponent.prototype, "stateSubscription", void 0);
    return EssTemplateSectionComponent;
}());
export { EssTemplateSectionComponent };
