import { EmployeePunchRequest, IEmployeePunchRequest } from '../../models';
import { dateTimeUtils } from './../../../common/utils/dateTimeUtils';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
@Injectable()
export class EmployeePunchRequestMapService {

  public mapEmployeePunchRequests(dtos: IEmployeePunchRequest[]): EmployeePunchRequest[] {
      return dtos.map(x => this.mapEmployeePunchRequest(x));
  }

  public mapEmployeePunchRequest(dto: IEmployeePunchRequest): EmployeePunchRequest {
      const type: EmployeePunchRequest = new EmployeePunchRequest();
      type.id = dto.id;
      type.employeeId = dto.employeeId;
      type.punchTime = dateTimeUtils.convertFromDtoDateTimeString(dto.punchTime);
      type.punchDate = dateTimeUtils.convertFromDtoDateTimeString(dto.punchDate);
      if (_.isDate(type.punchDate)) {
        let d = dateTimeUtils.convertFromDtoDateTimeString(dto.punchDate);
        d = moment(dto.punchDate).startOf('day').toDate();
      }
      type.requestOn = dateTimeUtils.convertFromDtoDateTimeString(dto.requestOn);
      type.isApproved = dto.isApproved;
      type.punchTypes = dto.punchTypes;
      type.deleteInd = dto.deleteInd;
      type.employeeName = dto.employeeName;
      type.positionName = dto.positionName;
      type.departmentName = dto.departmentName;
      return type;
  }
  
}

