import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Provider, EventEmitter } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { EmployeeListMapService } from './../../../../employee/employee-list/services/employee-list/employee-list-map.service';
import { GenericListApiService } from '../../../../organization/services/generic-list/generic-list-api.service';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN } from './../../../../core/models/tokens';
import { EmployeeActionDefinition } from './../../../../employee/employee-list/models/employeeActionDefinition';
import { OrgLevel } from '../../../../state-model/models/index';
import { NotificationsApiService } from '../../../../portal/services/notifications/notifications-api.service';
import { ManagementBaseService } from '../../../../core/services/index';
import { EmployeeCallLogApiService } from './employee-call-log-api.service';
var EmployeeCallLogManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeCallLogManagementService, _super);
    function EmployeeCallLogManagementService(employeeCallLogApiService, genericService, employeeMapService, notificationsApiService) {
        var _this = _super.call(this) || this;
        _this.genericService = genericService;
        _this.employeeMapService = employeeMapService;
        _this.notificationsApiService = notificationsApiService;
        _this.employeeIds = [];
        _this.employeeCallLogApiService = employeeCallLogApiService;
        _this.employeeIds = [];
        _this.smsDataLoaded$ = new EventEmitter();
        _this.resetGridState$ = new Subject();
        _this.messageCenterEnabled$ = new ReplaySubject(1);
        _this.initialize();
        return _this;
    }
    EmployeeCallLogManagementService.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.onLoadStatusChanged(true);
                        this.isMessageCenterEnabled = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4 /*yield*/, this.notificationsApiService.getIconAccessCheck(this.orgLevelId)];
                    case 2:
                        _a.isMessageCenterEnabled = (_b.sent()).isMessageCenterEnabled;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.messageCenterEnabled$.next(this.isMessageCenterEnabled);
                        this.onLoadStatusChanged(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeCallLogManagementService.prototype.resetGridState = function () {
        this.resetGridState$.next(null);
    };
    EmployeeCallLogManagementService.prototype.orgLevelChanged = function (orgLevel) {
        this.orgLevel = orgLevel;
        if (orgLevel)
            this.orgLevelId = orgLevel.id;
        this.checkRequiredData();
    };
    EmployeeCallLogManagementService.prototype.employeesChanged = function (employeeIds) {
        this.employeeIds = employeeIds ? employeeIds : [];
        this.checkRequiredData();
    };
    EmployeeCallLogManagementService.prototype.dateRangeChanged = function (startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.checkRequiredData();
    };
    EmployeeCallLogManagementService.prototype.loadSmsData = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.genericService.getListDefinitions(this.applicationId, this.orgLevelId, 'SendSMStoaGroupofEmployees')
            .then(function (definition) {
            var empDef = _this.employeeMapService.mapFromGenericListDefinition(definition);
            _this.smsDataLoaded$.emit(empDef);
            _this.onLoadStatusChanged(false);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    EmployeeCallLogManagementService.prototype.getSmsWindowProviders = function (actionDefinition) {
        var resolvedProvidersConf = [
            { provide: EMPLOYEES_ID_TOKEN, useValue: [] },
            { provide: EmployeeActionDefinition, useValue: actionDefinition },
            { provide: OrgLevel, useValue: this.orgLevel },
            { provide: ISACTIVEONLY_ID_TOKEN, useValue: true }
        ];
        return resolvedProvidersConf;
    };
    EmployeeCallLogManagementService.prototype.checkRequiredData = function () {
        if (!this.freeze && this.orgLevelId && this.startDate && this.endDate && _.isArray(this.employeeIds)) {
            this.loadCallLog();
        }
    };
    EmployeeCallLogManagementService.prototype.loadCallLog = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        var promise = this.isMessageCenterEnabled ?
            this.employeeCallLogApiService.getMessageCenterCallLog(this.orgLevelId, this.employeeIds, this.startDate, this.endDate) :
            this.employeeCallLogApiService.getCallLog(this.orgLevelId, this.employeeIds, this.startDate, this.endDate);
        promise.then(function (employeeCallLog) {
            _this.onLoadStatusChanged(false);
            _this.onLoaded(employeeCallLog);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    return EmployeeCallLogManagementService;
}(ManagementBaseService));
export { EmployeeCallLogManagementService };
