var ProductModule = /** @class */ (function () {
    function ProductModule() {
    }
    Object.defineProperty(ProductModule.prototype, "isEnabled", {
        get: function () {
            return !this.isDisabled;
        },
        set: function (value) {
            this.isDisabled = !value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ProductModule.prototype, "isDirty", {
        get: function () {
            return this.isDisabled !== this.isDisabledInitial;
        },
        enumerable: true,
        configurable: true
    });
    return ProductModule;
}());
export { ProductModule };
export var ProductModuleType;
(function (ProductModuleType) {
    ProductModuleType["Application"] = "Application";
    ProductModuleType["Menu"] = "Menu";
})(ProductModuleType || (ProductModuleType = {}));
