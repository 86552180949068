<div class="k-card-list">
    <div class="k-card agency-shift-details">
        <div *ngIf="isShiftSelected" class="k-card-body">
            <div class="row">
                <div class="col-xs-3 border-right">
                    <label class="slx-label -heading">Instant Accept </label><br>
                    <slx-switcher class="indent-right" [(ngModel)]="instantAccept"
                        (ngModelChange)="instanctAcceptChange()" label="" name="instantAceept" labelOn="Yes"
                        labelOff="No">
                    </slx-switcher>
                </div>
                <div class="col-xs-3 border-right">
                    <label slx-label class="attribute-heading">Premium Pay</label><br />

                    <slx-radio-button name="Standard" fieldName="Standard" option="Standard" caption="Standard"
                        [(ngModel)]="premuimPay" (ngModelChange)="onDisplaySelectChanged('premiumPay','Standard')">
                    </slx-radio-button>
                    <slx-radio-button name="Premium" fieldName="Premium" option="Premium" caption="Premium"
                        [(ngModel)]="premuimPay" (ngModelChange)="onDisplaySelectChanged('premiumPay', 'Premium')">
                    </slx-radio-button>
                </div>
                <div class="col-xs-3 border-right">
                    <label slx-label class="attribute-heading">Incentive Pay</label><br />

                    <slx-radio-button name="No" fieldName="No" option="No" caption="No" [(ngModel)]="incentivePay"
                        (ngModelChange)="onDisplaySelectChanged('incentivePay', 'No')">
                    </slx-radio-button><br />
                    <slx-radio-button name="Yes" fieldName="Yes" option="Yes" caption="Yes" [(ngModel)]="incentivePay"
                        (ngModelChange)="onDisplaySelectChanged('incentivePay','Yes')">
                    </slx-radio-button>
                </div>
                <div class="col-xs-3 ">
                    <label slx-label class="attribute-heading">Infectious</label> <br />
                    <slx-radio-button name="None" fieldName="None" option="None" caption="None" [(ngModel)]="infectious"
                        (ngModelChange)="onDisplaySelectChanged('infectious', 'None')">
                    </slx-radio-button><br />
                    <slx-radio-button name="covid" fieldName="covid" option="COVID" caption="COVID"
                        [(ngModel)]="infectious" (ngModelChange)="onDisplaySelectChanged('infectious','COVID')">
                    </slx-radio-button>
                </div>

            </div>
            <div class="k-card row agency-details">
                <div class="k-card-body col-12 agency-list-header">
                    <label class="k-card-title selected-agencies">Selected Agencies :</label>
                    <label class=" selected-agencyCount">&nbsp;&nbsp;&nbsp;{{selectedAgencyCount}}</label>
                    <label class=" k-card-subtitle selected"> &nbsp;&nbsp;&nbsp;&nbsp;selected</label>
                </div>
                <div class="row" style="margin: 0px 0px 0px 0px;">


                    <div class="col-sm-10" style="margin-left: -29px;">

                        <div class="row slider-wrap">
                            <div class="col-sm-12 " style="margin: 0px 0px 0px 0px;
                                padding: 0px 0px 0px 0px;">


                                <div class="row">
                                    <div class="col-sm-3 positionMapping"
                                        *ngFor="let agency of scrollViewitems let i = index">
                                        <span *ngIf="!verifyInstantAcceptValue(agency.partnerId)" class="positionError">
                                           {{getInstanctAcceptError()}}
                                          
                                        </span>
                                        <span *ngIf="!isValidPosition[agency.partnerId]" class="positionError">
                                            Position is not mapped
                                        </span>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class=" col-sm-1 button-area">
                                        <div *ngIf="disablePreview()">
                                            <button type="button" class="prev" (click)="prevAgencyList()">
                                                <i class="fal fa-arrow-circle-left slx-arrow-scroll"></i></button>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 agency-list item" 
                                        *ngFor="let agency of scrollViewitems let i = index"
                                        [ngClass]="expandedAgencyDetails[agency.partnerId] && verifyInstantAcceptValue(agency.partnerId)? 'add-highlight':''">

                                        <div class="row agency-row" *ngIf ="verifyInstantAcceptValue(agency.partnerId)" (click)="selectAgency(agency);expandedAgencyDetails[agency.partnerId]= !expandedAgencyDetails[agency.partnerId] ; unselectedAgency(expandedAgencyDetails[agency.partnerId], agency.partnerId)">
                                            <div class="col-sm-12 agency-display-name" >
                                                <span class=""> {{getDisplayName(agency)}} </span>
                                               
                                            </div>

                                        </div>
                                        <div class="row agency-row"  *ngIf ="!verifyInstantAcceptValue(agency.partnerId)"                                        >
                                            <div class="col-sm-12 agency-display-name" >
                                                <span class=""> {{getDisplayName(agency)}} </span>
                                             
                                            </div>

                                        </div>
                                    </div>
                                    <div class=" col-sm-1 button-area" >
                                    
                                        <div *ngIf="disableNext()"><button type="button" (click)="nextAgencyList()"
                                                class="next">
                                                <i class="fal fa-arrow-circle-right slx-arrow-scroll"></i></button></div>
            
                                    </div>                               
                                   
                                </div>
                                <div class="row">
                                    <div class="col-sm-2 shift-attribute"
                                        *ngFor="let agency of scrollViewitems let i = index">
                                        <span *ngIf="expandedAgencyDetails[agency.partnerId] && isIncentiveDetails && verifyInstantAcceptValue(agency.partnerId)">
                                            <div [ngSwitch]="getAttributeControlType(agency.partner_attributes,agency.attributes)"
                                                class="padt5">
                                                <div *ngSwitchCase="'textbox'" class="incentive-details">
                                                    <slx-input-decorator>
                                                        <input slx-input type="text" name="incentivepayAmount"
                                                            placeholder="Incentive Pay"
                                                            [(ngModel)]="agency.attributes[controlConterText].value">
                                                    </slx-input-decorator>
                                                </div>
                                                <div *ngSwitchCase="'dropdown'" class="incentive-details">
                                                    <slx-input-decorator>
                                                        <slx-dropdown-input slx-input class="form-control"
                                                            name="incentivepaydetails" placeholder="Incentive Pay"
                                                            [options]="dropDownOptions"
                                                            [(ngModel)]="agency.attributes[controlCounter].attrValues[0]">

                                                        </slx-dropdown-input>

                                                    </slx-input-decorator>
                                                </div>
                                            </div>

                                        </span>
                                    </div>
                                </div>
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
            <div *ngIf="isShiftSelected" class="k-card-actions">
                <div *ngIf="!editMode"><button type="button" [disabled]="selectedAgencyCount==0"
                        class="slx-button slx-blue pull-right add-shift" (click)="addShiftRequest()">Add</button></div>
                <div *ngIf="editMode"><button type="button" [disabled]="selectedAgencyCount==0"  class="slx-button slx-blue pull-right update-shift"
                        (click)="addShiftRequest()">Update</button></div>
                <button type="button" class="slx-button slx-blue pull-right cancel-shift"
                    (click)="cancelShiftRequest()">Cancel</button>

            </div>
            <div *ngIf="!isShiftSelected" class="k-card-body noshift-selected">
                No shift has been selected
            </div>
        </div>
    </div>
    <!-- </slx-spinner> -->