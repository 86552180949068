import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { PayCycle } from '../../models/index';

export class Year {
  public year: number;

  constructor(year: number) {
    this.year = year;
  }
}

export class Range {
  public range: string;
  public year: number;
  public payCycle: PayCycle;

  constructor(range: string, year: number, payCycle: PayCycle) {
    this.range = range;
    this.year = year;
    this.payCycle = payCycle;
  }
}

@Component({
  moduleId: module.id,
  selector: 'slx-custom-paycycle-dropdown',
  templateUrl: 'custom-paycycle-dropdown.component.html'
})
export class CustomPayCycleDropdownComponent implements OnInit, OnDestroy {
  @Input('selectedPayCycle')
  public set setPayCycle(val: PayCycle) {
    if (_.isObject(val) && _.isDate(val.startDate) && _.isDate(val.endDate)) {
      this.selectedPayCycle = val;
      this.selectPayCycle();
    }
  }

  @Input('setDefaultIfNotSelected')
  public set setDefault(val: boolean) {
    if (_.isBoolean(val)) {
      this.isSetDefaultPayCycle = val;
      if (val) {
        this.selectPayCycle();
      }
    }
  }

  @Input()
  public set payCycles(value: PayCycle[]) {
    this.setPayCycles(value);
  }



  @Output()
  public payCycleSelected: EventEmitter<PayCycle>;
  @Output()
  public payCyclesLoaded: EventEmitter<PayCycle[]>;

  public yearList: Year[];
  public rangeList: Range[];
  public origRangeList: Range[];
  public defaultRange: Range;
  public appConfig: IApplicationConfig;

  private origPayCycles: PayCycle[];
  private selectedPayCycle: PayCycle;
  private customOrgLevelId: number;
  private m_year: Year;
  private m_range: Range;
  private isSetDefaultPayCycle: boolean;

  constructor() {
    this.appConfig = appConfig;
    this.payCycleSelected = new EventEmitter<PayCycle>();
    this.payCyclesLoaded = new EventEmitter<PayCycle[]>();
    this.defaultRange = new Range('Please select Pay Cycle', 0, null);
    this.isSetDefaultPayCycle = true;
  }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public set currentYear(year: Year) {
    if (_.get(this.m_year, 'year') !== _.get(year, 'year')) {
      this.setupPayCycle(year, this.currentRange);
    }
  }

  public get currentYear(): Year {
    return this.m_year;
  }

  public set currentRange(range: Range) {
    if (_.get(this.m_range, 'range') !== _.get(range, 'range')) {
      this.m_range = range;
      if (_.isDate(_.get(range, 'payCycle.startDate')) && _.isDate(_.get(range, 'payCycle.endDate'))) {
        this.payCycleSelected.emit(range.payCycle);
      }
    }
  }

  public get currentRange(): Range {
    return this.m_range;
  }

  public setPayCycles(cycles: PayCycle[]): void {
    this.m_range = null;
    this.m_year = null;
    this.yearList = null;

    this.origPayCycles = _.sortBy(cycles, (item: PayCycle) => { return moment(item.startDate).unix(); });
    this.payCyclesLoaded.emit(cycles);

    this.createYearsList();
    this.createRangesList();
    this.selectPayCycle();
  }

  private createYearsList(): void {
    const yearsList: StringMap<Year> = _.reduce(this.origPayCycles, (accum: StringMap<Year>, cycle: PayCycle) => {
      const year: number = moment(cycle.startDate).year();
      accum[year] = new Year(year);

      return accum;
    }, {});

    this.yearList = _.reverse(_.values(yearsList));
  }

  private createRangesList(): void {
    const rangeList: StringMap<any> = _.reduce(this.origPayCycles, (accum: StringMap<any>, cycle: PayCycle) => {
      accum[cycle.description] = new Range(cycle.description, moment(cycle.startDate).year(), cycle);

      return accum;
    }, {});

    this.origRangeList = _.reverse(_.values(rangeList));
  }

  private filteringRanges(year: Year): Range[] {
    return _.filter(this.origRangeList, (range: Range) => year.year === range.year);
  }

  private selectPayCycle(): void {
    if (!_.isArray(this.yearList) || !_.isArray(this.origRangeList)) return;

    const sDate: Date = _.get(this.selectedPayCycle, 'startDate');
    const eDate: Date = _.get(this.selectedPayCycle, 'endDate');

    if (_.isDate(sDate) && _.isDate(eDate)) {
      const startDate: moment.Moment = moment(sDate);
      const endDate: moment.Moment = moment(eDate);
      let year: Year = _.find(this.yearList, { year: sDate.getFullYear() });
      // if (!year) {
      //   return;
      // }
      let ranges: Range[] = this.filteringRanges(year);
      let currentRange: Range = _.find(ranges, (r: Range) => startDate.isSame(r.payCycle.startDate) && endDate.isSame(r.payCycle.endDate));
      this.setupPayCycle(year, currentRange);
    } else if (this.isSetDefaultPayCycle) {
      const currentYear = _.head(this.yearList);
      this.setupPayCycle(currentYear, null);
    }
  }

  private setupPayCycle(year: Year, currentRange: Range): void {
    if (_.get(this.m_year, 'year') !== _.get(year, 'year')) {
      this.m_year = year;
      let ranges: Range[] = this.filteringRanges(year);
      this.rangeList = ranges;
      let setFirstRange: boolean = _.isNil(currentRange);
      if (setFirstRange) {
        this.currentRange = _.first(this.rangeList);
      } else {
        this.currentRange = currentRange;
      }
    }
  }

}
