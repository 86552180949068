/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bottom-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../common/directives/slx-tooltip/slx-tooltip.directive";
import * as i4 from "../../directives/navigation-link/external-link.directive";
import * as i5 from "./bottom-menu.component";
import * as i6 from "../../../authentication/services/authentication/authentication.service";
import * as i7 from "../../../common/services/change-management/change-management.service";
import * as i8 from "../../services/application-state-bus/application-state-bus.service";
var styles_BottomMenuComponent = [i0.styles];
var RenderType_BottomMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BottomMenuComponent, data: {} });
export { RenderType_BottomMenuComponent as RenderType_BottomMenuComponent };
export function View_BottomMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "bottom-menu-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "left-sidebar-open": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 7, "span", [["class", "helper-button ellipsis"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleExpanded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "expanded": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 3, "i", [["aria-hidden", "true"], ["class", "fa fa-ellipsis-v"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { "fa-ellipsis-v": 0, "fa-ellipsis-h": 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 17, "div", [["class", "buttons-container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(15, { "expanded-butons": 0 }), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [["class", "helper-button left-shift"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-key"], ["slxTooltip", "Logout"], ["tipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(18, 4341760, null, 0, i3.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 2, "span", [["class", "helper-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleFullScreen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fas fa-expand-arrows-alt"], ["slxTooltip", "Full screen (F11)"], ["tipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(21, 4341760, null, 0, i3.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 3, "span", [["class", "helper-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 16384, null, 0, i4.ExternalLinkDirective, [], { slxExternalLink: [0, "slxExternalLink"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-info"], ["slxTooltip", "SLX support"], ["tipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(25, 4341760, null, 0, i3.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 3, "span", [["class", "helper-button right-shift"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 27).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 16384, null, 0, i4.ExternalLinkDirective, [], { slxExternalLink: [0, "slxExternalLink"] }, null), (_l()(), i1.ɵeld(28, 0, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fa fa-question-circle"], ["slxTooltip", "SLX trainings"], ["tipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(29, 4341760, null, 0, i3.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bottom-menu-container"; var currVal_1 = _ck(_v, 3, 0, _co.isLeftSidebarOpen); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "helper-button ellipsis"; var currVal_3 = _ck(_v, 7, 0, _co.isExpanded); _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_4 = "fa fa-ellipsis-v"; var currVal_5 = _ck(_v, 11, 0, !_co.isExpanded, _co.isExpanded); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = "buttons-container"; var currVal_7 = _ck(_v, 15, 0, _co.isExpanded); _ck(_v, 14, 0, currVal_6, currVal_7); var currVal_8 = "Logout"; var currVal_9 = "top"; _ck(_v, 18, 0, currVal_8, currVal_9); var currVal_10 = "Full screen (F11)"; var currVal_11 = "top"; _ck(_v, 21, 0, currVal_10, currVal_11); var currVal_12 = "support"; _ck(_v, 23, 0, currVal_12); var currVal_13 = "SLX support"; var currVal_14 = "top"; _ck(_v, 25, 0, currVal_13, currVal_14); var currVal_15 = "help"; _ck(_v, 27, 0, currVal_15); var currVal_16 = "SLX trainings"; var currVal_17 = "top"; _ck(_v, 29, 0, currVal_16, currVal_17); }, null); }
export function View_BottomMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-bottom-menu", [], null, null, null, View_BottomMenuComponent_0, RenderType_BottomMenuComponent)), i1.ɵdid(1, 114688, null, 0, i5.BottomMenuComponent, [i6.AuthenticationService, i7.ChangeManagementService, i8.ApplicationStateBusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottomMenuComponentNgFactory = i1.ɵccf("slx-bottom-menu", i5.BottomMenuComponent, View_BottomMenuComponent_Host_0, {}, {}, []);
export { BottomMenuComponentNgFactory as BottomMenuComponentNgFactory };
