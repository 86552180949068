import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { appConfig } from './../../../../../app.config';
import { LMManagementService } from '../../../services/index';
import { screenUtils } from '../../../../../common/utils/index';
var LMConsoleTableComponent = /** @class */ (function () {
    function LMConsoleTableComponent(managementService) {
        this.managementService = managementService;
        this.subscriptions = {};
        this.screenUtils = screenUtils;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.appConfig = appConfig;
    }
    Object.defineProperty(LMConsoleTableComponent.prototype, "records", {
        set: function (value) {
            this.m_records = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    LMConsoleTableComponent.prototype.ngOnInit = function () { };
    LMConsoleTableComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMConsoleTableComponent.prototype.getStartDate = function (dateItem) {
        if (!dateItem.actStartDate) {
            return dateItem.estStartDate;
        }
        return dateItem.actStartDate;
    };
    LMConsoleTableComponent.prototype.onClickEmployee = function (req) {
        this.managementService.openCreationDialog(null, req);
    };
    LMConsoleTableComponent.prototype.getIcon = function (request) {
        return this.managementService.getIcon(request.requestClass);
    };
    LMConsoleTableComponent.prototype.refreshGrid = function () {
        if (!this.m_records) {
            this.gridState.view = null;
            return;
        }
        if (this.mode === 'onLeave' || this.mode === 'upcoming') {
            this.gridState.state.sort = [{ field: 'actStartDate', dir: 'asc' }];
        }
        else {
            this.gridState.state.sort = [{ field: 'estEndDate', dir: 'asc' }];
        }
        this.gridState.view = process(this.m_records, this.gridState.state);
    };
    LMConsoleTableComponent.prototype.getDaysOnLeave = function (dataItem) {
        return dataItem.isIntermittent ? '' : dataItem.daysOnLeave;
    };
    LMConsoleTableComponent.prototype.getDaysRemaining = function (dataItem) {
        return dataItem.isIntermittent ? '' : dataItem.daysRemaining;
    };
    return LMConsoleTableComponent;
}());
export { LMConsoleTableComponent };
