import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { ApplicationStateBusService } from '../../application-state-bus/application-state-bus.service';
import * as i0 from "@angular/core";
import * as i1 from "../../application-state-bus/application-state-bus.service";
var MenuAccessibleProviderService = /** @class */ (function () {
    function MenuAccessibleProviderService(busService) {
        var _this = this;
        this.busService = busService;
        this.accessibleChanged$ = new Subject();
        this.busService.subscribeToMenuLoaded(function (menu) { return _this.setMenu(menu); });
    }
    MenuAccessibleProviderService.prototype.subscribeToAccessibleChanged = function (callback) {
        return this.accessibleChanged$.subscribe(callback);
    };
    MenuAccessibleProviderService.prototype.setMenu = function (menu) {
        this.allowedMenu = {};
        this.flattenMenu(menu);
        this.accessibleChanged$.next();
    };
    MenuAccessibleProviderService.prototype.flattenMenu = function (menu) {
        var _this = this;
        if (!menu) {
            return;
        }
        _.forEach(menu, function (m) {
            if (!_this.allowedMenu[m.name]) {
                _this.allowedMenu[m.name] = m;
            }
            _this.flattenMenu(m.childs);
        });
    };
    MenuAccessibleProviderService.prototype.isAccessible = function (menuName) {
        return !!this.allowedMenu[menuName];
    };
    MenuAccessibleProviderService.prototype.getMenuItem = function (menuName) {
        return this.allowedMenu[menuName];
    };
    MenuAccessibleProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MenuAccessibleProviderService_Factory() { return new MenuAccessibleProviderService(i0.ɵɵinject(i1.ApplicationStateBusService)); }, token: MenuAccessibleProviderService, providedIn: "root" });
    return MenuAccessibleProviderService;
}());
export { MenuAccessibleProviderService };
