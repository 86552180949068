<div class="lm-toolbar">
  <slx-toolbar class="slx-so-toolbar-container" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate [width]="getLeaveTypeWidth()">
      <span class="lm-toolbar__control as-flex-end" *ngIf="canAddEdit">
        <slx-static-dropdown-list
          class="slx-wide slx-short"
          [options]="leaveTypes"
          (selectItem)="onClickNewRequest($event)"
          controlIcon="fal fa-file-plus"
          placeholder="Create New"
        ></slx-static-dropdown-list>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate width="234" >
      <span class="lm-toolbar__control">
        <span *ngIf="!isListView" (click)="onNavButtonClick(false)" class="lm-toolbar__nav-button left">
          <i class="fas fa-angle-left" aria-hidden="true"></i>
        </span>
        <slx-daterange
          class="slx-width-260 lm-toolbar__va-top"
          name="startEndDate"
          [startDate]="sDate"
          [endDate]="eDate"
          (rangeDateChanged)="onChangeDates($event)"
        ></slx-daterange>
        <span *ngIf="!isListView" (click)="onNavButtonClick(true)" class="lm-toolbar__nav-button right">
          <i class="fas fa-angle-right" aria-hidden="true"></i>
        </span>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" width="130">
      <span class="lm-toolbar__control as-flex-end" *ngIf="!isCollapsed">
        <button class="slx-button slx-with-icon slx-margin-r" type="button" (click)="onClickConsole()">
          <i aria-hidden="true" class="fas fa-th-large slx-button__icon"></i>
          <span class="slx-button__text">LOA Console</span>
        </button>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="120">
      <span class="lm-toolbar__control as-center" [ngClass]="{ 'collapsed': isCollapsed }">
        <span class="lm-toolbar__view-mode" title="Switch to List View" (click)="onClickViewMode(true)" *ngIf="!isListView">
          <i aria-hidden="true" class="fas fa-list"></i>
          <span class="lm-toolbar__vm-text">List View</span>
        </span>
        <span class="lm-toolbar__view-mode" title="Switch to Calendar View" (click)="onClickViewMode(false)" *ngIf="isListView">
          <i aria-hidden="true" class="fal fa-calendar"></i>
          <span class="lm-toolbar__vm-text">Calendar View</span>
        </span>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="90">
      <span class="lm-toolbar__control" *ngIf="!isListView" [ngClass]="{ 'collapsed': isCollapsed }">
        <slx-dropdown-list
          class="slx-wide slx-short"
          [options]="calendarModes"
          [(ngModel)]="calendarMode"
          (ngModelChange)="onChangeCalendarMode()"
          name="calendarMode"
        ></slx-dropdown-list>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="30">
      <span class="lm-toolbar__control" *ngIf="isListView && !isCollapsed">
        <button type="button"
          class="slx-button slx-only-icon slx-toolbar"
          [popper]="columnsToggler"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-start'"
          [popperDisableStyle]="true"
        >
          <i aria-hidden="true" class="fa fa-cog"></i>
        </button>
        <popper-content #columnsToggler class="slx-popper">
          <span class="lm-toolbar__popper-title">Columns to Display</span>
          <div class="lm-toolbar__popper-body">
            <div *ngFor="let column of state?.columns; let idx=index;">
              <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                <slx-checkbox-input slx-input
                  [caption]="column.description"
                  [(ngModel)]="column.displayed"
                  (ngModelChange)="onChangeColumn()"
                  name="empColumn{{idx}}"
                  fieldName="empColumn{{idx}}"
                ></slx-checkbox-input>
              </slx-input-decorator>
            </div>
          </div>
          <div class="lm-toolbar__popper-footer">
            <button class="slx-button slx-margin-r" type="button" (click)="onClickToggleSelection(true)">Select All </button>
            <button class="slx-button" type="button" (click)="onClickToggleSelection(false)"> Clear All</button>
          </div>
        </popper-content>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="140">
      <span class="lm-toolbar__control" [ngClass]="{ 'collapsed': isCollapsed }">
        <slx-multiselect class="lm-toolbar__filter"
          [options]="filters"
          [(ngModel)]="filter"
          (ngModelChange)="onChangeFilter($event)"
          placeholder="Category filter"
          name="filter"
        ></slx-multiselect>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="90">
      <span class="lm-toolbar__control" *ngIf="!isCollapsed && isListView">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>
            <slx-action-list-item (onClick)="onClickExport(true)">Export to PDF</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </span>
    </ng-template>
  </slx-toolbar>
</div>
