import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody, Meta } from '../../../core/models/index';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { IBudgetDefinition, BudgetDefinition, BudgetedGroup, IBudgetedGroup } from '../../../organization/models/index';
import { Assert } from '../../../framework/index';
import { BudgetMapService } from './budget-map.service';
import {
  BudgetRecord, IBudgetRecord,
  Budget, IBudget,
  BudgetCensus, IBudgetCensus,
  BudgetHistory, IBudgetHistory
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class BudgetApiService {
  constructor(
    private budgetMapService: BudgetMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public addBudget(req: Budget): Promise<Budget> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getBudgetsApiRoot()}`;
    let modelDTO: IBudget = this.budgetMapService.mapToBudgetDto(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<Budget> = this.apiUtilService.request<IBudget, Meta>(request)
      .then((response: ResponseBody<IBudget, Meta>) => {
        return this.budgetMapService.mapBudget(response.data);
      });
    return promise;
  }

  public saveBudget(req: Budget): Promise<any> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getBudgetsApiRoot()}/${req.id}`;
    let modelDTO: IBudget = this.budgetMapService.mapToBudgetDto(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<Budget> = this.apiUtilService.request<IBudget, Meta>(request)
      .then((response: ResponseBody<IBudget, Meta>) => {
        return this.budgetMapService.mapBudget(response.data);
      });
    return promise;
  }

  public restoreBudget(sourceBudgetId: number, destinationBudgetId: number): Promise<Budget> {
    Assert.isNotNull(sourceBudgetId, 'sourceBudgetId');

    const url: string = `${this.getBudgetsApiRoot()}/${sourceBudgetId}/${timeAndAttendanceConfig.api.budgets.restore}/${destinationBudgetId}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, null);

    let promise: Promise<Budget> = this.apiUtilService.request<IBudget, Meta>(request)
      .then((response: ResponseBody<IBudget, Meta>) => this.budgetMapService.mapBudget(response.data));
    return promise;
  }

  public cloneBudget(sourceBudgetId: number, req: BudgetDefinition): Promise<Budget> {
    Assert.isNotNull(req, 'cloneBudget request');
    let modelDTO: IBudgetDefinition = this.budgetMapService.mapToBudgetDefinitionDto(req);
    let body: any = modelDTO;
    const url: string = `${this.getBudgetsApiRoot()}/${sourceBudgetId}/${timeAndAttendanceConfig.api.budgets.clone}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<Budget> = this.apiUtilService.request<IBudget, Meta>(request)
      .then((response: ResponseBody<IBudget, Meta>) => this.budgetMapService.mapBudget(response.data));
    return promise;
  }

  public deleteBudget(budgetId: number): Promise<any> {
    Assert.isNotNull(budgetId, 'budgetId');

    const url: string = `${this.getBudgetsApiRoot()}/${budgetId}`;
    let request: HttpRequest<any> = new HttpRequest('DELETE', url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
    return promise;
  }

  public getBudget(orgLevelId: number, budgetId: number): Promise<Budget> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(budgetId, 'budgetId');

    const url: string = `${this.getOrgLevelBudgetsApiRoot(orgLevelId)}/${budgetId}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<Budget> = this.apiUtilService.request<IBudget, Meta>(request)
      .then((response: ResponseBody<IBudget, Meta>) => {
        return this.budgetMapService.mapBudget(response.data);
      });
    /*
    let promise: Promise<Budget> = Promise.resolve(this.budgetMapService.mapBudget(Budget.mock));
    */
    return promise;
  }

  public getBudgetDummy(orgLevelId: number): Promise<Budget> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = `${this.getOrgLevelBudgetsApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.budgets.dummy}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<Budget> = this.apiUtilService.request<IBudget, Meta>(request)
      .then((response: ResponseBody<IBudget, Meta>) => {
        return this.budgetMapService.mapDummyBudget(response.data);
      });
    return promise;
  }

  public getBudgetHistory(orgLevelId: number, startDate: Date, endDate: Date): Promise<BudgetHistory[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.orglevel.budgetDefinitions}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      })
    });

    let promise: Promise<BudgetHistory[]> = this.apiUtilService.request<IBudgetDefinition[], Meta>(request)
      .then((response: ResponseBody<IBudgetDefinition[], Meta>) => {
        return this.budgetMapService.mapBudgetHistories(response.data);
      });

    //let promise: Promise<BudgetHistory[]> = Promise.resolve(this.budgetMapService.mapBudgetHistories(BudgetHistory.mock));
    return promise;
  }

  public createBudgetedGroup(orgLevelId: number, grp: BudgetedGroup): Promise<BudgetedGroup> {
    Assert.isNotNull(grp, 'createBudgetedGroup BudgetGroup');
    const url: string = `${this.getBudgetedGroupsOrgLevelApiRoot(orgLevelId)}`;
    let modelDTO: IBudgetedGroup = this.budgetMapService.mapToBudgetedGroupDto(grp);
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, modelDTO);
    let promise: Promise<BudgetedGroup> = this.apiUtilService.request<IBudgetedGroup, Meta>(request)
      .then((response: ResponseBody<IBudgetedGroup, Meta>) => this.budgetMapService.mapBudgetedGroup(response.data));
    return promise;
  }

  public updateBudgetedGroup(grp: BudgetedGroup): Promise<BudgetedGroup> {
    Assert.isNotNull(grp, 'createBudgetedGroup BudgetGroup');
    const url: string = `${this.getBudgetedGroupsApiRoot()}/${grp.id}`;
    let modelDTO: IBudgetedGroup = this.budgetMapService.mapToBudgetedGroupDto(grp);
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, modelDTO);
    let promise: Promise<BudgetedGroup> = this.apiUtilService.request<IBudgetedGroup, Meta>(request)
      .then((response: ResponseBody<IBudgetedGroup, Meta>) => this.budgetMapService.mapBudgetedGroup(response.data));
    return promise;
  }

  public deleteBudgetedGroup(groupsIds: number[]): Promise<any> {
    const url: string = `${this.getBudgetedGroupsApiRoot()}/${timeAndAttendanceConfig.api.budgetedGroups.deleteSet}`;
    let request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url, undefined, groupsIds);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  private getApiRoot(): string {
    return this.apiUtilService.getApiRoot();
  }
  private getOrgLevelBudgetsApiRoot(orgLevelId: number): string {
    return `${this.getOrgLevelApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.orglevel.budgets}`;
  }
  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.orglevel.root}/${orgLevelId}`;
  }
  private getBudgetsApiRoot(): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.budgets.root}`;
  }
  private getBudgetedGroupsApiRoot(): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.budgetedGroups.root}`;
  }
  private getBudgetedGroupsOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getBudgetedGroupsApiRoot()}/${timeAndAttendanceConfig.api.budgetedGroups.orgLevel}/${orgLevelId}`;
  }

}
