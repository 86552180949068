import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { appConfig, IApplicationConfig } from '../../../../../../app.config';

import { EmployeeWarningSections, EmployeeWarningExtended, EmployeeWarningSectionViolations, EmployeeWarningViolation } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-warnings-violations',
  templateUrl: 'employee-sections-warnings-violations.component.html',
  styleUrls: ['employee-sections-warnings-violations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsWarningsViolationsComponent implements OnInit, OnDestroy {
  public violations: EmployeeWarningSectionViolations;
  public violationDate: Date = null;
  public violationsList: Array<EmployeeWarningViolation> = [];

  @unsubscribeAll()
  public subscriptions: StringMap<Subscription> = {};
  @ViewChild('form', { static: true })
  public form: NgForm;
  public appConfig: IApplicationConfig = appConfig;

  constructor(
    private readonly manService: EmployeeSectionWarningsManagementService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.violations = this.manService.getViolationsSection();

    this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(warning => {
      this.assignViolations(warning);
      this.assignViolationsData();
      this.updateView();
    });

    this.subscriptions.statusChanges = this.form.statusChanges.subscribe(() => {
      this.manService.changeSectionValidity(EmployeeWarningSections.StandardViolations, this.form.valid);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onChangeDate(): void {
    this.violations.data.dateOfViolation = this.getDateCopy(this.violationDate);
    this.manService.markWarningAsEdited(true);
  }

  public onChangeViolation(vio: EmployeeWarningViolation): void {
    const violation = _.find(this.violations.data.violations, { id: vio.id });
    if (_.isObjectLike(violation)) {
      violation.selected = vio.selected;
      this.manService.markWarningAsEdited(true);
    }
  }

  private updateView(): void {
    Observable.of(true)
      .delay(200)
      .subscribe(() => this.changeDetector.detectChanges());
  }

  private assignViolations(warning: EmployeeWarningExtended): void {
    const violations = _.find(warning.sections, s => s.isStandardViolations);
    if (_.isObjectLike(violations) && _.isObjectLike(violations.data)) {
      this.violations = violations as EmployeeWarningSectionViolations;
    }
  }

  private assignViolationsData(): void {
    this.violationDate = this.getDateCopy(this.violations.data.dateOfViolation);
    this.violationsList = _.cloneDeep(this.violations.data.violations);
  }

  private getDateCopy(d: Date): Date {
    return _.isDate(d) ? new Date(d.getTime()) : null;
  }
}
