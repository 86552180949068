import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PmConfigurationManagementService } from '../../services';
import { CfgPmCode } from '../../models';
import { PmTemplateDefinition } from '../../../../organization';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'slx-configure-performance-management',
  templateUrl: 'configure-performance-management.component.html',
  styleUrls: ['configure-performance-management.component.scss'],
  providers: [
    PmConfigurationManagementService
  ]
})
export class ConfigurePerformanceManagementComponent implements OnInit, OnDestroy {

  public codes: CfgPmCode[];
  public templates: PmTemplateDefinition[];
  public isLoading: boolean;

  private subscriptions: StringMap<Subscription> = {};

  constructor (
    private managementService: PmConfigurationManagementService
  ) { }

  public ngOnInit(): void {

    this.subscriptions.codesLoaded = this.managementService.subscribeToCodesLoaded((codes: CfgPmCode[]) => {
      this.codes = codes;
    });

    this.subscriptions.templatesLoaded = this.managementService.subscribeToTemplatesLoaded((templates: PmTemplateDefinition[]) => {
      this.templates = templates;
    });

    this.subscriptions.loading = this.managementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });

    this.managementService.initialize();
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

}
