import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';
import { ShiftDefinition, Position, ConstraintDefinition, LocationUnit, ScheduleAbsence } from '../../../../organization/models/index';
import { ScheduleEntryManagementService } from '../../../services/index';
import { ScheduleEntry, ScheduleEntryShift, ScheduleEntryShiftContainer, ShiftLate, ShiftOvertime, ShiftPartialAbsence } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-scheduled-shift',
  templateUrl: 'scheduled-shift.component.html',
  styleUrls: ['scheduled-shift.component.scss']
})
export class ScheduledShiftComponent implements OnInit {

  @Input()
  public set shift(value: ScheduleEntryShiftContainer) {
    this.m_shift = value;
  }

  @Input()
  public index: number;

  public get shift(): ScheduleEntryShiftContainer {
    return this.m_shift;
  }

  public appConfig: IApplicationConfig;

  private m_shift: ScheduleEntryShiftContainer;

  constructor(public management: ScheduleEntryManagementService) {
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    _.noop();
  }

  // --- menu hanlders ---

  public removeShift(): void {
    this.management.removeShift(this.m_shift);
  }

  public addConstraint(): void {
    if (this.m_shift && this.m_shift.hasConstraints) {
      this.management.addConstraint(this.m_shift);
    }
  }

  public removeConstraint(): void {
    this.management.removeConstraint(this.m_shift);
  }

  public addLate(): void {
    this.management.addLate(this.m_shift);
  }

  public removeLate(): void {
    this.management.removeLate(this.m_shift);
  }

  public addOvertime(): void {
    this.management.addOvertime(this.m_shift);
  }

  public removeOvertime(): void {
    this.management.removeOvertime(this.m_shift);
  }

  public markAbsent(): void {
    this.management.markAbsent(this.m_shift);
  }

  public markPresent(): void {
    this.management.markPresent(this.m_shift);
  }

  public addPartialAbsence(): void {
    this.management.addPartialAbsence(this.m_shift);
  }

  public removePartialAbsence(): void {
    this.management.removePartialAbsence(this.m_shift);
  }

  public addNotes(): void {
    this.management.addNotes(this.m_shift);
  }

  public removeNotes(): void {
    this.management.removeNotes(this.m_shift);
  }

  public onReplacement(): void {
    this.management.onReplacement(this.m_shift);
  }

  //--- section hanlders
  public onPositionChanged(newPosition: Position): void {
    this.m_shift.scheduleEntryShift.position = newPosition;
    this.management.onPositionChanged(this.m_shift);
  }

  public onShiftChanged(definition: ShiftDefinition): void {
    this.m_shift.scheduleEntryShift.shift = definition;
    this.management.shiftChange(this.m_shift.scheduleEntryShift, this.m_shift);
  }

  public onUnitChanged(unit: LocationUnit): void {
    this.m_shift.scheduleEntryShift.unit = unit;
    this.management.unitChange(unit, this.m_shift);
  }

  public onAbsenceChanged(absence: ScheduleAbsence): void {
    this.m_shift.scheduleEntryShift.scheduleAbsence = absence;
    this.management.absenceChange(absence, this.m_shift);
  }

  public onConstraintChanged(constraint: ConstraintDefinition): void {
    this.m_shift.scheduleEntryShift.constraint = constraint;
    this.management.constraintChanged(this.m_shift);
  }

  public onStartDateChanged(date: Date): void {
    this.m_shift.shiftStartDate = date;
    this.management.onShiftStartDateChanged(this.m_shift);
  }

  public onEndDateChanged(date: Date): void {
    this.m_shift.shiftEndDate = date;
    this.management.onShiftEndDateChanged(this.m_shift);
  }

  public onShiftHoursChanged(value: number): void {
    this.m_shift.shiftHours = value;
    this.management.shiftPaidTimeChanged(this.m_shift);
  }

  public onUnpaidHoursChanged(value: number): void {
    this.m_shift.unpaidHours = value;
    this.management.shiftUnpaidTimeChanged(this.m_shift);
  }

  public onNoteChanged(notes: string): void {
    this.m_shift.scheduleEntryShift.notes = notes;
    this.management.checkChanges();
  }

  public lateDateChanged(late: ShiftLate): void {
    this.management.lateDateChanged(this.m_shift);
  }

  public onLateIntervalChanged(late: ShiftLate): void {
    this.management.lateIntervalChanged(this.m_shift);
  }

  public overtimeDateChanged(overtime: ShiftOvertime): void {
    this.management.overtimeDateChanged(this.m_shift);
  }

  public overtimeHoursChanged(overtime: ShiftOvertime): void {
    this.management.overtimeHoursChanged(this.m_shift);
  }

  public onPartialAbsenceTimeChange(pa: ShiftPartialAbsence): void {
    this.management.shiftPartialAbsenceTimeChanged(this.m_shift);
  }

  public onPartialAbsenceChange(pa: ShiftPartialAbsence): void {
    this.management.checkChanges();
  }

  public onPartialAbsenceAttachmentChange(toEnd: boolean): void {
    this.m_shift.partialAbsToEnd = toEnd;
    this.management.onPartialAbsenceAttachmentChanged(this.m_shift);
  }

}
