import { Injectable } from '@angular/core';

import { ToolbarBaseService } from '../../../core/services/index';

@Injectable()
export class IdealScheduleToolbarService extends ToolbarBaseService<any> {

  constructor() {
    super();
  }
}
