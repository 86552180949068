import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { ReportFormBase } from '../report-form-base';
import { FormGroup, NgModel } from '@angular/forms';
import { ThreeChoiceEnum } from '../../../models/three-choice.enum';
import { wcConfig } from '../../../workers-compensation.config';
var WcWorkerCompensationReportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WcWorkerCompensationReportComponent, _super);
    function WcWorkerCompensationReportComponent(management) {
        var _this = _super.call(this) || this;
        _this.management = management;
        _this.subscriptions = {};
        _this.threeChoiceOptions = [
            { id: ThreeChoiceEnum.Yes, name: 'Yes' },
            { id: ThreeChoiceEnum.No, name: 'No' },
            { id: ThreeChoiceEnum.Unknown, name: 'Unknown' }
        ];
        _this.bodySides = [
            'left', 'right', 'both', 'unknown'
        ];
        _this.m_minimumDate = moment(wcConfig.settings.minDateString).toDate();
        return _this;
    }
    Object.defineProperty(WcWorkerCompensationReportComponent.prototype, "isCreatingNew", {
        get: function () {
            return this.report && !_.isFinite(this.report.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcWorkerCompensationReportComponent.prototype, "comp", {
        get: function () {
            return this.report ? this.report.compReport : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcWorkerCompensationReportComponent.prototype, "minimumDate", {
        get: function () {
            return this.m_minimumDate;
        },
        enumerable: true,
        configurable: true
    });
    WcWorkerCompensationReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.load = this.management.onLoaded$
            .combineLatest(this.management.wcLookupsLoaded$)
            .subscribe(function (_a) {
            var report = _a[0], lookups = _a[1];
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_b) {
                    this.lookups = lookups;
                    this.report = report;
                    this.updateLostDaysCount();
                    if (this.isCreatingNew) {
                        this.populateDates();
                    }
                    return [2 /*return*/];
                });
            });
        });
        this.subscriptions.form = this.wcReportForm.statusChanges.subscribe(function (x) {
            if (_this.wcReportForm.dirty && _this.wcReportForm.touched) {
                _this.management.onChangeNotify();
            }
            _this.management.setWcFormValidity(_this.wcReportForm.valid);
        });
        this.loadStatesLookup();
        if (this.isCreatingNew) {
            this.report.compReport.dateTreatmentReceived = null;
        }
    };
    WcWorkerCompensationReportComponent.prototype.ngOnDestroy = function () { };
    WcWorkerCompensationReportComponent.prototype.onClickDisable = function (acceptPopover) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        acceptPopover.hide();
                        this.management.changeLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.management.disableWCReport()];
                    case 2:
                        _a.sent();
                        this.management.changeLoading(false);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    WcWorkerCompensationReportComponent.prototype.onClickCancel = function (acceptPopover) {
        acceptPopover.hide();
    };
    WcWorkerCompensationReportComponent.prototype.loadStatesLookup = function () {
        var _this = this;
        this.management.loadStates()
            .then(function (l) {
            _this.statesLookup = l;
        });
    };
    WcWorkerCompensationReportComponent.prototype.onisLostDaysChanged = function (enable) {
        if (this.comp) {
            if (enable) {
                this.comp.dateLoseBegan = moment().toDate();
                this.comp.dateLoseEnd = moment().add(1, 'day').toDate();
            }
            else {
                this.comp.dateLoseBegan = null;
                this.comp.dateLoseEnd = null;
            }
        }
        this.updateLostDaysCount();
    };
    WcWorkerCompensationReportComponent.prototype.updateLostDaysCount = function () {
        if (this.comp) {
            if (this.comp.isLosingTime) {
                if (this.comp.dateLoseBegan && this.comp.dateLoseEnd) {
                    this.comp.lostDaysCount = moment(this.comp.dateLoseEnd).diff(this.comp.dateLoseBegan, 'day');
                    return;
                }
            }
        }
        this.comp.lostDaysCount = 0;
    };
    WcWorkerCompensationReportComponent.prototype.updateLostEndDate = function (days) {
        if (this.comp) {
            if (this.comp.isLosingTime) {
                if (!this.comp.dateLoseBegan) {
                    this.comp.dateLoseBegan = moment().toDate();
                }
                this.comp.lostDaysCount = days;
                this.comp.dateLoseEnd = moment(this.comp.dateLoseBegan).add(days, 'day').toDate();
            }
        }
    };
    WcWorkerCompensationReportComponent.prototype.populateDates = function () {
        if (this.comp) {
            this.comp.dateErNotified = new Date();
            this.comp.lastDateWorked = new Date();
            this.comp.expectReturnDate = null;
            this.comp.preparedDate = new Date();
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcWorkerCompensationReportComponent.prototype, "subscriptions", void 0);
    return WcWorkerCompensationReportComponent;
}(ReportFormBase));
export { WcWorkerCompensationReportComponent };
