import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { OrgLevelType } from '../../../../../../state-model/models';
import { BenefitListManagementService } from './../../../../services/benefit-console/index';
import { BenefitConsoleCommonService } from './../../../../services/benefit-console/benefit-console-common.service';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
var BenefitPlanTypeComponent = /** @class */ (function () {
    function BenefitPlanTypeComponent(manService, activatedRoute, router, commonService) {
        this.manService = manService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.commonService = commonService;
        this.m_isCollapsed = true;
        this.singleGroupCalled = true;
        this.subscriptions = {};
        this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
    }
    Object.defineProperty(BenefitPlanTypeComponent.prototype, "hasErrors", {
        get: function () {
            if (this.validatingFunction) {
                return this.validatingFunction();
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitPlanTypeComponent.prototype, "isCollapsed", {
        get: function () {
            return this.m_isCollapsed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitPlanTypeComponent.prototype, "canCreateNew", {
        get: function () {
            return _.isObjectLike(this.orgLevel) && this.orgLevel.type !== OrgLevelType.department;
        },
        enumerable: true,
        configurable: true
    });
    BenefitPlanTypeComponent.prototype.getDataForExpandedGroups = function () {
        var _this = this;
        if (this.subscriptions.expandedGroups) {
            this.subscriptions.expandedGroups.unsubscribe();
        }
        this.subscriptions.expandedGroups = this.commonService.subscribetoExpandedGroups(function (currentExpandedGroups) {
            if (!_this.singleGroupCalled) {
                if (_.includes(currentExpandedGroups, _this.groupId) && !_this.m_isCollapsed) {
                    _this.manService.getBenefitPlanTypes(_this.groupId, _this.isShowExpired);
                }
            }
        });
    };
    BenefitPlanTypeComponent.prototype.callExpandedGroupsData = function () {
        this.singleGroupCalled = false;
        this.getDataForExpandedGroups();
        this.singleGroupCalled = true;
    };
    BenefitPlanTypeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.manService
            .subscribeToOrgLevel(function (v) {
            _this.orgLevel = v;
            _this.callExpandedGroupsData();
        });
        this.subscriptions.showExpired = this.manService
            .subscribeToIsLoadingExpired(function (r) {
            _this.isShowExpired = r;
            _this.callExpandedGroupsData();
        });
        if (this.groupId === 1) {
            this.m_isCollapsed = false;
            this.singleGroupCalled = true;
        }
    };
    BenefitPlanTypeComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    BenefitPlanTypeComponent.prototype.collapsedChange = function (isCollapsed, groupId) {
        this.singleGroupCalled = true;
        this.m_isCollapsed = isCollapsed;
        if (groupId) {
            if (isCollapsed) {
                this.commonService.removeCollapsedGroups(groupId);
            }
            else {
                this.commonService.recordExpandedGroups(groupId);
                this.manService.getBenefitPlanTypes(groupId, this.isShowExpired);
            }
        }
    };
    BenefitPlanTypeComponent.prototype.createNewPlans = function () {
        this.navService.navigateToDetails(this.orgLevel.id, parseInt(this.groupId));
    };
    BenefitPlanTypeComponent.prototype.getCollapsedState = function () {
        return (this.index === 0) ? false : true;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitPlanTypeComponent.prototype, "subscriptions", void 0);
    return BenefitPlanTypeComponent;
}());
export { BenefitPlanTypeComponent };
