import * as _ from 'lodash';
export var requireDecimalMultiValidationKey = 'requireDecimalMulti';
export function requireDecimalMultiValidator() {
    var validationPattern = new RegExp(/^\d+(\.\d+)?(([\w\s]?)[,]([\w\s]?)\d+(\.\d+)?)*$/);
    var validator = function (control) {
        var _a;
        var value = _.trim(control.value);
        var isValid = validationPattern.test(value);
        return !isValid ? (_a = {}, _a[requireDecimalMultiValidationKey] = true, _a) : null;
    };
    return validator;
}
