<slx-spinner [show]="isLoading">
  <div class="slx-sms-box" id="msg-list">
    <kendo-grid #kendoGrid [height]="465" class="slx-msg-grid msg-center-screen" 
      [data]="gridState?.view">
      <kendo-grid-column>
        <ng-template kendoGridCellTemplate>
          <div class="emp-msg-list" id="emp-msg-list">
            <div class="msg-list">
              <slx-slate-messages-grid [messages]="messages" [isExpanded]="isExpanded"></slx-slate-messages-grid>
            </div>
            <div class="report-section white" *ngFor="let type of sectionTypes">
              <div class="report-section-header" *ngIf="type.sectionType != 'lessthanmonth'">
                <div class="report-section-header-content">
                  <div class="header-item header-title">
                    <span class="header-title__text">
                      {{ type.sectionTypeText }}
                    </span>
                  </div>
                </div>
                <div class="report-section-header-button-right">
                  <slx-collapse-button [isCollapsed]="type.isCollapsed" className="slx-with-icon"
                                       (isCollapsedChange)="collapsedChange($event, type)" [slxCollapseByCss]="collapseContent1">
                  </slx-collapse-button>
                </div>
              </div>

              <div #collapseContent1 class="report-section-content">
                <slx-slate-messages-grid *ngIf="type.messages.length > 0" [messages]="type.messages" [isExpanded]="isExpanded"></slx-slate-messages-grid>
              </div>
            </div>
            </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</slx-spinner>
