import { PositionModel } from './positions.model';

export class RemovePositionRequest {
    public requireReassign: boolean;
    public orgLevelId: number;
    public ressignedPositionId: number;
    public itemIdToDelete: number;
    public effectiveDate: Date;
    public itemToDelete: PositionModel;
}
