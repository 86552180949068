import { Injectable } from '@angular/core';
import { AbsenceModel, IAbsenceDTO } from '../../models/absences/absence.model';
import * as _ from 'lodash';
import { Assert } from '../../../framework/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class AbsenceConfigurationMapService {
    public mapAbsences(dtos: IAbsenceDTO[]): AbsenceModel[] {

        Assert.isNotNull(dtos);
        let arr: AbsenceModel[] = [];

        _.each(dtos, (dto: IAbsenceDTO) => {
            arr.push(this.mapAbsence(dto));
        });

        return arr;
    }

    private mapAbsence(dto: IAbsenceDTO): AbsenceModel {
        Assert.isNotNull(dto.taAbsence, 'taAbsence');
        let model: AbsenceModel = new AbsenceModel();
        model.code = dto.code;
        model.color = dto.color;
        model.description = dto.description;
        model.essPresent = dto.essPresent;
        model.isPaid = dto.isPaid;
        model.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
        model.lastUpdateUsername = dto.lastUpdateUsername;
        model.ptoPlannerIndicator = dto.ptoPlannerIndicator;
        model.loaIndicator = dto.loaIndicator;
        model.taCode = dto.taAbsence.code;
        return model;
    }

}
