import { Component } from '@angular/core';
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common/services/index';
import { data_Check_orgsync, WFMOrgLevelChangeLogModel } from '../../../models/wfm-sync';
import { WfmSyncService } from '../../../services/wfm/wfm-sync.service';
import { BaseSyncComponent } from '../wfm-sync-base.component';

@Component({
  selector: 'slx-work-location-sync',
  templateUrl: './work-location-sync.component.html',
  styleUrls: ['./work-location-sync.component.scss'],
})
export class WorkLocationSyncComponent extends BaseSyncComponent<WFMOrgLevelChangeLogModel> {
  organization: boolean = true;
  department: boolean = false;
  position: boolean = false;

  constructor(
    private wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(notificationService, busyService);
  }

  isCheckboxDisabled(item: WFMOrgLevelChangeLogModel): boolean {
    return this.isSyncItemDisabled(item);
  }

  protected syncDataCore(selectedItems: WFMOrgLevelChangeLogModel[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.SyncOrganizationLevelsChangeLogs(selectedItems, 'ongoing')
    );
  }

  protected getSyncItemKey(item: WFMOrgLevelChangeLogModel): string {
    if (item.orgLevelType.toUpperCase() != 'POSITION') {
      return `${item.orgLevelId}_${item.orgLevelType}`;
    }

    return '' + item.jobCode
  }

  protected async fetchData(): Promise<WFMOrgLevelChangeLogModel[]> {
    const response = await this.busyService.busy(
      this.wfmSyncService.GetOrganizationLevelsChangeLogs('ongoing', 'orgstructure')
    );

    return this.processData(response.data);
  }

  protected isSyncItemDisabled(item: WFMOrgLevelChangeLogModel): boolean {
    const orgLevelType = item.orgLevelType.toUpperCase();
    const isOrg = orgLevelType === 'ORGANIZATION';
    const isDep = orgLevelType === 'DEPARTMENT';
    const isPos = orgLevelType === 'POSITION';

    return (this.organization && !isOrg) || // Organization checked, but item is not an organization
           (this.department && !isDep)   || // Department checked, but item is not a department
           (this.position && !isPos)     || // Position checked, but item is not a positon
           // or if data is not valid
           (item.companyId == null || item.companyId == 0 || item.shortCode == null || item.shortCode == 0 || item.payrollCode === null || !(item.payrollCode >= 1 && item.payrollCode <= 99) || (item.paygroup === "" || item.paygroup === null || item.paygroup === undefined))
  }

  private processData(data: WFMOrgLevelChangeLogModel[]): WFMOrgLevelChangeLogModel[] {
    return data_Check_orgsync(data);
  }
}
