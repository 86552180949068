import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, OnChanges, SimpleChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { DailyPunchesManagementService } from '../../../services/index';
import { LinePunch, TimeCardModel, DailyLinePunchActions, TimeCardDisplayOptions } from '../../../models/index';
import { DailyTimecardManagementService } from '../../../services/index';
import { ChangeManagementService } from '../../../../common/services/index';
var SectionPunchesManagementComponent = /** @class */ (function () {
    function SectionPunchesManagementComponent(timecardsManagementService, managementService, changeDetector, changeManagementService) {
        this.timecardsManagementService = timecardsManagementService;
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.changeManagementService = changeManagementService;
        this.changeGroup = 'SectionPunchesManagementComponent';
        this.state = {
            isLoading: false
        };
        this.applyChanges = new EventEmitter();
        this.changingPunches = new EventEmitter();
        this.userActions = new DailyLinePunchActions(true, false);
    }
    SectionPunchesManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.startChangingPunchesSubscription = this.managementService.onStartChangingPunches$
            .subscribe(function () {
            _this.timecardsManagementService.onPunchesEditAction(true);
        });
        this.cancelChangingPunchesSubscription = this.managementService.onCancelChangingPunches$
            .subscribe(function () {
            _this.timecardsManagementService.onPunchesEditAction(false);
        });
        this.changingPunchesSubscription = this.managementService.onChangingPunches$
            .subscribe(function (changes) {
            _this.timecard.dailyPunches = changes;
            _this.managementService.markClosingPunches(_this.timecard.dailyPunches);
            var res = _this.managementService.validatePairs(_this.timecard.dailyPunches);
            _this.timecard.hasMissingPunches = res.hasInMissingPunch || res.hasOutMissingPunch;
            _this.managementService.setPanches(_this.timecard.dailyPunches);
            _this.timecardsManagementService.onPunchesEditAction(false);
            _this.changeManagementService.changeNotify(_this.changeGroup);
        });
        this.discardChangesSubscription = this.managementService.onDiscardChanges$
            .subscribe(function () {
            _this.timecardsManagementService.onPunchesDiscardAction();
            _this.managementService.setPanches(_this.timecard.dailyPunches);
            _this.changeManagementService.clearChanges(_this.changeGroup);
        });
        this.canEditChangedSubscription = this.timecardsManagementService.canEditChanged$
            .subscribe(function (canEdit) {
            _this.managementService.canEditChanged(canEdit);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.optionsChangedSubscription = this.timecardsManagementService.displayOptionSelected$
            .subscribe(function (options) {
            _this.displayOptions = options;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    SectionPunchesManagementComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    SectionPunchesManagementComponent.prototype.ngOnChanges = function (changes) {
        if (changes['timecard'] && this.timecard) {
            this.managementService.setPanches(this.timecard.dailyPunches);
            this.managementService.canEditChanged(this.timecard.canEditTimecard && this.timecard.canEditOwnTimecard);
            var res = this.managementService.validatePairs(this.timecard.dailyPunches);
            this.timecard.hasMissingPunches = res.hasInMissingPunch || res.hasOutMissingPunch;
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SectionPunchesManagementComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SectionPunchesManagementComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SectionPunchesManagementComponent.prototype, "startChangingPunchesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SectionPunchesManagementComponent.prototype, "changingPunchesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SectionPunchesManagementComponent.prototype, "cancelChangingPunchesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SectionPunchesManagementComponent.prototype, "discardChangesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SectionPunchesManagementComponent.prototype, "canEditChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SectionPunchesManagementComponent.prototype, "optionsChangedSubscription", void 0);
    return SectionPunchesManagementComponent;
}());
export { SectionPunchesManagementComponent };
