import * as moment from 'moment';
/*
export enum PunchStatus {
  valid = 'valid',
  validEdited = 'validEdited',
  invalid = 'invalid',
  scheduled = 'scheduled',
  ess = 'ess',
  deleted = 'deleted',
  edited = 'edited',
  request = 'request'
}*/
export var PunchSource;
(function (PunchSource) {
    PunchSource["Unknown"] = "Unknown";
    PunchSource["Timeclock"] = "Timeclock";
    PunchSource["Schedule"] = "Schedule";
    PunchSource["Request"] = "Request";
    PunchSource["Manual"] = "Manual";
    PunchSource["Geo"] = "Geo";
})(PunchSource || (PunchSource = {}));
export var PunchStatus;
(function (PunchStatus) {
    PunchStatus["Unknown"] = "Unknown";
    PunchStatus["Valid"] = "Valid";
    PunchStatus["Invalid"] = "Invalid";
    PunchStatus["Pending"] = "Pending";
    PunchStatus["Edited"] = "Edited";
    PunchStatus["Deleted"] = "Deleted";
    PunchStatus["Overlap"] = "Overlap";
    PunchStatus["InvalidLogin"] = "InvalidLogin";
})(PunchStatus || (PunchStatus = {}));
export var PunchDisplay;
(function (PunchDisplay) {
    PunchDisplay["Unknown"] = "Unknown";
    PunchDisplay["Edited"] = "Edited";
    PunchDisplay["ValidPunch"] = "Valid Punch";
    PunchDisplay["ValidEdited"] = "Valid Edited";
    PunchDisplay["Schedule"] = "Schedule";
    PunchDisplay["ScheduleExempt"] = "Schedule Exempt";
    PunchDisplay["PunchRequest"] = "Punch Request";
    PunchDisplay["InvalidPunch"] = "Invalid Punch";
    PunchDisplay["DeletedPunch"] = "Deleted Punch";
    PunchDisplay["Overlap"] = "Overlap";
    PunchDisplay["InvalidLogin"] = "Invalid Login";
})(PunchDisplay || (PunchDisplay = {}));
var LinePunch = /** @class */ (function () {
    function LinePunch() {
        this.autoOverlap = false;
    }
    Object.defineProperty(LinePunch.prototype, "punchDisplay", {
        get: function () {
            if (this.isEditedAndValidated) {
                if (this.punchStatus === PunchStatus.Overlap) {
                    return PunchDisplay.Overlap;
                }
                return PunchDisplay.Edited;
            }
            if (this.punchStatus === PunchStatus.Valid) {
                if (this.punchSource === PunchSource.Manual) {
                    return PunchDisplay.ValidEdited;
                }
                return PunchDisplay.ValidPunch;
            }
            if (this.punchStatus === PunchStatus.Edited) {
                return PunchDisplay.ValidEdited;
            }
            if (this.punchStatus === PunchStatus.Unknown || this.punchStatus === PunchStatus.Pending) {
                if (this.punchSource === PunchSource.Schedule) {
                    if (this.isExempt) {
                        return PunchDisplay.ScheduleExempt;
                    }
                    else {
                        return PunchDisplay.Schedule;
                    }
                }
                if (this.punchSource === PunchSource.Request) {
                    return PunchDisplay.PunchRequest;
                }
            }
            if (this.punchStatus === PunchStatus.Invalid) {
                return PunchDisplay.InvalidPunch;
            }
            if (this.punchStatus === PunchStatus.Deleted) {
                return PunchDisplay.DeletedPunch;
            }
            if (this.punchStatus === PunchStatus.InvalidLogin) {
                return PunchDisplay.InvalidLogin;
            }
            return PunchDisplay.Unknown;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LinePunch.prototype, "isDirty", {
        get: function () {
            if (this.m_otypeName !== (this.type ? this.type.name : null)) {
                return true;
            }
            if (this.m_otime && this.time && !moment(this.m_otime).isSame(this.time)) {
                return true;
            }
            if (!this.m_otime && this.time || this.m_otime && !this.time) {
                return true;
            }
            if ((this.m_oroundedTime && this.roundedTime && !moment(this.m_oroundedTime).isSame(this.roundedTime))) {
                return true;
            }
            if (!this.m_oroundedTime && this.roundedTime || this.m_oroundedTime && !this.roundedTime) {
                return true;
            }
            if (this.m_oinservice !== this.inService) {
                return true;
            }
            if (this.m_otimeclockId !== (this.timeclock ? this.timeclock.id : null)) {
                return true;
            }
            if (this.isEditedAndValidated) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LinePunch.prototype, "isGeoPunch", {
        get: function () {
            return this.punchSource === PunchSource.Geo;
        },
        enumerable: true,
        configurable: true
    });
    LinePunch.prototype.resetDirty = function () {
        this.m_otypeName = this.type ? this.type.name : null;
        this.m_otime = this.time;
        this.m_oroundedTime = this.roundedTime;
        this.m_oinservice = this.inService;
        this.m_otimeclockId = this.timeclock ? this.timeclock.id : null;
    };
    return LinePunch;
}());
export { LinePunch };
