import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { ValuePairChartModel } from '../../models/value-pair-chart/value-pair-chart-model';
import { ValuePairWidgetConfig } from '../../models/value-pair-widget/value-pair-widget-config';
import { ValuePairChartInput } from '../../models/value-pair-chart/value-pair-chart-input';
import { PairColorScheme } from '../../models/pair-color-scheme/pair-color-scheme';
import { ValuePairChartDataCalculationService } from './value-pair-chart-data-calculation.service';

@Injectable()
export class ValuePairChartDataService {

  public onConfigLoaded: ReplaySubject<ValuePairWidgetConfig>;
  public onModelLoaded: ReplaySubject<ValuePairChartModel>;

  // TODO: Stop using this method and instead use ValuePairChartDataCalculationService directly
  public static getColor(input: ValuePairChartInput, config: ValuePairWidgetConfig): PairColorScheme {
    return ValuePairChartDataCalculationService.getColor(input, config);
  }

  constructor() {
    this.onConfigLoaded = new ReplaySubject<ValuePairWidgetConfig>(1);
    this.onModelLoaded = new ReplaySubject<ValuePairChartModel>(1);
  }

  public setConfig(config: ValuePairWidgetConfig): void {
    this.onConfigLoaded.next(config);
  }

  public setModel(input: ValuePairChartInput, config: ValuePairWidgetConfig): void {
    const model = ValuePairChartDataCalculationService.createModel(input, config);
    this.onModelLoaded.next(model);
  }
}
