<div class="headless-modal-content">

  <div class="modal-header">
    <div class="txt-pad10">
      <span class="pull-left">Recalculate Accruals</span>
      <span class="pull-right btn-close" (click)="onClose()"><span><i class="fa fa-times" aria-hidden="true"></i></span></span>
    </div>
  </div>
  <div class="modal-body">
    <slx-spinner [show]="isLoading">
      <div class="col-xs-12 ">
        <div class="row pull-right head-btn">
          <button class="theme-button-apply btn-blue" (click)="onRefresh()" title="Refresh">
            <span>Refresh</span>
          </button>
          <button class="theme-button-apply btn-blue ml5" [disabled]="isRecalculateDisabled"
                  [ngClass]="{'disabledbtn' : isRecalculateDisabled}" [title]="recalculateTooltip" (click)="onRecalculate()">
            <span>Recalculate</span>
          </button>
        </div>
      </div>
      <kendo-grid #kendoGrid class="full-height slx-blue-grid slx-white-cells slx-cell-padding"
                  scrollable="'scrollable'"
                  [data]="gridState.view"
                  (dataStateChange)="gridState.dataStateChange($event)"
                  [sortable]="true"
                  [sort]="gridState.state.sort"
                  (sortChange)="sortChange($event)"
                  [groupable]="false"
                  [selectable]="false"
                  (selectionChange)="gridState.selectionChange($event)"
                  [slxKendoGridState]="gridState.gridDefinition"
                  (stateRestored)="gridState.dataStateChange($event)">
        <kendo-grid-column field="requestId" title="Id" width="25">
          <ng-template kendoGridHeaderTemplate>
            Id
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [title]="dataItem.requestId">{{ dataItem.requestId }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="organizationName" title="Org Level" width="60">
          <ng-template kendoGridHeaderTemplate>
            Org Level
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [title]="dataItem.organizationName">{{ dataItem.organizationName }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="createdBy" title="Requested By" width="50">
          <ng-template kendoGridHeaderTemplate>
            Requested By
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [title]="dataItem.createdBy">{{ dataItem.createdBy }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="requestDate" title="Requested Time" width="65">
          <ng-template kendoGridHeaderTemplate>
            Requested Time
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [title]="dataItem.requestDate | amDateFormat: appConfig.dateTimeFormatUS">{{ dataItem.requestDate | amDateFormat: appConfig.dateTimeFormatUS}}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="startDate" title="Start Time" width="65">
          <ng-template kendoGridHeaderTemplate>
            Start Time
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [title]="dataItem.startDate | amDateFormat: appConfig.dateTimeFormatUS">{{ dataItem.startDate | amDateFormat: appConfig.dateTimeFormatUS}}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="endDate" title="End Time" width="65">
          <ng-template kendoGridHeaderTemplate>
            End Time
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [title]="dataItem.endDate | amDateFormat: appConfig.dateTimeFormatUS">{{ dataItem.endDate | amDateFormat: appConfig.dateTimeFormatUS}}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="employeeCount" title="Num Employees" width="55">
          <ng-template kendoGridHeaderTemplate>
            Num Employees
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [title]="dataItem.employeeCount">{{ dataItem.employeeCount }}</span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </slx-spinner>
  </div>
  <div class="modal-footer center-align" (click)="onClose()">
    <div class="txt-pad10"><span><i class="fa fa-times" aria-hidden="true"></i>  Close</span></div>
  </div>

</div>
