<div class="left-col" >
  <button type="button" class="slx-button slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"
    (click)="onClickOverview()">
    <i aria-hidden="true" class="fas fa-eye slx-button__icon"></i>
    <span class="slx-button__text">Benefits Console</span>
  </button>
  <slx-switcher className="slx-switcher-green" [disabled]="isEditMode" [(ngModel)]="isShowingExpiredPlans"
    label="Show All" labelOn="Yes" labelOff="No" name="showExpiredPlans"></slx-switcher>
</div>
<slx-action-list>
  <slx-action-button class="slx-mobile-adapted" [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
  <popper-content #popperContent>
    <slx-action-list-item [disabled]="!canMakeRenew"
      [slxBenefitDetailsRenewDialog]="selectedProviderLine"
      [canDoAction]="canMakeRenew"
      (changedRenewedNewEndDate)="onRenewPlan($event)"
      [nextLineDetails]="nextLineDetails"
    >
      <i aria-hidden="true" class="fas fa-file-plus slx-action-list-item__icon"></i>
      Renew <em *ngIf="!enabledRenew">(No rights)</em>
      <em *ngIf="enabledRenew && !canRenew">(Available in Edit mode)</em>
    </slx-action-list-item>
    <slx-action-list-item [disabled]="!canMakeExpire"
      [slxBenefitDetailsExpireDialog]="selectedProviderLine"
      [canDoAction]="canMakeExpire"
      (changedExpiredNewEndDate)="onExpirePlan($event)"
      >
      <i aria-hidden="true" class="fas fa-calendar-times slx-action-list-item__icon"></i>
      Expire <em *ngIf="!enabledExpire">(No rights)</em>
      <em *ngIf="enabledExpire && !canExpire">(Available in Edit mode)</em>
    </slx-action-list-item>
    <slx-action-list-item [disabled]="!canMakeExtend"
      [slxBenefitDetailsExtendDialog]="selectedProviderLine"
      [benefitdeduction2]="benefitDeduction2"
      [canDoAction]="canMakeExtend"
      (changedExtendedNewEndDate)="onExtendPlan($event)"
      [nextLineDetails]="nextLineDetails"
    >
      <i aria-hidden="true" class="fas fa-clock slx-action-list-item__icon"></i>
      Extend <em *ngIf="!enabledExtend">(No rights)</em>
      <em *ngIf="enabledExtend && !canExtend">(Available in Edit mode)</em>
    </slx-action-list-item>
  </popper-content>
</slx-action-list>
