import { Injectable } from '@angular/core';
import { ExportDataMapService } from './export-data-map.service';
import { ExportDataConfigurationInfo, ExportDataExecutionStatus, ExportDataExecutionItem, IExportDataExecutionItem, IExportDataConfigurationInfo, IExportDataExecutionStatus } from '../models/index';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { ResponseBody, Meta } from '../../../core/models/index';
import { exportDataConfig } from '../export-data.config';
import { ExportDataApiServiceMock } from '../mocks/export-data-api.service.spec';
import { of } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ExportDataStatus } from '../enums/export-data-status';
import { ExportDataRequest } from '../models/export-data-request';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { appConfig } from '../../../app.config';

@Injectable()
export class ExportDataApiService {
  constructor(
    private mapService: ExportDataMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService
  ) {}

  public async getExportConfigurationList(orgLevelId: number): Promise<ExportDataConfigurationInfo[]> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.exports.exportConfigurations}/${orgLevelId}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<IExportDataConfigurationInfo[], Meta>(request)
      .then((response: ResponseBody<IExportDataConfigurationInfo[], Meta>) => this.mapService.mapToExportDataConfigurationList(<any>response));
  }

  public async getExportDataHistory(orgLevelId: number, configurationId: number): Promise<ExportDataExecutionItem[]> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.exports.exportDataHistory}/${orgLevelId}/${configurationId}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<IExportDataExecutionItem[], Meta>(request)
      .then((response: ResponseBody<IExportDataExecutionItem[], Meta>) => this.mapService.mapToExportDataHistoryList(<any>response));
  }

  public async getExportDataStatuses(orgLevelId: number, ids: string[]): Promise<ExportDataExecutionStatus[]> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.exports.exportDataStatuses}`;
    const request = this.urlParamsService.createPostRequest(url, { orgLevelId: orgLevelId, executionIds: ids });
    return this.apiUtilService.request<IExportDataExecutionStatus[], Meta>(request)
      .then((response: ResponseBody<IExportDataExecutionStatus[], Meta>) => this.mapService.mapToExportDataStatusList(<any>response));

  }

  public async generateExportData(req: ExportDataRequest): Promise<ExportDataExecutionItem> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.exports.generate}`;
    const request = this.urlParamsService.createPostRequest(url, req);

    return this.apiUtilService.request<IExportDataExecutionItem, Meta>(request)
      .then((response: ResponseBody<IExportDataExecutionItem, Meta>) => this.mapService.mapToExportDataHistory(<any>response));
  }

  public async downloadExportData(executionId: string): Promise<FileBlobResponse> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.exports.download}/${executionId}`;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public async cancelExportData(configurationId: number, executionId: string): Promise<ExportDataExecutionStatus> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.exports.cancel}/${executionId}`;
    const request = this.urlParamsService.createDeleteRequest(url);
    return this.apiUtilService.request<IExportDataExecutionStatus, Meta>(request)
      .then((response: ResponseBody<IExportDataExecutionStatus, Meta>) => this.mapService.mapToExportDataStatus(<any>response));
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version2}/${exportDataConfig.api.root}`;
  }
}
