import { Component, OnInit } from '@angular/core';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { Subscription } from 'rxjs';
import { ChildTreeView, TreeView } from '../../../../../components-library/models/tree-view';
import { unsubscribe } from '../../../../../core/decorators/index';
import { ApplicationStateBusService, OrgLevelWatchService } from '../../../../../organization/services';
import { OrgLevel } from '../../../../../state-model/models/index';
import * as _ from 'lodash';
import { MealDeductionManagementService } from '../../../services';
import { PbjNewExportHierarchyHelper } from '../../pbj-export/pbj-new-export/pbj-new-export-hierarchy-helper';

@Component({
  selector: 'slx-pbj-export-locations',
  templateUrl: './pbj-export-locations.component.html',
  styleUrls: ['./pbj-export-locations.component.scss']
})
export class PbjExportLocationsComponent extends PbjNewExportHierarchyHelper implements OnInit {
  public orgLevel: OrgLevel;
  public currentPath: OrgLevel[];
  selIds: Number[];
  orgIds = [];
  selOrgIds: any[];
  items: any;
  public orgHireacy: TreeView;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  config = TreeviewConfig.create({
    hasAllCheckBox: false,
  })
  constructor(private orgLevelWatchService: OrgLevelWatchService, private applicationStateBusService: ApplicationStateBusService, private managementService: MealDeductionManagementService) {
    super();
    this.orgLevelSubscription = this.applicationStateBusService.subscribeToSelectOrgLevel((o: OrgLevel) => {
      if (_.isObject(o) && _.isNumber(o.id) && (!this.orgLevel || this.orgLevel.id !== o.id)) {
        this.orgLevel = o;
        this.orgHireacy = this.setOrgHierarchy(o, 'mealConfigDialog');
        this.getAllMealConfigOrgIds(o);
        this.managementService.setSelectedIds(this.organizationIds);
       }
    });
   }

  ngOnInit() {
    this.items = this.getItems([this.orgHireacy]);
  }


  getItems(parentChildObj) {
    let itemsArray = [];
    parentChildObj.forEach(set => {
      itemsArray.push(new TreeviewItem(set))
    });
    return itemsArray;
  }



  onSelectAll()
  {
    this.checkAll(this.items)
  }

  checkAll(items) {
    items.forEach((element: any) => {
      element.checked = true;
      //element.collapsed = false;
      if (element.internalChildren) {
        this.checkAll(element.internalChildren);
      }
    });
    this.selOrgIds = this.getSelectedValuesRecursive(items);
    this.getAllMealConfigOrgIds(this.orgLevel);
    this.managementService.setSelectedIds(Array.from(new Set(this.organizationIds)));
  }

  getSelectedValuesRecursive(items) {
    const selectedValues = [];
    items.forEach(item => {
      if (item.checked) {
        selectedValues.push(item.value);
      }
      if (item.children) {
        const childSelectedValues = this.getSelectedValuesRecursive(item.children);
        selectedValues.push(...childSelectedValues);
      }
    });
    return selectedValues;
  }

  public getSelectedIds(selectedOrgIds){
    let orgIds = this.managementService.getSelectedIds();
    let modifiedOrgIds = selectedOrgIds.filter(orgId => orgIds.some(id => orgId == id) );
    this.managementService.setSelectedIds(null)
    this.managementService.setSelectedIds(modifiedOrgIds);
  }
}
