import { OrgLevelType } from './../../../../state-model/models/index';
import { ExceptionConsoleNavigationService } from './../../../../common/services/index';
import { Component, OnInit, OnDestroy, Input, Provider, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { NgForm } from '@angular/forms';
import { ScrollElementSelectorDirective } from '../../../../common/directives/index';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { IColumnSettings } from '../../../../core/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { timeAndAttendanceConfig } from '../../../time-and-attendance.config';
import { KendoGridStateHelper, ScrollWatchEvent } from '../../../../common/models/index';
import { ExceptionConsoleContainer, ExceptionConsoleRollupRecord, ExceptionConsoleState, IExceptionConsoleColumnPayload, ExceptionConsoleRollupState, ExceptionConsoleRecord } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ExceptionConsoleRollupManagementService } from '../../../services/exception-console/exception-console-rollup-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ExceptionConsoleRollupContainer } from '../../../models/exception-console/exceptions-console-rollup-container';

@Component({
  moduleId: module.id,
  selector: 'slx-exception-console-grid-rollup',
  templateUrl: 'exception-console-grid-rollup.component.html',
  styleUrls: ['exception-console-grid-rollup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExceptionConsoleGridRollupComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public container: ExceptionConsoleRollupContainer;
  public state: ExceptionConsoleRollupState;
  public gridState: KendoGridStateHelper<ExceptionConsoleRollupRecord>;
  public total: any = {};
  public orgLevelTitle: string;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  @ViewChild('form', {static: true})
  private form: NgForm;
  private managementService: ExceptionConsoleRollupManagementService;

  private aggregates: any = [
    { field: 'totalExceptionsCount', aggregate: 'sum' },
    { field: 'pendingMissingPunchesCount', aggregate: 'sum' },
    { field: 'approvedMissingPunchesCount', aggregate: 'sum' }
  ];


  @unsubscribe()
  private loadStateSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private recordsSelectedSubscription: Subscription;

  private changeDetector: ChangeDetectorRef;
  private gridHeight: number;
  private elementRef: ElementRef;
  private navService: ExceptionConsoleNavigationService;

  constructor(managementService: ExceptionConsoleRollupManagementService,
    changeDetector: ChangeDetectorRef, elementRef: ElementRef,
    router: Router, route: ActivatedRoute) {
    this.navService = new ExceptionConsoleNavigationService(router, route);
    this.managementService = managementService;
    this.changeDetector = changeDetector;
    this.gridState = new KendoGridStateHelper<ExceptionConsoleRollupRecord>();
    this.elementRef = elementRef;
  }


  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (container: ExceptionConsoleRollupContainer) => {
        this.container = container;
        this.orgLevelTitle = this.managementService.getOrgLevelTitle();
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.loadStateSubscription = this.managementService.onStateChanged$
      .subscribe((state: ExceptionConsoleRollupState) => {
        this.state = state;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

  }


  public onPaycycleClicked(item: ExceptionConsoleRollupRecord): void {
    // rollup grid component always used only in date ranges mode
    let orgLevelId: number = this.managementService.needShowDepartments ? item.department.orgLevelId : item.organization.orgLevelId;
    this.navService.navigateToExceptionConsoleDates(orgLevelId, item.startDate, item.endDate, false);
  }

  public onOrgLevelClicked(item: ExceptionConsoleRollupRecord): void {
    // rollup grid component always used only in date ranges mode
    let orgLevelId: number = this.managementService.needShowDepartments ? item.department.orgLevelId : item.organization.orgLevelId;
    this.navService.navigateToExceptionConsole(orgLevelId, false);
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get orgColumnField(): string {
    return this.managementService.needShowDepartments ? 'depName' : 'orgName';
  }

  public getOrgLevelName(item: ExceptionConsoleRollupRecord): string {
    if (this.managementService.needShowDepartments) {
      if (item.department) return item.department.name;
    } else {
      if (item.organization) return item.organization.name;
    }
    return '';
  }

  private refreshGrid(): void {
    if (!this.container) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.container.records, this.gridState.state);
    this.total = aggregateBy(this.gridState.view.data, this.aggregates);

    if (this.state.dynamicColumns && this.state.dynamicColumns.length > 0) {
      _.each(this.state.dynamicColumns, (c: IColumnSettings) => {
        this.total[c.name] = 0;
        _.each(this.gridState.view.data, (r: ExceptionConsoleRecord) => {
          this.total[c.name] += r.exceptionsMap[c.name].exceptionCount;
        });
      });
    }

  }
}

