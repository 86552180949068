import * as tslib_1 from "tslib";
import { mutableSelect } from './../../../../core/decorators/redux-decorators';
import { Observable } from 'rxjs/Observable';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { OpenShiftManagementApiService } from './../../../services/open-shift-management/open-shift-management-api.service';
import { EditOpenShiftCountComponent } from './../edit-open-shift-count/edit-open-shift-count.component';
import { OPEN_SHIFT_DETAILS_TOKEN } from './../../../../core/models/tokens';
import { DialogOptions } from './../../../../common/models/dialog-options';
import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { OpenShiftSummary } from './../../../models/open-shift-management/open-shift-summary';
import { KendoGridStateHelper } from './../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import * as _ from 'lodash';
import { OnDestroy, Provider } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
var DailyShiftSummaryGridComponent = /** @class */ (function () {
    function DailyShiftSummaryGridComponent(managementService, openShiftManagementApiService, modalService) {
        var _this = this;
        this.managementService = managementService;
        this.openShiftManagementApiService = openShiftManagementApiService;
        this.modalService = modalService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
        this.pageSize = 100;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.onShowFilledShiftsChanged = this.managementService.onShowFilledShiftsChanged$.subscribe(function (value) {
            _this.showFilledShifts = value;
            _this.refreshGrid();
        });
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            _this.selectedOrgLevel = o;
        });
    }
    Object.defineProperty(DailyShiftSummaryGridComponent.prototype, "summary", {
        get: function () {
            return this.m_summary;
        },
        set: function (value) {
            this.m_summary = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    DailyShiftSummaryGridComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    DailyShiftSummaryGridComponent.prototype.onEditOpenShiftsClick = function (groupDetails, event) {
        var _this = this;
        var shiftCount = groupDetails.openShiftCount;
        var options = new DialogOptions();
        options.height = 280;
        options.width = 320;
        var resolvedProvidersConf = [
            { provide: DialogOptions, useValue: options },
            { provide: OPEN_SHIFT_DETAILS_TOKEN, useValue: groupDetails },
        ];
        var modal = this.modalService.globalAnchor.openDialog(EditOpenShiftCountComponent, 'Adjust open shift count', options, resolvedProvidersConf, function (result, uniqueId) {
            if (result) {
                _this.changeOpenShiftCount(groupDetails, modal.adjustedShiftCount);
            }
        });
    };
    DailyShiftSummaryGridComponent.prototype.removeItem = function (item, groupDetails) {
        this.summary.details.filter(function (x) { return x === groupDetails; }).forEach(function (ele) {
            ele.messages = ele.messages.filter(function (y) { return y !== item; });
        });
        this.refreshGrid();
    };
    DailyShiftSummaryGridComponent.prototype.changeOpenShiftCount = function (groupDetails, value) {
        var _this = this;
        this.openShiftManagementApiService.setOpenShiftScheduleCycleSummary(this.selectedOrgLevel.id, groupDetails.dateOn, { positionId: groupDetails.position.id, shiftId: groupDetails.shift.id, unitId: groupDetails.unit.id, originalShiftCount: groupDetails.idealShiftCount, newShiftCount: value })
            .then(function (result) {
            var newOpen = value - groupDetails.scheduledShiftCount;
            newOpen = newOpen > 0 ? newOpen : 0;
            var shiftCountDiff = groupDetails.openShiftCount - newOpen;
            groupDetails.openShiftCount = newOpen;
            groupDetails.calculatedOpenShiftCount = newOpen;
            groupDetails.adjustedShiftCount = value;
            _this.managementService.onOpenShiftCountChanged({ dateOn: groupDetails.dateOn, shiftCountDiff: shiftCountDiff });
            if (groupDetails.hasPartialShift) {
                _this.managementService.onOpenShiftCountChangedByPartialShift({ dateOn: groupDetails.dateOn, shiftCountDiff: shiftCountDiff });
            }
        });
    };
    DailyShiftSummaryGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.summary) {
            this.gridState.view = null;
            return;
        }
        var details = this.summary.details.filter(function (o) { return o.parentShiftId == -1; }); //get only parent shifts
        //set partialShifts for the parent
        this.partialShifts = this.summary.details.filter(function (o) { return o.parentShiftId > 0; });
        _.forEach(details, function (shiftInDetails) {
            shiftInDetails.partialShifts = _this.getPartialShifts(shiftInDetails);
            if (shiftInDetails.partialShifts && shiftInDetails.partialShifts.length > 0) {
                //if(shiftInDetails.messages && shiftInDetails.messages.length>0) {
                shiftInDetails.partialShifts.push(shiftInDetails);
                //}
            }
        });
        if (!this.showFilledShifts) {
            details = _.filter(details, function (osd) { return osd.openShiftCount > 0 || osd.calculatedOpenShiftCount > 0; });
        }
        this.gridState.state.take = this.pageSize;
        this.gridState.view = process(details, this.gridState.state);
    };
    DailyShiftSummaryGridComponent.prototype.getPartialShifts = function (openShift) {
        //let partialShiftRequested = this.partialShifts.filter(p => p.parentShiftId === openShift.shift.id && p.unit.id === openShift.unit.id && p.messages.length>0);
        var partialShiftRequested = this.partialShifts.filter(function (p) { return p.parentShiftId === openShift.shift.id && p.unit.id === openShift.unit.id; });
        return partialShiftRequested;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], DailyShiftSummaryGridComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyShiftSummaryGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyShiftSummaryGridComponent.prototype, "onShowFilledShiftsChanged", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyShiftSummaryGridComponent.prototype, "orgLevelSubscription", void 0);
    return DailyShiftSummaryGridComponent;
}());
export { DailyShiftSummaryGridComponent };
