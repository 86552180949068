<slx-spinner [show]="state.isLoading">

  <form #form="ngForm" *ngIf="scheduleEntryContainer" novalidate>

    <section class="slx-content-toolbar-indents header">
      <div class="header-element date-element">
        <slx-input-decorator #datePickerContainer>
          <slx-date-picker-ngx slx-input slxChangeManagement slxDatePager
            [targetContainer]="datePickerContainer"
            [ngModel]="scheduleEntryContainer.date"
            (ngModelChange)="changeDateOn($event)"
            placeholder="Date" name="date"
          ></slx-date-picker-ngx>
        </slx-input-decorator>
      </div>
      <div class="header-element empName-element">
        <slx-input-decorator>
          <input slx-input type="text"
            [employeeSectionLink]="scheduleEntryContainer.employeeId"
            [readonly]="true"
            [(ngModel)]="scheduleEntryContainer.employeeName"
            placeholder="Employee Name" name="empName"
          />
        </slx-input-decorator>
      </div>
      <div class="header-element payroll-element">
        <slx-input-decorator>
          <input slx-input type="text"
            [readonly]="true"
            [(ngModel)]="scheduleEntryContainer.employeePayrollNumber"
            placeholder="Payroll No" name="payrollNumber"
          />
        </slx-input-decorator>
      </div>
      <div class="header-element empId-element">
        <slx-input-decorator>
          <input slx-input type="text"
            [readonly]="true"
            [(ngModel)]="scheduleEntryContainer.employeeId"
            placeholder="Employee Id" name="employeeId"
          />
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="errors">
        <span class="error-label" *ngIf="state.isOverlaping">Please, remove overlaps to save.</span>
        <span class="error-label" *ngIf="state.hasDuplicates">Please, remove duplicated shifts.</span>
      </div>
      <div class="actions-column">
        <button type="button" class="btn action-button" (click)="save(form)" [disabled]="!management.hasChanges || state.hasDuplicates || state.isOverlaping || form.invalid">
          <i class="fa fa-save" aria-hidden="true"></i>
          <span class="hidden-on-mobile">Save</span>
        </button>
        <button type="button" class="btn action-button" (click)="discard(form)" [disabled]="!management.hasChanges">
          <i class="fa fa-ban" aria-hidden="true"></i>
          <span class="hidden-on-mobile">Discard</span>
        </button>
        <button type="button" class="btn action-button hidden-on-mobile" slxBackLink>
          <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>Back
        </button>
      </div>
    </section>

    <div class="slx-main-content-indents">
      <section class="shifts-container">
        <div class="shifts">
          <slx-scheduled-shift class="shift-elt" [shift]="shift" [index]="idx" *ngFor="let shift of scheduleEntryContainer.shifts; let idx = index;"></slx-scheduled-shift>
          <div class="add-btn-holder">
            <button type="button" class="btn btn-default btn-ie add-shift-button" [disabled]="!scheduleEntryContainer" (click)="addNewShift()">
              <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Shift
            </button>
          </div>
        </div>
      </section>

      <section class="contacts">
        <p class="title">Contacts</p>
        <div class="contacts-info">
          <!--
          <div class="phone-actions">
            <button class="btn btn-default btn-rounded" type="button" aria-haspopup="true" aria-expanded="true">
              <i class="fa fa-phone fa-2x" aria-hidden="true"></i>
            </button>
            <button class="btn btn-default btn-rounded" type="button" aria-haspopup="true" aria-expanded="true">
              <i class="fas fa-comment-alt fa-2x" aria-hidden="true"></i>
            </button>
            <button class="btn btn-default btn-rounded" type="button" aria-haspopup="true" aria-expanded="true">
              <i class="far fa-sticky-note fa-2x" aria-hidden="true"></i>
            </button>
          </div>
          -->
          <button class="btn-ie btn btn-default" [employeeCallLogButton]="scheduleEntryContainer.employeeId" type="button" aria-expanded="true">
            <i class="fa fa-th-list" aria-hidden="true"></i> Call Log
          </button>
        </div>
      </section>
      <section>
        <p class="title">Time and Attendance</p>
        <div class="contacts-info">
          <button class="btn btn-default" type="button" aria-haspopup="true" aria-expanded="true" (click)="requestTALog()" >
            <i class="fa fa-calendar-check" aria-hidden="true"></i> TA Log
          </button>
        </div>
      </section>
   </div>
  </form>

</slx-spinner>
