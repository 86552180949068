import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { OrgLevel } from '../../../../state-model/models/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { W2sManagementService } from '../../../services/w2s/w2s-management.service';
import { ModalService, ConfirmOptions } from '../../../../common/index';
import { ConfirmDialog4Component } from '../../../../common/components/index';
import { CompanyCounts, IPublishType, W2sInfo, W2sRecord } from '../../../../../app/reports/models/w2s/w2s-model';
@Component({
  selector: 'slx-w2s-toolbar',
  templateUrl: './w2s-toolbar.component.html',
  styleUrls: ['./w2s-toolbar.component.scss']
})
export class W2sToolbarComponent implements OnInit, OnDestroy {
  public currentYear: number = moment().toDate().getFullYear();
  public defaultYear: number = 2022;
  public selectedYear: number = this.defaultYear;
  public defaultValue: number = this.defaultYear;
  public yearOptions: number[] = [];
  public isUnApproved:boolean = false;
  public isApproved:boolean = false;
  public isAuthorizedToPublish: boolean = false;
  public isAuthorizedToUnpublish: boolean = false;



  public readonly columnsStateName: string = 'W2sToolbar';

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  counts: CompanyCounts;
  companyCounts: number;
  finalizedCounts: number;
  inProgressCounts: number;

  constructor(
    private w2sManagementService: W2sManagementService,
    private modalService: ModalService,
  ) { }

  public ngOnInit(): void {
    this.createYearOptions();
    this.subscribeToOrgLevel();
    this.subscriptions.onPublish = this.w2sManagementService.subscribeToRecordsAlreadyPublished((publishedType: IPublishType) =>{
      if(publishedType.publish)
      {
        this.recordAlreadyPublished("published");
      } 
      if(publishedType.unPublish){
        this.recordAlreadyPublished("unpublished");
      }
    });

    this.subscriptions.onLoaded = this.w2sManagementService
    .subscribeToLoadedRecords((r: W2sInfo) => {
      let selectedRecords=_.filter(r.records,(item:W2sRecord)=>(item.w2Document.isApproved==0));
       this.isAuthorizedToPublish=r.publishW2sAction;
       this.isAuthorizedToUnpublish=r.unPublishW2sAction;   
      if(selectedRecords.length>0)
      {
        this.isUnApproved=true;
      }
      else
      {
        this.isUnApproved=false;
      }

      if(selectedRecords.length<r.records.length)
      {
        this.isApproved=true
      }
      else
      {
        this.isApproved=false;
      }
      
    });
    
    this.subscriptions.onLoaded = this.w2sManagementService
    .subscribeToLoadedCounts((c: CompanyCounts) => {
      this.companyCounts = c.companieswithW2Data;
      this.finalizedCounts = c.companiesFinalized;
      this.inProgressCounts = c.companiesinProgress;
    });
    
  }

 
  private recordAlreadyPublished(type: string): void {
    
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    options.isWarningIcon = true;
    ConfirmDialog4Component.openDialog(
      'Warning',
      `Form W-2 Statements have already been ${type}, please refresh your screen to see the details.`,
      this.modalService,
      (result: boolean) => {
        
      }, options);
  }

  onPublish() {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    options.isWarningIcon = false;
    options.notes = 'Please confirm you want to make these documents visible to employees.';
    ConfirmDialog4Component.openDialog(
      'Publish Form W-2 Statements',
      'Publishing Form W-2 Statements to your Employees will now make these documents visible to your employees in their Mobile App.',
      this.modalService,
      (isConfirm: boolean) => {
        if (isConfirm) {
          this.publishW2s();
        }
      },
      options);
      
  }

  onUnPublish() {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    options.isWarningIcon = true;
    options.notes = 'Please confirm you want to revoke the visibility of the Form W-2 Statements from the Employees.';
    ConfirmDialog4Component.openDialog(
      'Unpublish Form W-2 Statements',
      'Unpublishing Form W-2 Statements to your Employees will now revoke the visiblity from the employees in Smartlinx GO. Please note that employees may have already viewed and downloaded the document.',
      this.modalService,
      (isConfirm: boolean) => {
        if (isConfirm) {
          this.unPublishW2s();
        }
      },
      options);
      
  }


  public onYearChange(selYear: number): void {
    this.selectedYear = selYear;    
    if(this.defaultValue != selYear)
      this.w2sManagementService.loadW2s(this.orgLevel.id,this.selectedYear);
    this.defaultValue = 0;
    this.getCompanyCounts();
  }
  private createYearOptions(): void {
    this.yearOptions = _.values(_.range(this.defaultYear, this.currentYear+1));
  }

  private subscribeToOrgLevel(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.w2sManagementService.loadW2s(this.orgLevel.id,this.selectedYear);
    });
  }

  public publishW2s() : void {
   this.w2sManagementService.publishLoadedRecords(this.orgLevel.id,this.selectedYear);
  }

  public unPublishW2s() : void {
    this.w2sManagementService.unPublishLoadedRecords(this.orgLevel.id,this.selectedYear);
   }
  public ngOnDestroy(): void { }
  public get isAllowedPublish(): boolean {
    return this.isAuthorizedToPublish;
  }

  public get isAllowedUnublish(): boolean {
    return this.isAuthorizedToUnpublish;
  }
  
  public getCompanyCounts(){
    this.w2sManagementService.getCompanyCounts(this.orgLevel.id,this.selectedYear);
  }
}
