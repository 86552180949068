export var MasterScheduleRecordType;
(function (MasterScheduleRecordType) {
    MasterScheduleRecordType["Employee"] = "Employee";
    MasterScheduleRecordType["Subtotal"] = "Subtotal";
    MasterScheduleRecordType["Total"] = "Total";
})(MasterScheduleRecordType || (MasterScheduleRecordType = {}));
var MasterScheduleEntryRow = /** @class */ (function () {
    function MasterScheduleEntryRow() {
    }
    return MasterScheduleEntryRow;
}());
export { MasterScheduleEntryRow };
export function isMasterScheduleEntryRow(value) {
    if (!value) {
        return false;
    }
    return value.recordType === MasterScheduleRecordType.Employee;
}
var MasterScheduleSubtotalRow = /** @class */ (function () {
    function MasterScheduleSubtotalRow() {
    }
    Object.defineProperty(MasterScheduleSubtotalRow.prototype, "seniority", {
        get: function () {
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleSubtotalRow.prototype, "secondarySort", {
        get: function () {
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    return MasterScheduleSubtotalRow;
}());
export { MasterScheduleSubtotalRow };
export function isMasterScheduleSubtotalRow(value) {
    if (!value) {
        return false;
    }
    return value.recordType === MasterScheduleRecordType.Subtotal;
}
var MasterScheduleTotalRow = /** @class */ (function () {
    function MasterScheduleTotalRow() {
    }
    Object.defineProperty(MasterScheduleTotalRow.prototype, "seniority", {
        get: function () {
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleTotalRow.prototype, "secondarySort", {
        get: function () {
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    return MasterScheduleTotalRow;
}());
export { MasterScheduleTotalRow };
export function isMasterScheduleTotalRow(value) {
    if (!value) {
        return false;
    }
    return value.recordType === MasterScheduleRecordType.Total;
}
