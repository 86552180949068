import { Map } from 'immutable';
import { makeTypedMapFactory } from '../../framework/immutable-utils/index';

export const orgLevelFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  id: null,
  name: null,
  type: null,
  sequenceNumber: null,
  relatedItemId: null,
  parentId: null,
  isLoading: false,
  treeViewPath: [],
  treeViewNamePath: []
});

export const INITIAL_ORG_LEVEL_STATE: Map<string, any> = orgLevelFactory();
