import { state } from '@angular/animations';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  Inject,
  ElementRef,
  ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Assert } from '../../../../framework/index';
import { DOCUMENT } from '@angular/common';

import {
  OpenShiftDetails, EmployeeMessage,
  EmployeeMessageState, EmployeeMessageSource,
  EmployeeMessageDirection, IOpenShiftMessageCountChangedEventArgs,
  EmployeeMessageAction, MessagesThread
} from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { EmployeeMessagesApiService } from '../../../../employee/employee/services/index';
import { OpenShiftManagementManagementService } from '../../../../scheduler/services/index';
import { SendShiftSmsDialogComponent } from '../../../../employee/employee/components/index';
import { RecipientInfo, RecipientType, SendShiftSmsRequest } from '../../../../employee/employee/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { SessionService } from '../../../../core/services/index';
import { IUser } from '../../../../authentication/store/index';
import { PageScrollService, PageScrollInstance } from '../../../../common/index';
import {
  PageScrollConfig,
  PageScrollingViews,
  ScrollWatchAreaEvent,
  ScrollWatchAreaEventType
} from '../../../../common/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-shift-messages',
  templateUrl: 'daily-shift-messages.component.html',
  styleUrls: ['daily-shift-messages.component.scss']
})
export class DailyShiftMessagesComponent implements OnInit {

  @Input()
  public details: OpenShiftDetails;

  public state: {
    isInProgress: boolean;
  };
  public appConfig: IApplicationConfig;
  @ViewChild('contentContainer', {static: true})
  private container: ElementRef;
  private scrollContainer: PageScrollingViews;

  constructor(
    private employeeMessagesApiService: EmployeeMessagesApiService
    , private openShiftManagementManagementService: OpenShiftManagementManagementService
    , private modalService: ModalService
    , private sessionService: SessionService
    , private pageScrollService: PageScrollService
    , @Inject(DOCUMENT) private document: any) {
  }

  public ngOnInit(): void {
    this.state = {
      isInProgress: false
    };
    this.appConfig = appConfig;
    this.configure();
  }

  public onReadClicked(message: EmployeeMessage): any {
    this.state.isInProgress = true;
    this.employeeMessagesApiService.read(message.id).then((result: any) => {
      let messageCount: number = this.details.newMessageCount;
      message.state = EmployeeMessageState.read;
      let messageCountDiff: number = messageCount - this.details.newMessageCount;
      this.openShiftManagementManagementService.onMessageCountChanged({ dateOn: this.details.dateOn, messageCountDiff: messageCountDiff });
      this.state.isInProgress = false;
    })
      .catch((error: any) => {
        this.state.isInProgress = false;
      });
  }

  public onApproveClicked(message: EmployeeMessage, details: OpenShiftDetails): any {
    this.state.isInProgress = true;
    this.employeeMessagesApiService.approveShift(message.id, details.dateOn, message.senderId, details.shift.id, details.unit.id, details.position.id)
      .then((result: any) => {
        let messageCount: number = this.details.newMessageCount;
        message.state = EmployeeMessageState.scheduled;
        let messageCountDiff: number = messageCount - this.details.newMessageCount;
        this.openShiftManagementManagementService.onMessageCountChanged({ dateOn: this.details.dateOn, messageCountDiff: messageCountDiff });
        this.state.isInProgress = false;
      })
      .catch((error: any) => {
        this.state.isInProgress = false;
      });
  }
  public onReplyViaSmsClicked(message: EmployeeMessage, details: OpenShiftDetails): any {
    let recipientInfo: RecipientInfo =
      new RecipientInfo(message.senderId, message.senderName, message.senderMobilePhone, RecipientType.employee);
    let request: SendShiftSmsRequest =
      new SendShiftSmsRequest([recipientInfo], message.id, details.shift, details.unit, details.position, details.dateOn);
    let sendSmsDialog: SendShiftSmsDialogComponent = SendShiftSmsDialogComponent.openDialog(request, this.modalService, (result: boolean, sendRequest: SendShiftSmsRequest): void => {
      if (sendSmsDialog.dialogResult) {
        let sentMessage: EmployeeMessage = this.createMessageResponse(message, request, sendSmsDialog.newMessageId);
        let messagesThread: MessagesThread = details.getThreadByMessageId(message.id);
        messagesThread.messages.push(sentMessage);
        setTimeout(() => {
          let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInlineInstance(this.document, sentMessage.messageAnchor, this.scrollContainer, 0);
          this.pageScrollService.start(pageScrollInstance);
        }, 200);
      }
    });
  }

  public onDenyClicked(message: EmployeeMessage, details: OpenShiftDetails): any {
    this.state.isInProgress = true;
    this.employeeMessagesApiService.denyShift(message.id, details.dateOn, message.senderId, details.shift.id, details.unit.id, details.position.id).then((result: any) => {
      let messageCount: number = this.details.newMessageCount;
      message.state = EmployeeMessageState.closed;
      let messageCountDiff: number = messageCount - this.details.newMessageCount;
      this.openShiftManagementManagementService.onMessageCountChanged({ dateOn: this.details.dateOn, messageCountDiff: messageCountDiff });
      this.state.isInProgress = false;
    })
      .catch((error: any) => {
        this.state.isInProgress = false;
      });
  }

  private createMessageResponse(originalMessage: EmployeeMessage, request: SendShiftSmsRequest, messageId: number): EmployeeMessage {
    let newMessage: EmployeeMessage = new EmployeeMessage();
    let currentUser: IUser = this.sessionService.getUser();
    newMessage.id = messageId;
    newMessage.source = EmployeeMessageSource.sms;
    newMessage.text = request.messageText;
    newMessage.timestamp = moment().toDate();
    newMessage.direction = EmployeeMessageDirection.outbound;
    newMessage.receiverId = newMessage.senderId;
    newMessage.receiverName = newMessage.senderName;
    newMessage.senderMobilePhone = undefined;
    newMessage.senderId = currentUser.id;
    newMessage.senderName = currentUser.name;
    newMessage.state = EmployeeMessageState.sent;
    return newMessage;
  }

  private configure(): void {
    this.scrollContainer = this.container.nativeElement;
    PageScrollConfig.defaultScrollOffset = 110;
  }
}
