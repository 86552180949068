<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents">
      <slx-aca-measurement-toolbar></slx-aca-measurement-toolbar>
    </div>
    <div class="pad-insight">
      <slx-aca-measurement-insights></slx-aca-measurement-insights>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">

      <slx-aca-measurement-ag-grid></slx-aca-measurement-ag-grid>
    </div>
  </div>
</slx-spinner>
