export * from './employee-sections-profile/employee-sections-profile.component';
export * from './employee-sections-licenses/employee-sections-licenses.component';
export * from './employee-sections-physicals/employee-sections-physicals.component';
export * from './employee-sections-documents/employee-sections-documents.component';
export * from './employee-sections-warnings/employee-sections-warnings-entity/employee-sections-warnings-entity.component';
export * from './employee-sections-warnings/employee-sections-warnings-dialog/employee-sections-warnings-dialog.component';
export * from './employee-sections-warnings/employee-sections-warnings-violations/employee-sections-warnings-violations.component';
export * from './employee-sections-warnings/employee-sections-warnings-company-remarks/employee-sections-warnings-company-remarks.component';
export * from './employee-sections-warnings/employee-sections-warnings-employee-remarks/employee-sections-warnings-employee-remarks.component';
export * from './employee-sections-warnings/employee-sections-warnings-actions/employee-sections-warnings-actions.component';
export * from './employee-sections-warnings/employee-sections-warnings-violationsab/employee-sections-warnings-violationsab.component';
export * from './employee-sections-warnings/employee-sections-warnings-incident-description/employee-sections-warnings-incident-description.component';
export * from './employee-sections-warnings/employee-sections-warnings.component';

import { EmployeeSectionsProfileComponent } from './employee-sections-profile/employee-sections-profile.component';
import { EmployeeSectionsLicensesComponent } from './employee-sections-licenses/employee-sections-licenses.component';
import { EmployeeSectionsContactsComponent } from './employee-sections-contacts/employee-sections-contacts.component';
import { EmployeeSectionsEmergencyContactsComponent } from './employee-sections-emergency-contacts/employee-sections-emergency-contacts.component';
import { EmployeeSectionsPhysicalsComponent } from './employee-sections-physicals/employee-sections-physicals.component';
import { EmployeeSectionsDocumentsComponent } from './employee-sections-documents/employee-sections-documents.component';
import { EmployeeSectionsBackgroundCheckComponent } from './employee-sections-background-check/employee-sections-background-check.component';
import { EmployeeSectionsCertificationsComponent } from './employee-sections-certifications/employee-sections-certifications.component';
import { EmployeeSectionsEducationsComponent } from './employee-sections-educations/employee-sections-educations.component';
import { EmployeeSectionsWarningsEntityComponent } from './employee-sections-warnings/employee-sections-warnings-entity/employee-sections-warnings-entity.component';
import { EmployeeSectionsWarningsViolationsComponent } from './employee-sections-warnings/employee-sections-warnings-violations/employee-sections-warnings-violations.component';
import { EmployeeSectionsWarningsCompanyRemarksComponent } from './employee-sections-warnings/employee-sections-warnings-company-remarks/employee-sections-warnings-company-remarks.component';
import { EmployeeSectionsWarningsEmployeeRemarksComponent } from './employee-sections-warnings/employee-sections-warnings-employee-remarks/employee-sections-warnings-employee-remarks.component';
import { EmployeeSectionsWarningsActionsComponent } from './employee-sections-warnings/employee-sections-warnings-actions/employee-sections-warnings-actions.component';
import { EmployeeSectionsWarningsDialogComponent } from './employee-sections-warnings/employee-sections-warnings-dialog/employee-sections-warnings-dialog.component';
import { EmployeeSectionsWarningsViolationsABComponent } from './employee-sections-warnings/employee-sections-warnings-violationsab/employee-sections-warnings-violationsab.component';
import { EmployeeSectionsWarningsIncidentDescriptionComponent } from './employee-sections-warnings/employee-sections-warnings-incident-description/employee-sections-warnings-incident-description.component';
import { EmployeeSectionsWarningsComponent } from './employee-sections-warnings/employee-sections-warnings.component';

export const componentsPersonalSection: any[] = [
  EmployeeSectionsProfileComponent,
  EmployeeSectionsLicensesComponent,
  EmployeeSectionsContactsComponent,
  EmployeeSectionsEmergencyContactsComponent,
  EmployeeSectionsPhysicalsComponent,
  EmployeeSectionsDocumentsComponent,
  EmployeeSectionsBackgroundCheckComponent,
  EmployeeSectionsCertificationsComponent,
  EmployeeSectionsEducationsComponent,
  EmployeeSectionsCertificationsComponent,
  EmployeeSectionsWarningsComponent,
  EmployeeSectionsWarningsDialogComponent,
  EmployeeSectionsWarningsEntityComponent,
  EmployeeSectionsWarningsViolationsComponent,
  EmployeeSectionsWarningsCompanyRemarksComponent,
  EmployeeSectionsWarningsEmployeeRemarksComponent,
  EmployeeSectionsWarningsActionsComponent,
  EmployeeSectionsWarningsViolationsABComponent,
  EmployeeSectionsWarningsIncidentDescriptionComponent,
];
