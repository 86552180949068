import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { appConfig } from '../../../app.config';
import { DailyTimecardApiService } from './daily-timecard-api.service';
import { TimecardsApiService } from '../timecards/timecards-api.service';
import { DailyTimecardMapService } from './daily-timecard-map.service';
import { EmployeeDefinitionsApiService, LookupApiService } from '../../../organization/services/index';
import { ManagementBaseService } from '../../../core/services/index';
import { TimeCardDisplayOptions, DailyTimecardChangeStatus } from '../../models/index';
import { StateManagementService, ComponentStateStorageService } from '../../../common/services/index';
import { StateResetTypes } from '../../../core/models/index';
import { LinePunch, PunchStatus, punchWrapper, } from '../../models/index';
//import { KendoGridStateHelper } from 'src/app/common';
import { KendoGridStateHelper } from '../../../common/models/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../common/index';
import { ModalService } from '../../../common/services/index';
var DailyTimecardManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(DailyTimecardManagementService, _super);
    function DailyTimecardManagementService(api, timecardsApi, mapService, empDefApi, lookupService, stateManagement, storageService, modalService) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.timecardsApi = timecardsApi;
        _this.mapService = mapService;
        _this.empDefApi = empDefApi;
        _this.lookupService = lookupService;
        _this.stateManagement = stateManagement;
        _this.storageService = storageService;
        _this.modalService = modalService;
        _this.displayOptionsControlKey = 'timecardDisplayOptions';
        _this.updateScheduleOptionControlKey = 'updateScheduleOption';
        _this.prevFirstPunch = null;
        _this.prevLastPunch = null;
        _this.nextFirstPunch = null;
        _this.nextLastPunch = null;
        _this.sectionEditAction$ = new ReplaySubject(1);
        _this.exportedTimecard$ = new ReplaySubject(1);
        _this.canEditChanged$ = new ReplaySubject(1);
        _this.hasOverlap$ = new ReplaySubject(1);
        _this.setHasOverlapToTC$ = new ReplaySubject(1);
        _this.overlapingPunchesAction$ = new ReplaySubject(1);
        _this.overlapPunchesDesc$ = new ReplaySubject(1);
        _this.changedDate$ = new ReplaySubject(1);
        _this.resetSection$ = new ReplaySubject(1);
        _this.displayOptionSelected$ = new ReplaySubject(1);
        _this.navigateToUnApprovedPC$ = new Subject();
        _this.navigateToUnApprovedOT$ = new Subject();
        _this.abscenceCount$ = new Subject();
        _this.isPaidRestBreakEnabled$ = new ReplaySubject(1);
        _this.stateManagement.init('DailyTimeCardComponent', true);
        _this.restoreLastUpdateScheduleOption();
        return _this;
    }
    DailyTimecardManagementService.prototype.setRouteData = function (empId, dateOn) {
        this.employeeId = empId;
        this.dateOn = dateOn;
        this.getTimecard(false);
    };
    DailyTimecardManagementService.prototype.changeDate = function (date) {
        this.changedDate$.next(date);
    };
    DailyTimecardManagementService.prototype.setHasOverlapToTC = function (hasOverlap) {
        this.setHasOverlapToTC$.next(hasOverlap);
    };
    DailyTimecardManagementService.prototype.onSaveTimecard = function () {
        this.saveTimecard();
    };
    DailyTimecardManagementService.prototype.onExportTimecard = function () {
        this.exportTimecard();
    };
    DailyTimecardManagementService.prototype.canEditChanged = function (canEdit) {
        this.canEditChanged$.next(canEdit && this.dataModel.canEditOwnTimecard);
    };
    DailyTimecardManagementService.prototype.setHasOverlap = function (hasOverlap) {
        this.hasOverlap$.next(hasOverlap);
    };
    DailyTimecardManagementService.prototype.overlapingPunchesAction = function (event) {
        this.overlapingPunchesAction$.next(event);
    };
    DailyTimecardManagementService.prototype.overlapPunchesDesc = function (event) {
        this.overlapPunchesDesc$.next(event);
    };
    DailyTimecardManagementService.prototype.onPunchesDiscardAction = function () {
        this.dataModel.dailyPunches = _.cloneDeep(this.cleanDatamodel.dailyPunches);
        this.resetSection$.next({ target: 'punches', model: this.dataModel });
        this.onPunchesEditAction(false);
    };
    DailyTimecardManagementService.prototype.onAbsenceDiscardAction = function () {
        this.dataModel.absences = _.cloneDeep(this.cleanDatamodel.absences);
        this.resetSection$.next({ target: 'absences', model: this.dataModel });
        this.onAbsenceEditAction(false);
    };
    DailyTimecardManagementService.prototype.onPayCodesDiscardAction = function () {
        this.dataModel.payRules = _.cloneDeep(this.cleanDatamodel.payRules);
        this.resetSection$.next({ target: 'paycodes', model: this.dataModel });
        this.onPayCodesEditAction(false);
    };
    DailyTimecardManagementService.prototype.onPunchesEditAction = function (lockOthers) {
        this.sectionEditAction$.next({ source: 'punches', lockOthers: lockOthers });
    };
    DailyTimecardManagementService.prototype.onAbsenceEditAction = function (lockOthers) {
        this.sectionEditAction$.next({ source: 'absences', lockOthers: lockOthers });
    };
    DailyTimecardManagementService.prototype.onPayCodesEditAction = function (lockOthers) {
        this.sectionEditAction$.next({ source: 'paycodes', lockOthers: lockOthers });
    };
    DailyTimecardManagementService.prototype.unapproveTimecard = function () {
        this.dataModel.isApproved = false;
        this.dataModel.isLoadedApproved = false;
        this.canEditChanged(this.dataModel.canEditTimecard);
    };
    DailyTimecardManagementService.prototype.approveTimecard = function () {
        this.dataModel.isApproved = true;
        this.dataModel.isLoadedApproved = false;
        this.canEditChanged(this.dataModel.canEditTimecard);
    };
    DailyTimecardManagementService.prototype.selectDisplayOption = function (option) {
        this.saveDisplayOptions(option);
        this.displayOptionSelected$.next(option);
    };
    DailyTimecardManagementService.prototype.discardPunches = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.discardPunches(this.dateOn, this.employeeId)
            .then(function (res) {
            if (res && res.data && res.data.errorNumber == 1 && moment(res.data.punchDate).isValid()) {
                _this.onLoadStatusChanged(false);
                _this.preventOverlappingPunchesModal(res.data.punchDate);
            }
            else {
                _this.getTimecard(false);
            }
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    DailyTimecardManagementService.prototype.discardPayRules = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.discardPayRules(this.dateOn, this.employeeId)
            .then(function (res) {
            _this.getTimecard(false);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    DailyTimecardManagementService.prototype.saveLastUpdateScheduleOption = function (value) {
        this.lastUpdateScheduleSelection = value;
        this.storageService.setControlState(this.stateManagement.componentKey, this.updateScheduleOptionControlKey, { value: value }, StateResetTypes.None);
    };
    DailyTimecardManagementService.prototype.navigateToUnapprovedPaycodes = function () {
        this.navigateToUnApprovedPC$.next(null);
    };
    DailyTimecardManagementService.prototype.navigateToUnapprovedOvertimes = function (homeOrgLevelId) {
        this.navigateToUnApprovedOT$.next(homeOrgLevelId);
    };
    DailyTimecardManagementService.prototype.updateAbscenseCount = function (count) {
        this.abscenceCount$.next(count);
    };
    DailyTimecardManagementService.prototype.getAbsencescount = function (absencesList) {
        var count = 0;
        _.forEach(absencesList, function (ele) {
            if (ele.isDeleted === false) {
                count = count + 1;
            }
        });
        return count;
    };
    DailyTimecardManagementService.prototype.recalculateTimecard = function () {
        var _this = this;
        this.cleanupModel();
        this.onLoadStatusChanged(true);
        this.dataModelBeforeRecalculation = _.cloneDeep(this.dataModel);
        this.timecardsApi.recalculateTimecardsByRange([this.employeeId], this.dateOn, this.dateOn)
            .then(function (param) {
            _this.onLoadStatusChanged(false);
            _this.getTimecard(true);
        }).catch(function (reason) {
            _this.onError(reason);
            throw new Error(reason);
        });
    };
    DailyTimecardManagementService.prototype.lockTimecard = function (model) {
        return this.callLockApi(model, true);
    };
    DailyTimecardManagementService.prototype.unlockTimecard = function (model) {
        return this.callLockApi(model, false);
    };
    DailyTimecardManagementService.prototype.callLockApi = function (model, lock) {
        var _this = this;
        this.onLoadStatusChanged(true);
        return this.api.lockTimecard(model.employee.id, model.date, lock)
            .then(function (value) {
            _this.onLoadStatusChanged(false);
            _this.timecardsApi.clearIndividualTimecardsCacheData();
            if (value) {
                _this.getTimecard(false);
            }
            return value;
        });
    };
    DailyTimecardManagementService.prototype.getTimecardPunches = function (punchDate, empId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        return this.api.getDailyTimecardForOverlap(empId, punchDate)
            .then(function (model) {
            _this.onLoadStatusChanged(false);
            return model;
        }).catch(function (reason) {
            _this.onError(reason);
            _this.onLoadStatusChanged(false);
            return null;
        });
    };
    DailyTimecardManagementService.prototype.getAllAbsence = function (date) {
        var _this = this;
        this.onLoadStatusChanged(true);
        return this.api.getAllAbsence(this.employeeId, date)
            .then(function (model) {
            _this.onLoadStatusChanged(false);
            return model;
        }).catch(function (reason) {
            _this.onError(reason);
            _this.onLoadStatusChanged(false);
        });
    };
    DailyTimecardManagementService.prototype.getTimecard = function (recalculate) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.getDailyTimecard(this.employeeId, this.dateOn)
            .then(function (model) {
            _this.canLock = model.canLock;
            _this.canUnlock = model.canUnlock;
            _this.cleanDatamodel = model;
            _this.prepareAdditionalInfo(model, recalculate);
            _this.isPaidRestBreakEnabled$.next(model.isPaidRestBreakEnabled);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    DailyTimecardManagementService.prototype.prepareAdditionalInfo = function (model, recalculate) {
        var _this = this;
        var clonedModel = this.mapService.mapTimcard(model.rawData);
        this.dataModel = clonedModel;
        if (recalculate) {
            this.recalculateDiff();
        }
        this.restoreDisplayOptions();
        this.loadEmployeeShortInfo(this.employeeId)
            .then(function (empInfo) {
            _this.employeeShortInfo = empInfo;
            _this.dataModel.activationDate = empInfo.activationDate;
            _this.dataModel.terminationDate = empInfo.terminationDate;
            _this.dataModel.transferDepartmentId = empInfo.transferDepartmentId;
            _this.getAbsencesAndRestore(_this.employeeId, _this.dataModel.absences);
            _this.getShifts(_this.employeeId);
            _this.onLoaded(_this.dataModel);
            _this.onLoadStatusChanged(false);
            _this.canEditChanged(_this.dataModel.canEditTimecard);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    DailyTimecardManagementService.prototype.recalculateDiff = function () {
        var _this = this;
        if (!this.dataModelBeforeRecalculation) {
            return;
        }
        this.dataModel.recalculated = true;
        _.forEach(this.dataModel.earningStatistics, function (earning) {
            var prior = _.find(_this.dataModelBeforeRecalculation.earningStatistics, function (e) {
                return earning.id === e.id;
            });
            if (prior) {
                if (earning.amount === prior.amount && earning.interval === prior.interval) {
                    earning.changeStatus = DailyTimecardChangeStatus.nochanges;
                }
                else {
                    earning.changeStatus = DailyTimecardChangeStatus.changed;
                }
                _.remove(_this.dataModelBeforeRecalculation.earningStatistics, function (e) {
                    return earning.id === e.id;
                });
            }
            else {
                earning.changeStatus = DailyTimecardChangeStatus.added;
            }
        });
        _.forEach(this.dataModelBeforeRecalculation.earningStatistics, function (earning) {
            earning.changeStatus = DailyTimecardChangeStatus.deleted;
            _this.dataModel.earningStatistics.push(earning);
        });
        this.dataModelBeforeRecalculation = null;
    };
    DailyTimecardManagementService.prototype.loadEmployeeShortInfo = function (employeeId) {
        return this.empDefApi.getEmployeeShortInfoCached(employeeId);
    };
    DailyTimecardManagementService.prototype.saveTimecard = function () {
        var _this = this;
        this.cleanupModel();
        this.dataModelBeforeRecalculation = _.cloneDeep(this.dataModel);
        var dateOn = moment(this.dataModel.date).format(appConfig.requestDate);
        this.onLoadStatusChanged(true);
        this.api.saveTimecard(this.dataModel, dateOn)
            .then(function (model) {
            _this.timecardsApi.clearIndividualTimecardsCacheData();
            _this.cleanDatamodel = model;
            _this.cleanDatamodel.canUnlock = _this.canUnlock;
            _this.cleanDatamodel.canLock = _this.canLock;
            _this.prepareAdditionalInfo(_this.cleanDatamodel, true);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    DailyTimecardManagementService.prototype.cleanupModel = function () {
        _.remove(this.dataModel.earningStatistics, function (earning) { return earning.changeStatus === DailyTimecardChangeStatus.deleted; });
        _.remove(this.dataModel.payRules, function (payCode) { return payCode.changeStatus === DailyTimecardChangeStatus.deleted; });
    };
    DailyTimecardManagementService.prototype.exportTimecard = function () {
        var _this = this;
        this.cleanupModel();
        this.onLoadStatusChanged(true);
        var dateOn = moment(this.dataModel.date).format(appConfig.requestDate);
        this.api.exportTimecard(this.dataModel, dateOn)
            .then(function (file) {
            _this.exportedTimecard$.next(file);
            _this.onLoadStatusChanged(false);
        }).catch(function (reason) {
            _this.onError(reason);
            throw new Error(reason);
        });
    };
    DailyTimecardManagementService.prototype.saveDisplayOptions = function (option) {
        this.storageService.setControlState(this.stateManagement.componentKey, this.displayOptionsControlKey, { value: option }, StateResetTypes.None);
    };
    DailyTimecardManagementService.prototype.restoreDisplayOptions = function () {
        var option;
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.displayOptionsControlKey);
        if (!state.value) {
            option = TimeCardDisplayOptions.showIntervalsInDecimal;
        }
        else {
            option = state.value;
        }
        this.displayOptionSelected$.next(option);
    };
    DailyTimecardManagementService.prototype.restoreLastUpdateScheduleOption = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.updateScheduleOptionControlKey);
        this.lastUpdateScheduleSelection = !!state.value;
    };
    DailyTimecardManagementService.prototype.getShifts = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.shiftsLookup) {
                            return [2 /*return*/];
                        }
                        _a = this;
                        return [4 /*yield*/, this.lookupService.getShifts(employeeId)];
                    case 1:
                        _a.shiftsLookup = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DailyTimecardManagementService.prototype.getAbsencesAndRestore = function (employeeId, records) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (this.absenceCodesLookup) {
                            this.restoreAbsenceCodes(records);
                            return [2 /*return*/, this.absenceCodesLookup];
                        }
                        _a = this;
                        return [4 /*yield*/, this.lookupService.getTaAbsenceCodes(employeeId)];
                    case 1:
                        _a.absenceCodesLookup = _b.sent();
                        this.restoreAbsenceCodes(records);
                        return [2 /*return*/, this.absenceCodesLookup];
                }
            });
        });
    };
    DailyTimecardManagementService.prototype.restoreAbsenceCodes = function (records) {
        var _this = this;
        _.forEach(records, function (item) {
            var code = _.find(_this.absenceCodesLookup, function (c) {
                return c.id === item.id;
            });
            if (code) {
                item.absence = code;
                item.checkHasLinkedSa();
                if (!item.hasLinkedSa) {
                    item.updateSchedule = false;
                }
                else {
                    item.updateSchedule = _this.lastUpdateScheduleSelection;
                }
            }
        });
    };
    DailyTimecardManagementService.prototype.hasOverlap = function (matchedPair, records, defaultPunchTime, employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.employeeId)
                            this.employeeId = employeeId;
                        return [4 /*yield*/, this.getTimecardPunches(matchedPair.inpunch.punch.time, this.employeeId).then(function (data) {
                                var _dailyPunches = _.filter(data.dailyPunches, function (x) { return moment(x.punchDate).format(appConfig.linkDateFormat) != moment(defaultPunchTime).format(appConfig.linkDateFormat); });
                                var hasOverlapPunches = false;
                                var pairs = [];
                                var inp = null, oup = null;
                                _.forEach(_dailyPunches, function (x) {
                                    if (x.type.name == 'in' || x.type.name == 'in after lunch' || x.type.name == 'in after break' || x.type.name == 'training start') {
                                        inp = x;
                                    }
                                    else if (x.type.name == 'out' || x.type.name == 'out to lunch' || x.type.name == 'out to break' || x.type.name == 'training end') {
                                        oup = x;
                                        if (inp && oup) {
                                            pairs.push({ inpunch: inp, outpunch: oup });
                                        }
                                        inp = null;
                                        oup = null;
                                    }
                                });
                                var overlapIndex = -1;
                                var _overlapType = 'inOverlap';
                                _.forEach(pairs, function (existing) {
                                    var res = _this.containsOverlap(existing, matchedPair, defaultPunchTime);
                                    if (res.result) {
                                        hasOverlapPunches = true;
                                        _overlapType = res.overlapType;
                                        if (res.overlapType == "both") {
                                            var indiff = moment(existing.inpunch.punchDate).format(appConfig.linkDateFormat) == moment(matchedPair.inpunch.punch.time).format(appConfig.linkDateFormat);
                                            _this.currentOverlapDate = indiff ? (matchedPair.inpunch.punch.time) : matchedPair.outpunch.punch.time;
                                            overlapIndex = _.findIndex(records, function (x) { return x.punch.time.toString() == matchedPair.inpunch.punch.time.toString() && x.id == matchedPair.inpunch.id; });
                                            if (records[overlapIndex] && records[overlapIndex].punch) {
                                                records[overlapIndex].punch.isEditedAndValidated = true;
                                                records[overlapIndex].punch.punchStatus = PunchStatus.Overlap;
                                            }
                                            if (records[overlapIndex + 1] && records[overlapIndex + 1].punch && records[overlapIndex + 1].punch.punchDisplay != "Schedule" && records[overlapIndex + 1].punch.punchSource != "Schedule") {
                                                records[overlapIndex + 1].punch.isEditedAndValidated = true;
                                                records[overlapIndex + 1].punch.punchStatus = PunchStatus.Overlap;
                                            }
                                        }
                                        else if (res.overlapType == "inOverlap") {
                                            _this.currentOverlapDate = (matchedPair.inpunch.punch.time);
                                            overlapIndex = _.findIndex(records, function (x) { return x.punch.time.toString() == matchedPair.inpunch.punch.time.toString() && x.id == matchedPair.inpunch.id; });
                                            if (records[overlapIndex] && records[overlapIndex].punch) {
                                                records[overlapIndex].punch.isEditedAndValidated = true;
                                                records[overlapIndex].punch.punchStatus = PunchStatus.Overlap;
                                            }
                                            if (records[overlapIndex + 1] && records[overlapIndex + 1].punch && records[overlapIndex + 1].punch.punchStatus == PunchStatus.Overlap)
                                                records[overlapIndex + 1].punch.punchStatus = PunchStatus.Edited;
                                        }
                                        else {
                                            _this.currentOverlapDate = matchedPair.outpunch.punch.time;
                                            overlapIndex = _.findIndex(records, function (x) { return x.punch.time.toString() == matchedPair.outpunch.punch.time.toString() && x.id == matchedPair.outpunch.id; });
                                            if (records[overlapIndex] && records[overlapIndex].punch) {
                                                records[overlapIndex].punch.isEditedAndValidated = true;
                                                records[overlapIndex].punch.punchStatus = PunchStatus.Overlap;
                                            }
                                            if (records[overlapIndex - 1] && records[overlapIndex - 1].punch && records[overlapIndex - 1].punch.punchStatus == PunchStatus.Overlap)
                                                records[overlapIndex - 1].punch.punchStatus = PunchStatus.Edited;
                                        }
                                        return false;
                                    }
                                });
                                if (hasOverlapPunches) {
                                    _this.setHasOverlap(true);
                                    _this.preventOverlappingPunchesModal(_this.currentOverlapDate);
                                    return { hasOverlapPunches: hasOverlapPunches, records: records };
                                }
                                else {
                                    _this.setHasOverlap(false);
                                    return { hasOverlapPunches: hasOverlapPunches, records: records };
                                }
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DailyTimecardManagementService.prototype.containsOverlap = function (existing, matchedPair, defaultPunchTime) {
        var isSameDay = (moment(matchedPair.inpunch.punch.punchDate).isSame(defaultPunchTime) && moment(matchedPair.outpunch.punch.punchDate).isSame(defaultPunchTime) &&
            moment(existing.inpunch.punchDate).isSame(defaultPunchTime) && moment(existing.outpunch.punchDate).isSame(defaultPunchTime));
        if (isSameDay) {
            return { result: !isSameDay, overlapType: "inOverlap" };
        }
        var res = (moment(matchedPair.inpunch.punch.time).isSameOrBefore(existing.inpunch.time) && moment(existing.outpunch.time).isBefore(matchedPair.outpunch.punch.time) ||
            moment(existing.inpunch.time).isSame(matchedPair.inpunch.punch.time) && moment(matchedPair.outpunch.punch.time).isSame(existing.outpunch.time));
        if (res) {
            return { result: res, overlapType: "both" };
        }
        else {
            res = (moment(existing.inpunch.time).isSameOrBefore(matchedPair.inpunch.punch.time) && moment(matchedPair.outpunch.punch.time).isBefore(existing.outpunch.time) ||
                moment(existing.inpunch.time).isBefore(matchedPair.inpunch.punch.time) && moment(existing.outpunch.time).isSameOrBefore(matchedPair.outpunch.punch.time) && moment(matchedPair.inpunch.punch.time).isBefore(existing.outpunch.time));
            if (res) {
                return { result: res, overlapType: "inOverlap" };
            }
            else {
                res = (moment(matchedPair.inpunch.punch.time).isBefore(existing.inpunch.time) && moment(matchedPair.outpunch.punch.time).isSameOrBefore(existing.outpunch.time) && moment(existing.inpunch.time).isBefore(matchedPair.outpunch.punch.time));
                return { result: res, overlapType: "outOverlap" };
            }
        }
    };
    DailyTimecardManagementService.prototype.checkOverlap = function (defaultPunchTime, records, hasOverlapPunches, overlapIndex, gridState, hasChanges, employeeId, item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getTimecardPunches(defaultPunchTime, employeeId).then(function (data) {
                            if (item != undefined) {
                                _.sortBy(data.dailyPunches, function (x) { return x.time; });
                                records = gridState.view.data;
                                var ind = gridState.view.data.findIndex(function (x) { return x.id === item.id && x.punch.time.valueOf() === item.punch.time.valueOf(); });
                                if (ind != 0) {
                                    if ((ind != records.length - 1)) {
                                        return { hasOverlapPunches: hasOverlapPunches, records: records };
                                    }
                                }
                            }
                            var prevDate = moment(defaultPunchTime).subtract(1, 'day').toDate();
                            //var currentDate = moment(defaultPunchTime).toDate();
                            var nextDate = moment(defaultPunchTime).add(1, 'day').toDate();
                            var prevDay = [];
                            var nextDay = [];
                            var prevDayInd = _this.findPunchIndex(data, prevDate);
                            if (prevDayInd >= 0) {
                                _this.filterPunches(data, prevDate, prevDay);
                            }
                            else {
                                prevDate = moment(prevDate).subtract(1, 'day').toDate();
                                _this.filterPunches(data, prevDate, prevDay);
                            }
                            var nextDayInd = _this.findPunchIndex(data, nextDate);
                            if (nextDayInd >= 0) {
                                _this.filterPunches(data, nextDate, nextDay);
                            }
                            else {
                                nextDate = moment(nextDate).add(1, 'day').toDate();
                                _this.filterPunches(data, nextDate, nextDay);
                            }
                            if (prevDay.length > 0) {
                                _this.prevFirstPunch = prevDay[0];
                                _this.prevLastPunch = prevDay.slice(-1).pop();
                                _this.prevFirstPunchTime = _this.prevFirstPunch.time;
                                _this.prevLastPunchTime = _this.prevLastPunch.time;
                            }
                            _this.currentFirstPunch = gridState.view.data[0];
                            _this.currentLastPunch = gridState.view.data.slice(-1).pop();
                            _this.currentFirstPunchTime = _this.currentFirstPunch.punch.time;
                            _this.currentLastPunchTime = _this.currentLastPunch.punch.time;
                            if (nextDay.length > 0) {
                                _this.nextFirstPunch = nextDay[0];
                                _this.nextLastPunch = nextDay.slice(-1).pop();
                                _this.nextFirstPunchTime = _this.nextFirstPunch.time;
                                _this.nextLastPunchTime = _this.nextLastPunch.time;
                            }
                            hasOverlapPunches = false;
                            _this.setHasOverlap(false);
                            if (prevDay.length > 0) {
                                if (moment(_this.currentFirstPunchTime.valueOf()).isBefore(_this.prevLastPunchTime.valueOf())) {
                                    hasOverlapPunches = true;
                                    _this.actualOverlapIndex = 0;
                                    _this.currentOverlapDate = prevDate;
                                }
                            }
                            if (!hasOverlapPunches && ind != 0) {
                                if (nextDay.length > 0) {
                                    if (moment(_this.nextFirstPunchTime.valueOf()).isBefore(_this.currentLastPunchTime.valueOf())) {
                                        hasOverlapPunches = true;
                                        _this.actualOverlapIndex = records.length - 1;
                                        _this.currentOverlapDate = nextDate;
                                    }
                                }
                            }
                            if (records[ind] && records[ind].punch) {
                                records[ind].punch.punchStatus = PunchStatus.Edited;
                            }
                            if (hasOverlapPunches) {
                                var ind = _this.actualOverlapIndex;
                                overlapIndex = _this.actualOverlapIndex;
                                hasChanges = true;
                                records[ind].punch.isEditedAndValidated = true;
                                records[ind].punch.punchStatus = PunchStatus.Overlap;
                                _this.setHasOverlap(true);
                                _this.preventOverlappingPunchesModal(_this.currentOverlapDate);
                                return { hasOverlapPunches: hasOverlapPunches, records: records };
                            }
                            else {
                                return { hasOverlapPunches: hasOverlapPunches, records: records };
                            }
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DailyTimecardManagementService.prototype.preventOverlappingPunchesModal = function (value) {
        var newDate = moment(value).format(appConfig.dateFormat);
        var options = new ConfirmOptions();
        options.showOK = true;
        options.buttonOKtext = 'OK';
        ConfirmDialogComponent.openDialog('Overlapping Punches', "The punch edits on this timecard overlap with an existing entry on the timecard for - " + newDate, this.modalService, function (result) { }, options);
    };
    DailyTimecardManagementService.prototype.filterPunches = function (data, date, filteredData) {
        return data.dailyPunches.filter(function (x) {
            if (x.punchDate.valueOf() == date.valueOf()) {
                filteredData.push(x);
            }
        });
    };
    DailyTimecardManagementService.prototype.findPunchIndex = function (data, date) {
        var punchIndex = data.dailyPunches.findIndex(function (x) { return x.punchDate.valueOf() == date.valueOf(); });
        return punchIndex;
    };
    return DailyTimecardManagementService;
}(ManagementBaseService));
export { DailyTimecardManagementService };
