<div modalAnchor class="enrolled-anchor"></div>
<slx-spinner [show]="isLoading">
  <div class="slx-high-box benefit-employees-eligible ">
    <div class="slx-content-toolbar-indents flex alighn-items__center justify-content__flex-end">
      <button type="button" class="slx-button slx-blue drop-btn" (click)="dropEmployeeBenefit()" [disabled]="selectedEmployees.size === 0 || canEnroll">
        Drop Benefit Coverage
      </button>
      <div class="benefit-action-align">
        <slx-actions-list>
          <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">
            Actions <span class="caret"></span>
          </slx-actions-button>
          <popper-content #popperContent>
            <slx-actions-list-item (onClick)="exportToExcel()" [disabled]="!records || (records.length === 0)"> Export to Excel </slx-actions-list-item>
            <slx-actions-list-item (onClick)="exportToPdf()" [disabled]="!records || (records.length === 0)"> Export to PDF </slx-actions-list-item>
          </popper-content>
        </slx-actions-list>
      </div>
    </div>
    <div class="slx-high-box__body slx-main-content-indents ben-employee-grid">
      <form #form="ngForm" novalidate class="slx-full-height">
        <kendo-grid #kendoGrid class="slx-blue-grid slx-transparent-selection slx-grid-slim-rows slx-full-height"
          [data]="gridState?.view"
          [filterable]="'menu'"
          [filter]="gridState.state.filter"
          [sortable]="{ mode: 'multiple' }"
          [sort]="gridState.state.sort"
          [pageable]="true"
          [pageSize]="pageSize"
          [skip]="gridState.state.skip"
          (cancel)="gridState.cancelHandler($event)"
          (edit)="gridState.editHandler($event)"
          (remove)="gridState.removeHandler($event)"
          (save)="gridState.saveHandler($event)"
          (dataStateChange)="gridState.dataStateChange($event)"
        >

          <kendo-grid-checkbox-column width="50" [locked]="true">
            <ng-template kendoGridHeaderTemplate>
              <input type="checkbox" class="k-checkbox checkbox-employee" [checked]="isCheckedAll" (change)="onSelectAll()">
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <input type="checkbox" class="k-checkbox checkbox-employee" [checked]="isCheckedEmp(dataItem)" (change)="onSelectSingle(dataItem)">
            </ng-template>
          </kendo-grid-checkbox-column>

          <ng-container *ngIf="showEditColumn">
            <kendo-grid-command-column title="Edit" width="100" [locked]="true" *slxHiddenOn="['tablet', 'mobile']">
              <ng-template kendoGridCellTemplate>
                <div class="inline-buttons">
                  <button kendoGridEditCommand type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r" [disabled]="!canEdit">
                    <i class="fas fa-edit slx-only-icon" aria-hidden="true"></i>
                  </button>
                  <button kendoGridSaveCommand type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r" [disabled]="hasFormulaError || empContrError || !form.valid || empContrPercentError">
                    <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                  <button kendoGridCancelCommand type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r">
                    <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                </div>
              </ng-template>
            </kendo-grid-command-column>
          </ng-container>

          <kendo-grid-column title="Employee" width="120" field="employeeName" [sortable]="true" [locked]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Employee</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <a [employeeSectionNewWindowLink]="dataItem.employeeId">
                {{dataItem.employeeName}}
              </a>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Position" width="100" field="position.name" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Position</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.position?.name}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Facility" width="100" field="organization.name" [sortable]="true" *slxHiddenOn="['tablet', 'mobile']">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Facility</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.organization?.name}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Department" width="150" field="department.name" [sortable]="true" *slxHiddenOn="['tablet', 'mobile']">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Department</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.department?.name}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Type" width="100" field="employeeType" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Type</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.employeeType}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Cost" width="80" field="cost" [sortable]="true" [class]="'right-text'" *ngIf="calcMethod.isOptionsRates">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Cost</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.cost | number: ".2-2"| slxMoney }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Employer Contribution"
            width="200"
            field="employerContributionAmount"
            [editable]="calcMethod.isOptionsRates || calcMethod.isFlat"
            *ngIf="!calcMethod.is401K"
            class="numeric-cell"
            [sortable]="true"
          >
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Employer Contribution</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf="!(calcMethod.isFormula || calcMethod.isFormulaWithOption)">
              {{ dataItem.employerContributionAmount | number: ".2-2"| slxMoney}}
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf="calcMethod.isFormula || calcMethod.isFormulaWithOption">
              {{ dataItem.employerContributionAmount | number: ".2-2"| slxMoney}}
              <i *ngIf="dataItem.employerContributionAmount==-1"
                 title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                 aria-hidden="true"
                 [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
              <slx-number class="slx-wide slx-input-number"
                #erContr="ngModel"
                [min]="minAmt"
                [max]="maxAmt"
                [step]="stepcurrency"
                [decimals]="2"
                [format]="currencyFormat"
                [(ngModel)]="dataItem.employerContributionAmount"
                (ngModelChange)="onChangeContribution(dataItem)"
                [required]="true"
                name="employerContributionAmount"
              ></slx-number>
              <div *ngIf="erContr.errors" class="slx-error-block">
                <span *ngIf="erContr.errors.required" errorMessage for="required"></span>
                <span *ngIf="erContr.errors.min" errorMessage>Employer contribution have to be greater or equal to {{ minAmt }}</span>
                <span *ngIf="erContr.errors.max" errorMessage>Employer contribution have to be less or equal to {{ maxAmt }}</span>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Employee Contribution"
            width="200"
            field="employeeContributionAmount"
            [editable]="calcMethod.isOptionsRates || calcMethod.isFlat || calcMethod.is401K"
            class="numeric-cell"
            [sortable]="true"
            [media]="screenUtils.getKendoMediaForDesktop()"
          >
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Employee Contribution</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf="!(calcMethod.isFormula || calcMethod.isFormulaWithOption)">
              {{ dataItem.employeeContributionAmount | number: ".2-2"| slxMoney}}
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf="calcMethod.isFormula || calcMethod.isFormulaWithOption">
              {{ dataItem.employeeContributionAmount | number: ".2-2"| slxMoney}}
              <i *ngIf="dataItem.employeeContributionAmount==-1"
                 title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                 aria-hidden="true"
                 [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
              <slx-number class="slx-wide slx-input-number"
                #empContr="ngModel"
                [min]="minAmt"
                [max]="maxEmpAmt"
                [step]="stepcurrency"
                [decimals]="2"
                [autoCorrect]='dataItem.employeeContributionAmount !=null ? false : true'
                [format]="currencyFormat"
                [(ngModel)]="dataItem.employeeContributionAmount"
                (ngModelChange)="onChangeContribution(dataItem)"
                name="employeeContributionAmount"
                [readonly]="isEmpContribSelected(dataItem) && calcMethod.is401K"
              ></slx-number>
              <div *ngIf="empContr.errors  && !isEmpContribSelected(dataItem)" class="slx-error-block">
                <span *ngIf="empContr.errors.required" errorMessage for="required"></span>
                <span *ngIf="empContr.errors.min" errorMessage>Employee contribution have to be greater or equal to {{ minAmt }}</span>
                <span *ngIf="empContr.errors.max" errorMessage>Employee contribution have to be less or equal to {{ maxEmpAmt }}</span>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Coverage" width="140" field="coverage" class="numeric-cell" [sortable]="true" [editable]="(calcMethod.isFormula || calcMethod.isFormulaWithOption)" *ngIf="(calcMethod.isFormula || calcMethod.isFormulaWithOption)">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Coverage</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" *ngIf="!tierDetails?.coverageFormula?.isMultiplierCalcType && !tierDetails?.coverageFormula?.isAnyValueMaxCapCalcType">
              {{ (tierDetails?.coverageFormula?.isFormulaCalcType ? dataItem.calculatedCoverage : dataItem.coverage) | number: ".2-2"| slxMoney }}
              <i *ngIf="dataItem.calculatedCoverage==-1"
                 title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                 aria-hidden="true"
                 [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem *ngIf="tierDetails?.coverageFormula?.isMultiplierCalcType || tierDetails?.coverageFormula?.isAnyValueMaxCapCalcType">
              {{ dataItem.coverage | number: ".2-2"| slxMoney }}
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
              <ng-container *ngIf="tierDetails?.coverageFormula?.isMultiplierCalcType">
                <slx-autocomplete class="slx-wide" #multiplier="ngModel"
                                  [options]="coverageMultipllierOptions"
                                  [ngModel]="editedItemCoverage"
                                  (ngModelChange)="onChangeCoverageWithMultiplier($event, dataItem)"
                                  [strictSearch]="false"
                                  rightIcon="fas fa-search"
                                  name="coverage"
                                  [virtual]="virtual"
                                  titleField="name"
                                  [required]="true">
                  <ng-template slxAutocompleteItem let-item="item">
                    {{item.name}}
                  </ng-template>
                </slx-autocomplete>
                <span *ngIf="multiplier.errors?.required" class="slx-error-block pull-left" errorMessage for="required"></span>
              </ng-container>
              <ng-container *ngIf="tierDetails?.coverageFormula?.isAnyValueMaxCapCalcType">
                <slx-number #covAnyValue="ngModel" class="slx-wide slx-input-number"
                  [min]="minAmt"
                  [max]="tierDetails?.coverageFormula?.maxCap"
                  [step]="stepcurrency"
                  [format]="'c2'"
                  [decimals]="2"
                  [(ngModel)]="dataItem.coverage"
                  (blur)="onCoverageAnyValueChange($event, dataItem, coverageAny?.invalid)"
                  [required]="true"
                  name="coverage"
                ></slx-number>
                <span *ngIf="covAnyValue.errors?.required" class="slx-error-block pull-left" errorMessage for="required"></span>
                <span *ngIf="covAnyValue.errors?.min" class="slx-error-block pull-left" errorMessage>Must be greater than 0</span>
                <span *ngIf="covAnyValue.errors?.max" class="slx-error-block pull-left" errorMessage>Must be less than {{ tierDetails?.coverageFormula?.maxCap }}</span>
              </ng-container>
              <ng-container *ngIf="tierDetails?.coverageFormula?.isFormulaCalcType">
                <slx-number class="slx-wide slx-input-number"
                            #coverageFormula="ngModel"
                            [min]="minFormulaAmt"
                            [max]="tierDetails?.coverageFormula?.maxCap"
                            [step]="stepcurrency"
                            [format]="'c2'"
                            [autoCorrect]='true'
                            [decimals]="2"
                            [(ngModel)]="dataItem.calculatedCoverage"
                            name="coverage"
                            [required]="true"
                            (blur)="onCoverageFormulaValueChange($event, dataItem)">
                </slx-number>
                <span *ngIf="coverageFormula.errors?.required" class="slx-error-block pull-left" errorMessage for="required"></span>
              </ng-container>
              <ng-container *ngIf="tierDetails?.coverageFormula?.isFixedCalcType">
                {{ dataItem.coverage | number: ".2-2"| slxMoney }}
              </ng-container>
          </ng-template>
        </kendo-grid-column>

          <kendo-grid-column title="Employee % Contribution" width="180" field="employeeContributionPercentage" [editable]="calcMethod.is401K" *ngIf="calcMethod.is401K" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Employee % Contribution</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.employeeContributionPercentage | number:'.2'| slxPercent}}
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
              <slx-number #empPercentContr="ngModel" class="slx-wide slx-input-number" [min]="minAmt" [max]="maxEmployeeContribution" [step]="1"
                          [decimals]="2" [autoCorrect]='dataItem.employeeContributionPercentage !=null ? false : true' [(ngModel)]="dataItem.employeeContributionPercentage"
                          name="employeeContributionPercentage" (ngModelChange)="empContrPercentageChange(dataItem, rowIndex)"
                          [readonly]="isEmpPercentSelected(dataItem) && calcMethod.is401K"
                          ></slx-number>
                          <div *ngIf="empPercentContr.errors && !isEmpPercentSelected(dataItem)" class="slx-error-block">
                            <span *ngIf="empPercentContr.errors.required" errorMessage for="required"></span>
                            <span *ngIf="empPercentContr.errors.min" errorMessage>Must be greater than {{ minAmt }}</span>
                            <span *ngIf="empPercentContr.errors.max" errorMessage>Must be less than {{ maxEmployeeContribution }}</span>
                          </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Option" width="250" field="optionViewText" [sortable]="true" *ngIf="calcMethod.isFormulaWithOption || calcMethod.isOptionsRates">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Option</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.optionViewText}}
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" *ngIf="calcMethod.isFormulaWithOption">
              <ng-container>
                <slx-dropdown-list class="slx-wide"
                                   #option="ngModel" slx-input
                                    [options]="benefitOptionData"
                                    [(ngModel)]="dataItem.optionCode"
                                    (ngModelChange)="onOptionValueChange(dataItem)"
                                    valueField="id"
                                    titleField="type"
                                    name="option{{rowIndex}}"
                                   [required]="true">
                </slx-dropdown-list>
                <span *ngIf="option.errors?.required" class="slx-error-block pull-left" errorMessage for="required"></span>
              </ng-container>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Gender" width="120" field="sex" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Gender</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.sex}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="DoB" width="120" field="dateOfBirth" filter="date" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>DoB</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.dateOfBirth| amDateFormat: appConfig.dateFormat }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Age" width="120" field="age" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Age</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.age}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Benefit Start Date" width="170" field="startDate" filter="date" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>{{ canMapPayroll == true ? 'Benefit Start Date' : 'Start Date' }}</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Benefit End Date" width="170" field="endDate" filter="date" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>{{ canMapPayroll == true ? 'Benefit End Date' : 'End Date' }} </span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span>{{ dataItem.endDate | amDateFormat: appConfig.dateFormat | slxHideMaxDate }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Deduction Start Date" width="200" field="payrollDeductionStartDate" *ngIf="canMapPayroll && enableBenefitDeduction" filter="date" [sortable]="true" >
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Deduction Start Date</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.payrollDeductionStartDate | amDateFormat: appConfig.dateFormat }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Deduction End Date" width="200" field="payrollDeductionEndDate" *ngIf="canMapPayroll && enableBenefitDeduction" filter="date" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Deduction End Date</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span>{{ dataItem.payrollDeductionEndDate | amDateFormat: appConfig.dateFormat | slxHideMaxDate }}</span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Limit Amount" width="180" field="limit" *ngIf="calcMethod.is401K" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Limit Amount</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{dataItem.limit | number: ".2-2"| slxMoney}}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Notes" width="150" field="employeeToBenefitNotes.notes" [sortable]="true">
            <ng-template kendoGridHeaderTemplate>
              <span slxKendoGridHeaderTooltip>Notes</span>
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div  class="benefit-employees-eligible">
                <span class="notes-long"
                    slxTooltip="{{ dataItem.employeeToBenefitNotes?.notes }}"
                    tipPosition="top">{{  dataItem.employeeToBenefitNotes?.notes  }}</span>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-excel fileName="{{ planName }} (Enrolled Employees).xlsx" [fetchData]="retriveAllPages()"></kendo-grid-excel>
          <kendo-grid-pdf fileName="{{ planName }} (Enrolled Employees).pdf" [allPages]="true">
            <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
          </kendo-grid-pdf>

        </kendo-grid>
      </form>
    </div>
    <div class="block-cover">
      <span *ngIf="empContrError" class="slx-error-block">Employee Contribution Percentage can not be greater than Max Employee Contribution.</span>
    </div>

  </div>
</slx-spinner>
