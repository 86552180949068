import * as _ from 'lodash';
import * as moment from 'moment';

import { Directive, Host, HostListener, Inject, OnInit, AfterContentInit } from '@angular/core';
import { NgModel, ControlValueAccessor } from '@angular/forms';
import { AcceptableControlValueAccessor, isAcceptableControlValueAccessor } from '../../../core/models/index';
import { ChangeManagementService } from '../../services/index';


@Directive({
  selector: '[slxChangeManagement]'
})
export class ChangeManagementDirective implements AfterContentInit {

  private accessor: AcceptableControlValueAccessor;
  constructor( @Host() private ngModel: NgModel, private changeManagementService: ChangeManagementService) {
  }

  public ngAfterContentInit(): void {
    if (!this.ngModel) {
      return;
    }
    let cva = this.ngModel.valueAccessor;
    if (isAcceptableControlValueAccessor(cva)) {
      this.accessor = cva;
      this.accessor.registerOnAccept((val: any) => this.checkAccepted(val));
      return;
    }
    throw new Error('Directive slxChangeManagement can be used only with ControlValueAccessor which implemented AcceptableControlValueAccessor!');
  }
  public checkAccepted(value: any): boolean | Promise<boolean> {
    return this.changeManagementService.canMoveForward();
  }
}
