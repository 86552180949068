import * as moment from 'moment';
import { appConfig } from '../../../app.config';
var ShiftGroupDefinition = /** @class */ (function () {
    function ShiftGroupDefinition(id, name, startTime, endTime) {
        this.startTimeValue = new Date(new Date().toDateString() + " " + startTime);
        this.endTimeValue = new Date(new Date().toDateString() + " " + endTime);
        this.idValue = id;
        this.nameValue = name;
        var startDateMoment = moment(this.startTime, appConfig.militaryTimeFormat);
        var endDateMoment = moment(this.endTime, appConfig.militaryTimeFormat);
        if (startDateMoment <= endDateMoment) {
            this.durationHoursValue = endDateMoment.diff(startDateMoment, 'h');
        }
        else {
            var zeroDateMoment = moment().startOf('day');
            var enddayDateMoment = moment().endOf('day');
            this.durationHoursValue = enddayDateMoment.diff(startDateMoment, 'h') + endDateMoment.diff(zeroDateMoment, 'h');
        }
    }
    Object.defineProperty(ShiftGroupDefinition.prototype, "id", {
        get: function () {
            return this.idValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftGroupDefinition.prototype, "name", {
        get: function () {
            return this.nameValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftGroupDefinition.prototype, "start", {
        get: function () {
            return this.startTimeValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftGroupDefinition.prototype, "end", {
        get: function () {
            return this.endTimeValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftGroupDefinition.prototype, "startTime", {
        get: function () {
            return moment(this.startTimeValue).format(appConfig.militaryTimeFormat);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftGroupDefinition.prototype, "endTime", {
        get: function () {
            return moment(this.endTimeValue).format(appConfig.militaryTimeFormat);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftGroupDefinition.prototype, "durationHours", {
        get: function () {
            return this.durationHoursValue;
        },
        enumerable: true,
        configurable: true
    });
    return ShiftGroupDefinition;
}());
export { ShiftGroupDefinition };
