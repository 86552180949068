<form novalidate #form="ngForm" class="slx-full-height">
  <kendo-grid #kendoGrid class="budget-list budget-grid-component slx-full-height"
    [data]="gridState.view"
    [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort"
    [groupable]="{ showFooter: true }"
    [group]="gridState.state.group"
    [filterable]="true"
    [filter]="gridState.state.filter"
    (cancel)="cancelHandler()"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
    (save)="saveHandler($event)"
    [selectable]="editMode?{ mode:'single'}:false"
    (selectionChange)="gridState.selectionChange($event)"
    (dataStateChange)="gridState.dataStateChange($event)"
  >
    <kendo-grid-command-column [hidden]="!editMode" title="Сommand" width="70">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <div class="flex-horizontal">
         <button kendoGridEditCommand [disabled]="addNewMode" type="button" class="theme-icon-button apply-button">
            <i class="fas fa-pencil-alt" aria-hidden="true"></i>
         </button>
         <button kendoGridRemoveCommand [disabled]="addNewMode" type="button" class="theme-icon-button apply-button">
           <i class="fa fa-times" aria-hidden="true"></i>
         </button>
         <button kendoGridSaveCommand type="button" [disabled]="!form.valid" class="theme-icon-button apply-button">
          <i class="far fa-check-circle" aria-hidden="true"></i>
         </button>
          <button kendoGridCancelCommand type="button"  class="theme-icon-button cancel-button">
          <i class="fa fa-ban" aria-hidden="true"></i>
         </button>
        </div>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column title="Budgeted Group" field="budgetedGroup.description">
      <ng-template kendoGridHeaderTemplate>
        Budgeted Group
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a> {{dataItem?.budgetedGroup?.description}} </a>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem>
        <slx-input-decorator>
          <slx-dropdown-input slx-input required="true" [lookup]="{lookupType: 'budgetedGroup', orgLevelId: editedOrgLevelId, updateCacheForced:forceGroupsCache }" name="budgetedGroup"
            placeholder="Budgeted group" [(ngModel)]="editedRecord.budgetedGroup" emptyOption>
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Budgeted Position" field="budgetedPosition.description">
      <ng-template kendoGridHeaderTemplate>
        Budgeted Position
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a> {{dataItem?.budgetedPosition?.description}} </a>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem>
        <slx-input-decorator>
          <input slx-input required="true" name="budgetedPosition" placeholder="Budgeted position" [(ngModel)]="editedRecord.budgetedPosition.description">
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Weekday Hours" field="weekdayHours">
      <ng-template kendoGridHeaderTemplate>
        Weekday Hours
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.weekdayHours | number: ".2-2"}}
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
        Sum: {{aggregates['weekdayHours'].sum | number: ".2-2"}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <slx-input-decorator>
          <slx-kendo-number slx-input number
            [(ngModel)]="editedRecord.weekdayHours"
            [decimals]="2"
            [min]="0"
            [slxMin]="0"
            [required]="'true'"
            [autoCorrect]="true"
            name="weekdayHours"
            placeholder="Weekday Hours"
          ></slx-kendo-number>
          <span errorMessage for="min">The value should be greater than 0</span>
          <span errorMessage for="number"></span>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter" [min]="0">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Weekend Hours" field="weekendHours">
      <ng-template kendoGridHeaderTemplate>
        Weekend Hours
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.weekendHours | number: ".2-2"}}
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
        Sum: {{aggregates['weekendHours'].sum | number: ".2-2"}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <slx-input-decorator>
          <slx-kendo-number slx-input number
            [(ngModel)]="editedRecord.weekendHours"
            [decimals]="2"
            [min]="0"
            [slxMin]="0"
            [required]="'true'"
            [autoCorrect]="true"
            name="weekendHours"
            placeholder="Weekend Hours"
          ></slx-kendo-number>
          <span errorMessage for="min">The value should be greater than 0</span>
          <span errorMessage for="number"></span>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter" [min]="0">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Pay Rate" field="payRate">
      <ng-template kendoGridHeaderTemplate>
        Pay Rate
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.payRate}}
      </ng-template>
      <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
        Sum: {{aggregates['payRate'].sum | number: ".2-2"}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <slx-input-decorator>
          <slx-kendo-number slx-input number
            [(ngModel)]="editedRecord.payRate"
            [decimals]="2"
            [min]="0"
            [slxMin]="0"
            [required]="'true'"
            [autoCorrect]="true"
            name="payRate"
            placeholder="Pay Rate"
          ></slx-kendo-number>
          <span errorMessage for="min">The value should be greater than 0</span>
          <span errorMessage for="number"></span>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter" [min]="0">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Census Adjust" field="censusAdjust">
      <ng-template kendoGridHeaderTemplate>
        Census Adjust
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value | boolyn}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.censusAdjust | boolyn }}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem>
        <slx-input-decorator className="slx-no-label slx-no-border slx-small-font">
          <slx-checkbox-input slx-input name="censusAdjust" fieldName="censusAdjust" placeholder="" caption="" [(ngModel)]="editedRecord.censusAdjust">
          </slx-checkbox-input>
        </slx-input-decorator>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-boolean-filter [column]="column" [filter]="filter">
        </slx-kendo-grid-boolean-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="!addNewMode && !editedRowIndex" title="Last Updated By" field="lastUpdatedBy">
      <ng-template kendoGridHeaderTemplate>
        Last Updated By
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.lastUpdatedBy}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="!addNewMode && !editedRowIndex" title="Last Updated Date" field="lastUpdatedDate">
      <ng-template kendoGridHeaderTemplate>
        Last Updated Date
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value | amDateFormat: appConfig.dateFormat}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.lastUpdatedDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-date-filter>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</form>
