/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./authorizesso.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./authorizesso.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../authentication/services/authentication/authentication.service";
var styles_AuthorizessoComponent = [i0.styles];
var RenderType_AuthorizessoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthorizessoComponent, data: {} });
export { RenderType_AuthorizessoComponent as RenderType_AuthorizessoComponent };
export function View_AuthorizessoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "loading-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AuthorizessoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-authorizesso", [], null, null, null, View_AuthorizessoComponent_0, RenderType_AuthorizessoComponent)), i1.ɵdid(1, 114688, null, 0, i4.AuthorizessoComponent, [i5.Router, i5.ActivatedRoute, i6.AuthenticationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthorizessoComponentNgFactory = i1.ɵccf("slx-authorizesso", i4.AuthorizessoComponent, View_AuthorizessoComponent_Host_0, {}, {}, []);
export { AuthorizessoComponentNgFactory as AuthorizessoComponentNgFactory };
