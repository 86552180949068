import { StringUtils } from '../../../framework/index';
export var EmployeeMessageAction;
(function (EmployeeMessageAction) {
    EmployeeMessageAction[EmployeeMessageAction["read"] = 0] = "read";
    EmployeeMessageAction[EmployeeMessageAction["approveShift"] = 1] = "approveShift";
    EmployeeMessageAction[EmployeeMessageAction["denyShift"] = 2] = "denyShift";
})(EmployeeMessageAction || (EmployeeMessageAction = {}));
export var EmployeeMessageState;
(function (EmployeeMessageState) {
    EmployeeMessageState[EmployeeMessageState["new"] = 0] = "new";
    EmployeeMessageState[EmployeeMessageState["read"] = 1] = "read";
    EmployeeMessageState[EmployeeMessageState["closed"] = 2] = "closed";
    EmployeeMessageState[EmployeeMessageState["reopened"] = 3] = "reopened";
    EmployeeMessageState[EmployeeMessageState["requested"] = 4] = "requested";
    EmployeeMessageState[EmployeeMessageState["scheduled"] = 5] = "scheduled";
    EmployeeMessageState[EmployeeMessageState["ignored"] = 6] = "ignored";
    EmployeeMessageState[EmployeeMessageState["sent"] = 7] = "sent";
})(EmployeeMessageState || (EmployeeMessageState = {}));
export var EmployeeMessageSource;
(function (EmployeeMessageSource) {
    EmployeeMessageSource[EmployeeMessageSource["system"] = 0] = "system";
    EmployeeMessageSource[EmployeeMessageSource["sms"] = 1] = "sms";
})(EmployeeMessageSource || (EmployeeMessageSource = {}));
export var EmployeeMessageDirection;
(function (EmployeeMessageDirection) {
    EmployeeMessageDirection[EmployeeMessageDirection["inbound"] = 0] = "inbound";
    EmployeeMessageDirection[EmployeeMessageDirection["outbound"] = 1] = "outbound";
})(EmployeeMessageDirection || (EmployeeMessageDirection = {}));
var EmployeeMessage = /** @class */ (function () {
    function EmployeeMessage() {
    }
    Object.defineProperty(EmployeeMessage.prototype, "canRead", {
        get: function () {
            return this.state === EmployeeMessageState.new || this.state === EmployeeMessageState.requested;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isInbound", {
        get: function () {
            return this.direction === EmployeeMessageDirection.inbound;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isOutbound", {
        get: function () {
            return this.direction === EmployeeMessageDirection.outbound;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isNew", {
        get: function () {
            return this.state === EmployeeMessageState.new;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isReaded", {
        get: function () {
            return this.state === EmployeeMessageState.read;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isClosed", {
        get: function () {
            return this.state === EmployeeMessageState.closed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isReopened", {
        get: function () {
            return this.state === EmployeeMessageState.reopened;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isRequested", {
        get: function () {
            return this.state === EmployeeMessageState.requested;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isScheduled", {
        get: function () {
            return this.state === EmployeeMessageState.scheduled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isIgnored", {
        get: function () {
            return this.state === EmployeeMessageState.ignored;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isSent", {
        get: function () {
            return this.state === EmployeeMessageState.sent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isSystem", {
        get: function () {
            return this.source === EmployeeMessageSource.system;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "isSms", {
        get: function () {
            return this.source === EmployeeMessageSource.sms;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "IsPositionGrouped", {
        get: function () {
            return this.isPositionGroupInd == 1 ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "messageId", {
        get: function () {
            return StringUtils.format('message{0}', this.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeMessage.prototype, "messageAnchor", {
        get: function () {
            return StringUtils.format('#{0}', this.messageId);
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeMessage;
}());
export { EmployeeMessage };
