import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';

import { LMCreationAbsenceManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-absence-notes-tab',
  templateUrl: 'lm-absence-notes-tab.component.html',
  styleUrls: ['lm-absence-notes-tab.component.scss']
})
export class LMAbsenceNotesTabComponent implements OnInit {
  public notes: string;

  public get canEditRequest(): boolean {
    return this.manService.canChange;
  }

  constructor(private manService: LMCreationAbsenceManagementService) {}

  public ngOnInit(): void {
    this.notes = this.manService.getNotes();
  }

  public onChangeNotes(note: string): void {
    this.manService.setNotes(note);
  }
}
