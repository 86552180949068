import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TextMaskModule } from 'angular2-text-mask';
import { MomentModule } from 'angular2-moment';
import { NgxPopperModule } from 'ngx-popper';
import { PopupModule } from '@progress/kendo-angular-popup';

import { commonComponents, exportCommonComponents, entryComponents } from './components/index';
import { directives, exportDirectives } from './directives/index';
import { services } from './services/index';
import { routesModule } from './common.routes';
import { commonValidators } from './validators/index';
import { errorFilterDirectives, errotFilterPipes } from './error-filters/index';
import { commonPipes } from './pipes/index';
import { ACTION_PROVIDERS } from './store/index';
import { versionServices } from './services/technical/index';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LayoutModule } from "@progress/kendo-angular-layout";

import { badges } from './badges/index';
import { AngularWeatherWidgetModule } from '../app-modules/angular-weather-widget/index';
import { TreeviewModule } from 'ngx-treeview';
import { CheckboxTreeViewComponent } from './components/checkbox-tree-view/checkbox-tree-view.component';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    AngularCommonModule,
    MomentModule,
    GridModule,
    ChartsModule,
    DateInputsModule,
    DropDownsModule,
    InputsModule,
    LayoutModule,
    ButtonsModule,
    NgxPopperModule,
    PopupModule,
    routesModule,
    AngularWeatherWidgetModule,
    TreeviewModule.forRoot()
  ],
  declarations: [
    ...commonComponents,
    ...directives,
    ...commonValidators,
    ...errotFilterPipes,
    ...errorFilterDirectives,
    ...commonPipes,
    ...badges,
    CheckboxTreeViewComponent
  ],
  providers: [
    ...services,
    ...ACTION_PROVIDERS,
    ...versionServices
  ],
  entryComponents: [
    ...entryComponents
  ],
  exports: [
    commonValidators,
    errorFilterDirectives,
    errotFilterPipes,
    ...commonPipes,
    ...exportDirectives,
    ...exportCommonComponents,
    ...badges
  ]
})
export class CommonModule {
}
