/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./login.component";
import * as i5 from "../../../common/services/technical/version.subscribe.service";
import * as i6 from "../../services/authentication/authentication.service";
import * as i7 from "../../actions/session.actions";
import * as i8 from "../../../core/services/user-activity/user-activity.service";
import * as i9 from "@angular/platform-browser";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "mobile-marketing"]], [[2, "expanded", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMarketing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "i", [["aria-hidden", "true"], ["class", "fas fa-3x"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "fa-chevron-circle-up": 0, "fa-chevron-circle-down": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "iframe", [["class", "login-iframe"], ["title", "Promo page"]], [[8, "src", 5]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fas fa-3x"; var currVal_2 = _ck(_v, 5, 0, _co.isMarketingExpanded, !_co.isMarketingExpanded); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMarketingExpanded; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.promoUrl; _ck(_v, 6, 0, currVal_3); }); }
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "panels"]], [[2, "marketing-expanded", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "left-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "iframe", [["class", "login-iframe"], ["title", "Promo page"]], [[8, "src", 5]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "right-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "login-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "col-xs-12 logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Smartlinx"], ["class", "logo__img"], ["src", "/assets/img/slx-logo-6-1-blue.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 8, 0); var currVal_2 = _co.appConfig.showMobileMarket; _ck(_v, 10, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMarketingExpanded; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.promoUrl; _ck(_v, 2, 0, currVal_1); }); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i4.LoginComponent, [i3.Router, i3.ActivatedRoute, i5.VersionSubscribeService, i6.AuthenticationService, i7.SessionActions, i8.UserActivityService, i9.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("slx-login", i4.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
