import { BudgetedParsDetailsContainer, IBudgetedParsDetailsContainer } from './../../models/budgeted-pars/budgeted-pars-details-container';
import { PostScheduleSettings } from './../../models/open-shift-management/post-schedule-settings';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import * as moment from 'moment';
import * as _ from 'lodash';

import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';

import { schedulerConfig } from '../../scheduler.config';
import { IEmployeeScheduleDefinition, EmployeeScheduleDefinition, GenerateScheduleSummary, IGenerateScheduleSummary, EmployeeScheduleDefinitionContainer } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { Employee, IPostScheduleSettings } from '../../../scheduler/models/index';
import { Assert } from '../../../framework/assert/assert';
import { ResponseBody } from '../../../core/models/api/response-body';
import { PostScheduleData, SummaryGridRecord, IPostedSchedule, PostedSchedule } from '../../models/post-schedule/index';
import { ScheduleMapService } from './schedule-map.service';
import { Backup, EmployeeScheduleCycleBackupDTO, IScheduleTotalSummary, ScheduleTotalSummary, AutoSchedulingConfig, IAutoSchedulingConfig, AutoSchedulingState, IAutoSchedulingState } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { IMasterScheduleFilters } from '../../store/master-schedule/master-schedule.types';
import { FieldsMeta } from '../../../core/models/index';
import { IScheduleNotPosted, ScheduleNotPosted } from '../../models/schedules-not-posted/schedule-not-posted';

@Injectable()
export class ScheduleApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: ScheduleMapService) {
  }

  public generateSchedule(orgLevelId: number, employeeId: number, startDate: Date, endDate: Date): Promise<EmployeeScheduleDefinition> {
    const url: string =
      `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevelId}/${schedulerConfig.api.employee.root}/${employeeId}/${schedulerConfig.api.schedule.generate}`;

    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let request: HttpRequest<any> = new HttpRequest('PUT', url, null, {
      params: params
    });

    let promise: Promise<EmployeeScheduleDefinition> = this.apiUtilService.request<IEmployeeScheduleDefinition, Meta>(request)
      .then((response: ResponseBody<IEmployeeScheduleDefinition, Meta>) => this.mapService.mapEmployeeScheduleDefinition(response.data));
    return promise;
  }

  public generateScheduleForDepartment(orgLevel: OrgLevel, startDate: Date, endDate: Date): Promise<any> {
    const url: string =
      `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevel.id}/${schedulerConfig.api.schedule.generate}`;

    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let request: HttpRequest<any> = new HttpRequest('PUT', url, null, {
      params: params
    });
    return this.apiUtilService.request<any[], Meta>(request);
  }

  public generateScheduleAtBackend(orgLevelId: number, startDate: Date): Promise<GenerateScheduleSummary> {
    const url: string =
      `${this.getGenerateApiRoot(startDate)}`;

    return this.apiUtilService.request<IGenerateScheduleSummary, Meta>(this.urlParamsService.createPostRequest(url, undefined, { orgLevelId: orgLevelId }))
      .then((response: ResponseBody<IGenerateScheduleSummary, Meta>) => this.mapService.mapGenerateScheduleSummary(response.data));
  }

  public generateEmpScheduleAtBackend(orgLevelId: number, startDate: Date, employeeId: number): Promise<GenerateScheduleSummary> {
    const url: string =
      `${this.getGenerateApiRoot(startDate)}/${schedulerConfig.api.schedule.employee}/${employeeId}`;

    return this.apiUtilService.request<IGenerateScheduleSummary, Meta>(this.urlParamsService.createPostRequest(url, undefined, { orgLevelId: orgLevelId }))
      .then((response: ResponseBody<IGenerateScheduleSummary, Meta>) => this.mapService.mapGenerateScheduleSummary(response.data));
  }

  public deleteScheduleForDepartment(orgLevel: OrgLevel, startDate: Date, endDate: Date): Promise<any> {
    const url: string =
      `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevel.id}`;

    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let request: HttpRequest<any> = new HttpRequest('DELETE', url, {
      params: params
    });

    return this.apiUtilService.request<any[], Meta>(request);
  }

  public deleteScheduleForEmployee(orgLevelId: number, employeeId: number, startDate: Date, endDate: Date): Promise<any> {
    const url: string =
      `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevelId}/${schedulerConfig.api.employee.root}/${employeeId}`;

    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let request: HttpRequest<any> = new HttpRequest('DELETE', url, {
      params: params
    });

    return this.apiUtilService.request<any[], Meta>(request);
  }

  public createRotationFromSchedule(orgLevelId: number, employeeId: number, startDate: Date, weeks: number): Promise<any> {
    const url: string =
      `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevelId}/${schedulerConfig.api.employee.root}/${employeeId}/${schedulerConfig.api.schedule.createRotationFromSchedule}`;

    return this.apiUtilService.request<any[], Meta>(this.urlParamsService.createPutRequest(url, null, {
      weeks,
      startDate: dateTimeUtils.convertToDtoString(startDate)
    }));
  }

  public getEmployeesSchedule(orgLevel: OrgLevel, filters: IMasterScheduleFilters): Promise<EmployeeScheduleDefinitionContainer> {
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevel.id}`;

    let promise: Promise<EmployeeScheduleDefinitionContainer> = this.apiUtilService.request<IEmployeeScheduleDefinition[], Meta>(
      this.urlParamsService.createPostRequest(url, this.mapService.mapToRequest(filters))
    )
      .then((response: ResponseBody<IEmployeeScheduleDefinition[], Meta>) => this.mapService.mapEmployeeScheduleDefinitionContainer(response.data, response.meta as FieldsMeta));
    return promise;
  }

  public getEmployeeSchedule(orgLevelId: number, employeeId: number, startDate: Date, endDate: Date): Promise<EmployeeScheduleDefinition> {
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevelId}/${schedulerConfig.api.employee.root}/${employeeId}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({
        startDate: dateTimeUtils.convertToDtoString(startDate),
        scheduleWeeksCount: moment(endDate).diff(startDate, 'weeks') + 1
      })
    });
    let promise: Promise<EmployeeScheduleDefinition> = this.apiUtilService.request<IEmployeeScheduleDefinition, Meta>(request)
      .then((response: ResponseBody<IEmployeeScheduleDefinition, Meta>) => this.mapService.mapEmployeeScheduleDefinition(response.data));
    return promise;
  }

  public getTotals(orgLevel: OrgLevel, filters: IMasterScheduleFilters): Promise<ScheduleTotalSummary> {
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevel.id}/${schedulerConfig.api.totals}`;

    let promise: Promise<ScheduleTotalSummary> = this.apiUtilService.request<IScheduleTotalSummary, Meta>(
      this.urlParamsService.createPostRequest(url, this.mapService.mapToRequest(filters))
    )
      .then((response: ResponseBody<IScheduleTotalSummary, Meta>) => this.mapService.mapScheduleTotalSummary(response.data));
    return promise;
  }


  public getScheduleSummary(orgLevel: OrgLevel, startDate: Date): Promise<any> {

    Assert.isNotNull(orgLevel, 'orgLevel');

    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/`
      + `${orgLevel.id}/${schedulerConfig.api.schedule.post.root}/${schedulerConfig.api.schedule.post.shifts}`;

    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate)
    });

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<any> = this.apiUtilService.request<any[], Meta>(request);

    promise.then((response: ResponseBody<any, Meta>) => {
      response.data = this.mapService.mapPostScheduleData(response.data);
    })
      .catch((ex: Error) => {
        console.log('catch promise rejection');
      });

    return promise;

  }

  public postSummarySchedule(orgLevel: OrgLevel, ot: boolean, sameDay: boolean, data: PostScheduleData): Promise<any> {

    Assert.isNotNull(orgLevel, 'orgLevel');

    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/`
      + `${orgLevel.id}/${schedulerConfig.api.schedule.post.root}/${schedulerConfig.api.schedule.post.shifts}`;

    let params: HttpParams = this.urlParamsService.convertToParams({
      dateOn: dateTimeUtils.convertToDtoString(data.startDate),
      approachingAt: ot,
      sameDay: sameDay
    });
    let body: SummaryGridRecord[] = data.entities;
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body, {
      params: params
    });

    return this.apiUtilService.request<any[], Meta>(request);
  }

  public backupSchedule(startDate: Date, endDate: Date, orglevel: OrgLevel, employee?: Employee): Promise<any> {
    let employeeId: number = 0;
    if (employee && employee instanceof Employee) {
      employeeId = employee.id;
    }

    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/`
      + `${orglevel.id}/${schedulerConfig.api.schedule.backup}`;

    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate),
      employeeId: employeeId
    });

    let request: HttpRequest<any> = new HttpRequest('POST', url, null, {
      params: params
    });

    return this.apiUtilService.request<any[], Meta>(request);
  }

  public getBackups(orgLevel: OrgLevel): Promise<Backup[]> {
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/`
      + `${orgLevel.id}/${schedulerConfig.api.schedule.backup}`;

      let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<EmployeeScheduleCycleBackupDTO[], Meta>(request)
      .then((result: ResponseBody<EmployeeScheduleCycleBackupDTO[], Meta>) => _.map(result.data, (data: EmployeeScheduleCycleBackupDTO) => this.mapService.mapToBackup(data)));
  }

  public backupApply(orgLevel: OrgLevel, backupId: number): Promise<any> {
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/`
      + `${orgLevel.id}/${schedulerConfig.api.schedule.backup}/${backupId}`;

    let request: HttpRequest<any> = new HttpRequest('POST', url, {
      orgLevelId: orgLevel.id,
      backupId
    });

    return this.apiUtilService.request<any[], Meta>(request);
  }

  public getPostedSchedules(orgLevelId: number, startDate: Date, endDate: Date): Promise<PostedSchedule[]> {

    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}/${schedulerConfig.api.schedule.orglevel.root}/${orgLevelId}/${schedulerConfig.api.schedule.orglevel.posted}`;

    let params: HttpParams = this.urlParamsService.convertToParams({
      orgLevelId: orgLevelId,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<PostedSchedule[]> = this.apiUtilService.request<IPostedSchedule[], Meta>(request)
      .then((response: ResponseBody<IPostedSchedule[], Meta>) => {
        return this.mapService.mapPostedSchedules(response.data);
      });
    return promise;
  }
  public getSchedulesNotPosted(orgLevelId: number, startDate: Date, endDate: Date): Promise<ScheduleNotPosted[]> {

    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}/${schedulerConfig.api.schedule.orglevel.root}/${orgLevelId}/${schedulerConfig.api.schedule.orglevel.scheduleNotPosted}`;

    let params: HttpParams = this.urlParamsService.convertToParams({
      orgLevelId: orgLevelId,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<ScheduleNotPosted[]> = this.apiUtilService.request<IScheduleNotPosted[], Meta>(request)
      .then((response: ResponseBody<IScheduleNotPosted[], Meta>) => {
        return this.mapService.mapSchedulesNotPosted(response.data);
      });
    return promise;
  }

  public postSchedule(orgLevel: OrgLevel, startDate: Date, settings: PostScheduleSettings): Promise<any> {
    Assert.isNotNull(orgLevel, 'orgLevel');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(settings, 'settings');
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/`
      + `${schedulerConfig.api.schedule.post.root}/${schedulerConfig.api.schedule.post.shifts}`;
    let body: any = this.mapService.mapSaveScheduleForPostingRequestDTO(orgLevel, startDate, settings);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);
    return this.apiUtilService.request<any[], Meta>(request);
  }

  public savePostSettings(orgLevel: OrgLevel, settings: PostScheduleSettings): Promise<any> {
    Assert.isNotNull(orgLevel, 'orgLevel');
    Assert.isNotNull(settings, 'settings');
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/`
      + `${schedulerConfig.api.schedule.post.root}/${schedulerConfig.api.schedule.post.options}`;
    let body: any = {
      orgLevelId: orgLevel.id,
      postingScheduleOptions: this.mapService.mapPostScheduleSettingsDTO(settings)
    };
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);
    return this.apiUtilService.request<any[], Meta>(request);
  }

  public loadPostSettings(orgLevel: OrgLevel, startDate: Date, endDate: Date): Promise<PostScheduleSettings> {
    Assert.isNotNull(orgLevel, 'orgLevel');
    let params: HttpParams = this.urlParamsService.convertToParams({
      orgLevelId: orgLevel.id,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/`
      + `${schedulerConfig.api.schedule.post.root}/${schedulerConfig.api.schedule.post.options}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<PostScheduleSettings> = this.apiUtilService.request<IPostScheduleSettings, Meta>(request)
      .then((response: ResponseBody<IPostScheduleSettings, Meta>) => {
        return this.mapService.mapPostScheduleSettings(response.data);
      });
    return promise;

  }

  public loadScheduleSummaryMessages(orgLevelId: number, startDate: Date, endDate: Date): Promise<GenerateScheduleSummary> {

    const url: string = `${this.getSummaryMessagesApiRoot()}`;

    let params: any = {
      orgLevelId: orgLevelId,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    };

    return this.apiUtilService.request<IGenerateScheduleSummary, Meta>(this.urlParamsService.createGetRequest(url, params))
      .then((response: ResponseBody<IGenerateScheduleSummary, Meta>) => this.mapService.mapGenerateScheduleSummary(response.data));
  }

  public changeOpenShift(date: Date, employeeId: number, originalId: number, replaceId: number, unitId: number): Promise<any> {
    Assert.isNotNull(date, 'date');
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(originalId, 'originalId');
    Assert.isNotNull(replaceId, 'replaceId');
    Assert.isNotNull(unitId, 'unitId');
    let dateOn: string = dateTimeUtils.convertToDtoString(date);
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}/${dateOn}/${schedulerConfig.api.employees.root}/${employeeId}/${schedulerConfig.api.employees.changeShift}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, { originalShiftId: originalId, replacedShiftId: replaceId, replacedUnitId: unitId });
    return this.apiUtilService.request<any, Meta>(request);
  }

  public getAutoScheduling(orgLevelId: number): Promise<AutoSchedulingState> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = this.getAutomationApiRoot(orgLevelId);
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<IAutoSchedulingState, Meta>(request)
      .then((response: ResponseBody<IAutoSchedulingState, Meta>) => {
        return this.mapService.mapAutoSchedulingState(response.data);
      });
  }

  public saveAutoScheduling(orgLevelId: number, config: AutoSchedulingConfig): Promise<AutoSchedulingState> {
    Assert.isNotNull(config, 'config');

    const url: string = this.getAutomationApiRoot(orgLevelId);
    const data: IAutoSchedulingConfig = this.mapService.mapToDTOAutoSchedulingConfig(config);
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, data);

    return this.apiUtilService.request<IAutoSchedulingState, Meta>(request)
    .then((response: ResponseBody<IAutoSchedulingState, Meta>) => {
      return this.mapService.mapAutoSchedulingState(response.data);
    });
  }

  public deleteAutoScheduling(orgLevelId: number): Promise<any> {
    const url: string = this.getAutomationApiRoot(orgLevelId);
    let request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url);
    return this.apiUtilService.request<AutoSchedulingState, Meta>(request);
  }

  public getBudgetedParsDetails(orgLevelId: number, date: Date, positionIds: number[]): Promise<BudgetedParsDetailsContainer> {
    let dateOn: string = dateTimeUtils.convertToDtoString(date);
    let url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}/${dateOn}/${schedulerConfig.api.schedule.orglevel.root}/${orgLevelId}`;
    url = `${url}/${schedulerConfig.api.schedule.orglevel.budgetedParsDetails}`;

    let request: HttpRequest<IBudgetedParsDetailsContainer> = this.urlParamsService.createPostRequest(url, positionIds);
    return this.apiUtilService.request<IBudgetedParsDetailsContainer, Meta>(request)
      .then((response: ResponseBody<IBudgetedParsDetailsContainer, Meta>) => this.mapService.mapBudgetedParsDetailsContainer(response.data));
  }

  private getAutomationApiRoot(orgLevelId: number): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}/${schedulerConfig.api.orglevel}/${orgLevelId}/${schedulerConfig.api.automation.root}/${schedulerConfig.api.automation.config}`;
  }

  private getGenerateApiRoot(startDate: Date): string {
    return `${this.getScheduleApiRoot()}/${schedulerConfig.api.schedule.cycle}/${moment(startDate).format(appConfig.requestDate)}/${schedulerConfig.api.schedule.generate}`;
  }
  private getScheduleApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}`;
  }

  private getSummaryMessagesApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.schedule.summary.root}/${schedulerConfig.api.schedule.summary.messages}`;
  }
}
