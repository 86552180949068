import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, IDialog } from '../../../../../common/index';
import { AuditTrailLimitedComponent } from '../audit-trail-limited/audit-trail-limited.component';
import { ModalService } from '../../../../../common/services/index';
import { AuditTrailLimitedReq } from '../../../models/index';
import { screenUtils, IScreenUtils } from '../../../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-audit-trail-limited-dialog',
  templateUrl: 'audit-trail-limited-dialog.component.html',
  styleUrls: ['audit-trail-limited-dialog.component.scss']
})
export class AuditTrailLimitedDialogComponent implements IDialog {


  public options: DialogOptions;
  public dialogResult: boolean;
  public request: AuditTrailLimitedReq;

  private modalService: ModalService;
  private screenUtils: IScreenUtils;



  public static openDialog(request: AuditTrailLimitedReq, modalService: ModalService, callback: (result: boolean, cmd: any) => void): AuditTrailLimitedDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 650;
    dialogOptions.width = 1200;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.showCloseButton = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: AuditTrailLimitedReq,
        useValue: request
      }
    ];

    let dialog: AuditTrailLimitedDialogComponent = modalService.globalAnchor
      .openDialog(AuditTrailLimitedDialogComponent, request.header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, undefined);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    request: AuditTrailLimitedReq
  ) {
    this.modalService = modalService;
    this.options = options;
    this.request = request;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
