import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter  } from '@angular/core';
import { PopperContent, PopperController } from 'ngx-popper';
import { PopperPositions } from '../../../../common/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-action-button',
  templateUrl: 'action-button.component.html',
})
export class ActionButtonComponent {
  @Input()
  public popperContent: PopperContent;
  @Input()
  public popperPosition: PopperPositions = 'bottom-start';
  @Output()
  public popperOnShow: EventEmitter<PopperController> = new EventEmitter<PopperController>();
  @Output()
  public popperOnHide: EventEmitter<PopperController> = new EventEmitter<PopperController>();

  @Input('className')
  public customClassName: string;
  @Input('iconName')
  public iconName: string;
  @Input('dropDownIcon')
  public dropDownIcon: string;

  @Input()
  public fullyCustomStyle: boolean = false;

  private escCode: number = 27;
  private defaultCssClasse: string = 'slx-action-button';

  public onKeyup(event: KeyboardEvent): void {
    if (event.keyCode === this.escCode) {
      this.popperContent.hide();
    }
  }

  public onShown(popper: PopperController): void {
    this.popperOnShow.emit(popper);
  }

  public onHidden(popper: PopperController): void {
    this.popperOnHide.emit(popper);
  }

  public get className(): string {
    const classes: string[] = [];
    if (_.isString(this.customClassName) && _.size(this.customClassName) > 0) {
      classes.push(this.customClassName);
    }
    if (!this.fullyCustomStyle) {
      classes.push(this.defaultCssClasse);
    }

    return classes.join(' ');
  }
}
