import * as tslib_1 from "tslib";
import * as _ from "lodash";
import { process } from "@progress/kendo-data-query";
import { GridComponent, } from "@progress/kendo-angular-grid";
import { KendoGridStateHelper, ConfirmDialog2Component, ModalService, ConfirmOptions, } from "../../../../../common/index";
import { appConfig } from "../../../../../app.config";
import { EmployeeSectionsCertification, EmployeeSectionsCertifications, } from "../../../models/employee-sections-personal/employee-sections-certification";
import { unsubscribe } from "../../../../../core/decorators/index";
import { Subscription } from "rxjs";
import { EmployeeSectionsPersonalApiService } from "../../../services";
import { EmployeeSubSectionsDecoratorComponent } from "../../employee-subsection-decorator/employee-subsection-decorator.component";
var EmployeeSectionsCertificationsComponent = /** @class */ (function () {
    function EmployeeSectionsCertificationsComponent(modalService, apiService, decorator) {
        this.modalService = modalService;
        this.apiService = apiService;
        this.decorator = decorator;
        this.newEmpCertification = new EmployeeSectionsCertification();
        this.isSequenceError = false;
        this.sort = [];
        this.actions = { canEdit: true };
        this.enableSave = false;
        this.addNewRow = new EmployeeSectionsCertification();
        this.skip = 0;
        this.pageSize = 10;
        this.codeSize = 255;
        this.nameSize = 255;
        this.descriptionSize = 500;
        this.licenseSize = 50;
        this.confirmingSize = 255;
        this.minDate = new Date(1899, 12, 1);
        this.maxDate = new Date(2079, 5, 6);
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: "code", dir: "asc" }];
        this.gridState.state.take = this.pageSize;
        this.decorator.isEditableByConfiguration = false;
        this.createSubscriptions();
    }
    Object.defineProperty(EmployeeSectionsCertificationsComponent.prototype, "certificatesList", {
        set: function (employeeSectionsCertifications) {
            var _this = this;
            if (employeeSectionsCertifications != null) {
                var actions = employeeSectionsCertifications.fieldsMeta.actions;
                _.forEach(actions, function (v) {
                    if (v === "add")
                        _this.canAdd = true;
                    if (v === "edit")
                        _this.canEdit = true;
                    if (v === "delete")
                        _this.canDelete = true;
                });
                this.certifications = employeeSectionsCertifications.certificates;
                this.refreshGrid();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsCertificationsComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsCertificationsComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    EmployeeSectionsCertificationsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsCertificationsComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function (item) {
            _this.isSequenceError = false;
            _this.editingItem = item;
            _this.addMode = false;
        });
        this.gridCancelSubscription = this.gridState.onCancel$.subscribe(function (item) {
            _this.editingItem = null;
            _this.addMode = false;
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (item) {
            if (item.isNew) {
                item.dataItem.id = null;
                _this.addMode = false;
            }
            _this.doUpdate(item.dataItem);
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (item) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialog2Component.openDialog("Delete Certificate", "Are you sure that you want to remove this certificate?", _this.modalService, function (result) {
                if (result) {
                    _this.doRemove(item.dataItem);
                }
            }, options);
        });
    };
    EmployeeSectionsCertificationsComponent.prototype.onStartAdd = function () {
        this.enableSave = false;
        this.addMode = true;
        this.newEmpCertification.certDate = null;
        this.newEmpCertification.endDate = null;
        this.newEmpCertification.code = null;
        this.newEmpCertification.confirmingBody = null;
        this.newEmpCertification.description = null;
        this.newEmpCertification.id = null;
        this.newEmpCertification.license = null;
        this.newEmpCertification.licenseSequence = null;
        this.newEmpCertification.name = null;
        this.grid.addRow(this.newEmpCertification);
    };
    EmployeeSectionsCertificationsComponent.prototype.doUpdate = function (item) {
        var _this = this;
        this.isLoading = true;
        if (item.certDate != null) {
            item.certDate = new Date(item.certDate).toDateString();
        }
        if (item.endDate != null) {
            item.endDate = new Date(item.endDate).toDateString();
        }
        this.apiService.setCertificate(item, this.employeeId).then(function (status) {
            if (status) {
                _this.loadCheckList();
            }
        });
    };
    EmployeeSectionsCertificationsComponent.prototype.doRemove = function (item) {
        var _this = this;
        this.certifications = _.without(this.certifications, item);
        this.isLoading = true;
        this.apiService.deleteCertificate(item, this.employeeId).then(function (status) {
            if (status) {
                _this.refreshGrid();
                _this.isLoading = false;
            }
        });
    };
    EmployeeSectionsCertificationsComponent.prototype.refreshGrid = function () {
        if (!this.certifications) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.certifications, this.gridState.state);
    };
    EmployeeSectionsCertificationsComponent.prototype.onChangeValue = function (item) {
        var isCode = this.validateCode(item.code, this.codeSize);
        var isName = this.validateString(item.name, this.nameSize);
        var isDescription = this.validateString(item.description, this.descriptionSize);
        var isLicense = this.validateString(item.license, this.licenseSize);
        var isConfirmingBody = this.validateString(item.confirmingBody, this.confirmingSize);
        var isSequence = this.validateSequence(item.licenseSequence);
        var isCertDate = this.validateDate(item);
        var isEndDate = this.validateDate(item);
        if (isCode &&
            isName &&
            isDescription &&
            isLicense &&
            isConfirmingBody &&
            isSequence &&
            isCertDate &&
            isEndDate) {
            this.enableSave = true;
        }
        else {
            this.enableSave = false;
        }
    };
    EmployeeSectionsCertificationsComponent.prototype.validateCode = function (value, size) {
        var validate;
        if (value == null || value == "") {
            validate = false;
        }
        else {
            validate = true;
        }
        if (value != null && value != "") {
            if (value.length > size) {
                validate = false;
            }
            else {
                validate = true;
            }
        }
        return validate;
    };
    EmployeeSectionsCertificationsComponent.prototype.validateString = function (value, size) {
        var validate;
        if (value != null) {
            if (value.length > size) {
                validate = false;
            }
            else {
                validate = true;
            }
        }
        else {
            validate = true;
        }
        return validate;
    };
    EmployeeSectionsCertificationsComponent.prototype.validateSequence = function (value) {
        var validate;
        if (value != null) {
            if (value > 255 || value < 0) {
                validate = false;
                this.isSequenceError = true;
            }
            else {
                validate = true;
                this.isSequenceError = false;
            }
        }
        else {
            validate = true;
            this.isSequenceError = false;
        }
        return validate;
    };
    EmployeeSectionsCertificationsComponent.prototype.validateDate = function (item) {
        var validate;
        var CertDate = new Date(item.certDate);
        var EndDate = new Date(item.endDate);
        if (item.certDate != null && item.endDate != null) {
            if (CertDate > EndDate) {
                validate = false;
                this.certError = true;
            }
            else if (EndDate < CertDate) {
                validate = false;
                this.endError = true;
            }
            else {
                validate = true;
                this.certError = false;
                this.endError = false;
            }
        }
        else {
            validate = true;
            this.certError = false;
            this.endError = false;
        }
        return validate;
    };
    EmployeeSectionsCertificationsComponent.prototype.loadCheckList = function () {
        var _this = this;
        this.apiService
            .getCertifications(this.employeeId)
            .then(function (certifications) {
            _this.certifications = certifications.certificates;
            _this.refreshGrid();
            _this.isLoading = false;
        });
    };
    EmployeeSectionsCertificationsComponent.prototype.pageChange = function (event) {
        this.skip = event.skip;
        this.refreshGrid();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsCertificationsComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsCertificationsComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsCertificationsComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsCertificationsComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsCertificationsComponent.prototype, "gridCancelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsCertificationsComponent.prototype, "formValueChangeSubscription", void 0);
    return EmployeeSectionsCertificationsComponent;
}());
export { EmployeeSectionsCertificationsComponent };
