import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AppSettingsManageService } from '../../../../app-settings/services';
var WFMOngoingSyncComponent = /** @class */ (function () {
    function WFMOngoingSyncComponent(appSettingManageService) {
        this.nextgenPhasedRollout = false;
        this.appSettingManageService = appSettingManageService;
    }
    WFMOngoingSyncComponent.prototype.ngOnInit = function () {
        this.getSettings();
    };
    WFMOngoingSyncComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
                        return [2 /*return*/];
                }
            });
        });
    };
    return WFMOngoingSyncComponent;
}());
export { WFMOngoingSyncComponent };
