import { IdealSchedulePositionRange } from './../../../models/ideal-schedule/ideal-schedule-position-range';
import { Component, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-ideal-schedule-total-grid',
    templateUrl: 'ideal-schedule-total-grid.component.html',
    styleUrls: ['ideal-schedule-total-grid.component.scss']
})

export class IdealScheduleTotalGridComponent {
    @Input()
    public ranges: IdealSchedulePositionRange[];

    public rangeName(range: IdealSchedulePositionRange): string {
        return `Range - Default (${range.acuity.minValue || 0} - ${range.acuity.maxValue || 0})`;
    }

}
