var ExceptionConsoleRollupRecord = /** @class */ (function () {
    function ExceptionConsoleRollupRecord() {
    }
    Object.defineProperty(ExceptionConsoleRollupRecord.prototype, "missingPunches", {
        get: function () {
            return this.pendingMissingPunchesCount + "/" + this.approvedMissingPunchesCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExceptionConsoleRollupRecord.prototype, "orgName", {
        get: function () {
            return this.organization ? this.organization.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExceptionConsoleRollupRecord.prototype, "depName", {
        get: function () {
            return this.department ? this.department.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExceptionConsoleRollupRecord.prototype, "startDate", {
        get: function () {
            return this.payCycle ? this.payCycle.startDate : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExceptionConsoleRollupRecord.prototype, "endDate", {
        get: function () {
            return this.payCycle ? this.payCycle.endDate : null;
        },
        enumerable: true,
        configurable: true
    });
    ExceptionConsoleRollupRecord.prototype.getExceptionCount = function (name) {
        return this.exceptionsMap && this.exceptionsMap[name] ? this.exceptionsMap[name].exceptionCount : 0;
    };
    return ExceptionConsoleRollupRecord;
}());
export { ExceptionConsoleRollupRecord };
