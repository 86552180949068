import { DialogOptions2, DialogModeSize } from './../../../../../../common/models/dialog-options';
import { Component, Provider } from '@angular/core';
import { IDialog, DialogOptions } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { BenefitsEnrollmentSectionManagementService } from '../../../../services';
import * as _ from 'lodash';
import { EmployeeSectionsBase } from '../../../../models';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';
import { employeeSectionsConfig } from '../../../../employee-sections.config';
import { ReadFile, AttachmentFile, UiAttachmentFile } from '../../../../../../organization/models/index';

@Component({
  selector: 'benefits-add-file-modal.component',
  templateUrl: './benefits-add-file-modal.component.html',
  styleUrls: ['./benefits-add-file-modal.component.scss']
})
export class BenefitAddAttachmentsDialogComponent implements IDialog {

  public dialogResult: boolean;
  public enrollmentStartDate: Date;
  public enrollmentEndDate: Date;
  public notes: string;
  public appConfig: IApplicationConfig = appConfig;
  public errors: { maxFileSize?: boolean, fileType?: boolean, zeroFileSize?: boolean } = {};
  public acceptedFileTypesInputFormat: string;
  public acceptedFileTypes: string[];
  public maxFileSize: string;
  public maxFileSizeBytes: number;
  public acceptedFileTypesReadable: string;
  public manService: BenefitsEnrollmentSectionManagementService;
  public files: ReadFile[];
  public customFileName: string;
  public isFileSelected: boolean = true;

  public static openDialog(modalService: ModalService, callback?: (result: boolean) => void): BenefitAddAttachmentsDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.dialog;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },

    ];

    return modalService.globalAnchor
      .openDialog2(BenefitAddAttachmentsDialogComponent, 'Add Employee Document', dialogOptions, resolvedProviders,
        (result: boolean) => {
          callback(result);
        });
  }

  constructor(private dialogOptions: DialogOptions, private modalService: ModalService, manService: BenefitsEnrollmentSectionManagementService) {
    this.modalService = modalService;
    this.manService = manService;
    this.maxFileSizeBytes = employeeSectionsConfig.files.maxFileSizeBytes;
    this.acceptedFileTypes = employeeSectionsConfig.files.acceptedTypes;
    this.acceptedFileTypesInputFormat = employeeSectionsConfig.files.acceptedTypes.join(',');
    this.acceptedFileTypesReadable = employeeSectionsConfig.files.acceptedTypes.join(', ');
    this.maxFileSize = this.getReadableBytes(employeeSectionsConfig.files.maxFileSizeBytes);
  }

  public async onFileChange(event: MouseEvent): Promise<void> {
    const fileList: FileList = _.get(event, 'target.files');
    const files = this.validateFiles(fileList, event);
    if (files.length > 0) {
      this.files = await this.manService.readAddedFiles(files);
      this.customFileName = this.files[0].name;
      this.isFileSelected = false;
    }
  }

  private validateFiles(fileList: FileList, event: MouseEvent): File[] {
    let isError = false;
    const files: File[] = [];
    for (let i = 0, length = fileList.length; i < length; i++) {
      const file = fileList.item(i);
      if (file.size > this.maxFileSizeBytes) {
        isError = true;
        this.setError('maxFileSize');
        break;
      }
      if (file.size === 0) {
        isError = true;
        this.setError('zeroFileSize');
        break;
      }
      const fileExt = `.${_.last(file.name.split('.'))}`;
      if (!_.includes(this.acceptedFileTypes, fileExt.toLowerCase())) {
        isError = true;
        this.setError('fileType');
        break;
      }
      files.push(file);
    }
    this.resetInput(event.target);

    return isError ? [] : files;
  }

  public onResetErrors(event: any): void {
    this.resetInput(event.target);
    this.errors = {};
  }

  private setError(errorType: string): void {
    this.errors.maxFileSize = false;
    this.errors.fileType = false;
    switch (errorType) {
      case 'maxFileSize':
        this.errors.maxFileSize = true;
        break;
      case 'fileType':
        this.errors.fileType = true;
        break;
      case 'zeroFileSize':
        this.errors.zeroFileSize = true;
        break;
    }
  }

  private resetInput(elem: any): void {
    elem.value = '';
    this.isFileSelected = true;
    this.customFileName = '';
  }

  public save(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  private getReadableBytes(bytes: number): string {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const res = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));

    return `${res * 1} ${sizes[i]}`;
  }


}
