import * as i0 from "@angular/core";
var DurationInputService = /** @class */ (function () {
    function DurationInputService() {
        this.numRegx = '[0-9]*';
        this.decimalRegx = "[0-9]*[\\.,][0-9]*[1-9][0-9]*";
        this.spaceRegx = "[\\s]*";
        this.hrRegx = this.spaceRegx + "[\u00F3\u00D3hH:]" + this.spaceRegx; // TODO : handle differently ( 1: -> 1ó engedjük e), :15 nem parsolja
        this.minRegx = this.spaceRegx + "[pPmM]" + this.spaceRegx;
        this.justNumbers = "^" + this.numRegx + this.spaceRegx + "$"; // 150 -> 150 perc
        this.numbersWithMinPostfix = "^" + this.numRegx + this.spaceRegx + this.minRegx + this.spaceRegx + "$"; // 150p -> 150 perc
        this.numbersWithHourPostfix = "^" + this.numRegx + this.spaceRegx + this.hrRegx + this.spaceRegx + "$"; // 2ó -> 120 perc
        this.decimalNumbers = "^" + this.decimalRegx + this.spaceRegx + "$"; // 1.5 -> 90 perc
        this.decimalNumbersWithHourPostfix = "^" + this.decimalRegx + this.spaceRegx + this.hrRegx + this.spaceRegx + "$"; // 1.5ó -> 90 perc
        this.numbersWithHourInfix = "^" + this.numRegx + this.hrRegx + this.numRegx + this.spaceRegx + "$"; // 1ó15 -> 75 perc
        this.numbersWithWhitespaceInfix = "^" + this.numRegx + this.spaceRegx + this.numRegx + this.spaceRegx + "$"; // 1 15 -> 75 perc
        this.numbersWithAllPostfix = "^" + this.numRegx + this.hrRegx + this.numRegx + this.minRegx + "$"; // 1ó15p -> 75 perc
        this.durationRegex = "(" +
            [
                this.justNumbers,
                this.numbersWithMinPostfix,
                this.numbersWithHourPostfix,
                this.decimalNumbers,
                this.decimalNumbersWithHourPostfix,
                this.numbersWithHourInfix,
                this.numbersWithWhitespaceInfix,
                this.numbersWithAllPostfix
            ].join(')|(') +
            ")";
    }
    DurationInputService.prototype.getDurationString = function (duration) {
        if (isNaN(duration)) {
            return null;
        }
        var value = '';
        var hr = Math.floor(duration / 60);
        var min = duration - 60 * hr;
        if (hr) {
            // value = `${hr} ${this.translateService.instant('HOUR_SHORT')}`;
            value = hr + " h";
        }
        if (hr && min) {
            value += ' ';
        }
        if (min || duration === 0) {
            // value += `${min} ${this.translateService.instant('MINUTE_SHORT')}`;
            value += min + " m";
        }
        return value;
    };
    DurationInputService.prototype.parseDurationString = function (value) {
        value = (value + " ").trim();
        if (this.isEmptyString(value)) {
            return;
        }
        // convert to <x>h<y> format
        if (value.match(this.justNumbers)) {
            // 150 -> 150 perc
            value = "0h" + value;
        }
        else if (value.match(this.numbersWithMinPostfix)) {
            // 150p -> 150 perc
            value = "0h" + this.removeMin(value);
        }
        else if (value.match(this.numbersWithHourPostfix)) {
            // 2ó -> 120 perc
            value = this.removeHour(value) + "h0";
        }
        else if (value.match(this.decimalNumbers)) {
            // 1.5 -> 90 perc
            value = this.stdizeSeparator(value) + "h0";
        }
        else if (value.match(this.decimalNumbersWithHourPostfix)) {
            // 1.5ó -> 90 perc
            value = this.removeHour(this.stdizeSeparator(value)) + "h0";
        }
        else if (value.match(this.numbersWithHourInfix)) {
            // 1ó15 1:15 -> 75 perc
            value = this.stdizeHour(value);
        }
        else if (value.match(this.numbersWithWhitespaceInfix)) {
            // 1 15 -> 75 perc
            value = value
                .toLocaleLowerCase()
                .split(/\s+/)
                .join('h');
        }
        else if (value.match(this.numbersWithAllPostfix)) {
            // 1ó15p -> 75 perc
            value = this.removeMin(value.toLocaleLowerCase().replace('ó', 'h'));
        }
        else {
            return;
        }
        var values = value.split('h');
        var hr = parseFloat(values[0].trim());
        var min = parseFloat(values[1].trim());
        return Math.ceil(hr * 60) + min;
    };
    DurationInputService.prototype.isParseable = function (value) {
        if (this.isEmptyString(value)) {
            return false;
        }
        return !!value.match(this.durationRegex);
    };
    DurationInputService.prototype.isEmptyString = function (value) {
        return (value + " ").trim() === '';
    };
    DurationInputService.prototype.stdizeSeparator = function (value) {
        return value.replace(',', '.');
    };
    DurationInputService.prototype.stdizeHour = function (value) {
        return value
            .toLocaleLowerCase()
            .replace('ó', 'h')
            .replace(':', 'h');
    };
    DurationInputService.prototype.removeMin = function (value) {
        return value
            .toLocaleLowerCase()
            .replace('p', '')
            .replace('m', '');
    };
    DurationInputService.prototype.removeHour = function (value) {
        return value
            .toLocaleLowerCase()
            .replace('ó', '')
            .replace('h', '')
            .replace(':', '');
    };
    DurationInputService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DurationInputService_Factory() { return new DurationInputService(); }, token: DurationInputService, providedIn: "root" });
    return DurationInputService;
}());
export { DurationInputService };
