import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { OrgLevel, OrgLevelType } from '../../../../../../state-model/models';

import { BenefitListManagementService } from './../../../../services/benefit-console/index';
import { BenefitConsoleCommonService } from './../../../../services/benefit-console/benefit-console-common.service';
import { BenefitsNavigationService } from '../../../../../../common/services/index';

@Component({
    moduleId: module.id,
    selector: 'slx-benefit-plan-type',
    templateUrl: 'benefit-plan-type.component.html',
    styleUrls: ['benefit-plan-type.component.scss']
})

export class BenefitPlanTypeComponent implements OnInit, OnDestroy {

    @Input()
    public title: String;
    @Input()
    public icon: String;
    @Input()
    public groupId: number;
    @Input()
    public index: number;
    @Input()
    public creatPlanAccess: boolean;
    @Input()
    public validatingFunction: () => boolean;

    public isShowExpired: boolean;
    public navService: BenefitsNavigationService;

    public get hasErrors(): boolean {
        if (this.validatingFunction) {
            return this.validatingFunction();
        }
        return false;
    }

    public get isCollapsed(): boolean {
        return this.m_isCollapsed;
    }

    public get canCreateNew(): boolean {
      return _.isObjectLike(this.orgLevel) && this.orgLevel.type !== OrgLevelType.department;
    }

    private m_isCollapsed: boolean = true;
    private singleGroupCalled: boolean = true;

    @unsubscribeAll()
    public subscriptions: StringMap<Subscription> = {};
    private orgLevel: OrgLevel;

    constructor(
        private manService: BenefitListManagementService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public commonService: BenefitConsoleCommonService
    ) {
        this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
    }

    public getDataForExpandedGroups() {
        if(this.subscriptions.expandedGroups) {
            this.subscriptions.expandedGroups.unsubscribe();
        }
        this.subscriptions.expandedGroups = this.commonService.subscribetoExpandedGroups((currentExpandedGroups) => {
            if(!this.singleGroupCalled) {
                if(_.includes(currentExpandedGroups, this.groupId) && !this.m_isCollapsed) {
                    this.manService.getBenefitPlanTypes(this.groupId, this.isShowExpired);
                }
            }
        });
    }

    callExpandedGroupsData() {
        this.singleGroupCalled = false;
        this.getDataForExpandedGroups();
        this.singleGroupCalled = true;
    }

    public ngOnInit(): void {
        this.subscriptions.orgLevel = this.manService
        .subscribeToOrgLevel((v: OrgLevel) => {
          this.orgLevel = v;
          this.callExpandedGroupsData();
        });
        this.subscriptions.showExpired = this.manService
        .subscribeToIsLoadingExpired((r: boolean) => {
            this.isShowExpired = r;
            this.callExpandedGroupsData();
        });
        if(this.groupId === 1) {
            this.m_isCollapsed = false;
            this.singleGroupCalled = true;
        }
    }

    public ngOnDestroy(): void {
        _.forEach(this.subscriptions, (s: Subscription) => {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    }

    public collapsedChange(isCollapsed: boolean, groupId: number): void {
        this.singleGroupCalled = true;
        this.m_isCollapsed = isCollapsed;
        if(groupId) {
            if (isCollapsed) {
                this.commonService.removeCollapsedGroups(groupId);
            } else {
                this.commonService.recordExpandedGroups(groupId);
                this.manService.getBenefitPlanTypes(groupId, this.isShowExpired);
            }
        }
    }

    public createNewPlans() {
        this.navService.navigateToDetails(this.orgLevel.id, parseInt(this.groupId as any));
    }

    public getCollapsedState(): boolean {
        return (this.index === 0) ? false : true;
    }

}
