import * as _ from 'lodash';
import { Role, RoleField, RoleSubsection, RoleSection, RoleProfile, RolesAccess } from '../role-models/index';
import { RolesFieldRow } from './roles-field-row';
import { RolesRowDefinition, IRolesRow } from './roles-row';

const commonRecalcStatus = function (row: RolesProfileRow | RolesSectionRow | RolesSubsectionRow, roleId: number): void {
  let item: RoleSubsection | RoleSection | RoleProfile = row.mapByRole[roleId];
  let existNone: boolean = false;
  let existEdit: boolean = false;
  let existView: boolean = false;
  let existMasked: boolean = false;
  let existMixed: boolean = false;
  row.dirtyByRole[roleId] = false;
  _.forEach(row.childRows, (childRow: RolesSectionRow | RolesSubsectionRow | RolesFieldRow) => {
    let child: RoleSubsection | RoleSection | RoleField = childRow.mapByRole[roleId];
    existNone = existNone || (child.access === RolesAccess.none);
    existEdit = existEdit || (child.access === RolesAccess.edit);
    existView = existView || (child.access === RolesAccess.view);
    existMasked = existMasked || (child.access === RolesAccess.masked);
    existMixed = existMixed || (child.access === RolesAccess.mixed);
    //if (existMixed) {
    //  return false;
    //}
    if ((existNone && existEdit) || (existNone && existView) || (existNone && existMasked) || (existEdit && existView) || (existEdit && existMasked) || (existView && existMasked)) {
      existMixed = true;
      //  return false;
    }

    if (childRow.dirtyByRole[roleId]) {
      row.dirtyByRole[roleId] = true;
    }
    //return true;
  });
  if (existMixed) {
    item.access = RolesAccess.mixed;
  } else if (existNone) {
    item.access = RolesAccess.none;
  } else if (existEdit) {
    item.access = RolesAccess.edit;
  } else if (existMasked) {
    item.access = RolesAccess.masked;
  } else if (existView) {
    item.access = RolesAccess.view;
  }
};

const commonNextStatus = function (row: RolesProfileRow | RolesSectionRow | RolesSubsectionRow, roleId: number): void {
  let item: RoleSubsection | RoleSection | RoleProfile = row.mapByRole[roleId];
  let access: RolesAccess;
  if (item.access === RolesAccess.none) {
    access = RolesAccess.edit;
  } else if (item.access === RolesAccess.edit) {
    access = RolesAccess.view;
  } else if (item.access === RolesAccess.view) {
    access = RolesAccess.masked;
  } else {
    access = RolesAccess.none;
  }
  commonSetStatus(row, access, roleId);
};

const commonSetStatus = function (row: RolesProfileRow | RolesSectionRow | RolesSubsectionRow, access: RolesAccess, roleId: number): void {
  let item: RoleSubsection | RoleSection | RoleProfile = row.mapByRole[roleId];
  item.access = access;
  row.dirtyByRole[roleId] = false;
  _.forEach(row.childRows, (childRow: RolesSectionRow | RolesSubsectionRow | RolesFieldRow) => {
    childRow.setStatus(access, roleId);
    if (childRow.dirtyByRole[roleId]) {
      row.dirtyByRole[roleId] = true;
    }
  });
};

export class RolesProfileRow implements IRolesRow<RoleProfile> {
  public roles: Role[];
  public mapByRole: NumberMap<RoleProfile>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesSectionRow[];
  public type: RolesRowDefinition = 'RolesProfileRow';
  public description: string;

  public recalcStatus(roleId: number): void {
    commonRecalcStatus(this, roleId);
  }
  public setNextStatus(roleId: number): void {
    commonNextStatus(this, roleId);
  }
  public setStatus(access: RolesAccess, roleId: number): void {
    commonSetStatus(this, access, roleId);
  }
}

export class RolesSectionRow implements IRolesRow<RoleSection> {
  public roles: Role[];
  public mapByRole: NumberMap<RoleSection>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesSubsectionRow[];
  public type: RolesRowDefinition = 'RolesSectionRow';
  public description: string;
  public recalcStatus(roleId: number): void {
    commonRecalcStatus(this, roleId);
  }
  public setNextStatus(roleId: number): void {
    commonNextStatus(this, roleId);
  }
  public setStatus(access: RolesAccess, roleId: number): void {
    commonSetStatus(this, access, roleId);
  }
}

export class RolesSubsectionRow implements IRolesRow<RoleSubsection> {
  public roles: Role[];
  public mapByRole: NumberMap<RoleSubsection>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesFieldRow[];
  public type: RolesRowDefinition = 'RolesSubsectionRow';
  public description: string;
  public recalcStatus(roleId: number): void {
    commonRecalcStatus(this, roleId);
  }
  public setNextStatus(roleId: number): void {
    commonNextStatus(this, roleId);
  }
  public setStatus(access: RolesAccess, roleId: number): void {
    commonSetStatus(this, access, roleId);
  }
}
