import { BenefitDetailsLine } from './../../models/benefit-details/benefit-details-line';
import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../../common/services/index';
import { BenefitDetailsExtendDialogComponent } from '../../components/benefit-details/benefit-details-extend-dialog/benefit-details-extend-dialog.component';
import { BenefitDetailsLineStandart } from '../../models/index';

@Directive({
  selector: '[slxBenefitDetailsExtendDialog]',
})
export class BenefitDetailsExtendDialogDirective {
  constructor(private modalService: ModalService) {}

  @Input()
  public slxBenefitDetailsExtendDialog: BenefitDetailsLineStandart;
  @Input()
  public canDoAction: boolean;
  @Input()
  public benefitdeduction2: boolean = false;
  @Output()
  public changedExtendedNewEndDate = new EventEmitter<Date>();
  @Input()
  public nextLineDetails: BenefitDetailsLine;

  @HostListener('click', ['$event'])
  public onClick(): void {
    if (this.canDoAction) {
      const extendedDateValue = BenefitDetailsExtendDialogComponent.openDialog(
        this.slxBenefitDetailsExtendDialog,this.benefitdeduction2,
        this.modalService,
        this.nextLineDetails,
        (result: boolean) => {
          if (result) {
            this.changedExtendedNewEndDate.emit(extendedDateValue.benefitDetailsLineEndDate);
          }
        }
      );
    }
  }
}
