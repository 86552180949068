<slx-spinner [show]="isLoading">
    <div class="slx-high-box benefit-employees-eligible ">
        <div class="slx-high-box__body">
            <form #form="ngForm" novalidate class="slx-full-height">
                <kendo-grid #kendoGrid
                    class="slx-blue-grid slx-transparent-selection slx-grid-slim-rows slx-full-height"
                    slxKendoGridState="BENEFIT_OPTION_FLAT_GRID" [data]="gridState?.view"
                    [filterable]="'menu'" [sortable]="true" [filter]="gridState.state.filter"
                    [sort]="gridState.state.sort" [selectable]="selectableSettings"
                    (dataStateChange)="gridState.dataStateChange($event)" 
                    (edit)="gridState.editHandler($event)">

                    <kendo-grid-column title="Max Contribution" width="180" field="maxContribution" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Max Contribution</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{ dataItem.maxContribution  | number: ".2-2"| slxMoney }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Suggested Contribution" width="180" field="employerContribution" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Suggested Contribution</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{ dataItem.suggestedContribution | number:'.2'| slxPercent }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Max Matching Grant (%)" width="180" field="maxMatchGrant" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Max Matching Grant (%)</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{ dataItem.maxMatchGrant | number:'.2'| slxPercent }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Min Contribution" width="180" field="minContribution" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Min Contribution</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{ dataItem.empContribution | number:'.2'| slxPercent }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Max Employee Contribution (%)" width="180" field="maxEmpContribution" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Max Employee Contribution (%)</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{ dataItem.maxEmpContribution  | number:'.2'| slxPercent }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="limitAmount" width="180" field="limitAmount" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Limit Amount</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{ dataItem.limitAmount  | number: ".2-2"| slxMoney }}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="compensationLimit" width="180" field="compensationLimit" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Compensation Limit</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{ dataItem.compensationLimit | number: ".2-2"| slxMoney }}
                        </ng-template>
                    </kendo-grid-column>

                </kendo-grid>
            </form>
        </div>
    </div>
</slx-spinner>