<div class="result-container">
    <h3>Copied Policy Status</h3>
    <kendo-grid class="copy-policy-result-grid" [kendoGridBinding]="resultData.data">
        <kendo-grid-column title="Accrual Policy" field="policyName" [width]="300"></kendo-grid-column>
        <kendo-grid-column title="Location" field="organizationName" [width]="190"></kendo-grid-column>
        <kendo-grid-column filter="text" [width]="150">
            <ng-template kendoGridHeaderTemplate>
                Status
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <span class="slx-copy-status">
                    <span [ngStyle]="{'color': dataItem.statusMessage === 'Successful' ? '#8fad1e' : 'darkred'}">
                        {{dataItem.statusMessage}}
                    </span>
                    <span *ngIf="dataItem.statusMessage !== 'Successful'">(Existing Policy)</span>
                </span>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>
<div class="footer-btns-container">
    <button type="button" class="discard-btn" (click)="discard()">
        Close
    </button>
</div>