import { Component, OnInit, Provider, SimpleChanges } from '@angular/core';
import { IdealScheduleImportTemplate, ImportException } from './../../../../../app/configuration/models/ideal-schedule/ideal-schedule-import-template';
import { DialogOptions, IDialog, ModalService, StartEndDates, StartEndDatesDialogComponent } from './../../../../../app/common';
import { IdealScheduleConfigType, IdealScheduleConfigTypes, IdealScheduleConfigCensus, IdealScheduleConfigCensusOptions, IdealSchedulePosition } from './../../../../../app/configuration/models';
import * as _ from 'lodash';
import { IdealScheduleApiService, IdealScheduleStateService } from './../../../../../app/configuration/services';
import * as moment from 'moment';
import { IdealScheduleTemplateExcelValidationService } from './../../../../../app/configuration/services/ideal-schedule/ideal-schedule-template-excel-validation.service';
import { ImportFileDetails, UIException } from './../../../../../app/configuration/models/ideal-schedule/ideal-schedule-import-template-validator';
import { DateRange } from './../../../../../app/core/models';
import { Subscription } from 'rxjs';
import { NotificationsService } from './../../../../../app/core/components';
import { Router } from '@angular/router';

@Component({
  selector: 'slx-ideal-schedule-import-template-dialog',
  templateUrl: './ideal-schedule-import-template-dialog.component.html',
  styleUrls: ['./ideal-schedule-import-template-dialog.component.scss']
})
export class IdealScheduleImportTemplateDialogComponent implements IDialog, OnInit {

  public dialogResult: boolean;
  public dialogOptions: DialogOptions;
  public modalService: ModalService;
  public importTemplateRequest: IdealScheduleImportTemplate;
  public IdealScheduleConfigTypes: IdealScheduleConfigType[] = IdealScheduleConfigTypes;
  public IdealScheduleConfigCensusOptions: IdealScheduleConfigCensus[] = IdealScheduleConfigCensusOptions;

  public isAllScheduleCheck: boolean = false;
  public isFileExist: boolean = false;
  public isDatePopupTriggered: boolean = false;

  public exceptionList: UIException[] = [];
  public apiExceptionList: ImportException[] = [];
  public startDate: Date = moment().add(1, 'month').startOf('month').toDate();
  public endDate: Date = moment('06/06/2079').toDate();
  public dateRange: DateRange = {
    startDate: this.startDate,
    endDate: this.endDate
  };

  private uiExceptionSubscription: Subscription;
  private apiExceptionSubscription: Subscription;
  public isFileUploadMessageShown: boolean = false;

  public static openDialog(importTemplateRequest: IdealScheduleImportTemplate, dialogOptions: DialogOptions, modalService: ModalService, callback: (result: boolean, importTemplateRequest: IdealScheduleImportTemplate) => void): IdealScheduleImportTemplateDialogComponent {
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: IdealScheduleImportTemplate,
        useValue: importTemplateRequest
      }
    ];
    let dialog: IdealScheduleImportTemplateDialogComponent = modalService.globalAnchor
      .openDialog(IdealScheduleImportTemplateDialogComponent, importTemplateRequest.header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, importTemplateRequest);
      });
    return dialog;
  }

  constructor(dialogOptions: DialogOptions,
    modalService: ModalService,
    importTemplateRequest: IdealScheduleImportTemplate,
    public stateService: IdealScheduleStateService,
    public excelValidatorService: IdealScheduleTemplateExcelValidationService,
    public apiService: IdealScheduleApiService,
    public notifyService: NotificationsService,
    public router:Router
  ) {
    this.dialogOptions = dialogOptions;
    this.modalService = modalService;
    this.importTemplateRequest = importTemplateRequest;

    this.uiExceptionSubscription = this.excelValidatorService._uiExceptionTrigger$.subscribe((data) => {
      this.exceptionList = data;
      this.stateService.state.isLoading = false;
    });

    this.apiExceptionSubscription = this.excelValidatorService._apiExceptionTrigger$.subscribe((data) => {
      this.apiExceptionList = data;
      this.stateService.state.isLoading = false;
    });
  }

  ngOnInit() {
  }

  public selectAllIdealSchedule(e, positions) {
    this.isAllScheduleCheck = e.target.checked;
    positions.map(i => this.onChangeIdealScheduleCheck(e, i));
    this.importTemplateRequest.positionList = this.updateSelectAll(this.isAllScheduleCheck, positions);
  }

  public onChangeIdealScheduleCheck(e: any, data: any) {
    data.isChecked = e.target.checked;
    this.checkConfigTypeWhileChange();
  }

  public onChangeIdealScheduleConfigType(e: any, data: any) {
    data.idealScheduleType = e;
    this.checkConfigTypeWhileChange();
  }

  public onChangeIdealScheduleCensusType(e: any, data: any) {
    data.idealScheduleCensus = e;
  }

  public async save(isDownloadTemplate: boolean) {
    this.dialogResult = true;
    this.stateService.state.isLoading = true;
    if (isDownloadTemplate) {
      return this.stateService.checkPositionsValidation(this.modalService, this.dialogOptions.windowUniqueId, this.importTemplateRequest);
    }
    else {
      const requestData: ImportFileDetails = new ImportFileDetails();
      requestData.recordId = this.setRecordId(this.stateService.orgLevelId);
      requestData.fileData = this.excelValidatorService._excelData;
      this.apiService.saveIdealScheduleExcel(this.stateService.orgLevelId, requestData, moment(this.dateRange.startDate), moment(this.dateRange.endDate))
        .then((data: any) => { }).catch(err => { console.log(err); })
      this.stateService.state.isLoading = false;
      this.isFileUploadMessageShown = true;
      this.excelValidatorService.isFileExist = false;
    }
  }

  public navigateToFileUploadPage() {
    this.isFileUploadMessageShown = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    this.router.navigateByUrl(`apps/common/File_Uploads?orgLevelId=${this.stateService.orgLevelId}`);
  }

  public discard(isDownloadTemplate: boolean) {
    this.dialogResult = false;
    this.isFileUploadMessageShown = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public get saveDisabled(): boolean {
    return (this.importTemplateRequest.positionList.every(x => !x.isChecked)
      || this.importTemplateRequest.exceptionData.length > 0) ? true : false
  }

  public updateSelectAll(ind, data) {
    data.map(x => x.position.isChecked = ind);
    return data;
  }

  public isInvalidConfigType(dataItem) {
    if (this.importTemplateRequest.exceptionData.length === 0) return false;
    let index = this.importTemplateRequest.exceptionData.findIndex(x => x.positionId == dataItem.position.id && x.isInvalidConfigType);
    if (index >= 0) {
      dataItem.isInvalidConfigType = this.importTemplateRequest.exceptionData[index].isInvalidConfigType;
    }
    else {
      dataItem.isInvalidConfigType = false;
    }
    return dataItem.isInvalidConfigType;
  }

  public isInvalidCensusType(dataItem) {
    if (this.importTemplateRequest.exceptionData.length === 0) return false;
    let index = this.importTemplateRequest.exceptionData.findIndex(x => x.positionId == dataItem.position.id && x.isInvalidCensusType);
    if (index >= 0) {
      dataItem.isInvalidCensusType = this.importTemplateRequest.exceptionData[index].isInvalidCensusType;
    }
    else {
      dataItem.isInvalidCensusType = false;
    }
    return dataItem.isInvalidCensusType;
  }

  public checkConfigTypeWhileChange() {
    let selectedShiftGroupData = this.importTemplateRequest.positionList.filter(x => x.isChecked && x.idealScheduleType.id == 1);
    if (selectedShiftGroupData && selectedShiftGroupData.length > 0) {
      if (this.stateService.isDefaultShiftConfigured) {
        const exceptionData = _.map(selectedShiftGroupData, (item) => {
          const data: ImportException = new ImportException();
          data.positionId = item.position.id;
          data.positionName = item.position.name;
          data.configurationType = item.idealScheduleType.name;
          data.censusType = item.idealScheduleCensus.name;
          data.errorMessage = 'Default Shifts Not Configured';
          data.isInvalidConfigType = true;
          data.isInvalidCensusType = false;
          data.isNoException = false;
          return data;
        });
        setTimeout(() => {
          this.importTemplateRequest.exceptionData = exceptionData;
        }, 1000)
        return this.importTemplateRequest;
      }
    }
    else {
      this.importTemplateRequest.exceptionData = [];
      return this.importTemplateRequest;
    }
  }

  public filterConfigData(data) {
    if (!this.importTemplateRequest.isDefaultShiftGroupEnabled)
      return data.filter(x => x.id != 1);
    else
      return data;
  }

  public validateNewChanges(e: any) {
    this.excelValidatorService._excelData$.next([e]);
  }

  public discardUpload(e: any) {
    this.excelValidatorService.isFileExist = false;
    this.exceptionList = [];
    this.apiExceptionList = [];
  }

  public onDateRangeChanged(e) {
    this.dateRange = e;
  }

  private setRecordId(orgLevelId): string {
    let orgLevelDetails = JSON.parse(sessionStorage.getItem('Organizations'));
    let obj = _.filter(orgLevelDetails, (item) => item.id == orgLevelId);
    let recordId = `${obj[0].parentId}_${obj[0].id}_${this.stateService.getUniqueId()}`;
    return recordId;
}

}
