import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ColumnSettingsStorageService } from '../column-settings/column-settings-storage.service';
import { IColumnSettings, IColumnGroupsState, IColumnsState } from '../../../core/models/index';

type groupWrapper = { count: number, columns: IColumnSettings[] };
export type GroupEvent = { component: string, group: string };
export type ColumnsEvent = { component: string, group: string, column: IColumnSettings };
export type ColumnsChangedEvent = { component: string, group: string, columns: IColumnSettings[] };

@Injectable()
export class ColumnManagementService {

  public initGroup$: ReplaySubject<GroupEvent>;
  public groupInitialized$: ReplaySubject<ColumnsChangedEvent>;
  public columnsChanged$: ReplaySubject<ColumnsChangedEvent>;

  public component: string;
  private groups: StringMap<groupWrapper> = {};


  constructor(private columnSettingsStorageService: ColumnSettingsStorageService) {
    this.initGroup$ = new ReplaySubject<GroupEvent>(1);
    this.groupInitialized$ = new ReplaySubject<ColumnsChangedEvent>(1);
    this.columnsChanged$ = new ReplaySubject<ColumnsChangedEvent>(1);
  }

  public init(component: string): void {
    this.component = component;
  }

  public initGroup(group: string, columnsCount: number): void {
    this.groups[group] = { count: columnsCount, columns: [] };
    this.initGroup$.next({ component: this.component, group: group });
  }

  public initializeGroupWithColumns(group: string, columns: IColumnSettings[]): void {
    this.groups[group] = { count: columns.length, columns: columns };
    this.groupInitialized();
  }

  public columnsChanged(event: ColumnsChangedEvent): void {
    this.columnsChanged$.next(event);
  }

  public registerColumn(group: string, column: IColumnSettings): void {
    if (!this.groups[group]) {
      throw new Error(`unknown group ${group}`);
    }

    let columns: IColumnSettings[] = this.groups[group].columns;
    let exist: boolean = false;
    _.each(columns, (colSettings: IColumnSettings) => {
      if (colSettings.name === column.name) exist = true;
    });
    if (!exist) columns.push(column);

    if (this.groups[group].columns.length === this.groups[group].count) {
      this.groupInitialized();
    }
  }

  public unregisterColumn(group: string, column: IColumnSettings): void {
    if (!this.groups[group]) {
      throw new Error(`unknown group ${group}`);
    }
    _.remove(this.groups[group].columns, (c: IColumnSettings) => c.name === column.name);
  }

  private groupInitialized(): void {
    let grpName: string = _.findKey(this.groups, (g: groupWrapper) => g.columns.length < g.count);
    if (!grpName) {
      this.columnSettingsStorageService.restoreServerColumnsState(this.component)
        .then((value: IColumnGroupsState) => {
          _.forIn(this.groups, (g: groupWrapper, key: string) => {
            let cs: IColumnsState = value[key];
            if (cs) {
              _.forEach(g.columns, (c: IColumnSettings) => {
                if (cs.state[c.name]) {
                  c.displayed = cs.state[c.name].displayed;
                }
              });
            }
            this.groupInitialized$.next({ component: this.component, group: key, columns: g.columns });
            this.columnsChanged({ component: this.component, group: key, columns: g.columns });
          });
        });
    }
  }

}
