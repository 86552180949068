import { IEmployeeDefinition, EmployeeDefinition } from './../../../organization/models/index';

export interface IEmployeeCallLog {
  records: IEmployeeCallLogRecord[];
  canLogPhoneCall: boolean;
  canSendSms: boolean;
  canSendToTimeClock: boolean;
}

export interface IMessageCenterCallLogRecord {
  id: string;
  messageId: string;
  employeeId: number;
  employeeName: string;
  messageType: string;
  body: string;
  senderId: number;
  sentBy: string;
  sentTime: string;
  response: string;
  responseTime: string;
  error: string;
  status: string;
  numSegments: string;
}

export interface IEmployeeCallLogRecord {
  employee: IEmployeeDefinition;
  contactType: string;
  contactMessage: string;
  sentBy: string;
  sentTime: Date;
  response: string;
  responseTime: Date;
  notes: string;
  error: string;
  expiration: Date;
  status: string;
}

export class EmployeeCallLogDto {
  public employeeIds: number[];
  public orgLevelId: number;
  public startDate: string;
  public endDate: string;
}

export class EmployeeCallLog {
  public records: EmployeeCallLogRecord[];
  public canLogPhoneCall: boolean;
  public canSendSms: boolean;
  public canSendToTimeClock: boolean;

  constructor() {
    this.records = [];
  }
}

export class EmployeeCallLogRecord {
  public employee: EmployeeDefinition;
  public type: string;
  public message: string;
  public sentBy: string;
  public sentTime: Date;
  public response: string;
  public responseTime: Date;
  public noted: string;
  public error: string;
  public expiration: Date;
  public status: string;
  public sentTimeFC: Date;
  public responseTimeFC: Date;
  public numSegments: string;
}
