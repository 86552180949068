var RolesWithUsersDecorator = /** @class */ (function () {
    function RolesWithUsersDecorator(usersRoles) {
        this.m_assignedCount = 0;
        this.m_filteredCount = 0;
        this.m_users = [];
        this.usersRoles = usersRoles;
    }
    Object.defineProperty(RolesWithUsersDecorator.prototype, "id", {
        get: function () {
            return this.usersRoles.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RolesWithUsersDecorator.prototype, "name", {
        get: function () {
            return this.usersRoles.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RolesWithUsersDecorator.prototype, "assignedCount", {
        get: function () {
            return this.m_assignedCount;
        },
        set: function (count) {
            this.m_assignedCount = count;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RolesWithUsersDecorator.prototype, "filteredCount", {
        get: function () {
            return this.m_filteredCount;
        },
        set: function (count) {
            this.m_filteredCount = count;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RolesWithUsersDecorator.prototype, "users", {
        get: function () {
            return this.m_users;
        },
        enumerable: true,
        configurable: true
    });
    RolesWithUsersDecorator.prototype.addUser = function (user) {
        this.m_users.push(user);
    };
    return RolesWithUsersDecorator;
}());
export { RolesWithUsersDecorator };
