import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  ViewChild,
  HostListener,
  HostBinding,
  SimpleChanges
} from '@angular/core';
import * as _ from 'lodash';
import { CustomDomEvents } from '../../models/index';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';

export const IPV4_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Ipv4InputComponent),
  multi: true
};


@Component({
  moduleId: module.id,
  selector: 'slx-ipv4-input',
  templateUrl: 'ipv4-input.component.html',
  styleUrls: ['ipv4-input.component.scss'],
  providers: [IPV4_VALUE_ACCESSOR]
})
export class Ipv4InputComponent implements ControlValueAccessor {
  @Input() public className: string;
  @Input() public placeholder: string = 'IP Address';
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
  }

  public phoneMaskConf: any;
  public innerValue: any = '';
  public inEdit: boolean;
  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  @ViewChild('ipv4Input', {static: true}) private inputComponent: ElementRef;

  @HostBinding('class.edited') private hasFocus: boolean = false;
  constructor(elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.inEdit = true;
    this.hasFocus = false;
  }
  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }
  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
  }

  public writeValue(value?: any): void {
    this.innerValue = value;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public valueChanged(value: any): void {
    this.onChangeCallback(value);
  }
}
