<form #form="ngForm" novalidate class="header-form">
  <div class="flex-horizontal">
    <slx-budget-dropdown-selector #budgetSelector [ngClass]="{'hidden': state.mode!=='None'}" [ignoreOrgLevelChange]="true" [selectedBudgetDefinition]="budgetManagementService.currentBudgetDefinition"
      (budgetDefinitionSelected)="budgetDefinitionSelected($event)" (budgetDefinitionAdd)="budgetDefinitionAdd($event)"></slx-budget-dropdown-selector>
    <div class="flex-vertical range-container theme-body-text" *ngIf="state.mode==='EditBudget' && budget">
      <label class="range-title">Budget Range</label>
      <span>{{budget?.startDate | amDateFormat: appConfig.dateFormat}} -
        <span *ngIf="budget.endDate">{{budget.endDate | amDateFormat: appConfig.dateFormat}}</span>
      </span>
    </div>
    <div *ngIf="state.mode==='AddBudget'" class="flex-horizontal dates-container">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input date #startDate [(ngModel)]="budget.startDate" [required]="true" [acceptNullDate]="false"
         placeholder="Budget Start Date" name="startDate" [slxMaxDate]="budget.endDate"></slx-date-picker-ngx>
        <span errorMessage for="required"></span>
        <span errorMessage for="date"></span>
        <span errorMessage for="maxDate">Start Date should be less than the End Date</span>
      </slx-input-decorator>

      <div class="flex-horizontal">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input [(ngModel)]="budget.endDate" [acceptNullDate]="false" 
          placeholder="Budget End Date" name="endDate" [slxMinDate]="budget.startDate"></slx-date-picker-ngx>
          <span errorMessage for="date"></span>
          <span errorMessage for="minDate">End Date should be greater than the Start Date</span>
        </slx-input-decorator>
        <button class="remove-button" (click)="removeEndDate ()">
          <i aria-hidden="true" class="fa fa-times"></i>
        </button>
      </div>
    </div>

    <div *ngIf="budget" class="census-container flex-horizontal theme-body-text">
      <span class="census-edit-item">Maximum Capacity: {{budget?.census.maximumCapacity | number:'.2-2'}}</span>
      <span class="census-edit-item">Budgeted Census: {{budget?.census.budgetedCensus | number:'.2-2'}}</span>
      <button *ngIf="(state.mode==='AddBudget' || state.mode==='EditBudget')" type="button" class="theme-icon-button edit-census-button"
        (click)="editCensus()">
        <i class="fas fa-pencil-alt fa-lg" aria-hidden="true"></i>
      </button>
    </div>
    <div *ngIf="(state.mode==='AddBudget' || state.mode==='EditBudget')" class="flex-horizontal buttons-container">
      <button type="button" (click)="onManageGroups()" class="theme-button-apply">Manage Groups</button>
      <button type="button" (click)="onAddPosition()" class="theme-button-apply">Add Position</button>
    </div>
  </div>
  <div class="flex-horizontal buttons-container" [ngSwitch]="state.mode">

    <div *ngSwitchCase="'AddBudget'" class="buttons-container">
      <div *ngIf="!state.isRecordEditMode">
        <button type="button" (click)="onAddDiscard()" class="theme-button-cancel">Discard</button>
        <button type="button" (click)="onAddSave()" [disabled]="!form.valid" class="theme-button-apply">Save</button>
      </div>
    </div>

    <div *ngSwitchCase="'EditBudget'" class="buttons-container">
      <div *ngIf="!state.isRecordEditMode">
        <button type="button" (click)="onEditDiscard()" class="theme-button-cancel">Discard</button>
        <button type="button" (click)="onEditSave()" class="theme-button-apply">Save</button>
      </div>
    </div>

    <div *ngSwitchCase="'None'" class="buttons-container">
      <button type="button" (click)="onRestore()" [disabled]="!budgetManagementService.currentBudgetDefinition" class="theme-button-cancel">Clone</button>
      <button type="button" (click)="onDelete()" [disabled]="!budgetManagementService.currentBudgetDefinition" class="theme-button-cancel">Delete</button>
      <button type="button" (click)="onEdit()" [disabled]="!budgetManagementService.currentBudgetDefinition" class="theme-button-apply">Edit</button>
    </div>
  </div>
</form>