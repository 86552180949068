import { BenAdminMenuManagementService } from './../../../organization/services/navigation/ben-admin/ben-admin-menu-management.service';
import { Component, Input, ViewEncapsulation, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { StyledUserApplication } from '../../../organization/models/index';
import { SsoNavigationService, LookupService } from '../../../organization/services/index';
import { appConfig } from '../../../app.config';
import * as _ from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../../../common/services/modal/modal.service';
import { InfoDialogComponent } from '../../../common/components/info-dialog/info-dialog.component';
import { DashBoardAlertsModel,DashboardAppTypeEnum } from '../../../portal/models/index';
import { DashboardAlertsSelectPopupDialogComponent } from '../dashboard-alerts/dashboard-alerts-select-popup-dialog/dashboard-alerts-select-popup-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'slx-application-item',
  templateUrl: 'application-item.component.html',
  styleUrls: ['application-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ApplicationItemComponent {
  @Input()
  public application: StyledUserApplication;
  
  @Input()
  public progressbar: boolean;

  public get isExternalLink(): boolean {
    return this._isExternalLink;
  }

  @Input()
  public set isExternalLink(value: boolean) {
    this._isExternalLink = value;
  }

  @Output()
  public onHasChanges = new EventEmitter<boolean>();

  public get className(): string {
    return this.application && this.application.loadCounters ? 'expanded' : 'collapsed';
  }

  public modifiers = {
    preventOverflow: {
      escapeWithReference: true,
      boundariesElement: 'viewport',
      priority: ['right', 'bottom', 'top', 'left'],
      padding: 10
    }
  };

  private _isExternalLink: boolean = false;
  public modelData: DashBoardAlertsModel;

  constructor(private navigationService: SsoNavigationService,
    private route: ActivatedRoute,
    private benAdminService: BenAdminMenuManagementService,
    public modalService: ModalService,
    private detector: ChangeDetectorRef
  ) {
    this.modelData = new DashBoardAlertsModel();
  }

  public ngOnInit() {
  }

  public redirectToApp(): boolean {

    if (this.benAdminService.isBenAdminItem(this.application.link)) {
      let menuItemState = this.benAdminService.getBenAdminMenuItemState();
      if (!menuItemState.isMenuAccessible) {
        InfoDialogComponent.OpenDialog('Warning', menuItemState.userMessage, this.modalService);
      } else {
        this.benAdminService.openBswift();
      }
    } else {
      this.openV5();
    }
    return false;
  }

  private openBenAdmin(): void {
    let orgLevel = this.getOrglevelIdFromRoute();
    this.benAdminService.openBswift(orgLevel);
  }

  private openV5(): void {
    let orgLevel = this.getOrglevelIdFromRoute();
    let url = `${appConfig.api.url}/${this.application.link}?orglevel=${orgLevel}`;
    this.navigationService.navigationToV5(url);
  }

  private getOrglevelIdFromRoute(): number {
    const id: string = this.route.snapshot.queryParamMap.get('orgLevelId');
    return _.toInteger(id);
  }

  public onStartAlerts(): void {
    this.modelData.appId = this.application.id;
    this.modelData.appTitle = this.application.title;

    DashboardAlertsSelectPopupDialogComponent.initiateDialog(this.modelData, this.modalService, (hasChanges: boolean) => {
      if (hasChanges) {
        this.emitChanges();
      }
    });
  }

  private emitChanges(): void {
    this.onHasChanges.emit(true);
  }
  
}
