import * as tslib_1 from "tslib";
import { ToolbarBaseService } from '../../../core/services/index';
var TaConsoleToolbarService = /** @class */ (function (_super) {
    tslib_1.__extends(TaConsoleToolbarService, _super);
    function TaConsoleToolbarService() {
        return _super.call(this) || this;
    }
    return TaConsoleToolbarService;
}(ToolbarBaseService));
export { TaConsoleToolbarService };
