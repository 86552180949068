import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { ArrivalsDeparturesAnalytics } from './arrivals-departures-analytics';
var Arrival = /** @class */ (function () {
    function Arrival() {
    }
    Object.defineProperty(Arrival.prototype, "arrivalTimeTitle", {
        get: function () {
            if (this.arrivalTime)
                return "" + moment(this.arrivalTime).format(appConfig.timeFormat);
            return ArrivalsDeparturesAnalytics.unscheduled;
        },
        enumerable: true,
        configurable: true
    });
    return Arrival;
}());
export { Arrival };
