import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs-compat';
import { ShiftRequestService } from '../../../scheduler/services/schedule/shift-request.service';
import { PartnerPositionDefinition } from '../../models';
import { PartnerPosition } from '../../models/positions/partner-position';
import { AccessManagementService, PartnerConfigApiService, PartnerConfigManagementService } from '../../services/index';

@Component({
  selector: 'slx-partner-position-mapping',
  templateUrl: './partner-position-mapping.component.html',
  styleUrls: ['./partner-position-mapping.component.scss'],
  providers: [AccessManagementService, PartnerConfigApiService, PartnerConfigManagementService],
})
export class PartnerPositionMappingComponent implements OnInit {
  @Input()
  public positionsList: any;

  @Input()
  public positionName: string;

  public partnerPositionList: PartnerPosition[];

  @Input()
  public customerId: string;

  @Input()
  public partnerId: string;

  public filteredPartnerPositionList: PartnerPosition[];

  public currentTabPartnerPositionList: PartnerPosition[];

  public gridData: any = [];
  public view: Observable<GridDataResult>;
  public isEdit: boolean = true;
  public currentPositionsList: any;

  public state: {
    isLoading: boolean;
    configureMode: boolean;
    copyMode: boolean;
  };

  @ViewChild('kendoGrid', { static: true })
  public grid: GridComponent;

  @Input() private addAction: EventEmitter<boolean>;

  @Input()
  public restrictedByOrgLevel:boolean;

  constructor(private manageService: PartnerConfigManagementService,private shiftRequestService: ShiftRequestService,) {
    
  }

  ngOnInit() {
    this.state = {
      isLoading: false,
      configureMode: true,
      copyMode: false,
    };
    this.currentPositionsList = this.positionsList;
    this.getPartnerPositions();
    this.addAction.subscribe((data) => {
      this.onAdd(data);
    });
  }

  filterPositions() {
    let currentDropDownList = this.currentPositionsList.filter(
      (e) => this.partnerPositionList.filter((p) => p.position_name === e.name).length == 0
    );
    this.filteredPartnerPositionList = currentDropDownList.filter(
      (e) => this.partnerPositionList.filter((p) => p === e.name).length == 0
    );
    this.currentTabPartnerPositionList = this.partnerPositionList.filter(
      (e) => e.partner_position_name == this.positionName
    );
    this.currentTabPartnerPositionList.sort((a, b) => (a.position_name > b.position_name) ? 1 : -1);
    this.grid.data = this.currentTabPartnerPositionList;
  }

  onAdd(data) {
    let partnerPositionDefinition = new PartnerPositionDefinition();
    partnerPositionDefinition.partnerId = this.partnerId;
    this.grid.addRow(partnerPositionDefinition);
    this.filterPositions();
  }

  public saveHandler({ sender, rowIndex, dataItem, isNew }) {
    if (dataItem.position && dataItem.position.name) {
      this.state.isLoading = true;
      this.grid.closeRow(rowIndex);
      let partnerPosition = new PartnerPosition();
      partnerPosition.partner_position_name = this.positionName;
      partnerPosition.position_name = dataItem.position.name;
      partnerPosition.map_id = 0;
      let partnerPositionsList: PartnerPosition[] = [];
      partnerPositionsList.push(partnerPosition);
      this.manageService
        .addPartnerPosition(this.customerId, this.partnerId, partnerPositionsList)
        .then((response: any) => {
          this.getPartnerPositions();
          this.state.isLoading = false;
        });
    }
  }

  public getPartnerPositions() {
    this.state.isLoading = true;
    this.manageService.getPartnerPositions(this.customerId, this.partnerId).then((response: any) => {
      this.partnerPositionList = response;
      this.shiftRequestService.setPartnerPositionMapData(this.partnerId, this.partnerPositionList);
      this.currentTabPartnerPositionList = this.partnerPositionList.filter(
        (e) => e.partner_position_name == this.positionName
      );
      this.grid.data = this.currentTabPartnerPositionList;
      this.filterPositions();
      this.state.isLoading = false;
    });
  }

  public removeHandler({ sender, rowIndex, dataItem, isNew }) {
    this.state.isLoading = true;
    let map_id = this.grid.data[rowIndex].map_id;
    this.manageService.deletePosotionMapping(this.customerId, this.partnerId, map_id).then((response: any) => {
      this.getPartnerPositions();
      this.state.isLoading = false;
    });
  }

  public cancelHandler({ sender, rowIndex, dataItem, isNew }) {
    this.grid.closeRow(rowIndex);
  }
}
