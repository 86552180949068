import { Router, ActivatedRoute, Params } from '@angular/router';
import { appConfig } from '../../../app.config';
import * as moment from 'moment';

export class TaConsoleNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToTaConsole(d: Date): void {
    let params: Params = {
      date: moment(d).format(appConfig.linkDateFormat)
    };
    this.router.navigate(['ta_console'], { relativeTo: this.route.parent, queryParams: params });
  }

}
