import { Component, Input, Output, EventEmitter } from '@angular/core';

import { LinePunch, PunchStatus, PunchLogRequest, PunchDisplay, PunchSource } from '../../../models/index';
@Component({
  moduleId: module.id,
  selector: 'slx-daily-line-punch-status',
  templateUrl: 'daily-line-punch-status.component.html',
  styleUrls: ['daily-line-punch-status.component.scss']
})
export class DailyLinePunchStatusComponent {
  @Input()
  public punch: LinePunch;
  @Output()
  public showComment: EventEmitter<LinePunch>;

  public unknown = PunchDisplay.Unknown;
  public edited = PunchDisplay.Edited;
  public validPunch = PunchDisplay.ValidPunch;
  public validEdited = PunchDisplay.ValidEdited;
  public schedule = PunchDisplay.Schedule;
  public scheduleExempt = PunchDisplay.ScheduleExempt;
  public punchRequest = PunchDisplay.PunchRequest;
  public invalidPunch = PunchDisplay.InvalidPunch;
  public deletedPunch = PunchDisplay.DeletedPunch;
  public overlap = PunchDisplay.Overlap;
  public invalidLogin = PunchDisplay.InvalidLogin;


  constructor() {
    this.showComment = new EventEmitter<LinePunch>();
  }

  public onShowComment(punch: LinePunch): void {
    this.showComment.emit(punch);
  }
}
