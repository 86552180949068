import * as tslib_1 from "tslib";
import { MasterScheduleContextMenuRequest } from './../../../models/master-schedule/master-schedule-context-menu-request';
import { OnInit, Provider } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DialogOptions, IDialog, PopoverContentComponent, ConfirmOptions, ConfirmDialog2Component } from '../../../../common/index';
import { ModalService, ColumnSettingsStorageService } from '../../../../common/services/index';
import { LookupApiService } from '../../../../organization/services/index';
import { appConfig } from '../../../../app.config';
import { screenUtils } from '../../../../common/utils/index';
import { MasterScheduleContextMenuRotation } from '../../../models/index';
import { ScheduleEntryApiService } from '../../../services/schedule-entry/schedule-entry-api.service';
import { AppSettingsManageService } from '../../../../../app/app-settings/services/app-settings-manage.service';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../../app/core/decorators';
import { MasterScheduleManagementService } from '../../../../../app/scheduler/services';
var MasterScheduleOptionsDialogComponent = /** @class */ (function () {
    function MasterScheduleOptionsDialogComponent(options, modalService, lookupApiService, managementService, appSettingsManageService, request, columnSettingsStorageService, ScheduleEntryApiService) {
        this.managementService = managementService;
        this.appSettingsManageService = appSettingsManageService;
        this.ScheduleEntryApiService = ScheduleEntryApiService;
        this.createRotation = new MasterScheduleContextMenuRotation();
        this.disableButtons = {
            disableGeneratebtn: false,
            disableDeletebtn: false
        };
        this.modalService = modalService;
        this.request = request;
        this.empId = request.empId;
        this.startDate = request.startDate;
        this.endDate = request.endDate;
        this.options = options;
        this.lookupApiService = lookupApiService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        var isMobile = screenUtils.isMobile;
    }
    Object.defineProperty(MasterScheduleOptionsDialogComponent.prototype, "canSave", {
        get: function () {
            return this.createRotationFromScheduleSection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleOptionsDialogComponent.prototype, "rotationMinDate", {
        get: function () {
            return this.startDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleOptionsDialogComponent.prototype, "rotationMaxDate", {
        get: function () {
            return this.endDate ? moment(this.endDate).subtract(6, 'd').toDate() : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleOptionsDialogComponent.prototype, "rotationWeeks", {
        get: function () {
            if (!this.endDate || !this.createRotation.startDate) {
                return [];
            }
            var endDate = moment(this.endDate);
            var startDate = moment(this.createRotation.startDate);
            var weeksDifference = Math.floor((endDate.diff(startDate, 'd') + 1) / 7);
            if (weeksDifference === 1) {
                return [1];
            }
            return _.range(1, weeksDifference + 1);
        },
        enumerable: true,
        configurable: true
    });
    MasterScheduleOptionsDialogComponent.openDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 480;
        dialogOptions.width = 340;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: MasterScheduleContextMenuRequest,
                useValue: request
            }
        ];
        var header = request.empName;
        var dialog = modalService.globalAnchor
            .openDialog(MasterScheduleOptionsDialogComponent, header, dialogOptions, resolvedProviders, function (result, cmd, payload) {
            if (result) {
                cmd = request.selectedCommand;
            }
            switch (cmd) {
                case 'CreateRotationFromSchedule':
                    payload = request.selectedCommandPayload;
                    break;
            }
            callback(result, cmd, payload);
        });
        return dialog;
    };
    MasterScheduleOptionsDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.screenUtils = screenUtils;
        this.selectedCommand = '';
        this.getSettings();
        this.actionsSubscription = this.managementService.actions$.subscribe(function (data) {
            _this.actions = data;
        });
    };
    MasterScheduleOptionsDialogComponent.prototype.getEmployeeId = function () {
        return this.empId;
    };
    MasterScheduleOptionsDialogComponent.prototype.getDates = function () {
        return moment(this.startDate).format('MM/DD/YYYY') + ' - ' + moment(this.endDate).format('MM/DD/YYYY');
    };
    MasterScheduleOptionsDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    MasterScheduleOptionsDialogComponent.prototype.disableBtnOnClick = function (command) {
        this.disableButtons.disableDeletebtn = command == 'DeleteSchedule' ? true : false;
        this.disableButtons.disableGeneratebtn = command == 'GenerateSchedule' ? true : false;
    };
    MasterScheduleOptionsDialogComponent.prototype.enableBtnOnClick = function (command) {
        command == 'DeleteSchedule' ? this.disableButtons.disableDeletebtn = false : this.disableButtons.disableGeneratebtn = false;
    };
    MasterScheduleOptionsDialogComponent.prototype.onSelect = function (command) {
        var _this = this;
        if (command == 'DeleteSchedule' || 'GenerateSchedule') {
            this.disableBtnOnClick(command);
            if (command == 'DeleteSchedule' && _.isEmpty(this.request.rowData.cell)) {
                this.request.selectedCommand = command;
                this.dialogResult = true;
                this.modalService.closeWindow(this.options.windowUniqueId);
                this.enableBtnOnClick(command);
            }
            else {
                var EmpId = this.empId;
                var StartDate = moment(this.startDate).format("MM-DD-YYYY");
                var EndDate = moment(this.endDate).format("MM-DD-YYYY");
                this.ScheduleEntryApiService.checkApprovedPayperiod(EmpId, StartDate, EndDate).then(function (data) {
                    _this.approvedPayPeriod = data;
                    if (_this.approvedPayPeriod == true && _this.isModifyPayPeriodApproved) {
                        var message = _this.actions.canEditForApprovedPayPeriod ?
                            "This will modify a schedule in an approved pay period and impact the PBJ Calculation for the \n              employee, are you sure you want to continue?" :
                            "You do not have permissions to modify a schedule in an approved pay period";
                        var popupOptions = new ConfirmOptions();
                        popupOptions.showCancel = true;
                        popupOptions.showOK = _this.actions.canEditForApprovedPayPeriod ? true : false;
                        popupOptions.buttonOKtext = 'Ok';
                        popupOptions.buttonCanceltext = _this.actions.canEditForApprovedPayPeriod ? 'Cancel' : 'Ok';
                        ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                            if (result) {
                                _this.request.selectedCommand = command;
                                _this.dialogResult = true;
                                _this.modalService.closeWindow(_this.options.windowUniqueId);
                            }
                            _this.enableBtnOnClick(command);
                        }, popupOptions);
                    }
                    else {
                        _this.request.selectedCommand = command;
                        _this.dialogResult = true;
                        _this.modalService.closeWindow(_this.options.windowUniqueId);
                        _this.enableBtnOnClick(command);
                    }
                });
            }
        }
    };
    MasterScheduleOptionsDialogComponent.prototype.onGenerateScheduleAction = function (acceptPopover, result) {
        acceptPopover.hide();
        if (result) {
            this.onSelect('GenerateSchedule');
        }
    };
    MasterScheduleOptionsDialogComponent.prototype.toggleCreateRotationFromSchedule = function () {
        this.createRotationFromScheduleSection = !this.createRotationFromScheduleSection;
    };
    MasterScheduleOptionsDialogComponent.prototype.onSaveAction = function (popover, confirmed) {
        popover.hide();
        if (confirmed) {
            this.request.selectedCommandPayload = this.createRotation;
            this.onSelect('CreateRotationFromSchedule');
        }
    };
    MasterScheduleOptionsDialogComponent.prototype.onCreateRotationStartDateChange = function () {
        this.createRotation.weeks = 1;
    };
    MasterScheduleOptionsDialogComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleOptionsDialogComponent.prototype, "actionsSubscription", void 0);
    return MasterScheduleOptionsDialogComponent;
}());
export { MasterScheduleOptionsDialogComponent };
