import { FieldLimitAttribute, LimitType } from './field-limit-attribute';
import { FieldSecurityAttribute } from './field-security-attribute';
import { IFieldPolicy } from '../index';


export class FieldData<T> {
  public fieldName: string;
  public fieldType?: string;
  public displayName: string;
  public defaultFieldValue: any;

  public set rawValue(value: string) {
    this.innerRawValue = value;
  }

  public get rawValue(): string {
    return this.innerRawValue;
  }

  public set fieldValue(val: T) {
    if (this.innerValue !== val) {
      this.innerValue = val;
      this.isDirty = true;
    }
  }

  public get fieldValue(): T {
    return this.innerValue;
  }
  public get Policy(): IFieldPolicy {
    let policy: IFieldPolicy = {
      isEditable: this.securityAttribute.editable,
      isVisible: !this.securityAttribute.hidden,
      isMasked: this.securityAttribute.masked,
      isRequired: this.isRequired,
      minValue: null,
      maxValue: null,
      rawValue: this.innerRawValue
    };
    if (this.fieldType !== 'string') {
      if (this.limitAttribute.limitType === LimitType.noLower || this.limitAttribute.limitType === LimitType.inRange) {
        policy.minValue = this.limitAttribute.minValue;
      }
      if (this.limitAttribute.limitType === LimitType.noBigger || this.limitAttribute.limitType === LimitType.inRange) {
        policy.maxValue = this.limitAttribute.maxValue;
      }
    }

    return policy;
  }
  public isDirty: boolean;
  public isRequired: boolean;
  public isVisible: boolean;

  public get isEditable(): boolean {
    return this.securityAttribute.editable;
  }
  public limitAttribute: FieldLimitAttribute<T>;
  public securityAttribute: FieldSecurityAttribute;
  public isTemporal: boolean;
  public temporalMinDate: Date;

  private innerValue: T;
  private innerRawValue: string;

  public createBasedOn(): FieldData<T> {
    let fd: FieldData<T> = new FieldData<T>();
    fd.fieldName = this.fieldName;
    fd.limitAttribute = this.limitAttribute;
    fd.securityAttribute = this.securityAttribute;
    fd.isTemporal = this.isTemporal;
    fd.temporalMinDate = this.temporalMinDate;
    return fd;
  }
}
