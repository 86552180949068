import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { StateResetTypes } from '../../../../core/models/settings/reset-types';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/index';
import { GenericListDefinition, GenericList, GenericListRequest } from '../../../../organization/models/generic-list/index';
import { appConfig } from '../../../../app.config';
import { genericListConfig } from '../../../../organization/generic-list.config';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';
import { IRangeDates } from '../../../../common/models/range-dates';
var LatenessesComponent = /** @class */ (function () {
    function LatenessesComponent(genericListManagementService, stateManagement, storageService) {
        this.genericListManagementService = genericListManagementService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.m_dateFiltersControlId = 'DateFiltersNgx';
        this.m_dateFiltersResetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.state = {
            isLoading: false,
            startDate: moment().startOf('year').toDate(),
            endDate: moment().endOf('year').toDate()
        };
    }
    LatenessesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.stateManagement.init('LatenessesComponent');
        this.loadSubscription = this.stateManagement.loadData$.subscribe(function () {
            _this.loadValues();
        });
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.application)
            .subscribe(function (value) {
            var orgLevel = value[0], application = value[1];
            if (!orgLevel || !orgLevel.id || !application.id) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentApplication = application;
            _this.restoreFilters();
            _this.loadDefinitions();
        });
        this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe(function (defs) {
            _this.stateManagement.onComponentActiveStateChanged({});
        });
        this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe(function (list) {
            _this.state.isLoading = false;
            _this.stateManagement.loadedData({});
        });
    };
    LatenessesComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    LatenessesComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.state.startDate = startDate;
        this.state.endDate = endDate;
        this.saveFilters();
    };
    LatenessesComponent.prototype.loadDefinitions = function () {
        this.state.isLoading = true;
        this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id, genericListConfig.latenesses, false);
    };
    LatenessesComponent.prototype.loadValues = function () {
        this.state.isLoading = true;
        var req = new GenericListRequest();
        req.startDate = this.state.startDate;
        req.endDate = this.state.endDate;
        this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
    };
    LatenessesComponent.prototype.saveFilters = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId, {
            value: { startDate: this.state.startDate, endDate: this.state.endDate }
        }, this.m_dateFiltersResetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
    };
    LatenessesComponent.prototype.restoreFilters = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
        if (state && state.value) {
            if (state.value.startDate)
                this.state.startDate = moment(state.value.startDate).toDate();
            if (state.value.endDate)
                this.state.endDate = moment(state.value.endDate).toDate();
        }
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], LatenessesComponent.prototype, "application", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], LatenessesComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LatenessesComponent.prototype, "definitionsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LatenessesComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LatenessesComponent.prototype, "valuesLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LatenessesComponent.prototype, "loadSubscription", void 0);
    return LatenessesComponent;
}());
export { LatenessesComponent };
