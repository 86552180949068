import { FavoriteManagementService } from '../../../services';
import { Provider, Component } from '@angular/core';



@Component({
  moduleId: module.id,
  selector: 'slx-favorites-configuration',
  templateUrl: 'favorites-configuration.component.html',
  styleUrls: ['./favorites-configuration.component.scss']
})
export class FavoritesConfigurationComponent {
    public modulesIsCollapsed: boolean;
    public favoritesIsCollapsed: boolean;
}
