import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator } from '@angular/forms';
import { requireNumberMultiValidator } from './slx-require-number-multi.validator';
const NUMBER_MULTI_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SlxRequireNumberValidator),
  multi: true,
};

@Directive({
  selector: '[slxNumberMulti][formControlName],[slxNumberMulti][formControl],[slxNumberMulti][ngModel]',
  providers: [NUMBER_MULTI_VALIDATOR],
})
export class SlxRequireNumberValidator implements Validator {
  public validate(c: AbstractControl): { [key: string]: any } {
    const validator = requireNumberMultiValidator();

    const result = validator(c);
    return result;
  }
}
