import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { TextEditorThemes, TextEditorOptions } from '../../models/index';
import { appConfig } from '../../../app.config';
var TextEditorComponent = /** @class */ (function () {
    function TextEditorComponent() {
        this.onChangeCallback = _.noop;
        this.onTouchedCallback = _.noop;
        this.appConfig = appConfig;
        this.onTextChange = new EventEmitter();
        this.defaultOptions = this.createDefaultOptions();
    }
    Object.defineProperty(TextEditorComponent.prototype, "incomingOptions", {
        set: function (value) {
            if (_.isObject(value)) {
                this.options = _.assign(new TextEditorOptions(), this.defaultOptions, value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextEditorComponent.prototype, "innerHtml", {
        get: function () {
            return this.m_innerHtml;
        },
        set: function (value) {
            if (_.isString(value) && value !== this.innerHtml) {
                this.m_innerHtml = value;
                this.onTextChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    TextEditorComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    TextEditorComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    TextEditorComponent.prototype.writeValue = function (value) {
        this.innerHtml = value;
    };
    TextEditorComponent.prototype.onValueChanged = function (value) {
        this.onChangeCallback(value);
    };
    TextEditorComponent.prototype.createDefaultOptions = function () {
        var options = new TextEditorOptions();
        options.key = this.appConfig.licenses.froalaEditor;
        options.theme = TextEditorThemes.gray;
        options.toolbarButtons = [
            'fontFamily', 'fontSize', 'color', '|',
            'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'insertLink', 'insertImage', 'insertTable', '|',
            'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|',
            'clearFormatting', '|',
            'html'
        ];
        options.placeholderText = 'Create your message here!';
        options.charCounterMax = 500;
        options.charCounterCount = true;
        return options;
    };
    return TextEditorComponent;
}());
export { TextEditorComponent };
