export interface IWcWorkersCompensationCaseDto {
    id: number;
    name: string;
}

export enum WcWorkersCompensationCaseEnum {
    Yes, No, NotKnown, EnteredAsRecord
}

export class WcWorkersCompensationCase {
    public id: WcWorkersCompensationCaseEnum;
    public name: string;
}