import { StaffingRating } from './staffing-rating';

export interface IStaffingPredictorRatingOptionDTO {
    rating: number;
    rnHours: number;
    totalHours: number;
}

export class StaffingPredictorRatingOption {
    public rating: StaffingRating;
    public rnHours: number;
    public totalHours: number;

    public rnHoursString: string;
    public totalHoursString: string;
}
