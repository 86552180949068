import * as tslib_1 from "tslib";
import { UserExtendedSettings } from '../../models/index';
import { UserExtendedSettingsApiService } from './user-extended-settings-api.service';
var UserExtendedSettingsManagementService = /** @class */ (function () {
    function UserExtendedSettingsManagementService(userSettingsApiService) {
        this.userSettingsApiService = userSettingsApiService;
        this.isLiveChatMenuenabled = false;
    }
    UserExtendedSettingsManagementService.prototype.getLiveChatMenu = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.userSettingsApiService.getUserSettings()
                            .then(function (value) {
                            _this.isLiveChatMenuenabled = value.isLiveChatEndabled;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return UserExtendedSettingsManagementService;
}());
export { UserExtendedSettingsManagementService };
