import { Component, Input, Output, EventEmitter, ViewEncapsulation, OnDestroy } from '@angular/core';

import * as _ from 'lodash';
import { ILookupMultiselectModel, LookupMultiselectModel } from '../../../common/models/index';
import { Subscription } from 'rxjs/Subscription';
import { LookupService } from '../../services/index';
import { Lookup, LookupDefinition } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lookup-multiselect',
  templateUrl: 'lookup-multiselect.component.html',
  styleUrls: ['lookup-multiselect.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LookupMultiSelectComponent implements OnDestroy {
  @Input()
  public caption: string;

  @Input()
  public dropDownWidth: string = '100%';

  @Output()
  public onFilterChange: EventEmitter<ILookupMultiselectModel[]>;

  @Input()
  public lookup: LookupMultiselectModel[];

  @Input()
  public employeeId: number;

  private lookupNameValue: LookupDefinition;
  private lookupSubscription: Subscription;
  private lookupService: LookupService;
  private orgLevelIdValue: number;

  constructor(lookupService: LookupService) {
    this.lookupService = lookupService;

    this.onFilterChange = new EventEmitter<ILookupMultiselectModel[]>();
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public hasSelected(): boolean {
    return _.some(this.lookup, (item: ILookupMultiselectModel) => item.isSelected === true);
  }

  public get selectedCount(): number {
    return _.filter(this.lookup, (item: ILookupMultiselectModel) => item.isSelected === true).length;
  }

  public checkAll(): void {
    _.forEach(this.lookup, (item: ILookupMultiselectModel) => item.isSelected = true);
  }

  public uncheckAll(): void {
    _.forEach(this.lookup, (item: ILookupMultiselectModel) => {
      item.isSelected = false;
    });
  }

  public itemIsSelected(item: ILookupMultiselectModel): boolean {
    return _.some(this.lookup, (p: ILookupMultiselectModel) => p.id === item.id);
  }

  public onChange(event: any, item: ILookupMultiselectModel): void {
    if(+item.id === 0) {
      if(item.isSelected) {
        this.checkAll();
      } else {
        this.uncheckAll();
      }
    }
    let selected: LookupMultiselectModel[] = this.lookup.filter( (x: LookupMultiselectModel) => x.isSelected);
    this.onFilterChange.emit(selected);
  }

  public chunk(arr: any[], size: number): any[] {
    let newArr: any = [];
    for (let i: number = 0; i < arr.length; i += size) {
      newArr.push(arr.slice(i, i + size));
    }
    return newArr;
  }

  @Input()
  public set lookupName(value: LookupDefinition) {
    this.lookupNameValue = value;
    this.loadList();
  }

  public get lookupName(): LookupDefinition {
    return this.lookupNameValue;
  }

  @Input()
  public set orgLevelId(value: number) {
    this.orgLevelIdValue = value;
    this.loadList();
  }

  public get orgLevelId(): number {
    return this.orgLevelIdValue;
  }

  private loadList(): void {
    if(!this.lookupName || !this.orgLevelId) return;
    let promise: Promise<Lookup> = this.lookupService.getLookup({ lookupType: this.lookupName, orgLevelId: this.orgLevelId, employeeId: undefined });

    promise.then((value: Lookup) => {
          this.lookup = this.mapModels(value);
    });
  }

  private mapModels(data: Lookup): LookupMultiselectModel[] {
      let models: LookupMultiselectModel[] = [];
      let model: LookupMultiselectModel = new LookupMultiselectModel();
      model = new LookupMultiselectModel();
      model.id = '0';
      model.name = 'All';
      model.isSelected = false;
      models.push(model);
      _.each(data.items, (dto: any) => {
          model = new LookupMultiselectModel();
          model.id = dto[data.valueField];
          model.name = dto[data.titleField];
          model.isSelected = false;
          models.push(model);
      });
      return models;
  }

  private unsubscribe(): void {
    if (this.lookupSubscription) {
      this.lookupSubscription.unsubscribe();
    }
  }
}
