
import { IServerValidationResult } from '../../../common/validators/common-validators-models';

export interface IEmployeeFieldValidation {
  isValid: boolean;
  errorMessage: string;
  empId: number;
}

export class EmployeeFieldValidation implements IServerValidationResult {
  public isValid: boolean;
  public isReadyForValidation: boolean;
  public empId: number;
  public errorMessage: string;
}

export interface INamesValidation {
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  departmentOrgLevelId: number;
}

