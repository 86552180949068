import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../framework';
import { IpManagementApiService } from './ip-management-api.service';
import { IpManagementConfig } from '../../models/ip-management/ip-management-config.model';
import { IpSettings } from '../../models/ip-management/ip-settings.model';
import { IpDownloadFile } from '../../models/ip-management/ip-download-file.model';
import { IpRestriction } from '../../models/ip-management/ip-restriction.model';
import { IpDuplicate } from '../../models/ip-management/ip-duplicate.model';

@Injectable()
export class IpManagementService {
  // private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private ipWhiteList$ = new ReplaySubject<IpRestriction[]>(1);
  private ipBlackList$ = new ReplaySubject<IpRestriction[]>(1);
  private ipAddress$ = new ReplaySubject<IpRestriction[]>(1);
  private ipSettings$ = new ReplaySubject<IpSettings>(1);
  private loading$ = new Subject<boolean>();
  private activeTab$ = new ReplaySubject<string>(1);
  private downloadWhitelistConfig$ = new Subject<IpDownloadFile>();
  private downloadBlacklistConfig$ = new Subject<IpDownloadFile>();
  private addNewIp$ = new Subject<boolean>();
  private ipAddress = [];
  private ipWhiteListAddrs = [];
  private ipBlackListAddrs = [];
  private ipListobj = [];

  constructor(private apiService: IpManagementApiService) {
    this.activeTab$.next('Allowlist');
  }

  public subscribeToLoading(callback: (v) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToIpWhitelist(callback: (v) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.ipWhiteList$.subscribe(callback);
  }

  public subscribeToIpBlacklist(callback: (v) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.ipBlackList$.subscribe(callback);
  }

  public subscribeToIpAddress(callback: (v) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.ipAddress$.subscribe(callback);
  }

  public subscribeToIpSettings(callback: (v) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.ipSettings$.subscribe(callback);
  }

  public subscribeToActiveTab(callback: (v) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.activeTab$.subscribe(callback);
  }

  public subscribeToDownloadWhitelistConfig(
    callback: (v) => void
  ): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.downloadWhitelistConfig$.subscribe(callback);
  }

  public subscribeToDownloadBlacklistConfig(
    callback: (v) => void
  ): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.downloadBlacklistConfig$.subscribe(callback);
  }

  public subscribeToAddNewIp(callback: (v) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.addNewIp$.subscribe(callback);
  }

  public async getIpManagementConfig(): Promise<any> {
    this.startProgress();
    this.apiService
      .getIpManagementConfig()
      .then((container: IpManagementConfig) => {
        this.ipWhiteList$.next(container.allowAccessList);
        this.ipBlackList$.next(container.denyAccessList);
        this.ipSettings$.next(container.settings);
        this.setIPList(container);
        this.stopProgress();
      });
  }

  public setIPList(container: IpManagementConfig) {
    _.forEach(container.allowAccessList, (ip: IpRestriction) => {
      this.ipAddress.push({ id: ip.id, address: ip.ipAddress });
      this.ipWhiteListAddrs.push({ id: ip.id, address: ip.ipAddress });
    });
    _.forEach(container.denyAccessList, (ip: IpRestriction) => {
      this.ipAddress.push({ id: ip.id, address: ip.ipAddress });
      this.ipBlackListAddrs.push({ id: ip.id, address: ip.ipAddress });
    });
    this.ipAddress$.next(this.ipAddress);
  }

  public setRestrictions(
    whiteListRestriction: boolean,
    blackListRestriction: boolean,
    isCanEdit: boolean,
    isCanExport: boolean
  ): void {
    let restrictions: IpSettings = {
      enforceWhitelistRestrictions: whiteListRestriction,
      enforceBlacklistRestrictions: blackListRestriction,
      canEdit: isCanEdit,
      canExport: isCanExport,
    };
    this.apiService.setRestrictions(restrictions);
  }

  public updateRestriction(ipRestriction: IpRestriction): Promise<any> {
    return this.apiService.updateRestriction(ipRestriction);
  }

  public addRestriction(ipRestriction: IpRestriction): Promise<any> {
    return this.apiService.addRestriction(ipRestriction);
  }

  public deleteRestriction(restrictionId: number): Promise<any> {
    return this.apiService.deleteRestriction(restrictionId);
  }

  public updateIPList(ip: IpRestriction, ipType: boolean) {
    this.ipAddress.push({ id: ip.id, address: ip.ipAddress });
    if (ipType) {
      this.ipWhiteListAddrs.push({ id: ip.id, address: ip.ipAddress });
    } else {
      this.ipBlackListAddrs.push({ id: ip.id, address: ip.ipAddress });
    }
  }

  public setActiveTab(tab: string) {
    this.activeTab$.next(tab);
  }

  public setDownloadFile(tab: string, type: string) {
    let downloadConfig: IpDownloadFile = {
      tabType: tab,
      fileType: type,
    };
    if (tab === 'Allowlist') {
      this.downloadWhitelistConfig$.next(downloadConfig);
    } else if (tab === 'Denylist') {
      this.downloadBlacklistConfig$.next(downloadConfig);
    }
  }

  public addNewIp(isNew: boolean) {
    this.addNewIp$.next(isNew);
  }

  public checkIpValidation(ip: any) {
    let regex = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*])\.)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*])\.)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*])\.)([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]|[*]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
    let validIp = regex.exec(ip);
    return validIp;
  }

  public checkDuplicateToEdit(e: any, id: number): IpDuplicate {
    let iplist = [];
    let tab;
    let excludeIP;
    _.forEach(this.ipAddress, (ip) => {
      iplist.push(ip);
      if (ip.id === id) {
        excludeIP = ip;
      }
    });

    if (id) {
      _.remove(iplist, (ip) => ip.address == excludeIP.address);
    }

    let isIpExists = _.find(iplist, (ip) => ip.address === e);
    if (_.find(this.ipWhiteListAddrs, (ip) => ip.address === e)) {
      tab = 'Allowlist';
    } else if (_.find(this.ipBlackListAddrs, (ip) => ip.address === e)) {
      tab = 'Denylist';
    }
    if (isIpExists) {
      return { show: true, inTab: tab };
    }
  }

  public updateIPAddress(id) {
    _.remove(this.ipAddress, function (ip) {
      return ip.id == id;
    });
  }

  public startProgress(): void {
    this.loading$.next(true);
  }

  public stopProgress(): void {
    this.loading$.next(false);
  }
}
