/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./master-schedule-columns-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components-library/components/check-box/check-box.component.ngfactory";
import * as i3 from "../../../../components-library/components/check-box/check-box.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./master-schedule-columns-settings.component";
var styles_MasterScheduleColumnsSettingsComponent = [i0.styles];
var RenderType_MasterScheduleColumnsSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MasterScheduleColumnsSettingsComponent, data: {} });
export { RenderType_MasterScheduleColumnsSettingsComponent as RenderType_MasterScheduleColumnsSettingsComponent };
function View_MasterScheduleColumnsSettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "column-option"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "slx-check-box", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; if (("ngModelChange" === en)) {
        var pd_0 = ((_v.context.$implicit.displayed = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CheckBoxComponent_0, i2.RenderType_CheckBoxComponent)), i1.ɵdid(2, 49152, null, 0, i3.CheckBoxComponent, [], { fieldName: [0, "fieldName"], caption: [1, "caption"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CheckBoxComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var currVal_7 = _v.context.$implicit.name; var currVal_8 = _v.context.$implicit.description; _ck(_v, 2, 0, currVal_7, currVal_8); var currVal_9 = _v.context.$implicit.displayed; _ck(_v, 4, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_MasterScheduleColumnsSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "columns-settings"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleColumnsSettingsComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columns.columns; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MasterScheduleColumnsSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleColumnsSettingsComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.columns; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_MasterScheduleColumnsSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-master-schedule-columns-settings", [], null, null, null, View_MasterScheduleColumnsSettingsComponent_0, RenderType_MasterScheduleColumnsSettingsComponent)), i1.ɵdid(1, 49152, null, 0, i6.MasterScheduleColumnsSettingsComponent, [], null, null)], null, null); }
var MasterScheduleColumnsSettingsComponentNgFactory = i1.ɵccf("slx-master-schedule-columns-settings", i6.MasterScheduleColumnsSettingsComponent, View_MasterScheduleColumnsSettingsComponent_Host_0, { columns: "columns" }, {}, []);
export { MasterScheduleColumnsSettingsComponentNgFactory as MasterScheduleColumnsSettingsComponentNgFactory };
