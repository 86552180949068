
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, Input, QueryList, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper, saveEvent } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';

import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitLineInfo, BenefitDetailsOption, BenefitDetailsTier, BenefitEnrolledEmployee } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/benefit-employees/index';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'benefit-option-flat-grid',
  templateUrl: 'benefit-option-flat-grid.component.html'
})
export class BenefitOptionFlatGridComponent implements OnInit, OnDestroy, AfterViewInit {
  public gridState: KendoGridStateHelper<BenefitEnrolledEmployee>;
  public columnGroup = 'BenefitOptionFlatGrid';
  public appConfig: IApplicationConfig;
  public isLoading: boolean = false;
  public selectableSettings: SelectableSettings;
  public format = 'c2';
  public minAmt: number = 0;
  public maxAmt: number = 99999999.99;
  public readonly pageSize = 50;
  @Input()
  public selectedEmployees: BenefitEnrolledEmployee[];
  @Input()
  public tierDetails: BenefitDetailsTier;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private invalidEnrollments = new Set<string>();

  @ViewChildren('erContr', { read: NgModel })
  private erContrList: QueryList<NgModel>;
  @ViewChildren('empContr', { read: NgModel })
  private empContrList: QueryList<NgModel>;

  @ViewChild('form', {static: true})
  private ngForm: NgForm;

  private isFormValid: boolean = true;

  constructor(private manService: BenefitEmployeeManagementService) {
    this.gridState = new KendoGridStateHelper<BenefitEnrolledEmployee>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.selectableSettings = {
      checkboxOnly: true
    };
    this.appConfig = appConfig;
  }

  public ngOnInit() {
    if (_.isObjectLike(this.tierDetails)) {
      const { empContribution, erContribution } = this.tierDetails;
      let enrollmentIsValid = true;
      _.forEach(this.selectedEmployees, item => {
        const { employeeContributionAmount: empContr, employerContributionAmount: erContr } = item;
        item.employeeContributionAmount = !_.isFinite(empContr) && _.isFinite(empContribution) ? empContribution : empContr;
        item.employerContributionAmount = !_.isFinite(erContr) && _.isFinite(erContribution) ? erContribution : erContr;
        if (!this.manService.isValidEnrollment(item.employeeContributionAmount, item.employerContributionAmount)) {
          enrollmentIsValid = false;
          this.invalidEnrollments
            .add(`empContribution_${item.employeeId}`)
            .add(`erContribution_${item.employeeId}`);
        }
      });
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid || !enrollmentIsValid);
    }
    this.refreshGrid();

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(() => {
      this.isFormValid = this.ngForm.valid;
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
    });
  }

  public ngAfterViewInit(): void {
    if (this.invalidEnrollments.size > 0) {
      const models = _.concat(this.empContrList.toArray(), this.erContrList.toArray());
      Promise.resolve()
        .then(() => {
          _.forEach(models, m => {
            if (this.invalidEnrollments.has(m.name)) {
              this.manService.setControlErrors(m, { contributionInvalid: true });
            }
          });
          this.invalidEnrollments.clear();
      });
    }
  }

  public ngOnDestroy(): void { }

  public updateRowsData() {
    this.manService.updateEmployeesUpdatedInfo(this.selectedEmployees);
    if (_.size(this.selectedEmployees) > 0) {
      const enrollmentIsValid = _.every(this.selectedEmployees, e => this.manService.isValidEnrollment(e.employeeContributionAmount, e.employerContributionAmount));
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid || !enrollmentIsValid);
    }
  }

  public onContributionChange() {
    this.updateRowsData();
    this.refreshGrid();
  }

  private refreshGrid(): void {
    if (!this.selectedEmployees) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.selectedEmployees, this.gridState.state);
  }

}
