<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents">
      <slx-vacation-planner-header (settingsChanged)="onSettingsChanged($event)"></slx-vacation-planner-header>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <slx-vacation-grid
        [settings]="settings"
        [vacationPlannerData]="vacationPlannerData"
        [orgLevel]="selectedOrgLevel"
        [redrawToggler]="redrawToggler"
      ></slx-vacation-grid>
    </div>
  </div>
</slx-spinner>
