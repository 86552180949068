export interface IBenefitsConfig {
  api: {
    configuration: {
      root: string;
      benefitClass: string;
      validate: string;
    };
    benefits: {
      root: string;
      workplace: string;
      providers: string;
      line: string;
      lineCobra: string;
      provider: string;
      expire: string;
      extend: string;
      renew: string;
      attachment: string;
      linesInfo: string;
      providersWithEnrollments: string;
      attachments: string;
      eligibilityRule: string;
      eligibilityRuleByTier: string;
      eligibilityRuleConfiguration:string;
      eligibleRules: string;
      validateDeductionCode:string;
      employee: {
        root: string;
        eligibleInfo: string;
        enrolledInfo: string;
        eligible: string;
        processFormulaForEmployees: string;
        enroll: string;
        enrolled: string;
        benefitEnrollment: string;
        enrolledEmployees: string;
        dropReason: string;
        dropReasons: string;
        dropCoverage: string;
        updateEmployeeBenefitEnrollment: string;
        deleteEmployeeBenefitEnrollment: string;
        enrollBenefits: string;
        dependents: string;
      },
      payrollMapping: string;
      payrollExistingMapping: string;
      createMapping:string;
      validation: {
        root: string;
        providerName: string;
      }
    };
  };
  files: {
    acceptedTypes: Array<string>;
    maxFileSizeBytes: number;
    maxFiles: number;
  };
  benefitGroupsConfig: {
    medical: {
      type: string;
      icon: string;
    };
    dental: {
      type: string;
      icon: string;
    };
    vision: {
      type: string;
      icon: string;
    };
    C401K: {
      type: string;
      icon: string;
    };
    insurance: {
      type: string;
      icon: string;
    };
    cobra: {
      type: string;
      icon: string;
    };
    tuition: {
      type: string;
      icon: string;
    };
    voluntary: {
      type: string;
      icon: string;
    };
  };
}
export const benefitsConfig: IBenefitsConfig = {
  api: {
    configuration: {
      root: 'configuration',
      benefitClass: 'benefitClass',
      validate: 'validate'
    },
    benefits: {
      root: 'benefits',
      workplace: 'workplace',
      providers: 'providers',
      line: 'line',
      lineCobra: 'lineCobra',
      provider: 'provider',
      expire: 'expire',
      extend: 'extend',
      renew: 'renew',
      attachment: 'attachment',
      linesInfo: 'linesInfo',
      providersWithEnrollments: 'providersWithEnrollments',
      attachments: 'attachments',
      eligibilityRule: 'eligibilityRule',
      eligibilityRuleByTier: 'eligibilityRuleByTier',
      eligibilityRuleConfiguration: 'eligibilityRuleConfiguration',
      eligibleRules: 'eligibleRules',
      validateDeductionCode:'validateDeductionCode',
      employee: {
        root: 'employee',
        eligibleInfo: 'eligibleInfo',
        enrolledInfo: 'enrolledInfo',
        eligible: 'eligible',
        processFormulaForEmployees:'processFormulaForEmployees',
        enroll: 'enroll',
        enrolled: 'enrolled',
        benefitEnrollment: 'benefitEnrollment',
        enrolledEmployees: 'enrolledEmployees',
        dropReason: 'dropReason',
        dropReasons: 'dropReasons',
        dropCoverage: 'dropCoverage',
        updateEmployeeBenefitEnrollment: 'updateEmployeeBenefitEnrollment',
        deleteEmployeeBenefitEnrollment: 'deleteEmployeeBenefitEnrollment',
        enrollBenefits: 'enrollBenefits',
        dependents: 'dependents'
      },
      payrollMapping: 'payrollMapping',
      payrollExistingMapping: 'payrollExistingMapping',
      createMapping:'createMapping',
      validation: {
        root: 'validation',
        providerName: 'providerName'
      }
    }
  },
  files: {
    acceptedTypes: ['.doc', '.docm', '.docx', '.txt', '.pdf', '.xlsx', '.xls', '.csv', '.xlsm', '.jpeg', '.jpg', '.tiff', '.gif'],
    maxFileSizeBytes: 10485760,
    maxFiles: 100
  },
  benefitGroupsConfig: {
    medical: {
      type: 'Medical',
      icon: 'fas fa-clinic-medical'
    },
    dental: {
      type: 'Dental',
      icon: 'fas fa-tooth'
    },
    vision: {
      type: 'Vision',
      icon: 'fas fa-eye'
    },
    insurance: {
      type: 'Insurance',
      icon: 'fas fa-heartbeat'
    },
    C401K: {
      type: 'C401K',
      icon: 'fas fa-hand-holding-usd'
    },
    cobra: {
      type: 'Others',
      icon: 'fas fa-circle'
    },
    tuition: {
      type: 'TuitionReimbursement',
      icon: 'fas fa-user-graduate'
    },
    voluntary: {
      type: 'Voluntary',
      icon: 'fas fa-sack-dollar'
    }
  }
};
