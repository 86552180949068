import { Injectable } from '@angular/core';
import { IAppState } from '../../../../store';
import { NgRedux } from '@angular-redux/store';
import { OrganizationTreeContext } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { OrgLevelActions } from '../../../../organization/actions/index';

@Injectable()
export class EmployeeListActions {
  public static RESET_VIEW: string = 'RESET_VIEW';
  public static RESET_IS_ACTIVE: string = 'RESET_IS_ACTIVE';
  public static SELECT_VIEW: string = 'SELECT_VIEW';
  public static SELECT_ITEM: string = 'SELECT_ITEM';
  public static SELECT_EMPLOYEE: string = 'SELECT_EMPLOYEE';

  private ngRedux: NgRedux<IAppState>;
  private orgLevelActions: OrgLevelActions;
  constructor(ngRedux: NgRedux<IAppState>, orgLevelActions: OrgLevelActions) {
    this.ngRedux = ngRedux;
    this.orgLevelActions = orgLevelActions;
  }

  public resetView(): void {
     this.ngRedux.dispatch({
      type: EmployeeListActions.RESET_VIEW
    });
  }

  public selectView(viewName: number, isActive: boolean): void {
    let state: any = this.ngRedux.getState();
    let orgLevelId: string = state.orgLevel.get('id').toString();
    this.ngRedux.dispatch({
      type: EmployeeListActions.SELECT_VIEW,
      payload: { orgLevelId: orgLevelId, viewName: viewName, isActive: isActive }
    });
  }

  public selectItems(viewName: number, isActive: boolean, itemIds: string[]): void {
    let state: any = this.ngRedux.getState();
    let orgLevelId: string = state.orgLevel.get('id').toString();
    this.ngRedux.dispatch({
      type: EmployeeListActions.SELECT_ITEM,
      payload: { orgLevelId: orgLevelId, viewName: viewName, isActive: isActive, itemIds: itemIds }
    });
  }
}
