import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import * as moment from 'moment';
import { configurationConfig } from '../../../configuration/configuration.config';

import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Actions, FieldsMeta, Meta, ResponseBody } from '../../../core/models/index';

import { IdealScheduleListDTO, IdealScheduleConfigurationDTO, IdealScheduleOverviewItemDTO, IdealScheduleShiftGroupDTO } from '../../models/ideal-schedule/dto/index';
import { IdealScheduleMapService } from './ideal-schedule-map.service';
import { IdealScheduleList, IdealSchedulePosition, IdealScheduleOverviewItem, IdealScheduleConfigCensus } from '../../models/ideal-schedule/index';
import { IdealSchedulePositionHistoryRecord } from '../../models/ideal-schedule/ideal-schedule-position-history-record';
import { IdealSchedulePositionDTO } from '../../models/ideal-schedule/dto/ideal-schedule-position-dto';
import { IdealSchedulePositionHistoryRecordDTO } from '../../models/ideal-schedule/dto/ideal-schedule-position-history-record-dto';
import { dateTimeUtils } from '../../../common/utils/index';
import { Lookup, LookupType } from './../../../organization/models/lookup/lookup-definition';
import { LookupService } from './../../../organization/services/lookup/lookup.service';
import { MetaMapService } from '../../../core/services/index';
import * as _ from 'lodash';
import { IImportException, IdealScheduleImportTemplate, ImportException, ImportTemplateDto } from '../../models/ideal-schedule/ideal-schedule-import-template';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { ReplaySubject } from 'rxjs';
import { ImportFileDetails, TemplateValidator } from '../../models/ideal-schedule/ideal-schedule-import-template-validator';

@Injectable()
export class IdealScheduleApiService {

  constructor(private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private idealScheduleMapService: IdealScheduleMapService,
    private metaMapService: MetaMapService,
    private lookupService: LookupService
  ) {
  }

  public getIdealScheduleOverview(orgLevelId: number): Promise<IdealScheduleOverviewItem[]> {

    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.idealSchedule.root}/${configurationConfig.api.configuration.configuration.idealSchedule.overview}`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<IdealScheduleOverviewItem[]> = this.apiUtilService.request<IdealScheduleOverviewItemDTO[], Meta>(request)
      .then((response: ResponseBody<IdealScheduleOverviewItemDTO[], Meta>) =>
        this.idealScheduleMapService.mapIdealScheduleOverview(response.data));
    return promise;
  }

  public getIdealSchedule(orgLevelId: number): Promise<{ actions: Actions, records: IdealScheduleList }> {

    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.idealSchedule.root}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<{ actions: Actions, records: IdealScheduleList }> = this.apiUtilService.request<IdealScheduleListDTO, Meta>(request)
      .then((response: ResponseBody<IdealScheduleListDTO, Meta>) => {
        let actions: Actions = response.meta ? this.metaMapService.mapActions(response.meta as FieldsMeta) : null;
        return { actions: actions, records: this.idealScheduleMapService.mapIdealScheduleList(response.data) };
      });
    return promise;
  }
  public getIdealSchedulePosition(orgLevelId: number, positionId: number, startDate: Date, endDate: Date): Promise<IdealSchedulePosition> {

    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.idealSchedule.root}/position/${positionId}`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let promise: Promise<ResponseBody<IdealSchedulePositionDTO, Meta>> = this.apiUtilService.request<IdealSchedulePositionDTO, Meta>(request);
    let shiftDefinitionPromise: Promise<Lookup> = this.lookupService.getLookup({ lookupType: LookupType.shiftDefinition, orgLevelId: orgLevelId });
    let shiftGroupDefinitionPromise: Promise<Lookup> = this.lookupService.getLookup({ lookupType: LookupType.shiftGroupDefinition, orgLevelId: orgLevelId });

    return Promise.all([promise, shiftDefinitionPromise, shiftGroupDefinitionPromise])
      .then((values: any[]) => {
        let positionResponse: ResponseBody<IdealSchedulePositionDTO, Meta> = values[0];
        let shifts: Lookup = values[1];
        let groups: Lookup = values[2];
        return this.idealScheduleMapService.mapIdealSchedulePosition(positionResponse.data, shifts, groups);
      });
  }

  public getIdealSchedulePositionHistory(orgLevelId: number, positionId: number): Promise<IdealSchedulePositionHistoryRecord[]> {

    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.idealSchedule.root}/position/${positionId}/history`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<ResponseBody<IdealSchedulePositionHistoryRecordDTO[], Meta>> = this.apiUtilService.request<IdealSchedulePositionHistoryRecordDTO[], Meta>(request);
    return promise.then((response: ResponseBody<IdealSchedulePositionHistoryRecordDTO[], Meta>) => {
      return this.idealScheduleMapService.mapIdealSchedulePositionsHistory(response.data);
    });
  }

  public saveIdealSchedulePosition(scheduleId: number, orgLevelId: number, census: IdealScheduleConfigCensus, startDate: Date, endDate: Date,
    position: IdealSchedulePosition): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.idealSchedule.root}/${scheduleId}`;
    position.startDate = startDate;
    position.endDate = endDate;
    position.idealScheduleCensus = census;
    let body: any = this.idealScheduleMapService.reverseMapIdealSchedulePosition(position);

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    return this.apiUtilService.request<any[], Meta>(request);
  }

  public saveIdealScheduleConfiguration(scheduleId: number, orgLevelId: number, startDate: moment.Moment, endDate: moment.Moment,
    positionId: number, targetHours: number): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.idealSchedule.root}/${scheduleId}/${configurationConfig.api.configuration.root}`;

    let configuration: IdealScheduleConfigurationDTO = this.idealScheduleMapService.mapIdealScheduleConfiguration(orgLevelId, startDate, endDate, positionId, targetHours);

    let request: HttpRequest<any> = new HttpRequest('POST', url, configuration);

    return this.apiUtilService.request<any[], Meta>(request);
  }

  public GetIdealShiftValidation(orgLevelId: number): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.idealScheduleShiftGroup}`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<IdealScheduleShiftGroupDTO> = this.apiUtilService.request<IdealScheduleShiftGroupDTO, Meta>(request).then((response: ResponseBody<IdealScheduleShiftGroupDTO, Meta>) => {
      return this.idealScheduleMapService.mapidealScheduleShiftGroup(response.data);
    });
    return promise;
  }

  public getOrganizationsList(orgLevelId: number): Promise<any> {
    let orgLevelDetails = JSON.parse(sessionStorage.getItem('Organizations'));
    let orgLevelData = _.filter(orgLevelDetails, (item) => item.id !== orgLevelId && item.orgLevelType === 'Organization');
    let promise = new Promise((resolve) => resolve(this.idealScheduleMapService.mapOrgLevelDetails(orgLevelData)));
    return promise;
  }

  public importTemplatePositionsValidation(orgLevelId: number, selectedPositions: TemplateValidator[]): Promise<IImportException[]> {
    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.importTemplate.root}/${configurationConfig.api.configuration.configuration.importTemplate.export.root}/${configurationConfig.api.configuration.configuration.importTemplate.export.selectedPositionValidation.root}`;
    const body = this.idealScheduleMapService.reverseMapSelectPositionData(selectedPositions);
    const request = this.urlParamsService.createPostRequest(url, body);
    let promise: Promise<ImportException[]> = this.apiUtilService.request<IImportException[], Meta>(request)
      .then((response: ResponseBody<IImportException[], Meta>) => this.idealScheduleMapService.mapValidationException(response.data));
    return promise;
  }

  public downloadImportTemplate(orgLevelId: number, selectedPositions: IdealScheduleImportTemplate): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.importTemplate.root}/${configurationConfig.api.configuration.configuration.importTemplate.export.root}/${configurationConfig.api.configuration.configuration.importTemplate.export.excel.root}`;
    const body = this.idealScheduleMapService.mapImportTemplateData(selectedPositions);
    const request = this.urlParamsService.createPostRequest(url, body);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => this.idealScheduleMapService.mapDownloadedData(response.data));
    return promise;
  }

  public saveIdealScheduleExcel(orgLevelId: number, excelData: ImportFileDetails, startDate: moment.Moment, endDate: moment.Moment): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.importTemplate.root}/${configurationConfig.api.configuration.configuration.importTemplate.export.root}/${configurationConfig.api.configuration.configuration.importTemplate.export.saveIdealScheduleExcel.root}?startDate=${startDate.format('MM-DD-YYYY')}&endDate=${endDate.format('MM-DD-YYYY')}`;
    let body: any = this.idealScheduleMapService.reverseMapResultData(excelData);
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);
    return this.apiUtilService.request<any[], Meta>(request);
  }

}

