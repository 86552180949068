
<div class="filter">
  <a href="#" class="filter__icon" [ngClass]="{ 'active': hasFilter }" (click)="onToggleState()">
    <span class="k-icon k-i-filter"></span>
  </a>
  <div *ngIf="isShown" class="k-popup k-grid-filter-popup filter__popup">
    <p class="filter__control">
      <kendo-dropdownlist
        name="firstDescriptor"
        [data]="filterDescriptors"
        valueField="id"
        textField="text"
        [(ngModel)]="firstDescriptior"
        [popupSettings]="{ width: 'auto' }"
      ></kendo-dropdownlist>
    </p>
    <p class="filter__control margin-b">
      <input type="text" class="filter__text" [(ngModel)]="firstSearchValue" />
    </p>
    <p class="filter__control margin-b">
      <kendo-dropdownlist class="auto-width"
        name="currentCombination"
        [data]="filterCombinations"
        valueField="id"
        textField="text"
        [(ngModel)]="currentCombination"
        [popupSettings]="{ width: 'auto' }"
      ></kendo-dropdownlist>
    </p>
    <p class="filter__control">
      <kendo-dropdownlist
        name="secondDescriptor"
        [data]="filterDescriptors"
        valueField="id"
        textField="text"
        [(ngModel)]="secondDescriptior"
        [popupSettings]="{ width: 'auto' }"
      ></kendo-dropdownlist>
    </p>
    <p class="filter__control">
      <input type="text" class="filter__text" [(ngModel)]="secondSearchValue"/>
    </p>
    <p class="k-action-buttons k-button-group">
      <button class="k-button filter__button" (click)="onClear()">Clear</button>
      <button class="k-button k-primary filter__button" (click)="onFilter()" [disabled]="!hasFirstSearchValue && !hasSecondSearchValue">Filter</button>
    </p>
  </div>
</div>
