<slx-spinner [show]="isLoading">
  <form #form="ngForm">
    <label class="rb-label">Effective Date :</label>
    <div class="rb-effectiveDate">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input [(ngModel)]="selectedDate" [required]="true" name="selectedDate"
          (ngModelChange)="getAccrualTypes($event)" [acceptNullDate]="false" [minDate]="minDateLimit()">
        </slx-date-picker-ngx>
        <span errorMessage for="date"></span>
        <span errorMessage for="required"></span>
        <span errorMessage for="minDate">Date is invalid or out of valid range</span>
      </slx-input-decorator>
    </div>
    <div class="modal-body">
      <div class="reset-balance-area" *ngFor="let accrualType of accrualTypesList; let idx=index">
        <div class="col-lg-7 pad0">
          <label class="rb-label"><input type="checkbox" class="rb-CheckBox" name="accrualTypes{{idx}}"
              id="accId{{idx}}" (change)="onChangeEnableBalance(accrualType,$event.target.checked)"
              [checked]="accrualType.chkEnable" />
            {{accrualType.accrualTypeName}}</label></div>
        <div class="col-lg-5 pad0">
          <slx-kendo-number #accType="ngModel" class="rb-balance k-numerictextbox" [(ngModel)]="accrualType.balance"
            name="accrualTypes{{idx}}" [format]="format" [readonly]="!accrualType.chkEnable" [decimals]="decimalLimit"
            [required]="'true'" [max]="9999999" [min]="-9999999">
          </slx-kendo-number>
          <div class="slx-error-block align-item" *ngIf="accType.errors?.max" errorMessage for="max">
            You can only enter up to 7 digits and 2 decimals. Maximum value is 9,999,999.00.
          </div>
          <div class="slx-error-block align-item" *ngIf="accType.errors?.min" errorMessage for="min">
            You can only enter up to 7 digits and 2 decimals. Minimum value is -9,999,999.00.
          </div>
        </div>
      </div>
    </div>
    <div class="notes">
      <label class="notes__label">Notes:</label>
      <textarea class="notes__textarea" [(ngModel)]="notes" name="notes" maxlength="225"></textarea>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r pull-left">Cancel</button>
      <button type="button" (click)="onOk()" [disabled]="!chkEnable || !form.valid"
        class="theme-button-apply">Save</button>
    </div>
  </form>
</slx-spinner>
