import * as _ from 'lodash';
import { dateTimeUtils } from '../../../../common/utils/index';
import { BenefitEligibilityRule, BenefitEligibilityRuleStatement, BenefitEligibilityRuleConfigurationData, BenefitEligibilityRuleStatementGroupItem, } from '../../models/index';
import * as i0 from "@angular/core";
var BenefitEligibilityRulesMapService = /** @class */ (function () {
    function BenefitEligibilityRulesMapService() {
    }
    BenefitEligibilityRulesMapService.prototype.mapToBenefitEligibilityRules = function (eligibilityRuleDtos) {
        var _this = this;
        return _.map(eligibilityRuleDtos, function (eligibilityRuleDto) { return _this.mapToBenefitEligibilityRule(eligibilityRuleDto); });
    };
    BenefitEligibilityRulesMapService.prototype.mapToBenefitEligibilityRule = function (dto) {
        var _this = this;
        var ruleStatements = _.map(dto.ruleStatements, function (ruleStatement) {
            return _this.mapToBenefitEligibilityRuleStatement(ruleStatement);
        });
        var rule = new BenefitEligibilityRule({
            id: dto.id,
            name: dto.name,
            description: dto.description,
            type: dto.type,
            rule: dto.rule,
            ruleV5: dto.ruleV5,
            isDeleted: dto.isDeleted,
            modifiedAt: dateTimeUtils.convertFromDtoDateTimeString(dto.modifiedAt),
            modifiedBy: dto.modifiedBy,
            addedAt: dateTimeUtils.convertFromDtoDateTimeString(dto.addedAt),
            addedBy: dto.addedBy,
            ruleStatements: ruleStatements,
        });
        return rule;
    };
    BenefitEligibilityRulesMapService.prototype.mapToBenefitEligibilityRuleStatement = function (dto) {
        var statement = new BenefitEligibilityRuleStatement({
            ruleId: dto.ruleId,
            variableCode: dto.variableCode,
            predicate: dto.predicate,
            value: dto.value,
            groupId: dto.groupId,
            sequence: dto.sequence,
        });
        return statement;
    };
    BenefitEligibilityRulesMapService.prototype.mapToBenefitEligibilityRuleStatementRequestDto = function (ruleStatement) {
        return {
            ruleId: ruleStatement.ruleId,
            variableCode: ruleStatement.variableCode,
            predicate: ruleStatement.predicate,
            value: ruleStatement.value,
            groupId: ruleStatement.groupId,
            sequence: ruleStatement.sequence,
        };
    };
    BenefitEligibilityRulesMapService.prototype.mapToBenefitEligibilityRuleRequestDto = function (eligibilityRule) {
        var _this = this;
        var ruleStatementsDto = _.map(eligibilityRule.ruleStatements, function (ruleStatement) {
            return _this.mapToBenefitEligibilityRuleStatementRequestDto(ruleStatement);
        });
        var eligibilityRuleDto = {
            id: eligibilityRule.id,
            name: eligibilityRule.name,
            description: eligibilityRule.description,
            type: eligibilityRule.type,
            rule: eligibilityRule.rule,
            ruleV5: eligibilityRule.ruleV5,
            isDeleted: eligibilityRule.isDeleted,
            modifiedAt: dateTimeUtils.convertToDtoDateTimeString(eligibilityRule.modifiedAt),
            modifiedBy: eligibilityRule.modifiedBy,
            addedAt: dateTimeUtils.convertToDtoDateTimeString(eligibilityRule.addedAt),
            addedBy: eligibilityRule.addedBy,
            ruleStatements: ruleStatementsDto,
        };
        return eligibilityRuleDto;
    };
    BenefitEligibilityRulesMapService.prototype.mapToBenefitEligibilityRuleChangeRequestDto = function (changeRequest) {
        var _this = this;
        var rulesToAdd = _.map(changeRequest.rulesToAdd, function (rule) { return _this.mapToBenefitEligibilityRuleRequestDto(rule); });
        var rulesToUpdate = _.map(changeRequest.rulesToUpdate, function (rule) {
            return _this.mapToBenefitEligibilityRuleRequestDto(rule);
        });
        var eligibilityRulesChangeRequestDto = {
            rulesToAdd: rulesToAdd,
            rulesToUpdate: rulesToUpdate,
            rulesToDelete: changeRequest.rulesToDelete,
        };
        return eligibilityRulesChangeRequestDto;
    };
    BenefitEligibilityRulesMapService.prototype.mapToBenefitEligibilityRuleConfigurationData = function (dto) {
        var data = new BenefitEligibilityRuleConfigurationData({
            variables: dto.variables,
            predicates: dto.predicates
        });
        this.mapPredicatestoVariables(data.variables, dto.predicates);
        return data;
    };
    BenefitEligibilityRulesMapService.prototype.mapPredicatestoVariables = function (variables, predicates) {
        var _this = this;
        if (variables && variables.length > 0) {
            _.forEach(variables, function (variable) {
                variable.predicates = _this.mapPredicatesByIds(predicates, variable.operatorIds);
            });
        }
    };
    BenefitEligibilityRulesMapService.prototype.mapGroupsToJsonRuleObject = function (benefitRuleStatements) {
        var groups = this.mapGroupsToJsonObject(benefitRuleStatements);
        return { groups: groups };
    };
    BenefitEligibilityRulesMapService.prototype.mapResponseToGroups = function (rule) {
        var jsonGroups = JSON.parse(rule);
        if (!jsonGroups) {
            return [];
        }
        var groups = this.mapJsonRuleObjectToGroups(jsonGroups.groups);
        return groups;
    };
    BenefitEligibilityRulesMapService.prototype.mapGroupsToJsonObject = function (benefitRuleStatements) {
        var _this = this;
        var groups = _.map(benefitRuleStatements, function (statement) {
            if (statement.groups && statement.groups.length > 0) {
                var groups_1 = _this.mapGroupsToJsonObject(statement.groups);
                var groupItem = {
                    sequence: statement.sequence,
                    conditionType: statement.conditionType
                        ? statement.conditionType
                        : null,
                    groups: groups_1,
                };
                return groupItem;
            }
            var varableItem = _this.mapVariablesToJsonObject(statement);
            return varableItem;
        });
        return groups;
    };
    BenefitEligibilityRulesMapService.prototype.mapVariablesToJsonObject = function (statement) {
        if (statement && statement.variable) {
            var variable = {
                variableId: statement.variable.variableId,
                operatorType: statement.variable.operatorType,
                valueType: statement.variable.valueType,
                value: statement.variable.value,
                dataType: statement.variable.dataType,
            };
            var newStatement = {
                sequence: statement.sequence,
                conditionType: statement.conditionType ? statement.conditionType : null,
                variable: variable,
            };
            return newStatement;
        }
    };
    BenefitEligibilityRulesMapService.prototype.mapJsonRuleObjectToGroups = function (groups) {
        var _this = this;
        var newGroups = _.map(groups, function (group) {
            if (group.groups && group.groups.length > 0) {
                return new BenefitEligibilityRuleStatementGroupItem({
                    sequence: group.sequence,
                    conditionType: group.conditionType ? group.conditionType : null,
                    groups: _this.mapJsonRuleObjectToGroups(group.groups),
                });
            }
            return _this.mapJsonRuleObjectToVariables(group);
        });
        return newGroups;
    };
    BenefitEligibilityRulesMapService.prototype.mapJsonRuleObjectToVariables = function (statement) {
        var newStatement = new BenefitEligibilityRuleStatementGroupItem({
            sequence: statement.sequence,
            conditionType: statement.conditionType ? statement.conditionType : null,
            variable: {
                variableId: statement.variable.variableId,
                operatorType: statement.variable.operatorType,
                valueType: statement.variable.valueType,
                value: statement.variable.value,
                dataType: statement.variable.dataType,
            },
        });
        return newStatement;
    };
    BenefitEligibilityRulesMapService.prototype.mapPredicatesByIds = function (configurationPredicates, ids) {
        if (_.isEmpty(ids)) {
            return [];
        }
        var currentPredicates = _.filter(configurationPredicates, function (predicate) { return _.some(ids, function (id) { return id === predicate.id; }); });
        return currentPredicates;
    };
    BenefitEligibilityRulesMapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BenefitEligibilityRulesMapService_Factory() { return new BenefitEligibilityRulesMapService(); }, token: BenefitEligibilityRulesMapService, providedIn: "root" });
    return BenefitEligibilityRulesMapService;
}());
export { BenefitEligibilityRulesMapService };
