import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { Subscription } from 'rxjs/Subscription';

import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';
import { ModalService } from '../../../../../../common/services/index';

import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitEnrolledEmployee, BenefitDetailsOption } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/benefit-employees/index';
import { ConfirmOptions, ConfirmDialog2Component } from './../../../../../../common/components/index';

@Component({
  selector: 'benefit-option-options-grid',
  templateUrl: 'benefit-option-options-grid.component.html'
})
export class BenefitOptionOptionsGridComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<BenefitEnrolledEmployee>;
  public columnGroup = 'BenefitsOptionOptionsGrid';
  public appConfig: IApplicationConfig;
  public isLoading: boolean = true;
  public selectableSettings: SelectableSettings;
  public readonly pageSize = 50;
  @Input()
  public selectedEmployees: BenefitEnrolledEmployee[];
  @Input()
  public benefitOptionData: BenefitDetailsOption[];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  private readonly noCovOptions = 'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.';
  private readonly noCovOptionContribution = 'Employees and/or Employer contributions have to be greater than 0';

  @Output()
  public onEnrollmentCancel: EventEmitter<true>;

  @ViewChild('form', {static: true}) 
  private ngForm: NgForm;

  private isFormValid: boolean = true;

  constructor(private manService: BenefitEmployeeManagementService, private modalService: ModalService) {
    this.gridState = new KendoGridStateHelper<BenefitEnrolledEmployee>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.selectableSettings = {
      checkboxOnly: true
    };
    this.appConfig = appConfig;
    this.onEnrollmentCancel = new EventEmitter();
  }

  public ngOnInit() {
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
    this.refreshGrid();
    if (_.size(this.benefitOptionData) === 0) {
      this.showWarningMessage(this.noCovOptions);
    }
    this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(() => {
      this.isFormValid = this.ngForm.valid;
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
    });
  }

  public ngOnDestroy(): void { }

  public updateRowsData() {
    this.manService.updateEmployeesUpdatedInfo(this.selectedEmployees);
    if (_.size(this.selectedEmployees) > 0) {
      const enrollmentIsValid = _.every(this.selectedEmployees, e => this.manService.isValidCovOption(e));
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid || !enrollmentIsValid);
    }
  }

  public onOptionValueChange(item: BenefitEnrolledEmployee) {
    item.benefitTierOptionId = item.optionCode.id;
    item.code = item.optionCode.code;
    this.updateRowsData();
  }

  public showWarningMessage(message: string): void {
    const options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'Warning',
      message,
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.onEnrollmentCancel.emit();
        }
      }, options);
  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (!this.selectedEmployees) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.selectedEmployees, this.gridState.state);
  }
}
