import { ExportDataStatus } from '../enums/export-data-status';

export interface IExportDataExecutionStatus {
  configurationId: number;
  executionId: string;
  executionStatus: number;
  changedOn: string;
  reason: string;
}

export class ExportDataExecutionStatus {
  public configurationId: number;
  public executionId: string;
  public status: ExportDataStatus;
  public changedOn: Date;
  public reason: string;
}
