import * as tslib_1 from "tslib";
import { appConfig } from '../../../app.config';
import { pmConfig } from '../performance-management.config';
import { FieldsMeta } from '../../../core/models/index';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { PerformanceManagementMapService } from './performance-management-map.service';
var PerformanceManagementApiService = /** @class */ (function () {
    function PerformanceManagementApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    PerformanceManagementApiService.prototype.getReviewRecords = function (orgLevelId, sDate, eDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getPmRosterApi();
                params = {
                    orgLevelId: orgLevelId,
                    start: dateTimeUtils.convertToDtoString(sDate),
                    end: dateTimeUtils.convertToDtoString(eDate)
                };
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapReviewRecords(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.loadReviewEntry = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getPmReviewApi() + "/" + id;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapReviewEntry(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.createReviewEntry = function (review) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getPmReviewApi();
                body = this.mapService.mapReviewEntryToDto(review);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapReviewEntry(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.updateReviewEntry = function (review) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getPmReviewApi() + "/" + review.id;
                body = this.mapService.mapReviewEntryToDto(review);
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapReviewEntry(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.deleteReviewEntry = function (review) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getPmReviewApi() + "/" + review.id;
                request = this.urlParamsService.createDeleteRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return response.data; })];
            });
        });
    };
    PerformanceManagementApiService.prototype.completeReviewEntry = function (review) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getPmReviewApi() + "/" + review.id;
                body = this.mapService.mapReviewEntryToDto(review);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapReviewEntry(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.saveTemplateAttachments = function (templateId, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, formData, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getCfgTemplatesApi() + "/attachments";
                formData = this.mapService.mapFilesToFormData(files);
                request = this.urlParamsService.requestPost(url, formData, { templateId: templateId });
                request.autoContentType = true;
                return [2 /*return*/, this.apiUtilService.requestNew(request)
                        .then(function (response) { return _this.mapService.mapTemplateDefinition(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.saveAttachments = function (reviewId, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, formData, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getPmAttachmentApi();
                formData = this.mapService.mapFilesToFormData(files);
                request = this.urlParamsService.requestPost(url, formData, { reviewId: reviewId });
                request.autoContentType = true;
                return [2 /*return*/, this.apiUtilService.requestNew(request)
                        .then(function (response) { return _this.mapService.mapReviewEntry(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.deleteAttachment = function (reviewId, attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getPmAttachmentApi();
                request = this.urlParamsService.createDeleteRequest(url, { id: reviewId, documentIds: [attachmentId] });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapReviewEntry(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.downloadAttachment = function (documentId) {
        var url = "" + this.getPmAttachmentApi();
        var params = {
            documentId: documentId
        };
        var request = this.urlParamsService.createGetRequest(url, params);
        var promise = this.apiUtilService.requestForFile(request)
            .then(function (file) { return file; });
        return promise;
    };
    PerformanceManagementApiService.prototype.getTemplateDefinitions = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getCfgTemplatesApi();
                params = {
                    orgLevelId: orgLevelId
                };
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) {
                        return _this.mapService.mapTemplateDefinitions(response.data);
                    })];
            });
        });
    };
    PerformanceManagementApiService.prototype.getCodeDefinitions = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getCfgCodesApi();
                params = {
                    orgLevelId: orgLevelId
                };
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) {
                        return _this.mapService.mapCfgPmCodes(response.data);
                    })];
            });
        });
    };
    PerformanceManagementApiService.prototype.createCode = function (code) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getCfgCodesApi();
                body = this.mapService.mapCfgPmCodeDto(code);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapCfgPmCode(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.updateCode = function (code) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getCfgCodesApi();
                body = this.mapService.mapCfgPmCodeDto(code);
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapCfgPmCode(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.removeCode = function (codeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getCfgCodesApi() + "/" + codeId;
                request = this.urlParamsService.createDeleteRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return response.data; })];
            });
        });
    };
    PerformanceManagementApiService.prototype.createTemplate = function (tpl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getCfgTemplatesApi();
                body = this.mapService.mapTemplateDefinitionDto(tpl);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapTemplateDefinition(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.updateTemplate = function (tpl) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.getCfgTemplatesApi();
                body = this.mapService.mapTemplateDefinitionDto(tpl);
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapTemplateDefinition(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.removeTemplate = function (tplId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getCfgTemplatesApi() + "/" + tplId;
                request = this.urlParamsService.createDeleteRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return response.data; })];
            });
        });
    };
    PerformanceManagementApiService.prototype.getEmployeeReviews = function (employeeId, startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getPmEmployeeRosterApi();
                params = {
                    employeeId: employeeId
                };
                if (startDate != null) {
                    params.startDate = dateTimeUtils.convertToDtoString(startDate);
                }
                if (endDate != null) {
                    params.endDate = dateTimeUtils.convertToDtoString(endDate);
                }
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapEmployeeReviewRecords(response.data); })];
            });
        });
    };
    PerformanceManagementApiService.prototype.getPmAttachmentApi = function () {
        return this.getPmRootApi() + "/" + pmConfig.api.pm.attachment;
    };
    PerformanceManagementApiService.prototype.getPmReviewApi = function () {
        return this.getPmRootApi() + "/" + pmConfig.api.pm.review;
    };
    PerformanceManagementApiService.prototype.getPmEmployeeRosterApi = function () {
        return this.getPmRosterApi() + "/" + pmConfig.api.pm.employee;
    };
    PerformanceManagementApiService.prototype.getPmRosterApi = function () {
        return this.getPmRootApi() + "/" + pmConfig.api.pm.roster;
    };
    PerformanceManagementApiService.prototype.getPmRootApi = function () {
        return this.getApiRoot() + "/" + pmConfig.api.pm.root;
    };
    PerformanceManagementApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    PerformanceManagementApiService.prototype.getConfigRootApi = function () {
        return this.getApiRoot() + "/" + pmConfig.api.configuration.root + "/" + pmConfig.api.configuration.pm.root;
    };
    PerformanceManagementApiService.prototype.getCfgCodesApi = function () {
        return this.getConfigRootApi() + "/" + pmConfig.api.configuration.pm.pmCode.root;
    };
    PerformanceManagementApiService.prototype.getCfgTemplatesApi = function () {
        return this.getConfigRootApi() + "/" + pmConfig.api.configuration.pm.templates.root;
    };
    return PerformanceManagementApiService;
}());
export { PerformanceManagementApiService };
