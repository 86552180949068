import { Injectable } from '@angular/core';
import { Assert } from '../../../../framework/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import {
  IEmployeeSectionsAttendancePoints,
  EmployeeSectionsAttendancePoints,
  IEmployeeSectionsAttendancePointsEntry,
  EmployeeSectionsAttendancePointsEntry,
  IEmployeeSectionsPerformance,
  EmployeeSectionsPerformance,
  IEmployeeSectionsAttendance,
  IEmployeeSectionsAttendanceDay,
  IAbsence,
  EmployeeSectionsAttendance,
  EmployeeSectionsAttendanceDay,
  IAbsenceStatistics, IAbsenceStatItem,
  AbsenceStatistics, AbsenceStatItem, IEmployeeSectionsAttendanceBuybacks, EmployeeSectionsAttendanceBuybacks, IEmployeeSectionsAssignedDefinitions, EmployeeSectionsAssignedDefinitions
} from '../../models/index';
import { IFieldData, FieldsMeta, Meta } from '../../../../core/models/index';
import { MetaMapService } from '../../../../core/services/index';
import { LookupMapService } from '../../../../organization/services/index';
import { appConfig } from '../../../../app.config';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable()
export class EmployeeSectionsPerformanceMapService {
  private metaMapService: MetaMapService;
  private lookupMapService: LookupMapService;

  constructor(metaMapService: MetaMapService, lookupMapService: LookupMapService) {
    Assert.isNotNull(metaMapService, 'metaMapService');
    Assert.isNotNull(lookupMapService, 'lookupMapService');
    this.metaMapService = metaMapService;
    this.lookupMapService = lookupMapService;
  }

  public mapToAttendancePoints(dto: IEmployeeSectionsAttendancePoints, meta: FieldsMeta): EmployeeSectionsAttendancePoints {
    Assert.isNotNull(dto, 'dto');
    let attendancePoints: EmployeeSectionsAttendancePoints = new EmployeeSectionsAttendancePoints();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);

    attendancePoints.currentBalance = dto.currentBalance;
    const entries = this.mapEntries(dto, metaMap);
    attendancePoints.points = entries;
    attendancePoints.actions = this.metaMapService.mapActions(meta);
    return attendancePoints;
  }

  public mapToAttendanceBuyBacks(dto: IEmployeeSectionsAttendanceBuybacks, meta: FieldsMeta): EmployeeSectionsAttendanceBuybacks {
    Assert.isNotNull(dto, 'dto');
    let buybacks: EmployeeSectionsAttendanceBuybacks = new EmployeeSectionsAttendanceBuybacks();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    const entries = this.mapEntries(dto, metaMap);
    buybacks.points = entries;
    buybacks.actions = this.metaMapService.mapActions(meta);
    return buybacks;
  }

  private mapEntries(attendancePointsDto: IEmployeeSectionsAttendanceBuybacks, metaMap: StringMap<IFieldData>) {
    const entries = [];
    _.forEach(attendancePointsDto.points, (pointsEntryDto: IEmployeeSectionsAttendancePointsEntry) => {
      let pointsEntry: EmployeeSectionsAttendancePointsEntry = new EmployeeSectionsAttendancePointsEntry();
      pointsEntry.id = pointsEntryDto.id;
      pointsEntry.value = this.metaMapService.mapMeta<number>(metaMap, 'value', pointsEntryDto.value);
      pointsEntry.value2 = this.metaMapService.mapMeta<number>(metaMap, 'value2', pointsEntryDto.value2);
      pointsEntry.categoryName = this.metaMapService.mapMeta<string>(metaMap, 'categoryName', pointsEntryDto.categoryName);
      let dateOn: Date = dateTimeUtils.convertFromDtoString(pointsEntryDto.dateOn);
      pointsEntry.dateOn = this.metaMapService.mapMeta<Date>(metaMap, 'dateOn', dateOn, pointsEntryDto.dateOn);
      pointsEntry.definition = this.metaMapService.mapMeta<string>(metaMap, 'definition', pointsEntryDto.definition);
      pointsEntry.expiration = this.metaMapService.mapMeta<Date>(metaMap, 'expiration',
        dateTimeUtils.convertFromDtoString(pointsEntryDto.expiration), pointsEntryDto.expiration);
      entries.push(pointsEntry);
    });
    return entries;
  }

  public mapToAttendance(attendanceDays: IEmployeeSectionsAttendance, meta: FieldsMeta): EmployeeSectionsAttendance {
    Assert.isNotNull(attendanceDays, 'attendanceDays');
    let attendance: EmployeeSectionsAttendance = new EmployeeSectionsAttendance();
    let attDays: EmployeeSectionsAttendanceDay[] = [];
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);

    _.forEach(attendanceDays.dates, (attendanceDay: IEmployeeSectionsAttendanceDay) => {
      let attDay: EmployeeSectionsAttendanceDay = new EmployeeSectionsAttendanceDay();
      attDay.date = attendanceDay.date;
      attDay.isWeekend = attendanceDay.isWeekend;
      attDay.isScheduled = attendanceDay.isScheduled;
      attDay.isWorkDay = attendanceDay.isWorkDay;
      attDay.scheduledAbsence = attendanceDay.scheduledAbsence;
      attDay.actualAbsence = attendanceDay.actualAbsence;
      attDay.scheduledAbsenceHours = attendanceDay.scheduledAbsenceHours;
      attDay.actualAbsenceHours = attendanceDay.actualAbsenceHours;
      attDays.push(attDay);
    });

    attendance.dates = attDays;
    attendance.totals = this.mapStatistics(attendanceDays.totals);
    attendance.actions = this.metaMapService.mapActions(meta);
    return attendance;
  }

  public mapStatistics(dto: IAbsenceStatistics): AbsenceStatistics {
    let totals: AbsenceStatistics = new AbsenceStatistics();
    totals.absentAfterHoliday = dto.absentAfterHoliday;
    totals.absentBeforeHoliday = dto.absentBeforeHoliday;
    totals.frequentlyAbsentOn = dto.frequentlyAbsentOn;
    totals.occurrences = dto.occurrences;
    totals.actualAbsenceStatistics = this.mapAbsenceStatItems(dto.actualAbsenceStatistics);
    totals.scheduledAbsenceStatistics = this.mapAbsenceStatItems(dto.scheduledAbsenceStatistics);
    return totals;
  }

  public mapAbsenceStatItems(dtos: IAbsenceStatItem[]): AbsenceStatItem[] {
    let items: AbsenceStatItem[] = [];
    _.forEach(dtos, (dto: IAbsenceStatItem) => {
      let item: AbsenceStatItem = new AbsenceStatItem();
      item.absence = dto.absence;
      item.occurrences = dto.occurrences;
      item.shiftCount = dto.shiftCount;
      item.partialShiftCount = dto.partialShiftCount;
      item.totalsHours = dto.totalsHours;
      items.push(item);
    });
    return items;
  }

  public mapToAttendancePointsDto(attendancePoints: EmployeeSectionsAttendancePoints): IEmployeeSectionsAttendancePoints {
    Assert.isNotNull(attendancePoints, 'terminationEntries');
    let points: IEmployeeSectionsAttendancePointsEntry[] = [];
    _.forEach(attendancePoints.points, (termination: EmployeeSectionsAttendancePointsEntry) => {
      let dateOn: string = dateTimeUtils.convertToDtoString(termination.dateOn.fieldValue);
      let expiration: string = dateTimeUtils.convertToDtoString(termination.expiration.fieldValue);
      let terminationEntry: IEmployeeSectionsAttendancePointsEntry = {
        id: termination.id,
        value: termination.value.fieldValue,
        value2: termination.value2.fieldValue,
        categoryName: termination.categoryName.fieldValue,
        dateOn: dateOn,
        definition: termination.definition.fieldValue,
        expiration: expiration
      };
      points.push(terminationEntry);
    });
    let attendancePointsDto: IEmployeeSectionsAttendancePoints = {
      currentBalance: attendancePoints.currentBalance,
      points: points
    };
    return attendancePointsDto;
  }

  public mapToSectionsPerformance(data: IEmployeeSectionsPerformance): EmployeeSectionsPerformance {
    let performance: EmployeeSectionsPerformance = new EmployeeSectionsPerformance();
    performance.attendancePoints = this.mapToAttendancePoints(data.attendancePoints.data, data.attendancePoints.metadata);
    performance.attendance = this.mapToAttendance(data.attendance.data, data.attendance.metadata);
    // using access rights of attendance points subsection
    performance.buyBackPoints = this.mapToAttendanceBuyBacks(data.buyBackPoints.data, data.attendancePoints.metadata);
    return performance;
  }

  public mapToAssignedDefinitions(data: IEmployeeSectionsAssignedDefinitions[], meta: Meta): EmployeeSectionsAssignedDefinitions[] {
    return _.map(data, d => this.mapToDefinitionInfo(d));
  }

  public mapToDefinitionInfo(dtos: IEmployeeSectionsAssignedDefinitions): EmployeeSectionsAssignedDefinitions {
      let item: EmployeeSectionsAssignedDefinitions = new EmployeeSectionsAssignedDefinitions();
      item.categoryName = dtos.categoryName;
      item.definition = dtos.definition;
      item.id = dtos.id;
      let assignedon : Date = dateTimeUtils.convertFromDtoDateString(dtos.dateOn);
      item.dateOn = assignedon;
     return item;
  }
}

