import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { FileService } from '../../../../common/services/index';
import { WcCreateOshaRosterState, WcIncidentRosterContainer, WcViewList, WcIncidentRosterEntry, IWcGenerateOsha301APDF, IWcGenerateOsha300APDF, CreateOsha300AFormDialogOptions } from '../../models/index';
import { WcCommonManagementService } from './wc-common-management.service';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { WcRosterApiService } from './wc-roster-api.service';
import { DateRange } from '../../../../core/models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { WcRosterToolbarService } from './wc-roster-toolbar.service';
import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
var WcCreateOshaFormService = /** @class */ (function () {
    function WcCreateOshaFormService(commonService, fileService, apiService, wcRosterToolbarService) {
        this.commonService = commonService;
        this.fileService = fileService;
        this.apiService = apiService;
        this.wcRosterToolbarService = wcRosterToolbarService;
        this.loading$ = new ReplaySubject(1);
        this.establishmentSize$ = new ReplaySubject(1);
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.subscriptions = {};
    }
    WcCreateOshaFormService.prototype.init = function (componentId) {
        this.commonService.init(componentId, 'columnsVisibility', WcViewList.CreateOshaRkForms);
        this.subscribeToToolbarService();
        this.subscribeToOrgLevelChanges();
        this.restoreState();
    };
    WcCreateOshaFormService.prototype.destroy = function () {
        this.commonService.destroy();
        this.loading$.complete();
        this.orgLevelChanged$.complete();
        this.establishmentSize$.complete();
    };
    WcCreateOshaFormService.prototype.subscribeToOrgLevel = function (callback) {
        return this.commonService.subscribeToOrgLevel(callback);
    };
    WcCreateOshaFormService.prototype.subscribeToExport = function (callback) {
        return this.commonService.subscribeToExport(callback);
    };
    WcCreateOshaFormService.prototype.subscribeToChangeState = function (callback) {
        return this.commonService.subscribeToChangeState(callback);
    };
    WcCreateOshaFormService.prototype.subscribeToLoading = function (callback) {
        return this.commonService.subscribeToLoading(callback);
    };
    WcCreateOshaFormService.prototype.subscribeToLoadedRecords = function (callback) {
        return this.commonService.subscribeToLoadedRecords(callback);
    };
    WcCreateOshaFormService.prototype.openEditReportDialog = function (reportId) {
        this.commonService.openEditReportDialog(reportId);
    };
    WcCreateOshaFormService.prototype.subscribeToToolbarService = function () {
        this.commonService.subscribeToToolbarService();
    };
    WcCreateOshaFormService.prototype.subscribeToOrgLevelChanges = function () {
        this.subscribeToOrgLevelChangesFromComparisons();
        this.commonService.subscribeToOrgLevelChanges();
    };
    WcCreateOshaFormService.prototype.subscribeToestablishmentSize = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.establishmentSize$.subscribe(callback);
    };
    WcCreateOshaFormService.prototype.restoreState = function () {
        var state = new WcCreateOshaRosterState();
        state.createColumns();
        this.commonService.restoreState(state);
    };
    WcCreateOshaFormService.prototype.subscribeToGenerateOSHAForm300A = function (callback) {
        return this.wcRosterToolbarService.subscribeToGenerateOSHAForm300A(callback);
    };
    WcCreateOshaFormService.prototype.subscribeToDateRange = function (callback) {
        return this.wcRosterToolbarService.subscribeToDateRange(callback);
    };
    WcCreateOshaFormService.prototype.subscribeToInitDateRange = function (callback) {
        return this.wcRosterToolbarService.subscribeToInitDateRange(callback);
    };
    WcCreateOshaFormService.prototype.subscribeToOrgLevelChangesFromComparisons = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id)
                    return [2 /*return*/];
                this.orgLevel = orgLevel;
                this.orgLevelChanged$.next(this.orgLevel);
                if (this.orgLevel.relatedItemId && this.orgLevel.type == "Organization") {
                    this.getEstablimentSize();
                }
                return [2 /*return*/];
            });
        }); });
    };
    WcCreateOshaFormService.prototype.generateOshaExcel300 = function (orgLevel, date, records) {
        var _this = this;
        this.commonService.setLoading(true);
        var InjuryIds = records.map(function (a) { return a.id; });
        this.apiService.generateOshaExcel(orgLevel, date, InjuryIds)
            .then(function (fileBlobResponse) {
            _this.fileService.saveToFileSystem(fileBlobResponse.blob, fileBlobResponse.file);
            _this.commonService.setLoading(false);
        }).catch(function (error) {
            if (error) {
                _this.commonService.setLoading(false);
            }
        });
    };
    WcCreateOshaFormService.prototype.generateOshaPDF300 = function (orgLevel, sDate, eDate, records) {
        var _this = this;
        this.commonService.setLoading(true);
        var InjuryIds = records.map(function (a) { return a.id; });
        var data = {
            orgLevelId: orgLevel,
            formName: 'Osha 300',
            startDate: dateTimeUtils.convertToDtoString(sDate),
            endDate: dateTimeUtils.convertToDtoString(eDate),
            recordIds: InjuryIds,
        };
        this.apiService.generateOshaPDF300(data)
            .then(function (fileBlobResponse) {
            _this.fileService.openOrSavePdf(fileBlobResponse);
            _this.commonService.setLoading(false);
        }).catch(function (error) {
            if (error) {
                _this.commonService.setLoading(false);
            }
        });
    };
    WcCreateOshaFormService.prototype.generateOshaPDF301 = function (orgLevel, records) {
        var _this = this;
        this.commonService.setLoading(true);
        var InjuryIds = records.map(function (a) { return a.id; });
        var data = {
            orgLevelId: orgLevel,
            formName: 'Osha 301',
            recordIds: InjuryIds,
        };
        this.apiService.generateOshaPDF301(data)
            .then(function (fileBlobResponse) {
            _this.fileService.openOrSavePdf(fileBlobResponse);
            _this.commonService.setLoading(false);
        }).catch(function (error) {
            if (error) {
                _this.commonService.setLoading(false);
            }
        });
    };
    WcCreateOshaFormService.prototype.getEstablimentSize = function () {
        var _this = this;
        this.loading$.next(true);
        this.apiService.getEstablismentSize(this.orgLevel.relatedItemId).then(function (value) {
            _this.establishmentSize$.next(value);
            _this.loading$.next(false);
        }).catch(function (error) {
            if (error) {
                _this.loading$.next(false);
            }
        });
    };
    WcCreateOshaFormService.prototype.updateEstablismentSize = function (orgLevelRelatedId, value) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var size;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loading$.next(true);
                this.establishmentSize$.next(value);
                size = value.establishmentSize;
                return [2 /*return*/, this.apiService.updateEstablismentSize(orgLevelRelatedId, size).catch(function (error) {
                        if (error) {
                            _this.establishmentSize$.next({ establishmentSize: null });
                            _this.loading$.next(false);
                        }
                    })];
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], WcCreateOshaFormService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], WcCreateOshaFormService.prototype, "subscriptions", void 0);
    return WcCreateOshaFormService;
}());
export { WcCreateOshaFormService };
