<div class="shifts-container">
  <div class="button-container">
    <button type="button" class="slx-button slx-with-icon" scheduleEntryLink [orgLevelId]="employeeDepartmentId"
        [employeeId]="employeeId" [dateOn]="dateOn" [disabled]="!model?.canEditSchedule || !dateOn || !employeeId ">
        <i class="fas fa-pencil-alt slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Edit</span>
      </button>
  </div>
  <div class="shifts-row">
    <div *ngFor="let dataItem of records; let rowIndex=index" class="shift-container">
      <div class="shift-header">
        <div class="shift-title">Shift {{rowIndex+1}}</div>
      </div>
      <div class="shift-par">
        <span class="shift-par-header">Start Time:</span>
        <span class="shift-par-value">{{ dataItem.start | amDateFormat: appConfig.timeFormat }}</span>
      </div>
      <div class="shift-par">
        <span class="shift-par-header">End Time:</span>
        <span class="shift-par-value">{{ dataItem.end | amDateFormat: appConfig.timeFormat }}</span>
      </div>
      <div class="shift-par">
        <span class="shift-par-header">Shift Hours</span>
        <span class="shift-par-value" *ngIf="displayOptions==='number'">{{ dataItem.duration | slxInterval }}</span>
        <span class="shift-par-value" *ngIf="displayOptions==='hmstring'">{{ dataItem.duration | slxDurationHM }}</span>

      </div>
      <div *ngIf="dataItem.unit&&dataItem.unit.name" class="shift-par">
        <span class="shift-par-header">Unit</span>
        <span class="shift-par-value">{{ dataItem.unit?.name }}</span>
      </div>
      <div *ngIf="dataItem.absence&&dataItem.absence.code" class="shift-par">
        <span class="shift-par-header">Absence</span>
        <span class="shift-par-value">{{ dataItem.absence?.description }}({{ dataItem.absence?.code }})</span>
      </div>
      <div *ngIf="!dataItem.absence && dataItem.constraint&&dataItem.constraint.code" class="shift-par">
        <span class="shift-par-header">Constraint</span>
        <span class="shift-par-value">{{ dataItem.constraint?.name }}</span>
      </div>
    </div>
  </div>
</div>
