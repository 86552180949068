import { Injectable, InjectionToken } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

export const TOOLBAR_SERVICE = new InjectionToken<ToolbarBaseService<any>>('TOOLBAR_SERVICE');

export abstract class ToolbarBaseService<T> {

  public toolbarFilters: T;
  public onFiltersChanged$: ReplaySubject<T>;
  public onRecalcToolbar$: ReplaySubject<any>;

  constructor() {
    this.onFiltersChanged$ = new ReplaySubject(1);
    this.onRecalcToolbar$ = new ReplaySubject(1);
  }

  public onRecalcToolbar(): void {
    this.onRecalcToolbar$.next(null);
  }

  public onFiltersChanged(filters: T): void {
    this.onFiltersChanged$.next(filters);
  }
}
