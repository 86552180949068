import { DialogOptions2, DialogModeSize } from './../../../../../../common/models/dialog-options';
import { Component, OnInit, Provider, Input, ViewChild } from '@angular/core';
import { EmployeeSectionsBenefitsManagementApiService } from '../../../../services';
import { ModalService } from '../../../../../../common/services';
import { LookupService } from '../../../../../../organization/services';
import { DialogOptions, IDialog } from '../../../../../../common/models';
import { BeneficiaryEditorComponent } from '../beneficiary-editor/beneficiary-editor.component';

@Component({
  selector: 'slx-beneficiary-editor-popup',
  templateUrl: './beneficiary-editor-popup.component.html'
})
export class BeneficiaryEditorPopupComponent implements OnInit, IDialog {

  public enrollmentId: number;

  public dialogResult: boolean;

  @ViewChild('editor', { static: true })
  private editor: BeneficiaryEditorComponent;

  public static openDialog(
    enrollmentId: number,
    apiService: EmployeeSectionsBenefitsManagementApiService,
    modalService: ModalService,
    lookupService: LookupService,
    callback?: (result: boolean) => void
  ): BeneficiaryEditorPopupComponent {


    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.width = '85%';
    dialogOptions.height = 560;
    dialogOptions.minWidth = 700;
    
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: LookupService,
        useValue: lookupService
      },
      {
        provide: ModalService,
        useValue: modalService
      },
      {
        provide: EmployeeSectionsBenefitsManagementApiService,
        useValue: apiService
      }

    ];

    const title = `Your Beneficiaries`;
    let component = modalService.globalAnchor.openDialog2(
      BeneficiaryEditorPopupComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );

    component.enrollmentId = enrollmentId;

    return component;

  }


  public get canSave(): boolean {
    return this.editor ? this.editor.canSave : false;
  }

  public get canCancel(): boolean {
    return this.editor ? this.editor.canCancel : true;
  }

  constructor(private options: DialogOptions2, private modalService: ModalService, private lookupService: LookupService) { }

  public ngOnInit(): void {
  }

  public async onSave(): Promise<void> {
    const result = await this.editor.saveBeneficiaries();
    if (result) {
      this.dialogResult = true;
      this.modalService.closeWindow(this.options.windowUniqueId);
    }
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

}
