export interface IShiftGroup {
    id: number;
    shiftGroupId: number;
    name: string;
    shiftGroupStartTime: number;
    shiftGroupEndTime: number;
    shiftGroupStartTimeElapsed: number;
    shiftGroupEndTimeElapsed: number;
    isNightShiftPrimary: string;
}

export class ShiftGroup {
    public id: number;
    public shiftGroupId: number;
    public name: string;
    public shiftGroupStartTime: number;
    public shiftGroupEndTime: number;
    public shiftGroupWorkHours: number;
    public isSelected: boolean;
    public selectable: boolean;
    public start: Date;
    public end: Date;
    public startTime: any;
    public endTime: any;
    public startDate: Date;
    public endDate: Date;
    public isNightShiftPrimary: boolean;
}

export class UpdateShiftGroup {
    public id: number;
    public name: string;
    public organizationId: number;
    public shiftGroupStartTime: any;
    public startTimeElapsed: number;
    public shiftGroupEndTime: any;
    public endTimeElapsed: number;
}