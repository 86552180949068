import * as tslib_1 from "tslib";
import { OverlapOverTimeMessage } from "./../../../../../app/scheduler/models/schedule-rotation/schedule-rotation-template.model";
import * as _ from "lodash";
import { DialogOptions, ModalService } from "./../../../../../app/common";
import { ShiftOverlapWarningMessageComponent } from "./../../../../../app/common/components/shift-overlap-warning-message/shift-overlap-warning-message.component";
import { Subject } from "rxjs";
import { ScheduleRotationFilterService } from "./../../../../../app/scheduler/services/scheduled-rotation-template/schedule-rotations-filter.service";
var ScheduledRotationEmployeeGridHelper = /** @class */ (function (_super) {
    tslib_1.__extends(ScheduledRotationEmployeeGridHelper, _super);
    function ScheduledRotationEmployeeGridHelper() {
        var _this = _super.call(this) || this;
        _this.shiftEdit$ = new Subject();
        _this.shiftRemove$ = new Subject();
        _this.shiftOverTimeProceed$ = new Subject();
        return _this;
    }
    ScheduledRotationEmployeeGridHelper.prototype.shiftOverLapMessage = function (result, employee, shift) {
        var _this = this;
        var overLapDatesData = _.map(result, function (item) { return item.overLappingDate; });
        var overLappingDates = overLapDatesData.join();
        var options = new DialogOptions();
        options.width = 368;
        options.showCloseButton = true;
        options.hideTitleBar = true;
        options.className = 'slx-srt-warning-message';
        options.message = 'Overlapping Shift';
        var message = new OverlapOverTimeMessage();
        message.overLap = "This shift will overlap with another shift on following dates - " + overLapDatesData + " and cannot be added";
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: options
            },
            {
                provide: ModalService,
                useValue: this.modalService
            },
            {
                provide: OverlapOverTimeMessage,
                useValue: message
            }
        ];
        var dialog = this.modalService.globalAnchor.openDialog(ShiftOverlapWarningMessageComponent, 'Warning', options, resolvedProviders, function (res) {
            if (!res) {
                _this.shiftEdit$.next([true, shift]);
            }
        });
    };
    ScheduledRotationEmployeeGridHelper.prototype.shiftOverTimeMessage = function (result, employee, shift) {
        var _this = this;
        var overTimeDatesData = _.map(result, function (item) { return item.overLappingDate; });
        var overTimeDates = overTimeDatesData.join();
        var options = new DialogOptions();
        options.width = 390;
        options.showCloseButton = true;
        options.hideTitleBar = true;
        options.className = 'slx-srt-warning-message';
        options.message = 'Overtime';
        var message = new OverlapOverTimeMessage();
        message.overTime = "By adding this shift it will result in overtime on following dates - " + overTimeDatesData;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: options
            },
            {
                provide: ModalService,
                useValue: this.modalService
            },
            {
                provide: OverlapOverTimeMessage,
                useValue: message
            }
        ];
        var dialog = this.modalService.globalAnchor.openDialog(ShiftOverlapWarningMessageComponent, 'Warning', options, resolvedProviders, function (result) {
            _this.shiftOverTimeProceed$.next([result, shift]);
        });
        if (dialog.initialized) {
            console.log(dialog.dialogResult);
        }
    };
    return ScheduledRotationEmployeeGridHelper;
}(ScheduleRotationFilterService));
export { ScheduledRotationEmployeeGridHelper };
