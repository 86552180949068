import { OnInit } from '@angular/core';
import { DialogOptions2, ModalService } from '../../../../../common';
import { NgForm } from '@angular/forms';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import * as _ from 'lodash';
var AcaExportCorrected1094cParametersComponent = /** @class */ (function () {
    function AcaExportCorrected1094cParametersComponent(modalService, options, manService) {
        this.dialogResult = false;
        this.isLoading = true;
        this.options = options;
        this.modalService = modalService;
        this.manService = manService;
    }
    Object.defineProperty(AcaExportCorrected1094cParametersComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    AcaExportCorrected1094cParametersComponent.prototype.ngOnInit = function () {
        this.isLoading = false;
        this.m_initialized = true;
    };
    Object.defineProperty(AcaExportCorrected1094cParametersComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaExportCorrected1094cParametersComponent.prototype, "valid", {
        get: function () {
            var formValid = this.form.invalid ? false : true;
            return formValid;
        },
        enumerable: true,
        configurable: true
    });
    AcaExportCorrected1094cParametersComponent.prototype.onBack = function () {
        this.onCancel();
        var acaExportDialogOptions = new AcaExportDialogOptions();
        acaExportDialogOptions.dialogType = 1;
        acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
        acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
        acaExportDialogOptions.acaExportCorrectionParameter = this.acaExportCorrectionParameter;
        this.manService.openAcaExportDialog(acaExportDialogOptions);
    };
    AcaExportCorrected1094cParametersComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    AcaExportCorrected1094cParametersComponent.prototype.onNext = function () {
        this.onCancel();
        var acaExportDialogOptions = new AcaExportDialogOptions();
        acaExportDialogOptions.dialogType = 6;
        acaExportDialogOptions.acaExportCorrectionParameter = this.acaExportCorrectionParameter;
        acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
        acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
        this.manService.openAcaExportDialog(acaExportDialogOptions);
    };
    AcaExportCorrected1094cParametersComponent.prototype.onChangeQualifiedOffer = function (data, isChecked) {
        data.qualifiedOfferMethod = isChecked;
    };
    AcaExportCorrected1094cParametersComponent.prototype.onChange98PercentageOffer = function (data, isChecked) {
        data.offerMethod98 = isChecked;
    };
    AcaExportCorrected1094cParametersComponent.prototype.onChangeOfferMecAll12Month = function (data, isChecked) {
        data.offeredMecAll12Month = isChecked;
        if (isChecked) {
            _.forEach(data.offeredMec, function (item) {
                item.isSelected = false;
            });
        }
    };
    AcaExportCorrected1094cParametersComponent.prototype.onChangeAleGroupAll12Month = function (data, isChecked) {
        data.memberOfAleGroupAll12Month = isChecked;
        if (isChecked) {
            _.forEach(data.memberOfAleGroup, function (item) {
                item.isSelected = false;
            });
        }
    };
    AcaExportCorrected1094cParametersComponent.prototype.onChangeOfferedMec = function (item, isChecked) {
        item.isSelected = isChecked;
    };
    AcaExportCorrected1094cParametersComponent.prototype.onChangeAleGroup = function (item, isChecked) {
        item.isSelected = isChecked;
    };
    return AcaExportCorrected1094cParametersComponent;
}());
export { AcaExportCorrected1094cParametersComponent };
