import * as tslib_1 from "tslib";
import { FileUploadsMapService } from "./file-uploads-map.service";
import { ApiUtilService, UrlParamsService } from "./../../../../app/common";
import { MetaMapService } from "./../../../../app/core/services";
import { LookupService } from "./../../../../app/organization";
import { configurationConfig } from "../../configuration.config";
var FileUploadsApiService = /** @class */ (function () {
    function FileUploadsApiService(apiUtilService, urlParamsService, fileUploadsMapService, metaMapService, lookupService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.fileUploadsMapService = fileUploadsMapService;
        this.metaMapService = metaMapService;
        this.lookupService = lookupService;
    }
    FileUploadsApiService.prototype.getImportedFiles = function (orgLevelId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["", "/", "/", "/", ""], ["", "/", "/", "/", ""])), configurationConfig.api.configuration.root, configurationConfig.api.configuration.orglevel.root, orgLevelId, configurationConfig.api.configuration.configuration.fileUploads.root);
        var request = this.urlParamsService.createGetRequest(url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            return _this.fileUploadsMapService.mapImportedFiles(response.data, orgLevelId);
        });
        return promise;
    };
    FileUploadsApiService.prototype.downloadImportedFile = function (importId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), configurationConfig.api.configuration.root, configurationConfig.api.configuration.configuration.fileUploads.downloadImportedFiles.root, importId);
        var request = this.urlParamsService.createPostRequest(url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.fileUploadsMapService.mapExcelResultData(response.data); });
        return promise;
    };
    return FileUploadsApiService;
}());
export { FileUploadsApiService };
var templateObject_1, templateObject_2;
