import { LookupEntity, ILookupEntity } from './lookup-entity';

export interface IShift extends ILookupEntity {
  duration: string;
  end: string;
  start: string;
}

export class Shift extends LookupEntity implements IShift {
  public duration: string;
  public end: string;
  public start: string;
}
