import { Injectable } from '@angular/core';
import { Assert } from '../../../../framework/index';
import * as _ from 'lodash';
import {
  AddEmployeeModel,
  IAddEmployeeModel,
  AddEmployeeRestrictions,
  IAddEmployeeRestrictions,
  ISectionAddEmployeeRestrictions
} from '../../models/index';
import { LookupMapService } from '../../../../organization/services/lookup/lookup-map.service';
import { MetaMapService } from '../../../../core/services/index';
import { IFieldData } from '../../../../core/models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import {
  EmpType,
  Department,
  Organization,
  Position,
  PayPolicy,
  ShiftDiffPolicy,
  PayType,
  ExemptStatus,
  State,
  MaritalStatus,
  Gender,
  Race,
  IGender, IRace, IPayType, IState, IExemptStatus, IMaritalStatus,
  BenefitClassDefinition
} from '../../../../organization/models/lookup/index';
import { TimeclockDefinition } from '../../../../organization/models/index';
import { ISelectableItemContainer } from '../../../../common/models/index';

@Injectable()
export class EmployeeActivitiesMapService {

  private lookupMapService: LookupMapService;
  private metaMapService: MetaMapService;
  constructor(lookupMapService: LookupMapService, metaMapService: MetaMapService) {
    this.lookupMapService = lookupMapService;
    this.metaMapService = metaMapService;
  }

  public mapToAddEmployeeModelDTO(data: AddEmployeeModel): IAddEmployeeModel {
    Assert.isNotNull(data, 'data IEmployeeSection');
    let selectedTimeclock: ISelectableItemContainer<TimeclockDefinition>[] = _.filter(data.timeclockDefinitions, (opt: ISelectableItemContainer<TimeclockDefinition>) => { return opt.selected; });
    let timeclockIds: number[] = _.map(selectedTimeclock, (opt: ISelectableItemContainer<TimeclockDefinition>) => { return opt.item.id; });
    let genderDto: IGender = this.lookupMapService.mapGenderDto(data.gender);
    let raceDto: IRace = this.lookupMapService.mapRaceDto(data.race);
    let payTypeDto: IPayType = this.lookupMapService.mapPayTypeDto(data.payType);
    let stateDto: IState = this.lookupMapService.mapStateDto(data.state);
    let exempDto: IExemptStatus = this.lookupMapService.mapExemptStatusDto(data.exemptStatus);
    let maritalDto: IMaritalStatus = this.lookupMapService.mapMaritalStatusDto(data.maritalStatus);

    let modelDto: IAddEmployeeModel = {
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      suffix: data.suffix,
      employeeType: this.lookupMapService.mapEmpTypeDto(data.employeeType),
      position: this.lookupMapService.mapPositionDto(data.position),
      payrollNumber: data.payrollNumber ? _.trim(data.payrollNumber) : data.payrollNumber,
      ssn: data.ssn,
      hireDate: dateTimeUtils.convertToDtoDateTimeString(data.hireDate),
      birthDate: dateTimeUtils.convertToDtoDateTimeString(data.birthDate),
      payPolicy: this.lookupMapService.mapPayPolicyDto(data.payPolicy),
      shiftDiffPolicy: this.lookupMapService.mapShiftDiffPolicyDto(data.shiftDiffPolicy),
      payType: payTypeDto ? payTypeDto.name : null,
      exemptStatus: exempDto ? exempDto.name : null,
      workWeekHours: data.workWeekHours,
      dailyBenefitHours: data.dailyBenefitHours,
      hourlyRate: data.hourlyRate,
      hourlyRate2: data.hourlyRate2,
      hourlyRate3: data.hourlyRate3,
      payClass: data.payClass,
      companyCode: data.companyCode,
      fileNumber: data.fileNumber,
      pbjId: data.pbjId,

      address: data.address,
      city: data.city,
      state: stateDto ? stateDto.value : null,
      zipCode: data.zipCode,
      phone: data.phone,
      mobilePhone: data.mobilePhone,
      altPhone: data.altPhone,
      email1: data.email1,
      email2: data.email2,
      gender: genderDto ? genderDto.value : null,
      maritalStatus: maritalDto ? maritalDto.value : null,
      race: raceDto ? raceDto.value : null,

      badgeId: data.badgeId,
      timeclockIds: timeclockIds,
      enableTimesheetsAccess: data.enableTimesheetsAccess,

      accessType: data.accessType ? data.accessType.id : null,
      timeCard: data.timeCard,
      schedule: data.schedule,
      availability: data.availability,

      departmentSchedule: data.departmentSchedule,
      inservice: data.inservice,
      payStubs: data.payStubs,
      allowPasswordChange: data.allowPasswordChange,
      allowAddressChange: data.allowAddressChange,
      password: data.password,

      benefitClassId: data.benefitClass ? data.benefitClass.id : null,
      benefitClassEffectiveDate: data.benefitClassEffectiveDate ? dateTimeUtils.convertToDtoDateTimeString(data.benefitClassEffectiveDate) : null,
      optInRequest: data.optInRequest
    };
    return modelDto;
  }

  public mapAddEmployeeRestrictions(dtoSectionRestrictions: ISectionAddEmployeeRestrictions): AddEmployeeRestrictions {
    Assert.isNotNull(dtoSectionRestrictions, 'dtoSectionRestrictions');
    let restrictions: AddEmployeeRestrictions = new AddEmployeeRestrictions();
    let metaMap: StringMap<IFieldData> = {};
    _.forEach(dtoSectionRestrictions.sections, (dtoRestrictions: IAddEmployeeRestrictions) => {
      _.forEach(dtoRestrictions.fields, (dmeta: IFieldData) => {
        metaMap[dmeta.fieldName.toLowerCase()] = dmeta;
      });
    });
    restrictions.firstName = this.metaMapService.mapMeta<string>(metaMap, 'firstName', null);
    restrictions.lastName = this.metaMapService.mapMeta<string>(metaMap, 'lastName', null);
    restrictions.middleName = this.metaMapService.mapMeta<string>(metaMap, 'middleName', null);
    restrictions.suffix = this.metaMapService.mapMeta<string>(metaMap, 'suffix', null);
    restrictions.employeeType = this.metaMapService.mapMeta<EmpType>(metaMap, 'empType', null);
    restrictions.organization = this.metaMapService.mapMeta<Organization>(metaMap, 'organization', null);
    restrictions.department = this.metaMapService.mapMeta<Department>(metaMap, 'department', null);
    restrictions.position = this.metaMapService.mapMeta<Position>(metaMap, 'position', null);

    restrictions.payrollNumber = this.metaMapService.mapMeta<string>(metaMap, 'payrollNumber', null);
    restrictions.ssn = this.metaMapService.mapMeta<string>(metaMap, 'empssn', null);
    restrictions.hireDate = this.metaMapService.mapMeta<Date>(metaMap, 'hireDate', null);
    restrictions.birthDate = this.metaMapService.mapMeta<Date>(metaMap, 'empdob', null);
    restrictions.payPolicy = this.metaMapService.mapMeta<PayPolicy>(metaMap, 'payPolicy', null);
    restrictions.shiftDiffPolicy = this.metaMapService.mapMeta<ShiftDiffPolicy>(metaMap, 'shiftPolicy', null);
    restrictions.payType = this.metaMapService.mapMeta<PayType>(metaMap, 'payType', null);
    restrictions.exemptStatus = this.metaMapService.mapMeta<ExemptStatus>(metaMap, 'exemptStatus', null);
    restrictions.workWeekHours = this.metaMapService.mapMeta<number>(metaMap, 'workWeek', null);
    restrictions.dailyBenefitHours = this.metaMapService.mapMeta<number>(metaMap, 'dailyBenefitHours', null);
    restrictions.hourlyRate = this.metaMapService.mapMeta<number>(metaMap, 'hourlyRate', null);
    restrictions.hourlyRate2 = this.metaMapService.mapMeta<number>(metaMap, 'hourlyRate2', null);
    restrictions.hourlyRate3 = this.metaMapService.mapMeta<number>(metaMap, 'hourlyRate3', null);
    restrictions.payClass = this.metaMapService.mapMeta<string>(metaMap, 'payClass', null);
    restrictions.companyCode = this.metaMapService.mapMeta<string>(metaMap, 'companyCode', null);
    restrictions.fileNumber = this.metaMapService.mapMeta<string>(metaMap, 'fileNumber', null);
    restrictions.pbjId = this.metaMapService.mapMeta<string>(metaMap, 'pbjId', null);

    restrictions.address = this.metaMapService.mapMeta<string>(metaMap, 'empaddress', null);
    restrictions.city = this.metaMapService.mapMeta<string>(metaMap, 'empcity', null);
    restrictions.state = this.metaMapService.mapMeta<State>(metaMap, 'empstate', null);
    restrictions.zipCode = this.metaMapService.mapMeta<string>(metaMap, 'empzip', null);
    restrictions.phone = this.metaMapService.mapMeta<string>(metaMap, 'empphone', null);
    restrictions.mobilePhone = this.metaMapService.mapMeta<string>(metaMap, 'mobilePhone', null);
    restrictions.altPhone = this.metaMapService.mapMeta<string>(metaMap, 'altPhone', null);
    restrictions.email1 = this.metaMapService.mapMeta<string>(metaMap, 'email1', null);
    restrictions.email2 = this.metaMapService.mapMeta<string>(metaMap, 'email2', null);
    restrictions.gender = this.metaMapService.mapMeta<Gender>(metaMap, 'empgender', null);
    restrictions.maritalStatus = this.metaMapService.mapMeta<MaritalStatus>(metaMap, 'maritalStatus', null);
    restrictions.race = this.metaMapService.mapMeta<Race>(metaMap, 'emprace', null);

    restrictions.badgeId = this.metaMapService.mapMeta<string>(metaMap, 'badgeId', null);
    restrictions.essEnabledInd = this.metaMapService.mapMeta<boolean>(metaMap, 'essEnabledInd', null);
    if (restrictions.essEnabledInd.defaultFieldValue === '1' || restrictions.essEnabledInd.defaultFieldValue === 1) {
      restrictions.essEnabledInd.defaultFieldValue = true;
    } else {
      restrictions.essEnabledInd.defaultFieldValue = false;
    }
    restrictions.timeclocksEnabledInd = this.metaMapService.mapMeta<boolean>(metaMap, 'timeclocksEnabledInd', null);
    if (restrictions.timeclocksEnabledInd.defaultFieldValue === '1' || restrictions.timeclocksEnabledInd.defaultFieldValue === 1) {
      restrictions.timeclocksEnabledInd.defaultFieldValue = true;
    } else {
      restrictions.timeclocksEnabledInd.defaultFieldValue = false;
    }

    restrictions.benefitClass = this.metaMapService.mapMeta<BenefitClassDefinition>(metaMap, 'benefitClass', null);
    restrictions.benefitClassEffectiveDate = this.metaMapService.mapMeta<Date>(metaMap, 'benefitClassEffectiveDate', null);

    return restrictions;
  }
}
