/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./weather-location.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./weather-location.component";
var styles_WeatherLocationComponent = [i0.styles];
var RenderType_WeatherLocationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WeatherLocationComponent, data: {} });
export { RenderType_WeatherLocationComponent as RenderType_WeatherLocationComponent };
export function View_WeatherLocationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "title-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Today's weather in "])), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.place; _ck(_v, 2, 0, currVal_0); }); }
export function View_WeatherLocationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "weather-location", [], null, null, null, View_WeatherLocationComponent_0, RenderType_WeatherLocationComponent)), i1.ɵdid(1, 49152, null, 0, i2.WeatherLocationComponent, [], null, null)], null, null); }
var WeatherLocationComponentNgFactory = i1.ɵccf("weather-location", i2.WeatherLocationComponent, View_WeatherLocationComponent_Host_0, { place: "place" }, {}, []);
export { WeatherLocationComponentNgFactory as WeatherLocationComponentNgFactory };
