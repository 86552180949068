import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { appCommonRoutes, employeeSectionRoutes } from './portal.common.routes';
import {
  ApplicationContainerComponent
} from './components/index';
import { OrgLevelResolver } from './services/index';

export const globalRoutes: Routes = [
  {
    path: '',
    component: ApplicationContainerComponent,
    children:  [
      ...appCommonRoutes

    ]
  },
  ...employeeSectionRoutes
];
