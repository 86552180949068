import * as tslib_1 from "tslib";
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
var SlxPbjRecNumberPipe = /** @class */ (function (_super) {
    tslib_1.__extends(SlxPbjRecNumberPipe, _super);
    function SlxPbjRecNumberPipe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SlxPbjRecNumberPipe.prototype.transform = function (val) {
        var value = +val;
        var result = '';
        if (!isNaN(value)) {
            result = _super.prototype.transform.call(this, value, '1.2-2');
        }
        return result;
    };
    return SlxPbjRecNumberPipe;
}(DecimalPipe));
export { SlxPbjRecNumberPipe };
