export class PartnerData {
    public  id: string;
    public  name: string ;
    public  description: string ;
}

export class IPartnerData {
    public  id: string;
    public  name: string ;
    public  description: string ;
}