
import { IFieldData, FieldData } from '../../../../core/models/index';
import {
  EmpType,
  Department,
  Organization,
  Position,
  PayPolicy,
  ShiftDiffPolicy,
  PayType,
  ExemptStatus,
  State,
  MaritalStatus,
  Gender,
  Race,
  BenefitClassDefinition
} from '../../../../organization/models/lookup/index';

export interface ISectionAddEmployeeRestrictions {
  sections: IAddEmployeeRestrictions[];
}
export interface IAddEmployeeRestrictions {
  fields: IFieldData[];
}

export class AddEmployeeRestrictions {
  public firstName: FieldData<string>;
  public lastName: FieldData<string>;
  public middleName: FieldData<string>;
  public suffix: FieldData<string>;
  public employeeType: FieldData<EmpType>;
  public organization: FieldData<Organization>;
  public department: FieldData<Department>;
  public position: FieldData<Position>;

  public payrollNumber: FieldData<string>;
  public ssn: FieldData<string>;
  public hireDate: FieldData<Date>;
  public birthDate: FieldData<Date>;
  public payPolicy: FieldData<PayPolicy>;
  public shiftDiffPolicy: FieldData<ShiftDiffPolicy>;
  public payType: FieldData<PayType>;
  public exemptStatus: FieldData<ExemptStatus>;
  public workWeekHours: FieldData<number>;
  public dailyBenefitHours: FieldData<number>;
  public hourlyRate: FieldData<number>;
  public hourlyRate2: FieldData<number>;
  public hourlyRate3: FieldData<number>;
  public payClass: FieldData<string>;
  public companyCode: FieldData<string>;
  public fileNumber: FieldData<string>;
  public pbjId: FieldData<string>;

  public address: FieldData<string>;
  public city: FieldData<string>;
  public state: FieldData<State>;
  public zipCode: FieldData<string>;
  public phone: FieldData<string>;
  public mobilePhone: FieldData<string>;
  public altPhone: FieldData<string>;
  public email1: FieldData<string>;
  public email2: FieldData<string>;
  public gender: FieldData<Gender>;
  public maritalStatus: FieldData<MaritalStatus>;
  public race: FieldData<Race>;

  public badgeId: FieldData<string>;
  public essEnabledInd: FieldData<boolean>;
  public timeclocksEnabledInd: FieldData<boolean>;
  public accessType: FieldData<boolean>;
  public timeCard: FieldData<boolean>;
  public schedule: FieldData<boolean>;
  public availability: FieldData<boolean>;
  public password: FieldData<string>;

  public benefitClass: FieldData<BenefitClassDefinition>;
  public benefitClassEffectiveDate: FieldData<Date>;
}
