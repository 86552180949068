import * as _ from 'lodash';
import { Component, Provider } from '@angular/core';

import { DialogOptions, IDialog } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { GeolocationDialogOptions } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-geolocation-dialog',
  templateUrl: 'geolocation-dialog.component.html',
  styleUrls: ['geolocation-dialog.component.scss']
})
export class GeolocationDialogComponent implements IDialog {
  public geolocationData: GeolocationDialogOptions;
  public dialogResult: boolean;
  private options: DialogOptions;
  private modalService: ModalService;

  public static openDialog(data: GeolocationDialogOptions, modalService: ModalService): GeolocationDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 670;
    dialogOptions.height = 600;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: GeolocationDialogOptions,
        useValue: data
      }
    ];
    return modalService.globalAnchor.openDialog(
      GeolocationDialogComponent,
      'Map',
      dialogOptions,
      resolvedProviders
    );
  }

  constructor(
    geolocationData: GeolocationDialogOptions,
    options: DialogOptions,
    modalService: ModalService
  ) {
    this.geolocationData = geolocationData;
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
