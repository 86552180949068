import { EmployeeSectionsBase } from '../employee-sections-base';
import { LoaRequest, ILoaRequest } from '../../../../app-modules/leave-management/models/index';

export interface IEmployeeLeaveManagement {
  records: ILoaRequest[];
}

export class EmployeeLeaveManagement extends EmployeeSectionsBase {
  
  public canAddEdit: boolean;
  public canDelete: boolean;

  public records: LoaRequest[];

  constructor() {
    super();
    this.records = [];
  }
}
