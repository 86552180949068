export interface IPunchAttestationConfig {
  api: {
    root: string;
    punchAttestation: {
      root: string;
      orgLevel: {
        root: string;
      }
    }
  };
}

export const punchAttestationConfig: IPunchAttestationConfig = {
  api: {
    root: 'ta',
    punchAttestation: {
      root: 'punchAttestation',
      orgLevel: {
        root: 'orgLevel'
      }
    }
  }
};
