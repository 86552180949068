import * as tslib_1 from "tslib";
import { PropertiesPluginIdentifier, Util } from '@microsoft/applicationinsights-common';
import { BaseTelemetryPlugin, CoreUtils, _InternalMessageId, LoggingSeverity, getLocation } from '@microsoft/applicationinsights-core-js';
import { NavigationEnd } from '@angular/router';
var AngularPlugin = /** @class */ (function (_super) {
    tslib_1.__extends(AngularPlugin, _super);
    function AngularPlugin() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.priority = 186;
        _this.identifier = 'AngularPlugin';
        return _this;
    }
    AngularPlugin.prototype.initialize = function (config, core, extensions, pluginChain) {
        var _this = this;
        _super.prototype.initialize.call(this, config, core, extensions, pluginChain);
        var ctx = this._getTelCtx();
        var extConfig = ctx.getExtCfg(this.identifier, { router: null });
        CoreUtils.arrForEach(extensions, function (ext) {
            var identifier = ext.identifier;
            if (identifier === 'ApplicationInsightsAnalytics') {
                _this.analyticsPlugin = ext;
            }
            if (identifier === PropertiesPluginIdentifier) {
                _this.propertiesPlugin = ext;
            }
        });
        if (extConfig.router) {
            this.isPageInitialLoad = true;
            if (this.isPageInitialLoad) {
                var pageViewTelemetry = {
                    uri: extConfig.router.url
                };
                this.trackPageView(pageViewTelemetry);
            }
            extConfig.router.events.subscribe(function (event) {
                _this.onNavigation(event, extConfig);
            });
        }
    };
    AngularPlugin.prototype.onNavigation = function (event, extConfig) {
        if (event instanceof NavigationEnd) {
            // for page initial load, do not call trackPageView twice
            if (this.isPageInitialLoad) {
                this.isPageInitialLoad = false;
                return;
            }
            var pageViewTelemetry = { uri: extConfig.router.url };
            this.trackPageView(pageViewTelemetry);
        }
    };
    /**
     * Add Part A fields to the event
     * @param event The event that needs to be processed
     */
    AngularPlugin.prototype.processTelemetry = function (event, itemCtx) {
        this.processNext(event, itemCtx);
    };
    AngularPlugin.prototype.trackPageView = function (pageView) {
        if (this.analyticsPlugin) {
            var location_1 = getLocation();
            if (this.propertiesPlugin && this.propertiesPlugin.context && this.propertiesPlugin.context.telemetryTrace) {
                this.propertiesPlugin.context.telemetryTrace.traceID = Util.generateW3CId();
                this.propertiesPlugin.context.telemetryTrace.name = location_1 && location_1.pathname || '_unknown_';
            }
            this.analyticsPlugin.trackPageView(pageView);
        }
        else {
            this.diagLog().throwInternal(
            // tslint:disable-next-line:max-line-length
            LoggingSeverity.CRITICAL, _InternalMessageId.TelemetryInitializerFailed, 'Analytics plugin is not available, Angular plugin telemetry will not be sent: ');
        }
    };
    return AngularPlugin;
}(BaseTelemetryPlugin));
export { AngularPlugin };
