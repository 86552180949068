import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsACA = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsACA, _super);
    function EmployeeSectionsACA() {
        var _this = _super.call(this) || this;
        _this.records = [];
        return _this;
    }
    return EmployeeSectionsACA;
}(EmployeeSectionsBase));
export { EmployeeSectionsACA };
var EmployeeACA = /** @class */ (function () {
    function EmployeeACA() {
    }
    return EmployeeACA;
}());
export { EmployeeACA };
var EmployeeAcaDto = /** @class */ (function () {
    function EmployeeAcaDto() {
    }
    return EmployeeAcaDto;
}());
export { EmployeeAcaDto };
