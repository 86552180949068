import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import { PositionModel, EditableListActionKind } from '../../../../models/index';
import { CustomListActorBase } from '../../../editableList/custom-list-actor.base';
var ConfigureCopyPositionRendererComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConfigureCopyPositionRendererComponent, _super);
    function ConfigureCopyPositionRendererComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ConfigureCopyPositionRendererComponent.prototype, "context", {
        set: function (value) {
            this.item = value.item;
            this.rowIndex = value.rowIndex;
        },
        enumerable: true,
        configurable: true
    });
    ConfigureCopyPositionRendererComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    ConfigureCopyPositionRendererComponent.prototype.onItemSelect = function () {
        this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
    };
    return ConfigureCopyPositionRendererComponent;
}(CustomListActorBase));
export { ConfigureCopyPositionRendererComponent };
