<slx-spinner [show]="state.isLoading">
  <slx-generic-grid [genericGridConfig]="genericGridConfig">
    <form class="attendance-points-header" novalidate>
      <slx-date-range-ngx
        name="startEndDate"
        [startDate]="startDate"
        [endDate]="endDate"
        (rangeDateChanged)="onFilterDateChanged($event)"
      ></slx-date-range-ngx>
      <div class="attendance-points-dropdown col-lg-2">
        <slx-actions-list>
          <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">
            Actions <span class="caret"></span>
          </slx-actions-button>
          <popper-content #popperContent>
            <slx-actions-list-item (onClick)="onExportToExcel()">Export to Excel</slx-actions-list-item>
            <slx-actions-list-item (onClick)="onExportToPdf()">Export to Pdf</slx-actions-list-item>
          </popper-content>
        </slx-actions-list>
      </div>
    </form>
  </slx-generic-grid>
</slx-spinner>
