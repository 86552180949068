import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { FormControl, AbstractControl, NgControl } from '@angular/forms';
import { Assert, StringUtils } from '../../framework/index';

@Pipe({
  name: 'pending',
  pure: false
})
@Injectable()
export class Pending implements PipeTransform {

  public transform(form: FormControl | NgControl, controlPath: string, errorType?: string): boolean {
    Assert.isNotNull(form, 'form');

    if (form instanceof NgControl) {
      return form.pending;
    }

    if (StringUtils.isNullOrEmpty(controlPath)) {
      return false;
    }

    let control: AbstractControl = form.get(controlPath);
    if (!control) {
      return false;
    }
    return control.pending;
  }
}
