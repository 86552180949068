import { OrgLevel } from '../../../../state-model/models/index';
import { PunchAttestationManagementService } from '../../services/index';
var PunchAttestationHeaderComponent = /** @class */ (function () {
    function PunchAttestationHeaderComponent(managementService) {
        this.managementService = managementService;
    }
    PunchAttestationHeaderComponent.prototype.ngOnInit = function () {
    };
    PunchAttestationHeaderComponent.prototype.addGroup = function () {
        if (this.isGroupNameValid) {
            this.managementService.addGroups();
        }
    };
    PunchAttestationHeaderComponent.prototype.addLegacyGroup = function () {
        if (!this.isLeagacyGroupExists && this.isLegacy && this.isGroupNameValid) {
            this.managementService.addLegacyGroup();
        }
    };
    Object.defineProperty(PunchAttestationHeaderComponent.prototype, "isLegacy", {
        get: function () {
            return this.managementService.isLegacy$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchAttestationHeaderComponent.prototype, "isConfigure", {
        get: function () {
            return this.managementService.configurePunchAttestation.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchAttestationHeaderComponent.prototype, "isGroupNameValid", {
        get: function () {
            return this.managementService.isGroupNameValid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchAttestationHeaderComponent.prototype, "isLeagacyGroupExists", {
        get: function () {
            return this.managementService.isLegacyGroupExists;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchAttestationHeaderComponent.prototype, "disableSalteButton", {
        get: function () {
            return !this.isGroupNameValid || !this.isConfigure;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchAttestationHeaderComponent.prototype, "disableLegacyButton", {
        get: function () {
            return this.isLeagacyGroupExists || !this.isGroupNameValid || !this.isConfigure;
        },
        enumerable: true,
        configurable: true
    });
    return PunchAttestationHeaderComponent;
}());
export { PunchAttestationHeaderComponent };
