import { Injectable } from '@angular/core';
import { EmployeeSection, EmployeeSubSection, IEmployeeWorkplace, EmployeeWorkplace } from '../models/index';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';
import { employeeSectionsConfig } from '../employee-sections.config';

export interface IEmployeeSubSection {
  id: string;
  displayName: string;
  dataExists: boolean;
}
export interface IEmployeeSection {
  id: string;
  displayName: string;
  subsections: IEmployeeSubSection[];
}

@Injectable()
export class EmployeeSectionMapService {

  public mapToSection(data: IEmployeeSection): EmployeeSection {
    Assert.isNotNull(data, 'data IEmployeeSection');
    let section: EmployeeSection = new EmployeeSection();
    section.id = data.id;
    section.displayName = data.displayName;
    section.subsections = [];
    _.each(data.subsections, (sub: IEmployeeSubSection) => {
      let subsection: EmployeeSubSection = this.mapToSubSection(sub);
      section.subsections.push(subsection);
    });
    return section;
  }

  public mapToSubSection(data: IEmployeeSubSection): EmployeeSubSection {
    Assert.isNotNull(data, 'data IEmployeeSubSection');
    let subsection: EmployeeSubSection = new EmployeeSubSection();
    subsection.id = this.renameId(data.id);
    subsection.displayName = data.displayName;
    subsection.dataExists = data.dataExists;
    if (employeeSectionsConfig.nonEditableSubsections.hasOwnProperty(subsection.id)) {
      subsection.isEditableByConfiguration = false;
    }
    if (employeeSectionsConfig.editableSubsectionsWhenEmpTerminated.has(data.id)) {
      subsection.isEditableWhenEmpTerminated = true;
    }
    if (employeeSectionsConfig.nonEditableSubsectionsWhenEmpFutureRehire.has(data.id)) {
      subsection.isNotEditableWhenEmpFutureRehired = true;
    }
    return subsection;
  }

  public mapToSections(data?: IEmployeeSection[]): EmployeeSection[] {
    let sections: EmployeeSection[] = [];
    if (!data) return sections;
    _.each(data, (secDto: IEmployeeSection) => {
      let section: EmployeeSection = this.mapToSection(secDto);
      sections.push(section);
    });
    return sections;
  }

  public mapToWorkplace(data: IEmployeeWorkplace): EmployeeWorkplace {
    Assert.isNotNull(data, 'data IEmployeeWorkplace');
    let workplace: EmployeeWorkplace = new EmployeeWorkplace();
    workplace.ignoreLockedPayrollCycles = data.ignoreLockedPayrollCycles;
    return workplace;
  }

  private renameId(id: string): string {
    switch(id) {
      case 'ContactsInfo':
        return 'ContactInfo';
      case 'SelfService':
        return 'SmartLinxGo';
    }
    return id;
  }
}

