import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody, Meta, FieldsMeta, DateRange } from '../../../../core/models/index';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeSectionsAccrualsMapService } from './employee-section-accruals-map.service';
import {
  EmployeeSectionsAccruals,
  IEmployeeSectionsAccruals,
  EmployeeSectionsPolicyAssignments,
  IEmployeeSectionsPolicyAssignments,
  EndingBalanceDialogOptions,
  IEndingBalanceDialogOptions,
  IAccrualPolicy,
  AccrualPolicy,
  DynamicAccrualPolicies,
  IDynamicAccrualPolicies,
  EmployeePolicyAssignment,
  IEmployeeSectionAccrualTransactions,
  EmployeeSectionAccrualTransactions,
  AccrualTypes,
  IAccrualTypes,
  ResetBalance,
  EmployeeSectionAccrualsBalances,
  IEmployeeSectionAccrualsBalances,
} from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { CacheType } from '../../../../common/models/cache/cache-definition';

@Injectable()
export class EmployeeSectionsAccrualsApiService {

  constructor(
    private employeeSectionsAccrualsMapService: EmployeeSectionsAccrualsMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService
  ) { }

  public getAccrualsSection(employeeId: number, startDate: Date, endDate: Date): Promise<EmployeeSectionsAccruals> {
    Assert.isNotNull(employeeId, 'employeeId');
    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });
    const url: string = `${this.getAccrualsApiRoot(employeeId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });
    let promise: Promise<EmployeeSectionsAccruals> = this.apiUtilService.request<IEmployeeSectionsAccruals, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsAccruals, Meta>) =>
        this.employeeSectionsAccrualsMapService.mapToSectionsAccruals(response.data)
      );
    return promise;
  }

  public getAccrualsPolicyAssignments(employeeId: number): Promise<EmployeeSectionsPolicyAssignments> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.policyAssignment}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsPolicyAssignments> = this.apiUtilService.request<IEmployeeSectionsPolicyAssignments, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsPolicyAssignments, FieldsMeta>) =>
        this.employeeSectionsAccrualsMapService.mapToPolicyAssignments(response.data, response.meta)
      );
    return promise;
  }

  public postAccrualsPolicyAssignments(employeeId: number, policyAssignments: EmployeePolicyAssignment): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(policyAssignments, 'policyAssignments');

    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.policy.root}/${employeeSectionsConfig.api.employees.sections.accruals.policy.assign}`;
    let body: any = this.employeeSectionsAccrualsMapService.mapToPolicyAssignmentDto(policyAssignments);
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.employeeSectionsAccrualsMapService.mapToPolicyAssignments(response.data, response.meta)
      );
    return promise;
  }

  public getAccrualsPolicyBalance(employeeId: number, policyId): Promise<EndingBalanceDialogOptions> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(policyId, 'policyId');

    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.policyBalance}/${policyId}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EndingBalanceDialogOptions> = this.apiUtilService.request<IEndingBalanceDialogOptions, FieldsMeta>(request)
      .then((response: ResponseBody<IEndingBalanceDialogOptions, FieldsMeta>) =>
        this.employeeSectionsAccrualsMapService.mapToPolicyBalance(response.data, response.meta)
      );
    return promise;
  }

  public getAccrualsPolicyList(employeeId: number, effectiveDate: string): Promise<AccrualPolicy[]> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(effectiveDate, 'effectiveDate');

    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.policy.root}/${employeeSectionsConfig.api.employees.sections.accruals.policy.all}/${effectiveDate}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<AccrualPolicy[]> = this.apiUtilService
      .request<IAccrualPolicy[], Meta>(request)
      .then((response: ResponseBody<AccrualPolicy[], Meta>) => this.employeeSectionsAccrualsMapService.mapAccrualPolicy(response.data));
    return promise;
  }

  public getAllowDynamicAccrualPolicies(): Promise<boolean> {
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.app.root}/${employeeSectionsConfig.api.app.settings.root}/${employeeSectionsConfig.api.app.settings.accruals.root}/${employeeSectionsConfig.api.app.settings.accruals.allowDynamicAccrualPolicies}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<boolean> = this.apiUtilService
      .cachedRequest<IDynamicAccrualPolicies, FieldsMeta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IDynamicAccrualPolicies, FieldsMeta>) =>
        this.employeeSectionsAccrualsMapService.mapToDynamicPolicy(response.data, response.meta)
      );
    return promise;
  }

  public getAccrualTransactions(employeeId: number, startDate: Date, endDate: Date): Promise<EmployeeSectionAccrualTransactions> {
    Assert.isNotNull(employeeId, 'employeeId');
    let params: HttpParams = this.urlParamsService.convertToParams({
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });
    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.transactions}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });
    let promise: Promise<EmployeeSectionAccrualTransactions> = this.apiUtilService
      .request<IEmployeeSectionAccrualTransactions, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionAccrualTransactions, FieldsMeta>) =>
        this.employeeSectionsAccrualsMapService.mapToAccrualTransactions(response.data, response.meta)
      );
    return promise;
  }

  public getAccrualTypes(employeeId?: number, effectiveTDate?: Date): Promise<AccrualTypes[]> {
    let effectiveDate = dateTimeUtils.convertToDtoString(effectiveTDate);
    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.accrualTypes}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { effectiveDate });
    let promise: Promise<AccrualTypes[]> = this.apiUtilService.request<IAccrualTypes[], Meta>(request).
      then((response: ResponseBody<IAccrualTypes[], Meta>) =>
        this.employeeSectionsAccrualsMapService.mapAccrualTypes(response.data)
      );
    return promise;
  }

  public postAccrualTransactions(employeeId: number, transactions: EmployeeSectionAccrualTransactions, dateRange: DateRange): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(transactions, 'transactions');
    let postRecord: any = {
      records: this.employeeSectionsAccrualsMapService.mapToAccrualTransactionsRecordsDto(transactions.records),
      fetchFromDate: dateTimeUtils.convertToDtoString(dateRange.startDate),
      fetchUptoDate: dateTimeUtils.convertToDtoString(dateRange.endDate)
    }

    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.transactions}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, postRecord);
    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<number, FieldsMeta>) => response.status);
    return promise;
  }

  public postResetBalance(employeeId: number, resetBalances: ResetBalance): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(resetBalances, 'resetBalance');
    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.balance.root}/${employeeSectionsConfig.api.employees.sections.accruals.balance.reset}`;
    let body: any = this.employeeSectionsAccrualsMapService.mapToResetBalanceDto(resetBalances);
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<number, FieldsMeta>) => response.status
      );
    return promise;
  }

  public postAccrualsRecalculate(employeeId: number): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.calculate}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url);
    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request).
      then((response: ResponseBody<any, FieldsMeta>) => response
      );
    return promise;
  }

  public getEmployeeBalances(employeeId: number): Promise<EmployeeSectionAccrualsBalances> {
    Assert.isNotNull(employeeId, 'employeeId');
    const url: string = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.balances}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionAccrualsBalances> = this.apiUtilService.request<IEmployeeSectionAccrualsBalances, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionAccrualsBalances, FieldsMeta>) =>
        this.employeeSectionsAccrualsMapService.mapToAccrualsBalances(response.data, response.meta)
      );

    return promise;
  }

  public deletePolicyAssignment(employeeId: number, assignmentId: number): Promise<EmployeeSectionsPolicyAssignments> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(assignmentId, 'assignmentId');

    const url = `${this.getAccrualsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.policyAssignment}/${assignmentId}`;
    const request = this.urlParamsService.createDeleteRequest(url);

    const promise = this.apiUtilService
      .request<IEmployeeSectionsPolicyAssignments, FieldsMeta>(request)
      .then(response => this.employeeSectionsAccrualsMapService.mapToPolicyAssignments(response.data, response.meta));

    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.employees.root}`;
  }

  private getSectionApiRoot(employeeId: number): string {
    return `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.sections.root}`;
  }

  private getAccrualsApiRoot(employeeId: number) {
    return `${this.getSectionApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.accruals.root}`;
  }
}
