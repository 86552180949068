import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ScheduleRotationsManagementService } from './../../../../../app/scheduler/services/scheduled-rotation-template/schedule-rotations-management.service';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ScheduleCycle } from './../../../../../app/organization';
import { ScheduleRotationApiService } from './../../../../../app/scheduler/services/scheduled-rotation-template/scheduled-rotation-api.service';
import { ScheduledRotationMapService } from './../../../../../app/scheduler/services/scheduled-rotation-template/scheduled-rotation-map.service';
import { IDays, IScheduleDays, IScheduleRotationPermisions } from './../../../../scheduler/models/schedule-rotation/schedule-rotation-template.model';

@Component({
  selector: 'slx-scheduled-rotations-avail-shifts',
  templateUrl: './scheduled-rotations-avail-shifts.component.html',
  styleUrls: ['./scheduled-rotations-avail-shifts.component.scss']
})
export class ScheduledRotationsAvailShiftsComponent implements OnInit {
  public permissions: IScheduleRotationPermisions;
  public payCycleSubscription: Subscription;
  public loadDataSubscription: Subscription;
  @Input()
  public orgLevelId: number;
  @Input()
  public startDate: string;
  @Input()
  public endDate: string;
  public weekOfDays: IDays[] = [];
  public ShiftList: IScheduleDays[] = [];
  public isDraggable: boolean = true;

  constructor(public manService: ScheduleRotationsManagementService,
    public apiService: ScheduleRotationApiService,
    private mapService: ScheduledRotationMapService,
    private ref: ChangeDetectorRef) {
    this.payCycleSubscription = this.manService.payCycle.subscribe((dateRange: ScheduleCycle) => {
      this.startDate = moment(dateRange.startDate).format('MM/DD/YYYY');
      this.endDate = moment(dateRange.endDate).format('MM/DD/YYYY');
      const dates = this.manService.dates;
      this.setWeekOfDays(dates);
    });
    this.loadDataSubscription = this.manService.subscribeToLoadDataSubscription((data: any) => {
      const availableShifts = this.mapService.mapScheduleRotationAvailShiftsData(data[0], this.manService.currentWeek);
      this.ShiftList = availableShifts;
    });

    this.manService.availableShifts$.subscribe((shifts: IScheduleDays[]) => {
      this.ShiftList = shifts;
      this.manService.ShiftList = shifts;
      // this.ref.detectChanges();
    });

    this.manService.permissions$.subscribe((permissions) => {
      this.permissions = permissions;
    });

    this.manService.isDragabble$.subscribe((isDragabble: boolean) => {
      this.isDraggable = !isDragabble;
    });
  }

  ngOnInit() {

  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['orgLevelId'] || changes['startDate'] || changes['endDate']) {
      if (this.orgLevelId && this.startDate && this.endDate) {
        this.manService.getScheduleRotationAvailShifts(this.orgLevelId, this.startDate, this.endDate);
        this.manService.getNoRotationEmployeeList(this.orgLevelId, this.startDate, this.endDate);
      }
    }
  }

  public dateName(days: any) {
    return `${days.day}`;
  }

  get hasPermissions():boolean{
    return this.permissions ? this.permissions.modify : false;
  }

  drag(ev, shift) {
    shift.isView = true;
    this.manService.draggedShift = shift;
    ev.dataTransfer.setData('shift', JSON.stringify(shift));
  }

  public setWeekOfDays(dates: IDays[]) {
    const weekDays = _.cloneDeep(dates);
    this.weekOfDays = weekDays.splice(0, 7);
  }

  public getExpandChange(e) {
    this.manService.isExpand$.next(e.expanded);
  }
}
