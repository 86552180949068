import { Component, OnInit, OnDestroy, Input, Optional, Inject, Provider, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

import { select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/filter';

import * as moment from 'moment';
import * as _ from 'lodash';
import { mutableSelect } from '../../../core/decorators/index';

import { EmployeeDefinition } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';

import { EmployeeSectionNavigationService } from '../../../employee/employee-sections/services/index';
import { ShiftEligibleEmployee, ShiftReplacementRequest, ShiftOpenCmd } from '../../models/index';
import { employeeListConfig } from '../../../employee/employee-list/employee-list.config';
import { ModalBehavior } from '../../../common/models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { ShiftReplacementOpenComponent } from '../shift-replacement/shift-replacement-open/shift-replacement-open.component';
import { DetailScreenService, DetailScreenMapService, ShiftReplacementApiService } from '../../services/index';
import { Details, Filter, Group, DetailRow, DetailColumn, DetailField, DetailGroup, DetailGrouping } from '../../models/index';
import { appConfig } from '../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-detail-screen',
  templateUrl: 'detail-screen.component.html',
  styleUrls: ['detail-screen.component.scss']
})
export class DetailScreenComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @Input()
  public dateOn: Date;

  @Input()
  public filters: Filter[];

  @Input()
  public groups: Group[];

  public details: Details;

  public state: {
    isLoading: boolean;
  };
  public restrictedInfo: string[];

  private filtersAndGroupingChange$: Subject<any>;
  private detailScreenService: DetailScreenService;
  private detailScreenMapService: DetailScreenMapService;
  private shiftReplacementApiService: ShiftReplacementApiService;
  private detailsSubscription: Subscription;
  private dateSubscription: Subscription;
  private modalService: ModalService;
  private activatedRoute: ActivatedRoute;
  private router: Router;

  constructor(
    detailScreenService: DetailScreenService, detailScreenMapService: DetailScreenMapService, modalService: ModalService,
    shiftReplacementApiService: ShiftReplacementApiService,
    activatedRoute: ActivatedRoute, router: Router, @Inject('dateOn') @Optional() dateOn: Date,
    @Inject('filters') @Optional() filters: Filter[], @Inject('groups') @Optional() groups: Group[]) {
    this.detailScreenService = detailScreenService;
    this.detailScreenMapService = detailScreenMapService;
    this.shiftReplacementApiService = shiftReplacementApiService;
    this.modalService = modalService;
    this.activatedRoute = activatedRoute;
    this.router = router;
    this.restrictedInfo = ['Position', 'Shift', 'Unit'];
    this.dateOn = dateOn || new Date();
    this.filters = filters || [];
    this.groups = groups || [{
      fieldName: 'Position'
    }, {
      fieldName: 'Shift'
    }, {
      fieldName: 'Unit'
    }];

    this.state = {
      isLoading: false
    };

    this.filtersAndGroupingChange$ = new Subject<any>();
  }

  public ngOnInit(): void {
    this.state.isLoading = true;
    let dateOnEvent$: Observable<Date> = this.activatedRoute.params
      .map(({ date: dateOn }: Params) => {
        if (!dateOn) {
          this.changeDateOn(new Date());
        }
        return moment(dateOn, appConfig.linkDateFormat).toDate();
      });

    this.dateSubscription = dateOnEvent$.subscribe((dateOn: Date) => {
      this.dateOn = dateOn;
    });

    let orgLevelChangeEvent$: Observable<OrgLevel> = this.orgLevel$
      .filter((selectedOrgLevel: OrgLevel) => selectedOrgLevel.type === OrgLevelType.department);

    let detailsEvent$: Observable<[OrgLevel, Date, any]> = Observable.combineLatest(orgLevelChangeEvent$, dateOnEvent$, this.filtersAndGroupingChange$)
      .do(() => this.state.isLoading = true);

    this.detailsSubscription = detailsEvent$.subscribe(([selectedOrgLevel, dateOn]: [OrgLevel, Date, any]) => {
      this.loadDetails(selectedOrgLevel, dateOn)
        .then((d: Details) => this.details = d)
        .catch((error: any) => console.log(error))
        .then(() => this.state.isLoading = false);
    });

    this.filtersAndGroupingChange$.next();
  }

  public loadDetails(orgLevel: OrgLevel, dateOn: Date): Promise<Details> {
    let positionFilter: Filter = _.find(this.filters, (filter: Filter) => filter.fieldName === 'Position') || new Filter();
    let shiftFilter: Filter = _.find(this.filters, (filter: Filter) => filter.fieldName === 'Shift') || new Filter();
    let unitFilter: Filter = _.find(this.filters, (filter: Filter) => filter.fieldName === 'Unit') || new Filter();
    let groupings: string[] = this.groups.map((group: Group) => group.fieldName);
    return this.detailScreenService.getDetails(orgLevel, dateOn, positionFilter.value, shiftFilter.value, unitFilter.value, groupings);
  }

  public ngOnDestroy(): void {
    this.detailsSubscription.unsubscribe();
    this.dateSubscription.unsubscribe();
  }

  public getFieldValue(row: DetailRow, column: DetailColumn): string {
    let field: DetailField = _.find(row.fields, (f: DetailField) => f.name === column.name);
    return field ? field.value : '';
  }

  public filtersChanged(filters: Filter[]): void {
    this.filters = filters;
    this.filtersAndGroupingChange$.next(filters);
  }

  public groupsChanged(groups: Group[]): void {
    this.groups = groups;
    this.filtersAndGroupingChange$.next(groups);
  }

  public employeeOpen(row: DetailRow): void {
    let empId: string = _.find(row.fields, (field: DetailField) => field.name === employeeListConfig.employeeIdentifierName).value;
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
    navService.NavigateToEmployeeSections(+empId);
  }

  public onOpenShift(group: DetailGroup): void {
    let shiftIdGrouping: DetailGrouping = _.find(group.grouping, (grouping: DetailGrouping) => grouping.name === 'ShiftId');
    let positionIdGrouping: DetailGrouping = _.find(group.grouping, (grouping: DetailGrouping) => grouping.name === 'PositionId');
    let unitIdGrouping: DetailGrouping = _.find(group.grouping, (grouping: DetailGrouping) => grouping.name === 'UnitId');

    if (!shiftIdGrouping || !positionIdGrouping || !unitIdGrouping) {
      return;
    }

    let request: ShiftReplacementRequest = new ShiftReplacementRequest();
    request.shiftId = +shiftIdGrouping.value;
    request.positionId = +positionIdGrouping.value;
    request.date = new Date();
    request.showDayOffEmployees = true;

    let replacementComponent: ShiftReplacementOpenComponent = ShiftReplacementOpenComponent.openDialog(request, +unitIdGrouping.value, this.modalService, (result: boolean, cmd: ShiftOpenCmd) => {
      if (result && cmd) {
        this.shiftReplacementApiService.openShift(cmd)
          .then((status: number) => {
            let employees: ShiftEligibleEmployee[] = replacementComponent.selectedEmployees;
            _.forEach(employees, (employee: ShiftEligibleEmployee) => {
              group.rows.push(this.detailScreenMapService.mapShiftEligibleEmployeeToDetailRow(employee));
            });
          });
      }
    });
  }

  public changeDateOn(dateOn: Date): void {
    this.router.navigate([
      'details_screen',
      moment(dateOn).format(appConfig.linkDateFormat)], {
        relativeTo: this.activatedRoute.parent
      });
  }
}
