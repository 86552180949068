export interface IEmployeeTerminationReason {
  id: number;
  reason: string;
  isVoluntary: boolean;
}

export class EmployeeTerminationReason {
  public id: number;
  public reason: string;
  public isVoluntary: boolean;
}
