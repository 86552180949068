<span class="fav-subtitle">
  <span class="fav-label">Reorder</span>
  <span class="fav-label">Show/Hide</span>
</span>
<span class="flex flex-direction__column groups__grid-positions" (mouseup)="scrollToPosition()">
  <kendo-sortable
    [(kendoSortableBinding)]="favorites"
    [animation]="true"
    itemClass="fav-item"
    emptyItemClass="fav-name"
    zone="favorites"
    [disabledIndexes]="canDrag ? [] : disIndexes"
    emptyText=""
  >
    <ng-template let-favorite="item">
      <span class="fav-item" id="favitem">
        <i aria-hidden="true" [class]="'icon fas ' + favorite.menuItemMapping?.icon" ></i>
        <div class="fav-name" [title]="favorite.displayName">{{ favorite.displayName }}</div>
        <i aria-hidden="true" class="fav-icon-pointer far fa-bars" (mousedown)="canDrag = true" (touchend)="canDrag = false" (touchstart)="canDrag = true" ></i>
        <i aria-hidden="true" class="fav-icon-pointer star-icon fas fa-star selected" (click)="onStarClicked(favorite)"></i>
      </span>
    </ng-template>
  </kendo-sortable>
</span>
