import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { OrgLevelType } from './../../../../state-model/models/index';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from './../../../../core/decorators/index';
import { OnInit } from '@angular/core';
var ExceptionConsolePageComponent = /** @class */ (function () {
    function ExceptionConsolePageComponent() {
    }
    ExceptionConsolePageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (value) {
            _this.orgLevel = value;
            _this.isDepartament = _this.orgLevel.type === OrgLevelType.department;
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ExceptionConsolePageComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsolePageComponent.prototype, "orgLevelSubscription", void 0);
    return ExceptionConsolePageComponent;
}());
export { ExceptionConsolePageComponent };
