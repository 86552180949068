
export * from './timecards-display/timecards-display.component';
export * from './timecards-display-grid/timecards-display-grid.component';
export * from './timecards-display-flat-grid/timecards-display-flat-grid.component';
export * from './timecards-display-detail-grid/timecards-display-detail-grid.component';
export * from './payroll-export-result-dialog/payroll-export-result-dialog.component';
export * from './timecard-queue-dialog/timecard-queue-dialog.component';

import { TimecardsDisplayComponent } from './timecards-display/timecards-display.component';
import { TimecardsDisplayGridComponent } from './timecards-display-grid/timecards-display-grid.component';
import { TimecardsDisplayFlatGridComponent } from './timecards-display-flat-grid/timecards-display-flat-grid.component';
import { TimecardsDisplayDetailGridComponent } from './timecards-display-detail-grid/timecards-display-detail-grid.component';
import { PayrollExportResultDialogComponent } from './payroll-export-result-dialog/payroll-export-result-dialog.component';
import { TimecardQueueDialogComponent } from './timecard-queue-dialog/timecard-queue-dialog.component';
import { PayCodesAdditionalDataComponent } from '../daily-timecard/pay-codes-additional-data/pay-codes-additional-data.component';

export const timecardsDisplayComponents: any = [
  TimecardsDisplayComponent,
  TimecardsDisplayGridComponent,
  TimecardsDisplayFlatGridComponent,
  TimecardsDisplayDetailGridComponent,
  PayrollExportResultDialogComponent,
  TimecardQueueDialogComponent
];

export const entryTimecardsDisplayComponents: any[] = [
  PayrollExportResultDialogComponent,
  TimecardQueueDialogComponent,
  PayCodesAdditionalDataComponent
];
