 
export interface IGetHiredLink {
  landing_uri: string;
  status: string;
  errorCode: string;
  errorMessage: string;
}

export class GetHiredLink {
  linkUrl: string;
  status: string;
  errorCode: number;
  errorMessage: string;
}
