<div class="modal-body">
  <div class="row">
      <label class="col-xs-6">End Date:</label>
      <div class="col-xs-6">
          <span>{{ benefitDetailsLineEndDate | amDateFormat: appConfig.planDateTimeFormat }}</span>
      </div>
  </div>
  <div class="row">
      <label class="col-xs-6">New End Date:</label>
      <div class="col-xs-6">
        <form #form="ngForm" novalidate>
          <slx-datepicker class="slx-padd-r benefit-provider__date"
              name="dateField"
              [minDate]="minValidDate"
              [maxDate]="maxValidDate"
              [(ngModel)]="benefitDetailsLineNewEndDate">
          </slx-datepicker>
        </form>
      </div>
  </div>
  <div class="row" *ngIf="form.valid">
      <div class="col-xs-12 comment">
          This will automatically drop coverage for enrolled employees and dependents effective at the new end date.
      </div>
  </div>
  <div class="row" *ngIf="form.invalid">
    <div class="col-xs-12 comment">
        Select a date within {{minValidDate | amDateFormat: appConfig.dateFormat }} - {{ maxValidDate  | amDateFormat: appConfig.dateFormat }} to not overlap with an existing timeline.
    </div>
  </div>
</div>

<div class="modal-footer">
      <button type="button" (click)="save()" class="btn green" [disabled]="form.invalid"><i class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save</button>
      <button type="button" class="btn red" (click)="cancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
</div>
