import * as _ from 'lodash';

import { Injectable } from '@angular/core';

import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { ResponseBody, Meta } from '../../../../core/models/index';

import { AcaReportEmployerStatus, IAcaReportEmployerStatus } from '../../models/index';
import { exportDataConfig } from '../../export-data.config';
import { appConfig } from '../../../../app.config';
import { AcaReportMapService } from './aca-report-map.service';

@Injectable()
export class AcaReportApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: AcaReportMapService
  ) {}

  public async getEmployerStatus(employerId: number, year: number): Promise<AcaReportEmployerStatus> {
    const url = `${this.getApiRoot()}/${exportDataConfig.api.aca1095.report}/${exportDataConfig.api.aca1095.employer.root}/${employerId}/${exportDataConfig.api.aca1095.employer.status}`;
    const request = this.urlParamsService.createGetRequest(url, { year });

    return this.apiUtilService.request<AcaReportEmployerStatus, Meta>(request)
      .then((response: ResponseBody<IAcaReportEmployerStatus, Meta>) => this.mapService.mapToAcaReportEmployerStatus(response.data));
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${exportDataConfig.api.aca1095.root}`;
  }
}
