<span class="day" *ngFor="let day of days; first as isFirst; last as isLast" title="{{ day | date: dateFormat }}">
  <span *ngIf="showNavButtons && isFirst" (click)="onNavButtonClick(false)" class="day__nav-button left">
    <i class="fas fa-angle-left" aria-hidden="true"></i>
  </span>
  <span *ngIf="isDayView" class="day__content">{{ day | date: dateFormat }}</span>
  <span *ngIf="!isDayView"  class="day__content header-full-date">{{ day | date: dateFormatShort }}</span>
  <span *ngIf="!isDayView" class="day__content header-short-date">
    {{ day | date: dateFormatForSmallDevice }}
  </span>
  <span *ngIf="showNavButtons && isLast" (click)="onNavButtonClick(true)" class="day__nav-button right">
    <i class="fas fa-angle-right" aria-hidden="true"></i>
  </span>
</span>