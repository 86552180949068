<slx-toolbar class="slx-ta-timeline-toolbar-container"  [alignExpandButtonRight]="true">

  <ng-template slxToolbarSectionTemplate width="170">
    <div class="slx-toolbar-section">
      <slx-multiselect class="slx-wide"
        slxControlInitState
        [restoreManually]="true"
        [options]="units"
        [externalPlaceholder]="true"
        [(ngModel)]="unitFilter"
        titleField="name"
        valueField="id"
        placeholder="All Units"
        externalPlaceholderText = "Units"
        name="units"
        (ngModelChange)="onUnitChanged()"
      ></slx-multiselect>
    </div>
  </ng-template>

  <ng-template slxToolbarSectionTemplate let-counts="counts" width="130">
    <div class="slx-toolbar-section">
        <slx-multiselect class="slx-wide"
        slxControlInitState [restoreManually]="true"
        [options]="variances"
        [externalPlaceholder]="true"
        [(ngModel)]="variancesFilter"
        placeholder="All"
        externalPlaceholderText = "Variances"
        name="variances"
        titleField="value"
        valueField="value"
        (ngModelChange)="onVariancesChanged()"
      ></slx-multiselect>
    </div>
  </ng-template>

  <ng-template slxToolbarSectionTemplate let-counts="counts" width="100">
     <div class="slx-toolbar-section">
        <div class="toggler-container">
          <slx-switcher
            slxControlInitState
            [restoreManually]="true"
            [(ngModel)]="isShowOverages"
            (ngModelChange)="onShowOveragesChanged($event)"
            label="Show Overages"
            labelOn="Yes"
            labelOff="No"
            name="isShowOverages"
          ></slx-switcher>
      </div>
    </div>
  </ng-template>

  <ng-template slxToolbarSectionTemplate let-counts="counts" width="100">
    <div class="slx-toolbar-section">
       <div class="toggler-container">
         <slx-switcher
            [(ngModel)]="isExpanded"
            (ngModelChange)="onModeChanged($event)"
            label="Expand timeline"
            labelOn="Yes"
            labelOff="No"
            name="isExpanded"
          ></slx-switcher>
     </div>
   </div>
  </ng-template>

  <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="right" width="150">
    <div class="slx-toolbar-section">
      <slx-multiselect slxControlInitState [restoreManually]="true" class="slx-wide"
        [options]="commonFilterItems"
        [(ngModel)]="appliedCommonFilterItems"
        placeholder="Filter..."
        name="empFilter"
        titleField="name"
        valueField="id"
        (ngModelChange)="onCommonFilterChanged($event)"
      ></slx-multiselect>
    </div>
  </ng-template>

  </slx-toolbar>
