import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var SlateMessagesManagementService = /** @class */ (function () {
    function SlateMessagesManagementService() {
        this.selectedMessage$ = new BehaviorSubject(null);
        this.isMessagesDataUpdated$ = new BehaviorSubject(null);
    }
    SlateMessagesManagementService.prototype.destroy = function () {
        this.selectedMessage$.complete();
        this.isMessagesDataUpdated$.complete();
    };
    SlateMessagesManagementService.prototype.updateSelectedmessage = function (message) {
        this.selectedMessage$.next(message);
    };
    SlateMessagesManagementService.prototype.updateMessagesData = function () {
        this.isMessagesDataUpdated$.next(true);
    };
    SlateMessagesManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SlateMessagesManagementService_Factory() { return new SlateMessagesManagementService(); }, token: SlateMessagesManagementService, providedIn: "root" });
    return SlateMessagesManagementService;
}());
export { SlateMessagesManagementService };
