import {
    EmployeeDefinition, IPosition, Position
} from '../../../../organization/models/index';

export class IEmployeeDetails {
  public employee: EmployeeDefinition;
  public position: IPosition;
  public scheduleStart: Date;
  public scheduleEnd: Date;
  public actualStart: Date;
  public actualEnd: Date;
}

export class EmployeeDetails {
    public employee: EmployeeDefinition;
    public position: Position;
    public scheduleStart: Date;
    public scheduleEnd: Date;
    public actualStart: Date;
    public actualEnd: Date;
}
