export interface IBenefitClass {
    id: number;
    orgLevelId: number;
    name: string;
    description: string;
    code: string;
    updatedOn: string;
    updatedBy: string;
}

export class BenefitClass {
    public id: number;
    public orgLevelId: number;
    public name: string;
    public description: string;
    public code: string;
    public updatedOn: Date;
    public updatedBy: string;
}
