<div class="slx-chart-widget-container">
  <div *ngIf="topDirective && !topDirective.isHidden">
    <ng-container *ngTemplateOutlet="topDirective.template"></ng-container>
  </div>

  <div *ngIf="middleDirective && !middleDirective.isHidden">
    <ng-container *ngTemplateOutlet="middleDirective.template"></ng-container>
  </div>
  
  <div *ngIf="useDefaultClickHanlder" class="slx-chart-widget-click-hanlder" (click)="defaultClickHandler ()"></div>

  <div *ngIf="bottomDirective && !bottomDirective.isHidden">
    <ng-container *ngTemplateOutlet="bottomDirective.template"></ng-container>
  </div>

</div>