import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { FieldsMeta, IFieldData, Actions } from '../../../core/models/index';
import { IAcaMeasurement, AcaMeasurement, IAcaMeasurementRecord, AcaMeasurementRecord, IAcaMeasurementInsights, AcaMeasurementInsights, IACAPeriodType, ACAPeriodType, IACAPeriod, ACAPeriod, AcaInsightPeriod, IAcaInsightPeriod, AcaInsightField, IAcaInsightField } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils';
import { Assert } from '../../../framework';
import * as moment from 'moment';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { AcaMeasurementActions } from '../../models/aca-measurement/aca-measurement-actions';
import { MetaMapService } from '../../../core/services';

@Injectable()
export class AcaMeasurementMapService {
  public appConfig: IApplicationConfig;

  constructor(private metaMapService: MetaMapService,
  ) {
    this.appConfig = appConfig;
  }

  public mapAcaMeasurement(dto: IAcaMeasurement, meta: FieldsMeta, year: number): AcaMeasurement {
    const data: AcaMeasurement = new AcaMeasurement();

    data.records = dto.records?_.map(dto.records, record => this.mapAcaMeasurementRecord(record, year)):null;
    data.actions = this.mapAcaActions(meta);
    data.insightSummary = dto.insightSummary ? this.mapAcaInsights(dto.insightSummary) : null;
    return data;
  }

  private mapAcaActions(meta: FieldsMeta): AcaMeasurementActions {
    let actions: AcaMeasurementActions = new AcaMeasurementActions();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    let action: Actions = new Actions();
    action = this.metaMapService.mapActions(meta);
    actions.empName = this.metaMapService.mapMeta<string>(metaMap, 'empName', 'empName').Policy.isVisible;
    actions.organizationName = this.metaMapService.mapMeta<string>(metaMap, 'empOrganization', 'empOrganization').Policy.isVisible;
    actions.departmentName = this.metaMapService.mapMeta<string>(metaMap, 'empDepartment', 'empDepartment').Policy.isVisible;
    actions.positionName = this.metaMapService.mapMeta<string>(metaMap, 'jobDescription', 'jobDescription').Policy.isVisible;
    actions.dateHired = this.metaMapService.mapMeta<string>(metaMap, 'empDtHire', 'empDtHire').Policy.isVisible;
    actions.dateTerm = this.metaMapService.mapMeta<string>(metaMap, 'empTerminationDate', 'empTerminationDate').Policy.isVisible;
    actions.empType = this.metaMapService.mapMeta<string>(metaMap, 'empType', 'empType').Policy.isVisible;
    actions.acaRecord = this.metaMapService.mapMeta<string>(metaMap, 'acaRecord', 'acaRecord').Policy.isVisible;
    actions.exportToExcel = action.canExportToExcel;

    return actions;
  }

  private mapAcaMeasurementRecord(dto: IAcaMeasurementRecord, year: number): AcaMeasurementRecord {
    const data = new AcaMeasurementRecord();
    data.empId = dto.empId;
    data.empName = dto.empName;
    data.empType = dto.empType;
    data.organizationName = dto.organizationName;
    data.departmentName = dto.departmentName;
    data.positionName = dto.positionName;
    data.dateHired = dto.dateHired ? moment(dto.dateHired).format(appConfig.dateFormat) : '';
    data.dateTerm = dto.dateTerminated ? moment(dto.dateTerminated).format(appConfig.dateFormat) : '';

    data.currentMP = dto.currentMP ? this.mapAcaInsightField(dto.currentMP) : null;
    data.currentAP = dto.currentAP ? this.mapAcaInsightField(dto.currentAP) : null;
    data.nextMP = dto.nextMP ? this.mapAcaInsightField(dto.nextMP) : null;
    data.nextAP = dto.nextAP ? this.mapAcaInsightField(dto.nextAP) : null;

    data.periodTypes = _.map(dto.periodTypes, item => this.mapAcaDetails(item, year));

    return data;
  }

  private mapAcaInsightField(dto: IAcaInsightField): AcaInsightField {
    let data: AcaInsightField = new AcaInsightField();
    data.isEnding = dto.isEnding;
    data.ptDeterminedEligible = dto.ptDeterminedEligible;
    data.ftDeterminedEligible = dto.ftDeterminedEligible;
    data.ftDeterminedIneligible = dto.ftDeterminedIneligible;

    return data;
  }

  private mapAcaDetails(dto: IACAPeriodType, year: number): ACAPeriodType {
    const data = new ACAPeriodType();
    data.periodType = dto.periodType;
    data.periodTypeFullName = dto.periodType ? (dto.periodType == 'IP' ? 'Initial Period' : 'Standard Period') : '';

    data.measurementPeriodStart = dto.measurementPeriodStart ? moment(dto.measurementPeriodStart).format(appConfig.dateFormat) : '';
    data.measurementPeriodEnd = dto.measurementPeriodEnd ? moment(dto.measurementPeriodEnd).format(appConfig.dateFormat) : '';
    data.administrativePeriodStart = dto.administrativePeriodStart ? moment(dto.administrativePeriodStart).format(appConfig.dateFormat) : '';
    data.administrativePeriodEnd = dto.administrativePeriodEnd ? moment(dto.administrativePeriodEnd).format(appConfig.dateFormat) : '';
    data.stabilityPeriodStart = dto.stabilityPeriodStart ? moment(dto.stabilityPeriodStart).format(appConfig.dateFormat) : '';
    data.stabilityPeriodEnd = dto.stabilityPeriodEnd ? moment(dto.stabilityPeriodEnd).format(appConfig.dateFormat) : '';

    data.acaDate = dto.acaDate ? moment(dto.acaDate).format(appConfig.dateFormat) : '';
    data.acaType = dto.acaType;
    data.acaExclude = dto.acaExclude;
    data.benefitDeclined = dto.declinedBenefit;
    data.purchasedMarketplace = dto.purchasedMarketplaceBenefit;
    data.benefitName = dto.benefitName;
    data.benefitStart = dto.benefitStartDate ? moment(dto.benefitStartDate).format(appConfig.dateFormat) : '';

    let mpSYear = moment(dateTimeUtils.convertFromDtoString(dto.measurementPeriodStart)).toDate().getFullYear();
    let mpEYear = moment(dateTimeUtils.convertFromDtoString(dto.measurementPeriodEnd)).toDate().getFullYear();
    let apSYear = moment(dateTimeUtils.convertFromDtoString(dto.administrativePeriodStart)).toDate().getFullYear();
    let apEYear = moment(dateTimeUtils.convertFromDtoString(dto.administrativePeriodEnd)).toDate().getFullYear();
    let spSYear = moment(dateTimeUtils.convertFromDtoString(dto.stabilityPeriodStart)).toDate().getFullYear();
    let spEYear = moment(dateTimeUtils.convertFromDtoString(dto.stabilityPeriodEnd)).toDate().getFullYear();

    data.isPrevYearExist = this.checkYearExist(year, mpSYear, mpEYear, apSYear, apEYear, spSYear, spEYear, true);
    data.isNextYearExist = this.checkYearExist(year, mpSYear, mpEYear, apSYear, apEYear, spSYear, spEYear, false);

    let tmpPeriods: ACAPeriod[] = [];
    if (dto.measurementPeriodStart && dto.measurementPeriodEnd && (year == mpSYear || year == mpEYear)) {
      tmpPeriods.push(this.mapAcaPeriods(year, 'Measurement Period', 'MP', dto.measurementPeriodStart, dto.measurementPeriodEnd, dto.weeklyAverage, dto.weeklyThreshold, dto.weeklyTotalHours));
    }
    if (dto.administrativePeriodStart && dto.administrativePeriodEnd && (year == apSYear || year == apEYear)) {
      tmpPeriods.push(this.mapAcaPeriods(year, 'Administrative Period', 'AP', dto.administrativePeriodStart, dto.administrativePeriodEnd, dto.weeklyAverage, dto.weeklyThreshold, dto.weeklyTotalHours));
    }
    if (dto.stabilityPeriodStart && dto.stabilityPeriodEnd && (year == spSYear || year == spEYear)) {
      tmpPeriods.push(this.mapAcaPeriods(year, 'Stability Period', 'SP', dto.stabilityPeriodStart, dto.stabilityPeriodEnd, dto.weeklyAverage, dto.weeklyThreshold, dto.weeklyTotalHours));
    }

    let periods: ACAPeriod[] = _.orderBy(tmpPeriods, ['startFrom', 'asc']);
    let firstDayYear = new Date(year + '-01-01');
    let lastDayYear = new Date(year + '-12-31');

    _.forEach(periods, function (item, index) {
      if (index == 0 && moment(item.startFrom).toDate().getFullYear() == year
        && moment(item.startFrom).format(appConfig.dateFormat) != moment(firstDayYear).format(appConfig.dateFormat)) {
        let period: ACAPeriod = new ACAPeriod();
        period.startFrom = firstDayYear;
        period.endTo = moment(item.startFrom).subtract(1, 'day').toDate();
        period.currentSelectedYear = year;
        period.periodSortName = '';
        period.periodName = '';
        tmpPeriods.push(period);
      }

      if (index > 0 && index < periods.length - 1) {
        if ((moment(periods[index - 1].endTo).diff(item.startFrom, 'days', true)) > 0) {
          let period: ACAPeriod = new ACAPeriod();
          period.startFrom = moment(periods[index - 1].endTo).add(1, 'day').toDate();
          period.endTo = moment(item.startFrom).subtract(1, 'day').toDate();
          period.currentSelectedYear = year;
          period.periodSortName = '';
          period.periodName = '';
          tmpPeriods.push(period);
        }
      }

      if (index == periods.length - 1
        && moment(item.endTo).toDate().getFullYear() == year
        && moment(item.endTo).format(appConfig.dateFormat) != moment(lastDayYear).format(appConfig.dateFormat)) {

        let period: ACAPeriod = new ACAPeriod();
        period.startFrom = moment(item.endTo).add(1, 'day').toDate();
        period.endTo = lastDayYear;
        period.currentSelectedYear = year;
        period.periodSortName = '';
        period.periodName = '';
        tmpPeriods.push(period);
      }
    });

    data.acaPeriod = _.orderBy(tmpPeriods, ['startFrom', 'asc']);
    return data;
  }

  private checkYearExist(currentYear: number, mpSYear: number, mpEYear: number, apSYear: number, apEYear: number, spSYear: number, spEYear: number, isPrevYear: boolean): boolean {
    let isExist: boolean = false;
    if (isPrevYear) {
      if (((currentYear == mpSYear || currentYear == mpEYear) && currentYear > mpSYear) ||
          ((currentYear == apSYear || currentYear == apEYear) && currentYear > apSYear) ||
          ((currentYear == spSYear || currentYear == spEYear) && currentYear > spSYear)) {
        isExist = true;
      }
    }
    else {
      if (((currentYear == mpSYear || currentYear == mpEYear) && currentYear < mpEYear) ||
        ((currentYear == apSYear || currentYear == apEYear) && currentYear < apEYear) ||
        ((currentYear == spSYear || currentYear == spEYear) && currentYear < spEYear)) {
        isExist = true;
      }
    }
    return isExist;

  }

  private mapAcaPeriods(year: number, periodName: string, periodSortName, startFrom: string, endTo: string, empAvgHours: number, weeklyAvgHours: number, totalAvgHours: number): ACAPeriod {
    const data = new ACAPeriod();
    data.periodName = periodName;
    data.periodSortName = periodSortName;
    data.startFrom = startFrom ? dateTimeUtils.convertFromDtoString(startFrom) : null;
    data.endTo = endTo ? dateTimeUtils.convertFromDtoString(endTo) : null;
    data.empAvgHours = empAvgHours;
    data.weeklyAvgHours = weeklyAvgHours;
    data.totalAvgHours = totalAvgHours;
    data.currentSelectedYear = year;
    return data;
  }

  private mapAcaInsights(dto: IAcaMeasurementInsights): AcaMeasurementInsights {
    let data: AcaMeasurementInsights = new AcaMeasurementInsights();
    data.currentMonthAP = this.mapAcaInsightsPeriod(dto.currentMonthAP);
    data.currentMonthMP = this.mapAcaInsightsPeriod(dto.currentMonthMP);
    data.nextMonthAP = this.mapAcaInsightsPeriod(dto.nextMonthAP);
    data.nextMonthMP = this.mapAcaInsightsPeriod(dto.nextMonthMP);
    return data;
  }

  private mapAcaInsightsPeriod(dto: IAcaInsightPeriod): AcaInsightPeriod {
    let data: AcaInsightPeriod = new AcaInsightPeriod();
    data.period = dto.period;
    data.totalDeterminedEligibleCount = dto.totalDeterminedEligibleCount;
    data.ptDeterminedEligibleCount = dto.ptDeterminedEligibleCount;
    data.ftDeterminedEligibleCount = dto.ftDeterminedEligibleCount;
    data.ftDeterminedIneligibleCount = dto.ftDeterminedIneligibleCount;
    return data;
  }

}
