import * as tslib_1 from "tslib";
import { OnInit, Provider } from '@angular/core';
import { IdealScheduleImportTemplate, ImportException } from './../../../../../app/configuration/models/ideal-schedule/ideal-schedule-import-template';
import { DialogOptions, IDialog, ModalService } from './../../../../../app/common';
import { IdealScheduleConfigType, IdealScheduleConfigTypes, IdealScheduleConfigCensus, IdealScheduleConfigCensusOptions } from './../../../../../app/configuration/models';
import * as _ from 'lodash';
import { IdealScheduleApiService, IdealScheduleStateService } from './../../../../../app/configuration/services';
import * as moment from 'moment';
import { IdealScheduleTemplateExcelValidationService } from './../../../../../app/configuration/services/ideal-schedule/ideal-schedule-template-excel-validation.service';
import { ImportFileDetails } from './../../../../../app/configuration/models/ideal-schedule/ideal-schedule-import-template-validator';
import { NotificationsService } from './../../../../../app/core/components';
import { Router } from '@angular/router';
var IdealScheduleImportTemplateDialogComponent = /** @class */ (function () {
    function IdealScheduleImportTemplateDialogComponent(dialogOptions, modalService, importTemplateRequest, stateService, excelValidatorService, apiService, notifyService, router) {
        var _this = this;
        this.stateService = stateService;
        this.excelValidatorService = excelValidatorService;
        this.apiService = apiService;
        this.notifyService = notifyService;
        this.router = router;
        this.IdealScheduleConfigTypes = IdealScheduleConfigTypes;
        this.IdealScheduleConfigCensusOptions = IdealScheduleConfigCensusOptions;
        this.isAllScheduleCheck = false;
        this.isFileExist = false;
        this.isDatePopupTriggered = false;
        this.exceptionList = [];
        this.apiExceptionList = [];
        this.startDate = moment().add(1, 'month').startOf('month').toDate();
        this.endDate = moment('06/06/2079').toDate();
        this.dateRange = {
            startDate: this.startDate,
            endDate: this.endDate
        };
        this.isFileUploadMessageShown = false;
        this.dialogOptions = dialogOptions;
        this.modalService = modalService;
        this.importTemplateRequest = importTemplateRequest;
        this.uiExceptionSubscription = this.excelValidatorService._uiExceptionTrigger$.subscribe(function (data) {
            _this.exceptionList = data;
            _this.stateService.state.isLoading = false;
        });
        this.apiExceptionSubscription = this.excelValidatorService._apiExceptionTrigger$.subscribe(function (data) {
            _this.apiExceptionList = data;
            _this.stateService.state.isLoading = false;
        });
    }
    IdealScheduleImportTemplateDialogComponent.openDialog = function (importTemplateRequest, dialogOptions, modalService, callback) {
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: IdealScheduleImportTemplate,
                useValue: importTemplateRequest
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(IdealScheduleImportTemplateDialogComponent, importTemplateRequest.header, dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, importTemplateRequest);
        });
        return dialog;
    };
    IdealScheduleImportTemplateDialogComponent.prototype.ngOnInit = function () {
    };
    IdealScheduleImportTemplateDialogComponent.prototype.selectAllIdealSchedule = function (e, positions) {
        var _this = this;
        this.isAllScheduleCheck = e.target.checked;
        positions.map(function (i) { return _this.onChangeIdealScheduleCheck(e, i); });
        this.importTemplateRequest.positionList = this.updateSelectAll(this.isAllScheduleCheck, positions);
    };
    IdealScheduleImportTemplateDialogComponent.prototype.onChangeIdealScheduleCheck = function (e, data) {
        data.isChecked = e.target.checked;
        this.checkConfigTypeWhileChange();
    };
    IdealScheduleImportTemplateDialogComponent.prototype.onChangeIdealScheduleConfigType = function (e, data) {
        data.idealScheduleType = e;
        this.checkConfigTypeWhileChange();
    };
    IdealScheduleImportTemplateDialogComponent.prototype.onChangeIdealScheduleCensusType = function (e, data) {
        data.idealScheduleCensus = e;
    };
    IdealScheduleImportTemplateDialogComponent.prototype.save = function (isDownloadTemplate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var requestData;
            return tslib_1.__generator(this, function (_a) {
                this.dialogResult = true;
                this.stateService.state.isLoading = true;
                if (isDownloadTemplate) {
                    return [2 /*return*/, this.stateService.checkPositionsValidation(this.modalService, this.dialogOptions.windowUniqueId, this.importTemplateRequest)];
                }
                else {
                    requestData = new ImportFileDetails();
                    requestData.recordId = this.setRecordId(this.stateService.orgLevelId);
                    requestData.fileData = this.excelValidatorService._excelData;
                    this.apiService.saveIdealScheduleExcel(this.stateService.orgLevelId, requestData, moment(this.dateRange.startDate), moment(this.dateRange.endDate))
                        .then(function (data) { }).catch(function (err) { console.log(err); });
                    this.stateService.state.isLoading = false;
                    this.isFileUploadMessageShown = true;
                    this.excelValidatorService.isFileExist = false;
                }
                return [2 /*return*/];
            });
        });
    };
    IdealScheduleImportTemplateDialogComponent.prototype.navigateToFileUploadPage = function () {
        this.isFileUploadMessageShown = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
        this.router.navigateByUrl("apps/common/File_Uploads?orgLevelId=" + this.stateService.orgLevelId);
    };
    IdealScheduleImportTemplateDialogComponent.prototype.discard = function (isDownloadTemplate) {
        this.dialogResult = false;
        this.isFileUploadMessageShown = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    Object.defineProperty(IdealScheduleImportTemplateDialogComponent.prototype, "saveDisabled", {
        get: function () {
            return (this.importTemplateRequest.positionList.every(function (x) { return !x.isChecked; })
                || this.importTemplateRequest.exceptionData.length > 0) ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleImportTemplateDialogComponent.prototype.updateSelectAll = function (ind, data) {
        data.map(function (x) { return x.position.isChecked = ind; });
        return data;
    };
    IdealScheduleImportTemplateDialogComponent.prototype.isInvalidConfigType = function (dataItem) {
        if (this.importTemplateRequest.exceptionData.length === 0)
            return false;
        var index = this.importTemplateRequest.exceptionData.findIndex(function (x) { return x.positionId == dataItem.position.id && x.isInvalidConfigType; });
        if (index >= 0) {
            dataItem.isInvalidConfigType = this.importTemplateRequest.exceptionData[index].isInvalidConfigType;
        }
        else {
            dataItem.isInvalidConfigType = false;
        }
        return dataItem.isInvalidConfigType;
    };
    IdealScheduleImportTemplateDialogComponent.prototype.isInvalidCensusType = function (dataItem) {
        if (this.importTemplateRequest.exceptionData.length === 0)
            return false;
        var index = this.importTemplateRequest.exceptionData.findIndex(function (x) { return x.positionId == dataItem.position.id && x.isInvalidCensusType; });
        if (index >= 0) {
            dataItem.isInvalidCensusType = this.importTemplateRequest.exceptionData[index].isInvalidCensusType;
        }
        else {
            dataItem.isInvalidCensusType = false;
        }
        return dataItem.isInvalidCensusType;
    };
    IdealScheduleImportTemplateDialogComponent.prototype.checkConfigTypeWhileChange = function () {
        var _this = this;
        var selectedShiftGroupData = this.importTemplateRequest.positionList.filter(function (x) { return x.isChecked && x.idealScheduleType.id == 1; });
        if (selectedShiftGroupData && selectedShiftGroupData.length > 0) {
            if (this.stateService.isDefaultShiftConfigured) {
                var exceptionData_1 = _.map(selectedShiftGroupData, function (item) {
                    var data = new ImportException();
                    data.positionId = item.position.id;
                    data.positionName = item.position.name;
                    data.configurationType = item.idealScheduleType.name;
                    data.censusType = item.idealScheduleCensus.name;
                    data.errorMessage = 'Default Shifts Not Configured';
                    data.isInvalidConfigType = true;
                    data.isInvalidCensusType = false;
                    data.isNoException = false;
                    return data;
                });
                setTimeout(function () {
                    _this.importTemplateRequest.exceptionData = exceptionData_1;
                }, 1000);
                return this.importTemplateRequest;
            }
        }
        else {
            this.importTemplateRequest.exceptionData = [];
            return this.importTemplateRequest;
        }
    };
    IdealScheduleImportTemplateDialogComponent.prototype.filterConfigData = function (data) {
        if (!this.importTemplateRequest.isDefaultShiftGroupEnabled)
            return data.filter(function (x) { return x.id != 1; });
        else
            return data;
    };
    IdealScheduleImportTemplateDialogComponent.prototype.validateNewChanges = function (e) {
        this.excelValidatorService._excelData$.next([e]);
    };
    IdealScheduleImportTemplateDialogComponent.prototype.discardUpload = function (e) {
        this.excelValidatorService.isFileExist = false;
        this.exceptionList = [];
        this.apiExceptionList = [];
    };
    IdealScheduleImportTemplateDialogComponent.prototype.onDateRangeChanged = function (e) {
        this.dateRange = e;
    };
    IdealScheduleImportTemplateDialogComponent.prototype.setRecordId = function (orgLevelId) {
        var orgLevelDetails = JSON.parse(sessionStorage.getItem('Organizations'));
        var obj = _.filter(orgLevelDetails, function (item) { return item.id == orgLevelId; });
        var recordId = obj[0].parentId + "_" + obj[0].id + "_" + this.stateService.getUniqueId();
        return recordId;
    };
    return IdealScheduleImportTemplateDialogComponent;
}());
export { IdealScheduleImportTemplateDialogComponent };
