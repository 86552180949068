import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeSectionAccrualsBalances } from '../../../models/employee-sections-accruals/index';
import { EmployeeSectionsAccrualsApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { AppSettingsManageService } from './../../../../../app-settings/services/index';
var EmployeeSectionsAccrualsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAccrualsComponent, _super);
    function EmployeeSectionsAccrualsComponent(employeeSectionsAccrualsApiService, appSettingsManageService, decorator, ngZone, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeSectionsAccrualsApiService = employeeSectionsAccrualsApiService;
        _this.appSettingsManageService = appSettingsManageService;
        _this.changeDetector = changeDetector;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsAccrualsComponent.prototype, "accruals", {
        get: function () {
            return this.empAccruals;
        },
        set: function (accrualsObject) {
            this.empAccruals = accrualsObject;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAccrualsComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAccrualsComponent.prototype.ngOnInit = function () {
        this.getSettings();
    };
    EmployeeSectionsAccrualsComponent.prototype.getSubsectionModel = function () {
        return this.empAccruals;
    };
    EmployeeSectionsAccrualsComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsAccrualsApiService.getEmployeeBalances(this.employeeId)
            .then(function (data) {
            _this.accruals = data;
            _this.stopProgress();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        }).catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsAccrualsComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.EnableRate4DecimalPlaces = config.EnableRate4DecimalPlaces;
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(EmployeeSectionsAccrualsComponent.prototype, "isShowHighPrecision", {
        get: function () {
            return this.EnableRate4DecimalPlaces;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeSectionsAccrualsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsAccrualsComponent };
