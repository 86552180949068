/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./collapsible-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./collapsible-panel.component";
var styles_CollapsiblePanelComponent = [i0.styles];
var RenderType_CollapsiblePanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CollapsiblePanelComponent, data: {} });
export { RenderType_CollapsiblePanelComponent as RenderType_CollapsiblePanelComponent };
function View_CollapsiblePanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "collapse-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_CollapsiblePanelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { barContent: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["barContent", 1]], null, 1, "div", [], [[8, "hidden", 0]], null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CollapsiblePanelComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(8, { "collapse-state": 0, "expanded-state": 1, "expanding-state": 2, "locked-state": 3 }), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.defaultHeader; _ck(_v, 4, 0, currVal_1); var currVal_2 = _ck(_v, 8, 0, _co.isCollapsed, !_co.isCollapsed, _co.isExpanding, _co.locked); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.defaultHeader; _ck(_v, 1, 0, currVal_0); }); }
export function View_CollapsiblePanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-collapsible-panel", [], null, null, null, View_CollapsiblePanelComponent_0, RenderType_CollapsiblePanelComponent)), i1.ɵdid(1, 1097728, null, 0, i3.CollapsiblePanelComponent, [], null, null)], null, null); }
var CollapsiblePanelComponentNgFactory = i1.ɵccf("slx-collapsible-panel", i3.CollapsiblePanelComponent, View_CollapsiblePanelComponent_Host_0, { hideButton: "hideButton", title: "title", collapsed: "collapsed", locked: "locked" }, {}, [".panel-header", ".panel-content"]);
export { CollapsiblePanelComponentNgFactory as CollapsiblePanelComponentNgFactory };
