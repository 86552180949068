import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { LookupService, OrgLevelWatchService } from '../../../../organization/services/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { MealDeductionApiService } from './meal-deduction-api.service';
import { LookupType } from '../../../../organization/models/index';
import { MealDeductionSettings, ShiftModes } from '../../models/index';
import { ManagementBaseService } from '../../../../core/services/index';
import { ReplaySubject } from 'rxjs';
var MealDeductionManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(MealDeductionManagementService, _super);
    function MealDeductionManagementService(api, lookupService, orgLevelWatchService) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.lookupService = lookupService;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.onSaved$ = new Subject();
        _this.selIds$ = new ReplaySubject();
        return _this;
    }
    MealDeductionManagementService.prototype.saveConfiguration = function (settings) {
        var _this = this;
        this.onLoadStatusChanged(true);
        if (_.isNil(settings.recordId) || settings.recordId === 0) {
            this.api.addOrganizationMealDeductionSettings(settings).then(function () {
                _this.onSaved$.next(true);
                _this.onLoadStatusChanged(false);
            }).catch(function () {
                _this.onSaved$.next(false);
                _this.onLoadStatusChanged(false);
            });
        }
        else {
            var savingSerialized = JSON.stringify(settings);
            if (savingSerialized === this.orgiginalSerialized) {
                this.onSaved$.next(false);
                return;
            }
            this.api.saveOrganizationMealDeductionSettings(settings).then(function () {
                _this.onSaved$.next(true);
                _this.onLoadStatusChanged(false);
            }).catch(function () {
                _this.onSaved$.next(false);
                _this.onLoadStatusChanged(false);
            });
        }
    };
    MealDeductionManagementService.prototype.reload = function () {
        var _this = this;
        if (!_.isNil(this.currentOrganization)) {
            this.api.getOrganizationMealDeductionSettings(this.currentOrganization.id)
                .then(function (s) {
                if (!_.isNil(s.recordId) && s.recordId !== 0) {
                    _this.settings = s;
                    _this.orgiginalSerialized = JSON.stringify(_this.settings);
                }
                else {
                    var settings = _this.createDefaultSettings(_this.currentOrganization);
                    settings.canConfigure = s.canConfigure;
                    settings.generatePBJFromAgency = s.generatePBJFromAgency;
                    settings.shift_Type = s.shift_Type;
                    settings.midShiftOne = s.midShiftOne;
                    settings.midShiftTwo = s.midShiftTwo;
                    _this.settings = settings;
                    _this.orgiginalSerialized = null;
                }
                _this.onLoaded(_this.settings);
                _this.onLoadStatusChanged(false);
            }).catch(function () {
                _this.settings = null;
                _this.orgiginalSerialized = null;
                _this.onLoaded(_this.settings);
                _this.onLoadStatusChanged(false);
            });
        }
        else {
            var settings = this.createDefaultSettings(this.currentOrganization);
            // settings.canConfigure = true;
            // settings.generatePBJFromAgency = false;
            // settings.shift_Type = "";
            // settings.midShiftOne = 0;
            // settings.midShiftTwo = 0;
            // this.settings = settings;
            // this.orgiginalSerialized = null;
            this.onLoaded(this.settings);
            this.onLoadStatusChanged(false);
        }
    };
    MealDeductionManagementService.prototype.onOrglevelChanged = function (orgLevel) {
        var _this = this;
        this.currentOrgLevel = orgLevel;
        this.onLoadStatusChanged(true);
        this.loadOrganizations().then(function () {
            _this.getCurrentOrganization();
        });
    };
    MealDeductionManagementService.prototype.createDefaultSettings = function (organization) {
        var s = new MealDeductionSettings();
        s.organizationId = organization.id;
        s.enableForAgency = undefined;
        s.enableForExemptS = undefined;
        s.enableForExemptT = undefined;
        s.enableForExemptX = undefined;
        s.enableForExemptY = undefined;
        s.enableForNonExempt = undefined;
        s.maxPunchTreshold = undefined;
        s.lastUpdateOn = new Date();
        s.generatePBJFromAgency = false;
        s.shift_Type = _.head(ShiftModes);
        s.midShiftOne = 4;
        s.midShiftTwo = 12;
        return s;
    };
    MealDeductionManagementService.prototype.getCurrentOrganization = function () {
        var _this = this;
        var organization;
        var searchOrgLevelId;
        var corpOrgLevel = this.orgLevelWatchService.getCurrentOrgLevel();
        var lookupdata = this.organizationsLookup.items.filter(function (x) { return x.id != 1000; });
        if (corpOrgLevel.type === OrgLevelType.organization) {
            searchOrgLevelId = this.currentOrgLevel.id;
            this.currentOrganization = _.find(lookupdata, function (o) { return o.orgLevelId === searchOrgLevelId; });
            this.reload();
        }
        else if (corpOrgLevel.type == OrgLevelType.department) {
            searchOrgLevelId = this.currentOrgLevel.parentId;
            this.currentOrganization = _.find(lookupdata, function (o) { return o.orgLevelId === searchOrgLevelId; });
            this.reload();
        }
        else {
            this.api.getAllOrganizationIds(corpOrgLevel.id).then(function (res) {
                var orgLevelIds = res.data;
                if (!_.isNil(orgLevelIds)) {
                    for (var x = 0; x < orgLevelIds.length; x++) {
                        for (var y = 0; y < lookupdata.length; y++) {
                            if (orgLevelIds[x] == lookupdata[y].orgLevelId) {
                                organization = lookupdata[y];
                                break;
                            }
                        }
                        if (organization) {
                            _this.currentOrganization = organization;
                            _this.reload();
                            break;
                        }
                    }
                }
            });
        }
    };
    MealDeductionManagementService.prototype.loadOrganizations = function () {
        var _this = this;
        return this.lookupService
            .getLookup({ lookupType: LookupType.organization })
            .then(function (organizationLookup) {
            _this.organizationsLookup = organizationLookup;
            return _this.organizationsLookup;
        });
    };
    MealDeductionManagementService.prototype.setSelectedIds = function (selIds) {
        this.selIds$.next(selIds);
    };
    MealDeductionManagementService.prototype.getSelectedIds = function () {
        var selectedIds;
        this.selIds$.subscribe(function (data) {
            selectedIds = data;
        });
        return selectedIds;
    };
    return MealDeductionManagementService;
}(ManagementBaseService));
export { MealDeductionManagementService };
