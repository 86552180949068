import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { DateRange } from '../../../core/models/index';
import { AccrualsTransactionRecord } from '../models/index';
import { AccrualsManagementService } from './accruals-management.service';
import { AccrualsApiService } from './accruals-api.service';
var AccrualsTransactionsManagementService = /** @class */ (function () {
    function AccrualsTransactionsManagementService(manService, apiService) {
        this.manService = manService;
        this.apiService = apiService;
        this.loading$ = new Subject();
        this.recordsLoaded$ = new Subject();
        this.dateRange$ = new Subject();
        this.exportTo$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.calculateAccrualsAction$ = new Subject();
        this.subscriptions = {};
    }
    AccrualsTransactionsManagementService.prototype.init = function () {
        this.subscribeToOrgLevelChanges();
        this.subscribeToLoadAccrualsTransactions();
    };
    AccrualsTransactionsManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.dateRange$.complete();
        this.exportTo$.complete();
        this.orgLevelChanged$.complete();
        this.calculateAccrualsAction$.complete();
    };
    AccrualsTransactionsManagementService.prototype.getDefaultDateRange = function () {
        var d = new Date();
        return new DateRange(new Date(d.getFullYear() - 1, d.getMonth(), d.getDate()), d);
    };
    AccrualsTransactionsManagementService.prototype.changeDateRange = function (r) {
        this.dateRange = r;
        this.dateRange$.next(r);
        this.loadAccrualsTransactions();
    };
    AccrualsTransactionsManagementService.prototype.subscribeToDateRange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.dateRange$.subscribe(callback);
    };
    AccrualsTransactionsManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    AccrualsTransactionsManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    AccrualsTransactionsManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    AccrualsTransactionsManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    AccrualsTransactionsManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    AccrualsTransactionsManagementService.prototype.subscribeTocalculateAccrualsAction = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.calculateAccrualsAction$.subscribe(callback);
    };
    AccrualsTransactionsManagementService.prototype.loadAccrualsTransactions = function () {
        var _this = this;
        if (!_.isFinite(_.get(this.orgLevel, 'id'))
            || !_.isDate(_.get(this.dateRange, 'startDate'))
            || !_.isDate(_.get(this.dateRange, 'endDate')))
            return;
        this.loading$.next(true);
        this.apiService.getAccrualsTransactionRecords(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
            .then(function (accrualTransactions) {
            _this.recordsLoaded$.next(accrualTransactions.records);
            _this.calculateAccrualsAction$.next(accrualTransactions.calculateAccruals);
            _this.loading$.next(false);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    AccrualsTransactionsManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
            _this.loadAccrualsTransactions();
        });
    };
    AccrualsTransactionsManagementService.prototype.subscribeToLoadAccrualsTransactions = function () {
        var _this = this;
        this.subscriptions.loadAccrualsTransaction = this.manService.subscribeToLoadAccrualsTransactions(function () { return _this.loadAccrualsTransactions(); });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AccrualsTransactionsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], AccrualsTransactionsManagementService.prototype, "subscriptions", void 0);
    return AccrualsTransactionsManagementService;
}());
export { AccrualsTransactionsManagementService };
