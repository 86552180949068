<div class="modal-content">
  <div class="modal-body">  
    <slx-spinner [show]="state.isLoading" novalidate>
      <div class="content-holder">
          
        <div class="profile-selector-holder">
          <slx-input-decorator>
            <slx-dropdown-input slx-input
              lookup="timeclockSlateProfileDefinition"
              [(ngModel)]="selectedProfile"
              placeholder="SLATE Profile"
              required="true"
              name="slateProfile"
            >
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>

        <div class="employee-list">
          <slx-employee-grid 
          [fieldDefinition]="fieldDefinition" 
          [employees]="employees" 
          [isMultiselect]="true"
          [isOptInStatus]="false" 
          [isGroupable]="false" 
          (onEmployeesSelect)="employeesSelect($event)">
          </slx-employee-grid>
        </div>

      </div>
    </slx-spinner>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn red" (click)="onCancel()"><i class="fas fa-times slx-button__icon"
        aria-hidden="true"></i>Cancel</button>
    <button type="button" (click)="assignProfile()" class="btn green" [disabled]="!canAssign || !this.selectedProfile"><i
        class="fa fa-save slx-button__icon" aria-hidden="true"></i>Save</button>
  </div>
</div>    