/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./actions-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./actions-list.component";
var styles_ActionsListComponent = [i0.styles];
var RenderType_ActionsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionsListComponent, data: {} });
export { RenderType_ActionsListComponent as RenderType_ActionsListComponent };
export function View_ActionsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵncd(null, 0), i1.ɵncd(null, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "slx-actions-list", _co.getClassName(), ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_ActionsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-actions-list", [], null, null, null, View_ActionsListComponent_0, RenderType_ActionsListComponent)), i1.ɵdid(1, 49152, null, 0, i2.ActionsListComponent, [], null, null)], null, null); }
var ActionsListComponentNgFactory = i1.ɵccf("slx-actions-list", i2.ActionsListComponent, View_ActionsListComponent_Host_0, { className: "className" }, {}, ["*", "*"]);
export { ActionsListComponentNgFactory as ActionsListComponentNgFactory };
