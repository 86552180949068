import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ManagementBaseService } from '../../../core/services/management/management-base.service';
import { TimecardSummaryApiService } from './timecards-summary-api.service';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { OrgLevelFlat, PayCycle, Corporation, Position, Department, EmpType, PayPolicy, ShiftDiffPolicy } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { OrgLevelWatchService, LookupApiService } from '../../../organization/services/index';
import { ITimecardSummaryRequest, TimecardSummaryRequest, TimecardIndividualSummaryRequest, TimecardSummaryModel } from '../../models/index';

const DEFAULT_DIFF_PERCENTAGE: number = 5;

@Injectable()
export class TimecardsSummaryManagementService extends ManagementBaseService<TimecardSummaryModel, any> {

    public get allowFilters(): boolean {
        return !this.individualMode;
    }
    public get showExemptEmployees(): boolean {
        return !this.individualMode;
    }

    public get initialized(): boolean {
        return this.m_initialized;
    }

    @mutableSelect('orgLevel')
    public orgLevel$: Observable<OrgLevel>;

    @unsubscribeInService()
    public orgLevelSubscription: Subscription;
    @unsubscribeInService()
    public orgLevelTreeSubscription: Subscription;
    public currentOrgLevel: OrgLevel;
    public currentOrganizationOrgLevel: OrgLevel;
    public currentPayCycle: PayCycle;

    public diffPercent: number = DEFAULT_DIFF_PERCENTAGE;

    public organizationId: number = -1;
    public employeeId: number = -1;

    public positions: Position[];
    public departments: Department[];
    public orgLevelRelatedDepartments: Department[];
    public orgLevelDepartment: Department;
    public orgLevelDepartmentIds: number[];
    public empTypes: EmpType[];
    public payPolicies: PayPolicy[];
    public shifDiffPolicies: ShiftDiffPolicy[];

    private m_initialized: boolean = false;
    private individualMode: boolean = false;

    constructor(
      private api: TimecardSummaryApiService,
      private lookupService: LookupApiService,
      private orgLevelWatchService: OrgLevelWatchService
    ) {
        super();
    }

    public onPayCycleChanged(payCycle: PayCycle): void {
        if (this.currentPayCycle && moment(this.currentPayCycle.startDate).isSame(payCycle.startDate)
            && moment(this.currentPayCycle.endDate).isSame(payCycle.endDate)) {
            return;
        }
        this.currentPayCycle = payCycle;
    }

    public async onOrgLevelChanged(organizationId: number, currentOrganizationOrgLevel: OrgLevel, orgLevel: OrgLevel): Promise<any> {
        this.diffPercent = DEFAULT_DIFF_PERCENTAGE;
        this.individualMode = false;
        if(!orgLevel) {
          this.currentOrgLevel = this.findOrgLevelForOrganization(organizationId);
          this.currentOrganizationOrgLevel = this.currentOrgLevel;
        } else {
          this.currentOrgLevel = orgLevel;
          this.currentOrganizationOrgLevel = currentOrganizationOrgLevel;
        }


        if (this.organizationId !== organizationId) {
            this.setOrganizationId(organizationId);
            await this.loadLookups();
        }
        if(this.currentOrgLevel.type === OrgLevelType.department) {
            this.orgLevelDepartment = _.find(this.departments, (d) => d.orgLevelId === this.currentOrgLevel.id);
            this.orgLevelRelatedDepartments = [this.orgLevelDepartment];
            this.orgLevelDepartmentIds = [this.orgLevelDepartment.id];
        } else {
          this.orgLevelDepartmentIds = null;
          this.orgLevelDepartment = null;
          this.orgLevelRelatedDepartments = this.departments;
        }
        this.loadTimecardsSummary();

    }

    public onEmployeeIdChanged(id: number): void {
        this.individualMode = true;
        if (this.employeeId !== id) {
            this.employeeId = id;
            this.loadIndividualTimecardSummary();
        }
    }

    public loadTimecardsSummary(req?: TimecardSummaryRequest): void {
        if (!isNaN(this.organizationId) && this.currentPayCycle) {
            this.onLoadStatusChanged(true);
            let request: TimecardSummaryRequest = req ? req : new TimecardSummaryRequest();
            request.organizationId = this.organizationId;
            request.startDate = this.currentPayCycle.startDate;
            request.endDate = this.currentPayCycle.endDate;
            if(!request.departmentIds && this.orgLevelDepartmentIds) {
              request.departmentIds = this.orgLevelDepartmentIds;
            }
            this.api.loadTimecardsSummary(request).then((result: TimecardSummaryModel) => {
                this.onLoaded(result);
                this.onLoadStatusChanged(false);
            }).catch((result: any) => {
                this.onLoaded(null);
                this.onError(result);
            });
        }
    }

    public loadIndividualTimecardSummary(): void {
        if (!isNaN(this.employeeId) && this.currentPayCycle) {
            this.onLoadStatusChanged(true);
            let request: TimecardIndividualSummaryRequest = new TimecardIndividualSummaryRequest();
            request.employeeId = this.employeeId;
            request.startDate = this.currentPayCycle.startDate;
            request.endDate = this.currentPayCycle.endDate;
            this.api.loadTimecardIndividualSummary(request).then((result: TimecardSummaryModel) => {
                this.onLoaded(result);
                this.onLoadStatusChanged(false);
            }).catch((result: any) => {
                this.onLoaded(null);
                this.onError(result);
            });
        }
    }

    private async loadLookups(): Promise<any> {
        let positionsLookup: Promise<Position[]> = this.lookupService.getPositions(undefined, this.currentOrganizationOrgLevel.id);
        let departmentLookup: Promise<Department[]> = this.lookupService.getDepartments(undefined, this.currentOrganizationOrgLevel.id);
        let empTypeLookup: Promise<EmpType[]> = this.lookupService.getEmpTypes();
        let payPolicyLookup: Promise<PayPolicy[]> = this.lookupService.getPayPolicies(undefined, this.currentOrganizationOrgLevel.id);
        let shiftDiffPolicyLookup: Promise<PayPolicy[]> = this.lookupService.getShiftDiffPolicies(undefined, this.currentOrganizationOrgLevel.id);

        return Promise.all([positionsLookup, departmentLookup, empTypeLookup, payPolicyLookup, shiftDiffPolicyLookup]).then((values: any[]) => {
            [this.positions, this.departments, this.empTypes, this.payPolicies, this.shifDiffPolicies] = values;
            this.m_initialized = true;
        });
    }

    private findOrgLevelForOrganization(organizationId: number): OrgLevel {
        let list: OrgLevelFlat[] = this.orgLevelWatchService.getFlatList();
        if (list) {
            let orgLevelFlat: OrgLevelFlat = _.find(list, (flat: OrgLevelFlat) => {
                const res = flat.orgLevel.relatedItemId === organizationId && flat.orgLevel.type === OrgLevelType.organization;
                return res;
            });
            if(!orgLevelFlat) {
              return null;
            }
            return orgLevelFlat.orgLevel;
        }
        return null;
    }

    private setOrganizationId(id: number): void;
    private setOrganizationId(orgLevel: OrgLevel): void;
    private setOrganizationId(argument: any): void {
        if (typeof argument === 'number') {
            this.organizationId = argument;
        } else {
            let orgLevel: OrgLevel = argument;
            if (orgLevel.type === OrgLevelType.department) {
                this.organizationId = orgLevel.parentId;
            } else {
                this.organizationId = orgLevel.id;
            }
        }
    }
}
