import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { UrlSegment } from '@angular/router';
import * as moment from 'moment';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../../framework/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { ManagementBaseService } from '../../../../core/services/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { DateRange, IDateRange } from '../../../../core/models/index';

import { StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';
import { StateResetTypes, IControlState } from '../../../../core/models/index';

import { WcViewList, WcViewListDisplay, WcViewItem, WcIncidentAmountItem, IWcRosterToolbarState } from '../../models/index';
import { WCIncidentReportDialogComponent } from '../../components/crud/wc-incident-report-dialog/wc-incident-report-dialog.component';
import { ModalService } from '../../../../common/services';

@Injectable()
export class WcRosterToolbarService extends ManagementBaseService<any, any> {
  public get newIncidentIsActive(): boolean {
    return this.buttonIsActive;
  }
  public get currentDateRange(): IDateRange {
    return this.dateRange;
  }

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;
  private buttonIsActive: boolean = true;
  private currentView: WcViewItem = null;
  private viewsList: WcViewItem[] = [];
  private incidentsList: WcIncidentAmountItem[] = [];
  private dateRange: DateRange;

  private newIncident$ = new Subject<boolean>();
  private permissionsChanged$ = new Subject<boolean>();
  private dateRange$ = new Subject<DateRange>();
  private initDateRange$ = new ReplaySubject<DateRange>();
  private view$ = new Subject<WcViewItem>();
  private definedState$ = new Subject<IWcRosterToolbarState>();
  private saveState$ = new Subject<IWcRosterToolbarState>();
  private exportTo$ = new Subject<boolean>();
  private incidentsAmount$ = new Subject<number>();
  private toolbarActions$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  public yearRange = new ReplaySubject<number[]>(1);
  
  private dateRangeKey: string = 'dateRangeKey';
  private resetBy: StateResetTypes = StateResetTypes.SessionEnd;
  private availableIncidents = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  private generateOSHAForm$ = new Subject<string>();

  constructor(
    private modalService: ModalService,
    private stateManagement: StateManagementService,
    private compStorageService: ComponentStateStorageService
  ) {
    super();
    this.init();
  }

  public init(): void {
    this.viewsList = this.getViewList();
    this.incidentsList = this.getIncidentList();
    this.initStateManagement();
    this.subscribeToOrgLevelChanges();
  }

  public destroy(): void {
    this.orgLevel = null;
    this.buttonIsActive = true;
    this.currentView = null;
    this.viewsList = [];

    this.newIncident$.complete();
    this.permissionsChanged$.complete();
    this.dateRange$.complete();
    this.initDateRange$.complete();
    this.view$.complete();
    this.definedState$.complete();
    this.saveState$.complete();
    this.exportTo$.complete();
    this.incidentsAmount$.complete();
    this.toolbarActions$.complete();
    this.orgLevelChanged$.complete();
    this.generateOSHAForm$.complete();
    this.yearRange.complete();

    super.destroy();
  }

  public permissionsChanged(canCreate: boolean): void {
    this.permissionsChanged$.next(canCreate);
  }

  public clickNewIncident(isActive: boolean): void {
    this.newIncident$.next(isActive);
  }

  public toogleToolbarActions(actionsAllowed: boolean): void {
    this.toolbarActions$.next(actionsAllowed);
  }

  public subscribeToToolbarActions(callback: (actionsAllowed: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.toolbarActions$.subscribe(callback);
  }

  public subscribeToPermissionsChanged(callback: (canCreate) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.permissionsChanged$.subscribe(callback);
  }

  public subscribeToNewIncident(callback: (isActive) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.newIncident$.subscribe(callback);
  }

  public changeDateRange(r: DateRange): void {
    this.dateRange = r;
    this.dateRange$.next(r);
    this.saveDateRange(r);
  }

  public selectedYearRange(selectedYearsList: number[]): void {
     this.yearRange.next(selectedYearsList);
  }

  public subscritpionToSenseYearChange(callback: (r: number[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    
    return this.yearRange.subscribe(callback);
  }

  public subscribeToDateRange(callback: (r: DateRange) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.dateRange$.subscribe(callback);
  }

  public subscribeToInitDateRange(callback: (r: DateRange) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.initDateRange$.subscribe(callback);
  }

  public saveState(state: IWcRosterToolbarState): void {
    this.saveState$.next(_.cloneDeep(state));
  }

  public subscribeToSaveState(callback: (state: IWcRosterToolbarState) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.saveState$.subscribe(callback);
  }

  public defineState(state: IWcRosterToolbarState): void {
    this.definedState$.next(_.cloneDeep(state));
  }

  public subscribeToDefineState(callback: (state: IWcRosterToolbarState) => void): Subscription {
     Assert.isNotNull(callback, 'callback');

    return this.definedState$.subscribe(callback);
  }

  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }

  public subscribeToExport(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.exportTo$.subscribe(callback);
  }

  public changeIncidentsAmount(amount: number): void {
    this.incidentsAmount$.next(amount);
  }

  public subscribeToIncidentsAmount(callback: (a: number) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.incidentsAmount$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelChanged$.subscribe(callback);
  }

  public defineView(url: UrlSegment[]): WcViewItem {
    if (_.isArray(url) && _.size(url) > 0) {
      const headUrl = _.head(url);
      const view = _.find(this.viewsList, (view: WcViewItem) => view.path === headUrl.path);
      if (_.isObjectLike(view) && _.get(this.currentView, 'path') !== view.path) {
        this.currentView = view;
        this.view$.next(this.currentView);
      }
      return _.isObjectLike(view) && view || null;
    }
    return null;
  }

  public ytdListRender(): any[] {
    const amountOfYearsLessCurrent: number = 3;
    const currentYear: number = moment().year();
    let ytdList =  _.range(currentYear, currentYear - amountOfYearsLessCurrent, -1);
    return ytdList;
  }

  public getDefaultRange(): DateRange {
    return new DateRange(new Date(this.dateRange.startDate.getTime()), new Date(this.dateRange.endDate.getTime()));
  }

  public getViewsList(): WcViewItem[] {
    return this.viewsList.concat();
  }

  public getIncidentsList(): WcIncidentAmountItem[] {
    return this.incidentsList.concat();
  }

  public getCurrentIncident(): WcIncidentAmountItem {
    return this.incidentsList[2];
  }

  public openNewReportDialog(): void {
    WCIncidentReportDialogComponent.openDialog(null, this.modalService, (result: boolean) => {
      this.clickNewIncident(true);
      if (result) {
        this.changeDateRange(this.dateRange);
      }
    });
  }

  public openEditReportDialog(reportId: number): void {
    this.clickNewIncident(false);
    WCIncidentReportDialogComponent.openDialog({ reportId: reportId, employeeId: null }, this.modalService, (result: boolean) => {
      this.clickNewIncident(true);
      if (result) {
        this.changeDateRange(this.dateRange);
      }
    });
  }

  private initStateManagement(): void {
    this.stateManagement.init('IncidentRosterToolbar', true);
    const stateSubscription = this.stateManagement.onInit$
      .combineLatest(this.view$)
      .subscribe(() => {
        let range: DateRange = null;
        if (this.currentView.isIncidentRoster) {
          range = this.getDefaultDateRange();
          this.initDateRange$.next(range);
          this.changeDateRange(range);
        } else {
          range = this.restoreDateRange();
          if (!_.isObjectLike(range)) {
            range = this.getDefaultDateRange();
          }
          this.initDateRange$.next(range);
          this.changeDateRange(range);
        }
        stateSubscription.unsubscribe();
      });
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
      });
  }

  private getViewList(): WcViewItem[] {
    return _.map(WcViewList, (value) => {
      const viewPath = value.split(' ').join('_').toLowerCase();
      const item = new WcViewItem(value, WcViewListDisplay[value], viewPath);
      return item;
    });
  }

  private getIncidentList(): WcIncidentAmountItem[] {
    return _.map(this.availableIncidents, (v) => {
      const id = v;
      const value = v === 10 ? `${v}+` : `${v}`;

      return new WcIncidentAmountItem(id, value);
    });
  }

  private saveDateRange(range: IDateRange): void {
    const startDate = range.startDate.toISOString();
    const endDate = range.endDate.toISOString();

    this.compStorageService.setControlState(
      this.stateManagement.componentKey,
      this.dateRangeKey,
      { value: { startDate, endDate } },
      this.resetBy
    );
  }

  private restoreDateRange(): IDateRange {
    let state: IControlState = this.compStorageService.getControlState(this.stateManagement.componentKey, this.dateRangeKey);
    const value: { startDate: string, endDate: string } = _.get(state, 'value');
    if (_.isObject(value) && _.isString(value.startDate) && _.isString(value.endDate)) {
      return new DateRange(new Date(value.startDate), new Date(value.endDate));
    }

    return null;
  }

  private getDefaultDateRange(): IDateRange {
    const d = new Date();

    return new DateRange(new Date(d.getFullYear(), 0, 1), d);
  }

  public generateOSHAForm300A(action: string) {
     this.generateOSHAForm$.next(action);
  }

  public subscribeToGenerateOSHAForm300A(callback: (action: string) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.generateOSHAForm$.subscribe(callback);
  }

}
