/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./master-schedule-grid.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i3 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i4 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i5 from "ag-grid-angular/dist/agGridAngular";
import * as i6 from "@angular/common";
import * as i7 from "ngx-popper";
import * as i8 from "../../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i9 from "../../census-entries-popup/census-entries-popup.component.ngfactory";
import * as i10 from "../../census-entries-popup/census-entries-popup.component";
import * as i11 from "./master-schedule-grid.component";
import * as i12 from "../../../../common/services/modal/modal.service";
import * as i13 from "../../../../organization/services/lookup/lookup-api.service";
import * as i14 from "../../../store/master-schedule/master-schedule.actions";
import * as i15 from "../../../services/schedule/master-schedule-management.service";
import * as i16 from "@angular/router";
import * as i17 from "../../../../portal/actions/sidebar.actions";
import * as i18 from "../../../../core/services/toolbar/toolbar-base.service";
import * as i19 from "../../../services/schedule/master-schedule-quick-edit.service";
import * as i20 from "../../../../organization/services/lookup/lookup.service";
var styles_MasterScheduleGridComponent = [i0.styles];
var RenderType_MasterScheduleGridComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_MasterScheduleGridComponent, data: {} });
export { RenderType_MasterScheduleGridComponent as RenderType_MasterScheduleGridComponent };
function View_MasterScheduleGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "slx-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-fresh master-schedule-grid"], ["groupHeaders", ""], ["rowHeight", "25"], ["rowSelection", "single"]], null, [[null, "cellClicked"], [null, "sortChanged"], [null, "componentStateChanged"], [null, "selectionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cellClicked" === en)) {
        var pd_0 = (_co.onCellClicked($event) !== false);
        ad = (pd_0 && ad);
    } if (("sortChanged" === en)) {
        var pd_1 = (_co.onSortChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("componentStateChanged" === en)) {
        var pd_2 = (_co.onComponentStateChanged($event) !== false);
        ad = (pd_2 && ad);
    } if (("selectionChanged" === en)) {
        var pd_3 = (_co.onSelectionChanged($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_AgGridAngular_0, i2.RenderType_AgGridAngular)), i1.ɵprd(512, null, i3.AngularFrameworkOverrides, i3.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i4.AngularFrameworkComponentWrapper, i4.AngularFrameworkComponentWrapper, []), i1.ɵdid(4, 4898816, [["agGrid", 4]], 1, i5.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i3.AngularFrameworkOverrides, i4.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { gridOptions: [0, "gridOptions"], rowData: [1, "rowData"], columnDefs: [2, "columnDefs"], pinnedBottomRowData: [3, "pinnedBottomRowData"], rowSelection: [4, "rowSelection"], rowHeight: [5, "rowHeight"] }, { cellClicked: "cellClicked", selectionChanged: "selectionChanged", sortChanged: "sortChanged", componentStateChanged: "componentStateChanged" }), i1.ɵqud(603979776, 4, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridOptions; var currVal_1 = _co.rowData; var currVal_2 = _co.gridHelper.columnDefs; var currVal_3 = _co.footerData; var currVal_4 = "single"; var currVal_5 = "25"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_MasterScheduleGridComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { censusPopperConent: 0 }), i1.ɵqud(402653184, 2, { popperInitiator: 0 }), i1.ɵqud(671088640, 3, { censusWindow: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "slx-master-schedule"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "master-schedule-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MasterScheduleGridComponent_1)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "span", [], null, [[null, "popperOnShown"], [null, "popperOnHidden"], [null, "touchstart"], [null, "click"], [null, "mousedown"], [null, "mouseenter"], [null, "touchend"], [null, "touchcancel"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).showOrHideOnMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).showOrHideOnClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8).showOrHideOnMouseOver($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8).showOnHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 8).hideOnLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("touchcancel" === en)) {
        var pd_5 = (i1.ɵnov(_v, 8).hideOnLeave($event) !== false);
        ad = (pd_5 && ad);
    } if (("mouseleave" === en)) {
        var pd_6 = (i1.ɵnov(_v, 8).hideOnLeave($event) !== false);
        ad = (pd_6 && ad);
    } if (("popperOnShown" === en)) {
        var pd_7 = (_co.onCensusPopperShown($event) !== false);
        ad = (pd_7 && ad);
    } if (("popperOnHidden" === en)) {
        var pd_8 = (_co.onCensusPopperHidden($event) !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 737280, [[2, 4]], 0, i7.PopperController, [i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i1.Renderer2], { content: [0, "content"], placement: [1, "placement"], showTrigger: [2, "showTrigger"], hideOnClickOutside: [3, "hideOnClickOutside"], disableStyle: [4, "disableStyle"] }, { popperOnShown: "popperOnShown", popperOnHidden: "popperOnHidden" }), (_l()(), i1.ɵeld(9, 0, null, null, 3, "popper-content", [["class", "census-popup-content"]], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_PopperContent_0, i8.RenderType_PopperContent)), i1.ɵdid(10, 180224, [[1, 4], ["censusPopperConent", 4]], 0, i7.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "slx-census-entries-popup", [], null, null, null, i9.View_CensusEntriesPopupComponent_0, i9.RenderType_CensusEntriesPopupComponent)), i1.ɵdid(12, 49152, [[3, 4], ["censusWindow", 4]], 0, i10.CensusEntriesPopupComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showGrid; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 10); var currVal_2 = "top"; var currVal_3 = "click"; var currVal_4 = true; var currVal_5 = true; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_MasterScheduleGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-master-schedule-grid", [], null, null, null, View_MasterScheduleGridComponent_0, RenderType_MasterScheduleGridComponent)), i1.ɵdid(1, 4440064, null, 0, i11.MasterScheduleGridComponent, [i12.ModalService, i1.ChangeDetectorRef, i13.LookupApiService, i14.MasterScheduleActions, i15.MasterScheduleManagementService, i1.ElementRef, i16.Router, i16.ActivatedRoute, i17.SidebarActions, i18.TOOLBAR_SERVICE, i19.MasterScheduleQuickEditService, i20.LookupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MasterScheduleGridComponentNgFactory = i1.ɵccf("slx-master-schedule-grid", i11.MasterScheduleGridComponent, View_MasterScheduleGridComponent_Host_0, {}, {}, []);
export { MasterScheduleGridComponentNgFactory as MasterScheduleGridComponentNgFactory };
