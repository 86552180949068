import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSubsectionDependents = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSubsectionDependents, _super);
    function EmployeeSubsectionDependents() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dependents = [];
        _this.relations = [];
        _this.canAddDependents = false;
        _this.canRemoveDependents = false;
        _this.canEditDependents = false;
        _this.canEnrollDependents = false;
        _this.canDropCoverageDependents = false;
        return _this;
    }
    return EmployeeSubsectionDependents;
}(EmployeeSectionsBase));
export { EmployeeSubsectionDependents };
