import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ExportDataConfigurationInfo, ExportDataExecutionItem, ExportDataConfigurationParameter } from '../../../models';
import { ExportDataManagementService } from '../../../services/export-data-management.service';
import { ExportDataStatus } from '../../../enums/export-data-status';
import * as _ from 'lodash';
import { unsubscribeAll } from '../../../../../core/decorators';
import * as moment from 'moment';
var ExportDataSectionComponent = /** @class */ (function () {
    function ExportDataSectionComponent(manService, changeDetector) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.isCollapsed = true;
        this.subscriptions = {};
    }
    Object.defineProperty(ExportDataSectionComponent.prototype, "formValid", {
        get: function () {
            return !_.some(this.parameters, function (x) { return (!x.isValid || (x.dataType.type !== 'hidden' && x.dataType.required && x.value !== undefined)); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportDataSectionComponent.prototype, "isExpired", {
        get: function () {
            return this.configuration.lastExecuted && (this.configuration.lastExecuted.completedOn && this.configuration.lastExecuted.completedOn < moment().subtract(1, 'days').toDate()
                || !this.configuration.lastExecuted.completedOn && this.configuration.lastExecuted.startedOn < moment().subtract(1, 'days').toDate());
        },
        enumerable: true,
        configurable: true
    });
    ExportDataSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.parameters = _.cloneDeep(this.configuration.parameters);
        this.initParams(this.parameters);
        this.subscriptions.exportExecuted = this.manService.subscribeToExportExecuted(function (v) {
            if (!v) {
                return;
            }
            if (_this.configuration.id === v.configurationId) {
                _this.configuration.lastExecuted = v;
            }
        });
        if (!this.isCollapsed) {
            this.manService.loadExportDataHistory(this.configuration.id);
        }
    };
    ExportDataSectionComponent.prototype.ngOnDestroy = function () {
        this.configuration = null;
        this.isSubmitted = false;
        this.isCollapsed = true;
        this.changeDetector.detach();
    };
    Object.defineProperty(ExportDataSectionComponent.prototype, "exportDataStatus", {
        get: function () {
            return ExportDataStatus;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportDataSectionComponent.prototype, "generateIsAllowed", {
        get: function () {
            return this.configuration && (!this.configuration.lastExecuted || (this.configuration.lastExecuted.status !== ExportDataStatus.Waiting &&
                this.configuration.lastExecuted.status !== ExportDataStatus.InProgress));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportDataSectionComponent.prototype, "cancelIsAllowed", {
        get: function () {
            return this.configuration && !this.generateIsAllowed;
        },
        enumerable: true,
        configurable: true
    });
    ExportDataSectionComponent.prototype.collapsedChange = function (isCollapsed) {
        if (!isCollapsed && !this.configuration.history) {
            this.manService.loadExportDataHistory(this.configuration.id);
        }
    };
    ExportDataSectionComponent.prototype.initParams = function (parameters) {
        var _this = this;
        _.each(parameters, function (x) {
            x.isValid = true;
            x.dataType.required = false;
            if (x.dataType.type === 'bit') {
                x.value = false;
            }
            if (x.dataType.type === 'hidden') {
                switch (x.name) {
                    case 'org_level_id':
                        x.value = _this.manService.orgLevel.id;
                        break;
                }
            }
        });
    };
    ExportDataSectionComponent.prototype.generate = function () {
        if (this.isSubmitted) {
            return;
        }
        this.isSubmitted = true;
        this.manService.generateExportData(this.configuration.id, this.parameters);
        this.isSubmitted = false;
    };
    ExportDataSectionComponent.prototype.cancelExecution = function () {
        this.manService.cancelExportData(this.configuration.id, this.configuration.lastExecuted.id);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], ExportDataSectionComponent.prototype, "subscriptions", void 0);
    return ExportDataSectionComponent;
}());
export { ExportDataSectionComponent };
