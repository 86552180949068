<slx-spinner [show]="isLoading">
    <div class="srt-view-schedule-main-container">
        <div class="col-xs-12">
            <h5>{{empDetails.empPayPeriod}} <b>{{empDetails.name}}</b>
                <span *ngIf="empDetails.isDifferentDepartment">
                    <b> (Different Department)</b>
                </span>
            </h5>
        </div>
        <div class="col-xs-12 srt-emp-shift-detail">
            <slx-employee-shift-editor *ngIf="rotationsSection" [employeeId]="employeeId"
                [homePositionId]="rotationsSection.homePositionId" [employeeShift]="selectedEmployeeShift"
                [homeShiftId]="rotationsSection.homeShiftId" [homeUnitId]="rotationsSection.homeUnitId"
                [isReadOnly]="true">
            </slx-employee-shift-editor>
        </div>
        <div class="col-xs-12 srt-emp-rotation-detail">
            <slx-srt-view-individual-schedule [weekDaysRibbon]="weekDaysRibbon"
                [selectedRotation]="selectedRotation"></slx-srt-view-individual-schedule>
        </div>
    </div>
    <div class="footer-btns-container">
        <button type="button" class="discard-btn" (click)="discard()">
            <i class="fas fa-times slx-button__icon" aria-hidden="true"></i>  Close
        </button>
    </div>
</slx-spinner>