import { NavigationMenuMapService } from './../../../organization/services/navigation/navigation-menu-map.service';
import { NavigationMenuItem, INavigationMenuItem } from './../../../organization/models/navigation-menu-item';
import { Meta } from './../../../core/models/api/meta';
import { ResponseBody } from './../../../core/models/api/response-body';
import { IFavoriteItem } from './../../models/favorites/favorite-item';
import { UrlParamsService } from './../../../common/services/url-params/url-params.service';
import { ApiUtilService } from './../../../common/services/api/api-util.service';
import { appConfig } from './../../../app.config';
import { configurationConfig } from './../../configuration.config';
import { Injectable } from '@angular/core';
import { FavoriteItem } from '../../models/favorites/favorite-item';
import { FavoritesMapService } from './favorites-map.service';
import { CacheType } from '../../../common/models/cache/cache-definition';
import { CacheUtilService } from './../../../common/services/cache/cache.service';

@Injectable()
export class FavoriteApiService {

  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: FavoritesMapService,
    private cacheUtilService: CacheUtilService
  ) {}

  public loadFavoriteList(updateCacheForced: boolean = false): Promise<NavigationMenuItem[]> {
    const url: string = this.getFavoritesApi();
    const request =  this.urlParamsService.createGetRequest(url);
    return this.apiUtilService.cachedRequest<INavigationMenuItem[], Meta>(request, CacheType.longTerm, updateCacheForced)
        .then((response: ResponseBody<INavigationMenuItem[], Meta>) => this.mapService.mapMenuItems(response.data));
  }

  public saveFavoriteList(list: NavigationMenuItem[]): Promise<boolean> {
    const url: string = this.getFavoritesApi();
    this.cacheUtilService.clear();
    const request =  this.urlParamsService.createPostRequest(url, this.mapService.mapMenuToFavoriteItemsDto(list));
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
  }

  private getFavoritesApi(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.favorites.root}/${configurationConfig.api.favorites.menu}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
