<slx-spinner>
  <div class="aca-export-data-section" *ngIf="configuration">
    <div class="aca-export-data-section-header">
      <div class="aca-export-data-section-header-button-left">
        <slx-collapse-button [(isCollapsed)]="configuration.isCollapsed" (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent"></slx-collapse-button>
      </div>
      <div class="aca-export-data-section-header-content">
        <div class="header-item header-title">
          <em class="fal fa-building header-title__icon regular-icon" aria-hidden="true"></em>
          <em class="fas fa-check-circle header-title__icon submitted-icon" aria-hidden="true"></em>
          <span class="header-title__text">{{configuration.name}}</span>
        </div>
        <div class="header-item">
          <div class="button-item-bar-header" *ngIf="configuration.lastExecuted && !isExpired">
            <div *ngIf="configuration.lastExecuted.status === exportDataStatus.Waiting" class="ed-grey">
              <em class="fas fa-clock icon" aria-hidden="true"></em>
              <span>Waiting</span>
            </div>
            <div *ngIf="configuration.lastExecuted.status === exportDataStatus.Completed || configuration.lastExecuted.status === exportDataStatus.Expired" class="ed-green">
              <em class="fas fa-check-circle icon" aria-hidden="true"></em>
              <span>Completed</span>
            </div>
            <div *ngIf="configuration.lastExecuted.status === exportDataStatus.InProgress" class="ed-blue">
              <em class="fas fa-spinner fa-pulse icon" aria-hidden="true"></em>
              <span>In progress</span>
            </div>
            <div *ngIf="configuration.lastExecuted.status == exportDataStatus.Failed" class="ed-red">
              <em class="fas fa-times-circle icon" aria-hidden="true"></em>
              <span>Failed</span>
            </div>
            <div *ngIf="configuration.lastExecuted.status == exportDataStatus.Cancelled" class="ed-red">
              <em class="fas fa-times-circle icon" aria-hidden="true"></em>
              <span>Cancelled</span>
            </div>
          </div>

        </div>
      </div>
      <div class="aca-export-data-section-header-button-right">
        <slx-collapse-button [(isCollapsed)]="configuration.isCollapsed" (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent"></slx-collapse-button>
      </div>
    </div>
    <div #collapseContent class="aca-export-data-section-content">
      <div class="aca-export-data-section-toolbar">
        <div class="aca-export-data-section-toolbar-parameters">

          <div *ngIf="configuration?.exportType != 3" class="slx-toolbar-section year-width">
            <span class="aca-export-data-parameter_label">Year</span>
            <slx-dropdown-list name="ddlYear"
                               class="slx-wide slx-short"
                               [valuePrimitive]="true"
                               [(ngModel)]="configuration.exportParams.year"
                               (ngModelChange)="onYearChange($event)"
                               [options]="yearOptions">
            </slx-dropdown-list>
          </div>

          <div *ngIf="configuration?.exportType == 3" class="slx-toolbar-section year-width">
            <span class="aca-export-data-parameter_label">Year</span>
            <slx-dropdown-list name="ddlYear"
                               class="slx-wide slx-short"
                               [valuePrimitive]="true"
                               [(ngModel)]="configuration.exportParams.year"
                               (ngModelChange)="onYearChange($event)"
                               [options]="yearOptionsTestXml">
            </slx-dropdown-list>
          </div>

          <div class="slx-toolbar-section emp-width">
            <span class="aca-export-data-parameter_label">Employer</span>
            <slx-dropdown-list name="ddlEmployers"
                               #employers="ngModel"
                               class="slx-wide slx-short"
                               titleField="legalName"
                               valueField="companyId"
                               [(ngModel)]="selectedEmployer"
                               (ngModelChange)="onEmployersChanged($event)"
                               [required]="true"
                               [options]="employersLookup">
            </slx-dropdown-list>
            <div *ngIf="employers.errors" class="slx-error-block pull-left">
              <span *ngIf="employers.errors.required" errorMessage for="required"></span>
            </div>
          </div>

          <div *ngIf="!configuration?.exportParams.hidden" class="slx-toolbar-section qoffer-width">
            <span class="aca-export-data-parameter_label">Qualified Offer Method</span>
            <slx-dropdown-list name="ddQualifiedOffer"
                               class="slx-wide slx-short"
                               [valuePrimitive]="true"
                               [(ngModel)]="configuration.exportParams.qualifiedOfferMethod"
                               [options]="qualifiedOfferOptions">
            </slx-dropdown-list>
          </div>

          <div *ngIf="!configuration?.exportParams.hidden" class="slx-toolbar-section offerMethod">
            <span class="aca-export-data-parameter_label">98% Offer Method</span>
            <slx-dropdown-list name="ddlNintyOfferMethod"
                               class="slx-wide slx-short"
                               [valuePrimitive]="true"
                               [(ngModel)]="configuration.exportParams.offerMethod98"
                               [options]="nintyEightOfferOptions">
            </slx-dropdown-list>
          </div>

          <div *ngIf="!configuration?.exportParams.hidden" class="slx-toolbar-section mem-alegroup-width">
            <span class="aca-export-data-parameter_label">Member of ALE Group</span>
            <slx-multiselect class="slx-wide slx-short"
                             [options]="monthOptions"
                             titleField="name"
                             valueField="id"
                             label="Months"
                             [valuePrimitive]="false"
                             placeholder="Select Months"
                             [externalPlaceholder]="false"
                             [(ngModel)]="configuration.exportParams.memberOfAleGroup"
                             name="memberAleGroup">
            </slx-multiselect>
          </div>

          <div *ngIf="!configuration?.exportParams.hidden" class="slx-toolbar-section mec-offered-width">
            <span class="aca-export-data-parameter_label">Offered MEC to 95% of FT Employees and Dependents</span>
            <slx-multiselect class="slx-wide slx-short"
                             [options]="monthOptions"
                             titleField="name"
                             valueField="id"
                             [valuePrimitive]="false"
                             label="Months"
                             placeholder="Select Months"
                             [externalPlaceholder]="false"
                             [(ngModel)]="configuration.exportParams.offeredMec"
                             name="offeredMec">
            </slx-multiselect>
          </div>
          <div *ngIf="configuration?.exportType == 4" class="slx-toolbar-section qoffer-width">
            <span class="aca-export-data-parameter_label">Replacement?</span>
            <slx-dropdown-list name="ddlReplacement"
                               class="slx-wide slx-short"
                               [valuePrimitive]="true"
                               [(ngModel)]="configuration.exportParams.replacement"
                               (ngModelChange)="onReplacementChange()"
                               [options]="replacementOptions">
            </slx-dropdown-list>
          </div>
          <div *ngIf="configuration?.exportType == 4" class="slx-toolbar-section receipt-width">
            <slx-input-decorator>
              <input slx-input class="slx-wide slx-short slx-rule-input" type="text" slx-aca-receiptid [textMask]="receiptIdMaskConf" [(ngModel)]="configuration.exportParams.receiptId" [disabled]="configuration.exportParams.replacement==='No'" maxlength="225"
                     placeholder="Receipt Id"
                     name="receiptId_{{configuration.exportParams.id}}">
              <span errorMessage for="required"></span>
              <span errorMessage for="acaReceiptId"></span>
            </slx-input-decorator>
          </div>
          <div *ngIf="aleMembersInValid">
            <span class="slx-error-block">Your employer settings show that this employer is a member of an aggregated ALE group. Please indicate which months this employer was a member before proceeding.</span>
            <span errorMessage for="acaReceiptId"></span>
          </div>
        </div>
        <div class="aca-export-data-section-toolbar-actions">
          
          <button class="slx-button slx-blue slx-margin-r btn-margin-t" type="button" *ngIf="canGenerate" [disabled]="!isFormValid" (click)="generate()">
            <span class="slx-button__text" *ngIf="isFormValid">Generate</span>
            <span class="slx-button__text" *ngIf="!isFormValid" [slxTooltip]="generateBtnTooltip()" tipPosition="left">Generate</span>
          </button>
          <button class="slx-button slx-blue slx-margin-r btn-margin-t" type="button" *ngIf="canCancel" (click)="cancelExecution()">
            <span class="slx-button__text">Cancel</span>
          </button>
        </div>
      </div>

      <slx-aca-export-history [configuration]="configuration">
      </slx-aca-export-history>
    </div>
  </div>
</slx-spinner>
