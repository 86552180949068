export interface IPBJExportChildResult {
     exportID: number;
     organizationName: number;
     exportStatus: string;
     exportProgress: number;
     processStartDate: Date;
     processEndDate: Date;
     processingTime: number;
     details:string;
}


export class PBJExportChildResult {
    public exportID: number;
    public organizationName: number;
    public exportStatus: string;
    public exportProgress: number;
    public processStartDate: Date;
    public processEndDate: Date;
    public processingTime: number;
    public details:string;
}

