<div class="modal-content-resizable">
  <div class="modal-body">
    <div class="bootbox-body">
      <h4>
        {{changesOptions.questionMessage}}
      </h4>
      <h4>
        {{changesOptions.warningMessage}}
      </h4>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="onOk()" class="theme-button-cancel margin-r">{{changesOptions.continueButtonText}}</button>
    <button type="button" (click)="onCancel()" class="theme-button-apply">{{changesOptions.cancelButtonText}}</button>
  </div>
</div>
