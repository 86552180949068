<slx-spinner [show]="!state.isInitialized">
  <form novalidate #resetPasswordForm="ngForm" *ngIf="!state.hasSuccedeed && !state.hasFailed">
    <div class="col-xs-12 title">
      Enter your username below and select Reset Password.
    </div>

    <div class="col-xs-12 row-control login">
      <input slx-input type="text" placeholder="Username@Alias" id="username" name="username" [(ngModel)]="username"
        #usernameControl="ngModel" required tabindex="1" [disabled]="state.isLoading">
      <div class="slx-error-block" *ngIf="usernameControl.invalid && usernameControl.dirty">
        <span errorMessage for="required">Username is required</span>
      </div>
    </div>

    <div class="col-xs-12 alerts-area row-control" *ngIf="state.userNotFound">
      <div class="alert alert-danger alert-dismissible">
        <button class="close" (click)="hideUserNotFoundErrorMessage()"><span aria-hidden="true">&times;</span></button>
        <span>The user was not found.</span>
      </div>
    </div>

    <div class="col-xs-12 alerts-area row-control" *ngIf="state.isUnknownError">
      <div class="alert alert-danger alert-dismissible">
        <button class="close" (click)="hideUnknownErrorMessage()"><span aria-hidden="true">&times;</span></button>
        <span>Unexpected error, please try again or contact support.</span>
      </div>
    </div>

    <div class="col-xs-12 alerts-area row-control" *ngIf="state.isValidationError">
      <div class="alert alert-danger alert-dismissible">
        <button class="close" (click)="hideValidationErrorMessage()"><span aria-hidden="true">&times;</span></button>
        <span>There was an error validating the captcha, please try again.</span>
      </div>
    </div>

    <div class="col-xs-12 row-control recaptcha-container">
      <re-captcha *ngIf="siteKey" siteKey="{{ siteKey }}" name="recaptcha" [(ngModel)]="recaptcha" required></re-captcha>
    </div>

    <div class="col-xs-12 row-control actions">
      <button type="button" class="theme-button-cancel" routerLink="/login" tabindex="3" [disabled]="state.isLoading">
        Cancel
      </button>

      <button type="submit" class="theme-button-apply reset-password-button" [disabled]="!resetPasswordForm.form.valid || state.isLoading" (click)="onResetPasswordClicked()" tabindex="2">
        <i class="fal fa-spinner-third fa-spin" *ngIf="state.isLoading" aria-hidden="true"></i>
        Reset Password
      </button>
    </div>
  </form>

  <div *ngIf="state.hasSuccedeed && !state.hasFailed">
    <div class="col-xs-12 title">
      An e-mail has been sent to <strong>{{ email }}</strong> with instructions for resetting your password.
    </div>

    <div class="col-xs-12 row-control additional">
      If you haven't received an e-mail within 15 minutes contact your Administrator for further assistance.
    </div>

    <div class="col-xs-12 row-control actions">
      <button class="theme-button-apply" routerLink="/login" tabindex="1">
        Login
      </button>
    </div>
  </div>

  <div *ngIf="state.hasFailed && !state.hasSuccedeed">
    <div class="col-xs-12 title">
      <em>We're sorry, we couldn't find an e-mail address associated with your account.</em>
    </div>

    <div class="col-xs-12 row-control title">
      For security reasons, your password reset request has been forwarded to <span *ngIf="admins && admins.length === 1"><em>{{ admins }}</em>, an Administrator</span><span *ngIf="admins && admins.length > 1"><em>{{ admins.join(', ') }}, </em>Administrators</span> at your building, to complete your password reset.
    </div>

    <div class="col-xs-12 row-control additional">
      If you haven't received a password reset e-mail please contact an Administrator for assistance.
    </div>

    <div class="col-xs-12 row-control login-button">
      <button class="theme-button-apply" routerLink="/login" tabindex="1">
        Login
      </button>
    </div>
  </div>
</slx-spinner>
