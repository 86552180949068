import { OrgLevelFlat } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';

export class OrgLevelRecordWrapper {
    public selectable: boolean = true;
    public isSelected: boolean;
    public depth: number = 0;
    public orgLevel: OrgLevelFlat;
}

