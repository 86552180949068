import { RouterModule } from '@angular/router';
import { schedulerRoutes } from './portal.scheduler.routes';
import { timeRoutes } from './portal.time.routes';
import { reportsRoutes } from './portal.reports.routes';
import { pbjRoutes } from './portal.pbj.routes';
import { logiRoutes } from './portal.logi.routes';
import { hrRoutes } from './portal.hr.routes';
import { globalRoutes } from './portal.global.routes';
import { appConfig } from '../../app/app.config';
import { accrualsRoutes } from './portal.accruals.routes';
import { PortalComponent, ApplicationsDashboardComponent, ApplicationComponent, HeaderComponent, LeftSidebarComponent, } from './components/index';
import { AuthenticationGuard } from '../authentication/services/index';
import { CanLeaveGuard, ComponentStateGuard } from '../common/services/index';
import { AccessibleGuard } from '../organization/services/index';
import { SsoGuard } from '../authentication/services/index';
import { intgRoutes } from './portal.intg.routes';
export var menuRoutes = [];
var ɵ0 = { showSecondLine: true };
export var routes = [
    {
        path: 'apps',
        canActivate: [SsoGuard, AuthenticationGuard, AccessibleGuard],
        canActivateChild: [AuthenticationGuard, AccessibleGuard, ComponentStateGuard],
        canDeactivate: [CanLeaveGuard],
        component: PortalComponent,
        children: [
            {
                path: 'scheduler',
                component: ApplicationComponent,
                children: schedulerRoutes.slice()
            },
            {
                path: 'time',
                component: ApplicationComponent,
                children: timeRoutes.slice()
            },
            {
                path: 'payroll',
                component: ApplicationComponent,
                children: schedulerRoutes.slice()
            },
            {
                path: 'inservice',
                component: ApplicationComponent,
                children: schedulerRoutes.slice()
            },
            {
                path: 'cpcs',
                component: ApplicationComponent,
                children: schedulerRoutes.slice()
            },
            {
                path: 'directory',
                component: ApplicationComponent,
                children: schedulerRoutes.slice()
            },
            {
                path: 'reports',
                component: ApplicationComponent,
                children: reportsRoutes.slice()
            },
            {
                path: 'accruals',
                component: ApplicationComponent,
                children: accrualsRoutes.slice()
            },
            {
                path: 'aca',
                component: ApplicationComponent,
                children: schedulerRoutes.slice()
            },
            {
                path: 'pbj',
                component: ApplicationComponent,
                children: pbjRoutes.slice()
            },
            {
                path: 'bi',
                component: ApplicationComponent,
                children: logiRoutes.slice()
            },
            {
                path: 'ngp',
                component: ApplicationComponent,
                children: schedulerRoutes.slice()
            },
            {
                path: 'hr',
                component: ApplicationComponent,
                children: hrRoutes.slice()
            },
            {
                path: 'intg',
                component: ApplicationComponent,
                children: intgRoutes.slice()
            },
            {
                path: appConfig.globalMenuItemsApplication.link,
                component: ApplicationComponent,
                children: globalRoutes.slice()
            },
            {
                path: '',
                component: ApplicationsDashboardComponent,
                data: ɵ0
            },
            {
                path: '',
                outlet: 'leftSidebar',
                component: LeftSidebarComponent
            },
            {
                path: '',
                outlet: 'header',
                component: HeaderComponent
            }
            /*,
            {
              path: '',
              outlet: 'footer',
              component: FooterComponent
            }*/
        ]
    }
];
export var routesModule = RouterModule.forChild(routes);
export { ɵ0 };
