import { Component, OnInit } from '@angular/core';
import { LegendLabelsContentArgs, SeriesClickEvent } from '@progress/kendo-angular-charts';
import { IntlService } from '@progress/kendo-angular-intl';
import { WFMDashBoardDrillDownModel, WFMDashBoardModel, data_Colors } from '../../../../models/wfm-sync';
import { WfmSyncService } from '../../../../services/wfm/wfm-sync.service';
import * as _ from 'lodash';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
@Component({
  selector: 'slx-benefit-dashboard-migration',
  templateUrl: './benefit-dashboard-migration.component.html',
  styleUrls: ['./benefit-dashboard-migration.component.scss']
})
export class BenefitDashboardMigrationComponent implements OnInit {
  autofit: any;
  public gridData: WFMDashBoardModel[] = [];
  public data: WFMDashBoardModel[] = [];
  public TotalCount: number;
  public sync: number;
  public percent: number;
  public Status: string;
  public opened = false;
  public StatusMessage: string;
  public dataView: WFMDashBoardDrillDownModel[] = [];
  public pieData: WFMDashBoardModel[] = [];
  public isLoading:boolean=false;
  public color:string;
  constructor(private intl: IntlService, private wfmService: WfmSyncService) {
    this.isLoading = true;
    this.labelContent = this.labelContent.bind(this);
    this.allData = this.allData.bind(this); 
  }
  ngOnInit() {
    this.res();
    this.isLoading = false;
  }

  public labelContent(args: WFMDashBoardModel): string {
    this.res();
    const result = args.totalCount + ' share: \n' + args.statusMessage;
    return result;
  }

  public res() {
    this.isLoading = true;
    this.wfmService.syncDashboard('migration', 'Benefit').then((value2: any) => {
      this.gridData =data_Colors (value2.data);
      this.color=value2.color;
      this.data = value2.totalCount;
      this.TotalCount = value2.data[0].totalCount;
      this.percentage();
      this.isLoading = false;
    });
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  public percentage() {
    _.map(this.gridData, (item) => {
      if (item.statusMessage == 'SUCCESS') {
        item.percentage = (item.statusCount / item.totalCount) * 100;
        this.sync = item.percentage;
        this.percent = this.sync;
      }
    });
    const round = (value: number): number => {
      return Math.round(100 * value) / 100;
    };
    if (this.sync == null || this.sync == undefined) {
      this.sync = 0;
    } else this.sync = round(this.percent);
  }

  public onClick(event: SeriesClickEvent) {
    this.opened = true;
    this.dataView =[];
      this.StatusMessage = '';
    this.wfmService.syncPostDashboard('migration', 'Benefit', event.category).then((value2: any) => {
      this.dataView = value2.data;
      this.StatusMessage = event.category;
    });
  }

  public close(status: string): void {
    this.opened = false;
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataView, {
        sort: [{ field: 'payRollNumber', dir: 'asc' }],
      }).data
    };

    return result;
  }
}
