export class TimecardSummaryRequest {
    public organizationId: number;
    public startDate: Date;
    public endDate: Date;
    public departmentIds: number[];
    public positionIds: number[];
    public payPolicyNames: string[];
    public employeeTypeNames: string[];
    public shiftDifferentialPolicyNames: string[];
}

export interface ITimecardSummaryRequest {
    organizationId: number;
    startDate: string;
    endDate: string;
    departmentIds: number[];
    positionIds: number[];
    payPolicyNames: string[];
    employeeTypeNames: string[];
    shiftDifferentialPolicyNames: string[];
}
