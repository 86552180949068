import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnDestroy, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Actions } from '../../../../core/models/index';
import { EditableListComponent } from '../../../../configuration/components/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { DailyAbsence, TimeCardModel, TimeCardDisplayOptions } from '../../../models/index';
import { DailyTimecardManagementService, editAction, resetAction } from '../../../services/index';
import { NotificationsService } from '../../../../core/components/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { ChangeManagementService, ModalService } from '../../../../common/services/index';

import { TaAbsenceCode, Shift } from '../../../../organization/models/index';
import { process, State } from '@progress/kendo-data-query';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { dateTimeUtils } from '../../../../common/utils/index';
import { TimecardAbsencesEditorDialogComponent } from '../timecard-absences-editor-dialog/timecard-absences-editor-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'slx-timecard-absences-list',
  templateUrl: 'timecard-absences-list.component.html',
  styleUrls: ['timecard-absences-list.component.scss'],
})
export class TimeCardAbsencesListComponent implements OnInit, OnDestroy {

  @Input()
  public set model(value: TimeCardModel) {
    if (!value) {
      return;
    }
    this.m_model = value;
    this.records = value.absences;
    this.refreshGrid();
  }
  public get model(): TimeCardModel {
    return this.m_model;
  }
  public records: DailyAbsence[];
  public recordsToShow: DailyAbsence[];
  public appConfig: IApplicationConfig;
  public canEditTimecard: boolean;
  public displayOptions: TimeCardDisplayOptions;
  public absCount: number = 0;
  private m_model: TimeCardModel;
  private changeGroup: string = 'TimeCardAbsencesListComponent';
  private isIncludeDeleted: boolean;

  @ViewChild('absencesForm', { static: true })
  private absencesForm: FormGroup;

  @unsubscribe()
  private modelSubscription: Subscription;

  @unsubscribe()
  private discardSubscription: Subscription;

  @unsubscribe()
  private editSubscription: Subscription;

  @unsubscribe()
  private resetSubscription: Subscription;

  @unsubscribe()
  private canEditChangedSubscription: Subscription;
  @unsubscribe()
  private optionsChangedSubscription: Subscription;
  @unsubscribe()
  private abscenceCountSubscription: Subscription;

  constructor(
    private managementService: DailyTimecardManagementService,
    private notificationsService: NotificationsService,
    private changeManagementService: ChangeManagementService, private modalService: ModalService) {
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.resetSubscription = this.managementService.resetSection$.filter((m: resetAction) => m.target === 'absences').subscribe((m: resetAction) => {
      this.records = m.model.absences;
      this.refreshGrid();
    });

    this.canEditChangedSubscription = this.managementService.canEditChanged$
      .subscribe((canEdit: boolean) => {
        this.canEditTimecard = canEdit;
      });

    this.optionsChangedSubscription = this.managementService.displayOptionSelected$
      .subscribe((options: TimeCardDisplayOptions) => {
        this.displayOptions = options;
      });

    this.abscenceCountSubscription = this.managementService.abscenceCount$.subscribe((v: number) => {
      this.absCount = v;
    });

  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public addAbsence(): void {
    if(!this.canEditTimecard) {
      this.modalService.globalAnchor.openInfoDialog(`Warning`, 'Absences can\'t be added');
      return;
    }
    let absence: DailyAbsence = new DailyAbsence();
    absence.start = this.model.date;
    absence.end = this.model.date;
    absence.interval = 0;
    absence.calculateInterval();
    TimecardAbsencesEditorDialogComponent.openDialog('Add new absence', absence, this.modalService, this.managementService, (result: boolean, item: DailyAbsence) => {
      if (result) {
        this.records.push(absence);
        this.changeManagementService.changeNotify(this.changeGroup);
        this.refreshGrid();
        this.absCount = this.recordsToShow.length;
        this.managementService.updateAbscenseCount(this.absCount);
      }
    });
  }
  public editHandler(rowIndex: number, dataItem: DailyAbsence): void {
    let itemToEdit = _.cloneDeep(dataItem);
    TimecardAbsencesEditorDialogComponent.openDialog('Edit absence', itemToEdit, this.modalService, this.managementService, (result: boolean, item: DailyAbsence) => {
      if (result) {
        _.merge(dataItem, itemToEdit);
        this.changeManagementService.changeNotify(this.changeGroup);
        this.refreshGrid();
      }
    });
  }

  public removeHandler(rowIndex: number, dataItem: DailyAbsence): void {
    if (!dataItem) {
      return;
    }
    if (dataItem.isServerSide) {
      dataItem.isDeleted = true;
    } else {
      this.records.splice(rowIndex, 1);
    }
    this.refreshGrid();
    this.absCount = this.recordsToShow.length;
    this.managementService.updateAbscenseCount(this.absCount);
  }

  public discardChanges(): void {
    this.changeManagementService.clearChanges(this.changeGroup);
    this.managementService.onAbsenceDiscardAction();
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.recordsToShow = null;
      return;
    }
    this.recordsToShow = _.filter(this.records, (item: DailyAbsence) => !item.isDeleted);
  }


}
