import { Component, Provider } from '@angular/core';
import { DialogOptions } from '../../models/index';
import { ModalService } from '../../services/modal/modal.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ChangeManagementService } from '../../services';

export class ConfirmOptions {
  public showOK: boolean;
  public showCancel: boolean;
  public buttonOKtext: string;
  public buttonCanceltext: string;
  public className: string;
  public width: number;
  public height: number;
  public notes: string;
  public isWarningIcon?: boolean;
  public isShiftGroup?: boolean;
  public orgLevelId?: number;
}

@Component({
  moduleId: module.id,
  selector: 'slx-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  public options: DialogOptions;
  public dialogResult: boolean;
  public confirmOptions: ConfirmOptions;
  private modalService: ModalService;
  public get okButtonText(): string {
    if (!this.confirmOptions || !this.confirmOptions.buttonOKtext) {
      return 'OK';
    }
    return this.confirmOptions.buttonOKtext;
  }
  public get cancelButtonText(): string {
    if (!this.confirmOptions || !this.confirmOptions.buttonCanceltext) {
      return 'Cancel';
    }
    return this.confirmOptions.buttonCanceltext;
  }


  public static openDialog(
    title: string,
    message: string,
    modalService: ModalService,
    callback: (result: boolean) => void, options?: ConfirmOptions): ConfirmDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.message = message;
    let confirmOptions: ConfirmOptions = options;
    if (!options) {
      confirmOptions = new ConfirmOptions();
      confirmOptions.showOK = true;
      confirmOptions.showCancel = true;
      confirmOptions.isShiftGroup = false;
    }
    dialogOptions.width = confirmOptions.width || 400;
    dialogOptions.height = confirmOptions.height || 250;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ConfirmOptions,
        useValue: confirmOptions
      }
    ];
    let dialog: ConfirmDialogComponent = modalService.globalAnchor
      .openDialog(ConfirmDialogComponent, title, dialogOptions, resolvedProviders, callback);
    return dialog;
  }

  constructor(options: DialogOptions, modalService: ModalService, confirmOptions: ConfirmOptions, private router: Router) {
    this.options = options;
    this.modalService = modalService;
    this.confirmOptions = confirmOptions;
    this.dialogResult = false;
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this.dialogResult = false;
        if(this.options.windowUniqueId) {
          this.modalService.closeWindow(this.options.windowUniqueId);
        }
      }
    });
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public getRouterLink() {
    this.router.navigateByUrl(`apps/common/configure_shifts?orgLevelId= + ${this.confirmOptions.orgLevelId}`);
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
