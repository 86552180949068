<div class="badge-container">
    <span class="container-child badge-title">Your Rating</span>
    <span class="container-child badge-note">This value is approximation based on a combination of current and historical staffing hours</span>
    <slx-star-rating [readonly]="true"
        [rating]="model.rating.value"
        [maxValue]="model.rating.maxValue"
        [filledItemClassName]="'full-yellow-big-star'"
        [emptyItemClassName]="'far empty-grey-big-star'"
        class="container-child"
    ></slx-star-rating>
    <div class="container-child badge-bottom-bar">
        <button *ngIf="showDetails" class="slx-button slx-with-icon show-details-btn" (click)="onShowDetails()">Show Details </button>
    </div>
</div>
