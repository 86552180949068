import { IBenefitDetailsLine, BenefitDetailsLine } from './benefit-details-line';
import { IBenefitDetailsBasic, BenefitDetailsBasic } from './benefit-details-basic';
import { BenefitDetailsCalcMethod } from './benefit-details-calc-method';
import { IBenefitDetailsTier, BenefitDetailsTier } from './benefit-details-tier';
import { IBenefitPayrollDeduction, BenefitPayrollDeduction } from './benefit-details-payroll-deduction';

export interface IBenefitDetailsLineStandart {
  lineDefinition: IBenefitDetailsLine;
  isDeleted: boolean;
  modifiedAt: string;
  modifiedBy: string;
  addedAt: string;
  addedBy: string;
  payrollDeductionDate: string;
  childAgeLimit: number;
  includeInReport: boolean;
  includeInEoiReport: boolean;
  costFreq: IBenefitDetailsBasic<number, string>;
  empContFreq: IBenefitDetailsBasic<number, string>;
  calcMethod: IBenefitDetailsBasic<string, string>;
  benefitTiers: IBenefitDetailsTier[];
  mappedDeduction: IBenefitPayrollDeduction;
  enrollmentCount: number;
}

export class BenefitDetailsLineStandart {
  public line = new BenefitDetailsLine();
  public isDeleted: boolean;
  public modifiedAt: Date;
  public modifiedBy: string;
  public addedAt: Date;
  public addedBy: string;
  public payrollDeductionDate: Date;
  public childAgeLimit: number;
  public includeInReport: boolean;
  public includeInEoiReport: boolean;
  public costFreq: BenefitDetailsBasic<number, string>;
  public empContFreq: BenefitDetailsBasic<number, string>;
  public calcMethod: BenefitDetailsCalcMethod;
  public tiers: BenefitDetailsTier[] = [];
  public selectedTier?: any;
  public mappedDeduction: BenefitPayrollDeduction;
  public enrollmentCount: number;

  public get id(): number {
    return this.line.id;
  }

  public get name(): string {
    return this.line.name;
  }
}
