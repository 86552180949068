import { StringUtils } from '../../../framework/index';
var EmployeeSection = /** @class */ (function () {
    function EmployeeSection() {
    }
    Object.defineProperty(EmployeeSection.prototype, "anchor", {
        get: function () {
            return StringUtils.format('#{0}', this.id);
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeSection;
}());
export { EmployeeSection };
