import { Component, OnInit, OnDestroy } from '@angular/core';
import { AcaMeasurementManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../core/decorators';
import { Subscription } from 'rxjs';

import { AcaMeasurementInsights, AcaMeasurementRecord, AcaMeasurement, filterAcaMeasurementRecord } from '../../../models';
import * as moment from 'moment';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-aca-measurement-insights',
  templateUrl: 'aca-measurement-insights.component.html',
  styleUrls: ['aca-measurement-insights.component.scss']
})
export class AcaMeasurementInsightsComponent implements OnInit, OnDestroy {

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public insightsRecord: AcaMeasurementInsights;
  public acaFilter = new filterAcaMeasurementRecord();
  public isInsightVisible: boolean = false;
  public insightHeading: string = 'ACA Insights';
  private currentYear: number = moment().startOf('day').toDate().getFullYear();

  public buttonNum: number = 0;

  constructor(private managementService: AcaMeasurementManagementService) { }

  public ngOnInit() {
    this.subscriptions.onLoaded = this.managementService.subscribeToLoadedInsights((insightsContainer: AcaMeasurementInsights) => {
      this.insightsRecord = insightsContainer;
    });

    this.subscriptions.state = this.managementService.subscribeToCurrentYear((year: number) => {
      if (this.currentYear == year) {
        this.isInsightVisible = true;
        this.buttonNum = 0;
      }
      else {
        this.isInsightVisible = false;
      }
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }


  public filterAcaMeasurementDetails(isCurrentMonth: boolean, isMP: boolean, type: string, btnNum: number): void {
    if (_.isEqual(this.buttonNum, btnNum)) {
      btnNum = 0;
    }
    this.buttonNum = btnNum;
    this.acaFilter.isClicked = btnNum!=0?true:false;
    this.acaFilter.isCurrentMonth = isCurrentMonth;
    this.acaFilter.isMP = isMP;
    this.acaFilter.type = type;
    this.managementService.setInsightsFilter(this.acaFilter);
  }
}
