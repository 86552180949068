<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box schedule-console">
    <div class="slx-content-toolbar-indents header">
      <slx-schedule-console-toolbar
        [consoleConfig]="consoleConfig"
        [selectedRangeType]="selectedRangeType"
        [startOfWeek]="startOfWeek"
        [selectedDate]="selectedDate"
        [filtersList]="filtersList"
        [groupBy]="groupBy"
        [groupByList]="groupByList"
        [orgLevel]="orgLevel"
        [charts]="charts"
        [shownChart]="shownChart"
        (onFilterChange)="onFilterChange($event)"
        (onConfigChange)="onConfigChange($event)"
        (onGrouppingChange)="onGrouppingChange($event)"
        (onDateRangeChange)="onDateRangeChange($event)"
        (onDateRangeTypeChange)="onDateRangeTypeChange($event)"
        (onShownChartChange)="onShownChartChange($event)"
      ></slx-schedule-console-toolbar>
    </div>
    <div class="slx-high-box__body slx-main-content-indents slx-flex">
      <div class="content" slxElementCreatedSelector (onCreated)="groupContainerCreated($event)">     
            <slx-collapsible-section title="Overview - {{ orgLevel?.name }}" [expanded]="restoreExpanded(generateStaticUniqId(orgLevel, true), true)" mode="blue" 
            (expandChanged)="onSectionExpandChanged($event)"
            [uniqId]="generateStaticUniqId(orgLevel, true)"  *ngIf="!isPositionData"> 
            <ng-container body> 
              <slx-schedule-console-overview
                [consoleConfig]="consoleConfig"
                [consoleData]="scheduleConsoleData"
                [rangeType]="selectedRangeType"
              ></slx-schedule-console-overview>
            </ng-container>
            </slx-collapsible-section>
     
        <ng-container *ngIf="isGroupedByUnit">
          <slx-collapsible-section *ngFor="let unit of scheduleConsoleData?.groupByUnit"
              [uniqId]="generateDynamicUniqId([unit])" (expandChanged)="onSectionExpandChanged($event)"
              [ngClass]="{ 'hidden': unit.hidden }" [title]="unit.name" [expanded]="restoreExpanded(generateDynamicUniqId([unit]), true)" mode="blue">
            <slx-collapsible-section body *ngFor="let shift of unit.values" [ngClass]="{ 'hidden': shift.hidden }"
              [title]="shift.name" [expanded]="restoreExpanded(generateDynamicUniqId([unit, shift]))" mode="gray"
              [uniqId]="generateDynamicUniqId([unit, shift])" (expandChanged)="onSectionExpandChanged($event)">
              <ng-container body *ngIf="!isDayRange">
                <slx-collapsible-section *ngFor="let position of shift.values" [ngClass]="{ 'hidden': position.hidden }"
                  [title]="position.name" [expanded]="restoreExpanded(generateDynamicUniqId([unit, shift, position]))" mode="white"
                  [uniqId]="generateDynamicUniqId([unit, shift, position])" (expandChanged)="onSectionExpandChanged($event)">
                  <slx-schedule-console-weekly-indicator header [days]="getWeek(position.values)" class="schedule-console__weekly"></slx-schedule-console-weekly-indicator>
                  <div body class="schedule-console__charts">
                    <slx-schedule-console-graphic-comparison
                      class="schedule-console__chart wide"
                      [comparisons]="position.values"
                      [comparisonName]="position.name"
                      [consoleConfig]="consoleConfig"
                      [chart]="shownChart"
                      [rangeType]="selectedRangeType"
                      (onNeedScroll)="scrollToGraphicComparison($event)"
                    ></slx-schedule-console-graphic-comparison>
                  </div>
                </slx-collapsible-section>
              </ng-container>
              <div body *ngIf="isDayRange" class="schedule-console__charts wrap">
                <slx-schedule-console-graphic-comparison
                  class="schedule-console__chart padding-bottom"
                  *ngFor="let position of shift.values"
                  [ngClass]="{ 'hidden': position.hidden }"
                  [comparisons]="position.values"
                  [comparisonName]="position.name"
                  [consoleConfig]="consoleConfig"
                  [chart]="shownChart"
                  [rangeType]="selectedRangeType"
                  (onNeedScroll)="scrollToGraphicComparison($event)"
                ></slx-schedule-console-graphic-comparison>
              </div>
            </slx-collapsible-section>
          </slx-collapsible-section>
        </ng-container>

        <ng-container *ngIf="isGroupedByShiftGroup">
          <slx-collapsible-section *ngFor="let shift of scheduleConsoleData?.groupByShift" [ngClass]="{ 'hidden': shift.hidden }"
            [title]="shift.name" [expanded]="restoreExpanded(generateDynamicUniqId([shift]), true)" mode="blue"
            [uniqId]="generateDynamicUniqId([shift])" (expandChanged)="onSectionExpandChanged($event)">
            <slx-collapsible-section body *ngFor="let unit of shift.values" [ngClass]="{ 'hidden': unit.hidden }" [title]="unit.name"
              [expanded]="restoreExpanded(generateDynamicUniqId([shift, unit]))" mode="gray"
              [uniqId]="generateDynamicUniqId([shift, unit])" (expandChanged)="onSectionExpandChanged($event)">
              <ng-container body *ngIf="!isDayRange">
                <slx-collapsible-section *ngFor="let position of unit.values" [ngClass]="{ 'hidden': position.hidden }"
                  [title]="position.name" [expanded]="restoreExpanded(generateDynamicUniqId([shift, unit, position]))" mode="white"
                  [uniqId]="generateDynamicUniqId([shift, unit, position])" (expandChanged)="onSectionExpandChanged($event)">
                  <slx-schedule-console-weekly-indicator header [days]="getWeek(position.values)" class="schedule-console__weekly"></slx-schedule-console-weekly-indicator>
                  <div body class="schedule-console__charts">
                    <slx-schedule-console-graphic-comparison
                      class="schedule-console__chart wide"
                      [comparisons]="position.values"
                      [comparisonName]="position.name"
                      [consoleConfig]="consoleConfig"
                      [chart]="shownChart"
                      [rangeType]="selectedRangeType"
                      (onNeedScroll)="scrollToGraphicComparison($event)"
                    ></slx-schedule-console-graphic-comparison>
                  </div>
                </slx-collapsible-section>
              </ng-container>
              <div body *ngIf="isDayRange" class="schedule-console__charts wrap">
                <slx-schedule-console-graphic-comparison
                  class="schedule-console__chart padding-bottom"
                  *ngFor="let position of unit.values"
                  [ngClass]="{ 'hidden': position.hidden }"
                  [comparisons]="position.values"
                  [comparisonName]="position.name"
                  [consoleConfig]="consoleConfig"
                  [chart]="shownChart"
                  [rangeType]="selectedRangeType"
                  (onNeedScroll)="scrollToGraphicComparison($event)"
                ></slx-schedule-console-graphic-comparison>
              </div>
            </slx-collapsible-section>
          </slx-collapsible-section>
        </ng-container>

        <ng-container *ngIf="isDisabledGrouping">
          <slx-collapsible-section *ngFor="let position of scheduleConsoleData?.groupByPosition" [ngClass]="{ 'hidden': position.hidden }"
            [title]="position.name" [expanded]="restoreExpanded(generateDynamicUniqId([position]), true)" mode="blue"
            [uniqId]="generateDynamicUniqId([position])" (expandChanged)="onSectionExpandChanged($event)">
            <div body class="schedule-console__charts">
              <slx-schedule-console-graphic-comparison
                class="schedule-console__chart wide"
                [comparisons]="position.values"
                [comparisonName]="position.name"
                [consoleConfig]="consoleConfig"
                [chart]="shownChart"
                [rangeType]="selectedRangeType"
                (onNeedScroll)="scrollToGraphicComparison($event)"
              ></slx-schedule-console-graphic-comparison>
            </div>
          </slx-collapsible-section>
        </ng-container>
      </div>
    </div>
  </div>
</slx-spinner>
