import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { IShiftGroup, ShiftGroup, UpdateShiftGroup } from "../../models";
import * as moment from "moment";
import { dateTimeUtils } from "./../../../../app/common/utils";
import { IShiftGroupDefinition, LookupMapService, ShiftGroupDefinition } from "./../../../../app/organization";

@Injectable()
export class ShiftGroupMapService {

    public shiftGroupData: ShiftGroup[];

    constructor() { }

    public mapShiftGroup(dto: IShiftGroup[]): ShiftGroup[] {
        let shiftGroup: ShiftGroup[] = [];
        _.each(dto, (dto: IShiftGroup) => {
            shiftGroup.push(this.convertDataToShiftGroup(dto));
        });
        return shiftGroup;
    }

    public convertDataToShiftGroup(data: IShiftGroup): ShiftGroup {

        let shiftGroup: ShiftGroup = new ShiftGroup();

        shiftGroup.id = data.id;
        shiftGroup.name = data.name;
        shiftGroup.shiftGroupStartTime = data.shiftGroupStartTime;
        shiftGroup.shiftGroupEndTime = data.shiftGroupEndTime;

        let startTime = moment(data.shiftGroupStartTime).format('HH:mm:ss');
        let endTime = moment(data.shiftGroupEndTime).format('HH:mm:ss');
        let startDate = new Date(`${new Date('1900-01-01').toDateString()} ${startTime}`);
        let endDate = new Date(`${new Date('1900-01-01').toDateString()} ${endTime}`);
        let start: moment.Moment = moment(startDate);
        let end: moment.Moment = moment(endDate);
        
        if (start.isAfter(end)) {
            end.add(1, 'days');
        }

        shiftGroup.start = start.toDate();
        shiftGroup.end = end.toDate();

        shiftGroup.startTime = dateTimeUtils.getTimeTotalSeconds(shiftGroup.start);
        shiftGroup.endTime = dateTimeUtils.getTimeTotalSeconds(shiftGroup.end);
        shiftGroup.shiftGroupWorkHours = Math.round(dateTimeUtils.getDurationDiffHours(shiftGroup.start, shiftGroup.end) * 100) / 100;
        shiftGroup.isNightShiftPrimary = data.isNightShiftPrimary === 'Y' ? true : false;
        return shiftGroup;
    }

    public reverseMap(data: ShiftGroup): UpdateShiftGroup {
        let startTime = moment(data.start).format('HH:mm:ss');
        let endTime = moment(data.end).format('HH:mm:ss');
        let result: UpdateShiftGroup = new UpdateShiftGroup();
        result.id = data.id;
        result.name = data.name;
        result.shiftGroupStartTime = startTime;
        result.shiftGroupEndTime = endTime;
        result.startTimeElapsed = this.getElapsedTime(startTime, false);
        result.endTimeElapsed = this.getElapsedTime(endTime, data.isNightShiftPrimary);
        return result;
    }

    public getElapsedTime(startTime: string, isNightShiftPrimary: boolean): number {
        let date = new Date(`${new Date('1900-01-01').toDateString()} ${startTime}`);
        let minutes = date.getHours() * 60;
        let nightShiftPrimaryMinutes = 1440 + minutes;
        return isNightShiftPrimary ? nightShiftPrimaryMinutes : minutes;
    }
}