import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, Output, EventEmitter, HostListener, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { ControlValueAccessor, Validator, AbstractControl, NgControl } from '@angular/forms';

import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor, dateTimeUtils } from '../../../common/utils/index';
import { CustomDomEvents } from '../../../common/models/index';
import { AcceptableControlValueAccessor } from '../../../core/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-datepicker',
  templateUrl: 'datepicker.component.html',
})
export class DatepickerInputComponent extends AcceptableControlValueAccessor implements OnChanges, ControlValueAccessor {
  @Input()
  public constantValidation: boolean = false;
  @Input()
  public format: string;
  @Input()
  public set minDate(value: Date) {
    this.m_minDate = value;
    this.validateDate();
  }
  public get minDate(): Date {
    if (!this.m_maxDate || !this.m_minDate) {
      return this.m_minDate;
    }
    return moment(this.m_minDate).isSameOrBefore(this.m_maxDate) ? this.m_minDate : this.m_maxDate;
  }

  @Input()
  public set maxDate(value: Date) {
    this.m_maxDate = value;
    this.validateDate();
  }
  public get maxDate(): Date {
    if (!this.m_minDate || !this.m_maxDate) {
      return this.m_maxDate;
    }
    return moment(this.m_maxDate).isSameOrAfter(this.m_minDate) ? this.m_maxDate : this.m_minDate;
  }
  @Input()
  public readonly: boolean;
  @Input()
  public readOnlyInput: boolean;
  @Input()
  public placeholder: boolean;
  @Input()
  public required: boolean;
  @Input()
  public cssClass: string;
  @Input()
  public calendarCellClass: string | Function;
  @Input()
  public acceptNullDate: boolean;
  @Input()
  public emitValueAnyway = false;
  @Input()
  public set watermarkStyleUnlessTouched(v: boolean) {
    this.watermarkClass = !!v;
  }
  @Output('open')
  public calendarOpen: EventEmitter<null>;
  @Output('close')
  public calendarClose: EventEmitter<null>;
  @Output('valueChange')
  public valueChangeEvent: EventEmitter<Date>;

  @HostBinding('class.slx-watermark')
  public watermarkClass: boolean;

  public getCalendarCellClass: (date: Date) => string;
  public currentValue: Date;
  public prevValue: Date;
  public hasClosed: boolean;
  public isIncorrectDate: boolean;

  private ngControl: NgControl;
  private changesEmitted: boolean;
  private m_minDate: Date;
  private m_maxDate: Date;

  @Input()
  public disabledDates: any;

  constructor(ngControl: NgControl) {
    super();
    this.format = 'MM/dd/yyyy';
    this.readonly = false;
    this.acceptNullDate = true;
    this.getCalendarCellClass = () => '';
    this.ngControl = ngControl;
    ngControl.valueAccessor = this;
    this.calendarOpen = new EventEmitter();
    this.calendarClose = new EventEmitter();
    this.valueChangeEvent = new EventEmitter();
    this.hasClosed = false;
    this.isIncorrectDate = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.calendarCellClass) {
      const calendarCellClass: string | Function = changes.calendarCellClass.currentValue;
      if (_.isString(calendarCellClass)) {
        this.getCalendarCellClass = () => calendarCellClass;
      }

      if (_.isFunction(calendarCellClass)) {
        this.getCalendarCellClass = (date: Date) => calendarCellClass(date);
      }
    }
  }

  @HostListener('keydown', ['$event'])
  public onMouseover(event: KeyboardEvent): void {
    if (event.keyCode === 13) {
      this.validateDate();
    }
  }

  public onCalendarOpen(): void {
    this.calendarOpen.emit();
  }

  public onCalendarClose(): void {
    this.hasClosed = true;
    this.calendarClose.emit();
    if (this.readOnlyInput) {
      const active = document.activeElement as any;
      if(active) {
        active.blur();
      }
    }
  }

  public onValueChanged(newDate: Date): void {
    this.currentValue = newDate;
    if (this.hasClosed || this.constantValidation) {
      this.resetError();
      this.validateDate();
      this.hasClosed = false;
    }
    this.valueChangeEvent.emit(newDate);
  }

  public onBlurInput(): void {
    this.resetError();
    this.onTouchedCallback();
    this.validateDate();
  }

  public writeValue(value?: any): void {
    this.currentValue = this.prevValue = null;
    if (this.emitValueAnyway) {
      this.checkValidityWhenEmittingValueAnyway(value, (data: { value: Date, error: boolean }) => {
        if (!data.error) {
          this.currentValue = this.prevValue = data.value;
        }
      });
    } else {
      this.checkValidity(value, (date: Date) => {
        this.currentValue = this.prevValue = date;
      });
    }
  }

  public getPlaceholder(): boolean {
    return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
  }

  private validateDate(): void {
    if (this.changesEmitted) {
      return;
    }
    if (this.isIncorrectDate || this.isValueChanged()) {
      if (this.emitValueAnyway) {
        this.checkValidityWhenEmittingValueAnyway(this.currentValue, (data: { value: Date, error: boolean }) => {
          if (this.isValueChanged()) {
            this.changesEmitted = true;
            this.emitEventWhenEmittingValueAnyway(data);
          }
        });
      } else {
        this.checkValidity(this.currentValue, (date: Date) => {
          if (this.isValueChanged()) {
            this.changesEmitted = true;
            this.emitEvent(date);
          }
        });
      }
    }
  }

  private checkValidity(d: Date, callback: (date: Date) => void): void {
    let date: moment.Moment | Date = moment(d);
    if (date.isValid()) {
      date = date.toDate();
      const isValidDate: boolean = this.isValidDate(date);
      const isValidMin: boolean = this.isValidMin(date);
      const isValidMax: boolean = this.isValidMax(date);
      switch (false) {
        case isValidDate:
          this.prevValue = null;
          this.setError('date');
          break;
        case isValidMin:
          this.prevValue = null;
          this.setError('minDate');
          break;
        case isValidMax:
          this.prevValue = null;
          this.setError('maxDate');
          break;
        default:
          callback(date);
      }
    } else {
      if (this.acceptNullDate) {
        callback(null);
      } else {
        this.prevValue = null;
        if (this.required) {
          this.setError('required');
        } else {
          this.setError('date');
        }
      }
    }
  }

  private checkValidityWhenEmittingValueAnyway(d: Date, callback: (data: { value: Date, error: boolean }) => void): void {
    let date: moment.Moment | Date = moment(d);
    if (date.isValid()) {
      date = date.toDate();
      const isValidDate: boolean = this.isValidDate(date);
      const isValidMin: boolean = this.isValidMin(date);
      const isValidMax: boolean = this.isValidMax(date);
      switch (false) {
        case isValidDate:
          this.setError('date');
          callback({ value: null, error: true });
          break;
        case isValidMin:
          this.setError('minDate');
          callback({ value: null, error: true });
          break;
        case isValidMax:
          this.setError('maxDate');
          callback({ value: null, error: true });
          break;
        default:
          callback({ value: date, error: false });
          break;
      }
    } else {
      if (this.acceptNullDate) {
        callback({ value: null, error: false });
      } else {
        if (this.required !== false) {
          this.setError('required');
          callback({ value: null, error: true });
        } else {
          callback({ value: null, error: false });
        }
      }
    }
  }

  private async emitEvent(newValue: Date): Promise<void> {
    let res = await this.changeValue(newValue);
    this.changesEmitted = false;
    this.resetError();
    if (!res) {
      this.currentValue = this.prevValue;
      return;
    }
    this.prevValue = newValue;
  }

  private async emitEventWhenEmittingValueAnyway(data: { value: Date, error: boolean }): Promise<void> {
    const errors = _.cloneDeep(this.ngControl.control.errors);
    const res = await this.changeValue(data.value);
    this.changesEmitted = false;
    if (data.error) {
      this.ngControl.control.setErrors(errors);
    }
    if (!res) {
      this.currentValue = this.prevValue;

      return;
    }
    this.prevValue = data.value === null ? undefined : data.value;
  }

  private isValueChanged(): boolean {

    if (this.constantValidation) return true;

    if (_.isDate(this.currentValue) && _.isDate(this.prevValue)) {
      return !moment(this.currentValue).isSame(moment(this.prevValue));
    }
    return this.currentValue !== this.prevValue;
  }

  private isValidValue(currentValue: Date): boolean {
    return !_.isNull(currentValue) && !_.isUndefined(currentValue);
  }

  private isValidDate(currentValue: Date): boolean {
    return dateTimeUtils.validateDate(currentValue);
  }

  private isValidMin(date: Date): boolean {
    if (!_.isDate(this.minDate) || !_.isDate(date)) {
      return true;
    }
    const minDate: moment.Moment = moment(date);

    return moment(this.minDate).isSameOrBefore(minDate);
  }

  private isValidMax(date: Date): boolean {
    if (!_.isDate(this.maxDate) || !_.isDate(date)) {
      return true;
    }
    const maxDate: moment.Moment = moment(date);

    return moment(this.maxDate).isSameOrAfter(maxDate);
  }

  private setError(errorName: string): void {
    this.isIncorrectDate = true;
    this.ngControl.control.setErrors({ [errorName]: true });
  }

  private resetError(): void {
    this.isIncorrectDate = false;
    this.ngControl.control.setErrors(null);
  }
}
