import { IFilterRotationOptions } from "./schedule-rotation-template.model";

export const filterOptionsRecord: IFilterRotationOptions = {
    showStaff: [
        { id: 1, name: 'Full Time', isSelected: true },
        { id: 2, name: 'Part Time', isSelected: false },
        { id: 3, name: 'Employees without a Rotation', isSelected: false },
        { id: 4, name: 'Rotations without an Employee', isSelected: false }
    ],
    viewWeeks: [
        { id: 11, name: 'Dates (within current rotation cycle)', isSelected: true },
        { id: 12, name: 'Rotation Week Numbers', isSelected: false }
    ],
    positionOptions: {
        primaryPosition: {
            id: 201, name: 'Default (Primary Position Only)', isSelected: true
        },
        secondaryPosition: {
            id: 202,
            name: 'Show Secondary Positions',
            isSelectAll: false,
            options: [
                { id: 203, name: 'Current Department Only', isSelected: false },
                { id: 204, name: 'Another Department / Facility', isSelected: false },
            ]
        },
        agencyStaff: { id: 205, name: 'Show Agency Staff', isSelected: false },
        emptyRotation: { id: 206, name: 'Empty Rotations Only', isSelected: false }
    },
    gridFilterOption: { id: 301, name: 'Apply Grid Filter', isSelected: false }
}