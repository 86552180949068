import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { OrgLevel } from '../../../../state-model/models/index';

import { WcRepeatInjuryRosterState, WcIncidentRosterContainer, WcViewList } from '../../models/index';
import { WcCommonManagementService } from './wc-common-management.service';

@Injectable()
export class WcRepeatInjuryManagementService {
  constructor (private manService: WcCommonManagementService) {}

  public init(componentId: string): void {
    this.manService.init(componentId, 'columnsVisibility', WcViewList.RepeatInjuryList);
    this.subscribeToToolbarService();
    this.subscribeToOrgLevelChanges();
    this.restoreState();
  }

  public destroy(): void {
    this.manService.destroy();
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    return this.manService.subscribeToOrgLevel(callback);
  }

  public subscribeToExport(callback: (isPDF: boolean) => void): Subscription {
    return this.manService.subscribeToExport(callback);
  }

  public subscribeToChangeState(callback: (s: WcRepeatInjuryRosterState) => void): Subscription {
    return this.manService.subscribeToChangeState(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    return this.manService.subscribeToLoading(callback);
  }

  public subscribeToLoadedRecords(callback: (v: WcIncidentRosterContainer) => void): Subscription {
    return this.manService.subscribeToLoadedRecords(callback);
  }

  public openEditReportDialog(reportId: number): void {
    this.manService.openEditReportDialog(reportId);
  }

  private subscribeToToolbarService(): void {
    this.manService.subscribeToToolbarService();
    this.manService.subscribeToIncidentsAmount();
  }

  private subscribeToOrgLevelChanges(): void {
    this.manService.subscribeToOrgLevelChanges();
  }

  private restoreState(): void {
    const state = new WcRepeatInjuryRosterState();
    state.createColumns();

    this.manService.restoreState(state);
  }
}
