import { Injectable } from '@angular/core';
import { appConfig } from '../../../app.config';
import { ModalService } from '../modal/modal.service';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { VersionApiService } from './version-api.service';
import { SessionService } from '../../../core/services/index';
import { LocalStorageService } from '../../../core/services/local-storage/local-storage.service';
import { AppVersionInfo } from '../../../state-model/models/index';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class VersionSubscribeService {
  public static storageKey: string = 'app-version';

  private appVersion$: Subject<AppVersionInfo> = new Subject<AppVersionInfo>();
  private hotKeys: string = 'ctrl+alt+i';

  constructor(private hotkeysService: HotkeysService,
    private modalService: ModalService,
    private versionService: VersionApiService,
    private sessionService: SessionService,
    private localStorageService: LocalStorageService) {
  }

  public get(): Promise<AppVersionInfo> {
    return new Promise((resolve: any, reject: any) => {
      if (this.sessionService.isExpired()) {
        reject();
      }

      let value: AppVersionInfo = this.localStorageService.get<AppVersionInfo>(VersionSubscribeService.storageKey);
      if (value && (new Date().getTime() - new Date(value.updateDate).getTime()) < 1000 * 60) {
        resolve(value);
        return;
      }

      this.refreshAppVersion().then((data: AppVersionInfo) => resolve(data));
    });
  }

  public getCachedVersion(): AppVersionInfo {
    return this.localStorageService.get<AppVersionInfo>(VersionSubscribeService.storageKey);
  }

  public async getVersion(): Promise<AppVersionInfo> {
    const version = this.getCachedVersion();
    if (version) {
      return Promise.resolve(version);
    }
    return this.refreshAppVersion();
  }

  public refreshAppVersion(): Promise<AppVersionInfo> {
    return this.versionService.getServerVersion().then((data: AppVersionInfo) => {
      this.appVersion$.next(data);
      this.localStorageService.set(VersionSubscribeService.storageKey, data);
      return data;
    });
  }

  public runDialogListener(): void {
    this.hotkeysService.add(new Hotkey(this.hotKeys, (event: KeyboardEvent): boolean => {
      this.get()
        .then((data: AppVersionInfo) => this.showDialogWithInfo(data))
        .catch((ex: any) => {
          console.log('Error: ' + ex);
        });
      return false; // Prevent bubbling
    }));
  }

  public getAppVersion(): Observable<AppVersionInfo> {
    return this.appVersion$.asObservable();
  }

  private showDialogWithInfo(data: AppVersionInfo): void {
    let appVersion: string = 'App version: ' + appConfig.appVersion;
    let serviceVersion: string = 'Service version: ' + data.serviceVersion;
    let dbVersion: string = (data.dbVersion) ? 'Database version: ' + data.dbVersion : '';
    this.modalService.globalAnchor.openInfoDialog('Application info', [appVersion, serviceVersion, dbVersion].join('<br/>'));
  }
}
