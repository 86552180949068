import * as _ from 'lodash';

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { BenefitEligibilityRule, BenefitEligibilityRuleStatus } from '../../../models';
import { PopoverContentComponent, ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { BenefitDetailsEligibilityRuleDialogComponent } from '../benefit-details-eligibility-rule-dialog/benefit-details-eligibility-rule-dialog.component';

@Component({
  selector: 'slx-benefit-details-eligibility-rules-list',
  templateUrl: './benefit-details-eligibility-rules-list.component.html',
  styleUrls: ['./benefit-details-eligibility-rules-list.component.scss'],
})
export class BenefitDetailsEligibilityRulesListComponent {
  @Input()
  public benefitEligibilityRules: BenefitEligibilityRule[];

  @Input()
  public benefitName: string;

  @Input()
  public isEditMode: boolean;

  @Input()
  public isLoading: boolean;

  @Input()
  public orgLevelId: number;

  @Input()
  public benefitRulesAlreadyUsedNames: string[];

  @Input()
  public canDelete: boolean;

  @Output()
  public benefitEligibilityRulesAdded: EventEmitter<BenefitEligibilityRule> = new EventEmitter();

  @Output()
  public benefitEligibilityRulesUpdated: EventEmitter<BenefitEligibilityRule> = new EventEmitter();

  @Output()
  public benefitEligibilityRulesDeleted: EventEmitter<BenefitEligibilityRule> = new EventEmitter();

  get BenefitEligibilityRuleStatus() {
    return BenefitEligibilityRuleStatus;
  }

  constructor(private modalService: ModalService) {}

  public showUpdateEligibilityRuleDialog(ruleToUpdate: BenefitEligibilityRule) {
    if (!this.modalService || !ruleToUpdate) {
      return;
    }

    const rulesAlreadyUsedNamesWithoutCurrent = _.without(this.benefitRulesAlreadyUsedNames, ruleToUpdate.name);

        BenefitDetailsEligibilityRuleDialogComponent.openDialog(
          this.modalService,
      rulesAlreadyUsedNamesWithoutCurrent,
          this.orgLevelId,
          ruleToUpdate,
      !this.isEditMode,
          (result: boolean, rule: BenefitEligibilityRule) => {
            if (result) {
          if (rule.status === BenefitEligibilityRuleStatus.Added) {
                this.benefitEligibilityRulesAdded.next(rule);
                return;
              }
              this.benefitEligibilityRulesUpdated.next(rule);
            }
          }
        );
      }

  public onDeleteRule(ruleId: number): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Delete Rule',
      'Are you sure you want to delete the rule?',
      this.modalService,
      (isDelete: boolean) => {
    if (isDelete) {
      const rule = _.find(this.benefitEligibilityRules, (rule) => rule.id === ruleId);
      this.benefitEligibilityRulesDeleted.next(rule);
    }
      },
      options);
  }

  trackByItem(index: number, item: BenefitEligibilityRule): number {
    return item.id;
  }
}
