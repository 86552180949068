import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnDestroy, OnInit, Provider } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NewEnrollmentModel, BenefitTierDefinition } from '../../../../models';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { ModalService } from '../../../../../../common/services';
import { CalculationCommonService } from '../../../../../../app-modules/benefits/services/benefit-details/calculation-common.service';
import { DialogOptions, IDialog, KendoGridStateHelper, DialogOptions2 } from '../../../../../../common/models';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { appConfig } from '../../../../../../app.config';
import { process } from '@progress/kendo-data-query';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EnrollEmployeeSelectCoveragePopupComponent = /** @class */ (function () {
    function EnrollEmployeeSelectCoveragePopupComponent(model, apiService, options, modalService, calculationService, man) {
        var _this = this;
        this.model = model;
        this.apiService = apiService;
        this.options = options;
        this.modalService = modalService;
        this.calculationService = calculationService;
        this.man = man;
        this.minAmt = 0;
        this.maxAmt = 99999999;
        this.stepcurrency = 1;
        this.currencyFormat = 'c2';
        this.hasDedError = false;
        this.subscriptions = {};
        this.isRowSelected = function (e) { return e.dataItem === _this.selectedCoverageOption; };
        this.hasSelected = false;
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.selectableSettings = {
            checkboxOnly: false,
            enabled: true,
            mode: 'single'
        };
    }
    EnrollEmployeeSelectCoveragePopupComponent.openDialog = function (model, apiService, modalService, calcService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.width = 850;
        dialogOptions.height = 450;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: ModalService,
                useValue: modalService
            },
            {
                provide: NewEnrollmentModel,
                useValue: model
            },
            {
                provide: EmployeeSectionsBenefitsManagementApiService,
                useValue: apiService
            },
            {
                provide: CalculationCommonService,
                useValue: calcService
            }
        ];
        var title = "Enroll Employee in Benefits";
        var component = modalService.globalAnchor.openDialog2(EnrollEmployeeSelectCoveragePopupComponent, title, dialogOptions, resolvedProviders, callback);
        return component;
    };
    Object.defineProperty(EnrollEmployeeSelectCoveragePopupComponent.prototype, "hasTierOptRates", {
        get: function () {
            if (this.selectedCoverageOption) {
                return this.checkCoverageOptionValidity();
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    EnrollEmployeeSelectCoveragePopupComponent.prototype.checkCoverageOptionValidity = function () {
        return _.isNumber(this.selectedCoverageOption.tierOptionErContribution) &&
            _.isNumber(this.selectedCoverageOption.tierOptionEmpContribution) &&
            _.isNumber(this.selectedCoverageOption.tierOptionCost) &&
            (_.isFinite(this.selectedCoverageOption.id) && this.selectedCoverageOption.id != 0);
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.groupName = this.model.plan.name;
        this.effectiveDate = this.model.effectiveDate;
        this.payrollDedStartDate = this.model.effectiveDate;
        this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
        this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
        this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
        this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
        this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate : this.model.plan.dedEndDate;
        this.startDate = _.isNull(this.model.plan.startDate) ? this.model.effectiveDate : this.model.plan.startDate;
        this.endDate = this.model.plan.endDate;
        this.canMapPayroll = this.model.canMapPayroll;
        this.apiService.getBenefitPlanOptionRates(this.model.plan.benefitLineId)
            .then(function (options) {
            _this.coverageOptions = options;
            var tiers = _.map(_this.coverageOptions, function (o) {
                var d = new BenefitTierDefinition();
                d.id = o.tierId;
                d.name = o.tierName;
                return d;
            });
            _this.tiers = _.uniqBy(tiers, function (t) { return t.id; });
            _this.filteredCoverageOptions = [];
            _this.refreshGrid();
            _this.isLoading = false;
        });
        this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate(function (hasDedError) {
            _this.hasDedError = hasDedError;
        });
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.ngOnDestroy = function () {
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.onSelectionChange = function (event) {
        this.gridState.selectionChange(event);
        var selectedRecords = _.map(event.selectedRows, function (args) { return args.dataItem; });
        if (selectedRecords && _.size(selectedRecords) > 0) {
            this.selectedCoverageOption = _.first(selectedRecords);
        }
        this.hasSelected = this.selectedCoverageOption != null;
        if (!this.checkCoverageOptionValidity()) {
            this.showWarning();
        }
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.onCancel = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Discard Changes', 'Are you sure you want to cancel? You will lose all unsaved selections.', this.modalService, function (isCancel) {
            if (isCancel) {
                _this.dialogResult = false;
                _this.modalService.closeWindow(_this.options.windowUniqueId);
            }
        }, options);
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.showWarning = function () {
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('Warning', 'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.', this.modalService, function (result) { }, options);
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.onEnroll = function () {
        this.model.selectedCoverageOption = this.selectedCoverageOption;
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.onStartEdit = function (item) {
        this.selectedCoverageOption = item;
        this.hasSelected = this.selectedCoverageOption != null;
        this.refreshGrid();
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.onChangeTier = function (t) {
        var _this = this;
        this.showTooltipOnDialogWarning = false;
        this.selectedTier = t;
        if (this.selectedTier && this.coverageOptions) {
            this.filteredCoverageOptions = _.filter(this.coverageOptions, function (o) { return o.tierId === _this.selectedTier.id; });
            if (_.size(this.filteredCoverageOptions) === 1 && this.filteredCoverageOptions[0].id === 0) {
                this.showTooltipOnDialogWarning = true;
                this.showWarning();
            }
        }
        else {
            this.filteredCoverageOptions = [];
        }
        if (this.selectedCoverageOption != null) {
            var filterSelectedOption = _.filter(this.filteredCoverageOptions, function (o) { return o.id === _this.selectedCoverageOption.id; });
            this.hasSelected = !_.isEmpty(filterSelectedOption);
        }
        this.refreshGrid();
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.onChangeEmpContribution = function (dataItem) {
        var employeeContribution = 0;
        if (dataItem.tierOptionEmpContribution > 0) {
            employeeContribution = this.calculationService.getConvertedEmployeeContribution(dataItem.costFreq, dataItem.empContFreq, dataItem.tierOptionEmpContribution);
        }
        dataItem.tierOptionCost = dataItem.tierOptionErContribution + employeeContribution;
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.refreshGrid = function () {
        if (!this.coverageOptions) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.filteredCoverageOptions, this.gridState.state);
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.getOptRatesToolTip = function () {
        return 'Employee cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll.';
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.OnDeductionEndDateChange = function (date) {
        this.model.plan.payrollDeductionEndDate = date;
    };
    EnrollEmployeeSelectCoveragePopupComponent.prototype.OnDeductionStartDateChange = function (date) {
        this.model.plan.payrollDeductionStartDate = date;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EnrollEmployeeSelectCoveragePopupComponent.prototype, "subscriptions", void 0);
    return EnrollEmployeeSelectCoveragePopupComponent;
}());
export { EnrollEmployeeSelectCoveragePopupComponent };
