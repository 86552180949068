import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { unsubscribe } from '../../../../core/decorators/index';
import { ChangeManagementService } from '../../../../common/services/index';
import { StaffingPredictorManagementService } from '../../services/index';
import { StaffingRatingOrganization, FiveStarRatingOrganization } from '../../models/index';
import { Subscription } from 'rxjs/Subscription';
import { ModalService, ConfirmChangesDialogComponent } from '../../../../common/index';
var StaffingPredictorSectionComponent = /** @class */ (function () {
    function StaffingPredictorSectionComponent(managementService, changeDetector, changeManagement, modalService) {
        var _this = this;
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.changeManagement = changeManagement;
        this.modalService = modalService;
        this.isLoading = true;
        this.five_star_v2_enabled = false;
        this.five_star_goal_section_enabled = false;
        this.sectionIndex = 0;
        this.isValueChanged = false;
        this.m_isCollapsed = true;
        this.managementService.onLoadStatus$.subscribe(function (val) {
            _this.isLoading = val;
        });
        this.managementService.isGoalValueChanged$.subscribe(function (val) {
            _this.isValueChanged = val;
            if (_this.isValueChanged)
                _this.changeManagement.changeNotify();
            else
                _this.changeManagement.clearChanges();
        });
    }
    Object.defineProperty(StaffingPredictorSectionComponent.prototype, "hasErrors", {
        get: function () {
            if (this.five_star_v2_enabled) {
                return this.fiveStarOrganization ? this.fiveStarOrganization.noCcn
                    || this.fiveStarOrganization.noCensus
                    || this.fiveStarOrganization.noCmsData
                    || this.fiveStarOrganization.noHoursData
                    || this.fiveStarOrganization.rnZeroDays || this.fiveStarOrganization.noCaseMixRNHoursInfo || this.fiveStarOrganization.noTotalNurseTurnoverInfo || this.fiveStarOrganization.noCaseMixTotalNurseHoursInfo || this.fiveStarOrganization.noStaffingRatingPastRatedPeriodInfo || this.fiveStarOrganization.noAdministratorDeparturesInfo : false;
            }
            else {
                return this.organization ? this.organization.noCcn
                    || this.organization.noCensus
                    || this.organization.noCmsData
                    || this.organization.noHoursData
                    || this.organization.rnZeroDays : false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorSectionComponent.prototype, "ratingValues", {
        get: function () {
            return this.five_star_v2_enabled ? this.fiveStarOrganization.rating.value : this.organization.rating.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorSectionComponent.prototype, "ratingMaxValues", {
        get: function () {
            return this.five_star_v2_enabled ? this.fiveStarOrganization.rating.maxValue : this.organization.rating.maxValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorSectionComponent.prototype, "isCollapsed", {
        get: function () {
            return this.m_isCollapsed;
        },
        enumerable: true,
        configurable: true
    });
    StaffingPredictorSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modelLoadedSubscription = this.managementService.onOrganizationInfoLoaded$.subscribe(function (data) {
            if (!_this.five_star_v2_enabled) {
                if (data.organization.organization.id === _this.organization.organization.id) {
                    _this.fullInfo = data;
                    _this.fullInfo.organization = _this.organization;
                    _this.changeDetector.markForCheck();
                    _this.changeDetector.detectChanges();
                }
            }
            else {
                if (data.organization.organization.id === _this.fiveStarOrganization.organization.id) {
                    _this.fullInfo = data;
                    _this.fullInfo.organization = _this.fiveStarOrganization;
                    _this.changeDetector.markForCheck();
                    _this.changeDetector.detectChanges();
                }
            }
        });
        if (!this.m_isCollapsed && this.five_star_v2_enabled) {
            this.loadFullInfo();
        }
    };
    StaffingPredictorSectionComponent.prototype.collapsedChange = function (isCollapsed, index) {
        var _this = this;
        this.m_isCollapsed = isCollapsed;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        if (isCollapsed == true && this.isValueChanged == true) {
            this.m_isCollapsed = false;
            ConfirmChangesDialogComponent.openDialog('Warning', this.modalService, function (result) {
                if (result) {
                    _this.m_isCollapsed = true;
                    _this.isValueChanged = false;
                    _this.managementService.disableCollapsableContainer$.next([false, _this.sectionIndex]);
                }
                else
                    _this.m_isCollapsed = false;
            });
        }
        if (!this.m_isCollapsed) {
            this.loadFullInfo();
        }
    };
    StaffingPredictorSectionComponent.prototype.loadFullInfo = function () {
        var startDate = this.managementService.container.startDate;
        var endDate = this.managementService.container.endDate;
        if (this.fullInfo) {
            if (moment(startDate).isSame(this.fullInfo.startDate) && moment(endDate).isSame(this.fullInfo.endDate)) {
                return;
            }
        }
        var orgId = this.organization ? this.organization.organization.id : this.fiveStarOrganization.organization.id;
        this.managementService.organizationId = orgId;
        this.managementService.loadOrganizationInfo(orgId, startDate, endDate, this.five_star_v2_enabled);
    };
    StaffingPredictorSectionComponent.prototype.getGoalRating = function (value) {
        this.goalRating = value;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], StaffingPredictorSectionComponent.prototype, "modelLoadedSubscription", void 0);
    return StaffingPredictorSectionComponent;
}());
export { StaffingPredictorSectionComponent };
