/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./labeled-badge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./labeled-badge.component";
var styles_LabeledBadgeComponent = [i0.styles];
var RenderType_LabeledBadgeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabeledBadgeComponent, data: {} });
export { RenderType_LabeledBadgeComponent as RenderType_LabeledBadgeComponent };
function View_LabeledBadgeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "fa ", _co.icon, " icon-setting"); _ck(_v, 1, 0, currVal_0); }); }
export function View_LabeledBadgeComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "labeled-badge-container"]], [[2, "no-value", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "value-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabeledBadgeComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "badge"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "badge-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.icon; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.value; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.value; _ck(_v, 8, 0, currVal_3); }); }
export function View_LabeledBadgeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-labeled-badge", [], null, null, null, View_LabeledBadgeComponent_0, RenderType_LabeledBadgeComponent)), i1.ɵdid(1, 49152, null, 0, i3.LabeledBadgeComponent, [], null, null)], null, null); }
var LabeledBadgeComponentNgFactory = i1.ɵccf("slx-labeled-badge", i3.LabeledBadgeComponent, View_LabeledBadgeComponent_Host_0, { icon: "icon", label: "label", value: "value" }, { onClick: "onClick" }, []);
export { LabeledBadgeComponentNgFactory as LabeledBadgeComponentNgFactory };
