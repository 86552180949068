import * as _ from 'lodash';

export interface IWfmSync {
  type: string;
  orgLevelId: number;
  orgName: string;
  startDate: string;
  endDate: string;
  employeeIds: string[];
  partnerId: string;
  customerId: string;
  companyCode: string;
  status: string;
}
export class WfmSync {
  public type: string;
  public orgLevelId: number;
  public orgName: string;
  public startDate: string;
  public endDate: string;
  public employeeIds: string[];
  public partnerId: string;
  public customerId: string;
  public companyCode: string;
  public status: string;
}

export interface IWfnSyncResponse {
  id: number;
  syncId: string;
  partnerId: string;
  customerId: string;
  companyId: string;
  errorCount: number;
  successCount: number;
  currentCount: number;
  totalCount: number;
  activeCount: number;
  terminateCount: string;
  syncStartDate: Date;
  syncEndDate: Date;
  createdDate: Date;
  updatedDate: Date;
  syncType: string;
  orgName: string;
  addedEmpCount: number;
  orgCode: string;
}
export class WfnSyncResponse {
  public id: number;
  public syncId: string;
  public partnerId: string;
  public customerId: string;
  public companyId: string;
  public errorCount: number;
  public successCount: number;
  public currentCount: number;
  public totalCount: number;
  public activeCount: number;
  public terminateCount: string;
  public syncStartDate: Date;
  public syncEndDate: Date;
  public createdDate: Date;
  public updatedDate: Date;
  public syncType: string;
  public orgName: string;
  public addedEmpCount: number;
  public orgCode: string;
}

export interface IWfmSynDetails {
  syncId: string;
  errorMessage: string;
  status: string;
  employeeId: string;
  StartDate: Date;
  endDate: Date;
  syncType: string;
  employeeName: string;
  errorCode: number;
}
export class WfmSynDetails {
  public syncId: string;
  public errorMessage: string;
  public status: string;
  public employeeId: string;
  public StartDate: Date;
  public endDate: Date;
  public syncType: string;
  public employeeName: string;
  public errorCode: number;
}

export interface IWfmTransactionReport {
  transactionId: number;
  transactionDate: Date;
  transactionName: string;
  transactionEndDate: Date;
  employeeName: string;
  transactionStatus: string;
  errorCode: number;
  employeeId: string;
  errorMessage: string;
  clinetType:string;
  isEventSubscriptionEnabled:boolean;
  reSyncAction:string;
  modifiedDate:Date;
  organizationName:string;
  payrollNumber:string;
  payDate:Date;
}
export class WfmTransactionReport {
  public transactionId: number;
  public transactionDate: Date;
  public transactionName: string;
  public transactionEndDate: Date;
  public employeeName: string;
  public transactionStatus: string;
  public errorCode: number;
  public employeeId: string;
  public errorMessage: string;
  public clinetType:string;
  public isEventSubscriptionEnabled:boolean;
  public reSyncAction:string;
  public modifiedDate:Date;
  public organizationName:string;
  public payrollNumber:string;
  public payDate:Date;
}
export interface IWFMOrgLevelChangeLogModel {
  //  id:number,
  orgLevelId: number;
  action: string;
  //  actionDate:Date,
  orgLevelType: string;
  orgLevelName: string;
  jobClass: string;
  jobCode: number;
  //   parentId : number,
  ischecked: boolean;
  companyId: number;
  companyCode: string;
  shortCode: number;
  errorMessage: string;
  status: string;
  failedAt: string;
  isDisabled: boolean;
  paygroup: string;
  orgName: string;
}
export class WFMOrgLevelChangeLogModel {
  public id: number;
  public orgLevelId: number;
  public action: string;
  //  public actionDate:Date;
  public orgLevelType: string;
  public orgLevelName: string;
  public jobClass: string;
  public jobCode: number;
  public parentId: number;
  public ischecked: boolean;
  public companyId: number;
  public companyCode: string;
  public shortCode: number;
  public errorMessage: string;
  public status: string;
  public failedAt: string;
  public isDisabled: boolean;
  public paygroup: string;
  public orgName: string;
  public payrollCode: number;
}
export interface IEventReport {
  transactionId: number;
  transactionDate: Date;
  transactionName: string;
  transactionEndDate: Date;
  employeeName: string;
  transactionStatus: string;
  errorCode: number;
  employeeId: string;
  errorMessage: string;
  transactionsubName: string;
  orgTypeId: number;
  orgType: string;
  isChecked: boolean;
  eventId: string;
  organizationName: string;
  timecarExportId: string;
  payCycle: string;
}
export class EventReport {
  public transactionId: number;
  public transactionDate: Date;
  public transactionName: string;
  public transactionEndDate: Date;
  public employeeName: string;
  public transactionStatus: string;
  public errorCode: number;
  public employeeId: string;
  public errorMessage: string;
  public transactionsubName: string;
  public orgTypeId: number;
  public orgType: string;
  public isChecked: boolean;
  public eventId: string;
  public organizationName: string;
  public timecarExportId: string;
  public payCycle: string;
}

export interface IEmpSynDetails {
   id: number;
   employeeID: number;
   payRollNumber: string;
   employeeName: string;
   orgName: string;
   depName: string;
   positionName: string;
   positionId: number;
   departmentId: number;
   organizationId: number;
   terminationDate: Date |string;
   transferDepartmentId: number;
   companyId: number;
   coCode: string;
   errorMessage: string;
   empStatus: string;
   action: string;
   status: string;
   failedAt: string;
   ischecked: boolean;
   isDisabled: boolean;
   paygroup: string;
   lastSyncDate: Date;
   dateReHired: Date;
}
export class EmpSynDetails {
  public id: number;
  public employeeID: number;
  public payRollNumber: string;
  public employeeName: string;
  public orgName: string;
  public depName: string;
  public positionName: string;
  public positionId: number;
  public departmentId: number;
  public organizationId: number;
  public terminationDate: Date |string;
  public transferDepartmentId: number;
  public companyId: number;
  public coCode: string;
  public errorMessage: string;
  public empStatus: string;
  public action: string;
  public status: string;
  public failedAt: string;
  public ischecked: boolean;
  public isDisabled: boolean;
  public paygroup: string;
  public lastSyncDate: Date;
  public dateReHired: Date;
}

export interface IOrg {
  id: number;
  name: string;
  orgLevelType: string;
  sequenceNumber: number;
  relatedItemId: number;
  parentId: number;
  organizationId: number;
}
export class Org {
  public id: number;
  public name: string;
  public orgLevelType: string;
  public sequenceNumber: number;
  public relatedItemId: number;
  public parentId: number;
  public organizationId: number;
}
export interface IOrganization {
  Id: number;
  Name: string;
  OrgLevelId: number;
}
export class Organization {
  public Id: number;
  public Name: string;
  public OrgLevelId: number;
}
export interface IDepartment {
  Id: number;
  Name: string;
  OrgLevelId: number;
  OrganizationId: number;
  ScheduleStart: Date;
}
export class Department {
  public Id: number;
  public Name: string;
  public OrgLevelId: number;
  public OrganizationId: number;
  public ScheduleStart: Date;
}
export interface IPosition {
  Id: number;
  Name: string;
  OrgLevelId: number;
  OrganizationName: string;
  GroupedWithPositionId: number;
  StartDate: Date;
  EndDate: Date;
  PositionGroupId: number;
  PositionGroupName: string;
}
export class Position {
  Id: number;
  public Name: string;
  public OrgLevelId: number;
  public OrganizationName: string;
  public GroupedWithPositionId: number;
  public StartDate: Date;
  public EndDate: Date;
  public PositionGroupId: number;
  public PositionGroupName: string;
}
export interface IBenefitDeductionsync {
  //BenefitClassName: string;
  id: number;
  employeeID: number;
  employeeName: string;
  payRollNumber: string;
  benefitLineId: number;
  benefitLineName: string;
  startDate: Date;
  endDate: Date;
  orgName: string;
  depName: string;
  positionName: string;
  positionId: number;
  departmentId: number;
  organizationId: number;
  ischecked: boolean;
  companyId: number;
  coCode: string;
  errorMessage: string;
  status: string;
  failedAt: string;
  isDisabled: boolean;
  actionType: string;
  enrollmentId:number;
  paygroup: string;
}
export class BenefitDeductionsync implements IBenefitDeductionsync {
  //BenefitClassName: string;
  id: number;
  employeeID: number;
  employeeName: string;
  payRollNumber: string;
  benefitLineId: number;
  benefitLineName: string;
  startDate: Date;
  endDate: Date;
  orgName: string;
  depName: string;
  positionName: string;
  positionId: number;
  departmentId: number;
  organizationId: number;
  ischecked: boolean;
  companyId: number;
  coCode: string;
  errorMessage: string;
  status: string;
  failedAt: string;
  isDisabled: boolean;
  actionType: string;
  enrollmentId:number;
  paygroup: string;
}
export interface ICostcentersyncDetails {
  id: number;
  lineNumber: number;
  code: number;
  description: string;
  jobCode: number;
  jobDesc: string;
  unit: number;
  departmentId: number;
  departmentName: string;
  organizationId: number;
  organizationName: string;
  ischecked: boolean;
  companyId: number;
  companyCode: string;
  errorMessage: string;
  action: string;
  status: string;
  failedAt: string;
  isDisabled: boolean;
  paygroup: string;
}

export class CostcentersyncDetails implements ICostcentersyncDetails {
  public id: number;
  public lineNumber: number;
  public code: number;
  public description: string;
  public jobCode: number;
  public jobDesc: string;
  public unit: number;
  public departmentId: number;
  public departmentName: string;
  public organizationId: number;
  public organizationName: string;
  public ischecked: boolean;
  public companyId: number;
  public companyCode: string;
  public errorMessage: string;
  public action: string;
  public status: string;
  public failedAt: string;
  public isDisabled: boolean;
  public paygroup: string;
}

export interface IWFMSecondaryPositionSyncModel {
  id: number;
  action: string;
  ischecked: boolean;
  companyId: number;
  companyCode: string;
  shortCode: number;
  message: string;
  status: string;
  failedAt: string;
  isDisabled: boolean;
  paygroup: string;
  payrollCode: string;
  homeOrgLevelId: string;
  homeOrganization: string;
  secondaryOrgLevelId: number;
  secondaryDeporgLevelId: number;
  secondaryOrganization: string;
  secondaryPositionDept: string;
  secondaryPositionid: number;
  secondaryPositionName: string;
}
export class WFMSecondaryPositionSyncModel implements IWFMSecondaryPositionSyncModel {
  public id: number;
  public action: string;
  public ischecked: boolean;
  public companyId: number;
  public companyCode: string;
  public shortCode: number;
  public message: string;
  public status: string;
  public failedAt: string;
  public isDisabled: boolean;
  public paygroup: string;
  public payrollCode: string;
  public homeOrgLevelId: string;
  public homeOrganization: string;
  public secondaryOrgLevelId: number;
  public secondaryDeporgLevelId: number;
  public secondaryOrganization: string;
  public secondaryPositionDept: string;
  public secondaryPositionid: number;
  public secondaryPositionName: string;

}
export interface IWFMPostEventSyncModel {
  id: number;
  partnerId: string;
  customerId: string;
  type: number;
  status: number;
  entityId: string;
  entity: string;
  failedA: string;
  eventId: string;
  errorCode: string;
  errorMessage: string;
  eventType: string;
  eventDetail: string;
  eventCategory: string;
  orgLevelId: number;
  retryCount: number;
  createdDate: Date;
  updatedDate: Date;
  ischecked: boolean;
}
export class WFMPostEventSyncModel {
  public id: number;
  public partnerId: string;
  public customerId: string;
  public type: number;
  public status: number;
  public entityId: string;
  public entity: string;
  public failedA: string;
  public eventId: string;
  public errorCode: string;
  public errorMessage: string;
  public eventType: string;
  public eventDetail: string;
  public eventCategory: string;
  public orgLevelId: number;
  public retryCount: number;
  public createdDate: Date;
  public updatedDate: Date;
  public ischecked: boolean;
}

export interface IWFMDashBoardModel {
  statusCount:number;
  statusMessage:string;
  entity:string;
  totalCount:number;
  percentage:number;
  color:string;
}
export class WFMDashBoardModel {
  public statusCount:number;
  public statusMessage:string;
  public entity:string;
  public totalCount:number;
  public percentage:number;
  public color:string;
}

export interface IWFMDashBoardDrillDownModel {
  orgLevelId:number;
  orgLevelName:string;
  depName:string;
  positionName:string;
  action:string;
  status:string;
  empStatus:string;
  jobCode:number;
  orgLevelType:string;
  companyId:number;
  employeeID:number;
  payRollNumber:string;
  employeeName:string;
  costCenterId:number;
  costCenterCode:number;
  CostCenterDescription:string;
  benefitLineId:number;
  benefitClass:string;
  startDate:Date;
  endDate:Date;
  entity:string;
  actionDate:Date;
}

export class WFMDashBoardDrillDownModel {
  public orgLevelId:number;
  public orgLevelName:string;
  public depName:string;
  public positionName:string;
  public action:string;
  public status:string;
  public empStatus:string;
  public jobCode:number;
  public orgLevelType:string;
  public companyId:number;
  public employeeID:number;
  public payRollNumber:string;
  public employeeName:string;
  public costCenterId:number;
  public costCenterCode:number;
  public   CostCenterDescription:string;
  public benefitLineId:number;
  public benefitClass:string;
  public startDate:Date;
  public endDate:Date;
  public entity:string;
  public actionDate:Date;
}

export interface IWFMFlagsStatus {
  NextgenPayrollEnabled:boolean;
  WFMEventSubscriptionEnabled:boolean;
  NextgenPayrollOnboardingEnabled:boolean;
}
export class WFMFlagsStatus {
  public NextgenPayrollEnabled:boolean;
  public WFMEventSubscriptionEnabled:boolean;
  public NextgenPayrollOnboardingEnabled:boolean;
}

export interface IWFMFlagsSyncStatus {
  migrationSync:boolean;
  ongoingSync:boolean;
  pauseSync:boolean;
}
export class WFMFlagsSyncStatus {
  public migrationSync:boolean;
  public ongoingSync:boolean;
  public pauseSync:boolean;
}

export interface IWFMStopSyncDetails
{
    customerId:string;
    stopSync:boolean;
}
export class WFMStopSyncDetails
{
    public customerId:string;
    public stopSync:boolean;
}

export function dataCheck(data: any[]) {
    _.map(data, (item, index) => {
    item.id = index + 1;

    item.isDisabled = item.companyId === null || item.companyId === 0 || (item.paygroup === "" || item.paygroup === null || item.paygroup === undefined) ? true : false;
    item.status=(item.status==null)?'':item.status;
    item.jobDesc=(item.jobDesc==null)?'ALL':item.jobDesc;
    if(item.status!=null){
      item.status=item.status.toUpperCase();
    }
  });
  return data;
}

export function dataCheck_emp(data: any[]) {
  _.map(data, (item, index) => {
    item.id = index + 1;
    item.isDisabled = item.companyId === null || item.companyId === 0 || item.shortCode === null || item.shortCode === 0 || item.payrollCode === null || !(item.payrollCode >= 1 && item.payrollCode <= 99) || (item.paygroup === "" || item.paygroup === null || item.paygroup === undefined)  ? true : false;
    item.status=(item.status==null)? '':item.status;
    // why jobdesc to change
    item.jobDesc=(item.jobDesc==null)?'ALL':item.jobDesc;
    if(item.status!=null){

      item.status=item.status.toUpperCase();
    }
  });
  return data;
}

export function data_Check_orgsync(data: any[]) {
  _.map(data, (item, index) => {
    item.id = index + 1;

    item.isDisabled = item.CompanyId === null || item.CompanyId === 0 || item.shortCode === null || item.shortCode === 0 || (item.paygroup === "" || item.paygroup === null || item.paygroup === undefined) ? true : false;
    item.status=(item.status==null||'')?'':item.status;
    if(item.status!=null){
      item.status=item.status.toUpperCase();
    }
  });
  return data;
}
export function data_Check(data: any[]) {
  _.map(data, (item, index) => {
    item.id = index + 1;
    item.isDisabled = item.companyId === null || item.companyId === 0 || (item.paygroup === "" || item.paygroup === null || item.paygroup === undefined) ? true : false;
    item.status=(item.status==null||'')?'':item.status;
    if(item.status!=null){
      item.status=item.status.toUpperCase();
    }
  });
  return data;
}
export function dataCheck_labor(data: any[]) {
  _.map(data, (item, index) => {
    item.id = index + 1;
    item.positionName=(item.positionName==null)?'ALL':item.positionName;
  });
  return data;
}

export function data_Colors(data: any[]) {
  _.map(data, (item, index) => {
    item.id = index + 1;
    if(item.statusMessage=="Success"){
      item.statusMessage=item.statusMessage.toUpperCase();
      item.color='#46CD6F'
    }
    if(item.statusMessage=="Queued"){
      item.statusMessage=item.statusMessage.toUpperCase();
      item.color='#DEF805'
    }
    if(item.statusMessage=="Failed"){
      item.statusMessage=item.statusMessage.toUpperCase();
      item.color='#E3311C'
    }
    if(item.statusMessage=="Pending"){
      item.statusMessage=item.statusMessage.toUpperCase();
      item.color='#4650CD'
    }
    if(item.statusMessage=="Event Paused"){
      item.statusMessage=item.statusMessage.toUpperCase();
      item.color='#FFC433'
    }
    if(item.statusMessage=="Event Stopped"){
      item.statusMessage=item.statusMessage.toUpperCase();
      item.color='#EC33FF'
    }

  });
  return data;
}
export function statusCheck(data: any[]) {
  _.map(data, (item, index) => {
    item.id = index + 1;

    item.transactionStatus=(item.transactionStatus==null||'')?'null':item.transactionStatus;
    if(item.transactionStatus!=null){

      item.transactionStatus=item.transactionStatus.toUpperCase();
    }
  });
  return data;
}

export function statusCheck_EmployeeSync(data: any[]) {
  _.map(data, (item, index) => {
    item.id = index + 1;

    item.status=(item.status==null||'')?'':item.status;
    if(item.status!=null){

      item.status=item.status.toUpperCase();
    }
  });
  return data;
}

export function isChecked(gridData: any[]) {
  return _.some(gridData, (item) => item.ischecked == true);
}



export function  distinctPrimitive(fieldName: string, isNumeric: boolean, gridData: any[]): unknown[] {
  let unique = [];
  const blanks = '(Blanks)';
  if(isNumeric){
    gridData.forEach(element =>
      {
        if(element[fieldName] !== null && element[fieldName] !== '' &&  !unique.includes(element[fieldName])){
          unique.push(element[fieldName]);
        }
        else if((element[fieldName] === null || element[fieldName] === '') && !unique.includes(blanks)){
          unique.push(blanks);
        }
      });
  } else {
    gridData.forEach(element =>
      {
        if(element[fieldName] !== null && element[fieldName].trim() !== '' && !unique.includes(element[fieldName].trim())){
          unique.push(element[fieldName].trim());
        }
        else if((element[fieldName] === null || element[fieldName].trim() === '') && !unique.includes(blanks)){
          unique.push(blanks);
        }
      });
  }
  unique = unique.sort()
  if(unique.includes(blanks)){
    const index = unique.indexOf(blanks, 0);
    if (index > -1) {
      unique.splice(index, 1);
    }
    let blankElement = [blanks];
    unique = blankElement.concat(unique);
  }
  return unique;
}

