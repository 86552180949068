<div class="modal-content">
  <div class="modal-body">
    <div class="col-xs-12">
      <div class="col-xs-5">
        <input type="checkbox" [(ngModel)]="isHomePhoneCall" (ngModelChange)="onHomePhone()" id="homePhone"
          name="homePhone">
        <label for="homePhone">Home Phone</label>
      </div>
      <div class="col-xs-5">
        <div class="form-group form-md-line-input">
          <div class="input-group">
            <slx-phone-input [readonly]="true" className="form-control" class="form-control" name="homePhone" [(ngModel)]="record.homePhone">
            </slx-phone-input>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="col-xs-5">
        <input type="checkbox" [(ngModel)]="isMobilePhoneCall"  (ngModelChange)="onMobilePhone() "id="mobilePhone"
          name="mobilePhone">
        <label for="mobilePhone">Mobile Phone</label>
      </div>
      <div class="col-xs-5">
         <div class="form-group form-md-line-input">
          <div class="input-group">
            <slx-phone-input [readonly]="true" className="form-control" class="form-control" name="mobilePhone" [(ngModel)]="record.mobilePhone">
            </slx-phone-input>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="col-xs-5">
        <input type="checkbox" [(ngModel)]="isAltPhoneCall" (ngModelChange)="onAltPhone()" id="altPhone"
          name="altPhone">
        <label for="altPhone">Alt Phone</label>
      </div>
      <div class="col-xs-5">
        <div class="form-group form-md-line-input">
          <div class="input-group">
            <slx-phone-input [readonly]="true" className="form-control" class="form-control" name="altPhone" [(ngModel)]="record.altPhone">
            </slx-phone-input>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <fieldset>
        <legend>Notes</legend>
        <textarea type="text" maxlength="255" rows="5" placeholder="Enter information for the call log" [(ngModel)]="phoneCall.notes"></textarea>
      </fieldset>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
    <button type="button" (click)="onOk()" class="btn btn-primary">OK</button>
  </div>
</div>
