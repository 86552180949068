import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { StaffingPredictorMapService } from './staffing-predictor-map.service';
import { StaffingRatingContainer, IStaffingRatingContainerDTO, IFiveStarRatingContainerDTO, FiveStarRatingContainer } from '../models/staffing-rating-container';
import { staffingPredictorConfig } from '../staffing-predictor.config';
import { SlxHttpRequest, Meta } from '../../../core/models/index';
import { StaffingPredictorOrganizationInfo, IStaffingPredictorOrganizationInfoDTO, StaffingRatingRequest, IStaffingRatingRequest, StaffingOrganizationInfoRequest, FiveStarPredictorOrganizationInfo, IFiveStarPredictorOrganizationInfoDTO } from '../models/index';
import { GoalSettingRequest, GoalSettingResponse, IGoalSettingResponse } from '../models/goal-setting';
import { ISaveGoalRequest, ISaveGoalResponse, SaveGoalRequest, SaveGoalResponse } from '../models/save-goal';

@Injectable()
export class StaffingPredictorApiService {
    constructor(
        private mapService: StaffingPredictorMapService,
        private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService) {
    }

    public async getRatingList(requestObj: StaffingRatingRequest): Promise<StaffingRatingContainer> {

        const url: string = `${this.getApiUrl()}/${staffingPredictorConfig.api.staffingPredictor.rating}`;
        const body: any = this.mapService.mapToStaffinRatingRequestDto(requestObj);
        const request: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, body);
        const response = await this.apiUtilService.requestNew<IStaffingRatingContainerDTO, Meta>(request);
        return this.mapService.mapToStaffingRatingContainer(response.data);
    }

    public async loadOrganizationInfo(request: StaffingOrganizationInfoRequest): Promise<StaffingPredictorOrganizationInfo> {
        const url: string = `${this.getApiUrl()}/${staffingPredictorConfig.api.staffingPredictor.organization}/${request.organizationId}`;
        const body: any = this.mapService.mapToStaffinRatingOrganizationInfoRequestDto(request);
        const urlRequest: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, body);
        const response = await this.apiUtilService.requestNew<IStaffingPredictorOrganizationInfoDTO, Meta>(urlRequest);
        return this.mapService.mapToOrganizationInfo(response.data);
    }

    public getApiUrl(): string {
        return `${this.apiUtilService.getApiRoot()}/${staffingPredictorConfig.api.staffingPredictor.root}/${staffingPredictorConfig.api.staffingPredictor.predictor}`;
    }

    public async getFiveStarRatingList(requestObj: StaffingRatingRequest): Promise<FiveStarRatingContainer> {
        const url: string = `${this.getApiUrl()}/${staffingPredictorConfig.api.staffingPredictor.pastfuture}`;
        const body: any = this.mapService.mapToStaffinRatingRequestDto(requestObj);
        const request: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, body);
        const response = await this.apiUtilService.requestNew<IFiveStarRatingContainerDTO, Meta>(request);
        return this.mapService.mapToFiveStarRatingContainer(response.data);
    }

    public async loadFiveStarOrganizationInfo(request: StaffingOrganizationInfoRequest): Promise<FiveStarPredictorOrganizationInfo> {
        const url: string = `${this.getApiUrl()}/${staffingPredictorConfig.api.staffingPredictor.organization}2/${request.organizationId}`;
        const body: any = this.mapService.mapToStaffinRatingOrganizationInfoRequestDto(request);
        const urlRequest: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, body);
        const response = await this.apiUtilService.requestNew<IFiveStarPredictorOrganizationInfoDTO, Meta>(urlRequest);
        return this.mapService.mapToFiveStarOrganizationInfo(response.data);
    }

    public async loadGoalSectionInfo(request: GoalSettingRequest): Promise<GoalSettingResponse> {
        const url: string = `${this.getApiUrl()}/${staffingPredictorConfig.api.staffingPredictor.goalSetting}`;
        const body: any = this.mapService.mapGoalSectionReq(request);
        const urlRequest: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, body);
        const response = await this.apiUtilService.requestNew<IGoalSettingResponse, Meta>(urlRequest);
        return this.mapService.mapGoalSectionResponse(response.data);
    }

    public async saveGoal(request: SaveGoalRequest): Promise<SaveGoalResponse> {
        const url: string = `${this.getApiUrl()}/${staffingPredictorConfig.api.staffingPredictor.SavingGoal}`;
        const body: any = this.mapService.mapSaveGoalReq(request);
        const urlRequest: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, body);
        const response = await this.apiUtilService.requestNew<ISaveGoalResponse, Meta>(urlRequest);
        return this.mapService.mapGoalresponse(response.data);
    }

    public async retrieveGoalDetails(request: StaffingOrganizationInfoRequest): Promise<GoalSettingResponse> {
        const url: string = `${this.getApiUrl()}/${staffingPredictorConfig.api.staffingPredictor.retrieveGoal}`;
        const body = this.mapService.mapToStaffinRatingOrganizationInfoRequestDto(request);
        const urlRequest = this.urlParamsService.createPostRequest(url, body);
        const response = await this.apiUtilService.request<GoalSettingResponse, Meta>(urlRequest);
        return response.data!=null ? this.mapService.mapGoalSectionResponse(response.data) : new GoalSettingResponse();
    }
}
