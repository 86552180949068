import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { ScrollElementSelectorDirective } from '../../../../common/directives/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Router, ActivatedRoute } from '@angular/router';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ExceptionConsoleManagementService } from '../../../services/exception-console/exception-console-management.service';
import { ExceptionConsoleNavigationService } from '../../../../common/services/index';
var ExceptionConsoleGridComponent = /** @class */ (function () {
    function ExceptionConsoleGridComponent(managementService, changeDetector, elementRef, router, route) {
        this.total = {};
        this.aggregates = [
            { field: 'totalExceptionsCount', aggregate: 'sum' },
            { field: 'pendingMissingPunchesCount', aggregate: 'sum' },
            { field: 'approvedMissingPunchesCount', aggregate: 'sum' }
        ];
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.elementRef = elementRef;
        this.navService = new ExceptionConsoleNavigationService(router, route);
    }
    ExceptionConsoleGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (container) {
            _this.container = container;
            _this.records = container.records;
            _this.isAllSelected = false;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.loadStateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.state = state;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    ExceptionConsoleGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ExceptionConsoleGridComponent.prototype.onToggleAllSelected = function () {
        var _this = this;
        _.forEach(this.records, function (record) {
            record.isSelected = _this.isAllSelected && record.isSelectable && !record.isLocked;
        });
        this.selectionChange();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ExceptionConsoleGridComponent.prototype.selectionChange = function () {
        var selectedRecords = [];
        selectedRecords = _.filter(this.records, function (record) {
            return record.isSelected === true;
        });
        this.isAllSelected = this.records && selectedRecords ? this.records.length === selectedRecords.length : false;
        this.managementService.onRecordsSelected(selectedRecords);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ExceptionConsoleGridComponent.prototype.onPaycycleClicked = function (item) {
        // rollup grid component always used only in date ranges mode
        var orgLevelId = this.managementService.currentOrgLevel.id;
        this.navService.navigateToExceptionConsoleDates(orgLevelId, item.startDate, item.endDate, true);
    };
    ExceptionConsoleGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        if (this.state.exceptionOnly) {
            this.filteredRecords = _.filter(this.records, function (record) {
                var isException = record.totalExceptionsCount > 0;
                if (isException) {
                    return true;
                }
                _.forEach(_this.state.columns, function (col) {
                    if (col.payload) {
                        var payload = col.payload;
                        if (payload.countAsException) {
                            var rec = record;
                            var count = rec[col.name];
                            if (count > 0) {
                                isException = true;
                                return;
                            }
                        }
                    }
                });
                return isException;
            });
        }
        else {
            this.filteredRecords = this.records;
        }
        this.gridState.view = process(this.filteredRecords, this.gridState.state);
        this.total = aggregateBy(this.gridState.view.data, this.aggregates);
        if (this.state.dynamicColumns && this.state.dynamicColumns.length > 0) {
            _.each(this.state.dynamicColumns, function (c) {
                _this.total[c.name] = 0;
                _.each(_this.gridState.view.data, function (r) {
                    _this.total[c.name] += r.exceptionsMap[c.name].exceptionCount;
                });
            });
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleGridComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleGridComponent.prototype, "recordsSelectedSubscription", void 0);
    return ExceptionConsoleGridComponent;
}());
export { ExceptionConsoleGridComponent };
