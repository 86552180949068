/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./collapsible-section.component";
import * as i3 from "../../services/collapsible-section.service";
var styles_CollapsibleSectionComponent = [];
var RenderType_CollapsibleSectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollapsibleSectionComponent, data: {} });
export { RenderType_CollapsibleSectionComponent as RenderType_CollapsibleSectionComponent };
function View_CollapsibleSectionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "slx-collapsible-section__title-icon"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-collapsible-section__title-icon"; var currVal_1 = _co.customIcon; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CollapsibleSectionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_CollapsibleSectionComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "slx-collapsible-section__title-counter"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.counter; _ck(_v, 1, 0, currVal_0); }); }
function View_CollapsibleSectionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "h3", [["class", "slx-collapsible-section__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "slx-collapsible-section__right"]], [[2, "padd-l", null]], null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(10, 0, null, null, 3, "span", [["class", "slx-collapsible-section__icon"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleSection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "i", [["aria-hidden", "true"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(13, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasCustomIcon; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.emptyHeader; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.hasCounter; _ck(_v, 7, 0, currVal_2); var currVal_5 = _co.toggleIconClass(); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.emptyHeader; _ck(_v, 8, 0, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(1, "", _co.toggleIconTitle(), ""); _ck(_v, 10, 0, currVal_4); }); }
function View_CollapsibleSectionComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "slx-collapsible-section__title-icon"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-collapsible-section__title-icon"; var currVal_1 = _co.customIcon; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CollapsibleSectionComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_CollapsibleSectionComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "slx-collapsible-section__title-counter"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.counter; _ck(_v, 1, 0, currVal_0); }); }
function View_CollapsibleSectionComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "h3", [["class", "slx-collapsible-section__header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleSection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_6)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_7)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_8)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "slx-collapsible-section__right"]], [[2, "padd-l", null]], null, null, null, null)), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(10, 0, null, null, 3, "span", [["class", "slx-collapsible-section__icon"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "i", [["aria-hidden", "true"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(13, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasCustomIcon; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.emptyHeader; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.hasCounter; _ck(_v, 7, 0, currVal_2); var currVal_5 = _co.toggleIconClass(); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.emptyHeader; _ck(_v, 8, 0, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(1, "", _co.toggleIconTitle(), ""); _ck(_v, 10, 0, currVal_4); }); }
export function View_CollapsibleSectionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "section", [["class", "slx-collapsible-section"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleSectionComponent_5)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "slx-collapsible-section__content"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(9, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(10, { "shown": 0 }), i0.ɵncd(null, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-collapsible-section"; var currVal_1 = _co.getModeClass(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.headerOpenClose; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.headerOpenClose; _ck(_v, 6, 0, currVal_3); var currVal_4 = "slx-collapsible-section__content"; var currVal_5 = _ck(_v, 10, 0, _co.isShown()); _ck(_v, 9, 0, currVal_4, currVal_5); }, null); }
export function View_CollapsibleSectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-collapsible-section", [], null, null, null, View_CollapsibleSectionComponent_0, RenderType_CollapsibleSectionComponent)), i0.ɵdid(1, 770048, null, 0, i2.CollapsibleSectionComponent, [i3.CollapsibleSectionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CollapsibleSectionComponentNgFactory = i0.ɵccf("slx-collapsible-section", i2.CollapsibleSectionComponent, View_CollapsibleSectionComponent_Host_0, { uniqId: "uniqId", title: "title", expanded: "expanded", customIcon: "customIcon", emptyHeader: "emptyHeader", counter: "counter", mode: "mode", headerOpenClose: "headerOpenClose" }, { expandChanged: "expandChanged" }, ["[header]", "[header]", "[body]"]);
export { CollapsibleSectionComponentNgFactory as CollapsibleSectionComponentNgFactory };
