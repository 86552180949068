var PmTemplate = /** @class */ (function () {
    function PmTemplate() {
    }
    return PmTemplate;
}());
export { PmTemplate };
var PmTemplateDefinition = /** @class */ (function () {
    function PmTemplateDefinition() {
    }
    Object.defineProperty(PmTemplateDefinition.prototype, "modifiedUserName", {
        get: function () {
            return this.modifiedBy || this.addedBy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmTemplateDefinition.prototype, "fileName", {
        get: function () {
            if (this.attachment) {
                return this.attachment.fileName || '';
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmTemplateDefinition.prototype, "modifiedDate", {
        get: function () {
            return this.modifiedOn || this.addedOn;
        },
        enumerable: true,
        configurable: true
    });
    return PmTemplateDefinition;
}());
export { PmTemplateDefinition };
