import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';

import { PbjReconciliationManagementService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-reconciliation',
  templateUrl: 'pbj-reconciliation.component.html',
  styleUrls: ['pbj-reconciliation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbjReconciliationComponent implements OnInit, OnDestroy {
  public orgLevel: OrgLevel;
  public appConfig: IApplicationConfig;
  public expanded: boolean;
  public currentEmpId: number;
  public currentEmpName: string;
  public parentOrganizationName: string;
  public isLoading: boolean;

  @destroyService()
  private managementService: PbjReconciliationManagementService;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;

  constructor(
    managementService: PbjReconciliationManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private orgLevelWatchService: OrgLevelWatchService
  ) {
    this.appConfig = appConfig;
    this.managementService = managementService;
    this.currentEmpId = 0;
    this.isLoading = true;
  }

  public ngOnInit(): void {
    this.managementService.init(this.router, this.route, this.expanded = true);

    this.loadStatusSubscription = this.managementService.onLoadStatus$.subscribe((state: boolean) => {
      this.isLoading = state;
      this.cdr.detectChanges();
    });
    this.orgLevelSubscription = this.managementService.subscribeToOrgLevel((orgLevel: OrgLevel) => {
      this.orgLevel = orgLevel;
      const test = this.hasOrglevel;
      if (this.isDepartment) {
        this.findParentOrgLevel();
      }
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.managementService.destroy();
  }

  public get hasOrglevel(): boolean {
    return _.isFinite(_.get(this.orgLevel, 'id'));
  }

  public get isDepartment(): boolean {
    return _.get(this.orgLevel, 'type') === OrgLevelType.department;
  }

  public get isOrganization(): boolean {
    return _.get(this.orgLevel, 'type') === OrgLevelType.organization;
  }

  public get isAboveOrganization(): boolean {
    return this.hasOrglevel && !this.isDepartment && !this.isOrganization;
  }

  private async findParentOrgLevel(): Promise<any> {
    let orgLevel: OrgLevel = await this.orgLevelWatchService.getOrgLevelByIdSafe(this.orgLevel.parentId);
    this.parentOrganizationName = _.get(orgLevel, 'name');
  }
}
