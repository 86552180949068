import { PayPolicy } from './../../organization/models/lookup/pay-policy';
import { dateTimeUtils } from './../../common/utils/dateTimeUtils';
import { Injectable } from '@angular/core';
import { Assert } from '../../framework/index';
import { LookupDefinition, LookupType, LookupEntity } from '../../../app/organization/index';

import * as moment from 'moment';
import * as _ from 'lodash';
import { v4 as UUID } from 'uuid';

import {
  ReportDefinition, IReportDefinition,
  ReportParameter, IReportParameter,
  ReportGroup, IReportParameterType, IReportStaffingSetting, IReportConfigPositionCategory, ReportConfigPositionCategory, ReportStaffingSetting, ReportAttendanceSetting, IReportAttendanceSetting, GetBIPAReportDefinition, BIPAShiftGroupTime, BIPATableData, IGetBIPAReportDefinition, MidnightToMidnightReport, IMidnightToMidnightReport, IMidnightToMidnightRequestData
} from '../models/index';
import { FieldsMeta } from './../../../app/core/models';


@Injectable()
export class ReportsMapService {

  public editLaborReportConfigActionName: string = "Edit Labor Report Position Settings";

  public mapReportsDtoToReportGroups(reportsDto: IReportDefinition[]): ReportGroup[] {
    let reportGroups: ReportGroup[] = [];
    let reports: ReportDefinition[] = _.map<IReportDefinition, ReportDefinition>(reportsDto,
      (item: IReportDefinition) => this.mapReportDtoToReport(item));
    let reportsByGroups: _.Dictionary<ReportDefinition[]> = _.groupBy(reports, (item: ReportDefinition): string => {
      return item.group;
    });

    _.each(reportsByGroups, (value: ReportDefinition[], key: string) => {
      let reportGroup: ReportGroup = new ReportGroup();
      reportGroup.name = key;
      reportGroup.reports = value;
      reportGroups.push(reportGroup);
    });

    return reportGroups;
  }

  public mapReportDefinitionToRequest(reportDefinition: ReportDefinition): IReportDefinition {
    let reportRequest: IReportDefinition = {
      group: reportDefinition.group,
      folder: reportDefinition.folder,
      name: reportDefinition.name,
      displayName: reportDefinition.displayName,
      pageSize: reportDefinition.pageSize,
      pageLayout: reportDefinition.pageLayout,
      exportType: reportDefinition.exportType,
      reportConfigId: reportDefinition.reportConfigId,
      parameters: _.map(reportDefinition.parameters, (parameter: ReportParameter) => this.mapReportParameterReportParameterDto(parameter))
    };
    return reportRequest;
  }

  private mapReportDtoToReport(reportDto: IReportDefinition): ReportDefinition {
    let report: ReportDefinition = new ReportDefinition();
    report.displayName = reportDto.displayName;
    report.folder = reportDto.folder;
    report.group = reportDto.group;
    report.name = reportDto.name;
    report.pageLayout = reportDto.pageLayout;
    report.pageSize = reportDto.pageSize;
    report.exportType = reportDto.exportType;
    report.reportConfigId = reportDto.reportConfigId;
    report.parameters = _.map<IReportParameter, ReportParameter>(reportDto.parameters, (item: IReportParameter) => {
      let parameter: ReportParameter = this.mapReportParameterDtoToReportParameter(item);
      return parameter;
    });

    _.each(report.parameters, (p: ReportParameter) => {
      p.reportDefinition = report;
    });

    return report;
  }

  private mapReportParameterDtoToReportParameter(reportParameterDto: IReportParameter): ReportParameter {
    let reportParameter: ReportParameter = new ReportParameter();
    reportParameter.dataType = this.mapReportParameterType(reportParameterDto);
    reportParameter.isVisible = reportParameterDto.isVisible;
    reportParameter.defaultValue = reportParameterDto.defaultValue;
    reportParameter.displayName = reportParameterDto.displayName;
    reportParameter.name = reportParameterDto.name;
    reportParameter.isMultiselect = reportParameterDto.isMultiselect;
    reportParameter.value = !(_.isUndefined(reportParameterDto.value) || _.isNull(reportParameterDto.value))
      ? reportParameterDto.value : undefined;
    return reportParameter;
  }

  private mapReportParameterReportParameterDto(reportParameter: ReportParameter): IReportParameter {
    let reportParameterDto: IReportParameter = {
      dataType: reportParameter.dataType.name,
      lookupName: reportParameter.dataType.lookupName,
      isVisible: reportParameter.isVisible,
      defaultValue: reportParameter.defaultValue,
      displayName: reportParameter.displayName,
      name: reportParameter.name,
      isMultiselect: reportParameter.isMultiselect,
      value: reportParameter.value
    };
    return reportParameterDto;
  }

  private mapLookupType(lookupType: string): LookupDefinition {
    switch (lookupType) {
      case 'positions':
        return LookupType.position;
      case 'shifts':
        return LookupType.shift;
      case 'units':
        return LookupType.locationUnit;
      case 'paypolicies':
        return LookupType.payPolicy;
      case 'paycycles':
        return LookupType.payCycles;
      case 'employees':
        return LookupType.employeeList;
      case 'department':
        return LookupType.department;
      case 'agencies':
        return LookupType.agenciesReports;
      case 'emptypes':
        return LookupType.empType;
      case 'monthswithall':
        return LookupType.months;
      case 'retroreportoptns':
        return LookupType.retroReportOptions;
      default:
        throw new Error(`Can't map lookup type: ${lookupType}.`);
    }
  }

  private mapReportParameterType(reportParameter: IReportParameter): IReportParameterType {
    let reportParameterType: IReportParameterType = {
      name: reportParameter.dataType,
      isLookup: !!reportParameter.lookupName,
      lookupName: reportParameter.lookupName ? this.mapLookupType(reportParameter.lookupName) : undefined,
      isMultipleValues: reportParameter.isMultiselect
    };
    return reportParameterType;
  }

  public mapSettingsDTOReportStaffingSettings(reportStaffingSettingDTO: IReportStaffingSetting): ReportStaffingSetting {
    const reportStaffingSetting = new ReportStaffingSetting();
    reportStaffingSetting.departmentId = reportStaffingSettingDTO.departmentId;
    reportStaffingSetting.time1 = dateTimeUtils.convertFromDtoDateTimeString(reportStaffingSettingDTO.time1);
    reportStaffingSetting.time2 = dateTimeUtils.convertFromDtoDateTimeString(reportStaffingSettingDTO.time2);
    reportStaffingSetting.time3 = dateTimeUtils.convertFromDtoDateTimeString(reportStaffingSettingDTO.time3);
    reportStaffingSetting.positionCategory = this.mapPositionCategory(reportStaffingSettingDTO.positionCategory);
    return reportStaffingSetting;
  }
  public mapToReportStaffingSettingsDTO(req: ReportStaffingSetting): IReportStaffingSetting {
    const dto = {} as IReportStaffingSetting;
    dto.departmentId = req.departmentId;
    dto.time1 = dateTimeUtils.convertToDtoDateTimeString(req.time1);
    dto.time2 = dateTimeUtils.convertToDtoDateTimeString(req.time2);
    dto.time3 = dateTimeUtils.convertToDtoDateTimeString(req.time3);
    dto.positionCategory = this.mapPositionCategory(req.positionCategory);
    return dto;
  }

  public mapSettingsDTOReportAttendanceSettings(reportAttendanceSettingDTO: IReportAttendanceSetting): ReportAttendanceSetting {
    const reportAttendanceSetting = new ReportAttendanceSetting();
    reportAttendanceSetting.departmentId = reportAttendanceSettingDTO.departmentId;
    reportAttendanceSetting.positionCategory = this.mapPositionCategory(reportAttendanceSettingDTO.positionCategory);
    return reportAttendanceSetting;
  }

  public mapToReportAttendanceSettingsDTO(req: ReportAttendanceSetting): IReportAttendanceSetting {
    const dto = {} as IReportAttendanceSetting;
    dto.departmentId = req.departmentId;
    dto.positionCategory = this.mapPositionCategory(req.positionCategory);
    return dto;
  }


  private mapPositionCategory(reportConfigPositionCategoryDTO: IReportConfigPositionCategory[]): IReportConfigPositionCategory[] {
    const reportConfigPositionCategory: IReportConfigPositionCategory[] = [];
    _.forEach(reportConfigPositionCategoryDTO, (a) => {
      const positionCategory: ReportConfigPositionCategory = this.mapPosition(a);
      reportConfigPositionCategory.push(positionCategory);
    });
    return reportConfigPositionCategory;
  }

  private mapPosition(positionCategoryDTO: IReportConfigPositionCategory): IReportConfigPositionCategory {
    const positionCategory: IReportConfigPositionCategory = {
      jobCode: positionCategoryDTO.jobCode,
      position: positionCategoryDTO.position,
      positionCategoryType: positionCategoryDTO.positionCategoryType
    };
    return positionCategory;
  }

  public mapBIPAReportData(dto: IGetBIPAReportDefinition) {
    let bipaReportData: GetBIPAReportDefinition = new GetBIPAReportDefinition();
    bipaReportData.showUnitsOnBipaReportKey = dto.showUnitsOnBipaReportKey;
    bipaReportData.showUnitsOnBipaReportValue = dto.showUnitsOnBipaReportValue === 'true' ? true : false;
    bipaReportData.showShiftsOnBipaReportKey = dto.showShiftsOnBipaReportKey;
    bipaReportData.showShiftsOnBipaReportValue = dto.showShiftsOnBipaReportValue === 'true' ? true : false;
    bipaReportData.shiftgroups = _.map(dto.shiftgroups, (item) => this.mapShiftGroup(item));
    bipaReportData.slxCmsPositions = _.map(dto.slxCmsPositions, (item) => this.mapCmsSlxData(item));
    return bipaReportData;
  }

  public mapShiftGroup(dto: BIPAShiftGroupTime) {
    let data: BIPAShiftGroupTime = new BIPAShiftGroupTime();
    data.shiftType = dto.shiftType;
    data.shiftStartTime = dto.shiftStartTime;
    return data;
  }

  public mapCmsSlxData(dto: BIPATableData) {
    let data: BIPATableData = new BIPATableData();
    data.slxPosition = dto.slxPosition;
    data.cmsJobDescription = dto.cmsJobDescription;
    return data;
  }

  public mapMidnightToMidnightConfigDetails(dto: IMidnightToMidnightReport[], metaData: FieldsMeta): MidnightToMidnightReport[] {
    return _.map(dto, (item: IMidnightToMidnightReport) => this.mapMidnightToMidnightConfigData(item, metaData));
  }

  public mapMidnightToMidnightConfigData(dto: IMidnightToMidnightReport, metaData: FieldsMeta): MidnightToMidnightReport {
    const data: MidnightToMidnightReport = new MidnightToMidnightReport();
    data.id = UUID();
    data.jobcode = dto.jobcode;
    data.position = dto.position;
    data.departmentId = dto.departmentId;
    data.department = dto.department;
    data.budgetedPosition = dto.budgetedPosition;
    data.hourPercentage = (dto.hourPercentage === null) ? 100 : +dto.hourPercentage;
    data.editPermission = metaData.actions.includes(this.editLaborReportConfigActionName) ? true : false;
    return data;
  }

  public reverseMapMidnightToMidnightConfigData(dto: MidnightToMidnightReport[]): IMidnightToMidnightRequestData[] {
    return _.map(dto, (item: MidnightToMidnightReport) => this.reverseMapMidnightToMidnightReportRequest(item));
  }

  public reverseMapMidnightToMidnightReportRequest(dto: MidnightToMidnightReport): IMidnightToMidnightRequestData {
    let result: IMidnightToMidnightRequestData = <IMidnightToMidnightRequestData>{};
    result.jobcode = dto.jobcode;
    result.value = dto.hourPercentage;
    return result;
  }

}
