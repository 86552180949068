import { PayrollExportTestManagementService } from './../../../services/payroll-export-test/payroll-export-test-management.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { Router, ActivatedRoute } from '@angular/router';
import { PayrollExportNavigationService } from '../../../../../common/services/navigation/payroll-export-navigation.service';
import { PayCycle } from '../../../../../organization/models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-payroll-export-test',
    templateUrl: 'payroll-export-test.component.html',
    styleUrls: ['payroll-export-test.component.scss'],
    providers: [PayrollExportTestManagementService],
})
export class PayrollExportTestComponent implements OnInit, OnDestroy {

    @unsubscribe()
    private urlChangeSubscription: Subscription;
    @unsubscribe()
    private loadStatusSubscription: Subscription;

    public isLoading: boolean = false;

    constructor(public manageService: PayrollExportTestManagementService, private router: Router, private activatedRoute: ActivatedRoute) {

    }

    public ngOnInit(): void {
        this.loadStatusSubscription = this.manageService.loadingChanged.subscribe((value: boolean) => {
            setTimeout(() => {
                this.isLoading = value;
            });
        });

        this.urlChangeSubscription = this.manageService.urlParamsChanged.subscribe((p: PayCycle) => {
            let service = new PayrollExportNavigationService(this.router, this.activatedRoute);
            service.navigateToPayrollExport(p.startDate, p.endDate);
        });
    }

    public ngOnDestroy(): void {
        // See #issueWithAOTCompiler
    }
}
