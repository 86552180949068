import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TreeModel } from '../../models/tree.model';

@Component({
  moduleId: module.id,
  selector: 'slx-tree-root-component',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['tree-root.component.scss'],
  templateUrl: 'tree-root.component.html'
})
export class TreeRootComponent {
  @Input() public treeModel: TreeModel;
  @Input() public templates: any;
}
