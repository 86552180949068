import { Injectable } from "@angular/core";
import * as _ from "lodash";
import {NotificationGroupEvent} from "../../models/notifications/notifications-group-event"
import { Assert } from "../../../framework";
import { NotificationGroup } from "../../models";
import { dateTimeUtils } from '../../../common/utils/index';
import { NotificationEventType } from "../../models/notifications/notifications-event-type";
import { NotificationRole } from "../../models/notifications/notifications-role";

@Injectable()
export class NotificationsMapService {

  constructor( ) { }

  public mapNotificationGroups(dtos: any): NotificationGroup [] {  
    Assert.isNotNull(dtos);
    let data: NotificationGroup[] = [];  
    _.each(dtos, (dto: any) => {        
      data.push(this.mapNotificationGroup(dto));    
    });    
    
    return data;    
  }
  
  public mapNotificationGroup(dto: any): NotificationGroup {
    let modifiedOn= dateTimeUtils.convertToDtoString(dto.modifiedOn);
    let model: NotificationGroup = new NotificationGroup();
    model.deliveryMethod = dto.deliveryMethod;   
    model.modifiedOn=dateTimeUtils.convertFromDtoString(modifiedOn)
    model.modifiedBy = dto.modifiedBy;
    model.groupEvents = new NotificationGroupEvent();
    model.groupEvents.eventGroupId = dto.groupEvents.eventGroupId;
    model.groupName = dto.groupName;  
    model.groupId = dto.groupId; 
    model.groupEvents.events = [];
    _.each(dto.groupEvents.events, (edto: any) => {
        model.groupEvents.events.push(this.mapNotificationEventType(edto));
    });
    model.groupRoles = [];
    _.each(dto.groupRoles, (edto: any) => {
        model.groupRoles.push(this.mapNotificationRole(edto));
    });
    return model;
  }

  public mapNotificationEventType(dto: any): NotificationEventType {
    let model: NotificationEventType = new NotificationEventType(dto.id,'');
    model.eventId = dto.eventId;  
    return model;
  }

  public mapNotificationRole(dto: any): NotificationRole {
    let model: NotificationRole = new NotificationRole(dto.roleId,dto.name);
    model.roleId = dto.roleId;    
    return model;
  }
  public mapNotificationGroupEvent(dtos:NotificationGroupEvent):NotificationGroupEvent[]{
    Assert.isNotNull(dtos);
    let data: NotificationGroupEvent[] =[];    
    _.each(dtos, (dto: any) => {  
      data.push(this.mapNotificationEventGroup(dto))  
      
    });
    
  return data;

  }
  public mapNotificationEventGroup(dto:any):NotificationGroupEvent{
    let model:NotificationGroupEvent = new NotificationGroupEvent();
    model.eventGroupId = dto.eventGroupId;
    model.name = dto.name;   
    model.events = [];
    _.each(dto.events, (edto: any) => {
      model.events.push(this.mapNotificationEvent(edto));
    });
    
    return model
  }
  public mapNotificationEvent(dto:any):NotificationEventType{
    let model: NotificationEventType = new NotificationEventType(dto.id,'');
    model.eventId = dto.eventId; 
    model.name=dto.notificationType; 
    return model;
    
  }

}