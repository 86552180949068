<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <form novalidate class="slx-content-toolbar-indents">
      <div class="employee-call-logs" [ngClass]="screenWidth >1024 ? 'employee-header' : '' ">
        <slx-date-range-ngx
          name="startEndDate"          [startDate]="state.startDate"
          [endDate]="state.endDate"
          [hasApplyButton]="true" [isSmallDevice]="true"
          [maxSecRange]="15552000"
          [maxRangeErrorText]="'You can not choose dates greater than 180 days'"
          (rangeDateChanged)="onDataRangeChange($event)"
        ></slx-date-range-ngx>
        <span class="col-sm-4 col-md-4 col-lg-6 employee-filter filter mobile-align" [ngClass]=" this.screenWidth > 780 && this.screenWidth < 832 && this.state.isLeftSidebarOpen? 'set-margin' : ''">
          <slx-input-decorator class="slx-auto-height" >
            <slx-dropdown-multiselect slx-input
              [lookup]="{ lookupType: 'employeeListByMasterId', orgLevelId: orgLevel.id, isActive: false }"
              [(ngModel)]="empFilter"
              (ngModelChange)="onEmployeesChange($event)"
              placeholder="Employee Filter"
              name="empFilter"
            ></slx-dropdown-multiselect>
          </slx-input-decorator>
        </span>
        <span class="col-sm-6 col-md-2" [ngClass]="screenWidth > 767 || (this.screenWidth > 780 && this.screenWidth < 832 && this.state.isLeftSidebarOpen)? 'action-button' : ''">
        <slx-actions-list>
          <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-start'">
            Actions <span class="caret"></span>
          </slx-actions-button>
          <popper-content #popperContent>
            <slx-actions-list-item *ngFor="let action of actions" (onClick)="showWindow(action)" [disabled]="!action.enabled">{{action.displayName}}</slx-actions-list-item>
          </popper-content>
        </slx-actions-list>
        </span>
      </div>
    </form>
    <div class="slx-high-box__body slx-main-content-indents">
      <slx-employee-call-log-grid [pageSize]="pageSize" [employeeLink]="employeeLink"></slx-employee-call-log-grid>
    </div>
  </div>
</slx-spinner>
