import * as tslib_1 from "tslib";
import { FieldData } from './../../../../../core/models/field/field-data';
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { unsubscribe } from '../../../../../core/decorators/index';
import { Assert } from '../../../../../framework/index';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper, ModalService, ConfirmOptions, ConfirmDialogComponent, saveEvent, cancelEvent, ChangeManagementService } from '../../../../../common/index';
import { mutableSelect } from '../../../../../core/decorators/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsACA, EmployeeACA } from '../../../models/employee-sections-employment/employee-sections-aca';
var EmployeeSectionsAcaComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAcaComponent, _super);
    function EmployeeSectionsAcaComponent(modalService, employeeSectionsEmployementApiService, decorator, ngZone, changeDetector, changeService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.modalService = modalService;
        _this.changeDetector = changeDetector;
        _this.changeService = changeService;
        _this.sort = [];
        _this.minDate = new Date(1900, 1, 1);
        _this.now = moment().startOf('day').toDate();
        _this.overlapStartDate = false;
        _this.overlapEndDate = false;
        _this.gapStartDate = false;
        _this.gapEndDate = false;
        _this.dateError = false;
        _this.isAnnualIncomeRequired = false;
        _this.nonEmployeeOptions = ['Yes', 'No'];
        _this.incomeCalcOptions = ['System-Calculated', 'Employee-Provided'];
        _this.declinedOfferOptions = ['Yes', 'No'];
        _this.marketplacePurchaseOptions = ['Yes', 'No'];
        _this.annualIncomeLimit = 9999999999.99;
        _this.deleteBtnTooltip = 'Deleting this record is not allowed as it will create a gap.';
        _this.isAddNewAccessible = true;
        _this.decorator.isEditableByConfiguration = false;
        Assert.isNotNull(employeeSectionsEmployementApiService, 'employeeSectionsAccrualsApiService');
        _this.employeeSectionsEmployementApiService = employeeSectionsEmployementApiService;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.skip = 0;
        _this.gridState.state.sort = [{ field: 'startDate', dir: 'desc' }];
        _this.gridState.state.take = 5;
        _this.createSubscriptions();
        return _this;
    }
    Object.defineProperty(EmployeeSectionsAcaComponent.prototype, "aca", {
        set: function (employeeSectionsACA) {
            this.employeeSectionsACA = employeeSectionsACA;
            var canEdit = _.get(employeeSectionsACA, 'actions.canEdit', null);
            if (_.isBoolean(canEdit)) {
                this.canEdit = canEdit;
            }
            this.originalACA = _.cloneDeep(employeeSectionsACA);
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAcaComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAcaComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAcaComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    EmployeeSectionsAcaComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function (item) {
            _this.editingItem = item;
            _this.addMode = false;
            item.isAddEditMode = true;
            _this.changeService.changeNotify();
        });
        this.gridCancelSubscription = this.gridState.onCancel$.subscribe(function (item) {
            _this.resetAddMode();
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (item) {
            _this.gridSaveSubscribe(item.dataItem, item.isNew);
        });
    };
    EmployeeSectionsAcaComponent.prototype.onDelete = function (item) {
        this.gridRemoveSubscribe(item);
    };
    EmployeeSectionsAcaComponent.prototype.onCancel = function (item, rowIndex) {
        item.isAddEditMode = false;
        var cancelEvent = { sender: this.grid, rowIndex: rowIndex, dataItem: item, isNew: false };
        this.gridState.cancelHandler(cancelEvent);
    };
    EmployeeSectionsAcaComponent.prototype.gridSaveSubscribe = function (item, isNew) {
        var _this = this;
        var selAcaDates = '';
        var acaList = _.without(this.employeeSectionsACA.records, item);
        _.forEach(acaList, function (el) {
            var startDate = 'Start Date: ' + (moment(el.startDate).format(appConfig.dateFormat));
            var endDate = 'End Date: ' + (el.endDate ? (moment(el.endDate).format(appConfig.dateFormat)) : '');
            if (item.endDate) {
                if (el.startDate >= item.startDate && el.startDate <= item.endDate && el.endDate <= item.endDate && el.endDate != null) {
                    selAcaDates = selAcaDates == '' ? (startDate + ' - ' + endDate) : selAcaDates + ', ' + (startDate + ' - ' + endDate);
                }
            }
            else {
                if (el.startDate >= item.startDate) {
                    selAcaDates = selAcaDates == '' ? (startDate + ' - ' + endDate) : selAcaDates + ', ' + (startDate + ' - ' + endDate);
                }
            }
        });
        if (selAcaDates != '') {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialogComponent.openDialog('Warning', 'These records (' + selAcaDates + ') will be deleted, Would you like to continue?', this.modalService, function (result) {
                if (result) {
                    _this.saveChanges(item, isNew);
                }
                else {
                    _this.resetPreviousDates(item);
                }
            }, options);
        }
        else {
            this.saveChanges(item, isNew);
        }
        this.resetAddMode();
    };
    EmployeeSectionsAcaComponent.prototype.resetPreviousDates = function (item) {
        if (item.id != 0) {
            var orgPA = _.find(this.originalACA.records, ['id', item.id]);
            item.startDate = new Date(orgPA.startDate);
            if (item.endDate) {
                item.endDate = new Date(orgPA.endDate);
            }
            this.changeDetected();
        }
    };
    EmployeeSectionsAcaComponent.prototype.resetAddMode = function () {
        this.addMode = false;
        this.editingItem = null;
        this.dateError = false;
        this.overlapStartDate = false;
        this.overlapEndDate = false;
        this.gapStartDate = false;
        this.gapEndDate = false;
        this.changeDetected();
    };
    EmployeeSectionsAcaComponent.prototype.saveChanges = function (item, isNew) {
        if (isNew) {
            var source = this.employeeSectionsACA.records;
            this.employeeSectionsACA.records = _.concat(source, [item]);
        }
        this.confirmSaveChanges(item);
    };
    EmployeeSectionsAcaComponent.prototype.changeDetected = function () {
        this.changeService.clearChanges();
        this.changeService.clearCurrentComponentId();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsAcaComponent.prototype.gridRemoveSubscribe = function (item) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure that you want to remove this?', this.modalService, function (result) {
            if (result) {
                _this.doRemove(item);
                _this.changeDetected();
            }
        }, options);
    };
    EmployeeSectionsAcaComponent.prototype.confirmSaveChanges = function (item) {
        var _this = this;
        this.startProgress();
        item.empId = this.employeeId;
        this.employeeSectionsEmployementApiService.postEmployeeACA(this.employeeId, item)
            .then(function (employeeSectionsACA) {
            _this.stopProgress();
            _this.loadSubsection();
        }).catch(function (reason) {
            _this.stopProgress();
            _this.loadSubsection();
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsAcaComponent.prototype.doRemove = function (item) {
        item.deleted = true;
        this.confirmSaveChanges(item);
    };
    EmployeeSectionsAcaComponent.prototype.minDateLimit = function (item) {
        if (_.isNil(item)) {
            return this.minDate;
        }
        if (this.employeeStatus.toLowerCase() === "future rehire") {
            return this.employeeRehireDate;
        }
        if (this.employeeShortInfo && this.employeeShortInfo.dateHired) {
            return this.employeeShortInfo.dateHired;
        }
        return this.now;
    };
    EmployeeSectionsAcaComponent.prototype.isRequiredEndDate = function (item) {
        if (item && item.id == 0) {
            return false;
        }
        return true;
    };
    EmployeeSectionsAcaComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsACA;
    };
    EmployeeSectionsAcaComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmployementApiService.getEmployeeACA(this.employeeId)
            .then(function (employeeSectionsACA) {
            _this.employeeSectionsACA = employeeSectionsACA;
            _this.state.isLoaded = true;
            _this.gridState.state.skip = 0;
            _this.canEdit = _.get(_this.employeeSectionsACA, 'actions.canEdit', false);
            _this.refreshGrid();
            _this.originalACA = _.cloneDeep(employeeSectionsACA);
            _this.stopProgress();
            _this.changeDetected();
        }).catch(function () {
            _this.stopProgress();
        });
    };
    Object.defineProperty(EmployeeSectionsAcaComponent.prototype, "valid", {
        get: function () {
            if ((this.ngFormChild && !this.ngFormChild.valid) || this.overlapStartDate || this.overlapEndDate || this.gapStartDate || this.gapEndDate || this.dateError) {
                return false;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAcaComponent.prototype.refreshGrid = function () {
        if (!this.employeeSectionsACA) {
            this.gridView = null;
            return;
        }
        if (this.employeeSectionsACA && this.employeeSectionsACA.records && _.size(this.employeeSectionsACA) > 0) {
            var sortedList = _.orderBy(this.employeeSectionsACA.records, ['startDate'], ['desc']);
            var count_1 = 0;
            _.forEach(sortedList, function (el) {
                if (count_1 == 0) {
                    el.isDisabledDel = false;
                }
                else {
                    el.isDisabledDel = true;
                }
                count_1++;
            });
            if (!_.isNull(this.employeeSectionsACA.records) && _.size(this.employeeSectionsACA.records) > 0) {
                this.isAddNewAccessible = _.first(this.employeeSectionsACA.records).annualIncome.securityAttribute.editable;
            }
        }
        this.gridState.view = process(this.employeeSectionsACA.records, this.gridState.state);
    };
    EmployeeSectionsAcaComponent.prototype.onAddNew = function () {
        this.grid.addRow(this.addNewRow());
        this.changeService.changeNotify();
    };
    EmployeeSectionsAcaComponent.prototype.addNewRow = function () {
        this.addMode = true;
        var annualIncome = new FieldData();
        annualIncome.fieldValue = 0;
        var newEmpACA = new EmployeeACA();
        var defaultDate = moment().startOf('year').toDate();
        if (this.employeeShortInfo && this.employeeShortInfo.dateHired && this.employeeShortInfo.dateHired > defaultDate) {
            newEmpACA.startDate = this.employeeShortInfo.dateHired;
        }
        else {
            newEmpACA.startDate = defaultDate;
        }
        newEmpACA.endDate = null;
        newEmpACA.id = 0;
        newEmpACA.incomeCalculationType = 'System-Calculated';
        newEmpACA.isEmployee = false;
        newEmpACA.isSystemCalculated = true;
        newEmpACA.nonEmployee = 'No';
        newEmpACA.annualIncome = annualIncome;
        newEmpACA.isAddEditMode = true;
        newEmpACA.declinedOffer = 'No';
        newEmpACA.marketplacePurchase = 'No';
        return newEmpACA;
    };
    EmployeeSectionsAcaComponent.prototype.onChangedDate = function (item, isStartDate) {
        var overlap = this.validateOverlaps(item, isStartDate);
        if (!overlap) {
            this.validateGapExists(item, isStartDate);
        }
    };
    EmployeeSectionsAcaComponent.prototype.validateOverlaps = function (item, isStartDate) {
        var isOverlap = false;
        if (item.id != 0) {
            var acaList = _.without(this.employeeSectionsACA.records, item);
            _.forEach(acaList, function (el) {
                var range = moment.range(el.startDate, el.endDate);
                isOverlap = range.contains(isStartDate ? item.startDate : item.endDate);
                if (isOverlap) {
                    return false;
                }
            });
            if (isStartDate) {
                this.overlapStartDate = isOverlap;
            }
            else {
                this.overlapEndDate = isOverlap;
            }
        }
        return isOverlap;
    };
    EmployeeSectionsAcaComponent.prototype.validateGapExists = function (item, isStartDate) {
        this.dateError = false;
        if (item.endDate) {
            if (item.startDate > item.endDate) {
                this.dateError = true;
                return;
            }
        }
        if (item.id != 0 || (isStartDate && item.id == 0)) {
            var fromDate = new Date();
            var toDate = new Date();
            var sortedList = _.orderBy(this.employeeSectionsACA.records, ['startDate'], ['desc']);
            var currIndex = _.findIndex(sortedList, ['id', item.id]);
            if (isStartDate) {
                fromDate = sortedList[currIndex + 1].endDate;
                toDate = item.startDate;
            }
            else {
                fromDate = sortedList[currIndex - 1].startDate;
                toDate = item.endDate;
            }
            fromDate = new Date(moment(fromDate).format(appConfig.dateFormat));
            toDate = new Date(moment(toDate).format(appConfig.dateFormat));
            var range = void 0;
            if (isStartDate)
                range = moment.range(fromDate, toDate);
            else
                range = moment.range(toDate, fromDate);
            var dateDiff = range.diff('days');
            if ((dateDiff > 1 && isStartDate)) {
                this.gapStartDate = true;
            }
            else if (dateDiff > 1 && !isStartDate) {
                this.gapEndDate = true;
            }
            else {
                if (isStartDate) {
                    this.gapStartDate = false;
                }
                else {
                    this.gapEndDate = false;
                }
            }
        }
    };
    EmployeeSectionsAcaComponent.prototype.isEndDateRequired = function (item) {
        var isRequired = false;
        var employeeACA = _.find(this.originalACA.records, ['id', item.id]);
        if (!_.isNull(employeeACA.endDate)) {
            isRequired = true;
        }
        return isRequired;
    };
    EmployeeSectionsAcaComponent.prototype.onSelectedIncomeCalc = function (item) {
        if (item.incomeCalculationType == 'System-Calculated') {
            item.isSystemCalculated = true;
            if (item.id != 0 && this.originalACA) {
                var employeeACA = _.find(this.originalACA.records, ['id', item.id]);
                item.annualIncome = employeeACA.annualIncome;
            }
            else {
                item.annualIncome.fieldValue = 0;
            }
        }
        else {
            item.isSystemCalculated = false;
        }
    };
    EmployeeSectionsAcaComponent.prototype.getDeleteTooltip = function (isDisabledDel) {
        var tooltipMsg = '';
        if (isDisabledDel) {
            tooltipMsg = 'Deleting this record is not allowed as it will create a gap.';
        }
        return tooltipMsg;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeSectionsAcaComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsAcaComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsAcaComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsAcaComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsAcaComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsAcaComponent.prototype, "gridCancelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsAcaComponent.prototype, "formValueChangeSubscription", void 0);
    return EmployeeSectionsAcaComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsAcaComponent };
