<div class="panels" [class.marketing-expanded]="isMarketingExpanded">
  <div class="left-panel">
    <iframe class="login-iframe" [src]="promoUrl" title="Promo page"></iframe>
  </div>

  <div class="right-panel">
    <div class="login-content">
      <p class="col-xs-12 logo">
        <img class="logo__img" src="/assets/img/slx-logo-6-1-blue.png" alt="Smartlinx">
      </p>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div *ngIf="appConfig.showMobileMarket" class="mobile-marketing" [class.expanded]="isMarketingExpanded">
  <div class="toggle" (click)="toggleMarketing()">
    <i class="fas fa-3x" aria-hidden="true" [ngClass]="{
      'fa-chevron-circle-up': isMarketingExpanded,
      'fa-chevron-circle-down': !isMarketingExpanded
    }"></i>
  </div>

  <iframe class="login-iframe" [src]="promoUrl" title="Promo page"></iframe>
</div>
