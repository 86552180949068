import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { ComponentStateStorageService } from './../../../common/services/index';
import { StateResetTypes } from './../../../core/models/settings/index';
import { appConfig } from '../../../app.config';
import { LeaveRequestApiService } from '../../services';
var LeaveRequestToolbarComponent = /** @class */ (function () {
    function LeaveRequestToolbarComponent(stateManagement, storageService, apiService) {
        this.apiService = apiService;
        this.resetBy = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.appConfig = appConfig;
        this.onDatesChanged = new EventEmitter();
        this.onFiltersChanged = new EventEmitter();
        this.filtersList = [
            { id: 0, name: 'Pending Approval' },
            { id: 1, name: 'Pending Replacement' },
            { id: 2, name: 'Approved' },
            { id: 3, name: 'Denied' },
            { id: 4, name: 'Submit for Review' },
        ];
        this.appliedFilters = [this.filtersList[0], this.filtersList[1]];
        this.dateRange = { startDate: null, endDate: null };
        this.filtersControlKey = 'filters';
        this.datesControlKey = 'dates';
    }
    LeaveRequestToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('LeaveRequestComponent', true);
        this.loadSubscription = this.stateManagement.onInit$.subscribe(function () {
            if (_.isDate(_this.startDate) && _.isDate(_this.endDate)) {
                _this.dateRange.startDate = _this.startDate;
                _this.dateRange.endDate = _this.endDate;
                _this.restoreFilters();
                _this.emitFiltersChanges(_this.appliedFilters);
            }
            else if (_.isNull(_this.startDate) && _.isNull(_this.endDate)) {
                _this.restoreDates();
                _this.emitDatesChanges(_this.dateRange);
                _this.restoreFilters();
                _this.emitFiltersChanges(_this.appliedFilters);
            }
            else {
                throw new Error("One of the date range value has no value. startDate: " + _this.startDate + ", endDate: " + _this.endDate);
            }
        });
    };
    LeaveRequestToolbarComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    LeaveRequestToolbarComponent.prototype.onDatesRangeChanged = function ($event) {
        this.dateRange.startDate = $event.startDate;
        this.dateRange.endDate = $event.endDate;
        this.saveDates();
        this.emitDatesChanges($event);
    };
    LeaveRequestToolbarComponent.prototype.onFiltersSetChanged = function ($event) {
        this.saveFilters();
        this.emitFiltersChanges($event);
    };
    LeaveRequestToolbarComponent.prototype.emitDatesChanges = function (dateRange) {
        this.onDatesChanged.emit(dateRange);
    };
    LeaveRequestToolbarComponent.prototype.emitFiltersChanges = function (filters) {
        this.onFiltersChanged.emit(filters);
    };
    LeaveRequestToolbarComponent.prototype.saveFilters = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.filtersControlKey, { value: this.appliedFilters }, this.resetBy);
    };
    LeaveRequestToolbarComponent.prototype.saveDates = function () {
        var startDate = moment(this.startDate).format(appConfig.dateFormat);
        var endDate = moment(this.endDate).format(appConfig.dateFormat);
        this.storageService.setControlState(this.stateManagement.componentKey, this.datesControlKey, {
            value: { startDate: this.dateRange.startDate, endDate: this.dateRange.endDate }
        }, this.resetBy);
    };
    LeaveRequestToolbarComponent.prototype.restoreFilters = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.filtersControlKey);
        var filters = state.value;
        if (_.size(filters) > 0) {
            this.appliedFilters = filters.slice(0);
        }
    };
    LeaveRequestToolbarComponent.prototype.restoreDates = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.datesControlKey);
        var startDate = moment(_.get(state.value, 'startDate', null));
        var endDate = moment(_.get(state.value, 'endDate', null));
        if (startDate.isValid() && endDate.isValid()) {
            this.dateRange.startDate = startDate.toDate();
            this.dateRange.endDate = endDate.toDate();
        }
        else {
            this.dateRange.startDate = moment().startOf('year').toDate();
            this.dateRange.endDate = moment().endOf('year').toDate();
        }
    };
    LeaveRequestToolbarComponent.prototype.onClickExport = function (isPdf) {
        this.apiService.exportTo(isPdf);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LeaveRequestToolbarComponent.prototype, "loadSubscription", void 0);
    return LeaveRequestToolbarComponent;
}());
export { LeaveRequestToolbarComponent };
