<div class="dates-tab">
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label">Est. Start Date</label>
    <span class="flex align-items__center flex__grow">
      <slx-datetimepicker
        name="startDate"
        [(ngModel)]="estStartDate"
        (ngModelChange)="onChangeEstStartDate($event)"
        [maxDateTime]="estEndDate"
        [readonly]="!canEditRequest || hasExceptions"
        [readonlyOnlyTime]="estAllDay"
        [readonlyTimeWithoutDate]="true"
        [required]="true" #estStartDateModel="ngModel"
      ></slx-datetimepicker>
      <slx-checkbox-button class="dates-tab__checkbox"
        [(ngModel)]="estAllDay"
        (ngModelChange)="onChangeEstAllDay()"
        [readonly]="!canEditRequest || hasExceptions || !estStartDate || !estEndDate"
        placeholder="All Day"
        name="estAllDay"
      ></slx-checkbox-button>
    </span>
  </p>
  <p class="date_error_message">
    <span *ngIf="estStartDateModel.errors" class="slx-error-block error-box">
      <span *ngIf="estStartDateModel.errors.required" errorMessage for="required"></span>
      <span *ngIf="estStartDateModel.errors.date" errorMessage>Date is out of valid range</span>
      <span *ngIf="estStartDateModel.errors.maxDateTime" errorMessage>Cannot be greater than {{ estEndDate | amDateFormat: appConfig.dateTimeFormatUS}}</span>
      </span>
  </p>
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label">Est. End Date</label>
    <span class="flex align-items__center flex__grow">
      <slx-datetimepicker
        name="endDate"
        [(ngModel)]="estEndDate"
        (ngModelChange)="onChangeEstEndDate($event)"
        [minDateTime]="estStartDate"
        [readonly]="!canEditRequest || hasExceptions"
        [readonlyOnlyTime]="estAllDay"
        [readonlyTimeWithoutDate]="true"
        [required]="true" #estEndDateModel="ngModel"
      ></slx-datetimepicker>
      <span class="dates-tab__checkbox"></span>
    </span>
  </p>
  <p class="date_error_message">
      <span *ngIf="estEndDateModel.errors" class="slx-error-block error-box">
        <span *ngIf="estEndDateModel.errors.required" errorMessage for="required"></span>
        <span *ngIf="estEndDateModel.errors.date" errorMessage>Date is out of valid range</span>
        <span *ngIf="estEndDateModel.errors.minDateTime" errorMessage>Cannot be less than {{ estStartDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
      </span>
  </p>
</div>
<div class="recurrency">
  <slx-lm-recurrence-frequency></slx-lm-recurrence-frequency>
  <slx-lm-custom-recurrence *ngIf="isCustom"></slx-lm-custom-recurrence>
  <slx-lm-daily-recurrence *ngIf="isDaily"></slx-lm-daily-recurrence>
  <slx-lm-weekly-recurrence *ngIf="isWeekly"></slx-lm-weekly-recurrence>
  <slx-lm-monthly-recurrence *ngIf="isMonthly"></slx-lm-monthly-recurrence>
</div>
