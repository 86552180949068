/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-details-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../employee-details/employee-details.component.ngfactory";
import * as i3 from "../employee-details/employee-details.component";
import * as i4 from "@angular/common";
import * as i5 from "./employee-details-list.component";
var styles_EmployeeDetailsListComponent = [i0.styles];
var RenderType_EmployeeDetailsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeDetailsListComponent, data: {} });
export { RenderType_EmployeeDetailsListComponent as RenderType_EmployeeDetailsListComponent };
function View_EmployeeDetailsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flex-container-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-employee-details", [], null, null, null, i2.View_EmployeeDetailsComponent_0, i2.RenderType_EmployeeDetailsComponent)), i1.ɵdid(2, 114688, null, 0, i3.EmployeeDetailsComponent, [], { employeeDetails: [0, "employeeDetails"], dateOn: [1, "dateOn"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.dateOn; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_EmployeeDetailsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "flex-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeeDetailsListComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.employeeDetails; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EmployeeDetailsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-details-list", [], null, null, null, View_EmployeeDetailsListComponent_0, RenderType_EmployeeDetailsListComponent)), i1.ɵdid(1, 114688, null, 0, i5.EmployeeDetailsListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeDetailsListComponentNgFactory = i1.ɵccf("slx-employee-details-list", i5.EmployeeDetailsListComponent, View_EmployeeDetailsListComponent_Host_0, { employeeDetails: "employeeDetails", dateOn: "dateOn" }, {}, []);
export { EmployeeDetailsListComponentNgFactory as EmployeeDetailsListComponentNgFactory };
