import { Component, OnInit, Provider } from '@angular/core';
import { IDialog, DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import {
  BenefitEligibilityRulesApiService,
  BenefitEligibilityRulesMapService,
} from '../../../services/benefit-eligibility-rules';
import * as _ from 'lodash';
import {
  BenefitEligibilityRuleModel,
  BenefitEligibilityRuleStatementGroupItem,
  BenefitEligibilityRuleVariable,
  BenefitEligibilityRuleConfigurationVariable,
  BenefitEligibilityRuleConfigurationPredicate,
  BenefitEligibilityRuleStatus,
} from '../../../models/benefit-eligibility-rules/index';
import { BenefitEligibilityRule, BenefitClass } from '../../../models';
import * as uuid from 'uuid';
import { BenefitClassApiService } from '../../../services';

export class BenefitDetailsEligibilityRuleDialogRequest {
  readonly benefitRulesAlreadyUsedNames: string[];
  readonly orgLevelId: number;
  readonly isReadonlyMode: boolean;

  constructor({
    benefitRulesAlreadyUsedNames,
    orgLevelId,
    isReadonlyMode,
  }: Partial<BenefitDetailsEligibilityRuleDialogRequest>) {
    this.benefitRulesAlreadyUsedNames = benefitRulesAlreadyUsedNames;
    this.orgLevelId = orgLevelId;
    this.isReadonlyMode = isReadonlyMode;
  }
}


@Component({
  selector: 'slx-benefit-details-eligibility-rule-dialog',
  templateUrl: './benefit-details-eligibility-rule-dialog.component.html',
  styleUrls: ['./benefit-details-eligibility-rule-dialog.component.scss'],
})
export class BenefitDetailsEligibilityRuleDialogComponent implements IDialog, OnInit {
  dialogResult: boolean;
  rule: BenefitEligibilityRule;
  ruleToUpdate: BenefitEligibilityRule;

  ruleName: string;
  description: string;
  ruleModel: BenefitEligibilityRuleModel;
  configurationVariables: BenefitEligibilityRuleConfigurationVariable[];
  configurationPredicates: BenefitEligibilityRuleConfigurationPredicate[];
  benefitClasses: BenefitClass[];
  public isEditMode: boolean;
  isNewRule: boolean;

  state: {
    isLoading: boolean;
    isSaving: boolean;
    isEditing: boolean;
  };

  public modalService: ModalService;
  public dialogOptions: DialogOptions;

  get benefitRulesAlreadyUsedNames() {
    return this.request ? this.request.benefitRulesAlreadyUsedNames : [];
  }

  public static openDialog(
    modalService: ModalService,
    benefitRulesAlreadyUsedNames: string[],
    orgLevelId: number,
    ruleToUpdate: BenefitEligibilityRule,
    isReadonlyMode: boolean,
    callback: (result: boolean, rule: BenefitEligibilityRule) => void
  ): BenefitDetailsEligibilityRuleDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    // dialogOptions.height = 800;
    dialogOptions.width = 950;
    dialogOptions.modeSize = DialogModeSize.custom;

    const request = new BenefitDetailsEligibilityRuleDialogRequest({
      benefitRulesAlreadyUsedNames: benefitRulesAlreadyUsedNames,
      orgLevelId: orgLevelId,
      isReadonlyMode: isReadonlyMode,
    });

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions,
      },
      {
        provide: BenefitDetailsEligibilityRuleDialogRequest,
        useValue: request,
      },
      {
        provide: BenefitEligibilityRule,
        useValue: ruleToUpdate,
      },
    ];
    let dialog: BenefitDetailsEligibilityRuleDialogComponent = modalService.globalAnchor.openDialog2(
      BenefitDetailsEligibilityRuleDialogComponent,
      'Eligibility Rule',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result, dialog.rule);
      }
    );
    return dialog;
  }

  constructor(
    dialogOptions: DialogOptions,
    modalService: ModalService,
    private apiService: BenefitEligibilityRulesApiService,
    private benefitClassApiService: BenefitClassApiService,
    private mapService: BenefitEligibilityRulesMapService,
    private request: BenefitDetailsEligibilityRuleDialogRequest,
    ruleToUpdate: BenefitEligibilityRule,
  ) {
    this.ruleName = '';
    this.description = '';
    this.ruleToUpdate = ruleToUpdate;
    this.isNewRule = false;
    this.isEditMode = !this.request.isReadonlyMode;
    this.ruleModel = null;
    this.modalService = modalService;
    this.dialogOptions = dialogOptions;

    this.state = {
      isLoading: false,
      isSaving: false,
      isEditing: false,
    };
  }

  async ngOnInit() {
    this.isNewRule =
      !this.ruleToUpdate || (this.ruleToUpdate && this.ruleToUpdate.status === BenefitEligibilityRuleStatus.Added);
    if (!this.ruleToUpdate) {
      this.state.isEditing = true;
    }

    await this.loadConfigurations();

    if (!this.ruleToUpdate) {
      this.ruleModel = new BenefitEligibilityRuleModel({
        ruleStatements: [
          new BenefitEligibilityRuleStatementGroupItem({
            id: uuid.v4(),
            variable: new BenefitEligibilityRuleVariable(),
          }),
        ],
      });
    } else {
      this.ruleName = this.ruleToUpdate.name;
      this.description = this.ruleToUpdate.description;

      const ruleStatements = this.mapService.mapResponseToGroups(this.ruleToUpdate.rule);

      this.ruleModel = new BenefitEligibilityRuleModel({
        id: this.ruleToUpdate.id,
        name: this.ruleToUpdate.name,
        description: this.ruleToUpdate.description,
        ruleStatements: ruleStatements,
      });
    }
  }

  onRuleStatementsChanged(ruleStatements: BenefitEligibilityRuleStatementGroupItem[]) {
    this.ruleModel = new BenefitEligibilityRuleModel({
      ...this.ruleModel,
      ruleStatements: ruleStatements,
    });
  }

  save(): void {
    this.dialogResult = true;
    const groups = this.mapService.mapGroupsToJsonRuleObject(this.ruleModel.ruleStatements);

    this.rule = new BenefitEligibilityRule({
      id: this.ruleToUpdate ? this.ruleToUpdate.id : uuid.v4(),
      name: this.ruleName,
      description: this.description,
      rule: JSON.stringify(groups),
      status: this.isNewRule ? BenefitEligibilityRuleStatus.Added : BenefitEligibilityRuleStatus.Updated,
    });
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  edit(): void {
    this.state.isEditing = true;
  }

  cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  onValueChanged(rule) {
    this.ruleName = _.trim(this.ruleName);
  }

  private async loadConfigurations(): Promise<any> {
    this.state.isLoading = true;
    try {
      const ruleConfigurationDataPromise = this.apiService.getBenefitEligibilityConfiguration();
      const benefitClassesPromise = this.benefitClassApiService.getBenefitClasses(this.request.orgLevelId);

      const values = await Promise.all([ruleConfigurationDataPromise, benefitClassesPromise]);

      const ruleConfigurationData = values[0];
      const benefitClasses = values[1];

      this.configurationVariables = ruleConfigurationData.variables;
      this.configurationPredicates = ruleConfigurationData.predicates;
      this.benefitClasses = benefitClasses;
    } catch (ex) {
      // todo catch
      console.log(ex);
    } finally {
      this.state.isLoading = false;
    }
  }
}
