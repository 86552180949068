import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { ColumnComponent } from '@progress/kendo-angular-grid';
import { DependsOnDeviceTypeHelper } from '../../../models/index';
import { DeviceDetectorService } from '../../../services/index';
var KendoGridColumnFilterableOnDirective = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridColumnFilterableOnDirective, _super);
    function KendoGridColumnFilterableOnDirective(column, devDetector) {
        var _this = _super.call(this) || this;
        _this.column = column;
        _this.devDetector = devDetector;
        _this.deviceList = _this.devDetector.deviceList;
        return _this;
    }
    Object.defineProperty(KendoGridColumnFilterableOnDirective.prototype, "kendoGridColumnFilterableOn", {
        set: function (v) {
            if (_.isArray(v)) {
                this.devices = v;
                this.validateDevices();
            }
        },
        enumerable: true,
        configurable: true
    });
    KendoGridColumnFilterableOnDirective.prototype.updateView = function () {
        this.column.filterable = this.deviceHasInDeviceList();
    };
    return KendoGridColumnFilterableOnDirective;
}(DependsOnDeviceTypeHelper));
export { KendoGridColumnFilterableOnDirective };
