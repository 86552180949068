<button type="button" class="slx-button slx-only-icon slx-toolbar" [ngClass]="{'hidden-config-button':!isInitialized}"
  [popper]="columnsToggler"
  [popperTrigger]="'click'"
  [popperPlacement]="popperPosition"
  [popperDisableStyle]="'true'"
  [popperPositionFixed]="true" >
  <i aria-hidden="true" class="fa fa-cog"></i>
</button>

<popper-content #columnsToggler [ngStyle]="{'width.px': width}" class="popper-white-background">
  <span class="title"><strong>{{title}}</strong></span>
  <div class="columns-container">
    <div *ngFor="let column of columns; let idx=index;">
      <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="{{uniqId}}_col_{{idx}}" fieldName="{{uniqId}}_col_{{idx}}" [caption]="column.description" [(ngModel)]="column.displayed"
          (ngModelChange)="onChangeColumn(column)">
        </slx-checkbox-input>
      </slx-input-decorator>
    </div>
  </div>
  <div class="button-container">
    <button class="slx-button slx-margin-r" type="button" (click)="selectAll()"> Select All </button>
    <button class="slx-button" type="button" (click)="clearAll()"> Clear All</button>
  </div>
</popper-content>
