import { Injectable } from '@angular/core';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { ACAConfigurationMapService } from './aca-map.service';
import { ManageACAPeriodsContainer } from '../../models/aca-config/manage-aca-container';
import { configurationConfig } from '../../configuration.config';
import { appConfig } from '../../../app.config';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody } from '../../../core/models/api/response-body';
import { FieldsMeta } from '../../../core/models/index';
import { ManageACAPeriods } from '../../models';

@Injectable()
export class ACAConfigurationApiService {

  constructor(private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: ACAConfigurationMapService) {
  }

  public getManageACAPeriodsRecords(orgLevelId: number): Promise<ManageACAPeriodsContainer> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.ACA.configuration.acaPeriods}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapManageACAPeriodsRecords(response.data, response.meta)
      );
    return promise;
  }

  public postManageACARecords(item: ManageACAPeriods, orgLevelId: number): Promise<ManageACAPeriodsContainer> {
    let body: any = this.mapService.mapToManageACAPeriodsRecordsDto(item);
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.ACA.configuration.acaPeriod}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => response.status);
    return promise;
  }

  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.configuration.root}/${orgLevelId}`;
  }

  private getApiRootWithoutOrgLevel(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.configuration.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}