<section *ngIf="late" class="late" provide-parent-form>
    <span class="title">Late</span>
    <div class="section-body">
        <slx-input-decorator #latenessStart>
            <input slx-time-picker slx-input name="arrivalTime{{index}}" required="true" placeholder="Arrival time" dateInput="true" format="hh:mm tt"
                [(ngModel)]="late.arrivalDate" (onDateChanged)="lateDateChanged()" [slxMinDate]="startDate"
                [slxMaxDate]="endDate" slxDatePager [pagerMinDate]="minDate" [pagerMaxDate]="maxDate"
                [targetContainer]="latenessStart" btnLeftClass="prev-day-btn" btnRightClass="next-day-btn" styleContainerClass="slx-date-pager-date">
            <span errorMessage for="required">Field is required</span>
            <span errorMessage for="minDate">Value should be greater than shift start</span>
            <span errorMessage for="maxDate">Value should be less than shift end</span>
            <span slx-input-hint>{{ late.arrivalDate | amDateFormat: appConfig.monthDayDateFormat}}</span>
        </slx-input-decorator>
        <slx-input-decorator class="hours-field">
            <slx-kendo-number slx-input
              [(ngModel)]="late.lateInterval"
              (ngModelChange)="lateIntervalChanged()"
              [min]="0"
              [slxMin]="0"
              [max]="shiftHoursInMinutes"
              [slxMax]="shiftHoursInMinutes"
              [required]="'true'"
              name="lateInterval{{index}}"
              placeholder="Late interval(minutes)"
            ></slx-kendo-number>
            <span errorMessage for="min">Value should be greater than zero</span>
            <span errorMessage for="max">Value should be less than shift total minutes</span>
            <span errorMessage for="required">Value is required</span>
        </slx-input-decorator>
    </div>
</section>