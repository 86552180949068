/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./favorites-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../common/components/locker/locker.component.ngfactory";
import * as i4 from "../../../common/components/locker/locker.component";
import * as i5 from "@angular/forms";
import * as i6 from "./favorites-menu.component";
import * as i7 from "../../../configuration/services/favorites/favorites-management.service";
import * as i8 from "../../services/navigation/navigation-menu-redirect.service";
var styles_FavoritesMenuComponent = [i0.styles];
var RenderType_FavoritesMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FavoritesMenuComponent, data: {} });
export { RenderType_FavoritesMenuComponent as RenderType_FavoritesMenuComponent };
function View_FavoritesMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-search favorites__search-icon no-action"]], null, null, null, null, null))], null, null); }
function View_FavoritesMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times-circle favorites__search-icon"], ["title", "Clear"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClearSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_FavoritesMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["class", "item-nav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "left-icon fas"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "item-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "right-div-star"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "star-icon fas fa-star"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "left-icon fas"; var currVal_1 = (_v.context.$implicit.menuItemMapping ? _v.context.$implicit.menuItemMapping.icon : ""); _ck(_v, 5, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.displayName; _ck(_v, 7, 0, currVal_2); }); }
function View_FavoritesMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "favorites__no-results"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No favorites found"]))], null, null); }
export function View_FavoritesMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "js-pointer pointer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "slx-locker", [], null, null, null, i3.View_LockerComponent_0, i3.RenderType_LockerComponent)), i1.ɵdid(2, 49152, null, 0, i4.LockerComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 23, "div", [["class", "favorites"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 15, "div", [["class", "favorites__search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 10, "p", [["class", "favorites__search-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, [["searchField", 1]], null, 5, "input", [["class", "favorites__search-field"], ["placeholder", "Filter Favorites"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.searchValue = $event) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (_co.filterFavorites(i1.ɵnov(_v, 7).value) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(12, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FavoritesMenuComponent_1)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FavoritesMenuComponent_2)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "right-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "a", [["class", "config-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showConfigDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", " fas fa-cog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "favorites__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 2, "ul", [["class", "list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FavoritesMenuComponent_3)), i1.ɵdid(24, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FavoritesMenuComponent_4)), i1.ɵdid(26, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.searchValue; _ck(_v, 10, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 7).value.length === 0); _ck(_v, 14, 0, currVal_8); var currVal_9 = (i1.ɵnov(_v, 7).value.length > 0); _ck(_v, 16, 0, currVal_9); var currVal_10 = _co.favorites; _ck(_v, 24, 0, currVal_10); var currVal_11 = (((_co.favorites == null) ? null : _co.favorites.length) == 0); _ck(_v, 26, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 12).ngClassValid; var currVal_5 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FavoritesMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-favorites-menu", [], null, null, null, View_FavoritesMenuComponent_0, RenderType_FavoritesMenuComponent)), i1.ɵdid(1, 4308992, null, 0, i6.FavoritesMenuComponent, [i1.Renderer2, i1.ElementRef, i7.FavoriteManagementService, i8.NavigationMenuRedirectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FavoritesMenuComponentNgFactory = i1.ɵccf("slx-favorites-menu", i6.FavoritesMenuComponent, View_FavoritesMenuComponent_Host_0, { show: "show", top: "top", left: "left", pointerIconShift: "pointerIconShift" }, {}, []);
export { FavoritesMenuComponentNgFactory as FavoritesMenuComponentNgFactory };
