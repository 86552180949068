import * as moment from 'moment';
import { IEmployeeDefinition, EmployeeDefinition, IShift, Shift, IPosition, Position, IOrganization, Organization, IDepartment, Department } from '../../../organization/models/index';
import { ILinePunch, LinePunch, PunchStatus } from './line-punch';
import { IDailyPunchesShift, DailyPunchesShift } from './daily-punches-shift';
import { EmployeeDailyPunchesStatus } from './employee-daily-punches-status';
import { IEmployeeDailyPunchesHeader, EmployeeDailyPunchesHeader } from './employee-daily-punches-header';

export interface IEmployeeDailyPunches {
  header: IEmployeeDailyPunchesHeader;
  punches: ILinePunch[];
}

export class EmployeeDailyPunches {
  public header: EmployeeDailyPunchesHeader;
  public punches: LinePunch[];
  public firstIn: LinePunch;
  public lastOut: LinePunch;
  public isPayrollCycleSubmitted: boolean;
  public isLocked: boolean;
}
