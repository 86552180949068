import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';

export class EmployeeSectionNavigationService {
  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }
  public NavigateToEmployeeSections(employeeId: number): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(this.getUrlTree(employeeId), extras);
  }
  private getUrlTree(employeeId: number): UrlTree {
    return this.router.createUrlTree(['../employee', employeeId, 'employee_sections'], { relativeTo: this.route, queryParams: { hideLeftSideBar: true } });
  }
}
