import { StreamInvocationMessage } from '@microsoft/signalr';
import {
    IPunchAttestationQuestion,
    PunchAttestationQuestion
  } from './punch-attestation-question';
  
  export interface IPunchAttestationGroup {
    id: number;
    organizationId: number;
    groupName: string;
    questions: IPunchAttestationQuestion[];
  }
  
  export class PunchAttestationGroup {
    public id: number;
    public organizationId: number;
    public groupName: string;
    public questions: IPunchAttestationQuestion[];
  }
  