/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lm-console-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../components-library/components/static-dropdown-list/static-dropdown-list.component.ngfactory";
import * as i3 from "../../../../../components-library/components/static-dropdown-list/static-dropdown-list.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../common/components/toolbar/toolbar.component.ngfactory";
import * as i6 from "../../../../../common/components/toolbar/toolbar.component";
import * as i7 from "../../../../../core/services/toolbar/toolbar-base.service";
import * as i8 from "../../../../../common/directives/toolbar/toolbar-section-template.directive";
import * as i9 from "./lm-console-toolbar.component";
import * as i10 from "../../../services/lm-management.service";
import * as i11 from "@angular/router";
var styles_LMConsoleToolbarComponent = [i0.styles];
var RenderType_LMConsoleToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LMConsoleToolbarComponent, data: {} });
export { RenderType_LMConsoleToolbarComponent as RenderType_LMConsoleToolbarComponent };
function View_LMConsoleToolbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "lm-toolbar__control as-flex-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-static-dropdown-list", [["class", "slx-wide slx-short"], ["controlIcon", "fal fa-file-plus"], ["placeholder", "Create New"]], null, [[null, "selectItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectItem" === en)) {
        var pd_0 = (_co.onCreate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_StaticDropdownListComponent_0, i2.RenderType_StaticDropdownListComponent)), i1.ɵdid(2, 49152, null, 0, i3.StaticDropdownListComponent, [], { options: [0, "options"], placeholder: [1, "placeholder"], controlIcon: [2, "controlIcon"] }, { selectItem: "selectItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.leaveTypes; var currVal_1 = "Create New"; var currVal_2 = "fal fa-file-plus"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_LMConsoleToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMConsoleToolbarComponent_2)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canAdd; _ck(_v, 1, 0, currVal_0); }, null); }
function View_LMConsoleToolbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "lm-toolbar__control as-flex-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon slx-margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickConsole() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-list slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Roster"]))], null, null); }
function View_LMConsoleToolbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMConsoleToolbarComponent_4)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.isCollapsed; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LMConsoleToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "lm-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "slx-toolbar", [["class", "slx-so-toolbar-container"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ToolbarComponent_0, i5.RenderType_ToolbarComponent)), i1.ɵdid(2, 1228800, null, 1, i6.ToolbarComponent, [i1.ElementRef, [2, i7.TOOLBAR_SERVICE], i1.ChangeDetectorRef], { alignExpandButtonRight: [0, "alignExpandButtonRight"] }, null), i1.ɵqud(603979776, 1, { contentChildren: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_LMConsoleToolbarComponent_1)), i1.ɵdid(5, 16384, [[1, 4]], 0, i8.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { width: [0, "width"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_LMConsoleToolbarComponent_3)), i1.ɵdid(7, 16384, [[1, 4]], 0, i8.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { width: [0, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getLeaveTypeWidth(); _ck(_v, 5, 0, currVal_1); var currVal_2 = "130"; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_LMConsoleToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-lm-console-toolbar", [], null, null, null, View_LMConsoleToolbarComponent_0, RenderType_LMConsoleToolbarComponent)), i1.ɵdid(1, 245760, null, 0, i9.LMConsoleToolbarComponent, [i10.LMManagementService, i11.ActivatedRoute, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LMConsoleToolbarComponentNgFactory = i1.ɵccf("slx-lm-console-toolbar", i9.LMConsoleToolbarComponent, View_LMConsoleToolbarComponent_Host_0, {}, {}, []);
export { LMConsoleToolbarComponentNgFactory as LMConsoleToolbarComponentNgFactory };
