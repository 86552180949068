import * as tslib_1 from "tslib";
import { Meta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { essTemplatesConfig } from '../ess-templates.config';
import { EssTemplateMapService } from './ess-templates-map.service';
var EssTemplateApiService = /** @class */ (function () {
    function EssTemplateApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    EssTemplateApiService.prototype.getTemplates = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + essTemplatesConfig.api.essTemplates.templates;
                        request = this.urlParamsService.requestGet(url, { orgLevelId: orgLevelId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToEssTemplates(response.data)];
                }
            });
        });
    };
    EssTemplateApiService.prototype.getTemplateDefinitions = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + essTemplatesConfig.api.essTemplates.templateDefinitions;
                        request = this.urlParamsService.requestGet(url, { orgLevelId: orgLevelId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToEssTemplateDefinitions(response.data)];
                }
            });
        });
    };
    EssTemplateApiService.prototype.getTemplate = function (templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + essTemplatesConfig.api.essTemplates.template;
                        request = this.urlParamsService.requestGet(url, { templateId: templateId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToEssTemplate(response.data)];
                }
            });
        });
    };
    EssTemplateApiService.prototype.getTemplateForPosition = function (positionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + essTemplatesConfig.api.essTemplates.template;
                        request = this.urlParamsService.requestGet(url, { positionId: positionId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToEssTemplate(response.data)];
                }
            });
        });
    };
    EssTemplateApiService.prototype.getTemplateDefinitionForPosition = function (positionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + essTemplatesConfig.api.essTemplates.templateDefinition;
                        request = this.urlParamsService.requestGet(url, { positionId: positionId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToEssTemplateDefinition(response.data)];
                }
            });
        });
    };
    EssTemplateApiService.prototype.addTemplate = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + essTemplatesConfig.api.essTemplates.template;
                        request = this.urlParamsService.requestPost(url, undefined, { orgLevelId: orgLevelId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToEssTemplate(response.data)];
                }
            });
        });
    };
    EssTemplateApiService.prototype.saveTemplate = function (template) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + essTemplatesConfig.api.essTemplates.template;
                        request = this.urlParamsService.requestPut(url, this.mapService.mapToEssTemplateDto(template));
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToEssTemplate(response.data)];
                }
            });
        });
    };
    EssTemplateApiService.prototype.deleteTemplate = function (templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + essTemplatesConfig.api.essTemplates.template;
                        request = this.urlParamsService.requestDelete(url, { templateId: templateId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapDeletionResponse(response.data)];
                }
            });
        });
    };
    EssTemplateApiService.prototype.assignTemplate = function (positionId, templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + essTemplatesConfig.api.essTemplates.template + "/" + essTemplatesConfig.api.essTemplates.assign;
                        request = this.urlParamsService.requestPost(url, { positionId: positionId, templateId: templateId });
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    EssTemplateApiService.prototype.getApiUrl = function () {
        return this.apiUtilService.getApiRoot() + "/" + essTemplatesConfig.api.essTemplates.root;
    };
    return EssTemplateApiService;
}());
export { EssTemplateApiService };
