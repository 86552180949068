import { BudgetRecord, IBudgetRecord } from './budget-record';
import { BudgetCensus, IBudgetCensus } from './budget-census';

export interface IBudget {
  id: number;
  orgLevelId: number;
  isActive: boolean;
  startDate: string;
  endDate?: string;
  census: IBudgetCensus;
  records: IBudgetRecord[];
}

export class Budget {
  public id: number;
  public orgLevelId: number;
  public isActive: boolean;
  public startDate: Date;
  public endDate: Date;
  public census: BudgetCensus;
  public records: BudgetRecord[];
}
