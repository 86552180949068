import { Component, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { StateManagementService, ColumnSettingsStorageService, ColumnManagementService, ColumnsChangedEvent } from '../../../../common/services/index';
import { IColumnSettings } from '../../../../core/models/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { PopperPositions } from '../../../../common/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-blue-columns-config-button',
  templateUrl: 'columns-config-button.component.html',
  styleUrls: ['columns-config-button.component.scss']
})
export class ColumnsConfigButtonComponent implements OnDestroy, AfterViewInit {

  @Input()
  public colGroupKey: string = ColumnSettingsStorageService.defaultColumnGroupKey;

  @Input()
  public popperPosition: PopperPositions = 'bottom-start';

  @Input()
  public title: string;

  @Input()
  public width: number = 250;

  @Output()
  public columnChanged: EventEmitter<IColumnSettings>;
  @Output()
  public selectedAll: EventEmitter<IColumnSettings[]>;
  @Output()
  public clearedAll: EventEmitter<IColumnSettings[]>;

  public columns: IColumnSettings[];
  public contextKey: string;
  public uniqId: string;
  public isInitialized: boolean;

  @unsubscribe()
  private groupInitializedSubscription: Subscription;

  constructor(private columnSettingsStorageService: ColumnSettingsStorageService, private columnManagementService: ColumnManagementService, private changeDetector: ChangeDetectorRef) {
    this.columnChanged = new EventEmitter<IColumnSettings>();
    this.selectedAll = new EventEmitter<IColumnSettings[]>();
    this.clearedAll = new EventEmitter<IColumnSettings[]>();
    this.uniqId = this.getComponentUniqueId();
  }

  public ngAfterViewInit(): void {
    this.groupInitializedSubscription = this.columnManagementService.groupInitialized$.filter((event: ColumnsChangedEvent) => event.group === this.colGroupKey)
    .subscribe((event: ColumnsChangedEvent) => {
      this.isInitialized = true;
      this.contextKey = event.component;
      this.columns = event.columns;
      _.forEach(this.columns, (column: IColumnSettings) => {
        if (column.displayed === undefined) {
          column.displayed = true;
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
    });
  }
  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onChangeColumn(column: IColumnSettings): void {
    this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
    this.columnChanged.emit(column);
    this.saveColumns();
  }

  public selectAll(): void {
    _.forEach(this.columns, (column: IColumnSettings) => {
      column.displayed = true;
    });
    this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
    this.selectedAll.emit(this.columns);
    this.saveColumns();
  }
  public clearAll(): void {
    _.forEach(this.columns, (column: IColumnSettings) => {
      column.displayed = false;
    });
    this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
    this.clearedAll.emit(this.columns);
    this.saveColumns();
  }

  private getComponentUniqueId(): string {
    return `b${Math.random().toString(36).substr(2, 9)}`;
  }

  private saveColumns(): void {
    this.columnSettingsStorageService.setColumnsState(this.contextKey, this.colGroupKey, this.columns);
  }



}
