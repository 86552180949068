<div class="exceptions">
  <p class="flex align-items__center exceptions__ctrl">
    <label class="exceptions__label">Start Date</label>
    <slx-datetimepicker class="flex__grow"
      [(ngModel)]="startDate"
      (ngModelChange)="onChangeDate()"
      [minDateTime]="limitMinDate"
      [maxDateTime]="startMaxDate"
      [readonly]="!canModifyExceptions"
      [readonlyTimeWithoutDate]="true"
      #startDateModel="ngModel"
    ></slx-datetimepicker>
    <span class="exceptions__btn">
      <button type="button"
        class="slx-button slx-with-icon slx-margin-r"
        [disabled]="!canModifyExceptions || !canAddException || !startDateModel.valid || !endDateModel.valid"
        (click)="onAddException()"
      >
        <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
        <span class="slx-button__text">Add Exception</span>
      </button>
    </span>
  </p>

  <p class="date_error_message">
    <span *ngIf="startDateModel.errors" class="slx-error-block error-box">
      <span *ngIf="startDateModel.errors.date" errorMessage>Date is out of valid range</span>
      <span *ngIf="startDateModel.errors.maxDateTime" errorMessage>Cannot be greater than {{ startMaxDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
      <span *ngIf="startDateModel.errors.minDateTime" errorMessage>Cannot be less than {{ limitMinDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
    </span>
  </p>

  <p class="flex align-items__center exceptions__ctrl">
    <label class="exceptions__label">End Date</label>
    <span class="flex__grow">
      <slx-datetimepicker
        [(ngModel)]="endDate"
        (ngModelChange)="onChangeDate()"
        [minDateTime]="endMinDate"
        [maxDateTime]="limitMaxDate"
        [readonly]="!canModifyExceptions"
        [readonlyTimeWithoutDate]="true"
        #endDateModel="ngModel"
      ></slx-datetimepicker>
      <span class="slx-error-block exceptions__error"
        *ngIf="hasEqual"
      >Start and End date shouldn't be equal</span>
      <span class="slx-error-block exceptions__error"
        *ngIf="hasOverlapped"
      >Exception dates shouldn't overlap each other</span>
      <span class="slx-error-block exceptions__error"
        *ngIf="hasExceededCustom"
      >Exception dates should be within Custom dates (Actual dates)</span>
      <span class="slx-error-block exceptions__error"
        *ngIf="hasExcludedCustom"
      >You are excluding all Custom dates (Actual dates) and employee won't have any assigned time off days</span>
      <span class="slx-error-block exceptions__error"
        *ngIf="hasExceededRepeatbaleDates"
      >Exception dates should be within repeatable dates which you specified on Dates tab</span>
      <span class="slx-error-block exceptions__error"
        *ngIf="hasExcludedStartEnd"
      >You are excluding all range between Start nad End dates and employee won't have any assigned time off days</span>
    </span>
    <span class="exceptions__btn"></span>
  </p>

  <p class="date_error_message">
    <span *ngIf="endDateModel.errors" class="slx-error-block error-box">
      <span *ngIf="endDateModel.errors.date" errorMessage>Date is out of valid range</span>
      <span *ngIf="endDateModel.errors.maxDateTime" errorMessage>Cannot be greater than {{ limitMaxDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
      <span *ngIf="endDateModel.errors.minDateTime" errorMessage>Cannot be less than {{ endMinDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
    </span>
  </p>

  <p class="flex align-items__center exceptions__ctrl">
    <span class="exceptions__label"></span>
    <span class="exceptions__added-text flex__grow">Exceptions added:</span>
  </p>
  <div class="flex align-items__center exceptions__ctrl">
    <span class="exceptions__label"></span>
    <div class="flex__grow">
      <ng-container *ngIf="hasExceptions">
        <p class="flex align-items__center exceptions__date" *ngFor="let item of exceptions; index as i">
          <i aria-hidden="true" class="fal fa-file exceptions__i-file"></i>
          <span class="flex__grow exceptions__name">
            {{ item.startDate | amDateFormat: appConfig.dateTimeFormatUS }} - {{ item.endDate | amDateFormat: appConfig.dateTimeFormatUS }}
          </span>
          <i aria-hidden="true"
            *ngIf="canModifyExceptions"
            (click)="onDeleteException(i)"
            class="fal fa-trash-alt exceptions__i-delete"
            title="Delete exception"
          ></i>
        </p>
      </ng-container>
      <p class="exceptions__no-files" *ngIf="!hasExceptions">No dates added...</p>
    </div>
  </div>
</div>
