import { ShiftSwapListComponent } from './shift-swap-list/shift-swap-list.component';
import { ShiftSwapListItemComponent } from './shift-swap-list-item/shift-swap-list-item.component';
import { ShiftSwapListItemProfileComponent } from './shift-swap-list-item-profile/shift-swap-list-item-profile.component';
import { ShiftSwapHeaderComponent } from './shift-swap-header/shift-swap-header.component';
import { ShiftSwapComponent } from './shift-swap.component';
import { ShiftSwapApproveDenyComponent } from './shift-swap-approve-deny/shift-swap-approve-deny.component';
import { ShiftSwapApproveDenyPopupComponent } from './shift-swap-approve-deny-popup/shift-swap-approve-deny-popup.component';

export * from './shift-swap.component';
export * from './shift-swap-list-item-profile/shift-swap-list-item-profile.component';
export * from './shift-swap-list-item/shift-swap-list-item.component';
export * from './shift-swap-list/shift-swap-list.component';
export * from './shift-swap-header/shift-swap-header.component';
export * from './shift-swap-approve-deny/shift-swap-approve-deny.component';
export * from './shift-swap-approve-deny-popup/shift-swap-approve-deny-popup.component';

export const shiftSwapComponents: any = [
    ShiftSwapComponent,
    ShiftSwapListComponent,
    ShiftSwapListItemComponent,
    ShiftSwapListItemProfileComponent,
    ShiftSwapHeaderComponent,
    ShiftSwapApproveDenyComponent,
    ShiftSwapApproveDenyPopupComponent
];
