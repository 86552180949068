import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../../app/app.config';
import { StateManagementService, ColumnManagementService } from '../../../../../../common/services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitLineInfo, BenefitDetailsOption } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/index';
var BenefitOptionDetailsOptionsGridComponent = /** @class */ (function () {
    function BenefitOptionDetailsOptionsGridComponent(manService, stateManagement, columnManagementService) {
        this.manService = manService;
        this.stateManagement = stateManagement;
        this.columnManagementService = columnManagementService;
        this.columnGroup = 'BenefitOptionDetailsOptions';
        this.isLoading = true;
        this.isShowingExpired = false;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.appConfig = appConfig;
    }
    BenefitOptionDetailsOptionsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('BenefitsOptionGridComponent');
        this.columnManagementService.init('BenefitsOptionGridComponent');
        this.columnManagementService.initGroup(this.columnGroup, 5);
        this.refreshGrid();
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    BenefitOptionDetailsOptionsGridComponent.prototype.ngOnDestroy = function () {
    };
    BenefitOptionDetailsOptionsGridComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        if (!this.benefitOptionData) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.benefitOptionData, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitOptionDetailsOptionsGridComponent.prototype, "subscriptions", void 0);
    return BenefitOptionDetailsOptionsGridComponent;
}());
export { BenefitOptionDetailsOptionsGridComponent };
