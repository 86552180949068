import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { PmReviewRecord } from './pm-review-records';
var PmReviewEntry = /** @class */ (function (_super) {
    tslib_1.__extends(PmReviewEntry, _super);
    function PmReviewEntry() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.attachments = [];
        _this.templates = [];
        _this.isEditMode = false;
        _this.addedFiles = [];
        return _this;
    }
    Object.defineProperty(PmReviewEntry.prototype, "isNew", {
        get: function () {
            return _.isNil(this.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewEntry.prototype, "hasEmployee", {
        get: function () {
            return this.employee && _.isFinite(this.employee.id);
        },
        enumerable: true,
        configurable: true
    });
    return PmReviewEntry;
}(PmReviewRecord));
export { PmReviewEntry };
