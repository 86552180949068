import { Component, Provider } from '@angular/core';
import { ModalService, DialogOptions, DialogOptions2, DialogModeSize, ConfirmOptions, ConfirmDialog2Component } from '../../../../common';
import { ReportDefinition, ReportAttendanceSetting, ConfigForceLoad } from '../../../models/index';
import { Assert } from '../../../../framework/index';

@Component({
  selector: 'slx-report-daily-attendance-config-dialog',
  templateUrl: './report-daily-attendance-config-dialog.component.html',
  styleUrls: ['./report-daily-attendance-config-dialog.component.scss']
})

export class ReportDailyAttendanceConfigDialogComponent {
  public hasValueChange: boolean = false;
  public dialogResult: boolean;
  public reportConfig: ReportAttendanceSetting;
  public forcedLoad: boolean = false;

  constructor(reportDefinition: ReportDefinition, configForceLoad: ConfigForceLoad, private options: DialogOptions,
    private modalService: ModalService) {
    this.forcedLoad = configForceLoad.dailyAttendance;
    Assert.isNotNull(reportDefinition, 'reportDefinition');
  }
  public static openDialog(request: ReportDefinition, configForceLoad: ConfigForceLoad, modalService: ModalService, callback: (result: boolean) => void): ReportDailyAttendanceConfigDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.width = 767;
    dialogOptions.height = 557;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ReportDefinition,
        useValue: request
      },
      {
        provide: ConfigForceLoad,
        useValue: configForceLoad
      }
    ];
    const title = `Edit Attendance Settings`;
    let component = modalService.globalAnchor.openDialog2(
      ReportDailyAttendanceConfigDialogComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );

    return component;
  }
  public onSave(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
  public onCancel(): void {
    if (this.hasValueChange) {
      this.displayConfirm();
    }
    else {
      this.closeWindow();
    }
  }

  private closeWindow() {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  private displayConfirm() {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Discard Changes',
      'Are you sure you want to cancel? You will lose all unsaved data.',
      this.modalService,
      (isCancel: boolean) => {
        if (isCancel) {
          this.closeWindow();
        }
      },
      options);
  }
  public onValueChanged(hasValueChange: boolean) {
    this.hasValueChange = hasValueChange;
  }
  public onReportConfigChanged(reportConfig: ReportAttendanceSetting) {
    this.reportConfig = reportConfig;
  }
}


