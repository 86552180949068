<slx-spinner [show]="manageService.isLoading">
  <kendo-grid #kendoGrid 
      class="slx-blue-grid slx-full-height no-border" 
      [data]="manageService.gridState.view"
      [sortable]="{ mode: 'multiple' }" 
      [sort]="manageService.sort" 
      (sortChange)="sortChangeHandler($event)"
      [pageable]="true" 
      [pageSize]="manageService.pageSize" 
      [skip]="manageService.gridState.state.skip"
      (pageChange)="pageChangeHandler($event)" 
      [filterable]="'menu'" 
      [filter]="manageService.gridState.state.filter"
      (filterChange)="filterChangeHandler($event)" 
      (dataStateChange)="manageService.gridState.dataStateChange($event)"
      (stateRestored)="manageService.gridState.dataStateChange($event)">
      <kendo-grid-column title="Employee" field="employeeName">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [employeeSectionLink]="dataItem.employeeId">
          {{ dataItem.employeeName }}
        </a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Department" field="departmentName">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.departmentName }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Position" field="positionName">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.positionName }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Approved" field="checkIsApproved">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.checkIsApproved }}</ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Requested On" field="requestOn" filter="date" [format]="dateWithTimeFormat">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.requestOn | amDateFormat: appConfig.dateTimeFormatUS }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Punch Date" field="punchDate" filter="date" [format]="dateFormat">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [dailyTimecardsLink]="dataItem.employeeId" [timecardDate]="dataItem.punchDate">
          {{ dataItem.punchDate | amDateFormat: appConfig.dateFormat }}
        </a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Punch Date/Time" field="punchTime" filter="date" [format]="dateWithTimeFormat">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.punchTime | amDateFormat: appConfig.dateTimeFormatUS }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Punch Type" field="punchTypeName" filter="text">
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.punchTypeName }}</ng-template>
    </kendo-grid-column>
  </kendo-grid>
</slx-spinner>