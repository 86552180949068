import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { Assert } from '../../../framework';

import { pmConfig } from '../performance-management.config';
import { OrgLevel } from '../../../state-model/models/index';
import { mutableSelect } from '../../../core/decorators/index';
import { ReadFile, PmAttachment } from '../../../organization/models/index';
import { Subscription } from 'rxjs/Subscription';

import { PmFile } from '../models/index';
import { SessionService } from '../../../core/services/session/session.service';

@Injectable()
export class PmAttachmentManagementService {

  public get maxFiles(): number {
    return pmConfig.files.maxFiles;
  }

  public get maxFileSizeBytes(): number {
    return pmConfig.files.maxFileSizeBytes;
  }

  public readonly acceptedFileTypes: string[] = pmConfig.files.acceptedTypes.concat();

  public get acceptedFileTypesInputFormat(): string {
    return this.fileTypesInputFormat;
  }

  public get acceptedFileTypesReadable(): string {
    return this.fileTypesReadable;
  }
  public get maxFileSize(): string {
    return this.mFileSize;
  }

  private fileTypesInputFormat: string = pmConfig.files.acceptedTypes.join(',');
  private fileTypesReadable: string = pmConfig.files.acceptedTypes.join(', ');
  private mFileSize: string;

  constructor (private sessionService: SessionService) {
    this.mFileSize = this.getReadableBytes(this.maxFileSizeBytes);
  }


  public mapToFiles(files: PmAttachment[] | ReadFile[]): PmFile[] {
    var userName = this.getUserName();
    return _.map(files, (f: PmAttachment | ReadFile) => new PmFile(f, userName));
  }

  public async readAddedFiles(files: File[]): Promise<ReadFile[]> {
    const promises = _.map(files, (f: File) => this.readFileData(f));
    let readedFiles: ReadFile[] = [];
    try {
      const binaryData = await Promise.all(promises);
      readedFiles = this.mapDateToReadFiles(files, binaryData);
    } catch (err) {
      console.error(err);
    } finally {
      return readedFiles;
    }
  }

  private readFileData(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve: (v: ArrayBuffer) => void, reject: (r: any) => void) => {
      const fr: FileReader = new FileReader();
      fr.onloadend = (): void => {
        const buffer = fr.result as ArrayBuffer;
        resolve(buffer);
      };
      fr.onerror = (): void => {
          reject(fr.error);
      };
      fr.readAsArrayBuffer(file);
    });
  }

  private mapDateToReadFiles(files: File[], binaryData: ArrayBuffer[]): ReadFile[] {
    return _.map(files, (file: File, i: number) => {
      const index = file.name.lastIndexOf('.');
      const name = file.name.slice(0, index);
      const ext = file.name.slice(index + 1);
      const array = binaryData[i];
      const data = new Blob([array]);
      return new ReadFile(name, file.size, file.type, ext, data);
    });
  }

  public resetInput(elem: any): void {
    elem.value = '';
  }

  public getUserName (): string {
    var user = this.sessionService.getUser();
    return user.username;
  }

  public validateFiles(fileList: FileList, event: MouseEvent): { files: File[], errors: StringMap<boolean> } {
    const errors: { maxFiles?: boolean, maxFileSize?: boolean, fileType?: boolean } = {};
    const files: File[] = [];
    if (fileList.length > this.maxFiles) {
      errors.maxFiles = true;
    }

    for (let i = 0, length = fileList.length; i < length; i++) {
      const file = fileList.item(i);
      if (file.size > this.maxFileSizeBytes) {
        errors.maxFileSize = true;
        break;
      }
      let fileExt = `.${_.last(file.name.split('.'))}`;
      fileExt = fileExt.toLowerCase();
      if (!_.includes(this.acceptedFileTypes, fileExt)) {
        errors.fileType = true;
        break;
      }
      files.push(file);
    }
    this.resetInput(event.target);

    if (_.size(errors) > 0) {
      files.length = 0;
    }

    return { files, errors };
  }

  private getReadableBytes(bytes: number): string {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const res = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));

    return `${res * 1} ${sizes[i]}`;
  }
}
