import * as tslib_1 from "tslib";
import { EmployeeDailyPunchesState, EmployeeDailyPunchesStatusLabel, EmployeeDailyPunchesStatus } from './../../../models/punches/employee-daily-punches-status';
import { OnInit, OnDestroy, ChangeDetectorRef, ElementRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/debounceTime';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe, clearChanges } from '../../../../core/decorators/index';
import { PunchesManagementService } from '../../../services/punches/punches-management.service';
import { ColumnManagementService, ChangeManagementService } from '../../../../common/services/index';
var PunchesEmployeesGridComponent = /** @class */ (function () {
    function PunchesEmployeesGridComponent(managementService, changeDetector, columnManagementService, changeManagementService, elRef) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.columnManagementService = columnManagementService;
        this.elRef = elRef;
        this.groupName = 'EmployeePunches';
        this.changeManagementService = changeManagementService;
        this.gridState = new KendoGridStateHelper();
        this.changes = {};
    }
    PunchesEmployeesGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (container) {
            _this.container = container;
            _this.records = container.filteredEntities;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.onFilterAppliedSubscription = this.managementService.onFilterApplied$.subscribe(function (records) {
            _this.records = records;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
            var gridContent = _this.elRef.nativeElement.getElementsByClassName('k-grid-content')[0];
            if (gridContent) {
                gridContent.scrollTop = 0;
            }
        });
        this.savedEmpPunchesSubscription = this.managementService.onSavedEmpPunches$.subscribe(function (empPunches) {
            _.forEach(empPunches, function (empPunch) {
                var index = _.findIndex(_this.records, function (r) {
                    return moment(r.header.date).isSame(empPunch.header.date) && r.header.employee.id === empPunch.header.employee.id;
                });
                _this.records.splice(index, 1, empPunch);
            });
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
            _this.managementService.applyCurrentFilter(_this.container);
            _this.scrollToPosition();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.changesSubscription = this.columnManagementService.columnsChanged$.filter(function (event) { return event.group === _this.groupName; })
            .debounceTime(500)
            .subscribe(function (event) {
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.saveAllChangesSubscription = this.managementService.onSaveAllChanges$.subscribe(function (v) {
            var employeePunchesList = [];
            _.forIn(_this.changes, function (value, key) {
                var eP = _.find(_this.records, function (empPanches) { return empPanches.header.uniqueKey === key; });
                eP.punches = value;
                employeePunchesList.push(eP);
            });
            _this.managementService.saveEmployeePunches(employeePunchesList, _this.container);
        });
    };
    PunchesEmployeesGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PunchesEmployeesGridComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        document.querySelector('.k-grid .k-grid-content').addEventListener('scroll', function (event) {
            _this.scrollPosition = event.target.scrollTop;
        });
    };
    PunchesEmployeesGridComponent.prototype.toggleRows = function () {
        var _this = this;
        if (this.gridState.view) {
            _.times(this.gridState.view.total, function (num) { return _this.grid.expandRow(num); });
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    PunchesEmployeesGridComponent.prototype.applyPanchesChanges = function (changes, employeePunches) {
        employeePunches.punches = changes.punches;
        employeePunches.header.comment = changes.comment;
        this.managementService.saveEmployeePunches([employeePunches], this.container);
        this.changes[employeePunches.header.uniqueKey] = undefined;
        this.clearChangesIfAllPristine();
    };
    PunchesEmployeesGridComponent.prototype.onDiscardChanges = function (employeePunches) {
        employeePunches.header.dirty = false;
        this.changes[employeePunches.header.uniqueKey] = undefined;
        this.clearChangesIfAllPristine();
    };
    PunchesEmployeesGridComponent.prototype.onChangingPunches = function (changes, employeePunch) {
        employeePunch.header.dirty = true;
        this.changes[employeePunch.header.uniqueKey] = changes;
        this.changeManagementService.changeNotify();
    };
    PunchesEmployeesGridComponent.prototype.getExceptionLabel = function (header) {
        if (header.status !== EmployeeDailyPunchesStatus.missing && (header.state & EmployeeDailyPunchesState.emprequest) === EmployeeDailyPunchesState.emprequest) { //this.managementService.empRequestFilterSelected &&
            return EmployeeDailyPunchesStatusLabel.emprequest;
        }
        return EmployeeDailyPunchesStatusLabel[header.status];
    };
    PunchesEmployeesGridComponent.prototype.getExceptionStyle = function (header) {
        if (header.status !== EmployeeDailyPunchesStatus.missing && (header.state & EmployeeDailyPunchesState.emprequest) === EmployeeDailyPunchesState.emprequest) {
            return 'emprequest-display-status';
        }
        else if (header.status == EmployeeDailyPunchesStatus.invalidlogin) {
            return 'invalid-display-status';
        }
        return header.status + "-display-status";
    };
    PunchesEmployeesGridComponent.prototype.scrollToPosition = function () {
        if (this.scrollPosition) {
            var gridContent = this.elRef.nativeElement.getElementsByClassName('k-grid-content')[0];
            if (gridContent) {
                gridContent.scrollTop = this.scrollPosition;
            }
        }
    };
    PunchesEmployeesGridComponent.prototype.clearChangesIfAllPristine = function () {
        var firstDirtyKey = _.findKey(this.changes, function (p) {
            return p !== undefined;
        });
        if (!firstDirtyKey) {
            this.changeManagementService.clearChanges();
        }
    };
    PunchesEmployeesGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesEmployeesGridComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesEmployeesGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesEmployeesGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesEmployeesGridComponent.prototype, "saveAllChangesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesEmployeesGridComponent.prototype, "savedEmpPunchesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesEmployeesGridComponent.prototype, "onFilterAppliedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesEmployeesGridComponent.prototype, "changesSubscription", void 0);
    tslib_1.__decorate([
        clearChanges(),
        tslib_1.__metadata("design:type", ChangeManagementService)
    ], PunchesEmployeesGridComponent.prototype, "changeManagementService", void 0);
    return PunchesEmployeesGridComponent;
}());
export { PunchesEmployeesGridComponent };
