import { ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
//export type dateRange = { startDate: Date, endDate: Date };
var DateRangeSelectorComponent = /** @class */ (function () {
    function DateRangeSelectorComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.appConfig = appConfig;
    }
    Object.defineProperty(DateRangeSelectorComponent.prototype, "ranges", {
        set: function (rs) {
            var _this = this;
            this.m_ranges = rs;
            this.dataModel = [];
            this.selectedRange = _.first(rs);
            _.forEach(rs, function (r) {
                var model = Object.assign({
                    click: function (dataItem) {
                        _this.rangeChanged(dataItem);
                    }
                }, r);
                _this.dataModel.push(model);
            });
        },
        enumerable: true,
        configurable: true
    });
    DateRangeSelectorComponent.prototype.writeValue = function (value) {
        if (!value) {
            this.selectedDate = moment().startOf('day').toDate();
            return;
        }
        this.selectedDate = value.startDate;
        var seconds = moment(value.endDate).unix() - moment(value.startDate).unix();
        this.selectedRange = _.find(this.m_ranges, function (i) { return i.seconds === seconds; });
        if (!this.selectedRange) {
            var days = _.floor(seconds / 86400, 0);
            var sec_1 = days * 86400;
            this.selectedRange = _.find(this.m_ranges, function (i) { return i.seconds === sec_1; });
            if (!this.selectedRange) {
                var one = days === -1 || days === 1;
                this.selectedRange = { icon: '', seconds: seconds, text: days + " day" + (one ? '' : 's') };
            }
        }
        this.calcRange();
    };
    DateRangeSelectorComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = function (value) { Promise.resolve(null).then(function () { return fn(value); }); };
    };
    DateRangeSelectorComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DateRangeSelectorComponent.prototype.rangeChanged = function (item) {
        this.selectedRange = item;
        this.onChangeCallback(this.calcRange());
    };
    DateRangeSelectorComponent.prototype.onDateChanged = function () {
        this.onChangeCallback(this.calcRange());
    };
    DateRangeSelectorComponent.prototype.calcRange = function () {
        var r = {
            startDate: this.selectedDate,
            endDate: moment(this.selectedDate).add(this.selectedRange.seconds, 's').toDate()
        };
        this.selectedEndDate = r.endDate;
        return r;
    };
    return DateRangeSelectorComponent;
}());
export { DateRangeSelectorComponent };
