export * from './lm-recurrence-frequency/lm-recurrence-frequency.component';
export * from './lm-custom-recurrence/lm-custom-recurrence.component';
export * from './lm-daily-recurrence/lm-daily-recurrence.component';
export * from './lm-weekly-recurrence/lm-weekly-recurrence.component';
export * from './lm-monthly-recurrence/lm-monthly-recurrence.component';

import { LMRecurrenceFrequencyComponent } from './lm-recurrence-frequency/lm-recurrence-frequency.component';
import { LMCustomRecurrenceComponent } from './lm-custom-recurrence/lm-custom-recurrence.component';
import { LMDailyRecurrenceComponent } from './lm-daily-recurrence/lm-daily-recurrence.component';
import { LMWeeklyRecurrenceComponent } from './lm-weekly-recurrence/lm-weekly-recurrence.component';
import { LMMonthlyRecurrenceComponent } from './lm-monthly-recurrence/lm-monthly-recurrence.component';

export const lmRecurrenceComponents: any[] = [
  LMRecurrenceFrequencyComponent,
  LMCustomRecurrenceComponent,
  LMDailyRecurrenceComponent,
  LMWeeklyRecurrenceComponent,
  LMMonthlyRecurrenceComponent
];
