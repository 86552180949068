import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Assert } from '../../../framework/index';
import {
  BudgetRecord, IBudgetRecord,
  Budget, IBudget,
  BudgetCensus, IBudgetCensus,
  BudgetHistory, IBudgetHistory
} from '../../models/index';
import { LookupMapService } from '../../../organization/services/index';
import { IBudgetDefinition, BudgetDefinition, BudgetedGroup, IBudgetedGroup } from '../../../organization/models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class BudgetMapService {
  private lookupMapService: LookupMapService;

  constructor(lookupMapService: LookupMapService) {
    this.lookupMapService = lookupMapService;
  }

  public mapBudgetHistories(dto: IBudgetDefinition[]): BudgetHistory[] {
    Assert.isNotNull(dto, 'IBudgetHistory');
    let data: BudgetHistory[] = [];
    data = _.map(dto, (record: IBudgetDefinition) => {
      return this.mapBudgetHistory(record);
    });
    return data;
  }

  public mapBudgetHistory(dto: IBudgetDefinition): BudgetHistory {
    Assert.isNotNull(dto, 'IBudgetHistory');
    let data: BudgetHistory = new BudgetHistory();
    data.budgetDefinition = this.lookupMapService.mapBudgetDefinition(dto);
    return data;
  }

  public mapToBudgetDefinitionDto(data: BudgetDefinition): IBudgetDefinition {
    let dto: IBudgetDefinition = this.lookupMapService.mapBudgetDefinitionDto(data);
    return dto;
  }

  public mapBudgetCensus(dto: IBudgetCensus): BudgetCensus {
    let data: BudgetCensus = new BudgetCensus();
    data.budgetedCensus = 0;
    data.maximumCapacity = 0;
    if (dto) {
      data.budgetedCensus = dto.budgetedCensus;
      data.maximumCapacity = dto.maximumCapacity;
    }
    return data;
  }

  public mapToBudgetCensusDto(data: BudgetCensus): IBudgetCensus {
    let dto: IBudgetCensus = {
      budgetedCensus: data && data.budgetedCensus ? data.budgetedCensus : 0,
      maximumCapacity: data && data.maximumCapacity ? data.maximumCapacity : 0
    };
    return dto;
  }

  public mapBudgetRecord(uniqId: number, dto: IBudgetRecord): BudgetRecord {
    Assert.isNotNull(dto, 'IBudgetRecord');
    let record: BudgetRecord = new BudgetRecord();
    record.id = dto.id;
    record.budgetedPosition = this.lookupMapService.mapBudgetedPosition(dto.budgetedPosition);
    record.budgetedGroup = this.lookupMapService.mapBudgetedGroup(dto.budgetedGroup);
    record.weekdayHours = dto.weekdayHours;
    record.weekendHours = dto.weekendHours;
    record.censusAdjust = dto.censusAdjust;
    record.payRate = dto.payRate;
    record.lastUpdatedBy = dto.lastUpdatedBy;
    record.lastUpdatedDate = dateTimeUtils.getUtcDateTime(dto.lastUpdatedDate);
    record.uniqId = uniqId;
    return record;
  }

  public mapToBudgetRecordDto(data: BudgetRecord): IBudgetRecord {
    Assert.isNotNull(data, 'BudgetRecord');
    let dto: IBudgetRecord = {
      id: data.id,
      budgetedPosition: data.budgetedPosition ? this.lookupMapService.mapBudgetedPositionDto(data.budgetedPosition) : null,
      budgetedGroup: data.budgetedGroup ? this.lookupMapService.mapBudgetedGroupDto(data.budgetedGroup) : null,
      weekdayHours: data.weekdayHours,
      weekendHours: data.weekendHours,
      censusAdjust: data.censusAdjust,
      payRate: data.payRate,
      lastUpdatedBy: data.lastUpdatedBy,
      lastUpdatedDate: data.lastUpdatedDate
    };
    return dto;
  }

  public mapDummyBudget(dto: IBudget): Budget {
    let budget: Budget = this.mapBudget(dto);
    // for new dummy budget we set date to current date
    _.each(budget.records, (record: BudgetRecord) => {
      record.lastUpdatedDate = new Date();
    });
    return budget;
  }

  public mapBudget(dto: IBudget): Budget {
    Assert.isNotNull(dto, 'IBudgetContainer');
    let data: Budget = new Budget();
    data.id = dto.id;
    data.orgLevelId = dto.orgLevelId;
    data.isActive = dto.isActive;
    data.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    data.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    data.census = this.mapBudgetCensus(dto.census);
    data.records = [];
    let uniqId: number = 0;
    _.forEach(dto.records, (record: IBudgetRecord) => {
      data.records.push(this.mapBudgetRecord(uniqId, record));
      uniqId++;
    });
    return data;
  }

  public mapToBudgetDto(data: Budget): IBudget {
    Assert.isNotNull(data, 'BudgetContainer');
    let dto: IBudget = {
      id: data.id,
      orgLevelId: data.orgLevelId,
      isActive: data.isActive,
      startDate: dateTimeUtils.convertToDtoString(data.startDate),
      endDate: dateTimeUtils.convertToDtoString(data.endDate),
      census: this.mapToBudgetCensusDto(data.census),
      records: data.records ? _.map(data.records, (record: BudgetRecord) => {
        return this.mapToBudgetRecordDto(record);
      }) : null
    };
    return dto;
  }

  public mapToBudgetedGroupDto(data: BudgetedGroup): IBudgetedGroup {
    Assert.isNotNull(data, 'BudgetRecord');
    let dto: IBudgetedGroup = this.lookupMapService.mapBudgetedGroupDto(data);
    return dto;
  }
  public mapBudgetedGroup(dto: IBudgetedGroup): BudgetedGroup {
    Assert.isNotNull(dto, 'IBudgetRecord');
    let data: BudgetedGroup = this.lookupMapService.mapBudgetedGroup(dto);
    return data;
  }

}

