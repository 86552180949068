import * as _ from 'lodash';
import randomColor from 'randomcolor';

import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Assert } from '../../../framework/index';

import { dateTimeUtils } from '../../../common/utils/index';
import {
  LookupMapService,
  EmployeeDefinitionsMapService
} from '../../../organization/services/index';

import {
  IAccrualBalanceRecord,
  AccrualBalanceRecord,
  IAccrualPolicyAssignment,
  AccrualPolicyAssignment,
  AccrualType,
  IAccrualBalances,
  AccrualBalances,
  IAccrualTypeBalance,
  AccrualTypeBalance,
  IAccrualsTransactionRecord,
  AccrualsTransactionRecord,
  IAccrualsTransaction,
  AccrualsTransaction,
  IAccrualPolicyinfo,
  AccrualPolicyinfo,
  IAccrualRecalculate,
  AccrualRecalculate,

  IAccrualsTransactionRecords,
  AccrualsTransactionRecords
} from '../models/index';
import { FieldsMeta } from '../../../core/models/index';
import { MetaMapService } from '../../../core/services/index';

@Injectable()
export class AccrualsMapService {

  constructor(
    private lookupMapService: LookupMapService,
    private employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    private metaMapService: MetaMapService,
    private decimalPipe: DecimalPipe
  ) { }

  public mapAccrualBalances(dto: IAccrualBalances, meta: FieldsMeta): AccrualBalances {
    Assert.isNotNull(dto, 'AccrualBalances');
    const data: AccrualBalances = new AccrualBalances();

    data.hideRates = dto.hideRates;
    data.accrualTypes = this.getAccrualTypesFromRecords(dto.records);
    data.records = _.map(dto.records, record => this.mapAccrualBalanceRecord(record));
    data.calculateAccruals = this.mapcalculateAccrualsAction(meta);
    return data;
  }

  public mapcalculateAccrualsAction(meta: FieldsMeta): boolean {
    let bool: boolean = false;
    _.forEach(meta.actions, (act: string) => {
      if (act == 'CalculateAccruals') {
        bool = true;
      }
    });
    return bool;
  }

  public mapAccrualBalanceRecord(dto: IAccrualBalanceRecord): AccrualBalanceRecord {
    const data = new AccrualBalanceRecord();

    data.employee = _.isObjectLike(dto.employee) ? this.employeeDefinitionsMapService.mapToEmployeeShortInfo(dto.employee) : null;
    data.organization = _.isObjectLike(dto.organization) ? this.lookupMapService.mapOrganization(dto.organization) : null;
    data.department = _.isObjectLike(dto.department) ? this.lookupMapService.mapDepartment(dto.department) : null;
    data.position = _.isObjectLike(dto.position) ? this.lookupMapService.mapPosition(dto.position) : null;
    data.accrualPolicy = _.isObjectLike(dto.accrualPolicy) ? this.mapAccrualPolicyAssignment(dto.accrualPolicy) : null;
    data.accrualTypes = this.mapAccrualTypes(dto.accrualTypes);
    data.accrualTypesMap = this.mapAccrualTypesMap(dto.accrualTypes);

    return data;
  }

  public mapAccrualPolicyAssignment(dto: IAccrualPolicyAssignment): AccrualPolicyAssignment {
    const data: AccrualPolicyAssignment = new AccrualPolicyAssignment();

    data.id = dto.id;
    data.policyName = dto.policyName;
    data.policyIdList = dto.policyIdList ? dto.policyIdList.split(',').map(id => _.toNumber(id)) : [];
    data.startDate = dateTimeUtils.convertFromDtoDateTimeString(dto.startDate);
    data.endDate = dateTimeUtils.convertFromDtoDateTimeString(dto.endDate);
    data.lastUpdatedBy = dto.lastUpdatedBy;
    data.lastUpdatedDate = dateTimeUtils.convertFromDtoDateTimeString(dto.lastUpdatedDate);

    return data;
  }

  public getAccrualTypesFromRecords(records: IAccrualBalanceRecord[]): AccrualType[] {
    const accrualTypes = _.flatMap(records, record => _.flatMap(record.accrualTypes));
    const accrualTypeNames = _.flatMap(accrualTypes, accrualType => accrualType.name);
    const uniqueAccrualTypeNames = _.sortBy(_.uniq(accrualTypeNames));

    const colors = randomColor({
      count: _.size(uniqueAccrualTypeNames),
      seed: 'accrualTypes'
    });

    return _.map(uniqueAccrualTypeNames, (name, index) => {
      const accrualType: AccrualType = new AccrualType();

      accrualType.name = name;
      accrualType.color = colors[index];

      return accrualType;
    });
  }

  public mapAccrualTypes(dtos: IAccrualTypeBalance[]): AccrualTypeBalance[] {
    return _.map(dtos, dto => this.mapAccrualType(dto));
  }

  public mapAccrualType(dto: IAccrualTypeBalance): AccrualTypeBalance {
    const data: AccrualTypeBalance = new AccrualTypeBalance();

    data.id = dto.id;
    data.name = dto.name;
    data.hourBalance = dto.hourBalance;
    data.dollarBalance = dto.dollarBalance;

    return data;
  }

  public mapAccrualTypesMap(dtos: IAccrualTypeBalance[]): StringMap<AccrualTypeBalance> {
    const groupedByName = _.groupBy(dtos, dto => dto.name);
    return _.mapValues(groupedByName, group => this.mapAccrualTypeMap(group));
  }

  public mapAccrualTypeMap(dtos: IAccrualTypeBalance[]): AccrualTypeBalance {
    const first: IAccrualTypeBalance = _.first(dtos);
    const data: AccrualTypeBalance = new AccrualTypeBalance();
    let sumDollarBalance;
    let sumHourBalance;
    data.name = first.name;
    sumHourBalance = _.sumBy(dtos, dto => dto.hourBalance);
    sumDollarBalance = _.sumBy(dtos, dto => dto.dollarBalance);
    if (!_.isNull(sumHourBalance)) {
      data.hourBalance = Number(this.decimalPipe.transform(sumHourBalance, "1.2-2").replace(/,/g, ""));
    }
    if (!_.isNull(sumDollarBalance)) {
      data.dollarBalance = Number(this.decimalPipe.transform(sumDollarBalance, "1.2-2").replace(/,/g, ""));
    }

    return data;
  }

  public mapAccrualsTransactions(dto: any, meta: FieldsMeta): AccrualsTransactionRecords {
    Assert.isNotNull(dto, 'AccrualsTransactionRecords');
    let data: AccrualsTransactionRecords = new AccrualsTransactionRecords();
    data.calculateAccruals = this.mapcalculateAccrualsAction(meta);
    data.records = this.mapAccrualsTransactionRecords(dto);

    return data;
  }

  public mapAccrualsTransactionRecords(dto: IAccrualsTransactionRecord[]): AccrualsTransactionRecord[] {
    return _.map(dto, (dto: IAccrualsTransactionRecord) => this.mapAccrualsTransactionRecord(dto));
  }

  public mapAccrualsTransactionRecord(dto: IAccrualsTransactionRecord): AccrualsTransactionRecord {
    const data = new AccrualsTransactionRecord();

    data.employee = _.isObjectLike(dto.employee) ? this.employeeDefinitionsMapService.mapToEmployeeShortInfo(dto.employee) : null;
    data.organization = _.isObjectLike(dto.organization) ? this.lookupMapService.mapOrganization(dto.organization) : null;
    data.department = _.isObjectLike(dto.department) ? this.lookupMapService.mapDepartment(dto.department) : null;
    data.position = _.isObjectLike(dto.position) ? this.lookupMapService.mapPosition(dto.position) : null;
    data.accrualPolicy = _.isObjectLike(dto.accrualPolicy) ? this.mapAccrualPolicyInfo(dto.accrualPolicy) : null;
    data.transaction = _.isObjectLike(dto.transaction) ? this.mapAccrualsTransaction(dto.transaction) : null;

    return data;
  }

  public mapAccrualPolicyInfo(dto: IAccrualPolicyinfo): AccrualPolicyinfo {
    let data: AccrualPolicyinfo = new AccrualPolicyinfo();

    data.id = dto.id;
    data.policyName = dto.policyName;

    return data;
  }

  public mapAccrualsTransaction(dto: IAccrualsTransaction): AccrualsTransaction {
    let data: AccrualsTransaction = new AccrualsTransaction();

    data.action = dto.action;
    data.change = dto.change;
    data.date = dateTimeUtils.convertFromDtoDateTimeString(dto.date);
    data.newValue = dto.newValue;
    data.notes = dto.notes;
    data.original = dto.original;
    data.type = dto.type;

    return data;
  }

  public mapAccrualsRecalculateRecords(dto: IAccrualRecalculate[]): AccrualRecalculate[] {
    Assert.isNotNull(dto, 'AccrualRecalculate');
    return _.map(dto, (dto: IAccrualRecalculate) => this.mapAccrualsRecalculateRecord(dto));
  }

  public mapAccrualsRecalculateRecord(dto: IAccrualRecalculate): AccrualRecalculate {
    let data: AccrualRecalculate = new AccrualRecalculate();
    data.requestId = dto.requestId;
    data.organizationName = dto.organizationName;
    data.createdBy = dto.createdBy;
    data.requestDate = dto.requestDate;
    data.startDate = dto.startDate;
    data.endDate = dto.endDate;
    data.employeeCount = dto.employeeCount;
    return data;
  }
}
