import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { RolesRowDefinition, IRolesRow, ILinkedToRightRolesRow, isILinkedToRightRolesRow } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-roles-menu-access-toggler',
  templateUrl: 'roles-menu-access-toggler.component.html',
  styleUrls: ['roles-menu-access-toggler.component.scss']
})
export class RolesMenuAccessTogglerComponent implements OnChanges {
  @Input()
  public rowItem: IRolesRow<any> | ILinkedToRightRolesRow<any>;
  @Input()
  public roleId: number;
  @Output()
  public roleToggled: EventEmitter<number>;

  public linkedRightName: string;

  constructor() {
    this.roleToggled = new EventEmitter<number>();
  }
  public onAccessClick(): void {
    if (this.rowItem) {
      this.rowItem.setNextStatus(this.roleId);
      this.roleToggled.next(this.roleId);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['rowItem']) {
      if (isILinkedToRightRolesRow(this.rowItem)) {
        this.linkedRightName = this.rowItem.linkedRightName;
      } else {
        this.linkedRightName = null;
      }
    }
  }
}

