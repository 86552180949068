import * as _ from 'lodash';

import { OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { SupportDevices, DevicesDefinition } from '../models/support-devices';
import { DeviceDetectorService } from '../services/device-detector/device-detector.service';

export abstract class DependsOnDeviceTypeHelper implements OnInit, OnDestroy {
  protected devices: DevicesDefinition[] = [];
  protected deviceList: DevicesDefinition[] = [];
  protected devDetectionSubscription: Subscription;

  protected abstract devDetector: DeviceDetectorService;
  protected abstract updateView(): void;

  public ngOnInit(): void {
    this.devDetectionSubscription = this.devDetector.subscribeToDeviceDetection(() => this.validateDevices());
  }

  public ngOnDestroy(): void {
    this.devDetectionSubscription.unsubscribe();
  }

  protected validateDevices(): void {
    const length = this.devices.length;
    const hasIncorrectDeviceName = _.some(this.devices, (d) => !_.includes(this.deviceList, d));
    if (length === 0 || length > this.deviceList.length || hasIncorrectDeviceName) {
      throw new TypeError('DependsOnDeviceTypeHelper: passed incorrect device list or list is empty');
    } else {
      this.updateView();
    }
  }

  protected deviceHasInDeviceList(): boolean {
    const filteredDevices = _.filter(this.deviceList, (d: DevicesDefinition) => _.includes(this.devices, d));
    switch(this.devDetector.deviceType) {
      case SupportDevices.mobile:
        return _.includes(filteredDevices, SupportDevices.mobile);
      case SupportDevices.tablet:
        return _.includes(filteredDevices, SupportDevices.tablet);
      case SupportDevices.desktop:
        return _.includes(filteredDevices, SupportDevices.desktop);
    }
  }
}
