import { dateTimeUtils } from './../../../../common/utils/dateTimeUtils';
import { IApprovalRequiredCodeRecord, ApprovalRequiredCodeRecord } from './../../models/approval-required-codes/approval-required-code-record.model';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class ApprovalRequiredCodesMapService {

    public toARCRecordsDto(records: ApprovalRequiredCodeRecord[]): IApprovalRequiredCodeRecord[] {
        let arr: any[] = [];
        _.forEach(records, (record: ApprovalRequiredCodeRecord) => {
            arr.push(this.toARCRecordDto(record));
        });
        return arr;
    }

    public toARCRecordDto(record: ApprovalRequiredCodeRecord): IApprovalRequiredCodeRecord {
        let item: IApprovalRequiredCodeRecord = {
            employeeId: +record.employeeId,
            payCodeId: +record.payCodeId,
            date: dateTimeUtils.convertToDtoString(record.date)
        };
        return item;
    }
}
