var IndSchOrgLevelEmployee = /** @class */ (function () {
    function IndSchOrgLevelEmployee() {
    }
    Object.defineProperty(IndSchOrgLevelEmployee.prototype, "id", {
        get: function () {
            if (!this.employee) {
                return 0;
            }
            return this.employee.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndSchOrgLevelEmployee.prototype, "name", {
        get: function () {
            if (!this.employee) {
                return '';
            }
            return this.employee.name;
        },
        enumerable: true,
        configurable: true
    });
    return IndSchOrgLevelEmployee;
}());
export { IndSchOrgLevelEmployee };
