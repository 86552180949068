var Supervisor = /** @class */ (function () {
    function Supervisor() {
        this.selectable = true;
    }
    Object.defineProperty(Supervisor.prototype, "hasEmployee", {
        get: function () {
            return !!(this.employee && this.employee.employee && this.employee.employee.id > 0);
        },
        enumerable: true,
        configurable: true
    });
    return Supervisor;
}());
export { Supervisor };
