import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appConfig } from '../../../../../app.config';
import { DateRange } from '../../../../../core/models/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { LmNavigationService } from '../../../../../common/services/navigation/index';
import { LMManagementService, LMRosterManagementService } from '../../../services/index';
import { DropDownItem, LoaCategoryDropDownItem, LoaCategory } from '../../../../../common/models';
import { LoaRosterState } from '../../../models';
var LMRosterToolbarComponent = /** @class */ (function () {
    function LMRosterToolbarComponent(manService, rosterManService, activatedRoute, router) {
        var _this = this;
        this.manService = manService;
        this.rosterManService = rosterManService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.subscriptions = {};
        this.listViewOn = true;
        this.leaveTypes = manService.getRequestTypes();
        this.filters = _.reduce(LoaCategory, function (accum, key) {
            if (key !== LoaCategory.None) {
                accum.push(_this.createLoaCategoryDropDownItem(LoaCategory[key]));
            }
            return accum;
        }, []);
        this.filter = [];
        var calendarModes = ['Day', 'Week'];
        this.calendarModes = _.map(calendarModes, function (n, i) { return new DropDownItem(i + 1, n); });
        this.calendarMode = _.last(this.calendarModes);
        this.hrNavService = new LmNavigationService(this.router, this.activatedRoute);
    }
    Object.defineProperty(LMRosterToolbarComponent.prototype, "canAddEdit", {
        get: function () {
            return this.manService.canAddEdit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMRosterToolbarComponent.prototype, "canDelete", {
        get: function () {
            return this.manService.canDelete;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMRosterToolbarComponent.prototype, "isListView", {
        get: function () {
            return this.listViewOn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMRosterToolbarComponent.prototype, "isDayMode", {
        get: function () {
            return this.calendarMode.name === _.head(this.calendarModes).name;
        },
        enumerable: true,
        configurable: true
    });
    LMRosterToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.viewSate = this.rosterManService
            .subscribeToChangeListViewState(function (isList) { return _this.listViewOn = isList; });
        this.subscriptions.viewSate = this.rosterManService
            .subscribeToChangeDayViewState(function (isDay) {
            if (isDay) {
                _this.calendarMode = _.head(_this.calendarModes);
            }
            else {
                _this.calendarMode = _.last(_this.calendarModes);
            }
        });
        this.subscriptions.routeFilters = this.rosterManService
            .subscribeToRouteFilter(function (filter) { return _this.applyFilterFromRoute(filter); });
        this.subscriptions.orgLevel = this.rosterManService
            .subscribeToOrgLevelChanged(function (orgLevel) {
            _this.orgLevel = orgLevel;
        });
        this.subscriptions.state = this.rosterManService
            .subscribeToChangeState(function (s) {
            _this.state = s;
        });
        this.subscriptions.dateRange = this.rosterManService
            .subscribeToDateRange(function (r) { return _this.assignDateRanges(r); });
        this.subscriptions.navButton = this.manService
            .subscribeToNavButtonClick(function (isNext) { return _this.onNavButtonClick(isNext); });
    };
    LMRosterToolbarComponent.prototype.applyFilterFromRoute = function (filter) {
        this.filter = [];
        if (_.size(filter.category) > 0) {
            this.filter = [this.createLoaCategoryDropDownItem(LoaCategory[filter.category])];
        }
        this.onChangeFilter(this.filter);
    };
    LMRosterToolbarComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMRosterToolbarComponent.prototype.createLoaCategoryDropDownItem = function (c) {
        if (!c) {
            return null;
        }
        var item = new LoaCategoryDropDownItem();
        item.id = c;
        switch (c) {
            case LoaCategory.OnLeave:
                item.name = 'On Leave';
                break;
            case LoaCategory.PastDue:
                item.name = 'Past Due';
                break;
            default:
                item.name = c.toString();
        }
        return item;
    };
    LMRosterToolbarComponent.prototype.onClickNewRequest = function (item) {
        this.manService.openCreationDialog(item.id);
    };
    LMRosterToolbarComponent.prototype.onNavButtonClick = function (isNext) {
        var days = this.isDayMode ? 1 : 7;
        var startDate = new Date(this.sDate.getTime());
        var endDate = new Date(this.eDate.getTime());
        var newStartDate = new Date(startDate.setDate(isNext ? (startDate.getDate() + days) : (startDate.getDate() - days)));
        var newEndDate = new Date(endDate.setDate(isNext ? (endDate.getDate() + days) : (endDate.getDate() - days)));
        this.sDate = newStartDate;
        this.eDate = newEndDate;
        this.rosterManService.changeDateRange(new DateRange(this.sDate, this.eDate), true);
    };
    LMRosterToolbarComponent.prototype.assignDateRanges = function (range) {
        this.sDate = range.startDate;
        this.eDate = range.endDate;
    };
    LMRosterToolbarComponent.prototype.onChangeDates = function (range) {
        if (moment(range.startDate).isSame(this.sDate) && moment(range.endDate).isSame(this.eDate))
            return;
        this.sDate = range.startDate;
        this.eDate = range.endDate;
        this.rosterManService.changeDateRange(new DateRange(this.sDate, this.eDate), true);
    };
    LMRosterToolbarComponent.prototype.onClickConsole = function () {
        this.hrNavService.navigateToConsole(this.orgLevel.id);
    };
    LMRosterToolbarComponent.prototype.onClickViewMode = function (isListView) {
        this.listViewOn = isListView;
        this.rosterManService.changeViewMode(this.listViewOn);
    };
    LMRosterToolbarComponent.prototype.onChangeCalendarMode = function () {
        this.rosterManService.changeCalenderMode(this.isDayMode);
    };
    LMRosterToolbarComponent.prototype.onChangeFilter = function (filters) {
        if (_.isArray(filters) && _.size(filters) > 0) {
            this.rosterManService.changeCategoryFilter(_.map(filters, function (f) { return f.id; }));
            return;
        }
        this.rosterManService.changeCategoryFilter([]);
    };
    LMRosterToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.rosterManService.exportTo(isPDF);
    };
    LMRosterToolbarComponent.prototype.onClickToggleSelection = function (isSelectAll) {
        _.forEach(this.state.columns, function (column) {
            column.displayed = isSelectAll;
        });
        this.rosterManService.saveState();
    };
    LMRosterToolbarComponent.prototype.onChangeColumn = function () {
        this.rosterManService.saveState();
    };
    LMRosterToolbarComponent.prototype.getLeaveTypeWidth = function () {
        return (screen.width <= appConfig.mobileMaxWidth) ? 30 : 130;
    };
    return LMRosterToolbarComponent;
}());
export { LMRosterToolbarComponent };
