import { EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
var KendoGridFilterInputDirective = /** @class */ (function () {
    function KendoGridFilterInputDirective(valueAccessors, changeDetectorRef) {
        this.accessor = valueAccessors[0];
        this.change = new EventEmitter();
        this.changeDetectorRef = changeDetectorRef;
    }
    Object.defineProperty(KendoGridFilterInputDirective.prototype, "value", {
        set: function (val) {
            this.accessor.writeValue(val);
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    KendoGridFilterInputDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.suppresAutoEmit) {
            this.accessor.registerOnChange(function (x) {
                _this.valueChanged(x);
            });
        }
    };
    KendoGridFilterInputDirective.prototype.valueChanged = function (val) {
        return this.change.emit(val);
    };
    return KendoGridFilterInputDirective;
}());
export { KendoGridFilterInputDirective };
