import { IEmployeeListState } from './employee-list.types';
import { Map } from 'immutable';
import { makeTypedMapFactory } from '../../../../framework/immutable-utils/index';

export const employeeListStateFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  viewName: 0,
  isActive: true,
  stateByOrglevel: {},
});

export const stateByOrlevelStateFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  stateByView: {}
});

export const stateByViewStateFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  itemIds: []
});

export const INITIAL_EMPLOYEE_LIST_STATE: Map<string, any> = employeeListStateFactory();
export const INITIAL_STATE_BY_ORGLEVEL_STATE: Map<string, any> = stateByOrlevelStateFactory();
export const INITIAL_STATE_BY_VIEW_STATE: Map<string, any> = stateByViewStateFactory();
