/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aca-1095-c.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./aca-1095-c-toolbar/aca-1095-c-toolbar.component.ngfactory";
import * as i5 from "./aca-1095-c-toolbar/aca-1095-c-toolbar.component";
import * as i6 from "../../services/aca-1095-c/aca-1095-c-management.service";
import * as i7 from "../../../common/services/column-settings/column-management.service";
import * as i8 from "../../../common/services/state-management/state-management.service";
import * as i9 from "../../../common/services/modal/modal.service";
import * as i10 from "./aca-1095-c-insights/aca-1095-c-insights.component.ngfactory";
import * as i11 from "./aca-1095-c-insights/aca-1095-c-insights.component";
import * as i12 from "./aca-1095-c-grid/aca-1095-c-grid.component.ngfactory";
import * as i13 from "./aca-1095-c-grid/aca-1095-c-grid.component";
import * as i14 from "@angular/router";
import * as i15 from "../../../common/services/column-settings/column-settings-storage.service";
import * as i16 from "../../../common/services/component-state/component-state-storage.service";
import * as i17 from "../../services/aca-1095-c/aca-1095-c-api.service";
import * as i18 from "../../../organization/services/lookup/lookup-api.service";
import * as i19 from "../../../common/services/change-management/change-management.service";
import * as i20 from "./aca-1095-c.component";
var styles_Aca1095cComponent = [i0.styles];
var RenderType_Aca1095cComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Aca1095cComponent, data: {} });
export { RenderType_Aca1095cComponent as RenderType_Aca1095cComponent };
export function View_Aca1095cComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-aca-1095-c-toolbar", [], null, null, null, i4.View_Aca1095cToolbarComponent_0, i4.RenderType_Aca1095cToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i5.Aca1095cToolbarComponent, [i6.Aca1095cManagementService, i7.ColumnManagementService, i8.StateManagementService, i9.ModalService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "insight_section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-aca-1095-c-insights", [], null, null, null, i10.View_Aca1095cInsightsComponent_0, i10.RenderType_Aca1095cInsightsComponent)), i1.ɵdid(8, 245760, null, 0, i11.Aca1095cInsightsComponent, [i6.Aca1095cManagementService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents grid-1095c"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "slx-aca-1095-c-grid", [], null, null, null, i12.View_Aca1095cGridComponent_0, i12.RenderType_Aca1095cGridComponent)), i1.ɵdid(11, 4440064, null, 0, i13.Aca1095cGridComponent, [i6.Aca1095cManagementService, i14.Router, i14.ActivatedRoute], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 8, 0); _ck(_v, 11, 0); }, null); }
export function View_Aca1095cComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-aca-1095-c", [], null, null, null, View_Aca1095cComponent_0, RenderType_Aca1095cComponent)), i1.ɵprd(512, null, i7.ColumnManagementService, i7.ColumnManagementService, [i15.ColumnSettingsStorageService]), i1.ɵprd(512, null, i8.StateManagementService, i8.StateManagementService, [i16.ComponentStateStorageService, i15.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.Aca1095cManagementService, i6.Aca1095cManagementService, [i17.Aca1095cApiService, i7.ColumnManagementService, i8.StateManagementService, i18.LookupApiService, i19.ChangeManagementService]), i1.ɵdid(4, 245760, null, 0, i20.Aca1095cComponent, [i6.Aca1095cManagementService, i8.StateManagementService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var Aca1095cComponentNgFactory = i1.ɵccf("slx-aca-1095-c", i20.Aca1095cComponent, View_Aca1095cComponent_Host_0, {}, {}, []);
export { Aca1095cComponentNgFactory as Aca1095cComponentNgFactory };
