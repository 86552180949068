import { Provider } from '@angular/core';

export * from './benefit-eligibility-rules-api.service';
export * from './benefit-eligibility-rules-map.service';
export * from './benefit-eligibility-rules-management.service';
export * from './benefit-eligibility-rule-statements-management.service';

import { BenefitEligibilityRulesApiService } from './benefit-eligibility-rules-api.service';
import { BenefitEligibilityRulesMapService } from './benefit-eligibility-rules-map.service';
import { BenefitEligibilityRulesManagementService } from './benefit-eligibility-rules-management.service';
import { BenefitEligibilityRuleStatementsManagementService } from './benefit-eligibility-rule-statements-management.service';

export const benefitEligibilityRulesServices: Provider[] = [
  BenefitEligibilityRulesApiService,
  BenefitEligibilityRulesMapService,
  BenefitEligibilityRulesManagementService,
  BenefitEligibilityRuleStatementsManagementService
];
