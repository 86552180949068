export interface IEmployeePhoto {
  employeeId: number;
  photo: string;
  empInitial:string;
}

export class EmployeePhoto implements IEmployeePhoto {
  public employeeId: number;
  public photo: string;
  public empInitial:string;
}
