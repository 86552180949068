import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ConfirmDialog3Component, ConfirmOptions3 } from '../../../../common/components/confirm-dialog3/confirm-dialog3.component';
import { ModalService } from '../../../../common/index';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
import { IControlState, StateResetTypes } from '../../../../core/models/settings/index';
import { employeeListConfig } from '../../../../employee/employee-list/employee-list.config';
import { DetailColumn, DetailField, DetailGroup, DetailRow } from '../../../models/index';
import { ExportExcelGridComponent } from '../export-excel-grid/export-excel-grid.component';
import { screenUtils } from '../../../../common/utils/screenUtils';
import { DailyUnitGridEmployee } from './daily-unit-grid-employees-model';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { CancelTypeList } from '../../../../scheduler/models/detail-screen/cancel-type';
import { RolesApiService } from '../../../../../app/configuration/services';
import { TreeviewModule } from 'ngx-treeview';

const SELECTED_EMP_ID: string = 'selectedEmpId';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-unit-grid-employees',
  templateUrl: 'daily-unit-grid-employees.component.html',
  styleUrls: ['daily-unit-grid-employees.component.scss'],
})
export class DailyUnitGridEmployeesComponent {
  private static inParFieldName: string = 'InPar';
  private static isOvertimeFieldName: string = 'IsOvertime';
  private static messageCountFieldName: string = 'MessageCount';

  @Input()
  public pageSize: number;
  public canAdd: boolean;
  public gridState: KendoGridStateHelper<any>;
  public appConfig: IApplicationConfig;

  @ViewChild('kendoGrid', { static: true })
  public grid: GridComponent;

  @Input()
  public agencyMap: any;

  @Input()
  public GroupData: DetailGroup[];

  @Input()
  public canReplaceEmployee: boolean;

  @Input()
  public columns: DetailColumn[];
  showFilterData: boolean = false;
  isLoading: boolean;
  public singleRowSelected: boolean= false;
  public isEditOpenShift: boolean;

  @Input()
  public set rows(value: DetailRow[]) {
    this.m_originalRows = value;
    this.refreshView();
  }

  @Input()
  public set showEmptySlots(value: boolean) {
    this.m_showEmptySlots = value;
    this.refreshGrid();
  }
  public get showEmptySlots(): boolean {
    return this.m_showEmptySlots;
  }

  @Input()
  public set showPendingSlots(value: boolean) {
    this.m_showPendingSlots = value;
    this.refreshGrid();
  }

  public get showPendingSlots(): boolean {
    return this.m_showPendingSlots;
  }

  @Input()
  public set showOvertimes(value: boolean) {
    this.m_showOvertimes = value;
    this.refreshGrid();
  }
  public get showOvertimes(): boolean {
    return this.m_showOvertimes;
  }

  @Input()
  public set showMessages(value: boolean) {
    this.m_showMessages = value;
    this.refreshView();
  }
  public get showMessages(): boolean {
    return this.m_showMessages;
  }

  @Input()
  public set showOutOfPar(value: boolean) {
    this.m_showOutOfPar = value;
    this.refreshGrid();
  }
  public get showOutOfPar(): boolean {
    return this.m_showOutOfPar;
  }

  @Input()
  public isEmptyExists: boolean = false;

  @Input()
  public cancelTypeList:CancelTypeList[];

  @Output()
  public onEmployeeDelete: EventEmitter<DailyUnitGridEmployee>;

  @Output()
  public onEmployeeCancel: EventEmitter<{}>;

  @Output()
  public onFillShift: EventEmitter<DailyUnitGridEmployee>;

  @Output()
  public onReplaceEmployee: EventEmitter<DailyUnitGridEmployee>;

  @Output()
  public onSwitchEmployeeShift: EventEmitter<DailyUnitGridEmployee>;

  @Output()
  public onShiftOpen: EventEmitter<DailyUnitGridEmployee>;

  @Output()
  public onOpenIndividual: EventEmitter<DailyUnitGridEmployee>;

  @Output()
  public onNeedScroll: EventEmitter<number>;

  @Input()
  public cancelRequestShift: boolean;

  public filteredRows: DetailRow[];

  public restrcitedMobileFields: string[];

  private m_showEmptySlots: boolean;
  private m_showPendingSlots: boolean;
  private m_showOvertimes: boolean;
  private m_showMessages: boolean;
  private m_showOutOfPar: boolean;
  private m_originalRows: DetailRow[];
  private selectedEmpId: number;

  @Input()
  public dateOn: Date;

  @Input()
  public IsEnabled: boolean = false;
  private rowData: any = [];
  public showGrid = false;

  public GroupedListArray: any = [];
  public GroupedList: any = [];

  public filteredData: any =[];
 
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  
  public currentOrgLevel: OrgLevel;

  @unsubscribe()
  public orgLevelSubscription: Subscription;

  constructor(
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private modalService: ModalService,
  ) {
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper();
    this.onEmployeeCancel = new EventEmitter<{DailyUnitGridEmployee,string}>();
    this.onEmployeeDelete = new EventEmitter<DailyUnitGridEmployee>();
    this.onFillShift = new EventEmitter<DailyUnitGridEmployee>();
    this.onReplaceEmployee = new EventEmitter<DailyUnitGridEmployee>();
    this.onShiftOpen = new EventEmitter<DailyUnitGridEmployee>();
    this.onSwitchEmployeeShift = new EventEmitter<DailyUnitGridEmployee>();
    this.onOpenIndividual = new EventEmitter<DailyUnitGridEmployee>();
    this.onNeedScroll = new EventEmitter<number>();

    this.restrcitedMobileFields = ['EmpName', 'DepartmentName', 'ShiftName', 'UnitName'];
    this.restoreSelectedEmp();
  }

  public exportToExcel(): void {
    let myCompOneObj = new ExportExcelGridComponent();
    myCompOneObj.exportData();
  }

  public ngOnInit() {
    this.appConfig = appConfig;
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
      this.loadData();
    });
  }
  
  loadData() {
    this.GroupedList =[];
    this.GroupedListArray = [];
    this.convertToDailyUnitEmployee();
    this.showGrid = true;
    this.rowData.sort((a: { EmpName: { toUpperCase: () => number; }; }, b: { EmpName: { toUpperCase: () => number; }; }) => (a.EmpName.toUpperCase() > b.EmpName.toUpperCase()) ? 1 : -1);
    this.refreshGrid();
    this.getExportData();
  }

  private getExportData(): void {
    try {
      this.GroupedListArray = this.GroupedListArray.length > 0 ? this.GroupedListArray[0] : this.GroupedListArray;
    } catch (e) {}
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.gridState.state = state;
    this.gridState.view = process(this.filteredData, this.gridState.state);
  }

  public createSorting(event: DataStateChangeEvent): SortDescriptor[] {
    const sorting: SortDescriptor[] = [];
    return sorting;
  }

  private refreshGrid(): void {
    
    if (!this.rowData) {
      this.gridState.view = null;
      return;
    }
    if(this.rowData.length== 1) {
      this.singleRowSelected= true;
    }
    this.showFilterData = this.showEmptySlots || this.showPendingSlots || this.showOutOfPar || this.showOvertimes;
    this.filteredData = [];
    _.forEach(this.rowData, (row: DailyUnitGridEmployee): void => {
      let rowToAdd: DailyUnitGridEmployee = undefined;
        if (this.showEmptySlots && row.IsOpenShift) rowToAdd = row;
        if (!rowToAdd && this.showPendingSlots && row.IsPendingShift) rowToAdd = row;
        if (!rowToAdd && this.showOutOfPar && row.IsSwitchShift) rowToAdd = row;
        if(!rowToAdd && this.showOvertimes && row.IsOTShift) rowToAdd = row;
        if (rowToAdd) {
          this.filteredData.push(rowToAdd);
        }
    });
    if(!this.showFilterData){
      this.filteredData = this.rowData;
    }
    let records:any[]= [];
    
    //get all parent shifts + partial with employee assigned(scheduled)
    records=this.filteredData.filter((row: DailyUnitGridEmployee) => ((row.ParentShiftId == '-1') || (row.ParentShiftId != '-1' && row.EmpId != '0')));
    records.forEach((item) => {
      item.Hours=parseFloat(item.Hours) 
      item.SchedHours=parseFloat(item.SchedHours);
      
      //add partial shifts for parent
      let partialShifts: DailyUnitGridEmployee[] = [];
      if(item.HasPartialShift) {
        partialShifts = this.filteredData.filter((row: DailyUnitGridEmployee) => (row.ParentShiftId == item.ShiftId && row.RecordNumber == item.RecordNumber && row.JobCode==item.JobCode && row.UnitId==item.UnitId && row.EmpId == '0'));
      }
      item.PartialShiftDailyUnitGridEmployee = partialShifts;
    });

    //only display 1. scheduled; 2. non-scheduled for shift with non-children; 3. full shift with children
    records=records.filter((row: DailyUnitGridEmployee) => (row.EmpId !== '0' || (row.EmpId === '0' && row.PartialShiftDailyUnitGridEmployee.length === 0) || (row.HasPartialShift && row.PartialShiftDailyUnitGridEmployee.length != 0)));
    this.gridState.view = process(records, this.gridState.state);
  }

  public removeEmployee(row: DailyUnitGridEmployee): void {
    this.onEmployeeDelete.emit(row);
  }

  public cancelEmployee(row: DailyUnitGridEmployee, option:string): void {
    this.isLoading= true;
    this.onEmployeeCancel.emit({row,option});
    this.isLoading= false;

}
  public switchEmployee(row: DailyUnitGridEmployee): void {
    this.selectEmployee(row);
    this.onSwitchEmployeeShift.emit(row);
  }

  public getEmployeeId(row: DetailRow): string {
    return row.getFieldValue(employeeListConfig.employeeIdentifierName);
  }

  public isEmptyEmployee(row: DailyUnitGridEmployee): boolean {
    if (row.EmpId === '0') return true;
  }

  public getFieldValue(row: DetailRow, column: DetailColumn): string {
    let field: DetailField = _.find(row.fields, (f: DetailField) => f.name === column.name);
    return field ? field.value : '';
  }

  public fillShift(row: DailyUnitGridEmployee): void {
    this.onFillShift.emit(row);
  }
  public onFillShiftPartial(row: DailyUnitGridEmployee): void {
    this.onFillShift.emit(row);
  }

  public replaceEmployee(row: DailyUnitGridEmployee): void {
    this.onReplaceEmployee.emit(row);
  }

  public openIndividual(row: DailyUnitGridEmployee): void {
    this.onOpenIndividual.emit(row);
    this.selectEmployee(row);
  }

  public openShift(row: DailyUnitGridEmployee): void {
    this.onShiftOpen.emit(row);
    this.selectEmployee(row);
  }

  public isInPar(row: DailyUnitGridEmployee): boolean {
    return row.InPar.toLowerCase() !== 'false';
  }

  public isOvertime(row: DailyUnitGridEmployee): boolean {
    return row.IsOvertime.toLowerCase() === 'true';
  }
 

  public getMessageCount(row: DailyUnitGridEmployee): number {
    return +row.MessageCount;
  }

  public selectEmployee(row: DailyUnitGridEmployee): void {
    let empId: number = +row.EmpId;
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      SELECTED_EMP_ID,
      { value: empId },
      StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange
    );
  }

  public isSelectedEmployee(row: DetailRow): boolean {
    return this.selectedEmpId && +this.getEmployeeId(row) === this.selectedEmpId;
  }

  public onTRCreated(item: HTMLElement): void {
    this.onNeedScroll.emit(item.offsetTop + (<HTMLElement>item.offsetParent).offsetTop);
  }

  public getRemoveTip(row: DailyUnitGridEmployee): string {
    return this.isOvertime(row) ? 'Projected for OT' : 'Remove Employee';
  }

  public getShiftRequestDateTip(row: DailyUnitGridEmployee): string {
    let dateString = row.ShiftRequestDate;
    let stringvalue = moment(dateTimeUtils.convertFromDtoDateTimeString(dateString)).format(appConfig.requestDateTime);
    return stringvalue.toString();
  }

  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }

  private restoreSelectedEmp(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, SELECTED_EMP_ID);
    if (state.value > 0) {
      this.selectedEmpId = state.value;
    }
  }

  private refreshView(): void {
    this.filteredRows = this.filterRows(this.m_originalRows);
  }

  private filterRows(rows: DetailRow[]): DetailRow[] {
    if (!this.showEmptySlots && !this.showMessages && !this.showOutOfPar && !this.showOvertimes) {
      return rows.concat([]);
    }

    let filteredRows: DetailRow[] = [];
    _.forEach(rows, (row: DetailRow): void => {
      let rowToAdd: DetailRow = undefined;
      if (this.showEmptySlots && row.isEmpty) rowToAdd = row;
      if (!rowToAdd && this.showMessages && row.messageCount > 0) rowToAdd = row;
      if (!rowToAdd && this.showOutOfPar && row.isOutOfPar) rowToAdd = row;
      if (!rowToAdd && this.showOvertimes && row.isOvertime) rowToAdd = row;
      if (rowToAdd) {
        filteredRows.push(rowToAdd);
      }
    });
    return filteredRows;
  }

  public isNoPatner(row: DailyUnitGridEmployee): boolean {
    if (row.SlxpartnerdataId === '0') {
      return true;
    } else {
      if (row.RequeststatusId === '0' || row.RequeststatusId === '3') {
        return true;
      } else {
        return false;
      }
    }
  }

  public getShiftStatus(row: DailyUnitGridEmployee): string {
    if (row.RequeststatusId === '1') {
      return 'Pending Agency Assignment';
    }
  }

  public isPartnerEmployee(row: DailyUnitGridEmployee): boolean {
    if (row.EmpId !== '0' && row.RequeststatusId === '2') {
      return true;
    } else {
      return false;
    }
  }

  public checkPartnerData(row: DailyUnitGridEmployee): boolean {
    if (row.SlxpartnerdataId !== '0') return true;
  }

  convertToDailyUnitEmployee() {
    this.filteredRows && this.filteredRows.forEach((currentDetailRow) => {
      let dailyUnitGridEmployee: DailyUnitGridEmployee = new DailyUnitGridEmployee();
      currentDetailRow.fields.forEach((field) => {
        if ('InPar' === field.name) {
          dailyUnitGridEmployee.InPar = field.value;
        } else if ('EmpId' === field.name) {
          dailyUnitGridEmployee.EmpId = field.value;
        } else if ('RecordNumber' === field.name) {
          dailyUnitGridEmployee.RecordNumber = field.value;
        } else if ('EmpName' === field.name) {
          dailyUnitGridEmployee.EmpName = field.value;
        } else if ('DepartmentId' === field.name) {
          dailyUnitGridEmployee.DepartmentId = field.value;
        } else if ('PositionGroupId' === field.name) {
          dailyUnitGridEmployee.PositionGroupId = field.value;
        } else if ('PositionGroupName' === field.name) {
          dailyUnitGridEmployee.PositionGroupName = field.value;
        } else if ('JobCode' === field.name) {
          dailyUnitGridEmployee.JobCode = field.value;
        } else if ('JobDescription' === field.name) {
          dailyUnitGridEmployee.JobDescription = field.value;
        } else if ('ShiftGroupId' === field.name) {
          dailyUnitGridEmployee.ShiftGroupId = field.value;
        } else if ('ShiftGroup' === field.name) {
          dailyUnitGridEmployee.ShiftGroup = field.value;
        } else if ('IsConstraint' === field.name) {
          dailyUnitGridEmployee.IsConstraint = field.value;
        } else if ('ParentShiftId' === field.name) {
          dailyUnitGridEmployee.ParentShiftId = field.value;
        } else if ('HasPartialShift' === field.name) {
          dailyUnitGridEmployee.HasPartialShift = field.value==="1";
        } else if ('ParentShiftName' === field.name) {
          dailyUnitGridEmployee.ParentShiftName = field.value;
        } else if ('ExcludeFromCount' === field.name) {
          dailyUnitGridEmployee.ExcludeFromCount = field.value;
        } else if ('ShiftId' === field.name) {
          dailyUnitGridEmployee.ShiftId = field.value;
        } else if ('ShiftName' === field.name) {
          dailyUnitGridEmployee.ShiftName = field.value;
        } else if ('ShiftStart' === field.name) {
          dailyUnitGridEmployee.ShiftStart = field.value;
        } else if ('ShiftEnd' === field.name) {
          dailyUnitGridEmployee.ShiftEnd = field.value;
        } else if ('UnitId' === field.name) {
          dailyUnitGridEmployee.UnitId = field.value;
        } else if ('UnitName' === field.name) {
          dailyUnitGridEmployee.UnitName = field.value;
        } else if ('SchedHours' === field.name) {
          dailyUnitGridEmployee.SchedHours = field.value;
        } else if ('Hours' === field.name) {
          dailyUnitGridEmployee.Hours = field.value;
        } else if ('IsOvertime' === field.name) {
          dailyUnitGridEmployee.IsOvertime = field.value;
        } else if ('PPD' === field.name) {
          dailyUnitGridEmployee.PPD = field.value;
        } else if ('SlxpartnerdataId' === field.name) {
          dailyUnitGridEmployee.SlxpartnerdataId = field.value;
        } else if ('RequeststatusId' === field.name) {
          dailyUnitGridEmployee.RequeststatusId = field.value;
        } else if ('SlxagencyId' === field.name) {
          dailyUnitGridEmployee.SlxagencyId = field.value;
          if ( field.value != "0" ){
            dailyUnitGridEmployee.AgencyName = this.agencyMap[field.value];
          }
        } else if ('MessageCount' === field.name) {
          dailyUnitGridEmployee.MessageCount = field.value;
        } else if ('PartnerId' === field.name) {
          dailyUnitGridEmployee.PartnerId = field.value;
        } else if ('ShiftRequestDate' === field.name) {
          dailyUnitGridEmployee.ShiftRequestDate = field.value;
        } 
        else if ('ActualShiftStart' === field.name) {
          dailyUnitGridEmployee.actualShiftStart = field.value;
        }
        else if ('ActualShiftEnd' === field.name) {
          dailyUnitGridEmployee.actualShiftEnd = field.value;
        }
      });
      if(dailyUnitGridEmployee.RequeststatusId == "1" && dailyUnitGridEmployee.EmpName == ""){
        dailyUnitGridEmployee.EmpName = employeeListConfig.pendingShiftPartnerStatus;
        dailyUnitGridEmployee.AgencyName = "";
      }
      if(dailyUnitGridEmployee.RequeststatusId == "4" && dailyUnitGridEmployee.EmpName == ""){
        dailyUnitGridEmployee.EmpName = employeeListConfig.preassignPendingStatus;
        dailyUnitGridEmployee.AgencyName = "";
      }
      if(currentDetailRow.isEmpty && !currentDetailRow.isPendingShift){
        dailyUnitGridEmployee.IsOpenShift = currentDetailRow.isEmpty;
      }
      dailyUnitGridEmployee.IsPendingShift = currentDetailRow.isPendingShift;
      dailyUnitGridEmployee.IsSwitchShift = currentDetailRow.isOutOfPar && !currentDetailRow.isEmpty && this.isEmptyExists;
      dailyUnitGridEmployee.IsOTShift = currentDetailRow.isOvertime;
      this.rowData.push(dailyUnitGridEmployee);
    });

    this.GroupData && this.GroupData.forEach((currentDetailGroup) => {
      currentDetailGroup && currentDetailGroup.rows.forEach((currentDetailRow) => {
        let dailyUnitGridEmployee: DailyUnitGridEmployee = new DailyUnitGridEmployee();
        currentDetailRow.fields.forEach((field) => {
          if ('InPar' === field.name) {
            dailyUnitGridEmployee.InPar = field.value;
          } else if ('EmpId' === field.name) {
            dailyUnitGridEmployee.EmpId = field.value;
          } else if ('RecordNumber' === field.name) {
            dailyUnitGridEmployee.RecordNumber = field.value;
          } else if ('EmpName' === field.name) {
            dailyUnitGridEmployee.EmpName = field.value;
          } else if ('DepartmentId' === field.name) {
            dailyUnitGridEmployee.DepartmentId = field.value;
          } else if ('PositionGroupId' === field.name) {
            dailyUnitGridEmployee.PositionGroupId = field.value;
          } else if ('PositionGroupName' === field.name) {
            dailyUnitGridEmployee.PositionGroupName = field.value;
          } else if ('JobCode' === field.name) {
            dailyUnitGridEmployee.JobCode = field.value;
          } else if ('JobDescription' === field.name) {
            dailyUnitGridEmployee.JobDescription = field.value;
          } else if ('ShiftGroupId' === field.name) {
            dailyUnitGridEmployee.ShiftGroupId = field.value;
          } else if ('ShiftGroup' === field.name) {
            dailyUnitGridEmployee.ShiftGroup = field.value;
          } else if ('ParentShiftId' === field.name) {
            dailyUnitGridEmployee.ParentShiftId = field.value;
          } else if ('HasPartialShift' === field.name) {
            dailyUnitGridEmployee.HasPartialShift = field.value==="1";
          } else if ('ParentShiftName' === field.name) {
            dailyUnitGridEmployee.ParentShiftName = field.value;
          } else if ('ExcludeFromCount' === field.name) {
            dailyUnitGridEmployee.ExcludeFromCount = field.value;
          } else if ('ShiftId' === field.name) {
            dailyUnitGridEmployee.ShiftId = field.value;
          } else if ('ShiftName' === field.name) {
            dailyUnitGridEmployee.ShiftName = field.value;
          } else if ('ShiftStart' === field.name) {
            dailyUnitGridEmployee.ShiftStart = field.value;
          } else if ('ShiftEnd' === field.name) {
            dailyUnitGridEmployee.ShiftEnd = field.value;
          } else if ('UnitId' === field.name) {
            dailyUnitGridEmployee.UnitId = field.value;
          } else if ('UnitName' === field.name) {
            dailyUnitGridEmployee.UnitName = field.value;
          } else if ('SchedHours' === field.name) {
            dailyUnitGridEmployee.SchedHours = field.value;
          } else if ('Hours' === field.name) {
            dailyUnitGridEmployee.Hours = field.value;
          } else if ('IsOvertime' === field.name) {
            dailyUnitGridEmployee.IsOvertime = field.value;
          } else if ('PPD' === field.name) {
            dailyUnitGridEmployee.PPD = field.value;
          } else if ('SlxpartnerdataId' === field.name) {
            dailyUnitGridEmployee.SlxpartnerdataId = field.value;
          } else if ('RequeststatusId' === field.name) {
            dailyUnitGridEmployee.RequeststatusId = field.value;
          } else if ('SlxagencyId' === field.name) {
            dailyUnitGridEmployee.SlxagencyId = field.value;
            if ( field.value != "0" ){
              dailyUnitGridEmployee.AgencyName = this.agencyMap[field.value];
            }
          } else if ('MessageCount' === field.name) {
            dailyUnitGridEmployee.MessageCount = field.value;
          } else if ('PartnerId' === field.name) {
            dailyUnitGridEmployee.PartnerId = field.value;
          } else if ('ShiftRequestDate' === field.name) {
            dailyUnitGridEmployee.ShiftRequestDate = field.value;
          }
        });
        if(dailyUnitGridEmployee.RequeststatusId == "1" && dailyUnitGridEmployee.EmpName == ""){
          dailyUnitGridEmployee.EmpName = employeeListConfig.pendingShiftPartnerStatus;
          dailyUnitGridEmployee.AgencyName = "";
        }
        if(dailyUnitGridEmployee.RequeststatusId == "4" && dailyUnitGridEmployee.EmpName == ""){
          dailyUnitGridEmployee.EmpName = employeeListConfig.preassignPendingStatus;
          dailyUnitGridEmployee.AgencyName = "";
        }
        if(currentDetailRow.isEmpty && !currentDetailRow.isPendingShift){
          dailyUnitGridEmployee.IsOpenShift = currentDetailRow.isEmpty;
        }
        dailyUnitGridEmployee.IsPendingShift = currentDetailRow.isPendingShift;
        dailyUnitGridEmployee.IsSwitchShift = currentDetailRow.isOutOfPar && !currentDetailRow.isEmpty && this.isEmptyExists;
        dailyUnitGridEmployee.IsOTShift = currentDetailRow.isOvertime;
        this.GroupedList.push(dailyUnitGridEmployee);
      });
      this.GroupedList.sort((a: { EmpName: { toUpperCase: () => number; }; }, b: { EmpName: { toUpperCase: () => number; }; }) => (a.EmpName.toUpperCase() > b.EmpName.toUpperCase()) ? 1 : -1);
      this.GroupedListArray.push(this.GroupedList);
    });
  }

  public cancelConfirmation(item: DailyUnitGridEmployee, cancelOption:string){
    let options: ConfirmOptions3 = new ConfirmOptions3();
      options.showCancel = true;
      options.showOK = true;
      options.itemData = item;
      if(cancelOption=="noshow") {
        options.note =" Note:  The cancellation will be reported as a No Show cancellation. This cancellation indicates the Agency employee did not come in and did not call out for their assigned shift." ;
      }
      else if(cancelOption =="calledout") {
       options.note =" Note:  The cancellation will be reported as a Called Out cancellation. This cancellation indicates the Agency employee is no longer available for the assigned shift.";
      }
      else if(cancelOption=="facility"){
        options.note =" Note: Please check your agency cancellation policy to determine if you will incur any cancellation fees";
      }
      options.agencyName = item.AgencyName;
      
      ConfirmDialog3Component.openDialog(
        'Confirmation', 
        'Are you sure you want to cancel this shift request?',
        this.modalService,
        (result: boolean) => {
          if (result) {
           this.cancelEmployee(item,cancelOption);
          }
        }, options);
  }

  public showMutliPartnerRows(dataItem: DailyUnitGridEmployee): boolean {
    let dsplayPartialGrid = false;
    if(dataItem) {
    if(dataItem.EmpId==="0" && dataItem.PartialShiftDailyUnitGridEmployee && dataItem.PartialShiftDailyUnitGridEmployee.length > 0) {
      dsplayPartialGrid = true;
    }
    }  
    let showmultipartnerrows = dataItem.SlxpartnerdataId !== '0' && ( dataItem.RequeststatusId == '1' || dataItem.RequeststatusId == '4');
    return showmultipartnerrows || dsplayPartialGrid;
  }

  public checkCancelActions(dataItem: DailyUnitGridEmployee, actions:string) : boolean {
    if(dataItem.SlxpartnerdataId !== '0' && dataItem.RequeststatusId == '2' && dataItem.EmpName != "" ) {
      let actionFlag:boolean = false;
      if(this.cancelTypeList != null && this.cancelTypeList.length > 0){
        this.cancelTypeList && this.cancelTypeList.forEach( e => {
          if(e.partnerId == dataItem.PartnerId){
            actionFlag = e.types.some(i => i.name == actions);
          }
        });
      }
      return actionFlag;
    }
  }
  public filterChange(dataItem): void {
    if(this.gridState.view.data.length==1 ){
   if(this.checkCancelActions(this.gridState.view.data[0],'noshow') || this.checkCancelActions(this.gridState.view.data[0],'calledout')){
    this.singleRowSelected= true;
   }
 }
  else {
    this.singleRowSelected= false;
  }
 }  

 public displayPartialGrid(dataItem: DailyUnitGridEmployee): boolean {
  let dsplayPartialGrid = false;
  if(dataItem) {
    if(dataItem.EmpId==="0" && dataItem.PartialShiftDailyUnitGridEmployee && dataItem.PartialShiftDailyUnitGridEmployee.length > 0) {
      dsplayPartialGrid = true;
    }
  }
  return dsplayPartialGrid;
 }
 public displayPartial(dataItem): boolean {
  let dsplayPartial = false;
  if(dataItem.EmpId==="0" && dataItem.HasPartialShift && dataItem.PartialShiftDailyUnitGridEmployee.length > 0) {
    dsplayPartial = true;
  }
  return dsplayPartial;
 }
 public displayActionIcon(dataItem): boolean {
  let dsplayActionIcon = true;
  if(dataItem.HasPartialShift) {
    let scheduledPartials = this.rowData.filter((row: DailyUnitGridEmployee) => (row.ParentShiftId == dataItem.ShiftId && row.RecordNumber == dataItem.RecordNumber && row.EmpId != '0'));
    if(scheduledPartials.length > 0) {
      dsplayActionIcon = false;
    }
  }
  return dsplayActionIcon;
 }
}
