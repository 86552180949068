import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { dateTimeUtils } from '../../../common/utils/index';

import {
  Employee,
  IEmployee,
  PerformanceReviewCode,
  IPerformanceReviewCode,
  ReadFile,
  IOrganization,
  IDepartment,
  IPosition,
  IPmTemplateDefinition,
  PmTemplateDefinition
} from '../../../organization/models/index';

import { LookupMapService } from '../../../organization/services/index';

import {
  IPmReviewRecords,
  PmReviewRecords,
  IPmReviewRecord,
  PmReviewRecord,
  IPmReviewEntry,
  PmReviewEntry,
  IPmRosterRecord,
  ICfgPmCode,
  CfgPmCode,
  OrgLevelObject,
  IOrgLevelObject,
  EmployeeRosterRecord,
  IEmployeeRosterRecord
} from '../models/index';

@Injectable()
export class PerformanceManagementMapService {

  constructor(private lookupMapService: LookupMapService) { }

  public mapReviewRecords(dto: IPmReviewRecords): PmReviewRecords<PmReviewRecord> {

    const records = _.map(dto.records, (dto: IPmRosterRecord) => {
      return this.mapRosterRecord(dto);
    });

    const startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    const endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    let employee = _.isObjectLike(dto.employee) ? this.lookupMapService.mapEmployee(dto.employee) : null;

    return new PmReviewRecords(employee, startDate, endDate, dto.canCreateNewReview, dto.canDeleteReview, records);
  }

  public mapEmployeeReviewRecords(dto: IPmReviewRecords): PmReviewRecords<EmployeeRosterRecord> {

    const records = _.map(dto.records, (dto: IPmRosterRecord) => {
      return this.mapToEmployeeReview(dto);
    });

    let startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    let endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    let employee = _.isObjectLike(dto.employee) ? this.lookupMapService.mapEmployee(dto.employee) : null;

    return new PmReviewRecords(employee, startDate, endDate, dto.canCreateNewReview, dto.canDeleteReview, records);
  }

  public mapToEmployeeReview(dto: IEmployeeRosterRecord): EmployeeRosterRecord {
    let review = new EmployeeRosterRecord();
    review.rating = dto.rating ? this.lookupMapService.mapPerformanceReviewCode(dto.rating) : null;
    review.reviewType = dto.reviewType ? this.lookupMapService.mapPerformanceReviewCode(dto.reviewType) : null;
    review.reviewRecord = this.mapToReviewRecord(dto.reviewRecord);
    review.status = dto.reviewRecord.completionDate != null ? 'Completed' : 'In Progress';
    return review;
  }

  public mapRosterRecord(dto: IPmRosterRecord): PmReviewRecord {
    const review = new PmReviewRecord();
    this.setupEmployeeInfo(review, dto);
    review.reviewType = dto.reviewType ? this.lookupMapService.mapPerformanceReviewCode(dto.reviewType) : null;
    if (dto.reviewRecord) {
      this.updateReviewRecord(dto.reviewRecord, review);
    }
    return review;
  }

  public mapToReviewRecord(dto: IPmReviewRecord): PmReviewRecord {
    const review = new PmReviewRecord();
    this.updateReviewRecord(dto, review);
    return review;
  }

  private setupEmployeeInfo(review: PmReviewRecord, dto: {
    employee: IEmployee;
    organization: IOrganization;
    department: IDepartment;
    position: IPosition;
    reviewRecord: IPmReviewRecord;
    hireDate: string;
    terminationDate: string;
    activationDate: string;
    rating: IPerformanceReviewCode;
  }): void {
    review.employee = _.isObjectLike(dto.employee) ? this.lookupMapService.mapEmployee(dto.employee) : null;
    review.organization = _.isObjectLike(dto.organization) ? this.lookupMapService.mapOrganization(dto.organization) : null;
    review.department = _.isObjectLike(dto.department) ? this.lookupMapService.mapDepartment(dto.department) : null;
    review.position = _.isObjectLike(dto.position) ? this.lookupMapService.mapPosition(dto.position) : null;
    review.hideDate = dateTimeUtils.convertFromDtoString(dto.hireDate);
    review.terminationDate = dateTimeUtils.convertFromDtoString(dto.terminationDate);
    review.employee.department = review.department;
    review.employee.organization = review.organization;
    review.employee.position = review.position;
    if (!_.isDate(review.terminationDate)) {
      review.employeeStatus = 'Active';
    } else {
      review.employeeStatus = 'Terminated';
    }
    if (dto.rating) {
      review.reviewRating = dto.rating.code;
    }
  }

  private updateReviewRecord(dto: IPmReviewRecord, review: PmReviewRecord): void {

    review.id = dto.id;
    review.reviewDate = dateTimeUtils.convertFromDtoString(dto.reviewDate);
    review.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    review.completionDate = dateTimeUtils.convertFromDtoString(dto.completionDate);
    review.status = dto.status === true ? 'Completed' : 'In Progress';
    review.comment = dto.comment;
    review.reviewedBy = dto.reviewedBy;
  }

  public mapReviewEntry(dto: IPmReviewEntry): PmReviewEntry {

    const review = new PmReviewRecord();
    this.setupEmployeeInfo(review, dto);
    this.updateReviewRecord(dto.reviewRecord, review);

    const entry = new PmReviewEntry();
    entry.id = review.id;
    entry.employee = review.employee;
    entry.organization = review.organization;
    entry.department = review.department;
    entry.position = review.position;
    entry.hideDate = review.hideDate;
    entry.terminationDate = review.terminationDate;
    entry.employeeStatus = review.employeeStatus;
    entry.reviewDate = review.reviewDate;
    entry.startDate = review.startDate;
    entry.completionDate = review.completionDate;
    entry.status = review.status;
    entry.reviewedBy = review.reviewedBy;

    entry.reviewType = dto.reviewType ? this.lookupMapService.mapPerformanceReviewCode(dto.reviewType) : null;
    entry.rating = dto.rating ? this.lookupMapService.mapPerformanceReviewCode(dto.rating) : null;
    entry.lastReviewDate = dateTimeUtils.convertFromDtoString(dto.prevReviewDate);
    entry.comment = review.comment;
    entry.attachments = this.lookupMapService.mapPmAttachments(dto.attachments);


    entry.canEdit = dto.canEdit;
    entry.canDelete = dto.canDelete;

    return entry;
  }

  public mapReviewEntryToDto(review: PmReviewEntry): IPmReviewEntry {

    const dto: IPmReviewEntry = {
      reviewRecord: this.mapToPmReviewRecordDto(review),
      employee: this.mapToEmployeeInfoDto(review.employee),
      organization: this.lookupMapService.mapOrganizationDto(review.organization),
      department: this.lookupMapService.mapDepartmentDto(review.department),
      position: this.lookupMapService.mapPositionDto(review.position),
      hireDate: dateTimeUtils.convertToDtoString(review.hideDate),
      terminationDate: dateTimeUtils.convertToDtoString(review.terminationDate),
      rating: review.rating ? this.mapToPmCodeDto(review.rating) : null,
      reviewType: review.reviewType ? this.mapToPmCodeDto(review.reviewType) : null,
      attachments: this.lookupMapService.mapPmAttachmentsToDto(review.attachments),
      activationDate: null,
      prevReviewDate: dateTimeUtils.convertToDtoString(review.lastReviewDate),
      prevReviewId: null,
      canEdit: false,
      canDelete: false
    };

    return dto;
  }

  public mapToPmReviewRecordDto(review: PmReviewEntry): IPmReviewRecord {
    return {
      id: review.id,
      completionDate: dateTimeUtils.convertToDtoString(review.completionDate),
      departmentId: review.department ? review.department.id : null,
      employeeId: review.employee ? review.employee.id : null,
      employeeStatus: review.employeeStatus,
      organizationId: review.organization ? review.organization.id : null,
      positionId: review.position ? review.position.id : null,
      reviewDate: review.reviewDate ? dateTimeUtils.convertToDtoString(review.reviewDate) : null,
      reviewType: review.reviewType ? review.reviewType.id : null,
      reviewedBy: review.reviewedBy,
      startDate: review.startDate ? dateTimeUtils.convertToDtoString(review.startDate) : null,
      status: review.status === 'Completed' ? true : false,
      comment: review.comment,
      rating: review.rating ? review.rating.id : null
    };
  }

  public mapToPmCodeDto(code: PerformanceReviewCode): IPerformanceReviewCode {
    if (code == null) return null;
    return {
      id: code.id,
      code: code.name,
      codeType: {
        id: code.type.id,
        name: code.type.name,
        description: code.type.description
      },
      description: code.description
    };
  }

  public mapTemplateDefinitions(data: IPmTemplateDefinition[]): PmTemplateDefinition[] {
    return _.map(data, d => this.lookupMapService.mapTemplateDefinition(d));
  }

  public mapTemplateDefinition(data: IPmTemplateDefinition): PmTemplateDefinition {
    return data ? this.lookupMapService.mapTemplateDefinition(data) : null;
  }

  public mapTemplateDefinitionDto(tpl: PmTemplateDefinition): IPmTemplateDefinition {
    return tpl ? this.lookupMapService.mapTemplateDefinititionDto(tpl) : null;
  }

  public mapCfgPmCodes(data: ICfgPmCode[]): CfgPmCode[] {
    return _.map(data, d => this.mapCfgPmCode(d));
  }

  public mapCfgPmCode(data: ICfgPmCode): CfgPmCode {
    let model: CfgPmCode = new CfgPmCode();
    model.id = data.id;
    model.name = data.code;
    model.description = data.description;
    model.type = data.codeType ? this.lookupMapService.mapPerformanceReviewCodeType(data.codeType) : null,
      model.orgLevel = data.orgLevel ? this.mapOrgLevelObject(data.orgLevel) : null;
    model.useCount = data.useCount;
    model.addedBy = data.addedBy;
    model.addedOn = data.addedOn ? dateTimeUtils.convertFromDtoDateTimeString(data.addedOn) : null;
    model.updatedBy = data.updatedBy;
    model.updatedOn = data.updatedOn ? dateTimeUtils.convertFromDtoDateTimeString(data.updatedOn) : null;
    return model;
  }

  public mapOrgLevelObject(dto: IOrgLevelObject): OrgLevelObject {
    const obj = new OrgLevelObject();
    obj.id = dto.id;
    obj.name = dto.name;
    return obj;
  }

  public mapOrgLevelObjectDto(obj: OrgLevelObject): IOrgLevelObject {
    return {
      id: obj.id,
      name: obj.name
    };
  }

  public mapCfgPmCodeDto(code: CfgPmCode): ICfgPmCode {
    return {
      id: code.id,
      code: _.trim(code.name),
      description: code.description,
      orgLevel: code.orgLevel ? this.mapOrgLevelObjectDto(code.orgLevel) : null,
      codeType: code.type ? this.lookupMapService.mapPerformanceReviewCodeTypeDto(code.type) : null,
      addedBy: null,
      addedOn: null,
      updatedBy: null,
      updatedOn: null,
      useCount: code.useCount
    };
  }

  public mapFilesToFormData(files: ReadFile[]): FormData {
    const formData: FormData = new FormData();
    _.forEach(files, (file: ReadFile, index: number) => {
      formData.append(`i${index}_name`, file.name);
      formData.append(`i${index}_fileName`, file.fileName);
      formData.append(`i${index}_category`, file.mimeType);
      formData.append(`i${index}_file`, file.data, file.fileName);
    });
    return formData;
  }

  private mapToEmployeeInfoDto(emp: Employee): IEmployee {
    return {
      id: emp.id,
      name: emp.name,
      masterId: emp.masterId,
      badgeId: emp.badgeId,
      department: emp.department,
      position: this.lookupMapService.mapPositionDto(emp.position),
      organization: emp.organization
    } as IEmployee;
  }
}


