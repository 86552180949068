import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import {
  IPunchAttestationGroup, PunchAttestationGroup, IPunchAttestationQuestion, PunchAttestationQuestion, IPunchAttestationDeletionResponse, PunchAttestationDeletionResponse
} from '../models/index';
import { IPunchAttestationGroupPermissions, PunchAttestationGroupPermissions } from '../models/punch-attestation-group-permissions';

@Injectable()
export class PunchAttestationMapService {


  constructor() {}

  public mapPeriods(dtos: IPunchAttestationGroup[]): PunchAttestationGroup[] {
    return _.map(dtos, dto => this.mapPeriod(dto));
  }

  public mapPeriod(dto: IPunchAttestationGroup): PunchAttestationGroup {
    if (!dto) {
      return null;
    }

    const data = new PunchAttestationGroup();

    data.id = dto.id;
    data.organizationId = dto.organizationId;
    data.groupName = dto.groupName;
    data.questions = this.mapQuestions(dto.questions, true);

    return data;
  }

  public mapPeriodsDto(datas: PunchAttestationGroup[]): IPunchAttestationGroup[] {
    return _.map(datas, data => this.mapPeriodDto(data));
  }

  public mapPeriodDto(data: PunchAttestationGroup): IPunchAttestationGroup {
    if (!data) {
      return null;
    }

    return {
      id: data.id,
      organizationId: data.organizationId,
      groupName: data.groupName,
      questions: this.mapQuestionsDto(data.questions)
    };
  }

  public mapQuestions(dtos: IPunchAttestationQuestion[], fillQuestions?: boolean): PunchAttestationQuestion[] {
    const questions = _.map(dtos, dto => this.mapQuestion(dto));
    return questions;
  }

  public mapQuestion(dto: IPunchAttestationQuestion): PunchAttestationQuestion {
    if (!dto) {
      return null;
    }

    const data = new PunchAttestationQuestion();

    data.id = dto.id;
    data.order = dto.order;
    data.textLine1 = dto.textLine1;
    data.textLine2 = dto.textLine2;
    data.defaultAnswer = dto.defaultAnswer;
    //data.isConditional = dto.isConditional;
    data.attestationTypeId = dto.attestationTypeId;
    data.restrictedAnswerId = dto.restrictedAnswerId;
    data.punchType = dto.punchType;
    data.enable = dto.enable;
    
    return data;
  }

  public mapQuestionsDto(datas: IPunchAttestationQuestion[]): IPunchAttestationQuestion[] {
    return _.map(datas, data => this.mapQuestionDto(data));
  }

  public mapQuestionDto(data: IPunchAttestationQuestion): IPunchAttestationQuestion {
    if (!data || !data.textLine1) {
      return null;
    }

    return {
      id: data.id,
      order: data.order,
      textLine1: data.textLine1,
      textLine2 : data.textLine2,
      defaultAnswer: this.mapDefaultAnswer(data.defaultAnswer),
      //isConditional: this.mapisConditional(data.isConditional),
      attestationTypeId: this.mapAttestationTypeId(data.attestationTypeId),
      restrictedAnswerId: this.maprestrictedAnswerId(data.restrictedAnswerId),
      punchType: this.mapPunchTypeId(data.punchType),
      enable: data.enable
    };
  }

  public mapAttestationTypeId(item: any){
    if(item) {
      return item.attestationTypeId;
    }
  }
  public mapPunchTypeId(item: any){
    if(item) {
      return item.id;
    }
  }
  public mapisConditional(item: any){
    if(item) {
      return item.id;
    }
  }
  public mapDefaultAnswer(item: any){
    if(item) {
      return item.id;
    }
  }

  public maprestrictedAnswerId(item: any){
    if(item) {
      return item.restrictedAnswerId;
    }
  }

  public mapDeletionResponse(dto: IPunchAttestationDeletionResponse): PunchAttestationDeletionResponse {
    if (!dto) {
      return null;
    }

    const data = new PunchAttestationDeletionResponse();

    data.isSuccess = dto.isSuccess;
    data.message = dto.message;

    return data;
  }

  public mapPermissions(dto:IPunchAttestationGroupPermissions) : PunchAttestationGroupPermissions{
    if (!dto) {
      return null;
    }

    const data = new PunchAttestationGroupPermissions();

    data.isLegacy = dto.isLegacy;
    data.isSlate = dto.isSlate;
    data.configurePunchAttestation = dto.configurePunchAttestation;
    data.configurePunchProfile = dto.configurePunchProfile;

    return data;
  }
}
