/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manage-position-groups-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../../common/components/spinner/spinner.component";
import * as i4 from "../manage-position-groups/manage-position-groups.component.ngfactory";
import * as i5 from "../../../../services/manage-groups/manage-groups-management.service";
import * as i6 from "../../../../../organization/services/lookup/lookup.service";
import * as i7 from "../../../../services/manage-groups/manage-groups-api.service";
import * as i8 from "../../../../services/manage-groups/manage-groups-map.service";
import * as i9 from "../manage-position-groups/manage-position-groups.component";
import * as i10 from "./manage-position-groups-dialog.component";
import * as i11 from "../../../../../common/models/dialog-options";
import * as i12 from "../../../../../common/services/modal/modal.service";
var styles_ManagePositionGroupsDialogComponent = [i0.styles];
var RenderType_ManagePositionGroupsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManagePositionGroupsDialogComponent, data: {} });
export { RenderType_ManagePositionGroupsDialogComponent as RenderType_ManagePositionGroupsDialogComponent };
export function View_ManagePositionGroupsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "slx-manage-position-groups", [], null, [[null, "onLoading"], [null, "onHasChanges"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLoading" === en)) {
        var pd_0 = (_co.onLoading($event) !== false);
        ad = (pd_0 && ad);
    } if (("onHasChanges" === en)) {
        var pd_1 = (_co.onHasChanges($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ManagePositionGroupsComponent_0, i4.RenderType_ManagePositionGroupsComponent)), i1.ɵprd(512, null, i5.ManageGroupsManagementService, i5.ManageGroupsManagementService, [i6.LookupService, i7.ManageGroupsApiService, i8.ManageGroupsMapService]), i1.ɵdid(6, 245760, null, 0, i9.ManagePositionGroupsComponent, [i5.ManageGroupsManagementService], null, { onLoading: "onLoading", onHasChanges: "onHasChanges" }), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 6, 0); }, null); }
export function View_ManagePositionGroupsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-manage-position-groups-dialog", [], null, null, null, View_ManagePositionGroupsDialogComponent_0, RenderType_ManagePositionGroupsDialogComponent)), i1.ɵdid(1, 49152, null, 0, i10.ManagePositionGroupsDialogComponent, [i11.DialogOptions, i12.ModalService], null, null)], null, null); }
var ManagePositionGroupsDialogComponentNgFactory = i1.ɵccf("slx-manage-position-groups-dialog", i10.ManagePositionGroupsDialogComponent, View_ManagePositionGroupsDialogComponent_Host_0, {}, {}, []);
export { ManagePositionGroupsDialogComponentNgFactory as ManagePositionGroupsDialogComponentNgFactory };
