import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { LookupApiService } from '../../../../organization/services/index';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { ShiftsApiService } from '../../../services/index';
import { KendoGridCustomSelectionHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-custom-selection-helper';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { NotificationsService } from '../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
var CopyShiftsComponent = /** @class */ (function () {
    function CopyShiftsComponent(api, lookup, notificationsService) {
        this.api = api;
        this.lookup = lookup;
        this.notificationsService = notificationsService;
        this.appConfig = appConfig;
        this.discardEmitter = new EventEmitter();
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
    }
    Object.defineProperty(CopyShiftsComponent.prototype, "shifts", {
        set: function (value) {
            this.m_shifts = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyShiftsComponent.prototype, "organizations", {
        get: function () {
            return this.m_organizations;
        },
        enumerable: true,
        configurable: true
    });
    CopyShiftsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false,
            allowEdit: true,
            allowCopy: false,
            departmentLevel: false
        };
        this.gridState.onRefreshGrid.subscribe(function (state) {
            _this.refreshGrid();
        });
        this.formSubscription = this.form.valueChanges.subscribe(function () {
            _this.checkActionsConditions();
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.state.isLoading = true;
            _this.currentOrgLevel = orgLevel;
            _this.copyFromDepartment = _this.currentOrgLevel.name;
            _this.selectionHelper.clearSelection();
            _this.lookup.getOrganizations(undefined, _this.currentOrgLevel.parentId).then(function (orgs) {
                if (orgs && orgs.length > 0) {
                    _this.copyFromCenter = orgs[0].name;
                }
                else {
                    _this.copyFromCenter = _this.currentOrgLevel.parentId.toString();
                }
            });
            _this.checkActionsConditions();
            _this.api.getCopyShiftConfiguration(_this.currentOrgLevel.id).then(function (org) {
                _this.m_organizations = org;
                _this.copyToCenter = _.first(org);
                if (_this.copyToCenter) {
                    _this.copyToDepartment = _.first(_this.copyToCenter.departments);
                    if (_this.copyToDepartment)
                        _this.onTargetDepartmentChanged();
                }
                _this.state.isLoading = false;
            });
        });
        this.selectionHelper.onSelectionChanged.subscribe(function () {
            if (_this.selectionHelper.selectionLength > 0) {
                _this.state.allowCopy = true;
            }
            else {
                _this.state.allowCopy = false;
            }
            _this.checkActionsConditions();
        });
    };
    CopyShiftsComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    CopyShiftsComponent.prototype.onDoCopy = function () {
        var _this = this;
        this.state.isLoading = true;
        var selectedForCopyItems = this.selectionHelper.selection;
        var ids = _.map(selectedForCopyItems, function (shift) { return shift.id; });
        this.api.copyShifts(this.currentOrgLevel.relatedItemId, this.copyToDepartment.id, ids)
            .then(function (respone) {
            _this.state.isLoading = false;
            _this.selectionHelper.clearSelection();
            _this.fetchShiftsList();
            _this.notificationsService.success('Copy Shifts', 'Shift(s) has been successfully copied.');
        }).catch(function (reason) {
            _this.state.isLoading = false;
            _this.selectionHelper.clearSelection();
            _this.updateSelectableState();
        });
    };
    CopyShiftsComponent.prototype.onDiscardCopy = function () {
        this.selectionHelper.clearSelection();
        this.discardEmitter.emit();
    };
    CopyShiftsComponent.prototype.onTargetDepartmentChanged = function () {
        if (!this.copyToDepartment || !this.copyToDepartment.orgLevelId) {
            return;
        }
        this.selectionHelper.clearSelection();
        this.fetchShiftsList();
    };
    CopyShiftsComponent.prototype.onTargetOrganizationChanged = function () {
        this.copyToDepartment = _.first(this.copyToCenter.departments);
        this.onTargetDepartmentChanged();
    };
    CopyShiftsComponent.prototype.fetchShiftsList = function () {
        var _this = this;
        this.api.getShiftsList(this.copyToDepartment.orgLevelId).
            then(function (result) {
            _this.m_targetShifts = result.records;
            _this.updateSelectableState();
        });
    };
    CopyShiftsComponent.prototype.updateSelectableState = function () {
        var _this = this;
        _.each(this.m_shifts, function (item) {
            item.selectable = !_.some(_this.m_targetShifts, function (target) {
                return target.start.getTime() === item.start.getTime()
                    && target.end.getTime() === item.end.getTime()
                    && target.duration === item.duration;
            });
        });
    };
    CopyShiftsComponent.prototype.refreshGrid = function () {
        if (!this.m_shifts) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_shifts, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    };
    CopyShiftsComponent.prototype.checkActionsConditions = function () {
        var allowCopy = true;
        if (this.currentOrgLevel.type === OrgLevelType.department) {
            this.state.departmentLevel = true;
        }
        else {
            this.state.departmentLevel = false;
            allowCopy = false;
        }
        if (!this.form.valid) {
            allowCopy = false;
        }
        if (this.selectionHelper.selectionLength === 0) {
            allowCopy = false;
        }
        this.state.allowCopy = allowCopy;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], CopyShiftsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CopyShiftsComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CopyShiftsComponent.prototype, "formSubscription", void 0);
    return CopyShiftsComponent;
}());
export { CopyShiftsComponent };
