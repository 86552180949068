import * as tslib_1 from "tslib";
import { BehaviorSubject, asyncScheduler } from 'rxjs';
import { distinctUntilChanged, finalize, observeOn, scan, shareReplay, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
export var notBusy = { isBusy: false };
var BusyService = /** @class */ (function () {
    function BusyService() {
        this.busyCounter = 0;
        this.busySubject = new BehaviorSubject(notBusy);
        this._busyState$ = this.busySubject.pipe(scan(function (oldValue, value) {
            return oldValue.isBusy === value.isBusy
                ? oldValue
                : tslib_1.__assign({}, value);
        }, tslib_1.__assign({}, notBusy)), distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));
        this.busyState$ = this._busyState$.pipe(observeOn(asyncScheduler));
    }
    /**
     * Indicate busy until the source observable completes.
     *
     * @param source Observable that the busy service should watch
     * @return piped continuation of the source observable. Caller should subscribe to this.
     * Warning: busy and live forever if observable fails to terminate
     */
    BusyService.prototype.busy$ = function (source) {
        var _this = this;
        this.increment();
        return source.pipe(finalize(function () { return _this.decrement(); }));
    };
    /**
     * Indicate busy until the source promise completes.
     * Display spinner (and message) after a delay if promise has not yet completed.
     *
     * @param source Promise that the busy service should watch
     * @return source Promise that the caller can use for continuation
     */
    BusyService.prototype.busy = function (source) {
        var _this = this;
        this.increment();
        return source.finally(function () { return _this.decrement(); });
    };
    /**
     * Increment the count of busy processes and set current message if no message pending.
     * Causes isBusy$ to emit true.
     */
    BusyService.prototype.increment = function () {
        this.busyCounter++;
        var oldState;
        this._busyState$.pipe(take(1)).subscribe(function (state) { return (oldState = state); });
        // change message with caller's if provided and either there is no current message or should override it
        this.busySubject.next({ isBusy: true });
    };
    /**
     * Decrement the count of busy processes.
     * If no more busy processes, clear the current message and indicate no longer busy
     * (isBusy$ emits false).
     */
    BusyService.prototype.decrement = function () {
        if (this.busyCounter > 0) {
            this.busyCounter--;
        }
        if (this.busyCounter === 0) {
            this.busySubject.next(notBusy);
        }
    };
    BusyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusyService_Factory() { return new BusyService(); }, token: BusyService, providedIn: "root" });
    return BusyService;
}());
export { BusyService };
