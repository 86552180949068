<slx-spinner [show]="isLoading">
  <section class="slx-high-box">
    <header class="slx-content-toolbar-indents">
      <slx-pbj-reconciliation-toolbar type="orglevel"></slx-pbj-reconciliation-toolbar>
    </header>
    <div class="slx-high-box__body slx-main-content-indents">
      <h3 class="pbj-rec-header">
        <i class="pbj-rec-header__icon fas fa-id-badge fa-users" aria-hidden="true"></i>
        <span *ngIf="isAboveOrganization || isOrganization" class="pbj-rec-header__text">{{ orgLevel?.name }}</span>
        <span *ngIf="isDepartment" class="pbj-rec-header__text">
          <span *ngIf="parentOrganizationName">{{ parentOrganizationName }} - </span>
          <span>{{ orgLevel?.name }}</span>
        </span>
      </h3>
      <div class="pbj-rec-grids">
        <slx-pbj-reconciliation-org-grid *ngIf="isAboveOrganization"></slx-pbj-reconciliation-org-grid>
        <slx-pbj-reconciliation-dep-grid *ngIf="isOrganization"></slx-pbj-reconciliation-dep-grid>
        <slx-pbj-reconciliation-emp-grid *ngIf="isDepartment"></slx-pbj-reconciliation-emp-grid>
      </div>
    </div>
  </section>
</slx-spinner>
