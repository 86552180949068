import { OrgLevel } from '../../state-model/models/index';

export class OrgLevelFlat {
  public orgLevel: OrgLevel;
  public parentOrgLevel: OrgLevel;
  public get parentName(): string {
    if (!this.parentOrgLevel) {
      return '';
    }
    return this.parentOrgLevel.name;
  }
}
