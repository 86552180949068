import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnChanges, SimpleChanges, OnInit, OnDestroy, Inject } from '@angular/core';
import { ArrivalsDeparturesTimelineDetailsManagementService, ArrivalDeparturesManagementService, TaConsoleToolbarService } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { ArrivalDeparturesFilter, ArrivalDeparturesContainer } from '../../../models/index';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'slx-arrivals-departures-timeline-details',
  templateUrl: 'arrivals-departures-timeline-details.component.html',
  styleUrls: ['arrivals-departures-timeline-details.component.scss'],
  providers: [ArrivalsDeparturesTimelineDetailsManagementService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrivalsDeparturesTimelineDetailsComponent implements OnChanges, OnInit, OnDestroy {

  @Input()
  public orgLevelId: number;
  @Input()
  public date: Date;


  public isLoading: boolean;
  public isCollapsed: boolean;
  public allStateRestored: boolean;

  @unsubscribe()
  private routeSubscription: Subscription;
  @unsubscribe()
  private onLoadStatusSubscription: Subscription;
  @unsubscribe()
  private onFilterChangedSubscription: Subscription;
  @unsubscribe()
  private onArrivalDeprturesLoadedSubscription: Subscription;

  private readonly m_componentId: string = 'TaConsoleDetailsComponent';

  constructor(private changeDetector: ChangeDetectorRef, private consoleManagementService: ArrivalDeparturesManagementService,
    private managementService: ArrivalsDeparturesTimelineDetailsManagementService,
    @Inject(TOOLBAR_SERVICE) private consoleToolbarService: TaConsoleToolbarService) {
  }

  public changeDetection(): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public ngOnInit(): void {
    this.onLoadStatusSubscription = this.managementService.onLoadStatus$.subscribe((value: boolean) => {
      this.isLoading = value;
      this.changeDetection();
    });
    this.onFilterChangedSubscription = this.consoleManagementService.onFilterChanged$.subscribe((filter: ArrivalDeparturesFilter) => {
      this.managementService.onArrivalDeparturesFilterChangedSelected(filter);
    });
    this.onArrivalDeprturesLoadedSubscription = this.consoleManagementService.onLoaded$.subscribe((container: ArrivalDeparturesContainer) => {
      this.managementService.ArrivalDeparturesLoaded(container);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const isDateChanged = changes['date'] && !moment(changes['date'].previousValue).isSame(changes['date'].currentValue);
    const isOrgLevelChanged = changes['orgLevelId'] && changes['orgLevelId'].previousValue !== changes['orgLevelId'].currentValue;
    if (!isDateChanged && !isOrgLevelChanged) {
      return;
    }
    if (this.orgLevelId && this.date) {
      this.loadData();
    }
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public toggleState(): void {
    this.isCollapsed = !this.isCollapsed;
    this.consoleToolbarService.onRecalcToolbar();
    this.changeDetection();
  }

  private loadData(): void {
    if (!this.orgLevelId || !this.date) {
      return;
    }
    this.managementService.loadData(this.orgLevelId, this.date, this.date);
    this.changeDetection();
  }
}
