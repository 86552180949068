import * as tslib_1 from "tslib";
import { OnInit, NgZone } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { ModalService } from '../../../../../common/services';
import { EmployeeSectionsEmploymentApiService } from '../../../services';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { NgForm } from '@angular/forms';
import { WcIncidentRosterEntry } from '../../../../../app-modules/workers-compensation/models';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models';
import { process } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../../../core/decorators';
import { Subscription } from 'rxjs/Subscription';
import { EmployeeIncidents } from '../../../models/employee-sections-employment/employee-incidents';
import { appConfig } from '../../../../../app.config';
import { WCIncidentReportDialogComponent } from '../../../../../app-modules/workers-compensation/components/crud';
var EmployeeSectionsWorkersCompComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsWorkersCompComponent, _super);
    function EmployeeSectionsWorkersCompComponent(modalService, apiService, decorator, ngZone) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.modalService = modalService;
        _this.apiService = apiService;
        _this.sort = [];
        _this.pageSize = 5;
        _this.paceholderlink = "-------------------";
        _this.appConfig = appConfig;
        decorator.isEditableByConfiguration = false;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.skip = 0;
        _this.gridState.state.take = _this.pageSize;
        _this.gridState.state.sort = [{ field: 'reportDateTime', dir: 'desc' }];
        return _this;
    }
    Object.defineProperty(EmployeeSectionsWorkersCompComponent.prototype, "workersComp", {
        get: function () {
            return this.m_workersComp;
        },
        set: function (c) {
            this.m_workersComp = c;
            if (this.m_workersComp) {
                this.generateFilters(this.m_workersComp.records);
            }
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsWorkersCompComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsWorkersCompComponent.prototype, "employeeId", {
        get: function () {
            return this.m_employeeId;
        },
        set: function (value) {
            this.m_employeeId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsWorkersCompComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsWorkersCompComponent.prototype.ngOnInit = function () {
        this.createSubscriptions();
    };
    EmployeeSectionsWorkersCompComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    EmployeeSectionsWorkersCompComponent.prototype.getSubsectionModel = function () {
        return this.m_workersComp;
    };
    EmployeeSectionsWorkersCompComponent.prototype.onStartAdd = function () {
        this.openNewReportDialog();
    };
    EmployeeSectionsWorkersCompComponent.prototype.onSelectItem = function (item) {
        this.openEditReportDialog(item.id);
    };
    EmployeeSectionsWorkersCompComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    EmployeeSectionsWorkersCompComponent.prototype.openNewReportDialog = function () {
        var _this = this;
        WCIncidentReportDialogComponent.openDialog({ reportId: null, employeeId: this.m_employeeId }, this.modalService, function (result) {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsWorkersCompComponent.prototype.openEditReportDialog = function (reportId) {
        var _this = this;
        WCIncidentReportDialogComponent.openDialog({ reportId: reportId, employeeId: null }, this.modalService, function (result) {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsWorkersCompComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.apiService.getEmployeeWrokersCompensation(this.employeeId)
            .then(function (subsectionData) {
            _this.workersComp = subsectionData;
            _this.refreshGrid();
            _this.stopProgress();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsWorkersCompComponent.prototype.generateFilters = function (records) {
        this.typeFilters = this.generateTypeFilters();
    };
    EmployeeSectionsWorkersCompComponent.prototype.generateTypeFilters = function () {
        return [
            {
                text: 'Incident',
                value: 0
            },
            {
                text: 'Incident/OSHA',
                value: 1
            },
            {
                text: 'Incident/WC',
                value: 2
            },
            {
                text: 'Incident/OSHA/WC',
                value: 1 | 2
            }
        ];
    };
    EmployeeSectionsWorkersCompComponent.prototype.refreshGrid = function () {
        if (!this.m_workersComp) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.m_workersComp.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsWorkersCompComponent.prototype, "gridRefreshSubscription", void 0);
    return EmployeeSectionsWorkersCompComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsWorkersCompComponent };
