<slx-spinner [show]="isLoading">
  <div class="slx-high-box workers-compensation-configuration">
    <form #form="ngForm" novalidate class="slx-full-height">
      <div class="slx-high-box__body">
          <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
            [data]="gridState?.view"
            [filterable]="'menu'"
            [sortable]="true"
            [pageable]="true"
            [pageSize]="pageSize"
            [filter]="gridState.state.filter"
            [sort]="gridState.state.sort"
            [skip]="gridState.state.skip"
            [selectable]="selectableSettings"
            (dataStateChange)="gridState.dataStateChange($event)"
          >

            <kendo-grid-column title="Employee" width="200" field="employeeName">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Employee</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a (click)="onClickBenefit(dataItem.id)"><strong>{{ dataItem.employeeName }}</strong></a>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Position" width="180" field="position.name">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Position</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.position?.name }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Department" width="180" field="department.name">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Department</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.department?.name }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Type" width="130" field="type.name">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Type</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.type?.name }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Employee % Contribution" width="180" field="employeeContributionPercentage">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Employee % Contribution</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <slx-number class="slx-wide slx-input-number" 
                  #empContrPercentage="ngModel"
                  [min]="minEmployeeContribution"
                  [max]="maxEmployeeContribution"
                  [step]="stepcurrency"
                  [decimals]="2"
                  [autoCorrect]='dataItem.employeeContributionPercentage !=null ? false : true'
                  [(ngModel)]="dataItem.employeeContributionPercentage"
                  (ngModelChange)="empContrPercentageChange(dataItem)"  name="employeeContributionPercentage_{{ rowIndex }}"
                  [readonly]="isEmpPercentSelected(dataItem)"
                  [required]='dataItem.employeeContributionAmount !=null ? false : true'
                ></slx-number>
                <span *ngIf="empContrPercentage.errors && !isEmpPercentSelected(dataItem)" class="slx-error-block error-box">
                  <span *ngIf="empContrPercentage.errors.required" errorMessage for="required"></span>
                  <span *ngIf="empContrPercentage.errors.min" errorMessage>Must be greater than {{ minEmployeeContribution }}</span>
                  <span *ngIf="empContrPercentage.errors.max" errorMessage>Must be less than {{ maxEmployeeContribution }}</span>
                </span>
            </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Employee Contribution" width="180" field="employeeContributionAmount">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Employee Contribution</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <slx-number class="slx-wide slx-input-number"
                  #empContrAmount="ngModel"
                  [min]="minAmt" 
                  [max]="maxAmt"
                  [step]="stepcurrency"
                  [format]="currencyFormat"
                  [decimals]="2"
                  [autoCorrect]='dataItem.employeeContributionAmount !=null ? false : true'
                  [(ngModel)]="dataItem.employeeContributionAmount"
                  (ngModelChange)="empContrPercentageChange(dataItem)"
                  name="employeeContributionAmount_{{ rowIndex }}"
                  [readonly]="isEmpContribSelected(dataItem)"
                  [required]='dataItem.employeeContributionPercentage !=null ? false : true'
                ></slx-number>
                <span *ngIf="empContrAmount.errors  && !isEmpContribSelected(dataItem)" class="slx-error-block error-box">
                  <span *ngIf="empContrAmount.errors.required" errorMessage for="required"></span>
                  <span *ngIf="empContrAmount.errors.min" errorMessage>Must be greater than {{ minAmt }}</span>
                  <span *ngIf="empContrAmount.errors.max" errorMessage>Must be less than {{ maxAmt }}</span>
                </span>
            </ng-template>
            </kendo-grid-column>
           
            <kendo-grid-column title="Max Matching Grant (%)" width="180" field="maxMatchGrant">
              <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Max Matching Grant (%)</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.maxMatchGrant | number:'.2'| slxPercent }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Limit Amount" width="180" field="limit">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Limit Amount</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      {{dataItem.limit | number: ".2-2"| slxMoney }}
              </ng-template>
            </kendo-grid-column>

            </kendo-grid>
      </div>
    </form>
  </div>
</slx-spinner>
