import {
  Directive, DoCheck, AfterViewInit, ChangeDetectorRef, ElementRef, Input, IterableChanges, IterableDiffer, IterableDiffers,
  KeyValueChanges, KeyValueDiffer, KeyValueDiffers, Renderer, ɵisListLikeIterable as isListLikeIterable, ɵstringify as stringify
} from '@angular/core';

@Directive({ selector: '[classToParent]' })
export class ClassToParentDirective implements DoCheck, AfterViewInit {
  @Input()
  public parentTagName: string;
  @Input()
  public set classToParent(v: string | string[] | Set<string> | { [klass: string]: any }) {
    this.cleanupClasses(this.rawClass);

    this.iterableDiffer = null;
    this.keyValueDiffer = null;

    this.rawClass = typeof v === 'string' ? v.split(/\s+/) : v;

    if (this.rawClass) {
      if (isListLikeIterable(this.rawClass)) {
        this.iterableDiffer = this.i_iterableDiffers.find(this.rawClass).create();
      } else {
        this.keyValueDiffer = this.i_keyValueDiffers.find(this.rawClass).create();
      }
    }
  }

  private iterableDiffer: IterableDiffer<string>;
  private keyValueDiffer: KeyValueDiffer<string, any>;
  private initialClasses: string[] = [];
  private rawClass: string[] | Set<string> | { [klass: string]: any };
  private rowElement: any;
  private i_iterableDiffers: IterableDiffers;
  private i_keyValueDiffers: KeyValueDiffers;
  private i_ngEl: ElementRef;
  private i_renderer: Renderer;

  constructor(
    i_iterableDiffers: IterableDiffers, i_keyValueDiffers: KeyValueDiffers, i_ngEl: ElementRef, i_renderer: Renderer) {
    this.i_iterableDiffers = i_iterableDiffers;
    this.i_keyValueDiffers = i_keyValueDiffers;
    this.i_ngEl = i_ngEl;
    this.i_renderer = i_renderer;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.findParent(), 200);
  }

  public ngDoCheck(): void {
    if (!this.rowElement) {
      return;
    }
    if (this.iterableDiffer) {
      const iterableChanges = this.iterableDiffer.diff(this.rawClass as string[]);
      if (iterableChanges) {
        this.applyIterableChanges(iterableChanges);
      }
    } else if (this.keyValueDiffer) {
      const keyValueChanges = this.keyValueDiffer.diff(this.rawClass as { [k: string]: any });
      if (keyValueChanges) {
        this.applyKeyValueChanges(keyValueChanges);
      }
    }
  }

  private cleanupClasses(rawClassVal: string[] | { [klass: string]: any }): void {
    this.applyClasses(rawClassVal, true);
    this.applyInitialClasses(false);
  }

  private applyKeyValueChanges(changes: KeyValueChanges<string, any>): void {
    changes.forEachAddedItem((record) => this.toggleClass(record.key, record.currentValue));
    changes.forEachChangedItem((record) => this.toggleClass(record.key, record.currentValue));
    changes.forEachRemovedItem((record) => {
      if (record.previousValue) {
        this.toggleClass(record.key, false);
      }
    });
  }

  private applyIterableChanges(changes: IterableChanges<string>): void {
    changes.forEachAddedItem((record) => {
      if (typeof record.item === 'string') {
        this.toggleClass(record.item, true);
      } else {
        throw new Error(
          `NgParentClass can only toggle CSS classes expressed as strings, got ${stringify(record.item)}`);
      }
    });

    changes.forEachRemovedItem((record) => this.toggleClass(record.item, false));
  }

  private applyInitialClasses(isCleanup: boolean): void {
    this.initialClasses.forEach(classStub => this.toggleClass(classStub, !isCleanup));
  }

  private applyClasses(
    rawClassVal: string[] | Set<string> | { [klass: string]: any }, isCleanup: boolean): void {
    if (rawClassVal) {
      if (Array.isArray(rawClassVal) || rawClassVal instanceof Set) {
        (<any>rawClassVal).forEach((klass: string) => this.toggleClass(klass, !isCleanup));
      } else {
        Object.keys(rawClassVal).forEach(klass => {
          if (rawClassVal[klass] !== null) this.toggleClass(klass, !isCleanup);
        });
      }
    }
  }

  private findParent(): void {
    if (!this.rowElement) {
      this.rowElement = this.getParentByTagName(this.i_ngEl.nativeElement, this.parentTagName);
      if (this.rowElement) {
        this.applyParentClasses(this.rowElement.className);
        this.ngDoCheck();
      }
    }
  }
  private toggleClass(klass: string, enabled: any): void {
    if (!this.rowElement) {
      return;
    }
    klass = klass.trim();
    if (klass) {
      klass.split(/\s+/g).forEach(
        klass => { this.i_renderer.setElementClass(this.rowElement, klass, !!enabled); });
    }
  }

  private applyParentClasses(v: string): void {
    this.applyInitialClasses(true);
    this.initialClasses = typeof v === 'string' ? v.split(/\s+/) : [];
    this.applyInitialClasses(false);
    this.applyClasses(this.rawClass, false);
  }

  private getParentByTagName(node: any, tagname: string): any {
    let parent: any;
    if (node === null || tagname === '') {
      return null;
    }
    parent = node.parentNode;
    tagname = tagname.toUpperCase();
    if (!parent) {
      return null;
    }
    while (parent && parent.tagName !== 'HTML') {
      if (parent.tagName === tagname) {
        return parent;
      }
      parent = parent.parentNode;
    }
    return parent;
  }
}
