import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TreeNodeModel } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-tree-node-component',
  encapsulation: ViewEncapsulation.Emulated,
  templateUrl: 'tree-node.component.html'
})

export class TreeNodeComponent {

  @Input() public node: TreeNodeModel;
  @Input() public index: number;
  @Input() public templates: any;

}
