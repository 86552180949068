/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./action-list-item.component";
import * as i3 from "ngx-popper";
var styles_ActionListItemComponent = [];
var RenderType_ActionListItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionListItemComponent, data: {} });
export { RenderType_ActionListItemComponent as RenderType_ActionListItemComponent };
function View_ActionListItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [], null, [[null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseup" === en)) {
        var pd_0 = (_co.onMouseup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ActionListItemComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionListItemComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ActionListItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-action-list-item", [], null, null, null, View_ActionListItemComponent_0, RenderType_ActionListItemComponent)), i0.ɵdid(1, 49152, null, 0, i2.ActionListItemComponent, [i3.PopperContent], null, null)], null, null); }
var ActionListItemComponentNgFactory = i0.ɵccf("slx-action-list-item", i2.ActionListItemComponent, View_ActionListItemComponent_Host_0, { isDisabled: "disabled", hidden: "hidden", customClassName: "className", fullyCustomStyle: "fullyCustomStyle" }, { onClick: "onClick" }, ["*"]);
export { ActionListItemComponentNgFactory as ActionListItemComponentNgFactory };
