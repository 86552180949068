import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { OrgLevelResolver } from './services/index';
import { LogiHostComponent } from '../app-modules/logi-integration/components/logi-host.component';
import { ExportDataComponent } from '../app-modules/export-data/components/index';
import { W2sListComponent } from '../reports/components/w2s/w2s-list/w2s-list.component';

import {
  ApplicationContainerComponent,
  AuthorizessoComponent,
  HeaderComponent,
  LeftSidebarComponent
} from './components/index';

import { ReportsComponent } from '../reports/components/index';

export const reportsRoutes: Routes = [
  {
    path: '',
    component: ApplicationContainerComponent,
    children: [
      {
        path: '',
        component: ReportsComponent,
        data: { componentId: 'reports', accessContext: 'OrgLevel' }
      },
      {
        path: 'standard_reports',
        component: ReportsComponent,
        data: { componentId: 'reports', accessContext: 'OrgLevel' }
      },
      {
        path: 'bi_adhoc_create_report',
        component: LogiHostComponent,
        data: { logiComponentId: 'analysis_adh', componentId: 'bi_adhoc_create_report' }
      },
      {
        path: 'bi_adhoc_view_reports',
        component: LogiHostComponent,
        data: { logiComponentId: 'explorer_adh', componentId: 'bi_adhoc_view_reports' }
      },
      {
        path: 'export_data',
        resolve: [OrgLevelResolver],
        component: ExportDataComponent,
        data: { componentId: 'export_data', accessContext: 'OrgLevel' }
      },
      {
        path: 'w2_management',
        resolve: [OrgLevelResolver],
        component: W2sListComponent,
        data: { componentId: 'w2_management', accessContext: 'OrgLevel' }
      },
      {
        path: 'sso',
        component: AuthorizessoComponent,
        data: { componentId: 'authorize' }
      },
    ]
  },
];


