import { IEssTemplateOption, EssTemplateOption } from './ess-template-option';

export interface IEssTemplateOptionGroup {
  id: number;
  name: string;
  options: IEssTemplateOption[];
}

export class EssTemplateOptionGroup {
  id: number;
  name: string;
  options: EssTemplateOption[];
}
