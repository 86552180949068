import * as tslib_1 from "tslib";
import { UserApplication } from '../../state-model/models/index';
var ApplicationDashboardItem = /** @class */ (function () {
    function ApplicationDashboardItem() {
    }
    return ApplicationDashboardItem;
}());
export { ApplicationDashboardItem };
var StyledUserApplication = /** @class */ (function (_super) {
    tslib_1.__extends(StyledUserApplication, _super);
    function StyledUserApplication() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return StyledUserApplication;
}(UserApplication));
export { StyledUserApplication };
var DashboardApplications = /** @class */ (function () {
    function DashboardApplications() {
    }
    return DashboardApplications;
}());
export { DashboardApplications };
var DashboardApplication = /** @class */ (function () {
    function DashboardApplication() {
    }
    return DashboardApplication;
}());
export { DashboardApplication };
