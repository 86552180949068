import { Injectable } from '@angular/core';
import { dateTimeUtils } from '../../../common/utils/index';
import * as _ from 'lodash';
import { ITempSensorReading, TempSensorReading } from '../../models/index';
import { LookupMapService, EmployeeDefinitionsMapService } from '../../../../app/organization';

@Injectable()
export class TempSensorReadingMapService {

  constructor(private lookupMapService: LookupMapService,
              private employeeDefinitionsMapService: EmployeeDefinitionsMapService) {
  }

  public mapTempSensorReadings(records: ITempSensorReading[]): TempSensorReading[] {
    return _.map(records, (item: ITempSensorReading) => {
      return this.mapTempSensorReading(item);
    });
  }
  public mapTempSensorReading(dto: ITempSensorReading): TempSensorReading {
    let data: TempSensorReading = new TempSensorReading();
    data.employee = !_.isNil(dto.employee) ? this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee) : null;
    data.organization = !_.isNil(dto.organization) ? this.lookupMapService.mapOrganization(dto.organization) : null;
    data.department = !_.isNil(dto.department) ? this.lookupMapService.mapDepartment(dto.department) : null;
    data.position = !_.isNil(dto.position) ? this.lookupMapService.mapPosition(dto.position) : null;
    data.clock = dto.clock;
    data.eventDate = dateTimeUtils.convertFromDtoDateString(dto.dateTime);
    data.timeStamp = dateTimeUtils.convertFromDtoString(dto.dateTime);
    data.tempReading = dto.tempReading;
    data.temperatureWithDec = dto.temperatureWithDec == 0 ? '' : dto.temperatureWithDec.toFixed(1) + '°F';
    data.thresholdWithDec = dto.thresholdWithDec == 0 ? '' : dto.thresholdWithDec.toFixed(1) + '°F';
    return data;
  }
}
