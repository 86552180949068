import { PBJExportRange } from './pbj-export-range';
import { PBJExportType } from './pbj-export-type';

export interface IPBJExportRequest {
  fiscalYear: number;
  exportRange: string;
  startDate: string;
  endDate: string;
  includeEmployee: boolean;
  includeStaffingHours: boolean;
  staffingHoursExportType: string;
  orgLevelId: number;
  parentId: number;
}


export class PBJExportRequest {
  public fiscalYear: number;
  public exportRange: PBJExportRange;
  public startDate: Date;
  public endDate: Date;
  public includeEmployee: boolean;
  public includeStaffingHours: boolean;
  public staffingHoursExportType: PBJExportType;
  public orgLevelId: any;
  public parentId: any = 0;
}


export interface IPBJBatchExport {
  startDate: string;
  endDate: string;
  orgLevelId: number;
}


export class PBJBatchExport {
  public startDate: Date;
  public endDate: Date;
  public orgLevelId: number;
}
