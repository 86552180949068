import * as tslib_1 from "tslib";
import { DialogOptions2, DialogModeSize } from './../../../../../../common/models/dialog-options';
import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { BenefitsEnrollmentSectionManagementService } from '../../../../services';
import * as _ from 'lodash';
import { appConfig } from '../../../../../../app.config';
import { employeeSectionsConfig } from '../../../../employee-sections.config';
import { ReadFile } from '../../../../../../organization/models/index';
var BenefitAddAttachmentsDialogComponent = /** @class */ (function () {
    function BenefitAddAttachmentsDialogComponent(dialogOptions, modalService, manService) {
        this.dialogOptions = dialogOptions;
        this.modalService = modalService;
        this.appConfig = appConfig;
        this.errors = {};
        this.isFileSelected = true;
        this.modalService = modalService;
        this.manService = manService;
        this.maxFileSizeBytes = employeeSectionsConfig.files.maxFileSizeBytes;
        this.acceptedFileTypes = employeeSectionsConfig.files.acceptedTypes;
        this.acceptedFileTypesInputFormat = employeeSectionsConfig.files.acceptedTypes.join(',');
        this.acceptedFileTypesReadable = employeeSectionsConfig.files.acceptedTypes.join(', ');
        this.maxFileSize = this.getReadableBytes(employeeSectionsConfig.files.maxFileSizeBytes);
    }
    BenefitAddAttachmentsDialogComponent.openDialog = function (modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.dialog;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
        ];
        return modalService.globalAnchor
            .openDialog2(BenefitAddAttachmentsDialogComponent, 'Add Employee Document', dialogOptions, resolvedProviders, function (result) {
            callback(result);
        });
    };
    BenefitAddAttachmentsDialogComponent.prototype.onFileChange = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileList, files, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        fileList = _.get(event, 'target.files');
                        files = this.validateFiles(fileList, event);
                        if (!(files.length > 0)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.manService.readAddedFiles(files)];
                    case 1:
                        _a.files = _b.sent();
                        this.customFileName = this.files[0].name;
                        this.isFileSelected = false;
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BenefitAddAttachmentsDialogComponent.prototype.validateFiles = function (fileList, event) {
        var isError = false;
        var files = [];
        for (var i = 0, length_1 = fileList.length; i < length_1; i++) {
            var file = fileList.item(i);
            if (file.size > this.maxFileSizeBytes) {
                isError = true;
                this.setError('maxFileSize');
                break;
            }
            if (file.size === 0) {
                isError = true;
                this.setError('zeroFileSize');
                break;
            }
            var fileExt = "." + _.last(file.name.split('.'));
            if (!_.includes(this.acceptedFileTypes, fileExt.toLowerCase())) {
                isError = true;
                this.setError('fileType');
                break;
            }
            files.push(file);
        }
        this.resetInput(event.target);
        return isError ? [] : files;
    };
    BenefitAddAttachmentsDialogComponent.prototype.onResetErrors = function (event) {
        this.resetInput(event.target);
        this.errors = {};
    };
    BenefitAddAttachmentsDialogComponent.prototype.setError = function (errorType) {
        this.errors.maxFileSize = false;
        this.errors.fileType = false;
        switch (errorType) {
            case 'maxFileSize':
                this.errors.maxFileSize = true;
                break;
            case 'fileType':
                this.errors.fileType = true;
                break;
            case 'zeroFileSize':
                this.errors.zeroFileSize = true;
                break;
        }
    };
    BenefitAddAttachmentsDialogComponent.prototype.resetInput = function (elem) {
        elem.value = '';
        this.isFileSelected = true;
        this.customFileName = '';
    };
    BenefitAddAttachmentsDialogComponent.prototype.save = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    BenefitAddAttachmentsDialogComponent.prototype.cancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    BenefitAddAttachmentsDialogComponent.prototype.getReadableBytes = function (bytes) {
        var i = Math.floor(Math.log(bytes) / Math.log(1024));
        var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var res = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
        return res * 1 + " " + sizes[i];
    };
    return BenefitAddAttachmentsDialogComponent;
}());
export { BenefitAddAttachmentsDialogComponent };
