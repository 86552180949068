import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { PunchLogsManagementService } from '../../../services/punches/punch-logs-management.service';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
var PunchLogsComponent = /** @class */ (function () {
    function PunchLogsComponent(managementService) {
        this.groupName = 'PunchLogs';
        this.managementService = managementService;
        this.state = {
            isLoading: false,
        };
    }
    PunchLogsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
    };
    PunchLogsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PunchLogsComponent.prototype.onDateChange = function () {
        this.reload();
    };
    PunchLogsComponent.prototype.onExport = function () {
        this.managementService.export();
    };
    PunchLogsComponent.prototype.reload = function () {
        if (!this.employeeId || !this.date) {
            return;
        }
        var startDate = moment(this.date).startOf('day').toDate();
        var endDate = moment(this.date).endOf('day').toDate();
        this.managementService.reloadLogs(startDate, endDate, this.employeeId);
    };
    PunchLogsComponent.prototype.ngOnChanges = function (changes) {
        if (changes['employeeId'] || changes['date']) {
            this.reload();
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchLogsComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchLogsComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PunchLogsManagementService)
    ], PunchLogsComponent.prototype, "managementService", void 0);
    return PunchLogsComponent;
}());
export { PunchLogsComponent };
