import * as tslib_1 from "tslib";
import { IndividualScheduleNightShiftService } from './../../services/individual-schedule/individual-schedule-night-shift.service';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import * as moment from 'moment';
import { select } from '@angular-redux/store';
import { LookupApiService, LookupService } from '../../../organization/services/index';
import { OrgLevel } from '../../../state-model/models/index';
import { IndividualScheduleManagementService, IndividualScheduleEmpManagementService } from '../../services/index';
import { unsubscribe, destroyService, mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { Router, ActivatedRoute } from '@angular/router';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { StateResetTypes } from '../../../core/models/index';
import { scheduleMicrotask } from '../../../core/utils';
var IndividualScheduleComponent = /** @class */ (function () {
    function IndividualScheduleComponent(individualScheduleManagementService, individualScheduleEmpManagementService, stateManagement, router, route, lookupApiService, lookupService, nightShiftService) {
        this.stateManagement = stateManagement;
        this.router = router;
        this.route = route;
        this.lookupApiService = lookupApiService;
        this.lookupService = lookupService;
        this.nightShiftService = nightShiftService;
        this.subscriptions = {};
        this.scheduleCycleControlKey = 'scheduleCycle';
        this.individualScheduleManagementService = individualScheduleManagementService;
        this.individualScheduleEmpManagementService = individualScheduleEmpManagementService;
        this.state = {
            isEmployeeLoading: false,
            isScheduleLoading: false,
            isNightInfoLoading: false,
            isLeftSideNavBarOpen: false
        };
    }
    Object.defineProperty(IndividualScheduleComponent.prototype, "weeksCount", {
        get: function () {
            if (!this.selectedScheduleCycle)
                return 0;
            var weeksCount = this.selectedScheduleCycle.endDate.diff(this.selectedScheduleCycle.startDate, 'days');
            weeksCount = Math.ceil(weeksCount / 7);
            return weeksCount;
        },
        enumerable: true,
        configurable: true
    });
    IndividualScheduleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLeftSidebarOpenSubscripion = this.isLeftSideNavOpen.subscribe(function (value) {
            scheduleMicrotask(function () { _this.state.isLeftSideNavBarOpen = value; });
        });
        this.state.isLeftSideNavBarOpen = false;
        this.stateManagement.init('IndividualScheduleComponent', true);
        this.uiLockSubscription = this.individualScheduleEmpManagementService.onUILock$.subscribe(function (isLocked) {
            _this.state.isEmployeeLoading = isLocked;
        });
        this.nightShiftSubscription = this.nightShiftService.onLoading$.subscribe(function (loading) {
            _this.state.isNightInfoLoading = loading;
        });
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            _this.selectedOrgLevel = o;
            _this.loadPayCycles();
        });
        this.individualScheduleManagementService.loadOrgLvlEmployees$.next(this.selectedScheduleCycle);
        this.route.queryParams.subscribe(function (params) {
            if (params.empID) {
                var cycle = { startDate: moment(params.startDate), id: null, departmentId: null, endDate: moment(params.endDate), name: '' };
                _this.onScheduleCycleSelected(cycle);
            }
        });
    };
    IndividualScheduleComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualScheduleComponent.prototype.loadPayCycles = function () {
        var _this = this;
        if (!this.selectedOrgLevel) {
            return;
        }
        this.lookupApiService.getScheduleCycles(this.selectedOrgLevel.id)
            .then(function (cycles) {
            _this.scheduleCycles = cycles;
            if (!_this.scheduleCycles)
                return;
            if (_this.selectedScheduleCycle) {
                var selectedCycle = _.find(_this.scheduleCycles, function (cycle) {
                    return moment(_this.selectedScheduleCycle.startDate).isSameOrAfter(cycle.startDate) && moment(_this.selectedScheduleCycle.startDate).isSameOrBefore(cycle.endDate);
                });
                _this.selectedScheduleCycle = selectedCycle;
            }
            if (!_this.selectedScheduleCycle) {
                var date_1 = moment().startOf('day');
                _this.selectedScheduleCycle = _.find(_this.scheduleCycles, function (_a) {
                    var startDate = _a.startDate, endDate = _a.endDate;
                    return startDate.isSameOrBefore(date_1) && endDate.isSameOrAfter(date_1);
                });
                if (!_this.selectedScheduleCycle) {
                    _this.selectedScheduleCycle = _.head(_this.scheduleCycles);
                }
            }
            _this.restorePayCycles();
            _this.individualScheduleManagementService.loadOrgLvlEmployees$.next(_this.selectedScheduleCycle);
        })
            .catch(function () {
        });
    };
    IndividualScheduleComponent.prototype.savePayCycles = function () {
        if (!this.selectedScheduleCycle) {
            return;
        }
        this.stateManagement.setControlState(this.scheduleCycleControlKey, { value: { startDate: this.selectedScheduleCycle.startDate, endDate: this.selectedScheduleCycle.endDate } }, StateResetTypes.All);
    };
    IndividualScheduleComponent.prototype.restorePayCycles = function () {
        var state = this.stateManagement.getControlState(this.scheduleCycleControlKey);
        if (state.value) {
            var selectedCycle = _.find(this.scheduleCycles, function (cycle) {
                return moment(state.value.startDate).isSameOrAfter(cycle.startDate) && moment(state.value.startDate).isSameOrBefore(cycle.endDate);
            });
            if (selectedCycle) {
                this.selectedScheduleCycle = selectedCycle;
            }
        }
    };
    IndividualScheduleComponent.prototype.onScheduleCycleSelected = function (cycle) {
        if (cycle !== null) {
            this.selectedScheduleCycle = cycle;
            this.savePayCycles();
            if (this.individualScheduleManagementService.showEmployeeList) {
                this.individualScheduleManagementService.loadOrgLvlEmployees$.next(this.selectedScheduleCycle);
            }
            else {
                this.individualScheduleManagementService.onScheduleChange(this.selectedScheduleCycle, this.weeksCount);
            }
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        select(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        select(['sidebar', 'isLeftSidebarHidden']),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleComponent.prototype, "isLeftSidebarHidden", void 0);
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSideNavOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleComponent.prototype, "isLeftSideNavOpen", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleComponent.prototype, "uiLockSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleComponent.prototype, "nightShiftSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", IndividualScheduleManagementService)
    ], IndividualScheduleComponent.prototype, "individualScheduleManagementService", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", IndividualScheduleEmpManagementService)
    ], IndividualScheduleComponent.prototype, "individualScheduleEmpManagementService", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], IndividualScheduleComponent.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleComponent.prototype, "isLeftSidebarOpenSubscripion", void 0);
    return IndividualScheduleComponent;
}());
export { IndividualScheduleComponent };
