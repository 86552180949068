import { Component, Input, OnChanges } from '@angular/core';
import { EmployeeImagesService, EmployeePhoto } from '../../../../../app/organization';

@Component({
  selector: 'slx-msg-grid-thumbnail-src',
  templateUrl: './msg-grid-thumbnail-src.component.html',
  styleUrls: ['./msg-grid-thumbnail-src.component.scss']
})
export class MsgGridThumbnailSrcComponent implements OnChanges {
  @Input()
  public employeeId: number;
  @Input()
  public employeeName: string;
  public imgPhoto: string;
  @Input()
  public parent: string;
  @Input()
  public mobilePhoneNumber: string;
  @Input()
  public empOptIn: string;
  constructor(private employeeImagesService: EmployeeImagesService) { }

 

  ngOnChanges() {   
      this.employeeImagesService.getEmployeeThumbnail(this.employeeId)
        .then((employeePhoto: EmployeePhoto) => {
          if (employeePhoto.photo) {
            this.imgPhoto = employeePhoto.photo;  
          }
        });
  }
}
