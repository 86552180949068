/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ideal-schedule-fixed-grid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ideal-schedule-grid/ideal-schedule-grid.component.ngfactory";
import * as i3 from "../ideal-schedule-grid/ideal-schedule-grid.component";
import * as i4 from "../../../services/ideal-schedule/ideal-schedule.state.service";
import * as i5 from "./ideal-schedule-fixed-grid.component";
var styles_IdealScheduleFixedGridComponent = [i0.styles];
var RenderType_IdealScheduleFixedGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IdealScheduleFixedGridComponent, data: {} });
export { RenderType_IdealScheduleFixedGridComponent as RenderType_IdealScheduleFixedGridComponent };
export function View_IdealScheduleFixedGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-ideal-schedule-grid", [], null, null, null, i2.View_IdealScheduleGridComponent_0, i2.RenderType_IdealScheduleGridComponent)), i1.ɵdid(1, 245760, null, 0, i3.IdealScheduleGridComponent, [i4.IdealScheduleStateService, i1.ChangeDetectorRef], { range: [0, "range"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fixedRange; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IdealScheduleFixedGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-ideal-schedule-fixed-grid", [], null, null, null, View_IdealScheduleFixedGridComponent_0, RenderType_IdealScheduleFixedGridComponent)), i1.ɵdid(1, 49152, null, 0, i5.IdealScheduleFixedGridComponent, [], null, null)], null, null); }
var IdealScheduleFixedGridComponentNgFactory = i1.ɵccf("slx-ideal-schedule-fixed-grid", i5.IdealScheduleFixedGridComponent, View_IdealScheduleFixedGridComponent_Host_0, { ranges: "ranges" }, {}, []);
export { IdealScheduleFixedGridComponentNgFactory as IdealScheduleFixedGridComponentNgFactory };
