/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../models/collapse-component";
import * as i3 from "./collapse-button.component";
var styles_CollapseButtonComponent = [];
var RenderType_CollapseButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollapseButtonComponent, data: {} });
export { RenderType_CollapseButtonComponent as RenderType_CollapseButtonComponent };
function View_CollapseButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "slx-collapse-toggle-icon ", _co.expandedIcon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_CollapseButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "slx-collapse-toggle-icon ", _co.collapsedIcon, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_CollapseButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapseButtonComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapseButtonComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isCollapsed; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.isCollapsed; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "slx-collapse-button ", _co.className, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_CollapseButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "slx-collapse-button", [], null, null, null, View_CollapseButtonComponent_0, RenderType_CollapseButtonComponent)), i0.ɵprd(6144, null, i2.COLLAPSE_COMPONENT, null, [i3.CollapseButtonComponent]), i0.ɵdid(2, 49152, null, 0, i3.CollapseButtonComponent, [], null, null)], null, null); }
var CollapseButtonComponentNgFactory = i0.ɵccf("slx-collapse-button", i3.CollapseButtonComponent, View_CollapseButtonComponent_Host_0, { className: "className", collapsedIcon: "collapsedIcon", expandedIcon: "expandedIcon", isCollapsed: "isCollapsed" }, { isCollapsedChange: "isCollapsedChange", isCollapsedModify: "isCollapsedModify" }, []);
export { CollapseButtonComponentNgFactory as CollapseButtonComponentNgFactory };
