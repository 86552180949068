<slx-toolbar [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate [width]="270">
      <slx-toolbar-section [showDivider]="false">
          <form novalidate #form="ngForm">
          <div class="date-section">
              <slx-date-navigator className="slx-mobile-navigator"
              [readonly]="lockSave"
              slxChangeManagement
              name="logicalDate"
              [(ngModel)]="logicalDate"
              [maxDate]="dateNow"
              format="MM/dd/yyyy"
              (ngModelChange)="onDateChange()"
              >
              </slx-date-navigator>
          </div>
        </form>
      </slx-toolbar-section>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed"  width="120">
      <slx-toolbar-section [showDivider]="false" *ngIf="!isCollapsed">
        <slx-action-list class="slx-wide">
          <slx-action-button className="slx-action-button-height100" [iconName]="'fas fa-list'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">
            <div class="options-button-content">Options<span class="caret"></span></div>
          </slx-action-button>
          <popper-content #popperContent>
            <slx-actions-list-item class="options-menu-item" [disabled]="disableEditTimeCard" (onClick)="onRecreatePunches()">Create Punches Based on Start and End Times</slx-actions-list-item>
            <slx-actions-list-item class="options-menu-item" [disabled]="disableEditTimeCard" (onClick)="onRemoveCodes()">Remove Manual Pay Code/Exception Adjustments</slx-actions-list-item>
            <slx-actions-list-item class="options-menu-item" [fullyCustomStyle]="true" className="slx-actions-list-item no-hover">
            <div class="options-menu-header">Time Display Options</div>
            <div class="display-menu-item">
              <slx-input-decorator className="slx-form-group slx-no-border slx-small-font">
                <slx-radio-input slx-input name="showIntervalsInDecimal" fieldName="showIntervalsInDecimal" [option]="showIntervalsInDecimal"
                  caption="Show Intervals in Decimals" [(ngModel)]="displayOptions" (ngModelChange)="onOptionSelected(showIntervalsInDecimal)">
                </slx-radio-input>
              </slx-input-decorator>
            </div>
            <div class="display-menu-item">
              <slx-input-decorator className="slx-form-group slx-no-border slx-small-font">
                <slx-radio-input slx-input name="showIntervalsInTime" fieldName="showIntervalsInTime" [option]="showIntervalsInTime" caption="Show Intervals in Hours and Minutes"
                  [(ngModel)]="displayOptions" (ngModelChange)="onOptionSelected(showIntervalsInTime)">
                </slx-radio-input>
              </slx-input-decorator>
            </div>
          </slx-actions-list-item>
          </popper-content>
        </slx-action-list>
      </slx-toolbar-section>
      <slx-toolbar-section [showDivider]="false" *ngIf="isCollapsed">
        <div>
          <button class="daily-timecard-button collapsed-button" [disabled]="disableEditTimeCard" (click)="onRecreatePunches()">
              <i class="fas fa-list" aria-hidden="true"></i>
              Create Punches Based on Start and End Times
          </button>
          <button class="daily-timecard-button collapsed-button" [disabled]="disableEditTimeCard" (click)="onRemoveCodes()">
                  <i class="fas fa-list" aria-hidden="true"></i>
                  Remove Manual Pay Code/Exception Adjustments
          </button>
          <div class="options-menu-header">Time Display Options</div>
              <div class="display-menu-item">
                    <slx-input-decorator className="slx-form-group slx-no-border slx-small-font">
                      <slx-radio-input slx-input name="showIntervalsInDecimal" fieldName="showIntervalsInDecimal" [option]="showIntervalsInDecimal"
                        caption="Show Intervals in Decimals" [(ngModel)]="displayOptions" (ngModelChange)="onOptionSelected(showIntervalsInDecimal)">
                      </slx-radio-input>
                    </slx-input-decorator>
               </div>
               <div class="display-menu-item">
                    <slx-input-decorator className="slx-form-group slx-no-border slx-small-font">
                      <slx-radio-input slx-input name="showIntervalsInTime" fieldName="showIntervalsInTime" [option]="showIntervalsInTime" caption="Show Intervals in Hours and Minutes"
                        [(ngModel)]="displayOptions" (ngModelChange)="onOptionSelected(showIntervalsInTime)">
                      </slx-radio-input>
                    </slx-input-decorator>
               </div>
         </div>
      </slx-toolbar-section>

    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" [width]="120">
      <slx-toolbar-section [showDivider]="false" className="toolbar-section-content-collapsed-height40">
        <button class="daily-timecard-button" [ngClass]="{'collapsed-button': isCollapsed}" (click)="openAudit()">
          <i class="fas fa-history" aria-hidden="true"></i>
          History</button>
      </slx-toolbar-section>
    </ng-template>

    <ng-template  slxToolbarSectionTemplate let-isCollapsed="isCollapsed" [width]="120" >
      <slx-toolbar-section *ngIf="model?.canDebug" [showDivider]="false" className="toolbar-section-content-collapsed-height40">
        <button class="daily-timecard-button" [ngClass]="{'collapsed-button': isCollapsed}" (click)="exportDebugTimecard()">
          <i class="far fa-bug" aria-hidden="true"></i>
          Debug</button>
      </slx-toolbar-section>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="120">
        <slx-toolbar-section [showDivider]="false" className="toolbar-section-content-collapsed-height40">
              <button class="daily-timecard-button return-timecards" [ngClass]="{'collapsed-button': isCollapsed}" [disabled]="!currentPaycycle" (click)="navigateToIndividualTimecards()">
                <i class="far fa-chevron-circle-left" aria-hidden="true"></i>
                Timecards</button>
        </slx-toolbar-section>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="120" >
      <slx-toolbar-section [showDivider]="false" className="toolbar-section-content-collapsed-height40">
            <button *ngIf="!model?.isApproved" class="daily-timecard-button" [ngClass]="{'collapsed-button': isCollapsed}"
              [disabled]="model?.locked || !model?.canModifyOwnTimecard || !model?.canApprove || lockSave || !model?.canEditTimecard || model?.isPayrollCycleSubmitted || model?.hasMissingPunchesAlert || hasOverlap || model?.hasMissingPunches" 
              (click)="approveTimecard()">
              <i class="far fa-calendar-check" aria-hidden="true"></i>
              Approve
              <span class="no-rights-span" *ngIf="!model?.canApprove && !model?.locked">(No rights)</span>
              <span class="no-rights-span" *ngIf="model?.isLocked">(Timecard is locked)</span>
              <span class="no-rights-span" *ngIf="!model?.isLocked && (model?.isPayrollCycleLocked || model?.isOrganizationPayrollLocked)">(Paycycle is locked)</span>
            </button>

            <button *ngIf="model?.isApproved" class="daily-timecard-button unapprove-button"
                [ngClass]="{'collapsed-button': isCollapsed}"
                [disabled]="model?.locked || !model?.canModifyOwnTimecard || !model?.canUnapprove || model?.beforeHire || lockSave  || model?.isPayrollCycleSubmitted || hasOverlap" (click)="unapproveTimecard()">
                <i class="far fa-calendar-times" aria-hidden="true"></i>
                Un-Approve
                <span class="no-rights-span" *ngIf="!model?.canUnapprove&& !model?.locked">(No rights)</span>
                <span class="no-rights-span" *ngIf="model?.isLocked">(Timecard is locked)</span>
                <span class="no-rights-span" *ngIf="!model?.isLocked && (model?.isPayrollCycleLocked || model?.isOrganizationPayrollLocked)">(Paycycle is locked)</span>
            </button>
      </slx-toolbar-section>
    </ng-template>
<!--
    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="120">
      <slx-toolbar-section [showDivider]="false" className="toolbar-section-content-collapsed-height40">
            <button class="daily-timecard-button button-recalc" [ngClass]="{'collapsed-button': isCollapsed}" [disabled]="!currentPaycycle || model?.isApproved" (click)="onRecalculate()">
              <i class="fas fa-save" aria-hidden="true"></i>
              Recalc</button>
      </slx-toolbar-section>
    </ng-template>
-->
    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="90" >
      <slx-toolbar-section *ngIf="model?.id > 0" [showDivider]="false" className="toolbar-section-content-collapsed-height40">
            <button *ngIf="!model?.locked && model?.canLock" class="daily-timecard-button lock-button" [ngClass]="{'collapsed-button': isCollapsed}"
              [disabled]="!model?.canModifyOwnTimecard || hasOverlap" (click)="lockTimecard()">
              <i class="far fa-lock" aria-hidden="true"></i>
              Lock</button>

            <button *ngIf="model?.locked" class="daily-timecard-button unlock-button"
              [ngClass]="{'collapsed-button': isCollapsed}"
              [disabled]="!model?.canModifyOwnTimecard || !model?.canUnlock || model?.isPayrollCycleLocked || model?.isOrganizationPayrollLocked || hasOverlap" (click)="unlockTimecard()">
              <i class="far fa-unlock" aria-hidden="true"></i>
              Unlock
              <span class="no-rights-span" *ngIf="!model?.canUnlock">(No rights)</span>
              <span class="no-rights-span" *ngIf="model?.canUnlock && (model?.isPayrollCycleLocked || model?.isOrganizationPayrollLocked)">(Paycycle is locked)</span>
            </button>
      </slx-toolbar-section>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="120">
      <slx-toolbar-section [showDivider]="false" className="toolbar-section-content-collapsed-height40">
            <button class="daily-timecard-button button-save" [ngClass]="{'collapsed-button': isCollapsed}"
            [disabled]="lockSave || !model?.canEditTimecard || !model?.canEditOwnTimecard || hasOverlap" (click)="saveTimecard()">
              <i class="fas fa-save" aria-hidden="true"></i>
              Save/Recalc</button>
      </slx-toolbar-section>
  </ng-template>
  
</slx-toolbar>
<slx-daily-timecard-employee-info></slx-daily-timecard-employee-info>
<!--
  <div *ngIf="!model?.canEditTimecard" class="readonly-warning">
      Timecard is readonly. <span *ngIf="model?.isApproved">(Approved)</span>
      <span *ngIf="!model?.canEdit">(No rights)</span>
      <span *ngIf="model?.afterTermination">(Employee Terminated)</span>
      <span *ngIf="model?.beforeHire">(Before Date of Hire)</span>
      <span *ngIf="model?.isOutOfPaycycle">(Payroll Cycle has not been generated)</span>
  </div>
  <div class="approve-warning">
    <a *ngIf="model?.unApprovedPayCodesCount>0" (click)="navigateToUnapprovedPaycodes()">(Timecard has unapproved Pay Codes)</a>
    <a *ngIf="model?.unApprovedOTCount>0" (click)="navigateToUnapprovedOvertimes()">(Timecard has unapproved overtimes)</a>
  </div>
<div class="slx-content-toolbar-indents buttons-line">
  <div class="left-buttons">
    <slx-actions-list>
      <slx-actions-button className="low-height" [popperContent]="popperContent" [popperPosition]="'bottom-start'">
        Options <span class="caret"></span>
      </slx-actions-button>
      <popper-content #popperContent>
        <slx-actions-list-item class="options-menu-item" [disabled]="!model?.canEditTimecard" (onClick)="onRecreatePunches()">Create Punches Based on Start and End Times</slx-actions-list-item>
        <slx-actions-list-item class="options-menu-item" [disabled]="!model?.canEditTimecard" (onClick)="onRemoveCodes()">Remove Manual Pay Code/Exception Adjustments</slx-actions-list-item>
        <slx-actions-list-item class="options-menu-item" [fullyCustomStyle]="true" className="slx-actions-list-item no-hover">
          <div class="options-menu-header">Time Display Options</div>
          <div class="display-menu-item">
            <slx-input-decorator className="slx-form-group slx-no-border slx-small-font">
              <slx-radio-input slx-input name="showIntervalsInDecimal" fieldName="showIntervalsInDecimal" [option]="showIntervalsInDecimal"
                caption="Show Intervals in Decimals" [(ngModel)]="displayOptions" (ngModelChange)="onOptionSelected(showIntervalsInDecimal)">
              </slx-radio-input>
            </slx-input-decorator>
          </div>
          <div class="display-menu-item">
            <slx-input-decorator className="slx-form-group slx-no-border slx-small-font">
              <slx-radio-input slx-input name="showIntervalsInTime" fieldName="showIntervalsInTime" [option]="showIntervalsInTime" caption="Show Intervals in Hours and Minutes"
                [(ngModel)]="displayOptions" (ngModelChange)="onOptionSelected(showIntervalsInTime)">
              </slx-radio-input>
            </slx-input-decorator>
          </div>
        </slx-actions-list-item>
      </popper-content>
    </slx-actions-list>

    <button class="theme-iconed-accent-button" type="button" (click)="openAudit()">History</button>
    <button *ngIf="model?.canDebug" class="theme-iconed-accent-button" type="button" (click)="exportDebugTimecard()">Debug
          <i class="fa fa-bug" aria-hidden="true"></i>
        </button>

  </div>
  <div class="middle-buttons">
    <form novalidate #form="ngForm" class="start-date">
      <slx-input-decorator #datePagerContainer className="slx-form-group-white">
        <slx-date-picker-ngx [readonly]="lockSave" slxChangeManagement slx-input slxDatePager [targetContainer]="datePagerContainer" [(ngModel)]="logicalDate"
          (ngModelChange)="onDateChange()" [acceptNullDate]="false" placeholder="Date" name="logicalDate"></slx-date-picker-ngx>
        <span errorMessage for="date"></span>
      </slx-input-decorator>
    </form>
  </div>
  <div class="right-buttons">
    <button type="button" class="theme-iconed-accent-button" [disabled]="!currentPaycycle" (click)="navigateToIndividualTimecards()">
      <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>Timecards
    </button>
    <button class="theme-button-apply" [disabled]="!hasChanges || lockSave || !model?.canEditTimecard" type="button" (click)="saveTimecard()">Save</button>
    <button *ngIf="!model?.isApproved" [disabled]="!model?.canApprove || model?.afterTermination || lockSave || !model?.canEditTimecard" class="theme-button-apply"
      type="button" (click)="approveTimecard()">Approve<span class="no-rights-span" *ngIf="!model?.canApprove">(No rights)</span></button>
    <button *ngIf="model?.isApproved" [disabled]="!model?.canUnapprove || model?.beforeHire || lockSave" class="theme-iconed-accent-button"
      type="button" (click)="unapproveTimecard()">Un-Approve<span class="no-rights-span" *ngIf="!model?.canUnapprove">(No rights)</span></button>
  </div>
</div>
<slx-daily-timecard-employee-info></slx-daily-timecard-employee-info>
-->
