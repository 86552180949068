import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { FieldsMeta, IFieldData } from '../../../core/models/index';
import { IAcaC1095Audit, AcaC1095Audit, IAcaC1095AuditRecord, AcaC1095AuditRecord } from '../../models/aca-c1095-audit';
import { dateTimeUtils } from '../../../common/utils';
import { MetaMapService } from '../../../core/services';

@Injectable()
export class AcaC1095AuditMapService {
    public appConfig: IApplicationConfig;
    constructor(
        private metaMapService: MetaMapService
    ) {
        this.appConfig = appConfig;
    }

    public mapC1095AuditRecords(dto: IAcaC1095Audit, meta: FieldsMeta): AcaC1095Audit {
        const data: AcaC1095Audit = new AcaC1095Audit();
        data.records = _.map(dto.records, record => this.mapC1095AuditRecord(record));
        data.actions = this.metaMapService.mapActions(meta);
        return data;
    }

    public mapC1095AuditRecord(dto: IAcaC1095AuditRecord): AcaC1095AuditRecord {
        const data = new AcaC1095AuditRecord();
        data.employeeId = dto.employeeId;
        data.employeeName = dto.employeeName;
        data.year = dto.year;
        data.month = dto.month;
        data.sugCost = !_.isNull(dto.sugCost) ? parseFloat((dto.sugCost).toFixed(2)) : 0;
        data.sugCode = !_.isNull(dto.sugCode) ? dto.sugCode : '';
        data.sugHarbor = !_.isNull(dto.sugHarbor) ? dto.sugHarbor : '';
        data.sugZip = !_.isNull(dto.sugZip) ? dto.sugZip : '';
        data.selCost = !_.isNull(dto.selCost) ? parseFloat((dto.selCost).toFixed(2)) : 0;
        data.selCode = !_.isNull(dto.selCode) ? dto.selCode : '';
        data.selHarbor = !_.isNull(dto.selHarbor) ? dto.selHarbor : '';
        data.selZip = !_.isNull(dto.selZip) ? dto.selZip : '';
        data.selectedBy = !_.isNull(dto.selectedBy) ? dto.selectedBy : '';
        data.selectedOn = dto.selectedOn ? `${moment(dateTimeUtils.convertFromDtoDateTimeString(dto.selectedOn)).format(appConfig.dateFormatShortYear)} ${moment(dateTimeUtils.convertFromDtoDateTimeString(dto.selectedOn)).format('hh:mm A')}` : '';
        data.confirmedInd = dto.confirmedInd ? 'Yes' : 'No';
        data.confirmedBy = !_.isNull(dto.confirmedBy) ? dto.confirmedBy : '';
        data.confirmedOn = dto.confirmedOn ? `${moment(dateTimeUtils.convertFromDtoDateTimeString(dto.confirmedOn)).format(appConfig.dateFormatShortYear)} ${moment(dateTimeUtils.convertFromDtoDateTimeString(dto.confirmedOn)).format('hh:mm A')}` : '';
        return data;
    }
}
