/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./info-dialog.component";
import * as i3 from "../../models/dialog-options";
import * as i4 from "../../services/modal/modal.service";
var styles_InfoDialogComponent = [i0.styles];
var RenderType_InfoDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoDialogComponent, data: {} });
export { RenderType_InfoDialogComponent as RenderType_InfoDialogComponent };
export function View_InfoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "bootbox-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.options == null) ? null : _co.options.message); _ck(_v, 2, 0, currVal_0); }); }
export function View_InfoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-info-dialog", [], null, null, null, View_InfoDialogComponent_0, RenderType_InfoDialogComponent)), i1.ɵdid(1, 49152, null, 0, i2.InfoDialogComponent, [i3.DialogOptions, i4.ModalService], null, null)], null, null); }
var InfoDialogComponentNgFactory = i1.ɵccf("slx-info-dialog", i2.InfoDialogComponent, View_InfoDialogComponent_Host_0, {}, {}, []);
export { InfoDialogComponentNgFactory as InfoDialogComponentNgFactory };
