import { Component, OnInit, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../app.config';

import { ConstraintModel, EditableListActionKind } from '../../../models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';

@Component({
    moduleId: module.id,
    selector: 'slx-configure-constraint-renderer',
    templateUrl: 'configure-constraint-renderer.component.html',
    styleUrls: ['configure-constraint-renderer.component.scss'],
    providers: [
        {
            provide: CustomListActorBase,
            useExisting: forwardRef(() => ConfigureConstraintRendererComponent)
        }
    ]
})
export class ConfigureConstraintRendererComponent extends CustomListActorBase implements OnInit {

    @Input()
    public set context(value: any) {
        this.item = value.item;
        this.rowIndex = value.rowIndex;
    }

    @Input()
    public item: ConstraintModel;

    @Input()
    public editable: boolean = true;

    @Input()
    public rowIndex: number;


    public get appConfig(): IApplicationConfig {
        return this.m_appConfig;
    }
    private m_appConfig: IApplicationConfig;

    public ngOnInit(): void {
        this.m_appConfig = appConfig;
    }

    public onEditClick(): void {
        this.actionEmitter.emit(EditableListActionKind.START_EDIT);
    }

    public onItemSelect(): void {
        this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
    }
}
