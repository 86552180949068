import { LookupEntity, ILookupEntity } from './lookup-entity';
import { appConfig } from '../../../app.config';

import * as moment from 'moment';

export interface IAvailabilityDateRange extends ILookupEntity {
  employeeId: number;
  startDate: Date;
  endDate: Date;
}

export class AvailabilityDateRange extends LookupEntity implements IAvailabilityDateRange {
  public employeeId: number;
  public get name(): string {
    return `${moment(this.startDate).format(appConfig.dateFormat)} - ${moment(this.endDate).format(appConfig.dateFormat)}`;
  }
  public startDate: Date;
  public endDate: Date;
}
