import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process, aggregateBy } from '@progress/kendo-data-query';
import { ColorUtil } from '../../../../common/utils/index';
import { ArrayUtils } from '../../../../framework/array-utils/array-utils';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import * as kendoUiUtils from '../../../../core/utils/kendo-ui-utils';
import { GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { timeAndAttendanceConfig } from '../../../time-and-attendance.config';
import { IndividualTimecardsManagementService, TimecardDisplayCommonService } from '../../../services/index';
import { TimecardsActionCmd, IndividualTimecardFlatRecord, PayCodeGridModel } from '../../../models/index';
import { A4 } from '../../../../common/models/media/paper-sizes';
var IndividualTimecardMobile = /** @class */ (function () {
    function IndividualTimecardMobile() {
    }
    return IndividualTimecardMobile;
}());
export { IndividualTimecardMobile };
var IndividualTimecardsFlatGridComponent = /** @class */ (function () {
    function IndividualTimecardsFlatGridComponent(managementService, changeDetector, _commonService) {
        this._commonService = _commonService;
        this.aggregates = [];
        this.aggregatesDef = [
            { field: 'day.week', aggregate: 'max' },
            { field: 'productiveHours', aggregate: 'sum' },
            { field: 'nonProductiveHours', aggregate: 'sum' },
            { field: 'totalHours', aggregate: 'sum' },
            { field: 'regularHours', aggregate: 'sum' },
            { field: 'regularPay', aggregate: 'sum' },
            { field: 'overtimeAndExtraHours', aggregate: 'sum' },
            { field: 'overtimeAndExtraPay', aggregate: 'sum' },
            { field: 'totalOtherPay', aggregate: 'sum' },
            { field: 'overTimePay', aggregate: 'sum' },
            { field: 'totalAbsencePay', aggregate: 'sum' },
            { field: 'totalPay', aggregate: 'sum' },
        ];
        this.total = {};
        this.loading = false;
        this.managementService = managementService;
        this.gridState = new KendoGridStateHelper();
        this.changeDetector = changeDetector;
        this.gridState.state.group = [{ field: 'day.week', aggregates: this.aggregates }];
        this.gridState.state.sort = [{ field: 'day.date', dir: 'asc' }];
        this.aggregates = _.map(this.aggregatesDef, function (rule) { return rule; });
        this.highlightedRows = [];
        this.collapsed = {};
    }
    Object.defineProperty(IndividualTimecardsFlatGridComponent.prototype, "exportFilename", {
        get: function () {
            if (this.container) {
                var nameStr = this.getEmployeeName(this.container.employee);
                var sanitizedNameStr = this.sanitizeForFileName(nameStr);
                return "Timecards_" + sanitizedNameStr;
            }
            return 'Timecards';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsFlatGridComponent.prototype, "pdfScale", {
        get: function () {
            return this.m_pdfScale;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsFlatGridComponent.prototype, "isShowHighPrecision", {
        get: function () {
            if (!this.state) {
                return false;
            }
            return this.state.isShowHighPrecision;
        },
        enumerable: true,
        configurable: true
    });
    IndividualTimecardsFlatGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.taConfig = timeAndAttendanceConfig;
        this.stateChangedSubscription = this.managementService.onLoaded$.subscribe(function (container) {
            _this.container = container;
            _this.aggregates = _.map(_this.aggregatesDef, function (rule) { return rule; });
            _.forEach(_this.container.usedRulesDefinitions, function (rule) {
                _this.aggregates.push({ field: rule.uniqName, aggregate: 'sum' });
            });
            _this.firstWeekTotals = _this.container.firstWeekTotals;
            _this.load();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.actionSubscription = this.managementService.onActionCmd$.subscribe(function (v) {
            if (v.cmd === TimecardsActionCmd.excelExport) {
                // this.grid.saveAsExcel();
                _this.makeXlsExport();
            }
            if (v.cmd === TimecardsActionCmd.pdfExport) {
                _this.setupPdfTemplate();
                _this.grid.saveAsPDF();
            }
        });
        this.stateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.state = state;
            var col = _.find(state.empColumns.columns, function (c) { return _this.isVisible(c.name); });
            _this.isAllHidden = !!col;
            if (_this.container && !_this.loading) {
                _this.load();
            }
            else {
                _this.changeDetector.markForCheck();
                _this.changeDetector.detectChanges();
            }
        });
    };
    IndividualTimecardsFlatGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualTimecardsFlatGridComponent.prototype.onCellClick = function (cell) {
        var record = _.get(cell, 'dataItem', null);
        if (_.isObject(record)) {
            var currentId = moment(record.day.date).format(appConfig.dateFormat);
            var previousId = _.head(this.highlightedRows);
            this.highlightedRows.length = 0;
            if (currentId !== previousId) {
                this.highlightedRows.push(currentId);
            }
        }
    };
    IndividualTimecardsFlatGridComponent.prototype.onChangedState = function () {
        var indexes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indexes[_i] = arguments[_i];
        }
        var key = _.map(indexes, function (index) { return String(index); }).join('');
        this.collapsed[key] = !this.collapsed[key];
    };
    IndividualTimecardsFlatGridComponent.prototype.isRowSelected = function () {
        var _this = this;
        return function (event) {
            var date = moment(event.dataItem.day.date).format(appConfig.dateFormat);
            return _.indexOf(_this.highlightedRows, date) !== -1;
        };
    };
    IndividualTimecardsFlatGridComponent.prototype.getUnit = function (field, value) {
        if (!value) {
            return null;
        }
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].unit : null;
    };
    IndividualTimecardsFlatGridComponent.prototype.isVisible = function (field, payCode) {
        if (!this.state) {
            return false;
        }
        var columnState = payCode ? this.state.payCodeColumns : this.state.empColumns;
        if (!columnState || !columnState.columnsMap[field]) {
            return false;
        }
        var column = columnState.columnsMap[field];
        if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
            return false;
        }
        return column.displayed;
    };
    IndividualTimecardsFlatGridComponent.prototype.getWidth = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 50;
    };
    IndividualTimecardsFlatGridComponent.prototype.getFilter = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
    };
    IndividualTimecardsFlatGridComponent.prototype.getColor = function (ruleDef) {
        return ColorUtil.DecToHexString(ruleDef.color, true);
    };
    IndividualTimecardsFlatGridComponent.prototype.getFieldPath = function (ruleDef) {
        return ruleDef.uniqName;
    };
    IndividualTimecardsFlatGridComponent.prototype.getCurrentState = function () {
        var indexes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indexes[_i] = arguments[_i];
        }
        var key = _.map(indexes, function (index) { return String(index); }).join('');
        return _.isBoolean(this.collapsed[key]) ? this.collapsed[key] : (this.collapsed[key] = true);
    };
    IndividualTimecardsFlatGridComponent.prototype.getPayCodeModel = function (record) {
        var payCode = new PayCodeGridModel();
        payCode.positionName = record.positionName;
        payCode.rulesMap = record.rulesMap;
        return payCode;
    };
    IndividualTimecardsFlatGridComponent.prototype.getGridData = function () {
        var _this = this;
        return function () {
            var data = _.cloneDeep(_this.gridState.view.data);
            _.forEach(data, function (group) {
                _.forEach(group.items, function (timecard) {
                    var punchIn = moment(timecard.day.punchInTime);
                    var punchOut = moment(timecard.day.punchOutTime);
                    if (punchIn.isValid()) {
                        timecard.day.punchInSeconds = punchIn.format(appConfig.timeFormat);
                    }
                    if (punchOut.isValid()) {
                        timecard.day.punchOutSeconds = punchOut.format(appConfig.timeFormat);
                    }
                });
            });
            return {
                data: data,
                group: _this.gridState.state.group
            };
        };
    };
    IndividualTimecardsFlatGridComponent.prototype.makeGrouppingForMobile = function () {
        var _this = this;
        var weekErrors = {};
        var groupsOfWeeks = {};
        _.forEach(this.payCycleRecords, function (r) {
            var week = weekErrors[r.day.week] || { errors: 0, invalidPunches: 0, edited: 0 };
            if (r.isError)
                week.errors++;
            if (r.isInvalidPunch || r.isInvalidLogin || r.isNoShow)
                week.invalidPunches++;
            if (r.isEdited)
                week.edited++;
            weekErrors[r.day.week] = week;
            var day = moment(r.day.date).format(_this.appConfig.dateFormat);
            var grouppedByDays = groupsOfWeeks[r.day.week] || {};
            grouppedByDays[day] = grouppedByDays[day] || [];
            grouppedByDays[day].push(r);
            groupsOfWeeks[r.day.week] = grouppedByDays;
        });
        this.groupsOfWeeks = _.map(groupsOfWeeks, function (groupsOfDays, weekNumber) {
            var indTimecard = new IndividualTimecardMobile();
            indTimecard.groupsOfDays = _.values(groupsOfDays);
            indTimecard.weekNumber = weekNumber;
            indTimecard.start = _.head(indTimecard.groupsOfDays)[0].day.date;
            indTimecard.end = _.last(indTimecard.groupsOfDays)[0].day.date;
            indTimecard.weekErrors = weekErrors[weekNumber];
            return indTimecard;
        });
    };
    IndividualTimecardsFlatGridComponent.prototype.addHeaderInformation = function () {
        var exported = moment().format(appConfig.dateTimeFormatUS);
        var empName = this.getEmployeeName(this.container.employee);
        var position = this.container.primaryPosition.name;
        var id = this.container.employee ? this.container.employee.id.toString() : '';
        var extempt = this.container.exemptStatus ? this.container.exemptStatus.name : '';
        var payPolicy = this.container.payPolicy ? this.container.payPolicy.name : '';
        var shiftDiffPolicy = this.container.shiftDiffPolicy && this.container.shiftDiffPolicy.name ? this.container.shiftDiffPolicy.name : '';
        var payrollNumber = this.container.employee ? this.container.employee.payrollNumber : '';
        var employeeType = this.container.employee ? this.container.employee.employeeType.name : '';
        var dateHired = this.container.employee ? moment(this.container.employee.dateHired).format(appConfig.dateFormat) : '';
        var headerString = "Id: " + id + ", Employee: " + empName + ", Position: " + position + ", Exempt Ind: " + extempt + ", Pay Policy: " + payPolicy + ", Shift Diff Policy: " + shiftDiffPolicy + ", Payroll #: " + payrollNumber + ", Type: " + employeeType + ", Hire Date: " + dateHired + ", Exported on " + exported;
        return headerString;
    };
    IndividualTimecardsFlatGridComponent.prototype.setupPdfTemplate = function () {
        var pdfMargins = this.taConfig.settings.export.pdf.margins;
        var cssPixelWidth = this.grid.lockedWidth + this.grid.nonLockedWidth;
        var physPixelWidth = cssPixelWidth;
        var mmWidth = physPixelWidth * this.taConfig.settings.export.pdf.inchInMm / this.taConfig.settings.export.pdf.baseDencity;
        pdfMargins.top = 20; // in this page the top margin is 2 cm = 20mm
        pdfMargins.bottom = 20; // in this page the botton margin is 2 cm = 20mm
        var scale = (A4.heightMM - (pdfMargins.top + pdfMargins.bottom)) / (mmWidth - (pdfMargins.left + pdfMargins.right));
        this.m_pdfScale = Math.min(scale, 0.75);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    IndividualTimecardsFlatGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.payCycleRecords) {
            this.gridState.view = null;
            return;
        }
        var keepFilters;
        if (this.gridState.state.filter && !this.isShowHighPrecision) {
            keepFilters = this.adaptFilterPrecision(this.gridState.state.filter);
        }
        this.gridState.state.group.forEach(function (group) { return group.aggregates = _this.aggregates; });
        this.gridState.view = process(this.payCycleRecords, this.gridState.state);
        this.total = aggregateBy(this.records, this.aggregates);
        if (keepFilters) {
            this.gridState.state.filter = keepFilters;
        }
    };
    IndividualTimecardsFlatGridComponent.prototype.adaptFilterPrecision = function (filter) {
        var filters = _.cloneDeep(filter);
        kendoUiUtils.adaptNumberFilterPrecision(filter, 2);
        return filters;
    };
    IndividualTimecardsFlatGridComponent.prototype.getEmployeeName = function (employee) {
        if (employee) {
            return employee.name;
        }
        return null;
    };
    IndividualTimecardsFlatGridComponent.prototype.sanitizeForFileName = function (inputStr) {
        if (inputStr && inputStr.length > 0) {
            var MAX_FILENAME_LENGTH = 100;
            var reRelativePath = /^\.+/;
            var nonWordChars = /[^\w+]/gi;
            var doubleDashes = /_{2,}/gi;
            var replacement = '_';
            inputStr = _.trim(inputStr);
            inputStr = inputStr.replace(nonWordChars, replacement);
            inputStr = inputStr.replace(doubleDashes, replacement);
            inputStr = inputStr.replace(reRelativePath, replacement);
            inputStr = inputStr.slice(0, MAX_FILENAME_LENGTH);
            return inputStr;
        }
        return 'file';
    };
    IndividualTimecardsFlatGridComponent.prototype.makeXlsExport = function () {
        var gridState = _.cloneDeep(this.gridState);
        var grid = this.grid;
        var addHeaderInfo = this.addHeaderInformation();
        var group = this.gridState.state.group;
        var fileName = this.exportFilename;
        var usedRulesDefinitionsPayRule = this.container ? this.container.usedRulesDefinitions : [];
        this._commonService.makeXlsExport(gridState, grid, group, addHeaderInfo, fileName, usedRulesDefinitionsPayRule);
    };
    IndividualTimecardsFlatGridComponent.prototype.load = function () {
        var _this = this;
        this.loading = true;
        try {
            var groupByKeys_1 = this.getGroupByKeys();
            this.records = _.reduce(this.container.records, function (result, day) {
                var first = true;
                var rs = _.map(day.earnings, function (earn) {
                    var r = new IndividualTimecardFlatRecord();
                    r.day = day;
                    r.earning = earn;
                    r.regularHours = earn.regularHours;
                    r.regularPay = earn.regularPay;
                    r.overtimeAndExtraHours = earn.overtimeAndExtraHours;
                    r.overtimeAndExtraPay = earn.overtimeAndExtraPay;
                    r.totalOtherPay = earn.totalOtherPay;
                    r.overTimePay = earn.overTimePay;
                    r.totalAbsencePay = earn.totalAbsencePay;
                    r.productiveHours = earn.productiveHours;
                    r.nonProductiveHours = earn.nonProductiveHours;
                    r.totalHours = earn.totalHours;
                    r.rulesMap = earn.rulesMap;
                    r.payPolicy = earn.payPolicy;
                    r.shiftDiffPolicy = earn.shiftDiffPolicy;
                    r.isError = day.isError;
                    r.isNoShow = day.isNoShow;
                    r.isInvalidPunch = day.isInvalidPunch;
                    r.isInvalidLogin = day.isInvalidLogin;
                    r.empOrganization = _this.container.empOrganization;
                    r.isLocked = day.isLocked || _this.container.isPayCycleLocked || _this.container.isOrganizationPayrollLocked;
                    r.isEdited = day.isEdited;
                    var ur = r;
                    _.forEach(_this.container.usedRulesDefinitions, function (rule) {
                        ur[rule.uniqName] = r.getRuleValue(rule.name) ? r.getRuleValue(rule.name) : null;
                    });
                    r.totalPay = earn.totalPay;
                    if (first) {
                        first = false;
                        r.isFirst = true;
                    }
                    return r;
                });
                if (rs.length === 0) {
                    var er = new IndividualTimecardFlatRecord();
                    er.day = day;
                    er.earning = null;
                    er.productiveHours = day.productiveHours;
                    er.nonProductiveHours = day.nonProductiveHours;
                    er.totalHours = day.totalHours;
                    er.regularHours = null;
                    er.regularPay = day.regularPay;
                    er.overtimeAndExtraHours = null;
                    er.overtimeAndExtraPay = day.overtimeAndExtra;
                    er.totalOtherPay = day.totalOtherPay;
                    er.overTimePay = day.overTimePay;
                    er.totalAbsencePay = day.totalAbsencePay;
                    er.totalPay = day.totalPay;
                    er.rulesMap = {};
                    er.payPolicy = _this.container.payPolicy;
                    er.shiftDiffPolicy = _this.container.shiftDiffPolicy;
                    er.isFirst = true;
                    er.isError = day.isError;
                    er.isNoShow = day.isNoShow;
                    er.isInvalidPunch = day.isInvalidPunch;
                    er.isInvalidLogin = day.isInvalidLogin;
                    er.empOrganization = _this.container.empOrganization;
                    er.isLocked = day.isLocked || _this.container.isPayCycleLocked || _this.container.isOrganizationPayrollLocked;
                    er.isEdited = day.isEdited;
                    var uer_1 = er;
                    _.forEach(_this.container.usedRulesDefinitions, function (rule) {
                        uer_1[rule.uniqName] = null;
                    });
                    rs.push(er);
                }
                if (rs.length > 1) {
                    var groupResult = ArrayUtils.groupObjects(rs, groupByKeys_1);
                    var groupedRecords_1 = [];
                    var isFirst_1 = true;
                    _.forEach(groupResult, function (groupRecords) {
                        var er = new IndividualTimecardFlatRecord();
                        groupRecords.forEach(function (item) {
                            _this.mergeTimecardRecord(item, er);
                            er.isFirst = isFirst_1;
                        });
                        _this.rebuildRulesMap(er);
                        isFirst_1 = false;
                        groupedRecords_1.push(er);
                    });
                    rs = groupedRecords_1;
                }
                return (result || []).concat(rs);
            }, []);
            this.payCycleRecords = _.filter(this.records, function (r) { return r.day.isInPayCycle; });
            this.makeGrouppingForMobile();
            this.refreshGrid();
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        }
        finally {
            this.loading = false;
        }
    };
    IndividualTimecardsFlatGridComponent.prototype.getGroupByKeys = function () {
        var groupByProperties = ['day.date'];
        this.addGroupByField('organization', 'empOrganization', groupByProperties);
        this.addGroupByField('department', 'departmentName', groupByProperties);
        this.addGroupByField('position', 'positionName', groupByProperties);
        this.addGroupByField('approved', 'day.approved', groupByProperties);
        this.addGroupByField('payPolicy', 'payPolicy', groupByProperties);
        this.addGroupByField('shiftDiffPolicy', 'shiftDiffPolicy', groupByProperties);
        this.addGroupByField('costCenterCode', 'earning.costCenterCode', groupByProperties);
        if (this.state.isShowPayRates) {
            this.addGroupByField('payRate', 'payRate', groupByProperties);
        }
        return groupByProperties;
    };
    IndividualTimecardsFlatGridComponent.prototype.addGroupByField = function (columnName, fieldName, target) {
        if (this.isVisible(columnName)) {
            target.push(fieldName);
        }
    };
    IndividualTimecardsFlatGridComponent.prototype.mergeTimecardRecord = function (source, target) {
        target.day = source.day;
        if (!target.earning) {
            target.earning = _.cloneDeep(source.earning);
        }
        else {
            source.earning.rules.forEach(function (rule) {
                var r = _.cloneDeep(rule);
                target.earning.rules.push(r);
            });
        }
        target.productiveHours = source.productiveHours + (target.productiveHours ? target.productiveHours : 0);
        target.nonProductiveHours = source.nonProductiveHours + (target.nonProductiveHours ? target.nonProductiveHours : 0);
        target.totalHours = source.totalHours + (target.totalHours ? target.totalHours : 0);
        target.regularHours = source.regularHours + (target.regularHours ? target.regularHours : 0);
        target.overtimeAndExtraHours = source.overtimeAndExtraHours + (target.overtimeAndExtraHours ? target.overtimeAndExtraHours : 0);
        target.regularPay = source.regularPay + (target.regularPay ? target.regularPay : 0);
        target.overtimeAndExtraPay = source.overtimeAndExtraPay + (target.overtimeAndExtraPay ? target.overtimeAndExtraPay : 0);
        target.totalOtherPay = source.totalOtherPay + (target.totalOtherPay ? target.totalOtherPay : 0);
        target.overTimePay = source.overTimePay + (target.overTimePay ? target.overTimePay : 0);
        target.totalAbsencePay = source.totalAbsencePay + (target.totalAbsencePay ? target.totalAbsencePay : 0);
        target.payPolicy = source.payPolicy;
        target.shiftDiffPolicy = source.shiftDiffPolicy;
        target.isError = source.isError;
        target.isNoShow = source.isNoShow;
        target.isInvalidPunch = source.isInvalidPunch;
        target.isInvalidLogin = source.isInvalidLogin;
        target.isMoneyRule = source.isMoneyRule;
        target.empOrganization = source.empOrganization;
        target.isLocked = source.isLocked;
        target.totalPay = source.totalPay + (target.totalPay ? target.totalPay : 0);
        target.isEdited = source.isEdited;
    };
    IndividualTimecardsFlatGridComponent.prototype.rebuildRulesMap = function (target) {
        target.earning.rulesMap = _.groupBy(target.earning.rules, function (rule) {
            return rule.payRule.name;
        });
        target.rulesMap = _.groupBy(target.earning.rules, function (rule) {
            return rule.payRule.name;
        });
        var ur = target;
        _.forEach(this.container.usedRulesDefinitions, function (rule) {
            ur[rule.uniqName] = target.getRuleValue(rule.name) ? target.getRuleValue(rule.name) : null;
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsFlatGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsFlatGridComponent.prototype, "stateChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsFlatGridComponent.prototype, "actionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsFlatGridComponent.prototype, "stateSubscription", void 0);
    return IndividualTimecardsFlatGridComponent;
}());
export { IndividualTimecardsFlatGridComponent };
