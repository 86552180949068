<div *ngIf="request" class="slx-high-box">
  <div class="slx-high-box__body slx-main-content-indents table-schedule"
    [ngClass]="{'no-scroll': !hasScheduleViewParams}">

    <div *ngIf="!individualScheduleManagementService.showEmployeeList" class="toggle-l-column" (click)="toggleLCol()" title="Expand">
      <i class="fas fa-angle-right" aria-hidden="true"></i>
    </div>

    <div *ngIf="showEmployeeName" class="emp-name">
      <label>{{request.employeeName}}</label>
    </div>

    <div class="edit-block employee-schedule-header">
      <div class="quick-div">
        <i class="far fa-calendar-plus slx-button__icon quick-edit-btn__c-icon" aria-hidden="true"></i>
        <i class="far fa-hand-point-up slx-button__icon quick-edit-btn__h-icon" aria-hidden="true"></i>
        <span class="slx-button__text">Quick Edit Panel</span>
      </div>

      <div class="eb-header">
        Select options below to add to the employee's schedule then just click on the day(s) you want to add the selected criteria to.
      </div>

      <div class="flex-horizontal editor-div" [ngClass]="{'edit-mode' : state.editMode }">
        <slx-employee-shift-editor
          (onRequiredFieldsPopulated)="onRequiredFieldsPopulated()"
          #shiftEditor
          useAbsence="true"
          [positionPeriod]="scheduleDateRange"
          [employeeId]="request.employeeId"
          [employeeShift]="employeeShift"
          [isReadOnly]="!state.editMode"
          [homePositionId]="homePositionId"
          [homeShiftId]="homeShiftId"
          [homeUnitId]="homeUnitId"
          class="editor-block"
          >
        </slx-employee-shift-editor>

        <div class="edit-button-block" [ngClass]="{'save-cancel-buttons': state.editMode }">
          <button type="button" name="putInEdit"
            class="slx-button slx-blue slx-with-icon slx-margin-r slx-mobile-adapted btn-padding"
            *ngIf="!state.editMode" (click)="onEditModeStart()">
            <i aria-hidden="true" class="fas fa-edit slx-button__icon"></i>
            <span>Edit</span>
          </button>
          <button type="button" name="cancelEdit"
            class="slx-button slx-red slx-with-icon slx-mobile-adapted slx-margin-r btn-padding btn-red"
            *ngIf="state.editMode" (click)="onCancelClicked()">
            <i aria-hidden="true" class="fas fa-times slx-button__icon"></i>
            <span>Cancel</span>
          </button>
          <button name="saveEdit"
            class="slx-button slx-with-icon slx-margin-r slx-mobile-adapted btn-padding btn-green"
            *ngIf="state.editMode" [disabled]="!state.isDirty || state.isFatalOverlapping"
            (click)="onAcceptClicked()" type="button">
            <i aria-hidden="true" class="fas fa-check"></i>
            <span>Save</span>
          </button>
        </div>
      </div>
    </div>

    <table class="table" aria-describedby="weekDay">
      <thead>
        <tr class="table-header">
          <th id="weekDay" class="weekday-cell" *ngFor="let dayHeader of dayColumns">{{dayHeader.startOfDay | amDateFormat: appConfig.dayFullNameWeekDayFormat}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let weeklyData of scheduledEntries?.weeklyData; let i = index; let isFirst = first;">
          <td *ngFor="let dailyData of weeklyData.days;let j = index" class="day-cell" [ngClass]="{'first-row': isFirst}"
            [ngClass]="getCssClass(dailyData)" (click)="addShift(dailyData)">
            <slx-individual-schedule-entry-editor
              [readonly]="isDayReadOnly(dailyData)"
              [nextDayData]="weeklyData.days[j+1]"
              [previousDayData]="weeklyData.days[j-1]"
              [dailyData]="dailyData"
              [employeeShift]="employeeShift"
              [scheduleDateRange]="scheduleDateRange"
              (onEntryChanged)="onEntryChanged($event)">
            </slx-individual-schedule-entry-editor>

            <div class="plus-div" [attr.disabled]="isOutOfPositionPeriod(dailyData)"
              *ngIf="dailyData?.data?.entryDef?.shifts?.length < 10 && isAddShiftReadOnly(dailyData)">
              <hr class="plus-hr">
              <i *ngIf="!isOutOfPositionPeriod(dailyData)" class="fa fa-plus-circle plus-sym" aria-hidden="true" [slxTooltip]="'Click to add Shift'" tipPosition="bottom"></i>
              <i *ngIf="isOutOfPositionPeriod(dailyData)" class="fa fa-plus-circle plus-sym" aria-hidden="true" [slxTooltip]="'The selected position is not active on the selected date'" tipPosition="bottom"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngFor="let entry of checkedEntries">
    <div *ngIf="entry.errors" class="emp-shift-title">{{entry.date | amDateFormat: appConfig.dayFullNumberFormat}}</div>
    <div *ngFor="let error of entry.errors|values" class="error-message">{{error.value}}</div>
  </div>
</div>
