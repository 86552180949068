import * as tslib_1 from "tslib";
import { DialogOptions2, DialogModeSize } from './../../../../../common/models/dialog-options';
import { OnInit, Provider, OnDestroy } from '@angular/core';
import { DialogOptions } from '../../../../../common/index';
import { ModalService } from '../../../../../common/services/index';
import { BenefitEligibleEmployeesRequest } from '../../../models/index';
import { BenefitEmployeeManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../../core/decorators/index';
var BenefitEmployeesDialogComponent = /** @class */ (function () {
    function BenefitEmployeesDialogComponent(options, modalService, request, manService) {
        this.subscriptions = {};
        this.modalService = modalService;
        this.options = options;
        this.request = request;
        this.manService = manService;
    }
    BenefitEmployeesDialogComponent.getTitle = function (mode, planName) {
        if (mode === 'eligible') {
            return "Eligible - Not Enrolled Employees - " + planName;
        }
        else {
            return "Eligible - Employees - Enroll in " + planName;
        }
    };
    BenefitEmployeesDialogComponent.openDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: BenefitEligibleEmployeesRequest,
                useValue: request
            }
        ];
        var title = BenefitEmployeesDialogComponent.getTitle('eligible', request.planName);
        var dialog = modalService.globalAnchor
            .openDialog2(BenefitEmployeesDialogComponent, title, dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, undefined);
        });
        return dialog;
    };
    BenefitEmployeesDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.emloyeeDetails = this.manService.subscribeTogetToDetailsPage(function (value) {
            if (value) {
                _this.onCancel();
            }
        });
    };
    BenefitEmployeesDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    BenefitEmployeesDialogComponent.prototype.modeChanged = function (mode) {
        var title = BenefitEmployeesDialogComponent.getTitle(mode, this.request.planName);
        this.modalService.setTitle(title, this.options.windowUniqueId);
    };
    BenefitEmployeesDialogComponent.prototype.ngOnDestroy = function () { };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitEmployeesDialogComponent.prototype, "subscriptions", void 0);
    return BenefitEmployeesDialogComponent;
}());
export { BenefitEmployeesDialogComponent };
