
<slx-spinner [show]="manageService.isLoading" class="license-types">
  <form novalidate #form="ngForm" class="slx-full-height slx-main-content-indents no-padding">

    <kendo-grid #kendoGrid class="slx-blue-grid slx-full-height no-border"
      [data]="manageService.gridData"
      [sortable]="{ mode: 'multiple' }"
      [sort]="manageService.sort"
      (sortChange)="sortChangeHandler($event)"
      (add)="addHandler()"
      (edit)="editHandler($event)"
      (remove)="removeHandler($event)"
      (save)="saveHandler()"
      (cancel)="cancelHandler()"
    >
      <ng-template kendoGridToolbarTemplate position="top">
        <button (click)="addHandler()" type="button" class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted">
          <i class="fas fa-plus-circle slx-button__icon icon-padding" aria-hidden="true"></i>
          Add New</button>
      </ng-template>
      <kendo-grid-command-column title="Command" width="120">
        <ng-template kendoGridCellTemplate>
          <div class="flex-horizontal">
            <button kendoGridEditCommand [disabled]="isAdding" type="button" class="button-margin slx-button slx-only-icon slx-toolbar">
              <i class="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
            <button kendoGridRemoveCommand [disabled]="isAdding" [popover]="acceptPopover" type="button" class="button-margin slx-button slx-only-icon slx-toolbar">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
            <popover-content #acceptPopover title="Are you sure?" placement="auto">
                <div style="width:70px">
                  <button type="button" (click)="onPopoverAction(acceptPopover, false)" class="button-margin slx-button slx-only-icon slx-toolbar">
                    <i class="fas fa-ban" aria-hidden="true"></i>
                  </button>
                  <button type="button" (click)="onPopoverAction(acceptPopover, true)" class="button-margin slx-button slx-only-icon slx-toolbar">
                      <i class="fas fa-check-circle" aria-hidden="true"></i>
                  </button>
                </div>
            </popover-content>
            <button kendoGridSaveCommand [disabled]="!form.valid" type="button" class="button-margin slx-button slx-only-icon slx-toolbar">
              <i class="far fa-check-circle" aria-hidden="true"></i>
            </button>
              <button kendoGridCancelCommand type="button"  class="button-margin slx-button slx-only-icon slx-toolbar">
              <i class="fa fa-ban" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-column title="Description" field="licenseTypeName">
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.licenseTypeName }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem>
          <slx-input-decorator>
            <input slx-input required="true" [max]="255" name="licenseTypeName" filled
            placeholder="Description" [(ngModel)]="editedEntry.licenseTypeName">
            <span errorMessage for="required"></span>
            <span errorMessage for="filled"></span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Confirming Body" field="confirmingBody">
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.confirmingBody }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem>
           <slx-input-decorator>
              <input slx-input required="true" name="confirmingBody" [max]="255" filled
              placeholder="Confirming Body" [(ngModel)]="editedEntry.confirmingBody">
              <span errorMessage for="required"></span>
              <span errorMessage for="filled"></span>
           </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Applicable Region" field="region">
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.region }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem>
           <slx-input-decorator>
              <input slx-input required="true" name="region" [max]="255" filled
              placeholder="Applicable Region" [(ngModel)]="editedEntry.region">
              <span errorMessage for="required"></span>
              <span errorMessage for="filled"></span>
           </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</slx-spinner>
