import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { IncidentReportDialogOptions } from '../../../models/index';
import { WCReportManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../../core/decorators/index';
var WCIncidentReportContainerComponent = /** @class */ (function () {
    function WCIncidentReportContainerComponent(management) {
        this.management = management;
        this.action = new EventEmitter();
        this.employeeSelected = new EventEmitter();
        this.subscriptions = {};
    }
    Object.defineProperty(WCIncidentReportContainerComponent.prototype, "editMode", {
        get: function () {
            return this.report ? this.report.editMode : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WCIncidentReportContainerComponent.prototype, "hasEmployee", {
        get: function () {
            return _.isFinite(this.employeeId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WCIncidentReportContainerComponent.prototype, "hasReportId", {
        get: function () {
            return _.isFinite(this.reportId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WCIncidentReportContainerComponent.prototype, "canSave", {
        get: function () {
            return this.report.isValid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WCIncidentReportContainerComponent.prototype, "canAdd", {
        get: function () {
            return this.report.canAdd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WCIncidentReportContainerComponent.prototype, "canEdit", {
        get: function () {
            return this.report.canEdit;
        },
        enumerable: true,
        configurable: true
    });
    WCIncidentReportContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.management.subscribeToOrgLevelChange(function (o) {
            if (o) {
                _this.orgLevelId = o.id;
            }
            else {
                _this.orgLevelId = null;
            }
        });
        this.subscriptions.loadStatus = this.management.onLoadStatus$.subscribe(function (isLoading) {
            _this.isLoading = isLoading;
        });
        this.subscriptions.load = this.management.subscribeToReportLoad(function (x) {
            _this.hasError = false;
            _this.report = x;
            _this.reportId = _this.report.id;
            _this.employeeId = _this.report.employee.id;
        });
        this.subscriptions.error = this.management.subscribeToError(function (msg) {
            _this.report = null;
            _this.employeeId = null;
            _this.reportId = null;
            _this.errorMessage = msg;
            _this.hasError = true;
        });
        this.subscriptions.close = this.management.subscribeToClosePopup(function (flag) {
            _this.onClose(flag);
        });
        this.subscriptions.valid = this.management.onValidityChanged(function (valid) {
            _this.formValid = valid;
        });
        if (this.options) {
            if (_.isFinite(this.options.reportId)) {
                this.reportId = this.options.reportId;
                this.management.setReportId(this.options.reportId);
                this.isCreatingNew = false;
            }
            else if (_.isFinite(this.options.employeeId)) {
                this.employeeId = this.options.employeeId;
                this.management.createNewReport(this.employeeId);
                this.isCreatingNew = true;
            }
        }
    };
    WCIncidentReportContainerComponent.prototype.ngOnDestroy = function () { };
    WCIncidentReportContainerComponent.prototype.onSearchLoadStatusChange = function (isLoading) {
        this.isLoading = isLoading;
    };
    WCIncidentReportContainerComponent.prototype.onEmployeeSelected = function (emp) {
        if (emp) {
            this.employeeId = emp.id;
            this.isCreatingNew = true;
            this.management.createNewReport(this.employeeId);
            this.employeeSelected.emit(this.employeeId);
        }
    };
    WCIncidentReportContainerComponent.prototype.onSave = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isCreatingNew) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.saveReport()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.updateReport()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    WCIncidentReportContainerComponent.prototype.onCancel = function () {
        this.management.onCancel();
    };
    WCIncidentReportContainerComponent.prototype.onClose = function (flag) {
        if (flag === void 0) { flag = false; }
        this.action.emit(flag);
    };
    WCIncidentReportContainerComponent.prototype.onEdit = function () {
        this.management.toggleEditMode();
    };
    WCIncidentReportContainerComponent.prototype.saveReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.management.saveReport()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.errorMessage = err_1 && err_1.message || 'An error has occurred, can\'t save report';
                        console.error(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WCIncidentReportContainerComponent.prototype.updateReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.management.updateReport()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.errorMessage = err_2 && err_2.message || 'An error has occurred, can\'t update report';
                        console.error(err_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WCIncidentReportContainerComponent.prototype, "subscriptions", void 0);
    return WCIncidentReportContainerComponent;
}());
export { WCIncidentReportContainerComponent };
