import { OnInit } from '@angular/core';
import { OpenShiftShortDetailsWrapper } from '../../../models/index';
import { appConfig } from '../../../../app.config';
var OpenShiftManagementScheduleCycleDetailsComponent = /** @class */ (function () {
    function OpenShiftManagementScheduleCycleDetailsComponent() {
    }
    OpenShiftManagementScheduleCycleDetailsComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    Object.defineProperty(OpenShiftManagementScheduleCycleDetailsComponent.prototype, "openShiftCount", {
        get: function () {
            return this.detailsWrapper && this.detailsWrapper.details ? this.detailsWrapper.details.openShiftCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftManagementScheduleCycleDetailsComponent.prototype, "messageCount", {
        get: function () {
            return this.detailsWrapper && this.detailsWrapper.details ? this.detailsWrapper.details.messageCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftManagementScheduleCycleDetailsComponent.prototype, "partnerShiftCount", {
        get: function () {
            return this.detailsWrapper && this.detailsWrapper.details ? this.detailsWrapper.details.partnerShiftCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    return OpenShiftManagementScheduleCycleDetailsComponent;
}());
export { OpenShiftManagementScheduleCycleDetailsComponent };
