import { appConfig } from './../../../../app.config';
import { Injectable } from '@angular/core';
import { AuditTrailEmployeeAction, IAuditTrailEmployeeAction, IAuditTrailConfigAction, AuditTrailConfigAction } from '../../models/index';
import * as _ from 'lodash';
import * as moment from 'moment';
import { dateTimeUtils } from '../../../../common/utils/index';
import { AppSettingsManageService } from './../../../../app-settings/services';
import { mutableSelect } from '../../../../core/decorators/index';
import { Observable } from 'rxjs';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';

@Injectable()
export class AuditTrailMapService {

  serverUtcOffsetSec: number;
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  private orgLevel: OrgLevel;
  private isLocalTimeShown: boolean = true;
  private currentOffset: number;


  constructor(private appSettingsManageService: AppSettingsManageService) {
    this.appSettingsManageService.getAppServerConfig()
      .then((appConfig) => {
        this.serverUtcOffsetSec = appConfig.serverUtcOffsetSec;
      });

    this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevel = orgLevel;
      this.getTimeStatus(orgLevel);
    });
  }

  private getTimeStatus(orgLevel: OrgLevel): void {
    if (!orgLevel) return;

    this.isLocalTimeShown = !(orgLevel.type === OrgLevelType.organization || orgLevel.type === OrgLevelType.department);
    if (!this.isLocalTimeShown) {
      this.currentOffset = +_.get(orgLevel, 'location.timeZoneOffset') || 0;
    }
  }

  public mapAuditTrailEmployeeActions(recordsDto: IAuditTrailEmployeeAction[]): AuditTrailEmployeeAction[] {
    return _.map(recordsDto, (dto: IAuditTrailEmployeeAction) => this.mapAuditTrailEmployeeAction(dto));
  }

  public mapAuditTrailEmployeeAction(dto: IAuditTrailEmployeeAction): AuditTrailEmployeeAction {
    let act: AuditTrailEmployeeAction = new AuditTrailEmployeeAction();
    act.action = dto.action;
    act.actionType = dto.actionType;
    act.area = dto.area;
    // act.auditDateTime =  moment(dto.auditDateTime).add(this.currentOffset,'hours').toDate();
    act.auditDateTime = this.convertUtcToFacilityTime(dto.auditDateTime);
    act.effectiveDate = dto.effectiveDate ? moment(dto.effectiveDate).startOf('day').toDate() : null;
    act.employeeId = dto.employeeId;
    act.employeeName = dto.employeeName;
    act.fieldName = dto.fieldName;
    act.orgLevelId = dto.orgLevelId;
    act.recordNumber = dto.recordNumber;
    act.userLoginId = dto.userLoginId;
    act.valueAfter = dto.valueAfter;
    act.valueBefore = dto.valueBefore;
    act.departmentName = dto.departmentName;
    act.empOrganization = dto.empOrganization;

    return act;
  }

  public mapAuditTrailConfigActions(recordsDto: IAuditTrailConfigAction[]): AuditTrailConfigAction[] {
    return _.map(recordsDto, (dto: IAuditTrailConfigAction) => this.mapAuditTrailConfigAction(dto));
  }

  public mapAuditTrailConfigAction(dto: IAuditTrailConfigAction): AuditTrailConfigAction {
    let act: AuditTrailConfigAction = new AuditTrailConfigAction();
    act.action = dto.action;
    act.actionType = dto.actionType;
    act.area = dto.area;
    // act.auditDateTime = moment(dto.auditDateTime).add(this.currentOffset,'hours').toDate();
    act.auditDateTime = this.convertUtcToFacilityTime(dto.auditDateTime);
    act.effectiveDate = moment(dto.effectiveDate).startOf('day').toDate();
    act.entityDate = dto.entityDate;
    act.entityDescription = _.isNil(dto.entityDescription) ? '' : dto.entityDescription.trim();
    act.fieldName = _.isNil(dto.fieldName) ? '' : dto.fieldName.trim();
    act.orgLevelId = dto.orgLevelId;
    act.recordNumber = dto.recordNumber;
    act.userLoginId = dto.userLoginId;
    act.valueAfter = _.isNil(dto.valueAfter) ? '' : dto.valueAfter.trim();
    act.valueBefore = _.isNil(dto.valueBefore) ? '' : dto.valueBefore.trim();

    return act;
  }

  private convertUtcToFacilityTime(date: any) {
    date = ((date.includes("-", date.length-6)) || (date.includes("+", date.length - 6))) ? this.convertToZuluFormat(date) : date;
    const formattedDateTime = moment.utc(date).format('MM/DD/YYYY hh:mm:ss A');
    let newDate = moment(new Date(`${formattedDateTime} UTC`));
    let locaTime: moment.Moment = newDate.utcOffset(this.currentOffset);
    return moment(locaTime);
  }

  private convertToZuluFormat(date: string)  {
    let replaceText = date.slice(date.length - 6, date.length);
    date = date.replace(replaceText, "Z");
    return date;
  }

}
