<div class="modal-body">
  <div class="attachments">
    <div class="flex align-items__center">
      <label class="attachments__label">Browse</label>
      <span class="attachments__btn-box">
        <button class="slx-button slx-with-icon" type="button">
          <i aria-hidden="true" class="fal fa-paperclip slx-button__icon"></i>
          <span class="slx-button__text"></span>
        </button>
        <input class="attachments__input" type="file" (change)="onFileChange($event)" (click)="onResetErrors($event)"
          [accept]="acceptedFileTypesInputFormat" />
      </span>
      <span class="flex__grow attachments__types">Allowed file types: {{ acceptedFileTypesReadable }}. {{ maxFileSize }}
        max</span>
    </div>
    <p class="slx-error-block attachments__error" *ngIf="errors">
      <span *ngIf="errors.maxFileSize">Unable to upload file. Max size for files is 10MB.</span>
      <span *ngIf="errors.fileType">Unable to upload file. Allowed types: Word, Excel, PDF and image files</span>
      <span *ngIf="errors.zeroFileSize">Unable to upload file. Please try again</span>
    </p>

    <div class="flex align-items__center margin-t">
      <p class="attachments__label">Name</p>
      <p class="slx-benefit-box__value flex__grow">
        <input type="text" class="slx-benefit-box__text-field" name="Name" #fileNameField="ngModel" [slxMaxLength]="50" required
          [(ngModel)]="customFileName" />
      </p>
    </div>
    <div>
      <p class="slx-error-block" *ngIf="fileNameField.errors">
        <span *ngIf="fileNameField.errors?.slxMaxLength">Name must not exceed 50 characters</span>
      </p>
    </div>
  </div>
</div>

<div class="modal-footer">
    <button type="button" [disabled]="isFileSelected || fileNameField.errors?.slxMaxLength" (click)="save()"
      class="btn green"><i class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save</button>
    <button type="button" class="btn red" (click)="cancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
</div>
