import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { IDestroyService, DateRange, IDateRange } from '../../../core/models/index';
import { AccessibleApiService,  UserAction } from '../../../organization';
import { Subscription, Subject, Observable } from 'rxjs';
import { Assert } from '../../../framework';
import { TempSensorReading, TempSensorReadingEvent } from '../../models';
import { unsubscribeAll, mutableSelect } from '../../../core/decorators';
import { TempSensorReadingApiService } from './temp-sensor-reading-api.service';
import { OrgLevel } from '../../../state-model/models';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable()
export class TempSensorReadingManagementService implements IDestroyService {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;
  private dateRange: DateRange;
  private loading$ = new Subject<boolean>();
  private recordsLoaded$ = new Subject<TempSensorReading[]>();
  private dateRange$ = new Subject<DateRange>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private userActonsChanged$ = new ReplaySubject<UserAction[]>(1);
  private exportTo$ = new Subject<TempSensorReadingEvent>();

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(private accessibleApiService: AccessibleApiService,
              private apiService: TempSensorReadingApiService
  ) {}

  public init(): void {
    this.subscribeToOrgLevelChanges();
  }

  public destroy(): void {
    this.orgLevel = null;
    this.loading$.complete();
    this.recordsLoaded$.complete();
    this.dateRange$.complete();
    this.exportTo$.complete();
    this.orgLevelChanged$.complete();
  }

  public getDefaultDateRange(): IDateRange {
    const d = new Date();
    return new DateRange(moment().add(-2, 'weeks').toDate(), moment().toDate());
  }

  public changeDateRange(r: IDateRange): void {
    this.dateRange = r;
    this.dateRange$.next(r);
    this.loadTempReadings();
  }

  public subscribeToDateRange(callback: (r: DateRange) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.dateRange$.subscribe(callback);
  }

  public exportTo(event: TempSensorReadingEvent): void {
    this.exportTo$.next(event);
  }

  public subscribeToExport(callback: (event: TempSensorReadingEvent) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportTo$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (b: TempSensorReading[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recordsLoaded$.subscribe(callback);
  }

  public loadTempReadings(): void {
    if (!_.isDate(_.get(this.dateRange, 'startDate'))
     || !_.isDate(_.get(this.dateRange, 'endDate'))
    ) return;
    this.loading$.next(true);
    this.apiService.getTimeSensorReadings(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
      .then((tempReadings: TempSensorReading[]) => {
        this.recordsLoaded$.next(tempReadings);
        this.loading$.next(false);
      })
      .catch (() => {
        this.loading$.next(false);
      });
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        this.loadTempReadings();
        this.loadUserActions(this.orgLevel.id);
      });
  }

  public subscribeToUserActionsChanged(callback: (v: UserAction[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.userActonsChanged$.subscribe(callback);
  }
  

  public loadUserActions(orgLevelId: number): void {
    this.accessibleApiService.getUserActions(orgLevelId, ['Export to Excel'])
      .then((data: UserAction[]) => {
        this.userActonsChanged$.next(data);
      });
  }
}
