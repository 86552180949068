import { BenefitDetailsOption, BenefitDetailsTier, BenefitDetailsLineStandart } from './../benefit-details/index';

export class BenefitEligibleEmployeesRequest {
  public tierId: number;
  public date: Date;
  public canEnroll: boolean;
  public planName: string;
  public calcMethod: string;
  public lineId: number;
  public tierDetails: BenefitDetailsTier;
  public employeeContributionAmount?: number;
  public employerContributionAmount?: number;
  public lineDetails?: BenefitDetailsLineStandart;
  public isUserCanEnroll: boolean;
  public lowLevelOrgId: number;
  public canMapPayroll: boolean = false;
}

export class BenefitEnrolledEmployeesRequest {
  public tierId: number;
  public date: Date;
  public canEnroll: boolean;
  public planName: string;
  public lineId: number;
  public calcMethod: string;
  public startDate: Date;
  public endDate: Date;
  public tierDetails: BenefitDetailsTier;
  public employeeContributionAmount?: number;
  public employerContributionAmount?: number;
  public notes?: string;
  public lineDetails?: BenefitDetailsLineStandart;
  public isUserCanEnroll: boolean;
  public lowLevelOrgId: number;
  public canMapPayroll: boolean = false;
}
