import { forwardRef } from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
export var RADIO_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return RadioInputComponent; }),
    multi: true
};
var RadioInputComponent = /** @class */ (function () {
    function RadioInputComponent() {
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.innerValue = {};
    }
    Object.defineProperty(RadioInputComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChangeCallback(this.innerValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    RadioInputComponent.prototype.writeValue = function (value) {
        if (!_.isUndefined(value) && !_.isNull(value)) {
            this.innerValue = value;
        }
    };
    RadioInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    RadioInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    return RadioInputComponent;
}());
export { RadioInputComponent };
