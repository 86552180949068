import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, EventEmitter } from '@angular/core';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { ApplicationDashboardManagementService, ApplicationService } from '../../../services/index';
import { SessionService } from '../../../../core/services/index';
import { DashboardApplication } from '../../../../organization/models/index';
import { appConfig } from '../../../../app.config';
var ApplicationsConfigComponent = /** @class */ (function () {
    function ApplicationsConfigComponent(appDashboardMngtService, sessionService, applicationService) {
        this.appDashboardMngtService = appDashboardMngtService;
        this.sessionService = sessionService;
        this.applicationService = applicationService;
        this.appsToUpdate = [];
        this.subscriptions = {};
        this.onLoading = new EventEmitter();
        this.onHasChanges = new EventEmitter();
        this.appsModel = this.activeApps = this.inActiveApps = [];
    }
    ApplicationsConfigComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.loading = this.appDashboardMngtService
            .subscribeToLoading(function (isLoading) {
            _this.onLoading.emit(isLoading);
        });
        this.loadApps();
    };
    ApplicationsConfigComponent.prototype.loadApps = function () {
        var _this = this;
        this.subscriptions.applicationUpdate = this.applicationService.applicationsUpdated$
            .subscribe(function (apps) {
            _this.applications = apps;
        });
        this.subscriptions.dashboardUpdates = this.appDashboardMngtService.dashboardApps$.subscribe(function (apps) {
            _this.appsModel = [];
            var user = _this.sessionService.getUser();
            if (apps.applications) {
                _.forEach(_this.applications, function (app) {
                    if (_.includes(appConfig.implementedAppDashboards, app.name)) {
                        var result = _.find(apps.applications, ['appId', app.id]);
                        var userApp = new DashboardApplication();
                        userApp.id = 0;
                        userApp.appName = app.title;
                        userApp.icon = app.icon;
                        userApp.userId = user.id;
                        userApp.appId = app.id;
                        userApp.displayOrder = 999;
                        userApp.visible = true;
                        userApp.itemId = app.id;
                        userApp.itemType = 0;
                        if (app.name === 'HR') {
                            userApp.appName = 'Leave Management';
                            userApp.itemType = 1;
                        }
                        if (!_.isUndefined(result) && !_.isUndefined(result.visible)) {
                            userApp.visible = result.visible;
                            userApp.id = result.id;
                            userApp.displayOrder = result.displayOrder;
                        }
                        if (userApp.itemType == 0 || (userApp.itemType == 1 && app.dashboards.length > 0)) {
                            _this.appsModel.push(userApp);
                        }
                    }
                });
                _this.configureApps();
            }
        });
    };
    ApplicationsConfigComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ApplicationsConfigComponent.prototype.emitChanges = function () {
        this.onHasChanges.emit(true);
    };
    ApplicationsConfigComponent.prototype.configureApps = function () {
        this.activeApps = _.sortBy(_.filter(this.appsModel, function (t) { return t.visible; }), function (a) { return a.displayOrder; });
        this.inActiveApps = _.filter(this.appsModel, function (t) { return !t.visible; });
    };
    ApplicationsConfigComponent.prototype.onDragEnd = function (movedTo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var order;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.appsToUpdate = [];
                        order = 1;
                        _.forEach(this.activeApps, function (item) {
                            item.displayOrder = order;
                            order++;
                            _this.appsToUpdate.push(item);
                        });
                        this.emitChanges();
                        return [4 /*yield*/, this.appDashboardMngtService.modifyApps(this.appsToUpdate)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ApplicationsConfigComponent.prototype.showHideAppItem = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        user = this.sessionService.getUser();
                        this.appsToUpdate = [];
                        item.displayOrder = _.max(_.map(this.activeApps, function (a) { return a.displayOrder; })) + 1;
                        item.visible = !item.visible;
                        item.userId = user.id;
                        this.appsToUpdate.push(item);
                        this.configureApps();
                        this.emitChanges();
                        return [4 /*yield*/, this.appDashboardMngtService.modifyApps(this.appsToUpdate)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], ApplicationsConfigComponent.prototype, "subscriptions", void 0);
    return ApplicationsConfigComponent;
}());
export { ApplicationsConfigComponent };
