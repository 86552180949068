import * as _ from 'lodash';
import { Renderer2, RendererFactory2, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { appConfig } from '../../../app.config';
import { Assert } from '../../../framework/index';
import { SupportDevices } from '../../models/index';
import { WindowRef } from '../../../core/services/window/window-ref.model';
var DeviceDetectorService = /** @class */ (function () {
    function DeviceDetectorService(win, rendererFactory) {
        this.win = win;
        this.detection$ = new Subject();
        this.appConfig = appConfig;
        this.timerId = null;
        this.unsubscribe = function () { };
        this.renderer = rendererFactory.createRenderer(null, null);
        this.runDetection();
        this.subscribeToEvents();
    }
    Object.defineProperty(DeviceDetectorService.prototype, "isMobile", {
        get: function () {
            return this.mobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceDetectorService.prototype, "isTablet", {
        get: function () {
            return this.tablet;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceDetectorService.prototype, "isDesktop", {
        get: function () {
            return this.desktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceDetectorService.prototype, "isLandscape", {
        get: function () {
            return this.landscape;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceDetectorService.prototype, "isPortrait", {
        get: function () {
            return this.portrait;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceDetectorService.prototype, "deviceType", {
        get: function () {
            return this.device;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DeviceDetectorService.prototype, "deviceList", {
        get: function () {
            return [SupportDevices.mobile, SupportDevices.tablet, SupportDevices.desktop];
        },
        enumerable: true,
        configurable: true
    });
    DeviceDetectorService.prototype.ngOnDestroy = function () {
        this.detection$.complete();
    };
    DeviceDetectorService.prototype.runDetection = function () {
        this.detectOrientation();
        this.detectDevice();
        this.assignDeviceType();
        this.detection$.next();
    };
    DeviceDetectorService.prototype.subscribeToDeviceDetection = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.detection$.subscribe(callback);
    };
    DeviceDetectorService.prototype.subscribeToEvents = function () {
        var _this = this;
        this.renderer.listen('window', 'orientationchange', function () { return _this.runDetection(); });
        this.renderer.listen('window', 'resize', function () { return _this.waitForEndResizing(); });
    };
    DeviceDetectorService.prototype.detectOrientation = function () {
        this.orientationType = this.getOrientationType();
        this.landscape = this.orientationType === 'landscape-primary' || this.orientationType === 'landscape-secondary';
        this.portrait = this.orientationType === 'portrait-primary' || this.orientationType === 'portrait-secondary';
        if (!this.portrait && !this.landscape) {
            this.landscape = true;
            console.info("Device orientation didn't recognized. orientation: " + this.orientationType);
        }
    };
    DeviceDetectorService.prototype.getOrientationType = function () {
        var screen = this.win.nativeWindow.screen;
        var screenOrientation = (screen.orientation || screen.msOrientation || screen.mozOrientation);
        return screenOrientation && screenOrientation.type || null;
    };
    DeviceDetectorService.prototype.detectDevice = function () {
        var win = this.win.nativeWindow;
        var _a = this.appConfig.devices, mobileWidth = _a.mobileWidth, tabletWidth = _a.tabletWidth, desktopWidth = _a.desktopWidth;
        var lengthOfSide = win.screen.width;
        this.mobile = lengthOfSide <= mobileWidth.max;
        this.tablet = lengthOfSide >= tabletWidth.min && lengthOfSide <= tabletWidth.max;
        this.desktop = lengthOfSide >= desktopWidth.min;
        if (!this.mobile && !this.tablet && !this.desktop) {
            this.desktop = true;
            console.info("Device Detector: device type doesn't recognized. Screen: " + win.screen.width + " x " + win.screen.height);
        }
    };
    DeviceDetectorService.prototype.assignDeviceType = function () {
        switch (true) {
            case this.mobile:
                this.device = SupportDevices.mobile;
                break;
            case this.tablet:
                this.device = SupportDevices.tablet;
                break;
            case this.desktop:
                this.device = SupportDevices.desktop;
                break;
        }
    };
    DeviceDetectorService.prototype.waitForEndResizing = function () {
        var _this = this;
        if (!_.isNull(this.timerId)) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
        this.timerId = setTimeout(function () { return _this.runDetection(); }, 200);
    };
    return DeviceDetectorService;
}());
export { DeviceDetectorService };
