import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { ResponseBody } from '../../../core/models/api/response-body';
import { FieldsMeta, Actions } from '../../../core/models/index';
import { MetaMapService } from '../../../core/services/index';

import { configurationConfig } from '../../configuration.config';
import { ConstraintsConfigurationMapService } from './constraints-configuration-map.service';

import { IConstraintDTO, ConstraintModel } from '../../models/constraints/constraint.model';

@Injectable()
export class ConstraintsConfigurationApiService {
  constructor(private apiUtilService: ApiUtilService,
              private mapService: ConstraintsConfigurationMapService,
              private metaMapService: MetaMapService,
              private cacheUtilService: CacheUtilService) {
  }

  public getConstraints(orgLevelId: number): Promise<{ actions: Actions, records: ConstraintModel[] }> {
    const url: string = this.getOrgLevelApiRoot(orgLevelId);
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<any[], Meta>(request).then((response: ResponseBody<IConstraintDTO[], Meta>) => {
      let actions: Actions = this.metaMapService.mapActions(response.meta as FieldsMeta);
      return {actions: actions, records: this.mapService.mapConstraints(response.data)};
    });
  }

  public deleteItem(orgLevelId: number, constraint: ConstraintModel): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${constraint.id}`;
    let request: HttpRequest<any> = new HttpRequest('DELETE', url);

    return this.apiUtilService.request<any[], Meta>(request);
  }

  public addItem(orgLevelId: number, item: ConstraintModel): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}`;

    let body: any = item;
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    return this.apiUtilService.request<IConstraintDTO, Meta>(request)
      .then((response: ResponseBody<IConstraintDTO, Meta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapConstraint(response.data);
      });
  }

  public saveItem(orgLevelId: number, item: ConstraintModel): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${item.id}`;

    let body: any = item;
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.cacheUtilService.clear();
        return response;
      });
  }

  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}
/${configurationConfig.api.configuration.root}
/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}
/${configurationConfig.api.configuration.constraints.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }

}
