import * as tslib_1 from "tslib";
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';
var KendoGridMultiselectFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridMultiselectFilterComponent, _super);
    function KendoGridMultiselectFilterComponent(filterService, localization) {
        var _this = _super.call(this, filterService, localization) || this;
        _this.operator = 'slxEqualsArray';
        _this.showOperators = false;
        _this.operators = [];
        return _this;
    }
    KendoGridMultiselectFilterComponent.prototype.getSizes = function () {
        if (!this.multiselect)
            return {};
        var w = this.multiselect.wrapper.nativeElement.clientWidth;
        return { 'width': w + 'px', 'max-width': this.multiselect.width.min };
    };
    Object.defineProperty(KendoGridMultiselectFilterComponent.prototype, "selectedCount", {
        get: function () {
            return this.multiselect ? this.multiselect.value.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    return KendoGridMultiselectFilterComponent;
}(KendoGridBasicFilterComponent));
export { KendoGridMultiselectFilterComponent };
