import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { OrgLevel } from '../../../../../state-model/models/index';
import { PayCycle } from '../../../../../organization/models/index';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { PayrollExportManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-payroll-export-header',
  templateUrl: 'payroll-export-header.component.html',
  styleUrls: ['payroll-export-header.component.scss']
})
export class PayrollExportHeaderComponent implements OnInit, OnDestroy {

  public orgLevel: OrgLevel;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public currentPayCycle: PayCycle;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  constructor(private managementService: PayrollExportManagementService) {

  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = orgLevel;
        this.load();
      }
    });
  }
  public onPayCycleSelected(payCycle: PayCycle): void {
    if (this.currentPayCycle
      && moment(this.currentPayCycle.startDate).isSame(payCycle.startDate)
      && moment(this.currentPayCycle.endDate).isSame(payCycle.endDate)) {
      return;
    }
    this.currentPayCycle = payCycle;
    this.load();
  }

  public load(): void {
    if (!this.orgLevel.id || !this.currentPayCycle) {
      return;
    }
    this.managementService.loadPayrollExport(this.orgLevel, this.currentPayCycle.startDate, this.currentPayCycle.endDate);
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}
