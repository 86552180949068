var NewEnrollmentModel = /** @class */ (function () {
    function NewEnrollmentModel(activeEnrollments, effectiveDate, canMapPayroll) {
        this.m_activeEnrollments = activeEnrollments;
        this.m_effectiveDate = effectiveDate;
        this.m_canMapPayroll = canMapPayroll;
    }
    Object.defineProperty(NewEnrollmentModel.prototype, "activeEnrollments", {
        get: function () {
            return this.m_activeEnrollments;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEnrollmentModel.prototype, "effectiveDate", {
        get: function () {
            return this.m_effectiveDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NewEnrollmentModel.prototype, "canMapPayroll", {
        get: function () {
            return this.m_canMapPayroll;
        },
        set: function (value) {
            this.m_canMapPayroll = value;
        },
        enumerable: true,
        configurable: true
    });
    return NewEnrollmentModel;
}());
export { NewEnrollmentModel };
