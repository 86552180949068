import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { benefitsConfig } from '../../../benefits.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { ReadFile, AttachmentFile } from '../../../../../organization/models/index';
import { BenefitEligibleEmployeesRequest, BenefitEnrolledEmployeesRequest, BenefitEligibilityRuleStatus, } from '../../../models/index';
import { BenefitDetailsApiService, BenefitDetailsManagementService, BenefitDetailsPermissionService, BenefitDetailsStandartManagementService, } from '../../../services/index';
import { BenefitEligibilityRulesManagementService } from '../../../services/benefit-eligibility-rules/index';
import { BenefitDetailsEligibilityRuleDialogComponent } from '../benefit-details-eligibility-rule-dialog/benefit-details-eligibility-rule-dialog.component';
import { ModalService } from './../../../../../common/services/modal/modal.service';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { debounceTime } from 'rxjs/operators';
var BenefitDetailsBenefitInfoLineComponent = /** @class */ (function () {
    function BenefitDetailsBenefitInfoLineComponent(permissionService, commonManService, manService, modalService, orgLevelWatchService, benefitDetailsApiService, rulesManagementService) {
        var _this = this;
        this.permissionService = permissionService;
        this.commonManService = commonManService;
        this.manService = manService;
        this.modalService = modalService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.benefitDetailsApiService = benefitDetailsApiService;
        this.rulesManagementService = rulesManagementService;
        this.benefitPayrollDeductionCode = '';
        this.totalEnrolledEmployees = null;
        this.totalEligibleEmployees = null;
        this.attachments = [];
        this.filesToAttach = [];
        this.isValidProviderTierName = true;
        this.isValidProviderTierName1 = true;
        this.maxFiles = benefitsConfig.files.maxFiles;
        this.maxFileSizeBytes = benefitsConfig.files.maxFileSizeBytes;
        this.acceptedFileTypes = benefitsConfig.files.acceptedTypes;
        this.pattern = /[^\w\s]|[_]/gi;
        this.alphanumericRegex = /^[a-zA-Z0-9 ]*$/;
        this.benefitRulesAlreadyUsedNames = [];
        this.availableExistingBenefitRules = [];
        this.canDelete = false;
        this.deductionValue = false;
        this.duplicateValue = false;
        this.subscriptions = {};
        this.addedFiles = [];
        this.loadedEmpsFor = { orgLevelId: null, tierId: null, date: null };
        this.refreshEmpInfo$ = new Subject();
        this.groupConfig = benefitsConfig.benefitGroupsConfig;
        this.benefitCodeSearchText = new Subject();
        this.eligibleEmpData = new BenefitEligibleEmployeesRequest();
        this.enrolledEmpData = new BenefitEnrolledEmployeesRequest();
        this.benefitCodeSearchText.pipe(debounceTime(500)).subscribe(function (searchValue) {
            _this.onChangeTierpayrollDeductionCode(searchValue);
        });
    }
    Object.defineProperty(BenefitDetailsBenefitInfoLineComponent.prototype, "shownAcaCompliance", {
        get: function () {
            return _.isObjectLike(this.group) && this.group.type === this.groupConfig.medical.type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsBenefitInfoLineComponent.prototype, "fileLength", {
        get: function () {
            return this.addedFiles.length + this.attachments.length;
        },
        enumerable: true,
        configurable: true
    });
    // public ngAfterViewInit(): void {
    //   this.deduction.control.setValidators(Validators.required)
    //     this.subscriptions.enrolledEmployeesChanged = this.deduction.statusChanges
    //     .subscribe((status: string) => {
    //       if (this.isEditMode) {
    //       this.UpdateSaveStatus(this.deduction.valid);
    //       // this.UpdateSaveStatus1(this.deduction.invalid);
    //       }
    //     });
    // }
    BenefitDetailsBenefitInfoLineComponent.prototype.UpdateSaveStatus = function (valid) {
        if (this.isEditMode) {
            this.manService.updatedeductionCode(!valid ? '' : this.benefitPayrollDeductionCode);
        }
    };
    Object.defineProperty(BenefitDetailsBenefitInfoLineComponent.prototype, "shownEmployeesCounters", {
        // private UpdateSaveStatus1(valid:boolean){
        //   if (this.isEditMode) {
        //     this.manService.updateSpecial(!valid );
        //   }
        // }
        get: function () {
            return !this.isEditMode;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsBenefitInfoLineComponent.prototype.ngOnInit = function () {
        // this.UpdateSaveStatus(this.duplicateValue);
        var _this = this;
        this.subscriptions.refreshEmpInfo = this.refreshEmpInfo$
            .debounceTime(50)
            .subscribe(function () { return _this.onRefreshEmployees(); });
        this.subscriptions.config = this.commonManService.subscribeToChangeConfig(function (config) { return (_this.config = config); });
        this.subscriptions.calcMethod = this.commonManService.subscribeToSelectGroup(function (group) { return (_this.group = group); });
        this.subscriptions.orgLevel = this.commonManService.subscribeToOrgLevel(function (o) {
            _this.orgLevelId = o.id;
            _this.refreshEmpInfo$.next();
        });
        this.subscriptions.provider = this.commonManService.subscribeToSelectProvider(function (provider) {
            _this.selectedProvider = provider;
        });
        this.subscriptions.line = this.commonManService.subscribeToSelectProviderLine(function (l) {
            _this.selectedProviderLine = l;
            _this.enrolledEmpData.lineId = l.current.id;
            _this.enrolledEmpData.startDate = l.current.line.startDate;
            _this.enrolledEmpData.endDate = l.current.line.endDate;
            _this.eligibleEmpData.lineDetails = l.current;
            _this.enrolledEmpData.lineDetails = l.current;
            _this.eligibleEmpData.planName = l.current.line.name;
            _this.enrolledEmpData.planName = l.current.line.name;
            _this.enrolledEmpData.lowLevelOrgId = _this.commonManService.getProperOrglevelBasedOnSelectedOrglevel();
        });
        this.subscriptions.calcMethod = this.manService.subscribeToChangeCalcMethod(function (calcMethod) {
            if (_.isObjectLike(calcMethod)) {
                _this.enrolledEmpData.calcMethod = calcMethod.id;
                _this.eligibleEmpData.calcMethod = calcMethod.id;
            }
        });
        this.subscriptions.providerTier = this.manService.subscribeToSelectProviderTier(function (tier) {
            _this.addedFiles = [];
            _this.providerTierId = tier.isDraft ? null : tier.id;
            _this.selectedProviderTier = tier;
            _this.benefitName = tier.name;
            _this.currentTierName = tier.name;
            _this.benefitPlanId = tier.planId || '';
            _this.benefitPayrollDeductionCode = tier.payrollDeductionCode;
            _this.highDeductible = tier.highDeductableTier || false;
            _this.hasBeneficiaries = tier.hasBeneficiaries || false;
            _this.benefitNote = tier.notes || '';
            _this.nonTobacco = tier.nonTobacco || false;
            _this.domesticPartnerEligible = tier.domesticPartnerEligible || false;
            _this.companyProvided = tier.companyProvided || false;
            _this.acaMinCoverage = tier.acaMinCoverage || false;
            _this.acaMinValue = tier.acaMinValue || false;
            _this.acaPovLevelTest = tier.acaPovLevelTest || false;
            _this.acaHarborCode = tier.acaHarborCode || null;
            _this.attachments = tier.attachments || [];
            _this.filesToAttach = tier.filesToAttach || [];
            _this.eligibleEmpData.tierId = tier.id;
            _this.enrolledEmpData.tierId = tier.id;
            _this.eligibleEmpData.tierDetails = tier;
            _this.enrolledEmpData.tierDetails = tier;
            _this.enrolledEmpData.employeeContributionAmount = tier.empContribution;
            _this.enrolledEmpData.employerContributionAmount = tier.erContribution;
            if (tier.isDraft) {
                _this.isValidProviderTierName = _this.manService.updateProviderTierName(_this.selectedProviderTier.id, _this.benefitName);
            }
            else {
                _this.isValidProviderTierName = true;
            }
            if (tier.isDraft) {
                _this.isValidProviderTierName1 = _this.manService.updateProviderTierCode1(_this.selectedProviderTier.id, _this.benefitPayrollDeductionCode);
            }
            else {
                _this.isValidProviderTierName1 = true;
            }
            _this.benefitEligibilityRules = [];
            _this.benefitRulesAlreadyUsedNames = [];
            _this.availableExistingBenefitRules = [];
            if (_this.providerTierId) {
                _this.rulesManagementService.loadBenefitEligibilityRulesByTierId(_this.providerTierId, _this.currentTierName, _this.isEditMode);
                _this.rulesManagementService.loadBenefitEligibilityRulesNotBelongsToTier(_this.providerTierId);
            }
            else {
                _this.benefitEligibilityRules = _this.rulesManagementService.getExistingBenefitEligibilityRulesByTierName(_this.currentTierName);
            }
            _this.onBenefitEligibilityRulesUpdated();
            _this.refreshEmpInfo$.next();
        });
        this.subscriptions.benefitEligibleInfo = this.manService.subscribeToBenefitEligibleEmployeesInfo(function (info) {
            _this.totalEligibleEmployees = info;
        });
        this.subscriptions.benefitenrolledInfo = this.manService.subscribeToBenefitEnrolledEmployeesInfo(function (count) {
            _this.totalEnrolledEmployees = count || 0;
        });
        this.subscriptions.planDate = this.manService.subscribeToChangeProviderPlanDate(function (d) {
            _this.enrolledEmpData.date = d;
            _this.eligibleEmpData.date = d;
            _this.refreshEmpInfo$.next();
        });
        this.subscriptions.editMode = this.permissionService.subscribeToEditMode(function (v) {
            _this.isEditMode = v.providerInfo;
            _this.eligibleEmpData.canEnroll = _this.isEditMode;
            _this.enrolledEmpData.canEnroll = _this.isEditMode;
        });
        this.subscriptions.userActions = this.permissionService.subscribeToUserActions(function (v) {
            _this.canDelete = v.canDelete;
            _this.eligibleEmpData.isUserCanEnroll = v.canEnroll;
            _this.eligibleEmpData.canMapPayroll = v.canMapPayroll;
            _this.enrolledEmpData.canMapPayroll = v.canMapPayroll;
        });
        this.subscriptions.benefitRulesByTierId = this.rulesManagementService.subscribeToLoadBenefitRulesByTierId(function (rulesPair) {
            if (rulesPair.key === _this.currentTierName) {
                _this.benefitEligibilityRules = rulesPair.value;
                _this.onBenefitEligibilityRulesUpdated();
            }
        });
        this.subscriptions.benefitRulesNotBelongsToTier = this.rulesManagementService.subscribeToLoadBenefitRulesNotBelongsToTier(function (rules) {
            _this.benefitEligibilityRulesNotBelongToTier = rules;
            _this.onBenefitEligibilityRulesUpdated();
        });
        this.subscriptions.Loading = this.rulesManagementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
        this.subscriptions.forceRefresh = this.manService.subscribeToChangeupdateEmployees(function (value) {
            _this.onRefreshEmployees(value);
        });
        this.subscriptions.loadingEligibleEmployees = this.commonManService.subscribeToEligibleEmployeesLoading(function (isLoadingEligibleEmployees) {
            _this.isLoadingEligibleEmployees = isLoadingEligibleEmployees;
        });
        this.subscriptions.loadingEnrolledEmployees = this.commonManService.subscribeToEnrolledEmployeesLoading(function (isLoadingEnrolledEmployees) {
            _this.isLoadingEnrolledEmployees = isLoadingEnrolledEmployees;
        });
        this.onChangeTierpayrollDeductionCode(this.benefitPayrollDeductionCode);
    };
    // }
    BenefitDetailsBenefitInfoLineComponent.prototype.checkForSpecialCharacters = function (value) {
        return this.alphanumericRegex.test(value);
    };
    // onInputChange() {
    //   if (this.deduction) {
    //     // this.deduction.control.setErrors(
    //     //   this.checkForSpecialCharacters(this.benefitPayrollDeductionCode) ? null : { specialCharacters: true }
    //     // );
    //     this.hasSpecialCharacters = this.checkForSpecialCharacters(this.benefitPayrollDeductionCode) ? true : false;
    //     this.UpdateSaveStatus(this.hasSpecialCharacters);
    //   }
    // }
    BenefitDetailsBenefitInfoLineComponent.prototype.ngOnDestroy = function () {
        this.rulesManagementService.clearExistingMappedRules();
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onSearch = function () {
        this.benefitCodeSearchText.next(this.benefitPayrollDeductionCode);
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierName = function (ngModel) {
        this.isValidProviderTierName = this.manService.updateProviderTierName(this.selectedProviderTier.id, this.benefitName);
        var errors = ngModel.control.errors || {};
        if (this.isValidProviderTierName) {
            delete errors.uniqueName;
            ngModel.control.setErrors(_.size(errors) > 0 ? errors : null);
            this.rulesManagementService.updateRuleTierName(this.benefitName, this.currentTierName);
            this.currentTierName = this.benefitName;
        }
        else {
            errors.uniqueName = true;
            ngModel.control.setErrors(errors);
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierPlanId = function () {
        if (this.selectedProviderTier.planId !== this.benefitPlanId) {
            this.selectedProviderTier.planId = this.benefitPlanId;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.containsSpecialCharacters = function (value) {
        var regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~'"=\\/-]/;
        return regex.test(value);
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierpayrollDeductionCode = function (searchValue) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.manService;
                        return [4 /*yield*/, this.manService.benefitDetailsApiService.getdeductionCodeInfo(this.providerTierId, searchValue.toLowerCase())];
                    case 1:
                        _a.deductions = _b.sent();
                        this.manService.deductionValue = this.manService.deductions.nextGenEnabled;
                        this.manService.duplicateValue = this.manService.deductions.isDuplicateDeductionCode;
                        this.isValidProviderTierName1 = this.manService.updateProviderTierCode1(this.selectedProviderTier.id, this.benefitPayrollDeductionCode.toLowerCase());
                        if (this.manService.deductionValue == true) {
                            this.isValidProviderTierName1 = this.manService.updateProviderTierCode1(this.selectedProviderTier.id, this.benefitPayrollDeductionCode.toLowerCase());
                            this.UpdateSaveStatus(this.manService.duplicateValue || !this.containsSpecialCharacters(this.benefitPayrollDeductionCode));
                            // this.manService.updateCanSaveState
                            this.manService.updateProviderLine();
                        }
                        else {
                            this.isValidProviderTierName1 = this.manService.updateProviderTierCode1(this.selectedProviderTier.id, this.benefitPayrollDeductionCode.toLowerCase());
                            this.UpdateSaveStatus1(this.manService.duplicateValue || this.containsSpecialCharacters(this.benefitPayrollDeductionCode));
                            this.manService.updateProviderLine();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.UpdateSaveStatus1 = function (valid) {
        if (this.isEditMode) {
            this.manService.updatedeductionCode(valid ? this.benefitPayrollDeductionCode : '');
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierDeductible = function () {
        if (this.selectedProviderTier.highDeductableTier !== this.highDeductible) {
            this.selectedProviderTier.highDeductableTier = this.highDeductible;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierBeneficiaries = function () {
        if (this.selectedProviderTier.hasBeneficiaries !== this.hasBeneficiaries) {
            this.selectedProviderTier.hasBeneficiaries = this.hasBeneficiaries;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierNote = function () {
        if (this.selectedProviderTier.notes !== this.benefitNote) {
            this.selectedProviderTier.notes = this.benefitNote;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierTobacco = function () {
        if (this.selectedProviderTier.nonTobacco !== this.nonTobacco) {
            this.selectedProviderTier.nonTobacco = this.nonTobacco;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierPartner = function () {
        if (this.selectedProviderTier.domesticPartnerEligible !== this.domesticPartnerEligible) {
            this.selectedProviderTier.domesticPartnerEligible = this.domesticPartnerEligible;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierCompanyProvided = function () {
        if (this.selectedProviderTier.companyProvided !== this.companyProvided) {
            this.selectedProviderTier.companyProvided = this.companyProvided;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierAcaCoverage = function () {
        if (this.selectedProviderTier.acaMinCoverage !== this.acaMinCoverage) {
            this.selectedProviderTier.acaMinCoverage = this.acaMinCoverage;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierAcaValue = function () {
        if (this.selectedProviderTier.acaMinValue !== this.acaMinValue) {
            this.selectedProviderTier.acaMinValue = this.acaMinValue;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeTierPoverty = function () {
        if (this.selectedProviderTier.acaPovLevelTest !== this.acaPovLevelTest) {
            this.selectedProviderTier.acaPovLevelTest = this.acaPovLevelTest;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onChangeHarbourCode = function () {
        if (!_.isEqual(this.selectedProviderTier.acaHarborCode, this.acaHarborCode)) {
            this.selectedProviderTier.acaHarborCode = this.acaHarborCode;
            this.manService.updateProviderTier();
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onAddedFiles = function (files) {
        this.addedFiles = _.concat(this.addedFiles, files);
        this.selectedProviderTier.filesToAttach = this.addedFiles;
        this.selectedProviderLine.hasTiersAttachments = _.size(this.selectedProviderTier.filesToAttach) > 0;
        this.manService.updateProviderTier();
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onDeletedFile = function (file) {
        this.addedFiles = _.filter(this.addedFiles, function (f) { return !(f.fileName === file.fileName && f.sizeInBytes === file.sizeInBytes); });
        this.selectedProviderTier.filesToAttach = this.addedFiles;
        this.selectedProviderLine.hasTiersAttachments = _.size(this.selectedProviderTier.filesToAttach) > 0;
        this.manService.updateProviderTier();
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onDeletedAttachment = function (file) {
        this.manService.deleteTierAttachments([file.id]);
        this.manService.updateProviderTier();
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onDownloadAttachment = function (file) {
        this.manService.downloadTierAttachment(file.id);
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onRefreshEmployees = function (force) {
        if ((_.isFinite(this.orgLevelId) &&
            _.isFinite(this.providerTierId) &&
            _.isDate(this.eligibleEmpData.date) &&
            this.shownEmployeesCounters &&
            (this.loadedEmpsFor.orgLevelId !== this.orgLevelId ||
                this.loadedEmpsFor.tierId !== this.providerTierId ||
                !_.isEqual(this.loadedEmpsFor.date, this.eligibleEmpData.date))) ||
            force) {
            this.totalEnrolledEmployees = 0;
            this.totalEligibleEmployees = null;
            this.eligibleEmpData.lowLevelOrgId = this.commonManService.getProperOrglevelBasedOnSelectedOrglevel();
            this.loadedEmpsFor.orgLevelId = this.commonManService.getProperOrglevelBasedOnSelectedOrglevel();
            this.loadedEmpsFor.tierId = this.providerTierId;
            this.loadedEmpsFor.date = this.eligibleEmpData.date;
            if (this.loadedEmpsFor.tierId) {
                this.manService.getBenefitEligibleEmployeesInfo(this.loadedEmpsFor);
                this.manService.getBenefitEnrolledEmployeesInfo(this.loadedEmpsFor);
            }
        }
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.showEligibilityRuleDialog = function () {
        var _this = this;
        BenefitDetailsEligibilityRuleDialogComponent.openDialog(this.modalService, this.benefitRulesAlreadyUsedNames, this.orgLevelId, null, !this.isEditMode, function (result, rule) {
            if (result) {
                _this.benefitEligibilityRulesAdded(rule);
                _this.manService.updateCanSaveState();
            }
        });
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.addExistigBenefitEligibilityRule = function (addedRule) {
        addedRule.status = BenefitEligibilityRuleStatus.Added;
        this.rulesManagementService.addExistigBenefitEligibilityRule(addedRule, this.benefitName);
        this.manService.updateCanSaveState();
        this.selectedProviderTier.setStateUpdated();
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.benefitEligibilityRulesAdded = function (addedRule) {
        this.rulesManagementService.addBenefitEligibilityRule(addedRule, this.benefitName);
        this.manService.updateCanSaveState();
        this.selectedProviderTier.setStateUpdated();
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.benefitEligibilityRulesUpdated = function (updatedRule) {
        this.rulesManagementService.updateBenefitEligibilityRule(updatedRule, this.benefitName);
        this.manService.updateCanSaveState();
        this.selectedProviderTier.setStateUpdated();
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.benefitEligibilityRulesDeleted = function (rule) {
        this.rulesManagementService.deleteBenefitEligibilityRule(rule.id, this.benefitName);
        this.manService.updateCanSaveState();
        this.selectedProviderTier.setStateUpdated();
    };
    BenefitDetailsBenefitInfoLineComponent.prototype.onBenefitEligibilityRulesUpdated = function () {
        this.benefitRulesAlreadyUsedNames = this.rulesManagementService.getUsedRuleNames(this.benefitEligibilityRules, this.benefitEligibilityRulesNotBelongToTier);
        this.availableExistingBenefitRules = _.differenceWith(this.benefitEligibilityRulesNotBelongToTier, this.benefitEligibilityRules, function (existingRule, selectedRule) {
            return existingRule.id === selectedRule.id && selectedRule.status !== BenefitEligibilityRuleStatus.Deleted;
        });
    };
    Object.defineProperty(BenefitDetailsBenefitInfoLineComponent.prototype, "isDuplicate", {
        get: function () {
            return this.manService.duplicateValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsBenefitInfoLineComponent.prototype, "isSpecialCharactersPresent", {
        get: function () {
            return this.containsSpecialCharacters(this.benefitPayrollDeductionCode);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsBenefitInfoLineComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsBenefitInfoLineComponent;
}());
export { BenefitDetailsBenefitInfoLineComponent };
