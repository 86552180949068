<slx-spinner [show]="state.isLoading">
    <div class="slx-high-box">
      <div class="slx-high-box__body main-container">
        <div class="l-col">
          <form #copyActionsForm="ngForm" class="form">

            <div class="from-info">
              <span class="from-field-label">Copy holidays</span>
              <span class="from-field-label">From</span>
            </div>

            <div class="control-group  dropdown-group">
              <slx-input-decorator>
                <input class="form-control" [readonly]="true" slx-input placeholder="Year" name="copyFromYear" [ngModel]="copyFromYear">
              </slx-input-decorator>
            </div>

            <div class="control-group  dropdown-group">
              <slx-input-decorator>
                <input class="form-control" [readonly]="true" slx-input placeholder="{{currentOrgLevel?.type}}" name="copyFromDepartment"
                    [ngModel]="currentOrgLevel?.name">
              </slx-input-decorator>
            </div>

            <div class="from-info">
              <span class="from-field-label">To</span>
            </div>

            <div class="control-group  dropdown-group">
              <slx-input-decorator>
                <slx-dropdown-input class="form-control" slx-input placeholder="Year" name="currentYear" [required]="true" [options]="yearItems"
                    [(ngModel)]="copyToYear" (ngModelChange)="checkCopyConditions()">
                </slx-dropdown-input>
              </slx-input-decorator>
            </div>

            <div class="control-group  dropdown-group">
              <slx-input-decorator>
                <slx-dropdown-input class="form-control" slx-input placeholder="Center" name="copyToCenter" [required]="true" [options]="organizations"
                    [(ngModel)]="copyToCenter" (ngModelChange)="onTargetDepartmentChanged()">
                </slx-dropdown-input>
              </slx-input-decorator>
            </div>

          </form>
        </div>


        <div class="r-col">
          <kendo-grid #kendoGrid class="slx-full-height"
            [data]="gridState?.view"
            [selectable]="false"
            [filterable]="true"
            [filter]="gridState.state.filter"
            (dataStateChange)="gridState.dataStateChange($event)"
            (cancel)="gridState.cancelHandler($event)"
            (edit)="gridState.editHandler($event)"
            (remove)="gridState.removeHandler($event)"
            (save)="gridState.saveHandler($event)"
          >

            <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" width="45">
              <ng-template kendoGridHeaderTemplate>
                <input type="checkbox" name="allSelector" [ngModel]="selectionHelper.isAllSelected" (ngModelChange)="selectionHelper.onToggleAllSelected()">
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <input  *ngIf="dataItem.selectable"
                        name="selector{{rowIndex}}" type="checkbox"
                        [(ngModel)]="dataItem.isSelected"
                        (ngModelChange)="selectionHelper.onItemSelectionChange(dataItem, rowIndex)">
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Holiday" field="name" [filterable]="true" locked="false">
              <ng-template kendoGridHeaderTemplate>
                Holiday
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                <span class="left-align" [ngClass]="{'form-field-disabled': !dataItem.selectable}">{{ dataItem.name }}</span>
              </ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                </slx-kendo-grid-string-filter>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
    </div>

    <div class="actions-bar">
      <div class="col md-no-line-column width-100">
        <span *ngIf="needOrganization" class="edit-info-warning">Holidays can only be copied at the Facility level in the Org Tree.</span>
      </div>
      <button class="action-button copy-btn" [disabled]="!state.allowCopy || needOrganization" (click)="onDoCopy()">Copy</button>
      <button class="action-button" (click)="onDiscardCopy()">Back</button>
    </div>
  </div>

</slx-spinner>
