import { Component, OnInit, Provider } from '@angular/core';
import { DayOfWeek, DialogModeSize, DialogOptions2, IDialog, ModalService } from './../../../../../../app/common';
import { ScheduleRotationTemplate } from './../../../../../../app/scheduler/models/schedule-rotation/schedule-rotation-template.model';
import { EmployeeSectionsScheduleApiService, WeekDayService } from './../../../../../../app/employee/employee-sections/services';
import { EmployeeRotation, EmployeeSectionsRotations } from './../../../../../../app/employee/employee-sections/models';
import { EmployeeShift } from './../../../../../../app/organization';
import * as _ from 'lodash';

@Component({
  selector: 'slx-srt-view-individual-schedule-dialog',
  templateUrl: './srt-view-individual-schedule-dialog.component.html',
  styleUrls: ['./srt-view-individual-schedule-dialog.component.scss']
})
export class SrtViewIndividualScheduleDialogComponent implements IDialog, OnInit {

  public isLoading: boolean = false;
  public dialogResult: boolean;
  public modalService: ModalService;
  public employeeId: number;
  public rotationsSection: EmployeeSectionsRotations;
  public selectedEmployeeShift: EmployeeShift;
  public dialogOption: DialogOptions2;
  public weekDayService: WeekDayService;
  public rotationsExists: boolean;
  public selectedRotation: EmployeeRotation;
  public weekDaysRibbon: DayOfWeek[];
  public rotations: EmployeeRotation[];
  public empDetails: ScheduleRotationTemplate;

  public static openDialog(empData: ScheduleRotationTemplate, rotationsSection: EmployeeSectionsRotations, modalService: ModalService, callback: (result: boolean) => void): SrtViewIndividualScheduleDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.width = '80%';
    dialogOptions.height = '80%';
    dialogOptions.minHeight = 320;
    dialogOptions.className = 'slx-view-individual-schedule-dialog-custom';
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: EmployeeSectionsRotations,
        useValue: rotationsSection
      },
      {
        provide: ScheduleRotationTemplate,
        useValue: empData
      }
    ];
    let dialog: SrtViewIndividualScheduleDialogComponent = modalService.globalAnchor
      .openDialog2(SrtViewIndividualScheduleDialogComponent, 'Schedule Rotation Template - Individual Rotation', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result);
      });
    return dialog;
  }

  constructor(dialogOption: DialogOptions2, modalService: ModalService, empData: ScheduleRotationTemplate, rotationsSection: EmployeeSectionsRotations, public empScheduleApiService: EmployeeSectionsScheduleApiService, weekDayService: WeekDayService) {
    this.isLoading = true;
    this.dialogOption = dialogOption;
    this.modalService = modalService;
    this.weekDayService = weekDayService;
    this.rotationsSection = rotationsSection;
    this.employeeId = empData.employeeId;
    this.empDetails = empData;
    this.selectedEmployeeShift = {
      isValid: false,
      constraint: undefined,
      position: undefined,
      shift: undefined,
      unit: undefined,
      absence: undefined,
      isDirty: false
    };
    this.setRotationView(this.rotationsSection);
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  }

  ngOnInit() {
  }

  discard() {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOption.windowUniqueId);
  }

  setRotationView(rotationsSection: EmployeeSectionsRotations) {
    this.rotationsExists = false;
    if (rotationsSection !== null && rotationsSection !== undefined) {
      this.rotationsSection = rotationsSection;
      this.rotations = rotationsSection.rotations;
      if (this.rotations !== null && this.rotations !== undefined) {
        this.rotationsExists = this.rotations.length > 0;
        this
          .rotations
          .forEach((r: EmployeeRotation) => this.weekDayService.prepareRotation(r));
        this.selectedRotation = this
          .rotationsSection
          .rotations
          .find((r: EmployeeRotation) => r.isCurrent);
        this.weekDaysRibbon = this
          .weekDayService
          .getWeekDaysByRotation(this.selectedRotation);
      }
    }
  }

}
