import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { PopperContent } from 'ngx-popper';
import { AcceptableControlValueAccessor } from '../../../core/models/index';
import { ControlValueAccessor } from '@angular/forms';
import { createValuAccessor } from '../../../common/utils/index';

@Component({
    moduleId: module.id,
    selector: 'slx-date-navigator',
    templateUrl: 'date-navigator.component.html',
    providers: [createValuAccessor(DateNavigatorComponent)]
})
export class DateNavigatorComponent extends AcceptableControlValueAccessor implements ControlValueAccessor {

    @Input()
    public format: string;

    @Input()
    public minDate: Date;

    @Input()
    public maxDate: Date;

    @Input()
    public readOnlyInput: boolean;

    @Input()
    public acceptNullDate: boolean;

    @Input()
    public readonly: boolean;

    @Input()
    public className: string ='';

    @Input()
    public calendarCellClass: string | Function;

    @Input()
    public set selectedDate(value: Date) {
        this.m_currentDate = value;
        this.changeDate(value);
    }

    @Output()
    public dateChanged: EventEmitter<Date>;

    @Input()
    public navigatorButtons: boolean;

    public get selectedDate(): Date {
        return this.m_currentDate;
    }

    public appConfig: IApplicationConfig;

    private m_currentDate: Date;

    constructor(private changeDetector: ChangeDetectorRef) {
        super();
        this.appConfig = appConfig;
        this.format = 'MMMM dd, yyyy';
        this.dateChanged = new EventEmitter<Date>();
    }

    public ngOnInit(): void {
        //
    }

    public writeValue(value?: any): void {
      this.m_currentDate = value;
    }

    public onNavButtonClick(event: MouseEvent, isNext: boolean): void {
        if (this.readonly) return;

        const currentDate: moment.Moment = moment(this.m_currentDate).startOf('day');
        let newCurrentDate: moment.Moment = null;
        if (isNext) {
            newCurrentDate = currentDate.add(1, 'days');
        } else {
            newCurrentDate = currentDate.subtract(1, 'days');
        }

        this.changeDate(newCurrentDate.toDate());
    }

    public selectorDateChange(date: Date): void {
        this.m_currentDate = date;
        this.dateChanged.emit(this.m_currentDate);
        this.onChangeCallback(this.m_currentDate);
    }


    public isMaximum(date: Date): boolean {
        if (this.maxDate) {
            return moment(date).startOf('day').isSameOrAfter(moment(this.maxDate).startOf('day'));
        }

        return false;
    }

    public isMinimum(date: Date): boolean {
        if (this.minDate) {
            return moment(date).startOf('day').isSameOrBefore(moment(this.minDate).startOf('day'));
        }
        return false;
    }


    private changeDate(date: Date): void {
        if (this.m_currentDate !== date) {
            this.m_currentDate = date;
            this.dateChanged.emit(date);
            this.onChangeCallback(this.m_currentDate);
            this.changeDetector.markForCheck();
            if (!this.changeDetector['destroyed']) {
              this.changeDetector.detectChanges();
            }
        }
    }
}
