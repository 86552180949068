import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { TempSensorReadingEventCmd } from '../../../../../configuration/models';
import { TempSensorReadingManagementService } from '../../../../../configuration/services';
import { DeviceDetectorService } from '../../../../..//common';
import { Router, ActivatedRoute } from '@angular/router';
var TempReadingsGridComponent = /** @class */ (function () {
    function TempReadingsGridComponent(managementService, devDetector, changeDetector, router, route) {
        this.managementService = managementService;
        this.devDetector = devDetector;
        this.changeDetector = changeDetector;
        this.router = router;
        this.route = route;
        this.pageSize = 50;
        this.xlsxName = 'Temp_Sensor_Readings_';
        this.pdfName = 'Temp_Sensor_Readings_';
        this.columnsGroupName = 'TempReadings';
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'eventDate', dir: 'desc' },
            { field: 'employee.name', dir: 'asc' }
        ];
        this.appConfig = appConfig;
    }
    Object.defineProperty(TempReadingsGridComponent.prototype, "isLockedColumn", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    TempReadingsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        var date = new Date().toLocaleDateString();
        this.xlsxName += date + ".xlsx";
        this.pdfName += date + ".pdf";
        this.subscriptions.onLoaded = this.managementService
            .subscribeToLoadedRecords(function (r) { return _this.assignRecords(r); });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
        this.subscriptions.export = this.managementService.subscribeToExport(function (event) {
            _this.exportTo(event);
        });
    };
    TempReadingsGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TempReadingsGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    TempReadingsGridComponent.prototype.assignRecords = function (records) {
        this.records = records;
        this.refreshGrid();
    };
    TempReadingsGridComponent.prototype.exportTo = function (event) {
        if (event.cmd === TempSensorReadingEventCmd.excelExport) {
            this.grid.saveAsExcel();
        }
        else if (event.cmd === TempSensorReadingEventCmd.pdfExport) {
            this.grid.saveAsPDF();
        }
    };
    TempReadingsGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
        this.changeDetector.detectChanges();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], TempReadingsGridComponent.prototype, "subscriptions", void 0);
    return TempReadingsGridComponent;
}());
export { TempReadingsGridComponent };
