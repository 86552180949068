import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/index';
import { MealDeducitonsDialogOptions } from '../../../models/meal-deductions/meal-deductions-dialog-options';
import { MealDeductionNewConfigurationComponent } from '../meal-deduction-new-configuration/meal-deduction-new-configuration.component';
import { PbjExportLocationsComponent } from '../pbj-export-locations/pbj-export-locations.component';
import { MealDeductionManagementService } from '../../../services';
import { ConfirmDialog2Component, ConfirmOptions } from '../../../../../../app/common';
var MealDeductionNewConfigurationDialogComponent = /** @class */ (function () {
    function MealDeductionNewConfigurationDialogComponent(mealDeductionOptions, dialogOptions, modalService, managementService) {
        this.mealDeductionOptions = mealDeductionOptions;
        this.dialogOptions = dialogOptions;
        this.modalService = modalService;
        this.managementService = managementService;
        this.orgIds = [];
        this.modified = false;
    }
    Object.defineProperty(MealDeductionNewConfigurationDialogComponent.prototype, "hideDescription", {
        get: function () {
            if (this.mealDeductionOptions) {
                return this.mealDeductionOptions.hideDescription;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    MealDeductionNewConfigurationDialogComponent.openDialog = function (options, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 1000;
        dialogOptions.height = 600;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.headerClassName = 'setBlueBg';
        var providers = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: MealDeducitonsDialogOptions,
                useValue: options
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(MealDeductionNewConfigurationDialogComponent, options.title, dialogOptions, providers, function (result) {
            callback(result);
        });
        return dialog;
    };
    MealDeductionNewConfigurationDialogComponent.prototype.onClose = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    MealDeductionNewConfigurationDialogComponent.prototype.closeAfterSave = function (result) {
        this.modified = false;
        this.dialogResult = result;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    MealDeductionNewConfigurationDialogComponent.prototype.onSave = function () {
        var _this = this;
        var message = "The Meal Deduction Configuration will be applied to all selected organizations \n                        and used for this export and future exports";
        var popupOptions = new ConfirmOptions();
        popupOptions.showCancel = true;
        popupOptions.showOK = true;
        popupOptions.buttonCanceltext = 'Cancel';
        popupOptions.buttonOKtext = 'Save';
        ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
            if (result) {
                if (!_this.mealDeductionOptions.skipSave) {
                    _this.orgIds = [];
                    _this.orgIds = _this.managementService.getSelectedIds();
                    if (_this.orgIds.length > 0) {
                        _this.orgIds.forEach(function (org) {
                            _this.configEditorComponent.saveByOrg(org);
                        });
                    }
                    else {
                        _this.configEditorComponent.save();
                    }
                }
                else {
                    _this.closeAfterSave(true);
                }
            }
        }, popupOptions);
    };
    MealDeductionNewConfigurationDialogComponent.prototype.onCancel = function () {
        this.modified = false;
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    //  get selCheckbox(){
    //     let orgIds= this.managementService.getSelectedIds();
    //    return (orgIds!=undefined && orgIds.length>0);
    //   }
    MealDeductionNewConfigurationDialogComponent.prototype.getIsMealChanged = function (event) {
        this.modified = event;
    };
    return MealDeductionNewConfigurationDialogComponent;
}());
export { MealDeductionNewConfigurationDialogComponent };
