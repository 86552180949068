import { StaffingPredictorOrganizationInfo } from './staffing-predictor-organization-info';
import { StaffingPointsTable } from './staffing-points-table';

export class StaffingPredictorMatrixDialogOptions {
    public title: string;
    public organizationInfo: StaffingPredictorOrganizationInfo;
    public staffingPointsTables: StaffingPointsTable[];
    public height: number = 550;
    public width: number = 700;
    public five_star_v2_enabled: boolean = false;
}
