import * as tslib_1 from "tslib";
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import * as _ from 'lodash';
import { mutableSelect } from '../../../core/decorators/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
import { ManagementBaseService, BreadcrumbStateService } from '../../../core/services/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { LogiApiService } from '../services/logi-api.service';
import { isLogiRouteInfo } from '../../../core/models/index';
import { LogiMessagingServices } from '../services/logi-messaging.service';
var LogiManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(LogiManagementService, _super);
    function LogiManagementService(sanitizer, logiApiService, applicationStateBusService, userActivityService, logiMessagingService, breadcrumbService) {
        var _this = _super.call(this) || this;
        _this.sanitizer = sanitizer;
        _this.logiApiService = logiApiService;
        _this.applicationStateBusService = applicationStateBusService;
        _this.userActivityService = userActivityService;
        _this.logiMessagingService = logiMessagingService;
        _this.breadcrumbService = breadcrumbService;
        _this.isLoading = false;
        _this.currentUrl = '';
        _this.initialized = false;
        return _this;
    }
    LogiManagementService.prototype.initialize = function (currentUrl) {
        var _this = this;
        this.currentUrl = currentUrl;
        this.subscriptions = {};
        this.logiMessagingService.initialize();
        this.logiUrlChanged$ = new ReplaySubject();
        this.subscriptions.orgLevel = this.orgLevel$
            .subscribe(function (orgLevel) {
            _this.processOrglevelChange(orgLevel);
        });
        this.subscriptions.routeInfo = this.applicationStateBusService.subscribeToRouteInfo(function (data) {
            _this.processMenuChange(data);
        });
        this.subscriptions.clickInMenu = this.applicationStateBusService.subscribeToClickMenu(function (menu) {
            if (_this.initialized && _.includes(_this.currentUrl, menu.link)) {
                _this.reloadLogi();
            }
        });
        this.subscriptions.clickInBreadcrumb = this.breadcrumbService.subscribeToClickOnBreadcrumb(function (i) {
            if (_this.initialized && _.includes(_this.currentUrl, i.linkPath)) {
                _this.reloadLogi();
            }
        });
        this.subscriptions.urlChanged = this.logiUrlChanged$.subscribe(function () {
            _this.initialized = true;
        });
    };
    LogiManagementService.prototype.destroy = function () {
        this.logiMessagingService.destroy();
        _super.prototype.destroy.call(this);
    };
    LogiManagementService.prototype.subscribeToLogiLoaded = function (callback) {
        if (!_.isFunction(callback))
            throw new TypeError('Can\'t subscribe, argument is not a function');
        return this.logiMessagingService.subscribeToLoaded(callback);
    };
    LogiManagementService.prototype.subscribeToLogiUserAction = function (callback) {
        if (!_.isFunction(callback))
            throw new TypeError('Can\'t subscribe, argument is not a function');
        return this.logiMessagingService.subscribeToUserAction(callback);
    };
    LogiManagementService.prototype.resetCounterUserInactivity = function () {
        this.userActivityService.reset();
    };
    LogiManagementService.prototype.biuldLogiParams = function (secureKey, reportName) {
        return "session=" + secureKey + "&report=" + reportName;
    };
    LogiManagementService.prototype.processMenuChange = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isObject(data)
                    && isLogiRouteInfo(data)
                    && (_.isNull(this.selecetedRouteInfo) || _.isUndefined(this.selecetedRouteInfo) || this.selecetedRouteInfo.componentId !== data.componentId)) {
                    this.selecetedRouteInfo = data;
                    this.reloadLogi();
                }
                return [2 /*return*/];
            });
        });
    };
    LogiManagementService.prototype.processOrglevelChange = function (orgLevel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isNull(this.selectedOrgLevel) ||
                    _.isUndefined(this.selectedOrgLevel) ||
                    orgLevel.id !== this.selectedOrgLevel.id) {
                    this.selectedOrgLevel = orgLevel;
                    this.reloadLogi();
                }
                return [2 /*return*/];
            });
        });
    };
    LogiManagementService.prototype.reloadLogi = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var logiParameters, logiUrl;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!_.isNull(this.selectedOrgLevel)
                            && !_.isUndefined(this.selectedOrgLevel)
                            && !_.isNull(this.selecetedRouteInfo)
                            && !_.isUndefined(this.selecetedRouteInfo)
                            && !this.isLoading)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.requestSecureKey()];
                    case 1:
                        _a.sent();
                        logiParameters = this.biuldLogiParams(this.secureKey, this.selecetedRouteInfo.logiComponentId);
                        logiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.logiUrl + "index.html?" + logiParameters);
                        this.logiUrlChanged$.next(logiUrl);
                        console.info("Logi URL: " + logiUrl);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    LogiManagementService.prototype.requestSecureKey = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var responseData, exc_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.onLoadStatusChanged(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.logiApiService.requestSecureKey(this.selectedOrgLevel.id, this.selecetedRouteInfo.componentId)];
                    case 2:
                        responseData = _a.sent();
                        this.secureKey = responseData.key;
                        this.logiUrl = responseData.url;
                        this.logiMessagingService.setLogiUrl(responseData.url);
                        this.onLoadStatusChanged(false);
                        this.isLoading = false;
                        return [3 /*break*/, 4];
                    case 3:
                        exc_1 = _a.sent();
                        this.onLoadStatusChanged(false);
                        this.isLoading = false;
                        throw exc_1;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], LogiManagementService.prototype, "orgLevel$", void 0);
    return LogiManagementService;
}(ManagementBaseService));
export { LogiManagementService };
