import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import * as _ from 'lodash';

export const requireNumberMultiValidationKey = 'requireNumberMulti';

export function requireNumberMultiValidator(): ValidatorFn {
  const validationPattern = new RegExp(/^\d+(([\w\s]?),([\w\s]?)\d+)*$/);

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value ? _.trim(control.value) : control.value;

    const isValid = validationPattern.test(value);
    return !isValid ? { [requireNumberMultiValidationKey]: true } : null;
  };

  return validator;
}
