<slx-spinner [show]="isLoading">
    <div class="slx-high-box">

      <div class="slx-high-box__body">
        <kendo-grid #kendoGrid
        class="slx-blue-grid slx-full-height ip-whitelisting"
        [data]="gridState?.view"
        [filterable]="'menu'"
        [sortable]="{ mode: 'multiple' }"
        [filter]="gridState.state.filter"
        [skip]="gridState.state.skip"
        [sort]="gridState.state.sort"
        (cancel)="gridState.cancelHandler($event)"
        (edit)="gridState.editHandler($event)"
        (remove)="gridState.removeHandler($event)"
        (save)="gridState.saveHandler($event)"
        (dataStateChange)="gridState.dataStateChange($event)"
      >
     
        <kendo-grid-column title="Edit" [sortable]="false" [filterable]="false" width="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex">
              <button kendoGridEditCommand type="button"class="slx-button slx-only-icon slx-toolbar" [disabled]="!isCanEdit">
                <i class="fas fa-edit" aria-hidden="true"></i>
              </button>
              <button kendoGridRemoveCommand type="button" class="slx-button slx-only-icon slx-toolbar" [disabled]="!isCanEdit">
                <i class="fas fa-trash" aria-hidden="true"></i>
              </button>
              <button kendoGridSaveCommand type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r" [disabled]="!isEnableSave">
                <i class="fas fa-check" aria-hidden="true"></i>
              </button>
              <button kendoGridCancelCommand type="button" class="slx-button slx-only-icon slx-toolbar" [disabled]="!isCanEdit">
                <i class="fas fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="IP Address" width="250" field="ipAddress">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>IP Address</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.ipAddress }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
              <slx-input-decorator>
                  <input slx-input [(ngModel)]="dataItem.ipAddress"  (ngModelChange)="onChangeWhiteListIPAddress($event,dataItem)"  name="description{{rowIndex}}" class="k-textbox" placeholder="IP Address" />
                  <span errorMessage *ngIf="invalidIP">Please enter valid IP address</span>
                  <span errorMessage *ngIf="isDuplicate.show">This IP Address exists in the {{isDuplicate.inTab}}</span>
                </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Description" width="350" field="description"  [sortable]="false" [filterable]="false">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Description</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.description}}
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-input-decorator>
                <input slx-input [(ngModel)]="dataItem.description" name="description{{rowIndex}}" (ngModelChange)="onChangeWhiteListDescription(dataItem)" class="k-textbox" placeholder="Description" />
              </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Enable" width="100" field="isEnabled"  [sortable]="false" [filterable]="false">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Enable</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" >
            <slx-checkbox-toggle 
              class="tab-switch" 
              (ngModelChange)="onChangeIPStatus($event,dataItem)"
              className="toggle-right slx-green-checkbox-toggle"
              id="enable{{rowIndex}}" 
              [isDisabled]="disabled && !isNew" 
              [ngModel]="dataItem.isEnabled" 
              checkedText="Yes"
              uncheckedText="No"></slx-checkbox-toggle>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-checkbox-toggle 
            class="tab-switch" 
            (ngModelChange)="onChangeIPStatus($event,dataItem)"
            className="toggle-right slx-green-checkbox-toggle"
            id="enable{{rowIndex}}" 
            [isDisabled]="disabled && isNew" 
            [ngModel]="dataItem.isEnabled" 
            checkedText="Yes"
            uncheckedText="No"></slx-checkbox-toggle>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="SmartLinx Go web-punch" width="110" field="useInGo"  [sortable]="false" [filterable]="false">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>SmartLinx Go web-punch</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="ip-management-checkbox">
              <slx-input-decorator
              className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-checkbox-small">
            <slx-checkbox-input
            slx-input [readonly]="disabled && !isNew"
            name="useInGo"
            fieldName="useInGo"
            (ngModelChange)="onChangeWhiteListCheckBox(dataItem)"
            [(ngModel)]="dataItem.useInGo"
          > </slx-checkbox-input>
              </slx-input-decorator>
              </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-input-decorator
              className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-checkbox-small">
            <slx-checkbox-input
            slx-input [readonly]="disabled && isNew"
            name="useInGo"
            fieldName="useInGo"
            (ngModelChange)="onChangeWhiteListCheckBox(dataItem)"
            [(ngModel)]="dataItem.useInGo"
          >
          </slx-checkbox-input>
          </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="SmartLinx 6" width="100" field="useInV6"  [sortable]="false" [filterable]="false">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>SmartLinx 6</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <div class="ip-management-checkbox">
            <slx-input-decorator
            className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-checkbox-small">
            <slx-checkbox-input
            className="ip-management-checkbox"
            slx-input [readonly]="disabled && !isNew"
            name="useInV6"
            fieldName="useInV6"
            (ngModelChange)="onChangeWhiteListCheckBox(dataItem)"
            [(ngModel)]="dataItem.useInV6"
          > </slx-checkbox-input>
          </slx-input-decorator>
          </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <slx-input-decorator
            class="ip-management-checkbox"
            className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-checkbox-small"
            >
            <slx-checkbox-input
            slx-input [readonly]="disabled && isNew"
            name="useInV6"
            fieldName="useInV6"
            (ngModelChange)="onChangeWhiteListCheckBox(dataItem)"
            [(ngModel)]="dataItem.useInV6"
          >
          </slx-checkbox-input>
        </slx-input-decorator>
          </ng-template>
        </kendo-grid-column> 

        <kendo-grid-excel fileName="IP Allowlist.xlsx" [fetchData]="retriveAllPages()"></kendo-grid-excel>
        <kendo-grid-pdf fileName="IP Allowlist.pdf" [allPages]="true">
          <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
        </kendo-grid-pdf>
      </kendo-grid>

      </div>
    </div>
  </slx-spinner>