import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsPerformance = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPerformance, _super);
    function EmployeeSectionsPerformance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsPerformance;
}(EmployeeSectionsBase));
export { EmployeeSectionsPerformance };
