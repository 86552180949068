import { dateTimeUtils } from '../../../common/utils/index';
import { Injectable } from '@angular/core';
import { appConfig } from '../../../app.config';
import { ResponseBody, Meta } from '../../../core/models/index';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import * as _ from 'lodash';
import * as moment from 'moment';

import {
  TimeCardModel, ITimeCardModel, DailyPayRule, IDailyPayRule, DailyAbsence, IDailyAbsence, DailyAllAbsence, IDailyAllAbsence, EarningStatistic, IEarningStatistic, DailyPayRuleStatus, LinePunch, ILinePunch
} from '../../models/index';

import { PunchesMapService } from '../../services/punches/punches-map.service';
import { LookupMapService, EmployeeDefinitionsMapService, ScheduleDefinitionsMapService } from '../../../organization/services/index';
import { PayRule, TaAbsenceCode, IScheduledShiftDefinition, ScheduledShiftDefinition } from '../../../organization/models/index';
import { TimecardsCheckRightsService } from '../timecards/timecards-check-rights.service';

@Injectable()
export class DailyTimecardMapService {

  constructor(private lookupMapService: LookupMapService,
    private employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    private scheduleDefinitionsMapService: ScheduleDefinitionsMapService,
    private punchesMapService: PunchesMapService,
    private timecardsCheckService: TimecardsCheckRightsService
  ) { }
  
  public mapTimcardForOverLap(dto: ILinePunch[]): TimeCardModel {
    let model: TimeCardModel = new TimeCardModel();
    model.dailyPunches = this.punchesMapService.mapLinePanches(dto);
    return model;
  }

  public mapTimcard(dto: ITimeCardModel): TimeCardModel {

    let model: TimeCardModel = new TimeCardModel();
    model.id = dto.id;
    model.date = dateTimeUtils.convertFromDtoString(dto.date);
    model.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    model.exemptStatus = this.lookupMapService.mapExemptStatus(dto.exemptStatus);
    model.payPolicy = this.lookupMapService.mapPayPolicy(dto.payPolicy);
    model.shiftDifferentialPolicy = this.lookupMapService.mapShiftDiffPolicy(dto.shiftDifferentialPolicy);
    model.primaryPosition = this.lookupMapService.mapPosition(dto.primaryPosition);
    model.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
    model.lastUpdateUsername = dto.lastUpdateUsername;
    model.schedule = _.map(dto.schedule, (s: IScheduledShiftDefinition) => this.scheduleDefinitionsMapService.mapToScheduledShiftDefinition(s, model.date));
    model.payRules = _.map(dto.payRules, (p: IDailyPayRule) => this.mapPayRule(p));
    model.absences = _.map(dto.absences, (p: IDailyAbsence) => this.mapAbsence(p));
    model.allabsences = _.map(dto.allabsences, (p: IDailyAllAbsence) => this.mapAllAbsence(p));
    model.dailyPunches = this.punchesMapService.mapLinePanches(dto.dailyPunches);
    model.punchDisplayTags = _.map(dto.punchDisplayTags, (alert: string) => this.mapTimcardPunchesAlerts(alert));
    model.hasMissingPunchesAlert = _.includes(dto.punchDisplayTags, 'Punch Missing');
    model.comment = dto.comment;
    model.earningStatistics = _.map(dto.earningStatistics, (p: IEarningStatistic) => this.mapEarningStatistic(p));
    model.maxPunchDate = dateTimeUtils.convertFromDtoDateTimeString(dto.maxPunchDate);
    model.minPunchDate = dateTimeUtils.convertFromDtoDateTimeString(dto.minPunchDate);
    model.isApproved = dto.isApproved;
    model.canDebug = dto.canDebug;
    model.canApprove = dto.canApprove;
    model.isLoadedApproved = dto.isApproved;
    model.canUnapprove = dto.canUnapprove;
    model.canEdit = dto.canEdit;
    model.isOutOfPaycycle = dto.isOutOfPaycycle;
    model.isPayrollCycleSubmitted = dto.isPayrollCycleSubmitted;
    model.unApprovedPayCodesCount = dto.unApprovedPayCodesCount;
    model.unApprovedOTCount = dto.unApprovedOTCount;
    model.rawData = dto;
    model.canModifyOwnTimecard = this.timecardsCheckService.userCanApproveOwnTimecard(model.employee.id);
    model.canEditOwnTimecard = this.timecardsCheckService.userCanEditOwnTimecard(model.employee.id);
    model.isLocked = dto.isLocked;
    model.isPayrollCycleLocked = dto.isPayrollCycleLocked;
    model.isOrganizationPayrollLocked = dto.isOrganizationPayrollLocked;
    model.canUnlock = dto.canUnlock;
    model.canLock = dto.canLock;
    model.shiftDiffBasedOnTime = dto.shiftDiffBasedOnTime;
    model.hasLicenseRestriction = dto.checkLicenseRestriction!==undefined && dto.checkLicenseRestriction === 0;
    model.canEditSchedule = dto.canEditSchedule;
    model.isPaidRestBreakEnabled = dto.isPaidRestBreakEnabled;
    return model;
  }

  public mapTimcardDto(data: TimeCardModel): ITimeCardModel {

    let dto: ITimeCardModel = {
      id: data.id,
      date: dateTimeUtils.convertToDtoString(data.date),
      employee: this.employeeDefinitionsMapService.mapToEmployeeDefinitionDto(data.employee),
      exemptStatus: this.lookupMapService.mapExemptStatusDto(data.exemptStatus),
      payPolicy: this.lookupMapService.mapPayPolicyDto(data.payPolicy),
      shiftDifferentialPolicy: this.lookupMapService.mapShiftDiffPolicyDto(data.shiftDifferentialPolicy),
      primaryPosition: this.lookupMapService.mapPositionDto(data.primaryPosition),
      lastUpdateDate: dateTimeUtils.convertToDtoString(data.lastUpdateDate),
      lastUpdateUsername: data.lastUpdateUsername,
      schedule: _.map(data.schedule, (s: ScheduledShiftDefinition) => this.scheduleDefinitionsMapService.mapToScheduledShiftDefinitionDto(s)),
      payRules: _.map(data.payRules, (s: DailyPayRule) => this.mapPayRuleDto(s)),
      absences: _.map(data.absences, (s: DailyAbsence) => this.mapAbsenceDto(s)),
      allabsences: _.map(data.allabsences, (s: DailyAllAbsence) => this.mapAllAbsenceDto(s)),
      dailyPunches: this.punchesMapService.mapLinePanchesDto(data.dailyPunches),
      punchDisplayTags: [],
      comment: data.comment,
      earningStatistics: _.map(data.earningStatistics, (s: EarningStatistic) => this.mapEarningStatisticDto(s)),
      maxPunchDate: dateTimeUtils.convertToDtoDateTimeString(data.maxPunchDate),
      minPunchDate: dateTimeUtils.convertToDtoDateTimeString(data.minPunchDate),
      isApproved: data.isApproved,
      canDebug: data.canDebug,
      canApprove: data.canApprove,
      canUnapprove: data.canUnapprove,
      canEdit: data.canEdit,
      isOutOfPaycycle: data.isOutOfPaycycle,
      isPayrollCycleSubmitted: data.isPayrollCycleSubmitted,
      unApprovedPayCodesCount: data.unApprovedPayCodesCount,
      unApprovedOTCount: data.unApprovedOTCount,
      isLocked: data.isLocked,
      isPayrollCycleLocked: data.isPayrollCycleLocked,
      isOrganizationPayrollLocked: data.isOrganizationPayrollLocked,
      canUnlock: data.canUnlock,
      canLock: data.canLock,
      shiftDiffBasedOnTime: data.shiftDiffBasedOnTime,
      checkLicenseRestriction: undefined,
      canEditSchedule: data.canEditSchedule,
      isPaidRestBreakEnabled:data.isPaidRestBreakEnabled
    };
    return dto;
  }

  public mapTimcardPunchesAlerts(str: string): string {
    switch (str) {
      case 'Punch Missing':
        return 'Punch Missing';
      case 'Invalid Punches':
        return 'Invalid Punches';
      case 'Scheduled Only':
        return 'Scheduled Only';
      case 'No Punches':
        return 'No Punches';
    }
    return str;
  }

  public mapPayRule(dto: IDailyPayRule): DailyPayRule {
    let payRule: DailyPayRule = new DailyPayRule();
    payRule.ruleDefinition = this.lookupMapService.mapPayRule(dto.ruleDefinition);
    if (!payRule.ruleDefinition.description) {
      payRule.ruleDefinition.description = payRule.ruleDefinition.name;
    }

    payRule.position = dto.position ? this.lookupMapService.mapPosition(dto.position) : null;

    payRule.serverStatus = dto.status;
    payRule.status = this.mapDailyPayRuleStatus(dto.status);
    payRule.start = dateTimeUtils.convertFromDtoDateTimeString(dto.start);
    payRule.end = dateTimeUtils.convertFromDtoDateTimeString(dto.end);
    payRule.interval = dateTimeUtils.convertFromDtoDurationString(dto.interval).asMilliseconds();
    payRule.serverInterval = payRule.interval;
    payRule.moneyAmount = dto.moneyAmount;
    payRule.serverMoneyAmount = payRule.moneyAmount;
    payRule.type = payRule.ruleDefinition.ruleType;
    payRule.intervalHM = dateTimeUtils.getIntervalTimeFromInterval(payRule.start, payRule.interval, 'ms');
    payRule.additionalVariable=dto.additionalVariable;
    return payRule;
  }

  public mapPayRuleDto(data: DailyPayRule): IDailyPayRule {
    let status = data.serverStatus ? data.serverStatus : data.status;
    if (data.isAdjusted) {
      status = 'Adjusted';
    }
    if (data.status === DailyPayRuleStatus.deleted) {
      status = DailyPayRuleStatus.deleted;
    }
    let payRule: IDailyPayRule = {
      position: this.lookupMapService.mapPositionDto(data.position),
      ruleDefinition: this.lookupMapService.mapPayRuleDto(data.ruleDefinition),
      status: status,
      start: dateTimeUtils.convertToDtoDateTimeString(data.start),
      end: dateTimeUtils.convertToDtoDateTimeString(data.end),
      interval: null,
      moneyAmount: data.moneyAmount,
      additionalVariable: _.isEqual(data.ruleDefinition.additionalVariable,timeAndAttendanceConfig.retroFieldValue) ? ( data.additionalVariable ? moment(data.additionalVariable).format(appConfig.dateFormat) : '' ):''
    };

    if (data.interval) {
      let d: moment.Duration = moment.duration(data.interval);
      payRule.interval = dateTimeUtils.convertToDtoDurationString(d);
    } else {
      payRule.interval = null;
    }


    return payRule;
  }

  public mapDailyPayRuleStatus(status: string): DailyPayRuleStatus {
    switch (status) {
      case 'Adjusted':
        return DailyPayRuleStatus.adjusted;
      case 'Deleted':
        return DailyPayRuleStatus.deleted;
      default:
        return DailyPayRuleStatus.generated;

    }
  }

  public mapAbsence(dto: IDailyAbsence): DailyAbsence {
    let absence: DailyAbsence = new DailyAbsence();
    absence.id = dto.id;
    absence.name = dto.name;
    absence.start = dateTimeUtils.convertFromDtoDateTimeString(dto.start);
    absence.end = dateTimeUtils.convertFromDtoDateTimeString(dto.end);
    if (absence.shift) {
      absence.shift = this.lookupMapService.mapShift(dto.shift);
      absence.calculateInterval();
      absence.copyIntervalToRaw();
    } else {
      absence.interval = dateTimeUtils.getIntervalFromTimespanDto(dto.interval);
      absence.unpaidInterval = 0;
      absence.copyIntervalToRaw();
    }
    absence.absence = new TaAbsenceCode();
    absence.absence.id = dto.id;
    absence.absence.description = dto.name;
    absence.updateSchedule = dto.updateSchedule;
    absence.isDeleted = dto.isDeleted;
    absence.isServerSide = true;
    return absence;
  }
  public mapAllAbsence(dto: IDailyAllAbsence): DailyAllAbsence {
    let allabsence: DailyAllAbsence = new DailyAllAbsence();
    allabsence.id = dto.id;
    allabsence.name = dto.name;
    allabsence.start = dateTimeUtils.convertFromDtoDateTimeString(dto.start);
    allabsence.end = dateTimeUtils.convertFromDtoDateTimeString(dto.end);
    if (allabsence.shift) {
      allabsence.shift = this.lookupMapService.mapShift(dto.shift);
      allabsence.calculateInterval();
      allabsence.copyIntervalToRaw();
    } else {
      allabsence.interval = dateTimeUtils.getIntervalFromTimespanDto(dto.interval);
      allabsence.unpaidInterval = 0;
      allabsence.copyIntervalToRaw();
    }
    allabsence.absence = new TaAbsenceCode();
    allabsence.absence.id = dto.id;
    allabsence.absence.description = dto.name;
    allabsence.updateSchedule = dto.updateSchedule;
    allabsence.isDeleted = dto.isDeleted;
    allabsence.isServerSide = true;
    return allabsence;
  }
  public mapAbsenceDto(data: DailyAbsence): IDailyAbsence {
    let dto: IDailyAbsence = {
      id: data.id,
      name: data.name,
      start: dateTimeUtils.convertToDtoDateTimeString(data.start),
      end: dateTimeUtils.convertToDtoDateTimeString(data.end),
      interval: null,
      shift: data.shift ? this.lookupMapService.mapShift(data.shift) : null,
      updateSchedule: data.updateSchedule,
      isDeleted: data.isDeleted
    };
    if (data.interval) {
      let interval: number;
      if (data.interval > 0) {
        interval = data.interval;
      } else {
        interval = data.rawInterval;
      }
      dto.interval = dateTimeUtils.getTimespanDto(interval);
    }
    return dto;
  }
  public mapAllAbsenceDto(data: DailyAllAbsence): IDailyAllAbsence {
    let dto: IDailyAllAbsence = {
      id: data.id,
      name: data.name,
      start: dateTimeUtils.convertToDtoDateTimeString(data.start),
      end: dateTimeUtils.convertToDtoDateTimeString(data.end),
      interval: null,
      shift: data.shift ? this.lookupMapService.mapShift(data.shift) : null,
      updateSchedule: data.updateSchedule,
      isDeleted: data.isDeleted
    };
    if (data.interval) {
      let interval: number;
      if (data.interval > 0) {
        interval = data.interval;
      } else {
        interval = data.rawInterval;
      }
      dto.interval = dateTimeUtils.getTimespanDto(interval);
    }
    return dto;
  }
  public mapEarningStatistic(dto: IEarningStatistic): EarningStatistic {
    let record: EarningStatistic = new EarningStatistic();
    record.id = dto.codeId;
    record.name = dto.codeName;
    record.position = dto.positionName;
    record.interval = dateTimeUtils.convertFromDtoDurationString(dto.interval).asMilliseconds();
    record.amount = dto.amount;
    record.exportCode = dto.exportCode;
    record.shiftCodeName = dto.shiftCodeName;
    return record;
  }

  public mapEarningStatisticDto(data: EarningStatistic): IEarningStatistic {
    let dto: IEarningStatistic = {
      codeId: data.id,
      codeName: data.name,
      positionName: data.position,
      interval: dateTimeUtils.convertFromNumberToDtoDurationString(data.interval, 'ms'),
      amount: data.amount,
      exportCode: data.exportCode,
      shiftCodeName: data.shiftCodeName,
    };
    return dto;
  }

}
