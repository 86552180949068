import { Pipe, PipeTransform } from '@angular/core';
import { LogicalOperator } from '../models/logical-expression.model';

@Pipe({name: 'logicalOperator'})
export class LogicalOperatorPipe implements PipeTransform {

 public transform (value: LogicalOperator): string {
      let str: string = '';
    switch (value) {
        case LogicalOperator.And:
          str = 'And';
        break;
        case LogicalOperator.Empty:
          str = 'Empty';
        break;
        case LogicalOperator.Not:
          str = 'Not';
        break;
        case LogicalOperator.Or:
          str = 'Or';
        break;
    }

    return str;
  }

}
