<slx-spinner [show]="state.isLoading">
  <div id="contentToConvert" #contentToConvert class="details-screen scheduler daily-unit-component content">
    <ng-container *ngIf="!state.switchShiftMode">

      <div class="slx-content-toolbar-indents slx-auto-height">
        <div class="col-xs-12 info-box padd-l-0 padd-r-0">
          <div class="col-xs-6 col-md-6 col-lg-6 padd-l-0">
            <button type="button" class="theme-button-apply" (click)="onCensusEdit()">Census: {{censusCount}}</button>
          </div>
          <div class="col-xs-6 col-md-6 col-lg-6 total-hours padd-r-0">
            Total hours: {{details?.totalHours}}
          </div>
        </div>
        <div class="col-xs-12 padd-l-0 padd-r-0">
          <div class="col-xs-9 col-md-6 col-lg-6 padd-l-0">
            <slx-input-decorator #datePickerContainer>
              <slx-date-picker-ngx slx-input slxDatePager id="_selectedDateVal"
                [targetContainer]="datePickerContainer"
                [(ngModel)]="dateOn"
                (ngModelChange)="changeDateOn($event)"
                [acceptNullDate]="false"
                placeholder="Date"
                name="dateOn"
              ></slx-date-picker-ngx>
              <span errorMessage for="date"></span>
            </slx-input-decorator>
          </div>

          <div class="col-xs-3 col-md-6 col-lg-6 filters padd-r-0">
            <div class="filter">
              <slx-actions-list>
                <slx-actions-button className="low-height auto-width" [popperContent]="Actionsettings" [popperPosition]="'bottom-end'">
                  <i class="fas fa-cog" aria-hidden="true"></i> <span style="margin-left:10px;">Actions</span>
                </slx-actions-button>
                <popper-content #Actionsettings>
                  <slx-actions-list-item (click)="exportToExcel()">Export to Excel</slx-actions-list-item>
                  <slx-actions-list-item (click)="captureScreen()">Export to PDF</slx-actions-list-item>
                </popper-content>
              </slx-actions-list>   
            </div>

            <div class="filter">
              <button type="button" [popover]="viewPopover" class="theme-button-apply filter-btn">
                <i class="fas fa-cog" aria-hidden="true"></i>
              </button>
              <popover-content #viewPopover title="View settings" class="popover-content-arrow" disableClickOutside="false" placement="left">
                <slx-detail-group-view-settings [settingsType]="currentViewMode" (close)="viewPopover.hide(); viewSettingsChanged($event);"></slx-detail-group-view-settings>
              </popover-content>
            </div>

            <div class="filter">
              <button type="button" [popover]="filterPopover" class="{{filtersApplied ? 'theme-button-apply filter-btn' : 'theme-button-cancel filter-btn'}}">
                <i class="fa fa-filter" aria-hidden="true"></i>
              </button>
              <popover-content #filterPopover title="Filters" class="popover-content-arrow slx-wide" disableClickOutside="true" (onShow)="savePreviousFilters()"
                placement="left">
                  <div>
                    <slx-input-decorator class="auto-height">
                      <slx-dropdown-multiselect slx-input [options]="positionGroupLookup?.items" valueField="id" titleField="name" (ngModelChange)="onFilterChange('positionGroups',$event)"
                        placeholder="Position Group" name="positionGroupFilter" [(ngModel)]="positionGroupFilter">
                      </slx-dropdown-multiselect>
                    </slx-input-decorator>
                  </div>
                  <div>
                    <slx-input-decorator class="auto-height">
                      <slx-dropdown-multiselect slx-input [options]="positionLookup?.items" valueField="id" titleField="name" (ngModelChange)="onFilterChange('position',$event)"
                        placeholder="Position" name="posFilter" [(ngModel)]="posFilter">
                      </slx-dropdown-multiselect>
                    </slx-input-decorator>
                  </div>
                  <div>
                    <slx-input-decorator class="auto-height">
                      <slx-dropdown-multiselect slx-input  [options]="unitsLookup?.items" (ngModelChange)="onFilterChange('unit', $event)"
                        placeholder="Unit" name="unitFilter" [(ngModel)]="unitFilter" valueField="id" titleField="name" >
                      </slx-dropdown-multiselect>
                    </slx-input-decorator>
                  </div>
                  <div>
                    <slx-input-decorator class="auto-height">
                      <slx-dropdown-multiselect slx-input [options]="shiftsLookup?.items" (ngModelChange)="onFilterChange('shift',$event)"
                        placeholder="Shift" name="shiftFilter" [(ngModel)]="shiftFilter" valueField="id" titleField="name" >
                      </slx-dropdown-multiselect>
                    </slx-input-decorator>
                  </div>
                  <div class="btn-container">
                    <button type="button" class="theme-button-apply margin-r" (click)="filterPopover.hide(); this.cancelFilters();">Cancel</button>
                    <button type="button" class="theme-button-apply" (click)="filterPopover.hide(); this.applyFilters(); saveFilters()">Apply</button>
                  </div>
              </popover-content>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="details && previousGroups" class="slx-main-content-indents details-screen-groups" slxElementCreatedSelector (onCreated)="groupContainerCreated($event)">
        <div class="group" *ngFor="let group of previousGroups">
          <div class="group-header" (click)="hideTable(groupTable)">
            <div class="left-side" (click)="hideTable(groupTable)">
              <span class="toggle-table-visibility-button" (click)="hideTable(groupTable)">
                <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="groupTable.style.display !== 'none'"></i>
                <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="groupTable.style.display === 'none'"></i>
              </span>
              <div class="title" (click)="hideTable(groupTable)">
                <span *ngFor="let grouping of group.grouping | filter:{name: restrictedInfo}; let last = last">{{grouping.value}}{{!last ? ', ' : ''}}</span>
              </div>
              <!-- <div class="par-level-discrepancy">
                <span class="fa-stack fa-large">
                  <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                  <i class="fa fa-plus fa-stack-1x" aria-hidden="true"></i>
                  <span class="label label-warning">2</span>
                </span>
              </div> -->
            </div>
            <div class="right-side">
              <div *ngIf="group.emptySlotCount > 0" class="flex-vertical-selector">
                <button type="button" class="selector-button" (click)="toggleEmptySlots(group, $event)" [ngClass]="{'button-filter-applied': group.isEmptySlotFilterSelected, 'button-filter-clean': !group.isEmptySlotFilterSelected}">
                    <span class="clickable fa-stack fa group-filter">
                        <i class="far fa-circle fa-stack-2x" aria-hidden="true"  title="EmptySlotCount"></i>
                        <i class="fa fa-plus fa-stack-1x" aria-hidden="true"></i>
                      </span>
                  {{group.emptySlotCount}}
              </button>
              </div>
              <div *ngIf="group.outOfParCount > 0 && totalEmptySlotCount > 0" class="flex-vertical-selector">
                <button type="button" class="selector-button" (click)="toggleOutOfPar(group, $event)" [ngClass]="{'button-filter-applied': group.isOutOfParFilterSelected, 'button-filter-clean': !group.isOutOfParFilterSelected}">
                    <span class="clickable fa-stack fa" ngClass="{'group-filter': group.isOutOfParFilterSelected,'group-filter-inverse': !group.isOutOfParFilterSelected}">
                        <i class="fa fa-circle fa-stack-2x" aria-hidden="true" title="OutOfParCount"></i>
                        <i class="fas fa-exchange-alt fa-rotate-90 fa-stack-1x fa-inverse set-switchemp-icon" aria-hidden="true"></i>
                      </span>
                    {{group.outOfParCount}}
                </button>
              </div>
              <div *ngIf="group.overtimeCount > 0" class="flex-vertical-selector">
                <button type="button" class="selector-button" (click)="toggleOvertimeEmployees(group, $event)" [ngClass]="{'button-filter-applied': group.isOvertimeFilterSelected, 'button-filter-clean': !group.isOvertimeFilterSelected}">
                    <span class="clickable fa-stack fa employee-ot-icon">
                        <i class="fa fa-circle fa-stack-2x employee-ot-icon-back" aria-hidden="true"></i>
                        <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                        <span class="fa-stack-1x overtime-criteria">OT</span>
                    </span>
                    {{group.overtimeCount}}
                  </button>
              </div>
              <div *ngIf="group.messageCount > 0" class="flex-vertical-selector">
                <button type="button" class="selector-button" (click)="toggleNewMessages(group, $event)" [ngClass]="{'button-filter-applied': group.isMessageFilterSelected, 'button-filter-clean': !group.isMessageFilterSelected}">
                    <span class="clickable fa-stack fa employee-message-icon">
                        <i class="far fa-envelope fa-stack-2x" aria-hidden="true"></i>
                    </span>
                    {{group.messageCount}}
                    </button>
              </div>
              <!-- <div class="total">PAR: {{group.parLevel}}</div> -->
              <div class="total" *ngIf="currentViewMode==='slots'">Slots: {{group.slots}}</div>
              <div class="total" *ngIf="currentViewMode==='hours'">Hours: {{group.filteredScheduledHours | number : '1.0-2'}}/{{group.filteredTotalIdealHours | number : '1.0-2'}}</div>
              <div class="total" *ngIf="currentViewMode==='ppd'">PPD: {{ group.filteredFilledPpd | number : '1.0-2'}}/{{ group.filteredTotalPpd | number : '1.0-2'}}</div>
              <!-- <div class="flex-vertical-selector">
                  <button type="button" (click)="$event.stopPropagation();" [popover]="viewPopover" class="theme-button-apply filter-btn">
                    <span class="fas fa-cog"></span>
                  </button>
              </div> -->
              <!-- <span class="fa-stack clickable desktop">
                    <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                    <i class="fa fa-file fa-stack-1x" aria-hidden="true"></i>
                    <span class="label label-warning">{{group.postedShiftRepliesCount}}</span>
              </span>
              <span class="fa-stack fa-large clickable desktop">
                  <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                  <i class="far fa-envelopepen-o fa-stack-1x" aria-hidden="true"></i>
                  <span class="label label-warning">{{group.smsRepliesCount}}</span>
              </span> -->
            </div>
          </div>
          <div class="group-table" #groupTable>
            <slx-daily-unit-employees [canReplaceEmployee]="details.canReplaceEmployee" [showEmptySlots]="group.isEmptySlotFilterSelected" [showOvertimes]="group.isOvertimeFilterSelected"
              [showMessages]="group.isMessageFilterSelected" [showOutOfPar]="group.isOutOfParFilterSelected" [columns]="details.columns"
              [rows]="group.rows" (onEmployeeDelete)="employeeDelete($event)" (onFillShift)="onFillShift($event)" (onReplaceEmployee)="onReplaceEmployee($event)"
              (onShiftOpen)="shiftOpen($event)" (onSwitchEmployeeShift)="onSwitchEmployeeShift($event)" (onOpenIndividual)="onOpenIndividual($event)"
              (onNeedScroll)="scrollTo($event)" [isEmptyExists]="totalEmptySlotCount > 0"></slx-daily-unit-employees>
          </div>
        </div>
      </div>

    </ng-container>
    <div id="excelGrid" style="display: none" *ngIf="showGrid">
      <slx-export-excel-grid #exportRef *ngIf="GroupedListArray" [ExportGroupData]="GroupedListArray"  [dateOn] = "dateOn"></slx-export-excel-grid>    
    </div>
    <slx-switch-employee-shift *ngIf="state.switchShiftMode" [date]="dateOn" [selectedItem]="rowForShiftChange" (onSave)="performShiftSwitch ($event)"
      (onCancel)="discardShiftSwitch()"></slx-switch-employee-shift>
  </div>
</slx-spinner>
