import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { BreadcrumbStateService } from '../../../core/services/index';
import { BreadcrumbItem } from '../../../core/models/index';
import { unsubscribe } from '../../../core/decorators/index';
var HeaderHistoryBarComponent = /** @class */ (function () {
    function HeaderHistoryBarComponent(breadcrumbService) {
        this.breadcrumbService = breadcrumbService;
    }
    HeaderHistoryBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.serviceSubscription = this.breadcrumbService.changeNotify$.subscribe(function () {
            _this.updateLabel();
        });
        this.updateLabel();
    };
    HeaderHistoryBarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    HeaderHistoryBarComponent.prototype.updateLabel = function () {
        if (this.breadcrumbService) {
            var urls = this.breadcrumbService.breadcrumbs;
            if (urls && urls.length > 0) {
                this.item = _.last(urls);
                return;
            }
        }
        this.item = null;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderHistoryBarComponent.prototype, "serviceSubscription", void 0);
    return HeaderHistoryBarComponent;
}());
export { HeaderHistoryBarComponent };
