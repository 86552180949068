import { Observable } from 'rxjs/Observable';
import { Directive, Optional, Input, Output, EventEmitter, ContentChild, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';

import {
  LookupDefinition, LookupType, Lookup, ILookupRequest
} from '../../models/index';
import { LookupService } from '../../services/index';

@Directive({
  selector: 'slx-dropdown-input[lookup], slx-dropdown-multiselect[lookup], slx-autocomplete-combobox-input[lookup],slx-autocomplete-input[lookup], slx-dropdown-list[lookup], slx-autocomplete[lookup], slx-multiselect[lookup]'
})
export class LookupDirective implements OnDestroy, OnChanges {
  @Input()
  public lookup: LookupDefinition | Observable<any[]> | Promise<any[]> | ILookupRequest;
  @Input()
  public orgLevelId: number;
  @Input()
  public employeeId: number;
  @Input()
  public isActive: boolean;
  @Input()
  public filterLookup: Function;
  @Input()
  public orderLookup: 'asc' | 'desc';
  @Input()
  public orderField: string;
  @Output()
  public lookupApplied: EventEmitter<Lookup> = new EventEmitter<Lookup>();

  private unifiedLookup: Lookup;
  private lookupSubscription: Subscription;
  private lookupService: LookupService;
  private ngControl: NgControl;
  private ngControlHost: any;

  constructor(lookupService: LookupService, ngControl: NgControl) {
    this.ngControl = ngControl;
    this.ngControlHost = ngControl.valueAccessor;
    this.lookupService = lookupService;
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['lookup'] || changes['orgLevelId'] || changes['employeeId']) {
      this.applyLookup();
    }
  }

  public applyLookup(): void {
    if (this.lookup instanceof Promise) {
      this.lookup.then((items: any[]) => {
        if (this.ngControlHost) {
          this.unifiedLookup = this.lookupService.mapToUnified(this.lookup, items, this.ngControlHost.valueField, this.ngControlHost.titleField);
        } else {
          this.unifiedLookup = this.lookupService.mapToUnified(this.lookup, items, undefined, undefined);
        }
        this.applyLookupValues();
      });
    } else if (this.lookup instanceof Observable) {
      this.unsubscribe();
      this.lookup.subscribe((items: any[]) => {
        this.unifiedLookup = this.lookupService.mapToUnified(this.lookup, items, undefined, undefined);
        this.applyLookupValues();
      });
    } else if (this.lookup instanceof Lookup) {
      this.unifiedLookup = this.lookup;
      this.applyLookupValues();
    } else if (this.lookup instanceof Object) {
      this.lookupService.getLookup(this.lookup as ILookupRequest)
        .then((value: Lookup) => {
          this.unifiedLookup = value;
          this.applyLookupValues();
        });
    } else if (typeof (this.lookup) === 'string') {
      let lookupType: LookupDefinition = this.lookup as LookupDefinition;
      this.lookupService.getLookup({ lookupType: lookupType, orgLevelId: this.orgLevelId, employeeId: this.employeeId, isActive: this.isActive })
        .then((value: Lookup) => {
          this.unifiedLookup = value;
          this.applyLookupValues();
        });
    }
  }

  private applyLookupValues(): void {
    if (this.unifiedLookup.valueField) {
      this.ngControlHost.valueField = this.unifiedLookup.valueField;
    }
    if (this.unifiedLookup.titleField) {
      this.ngControlHost.titleField = this.unifiedLookup.titleField;
    }
    let items: any[] = this.unifiedLookup.items;
    if (this.filterLookup) {
      items = this.filterLookup(this.unifiedLookup.items);
    }
    if (this.orderLookup) {
      const firstItem = _.first(items);
      if (this.orderField && firstItem && _.has(firstItem, this.orderField)) {
        items = _.orderBy(items, this.orderField, this.orderLookup);
      } else {
        items = _.orderBy(items, this.ngControlHost.titleField, this.orderLookup);
      }
    }
    this.ngControlHost.options = items;
    this.lookupApplied.emit(this.unifiedLookup);
  }

  private unsubscribe(): void {
    if (this.lookupSubscription) {
      this.lookupSubscription.unsubscribe();
    }
  }
}
