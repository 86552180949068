<kendo-grid [data]="gridState.view" [selectable]="false"
    [groupable]="false"
    [sort]="gridState.state.sort"
    [group]="gridState.state.group"
    [sortable]="{ mode: 'multiple' }"
    class="slx-grid-lightgray ideal-grid"
    [scrollable]="'scrollable'">

    <kendo-grid-column title="Position">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.position?.position?.name}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Avg Daily Hours" field="avgDailyHours">
    </kendo-grid-column>

    <kendo-grid-column title="Configuration Type">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.position?.idealScheduleType?.name}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Census Config">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.censusConfig?.name}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="period" [hidden]="true">
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        Period: {{value}}
      </ng-template>
    </kendo-grid-column>

    <!-- Hide this for now
    <kendo-grid-column title="Alternating Week" field="alternatingWeeks">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.censusConfig?.alternatingWeeks | boolyn}}
        </ng-template>
    </kendo-grid-column>


    <kendo-grid-column title="History">
        <ng-template kendoGridCellTemplate let-dataItem>
            <span class="history-link">History</span>
        </ng-template>
    </kendo-grid-column>
    -->

</kendo-grid>
