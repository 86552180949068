<div class="slx-lr-toolbar">
  <div class="slx-lr-toolbar__dates">
        <slx-daterange 
        class="slx-width-260" 
        name="startEndDate" 
        [startDatePlaceholder]="startDatePlaceHolder"
        [endDatePlaceholder]="endDatePlaceHolder"
        [startDate]="dateRange.startDate"
        [endDate]="dateRange.endDate"
        [minimumStartDate]="minDate"
        [maxSecRange]="maxDateRange"
        [maxRangeErrorText]="maxDateRangeError"
        [disableStartDate]="disableStartDate"
        [disableEndDate]="disableEndDate"
        (rangeDateChanged)="onDatesRangeChanged($event)"
        [startDateReadOnlyInput]="true"
        [endDateReadOnlyInput]="true"
        (keydown)="onKeydown($event)"
      slxControlActiveState></slx-daterange>
  </div>
  <div class="action-btns-container">
    <div class="config-btn" [ngClass]="{'config-width' :isMobile }" (click)="openConfigPopup('Organization', currentOrgLevel.id, currentOrgLevel.name, isMobile)" *ngIf="currentOrgLevel.type == 'Organization'">
      <span class="fas fa-cog"></span>
      <span>Configure</span>
    </div>
    <div class="slx-lr-toolbar__filters actionsAlign">
      <slx-action-list>
        <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent"
          [popperPosition]="'bottom-end'">Actions</slx-action-button>
        <popper-content #popperContent [ngClass]="isMobile ? 'actionsContentClass' : ''">
          <slx-action-list-item (click)="exportToExcel()">Export to Excel</slx-action-list-item>
        </popper-content>
      </slx-action-list>
    </div>
  </div>
    
</div>

<kendo-excelexport [data]="exportOpenPositionControlData" fileName="OpenPositionControl.xlsx" #excelExport>
  <kendo-excelexport-column-group [title]="getDateRange()" [headerCellOptions]="{ textAlign:'center', bold: true }">
    <kendo-excelexport-column field="organizationLevel" title="Organization Level" [width]="300" [headerCellOptions]="{ bold: true }">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="totalHourGaps" title="Total Hours Gap" [width]="200" [headerCellOptions]="{ bold: true }">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="totalOpenShifts" title="Open Shifts" [width]="200" [headerCellOptions]="{ bold: true }">
    </kendo-excelexport-column>
  </kendo-excelexport-column-group>
</kendo-excelexport>