import { IBenefitDetailsBasic, BenefitDetailsBasic } from './benefit-details-basic';
import { BenefitDetailsState } from './benefit-details-state';
import { ReadFile, IAttachmentFile, AttachmentFile } from '../../../../organization/models/index';
import { IBenefitDetailsOption, BenefitDetailsOption } from './benefit-details-option';
import { IBenefitDetailsFormula, BenefitDetailsFormula } from './benefit-details-formula';

export interface IBenefitDetailsTier {
  tierDefinition: IBenefitDetailsBasic<number, string>;

  //Benefit Info
  planId: string;
  highDeductableTier: boolean;
  hasBeneficiaries: boolean;
  notes: string;

  //Employees
  benefitGroupsCount: number;
  nonTobacco: boolean;
  domesticPartnerEligible: boolean;
  companyProvided: boolean;

  //Flat calculation
  empContribution: number;
  erContribution: number;

  //Formula calculation
  empFormula: IBenefitDetailsFormula;
  erFormula: IBenefitDetailsFormula;
  coverageFormula: IBenefitDetailsFormula;

  //ACA
  acaMinCoverage: boolean;
  acaMinValue: boolean;
  acaPovLevelTest: boolean;
  acaHarborCode: IBenefitDetailsBasic<number, string>;

  attachments: IAttachmentFile[];
  options: IBenefitDetailsOption[];

  isDeleted: boolean;
  modifiedAt: string;
  modifiedBy: string;
  addedAt: string;
  addedBy: string;
  payrollDeductionCode:string;
}

export class BenefitDetailsTier extends BenefitDetailsState {
  public tier = new BenefitDetailsBasic<number, string>(null, '', '');

  //Benefit Info
  public planId: string;
  public highDeductableTier: boolean;
  public hasBeneficiaries: boolean;
  public notes: string;

  //Employees
  public benefitGroupsCount: number;
  public nonTobacco: boolean;
  public domesticPartnerEligible: boolean;
  public companyProvided: boolean;

  //Flat calculation
  public empContribution: number;
  public erContribution: number;

  //Formula calculation
  public empFormula = new BenefitDetailsFormula();
  public erFormula = new BenefitDetailsFormula();
  public coverageFormula = new BenefitDetailsFormula();

  //ACA
  public acaMinCoverage: boolean;
  public acaMinValue: boolean;
  public acaPovLevelTest: boolean;
  public acaHarborCode: BenefitDetailsBasic<number, string>;

  public attachments: AttachmentFile[] = [];
  public options: BenefitDetailsOption[] = [];
  public filesToAttach: ReadFile[] = [];

  public isDeleted: boolean;
  public modifiedAt: Date;
  public modifiedBy: string;
  public addedAt: Date;
  public addedBy: string;
  public payrollDeductionCode:string;
  public get id(): number {
    return this.tier.id;
  }

  public get name(): string {
    return this.tier.name;
  }

  public set name(v: string) {
    this.tier.name = v;
  }

  public get shortName(): string {
    if (this.tier.name.length <= 25) {
      return this.tier.name;
    }
    return `${this.tier.name.slice(0, 25)}...`;
  }

  public get title(): string {
    return this.tier.title;
  }

  public get shortTitle(): string {
    if (this.tier.title.length <= 25) {
      return this.tier.title;
    }
    return `${this.tier.title.slice(0, 25)}...`;
  }

  public get isDraft(): boolean {
    return !isFinite(this.id) || this.id < 0;
  }

  public get hasTypeAndCode(): boolean {
    if (Array.isArray(this.options) && this.options.length > 0) {
      return this.options.every((o) => o.hasTypeAndCode);
    }

    return true;
  }
}
