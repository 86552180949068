/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-details-providers-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../components-library/components/dropdown-list/dropdown-list.component.ngfactory";
import * as i4 from "../../../../../components-library/components/dropdown-list/dropdown-list.component";
import * as i5 from "@angular/forms";
import * as i6 from "./benefit-details-providers-menu.component";
import * as i7 from "../../../services/benefit-details/benefit-details-management.service";
import * as i8 from "../../../services/benefit-details/benefit-details-permission.service";
import * as i9 from "../../../services/benefit-details/benefit-details-standart-management.service";
import * as i10 from "../../../../../common/services/device-detector/device-detector.service";
var styles_BenefitDetailsProvidersMenuComponent = [i0.styles];
var RenderType_BenefitDetailsProvidersMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitDetailsProvidersMenuComponent, data: {} });
export { RenderType_BenefitDetailsProvidersMenuComponent as RenderType_BenefitDetailsProvidersMenuComponent };
function View_BenefitDetailsProvidersMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [["class", "providers-tabs__item"]], [[8, "title", 0], [2, "active", null], [2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.current.storedName; var currVal_1 = _co.isActiveMenu(_v.context.$implicit); var currVal_2 = (_co.isEditMode && !_co.isActiveMenu(_v.context.$implicit)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.storedProviderName; _ck(_v, 1, 0, currVal_3); }); }
function View_BenefitDetailsProvidersMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BenefitDetailsProvidersMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "providers-tabs flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "flex providers-tabs__wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "providers-tabs__arrows align-self__center"]], [[2, "disabled", null], [2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickShift(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-angle-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "providers-tabs__slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "hr", [["class", "page-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["list", 1]], null, 2, "ul", [["class", "providers-tabs__list flex flex-direction-row"]], [[4, "transform", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsProvidersMenuComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "providers-tabs__arrows align-self__center"]], [[2, "disabled", null], [2, "hidden", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickShift(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-angle-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsProvidersMenuComponent_3)), i1.ɵdid(12, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.providers; _ck(_v, 8, 0, currVal_3); var currVal_6 = i1.ɵnov(_v.parent, 5); _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabledLeft; var currVal_1 = _co.isHiddenLeftRigth; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.translateX; _ck(_v, 6, 0, currVal_2); var currVal_4 = _co.isDisabledRight; var currVal_5 = _co.isHiddenLeftRigth; _ck(_v, 9, 0, currVal_4, currVal_5); }); }
function View_BenefitDetailsProvidersMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BenefitDetailsProvidersMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "providers-tabs mobile flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "providers-tabs__mobile-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "slx-dropdown-list", [["class", "slx-wide"], ["titleField", "storedProviderName"], ["valueField", "id"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedProvider = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onChangeProviderOnMobile($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_DropdownListComponent_0, i3.RenderType_DropdownListComponent)), i1.ɵdid(3, 49152, null, 0, i4.DropdownListComponent, [], { options: [0, "options"], valueField: [1, "valueField"], titleField: [2, "titleField"], readonly: [3, "readonly"] }, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DropdownListComponent]), i1.ɵdid(5, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(7, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsProvidersMenuComponent_5)), i1.ɵdid(9, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.providers; var currVal_8 = "id"; var currVal_9 = "storedProviderName"; var currVal_10 = _co.isEditMode; _ck(_v, 3, 0, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.selectedProvider; _ck(_v, 5, 0, currVal_11); var currVal_12 = i1.ɵnov(_v.parent, 5); _ck(_v, 9, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 7).ngClassValid; var currVal_5 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_BenefitDetailsProvidersMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "slx-button slx-blue slx-with-icon slx-no-breaks slx-mobile-adapted align-self__center"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCreateNew() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-plus slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create New"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.canCreate; _ck(_v, 0, 0, currVal_0); }); }
export function View_BenefitDetailsProvidersMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ulElem: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsProvidersMenuComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsProvidersMenuComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["createButton", 2]], null, 0, null, View_BenefitDetailsProvidersMenuComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isMobile; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isMobile; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BenefitDetailsProvidersMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-benefit-details-providers-menu", [], null, null, null, View_BenefitDetailsProvidersMenuComponent_0, RenderType_BenefitDetailsProvidersMenuComponent)), i1.ɵdid(1, 245760, null, 0, i6.BenefitDetailsProvidersMenuComponent, [i7.BenefitDetailsManagementService, i8.BenefitDetailsPermissionService, i9.BenefitDetailsStandartManagementService, i10.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BenefitDetailsProvidersMenuComponentNgFactory = i1.ɵccf("slx-benefit-details-providers-menu", i6.BenefitDetailsProvidersMenuComponent, View_BenefitDetailsProvidersMenuComponent_Host_0, {}, {}, []);
export { BenefitDetailsProvidersMenuComponentNgFactory as BenefitDetailsProvidersMenuComponentNgFactory };
