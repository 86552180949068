import * as _ from 'lodash';
import { Directive, Input, forwardRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';
import { CommonValidators } from './common-validators';
import { IProhibitedValidatorConf } from './common-validators-models';

const PROHIBITED_VALUES_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ProhibitedValuesValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[prohibitedValues][formControlName],[prohibitedValues][formControl],[prohibitedValues][ngModel]',
  /* tslint:enable */
  providers: [PROHIBITED_VALUES_VALIDATOR]
})
export class ProhibitedValuesValidator implements Validator, OnChanges {
  @Input()
  public set prohibitedValues(value: any[] | IProhibitedValidatorConf | any) {
    if (!Array.isArray(value) && !_.has(value, 'valuePropertyName') && !_.has(value, 'excludeSelf')) {
      this.m_prohibitedValues = [value];
    } else {
      this.m_prohibitedValues = value;
    }
  }

  @Input()
  public selfValue: any;

  /* tslint:disable */
  private _validator: ValidatorFn;
  private _onChange: () => void;
  private m_prohibitedValues: any;
  /* tslint:enable */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['prohibitedValues']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): { [key: string]: any } {
    return this._validator(c);
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    if(!Array.isArray(this.m_prohibitedValues)) {
      this.m_prohibitedValues.selfValue = this.selfValue;
    }
    this._validator = CommonValidators.prohibitedValues(this.m_prohibitedValues);
  }
}
