/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aca-1095-c-grid-column-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./aca-1095-c-grid-column-header.component";
import * as i3 from "../../../services/aca-1095-c/aca-1095-c-management.service";
var styles_Aca1095cGridColumnHeaderComponent = [i0.styles];
var RenderType_Aca1095cGridColumnHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Aca1095cGridColumnHeaderComponent, data: {} });
export { RenderType_Aca1095cGridColumnHeaderComponent as RenderType_Aca1095cGridColumnHeaderComponent };
export function View_Aca1095cGridColumnHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMonthLinkClick(_co.params, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "link-header-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "link-column  unlink-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "em", [["class", "fal fa-unlink"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMonthLinkClick(_co.params, false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "link-header-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "link-column link-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "em", [["class", "fal fa-link"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.link[_co.params.name]; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.getColumnName(_co.params.name); _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.params.link[_co.params.name]; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.getColumnName(_co.params.name); _ck(_v, 9, 0, currVal_3); }); }
export function View_Aca1095cGridColumnHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "aca-1095c-grid-column-header", [], null, null, null, View_Aca1095cGridColumnHeaderComponent_0, RenderType_Aca1095cGridColumnHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i2.Aca1095cGridColumnHeaderComponent, [i3.Aca1095cManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Aca1095cGridColumnHeaderComponentNgFactory = i1.ɵccf("aca-1095c-grid-column-header", i2.Aca1095cGridColumnHeaderComponent, View_Aca1095cGridColumnHeaderComponent_Host_0, {}, {}, []);
export { Aca1095cGridColumnHeaderComponentNgFactory as Aca1095cGridColumnHeaderComponentNgFactory };
