import { Component, Input } from '@angular/core';

import { OrgLevel } from '../../../../state-model/models/index';
import { PunchAttestationGroup } from '../../models';

import { PunchAttestationManagementService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-punch-attestation-header',
  templateUrl: 'punch-attestation-header.component.html',
  styleUrls: ['punch-attestation-header.component.scss']
})
export class PunchAttestationHeaderComponent {

  @Input()
  public orgLevel: OrgLevel;

  public group: PunchAttestationGroup;

  public isLegacyDisabled: boolean;

  constructor(
    private managementService: PunchAttestationManagementService
  ) { }

  public ngOnInit(): void {
  }

  public addGroup() {
    if(this.isGroupNameValid){
      this.managementService.addGroups();
    }
  }

  public addLegacyGroup(){
    if(!this.isLeagacyGroupExists && this.isLegacy && this.isGroupNameValid){
      this.managementService.addLegacyGroup();   
    }
  }

  get isLegacy(){
    return this.managementService.isLegacy$;
  }

  get isConfigure(){
    return this.managementService.configurePunchAttestation.getValue();
  }

  get isGroupNameValid(){
    return this.managementService.isGroupNameValid;
  }

  get isLeagacyGroupExists(){
    return this.managementService.isLegacyGroupExists;
  }

  get disableSalteButton(){
      return !this.isGroupNameValid || !this.isConfigure;
  }

  get disableLegacyButton(){
    return this.isLeagacyGroupExists || !this.isGroupNameValid || !this.isConfigure;
  }
}
