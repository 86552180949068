import * as _ from 'lodash';
import { IEssTemplate, EssTemplate } from '../../../../app-modules/ess-templates/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';


export interface IEmployeeSectionsEssTemplate {
  employeeId: number;
  essTemplate: IEssTemplate;
  passwordShouldBeReseted: boolean;
}

export class EmployeeSectionsEssTemplate extends EmployeeSectionsBase {
  public employeeId: number;
  public essTemplate: EssTemplate;
  public passwordShouldBeReseted: boolean;
}
