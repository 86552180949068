import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import {
  LookupMapService,
  EmployeeDefinitionsMapService
} from '../../../organization/services/index';

import { FieldsMeta, IFieldData,Actions } from '../../../core/models/index';
import { Aca1095cActions, IAca1095c, Aca1095c, IAca1095cRecord, Aca1095cRecord, IAcaMonthRecord, AcaMonthRecord, IAcaInsightSummaryRecords, AcaInsightSummaryRecords} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils';
import { MetaMapService } from '../../../core/services';

@Injectable()
export class Aca1095cMapService {

  constructor(
    private lookupMapService: LookupMapService,
    private employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    private metaMapService:MetaMapService
  ) { }

  public mapAca1095cRecords(dto: IAca1095c, meta: FieldsMeta): Aca1095c {
    const data: Aca1095c = new Aca1095c();
    data.records = _.map(dto.records, record => this.mapAca1095cRecord(record));
    data.insightSummary = dto.insightSummary?this.mapInsightSummary(dto.insightSummary):null;
    data.actions = meta?this.mapAcaActions(meta):null;
    return data;
  }
  private mapInsightSummary(dto: IAcaInsightSummaryRecords): AcaInsightSummaryRecords {
    let insights: AcaInsightSummaryRecords = new AcaInsightSummaryRecords();
    insights.total = dto.total;
    insights.totalConfirmed = dto.totalConfirmed;
    insights.totalNotConfirmed = dto.totalNotConfirmed;
    insights.fullTimeEnrolledFullYear = dto.fullTimeEnrolledFullYear;
    insights.fullTimeEnrolledPartYear = dto.fullTimeEnrolledPartYear;
    insights.fullTimeNotEnrolled = dto.fullTimeNotEnrolled;
    insights.partTimeEnrolled = dto.partTimeEnrolled;
    return insights;
  }
  private mapAcaActions(meta: FieldsMeta): Aca1095cActions {
    let actions: Aca1095cActions = new Aca1095cActions();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    let action: Actions = new Actions();
    action = this.metaMapService.mapActions(meta);
    actions.employeeName = this.metaMapService.mapMeta<string>(metaMap, 'empName', 'empName').Policy.isVisible;
    actions.organization = this.metaMapService.mapMeta<string>(metaMap, 'empOrganization', 'empOrganization').Policy.isVisible;
    actions.department = this.metaMapService.mapMeta<string>(metaMap, 'empDepartment', 'empDepartment').Policy.isVisible;
    actions.position = this.metaMapService.mapMeta<string>(metaMap, 'jobDescription', 'jobDescription').Policy.isVisible;
    actions.hireDate =  this.metaMapService.mapMeta<string>(metaMap, 'empDtHire', 'empDtHire').Policy.isVisible;
    actions.terminationDate =  this.metaMapService.mapMeta<string>(metaMap, 'empTerminationDate', 'empTerminationDate').Policy.isVisible;
    actions.acaRecord = this.metaMapService.mapMeta<string>(metaMap, 'aCARecord', 'aCARecord').Policy.isVisible;
    actions.addEmployee=action.canAdd;
    actions.deleteEmployee=action.canDelete;
    actions.exportToExcel=action.canExportToExcel;
    actions.exportToPdf=action.canExportToPdf;
    actions.edit1095c=action.canEdit;
    actions.confirm1095c=action.canSubmit;
    return actions;
  }

  public mapAca1095cRecord(dto: IAca1095cRecord): Aca1095cRecord {
    const data = new Aca1095cRecord();

    data.employeeId = dto.employeeId;
    data.employeeMasterId=dto.employeeMasterId;
    data.employeeName = dto.employeeName;
    data.companyId=dto.companyId;
    data.organization =dto.organization;
    data.department = dto.department;
    data.position = dto.position;
    data.acaType = dto.acaType;  
    data.benefitDeclined = dto.benefitDeclined;
    data.purchasedMarketplace = dto.purchasedMarketplace;
    data.hireDate = dateTimeUtils.convertFromDtoDateTimeString(dto.hireDate);
    data.terminationDate = dateTimeUtils.convertFromDtoDateTimeString(dto.terminationDate);
    data.months = _.isObjectLike(dto.months) ? _.map(dto.months, month => this.mapMonthRecord(month)): null;
    data.isConfirmed=dto.isConfirmed;
    data.isNotConfirmed=dto.isNotConfirmed;
    data.isFullTimeEnrolledFullYear=dto.isFullTimeEnrolledFullYear;    
    data.isFullTimeEnrolledPartYear=dto.isFullTimeEnrolledPartYear;    
    data.isFullTimeNotEnrolled=dto.isFullTimeNotEnrolled;
    data.isPartTimeEnrolled=dto.isPartTimeEnrolled;
    return data;
  }

  public mapMonthRecord(dto: IAcaMonthRecord): AcaMonthRecord {
    const data = new AcaMonthRecord();
    data.monthName= dto.monthName;
    data.date = dto.date;
    data.activeInd = dto.activeInd ;
    data.suggestedOption = !_.isNull(dto.suggestedOption) ? dto.suggestedOption : '';
    data.suggestedTier = !_.isNull(dto.suggestedTier) ? dto.suggestedTier : '';
    data.suggestedBenefitId = dto.suggestedBenefitId;
    data.suggestedBenefitName = dto.suggestedBenefitName;
    data.suggestedUnsure = dto.suggestedUnsure;
    data.suggestedCode = !_.isNull(dto.suggestedCode) ? dto.suggestedCode : '';
    data.suggestedHarbor = !_.isNull(dto.suggestedHarbor) ? dto.suggestedHarbor : '';
    data.suggestedCost = !_.isNull(dto.suggestedCost) ? dto.suggestedCost : '';
    data.enrolledInd = dto.enrolledInd;
    data.selectedOption = !_.isNull(dto.selectedOption) ? dto.selectedOption : '';
    data.selectedTier = !_.isNull(dto.selectedTier) ? dto.selectedTier : '';
    data.selectedBenefitName = dto.selectedBenefitName;
    data.selectedBenefitId = dto.selectedBenefitId;
    data.selectedCode =!_.isNull(dto.selectedCode) ? dto.selectedCode : ''; 
    data.selectedHarbor =!_.isNull(dto.selectedHarbor) ? dto.selectedHarbor : ''; 
    data.selectedCost =!_.isNull(dto.selectedCost) ? dto.selectedCost : ''; 
    data.selZip=!_.isNull(dto.selZip) ? dto.selZip : ''; 
    data.selected = dto.selected;
    data.confirmedInd = dto.confirmedInd;
    data.hireMonthInt = dto.hireMonthInt;
    data.termMonthInt = dto.termMonthInt;
    data.reason=dto.reason;
    data.quickConfirm=false;
    data.quickEdit=false;
    data.quickConfirmReset=false;
    data.quickEditReset=false;
    data.link=false;
    data.debug = dto.debug;
    return data;
  }

}
