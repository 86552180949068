<label class="selector-title">Budget Start Date</label>&nbsp;
<slx-actions-list>
  <slx-actions-button className="auto-width" [popperContent]="popperContent" [popperPosition]="'bottom-start'">
    <span *ngIf="!selectedBudgetDefinition">Please select budget</span>
    <span *ngIf="selectedBudgetDefinition">{{selectedBudgetDefinition.startDate | amDateFormat: appConfig.dateFormat}}-<span *ngIf="selectedBudgetDefinition.endDate">{{selectedBudgetDefinition.endDate | amDateFormat: appConfig.dateFormat}}</span></span>
    <span class="caret"></span>
  </slx-actions-button>
  <popper-content #popperContent>
    <slx-actions-list-item (onClick)="onBudgetDefinitionAdd()">Create New</slx-actions-list-item>
    <slx-actions-list-item
      *ngFor="let item of budgetDefinitions"
      (onClick)="onBudgetDefinitionSelect(item)"
      [className]="selectedBudgetDefinition && selectedBudgetDefinition.id === item.id ? 'active' : ''"
    >
      {{item?.startDate | amDateFormat: appConfig.dateFormat}} - <span *ngIf="item.endDate">{{item.endDate | amDateFormat: appConfig.dateFormat}}</span>
    </slx-actions-list-item>
  </popper-content>
</slx-actions-list>
