export * from './meal-deduction-configuration/meal-deduction-configuration.component';
export * from './meal-deduction-configuration-dialog/meal-deduction-configuration-dialog.component';

import { MealDeductionConfigurationComponent } from './meal-deduction-configuration/meal-deduction-configuration.component';
import { MealDeductionConfigurationDialogComponent } from './meal-deduction-configuration-dialog/meal-deduction-configuration-dialog.component';
import { PbjExportLocationsComponent } from './pbj-export-locations/pbj-export-locations.component';
import { MealDeductionNewConfigurationDialogComponent } from './meal-deduction-new-configuration-dialog/meal-deduction-new-configuration-dialog.component';
import { MealDeductionNewConfigurationComponent } from './meal-deduction-new-configuration/meal-deduction-new-configuration.component';

export const mealDeductionExportComponents: any[] = [
    MealDeductionConfigurationComponent,
    PbjExportLocationsComponent,
    MealDeductionNewConfigurationComponent
];

export const mealDeductionEntryComponents: any[] = [MealDeductionConfigurationDialogComponent, MealDeductionNewConfigurationDialogComponent];

export const mealDeductionComponents: any[] = [
    ...mealDeductionExportComponents,
    MealDeductionConfigurationDialogComponent,
    MealDeductionNewConfigurationDialogComponent
];
