<form class="dialog-form" #dialogForm="ngForm">
  <div class="modal-content">
    <div class="modal-body">
        <slx-user-password-settings #settingForm [hideToolbar]="true"></slx-user-password-settings>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onOk()" [disabled]="!settingForm.canSave" class="theme-button-apply">Save</button>
      <button type="button" (click)="onCancel()" class="theme-button-apply">Close</button>
    </div>
  </div>
</form>
