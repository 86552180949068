import * as tslib_1 from "tslib";
import { BusyService } from '../../../../common';
import { data_Check } from '../../../../configuration/models/wfm-sync';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { NotificationsService } from '../../../../core/components/angular2-notifications';
import { BaseSyncComponent } from '../wfm-sync-base.component';
var WfmNgpEmployeeSyncComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WfmNgpEmployeeSyncComponent, _super);
    function WfmNgpEmployeeSyncComponent(wfmSyncService, notificationService, busyService) {
        var _this = _super.call(this, notificationService, busyService) || this;
        _this.wfmSyncService = wfmSyncService;
        return _this;
    }
    WfmNgpEmployeeSyncComponent.prototype.syncDataCore = function (selectedItems) {
        return this.busyService.busy(this.wfmSyncService.employeeSyncOrg(selectedItems, this.syncType));
    };
    WfmNgpEmployeeSyncComponent.prototype.getSyncItemKey = function (item) {
        return '' + item.employeeID;
    };
    WfmNgpEmployeeSyncComponent.prototype.fetchData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.employeeDetailsSync(this.syncType))];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, this.processData(data)];
                }
            });
        });
    };
    WfmNgpEmployeeSyncComponent.prototype.processData = function (data) {
        return data_Check(data);
    };
    return WfmNgpEmployeeSyncComponent;
}(BaseSyncComponent));
export { WfmNgpEmployeeSyncComponent };
