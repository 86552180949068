import { EventEmitter } from '@angular/core';
import { RecipientInfo, SendShiftSmsRequest, SentMessageAttributes } from '../../../models/index';
import { EmployeeMessagesApiService } from '../../../services/index';
import * as _ from 'lodash';
var SendShiftSmsComponent = /** @class */ (function () {
    function SendShiftSmsComponent(employeeMessagesApiService) {
        this.employeeMessagesApiService = employeeMessagesApiService;
        this.state = {
            inProcess: false
        };
        this.m_selectedRecipients = [];
        this.sendCanceled = new EventEmitter();
        this.messageSent = new EventEmitter();
        this.errorOccured = new EventEmitter();
    }
    Object.defineProperty(SendShiftSmsComponent.prototype, "recipientsInfo", {
        get: function () {
            return this.m_recipientsInfo;
        },
        set: function (value) {
            this.m_recipientsInfo = value;
            if (value && value.length > 0) {
                this.selectedRecipients.push(_.first(value));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SendShiftSmsComponent.prototype, "sendSmsRequest", {
        get: function () {
            return this.m_sendShiftSmsRequest;
        },
        set: function (value) {
            this.m_sendShiftSmsRequest = value;
            if (value) {
                this.recipientsInfo = value.recipients;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SendShiftSmsComponent.prototype, "selectedRecipients", {
        get: function () {
            return this.m_selectedRecipients;
        },
        set: function (value) {
            this.m_selectedRecipients = value;
        },
        enumerable: true,
        configurable: true
    });
    SendShiftSmsComponent.prototype.send = function () {
        var _this = this;
        this.state.inProcess = true;
        this.employeeMessagesApiService
            .sendShiftSms(this.sendSmsRequest).then(function (messageId) {
            var sentAttributes = new SentMessageAttributes();
            sentAttributes.messageId = messageId;
            _this.state.inProcess = false;
            _this.messageSent.emit(sentAttributes);
        }).catch(function (error) {
            _this.state.inProcess = false;
            _this.errorOccured.emit();
        });
    };
    SendShiftSmsComponent.prototype.cancel = function () {
        this.sendCanceled.emit();
    };
    return SendShiftSmsComponent;
}());
export { SendShiftSmsComponent };
