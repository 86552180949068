import { Pipe, PipeTransform } from '@angular/core';

import { Organization, Department, Position } from '../models/index';

@Pipe({ name: 'slxPositionPath' })
export class PositionPathPipe implements PipeTransform {
    transform(value: Position, organization?: Organization, department?: Department): string {
        let path: string = '';
        let values: string[] = [];
        if (organization) values.push(organization.name);
        if (department) values.push(department.name);
        if (value) values.push(value.name);
        if (values.length > 0) path = values.join(' > ');
        return path;
    }
}
