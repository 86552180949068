<span
      *ngIf="node.hasChildren"
      [class.toggle-children-wrapper-expanded]="node.isExpanded"
      [class.toggle-children-wrapper-collapsed]="node.isCollapsed"
      class="toggle-children-wrapper"
      (click)="node.mouseAction('expanderClick', $event)">

      <span class="toggle-children"></span>
</span>
<span
    *ngIf="!node.hasChildren"
    class="toggle-children-placeholder">
</span>