import { Directive, Input, ElementRef, Renderer, HostListener, OnInit, Host, AfterViewInit, DoCheck, Optional, ChangeDetectorRef } from '@angular/core';
import { GridComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';

import { IColumnSettings } from '../../../../core/models/index';
import { ColumnSettingsStorageService, ColumnManagementService, GroupEvent, ColumnsChangedEvent } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { KendoGridColumnsGroupDirective } from './kendo-grid-columns-group.directive';

@Directive({
  selector: '[slxKendoGridColumnHiddenState]',
})
export class KendoGridColumnHiddenStateDirective implements OnInit {

  @Input('slxKendoGridColumnHiddenState')
  public columnName: string;
  
  @Input()
  public supressRegistration: boolean;

  @Input()
  public columnGroup: string;
  @Input()
  public displayedDefault: boolean = true;

  private state: IColumnSettings;
  @unsubscribe()
  private initSubscription: Subscription;
  @unsubscribe()
  private changesSubscription: Subscription;

  private get name(): string {
    return this.columnName ? this.columnName : this.column.field;
  }
  private get groupName(): string {
    if(!this.columnGroup && this.m_groupName) {
      return this.m_groupName;
    }
    return this.columnGroup ? this.columnGroup : ColumnSettingsStorageService.defaultColumnGroupKey;
  }

  private m_groupName: string;
  constructor(
    @Host() private group: KendoGridColumnsGroupDirective, 
    @Host() private column: ColumnComponent, 
    private stateManagement: ColumnManagementService, 
    private columnSettingsStorageService: ColumnSettingsStorageService, 
    private changeDetector: ChangeDetectorRef) {
  }
  public ngOnInit(): void {
    this.m_groupName = this.group.columnGroup;
    if(this.supressRegistration)
    {
      return;
    }
    this.initSubscription = this.stateManagement.initGroup$.filter((event: GroupEvent) => event.group === this.groupName)
      .subscribe((event: GroupEvent) => {
        this.stateManagement.registerColumn(this.groupName, { name: this.name, description: this.column.displayTitle, displayed: this.displayedDefault });
      });

    this.changesSubscription = this.stateManagement.columnsChanged$.filter((event: ColumnsChangedEvent) => event.group === this.groupName)
      .subscribe((event: ColumnsChangedEvent) => {
        this.state = _.find(event.columns, (c: IColumnSettings) => c.name === this.name);
        if (!this.state) {
          return;
        }
        if (this.state.displayed === undefined) {
          return;
        }
        this.column.hidden = !this.state.displayed;

        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
  }
}
