import { EmployeeSectionsBase } from '../employee-sections-base';
import { FieldsMeta } from '../../../../core/models/index';

export interface IEmployeeSectionsBackgroundCheck {
  recordId:number;
  subject: string;
  description: string
  addedBy: string;
  addedOn: Date;
  lastModifiedBy: string;
  lastModifiedOn: Date;
}
export class EmployeeSectionsBackgroundCheck {
  public recordId: number;
  public subject: string;
  public description: string
  public addedBy: string;
  public addedOn: Date;
  public lastModifiedBy: string;
  public lastModifiedOn: Date;
}
export interface IEmployeeSectionsBackgroundChecks {
  backgroundChecks: EmployeeSectionsBackgroundCheck[];
}
export class EmployeeSectionsBackgroundChecks extends EmployeeSectionsBase{
  public backgroundChecks: EmployeeSectionsBackgroundCheck[];
  public fieldsMeta: FieldsMeta;
}
