import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import * as moment from 'moment';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { DateRange } from '../../../../../core/models/index';
import { dateTimeUtils } from '../../../../../common/utils/index';
var LMCustomRecurrenceComponent = /** @class */ (function () {
    function LMCustomRecurrenceComponent(manService) {
        this.manService = manService;
        this.actStartDate = null;
        this.actEndDate = null;
        this.actAllDay = false;
        this.customDates = [];
        this.hasOverlapping = false;
        this.hasExceeded = false;
        this.appConfig = appConfig;
        this.isValidActStartDate = true;
        this.isValidActEndDate = true;
        this.exceptionsDates = [];
        this.subscriptions = {};
    }
    Object.defineProperty(LMCustomRecurrenceComponent.prototype, "canAddDate", {
        get: function () {
            return this.isValidDate(this.actStartDate) && this.isValidDate(this.actEndDate) && !this.hasOverlapping && !this.hasExceeded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCustomRecurrenceComponent.prototype, "hasDates", {
        get: function () {
            return _.size(this.customDates) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCustomRecurrenceComponent.prototype, "canEditRequest", {
        get: function () {
            return this.manService.canChange;
        },
        enumerable: true,
        configurable: true
    });
    LMCustomRecurrenceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updatesDates();
        this.subscriptions.loaRequest = this.manService.subscribeToChangedRequest(function () { return _this.updatesDates(); });
    };
    LMCustomRecurrenceComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMCustomRecurrenceComponent.prototype.onClickAddDate = function () {
        var range = new DateRange(this.actStartDate, this.actEndDate);
        this.customDates = this.customDates.concat([range]);
        this.actStartDate = null;
        this.actEndDate = null;
        this.isValidActStartDate = false;
        this.isValidActEndDate = false;
        this.manService.setCustomDates(this.customDates);
    };
    LMCustomRecurrenceComponent.prototype.onDeleteDate = function (index) {
        this.customDates = _.filter(this.customDates, function (d, i) { return i !== index; });
        this.manService.setCustomDates(this.customDates);
    };
    LMCustomRecurrenceComponent.prototype.onChangeActStartDate = function (date) {
        this.validateOverlappedDates();
        this.validateExceptionsDates();
        if (date != null)
            this.isValidActStartDate = false;
        else
            this.isValidActStartDate = true;
    };
    LMCustomRecurrenceComponent.prototype.onChangeActEndDate = function (date) {
        this.validateOverlappedDates();
        this.validateExceptionsDates();
        if (date != null)
            this.isValidActEndDate = false;
        else
            this.isValidActEndDate = true;
    };
    LMCustomRecurrenceComponent.prototype.onChangeActAllDay = function () {
        if (this.actAllDay) {
            this.actStartDate = this.manService.getStartOfDay(this.actStartDate);
            this.actEndDate = this.manService.getEndOfDay(this.actEndDate);
            this.manService.setActualDates(this.actStartDate, this.actEndDate);
        }
        else {
            this.actEndDate = moment(this.actEndDate).hour(0).minute(0).second(0).toDate();
            this.manService.setActualDates(this.actStartDate, this.actEndDate);
        }
        this.manService.setActAllDay(this.actAllDay);
    };
    LMCustomRecurrenceComponent.prototype.updatesDates = function () {
        this.customDates = this.manService.getCustomDates();
        this.exceptionsDates = this.manService.getExceptions();
    };
    LMCustomRecurrenceComponent.prototype.validateOverlappedDates = function () {
        var _this = this;
        if (_.size(this.customDates) > 0 && _.isDate(this.actStartDate) && _.isDate(this.actEndDate)) {
            this.hasOverlapping = _.some(this.customDates, function (d) {
                var noOverlapping = _this.actEndDate.getTime() <= d.startDate.getTime() || _this.actStartDate.getTime() >= d.endDate.getTime();
                return !noOverlapping;
            });
        }
    };
    LMCustomRecurrenceComponent.prototype.validateExceptionsDates = function () {
        var _this = this;
        if (_.size(this.exceptionsDates) > 0 && _.isDate(this.actStartDate) && _.isDate(this.actEndDate)) {
            this.hasExceeded = _.some(this.exceptionsDates, function (e) {
                var withinCustomDates = _this.actStartDate.getTime() <= e.startDate.getTime() && _this.actEndDate.getTime() > e.endDate.getTime()
                    || _this.actStartDate.getTime() < e.startDate.getTime() && _this.actEndDate.getTime() >= e.endDate.getTime();
                return !withinCustomDates;
            });
        }
    };
    LMCustomRecurrenceComponent.prototype.isValidDate = function (date) {
        return _.isDate(date) && dateTimeUtils.validateDate(date);
    };
    return LMCustomRecurrenceComponent;
}());
export { LMCustomRecurrenceComponent };
