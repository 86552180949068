import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { ManagementBaseService } from '../../../../core/services/index';
import { ServerFilterService } from '../../../../core/services/index';
import { unsubscribeInService } from '../../../../core/decorators/index';
import { IFilteredItems, ServerCompositeFilterDescriptor, PagingData, } from '../../../../core/models/index';
import { PbjExportApiService } from './pbj-export-api.service';
import { PBJBatchExport } from '../../models/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { DatePipe } from '@angular/common';
import { FileService } from '../../../../common';
import { ModalService } from '../../../../common/services/index';
import { PbjNewExportWarningDialogComponent } from '../../components/pbj-export/pbj-new-export-warning-dialog/pbj-new-export-warning-dialog/pbj-new-export-warning-dialog.component';
import { NotificationsService } from '../../../../core/components';
import { appMessages } from '../../../../app.messages';
import { PbjExportMapService } from './pbj-export-map.service';
import { PbjNewExportWarningDialogOptions } from '../../models/pbj-export/Pbj-new-export-warning-options';
var PbjExportLogManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PbjExportLogManagementService, _super);
    function PbjExportLogManagementService(apiService, datePipe, serverFilterService, orgLevelWatchService, fileService, modalService, notificationsService, mapService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.datePipe = datePipe;
        _this.serverFilterService = serverFilterService;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.fileService = fileService;
        _this.modalService = modalService;
        _this.notificationsService = notificationsService;
        _this.mapService = mapService;
        _this.parentGridExport$ = new Subject();
        _this.childGridExport$ = new Subject();
        _this.filteredData$ = new Subject();
        _this.realtimeFeedback$ = new Subject();
        _this.downloadOptionsFeedback$ = new Subject();
        _this.collapseAll$ = new Subject();
        _this.expandedBatchIds = [];
        _this.exportPerformed$ = new Subject();
        _this.pagingData = { take: 50, skip: 0 };
        _this.filterChangeSubscription = _this.serverFilterService.changes$.subscribe(function (filter) {
            _this.refreshData();
        });
        return _this;
    }
    PbjExportLogManagementService.prototype.refreshData = function () {
        var _this = this;
        if (!this.currentOrgLevel) {
            return;
        }
        this.onLoadStatusChanged(true);
        if (this.IsPBJExportCorpLevelEnabled != undefined && this.IsPBJExportCorpLevelEnabled != null && !this.IsPBJExportCorpLevelEnabled) {
            this.apiService
                .getPBJExportLogRecords(this.serverFilterService.createQuery(this.pagingData, this.sort), this.currentOrgLevel.id)
                .then(function (val) {
                _this.onLoadStatusChanged(false);
                _this.onLoaded(val);
            })
                .catch(function (reason) {
                _this.onError(reason);
            });
        }
        if (this.IsPBJExportCorpLevelEnabled != undefined && this.IsPBJExportCorpLevelEnabled != null && this.IsPBJExportCorpLevelEnabled) {
            var data = new PBJBatchExport();
            data.startDate = this.startDate;
            data.endDate = this.endDate;
            data.orgLevelId = this.currentOrgLevel.id;
            this.apiService
                .getBatchIDExportData(data)
                .then(function (val) {
                _this.onLoadStatusChanged(false);
                _.forEach(val, function (record, ind) {
                    _.forEach(_this.expandedBatchIds, function (id) {
                        if (id === record.batchId) {
                            _this.collapseAll$.next(_this.expandedBatchIds);
                        }
                    });
                });
                _this.filteredData$.next(val);
            })
                .catch(function (reason) {
                _this.onError(reason);
            });
        }
    };
    PbjExportLogManagementService.prototype.performExport = function (request) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService
            .performExport(request)
            .then(function (val) {
            _this.onLoadStatusChanged(false);
            _this.exportPerformed$.next(val);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjExportLogManagementService.prototype.performBatchExport = function (request) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService
            .performBatchExport(request)
            .then(function (val) {
            _this.onLoadStatusChanged(false);
            if (val[0].isSuccess) {
                var reqObj = new PBJBatchExport();
                reqObj.startDate = request.startDate;
                reqObj.endDate = request.endDate;
                reqObj.orgLevelId = parseInt(request.orgLevelId);
                _this.refreshData();
                _this.getParentGridExportData(reqObj);
                _this.notificationsService.success("" + appMessages.success.pbjExportStarted.title, appMessages.success.pbjExportStarted.message);
            }
            else {
                var csvData = _this.mapService.mapPbjExportCSVRecords(val);
                csvData.sort(function (a, b) {
                    return a.Error_ID - b.Error_ID ||
                        a.Error_Description.localeCompare(b.Error_Description) ||
                        a.Location.localeCompare(b.Location);
                });
                console.log(csvData);
                var options = new PbjNewExportWarningDialogOptions('Alert', csvData);
                PbjNewExportWarningDialogComponent.openDialog(options, _this.modalService, function (result) {
                    console.log('Trigger Pbj Export warning modal');
                });
            }
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjExportLogManagementService.prototype.getParentGridExportData = function (request) {
        this.onLoadStatusChanged(true);
        return this.apiService.getBatchIDExportData(request);
    };
    PbjExportLogManagementService.prototype.getChildExportData = function (request) {
        return this.apiService.getChildExportData(request);
    };
    PbjExportLogManagementService.prototype.filterRangeChanged = function (fromFilter, toFilter) {
        toFilter = new Date(toFilter);
        toFilter.setHours(23, 59, 59);
        this.hasFullDateRange = Boolean(fromFilter && toFilter);
        this.serverFilterService.removeFilter('exportDate');
        if (fromFilter) {
            this.serverFilterService.composeFilter({ field: 'exportDate', operator: 'gte', value: fromFilter });
            this.startDate = fromFilter;
        }
        if (toFilter) {
            this.serverFilterService.composeFilter({ field: 'exportDate', operator: 'lte', value: toFilter });
            this.endDate = toFilter;
        }
    };
    PbjExportLogManagementService.prototype.applyFilter = function () {
        if (this.hasFullDateRange)
            this.serverFilterService.applyFilter();
    };
    PbjExportLogManagementService.prototype.orgLevelChanged = function (orgLevel) {
        this.currentOrgLevel = orgLevel;
        this.serverFilterService.removeFilter('orgLevelId');
        this.serverFilterService.composeFilter({ field: 'orgLevelId', operator: 'eq', value: this.currentOrgLevel.id });
        this.applyFilter();
    };
    PbjExportLogManagementService.prototype.pageChanged = function (pagingData) {
        this.pagingData = pagingData;
        this.refreshData();
    };
    PbjExportLogManagementService.prototype.sortChanged = function (sort) {
        this.sort = sort;
        this.refreshData();
    };
    PbjExportLogManagementService.prototype.downloadChildExportData = function (exportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onLoadStatusChanged(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.downloadAllChildFiles(exportId)];
                    case 2:
                        file = _a.sent();
                        this.fileService.saveToFileSystem(file.blob, file.file);
                        this.onLoadStatusChanged(false);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.onLoadStatusChanged(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PbjExportLogManagementService.prototype.downloadExportSummaryData = function (exportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onLoadStatusChanged(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.downloadExportSummaryExcel(exportId)];
                    case 2:
                        file = _a.sent();
                        this.fileService.saveToFileSystem(file.blob, file.file);
                        this.onLoadStatusChanged(false);
                        return [3 /*break*/, 5];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 5];
                    case 4:
                        this.onLoadStatusChanged(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PbjExportLogManagementService.prototype.downloadBulkExportFile = function (exportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onLoadStatusChanged(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.downloadBulkExportSummary(exportId)];
                    case 2:
                        file = _a.sent();
                        this.fileService.saveToFileSystem(file.blob, file.file);
                        this.onLoadStatusChanged(false);
                        return [3 /*break*/, 5];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 5];
                    case 4:
                        this.onLoadStatusChanged(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    PbjExportLogManagementService.prototype.downloadExcelPDF = function (exportObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onLoadStatusChanged(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, 7, 8]);
                        if (!(exportObj.type === "pdf")) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.apiService.downloadPDFFile(exportObj)];
                    case 2:
                        file = _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.apiService.downloadExcelFile(exportObj)];
                    case 4:
                        file = _a.sent();
                        _a.label = 5;
                    case 5:
                        if (file.blob != null) {
                            this.fileService.saveToFileSystem(file.blob, file.file);
                        }
                        else {
                            this.notificationsService.info("Your download is being processed, please wait!");
                        }
                        this.onLoadStatusChanged(false);
                        return [3 /*break*/, 8];
                    case 6:
                        e_4 = _a.sent();
                        console.error(e_4);
                        return [3 /*break*/, 8];
                    case 7:
                        this.onLoadStatusChanged(false);
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    PbjExportLogManagementService.prototype.showBulkExportWarn = function () {
        this.notificationsService.info("The selected Batch ID does not include the Staffing Hours section.  The Staffing Hours section is required to produce a bulk export file.  Please select a Batch ID that includes the Staffing Hours section to proceed.", '', true, 7000);
    };
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjExportLogManagementService.prototype, "filterChangeSubscription", void 0);
    return PbjExportLogManagementService;
}(ManagementBaseService));
export { PbjExportLogManagementService };
