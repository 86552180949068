/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timecard-absences-editor-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../timecard-absences-editor/timecard-absences-editor.component.ngfactory";
import * as i3 from "../timecard-absences-editor/timecard-absences-editor.component";
import * as i4 from "../../../services/daily-timecard/daily-timecard-management.service";
import * as i5 from "../../../../common/services/date-time/date-time.service";
import * as i6 from "../../../../app-settings/services/app-settings-manage.service";
import * as i7 from "./timecard-absences-editor-dialog.component";
import * as i8 from "../../../../common/models/dialog-options";
import * as i9 from "../../../../common/services/modal/modal.service";
import * as i10 from "../../../models/daily-timecard/daily-absence";
var styles_TimecardAbsencesEditorDialogComponent = [i0.styles];
var RenderType_TimecardAbsencesEditorDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimecardAbsencesEditorDialogComponent, data: {} });
export { RenderType_TimecardAbsencesEditorDialogComponent as RenderType_TimecardAbsencesEditorDialogComponent };
export function View_TimecardAbsencesEditorDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { absencesEditor: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "dialog-modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "dialog-modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-timecard-absences-editor", [], null, null, null, i2.View_TimeCardAbsencesEditorComponent_0, i2.RenderType_TimeCardAbsencesEditorComponent)), i1.ɵdid(4, 245760, [[1, 4], ["absencesEditor", 4]], 0, i3.TimeCardAbsencesEditorComponent, [i4.DailyTimecardManagementService, i5.DateTimeService, i6.AppSettingsManageService], { dataItem: [0, "dataItem"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "dialog-modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.request; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.formValid || _co.isOverlappedAndUpdateSchedule); _ck(_v, 6, 0, currVal_1); }); }
export function View_TimecardAbsencesEditorDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-timecard-absences-editor-dialog", [], null, null, null, View_TimecardAbsencesEditorDialogComponent_0, RenderType_TimecardAbsencesEditorDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.TimecardAbsencesEditorDialogComponent, [i8.DialogOptions, i9.ModalService, i10.DailyAbsence], null, null)], null, null); }
var TimecardAbsencesEditorDialogComponentNgFactory = i1.ɵccf("slx-timecard-absences-editor-dialog", i7.TimecardAbsencesEditorDialogComponent, View_TimecardAbsencesEditorDialogComponent_Host_0, {}, {}, []);
export { TimecardAbsencesEditorDialogComponentNgFactory as TimecardAbsencesEditorDialogComponentNgFactory };
