import * as _ from 'lodash';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ISelectableItemContainer, SelectableItemsProducer, IDialog, ModalService, DialogOptions, KendoGridStateHelper, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { Assert } from '../../../../../framework/index';
import { LookupApiService } from '../../../../../organization/services/lookup/lookup-api.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { AttendancePointsDefinition } from '../../../../../organization/models/lookup/index';
import {
  SortDescriptor,
  orderBy
} from '@progress/kendo-data-query';
import { EmployeeSectionsPerformanceApiService } from '../../../services/employee-section-performance/employee-section-performance-api.service';
import { EmployeeSectionsAssignedDefinitions} from '../../../models/index';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'employee-sections-attendance-points-definition',
  templateUrl: 'employee-sections-attendance-points-definition.component.html',
  styleUrls: ['employee-sections-attendance-points-definition.component.scss']
})
export class EmployeeSectionsAttendancePointsDefinitionComponent implements IDialog, OnInit {

  public get initialized(): boolean {
    return this.m_initialized;
  }
  public empId: number;
  public selectableAttendancePoints: ISelectableItemContainer<AttendancePointsDefinition>[];
  public startDate: Date;
  public endDate: Date;
  public categoryName: string;
  public isAnyItemSelected: boolean = false;
  public selectedDate: Date;
  public options: DialogOptions;
  public dialogResult: boolean;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public isLoading: boolean;
  public hasAssignedBuyBack: boolean = false;
  public hasSelectedBuyback: boolean = false;


  private modalService: ModalService;
  private selectableItemsProducer: SelectableItemsProducer;
  private lookupApiService: LookupApiService;
  private employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService
  public selectedRowIndex: number;
  public employeeassigneddefintions: EmployeeSectionsAssignedDefinitions[];
  private buyBack : string ="Buy Back";
  private m_initialized: boolean;

  constructor(lookupApiService: LookupApiService,
    selectableItemsProducer: SelectableItemsProducer,
    options: DialogOptions, modalService: ModalService,
    employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService,
    private changeDetector: ChangeDetectorRef) {
    Assert.isNotNull(selectableItemsProducer, 'selectableItemsProducer');
    this.selectableItemsProducer = selectableItemsProducer;
    Assert.isNotNull(options, 'options');
    this.options = options;
    Assert.isNotNull(modalService, 'modalService');
    this.lookupApiService = lookupApiService;
    this.modalService = modalService;
    this.dialogResult = false;
    this.employeeSectionsPerformanceApiService = employeeSectionsPerformanceApiService;
    this.selectedDate = new Date();
    this.isLoading = false;
  }

  public ngOnInit(): void {
    this.m_initialized = true;
    if (!_.isNil(this.empId)) {
      this.loadSubsection();
    }
  }

  public onFilterDateChanged(startDate: any): void {
    this.selectableAttendancePoints.filter(x=> x.selected === true).forEach(item =>{ item.selected = false;});
    this.startDate = moment(startDate).startOf('day').toDate();
    this.hasAssignedBuyBack = false;
    this.hasSelectedBuyback = false;
    this.isAnyItemSelected = false;
    this.loadAssignedDefintionData();
  }

  private loadAssignedDefintionData(): void {
    if(this.startDate == undefined){
    this.startDate = moment(new Date()).startOf('day').toDate()
    }
    this.categoryName = 'Buy Back'
    this.employeeSectionsPerformanceApiService.getAssignedDefinitions(this.empId, this.startDate, this.categoryName).then((res) => {
      this.attendanceassignedsDefinitions(res);
    })
  }


  public get selectedIds(): any[] {
    let pointIds: any[] = [];
    this.selectableAttendancePoints.forEach((i: ISelectableItemContainer<AttendancePointsDefinition>) => {
      if (i.selected) pointIds.push(i.item.id);
    });
    return pointIds;
  }

  public set attendancePointsDefinitions(attendancePointsDefinitions: AttendancePointsDefinition[]) {
    if (attendancePointsDefinitions !== null) {
      this.selectableAttendancePoints = this.selectableItemsProducer.produceSelectable<AttendancePointsDefinition>(attendancePointsDefinitions);
      this.refreshGrid();
    }
  }

  public attendanceassignedsDefinitions(attendanceassignedsDefinitions: EmployeeSectionsAssignedDefinitions[]) {
    if(attendanceassignedsDefinitions.length > 0 ){
    for (let i = 0; i < attendanceassignedsDefinitions.length; i++) {
      if (attendanceassignedsDefinitions[i].categoryName == 'Buy Back' && attendanceassignedsDefinitions[i].dateOn.valueOf() == this.startDate.valueOf()) {
        this.hasAssignedBuyBack = true;
      }
      else {
        this.hasAssignedBuyBack = false;
      }
    }

  }
  }

  public onItemSelectionChanged(e, dataitem): void {
    dataitem.selected=e.currentTarget.checked;
    this.isAnyItemSelected = this.selectableAttendancePoints.some((i: ISelectableItemContainer<AttendancePointsDefinition>) => i.selected);
    if(!this.hasSelectedBuyback && dataitem.item.category.name == this.buyBack){
      this.hasSelectedBuyback =true;
    } else if(this.hasSelectedBuyback && dataitem.item.category.name == this.buyBack) {
      this.hasSelectedBuyback = false;
    }
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  isCheckboxEnabled(dataItem){
    if(this.hasAssignedBuyBack  && dataItem.item.category.name == this.buyBack)
    {
      return true;
    }
    else if(dataItem.selected)
    {
      return null;
    } else{
      return (dataItem.selected === false && this.hasSelectedBuyback &&  dataItem.item.category.name == this.buyBack) ? true : null;

    } 
  }

  isMouseover(dataitem) {
    let isValid: boolean = this.isCheckboxEnabled(dataitem) ? true : false;
    if (isValid) {
      if (this.hasSelectedBuyback && !this.hasAssignedBuyBack) {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = false;
        ConfirmDialog2Component.openDialog(
          'Warning',
          'Please note that only one Buy Back transaction can be added per day.',
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.isLoading = false;
            }
          }, options);
      }
      else if (this.hasAssignedBuyBack) {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = false;
        ConfirmDialog2Component.openDialog(
          'Warning',
          'Only one BuyBack transaction per day is supported. A BuyBack transaction already exists for this day.',
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.isLoading = false;
            }
          }, options);
      }
    }

  }


  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public loadSubsection(): void {
    this.startProgress();
    this.lookupApiService.getAttendancePointsDefinitions(null, this.empId)
      .then((attendancePointsDefinitions: AttendancePointsDefinition[]) => {
        this.attendancePointsDefinitions = attendancePointsDefinitions;
        this.stopProgress();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
    this.loadAssignedDefintionData();
  }

  protected startProgress(): void {
    this.isLoading = true;
  }

  protected stopProgress(): void {
    this.isLoading = false;
  }

  private refreshGrid(): void {
    if (!this.selectableAttendancePoints) {
      this.gridView = null;
      return;
    }
    this.gridView = {
      data: orderBy(this.selectableAttendancePoints, this.sort),
      total: this.selectableAttendancePoints.length
    };
  }
}
