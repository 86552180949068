<slx-spinner [show]="isLoading">

  <form #gridForm="ngForm" class="content-form">

    <div class="modal-body pad-0">

      <div class="model-container">
        <div class="controls-holder">
          <slx-benefit-payroll-deduction [groupName]="groupName"
          [canMapPayroll]="canMapPayroll"
          [effectiveDate]="effectiveDate" 
          [dedStartDate]="dedStartDate"
          [dedEndDate]="dedEndDate"
          [startDate]="startDate"
          [endDate]="endDate"
          [payrollDedStartDate]="payrollDedStartDate"
          [payrollDedEndDate]="dedEndDate" 
          (dedEndDateChange)="OnDeductionEndDateChange($event)"
          
          (dedStartDateChange)="OnDeductionStartDateChange($event)"
        >
        </slx-benefit-payroll-deduction>

      

        <p class="flex form__ctrl">
          <label class="form__label">Select a Benefit Tier</label>
          <span class="flex flex__grow form-dropdown-control">
            <slx-dropdown-list class="slx-wide control__width" [options]="tiers" [ngModel]="selectedTier"
              (ngModelChange)="onChangeTier($event)" name="tiersList" required>
            </slx-dropdown-list>
          </span>
        </p>

        <span class="sub-caption" *ngIf="selectedTier">Select an Option</span>

        <p class="flex form__ctrl" *ngIf="selectedTier">
          <label class="form__label">Employee % Contribution</label>
          <span class="flex flex__grow form-dropdown-control flex__wrap">
            <slx-number class="slx-wide slx-input-number"
              [min]="minAmt" [max]="settings401K.maxEmployeePercentContribution"
              [slxMin]="minAmt" [slxMax]="settings401K.maxEmployeePercentContribution"
              [step]="stepPercent" [format]="'n2'"
              [decimals]="2"
              [autoCorrect]='settings401K.employeePercentageContribution !=null ? false : true'  
              name="empCont" 
              #empContField="ngModel"
              [(ngModel)]="settings401K.employeePercentageContribution"
              [readonly]="!hasSelectedValidTier401K || settings401K.employeeContribution || (settings401K.employeeContribution === 0)"
              [required]="(settings401K.employeeContribution || settings401K.employeeContribution === 0) ? false : true"
              >
            </slx-number>
            <span *ngIf="empContField.errors" class="slx-error-block pull-left">
              <span *ngIf="empContField.errors.required" errorMessage for="required"></span>
              <span *ngIf="empContField.errors.min" errorMessage>Must be greater than {{ minAmt }}</span>
              <span *ngIf="empContField.errors.max" errorMessage>Must be less than {{ settings401K.maxEmployeePercentContribution }}</span>
            </span>
          </span>

        </p>
        <p class="flex form__ctrl" *ngIf="selectedTier">
          <label class="form__label">Employee Contribution</label>
          <span class="flex flex__grow form-dropdown-control flex__wrap" >
            <slx-number class="slx-wide slx-input-number" 
              [step]="stepPercent" [format]="'c2'"
              [decimals]="2"
              [min]="minAmt" 
              [max]="maxAmt"
              [autoCorrect]='settings401K.employeeContribution !=null ? false : true' 
              name="empCont1" 
              #empContAmountField="ngModel"
              [(ngModel)]="settings401K.employeeContribution"
              [readonly]="!hasSelectedValidTier401K || settings401K.employeePercentageContribution || (settings401K.employeePercentageContribution === 0)"
              [required]="(settings401K.employeePercentageContribution || settings401K.employeePercentageContribution === 0) ? false : true"
              >
            </slx-number>
            <div>
            <span *ngIf="empContAmountField.errors" class="slx-error-block error-box">
              <span *ngIf="empContAmountField.errors.required" errorMessage for="required"></span>
              <span *ngIf="empContAmountField.errors.min" errorMessage>Must be greater than {{ minAmt }}</span>
              <span *ngIf="empContAmountField.errors.max" errorMessage>Must be less than {{ maxAmt }}</span>
            </span>
          </div>
          </span>
        </p>
        <p class="flex form__ctrl" *ngIf="selectedTier">
          <label class="form__label">Limit Amount</label>
          <span class="flex flex__grow form-dropdown-control control__width">
            <slx-number class="slx-wide slx-input-number control__width"
              [(ngModel)]="settings401K.limitAmount"
              [readonly]="true"
              name="limitAmount"
            ></slx-number>
          </span>
        </p>

      </div>
    </div>

    </div>
    <div class="modal-footer">
      <span id="enroll-button" class="K401-info" [ngClass]="{'hide_item_tip':hasSelectedValidTier401K}">

        <button [disabled]="gridForm.invalid || !hasSelectedValidTier401K || hasDedError"
            type="button"
            (click)="onEnroll()"
            class="btn green"><i class="fas fa-save slx-button__icon" aria-hidden="true"></i>Enroll</button>
        <span class="K401__btn-box" *ngIf="!hasSelectedValidTier401K" [slxTooltip]="get401kToolTip()"
            tipPosition="top"></span>
            <div></div>
      </span>
        <button type="button"
          class="btn red"
            (click)="onCancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
    </div>
  </form>

</slx-spinner>
