export interface IPBJExportResultFlagEnabled {
    exportId: number;
    isSuccess: boolean;
    errorMessage: string;
    xMLData : string;
    organizationId: number;
    organizationName:string;
    batchId: number;
    errorNumber: number;
  }

  export class PBJExportResultFlagEnabled {
    public exportId: number;
    public isSuccess: boolean;
    public errorMessage: string;
    public xMLData : string;
    public organizationId: number;
    public organizationName:string;
    public batchId: number
    public errorNumber: number;
  }
