import { OpenShiftDetails } from './../../../models/open-shift-management/open-shift-details';
import { DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import * as _ from 'lodash';
var EditOpenShiftCountComponent = /** @class */ (function () {
    function EditOpenShiftCountComponent(options, modalService, openShiftDetails) {
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
        this.adjustedShiftCount = openShiftDetails.adjustedShiftCount;
        this.openShiftDetails = openShiftDetails;
    }
    Object.defineProperty(EditOpenShiftCountComponent.prototype, "currenOpenShiftCount", {
        get: function () {
            if (!this.openShiftDetails)
                return 0;
            return this.adjustedShiftCount - this.openShiftDetails.scheduledShiftCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditOpenShiftCountComponent.prototype, "scheduledShiftCount", {
        get: function () {
            if (!this.openShiftDetails)
                return 0;
            var scheduledShiftCount = 0;
            if (this.openShiftDetails.hasPartialShift) {
                _.forEach(this.openShiftDetails.partialShifts, function (shiftInDetails) {
                    scheduledShiftCount += shiftInDetails.scheduledShiftCount;
                });
            }
            else {
                scheduledShiftCount = this.openShiftDetails.scheduledShiftCount;
            }
            return scheduledShiftCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditOpenShiftCountComponent.prototype, "scheduledHours", {
        get: function () {
            if (!this.openShiftDetails)
                return 0;
            var scheduledShiftHours = 0;
            if (this.openShiftDetails.hasPartialShift) {
                _.forEach(this.openShiftDetails.partialShifts, function (shiftInDetails) {
                    scheduledShiftHours += shiftInDetails.scheduledHours;
                });
            }
            else {
                scheduledShiftHours = this.openShiftDetails.scheduledHours;
            }
            return scheduledShiftHours;
        },
        enumerable: true,
        configurable: true
    });
    EditOpenShiftCountComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EditOpenShiftCountComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return EditOpenShiftCountComponent;
}());
export { EditOpenShiftCountComponent };
