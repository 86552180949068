import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
var GeolocationPlace = /** @class */ (function () {
    function GeolocationPlace(place) {
        this.place = place;
        this.location = { lat: this.place.lat, lng: this.place.lng };
    }
    Object.defineProperty(GeolocationPlace.prototype, "lat", {
        get: function () {
            return this.place.lat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationPlace.prototype, "lng", {
        get: function () {
            return this.place.lng;
        },
        enumerable: true,
        configurable: true
    });
    return GeolocationPlace;
}());
export { GeolocationPlace };
var ViewModeSettings = /** @class */ (function () {
    function ViewModeSettings(lat, lng, z) {
        this.lat = lat;
        this.lng = lng;
        this.zoom = z;
    }
    return ViewModeSettings;
}());
export { ViewModeSettings };
var BasicModeSettings = /** @class */ (function () {
    function BasicModeSettings(r, z) {
        this.circleRadius = r;
        this.zoom = z;
    }
    return BasicModeSettings;
}());
export { BasicModeSettings };
var SearchModeSettings = /** @class */ (function (_super) {
    tslib_1.__extends(SearchModeSettings, _super);
    function SearchModeSettings(s, r, z) {
        var _this = _super.call(this, r, z) || this;
        _this.addressForSearch = s;
        return _this;
    }
    return SearchModeSettings;
}(BasicModeSettings));
export { SearchModeSettings };
var CoordsModeSettings = /** @class */ (function (_super) {
    tslib_1.__extends(CoordsModeSettings, _super);
    function CoordsModeSettings(lat, lng, r, z) {
        var _this = _super.call(this, r, z) || this;
        _this.lat = lat;
        _this.lng = lng;
        return _this;
    }
    return CoordsModeSettings;
}(BasicModeSettings));
export { CoordsModeSettings };
var MapCircleChangedEvent = /** @class */ (function () {
    function MapCircleChangedEvent(lat, lng, dragged) {
        this.lat = lat;
        this.lng = lng;
        this.dragged = dragged;
    }
    return MapCircleChangedEvent;
}());
export { MapCircleChangedEvent };
var GeolocationEntity = /** @class */ (function () {
    function GeolocationEntity() {
        this.id = 0;
        this.orgLevelId = 0;
        this.orgLevelName = '';
        this.latitude = 0;
        this.longitude = 0;
        this.distance = 0;
        this.unitOfMeasure = 1;
        this.overriddenLatitude = 0;
        this.overriddenLongitude = 0;
        this.overriddenDistance = 0;
        this.overriddenUnitOfMeasure = 0;
        this.isOverridden = false;
        this.isActive = true;
        this.isAllowPunchWhenLocationServiceDisabled = true;
        this.storedDistance = new Map();
        this.storedDates = new Map();
    }
    Object.defineProperty(GeolocationEntity.prototype, "currentLat", {
        get: function () {
            return this.isOverridden ? this.overriddenLatitude : this.latitude;
        },
        set: function (v) {
            if (this.isOverridden) {
                this.overriddenLatitude = v;
                return;
            }
            this.latitude = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEntity.prototype, "currentLng", {
        get: function () {
            return this.isOverridden ? this.overriddenLongitude : this.longitude;
        },
        set: function (v) {
            if (this.isOverridden) {
                this.overriddenLongitude = v;
                return;
            }
            this.longitude = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEntity.prototype, "currentDistance", {
        get: function () {
            return this.isOverridden ? this.overriddenDistance : this.distance;
        },
        set: function (v) {
            if (this.isOverridden) {
                this.overriddenDistance = v;
                return;
            }
            this.distance = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEntity.prototype, "currentUnitOfMeasure", {
        get: function () {
            return this.isOverridden ? this.overriddenUnitOfMeasure : this.unitOfMeasure;
        },
        set: function (v) {
            if (this.isOverridden) {
                this.overriddenUnitOfMeasure = v;
                return;
            }
            this.unitOfMeasure = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEntity.prototype, "localizedCurrentDistance", {
        get: function () {
            var key = this.currentDistance + "_" + this.currentUnitOfMeasure;
            if (this.storedDistance.has(key)) {
                return this.storedDistance.get(key);
            }
            var units = ['Meters', 'Yards'];
            var unitOfMeasure = 'Unknown type';
            if (this.currentUnitOfMeasure > 0) {
                unitOfMeasure = units[this.currentUnitOfMeasure - 1];
            }
            var distance = this.currentDistance + " " + unitOfMeasure;
            this.storedDistance.set(key, distance);
            return distance;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEntity.prototype, "localizedCreatedDate", {
        get: function () {
            if (this.storedDates.has(this.createdAt)) {
                return this.storedDates.get(this.createdAt);
            }
            var date = moment(this.createdAt).format(appConfig.dateFormat);
            this.storedDates.set(this.createdAt, date);
            return date;
        },
        enumerable: true,
        configurable: true
    });
    GeolocationEntity.prototype.location = function () {
        return { lat: this.currentLat, lng: this.currentLng };
    };
    return GeolocationEntity;
}());
export { GeolocationEntity };
var GeolocationEvents = /** @class */ (function () {
    function GeolocationEvents() {
    }
    GeolocationEvents.ADD = 'ADD';
    GeolocationEvents.UPDATE = 'UPDATE';
    GeolocationEvents.SAVE = 'SAVE';
    GeolocationEvents.CANCEL = 'CANCEL';
    GeolocationEvents.PDF = 'PDF';
    GeolocationEvents.EXCEL = 'EXCEL';
    return GeolocationEvents;
}());
export { GeolocationEvents };
var IGeolocationEvent = /** @class */ (function () {
    function IGeolocationEvent() {
    }
    return IGeolocationEvent;
}());
export { IGeolocationEvent };
var GeolocationEvent = /** @class */ (function () {
    function GeolocationEvent(m_type, m_data) {
        this.m_type = m_type;
        this.m_data = m_data;
    }
    GeolocationEvent.createAdd = function (data) {
        if (data === void 0) { data = null; }
        return new GeolocationEvent(GeolocationEvents.ADD, data);
    };
    GeolocationEvent.createUpdate = function (data) {
        if (data === void 0) { data = null; }
        return new GeolocationEvent(GeolocationEvents.UPDATE, data);
    };
    GeolocationEvent.createSave = function (data) {
        if (data === void 0) { data = null; }
        return new GeolocationEvent(GeolocationEvents.SAVE, data);
    };
    GeolocationEvent.createCancel = function (data) {
        if (data === void 0) { data = null; }
        return new GeolocationEvent(GeolocationEvents.CANCEL, data);
    };
    GeolocationEvent.createPDF = function (data) {
        if (data === void 0) { data = null; }
        return new GeolocationEvent(GeolocationEvents.PDF, data);
    };
    GeolocationEvent.createExcel = function (data) {
        if (data === void 0) { data = null; }
        return new GeolocationEvent(GeolocationEvents.EXCEL, data);
    };
    Object.defineProperty(GeolocationEvent.prototype, "data", {
        get: function () {
            return this.m_data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEvent.prototype, "isAdd", {
        get: function () {
            return this.m_type === GeolocationEvents.ADD;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEvent.prototype, "isUpdate", {
        get: function () {
            return this.m_type === GeolocationEvents.UPDATE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEvent.prototype, "isSave", {
        get: function () {
            return this.m_type === GeolocationEvents.SAVE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEvent.prototype, "isCancel", {
        get: function () {
            return this.m_type === GeolocationEvents.CANCEL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEvent.prototype, "isExportToPDF", {
        get: function () {
            return this.m_type === GeolocationEvents.PDF;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationEvent.prototype, "isExportToExcel", {
        get: function () {
            return this.m_type === GeolocationEvents.EXCEL;
        },
        enumerable: true,
        configurable: true
    });
    return GeolocationEvent;
}());
export { GeolocationEvent };
var GeolocationDialogOptions = /** @class */ (function () {
    function GeolocationDialogOptions(latitude, longitude, mapWidth, mapHeight, mapZoom) {
        if (mapWidth === void 0) { mapWidth = 300; }
        if (mapHeight === void 0) { mapHeight = 200; }
        if (mapZoom === void 0) { mapZoom = 15; }
        this.latitude = latitude;
        this.longitude = longitude;
        this.mapWidth = mapWidth;
        this.mapHeight = mapHeight;
        this.mapZoom = mapZoom;
    }
    return GeolocationDialogOptions;
}());
export { GeolocationDialogOptions };
