import * as tslib_1 from "tslib";
import * as _ from 'lodash';
export function subscribeToOrgLevel(orgLevel$, getCurrentOrglevel, callback) {
    var _this = this;
    return orgLevel$
        .filter(function (o) { return o && _.isFinite(o.id); })
        .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        var currentOrglevel;
        return tslib_1.__generator(this, function (_a) {
            currentOrglevel = getCurrentOrglevel();
            if (_.isFinite(_.get(currentOrglevel, 'id')) && currentOrglevel.id === orgLevel.id)
                return [2 /*return*/];
            callback(orgLevel);
            return [2 /*return*/];
        });
    }); });
}
