<slx-spinner [show]="isLoading">
<form #passForm="ngForm">
<div class="toolbar slx-content-toolbar-indents" [ngClass]="{'hidden': hideToolbar}">
    <div class="toolbar-section buttons-section">
        <button class="save-btn btn btn-default theme-button-apply margin-r"
            type="button" [disabled]="!canSave" (click)="saveData()">
            <i class="fa fa-save" aria-hidden="true"></i>
            Save
        </button>
        <button class="btn btn-default theme-button-cancel" [disabled]="!hasChanges"
            type="button" (click)="discardChanges()">
            <i class="fa fa-ban" aria-hidden="true"></i>
            Discard
        </button>
    </div>
</div>
<div class="title-container">
    <i class="key fas fa-key fa-4x" aria-hidden="true"></i>
    <div class="title-text">
        <span class="title">Help keep your users Safe and Secure</span><br>
        <span>Using the fields below you can control the password settings your users will adhere to when creating new passwords to access Smartlinx.<br>
            You can configure settings to control how many characters a password must be, if and how many special characters or capital letters must be used and when or if the users password will expire</span>
    </div>
</div>


<div class="main-container">
    <div class="field">
        <slx-input-decorator>
            <!--    <input slx-input type="text" required="true" filled maxlength="50" name="name" placeholder="Minimum Password Length"
                [(ngModel)]="setting?.userPasswordComplexity.pwdLength"> -->
             <slx-kendo-number slx-input
                class="form-control"
                name="pwdLength"
                placeholder="Minimum Password Length"
                [decimals]="0"
                [min]="0"
                [required]="'true'"
                [(ngModel)]="setting?.userPasswordComplexity.pwdLength"
                [autoCorrect]="true"
            ></slx-kendo-number>
            <span errorMessage for="required"></span>
            <span errorMessage for="number"></span>
        </slx-input-decorator>
    </div>
    <div class="field">
        <slx-input-decorator>
            <slx-kendo-number slx-input
                name="specialCount"
                class="form-control"
                placeholder="Count of Special Characters"
                [decimals]="0"
                [min]="0"
                [autoCorrect]="true"
                [required]="'true'"
                [(ngModel)]="setting?.userPasswordComplexity.specialCount"
            ></slx-kendo-number>
            <span errorMessage for="required"></span>
            <span errorMessage for="number"></span>
          </slx-input-decorator>
    </div>

    <div class="field">
        <slx-input-decorator>
            <slx-kendo-number slx-input
                name="capitalCount"
                class="form-control"
                placeholder="Count of Capital Letters"
                [decimals]="0"
                [min]="0"
                [autoCorrect]="true"
                [required]="'true'"
                [(ngModel)]="setting?.userPasswordComplexity.capitalCount"
            ></slx-kendo-number>
            <span errorMessage for="required"></span>
            <span errorMessage for="number"></span>
          </slx-input-decorator>
    </div>

    <div class="field align-offset">
        <slx-input-decorator class="copy" className="slx-form-group slx-no-border slx-no-label small-font">
            <slx-checkbox-input slx-input name="lifeTime" fieldName="lifeTime"
                [(ngModel)]="lifetimeEnabled"
                caption="Use Life Time" placeholder=""></slx-checkbox-input>
        </slx-input-decorator>
    </div>
    <div class="field" *ngIf="lifetimeEnabled">
        <slx-input-decorator>
            <slx-kendo-number slx-input
                name="daysCount"
                class="form-control"
                placeholder="Active Days"
                [min]="1"
                [slxMin]="1"
                [decimals]="0"
                [autoCorrect]="true"
                [required]="setting?.userPasswordLifetime.enabled"
                [(ngModel)]="setting?.userPasswordLifetime.daysCount"
            ></slx-kendo-number>
            <span errorMessage for="required"></span>
            <span errorMessage for="number"></span>
            <span errorMessage for="min">The value must be greater than or equal to 1</span>
            <!-- <span slx-input-hint>How many days a password can be active</span> -->
          </slx-input-decorator>
        </div>
</div>
</form>
</slx-spinner>
