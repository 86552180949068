import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../core/decorators/index';
import { ColumnManagementService, StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';

import { AccrualsBalancesManagementService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-accruals-balances',
  templateUrl: 'accruals-balances.component.html',
  styleUrls: ['accruals-balances.component.scss'],
  providers: [ColumnManagementService, StateManagementService, AccrualsBalancesManagementService]
})
export class AccrualsBalancesComponent implements OnInit, OnDestroy {

  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private stateManagement: StateManagementService,
    private accrualsManagementService: AccrualsBalancesManagementService
  ) { }

  public ngOnInit() {
    this.stateManagement.init('AccrualsBalancesComponent');
    this.accrualsManagementService.init();
    this.subscriptions.loading = this.accrualsManagementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  public ngOnDestroy(): void {
    this.accrualsManagementService.destroy();
  }
}
