export interface ILoginEvent {
  username: string;
  alias: string;
  hasAliasChanged: boolean;
  isRenewAuthentication: boolean;
  email: string;
  roles: string[];
}

export class LoginEvent {
  public username: string;
  public alias: string;
  public hasAliasChanged: boolean;
  public isRenewAuthentication: boolean;
  public email: string;
  public roles: string[];

  constructor(username: string, email: string, roles: string[], alias: string, hasAliasChanged: boolean, isRenewAuthentication: boolean) {
    this.username = username;
    this.email = email;
    this.roles = roles;
    this.alias = alias;
    this.hasAliasChanged = hasAliasChanged;
    this.isRenewAuthentication = isRenewAuthentication;
  }
}

export class OAuth2AuthorizeRequest {

  public clientId: string;
  public redirectUri: string;
  public responseType: string;
  public scope: string;
  public state: string;

  constructor(clientId: string, redirectUri: string, responseType: string, scope: string, state: string) {
    this.clientId = clientId;
    this.redirectUri = redirectUri;
    this.responseType = responseType;
    this.scope = scope;
    this.state = state;
  }
}
