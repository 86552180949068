<section class="benefit-provider">
  <slx-benefit-details-providers-menu></slx-benefit-details-providers-menu>
  <ng-container *ngIf="hasProviders">
    <header class="benefit-provider__header flex justify-content__space-between">
      <div class="flex align-items__center benefit-provider__hd-line">
        <span class="benefit-provider__sm-txt benefit-provider__padd-r">As of:</span>
        <span class="flex flex-direction__column">
          <slx-datepicker class="slx-padd-r benefit-provider__date" #effectiveDate="ngModel"
            [minDate]="minEffectiveDate"
            [maxDate]="maxEffectiveDate"
            [(ngModel)]="providerPlanDate"
            (ngModelChange)="onChangeProviderPlanDate()"
            [readonly]="isEditMode"
          ></slx-datepicker>
          <span *ngIf="effectiveDate.errors" class="slx-error-block error-box">
            <span *ngIf="effectiveDate.errors.date" errorMessage>Date is out of valid range</span>
            <span *ngIf="effectiveDate.errors.minDate" errorMessage>Min date is {{ minEffectiveDate | amDateFormat: appConfig.dateFormat }}</span>
            <span *ngIf="effectiveDate.errors.maxDate" errorMessage>Max date is {{ maxEffectiveDate | amDateFormat: appConfig.dateFormat }}</span>
          </span>
        </span>
        <slx-daterange-list [listRanges]="benefitLines" [valueIconClass]="valueIconClass" [(ngModel)]="selectedBenefitLine"
          (ngModelChange)="onChangeBenefitLine($event)" [readonly]="isEditMode"></slx-daterange-list>
        <strong class="benefit-provider__txt benefit-provider__padd-r">Applied to: {{ providerOrglevelName }}</strong>
      </div>
      <div class="flex align-items__center benefit-provider__hd-line">
        <ng-container *ngIf="!isEditMode">
          <button type="button"
            class="slx-button slx-blue slx-with-icon slx-margin-r slx-mobile-adapted"
            [disabled]="!canEdit"
            (click)="onEdit()"
          >
            <i aria-hidden="true" class="fas fa-edit slx-button__icon"></i>
            <span class="slx-button__text">Edit</span>
          </button>
          <button type="button"
            class="slx-button slx-red slx-with-icon slx-mobile-adapted"
            [disabled]="!canDelete"
            (click)="onDelete()"
          >
            <i aria-hidden="true" class="fas fa-trash-alt slx-button__icon"></i>
            <span class="slx-button__text">Delete</span>
          </button>
        </ng-container>
        <ng-container *ngIf="isEditMode ">
          <span *ngIf="manService.hasOptionsWithoutMandatoryFields" class="save-error-label">Please populate all required fields under Coverage Options.</span>
          <button type="button"
            class="slx-button slx-blue slx-with-icon slx-margin-r slx-mobile-adapted"
            [disabled]="!canSave || !canAddCovOptions"
            (click)="onSave()">
            <i aria-hidden="true" class="fas fa-save slx-button__icon"></i>
            <span class="slx-button__text">Save</span>
          </button>
          <button type="button" class="slx-button slx-red slx-with-icon slx-mobile-adapted"
            (click)="onCancel()">
            <i aria-hidden="true" class="fas fa-times slx-button__icon"></i>
            <span class="slx-button__text">Cancel</span>
          </button>
        </ng-container>
      </div>
    </header>
    <section class="benefits-scroll">
    <slx-collapsible-section class="benefit-provider__line" title="Provider Info" mode="white" [expanded]="true">
      <ng-container body>
        <slx-benefit-details-provider-info-line></slx-benefit-details-provider-info-line>
      </ng-container>
    </slx-collapsible-section>

    <slx-collapsible-section class="benefit-provider__line" [emptyHeader]="true" mode="white" [expanded]="true">
      <ng-container header>
        <slx-benefit-details-benefit-info-menu></slx-benefit-details-benefit-info-menu>
      </ng-container>
      <ng-container body>
        <slx-benefit-details-benefit-info-line></slx-benefit-details-benefit-info-line>
      </ng-container>
    </slx-collapsible-section>

    <slx-collapsible-section class="benefit-provider__line" title="Coverage Options" mode="white" [expanded]="true">
      <ng-container header>
        <p class="benefit-provider__line-header">
          <slx-switcher *ngIf="!isCreatingNew && (calcMethod?.isFormula || calcMethod?.isFormulaWithOption)"
            className="slx-switcher-green" [(ngModel)]="isShownFormula" (ngModelChange)="onChangeShowFormula()"
            label="Show Formula" labelOn="Yes" labelOff="No" name="showExpiredPlans"></slx-switcher>
          <button type="button" class="slx-button slx-blue slx-with-icon slx-margin-r"
            *ngIf="isEditMode && calcMethod?.isOptionsRates" [disabled]="!canAddCovOptions" (click)="onAdd()">
            <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
            <span class="slx-button__text">Add New</span>
          </button>
        </p>
      </ng-container>
      <ng-container body>
        <ng-container *ngIf="calcMethod?.isOptionsRates">
          <slx-benefit-details-coverage-calc-options></slx-benefit-details-coverage-calc-options>
        </ng-container>
        <ng-container *ngIf="calcMethod?.isFlat">
          <slx-benefit-details-coverage-calc-flat></slx-benefit-details-coverage-calc-flat>
        </ng-container>
        <ng-container *ngIf="calcMethod?.isFormula">
          <slx-benefit-details-coverage-calc-formula></slx-benefit-details-coverage-calc-formula>
        </ng-container>
        <ng-container *ngIf="calcMethod?.is401K">
          <slx-benefit-details-coverage-calc-401K></slx-benefit-details-coverage-calc-401K>
        </ng-container>
        <ng-container *ngIf="calcMethod?.isFormulaWithOption">
          <slx-benefit-details-coverage-calc-formula-with-option></slx-benefit-details-coverage-calc-formula-with-option>
        </ng-container>
      </ng-container>
    </slx-collapsible-section>
  </section>
  </ng-container>
  <ng-container *ngIf="!hasProviders">
    <p class="benefit-provider__no-providers">There are no benefit providers in this group. Create it by clicking on button.</p>
  </ng-container>
</section>
