import * as tslib_1 from "tslib";
import { TimelineHelper } from './timeline-helper';
var TimelineExpandHelper = /** @class */ (function (_super) {
    tslib_1.__extends(TimelineExpandHelper, _super);
    function TimelineExpandHelper(config) {
        return _super.call(this, config) || this;
    }
    TimelineExpandHelper.prototype.updateSlide = function (timelineTotWidth, forward) {
        var translateValue = this.getTranslateValue(this.config.eventsWrapper.nativeElement);
        if (forward) {
            this.translateTimeline(translateValue - this.defaultTimelineWrapperWidth + this.eventsMinDistance, this.defaultTimelineWrapperWidth - timelineTotWidth);
        }
        else {
            this.translateTimeline(translateValue + this.defaultTimelineWrapperWidth - this.eventsMinDistance, null);
        }
    };
    TimelineExpandHelper.prototype.updateFillingStartByDate = function (date) {
        var distanceNorm = this.calcDistanceNorm(this.startDate, date);
        var scaleValue = 0;
        if (distanceNorm > 0) {
            scaleValue = (distanceNorm + this.elementWidth / 2) / (this.eventsWrapperWidth);
        }
        this.setTransformValue(this.config.fillingLineStart.nativeElement, 'scaleX', scaleValue);
        this.translateTimeline(this.parentWidth / 2 - distanceNorm, null);
    };
    TimelineExpandHelper.prototype.updateFillingEndByDate = function (date) {
        var distanceFromStartNorm = this.calcDistanceNorm(this.startDate, date);
        var distanceNorm = this.calcDistanceNorm(date, this.endDate);
        var scaleValue = 0;
        if (distanceNorm > 0) {
            scaleValue = (distanceNorm - this.elementWidth / 2) / this.eventsWrapperWidth;
        }
        this.setTransformValue(this.config.fillingLineEnd.nativeElement, 'scaleX', scaleValue);
        this.translateTimeline(this.parentWidth / 2 - distanceFromStartNorm, null);
    };
    TimelineExpandHelper.prototype.initTimelineImpl = function (timeLines, rtConfig) {
        this.eventsMinDistance = rtConfig.eventsMinDistance ? rtConfig.eventsMinDistance : 40;
        this.eventsMinLapse = this.minLapse(timeLines);
        // assign a width to the timeline
        this.setTimelineWidth(timeLines);
        // assign a left position to the single events along the timeline
        this.setDatePosition(timeLines);
        this.setTransformValue(this.config.eventsWrapper.nativeElement, 'translateX', '0px');
        this.updateFillingEndByDate(this.endDate);
        this.updateFillingStartByDate(this.startDate);
    };
    TimelineExpandHelper.prototype.setTimelineWidth = function (elements) {
        var timeSpan = this.dayDiff(this.startDate, this.endDate);
        this.norm = this.eventsMinDistance / this.eventsMinLapse;
        var width = timeSpan * this.norm;
        if (width < this.parentWidth) {
            width = this.parentWidth;
            this.norm = (width - this.offset * 2) / timeSpan;
            this.eventsMinDistance = this.norm * this.eventsMinLapse;
        }
        this.eventsWrapperWidth = width;
        var aHref = this.config.eventsWrapper.nativeElement.querySelectorAll('a.selected')[0];
        this.updateFilling(aHref);
        this.updateTimelinePosition(aHref);
        return this.eventsWrapperWidth;
    };
    TimelineExpandHelper.prototype.setDatePosition = function (elements) {
        var timelineEventsArray = this.config.timelineEvents.toArray();
        if (timelineEventsArray.length === 0) {
            return;
        }
        var i = 0;
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var component = elements_1[_i];
            var distanceNorm = this.calcDistanceNorm(this.startDate, component.date);
            var e = timelineEventsArray[i];
            if (e) {
                this.config.renderer.setStyle(e.nativeElement, 'left', distanceNorm + 'px');
                // span
                var span = e.nativeElement.parentElement.children[1];
                var spanWidth = this.getElementWidth(span);
                this.config.renderer.setStyle(span, 'left', distanceNorm + spanWidth / 2 + 'px');
                //span.style.left = distanceNorm * min + spanWidth / 2 + 'px';
            }
            i++;
        }
    };
    TimelineExpandHelper.prototype.calcDistanceNorm = function (startDate, endDate) {
        var distance = this.dayDiff(startDate, endDate);
        var distanceNorm = Math.round(distance * this.norm);
        return distanceNorm;
    };
    return TimelineExpandHelper;
}(TimelineHelper));
export { TimelineExpandHelper };
