<i class="js-pointer pointer" aria-hidden="true"></i>
<slx-locker [show]="isLocked">
  <div *ngIf="!noData" class="org-tree">
    <div class="org-tree__search">
      <p class="org-tree__search-wrapper">
        <input #searchField type="text" class="js-search org-tree__search-field" [(ngModel)]="searchValue" placeholder="Search in Navigator">
        <i class="fa fa-search org-tree__search-icon no-action" [ngClass]="{ 'hide': searchField.value.length > 0 }" aria-hidden="true"></i>
        <i class="fas fa-times-circle org-tree__search-icon" [ngClass]="{ 'hide': searchField.value.length === 0 }" (click)="onClearSearch()" title="Clear" aria-hidden="true"></i>
      </p>
    </div>
    <div class="org-tree__body">
      <ng-container *ngIf="hasOrgLevelTree">
        <kendo-treeview class="org-tree__tree"
          [nodes]="orgLevels"
          [textField]="orgLevelFieldName"
          [hasChildren]="hasChildsOrgLevels"
          [children]="childsOrgLevels"
          (selectionChange)="onOrgLevelChanged($event)"

          kendoTreeViewExpandable
          [expandBy]="keyForExpanding"
          [(expandedKeys)]="expandedOrgLevels"

          kendoTreeViewSelectable
          [selectBy]="keyForSelecting"
          [(selectedKeys)]="selectedOrgLevels"
        >
          <ng-template kendoTreeViewNodeTemplate let-dataItem>
            <i class="fa org-tree__org-icon" aria-hidden="true" [ngClass]="getIconClass(dataItem)"></i>
            <span [innerHTML]="getNodeText(dataItem)"></span>
          </ng-template>
        </kendo-treeview>
      </ng-container>
      <ng-container *ngIf="!hasOrgLevelTree">
        <p class="org-tree__no-results">No organization levels found</p>
      </ng-container>
    </div>
  </div>
  <div class="no-data" *ngIf="noData">
    No Data
  </div>
</slx-locker>
