import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ScheduleEntryManagementService } from '../../../../services/index';
import { ShiftLate } from '../../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';

@Component({
    moduleId: module.id,
    selector: 'slx-scheduled-shift-lateness-section',
    templateUrl: 'scheduled-shift-lateness-section.component.html',
    styleUrls: [
        'scheduled-shift-lateness-section.component.scss'
    ]
})
export class ScheduledShiftLatenessSectionComponent implements OnInit {

    @Input()
    public set late(value: ShiftLate) {
        this.m_late = value;
    }

    @Input()
    public minDate: Date;
    @Input()
    public maxDate: Date;

    @Input()
    public startDate: Date;

    @Input()
    public endDate: Date;

    @Input()
    public shiftHoursInMinutes: number;

    @Input()
    public index: number = -1;

    @Output()
    public onLateStartDateChanged: EventEmitter<ShiftLate>;
    @Output()
    public onLateIntervalChanged: EventEmitter<ShiftLate>;

    public appConfig: IApplicationConfig;

    public get late(): ShiftLate {
        return this.m_late;
    }

    private m_late: ShiftLate;

    constructor() {
        this.appConfig = appConfig;
        this.onLateStartDateChanged = new EventEmitter<ShiftLate>();
        this.onLateIntervalChanged = new EventEmitter<ShiftLate>();
    }

    public ngOnInit(): void {
        _.noop();
    }

    public lateDateChanged(): void {
        this.onLateStartDateChanged.next(this.late);
    }

    public lateIntervalChanged(): void {
        this.onLateIntervalChanged.next(this.late);
    }
}

