export class IConversionStateDTO {
    id: number;
    organizationId: number;
    startedAt: string;
    scheduledAt: string;
    finishedAt: string;
    state: string;
    message: string;
    runBy: number;
}

export class ConversionState {
    public id: number;
    public organizationId: number;
    public startedAt: Date;
    public scheduledAt: Date;
    public finishedAt: Date;
    public state: string;
    public message: string;
    public runBy: number;
}
