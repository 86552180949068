import * as _ from 'lodash';
var TreeNodeModel = /** @class */ (function () {
    function TreeNodeModel(data, parent, tree) {
        this.children = [];
        this.depth = 0;
        this.data = data;
        this.parent = parent;
        this.tree = tree;
    }
    TreeNodeModel.prototype.hasChildren = function () {
        return this.children.length > 0;
    };
    Object.defineProperty(TreeNodeModel.prototype, "isExpanded", {
        get: function () {
            if (this.tree.isExpanded(this))
                return true;
            return false;
        },
        enumerable: true,
        configurable: true
    });
    TreeNodeModel.prototype.mouseAction = function (actionName, $event, data) {
        if (data === void 0) { data = null; }
        this.tree.processMouseAction(actionName, $event, this, data);
    };
    return TreeNodeModel;
}());
export { TreeNodeModel };
var TreeModel = /** @class */ (function () {
    function TreeModel() {
        this.root = new TreeNodeModel(null, null, this);
        this.expandedNodes = new Array();
    }
    TreeModel.prototype.isExpanded = function (node) {
        return _.find(this.expandedNodes, function (item) {
            return node === item;
        });
    };
    TreeModel.prototype.setExpanded = function (node) {
        if (!this.isExpanded(node)) {
            this.expandedNodes.push(node);
        }
        else {
            this.expandedNodes = _.without(this.expandedNodes, node);
        }
    };
    TreeModel.prototype.processMouseAction = function (actionName, $event, node, data) {
        if (data === void 0) { data = null; }
        switch (actionName) {
            case 'expanderClick':
                this.setExpanded(node);
                break;
        }
    };
    TreeModel.prototype.getDepth = function (node) {
        var depth = 0;
        var parent;
        parent = node.parent;
        while (parent) {
            depth++;
            parent = parent.parent;
        }
        return depth;
    };
    return TreeModel;
}());
export { TreeModel };
