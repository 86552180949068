import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { BenefitsEnrollmentSectionManagementService, EmployeeSectionsBenefitsManagementApiService, EmployeeSectionBridgeService } from '../../../../services';
import { appConfig } from '../../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../../common/models/kendo-grid-helpers';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { CalculationType } from '../../../../models/employee-sections-benefits-management/calculation-type.enum';
import { FormulaTypeEnum } from '../../../../models/employee-sections-benefits-management/formula-type.enum';
import { DropCoveragePopupComponent } from '../drop-coverage-popup/drop-coverage-popup.component';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common';
import { BenefitEnrolledEmployee } from '../../../../../../app-modules/benefits/models';
import { EnrollEmployeeSelectPlanPopupComponent } from '../enroll-employee-select-plan-popup/enroll-employee-select-plan-popup.component';
import { LookupService } from '../../../../../../organization/services';
import { NewEnrollmentModel } from '../../../../models/employee-sections-benefits-management/new-enrollment.model';
import { EnrollEmployeeSelectCoveragePopupComponent } from '../enroll-employee-select-coverage-popup/enroll-employee-select-coverage-popup.component';
import { EnrollEmployeeFlatComponent } from '../enroll-employee-flat/enroll-employee-flat.component';
import { EnrollEmployeeFormulaComponent } from '../enroll-employee-formula/enroll-employee-formula.component';
import { EnrollEmployeeFormulaWithOptionComponent } from '../enroll-employee-formula-with-option/enroll-employee-formula-with-option.component';
import { EnrollEmployee401kComponent } from '../enroll-employee-401k/enroll-employee-401k.component';
import { BeneficiaryEditorPopupComponent } from '../beneficiary-editor-popup/beneficiary-editor-popup.component';
import { unsubscribeAll } from '../../../../../../core/decorators';
import { CalculationCommonService } from '../../../../../../app-modules/benefits/services/benefit-details/calculation-common.service';
import { BenefitEmployeesApiService } from '../../../../../../app-modules/benefits/services';
import { BenefitEnrollmentCommonService } from '../../../../../../../app/app-modules/benefits/services';
var BenefitEnrollmentComponent = /** @class */ (function () {
    function BenefitEnrollmentComponent(managementService, apiService, commonValidationService, modalService, lookupService, benefitsApiService, bridgeService, calculationCommonService) {
        this.managementService = managementService;
        this.apiService = apiService;
        this.commonValidationService = commonValidationService;
        this.modalService = modalService;
        this.lookupService = lookupService;
        this.benefitsApiService = benefitsApiService;
        this.bridgeService = bridgeService;
        this.calculationCommonService = calculationCommonService;
        // rights
        this.canCreateNewEnrollment = true;
        this.canEdit = true;
        this.canDelete = true;
        this.pageSize = 5;
        // dynamic settings
        this.applicableByEffectiveDate = false;
        this.minEffectiveDate = new Date(1900, 1, 1);
        this.maxEffectiveDate = new Date(2079, 6, 6);
        this.effectiveDate = new Date();
        this.hasFormulaError = false;
        this.empContrPercentError = false;
        // fields configuration (dynamic)
        this.fieldSettings = {};
        this.defaultMultiplierValue = 500;
        this.defaultMultiplierMaxCap = 10000;
        this.subscriptions = {};
        this.isCovCalculated = true;
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.resetGridState();
        this.fieldSettings.empPercentContribution = {
            min: 0,
            max: 100,
            step: 0.01,
            autoCorrect: true,
            format: 'n2',
            decimals: 2
        };
        this.fieldSettings.empContribution = {
            min: 0,
            max: 999999999.99,
            step: 0.01,
            autoCorrect: true,
            format: 'c2',
            decimals: 2
        };
        this.fieldSettings.coverage = {
            min: 0,
            max: 99999999.99,
            step: 0.01,
            autoCorrect: false,
            format: 'c2',
            decimals: 2
        };
        this.fieldSettings.coverageFormula = {
            min: -1,
            max: 99999999.99,
            step: 0.01,
            autoCorrect: false,
            format: 'c2',
            decimals: 2
        };
        this.calculationMethod = CalculationType;
    }
    Object.defineProperty(BenefitEnrollmentComponent.prototype, "isFutureRehired", {
        get: function () {
            return this.employeeStatus ? this.employeeStatus.toLowerCase() === "future rehire" : false;
        },
        enumerable: true,
        configurable: true
    });
    BenefitEnrollmentComponent.prototype.ngOnDestroy = function () {
    };
    BenefitEnrollmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.dataLoaded = this.managementService.subscribeToDataLoad(function (enrollments) {
            _this.employeeId = enrollments.employeeInfo.id;
            _this.empName = enrollments.employeeInfo.fullName;
            _this.minEffectiveDate = enrollments.employeeInfo.dateHired;
            _this.maxEffectiveDate = enrollments.employeeInfo.terminationDate ? moment(enrollments.employeeInfo.terminationDate).toDate() : new Date(2079, 6, 6);
            if (enrollments.actions) {
                _this.canDelete = enrollments.actions.canDelete;
                _this.canEdit = enrollments.actions.canEdit;
                _this.canCreateNewEnrollment = enrollments.actions.canAdd;
            }
            _this.updateApplicability();
        });
        this.subscriptions.loadingState = this.managementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
        this.subscriptions.enrollmentsLoaded = this.managementService.subscribeToEnrollmentsLoad(function (enrollments) {
            _.map(enrollments, function (data) { data.coverageLimit = ((_this.fieldSettings.empContribution.max) && data.limitAmount > 0) ? data.limitAmount : _this.fieldSettings.empContribution.max; });
            _this.enrollments = enrollments;
            _this.setCostValue();
            _this.refreshGrid();
        });
        this.subscriptions.enrollmentsDeleted = this.managementService.subscribeToEnrollmentDeleted(function (enrollments) {
            _this.managementService.getEmployeeEnrollments(_this.employeeId, _this.effectiveDate);
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.gridEdit = this.gridState.onEdit$
            .subscribe(function (record) {
            _this.hasFormulaError = false;
            _this.editedItem = record;
            var coverageValue = record.coverage || 0;
            _this.editedItemCoverage = { name: '' + coverageValue, value: coverageValue };
            _this.canEditEmpContribution = _this.editedItem.calculationMethod === CalculationType.Flat || _this.editedItem.calculationMethod === CalculationType.OptionsRates || _this.editedItem.calculationMethod === CalculationType.C401K;
            _this.fieldSettings.empPercentContribution.max = _.isNumber(_this.editedItem.maxEmployeePercentageContribution) && _this.editedItem.maxEmployeePercentageContribution > 0 ? _this.editedItem.maxEmployeePercentageContribution : 100;
            _this.coverageMultipllierOptions = [];
            _this.multiplerMaxCap = _this.editedItem.calculationType === FormulaTypeEnum.MultiplerMaxCap;
            _this.anyWithMaxCap = _this.editedItem.calculationType === FormulaTypeEnum.AnyWithMaxCap;
            _this.isFormula = _this.editedItem.calculationType === FormulaTypeEnum.Formula;
            if (_this.multiplerMaxCap) {
                var mult = _.isNumber(_this.editedItem.multiplier) ? _this.editedItem.multiplier : _this.defaultMultiplierValue;
                var cap = _.isNumber(_this.editedItem.maxCap) ? _this.editedItem.maxCap : _this.defaultMultiplierMaxCap;
                var range = _.range(mult, cap + mult, mult);
                var opts = _.map(range, function (value) { return ({ name: _.toString(value), value: value }); });
                _this.coverageMultipllierOptions = opts;
            }
            else if (_this.anyWithMaxCap) {
                _this.fieldSettings.coverage.max = _.isNumber(_this.editedItem.maxCap) ? _this.editedItem.maxCap : _this.fieldSettings.coverage.max;
                _this.coverageMultipllierOptions = [];
            }
            _this.validateFormulaEvaluation(record, false);
            _this.isCovCalculated = true;
        });
        this.subscriptions.gridCancel = this.gridState.onCancel$
            .subscribe(function () {
            _this.hasFormulaError = false;
            _this.editedItem = null;
            _this.isCovCalculated = true;
        });
        this.subscriptions.gridRemove = this.gridState.onRemove$
            .subscribe(function (event) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            options.buttonOKtext = 'Yes';
            ConfirmDialog2Component.openDialog('Confirmation', 'Are you sure you want to delete the selected benefit? This will terminate coverage for the enrolled employee and dependents.', _this.modalService, function (result) {
                if (result) {
                    _this.managementService.deleteEnrollment(event.dataItem);
                }
                else {
                    _this.refreshGrid();
                }
            }, options);
        });
        this.subscriptions.gridSave = this.gridState.onSave$
            .subscribe(function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.hasFormulaError = false;
                        this.gridState.closeEditor(this.grid);
                        return [4 /*yield*/, this.managementService.updateEnrollment(event.dataItem)];
                    case 1:
                        _a.sent();
                        this.editedItem = null;
                        return [4 /*yield*/, this.managementService.getEmployeeEnrollments(this.employeeId, this.effectiveDate)];
                    case 2:
                        _a.sent();
                        this.isCovCalculated = true;
                        return [2 /*return*/];
                }
            });
        }); });
    };
    BenefitEnrollmentComponent.prototype.updateApplicability = function () {
        if (this.maxEffectiveDate) {
            this.applicableByEffectiveDate = moment(this.effectiveDate).isSameOrBefore(this.maxEffectiveDate);
        }
        else {
            this.applicableByEffectiveDate = true;
        }
    };
    BenefitEnrollmentComponent.prototype.onPlanClick = function (selectedRecord) {
        this.managementService.planSelected(this.employeeId, selectedRecord);
    };
    BenefitEnrollmentComponent.prototype.onChangeEffectiveDate = function (date) {
        if (_.isDate(date) && !moment(this.effectiveDate).isSame(date)) {
            this.effectiveDate = date;
            this.resetGridState();
            this.managementService.getEmployeeEnrollments(this.employeeId, date);
        }
    };
    BenefitEnrollmentComponent.prototype.isEditingRowDiabled = function (dep) {
        return (dep.calcMethod.isOptionsRates ||
            ((dep.calcMethod.isFormulaWithOption || dep.calcMethod.isFormula) && dep.calculationType === 1));
    };
    BenefitEnrollmentComponent.prototype.getDisabledEditTooltip = function (dep) {
        if (this.isEditingRowDiabled(dep)) {
            return "This benefit does not contain editable fields.";
        }
        return "";
    };
    BenefitEnrollmentComponent.prototype.isEditingRow = function (dep) {
        return _.isObjectLike(this.editedItem) && _.isObjectLike(dep) && this.editedItem.empToBenefitsId === dep.empToBenefitsId;
    };
    BenefitEnrollmentComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    BenefitEnrollmentComponent.prototype.onChangeEndDate = function (item) {
        var _this = this;
        if (!this.canEdit)
            return;
        this.bridgeService.sectionsAreVisible = false;
        DropCoveragePopupComponent.openDialog(false, [item], this.canMapPayroll, this.effectiveDate, this.modalService, this.benefitsApiService, function (result) {
            if (result) {
                _this.managementService.getEmployeeEnrollments(_this.employeeId, _this.effectiveDate, true);
            }
            _this.bridgeService.sectionsAreVisible = true;
        });
    };
    BenefitEnrollmentComponent.prototype.onViewBeneficiaries = function (item) {
        var _this = this;
        this.bridgeService.sectionsAreVisible = false;
        BeneficiaryEditorPopupComponent.openDialog(item.empToBenefitsId, this.apiService, this.modalService, this.lookupService, function (r) {
            _this.bridgeService.sectionsAreVisible = true;
        });
    };
    BenefitEnrollmentComponent.prototype.onDropCoverage = function () {
        var _this = this;
        this.bridgeService.sectionsAreVisible = false;
        DropCoveragePopupComponent.openDialog(true, this.enrollments, this.canMapPayroll, this.effectiveDate, this.modalService, this.benefitsApiService, function (result) {
            if (result) {
                _this.managementService.getEmployeeEnrollments(_this.employeeId, _this.effectiveDate, true);
            }
            _this.bridgeService.sectionsAreVisible = true;
        });
    };
    BenefitEnrollmentComponent.prototype.onChangeCoverageWithMultiplier = function (value, item, hasError) {
        var numericValue = parseInt(value);
        if (hasError || isNaN(numericValue)) {
            item.coverage = null;
            this.editedItemCoverage = null;
            this.lastCoverageValue = null;
            this.hasFormulaError = true;
            return;
        }
        this.editedItemCoverage = _.find(this.coverageMultipllierOptions, function (x) { return x.value === numericValue; });
        item.coverage = numericValue;
        if (item.coverage !== this.lastCoverageValue) {
            this.lastCoverageValue = item.coverage;
            item.calculatedCoverage = item.coverage;
            this.calculateFormula(item.coverage, item);
        }
    };
    BenefitEnrollmentComponent.prototype.calculateCoverage = function (item, hasError) {
        if (!hasError && item.coverage !== item.calculatedCoverage) {
            item.calculatedCoverage = item.coverage;
            this.calculateFormula(item.coverage, item);
        }
        this.isCovCalculated = true;
    };
    BenefitEnrollmentComponent.prototype.onOptionValueChange = function (item) {
        if (item && item.selectedOption && item.option != item.selectedOption.code) {
            item.benefitTierOptionId = item.selectedOption.id;
            item.optionType = item.selectedOption.type;
            item.option = item.selectedOption.code;
            this.calculateFormula(item.coverage, item);
        }
    };
    BenefitEnrollmentComponent.prototype.onCoverageValueChange = function (value, item, hasError) {
        if (hasError || isNaN(value)) {
            item.coverage = null;
            this.editedItemCoverage = null;
            this.lastCoverageValue = null;
            this.hasFormulaError = true;
            return;
        }
        item.coverage = value;
        this.isCovCalculated = false;
    };
    BenefitEnrollmentComponent.prototype.calculateFormula = function (coverage, item) {
        var _this = this;
        if ((this.editedItem.option || item.calculationMethod === CalculationType.Formula)
            && (this.isFormula || this.form.valid)) {
            item.coverage = coverage ? coverage : 0;
            this.isLoading = true;
            this.apiService.getBenefitPlanFormula(item.benefitTierId, this.employeeId, this.effectiveDate, item.coverage, this.editedItem.option)
                .then(function (record) {
                if (record) {
                    item.employeeContribution = record.empContribution;
                    item.employerContributionAmount = record.erContribution;
                    item.calculatedCoverage = record.calculatedCoverage;
                    if (_this.multiplerMaxCap || _this.anyWithMaxCap) {
                        record.formulaValue = record.calculatedCoverage;
                    }
                    if (!_.isNil(record.formulaValue)) {
                        _this.hasFormulaError = false;
                    }
                    else {
                        _this.hasFormulaError = true;
                    }
                }
            }).finally(function () {
                _this.lastCoverageValue = null;
                _this.isLoading = false;
                _this.validateFormulaEvaluation(item, true);
            });
        }
    };
    BenefitEnrollmentComponent.prototype.onStartEnrollment = function () {
        var _this = this;
        this.bridgeService.sectionsAreVisible = false;
        var ids = _.map(this.enrollments, function (e) { return e.empToBenefitsId; });
        var newEnrollment = new NewEnrollmentModel(ids, this.effectiveDate, this.canMapPayroll);
        newEnrollment.empId = this.employeeId;
        this.newEnrollment = newEnrollment;
        EnrollEmployeeSelectPlanPopupComponent.openDialog(this.newEnrollment, this.apiService, this.modalService, this.lookupService, function (result) {
            if (result) {
                switch (_this.newEnrollment.plan.calculationMethod) {
                    case CalculationType.Flat:
                        EnrollEmployeeFlatComponent.openDialog(_this.newEnrollment, _this.apiService, _this.modalService, _this.lookupService, function (result) {
                            if (result) {
                                _this.createEnrollment(_this.newEnrollment);
                            }
                            else {
                                _this.newEnrollment = null;
                            }
                            _this.bridgeService.sectionsAreVisible = true;
                        });
                        break;
                    case CalculationType.Formula:
                        EnrollEmployeeFormulaComponent.openDialog(_this.newEnrollment, _this.apiService, _this.modalService, _this.lookupService, function (result) {
                            if (result) {
                                _this.createEnrollment(_this.newEnrollment);
                            }
                            else {
                                _this.newEnrollment = null;
                            }
                            _this.bridgeService.sectionsAreVisible = true;
                        });
                        break;
                    case CalculationType.FormulaWithOption:
                        EnrollEmployeeFormulaWithOptionComponent.openDialog(_this.newEnrollment, _this.apiService, _this.modalService, _this.lookupService, function (result) {
                            if (result) {
                                _this.createEnrollment(_this.newEnrollment);
                            }
                            else {
                                _this.newEnrollment = null;
                            }
                            _this.bridgeService.sectionsAreVisible = true;
                        });
                        break;
                    case CalculationType.C401K:
                        EnrollEmployee401kComponent.openDialog(_this.newEnrollment, _this.apiService, _this.modalService, _this.lookupService, function (result) {
                            if (result) {
                                _this.createEnrollment(_this.newEnrollment);
                            }
                            else {
                                _this.newEnrollment = null;
                            }
                            _this.bridgeService.sectionsAreVisible = true;
                        });
                        break;
                    case CalculationType.OptionsRates:
                        EnrollEmployeeSelectCoveragePopupComponent.openDialog(_this.newEnrollment, _this.apiService, _this.modalService, _this.calculationCommonService, function (result) {
                            if (result) {
                                if (_this.newEnrollment.selectedCoverageOption) {
                                    _this.createEnrollment(_this.newEnrollment);
                                }
                            }
                            else {
                                _this.newEnrollment = null;
                            }
                            _this.bridgeService.sectionsAreVisible = true;
                        });
                        break;
                }
            }
            else {
                _this.newEnrollment = null;
                _this.bridgeService.sectionsAreVisible = true;
            }
        });
    };
    BenefitEnrollmentComponent.prototype.setCostValue = function () {
        var _this = this;
        _.forEach(this.enrollments, function (employee) {
            if (employee.calcMethod.isOptionsRates) {
                _this.onChangeContribution(employee);
            }
        });
    };
    BenefitEnrollmentComponent.prototype.onChangePercentContribution = function (dataItem) {
        this.empContrPercentError = (this.editedItem.employeeContribution === null && this.editedItem.employeePercentageContribution === null) ? true : false;
    };
    BenefitEnrollmentComponent.prototype.onChangeContribution = function (dataItem) {
        var employeeContribution = 0;
        if (dataItem.employeeContribution > 0) {
            employeeContribution = this.calculationCommonService.getConvertedEmployeeContribution(dataItem.costFreq, dataItem.empContFreq, dataItem.employeeContribution);
        }
        dataItem.cost = dataItem.employerContributionAmount + employeeContribution;
        this.empContrPercentError = (dataItem.employeeContribution === null && dataItem.employeePercentageContribution === null) ? true : false;
    };
    BenefitEnrollmentComponent.prototype.createEnrollment = function (newEnrollment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var r, tierId, lineId, calculationStr, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.managementService.updateSpinner(true);
                        r = new BenefitEnrolledEmployee();
                        r.employeeId = newEnrollment.empId;
                        r.payrollDeductionStartDate = newEnrollment.plan.payrollDeductionStartDate;
                        r.payrollDeductionEndDate = newEnrollment.plan.payrollDeductionEndDate;
                        if (!newEnrollment) return [3 /*break*/, 4];
                        if (newEnrollment.flatSettings) {
                            tierId = newEnrollment.flatSettings.tierId;
                            lineId = newEnrollment.flatSettings.lineId;
                            calculationStr = CalculationType[CalculationType.Flat];
                            r.employeeContributionAmount = newEnrollment.flatSettings.empContribution;
                            r.employerContributionAmount = newEnrollment.flatSettings.erContribution;
                        }
                        else if (newEnrollment.formulaSettings && newEnrollment.selectedCoverageOption) {
                            tierId = newEnrollment.formulaSettings.tierId;
                            lineId = newEnrollment.formulaSettings.lineId;
                            calculationStr = CalculationType[CalculationType.FormulaWithOption];
                            r.coverage = newEnrollment.formulaSettings.getCoverage();
                            r.employeeContributionAmount = newEnrollment.formulaSettings.empContribution;
                            r.employerContributionAmount = newEnrollment.formulaSettings.erContribution;
                            r.benefitTierOptionId = newEnrollment.selectedCoverageOption.id;
                        }
                        else if (newEnrollment.formulaSettings) {
                            tierId = newEnrollment.formulaSettings.tierId;
                            lineId = newEnrollment.formulaSettings.lineId;
                            calculationStr = CalculationType[CalculationType.Formula];
                            r.coverage = newEnrollment.formulaSettings.getCoverage();
                            r.employeeContributionAmount = newEnrollment.formulaSettings.empContribution;
                            r.employerContributionAmount = newEnrollment.formulaSettings.erContribution;
                        }
                        else if (newEnrollment.settings401k) {
                            tierId = newEnrollment.settings401k.tierId;
                            lineId = newEnrollment.settings401k.lineId;
                            calculationStr = CalculationType[CalculationType.C401K];
                            r.employeeContributionPercentage = newEnrollment.settings401k.employeePercentageContribution;
                            r.employeeContributionAmount = newEnrollment.settings401k.employeeContribution;
                            r.limit = newEnrollment.settings401k.limitAmount;
                            this.fieldSettings.empContribution.max = newEnrollment.settings401k.limitAmount;
                        }
                        else if (newEnrollment.selectedCoverageOption) {
                            tierId = newEnrollment.selectedCoverageOption.tierId;
                            lineId = newEnrollment.selectedCoverageOption.lineId;
                            calculationStr = CalculationType[CalculationType.OptionsRates];
                            r.benefitTierOptionId = newEnrollment.selectedCoverageOption.id;
                            r.employeeContributionAmount = newEnrollment.selectedCoverageOption.tierOptionEmpContribution;
                            r.employerContributionAmount = newEnrollment.selectedCoverageOption.tierOptionErContribution;
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.benefitsApiService.enrollEmployeesBenefit([r], '', newEnrollment.effectiveDate, lineId, tierId, calculationStr)];
                    case 2:
                        _a.sent();
                        this.managementService.getEmployeeEnrollments(this.employeeId, this.effectiveDate);
                        return [2 /*return*/, true];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, false];
                }
            });
        });
    };
    BenefitEnrollmentComponent.prototype.refreshGrid = function () {
        if (!this.enrollments) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.enrollments, this.gridState.state);
    };
    BenefitEnrollmentComponent.prototype.resetGridState = function () {
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'startDate', dir: 'desc' }
        ];
    };
    BenefitEnrollmentComponent.prototype.validateFormulaEvaluation = function (dataItem, showWarning) {
        if (!this.isFormula && !this.multiplerMaxCap && this.anyWithMaxCap)
            return;
        var coverage = this.isFormula ? dataItem.calculatedCoverage : dataItem.coverage;
        var errorMessage = this.commonValidationService.validateFormulaResult(dataItem.employeeContribution, dataItem.employerContributionAmount, coverage);
        this.hasFormulaError = errorMessage && errorMessage != '';
        if (this.hasFormulaError && showWarning) {
            var options = new ConfirmOptions();
            options.showCancel = false;
            options.showOK = true;
            ConfirmDialog2Component.openDialog('Warning', errorMessage, this.modalService, function (result) { }, options);
        }
    };
    BenefitEnrollmentComponent.prototype.isEmpContribSelected = function (dataItem) {
        return !!_.get(dataItem, 'employeePercentageContribution', null) || (dataItem.employeePercentageContribution === 0);
    };
    BenefitEnrollmentComponent.prototype.isEmpPercentSelected = function (dataItem) {
        return !!_.get(dataItem, 'employeeContribution', null) || (dataItem.employeeContribution === 0);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitEnrollmentComponent.prototype, "subscriptions", void 0);
    return BenefitEnrollmentComponent;
}());
export { BenefitEnrollmentComponent };
