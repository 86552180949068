import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { OnInit } from '@angular/core';
import { process } from '@progress/kendo-data-query';
var UnassignedEmployeesGridComponent = /** @class */ (function () {
    //private managementService: SupervisorAssignmentManagementService
    function UnassignedEmployeesGridComponent() {
        this.gridState = new KendoGridStateHelper();
    }
    Object.defineProperty(UnassignedEmployeesGridComponent.prototype, "employees", {
        set: function (value) {
            this.m_employees = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    UnassignedEmployeesGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gridState.onRefreshGrid.subscribe(function (state) {
            _this.refreshGrid();
        });
        // TODO: maybe later we will use it internally
        /*
        this.managementService.loadedUnassignedEmployeesCmd$.subscribe((employees: UnassignedEmployee[]) => {
            this.employees = employees;
            this.refreshGrid();
        });

        this.managementService.loadUnassignedForCurrentOrgLevel();
        */
    };
    UnassignedEmployeesGridComponent.prototype.refreshGrid = function () {
        if (!this.m_employees) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_employees, this.gridState.state);
    };
    return UnassignedEmployeesGridComponent;
}());
export { UnassignedEmployeesGridComponent };
