import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { IColumnSettings, StateResetTypes } from '../../../../core/models/index';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
import { StateManagementService, ComponentStateStorageService, ExceptionConsoleNavigationService } from '../../../../common/services/index';
import { RangeDates } from '../../../../common/models/range-dates';
import { ExceptionConsoleManagementService } from '../../../services/exception-console/exception-console-management.service';
import { PayCycleHelperService } from '../../../../organization/services/pay-cycle-helper/pay-cycle-helper.service';
var ExceptionConsoleComponent = /** @class */ (function () {
    function ExceptionConsoleComponent(stateService, storageService, router, activatedRoute, payCycleHelper, managementService) {
        this.stateService = stateService;
        this.storageService = storageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.payCycleHelper = payCycleHelper;
        this.resetBy = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.componentId = 'ExceptionConsoleComponent';
        this.payCycleControlName = 'payCycle';
        this.dateRangeControlName = 'dateRange';
        this.isLoading = true;
        this.managementService = managementService;
    }
    Object.defineProperty(ExceptionConsoleComponent.prototype, "usePayCycle", {
        get: function () {
            return this.managementService.exactPayCycle;
        },
        set: function (value) {
            this.managementService.exactPayCycle = value;
        },
        enumerable: true,
        configurable: true
    });
    ExceptionConsoleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.usePayCycle = false;
        this.setDefaultCycle = false;
        this.routeSubscription = this.activatedRoute.data
            .combineLatest(this.stateService.onInit$)
            .subscribe(function (_a) {
            var data = _a[0], init = _a[1];
            var routeData = data.exceptionConsoleData;
            _this.usePayCycle = routeData.usePayCycle;
            var payCycle = routeData.payCycle;
            var range = routeData.range;
            if (_this.usePayCycle) {
                if (payCycle) {
                    _this.setDefaultCycle = false;
                    _this.selectedPayCycle = payCycle;
                    range = new RangeDates();
                    range.startDate = payCycle.startDate;
                    range.endDate = payCycle.endDate;
                }
                else {
                    _this.setDefaultCycle = true;
                }
            }
            _this.currentRange = range;
            if (_this.currentRange)
                _this.managementService.setDateRange(_this.currentRange);
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.isLoading = value;
        });
        this.loadStateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.globalState = state;
        });
        this.loadedSubscription = this.managementService.onLoaded$
            .subscribe(function (container) {
            _this.container = container;
        });
        this.recordsSelectedSubscription = this.managementService.onRecordsSelected$
            .subscribe(function (records) {
            _this.selectedRecords = records;
        });
        this.stateService.init(this.componentId);
    };
    ExceptionConsoleComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ExceptionConsoleComponent.prototype.onApproveTimecards = function () {
        this.managementService.approveTimecards(this.selectedRecords);
    };
    ExceptionConsoleComponent.prototype.selectAll = function (event) {
        _.forEach(this.globalState.allColumns, function (column) {
            if (!column.readonly) {
                column.displayed = true;
            }
        });
        this.managementService.onStateChanged(this.globalState);
    };
    ExceptionConsoleComponent.prototype.clearAll = function (event) {
        _.forEach(this.globalState.allColumns, function (column) {
            if (!column.readonly) {
                column.displayed = false;
            }
        });
        this.managementService.onStateChanged(this.globalState);
    };
    ExceptionConsoleComponent.prototype.onRefreshByCheck = function () {
        this.managementService.onStateChanged(this.globalState);
    };
    ExceptionConsoleComponent.prototype.onChangeColumn = function (event, column) {
        this.managementService.onStateChanged(this.globalState);
    };
    ExceptionConsoleComponent.prototype.onSwitchRangeMode = function (mode) {
        this.usePayCycle = mode;
        var navigation = new ExceptionConsoleNavigationService(this.router, this.activatedRoute);
        if (!this.currentRange) {
            navigation.navigateToExceptionConsole(this.managementService.currentOrgLevel.id, this.usePayCycle);
        }
        else {
            navigation.navigateToExceptionConsoleDates(this.managementService.currentOrgLevel.id, this.currentRange.startDate, this.currentRange.endDate, this.usePayCycle);
        }
    };
    ExceptionConsoleComponent.prototype.onPayCycleSelected = function (payCycle) {
        var range = new RangeDates();
        range.startDate = payCycle.startDate;
        range.endDate = payCycle.endDate;
        this.currentRange = range;
        this.managementService.navigateToDateRange(range, true);
    };
    ExceptionConsoleComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        var range = new RangeDates();
        range.startDate = startDate;
        range.endDate = endDate;
        this.currentRange = range;
        this.managementService.navigateToDateRange(range, false);
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", ExceptionConsoleManagementService)
    ], ExceptionConsoleComponent.prototype, "managementService", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleComponent.prototype, "recordsSelectedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleComponent.prototype, "routeSubscription", void 0);
    return ExceptionConsoleComponent;
}());
export { ExceptionConsoleComponent };
