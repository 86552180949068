import * as _ from 'lodash';
var AccrualBalanceSettings = /** @class */ (function () {
    function AccrualBalanceSettings() {
    }
    return AccrualBalanceSettings;
}());
export { AccrualBalanceSettings };
var AccrualBalanceColumnsSettings = /** @class */ (function () {
    function AccrualBalanceColumnsSettings() {
    }
    AccrualBalanceColumnsSettings.prototype.createColumns = function () {
        this.columns = [
            {
                name: 'payrollNumber',
                description: 'Payroll Number',
                readonly: false,
                displayed: true,
                width: 140,
                filter: 'string'
            },
            {
                name: 'employeeStatus',
                description: 'Employee Status',
                readonly: false,
                displayed: true,
                width: 140,
                filter: 'string'
            },
            {
                name: 'policyName',
                description: 'Policy Name',
                readonly: false,
                displayed: true,
                width: 250,
                filter: 'string'
            },
            {
                name: 'policyAssignmentDate',
                description: 'Policy Assignment Date',
                readonly: false,
                displayed: true,
                width: 150,
                filter: 'date'
            },
            {
                name: 'organization',
                description: 'Organization',
                readonly: false,
                displayed: true,
                width: 170,
                filter: 'string'
            },
            {
                name: 'department',
                description: 'Department',
                readonly: false,
                displayed: true,
                width: 150,
                filter: 'string'
            },
            {
                name: 'position',
                description: 'Position',
                readonly: false,
                displayed: true,
                width: 150,
                filter: 'numeric'
            },
            {
                name: 'employeeType',
                description: 'Employee Type',
                readonly: false,
                displayed: true,
                width: 150,
                filter: 'numeric'
            }
        ];
    };
    Object.defineProperty(AccrualBalanceColumnsSettings.prototype, "allColumns", {
        get: function () {
            return this.dynamicColumns ? _.concat(this.columns, this.dynamicColumns) : this.columns;
        },
        enumerable: true,
        configurable: true
    });
    AccrualBalanceColumnsSettings.prototype.mapColumns = function () {
        this.columnsMap = _.keyBy(this.allColumns, function (column) {
            return column.name;
        });
    };
    AccrualBalanceColumnsSettings.prototype.setDynamicColumns = function (accrualTypes) {
        var columns = _.map(accrualTypes, function (accrualType) {
            if (accrualType.name) {
                return {
                    name: accrualType.name,
                    description: accrualType.name,
                    readonly: false,
                    displayed: true,
                    width: 150,
                    filter: 'numeric'
                };
            }
        });
        this.dynamicColumns = columns;
        this.mapColumns();
    };
    return AccrualBalanceColumnsSettings;
}());
export { AccrualBalanceColumnsSettings };
