<kendo-grid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  (dataStateChange)="gridState.dataStateChange($event)"
>

  <kendo-grid-column title="Organization" field="organization.name" width="200">
    <ng-template kendoGridHeaderTemplate>
       Organization
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="facility-name"> {{dataItem?.organization?.name}}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Submitted Date" field="submittedDate" width="200" filter="date">
      <ng-template kendoGridHeaderTemplate>
          Submitted Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.submittedDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </ng-template>
    </kendo-grid-column>

  <kendo-grid-column title="Submitted By" field="submittedBy" width="150">
      <ng-template kendoGridHeaderTemplate>
          Submitted By
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.submittedBy }}</span>
      </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Release Date" field="releaseDate" width="200" filter="date">
      <ng-template kendoGridHeaderTemplate>
          Release Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.releaseDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </ng-template>
    </kendo-grid-column>

  <kendo-grid-column title="Release By" field="releasedBy" width="150">
      <ng-template kendoGridHeaderTemplate>
          Release By
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.releasedBy }}</span>
      </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Export Date" field="exportDate" width="250" filter="date">
      <ng-template kendoGridHeaderTemplate>
          Export Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span>{{ dataItem.exportDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Preview Report" width="250">
        <ng-template kendoGridHeaderTemplate>
            Preview Report
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <button *ngIf="dataItem.isSubmitted" type="button" class="slx-button slx-with-icon"
            timecardSummaryLink [organizationId]="dataItem?.organization?.id" [startDate]="startDate" [endDate]="endDate">
                <i class="far fa-search slx-button__icon" aria-hidden="true"></i>
            <span class="slx-button__text">Preview Report</span>
          </button>
        </ng-template>
      </kendo-grid-column>

  </kendo-grid>
