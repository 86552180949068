import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { LookupService } from '../../../../organization/services/index';
import { LookupType, Lookup } from '../../../../organization/models/index';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import { EmployeeDefinition } from '../../../../organization/models/index';
import { UserProfileNavigationService } from '../../../../common/services/index';
import { UserProfileSectionBaseComponent } from '../user-profile-sections/user-profile-section-base.component';
import { UserProfileSectionType } from '../../../models/users/models/user-profile-section-type';
import { UserProfileModel } from '../../../models/index';
import { UserProfileManagementService, UserValidatorAdapter } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { PasswordValidatorAdapter } from '../../../../authentication/services/index';
import { VirtualizationSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  moduleId: module.id,
  selector: 'slx-user-profile-section-profile',
  templateUrl: 'user-profile-section-profile.component.html',
  styleUrls: ['user-profile-section-profile.component.scss']
})

export class UserProfileSectionProfileComponent extends UserProfileSectionBaseComponent implements OnInit, OnDestroy {

  @Output()
  validStateUpdated = new EventEmitter();

  @ViewChild('form', {static: true})
  public form: NgForm;

  @Input()
  public virtual: VirtualizationSettings = {
    itemHeight: 65,
    pageSize: 5
  };

  public get passwordValidation(): string {
    return PasswordValidatorAdapter.passwordValidation;
  }

  public get userNameValidation(): string {
    return UserValidatorAdapter.loginValidation;
  }

  public get isValid(): boolean {
    return this.m_isValid;
  }
  public userProfile: UserProfileModel;
  public tempModel: UserProfileModel;
  public employees: EmployeeDefinition[];
  public isLoading: boolean = false;

  @unsubscribe()
  private formValueChangeSubscription: Subscription;
  @unsubscribe()
  private formStatusChangeSubscription: Subscription;
  @unsubscribe()
  private profileLoadedSubscription: Subscription;

  private m_isValid: boolean;

  constructor(
    public userValidatorAdapter: UserValidatorAdapter,
    public passwordValidator: PasswordValidatorAdapter,
    protected management: UserProfileManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private lookupService: LookupService,
  ) {
    super(management);
    this.m_type = UserProfileSectionType.PROFILE;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.updateList();

    this.profileLoadedSubscription = this.management.onProfileLoaded$.subscribe((userProfile: UserProfileModel) => {
      this.userProfile = userProfile;
      if (this.userProfile.isNew) {
        this.tempModel = this.userProfile;
        this.management.editSection(this);
      } else {
        this.tempModel = this.management.cloneProfile(this.userProfile);
      }
    });

    this.formValueChangeSubscription = this.form.control.valueChanges.subscribe(values => this.updateValid(values));
    this.formStatusChangeSubscription = this.form.control.statusChanges.subscribe(values => this.updateValid(values));
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    super.ngOnDestroy();
  }

  public onChangesSaved(): void {
    this.userProfile = this.tempModel;
    this.tempModel = this.management.cloneProfile(this.userProfile);
    this.state.isLoading = false;
    this.state.isEditMode = false;
  }

  public discardChanges(): void {
    this.state.isEditMode = false;
    if (this.userProfile.isNew) {
      let navigation: UserProfileNavigationService = new UserProfileNavigationService(this.router, this.route, this.location);
      navigation.discardNewUser();
    } else {
      this.tempModel = this.management.cloneProfile(this.userProfile);
    }
  }

  public getSaveData(): UserProfileModel {
    return this.tempModel;
  }

  private updateValid(values: any[]): void {
    this.m_isValid = this.form.valid;
    this.validStateUpdated.next(this.m_isValid);
  }

  private updateList(): void {
    if (!this.lookupService) return;

    this.isLoading = true;
    this.lookupService.getLookup({ lookupType: LookupType.employeeList })
      .then((lookup: Lookup) => {
        this.employees = lookup.items;
        this.isLoading = false;
      }).catch((reason: any) => {
        this.employees = [];
        this.isLoading = false;
      });
  }

}
