
<div class="providers-tabs flex left">

  <div class="providers-tabs__slider">
    <ul #list class="providers-tabs__list flex flex-direction-row">
      <li class="providers-tabs__item" [class.active]="activeTab0" (click)="onClickTab(0)">
          <span class="header-title__text">Organization Sync(Migration)</span>
      </li>
      <li class="providers-tabs__item" [class.active]="activeTab1" (click)="onClickTab(1)">
           <span class="header-title__text">Employee Sync(Migration) </span></li>
          <li class="providers-tabs__item" [class.active]="activeTab2" (click)="onClickTab(2)">
                   <span class="header-title__text">Benefit-Deduction Sync(Migration)</span>
            </li>
            <li class="providers-tabs__item" [class.active]="activeTab3" (click)="onClickTab(3)">
                <span class="header-title__text"> CostCenter Sync(Migration) </span></li>
            <li class="providers-tabs__item" [class.active]="activeTab4" (click)="onClickTab(4)">
              <span class="header-title__text"> Secondary Positions(Migration) </span>
            </li>
     
    </ul>
  </div>

  <ng-container *ngIf="activeTab0">
    <slx-organizations></slx-organizations>
  </ng-container>
  <ng-container *ngIf="activeTab1">
    <slx-employee-sync></slx-employee-sync>
  </ng-container>
  <ng-container *ngIf="activeTab2">
  <slx-benefitdeduction-sync></slx-benefitdeduction-sync>
</ng-container>
<ng-container *ngIf="activeTab3">
  <slx-laborcode-sync></slx-laborcode-sync>
</ng-container>
<ng-container *ngIf="activeTab4">
  <slx-wfm-ngp-secondary-positions-sync [syncType]="type"></slx-wfm-ngp-secondary-positions-sync>
</ng-container>
</div>

