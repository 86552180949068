import * as _ from 'lodash';
import { OnInit, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { dateTimeUtils } from '../../../../../common/utils/index';
import { appConfig } from '../../../../../app.config';
import { DateRange } from '../../../../../core/models/index';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { NgModel } from '@angular/forms';
var LMAbsenceExceptionsTabComponent = /** @class */ (function () {
    function LMAbsenceExceptionsTabComponent(manService, changeDetector) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.limitMinDate = null;
        this.limitMaxDate = null;
        this.startDate = null;
        this.endDate = null;
        this.loaDates = null;
        this.exceptions = [];
        this.customDates = [];
        this.hasEqual = false;
        this.hasOverlapped = false;
        this.hasExceededCustom = false;
        this.hasExcludedCustom = false;
        this.hasExceededRepeatbaleDates = false;
        this.hasExcludedStartEnd = false;
        this.isIntermittent = false;
        this.isContinuous = false;
        this.isRepeatable = false;
        this.appConfig = appConfig;
    }
    Object.defineProperty(LMAbsenceExceptionsTabComponent.prototype, "hasExceptions", {
        get: function () {
            return _.size(this.exceptions) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMAbsenceExceptionsTabComponent.prototype, "hasCustomDates", {
        get: function () {
            return _.size(this.customDates) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMAbsenceExceptionsTabComponent.prototype, "canModifyExceptions", {
        get: function () {
            var continuousIsCorrect = this.manService.isContinuous && this.manService.hasCorrectContinuousDates;
            var repeatableIsCorrect = (this.manService.isDaily || this.manService.isWeekly) && this.manService.hasCorrectRepeatableDates;
            var customIsCorrect = this.manService.isCustom && this.manService.hasCorrectCustomDates;
            return this.manService.canChange
                && (continuousIsCorrect || repeatableIsCorrect || customIsCorrect);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMAbsenceExceptionsTabComponent.prototype, "hasEstimatedDates", {
        get: function () {
            return this.manService.hasEstimatedDates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMAbsenceExceptionsTabComponent.prototype, "canAddException", {
        get: function () {
            return this.isValidDate(this.startDate) && this.isValidDate(this.endDate)
                && !this.hasEqual
                && !this.hasOverlapped
                && !this.hasExceededCustom
                && !this.hasExcludedCustom
                && !this.hasExceededRepeatbaleDates
                && !this.hasExcludedStartEnd;
        },
        enumerable: true,
        configurable: true
    });
    LMAbsenceExceptionsTabComponent.prototype.ngOnInit = function () {
        this.isIntermittent = this.manService.isIntermittent;
        this.isContinuous = this.manService.isContinuous;
        this.isRepeatable = this.manService.isDaily || this.manService.isWeekly || this.manService.isMonthly;
        this.customDates = this.manService.getCustomDates();
        this.exceptions = this.manService.getExceptions();
        this.loaDates = this.manService.getLoaDates();
        this.updateDefaultDatesLimit();
        this.updateDatesLimitForImtermittent();
    };
    LMAbsenceExceptionsTabComponent.prototype.ngAfterContentChecked = function () {
        this.changeDetector.detectChanges();
    };
    LMAbsenceExceptionsTabComponent.prototype.onAddException = function () {
        var item = new DateRange(this.startDate, this.endDate);
        this.exceptions = this.exceptions.concat([item]);
        this.manService.setExceptions(this.exceptions);
        this.startDate = null;
        this.endDate = null;
    };
    LMAbsenceExceptionsTabComponent.prototype.onDeleteException = function (index) {
        this.exceptions = _.filter(this.exceptions, function (ex, i) { return i !== index; });
        this.manService.setExceptions(this.exceptions);
    };
    LMAbsenceExceptionsTabComponent.prototype.onChangeDate = function () {
        this.hasEqual = this.hasStartAndEndAreEqual();
        this.hasOverlapped = this.hasOverlappedWithExceptions();
        if (this.isIntermittent) {
            if (this.customDates.length > 1) {
                this.hasExceededCustom = this.hasExceededCustomDates();
                this.hasExcludedCustom = this.hasExcludedAllCustomDates();
            }
            if (this.isRepeatable) {
                this.hasExceededRepeatbaleDates = this.hasExceededRepeatableDates();
            }
        }
        if (this.isContinuous) {
            this.hasExcludedStartEnd = this.hasExcludedStartEndDates();
        }
    };
    Object.defineProperty(LMAbsenceExceptionsTabComponent.prototype, "startMaxDate", {
        get: function () {
            if (this.endDateModel && this.endDateModel.valid) {
                return this.endDate;
            }
            else {
                return this.limitMaxDate;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMAbsenceExceptionsTabComponent.prototype, "endMinDate", {
        get: function () {
            if (this.startDateModel && this.startDateModel.valid) {
                return this.startDate;
            }
            else {
                return this.limitMinDate;
            }
        },
        enumerable: true,
        configurable: true
    });
    LMAbsenceExceptionsTabComponent.prototype.updateDefaultDatesLimit = function () {
        var _a = this.manService.getLoaDates(), startDate = _a.startDate, endDate = _a.endDate;
        if (_.isDate(startDate) && _.isDate(endDate) && endDate.getTime() > startDate.getTime()) {
            this.limitMinDate = startDate;
            this.limitMaxDate = endDate;
        }
    };
    LMAbsenceExceptionsTabComponent.prototype.updateDatesLimitForImtermittent = function () {
        if (this.isIntermittent && this.hasCustomDates) {
            if (this.customDates.length === 1) {
                var _a = _.head(this.customDates), startDate = _a.startDate, endDate = _a.endDate;
                this.limitMinDate = startDate;
                this.limitMaxDate = endDate;
            }
            else {
                var _b = this.geMinMaxCustomDates(), minStart = _b.minStart, maxEnd = _b.maxEnd;
                this.limitMinDate = minStart;
                this.limitMaxDate = maxEnd;
            }
        }
    };
    LMAbsenceExceptionsTabComponent.prototype.hasStartAndEndAreEqual = function () {
        if (_.isDate(this.startDate) && _.isDate(this.endDate)) {
            return dateTimeUtils.getTime(this.startDate) === dateTimeUtils.getTime(this.endDate);
        }
        return false;
    };
    LMAbsenceExceptionsTabComponent.prototype.hasExcludedStartEndDates = function () {
        var _a = this.loaDates || new DateRange(null, null), sDate = _a.startDate, eDate = _a.endDate;
        if (_.isDate(this.startDate) && _.isDate(this.endDate) && _.isDate(sDate) && _.isDate(eDate)) {
            return dateTimeUtils.getTime(this.startDate) === dateTimeUtils.getTime(sDate)
                && dateTimeUtils.getTime(this.endDate) === dateTimeUtils.getTime(eDate);
        }
        return false;
    };
    LMAbsenceExceptionsTabComponent.prototype.hasOverlappedWithExceptions = function () {
        var _this = this;
        if (_.size(this.exceptions) > 0 && _.isDate(this.startDate) && _.isDate(this.endDate)) {
            var noOverlapping = _.every(this.exceptions, function (d) {
                var exceptionsAreOutside = dateTimeUtils.getTime(_this.endDate) <= dateTimeUtils.getTime(d.startDate)
                    || dateTimeUtils.getTime(_this.startDate) >= dateTimeUtils.getTime(d.endDate);
                var exceptionsArentEqual = dateTimeUtils.getTime(_this.startDate) !== dateTimeUtils.getTime(d.startDate)
                    && dateTimeUtils.getTime(_this.endDate) !== dateTimeUtils.getTime(d.endDate);
                return exceptionsAreOutside && exceptionsArentEqual;
            });
            return !noOverlapping;
        }
        return false;
    };
    LMAbsenceExceptionsTabComponent.prototype.hasExceededCustomDates = function () {
        var _this = this;
        if (this.hasCustomDates && _.isDate(this.startDate) && _.isDate(this.endDate)) {
            var exceptionWithinCustomDates = _.some(this.customDates, function (d) { return dateTimeUtils.getTime(_this.startDate) >= dateTimeUtils.getTime(d.startDate)
                && dateTimeUtils.getTime(_this.endDate) <= dateTimeUtils.getTime(d.endDate); });
            return !exceptionWithinCustomDates;
        }
        return false;
    };
    LMAbsenceExceptionsTabComponent.prototype.hasExcludedAllCustomDates = function () {
        var _this = this;
        if (this.hasCustomDates && _.isDate(this.startDate) && _.isDate(this.endDate)) {
            var _a = this.geMinMaxCustomDates(), minStart = _a.minStart, maxEnd = _a.maxEnd;
            var completelyEqualOrGreater = dateTimeUtils.getTime(this.startDate) <= dateTimeUtils.getTime(minStart) && dateTimeUtils.getTime(this.endDate) >= dateTimeUtils.getTime(maxEnd);
            return completelyEqualOrGreater
                || _.some(this.customDates, function (d) {
                    return (dateTimeUtils.getTime(_this.startDate) <= dateTimeUtils.getTime(d.startDate) && dateTimeUtils.getTime(_this.endDate) >= dateTimeUtils.getTime(d.endDate));
                });
        }
        return false;
    };
    LMAbsenceExceptionsTabComponent.prototype.hasExceededRepeatableDates = function () {
        var _this = this;
        if (_.isDate(this.startDate) && _.isDate(this.endDate)) {
            var absenceDays = this.manService.getAbsenceDays();
            var exceptionWithinAbsenceDates = _.some(absenceDays, function (ad) { return dateTimeUtils.getTime(_this.startDate) >= dateTimeUtils.getTime(ad.startDate) && dateTimeUtils.getTime(_this.endDate) <= dateTimeUtils.getTime(ad.endDate); });
            return !exceptionWithinAbsenceDates;
        }
        return false;
    };
    LMAbsenceExceptionsTabComponent.prototype.geMinMaxCustomDates = function () {
        var sortedByStart = _.sortBy(this.customDates, function (r) { return dateTimeUtils.getTime(r.startDate); });
        var sortedByEnd = _.sortBy(this.customDates, function (r) { return dateTimeUtils.getTime(r.endDate); });
        return { minStart: _.head(sortedByStart).startDate, maxEnd: _.last(sortedByEnd).endDate };
    };
    LMAbsenceExceptionsTabComponent.prototype.isValidDate = function (date) {
        return _.isDate(date) && dateTimeUtils.validateDate(date);
    };
    return LMAbsenceExceptionsTabComponent;
}());
export { LMAbsenceExceptionsTabComponent };
