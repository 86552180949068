import {  IColumnSettings } from '../../../core/models/index';

export class WcIncidentRosterState {
  public columns: IColumnSettings[];
  public columnsMap: StringMap<IColumnSettings>;

  public createColumns(): void {
    this.columns = [
      {
        name: 'organization',
        description: 'Facility',
        displayed: true,
      },
      {
        name: 'position',
        description: 'Position',
        displayed: true,
      },
      {
        name: 'reportDateTime',
        description: 'Date',
        displayed: true,
      },
      {
        name: 'injuryType',
        description: 'Injury Type',
        displayed: true,
      },
      {
        name: 'incidentDateTime',
        description: 'Occurrence Date',
        displayed: true,
      },
      {
        name: 'bodyPart',
        description: 'Body Part',
        displayed: true,
      },
      {
        name: 'oshaDaysLost',
        description: 'Days Lost',
        displayed: true,
      },
      {
        name: 'isOshaRecordable',
        description: 'OSHA',
        displayed: true,
      },
      {
        name: 'isWorkersComp',
        description: 'Workers\' Comp',
        displayed: true,
      },
      {
        name: 'medicalCost',
        description: 'Medical Cost',
        displayed: true,
      },
      {
        name: 'indemnityCost',
        description: 'Indemnity Cost',
        displayed: true,
      },
      {
        name: 'addedBy',
        description: 'Created By',
        displayed: true,
      },
      {
        name: 'updatedBy',
        description: 'Last Updated By',
        displayed: true,
      }
    ];

    this.mapColumns();
  }

  public mapColumns(): void {
    this.columnsMap = {};
    this.columns.forEach((col: IColumnSettings) => {
      this.columnsMap[col.name] = col;
    });
  }
}
