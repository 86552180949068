import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { createValuAccessor } from '../../../common/utils/index';
import { DateRangeWithDate, isSameRanges, isCorrectRange } from '../../../components-library/models/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { RangeType } from '../../models/index';
import { CalendarViewTypes, CalendarViewTypesDefinitions } from '../../models/index';
import { PopperContent } from 'ngx-popper';

@Component({
  moduleId: module.id,
  selector: 'slx-range-navigator',
  templateUrl: 'range-navigator.component.html',
})
export class RangeNavigatorComponent {
  @Input()
  public set selectedDate(value: Date) {
    this.m_currentDate = value;
    this.checkRequiredDate();
  }

  // Monday, Tuesday, Wednesday etc.
  @Input()
  public set startOfWeek(value: string) {
    if (_.indexOf(this.daysOfWeek, value) === -1) {
      throw new Error(`The parameter "startOfWeek" does not match any day of week. Value: "${value}"`);
    }

    this.m_startOfWeek = value;
    this.checkRequiredDate();
  }

  public get startOfWeek(): string {
    return this.m_startOfWeek;
  }

  // Day | Week
  @Input()
  public set selectedRangeType(value: string) {
    this.m_currentRangeType = _.cloneDeep(this.rangeTypes[value] || this.rangeTypes.Day);
    this.checkRequiredDate();
  }

  @Input()
  public minDate: Date;

  @Input()
  public maxDate: Date;

  @Input()
  public readonly: boolean;

  @Input()
  public calendarCellClass: string | Function;

  @Output()
  public rangeChanged: EventEmitter<DateRangeWithDate>;

  @Output()
  public rangeTypeChanged: EventEmitter<RangeType>;

  @ViewChild('popperCalendar', {static: true})
  public popper: PopperContent;
  public isShown: boolean;
  public rangeTypes: StringMap<RangeType>;
  public rangeNames: string[];
  public viewType: CalendarViewTypesDefinitions;
  public currentRange: DateRangeWithDate;
  public appConfig: IApplicationConfig;
  private m_currentRangeType: RangeType;
  private m_currentDate: Date;
  private m_startOfWeek: string;
  private daysOfWeek: string[];
  private init: boolean;

  constructor() {
    this.appConfig = appConfig;
    this.rangeChanged = new EventEmitter<DateRangeWithDate>();
    this.rangeTypeChanged = new EventEmitter<RangeType>();
    this.rangeNames = ['Day', 'Week'];
    this.rangeTypes = this.createRangeTypes(this.rangeNames);
    this.currentRange = null;
    this.daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    this.isShown = false;
    this.init = true;
  }

  public get currentDate(): Date {
    return this.m_currentDate;
  }

  public set currentDate(value: Date) {
    if (this.init &&_.isDate(this.m_currentDate) && !moment(this.m_currentDate).isSame(value)) {
      this.m_currentDate = value;
      if (this.popper instanceof PopperContent) {
        this.popper.hide();
      }
      this.changeRange();
    }
  }

  public get currentRangeType(): RangeType {
    return this.m_currentRangeType;
  }

  public set currentRangeType(value: RangeType) {
    if (
      this.init && _.isObject(value) && _.isString(value.name) &&
      (!this.m_currentRangeType || this.m_currentRangeType.name !== value.name)
    ) {
      this.m_currentRangeType = value;
      this.rangeTypeChanged.next(value);
      this.changeRange();
    }
  }

  public onNavButtonClick(event: MouseEvent, isNext: boolean): void {
    if (this.readonly) return;

    const currentDate: moment.Moment = moment(this.currentDate).startOf('day');
    let newCurrentDate: moment.Moment = null;
    switch(this.currentRangeType.name) {
      case this.rangeTypes.Day.name:
        if (isNext) {
          newCurrentDate = currentDate.add(1, 'days');
        } else {
          newCurrentDate = currentDate.subtract(1, 'days');
        }
        break;
      case this.rangeTypes.Week.name:
        const currentRange: DateRangeWithDate = this.evaluateWeekRange(currentDate);
        if (isNext) {
          newCurrentDate = moment(currentRange.endDate).add(1, 'days');
        } else {
          const startDate: moment.Moment = moment(currentRange.startDate).subtract(1, 'days');
          const prevRange: DateRangeWithDate = this.evaluateWeekRange(startDate);
          newCurrentDate = moment(prevRange.startDate);
        }
        break;
      default:
        throw new Error(`Current range type does not match any values of "rangeTypes" object. Value: "${this.currentRangeType.name}"`);
    }

    this.currentDate = newCurrentDate.toDate();
  }

  // See #issueWithPopperVisibility
  public onToggleVisibility(isShown: boolean): void {
    this.isShown = isShown;
  }

  public getRangeTypes(): RangeType[] {
    return _.values(this.rangeTypes);
  }

  public isWeekRangeType(): boolean {
    return this.currentRangeType.name === this.rangeTypes.Week.name;
  }

  private changeRange(): void {
    const currentDate: moment.Moment = moment(this.currentDate).startOf('day');
    let newRange: DateRangeWithDate = null;
    switch(this.currentRangeType.name) {
      case this.rangeTypes.Day.name:
        newRange = new DateRangeWithDate(this.currentDate, currentDate.toDate(), currentDate.endOf('day').toDate());
        break;
      case this.rangeTypes.Week.name:
        newRange = this.evaluateWeekRange(currentDate);
        break;
      default:
      throw new Error(`Current range type does not match any values of "rangeTypes" object. Value: "${this.currentRangeType.name}"`);
    }

    if (!this.currentRange || !isSameRanges(this.currentRange, newRange)) {
      this.currentRange = newRange;
      this.rangeChanged.next(newRange);
    }
  }

  private evaluateWeekRange(date: moment.Moment): DateRangeWithDate {
    let newRange: DateRangeWithDate = null;
    const selectedDate: Date = date.toDate();
    const weekLengthInDays: number = 6;
    const indexOfCurrentDay: number = date.weekday();
    const indexOfStartDay: number = _.indexOf(this.daysOfWeek, this.startOfWeek);
    const currentDay: string = this.daysOfWeek[indexOfCurrentDay];
    if (indexOfCurrentDay === indexOfStartDay) {
      newRange = new DateRangeWithDate(selectedDate, date.toDate(), date.add(weekLengthInDays, 'days').endOf('day').toDate());
    } else if (indexOfCurrentDay > indexOfStartDay) {
      const startDay: moment.Moment = date.subtract(indexOfCurrentDay - indexOfStartDay, 'days').startOf('day');
      newRange = new DateRangeWithDate(selectedDate, startDay.toDate(), startDay.add(weekLengthInDays, 'days').endOf('day').toDate());
    } else if (indexOfCurrentDay < indexOfStartDay) {
      const endDay: moment.Moment = date.add(indexOfStartDay - indexOfCurrentDay - 1, 'days').endOf('day');
      const endDayDate: Date = endDay.toDate();
      newRange = new DateRangeWithDate(selectedDate, endDay.subtract(weekLengthInDays, 'days').startOf('day').toDate(), endDayDate);
    }

    return newRange;
  }

  private checkRequiredDate(): void {
    if (
      _.isDate(this.currentDate) &&
      _.isString(this.startOfWeek) &&
      _.isObject(this.currentRangeType) &&
      _.isString(this.currentRangeType.name)
    ) {
      this.changeRange();
    }
  }

  private createRangeTypes(rangeNames: string[]): StringMap<RangeType> {
    return _.reduce(rangeNames, (accumulator: StringMap<RangeType>, rangeName: string, index: number) => {
      accumulator[rangeName] = new RangeType(index, rangeName);

      return accumulator;
    }, {});
  }
}
