/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wc-report-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../components-library/components/buttons/collapse-button/collapse-button.component.ngfactory";
import * as i4 from "../../../../../components-library/components/buttons/collapse-button/collapse-button.component";
import * as i5 from "../../../../../components-library/models/collapse-component";
import * as i6 from "../../../../../components-library/directives/collapse/collapse-by-css.directive";
import * as i7 from "./wc-report-section.component";
import * as i8 from "../../../services/crud/wc-report-management.service";
import * as i9 from "../../../../../common/services/change-management/change-management.service";
var styles_WcReportSectionComponent = [i0.styles];
var RenderType_WcReportSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WcReportSectionComponent, data: {} });
export { RenderType_WcReportSectionComponent as RenderType_WcReportSectionComponent };
function View_WcReportSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "header-title__badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notifications; _ck(_v, 1, 0, currVal_0); }); }
export function View_WcReportSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "report-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "report-section-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "report-section-header-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "header-item header-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcReportSectionComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "report-section-header-button-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "slx-collapse-button", [["className", "slx-white-icon"]], null, [[null, "isCollapsedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isCollapsedChange" === en)) {
        var pd_0 = (_co.collapsedChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CollapseButtonComponent_0, i3.RenderType_CollapseButtonComponent)), i1.ɵdid(11, 49152, null, 0, i4.CollapseButtonComponent, [], { className: [0, "className"], isCollapsed: [1, "isCollapsed"] }, { isCollapsedChange: "isCollapsedChange" }), i1.ɵprd(2048, null, i5.COLLAPSE_COMPONENT, null, [i4.CollapseButtonComponent]), i1.ɵdid(13, 4341760, null, 0, i6.CollapseByCssDirective, [i5.COLLAPSE_COMPONENT, i1.Renderer2], { elementToCollapse: [0, "elementToCollapse"] }, null), (_l()(), i1.ɵeld(14, 0, [["collapseContent", 1]], null, 1, "div", [["class", "report-section-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.notifications > 0); _ck(_v, 8, 0, currVal_2); var currVal_3 = "slx-white-icon"; var currVal_4 = _co.isCollapsed; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 14); _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.icon, ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 6, 0, currVal_1); }); }
export function View_WcReportSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-wc-report-section", [], null, null, null, View_WcReportSectionComponent_0, RenderType_WcReportSectionComponent)), i1.ɵdid(1, 245760, null, 0, i7.WcReportSectionComponent, [i8.WCReportManagementService, i1.ChangeDetectorRef, i9.ChangeManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WcReportSectionComponentNgFactory = i1.ɵccf("slx-wc-report-section", i7.WcReportSectionComponent, View_WcReportSectionComponent_Host_0, { notifications: "notifications", title: "title", icon: "icon", validatingFunction: "validatingFunction" }, {}, [".panel-content"]);
export { WcReportSectionComponentNgFactory as WcReportSectionComponentNgFactory };
