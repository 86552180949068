import * as _ from 'lodash';
import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, ViewChild } from '@angular/core';
import { IFilteredItems, PagingData } from '../../../../../core/models/index';
import { PbjExportApiService } from '../../../services/index';
import { PBJExportLogError } from '../../../models/index';
import { ServerFilterService } from '../../../../../core/services/index';
import { PbjDetailsBasicGridComponent } from '../pbj-details-basic-grid/pbj-details-basic-grid.component';
import { GridExportType } from '../../../models/pbj-export/grid-export-type';
import { StateManagementService } from '../../../../../common/services/index';
import { PbjExportColumn } from '../../../models/pbj-export/pbj-export-column';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-details-errors-data-grid',
  templateUrl: 'pbj-details-errors-data-grid.component.html',
  styleUrls: ['pbj-details-errors-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerFilterService, StateManagementService]
})
export class PbjDetailsErrorsDataGridComponent extends PbjDetailsBasicGridComponent<PBJExportLogError> implements OnInit, OnChanges {

  @Input()
  public exportId: number;
  @Output()
  public loadStatus: EventEmitter<boolean>;
  @Input()
  public pbjIdConfigEnabled: boolean;

  public get exportFilename(): string {
    return `export-${this.exportId}-errors-data`;
  }

  protected readonly controlKey = 'PbjExportErrorsDataComponent';

  constructor(apiService: PbjExportApiService, serverFilterService: ServerFilterService, changeDetector: ChangeDetectorRef, stateManagement: StateManagementService) {
    super(apiService, serverFilterService, changeDetector, stateManagement);
  }

  public refreshData(): void {
    this.onLoadStatus(true);
    this.apiService.getPBJExportLogError(this.serverFilterService.createQuery(this.pagingData, this.gridState.state.sort), this.exportId)
      .then((val: IFilteredItems<PBJExportLogError>) => {
        this.records = val;
        this.onLoadStatus(false);
        this.showChanges();
      }).catch((reason: any) => {
        this.onLoadStatus(false);
      });
  }

  public loadAndExport(type: GridExportType): void {
    this.onLoadStatus(true);
    let zeroPaging: PagingData = { skip: 0 };
    this.apiService.getPBJExportLogError(this.serverFilterService.createQuery(zeroPaging, this.gridState.state.sort), this.exportId)
      .then((val: IFilteredItems<PBJExportLogError>) => {
        this.processExportData(type, val);
        this.onLoadStatus(false);
      }).catch((reason: any) => {
        this.onLoadStatus(false);
        this.exportRecords = null;
      });
  }

  protected applyDefaultSort(): void {
    this.gridState.state.sort = [{ field: 'errorId', dir: 'asc' }];
  }

  protected updateColumnsConfig(): void {
    super.updateColumnsConfig();
    const specificColumns: PbjExportColumn[] = [
      { field: 'errorId', displayTitle: 'Error/Warning Id', index: 1, width: 150 },
      { field: 'section', displayTitle: 'Section', index: 2, width: 150 },
      { field: 'field', displayTitle: 'Field', index: 3, width: 150 },
      { field: 'errorMessage', displayTitle: 'Error Message', index: 4, width: 150 },
      { field: 'employeeName', displayTitle: 'Name', index: 5, width: 150 },
      { field: 'employeePayrollNumber', displayTitle: 'Payroll No', index: 6, width: 150 },
      { field: 'referenceDate', displayTitle: 'Ref Date', index: 7, width: 150 },
      { field: 'entryType', displayTitle: 'Entry Type', index: 8, width: 150 }
    ];
    this.xlsExportColumns.push(...specificColumns);
  }

}

