<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid" [data]="gridState.view" [selectable]="{ mode: 'single' }"
  [rowSelected]="isRowSelected()" [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort" [filterable]="'menu'"
  [filter]="gridState.state.filter" (dataStateChange)="gridState.dataStateChange($event)">
  <kendo-grid-command-column title="" width="90">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div *ngIf="canEdit(dataItem)">
        <button type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r" title="Edit"
          (click)="onClickEdit(dataItem)" [disabled]="isDisabled">
          <i class="fas fa-pencil-alt" aria-hidden="true"></i>
        </button>
        <button type="button" class="slx-button slx-only-icon slx-toolbar" title="Delete" [popover]="acceptPopover"
          [disabled]="isDisabled">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <popover-content #acceptPopover title="Are you sure?" placement="auto bottom">
          <div>
            <button type="button" class="slx-button slx-narrow slx-margin-r"
              (click)="onClickDelete(dataItem, acceptPopover)">Yes</button>
            <button type="button" class="slx-button slx-narrow" (click)="onClickCancel(acceptPopover)">No</button>
          </div>
        </popover-content>
      </div>
      <div *ngIf="!canEdit(dataItem)" class="grid-org-icons">
        <i class="fas fa-arrow-square-up" aria-hidden="true" *ngIf="isParentOrgLevel(dataItem)"></i>
        <i class="fas fa-arrow-square-down" aria-hidden="true" *ngIf="!isParentOrgLevel(dataItem)"></i>
      </div>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-column field="orgLevelName" title="Org Level" width="250px">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Org Level</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.orgLevelName }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Description" field="description">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Description</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="grid-descr">
        <span class="grid-descr__text">{{ dataItem.description }}</span>
        <i aria-hidden="true" class="fas fa-map-marker-edit grid-descr__icon" *ngIf="dataItem.isOverridden"></i>
      </p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Street Addresss" field="address">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Street Addresss</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.address }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="City" field="city">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>City</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.city }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column width="70px" title="State" field="state">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>State</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.state }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column width="80px" title="Zip" field="zip">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Zip</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.zip }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Radius" field="localizedCurrentDistance">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Radius</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.localizedCurrentDistance }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column [sortable]="false" [filterable]="false" width="70px" title="Map">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Map</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <p class="grid-map" (click)="onClickMap(dataItem, $event)">
        <i aria-hidden="true" class="fas fa-map-marked grid-map__icon"></i>
      </p>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="User" field="createdBy">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>User</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.createdBy }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Date" filter="date" field="localizedCreatedDate">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Date</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.localizedCreatedDate }}</ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Allow Punch WhenLocation Service Disabled" filter="isAllowPunchWhenLocationServiceDisabled">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Allow Punch When Location Service Disabled</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-container *ngIf="dataItem.isAllowPunchWhenLocationServiceDisabled">
        Yes
      </ng-container>
      <ng-container *ngIf="!dataItem.isAllowPunchWhenLocationServiceDisabled">
        No
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel fileName="Geolocation.xlsx">
    <kendo-excelexport-column field="orgLevelName" title="Org Level"></kendo-excelexport-column>
    <kendo-excelexport-column field="description" title="Description"></kendo-excelexport-column>
    <kendo-excelexport-column field="address" title="Street Addresss"></kendo-excelexport-column>
    <kendo-excelexport-column field="city" title="City"></kendo-excelexport-column>
    <kendo-excelexport-column field="state" title="State"></kendo-excelexport-column>
    <kendo-excelexport-column field="zip" title="Zip"></kendo-excelexport-column>
    <kendo-excelexport-column field="localizedCurrentDistance" title="Radius"></kendo-excelexport-column>
    <kendo-excelexport-column field="createdBy" title="User"></kendo-excelexport-column>
    <kendo-excelexport-column field="localizedCreatedDate" title="Date"></kendo-excelexport-column>
  </kendo-grid-excel>
  <kendo-grid-pdf fileName="Geolocation.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
    <kendo-grid-column field="orgLevelName" title="Org Level"></kendo-grid-column>
    <kendo-grid-column field="description" title="Description"></kendo-grid-column>
    <kendo-grid-column field="address" title="Street Addresss"></kendo-grid-column>
    <kendo-grid-column field="city" title="City"></kendo-grid-column>
    <kendo-grid-column field="state" title="State"></kendo-grid-column>
    <kendo-grid-column field="zip" title="Zip"></kendo-grid-column>
    <kendo-grid-column field="localizedCurrentDistance" title="Radius"></kendo-grid-column>
    <kendo-grid-column field="createdBy" title="User"></kendo-grid-column>
    <kendo-grid-column field="localizedCreatedDate" title="Date"></kendo-grid-column>
  </kendo-grid-pdf>
</kendo-grid>