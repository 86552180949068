import * as tslib_1 from "tslib";
import { LookupEntity } from './lookup-entity';
var PositionGroup = /** @class */ (function (_super) {
    tslib_1.__extends(PositionGroup, _super);
    function PositionGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PositionGroup;
}(LookupEntity));
export { PositionGroup };
