import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import * as _ from 'lodash';

import { mutableSelect } from '../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { OrgLevel } from '../../../state-model/models/index';
import { AttendancePointsDefinition } from '../../../organization/models/lookup/index';
import { AttendancePointsConfigCategory, PayCodeModel, PayCodesContainer, PayCode } from '../../models/index';

import { organizationConfig } from '../../../organization/organization.config';

import { LookupApiService } from '../../../organization/services/lookup/lookup-api.service';
import { AttendancePointsConfigApiService, AttendancePointsConfigMapService, PayCodesApiService } from '../../services/index';

import { PopoverContentComponent, ChangeManagementService } from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';
import { NgForm } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-attendance-points-config',
  templateUrl: 'attendance-points-config.component.html',
  styleUrls: ['attendance-points-config.component.scss']
})
export class AttendancePointsConfigComponent implements OnInit, OnDestroy {
  public state: {
    isLoading: boolean;
  };
  public definitionsList: AttendancePointsDefinition[];
  public categoriesList: AttendancePointsConfigCategory[];
  public gridData: GridDataResult;
  public editedRowIndex: number;
  public editedEntry: AttendancePointsDefinition;
  public isAdding: boolean;
  public sort: SortDescriptor[] = [];

  public set selectedCategory(value: AttendancePointsConfigCategory) {
    this.m_selectedCategory = value;
    if (this.editedEntry && this.m_selectedCategory) {
      this.editedEntry.category.name = value.name;
    }
  }
  public get selectedCategory(): AttendancePointsConfigCategory {
    return this.m_selectedCategory;
  }

  public payCodes: PayCode[];

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @ViewChild('form', { static: true })
  private mainForm: NgForm;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private mainFormSubscription: Subscription;

  private m_selectedCategory: AttendancePointsConfigCategory;
  private deletingEntryId: number;
  private orgLevelId: number;


  constructor(
    private lookupApiService: LookupApiService,
    private attendancePointsConfigApiService: AttendancePointsConfigApiService,
    private attendancePointsConfigMapService: AttendancePointsConfigMapService,
    private changeService: ChangeManagementService,
    private payCodesApi: PayCodesApiService
  ) {
    this.state = {
      isLoading: false
    };
  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
      this.getDefinitions();
      this.getPayCodes();
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public addHandler(): void {
    this.closeEditor();

    let definition: AttendancePointsDefinition = new AttendancePointsDefinition();
    definition.id = 0;
    definition.category = { name: '' };
    definition.definition = '';

    this.isAdding = true;
    this.editedEntry = definition;
    this.grid.addRow(this.editedEntry);
    this.changeService.changeNotify();
  }

  public editHandler(event: { dataItem: AttendancePointsDefinition, rowIndex: number }): void {
    this.closeEditor();

    this.isAdding = false;
    this.editedEntry = Object.assign({}, event.dataItem);
    this.editedRowIndex = event.rowIndex;
    this.setSelectedCategory();
    this.grid.editRow(this.editedRowIndex);

    this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
      if (this.mainForm.dirty) {
        this.changeService.changeNotify();
      }
    });
  }

  public saveHandler(): void {
    if (this.isAdding) {
      this.addEntry(this.editedEntry);
    } else {
      const editedEntry: AttendancePointsDefinition = _.find(this.definitionsList, { 'id': this.editedEntry.id });
      _.assign(editedEntry, this.editedEntry);
      this.saveEntry(this.editedEntry);
    }
    this.closeEditor();
    this.refreshGrid();
  }

  public removeHandler(event: { dataItem: AttendancePointsDefinition, rowIndex: number }): void {
    this.deletingEntryId = event.dataItem.id;
  }

  public cancelHandler(): void {
    this.closeEditor();
    this.unsubscribeMainForm();
    this.changeService.clearChanges();
  }

  public sortChangeHandler(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);
    this.unsetSelectedCategory();
    this.isAdding = false;
    this.editedEntry = undefined;
    this.editedRowIndex = undefined;
  }

  public onPopoverAction(acceptPopover: PopoverContentComponent, isDelete: boolean): void {
    if (isDelete) {
      const index = _.findIndex(this.definitionsList, { 'id': this.deletingEntryId });
      this.definitionsList.splice(index, 1);

      this.deleteEntry(this.deletingEntryId);
      this.refreshGrid();
    }

    this.deletingEntryId = 0;
    acceptPopover.hide();
  }

  public getPayCodesNames(points: AttendancePointsDefinition): string {
    if (!points || !points.exceptions) return '';
    return _.map(points.exceptions, pc => pc.description).join(', ');
  }

  private addEntry(entry: AttendancePointsDefinition): void {
    this.state.isLoading = true;
    this.attendancePointsConfigApiService.addEntry(entry, this.orgLevelId)
      .then((addedEntry: AttendancePointsDefinition) => {
        this.definitionsList.push(addedEntry);
        this.handleApiCallCompletion();
      })
      .catch(() => {
        this.handleApiCallCompletion();
      });
  }

  private saveEntry(entry: AttendancePointsDefinition): void {
    this.state.isLoading = true;
    this.attendancePointsConfigApiService.saveEntry(entry, this.orgLevelId)
      .then(() => {
        this.handleApiCallCompletion();
      }).catch(() => {
        this.handleApiCallCompletion();
      });
  }

  private deleteEntry(entryId: number): void {
    this.state.isLoading = true;
    this.attendancePointsConfigApiService.deleteEntry(entryId)
      .then(() => {
        this.stopLoading();
      })
      .catch(() => {
        this.stopLoading();
      });
  }

  private handleApiCallCompletion(): void {
    this.stopLoading();
    this.unsubscribeMainForm();
    this.changeService.clearChanges();
  }

  private unsubscribeMainForm(): void {
    if (this.mainFormSubscription) {
      this.mainFormSubscription.unsubscribe();
    }
  }

  private stopLoading(): void {
    this.state.isLoading = false;
  }

  private setSelectedCategory(): void {
    const name = this.editedEntry.category.name;
    const selectedCategory = new AttendancePointsConfigCategory();
    selectedCategory.id = name;
    selectedCategory.name = name;

    this.selectedCategory = selectedCategory;
  }

  private unsetSelectedCategory(): void {
    if (this.selectedCategory) {
      this.selectedCategory = null;
    }
  }

  private getPayCodes(): void {
    this.state.isLoading = true;
    this.payCodes = [];
    this.payCodesApi.getPayCodes(this.orgLevelId)
      .then((value: PayCodesContainer) => {
        this.payCodes = _.map(value.records, model => model.payCode);
        this.state.isLoading = false;
      }).catch((reason: any) => {
        this.payCodes = [];
        this.state.isLoading = false;
      });
  }

  private getDefinitions(): void {
    this.state.isLoading = true;
    this.lookupApiService.getAttendancePointsDefinitions(this.orgLevelId)
      .then(this.definitionsReady.bind(this))
      .catch(this.definitionsFailed.bind(this));
  }

  private definitionsReady(definitions: AttendancePointsDefinition[]): void {
    this.definitionsList = definitions;
    this.state.isLoading = false;
    this.categoriesList = this.attendancePointsConfigMapService.mapAttendancePointsCategories(this.definitionsList);

    this.refreshGrid();
  }

  private definitionsFailed(reason: any): void {
    this.state.isLoading = false;
    this.definitionsList = null;
  }

  private refreshGrid(): void {
    if (!this.definitionsList) {
      this.gridData = null;
      return;
    }
    this.gridData = {
      data: orderBy(this.definitionsList, this.sort),
      total: this.definitionsList.length
    };
  }
}
