import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { mutableSelect, unsubscribe, destroyService } from '../../../../../core/decorators/index';
import { EmpType, Organization, Department, UserAction } from '../../../../../organization/models/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { RangeDates, IRangeDates } from '../../../../../common/models/range-dates';
import { PBJManualTimeEntity, PBJManualTimeEntry } from '../../../models/index';
import { PbjManualEntryManagementService } from '../../../services/pbj-manual-entry/pbj-manual-entry-management.service';
import { PbjManualEntryGridComponent } from '../pbj-manual-entry-grid/pbj-manual-entry-grid.component';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-manual-entry',
  templateUrl: 'pbj-manual-entry.component.html',
  styleUrls: ['pbj-manual-entry.component.scss'],
  providers: [PbjManualEntryManagementService]
})
export class PbjManualEntryComponent implements OnInit, OnDestroy {
  public state: {
    isLoading: boolean;
  };
  public pageSize: number;
  public appConfig: IApplicationConfig;
  public orgLevel: OrgLevel;
  public startDate: Date;
  public endDate: Date;
  public canAdd: boolean;
  public canRemove: boolean;
  public maxDateRange = 1 * 60 * 60 * 24 * 100; // max 100 days
  public maxDateRangeError = 'You cannot specify range more than 100 days';
  public canExportData: boolean = false;

  @ViewChild('empDataGrid', {static: false})
  public empDataGrid: PbjManualEntryGridComponent;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private onStatusSubscription: Subscription;
  @unsubscribe()
  private onSelectedSubscription: Subscription;
  @destroyService()
  private managementService: PbjManualEntryManagementService;
  private selectedEntries: PBJManualTimeEntry[];
  private organizations: Organization[];
  private selectedDates: IRangeDates;

  constructor(managementService: PbjManualEntryManagementService) {
    this.appConfig = appConfig;
    this.managementService = managementService;
    this.state = {
      isLoading: true
    };
    this.pageSize = 20;
    this.canAdd = true;
    this.canRemove = false;
    this.endDate = moment().endOf('day').toDate();
    this.startDate = moment(this.endDate).subtract(7, 'day').toDate();
    this.managementService.dateFilterChanged(this.startDate, this.endDate);
  }

  public ngOnInit(): void {
    this.managementService.subscribeToUserActionsChanged((actions: UserAction[]) => {
      this.canExportData = _.some(actions, x => x.name === 'Export To Excel');
    });
    
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && _.isNumber(orgLevel.id)) {
        this.orgLevel = orgLevel;
        this.managementService.orgLevelChanged(this.orgLevel);
      }
    });

    this.onStatusSubscription = this.managementService.onLoadStatus$.subscribe((value: boolean) => {
      this.state.isLoading = value;
    });

    this.onSelectedSubscription = this.managementService.onEditState$.subscribe((isCanEdit: boolean) => {
      this.canAdd = isCanEdit;
    });

    this.onSelectedSubscription = this.managementService.onEntriesSelected$.subscribe((selectedEntries: PBJManualTimeEntry[]) => {
      this.selectedEntries = selectedEntries;
      this.canRemove = this.selectedEntries.length > 0;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onDataRangeChange({ startDate, endDate }: IRangeDates): void {
    this.selectedDates = { startDate, endDate };
    this.startDate = startDate;
    this.endDate = endDate;
    this.managementService.dateFilterChanged(startDate, endDate);
  }

  public onRemoveEntries(): void {
    const selectedEntriesIds: number[] = _.map(this.selectedEntries, (entry) => entry.id);
    this.managementService.removeEntries(selectedEntriesIds);
  }

  public onAddEntry(): void {
    const entry: PBJManualTimeEntry = this.managementService.createPBJManualEntry();
    this.managementService.addEntryToRow(entry);
  }

  public onExportToExcel(): void {
    this.managementService.exportToExcel();
  }

  public onExportToPdf(): void {
    this.managementService.exportToPdf();
  }

}
