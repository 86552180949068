<div class="col-xs-12" *ngIf="decimalLimit">
  <span class="primary-position-title">Primary Position</span>
  <div class="primary-position col-xs-12">
    <h4 class="brcramp">{{primaryPosition?.orgNavBreadcrumb}}</h4>
    <hr>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator class="slx-input-long-text-dots">
        <input slx-input type="text" maxlength="255"  [slxTooltip]="primaryPosition?.position.fieldValue.name" [readonly]="true" name="prPosition" placeholder="Position" [inputPolicy]="primaryPosition?.position.Policy" [ngModel]="primaryPosition?.position.fieldValue.name">
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" maxlength="255" [readonly]="true" name="prPayPolicy" placeholder="Pay Policy" [ngModel]="primaryPosition?.payPolicy.fieldValue.name">
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" maxlength="255" [readonly]="true" name="prShiftDiffPolicy" placeholder="Shift Diff Policy" [ngModel]="primaryPosition?.shiftDiffPolicy.fieldValue.name">
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-money-input slx-input
          inputValueAsString
          [decimalLimit]="decimalLimit"
          [requireDecimal]="false"
          [allowNegative]="false"
          [readonly]="true"
          [inputPolicy]="primaryPosition?.hourlyRate.Policy"
          [(ngModel)]="primaryPosition?.hourlyRate.fieldValue"
          name="prHourlyRate"
          placeholder="{{getRateTitle}}">
        </slx-money-input>
      </slx-input-decorator>
    </div>
  </div>
</div>
<form novalidate #form="ngForm">
  <div class="col-xs-12">
    <div class="secondary-positions-container" *ngFor="let position of actualPositions; let idx=index">
      <span class="secondary-position-title">Secondary Position</span>
      <div class="additional-position col-xs-12">
        <button type="button" class="remove-position-button" (click)="onRemovePosition(position)" *ngIf="state.isEditMode && empPositions?.actions.canDelete">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>

        <div class="col-sm-6 col-lg-3">
          <slx-input-decorator class="slx-input-long-text-dots">
            <slx-dropdown-input slx-input   [required]="true" [lookup]="{lookupType: 'userAccesibleOrganizationsCrossCorpActionCheck'}" [readonly]="!organizationEditable(position)"
              name="organization{{idx}}" placeholder="Organization"
              emptyOption [(ngModel)]="position.organization.fieldValue" (ngModelChange)="onOrganizationChanged(position, $event,idx)">
            </slx-dropdown-input>
            <span *ngIf="!organizationEditable(position)" [slxTooltip]="position.organization.fieldValue?.name" > </span>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="col-sm-6 col-lg-3">
          <slx-input-decorator class="slx-input-long-text-dots" >
            <slx-dropdown-input slx-input   [required]="true" [lookup]="departmentLookup[idx]" [readonly]="!departmentEditable(position)"
              (ngModelChange)="onDepartmentChanged(position, $event,idx)" name="department{{idx}}" placeholder="Department"
              emptyOption [(ngModel)]="position.department.fieldValue">
            </slx-dropdown-input>
           <span *ngIf="!departmentEditable(position)" [slxTooltip]="position.department.fieldValue?.name"> </span>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="col-sm-6 col-lg-3">
          <slx-input-decorator class="slx-input-long-text-dots">
            <slx-dropdown-input slx-input   [required]="true" [lookup]="positionLookup[idx]" [readonly]="!positionEditable(position)" name="position{{idx}}"
              emptyOption (ngModelChange)="onPositionChanged($event, idx)" placeholder="Position" [inputPolicy]="position.position.Policy" [(ngModel)]="position.position.fieldValue">
            </slx-dropdown-input>
            <span *ngIf="!positionEditable(position)" [slxTooltip]="position.position.fieldValue?.name"> </span>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="col-sm-6 col-lg-3">
          <ng-container *ngIf="startDateEditable(position)">
          <slx-input-decorator>
            <slx-date-picker-ngx slx-input
              [(ngModel)]="position.startDate.fieldValue"
              [required]="true"
              [acceptNullDate]="false"
              [minDate]="positionMinDate"
              placeholder="Start Date"
              name="startDate{{idx}}"
            ></slx-date-picker-ngx>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </ng-container>
        <ng-container *ngIf="!startDateEditable(position)">
          <slx-input-decorator>
            <slx-date-picker-ngx slx-input
              [ngModel]="position.startDate.fieldValue"
              [readonly]="true"
              placeholder="Start Date"
              name="startDate{{idx}}"
            ></slx-date-picker-ngx>
          </slx-input-decorator>
        </ng-container>
        </div>
        <div class="col-sm-6 col-lg-3">
          <slx-input-decorator>
            <slx-dropdown-input slx-input
              [required]="true"
              [lookup]="payPolicyLookup[idx]"
              [readonly]="!payPolicyEditable(position)"
              [(ngModel)]="position.payPolicy.fieldValue"
              (ngModelChange)="onChangeCriticalData(position.startDate.fieldValue)"
              name="payPolicy{{idx}}"
              autoSelectFirst="true"
              placeholder="Pay Policy"
            ></slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="col-sm-6 col-lg-3">
          <slx-input-decorator>
            <slx-dropdown-input slx-input
              [required]="false"
              [lookup]="shiftDiffPolicyLookup[idx]"
              [readonly]="!shiftDiffPolicyEditable(position)"
              [(ngModel)]="position.shiftDiffPolicy.fieldValue"
              (ngModelChange)="onChangeCriticalData(position.startDate.fieldValue)"
              name="shiftDiffPolicy{{idx}}"
              placeholder="Shift Diff Policy"
            ></slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="col-sm-6 col-lg-3">
          <slx-input-decorator>
            <slx-money-input slx-input
              inputValueAsString
              [decimalLimit]="decimalLimit"
              [requireDecimal]="false"
              [allowNegative]="false"
              [readonly]="!hourlyRateEditable(position)"
              [inputPolicy]="position.hourlyRate.Policy"
              [(ngModel)]="position.hourlyRate.fieldValue"
              (ngModelChange)="onChangeCriticalData(position.startDate.fieldValue)"
              name="hourlyRate{{idx}}"
              placeholder="Hourly Rate"
            ></slx-money-input>
            <div class="warning-triangle-hourlyRate"
            [class.explamation-padding]="position.hourlyRate.securityAttribute.access != 'full'"
            *ngIf="state?.isEditMode && !hasSecondaryPayRate(position.hourlyRate.fieldValue)">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"
                 slxTooltip="If zero or blank, will get paid at primary rate"
                 tipPosition="right" tipClass="slx-warning-tooltip"></i>
            </div>
          </slx-input-decorator>
        </div>
        <div class="col-sm-6 col-lg-3" *ngIf="position.isSaved">
          <ng-container *ngIf="endDateEditable(position)">
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                [(ngModel)]="position.endDate.fieldValue"
                [required]="true"
                [acceptNullDate]="false"
                [minDate]="getMinDate(position.startDate.fieldValue)"
                placeholder="End Date"
                name="endDate{{idx}}"
              ></slx-date-picker-ngx>
              <span errorMessage for="required"></span>
              <span errorMessage for="date"></span>
              <span errorMessage for="minDate">End Date should be greater than the Start Date</span>
            </slx-input-decorator>
          </ng-container>
          <ng-container *ngIf="!endDateEditable(position)">
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                [ngModel]="getEndDate(position.endDate.fieldValue)"
                [readonly]="true"
                placeholder="End Date"
                name="endDate{{idx}}"
              ></slx-date-picker-ngx>
            </slx-input-decorator>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="add-position-button" (click)="addNewPosition()" *ngIf="state.isEditMode">
        <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Secondary Position
  </button>
</form>
