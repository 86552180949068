/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./user-info.component";
var styles_UserInfoComponent = [i0.styles];
var RenderType_UserInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserInfoComponent, data: {} });
export { RenderType_UserInfoComponent as RenderType_UserInfoComponent };
export function View_UserInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "user-info"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(4, { "left-sidebar-open": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "user-avatar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "user-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "welcome"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome,"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "user-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "user-info"; var currVal_1 = _ck(_v, 4, 0, i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_co.isLeftSidebarOpen))); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.userName; _ck(_v, 10, 0, currVal_2); }); }
export function View_UserInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-user-info", [], null, null, null, View_UserInfoComponent_0, RenderType_UserInfoComponent)), i1.ɵdid(1, 245760, null, 0, i3.UserInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserInfoComponentNgFactory = i1.ɵccf("slx-user-info", i3.UserInfoComponent, View_UserInfoComponent_Host_0, {}, {}, []);
export { UserInfoComponentNgFactory as UserInfoComponentNgFactory };
