export enum EmployeeDailyPunchesStatus {
  missing = 'missing', invalid = 'invalid', scheduledonly = 'scheduledonly', valid = 'valid', nopunches = 'nopunches', emprequest='emprequest',invalidlogin = 'invalidlogin'
}

export enum EmployeeDailyPunchesState {
  none = 0,
  invalid = 1,
  missing = 2,
  scheduledonly = 4,
  nopunches = 8,
  emprequest = 16,
  invalidlogin = 32,
}

export enum EmployeeDailyPunchesStatusLabel {
  missing = 'Missing Punch',
  invalid = 'Invalid Punch',
  scheduledonly = 'No Show',
  valid = 'Valid Punch',
  nopunches = 'No Punches',
  emprequest='Punch Request',
  invalidlogin = 'Invalid Login'
}
