<form #form="ngForm" novalidate class="slx-full-height grid-indent">
  <div class="punch-attestation-section">
    <div class="punch-attestation-section-header" [ngClass]="{'legacy-header' : isLegacyClockGroup}">
      <div class="punch-attestation-section-header-content">
        <div class="header-item header-title">
          <span class="header-title__text">
            {{group.groupName}}
          </span>
        </div>
      </div>

      <div class="punch-attestation-section-header-button-right">
        <slx-collapse-button [(isCollapsed)]="isCollapsed" className="slx-white-icon"
         [slxCollapseByCss]="collapseContent"></slx-collapse-button>
      </div>
    </div>

    <div #collapseContent class="punch-attestation-section-content">
      <div class="punch-attestation-section-content__header">
        <div class="datepicker-container">
          Attestation Group Name :
          <input slx-input type="text" [(ngModel)]="group.groupName" placeholder="Group Name" maxlength="99" name="groupname" [required]="true"
            *ngIf="!isLegacyClockGroup" />

          <label *ngIf="isLegacyClockGroup">{{group.groupName}}</label>
        </div>

        <button class="slx-button slx-with-icon delete-btn" (click)="delete($event)"
         [disabled] ="!isconfigure">
          <i class="slx-button__icon fas fa-trash"></i> Delete
          <span *ngIf="!isconfigure" 
          slxTooltip="You are not an authorized user to delete group"
          tipPosition="bottom"></span> 
        </button>

        <button class="slx-button slx-with-icon save-btn" (click)="save()" 
        [disabled]="(!isFormValid || !isGroupNameEntered) || !isconfigure">
          <i class="slx-button__icon fas fa-save"></i> Save
          <span *ngIf="!isconfigure" 
          slxTooltip="You are not an authorized user to save group"
          tipPosition="bottom"></span> 
        </button>
        
        <button class="slx-button slx-with-icon save-btn" 
        [disabled]="(!isAbleToAddQuestions || !isFormValid || !isGroupNameEntered) || !isconfigure" 
        style="width: 135px;"
          (click)="addQuestion()">
          Add New Question
          <span *ngIf="!isconfigure" 
          slxTooltip="You are not an authorized user to add new question"
          tipPosition="bottom"></span> 
        </button>
      </div>

      <kendo-grid #kendoGrid class="slx-blue-grid slx-full-height no-border" [data]="gridData" [sortable]="true"
        [sort]="sort" (sortChange)="sortChangeHandler($event)" (edit)="editHandler($event)"
        (remove)="removeHandler($event)" (save)="saveHandler($event)" (cancel)="cancelHandler($event)">
        <kendo-grid-command-column title="" width="80">
          <ng-template kendoGridCellTemplate>
            <div class="flex-horizontal">
              <button kendoGridEditCommand type="button" title="Edit" [disabled] ="!isconfigure" class="button-margin slx-button slx-only-icon slx-toolbar">
                <i class="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
              <button kendoGridRemoveCommand type="button" title="Remove" [disabled] ="!isconfigure" style="display:block !important;" class="button-margin slx-button slx-only-icon slx-toolbar">
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
              <button kendoGridSaveCommand type="button" [disabled]='form.invalid' title="Save"
                class="button-margin slx-button slx-only-icon slx-toolbar">
                <i class="far fa-check-circle" aria-hidden="true"></i>
              </button>
              <button kendoGridCancelCommand type="button" title="Cancel"
                class="button-margin slx-button slx-only-icon slx-toolbar">
                <i class="far fa-ban" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column title="Punch Type" field="punchType" width="120">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">{{ dataItem.punchType != null
            ? dataItem.punchType.name : ''}}</ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <slx-dropdown-input slx-input class="slx-drop" name="punchType{{rowIndex}}"
                [options]="punchAttestationPunchType" [(ngModel)]="dataItem.punchType" valueField="id" titleField="name"
                [required]="true"></slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="{{ isLegacyClockGroup ? 'Question 1' : 'Question'}}" field="text" width="200">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem"> 
            <span  [title]="dataItem.textLine1">{{ dataItem.textLine1 }}</span></ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input slx-input style="width: -webkit-fill-available;" name="textLine1{{rowIndex}}" [required]="true"
                [(ngModel)]="dataItem.textLine1" [slxMaxLength]="getmaxlengths" placeholder="{{ isLegacyClockGroup ? 'Question 1' : 'Question'}}" />
              <span errorMessage for="required" class="slx-error-block" *ngIf="!dataItem.textLine1"></span>
              <span errorMessage for="slxMaxLength">Question should be a maximum length of '{{getmaxlengths}}'</span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Question 2" field="text" width="200" *ngIf="isLegacyClockGroup">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <span [title]="dataItem.textLine2"> {{ dataItem.textLine2 }}</span></ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input slx-input style="width: -webkit-fill-available;" name="textLine2{{rowIndex}}" [required]="true"
                [(ngModel)]="dataItem.textLine2" slxMaxLength="50" placeholder="Question 2" />
              <span errorMessage for="required" class="slx-error-block" *ngIf="!dataItem.textLine2"></span>
              <span errorMessage for="slxMaxLength">Question 2 should be a maximum length of '50'</span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Default Answer" field="defaultAnswer" width="120">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">{{ dataItem.defaultAnswer !=
            null ? dataItem.defaultAnswer.name : '' }}</ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <slx-dropdown-input name="defaultAnswer{{rowIndex}}" slx-input [options]="punchAttestationAnswerCodes"
                [(ngModel)]="dataItem.defaultAnswer" valueField="id" [required]="true" titleField="name">
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Attestation Type" field="attestationTypeId" width="120">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">{{ dataItem.attestationTypeId
            != null ? dataItem.attestationTypeId.attestationTypeName : '' }} </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <slx-dropdown-input slx-input [options]="attestationTypeLookup" valueField="attestationTypeId"
                titleField="attestationTypeName" name="attestationType{{rowIndex}}" [required]="true"
                [(ngModel)]="dataItem.attestationTypeId">
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Restricted Answer" field="restrictedAnswerId" width="120">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem"> {{
            dataItem.restrictedAnswerId != null ? dataItem.restrictedAnswerId.restrictedAnswerName : '' }}</ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <slx-dropdown-input slx-input name="restrictedAnswerId{{rowIndex}}" [options]="restrictedAnswerLookup"
                valueField="restrictedAnswerId" titleField="restrictedAnswerName" [required]="true"
                name="restrictedAnswer" [(ngModel)]="dataItem.restrictedAnswerId">
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column title="Enable" field="dataItem" width="80">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem"> {{ dataItem.enable === true
            ? 'Yes' : dataItem.enable === false ? 'No' :''}}</ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-checkbox-button slx-input type="checkbox" class="rb-CheckBox slx-no-indents" name="enable{{rowIndex}}"
              [(ngModel)]="dataItem.enable">
            </slx-checkbox-button>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</form>