import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  HostListener,
  HostBinding,
  SimpleChanges,
  OnChanges,
  ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import * as emailMask from 'text-mask-addons/dist/emailMask.js';
import { CustomDomEvents } from '../../models/index';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';

@Component({
  moduleId: module.id,
  selector: 'slx-email-input',
  templateUrl: 'email-input.component.html',
  styleUrls: ['email-input.component.scss'],
})
export class EmailInputComponent implements ControlValueAccessor, OnChanges {
  @Input() public tabindex: number = 0;
  @Input() public className: string;
  @Input() public placeholder: string = 'Email';
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }

  // public emailMaskConf: any;
  public innerValue: any = '';
  public inEdit: boolean;

  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private ngControl: NgControl;
  @ViewChild('emailInput', {static: true}) private inputComponent: ElementRef;
  @HostBinding('class.edited') private hasFocus: boolean = false;

  constructor(elementRef: ElementRef, ngControl: NgControl) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.hasFocus = false;
    this.ngControl = ngControl;
    ngControl.valueAccessor = this;
    // this.emailMaskConf = {
    //   mask: emailMask.default.mask
    // };
  }

  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }

  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
  }

  // public onKey(event: KeyboardEvent): void {
  //   if (!this.innerValue) {
  //     return;
  //   }
  //   let str: string = this.innerValue;
  //   if (event.key === '@') {
  //     let atPos: number = str.lastIndexOf(event.key);
  //     let str1: string = str.slice(0, atPos);
  //     let str2: string = str.slice(atPos + 1);
  //     this.innerValue = str1 + str2;
  //   }
  //   if (event.key === '.') {
  //     let atPos: number = str.lastIndexOf('@');
  //     let pointPos: number = str.indexOf(event.key);
  //     if (pointPos > atPos + 1) {
  //       return;
  //     }
  //     let str1: string = str.slice(0, pointPos);
  //     let str2: string = str.slice(pointPos + 1);
  //     this.innerValue = str1 + str2;
  //   }
  //
  // }

  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !myattr;
  }

  public writeValue(value?: any): void {
    this.innerValue = value ? value : null;
    this.inputComponent.nativeElement.value = value ? value : null;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public valueChanged(value: any): void {
    if (value === '') this.writeValue(null);
    this.onChangeCallback(this.innerValue);
  }
}
