export * from './payroll-export/index';
export * from './payroll-export-test/index';

import { payrollExportComponents } from './payroll-export/index';
import { payrollExportTestComponents } from './payroll-export-test/index';



export const components: any[] = [
  ...payrollExportComponents,
  ...payrollExportTestComponents
];
