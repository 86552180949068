import { Component, Input, OnChanges, SimpleChanges, HostBinding, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';

import { createValuAccessor } from '../../utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-dropdown-lookup',
  templateUrl: 'dropdown-lookup.component.html',
  providers: [createValuAccessor(DropdownLookupComponent)]
})
export class DropdownLookupComponent implements ControlValueAccessor, OnChanges {
  @Input()
  public lookupPromise: Promise<any>;

  @Input()
  public valueField: string;

  @Input()
  public titleField: string;

  @Input()
  public disabled: boolean;

  @Input()
  public readonly: boolean;

  @Input()
  public placeholder: string;

  public options: any[];

  @ViewChild('selectElement', {static: true})
  public select: HTMLSelectElement;
  public internalValue: any;

  public items: any[];
  private selectedValue: any;

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;


  constructor() {
    this.valueField = 'id';
    this.titleField = 'name';
  }

  public writeValue(value: any): void {
    this.internalValue = value ? value[this.valueField] : null;
    this.selectedValue = value;
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    Observable.fromPromise(this.lookupPromise).subscribe((entities: any[]) => {
      this.items = entities;
    });
  }

  public change(selectChange: any): void {
    this.selectedValue = _.find(this.items, { [this.valueField]: selectChange });
    this.onChangeCallback(this.selectedValue);
  }

  public focus(): void {
    this.select.focus();
  }
}
