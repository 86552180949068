/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scheduled-shift-replacements-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/directives/navigation-link/employee-section-link.directive";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./scheduled-shift-replacements-section.component";
var styles_ScheduledShiftReplacementsSectionComponent = [i0.styles];
var RenderType_ScheduledShiftReplacementsSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduledShiftReplacementsSectionComponent, data: {} });
export { RenderType_ScheduledShiftReplacementsSectionComponent as RenderType_ScheduledShiftReplacementsSectionComponent };
function View_ScheduledShiftReplacementsSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "replace-by"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Replaced by"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.EmployeeSectionLinkDirective, [i3.Router, i3.ActivatedRoute], { employeeSectionLink: [0, "employeeSectionLink"] }, null), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.replaced.id; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.replaced.name; _ck(_v, 5, 0, currVal_1); }); }
function View_ScheduledShiftReplacementsSectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "replace-by"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Replacing"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.EmployeeSectionLinkDirective, [i3.Router, i3.ActivatedRoute], { employeeSectionLink: [0, "employeeSectionLink"] }, null), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.replacing.id; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.replacing.name; _ck(_v, 5, 0, currVal_1); }); }
export function View_ScheduledShiftReplacementsSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduledShiftReplacementsSectionComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduledShiftReplacementsSectionComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.replaced; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.replacing; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ScheduledShiftReplacementsSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-scheduled-shift-replacements-section", [], null, null, null, View_ScheduledShiftReplacementsSectionComponent_0, RenderType_ScheduledShiftReplacementsSectionComponent)), i1.ɵdid(1, 114688, null, 0, i5.ScheduledShiftReplacementsSectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduledShiftReplacementsSectionComponentNgFactory = i1.ɵccf("slx-scheduled-shift-replacements-section", i5.ScheduledShiftReplacementsSectionComponent, View_ScheduledShiftReplacementsSectionComponent_Host_0, { index: "index", replaced: "replaced", replacing: "replacing" }, {}, []);
export { ScheduledShiftReplacementsSectionComponentNgFactory as ScheduledShiftReplacementsSectionComponentNgFactory };
