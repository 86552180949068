import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import 'rxjs/add/operator/combineLatest';
import { ResetPasswordCodes, PasswordResetState } from '../../models/password-reset';
import { PasswordResetManagementService } from '../../services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { PasswordValidatorAdapter } from '../../services/password-reset/password-validator-adapter';
var ResetPasswordFormComponent = /** @class */ (function () {
    function ResetPasswordFormComponent(passwordValidator, route, router, management) {
        this.passwordValidator = passwordValidator;
        this.route = route;
        this.router = router;
        this.management = management;
        this.errorCodes = ResetPasswordCodes;
        this.state = new PasswordResetState();
    }
    Object.defineProperty(ResetPasswordFormComponent.prototype, "passwordValidationUnsecure", {
        get: function () {
            return PasswordValidatorAdapter.passwordValidationUnsecure;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetPasswordFormComponent.prototype, "alias", {
        get: function () {
            return this.m_alias;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetPasswordFormComponent.prototype, "username", {
        get: function () {
            return this.m_username;
        },
        enumerable: true,
        configurable: true
    });
    ResetPasswordFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.management.passwordPolicyLoaded$.subscribe(function (settings) {
            _this.passwordSettings = settings;
        });
        this.stateSubscription = this.management.stateChange$.subscribe(function (state) {
            _this.state = state;
        });
        this.routeSubscripion = this.route.params
            .combineLatest(this.route.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            var token = "";
            var username = "";
            var alias = "";
            if (queryParams['id']) {
                var base64token = queryParams['id'];
                var tokenstr = atob(base64token);
                var paramsObj = JSON.parse(tokenstr);
                if (paramsObj['username']) {
                    username = paramsObj['username'];
                }
                if (paramsObj['alias']) {
                    alias = paramsObj['alias'];
                }
                if (paramsObj['token']) {
                    token = paramsObj['token'];
                }
                localStorage.setItem("username", username);
                localStorage.setItem("alias", alias);
                localStorage.setItem("token", token);
                _this.router.navigate([], {
                    queryParams: {
                        id: null,
                    },
                    queryParamsHandling: 'merge',
                });
            }
            else {
                username = localStorage.getItem("username");
                alias = localStorage.getItem("alias");
                token = localStorage.getItem("token");
                _this.m_alias = alias;
                _this.m_username = username;
                _this.forceResetPasswordMode = !token && !!username;
                _this.forgotResetPasswordMode = !!token && !!username;
                _this.management.setUserData(token, username, alias);
                _this.management.loadPasswordPolicy();
            }
        });
        this.management.init();
    };
    ResetPasswordFormComponent.prototype.onResetPasswordClicked = function () {
        localStorage.removeItem("username");
        localStorage.removeItem("alias");
        localStorage.removeItem("token");
        if (this.forgotResetPasswordMode) {
            this.management.forgotResetPassword(this.newPassword);
        }
        else if (this.forceResetPasswordMode) {
            this.management.resetPasswordByOldPassword(this.newPassword, this.password);
        }
    };
    ResetPasswordFormComponent.prototype.hideUnknownErrorMessage = function () {
        this.state.isUnknownError = false;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ResetPasswordFormComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ResetPasswordFormComponent.prototype, "routeSubscripion", void 0);
    return ResetPasswordFormComponent;
}());
export { ResetPasswordFormComponent };
