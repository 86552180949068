import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';

import { IDateRange, DateRange } from '../../../../../core/models/index';

import { LMCreationAbsenceManagementService } from '../../../services/index';
import { RecurrenceFrequency, RecurrenceFrequencies } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-recurrence-frequency',
  templateUrl: 'lm-recurrence-frequency.component.html',
  styleUrls: ['lm-recurrence-frequency.component.scss']
})
export class LMRecurrenceFrequencyComponent implements OnInit {
  public absenceFrequencies: RecurrenceFrequency[] = [];

  public get hasExceptions(): boolean {
    return _.size(this.exceptionsDates) > 0;
  }
  public get canEditRequest(): boolean {
    return this.manService.canChange;
  }

  private exceptionsDates: DateRange[] = [];

  constructor(private manService: LMCreationAbsenceManagementService) {}

  public ngOnInit(): void {
    this.absenceFrequencies = this.manService.recurrenceFrequencies;
    this.exceptionsDates = this.manService.getExceptions();
  }

  public onClickFrequency(freq: RecurrenceFrequency): void {
    this.setActive(freq);
    this.setFrequency(freq);
  }

  private setActive(freq: RecurrenceFrequency): void {
    const frequencies = this.getCleanFrequencies();
    _.forEach(frequencies, (f: RecurrenceFrequency) => {
      if (f.id === freq.id) {
        f.isActive = true;
      }
    });
    this.absenceFrequencies = frequencies;
  }

  private setFrequency(freq: RecurrenceFrequency): void {
    this.manService.setFrequency(freq.id);
  }

  private getCleanFrequencies(): RecurrenceFrequency[] {
    return _.map(this.absenceFrequencies, (f: RecurrenceFrequency) => new RecurrenceFrequency(f.id, f.name));
  }
}
