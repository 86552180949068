<slx-spinner [show]="isLoading">
  <div class="empty-thread" *ngIf="completeThread.length === 0">
    <div class="envelope"><em class="far fa-envelope"></em></div>
    <span class="blank-screen-text"> Select the message to view the <br>
      thread or respond. </span>
  </div>
  <div *ngIf="completeThread.length > 0">
    <div class="header deep-link">
      <div class="left-buttons">
        <div *ngIf="employeeRecordConversionRestriction==false && employeeRecordRightsRestriction==false">
          <button type="button" class="slx-button profile-btn "  (click)="navigateToProfile()">
            <span class="slx-button__text"><em class="far fa-user-circle"></em> Profile</span>
          </button>
        </div>
        <div *ngIf="timeCardConversionRestriction==false && timeCardRightsRestriction==false">
          <button type="button" class="slx-button timecard-btn" (click)="navigateToTimeCard()">
            <span class="slx-button__text"><em class="fal fa-id-card"></em> Timecard</span>
          </button>
        </div>
        <div *ngIf="schedulerConversionRestriction==false && schedulerRightsRestriction==false">
          <button type="button" (click)="navigateToschedule()" class="slx-button scheduler-btn">
            <span class="slx-button__text"><em class="fal fa-calendar-alt"></em> Schedule</span>
          </button>
        </div>
        <div class="search-container">
          <input type="text" #searchBar placeholder="Search...." class="searchlistfeild" name="search2"
            (keyup)="onFilter($event.target.value)" />
          <button type="submit" (click)="onFilter(searchBar.value)">
            <em class="fa fa-search"></em>
          </button>
        </div>
      </div>
    </div>
    <div class="content-messages-container">
      <div class="content-message medium">
        <div class="content-message-text">By using the SmartLinx Application, you agree to the <a
          href="https://www.smartlinx.com/sms-terms/" target="_blank">Terms and Conditions.</a> Please
        remember, conversations are visible to users with rights to the employee(s).</div>
    </div>
    </div>

    <div class="message-content">
      <div *ngIf="employeeStatus === 'Terminated'" class="Transferred-Terminated-status">
        <em class="fas fa-times-circle status-icon"></em>
        <span class="status-text"> This employee has been terminated.  Message thread is locked.</span>
      </div>

      <div *ngIf="employeeStatus === 'Transferred'" class="Transferred-Terminated-status">
        <span class="transferred-status-circle"><em class="fa fa-exchange leave-status-icon"></em></span>
        <span class="status-text"> This employee has been transferred.  Message thread is locked.</span>
      </div>

      <div *ngIf="employeeStatus === 'Active' && employeeLeave" class="status-background">
        <span class="leave-status-circle"><em class="far fa-calendar-minus leave-status-icon"></em></span>
        <span class="status-text"> This employee is currently on a Leave of Absence.</span>
      </div>

        <div *ngIf="empOptInStatus === 0" class="status-background">
          <em class="fas fa-times-circle status-icon"></em>
          <span class="status-text">Employee has opted out for SMS Messages</span>
        </div>

        <div *ngIf="empOptInStatus === 2" class="status-background">
          <em class="fas fa-times-circle status-icon"></em>
          <span class="status-text">Employee has not responded to the request to Opt In</span>
        </div>
      <div class="msg-list">

        <kendo-grid #kendoGrid [height]="318" class="slx-msg-grid" [data]="gridState?.view">
          <kendo-grid-column>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div id="msg-list">
                <div *ngIf="dataItem.replied || dataItem.userId !== loggedInUserId || (dataItem.isOptInRequest  && dataItem.userId==0)" class="flex left-msg-container" [class.outgoing]="!dataItem.replied">
                  <div class="flex">
                    <div class="left-msg-photo">

                      <slx-message-thread-thumbnail-src [messageThread]="dataItem" [usersPicture]="usersPicture"
                      [employeeStatus]="employeeStatus" [employeeLeaveStatus]="employeeLeave" [employeeName]="selectedMessage.fullName" [employeeOptIn]="empOptInStatus"></slx-message-thread-thumbnail-src>

                    </div>
                    <div class="flex flex-direction__column dv-width" *ngIf="(dataItem.userId !== null && dataItem.userId !== 0) && !dataItem.isOptInRequest">
                      <div *ngIf="dataItem.userName === null" class="flex" [ngClass]="[employeeStatus === 'Terminated'? 'terminated-transferred': '',
                          employeeStatus === 'Transferred'? 'terminated-transferred': '',
                          empOptInStatus === 2? 'terminated-transferred': '',  empOptInStatus === 0? 'terminated-transferred': '']">
                          <div class="left-name" [innerHTML]="selectedMessage.fullName | highlight : searchText"></div>
                          <a class="left-phone" href="tel:{{item?.mobilePhoneNumber}}" [innerHTML]="employeeNumber | convertUSformatNumber | highlight : searchText"  *ngIf="!(dataItem.userId !== loggedInUserId && dataItem.userName !== null)"></a>
                      </div>
                      <div *ngIf="dataItem.userName !== null">
                        <div class="left-name" [innerHTML]="dataItem.userName | highlight : searchText"></div>
                        <div *ngIf="dataItem?.errorCode !== null &&  dataItem?.errorCode !== '0'">
                          <em class="fas fa-exclamation-triangle color-red font-bold padl10"></em>
                          <span class="color-red font-bold"> Message Failed: </span>
                          <span class="color-red">{{getFailedStatus(dataItem.errorCode)}}</span>
                        </div>
                      </div>
                   </div>
                   <div class="flex flex-direction__column dv-width" *ngIf="dataItem.userId !== loggedInUserId && dataItem.userId !== 0 && dataItem.isOptInRequest && dataItem.userName !== null">
                     <div class="flex">
                       <div class="left-name" [innerHTML]="dataItem.userName | highlight : searchText" *ngIf="dataItem.userName !== null"></div>
                       <div *ngIf="dataItem?.errorCode !== null &&  dataItem?.errorCode !== '0'">
                         <em class="fas fa-exclamation-triangle color-red font-bold padl10"></em>
                         <span class="color-red font-bold"> Message Failed: </span>
                         <span class="color-red">{{getFailedStatus(dataItem.errorCode)}}</span>
                       </div>
                     </div>
                  </div>
                  <div class="flex flex-direction__column dv-width" *ngIf="dataItem.userId !== loggedInUserId && dataItem.userId ===0 && dataItem.isOptInRequest === null && dataItem.userName === null && dataItem.replied">
                    <div class="flex" [ngClass]="[employeeStatus === 'Terminated'? 'terminated-transferred': '',
                    employeeStatus === 'Transferred'? 'terminated-transferred': '',
                    empOptInStatus === 2? 'terminated-transferred': '',  empOptInStatus === 0? 'terminated-transferred': '']">
                      <div class="left-name" [innerHTML]="selectedMessage.fullName | highlight : searchText"></div>
                      <a class="left-phone" href="tel:{{item?.mobilePhoneNumber}}" [innerHTML]="employeeNumber | convertUSformatNumber | highlight : searchText" *ngIf="!(dataItem.userId !== loggedInUserId && dataItem.userName !== null)"></a>
                      <div *ngIf="dataItem?.errorCode !== null &&  dataItem?.errorCode !== '0'">
                        <em class="fas fa-exclamation-triangle color-red font-bold padl10"></em>
                        <span class="color-red font-bold"> Message Failed: </span>
                        <span class="color-red">{{getFailedStatus(dataItem.errorCode)}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-direction__column dv-width" *ngIf="dataItem.userId !== loggedInUserId && dataItem.userId ===0 && !dataItem.isOptInRequest && dataItem.userName !== null">
                    <div class="flex" >
                      <div class="left-name"  [innerHTML]="dataItem.userName | highlight : searchText" *ngIf="dataItem.userName !== null"></div>
                      <div *ngIf="dataItem?.errorCode !== null &&  dataItem?.errorCode !== '0'">
                        <em class="fas fa-exclamation-triangle color-red font-bold padl10"></em>
                        <span class="color-red font-bold"> Message Failed: </span>
                        <span class="color-red">{{getFailedStatus(dataItem.errorCode)}}</span>
                      </div>
                    </div>
                  </div>
                    <div *ngIf="dataItem.isOptInRequest && dataItem.userId === 0" [ngClass]="{'error-msg-left':dataItem?.errorCode !== null && dataItem?.errorCode !== '0', 'system-user': dataItem?.errorCode === null || dataItem?.errorCode === '0'}"  class="circle">
                      <span class="text" [ngClass]="{'error-circle-left':dataItem?.errorCode !== null && dataItem?.errorCode !== '0'}">S</span>
                    </div>
                    <div class="flex flex-direction__column dv-width" *ngIf="dataItem.isOptInRequest && dataItem.userId === 0">
                      <div class="flex">
                        <div class="left-name">Smartlinx System</div>
                        <div *ngIf="dataItem?.errorCode !== null &&  dataItem?.errorCode !== '0'">
                          <em class="fas fa-exclamation-triangle color-red font-bold padl10"></em>
                          <span class="color-red font-bold"> Message Failed: </span>
                          <span class="color-red">{{getFailedStatus(dataItem.errorCode)}}</span>
                        </div>
                      </div>
                    </div>

                    <div class="left-time" [ngClass]="{'margin-top10':dataItem?.errorCode !== null && dataItem?.errorCode !== '0'}">
                      <span *ngIf="isMessageOptIn(dataItem.message) && !dataItem.isOptInRequest" class="opted-in">
                        <strong>Opted In</strong> -
                      </span>
                      <span *ngIf="isMessageOptOut(dataItem.message)  && !dataItem.isOptInRequest" class="opted-out">
                        <strong>Opted Out</strong> -
                      </span>
                      {{dataItem.time | convertUserFrndlyDate}}

                    </div>
                  </div>

                  <div [ngClass]="{
                    'system-user-msg-body': dataItem.isOptInRequest === true && dataItem.userId === 0,
                    'system-user-msg-body-bg-color': (dataItem?.errorCode === null || dataItem?.errorCode === '0') && dataItem.isOptInRequest === true && dataItem.userId === 0,
                    'msg-body': (dataItem.isOptInRequest !== true && dataItem.userId !== 0) || (dataItem.isOptInRequest !== true && dataItem.userId === 0) || (dataItem.isOptInRequest === true && dataItem.userId !== 0) ,
                    'error-body-left': dataItem?.errorCode !== null && dataItem?.errorCode !== '0'
                  }">
                    <div class="msg-content" [ngClass]="{'white-color':dataItem?.errorCode !== null && dataItem?.errorCode !== '0'}"  [innerHTML]="(convertHyperLink(dataItem.message)) | highlight : searchText"></div>
                  </div>
                </div>

                <div *ngIf="!dataItem.replied && dataItem.userId === loggedInUserId && (!dataItem.isOptInRequest || (dataItem.isOptInRequest && dataItem.userId!=0))" class="right-msg-container flex">
                  <div class="flex">
                    <div class="right-msg-date">
                    {{dataItem.time | convertUserFrndlyDate}}
                    </div>

                    <div class="msg-time" *ngIf="dataItem?.status !== 'error'">
                      <em *ngIf="dataItem.from === 'Me' && (dataItem.status === null || dataItem.status == 'queued' || dataItem.status == 'sent' || dataItem.status === 'delivered')" class="statusIcon fa fa-paper-plane"></em>
                      <em *ngIf="dataItem.from === 'Me' &&  dataItem.status === 'PendingOptIn'" class="statusIcon fal fa-clock"></em>
                      <em *ngIf="dataItem.from === 'Me' &&  dataItem.status === 'expired'" class="statusIcon color-red fas fa-alarm-exclamation"></em>
                      <span *ngIf="dataItem.from === 'Me' && (dataItem.status === null || dataItem.status == 'queued' || dataItem.status == 'sent' || dataItem.status === 'delivered')" class="statusClass"> Sent </span>
                      <span *ngIf="dataItem.from === 'Me' && dataItem.status === 'PendingOptIn' " class="statusClass"> Pending Opt In</span>
                      <span *ngIf="dataItem.from === 'Me' && dataItem.status === 'expired' " class="statusClass"> Expired</span>
                    </div>

                    <div class="right-msg-name" [ngClass]="{'error-name':dataItem?.errorCode !== null && dataItem?.errorCode !== '0'}">{{dataItem.from | convertUserFrndlyName}}</div>

                    <div class="right-msg-photo" [ngClass]="{'error-photo':dataItem?.errorCode !== null && dataItem?.errorCode !== '0'}">
                      <a *ngIf="dataItem.photoPresent">
                        <img
                          class="img"
                          [employeeThumbinalSrc]="dataItem.id"
                          alt="Employee Photo"
                        />
                      </a>

                      <div *ngIf="!dataItem.photoPresent && (dataItem?.errorCode === null || dataItem?.errorCode === '0')" class="circle" [ngClass]="{
                        'disable-color': dataItem?.status === 'expired' || dataItem?.status === 'PendingOptIn'
                      }">
                        <a *ngIf="usersPicture[dataItem.userId]">
                          <img class="img img-circle" src="{{usersPicture[dataItem.userId]}}" alt="Employee Photo" />
                        </a>
                        <span class="text" *ngIf="!usersPicture[dataItem.userId]">{{ loggedInUser.split('')[0] }}</span>
                      </div>

                      <div *ngIf="!dataItem.photoPresent && dataItem?.errorCode !== null && dataItem?.errorCode !== '0' " class="error-circle">
                        <a *ngIf="usersPicture[dataItem.userId]">
                          <img class="img img-circle" src="{{usersPicture[dataItem.userId]}}" alt="Employee Photo" />
                        </a>
                        <span class="text" *ngIf="!usersPicture[dataItem.userId]">{{ loggedInUser.split('')[0] }}</span>
                      </div>
                    </div>

                  </div>
                  <div class="error-message" *ngIf="dataItem?.errorCode !== null && dataItem?.errorCode !== '0'">
                    <em class="fas fa-exclamation-triangle color-red font-bold"></em>
                    <span class="color-red font-bold"> Message Failed: </span>
                    <span class="color-red">{{getFailedStatus(dataItem.errorCode)}}</span>
                  </div>

                  <div
                    class="right-msg-body"
                    [ngClass]="{
                      'expired-msg-body': dataItem?.status === 'expired',
                      'pendingoptin-msg-body': dataItem?.status === 'PendingOptIn',
                      'error-msg-body': dataItem?.errorCode !== null && dataItem?.errorCode !== '0'
                    }"
                  >
                      <div class="right-msg-content" [innerHTML]="(convertHyperLink(dataItem.message)) | highlight : searchText">
                    </div>
                </div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
  </div>

    <div class="footer">
      <div class="full-width-textarea">
        <textarea
          [disabled]="messageConversation.length == 0 || (employeeStatus === 'Terminated' || employeeNumber === '' || employeeStatus === 'Transferred' || empOptInStatus === 2 || empOptInStatus === 0)"
          [class.textarea-disabled]="messageConversation.length == 0 || !employeeNumber || (employeeStatus === 'Terminated' || employeeStatus === 'Transferred' || empOptInStatus === 2 || empOptInStatus === 0)"
          [(ngModel)]="message1" [ngModelOptions]="{ standalone: true }" class="border-hidden msg-box" spellcheck="true"
          placeholder="Enter your text" data-gramm="true" [maxlength]="maxCharPerSms">
        </textarea>
        <span class="msgcount">{{this.message1?.length || 0}}/{{maxCharPerSms}}</span>
        <button type="button" class="slx-button slx-mobile-adapted compose-btn"
          [ngClass]="[employeeStatus === 'Terminated'? 'disable-color': '', employeeStatus === 'Transferred'? 'disable-color': '', empOptInStatus === 2? 'disable-color': '',  empOptInStatus === 0? 'disable-color': '',  !employeeNumber ? 'disable-color': '']"
          [disabled]="messageConversation.length == 0 || this.message1.length == 0 || !employeeNumber"
          (click)="sendMessage()">
          <span class="slx-button__text"><em class="fas fa-paper-plane color-white"></em></span>
        </button>
      </div>
    </div>
    <div class="no-of-segment" *ngIf="message1">
      <span >{{getSegmentCount()}} </span>
    </div>

  </div>
</slx-spinner>

