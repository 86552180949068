<form class="dialog-form" #dialogForm="ngForm">
  <div class="modal-content">
    <div class="modal-body">
      <slx-input-decorator *ngIf="isChangeRestriction()">
        <slx-dropdown-input slx-input [options]="restrictions" valueField="id" titleField="name" name="restrictions"
          placeholder="Restriction" [(ngModel)]="selectedRestriction">
        </slx-dropdown-input>
      </slx-input-decorator>

      <kendo-grid #kendoGrid *ngIf="isAssignMode() || isReAssignedMode() || isUnassignMode()"
        [data]="gridState.view"
        [sortable]="{ mode: 'multiple' }"
        [sort]="gridState.state.sort"
        [filterable]="true"
        [filter]="gridState.state.filter"
        (dataStateChange)="gridState.dataStateChange($event)"
        selectable="false"
        height="460">

        <ng-template kendoGridToolbarTemplate position="top" *ngIf="isAssignMode() || isReAssignedMode()">
          <div class="col-xs-12 col-sm-6">
            <div class="row">
              <slx-input-decorator *ngIf="isAssignMode()">
                <slx-dropdown-input slx-input [options]="restrictions" valueField="id" titleField="name" name="restrictions"
                  placeholder="Restriction" [(ngModel)]="selectedRestriction">
                </slx-dropdown-input>
              </slx-input-decorator>
              <slx-input-decorator *ngIf="isReAssignedMode()">
                <slx-dropdown-input slx-input [options]="restrictions" valueField="id" titleField="name" name="restrictions" [emptyOption]="noChangeRestriction"
                  placeholder="Restriction" [(ngModel)]="selectedRestriction">
                </slx-dropdown-input>
              </slx-input-decorator>
              <div class="slx-error-block" *ngIf="hasRestrictionError">You should select Restriction to assign timeclock</div>
            </div>
          </div>
        </ng-template>

        <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" field="timeclock.isSelected" width="40">
          <ng-template kendoGridHeaderTemplate>
            <input type="checkbox" name="isAllSelected" [(ngModel)]="isAllSelected" (ngModelChange)="onSelectionChanged(true, null)">
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" name="isSelected{{dataItem.timeclock.id}}" [(ngModel)]="dataItem.isSelected" (ngModelChange)="onSelectionChanged(false, dataItem)">
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Id" field="timeclock.id" width="180">
          <ng-template kendoGridHeaderTemplate>
            Id
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem?.timeclock.id}}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-number-filter class="clockId" [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-number-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Id" field="isAssigned" width="150" [hidden]="!isReAssignedMode()">
          <ng-template kendoGridHeaderTemplate>
            Assigned
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem?.isAssigned | boolyn}}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-boolean-filter class="assigned" [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-boolean-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Timeclock" field="timeclock.name" width="200">
          <ng-template kendoGridHeaderTemplate>
            Timeclock
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem?.timeclock.name}}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Timeclock" field="timeclock.clockOrganization" width="300">
          <ng-template kendoGridHeaderTemplate>
            Clock Organization
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem?.timeclock.clockOrganization}}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Timeclock" field="timeclock.physicalId" width="200">
          <ng-template kendoGridHeaderTemplate>
            Real Clock ID
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem?.timeclock.physicalId}}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-number-filter class="clockwidth" [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-number-filter>
          </ng-template>
        </kendo-grid-column>

      </kendo-grid>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
      <button type="button" (click)="onOk()"  [disabled]="isDisabled()" class="theme-button-apply">OK</button>
    </div>
  </div>
</form>
