import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Params, Resolve } from '@angular/router';
import { appConfig } from '../../../app.config';
import { RangeDates } from '../../../common/models/range-dates';
import { BaseResolver } from '../../../common/index';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
import { PayCycleHelperService } from '../pay-cycle-helper/pay-cycle-helper.service';
var PayCycleResolver = /** @class */ (function (_super) {
    tslib_1.__extends(PayCycleResolver, _super);
    function PayCycleResolver(router, busService, payCycleHelper) {
        var _this = _super.call(this, router) || this;
        _this.busService = busService;
        _this.payCycleHelper = payCycleHelper;
        return _this;
    }
    PayCycleResolver.prototype.resolve = function (route, state) {
        var _this = this;
        var range = this.getRangeDates(route);
        if (!range.startDate)
            range.startDate = moment().startOf('day').toDate();
        return this.getOrgLevel().then(function (orgLevel) {
            return _this.payCycleHelper.getPayCycleByDate(range.startDate, orgLevel.id).then(function (payCycle) {
                if (payCycle) {
                    if (!_this.validateRangeDates(range, payCycle)) {
                        range.startDate = payCycle.startDate;
                        range.endDate = payCycle.endDate;
                        _this.navigateToPath(route, _this.createQueryParams(route, range));
                    }
                }
                return payCycle;
            });
        });
    };
    PayCycleResolver.prototype.createQueryParams = function (route, range) {
        var params = _super.prototype.createQueryParams.call(this, route);
        params['startDate'] = moment(range.startDate).format(appConfig.linkDateFormat);
        params['endDate'] = moment(range.endDate).format(appConfig.linkDateFormat);
        return params;
    };
    PayCycleResolver.prototype.getOrgLevel = function () {
        var promise = this.busService.orgLevelSelected$.first().toPromise();
        return promise;
    };
    PayCycleResolver.prototype.validateRangeDates = function (range, payCycle) {
        return !moment(range.startDate).isSame(payCycle.startDate) || !moment(range.endDate).isSame(payCycle.endDate);
    };
    PayCycleResolver.prototype.getRangeDates = function (route) {
        var startDateParam = _.toString(route.queryParamMap.get('startDate'));
        var endDateParam = _.toString(route.queryParamMap.get('endDate'));
        var startDate = startDateParam ? moment(startDateParam, appConfig.linkDateFormat).toDate() : null;
        var endDate = endDateParam ? moment(endDateParam, appConfig.linkDateFormat).toDate() : null;
        var range = new RangeDates();
        range.startDate = startDate;
        range.endDate = endDate;
        return range;
    };
    return PayCycleResolver;
}(BaseResolver));
export { PayCycleResolver };
