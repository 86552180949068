import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { SignalrHub2Service } from '../../../channel/services/signalr/signalr-hub2.service';
import { SessionService, ThrottlingService } from '../../../core/services';
import { appConfig } from '../../../app.config';
import { timeAndAttendanceConfig } from '../../../time-and-attendance/time-and-attendance.config';

@Injectable({
  providedIn: 'root',
})
export class TaSignalrService {

    public isRebooted$ = new Subject<any>();
    public isRebootInitiated$ = new Subject<boolean>();

    constructor(
      private signalRService: SignalrHub2Service,
      private throttlingService: ThrottlingService,
      private sessionService: SessionService){
        this.signalRService = new SignalrHub2Service(this.throttlingService, this.sessionService);
    }

    public init(){
      this.signalRService.init({ url: this.getApiRoot(), hubName: timeAndAttendanceConfig.api.signalR.signalRHub});
      this.isRebooted$ = this.signalRService.subscribe(timeAndAttendanceConfig.api.signalR.groupName);
    }

    private getApiRoot(): string {
      return `${appConfig.api.url}/${appConfig.api.version2}/${timeAndAttendanceConfig.api.signalR.root}/signalr`;
    }

    public checkAndEstablishConnection(): void{
      let connectionStatus: signalR.HubConnectionState | undefined =  this.signalRService.getConnectionStatus();
      if(connectionStatus !== undefined && connectionStatus === signalR.HubConnectionState.Disconnected){
          this.signalRService.RestartConnection();
      }else{
        this.init();
      }
    }
}