import { IPayloadAction } from '../../state-model/models/index';
import { ApplicationActions } from '../../portal/actions/application.actions';
import { IApplicationRecord } from './application.types';
import {
  INITIAL_APPLICATION_STATE
} from './application.initial-state';

export function applicationReducer(state: IApplicationRecord = INITIAL_APPLICATION_STATE, action: IPayloadAction): IApplicationRecord {
  switch (action.type) {
    case ApplicationActions.DESELECT_APPLICATION:
      return INITIAL_APPLICATION_STATE;
    case ApplicationActions.APPLICATION_SELECTED:
      return state.merge(action.payload);
    default:
      return state;
  }
}

