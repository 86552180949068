import { IReportConfigPositionCategory, ReportConfigPositionCategory } from './report-config-position-category';

export interface IReportStaffingSetting {
    departmentId: number;
    time1: string;
    time2: string;
    time3: string;
    positionCategory: IReportConfigPositionCategory[];
}

export class ReportStaffingSetting {
    public departmentId: number;
    public time1: Date;
    public time2: Date;
    public time3: Date;
    public positionCategory: ReportConfigPositionCategory[];
}

