export interface IEssTemplateDefinition {
  id: number;
  orgLevelId: number;
  isDefault: boolean;
  name: string;
  attestationGroupId: number;
}

export class EssTemplateDefinition {
  public id: number;
  public orgLevelId: number;
  public isDefault: boolean;
  public name: string;
  public attestationGroupId: number;
}
