<slx-spinner [show]="isLoading">
  <div class="slx-high-box payroll payroll-export-component">
    <slx-payroll-export-header class="slx-content-toolbar-indents"></slx-payroll-export-header>
    <div class="slx-high-box__body slx-main-content-indents payroll-export-content">
      <div class="payroll-export-content-header">
        <div class="header-item group-item">
          <i class="far fa-folder" aria-hidden="true"></i>
          <span class="header-item__text">Group</span>
        </div>
        <div class="header-item request-status-item">
          <i class="fas fa-thermometer-three-quarters" aria-hidden="true"></i>
          <span class="header-item__text header-item__text__desktop">Request status</span>
          <span class="header-item__text header-item__text__mobile">Status</span>
        </div>
        <div class="header-item release-status-item">
          <i class="far fa-paper-plane" aria-hidden="true"></i>
          <span class="header-item__text">Release status</span>
        </div>
        <div class="header-item actions-item">
          <i class="far fa-cog" aria-hidden="true"></i>
          <span class="header-item__text">Actions</span>
        </div>
      </div>
      <div class="payroll-export-sections" *ngFor="let group of data?.groups">
          <slx-payroll-export-section [group]="group"></slx-payroll-export-section>
      </div>
    </div>
  </div>
</slx-spinner>
