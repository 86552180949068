/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-schedule-settings-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../post-schedule-settings/post-schedule-settings.component.ngfactory";
import * as i3 from "../post-schedule-settings/post-schedule-settings.component";
import * as i4 from "../../../../organization/services/lookup/lookup.service";
import * as i5 from "../../../services/open-shift-management/open-shift-management-management.service";
import * as i6 from "../../../../app-settings/services/app-settings-manage.service";
import * as i7 from "@angular/common";
import * as i8 from "./post-schedule-settings-form.component";
var styles_PostScheduleSettingsFormComponent = [i0.styles];
var RenderType_PostScheduleSettingsFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostScheduleSettingsFormComponent, data: {} });
export { RenderType_PostScheduleSettingsFormComponent as RenderType_PostScheduleSettingsFormComponent };
function View_PostScheduleSettingsFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-post-schedule-settings", [], null, [[null, "isChecked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isChecked" === en)) {
        var pd_0 = (_co.isChecked !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PostScheduleSettingsComponent_0, i2.RenderType_PostScheduleSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i3.PostScheduleSettingsComponent, [i4.LookupService, i5.OpenShiftManagementManagementService, i6.AppSettingsManageService], { settings: [0, "settings"] }, { isChecked: "isChecked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PostScheduleSettingsFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostScheduleSettingsFormComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancelHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applySettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Apply"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isDisabled; _ck(_v, 6, 0, currVal_1); }); }
export function View_PostScheduleSettingsFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-post-schedule-settings-form", [], null, null, null, View_PostScheduleSettingsFormComponent_0, RenderType_PostScheduleSettingsFormComponent)), i1.ɵdid(1, 245760, null, 0, i8.PostScheduleSettingsFormComponent, [i5.OpenShiftManagementManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostScheduleSettingsFormComponentNgFactory = i1.ɵccf("slx-post-schedule-settings-form", i8.PostScheduleSettingsFormComponent, View_PostScheduleSettingsFormComponent_Host_0, {}, { onCancel: "onCancel", onApply: "onApply" }, []);
export { PostScheduleSettingsFormComponentNgFactory as PostScheduleSettingsFormComponentNgFactory };
