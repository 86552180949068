<div *ngIf="gridState?.view && position && range && range.parLevels"
     class="grid-container">
  <kendo-grid [data]="gridState.view" scrollable="none" [selectable]="false"
              [sort]="gridState.state.sort" [sortable]="{ mode: 'multiple' }" class="slx-grid-lightgray"
              [class.slx-hide-detail-hierarchy]="!isMobile()"
              (dataStateChange)="gridState.dataStateChange($event)"
              [scrollable]="'scrollable'">
    <kendo-grid-column title="" [width]="74">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="center-align-column buttons">
          <div>
            <button type="button" class="btn btn-default btn-xs row-button clear-row"
                    slxTooltip="Clear row" tipPosition="right"
                    (click)="stateService.resetShiftRowHours(range, dataItem.uniqueId)">
              <i class="fal fa-trash-alt" aria-hidden="true"></i>
            </button>
          </div>

          <div>
            <button type="button" class="btn btn-default btn-xs row-button all-day"
                    [class.active]="dataItem.isDailyVariance"
                    slxTooltip="Apply to All Days" tipPosition="right"
                    (click)="stateService.applyToAllDays(range, dataItem.uniqueId)">
              <i [ngClass]="{'fas fa-link': dataItem.isDailyVariance, 'far fa-unlink': !dataItem.isDailyVariance}" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="state.showShiftColumn" title="Shift" width="145" class="no-l-border">
      <ng-template kendoGridHeaderTemplate>
        Shift
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="hours-container shift">
          <div class="shift-name-container">
            <div class="shift-name" [slxTooltip]="dataItem.shift?.name" tipPosition="right">
              {{dataItem.shift?.name}}
            </div>
          </div>
          <div class="hours-description slx-small-label">
            <div class="hours">{{dataItem.shift?.durationHours | number: ".2-2"}}</div>
            <div class="hours-legend">Hours</div>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngIf="state.showUnitColumn" title="Unit" [locked]="false" width="80">
      <ng-template kendoGridHeaderTemplate>
        Unit
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{unit?.name}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [title]="dataItem.unit?.name" class="k-grid-td-text">
          {{dataItem.unit?.name}}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column *ngFor="let dday of weekDays" title="Sun" [locked]="false" class="no-padding" [width]="150" [hidden]="isMobile()">
      <ng-template kendoGridHeaderTemplate>
        <div class="header-wrapper">
          <div class="title-aside">
            {{dday.name}} <span class="title-hours">({{scheduleHelper.getDailyTotalHours(range, dday.dayNumber)}})</span>
          </div>
          <div class="button-aside">
            <a slxTooltip="Clear column" tipPosition="left"
              *ngIf="position.hasChanges" class="clear-row"
              (click)="stateService.resetDailyHours(range, dday.dayNumber)">
              <i class="fal fa-trash-alt" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="hours-container whole-cell" [class.highlight]="dataItem.isDailyVariance">
          <div class="slx-small-input">
            <slx-kendo-number slx-input
              [ngModel]="scheduleHelper.getDayCounter(range, dataItem.uniqueId, dataItem.isDailyVariance ? 0 : dday.dayNumber)"
              (ngModelChange)="stateService.setDayCounter(position, range, dataItem.uniqueId, dataItem.isDailyVariance ? 0 : dday.dayNumber, $event)"
              [decimals]="0"
              [min]="0"
              [max]="state.maxHourLimit"
              [autoCorrect]="true"
              [readonly]="!scheduleHelper.isCurrentSchedule"
              [name]="count"
            ></slx-kendo-number>
          </div>

          <div class="hours-description slx-small-label" [class.no-hours]="scheduleHelper.getDayHours(range, dataItem.uniqueId, dataItem.isDailyVariance ? 0 : dday.dayNumber) == 0">
            <div class="hours">{{scheduleHelper.getDayHours(range, dataItem.uniqueId, dataItem.isDailyVariance ? 0 : dday.dayNumber) | number: ".2-2"}}</div>
            <div class="hours-legend">Hours</div>
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="isMobile">
      <div class="mobile-hours-container">
        <div class="hours-container" *ngFor="let dday of weekDays">
          <div class="day">{{ dday.shortName }}</div>

          <div class="slx-small-input">
            <slx-kendo-number slx-input
              [ngModel]="scheduleHelper.getDayCounter(range, dataItem.uniqueId, dataItem.isDailyVariance ? 0 : dday.dayNumber)"
              (ngModelChange)="stateService.setDayCounter(position, range, dataItem.uniqueId, dataItem.isDailyVariance ? 0 : dday.dayNumber, $event)"
              [decimals]="2"
              [min]="0"
              [max]="state.maxHourLimit"
              [autoCorrect]="true"
              [readonly]="!scheduleHelper.isCurrentSchedule"
              [name]="count"
            ></slx-kendo-number>
          </div>

          <div class="hours-description slx-small-label" [class.no-hours]="scheduleHelper.getDayHours(range, dataItem.uniqueId, dataItem.isDailyVariance ? 0 : dday.dayNumber) == 0">
            <div class="hours">{{scheduleHelper.getDayHours(range, dataItem.uniqueId, dataItem.isDailyVariance ? 0 : dday.dayNumber) | number: ".2-2"}} h</div>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-grid>
</div>
