<div provide-parent-form>
  <slx-input-decorator>
    <slx-date-picker-ngx slx-input
      [name]="componentName"
      [(ngModel)]="parameter.value"
      [required]="true"
      [placeholder]="parameter.displayName"
      [minDate]="minValue"
      [maxDate]="maxValue" 
    ></slx-date-picker-ngx>
    <span errorMessage for="required">Report parameter is required.</span>
    <span errorMessage for="minValue">Date should be greater than {{ minValue | amDateFormat: appConfig.dateFormat}}</span>
    <span errorMessage for="maxValue">Date should be less than {{ maxValue | amDateFormat: appConfig.dateFormat}}</span>
  </slx-input-decorator>
</div>
<ng-container *ngIf="(isRetroEnd ||isWageComp1End || isAllMissingPunchesEnd) && isMoreThanYear">
  <span class="slx-error-block">Date range exceeds {{daysRange}} limit.</span>
</ng-container>
