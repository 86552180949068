<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid timecards-grid"
            [data]="gridState.view"
            (dataStateChange)="gridState.dataStateChange($event)"
            [sortable]="{ mode: 'multiple' }"
            [sort]="gridState.state.sort"
            [groupable]="{ showFooter: true }"
            [group]="gridState.state.group"
            [filterable]="'menu'"
            [filter]="gridState.state.filter"
            [resizable]="true"
            [selectable]="{ mode: 'single' }"
            [rowSelected]="isRowSelected"
            (cellClick)="onCellClick($event)"
            kendoGridFixFreezeHeader
            [scrollElementSelector]="'.k-grid-content.k-virtual-content'"
            [pageable]="true"
            [pageSize]="pageSize"
            [skip]="gridState.state.skip"
            [selectedKeys]="highlightedRows"
            [kendoGridSelectBy]="'emp.employeePosition.employee.id'"
            (pageChange)="gridState.pageChange($event)"
            slxKendoGridState="TIMECAD_DISPLAY_FLAT">
  <kendo-grid-column hidden="true" [width]="10" field="emp.employeePosition.employee.id" class="timecard-pdf-grid">
  </kendo-grid-column>
  <kendo-grid-column media="xs" width="500" class="timecard-pdf-grid">
    <ng-template kendoGridCellTemplate let-dataItem></ng-template>
  </kendo-grid-column>

  <kendo-grid-column [locked]="isAllHidden" title="Select" [sortable]="false" field="isSelected"
                     [resizable]="false"
                     [width]="40" [filterable]="false" headerClass="overflow-visible-cell" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <div class="header-checkbox">
        <input slxTooltip="Select/Deselect All" tipPosition="right" *ngIf="!container?.isLocked"
               type="checkbox" [ngModel]="isAllSelected" (ngModelChange)="onToggleAllSelected($event)">

        <div>
          <i class="fas fa-lock" *ngIf="container?.isLocked" aria-hidden="true"
             slxTooltip="This Organization Payroll is locked" tipPosition="top"></i>
        </div>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [classToParent]="{'altered-row': dataItem?.isFirst}" [parentTagName]="'tr'"></span>
      <input *ngIf="dataItem?.isFirst && !dataItem?.isLocked" type="checkbox" [(ngModel)]="dataItem.emp.isSelected" (ngModelChange)="selectionChange()">
      <i class="fas fa-lock" *ngIf="dataItem?.isFirst && dataItem?.isLocked"
         slxTooltip="This timecard is locked"
         tipPosition="right" aria-hidden="true"></i>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [locked]="isAllHidden" [width]="250" title="Name" field="emp.employeePosition.employee.name" class="overflow-visible-cell timecard-pdf-grid" headerClass="overflow-visible-cell remove-visible-cell-print-only">
    <ng-template kendoGridHeaderTemplate>
      Name
      <a href="javascript:void(0);">
        <span class="alerts" (click)="filterByPunches($event, 'missing')">
          <i class="fa fa-exclamation-circle"
             [class.alert-error]="punchFilter === 'missing'"
             [slxTooltip]="punchFilter === 'missing' ? 'Remove missing punches filter' : 'Filter by missing punches'"
             tipPosition="right"
             aria-hidden="true"></i>
        </span>
      </a>
      <a href="javascript:void(0);">
        <span class="alerts" (click)="filterByPunches($event, 'invalid')">
          <i class="fa fa-exclamation-triangle"
             [class.alert-warning]="punchFilter === 'invalid'"
             [slxTooltip]="punchFilter === 'invalid' ? 'Remove invalid punches filter' : 'Filter by invalid punches'"
             tipPosition="right"
             aria-hidden="true"></i>
        </span>
      </a>
      <a href="javascript:void(0);">
        <span class="alerts" (click)="filterByPunches($event, 'edited')">
          <i class="fa edited-tc-icon filter-icon"
             [class.filter-disabled]="punchFilter !== 'edited'"
             [slxTooltip]="punchFilter === 'edited' ? 'Remove edited timecards filter' : 'Filter by edited timecards'"
             tipPosition="right"
             aria-hidden="true"></i>
        </span>
      </a>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="flex">
        <span class="link">
          <a [individualTimecardsLink]="dataItem?.emp.employeePosition?.employee.id"
             [startDate]="container?.payCycle.startDate"
             [endDate]="container?.payCycle.endDate"
             (click)="onSelectEmployee(dataItem?.emp.employeePosition.employee.id)">{{dataItem?.emp.employeePosition?.employee.name}}</a>
        </span>
        <span class="alerts">
          <i *ngIf="dataItem.isError" class="fa fa-exclamation-circle alert-error" slxTooltip="Has missing punches" tipPosition="left" aria-hidden="true"></i>
        </span>
        <span class="alerts">
          <i *ngIf="dataItem.isWarning" class="fa fa-exclamation-triangle alert-warning" slxTooltip="Has invalid punches / Invalid login / No show" tipPosition="left" aria-hidden="true"></i>
        </span>
        <span class="alerts">
          <i *ngIf="dataItem.isEdited" class="fa edited-tc-icon" slxTooltip="Timecard has been edited" tipPosition="left" aria-hidden="true"></i>
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('organization')" [hidden]="!isVisible('organization')" title="Organization" media="lg"
                     field="organizationName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Organization</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.organizationName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('department')" [hidden]="!isVisible('department')" title="Department" media="lg"
                     field="departmentName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Department</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.departmentName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('position')" [hidden]="!isVisible('position')" title="Position"
                     media="sm" field="positionName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.positionName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('payroll')" [hidden]="!isVisible('payroll')" title="Payroll #"
                     media="lg" field="emp.employeePosition.employee.payrollNumber" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll #</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.emp.employeePosition?.employee.payrollNumber}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('empType')" [hidden]="!isVisible('empType')" title="Employee Type"
                     media="lg" field="emp.employeePosition.employee.employeeType.name" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee Type</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.emp.employeePosition?.employee.employeeType.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('approved')" [hidden]="!isVisible('approved')" title="Approved"
                     media="sm" field="emp.approved" filter="boolean" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Approved</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.emp?.approved | boolyn}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('certified')" [hidden]="!isVisible('certified')" title="Signoff"
                     media="sm" field="emp.certified" filter="boolean" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Signoff</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.emp?.certified | boolyn}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('payPolicy')" [hidden]="!isVisible('payPolicy')" title="Pay Policy"
                     media="lg" field="payPolicyName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Pay Policy</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.payPolicyName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('shiftDiffPolicy')" [hidden]="!isVisible('shiftDiffPolicy')"
                     title="Shift Diff Policy" media="lg" field="earning.shiftDiffPolicy.name" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Shift Diff Policy</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.earning?.shiftDiffPolicy?.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('shiftCodeName')" [hidden]="!isVisible('shiftCodeName') || !container || !container.shiftDiffBasedOnTime"
                     title="Shift Code" media="lg" field="earning.shiftCodeName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Shift Code</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.earning?.shiftCodeName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('costCenterCode')" [hidden]="!isVisible('costCenterCode') || !container || container.hideCostCenter"
                     title="Cost Center Code" media="lg" field="earning.costCenterCode" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Cost Center Code</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.earning?.costCenterCode}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('hireDate')" [hidden]="!isVisible('hireDate')" title="Hire date" media="lg" field="emp.employeePosition.employee.dateHired" filter="date"
                     class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Hire date</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.emp.employeePosition?.employee.dateHired | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('payRate')" [hidden]="!isVisible('payRate') || !state.isShowPayRates" title="Pay Rate"
                     media="sm" field="payRate" class="numeric-cell timecard-pdf-grid" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Pay Rate</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.payRate | slxDecimal24Switch : isShowHighPrecision | slxMoney}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngFor="let rule of container?.usedRulesDefinitions; let f= first" [hidden]="!isVisible(rule.name, true)" width="150" title="{{rule.name}}"
                     media="sm" [field]="getFieldPath(rule)" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate let-dataItem>
      <i class="pay-code-highlighter" aria-hidden="true" [style.border-color]="getColor(rule)"></i>
      <span slxKendoGridHeaderTooltip>{{rule.description}}</span>

    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="f" [classToParent]="{'altered-row': dataItem?.isFirst}" [parentTagName]="'tr'"></span>
      <span *ngIf="dataItem.isMoneyRule(rule.name)">{{dataItem.getRuleValue(rule.name) | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</span>
      <span *ngIf="!dataItem.isMoneyRule(rule.name)">{{dataItem.getRuleValue(rule.name) | slxDecimal24Switch : isShowHighPrecision }}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{aggregates| property : rule.uniqName | property : 'sum' | slxDecimal24Switch : isShowHighPrecision }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total| property : rule.uniqName | property : 'sum' | slxDecimal24Switch : isShowHighPrecision }}</strong>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('productiveHours')" title="Productive Hours" media="sm" [width]="getWidth('productiveHours')"
                     field="productiveHours" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Productive Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.productiveHours| slxDecimal24Switch : isShowHighPrecision }}
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['productiveHours']?.sum | slxDecimal24Switch : isShowHighPrecision }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['productiveHours']?.sum | slxDecimal24Switch : isShowHighPrecision }}</strong>
    </ng-template>
  </kendo-grid-column>

     <kendo-grid-column [hidden]="!isVisible('nonProductiveHours')" title="Non-Productive Hours" media="sm" [width]="getWidth('nonProductiveHours')"
                     field="nonProductiveHours" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Non-Productive Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.nonProductiveHours| slxDecimal24Switch : isShowHighPrecision }}
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['nonProductiveHours']?.sum | slxDecimal24Switch : isShowHighPrecision }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['nonProductiveHours']?.sum | slxDecimal24Switch : isShowHighPrecision }}</strong>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column [hidden]="!isVisible('totalHours')" title="Total Hours" media="sm" [width]="getWidth('totalHours')"
                     field="totalHours" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Total Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.totalHours| slxDecimal24Switch : isShowHighPrecision }}
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['totalHours']?.sum | slxDecimal24Switch : isShowHighPrecision }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['totalHours']?.sum | slxDecimal24Switch : isShowHighPrecision }}</strong>
    </ng-template>

  </kendo-grid-column>
  <!-- <kendo-grid-column [width]="getWidth('regularHours')" [hidden]="!isVisible('regularHours')" title="Regular Hours" media="sm"
    field="regularHours" class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      Regular Hours
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.regularHours | number: ".2-2"}}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell"> {{ aggregates['regularHours']?.sum | number: ".2-2" }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['regularHours']?.sum | number: ".2-2"}}</strong>
    </ng-template>
  </kendo-grid-column> -->

  <kendo-grid-column [width]="getWidth('regularPay')" [hidden]="!isVisible('regularPay')" title="Regular Pay" media="sm" field="regularPay"
                     class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Regular Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.regularPay | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['regularPay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['regularPay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</strong>
    </ng-template>
  </kendo-grid-column>

  <!-- <kendo-grid-column [filter]="getFilter('homeShift')" [width]="getWidth('homeShift')" [hidden]="!isVisible('homeShift')" title="Home Shift"
    media="sm" field="emp.homeShift.name">
    <ng-template kendoGridHeaderTemplate>
      Home Shift
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.emp.homeShift?.name}}
    </ng-template>
  </kendo-grid-column> -->
  <!-- <kendo-grid-column [filter]="getFilter('costCenterCode')" [width]="getWidth('costCenterCode')" [hidden]="!isVisible('costCenterCode')"
    title="Cost Center Code" media="sm" field="costCenterCode">
    <ng-template kendoGridHeaderTemplate>
      Cost Center Code
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.costCenterCode}}
    </ng-template>
  </kendo-grid-column> -->
  <!-- <kendo-grid-column [width]="getWidth('overtimeAndExtraHours')" [hidden]="!isVisible('overtimeAndExtraHours')" title="Overtime and Extra Hours"
    media="sm" field="overtimeAndOtherHours" class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <div>
        <span slxTooltip="Overtime and Extra Hours" tipPosition="bottom">OT and Extra Hours</span>
      </div>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.overtimeAndExtraHours | number: ".2-2"}}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['overtimeAndExtraHours']?.sum | number: ".2-2" }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['overtimeAndExtraHours']?.sum | number: ".2-2"}}</strong>
    </ng-template>

  </kendo-grid-column> -->
  <kendo-grid-column [width]="getWidth('overTimePay')" [hidden]="!isVisible('overTimePay')" title="Overtime Pay"
                     media="sm" field="overTimePay" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Overtime Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.overTimePay | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['overTimePay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['overTimePay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</strong>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column [width]="getWidth('totalAbsencePay')" [hidden]="!isVisible('totalAbsencePay')" title="Total Paid Absences"
                     media="sm" field="totalAbsencePay" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Total Paid Absences</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.totalAbsencePay | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['totalAbsencePay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['totalAbsencePay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</strong>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column [width]="getWidth('totalOtherPay')" [hidden]="!isVisible('totalOtherPay')" title="Total Other Pay"
                     media="sm" field="totalOtherPay" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Total Other Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.totalOtherPay | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['totalOtherPay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['totalOtherPay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</strong>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('overtimeAndExtraPay')" [hidden]="!isVisible('overtimeAndExtraPay')" title="OT and Other Pay"
                     media="sm" field="overtimeAndExtraPay" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>OT and Other Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.overtimeAndExtraPay | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['overtimeAndExtraPay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['overtimeAndExtraPay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</strong>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('totalPay')" title="Total Pay" media="sm" [width]="getWidth('totalPay')" field="totalPay"
                     class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Total Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.totalPay| slxDecimal24Switch : isShowHighPrecision | slxMoney}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['totalPay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</strong>
    </ng-template>

  </kendo-grid-column>


  <kendo-grid-pdf fileName="Timecards.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>

</kendo-grid>

<div class="timecards-mobile">
  <section *ngFor="let employeesGroup of groupOfEmployees; let ind = index" class="collapsible-box">
    <h2 class="collapsible-box__header">
      <a class="collapsible-box__w-name"
        [individualTimecardsLink]="employeesGroup[0].emp.employeePosition?.employee.id"
        [startDate]="container?.payCycle.startDate"
        [endDate]="container?.payCycle.endDate"
      >{{employeesGroup[0].emp.employeePosition?.employee.name}}</a>
      <span *ngIf="employeesGroup[0].isError" class="collapsible-box__w-icons error">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      </span>
      <span *ngIf="employeesGroup[0].isWarning" class="collapsible-box__w-icons invalid">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
      <span *ngIf="employeesGroup[0].isEdited" class="collapsible-box__w-icons error">
        <i class="fa edited-tc-icon" aria-hidden="true"></i>
      </span>
      <slx-collapse-button class="collapsible-box__w-button"
        [isCollapsed]="getCurrentState(ind)"
        (isCollapsedChange)="onChangedState(ind)"
      ></slx-collapse-button>
    </h2>
    <div *ngIf="!getCurrentState(ind)" class="collapsible-box__body">
      <ng-container *ngFor="let record of employeesGroup">
        <p class="timecards-mobile__emp-info">
          <span class="timecards-mobile__emp-item"><strong >Id:</strong> {{ record?.emp.employeePosition?.employee.id }}</span>
          <span class="timecards-mobile__emp-item"><strong >Exempt Ind:</strong> {{container?.exemptStatus?.name}}</span>
          <span class="timecards-mobile__emp-item"><strong >Payroll#:</strong> {{record?.emp.employeePosition?.employee.payrollNumber}}</span>
        </p>
        <div class="timecards-mobile__grid">
          <p class="timecards-mobile__g-item">
            <label class="timecards-mobile__g-label">Department:</label>
            <span class="timecards-mobile__g-value">{{record?.departmentName}}</span>
          </p>
          <p class="timecards-mobile__g-item">
            <label class="timecards-mobile__g-label">Position:</label>
            <span class="timecards-mobile__g-value">{{record?.positionName}}</span>
          </p>
          <p class="timecards-mobile__g-item">
            <label class="timecards-mobile__g-label">Employee Type:</label>
            <span class="timecards-mobile__g-value">{{record?.emp.employeePosition?.employee.employeeType.name}}</span>
          </p>
          <p class="timecards-mobile__g-item">
            <label class="timecards-mobile__g-label">Pay Policy:</label>
            <span class="timecards-mobile__g-value">{{record?.payPolicyName}}</span>
          </p>
          <p class="timecards-mobile__g-item">
            <label class="timecards-mobile__g-label">Shift Diff Policy:</label>
            <span class="timecards-mobile__g-value">{{record?.shiftDiffPolicyName}}</span>
          </p>
          <p class="timecards-mobile__g-item">
            <label class="timecards-mobile__g-label">Approved:</label>
            <span class="timecards-mobile__g-value">{{record?.emp?.approved | boolyn}}</span>
          </p>
          <p class="timecards-mobile__g-item">
            <label class="timecards-mobile__g-label">Signoff:</label>
            <span class="timecards-mobile__g-value">{{record?.emp?.certified | boolyn}}</span>
          </p>
          <p class="timecards-mobile__g-item">
            <label class="timecards-mobile__g-label">Hire Date:</label>
            <span class="timecards-mobile__g-value">{{record?.emp.employeePosition?.employee.dateHired | amDateFormat: appConfig.dateFormat }}</span>
          </p>
          <p *ngIf="state.isShowPayRates" class="timecards-mobile__g-item">
            <label class="timecards-mobile__g-label">Pay Rate:</label>
            <span class="timecards-mobile__g-value">{{record?.payRate | number: ".2-2"| slxMoney}}</span>
          </p>
        </div>
        <slx-pay-codes-grid class="timecards-mobile__pay-codes" [payCodeModel]="getPayCodeModel(record)" [ruleList]="container?.usedRulesDefinitions"></slx-pay-codes-grid>
      </ng-container>
    </div>
  </section>
</div>
