import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';

import * as moment from 'moment';
import * as _ from 'lodash';

import { ICollapseComponent, COLLAPSE_COMPONENT } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-collapse-button',
  templateUrl: 'collapse-button.component.html',
  providers: [{
    provide: COLLAPSE_COMPONENT,
    useExisting: forwardRef(() => CollapseButtonComponent)
  }]
})
export class CollapseButtonComponent implements ICollapseComponent {

  @Input()
  public className: string;

  @Input()
  public collapsedIcon: string = 'far fa-angle-up';

  @Input()
  public expandedIcon: string = 'far fa-angle-down';

  @Input()
  public set isCollapsed(value: boolean) {
    this.m_isCollapsed = value;
    this.isCollapsedModify.emit(this.m_isCollapsed);
  }
  public get isCollapsed(): boolean {
    return this.m_isCollapsed;
  }

  @Output()
  public isCollapsedChange: EventEmitter<boolean>;
  @Output()
  public isCollapsedModify: EventEmitter<boolean>;

  private m_isCollapsed: boolean;
  constructor() {
    this.isCollapsedChange = new EventEmitter<boolean>();
    this.isCollapsedModify = new EventEmitter<boolean>();
  }

  public onToggle(): void {
    this.m_isCollapsed = !this.m_isCollapsed;
    this.isCollapsedChange.emit(this.m_isCollapsed);
  }
}
