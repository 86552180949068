import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Assert } from '../../../framework/index';
import { IAleGroupView, AleGroupView, AleGroupAssign } from '../../../configuration/models/employers/ale-group-view';
import { Lookup, State } from '../../../organization/models/index';
import { EmployersContainer, AleGroup, IAleGroup, IEmployer, Employer, OrganizationDialogOptions } from '../../models/employers/index';
import { Organization } from '../../../employee/employee-search/models';
@Injectable()
export class EmployersConfigurationMapService {

  constructor(
  ) { }

  public mapEmployers(dtos: any): EmployersContainer {
    Assert.isNotNull(dtos);
    let empContainer: EmployersContainer = new EmployersContainer();
    let arr: Employer[] = [];

    _.each(dtos.records, (dto: IEmployer) => {
      arr.push(this.mapEmployer(dto));
    });

    empContainer.canEditEmployersConfig = dtos.canEditEmployersConfig;
    empContainer.canExportToExcel = dtos.canExportToExcel;
    empContainer.records = arr;
    return empContainer;
  }

  public mapEmployer(dto: IEmployer): Employer {

    let model: Employer = new Employer();
    model.companyId = dto.companyId;
    model.legalName = dto.legalName;
    model.tradeName = dto.tradeName;
    model.federalEIN = dto.federalEIN;
    model.acA1095TCC_Code = dto.acA1095TCC_Code;
    model.legalAddress1 = dto.legalAddress1;
    model.legalAddress2 = dto.legalAddress2;
    model.legalCity = dto.legalCity;
    model.legalStateModel = new State();
    model.legalStateModel.name = dto.legalState;
    model.legalState = dto.legalState;
    model.legalZip = dto.legalZip;
    model.businessAddress1 = dto.businessAddress1;
    model.businessAddress2 = dto.businessAddress2;
    model.businessCity = dto.businessCity;
    model.businessStateModel = new State();
    model.businessStateModel.name = dto.businessState;
    model.businessState = dto.businessState;
    model.businessZip = dto.businessZip;
    model.firstName = dto.firstName;
    model.lastName = dto.lastName;
    model.phone = dto.phone;
    model.fax = dto.fax;
    model.email = dto.email;
    model.selfInsured = dto.selfInsured;
    model.deleted = dto.deleted;
    model.modifiedBy = dto.modifiedBy;
    model.modifiedOn = dto.modifiedOn;
    model.aleGroupId = dto.aleGroupId;
    model.aleGroupName = dto.aleGroupId !=0 ? dto.aleGroupName:'ALE Group 1';
    model.aleGroupModel = new AleGroup();
    model.aleGroupModel.groupId = model.aleGroupId;
    model.aleGroupModel.groupName = model.aleGroupName;
    model.isSameAsLegalAddress = dto.isSameAsLegalAddress;
    model.isSelfInsured = dto.isSelfInsured;
    return model;
  }


  public mapAleGroups(dtos: IAleGroup[]): AleGroup[] {
    Assert.isNotNull(dtos);
    let arr: AleGroup[] = [];
    _.each(dtos, (dto: AleGroup) => {
      arr.push(this.mapAleGroup(dto));
    });

    return arr;
  }

  public mapAleGroup(dto: AleGroup): AleGroup {
    let model: AleGroup = new AleGroup();
    model.groupId = dto.groupId;
    model.groupName = dto.groupName;
    model.orgLevelId = dto.orgLevelId;
    return model;
  }

  public mapToPositionGroupView(
    employers: Employer[],
    groups: AleGroup[],
    defaultGroupId: number,
    defaultGroupName: string
  ): AleGroupView[] {

    const groupsList = new Map<number, AleGroupView>();
    this.addGroupViews(groups, groupsList, defaultGroupId, defaultGroupName);
    this.addEmployerToGroups(employers, groupsList, defaultGroupId);
    return Array.from(groupsList.values());
  }

  public mapPositionGroupDto(group: AleGroup): IAleGroup {
    const dto = {} as IAleGroup;

    if (_.isFinite(group.groupId)) {
      dto.groupId = group.groupId;
    }

    if (_.size(group.groupName) > 0) {
      dto.groupName = group.groupName;
    }

    if (_.isFinite(group.orgLevelId)) {
      dto.orgLevelId = group.orgLevelId;
    }

    return dto;
  }

  public mapToEmployersDto(edto: Employer): IEmployer {
    let model: IEmployer = new Employer();
    model.companyId = edto.companyId;
    model.legalName = edto.legalName;
    model.tradeName = edto.tradeName;
    model.federalEIN = edto.federalEIN;
    model.acA1095TCC_Code = edto.acA1095TCC_Code;
    model.legalAddress1 = edto.legalAddress1;
    model.legalAddress2 = edto.legalAddress2;
    model.legalCity = edto.legalCity;
    model.legalState = edto.legalStateModel.name;
    model.legalStateModel = edto.legalStateModel;
    model.legalZip = edto.legalZip;
    model.businessAddress1 = edto.businessAddress1;
    model.businessAddress2 = edto.businessAddress2;
    model.businessCity = edto.businessCity;
    model.businessState = edto.businessStateModel?edto.businessStateModel.name:null;
    model.businessStateModel = edto.businessStateModel;
    model.businessZip = edto.businessZip;
    model.firstName = edto.firstName;
    model.lastName = edto.lastName;
    model.phone = edto.phone;
    model.fax = edto.fax;
    model.email = edto.email;
    model.selfInsured = edto.selfInsured;
    model.deleted = edto.deleted;
    model.modifiedBy = edto.modifiedBy;
    model.modifiedOn = edto.modifiedOn;
    model.aleGroupId = edto.aleGroupModel ? edto.aleGroupModel.groupId : 0;
    model.aleGroupName = edto.aleGroupModel ? edto.aleGroupModel.groupName: null;
    model.isSameAsLegalAddress = edto.isSameAsLegalAddress;
    model.isSelfInsured = edto.isSelfInsured;
    return model;
  }

  public mapToGroupsWithEmployers(group: AleGroupView, employer: Employer, defaultGroupId: number, defaultGroupName: string): IAleGroupView {
    const isDefaultGroup = defaultGroupId === group.groupId && defaultGroupName === group.groupName;
    const groupDto: IAleGroupView = {
      groupId: isDefaultGroup ? null : group.groupId,
      employerIds: [employer.companyId]
    };
    return groupDto as IAleGroupView;
  }

  public makePosViewOfPosGroup(g: AleGroup, isEditing: boolean = false): AleGroupView {
    if (_.isObjectLike(g)) {
      return this.createGroupView(g.groupId, g.groupName, g.orgLevelId, isEditing);
    }
    return null;
  }

  public createGroupView(id: number = null, name: string = '', depId: number = null, isEditing: boolean = false): AleGroupView {
    const group = new AleGroupView();
    group.groupId = id;
    group.groupName = name;
    group.orgLevelId = depId;
    group.employers = [];
    group.isEditing = isEditing;

    return group;
  }

  private addGroupViews(
    groups: AleGroup[],
    groupsList: Map<number, AleGroupView>,
    defaultGroupId: number,
    defaultGroupName: string
  ): void {
    const defaultGroup = this.createGroupView(defaultGroupId, defaultGroupName, -1);
    groupsList.set(defaultGroup.groupId, defaultGroup);

    _.forEach(groups, (group) => {
      const groupView = this.createGroupView(group.groupId, group.groupName, group.orgLevelId);
      groupsList.set(groupView.groupId, groupView);
    });
  }

  private addEmployerToGroups(employers: Employer [], groups: Map<number, AleGroupView>, defaultGroupId: number): void {
    _.forEach(employers, (employer) => {
      if (_.isFinite(employer.aleGroupId) && groups.has(employer.aleGroupId)) {
        groups.get(employer.aleGroupId).employers.push(employer);
      } else if (!_.isFinite(employer.aleGroupId) && groups.has(defaultGroupId)) {
        groups.get(defaultGroupId).employers.push(employer);
      }
    });
  }

  public mapEmployersOrganization(dtos: any): OrganizationDialogOptions {
    Assert.isNotNull(dtos);
    let arr: OrganizationDialogOptions = new OrganizationDialogOptions();
    arr.organization = [];
    _.each(dtos, (dto: any) => {
      arr.organization.push(this.mapOrganization(dto));
    });
    return arr;
  }

  public mapOrganization(dto: any): Organization {
    let model: Organization = new Organization();
    model.id = dto.organizationId;
    model.name = dto.name;
    return model;
  }

  public mapAssignGroup(dto: AleGroupAssign[]): AleGroupAssign[] {

    let data: AleGroupAssign[] = [];

    _.forEach(dto, (groupAssign) => {
      let group = new AleGroupAssign();
      group.aleGroupId = groupAssign.aleGroupId ? groupAssign.aleGroupId : 0;
      group.companyIds = groupAssign.companyIds;
      data.push(group);

    });
    return data;
  }

  public mapEmployerGroup(dto: IAleGroup): AleGroup {
    const group = new AleGroup();
    group.groupId = dto.groupId;
    group.groupName = dto.groupName;
    group.orgLevelId = dto.orgLevelId;
    group.deleted = dto.deleted;

    return group;
  }

  public mapEmployerGroupDto(group: AleGroup): IAleGroup {
    const dto = {} as IAleGroup;

    if (_.isFinite(group.groupId)) {
      dto.groupId = group.groupId;
    }

    if (group.deleted) {
      dto.deleted = group.deleted;
    }

    if (_.size(group.groupName) > 0) {
      dto.groupName = group.groupName;
    }

    if (_.isFinite(group.orgLevelId)) {
      dto.orgLevelId = group.orgLevelId;
    }

    return dto;
  }
}
