import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BreadcrumbStateService } from '../../../core/services/index';

@Directive({
  selector: '[slxBackLink]',
})
export class BackLinkDirective {
  constructor(private location: Location, private router: Router, private breadcrumbStateService: BreadcrumbStateService) {
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let url = this.breadcrumbStateService.popPrevRoute();
    if (url) {
      if (url instanceof Array) {
        this.router.navigate(url);
      } else {
        this.router.navigateByUrl(url);
      }
      return;
    }
    this.location.back();
  }
}
