import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { LookupMapService, EmployeeDefinitionsMapService, OrgLevelMapService } from '../../../organization/services/index';
import { IEmployeePositionDefinition, EmployeePositionDefinition } from '../../../organization/models/index';
import { dateTimeUtils } from '../../../common/utils/index';


import { SupervisorGroupDefinition, ISupervisorGroupDefinition, IEmployeeDefinition, EmployeeDefinition } from '../../../organization/models/index';

import {
  ISupervisor, Supervisor, IEligibleSupervisor, EligibleSupervisor,
  ISupervisedEmployee, SupervisedEmployee, ISupervisorGroup, SupervisorGroup, SupervisorGroupsContainer, UnassignedEmployee
} from '../../models/index';

@Injectable()
export class SupervisorAssignmentMapService {
  constructor(private lookupMapService: LookupMapService, private employeeDefinitionsMapService: EmployeeDefinitionsMapService, private orgLevelMapService: OrgLevelMapService) {
  }

  public mapGroupDefinition(dto: ISupervisorGroupDefinition): SupervisorGroupDefinition {
    Assert.isNotNull(dto, 'ISupervisorGroupDefinition');
    return this.lookupMapService.mapSupervisorGroupDefinition(dto);
  }

  public mapGroupDefinitionDto(data: SupervisorGroupDefinition): ISupervisorGroupDefinition {
    Assert.isNotNull(data, 'SupervisorGroupDefinition');
    return this.lookupMapService.mapSupervisorGroupDefinitionDto(data);
  }

  public mapSupervisorIds(data: Supervisor[]): number[] {
    return _.map(data, (s: Supervisor) => { return s.id; });
  }

  public mapEmployeeIds(data: SupervisedEmployee[]): number[] {
    return _.map(data, (s: SupervisedEmployee) => { return s.id; });
  }

  public mapEligibleSupervisor(dto: IEligibleSupervisor): EligibleSupervisor {
    Assert.isNotNull(dto, 'IEligibleSupervisor');
    let data: EligibleSupervisor = new EligibleSupervisor();
    data.role = dto.role;
    if (dto.user) data.user = this.lookupMapService.mapUserProfileDefinition(dto.user);
    if (dto.employee) data.employee = this.employeeDefinitionsMapService.mapToEmployeePosition(dto.employee);
    if (dto.groups) data.groups = this.lookupMapService.mapSupervisorGroupDefinitions(dto.groups);
    return data;
  }

  public mapEligibleSupervisorDto(data: EligibleSupervisor): IEligibleSupervisor {
    Assert.isNotNull(data, 'EligibleSupervisor');
    let dto: IEligibleSupervisor = {
      role: data.role,
      user: this.lookupMapService.mapUserProfileDefinitionDto(data.user),
      employee: this.employeeDefinitionsMapService.mapToEmployeePositionDefinitionDto(data.employee),
      groups: this.lookupMapService.mapSupervisorGroupDefinitionDtos(data.groups)
    };
    return dto;
  }

  public mapEligibleSupervisors(dto: IEligibleSupervisor[]): EligibleSupervisor[] {
    return _.map(dto, (dtoDef: IEligibleSupervisor) => { return this.mapEligibleSupervisor(dtoDef); });
  }

  public mapEligibleSupervisorsDto(data: EligibleSupervisor[]): IEligibleSupervisor[] {
    return _.map(data, (s: EligibleSupervisor) => { return this.mapEligibleSupervisorDto(s); });
  }

  public mapSupervisor(dto: ISupervisor): Supervisor {
    Assert.isNotNull(dto, 'ISupervisor');
    let data: Supervisor = new Supervisor();
    data.id = dto.id;
    if (dto.user) data.user = this.lookupMapService.mapUserProfileDefinition(dto.user);
    data.role = dto.role;
    data.approvePTO = dto.approvePTO;
    data.punchOverride = dto.punchOverride;
    data.isPrimary = dto.isPrimary;
    if (dto.employee) data.employee = this.employeeDefinitionsMapService.mapToEmployeePosition(dto.employee);
    if (dto.groups) data.groups = this.lookupMapService.mapSupervisorGroupDefinitions(dto.groups);
    return data;
  }

  public mapSupervisorDto(data: Supervisor): ISupervisor {
    Assert.isNotNull(data, 'Supervisor');
    let dto: ISupervisor = {
      id: data.id,
      user: this.lookupMapService.mapUserProfileDefinitionDto(data.user),
      role: data.role,
      approvePTO: data.approvePTO,
      punchOverride: data.punchOverride,
      isPrimary: data.isPrimary,
      employee: data.employee ? this.employeeDefinitionsMapService.mapToEmployeePositionDefinitionDto(data.employee) : undefined,
      groups: data.user ? this.lookupMapService.mapSupervisorGroupDefinitionDtos(data.groups) : undefined
    };
    return dto;
  }

  public mapSupervisors(dto: ISupervisor[]): Supervisor[] {
    return _.map(dto, (dtoDef: ISupervisor) => { return this.mapSupervisor(dtoDef); });
  }

  public mapSupervisorsDto(data: Supervisor[]): ISupervisor[] {
    return _.map(data, (s: Supervisor) => { return this.mapSupervisorDto(s); });
  }
  public mapSupervisedEmployee(dto: ISupervisedEmployee): SupervisedEmployee {
    Assert.isNotNull(dto, 'ISupervisedEmployee');
    let data: SupervisedEmployee = new SupervisedEmployee();
    data.id = dto.id;
    data.badgeId = dto.badgeId;
    data.employee = this.employeeDefinitionsMapService.mapToEmployeePosition(dto.employee);
    data.groups = this.lookupMapService.mapSupervisorGroupDefinitions(dto.groups);
    return data;
  }

  public mapSupervisedEmployees(dto: ISupervisedEmployee[]): SupervisedEmployee[] {
    return _.map(dto, (dtoDef: ISupervisedEmployee) => { return this.mapSupervisedEmployee(dtoDef); });
  }

  public mapSupervisorGroup(dto: ISupervisorGroup): SupervisorGroup {
    Assert.isNotNull(dto, 'ISupervisorGroup');
    let data: SupervisorGroup = new SupervisorGroup();
    data.id = dto.id;
    data.name = dto.name;
    data.orgLevel = this.orgLevelMapService.mapToOrgLevel(dto.orgLevel);
    data.employees = this.mapSupervisedEmployees(dto.employees);
    data.supervisors = this.mapSupervisors(dto.supervisors);
    return data;
  }

  public mapSupervisorGroupsContainer(dto: ISupervisorGroup[]): SupervisorGroupsContainer {
    let container: SupervisorGroupsContainer = new SupervisorGroupsContainer();
    container.groups = _.map(dto, (dtoDef: ISupervisorGroup) => { return this.mapSupervisorGroup(dtoDef); });
    return container;
  }

  public mapUnassignedEmployees(employees: IEmployeePositionDefinition[]): UnassignedEmployee[] {
    let unassigned: UnassignedEmployee[] = [];
    _.each(employees, (emp: IEmployeePositionDefinition) => {
      let employee: EmployeePositionDefinition = this.employeeDefinitionsMapService.mapToEmployeePosition(emp);
      let unassignedEmployee: UnassignedEmployee = new UnassignedEmployee();
      unassignedEmployee.employee = employee;
      unassigned.push(unassignedEmployee);
    });
    return unassigned;
  }

}

