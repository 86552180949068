<div *ngIf="!fieldDef">
  Value not found!!!
</div>
<div *ngIf="fieldDef && !fieldDef.hyperlink">
  <div [ngSwitch]="fieldDef.fieldType" [ngClass]="getClassName()">
    <span *ngSwitchCase="'check'">
       <input *ngIf="genRow.selectable" name="{{genRow.index}}" type="checkbox" [(ngModel)]="genRow.selected" (ngModelChange)="selectionChange(genRow)">
    </span>
    <span *ngSwitchCase="'date'">{{genRow.fields | property: fieldDef.fieldName | property: 'value' | property: 'value' |  invalidDate | amDateFormat: appConfig.dateFormat }}</span>
    <span *ngSwitchCase="'datetime'">{{genRow.fields | property: fieldDef.fieldName | property: 'value'  | property: 'value' |  invalidDate | amDateFormat: appConfig.dateTimeFormat }}</span>
    <span *ngSwitchDefault>{{genRow.fields | property: fieldDef.fieldName | property: 'value' | property: 'value' }}</span>
  </div>
</div>
<div *ngIf="fieldDef && fieldDef.hyperlink">
  <div [ngSwitch]="fieldDef.hyperlink.linkType">
    <ng-container *ngSwitchCase="'employee'">
      <ng-container *ngIf="disableEmployeeLink">
        <a (click)="onEmployeeLinkClick(genRow.fields[fieldDef.hyperlink.fieldNames[0]].value.value)">
          <div [ngSwitch]="fieldDef.fieldType" [ngClass]="getClassName()">
            <span *ngSwitchCase="'date'">{{genRow.fields | property: fieldDef.fieldName| property: 'value' | property: 'value' |  invalidDate | amDateFormat: appConfig.dateFormat }}</span>
            <span *ngSwitchCase="'datetime'">{{genRow.fields | property: fieldDef.fieldName | property: 'value' | property: 'value' |  invalidDate | amDateFormat: appConfig.dateTimeFormat }}</span>
            <span *ngSwitchDefault>{{genRow.fields | property: fieldDef.fieldName | property: 'value' | property: 'value' }}</span>
          </div>
        </a>
      </ng-container>
      <ng-container *ngIf="!disableEmployeeLink">
        <a [employeeSectionLink]="genRow.fields[fieldDef.hyperlink.fieldNames[0]] | property: 'value' | property: 'value'">
          <div [ngSwitch]="fieldDef.fieldType" [ngClass]="getClassName()">
            <span *ngSwitchCase="'date'">{{genRow.fields | property: fieldDef.fieldName| property: 'value' | property: 'value' |  invalidDate | amDateFormat: appConfig.dateFormat }}</span>
            <span *ngSwitchCase="'datetime'">{{genRow.fields | property: fieldDef.fieldName | property: 'value' | property: 'value' |  invalidDate | amDateFormat: appConfig.dateTimeFormat }}</span>
            <span *ngSwitchDefault>{{genRow.fields | property: fieldDef.fieldName | property: 'value' | property: 'value' }}</span>
          </div>
        </a>
      </ng-container>
    </ng-container>
    <a *ngSwitchCase="'timecard'"
        [dailyTimecardsLink]="genRow.fields[fieldDef.hyperlink.fieldNames[0]] | property: 'value' | property: 'value'"
        [timecardDate]="genRow.fields[fieldDef.hyperlink.fieldNames[1]] | property: 'value' | property: 'value'">
      <div [ngSwitch]="fieldDef.fieldType" [ngClass]="getClassName()">
        <span *ngSwitchCase="'date'">{{genRow.fields | property: fieldDef.fieldName | property: 'value' | property: 'value' |  invalidDate | amDateFormat: appConfig.dateFormat }}</span>
        <span *ngSwitchCase="'datetime'">{{genRow.fields | property: fieldDef.fieldName | property: 'value' | property: 'value' |  invalidDate | amDateFormat: appConfig.dateTimeFormat }}</span>
        <span *ngSwitchDefault>{{genRow.fields | property: fieldDef.fieldName | property: 'value' | property: 'value' }}</span>
      </div>
    </a>
    <span *ngSwitchDefault>Unkown link type {{fieldDef.hyperlink.linkType}}</span>
  </div>
</div>
