<slx-spinner [show]="state.inProcess">
  <form #form="ngForm">
    <div class="send-sms-container">
      <div class="open-shift-info">
        <div>
          <slx-input-decorator>
            <input slx-input type="text" filled maxlength="50" [readonly]="true" name="position" placeholder="Position" [(ngModel)]="sendSmsRequest.position.name">
          </slx-input-decorator>
        </div>
        <div class="col-xs-6">
          <slx-input-decorator>
            <input slx-input type="text" filled maxlength="50" [readonly]="true" name="shift" placeholder="Shift" [(ngModel)]="sendSmsRequest.shift.name">
          </slx-input-decorator>
        </div>
        <div class="col-xs-6">
          <slx-input-decorator>
            <input slx-input type="text" filled maxlength="50" [readonly]="true" name="unit" placeholder="Unit" [(ngModel)]="sendSmsRequest.unit.name">
          </slx-input-decorator>
        </div>
      </div>
      <div class="recipient-info">
        <slx-input-decorator>
          <slx-dropdown-multiselect slx-input [options]="recipientsInfo" [readonly]="true" valueField="recipientId" titleField="displayName"
            [(ngModel)]="selectedRecipients" name="recipientsDropdown" [required]="true" placeholder="Recipient">
          </slx-dropdown-multiselect>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="message-info">
        <slx-input-decorator className="slx-form-group slx-form-expand">
          <textarea class="message-text" slx-input placeholder="Message Text" name="messageTextArea" [required]="true" maxlength="140"
            [(ngModel)]="sendSmsRequest.messageText"></textarea>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="message-actions pull-right">
        <button type="button" class="btn deny-action-button" (click)="cancel()">
            Cancel
          </button>
        <button type="button" class="btn accept-action-button" (click)="send()" [class.disabled]="form.invalid">
            <i class="fa fa-paper-plane" aria-hidden="true"></i> Send
          </button>
      </div>
    </div>
  </form>
</slx-spinner>
