import { ITImecardSummarySectionContainer } from './timecard-summary-section-container.interface';

export class HoursSummaryContainer implements ITImecardSummarySectionContainer {
    public records: TimecardSummaryPaidHours[];
}

export class TimecardSummaryPaidHours {
    public payDescription: string;
    public payCode: string;
    public currentCycleHours: number;
    public currentCycleHourlyPay: number;
    public currentCycleOtherPay: number; 
    public currentCycleAmount: number;
    public previousCycleHours: number;
    public previousCycleHourlyPay: number;
    public previousCycleOtherPay: number; 
    public previousCycleAmount: number;
    public differenceCycleHours: number;
    public differenceCycleHourlyPay: number;
    public differenceCycleOtherPay: number; 
    public differenceCycleAmount: number;
}
