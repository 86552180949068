
import * as _ from 'lodash';
import { Injectable, EventEmitter } from '@angular/core';
import { OrgLevel } from '../../../state-model/models/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/observable/forkJoin';
import { DashboardApplication, DashboardApplications, StyledUserApplication } from '../../../organization/models/index';
import { Assert } from '../../../framework/index';
import {
  ISoDashboardCounters,
  SoDashboardCounters,
  ITaDashboardCounters,
  TaDashboardCounters,
  AlertConfig,
  DashBoardAlertsModel,
  DashboardAppAlertConfig,
  AppAlertConfig,
  DashboardAppTypeEnum,
  AlertClassEnum
} from '../../models/index';
import { ModalService } from './../../../common/services/modal/modal.service';
import { ApplicationDashboardApiService } from './application-dashboard-api.service';
import { assert } from 'console';
import { NotificationsService } from '../../../core/components/index';
import { ApplicationsConfigDialogComponent } from '../../components/applications-dashboard/applications-config-dialog/applications-config-dialog.component';
import { ApplicationService } from '../application/application.service';
import { IUser } from '../../../authentication/store/session/session.types';
import { SessionService } from '../../../core/services/session/session.service';
import { appConfig } from '../../../app.config';

enum ApplicationList {
  Scheduler = "Schedule Optimizer",
  Time = "Time & Attendance",
  HR = "HRMS",
  Reports = "Reports",
  PBJ = "PBJ",
  More = "More Apps..."
}

@Injectable()
export class ApplicationDashboardManagementService {
  private apiService: ApplicationDashboardApiService;
  private countersLoaded$: Subject<StringMap<SoDashboardCounters | TaDashboardCounters>>;
  private appNames: StringMap<string>;
  private orgLevel: OrgLevel;
  private appConfigWindowOpened: boolean;

  public appsData: StyledUserApplication[] = [];
  private loading$ = new Subject<boolean>();
  public dashboardApps$: ReplaySubject<DashboardApplications> = new ReplaySubject(1);
  private dashboardAlerts: DashboardAppAlertConfig;
  private constOrgId: number = 1;
  constructor(apiService: ApplicationDashboardApiService,
    private notificationService: NotificationsService,
    private modalService: ModalService, private applicationService: ApplicationService, private sessionService: SessionService) {
    this.apiService = apiService;
    this.countersLoaded$ = new Subject<StringMap<SoDashboardCounters | TaDashboardCounters>>();
    this.appNames = {
      so: 'Scheduler',
      ta: 'Time'
    };

  }

  public destroy(): void {
    this.loading$.complete();
  }

  public subscribeToLoading(callback: (o: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }


  public async modifyAlerts(model: DashBoardAlertsModel[]): Promise<void> {
    try {
      this.loading$.next(true);
      model.forEach(x => x.orgLevelId = this.constOrgId);
      await this.apiService.modifyAlerts(model);
      this.notificationService.success('Alert Saved', 'Alert saved successfully');
    } finally {
      this.loading$.next(false);
    }
  }

  public loadAppCounters(applications: StyledUserApplication[], orgLevel: OrgLevel): void {
    if (applications && applications.length > 0) {
      this.orgLevel = orgLevel;
      const counters: Promise<any>[] = _.reduce(applications, (accumulator: Promise<any>[], app: StyledUserApplication) => {
        if (app.loadCounters) {
          accumulator.push(this.loadCounter(app));
        }
        return accumulator;
      }, []);

      this.resolveCounters(counters);
    }
  }

  public subscribeToCountersLoaded(callback: (counters: StringMap<SoDashboardCounters | TaDashboardCounters>) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.countersLoaded$.subscribe(callback);
  }

  private countersLoaded(counters: StringMap<SoDashboardCounters | TaDashboardCounters>): void {
    this.countersLoaded$.next(counters);
  }

  private resolveCounters(promises: Promise<any>[]): void {
    const subscription: Subscription = Observable.forkJoin(promises)
      .subscribe((counters: any) => {
        this.countersLoaded(_.fromPairs(counters));
        subscription.unsubscribe();
      });
  }
  private alertsData() {
    this.dashboardAlerts = {
      soAlerts: {
        appId: DashboardAppTypeEnum.soAlerts,
        alerts: [{
          alertId: 1,
          alertName: 'Open Shift Requests',
          userAppAlertId: 0,
          link: 'open_shift_management',
          icon: 'far fa-address-card',
          displayOrder: 1,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        },
        {
          alertId: 2,
          userAppAlertId: 0,
          alertName: 'Emp Projected Overtime',
          link: 'so_console',
          icon: 'far fa-clock',
          displayOrder: 2,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        },
        {
          alertId: 3,
          userAppAlertId: 0,
          alertName: 'PTO Req',
          link: 'leave_requests',
          icon: 'fa fa-user-times',
          displayOrder: 3,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        }
        ]
      },
      taAlerts: {
        appId: DashboardAppTypeEnum.taAlerts,
        alerts: [{
          alertId: 1,
          userAppAlertId: 0,
          alertName: 'Missing Punch Count',
          link: 'daily_punches',
          icon: 'far fa-calendar-times',
          displayOrder: 1,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        },
        {
          alertId: 2,
          userAppAlertId: 0,
          alertName: 'Overtime Approvals',
          link: 'overtime_approvals',
          icon: 'far fa-calendar-plus',
          displayOrder: 2,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        },
        {
          alertId: 3,
          userAppAlertId: 0,
          alertName: 'Timecard Exceptions',
          link: 'exception_console',
          icon: 'far fa-clock',
          displayOrder: 3,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        }
        ]
      },
      lmAlerts: {
        appId: DashboardAppTypeEnum.lmAlerts,
        alerts: [{
          alertId: 1,
          userAppAlertId: 0,
          alertName: 'On Leave',
          link: 'OnLeave',
          icon: 'far fa-calendar-times',
          displayOrder: 1,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        },
        {
          alertId: 2,
          userAppAlertId: 0,
          alertName: 'Returning',
          link: 'Returning',
          icon: 'far fa-calendar-plus',
          displayOrder: 2,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        },
        {
          alertId: 3,
          userAppAlertId: 0,
          alertName: 'Upcoming',
          link: 'Upcoming',
          icon: 'far fa-clock',
          displayOrder: 3,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        },
        {
          alertId: 4,
          userAppAlertId: 0,
          alertName: 'Incomplete',
          link: 'Incomplete',
          icon: 'far fa-calendar-plus',
          displayOrder: 4,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        },
        {
          alertId: 5,
          userAppAlertId: 0,
          alertName: 'Past Due',
          link: 'PastDue',
          icon: 'far fa-clock',
          displayOrder: 5,
          counter: 0,
          visible: true,
          loginId: 'system',
          lastModifiedDate: new Date()
        }]
      }
    };
  }

  public loadCounter(app: StyledUserApplication): Promise<any> {
    let promise: Promise<any> = null;
    switch (app.name) {
      case this.appNames.so:
        promise = this.apiService.getSoCounters(this.orgLevel.id);
        break;
      case this.appNames.ta:
        promise = this.apiService.getTaCounters(this.orgLevel.id);
        break;
      default:
        promise = Promise.resolve(null);
    }
    return promise.then((res: any) => [app.name, res]);
  }

  public async getDashboardAppAlerts(appId: number): Promise<AlertConfig[]> {
    try {
      this.alertsData();
      this.loading$.next(true);
      return await this.apiService.getDashboardUserConfig(this.constOrgId)
        .then((res: DashboardAppAlertConfig) => {
          let alertdefinition: AppAlertConfig = new AppAlertConfig();
          switch (appId) {
            case DashboardAppTypeEnum.soAlerts:
              alertdefinition = { appId: appId, alerts: this.UpdateUserAlertConfigData(res.soAlerts.alerts, this.dashboardAlerts.soAlerts.alerts) };
              break;
            case DashboardAppTypeEnum.lmAlerts:
              alertdefinition = { appId: appId, alerts: this.UpdateUserAlertConfigData(res.lmAlerts.alerts, this.dashboardAlerts.lmAlerts.alerts) };
              break;
            case DashboardAppTypeEnum.taAlerts:
              alertdefinition = { appId: appId, alerts: this.UpdateUserAlertConfigData(res.taAlerts.alerts, this.dashboardAlerts.taAlerts.alerts) };
              break;
            default:
              alertdefinition.alerts = [];
              break;
          }
          _.forEach(alertdefinition.alerts, (a) => {
            let classId = _.toInteger(`${a.alertId}${appId}`);
            a.icon = AlertClassEnum[classId];
          });
          return _.sortBy(alertdefinition.alerts, rec => rec.displayOrder);
        });
    } finally {
      this.loading$.next(false);
    }
  }

  public UpdateUserAlertConfigData(alertdefinition: AlertConfig[], alertData: AlertConfig[]): AlertConfig[] {
    _.forEach(alertData, (record: AlertConfig) => {
      _.forEach(alertdefinition, (alert: AlertConfig) => {
        if (record.alertId == alert.alertId) {
          record.userAppAlertId = alert.userAppAlertId;
          record.icon = alert.icon;
          record.loginId = alert.loginId;
          record.displayOrder = alert.displayOrder;
          record.visible = alert.visible;
        }
      });
    });
    return alertData;
  }

  public showConfigDialog(): void {
    this.appConfigWindowOpened = true;
    ApplicationsConfigDialogComponent.showDialog(this.modalService, (hasChanges: boolean) => {
      this.appConfigWindowOpened = false;
      if (hasChanges) {
        this.loadApps(true);
      }
    });
  }

  public async loadApps(updateCacheForced: boolean = false): Promise<DashboardApplications> {
    try {
      this.loading$.next(true);
      return await this.apiService.loadApps(updateCacheForced).then((res) => {
        let apps = res;
        if (!this.appConfigWindowOpened) {
          this.dashboardApps$.next(apps);
        }
        return apps;
      });
    } finally {
      this.loading$.next(false);
    }

  }

  public async modifyApps(model: DashboardApplication[]): Promise<void> {
    try {
      this.loading$.next(true);
      await this.apiService.modifyApps(model);
    } finally {
      this.loadApps(true);
      this.loading$.next(false);
    }
  }

}
