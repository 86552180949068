import * as _ from 'lodash';
import { Component, Provider, ViewChild } from '@angular/core';

import { DialogOptions, IDialog } from '../../../common/models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { LeaveRequestEntry } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-leave-request-confirm',
  templateUrl: 'leave-request-confirm.component.html',
  styleUrls: ['leave-request-confirm.component.scss']
})
export class LeaveRequestConfirmComponent implements IDialog {
  public entry: LeaveRequestEntry;
  public dialogResult: boolean;
  private options: DialogOptions;
  private modalService: ModalService;

  public static openDialog(data: LeaveRequestEntry, modalService: ModalService, callback: (result: boolean) => void): LeaveRequestConfirmComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 350;
    dialogOptions.height = 200;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: LeaveRequestEntry,
        useValue: data
      }
    ];
    let dialog: LeaveRequestConfirmComponent = modalService.globalAnchor.openDialog(
      LeaveRequestConfirmComponent,
      data.header,
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
    });

    return dialog;
  }

  constructor(
    entry: LeaveRequestEntry,
    options: DialogOptions,
    modalService: ModalService
  ) {
    this.entry = entry;
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
