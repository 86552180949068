import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../../core/decorators/index';
import {
  BenefitDetailsEditModes,
  BenefitDetailsBasic,
  BenefitDetailsUserActions,
  BenefitDetailsTier,
  BenefitDetailsFormula,
  BenefitFormulaCalculationType,
  BenefitDetailsProviderLineStandartEntity
} from '../../../models/index';
import { BenefitDetailsManagementService, BenefitDetailsStandartManagementService, BenefitDetailsPermissionService } from '../../../services/index';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'slx-benefit-details-coverage-calc-formula',
  templateUrl: './benefit-details-coverage-calc-formula.component.html',
  styleUrls: ['./benefit-details-coverage-calc-formula.component.scss'],
})
export class BenefitDetailsCoverageCalcFormulaComponent implements OnInit, OnDestroy {
  public calculationTypesShort: BenefitDetailsBasic<number, BenefitFormulaCalculationType>[] = [];
  public calculationTypes: BenefitDetailsBasic<number, BenefitFormulaCalculationType>[] = [];

  public empFormula = new BenefitDetailsFormula();
  public empContribution = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
  public empFormulaText = '';
  public empFixed = 0.00;

  public emprFormula = new BenefitDetailsFormula();
  public emprContribution = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
  public emprFormulaText = '';
  public emprFixed = 0.00;

  public covFormula = new BenefitDetailsFormula();
  public coverage = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
  public covFormulaText = '';
  public covFixed = 0.00;
  public covMultiplier = 0.00;
  public covMaxCap = 0.00;

  public isEditMode = false;
  public min: number = 0;
  public max: number = 99999999.99;
  public multiplierMax = 100;
  public step = 1;
  public format = 'c2';
  public multiplierFormat = 'n2';
  public multiplierMaxValues: number[];
  public hasEnrolledEmployees: boolean = false;
  public hasEnrolledEmpTierLevel: boolean = false;

  public get isFormulaEditable(): boolean {
    return this.isEditMode && this.сanEditFormula;
  }

  public get isEmpFixedCalcType(): boolean {
    return this.empContribution.name === this.empFormula.calculationType && this.empFormula.isFixedCalcType;
  }

  public get isEmpFormulaCalcType(): boolean {
    return this.empContribution.name === this.empFormula.calculationType && this.empFormula.isFormulaCalcType;
  }

  public get isEmprFixedCalcType(): boolean {
    return this.emprContribution.name === this.emprFormula.calculationType && this.emprFormula.isFixedCalcType;
  }

  public get isEmprFormulaCalcType(): boolean {
    return this.emprContribution.name === this.emprFormula.calculationType && this.emprFormula.isFormulaCalcType;
  }

  public get isCovFormulaCalcType(): boolean {
    return this.coverage.name === this.covFormula.calculationType && this.covFormula.isFormulaCalcType;
  }

  public get isCovFixedCalcType(): boolean {
    return this.coverage.name === this.covFormula.calculationType && this.covFormula.isFixedCalcType;
  }

  public get isCovMultiplierCalcType(): boolean {
    return this.coverage.name === this.covFormula.calculationType && this.covFormula.isMultiplierCalcType;
  }

  public get isShownFormula(): boolean {
    return this.showFormula;
  }

  public get isCovAnyValueCalcType(): boolean {
    return this.coverage.name === this.covFormula.calculationType && this.covFormula.isAnyValueMaxCapCalcType;
  }

  public get isMaxCapLessThanMultiplier(): boolean {
    if (this.covFormula.isMultiplierCalcType && !_.isNull(this.covMaxCap) && !_.isNull(this.covMultiplier)) {
      return _.lt(this.covMaxCap, this.covMultiplier);
    }
  }

  @ViewChild('form', {static: true}) 
  ngForm: NgForm;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private сanEditFormula = false;
  private showFormula = false;
  private tierIsDraft = false;

  constructor(
    private permissionService: BenefitDetailsPermissionService,
    private manService: BenefitDetailsStandartManagementService,
    private commonManService: BenefitDetailsManagementService
  ) {
    this.calculationTypes = this.manService.getFormulaTypes();
    this.calculationTypesShort = this.calculationTypes.slice(0, 2);
    this.multiplierMaxValues = [500, 1000, 2000, 5000, 10000];
  }

  public ngOnInit() {
    this.subscriptions.editMode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => (this.isEditMode = v.providerInfo));

    this.subscriptions.actions = this.permissionService
      .subscribeToUserActions((v: BenefitDetailsUserActions) => (this.сanEditFormula = v.сanEditFormula));

    this.subscriptions.state = this.manService
      .subscribeToChangeState((v: boolean) => (this.showFormula = v));

    this.subscriptions.providerTier = this.manService
      .subscribeToSelectProviderTier((v: BenefitDetailsTier) => {
        const { empFormula, erFormula, coverageFormula } = v;
        this.tierIsDraft = v.isDraft;
        this.empFormula = empFormula;
        this.empFormulaText = empFormula.expression || '';
        this.empFixed = _.isFinite(empFormula.fixedAmount) ? empFormula.fixedAmount : null;

        this.emprFormula = erFormula;
        this.emprFormulaText = erFormula.expression || '';
        this.emprFixed = _.isFinite(erFormula.fixedAmount) ? erFormula.fixedAmount : null;

        this.covFormula = coverageFormula;
        this.covFormulaText = coverageFormula.expression || '';
        this.covFixed = _.isFinite(coverageFormula.fixedAmount) ? coverageFormula.fixedAmount : null;
        this.covMultiplier = coverageFormula.multipler || null;
        this.covMaxCap =  _.isFinite(coverageFormula.maxCap) ? coverageFormula.maxCap : null;
        this.setDropdownValues();
      });

    this.subscriptions.selectProviderLine = this.commonManService
    .subscribeToSelectProviderLine((pl: BenefitDetailsProviderLineStandartEntity) => {
      this.hasEnrolledEmployees = _.gt(pl.current.enrollmentCount, 0);
    });

    this.subscriptions.benefitenrolledInfo = this.manService
      .subscribeToBenefitEnrolledEmployeesChanged((hasEnrollEmp: boolean) => {
        this.hasEnrolledEmpTierLevel = hasEnrollEmp;
    });

    this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(() => {
      this.manService.updateCanSaveStateByValidity(this.ngForm.valid);
    });
  }

  public ngOnDestroy(): void {
  }

  public onChangeEmpContributionType(): void {
    if (this.empFormula.calculationType !== this.empContribution.name) {
      this.empFormula.calculationType = this.empContribution.name;
      this.manService.updateTierEmployeeFormula(this.empContribution.name, this.empFixed, this.empFormulaText);
    }
  }

  public onChangeEmpContribution(): void {
    if (_.isString(this.empContribution.name)) {
      this.manService.updateTierEmployeeFormula(this.empContribution.name, this.empFixed, this.empFormulaText);
    }
  }

  public onChangeEmprContributionType(): void {
    if (this.emprFormula.calculationType !== this.emprContribution.name) {
      this.emprFormula.calculationType = this.emprContribution.name;
      this.manService.updateTierEmployerFormula(this.emprContribution.name, this.emprFixed, this.emprFormulaText);
    }
  }

  public onChangeEmprContribution(): void {
    if (_.isString(this.emprContribution.name)) {
      this.manService.updateTierEmployerFormula(this.emprContribution.name, this.emprFixed, this.emprFormulaText);
    }
  }

  public onChangeCoverageType(): void {
    if (this.covFormula.calculationType !== this.coverage.name) {
      this.covFormula.calculationType = this.coverage.name;
      this.manService.updateTierCoverageFormula(
        this.coverage.name,
        this.covFixed,
        this.covFormulaText,
        this.covMultiplier,
        this.covMaxCap
      );
    }
  }

  public onChangeCoverage(): void {
    let { fixedAmount, multipler, maxCap, expression } = this.covFormula;
    expression = expression || '';
    if (
      _.isString(this.coverage.name)
      && (
        fixedAmount !== this.covFixed
        || multipler !== this.covMultiplier
        || maxCap !== this.covMaxCap
        || expression !== this.covFormulaText
      )
    ) {
      this.manService.updateTierCoverageFormula(
        this.coverage.name,
        this.covFixed,
        this.covFormulaText,
        this.covMultiplier,
        this.covMaxCap
      );
    }
  }

  private setDropdownValues(): void {
    this.empContribution = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
    this.emprContribution = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);
    this.coverage = new BenefitDetailsBasic<number, BenefitFormulaCalculationType>(null, null, null);

    let empContr = null;
    let emprContr = null;
    let cov = null;

    _.forEach(this.calculationTypes, ct => {
      if (_.isNil(empContr) && ct.name === this.empFormula.calculationType) {
        this.empContribution = empContr = ct;
      }
      if (_.isNil(emprContr) && ct.name === this.emprFormula.calculationType) {
        this.emprContribution = emprContr = ct;
      }
      if (_.isNil(cov) && ct.name === this.covFormula.calculationType) {
        this.coverage = cov = ct;
      }
    });
  }

  public get isReadOnly(): boolean {
    return !(this.isEditMode && (this.tierIsDraft || !this.hasEnrolledEmpTierLevel));
  }
}
