import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const SLX_REQUIRED_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SlxRequiredValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[slx-required][formControlName],[slx-required][formControl],[slx-required][ngModel]',
  /* tslint:enable */
  providers: [SLX_REQUIRED_VALIDATOR]
})
export class SlxRequiredValidator implements Validator {
  public validate(c: AbstractControl): {[key: string]: any} {
    return CommonValidators.required(c);
  }
}
