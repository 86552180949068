import { Component, Input, OnInit } from '@angular/core';
import { KendoGridStateHelper } from '../../../../../app/common';
import { process } from '@progress/kendo-data-query';
import { AttributesModel, DetailsPartner } from '../../../../scheduler/models';

@Component({
  selector: 'slx-agency-details',
  templateUrl: './agency-details.component.html',
  styleUrls: ['./agency-details.component.scss']
})
export class AgencyDetailsComponent implements OnInit {
  @Input()
  public agencyDetails: DetailsPartner[];

  public gridState: KendoGridStateHelper<DetailsPartner>;
  constructor() {
    this.gridState = new KendoGridStateHelper<any>();
  }

  ngOnInit() {
   this.gridState.view = process(this.agencyDetails, this.gridState.state);
  }
  public getIncentivePayDetails(dataItem): string {
    let incentivePay: string = '';
    dataItem.filter(e => {

      if (e.name == "incentivepayPercent") {
        if (e.attrValues) {
          incentivePay = e.attrValues[0].name;
        }
      }
      else if (e.name == "incentivepayAmount") {
        incentivePay = e.value;
      }
    });

    return incentivePay;
  }

}
