import { Component, Provider } from '@angular/core';
import { DialogOptions, IDialog, ModalService } from './../../../../../../app/common';
import { CopyAccrualResult } from '../../../models/accruals-policy/copy-accruals-policy';

@Component({
  selector: 'slx-copied-policy-result-dialog',
  templateUrl: './copied-policy-result-dialog.component.html',
  styleUrls: ['./copied-policy-result-dialog.component.scss']
})
export class CopiedPolicyResultDialogComponent implements IDialog {

  public modalService: ModalService;
  public dialogResult: boolean;
  public resultData: CopyAccrualResult;

  public static openDialog(request: CopyAccrualResult, modalService: ModalService, callback: (result: boolean) => void): CopiedPolicyResultDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 850;
    dialogOptions.className = 'slx-copy-policy-result-dialog-custom';
    dialogOptions.showCloseButton = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: CopyAccrualResult,
        useValue: request
      }
    ];
    let dialog: CopiedPolicyResultDialogComponent = modalService.globalAnchor
      .openDialog(CopiedPolicyResultDialogComponent, request.header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result);
      });
    return dialog;
  }

  constructor(modalService: ModalService, request: CopyAccrualResult) {
    this.modalService = modalService;
    this.resultData = request;
   }

   discard() {
    this.dialogResult = false;
    this.modalService.closeAllWindows();
  }

}
