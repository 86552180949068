import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { EmployeeSectionsBase } from '../employee-sections-base';
import { appConfig } from '../../../../app.config';
import * as _ from 'lodash';
var RotationTemplate = /** @class */ (function () {
    function RotationTemplate() {
    }
    return RotationTemplate;
}());
export { RotationTemplate };
var RotationTemplateRecord = /** @class */ (function () {
    function RotationTemplateRecord() {
    }
    return RotationTemplateRecord;
}());
export { RotationTemplateRecord };
var EmployeeRotationRecord = /** @class */ (function () {
    function EmployeeRotationRecord(weeklyRotation) {
        this.isValid = true;
        this.employeeShifts = [];
        this.weeklyRotation = weeklyRotation;
    }
    Object.defineProperty(EmployeeRotationRecord.prototype, "nextDayShiftEndTime", {
        get: function () {
            var endDateTime;
            this.employeeShifts.forEach(function (s) {
                if (s && s.shift && s.shift.isNextDayShift && (s.shift.endDate > endDateTime || endDateTime === undefined)) {
                    endDateTime = s.shift.endDate;
                }
            });
            return endDateTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRotationRecord.prototype, "prevDayShiftStartTime", {
        get: function () {
            var startDateTime;
            this.employeeShifts.forEach(function (s) {
                if ((s !== null && s !== undefined) && s.shift.isNextDayShift && (s.shift !== null && s.shift !== undefined) && (s.shift.startDate < startDateTime || startDateTime === undefined)) {
                    startDateTime = s.shift.startDate;
                }
            });
            return startDateTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRotationRecord.prototype, "shiftEnd", {
        get: function () {
            var endDateTime;
            this.employeeShifts.forEach(function (s) {
                if (s && s.shift && (s.shift.endDate > endDateTime || endDateTime === undefined)) {
                    endDateTime = s.shift.endDate;
                }
            });
            return endDateTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRotationRecord.prototype, "shiftStart", {
        get: function () {
            var startDateTime;
            this.employeeShifts.forEach(function (s) {
                if ((s !== null && s !== undefined) && (s.shift !== null && s.shift !== undefined) && (s.shift.startDate < startDateTime || startDateTime === undefined)) {
                    startDateTime = s.shift.startDate;
                }
            });
            return startDateTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRotationRecord.prototype, "shifts", {
        get: function () {
            return this.employeeShifts;
        },
        set: function (shifts) {
            this.employeeShifts = shifts;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeRotationRecord.prototype.setNextShift = function (shift) {
        if (this.employeeShifts.length < 10) {
            this.employeeShifts.push(shift);
        }
    };
    EmployeeRotationRecord.prototype.removeShift = function (shift) {
        _.remove(this.employeeShifts, shift);
    };
    EmployeeRotationRecord.prototype.clearShifts = function () {
        if ((this.employeeShifts !== null && this.employeeShifts !== undefined) && this.employeeShifts.length > 0) {
            while (this.employeeShifts.length > 0) {
                this.employeeShifts.pop();
            }
        }
        // this.discardValidState();
    };
    return EmployeeRotationRecord;
}());
export { EmployeeRotationRecord };
var WeeklyRotation = /** @class */ (function () {
    function WeeklyRotation() {
        this.dailyRecords = [];
    }
    return WeeklyRotation;
}());
export { WeeklyRotation };
var EmployeeRotation = /** @class */ (function () {
    function EmployeeRotation() {
        this.weeklyRotations = [];
    }
    Object.defineProperty(EmployeeRotation.prototype, "firstWeek", {
        get: function () {
            if (this.weeklyRotations) {
                return this.weeklyRotations[0];
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRotation.prototype, "description", {
        get: function () {
            return this.isCurrent ? 'Current week' : moment(this.archivedDate).format(appConfig.dateTimeFormat);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRotation.prototype, "firstDayFirstWeek", {
        get: function () {
            var firstWeek = this.firstWeek;
            if (firstWeek !== null && firstWeek !== undefined) {
                var dayNumbers_1 = [];
                firstWeek.dailyRecords.forEach(function (d) { return dayNumbers_1.push(d.dayNumber); });
                var minDayNumber_1 = Math.min.apply(Math, dayNumbers_1);
                return firstWeek.dailyRecords.find(function (d) { return d.dayNumber === minDayNumber_1; });
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeRotation;
}());
export { EmployeeRotation };
var EmployeeSectionsRotations = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsRotations, _super);
    function EmployeeSectionsRotations() {
        var _this = _super.call(this) || this;
        _this.rotations = [];
        return _this;
    }
    return EmployeeSectionsRotations;
}(EmployeeSectionsBase));
export { EmployeeSectionsRotations };
var ConfigureRotationsRequest = /** @class */ (function () {
    function ConfigureRotationsRequest() {
    }
    return ConfigureRotationsRequest;
}());
export { ConfigureRotationsRequest };
