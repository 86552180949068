import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { IdealScheduleStateService } from '../../../services/index';
import { IdealScheduleConfigTypeDefinition, IdealScheduleConfigCensusDefinition } from '../../../models/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { IdealSchedulePositionRange } from '../../../models/ideal-schedule/ideal-schedule-position-range';
var IdealScheduleGridComponent = /** @class */ (function () {
    function IdealScheduleGridComponent(stateService, changeDetector) {
        this.stateService = stateService;
        this.changeDetector = changeDetector;
        this.IdealScheduleConfigTypeDefinition = IdealScheduleConfigTypeDefinition;
        this.IdealScheduleConfigCensusDefinition = IdealScheduleConfigCensusDefinition;
    }
    IdealScheduleGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateServiceChangedSubscription = this.stateService.changed$.subscribe(function () {
            _this.detectChanges();
        });
    };
    IdealScheduleGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IdealScheduleGridComponent.prototype.detectChanges = function () {
        var _this = this;
        setTimeout(function () {
            _this.changeDetector.markForCheck();
        }, 0);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleGridComponent.prototype, "stateServiceChangedSubscription", void 0);
    return IdealScheduleGridComponent;
}());
export { IdealScheduleGridComponent };
