export enum PaperSizeDefinition {
    a5 = 'A5',
    a4 = 'A4',
    a3 = 'A3',
    a2 = 'A2',
    a1 = 'A1',
    a0 = 'A0',
}

export class PaperSize {
    public name: string;
    public widthMM: number;
    public heightMM: number;
}

export const A0: PaperSize = { name: PaperSizeDefinition.a0.toString(), widthMM: 841, heightMM: 1189 };
export const A1: PaperSize = { name: PaperSizeDefinition.a1.toString(), widthMM: 594, heightMM: 841 };
export const A2: PaperSize = { name: PaperSizeDefinition.a2.toString(), widthMM: 420, heightMM: 594 };
export const A3: PaperSize = { name: PaperSizeDefinition.a3.toString(), widthMM: 297, heightMM: 420 };
export const A4: PaperSize = { name: PaperSizeDefinition.a4.toString(), widthMM: 210, heightMM: 297 };
export const A5: PaperSize = { name: PaperSizeDefinition.a5.toString(), widthMM: 148, heightMM: 210 };

export const PaperSizes: PaperSize[] = [A0, A1, A2, A3, A4, A5];

export const UnscaledGridColumnWidthMM: number = 56; // for 150 px;
