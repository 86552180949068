import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';

@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-time-filter',
  templateUrl: 'kendo-grid-time-filter.component.html',
  styleUrls: ['kendo-grid-time-filter.component.scss'],
})
export class KendoGridTimeFilterComponent extends KendoGridBasicFilterComponent {

  @Input()
  public showOperators: boolean;
  @Input()
  public column: ColumnComponent;
  @Input()
  public filter: CompositeFilterDescriptor;
  @Input()
  public operator: string;
  public value: any;

  constructor(filterService: FilterService, localization: LocalizationService) {
    super(filterService, localization);

    let slxFilters: string[] = _.map(kendoUtils.slxTimeFilters, (filter: kendoUtils.slxFilter) => { return filter.key; });
    let slxFiltersKeysMap: StringMap<string> = _.keyBy(slxFilters, (key: string) => key);
    let dateOperators: any = this.localizeOperators(
      Object.assign(slxFiltersKeysMap, {
        'filterEqOperator': 'eq',
        'filterNotEqOperator': 'neq',
        'filterAfterOrEqualOperator': 'gte',
        'filterAfterOperator': 'gt',
        'filterBeforeOrEqualOperator': 'lte',
        'filterBeforeOperator': 'lt'
      }));
    this.showOperators = true;
    this.operator = 'eq';
    this.defaultOperators = dateOperators(this.localization);
  }

}
