import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { IdealScheduleStateService, IdealScheduleToolbarService, IdealScheduleHelperService, IdealScheduleApiService } from '../../../services/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { LookupService, LookupType } from '../../../../organization/index';
import { IdealScheduleConfigCensusOptions } from '../../../models/ideal-schedule/ideal-schedule-config-census';
import { IdealScheduleConfigTypes, IdealScheduleConfigTypeDefinition } from '../../../models/ideal-schedule/ideal-schedule-config-type';
import { LookupMultiselectModel, ModalService, ConfirmDialogComponent, ConfirmOptions } from '../../../../common/index';
import { ShiftDetails } from '../../../../configuration/models/ideal-schedule/dto';
import { AppSettingsManageService } from '../../../../app-settings/services';
var IdealScheduleGridToolbarComponent = /** @class */ (function () {
    function IdealScheduleGridToolbarComponent(idealScheduleToolbarService, stateService, lookupService, scheduleService, modalService, apiService, appSettingManageService) {
        this.idealScheduleToolbarService = idealScheduleToolbarService;
        this.stateService = stateService;
        this.lookupService = lookupService;
        this.scheduleService = scheduleService;
        this.modalService = modalService;
        this.apiService = apiService;
        this.appSettingManageService = appSettingManageService;
        this.IdealScheduleConfigTypeDefinition = IdealScheduleConfigTypeDefinition;
        this.IdealScheduleConfigTypes = IdealScheduleConfigTypes;
        this.IdealScheduleConfigCensusOptions = IdealScheduleConfigCensusOptions;
        this.lookups = {
            shiftLookup: null,
            unitLookup: null,
            shiftModels: [],
            unitModels: []
        };
        this.selectedShifts = [];
        this.selectedUnits = [];
    }
    IdealScheduleGridToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSettings();
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return _.isNumber(o.id); })
            .subscribe(function (orgLevel) {
            if (!_this.currentOrgLevel || _this.currentOrgLevel.id !== orgLevel.id) {
                _this.currentOrgLevel = orgLevel;
                _this.orgLevelUpdated();
            }
        });
        this.stateService.GetIdealShiftValidationData();
        this.stateServiceChangedSubscription = this.stateService.poolChanged$.subscribe(function () {
            _this.updateLookups();
        });
    };
    IdealScheduleGridToolbarComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.stateService.isDefaultShiftGroupFlagEnabled = appServerConfig.isDefaultShiftEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    IdealScheduleGridToolbarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.currentOrgLevel = null;
    };
    IdealScheduleGridToolbarComponent.prototype.typeOrCensusChanged = function () {
        if (this.stateService.isFixedCensus) {
            this.updateLookups();
        }
        this.stateService.censusChange$.next(true);
        this.stateService.reconfigureIdealSchedule();
    };
    IdealScheduleGridToolbarComponent.prototype.BindShiftGroupFixed = function () {
        var _this = this;
        if (this.selectedShifts.length != 0) {
            _.forEach(this.stateService.shiftGroupDetailss.shifts, function (item) {
                var existingShift = _.find(_this.selectedShifts, function (pos) { return pos.id === item.shiftId; });
                if (!existingShift) {
                    _this.shiftGroupShift = new LookupMultiselectModel();
                    _this.shiftGroupShift.id = item.shiftId;
                    _this.shiftGroupShift.isSelected = true;
                    _this.shiftGroupShift.name = item.name;
                    _this.shiftGroupShift.object = _this.shiftGroupShift.object || {};
                    _this.shiftGroupShift.object.group = _this.shiftGroupShift.object.group || {};
                    _this.shiftGroupShift.object.group.name = item.group;
                    _this.shiftGroupShift.object.group.id = item.groupId;
                    _this.shiftGroupShift.object.name = item.name;
                    _this.shiftGroupShift.object.id = item.shiftId;
                    _this.shiftGroupShift.object.durationHours = item.totalHours;
                    _this.selectedShifts.push(_this.shiftGroupShift);
                }
            });
        }
        else {
            _.forEach(this.stateService.shiftGroupDetailss.shifts, function (item) {
                _this.shiftGroupShift = new LookupMultiselectModel();
                _this.shiftGroupShift.id = item.shiftId;
                _this.shiftGroupShift.isSelected = true;
                _this.shiftGroupShift.name = item.name;
                _this.shiftGroupShift.object = _this.shiftGroupShift.object || {};
                _this.shiftGroupShift.object.group = _this.shiftGroupShift.object.group || {};
                _this.shiftGroupShift.object.group.name = item.group;
                _this.shiftGroupShift.object.group.id = item.groupId;
                _this.shiftGroupShift.object.name = item.name;
                _this.shiftGroupShift.object.id = item.shiftId;
                _this.shiftGroupShift.object.durationHours = item.totalHours;
                _this.selectedShifts.push(_this.shiftGroupShift);
            });
        }
        this.selectedShifts = this.selectedShifts.filter(function (itemA) {
            return _this.stateService.shiftGroupDetailss.shifts.some(function (itemB) { return itemA.id === itemB.shiftId; });
        });
        this.stateService.configureShiftGroupData(this.selectedShifts);
    };
    IdealScheduleGridToolbarComponent.prototype.setShiftGroupStatus = function () {
        if (this.stateService.selectedPositionType.id == 1) {
            this.stateService.isShiftGroupEnabled = true;
        }
        else {
            this.stateService.isShiftGroupEnabled = false;
        }
    };
    IdealScheduleGridToolbarComponent.prototype.typeChanged = function () {
        if (this.stateService.selectedPositionType.name == 'Shift Group') {
            this.stateService.GetIdealShiftValidationData();
        }
        this.stateService.selectedPositionType.id = this.stateService.selectedPositionType.name == 'Shift Group' ? 1 : this.stateService.selectedPositionType.id;
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        options.isShiftGroup = true;
        options.height = 180;
        options.orgLevelId = this.currentOrgLevel.id;
        if (this.stateService.selectedPositionType.id == 1)
            this.stateService.isShiftNavPopupEnabled = this.stateService.shiftGroupDetailss.shiftGroupCount < 3 ? true : false;
        else
            this.stateService.isShiftNavPopupEnabled = false;
        if (this.stateService.isDefaultShiftGroupFlagEnabled && this.stateService.isShiftNavPopupEnabled && this.stateService.selectedPositionType.id == 1) {
            ConfirmDialogComponent.openDialog('Warning', 'The Default Shift must be configured per each shift group before setting up Ideal Schedule per Shift Group.', this.modalService, function (result) {
            }, options);
        }
        else {
            if (this.stateService.selectedPositionType.id == 1) {
                this.BindShiftGroupFixed();
                this.filtersChanged();
            }
        }
        this.setShiftGroupStatus();
    };
    IdealScheduleGridToolbarComponent.prototype.filtersChanged = function () {
        var _this = this;
        if (this.discarding)
            return;
        if (!this.stateService.isFixedCensus) {
            this.stateService.reconfigureIdealSchedule();
            return;
        }
        var deselectedUnits = _.filter(this.stateService.getSelectedUnits(), function (unit) {
            return unit.isSelected && !_.some(_this.selectedUnits, function (selectedUnit) { return selectedUnit.object.id === unit.object.id; });
        });
        var deselectedShifts = _.filter(this.stateService.getSelectedShifts(), function (shift) {
            return shift.isSelected && !_.some(_this.selectedShifts, function (selectedShift) { return selectedShift.object.id === shift.object.id; });
        });
        var hasHours = this.positionHasHours(deselectedUnits, deselectedShifts);
        if (hasHours) {
            this.showDiscardHoursDialog().then(function (apply) {
                _this.discarding = false;
                if (apply) {
                    _this.applyFilters();
                }
                else {
                    _this.restoreFilters();
                }
                _this.stateService.reconfigureIdealSchedule();
            });
        }
        else {
            this.applyFilters();
            this.stateService.reconfigureIdealSchedule();
        }
    };
    IdealScheduleGridToolbarComponent.prototype.applyFilters = function () {
        this.stateService.setSelectedUnits(_.map(this.selectedUnits, function (selectedUnit) {
            selectedUnit.isSelected = true;
            return selectedUnit;
        }));
        this.stateService.setSelectedShifts(_.map(this.selectedShifts, function (selectedShift) {
            selectedShift.isSelected = true;
            return selectedShift;
        }));
    };
    IdealScheduleGridToolbarComponent.prototype.restoreFilters = function () {
        this.selectedUnits = _.map(this.stateService.getSelectedUnits(), function (selectedUnit) {
            selectedUnit.isSelected = true;
            return selectedUnit;
        });
        this.selectedShifts = _.map(this.stateService.getSelectedShifts(), function (selectedShift) {
            selectedShift.isSelected = true;
            return selectedShift;
        });
    };
    IdealScheduleGridToolbarComponent.prototype.showDiscardHoursDialog = function () {
        var _this = this;
        if (this.discarding) {
            return Promise.resolve(false);
        }
        this.discarding = true;
        return new Promise(function (resolve) {
            ConfirmDialogComponent.openDialog('Discard changes', 'The deselected unit/shift has been used in this Ideal Schedule. Are you sure you wish to proceed?', _this.modalService, resolve);
        });
    };
    IdealScheduleGridToolbarComponent.prototype.positionHasHours = function (units, shifts) {
        var hasHours = false;
        if ((!units || !units.length) && (!shifts || !shifts.length)) {
            return false;
        }
        var fixedRange = _.first(this.stateService.selectedPosition.ranges);
        var parLevels = _.values(fixedRange.parLevels);
        if (units.length && this.stateService.selectedPositionType.id === IdealScheduleConfigTypeDefinition.ShiftUnit) {
            var unitFilteredParLevels = _.filter(parLevels, function (parLevel) { return _.some(units, function (unit) { return parLevel.unit.id === unit.object.id; }); });
            hasHours = hasHours || _.some(unitFilteredParLevels, function (parLevel) { return !parLevel.isUnused(); });
        }
        if (shifts.length) {
            var shiftFilteredParLevels = _.filter(parLevels, function (parLevel) { return _.some(shifts, function (shift) { return parLevel.shift.id === shift.object.id; }); });
            hasHours = hasHours || _.some(shiftFilteredParLevels, function (parLevel) { return !parLevel.isUnused(); });
        }
        return hasHours;
    };
    IdealScheduleGridToolbarComponent.prototype.orgLevelUpdated = function () {
        var _this = this;
        var shiftPromise = this.lookupService.getLookup({
            lookupType: LookupType.shiftDefinition,
            orgLevelId: this.currentOrgLevel.id,
            employeeId: undefined
        });
        var unitPromise = this.lookupService.getLookup({
            lookupType: LookupType.locationUnit,
            orgLevelId: this.currentOrgLevel.id,
            employeeId: undefined
        });
        Promise.all([shiftPromise, unitPromise]).then(function (arrays) {
            _this.lookups.shiftLookup = arrays[0];
            _this.lookups.unitLookup = arrays[1];
            _this.updateLookups(true);
        });
    };
    IdealScheduleGridToolbarComponent.prototype.updateLookups = function (updateFilters) {
        if (updateFilters === void 0) { updateFilters = false; }
        if (!this.stateService.selectedPosition || !this.stateService.isFixedCensus) {
            return;
        }
        var fixedRange = _.first(this.stateService.selectedPosition.ranges);
        var array = _.values(fixedRange.parLevels);
        if (this.lookups.shiftLookup) {
            this.lookups.shiftModels = this.scheduleService.filterShiftLookup(array, this.lookups.shiftLookup);
            this.selectedShifts = _.filter(this.lookups.shiftModels, function (shiftModel) { return shiftModel.isSelected; });
        }
        if (this.lookups.unitLookup) {
            this.lookups.unitModels = this.scheduleService.filterUnitLookup(array, this.lookups.unitLookup);
            this.selectedUnits = _.filter(this.lookups.unitModels, function (unitModel) { return unitModel.isSelected; });
        }
        if (updateFilters) {
            this.applyFiltersAfterLookupsUpdating();
        }
    };
    IdealScheduleGridToolbarComponent.prototype.applyFiltersAfterLookupsUpdating = function () {
        if (this.stateService.isFixedCensus) {
            this.applyFilters();
        }
        this.stateService.reconfigureIdealSchedule();
    };
    tslib_1.__decorate([
        mutableSelect(),
        tslib_1.__metadata("design:type", Observable)
    ], IdealScheduleGridToolbarComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleGridToolbarComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleGridToolbarComponent.prototype, "stateServiceChangedSubscription", void 0);
    return IdealScheduleGridToolbarComponent;
}());
export { IdealScheduleGridToolbarComponent };
