import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';

@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-number-filter',
  templateUrl: 'kendo-grid-number-filter.component.html',
  styleUrls: ['kendo-grid-number-filter.component.scss'],
})
export class KendoGridNumberFilterComponent extends KendoGridBasicFilterComponent {

  @Input()
  public showOperators: boolean;
  @Input()
  public column: ColumnComponent;
  @Input()
  public filter: CompositeFilterDescriptor;
  @Input()
  public operator: string;
  @Input()
  public step: number = 1;
  @Input()
  public min: number;
  @Input()
  public max: number;
  @Input()
  public spinners: boolean = true;
  public decimals: number;
  private m_format: string;

  public get format(): string {
    return !kendoUtils.isNullOrEmptyString(this.m_format) ? this.m_format : this.columnFormat;
  }
  public set format(value: string) {
    this.m_format = value;
  }

  public get columnFormat(): string {
    return this.column && !kendoUtils.isNullOrEmptyString(this.column.format) ?
      kendoUtils.extractFormat(this.column.format) : 'n2';
  }

  constructor(filterService: FilterService, localization: LocalizationService) {
    super(filterService, localization);
    this.localization = localization;

    let slxFilters: string[] = _.map(kendoUtils.slxNumericFilters, (filter: kendoUtils.slxFilter) => { return filter.key; });
    let slxFiltersKeysMap: StringMap<string> = _.keyBy(slxFilters, (key: string) => key);
    let numericOperators: any = this.localizeOperators(
      Object.assign(slxFiltersKeysMap, {
        'filterEqOperator': 'eq',
        'filterNotEqOperator': 'neq',
        'filterGteOperator': 'gte',
        'filterGtOperator': 'gt',
        'filterLteOperator': 'lte',
        'filterLtOperator': 'lt',
        'filterIsNullOperator': 'isnull',
        'filterIsNotNullOperator': 'isnotnull'
      }));
    this.showOperators = true;
    this.operator = 'slxFilterNumber1';
    this.defaultOperators = numericOperators(this.localization);
  }
}
