<button type="button"
  tabindex="1"
  [ngClass]="className"
  (keyup)="onKeyup($event)"
  [popper]="popperContent"
  [popperTrigger]="'click'"
  [popperPlacement]="popperPosition"
  [popperDisableStyle]="'true'"
  (popperOnShown)="onShown($event)"
  (popperOnHidden)="onHidden($event)"
>
  <ng-content></ng-content>
</button>
