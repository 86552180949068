import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AcaMeasurementManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../core/decorators';
import { AcaMeasurementInsights, filterAcaMeasurementRecord } from '../../../models';
import * as moment from 'moment';
import * as _ from 'lodash';
var AcaMeasurementInsightsComponent = /** @class */ (function () {
    function AcaMeasurementInsightsComponent(managementService) {
        this.managementService = managementService;
        this.subscriptions = {};
        this.acaFilter = new filterAcaMeasurementRecord();
        this.isInsightVisible = false;
        this.insightHeading = 'ACA Insights';
        this.currentYear = moment().startOf('day').toDate().getFullYear();
        this.buttonNum = 0;
    }
    AcaMeasurementInsightsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onLoaded = this.managementService.subscribeToLoadedInsights(function (insightsContainer) {
            _this.insightsRecord = insightsContainer;
        });
        this.subscriptions.state = this.managementService.subscribeToCurrentYear(function (year) {
            if (_this.currentYear == year) {
                _this.isInsightVisible = true;
                _this.buttonNum = 0;
            }
            else {
                _this.isInsightVisible = false;
            }
        });
    };
    AcaMeasurementInsightsComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    AcaMeasurementInsightsComponent.prototype.filterAcaMeasurementDetails = function (isCurrentMonth, isMP, type, btnNum) {
        if (_.isEqual(this.buttonNum, btnNum)) {
            btnNum = 0;
        }
        this.buttonNum = btnNum;
        this.acaFilter.isClicked = btnNum != 0 ? true : false;
        this.acaFilter.isCurrentMonth = isCurrentMonth;
        this.acaFilter.isMP = isMP;
        this.acaFilter.type = type;
        this.managementService.setInsightsFilter(this.acaFilter);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaMeasurementInsightsComponent.prototype, "subscriptions", void 0);
    return AcaMeasurementInsightsComponent;
}());
export { AcaMeasurementInsightsComponent };
