import { EmployeeSectionsBase } from '../employee-sections-base';
import { FieldData, FieldsMeta } from '../../../../core/models/index';
import { FieldRenderType } from '../../../../core/models/field/field-render-type';

export interface IEmployeeSectionsCustomFields {
  fields: IEmployeeSectionsCustomField[];
  specialConsideration: string;
  specialConsideration2: string;
}
export interface IEmployeeSectionsCustomField {
  id: number;
  name: string;
  displayName: string;
  value: any;
  fieldType: string;
  renderType: string;
  seq: number;
  dataType: string;
  dataTypeLength: any;
  dataTypePrecision: any;
}
export class EmployeeSectionsCustomFields extends EmployeeSectionsBase {
  public fields: EmployeeSectionsCustomField<any>[];
  public specialConsideration: FieldData<string>;
  public specialConsideration2: FieldData<string>;
  public fieldsMeta: FieldsMeta;
  constructor() {
    super();
    this.fields = [];
  }
}
export class EmployeeSectionsCustomField<T> {
  public id: number;
  public name: string;
  public displayName: string;
  public fieldData: FieldData<T>;
  public fieldRender: FieldRenderType;
  public fieldType: string;
  public seq: number;
  public dataType: string;
  public dataTypeLength: any;
  public dataTypePrecision: any;
}

