import { EmployeeSectionsBase } from '../employee-sections-base';
import { DayOfWeek } from '../../../../common/models/index';
import { IAvailabilityDateRange, AvailabilityDateRange } from '../../../../organization/models/index';
import * as _ from 'lodash';

export interface IEmployeeSectionsAvailability {
  availabilityDateRange: IAvailabilityDateRange;
  employeeId: number;
  weeklyAvailability: IEmployeeWeeklyAvailability;
}

export interface IEmployeeWeeklyAvailability {
  sunday: IEmployeeAvailabilityDay;
  monday: IEmployeeAvailabilityDay;
  tuesday: IEmployeeAvailabilityDay;
  wednesday: IEmployeeAvailabilityDay;
  thursday: IEmployeeAvailabilityDay;
  friday: IEmployeeAvailabilityDay;
  saturday: IEmployeeAvailabilityDay;
}

export class EmployeeWeeklyAvailability {
  days: EmployeeAvailabilityDay[];

  public get hours(): EmployeeAvailabilityHour[] {
    return _.reduce(this.days, (result: EmployeeAvailabilityHour[], day: EmployeeAvailabilityDay): EmployeeAvailabilityHour[] => {
      return (result || []).concat(day.hours);
    }, []);
  }

  constructor() {
    this.days = [];
  }
}


export interface IEmployeeAvailabilityDay {
  day: string;
  hours: number[];
}

export class EmployeeAvailabilityDay {
  public day: DayOfWeek;
  public hoursAM: EmployeeAvailabilityHour[];
  public hoursPM: EmployeeAvailabilityHour[];

  public get hours(): EmployeeAvailabilityHour[] {
    return [...this.hoursAM, ...this.hoursPM];
  }

  constructor() {
    this.hoursAM = [];
    this.hoursPM = [];
  }
}

export class EmployeeAvailabilityHour {
  public day: DayOfWeek;
  public hour: number;
  public isAvailable: boolean;
}

export interface IAvailabilityIndexRecord {
  employeeId: number;
  availabilityDateRange: IAvailabilityDateRange;
}

export class EmployeeSectionsAvailability extends EmployeeSectionsBase {
  public availabilityDateRange: AvailabilityDateRange;
  public employeeId: number;
  public weeklyAvailability: EmployeeWeeklyAvailability;

}




