import { Component, Output, Input, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Lookup, LookupType, TimecardPredefinedComment } from '../../../../organization/models/index';
import { ModalService, ChangeManagementService } from '../../../../common/services/index';
import { TimecardAddCommentsDialogComponent } from '../../punches/add-comments-dialog/add-comments-dialog.component';
import { TimeCardModel, TimecardAddCommentsReq } from '../../../models/daily-timecard/index';
import { LookupService } from '../../../../organization/services/index';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-timecard-comments',
  templateUrl: 'comment.component.html',
  styleUrls: ['comment.component.scss']
})

export class CommentComponent {
  public commentValue: string;
  public selectedComment: TimecardPredefinedComment;

  public empId: number;
  public empName: string;
  public empDate: Date;

  public timecardPredefinedComments: Lookup<TimecardPredefinedComment>;
  public isLoading: boolean = false;
  @Input()
  public set model(value: TimeCardModel) {
    if (!value) {
      return;
    }

    this.commentValue = value.comment.split("|").join("\n\n");
    this.empName = value.employee.name;
    this.empId = value.employee.id;
    this.empDate = value.date;
    this.commentValueInitial = value.comment === undefined || value.comment === null ? '' : value.comment;
  }
  @Input()
  public editable: boolean;
  @Output()
  public commentChanged: EventEmitter<string>;

  private changeGroup: string = 'CommentComponent';
  private commentValueInitial: string;
  public addcomment: boolean = false;

  constructor(private changeManagementService: ChangeManagementService,
    private modalService: ModalService,
    private lookupService: LookupService,
    private appSettingService: AppSettingsManageService) {
    this.commentChanged = new EventEmitter();
    this.getSettings();
  }

  public onCommentChanged(): void {
    this.commentChanged.emit(this.commentValue.replace(/\n\n/g, "|"));
    this.notifyChangeManagement();
  }

  public clearComment(): void {
    this.commentValue = '';
    this.commentChanged.emit(this.commentValue);
  }

  public commentSelected(item: TimecardPredefinedComment): void {
    if (item) {
      this.commentValue = item.description;
      this.onCommentChanged();
    }
  }

  private notifyChangeManagement(): void {
    if (this.commentValue !== this.commentValueInitial) {
      this.changeManagementService.changeNotify(this.changeGroup);
    } else {
      this.changeManagementService.clearChanges(this.changeGroup);
    }
  }

  public async addComment(): Promise<any> {
    this.isLoading = true;

    if (!this.isPredefinedCommentsExist) {
      this.timecardPredefinedComments = await this.lookupService.getLookup({ lookupType: LookupType.timecardPredefinedComment });
    }

    const req = new TimecardAddCommentsReq();
    req.employeeId = this.empId;
    req.employeeName = this.empName;
    req.date = this.empDate;
    req.timecardPredefinedComments = this.timecardPredefinedComments;
    req.employeeId = this.empId;
    req.employeeName = this.empName;
    req.date = this.empDate;
    this.isLoading = false;
    const response = await TimecardAddCommentsDialogComponent.openDialog('Add Comments', req, this.modalService,'multiple');
    if (!response.isApply) {
      return;
    }
    else {
      this.commentValue = response.comment;
      this.onCommentChanged();
    }
  }

  private async getSettings(): Promise<void> {
    this.timecardPredefinedComments = await this.lookupService.getLookup({ lookupType: LookupType.timecardPredefinedComment });
    let config: AppServerConfig = await this.appSettingService.getAppServerConfig();
    this.addcomment = config.addTimeCardComments;
  }

  public get isPredefinedCommentsExist() : boolean{
    return this.timecardPredefinedComments && this.timecardPredefinedComments.items && this.timecardPredefinedComments.items.length > 0;
  }
}
