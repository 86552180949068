import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { IFilteredItems, ServerCompositeFilterDescriptor, PagingData, MetadataInfo } from '../../../../../core/models/index';
import { orderBy, filterBy, State, SortDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper, ScrollWatchEvent } from '../../../../../common/models/index';
import { unsubscribe, unsubscribeAll } from '../../../../../core/decorators/index';
import { ExportDataExecutionItem, ExportDataConfigurationInfo, ExportDataExecutionStatus } from '../../../models';
import { ExportDataManagementService } from '../../../services';
import { ExportDataStatus } from '../../../enums/export-data-status';
import { InfoDialogComponent, ModalService } from '../../../../../common';
import { screenUtils } from '../../../../../common/utils';

@Component({
  selector: 'slx-export-data-history',
  templateUrl: './export-data-history.component.html',
  styleUrls: ['./export-data-history.component.scss']
})
export class ExportDataHistoryComponent implements OnInit, OnDestroy {
  @Input() configuration: ExportDataConfigurationInfo;

  public originalActions: ExportDataExecutionItem[];
  public pageSize: number = 50;
  public gridState: KendoGridStateHelper<ExportDataExecutionItem>;
  public appConfig: IApplicationConfig;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public messages: any = {
    downloadNotAvailable: 'Exports completed more than 24 hours can not be downloaded'
  };

  private records: IFilteredItems<ExportDataExecutionItem>;

  public get exportDataStatus() { return ExportDataStatus; }
  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }

  constructor(private manService: ExportDataManagementService,
              private changeDetector: ChangeDetectorRef,
              private modalService: ModalService) {
    this.gridState = new KendoGridStateHelper<ExportDataExecutionItem>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort =  [{ field: 'startedOn', dir: 'desc' }];
    this.gridState.state.group = [];
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.subscriptions.loadedHistory = this.manService.subscribeToLoadedHistory((data: ExportDataExecutionItem[]) => {
      data = _.filter(data, x => x.configurationId === this.configuration.id);

      if (this.configuration.history) {
        data = _.concat(data, this.configuration.history);
      }

      if (data.length === 0) {
        return;
      }

      this.configuration.history = data;
      this.records = {
        items: data,
        skiped: 0,
        taked: data.length,
        count: data.length,
      };

      this.refreshGrid();
    });

    this.subscriptions.loadedStatuses = this.manService.subscribeToLoadedStatuses((data: ExportDataExecutionStatus[]) => {
      if(!this.configuration.history) {
        return;
      }

      data = _.filter(data, x => x.configurationId === this.configuration.id);
      _.each(data, x => {
        const historyItem = _.find(this.configuration.history, y => y.id === x.executionId);
        if (historyItem) {
          historyItem.status = x.status;
          historyItem.reason = x.reason;
          if (x.status === ExportDataStatus.Completed || x.status === ExportDataStatus.Expired) {
            historyItem.completedOn = x.changedOn;
          }

          if (this.configuration.lastExecuted && this.configuration.lastExecuted.id === x.executionId) {
            this.configuration.lastExecuted.status = x.status;
            this.configuration.lastExecuted.reason = x.reason;
          }
        }
      });

      this.refreshGrid();
    });
  }

  public ngOnDestroy(): void {
    this.records = null;
    this.configuration.history = null;
    this.changeDetector.detach();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public filterChange(filter: any): void {
    this.gridState.state.filter = filter;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = { data: null, total: null };
    let filtered: ExportDataExecutionItem[] = filterBy(this.records.items, this.gridState.state.filter);
    this.gridState.view = { data: orderBy(filtered, this.gridState.state.sort), total: this.records.count };
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public downloadIsAvailable(data: ExportDataExecutionItem): boolean {
    return data.status === ExportDataStatus.Completed && data.completedOn > moment().subtract(1, 'days').toDate();
  }

  public downloadFile(data: ExportDataExecutionItem): void {
    this.manService.downloadExportData(data.id);
  }

  public showReasonInfoPopup(data: ExportDataExecutionItem): void {
    if (this.isMobile && data.reason) {
      InfoDialogComponent.OpenDialog('Information', data.reason, this.modalService);
    }
  }

  public showDownloadInfoPopup(data: ExportDataExecutionItem): void {
    if (this.isMobile && !this.downloadIsAvailable(data)) {
      InfoDialogComponent.OpenDialog('Information', this.messages.downloadNotAvailable, this.modalService);
    }
  }
}
