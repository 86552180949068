<kendo-grid
  #kendoGrid
  class="slx-full-height leave-request-grid slx-blue-grid"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [skip]="gridState.state.skip"
  [pageable]="true"
  [pageSize]="pageSize"
  (dataStateChange)="gridState.dataStateChange($event)"
>
  <kendo-grid-column
    media="(max-width: 780px)"
    title="Leave Requests"
    [sortable]="false"
    [filterable]="false"
    width="220"
    class="overflow-visible-cell"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <section class="lr-mobile slx-flex-row">
        <span>
          <a class="lr-mobile__link" [employeeSectionLink]="dataItem.employee.id">
            <img class="lr-mobile__emp-img" [employeeThumbinalSrc]="dataItem.employee.id" alt="Employee Photo" />
          </a>
        </span>
        <span>
          <p class="lr-mobile__emp-name">
            <a [employeeSectionLink]="dataItem.employee.id">{{ dataItem.employee.name }}</a>
          </p>
          <p class="lr-mobile__emp-status slx-flex-row">
            <i class="lr-mobile__status-icon" aria-hidden="true" [ngClass]="getIconClass(dataItem)"></i>
            <span>{{ dataItem.status }}</span>
          </p>
        </span>
        <span class="slx-relative">
          <span
            class="lr-mobile__sett-icon"
            [popper]="settingsContent"
            [popperTrigger]="'click'"
            [popperPlacement]="'bottom-end'"
            [popperDisableStyle]="'true'"
          >
            <i class="fas fa-cog" aria-hidden="true"></i>
          </span>
          <popper-content #settingsContent class="slx-popper">
            <button
              *ngIf="actions.canApprove"
              (click)="onConfirmDialog(dataItem, actionsDefinition.approve)"
              [disabled]="!canApprove(dataItem)"
              class="lr-mobile__menu-item slx-flex-row"
            >
              <i class="fas fa-check-circle" aria-hidden="true"></i>
              <span>Approve</span>
            </button>
            <button
              *ngIf="!actions.canApprove && actions.canSubmit"
              (click)="onConfirmDialog(dataItem, actionsDefinition.submit)"
              [disabled]="!canMakeAction(dataItem)"
              class="lr-mobile__menu-item slx-flex-row"
            >
              <i class="fas fa-share" aria-hidden="true"></i>
              <span>Submit for Review</span>
            </button>
            <button
              *ngIf="actions.canApprove || actions.canSubmit"
              (click)="onConfirmDialog(dataItem, actionsDefinition.deny)"
              [disabled]="!canMakeAction(dataItem)"
              class="lr-mobile__menu-item slx-flex-row"
            >
              <i class="fas fa-times-circle" aria-hidden="true"></i>
              <span>Deny</span>
            </button>
            <ng-container *ngIf="!actions.canApprove && !actions.canSubmit">
              <button class="lr-mobile__menu-item slx-flex-row" disabled>
                <i class="fas fa-check-circle" aria-hidden="true"></i>
                <span>Approve</span>
              </button>
              <button class="lr-mobile__menu-item slx-flex-row" disabled>
                <i class="fas fa-times-circle" aria-hidden="true"></i>
                <span>Deny</span>
              </button>
            </ng-container>
            <button (click)="onForwadTo(dataItem)" class="lr-mobile__menu-item slx-flex-row">
              <i class="fas fa-clock" aria-hidden="true"></i>
              <span>Individual Schedule</span>
            </button>
            <button (click)="onShowAccruals(dataItem)" class="lr-mobile__menu-item slx-flex-row">
              <i class="fas fa-briefcase" aria-hidden="true"></i>
              <span>Accrual balance</span>
            </button>
          </popper-content>
        </span>
        <span class="slx-relative">
          <span
            class="lr-mobile__dtls-icon"
            [popper]="detailsContent"
            [popperTrigger]="'click'"
            [popperPlacement]="'left-start'"
            [popperDisableStyle]="'true'"
          >
            <i class="fas fa-list-alt" aria-hidden="true"></i>
          </span>
          <popper-content #detailsContent class="slx-popper slx-leave-request-popper">
            <div class="lr-mobile__details">
              <header class="lr-mobile__header">
                <p class="lr-mobile__emp-name large">{{ dataItem.employee.name }}</p>
                <p class="lr-mobile__emp-status slx-flex-row">
                  <i class="lr-mobile__status-icon" aria-hidden="true" [ngClass]="getIconClass(dataItem)"></i>
                  <span>{{ dataItem.status }}</span>
                </p>
              </header>
              <section class="lr-mobile__section no-border">
                <label class="lr-mobile__label">Center</label>
                <p class="lr-mobile__text">{{ dataItem.organization.name }}</p>
              </section>
              <section class="lr-mobile__section">
                <label class="lr-mobile__label">Department</label>
                <p class="lr-mobile__text">{{ dataItem.department.name }}</p>
              </section>
              <div class="slx-flex-row">
                <section class="lr-mobile__section slx-half-width">
                  <label class="lr-mobile__label">Position</label>
                  <p class="lr-mobile__text">{{ dataItem.position.name }}</p>
                </section>
                <section class="lr-mobile__section slx-half-width">
                  <label class="lr-mobile__label">Submitted</label>
                  <p class="lr-mobile__text">{{ dataItem.submittedOn | amDateFormat: appConfig.dateFormat }}</p>
                </section>
              </div>
              <div class="slx-flex-row">
                <section class="lr-mobile__section slx-half-width">
                  <label class="lr-mobile__label">Request Start</label>
                  <p class="lr-mobile__text">{{ dataItem.start | amDateFormat: appConfig.dateTimeFormatUS }}</p>
                </section>
                <section class="lr-mobile__section slx-half-width">
                  <label class="lr-mobile__label">Request End</label>
                  <p class="lr-mobile__text">{{ dataItem.end | amDateFormat: appConfig.dateTimeFormatUS }}</p>
                </section>
              </div>
              <div class="slx-flex-row">
                <section class="lr-mobile__section slx-half-width">
                  <label class="lr-mobile__label">Overlap</label>
                  <p class="lr-mobile__text">
                    Appr/Pend: {{ dataItem.overlapsApproved + '/' + dataItem.overlapsPending }}
                  </p>
                </section>
                <section class="lr-mobile__section slx-half-width">
                  <label class="lr-mobile__label">Reason</label>
                  <p class="lr-mobile__text">{{ dataItem.reason }}</p>
                </section>
              </div>
            </div>
          </popper-content>
        </span>
      </section>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="employee.id" [sortable]="false" [filterable]="false">
    <ng-template kendoGridHeaderTemplate>Photo</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a class="leave-request-link" [employeeSectionLink]="dataItem.employee.id">
        <img class="img" [employeeThumbinalSrc]="dataItem.employee.id" alt="Employee Photo" />
      </a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="employee.name">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Employee</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem
      ><a [employeeSectionLink]="dataItem.employee.id">{{ dataItem.employee.name }}</a></ng-template
    >
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="organization.name">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Center</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.organization.name }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="department.name">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Department</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department.name }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="position.name">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position.name }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="submittedOn" filter="date" [format]="dateFormat">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Submitted</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{
      dataItem.submittedOn | amDateFormat: appConfig.dateFormat
    }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="submittedTo">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Submitted To</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.submittedTo }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="start" filter="date" [format]="dateWithTimeFormat">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Request Start</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{
      dataItem.start | amDateFormat: appConfig.dateTimeFormatUS
    }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="end" filter="date" [format]="dateWithTimeFormat">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Request End</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{
      dataItem.end | amDateFormat: appConfig.dateTimeFormatUS
    }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" [sortable]="false" [filterable]="false">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Overlap</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem
      >Appr/Pend: {{ dataItem.overlapsApproved + '/' + dataItem.overlapsPending }}</ng-template
    >
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="reason">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Reason</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.reason }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column media="(min-width: 781px)" field="status">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Status</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.status }}</ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    media="(min-width: 781px)"
    [sortable]="false"
    [filterable]="false"
    class="overflow-visible-cell"
    width="120"
  >
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Actions</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <slx-action-list>
        <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'"
          >Actions</slx-action-button
        >
        <popper-content #popperContent>
          <slx-action-list-item
            *ngIf="actions.canApprove"
            (onClick)="onConfirmDialog(dataItem, actionsDefinition.approve)"
            [disabled]="!canApprove(dataItem)"
          >
            <ng-container [ngSwitch]="true">
              <span *ngSwitchCase="!canApproveLeaveRequest(dataItem) && canMakeAction(dataItem)">
                <span
                  slxTooltip="This request was submitted on a day(s) that is not scheduled or on the employee's rotation. Please add this to the schedule manually"
                  tipPosition="bottom"
                  tipClass="custom-width"
                  >Approve</span
                >
              </span>
              <span *ngSwitchCase="!canApproveMyOwnLeaveRequests(dataItem) && canMakeAction(dataItem)">
                <span
                  slxTooltip="You do not have the rights to approve your own request"
                  tipPosition="bottom"
                  tipClass="custom-width"
                  >Approve</span
                >
              </span>
              <ng-container *ngSwitchDefault>Approve</ng-container>
            </ng-container>
          </slx-action-list-item>
          <slx-action-list-item
            *ngIf="!actions.canApprove && actions.canSubmit"
            (onClick)="onConfirmDialog(dataItem, actionsDefinition.submit)"
            [disabled]="!canMakeAction(dataItem)"
            >Submit for Review</slx-action-list-item
          >
          <slx-action-list-item
            *ngIf="actions.canApprove || actions.canSubmit"
            (onClick)="onConfirmDialog(dataItem, actionsDefinition.deny)"
            [disabled]="!canMakeAction(dataItem)"
            >Deny</slx-action-list-item
          >
          <ng-container *ngIf="!actions.canApprove && !actions.canSubmit">
            <slx-action-list-item [disabled]="true">Approve</slx-action-list-item>
            <slx-action-list-item [disabled]="true">Deny</slx-action-list-item>
          </ng-container>
          <slx-action-list-item (onClick)="onForwadTo(dataItem)">Individual Schedule</slx-action-list-item>
          <slx-action-list-item (onClick)="onShowAccruals(dataItem)">Accrual balance</slx-action-list-item>
        </popper-content>
      </slx-action-list>
    </ng-template>
  </kendo-grid-column>

  <ng-template kendoGridDetailTemplate let-dataItem>
    <slx-leave-request-details
      [orgLevel]="orgLevel"
      [leaveRequest]="dataItem"
      [absencesCodes]="absencesCodes"
      [editMode]="detailsEditMode"
      (detailsUpdated)="onUpdatedDetails()"
      (changedEditState)="onChangedEditState($event)"
    ></slx-leave-request-details>
  </ng-template>

  <kendo-grid-pdf [fileName]="pdfName" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
    <kendo-grid-column title="Employee Name" field="employee.name" width="220"></kendo-grid-column>
    <kendo-grid-column title="Center" field="organization.name" width="150"></kendo-grid-column>
    <kendo-grid-column title="Department" field="department.name" width="150"></kendo-grid-column>
    <kendo-grid-column title="Position" field="position.name" width="200"></kendo-grid-column>
    <kendo-grid-column title="Submitted" field="submittedOn" width="200" format="MM/dd/yyyy"></kendo-grid-column>
    <kendo-grid-column title="Submitted to" field="submittedTo" width="200"></kendo-grid-column>
    <kendo-grid-column title="Request start" field="start" width="200" format="MM/dd/yyyy h:mm a"></kendo-grid-column>
    <kendo-grid-column title="Request End" field="end" width="200" format="MM/dd/yyyy h:mm a"></kendo-grid-column>
    <kendo-grid-column title="Overlap" field="overlapsApproved" width="150"></kendo-grid-column>
    <kendo-grid-column title="Reason Position" field="reason" width="270"></kendo-grid-column>
    <kendo-grid-column title="Status" field="status" width="270"></kendo-grid-column>
  </kendo-grid-pdf>
  
  <kendo-grid-excel [fileName]="xlsxName" [fetchData]="retriveAllPages()">
    <kendo-excelexport-column title="Employee Name" field="employee.name" width="220"></kendo-excelexport-column>
    <kendo-excelexport-column title="Center" field="organization.name" width="150"></kendo-excelexport-column>
    <kendo-excelexport-column title="Department" field="department.name" width="150"></kendo-excelexport-column>
    <kendo-excelexport-column title="Position" field="position.name" width="180"></kendo-excelexport-column>
    <kendo-excelexport-column
      title="Submitted"
      field="submittedOn"
      width="100"
    ></kendo-excelexport-column>
    <kendo-excelexport-column
      title="Submitted to"
      field="submittedTo"
      width="100"
    ></kendo-excelexport-column>
    <kendo-excelexport-column
      title="Request start"
      field="start"
      width="120"
    ></kendo-excelexport-column>
    <kendo-excelexport-column title="Request End" field="end" width="250"></kendo-excelexport-column>
    <kendo-excelexport-column title="Overlap" field="overlapsApproved" width="200"></kendo-excelexport-column>
    <kendo-excelexport-column title="Reason Position" field="reason" width="270"></kendo-excelexport-column>
    <kendo-excelexport-column title="Status" field="status" width="270"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
