import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { subscribeToOrgLevel } from '../../../../../../organization/selectors/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../../../common/services/index';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
import { OrgLevel } from '../../../../../../state-model/models';
import { BenefitLineInfo,BenefitEnrolledEmployee, BenefitDetailsTier } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/index';

@Component({
  selector: 'benefit-option-details-formula-grid',
  templateUrl: 'benefit-option-details-formula-grid.component.html',
  providers: [StateManagementService, ColumnManagementService ]
})
export class BenefitOptionDetailsFormulaGridComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<BenefitDetailsTier>;
  public columnGroup = 'BenefitOptionDetailsOptions';
  public appConfig: IApplicationConfig;
  public data: BenefitDetailsTier[] = [];
  public isLoading: boolean = true;
  public isShowingExpired: boolean = false;
  public employeeContribution: number | string = 0;
  public employerContribution: number | string = 0;
  public coverage: any = 0;

  @Input()
  public tierDetails: BenefitDetailsTier;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  constructor(private manService: BenefitEmployeeManagementService,
    private stateManagement: StateManagementService,
    private columnManagementService: ColumnManagementService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitDetailsTier>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;

    this.appConfig = appConfig;
  }

  public ngOnInit() {
    this.stateManagement.init('BenefitOptionDetailsFormulaGridComponent');
    this.columnManagementService.init('BenefitOptionDetailsFormulaGridComponent');
    this.columnManagementService.initGroup(this.columnGroup, 5);
    this.data = [this.tierDetails];
    this.getEmployeeContribution();
    this.getEmployerContribution();
    this.getCoverage();
    this.refreshGrid();
  }

  public getEmployeeContribution() {
    if(this.tierDetails && this.tierDetails.empFormula) {
      if (this.tierDetails.empFormula.isFixedCalcType) {
        this.employeeContribution = this.tierDetails.empFormula.fixedAmount;
      } else if (this.tierDetails.empFormula.isFormulaCalcType) {
        this.employeeContribution = 0;
      } else {
        this.employeeContribution = 0;
      }
    }
  }

  public getEmployerContribution() {
    if(this.tierDetails && this.tierDetails.erFormula) {
      if (this.tierDetails.erFormula.isFixedCalcType) {
        this.employerContribution = this.tierDetails.erFormula.fixedAmount;
      } else if (this.tierDetails.erFormula.isFormulaCalcType) {
        this.employerContribution = 0;
      } else {
        this.employerContribution = 0;
      }
    }
  }

  public getCoverage() {
    if(this.tierDetails && this.tierDetails.coverageFormula) {
      if (this.tierDetails.coverageFormula.isFixedCalcType) {
        this.coverage = this.tierDetails.coverageFormula.fixedAmount;
      } else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
        this.coverage = 0;
      } else {
        this.coverage = 0;
      }
    }
  }

  public ngOnDestroy(): void {

  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (!this.data) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.data, this.gridState.state);
  }

}
