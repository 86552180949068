import { Component, OnInit, Provider, ViewChild } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { EmployeeToTerminate, EmployeeTerminate } from '../../../../models/index';
import { ChangeTerminationDateComponent } from '../change-termination-date/change-termination-date.component';

@Component({
  moduleId: module.id,
  selector: 'slx-change-termination-date-dialog',
  templateUrl: 'change-termination-date-dialog.component.html',
  styleUrls: ['change-termination-date-dialog.component.scss']
})
export class ChangeTerminationDateDialogComponent implements IDialog {
  public options: DialogOptions;
  public dialogResult: boolean;
  public employee: EmployeeToTerminate;

  public state: {
    isLoading: boolean;
  };

  @ViewChild('changeTerminationDate', {static: true})
  public changeTerminationDate: ChangeTerminationDateComponent;

  private modalService: ModalService;
  private orgLevelId: number;

  public static openDialog(request: EmployeeToTerminate, modalService: ModalService, callback: (result: boolean, req: EmployeeTerminate) => void): ChangeTerminationDateDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = request && request.isNGPUser ? 300 : 200;
    dialogOptions.width = 450;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: EmployeeToTerminate,
        useValue: request
      }
    ];
    let dialog: ChangeTerminationDateDialogComponent = modalService.globalAnchor
      .openDialog(ChangeTerminationDateDialogComponent, 'Change Employee Termination Date', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.requestToTerminate);
      });
    return dialog;
  }

  public get requestToTerminate(): EmployeeTerminate {
    return this.changeTerminationDate.requestToTerminate;
  }

  public get isemployeeTerminationFormValid(): boolean {
    if(this.changeTerminationDate && this.changeTerminationDate.ngForm) {
      return this.changeTerminationDate.ngForm.valid;
    }
    return false;
  }
  constructor(options: DialogOptions,
    modalService: ModalService,
    employee: EmployeeToTerminate,
  ) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
    this.employee = employee;
    this.state = {
      isLoading: false,
    };
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
