import * as moment from 'moment';
import { IEmployeeDefinition, EmployeeDefinition, IShift, Shift, IPosition, Position, IOrganization, Organization, IDepartment, Department } from '../../../organization/models/index';
import { LinePunch } from './line-punch';
import { IDailyPunchesShift, DailyPunchesShift } from './daily-punches-shift';
import { EmployeeDailyPunchesStatus, EmployeeDailyPunchesState } from './employee-daily-punches-status';

export interface IEmployeeDailyPunchesHeader {
  date: string;
  minPunchDate: string;
  maxPunchDate: string;
  employee: IEmployeeDefinition;
  position: IPosition;
  scheduledShifts: IDailyPunchesShift[];
  displayTag: string;
  state: number;  //0x01 - Invalid Punches, 0x02 - Missing Punches, 0x04 - Scheduled Only, 0x08 - No Punches
  workTime: string;
  paidTime: string;
  badgeId: string;
  organization: IOrganization;
  department: IDepartment;
  firstIn: string;
  lastOut: string;
  isOrganizationPayrollLocked: boolean;
  isPayCycleLocked: boolean;
  isTimecardLocked: boolean;
  isTimecardApproved: boolean;
  comment?: string;
  checkLicenseRestriction: number;
  isPaidRestBreakEnabled: boolean;
}

export class EmployeeDailyPunchesHeader {
  public date: Date;
  public minPunchDate: Date;
  public maxPunchDate: Date;
  public position: Position;
  public employee: EmployeeDefinition;
  public scheduledShifts: DailyPunchesShift[];

  public displayTag: string;
  public workTime: moment.Duration;
  public paidTime: moment.Duration;
  public badgeId: string;
  public organization: IOrganization;
  public department: IDepartment;
  public firstIn: Date;
  public lastOut: Date;

  public scheduledShiftsString: string;
  public scheduledUnitsString: string;
  public scheduledAbsencesString: string;
  public scheduledTimesString: string;
  public scheduledStartsString: string;
  public scheduledEndsString: string;
  public scheduledCommentsString: string;
  public scheduledPositionsString: string;

  public uniqueKey: string;
  public status: EmployeeDailyPunchesStatus;
  public state: EmployeeDailyPunchesState;
  public dirty: boolean;

  public isOrganizationPayrollLocked: boolean;
  public isPayCycleLocked: boolean;
  public isTimecardLocked: boolean;
  public isTimecardApproved: boolean;
  public comment?: string;
  public hasLicenseRestriction: boolean;
  public isPaidRestBreakEnabled: boolean;
  public isAfterTermination: boolean;
}
