import { Directive, ElementRef, Renderer, HostListener, Input } from '@angular/core';
import { CustomDomEvents } from '../../models/index';
@Directive({
  selector: '[slxSelectOnFocus]'
})
export class SelectOnFocusDirective {
  private elRef: ElementRef;
  private renderer: Renderer;
  constructor(elRef: ElementRef, renderer: Renderer) {
    this.elRef = elRef;
    this.renderer = renderer;
  }

  @HostListener(CustomDomEvents.focus, ['$event'])
  public onFocus(event: Event): void {
      this.elRef.nativeElement.children[0].select();
  }
}
