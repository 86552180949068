import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { PopperContent } from 'ngx-popper';
var ActionsButtonComponent = /** @class */ (function () {
    function ActionsButtonComponent() {
        this.popperPosition = 'bottom-start';
        this.popperOnShow = new EventEmitter();
        this.popperOnHide = new EventEmitter();
        this.fullyCustomStyle = false;
        this.escCode = 27;
        this.cssClasses = ['slx-actions-button'];
    }
    ActionsButtonComponent.prototype.onKeyup = function (event) {
        if (event.keyCode === this.escCode) {
            this.popperContent.hide();
        }
    };
    ActionsButtonComponent.prototype.onShown = function (popper) {
        this.popperOnShow.emit(popper);
    };
    ActionsButtonComponent.prototype.onHidden = function (popper) {
        this.popperOnHide.emit(popper);
    };
    Object.defineProperty(ActionsButtonComponent.prototype, "className", {
        get: function () {
            var hasClass = _.isString(this.customClassName) && _.size(this.customClassName) > 0;
            var standartClasses = this.fullyCustomStyle ? 'slx-clear-button' : "" + this.cssClasses.join(' ');
            if (hasClass) {
                return standartClasses + " " + this.customClassName;
            }
            return standartClasses;
        },
        enumerable: true,
        configurable: true
    });
    return ActionsButtonComponent;
}());
export { ActionsButtonComponent };
