import {  IColumnSettings } from '../../../core/models/index';

export class LoaRosterState {
  public columns: IColumnSettings[];
  public columnsMap: StringMap<IColumnSettings>;

  public createColumns(): void {
    this.columns = [
      {
        name: 'position',
        description: 'Position',
        displayed: true,
        width: 180,
        filter: 'text'
      },
      {
        name: 'organization',
        description: 'Facility',
        displayed: true,
        width: 180,
        filter: 'text'
      },
      {
        name: 'department',
        description: 'Department',
        displayed: true,
        width: 180,
        filter: 'text'
      },
      {
        name: 'type',
        description: 'Type',
        displayed: true,
        width: 110,
        filter: 'text'
      },
      {
        name: 'estStartDate',
        description: 'Estimated Start Date',
        displayed: true,
        width: 120,
        filter: 'date'
      },
      {
        name: 'estEndDate',
        description: 'Estimated End Date',
        displayed: true,
        width: 120,
        filter: 'date'
      },
      {
        name: 'actStartDate',
        description: 'Actual Start Date',
        displayed: true,
        width: 120,
        filter: 'date'
      },
      {
        name: 'actEndDate',
        description: 'Actual End Date',
        displayed: true,
        width: 120,
        filter: 'date'
      },
      {
        name: 'daysOnLeave',
        description: 'Days On Leave',
        displayed: false,
        width: 100,
        filter: 'numeric'
      },
      {
        name: 'daysRemaining',
        description: 'Days Remaining',
        displayed: true,
        width: 100,
        filter: 'numeric'
      },
      {
        name: 'reason',
        description: 'Reason',
        displayed: true,
        width: 120,
        filter: 'text'
      },
      {
        name: 'notes',
        description: 'Notes',
        displayed: true,
        width: 120,
        filter: 'text'
      },
      {
        name: 'attachments',
        description: 'Attachments',
        displayed: true,
        width: 120,
        filter: null
      },
      {
        name: 'addedBy',
        description: 'Created By',
        displayed: true,
        width: 120,
        filter: 'text'
      },
      {
        name: 'addedDate',
        description: 'Created Date',
        displayed: true,
        width: 120,
        filter: 'date',
      },
      {
        name: 'updatedBy',
        description: 'Last Updated By',
        displayed: true,
        width: 120,
        filter: 'text',
      },
      {
        name: 'updatedDate',
        description: 'Last Updated Date',
        displayed: true,
        width: 120,
        filter: 'date',
      },
    ];

    this.mapColumns();
  }

  public mapColumns(): void {
    this.columnsMap = {};
    this.columns.forEach((col: IColumnSettings) => {
      this.columnsMap[col.name] = col;
    });
  }
}
