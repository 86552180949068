import * as _ from 'lodash';
import { Component, Provider, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { DialogOptions, IDialog } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';

@Component({
  moduleId: module.id,
  selector: 'slx-manage-position-groups-dialog',
  templateUrl: 'manage-position-groups-dialog.component.html',
  styleUrls: ['manage-position-groups-dialog.component.scss']
})
export class ManagePositionGroupsDialogComponent implements IDialog {
  public dialogResult: boolean = false;
  public isLoading: boolean = true;

  private options: DialogOptions;
  private modalService: ModalService;

  public static openDialog(modalService: ModalService, callback?: (result: boolean) => void): ManagePositionGroupsDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 600;
    dialogOptions.height = 500;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.showCloseButton = false;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];

    return modalService.globalAnchor.openDialog(
      ManagePositionGroupsDialogComponent,
      'Manage Groups',
      dialogOptions,
      resolvedProviders,
      callback
    );
  }

  constructor (
    options: DialogOptions,
    modalService: ModalService,
  ) {
    this.options = options;
    this.modalService = modalService;
  }

  public onLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public onHasChanges(hasChanges: boolean): void {
    this.dialogResult = hasChanges;
  }

  public onCancel(): void {
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
