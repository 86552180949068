/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ta-console-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../components-library/components/date-navigator/date-navigator.component.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../components-library/components/date-navigator/date-navigator.component";
import * as i6 from "../../../../common/components/toolbar/toolbar.component.ngfactory";
import * as i7 from "../../../../common/components/toolbar/toolbar.component";
import * as i8 from "../../../../core/services/toolbar/toolbar-base.service";
import * as i9 from "../../../../common/directives/toolbar/toolbar-section-template.directive";
import * as i10 from "./ta-console-toolbar.component";
var styles_TaConsoleToolbarComponent = [i0.styles];
var RenderType_TaConsoleToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaConsoleToolbarComponent, data: {} });
export { RenderType_TaConsoleToolbarComponent as RenderType_TaConsoleToolbarComponent };
function View_TaConsoleToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "slx-toolbar-section slx-no-padd-l date-section"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "slx-border": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "slx-date-navigator", [["className", "slx-mobile-navigator"]], null, [[null, "dateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dateChanged" === en)) {
        var pd_0 = (_co.onFilterDateChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DateNavigatorComponent_0, i3.RenderType_DateNavigatorComponent)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DateNavigatorComponent]), i1.ɵdid(6, 114688, null, 0, i5.DateNavigatorComponent, [i1.ChangeDetectorRef], { format: [0, "format"], maxDate: [1, "maxDate"], className: [2, "className"], selectedDate: [3, "selectedDate"] }, { dateChanged: "dateChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-toolbar-section slx-no-padd-l date-section"; var currVal_1 = _ck(_v, 3, 0, _co.isAllSectionShown(_v.context.counts)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.dateFormat; var currVal_3 = _co.dateNow; var currVal_4 = "slx-mobile-navigator"; var currVal_5 = _co.dateOn; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_TaConsoleToolbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [["class", "slx-toolbar-section slx-no-padd-r issues-section flex-row"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "slx-no-padd-r": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col col-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Open Payroll Issues:"])), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "col col-punch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 8, "button", [["class", "tool-bar-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToPunchMissing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "i", [["aria-hidden", "true"], ["class", "toolbar-button-icon fal fa-2x fa-calendar-times"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(12, { "accent": 0 }), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "items-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Missing Punches"])), (_l()(), i1.ɵeld(17, 0, null, null, 9, "div", [["class", "col col-exception"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 8, "button", [["class", "tool-bar-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToTimeException() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "i", [["aria-hidden", "true"], ["class", "toolbar-button-icon fas fa-2x fa-exclamation-circle"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(21, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(22, { "accent": 0 }), (_l()(), i1.ɵeld(23, 0, null, null, 1, "span", [["class", "items-count"]], null, null, null, null, null)), (_l()(), i1.ɵted(24, null, ["", ""])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Timecard Exception(s)"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-toolbar-section slx-no-padd-r issues-section flex-row"; var currVal_1 = _ck(_v, 3, 0, _co.isVisibleSection(_v.context.sectionType)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "toolbar-button-icon fal fa-2x fa-calendar-times"; var currVal_3 = _ck(_v, 12, 0, (_co.missingPunchesCount > 0)); _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_5 = "toolbar-button-icon fas fa-2x fa-exclamation-circle"; var currVal_6 = _ck(_v, 22, 0, (_co.timecardExceptionCount > 0)); _ck(_v, 21, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.missingPunchesCount; _ck(_v, 14, 0, currVal_4); var currVal_7 = _co.timecardExceptionCount; _ck(_v, 24, 0, currVal_7); }); }
export function View_TaConsoleToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "slx-toolbar", [["class", "ta-console-toolbar"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ToolbarComponent_0, i6.RenderType_ToolbarComponent)), i1.ɵdid(1, 1228800, null, 1, i7.ToolbarComponent, [i1.ElementRef, [2, i8.TOOLBAR_SERVICE], i1.ChangeDetectorRef], { alignExpandButtonRight: [0, "alignExpandButtonRight"] }, null), i1.ɵqud(603979776, 1, { contentChildren: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_TaConsoleToolbarComponent_1)), i1.ɵdid(4, 16384, [[1, 4]], 0, i9.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { width: [0, "width"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_TaConsoleToolbarComponent_2)), i1.ɵdid(6, 16384, [[1, 4]], 0, i9.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { width: [0, "width"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = 200; _ck(_v, 4, 0, currVal_1); var currVal_2 = 600; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_TaConsoleToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-ta-console-toolbar", [], null, null, null, View_TaConsoleToolbarComponent_0, RenderType_TaConsoleToolbarComponent)), i1.ɵdid(1, 114688, null, 0, i10.TaConsoleToolbarComponent, [i8.TOOLBAR_SERVICE], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaConsoleToolbarComponentNgFactory = i1.ɵccf("slx-ta-console-toolbar", i10.TaConsoleToolbarComponent, View_TaConsoleToolbarComponent_Host_0, { dateOn: "dateOn", dateNow: "dateNow", missingPunchesCount: "missingPunchesCount", timecardExceptionCount: "timecardExceptionCount" }, { onDateChanged: "onDateChanged", onNavigateToMissingPunches: "onNavigateToMissingPunches", onNavigateToExceptions: "onNavigateToExceptions" }, []);
export { TaConsoleToolbarComponentNgFactory as TaConsoleToolbarComponentNgFactory };
