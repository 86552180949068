import { isCompositeFilterDescriptor, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { ServerQuery, ServerCompositeFilterDescriptor, ServerFilterDescriptor, isServerCompositeFilterDescriptor } from '../models/index';

export function isPresent(value: any): boolean { return value !== null && value !== undefined; }
export function isBlank(value: any): boolean { return value === null || value === undefined; }
export function isNullOrEmptyString(value: any): boolean { return isBlank(value) || value.trim().length === 0; }
export function isNotNullOrEmptyString(value: any): boolean { return !isNullOrEmptyString(value); }
export function isBlankOperator(operator: any): boolean { return (operator === 'isnull' || operator === 'isnotnull' || operator === 'isempty' || operator === 'isnotempty'); }



export function flatten(filter: any): any {
  if (isPresent(filter.filters)) {
    return filter.filters.reduce(function (acc: any, curr: any): any {
      return acc.concat(isCompositeFilterDescriptor(curr) ? flatten(curr) : [curr]);
    }, []);
  }
  return [];
}

export function adaptNumberFilterPrecision(filter: CompositeFilterDescriptor, precision: number): void {
  const flatFilters = flatten(filter);
  const multiplier = 10 ** precision;
  _.forEach(flatFilters, (f) => {
    if (!isCompositeFilterDescriptor(f) && _.isFinite(f.value) && f.operator === 'eq') {
      f.operator = (value: number, filterValue: number) => {
        return filterValue === Math.round((value + Number.EPSILON) * multiplier) / multiplier;
      };
    }
  });
}

export function flattenFilters(filter: ServerCompositeFilterDescriptor): any[] {
  if (filter.filters) {
    return filter.filters.reduce(function (acc: any, curr: any): any[] {
      return acc.concat(isServerCompositeFilterDescriptor(curr) ? flattenFilters(curr) : [curr]);
    }, []);
  }
  return [];
}

export function extractFormat(format: string): string {
  if (!isNullOrEmptyString(format) && format.startsWith('{0:')) {
    return format.slice(3, format.length - 1);
  }
  return format;
}

export function convertFromKendoFilter(filter: FilterDescriptor): ServerFilterDescriptor {
  let f: ServerFilterDescriptor = new ServerFilterDescriptor();
  f.field = <string>filter.field;
  f.operator = <string>filter.operator;
  f.value = filter.value;
  return f;
}

export function convertFromKendoComposeFilter(filter: CompositeFilterDescriptor): ServerCompositeFilterDescriptor {
  let root: ServerCompositeFilterDescriptor = {
    filters: [],
    logic: filter.logic
  };
  _.forEach(filter.filters, (f: CompositeFilterDescriptor | FilterDescriptor) => {
    if (isCompositeFilterDescriptor(f)) {
      root.filters.push(convertFromKendoComposeFilter(f));
    } else {
      root.filters.push(convertFromKendoFilter(f));
    }
  });
  return root;
}

export function convertFromKendoComposeFilterFlat(filter: CompositeFilterDescriptor): ServerFilterDescriptor[] {
  let filters: ServerFilterDescriptor[] = [];
  let flatFilters: FilterDescriptor[] = flatten(filter);
  _.forEach(flatFilters, (f: FilterDescriptor) => {
    filters.push(convertFromKendoFilter(f));
  });
  return filters;
}

export function composeFromKendoFilter(cfilter: ServerCompositeFilterDescriptor, kf: CompositeFilterDescriptor): void {
  let root: CompositeFilterDescriptor = kf || {
    filters: [],
    logic: 'and'
  };
  let flatFilters: FilterDescriptor[] = flatten(root);
  _.forEach(flatFilters, (f: FilterDescriptor) => {
    composeFieldToFilter(cfilter, <string>f.field, <string>f.operator, f.value);
  });
}

export function composeFieldToFilter(cfilter: ServerCompositeFilterDescriptor, field: string, operator: string, value: any): void {
  if (!field || !operator || !value) {
    return;
  }

  let fl: ServerFilterDescriptor = new ServerFilterDescriptor();
  fl.field = field;
  fl.operator = operator;
  fl.value = value;
  composeFilter(cfilter, fl);
}

export function composeFilter(cfilter: ServerCompositeFilterDescriptor, filter: ServerFilterDescriptor): void {
  cfilter.filters = cfilter.filters || [];
  let flatFilters: ServerFilterDescriptor[] = flattenFilters(cfilter);
  let currentFilter: ServerFilterDescriptor = _.find(flatFilters, (x: ServerFilterDescriptor) => { return x.field === filter.field && x.operator === filter.operator; });
  if (!currentFilter) {
    cfilter.filters.push(filter);
  } else {
    Object.assign(currentFilter, filter);
  }
}

export function composeFieldToKendoFilter(cfilter: CompositeFilterDescriptor, field: string, operator: string, value: any): CompositeFilterDescriptor {
  if (!field || !operator || !value) {
    return;
  }

  let fl: FilterDescriptor = {
    field: field,
    operator: operator,
    value: value
  };
  return composeKendoFilter(cfilter, fl);
}

export function composeKendoFilter(current: CompositeFilterDescriptor, filter: FilterDescriptor): CompositeFilterDescriptor {
  let root: CompositeFilterDescriptor = current || {
    filters: [],
    logic: 'and'
  };
  let flatFilters: FilterDescriptor[] = flatten(root);
  let currentFilter: FilterDescriptor = _.find(flatFilters, (x: FilterDescriptor) => { return x.field === filter.field && x.operator === filter.operator; });
  if (!isPresent(currentFilter)) {
    root.filters.push(filter);
  } else {
    Object.assign(currentFilter, filter);
  }
  return root;
}

export function trimFilterByField(current: CompositeFilterDescriptor, field: string): void {
  if (isPresent(current) && isPresent(current.filters)) {
    current.filters = current.filters.filter((f: CompositeFilterDescriptor | FilterDescriptor) => {
      if (isCompositeFilterDescriptor(f)) {
        trimFilterByField(f, field);
        return f.filters.length;
      } else {
        return f.field !== field;
      }
    });
  }
}

export type slxFilter = { key: string, text: string, needValue: boolean };
export const slxDateFilters: slxFilter[] = [
  { key: 'slxFilterYesterday', text: 'Yesterday', needValue: false },
  { key: 'slxFilterToday', text: 'Today', needValue: false },
  { key: 'slxFilterTomorrow', text: 'Tomorrow', needValue: false },
  { key: 'slxFilterDate', text: 'Selected Date', needValue: true },
  { key: 'slxFilterBetween1Day', text: '+-Day', needValue: true },
  { key: 'slxFilterBetween1Week', text: '+-Week', needValue: true },
  { key: 'slxFilterBetween1Month', text: '+-Month', needValue: true },
  { key: 'slxFilterBetween3Month', text: '+-3Months', needValue: true },
  { key: 'slxFilterBetween1Year', text: '+-Year', needValue: true },
  { key: 'slxFilterLast7Days', text: 'Last 7 Days', needValue: false },
  { key: 'slxFilterLast30Days', text: 'Last 30 Days', needValue: false },
  { key: 'slxFilterLast90Days', text: 'Last 90 Days', needValue: false },
  { key: 'slxFilterLast120Days', text: 'Last 120 Days', needValue: false },
  { key: 'slxFilterNext7Days', text: 'Next 7 Days', needValue: false },
  { key: 'slxFilterNext30Days', text: 'Next 30 Days', needValue: false },
  { key: 'slxFilterNext90Days', text: 'Next 90 Days', needValue: false },
  { key: 'slxFilterNext120Days', text: 'Next 120 Days', needValue: false },
  { key: 'slxFilterAfterDay', text: 'Is After', needValue: false },
  { key: 'slxFilterAfterOrEqualDay', text: 'Is After Or Equal', needValue: false },
  { key: 'slxFilterBeforeDay', text: 'Is Before', needValue: false },
  { key: 'slxFilterBeforeOrEqualDay', text: 'Is Before Or Equal', needValue: false },
];

export const slxTimeFilters: slxFilter[] = [
  { key: 'slxFilterBetween1Hour', text: '+-Hour', needValue: true },
];

export const slxNumericFilters: slxFilter[] = [
  { key: 'slxFilterNumber1', text: 'Equal To +-0.1', needValue: true },
  { key: 'slxFilterNumber2', text: 'Equal To +-0.01', needValue: true },
];

export const slxFiltersMap: StringMap<slxFilter> = _.keyBy(_.union(slxDateFilters, slxNumericFilters, slxTimeFilters), (filter: slxFilter) => { return filter.key; });
