import * as tslib_1 from "tslib";
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common';
import { dataCheck } from '../../../models/wfm-sync';
import { WfmSyncService } from '../../../services/wfm/wfm-sync.service';
import { BaseSyncComponent } from '../wfm-sync-base.component';
var WfmNgpLaborcodeSyncComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WfmNgpLaborcodeSyncComponent, _super);
    function WfmNgpLaborcodeSyncComponent(wfmSyncService, notificationService, busyService) {
        var _this = _super.call(this, notificationService, busyService) || this;
        _this.wfmSyncService = wfmSyncService;
        return _this;
    }
    WfmNgpLaborcodeSyncComponent.prototype.syncDataCore = function (selectedItems) {
        return this.busyService.busy(this.wfmSyncService.LabourCodeSyncOrg(selectedItems, this.syncType));
    };
    WfmNgpLaborcodeSyncComponent.prototype.getSyncItemKey = function (item) {
        return '' + item.id;
    };
    WfmNgpLaborcodeSyncComponent.prototype.fetchData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.GetLabourCodeChangeLogs(this.syncType))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.processData(response.data)];
                }
            });
        });
    };
    WfmNgpLaborcodeSyncComponent.prototype.processData = function (data) {
        return dataCheck(data);
    };
    return WfmNgpLaborcodeSyncComponent;
}(BaseSyncComponent));
export { WfmNgpLaborcodeSyncComponent };
