import { EmployeeSubSection } from './employee-sub-section';
import { StringUtils } from '../../../framework/index';

export class EmployeeSection {
  public id: string;
  public displayName: string;
  public get anchor(): string {
    return StringUtils.format('#{0}', this.id);
  }
  public subsections: EmployeeSubSection[];
}
