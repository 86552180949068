import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody, Meta } from '../../../core/models/index';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { TimeclockAssignmentMapService } from './timeclock-assignment-map.service';
import {
  TimeclockAssignmentContainer, ITimeclockAssignmentContainer,
  TimeclockAssignment, ITimeclockAssignment, ITimeclockUnAssignment
} from '../../models/index';


@Injectable()
export class TimeclockAssignmentApiService {
  constructor(
    private timeclockAssignmentMapService: TimeclockAssignmentMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public assignEmployees(orgLevelId: number, assignment: TimeclockAssignment[]): Promise<any> {
    Assert.isNotNull(assignment, 'assignment');

    const url: string = `${this.getOrgLevelTimeclockAssignmentsApiRoot(orgLevelId)}/assign`;
    const body: any = { employeeTimeclocks: this.timeclockAssignmentMapService.mapTimeclockAssignment(assignment) };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response.data;
      });
    return promise;
  }

  public unassignEmployees(orgLevelId: number, assignment: TimeclockAssignment[]): Promise<any> {
    Assert.isNotNull(assignment, 'assignment');

    const url: string = `${this.getOrgLevelTimeclockAssignmentsApiRoot(orgLevelId)}/unassign`;
    const body: any = this.timeclockAssignmentMapService.mapTimeclockUnAssignment(assignment);

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response.data;
      });
    return promise;
  }

  public getTimeclockAssignments(orgLevelId: number): Promise<TimeclockAssignmentContainer> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = `${this.getOrgLevelTimeclockAssignmentsApiRoot(orgLevelId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<TimeclockAssignmentContainer> = this.apiUtilService.request<ITimeclockAssignmentContainer, Meta>(request)
      .then((response: ResponseBody<ITimeclockAssignmentContainer, Meta>) => {
        return this.timeclockAssignmentMapService.mapTimeclockAssignmentContainer(response.data);
      });
    /*
    let promise: Promise<Budget> = Promise.resolve(this.budgetMapService.mapBudget(Budget.mock));
    */
    return promise;
  }

  private getApiRoot(): string {
    return this.apiUtilService.getApiRoot();
  }

  private getOrgLevelTimeclockAssignmentsApiRoot(orgLevelId: number): string {
    return `${this.getTimeclockAssignmentsApiRoot()}/${timeAndAttendanceConfig.api.orglevel.root}/${orgLevelId}`;
  }
  private getTimeclockAssignmentsApiRoot(): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.timeclockAssignments.root}`;
  }
}
