import { EmployeeSectionsBase } from '../employee-sections-base';
import {
  IEmployeeWarningSectionViolations,
  IEmployeeWarningSectionActions,
  IEmployeeWarningSectionCompanyRemarks,
  IEmployeeWarningSectionEmployeeRemarks,
  EmployeeWarningSectionViolations,
  EmployeeWarningSectionActions,
  EmployeeWarningSectionCompanyRemarks,
  EmployeeWarningSectionEmployeeRemarks,
  IEmployeeWarningSectionViolationsAB,
  EmployeeWarningSectionViolationsAB,
  IEmployeeWarningSectionIncidentDescription,
  EmployeeWarningSectionIncidentDescription
} from './employee-sections-warnings-types';

import { IEmployeeShortInfo, EmployeeShortInfo } from '../../../../organization/index';

export interface IEmployeeWarningHeaderDetail {
  id: number;
  label: string;
  value: string;
  dataType: string;
}

export class EmployeeWarningHeaderDetail {
  constructor(public id: number, public label: string, public value: string, public dataType: string) {}
}

export interface IEmployeeWarningBasic {
  id: number;
  label: string;
  addedBy: string;
  addedAt: string;
}

export class EmployeeWarningBasic {
  public id = -1;
  public warningSubject = '';
  public addedBy: string;
  public addedAt: Date;
}

export interface IEmployeeWarningExtended {
  id: number;
  employee: IEmployeeShortInfo;
  additionalInfo: Array<IEmployeeWarningHeaderDetail>;
  subject: string;
  dateOfWarning: string;
  sections: Array<
    IEmployeeWarningSectionViolations
    | IEmployeeWarningSectionActions
    | IEmployeeWarningSectionCompanyRemarks
    | IEmployeeWarningSectionEmployeeRemarks
    | IEmployeeWarningSectionViolationsAB
    | IEmployeeWarningSectionIncidentDescription
  >;
}

export class EmployeeWarningExtended {
  public id: number;
  public employee: EmployeeShortInfo = null;
  public additionalInfo: Array<EmployeeWarningHeaderDetail> = [];
  public warningSubject: string = null;
  public warningDate: Date = null;
  public sections: Array<
    EmployeeWarningSectionViolations
    | EmployeeWarningSectionActions
    | EmployeeWarningSectionCompanyRemarks
    | EmployeeWarningSectionEmployeeRemarks
    | EmployeeWarningSectionViolationsAB
    | EmployeeWarningSectionIncidentDescription
  > = [];
}

export interface IEmployeeSubsectionWarnings {
  warnings: Array<IEmployeeWarningBasic>;
  actions: Array<string>;
}

export class EmployeeSubsectionWarnings extends EmployeeSectionsBase {
  public warnings: Array<EmployeeWarningBasic> = [];
}
