/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./radio-parameter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components-library/components/radio-button/radio-button.component.ngfactory";
import * as i3 from "../../../../components-library/components/radio-button/radio-button.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "./radio-parameter.component";
import * as i7 from "../../../../organization/services/lookup/lookup.service";
var styles_RadioParameterComponent = [i0.styles];
var RenderType_RadioParameterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RadioParameterComponent, data: {} });
export { RenderType_RadioParameterComponent as RenderType_RadioParameterComponent };
function View_RadioParameterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "options-button-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "slx-radio-button", [["class", "radio-option"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.parameter.value = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RadioButtonComponent_0, i2.RenderType_RadioButtonComponent)), i1.ɵdid(2, 49152, null, 0, i3.RadioButtonComponent, [], { fieldName: [0, "fieldName"], caption: [1, "caption"], option: [2, "option"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.RadioButtonComponent]), i1.ɵdid(4, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); var currVal_8 = _v.context.$implicit.name; var currVal_9 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = i1.ɵinlineInterpolate(1, "option", _v.context.$implicit.name, ""); var currVal_11 = _co.parameter.value; _ck(_v, 4, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_RadioParameterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RadioParameterComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RadioParameterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RadioParameterComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RadioParameterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-radio-parameter", [], null, null, null, View_RadioParameterComponent_0, RenderType_RadioParameterComponent)), i1.ɵdid(1, 114688, null, 0, i6.RadioParameterComponent, [i7.LookupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RadioParameterComponentNgFactory = i1.ɵccf("slx-radio-parameter", i6.RadioParameterComponent, View_RadioParameterComponent_Host_0, { parameter: "parameter" }, {}, []);
export { RadioParameterComponentNgFactory as RadioParameterComponentNgFactory };
