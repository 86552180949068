/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pm-create-review-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../pm-create-review-container/pm-create-review-container.component.ngfactory";
import * as i3 from "../../../services/pm-creation-management.service";
import * as i4 from "../../../services/pm-management.service";
import * as i5 from "../../../services/performance-management-api.service";
import * as i6 from "../../../../../organization/services/lookup/lookup.service";
import * as i7 from "../../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i8 from "../../../../../common/services/file/file.service";
import * as i9 from "../../../../../common/services/change-management/change-management.service";
import * as i10 from "@angular/router";
import * as i11 from "../pm-create-review-container/pm-create-review-container.component";
import * as i12 from "./pm-create-review-popup.component";
import * as i13 from "../../../models/pm-dialog-options";
import * as i14 from "../../../../../common/models/dialog-options";
import * as i15 from "../../../../../common/services/modal/modal.service";
var styles_PmCreateReviewPopupComponent = [i0.styles];
var RenderType_PmCreateReviewPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PmCreateReviewPopupComponent, data: {} });
export { RenderType_PmCreateReviewPopupComponent as RenderType_PmCreateReviewPopupComponent };
export function View_PmCreateReviewPopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { containerElem: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 4, "div", [["class", "modal-window"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "slx-pm-create-review-container", [], null, [[null, "action"], [null, "employeeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.onAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("employeeSelected" === en)) {
        var pd_1 = (_co.onEmployeeSelected() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_PmCreateReviewContainerComponent_0, i2.RenderType_PmCreateReviewContainerComponent)), i1.ɵprd(512, null, i3.PmCreationManagementService, i3.PmCreationManagementService, [i4.PmManagementService, i5.PerformanceManagementApiService, i6.LookupService, i7.NotificationsService, i8.FileService, i9.ChangeManagementService, i10.Router, i10.ActivatedRoute]), i1.ɵdid(5, 245760, null, 0, i11.PmCreateReviewContainerComponent, [i3.PmCreationManagementService, i1.ChangeDetectorRef], { options: [0, "options"] }, { action: "action", employeeSelected: "employeeSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_PmCreateReviewPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-pm-create-review-popup", [], null, null, null, View_PmCreateReviewPopupComponent_0, RenderType_PmCreateReviewPopupComponent)), i1.ɵdid(1, 4243456, null, 0, i12.PmCreateReviewPopupComponent, [i13.PMDialogOptions, i14.DialogOptions, i15.ModalService], null, null)], null, null); }
var PmCreateReviewPopupComponentNgFactory = i1.ɵccf("slx-pm-create-review-popup", i12.PmCreateReviewPopupComponent, View_PmCreateReviewPopupComponent_Host_0, {}, {}, []);
export { PmCreateReviewPopupComponentNgFactory as PmCreateReviewPopupComponentNgFactory };
