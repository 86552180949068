<div class="accruals">
  <div class="modal-content">
    <div class="modal-body">
      <h4 class="employee-name">{{ employeeAccruals.employeeName }}</h4>
      <p class="employee-position">{{ employeeAccruals.positionName }}</p>
      <form novalidate class="row accruals" *ngIf="hasAccruals">
        <div class="col-xs-12 col-sm-12 col-lg-12">
          <div class="row" *ngFor="let accrual of employeeAccruals?.accruals">
            <p class="accrual-name col-xs-6 col-sm-6 col-lg-6">{{ accrual.benefitType.fieldValue }}:</p>
            <p class="accrual-value col-xs-6 col-sm-6 col-lg-6">{{ accrual.accruedHours.fieldValue | slxDecimal24Switch : isShowHighPrecision }}</p>
          </div>
        </div>
      </form>
      <div *ngIf="!hasAccruals">
        <p class="not-found">No entries found...</p>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel">Close</button>
      </div>
  </div>
</div>
