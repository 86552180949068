import * as tslib_1 from "tslib";
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { KendoGridBasicFilterComponent } from '../../../../common/components/kendo-ui-extensions/kendo-grid-basic-filter/kendo-grid-basic-filter.component';
import { KendoGridFilterInputDirective } from '../../../../common/directives/index';
var KendoGridAutocompleteLookupFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridAutocompleteLookupFilterComponent, _super);
    function KendoGridAutocompleteLookupFilterComponent(filterService, localization) {
        var _this = _super.call(this, filterService, localization) || this;
        _this.value = null;
        _this.localization = localization;
        var autocompleteOperators = _this.localizeOperators(Object.assign({}, {
            'filterContainsOperator': 'contains',
            'filterNotContainsOperator': 'doesnotcontain',
            'filterEqOperator': 'eq',
            'filterNotEqOperator': 'neq',
            'filterStartsWithOperator': 'startswith',
            'filterEndsWithOperator': 'endswith',
            'filterIsNullOperator': 'isnull',
            'filterIsNotNullOperator': 'isnotnull',
            'filterIsEmptyOperator': 'isempty',
            'filterIsNotEmptyOperator': 'isnotempty'
        }));
        _this.showOperators = true;
        _this.operator = 'contains';
        _this.defaultOperators = autocompleteOperators(_this.localization);
        return _this;
    }
    KendoGridAutocompleteLookupFilterComponent.prototype.valueChanged = function (value) {
        if (!value) {
            this.input.valueChanged(null);
            return;
        }
        if (value instanceof Object) {
            this.input.valueChanged(value[this.filterField]);
            return;
        }
        this.input.valueChanged(value);
    };
    return KendoGridAutocompleteLookupFilterComponent;
}(KendoGridBasicFilterComponent));
export { KendoGridAutocompleteLookupFilterComponent };
