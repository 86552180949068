import { Component, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { ApplicationDashboardItem } from '../../../organization/models/index';
import { DashBoardAlertsModel, DashboardAppTypeEnum } from '../../../portal/models/index';
import { DashboardAlertsSelectPopupDialogComponent } from '../dashboard-alerts/dashboard-alerts-select-popup-dialog/dashboard-alerts-select-popup-dialog.component';
import { ModalService } from '../../../common/services/modal/modal.service';

@Component({
  moduleId: module.id,
  selector: 'slx-dashboard-item',
  templateUrl: 'dashboard-item.component.html',
  styleUrls: ['dashboard-item.component.scss']
})
export class DashboardItemComponent {
  @Input()
  public dashboard: ApplicationDashboardItem;

  @Input()
  public className: string;

  @Input()
  public progressbar: boolean;

  @Output()
  public navigationClick: EventEmitter<any>;
  
  @Output()
  public onHasChanges = new EventEmitter<boolean>();

  public modelData: DashBoardAlertsModel;

  constructor(public modalService: ModalService) {
    this.navigationClick = new EventEmitter();
    this.modelData = new DashBoardAlertsModel();
  }

  public navigation(): void {
    this.navigationClick.emit();
  }

  public onStartAlerts(): void {
    this.modelData.appId = DashboardAppTypeEnum.lmAlerts;
    this.modelData.appTitle = this.dashboard.title;

    DashboardAlertsSelectPopupDialogComponent.initiateDialog(this.modelData, this.modalService, (hasChanges: boolean) => {
      if (hasChanges) {
        this.emitChanges();
      }
    });
  }

  private emitChanges(): void {
    this.onHasChanges.emit(true);
  }

}
