import {
  Organization,
  Department,
  Position,
  PayPolicy,
  ShiftDiffPolicy,
  BenefitClassDefinition,
  EmpType,
  PayType,
  ExemptStatus,
  LocationUnit
} from '../../../../organization/models/index';

export interface IEmployeeRehire {
  rehireDate: Date;
  employeeType?: string;
  organizationId?: number;
  departmentId?: number;
  positionId: number;
  payType?: string;
  exemptStatus?: string;
  payRate: number;
  payPolicy: string;
  shiftDiffPolicy: string;
  jobCode: number;
  approvalInd: boolean;
  approvalId: number;
  benefitClassId: number;
  benefitClassEffectiveDate: string;
  mobilePhone: string;
  optInRequest: boolean;
  unitId?: number;
  payrollNumber?: string;
  oldOrganizationId?: number;
  oldEmployeeType: string;
}

export class EmployeeRehire {
  public employeeId: number;
  public date: Date;
  public employeeType: EmpType;
  public payRate: number;
  public organization: Organization;
  public department: Department;
  public position: Position;
  public payType: PayType;
  public exemptStatus: ExemptStatus;
  public payPolicy: PayPolicy;
  public shiftDiffPolicy: ShiftDiffPolicy;
  public benefitClass: BenefitClassDefinition;
  public benefitClassEffectiveDate: Date = null;
  public mobilePhone: string;
  public optInRequest: boolean = false;
  public unit: LocationUnit;
  public payrollNumber: string;  
  public oldOrganizationId?: number;
  public oldEmployeeType: string;
}
