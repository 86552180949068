import * as _ from 'lodash';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { IDialog, ModalService, DialogOptions, ISelectableItemContainer } from '../../../../common/index';
import { Assert } from '../../../../framework/index';
import * as moment from 'moment';
import { dateTimeUtils } from '../../../../common/utils/index';
import { AbstractControl, NgForm } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-pay-codes-additional-data.component',
  templateUrl: 'pay-codes-additional-data.component.html',
  styleUrls: ['pay-codes-additional-data.component.scss']
})
export class PayCodesAdditionalDataComponent implements IDialog, OnInit {

  public get initialized(): boolean {
    return this.m_initialized;
  }
  public retroDate: Date;
 
  public options: DialogOptions;
  public dialogResult: boolean;
  public isLoading: boolean;
  private modalService: ModalService;
  private m_initialized: boolean;
  public maxDate:Date;

  constructor(  
     options: DialogOptions, modalService: ModalService) {
    Assert.isNotNull(options, 'options');
    this.options = options;
    Assert.isNotNull(modalService, 'modalService');
    this.modalService = modalService;
    this.dialogResult = false;
    this.isLoading = false;
    this.maxDate = moment().subtract(1, 'day').startOf('day').toDate();
  }

  public ngOnInit(): void {
    this.m_initialized = true;       
  }

  public get selectedRetroDate(): Date {
    return this.retroDate;
  }


  
  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public load(retroDate: Date): void {    
    if(dateTimeUtils.validateDate(retroDate))
      this.retroDate=retroDate;
    else
      this.retroDate=null;
  }

  public onChangeRetroDate(value:Date): void {
    this.retroDate = value;
  }

}
