import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as SlxCommon from '../common/index';
import { CoreModule } from '../core/index';
import { services } from './services/index';
@NgModule({
  imports: [
    CommonModule,
    SlxCommon.CommonModule,
    CoreModule
  ],
  providers: [
    ...services
  ],
  exports: []
})
export class AppSettingsModule {
}
