import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { EmployeeSectionNavigationService } from '../../services/index';
import { WindowRef } from '../../../core/services/window/window-ref.model';

@Directive({
  /* tslint:disable */
  selector: '[employeeSectionNewWindowLink]',
  /* tslint:enable */
})
export class EmployeeSectionLinkNewWindowDirective {
  @Input()
  public employeeSectionNewWindowLink: number;
  @Input()
  public hideOrgLevelBreadcrumb: boolean;
  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute,  private win: WindowRef) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
    const urlTree = navService.getUrlTree(this.employeeSectionNewWindowLink, true);
    const url = `${this.win.nativeWindow.document.location.origin}/#/apps/common${urlTree.toString()}`;
    this.win.nativeWindow.open(url, '_blank');
  }
}
