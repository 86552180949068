import { IOrganization, Organization } from '../../../organization/models/index';
import { StaffingRating } from './staffing-rating';

export interface IStaffingRatingOrganizationDTO {
    organization: IOrganization;
    rating: number;
    rnHoursPoints: number;
    totalHoursPoints: number;
    noCcn: boolean;
    noCensus: boolean;
    noCmsData: boolean;
    noHoursData: boolean;
    rnZeroDays: boolean;
    lastUpdateFromCms: string;
    outdatedData: boolean;
}

export class StaffingRatingOrganization {
    public organization: Organization;
    public rating: StaffingRating;
    public rnHoursPoints: number;
    public totalHoursPoints: number;
    public noCcn: boolean;
    public noCensus: boolean;
    public noCmsData: boolean;
    public noHoursData: boolean;
    public rnZeroDays: boolean;
    public lastUpdateFromCms: Date;
    public outdatedData: boolean;
}

export interface IFiveStarRatingOrganizationDTO {
    staffingRating: number;
    avgDailyCensus: number;
    totalNursingAvgDailyHours: number;
    reportedTotalNursingHRD: number;
    adjustedTotalNurseHRDPoints: number;
    rnAvgDailyHours: number;
    reportedRnHRD: number;
    adjustedRnHRDPoints: number;
    avgDailyCensusWeekEnd: number;
    totalNursingAvgDailyHoursWeekEnd: number;
    reportedTotalNursingHRDWeekEnd: number;
    adjustedTotalNurseOnWeekendHRDPoints: number;
    totalNursingTurnOver: number;
    totalNursingTurnOverPoints: number;
    rnTurnOver: number;
    rnTurnOverPoints: number;
    administratorDepartures: number;
    administratorDeparturesPoints: number;
    fiveStarOrganization: IOrganization;
    noCcn   : boolean;
    noCensus : boolean;
    noCmsData : boolean;
    noHoursData : boolean;
    rnZeroDays : boolean;
    adjustedTotalNurseHRD :number;
    adjustedRnHRD :number;
    adjustedTotalNurseOnWeekendHRD :number;
    noStaffingRatingPastRatedPeriodInfo: boolean;
    noCaseMixTotalNurseHoursInfo: boolean;
    noCaseMixRNHoursInfo: boolean;
    noRnTurnoverInfo: boolean;
    noTotalNurseTurnoverInfo: boolean;
    noAdministratorDeparturesInfo:boolean;
}

export class FiveStarRatingOrganization {
    public rating: StaffingRating;
    public avgDailyCensus: number;
    public totalNursingAvgDailyHours: number; //For cms Rated Period, TotalNursingAvgDailyHours = AvgDailyCensus * TotalNursingHRD
    public totalNursingHRD: number;
    public adjustedTotalNurseHRDPoints: number;
    public rNAvgDailyHours: number; //For cms Rated Period, RNAvgDailyHours = AvgDailyCensus * RnHRD
    public rnHRD: number;
    public adjustedRnHRDPoints: number;
    public avgDailyCensusWeekEnd: number;
    public totalNursingAvgDailyHoursWeekEnd: number;
    public totalNursingHRDWeekEnd: number;
    public adjustedTotalNurseOnWeekendHRDPoints: number;
    public totalNursingTurnOver: number;
    public totalNursingTurnOverPoints: number;
    public rnTurnOver: number;
    public rnTurnOverPoints: number;
    public administratorDepartures: number;
    public administratorDeparturesPoints: number;
    public organization: Organization;
    public noCcn: boolean;
    public noCensus: boolean;
    public noCmsData: boolean;
    public noHoursData: boolean;
    public rnZeroDays: boolean;
    public adjustedTotalNurseHRD:number;
    public adjustedRnHRD:number;
    public adjustedTotalNurseOnWeekendHRD :number;
    public noStaffingRatingPastRatedPeriodInfo: boolean;
    public noCaseMixTotalNurseHoursInfo: boolean;
    public noCaseMixRNHoursInfo: boolean;
    public noRnTurnoverInfo: boolean;
    public noTotalNurseTurnoverInfo: boolean;
    public noAdministratorDeparturesInfo:boolean;
}
