import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { AutocompleteItemDirective } from '../../directives/autocomplete/autocomplete.directive';
var AutocompleteComponent = /** @class */ (function () {
    function AutocompleteComponent() {
        this.rightIcon = 'fas fa-search';
        this.setNullAfterInit = false;
        this.popupSettings = {
            popupClass: 'slx-autocomplete-popup'
        };
        this.virtual = {
            itemHeight: 28
        };
        this.blur = new EventEmitter();
        this.focus = new EventEmitter();
        this.valueChange = new EventEmitter();
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.titleField = 'name';
        this.m_value = null;
        this.strictSearch = false;
        this.suggest = false;
    }
    Object.defineProperty(AutocompleteComponent.prototype, "options", {
        set: function (items) {
            if (_.isArray(items) && _.size(items) > 0) {
                this.sourceListItems = items;
                this.listItems = items.concat();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutocompleteComponent.prototype, "value", {
        get: function () {
            return this.m_value;
        },
        set: function (v) {
            if (!v) {
                if (this.m_value !== null) {
                    this.m_value = null;
                    this.onChangeCallback(this.m_value);
                }
                return;
            }
            if (!_.isEqual(this.m_value, v)) {
                this.m_value = v;
                var item = this.findItemInList(this.m_value);
                this.onChangeCallback(item ? item : null);
            }
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteComponent.prototype.onValueChange = function (value) {
        this.valueChange.emit(value);
    };
    AutocompleteComponent.prototype.onBlur = function (value) {
        this.blur.emit(value);
    };
    AutocompleteComponent.prototype.onFocus = function (value) {
        this.focus.emit(value);
    };
    AutocompleteComponent.prototype.writeValue = function (v) {
        if (!_.isNil(v)) {
            this.value = this.getValue(v);
        }
        else if (this.setNullAfterInit) {
            this.m_value = null;
        }
    };
    AutocompleteComponent.prototype.onFilterItems = function (v) {
        var _this = this;
        this.autocomplete.loading = true;
        var value = this.getValue(v);
        this.listItems = _.filter(this.sourceListItems, function (i) {
            var item = _this.getValue(i);
            if (_this.strictSearch) {
                var separatedWords = item.split(' ');
                return _.some(separatedWords, function (word) { return _.startsWith(word.toLowerCase(), value.toLowerCase()); });
            }
            else {
                return _.includes(item.toLowerCase(), value.toLowerCase());
            }
        });
        this.autocomplete.loading = false;
    };
    Object.defineProperty(AutocompleteComponent.prototype, "hasPlaceholder", {
        get: function () {
            return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
        },
        enumerable: true,
        configurable: true
    });
    AutocompleteComponent.prototype.findItemInList = function (v) {
        var _this = this;
        return _.find(this.sourceListItems, function (i) {
            var value = _this.getValue(i);
            return value === v;
        });
    };
    AutocompleteComponent.prototype.itemDisabled = function (itemArgs) {
        return false;
    };
    AutocompleteComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    AutocompleteComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    AutocompleteComponent.prototype.getValue = function (item) {
        if (_.isString(item)) {
            return item;
        }
        else if (_.isObjectLike(item) && !_.isArray(item)) {
            return item[this.titleField];
        }
        else {
            return null;
        }
    };
    return AutocompleteComponent;
}());
export { AutocompleteComponent };
