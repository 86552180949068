import { ScheduleEntryDefinition, IScheduleEntryDefinition } from '../../../organization/models/index';

export interface IMasterScheduleEmployeeChange {
  employeeId: number;
  entries: IScheduleEntryDefinition[];
}

export class MasterScheduleEmployeeChange {
  public employeeId: number;
  public entries: ScheduleEntryDefinition[];
}
