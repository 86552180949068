import { EmployeeSectionsBase } from '../employee-sections-base';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { EmployeeDocumentCategory } from '../../../../organization/index';
import { FieldsMeta } from '../../../../core/models/index';

export interface IEmployeeSectionsDocument {
  id: number;
  category: string;
  name: string;
  file: File;
  fileName: string;
  addedOn: string;
  addedBy: string;
}

export interface ISaveEmployeeDocumentResponse {
  id: number;
}

export interface IEmployeeSectionsDocuments {
  documents: IEmployeeSectionsDocument[];
}

export class EmployeeDocument {
  public id: number;
  public category: EmployeeDocumentCategory;
  public name: string;
  public file: File;
  public fileName: string;
  public addedOn: Date;
  public addedBy: string;
}

export class EmployeeSectionsDocuments extends EmployeeSectionsBase {
  public documents: EmployeeDocument[];
  public fieldsMeta: FieldsMeta;
}
