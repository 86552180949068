import { Component, OnInit, ViewChild, Provider } from '@angular/core';
import { IDialog, ModalService, DialogOptions } from '../../../../../../common/index';
import { Assert } from '../../../../../../framework/index';
import { AbstractControl, NgForm } from '@angular/forms';
import { EmployeeSectionsAccrualsApiService } from '../../../../services/index';
import {
  EmployeeSectionAccrualTransactions,
  AccrualTypes,
  ResetBalance,
  AccrualTypesDialogOptions,
} from '../../../../models/index';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';
import { AppServerConfig } from './../../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../../../app-settings/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-reset-balance-dialog.component',
  templateUrl: './reset-balance-dialog.component.html',
  styleUrls: ['./reset-balance-dialog.component.scss'],
})

export class ResetBalanceDialogComponent implements IDialog, OnInit {

  public get initialized(): boolean {
    return this.m_initialized;
  }

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form', { static: false })
  public ngForm: NgForm;

  constructor(
    private employeeSectionsAccrualsApiService: EmployeeSectionsAccrualsApiService,
    options: DialogOptions,
    modalService: ModalService,
    private appSettingsManageService: AppSettingsManageService,
    employeeAccruals: AccrualTypesDialogOptions) {
    Assert.isNotNull(options, 'options');
    this.options = options;
    Assert.isNotNull(modalService, 'modalService');
    this.modalService = modalService;
    this.selectedDate = new Date();
    this.dialogResult = false;
    this.accrualTypesList = employeeAccruals.accrual;
    this.employeeId = employeeAccruals.empId;
    this.fromDate = employeeAccruals.fromDate;
    this.endDate = employeeAccruals.toDate;
    this.empHireDate = employeeAccruals.hireDate;
    this.appConfig = appConfig;
  }

  public employeeAccrualTransactions: EmployeeSectionAccrualTransactions;
  public employeeId: number;
  public dialogResult: boolean;
  private m_initialized: boolean;
  private modalService: ModalService;
  public options: DialogOptions;
  public selectedDate: Date;
  public accrualTypesList: AccrualTypes[] = [];
  public chkEnable: boolean = false;
  public tDate: Date
  public accrualTypeInfo = [];
  public notes: string;
  public isLoading: boolean;
  public restBalance: ResetBalance;
  public lastTrasactionDate: Date;
  public fromDate: Date;
  public endDate: Date;
  protected appConfig: IApplicationConfig;
  public empHireDate: Date;
  public decimalLimit: number = 2;
  public format: string = 'n2';

  protected startProgress(): void {
    this.isLoading = true;
  }

  protected stopProgress(): void {
    this.isLoading = false;
  }

  ngOnInit() {
    this.m_initialized = true;
    this.getSettings();
  }

  public static openDialog(
    title: string,
    modalService: ModalService,
    employeeAccruals: AccrualTypesDialogOptions, callback: (result: boolean) => void): ResetBalanceDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 480;
    dialogOptions.width = 350;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: AccrualTypesDialogOptions,
        useValue: employeeAccruals
      }
    ];
    let dialog: ResetBalanceDialogComponent = modalService.globalAnchor
      .openDialog(ResetBalanceDialogComponent, title, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result)
      });
    _.forEach(employeeAccruals.accrual, function (value) {
      dialog.lastTrasactionDate = value.lastManualTransactionDate;
    });
    return dialog;
  }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    if(config.EnableRate4DecimalPlaces){
      this.decimalLimit = 4;
      this.format = 'n4';
    }
  }

  public minDateLimit(): Date {
    if (!_.isNil(this.lastTrasactionDate)) {
      return this.lastTrasactionDate
    }
    return this.empHireDate;
  }

  public onOk(): void {
    this.isLoading = true;
    let restBalances: ResetBalance = new ResetBalance();
    restBalances.employeeId = this.employeeId;
    restBalances.transactionDate = this.selectedDate;
    restBalances.notes = this.notes;
    restBalances.fromDateForFetchResult = this.fromDate;
    restBalances.endDateForFetchResult = this.endDate;
    restBalances.accrualTypeInfo = _.filter(this.accrualTypesList, ['chkEnable', true]);
    this.employeeSectionsAccrualsApiService.postResetBalance(this.employeeId, restBalances)
      .then((status: any) => {
        this.dialogResult = true;
        this.isLoading = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
      }).catch((reason: any) => {
        this.isLoading = false;
      });
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onChangeEnableBalance(accType: AccrualTypes, isChecked: boolean): void {
    accType.chkEnable = isChecked;
    this.saveButtonToggle();
  }

  public saveButtonToggle(): void {
    let saveButtonEnable: boolean = false;
    _.forEach(this.accrualTypesList, function (item) {
      if (item.chkEnable) {
        saveButtonEnable = true;
      }
    });
    if (saveButtonEnable && this.selectedDate) {
      this.chkEnable = true;
    }
    else {
      this.chkEnable = false;
    }
  }

  public getAccrualTypes(tDate: Date): void {
    this.startProgress();
    this.updateLookups(tDate);
    this.saveButtonToggle();
  }

  public updateLookups(transactionDate: Date): void {
    if (this.employeeId) {
      this.employeeSectionsAccrualsApiService.getAccrualTypes(this.employeeId, transactionDate)
        .then((accrualtype: AccrualTypes[]) => {
          this.accrualTypesList = accrualtype;
          this.chkEnable = false;
          this.stopProgress();
        });
    }
  }
}
