import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';

import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';

import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { WcCreateOshaFormService, WcCommonManagementService, WcRosterToolbarService } from '../../../../services/index';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';
import { DeviceDetectorService, ModalService } from '../../../../../../common/services/index';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { OrgLevel } from '../../../../../../state-model/models/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { WcIncidentRosterEntry, WcCreateOshaRosterState, WcIncidentRosterContainer, GenerateOshaFormAction, IGenerateOshaFormAction, ICreateOsha300AFormDialogOptions, CreateOsha300AFormDialogOptions } from '../../../../models/index';
import { WcCreateOshaFormExcelDialogComponent } from './wc-create-osha-form-excel-dialog/wc-create-osha-form-excel-dialog.component';
import { DateRange, IDateRange } from '../../../../../..//core/models/index';
import * as moment from 'moment';
import { WcCreateEstablishmentSizeDialogComponent } from './wc-create-establishment-size-dialog/wc-create-establishment-size-dialog.component';

@Component({
  selector: 'slx-wc-create-osha-grid',
  templateUrl: 'wc-create-osha-grid.component.html',
  styleUrls: ['wc-create-osha-grid.component.scss']
})
export class WcCreateOshaGridComponent implements OnInit, OnDestroy {
  public records: WcIncidentRosterEntry[];
  public storedRecords: WcIncidentRosterEntry[];
  public gridState: KendoGridStateHelper<WcIncidentRosterEntry>;
  public appConfig: IApplicationConfig;
  public orgLevel: number;
  public orgLevelRelatedId: number;
  public state: WcCreateOshaRosterState;
  public pageSize: number = 50;
  public canEdit: boolean;
  public products: any[];
  public isAllSelected: boolean = true;
  public selectedRecords: any[] = [];
  public generateOshaFormAction: IGenerateOshaFormAction;
  private dateRange: DateRange;
  public establishmentSize: number;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  constructor(private oshaService: WcCreateOshaFormService, private modalService: ModalService) {
    this.gridState = new KendoGridStateHelper<WcIncidentRosterEntry>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'organization.name', dir: 'asc' },
      { field: 'employee.name', dir: 'asc' }
    ];
    this.gridState.gridComponentKey = 'WCOshaGrid';
    this.gridState.gridControlStateKey = 'GridFiltersState';
    this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;

    this.appConfig = appConfig;

  }

  public ngOnInit() {
    this.generateOshaFormAction = new GenerateOshaFormAction();
    this.subscriptions.onLoaded = this.oshaService
      .subscribeToLoadedRecords((r: WcIncidentRosterContainer) => {
        this.canEdit = r.canEdit;
        this.assignRecords(r.records);
      });

    this.subscriptions.state = this.oshaService
      .subscribeToChangeState((s: WcCreateOshaRosterState) => this.applyState(s));

    this.subscriptions.export = this.oshaService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());

    this.subscriptions.oshaForm = this.oshaService
      .subscribeToGenerateOSHAForm300A((val: string) => this.generateOshaForm(val));


    this.subscriptions.dateRange = this.oshaService
      .subscribeToDateRange((val: IDateRange) => this.assignDateRange(val));

    this.subscriptions.orgLevel = this.oshaService
      .subscribeToOrgLevel((o: OrgLevel) => this.assignOrgLevel(o));

    this.subscriptions.state = this.oshaService
      .subscribeToInitDateRange((r: IDateRange) => this.assignInitDateRange(r));

    this.subscriptions.establishmentSize = this.oshaService
      .subscribeToestablishmentSize((s: ICreateOsha300AFormDialogOptions) => this.getEsSize(s));
  }

  public ngOnDestroy(): void {
    this.oshaService.destroy();
  }

  public onGridStateChanged(state: State): void {
    if (_.isObjectLike(state)) {
      this.gridState.updateStateWithDelay(state);
    }
  }

  public isVisible(field: string): boolean {
    return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].displayed : false;
  }

  public retriveAllPages(): () => ExcelExportData {
    return (): ExcelExportData => (
      {
        data: process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
      }
    ) as ExcelExportData;
  }

  private assignRecords(records: WcIncidentRosterEntry[]): void {
    const filteredOshaRecords = _.filter(records, r => r && r.isOSHARecordable);
    this.records = filteredOshaRecords;
    this.onToggleAllSelected();
    this.storedRecords = _.clone(records);
    this.refreshGrid();
  }

  private applyState(s: WcCreateOshaRosterState): void {
    this.state = s;
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.records, this.gridState.state);
  }

  public onToggleAllSelected(): void {
    _.forEach(this.records, (record: any) => {
      record.isSelected = this.isAllSelected;
    });
    this.selectionChange();

  }

  public selectionChange(): void {
    this.selectedRecords = _.filter(this.records, (record: any) => record.isSelected);

    this.isAllSelected = this.records && this.selectedRecords ? this.records.length === this.selectedRecords.length : false;

  }

  public onOpenReport(entry: WcIncidentRosterEntry): void {
    this.oshaService.openEditReportDialog(entry.id);
  }

  public assignInitDateRange(r: IDateRange) {
    if (_.isDate(r.startDate) && _.isDate(r.endDate)) {
      this.dateRange = r;
    }
  }

  public assignOrgLevel(o: OrgLevel) {
    if (o) {
      this.orgLevel = o.id;
      this.orgLevelRelatedId = o.relatedItemId;
    } else {
      this.orgLevel = null;
    }
  }

  public getSelectedRecords() {
    if (this.selectedRecords.length > 0) {
      return this.selectedRecords;
    } else {
      return this.records;
    }
  }

  private assignDateRange(r: IDateRange): void {
    if (
      _.isDate(r.startDate) &&
      _.isDate(r.endDate)
    ) {
      this.dateRange = r;
    }
  }

  public generateOshaForm(val: string) {
    if (val === this.generateOshaFormAction.generateOSHAForm300ACSV) {
      this.excelOsha300();
    } else if (val === this.generateOshaFormAction.generateOSHAForm300APDF) {
      this.exportToPDFOsha300();
    } else if (val === this.generateOshaFormAction.generateOSHAForm301APDF) {
      this.exportToPDFOsha301PDF()
    }
  };

  public getEsSize(s: ICreateOsha300AFormDialogOptions) {
    this.establishmentSize = s.establishmentSize;
  }

  public excelOsha300() {
    const items = this.getSelectedRecords() || [];
    WcCreateOshaFormExcelDialogComponent.openDialog(this.modalService, (result: boolean) => {
      if (result) {
        if (this.establishmentSize == null) {
          var componentEstSizeDialog = WcCreateEstablishmentSizeDialogComponent.openDialog(this.modalService, (result: boolean) => {
            if (result) {
              this.oshaService.updateEstablismentSize(this.orgLevelRelatedId, componentEstSizeDialog.size).then(() => {
                this.callToGenerateOshaExcel300(items);
              });
            }
          });
        } else {
          this.callToGenerateOshaExcel300(items);
        }
      }
    });
  }


  public exportToPDFOsha300() {
    const items = this.getSelectedRecords()
    this.oshaService.generateOshaPDF300(this.orgLevel, this.dateRange.startDate, this.dateRange.endDate, items);
  }

  public exportToPDFOsha301PDF() {
    const items = this.getSelectedRecords()
    this.oshaService.generateOshaPDF301(this.orgLevel, items);
  }

  public callToGenerateOshaExcel300(items) {
    this.oshaService.generateOshaExcel300(this.orgLevel, this.dateRange.startDate.getFullYear(), items);
  }
}
