import * as _ from 'lodash';
import { Component, OnDestroy, OnInit, Provider } from '@angular/core';
import { IDialog, ModalService, DialogOptions, DialogOptions2, DialogModeSize, ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common';
import { NewEnrollmentModel, BenefitTierDefinition, BenefitEmpEnrollment401k } from '../../../../models';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { LookupService } from '../../../../../../organization/services';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'slx-enroll-employee-401k',
  templateUrl: './enroll-employee-401k.component.html',
  styleUrls: ['./enroll-employee-401k.component.scss']
})
export class EnrollEmployee401kComponent implements OnInit, OnDestroy, IDialog {

  public appConfig: IApplicationConfig;
  public minAmt = 0;
  public maxAmt: number = 999999999.99;
  public stepcurrency = 0.01;
  public stepPercent = 0.01;
  public dialogResult: boolean;

  public isLoading: boolean;

  public groupName: string;
  public effectiveDate: Date;

  public tiers: BenefitTierDefinition[];
  public selectedTier: BenefitTierDefinition;

  public settings401K: BenefitEmpEnrollment401k;
  public payrollDedStartDate: Date;
  public payrollDedEndDate: Date;
  public dedStartDate: Date;
  public dedEndDate: Date;
  public endDate: Date;
  public startDate: Date;
  public canMapPayroll: boolean;
  public hasDedError: boolean = false;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};


  public static openDialog(
    model: NewEnrollmentModel,
    apiService: EmployeeSectionsBenefitsManagementApiService,
    modalService: ModalService,
    lookupService: LookupService,
    callback?: (result: boolean) => void
  ): EnrollEmployee401kComponent {


    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.width = 1050;
    dialogOptions.height = 400;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: LookupService,
        useValue: lookupService
      },
      {
        provide: ModalService,
        useValue: modalService
      },
      {
        provide: NewEnrollmentModel,
        useValue: model
      },
      {
        provide: EmployeeSectionsBenefitsManagementApiService,
        useValue: apiService
      }
    ];

    const title = `Enroll Employee in Benefits`;
    let component = modalService.globalAnchor.openDialog2(
      EnrollEmployee401kComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );

    return component;

  }

  public get hasSelectedValidTier401K(): boolean {
    this.maxAmt = ((this.maxAmt) && this.settings401K.limitAmount > 0) ? this.settings401K.limitAmount : this.maxAmt;
    return this.hasSelected &&
      this.has401kOption
  }

  public get hasSelected(): boolean {
    return !_.isNil(this.selectedTier);
  }
  
  public get has401kOption(): boolean {
    return !_.isNil(this.settings401K) &&
      _.gt(this.settings401K.id, 0)
  }

  constructor(private model: NewEnrollmentModel, private apiService: EmployeeSectionsBenefitsManagementApiService, private options: DialogOptions, private modalService: ModalService, private lookupService: LookupService, private man: BenefitsEnrollmentSectionManagementService) {
    this.appConfig = appConfig;
    this.settings401K = new BenefitEmpEnrollment401k();
  }

  public ngOnInit(): void {
    this.isLoading = true;
    this.groupName = this.model.plan.name;
    this.effectiveDate = this.model.effectiveDate;
    this.payrollDedStartDate = this.model.effectiveDate;
    this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
    this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
    this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
    this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
    this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate : this.model.plan.dedEndDate;
    this.endDate = this.model.plan.endDate;
    this.startDate = _.isNull(this.model.plan.startDate) ? this.model.effectiveDate : this.model.plan.startDate;
    this.canMapPayroll = this.model.canMapPayroll;
    this.apiService.getBenefitTiers(this.model.plan.benefitLineId)
      .then((tiers: BenefitTierDefinition[]) => {
        this.tiers = tiers;
        this.isLoading = false;
      });

    this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate((hasDedError: boolean) => {
      this.hasDedError = hasDedError;
    });
  }

  ngOnDestroy(): void {

  }

  public onChangeTier(tier: BenefitTierDefinition): void {
    this.selectedTier = tier;
    this.isLoading = true;
    this.apiService.getBenefitPlan401k(tier.id)
      .then((settings: BenefitEmpEnrollment401k) => {
        if (!settings.maxEmployeePercentContribution) {
          settings.maxEmployeePercentContribution = 100;
        }
        this.settings401K = settings;
        this.isLoading = false;
      });
  }

  public onEnroll(): void {
    this.model.settings401k = this.settings401K;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Discard Changes',
      'Are you sure you want to cancel? You will lose all unsaved selections.',
      this.modalService,
      (isCancel: boolean) => {
        if (isCancel) {
          this.dialogResult = false;
          this.modalService.closeWindow(this.options.windowUniqueId);
        }
      },
      options);
  }

  public get401kToolTip(): string {
    return 'Employee cannot be enrolled as amounts have not been created under coverage options for this benefit plan. Please create coverage options for this plan to enroll';
  }

  public OnDeductionEndDateChange(date: Date) {
    this.model.plan.payrollDeductionEndDate = date;
  }
  public OnDeductionStartDateChange(date: Date) {
    this.model.plan.payrollDeductionStartDate = date;
  }

}
