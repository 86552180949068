<div class="slx-form-group slx-readonly" ><label class="slx-label">{{ placeholder }}</label></div>
<input *ngIf="readonly" type="text" readonly [attr.class]="className" [ngModel]="internalValue">
<kendo-combobox *ngIf="!readonly"
  [data]="dataItems"
  [value]="internalValue"
  [placeholder]="placeholder"
  class="slx-autocomplete__autocomplete slx-input-group"
  (valueChange)="change($event)"
  [tabIndex]="tabindex"
  [popupSettings]="popupSettings"
  >
</kendo-combobox>
