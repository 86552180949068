import { IBenefitDetailsBasic, BenefitDetailsBasic } from './benefit-details-basic';
import { IBenefitDetailsGroup, BenefitDetailsGroup } from './benefit-details-group';

export interface IBenefitDetailsConfig {
  groups: IBenefitDetailsGroup[];
  acaHarborCodes: IBenefitDetailsBasic<number, string>[];
  freqCodes: IBenefitDetailsBasic<number, string>[];
  planForCodes: IBenefitDetailsBasic<number, string>[];
  benefitTypes: IBenefitDetailsBasic<number, string>[];
  canAdd: boolean;
  canEdit: boolean;
  canDelete: boolean;
  canEditFormulas: boolean;
  canRenew: boolean;
  canExpire: boolean;
  canExtend: boolean;
  canEnroll: boolean;
  canMapPayroll: boolean;
}

export class BenefitDetailsConfig {
  public groups: BenefitDetailsGroup[] = [];
  public acaHarborCodes: BenefitDetailsBasic<number, string>[] = [];
  public freqCodes: BenefitDetailsBasic<number, string>[] = [];
  public planForCodes: BenefitDetailsBasic<number, string>[] = [];
  public benefitTypes: BenefitDetailsBasic<number, string>[] = [];
  public canAdd: boolean;
  public canEdit: boolean;
  public canDelete: boolean;
  public canEditFormula: boolean;
  public canRenew: boolean;
  public canExpire: boolean;
  public canExtend: boolean;
  public canEnroll: boolean;
  public canMapPayroll: boolean;
}
