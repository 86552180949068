
import * as _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { LoaRequestCounters, AlertConfig } from '../../../models/index';
import { DashboardLMAlertEnum, DashboardAppTypeEnum } from '../../../../portal/models/index';
import { ApplicationDashboardItem } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { ApplicationDashboardApiService, ApplicationDashboardManagementService } from '../../../services/index';
import { LmNavigationService } from '../../../../common/services/navigation/index';


@Component({
  moduleId: module.id,
  selector: 'slx-leave-management-dashboard',
  templateUrl: 'leave-management-dashboard.component.html',
})
export class LeaveManagementDashboardComponent {
  @Input()
  public dashboard: ApplicationDashboardItem;
  public counters: LoaRequestCounters;
  public progressbar: boolean;

  @Input()
  public set orgLevel(value: OrgLevel) {
    if (!value || !value.id) return;
    this.loadCounters(value.id);
  }

  private navigationService: LmNavigationService;
  public alertConfigOrderAndVisibilty: AlertConfig[];
  constructor(private applicationDashboardApiService: ApplicationDashboardApiService,
    private appDashboardMngtService: ApplicationDashboardManagementService,
    router: Router, activatedRoute: ActivatedRoute) {
    this.navigationService = new LmNavigationService(router, activatedRoute);
  }
  
  public loadAlerts(): void {
    this.appDashboardMngtService.getDashboardAppAlerts(DashboardAppTypeEnum.lmAlerts)
    .then(data => {
      this.alertConfigOrderAndVisibilty = _.filter(data, (t) => t.visible);
      this.setCounter();
    });
  }

  public onHasChanges(hasChanges: boolean): void {
    if (hasChanges) {
      this.loadAlerts();
    }
  }

  public loadCounters(orglevelId: number): void {
    this.progressbar = true;
    this.applicationDashboardApiService.getLoaRequestCounters(orglevelId)
      .then((res: LoaRequestCounters) => {
        this.counters = res;
        this.loadAlerts();
      }).finally(() => {
        this.progressbar = false;
      });
  }

  public navigate(): void {
    this.navigationService.navigateToConsole();
  }

  public setCounter() {
    let appAlerts = this.alertConfigOrderAndVisibilty;
    _.forEach(appAlerts, (record: AlertConfig) => {
      if (record.alertId == DashboardLMAlertEnum.onLeave) {
        record.counter = this.counters ? this.counters.onLeaveCount : 0;
      }
      else if (record.alertId == DashboardLMAlertEnum.returning) {
        record.counter = this.counters ? this.counters.returningCount : 0;
      }
      else if (record.alertId == DashboardLMAlertEnum.upcoming) {
        record.counter = this.counters ? this.counters.upcomingCount : 0;
      }
      else if (record.alertId == DashboardLMAlertEnum.incomplete) {
        record.counter = this.counters ? this.counters.incompleteCount : 0;
      }
      else if (record.alertId == DashboardLMAlertEnum.pastDue) {
        record.counter = this.counters ? this.counters.pastDueCount : 0;
      }
    });
  }
}
