import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { appConfig } from '../../../app.config';

export class TimecardsNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public NavigateToIndividualTimecards(employeeId: number, startDate: Date, endDate: Date, hideOrgLevelBreadcrumb?: boolean): void {
    if (startDate && endDate) {
      this.router.navigate(
        ['timecards', 'employee', employeeId],
        {
          relativeTo: this.route.pathFromRoot[2],
          queryParams: {
            employeeId: employeeId,
            startDate: moment(startDate).format(appConfig.linkDateFormat),
            endDate: moment(endDate).format(appConfig.linkDateFormat),
            keepOrgLevelBreadcrmb: _.isBoolean(hideOrgLevelBreadcrumb) ? hideOrgLevelBreadcrumb : true
          }
        }
      );
      return;
    }
    if (!startDate && !endDate) {
      this.router.navigate(['timecards', 'employee', employeeId], { relativeTo: this.route.pathFromRoot[2] });
      return;
    }
    throw Error('incorrect routing');
  }

  public NavigateToDailyTimecards(employeeId: number, date: Date, hideOrgLevelBreadcrumb?: boolean): void {
    if (!employeeId || !date) {
      throw Error('incorrect routing');
    }
    this.router.navigate(
      ['timecards', 'employee', employeeId, moment(date).format(appConfig.linkDateFormat)],
      {
        relativeTo: this.route.pathFromRoot[2],
        queryParams: {
          keepOrgLevelBreadcrmb: _.isBoolean(hideOrgLevelBreadcrumb) ? hideOrgLevelBreadcrumb : true
        }
      }
    );
  }

  public NavigateToTimecards(startDate: Date, endDate: Date): void {
    if (startDate && endDate) {
      this.router.navigate(['timecards'],
        {
          relativeTo: this.route.pathFromRoot[2],
          queryParams: { startDate: moment(startDate).format(appConfig.linkDateFormat), endDate: moment(endDate).format(appConfig.linkDateFormat) }
        });
      return;
    }
    if (!startDate && !endDate) {
      this.router.navigate(['timecards'], { relativeTo: this.route.pathFromRoot[2] });
      return;
    }
    throw Error('incorrect routing');
  }

  public NavigateToTimecardsByOrgLevel(orgLevelId: number, startDate: Date, endDate: Date): void {
    if (startDate && endDate) {
      this.router.navigate(['timecards'],
        {
          relativeTo: this.route.pathFromRoot[2],
          queryParams: {
            startDate: moment(startDate).format(appConfig.linkDateFormat),
            endDate: moment(endDate).format(appConfig.linkDateFormat),
            orgLevelId: orgLevelId
          }
        });
      return;
    }
    if (!startDate && !endDate) {
      this.router.navigate(['timecards'], { relativeTo: this.route.pathFromRoot[2] });
      return;
    }
    throw Error('incorrect routing');
  }
}
