<kendo-grid class="slx-full-height"
  [data]="gridView"
  [scrollable]="'scrollable'"
  [selectable]="{ mode:'single'}"
  (selectionChange)="selectionChange($event)"
>
  <kendo-grid-column width="105" title="Time" field="arrivalTime">
    <ng-template kendoGridHeaderTemplate>
      <span class="widget-grid-header">
        <i class="icon far fa-clock" aria-hidden="true"></i>
        <span class="grid-header-label">Time</span>
      </span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.arrivalTimeTitle}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Scheduled" field="scheduled">
    <ng-template kendoGridHeaderTemplate>
      <span class="widget-grid-header">
        <i class="icon far fa-calendar" aria-hidden="true"></i>
        <span class="grid-header-label">Scheduled</span>
      </span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div [slxAccessibleSwitch]="{provider: 'Menu', permission: 'Scheduler'}">
         <ng-template slxAccessibleCase>
            <button type="button" [ngClass]="{'button-label': dataItem.scheduled == 0, 'button-link': dataItem.scheduled > 0}" (click)="onScheduledFieldClickHandler(dataItem)">{{dataItem.scheduled}}</button>
         </ng-template>
         <ng-template slxNonAccessibleCase>
           0
         </ng-template>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="In" field="weekdayHours">
    <ng-template kendoGridHeaderTemplate>
      <span class="widget-grid-header">
        <i class="icon far fa-calendar-check" aria-hidden="true"></i>
        <span class="grid-header-label">In</span>
      </span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div>
        <button type="button" [ngClass]="{'button-label': dataItem.in == 0, 'button-link': dataItem.in > 0}" (click)="onInFieldClickHandler(dataItem)">{{dataItem.in}}</button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Difference" field="difference">
    <ng-template kendoGridHeaderTemplate>
      <span class="widget-grid-header">
        <i class="icon far fa-adjust" aria-hidden="true"></i>
        <span class="grid-header-label">Difference</span>
      </span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div>
        <button type="button" [ngClass]="{'button-label': dataItem.difference == 0, 'button-link': dataItem.difference != 0}" (click)="onDifferencceFieldClickHandler(dataItem)">{{dataItem.difference}}</button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Late" field="late">
    <ng-template kendoGridHeaderTemplate>
        <span class="widget-grid-header">
            <i class="icon far fa-calendar-exclamation" aria-hidden="true"></i>
            <span class="grid-header-label">Late</span>
          </span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div>
        <button type="button" [ngClass]="{'button-label': !dataItem.late, 'button-link': dataItem.late > 0}"
          (click)="onLateFieldClickHandler(dataItem)">{{dataItem.late}}</button>
      </div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
