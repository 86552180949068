import * as _ from 'lodash';
import { Component, OnInit, Provider, Inject } from '@angular/core';
import { DialogModeSize, DialogOptions2, IDialog, ModalService, ColumnManagementService } from '../../../../common';
import { Employee, EmployeeActionDefinition, EmployeeListItem } from '../../models';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN } from '../../../../core/models';
import { OrgLevel } from '../../../../state-model/models';
import { employeeListConfig } from '../../employee-list.config';
import { EmployeeListService, SlateProfileService } from '../../services';
import { TimeclockSlateProfileDefinition } from '../../../../organization/models/lookup';
import * as moment from 'moment';
import { IRangeDates } from '../../../../common/models/range-dates';
@Component({
  selector: 'slx-slate-profile-assignment',
  templateUrl: './slate-profile-assignment.component.html',
  styleUrls: ['./slate-profile-assignment.component.scss'],
  providers: [ColumnManagementService]
})
export class SlateProfileAssignmentComponent implements OnInit, IDialog {

  public dialogResult: boolean;

  public state: {
    isLoading: boolean;
    isActiveOnly: boolean;
  };

  public canAssign: boolean;
  public employees: Employee[]
  public selectedEmployees: Employee[];
  public selectedProfile: TimeclockSlateProfileDefinition;
  public massAssignProfileEmployeeListItem: EmployeeListItem;
  public dateRange: IRangeDates;

  constructor(
    @Inject(ISACTIVEONLY_ID_TOKEN) public isActiveOnly: boolean,
    public fieldDefinition: EmployeeActionDefinition,
    public orgLevel: OrgLevel,
    public modalService: ModalService,
    public options: DialogOptions2,
    public slateProfileService: SlateProfileService,
    private employeeListService: EmployeeListService
    ) {
      this.dateRange = { startDate: moment().endOf('day').add(-1, 'week').toDate(), endDate: moment().endOf('day').toDate() };
      this.state = {
        isLoading: false,
        isActiveOnly: isActiveOnly
      };
      this.massAssignProfileEmployeeListItem = new EmployeeListItem();
      this.massAssignProfileEmployeeListItem.id = 70;
      this.massAssignProfileEmployeeListItem.listName = 'Mass Assign SLATE Profile';
    };

  public ngOnInit(): void {
    this.loadEmployees();
    this.employeesSelect([]);
  }

  private loadEmployees(): void {
    this.state.isLoading = true;
    this.employeeListService.getEmployees(this.massAssignProfileEmployeeListItem.id, this.orgLevel.id, this.state.isActiveOnly, this.massAssignProfileEmployeeListItem, this.dateRange.startDate, this.dateRange.endDate)
    .then((employees: Employee[]) => {
      this.employees = employees;
      this.employees = _.uniqBy(this.employees, e =>e.EmpId);
      this.state.isLoading = false;
    });
   }

  public assignProfile(): void {
    if (this.canAssign && this.selectedProfile) {
      this.state.isLoading = true;
      let employeeIds: string[] = _.map(this.selectedEmployees, (employee: Employee) => employee[employeeListConfig.employeeIdentifierName]);
      this.slateProfileService.assignProfile(employeeIds, this.selectedProfile.name, this.orgLevel.id)
      .finally(()=> {
        this.state.isLoading = false;
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
      })
    }
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public employeesSelect(selectedEmployees: Employee[]): void {
    this.selectedEmployees = selectedEmployees;
    this.canAssign = this.selectedEmployees && this.selectedEmployees.length > 0;
  }

  public static openDialog(
    modalService: ModalService, actionDefinition: Provider[],
    callback: (result: boolean) => void
  ): SlateProfileAssignmentComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.height = 400;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions,

      }
    ];
    resolvedProviders = [...resolvedProviders, ...actionDefinition];
    let dialog: SlateProfileAssignmentComponent = modalService.globalAnchor.openDialog2(
      SlateProfileAssignmentComponent,
      'Mass Assign SLATE Profile',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
      }
    );
    return dialog;
  }

}
