import { VacationPlanEmployeeSummaryRecord, IVacationPlanEmployeeSummaryRecord } from './index';

export interface IVacationPlanEmployeeSummary {
  totalVacations: number;
  totalVacationsHours: number;
  records: IVacationPlanEmployeeSummaryRecord[];
}

export class VacationPlanEmployeeSummary {
  public totalVacations: number;
  public totalVacationsHours: number;
  public records: VacationPlanEmployeeSummaryRecord[];
}
