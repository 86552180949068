/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./meal-deduction-configuration-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../meal-deduction-configuration/meal-deduction-configuration.component.ngfactory";
import * as i3 from "../../../services/meal-deduction/meal-deduction-management.service";
import * as i4 from "../../../services/meal-deduction/meal-deduction-api.service";
import * as i5 from "../../../../../organization/services/lookup/lookup.service";
import * as i6 from "../../../../../organization/services/org-level/org-level-watch.service";
import * as i7 from "../meal-deduction-configuration/meal-deduction-configuration.component";
import * as i8 from "./meal-deduction-configuration-dialog.component";
import * as i9 from "../../../models/meal-deductions/meal-deductions-dialog-options";
import * as i10 from "../../../../../common/models/dialog-options";
import * as i11 from "../../../../../common/services/modal/modal.service";
var styles_MealDeductionConfigurationDialogComponent = [i0.styles];
var RenderType_MealDeductionConfigurationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MealDeductionConfigurationDialogComponent, data: {} });
export { RenderType_MealDeductionConfigurationDialogComponent as RenderType_MealDeductionConfigurationDialogComponent };
export function View_MealDeductionConfigurationDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { configEditorComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "slx-meal-deduction-configuration", [], null, [[null, "onSaved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSaved" === en)) {
        var pd_0 = (_co.closeAfterSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MealDeductionConfigurationComponent_0, i2.RenderType_MealDeductionConfigurationComponent)), i1.ɵprd(512, null, i3.MealDeductionManagementService, i3.MealDeductionManagementService, [i4.MealDeductionApiService, i5.LookupService, i6.OrgLevelWatchService]), i1.ɵdid(5, 114688, [[1, 4], ["configEditorComponent", 4]], 0, i7.MealDeductionConfigurationComponent, [i3.MealDeductionManagementService, i6.OrgLevelWatchService], { hideDescription: [0, "hideDescription"] }, { onSaved: "onSaved" }), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "modal-footer center-align"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["class", "btn btn-default slx-button slx-with-icon slx-white-blue-text slx-no-border bolder-btn-text"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["class", "btn btn-default slx-button slx-with-icon slx-white-blue-text slx-no-border bolder-btn-text"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hideDescription; _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 5).form.invalid; _ck(_v, 11, 0, currVal_1); }); }
export function View_MealDeductionConfigurationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-meal-deduction-configuration-dialog", [], null, null, null, View_MealDeductionConfigurationDialogComponent_0, RenderType_MealDeductionConfigurationDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.MealDeductionConfigurationDialogComponent, [i9.MealDeducitonsDialogOptions, i10.DialogOptions, i11.ModalService], null, null)], null, null); }
var MealDeductionConfigurationDialogComponentNgFactory = i1.ɵccf("slx-meal-deduction-configuration-dialog", i8.MealDeductionConfigurationDialogComponent, View_MealDeductionConfigurationDialogComponent_Host_0, {}, {}, []);
export { MealDeductionConfigurationDialogComponentNgFactory as MealDeductionConfigurationDialogComponentNgFactory };
