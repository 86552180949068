import { ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
var EmailInputComponent = /** @class */ (function () {
    function EmailInputComponent(elementRef, ngControl) {
        this.tabindex = 0;
        this.placeholder = 'Email';
        // public emailMaskConf: any;
        this.innerValue = '';
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.hasFocus = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.hasFocus = false;
        this.ngControl = ngControl;
        ngControl.valueAccessor = this;
        // this.emailMaskConf = {
        //   mask: emailMask.default.mask
        // };
    }
    Object.defineProperty(EmailInputComponent.prototype, "readonly", {
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    EmailInputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    EmailInputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
    };
    // public onKey(event: KeyboardEvent): void {
    //   if (!this.innerValue) {
    //     return;
    //   }
    //   let str: string = this.innerValue;
    //   if (event.key === '@') {
    //     let atPos: number = str.lastIndexOf(event.key);
    //     let str1: string = str.slice(0, atPos);
    //     let str2: string = str.slice(atPos + 1);
    //     this.innerValue = str1 + str2;
    //   }
    //   if (event.key === '.') {
    //     let atPos: number = str.lastIndexOf('@');
    //     let pointPos: number = str.indexOf(event.key);
    //     if (pointPos > atPos + 1) {
    //       return;
    //     }
    //     let str1: string = str.slice(0, pointPos);
    //     let str2: string = str.slice(pointPos + 1);
    //     this.innerValue = str1 + str2;
    //   }
    //
    // }
    EmailInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !myattr;
    };
    EmailInputComponent.prototype.writeValue = function (value) {
        this.innerValue = value ? value : null;
        this.inputComponent.nativeElement.value = value ? value : null;
    };
    EmailInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    EmailInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    EmailInputComponent.prototype.valueChanged = function (value) {
        if (value === '')
            this.writeValue(null);
        this.onChangeCallback(this.innerValue);
    };
    return EmailInputComponent;
}());
export { EmailInputComponent };
