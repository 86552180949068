<div *ngIf="options">
    <div *ngFor="let option of options; let idx = index;" class="options-button-content" >
            <slx-radio-button  class="radio-option"
            name="option{{option.name}}"
            fieldName="{{option.name}}" 
            [option]="option.id"
            [caption]="option.name" 
            [(ngModel)]="parameter.value">
            </slx-radio-button>
    </div>
</div>