/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./punch-attestation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../punch-attestation-section/punch-attestation-section.component.ngfactory";
import * as i3 from "../punch-attestation-section/punch-attestation-section.component";
import * as i4 from "../../services/punch-attestation-management.service";
import * as i5 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i6 from "../../../../common/components/spinner/spinner.component";
import * as i7 from "../punch-attestation-header/punch-attestation-header.component.ngfactory";
import * as i8 from "../punch-attestation-header/punch-attestation-header.component";
import * as i9 from "@angular/common";
import * as i10 from "../../services/punch-attestation-api.service";
import * as i11 from "../../../../common/services/modal/modal.service";
import * as i12 from "./punch-attestation.component";
import * as i13 from "@angular/router";
var styles_PunchAttestationComponent = [i0.styles];
var RenderType_PunchAttestationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PunchAttestationComponent, data: {} });
export { RenderType_PunchAttestationComponent as RenderType_PunchAttestationComponent };
function View_PunchAttestationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-punch-attestation-sections"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-punch-attestation-section", [], null, null, null, i2.View_PunchAttestationSectionComponent_0, i2.RenderType_PunchAttestationSectionComponent)), i1.ɵdid(2, 114688, null, 0, i3.PunchAttestationSectionComponent, [i4.PunchAttestationManagementService, i1.ChangeDetectorRef], { orgLevel: [0, "orgLevel"], group: [1, "group"], attestationTypeLookup: [2, "attestationTypeLookup"], restrictedAnswerLookup: [3, "restrictedAnswerLookup"], punchAttestationAnswerCodes: [4, "punchAttestationAnswerCodes"], punchAttestationPunchType: [5, "punchAttestationPunchType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orgLevel; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.attestationTypeLookup; var currVal_3 = _co.restrictedAnswerLookup; var currVal_4 = _co.punchAttestationAnswerCodes; var currVal_5 = _co.punchAttestationPunchType; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_PunchAttestationComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { scrollContainer: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "slx-spinner", [], null, null, null, i5.View_SpinnerComponent_0, i5.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i6.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 6, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-punch-attestation-header", [["class", "slx-content-toolbar-indents"]], null, null, null, i7.View_PunchAttestationHeaderComponent_0, i7.RenderType_PunchAttestationHeaderComponent)), i1.ɵdid(5, 114688, null, 0, i8.PunchAttestationHeaderComponent, [i4.PunchAttestationManagementService], { orgLevel: [0, "orgLevel"] }, null), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["groups", 1]], null, 3, "div", [["class", "slx-high-box__body slx-main-content-indents punch-attestation-content"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onScroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PunchAttestationComponent_1)), i1.ɵdid(8, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.orgLevel; _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.groups$)); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_PunchAttestationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-punch-attestation", [], null, null, null, View_PunchAttestationComponent_0, RenderType_PunchAttestationComponent)), i1.ɵprd(512, null, i4.PunchAttestationManagementService, i4.PunchAttestationManagementService, [i10.PunchAttestationApiService, i11.ModalService]), i1.ɵdid(2, 245760, null, 0, i12.PunchAttestationComponent, [i4.PunchAttestationManagementService, i1.ChangeDetectorRef, i13.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PunchAttestationComponentNgFactory = i1.ɵccf("slx-punch-attestation", i12.PunchAttestationComponent, View_PunchAttestationComponent_Host_0, {}, {}, []);
export { PunchAttestationComponentNgFactory as PunchAttestationComponentNgFactory };
