<div class="main">
  <slx-schedule-console-overview-toolbar
    [showDetailedControl]="isDayView"
    (onShowDetailedChange)="onShowDetailedChange($event)"
  ></slx-schedule-console-overview-toolbar>

  <slx-schedule-console-overview-week
    *ngIf="!isDayView"
  ></slx-schedule-console-overview-week>

  <slx-schedule-console-overview-day
    *ngIf="isDayView && !showDetailed"
  ></slx-schedule-console-overview-day>

  <slx-operational-console-grid
    *ngIf="isDayView && showDetailed"
  ></slx-operational-console-grid>
</div>
