/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./single-message.component";
import * as i3 from "../../../services/messages.service";
var styles_SingleMessageComponent = [];
var RenderType_SingleMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SingleMessageComponent, data: {} });
export { RenderType_SingleMessageComponent as RenderType_SingleMessageComponent };
function View_SingleMessageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "slx-single-message__close-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose(_co.message) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null))], null, null); }
function View_SingleMessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "slx-single-message"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SingleMessageComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-single-message"; var currVal_1 = _co.level; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.canClose; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_SingleMessageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SingleMessageComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActive; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SingleMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-single-message", [], null, null, null, View_SingleMessageComponent_0, RenderType_SingleMessageComponent)), i0.ɵdid(1, 49152, null, 0, i2.SingleMessageComponent, [[2, i3.MessagesService]], null, null)], null, null); }
var SingleMessageComponentNgFactory = i0.ɵccf("slx-single-message", i2.SingleMessageComponent, View_SingleMessageComponent_Host_0, { isActive: "isActive", level: "level", message: "message", description: "description", canClose: "canClose" }, {}, ["*"]);
export { SingleMessageComponentNgFactory as SingleMessageComponentNgFactory };
