import { IEssOption, EssOption } from './ess-option';

export interface IEssTemplateOption {
  id: number;
  templateId: number;
  essOption: IEssOption;
  value: boolean;
}

export class EssTemplateOption {
  public id: number;
  public templateId: number;
  public essOption: EssOption;
  public value: boolean;
  public childs: EssTemplateOption[];
}
