export enum LogicalOperator {
    And,
    Or,
    Not,
    Empty
}

export enum ComparisionOperator {
    Between,
    Equals,
    'Starting With',
    Contains
}

export enum DataType {
    String,
    Date
}

export class LogicalExpressionModel {
    public logicalOperator: LogicalOperator = LogicalOperator.And;
    public children: LogicalExpressionModel[] = [];
    public searchPredicate: SearchPredicate = new SearchPredicate ();
}

export class SearchPredicate {
    public searchCategoryName: string;
    public values: any[];
    public searchOperator: string;
}


// DTO interfaces

export  interface ILogicExpression {
    LogicalOperator: number;
    Predicate: ISearchPredicate;
    ChildExpressions: ILogicExpression[];
}

export  interface ISearchPredicate {
    SearchCategoryName: string;
    Values: any[];
    SearchOperator: string;
}
