<div class="app collapsed is-desktop" [ngClass]="{'payroll': menuItem.id === 177}" (click)="open()">
  <a class="app__logo fav-logo">
    <i class="fa" [ngClass]="icon" aria-hidden="true"></i>
  </a>
  <div class="app__content">
    <a class="app__url">{{ menuItem.displayName }}</a>
  </div>
</div>

<div class="is-mobile" (click)="open()">
  <i class="icon fa" [ngClass]="icon" aria-hidden="true"></i>
  <div class="label">{{ menuItem.displayName }}</div>
</div>
