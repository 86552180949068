import { Subject } from 'rxjs/Subject';
import { Assert } from '../../framework/index';
var CollapsibleSectionService = /** @class */ (function () {
    function CollapsibleSectionService() {
        this.collapseAll$ = new Subject();
    }
    Object.defineProperty(CollapsibleSectionService.prototype, "isExpand", {
        get: function () {
            return this.m_isExpand;
        },
        enumerable: true,
        configurable: true
    });
    CollapsibleSectionService.prototype.toggleAllSections = function (isExpand) {
        this.m_isExpand = isExpand;
        this.collapseAll$.next(isExpand);
    };
    CollapsibleSectionService.prototype.subscribeToToggleSections = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.collapseAll$.subscribe(callback);
    };
    return CollapsibleSectionService;
}());
export { CollapsibleSectionService };
