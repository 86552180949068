import * as tslib_1 from "tslib";
import { PostScheduleSettings } from './../../../models/open-shift-management/post-schedule-settings';
import { EventEmitter, OnInit } from '@angular/core';
import { LookupService, LookupType } from '../../../../organization';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import { Subscription } from 'rxjs/Subscription';
import { AppSettingsManageService } from '../../../../app-settings/services';
var PostScheduleSettingsComponent = /** @class */ (function () {
    function PostScheduleSettingsComponent(lookupService, openShiftManagementManagementService, appSettingManageService) {
        this.lookupService = lookupService;
        this.openShiftManagementManagementService = openShiftManagementManagementService;
        this.appSettingManageService = appSettingManageService;
        this.isChecked = new EventEmitter();
        this.notifyType = [];
        this.notifyCount = 0;
        this.checkNotify = false;
    }
    PostScheduleSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getRestrictedNotify();
        this.getSettings();
        this.settingsSubscription = this.openShiftManagementManagementService.onPostSettingsLoaded$.subscribe(function (value) {
            _this.settings = value;
            _this.notifyData = _this.notifyType.find(function (x) { return x.notifyId == _this.settings.notifyDaysAndWeekly; });
        });
    };
    PostScheduleSettingsComponent.prototype.getRestrictedNotify = function () {
        var _this = this;
        this.lookupService.getLookup({ lookupType: LookupType.getRestrictedNotify }).then(function (x) {
            _this.notifyType = x.items;
        });
    };
    PostScheduleSettingsComponent.prototype.onNotifyChange = function () {
        this.settings.notifyDaysAndWeekly = this.notifyData.notifyId;
        if (this.settings.notifyDaysAndWeekly === 0) {
            this.settings.reminderCount = null;
        }
    };
    PostScheduleSettingsComponent.prototype.notifyCheckChange = function () {
        if (!this.settings.notifyWeekly) {
            this.notifyData = { notifyId: 0, notifyType: '' };
            this.settings.notifyDaysAndWeekly = 0;
            this.settings.reminderCount = null;
        }
    };
    PostScheduleSettingsComponent.prototype.validateNumber = function (event) {
        if (event.key == '-' || (event.target.value == '' && event.key == 0 && event.target.value == 0))
            event.preventDefault();
        if (event.target.value == 0)
            event.target.value = '';
    };
    PostScheduleSettingsComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.isIncreaseFrequencyofShiftsPostedNotifications = appServerConfig.IncreaseFrequencyofShiftsPostedNotifications;
                        this.openShiftManagementManagementService.isIncreaseFrequencyofShiftsPostedNotifications = this.isIncreaseFrequencyofShiftsPostedNotifications;
                        this.isAbilityForAdminToTurnOnAndOffAutoShiftPickUp = appServerConfig.AbilityForAdminToTurnOnAndOffAutoShiftPickUp;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PostScheduleSettingsComponent.prototype, "settingsSubscription", void 0);
    return PostScheduleSettingsComponent;
}());
export { PostScheduleSettingsComponent };
