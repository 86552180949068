<slx-spinner [show]="isLoading" class="response">
  <div class="charts-container">
    <div class="row dat">
      <div class="col-2 dis">
        <label class="name">
          <span>Positions:</span>  
        </label>
        <label class="count">
          <span>{{TotalCount}}</span>
        </label>
        <label class="sync">
          <span>Sync Completion:</span>  
        </label>
        <label class="count1">
          <span>{{sync}}%</span>  
        </label>
      </div>
      <div class="col-6 chart">
      <kendo-chart class="abc" (seriesClick)="onClick($event)">
        <ng-template kendoChartDonutCenterTemplate>
          <h3 class="data">{{TotalCount}}</h3>
        </ng-template>
        <kendo-chart-series >
          <kendo-chart-series-item
            [autoFit]="autofit"
            type="donut"
            [data]="gridData"
            field="statusCount"
            categoryField="statusMessage"
            zoomable="true"
            [color]="color"
          >
            <kendo-chart-series-item-labels class="def" color="#ffff"  background="none" > </kendo-chart-series-item-labels>
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
      <!-- <label>
          <input type="checkbox" [(ngModel)]="autofit" />
          <span>Toggle Autofit</span>
        </label> -->
    </div>
  </div>
  <div class="dialog">
  <kendo-dialog
        *ngIf="opened"
        (close)="close('cancel')"
        class="popup-header"
        [width]="850"
      [height]="490"
        title="Sync Status Details"
      >
      <div class="popup-body">
        <div class="row pop-body">
          <div class="col-9 lab">
        <label >
          <span>Positions:</span>  
        </label>
        <label>
          <span>{{StatusMessage}}</span>
        </label>
      </div>
       
    </div>
   
    <div class="row wrapper">
      <button class="theme-button-apply view" (click)="exportToExcel(grid)">Export</button>
  
    </div>
        <kendo-grid
          #grid="kendoGrid"
          [data]="dataView"
          [kendoGridBinding]="dataView"
          [pageable]="true"
          filterable="menu"
          [sortable]="{ mode: 'single' }"
          [pageSize]="50"
          class="grid slx-blue-grid"
        >
        <kendo-grid-column field="jobCode" title="Job Code" width="160" ></kendo-grid-column>
        <kendo-grid-column field="orgLevelName" title="Position Name" width="160"></kendo-grid-column>
          <!-- <kendo-grid-column field="orgLevelName" title="Action Date" [filterable]="false"></kendo-grid-column> -->
          <kendo-grid-column field="entity" title="Org Type" width="160" ></kendo-grid-column>
          <kendo-grid-column field="action" title="Action" width="160" [filterable]="false"></kendo-grid-column>
          <ng-template kendoGridNoRecordsTemplate>
            <p>There is no data to display</p>
          </ng-template>
          <kendo-grid-excel fileName="position.xlsx" [fetchData]="allData"></kendo-grid-excel>
        </kendo-grid>
      </div>
  
        <kendo-dialog-actions class="popup-footer">
          <button kendoButton themeColor="primary" class="popup-btn" (click)="close('yes')">
            Close
          </button>  
        </kendo-dialog-actions>
      </kendo-dialog>
    </div>
  </slx-spinner>