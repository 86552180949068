import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { WcRepeatInjuryManagementService } from '../../../services/index';
var WcRepeatInjuryComponent = /** @class */ (function () {
    function WcRepeatInjuryComponent(manService) {
        this.manService = manService;
        this.isLoading = true;
        this.subscriptions = {};
    }
    WcRepeatInjuryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.init('WcRepeatInjuryRoster');
        this.subscriptions.onLoad = this.manService
            .subscribeToLoading(function (value) { return (_this.isLoading = value); });
    };
    WcRepeatInjuryComponent.prototype.ngOnDestroy = function () {
        this.manService.destroy();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcRepeatInjuryComponent.prototype, "subscriptions", void 0);
    return WcRepeatInjuryComponent;
}());
export { WcRepeatInjuryComponent };
