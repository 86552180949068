<div class="slx-high-box">
  <div class="slx-content-toolbar-indents slx-flex-row">
    <button type="button" [disabled]="addNewMode" class="theme-button-apply margin-r edit-mode-btn" (click)="toggleEditMode()">
      <i class="fas fa-pencil-alt" aria-hidden="true"></i> Edit Mode
    </button>
    <button type="button" *ngIf="editMode && isOrganization" [disabled]="addNewMode" class="theme-button-cancel add-item-btn" (click)="addNewAgency()">
      <i class="fa fa-plus" aria-hidden="true"></i> Add New Agency
    </button>
  </div>

  <form novalidate #form="ngForm" class="slx-high-box__body slx-main-content-indents">
    <kendo-grid #kendoGrid class="slx-full-height agency-grid"
      [data]="gridState.view"
      [selectable]="editMode?{ mode:'single'}:false"
      (edit)="editHandler($event)"
      (remove)="removeHandler($event)"
      (save)="saveHandler($event)"
      (cancel)="cancelHandler()"
    >

      <kendo-grid-column title="Agency" field="agency.name" width="150">
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <slx-input-decorator>
                    <input [(ngModel)]="editedRecord.agency.name"
                    name="agencyName{{rowIndex}}" class="k-textbox"
                    [required]="true" [prohibitedValues]="{values: prohibitedNameValues, caseSensitive: false, trimValue: true, excludeSelf: true}"
                    slx-input placeholder="Name"
                      />
                    <span errorMessage for="prohibitedValues">This name already exists</span>
            </slx-input-decorator>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Address" field="agency.address" width="150">
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input slx-input [(ngModel)]="editedRecord.agency.address" name="agencyAddress{{rowIndex}}" class="k-textbox" placeholder="Address" />
            </slx-input-decorator>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="City" field="agency.city" width="80">
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input slx-input [(ngModel)]="editedRecord.agency.city" name="agencyCity{{rowIndex}}" class="k-textbox" placeholder="City" />
            </slx-input-decorator>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="State" field="agency.state" width="80">
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input slx-input [(ngModel)]="editedRecord.agency.state" name="agencyState{{rowIndex}}" class="k-textbox" placeholder="State" />
            </slx-input-decorator>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Zip" field="agency.zip" width="80">
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input slx-input [(ngModel)]="editedRecord.agency.zip" name="agencyZip{{rowIndex}}" class="k-textbox" placeholder="Zip" />
            </slx-input-decorator>
          </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Emp Count" field="agency.employeeCount" [editable]="false" width="80"></kendo-grid-column>
      <kendo-grid-column title="Updated By" field="agency.lastUpdateName" [editable]="false" width="100"></kendo-grid-column>
      <kendo-grid-column title="Last Updated" field="agency.lastUpdateDate" [editable]="false" width="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem?.agency?.lastUpdateDate">{{ dataItem?.agency?.lastUpdateDate | amDateFormat: appConfig.dateTimeFormat }} </span>
          </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column *ngIf="editMode" title="Command" width="120">
          <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
              <div class="flex-horizontal">
                  <button kendoGridEditCommand [disabled]="addNewMode" type="button" class="theme-icon-button theme-inline-apply-button">
                      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                  </button>
                  <button kendoGridRemoveCommand [disabled]="dataItem.agency.employeeCount > 0 || addNewMode"
                      type="button" class="theme-icon-button theme-inline-cancel-button">
                      <i class="fa fa-times" aria-hidden="true"></i>
                  </button>

                  <button kendoGridSaveCommand type="button" [disabled]="!form.valid" class="theme-icon-button theme-inline-edit-button">
                      <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
                  </button>
                  <button kendoGridCancelCommand type="button" class="theme-icon-button theme-inline-cancel-button">
                      <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
                  </button>
              </div>
          </ng-template>
      </kendo-grid-command-column>

      <ng-template kendoGridDetailTemplate let-dataItem let-rowIndex>
          <slx-agency-details [rowIndex]="rowIndex" [organizations]="organizations" [agencyMapping]="dataItem" [editMode]="editMode"></slx-agency-details>
      </ng-template>

    </kendo-grid>
  </form>
</div>
