import {
  Component,
  Input,
  ElementRef,
  forwardRef
} from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor } from '../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-check-box',
  templateUrl: 'check-box.component.html',
  providers: [createValuAccessor(CheckBoxComponent)]
})
export class CheckBoxComponent implements ControlValueAccessor {
  @Input()
  public set fieldName(value: string) {
    if (!this.m_fieldName && value) {
      this.m_fieldName = this.getUniqueId(value);
    }
  }
  public get fieldName(): string {
    return this.m_fieldName;
  }
  @Input()
  public caption: string;
  @Input()
  public className: string;
  @Input()
  public readonly: boolean;

  public get value(): boolean {
    return this.innerValue;
  }

  public set value(v: boolean) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(this.innerValue);
    }
  }

  private m_fieldName: string;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private innerValue: boolean = false;

  constructor () {
    this.m_fieldName = this.getUniqueId('checkbox');
  }
  public writeValue(value?: boolean): void {
    if (!_.isUndefined(value) && !_.isNull(value)) {
      this.innerValue = value;
    }
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }


  public cssClass(): string {
    let cssClass: string = 'slx-check-box';
    if (this.value) {
      cssClass += ` checked`;
    }
    if (this.readonly) {
      cssClass += ` disabled`;
    }
    if (_.isString(this.className) && _.size(this.className) > 0) {
      cssClass += ` ${this.className}`;
    }

    return cssClass;
  }

  private getUniqueId(name: string): string {
    return `${name}_${Math.random().toString(36).substr(2, 9)}`;
  }
}
