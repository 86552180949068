import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import * as _ from 'lodash';
import { appConfig } from '../../../../app.config';
import { TimeCardModel, DailyTimecardChangeStatus } from '../../../models/index';
import { DailyTimecardManagementService } from '../../../services/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { process } from '@progress/kendo-data-query';
var SummaryListComponent = /** @class */ (function () {
    function SummaryListComponent(managementService) {
        var _this = this;
        this.managementService = managementService;
        this.gridState = new KendoGridStateHelper();
        this.optionsChangedSubscription = this.managementService.displayOptionSelected$
            .subscribe(function (options) {
            _this.displayOptions = options;
        });
        this.rowCallback = function (context) {
            var item = context.dataItem;
            switch (item.changeStatus) {
                case DailyTimecardChangeStatus.deleted:
                    return { 'slx-deleted-row': true };
                case DailyTimecardChangeStatus.added:
                    return { 'slx-new-row': true };
                case DailyTimecardChangeStatus.changed:
                    return { 'slx-changed-row': true };
                default:
                    return {};
            }
        };
    }
    Object.defineProperty(SummaryListComponent.prototype, "model", {
        set: function (value) {
            var _this = this;
            if (!value) {
                return;
            }
            this.employeeId = value.employee.id;
            this.shiftDiffBasedOnTime = value.shiftDiffBasedOnTime;
            this.records = value.earningStatistics;
            if (value.recalculated) {
                this.afterRecalculatedMode = true;
                setTimeout(function () {
                    _this.afterRecalculatedMode = false;
                    _this.refreshGrid();
                }, appConfig.showChangesTimeoutMs);
            }
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    SummaryListComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    SummaryListComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    SummaryListComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        if (!this.afterRecalculatedMode) {
            this.records = _.filter(this.records, function (r) {
                return r.changeStatus !== DailyTimecardChangeStatus.deleted;
            });
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SummaryListComponent.prototype, "containerSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SummaryListComponent.prototype, "modelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SummaryListComponent.prototype, "optionsChangedSubscription", void 0);
    return SummaryListComponent;
}());
export { SummaryListComponent };
