import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ManagementBaseService } from '../../../core/services/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { UserRolesApiService } from './user-roles-api.service';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators';
var UserRolesManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(UserRolesManagementService, _super);
    function UserRolesManagementService(api) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.m_onRolesLoaded = new ReplaySubject();
        _this.m_onUsersWithRolesLoaded = new ReplaySubject();
        _this.m_onRoleUsersLoaded = new ReplaySubject();
        _this.m_gridState = new KendoGridStateHelper();
        return _this;
    }
    Object.defineProperty(UserRolesManagementService.prototype, "onRolesLoaded$", {
        get: function () {
            return this.m_onRolesLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserRolesManagementService.prototype, "onUsersWithRolesLoaded$", {
        get: function () {
            return this.m_onUsersWithRolesLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserRolesManagementService.prototype, "onRoleUsersLoaded$", {
        get: function () {
            return this.m_onRoleUsersLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserRolesManagementService.prototype, "gridState", {
        get: function () {
            return this.m_gridState;
        },
        enumerable: true,
        configurable: true
    });
    UserRolesManagementService.prototype.loadRoles = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.getRoles().then(function (roles) {
            _this.m_onRolesLoaded.next(roles);
            _this.onLoadStatusChanged(false);
        }).catch(function (res) {
            _this.onError(res);
        });
    };
    UserRolesManagementService.prototype.getRoleUsers = function (wrapper) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.getRoleUsers(wrapper.role.id).then(function (result) {
            wrapper.users = result;
            _this.m_onRoleUsersLoaded.next({ dataItem: wrapper, users: result });
            _this.onLoadStatusChanged(false);
        }).catch(function (res) {
            _this.onError(res);
        });
    };
    UserRolesManagementService.prototype.filterByUser = function (nameEntry) {
        //test
    };
    UserRolesManagementService.prototype.loadUsersRolesWithOrglevel = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.getUsersWithRolesOrglevel(this.orgLevelId).then(function (roles) {
            _this.m_onUsersWithRolesLoaded.next(roles);
            _this.onLoadStatusChanged(false);
        }).catch(function (res) {
            _this.onError(res);
        });
    };
    UserRolesManagementService.prototype.init = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
            _this.loadUsersRolesWithOrglevel();
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], UserRolesManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserRolesManagementService.prototype, "orgLevelSubscription", void 0);
    return UserRolesManagementService;
}(ManagementBaseService));
export { UserRolesManagementService };
