import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { LogiManagementService } from '../services/logi-management.service';
import { WindowRef } from '../../../core/services/window/window-ref.model';

import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-logi-host',
  templateUrl: 'logi-host.component.html',
  styleUrls: ['logi-host.component.scss'],
  providers: [LogiManagementService]
})
export class LogiHostComponent implements AfterViewInit, OnDestroy {

  public isIFrameLoading: boolean = true;
  public isDataLoading: boolean = false;
  public isActive: boolean = false;
  public logiUrl: any;

  @unsubscribe()
  private loadStateChangedSubscription: Subscription;

  @unsubscribe()
  private logiLinkChangedSubscription: Subscription;

  @unsubscribe()
  private logiLoadedSubscription: Subscription;

  @unsubscribe()
  private logiActionSubscription: Subscription;

  constructor(
    private router: Router,
    private logiManagementService: LogiManagementService,
    private winRef: WindowRef
  ) {
  }

  public ngAfterViewInit(): void {
    this.logiManagementService.initialize(this.router.url);

    this.loadStateChangedSubscription = this.logiManagementService.onLoadStatus$.subscribe((isLoading: boolean) => {
      if (isLoading) {
        this.isActive = false;
      }
      this.isDataLoading = isLoading;
    //  this.isIFrameLoading = isLoading;
    });

    this.logiLinkChangedSubscription = this.logiManagementService.logiUrlChanged$.subscribe((url: any) => {
      this.logiUrl = url;
      this.isActive = true;
    });

    this.logiLoadedSubscription = this.logiManagementService.subscribeToLogiLoaded(() => {
      this.isIFrameLoading = false;
      this.logiManagementService.resetCounterUserInactivity();
    });

    this.logiActionSubscription = this.logiManagementService.subscribeToLogiUserAction((event: string) => {
      this.logiManagementService.resetCounterUserInactivity();
      if (event === 'click') {
        this.winRef.nativeWindow.document.body.click();
      }
    });

    this.applyFixForIOSSafariScrolling();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.logiManagementService.destroy();
  }

  private applyFixForIOSSafariScrolling(): void {
    const elem = this.winRef.nativeWindow.document.body.querySelector('.main-box');
    if (elem !== null) {
      elem.classList.add('ios-safari-scroll-fix');
    }
  }
}
