import { FieldData } from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionAccrualsBalances {
  records: IEmployeeAccrual[];
}

export interface IEmployeeAccrual {
  effectiveDate: string;
  benefitType: string;
  accruedHours: number;
}

export class EmployeeAccrual  {
  public effectiveDate: FieldData<Date>;
  public benefitType: FieldData<string>;
  public accruedHours: FieldData<number>;
}

export class EmployeeSectionAccrualsBalances extends EmployeeSectionsBase {

  public records: EmployeeAccrual[];

  constructor() {
    super();
    this.records = [];
  }
}
