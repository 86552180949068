import { StaffingOptionsTable, IStaffingOptionsTableDTO } from './staffing-options-table';
import { StaffingRatingOrganization, IStaffingRatingOrganizationDTO, FiveStarRatingOrganization, IFiveStarRatingOrganizationDTO } from './staffing-rating-organization';

export interface IStaffingPredictorOrganizationInfoDTO {
    maxRatingValue: number;
    startDate: string;
    endDate: string;
    organization: IStaffingRatingOrganizationDTO;
    optionsTable: IStaffingOptionsTableDTO;
}

export class StaffingPredictorOrganizationInfo {
    public startDate: Date;
    public endDate: Date;
    public organization: StaffingRatingOrganization;
    public optionsTable: StaffingOptionsTable;
}

export interface IFiveStarPredictorOrganizationInfoDTO {
    maxRatingValue: number;
    startDate: string;
    endDate: string;
    organization: IFiveStarRatingOrganizationDTO;
}

export class FiveStarPredictorOrganizationInfo {
    public startDate: Date;
    public endDate: Date;
    public organization: FiveStarRatingOrganization;
}
