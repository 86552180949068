import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export const LOOKUP_DDN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LookupDropdownInputComponent),
  multi: true
};

@Component({
  moduleId: module.id,
  selector: 'slx-lookup-dropdown-input',
  templateUrl: 'lookup-dropdown-input.component.html',
  styleUrls: ['lookup-dropdown-input.component.scss'],
  providers: [LOOKUP_DDN_VALUE_ACCESSOR]
})
export class LookupDropdownInputComponent implements ControlValueAccessor, OnChanges {
  @Input() public inEdit: boolean;
  @Input() public className: string;

  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }

  @Input('lookupList')
  public set lookups(lookupList: any[]) {
    this.lookupList = lookupList;
    if (this.lookupList && this.staticValue && !this.innerValue) {
      let selectedLookup: any = this.getValueFromLookup(this.staticValue);
      this.innerValue = selectedLookup;
    }
  }
  public get lookups(): any[] {
    return this.lookupList;
  }

  @Input() public valueMemberPath: string;
  @Input() public descriptionMemberPath: string;

  public get value(): any {
    return this.innerValue;
  }

  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.staticValue = v;
      this.onChangeCallback(v);
    }
  }

  public get _elementRef(): ElementRef {
    return this.elementRef;
  }

  public staticValue: any;
  public lookupList: any[];

  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private innerValue: any = {};

  constructor(elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
  }
  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !Boolean(myattr);
  }
  public getValueFromLookup(value?: any): any {
    if (!value) return null;
    if (!this.lookupList) return null;
    if (!value.hasOwnProperty(this.valueMemberPath)) return null;
    let val: string | number = value[this.valueMemberPath];
    let selectedLookup: any = _.find(this.lookupList, (lookup: any) => {
      return lookup[this.valueMemberPath] === val;
    });
    return selectedLookup;
  }

  public writeValue(value?: any): void {
    let selectedLookup: any = this.getValueFromLookup(value);
    if (!selectedLookup) {
      if (!this.staticValue || this.lookupList) {
        this.staticValue = value;
      }
      this.innerValue = null;
    } else {
      this.innerValue = selectedLookup;
      this.staticValue = selectedLookup;
    }
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

}
