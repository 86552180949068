import * as internal from "assert";

export interface ITimeclockDefinition {
  id: number;
  name: string;
  isVirtual: boolean;
  messageDisplayStartDate: string;
  messageDisplayEndDate: string;
  lastMessageText: string;
  clockOrganization: string;
  physicalId: number;
}

export class TimeclockDefinition {
  public id: number;
  public name: string;
  public isVirtual: boolean;
  public messageDisplayStartDate: Date;
  public messageDisplayEndDate: Date;
  public lastMessageText: string;
  public clockOrganization: string;
  public physicalId: number;
}
