import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { AbstractControl } from '@angular/forms';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSectionsAttendance, IEmployeeSectionsAttendance, AbsenceStatItem } from '../../../models/employee-sections-performance/employee-sections-attendance';
import { EmployeeSectionsPerformanceApiService } from '../../../services/index';
import { Assert } from '../../../../../framework/assert/assert';
import * as moment from 'moment';
import { FileService } from '../../../../../common/services/file/file.service';
var EmployeeSectionsAttendanceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAttendanceComponent, _super);
    function EmployeeSectionsAttendanceComponent(employeeSectionsPerformanceApiService, decorator, ngZone, changeDetector, fileService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.fileService = fileService;
        _this.absencePrefix = 'scheduled';
        _this.monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        _this.days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
        Assert.isNotNull(employeeSectionsPerformanceApiService, 'employeeSectionsPerformanceApiService');
        _this.changeDetector = changeDetector;
        _this.employeeSectionsPerformanceApiService = employeeSectionsPerformanceApiService;
        _this.attendanceYears = _this.getAttendanceYears();
        _this.selectedYear = parseInt(moment().format('YYYY'), 10);
        return _this;
    }
    Object.defineProperty(EmployeeSectionsAttendanceComponent.prototype, "employeeSubsectionAttendance", {
        set: function (attendanceSection) {
            if (attendanceSection !== null && attendanceSection !== undefined) {
                this.attendanceSection = attendanceSection;
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendanceComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAttendanceComponent.prototype.getSubsectionModel = function () {
        return this.attendanceSection;
    };
    EmployeeSectionsAttendanceComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    EmployeeSectionsAttendanceComponent.prototype.onSelectYear = function (year) {
        this.selectedYear = year;
        this.loadSubsection();
    };
    EmployeeSectionsAttendanceComponent.prototype.onShowMenu = function (popper) {
        this.changeDetector.markForCheck();
    };
    EmployeeSectionsAttendanceComponent.prototype.onHideMenu = function (popper) {
        this.changeDetector.markForCheck();
    };
    EmployeeSectionsAttendanceComponent.prototype.onActualChanged = function () {
        this.absencePrefix = this.showActual ? 'actual' : 'scheduled';
        this.statsData = this.getStats(this.absencePrefix);
    };
    EmployeeSectionsAttendanceComponent.prototype.onPrintClick = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.startProgress();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.employeeSectionsPerformanceApiService.generateAttendancePdf(this.employeeId, this.selectedYear, this.absencePrefix == 'actual')
                            //this.fileService.saveToFileSystem(file.blob, file.file);
                        ];
                    case 2:
                        file = _a.sent();
                        //this.fileService.saveToFileSystem(file.blob, file.file);
                        this.fileService.openOrSavePdf(file);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.stopProgress();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsAttendanceComponent.prototype.getStats = function (prefix) {
        if (!this.attendanceSection || !this.attendanceSection.totals)
            return [];
        return this.attendanceSection.totals.getAbsenceStatistics(prefix);
    };
    Object.defineProperty(EmployeeSectionsAttendanceComponent.prototype, "statAdjacent", {
        get: function () {
            if (!this.attendanceSection || !this.attendanceSection.totals) {
                return 'Before - NA / After - NA';
            }
            return "Before - " + this.attendanceSection.totals.absentBeforeHoliday + " / After - " + this.attendanceSection.totals.absentAfterHoliday;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendanceComponent.prototype, "statFreq", {
        get: function () {
            if (!this.attendanceSection || !this.attendanceSection.totals || !this.attendanceSection.totals.frequentlyAbsentOn) {
                return 'NA';
            }
            return this.attendanceSection.totals.frequentlyAbsentOn + " (" + this.attendanceSection.totals.occurrences + ")";
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAttendanceComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPerformanceApiService.getPerformanceAttendance(this.employeeId, this.selectedYear)
            .then(function (employeeSectionsAttendance) {
            _this.employeeSubsectionAttendance = employeeSectionsAttendance;
            _this.stopProgress();
        })
            .catch(function (error) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsAttendanceComponent.prototype.getAttendanceYears = function () {
        var startYear = parseInt(moment().format('YYYY'), 10);
        var years = [];
        for (var i = startYear; i >= startYear - 8; i--) {
            years.push(i);
        }
        return years;
    };
    return EmployeeSectionsAttendanceComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsAttendanceComponent };
