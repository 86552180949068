export enum PayrollExportTestStateStatuses {
    INIT = 0,
    RUNNING = 1,
    SUCCEDED = 2,
    FAILED = 3,
    UNKNOWN = 4
}

export enum PayrollExportTestStateStatusLabels {
    INIT = 'Did not start',
    PROGRESS = 'In Progress',
    PASS = 'Pass',
    FAIL = 'Fail',
    ALERT = 'Alert',
    UNKNOWN = 'Unknown'
}
