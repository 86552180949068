import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../../framework/index';
import { appConfig } from '../../../../app.config';
import { unsubscribe } from '../../../../core/decorators/index';
import { AttendanceType, ArrivalsDeparturesAnalytics } from '../../../models/index';
import { ArrivalDeparturesManagementService } from '../../../services/index';
import * as _ from 'lodash';
var DeparturesWidgetComponent = /** @class */ (function () {
    function DeparturesWidgetComponent(activatedRoute, router, arrivalDeparturesManagementService) {
        this.otSum = 0;
        this.recordsSum = 0;
        Assert.isNotNull(arrivalDeparturesManagementService, 'arrivalDeparturesManagementService');
        this.arrivalDeparturesManagementService = arrivalDeparturesManagementService;
        this.activatedRoute = activatedRoute;
        this.router = router;
    }
    DeparturesWidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.loadStatusSubscription = this.arrivalDeparturesManagementService.onLoadStatus$.subscribe(function (val) { _this.isLoading = val; });
        this.loadedSubscription = this.arrivalDeparturesManagementService.onLoaded$.subscribe(function (container) {
            _this.arrivalDeparturesContainer = container;
            _this.recordsSum = container.departures ? container.departures.length : 0;
            _this.otSum = _.sumBy(_this.arrivalDeparturesContainer.departures, function (item) { return item.overtime; });
        });
    };
    DeparturesWidgetComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    DeparturesWidgetComponent.prototype.onOutFieldClickHandler = function (departure) {
        if (departure.departureTime) {
            this.navigateToArrivalsDeparturesDetails(AttendanceType.departures, ArrivalsDeparturesAnalytics.out, this.dateOn, departure.departureTime);
        }
        else if (!departure.departureTime && departure.scheduled === 0) {
            this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.unscheduled, this.dateOn);
        }
    };
    DeparturesWidgetComponent.prototype.onScheduledFieldClickHandler = function (departure) {
        this.navigateToArrivalsDeparturesDetails(AttendanceType.departures, ArrivalsDeparturesAnalytics.scheduled, this.dateOn, departure.departureTime);
    };
    DeparturesWidgetComponent.prototype.onOvertimeFieldClickHandler = function (departure) {
        this.navigateToArrivalsDeparturesDetails(AttendanceType.departures, ArrivalsDeparturesAnalytics.overtime, this.dateOn, departure.departureTime);
    };
    DeparturesWidgetComponent.prototype.onDifferenceFieldClickHandler = function (departure) {
        if (departure.departureTime) {
            this.navigateToArrivalsDeparturesDetails(AttendanceType.departures, ArrivalsDeparturesAnalytics.difference, this.dateOn, departure.departureTime);
        }
        else if (!departure.departureTime && departure.scheduled === 0) {
            this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.unscheduled, this.dateOn);
        }
    };
    DeparturesWidgetComponent.prototype.onRowSelectedHandler = function (departures) {
        var start = _.first(departures);
        var end = _.last(departures);
        if (start && end) {
            this.arrivalDeparturesManagementService.onDeparturesRangeChanged({ startDate: start.departureTime, endDate: end.departureTime });
        }
        else {
            this.arrivalDeparturesManagementService.onDeparturesRangeChanged(null);
        }
    };
    DeparturesWidgetComponent.prototype.toggleState = function () {
        this.collapsed = !this.collapsed;
    };
    DeparturesWidgetComponent.prototype.navigateToArrivalsDeparturesDetails = function (attendanceTypeDefinition, arrivalsDeparturesAnalyticsDefinition, dateOn, time) {
        if (time === void 0) { time = null; }
        var params = undefined;
        if (time) {
            params = {
                date: moment(dateOn).format(appConfig.linkDateFormat),
                time: moment(time).format(appConfig.linkDateTimeFormat),
                attendance_type: attendanceTypeDefinition,
                analytics: arrivalsDeparturesAnalyticsDefinition
            };
        }
        else {
            params = {
                date: moment(dateOn).format(appConfig.linkDateFormat),
                attendance_type: attendanceTypeDefinition,
                analytics: arrivalsDeparturesAnalyticsDefinition
            };
        }
        this.router.navigate(['arrivals_departures_details'], { relativeTo: this.activatedRoute.pathFromRoot[2], queryParams: params });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DeparturesWidgetComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DeparturesWidgetComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DeparturesWidgetComponent.prototype, "orgLevelSubscription", void 0);
    return DeparturesWidgetComponent;
}());
export { DeparturesWidgetComponent };
