export enum SummaryMessageLevel { info = 'Info', exception = 'Exception', error = 'Error', undefined = 'undefined' }

export interface IScheduleCycleSummaryMessage {
  workDate: string;
  employeeId: number;
  employeeName: number;
  positionId: number;
  positionName: string;
  shiftId: number;
  shiftName: string;
  unitId: number;
  unitName: string;
  level: string;
  text: string;
}

export class ScheduleCycleSummaryMessage {
  public workDate: Date;
  public employeeId: number;
  public employeeName: number;
  public positionId: number;
  public positionName: string;
  public shiftId: number;
  public shiftName: string;
  public unitId: number;
  public unitName: string;
  public summaryMessageLevel: SummaryMessageLevel;
  public text: string;
}
