import * as tslib_1 from "tslib";
import { MasterScheduleGroupingType } from './../../models/master-schedule/master-schedule-grouping-type';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/skip';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ManagementBaseService } from '../../../core/services/index';
import { ScheduleApiService } from './schedule-api.service';
import { ScheduleMapService } from './schedule-map.service';
import { ScheduleCycle, GenerateScheduleSummary, IScheduleActions, BudgetedPosition } from '../../../organization/models/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { MasterScheduleActions } from '../../store/master-schedule/master-schedule.actions';
import { IMasterScheduleRow, MasterScheduleData, EmployeeGridData, IMasterScheduleEntryRow, MasterScheduleEntryCell, MasterScheduleShiftInfo, MasterScheduleEntryRow, ISchedulePosition, IMasterScheduleSubtotalRow, MasterScheduleFilters, TotalsDescription, IMasterScheduleCell, MasterScheduleRecordType, MasterScheduleSubtotalRow, MasterScheduleGroupingTypes, IMasterScheduleGroupingType } from '../../models/index';
import { EmployeesStateSelector, EmployeesSelector } from '../../store/index';
import { MasterScheduleToolbarService } from './master-schedule-toolbar.service';
import { MasterScheduleFilterActions } from '../../store/master-schedule/master-schedule-filter.actions';
import { LookupApiService, ScheduleCycleHelperService, MenuAccessibleProviderService } from '../../../organization/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
var MasterScheduleManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(MasterScheduleManagementService, _super);
    function MasterScheduleManagementService(api, mapService, masterScheduleActions, masterScheduleFilterActions, employeesSelector, lookupApiService, scheduleCycleHelperService, menuAccessibleProviderService, masterScheduleToolbarService) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.mapService = mapService;
        _this.masterScheduleActions = masterScheduleActions;
        _this.masterScheduleFilterActions = masterScheduleFilterActions;
        _this.employeesSelector = employeesSelector;
        _this.lookupApiService = lookupApiService;
        _this.scheduleCycleHelperService = scheduleCycleHelperService;
        _this.menuAccessibleProviderService = menuAccessibleProviderService;
        _this.masterScheduleToolbarService = masterScheduleToolbarService;
        _this.onRecalculated$ = new ReplaySubject(1);
        _this.filtersChanged$ = new ReplaySubject(1);
        _this.filtersRestored$ = new ReplaySubject(1);
        _this.onLoadTotalsStatus$ = new ReplaySubject(1);
        _this.onTotalsLoaded$ = new ReplaySubject(1);
        _this.onEmployeeCellSelected$ = new ReplaySubject(1);
        _this.onStartCycleSelect$ = new Subject();
        _this.onNonStartCycleSelect$ = new Subject();
        _this.scheduleCyclesLoaded$ = new Subject();
        _this.statusSubscription = _this.isLoading$.subscribe(function (isLoading) {
            _this.onLoadStatusChanged(isLoading);
        });
        _this.statusTotalsSubscription = _this.isLoadingTotals$.subscribe(function (isLoading) {
            _this.onLoadTotalsStatus$.next(isLoading);
        });
        _this.orgLevelSubscription = _this.orgLevel$
            .combineLatest(_this.filtersRestored$)
            .subscribe(function (_a) {
            var orgLevel = _a[0], filters = _a[1];
            if (!orgLevel || !orgLevel.id) {
                return;
            }
            _this.lookupApiService.getScheduleCycles(orgLevel.id)
                .then(function (cycles) {
                _this.scheduleCyclesLoaded$.next(_this.cycles = cycles);
                _this.dateFrom = moment(filters.dateFrom).startOf('day').toDate();
                if (_this.cycles.length > 0) {
                    var today = moment().startOf('day');
                    var firstCycle = _this.findPayCycle(_this.cycles, today.toDate());
                    _this.firstCycleStart = firstCycle ? firstCycle.startDate : today;
                    if (!filters.dateFrom || !dateTimeUtils.validateDate(filters.dateFrom) || (_this.currentOrgLevelParentId > 0 && _this.currentOrgLevelParentId !== orgLevel.parentId)) {
                        _this.dateFrom = _this.firstCycleStart.toDate();
                        filters.dateFrom = null;
                    }
                    //this.updateStartOfCycleSubscription(this.dateFrom);
                }
                _this.cycleWeeks = _this.getWeeksByDate(_this.dateFrom);
                _this.cycleDays = _this.cycleWeeks * 7;
                _this.endDate = moment(_this.dateFrom).add(_this.cycleWeeks, 'weeks').subtract(1, 'day').toDate();
                if (filters.weekNumber !== _this.cycleWeeks || !filters.dateFrom) {
                    filters.dateFrom = _this.dateFrom;
                    filters.weekNumber = _this.getWeeksByDate(_this.dateFrom);
                    //this.actions.setFilters({ dateFrom: this.dateFrom, scheduleDisplay: this.getWeeksByDate(this.dateFrom) });
                }
                _this.masterScheduleToolbarService.onFiltersChanged(filters);
            });
        });
        _this.filterEchoSubscription = _this.masterScheduleToolbarService.onFiltersChanged$
            .subscribe(function (filters) {
            _this.filtersChanged$.next(filters);
            _this.filterSettings = filters;
            var ids = filters.filters.getIds();
            _this.selectedGrouping = filters.groupBy;
            _this.masterScheduleFilterActions.setFilters({
                dateFrom: filters.dateFrom,
                weekNumber: filters.weekNumber,
                positionGroup: ids.positionGroupId,
                position: ids.positionId,
                employeeType: ids.employeeTypeNames,
                unit: ids.unitId,
                shiftGroup: ids.shiftGroupId,
                groupBy: filters.groupBy,
                showTotalsFTEs: filters.showTotalsFTEs,
            });
        });
        _this.filterSubscription = _this.masterScheduleToolbarService.onFiltersChanged$
            .withLatestFrom(_this.orgLevel$)
            .withLatestFrom(_this.sessionUser$)
            .subscribe(function (_a) {
            var _b = _a[0], masterScheduleFilters = _b[0], orgLevel = _b[1], user = _a[1];
            _this.currentOrgLevelId = orgLevel.id;
            _this.currentOrgLevelParentId = orgLevel.parentId;
            _this.reload(masterScheduleFilters, orgLevel, user);
        });
        _this.masterScheduleActions.completeLoading();
        var firstRequest = true;
        _this.dataSubscription = _this.employees$
            .skip(1)
            .subscribe(function (rows) {
            if (!_this.totalsAlreadyLoaded) {
                _this.msData = new MasterScheduleData();
                _this.msData.employees = _this.employeesSelector.getEmployeeRows(rows);
                _this.msData.totals = [
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Total', cell: {} },
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Budgeted PARs', cell: {} },
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'PAR Levels / Ideal Schedule', cell: {} },
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Open Shifts', cell: {} },
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Difference', cell: {} },
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Census', cell: {} },
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'PPD', cell: {} },
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Staffing Ratio', cell: {} },
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Actual Hours', cell: {} },
                    { seniority: 0, secondarySort: 0, sortSequence: 2, recordType: MasterScheduleRecordType.Total, id: 0, key: 'Total', name: 'Actual PPD', cell: {} }
                ];
                _this.msData.subtotals = _this.getFakeSubtotals(_this.msData.employees, _this.currentOrgLevelId, _this.selectedGrouping);
            }
            else {
                _this.msData.employees = _this.employeesSelector.getEmployeeRows(rows);
            }
            _this.onLoaded(_this.msData);
        });
        _this.totalsSubscription = _this.totals$
            .skip(1)
            .withLatestFrom(_this.subtotals$, _this.employees$)
            .subscribe(function (_a) {
            var totals = _a[0], subtotals = _a[1], rows = _a[2];
            _this.totalsAlreadyLoaded = true;
            _this.msData = new MasterScheduleData();
            _this.msData.employees = _this.employeesSelector.getEmployeeRows(rows);
            _this.msData.totals = _this.employeesSelector.getTotals(totals, _this.getUnAccessibleTotals());
            _this.msData.subtotals = _this.employeesSelector.getSubtotals(subtotals);
            _this.onTotalsLoaded$.next(_this.msData);
        });
        _this.errorSubscription = _this.hasError$
            .withLatestFrom(_this.errorMessage$)
            .skip(1)
            .subscribe(function (_a) {
            var hasError = _a[0], errorMessage = _a[1];
            if (hasError) {
                console.error("The error occured during fetch Master Schedule data " + errorMessage);
            }
        });
        return _this;
    }
    MasterScheduleManagementService.prototype.reloadIndividualSchedule = function (hasChanges) {
        if (hasChanges === void 0) { hasChanges = false; }
        if (hasChanges) {
            this.masterScheduleToolbarService.onFiltersChanged(this.filterSettings);
        }
    };
    MasterScheduleManagementService.prototype.getUnAccessibleTotals = function () {
        var unAccessibleTotals = [];
        var timeApp = this.menuAccessibleProviderService.isAccessible('Time');
        if (!timeApp) {
            unAccessibleTotals.push.apply(unAccessibleTotals, [TotalsDescription.totalActualHours, TotalsDescription.totalActualPPD]);
        }
        return unAccessibleTotals;
    };
    MasterScheduleManagementService.prototype.onFiltersRestored = function (filters) {
        this.filtersRestored$.next(filters);
    };
    MasterScheduleManagementService.prototype.getWeeksByDate = function (dateFrom) {
        return this.scheduleCycleHelperService.getWeeksByDate(this.cycles, dateFrom);
    };
    MasterScheduleManagementService.prototype.generateEmpSchedule = function (employeeId, start, end) {
        var _this = this;
        this.masterScheduleActions.generateSchedule();
        return this.api.generateEmpScheduleAtBackend(this.currentOrgLevelId, start, employeeId)
            .then(function (result) {
            _this.masterScheduleActions.generateScheduleSuccess(_.first(result.schedule));
            return result;
        })
            .catch(function (error) {
            _this.masterScheduleActions.generateScheduleError(error);
            return error;
        });
    };
    MasterScheduleManagementService.prototype.reload = function (masterScheduleFilters, orgLevel, user) {
        if (!orgLevel || !orgLevel.id || orgLevel.type !== OrgLevelType.department) {
            return;
        }
        if (!masterScheduleFilters.dateFrom || !user) {
            return;
        }
        this.currentFilters = _.clone(masterScheduleFilters);
        this.currentOrgLevelId = orgLevel.id;
        //this.masterScheduleActions.fetchMasterScheduleData();
        this.totalsAlreadyLoaded = false;
        this.masterScheduleActions.fetchEmployeesSchedule();
        this.masterScheduleActions.fetchTotals();
    };
    MasterScheduleManagementService.prototype.deleteScheduleForEmployee = function (orgLevelId, employeeId, startDate, endDate) {
        var _this = this;
        this.masterScheduleActions.deleteEmployeesSchedule();
        this.api.deleteScheduleForEmployee(orgLevelId, employeeId, startDate, endDate).then(function (value) {
            //this.masterScheduleActions.fetchMasterScheduleData();
            _this.totalsAlreadyLoaded = false;
            _this.masterScheduleActions.fetchEmployeesSchedule();
            _this.masterScheduleActions.fetchTotals();
        }).catch(function (reason) {
            _this.masterScheduleActions.deleteEmployeesScheduleError();
            _this.onError(reason);
        });
    };
    MasterScheduleManagementService.prototype.createRotationFromSchedule = function (orgLevelId, employeeId, startDate, weeks) {
        var _this = this;
        this.masterScheduleActions.createEmployeeRotationFromSchedule();
        this.api.createRotationFromSchedule(orgLevelId, employeeId, startDate, weeks).then(function (value) {
            //this.masterScheduleActions.fetchMasterScheduleData();
            _this.totalsAlreadyLoaded = false;
            _this.masterScheduleActions.fetchEmployeesSchedule();
            _this.masterScheduleActions.fetchTotals();
        }).catch(function (reason) {
            _this.masterScheduleActions.createEmployeeRotationFromScheduleError();
            _this.onError(reason);
        });
    };
    MasterScheduleManagementService.prototype.updateDatesSubscription = function (dateFrom) {
        this.dateFrom = dateFrom;
        var cycle = this.scheduleCycleHelperService.findScheduleCycleByDate(this.cycles, dateFrom);
        if (cycle) {
            this.firstCycleStart = cycle.startDate;
        }
        this.endDate = cycle.endDate.toDate();
        if (this.isStartCycle(dateFrom)) {
            this.onStartCycleSelect$.next(dateFrom);
        }
        else {
            this.onNonStartCycleSelect$.next(dateFrom);
        }
    };
    MasterScheduleManagementService.prototype.isStartCycle = function (date) {
        var diff = moment(date).diff(this.firstCycleStart, 'days');
        if (diff % this.cycleDays === 0)
            return true;
        return false;
    };
    MasterScheduleManagementService.prototype.getScheduleCycle = function (date) {
        return this.findPayCycle(this.cycles, date);
    };
    MasterScheduleManagementService.prototype.findPayCycle = function (cycles, date) {
        var currentDate = moment(date).startOf('day');
        var selectedCycle = _.find(cycles, function (cycle) {
            return currentDate.isSameOrAfter(cycle.startDate) && currentDate.isSameOrBefore(cycle.endDate);
        });
        return selectedCycle;
    };
    MasterScheduleManagementService.prototype.calculateDataRow = function (entryRow) {
        var _this = this;
        var orgLevelId = this.currentOrgLevelId;
        if (entryRow.primaryPosition.orgLevelId !== orgLevelId) {
            entryRow.isFromDifferntDepartment = true;
            var firstPosInDepartment = _.find(entryRow.secondaryPositions, function (p) { return p.orgLevelId === orgLevelId; });
            entryRow.position = EmployeesStateSelector.mapPosition(firstPosInDepartment);
            entryRow.positionGroupName = entryRow.position.positionGroupName;
        }
        if (entryRow.isFromDifferntDepartment) {
            entryRow.secondarySort = 1;
        }
        else {
            entryRow.secondarySort = 0;
        }
        entryRow.weeklyTotals = {};
        if (entryRow && entryRow.hireDateStr) {
            entryRow.hireDate = new Date(entryRow.hireDateStr.replace(/-/g, '/'));
        }
        /*
        if (!subtotals[entryRow.position.name]) {
          subtotals[entryRow.position.name] = new ScheduleSubtotal();
          subtotals[entryRow.position.name].position = new Position();
          subtotals[entryRow.position.name].position.id = entryRow.position.id;
          subtotals[entryRow.position.name].position.name = entryRow.position.name;
          subtotals[entryRow.position.name].position.orgLevelId = entryRow.position.orgLevelId;
          subtotals[entryRow.position.name].dayTotals = {};
        }*/
        _.forEach(entryRow.cell, function (cellDay) {
            var d = moment(cellDay.dateOn);
            var dayName = d.format('dd');
            if (dayName === 'Sat' || dayName === 'Sun') {
                cellDay.holidayDayCode = dayName;
            }
            else {
                cellDay.holidayDayCode = null;
            }
            if (cellDay.isPosted) {
                entryRow.isPosted = true;
            }
            // let dateOn: string = EmployeesStateSelector.getDateKey(cellDay.dateOn);
            cellDay.isScheduledToMultipleShifts = cellDay.shiftsInfo.length > 1;
            _.forEach(cellDay.shiftsInfo, function (shiftInfo) {
                //badges
                if (shiftInfo.position.id !== entryRow.primaryPosition.id
                    && _.some(entryRow.secondaryPositions, function (x) { return x.id === shiftInfo.position.id; })
                    && !shiftInfo.absenceCode) {
                    cellDay.isScheduledToSecondaryPosition = true;
                }
                if (shiftInfo.constraintId) {
                    cellDay.hasConstraint = true;
                }
                if (orgLevelId === shiftInfo.position.orgLevelId) {
                    if (entryRow.isFromDifferntDepartment && entryRow.position.orgLevelId !== orgLevelId) {
                        //need to select first another position from current department
                        entryRow.position = EmployeesStateSelector.mapPosition(shiftInfo.position);
                    }
                    /*
                    if (!subtotals[shiftInfo.position.name]) {
                      subtotals[shiftInfo.position.name] = new ScheduleSubtotal();
                      subtotals[shiftInfo.position.name].position = new Position();
                      subtotals[shiftInfo.position.name].position.id = shiftInfo.position.id;
                      subtotals[shiftInfo.position.name].position.name = shiftInfo.position.name;
                      subtotals[shiftInfo.position.name].position.orgLevelId = shiftInfo.position.orgLevelId;
                      subtotals[shiftInfo.position.name].dayTotals = {};
                    }
                    //subtotals and totals
                    if (!subtotals[shiftInfo.position.name].dayTotals[dateOn]) {
                      subtotals[shiftInfo.position.name].dayTotals[dateOn] = new DayTotal();
                      subtotals[shiftInfo.position.name].dayTotals[dateOn].date = cellDay.dateOn;
                      subtotals[shiftInfo.position.name].dayTotals[dateOn].value = 0;
                    }
                    subtotals[shiftInfo.position.name].dayTotals[dateOn].value += (shiftInfo.duration / 3600000);
                    if (!totals.dayTotals[dateOn]) {
                      totals.dayTotals[dateOn] = new DayTotal();
                      totals.dayTotals[dateOn].date = cellDay.dateOn;
                      totals.dayTotals[dateOn].value = 0;
                    }
                    totals.dayTotals[dateOn].value += (shiftInfo.duration / 3600000);
                    */
                }
                else {
                    cellDay.scheduledInDifferntDepartment = true;
                }
            });
            //weekly totals
            var week = Math.floor(moment(cellDay.dateOn).diff(moment(_this.dateFrom), 'weeks'));
            if (!entryRow.weeklyTotals[week]) {
                entryRow.weeklyTotals[week] = 0;
            }
            var dayTotal = _.reduce(cellDay.shiftsInfo, function (total, shiftInfo) {
                var countAs = shiftInfo.constraintId > 0 ? shiftInfo.constraintCountAs : 1;
                return total + (shiftInfo.duration / 3600000) * countAs;
            }, 0);
            entryRow.weeklyTotals[week] = entryRow.weeklyTotals[week] + dayTotal;
        });
    };
    MasterScheduleManagementService.prototype.calculateData = function (data, separateSecondaryPositions, viewTotalsFTEs, posGroupingType) {
        var _this = this;
        var gridData = new EmployeeGridData();
        gridData.rows = [];
        gridData.subtotals = [];
        gridData.totals = [];
        var rowsExpanded = [];
        //if (separateSecondaryPositions) {
        //  rowsExpanded = this.expandByPosition(data.employees);
        //} else {
        rowsExpanded = data.employees;
        //}
        //calc subtotals and badges flags
        _.forEach(rowsExpanded, function (entryRow) {
            _this.calculateDataRow(entryRow);
            gridData.rows.push(entryRow);
            if (entryRow.isPosted) {
                gridData.isPosted = true;
            }
        });
        _.forEach(data.subtotals, function (subtotal) {
            var subTotalVal = _this.employeesSelector.getSubtotalAfterViewChange(subtotal, viewTotalsFTEs);
            gridData.subtotals = _.concat(gridData.subtotals, subTotalVal);
        });
        _.forEach(data.totals, function (total) {
            var totalVal = _this.employeesSelector.getTotalAfterViewChange(total, _this.getUnAccessibleTotals(), viewTotalsFTEs);
            gridData.totals = _.concat(gridData.totals, totalVal);
        });
        /*
        _.forIn(subtotals, (subtotal: ScheduleSubtotal) => {
          let subTotalRow = EmployeesStateSelector.mapMasterScheduleSubtotalRow(subtotal);
          gridData.rows.push(subTotalRow);
        });
        let totalRow = EmployeesStateSelector.mapMasterScheduleTotalRow(totals);
        gridData.totals = _.concat(data.totals, totalRow);
        */
        var eligibleSubtotals;
        if (!posGroupingType || posGroupingType.id === MasterScheduleGroupingType.BYPOSITION) {
            eligibleSubtotals = _.filter(gridData.subtotals, function (subTotalRow) {
                return (!subTotalRow.position) || _.findIndex(gridData.rows, function (row) {
                    return row.position.name === subTotalRow.position.name;
                }) !== -1;
            });
        }
        else {
            eligibleSubtotals = _.filter(gridData.subtotals, function (subTotalRow) {
                return (!subTotalRow.positionGroupName) || _.findIndex(gridData.rows, function (row) {
                    return row.positionGroupName === subTotalRow.positionGroupName;
                }) !== -1;
            });
        }
        gridData.rows = _.concat(gridData.rows, eligibleSubtotals);
        // gridData.totals = _.concat(data.totals);
        this.onRecalculated$.next(gridData);
        return gridData;
    };
    MasterScheduleManagementService.prototype.getBudgetedParsDetails = function (dateOn) {
        var posIds = this.currentFilters.filters.position ? [this.currentFilters.filters.position.id] : [];
        var detailsPromise = this.api.getBudgetedParsDetails(this.currentOrgLevelId, dateOn, posIds);
        var positionsPromise = this.lookupApiService.getBudgetedPositions(0, this.currentOrgLevelId);
        return Promise.all([detailsPromise, positionsPromise])
            .then(function (value) {
            var container = value[0];
            var posDict = {};
            _.each(value[1], function (pos) { return posDict[pos.id] = pos.description; });
            _.each(container.details, function (row) { return row.positionName = posDict[row.positionId]; });
            return container;
        });
    };
    MasterScheduleManagementService.prototype.selectEmployeeCell = function (cellInfo) {
        this.onEmployeeCellSelected$.next(cellInfo);
    };
    MasterScheduleManagementService.prototype.getFakeSubtotals = function (employeeScheduleRows, orgLevelId, grouping) {
        var _this = this;
        var allEmployeePrimaryPositions = _.filter(_.map(employeeScheduleRows, function (row) {
            var positionGroupPair = {};
            positionGroupPair.position = row.position;
            positionGroupPair.positionGroupName = row.positionGroupName;
            positionGroupPair.row = row;
            return positionGroupPair;
        }), function (pos) { return pos.position.orgLevelId === orgLevelId; });
        var allSecondaryPositions = _.filter(_.flatten(_.map(employeeScheduleRows, function (toFlat) {
            return _.map(toFlat.secondaryPositions, (function (secPos) {
                var positionGroupPair = {};
                positionGroupPair.position = secPos;
                positionGroupPair.positionGroupName = toFlat.positionGroupName;
                return positionGroupPair;
            }));
        })), function (pos) { return pos.position.orgLevelId === orgLevelId; });
        var allEmployeePositions = [].concat(allEmployeePrimaryPositions, allSecondaryPositions);
        var groupedEmployeePositions;
        if (grouping.id === MasterScheduleGroupingTypes.ByPositionGroup.id) {
            groupedEmployeePositions = _.groupBy(allEmployeePositions, function (positionGroupPair) { return positionGroupPair.positionGroupName; });
        }
        else {
            groupedEmployeePositions = _.groupBy(allEmployeePositions, function (positionGroupPair) { return positionGroupPair.position.id; });
        }
        var uniqueEmployeePositions = _.map(groupedEmployeePositions, function (positions, positionId) { return positions[0]; });
        var rows = [];
        var row;
        _.forIn(uniqueEmployeePositions, function (e, key) {
            row = _this.getFakeSubtotalRow(e);
            rows.push(row);
        });
        return rows;
    };
    MasterScheduleManagementService.prototype.getFakeSubtotalRow = function (e) {
        var row = new MasterScheduleSubtotalRow();
        row.id = 0;
        row.name = 'Subtotal';
        row.recordType = MasterScheduleRecordType.Subtotal;
        row.sortSequence = 0;
        row.positionGroupName = e.positionGroupName;
        row.position = e.position ? EmployeesStateSelector.mapPosition(e.position) : null;
        row.cell = {};
        return row;
    };
    MasterScheduleManagementService.prototype.expandByPosition = function (rows) {
        var rowsExpandedMap = {};
        _.forEach(rows, function (entryRow) {
            if (!rowsExpandedMap[entryRow.id]) {
                rowsExpandedMap[entryRow.id] = {};
            }
            rowsExpandedMap[entryRow.id][entryRow.position.id] = EmployeesSelector.restoreMasterScheduleEntryRow(entryRow);
            rowsExpandedMap[entryRow.id][entryRow.position.id].secondarySort = 0;
            _.forEach(entryRow.cell, function (cellDay) {
                var cellMap = {};
                _.forEach(cellDay.shiftsInfo, function (shiftInfo) {
                    if (!rowsExpandedMap[entryRow.id][shiftInfo.position.id]) {
                        rowsExpandedMap[entryRow.id][shiftInfo.position.id] = EmployeesSelector.restoreMasterScheduleEntryRow(entryRow);
                        rowsExpandedMap[entryRow.id][shiftInfo.position.id].position = EmployeesStateSelector.mapPosition(shiftInfo.position);
                        rowsExpandedMap[entryRow.id][shiftInfo.position.id].secondarySort = 1;
                    }
                    var day = EmployeesStateSelector.getDateKey(cellDay.dateOn);
                    if (!cellMap[shiftInfo.position.id]) {
                        cellMap[shiftInfo.position.id] = EmployeesSelector.restoreMasterScheduleEntryCell(cellDay);
                        rowsExpandedMap[entryRow.id][shiftInfo.position.id].cell[day] = cellMap[shiftInfo.position.id];
                        if (shiftInfo.position.id !== entryRow.position.id && _.some(entryRow.secondaryPositions, function (x) { return x.id === shiftInfo.position.id; })) {
                            //if employee scheduled on secondary position we should add cell to show budge
                            if (!rowsExpandedMap[entryRow.id][entryRow.position.id].cell[day]) {
                                rowsExpandedMap[entryRow.id][entryRow.position.id].cell[day] = EmployeesSelector.restoreMasterScheduleEntryCell(cellDay);
                                rowsExpandedMap[entryRow.id][entryRow.position.id].cell[day].shiftsInfo = [];
                            }
                            rowsExpandedMap[entryRow.id][entryRow.position.id].cell[day].isScheduledToSecondaryPosition = true;
                        }
                    }
                    var info = EmployeesSelector.restoreMasterScheduleShiftInfo(shiftInfo);
                    rowsExpandedMap[entryRow.id][shiftInfo.position.id].cell[day].shiftsInfo.push(info);
                });
            });
        });
        var rowsExpanded = [];
        _.forIn(rowsExpandedMap, function (em) {
            _.forIn(em, function (r) {
                rowsExpanded.push(r);
            });
        });
        return rowsExpanded;
    };
    MasterScheduleManagementService.firstLoad = true;
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "sessionUser$", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'employees']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "employees$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'totals']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "totals$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'subtotals']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "subtotals$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'actions']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "actions$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'isLoading']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "isLoading$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'isLoadingTotals']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "isLoadingTotals$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'hasError']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "hasError$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'errorMessage']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleManagementService.prototype, "errorMessage$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleManagementService.prototype, "dataSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleManagementService.prototype, "totalsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleManagementService.prototype, "filterSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleManagementService.prototype, "statusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleManagementService.prototype, "statusTotalsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleManagementService.prototype, "errorSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleManagementService.prototype, "filterEchoSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleManagementService.prototype, "orgLevelSubscription", void 0);
    return MasterScheduleManagementService;
}(ManagementBaseService));
export { MasterScheduleManagementService };
