import { ShiftSwapManageService } from './../../../services/shift-swap/shift-swap-manage.service';
import { ShiftSwapInfo } from '../../../models/shift-swap/shift-swap-info';
import { EventEmitter } from '@angular/core';
var ShiftSwapApproveDenyComponent = /** @class */ (function () {
    function ShiftSwapApproveDenyComponent(manageService) {
        this.manageService = manageService;
        this.comment = '';
        this.buttonClicked = new EventEmitter();
    }
    Object.defineProperty(ShiftSwapApproveDenyComponent.prototype, "shiftSwap", {
        get: function () {
            return this.m_shiftSwap;
        },
        set: function (value) {
            this.m_shiftSwap = value;
            if (value) {
                this.loadDetails();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftSwapApproveDenyComponent.prototype, "statusStyle", {
        get: function () {
            if (!this.shiftSwap) {
                return 'status-pending';
            }
            return 'status-' + this.shiftSwap.status.toLowerCase();
        },
        enumerable: true,
        configurable: true
    });
    ShiftSwapApproveDenyComponent.prototype.onColumnsChanged = function () {
        this.manageService.recalculateColumns();
    };
    ShiftSwapApproveDenyComponent.prototype.denyClick = function () {
        var _this = this;
        this.manageService.deny(this.shiftSwap, this.comment).then(function (value) {
            _this.buttonClicked.emit(2);
        });
    };
    ShiftSwapApproveDenyComponent.prototype.approveClick = function () {
        var _this = this;
        this.manageService.approve(this.shiftSwap, this.comment).then(function (value) {
            _this.buttonClicked.emit(1);
        });
    };
    ShiftSwapApproveDenyComponent.prototype.closeClick = function () {
        this.buttonClicked.emit(0);
    };
    ShiftSwapApproveDenyComponent.prototype.getDetailsProperty = function (propName, from) {
        if (!this.details) {
            return 'Loading...';
        }
        var emp = from ? this.details.submittedBy : this.details.submittedTo;
        return emp ? emp[propName] : '-';
    };
    ShiftSwapApproveDenyComponent.prototype.loadDetails = function () {
        var _this = this;
        this.manageService.loadDetails(this.shiftSwap).then(function (value) {
            _this.details = value;
        });
    };
    return ShiftSwapApproveDenyComponent;
}());
export { ShiftSwapApproveDenyComponent };
