import { dateTimeUtils } from './../../../common/utils/dateTimeUtils';
import { Injectable } from '@angular/core';
import { Dictionary } from 'lodash';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CalendarDataService } from '../../../core/services/index';
import { LookupMapService } from '../../../organization/services/index';
import { Assert } from '../../../framework/index';
import {
  IOpenShiftShortDetails, OpenShiftShortDetails,
  OpenShiftShortDetailsSetWrapper, OpenShiftShortDetailsWrapper,
  IOpenShiftSummary, OpenShiftSummary,
  IOpenShiftDetails, OpenShiftDetails,
  IEmployeeMessage, EmployeeMessage, MessagesThread,
  OpenShiftShortDetailsContainer,
  EmployeeMessageState, EmployeeMessageSource, EmployeeMessageDirection} from '../../models/index';

@Injectable()
export class OpenShiftManagementMapService {
  private lookupMapService: LookupMapService;
  private calendarDataService: CalendarDataService;

  constructor(lookupMapService: LookupMapService,
    calendarDataService: CalendarDataService) {
    this.lookupMapService = lookupMapService;
    this.calendarDataService = calendarDataService;
  }

  public mapOpenShiftShortDetailsSetToWrapper(dto: OpenShiftShortDetailsContainer): OpenShiftShortDetailsSetWrapper {
    Assert.isNotNull(dto, 'dto');
    let mappedDetails: OpenShiftShortDetails[] = this.mapOpenShiftShortDetailsSet(dto.entities);
    let detailsWrapper: OpenShiftShortDetailsSetWrapper = this.mapOpenShiftShortDetailsModelSetToWrapper(mappedDetails);
    detailsWrapper.isPosted = dto.isPosted;
    detailsWrapper.postedByUserId = dto.postedByUserId;
    detailsWrapper.postedByUserLogin = dto.postedByUserLogin;
    detailsWrapper.postDate = dateTimeUtils.convertFromDtoDateTimeString(dto.postDate);
    detailsWrapper.canEditOpenShiftCount = dto.canEditOpenShiftCount;
    detailsWrapper.canPostSchedule = dto.canPostSchedule;
    detailsWrapper.canApproveDenyPartnerShiftCount= dto.canApproveDenyPartnerShiftCount;
    detailsWrapper.canSendSMS = dto.canSendSMS;
    return detailsWrapper;
  }

  public mapOpenShiftDetailsSummary(dto: IOpenShiftSummary): OpenShiftSummary {
    Assert.isNotNull(dto, 'dto');
    let result: OpenShiftSummary = new OpenShiftSummary();
    result.dateOn = dto.dateOn;
    result.canEditScheduleApprovedPeriod = dto.canEditScheduleApprovedPeriod;
    result.details = this.mapOpenShiftsDetails(dto.details);
    return result;
  }

  private mapOpenShiftShortDetails(dto: IOpenShiftShortDetails): OpenShiftShortDetails {
    Assert.isNotNull(dto, 'dto');
    let data: OpenShiftShortDetails = new OpenShiftShortDetails();
    data.dateOn = dto.dateOn;
    data.messageCount = dto.messageCount;
    data.openShiftCount = dto.openShiftCount;
    data.overShiftCount = dto.overShiftCount;    
    data.partnerShiftCount= dto.partnerShiftCount;    
    return data; 
  }

  private mapOpenShiftShortDetailsSet(dto: IOpenShiftShortDetails[]): OpenShiftShortDetails[] {
    Assert.isNotNull(dto, 'dto');
    return _.map(dto, (record: IOpenShiftShortDetails) => {
      return this.mapOpenShiftShortDetails(record);
    });
  }

  private mapOpenShiftShortDetailsModelSetToWrapper(data: OpenShiftShortDetails[]): OpenShiftShortDetailsSetWrapper {
    Assert.isNotNull(data, 'data');
    let result: OpenShiftShortDetailsSetWrapper = new OpenShiftShortDetailsSetWrapper();

    let startDate: Date = _.min(_.map(data, (item: OpenShiftShortDetails) => item.dateOn));
    let endDate: Date = _.max(_.map(data, (item: OpenShiftShortDetails) => item.dateOn));

    let mapByDate: _.Dictionary<OpenShiftShortDetails[]> = _.groupBy(data, (item: OpenShiftShortDetails) => {
      return moment(item.dateOn).startOf('day').unix();
    });

    let weekCount: number = Math.ceil(moment(endDate).diff(moment(startDate), 'days') / 7);
    let dayCount: number = weekCount * 7;
    let calendarDate: moment.Moment = moment(startDate);
    let entities: OpenShiftShortDetailsWrapper[] = [];
    _.times(dayCount, () => {
      let items: OpenShiftShortDetails[] = mapByDate[calendarDate.unix()];
      let item: OpenShiftShortDetails = items && items.length ? items[0] : null;
      let subResult: OpenShiftShortDetailsWrapper = new OpenShiftShortDetailsWrapper();
      subResult.date = item && item.dateOn ? item.dateOn : calendarDate.toDate();
      subResult.details = item;
      calendarDate = calendarDate.add(1, 'd');
      entities.push(subResult);
    });

    result.weeklyData = this.calendarDataService.ExtendRangeToWeeklyDataByStartDate(moment(startDate), weekCount, entities);
    return result;
  }


  private mapOpenShiftDetails(details: IOpenShiftDetails): OpenShiftDetails {
    Assert.isNotNull(details, 'details');
    let result: OpenShiftDetails = new OpenShiftDetails();
    result.dateOn = details.dateOn;
    result.position = this.lookupMapService.mapPosition(details.position);
    result.unit = this.lookupMapService.mapLocationUnit(details.unit);
    result.shift = this.lookupMapService.mapShiftDefinition(details.shift);
    result.messages = this.mapOpenShiftDetailsMessages(details.messages);
    result.messagesThreads = this.mapOpenShiftDetailsMessagesThreadByOutbound(result.messages);
    result.openShiftCount = +details.openShiftCount;
    result.overShiftCount = 0;
    result.adjustedShiftCount = +details.adjustedShiftCount;
    result.idealShiftCount = +details.idealShiftCount;
    result.scheduledShiftCount = +details.scheduledShiftCount;
    result.openHours = +details.openHours;
    result.adjustedHours = +details.adjustedHours;
    result.idealHours = +details.idealHours;
    result.scheduledHours = +details.scheduledHours;
    result.workTimeHours = details.workTimeHours;
    result.shiftGroupName = details.shiftGroup ? details.shiftGroup.name : '';
    result.partnerShiftId = details.partnerShiftId;
    result.overScheduleCount = +details.overScheduleCount;
    result.partnerMessages = this.mapOpenShiftDetailsMessages(details.partnerMessages);
    result.parentShiftId = details.parentShiftId;
    result.hasPartialShift = details.hasPartialShift;
    result.calculatedOpenShiftCount = details.calculatedOpenShiftCount;
    return result;
  }

  private mapOpenShiftDetailsMessages(messages: IEmployeeMessage[]): EmployeeMessage[] {
    Assert.isNotNull(messages, 'messages');
    let mappedMesssages: EmployeeMessage[] = _.map(messages, (record: IEmployeeMessage) => {
      return this.mapOpenShiftDetailsMessage(record);
    });
    mappedMesssages.sort((first: EmployeeMessage, second: EmployeeMessage): number => {
      return moment(first.timestamp).diff(moment(second.timestamp));
    });

    return mappedMesssages;
  }

  private mapOpenShiftDetailsMessagesThreadByOutbound(messages: EmployeeMessage[]): MessagesThread[] {
    Assert.isNotNull(messages, 'messages');

    let outboundMessagesBySenders: Dictionary<EmployeeMessage[]> = _.groupBy(_.filter(messages, function (message: EmployeeMessage): boolean {
      return message.isOutbound;
    }), (message: EmployeeMessage) => {
      return message.receiverId;
    });

    let inboundMessages: EmployeeMessage[] = _.filter(messages, function (message: EmployeeMessage): boolean {
      return message.isInbound;
    });

    let outboundMessages: EmployeeMessage[] = _.filter(messages, function (message: EmployeeMessage): boolean {
      return message.isOutbound;
    });


    let result: MessagesThread[] = _.map(outboundMessagesBySenders, (group: EmployeeMessage[]): MessagesThread => {
      let intersected: EmployeeMessage[] = _.intersectionWith(inboundMessages, group, (first: EmployeeMessage, second: EmployeeMessage): boolean => {
        return first.senderId === second.receiverId;
      });
      return intersected.length > 0 ? this.createMessagesThread([...group, ...intersected]): undefined;
    }).filter((thread: MessagesThread): boolean => !!thread);

    let differenced: EmployeeMessage[] = _.differenceWith(outboundMessages, inboundMessages, (first: EmployeeMessage, second: EmployeeMessage): boolean => {
      return first.receiverId === second.senderId;
    });

    _.forEach(differenced, (message: EmployeeMessage): void => {
      let differencedThread: MessagesThread = this.createMessagesThread([message]);
      result.push(differencedThread);
    });

    result.sort((first: MessagesThread, second: MessagesThread): number => {
      return second.messages.length - first.messages.length;
    });

    return result;
  }

  private mapOpenShiftDetailsMessagesThreadByInbound(messages: EmployeeMessage[]): MessagesThread[] {
    Assert.isNotNull(messages, 'messages');

    let inboundMessagesBySenders: Dictionary<EmployeeMessage[]> = _.groupBy(_.filter(messages, function (message: EmployeeMessage): boolean {
      return message.isInbound;
    }), (message: EmployeeMessage) => {
      return message.senderId;
    });

    let inboundMessages: EmployeeMessage[] = _.filter(messages, function (message: EmployeeMessage): boolean {
      return message.isInbound;
    });

    let outboundMessages: EmployeeMessage[] = _.filter(messages, function (message: EmployeeMessage): boolean {
      return message.isOutbound;
    });


    let result: MessagesThread[] = _.map(inboundMessagesBySenders, (group: EmployeeMessage[]): MessagesThread => {
      let intersected: EmployeeMessage[] = _.intersectionWith(outboundMessages, group, (first: EmployeeMessage, second: EmployeeMessage): boolean => {
        return first.senderId === second.receiverId;
      });
      return this.createMessagesThread(_.union(group, intersected));
    });

    let differenced: EmployeeMessage[] = _.differenceWith(outboundMessages, inboundMessages, (first: EmployeeMessage, second: EmployeeMessage): boolean => {
      return first.receiverId === second.senderId;
    });

    let differencedThread: MessagesThread = this.createMessagesThread(differenced);
    result.push(differencedThread);

    result.sort((first: MessagesThread, second: MessagesThread): number => {
      return moment(first.firstMessageTimestamp).diff(moment(second.firstMessageTimestamp));
    });

    return result;
  }

  private createMessagesThread(messages: EmployeeMessage[]): MessagesThread {
    let thread: MessagesThread = new MessagesThread();
    thread.id = 0;
    let firstMessage: EmployeeMessage = _.minBy(messages, (message: EmployeeMessage): Date => {
      return message.timestamp;
    });

    if (firstMessage) {
      thread.firstMessageTimestamp = firstMessage.timestamp;
    }

    thread.messages = _.orderBy(messages, (message: EmployeeMessage): Date => {
      return message.timestamp;
    }, 'asc');
    return thread;
  }

  private mapOpenShiftDetailsMessage(message: IEmployeeMessage): EmployeeMessage {
    Assert.isNotNull(message, 'message');
    let result: EmployeeMessage = new EmployeeMessage();
    let direction: EmployeeMessageDirection = this.parseEmployeDireciton(message.direction);
    Assert.isNotNull(direction, 'direction');
    result.direction = direction;

    let source: EmployeeMessageSource = this.parseEmployeSource(message.source);
    Assert.isNotNull(source, 'source');
    result.source = source;

    let state: EmployeeMessageState = this.parseEmployeState(message.state);
    Assert.isNotNull(state, 'state');
    result.state = state;
    result.stateName = message.state;

    result.id = message.id;
    result.receiverId = message.receiverId;
    result.receiverName = message.receiverName;
    result.senderId = message.senderId;
    result.senderName = message.senderName;
    result.text = message.text;
    result.timestamp = message.timestamp;
    result.senderMobilePhone = message.senderMobilePhone;
    
    //Agency employee 
    result.partnerAgencyName=message.partnerAgencyName;
    result.partnerShiftId=message.partnerShiftId;
    result.partnerShiftCount = message.partnerShiftCount;
    result.partnerId = message.partnerId;
    result.isPositionGroupInd = message.isPositionGroupInd;
    return result;
  }

  public mapOpenShiftsDetails(detailsSet: IOpenShiftDetails[]): OpenShiftDetails[] {
    Assert.isNotNull(detailsSet, 'detailsSet');
    return _.map(detailsSet, (record: IOpenShiftDetails) => {
      return this.mapOpenShiftDetails(record);
    });
  }

  private parseEmployeDireciton(direction: string): EmployeeMessageDirection {
    if (direction.toLowerCase() === 'inbound') {
      return EmployeeMessageDirection.inbound;
    }
    if (direction.toLowerCase() === 'outbound') {
      return EmployeeMessageDirection.outbound;
    }
    return null;
  }

  private parseEmployeSource(source: string): EmployeeMessageSource {
    if (source.toLowerCase() === 'sms') {
      return EmployeeMessageSource.sms;
    }
    if (source.toLowerCase() === 'system') {
      return EmployeeMessageSource.system;
    }
    return null;
  }

  private parseEmployeState(state: string): EmployeeMessageState {
    if (state.toLowerCase() === 'new') {
      return EmployeeMessageState.new;
    }
    if (state.toLowerCase() === 'read') {
      return EmployeeMessageState.read;
    }
    if (state.toLowerCase() === 'closed') {
      return EmployeeMessageState.closed;
    }
    if (state.toLowerCase() === 'reopened') {
      return EmployeeMessageState.reopened;
    }
    if (state.toLowerCase() === 'requested') {
      return EmployeeMessageState.requested;
    }
    if (state.toLowerCase() === 'scheduled') {
      return EmployeeMessageState.scheduled;
    }
    if (state.toLowerCase() === 'ignored') {
      return EmployeeMessageState.ignored;
    }
    return null;
  }
}
