import { LicenseType, ILicenseType } from './../../../organization/models/lookup/license-type';
import { Injectable } from '@angular/core';
import { trim } from 'lodash';
@Injectable()
export class LicenseTypesMapService {

  public mapLicenseTypes(dtos: ILicenseType[]): LicenseType[] {
      return dtos.map(x => this.mapLicenseType(x));
  }

  public mapLicenseType(dto: ILicenseType): LicenseType {
      const type: LicenseType = new LicenseType();
      type.confirmingBody = dto.confirmingBody;
      type.licenseTypeID = dto.licenseTypeID;
      type.licenseTypeName = dto.licenseTypeName;
      type.region = dto.region;
      return type;
  }

  public mapLicenseTypeDto(type: LicenseType): ILicenseType {
    const dto: ILicenseType = {
      confirmingBody: type.confirmingBody,
      licenseTypeID: type.licenseTypeID,
      licenseTypeName: trim(type.licenseTypeName),
      region: type.region
    };
    return dto;
  }
}

