import { Component, OnDestroy, Input, NgZone, Host, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { GridDataResult, GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';

import * as _ from 'lodash';

import { EmployeeSectionsDocuments, EmployeeSectionsBase, EmployeeDocument } from '../../../models/index';
import { EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { KendoGridStateHelper, FileService, ConfirmDialogComponent, ModalService, saveEvent, removeEvent, cancelEvent, ConfirmOptions } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { FileBlobResponse } from '../../../../../core/models/api/file-blob-response';
import { LookupService, Lookup, LookupType, EmployeeDocumentCategory } from '../../../../../organization/index';
import { AppSettingsManageService } from '../../../../../app-settings/services/index';
import { AppServerConfig } from '../../../../../app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-documents',
  templateUrl: 'employee-sections-documents.component.html',
  styleUrls: ['employee-sections-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsDocumentsComponent extends EmployeeSectionsBasicComponent implements OnDestroy {

  @Input('employeeSectionsDocuments')
  public set documents(employeeSectionsDocuments: EmployeeSectionsDocuments) {
    this.employeeSectionsDocuments = employeeSectionsDocuments;
    this.refreshGrid();
    this.updateLookups();
  }
  @Input() public employeeId: number;

  public get isEditable(): boolean {
    return this.decorator.isEditableWhenEmpTerminated;
  }

  public get form(): AbstractControl {
    return null;
  }

  public get fileSizeLimitInMB(): string {
    return `${Math.round(this.fileSizeLimit / 1024)}MB`;
  }

  public gridState: KendoGridStateHelper<EmployeeDocument>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public employeeSectionsDocuments: EmployeeSectionsDocuments;
  public categoriesLookup: EmployeeDocumentCategory[] = [];
  public isFileValid: boolean;
  public fileSizeLimit: number;

  private employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService;
  private canShowFileName: boolean = false;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  @unsubscribe()
  private gridSaveSubscription: Subscription;

  @unsubscribe()
  private gridRemoveSubscription: Subscription;

  constructor(
    private fileService: FileService,
    private modalService: ModalService,
    private lookupService: LookupService,
    private changeDetector: ChangeDetectorRef,
    private appSettingsManageService: AppSettingsManageService,
    employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone
  ) {
    super(decorator, ngZone);
    decorator.isEditableByConfiguration = false;
    this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;

    this.gridState = new KendoGridStateHelper<EmployeeDocument>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'addedOn', dir: 'desc' }];

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });

    this.gridState.onEdit$.subscribe((item: EmployeeDocument): void => {
      this.isFileValid = true;
      this.canShowFileName = true;
    });

    this.gridState.onCancel$.subscribe((item: cancelEvent<EmployeeDocument>): void => {
      this.isFileValid = false;
      this.canShowFileName = false;
    });

    this.gridSaveSubscription = this.gridState.onSave$.subscribe((item: saveEvent<EmployeeDocument>): void => {
      if (item.isNew) {
        this.doAdd(item.dataItem);
        this.gridState.closeEditor(this.grid);
        this.isFileValid = false;
        this.canShowFileName = false;
      } else {
        this.doUpdate(item.dataItem);
        this.gridState.closeEditor(this.grid);
      }
    });

    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe((item: removeEvent<EmployeeDocument>): void => {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      ConfirmDialogComponent.openDialog(
        'Confirmation',
        'Are you sure that you want to remove this document?',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.doRemove(item.dataItem);
          }
        }, options);
    });

    this.appSettingsManageService.getAppServerConfig()
      .then((conf: AppServerConfig) => {
        this.fileSizeLimit = conf.maxFileSizeLimit;
      });

    this.updateLookups();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsDocuments;
  }

  public onDocumentFileClick(documentId: number): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.getDocumentFile(documentId)
      .then((data: FileBlobResponse): void => this.fileService.saveToFileSystem(data.blob, data.file))
      .finally(() => this.stopProgress());
  }

  public onFileClick(event: any, item: EmployeeDocument): void {
    const elem: HTMLInputElement = event.target;
    elem.value = '';
    item.file = null;
    this.isFileValid = false;
  }

  public onFileChange(event: Event, item: EmployeeDocument, fileInput: HTMLInputElement): void {
    this.canShowFileName = false;
    const files: File[] = _.get(event, 'target.files');
    const file: File = files[0];

    if (!file) {
      return;
    }

    item.file = file;
    item.fileName = file.name;
    item.name = file.name;

    this.validateFile(file.size, item, fileInput);
  }

  public canEditItem(item: EmployeeDocument): boolean {
    if (!item || !item.category) {
      return true;
    }

    const categoryMeta = _.find(
      this.employeeSectionsDocuments.fieldsMeta.fields,
      field => field.fieldName.toLowerCase() === item.category.description.toLowerCase()
    );

    if (!categoryMeta) {
      return true;
    }

    return categoryMeta.access.toLowerCase() === 'full';
  }

  public onStartAdd(): void {
    this.gridState.closeEditor(this.grid);
    const document: EmployeeDocument = new EmployeeDocument();
    this.grid.addRow(document);
    this.isFileValid = false;
  }

  protected doAdd(document: EmployeeDocument): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.addDocument(document, this.employeeId)
      .then((status: any) => {
        this.loadSubsection();
      }).catch((error: any) => {
        this.loadSubsection();
      });
  }

  protected doUpdate(document: EmployeeDocument): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.editDocument(document, this.employeeId)
      .then((status: any) => {
        this.loadSubsection();
      })
      .catch((error: any) => {
        this.loadSubsection();
      });
  }

  protected doRemove(document: EmployeeDocument): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.deleteDocument(document.id, this.employeeId)
      .then((status: any) => {
        this.loadSubsection();
      }).catch((error: any) => {
        this.loadSubsection();
      });
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.getPersonalDocuments(this.employeeId)
      .then((employeeSectionsDocuments: EmployeeSectionsDocuments) => {
        this.employeeSectionsDocuments = employeeSectionsDocuments;
        this.refreshGrid();
        this.stopProgress();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  private validateFile(fileSize: number, item: EmployeeDocument, fileInput: HTMLInputElement): void {
    if (!fileSize) {
      fileInput.value = null;
      item.name = '';
      this.isFileValid = true;
      return;
    }

    const fileSizeInKbytes = fileSize / 1024;
    this.isFileValid = fileSizeInKbytes <= this.fileSizeLimit;

    setTimeout(() => {
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }, 0);

  }

  private refreshGrid(): void {
    if (!this.employeeSectionsDocuments) {
      this.gridView = null;
      return;
    }

    this.gridState.view = process(this.employeeSectionsDocuments.documents, this.gridState.state);
  }

  private updateLookups(): void {
    this.lookupService.getLookup({
      lookupType: LookupType.employeeDocumentCategories
    }).then((lookup: Lookup) => {
      let categories: EmployeeDocumentCategory[] = [];

      if (this.employeeSectionsDocuments && this.employeeSectionsDocuments.fieldsMeta) {
        _.forEach(<EmployeeDocumentCategory[]>lookup.items, (item: EmployeeDocumentCategory) => {
          if (!item || !item.description) {
            return;
          }

          const categoryMeta = _.find(
            this.employeeSectionsDocuments.fieldsMeta.fields,
            field => field.fieldName.toLowerCase() === item.description.toLowerCase()
          );

          if (!categoryMeta) {
            return;
          }

          if (categoryMeta.access.toLowerCase() === 'full') {
            categories.push(item);
          }
        });
      }

      this.categoriesLookup = categories;
    });
  }
}
