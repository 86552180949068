/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./weather-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../app-modules/angular-weather-widget/components/weather-container/weather-container.component.ngfactory";
import * as i3 from "../../../app-modules/angular-weather-widget/components/weather-container/weather-container.component";
import * as i4 from "../../../app-modules/angular-weather-widget/services/api/weather.api.service";
import * as i5 from "@angular/common";
import * as i6 from "./weather-widget.component";
var styles_WeatherWidgetComponent = [i0.styles];
var RenderType_WeatherWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WeatherWidgetComponent, data: {} });
export { RenderType_WeatherWidgetComponent as RenderType_WeatherWidgetComponent };
function View_WeatherWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "weather-widget", [["class", "weather-widget"]], null, null, null, i2.View_WeatherContainerComponent_0, i2.RenderType_WeatherContainerComponent)), i1.ɵdid(1, 180224, null, 0, i3.WeatherContainerComponent, [i4.WeatherApiService, i1.ChangeDetectorRef, i1.Renderer2, i1.ElementRef], { settings: [0, "settings"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WeatherWidgetComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeatherWidgetComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.weatherExists && !_co.screenUtils.isMobile); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WeatherWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-weather-widget", [], null, null, null, View_WeatherWidgetComponent_0, RenderType_WeatherWidgetComponent)), i1.ɵdid(1, 49152, null, 0, i6.WeatherWidgetComponent, [i1.Renderer2, i1.ElementRef], null, null)], null, null); }
var WeatherWidgetComponentNgFactory = i1.ɵccf("slx-weather-widget", i6.WeatherWidgetComponent, View_WeatherWidgetComponent_Host_0, { orgLevelLocation: "orgLevelLocation" }, {}, []);
export { WeatherWidgetComponentNgFactory as WeatherWidgetComponentNgFactory };
