import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Unit } from '../../../models/index';
import { CustomListActorBase } from '../../../components/editableList/custom-list-actor.base';
import { EditableListActionKind } from '../../../models/index';
import { appMessages } from '../../../../app.messages';
import { UnitsConfigurationManagementService } from '../../../services/index';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var LocationUnitEditorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LocationUnitEditorComponent, _super);
    function LocationUnitEditorComponent(management) {
        var _this = _super.call(this) || this;
        _this.management = management;
        _this.prohibitedDescriptionValues = [];
        _this.prohibitedNameValues = [];
        _this.appMessages = appMessages;
        return _this;
    }
    Object.defineProperty(LocationUnitEditorComponent.prototype, "item", {
        get: function () {
            return this.origItem;
        },
        set: function (value) {
            this.origItem = value;
            this.m_editItem = new Unit();
            this.copyFields(value, this.m_editItem);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocationUnitEditorComponent.prototype, "editItem", {
        get: function () {
            return this.m_editItem;
        },
        enumerable: true,
        configurable: true
    });
    LocationUnitEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(function () {
            if (_this.mainForm.dirty) {
                _this.management.markAsDirty();
            }
        });
    };
    LocationUnitEditorComponent.prototype.onSaveClick = function (event) {
        event.preventDefault();
        this.copyFields(this.m_editItem, this.origItem);
        if (this.mainFormSubscription) {
            this.mainFormSubscription.unsubscribe();
        }
        this.actionEmitter.emit(EditableListActionKind.COMPLETE_EDIT);
    };
    LocationUnitEditorComponent.prototype.onCancelClick = function (event) {
        event.preventDefault();
        if (this.mainFormSubscription) {
            this.mainFormSubscription.unsubscribe();
        }
        this.actionEmitter.emit(EditableListActionKind.CANCEL_EDIT);
    };
    LocationUnitEditorComponent.prototype.copyFields = function (from, to) {
        to.name = from.name;
        to.description = from.description;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LocationUnitEditorComponent.prototype, "mainFormSubscription", void 0);
    return LocationUnitEditorComponent;
}(CustomListActorBase));
export { LocationUnitEditorComponent };
