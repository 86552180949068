import { Directive, Input, Output, EventEmitter, OnInit, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ServerFilterService } from '../../../../core/services/index';
import { ServerFilterDescriptor } from '../../../../core/models/index';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import * as _ from 'lodash';
@Directive({
  selector: '[slxKendoGridServerFilter]',
})
export class KendoGridServerFilterDirective {

  constructor(private serverFilterService: ServerFilterService) {
  }

  public filterChanged(filter: CompositeFilterDescriptor): void {
    let f: ServerFilterDescriptor[] = kendoUtils.convertFromKendoComposeFilterFlat(filter);
    _.forEach(f, (sf: ServerFilterDescriptor) => this.serverFilterService.composeFilter(sf));
    this.serverFilterService.applyFilter();
  }
  public removeFilter(filter: string): void {
    this.serverFilterService.removeFilter(filter);
    this.serverFilterService.applyFilter();
  }
}
