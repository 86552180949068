import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
import * as _ from 'lodash';
var EmployeeWeeklyAvailability = /** @class */ (function () {
    function EmployeeWeeklyAvailability() {
        this.days = [];
    }
    Object.defineProperty(EmployeeWeeklyAvailability.prototype, "hours", {
        get: function () {
            return _.reduce(this.days, function (result, day) {
                return (result || []).concat(day.hours);
            }, []);
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeWeeklyAvailability;
}());
export { EmployeeWeeklyAvailability };
var EmployeeAvailabilityDay = /** @class */ (function () {
    function EmployeeAvailabilityDay() {
        this.hoursAM = [];
        this.hoursPM = [];
    }
    Object.defineProperty(EmployeeAvailabilityDay.prototype, "hours", {
        get: function () {
            return this.hoursAM.concat(this.hoursPM);
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeAvailabilityDay;
}());
export { EmployeeAvailabilityDay };
var EmployeeAvailabilityHour = /** @class */ (function () {
    function EmployeeAvailabilityHour() {
    }
    return EmployeeAvailabilityHour;
}());
export { EmployeeAvailabilityHour };
var EmployeeSectionsAvailability = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAvailability, _super);
    function EmployeeSectionsAvailability() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsAvailability;
}(EmployeeSectionsBase));
export { EmployeeSectionsAvailability };
