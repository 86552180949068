import * as tslib_1 from "tslib";
/**
 * When SignalR runs it will add functions to the global $ variable
 * that you use to create connections to the hub. However, in this
 * class we won't want to depend on any global variables, so this
 * class provides an abstraction away from using $ directly in here.
 */
var SignalrWindow = /** @class */ (function (_super) {
    tslib_1.__extends(SignalrWindow, _super);
    function SignalrWindow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SignalrWindow;
}(Window));
export { SignalrWindow };
