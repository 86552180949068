import { Map } from 'immutable';

import { makeTypedMapFactory } from '../../../framework/immutable-utils/index';

export const employeeFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  sortSequence: null,
  recordType: null,
  id: null,
  name: null,
  hireDate: null,
  positionName: null,
  positionId: null,
  homeShiftName: null,
  homeShiftId: null,
  cell: {},
  weeklyTotals: {}
});

export const INITIAL_EMPLOYEE_STATE: Map<string, any> = employeeFactory();

export const employeesFactory: (val?: any) => Map<string, any> = makeTypedMapFactory();

export const INITIAL_EMPLOYEES_STATE: Map<string, any> = employeesFactory();

export const masterScheduleFiltersFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  dateFrom: new Date(),
  positionGroup: null,
  position: null,
  employeeType: null,
  shiftGroup: null,
  unit: null,
  scheduleDisplay: 4,
});

export const INITIAL_MASTER_SCHEDULE_FILTERS_STATE: Map<string, any> = masterScheduleFiltersFactory();



export const scheduleTotalFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({});

export const INITIAL_SCHEDULE_TOTAL_STATE: Map<string, any> = scheduleTotalFactory();

export const scheduleSubtotalFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({});

export const INITIAL_SCHEDULE_SUBTOTAL_STATE: Map<string, any> = scheduleSubtotalFactory();

export const masterScheduleFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  actions: null,
  filters: INITIAL_MASTER_SCHEDULE_FILTERS_STATE,
  employees: INITIAL_EMPLOYEES_STATE,
  totals: INITIAL_SCHEDULE_TOTAL_STATE,
  subtotals: INITIAL_SCHEDULE_SUBTOTAL_STATE,
  scrollPosition: {},
  selectedRow: null,
  isLoading: false,
  isLoadingTotals: false,
  hasError: false,
  errorMessage: null
});

export const INITIAL_MASTER_SCHEDULE_STATE: Map<string, any> = masterScheduleFactory();
