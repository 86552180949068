import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { UserSettingsService } from '../../../core/services/index';
import { IColumnState, IColumnsState, IComponentColumnsStateStorage, IColumnSettings, IColumnGroupsState, UserSettings } from '../../../core/models/index';
import { ColumnSettingsDefinitions } from '../../models/column-settings/column-settings-definitions';
import { Assert } from '../../../framework/index';
import { AppUserSettingsService } from '../../services/app-user-settings/app-user-settings.service';
import { AppUserSetting } from '../../models/index';

@Injectable()
export class ColumnSettingsStorageService {

  public static defaultColumnGroupKey: string = 'default';
  constructor(private userSettingsService: UserSettingsService, private appUserSettingsService: AppUserSettingsService) {
    Assert.isNotNull(userSettingsService, 'userSettingsService');
    this.userSettingsService = userSettingsService;
  }

  public restoreServerColumnsState(component: string): Promise<IColumnGroupsState> {
    if (this.appUserSettingsService.isLocalOrRestoredGroup(component)) {
      let settings: UserSettings = this.userSettingsService.get();
      let colsState: IColumnGroupsState = this.getColumnGroupsLocal(component, settings);
      return Promise.resolve(colsState);
    }

    let promise: Promise<IColumnGroupsState> = this.appUserSettingsService.getFromServer(component)
      .then((appSettings: AppUserSetting[]) => {
        let settings: UserSettings = this.userSettingsService.get();
        let colGroups: IColumnGroupsState = this.getColumnGroupsLocal(component, settings);
        _.forEach(appSettings, (appSett: AppUserSetting) => {
          let conf = this.appUserSettingsService.getConfigurationByServerNames(appSett.settingsGroup, appSett.name);
          if (conf && conf.serverSide && conf.type === 'column') {
            colGroups[appSett.name] = { state: appSett.value };
          }
        });
        this.userSettingsService.set(settings);
        return colGroups;
      }).catch(() => {
        let settings: UserSettings = this.userSettingsService.get();
        let colGroups: IColumnGroupsState = this.getColumnGroupsLocal(component, settings);
        return colGroups;
      });
    return promise;
  }

  public getColumnState(component: string, columnGroup: string, column: string): IColumnState {
    let settings: UserSettings = this.userSettingsService.get();
    let colsState: IColumnsState = this.getColumnsStateLocal(component, columnGroup, settings);
    let colState: IColumnState = colsState.state[column];
    if (!colState) {
      return {};
    }
    return colState;
  }

  public getColumnsState(component: string, columnGroup: string, columns: IColumnSettings[]): IColumnSettings[] {
    let settings: UserSettings = this.userSettingsService.get();
    let columnsStorage: IComponentColumnsStateStorage = settings.columnsState;
    let colsState: IColumnsState = this.getColumnsStateLocal(component, columnGroup, settings);
    this.restoreState(columns, colsState);
    return columns;
  }

  public setColumnsState(component: string, columnGroup: string, columns: IColumnSettings[]): void {
    let settings: UserSettings = this.userSettingsService.get();
    let colsState: IColumnsState = this.getColumnsStateLocal(component, columnGroup, settings);
    this.applyState(colsState, columns);
    this.userSettingsService.set(settings);

    let conf = this.appUserSettingsService.getConfigurationByLocalNames(component, columnGroup);
    if (conf && conf.serverSide) {
      this.appUserSettingsService.saveToServer([{ settingsGroup: this.appUserSettingsService.getServerGroupName(component), name: conf.serverName, value: colsState.state }]);
    }
  }

  public getColumnGroupsLocal(component: string, settings: UserSettings): IColumnGroupsState {
    let state: IColumnsState;
    if (!settings.columnsState) {
      settings.columnsState = { storage: {} };
      this.userSettingsService.set(settings);
    }
    let columnsStorage: IComponentColumnsStateStorage = settings.columnsState;
    if (!columnsStorage.storage[component]) {
      columnsStorage.storage[component] = {};
      this.userSettingsService.set(settings);
    }
    return columnsStorage.storage[component];
  }

  public getColumnsStateLocal(component: string, colGroup: string, settings: UserSettings): IColumnsState {
    if (!colGroup) {
      colGroup = ColumnSettingsStorageService.defaultColumnGroupKey;
    }
    let colGroups: IColumnGroupsState = this.getColumnGroupsLocal(component, settings);
    if (!colGroups[colGroup]) {
      colGroups[colGroup] = { state: {} };
      this.userSettingsService.set(settings);
    }
    return colGroups[colGroup];
  }

  public mapColumns(columns: IColumnSettings[]): StringMap<IColumnSettings> {
    let columnsMap: StringMap<IColumnSettings> = _.keyBy(columns, (column: IColumnSettings) => {
      return column.name;
    });
    return columnsMap;
  }

  public restoreState(initialState: IColumnSettings[], savedState: IColumnsState): void {
    _.forEach(_.values(initialState), (column: IColumnSettings) => {
      let colState: IColumnState = savedState.state[column.name];
      if (colState) {
        column.displayed = colState.displayed;
        column.payload = colState.payload;
      }
    });
  }

  public applyState(colState: IColumnsState, state: IColumnSettings[]): void {
    colState.state = {};
    _.forEach(state, (column: IColumnSettings) => {
      colState.state[column.name] = {
        displayed: column.displayed,
        payload: column.payload
      };
    });
  }

}
