import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Provider, OnInit } from '@angular/core';
import { DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { NgControl, NgForm } from '@angular/forms';
import { EmployersManagementService } from '../../../services/index';
import { LookupService } from '../../../../organization/services/lookup/lookup.service';
import { LookupType } from '../../../../organization';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';
import { EmployerDialogOptions } from '../../../models/employers/index';
import { commonConfig } from '../../../../common/common.config';
var ManageEmployerDialogComponent = /** @class */ (function () {
    function ManageEmployerDialogComponent(options, modalService, employerDialogOptions, manService, lookupService) {
        this.manService = manService;
        this.lookupService = lookupService;
        this.dialogResult = false;
        this.isLoading = true;
        this.options = options;
        this.modalService = modalService;
        this.employerDialogOptions = employerDialogOptions;
        if (this.employerDialogOptions && this.employerDialogOptions.employer) {
            if (this.employerDialogOptions.employer.companyId == 0) {
                this.headerTitle = 'Create New Company';
            }
            else {
                this.headerTitle = 'Edit Company';
                this.oldFein = this.employerDialogOptions.employer.federalEIN.replace('-', '');
            }
        }
    }
    Object.defineProperty(ManageEmployerDialogComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ManageEmployerDialogComponent.prototype, "valid", {
        get: function () {
            var formValid = this.form.invalid ? false : true;
            return formValid;
        },
        enumerable: true,
        configurable: true
    });
    ManageEmployerDialogComponent.openDialog = function (employerDialogOptions, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 800;
        dialogOptions.height = 570;
        dialogOptions.hideTitleBar = true;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.showCloseButton = false;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: EmployerDialogOptions,
                useValue: employerDialogOptions
            },
            {
                provide: NgControl,
                useValue: employerDialogOptions
            }
        ];
        return modalService.globalAnchor.openDialog(ManageEmployerDialogComponent, '', dialogOptions, resolvedProviders, callback);
    };
    ManageEmployerDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = false;
        this.commonConfig = commonConfig;
        this.stateSubscription = this.manService
            .subscribeToOnStateChanged(function (state) {
            _this.isLoading = state.isLoading;
        });
        this.subscriptionsClose = this.manService.subscribeToClosePopup(function () {
            _this.onClose();
        });
        if (this.employerDialogOptions) {
            this.employer = this.employerDialogOptions.employer;
            this.aleGroupList = this.employerDialogOptions.aleGroupList;
            this.allFeins = this.employerDialogOptions.allFeins;
            if (this.employer) {
                this.loadStateLookup(this.employer.orgLevelId);
            }
        }
    };
    ManageEmployerDialogComponent.prototype.onSave = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manService.onSaveEmployer(this.employer)];
                    case 1:
                        _a.sent();
                        this.dialogResult = this.ngFormChild.dirty;
                        this.modalService.closeWindow(this.options.windowUniqueId);
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageEmployerDialogComponent.prototype.onCancel = function () {
        if (this.ngFormChild && this.ngFormChild.dirty) {
            this.manService.markAsDirty();
            this.manService.requestClosePopup();
        }
        else {
            this.dialogResult = false;
            this.modalService.closeWindow(this.options.windowUniqueId);
        }
    };
    ManageEmployerDialogComponent.prototype.onClose = function () {
        if (this.ngFormChild && this.ngFormChild.dirty)
            this.dialogResult = this.ngFormChild.dirty;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ManageEmployerDialogComponent.prototype.loadStateLookup = function (orgLevelId) {
        var _this = this;
        this.lookupService.getLookup({ lookupType: LookupType.state, orgLevelId: orgLevelId })
            .then(function (stateLookup) {
            _this.stateLookup = stateLookup;
        })
            .catch(function () {
        });
    };
    ManageEmployerDialogComponent.prototype.onChangeSameAsLegal = function (isChecked) {
        if (isChecked) {
            this.employer.isSameAsLegalAddress = true;
            this.employer.businessAddress1 = this.employer.legalAddress1;
            this.employer.businessAddress2 = this.employer.legalAddress2;
            this.employer.businessCity = this.employer.legalCity;
            this.employer.businessStateModel = this.employer.legalStateModel;
            this.employer.businessZip = this.employer.legalZip;
        }
        else {
            this.employer.isSameAsLegalAddress = false;
        }
    };
    ManageEmployerDialogComponent.prototype.checkDuplicateFein = function (fein, ngModel) {
        if ((fein.indexOf('-') == -1 && fein.length == 9) || fein.length == 10) {
            fein = fein.replace('-', '');
            if (this.employerDialogOptions.employer.companyId != 0) {
                if (this.oldFein != fein) {
                    this.validateFein(fein, ngModel);
                }
            }
            else {
                this.validateFein(fein, ngModel);
            }
        }
    };
    ManageEmployerDialogComponent.prototype.validateFein = function (fein, ngModel) {
        var errors = ngModel.control.errors || {};
        if (this.allFeins.includes(fein)) {
            errors.duplicateFein = true;
            ngModel.control.setErrors(errors);
        }
        else {
            delete errors.duplicateFein;
            ngModel.control.setErrors(_.size(errors) > 0 ? errors : null);
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ManageEmployerDialogComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ManageEmployerDialogComponent.prototype, "subscriptionsClose", void 0);
    return ManageEmployerDialogComponent;
}());
export { ManageEmployerDialogComponent };
