import { Component, OnInit } from '@angular/core';
import { DialogOptions2, IDialog, ModalService, KendoGridStateHelper, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common';
import { ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { AcaExportCorrection1094CCounts, AcaExportCorrection1094CCountContainer } from '../../../models/aca-export/aca-export-correction-1094c-counts';
import { AcaExportCorrectionParameter } from '../../../models/aca-export/aca-export-correction-parameter';
import { AcaExportExecutionItem } from '../../../models/aca-export/aca-export-execution';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { AcaExportCorrectionType } from '../../../models/aca-export/aca-export-correctiontype';
import { AcaExportConfiguration } from '../../../models/aca-export/aca-export-configuration';
import { AcaExportParameter } from '../../../models/aca-export/aca-export-parameter';
import { AcaExportType } from '../../../enums/aca-export-type';
import * as _ from 'lodash';

@Component({
  selector: 'slx-aca-export-corrected-1094c-counts',
  templateUrl: './aca-export-corrected-1094c-counts.component.html',
  styleUrls: ['./aca-export-corrected-1094c-counts.component.scss']
})

export class AcaExportCorrected1094cCountsComponent implements IDialog, OnInit {

  @ViewChild('correctionForm', { static: true })
  private ngFormChild: NgForm;
  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }
  private m_initialized: boolean;
  private modalService: ModalService;
  private options: DialogOptions2;
  public acaCorrectionType: AcaExportCorrectionType;
  public acaExportCorrectionParameter: AcaExportCorrectionParameter;
  public acaExportExecutionItem: AcaExportExecutionItem;
  public dialogResult: boolean = false;
  public isLoading: boolean = true;
  public container: AcaExportCorrection1094CCountContainer = new AcaExportCorrection1094CCountContainer();
  public gridState: KendoGridStateHelper<AcaExportCorrection1094CCounts[]>;
  public configuration: AcaExportConfiguration = new AcaExportConfiguration();
  public manService: AcaExportManagementService;

  constructor(
    modalService: ModalService,
    options: DialogOptions2,
    manService: AcaExportManagementService
  ) {
    this.options = options;
    this.modalService = modalService;
    this.manService = manService;

    this.gridState = new KendoGridStateHelper<AcaExportCorrection1094CCounts[]>();
    this.gridState.view = null;
  }

  public get initialized(): boolean {
    return this.m_initialized;
  }

  public ngOnInit(): void {
    this.m_initialized = true;
  }

  public async getAcaExport1094CCountData(item: AcaExportExecutionItem, correctionParameters: AcaExportCorrectionParameter): Promise<void> {
    this.isLoading = true;
    this.container = await this.manService.getAcaExport1094CCountData(item, correctionParameters);
    this.refreshGrid();
    this.isLoading = false;
  }

  public get valid(): boolean {
    let formValid: boolean = this.form.invalid ? false : true;
    return formValid;
  }

  public onBack(): void {
    this.onCancel();
    let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
    acaExportDialogOptions.dialogType = 1;
    acaExportDialogOptions.acaExportCorrectionParameter = this.acaExportCorrectionParameter;
    acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
    acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;

    this.manService.openAcaExportDialog(acaExportDialogOptions);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  private refreshGrid(): void {
    if (!this.container || !this.container.counts) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.container.counts, this.gridState.state);
  }

  public generate1094cPdf(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'Generate PDF',
      'Do you wish to proceed with generating a PDF export?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.configuration.exportParams = new AcaExportParameter();
          this.configuration.exportParams.exportType = AcaExportType.ExportPdfCorrected1094C;
          this.configuration.exportParams.employerId = this.acaExportExecutionItem.employerId;
          this.configuration.exportParams.employerName = this.acaExportExecutionItem.employerName;
          this.configuration.exportParams.memberOfAleGroup = _.filter(this.acaExportCorrectionParameter.memberOfAleGroup, (item) => (item.isSelected == true));
          this.configuration.exportParams.offeredMec = _.filter(this.acaExportCorrectionParameter.offeredMec, (item) => (item.isSelected == true));
          this.configuration.exportParams.offerMethod98 = this.acaExportCorrectionParameter.offerMethod98 ? 'Yes' : 'No';
          this.configuration.exportParams.qualifiedOfferMethod = this.acaExportCorrectionParameter.qualifiedOfferMethod ? 'Yes' : 'No';
          this.configuration.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.configuration.exportParams.year = this.acaExportExecutionItem.year;
          this.configuration.exportParams.overrides = this.container;

          this.manService.generateAcaExport(this.configuration.exportParams);
          this.onCancel();
        }
      }, options);
  }

  public generate1094cXml(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'Generate Corrected XML',
      'Are you sure you wish to generate a corrected XML export according to the parameters you specified?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.configuration.exportParams = new AcaExportParameter();
          this.configuration.exportParams.exportType = AcaExportType.ExportXmlCorrected1094C;
          this.configuration.exportParams.employerId = this.acaExportExecutionItem.employerId;
          this.configuration.exportParams.employerName = this.acaExportExecutionItem.employerName;
          this.configuration.exportParams.memberOfAleGroup = _.filter(this.acaExportCorrectionParameter.memberOfAleGroup, (item) => (item.isSelected == true));
          this.configuration.exportParams.offeredMec = _.filter(this.acaExportCorrectionParameter.offeredMec, (item) => (item.isSelected == true));
          this.configuration.exportParams.offerMethod98 = this.acaExportCorrectionParameter.offerMethod98 ? 'Yes' : 'No';
          this.configuration.exportParams.qualifiedOfferMethod = this.acaExportCorrectionParameter.qualifiedOfferMethod ? 'Yes' : 'No';
          this.configuration.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.configuration.exportParams.year = this.acaExportExecutionItem.year;
          this.configuration.exportParams.overrides = this.container;

          this.manService.generateAcaExport(this.configuration.exportParams);
          this.onCancel();
        }
      }, options);
  }

}
