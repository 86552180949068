import { EssEntity } from './../../../../organization/models/lookup/ess-entity';
import { Component, Output, Inject, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ColumnManagementService } from './../../../../common/services/column-settings/column-management.service';
import { Employee, SmsResponse, EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { employeeListConfig } from '../../employee-list.config';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN } from '../../../../core/models/index';
import { ModalService, ModalAnchorDirective } from '../../../../common/index';
import { EmployeeSelfServiceApiService } from '../../../employee/services/index';
import { AppSettingsManageService } from '../../../../app-settings/services';
@Component({
  moduleId: module.id,
  selector: 'slx-create-ess-policy',
  templateUrl: 'create-ess-policy.component.html',
  styleUrls: ['create-ess-policy.component.scss'],
  providers: [ColumnManagementService]
})
export class CreateESSPolicyComponent implements OnInit {
  @Output()
  public onCancel: EventEmitter<any>;

  @Output()
  public onComplete: EventEmitter<string>;

  public actionEmployees: Employee[];

  public employees: Employee[];
  public fieldDefinition: EmployeeActionDefinition;
  public orgLevel: OrgLevel;
  public isGoClient: boolean;


  public state: {
    isLoading: boolean;
  };
  public effectiveDate: Date;

  public options: EssEntity[];
  public passType: EssEntity;
  public timeMethod: EssEntity;

  public canSend: boolean = false;

  @ViewChild(ModalAnchorDirective, {static: true})
  private modalAnchor: ModalAnchorDirective;

  private selectedEmployees: Employee[];
  private modalService: ModalService;

  private employeeSelfServiceApiService: EmployeeSelfServiceApiService;
  private groupActivitiesService: GroupActivitiesApiService;

  constructor(employeeSelfServiceApiService: EmployeeSelfServiceApiService, groupActivitiesService: GroupActivitiesApiService, @Inject(EMPLOYEES_ID_TOKEN) employees: Employee[], fieldDefinition: EmployeeActionDefinition,
              orgLevel: OrgLevel, modalService: ModalService, private appSettingsManageService: AppSettingsManageService) {
    this.employeeSelfServiceApiService = employeeSelfServiceApiService;
    this.groupActivitiesService = groupActivitiesService;
    this.employees = employees;
    this.fieldDefinition = fieldDefinition;
    this.orgLevel = orgLevel;
    this.modalService = modalService;
    this.onCancel = new EventEmitter<any>();
    this.onComplete = new EventEmitter<string>();

    this.state = {
      isLoading: false,
    };
  }

  public ngOnInit(): void {
    let employeeIds: string[] = _.map(this.employees, (employee: Employee) => employee[employeeListConfig.employeeIdentifierName]);
    this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, true, employeeIds, this.fieldDefinition).then((actionEmployees: Employee[]) => {
      this.actionEmployees = actionEmployees;
    });
    this.assignIntFormat();
    this.getGoClientsetting();
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public employeesSelect(selectedEmployees: Employee[]): void {
    this.selectedEmployees = selectedEmployees;
    this.canSend = this.selectedEmployees && this.selectedEmployees.length > 0;
  }

  public createPolicy(): void {
    this.state.isLoading = true;

    let employeeIds = _.map(this.selectedEmployees, (emp: Employee) => emp['EmpId']);
    let optionsIds = _.map(this.options, (opt: EssEntity) => opt.id);

    this.employeeSelfServiceApiService.createESSPolicy(employeeIds,
                                        this.passType ? this.passType.id : null,
                                        this.timeMethod ? this.timeMethod.id : null,
                                        optionsIds)
      .then((response: any) => {
        this.state.isLoading = false;
        this.modalAnchor.openInfoDialog('Information', 'ESS Accounts created!');
      })
      .catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  private assignIntFormat(): void {
    _.forEach(this.fieldDefinition.fields, f => {
      if (f.fieldType === 'int' && (_.isNil(f.fieldFormat) || _.size(f.fieldFormat) === 0)) {
        f.fieldFormat = 'n0';
      }
    });
  }

  private async getGoClientsetting(): Promise<void> {
    const config = await this.appSettingsManageService.getAppServerConfig();
    this.isGoClient = config.IsGOClient;  
  }
}
