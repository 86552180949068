<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid table-conatiner" 
  [data]="tabData"
  [trackBy]="trackByItem"
  (edit)="editHandler($event)"
  (cancel)="cancelHandler($event)" 
  (save)="saveHandler($event)"
  [navigable]="true"
  >
    <kendo-grid-column field="Description" [title]="tabName"
      [editable]="false">
      <ng-template kendoGridCellTemplate let-dataItem="dataItem">
        {{dataItem.description}} <span *ngIf="tabName === 'Departments' || tabName === 'Positions'">({{dataItem.count}})</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="shortCode" title="Code">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <form [formGroup]="formGroup">
          <kendo-formfield>
            <slx-input-decorator>
              <input slx-input type="text" 
              [required]="true" 
              placeholder="" title="" 
              [disabled]="this.tabName === 'Positions' && dataItem.shortCode && dataItem.isSaved"
              formControlName="ShortCode" 
              [maxLength]="getMaxLength()"
              (keyup)="debouncedSearch($event)" />
            </slx-input-decorator>            
            <kendo-formerror *ngIf="invalidField" class="error">{{errorText}}</kendo-formerror>
          </kendo-formfield>
        </form>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column *ngIf="tabName === 'Positions'" field="payrollDescription" title="Payroll Description">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <form [formGroup]="formGroup">
          <kendo-formfield>
            <slx-input-decorator>
              <input slx-input type="text" [required]="true" (keyup)="trimChar($event)" placeholder="" title="" [maxLength]="30" formControlName="PayrollDescription" />
            </slx-input-decorator>
          </kendo-formfield>
        </form>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Actions" [width]="200">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem" *ngIf="!!isReadonly">
        <div class="btnContainer" *ngIf="!dataItem.isSaved && tabName !== 'Positions'">
        <button kendoGridEditCommand [primary]="true" [attr.tabindex]="0" class="edit-Btn">Edit</button>
        <button kendoButton kendoGridSaveCommand [disabled]="formGroup?.invalid || invalidField" [primary]="true" class="theme-iconed-accent-button btn-apply">
          Done
        </button>
        <button kendoButton kendoGridCancelCommand class="theme-iconed-accent-button btn-cancel">
          Cancel
        </button>
        </div>   
        <div class="btnContainer" *ngIf="tabName === 'Positions'">
          <button kendoGridEditCommand [primary]="true" class="edit-Btn">Edit</button>
          <button kendoButton kendoGridSaveCommand [disabled]="formGroup?.invalid || invalidField" [primary]="true" class="theme-iconed-accent-button btn-apply">
            Done
          </button>
          <button kendoButton kendoGridCancelCommand class="theme-iconed-accent-button btn-cancel">
            Cancel
          </button>
          </div>     
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
