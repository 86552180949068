import { ProgressbarComponent } from './progressbar.component';
import * as moment from 'moment';
import * as _ from 'lodash';

export class AcaMeasurementAgGridHelper {
  public columnDefs: any[];
  public defaultColumnDefs: any = {
    resizable: true,
    suppressMenu: true,
    suppressMovable: true,
    suppressContextMenu: false
  };

  public state: {
    year: number;
    numMonths: number;
    organizationColShown: boolean;
    departmentColShown: boolean;
    positionColShown: boolean;
    dateHiredColShown: boolean;
    dateTerminatedColShown: boolean;
    mpStartColShown: boolean;
    mpEndColShown: boolean;
    weeklyAvgHoursColShown: boolean;
    apStartColShown: boolean;
    apEndColShown: boolean;
    spStartColShown: boolean;
    spEndColShown: boolean;
    acaDateColShown: boolean;
    acaTypeColShown: boolean;
    empTypeColShown: boolean;
    acaExcludeColShown: boolean;
    benefitDeclinedColShown: boolean;
    purchaseMarketplaceColShown: boolean;
    benefitNameColShown: boolean;
    benefitStartColShown: boolean;
  };

  public createColumnDefs(): void {
    this.columnDefs = [];
    this.columnDefs.push({
      'headerName': '',
      'field': 'expand',
      'width': 20,
      'resizable': false,
      'pinned': 'left',
      'headerClass': 'grid-header-emp',
      'cellClass': 'expandColumn',
      'cellRenderer': function (params) {
        var html = "<em class='fa fa-angle-right' aria-hidden='true'></em>";
        if (params.data.expand) {
          html = "<em class='fa fa-angle-down' aria-hidden='true'></em>";
        }
        return html;
      }
    });
    this.columnDefs.push({
      'headerName': 'Employee Name',
      'field': 'empName',
      'width': 150,
      'pinned': 'left',
      'headerClass': 'grid-header-row grid-header-emp',
      'cellClass': 'gridCutomCell1',
      'cellRenderer': function (params) { return `<a class="employee-name"> ${params.data.empName}</a>`; }
    });

    this.columnDefs.push({
      'headerName': 'PT',
      'field': 'periodType',
      'width': 45,
      'headerTooltip': 'Period Type',
      'resizable': false,
      'pinned': 'left',
      'headerClass': 'grid-header-row grid-header-emp',
      'cellClass': 'gridCutomCell2 pt-cell',
      'cellRenderer': this.getCellRender()
    });


    this.columnDefs.push({
      'headerName': 'Organization',
      'field': 'organizationName',
      'width': 160,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell1',
      'hide': !this.state.organizationColShown,
    });

    this.columnDefs.push({
      'headerName': 'Department',
      'field': 'departmentName',
      'width': 130,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell1',
      'hide': !this.state.departmentColShown,
    });

    this.columnDefs.push({
      'headerName': 'Position',
      'field': 'positionName',
      'width': 140,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell1',
      'hide': !this.state.positionColShown,
    });

    this.columnDefs.push({
      'headerName': 'Hire Date',
      'field': 'dateHired',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell1',
      'hide': !this.state.dateHiredColShown
    });

    this.columnDefs.push({
      'headerName': 'Term Date',
      'field': 'dateTerm',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell1',
      'hide': !this.state.dateTerminatedColShown
    });

    this.columnDefs.push({
      'headerName': 'Emp Type',
      'field': 'empType',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell1',
      'hide': !this.state.empTypeColShown
    });

    this.columnDefs.push({
      'headerName': 'MP Start',
      'field': 'measurementPeriodStart',
      'headerTooltip': 'Measurement Period Start',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.mpStartColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'MP End',
      'field': 'measurementPeriodEnd',
      'headerTooltip': 'Measurement Period End',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.mpEndColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'Weekly Avg Hours',
      'field': 'weeklyAvgHours',
      'headerTooltip': 'Weekly Avg Hours',
      'width': 140,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.weeklyAvgHoursColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'AP Start',
      'field': 'administrativePeriodStart',
      'headerTooltip': 'Admin Period Start',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.apStartColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'AP End',
      'field': 'administrativePeriodEnd',
      'headerTooltip': 'Admin Period End',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.apEndColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'SP Start',
      'field': 'stabilityPeriodStart',
      'headerTooltip': 'Stability Period Start',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.spStartColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'SP End',
      'field': 'stabilityPeriodEnd',
      'headerTooltip': 'Stability Period End',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.spEndColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'ACA Date',
      'field': 'acaDate',
      'width': 90,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.acaDateColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'ACA Type',
      'field': 'acaType',
      'width': 100,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.acaTypeColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'ACA Exclude',
      'field': 'acaExclude',
      'width': 110,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.acaExcludeColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'Benefit Declined',
      'field': 'benefitDeclined',
      'width': 120,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.benefitDeclinedColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'Purchased Marketplace',
      'field': 'purchasedMarketplace',
      'width': 130,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.purchaseMarketplaceColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'Benefit Name',
      'field': 'benefitName',
      'width': 110,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.benefitNameColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': 'Benefit Start',
      'field': 'benefitStart',
      'width': 120,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell2',
      'hide': !this.state.benefitStartColShown,
      'cellRenderer': this.getCellRender()
    });

    this.columnDefs.push({
      'headerName': '',
      'width': 1,
      'headerClass': 'grid-header-row',
      'cellClass': 'gridCutomCell3',
      'cellRendererFramework': ProgressbarComponent,
      'colSpan': function (params) { return 14; },
      'resizable': false,
    });

    this.createDateColumns();

  }

  private createDateColumns(): void {
    for (let month: number = 0; month < this.state.numMonths; month++) {
      this.columnDefs.push({
        'headerName': moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM'),
        'width': 2.8 * moment(moment().startOf('year').toDate().getFullYear() + "-" + (month + 1), "YYYY-MM").daysInMonth(),
        'headerClass': 'grid-header-row',
        'cellClass': 'gridCutomCell',
        'resizable': false,
      });
    }
  }

  public getCellRender(): any {
    let elm: any = this;
    return function (params): string {
      return elm.getCellHtmlTemplate(params);
    }
  }

  public getCellHtmlTemplate(params: any): string {
    if (params.data.expand) {
      let html: string = '';
      if (params.data.periodTypes) {
        _.forEach(params.data.periodTypes, function (item) {
          if (params.column.colId == 'periodType') {
            html += `<span class='gridCutomCell pt-content theme-tooltip'>${item.periodType}<span class="theme-tooltiptext">${item.periodTypeFullName}</span></span>`;
          }
          else if (params.column.colId == 'measurementPeriodStart') {
            html += `<div class='gridCutomCell'> ${item.measurementPeriodStart}</div>`;
          }
          else if (params.column.colId == 'measurementPeriodEnd') {
            html += `<div class='gridCutomCell'> ${item.measurementPeriodEnd}</div>`;
          }
          else if (params.column.colId == 'administrativePeriodStart') {
            html += `<div class='gridCutomCell'> ${item.administrativePeriodStart}</div>`;
          }
          else if (params.column.colId == 'administrativePeriodEnd') {
            html += `<div class='gridCutomCell'> ${item.administrativePeriodEnd}</div>`;
          }
          else if (params.column.colId == 'stabilityPeriodStart') {
            html += `<div class='gridCutomCell'> ${item.stabilityPeriodStart}</div>`;
          }
          else if (params.column.colId == 'stabilityPeriodEnd') {
            html += `<div class='gridCutomCell'> ${item.stabilityPeriodEnd}</div>`;
          }
          else if (params.column.colId == 'acaDate') {
            html += `<div class='gridCutomCell'> ${item.acaDate}</div>`;
          }
          else if (params.column.colId == 'acaType') {
            html += `<div class='gridCutomCell'> ${item.acaType}</div>`;
          }
          else if (params.column.colId == 'acaExclude') {
            html += `<div class='gridCutomCell'> ${item.acaExclude}</div>`;
          }
          else if (params.column.colId == 'benefitDeclined') {
            html += `<div class='gridCutomCell'> ${item.benefitDeclined}</div>`;
          }
          else if (params.column.colId == 'purchasedMarketplace') {
            html += `<div class='gridCutomCell'> ${item.purchasedMarketplace}</div>`;
          }
          else if (params.column.colId == 'benefitName') {
            html += `<div class='gridCutomCell'> ${item.benefitName}</div>`;
          }
          else if (params.column.colId == 'benefitStart') {
            html += `<div class='gridCutomCell'> ${item.benefitStart}</div>`;
          }
          else if (params.column.colId == 'weeklyAvgHours') {
          var mpPeriod = _.find(item.acaPeriod, ['periodSortName', 'MP']);
          if(mpPeriod)
          {
            html += `<div class='gridCutomCell' style='text-align:center'> ${mpPeriod.empAvgHours}</div>`;
          }
          else
          {
            html += `<div class='gridCutomCell'></div>`;
          }
        }
          
        })
      }
      return html;
    }
    else {
      return '';
    }
  }
}
