import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsSelfService, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSelfServiceApiService } from '../../../../employee/services/index';
import { ConfirmDialogComponent, InfoDialogComponent } from '../../../../../common/components/index';
import { ModalService } from '../../../../../common/index';
import { EssLookupManageService } from '../../../../../organization/services/lookup/ess-lookup-manage.service';
import { EmployeeDefinitionsApiService } from '../../../../../organization/services/index';
var EmployeeSectionsSelfServiceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsSelfServiceComponent, _super);
    function EmployeeSectionsSelfServiceComponent(ngZone, decorator, modalService, employeeSectionsEmploymentApiService, employeeSelfServiceApiService, essLookupService, employeeDefinitionApi) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.modalService = modalService;
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.employeeSelfServiceApiService = employeeSelfServiceApiService;
        _this.essLookupService = essLookupService;
        _this.employeeDefinitionApi = employeeDefinitionApi;
        _this.m_employeeSectionsSelfService = new EmployeeSectionsSelfService();
        Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.essLookupService.onLoaded$.subscribe(function (value) {
            _this.fillValues();
        });
        return _this;
    }
    Object.defineProperty(EmployeeSectionsSelfServiceComponent.prototype, "employeeSectionsSelfService", {
        get: function () {
            return this.m_employeeSectionsSelfService;
        },
        set: function (value) {
            this.m_employeeSectionsSelfService = value;
            this.fillValues();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsSelfServiceComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsSelfServiceComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    EmployeeSectionsSelfServiceComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsSelfService;
    };
    EmployeeSectionsSelfServiceComponent.prototype.canSave = function () {
        var _this = this;
        if (!this.employeeSectionsSelfService.hasEssAccount || !this.employeeSectionsSelfService.password) {
            return this.checkSsn();
        }
        var promise = new Promise(function (resolve) {
            ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to override the existing password?', _this.modalService, function (confirmed) {
                resolve(confirmed ? _this.checkSsn() : false);
            });
        });
        return promise;
    };
    EmployeeSectionsSelfServiceComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmploymentSelfService(this.employeeId)
            .then(function (employeeSectionsSelfService) {
            _this.employeeSectionsSelfService = employeeSectionsSelfService;
            _this.stopProgress();
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsSelfServiceComponent.prototype.doSave = function () {
        var _this = this;
        var promise;
        this.employeeSectionsSelfService.password = this.passwordType ? this.passwordType.id : null;
        this.employeeSectionsSelfService.enterTimeMethod = this.timeMethod ? this.timeMethod.id : null;
        if (this.employeeSectionsSelfService.isEssEnabled) {
            promise = this.employeeSelfServiceApiService.createESSPolicy([this.employeeId], this.employeeSectionsSelfService.password, this.employeeSectionsSelfService.enterTimeMethod, this.employeeSectionsSelfService.availableOptions);
        }
        else {
            promise = this.employeeSelfServiceApiService.deleteESSPolicy([this.employeeId]);
        }
        promise.then(function (response) {
            _this.onActionComplete(true);
        }).catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsSelfServiceComponent.prototype.fillValues = function () {
        if (this.employeeSectionsSelfService) {
            this.timeMethod = this.essLookupService.getTimeMethod(this.employeeSectionsSelfService.enterTimeMethod);
            this.passwordType = this.essLookupService.getPasswordType(this.employeeSectionsSelfService.password);
        }
    };
    EmployeeSectionsSelfServiceComponent.prototype.checkSsn = function () {
        var _this = this;
        if (this.passwordType && this.passwordType.id === 'SSNLast4Digits') {
            return this.employeeDefinitionApi.getEmployeeShortInfo(this.employeeId)
                .then(function (value) {
                if (value.ssn) {
                    return Promise.resolve(true);
                }
                else {
                    InfoDialogComponent.OpenDialog('Create ESS account', 'Can not create ESS Account with selected password type, when employee doesn\'t have SSN', _this.modalService);
                    return Promise.resolve(false);
                }
            }).catch(function (reason) {
                return Promise.resolve(false);
            });
        }
        return Promise.resolve(true);
    };
    return EmployeeSectionsSelfServiceComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsSelfServiceComponent };
