import { Router, ActivatedRoute, UrlTree, NavigationExtras, Params, ActivatedRouteSnapshot } from '@angular/router';
import { ChangeManagementService } from '../change-management/change-management.service';
import { RestoreQueryParamsService } from './restore-query-params.service';
import { appConfig, IApplicationConfig } from '../../../app.config';
import * as moment from 'moment';
import * as _ from 'lodash';
import { getChildRoute, isRouteInfo } from '../../../core/models/index';

export class StateNavigationService {
  private router: Router;
  private route: ActivatedRoute;
  private changeManagement: ChangeManagementService;
  private restoreQueryParamService: RestoreQueryParamsService;

  constructor(
    router: Router,
    route: ActivatedRoute,
    changeManagement: ChangeManagementService,
    restoreQueryParamService: RestoreQueryParamsService
  ) {
    this.router = router;
    this.route = route;
    this.changeManagement = changeManagement;
    this.restoreQueryParamService = restoreQueryParamService;
  }

  public navigateToHome(orgLevelId?: number): void {
    const params: Params = this.createParams(orgLevelId);
    this.router.navigate(['apps'], {
      relativeTo: this.route.pathFromRoot[0], queryParams: params
    });
  }

  public navigateToApplication(orgLevelId: number, appName: string): void {
    const params: Params = this.createParams(orgLevelId);
    this.router.navigate(['apps', appName], {
      relativeTo: this.route.pathFromRoot[0]
    });
  }

  public navigateToMenu(appName: string, menuItem: string): void {
    const params: Params = this.createParams(null);
    if (!appName) {
      this.router.navigate(['apps', menuItem], {
        relativeTo: this.route.pathFromRoot[0], queryParams: params
      });
      return;
    }
    this.router.navigate(['apps', appName, menuItem], {
      relativeTo: this.route.pathFromRoot[0], queryParams: params
    });
  }

  public navigateToOrgLevel(orgLevelId: number): void {
    this.canChangeOrgLevel().then((canNavigate: boolean) => {
      if (!canNavigate) {
        return;
      }
      const params: Params = this.createParams(orgLevelId);
      this.router.navigate([], {
        relativeTo: this.route, queryParams: params
      });
    });
  }

  public navigateToOrgLevelAndKeepQueryParams(orgLevelId: number): void {
    this.canChangeOrgLevel().then((canNavigate: boolean) => {
      if (!canNavigate) {
        return;
      }
      const params: Params = this.createParams(orgLevelId);
      this.restoreQueryParamService.restoreParams(this.route.snapshot.queryParams, params);
      this.router.navigate([], {
        relativeTo: this.route, queryParams: params
      });
    });
  }

  private createParams(orgLevelId?: number): Params {
    if (!orgLevelId) {
      const str = this.route.snapshot.queryParamMap.get('orgLevelId');
      const val = _.toInteger(str);
      orgLevelId = val;
    }
    let params: Params = {};
    if (orgLevelId) {
      params['orgLevelId'] = orgLevelId;
    }
    return params;
  }

  private canChangeOrgLevel(): Promise<boolean> {
    const route: ActivatedRouteSnapshot = getChildRoute(this.route.snapshot.root);
    if (!route.data || !isRouteInfo(route.data)) {
      return Promise.resolve(true);
    }
    return this.changeManagement.canMoveForward(route.data.componentId);
  }
}
