/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./leave-request-confirm.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/input-decorator/input-decorator.component.ngfactory";
import * as i3 from "../../../common/components/input-decorator/input-decorator.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../common/error-filters/errorMessage";
import * as i6 from "./leave-request-confirm.component";
import * as i7 from "../../models/leave-request/leave-request";
import * as i8 from "../../../common/models/dialog-options";
import * as i9 from "../../../common/services/modal/modal.service";
var styles_LeaveRequestConfirmComponent = [i0.styles];
var RenderType_LeaveRequestConfirmComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeaveRequestConfirmComponent, data: {} });
export { RenderType_LeaveRequestConfirmComponent as RenderType_LeaveRequestConfirmComponent };
export function View_LeaveRequestConfirmComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 24, "div", [["class", "leave-request-confirm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 16, "slx-input-decorator", [["class", "slx-auto-height"]], null, null, null, i2.View_InputDecoratorComponent_0, i2.RenderType_InputDecoratorComponent)), i1.ɵdid(4, 311296, null, 4, i3.InputDecoratorComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵqud(603979776, 1, { fieldChild1: 0 }), i1.ɵqud(603979776, 2, { fieldChild2: 0 }), i1.ɵqud(603979776, 3, { inputPolicyDirective: 0 }), i1.ɵqud(603979776, 4, { errorMessages: 1 }), (_l()(), i1.ɵeld(9, 0, null, 0, 8, "textarea", [["autocomplete", "off"], ["autofocus", ""], ["maxlength", "50"], ["name", "comments"], ["placeholder", "Comments"], ["rows", "3"], ["slx-input", ""]], [[1, "maxlength", 0], [8, "disabled", 0], [8, "readOnly", 0], [8, "required", 0], [8, "placeholder", 0], [8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.entry.comment = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 540672, null, 0, i4.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.MaxLengthValidator]), i1.ɵdid(12, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(14, 671744, null, 0, i4.NgModel, [[8, null], [6, i4.NG_VALIDATORS], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(16, 16384, [[1, 4]], 0, i3.SlxInputDirective, [[2, i4.NgControl], i1.ElementRef], { placeholder: [0, "placeholder"] }, null), i1.ɵdid(17, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(18, 0, null, 3, 1, "span", [["errorMessage", ""], ["for", "maxlength"]], null, null, null, null, null)), i1.ɵdid(19, 81920, [[4, 4]], 0, i5.ErrorMessageDirective, [i1.ElementRef], { for: [0, "for"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(22, null, ["", ""])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_13 = "50"; _ck(_v, 10, 0, currVal_13); var currVal_14 = "comments"; var currVal_15 = _co.entry.comment; _ck(_v, 14, 0, currVal_14, currVal_15); var currVal_16 = "Comments"; _ck(_v, 16, 0, currVal_16); var currVal_17 = "maxlength"; _ck(_v, 19, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 10).maxlength ? i1.ɵnov(_v, 10).maxlength : null); var currVal_1 = i1.ɵnov(_v, 16).disabled; var currVal_2 = i1.ɵnov(_v, 16).readonly; var currVal_3 = i1.ɵnov(_v, 16).required; var currVal_4 = i1.ɵnov(_v, 16).placeholder; var currVal_5 = i1.ɵnov(_v, 16).className; var currVal_6 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 17).ngClassValid; var currVal_11 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 9, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); var currVal_18 = _co.entry.buttonText; _ck(_v, 22, 0, currVal_18); }); }
export function View_LeaveRequestConfirmComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-leave-request-confirm", [], null, null, null, View_LeaveRequestConfirmComponent_0, RenderType_LeaveRequestConfirmComponent)), i1.ɵdid(1, 49152, null, 0, i6.LeaveRequestConfirmComponent, [i7.LeaveRequestEntry, i8.DialogOptions, i9.ModalService], null, null)], null, null); }
var LeaveRequestConfirmComponentNgFactory = i1.ɵccf("slx-leave-request-confirm", i6.LeaveRequestConfirmComponent, View_LeaveRequestConfirmComponent_Host_0, {}, {}, []);
export { LeaveRequestConfirmComponentNgFactory as LeaveRequestConfirmComponentNgFactory };
