<!--<div class="col-xs-12" *ngFor="let record of employeeDetails?.records; let i = index">
  <div class="col-xs-3 absence-icon-box">
    <i *ngIf="record.isScheduled" class="far fa-play-circle is-scheduled" aria-hidden="true"></i>
    <i *ngIf="record.isAbsent" class="fa fa-minus-circle is-absent" aria-hidden="true"></i>
    <i *ngIf="record.isRotationDay" class="fa fa-plus-circle is-rotation" aria-hidden="true"></i>
  </div>
  <div class="col-xs-9 flex-container">
    <input class="vacation-day-checkbox" [disabled]="record.isScheduled || !absenceCode" type="checkbox"
      (ngModelChange)="onCheckbox($event, record)" [ngModel]="record.scheduleAbsence">
    <label class="vacation-day-label">{{record.date | amDateFormat: appConfig.dateDayWeekMonthTextDayYearFormat}}</label>
    <label *ngIf="record && record.scheduleAbsence" class="vacation-day-label">({{record?.scheduleAbsence?.code}})</label>
  </div>
</div>-->
<div class="col-xs-12 flex-container">
  <div class="col-md-4 flex-container">
    <slx-input-decorator>
      <slx-dropdown-input autoSelectFirst=true class="form-control" slx-input placeholder="Absence Code:" name="absenceCodeLookup"
        [lookup]="absencesLookup" [orgLevelId]="orgLevelId" [required]="true" titleField="code" [(ngModel)]="absenceCode"></slx-dropdown-input>
      <span errorMessage for="required">Please select absence code</span>
    </slx-input-decorator>
    <!--<div class="dropdown">
        <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        {{vacationCode?.code}}<span class="caret"></span>
        </button>
        <ul class="dropdown-menu small-dropdown" >
          <li *ngFor="let code of scheduleLookup" [ngClass]="{'active': code.code === vacationCode?.code }"><a (click)="onSelectCode(code)">{{code.code}}</a></li>
        </ul>
      </div>-->
  </div>
  <div class="col-md-8 buttons-container">
    <button class="btn btn-default" type="button" (click)="selectAll()">
    Select All
  </button>
    <button class="btn btn-default" type="button" (click)="clearAll()">
    Clear All
  </button>
    <button class="btn btn-default" type="button" (click)="discardChanges()">
    Discard
  </button>
  </div>
</div>
<div class="col-xs-12">
  <hr>
</div>
<div class="col-xs-12">
  <slx-weekly-absences-grid [absencesLookup]="absencesLookup" [scheduleRecords]="scheduleRecords" [orgLevelId]="orgLevelId"></slx-weekly-absences-grid>
</div>
<div class="col-xs-12">
  <hr>
</div>
<div class="col-xs-12">
  <div class="col-md-10 col-md-offset-2">
    <div class="col-xs-12">
      <i class="far fa-play-circle is-scheduled" aria-hidden="true"></i> Scheduled to work
    </div>
    <!--<div class="col-xs-12">
      <i class="fa fa-minus-circle is-absent" aria-hidden="true"></i> Scheduled to be absent
    </div>-->
    <div class="col-xs-12">
      <i class="fa fa-plus-circle is-rotation" aria-hidden="true"></i> Could be scheduled to work
    </div>
  </div>
</div>
