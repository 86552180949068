/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./attachments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/directives/slx-tooltip/slx-tooltip.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../services/attachments-management.service";
import * as i5 from "../../../core/services/session/session.service";
import * as i6 from "./attachments.component";
import * as i7 from "../../../common/services/modal/modal.service";
var styles_AttachmentsComponent = [i0.styles];
var RenderType_AttachmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AttachmentsComponent, data: {} });
export { RenderType_AttachmentsComponent as RenderType_AttachmentsComponent };
function View_AttachmentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "attachments__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload Files"]))], null, null); }
function View_AttachmentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "attachments__types"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "box__icon fas fa-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["tipPosition", "right"]], null, null, null, null, null)), i1.ɵdid(3, 4341760, null, 0, i2.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTooltip(); var currVal_1 = "right"; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_AttachmentsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "flex__grow attachments__types"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "block attachments__break-words"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Allowed file types: ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "block"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Max ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.acceptedFileTypesReadable; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.maxFileSize; _ck(_v, 4, 0, currVal_1); }); }
function View_AttachmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "attachments__buttons flex"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "span", [["class", "attachments__btn-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon"], ["type", "button"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-paperclip slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Browse..."])), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["inputFile", 1]], null, 0, "input", [["class", "attachments__input"], ["multiple", ""], ["type", "file"]], [[8, "disabled", 0], [8, "accept", 0]], [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onResetErrors($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_3)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_4)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTitle; _ck(_v, 2, 0, currVal_0); var currVal_4 = _co.showFileTypeAsTooltip; _ck(_v, 10, 0, currVal_4); var currVal_5 = !_co.showFileTypeAsTooltip; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.disabled; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.disabled; var currVal_3 = _co.acceptedFileTypesInputFormat; _ck(_v, 8, 0, currVal_2, currVal_3); }); }
function View_AttachmentsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["You selected more than ", " files"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxFiles; _ck(_v, 1, 0, currVal_0); }); }
function View_AttachmentsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Unable to upload file. Max size for files is ", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxFileSize; _ck(_v, 1, 0, currVal_0); }); }
function View_AttachmentsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to upload file. Allowed types: Word, Excel, PDF and image files."]))], null, null); }
function View_AttachmentsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "slx-error-block attachments__error"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_6)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_7)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_8)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.maxFiles; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errors.maxFileSize; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.errors.fileType; _ck(_v, 6, 0, currVal_2); }, null); }
function View_AttachmentsComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-trash-alt attachments__i-delete"], ["title", "Delete file immediately"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_AttachmentsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "p", [["class", "attachments__file-wrapper flex align-items__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "span", [["class", "flex flex__grow attachments__file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal attachments__i-file"]], [[2, "fa-file-download", null], [2, "fa-file", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "tooltip-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "flex__grow attachments__name"], ["tipPosition", "top"]], [[2, "download-link", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 4341760, null, 0, i2.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_11)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "flex__shrink added-col added-by"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit.name; var currVal_4 = "top"; _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_6 = (!_co.disabled && _co.canDelete); _ck(_v, 8, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDownloadable(_v.context.$implicit); var currVal_1 = !_co.isDownloadable(_v.context.$implicit); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.isDownloadable(_v.context.$implicit); _ck(_v, 4, 0, currVal_2); var currVal_5 = _v.context.$implicit.fileName; _ck(_v, 6, 0, currVal_5); var currVal_7 = _v.context.$implicit.addedBy; _ck(_v, 10, 0, currVal_7); }); }
function View_AttachmentsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "attachments__attached"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "p", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "flex__grow attachments__file-name-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File Name"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "flex__shrink added-col attachments__added-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Added By"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_10)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 8, 0, currVal_0); }, null); }
function View_AttachmentsComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "attachments__no-files"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No attached files"]))], null, null); }
export function View_AttachmentsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { inputFile: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "attachments"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_5)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_9)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AttachmentsComponent_12)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canUpload; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.errors; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.hasFiles; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.hasFiles; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_AttachmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-attachments", [], null, null, null, View_AttachmentsComponent_0, RenderType_AttachmentsComponent)), i1.ɵprd(512, null, i4.AttachmentsManagementService, i4.AttachmentsManagementService, [i5.SessionService]), i1.ɵdid(2, 770048, null, 0, i6.AttachmentsComponent, [i7.ModalService, i4.AttachmentsManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AttachmentsComponentNgFactory = i1.ɵccf("slx-attachments", i6.AttachmentsComponent, View_AttachmentsComponent_Host_0, { canUpload: "canUpload", canDownload: "canDownload", canDelete: "canDelete", disabled: "disabled", showTitle: "showTitle", maxFiles: "maxFiles", maxFileSizeBytes: "maxFileSizeBytes", showFileTypeAsTooltip: "showFileTypeAsTooltip", acceptedFileTypes: "acceptedFileTypes", attachments: "attachments", filesToAttach: "filesToAttach" }, { addedFiles: "addedFiles", deletedFile: "deletedFile", deletedAttachment: "deletedAttachment", downloadAttachment: "downloadAttachment" }, []);
export { AttachmentsComponentNgFactory as AttachmentsComponentNgFactory };
