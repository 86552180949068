import { AccrualTypes } from './employee-accrual-types';

export interface IResetBalance {
    employeeId: number;
    transactionDate: string;
    notes: string
    accrualTypeInfo: AccrualTypes[];
    fromDateForFetchResult: Date;
    endDateForFetchResult: Date;
}

export class ResetBalance {
    public employeeId: number;
    public transactionDate: Date;
    public notes: string
    public accrualTypeInfo: AccrualTypes[];
    public fromDateForFetchResult : Date;
    public endDateForFetchResult: Date;
}