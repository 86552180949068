export * from './timecard-summary-header/timecard-summary-header.component';
export * from './hours-summary/hours-summary.component';
export * from './periods-summary/periods-summary.component';
export * from './exempts-summary/exempts-summary.component';
export * from './secondary-positions-summary/positions-summary.component';
export * from './panel-header/timecard-summary-panel-header.component';
export * from './summary-filters/summary-filters.component';
export * from './timecards-summary/timecards-summary.component';

import { TimecardSummaryPanelHeaderComponent } from './panel-header/timecard-summary-panel-header.component';
import { HoursSummaryComponent } from './hours-summary/hours-summary.component';
import { TimecardSummaryHeaderComponent } from './timecard-summary-header/timecard-summary-header.component';
import { PeriodsSummaryComponent } from './periods-summary/periods-summary.component';
import { ExemptsSummaryComponent } from './exempts-summary/exempts-summary.component';
import { PositionsSummaryComponent } from './secondary-positions-summary/positions-summary.component';
import { SummaryFiltersComponent } from './summary-filters/summary-filters.component';
import { TimecardSummaryComponent } from './timecards-summary/timecards-summary.component';

export const timecardsSummaryComponents: any = [
    TimecardSummaryComponent,
    TimecardSummaryPanelHeaderComponent,
    HoursSummaryComponent,
    TimecardSummaryHeaderComponent,
    PeriodsSummaryComponent,
    ExemptsSummaryComponent,
    PositionsSummaryComponent,
    SummaryFiltersComponent
];
