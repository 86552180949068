import { WeekDays } from '../../../../common/models/index';
import { WeeklyRotation, EmployeeRotationRecord } from '../../models/index';
import { Assert } from '../../../../framework/index';
import * as moment from 'moment';
var WeekDayService = /** @class */ (function () {
    function WeekDayService() {
    }
    WeekDayService.prototype.getWeekDaysByRotation = function (rotation) {
        if (rotation) {
            var firstWeek = rotation.firstWeek;
            if (firstWeek) {
                var firstDayName = moment(firstWeek.weekStartDate).format('dddd');
                var firstDayOfWeek = this.getWeekDayByName(firstDayName);
                return this.getWeekDays(firstDayOfWeek, 1);
            }
        }
        return WeekDays;
    };
    WeekDayService.prototype.prepareRotation = function (employeeRotation) {
        var _this = this;
        Assert.isNotNull(employeeRotation, 'employeeRotation');
        var weekDays = this.getWeekDaysByRotation(employeeRotation);
        var currentRotationsCount = employeeRotation.weeklyRotations ? employeeRotation.weeklyRotations.length : 0;
        if (currentRotationsCount > 0) {
            if (currentRotationsCount < employeeRotation.rotationsCount) {
                for (var i = 0; i < employeeRotation.rotationsCount - currentRotationsCount; i++) {
                    var weeklyRotation = new WeeklyRotation();
                    var previousWeeklyRotation = employeeRotation.weeklyRotations[currentRotationsCount - 1];
                    weeklyRotation.weekStartDate = moment(previousWeeklyRotation.weekStartDate).add(7, 'days').toDate();
                    weeklyRotation.isCurrent = new Date() >= weeklyRotation.weekStartDate && new Date() < moment(weeklyRotation.weekStartDate).add(7, 'days').toDate();
                    weeklyRotation.weekNumber = previousWeeklyRotation.weekNumber + 1;
                    employeeRotation.weeklyRotations.push(weeklyRotation);
                }
            }
        }
        else {
            var weeklyRotation = new WeeklyRotation();
            weeklyRotation.weekStartDate = moment().startOf('week').toDate();
            weeklyRotation.isCurrent = true;
            weeklyRotation.weekNumber = 0;
            employeeRotation.weeklyRotations.push(weeklyRotation);
        }
        employeeRotation.weeklyRotations.forEach(function (w) { return _this.prepareWeeklyRotation(w, weekDays); });
    };
    WeekDayService.prototype.prepareWeeklyRotation = function (weeklyRotation, weekDays) {
        Assert.isNotNull(weeklyRotation, 'weeklyRotation');
        var dailyRecords = [];
        var _loop_1 = function (dayOfWeekIndex) {
            var dailyRecord = weeklyRotation.dailyRecords.find(function (d) { return d.dayNumber === dayOfWeekIndex; });
            if (dailyRecord === null || dailyRecord === undefined) {
                dailyRecord = new EmployeeRotationRecord(weeklyRotation);
                dailyRecord.dayNumber = dayOfWeekIndex;
                dailyRecord.dayOfWeek = weekDays[dayOfWeekIndex].name;
            }
            dateOn = moment(dailyRecord.weeklyRotation.weekStartDate).add(dailyRecord.dayNumber, 'days').toDate();
            for (var shift = 0; shift < dailyRecord.shifts.length; shift++) {
                dailyRecord.shifts[shift].shift.startDate = this_1.copyTime(dailyRecord.shifts[shift].shift.startDate, dateOn);
                dailyRecord.shifts[shift].shift.endDate = this_1.copyTime(dailyRecord.shifts[shift].shift.endDate, dateOn);
                if (moment(dailyRecord.shifts[shift].shift.endDate).isBefore(dailyRecord.shifts[shift].shift.startDate)) {
                    dailyRecord.shifts[shift].shift.endDate = moment(dailyRecord.shifts[shift].shift.endDate).add(1, 'days').toDate();
                }
            }
            dailyRecords.push(dailyRecord);
        };
        var this_1 = this, dateOn;
        for (var dayOfWeekIndex = 0; dayOfWeekIndex < weekDays.length; dayOfWeekIndex++) {
            _loop_1(dayOfWeekIndex);
        }
        weeklyRotation.dailyRecords = dailyRecords;
    };
    WeekDayService.prototype.copyTime = function (source, target) {
        return moment(target.setHours(source.getHours(), source.getMinutes(), source.getSeconds(), source.getMilliseconds())).toDate();
    };
    WeekDayService.prototype.getWeekDayByName = function (name) {
        var dayIndex = WeekDays.findIndex(function (v) { return v.name === name; });
        return WeekDays[dayIndex];
    };
    WeekDayService.prototype.getWeekDays = function (dayOfWeek, dayNumber) {
        Assert.isNotNull(dayOfWeek, 'dayOfWeek');
        dayNumber = dayNumber % 7;
        var dayIndex = WeekDays.findIndex(function (v) { return v.name === dayOfWeek.name; }) + 1;
        if (dayIndex === undefined) {
            throw new SyntaxError("Cant find the day of week with name: " + dayOfWeek.name);
        }
        dayIndex = (7 + dayIndex - dayNumber) % 7;
        var result = [];
        var resultTail = [];
        for (var dayOfWeekIndex = 0; dayOfWeekIndex < WeekDays.length; dayOfWeekIndex++) {
            if (dayOfWeekIndex < dayIndex) {
                resultTail.push(WeekDays[dayOfWeekIndex]);
            }
            else {
                result.push(WeekDays[dayOfWeekIndex]);
            }
        }
        return result.concat(resultTail);
    };
    return WeekDayService;
}());
export { WeekDayService };
