/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-console.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components-library/components/collapsible-section/collapsible-section.component.ngfactory";
import * as i3 from "../../../../components-library/components/collapsible-section/collapsible-section.component";
import * as i4 from "../../../../components-library/services/collapsible-section.service";
import * as i5 from "../schedule-console-overview/schedule-console-overview.component.ngfactory";
import * as i6 from "../../../../common/services/value-pair-chart-data/value-pair-chart-series-data.service";
import * as i7 from "../schedule-console-overview/schedule-console-overview.component";
import * as i8 from "../../services/schedule-console/schedule-console-filters.service";
import * as i9 from "../../../services/operational-console/operational-console-state.service";
import * as i10 from "@angular/common";
import * as i11 from "../schedule-console-weekly-indicator/schedule-console-weekly-indicator.component.ngfactory";
import * as i12 from "../schedule-console-weekly-indicator/schedule-console-weekly-indicator.component";
import * as i13 from "../schedule-console-graphic-comparison/schedule-console-graphic-comparison.component.ngfactory";
import * as i14 from "../schedule-console-graphic-comparison/schedule-console-graphic-comparison.component";
import * as i15 from "@angular/router";
import * as i16 from "../../../../common/services/state-management/state-management.service";
import * as i17 from "../../../../common/services/component-state/component-state-storage.service";
import * as i18 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i19 from "../../../../common/components/spinner/spinner.component";
import * as i20 from "../schedule-console-toolbar/schedule-console-toolbar.component.ngfactory";
import * as i21 from "../schedule-console-toolbar/schedule-console-toolbar.component";
import * as i22 from "../../../../core/services/toolbar/toolbar-base.service";
import * as i23 from "../../../../common/directives/slx-element-created-selector/slx-element-created-selector.directive";
import * as i24 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i25 from "../../services/schedule-console/schedule-console-toolbar.service";
import * as i26 from "./schedule-console.component";
import * as i27 from "../../services/schedule-console/schedule-console-api.service";
var styles_ScheduleConsoleComponent = [i0.styles];
var RenderType_ScheduleConsoleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleConsoleComponent, data: {} });
export { RenderType_ScheduleConsoleComponent as RenderType_ScheduleConsoleComponent };
function View_ScheduleConsoleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-collapsible-section", [["mode", "blue"]], null, [[null, "expandChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandChanged" === en)) {
        var pd_0 = (_co.onSectionExpandChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CollapsibleSectionComponent_0, i2.RenderType_CollapsibleSectionComponent)), i1.ɵdid(1, 770048, null, 0, i3.CollapsibleSectionComponent, [i4.CollapsibleSectionService], { uniqId: [0, "uniqId"], title: [1, "title"], expanded: [2, "expanded"], mode: [3, "mode"] }, { expandChanged: "expandChanged" }), (_l()(), i1.ɵeld(2, 0, null, 2, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "slx-schedule-console-overview", [], null, null, null, i5.View_ScheduleConsoleOverviewComponent_0, i5.RenderType_ScheduleConsoleOverviewComponent)), i1.ɵprd(512, null, i6.ValuePairChartSeriesDataService, i6.ValuePairChartSeriesDataService, []), i1.ɵdid(5, 638976, null, 0, i7.ScheduleConsoleOverviewComponent, [i6.ValuePairChartSeriesDataService, i8.ScheduleConsoleFiltersService, i9.OperationalConsoleStateService], { consoleConfig: [0, "consoleConfig"], consoleData: [1, "consoleData"], rangeType: [2, "rangeType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.generateStaticUniqId(_co.orgLevel, true); var currVal_1 = i1.ɵinlineInterpolate(1, "Overview - ", ((_co.orgLevel == null) ? null : _co.orgLevel.name), ""); var currVal_2 = _co.restoreExpanded(_co.generateStaticUniqId(_co.orgLevel, true), true); var currVal_3 = "blue"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.consoleConfig; var currVal_5 = _co.scheduleConsoleData; var currVal_6 = _co.selectedRangeType; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6); }, null); }
function View_ScheduleConsoleComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "slx-collapsible-section", [["mode", "white"]], null, [[null, "expandChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandChanged" === en)) {
        var pd_0 = (_co.onSectionExpandChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CollapsibleSectionComponent_0, i2.RenderType_CollapsibleSectionComponent)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), i1.ɵdid(4, 770048, null, 0, i3.CollapsibleSectionComponent, [i4.CollapsibleSectionService], { uniqId: [0, "uniqId"], title: [1, "title"], expanded: [2, "expanded"], mode: [3, "mode"] }, { expandChanged: "expandChanged" }), i1.ɵpad(5, 3), i1.ɵpad(6, 3), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "slx-schedule-console-weekly-indicator", [["class", "schedule-console__weekly"], ["header", ""]], null, null, null, i11.View_ScheduleConsoleWeeklyIndicatorComponent_0, i11.RenderType_ScheduleConsoleWeeklyIndicatorComponent)), i1.ɵdid(8, 49152, null, 0, i12.ScheduleConsoleWeeklyIndicatorComponent, [i1.ChangeDetectorRef], { days: [0, "days"] }, null), (_l()(), i1.ɵeld(9, 0, null, 2, 2, "div", [["body", ""], ["class", "schedule-console__charts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "slx-schedule-console-graphic-comparison", [["class", "schedule-console__chart wide"]], null, [[null, "onNeedScroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNeedScroll" === en)) {
        var pd_0 = (_co.scrollToGraphicComparison($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_ScheduleConsoleGraphicComparisonComponent_0, i13.RenderType_ScheduleConsoleGraphicComparisonComponent)), i1.ɵdid(11, 49152, null, 0, i14.ScheduleConsoleGraphicComparisonComponent, [i15.Router, i15.ActivatedRoute, i16.StateManagementService, i17.ComponentStateStorageService], { comparisons: [0, "comparisons"], comparisonName: [1, "comparisonName"], consoleConfig: [2, "consoleConfig"], chart: [3, "chart"], rangeType: [4, "rangeType"] }, { onNeedScroll: "onNeedScroll" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.generateDynamicUniqId(_ck(_v, 5, 0, _v.parent.parent.parent.context.$implicit, _v.parent.parent.context.$implicit, _v.context.$implicit)); var currVal_2 = _v.context.$implicit.name; var currVal_3 = _co.restoreExpanded(_co.generateDynamicUniqId(_ck(_v, 6, 0, _v.parent.parent.parent.context.$implicit, _v.parent.parent.context.$implicit, _v.context.$implicit))); var currVal_4 = "white"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.getWeek(_v.context.$implicit.values); _ck(_v, 8, 0, currVal_5); var currVal_6 = _v.context.$implicit.values; var currVal_7 = _v.context.$implicit.name; var currVal_8 = _co.consoleConfig; var currVal_9 = _co.shownChart; var currVal_10 = _co.selectedRangeType; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
function View_ScheduleConsoleComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_6)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.values; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScheduleConsoleComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-schedule-console-graphic-comparison", [["class", "schedule-console__chart padding-bottom"]], null, [[null, "onNeedScroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNeedScroll" === en)) {
        var pd_0 = (_co.scrollToGraphicComparison($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_ScheduleConsoleGraphicComparisonComponent_0, i13.RenderType_ScheduleConsoleGraphicComparisonComponent)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), i1.ɵdid(4, 49152, null, 0, i14.ScheduleConsoleGraphicComparisonComponent, [i15.Router, i15.ActivatedRoute, i16.StateManagementService, i17.ComponentStateStorageService], { comparisons: [0, "comparisons"], comparisonName: [1, "comparisonName"], consoleConfig: [2, "consoleConfig"], chart: [3, "chart"], rangeType: [4, "rangeType"] }, { onNeedScroll: "onNeedScroll" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "schedule-console__chart padding-bottom"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.values; var currVal_3 = _v.context.$implicit.name; var currVal_4 = _co.consoleConfig; var currVal_5 = _co.shownChart; var currVal_6 = _co.selectedRangeType; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_ScheduleConsoleComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["body", ""], ["class", "schedule-console__charts wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_8)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.values; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScheduleConsoleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "slx-collapsible-section", [["body", ""], ["mode", "gray"]], null, [[null, "expandChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandChanged" === en)) {
        var pd_0 = (_co.onSectionExpandChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CollapsibleSectionComponent_0, i2.RenderType_CollapsibleSectionComponent)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), i1.ɵdid(4, 770048, null, 0, i3.CollapsibleSectionComponent, [i4.CollapsibleSectionService], { uniqId: [0, "uniqId"], title: [1, "title"], expanded: [2, "expanded"], mode: [3, "mode"] }, { expandChanged: "expandChanged" }), i1.ɵpad(5, 2), i1.ɵpad(6, 2), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_ScheduleConsoleComponent_5)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_ScheduleConsoleComponent_7)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.generateDynamicUniqId(_ck(_v, 5, 0, _v.parent.context.$implicit, _v.context.$implicit)); var currVal_2 = _v.context.$implicit.name; var currVal_3 = _co.restoreExpanded(_co.generateDynamicUniqId(_ck(_v, 6, 0, _v.parent.context.$implicit, _v.context.$implicit))); var currVal_4 = "gray"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = !_co.isDayRange; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.isDayRange; _ck(_v, 10, 0, currVal_6); }, null); }
function View_ScheduleConsoleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-collapsible-section", [["mode", "blue"]], null, [[null, "expandChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandChanged" === en)) {
        var pd_0 = (_co.onSectionExpandChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CollapsibleSectionComponent_0, i2.RenderType_CollapsibleSectionComponent)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), i1.ɵdid(4, 770048, null, 0, i3.CollapsibleSectionComponent, [i4.CollapsibleSectionService], { uniqId: [0, "uniqId"], title: [1, "title"], expanded: [2, "expanded"], mode: [3, "mode"] }, { expandChanged: "expandChanged" }), i1.ɵpad(5, 1), i1.ɵpad(6, 1), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_ScheduleConsoleComponent_4)), i1.ɵdid(8, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.generateDynamicUniqId(_ck(_v, 5, 0, _v.context.$implicit)); var currVal_2 = _v.context.$implicit.name; var currVal_3 = _co.restoreExpanded(_co.generateDynamicUniqId(_ck(_v, 6, 0, _v.context.$implicit)), true); var currVal_4 = "blue"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.values; _ck(_v, 8, 0, currVal_5); }, null); }
function View_ScheduleConsoleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_3)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scheduleConsoleData == null) ? null : _co.scheduleConsoleData.groupByUnit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScheduleConsoleComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "slx-collapsible-section", [["mode", "white"]], null, [[null, "expandChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandChanged" === en)) {
        var pd_0 = (_co.onSectionExpandChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CollapsibleSectionComponent_0, i2.RenderType_CollapsibleSectionComponent)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), i1.ɵdid(4, 770048, null, 0, i3.CollapsibleSectionComponent, [i4.CollapsibleSectionService], { uniqId: [0, "uniqId"], title: [1, "title"], expanded: [2, "expanded"], mode: [3, "mode"] }, { expandChanged: "expandChanged" }), i1.ɵpad(5, 3), i1.ɵpad(6, 3), (_l()(), i1.ɵeld(7, 0, null, 0, 1, "slx-schedule-console-weekly-indicator", [["class", "schedule-console__weekly"], ["header", ""]], null, null, null, i11.View_ScheduleConsoleWeeklyIndicatorComponent_0, i11.RenderType_ScheduleConsoleWeeklyIndicatorComponent)), i1.ɵdid(8, 49152, null, 0, i12.ScheduleConsoleWeeklyIndicatorComponent, [i1.ChangeDetectorRef], { days: [0, "days"] }, null), (_l()(), i1.ɵeld(9, 0, null, 2, 2, "div", [["body", ""], ["class", "schedule-console__charts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "slx-schedule-console-graphic-comparison", [["class", "schedule-console__chart wide"]], null, [[null, "onNeedScroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNeedScroll" === en)) {
        var pd_0 = (_co.scrollToGraphicComparison($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_ScheduleConsoleGraphicComparisonComponent_0, i13.RenderType_ScheduleConsoleGraphicComparisonComponent)), i1.ɵdid(11, 49152, null, 0, i14.ScheduleConsoleGraphicComparisonComponent, [i15.Router, i15.ActivatedRoute, i16.StateManagementService, i17.ComponentStateStorageService], { comparisons: [0, "comparisons"], comparisonName: [1, "comparisonName"], consoleConfig: [2, "consoleConfig"], chart: [3, "chart"], rangeType: [4, "rangeType"] }, { onNeedScroll: "onNeedScroll" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.generateDynamicUniqId(_ck(_v, 5, 0, _v.parent.parent.parent.context.$implicit, _v.parent.parent.context.$implicit, _v.context.$implicit)); var currVal_2 = _v.context.$implicit.name; var currVal_3 = _co.restoreExpanded(_co.generateDynamicUniqId(_ck(_v, 6, 0, _v.parent.parent.parent.context.$implicit, _v.parent.parent.context.$implicit, _v.context.$implicit))); var currVal_4 = "white"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.getWeek(_v.context.$implicit.values); _ck(_v, 8, 0, currVal_5); var currVal_6 = _v.context.$implicit.values; var currVal_7 = _v.context.$implicit.name; var currVal_8 = _co.consoleConfig; var currVal_9 = _co.shownChart; var currVal_10 = _co.selectedRangeType; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }, null); }
function View_ScheduleConsoleComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_13)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.values; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScheduleConsoleComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-schedule-console-graphic-comparison", [["class", "schedule-console__chart padding-bottom"]], null, [[null, "onNeedScroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNeedScroll" === en)) {
        var pd_0 = (_co.scrollToGraphicComparison($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_ScheduleConsoleGraphicComparisonComponent_0, i13.RenderType_ScheduleConsoleGraphicComparisonComponent)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), i1.ɵdid(4, 49152, null, 0, i14.ScheduleConsoleGraphicComparisonComponent, [i15.Router, i15.ActivatedRoute, i16.StateManagementService, i17.ComponentStateStorageService], { comparisons: [0, "comparisons"], comparisonName: [1, "comparisonName"], consoleConfig: [2, "consoleConfig"], chart: [3, "chart"], rangeType: [4, "rangeType"] }, { onNeedScroll: "onNeedScroll" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "schedule-console__chart padding-bottom"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.values; var currVal_3 = _v.context.$implicit.name; var currVal_4 = _co.consoleConfig; var currVal_5 = _co.shownChart; var currVal_6 = _co.selectedRangeType; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_ScheduleConsoleComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["body", ""], ["class", "schedule-console__charts wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_15)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.values; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScheduleConsoleComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "slx-collapsible-section", [["body", ""], ["mode", "gray"]], null, [[null, "expandChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandChanged" === en)) {
        var pd_0 = (_co.onSectionExpandChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CollapsibleSectionComponent_0, i2.RenderType_CollapsibleSectionComponent)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), i1.ɵdid(4, 770048, null, 0, i3.CollapsibleSectionComponent, [i4.CollapsibleSectionService], { uniqId: [0, "uniqId"], title: [1, "title"], expanded: [2, "expanded"], mode: [3, "mode"] }, { expandChanged: "expandChanged" }), i1.ɵpad(5, 2), i1.ɵpad(6, 2), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_ScheduleConsoleComponent_12)), i1.ɵdid(8, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_ScheduleConsoleComponent_14)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.generateDynamicUniqId(_ck(_v, 5, 0, _v.parent.context.$implicit, _v.context.$implicit)); var currVal_2 = _v.context.$implicit.name; var currVal_3 = _co.restoreExpanded(_co.generateDynamicUniqId(_ck(_v, 6, 0, _v.parent.context.$implicit, _v.context.$implicit))); var currVal_4 = "gray"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = !_co.isDayRange; _ck(_v, 8, 0, currVal_5); var currVal_6 = _co.isDayRange; _ck(_v, 10, 0, currVal_6); }, null); }
function View_ScheduleConsoleComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-collapsible-section", [["mode", "blue"]], null, [[null, "expandChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandChanged" === en)) {
        var pd_0 = (_co.onSectionExpandChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CollapsibleSectionComponent_0, i2.RenderType_CollapsibleSectionComponent)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), i1.ɵdid(4, 770048, null, 0, i3.CollapsibleSectionComponent, [i4.CollapsibleSectionService], { uniqId: [0, "uniqId"], title: [1, "title"], expanded: [2, "expanded"], mode: [3, "mode"] }, { expandChanged: "expandChanged" }), i1.ɵpad(5, 1), i1.ɵpad(6, 1), (_l()(), i1.ɵand(16777216, null, 2, 1, null, View_ScheduleConsoleComponent_11)), i1.ɵdid(8, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.generateDynamicUniqId(_ck(_v, 5, 0, _v.context.$implicit)); var currVal_2 = _v.context.$implicit.name; var currVal_3 = _co.restoreExpanded(_co.generateDynamicUniqId(_ck(_v, 6, 0, _v.context.$implicit)), true); var currVal_4 = "blue"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.values; _ck(_v, 8, 0, currVal_5); }, null); }
function View_ScheduleConsoleComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_10)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scheduleConsoleData == null) ? null : _co.scheduleConsoleData.groupByShift); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ScheduleConsoleComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "slx-collapsible-section", [["mode", "blue"]], null, [[null, "expandChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("expandChanged" === en)) {
        var pd_0 = (_co.onSectionExpandChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CollapsibleSectionComponent_0, i2.RenderType_CollapsibleSectionComponent)), i1.ɵprd(512, null, i10.ɵNgClassImpl, i10.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i10.NgClass, [i10.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "hidden": 0 }), i1.ɵdid(4, 770048, null, 0, i3.CollapsibleSectionComponent, [i4.CollapsibleSectionService], { uniqId: [0, "uniqId"], title: [1, "title"], expanded: [2, "expanded"], mode: [3, "mode"] }, { expandChanged: "expandChanged" }), i1.ɵpad(5, 1), i1.ɵpad(6, 1), (_l()(), i1.ɵeld(7, 0, null, 2, 2, "div", [["body", ""], ["class", "schedule-console__charts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "slx-schedule-console-graphic-comparison", [["class", "schedule-console__chart wide"]], null, [[null, "onNeedScroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onNeedScroll" === en)) {
        var pd_0 = (_co.scrollToGraphicComparison($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_ScheduleConsoleGraphicComparisonComponent_0, i13.RenderType_ScheduleConsoleGraphicComparisonComponent)), i1.ɵdid(9, 49152, null, 0, i14.ScheduleConsoleGraphicComparisonComponent, [i15.Router, i15.ActivatedRoute, i16.StateManagementService, i17.ComponentStateStorageService], { comparisons: [0, "comparisons"], comparisonName: [1, "comparisonName"], consoleConfig: [2, "consoleConfig"], chart: [3, "chart"], rangeType: [4, "rangeType"] }, { onNeedScroll: "onNeedScroll" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.hidden); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.generateDynamicUniqId(_ck(_v, 5, 0, _v.context.$implicit)); var currVal_2 = _v.context.$implicit.name; var currVal_3 = _co.restoreExpanded(_co.generateDynamicUniqId(_ck(_v, 6, 0, _v.context.$implicit)), true); var currVal_4 = "blue"; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _v.context.$implicit.values; var currVal_6 = _v.context.$implicit.name; var currVal_7 = _co.consoleConfig; var currVal_8 = _co.shownChart; var currVal_9 = _co.selectedRangeType; _ck(_v, 9, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_ScheduleConsoleComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_17)), i1.ɵdid(2, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.scheduleConsoleData == null) ? null : _co.scheduleConsoleData.groupByPosition); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ScheduleConsoleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "slx-spinner", [], null, null, null, i18.View_SpinnerComponent_0, i18.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i19.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 14, "div", [["class", "slx-high-box schedule-console"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-schedule-console-toolbar", [], null, [[null, "onFilterChange"], [null, "onConfigChange"], [null, "onGrouppingChange"], [null, "onDateRangeChange"], [null, "onDateRangeTypeChange"], [null, "onShownChartChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onFilterChange" === en)) {
        var pd_0 = (_co.onFilterChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("onConfigChange" === en)) {
        var pd_1 = (_co.onConfigChange($event) !== false);
        ad = (pd_1 && ad);
    } if (("onGrouppingChange" === en)) {
        var pd_2 = (_co.onGrouppingChange($event) !== false);
        ad = (pd_2 && ad);
    } if (("onDateRangeChange" === en)) {
        var pd_3 = (_co.onDateRangeChange($event) !== false);
        ad = (pd_3 && ad);
    } if (("onDateRangeTypeChange" === en)) {
        var pd_4 = (_co.onDateRangeTypeChange($event) !== false);
        ad = (pd_4 && ad);
    } if (("onShownChartChange" === en)) {
        var pd_5 = (_co.onShownChartChange($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i20.View_ScheduleConsoleToolbarComponent_0, i20.RenderType_ScheduleConsoleToolbarComponent)), i1.ɵdid(5, 180224, null, 0, i21.ScheduleConsoleToolbarComponent, [i22.TOOLBAR_SERVICE, i16.StateManagementService, i17.ComponentStateStorageService, i4.CollapsibleSectionService], { consoleConfig: [0, "consoleConfig"], selectedRangeType: [1, "selectedRangeType"], startOfWeek: [2, "startOfWeek"], selectedDate: [3, "selectedDate"], groupBy: [4, "groupBy"], origGroupByList: [5, "origGroupByList"], setOrgLevel: [6, "setOrgLevel"], filtersList: [7, "filtersList"], charts: [8, "charts"], shownChart: [9, "shownChart"] }, { onDateRangeChange: "onDateRangeChange", onDateRangeTypeChange: "onDateRangeTypeChange", onConfigChange: "onConfigChange", onGrouppingChange: "onGrouppingChange", onFilterChange: "onFilterChange", onShownChartChange: "onShownChartChange" }), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "slx-high-box__body slx-main-content-indents slx-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "content"], ["slxElementCreatedSelector", ""]], null, [[null, "onCreated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onCreated" === en)) {
        var pd_0 = (_co.groupContainerCreated($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 4210688, null, 0, i23.SlxElementCreatedSelectorDirective, [i1.ElementRef, i1.Renderer2], null, { onCreated: "onCreated" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_1)), i1.ɵdid(10, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_2)), i1.ɵdid(12, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_9)), i1.ɵdid(14, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleComponent_16)), i1.ɵdid(16, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.consoleConfig; var currVal_2 = _co.selectedRangeType; var currVal_3 = _co.startOfWeek; var currVal_4 = _co.selectedDate; var currVal_5 = _co.groupBy; var currVal_6 = _co.groupByList; var currVal_7 = _co.orgLevel; var currVal_8 = _co.filtersList; var currVal_9 = _co.charts; var currVal_10 = _co.shownChart; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = !_co.isPositionData; _ck(_v, 10, 0, currVal_11); var currVal_12 = _co.isGroupedByUnit; _ck(_v, 12, 0, currVal_12); var currVal_13 = _co.isGroupedByShiftGroup; _ck(_v, 14, 0, currVal_13); var currVal_14 = _co.isDisabledGrouping; _ck(_v, 16, 0, currVal_14); }, null); }
export function View_ScheduleConsoleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "schedule-console", [], null, null, null, View_ScheduleConsoleComponent_0, RenderType_ScheduleConsoleComponent)), i1.ɵprd(512, null, i16.StateManagementService, i16.StateManagementService, [i17.ComponentStateStorageService, i24.ColumnSettingsStorageService]), i1.ɵprd(512, null, i22.TOOLBAR_SERVICE, i25.ScheduleConsoleToolbarService, []), i1.ɵdid(3, 245760, null, 0, i26.ScheduleConsoleComponent, [i27.ScheduleConsoleApiService, i15.ActivatedRoute, i15.Router, i16.StateManagementService, i17.ComponentStateStorageService, i8.ScheduleConsoleFiltersService, i9.OperationalConsoleStateService, i22.TOOLBAR_SERVICE, i4.CollapsibleSectionService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ScheduleConsoleComponentNgFactory = i1.ɵccf("schedule-console", i26.ScheduleConsoleComponent, View_ScheduleConsoleComponent_Host_0, {}, {}, []);
export { ScheduleConsoleComponentNgFactory as ScheduleConsoleComponentNgFactory };
