import { IOrgLevelDto } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { IUserProfileDTO, UserProfileModel } from './user-profile.model';
import { IRoleDefinition, RoleDefinition } from 'app/organization';
import { IUserRoleRelationDTO, UserRoleRelation } from './user-role-relations.model';

export interface IUsersRolesDTO {
  relations: IUserRoleRelationDTO[];
  orgLevels: IOrgLevelDto[];
  roles: IRoleDefinition[];
  userProfiles: IUserProfileDTO[];
}

export class UsersRoles {
  public relations: UserRoleRelation[];
  public orgLevels: OrgLevel[];
  public roles: RoleDefinition[];
  public userProfiles: UserProfileModel[];
}

