import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { SсheduleHeaderMode } from '../../models/index';
import { CollapsibleSectionService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';
var CollapsibleSectionComponent = /** @class */ (function () {
    function CollapsibleSectionComponent(sectionService) {
        this.emptyHeader = false;
        this.headerOpenClose = false;
        this.sectionService = sectionService;
        this.expandChanged = new EventEmitter();
    }
    Object.defineProperty(CollapsibleSectionComponent.prototype, "hasCustomIcon", {
        get: function () {
            return _.isString(this.customIcon) && _.size(this.customIcon) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollapsibleSectionComponent.prototype, "hasCounter", {
        get: function () {
            return _.isFinite(this.counter);
        },
        enumerable: true,
        configurable: true
    });
    CollapsibleSectionComponent.prototype.ngOnChanges = function (changes) {
        var self = this;
        _.forEach(changes, function (change, prop) {
            if (changes.previousValue !== change.currentValue) {
                self[prop] = change.currentValue;
            }
        });
    };
    CollapsibleSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sectionSubscription = this.sectionService.subscribeToToggleSections(function (isExpand) {
            _this.expanded = isExpand;
        });
    };
    CollapsibleSectionComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    CollapsibleSectionComponent.prototype.onToggleSection = function () {
        this.expanded = !this.expanded;
        this.expandChanged.emit(this);
    };
    CollapsibleSectionComponent.prototype.toggleIconClass = function () {
        return this.expanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down';
    };
    CollapsibleSectionComponent.prototype.toggleIconTitle = function () {
        return this.expanded ? 'Hide section' : 'Show section';
    };
    CollapsibleSectionComponent.prototype.isShown = function () {
        return this.expanded;
    };
    CollapsibleSectionComponent.prototype.getModeClass = function () {
        var cssClass = '';
        switch (this.mode) {
            case SсheduleHeaderMode.blue:
                cssClass = 'blue';
                break;
            case SсheduleHeaderMode.gray:
                cssClass = 'gray';
                break;
            case SсheduleHeaderMode.white:
                cssClass = 'white';
                break;
            default:
        }
        return cssClass;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CollapsibleSectionComponent.prototype, "sectionSubscription", void 0);
    return CollapsibleSectionComponent;
}());
export { CollapsibleSectionComponent };
