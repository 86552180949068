import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { RecurrenceRuleOption, RecurrenceFrequencies, LMCommonRecurrence } from '../../../models/index';
var LMMonthlyRecurrenceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LMMonthlyRecurrenceComponent, _super);
    function LMMonthlyRecurrenceComponent(manService) {
        var _this = _super.call(this) || this;
        _this.manService = manService;
        _this.monthDay = 1;
        _this.repeatOnDay = 'monthday';
        _this.repeatOnCustom = 'weekday';
        _this.monthLength = 31;
        return _this;
    }
    Object.defineProperty(LMMonthlyRecurrenceComponent.prototype, "monthDayIsReadonly", {
        get: function () {
            return this.repeatMode !== this.repeatOnDay;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMMonthlyRecurrenceComponent.prototype, "weekDayIsReadonly", {
        get: function () {
            return this.repeatMode !== this.repeatOnCustom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMMonthlyRecurrenceComponent.prototype, "canEditRequest", {
        get: function () {
            return this.manService.canChange;
        },
        enumerable: true,
        configurable: true
    });
    LMMonthlyRecurrenceComponent.prototype.ngOnInit = function () {
        var _this = this;
        var startDate = this.defineStartDate();
        var recurrence = this.manService.getRecurrence();
        this.defineDefaults(recurrence, startDate);
        this.defineMode(recurrence);
        this.updatesRestrictionDates();
        this.subscriptions.loaRequest = this.manService
            .subscribeToChangedRequest(function () {
            _this.defineStartDate();
            _this.updatesRestrictionDates();
        });
    };
    LMMonthlyRecurrenceComponent.prototype.onChangeRecurrence = function () {
        var options = new RecurrenceRuleOption(this.interval, null, null);
        this.updatesRestrictionDates();
        this.updatedRepeatMode(options);
        this.updateEndMode(options);
        this.manService.setRecurrence(RecurrenceFrequencies.monthly, options);
    };
    LMMonthlyRecurrenceComponent.prototype.updatedRepeatMode = function (options) {
        switch (this.repeatMode) {
            case this.repeatOnDay:
                options.byMonthDay = this.monthDay;
                break;
            case this.repeatOnCustom:
                options.byWeekDay = [{
                        day: this.weekDay.id,
                        offset: this.offsetPosition.id
                    }];
                break;
        }
    };
    LMMonthlyRecurrenceComponent.prototype.updateEndMode = function (options) {
        switch (this.endMode) {
            case this.endAfterOccurrence:
                options.count = this.count;
                break;
            case this.endOnDate:
                options.until = this.until;
                break;
        }
    };
    LMMonthlyRecurrenceComponent.prototype.updatesRestrictionDates = function () {
        var minRequiredDays = this.interval * this.monthLength;
        var copyOfMinDate = this.copyDate(this.limitMinDate);
        this.calculatedMinDate = new Date(copyOfMinDate.setDate(copyOfMinDate.getDate() + minRequiredDays));
        if (!_.isDate(this.until) || this.until < this.calculatedMinDate) {
            this.until = this.copyDate(this.calculatedMinDate);
        }
    };
    LMMonthlyRecurrenceComponent.prototype.defineDefaults = function (recurrence, startDate) {
        this.interval = recurrence.interval || 1;
        this.count = recurrence.count || 1;
        this.until = recurrence.until || startDate;
        this.monthDay = recurrence.byMonthDay || 1;
        this.weekDays = this.manService.weekDays;
        this.offsetPositions = this.manService.offsetPositions;
        this.weekDay = _.head(this.weekDays);
        this.offsetPosition = _.head(this.offsetPositions);
        if (_.isArray(recurrence.byWeekDay) && _.size(recurrence.byWeekDay) > 0) {
            var day = _.head(recurrence.byWeekDay);
            var d_1 = _.get(day, 'day');
            var o_1 = _.get(day, 'offset');
            this.weekDay = _.find(this.weekDays, function (w) { return w.id === d_1; });
            this.offsetPosition = _.find(this.offsetPositions, function (p) { return p.id === o_1; });
        }
    };
    LMMonthlyRecurrenceComponent.prototype.defineMode = function (recurrence) {
        if (_.isFinite(recurrence.count) && recurrence.count > 0) {
            this.endMode = this.endAfterOccurrence;
        }
        else if (_.isDate(recurrence.until)) {
            this.endMode = this.endOnDate;
        }
        else {
            this.endMode = this.endNever;
        }
        if (_.isFinite(recurrence.byMonthDay)) {
            this.repeatMode = this.repeatOnDay;
        }
        else if (_.isArray(recurrence.byWeekDay) && _.size(recurrence.byWeekDay) > 0) {
            this.repeatMode = this.repeatOnCustom;
        }
        else {
            this.repeatMode = this.repeatOnDay;
        }
    };
    LMMonthlyRecurrenceComponent.prototype.defineStartDate = function () {
        var startDate = this.manService.getLoaDates().startDate;
        this.limitMinDate = startDate || new Date();
        return this.limitMinDate;
    };
    return LMMonthlyRecurrenceComponent;
}(LMCommonRecurrence));
export { LMMonthlyRecurrenceComponent };
