<div class="main-container">
  <div *ngIf="!costCenters.length">
    <div class="allocations-error">There are no cost centers configured.</div>
  </div>

  <div *ngIf="costCenters.length">
    <div class="row">
      <div class="column title">Cost Center</div>
      <div class="column title">Allocation</div>
      <div class="column icon"></div>
    </div>

    <div class="row" *ngFor="let allocation of allocations; let i = index ">
      <div class="column">
        <slx-input-decorator className="input-item slx-no-label slx-no-error-block slx-small-font">
          <slx-dropdown-input slx-input
            emptyOption
            [options]="costCenters"
            [(ngModel)]="allocation.costCenter"
            (ngModelChange)="addRowIfNeed()"
            [required]="true"
            autoSelectFirst="false"
            valueField="code" titleField="code"
            name="costCenter"
          ></slx-dropdown-input>
        </slx-input-decorator>
      </div>

      <div class="column">
        <slx-input-decorator className="input-item slx-no-label slx-no-error-block slx-small-font">
          <slx-kendo-number slx-input
            [readonly]="!allocation.costCenter"
            [(ngModel)]="allocation.percentage"
            (ngModelChange)="addRowIfNeed()"
            class="form-control"
            [decimals]="0"
            [step]="1"
            [min]="0"
            [max]="100"
            [slxMin]="0"
            [slxMax]="100"
            [autoCorrect]="true"
            [required]="false"
            name="percentage"
          ></slx-kendo-number>
        </slx-input-decorator>
      </div>

      <div class="column icon">
        <i *ngIf="allocation.costCenter" class="fa fa-question-circle" aria-hidden="true" [slxTooltip]="allocation.costCenter?.description" tipPosition="right"></i>
      </div>

      <div class="column icon deleteIcon">
        <popover-content #costPopover title="Are you sure?" placement="auto right" topOffset="-35">
          <div class="confirmationcontent">
            <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r" title=""
              (click)="onClickDelete(true,i,costPopover)">Yes</button>
            <button type="button" class="slx-button slx-narrow" title=""
              (click)="onClickDelete(false,i,costPopover)">Cancel</button>
          </div>
        </popover-content>
        <i *ngIf="allocation.costCenter || allocation.percentage" class="fa fa-trash" aria-hidden="true"
          (click)="showCostCenterCode()" [popover]="costPopover">
        </i>
      </div>
    </div>

    <div class="row">
      <div class="column"></div>
      <div class="column total">
        Allocation Total: <span>{{ currentSum }}%</span>
      </div>
    </div>

    <div class="allocations-error" *ngIf="!percentageValid">
      Sum of all allocations must equal 100%
    </div>
    <div class="allocations-error" *ngIf="!uniquenessValid">
      There are duplicate cost centers
    </div>
  </div>
</div>

