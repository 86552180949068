/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-swap.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./shift-swap-header/shift-swap-header.component.ngfactory";
import * as i5 from "./shift-swap-header/shift-swap-header.component";
import * as i6 from "../../services/shift-swap/shift-swap-manage.service";
import * as i7 from "./shift-swap-list/shift-swap-list.component.ngfactory";
import * as i8 from "./shift-swap-list/shift-swap-list.component";
import * as i9 from "./shift-swap.component";
var styles_ShiftSwapComponent = [i0.styles];
var RenderType_ShiftSwapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftSwapComponent, data: {} });
export { RenderType_ShiftSwapComponent as RenderType_ShiftSwapComponent };
export function View_ShiftSwapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "slx-shift-swap-header", [], null, null, null, i4.View_ShiftSwapHeaderComponent_0, i4.RenderType_ShiftSwapHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i5.ShiftSwapHeaderComponent, [i6.ShiftSwapManageService], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "slx-shift-swap-list", [["class", "list-scrollable-container"]], null, null, null, i7.View_ShiftSwapListComponent_0, i7.RenderType_ShiftSwapListComponent)), i1.ɵdid(5, 49152, null, 0, i8.ShiftSwapListComponent, [], { list: [0, "list"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.manageService.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = _co.manageService.list; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ShiftSwapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-shift-swap", [], null, null, null, View_ShiftSwapComponent_0, RenderType_ShiftSwapComponent)), i1.ɵdid(1, 245760, null, 0, i9.ShiftSwapComponent, [i6.ShiftSwapManageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftSwapComponentNgFactory = i1.ɵccf("slx-shift-swap", i9.ShiftSwapComponent, View_ShiftSwapComponent_Host_0, {}, {}, []);
export { ShiftSwapComponentNgFactory as ShiftSwapComponentNgFactory };
