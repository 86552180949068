import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { SupervisorGroup, SupervisorGroupsContainer, SupervisorAssignmentAction } from '../../../models/index';
import { SupervisorAssignmentManagementService } from '../../../services/supervisor-assignment/supervisor-assignment-management.service';
import { StateManagementService } from '../../../../common/index';
import { unsubscribe } from '../../../../core/decorators/index';
var formGroup = function (dataItem) { return new FormGroup({
    'name': new FormControl(dataItem.name, Validators.required),
    'supervisors': new FormControl(dataItem.supervisors),
    'employees': new FormControl(dataItem.employees),
    'orgLevel': new FormControl(dataItem.orgLevel)
}); };
var ɵ0 = formGroup;
var SupervisorGroupGridComponent = /** @class */ (function () {
    function SupervisorGroupGridComponent(managementService, changeDetector, stateManagement) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.stateManagement = stateManagement;
        this.gridState = new KendoGridStateHelper();
    }
    Object.defineProperty(SupervisorGroupGridComponent.prototype, "isEditorValid", {
        get: function () {
            if (this.addGroupMode || this.editGroupMode) {
                return this.selectedFormGroup ? this.selectedFormGroup.valid : false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    SupervisorGroupGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('SupervisorGroupGridComponent');
        this.appConfig = appConfig;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (container) {
            _this.container = container;
            _this.records = container.groups;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
            _this.stateManagement.loadedData({});
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.startEditGroupCmdSubscription = this.managementService.startEditGroupCmd$.subscribe(function (value) {
            _this.onEditGroup();
            _this.addGroupMode = false;
            _this.editGroupMode = true;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.startAddGroupCmdSubscription = this.managementService.startAddGroupCmd$.subscribe(function (value) {
            _this.onAddGroup();
            _this.addGroupMode = true;
            _this.editGroupMode = false;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.addedGroupCmdSubscription = this.managementService.addedGroupCmd$.subscribe(function (addedGroup) {
            _this.records.unshift(addedGroup);
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.deletedGroupCmdSubscription = this.managementService.deletedGroupCmd$.subscribe(function (deletedGroup) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.actionCmdSubscription = this.managementService.action$.subscribe(function (action) {
            if (action === SupervisorAssignmentAction.cancel) {
                _this.addGroupMode = false;
                _this.editGroupMode = false;
                _this.cancel();
            }
            if (action === SupervisorAssignmentAction.saveSupervisedGroup) {
                if (_this.addGroupMode) {
                    _this.addGroupMode = false;
                    _this.addGroup();
                }
                else {
                    _this.editGroupMode = false;
                    _this.saveGroup();
                }
            }
        });
    };
    SupervisorGroupGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    SupervisorGroupGridComponent.prototype.selectionChange = function (group, rowIndex) {
        this.selectedRowIndex = rowIndex;
        var selectedRecords = [];
        _.forEach(this.records, function (record) {
            if (record.id !== group.id) {
                record.isSelected = false;
            }
        });
        this.selected = group.isSelected ? group : null;
        if (group.isSelected) {
            selectedRecords.push(group);
        }
        this.managementService.selectedGroups(selectedRecords);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisorGroupGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    SupervisorGroupGridComponent.prototype.onAddGroup = function () {
        this.gridState.closeEditor(this.grid);
        this.selectedFormGroup = formGroup({
            id: 0,
            name: '',
            employees: [],
            supervisors: [],
            orgLevel: this.managementService.currentOrgLevel,
            isSelected: true
        });
        this.grid.addRow(this.selectedFormGroup);
    };
    SupervisorGroupGridComponent.prototype.addGroup = function () {
        var group = this.selectedFormGroup.value;
        this.managementService.addGroupCmd(group);
        this.gridState.closeEditor(this.grid);
    };
    SupervisorGroupGridComponent.prototype.onEditGroup = function () {
        this.selectedFormGroup = formGroup(this.selected);
        this.gridState.editHandlerReactive({ sender: this.grid, dataItem: this.selected, rowIndex: this.selectedRowIndex }, this.selectedFormGroup);
    };
    SupervisorGroupGridComponent.prototype.saveGroup = function () {
        var group = this.selectedFormGroup.value;
        this.selected.name = group.name;
        this.managementService.editGroupCmd(this.selected);
        this.gridState.closeEditor(this.grid);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisorGroupGridComponent.prototype.cancel = function () {
        this.gridState.cancelHandler({ sender: this.grid, rowIndex: this.selectedRowIndex });
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupGridComponent.prototype, "startAddGroupCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupGridComponent.prototype, "addedGroupCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupGridComponent.prototype, "startEditGroupCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupGridComponent.prototype, "deletedGroupCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupGridComponent.prototype, "actionCmdSubscription", void 0);
    return SupervisorGroupGridComponent;
}());
export { SupervisorGroupGridComponent };
export { ɵ0 };
