import { Component, OnInit, Provider, ViewChild } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { EmployeeToTerminate, EmployeeTerminate } from '../../../../models/index';
import { EmployeeTerminateComponent } from '../employee-terminate/employee-terminate.component';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-terminate-dialog',
  templateUrl: 'employee-terminate-dialog.component.html',
  styleUrls: ['employee-terminate-dialog.component.scss']
})
export class EmployeeTerminateDialogComponent implements IDialog {
  public options: DialogOptions;
  public dialogResult: boolean;
  public employee: EmployeeToTerminate;

  public state: {
    isLoading: boolean;
  };
  @ViewChild('employeeTermination', {static: true})
  public employeeTermination: EmployeeTerminateComponent;

  private modalService: ModalService;
  private orgLevelId: number;

  public static openDialog(request: EmployeeToTerminate, modalService: ModalService, callback: (result: boolean, req: EmployeeTerminate) => void): EmployeeTerminateDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 580;
    dialogOptions.width = 650;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: EmployeeToTerminate,
        useValue: request
      }
    ];
    let dialog: EmployeeTerminateDialogComponent = modalService.globalAnchor
      .openDialog(EmployeeTerminateDialogComponent, 'Terminate Employee', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.requestToTerminate);
      });
    return dialog;
  }

  public get requestToTerminate(): EmployeeTerminate {
    return this.employeeTermination.requestToTerminate;
  }

  public get isemployeeTerminationFormValid(): boolean {
    if(this.employeeTermination && this.employeeTermination.ngForm) {
      return this.employeeTermination.ngForm.valid;
    }
    return false;
  }
  constructor(options: DialogOptions,
    modalService: ModalService,
    employee: EmployeeToTerminate,
  ) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
    this.employee = employee;
    this.state = {
      isLoading: false,
    };
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
