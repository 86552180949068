import { TimeclockRestrictionDefinition, ITimeclockRestrictionDefinition, TimeclockDefinition, ITimeclockDefinition } from '../../../organization/models/index';

export interface ITimeclockAssignmentRestriction {
  restriction: ITimeclockRestrictionDefinition;
  timeclock: ITimeclockDefinition;
  lastUpdateDate: string;
}

export class TimeclockAssignmentRestriction {
  public restriction: ITimeclockRestrictionDefinition;
  public timeclock: TimeclockDefinition;
  public lastUpdateDate: Date;
}
