import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { WFMFlagsSyncStatus, WFMStopSyncDetails } from '../../../../configuration/models/wfm-sync';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSettingsManageService } from '../../../../app-settings/services';
var SyncDashboardMigrationComponent = /** @class */ (function () {
    function SyncDashboardMigrationComponent(wfmsyncService, router, route, appSettingManageService) {
        this.wfmsyncService = wfmsyncService;
        this.router = router;
        this.route = route;
        this.onGoing = false;
        this.migration = false;
        this.pause = false;
        this.stop = false;
        this.nextgenPhasedRollout = false;
        this.isNextgenPayrollEnabled = false;
        this.appSettingManageService = appSettingManageService;
    }
    SyncDashboardMigrationComponent.prototype.ngOnInit = function () {
        this.getSettings();
        this.res();
        this.getStopData();
    };
    SyncDashboardMigrationComponent.prototype.res = function () {
        var _this = this;
        this.wfmsyncService.getSyncStatus().then(function (value2) {
            _this.Data = value2.data;
            _this.onGoing = _this.Data.ongoingSync;
            _this.migration = _this.Data.migrationSync;
            _this.pause = _this.Data.pauseSync;
        });
    };
    Object.defineProperty(SyncDashboardMigrationComponent.prototype, "isMigrationDisable", {
        get: function () {
            if (this.onGoing == true && this.migration == false) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    SyncDashboardMigrationComponent.prototype.post = function () {
        var _this = this;
        var req = new WFMFlagsSyncStatus();
        if (this.Data.migrationSync == false && this.migration == true && this.onGoing == true) {
            this.migration = false;
            return;
        }
        if (this.onGoing == true) {
            req.ongoingSync = true;
            req.migrationSync = false;
        }
        else if (this.migration == true) {
            req.ongoingSync = false;
            req.migrationSync = true;
        }
        else if (this.migration == false) {
            req.ongoingSync = true;
            req.migrationSync = false;
        }
        else {
        }
        req.pauseSync = this.pause;
        this.wfmsyncService.postSyncStatus(req).then(function (value2) {
            if (req.ongoingSync == true || req.migrationSync == false) {
                var params = {};
                params.orgLevelId = _this.getOrglevelIdFromRoute();
                var url = "apps/ngp/sync_dashboard?orgLevelId=" + params.orgLevelId;
                _this.router.navigateByUrl(url);
                window.history.replaceState(null, null, "#/" + url);
                window.location.reload();
            }
            _this.res();
        }).catch(function (reason) {
            _this.res();
        });
    };
    SyncDashboardMigrationComponent.prototype.getStopData = function () {
        var _this = this;
        this.wfmsyncService.getStopSyncStatus().then(function (value2) {
            _this.stopSync = value2.data;
            _this.customerId = _this.stopSync.customerId;
            _this.stop = _this.stopSync.stopSync;
        });
    };
    SyncDashboardMigrationComponent.prototype.stopData = function () {
        var _this = this;
        var req1 = new WFMStopSyncDetails();
        req1.customerId = this.customerId;
        req1.stopSync = this.stop;
        this.wfmsyncService.stopSyncStatus(req1).then(function (value2) {
            _this.getStopData();
        }).catch(function (reason) {
            _this.res();
        });
    };
    SyncDashboardMigrationComponent.prototype.getOrglevelIdFromRoute = function () {
        var id = this.route.snapshot.queryParamMap.get('orgLevelId');
        return +id;
    };
    SyncDashboardMigrationComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
                        this.isNextgenPayrollEnabled = appServerConfig.IsNextgenPayrollEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    return SyncDashboardMigrationComponent;
}());
export { SyncDashboardMigrationComponent };
