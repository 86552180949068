<slx-spinner [show]="isLoading">

  <form #gridForm="ngForm" class="content-form">

    <div class="modal-body controls-holder">

          <slx-benefit-payroll-deduction [groupName]="groupName"
          [effectiveDate]="effectiveDate" 
          [canMapPayroll]="canMapPayroll"
          [payrollDedStartDate]="payrollDedStartDate"
          [payrollDedEndDate]="payrollDedEndDate" 
          [dedStartDate]="dedStartDate"
          [dedEndDate]="dedEndDate"
          [startDate]="startDate"
          [endDate]="endDate"
          (dedEndDateChange)="OnDeductionEndDateChange($event)"
          (dedStartDateChange)="OnDeductionStartDateChange($event)"
        >
        </slx-benefit-payroll-deduction>


        <div class="row">
          <div class="col-xs-4">
            <label class="form__label">Select a Benefit Tier</label>
          </div>
          <div class="col-xs-8">
            <slx-dropdown-list class="slx-wide"
              #tiersListField="ngModel"
              [options]="tiers"
              [ngModel]="selectedTier"
              (ngModelChange)="onChangeTier($event)"
              name="tiersList"
              required
            ></slx-dropdown-list>
            <span *ngIf="tiersListField.errors" class="slx-error-block pull-left">
              <span *ngIf="tiersListField.errors?.required" errorMessage for="required"></span>
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            Select Flat Rates
          </div>
        </div>

        <div class="row">
          <div class="col-xs-4">
            <label class="form__label">Employer Contribution</label>
          </div>
          <div class="col-xs-8">
              <slx-number class="slx-wide slx-input-number"
                [format]="currencyFormat"
                [readonly]="true"
                [decimals]="2"
                [(ngModel)]="eeCont"
                name="eeCont"
              ></slx-number>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-4">
            <label class="form__label">Employee Contribution</label>
          </div>
          <div class="col-xs-8 flex__wrap">
            <slx-number #empContField="ngModel"
              class="slx-wide slx-input-number"
              [min]="minEmpCont"
              [max]="maxEmpCont"
              [step]="stepEmpCont"
              [format]="currencyFormat"
              [(ngModel)]="empCont"
              [decimals]="2"
              [required]="true"
              name="empCont"
            ></slx-number>
            <span *ngIf="empContField.errors" class="slx-error-block pull-left">
              <span *ngIf="empContField.errors?.min" errorMessage for="min">Employee contribution have to be greater or equal to {{ minEmpCont }}</span>
              <span *ngIf="empContField.errors?.max" errorMessage for="min">Employee contribution have to be less or equal to {{ maxEmpCont }}</span>
              <span *ngIf="empContField.errors?.required" errorMessage for="required"></span>
            </span>
          </div>
        </div>

      </div>

      <div class="modal-footer">
          <button [disabled]="gridForm.invalid || hasDedError" type="button" (click)="onEnroll()"
          class="btn green"><i class="fas fa-save slx-button__icon" aria-hidden="true"
          ></i>Enroll</button>
          <button type="button" class="btn red"
          (click)="onCancel()"
          ><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
      </div>
  </form>

</slx-spinner>
