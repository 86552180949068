import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../../app.config';

import { StateResetTypes } from '../../../../core/models/settings/reset-types';
import { ControlStateKey, IControlState } from '../../../../core/models/settings/component-states';
import { EmployeesPunchesContainer, EmployeeDailyPunches, PunchesDisplaySettings, LinePunchesColumns } from '../../../models/index';
import { PunchesManagementService } from '../../../services/punches/punches-management.service';
import { mutableSelect, unsubscribe, destroyService } from '../../../../core/decorators/index';
import { ColumnManagementService, StateManagementService, ComponentStateStorageService, DailyPunchesNavigationService } from '../../../../common/services/index';
import { PayCycle } from '../../../../organization/models/index';
import { RangeDates, IRangeDates } from '../../../../common/models/range-dates';
import { PopperContent } from 'ngx-popper';
import { PunchesRouteData } from '../../../models/punches/punches-route-data';
import { PunchUtils } from '../../../utils/punch-utils';

@Component({
  moduleId: module.id,
  selector: 'slx-punches-management',
  templateUrl: 'punches-management.component.html',
  styleUrls: ['punches-management.component.scss'],
  providers: [PunchesManagementService, ColumnManagementService, StateManagementService]
})
export class PunchesManagementComponent implements OnInit, OnDestroy {

  public state: {
    isLoading: boolean;
    isFiltering: boolean;
  };
  public orgLevelId: number;
  public selectedRange: RangeDates;
  public appConfig: IApplicationConfig;
  public dataRangeCollapsed: boolean = true;
  public container: EmployeesPunchesContainer;
  public groupName: string = 'EmployeePunches';


  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private payCycleCalculatedSubscription: Subscription;
  @unsubscribe()
  private savedEmpPunchesSubscription: Subscription;
  @unsubscribe()
  private saveFiltersSubscription: Subscription;

  private readonly m_dateFiltersControlId: string = 'DateFiltersNgx';
  private readonly m_dateFiltersResetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private stateKey: ControlStateKey;
  private qStartDate: string;
  private qEndDate: string;
  private punchesNavService: DailyPunchesNavigationService;

  @destroyService()
  private managementService: PunchesManagementService;
  constructor(managementService: PunchesManagementService,
    private columnManagementService: ColumnManagementService,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private activatedRoute: ActivatedRoute, private router: Router) {

    this.managementService = managementService;
    this.appConfig = appConfig;
    this.state = {
      isLoading: false,
      isFiltering: false
    };
    this.selectedRange = new RangeDates();
    //this.ranges = [];
    this.punchesNavService = new DailyPunchesNavigationService(this.router, this.activatedRoute);
  }

  public ngOnInit(): void {
    this.columnManagementService.init('PunchesManagementComponent');
    this.columnManagementService.initGroup(this.groupName, 15);
    this.stateManagement.init('PunchesManagementComponent');

    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });

    this.loadedSubscription = this.managementService.onLoaded$
      .subscribe((container: EmployeesPunchesContainer) => {
        this.container = container;
      });

    this.savedEmpPunchesSubscription = this.managementService.onSavedEmpPunches$.subscribe(
      (empPunches: EmployeeDailyPunches[]) => {
        _.forEach(empPunches, (empPunch: EmployeeDailyPunches) => {
          const index: number = _.findIndex(this.container.entities, (r: EmployeeDailyPunches) => {
            return moment(r.header.date).isSame(empPunch.header.date) && r.header.employee.id === empPunch.header.employee.id;
          });
          this.container.entities.splice(index, 1, empPunch);
        });
      });

    this.saveFiltersSubscription = this.managementService.saveFilters$.subscribe(() => {
      this.saveFilters();
    });

    this.payCycleCalculatedSubscription = this.managementService.onOrgLevelLoaded$.
      combineLatest(this.activatedRoute.data, this.activatedRoute.queryParams)
      .subscribe(([lvl, data, queryParams]) => {

        if (PunchUtils.isRollup(lvl)) {
          return;
        }

        if (
          this.orgLevelId !== lvl.id ||
          this.qStartDate !== queryParams.startDate && _.isString(queryParams.startDate) ||
          this.qEndDate !== queryParams.endDate && _.isString(queryParams.endDate)
        ) {


          this.orgLevelId = lvl.id;
          this.qStartDate = queryParams.startDate;
          this.qEndDate = queryParams.endDate;
          let range = new RangeDates();
          const routeData: PunchesRouteData = data.punchesRouteData;
          this.managementService.lastPayCycle = routeData.payCycle;

          if (routeData.isMissingOnly) {
            this.managementService.setOnlyMissingNextFilter();
          }

          if (this.qStartDate && this.qEndDate) {
            range.startDate = moment(this.qStartDate, this.appConfig.linkDateFormat).toDate();
            range.endDate = moment(this.qEndDate, this.appConfig.linkDateFormat).toDate();
          } else {
            const dateRange = this.restoreFilters();
            if (dateRange.startDate && dateRange.endDate) {
              range = dateRange;
            } else {
              range = routeData.range;
            }
          }

          this.selectedRange = range;
          this.managementService.loadOrgLevelPunches(this.orgLevelId, range.startDate, range.endDate);
        }
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public toggleDataRangeFilter(): void {
    this.dataRangeCollapsed = !this.dataRangeCollapsed;
  }
  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.selectedRange = { startDate, endDate };
    this.activatedRoute.queryParamMap.subscribe((params: any) => {
      if(params.get('startDate') && params.get('endDate') && (params.get('startDate') !== moment(startDate).format(appConfig.linkDateFormat) || params.get('endDate') !== moment(endDate).format(appConfig.linkDateFormat))) {
        this.punchesNavService.navigateToDailyPunchesDates(this.orgLevelId, this.selectedRange.startDate, this.selectedRange.endDate);
      } else {
        this.managementService.loadOrgLevelPunches(this.orgLevelId, this.selectedRange.startDate, this.selectedRange.endDate);
        this.saveFilters();
      }
    });
  }

  public onSaveAllChanges(): void {
    this.managementService.saveAllChanges();
  }

  public onClose(popper: PopperContent): void {
    if (popper) {
      popper.hide();
    }
  }

  public filteringState(state: boolean): void {
    setTimeout(() => {
      this.state.isFiltering = state;
    }, state ? 1 : 100);
  }

  private saveFilters(): void {
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      this.m_dateFiltersControlId,
      {
        value: { startDate: this.selectedRange.startDate, endDate: this.selectedRange.endDate }
      },
      this.m_dateFiltersResetType,
      this.stateKey
    );
    this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
  }

  private restoreFilters(): RangeDates {
    const state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
    const startDate: Date = _.get(state, 'value.startDate');
    const endDate: Date = _.get(state, 'value.endDate');

    const dateRange: RangeDates = new RangeDates();
    dateRange.startDate = startDate ? moment(startDate).toDate() : null;
    dateRange.endDate = endDate ? moment(endDate).toDate() : null;

    return dateRange;
  }

}
