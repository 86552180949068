import { Pipe, PipeTransform } from '@angular/core';
import { ColorUtil } from '../utils/index';

@Pipe({ name: 'DecHex' })
export class DecHexPipe implements PipeTransform {

  public transform(value: number): string {
    let str: string = `#${this.displayInHex(value)}`;
    return str;
  }

  private displayInHex(c: number): string {
    return ColorUtil.DecToHexString(c);
  }

}


