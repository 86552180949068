<kendo-multiselect [data]="dataFiltered" [disabled]="disabled"
    [filterable]="true"
    (filterChange)="filterChange($event)"
    placeholder="{{placeholder}}"
    [textField]="titleField"
    [valueField]="valueField"
    [(ngModel)]="internalValue"
    [class.readonly]="readonly"
>
</kendo-multiselect>
