import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { screenUtils } from '../../../../../common/utils';
import { getAcaRelatedExportTypes, AcaExportType } from '../../../enums/aca-export-type';
import { ExportDataStatus } from '../../../enums/export-data-status';
import { InfoDialogComponent, ModalService, ConfirmDialog2Component } from '../../../../../common';
import { AcaExportConfiguration } from '../../../models/aca-export/aca-export-configuration';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportCorrectionType } from '../../../models/aca-export/aca-export-correctiontype';
import { ConfirmOptions } from '../../../../../scheduler/components/individual-schedule/overlapping-schedule-popup/overlapping-schedule-popup.component';
var AcaExportHistoryComponent = /** @class */ (function () {
    function AcaExportHistoryComponent(manService, changeDetector, modalService) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.modalService = modalService;
        this.pageSize = 10;
        this.sort = [];
        this.subscriptions = {};
        this.messages = {
            isDownloadAvailable: 'Exports completed more than 1 years can not be downloaded'
        };
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.view = null;
        this.gridState.state.sort = [{ field: 'startedOn', dir: 'desc' }];
    }
    Object.defineProperty(AcaExportHistoryComponent.prototype, "exportDataStatus", {
        get: function () { return ExportDataStatus; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaExportHistoryComponent.prototype, "acaExportType", {
        get: function () { return AcaExportType; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaExportHistoryComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    AcaExportHistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.subscriptions.loadedHistory = this.manService.subscribeToLoadedHistory(function (data) {
            var relatedExports = getAcaRelatedExportTypes(_this.configuration.exportType);
            data = _.filter(data, function (x) { return _.includes(relatedExports, x.type); });
            if (!_this.configuration.isCollapsed && _this.configuration.history && _this.configuration.history.length > 0) {
                data = _.concat(data, _this.configuration.history);
            }
            if (data.length === 0 || (_this.configuration.isCollapsed && (!_this.configuration.history || (_this.configuration.history && _this.configuration.history.length == 0)))) {
                return;
            }
            _this.configuration.history = data;
            if (_this.configuration && _this.gridState.state) {
                _this.gridState.state.skip = _this.configuration.pageSkip;
            }
            _this.records = data;
            _this.refreshGrid();
        });
        this.subscriptions.loadedStatuses = this.manService.subscribeToLoadedStatuses(function (data) {
            var relatedExports = getAcaRelatedExportTypes(_this.configuration.exportType);
            data = _.filter(data, function (x) { return _.includes(relatedExports, x.exportType); });
            _.each(data, function (x) {
                if (_this.configuration.lastExecuted && _this.configuration.lastExecuted.id === x.executionId) {
                    _this.configuration.lastExecuted.status = x.status;
                    _this.configuration.lastExecuted.reason = x.reason;
                }
                if (_this.configuration.history) {
                    var historyItem = _.find(_this.configuration.history, function (y) { return y.id === x.executionId; });
                    if (historyItem) {
                        historyItem.status = x.status;
                        historyItem.reason = x.reason;
                        if (x.status === ExportDataStatus.Completed || x.status === ExportDataStatus.Expired) {
                            historyItem.completedOn = x.changedOn;
                        }
                    }
                }
            });
            if (_this.configuration.history) {
                _this.records = _this.configuration.history;
                _this.refreshGrid();
            }
        });
    };
    AcaExportHistoryComponent.prototype.ngOnDestroy = function () {
        this.records = null;
        this.configuration.history = null;
        this.changeDetector.detach();
    };
    AcaExportHistoryComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    AcaExportHistoryComponent.prototype.pageChange = function (event) {
        this.gridState.state.skip = event.skip;
        this.configuration.pageSkip = event.skip;
        this.refreshGrid();
    };
    AcaExportHistoryComponent.prototype.filterChange = function (filter) {
        _.forEach(this.records, function (item) {
            if (item.completedOn != null) {
                item.completedOn.setSeconds(0, 0);
            }
            if (item.startedOn != null) {
                item.startedOn.setSeconds(0, 0);
            }
        });
        this.gridState.state.filter = filter;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    AcaExportHistoryComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    AcaExportHistoryComponent.prototype.isDownloadAvailable = function (data) {
        if (ExportDataStatus.Completed) {
            return data.status === ExportDataStatus.Completed && data.completedOn < moment(data.completedOn).add(1, 'years').toDate();
        }
        if (ExportDataStatus.Failed) {
            return data.status === ExportDataStatus.Failed && data.completedOn < moment(data.completedOn).add(1, 'years').toDate();
        }
    };
    AcaExportHistoryComponent.prototype.downloadFile = function (data) {
        this.manService.downloadExportData(this.configuration.exportType, data.id);
    };
    AcaExportHistoryComponent.prototype.showReasonInfoPopup = function (data) {
        if (this.isMobile && data.reason) {
            InfoDialogComponent.OpenDialog('Information', data.reason, this.modalService);
        }
    };
    AcaExportHistoryComponent.prototype.showDownloadInfoPopup = function (data) {
        if (this.isMobile && !this.isDownloadAvailable(data)) {
            InfoDialogComponent.OpenDialog('Information', this.messages.isDownloadAvailable, this.modalService);
        }
    };
    AcaExportHistoryComponent.prototype.showAcaExportCorrectionsPopup = function (data) {
        var _this = this;
        if (data.type == AcaExportType.ExportXmlReplacement1095C) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialog2Component.openDialog('Corrected Replacement XML', 'You are attempting to correct an export while "Replacement?" is set to Yes. To export a Replacement filing, please instead use the Generate button from the Production XML (IRS Filing) section. Do you wish to continue correcting the selected export?', this.modalService, function (result) {
                if (result) {
                    _this.openAcaCorrectionDialog(data);
                }
            }, options);
        }
        else {
            this.openAcaCorrectionDialog(data);
        }
    };
    AcaExportHistoryComponent.prototype.openAcaCorrectionDialog = function (data) {
        var dataOptions = new AcaExportDialogOptions();
        dataOptions.dialogType = 1;
        dataOptions.acaExportExecutionItem = data;
        dataOptions.acaExportCorrectionType = new AcaExportCorrectionType();
        dataOptions.acaExportCorrectionType.receiptId = null;
        this.manService.openAcaExportDialog(dataOptions);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaExportHistoryComponent.prototype, "subscriptions", void 0);
    return AcaExportHistoryComponent;
}());
export { AcaExportHistoryComponent };
