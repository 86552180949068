import { Assert } from '../../framework/index';

export abstract class BaseSession {
  public readonly token: string;

  constructor(token: string) {
    Assert.isNotNull(token, 'token');

    this.token = token;
  }
}
