import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const PBJ_ID_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SlxPbjIdValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[slxPbjId][formControlName],[slxPbjId][formControl],[slxPbjId][ngModel]',
  /* tslint:enable */
  providers: [PBJ_ID_VALIDATOR]
})
export class SlxPbjIdValidator implements Validator {
  public validate(c: AbstractControl): {[key: string]: any} {
    return CommonValidators.pbjId(c);
  }
}
