import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { appCommonRoutes, employeeSectionRoutes, userProfileRoutes } from './portal.common.routes';
import { PbjLogExportComponent } from '../app-modules/pbj/components/index';

import {
  ApplicationContainerComponent,
  HeaderComponent,
  LeftSidebarComponent
} from './components/index';


export const pbjRoutes: Routes = [
  {
    path: '',
    component: ApplicationContainerComponent,
    children: [
      {
        path: '',
        outlet: 'leftSidebar',
        component: LeftSidebarComponent
      },
      {
        path: '',
        redirectTo: 'pbj_exports',
        pathMatch: 'full'
      },
      ...appCommonRoutes
    ]
  },
  ...employeeSectionRoutes,
  ...userProfileRoutes
];


