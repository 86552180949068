import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { DateRange } from '../../../../core/models/index';
import { ColumnSettingsStorageService, ColumnManagementService } from '../../../../common/services/index';
import { WCComparisonsState, WcViewList, WcViewItem } from '../../models/index';
import { WcCommonManagementService } from './wc-common-management.service';
import { WcRosterToolbarService } from './wc-roster-toolbar.service';
import { WcRosterApiService } from './wc-roster-api.service';
var WcComparisonsManagementService = /** @class */ (function () {
    function WcComparisonsManagementService(manService, toolbarService, apiService, columnSettingsStorageService, columnManagementService) {
        this.manService = manService;
        this.toolbarService = toolbarService;
        this.apiService = apiService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.columnManagementService = columnManagementService;
        this.dateRange = null;
        this.loading$ = new ReplaySubject(1);
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.exportTo$ = new Subject();
        this.state$ = new ReplaySubject(1);
        this.comparisonsLoaded$ = new ReplaySubject(1);
        this.subscriptions = {};
    }
    WcComparisonsManagementService.prototype.init = function (componentId) {
        this.componentId = componentId;
        this.columnsVisibilityKey = 'columnsVisibility';
        this.currentViewMode = new WcViewItem(WcViewList.Comparisons, '', '');
        this.subscribeToToolbarService();
        this.subscribeToOrgLevelChanges();
        this.restoreState();
    };
    WcComparisonsManagementService.prototype.destroy = function () {
        this.manService.destroy();
        this.orgLevelChanged$.complete();
        this.comparisonsLoaded$.complete();
        this.loading$.complete();
        this.exportTo$.complete();
        this.state$.complete();
    };
    WcComparisonsManagementService.prototype.subscribeToOrgLevel = function (callback) {
        return this.manService.subscribeToOrgLevel(callback);
    };
    WcComparisonsManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    WcComparisonsManagementService.prototype.subscribeToChangeState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.state$.subscribe(callback);
    };
    WcComparisonsManagementService.prototype.subscribeToLoading = function (callback) {
        return this.subscribeToCompLoading(callback);
    };
    WcComparisonsManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        return this.subscribeToLoadedComparisonsRecords(callback);
    };
    WcComparisonsManagementService.prototype.subscritpionToSenseYearChange = function (callback) {
        return this.toolbarService.subscritpionToSenseYearChange(callback);
    };
    WcComparisonsManagementService.prototype.openEditReportDialog = function (reportId) {
        this.manService.openEditReportDialog(reportId);
    };
    WcComparisonsManagementService.prototype.subscribeToCompLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    WcComparisonsManagementService.prototype.subscribeToToolbarService = function () {
        var _this = this;
        this.subscriptions.export = this.toolbarService
            .subscribeToExport(function (isPDF) { return _this.exportTo$.next(isPDF); });
        this.subscriptions.saveState = this.toolbarService
            .subscribeToSaveState(function (s) {
            _this.columnSettingsStorageService.setColumnsState(_this.componentId, _this.columnsVisibilityKey, s.columns);
            _this.state$.next(s);
        });
        this.subscriptions.defineState = this.toolbarService
            .subscribeToDefineState(function (s) {
            _this.state$.next(s);
        });
        this.subscriptions.comparisons = this.toolbarService
            .subscritpionToSenseYearChange(function (selectedYearsList) {
            _this.yearRange = selectedYearsList;
            var sDate = new Date(selectedYearsList[0], 0, 1);
            var eDate = (selectedYearsList[selectedYearsList.length - 1] == moment().year()) ? new Date() : new Date(selectedYearsList[selectedYearsList.length - 1], 11, 31);
            _this.dateRange = new DateRange(sDate, eDate);
            _this.loadComparisons();
            _this.restoreState();
        });
    };
    WcComparisonsManagementService.prototype.subscribeToLoadedComparisonsRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.comparisonsLoaded$.subscribe(callback);
    };
    WcComparisonsManagementService.prototype.loadComparisons = function () {
        var _this = this;
        if (!_.isFinite(_.get(this.orgLevel, 'id'))
            || !_.isDate(_.get(this.dateRange, 'startDate'))
            || !_.isDate(_.get(this.dateRange, 'endDate')))
            return;
        this.loading$.next(true);
        this.apiService.getComparisons(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
            .then(function (container) {
            _this.comparisonsLoaded$.next(container);
            _this.loading$.next(false);
            _this.toolbarService.permissionsChanged(container.canEdit);
        });
    };
    WcComparisonsManagementService.prototype.subscribeToOrgLevelChanges = function () {
        this.subscribeToOrgLevelChangesFromComparisons();
    };
    WcComparisonsManagementService.prototype.subscribeToOrgLevelChangesFromComparisons = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id)
                    return [2 /*return*/];
                this.orgLevel = orgLevel;
                this.orgLevelChanged$.next(this.orgLevel);
                this.loadComparisons();
                return [2 /*return*/];
            });
        }); });
    };
    WcComparisonsManagementService.prototype.restoreState = function () {
        var state = new WCComparisonsState();
        state.dynamicMonthCount(this.yearRange);
        state.createColumns();
        state.dynamicColumn();
        this.restoreComparisonState(state);
    };
    WcComparisonsManagementService.prototype.restoreComparisonState = function (state) {
        var _this = this;
        this.columnManagementService.init(this.componentId);
        this.columnManagementService.initializeGroupWithColumns(this.columnsVisibilityKey, state.columns);
        this.subscriptions.state = this.columnManagementService.groupInitialized$
            .filter(function (event) { return event.group === _this.columnsVisibilityKey; })
            .subscribe(function () {
            _this.toolbarService.defineState(state);
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], WcComparisonsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], WcComparisonsManagementService.prototype, "subscriptions", void 0);
    return WcComparisonsManagementService;
}());
export { WcComparisonsManagementService };
