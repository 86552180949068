<div class="slx-is-toolbar flex-row">
  <slx-toolbar class="slx-is-toolbar-container">
    <ng-template slxToolbarSectionTemplate width="350">
      <div class="slx-is-toolbar-dropdown slx-toolbar-section slx-no-padd-l position-selector">
        <slx-dropdown-list
          class="slx-wide slx-small-label"
          [options]="positions"
          [ngModel]="selectedPosition"
          placeholder="Position:"
          valueField="id"
          titleField="name"
          (ngModelChange)="onPositionChanged($event)"
        ></slx-dropdown-list>
      </div>

      <div class="slx-is-toolbar-dropdown slx-toolbar-section slx-border slx-down period-selector">
        <slx-dropdown-list
          *ngIf="stateService.selectedPositionDefinition"
          class="slx-wide slx-small-label"
          placeholder="Period:"
          [ngModel]="stateService.selectedPositionDefinitionPeriod"
          [options]="stateService.selectedPositionDefinition.periods"
          valueField="name"
          titleField="name"
          (ngModelChange)="onPeriodChanged($event)"
          icon="fal fa-calendar-alt"
        ></slx-dropdown-list>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate width="125">
      <div class="slx-toolbar-section slx-is-toolbar-button">
        <button
          class="slx-button slx-with-icon slx-mobile-adapted indent-right"
          type="button"
          (click)="onOverviewClick()"
        >
          <i class="fal fa-list slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Overview</span>
        </button>
      </div>
    </ng-template>
  </slx-toolbar>

  <div class="slx-toolbar-section slx-no-padd-r flex-row">
    <div class="col import-col">
      <ng-container *ngIf="this.stateService.actionData && this.stateService.actionData.canFlag && this.stateService.actionData.canEdit">
        <button class="import-col-btn" (click)="openDownloadImportTemplateModal(false)">
          <i class="far fa-file-import"></i>
          Import
        </button>
      </ng-container>
    </div>
    <div class="col col-exception">
      <div class="filter" style="margin-right: 1rem">
        <slx-actions-list>
          <slx-actions-button
            (click)="checkData()"
            className="low-height auto-width"
            [popperContent]="Actionsettings"
            [popperPosition]="'bottom-end'"
          >
            <i class="fas fa-cog" aria-hidden="true"></i> <span style="margin-left: 10px">Actions</span>
          </slx-actions-button>
          <popper-content #Actionsettings>
            <slx-actions-list-item
              [title]="getActionCaption()"
              [ngClass]="{ disabledbtn: isDisabled }"
              [disabled]="isDisabled == true ? true : false"
              (click)="exportToExcel('true')"
              >Export to Excel</slx-actions-list-item
            >
            <slx-actions-list-item
              [title]="getActionCaption()"
              (click)="captureIdealScheduleScreen('pdf')"
              [disabled]="isDisabled == true ? true : false"
              >Export to PDF</slx-actions-list-item
            >
            <slx-actions-list-item *ngIf="isDownloadImportOptionEnabled" (click)="openDownloadImportTemplateModal(true)">Download Import Template</slx-actions-list-item>
          </popper-content>
        </slx-actions-list>
      </div>
    </div>
    <ng-container *ngIf="stateService.isCurrentOrFutureSchedule(stateService.selectedPosition)">
      <button
        type="button"
        class="theme-button-cancel margin-r"
        (click)="onDiscard()"
        [disabled]="!stateService.selectedPosition.hasChanges"
      >
        <i class="fa fa-ban" aria-hidden="true"></i> Discard
      </button>
      <button type="button" class="theme-button-apply" (click)="onSave()" [disabled]="!stateService.canBeSaved() || this.stateService.isShiftNavPopupEnabled">
        <i class="fas fa-save" aria-hidden="true"></i> Save
      </button>
    </ng-container>
    <ng-container *ngIf="!stateService.isCurrentOrFutureSchedule(stateService.selectedPosition)">
      <button type="button" class="theme-button-apply" (click)="onSave()">Restore</button>
    </ng-container>
  </div>
</div>
