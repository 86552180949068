/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "ngx-popper";
import * as i3 from "./action-button.component";
var styles_ActionButtonComponent = [];
var RenderType_ActionButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ActionButtonComponent, data: {} });
export { RenderType_ActionButtonComponent as RenderType_ActionButtonComponent };
function View_ActionButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.iconName, " slx-iconed-actions-button__icon"); _ck(_v, 0, 0, currVal_0); }); }
function View_ActionButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.dropDownIcon, " slx-dropdown-iconed-actions-button__icon"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ActionButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 9, "div", [["tabindex", "1"], ["type", "button"]], null, [[null, "keyup"], [null, "popperOnShown"], [null, "popperOnHidden"], [null, "touchstart"], [null, "click"], [null, "mousedown"], [null, "mouseenter"], [null, "touchend"], [null, "touchcancel"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).showOrHideOnMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).showOrHideOnClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).showOrHideOnMouseOver($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).showOnHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i0.ɵnov(_v, 3).hideOnLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("touchcancel" === en)) {
        var pd_5 = (i0.ɵnov(_v, 3).hideOnLeave($event) !== false);
        ad = (pd_5 && ad);
    } if (("mouseleave" === en)) {
        var pd_6 = (i0.ɵnov(_v, 3).hideOnLeave($event) !== false);
        ad = (pd_6 && ad);
    } if (("keyup" === en)) {
        var pd_7 = (_co.onKeyup($event) !== false);
        ad = (pd_7 && ad);
    } if (("popperOnShown" === en)) {
        var pd_8 = (_co.onShown($event) !== false);
        ad = (pd_8 && ad);
    } if (("popperOnHidden" === en)) {
        var pd_9 = (_co.onHidden($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 737280, null, 0, i2.PopperController, [i0.ViewContainerRef, i0.ChangeDetectorRef, i0.ComponentFactoryResolver, i0.Renderer2], { content: [0, "content"], placement: [1, "placement"], showTrigger: [2, "showTrigger"], disableStyle: [3, "disableStyle"] }, { popperOnShown: "popperOnShown", popperOnHidden: "popperOnHidden" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionButtonComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "slx-action-button__content"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ActionButtonComponent_2)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.popperContent; var currVal_2 = _co.popperPosition; var currVal_3 = "click"; var currVal_4 = "true"; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.iconName; _ck(_v, 5, 0, currVal_5); var currVal_6 = _co.dropDownIcon; _ck(_v, 9, 0, currVal_6); }, null); }
export function View_ActionButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-action-button", [], null, null, null, View_ActionButtonComponent_0, RenderType_ActionButtonComponent)), i0.ɵdid(1, 49152, null, 0, i3.ActionButtonComponent, [], null, null)], null, null); }
var ActionButtonComponentNgFactory = i0.ɵccf("slx-action-button", i3.ActionButtonComponent, View_ActionButtonComponent_Host_0, { popperContent: "popperContent", popperPosition: "popperPosition", customClassName: "className", iconName: "iconName", dropDownIcon: "dropDownIcon", fullyCustomStyle: "fullyCustomStyle" }, { popperOnShow: "popperOnShow", popperOnHide: "popperOnHide" }, ["*"]);
export { ActionButtonComponentNgFactory as ActionButtonComponentNgFactory };
