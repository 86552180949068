/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wfm-ongoing-sync.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../wfm-ngp-sync/wfm-ngp-sync.component.ngfactory";
import * as i3 from "../wfm-ngp-sync/wfm-ngp-sync.component";
import * as i4 from "../../../services/wfm/wfm-sync.service";
import * as i5 from "../organization-sync/organization-sync.component.ngfactory";
import * as i6 from "../organization-sync/organization-sync.component";
import * as i7 from "@angular/common";
import * as i8 from "./wfm-ongoing-sync.component";
import * as i9 from "../../../../app-settings/services/app-settings-manage.service";
var styles_WFMOngoingSyncComponent = [i0.styles];
var RenderType_WFMOngoingSyncComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WFMOngoingSyncComponent, data: {} });
export { RenderType_WFMOngoingSyncComponent as RenderType_WFMOngoingSyncComponent };
function View_WFMOngoingSyncComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-wfm-ngp-sync", [], null, null, null, i2.View_WfmNgpSyncComponent_0, i2.RenderType_WfmNgpSyncComponent)), i1.ɵdid(2, 114688, null, 0, i3.WfmNgpSyncComponent, [i4.WfmSyncService], { type: [0, "type"] }, null)], function (_ck, _v) { var currVal_0 = "ongoing"; _ck(_v, 2, 0, currVal_0); }, null); }
function View_WFMOngoingSyncComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-organization-sync", [], null, null, null, i5.View_OrganizationSyncComponent_0, i5.RenderType_OrganizationSyncComponent)), i1.ɵdid(2, 114688, null, 0, i6.OrganizationSyncComponent, [], { type: [0, "type"] }, null)], function (_ck, _v) { var currVal_0 = "ongoing"; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_WFMOngoingSyncComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WFMOngoingSyncComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WFMOngoingSyncComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nextgenPhasedRollout; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.nextgenPhasedRollout; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_WFMOngoingSyncComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-ongoing-sync", [], null, null, null, View_WFMOngoingSyncComponent_0, RenderType_WFMOngoingSyncComponent)), i1.ɵdid(1, 114688, null, 0, i8.WFMOngoingSyncComponent, [i9.AppSettingsManageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WFMOngoingSyncComponentNgFactory = i1.ɵccf("slx-ongoing-sync", i8.WFMOngoingSyncComponent, View_WFMOngoingSyncComponent_Host_0, {}, {}, []);
export { WFMOngoingSyncComponentNgFactory as WFMOngoingSyncComponentNgFactory };
