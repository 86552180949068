import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { IndividualTimecardsState } from '../../models/index';
import { LookupApiService, ApplicationStateBusService } from '../../../organization/services/index';
import { EmployeeSectionsAccrualsApiService } from '../../../employee/employee-sections/services/index';
import { TimecardsApiService } from './timecards-api.service';
import { TimecardsActions } from '../../store/index';
import { ConstraintsApiService } from '../../../organization/services/index';
import { ActivatedRoute } from '@angular/router';
var IndividualTimecardsManagementService = /** @class */ (function () {
    function IndividualTimecardsManagementService(apiService, constraintService, lookupApiService, timecardActions, busService, empSectionsApi, route) {
        var _this = this;
        this.constraintService = constraintService;
        this.route = route;
        this.employees = [];
        this.apiService = apiService;
        this.lookupApiService = lookupApiService;
        this.busService = busService;
        this.empSectionsApi = empSectionsApi;
        this.orgLevelSubscription = this.busService.subscribeToSelectOrgLevel(function (orgLevel) {
            _this.orgLevel = orgLevel;
        });
        this.timecardActions = timecardActions;
        this.onActionCmd$ = new ReplaySubject(1);
        this.onLoadStatus$ = new ReplaySubject(1);
        this.onLoaded$ = new ReplaySubject(1);
        this.onEmployeeLoaded$ = new ReplaySubject(1);
        this.onStateChanged$ = new ReplaySubject(1);
        this.activeEmployee$ = new ReplaySubject(1);
        this.accrualsLoaded$ = new Subject();
        this.currentEmployeeId = parseInt(this.route.snapshot.params['employeeId']);
        this.timecardsSubscription = this.timecards$
            .subscribe(function (settings) {
            _this.settings = settings;
            var state = new IndividualTimecardsState();
            if (!settings.empColumnStates || settings.empColumnStates['firstInit']) {
                state.createEmpColumns();
                state.empColumns.mapColumns();
                _this.timecardActions.changeIndividualTimecardsSettings(state);
            }
            else {
                state.isShowPayRates = settings.isShowPayRates;
                state.isShowHighPrecision = settings.isShowHighPrecision;
                state.createEmpColumns();
                state.empColumns.setState(settings.empColumnStates, false);
                state.empColumns.mapColumns();
            }
            if (_this.container) {
                _this.recreatePayCodeColumns(state, _this.container.usedRulesDefinitions);
            }
            _this.onStateChanged(state);
        });
        this.employeeSubscription = this.timecardsDisplay$
            .subscribe(function (displaySettings) {
            if (_.get(displaySettings, 'gridState.records')) {
                var tempState_1 = _.cloneDeep(displaySettings.gridState);
                // Reset skip and take so we always get the entire list instead of paged list
                tempState_1.state.skip = 0;
                tempState_1.state.take = tempState_1.records.length;
                if (_.get(tempState_1, 'state.group')) {
                    if (!tempState_1.state.sort) {
                        tempState_1.state.sort = [];
                    }
                    tempState_1.state.group.forEach(function (group) {
                        tempState_1.state.sort.unshift({
                            field: group.field,
                            dir: group.dir || 'asc'
                        });
                    });
                    tempState_1.state.group = undefined;
                }
                _this.employees = process(tempState_1.records, tempState_1.state).data;
                _this.onEmployeeLoaded$.next(_this.employees);
                _this.currentEmployeeIndex = _this.employees.map(function (e) { return e.emp.employeePosition.employee.id || -1; }).indexOf(_this.currentEmployeeId);
                if (_this.employees[_this.currentEmployeeIndex]) {
                    _this.activeEmployee$.next(_this.employees[_this.currentEmployeeIndex]);
                }
            }
        });
    }
    IndividualTimecardsManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualTimecardsManagementService.prototype.hasPrevious = function () {
        if (this.employees.length > 0) {
            // 145759 : Skipping if the prev time row card employee is same as current one
            return this.getEmployeeIdByIndex(false) !== -1 ? true : false;
            ;
        }
        return false;
    };
    IndividualTimecardsManagementService.prototype.hasNext = function () {
        if (this.employees.length > 0) {
            // 145759 : Skipping if the next time row card employee is same as current one
            return this.getEmployeeIdByIndex(true) !== -1 ? true : false;
        }
        return false;
    };
    IndividualTimecardsManagementService.prototype.getEmployees = function () {
        return this.employees;
    };
    IndividualTimecardsManagementService.prototype.resetCurrentEmployee = function (newEmployeeId) {
        this.currentEmployeeId = newEmployeeId;
        this.currentEmployeeIndex = this.employees.map(function (e) { return e.emp.employeePosition.employee.id; }).indexOf(this.currentEmployeeId);
        if (this.employees[this.currentEmployeeIndex]) {
            this.timecardActions.changeSelectedEmployeeId(this.currentEmployeeId);
            this.activeEmployee$.next(this.employees[this.currentEmployeeIndex]);
        }
    };
    IndividualTimecardsManagementService.prototype.getEmployeeIdByIndex = function (isNext) {
        var step = isNext ? 1 : -1;
        if (this.currentEmployeeIndex >= 0 && this.currentEmployeeIndex + step >= 0) {
            var newEmployeeIndex = this.currentEmployeeIndex + step;
            var newEmployeeId = _.get(this.employees[newEmployeeIndex], 'emp.employeePosition.employee.id', -1);
            // In the case an employee has multiple rows, we skip as all that employees timecards will be pulled onto the same page
            while (this.currentEmployeeId === newEmployeeId && newEmployeeIndex > 0 && newEmployeeIndex < this.employees.length - 1) {
                newEmployeeIndex = newEmployeeIndex + step;
                newEmployeeId = this.employees[newEmployeeIndex].emp.employeePosition.employee.id || -1;
            }
            if (this.currentEmployeeId !== newEmployeeId && newEmployeeIndex >= 0 && newEmployeeIndex < this.employees.length) {
                return this.employees[newEmployeeIndex].emp.employeePosition.employee.id || -1;
            }
        }
        return -1;
    };
    IndividualTimecardsManagementService.prototype.loadIndividualTimecards = function (employeeId, payCycle) {
        var _this = this;
        this.resetCurrentEmployee(employeeId);
        this.apiService.getIndividualTimecards(employeeId, payCycle)
            .then(function (container) {
            _this.container = container;
            _this.constraintService.isPayrollSubmittedForEmployee(employeeId, payCycle.startDate, payCycle.endDate)
                .then(function (res) {
                _this.container.isPayCycleSubmitted = res;
                _this.recreatePayCodeColumns(_this.state, container.usedRulesDefinitions);
                _this.onLoaded(container);
                _this.onLoadStatusChanged(false);
            })
                .catch(function (reason) {
                _this.onLoadStatusChanged(false);
            });
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    IndividualTimecardsManagementService.prototype.approveTimecards = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.approveTimecard(this.container.employee.id, this.currentPayCycle)
            .then(function (result) {
            _this.loadIndividualTimecards(_this.container.employee.id, _this.currentPayCycle);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    IndividualTimecardsManagementService.prototype.unapproveTimecards = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.unapproveTimecard(this.container.employee.id, this.currentPayCycle)
            .then(function (result) {
            _this.loadIndividualTimecards(_this.container.employee.id, _this.currentPayCycle);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    IndividualTimecardsManagementService.prototype.recalculateTimecards = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        var ids = [this.container.employee.id];
        this.apiService.recalculateTimecards(ids, this.currentPayCycle)
            .then(function (result) {
            _this.loadIndividualTimecards(_this.container.employee.id, _this.currentPayCycle);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    IndividualTimecardsManagementService.prototype.loadAccruals = function (empId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.empSectionsApi.getEmployeeBalances(empId)
            .then(function (accruals) {
            _this.onLoadStatusChanged(false);
            _this.accrualsLoaded$.next(accruals);
        }).catch(function () {
            _this.onLoadStatusChanged(false);
        });
    };
    IndividualTimecardsManagementService.prototype.onStateChanged = function (state) {
        this.state = state;
        if (!this.state) {
            this.state = new IndividualTimecardsState();
            this.state.createEmpColumns();
        }
        this.onStateChanged$.next(state);
    };
    IndividualTimecardsManagementService.prototype.onActionCmd = function (cmd) {
        this.onActionCmd$.next(cmd);
    };
    IndividualTimecardsManagementService.prototype.onLoadStatusChanged = function (isLoading) {
        this.onLoadStatus$.next(isLoading);
    };
    IndividualTimecardsManagementService.prototype.onLoaded = function (container) {
        this.onLoaded$.next(container);
    };
    IndividualTimecardsManagementService.prototype.onPayCycleChanged = function (payCycle) {
        this.currentPayCycle = payCycle;
        if (this.currentEmployeeId !== 0 && this.currentPayCycle) {
            this.loadIndividualTimecards(this.currentEmployeeId, this.currentPayCycle);
        }
    };
    IndividualTimecardsManagementService.prototype.onEmployeeChange = function (employeeId) {
        this.currentEmployeeId = employeeId;
        if (this.currentEmployeeId !== 0 && this.currentPayCycle) {
            this.loadIndividualTimecards(this.currentEmployeeId, this.currentPayCycle);
        }
    };
    IndividualTimecardsManagementService.prototype.onQueryChange = function (employeeId, payCycle) {
        this.currentEmployeeId = employeeId;
        this.currentPayCycle = payCycle;
        if (this.currentEmployeeId !== 0 && this.currentPayCycle) {
            this.loadIndividualTimecards(this.currentEmployeeId, this.currentPayCycle);
        }
    };
    IndividualTimecardsManagementService.prototype.recreatePayCodeColumns = function (state, usedPayRules) {
        if (!this.settings.payCodeColumnStates || this.settings.payCodeColumnStates['firstInit']) {
            state.createPayCodeColumns(usedPayRules);
            state.payCodeColumns.mapColumns();
            this.timecardActions.changeIndividualTimecardsSettings(state);
        }
        else {
            state.createPayCodeColumns(usedPayRules);
            state.payCodeColumns.setState(this.settings.payCodeColumnStates, true);
            state.payCodeColumns.mapColumns();
        }
    };
    tslib_1.__decorate([
        mutableSelect(['timecards', 'individualTimecards']),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualTimecardsManagementService.prototype, "timecards$", void 0);
    tslib_1.__decorate([
        mutableSelect(['timecards', 'timecardsDisplay']),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualTimecardsManagementService.prototype, "timecardsDisplay$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsManagementService.prototype, "timecardsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsManagementService.prototype, "employeeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsManagementService.prototype, "orgLevelSubscription", void 0);
    return IndividualTimecardsManagementService;
}());
export { IndividualTimecardsManagementService };
