import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';
import { ManagementBaseService } from '../../../core/services/index';
import { SupervisorGroupDefinition } from '../../../organization/models/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { SupervisorAssignmentApiService } from './supervisor-assignment-api.service';
import { Supervisor, SupervisorAssignmentAction } from '../../models/index';
var SupervisorAssignmentManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(SupervisorAssignmentManagementService, _super);
    function SupervisorAssignmentManagementService(apiService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.groupsSelected$ = new ReplaySubject(1);
        _this.action$ = new Subject();
        _this.startAddGroupCmd$ = new Subject();
        _this.startEditGroupCmd$ = new Subject();
        _this.startAddSupervisorCmd$ = new Subject();
        _this.startAddEmployeeCmd$ = new Subject();
        _this.addedGroupCmd$ = new Subject();
        _this.editedGroupCmd$ = new Subject();
        _this.deletedGroupCmd$ = new Subject();
        _this.addedSupervisorsCmd$ = new ReplaySubject(1);
        _this.deletedSupervisorsCmd$ = new ReplaySubject(1);
        _this.addedSupervisedEmployeesCmd$ = new ReplaySubject(1);
        _this.deletedSupervisedEmployeesCmd$ = new ReplaySubject(1);
        _this.loadedUnassignedEmployeesCmd$ = new ReplaySubject(1);
        _this.orgLevelSubscription = _this.orgLevel$
            .filter(function (o) { return !_this.currentOrgLevel || o && _this.currentOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            if (!o || !o.id) {
                return;
            }
            _this.onOrgLevelChanged(o);
        });
        return _this;
    }
    SupervisorAssignmentManagementService.prototype.actionCmd = function (act) {
        this.action$.next(act);
    };
    SupervisorAssignmentManagementService.prototype.selectedGroups = function (group) {
        this.groupsSelected$.next(group);
    };
    SupervisorAssignmentManagementService.prototype.startAddGroupCmd = function () {
        this.startAddGroupCmd$.next(null);
    };
    SupervisorAssignmentManagementService.prototype.startEditGroupCmd = function () {
        this.startEditGroupCmd$.next(null);
    };
    SupervisorAssignmentManagementService.prototype.startAddSupervisorCmd = function (group) {
        this.startAddSupervisorCmd$.next(group);
    };
    SupervisorAssignmentManagementService.prototype.startAddEmployeeCmd = function (group) {
        this.startAddEmployeeCmd$.next(group);
    };
    SupervisorAssignmentManagementService.prototype.addGroupCmd = function (group) {
        var _this = this;
        this.onLoadStatusChanged(true);
        var grp = new SupervisorGroupDefinition();
        grp.name = group.name;
        grp.orgLevelId = group.orgLevel.id;
        this.apiService.createGroup(grp)
            .then(function (res) {
            _this.onLoadStatusChanged(false);
            group.id = res.id;
            _this.addedGroupCmd$.next(group);
            _this.onLoaded(_this.container);
        })
            .catch(function (reason) {
            _this.onError(reason);
            _this.startEditGroupCmd$.next(group);
        });
    };
    SupervisorAssignmentManagementService.prototype.editGroupCmd = function (group) {
        var _this = this;
        this.onLoadStatusChanged(true);
        var grp = new SupervisorGroupDefinition();
        grp.id = group.id;
        grp.name = group.name;
        grp.orgLevelId = group.orgLevel.id;
        this.apiService.updateGroup(grp)
            .then(function (res) {
            _this.onLoadStatusChanged(false);
            _this.editedGroupCmd$.next(group);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    SupervisorAssignmentManagementService.prototype.deleteGroupCmd = function (group) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.deleteGroup(this.currentOrgLevel.id, group)
            .then(function (res) {
            _this.onLoadStatusChanged(false);
            _this.container.groups = _.without(_this.container.groups, group);
            _this.deletedGroupCmd$.next(group);
            _this.onLoaded(_this.container);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    SupervisorAssignmentManagementService.prototype.addSupervisorsToGroupCmd = function (group, esupervisors) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.actionCmd(SupervisorAssignmentAction.saveSupervisorsToGroup);
        var supervisors = _.map(esupervisors, function (esupervisor) {
            var supervisor = new Supervisor();
            //supervisor.id = 0;
            supervisor.user = esupervisor.user ? esupervisor.user : undefined;
            if (esupervisor.employee && esupervisor.employee.employee && !isNaN(esupervisor.employee.employee.id) && esupervisor.employee.employee.id !== null) {
                supervisor.employee = esupervisor.employee;
            }
            supervisor.isPrimary = false;
            supervisor.approvePTO = false;
            supervisor.punchOverride = false;
            supervisor.groups = esupervisor.groups;
            return supervisor;
        });
        this.apiService.assignSupervisorsToGroup(this.currentOrgLevel.id, group.id, supervisors)
            .then(function (res) {
            _this.onLoadStatusChanged(false);
            _this.addedSupervisorsCmd$.next({ group: group, supervisors: res });
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    SupervisorAssignmentManagementService.prototype.updateSupervisor = function (supervisor) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.updateSupervisor(supervisor)
            .then(function (res) {
            _this.onLoadStatusChanged(false);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    SupervisorAssignmentManagementService.prototype.deleteSupervisorsFromGroupCmd = function (group, supervisors) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.removeSupervisorsFromGroup(this.currentOrgLevel.id, group.id, supervisors)
            .then(function (res) {
            _this.onLoadStatusChanged(false);
            _this.deletedSupervisorsCmd$.next({ group: group, supervisors: res });
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    SupervisorAssignmentManagementService.prototype.addSupervisedEmployeesToGroupCmd = function (group, employees) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.actionCmd(SupervisorAssignmentAction.saveSupervisedEmployeesToGroup);
        this.apiService.assignEmployeesToGroup(this.currentOrgLevel.id, group.id, employees)
            .then(function (res) {
            _this.onLoadStatusChanged(false);
            _this.addedSupervisedEmployeesCmd$.next({ group: group, employees: res });
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    SupervisorAssignmentManagementService.prototype.deleteSupervisedEmployeesFromGroupCmd = function (group, employees) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.removeEmployeesFromGroup(this.currentOrgLevel.id, group.id, employees)
            .then(function (res) {
            _this.onLoadStatusChanged(false);
            _this.deletedSupervisedEmployeesCmd$.next({ group: group, employees: res });
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    SupervisorAssignmentManagementService.prototype.loadGroups = function (orgLevelId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getSupervisorGroups(orgLevelId)
            .then(function (container) {
            _this.container = container;
            _this.onLoadStatusChanged(false);
            _this.onLoaded(container);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    SupervisorAssignmentManagementService.prototype.loadUnassignedForCurrentOrgLevel = function () {
        this.loadUnassignedEmployees(this.currentOrgLevel.id);
    };
    SupervisorAssignmentManagementService.prototype.loadUnassignedEmployees = function (orgLevelId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getUnassignedEmployees(orgLevelId).then(function (employees) {
            _this.onLoadStatusChanged(false);
            _this.loadedUnassignedEmployeesCmd$.next(employees);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    SupervisorAssignmentManagementService.prototype.onOrgLevelChanged = function (o) {
        if (this.currentOrgLevel && this.currentOrgLevel.id === o.id) {
            return;
        }
        this.currentOrgLevel = o;
        if (this.currentOrgLevel) {
            if (this.currentOrgLevel.type === OrgLevelType.corporation) {
                this.onStateChanged({ orgLvl: false });
            }
            else {
                this.onStateChanged({ orgLvl: true });
                this.loadGroups(this.currentOrgLevel.id);
            }
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], SupervisorAssignmentManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentManagementService.prototype, "orgLevelSubscription", void 0);
    return SupervisorAssignmentManagementService;
}(ManagementBaseService));
export { SupervisorAssignmentManagementService };
