
<ng-container *ngIf="mode==='eligible'">
  <slx-benefit-employees-eligible [lineId]="lineId" [tierDetails]="tierDetails" [isUserCanEnroll]="isUserCanEnroll"
  [planName]="planName" [canEnroll]="canEnroll" [tierId]="tierId" [date]="date" [method]="method" [lowLevelOrgId]="lowLevelOrgId" (selectEmployees)="selectEmployees($event)"></slx-benefit-employees-eligible>
</ng-container>

<ng-container *ngIf="mode==='enrollment'">
  <slx-benefit-employees-enrollment [lineId]="lineId" [tierDetails]="tierDetails"
  [planName]="planName" [canEnroll]="canEnroll" [tierId]="tierId" [date]="date" [method]="method" [benefitDetailsLine]="benefitDetailsLine"
  [canMapPayroll]="canMapPayroll"  [mappedDeduction]="mappedDeduction" [selectedEmployees]="selectedEmployees" (onEnrollmentCancel)="onEnrollmentCancel()"></slx-benefit-employees-enrollment>
</ng-container>
