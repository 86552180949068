import { Provider } from '@angular/core';

export * from './benefit-employees-map.service';
export * from './benefit-employees-api.service';
export * from './benefit-employees-management.service';
export * from './benefit-enrollment-common.service';

import { BenefitEmployeesMapService } from './benefit-employees-map.service';
import { BenefitEmployeesApiService } from './benefit-employees-api.service';
import { BenefitEmployeeManagementService } from './benefit-employees-management.service';
import { BenefitEnrollmentCommonService } from './benefit-enrollment-common.service';

export const benefitEmployeesServices: Provider[] = [
  BenefitEmployeesMapService,
  BenefitEmployeesApiService,
  BenefitEmployeeManagementService,
  BenefitEnrollmentCommonService
];
