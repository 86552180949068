/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ess-templates.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ess-template-section/ess-template-section.component.ngfactory";
import * as i3 from "../ess-template-section/ess-template-section.component";
import * as i4 from "../../services/ess-template-management.service";
import * as i5 from "../../../../common/services/change-management/change-management.service";
import * as i6 from "@angular/common";
import * as i7 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i8 from "../../../../common/components/spinner/spinner.component";
import * as i9 from "../ess-templates-header/ess-templates-header.component.ngfactory";
import * as i10 from "../ess-templates-header/ess-templates-header.component";
import * as i11 from "../../../../app-settings/services/app-settings-manage.service";
import * as i12 from "../../services/ess-templates-api.service";
import * as i13 from "../../../../common/services/modal/modal.service";
import * as i14 from "./ess-templates.component";
import * as i15 from "../../../punch-attestation/services/punch-attestation-management.service";
var styles_EssTemplatesComponent = [i0.styles];
var RenderType_EssTemplatesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EssTemplatesComponent, data: {} });
export { RenderType_EssTemplatesComponent as RenderType_EssTemplatesComponent };
function View_EssTemplatesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-ess-template-sections"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-ess-template-section", [], null, null, null, i2.View_EssTemplateSectionComponent_0, i2.RenderType_EssTemplateSectionComponent)), i1.ɵdid(2, 770048, null, 0, i3.EssTemplateSectionComponent, [i4.EssTemplateManagementService, i1.ChangeDetectorRef, i5.ChangeManagementService], { template: [0, "template"], punchAttestationGroups: [1, "punchAttestationGroups"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.punchAttestationGroups; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EssTemplatesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents ess-templates-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EssTemplatesComponent_2)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EssTemplatesComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "slx-spinner", [], null, null, null, i7.View_SpinnerComponent_0, i7.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i8.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-ess-templates-header", [["class", "slx-content-toolbar-indents"]], null, null, null, i9.View_EssTemplatesHeaderComponent_0, i9.RenderType_EssTemplatesHeaderComponent)), i1.ɵdid(4, 245760, null, 0, i10.EssTemplatesHeaderComponent, [i4.EssTemplateManagementService, i11.AppSettingsManageService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EssTemplatesComponent_1)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); var currVal_1 = !!_co.punchAttestationGroups; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_EssTemplatesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-ess-templates", [], null, null, null, View_EssTemplatesComponent_0, RenderType_EssTemplatesComponent)), i1.ɵprd(512, null, i4.EssTemplateManagementService, i4.EssTemplateManagementService, [i12.EssTemplateApiService, i13.ModalService]), i1.ɵdid(2, 245760, null, 0, i14.EssTemplatesComponent, [i4.EssTemplateManagementService, i1.ChangeDetectorRef, i15.PunchAttestationManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var EssTemplatesComponentNgFactory = i1.ɵccf("slx-ess-templates", i14.EssTemplatesComponent, View_EssTemplatesComponent_Host_0, {}, {}, []);
export { EssTemplatesComponentNgFactory as EssTemplatesComponentNgFactory };
