import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { GridComponent } from '@progress/kendo-angular-grid';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { OperationalConsoleStateService } from '../../../../services/operational-console/operational-console-state.service';
var OperationalConsoleHeaderComponent = /** @class */ (function () {
    function OperationalConsoleHeaderComponent(stateService) {
        this.stateService = stateService;
        stateService.currentDate = moment().toDate();
        // stateService.isLoading = true;
        stateService.entries = [];
        this.employeeLinkClick = new EventEmitter();
    }
    OperationalConsoleHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orglevelSubscription = this.orgLevel$
            .combineLatest(this.application$)
            .subscribe(function (_a) {
            var orgLevel = _a[0], application = _a[1];
            if (!orgLevel || !orgLevel.id || !application || !application.id)
                return;
            _this.stateService.orgLevel = orgLevel;
            _this.stateService.application = application;
            _this.onDateChanged();
        });
    };
    OperationalConsoleHeaderComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    OperationalConsoleHeaderComponent.prototype.onDateChanged = function () {
        var old = this.stateService.canLoadData;
        this.stateService.canLoadData = true;
        this.stateService.loadData();
        this.stateService.canLoadData = old;
    };
    OperationalConsoleHeaderComponent.prototype.onExportToExcel = function () {
        this.grid.saveAsExcel();
    };
    OperationalConsoleHeaderComponent.prototype.onExportToPdf = function () {
        this.grid.saveAsPDF();
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], OperationalConsoleHeaderComponent.prototype, "application$", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], OperationalConsoleHeaderComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleHeaderComponent.prototype, "orglevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleHeaderComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleHeaderComponent.prototype, "employeeLinkClickSubscription", void 0);
    return OperationalConsoleHeaderComponent;
}());
export { OperationalConsoleHeaderComponent };
