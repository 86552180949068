export * from './lookup/lookup.directive';
export * from './empty-option/empty-option.directive';
export * from './employee-image-src/employee-image-src.directive';
export * from './employee-thumbinal-src/employee-thumbinal-src.directive';
export * from './control-state/pay-cycle-selector-state.directive';
export * from './menu-item-scroll/menu-item-scroll.directive';
export * from './accessible/accessible.directive';
export * from './navigation-link/index';

import { LookupDirective } from './lookup/lookup.directive';
import { EmptyOptionDirective } from './empty-option/empty-option.directive';
import { EmptyOptionLdiDirective } from './empty-option-ldi/empty-option-ldi.directive';
import { EmployeeImageSrcDirective } from './employee-image-src/employee-image-src.directive';
import { LookupAddItemDirective } from './lookup-add-item/lookup-add-item.directive';
import { EmployeeThumbinalSrcDirective } from './employee-thumbinal-src/employee-thumbinal-src.directive';
import { PayCycleActiveStateDirective } from './control-state/pay-cycle-selector-state.directive';
import { MenuItemScrollDirective } from './menu-item-scroll/menu-item-scroll.directive';
import { AccessibleDirective } from './accessible/accessible.directive';
import { navigationDirectives } from './navigation-link/index';
import { accessibleSwitchDirectives } from './accessible-switch/index';

export const exportDirectives: any[] = [
  LookupDirective,
  EmptyOptionDirective,
  EmptyOptionLdiDirective,
  EmployeeImageSrcDirective,
  LookupAddItemDirective,
  EmployeeThumbinalSrcDirective,
  PayCycleActiveStateDirective,
  MenuItemScrollDirective,
  AccessibleDirective,
  ...navigationDirectives,
  ...accessibleSwitchDirectives
];

export const directives: any[] = [
  ...exportDirectives,
];
