import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { mutableSelect } from '../../../core/decorators/redux-decorators';
import { unsubscribe } from '../../../core/decorators/unsubscribe-decorator';
import { ISession } from '../../../authentication/store/session/session.types';
import { NotificationType } from '../../models/notification-type.enum';
import { PopperContent } from 'ngx-popper';
import { scheduleMicrotask } from '../../../core/utils/index';
var NotificationButtonComponent = /** @class */ (function () {
    function NotificationButtonComponent() {
        this.popperPosition = 'bottom';
        this.popperOnShow = new EventEmitter();
        this.popperOnHide = new EventEmitter();
        this.notificationsCount = 0;
        this.popperModifiers = {
            arrow: {
                order: 500,
                enabled: true,
                element: '[x-arrow]'
            }
        };
        this.escCode = 27;
        this.notificationTypes = NotificationType;
    }
    NotificationButtonComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSubscription = this.session$.subscribe(function (session) {
            if (session && session.user) {
                switch (_this.notificationType) {
                    case NotificationType.MESSAGES:
                        scheduleMicrotask(function () {
                            _this.notificationsCount = session.user.messagesUnread;
                        });
                        break;
                    case NotificationType.REMINDERS:
                        scheduleMicrotask(function () {
                            _this.notificationsCount = session.user.remidersUnread;
                        });
                        break;
                }
            }
        });
    };
    NotificationButtonComponent.prototype.onKeyup = function (event) {
        if (event.keyCode === this.escCode) {
            this.popperContent.hide();
        }
    };
    NotificationButtonComponent.prototype.onShown = function (popper) {
        this.popupShown = true;
        this.popperOnShow.emit(popper);
    };
    NotificationButtonComponent.prototype.onHidden = function (popper) {
        this.popupShown = false;
        this.popperOnHide.emit(popper);
    };
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], NotificationButtonComponent.prototype, "session$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationButtonComponent.prototype, "userSubscription", void 0);
    return NotificationButtonComponent;
}());
export { NotificationButtonComponent };
