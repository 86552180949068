import { EmployeeSectionsBase } from '..';
import { FieldsMeta } from '../../../../core/models/index';

export interface IAgencyCertificate {
  
  dnr: boolean;
  preferred : boolean ;
  reason: string;
  user: string;
  startDate: Date;
  endDate: Date;
}

export class AgencyCertificate {
 
  public dnr: boolean = false;
  public preferred : boolean = false;
  public reason: string;
  public user: string;
  public startDate: Date;
  public endDate
}

export interface IAgencyCertificates {
  agencyCertificate: AgencyCertificate[];
}
export class AgencyCertificates extends EmployeeSectionsBase {
  public agencyCertificate: AgencyCertificate[];
  public fieldsMeta: FieldsMeta;
}