import { DoCheck, TemplateRef, ViewContainerRef } from '@angular/core';
import { StepView } from './wizard-step-view';
import * as _ from 'lodash';
var StepSwitchDirective = /** @class */ (function () {
    function StepSwitchDirective() {
        this.stepDefinitions = {};
        this.defaultUsed = false;
        this.caseCount = 0;
        this.lastCaseCheckIndex = 0;
        this.lastCasesMatched = false;
    }
    Object.defineProperty(StepSwitchDirective.prototype, "stepSwitch", {
        set: function (newValue) {
            this.selectedStepSwitch = newValue;
            if (this.caseCount === 0) {
                this.updateDefaultCases(true);
            }
        },
        enumerable: true,
        configurable: true
    });
    StepSwitchDirective.prototype.getStepDefinitions = function () {
        var stepDefs = _.map(_.map(this.stepDefinitions), function (view) { return view.stepDefinition; });
        return stepDefs;
    };
    StepSwitchDirective.prototype.addStep = function (view) {
        this.caseCount++;
        this.stepDefinitions[this.caseCount] = view;
        return this.caseCount;
    };
    StepSwitchDirective.prototype.addDefault = function (view) {
        if (!this.defaultViews) {
            this.defaultViews = [];
        }
        this.defaultViews.push(view);
    };
    StepSwitchDirective.prototype.matchStep = function (value) {
        var matched = (value === this.selectedStepSwitch);
        this.lastCasesMatched = this.lastCasesMatched || matched;
        this.lastCaseCheckIndex++;
        if (this.lastCaseCheckIndex === this.caseCount) {
            this.updateDefaultCases(!this.lastCasesMatched);
            this.lastCaseCheckIndex = 0;
            this.lastCasesMatched = false;
        }
        return matched;
    };
    StepSwitchDirective.prototype.updateDefaultCases = function (useDefault) {
        if (this.defaultViews && useDefault !== this.defaultUsed) {
            this.defaultUsed = useDefault;
            _.forEach(this.defaultViews, function (defaultView) {
                defaultView.enforceState(useDefault);
            });
        }
    };
    return StepSwitchDirective;
}());
export { StepSwitchDirective };
var StepCaseDirective = /** @class */ (function () {
    function StepCaseDirective(viewContainer, templateRef, stepSwitch) {
        this.stepSwitch = stepSwitch;
        this.view = new StepView(viewContainer, templateRef);
        var stepNum = stepSwitch.addStep(this.view);
        this.view.setStep(stepNum);
    }
    Object.defineProperty(StepCaseDirective.prototype, "stepCase", {
        set: function (conf) {
            if (this.view && conf) {
                this.view.setConf(conf);
            }
        },
        enumerable: true,
        configurable: true
    });
    StepCaseDirective.prototype.ngDoCheck = function () {
        this.view.enforceState(this.stepSwitch.matchStep(this.view.step));
    };
    return StepCaseDirective;
}());
export { StepCaseDirective };
var StepDefaultDirective = /** @class */ (function () {
    function StepDefaultDirective(viewContainer, templateRef, stepSwitch) {
        this.view = new StepView(viewContainer, templateRef);
        stepSwitch.addDefault(this.view);
        this.view.setStep(0);
    }
    return StepDefaultDirective;
}());
export { StepDefaultDirective };
