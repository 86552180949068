<div class="html2pdf__page-break" *ngIf="!isCollapsed && sectionIndex > 0"></div>
<div id="Section-{{sectionIndex}}" class="staffing-predictor-section">
    <div id="Section-{{sectionIndex}}" class="staffing-predictor-section-header">
        <div class="staffing-predictor-section-header-content">
            <div class="header-item header-title">
                <span class="header-title__text">{{five_star_v2_enabled ? fiveStarOrganization.organization.name
  :organization.organization.name}}</span>
            </div>
        </div>
        <div id="Section-{{sectionIndex}}" class="staffing-predictor-section-header-button-right">

            <i *ngIf="hasErrors" class="header-exclamation fas fa-exclamation-triangle"></i>

            <slx-star-rating [readonly]="true" [rating]="ratingValues" [maxValue]="ratingMaxValues"
 [filledItemClassName]="'full-yellow-medium-star'" [emptyItemClassName]="'fas empty-white-medium-star'">
</slx-star-rating>

            <slx-collapse-button id="Section-{{sectionIndex}}" [isCollapsed]="isCollapsed" className="slx-white-icon"
                (isCollapsedChange)="collapsedChange($event, sectionIndex)" [slxCollapseByCss]="collapseContent">
            </slx-collapse-button>
        </div>
    </div>
    <div id="Section-{{sectionIndex}}" #collapseContent class="staffing-predictor-section-content">
        <slx-staffing-predictor-star-rating [organization]="organization" [isLoading]="isLoading"[dataModel]="fullInfo"
 *ngIf="!five_star_v2_enabled"></slx-staffing-predictor-star-rating>
        <slx-fivestar-predictor-star-rating [srOrganization]="fiveStarOrganization" [dataModel]="fullInfo"
 (getGoalRating)="getGoalRating($event)" *ngIf="five_star_v2_enabled"
 [five_star_goal_section_enabled]="five_star_goal_section_enabled" [sectionIndex]="sectionIndex">
</slx-fivestar-predictor-star-rating>
    </div>
</div>