import * as tslib_1 from "tslib";
import { NgRedux } from '@angular-redux/store';
import { createEpicMiddleware } from 'redux-observable';
import { MonitoringService } from './organization/index';
import { middleware, enhancers, rootReducer } from './store/index';
import { VersionSubscribeService } from './common/services/index';
import * as Hammer from 'hammerjs';
import { rootEpics } from './state-model/state-model.module';
export function getWindow() { return window; }
export function getDocument() { return document; }
import { HammerGestureConfig } from '@angular/platform-browser';
var HammerConfig = /** @class */ (function (_super) {
    tslib_1.__extends(HammerConfig, _super);
    function HammerConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.overrides = {
            'swipe': { direction: Hammer.DIRECTION_ALL }
        };
        return _this;
    }
    return HammerConfig;
}(HammerGestureConfig));
export { HammerConfig };
var AppModule = /** @class */ (function () {
    function AppModule(ngRedux, versionSubscriber, monitoringService) {
        this.monitoringService = monitoringService;
        var epicMap = [];
        rootEpics.forEach(function (epic) {
            var epicMiddleware = createEpicMiddleware();
            middleware.push(epicMiddleware);
            epicMap.push({ epicMiddleware: epicMiddleware, epic: epic });
        });
        ngRedux.configureStore(rootReducer, {}, middleware, enhancers);
        epicMap.forEach(function (v) {
            v.epicMiddleware.run(v.epic);
        });
        versionSubscriber.runDialogListener();
    }
    return AppModule;
}());
export { AppModule };
