import * as tslib_1 from "tslib";
import { FiveStarRatingOrganization } from '../../models/index';
import { StaffingPredictorManagementService } from '../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
var FivestarPredictorRatingBadgeComponent = /** @class */ (function () {
    function FivestarPredictorRatingBadgeComponent(management) {
        this.management = management;
        this.hasRightErrors = false;
        this.showDetails = true;
    }
    FivestarPredictorRatingBadgeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hideSubscription = this.management.hideOnPrint$.subscribe(function (hide) {
            _this.hideToPrint = hide;
        });
    };
    FivestarPredictorRatingBadgeComponent.prototype.onShowDetails = function () {
        this.management.showDetailsFiveStar();
    };
    FivestarPredictorRatingBadgeComponent.prototype.checkForCondMargin = function (model, val) {
        if (((model.totalNursingAvgDailyHours >= 100 || model.rNAvgDailyHours >= 100 || model.totalNursingAvgDailyHoursWeekEnd >= 100) && val < 100) ||
            ((model.totalNursingAvgDailyHours >= 1000 || model.rNAvgDailyHours >= 1000 || model.totalNursingAvgDailyHoursWeekEnd >= 1000) && val < 1000)) {
            return true;
        }
        return false;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], FivestarPredictorRatingBadgeComponent.prototype, "hideSubscription", void 0);
    return FivestarPredictorRatingBadgeComponent;
}());
export { FivestarPredictorRatingBadgeComponent };
