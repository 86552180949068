import { EmployeeSectionsEmploymentMapService } from '../../../employee/employee-sections/services/index';
import * as _ from 'lodash';
import { Assert } from '../../../framework/index';
import { LookupMapService, EmployeeDefinitionsMapService } from '../../../organization/services/index';
import { TimeclockAssignmentContainer, TimeclockAssignmentEmployee } from '../../models/index';
var TimeclockAssignmentMapService = /** @class */ (function () {
    function TimeclockAssignmentMapService(lookupMapService, employeeDefinitionsMapService, employeeSectionMapService) {
        this.employeeSectionMapService = employeeSectionMapService;
        this.lookupMapService = lookupMapService;
        this.employeeDefinitionsMapService = employeeDefinitionsMapService;
    }
    TimeclockAssignmentMapService.prototype.mapTimeclockAssignmentRestriction = function (dto) {
        return this.employeeSectionMapService.mapTimeclockAssignmentRestriction(dto);
    };
    TimeclockAssignmentMapService.prototype.mapTimeclockAssignmentEmployee = function (dto) {
        var _this = this;
        Assert.isNotNull(dto, 'ITimeclockAssignmentEmployee');
        var data = new TimeclockAssignmentEmployee();
        data.badge = dto.badge;
        data.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
        data.organization = this.lookupMapService.mapOrganization(dto.organization);
        data.department = this.lookupMapService.mapDepartment(dto.department);
        data.position = this.lookupMapService.mapPosition(dto.position);
        data.assignments = _.map(dto.assignments, function (dtoAssignment) { return _this.mapTimeclockAssignmentRestriction(dtoAssignment); });
        return data;
    };
    TimeclockAssignmentMapService.prototype.mapTimeclockAssignmentContainer = function (dto) {
        var _this = this;
        Assert.isNotNull(dto, 'ITimeclockAssignmentContainer');
        var data = new TimeclockAssignmentContainer();
        data.employees = _.map(dto.employees, function (empoyee) { return _this.mapTimeclockAssignmentEmployee(empoyee); });
        return data;
    };
    TimeclockAssignmentMapService.prototype.mapTimeclockAssignment = function (data) {
        Assert.isNotNull(data, 'TimeclockAssignment');
        return _.map(data, function (assignment) {
            var timeclockRestrictions = _.map(assignment.timeclockRestrictions, function (entry) {
                var timeclockRestriction = {
                    timeclockId: entry.timeclock.id,
                    restrictionId: entry.restriction.id
                };
                return timeclockRestriction;
            });
            var dto = {
                employeeId: assignment.employee.id,
                timeclockRestrictions: timeclockRestrictions
            };
            return dto;
        });
    };
    TimeclockAssignmentMapService.prototype.mapTimeclockUnAssignment = function (data) {
        Assert.isNotNull(data, 'TimeclockAssignment');
        var timeclockUnAssignment = {
            employeeIds: [],
            timeclockIds: []
        };
        return _.reduce(data, function (collector, assignment) {
            var _a;
            var timeclocks = _.map(assignment.timeclockRestrictions, function (entry) { return entry.timeclock.id; });
            collector.employeeIds.push(assignment.employee.id);
            (_a = collector.timeclockIds).push.apply(_a, timeclocks);
            return collector;
        }, timeclockUnAssignment);
    };
    return TimeclockAssignmentMapService;
}());
export { TimeclockAssignmentMapService };
