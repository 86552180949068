 <span>Choose a year:</span>&nbsp;
<slx-actions-list>
  <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-start'">
    {{settings.year}} <span class="caret"></span>
  </slx-actions-button>
  <popper-content #popperContent>
    <slx-actions-list-item *ngFor="let year of yearItems" (onClick)="onSelectYear(year)" [className]="settings.year === year  ? 'active' : ''">{{year}}</slx-actions-list-item>
  </popper-content>
</slx-actions-list>
<div class="dropdown" [ngClass]="{'open': columnsMenuOpened}">
  <button class="theme-icon-button dropdown-toggle" (click)="toggleColumnsMenu()" type="button" aria-haspopup="true" aria-expanded="true">
    <i class="fa fa-cog" aria-hidden="true"></i>
  </button>
  <div class="theme-action-button theme-accent-men dropdown-menu" aria-labelledby="group-activities">
    <span class="title"><strong>Columns to display</strong></span>
    <div *ngFor="let column of this.settings.columns; let idx = index" >
      <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input  slx-input name="column{{idx}}" fieldName="column{{idx}}"
            [caption]="column.description" [(ngModel)]="column.displayed">
            </slx-checkbox-input>
      </slx-input-decorator>
    </div>
    <div class="button-container">
      <button class="theme-button-cancel btn-small margin-r" type="button" (click)="selectAll($event)">
        Select All
      </button>
      <button class="theme-button-cancel btn-small" type="button" (click)="clearAll($event)">
        Clear All
      </button>
    </div>
    <div class="button-container">
    <button class="theme-button-apply btn-small" type="button" (click)="columnSelected()">
      Apply
    </button>
    </div>
  </div>
</div>
