import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnDestroy, OnInit, Provider } from '@angular/core';
import { IDialog, DialogOptions, DialogOptions2 } from '../../../../../../common/models';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { NewEnrollmentModel } from '../../../../models';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { ModalService } from '../../../../../../common';
import { LookupService } from '../../../../../../organization';
import { appConfig } from '../../../../../../app.config';
import { BenefitEmployeeManagementService } from '../../../../../../app-modules/benefits/services/benefit-employees/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EnrollEmployeeFlatComponent = /** @class */ (function () {
    function EnrollEmployeeFlatComponent(model, apiService, options, modalService, lookupService, manService, man) {
        this.model = model;
        this.apiService = apiService;
        this.options = options;
        this.modalService = modalService;
        this.lookupService = lookupService;
        this.manService = manService;
        this.man = man;
        this.minEmpCont = 0;
        this.maxEmpCont = 99999999.99;
        this.stepEmpCont = 0.01;
        this.minEECont = 0;
        this.maxEECont = 99999999.99;
        this.stepEECont = 0.01;
        this.currencyFormat = 'c2';
        this.hasDedError = false;
        this.subscriptions = {};
        this.appConfig = appConfig;
    }
    EnrollEmployeeFlatComponent.openDialog = function (model, apiService, modalService, lookupService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.width = 1050;
        dialogOptions.height = 400;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: LookupService,
                useValue: lookupService
            },
            {
                provide: ModalService,
                useValue: modalService
            },
            {
                provide: NewEnrollmentModel,
                useValue: model
            },
            {
                provide: EmployeeSectionsBenefitsManagementApiService,
                useValue: apiService
            }
        ];
        var title = "Enroll Employee in Benefits";
        var component = modalService.globalAnchor.openDialog2(EnrollEmployeeFlatComponent, title, dialogOptions, resolvedProviders, callback);
        return component;
    };
    EnrollEmployeeFlatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.groupName = this.model.plan.name;
        this.effectiveDate = this.model.effectiveDate;
        this.payrollDedStartDate = this.model.effectiveDate;
        this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
        this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
        this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
        this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
        this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate : this.model.plan.dedEndDate;
        this.endDate = this.model.plan.endDate;
        this.startDate = _.isNull(this.model.plan.startDate) ? this.model.effectiveDate : this.model.plan.startDate;
        this.canMapPayroll = this.model.canMapPayroll;
        this.apiService.getBenefitTiers(this.model.plan.benefitLineId)
            .then(function (tiers) {
            _this.tiers = tiers;
            _this.isLoading = false;
        });
        this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate(function (hasDedError) {
            _this.hasDedError = hasDedError;
        });
    };
    EnrollEmployeeFlatComponent.prototype.ngOnDestroy = function () {
    };
    EnrollEmployeeFlatComponent.prototype.onChangeTier = function (tier) {
        var _this = this;
        this.selectedTier = tier;
        this.isLoading = true;
        this.apiService.getBenefitPlanFlat(tier.id)
            .then(function (flat) {
            _this.flatSetttings = flat;
            _this.empCont = flat.empContribution;
            _this.eeCont = flat.erContribution;
            _this.isLoading = false;
        });
    };
    EnrollEmployeeFlatComponent.prototype.onEnroll = function () {
        this.flatSetttings.empContribution = this.empCont;
        this.flatSetttings.erContribution = this.eeCont;
        this.model.flatSettings = this.flatSetttings;
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EnrollEmployeeFlatComponent.prototype.onCancel = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Discard Changes', 'Are you sure you want to cancel? You will lose all unsaved selections.', this.modalService, function (isCancel) {
            if (isCancel) {
                _this.dialogResult = false;
                _this.modalService.closeWindow(_this.options.windowUniqueId);
            }
        }, options);
    };
    EnrollEmployeeFlatComponent.prototype.OnDeductionEndDateChange = function (date) {
        this.model.plan.payrollDeductionEndDate = date;
    };
    EnrollEmployeeFlatComponent.prototype.OnDeductionStartDateChange = function (date) {
        this.model.plan.payrollDeductionStartDate = date;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EnrollEmployeeFlatComponent.prototype, "subscriptions", void 0);
    return EnrollEmployeeFlatComponent;
}());
export { EnrollEmployeeFlatComponent };
