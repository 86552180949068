import { ChartMenuItem } from './chart-menu-item';
import { ChartType } from './chart-type';

export interface RawChart {
  name: string;
  icon: string;
  type: ChartType;
}

export const SoRawCharts: RawChart[] = [{
  name: 'Donut Charts',
  icon: 'fas fa-chart-pie',
  type: ChartType.Donut
}, {
  name: 'Bar Charts',
  icon: 'far fa-chart-bar',
  type: ChartType.Bar
}, {
  name: 'Small Charts',
  icon: 'far fa-arrows-alt-v',
  type: ChartType.Small
}, {
  name: 'No Charts',
  icon: 'far fa-desktop',
  type: ChartType.None
}];

export const SoChartMenuItems: ChartMenuItem[] = SoRawCharts.map((item: RawChart) => {
  return new ChartMenuItem(item.name, item.icon, item.type);
});
