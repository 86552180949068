import * as _ from 'lodash';
import { dateTimeUtils } from '../../../common/utils/index';
var LMCommonRecurrence = /** @class */ (function () {
    function LMCommonRecurrence() {
        this.interval = 1;
        this.count = 1;
        this.until = new Date();
        this.endNever = 'never';
        this.endAfterOccurrence = 'count';
        this.endOnDate = 'until';
        this.min = 1;
        this.max = 31;
        this.step = 1;
        this.decimals = 0;
        this.format = '#';
        this.subscriptions = {};
    }
    Object.defineProperty(LMCommonRecurrence.prototype, "countIsReadonly", {
        get: function () {
            return this.endMode !== this.endAfterOccurrence;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCommonRecurrence.prototype, "untilIsReadonly", {
        get: function () {
            return this.endMode !== this.endOnDate;
        },
        enumerable: true,
        configurable: true
    });
    LMCommonRecurrence.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMCommonRecurrence.prototype.copyDate = function (date) {
        return dateTimeUtils.copyDate(date);
    };
    return LMCommonRecurrence;
}());
export { LMCommonRecurrence };
