<div class="slide-out-main-container" #container>
  <div class="date-range-container" [class.header]= "options.isMobile">
    <div class="date-range">
      Dates: {{startDate | date: 'MM/dd/yyyy'}} - {{endDate| date: 'MM/dd/yyyy'}}
    </div>
    <div class="action-btns-container">
      <div class="config-btn" (click)="openConfigPopup('Department', orgLevelId, orgLevel.orgLevelName, options.isMobile)"  >
        <span class="fas fa-cog"></span>
        <span>Configure</span>
      </div> 
       
    </div>
    <div class="slx-lr-toolbar__filters actionsAlign">
      <slx-action-list>
        <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent"
                           [popperPosition]="'bottom-end'">Actions</slx-action-button>
        <popper-content #popperContent [ngClass]="options.isMobile ? 'actionsContentClass' : ''">
          <slx-action-list-item (click)="exportToExcel()">Export to Excel</slx-action-list-item>
        </popper-content>
      </slx-action-list>
    </div>
   </div>
   <slx-spinner [show]="isLoading">
  <div class="position-groups-data-container">
    <div class="filter-container">
      <span class="filter-label">Filter by Shift Group:</span>
      <div class="radio-btns-container">
        <slx-radio-button class="weekly__radio"
                          name="shiftGroupRadioFilter"
                          caption="All"
                          option="All"
                          [(ngModel)]="filterOption"
                          (ngModelChange)="onFilterChange()"></slx-radio-button>
        <slx-radio-button class="weekly__radio"
                          name="shiftGroupRadioFilter"
                          caption="Day"
                          option="Day"
                          [(ngModel)]="filterOption"
                          (ngModelChange)="onFilterChange()"></slx-radio-button>
        <slx-radio-button class="weekly__radio"
                          name="shiftGroupRadioFilter"
                          caption="Evening"
                          option="Evening"
                          [(ngModel)]="filterOption"
                          (ngModelChange)="onFilterChange()"></slx-radio-button>
        <slx-radio-button class="weekly__radio"
                          name="shiftGroupRadioFilter"
                          caption="Night"
                          option="Night"
                          [(ngModel)]="filterOption"
                          (ngModelChange)="onFilterChange()"></slx-radio-button>
      </div>
    </div>
   
      <div class="data-main-container">
        <div class="data-container" *ngIf="filteredData.length === 0">
          <slx-collapsible-section mode="white" [expanded]="true">
           <ng-container body>
            <table class="position-groups-data-table">
              <thead>
                <tr>
                  <th class="position">Position</th>
                  <th>Shifts</th>
                  <th>Scheduled</th>
                  <th class="hours-gap">Hours Gap</th>
                  <th class="ft-headcount">FT Headcount</th>
                  <th class="pt-headcount">PT Headcount</th>
                </tr>
              </thead>
              <tbody>
                <tr class="background-white">
                  <td colspan="6">No Records Available</td>                   
                </tr>
              </tbody>     
            </table>
          </ng-container>
          </slx-collapsible-section>
        </div>
        <div class="data-container" *ngFor="let groupData of filteredData" [class.accordion-mobile-width]="options?.isMobile">
          <slx-collapsible-section [title]="'Position Group: '+groupData.positionGroup" [expanded]="true" mode="white" [uniqId]="1">
            <ng-container body>
              <table class="position-groups-data-table">
                <thead>
                  <tr>
                    <th class="position">Position</th>
                    <th>Shifts</th>
                    <th>Scheduled</th>
                    <th class="hours-gap">Hours Gap</th>
                    <th class="ft-headcount">FT Headcount</th>
                    <th class="pt-headcount">PT Headcount</th>
                  </tr>
                </thead>
                <tbody [ngClass]="isMobile ? 'set-width' : ''">
                  <tr class="background-white" *ngFor="let data of groupData.positionDetails">
                    <td class="position">{{data.position}}</td>
                    <td>{{data.shifts}}</td>
                    <td>{{data.scheduled}}</td>                    
                    <ng-container *ngIf="data.shifts == 0; then thenBlock; else elseBlock"></ng-container>
                    <ng-template #thenBlock>
                      <td colspan="3">Ideal Schedule is not configured</td>                                       
                    </ng-template>
                    <ng-template #elseifBlock> <td colspan="3">No Rotation Gaps</td> </ng-template>
                    <ng-template #elseBlock>
                      <ng-container *ngIf="(data.shifts > 0 && data.hoursGap == 0); then ifElseBlock; else elseIfBlock"></ng-container>
                      <ng-template #ifElseBlock>
                        <td colspan="3">No Rotation Gaps</td> 
                      </ng-template>
                      <ng-template #elseIfBlock>
                        <td><b>{{data.hoursGap}}</b></td>
                        <td><b>{{data.ftHeadCount}}</b></td>
                        <td><b>{{data.ptHeadCount}}</b></td>
                      </ng-template>
                    </ng-template>                    
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="background-pink">
                    <td><b>Totals</b></td>
                    <td><b>{{groupData.totalShifts | number}}</b></td>
                    <td><b>{{groupData.totalScheduled | number}}</b></td>
                    <td><b>{{groupData.totalHours | number}}</b></td>
                    <td><b>{{groupData.totalFTHead | number}}</b></td>
                    <td><b>{{groupData.totalPTHead | number}}</b></td>
                  </tr>
                </tfoot>
              </table>
            </ng-container>
          </slx-collapsible-section>
        </div>
      </div>
    
  </div>
</slx-spinner>
</div>
<kendo-excelexport data="exportOpenPositionControlSlideOutData"  fileName="OpenPositionControlSlideOut.xlsx" #excelExport>
  <kendo-excelexport-column-group [title]="getDateRange()" [headerCellOptions]="{ textAlign:'left', bold: true }">
    <kendo-excelexport-column field="PositionGroup" title="Position Group" [width]="200" [headerCellOptions]="{ bold: true }">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="Position" title="Position" [width]="200" [headerCellOptions]="{ bold: true }">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="ShiftGroup" title="Shift Group" [width]="100" [headerCellOptions]="{ bold: true }" [cellOptions]="{ textAlign: 'left' }">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="Shifts" title="Shifts" [width]="100" [headerCellOptions]="{ bold: true }" [cellOptions]="{ textAlign: 'center' }">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="Scheduled" title="Scheduled" [width]="100" [headerCellOptions]="{ bold: true }" [cellOptions]="{ textAlign: 'center' }">
    </kendo-excelexport-column>
  </kendo-excelexport-column-group>
  <kendo-excelexport-column-group [title]="getFilterData()" [headerCellOptions]="{ textAlign:'right', bold: true }">
    <kendo-excelexport-column field="hoursGap" title="Hours Gap" [width]="150" [headerCellOptions]="{ bold: true }">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="ftHeadCount" title="FT HeadCount" [width]="100" [headerCellOptions]="{ bold: true }" [cellOptions]="{ textAlign: 'center' }">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="ptHeadCount" title="PT HeadCount" [width]="100" [headerCellOptions]="{ bold: true }" [cellOptions]="{ textAlign: 'center' }">
    </kendo-excelexport-column>
  </kendo-excelexport-column-group>
</kendo-excelexport>
