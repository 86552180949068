export interface IBenefitClassDefinition {
  id: number;
  orgLevelId: number;
  name: string;
  description: string;
  code: string;
}

export class BenefitClassDefinition {
  public id: number;
  public orgLevelId: number;
  public name: string;
  public description: string;
  public code: string;
}
