
export interface IOpenShiftPostionsOrgDetails {
    orgLevelId: number
    totalHoursGap: number
    totalOpenShifts: number
    departmentName: string
    idealIndicator?: number
    totalFTENeeded: number
}

export class OpenShiftPostionsOrgDetails {
    public orgLevelId: number;
    public totalHoursGap: number;
    public totalOpenShifts: number;
    public departmentName: string;
    public idealIndicator?: number;
    public totalFTENeeded: number;
    public get isNoConfiguration() {
        return (this.totalHoursGap === 0 && 
               this.totalOpenShifts === 0 &&
               this.idealIndicator === 0) ? true : false; 
    }
    public get isNoRotationGaps() {
        return (this.totalHoursGap === 0 && 
               this.totalOpenShifts === 0 &&
               this.idealIndicator > 0) ? true : false; 
    }
}