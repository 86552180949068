import * as tslib_1 from "tslib";
import { screenUtils } from './../../../common/utils/screenUtils';
import * as _ from 'lodash';
import { OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, Renderer2, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { unsubscribe } from '../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/of';
import { OrgLevelType } from '../../../state-model/models/index';
import { ChangeManagementService, RestoreQueryParamsService } from '../../../common/services/index';
import { ApiWatchService } from '../../../core/services/index';
import { ApiEvent } from '../../../core/models/index';
import { ApplicationStateBusService, OrgLevelManagementService } from '../../services/index';
import { StateNavigationService } from '../../../common/services/index';
import { scheduleMicrotask } from '../../../core/utils/index';
var OrganizationStructureComponent = /** @class */ (function () {
    function OrganizationStructureComponent(router, route, managementService, apiWatchService, cdr, appStateBusService, renderer, elementRef, changeManagement, restoreQueryParamsService) {
        this.router = router;
        this.route = route;
        this.managementService = managementService;
        this.apiWatchService = apiWatchService;
        this.cdr = cdr;
        this.appStateBusService = appStateBusService;
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.changeManagement = changeManagement;
        this.restoreQueryParamsService = restoreQueryParamsService;
        this.orgLevelFieldName = 'name';
        this.keyForExpanding = 'id';
        this.keyForSelecting = 'id';
        this.expandedOrgLevels = [];
        this.selectedOrgLevels = [];
        this.orgLevels = [];
        this.searchValue$ = new Subject();
        this.isViewInit = false;
        this.isShowOrgTree = false;
        this.navService = new StateNavigationService(this.router, this.route, this.changeManagement, this.restoreQueryParamsService);
        this.m_searchValue = '';
        this.posTop = 0;
        this.posLeft = 0;
        this.iconShift = 0;
        this.isLocked = false;
    }
    Object.defineProperty(OrganizationStructureComponent.prototype, "show", {
        set: function (value) {
            this.visibilityChanged(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrganizationStructureComponent.prototype, "top", {
        set: function (value) {
            this.positionChanged(value, null, null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrganizationStructureComponent.prototype, "left", {
        set: function (value) {
            this.positionChanged(null, value, null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrganizationStructureComponent.prototype, "pointerIconShift", {
        set: function (value) {
            this.positionChanged(null, null, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrganizationStructureComponent.prototype, "noData", {
        get: function () {
            return this.managementService.noData;
        },
        set: function (s) {
            this.managementService.noData = s;
        },
        enumerable: true,
        configurable: true
    });
    OrganizationStructureComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.statusSubscription = this.managementService.onLoadStatus$.subscribe(function (isLocked) {
            _this.isLocked = isLocked;
        });
        this.orgLevelsLoadedSubscription = this.appStateBusService.subscribeToOrgLevelsLoaded(function (orgLevels) {
            scheduleMicrotask(function () {
                if (orgLevels.length > 0) {
                    _this.storedOrgLevels = orgLevels;
                    _this.orgLevels = _.cloneDeep(orgLevels);
                }
            });
        });
        this.selectedSubscription = this.appStateBusService.subscribeToSelectOrgLevel(function (orgLevel) {
            scheduleMicrotask(function () {
                var newOrgLevelId = _.get(orgLevel, 'id', null);
                var currentOrgLevelId = _.get(_this.orgLevel, 'id', null);
                if (_.isNumber(newOrgLevelId) && currentOrgLevelId !== newOrgLevelId) {
                    _this.orgLevel = orgLevel;
                    if (_this.isMobile) {
                        _this.visibilityChanged(false);
                    }
                }
            });
        });
        this.searchValueSubscription = this.searchValue$.debounceTime(400)
            .subscribe(function (value) { return _this.searchOrglevel(value); });
        this.apiWatchStartSubscription = this.apiWatchService.apiRequestStart$.subscribe(function (event) {
            if (!event.isBackgroundRequest) {
                _this.isLocked = true;
            }
            _this.cdr.detectChanges();
        });
        this.apiWatchEndSubscription = this.apiWatchService.apiRequestEnd$
            .debounceTime(1000)
            .subscribe(function (event) {
            if (!event.isBackgroundRequest) {
                _this.isLocked = false;
            }
            _this.cdr.detectChanges();
        });
    };
    OrganizationStructureComponent.prototype.ngAfterViewInit = function () {
        this.isViewInit = true;
        this.setVisibility();
        this.setPosition();
    };
    OrganizationStructureComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    Object.defineProperty(OrganizationStructureComponent.prototype, "searchValue", {
        get: function () {
            return this.m_searchValue;
        },
        set: function (value) {
            this.m_searchValue = value;
            this.searchValue$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrganizationStructureComponent.prototype, "orgTreeElem", {
        get: function () {
            return this.elementRef.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrganizationStructureComponent.prototype, "hasOrgLevelTree", {
        get: function () {
            return _.isArray(this.orgLevels) && _.size(this.orgLevels) > 0;
        },
        enumerable: true,
        configurable: true
    });
    OrganizationStructureComponent.prototype.hasChildsOrgLevels = function (orgLevelInTree) {
        return orgLevelInTree.hasChilds;
    };
    OrganizationStructureComponent.prototype.childsOrgLevels = function (orgLevelInTree) {
        return Observable.of(orgLevelInTree.childs);
    };
    OrganizationStructureComponent.prototype.searchOrglevel = function (value) {
        var _this = this;
        this.resetSearch(false);
        if (_.isString(value) && _.size(value) > 0) {
            var notContain_1 = [];
            _.forEach(this.orgLevels, function (orgLevel, index) {
                notContain_1.push(_this.searchInTree(orgLevel, value));
            });
            _.remove(this.orgLevels, function (o, i) { return notContain_1[i]; });
            this.expandAllTree();
        }
    };
    OrganizationStructureComponent.prototype.onOrgLevelChanged = function (event) {
        var orgLevel = event.dataItem;
        var expanded = this.expandedOrgLevels.concat(orgLevel.treeViewPath, [orgLevel.id]);
        this.expandedOrgLevels = _.uniq(expanded);
        if (orgLevel) {
            this.navService.navigateToOrgLevelAndKeepQueryParams(orgLevel.id);
        }
    };
    OrganizationStructureComponent.prototype.onClearSearch = function () {
        this.resetSearch(true);
        this.resetTree();
    };
    OrganizationStructureComponent.prototype.getIconClass = function (orgLevel) {
        var cssClass = '';
        switch (orgLevel.type) {
            case OrgLevelType.corporation:
                cssClass = 'fa-building corp';
                break;
            case OrgLevelType.organization:
                cssClass = 'fa-building';
                break;
            case OrgLevelType.region:
                cssClass = 'fa-globe';
                break;
            case OrgLevelType.division:
                cssClass = 'fa-sitemap';
                break;
            case OrgLevelType.department:
                cssClass = 'fa-users';
                break;
        }
        return cssClass;
    };
    OrganizationStructureComponent.prototype.getNodeText = function (orgLevel) {
        var name = orgLevel.name;
        var valueLength = this.searchValue.length;
        if (valueLength > 0) {
            var regexp = new RegExp(this.searchValue, 'i');
            var startIndex = name.search(regexp);
            if (startIndex !== -1) {
                var endIndex = startIndex + valueLength;
                name = name.slice(0, startIndex) + ("<strong>" + name.slice(startIndex, endIndex) + "</strong>") + name.slice(endIndex);
            }
            return name;
        }
        return name;
    };
    OrganizationStructureComponent.prototype.searchInTree = function (orgLevel, value) {
        var _this = this;
        var notContain = !_.includes(orgLevel.name.toLocaleLowerCase(), value.toLocaleLowerCase());
        var notContainChilds = [];
        if (orgLevel.childs.length > 0) {
            _.forEach(orgLevel.childs, function (orgLevel, index) {
                notContainChilds.push(_this.searchInTree(orgLevel, value));
            });
        }
        var notContainTotal = notContainChilds.length === 0 || _.every(notContainChilds, function (v) { return v === true; });
        if (notContainTotal) {
            orgLevel.childs.length = 0;
        }
        else {
            _.remove(orgLevel.childs, function (o, i) { return notContainChilds[i]; });
        }
        return notContain && notContainTotal;
    };
    OrganizationStructureComponent.prototype.expandAllTree = function () {
        var orgLevelsToExpand = [];
        var collectOrgLevelIds = function (orgLevel) {
            var childsOrgLevelIds = [orgLevel.id];
            if (orgLevel.childs.length > 0) {
                _.forEach(orgLevel.childs, function (orgLevel, index) {
                    childsOrgLevelIds.push.apply(childsOrgLevelIds, collectOrgLevelIds(orgLevel));
                });
            }
            return childsOrgLevelIds;
        };
        _.forEach(this.orgLevels, function (orgLevel) {
            orgLevelsToExpand.push.apply(orgLevelsToExpand, [orgLevel.id].concat(collectOrgLevelIds(orgLevel)));
        });
        this.expandedOrgLevels = _.uniq(orgLevelsToExpand);
    };
    OrganizationStructureComponent.prototype.visibilityChanged = function (isShow) {
        this.isShowOrgTree = isShow;
        if (this.isViewInit) {
            this.setVisibility();
        }
    };
    OrganizationStructureComponent.prototype.positionChanged = function (top, left, iconShift) {
        if (_.isNumber(top))
            this.posTop = top;
        if (_.isNumber(left))
            this.posLeft = left;
        if (_.isNumber(iconShift))
            this.iconShift = iconShift;
        if (this.isViewInit) {
            this.setPosition();
        }
    };
    OrganizationStructureComponent.prototype.setVisibility = function () {
        this.resetTree();
        if (!this.isShowOrgTree) {
            this.resetSearch(true);
        }
        if (this.isShowOrgTree) {
            this.renderer.addClass(this.orgTreeElem, 'show');
        }
        else {
            this.renderer.removeClass(this.orgTreeElem, 'show');
        }
    };
    OrganizationStructureComponent.prototype.resetSearch = function (isClear) {
        this.orgLevels = _.cloneDeep(this.storedOrgLevels);
        if (isClear) {
            this.m_searchValue = '';
        }
    };
    OrganizationStructureComponent.prototype.setPosition = function () {
        this.renderer.setStyle(this.orgTreeElem, 'top', this.posTop + "px");
        this.renderer.setStyle(this.orgTreeElem, 'left', this.posLeft + "px");
        var pointerIcon = this.orgTreeElem.querySelector('.js-pointer');
        if (_.isElement(pointerIcon)) {
            var styles = window.getComputedStyle(pointerIcon);
            this.renderer.setStyle(pointerIcon, 'left', this.iconShift - (parseInt(styles.width) / 2) + "px");
        }
    };
    OrganizationStructureComponent.prototype.resetTree = function () {
        var _a;
        var orgLevelId = _.get(this.orgLevel, 'id');
        var treeViewPath = _.get(this.orgLevel, 'treeViewPath');
        if (_.isNumber(orgLevelId)) {
            this.selectedOrgLevels.length = this.expandedOrgLevels.length = 0;
            this.selectedOrgLevels.push(orgLevelId);
            (_a = this.expandedOrgLevels).push.apply(_a, treeViewPath);
        }
    };
    Object.defineProperty(OrganizationStructureComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrganizationStructureComponent.prototype, "apiWatchStartSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrganizationStructureComponent.prototype, "apiWatchEndSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrganizationStructureComponent.prototype, "orgLevelsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrganizationStructureComponent.prototype, "statusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrganizationStructureComponent.prototype, "selectedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrganizationStructureComponent.prototype, "searchValueSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrganizationStructureComponent.prototype, "noOrgLevelsSubscription", void 0);
    return OrganizationStructureComponent;
}());
export { OrganizationStructureComponent };
