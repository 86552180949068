export interface IWcReportStatusDefinitionDto {
    id: WcReportStatusEnum;
    name: string;
}

export enum WcReportStatusEnum {
    Open, Closed, MedicalOnly, FirstAid
}

export class WcReportStatusDefinition {
    public id: WcReportStatusEnum;
    public name: string;
}
