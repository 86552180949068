<slx-spinner [show]="state.isLoading">
	<div class="slx-high-box" *ngIf="!listDefinitionsIsEmpty">
		<div #employeeListHeader class="section employee-list-actions slx-content-toolbar-indents">
      <slx-actions-list>
        <slx-actions-button className="auto-width" [popperContent]="employeesInfo" [popperPosition]="'bottom-start'">
          {{employeeListItem?.listName}} <span class="caret"></span>
        </slx-actions-button>
        <popper-content #employeesInfo>
          <slx-actions-list-item
            *ngFor="let item of employeeListItems"
            (onClick)="onItemSelect(item)"
            [className]="employeeListItem?.listName === item.listName ? 'active' : ''"
          >{{item.listName}}</slx-actions-list-item>
        </popper-content>
      </slx-actions-list>

      <div *ngIf="isCallLog" class="slx-emp-list__dates">
        <slx-daterange
          class="slx-width-260"
          name="startEndDate"
          [startDate]="dateRange.startDate"
          [endDate]="dateRange.endDate"
          (rangeDateChanged)="onDatesRangeChanged($event)"
        ></slx-daterange>
      </div>

      <div class="group-activities">
        <slx-actions-list>
          <slx-actions-button [popperContent]="employeesActions" [popperPosition]="'bottom-end'">
            Actions <span class="caret"></span>
          </slx-actions-button>
          <popper-content #employeesActions>
            <ng-container *ngFor="let action of actionDefinitions.actions">
              <slx-actions-list-item *ngIf="!action.isMassRateAdjustments" (onClick)="onGroupActivityActionSelect(action)">{{action.displayName}}</slx-actions-list-item>
            </ng-container>
              <slx-actions-list-item *ngIf="actionDefinitions.canAddEmployee" slxAddEmployeeLink>Add Employee</slx-actions-list-item>
            <ng-container *ngFor="let action of actionDefinitions.actions" >
              <slx-actions-list-item [disabled]="!actionDefinitions.canAdjustPayRate" *ngIf="action.isMassRateAdjustments" (onClick)="onGroupActivityActionSelect(action)">{{action.displayName}}</slx-actions-list-item>
            </ng-container>
          <slx-actions-list-item (onClick)="kendoDefault.exportToPdf()">Export to Pdf</slx-actions-list-item>
          <slx-actions-list-item (onClick)="kendoDefault.exportToExcel()">Export to Excel</slx-actions-list-item>
          </popper-content>
        </slx-actions-list>
        <slx-columns-config-button [colGroupKey]="groupName" title="Employee List Columns" ></slx-columns-config-button>
			</div>

      <slx-checkbox-toggle class="active-only-field" className="toggle-right"
        [(ngModel)]="isActiveOnly"
        (ngModelChange)="onDisplaySettingsChanged()"
        caption="Active only"
        checkedText="Yes"
        uncheckedText="No"
      ></slx-checkbox-toggle>
		</div>
		<div class="slx-high-box__body slx-main-content-indents">
 			<slx-employee-grid class="slx-full-height" #kendoDefault [fieldDefinition]="employeeListItem" [columnsGroupName]="groupName" [employees]="employees"
          (onEmployeesSelect)="employeeSelected($event)" (onEmployeeOpen)="employeeOpen($event)"
                         (onFiltered)="employeesFiltered($event)" [exportFileName]="fileName" [isMultiselect]="false" [isColumnLocked]="isColumnLocked"></slx-employee-grid>
		</div>
  </div>
  <div class="slx-high-box" *ngIf="!state.isLoading && listDefinitionsIsEmpty">
    <span class="error-span">There are no active list definitions. This can be caused by insufficient rights or internal error.</span>
  </div>
</slx-spinner>

