import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { MasterScheduleDayInfo } from '../../../models/index';
import { screenUtils, IScreenUtils } from '../../../../common/utils/index';


@Component({
  moduleId: module.id,
  selector: 'slx-master-schedule-day-info-dialog',
  templateUrl: 'master-schedule-day-info-dialog.component.html',
  styleUrls: ['master-schedule-day-info-dialog.component.scss']
})
export class MasterScheduleDayInfoDialogComponent implements IDialog {


  public options: DialogOptions;
  public dialogResult: boolean;
  public request: MasterScheduleDayInfo;

  private modalService: ModalService;
  private screenUtils: IScreenUtils;



  public static openDialog(request: MasterScheduleDayInfo, modalService: ModalService, callback: (result: boolean, cmd: any) => void): MasterScheduleDayInfoDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 600;
    dialogOptions.width = 500;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: MasterScheduleDayInfo,
        useValue: request
      }
    ];

    let dialog: MasterScheduleDayInfoDialogComponent = modalService.globalAnchor
      .openDialog(MasterScheduleDayInfoDialogComponent, 'Employee entry information', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, undefined);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    request: MasterScheduleDayInfo
  ) {
    this.modalService = modalService;
    this.options = options;
    this.request = request;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
