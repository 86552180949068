var TimeclockDailySummary = /** @class */ (function () {
    function TimeclockDailySummary() {
    }
    Object.defineProperty(TimeclockDailySummary.prototype, "sortName", {
        get: function () {
            return this.name ? this.name.toLowerCase() : '';
        },
        enumerable: true,
        configurable: true
    });
    return TimeclockDailySummary;
}());
export { TimeclockDailySummary };
