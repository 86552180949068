import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { LookupEntity } from './lookup-entity';
import { appConfig } from '../../../app.config';
var ShiftDefinition = /** @class */ (function (_super) {
    tslib_1.__extends(ShiftDefinition, _super);
    function ShiftDefinition(id, name, startTime, endTime, duration, lunchDuration, group, employeesCount, usedInIdealSchedule) {
        var _this = _super.call(this) || this;
        _this.isDirty = false;
        _this.startDate = new Date(new Date().toDateString() + " " + startTime);
        _this.endDate = new Date(new Date().toDateString() + " " + endTime);
        _this.durationValue = new Date(new Date().toDateString() + " " + duration);
        _this.durationHoursValue = _this.durationValue.getHours() + _this.durationValue.getMinutes() / 60; // TODO
        _this.id = id;
        _this.name = name;
        _this.lunchDurationValue = new Date(new Date().toDateString() + " " + lunchDuration);
        _this.group = group;
        _this.employeesCount = employeesCount;
        _this.usedInIdealSchedule = usedInIdealSchedule;
        return _this;
    }
    Object.defineProperty(ShiftDefinition.prototype, "actualDuration", {
        get: function () {
            return this.durationValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftDefinition.prototype, "lunchDurationDate", {
        get: function () {
            return this.lunchDurationValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftDefinition.prototype, "isNextDayShift", {
        get: function () {
            return this.endDate < this.startDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftDefinition.prototype, "start", {
        get: function () {
            return moment(this.startDate).format(appConfig.militaryTimeFormat);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftDefinition.prototype, "end", {
        get: function () {
            return moment(this.endDate).format(appConfig.militaryTimeFormat);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftDefinition.prototype, "duration", {
        get: function () {
            return moment(this.durationValue).format(appConfig.militaryTimeFormat);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftDefinition.prototype, "durationHours", {
        get: function () {
            return this.durationHoursValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftDefinition.prototype, "lunchDuration", {
        get: function () {
            return moment(this.lunchDurationValue).format(appConfig.militaryTimeFormat);
        },
        enumerable: true,
        configurable: true
    });
    return ShiftDefinition;
}(LookupEntity));
export { ShiftDefinition };
