import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { process, aggregateBy } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../../../app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { WcComparisonsManagementService } from '../../../../services/index';
var WcComparisonsGridComponent = /** @class */ (function () {
    function WcComparisonsGridComponent(manService) {
        this.manService = manService;
        this.pageSize = 50;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'organization', dir: 'asc' }
        ];
        this.appConfig = appConfig;
        this.totals = {};
        this.aggregates = [{ field: 'total', aggregate: 'sum' }];
    }
    WcComparisonsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onLoaded = this.manService
            .subscribeToLoadedRecords(function (r) {
            _this.canEdit = r.canEdit;
            _this.assignRecords(r.records);
        });
        this.subscriptions.state = this.manService
            .subscribeToChangeState(function (s) { return _this.applyState(s); });
        this.subscriptions.export = this.manService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
        this.manService.subscritpionToSenseYearChange(function (s) {
            _this.yearArray = s;
        });
    };
    WcComparisonsGridComponent.prototype.assignRecords = function (records) {
        this.records = records;
        this.storedRecords = _.clone(records);
        this.refreshGrid();
    };
    WcComparisonsGridComponent.prototype.applyState = function (s) {
        this.state = s;
        this.columns = s.monthlyColumns;
        this.refreshGrid();
    };
    WcComparisonsGridComponent.prototype.isVisible = function (field) {
        return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].displayed : false;
    };
    WcComparisonsGridComponent.prototype.aggregateFunc = function (columns) {
        this.aggregates = [{ field: 'total', aggregate: 'sum' }];
        for (var i in columns) {
            this.aggregates.push({ field: this.columns[i].name, aggregate: 'sum' });
        }
    };
    WcComparisonsGridComponent.prototype.ngOnDestroy = function () {
        this.manService.destroy();
    };
    WcComparisonsGridComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    WcComparisonsGridComponent.prototype.getMonthwiseCount = function (dateItem, column) {
        return dateItem[column.name];
    };
    WcComparisonsGridComponent.prototype.getMonthwiseTotalCount = function (totals, column) {
        if (totals[column.name]) {
            return totals[column.name].sum;
        }
    };
    WcComparisonsGridComponent.prototype.onExcelExport = function (e) {
        var rows = e.workbook.sheets[0].rows;
        e.workbook.sheets[0].rows.unshift({
            cells: [{
                    value: "Workers' Comp Comparison",
                    background: "#fff",
                    colSpan: 2,
                    color: "#000",
                    rowSpan: 1
                }]
        }, {
            cells: [{
                    value: "Selected Years : " + this.yearArray,
                    background: "#fff",
                    colSpan: 2,
                    color: "#000",
                    rowSpan: 1
                }]
        });
        for (var ri = 0; ri < rows.length; ri++) {
            var row = rows[ri];
            if (row.type == "footer") {
                for (var ci = 0; ci < row.cells.length; ci++) {
                    var cell = row.cells[ci];
                    if (Number(cell.value) >= 0) {
                        cell.value = Number(cell.value);
                    }
                }
            }
        }
    };
    WcComparisonsGridComponent.prototype.columnCustomization = function () {
        var monthDetailsContainer = [];
        for (var i in this.records) {
            for (var j in this.columns) {
                for (var k in this.records[i].months) {
                    var actualMonth = moment(this.records[i].months[k].startOfMonth).format('MMM') + moment(this.records[i].months[k].startOfMonth).format('YY');
                    if (actualMonth == this.columns[j].name) {
                        monthDetailsContainer.push({ count: this.records[i].months[k].count, startOfMonth: actualMonth });
                        break;
                    }
                    else {
                        if (Number(k) == (this.records[i].months.length - 1)) {
                            monthDetailsContainer.push({ count: 0, startOfMonth: this.columns[j].name });
                        }
                    }
                }
            }
            this.records[i].months = monthDetailsContainer;
            monthDetailsContainer = [];
        }
        var result = {};
        this.gridDataContainer = [];
        for (var k_1 in this.records) {
            result['organization'] = this.records[k_1].organization.name;
            result['total'] = this.records[k_1].total;
            for (var i_1 in this.records[k_1].months) {
                result[this.records[k_1].months[i_1].startOfMonth] = this.records[k_1].months[i_1].count;
            }
            this.gridDataContainer.push(result);
            result = {};
        }
        return this.gridDataContainer;
    };
    WcComparisonsGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.aggregateFunc(this.columns);
        this.gridDataContainer = this.columnCustomization();
        this.gridState.view = process(this.gridDataContainer, this.gridState.state);
        this.totals = aggregateBy(this.gridDataContainer, this.aggregates);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcComparisonsGridComponent.prototype, "subscriptions", void 0);
    return WcComparisonsGridComponent;
}());
export { WcComparisonsGridComponent };
