import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/index';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { benefitsConfig } from '../../../benefits.config';
import { coverageOptionTypesList } from '../../../models/index';
import { BenefitDetailsStandartManagementService, BenefitDetailsPermissionService, BenefitDetailsManagementService } from '../../../services/index';
var BenefitDetailsCoverageCalcOptionsComponent = /** @class */ (function () {
    function BenefitDetailsCoverageCalcOptionsComponent(modalService, commonManService, manService, permissionService) {
        var _this = this;
        this.modalService = modalService;
        this.commonManService = commonManService;
        this.manService = manService;
        this.permissionService = permissionService;
        this.records = [];
        this.min = 0;
        this.max = 99999999.99;
        this.step = 1;
        this.format = 'c2';
        this.isEditMode = false;
        this.spousesList = [this.createDropDownItem(true), this.createDropDownItem(false)];
        this.childrenValues = [];
        this.coverageOptionTypes = coverageOptionTypesList;
        this.childRange = Array(10).fill(0).map(function (x, i) { return _this.createDropDownItem(i); });
        this.isHealthBenifitPlanSelected = false;
        this.groupConfig = benefitsConfig.benefitGroupsConfig;
        this.pageSize = 50;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'type', dir: 'asc' }];
        this.gridState.state.take = this.pageSize;
        this.appConfig = appConfig;
        this.childrenValues = this.childRange.concat([this.createDropDownItem(99)]);
    }
    Object.defineProperty(BenefitDetailsCoverageCalcOptionsComponent.prototype, "isRowEditing", {
        get: function () {
            return _.isObjectLike(this.gridState.editedRecord);
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsCoverageCalcOptionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.acaCategoryLookupSubscription = this.commonManService.subscribeToAcaCategoryLookup(function (v) {
            _this.acaCategoryLookupValues = _.map(v, function (item) { return item.value; });
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.getBenifitPlanSubscription = this.commonManService.subscribeToSelectGroup(function (v) {
            _this.isHealthBenifitPlanSelected = v.type === _this.groupConfig.medical.type;
        });
        this.subscriptions.editMode = this.permissionService
            .subscribeToEditMode(function (v) {
            _this.isEditMode = v.providerInfo;
            _this.recalculateProhibitedCodeValues();
            _this.permissionService.toggleCanAddCovOptions(_this.isEditMode);
            if (!_this.isEditMode) {
                _this.gridState.closeEditor(_this.kendoGrid);
            }
        });
        this.subscriptions.coverageOption = this.manService
            .subscribeToAddNewCoverageOption(function (o) { return _this.addNewOption(o); });
        this.subscriptions.onEdit = this.gridState.onEdit$
            .subscribe(function (option) {
            _this.permissionService.toggleCanAddCovOptions(false);
        });
        this.subscriptions.onCancel = this.gridState.onCancel$
            .subscribe(function () {
            _this.permissionService.toggleCanAddCovOptions(true);
        });
        this.subscriptions.onSave = this.gridState.onSave$
            .subscribe(function (event) {
            _this.permissionService.toggleCanAddCovOptions(true);
            if (event.isNew) {
                _this.addOption(event.dataItem);
                return;
            }
            _this.updateOption(event.dataItem);
        });
        this.subscriptions.onRemove = this.gridState.onRemove$
            .subscribe(function (event) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            options.buttonOKtext = 'Yes';
            ConfirmDialog2Component.openDialog('Delete Coverage Option', 'Are you sure you want to remove selected coverage option? This will remove this plan for all enrolled employees', _this.modalService, function (isDelete) {
                if (isDelete) {
                    _this.removeOption(event.dataItem);
                    _this.permissionService.toggleCanAddCovOptions(true);
                }
            }, options);
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
        this.subscriptions.providerLine = this.commonManService
            .subscribeToSelectProviderLine(function (v) {
            _this.selectedProviderLine = v.current;
            _this.refreshGrid();
        });
        this.commonManService.loadAcaCategory();
        this.subscriptions.providerTier = this.manService
            .subscribeToSelectProviderTier(function (v) {
            _this.selectedProvidedTier = v;
            _this.gridState.closeEditor(_this.kendoGrid);
            _this.filterOutRecords();
            _this.permissionService.toggleCanAddCovOptions(true);
        });
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.ngOnDestroy = function () { };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.isCurrentRowEditing = function (option) {
        return this.isRowEditing && this.gridState.editedRecord.id === option.id;
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.addNewOption = function (option) {
        this.gridState.closeEditor(this.kendoGrid);
        this.kendoGrid.addRow(option);
        this.gridState.editedRecord = option;
        this.permissionService.toggleCanAddCovOptions(false);
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.addOption = function (option) {
        this.manService.saveCoverageOption(option, false);
        this.filterOutRecords();
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.updateOption = function (option) {
        this.manService.updateCoverageOption(option);
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.removeOption = function (option) {
        this.manService.removeCoverageOption(option);
        this.filterOutRecords();
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.onChangeContribution = function (option) {
        this.manService.updateTierOptionCost(this.selectedProviderLine, option);
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.recalculateProhibitedCodeValues = function () {
        var opts = this.manService.getAllCoverageOptions();
        if (opts) {
            this.prohibitedCodeValues = opts.map(function (o) { return o.code; });
        }
        else {
            this.prohibitedCodeValues = [];
        }
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.findDropDownItem = function (id, arr) {
        return _.find(arr, function (x) { return x.key === id; });
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.onSpouseChanged = function (option, value) {
        option.spouse = value.key;
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.onChildrenChanged = function (option, value) {
        option.children = value.key;
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.createDropDownItem = function (item) {
        return {
            key: item,
            value: item.toString()
        };
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.filterOutRecords = function () {
        this.records = _.filter(this.selectedProvidedTier.options, function (o) { return !o.stateIsDeleted; });
        this.refreshGrid();
    };
    BenefitDetailsCoverageCalcOptionsComponent.prototype.refreshGrid = function () {
        this.recalculateProhibitedCodeValues();
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsCoverageCalcOptionsComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsCoverageCalcOptionsComponent;
}());
export { BenefitDetailsCoverageCalcOptionsComponent };
