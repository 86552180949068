import { Component, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';

import { EmployeesPunchesContainer, EmployeeDailyPunches, PunchesDisplaySettings, DailyPunchesShift, PunchesEventFilter } from '../../../models/index';
import { PunchesManagementService } from '../../../services/punches/punches-management.service';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Lookup, Position, Shift, LookupType } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';
import { StateManagementService } from '../../../../common/services/index';
import { StateResetTypes } from '../../../../core/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-punches-filter',
  templateUrl: 'punches-filter.component.html',
  styleUrls: ['punches-filter.component.scss']
})
export class PunchesFilterComponent implements OnDestroy, OnChanges {
  @Input()
  public set orgLevelId(value: number) {
    this.m_orgLevelId = value;
    if (value > 0 && !!this.filter) {
      this.shiftsFilter = this.getShiftsFilter(this.orgLevelId, this.filter);
      this.positionsFilter = this.getPositionsFilter(this.orgLevelId, this.filter);
    }
  }

  public get orgLevelId(): number {
    return this.m_orgLevelId;
  }

  @Input()
  public container: EmployeesPunchesContainer;

  @Output()
  public filteringState: EventEmitter<boolean>;
  @Output()
  public close: EventEmitter<boolean>;

  public positionLookup: Lookup;
  public shiftLookup: Lookup;
  public filter: PunchesDisplaySettings;
  public shiftsFilter: number[];
  public positionsFilter: number[];

  private filterControlKey = 'EmployeePunchesFilters';
  private filtersRestored: boolean = false;
  private m_orgLevelId: number;

  @unsubscribe()
  private stateInitSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private saveFiltersSubscription: Subscription;

  constructor(private lookupService: LookupService, private punchesManagementService: PunchesManagementService, private stateManagement: StateManagementService) {
    this.filter = new PunchesDisplaySettings();
    this.filter.eventFilter = new PunchesEventFilter();
    this.stateInitSubscription = this.stateManagement.onInit$.subscribe(() => {
      this.filter = this.restoreFilters();
      this.shiftsFilter = this.getShiftsFilter(this.orgLevelId, this.filter);
      this.positionsFilter = this.getPositionsFilter(this.orgLevelId, this.filter);
      this.punchesManagementService.setFilter(this.filter);
      this.filtersRestored = true;
    });
    this.close = new EventEmitter();
    this.filteringState = new EventEmitter();

    this.saveFiltersSubscription = this.punchesManagementService.saveFilters$.subscribe(() => {
      this.saveFilters(this.filter);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['orgLevelId']) {
      this.loadLookup();
    }
    if (changes['container']) {
      this.createLookups();
    }
  }

  public onFiltersChanged(): void {
    if (!this.filtersRestored) {
      return;
    }
    this.filteringState.emit(true);
    setTimeout(() => {
      this.setShiftsFilter(this.orgLevelId, this.filter, this.shiftsFilter);
      this.setPositionsFilter(this.orgLevelId, this.filter, this.positionsFilter);
      this.punchesManagementService.applyFilter(this.container, this.filter);
      this.saveFilters(this.filter);
      this.close.emit(true);
      this.filteringState.emit(false);
    }, 5);
  }

  public onClose(): void {
    this.close.emit(false);
  }

  private createLookups(): void {
    if (!this.container || !this.shiftLookup) {
      return;
    }
    this.positionLookup = new Lookup();
    this.positionLookup.titleField = 'name';
    this.positionLookup.valueField = 'id';
    let positions: Position[] = _.filter(_.map(this.container.entities, (emp: EmployeeDailyPunches) => emp.header.position), (pos: Position) => !!pos && _.isString(pos.name) && pos.name.length > 0);
    this.positionLookup.items = _.uniqBy(positions, 'id');
    let shiftsIds: number[] = _.reduce(this.container.entities, (shifts: number[], emp: EmployeeDailyPunches) => {
      return shifts.concat(_.map(emp.header.scheduledShifts, (s: DailyPunchesShift) => s.shift.id));
    }, []);
    this.shiftLookup.items = _.filter(this.shiftLookup.items, (s: any) => _.includes(shiftsIds, s.id));
    this.shiftLookup.items = _.sortBy(this.shiftLookup.items, ['group', 'startDate', 'endDate']);
  }

  private loadLookup(): void {
    if (!this.orgLevelId) {
      return;
    }
    this.shiftLookup = null;
    this.lookupService.getLookup({ lookupType: LookupType.shift, orgLevelId: this.orgLevelId, employeeId: undefined })
      .then((lookup: Lookup) => {
        this.shiftLookup = lookup;
        this.createLookups();
      });
  }

  private saveFilters(filter: PunchesDisplaySettings): void {
    this.stateManagement.setControlState(this.filterControlKey, { value: filter }, StateResetTypes.OrgLevelChange);
  }

  private restoreFilters(): PunchesDisplaySettings {
    let filters: PunchesDisplaySettings;
    let state = this.stateManagement.getControlState(this.filterControlKey);
    if (state && state.value !== undefined) {
      filters = state.value;
      this.restoreEventFilters(filters);
      return filters;
    }
    filters = new PunchesDisplaySettings();
    filters.empMissingPunches = true;
    filters.empInvalidPunches = true;
    filters.empScheduleOnly = true;
    filters.empValid = true;
    filters.empNoPunches = true;
    filters.empRequest = true;
    filters.invalidLogin = true;
    this.restoreEventFilters(filters);
    return filters;
  }


  private restoreEventFilters(filters: PunchesDisplaySettings): void {
    if (!filters.eventFilter) {
      filters.eventFilter = new PunchesEventFilter();
      filters.eventFilter.empPunch = true;
      filters.eventFilter.editPunch = true;
      filters.eventFilter.essRequest = true;
      filters.eventFilter.invalidPunch = true;
      filters.eventFilter.schedule = true;
      filters.eventFilter.invalidLogin = true;      
    }
  }

  private getShiftsFilter(orgLevelId: number, filters: PunchesDisplaySettings): number[] {
    const shiftFilter = _.get(filters, 'shiftFilter');
    if (_.isObject(shiftFilter) && !_.isArray(shiftFilter)) {
      return _.isArray(shiftFilter[orgLevelId]) ? shiftFilter[orgLevelId] : [];
    }
    return [];
  }

  private setShiftsFilter(orgLevelId: number, filters: PunchesDisplaySettings, s: number[]): void {
    const shiftFilter = _.get(filters, 'shiftFilter');
    const shifts = _.isArray(s) ? s.concat() : [];
    if (_.isObject(shiftFilter) && !_.isArray(shiftFilter)) {
      shiftFilter[orgLevelId] = shifts;
    }
    if (!filters.shiftFilter) {
      filters.shiftFilter = {};
    }
    filters.shiftFilter[orgLevelId] = shifts;
  }

  private getPositionsFilter(orgLevelId: number, filters: PunchesDisplaySettings): number[] {
    const posFilter = _.get(filters, 'positionFilter');
    if (_.isObject(posFilter) && !_.isArray(posFilter)) {
      return _.isArray(posFilter[orgLevelId]) ? posFilter[orgLevelId] : [];
    }
    return [];
  }

  private setPositionsFilter(orgLevelId: number, filters: PunchesDisplaySettings, s: number[]): void {
    const posFilter = _.get(filters, 'positionFilter');
    const positions = _.isArray(s) ? s.concat() : [];
    if (_.isObject(posFilter) && !_.isArray(posFilter)) {
      posFilter[orgLevelId] = positions;
    }
    if (!filters.positionFilter) {
      filters.positionFilter = {};
    }
    filters.positionFilter[orgLevelId] = positions;
  }
}
