export interface ISchedulerConfig {
  api: {
    root: string;
    console: string;
    soConsole: string;
    entry: string;
    census: string;
    censusCategories: string;
    orglevel: string;
    employee: {
      root: string;
      replace: string;
      photo: string;
    }
    details: string;
    differences: string;
    dailyUnitAssignments: string;
    saveSlxPartnerData: string;
    positions:string;
    getPartnerDetails: string;
    totals: string;
    schedule: {
      root: string;
      generate: string;
      createRotationFromSchedule: string;
      employee: string;
      orglevel: {
        root: string,
        employees: string,
        employee: string,
        openShift: {
          root: string,
          count: string,
          staffing:string,
          customer:string,
          partner:string,
          shift:string
        },
        agencyConsole: {
          root:string,
          shortInfo: string ,
          custamerId:string;
          startDate: string,
          endDate: string,
        }
        leaveRequests: {
          root: string,
          details: string,
          approve: string,
          deny: string,
          submitForReview: string,
        },
        posted: string,
        budgetedParsDetails: string,
        scheduleNotPosted: string
      },
      shift: {
        root: string;
        shiftEligibleEmployees: string;
        fill: string;
        notifications: {
          root: string;
          addPhoneCallInfo: string;
          sendSms: string;
          sendAppNotification: string;
        }
      },
      post: {
        root: string;
        shifts: string;
        options: string;
      },
      backup: string;
      cycle: string;
      summary: {
        root: string,
        messages: string
      },
      openPosition: string,
    },
    vacations: {
      root: string;
      yearVacationPlan: string;
    },
    employees: {
      root: string;
      vacationPlanner: string;
      vacationPlannerYearSummary: string,
      changeShift: string;
    },
    automation: {
      root: string;
      config: string;
    },
    shiftSwap: {
      root: string;
      deny: string;
      approve: string;
    },
    schedulerotation:{
      root: string,
      permissions: string,
      employeerotation: string,
      noRotationEmployeeList: string,
      saveemployeerotation: string,
      availShifts: {
        availShiftsList: string
      }
      generate: {
        root: string,
      },
      assignTerminatedSlotEmployee: {
        root: string
      },
      futureDatesValidations: {
        root: string
      },
      deleteSlotRotation:string
    }
  };
  signalr: {
    employeeScheduleChanged: string;
  };
  timecards : {
    root: string;
    timeAndAttendance : string;
    approvedPayperiodForOrgLevel : string;
    approvedPayperiod : string;
    approvedPayperiodForIndividual : string;
    approvedPayperiodForQuickEdit : string;
  }
  individualScheduleEmpListHeaderHeight: number;
}

export const schedulerConfig: ISchedulerConfig = {
  api: {
    root: 'schedule',
    entry: 'entry',
    console: 'console',
    soConsole: 'soConsole',
    census: 'census',
    censusCategories: 'categories',
    orglevel: 'orglevel',
    employee: {
      root: 'employee',
      replace: 'replace',
      photo: 'photo'
    },
    details: 'details',
    differences: 'differences',
    dailyUnitAssignments: 'dailyUnitAssignments',
    saveSlxPartnerData: 'saveSlxPartnerData',
    positions:'positions',
    getPartnerDetails: 'getPartnerDetails',
    totals: 'totals',
    schedule: {
      root: 'schedule',
      generate: 'generate',
      createRotationFromSchedule: 'rotation/generate',
      employee: 'employee',
      orglevel: {
        root: 'orglevel',
        employees: 'employees',
        employee: 'employee',
        openShift: {
          root: 'openShift',
          count: 'count',
          staffing: 'staffing',
          customer: 'customer',
          partner: 'partner',
          shift:'shift'
        },
        agencyConsole:{
          root: 'reports',
          shortInfo: 'shortDetails',
          custamerId: 'custamerId',
          startDate: 'startDate',
          endDate: 'endDate',
        },
        leaveRequests: {
          root: 'leaveRequests',
          details: 'details',
          approve: 'approve',
          deny: 'deny',
          submitForReview: 'submit',
        },
        posted: 'posted',
        budgetedParsDetails: 'budgetedParsDetails',
        scheduleNotPosted: 'scheduleNotPosted',
      },
      shift: {
        root: 'shift',
        shiftEligibleEmployees: 'shiftEligibleEmployees',
        fill: 'fill',
        notifications: {
          root: 'notifications',
          addPhoneCallInfo: 'addPhoneCallInfo',
          sendSms: 'sendSms',
          sendAppNotification: 'sendAppNotification'
        }
      },
      post: {
        root: 'post',
        shifts: 'shifts',
        options: 'options'
      },
      backup: 'backup',
      cycle: 'cycle',
      summary: {
        root: 'summary',
        messages: 'messages'
      },
      openPosition: 'openposition',
    },
    vacations: {
      root: 'vacations',
      yearVacationPlan: 'yearVacationPlan'
    },
    employees: {
      root: 'employees',
      vacationPlanner: 'vacationPlanner',
      vacationPlannerYearSummary: 'vacationPlannerYearSummary',
      changeShift: 'changeShift'
    },
    automation: {
      root: 'automation',
      config: 'config',
    },
    shiftSwap: {
      root: 'shiftswap',
      approve: 'approve',
      deny: 'deny'
    },
    schedulerotation:{
      root : 'schedulerotation',
      permissions: 'permissions',
      employeerotation:'employeerotation',
      noRotationEmployeeList:'norotationemployeelist',
      saveemployeerotation : 'saverotations',
      availShifts: {
        availShiftsList: 'availableshifts'
      },
      generate: {
        root: 'generateRotations',
      },
      assignTerminatedSlotEmployee: {
        root: 'assignTerminatedSlotEmployee'
      },
      futureDatesValidations: {
        root: 'futureDatesValidations'
      },
      deleteSlotRotation:'deleteSlotRotation'
    }
  },
  signalr: {
    employeeScheduleChanged: 'EmployeeScheduleChanged'
  },
  individualScheduleEmpListHeaderHeight: 70,
  timecards : {
    root: 'timecards',
    timeAndAttendance : 'ta',
    approvedPayperiodForOrgLevel : 'approvedPayperiodForOrgLevel',
    approvedPayperiodForIndividual : 'approvedPayperiodForIndividual',
    approvedPayperiod : 'approvedPayperiod',
    approvedPayperiodForQuickEdit : 'approvedPayperiodForQuickEdit'
  }
};
