import * as tslib_1 from "tslib";
import { Assert } from '../../../../framework/index';
import { UrlParamsService, ApiUtilService, OdataApiService } from '../../../../common/services/index';
import { PbjExportMapService } from './pbj-export-map.service';
import { pbjConfig } from '../../pbj.config';
import { IPBJExportLogRecord, PBJExportLogRecord, IPBJExportLogCensusData, PBJExportLogCensusData, IPBJExportLogEmployeeData, PBJExportLogEmployeeData, IPBJExportLogError, PBJExportLogError, IPBJExportLogStaffingHours, PBJExportLogStaffingHours, IPBJExportLogHeader, PBJExportLogHeader, IPBJExportResult, PBJExportResult, PBJExportRequest, PBJBatchExport } from '../../models/index';
var PbjExportApiService = /** @class */ (function () {
    function PbjExportApiService(apiUtilService, odataApiService, urlParamsService, mapService) {
        this.apiUtilService = apiUtilService;
        this.odataApiService = odataApiService;
        this.urlParamsService = urlParamsService;
        this.mapService = mapService;
    }
    PbjExportApiService.prototype.getPBJExportLogRecords = function (query, orgLevelId) {
        var _this = this;
        var promise = this.odataApiService.getData('PBJExportLogRecord', query, function (dto) { return _this.mapService.mapPBJExportLogRecord(dto); }, { orgLevelId: orgLevelId });
        return promise;
    };
    PbjExportApiService.prototype.getPBJExportLogCensusData = function (query, exportId) {
        var _this = this;
        var promise = this.odataApiService.getData('PBJExportLogCensusData', query, function (dto) { return _this.mapService.mapPBJExportLogCensusData(dto); }, { exportId: exportId });
        return promise;
    };
    PbjExportApiService.prototype.getPBJExportLogEmployeeData = function (query, exportId) {
        var _this = this;
        var promise = this.odataApiService.getData('PBJExportLogEmployee', query, function (dto) { return _this.mapService.mapPBJExportLogEmployeeData(dto); }, { exportId: exportId });
        return promise;
    };
    PbjExportApiService.prototype.getPBJExportLogError = function (query, exportId) {
        var _this = this;
        var promise = this.odataApiService.getData('PBJExportLogError', query, function (dto) { return _this.mapService.mapPBJExportLogError(dto); }, { exportId: exportId });
        return promise;
    };
    PbjExportApiService.prototype.getPBJExportLogStaffingHours = function (query, exportId) {
        var _this = this;
        var promise = this.odataApiService.getData('PBJExportLogStaffingHours', query, function (dto) { return _this.mapService.mapPBJExportLogStaffingHours(dto); }, { exportId: exportId });
        return promise;
    };
    PbjExportApiService.prototype.getPBJExportLogHeader = function (exportId) {
        var _this = this;
        var url = this.getExportApiRoot() + "/" + exportId + "/" + pbjConfig.api.pbj.export.header;
        var promise = this.apiUtilService.request(this.urlParamsService.createGetRequest(url))
            .then(function (response) {
            return _this.mapService.mapPBJExportLogHeader(response.data);
        });
        return promise;
    };
    PbjExportApiService.prototype.performExport = function (req) {
        var _this = this;
        Assert.isNotNull(req, 'req');
        var url = this.getExportApiRoot() + "/" + pbjConfig.api.pbj.export.performExport;
        var promise = this.apiUtilService.request(this.urlParamsService.createPostRequest(url, this.mapService.mapPBJExportRequestDto(req)))
            .then(function (response) {
            return _this.mapService.mapPBJExportResult(response.data);
        });
        return promise;
    };
    PbjExportApiService.prototype.performBatchExport = function (req) {
        var _this = this;
        Assert.isNotNull(req, 'req');
        var url = this.getExportApiRoot() + "/" + pbjConfig.api.pbj.export.performBatchIDExport;
        var promise = this.apiUtilService.request(this.urlParamsService.createPostRequest(url, this.mapService.mapPBJExportRequestDto(req)))
            .then(function (response) {
            return _this.mapService.mapPBJExportResultFlagEnabledRecords(response.data);
        });
        return promise;
    };
    PbjExportApiService.prototype.performDuplicateExport = function (orgLevelId) {
        var _this = this;
        Assert.isNotNull(orgLevelId, 'req');
        var url = this.apiUtilService.getApiRoot() + "/" + pbjConfig.api.pbj.root + "/" + pbjConfig.api.pbj.duplicateWorkers.root + "/" + pbjConfig.api.pbj.duplicateWorkers.GetEmployeeDupes + "?orgLevelId=" + orgLevelId;
        var promise = this.apiUtilService.request(this.urlParamsService.createGetRequest(url))
            .then(function (response) {
            return _this.mapService.mapPBJEmployeeDuplicates(response.data);
        });
        return promise;
    };
    PbjExportApiService.prototype.changeDisposition = function (req) {
        var _this = this;
        Assert.isNotNull(req, 'req');
        var url = this.apiUtilService.getApiRoot() + "/" + pbjConfig.api.pbj.root + "/" + pbjConfig.api.pbj.duplicateWorkers.root + "/" + req.primary_org_level_id + "/" + pbjConfig.api.pbj.duplicateWorkers.changeDisposition;
        var promise = this.apiUtilService.request(this.urlParamsService.createPutRequest(url, req))
            .then(function (response) {
            return _this.mapService.mapPBJEmployeeDuplicates(response.data);
        });
        return promise;
    };
    PbjExportApiService.prototype.getExportMatchesDefaultDate = function (orgLevelId) {
        Assert.isNotNull(orgLevelId, 'req');
        var url = this.apiUtilService.getApiRoot() + "/" + pbjConfig.api.pbj.root + "/" + pbjConfig.api.pbj.duplicateWorkers.root + "/" + pbjConfig.api.pbj.duplicateWorkers.getExportMatchesDefaultDate + "?orgLevelId=" + orgLevelId;
        var promise = this.apiUtilService.request(this.urlParamsService.createGetRequest(url));
        return promise;
    };
    PbjExportApiService.prototype.updateExportMatchesDefaultDate = function (req, orgLevelId) {
        Assert.isNotNull(req, 'req');
        var url = this.apiUtilService.getApiRoot() + "/" + pbjConfig.api.pbj.root + "/" + pbjConfig.api.pbj.duplicateWorkers.root + "/" + orgLevelId + "/" + pbjConfig.api.pbj.duplicateWorkers.updateMatchesExportDate;
        var promise = this.apiUtilService.request(this.urlParamsService.createPutRequest(url, req));
        return promise;
    };
    PbjExportApiService.prototype.getBatchIDExportData = function (req) {
        var _this = this;
        Assert.isNotNull(req, 'req');
        var url = this.getExportApiRoot() + "/" + pbjConfig.api.pbj.export.getBatchIDExportData;
        var promise = this.apiUtilService.request(this.urlParamsService.createPostRequest(url, this.mapService.mapPBJBatchExportDto(req)))
            .then(function (response) {
            return _this.mapService.mapPBJParentGridExportRecords(response.data);
        });
        return promise;
    };
    PbjExportApiService.prototype.getChildExportData = function (req) {
        var _this = this;
        Assert.isNotNull(req, 'req');
        var url = this.getExportApiRoot() + "/" + pbjConfig.api.pbj.export.getChildExportData;
        var promise = this.apiUtilService.request(this.urlParamsService.createPostRequest(url, this.mapService.mapPBJChildExportDto(req)))
            .then(function (response) {
            return _this.mapService.mapPBJChildGridExportRecords(response.data);
        });
        return promise;
    };
    PbjExportApiService.prototype.getPBJExportData = function (exportId) {
        var url = this.getExportApiRoot() + "/" + exportId + "/" + pbjConfig.api.pbj.export.data;
        var promise = this.apiUtilService.requestForFile(this.urlParamsService.createGetRequest(url))
            .then(function (response) { return response; });
        /*
            let promise: Promise<PBJExportContainer> = this.apiUtilService.request<IPBJExportContainer, Meta>(this.urlParamsService.createGetRequest(url))
              .then((response: ResponseBody<IPBJExportContainer, Meta>) =>
                this.mapService.mapPBJExportContainer(response.data));*/
        return promise;
    };
    PbjExportApiService.prototype.getExportApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + pbjConfig.api.pbj.root + "/" + pbjConfig.api.pbj.export.root;
    };
    PbjExportApiService.prototype.downloadAllChildFiles = function (exportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getExportApiRoot() + "/" + pbjConfig.api.pbj.export.downloadBlobZipFile;
                request = this.urlParamsService.createPostRequest(url, exportId);
                return [2 /*return*/, this.apiUtilService.requestForFile(request)
                        .then(function (file) { return file; })];
            });
        });
    };
    PbjExportApiService.prototype.downloadExportSummaryExcel = function (exportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getExportApiRoot() + "/" + pbjConfig.api.pbj.export.DownloadExportSummary;
                request = this.urlParamsService.createPostRequest(url, exportId);
                return [2 /*return*/, this.apiUtilService.requestForFile(request)
                        .then(function (file) { return file; })];
            });
        });
    };
    PbjExportApiService.prototype.downloadBulkExportSummary = function (batchIdObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getExportApiRoot() + "/" + pbjConfig.api.pbj.export.DownloadBulkExport;
                request = this.urlParamsService.createPostRequest(url, batchIdObj);
                return [2 /*return*/, this.apiUtilService.requestForFile(request)
                        .then(function (file) { return file; })];
            });
        });
    };
    PbjExportApiService.prototype.downloadExcelFile = function (exportObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getExportApiRoot() + "/" + pbjConfig.api.pbj.export.downloadExcelFile;
                request = this.urlParamsService.createPostRequest(url, exportObj);
                return [2 /*return*/, this.apiUtilService.requestForFile(request)
                        .then(function (file) { return file; })];
            });
        });
    };
    PbjExportApiService.prototype.downloadPDFFile = function (exportObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getExportApiRoot() + "/" + pbjConfig.api.pbj.export.downloadPDFFile;
                request = this.urlParamsService.createPostRequest(url, exportObj);
                return [2 /*return*/, this.apiUtilService.requestForFile(request)
                        .then(function (file) { return file; })];
            });
        });
    };
    return PbjExportApiService;
}());
export { PbjExportApiService };
