import * as _ from 'lodash';

import { IColumnSettings } from '../../../core/models/index';
import { Aca1095cActions } from './index';

export class Aca1095cSettings {
  public columns: Aca1095cColumnsSettings;
}

export class Aca1095cColumnsSettings {
  public columnsMap: StringMap<IColumnSettings>;
  public columns: IColumnSettings[];
  public createColumns(): void {
    this.columns = [
      {
        name: 'hireDate',
        description: 'Hire Date',
        displayed: true
      },
      {
        name: 'organization',
        description: 'Organization',
        displayed: true
      },
      {
        name: 'department',
        description: 'Department',
        displayed: false
      },
      {
        name: 'position',
        description: 'Position',
        displayed: false
      },
      {
        name: 'terminationDate',
        description: 'Term Date',
        displayed: false
      },
      {
        name: 'benefitDeclined',
        description: 'Benefit Declined',
        displayed: false
      },
      {
        name: 'purchasedMarketplace',
        description: 'Purchased Marketplace',
        displayed: false
      }
    ];
    this.mapColumns();
  }

  public applySecurity(actions: Aca1095cActions): void {
    if (actions) {
      if (!actions.organization) {
        let item = _.find(this.columns, ['name', 'organization']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.department) {
        let item = _.find(this.columns, ['name', 'department']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.position) {
        let item = _.find(this.columns, ['name', 'position']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.hireDate) {
        let item = _.find(this.columns, ['name', 'hireDate']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.terminationDate) {
        let item = _.find(this.columns, ['name', 'terminationDate']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.acaRecord) {
        let item = _.find(this.columns, ['name', 'benefitDeclined']);
        this.columns = _.without(this.columns, item);
        item = _.find(this.columns, ['name', 'purchasedMarketplace']);
        this.columns = _.without(this.columns, item);
      }
      this.mapColumns();
    }
  }

  public mapColumns(): void {
    this.columnsMap = _.keyBy(this.columns, (column: IColumnSettings) => {
      return column.name;
    });
  }
}
