import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';

import { State } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import {
  GeolocationEntity,
  IGeolocationEntityDTO,
  IGeolocationEvent,
  GeolocationEvent,
  ViewModeSettings,
  SearchModeSettings,
  CoordsModeSettings,
  MapCircleChangedEvent
 } from '../../../models/index';
import { GeolocationManagementService } from '../../../services/index';

export interface IMetric {
  id: number;
  name: string;
  value: number;
}

export class GeoState extends State {
  public id: string;
  constructor(name: string) {
    super();
    this.id = name;
    this.name = name;
  }
}

@Component({
    moduleId: module.id,
    selector: 'slx-geolocation-form',
    templateUrl: 'geolocation-form.component.html',
    styleUrls: ['geolocation-form.component.scss']
})

export class GeolocationFormComponent implements OnInit, OnDestroy, AfterViewInit {
  public viewModeSettings: ViewModeSettings;
  public activeModeSettings: SearchModeSettings | CoordsModeSettings;
  public viewModeDefaults = { radius: 300, commonZoom: 7, placeZoom: 16 }; // radius = meters
  public activeModeDefaults = { zoom: 16 };
  public override: { lat: number, lng: number, overriddenDistance: number, distance: number } = null;
  public canOverride: boolean = false;

  public isLoading = true;
  public statesLookup: GeoState[];
  public editingEntity: GeolocationEntity;
  public originalEntity: IGeolocationEntityDTO;
  public orgLevel: OrgLevel;
  public currentState: GeoState;
  public currentDistance: number;
  public readonly metricsList: IMetric[] = [{ id: 1, name: 'Meters', value: 1 }, { id: 2, name: 'Yards', value: 1.0936 }];
  public currentMetric: IMetric;
  public get isDisabled(): boolean {
    return !_.isNull(this.override);
  }
  @ViewChild('geoForm', {static: true})
  public geoForm: NgForm;

  private orgLevelLat: number = 0;
  private orgLevelLng: number = 0;
  private radiusInMeters: number = 0;
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: GeolocationManagementService) {
    this.editingEntity = new GeolocationEntity();
    this.currentMetric = _.head(this.metricsList);
  }

  public ngOnInit(): void {
    this.subscriptions.orgLevel = this.manService.subscribeToOrgLevelChanged((orgLevel: OrgLevel) => {
      this.handleOrgLevelChange(orgLevel);
    });

    this.subscriptions.entitySelected = this.manService.subscribeToEntitySelected((entity: GeolocationEntity) => {
      if (_.isObjectLike(entity)) {
        this.handleEditEvent(entity);
      }
    });

    this.subscriptions.buttons = this.manService.subscribeToGeoEvent((event: IGeolocationEvent) => {
      if (event.isSave) {
        this.handleSaveEvent();
      } else if (event.isUpdate) {
        this.handleUpdateEvent();
      } else if (event.isCancel) {
        this.handleCancelEvent();
      }
    });

    this.subscriptions.states = this.manService.subscribeToStatesLoaded((states: State[]) => {
      this.statesLookup = _.map(states, (s:State) => new GeoState(s.name));
      this.currentState = _.head(this.statesLookup);
    });
  }

  public ngAfterViewInit(): void {
    this.subscriptions.form = this.geoForm.statusChanges.subscribe(() => {
      if (this.editingEntity.currentLat !== 0 && this.editingEntity.currentLng !== 0) {
        this.updateFormButtonsState(this.geoForm.valid);
      }
    });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onSearchPlaceOnMap(): void {
    const metersMetric = _.head(this.metricsList);
    const fullAddress = `${this.editingEntity.address}, ${this.editingEntity.city}, ${this.editingEntity.state} ${this.editingEntity.zip}`;
    this.activeModeSettings = new SearchModeSettings(fullAddress, this.viewModeDefaults.radius, this.viewModeDefaults.placeZoom);
    this.editingEntity.currentDistance = this.viewModeDefaults.radius;
    this.editingEntity.currentUnitOfMeasure = metersMetric.id;
    this.currentDistance = this.viewModeDefaults.radius;
    this.currentMetric = metersMetric;
  }

  public onClickOverride(): void {
    // console.log("debug form onclickoverride", this.editingEntity);
    this.assignOverride(this.editingEntity.currentLat, this.editingEntity.currentLng, 0.002, this.editingEntity.overriddenDistance, this.editingEntity.distance);

    this.editingEntity.isOverridden = true;
    this.editingEntity.overriddenUnitOfMeasure = this.editingEntity.unitOfMeasure;
    this.editingEntity.overriddenDistance = this.editingEntity.distance;
  }

  public onClickDeleteOverride(): void {
    this.editingEntity.isOverridden = false;
    this.resetOverride(this.editingEntity);
  }

  public onCenterChange(event: MapCircleChangedEvent): void {
    if (event.dragged && !this.editingEntity.isOverridden) {
      this.assignOverride(event.lat, event.lng,0,this.editingEntity.overriddenDistance, this.editingEntity.distance);

      this.editingEntity.isOverridden = true;
      this.editingEntity.overriddenUnitOfMeasure = this.editingEntity.unitOfMeasure;
      this.editingEntity.overriddenDistance = this.editingEntity.distance;
    }
    this.editingEntity.currentLat = event.lat;
    this.editingEntity.currentLng = event.lng;
  }

  public onRadiusChanged(r: number): void {
    const radius = this.radiusInMeters = _.round(r);
    const distance = this.getDistance(radius, this.currentMetric);
    this.editingEntity.currentDistance = distance;
    this.currentDistance = distance;
  }

  public onNotFound(isFoundAddress: boolean): void {
    this.updateFormButtonsState(isFoundAddress);
  }

  public onChangedMetric(metric: IMetric): void {
    const distance = this.getDistance(this.radiusInMeters, metric);
    this.editingEntity.currentDistance = distance;
    this.editingEntity.currentUnitOfMeasure = metric.id;
    this.currentDistance = distance;
  }

  public onChangedState(state: GeoState): void {
    this.editingEntity.state = state.name;
  }

  public handleOrgLevelChange(orgLevel: OrgLevel): void {
    this.orgLevel = orgLevel;
    this.orgLevelLat = _.get(this.orgLevel, 'location.latitude', 0) || 0;
    this.orgLevelLng = _.get(this.orgLevel, 'location.longitude', 0) || 0;
    this.viewModeSettings = new ViewModeSettings(this.orgLevelLat, this.orgLevelLng, this.viewModeDefaults.commonZoom);
    this.editingEntity.orgLevelId = this.orgLevel.id;
    this.editingEntity.orgLevelName = this.orgLevel.name;
  }

  private handleEditEvent(entity: GeolocationEntity): void {
    this.editingEntity = entity;

    let distance = entity.currentDistance;
    let unitOfMeasure = entity.currentUnitOfMeasure;

    this.radiusInMeters = this.getDistanceInMeters(distance, unitOfMeasure);
    this.activeModeSettings = new CoordsModeSettings(entity.latitude, entity.longitude, this.radiusInMeters, this.activeModeDefaults.zoom);
    this.currentDistance = distance;
    this.currentMetric = this.getMetric(unitOfMeasure);
    this.currentState = new GeoState(entity.state);
    this.updateFormButtonsState(true);
    if (entity.isOverridden) {
      // console.log("debug form handleeditevent", entity);
      this.assignOverride(entity.currentLat, entity.currentLng,0,this.editingEntity.overriddenDistance, this.editingEntity.distance);
    }
  }

  private async handleSaveEvent(): Promise<void> {
    await this.manService.saveGeolocation(this.editingEntity);
    this.manService.loadGeolocations(this.editingEntity.orgLevelId);
    this.manService.closeForm();
    this.resetSettings();
  }

  private async handleUpdateEvent(): Promise<void> {
    await this.manService.updateGeolocation(this.editingEntity);
    this.manService.loadGeolocations(this.editingEntity.orgLevelId);
    this.manService.closeForm();
    this.resetSettings();
  }

  private handleCancelEvent(): void {
    this.resetSettings();
  }

  private resetSettings(): void {
    this.activeModeSettings = null;
    this.editingEntity = new GeolocationEntity();
    this.editingEntity.orgLevelId = this.orgLevel.id;
    this.editingEntity.orgLevelName = this.orgLevel.name;
    this.override = null;
    this.currentDistance = 0;
    this.currentMetric = _.head(this.metricsList);
    this.currentState = _.head(this.statesLookup);
    this.canOverride = false;
    this.viewModeSettings = new ViewModeSettings(this.orgLevelLat, this.orgLevelLng, this.viewModeDefaults.commonZoom);
  }

  private getDistance(distance: number, metric: IMetric): number {
    return _.round(distance * metric.value);
  }

  private getDistanceInMeters(distance: number, metricId: number): number {
    const metric = _.find(this.metricsList, { id: metricId });
    if (metric) {
      if (metric.name === 'Meters') {
        return distance;
      }
      return _.round(distance / metric.value);
    }
    return 0;
  }

  private getMetric(metricId: number): IMetric {
    const metric = _.find(this.metricsList, { id: metricId });
    if (metric) {
      return metric;
    }
    return null;
  }

  private updateFormButtonsState(isActiveState: boolean): void {
    this.canOverride = isActiveState;
    this.manService.changeToolbarMode(isActiveState);
  }

  private resetOverride(e: GeolocationEntity): void {
    this.override = null;
    e.overriddenDistance = 0;
    e.overriddenUnitOfMeasure = 0;
    e.overriddenLatitude = 0;
    e.overriddenLongitude = 0;
  }

  private assignOverride(latitude: number, longitude: number, shift: number = 0, overriddenDistance : number, distance: number): void {
    const lat = shift > 0 ? latitude + shift : latitude;
    const lng = shift > 0 ? longitude + shift : longitude;

    this.override = { lat, lng, overriddenDistance, distance };
  }
}
