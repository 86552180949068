import { Directive, Input, forwardRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';

const MAX_TIME_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxTimeValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[maxTime][formControlName],[maxTime][formControl],[maxTime][ngModel]',
  /* tslint:enable */
  providers: [MAX_TIME_VALIDATOR]
})
export class MaxTimeValidator implements Validator, OnChanges {
  @Input()
  public maxTime: string;

  /* tslint:disable */
  private _validator: ValidatorFn;
  private _onChange: () => void;
  /* tslint:enable */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['maxTime']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): {[key: string]: any} {
    return !_.isNil(this.maxTime) ? this._validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = CommonValidators.maxTime(this.maxTime);
  }
}
