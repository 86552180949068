
export enum LoaRequestClass {
  Continuous = 'Continuous', Intermittent = 'Intermittent'
}

export enum LoaRepeatType {
  Custom, Daily, Weekly, Monthly
}

export enum LoaCategory {
  None = 'None', OnLeave = 'OnLeave', Returning = 'Returning', Upcoming = 'Upcoming', PastDue = 'PastDue', Incomplete = 'Incomplete'
}

export enum LoaActions {
  CreateNewContinuous = 'CreateNewContinuous', CreateNewIntermittent='CreateNewIntermittent', View = 'View'
}
