<slx-spinner [show]="isLoading">
 <div class="time-and-attendance details-timeline-container" [ngClass]="{'collapsed': isCollapsed}">
  <div class="details-timeline-header">
          <div class="header-left">
            <i class="details-timeline-header-main-icon fas fa-clock" aria-hidden="true"></i>
            <h4 class="header-label">Arrivals + Departures Timeline</h4>
            <h4 class="header-label-mobile">Arrivals + Departures</h4>
          </div>
          <div class="collapse-toggle header-item" (click)="toggleState()" slxPropertyInitState controlId="isDetailGridCollapsed" [(propertySave)]="isCollapsed">
              <i class="toggle-icon far fa-angle-down" *ngIf="!isCollapsed" aria-hidden="true"></i>
              <i class="toggle-icon far fa-angle-up" *ngIf="isCollapsed" aria-hidden="true"></i>
          </div>
  </div>
  <div class="details-timeline-content slx-high-box" [ngClass]="{'collapsed': isCollapsed}">
    <slx-arrivals-departures-timeline-details-toolbar [orgLevelId]="orgLevelId"></slx-arrivals-departures-timeline-details-toolbar>
    <slx-arrivals-departures-timeline></slx-arrivals-departures-timeline>
    <slx-arrivals-departures-timeline-details-grid></slx-arrivals-departures-timeline-details-grid>
  </div>
</div>
</slx-spinner>
