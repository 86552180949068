import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  forwardRef,
  SimpleChanges,
  Optional,
  OnChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { Assert } from '../../../framework/index';
import * as moment from 'moment';
import { commonConfig } from '../../common.config';
import { DateValidator } from '../../validators/index';
import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../utils/index';
@Component({
  moduleId: module.id,
  /* tslint:disable */
  selector: '[slx-time-picker]',
  /* tslint:enable */
  templateUrl: 'time-picker.component.html',
  styleUrls: ['time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {

  @Input() public placeholder: string = '';
  @Input()
  public set readonly(ro: boolean) {
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
    this.checkReadonly();
  }

  @Input()
  public set minDate(value: Date) {
    this.minDateInt = value;
    this.minTimeCurrentDate = this.getTimeinCurrentDate(value);
    if (this.kendoTimePicker) {
      this.kendoTimePicker.min(this.minTimeCurrentDate);
    }
  }

  @Input()
  public set maxDate(value: Date) {
    this.maxDateInt = value;
    this.maxTimeCurrentDate = this.getTimeinCurrentDate(value);
    if (this.kendoTimePicker) {
      this.kendoTimePicker.max(this.maxTimeCurrentDate);
    }
  }

  @Input() public format: string = 'MM/dd/yyyy hh:mm tt';
  @Input() public interval: number = 15;
  @Input() public dateInput: boolean = false;

  @Output() public onDateChanged: EventEmitter<Date>;

  private onTouchedCallback: () => void;
  private onChangeCallback: (val: any) => void;
  private kendoTimePicker: kendo.ui.TimePicker;
  private elementRef: ElementRef;
  private datePickerEnabled: boolean;
  private ngControl: NgControl;

  private originalDate: Date;
  private lastValue: moment.Moment;

  private minDateInt: Date;
  private maxDateInt: Date;
  private minTimeCurrentDate: Date;
  private maxTimeCurrentDate: Date;


  constructor(elementRef: ElementRef, ngControl: NgControl) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.ngControl = ngControl;
    ngControl.valueAccessor = this;
    this.datePickerEnabled = true;
    this.onDateChanged = new EventEmitter<Date>();
  }

  public ngOnInit(): void {
    let kendoTimePickerOptions: kendo.ui.TimePickerOptions = this.kendoTimePickerOptions();
    this.$element.kendoTimePicker(kendoTimePickerOptions);
    this.kendoTimePicker = this.$element.data('kendoTimePicker');
    this.checkReadonly();
    this.kendoTimePicker.min(this.minTimeCurrentDate);
    this.kendoTimePicker.max(this.maxTimeCurrentDate);
    //this.$element.on('input', () => { this.valueChange(this.$element.val()); });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.checkReadonly();
  }

  public ngOnDestroy(): void {
    if (this.kendoTimePicker) {
      this.kendoTimePicker.destroy();
    }
  }

  public writeValue(value?: Date): void {

    let date: moment.Moment = moment(value);
    let dateChanged: boolean = false;
    if (this.originalDate) {
      if (!date.isSame(this.originalDate)) {
        dateChanged = true;
      }
    }
    this.originalDate = value;

    if (value) {
      let timeInCurrentDate: Date = this.getTimeinCurrentDate(value);
      this.lastValue = moment(timeInCurrentDate);
    } else {
      this.lastValue = null;
    }

    if (this.kendoTimePicker) {
      if (value === null || value === undefined) {
        this.kendoTimePicker.value(null);
        return;
      }
      this.kendoTimePicker.value(this.lastValue.toDate());
    }
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  private getTimeinCurrentDate(source: Date): Date {
    let sourceMoment: moment.Moment = moment(source);
    let currentMoment: moment.Moment = moment();
    currentMoment.hours(sourceMoment.hours()).minutes(sourceMoment.minutes()).seconds(sourceMoment.seconds()).milliseconds(sourceMoment.milliseconds());
    return currentMoment.toDate();
  }

  private setTimeToMoment(source: moment.Moment, target: moment.Moment): void {
    target.hours(source.hours()).minutes(source.minutes()).seconds(source.seconds()).milliseconds(source.milliseconds());
  }

  private checkReadonly(): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.datePickerEnabled = !(Boolean(myattr) || myattr === 'readonly');
    if (this.kendoTimePicker) {
      this.kendoTimePicker.readonly(!this.datePickerEnabled);
    }
  }

  private kendoTimePickerOptions(): kendo.ui.TimePickerOptions {
    let options: kendo.ui.TimePickerOptions = {
      format: this.format,
      interval: this.interval,
      dateInput: this.dateInput,
      change: (e: kendo.ui.TimePickerChangeEvent) => {
        this.valueChanged(e);
      }
    };

    if (this.minTimeCurrentDate) {
      options.min = this.minTimeCurrentDate;
    }

    if (this.maxTimeCurrentDate) {
      options.max = this.maxTimeCurrentDate;
    }

    if (this.lastValue) {
      options.value = this.lastValue.toDate();
      this.lastValue = undefined;
    }
    return options;
  }

  private valueChanged(e: kendo.ui.TimePickerChangeEvent): void {

    let value: any = this.kendoTimePicker.value();
    if (!value) {
      return;
    }

    let newTimeInCurrentMoment: moment.Moment = moment(value);

    if (!this.lastValue || !this.lastValue.isSame(newTimeInCurrentMoment)) {
      // update time in original date
      let timeInOriginalMoment: moment.Moment = moment(this.originalDate);
      this.setTimeToMoment(newTimeInCurrentMoment, timeInOriginalMoment);
      this.originalDate = timeInOriginalMoment.toDate();
      // send changes to model
      this.onChangeCallback(this.originalDate);
      this.onDateChanged.emit(this.originalDate);
    }
    this.lastValue = newTimeInCurrentMoment;
  }

  private get $element(): JQuery {
    return $(this.elementRef.nativeElement);
  }
}
