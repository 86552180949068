import { BenefitDetailsBasic } from './benefit-details-basic';

enum BenefitDetailsCalcMethods {
  Flat = 'Flat',
  OptionsRates = 'OptionsRates',
  Formula = 'Formula',
  C401K = 'C401K',
  FormulaWithOption = 'FormulaWithOption'
}

export class BenefitDetailsCalcMethod extends BenefitDetailsBasic<string, string> {
  constructor(id: string, name: string, title: string) {
    super(id, name, title);
  }

  public get isFlat(): boolean {
    return this.id === BenefitDetailsCalcMethods.Flat;
  }
  public get isOptionsRates(): boolean {
    return this.id === BenefitDetailsCalcMethods.OptionsRates;
  }
  public get isFormula(): boolean {
    return this.id === BenefitDetailsCalcMethods.Formula;
  }
  public get is401K(): boolean {
    return this.id === BenefitDetailsCalcMethods.C401K;
  }
  public get isFormulaWithOption(): boolean {
    return this.id === BenefitDetailsCalcMethods.FormulaWithOption;
  }
}
