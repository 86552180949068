import { appConfig } from './../../app.config';
import { Pipe, Injectable, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'slxHideMaxDate',
  pure: true
})
@Injectable()
export class SlxHideMaxDatePipe implements PipeTransform {

  public transform(value: string): string {
    const year: number = moment(appConfig.maxCorrectDate).year();
    if (!value || value.indexOf(`/${year}`) > 0) {
      return '--/--/----';
    }
    return value;
  }

}
