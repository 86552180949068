import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../../app.config';
import { EmployeeWarningSections } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EmployeeSectionsWarningsActionsComponent = /** @class */ (function () {
    function EmployeeSectionsWarningsActionsComponent(manService, changeDetector) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.actionsList = [];
        this.min = 0;
        this.max = 99999999;
        this.step = 1;
        this.format = 'n0';
        this.subscriptions = {};
        this.appConfig = appConfig;
    }
    EmployeeSectionsWarningsActionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.actions = this.manService.getActionsSection();
        this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(function (warning) {
            _this.assignActions(warning);
            _this.assignActionsData();
            _this.updateView();
        });
        this.subscriptions.statusChanges = this.form.statusChanges.subscribe(function () {
            _this.manService.changeSectionValidity(EmployeeWarningSections.StandardActions, _this.form.valid);
        });
    };
    EmployeeSectionsWarningsActionsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeAction = function (act) {
        var action = _.find(this.actions.data.actions, { id: act.id });
        if (_.isObjectLike(action)) {
            action.selected = act.selected;
            this.manService.markWarningAsEdited(true);
        }
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeNote = function (act) {
        var action = _.find(this.actions.data.actions, { id: act.id });
        if (_.isObjectLike(action)) {
            action.notes = act.notes;
            this.manService.markWarningAsEdited(true);
        }
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeApprovedBy = function () {
        this.actions.data.approvedBy = this.approvedBy;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeNumberOfDays = function () {
        this.actions.data.numberOfDays = this.numberOfDays;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeComment = function () {
        this.actions.data.commentForNumberOfDays = this.commentForNumberOfDays;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeActionDate = function () {
        this.actions.data.actionDate = this.actionDate;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeApproveDate = function () {
        this.actions.data.approveDate = this.approveDate;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeActionTitle = function () {
        this.actions.data.actionTitle = this.actionTitle;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeEmployeeSignature = function () {
        this.actions.data.employeeSignature = this.employeeSignature;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeEmployeeTitle = function () {
        this.actions.data.employeeTitle = this.employeeTitle;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeCreatorSignature = function () {
        this.actions.data.creatorSignature = this.creatorSignature;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeWitnessSignature = function () {
        this.actions.data.witnessSignature = this.witnessSignature;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeSupervisorSignature = function () {
        this.actions.data.supervisorSignature = this.supervisorSignature;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeEmployeeSignDate = function () {
        this.actions.data.employeeSignDate = this.employeeSignDate;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeWitnessSignDate = function () {
        this.actions.data.witnessSignDate = this.witnessSignDate;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeIssuerSignDate = function () {
        this.actions.data.issuerSignDate = this.issuerSignDate;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.onChangeSupervisorSignDate = function () {
        this.actions.data.supervisorSignDate = this.supervisorSignDate;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsActionsComponent.prototype.updateView = function () {
        var _this = this;
        Observable.of(true)
            .delay(200)
            .subscribe(function () { return _this.changeDetector.detectChanges(); });
    };
    EmployeeSectionsWarningsActionsComponent.prototype.assignActions = function (warning) {
        var actions = _.find(warning.sections, function (s) { return s.isStandardActions; });
        if (_.isObjectLike(actions) && _.isObjectLike(actions.data)) {
            this.actions = actions;
        }
    };
    EmployeeSectionsWarningsActionsComponent.prototype.assignActionsData = function () {
        this.actionsList = _.cloneDeep(this.actions.data.actions);
        this.numberOfDays = this.actions.data.numberOfDays;
        this.commentForNumberOfDays = this.actions.data.commentForNumberOfDays;
        this.actionDate = this.actions.data.actionDate;
        this.approveDate = this.actions.data.approveDate;
        this.actionTitle = this.actions.data.actionTitle;
        this.approvedBy = this.actions.data.approvedBy;
        this.employeeSignature = this.actions.data.employeeSignature;
        this.employeeTitle = this.actions.data.employeeTitle;
        this.creatorSignature = this.actions.data.creatorSignature;
        this.witnessSignature = this.actions.data.witnessSignature;
        this.supervisorSignature = this.actions.data.supervisorSignature;
        this.employeeSignDate = this.actions.data.employeeSignDate;
        this.witnessSignDate = this.actions.data.witnessSignDate;
        this.issuerSignDate = this.actions.data.issuerSignDate;
        this.supervisorSignDate = this.actions.data.supervisorSignDate;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsWarningsActionsComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsWarningsActionsComponent;
}());
export { EmployeeSectionsWarningsActionsComponent };
