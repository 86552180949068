import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { ResponseBody, Meta } from '../../../core/models/index';
import { punchProfileConfig } from '../punch-profile.config';

import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import {
  IPunchAttestationPeriod
} from '../models/index';
import { punchAttestationConfig } from '../../punch-attestation/punch-attestation.config';
import { IPunchProfileGroup } from '../models/punch-profile-group';

@Injectable()
export class PunchProfileApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getAllGroups(): Promise<IPunchAttestationPeriod[]> {
    const url: string = `${this.getPunchProfileApiRoot()}`;
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<IPunchAttestationPeriod[]> = this.apiUtilService.request<IPunchAttestationPeriod[], Meta>(req)
      .then((response: ResponseBody<IPunchAttestationPeriod[], Meta>) => {
        return response.data;
      });
    return promise;
  }

  public getAllPunchProfiles(): Promise<IPunchProfileGroup[]> {
    const url: string = `${this.getApiRoot()}`;
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<IPunchProfileGroup[]> = this.apiUtilService.request<IPunchProfileGroup[], Meta>(req)
      .then((response: ResponseBody<IPunchProfileGroup[], Meta>) => {
        return response.data;
      });
    return promise;
  }

  public addOrUpdatePunchProfile(orgLevelId: number,punchProfile : IPunchProfileGroup): Promise<IPunchProfileGroup[]> {
    const url: string = `${this.getApiRoot()}/${punchProfileConfig.api.punchProfile.add}/${orgLevelId}`;

    let req = this.urlParamsService.createPostRequest(url, punchProfile);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(req)
      .then((response: ResponseBody<IPunchProfileGroup[], Meta>) => {
        return response.data;
      });
    return promise;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${punchProfileConfig.api.root}/${punchProfileConfig.api.punchProfile.root}`;
  }

  private getPunchProfileApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${punchAttestationConfig.api.root}/${punchAttestationConfig.api.punchAttestation.root}`;
  }
}
