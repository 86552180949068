import { Component, ElementRef } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-popup',
  templateUrl: 'popup.component.html',
  styleUrls: ['popup.component.scss']
})
export class PopupComponent {
  public state: {
    isFocused: boolean;
  };

  public elementRef: ElementRef;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;

    this.state = {
      isFocused: false
    };
  }

  public onFocus(event: Event): void {
    this.state.isFocused = true;
  }

  public onBlur(event: Event): void {
    this.state.isFocused = false;
  }
}
