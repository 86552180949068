import { IEmployeeDefinition, EmployeeDefinition, IDepartment, Department, IOrganization, IPosition, Position, Organization } from '../../../organization/models/index';
import { FieldsMeta, IFieldData } from '../../../core/models';

export interface IWcEmployeeInfoDto {
    id: number;
    employee: IEmployeeDefinition;
    department: IDepartment;
    organization: IOrganization;
    position: IPosition;
    firstName: string;
    lastName: string;
    middleName: string;
    empType: string;
    dateHired: string;
    gender: string;
    race: string;
    maritalStatus: string;
    dependens: number;
    dateOfBirth: string;
    age: number;
    zip: string;
    state: string;
    city: string;
    address: string;
    ssn: string;
}

export class WcEmployeeInfo {
    public id: number;
    public employee: EmployeeDefinition;
    public department: Department;
    public organization: Organization;
    public position: Position;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public empType: string;
    public dateHired: Date | string;
    public gender: string;
    public race: string;
    public maritalStatus: string;
    public dependents: number;
    public dateOfBirth: Date | string;
    public age: number;
    public zip: string;
    public state: string;
    public city: string;
    public address: string;
    public ssn: string;

    public metaMap: StringMap<IFieldData>;
}
