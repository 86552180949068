<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <slx-punch-attestation-header
      [orgLevel]="orgLevel"
      class="slx-content-toolbar-indents"
    ></slx-punch-attestation-header>

    <div class="slx-high-box__body slx-main-content-indents punch-attestation-content"  #groups (scroll)="onScroll($event)">
      <div class="slx-punch-attestation-sections" *ngFor="let group of groups$ | async">
        <slx-punch-attestation-section
          [group]="group"
          [orgLevel]="orgLevel"
          [attestationTypeLookup]="attestationTypeLookup"
          [restrictedAnswerLookup]="restrictedAnswerLookup"
          [punchAttestationAnswerCodes]="punchAttestationAnswerCodes"
          [punchAttestationPunchType]="punchAttestationPunchType"
        ></slx-punch-attestation-section>
      </div>
    </div>
  </div>
</slx-spinner>
