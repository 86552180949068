import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeSectionsCustomFields } from '../../../models/index';
import { EmployeeSectionsCustomApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { dateTimeUtils } from './../../../../../common/utils';
var EmployeeSectionsCustomFieldsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsCustomFieldsComponent, _super);
    function EmployeeSectionsCustomFieldsComponent(employeeSectionsCustomApiService, decorator, ngZone, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeSectionsCustomApiService = employeeSectionsCustomApiService;
        _this.changeDetector = changeDetector;
        _this.bitValues = [{ id: 'True', name: 'True' }, { id: 'False', name: 'False' }];
        _this.charValues = [{ id: 'Y', name: 'Y' }, { id: 'N', name: 'N' }];
        _this.pattern = '^/\d+$';
        return _this;
    }
    Object.defineProperty(EmployeeSectionsCustomFieldsComponent.prototype, "physicals", {
        set: function (employeeSectionsCustomFields) {
            this.employeeSectionsCustomFields = employeeSectionsCustomFields;
            if (this.employeeSectionsCustomFields !== null) {
                this.employeeSectionsCustomFields.fields.forEach(function (element) {
                    if (element.dataType.toLowerCase() === 'bit' || element.dataType.toLowerCase() === 'char') {
                        element.fieldData.fieldValue = { id: element.fieldData.fieldValue, name: element.fieldData.fieldValue };
                    }
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsCustomFieldsComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsCustomFieldsComponent.prototype, "specialConsideration", {
        get: function () {
            return this.employeeSectionsCustomFields ? this.employeeSectionsCustomFields.specialConsideration : null;
        },
        set: function (value) {
            if (this.employeeSectionsCustomFields)
                this.employeeSectionsCustomFields.specialConsideration = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsCustomFieldsComponent.prototype, "specialConsideration2", {
        get: function () {
            return this.employeeSectionsCustomFields ? this.employeeSectionsCustomFields.specialConsideration2 : null;
        },
        set: function (value) {
            if (this.employeeSectionsCustomFields)
                this.employeeSectionsCustomFields.specialConsideration2 = value;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsCustomFieldsComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsCustomFields;
    };
    EmployeeSectionsCustomFieldsComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsCustomApiService.getCustomFields(this.employeeId)
            .then(function (employeeSectionsCustomFields) {
            _this.employeeSectionsCustomFields = employeeSectionsCustomFields;
            _this.employeeSectionsCustomFields.fields.forEach(function (element) {
                if (element.dataType.toLowerCase() === 'bit' || element.dataType.toLowerCase() === 'char') {
                    element.fieldData.fieldValue = { id: element.fieldData.fieldValue, name: element.fieldData.fieldValue };
                }
                if (element.displayName == "AccrualsEligibilityDate") {
                    if (!dateTimeUtils.validateDate(element.fieldData.fieldValue)) {
                        _this.isInvalidData = true;
                        _this.disableSaveButton();
                    }
                }
            });
            _this.stopProgress();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        }).catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsCustomFieldsComponent.prototype.doSave = function (effectiveDate) {
        var _this = this;
        this.employeeSectionsCustomApiService.setCustomFields(this.employeeId, this.employeeSectionsCustomFields, effectiveDate)
            .then(function () {
            _this.onActionComplete(true);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsCustomFieldsComponent.prototype.checkTemporalDirty = function () {
        if (!this.employeeSectionsCustomFields)
            return null;
        var temporalModel = this.metaFieldsTemporalDirtyArrayChecker(this.employeeSectionsCustomFields.fields);
        return null;
    };
    EmployeeSectionsCustomFieldsComponent.prototype.checkIntLength = function (field) {
        if (field.fieldData.fieldValue !== null) {
            if (parseInt(field.fieldData.fieldValue)) {
                if (parseInt(field.fieldData.fieldValue) > 2147483647) {
                    this.disableSaveButton();
                    return true;
                }
                else {
                    this.disableSaveButton();
                    return false;
                }
            }
            else {
                this.disableSaveButton();
                return false;
            }
        }
        this.disableSaveButton();
    };
    EmployeeSectionsCustomFieldsComponent.prototype.checkDecimalLength = function (field) {
        if (field.fieldData.fieldValue !== null) {
            if (parseFloat(field.fieldData.fieldValue) > 999999.00) {
                this.disableSaveButton();
                return true;
            }
            else if (field.fieldData.fieldValue.toString().includes('.')) {
                var decimalLength = field.fieldData.fieldValue.toString().split('.')[1];
                if (decimalLength.toString().length > parseInt(field.dataTypePrecision)) {
                    this.disableSaveButton();
                    return true;
                }
                else {
                    this.disableSaveButton();
                    return false;
                }
            }
        }
    };
    EmployeeSectionsCustomFieldsComponent.prototype.onChangeDate = function (e) {
        if (dateTimeUtils.validateDate(e)) {
            return this.isInvalidData = false;
        }
        else {
            return this.isInvalidData = true;
        }
    };
    EmployeeSectionsCustomFieldsComponent.prototype.disableSaveButton = function () {
        setTimeout(function () {
            var htmlElement = document.getElementById('CustomFields');
            var btnElement = htmlElement.getElementsByClassName('subection-save');
            var intErrorMsg = document.getElementById('integerErrorMsg');
            var decimalErrorMsg = document.getElementById('decimalErrorMsg');
            if (btnElement.length > 0) {
                if (intErrorMsg !== null || decimalErrorMsg !== null) {
                    btnElement[0]['disabled'] = true;
                }
                else {
                    btnElement[0]['disabled'] = false;
                }
            }
        }, 100);
    };
    EmployeeSectionsCustomFieldsComponent.prototype.removeFieldValue = function (field) {
        return field.fieldData.fieldValue ? field.fieldData.fieldValue = null : field.fieldData.fieldValue;
    };
    return EmployeeSectionsCustomFieldsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsCustomFieldsComponent };
