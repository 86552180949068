import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsCertification = /** @class */ (function () {
    function EmployeeSectionsCertification() {
    }
    return EmployeeSectionsCertification;
}());
export { EmployeeSectionsCertification };
var EmployeeSectionsCertifications = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsCertifications, _super);
    function EmployeeSectionsCertifications() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsCertifications;
}(EmployeeSectionsBase));
export { EmployeeSectionsCertifications };
