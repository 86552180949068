import * as moment from 'moment';
import * as _ from 'lodash';
import { appConfig } from '../../../app.config';
import { SlateMessageGroupInfo } from './slate-message-group-info';
export interface IMessageList {
  alias: string;
  archived: boolean;
  body: string;
  employeeId: number;
  employeeName: string;
  id: string;
  modified: string;
  read: boolean;
  replyCount: number;
  created?: string;
  mobilePhoneNumber: string;
  optIn: number;
  orgLevelId: number;
  photo: string;
  status: string;
  isOnLeave: boolean;
  fullName: string;
  employeeStatus:string;
}

export class MessageList {
  public alias: string;
  public archived: boolean;
  public body: string;
  public employeeId: number;
  public employeeName: string;
  public id: string;
  public modified: string;
  public read: boolean;
  public replyCount: number;
  public mobilePhoneNumber: string;
  public optIn: number;
  public orgLevelId: number;
  public photo: string;
  public status: string;
  public isOnLeave: boolean;
  public fullName: string;
  public employeeStatus:string;
}


export interface IEmployeeProfilePicture {
  employeeId: number;
  fullName: string;
  isOnLeave: boolean;
  mobilePhoneNumber: string;
  optIn: number;
  orgLevelId: number;
  photo: string;
  status: string;
}

export class EmployeeProfilePicture {
  public employeeId: number;
  public fullName: string;
  public isOnLeave: boolean;
  public mobilePhoneNumber: string;
  public optIn: number;
  public orgLevelId: number;
  public photo: string;
  public status: string;
}

export interface IUpdateRequest {
  employees: any[];
  excludeEmployees: number[];
  all: boolean;
  allRead: boolean;
  allUnRead: boolean;
  isArchived: boolean;
  orgLevelId: number;
  includeCold: boolean
}

export interface IUnreadMessageCount {
  total: number;
  unRead: number;
  cold: number;
  hot: number;
}

export class UnreadMessageCount {
  total: number;
  unRead: number;
  cold: number;
  hot: number;
}

export interface IUserPicture {
  userId: number;
  profilePicture: string;
  employeeId: number;
}

export class UserPicture {
  userId: number;
  profilePicture: string;
  employeeId: number;
}

export interface IMessagePayload {
  currentPageno: number;
  IsArchive: boolean;
  orgLevelId: number;
  dateOrderByAscending: boolean;
  nameOrderByAscending: boolean;
  includeCold: boolean;
}

export interface ILatestScheduleCycle {
  startDate: string;
  endDate: string;
}

export class LatestScheduleCycle {
  startDate: string;
  endDate: string;
}

export interface IColdStorageFlag {
  hasMessages: boolean;
}

export class ColdStorageFlag {
  hasMessages: boolean;
}


export class sectionType
{
  public static lessThanOneMonth: string = 'lessthanmonth';
  public static olderThanOneMonth: string = 'olderthanonemonth';
  public static olderThanThreeMonth: string = 'olderthanthreemonth';
  public static olderThanSixMonth: String = 'loadolderThan6Month'
}
export class SectionState
{
  public sectionName: sectionType 
  public startDate: Date ;
  public endDate: Date ; 
  public includeCold : boolean ;
  public currentPageNumber:number;
  public totalCount:number;
  public loadCount:number;  
  public isCollapsed: boolean;
  public isloaded: boolean
  public messages:MessageList[];
}

export interface ISectionDetail
{
  sectionName: sectionType 
  startDate:Date,
  endDate:Date,
  includeCold : boolean, 
  orderByNameAsc:boolean;
}

export class SectionDetail
{
  public sectionName: sectionType 
  public get startDate(): Date {
      if(sectionType.lessThanOneMonth==this.sectionName)
      {
        if(_.isNull(this.orderByNameAsc))
        {
          return moment().subtract(1, 'month').toDate();
        }
        else
        {
          return moment(appConfig.minCorrectDate).toDate();
        }
          
      }
      else if(sectionType.olderThanOneMonth==this.sectionName)
      {
          return moment().subtract(3, 'month').toDate();
      }
      else if(sectionType.olderThanThreeMonth==this.sectionName)
      {
          return moment().subtract(6, 'month').toDate();
      }
      else if(sectionType.olderThanSixMonth==this.sectionName)
      {
          return moment(appConfig.minCorrectDate).toDate();
      }        
  };
  public get endDate(): Date {
      if(sectionType.lessThanOneMonth==this.sectionName)
      {   
          return moment().add(1, 'day').toDate();          
      }
      else if(sectionType.olderThanOneMonth==this.sectionName)
      {
          return moment().subtract(1, 'month').toDate();
      }
      else if(sectionType.olderThanThreeMonth==this.sectionName)
      {
          return moment().subtract(3, 'month').toDate();
      }
      else if(sectionType.olderThanSixMonth==this.sectionName)
      {
        return moment().subtract(6, 'month').toDate();
      }        
  }
  public get includeCold() : boolean {
     return  this.sectionName == sectionType.olderThanSixMonth ? true : false;
  }  
  public orderByNameAsc:boolean;
}

export interface IMessageCountRequest {   
  isArchived: boolean,
  searchText: string,
  orgLevelId: number,
  isMyMessage: boolean,
  sections:ISectionDetail[]
}
export interface IMessageDataAndCountRequest{
  messageCount:IMessageCountRequest,
  messageData:ISectionMessagesRequest
}
export class MessageCountRequest {   
  public isArchived: boolean;
  public searchText: string;
  public orgLevelId: number;
  public isMyMessage: boolean;
  public sections:SectionDetail[];
}

export interface IMessageCountResponse {   
  sectionName: sectionType;
  messageCount: number;
}
export class MessageCountResponse {   
  public sectionName:sectionType;
  public messageCount:number;
}

export interface ITabMessages {
  sectionName: string;
  messages: IMessageList[]; 
}

export class TabMessages {
  public sectionName: string;
  public isReset:boolean;
  public messages: MessageList[]; 
}

export interface ISectionMessagesRequest{
  page: number,
  pageSize: number,
  employees: any[],
  isArchived: boolean,
  searchText: string,
  orgLevelId: number,
  dateOrderByAscending: boolean,
  nameOrderByAscending: boolean,
  includeCold: boolean,
  startDate:Date,
  endDate: Date,
  sectionName: string,
  isReset: boolean,
  isMyMessage: boolean

}

export class SlateSection {
  sectionType: string;
  isCollapsed: boolean;
  sectionTypeText: string;
  messages: SlateMessageGroupInfo[];
}
