<div class="slx-user-menu">
  <div class="user-menu">
    <slx-actions-list>
      <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'" [fullyCustomStyle]="true" className="user-button">
        <span class="user-name">{{ userName }}</span>
        <span class="user-arrow">
          <i class="far fa-angle-down" aria-hidden="true"></i>
        </span>
      </slx-actions-button>
      <popper-content #popperContent>
        <slx-actions-list-item (onClick)="logOut()" className="user-actions-item no-hover">
          <i class="far fa-power-off" aria-hidden="true"></i>
          <span>Log Out</span>
        </slx-actions-list-item>
        <slx-actions-list-item (onClick)="resetPassword()" className="user-actions-item no-hover">
          <i class="far fa-key" aria-hidden="true"></i>
          <span>Change Password</span>
        </slx-actions-list-item>
      </popper-content>
    </slx-actions-list>
  </div>
  <div class="user-avatar"></div>
</div>