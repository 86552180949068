import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPartnerClientStatusData } from '../../../../app/common/models/integrations/partner-client-status.model';
import { CacheType } from '../../../../app/common/models/cache/cache-definition';
import { IPartnerData } from '../../../../app/common/models/integrations/partner-data.model';
import { ApiUtilService, CacheUtilService, UrlParamsService } from '../../../common/services/index';
import { FieldsMeta, Meta, ResponseBody } from '../../../core/models/index';
import { IPartnerPosition, IPartnerPositionDefinition, PartnerPosition } from '../../models/index';
import { RolesMapService } from '../roles/roles-map.service';
import { PartnerConfigMapService } from './partner-config-map.service';

@Injectable()
export class PartnerConfigApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamService: UrlParamsService,
    private cacheUtilService: CacheUtilService,
    private partnerMapService: PartnerConfigMapService
  ) {}

  public getPartnerPositions(customerId: string, partnerId: string): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`lookup/customer/${customerId}/partner/${partnerId}/positions`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<any> = this.apiUtilService
      .request<IPartnerPosition[], Meta>(request)
      .then((response: ResponseBody<IPartnerPosition[], Meta>) =>
        this.partnerMapService.mapToPartnerPositions(response.data)
      );
    return promise;
  }

  public getPartnerPositionsDefinitions(partnerId: string): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`lookup/customer/partner/${partnerId}/positions`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<any> = this.apiUtilService
      .request<IPartnerPositionDefinition[], Meta>(request)
      .then((response: ResponseBody<IPartnerPositionDefinition[], Meta>) =>
        this.partnerMapService.mapToPartnerPostionsDefinitions(response.data)
      );
    return promise;
  }

  public addPartnerPosition(
    customerId: string,
    partnerId: string,
    partnerPositionsList: PartnerPosition[]
  ): Promise<PartnerPosition> {
    const url: string = this.apiUtilService
      .apiRoot`configuration/partner/${partnerId}/customer/${customerId}/positions`;
    let request: HttpRequest<any[]> = this.urlParamService.createPostRequest(url, partnerPositionsList);
    // clear cache
    this.cacheUtilService.delete({ key: url }, '');
    let promise: Promise<any> = this.apiUtilService
      .request<IPartnerPosition, FieldsMeta>(request)
      .then((response: ResponseBody<IPartnerPosition, FieldsMeta>) => {
        return response.data;
      });
    return promise;
  }

  public DeletePartnerPositionMapping(customerId: string, partnerId: string, mapId: number): Promise<any> {
    const url: string = this.apiUtilService
      .apiRoot`configuration/partner/${partnerId}/customer/${customerId}/positions`;
    const request = this.urlParamService.requestDelete(url + `/${mapId}`);
    // clear GET cache
    this.cacheUtilService.delete({ key: url }, '');
    return this.apiUtilService.requestNew<any, Meta>(request);
  }

  public getPartnerDefinitions(subscriptionId: number): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`schedule/partner/subscriptions/${subscriptionId}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<any> = this.apiUtilService
      .cachedRequest<IPartnerData[], Meta>(request, CacheType.longTerm)
      .then((response: ResponseBody<IPartnerData[], Meta>) =>
        this.partnerMapService.mapToPartnerDefinitions(response.data)
      );
    return promise;
  }

  public getPartnerClientActivationStatus(): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`schedule/partner/wfm/status`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<any> = this.apiUtilService
      .request<IPartnerClientStatusData, Meta>(request)
      .then((response: ResponseBody<IPartnerClientStatusData, Meta>) =>
        this.partnerMapService.mapToPartnerClientActivationStatus(response.data)
      );
    return promise;
  }
}
