import { Component, Inject, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-toolbar-section',
  templateUrl: 'toolbar-section.component.html',
  styleUrls: ['toolbar-section.component.scss']
})
export class ToolbarSectionComponent {
  @Input()
  public showDivider: boolean = true;
  @Input()
  public className: string = '';
}
