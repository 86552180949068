import { Component, OnInit } from '@angular/core';
import { DialogOptions2, IDialog, ModalService } from '../../../../../common';
import { ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { AcaExportCorrectionType } from '../../../models/aca-export/aca-export-correctiontype';
import { AcaExportCorrectionParameter } from '../../../models/aca-export/aca-export-correction-parameter';
import { AcaExportExecutionItem } from '../../../models/aca-export/aca-export-execution';
import * as _ from 'lodash';

@Component({
  selector: 'slx-aca-export-corrected-1094c-parameters',
  templateUrl: './aca-export-corrected-1094c-parameters.component.html',
  styleUrls: ['./aca-export-corrected-1094c-parameters.component.scss']
})

export class AcaExportCorrected1094cParametersComponent implements IDialog, OnInit {

  @ViewChild('correctionForm', { static: true })
  private ngFormChild: NgForm;
  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }
  public manService: AcaExportManagementService;
  private modalService: ModalService;
  private options: DialogOptions2;
  private m_initialized: boolean;

  public acaCorrectionType: AcaExportCorrectionType;
  public acaExportExecutionItem: AcaExportExecutionItem;
  public acaExportCorrectionParameter: AcaExportCorrectionParameter;

  constructor(
    modalService: ModalService,
    options: DialogOptions2,
    manService: AcaExportManagementService
  ) {
    this.options = options;
    this.modalService = modalService;
    this.manService = manService;
  }

  public dialogResult: boolean = false;
  public isLoading: boolean = true;

  public ngOnInit(): void {
    this.isLoading = false;
    this.m_initialized = true;
  }

  public get initialized(): boolean {
    return this.m_initialized;
  }

  public get valid(): boolean {
    let formValid: boolean = this.form.invalid ? false : true;
    return formValid;
  }

  public onBack(): void {
    this.onCancel();
    let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
    acaExportDialogOptions.dialogType = 1;
    acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
    acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
    acaExportDialogOptions.acaExportCorrectionParameter = this.acaExportCorrectionParameter;
    this.manService.openAcaExportDialog(acaExportDialogOptions);
    
  }

  public onCancel(): void {
    this.dialogResult = false
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onNext(): void {
    this.onCancel();
    let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
    acaExportDialogOptions.dialogType = 6;
    acaExportDialogOptions.acaExportCorrectionParameter = this.acaExportCorrectionParameter;
    acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
    acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;

    this.manService.openAcaExportDialog(acaExportDialogOptions);
  }

  public onChangeQualifiedOffer(data: AcaExportCorrectionParameter, isChecked: boolean): void {
    data.qualifiedOfferMethod = isChecked;
  }

  public onChange98PercentageOffer(data: AcaExportCorrectionParameter, isChecked: boolean): void {
    data.offerMethod98 = isChecked;
  }

  public onChangeOfferMecAll12Month(data: AcaExportCorrectionParameter, isChecked: boolean): void {
    data.offeredMecAll12Month = isChecked;
    if(isChecked)
    {
      _.forEach(data.offeredMec, (item)=> {
        item.isSelected=false;
      });
    }
  }

  public onChangeAleGroupAll12Month(data: AcaExportCorrectionParameter, isChecked: boolean): void {
    data.memberOfAleGroupAll12Month = isChecked;
    if(isChecked)
    {
      _.forEach(data.memberOfAleGroup, (item)=> {
        item.isSelected=false;
      });
    }
  }

  public onChangeOfferedMec(item: any, isChecked: boolean): void {
    item.isSelected = isChecked;
  }

  public onChangeAleGroup(item: any, isChecked: boolean): void {
    item.isSelected = isChecked;
  }

}
