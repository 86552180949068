import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable()
export class RestoreQueryParamsService {
  public restoreParams(queryParams: Params = {}, paramsToRestore: Params = {}): void {
    this.restoreHrmsBenefitsParams(queryParams, paramsToRestore);
  }

  private restoreHrmsBenefitsParams(queryParams: Params, paramsToRestore: Params): void {
    const groupId = parseInt(queryParams.groupId);
    const providerId = parseInt(queryParams.providerId);
    const providerLineId = parseInt(queryParams.providerLineId);
    if (_.isFinite(groupId)) {
      paramsToRestore.groupId = groupId;
    }
    if (_.isFinite(providerId)) {
      paramsToRestore.providerId = providerId;
    }
    if (_.isFinite(providerLineId)) {
      paramsToRestore.providerLineId = providerLineId;
    }
  }
}
