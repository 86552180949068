import {  EmployeePayrollEffectiveDateSettings } from '../models/index';

export class EmployeeSectionsTemporalModel {
  public isTemporalDirty: boolean;
  public isOnlyPayRateDirty: boolean = false;
  public isNotOnlyPayRateDirty: boolean = false;
  public minEffectiveData: Date;
  public payrollEffectiveDateSetting: EmployeePayrollEffectiveDateSettings;
  public showPopup: boolean;
}
