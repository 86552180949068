import { EmployeeSectionsBase } from '../employee-sections-base';
import { Actions, FieldsMeta, FieldData } from '../../../../core/models/index';
import { BudgetedPosition } from '../../../../organization/models/lookup/budgeted-position';
import { LicenseType } from '../../../../organization';

export interface IEmployeeSectionsLicenses {
  licenses: IEmployeeSectionsLicense[];
}
export interface IEmployeeSectionsLicense {
  licenseNumber: string;
  expirationDate: any;
  budgetedPosition: BudgetedPosition;
  licenseType: LicenseType;
  seq: number;
  issueDate: any;
}

export class EmployeeSectionsLicense {
  public licenseNumber: FieldData<string>;
  public expirationDate: FieldData<any>;
  public budgetedPosition: FieldData<BudgetedPosition>;
  public licenseType: FieldData<LicenseType>;
  public seq: number;
  public indexSeq: number;
  public removed: boolean;
  public hasDuplicateAtSeq: number;
  public issueDate: FieldData<any>;
}

export class EmployeeSectionsLicenses extends EmployeeSectionsBase {
  public employeeSectionsLicenses: EmployeeSectionsLicense[];
  public licenseNumberMetaData: FieldData<string>;
  public expirationDateMetaData: FieldData<any>;
  public budgetedPositionMetaData: FieldData<BudgetedPosition>;
  public licenseTypeMetaData: FieldData<LicenseType>;
  public fieldsMeta: FieldsMeta;
  public issueDateMetaData: FieldData<any>;

  constructor() {
    super();
    this.employeeSectionsLicenses = [];
  }


}

