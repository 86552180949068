import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy, } from "@angular/core";
import * as _ from 'lodash';
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { mutableSelect } from "../../../core/decorators/redux-decorators";
import { unsubscribe } from "../../../core/decorators/unsubscribe-decorator";
import { NotificationType } from "../../models/notification-type.enum";
import { PopperContent } from "ngx-popper";
import { Router } from "@angular/router";
import { MessageNotificationService } from "../../services/notifications/msg-notifications.service";
import { NotificationsApiService } from '../../services';
import { messageCenterConstants } from '../../constants/message-center.constant';
import { ModalService } from '../../../../app/common';
import { SmsComposeNewDialogComponent } from '../../../../app/app-modules/message-center/components';
import { MessagesManagementService } from '../../../app-modules/message-center/services/messages-management.service';
import { unsubscribeAll } from '../../../core/decorators/index';
import { MessageCenterConfigService } from '../../../../../src/app/organization/services/index';
var MessageCenterNotificationComponent = /** @class */ (function () {
    function MessageCenterNotificationComponent(router, msgNotificationService, api, modalService, messagesManagementService, messageCenterConfigService) {
        this.msgNotificationService = msgNotificationService;
        this.api = api;
        this.modalService = modalService;
        this.messagesManagementService = messagesManagementService;
        this.messageCenterConfigService = messageCenterConfigService;
        this.activeTab = 0;
        this.subscriptions = {};
        this.popperPosition = "bottom";
        this.popperOnShow = new EventEmitter();
        this.popperOnHide = new EventEmitter();
        this.notificationsCount = 0;
        this.popupClassProperties = {};
        this.popperModifiers = {
            arrow: {
                order: 500,
                enabled: true,
                element: "[x-arrow]",
            },
        };
        this.tooltipText = messageCenterConstants;
        this.escCode = 27;
        this.orgLevelID = null;
        this.router = router;
        this.notificationTypes = NotificationType;
    }
    MessageCenterNotificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMyMessage = this.messagesManagementService.restoreFilterState();
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.orgLevelID = orgLevel.id;
                _this.getMessageCenterPermissions();
            }
        });
        this.subscriptions.checkIconAccess = this.msgNotificationService.subscribeToIconAccess(function (access) {
            _this.setIconAccess(access);
        });
        this.subscriptions.messageFilter = this.messagesManagementService.subscribeToswitchMessageFilter(function (res) {
            _this.isMyMessage = res;
            _this.getUnreadMessageCount();
        });
        this.subscriptions.updateStatus = this.messagesManagementService.subscribeToUpdateReadStatus(function (v) {
            _this.getUnreadMessageCount();
        });
        this.subscriptions.alert = this.api.subcribeToNewmessageAlert(function (v) {
            _this.getUnreadMessageCount();
        }, true);
        this.subscriptions.count = this.messagesManagementService.subscribecallUnReadCountApi(function () {
            _this.getUnreadMessageCount();
        });
        this.subscriptions.unreadSmsCount = this.msgNotificationService.subscribeToUnreadSmsCount((function (count) {
            _this.notificationsCount = count.unRead;
            _this.messageCount = count;
            _this.messagesManagementService.updateMessageCount(count);
        }));
    };
    MessageCenterNotificationComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    MessageCenterNotificationComponent.prototype.getMessageCenterPermissions = function () {
        var _this = this;
        this.messagesManagementService.getComposeNewTabsPermissions(this.orgLevelID).then(function (cNewTabs) {
            _this.canSendSMS = cNewTabs.canSendSMS;
            _this.canSendSlate = cNewTabs.canSendSlate;
            _this.canViewSlateTab = cNewTabs.canViewSlateTab;
            _this.canViewSMSTab = cNewTabs.canViewSmsTab;
            _this.checkIconAcess();
        });
    };
    MessageCenterNotificationComponent.prototype.checkIconAcess = function () {
        this.msgNotificationService.checkIconAcess(this.orgLevel.id);
    };
    MessageCenterNotificationComponent.prototype.onKeyup = function (event) {
        if (event.keyCode === this.escCode) {
            this.popperContent.hide();
        }
    };
    MessageCenterNotificationComponent.prototype.onShown = function (popper) {
        this.popupShown = true;
        this.popperOnShow.emit(popper);
        this.popper = popper;
    };
    MessageCenterNotificationComponent.prototype.onHidden = function (popper) {
        this.popupShown = false;
        this.popperOnHide.emit(popper);
    };
    MessageCenterNotificationComponent.prototype.onClickTab = function (tab) {
        if (this.activeTab === tab)
            return;
        this.activeTab = tab;
        if (tab === 0) {
        }
        else if (tab === 1) {
        }
        this.isActiveMenu(this.activeTab);
    };
    MessageCenterNotificationComponent.prototype.isActiveMenu = function (tabOrder) {
        return this.activeTab === tabOrder ? true : false;
    };
    MessageCenterNotificationComponent.prototype.setIconAccess = function (access) {
        if (access.isUserAllowed) {
            if (access.isMessageCenterEnabled && (this.canViewSMSTab || this.canViewSlateTab)) {
                this.isEnableIcon = true;
                this.isMessageIcon = true;
            }
            else {
                access.isAdminUser
                    ? (this.isMessageIcon = false, this.isEnableIcon = true)
                    : (this.isEnableIcon = false);
            }
        }
        else {
            access.isAdminUser
                ? (this.isMessageIcon = false, this.isEnableIcon = true)
                : (this.isEnableIcon = false);
        }
        this.isMessageCenterEnabled = access.isMessageCenterEnabled;
        this.getUnreadMessageCount();
    };
    MessageCenterNotificationComponent.prototype.goToMessageCenter = function (event) {
        this.popupShown = false;
        if (event === 'composeNew') {
            SmsComposeNewDialogComponent.openDialog(this.modalService, function (result) { });
        }
        else if (event === true) {
            this.getUnreadMessageCount();
            this.router.navigate([this.messageCenterConfigService.GetMessageCenterUrl()]);
        }
    };
    MessageCenterNotificationComponent.prototype.showPopper = function () {
        this.popupShown = true;
        var val = this.notificationsCount;
        var count = 0;
        if (val >= 1)
            ++count;
        while (val / 10 >= 1) {
            val /= 10;
            ++count;
        }
        this.popupClassProperties['top'] = 28;
        switch (count) {
            case 0:
                this.popupClassProperties['right'] = 0;
                break;
            case 1:
                this.popupClassProperties['right'] = 17;
                break;
            case 2:
                this.popupClassProperties['right'] = 22;
                break;
            case 3:
                this.popupClassProperties['right'] = 28;
                break;
            case 4:
                this.popupClassProperties['right'] = 34;
                break;
            case 5:
                this.popupClassProperties['right'] = 40;
                break;
            case 6:
                this.popupClassProperties['right'] = 45;
                break;
        }
    };
    MessageCenterNotificationComponent.prototype.getUnreadMessageCount = function () {
        if (!this.isMessageCenterEnabled || !this.orgLevelID) {
            return;
        }
        this.msgNotificationService.getUnreadSmsCount(this.orgLevelID, this.isMyMessage);
    };
    MessageCenterNotificationComponent.prototype.buttonCss = function () {
        if (this.notificationsCount > 0) {
            return 'notification-on';
        }
        else {
            return '';
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], MessageCenterNotificationComponent.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect(["session"]),
        tslib_1.__metadata("design:type", Observable)
    ], MessageCenterNotificationComponent.prototype, "session$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MessageCenterNotificationComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], MessageCenterNotificationComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MessageCenterNotificationComponent.prototype, "orgLevelSubscription", void 0);
    return MessageCenterNotificationComponent;
}());
export { MessageCenterNotificationComponent };
