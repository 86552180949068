import { Router, ActivatedRoute, Params } from '@angular/router';
import { appConfig } from '../../../app.config';
import * as moment from 'moment';

export class PayrollExportNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToPayrollExport(startDate: Date, endDate: Date): void {
    let params: Params = {
      startDate: moment(startDate).format(appConfig.linkDateFormat),
      endDate: moment(endDate).format(appConfig.linkDateFormat)
    };
    this.router.navigate(['time', 'payroll_export'], { relativeTo: this.route.pathFromRoot[1], queryParams: params });
  }

}
