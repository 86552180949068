/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aca-measurement.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./aca-measurement-toolbar/aca-measurement-toolbar.component.ngfactory";
import * as i5 from "./aca-measurement-toolbar/aca-measurement-toolbar.component";
import * as i6 from "../../services/aca-measurement/aca-measurement-management.service";
import * as i7 from "../../../common/services/column-settings/column-management.service";
import * as i8 from "../../../common/services/state-management/state-management.service";
import * as i9 from "./aca-measurement-insights/aca-measurement-insights.component.ngfactory";
import * as i10 from "./aca-measurement-insights/aca-measurement-insights.component";
import * as i11 from "./aca-measurement-ag-grid/aca-measurement-ag-grid.component.ngfactory";
import * as i12 from "./aca-measurement-ag-grid/aca-measurement-ag-grid.component";
import * as i13 from "@angular/router";
import * as i14 from "../../../common/services/column-settings/column-settings-storage.service";
import * as i15 from "../../../common/services/component-state/component-state-storage.service";
import * as i16 from "../../services/aca-measurement/aca-measurement-api.service";
import * as i17 from "./aca-measurement.component";
var styles_AcaMeasurementComponent = [i0.styles];
var RenderType_AcaMeasurementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AcaMeasurementComponent, data: {} });
export { RenderType_AcaMeasurementComponent as RenderType_AcaMeasurementComponent };
export function View_AcaMeasurementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 9, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-aca-measurement-toolbar", [], null, null, null, i4.View_AcaMeasurementToolbarComponent_0, i4.RenderType_AcaMeasurementToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i5.AcaMeasurementToolbarComponent, [i6.AcaMeasurementManagementService, i7.ColumnManagementService, i8.StateManagementService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "pad-insight"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-aca-measurement-insights", [], null, null, null, i9.View_AcaMeasurementInsightsComponent_0, i9.RenderType_AcaMeasurementInsightsComponent)), i1.ɵdid(8, 245760, null, 0, i10.AcaMeasurementInsightsComponent, [i6.AcaMeasurementManagementService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "slx-aca-measurement-ag-grid", [], null, null, null, i11.View_AcaMeasurementAgGridComponent_0, i11.RenderType_AcaMeasurementAgGridComponent)), i1.ɵdid(11, 4440064, null, 0, i12.AcaMeasurementAgGridComponent, [i6.AcaMeasurementManagementService, i13.Router, i13.ActivatedRoute], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 8, 0); _ck(_v, 11, 0); }, null); }
export function View_AcaMeasurementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-aca-measurement", [], null, null, null, View_AcaMeasurementComponent_0, RenderType_AcaMeasurementComponent)), i1.ɵprd(512, null, i7.ColumnManagementService, i7.ColumnManagementService, [i14.ColumnSettingsStorageService]), i1.ɵprd(512, null, i8.StateManagementService, i8.StateManagementService, [i15.ComponentStateStorageService, i14.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.AcaMeasurementManagementService, i6.AcaMeasurementManagementService, [i16.AcaMeasurementApiService, i7.ColumnManagementService, i8.StateManagementService]), i1.ɵdid(4, 245760, null, 0, i17.AcaMeasurementComponent, [i6.AcaMeasurementManagementService, i8.StateManagementService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var AcaMeasurementComponentNgFactory = i1.ɵccf("slx-aca-measurement", i17.AcaMeasurementComponent, View_AcaMeasurementComponent_Host_0, {}, {}, []);
export { AcaMeasurementComponentNgFactory as AcaMeasurementComponentNgFactory };
