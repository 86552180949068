import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { mutableSelect } from '../../../core/decorators/index';

import { JwtService } from '../jwt/jwt.service';
import { Assert } from '../../../framework/index';
import { ISession, IUser } from '../../../authentication/store/index';

import { IAppState } from '../../../store';
import { NgRedux } from '@angular-redux/store';
import { Map } from 'immutable';

@Injectable()
export class SessionService {
  @mutableSelect('session')
  public session$: Observable<ISession>;
  public lastUser: IUser;
  public lastAlias: string;
  public onTokenRenewed: Subject<any>;
  public onTokenRemoved: Subject<any>;
  public isIPAllowed:boolean;

  private jwtService: JwtService;
  private session: ISession;

  constructor(jwtService: JwtService, ngRedux: NgRedux<IAppState>) {
    Assert.isNotNull(jwtService, 'jwtService');

    this.jwtService = jwtService;
    this.onTokenRenewed = new Subject();
    this.onTokenRemoved = new Subject();

    //we cant use @mutableSelect this because NgRedux.instance not yet initialized
    ngRedux.select('session').subscribe((session: Map<string, any>) => {
      this.session = session.toJS();
      if (this.session && this.session.user) {
        this.lastUser = this.getUser();
        this.lastAlias = this.session.alias;
        this.isIPAllowed=this.session.isIPAllowed;
        this.onTokenRenewed.next();
      } else {
        this.onTokenRemoved.next();
      }
    });
  }

  public isExpired(): boolean {
    if (!this.session || !this.session.token) {
      return true;
    }

    let isTokenExpired: boolean = this.jwtService.isTokenExpired(this.session.token, this.session.clientTimeShift);
    return isTokenExpired;
  }

  public getToken(): string {
    if (!this.session.token) {
      return '';
    }
    return this.session.token;
  }

  public getAlias(): string {
    if (!this.session.alias) {
      return '';
    }
    return this.session.alias;
  }

  public getUser(): IUser {
    if (!this.session.user) {
      return null;
    }

    return this.session.user;
  }

  public isIpAddressValid(): boolean {    
    if (!this.session.isIPAllowed) {
      return false;
    }
    return this.session.isIPAllowed;
  }
  
  public getTokenExpirationDate(): Date {
    return this.jwtService.getTokenExpirationDate(this.session.token, undefined, this.session.clientTimeShift);
  }
}
