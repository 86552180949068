/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-details-eligibility-rules-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./benefit-details-eligibility-rules-list.component";
import * as i4 from "../../../../../common/services/modal/modal.service";
var styles_BenefitDetailsEligibilityRulesListComponent = [i0.styles];
var RenderType_BenefitDetailsEligibilityRulesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitDetailsEligibilityRulesListComponent, data: {} });
export { RenderType_BenefitDetailsEligibilityRulesListComponent as RenderType_BenefitDetailsEligibilityRulesListComponent };
function View_BenefitDetailsEligibilityRulesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "no-data-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No benefit eligibility rules avaliable"]))], null, null); }
function View_BenefitDetailsEligibilityRulesListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "benefit-eligibility-rule__btn-delete"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDeleteRule(_v.parent.parent.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "i", [["aria-hidden", "true"], ["class", "fal fa-trash-alt benefit-eligibility-rule__i-delete"], ["title", "Delete the rule"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "benefit-eligibility-rule_delete-enabled": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fal fa-trash-alt benefit-eligibility-rule__i-delete"; var currVal_2 = _ck(_v, 4, 0, _co.canDelete); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.canDelete; _ck(_v, 0, 0, currVal_0); }); }
function View_BenefitDetailsEligibilityRulesListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "benefit-eligibility-rule"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "benefit-eligibility-rule__editmode-name"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUpdateEligibilityRuleDialog(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsEligibilityRulesListComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isEditMode; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.parent.context.$implicit.name + "_") + _co.benefitName); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_v.parent.context.$implicit.name + "_") + _co.benefitName); _ck(_v, 2, 0, currVal_1); }); }
function View_BenefitDetailsEligibilityRulesListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsEligibilityRulesListComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.status !== _co.BenefitEligibilityRuleStatus.Deleted); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BenefitDetailsEligibilityRulesListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "benefit-eligibility-rules-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsEligibilityRulesListComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsEligibilityRulesListComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.benefitEligibilityRules || !_co.benefitEligibilityRules.length); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.benefitEligibilityRules; var currVal_2 = _co.trackByItem; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_BenefitDetailsEligibilityRulesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-benefit-details-eligibility-rules-list", [], null, null, null, View_BenefitDetailsEligibilityRulesListComponent_0, RenderType_BenefitDetailsEligibilityRulesListComponent)), i1.ɵdid(1, 49152, null, 0, i3.BenefitDetailsEligibilityRulesListComponent, [i4.ModalService], null, null)], null, null); }
var BenefitDetailsEligibilityRulesListComponentNgFactory = i1.ɵccf("slx-benefit-details-eligibility-rules-list", i3.BenefitDetailsEligibilityRulesListComponent, View_BenefitDetailsEligibilityRulesListComponent_Host_0, { benefitEligibilityRules: "benefitEligibilityRules", benefitName: "benefitName", isEditMode: "isEditMode", isLoading: "isLoading", orgLevelId: "orgLevelId", benefitRulesAlreadyUsedNames: "benefitRulesAlreadyUsedNames", canDelete: "canDelete" }, { benefitEligibilityRulesAdded: "benefitEligibilityRulesAdded", benefitEligibilityRulesUpdated: "benefitEligibilityRulesUpdated", benefitEligibilityRulesDeleted: "benefitEligibilityRulesDeleted" }, []);
export { BenefitDetailsEligibilityRulesListComponentNgFactory as BenefitDetailsEligibilityRulesListComponentNgFactory };
