<form novalidate #form="ngForm">
  <p class="theme-header-text">{{employee.fullName}} </p>
  <p class="theme-header-text termination-date">Termination Date: {{employee.terminationDate | amDateFormat:appConfig.dateFormat }} </p>
  <ng-container *ngIf="!isV6ReHireEnhacementEnabled else newRehireProcess">
  <div class="flex-horizontal-space-between">
    <slx-input-decorator class="flex-item">
      <slx-date-picker-ngx slx-input
                           [(ngModel)]="requestToRehire.date"
                           (ngModelChange)="onRehireDateUpdate($event)"
                           [required]="true" [acceptNullDate]="false"
                           placeholder="Rehire Date"
                           name="date"></slx-date-picker-ngx>
      <span errorMessage for="required">Date is invalid or out of valid range</span>
    </slx-input-decorator>
    <slx-input-decorator class="flex-item">
      <slx-money-input slx-input [decimalLimit]="2" [requireDecimal]="false" [allowNegative]="false"
                       name="payRate" placeholder="Pay Rate" min="0" slxMin="0" [slxMax]="getRateLimit" number
                       [required]="true" [(ngModel)]="requestToRehire.payRate">
      </slx-money-input>
      <div errorMessage for="min">The value should be greater than or equal to 0</div>
      <span errorMessage for="max">Should not exceed {{getRateLimit}}</span>
      <span errorMessage for="number"></span>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
    <slx-input-decorator class="flex-item">
      <slx-dropdown-input slx-input required="true" [lookup]="{lookupType: 'position', orgLevelId: currentOrgLevelId}" name="position" placeholder="Position"
                          [(ngModel)]="requestToRehire.position" autoSelectFirst="true">
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>
  <div class="flex-horizontal-space-between">
    <slx-input-decorator class="flex-item">
      <slx-dropdown-input slx-input required="true" [lookup]="{lookupType: 'payPolicy', orgLevelId: currentOrgLevelId}" name="payPolicy" placeholder="Pay Policy"
                          [(ngModel)]="requestToRehire.payPolicy" autoSelectFirst="true">
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
    <slx-input-decorator class="flex-item">
      <slx-dropdown-input slx-input required="true" [lookup]="{lookupType: 'shiftDiffPolicy', orgLevelId: currentOrgLevelId}" name="shiftDiffPolicy" placeholder="Shift Diff Policy"
                          [(ngModel)]="requestToRehire.shiftDiffPolicy" autoSelectFirst="true">
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>

  <div class="flex-horizontal-space-between" *ngIf="bswiftEnabled">
    <slx-input-decorator class="flex-item">
      <slx-dropdown-input slx-input required="true" [lookup]="benefitClassesLookup" name="benefitClass" placeholder="Benefit Class"
                          [(ngModel)]="requestToRehire.benefitClass" autoSelectFirst="true" emptyOption>
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
    <slx-input-decorator class="flex-item">
        <slx-date-picker-ngx slx-input
                             [(ngModel)]="requestToRehire.benefitClassEffectiveDate"
                             placeholder="Benefit Class Effective Date"
                             name="benefitClassEffectiveDate"
                             [readonly]="true"></slx-date-picker-ngx>
    </slx-input-decorator>
  </div>

  <div class="flex-horizontal-space-between">
    <slx-input-decorator class="flex-item">
      <span  class="slx-caption-block" *ngIf="!mobileRef.errors && isMessageCenterEnabled">Required for Smartlinx Go</span>
        <slx-phone-input slx-input
                       phone
                       name="mobilePhone"
                       placeholder="Mobile Phone"
                       #mobileRef="ngModel"
                       [(ngModel)]="requestToRehire.mobilePhone">
        </slx-phone-input>
      <span errorMessage for="phone"></span>
    </slx-input-decorator>
  </div>
  </ng-container>
  <ng-template #newRehireProcess>
    <div class="flex-horizontal-space-between">
      <slx-input-decorator class="flex-item">
        <slx-date-picker-ngx slx-input
                             [(ngModel)]="requestToRehire.date"
                             (ngModelChange)="onRehireDateUpdate($event)"
                             [required]="true" [acceptNullDate]="false"
                             placeholder="Rehire Date"
                             name="date"></slx-date-picker-ngx>
        <span errorMessage for="required">Date is invalid or out of valid range</span>
      </slx-input-decorator>
      <slx-input-decorator class="flex-item">
        <slx-dropdown-input slx-input
                            [required]="true"
                            [lookup]="{lookupType: 'empType'}"
                            [(ngModel)]="requestToRehire.employeeType"
                            name="empType"
                            emptyOption
                            placeholder="Employee Type">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="flex-horizontal-space-between">
        <slx-input-decorator class="flex-item">
          <slx-dropdown-input slx-input 
                              [required]="true" 
                              [lookup]="{lookupType: 'userAccesibleOrganizations'}"
                              name="organization" 
                              placeholder="Organization" 
                              [(ngModel)]="requestToRehire.organization" 
                              (ngModelChange)="onOrganizationChanged($event)" 
                              emptyOption>
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
        <slx-input-decorator class="flex-item">
          <slx-dropdown-input slx-input 
                              [required]="true" 
                              [lookup]="departmentLookup" 
                              [disabled]="!departmentEditable" 
                              name="department"
                              placeholder="Department" 
                              [(ngModel)]="requestToRehire.department" 
                              (ngModelChange)="onDepartmentChanged($event)" 
                              emptyOption>
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
    </div>
    <div class="flex-horizontal-space-between">
      <slx-input-decorator class="flex-item">
        <slx-dropdown-input slx-input 
                            [required]="true"
                            [disabled]="!positionEditable"
                            [lookup]="positionLookup" 
                            name="position" 
                            placeholder="Position"
                            [(ngModel)]="requestToRehire.position" 
                            emptyOption>
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
      <slx-input-decorator class="flex-item">
        <slx-money-input  slx-input 
                          [decimalLimit]="2" 
                          [requireDecimal]="false" 
                          [allowNegative]="false"
                          name="payRate" 
                          placeholder="Pay Rate" 
                          min="0" slxMin="0" 
                          [slxMax]="getRateLimit" number
                          [required]="true" 
                          [(ngModel)]="requestToRehire.payRate">
        </slx-money-input>
        <div errorMessage for="min">The value should be greater than or equal to 0</div>
        <span errorMessage for="max">Should not exceed {{getRateLimit}}</span>
        <span errorMessage for="number"></span>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="flex-horizontal-space-between">
      <slx-input-decorator class="flex-item">
        <slx-dropdown-input slx-input
                            [required]="true"
                            [lookup]="{lookupType: 'payType'}"
                            [(ngModel)]="requestToRehire.payType"
                            name="payType"
                            emptyOption
                            placeholder="Pay Type">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
      <slx-input-decorator class="flex-item">
        <slx-dropdown-input slx-input 
                            required="true" 
                            [disabled]="!payPolicyEditable"
                            [lookup]="payPolicyLookup" 
                            name="payPolicy" 
                            placeholder="Pay Policy"
                            [(ngModel)]="requestToRehire.payPolicy" 
                            autoSelectFirst="true">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="flex-horizontal-space-between">
      <slx-input-decorator class="flex-item">
        <slx-dropdown-input slx-input 
                            [required]="true" 
                            [disabled]="!shiftEditable"
                            [lookup]="shiftDiffPolicyLookup" 
                            name="shiftDiffPolicy" 
                            placeholder="Shift Diff Policy"
                            [(ngModel)]="requestToRehire.shiftDiffPolicy" 
                            autoSelectFirst="true">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
      <slx-input-decorator class="flex-item">
        <slx-dropdown-input slx-input 
                            [required]="true"
                            [readonly]="false" 
                            [lookup]="{lookupType: 'exemptStatus'}"
                            name="exemptStatus"
                            placeholder="Exempt Status"
                            [(ngModel)]="requestToRehire.exemptStatus">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>

    <div class="flex-horizontal-space-between">
      <slx-input-decorator class="flex-item">
        <slx-dropdown-input slx-input [required]="true" [disabled]="!unitEditable" [lookup]="unitLookup" name="unit" placeholder="Unit"
          [(ngModel)]="requestToRehire.unit" emptyOption>
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
      <slx-input-decorator class="flex-item">
        <span  class="slx-caption-block" *ngIf="!mobileRef.errors && isMessageCenterEnabled">Required for Smartlinx Go</span>
          <slx-phone-input  slx-input
                            phone
                            name="mobilePhone"
                            placeholder="Mobile Phone"
                            #mobileRef="ngModel"
                            [(ngModel)]="requestToRehire.mobilePhone">
          </slx-phone-input>
        <span errorMessage for="phone"></span>
      </slx-input-decorator>
    </div>
  
    <div class="flex-horizontal-space-between" *ngIf="bswiftEnabled">
      <slx-input-decorator class="flex-item">
        <slx-dropdown-input slx-input required="true" [lookup]="benefitClassesLookup" name="benefitClass" placeholder="Benefit Class"
                            [(ngModel)]="requestToRehire.benefitClass" autoSelectFirst="true" emptyOption>
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
      <slx-input-decorator class="flex-item">
          <slx-date-picker-ngx slx-input
                               [(ngModel)]="requestToRehire.benefitClassEffectiveDate"
                               placeholder="Benefit Class Effective Date"
                               name="benefitClassEffectiveDate"
                               [readonly]="true"></slx-date-picker-ngx>
      </slx-input-decorator>
    </div>
    <div class="flex-horizontal-space-between" *ngIf="!isNgpUser">
      <slx-input-decorator class="flex-item">
        <input slx-input type="text" 
         [(ngModel)]="requestToRehire.payrollNumber"
         [server]="{validationName: payrollNumberValidation, parameters: [requestToRehire.organization?.orgLevelId, this.requestToRehire.employeeId], validationAdapter: employeeActivitiesValidatorAdapter}"
         [disabled]="!requestToRehire.organization"
         placeholder="New Payroll No"
         name="payrollNumber">
        <span errorMessage for="required"></span>
        <span errorMessage for="min"></span>
        <span errorMessage for="max"></span>
        <span errorMessage [for]="payrollNumberValidation">msg from server</span>
      </slx-input-decorator>
    </div>
  </ng-template>
</form>
