import * as tslib_1 from "tslib";
import { ControlValueAccessorBase } from './control-value-accessor-base';
var AcceptableControlValueAccessor = /** @class */ (function (_super) {
    tslib_1.__extends(AcceptableControlValueAccessor, _super);
    function AcceptableControlValueAccessor() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AcceptableControlValueAccessor.prototype.registerOnAccept = function (fn) {
        this.onAcceptCallback = fn;
    };
    AcceptableControlValueAccessor.prototype.changeValue = function (val) {
        var _this = this;
        if (!this.onAcceptCallback) {
            this.onChangeCallback(val);
            return true;
        }
        var res = this.onAcceptCallback(val);
        if (res instanceof Promise) {
            res.then(function (value) {
                if (value) {
                    _this.onChangeCallback(val);
                }
            });
            return res;
        }
        if (res) {
            this.onChangeCallback(val);
        }
        return res;
    };
    return AcceptableControlValueAccessor;
}(ControlValueAccessorBase));
export { AcceptableControlValueAccessor };
export function isAcceptableControlValueAccessor(source) {
    if (!source) {
        return false;
    }
    return !!(source.registerOnChange && source.registerOnAccept && source.registerOnTouched);
}
