import { ShiftReplacementRequest } from './index';
import { IColumnSettings } from '../../../core/models/index';
import { ColumnSettingsDefinitions } from '../../../common/models/index';

export class ShiftReplacementSettings {

  public isMultipleSelectionMode: boolean;
  public request: ShiftReplacementRequest;
  public context: ColumnSettingsDefinitions;
  public columns: IColumnSettings[];
  public createColumns(): void {
    this.columns = [
      {
        name: 'position',
        description: 'Position',
        displayed: true
      },
      {
        name: 'department',
        description: 'Department',
        displayed: true
      },
      {
        name: 'acaType',
        description: 'ACA Type',
        displayed: false
      },
      {
        name: 'acaAvgHours',
        description: 'ACA Average Hours',
        displayed: false
      },
      {
        name: 'union',
        description: 'Union',
        displayed: true
      },
      {
        name: 'weeklyWorkedHours',
        description: 'Weekly Worked Hours',
        displayed: false
      },
      {
        name: 'weeklyScheduledHours',
        description: 'Weekly Scheduled Hours',
        displayed: false
      },
      {
        name: 'type',
        description: 'Type',
        displayed: false
      },
      {
        name: 'weeklyProjectedHours',
        description: 'Weekly Projected Hours',
        displayed: false
      },
      {
        name: 'payRate',
        description: 'Pay Rate',
        displayed: false
      },
      {
        name: 'dateHired',
        description: 'Date of Hire',
        displayed: false
      },
      {
        name: 'homePhone',
        description: 'Home Phone',
        displayed: false
      },
      {
        name: 'mobilePhone',
        description: 'Mobile Phone',
        displayed: false
      },
      {
        name: 'altPhone',
        description: 'Alt Phone',
        displayed: false
      },
      {
        name: 'approachingOT',
        description: 'Approaching OT',
        displayed: false
      }
    ];
  }
}

