import * as tslib_1 from "tslib";
import { OnInit, OnChanges, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { PbjExportApiService } from '../../../services/index';
import { ServerFilterService } from '../../../../../core/services/index';
import { PbjDetailsBasicGridComponent } from '../pbj-details-basic-grid/pbj-details-basic-grid.component';
import { StateManagementService } from '../../../../../common/index';
var PbjDetailsEmpDataGridComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PbjDetailsEmpDataGridComponent, _super);
    function PbjDetailsEmpDataGridComponent(apiService, serverFilterService, changeDetector, stateManagement) {
        var _this = _super.call(this, apiService, serverFilterService, changeDetector, stateManagement) || this;
        _this.controlKey = 'PbjExportEmpDataComponent';
        return _this;
    }
    Object.defineProperty(PbjDetailsEmpDataGridComponent.prototype, "exportFilename", {
        get: function () {
            return "export-" + this.exportId + "-employee-data";
        },
        enumerable: true,
        configurable: true
    });
    PbjDetailsEmpDataGridComponent.prototype.refreshData = function () {
        var _this = this;
        this.onLoadStatus(true);
        this.apiService.getPBJExportLogEmployeeData(this.serverFilterService.createQuery(this.pagingData, this.gridState.state.sort), this.exportId)
            .then(function (val) {
            _this.records = val;
            _this.onLoadStatus(false);
            _this.showChanges();
        }).catch(function (reason) {
            _this.onLoadStatus(false);
        });
    };
    PbjDetailsEmpDataGridComponent.prototype.loadAndExport = function (type) {
        var _this = this;
        this.onLoadStatus(true);
        var zeroPaging = { skip: 0 };
        this.apiService.getPBJExportLogEmployeeData(this.serverFilterService.createQuery(zeroPaging, this.gridState.state.sort), this.exportId)
            .then(function (val) {
            _this.processExportData(type, val);
            _this.onLoadStatus(false);
        }).catch(function (reason) {
            _this.exportRecords = null;
            _this.onLoadStatus(false);
        });
    };
    PbjDetailsEmpDataGridComponent.prototype.applyDefaultSort = function () {
        this.gridState.state.sort = [{ field: 'employeeName', dir: 'asc' }];
    };
    PbjDetailsEmpDataGridComponent.prototype.updateColumnsConfig = function () {
        var _a;
        _super.prototype.updateColumnsConfig.call(this);
        var specificColumns = [
            { field: 'employeeName', displayTitle: 'Name', index: 1, width: 150 },
            { field: 'payrollNumber', displayTitle: 'Payroll No', index: 2, width: 150 }
        ];
        (_a = this.xlsExportColumns).push.apply(_a, specificColumns);
    };
    return PbjDetailsEmpDataGridComponent;
}(PbjDetailsBasicGridComponent));
export { PbjDetailsEmpDataGridComponent };
