import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { BenefitDetailsState } from './benefit-details-state';
var BenefitDetailsProviderLineStandartEntity = /** @class */ (function (_super) {
    tslib_1.__extends(BenefitDetailsProviderLineStandartEntity, _super);
    function BenefitDetailsProviderLineStandartEntity() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.selectedTierId = null;
        _this.hasTiersAttachments = false;
        _this.stored = null;
        _this.draft = false;
        return _this;
    }
    Object.defineProperty(BenefitDetailsProviderLineStandartEntity.prototype, "isDraft", {
        get: function () {
            return this.draft;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsProviderLineStandartEntity.prototype, "isStored", {
        get: function () {
            return _.isObjectLike(this.stored);
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsProviderLineStandartEntity.prototype.markAsDraft = function () {
        this.draft = true;
    };
    BenefitDetailsProviderLineStandartEntity.prototype.storeEntity = function () {
        this.stored = _.cloneDeep(this.current);
        return this;
    };
    BenefitDetailsProviderLineStandartEntity.prototype.restoreEntity = function () {
        this.current = _.cloneDeep(this.stored);
        this.stored = null;
        return this;
    };
    return BenefitDetailsProviderLineStandartEntity;
}(BenefitDetailsState));
export { BenefitDetailsProviderLineStandartEntity };
var DeductionCodeValidation = /** @class */ (function () {
    function DeductionCodeValidation() {
    }
    return DeductionCodeValidation;
}());
export { DeductionCodeValidation };
