/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./meal-deduction-new-configuration-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../meal-deduction-new-configuration/meal-deduction-new-configuration.component.ngfactory";
import * as i4 from "../../../services/meal-deduction/meal-deduction-management.service";
import * as i5 from "../../../services/meal-deduction/meal-deduction-api.service";
import * as i6 from "../../../../../organization/services/lookup/lookup.service";
import * as i7 from "../../../../../organization/services/org-level/org-level-watch.service";
import * as i8 from "../meal-deduction-new-configuration/meal-deduction-new-configuration.component";
import * as i9 from "../pbj-export-locations/pbj-export-locations.component.ngfactory";
import * as i10 from "../pbj-export-locations/pbj-export-locations.component";
import * as i11 from "../../../../../organization/services/application-state-bus/application-state-bus.service";
import * as i12 from "./meal-deduction-new-configuration-dialog.component";
import * as i13 from "../../../models/meal-deductions/meal-deductions-dialog-options";
import * as i14 from "../../../../../common/models/dialog-options";
import * as i15 from "../../../../../common/services/modal/modal.service";
var styles_MealDeductionNewConfigurationDialogComponent = [i0.styles];
var RenderType_MealDeductionNewConfigurationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MealDeductionNewConfigurationDialogComponent, data: {} });
export { RenderType_MealDeductionNewConfigurationDialogComponent as RenderType_MealDeductionNewConfigurationDialogComponent };
function View_MealDeductionNewConfigurationDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "enabling-the-pbj-mea"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enabling the PBJ Meal Deduction feature for a group of employees will automatically calculate the required Meal Deduction for each employee based on the length of their shift and any existing break calculations. The meal deductions will be listed on the \u201CPBJ Manual Entries\u2019 screen and the data in the PBJ export will be adjusted accordingly."]))], null, null); }
export function View_MealDeductionNewConfigurationDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { configEditorComponent: 0 }), i1.ɵqud(402653184, 2, { pbjLocationExportComponent: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 21, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 13, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MealDeductionNewConfigurationDialogComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "h-divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-sm-8 pt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "slx-meal-deduction-new-configuration", [], null, [[null, "onSaved"], [null, "isMealPropsChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSaved" === en)) {
        var pd_0 = (_co.closeAfterSave($event) !== false);
        ad = (pd_0 && ad);
    } if (("isMealPropsChanged" === en)) {
        var pd_1 = (_co.getIsMealChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MealDeductionNewConfigurationComponent_0, i3.RenderType_MealDeductionNewConfigurationComponent)), i1.ɵprd(512, null, i4.MealDeductionManagementService, i4.MealDeductionManagementService, [i5.MealDeductionApiService, i6.LookupService, i7.OrgLevelWatchService]), i1.ɵdid(12, 114688, [[1, 4], ["configEditorComponent", 4]], 0, i8.MealDeductionNewConfigurationComponent, [i4.MealDeductionManagementService, i7.OrgLevelWatchService], { hideDescription: [0, "hideDescription"] }, { onSaved: "onSaved", isMealPropsChanged: "isMealPropsChanged" }), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "seperator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "col-sm-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "slx-pbj-export-locations", [], null, null, null, i9.View_PbjExportLocationsComponent_0, i9.RenderType_PbjExportLocationsComponent)), i1.ɵdid(16, 114688, [[2, 4], ["pbjLocationExportComponent", 4]], 0, i10.PbjExportLocationsComponent, [i7.OrgLevelWatchService, i11.ApplicationStateBusService, i4.MealDeductionManagementService], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "row m-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-danger bg-red wh-align pull-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Discard"])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "btn btn-success bg-green wh-align pull-right"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideDescription; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.hideDescription; _ck(_v, 12, 0, currVal_1); _ck(_v, 16, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (i1.ɵnov(_v, 12).form.invalid || !_co.modified); _ck(_v, 22, 0, currVal_2); }); }
export function View_MealDeductionNewConfigurationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-meal-deduction-new-configuration-dialog", [], null, null, null, View_MealDeductionNewConfigurationDialogComponent_0, RenderType_MealDeductionNewConfigurationDialogComponent)), i1.ɵdid(1, 49152, null, 0, i12.MealDeductionNewConfigurationDialogComponent, [i13.MealDeducitonsDialogOptions, i14.DialogOptions, i15.ModalService, i4.MealDeductionManagementService], null, null)], null, null); }
var MealDeductionNewConfigurationDialogComponentNgFactory = i1.ɵccf("slx-meal-deduction-new-configuration-dialog", i12.MealDeductionNewConfigurationDialogComponent, View_MealDeductionNewConfigurationDialogComponent_Host_0, {}, {}, []);
export { MealDeductionNewConfigurationDialogComponentNgFactory as MealDeductionNewConfigurationDialogComponentNgFactory };
