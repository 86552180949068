<div class="modal-content">
  <div class="modal-body">
    <slx-spinner [show]="state.isLoading">
      <slx-employee-terminate #employeeTermination [employee]="employee"></slx-employee-terminate>
    </slx-spinner>
    <div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
    <button type="button" (click)="onOk()" [disabled]="!isemployeeTerminationFormValid" class="theme-button-apply">OK</button>
  </div>
</div>
