<div class="g-map">
<div style="height: 100%;" *ngIf="isUrl && !hasError">
  <iframe #iframe width="100%" height="100%" 
  [src]="url | safe"
  loading="lazy"
  frameborder="0"></iframe>
</div>  
<div class="g-map__error-box" *ngIf="hasError">
  <div class="g-map__error-content">
    
    <p class="g-map__error-msg" *ngIf="errorCode==1"><em>Internal error. Missing the App URL. Please contact your system administrator.</em></p>
    <p class="g-map__error-msg" *ngIf="errorCode==2"><em>Found multiple locations. Update the address and try again.</em></p>
    <p class="g-map__error-msg" *ngIf="errorCode==3">No results found for "<em>{{ notFoundFor }}</em>"</p>
    
  </div>
</div>
</div>

