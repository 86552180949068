<slx-spinner [show]="state.isLoading">

    <div class="error-state" *ngIf="state.isError">
        <span class="error-label">Error happened. Please, try refresh page later</span>
    </div>

    <div class="slx-main-content-indents" *ngIf="summary">

        <slx-timecard-summary-header [summary]="summary"></slx-timecard-summary-header>

        <slx-collapsible-panel #filtersPanel title="Filters" [collapsed]="false" [hideButton]="true" *ngIf="allowFilters">
            <div class="panel-header">
                <slx-timecard-summary-panel-header [panel]="filtersPanel"></slx-timecard-summary-panel-header>
            </div>
            <div class="panel-content">
                <slx-timecard-summary-filters></slx-timecard-summary-filters>
            </div>
        </slx-collapsible-panel>

        <slx-collapsible-panel #hoursPanel title="Summary of Paid Hours" [collapsed]="false">
            <div class="panel-header">
                <slx-timecard-summary-panel-header [component]="paidHours" [panel]="hoursPanel"></slx-timecard-summary-panel-header>
            </div>
            <div class="panel-content">
                <slx-timecard-summary-hours #paidHours [container]="summary?.paidHours" [payrollCycle]="summary?.payrollCycle"></slx-timecard-summary-hours>
            </div>
        </slx-collapsible-panel>

        <slx-collapsible-panel #periodsPanel title="Current vs Previous Periods" [collapsed]="false">
            <div class="panel-header">
                <slx-timecard-summary-panel-header [component]="periodsSummary" [panel]="periodsPanel"></slx-timecard-summary-panel-header>
            </div>
            <div class="panel-content">
                <slx-periods-summary #periodsSummary [container]="summary?.periodCompare" [payrollCycle]="summary?.payrollCycle"></slx-periods-summary>
            </div>
        </slx-collapsible-panel>

        <slx-collapsible-panel #exemptPanel title="Exempt Employees" [collapsed]="false" *ngIf="showExemptEmployees">
            <div class="panel-header">
                <slx-timecard-summary-panel-header [component]="exemptEmployees" [panel]="exemptPanel"></slx-timecard-summary-panel-header>
            </div>
            <div class="panel-content">
                <slx-timecard-summary-exempts #exemptEmployees [container]="summary?.exemptEmployees" [payrollCycle]="summary?.payrollCycle"></slx-timecard-summary-exempts>
            </div>
        </slx-collapsible-panel>

        <slx-collapsible-panel #secondaryPanel title="Secondary Positions" [collapsed]="false">
            <div class="panel-header">
                <slx-timecard-summary-panel-header [component]="secondayPositions" [panel]="secondaryPanel"></slx-timecard-summary-panel-header>
            </div>
            <div class="panel-content">
                <slx-timecard-summary-positions #secondayPositions [container]="summary?.secondaryPosition" [payrollCycle]="summary?.payrollCycle"></slx-timecard-summary-positions>
            </div>
        </slx-collapsible-panel>

    </div>

</slx-spinner>
