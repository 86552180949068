import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { W2sManagementService } from '../../../../../app/reports/services/w2s/w2s-management.service';
import { unsubscribeAll } from '../../../../core/decorators/index';





@Component({
  moduleId: module.id,
  selector: 'slx-w2s-list',
  templateUrl: 'w2s-list.component.html',
  styleUrls: ['w2s-list.component.scss']
  
})
export class W2sListComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor( private w2sManagementService: W2sManagementService) { }

  public ngOnInit() {
   
    this.w2sManagementService.init();
    this.subscriptions.loading = this.w2sManagementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  public ngOnDestroy(): void {
    this.w2sManagementService.destroy();
  }
}
