import { Directive, Input, ElementRef, Renderer, HostListener, OnInit, Host } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';


@Directive({
  selector: '[kendoGridHeightResize]',
})
export class KendoGridHeightResizeDirective implements OnInit {
  @Input('kendoGridHeightResize')
  public topOffset: number;
  @Input()
  public mobileTopOffset: number;
  @Input()
  public mobileDisableResize: number;

  private get isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }
  private gridHeight: number;
  private elRef: ElementRef;
  private renderer: Renderer;
  private grid: GridComponent;

  constructor( @Host() grid: GridComponent, elRef: ElementRef, renderer: Renderer) {
    this.elRef = elRef;
    this.renderer = renderer;
    this.grid = grid;
  }

  public ngOnInit(): void {
    this.calcHeight();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent): void {
    this.calcHeight();
  }

  protected calcHeight(): void {
    if(this.isMobile && this.mobileDisableResize) {
      this.renderer.setElementStyle(this.elRef.nativeElement, 'height', '100%');
      return;
    }
    if (this.isMobile && this.mobileTopOffset) {
        this.gridHeight = window.innerHeight - this.mobileTopOffset;
        this.grid.height = this.gridHeight;
        this.renderer.setElementStyle(this.elRef.nativeElement, 'height', `${this.gridHeight}px`);
      return;
    }
    this.gridHeight = window.innerHeight - this.topOffset;
    this.grid.height = this.gridHeight;
    this.renderer.setElementStyle(this.elRef.nativeElement, 'height', `${this.gridHeight}px`);
  }
}
