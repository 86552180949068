<slx-spinner [show]="isLoading">
  <div>
    <kendo-grid
      #kendoGridEducation
      class="slx-no-wrap"
      scrollable="'scrollable'"
      [data]="gridState.view"
      (dataStateChange)="gridState.dataStateChange($event)"
      [sortable]="true"
      [sort]="gridState.state.sort"
      [pageable]="false"
      (edit)="gridState.editHandler($event)"
      (save)="gridState.saveHandler($event)"
      (cancel)="gridState.cancelHandler($event)"
      (remove)="gridState.removeHandler($event)"
      [skip]="skip"
      [groupable]="false"
      [selectable]="false"
      (selectionChange)="gridState.selectionChange($event)"
      [slxKendoGridState]="gridState.gridDefinition"
      (stateRestored)="gridState.dataStateChange($event)"
      [filterable]="false"
    >
      <!-- Add education button  -->
      <ng-template kendoGridToolbarTemplate position="top">
        <div class="col-xs-12" *ngIf="canAddEducation && isEditable">
          <div class="row">
            <button class="theme-iconed-accent-button pull-right" [disabled]="canAddEducation && !enableAddEducation" (click)="onAddEducation()">
              <span class="icon-button-content">
                <span>Create New</span><i class="fa fa-plus" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        </div>
      </ng-template>

      <!-- education button controlls -->
      <kendo-grid-column title="" [sortable]="false" [filterable]="false" width="80">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex">
            <button kendoGridEditCommand type="button" class="slx-button slx-only-icon slx-toolbar edit-icon-color" *ngIf="canEditEducation && isEditable" [disabled]="addMode">
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button kendoGridRemoveCommand type="button" class="slx-button slx-only-icon slx-toolbar del-icon-color" *ngIf="canDeleteEducation && isEditable" [disabled]="addMode">
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
            </button>
            <button kendoGridSaveCommand type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r" [disabled]="!enableSave">
              <i class="far fa-check-circle" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="slx-button slx-only-icon slx-toolbar">
              <i class="fa fa-ban" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <!-- Degree  -->
      <kendo-grid-column field="degree" title="Degree" width="150" [sortable]="true">
        <ng-template kendoGridHeaderTemplate>
          Degree 
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.degree}}"
            >{{ dataItem.degree}}</span
          >
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <input
              slx-input
              required="true"
              [(ngModel)]="dataItem.degree"
              (ngModelChange)="onChangeValue(dataItem)"
              class="k-textbox"
              placeholder="Degree"
              slxMaxLength="50"
            />
            <span errorMessage for="required" *ngIf="!dataItem.degree"
              >Enter Degree</span
            >
            <span errorMessage for="slxMaxLength"
              >Max length '50'</span
            >
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- School / College  -->
      <kendo-grid-column
        field="schoolCollege"
        title="schoolCollege"
        width="200"
        editable="false"
        [sortable]="true"
      >
        <ng-template kendoGridHeaderTemplate>
          School / College
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="{{dataItem.schoolCollege}}"
            >{{ dataItem.schoolCollege }}</span
          >
        </ng-template>
        <ng-template
          kendoGridEditTemplate
          let-dataItem="dataItem"
          let-rowIndex="rowIndex"
        >
          <slx-input-decorator>
            <input
              slx-input
              [(ngModel)]="dataItem.schoolCollege"
              (ngModelChange)="onChangeValue(dataItem)"
              class="k-textbox"
              placeholder="School / College"
              slxMaxLength="255"
              required="true"
            />
            <span errorMessage for="required" *ngIf="!dataItem.schoolCollege"
            >Enter School / College</span
          >
            <span errorMessage for="slxMaxLength"
              >Max length '255'</span
            >
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <!-- Graduation Year   -->
      <kendo-grid-column field="graduationYear" title="Graduation Year" width="130" editable="false" [sortable]="true">
        <ng-template kendoGridHeaderTemplate>
          Graduation Year
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.graduationYear | amDateFormat: appConfig.dateFormat }}
        </ng-template>

        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <slx-date-picker-ngx slx-input
            #graduationYear="ngModel"
              placeholder="Graduation Year"
              name="graduationYear{{rowIndex}}"
              [required]="true"
              [(ngModel)]="dataItem.graduationYear"
              (valueChange)="validityChanged($event,dataItem)"
              (ngModelChange)="onChangeValue(dataItem)"
              [minDate]="minDate" 
              [maxDate]="maxDate"
            ></slx-date-picker-ngx>
            <span errorMessage for="required">Please enter Date</span>
            <span errorMessage for="date"></span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</slx-spinner>
