import { UsersRoles } from '../models/users-roles.model';
import { RolesWithUsersDecorator } from './roles-with-users.decorator';
import { UserProfileWithOrgLevelDecorator } from './user-profile-with-orglevel.decorator';
import { UserProfileModel } from '../models/user-profile.model';
import { OrgLevel } from '../../../../state-model/models/index';
import * as _ from 'lodash';
import { UserRoleRelation } from '../models/user-role-relations.model';

export class RolesWithUsersContainerDecorator {
  public usersRoles: UsersRoles;
  public roles: RolesWithUsersDecorator[];
  public totalAssignedCount: number;
  public totalFilteredCount: number;

  private filter: string;

  constructor(usersRoles: UsersRoles) {
    this.usersRoles = usersRoles;
    this.updateRoles();
  }

  public setFilter(filter: string): void {
    this.filter = filter;
    this.updateRoles();
  }

  private updateRoles(): void {
    let newRoles:RolesWithUsersDecorator[] = [];
    let totalFilteredCount: number = 0;
    let totalAssignedCount: number = 0;
    for(let role of this.usersRoles.roles) {
      let roleDecorator: RolesWithUsersDecorator = new RolesWithUsersDecorator(role);
    
      let usersArray: UserProfileWithOrgLevelDecorator[] = this.getUsers(role.id);
      let filterArray: UserProfileWithOrgLevelDecorator[] = this.getUsersByFilter(usersArray, this.filter);

      roleDecorator.assignedCount = this.getUserCount(usersArray);
      roleDecorator.filteredCount = this.getUserCount(filterArray);

      for (let user of filterArray) {
        roleDecorator.addUser(user);
      }

      newRoles.push(roleDecorator);
      totalAssignedCount += roleDecorator.assignedCount;
      totalFilteredCount += roleDecorator.filteredCount;
    }

    this.roles  = _.sortBy(newRoles, role => {
      return role.filteredCount > 0  ? role.name : '~'+role.name;
    });
    this.totalAssignedCount = totalAssignedCount;
    this.totalFilteredCount = totalFilteredCount;
  }

  private getUsers(roleId: number): UserProfileWithOrgLevelDecorator[] {
    let rolesByRoleCollection: UserRoleRelation[] = _.filter(this.usersRoles.relations, usersRole => {
      return usersRole.roleId === roleId;
    });

    return _.map(rolesByRoleCollection, relation => {
      let decorator: UserProfileWithOrgLevelDecorator = null;
      if (!decorator) {
        let user: UserProfileModel = this.getUserProfileModel(relation.userId, relation.orgLevelId);
        decorator = new UserProfileWithOrgLevelDecorator(user);
      }
      let orgLevel: OrgLevel = this.getOrganizationLevel(relation.orgLevelId);
      decorator.addOrgLevel(orgLevel);
      return decorator;
    });

  }

  private getUsersByFilter(usersArray: UserProfileWithOrgLevelDecorator[], filter: string): UserProfileWithOrgLevelDecorator[] {
    if (!filter) {
      return usersArray;
    }
    const lowerCaseFilter = filter.toLowerCase();
    return _.filter(usersArray, user => {
      return user && (user.userProfile.name.toLowerCase().indexOf(lowerCaseFilter) !== -1
        || user.userProfile.login.toLowerCase().indexOf(lowerCaseFilter) !== -1);
    });
  }
 
  private getUserProfileModel(id: number,orgLevelId ?:number) : UserProfileModel {
    return _.find(this.usersRoles.userProfiles, user => {
      return user.id === id && user.orgLevelId === orgLevelId;
    });
  }

  private getOrganizationLevel(id: number) : OrgLevel {
    return _.find(this.usersRoles.orgLevels, orgLevel => {
      return orgLevel.id === id;
    });
  }

  private getUserCount(usersArray: UserProfileWithOrgLevelDecorator[]): number {
    let count: number = 0;
    if (_.size(usersArray) > 0) {
      count = _.uniqBy(_.map(usersArray, userProfile => {
        return userProfile.userProfile;
      }), 'id').length;
    }
    return count;
  }
}
