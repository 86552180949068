<slx-spinner [show]="isLoading">
  <div class="slx-high-box leave-management">
    <div class="slx-content-toolbar-indents">
      <slx-lm-console-toolbar></slx-lm-console-toolbar>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
     <div class="lm-console-overview slx-headered-section">
      <div class="slx-section-header">
        <div class="slx-section-header-content">
          <div class="slx-section-header-item slx-header-title" lmRosterLink>
            <span class="slx-header-title__text">Overview by Request Type</span>
          </div>
        </div>
        <div class="slx-section-header-button-right">
          <slx-collapse-button [(isCollapsed)]="isOverviewCollapsed" [slxCollapseByCss]="collapseOverviewContent"></slx-collapse-button>
        </div>
      </div>
      <div #collapseOverviewContent class="slx-section-content">
          <slx-lm-console-overview [loaConsole]="loaConsole"></slx-lm-console-overview>
      </div>
     </div>

     <div class="lm-console-grid">

        <div class="slx-headered-section lm-on-leave">
          <div class="slx-section-header">
            <div class="slx-section-header-content">
              <div class="slx-section-header-item slx-header-title" [lmRosterLink]="'OnLeave'">
                <i class="slx-header-title__icon fas fa-user-lock" aria-hidden="true"></i>
                <span class="slx-header-title__text">On Leave</span>
                <span class="slx-header-title__badge">{{loaConsole?.onLeaveTotalCount}}</span>
                <span class="slx-header-row-enable-align" *ngIf="loaConsole?.onLeaveTotalCount > 25"><a class="slx-header-rows-enable">Show all rows</a></span>
              </div>
            </div>
            <div class="slx-section-header-button-right">
              <slx-collapse-button [(isCollapsed)]="isOnLeaveCollapsed" [slxCollapseByCss]="collapseOnLeaveContent"></slx-collapse-button>
            </div>
          </div>
          <div #collapseOnLeaveContent class="slx-section-content">
            <slx-lm-console-table mode="onLeave" [records]="loaConsole?.onLeave"></slx-lm-console-table>
          </div>
       </div>

       <div class="slx-headered-section lm-returning">
          <div class="slx-section-header">
            <div class="slx-section-header-content">
              <div class="slx-section-header-item slx-header-title" [lmRosterLink]="'Returning'">
                <i class="slx-header-title__icon fas fa-undo-alt" aria-hidden="true"></i>
                <span class="slx-header-title__text">Returning</span>
                <span class="slx-header-title__badge">{{loaConsole?.returningTotalCount}}</span>
                <span class="slx-header-row-enable-align" *ngIf="loaConsole?.returningTotalCount > 25"><a class="slx-header-rows-enable">Show all rows</a></span>
              </div>
            </div>
            <div class="slx-section-header-button-right">
              <slx-collapse-button [(isCollapsed)]="isReturningCollapsed" [slxCollapseByCss]="collapseReturningContent"></slx-collapse-button>
            </div>
          </div>
          <div #collapseReturningContent class="slx-section-content">
            <slx-lm-console-table mode="returning" [records]="loaConsole?.returning"></slx-lm-console-table>
          </div>
       </div>

       <div class="slx-headered-section lm-upcoming">
          <div class="slx-section-header">
            <div class="slx-section-header-content">
              <div class="slx-section-header-item slx-header-title" [lmRosterLink]="'Upcoming'">
                <i class="slx-header-title__icon fas fa-download" aria-hidden="true"></i>
                <span class="slx-header-title__text">Upcoming</span>
                <span class="slx-header-title__badge">{{loaConsole?.upcomingTotalCount}}</span>
                <span class="slx-header-row-enable-align" *ngIf="loaConsole?.upcomingTotalCount > 25"><a class="slx-header-rows-enable">Show all rows</a></span>
              </div>
            </div>
            <div class="slx-section-header-button-right">
              <slx-collapse-button [(isCollapsed)]="isUpcomingCollapsed" [slxCollapseByCss]="collapseUpcomingContent"></slx-collapse-button>
            </div>
          </div>
          <div #collapseUpcomingContent class="slx-section-content">
            <slx-lm-console-table mode="upcoming" [records]="loaConsole?.upcoming"></slx-lm-console-table>
          </div>
       </div>

       <div class="slx-headered-section lm-past-due">
          <div class="slx-section-header">
            <div class="slx-section-header-content">
              <div class="slx-section-header-item slx-header-title" [lmRosterLink]="'PastDue'">
                <i class="slx-header-title__icon fas fa-user-clock" aria-hidden="true"></i>
                <span class="slx-header-title__text">Past Due</span>
                <span class="slx-header-title__badge">{{loaConsole?.pastDueTotalCount}}</span>
                <span class="slx-header-row-enable-align" *ngIf="loaConsole?.pastDueTotalCount > 25"><a class="slx-header-rows-enable">Show all rows</a></span>
              </div>
            </div>
            <div class="slx-section-header-button-right">
              <slx-collapse-button [(isCollapsed)]="isPastDueCollapsed" [slxCollapseByCss]="collapsePastDueContent"></slx-collapse-button>
            </div>
          </div>
          <div #collapsePastDueContent class="slx-section-content">
            <slx-lm-console-table mode="pastDue" [records]="loaConsole?.pastDue"></slx-lm-console-table>
          </div>
       </div>

     </div>

    </div>
  </div>
</slx-spinner>
