import { Injectable } from "@angular/core";
import { ExcelData, IExcelData, IImportedFiles, ImportedFiles } from "../../models/imported-files/ImportedFiles";
import * as _ from "lodash";
import { dateTimeUtils } from "./../../../../app/common/utils";
import * as moment from "moment";

@Injectable()
export class FileUploadsMapService {
    constructor() { }

    public mapImportedFiles(dto: IImportedFiles[], selectedOrgId: number): ImportedFiles[] {
        return _.map(dto, (item: ImportedFiles) => this.mapImportedFile(item, selectedOrgId));
    }

    public mapImportedFile(data: ImportedFiles, selectedOrgId: number): ImportedFiles {
        const result: ImportedFiles = new ImportedFiles();
        result.importId = data.importId;
        result.uniqueId = data.uniqueId;
        result.orgLevelId = data.orgLevelId;
        result.facilityName = this.getFacilityName(data.orgLevelId);
        result.departmentId = data.departmentId;
        result.departmentName = this.getOrgName(data.orgLevelId);
        result.moduleName = data.moduleName;
        result.status = data.status;
        result.importedBy = data.importedBy;
        result.importedDateTime = this.convertUtcToIst(data.importedDateTime);
        return result;
    }

    public convertUtcToIst(date: any) {
        let date1: Date = new Date(date);
        let timezone = moment.tz.guess();
        let changedTimeZone1 = moment(date1).tz(timezone).toString();
        return dateTimeUtils.convertFromDtoDateTimeString(new Date(changedTimeZone1).toISOString());
    }

    public getOrgName(orgLevelId): string {
        let orgData = JSON.parse(sessionStorage.getItem('Organizations'));
        let org = orgData.find(x => x.id === orgLevelId);
        return org.name;
    }

    public getFacilityName(orgLevelId): string {
        let orgData = JSON.parse(sessionStorage.getItem('Organizations'));
        let parentId = orgData.filter(x => x.id == orgLevelId).map(x => x.parentId);
        let facility = orgData.find(x => x.id == parentId[0]);
        return facility.name;
    }

    public mapExcelResultData(jsonData: IExcelData[]): ExcelData[] {
        return _.map(jsonData, (item: IExcelData) => this.mapTemplateObject(item));
    }

    public mapTemplateObject(item: IExcelData): ExcelData {
        const data: ExcelData = new ExcelData();
        data.Facility = item.facility;
        data.Department = item.department;
        data.ConfigurationType = item.configurationType;
        data.CensusType = item.censusType;
        data.Position = item.position;
        data.AcuityTypes = item.acuityTypes ? item.acuityTypes : null;
        data.ShiftGroup = item.shiftGroup;
        data.Shift = item.shift;
        data.Unit = item.unit ? item.unit : null;
        data.CensusRangeFrom = item.censusRangeFrom;
        data.CensusRangeTo = item.censusRangeTo;
        data.Sunday = item.sunday;
        data.Monday = item.monday;
        data.Tuesday = item.tuesday;
        data.Wednesday = item.wednesday;
        data.Thursday = item.thursday;
        data.Friday = item.friday;
        data.Saturday = item.saturday;
        return data;
    }
}