import * as tslib_1 from "tslib";
import { PayrollExportTestStateStatusLabels } from './../../../models/payroll-export-test/payroll-export-test-state-statuses';
import { OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { AppSettingsManageService } from './../../../../../../app/app-settings/services';
var PayrollExportTestGridComponent = /** @class */ (function () {
    function PayrollExportTestGridComponent(changeDetector, appSettingsManageService) {
        this.changeDetector = changeDetector;
        this.appSettingsManageService = appSettingsManageService;
        this.testClicked = new EventEmitter();
        this.gridState = new KendoGridStateHelper();
    }
    PayrollExportTestGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    PayrollExportTestGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes['tests']) {
            this.refreshGrid();
        }
    };
    PayrollExportTestGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PayrollExportTestGridComponent.prototype.getClassColor = function (status) {
        switch (status) {
            case PayrollExportTestStateStatusLabels.PASS:
                return 'pets-pass';
            case PayrollExportTestStateStatusLabels.FAIL:
                return 'pets-fail';
            case PayrollExportTestStateStatusLabels.ALERT:
                return 'pets-alert';
        }
        return '';
    };
    PayrollExportTestGridComponent.prototype.onTestClicked = function (test) {
        this.testClicked.emit(test);
    };
    PayrollExportTestGridComponent.prototype.isLinkEnabled = function (testId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isLink, config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isLink = true;
                        return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.NegativeAccrualExportTest = config.NegativeAccrualExportTest;
                        if (testId === 8) {
                            isLink = !this.isAccrualModuleEnabled || this.NegativeAccrualExportTest ? true : false;
                        }
                        return [2 /*return*/, isLink];
                }
            });
        });
    };
    PayrollExportTestGridComponent.prototype.refreshGrid = function () {
        if (!this.tests) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.tests, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayrollExportTestGridComponent.prototype, "gridRefreshSubscription", void 0);
    return PayrollExportTestGridComponent;
}());
export { PayrollExportTestGridComponent };
