<ng-container *ngIf="!editable">
  <div class="punch-value col-xs-12">
   <span class="panch-value-header">Type:</span> {{dataItem?.punch.type?.description}}
  </div>
  <div class="punch-value col-xs-12">
      <span class="panch-value-header">Timeclock:</span> {{dataItem?.punch.timeclock?.name}}
  </div>
  <div *ngIf="dataItem?.punch.closingPunch && (dataItem?.punch.closingPunch.position||dataItem?.punch.positionsString)" class="punch-value col-xs-12">
      <span  class="panch-value-header">Position:</span>
      <span *ngIf="dataItem?.punch.closingPunch" > {{ dataItem?.punch.closingPunch.position?.name }}</span>
      <span *ngIf="!dataItem?.punch.closingPunch||!dataItem?.punch.closingPunch.position"> {{ dataItem?.punch.positionsString }}</span>
  </div>
  <div class="punch-value col-xs-12">
      <span class="panch-value-header">Actual time:</span> {{dataItem?.punch.time | amDateFormat: appConfig.dateTimeFormatUS }}
  </div>
  <div class="punch-value col-xs-12">
      <span class="panch-value-header">Rounded time:</span> {{dataItem?.punch.roundedTime | amDateFormat: appConfig.dateTimeFormatUS }}
  </div>
  <div *ngIf="dataItem?.punch.closingPunch && (!displayOptions || displayOptions==='number')" class="punch-value col-xs-12">
      <span class="panch-value-header">Rounded Interval:</span>{{ dataItem?.punch.closingPunch?.roundedInterval | slxDuration:"hours" | number: "1.2-2" }}
  </div>
  <div *ngIf="dataItem?.punch.closingPunch && displayOptions==='hmstring'" class="punch-value col-xs-12">
      <span class="panch-value-header">Rounded Interval:</span>{{ dataItem?.punch.closingPunch?.roundedInterval | slxInterval: "hminstring" }}
  </div>
  <div class="punch-value col-xs-12">
      <span class="panch-value-header">Inservice:</span>{{ dataItem?.punch.inService | boolyn}}
  </div>
</ng-container>
<ng-container *ngIf="editable">
  <div class="col-xs-6">
      <slx-input-decorator>
        <slx-dropdown-input slx-input required="true" name="type" placeholder="Type" [lookup]="punchTypesLookup" [(ngModel)]="dataItem?.punch.type">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
  </div>
  <div class="col-xs-6">
      <slx-input-decorator *ngIf="!requireTimeclockForPunches">
          <slx-dropdown-input slx-input name="timeclock" lookup-add-item emptyOption placeholder="Time Clock" [lookup]="{lookupType: 'timeclockDefinition', orgLevelId: undefined, employeeId: employeeId}"
            [(ngModel)]="dataItem?.punch.timeclock">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
      </slx-input-decorator>
      <slx-input-decorator *ngIf="requireTimeclockForPunches">
        <slx-dropdown-input slx-input name="timeclock" placeholder="Time Clock" [lookup]="{lookupType: 'timeclockDefinition', orgLevelId: undefined, employeeId: employeeId}"
          [(ngModel)]="dataItem?.punch.timeclock">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>
  <div class="col-xs-12">
      <slx-input-decorator *ngIf="dataItem?.punch.closingPunch" className="slx-no-error-block" >
          <slx-dropdown-input slx-input name="position" placeholder="Position" [lookup]="{lookupType: 'position', employeeId: employeeId}"
            [(ngModel)]="dataItem?.punch.closingPunch.position">
          </slx-dropdown-input>
        </slx-input-decorator>
        <span *ngIf="!dataItem?.punch.closingPunch && dataItem?.punch.positionsString">Position: {{ dataItem?.punch.positionsString }}</span>
  </div>
  <div class="col-xs-12">
      <slx-input-decorator>
          <input slx-date-time-picker slx-input [required]="true" name="time" placeholder="Time" [slxMinDate]="minPunchDate" [slxMaxDate]="maxPunchDate"
            [(ngModel)]="dataItem.punch.time">
          <span errorMessage for="minDate">The value should be greater than {{minPunchDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
          <span errorMessage for="maxDate">The value should be lower than {{maxPunchDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
  </div>
  <div class="col-xs-12">
      Rounded time: {{dataItem?.punch.roundedTime | amDateFormat: appConfig.dateTimeFormatUS }}
  </div>
  <div class="col-xs-6">
      <span *ngIf="dataItem?.punch.closingPunch && (!displayOptions || displayOptions==='number')">{{ dataItem?.punch.closingPunch?.roundedInterval | slxDuration:"hours" | number: "1.2-2" }}</span>
      <span *ngIf="dataItem?.punch.closingPunch && displayOptions==='hmstring'">{{ dataItem?.punch.closingPunch?.roundedInterval | slxInterval: "hminstring"  }}</span>
  </div>
  <div class="col-xs-6">
      <slx-input-decorator className="slx-no-border slx-small-font slx-checkbox-small slx-no-error-block">
          <slx-checkbox-input slx-input name="inservice" fieldName="inservice{{rowIndex}}" placeholder="" caption="" [(ngModel)]="dataItem.punch.inService">
          </slx-checkbox-input>
      </slx-input-decorator>
  </div>
</ng-container>
