import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { dateTimeUtils } from '../../../../common/utils/index';

import {
  BenefitLineInfo,
  IBenefitLineInfo
} from '../../models/index';

@Injectable()
export class BenefitListMapService {
  constructor() {}

  public mapToBenefitList(dtos: IBenefitLineInfo[]): BenefitLineInfo[] {
    return _.map(dtos, (dto: IBenefitLineInfo) => this.mapToBenefit(dto));
  }

  public mapToBenefit(dto: IBenefitLineInfo): BenefitLineInfo {
    const list = new BenefitLineInfo();
    list.id = dto.id;
    list.name = dto.name;
    list.benefitGroupName = dto.benefitGroupName;
    list.benefitGroupId = dto.benefitGroupId;
    list.benefitProviderId = dto.benefitProviderId;
    list.modifiedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.modifiedAt || dto.addedAt);
    list.filterModifiedAt = new Date(list.modifiedAt);
    list.filterModifiedAt.setHours(0, 0, 0, 0);
    list.modifiedBy = dto.modifiedBy || dto.addedBy;
    list.endDate = dateTimeUtils.convertFromDtoDateTimeString(dto.endDate);
    list.endDate.setHours(0, 0, 0, 0);
    return list;
  }
}
