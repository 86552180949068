import { EmployeeSectionsBase } from '../employee-sections-base';
import { FieldsMeta } from '../../../../core/models/index';

export interface IEmployeeSectionsEducation {
  sequenceId:string;
  degree: string;
  schoolCollege: string
  graduationYear: string;
}
export class EmployeeSectionsEducation {
  public sequenceId: string;
  public degree: string;
  public schoolCollege: string
  public graduationYear: string;
}
export interface IEmployeeSectionsEducations {
  education: EmployeeSectionsEducation[];
}
export class EmployeeSectionsEducations extends EmployeeSectionsBase{
  public education: EmployeeSectionsEducation[];
  public fieldsMeta: FieldsMeta;
}