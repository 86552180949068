import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, EventEmitter } from '@angular/core';
import { EmployersManagementService } from '../../../../services/employers/employers-configuration-management.service';
var ManageAleGroupsComponent = /** @class */ (function () {
    function ManageAleGroupsComponent(manService) {
        this.manService = manService;
        this.isDragAndDrop = false;
        this.onLoading = new EventEmitter();
        this.onHasChanges = new EventEmitter();
        this.isGroupChanges = new EventEmitter();
        this.isSaveComplete = new EventEmitter();
        this.searchText = '';
        this.subscriptions = {};
        this.groupNameIsValid = false;
        this.indexToInsertNewGroup = 0;
    }
    Object.defineProperty(ManageAleGroupsComponent.prototype, "isEditing", {
        get: function () {
            return _.isObjectLike(this.editingGroup);
        },
        enumerable: true,
        configurable: true
    });
    ManageAleGroupsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.loadingAleGroup = this.manService
            .subscribeToLoadingAleGroup(function (isLoading) {
            _this.onLoading.emit(isLoading);
        });
        this.subscriptions.aleGroups = this.manService
            .subscribeToLoadAleGroups(function (aleGroups) {
            _this.groups = aleGroups;
            _this.storedGroups = _.cloneDeep(aleGroups);
        });
        this.subscriptions.onSaveComplete = this.manService.subscribeToSaveComplete(function () {
            _this.isSaveComplete.emit(true);
        });
        this.manService.init();
    };
    ManageAleGroupsComponent.prototype.isDefaultGroup = function (group) {
        return group.groupId === this.manService.defaultGroupId;
    };
    ManageAleGroupsComponent.prototype.onAddGroup = function () {
        var group = this.manService.createGroupView();
        group.isEditing = true;
        this.groups.splice(this.indexToInsertNewGroup, 0, group);
        this.toggleEdit(group, true);
    };
    ManageAleGroupsComponent.prototype.onEdit = function (group) {
        if (this.canEditGroup(group)) {
            this.toggleEdit(group, true);
        }
    };
    ManageAleGroupsComponent.prototype.canSaveGroup = function (group) {
        return this.isEditing && group.isEditing && _.size(group.groupName) > 0 && this.groupNameIsValid;
    };
    ManageAleGroupsComponent.prototype.onSave = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.canSaveGroup(group)) return [3 /*break*/, 5];
                        if (!!group.groupId) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.createGroup(group)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.updateGroup(group)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.emitChanges();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ManageAleGroupsComponent.prototype.updateGroup = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedGroup, storedIndex;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manService.updateGroup(group)];
                    case 1:
                        updatedGroup = _a.sent();
                        if (updatedGroup) {
                            group.groupName = updatedGroup.groupName;
                            storedIndex = this.findIndex(this.storedGroups, group);
                            if (storedIndex !== -1) {
                                this.storedGroups[storedIndex].groupName = updatedGroup.groupName;
                            }
                            this.toggleEdit(group, false);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageAleGroupsComponent.prototype.createGroup = function (g) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var createdGroup, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manService.addUpdateGroup(g)];
                    case 1:
                        createdGroup = _a.sent();
                        if (createdGroup) {
                            index = this.deleteGroup(this.groups, g);
                            if (index !== -1) {
                                this.groups.splice(index, 0, createdGroup);
                                this.storedGroups.splice(index, 0, _.cloneDeep(createdGroup));
                            }
                            this.toggleEdit(createdGroup, false);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageAleGroupsComponent.prototype.onDelete = function (isDelete, acceptPopover, group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        acceptPopover.hide();
                        if (!isDelete) return [3 /*break*/, 2];
                        if (!(this.canRemoveGroup(group) && (group.groupId >= 0))) return [3 /*break*/, 2];
                        group.deleted = true;
                        return [4 /*yield*/, this.manService.deleteGroup(group)];
                    case 1:
                        _a.sent();
                        this.deleteGroup(this.groups, group);
                        this.deleteGroup(this.storedGroups, group);
                        this.emitChanges();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ManageAleGroupsComponent.prototype.onCancel = function (group) {
        if (group.groupId == null) {
            this.deleteGroup(this.groups, group);
        }
        this.toggleEdit(group, false, true);
    };
    ManageAleGroupsComponent.prototype.onInputGroupName = function (model, group) {
        var groupName = _.trim(_.toLower(group.groupName));
        var groupViews = _.filter(this.groups, function (g) { return _.trim(_.toLower(g.groupName)) === groupName; });
        if (_.size(groupViews) > 1) {
            model.control.setErrors({ 'uniqueName': true });
            this.groupNameIsValid = false;
        }
        else {
            var errors = model.control.errors || {};
            delete errors['uniqueName'];
            model.control.setErrors(errors);
            this.groupNameIsValid = _.size(errors) === 0;
        }
    };
    ManageAleGroupsComponent.prototype.deleteGroup = function (groups, group) {
        var index = this.findIndex(groups, group);
        if (index !== -1) {
            groups.splice(index, 1);
        }
        return index;
    };
    ManageAleGroupsComponent.prototype.toggleEdit = function (group, isEditing, isRestore) {
        if (isRestore === void 0) { isRestore = false; }
        if (isEditing) {
            this.editingGroup = _.cloneDeep(group);
            group.isEditing = true;
            return;
        }
        if (isRestore) {
            group.groupName = this.editingGroup.groupName;
        }
        group.isEditing = false;
        this.editingGroup = null;
    };
    ManageAleGroupsComponent.prototype.onDragAdd = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.movedEmployer = event.dataItem;
                return [2 /*return*/];
            });
        });
    };
    ManageAleGroupsComponent.prototype.onDragStart = function (group) {
        this.movedFrom = group;
    };
    ManageAleGroupsComponent.prototype.onDragEnd = function (movedTo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (this.canDragNDropGroups() && this.movedEmployer) {
                    this.isGroupChanges.emit(false);
                    this.manService.assignGroupsToEmployers(movedTo, this.movedEmployer);
                    if (this.movedFrom && this.movedFrom.employers && this.movedFrom.employers.length > 0) {
                        this.movedFrom.disabled = true;
                    }
                    this.applyChangesForStoredGroups(this.movedFrom, movedTo, this.movedEmployer);
                    this.emitChanges();
                }
                return [2 /*return*/];
            });
        });
    };
    ManageAleGroupsComponent.prototype.canDragNDropGroups = function () {
        return !this.isEditing;
    };
    ManageAleGroupsComponent.prototype.canRemoveGroup = function (group) {
        return !this.isEditing && group.employers.length === 0;
    };
    ManageAleGroupsComponent.prototype.canEditGroup = function (group) {
        return !this.isEditing && !group.isEditing;
    };
    ManageAleGroupsComponent.prototype.saveGroupAssignments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manService.saveGroupAssignments()];
                    case 1:
                        _a.sent();
                        this.isGroupChanges.emit(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageAleGroupsComponent.prototype.applyChangesForStoredGroups = function (movedFrom, movedTo, emp) {
        var employer = _.cloneDeep(emp);
        var movedFromIndex = this.findIndex(this.storedGroups, movedFrom);
        if (movedFromIndex !== -1) {
            _.remove(this.storedGroups[movedFromIndex].employers, function (e) { return e.companyId === employer.companyId; });
        }
    };
    ManageAleGroupsComponent.prototype.findIndex = function (groups, group) {
        return _.findIndex(groups, function (g) { return g.groupId === group.groupId || g.groupName === group.groupName; });
    };
    ManageAleGroupsComponent.prototype.emitChanges = function () {
        this.onHasChanges.emit(true);
    };
    ManageAleGroupsComponent.prototype.onFilterChanged = function () {
        this.groups = this.manService.filteringGroups(this.storedGroups, this.searchText);
    };
    ManageAleGroupsComponent.prototype.onResetSearch = function () {
        if (this.searchText.length > 0) {
            this.searchText = '';
            this.onFilterChanged();
        }
    };
    return ManageAleGroupsComponent;
}());
export { ManageAleGroupsComponent };
