<div class="flex header">
  <div class="checkbox-drp-dwn" [ngClass]="{
    'checkbox-drp-dwn-expanded':isExpanded,
    'checkbox-drp-dwn-unexpanded': !isExpanded
  }">
    <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="isAllSelected"
            (ngModelChange)="onToggleAllSelected($event, 'All')"
          ></slx-checkbox-button>
    <slx-action-list class="select-dropdwn">
      <span [popper]="detailsContent"
        [popperTrigger]="'click'"
        [popperPlacement]="'bottom-start'"
        [popperDisableStyle]="'true'">
        <em class="fas fa-caret-down" aria-hidden="true"></em>
      </span>
      <popper-content #detailsContent>
        <slx-action-list-item class="slx-action-list-text" (onClick)="onToggelSelected('All')">All</slx-action-list-item>
        <slx-action-list-item class="slx-action-list-text" (onClick)="onToggelSelected('None')">None</slx-action-list-item>
        <slx-action-list-item class="slx-action-list-text" (onClick)="onToggelSelected('Read')">Read</slx-action-list-item>
        <slx-action-list-item class="slx-action-list-text" (onClick)="onToggelSelected('Unread')">Unread</slx-action-list-item>
      </popper-content>
    </slx-action-list>
  </div>
  <div class="search-main" [ngClass]="{
    'search-main-expanded':isExpanded,
    'search-main-unexpanded': !isExpanded
  }">
    <div>
      <label>Search</label>
    </div>
    <div class="search-container flex" [ngClass]="{
      'search-container-expanded':isExpanded
    }">
      <input type="text" #searchBar id="searchBar" [(ngModel)]="searchText" placeholder="Search messages" name="search2"
        (keyup)="onsearchType(searchBar.value)" />
      <button type="submit" (click)="onFilter(searchBar.value)">
        <em class="fa fa-search"></em>
      </button>
    </div>
  </div>
  <div class="sms-dropdown" *ngIf="!isExpanded">
    <slx-action-list class="headder-action-drop-btn filter-drp">
      <div><label>Sort By</label></div>
      <slx-action-button [dropDownIcon]="'fas fa-caret-down'" [popperContent]="popperContent"
        [popperPosition]="'bottom-start'" class="sorty-by-action-btn sms-dropdown-width">{{selectedSortFilter}}</slx-action-button>
      <popper-content #popperContent>
        <slx-action-list-item (onClick)="messageSort('Newest To Oldest')">Newest To Oldest</slx-action-list-item>
        <slx-action-list-item (onClick)="messageSort('Oldest To Newest')">Oldest To Newest</slx-action-list-item>
        <slx-action-list-item (onClick)="messageSort('Name A to Z')">Name A to Z</slx-action-list-item>
        <slx-action-list-item (onClick)="messageSort('Name Z to A')">Name Z to A</slx-action-list-item>
      </popper-content>
    </slx-action-list>
  </div>
</div>

<slx-spinner [show]="isLoading">
  <div class="slx-sms-box" id="msg-list">
    <kendo-grid #kendoGrid (scrollBottom)="onScroll()" [height]="465" class="slx-msg-grid msg-center-screen"
      [data]="gridState?.view">
      <kendo-grid-column>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="emp-msg-list" id="emp-msg-list">
          <div class="msg-list" *ngIf="dataItem === 'lessthanmonth' && lessThanOneMonth.length > 0">
            <slx-messages-grid [selectedId]="selectedId" [orgLevelId]="orgLevelId" [isExpanded]="isExpanded" [isArchive]="IsArchive" (updateMsgListSelectedMessageIds)="updateMsgListSelectedMessageIds($event)" [searchText]="searchInput" [messagesList]="lessThanOneMonth" (sendUnselectedEmployee)="getUnSelectedemployess($event)" [isMyMessage]="isMyMessage" >
            </slx-messages-grid>
          </div>

          <div class="report-section white" *ngIf="((dataItem === 'olderthanonemonth' && sectionTypesMap['olderthanonemonth'].totalCount > 0)
            || (dataItem === 'olderthanthreemonth' && sectionTypesMap['olderthanthreemonth'].totalCount > 0) || (dataItem === 'loadolderThan6Month') && sectionTypesMap['loadolderThan6Month'].totalCount > 0)" >
            <div class="report-section-header">
              <div class="report-section-header-content">
                <div class="header-item header-title">
                  <span class="header-title__text">
                    {{ getAccordianlevel(dataItem)}} </span>
                </div>
              </div>
              <div class="report-section-header-button-right">
                <slx-collapse-button [isCollapsed]="this.sectionTypesMap[dataItem].isCollapsed" className="slx-with-icon"
                  (isCollapsedChange)="collapsedChange($event, dataItem)" [slxCollapseByCss]="collapseContent1">
                </slx-collapse-button>
              </div>
            </div>

            <div #collapseContent1 class="report-section-content">
              <slx-messages-grid [selectedId]="selectedId" [orgLevelId]="orgLevelId" [searchText]="searchInput" [isArchive]="IsArchive" [isExpanded]="isExpanded"
                (updateMsgListSelectedMessageIds)="updateMsgListSelectedMessageIds($event)" *ngIf="dataItem === 'olderthanonemonth'"
                [messagesList]="olderThanOneMonth" (sendUnselectedEmployee)="getUnSelectedemployess($event)" [isMyMessage]="isMyMessage"></slx-messages-grid>
              <slx-messages-grid [selectedId]="selectedId" [orgLevelId]="orgLevelId" [searchText]="searchInput" [isArchive]="IsArchive" [isExpanded]="isExpanded"
                (updateMsgListSelectedMessageIds)="updateMsgListSelectedMessageIds($event)" *ngIf="dataItem === 'olderthanthreemonth'"
                [messagesList]="olderThanThreeMonth" (sendUnselectedEmployee)="getUnSelectedemployess($event)" [isMyMessage]="isMyMessage"></slx-messages-grid>
              <slx-messages-grid [selectedId]="selectedId" [orgLevelId]="orgLevelId" [searchText]="searchInput" [isArchive]="IsArchive" [isExpanded]="isExpanded"
                (updateMsgListSelectedMessageIds)="updateMsgListSelectedMessageIds($event)" *ngIf="dataItem === 'loadolderThan6Month'"
                [messagesList]="olderThanSixMonth" (sendUnselectedEmployee)="getUnSelectedemployess($event)" [isMyMessage]="isMyMessage"></slx-messages-grid>
            </div>
          </div>          
        </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>

  </div>
</slx-spinner>
