/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./geolocation-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/static-map/static-map.component.ngfactory";
import * as i3 from "../../../../common/components/static-map/static-map.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./geolocation-dialog.component";
import * as i6 from "../../../models/geolocation/geolocation";
import * as i7 from "../../../../common/models/dialog-options";
import * as i8 from "../../../../common/services/modal/modal.service";
var styles_GeolocationDialogComponent = [i0.styles];
var RenderType_GeolocationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeolocationDialogComponent, data: {} });
export { RenderType_GeolocationDialogComponent as RenderType_GeolocationDialogComponent };
export function View_GeolocationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "geo-punch-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-static-map", [], null, null, null, i2.View_StaticMapComponent_0, i2.RenderType_StaticMapComponent)), i1.ɵdid(4, 245760, null, 0, i3.StaticMapComponent, [i4.DomSanitizer], { latitude: [0, "latitude"], longitude: [1, "longitude"], mapWidth: [2, "mapWidth"], mapHeight: [3, "mapHeight"], mapZoom: [4, "mapZoom"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.geolocationData.latitude; var currVal_1 = _co.geolocationData.longitude; var currVal_2 = _co.geolocationData.mapWidth; var currVal_3 = _co.geolocationData.mapHeight; var currVal_4 = _co.geolocationData.mapZoom; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_GeolocationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-geolocation-dialog", [], null, null, null, View_GeolocationDialogComponent_0, RenderType_GeolocationDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.GeolocationDialogComponent, [i6.GeolocationDialogOptions, i7.DialogOptions, i8.ModalService], null, null)], null, null); }
var GeolocationDialogComponentNgFactory = i1.ɵccf("slx-geolocation-dialog", i5.GeolocationDialogComponent, View_GeolocationDialogComponent_Host_0, {}, {}, []);
export { GeolocationDialogComponentNgFactory as GeolocationDialogComponentNgFactory };
