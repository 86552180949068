<slx-spinner [show]="loadingReview || loadingEmployees" novalidate>
  <div class="modal-body">
    <slx-employee-search-field *ngIf="!hasEmployee"
      [orgLevelId]="orgLevel?.id"
      [instructions]="'To create new review, begin by finding an employee. Use the employee\'s name to search and select an employee to begin.'"
      (loadStatusChange)="onSearchLoadStatusChange($event)"
      (employeeSelected)="onEmployeeSelected($event)"
    ></slx-employee-search-field>

    <slx-pm-review-form *ngIf="hasEmployee"></slx-pm-review-form>
    
    <div *ngIf="hasError" class="error-box flex flex-direction__column justify-content__center">
      <i class="fal fa-exclamation-triangle error-box__icon" aria-hidden="true"></i>
      <p class="error-box__text">{{ errorMessage }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" *ngIf="!hasEmployee" (click)="onCancel()" class="theme-button-cancel">Close</button>
    <p *ngIf="hasEmployee" class="flex justify-content__flex-end modal-buttons">
      <button type="button" class="theme-button-cancel margin-r" (click)="onCancel()">Cancel</button>
      <button *ngIf="!isEditMode && !isComplete" [disabled]="!canEdit" type="button" (click)="onEdit()" class="theme-button-apply">Edit</button>
      <button *ngIf="isEditMode"
        type="button"
        class="theme-button-apply"
        (click)="onSave()"
        [disabled]="hasError || !formValid || !hasChanges"
      >{{ isNew ? 'Save' : 'Update' }}</button>
    </p>
  </div>
</slx-spinner>
