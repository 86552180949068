<slx-spinner [show]="isLoading">
  <div class="operational-console-dialog">
    <slx-generic-grid [genericGridConfig]="gridConfig" [gridKey]="parameters.field.colName"
                      [disableEmployeeLink]="isCustomMode" (employeeLinkClick)="onEmployeeLinkClick($event)">
      <div class="operational-console-dialog__actions">
        <p class="operational-console-dialog__header">{{ parameters.orgLevel.name }}</p>
        <slx-actions-list>
          <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">
            Actions <span class="caret"></span>
          </slx-actions-button>
          <popper-content #popperContent>
            <slx-actions-list-item (onClick)="onExportToExcel()">Export to Excel</slx-actions-list-item>
            <slx-actions-list-item (onClick)="onExportToPdf()">Export to PDF</slx-actions-list-item>
          </popper-content>
        </slx-actions-list>
      </div>
    </slx-generic-grid>
  </div>
</slx-spinner>
