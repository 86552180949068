import { ApplicationContainerComponent } from './components/index';
import { OrgLevelResolver } from './services/index';
import { ConfigurePartnerPositionsComponent } from '../configuration/components';
import { CanLeaveGuard } from '../common/services/index';
var ɵ0 = { componentId: 'intg_position_mapping', accessContext: 'OrgLevel' }, ɵ1 = { componentId: 'intg_position_mapping', accessContext: 'OrgLevel' };
export var intgRoutes = [
    {
        path: '',
        component: ApplicationContainerComponent,
        children: [
            {
                path: '',
                resolve: [OrgLevelResolver],
                component: ConfigurePartnerPositionsComponent,
                data: ɵ0
            },
            {
                path: 'intg_position_mapping',
                resolve: [OrgLevelResolver],
                canDeactivate: [CanLeaveGuard],
                component: ConfigurePartnerPositionsComponent,
                data: ɵ1
            },
        ]
    },
];
export { ɵ0, ɵ1 };
