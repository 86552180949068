
import { IndividualTimecardsDay, TimecardsEarning, TimecardsEarningPayRule } from './index';
import { ShiftDiffPolicy, PayPolicy, PayRuleDefinition } from '../../../organization/models/index';
import { Dictionary } from 'lodash';
import * as _ from 'lodash';

export class IndividualTimecardFlatRecord {
  public day: IndividualTimecardsDay;
  public earning: TimecardsEarning;
  public rulesMap: Dictionary<TimecardsEarningPayRule[]>;
  public productiveHours: number;
  public nonProductiveHours: number;
  public totalHours: number;
  public regularHours: number;
  public regularPay: number;
  public shiftDiffPolicy: ShiftDiffPolicy;
  public payPolicy: PayPolicy;
  public overtimeAndExtraHours: number;
  public overtimeAndExtraPay: number;
  public totalOtherPay: number;
  public overTimePay: number;
  public totalAbsencePay: number;
  public totalPay: number;
  public isFirst: boolean;
  public isError: boolean;
  public isNoShow: boolean;
  public isInvalidPunch: boolean;
  public isInvalidLogin: boolean;
  public empOrganization: string;
  public isLocked: boolean;
  public isEdited: boolean;

  public get warningMessage(): string {
    if (this.day) {
      return this.day.warningMessage;
    }
    return '';
  }

  public get costCenterCode(): string {
    if (!this.earning) return null;
    return this.earning.costCenterCode;
  }

  public get organizationName(): string {
    if (this.earning && this.earning.organization) {
      return this.earning.organization.name;
    }

    return this.empOrganization;
  }

  public get positionName(): string {
    if (this.earning) {
      return this.earning.position.name;
    }
    if (this.day) {
      return this.day.positionName;
    }
    return null;
  }

  public get shiftCode(): number {
    if (this.earning) {
      return this.earning.shiftCode;
    }
    if (this.day) {
      return this.day.shiftCode;
    }
    return null;
  }

  public get payRate(): number {
    if (this.earning) {
      return this.earning.payRate;
    }
    if (this.day) {
      return this.day.standardPayRate;
    }
    return null;
  }

  public get shiftDiffPolicyName(): string {
    if (this.shiftDiffPolicy) {
      return this.shiftDiffPolicy.name;
    }
    return null;
  }

  public get payPolicyName(): string {
    if (this.payPolicy) {
      return this.payPolicy.name;
    }
    return null;
  }

  public getRuleValue(name: string): any {
    let rules: TimecardsEarningPayRule[] = this.rulesMap[name];
    if (rules) {
      return _.sumBy(rules, r=> r.value ? r.value : 0 )
    }
    return null;
  }

  public isMoneyRule(name: string): boolean {
    const rules: TimecardsEarningPayRule[] = this.rulesMap[name];
    return rules && _.first(rules).isMoneyAmountRule;
  }
}
