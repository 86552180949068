import { IPayrollExportFacility, PayrollExportFacility } from './payroll-export-facility';

export interface IPayrollExportGroup {
  groupOrgCount: number;
  name: string;
  requestStatus: number;
  releaseStatus: number;
  facilities: IPayrollExportFacility[];
  exportDate: string;
  isExported: boolean;
  canCancelRelease: boolean;
  canReleaseToPayroll: boolean;
}

export class PayrollExportGroup {
  public name: string;
  public requestStatus: number;
  public releaseStatus: number;
  public facilities: PayrollExportFacility[];
  public exportDate: Date;
  public isExported: boolean;
  public canCancelRelease: boolean;
  public canReleaseToPayroll: boolean;
  public isAllFacilitiesRequested: boolean;
  public isAllFacilitiesReleased: boolean;
  public groupOrgCount: number;
}
