import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';

@Injectable()
export class LocalStorageService {
  constructor() {
    Assert.isNotNull(localStorage, 'localStorage', 'Cannot use LocalStorageService because "{0}" not supported by browser.');
  }

  public set(key: string, value?: any): void {
    Assert.isNotNull(key, 'key');

    let serializedValue: string = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  }

  public remove(key: string): void {
    Assert.isNotNull(key, 'key');

    localStorage.removeItem(key);
  }

  public get<T>(key: string): T {
    Assert.isNotNull(key, 'key');

    let value: T = undefined;

    if (this.has(key)) {
      let serializedValue: string = localStorage.getItem(key);
      value = JSON.parse(serializedValue);
    }

    return value;
  }

  public has(key: string): boolean {
    Assert.isNotNull(key, 'key');

    let value: any = localStorage.getItem(key);
    let isExists: boolean = !_.isUndefined(value) && !_.isNull(value);

    return isExists;
  }
}
