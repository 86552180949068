import { EmployeeSectionsFutureUpdates, IEmployeeSectionsFutureUpdates } from './employee-sections-future-updates';
import { IEmployeeSectionsAuditTrail, EmployeeSectionsAuditTrail } from './employee-sections-audittrail';
import { IEmployeeSectionsSubsectionContainer } from '../index';

export interface IEmployeeSectionsAudit {
  futureUpdates: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsFutureUpdates>;
  auditTrail: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsAuditTrail>;
}

export class EmployeeSectionsAudit  {
  public static dataMock: IEmployeeSectionsAudit = {
    futureUpdates: {
      data: EmployeeSectionsFutureUpdates.dataMock,
      metadata: null,
    },
    auditTrail: {
      data: EmployeeSectionsAuditTrail.dataMock,
      metadata: null,
    }
  };
  public futureUpdates: EmployeeSectionsFutureUpdates;
  public auditTrail: EmployeeSectionsAuditTrail;
}
