/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/directives/navigation-link/schedule-entry-link.directive";
import * as i3 from "@angular/router";
import * as i4 from "angular2-moment/date-format.pipe";
import * as i5 from "../../../directives/employee-thumbinal-src/employee-thumbinal-src.directive";
import * as i6 from "../../../services/employee/employee-images.service";
import * as i7 from "../../../../common/directives/navigation-link/employee-section-link.directive";
import * as i8 from "@angular/common";
import * as i9 from "../../../../common/directives/navigation-link/daily-timecards-link.directive";
import * as i10 from "./employee-details.component";
var styles_EmployeeDetailsComponent = [i0.styles];
var RenderType_EmployeeDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeDetailsComponent, data: {} });
export { RenderType_EmployeeDetailsComponent as RenderType_EmployeeDetailsComponent };
function View_EmployeeDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "button-link"], ["scheduleEntryLink", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ScheduleEntryLinkDirective, [i3.Router, i3.ActivatedRoute], { employeeId: [0, "employeeId"], dateOn: [1, "dateOn"] }, null), (_l()(), i1.ɵted(3, null, [" Shift: ", "-", " "])), i1.ɵppd(4, 2), i1.ɵppd(5, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.employeeDetails.employee.id; var currVal_1 = _co.dateOn; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.employeeDetails.scheduleStart, _co.appConfig.timeFormat)); var currVal_3 = i1.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _co.employeeDetails.scheduleEnd, _co.appConfig.timeFormat)); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_EmployeeDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DateFormatPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 23, "div", [["class", "details-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "emp-photo-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "img", [["alt", "Employee Photo"], ["class", "box-image"]], null, null, null, null, null)), i1.ɵdid(5, 540672, null, 0, i5.EmployeeThumbinalSrcDirective, [i1.ElementRef, i1.Renderer, i6.EmployeeImagesService], { employeeThumbinalSrc: [0, "employeeThumbinalSrc"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 18, "div", [["class", "col-lg-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "button-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i7.EmployeeSectionLinkDirective, [i3.Router, i3.ActivatedRoute], { employeeSectionLink: [0, "employeeSectionLink"] }, null), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeeDetailsComponent_1)), i1.ɵdid(14, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["class", "button-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i9.DailyTimecardsLinkDirective, [i3.Router, i3.ActivatedRoute], { dailyTimecardsLink: [0, "dailyTimecardsLink"], timecardDate: [1, "timecardDate"] }, null), (_l()(), i1.ɵted(18, null, [" Punches: ", "-", " "])), i1.ɵppd(19, 2), i1.ɵppd(20, 2), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, [" Phone: ", " "])), (_l()(), i1.ɵeld(23, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(24, null, [" Mobile: ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.employeeDetails.employee.id; _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.employeeDetails == null) ? null : ((_co.employeeDetails.employee == null) ? null : _co.employeeDetails.employee.id)); _ck(_v, 9, 0, currVal_1); var currVal_4 = (_co.employeeDetails.scheduleStart || _co.employeeDetails.scheduleEnd); _ck(_v, 14, 0, currVal_4); var currVal_5 = ((_co.employeeDetails.employee == null) ? null : _co.employeeDetails.employee.id); var currVal_6 = (_co.employeeDetails.actualStart ? _co.employeeDetails.actualStart : _co.dateOn); _ck(_v, 17, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.employeeDetails == null) ? null : ((_co.employeeDetails.employee == null) ? null : _co.employeeDetails.employee.name)); _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.employeeDetails.position.name; _ck(_v, 12, 0, currVal_3); var currVal_7 = i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v, 0), _co.employeeDetails.actualStart, _co.appConfig.timeFormat)); var currVal_8 = i1.ɵunv(_v, 18, 1, _ck(_v, 20, 0, i1.ɵnov(_v, 0), _co.employeeDetails.actualEnd, _co.appConfig.timeFormat)); _ck(_v, 18, 0, currVal_7, currVal_8); var currVal_9 = _co.employeeDetails.employee.phoneNumber; _ck(_v, 22, 0, currVal_9); var currVal_10 = _co.employeeDetails.employee.mobilePhoneNumber; _ck(_v, 24, 0, currVal_10); }); }
export function View_EmployeeDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-details", [], null, null, null, View_EmployeeDetailsComponent_0, RenderType_EmployeeDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i10.EmployeeDetailsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeDetailsComponentNgFactory = i1.ɵccf("slx-employee-details", i10.EmployeeDetailsComponent, View_EmployeeDetailsComponent_Host_0, { employeeDetails: "employeeDetails", dateOn: "dateOn" }, {}, []);
export { EmployeeDetailsComponentNgFactory as EmployeeDetailsComponentNgFactory };
