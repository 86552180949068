import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { ControlValueAccessorBase } from '../../../core/models/index';
import { appConfig } from '../../../app.config';
var ScheduleCycleCalendarComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ScheduleCycleCalendarComponent, _super);
    function ScheduleCycleCalendarComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.m_cycles_start = {};
        _this.m_cycles_end = {};
        return _this;
    }
    Object.defineProperty(ScheduleCycleCalendarComponent.prototype, "cycles", {
        set: function (value) {
            var _this = this;
            this.m_cycles = value;
            this.m_cycles_start = {};
            this.m_cycles_end = {};
            _.forEach(value, function (c) {
                _this.m_cycles_start[moment(c.startDate).startOf('day').format(appConfig.dateFormat)] = c;
                _this.m_cycles_end[moment(c.endDate).startOf('day').format(appConfig.dateFormat)] = c;
            });
        },
        enumerable: true,
        configurable: true
    });
    ScheduleCycleCalendarComponent.prototype.isCurrent = function (date) {
        return moment(date).isSame(moment().startOf('day'));
    };
    ScheduleCycleCalendarComponent.prototype.isSelected = function (date) {
        return moment(date).isSame(this.currentDate);
    };
    ScheduleCycleCalendarComponent.prototype.isStartOfPayCycle = function (date) {
        return !!this.m_cycles_start[moment(date).startOf('day').format(appConfig.dateFormat)];
    };
    ScheduleCycleCalendarComponent.prototype.isEndOfPayCycle = function (date) {
        return !!this.m_cycles_end[moment(date).startOf('day').format(appConfig.dateFormat)];
    };
    Object.defineProperty(ScheduleCycleCalendarComponent.prototype, "currentDate", {
        get: function () {
            return this.m_currentDate;
        },
        set: function (value) {
            if (_.isDate(value) && !moment(this.m_currentDate).isSame(value)) {
                this.m_currentDate = value;
                this.onChangeCallback(this.m_currentDate);
            }
        },
        enumerable: true,
        configurable: true
    });
    ScheduleCycleCalendarComponent.prototype.writeValue = function (value) {
        if (!_.isUndefined(value) && !_.isNull(value)) {
            this.m_currentDate = value;
        }
    };
    return ScheduleCycleCalendarComponent;
}(ControlValueAccessorBase));
export { ScheduleCycleCalendarComponent };
