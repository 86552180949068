import * as tslib_1 from "tslib";
import { LookupEntity } from './lookup-entity';
var LocationUnit = /** @class */ (function (_super) {
    tslib_1.__extends(LocationUnit, _super);
    function LocationUnit() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return LocationUnit;
}(LookupEntity));
export { LocationUnit };
