import {
  IDepartment,
  Department,
  Position,
  IPosition
} from '../../../organization/models/index';
import { IEmployeeDefinition, EmployeeDefinition } from '../../../organization/models/employee/index';
import {
  IShiftPhoneCall, ShiftPhoneCall,
  ShiftAppNotificationResponse, IShiftAppNotificationResponse,
  ShiftSmsNotificationResponse, IShiftSmsNotificationResponse
} from './index';

export interface IShiftEligibleEmployee {
  employee: IEmployeeDefinition;
  position: IPosition;
  department: IDepartment;
  acaType: string;
  acaAvgHours: number;
  isAgency: boolean;
  isAvailable: boolean;
  isWorkingOtherShift: boolean;
  isDayOff: boolean;
  union: string;
  weeklyScheduledHours: number;
  projectedHours: number;
  weeklyWorkedHours: number;
  weeklyProjectedHours: number;
  payRate: string;
  homePhone: string;
  mobilePhone: string;
  altPhone: string;
  approachingOT: boolean;

  isPhoneCalled: boolean;
  isSmsSent: boolean;
  isAppNotificationSent: boolean;

  phoneCall: IShiftPhoneCall;
  smsResponse: IShiftSmsNotificationResponse;
  appResponse: IShiftAppNotificationResponse;

  empOptIn: number;
  canSendSms: boolean;
  scheduledShifts: IScheduledShift[];
}

export class ShiftEligibleEmployee {

  public employee: EmployeeDefinition;
  public position: Position;
  public department: Department;
  public acaType: string;
  public acaAvgHours: number;
  public isAgency: boolean;
  public isAvailable: boolean;
  public isWorkingOtherShift: boolean;
  public isDayOff: boolean;
  public union: string;
  public weeklyScheduledHours: number;
  public projectedHours: number;
  public weeklyWorkedHours: number;
  public weeklyProjectedHours: number;
  public payRate: string;
  public homePhone: string;
  public mobilePhone: string;
  public altPhone: string;
  public approachingOT: boolean;

  public isPhoneCalled: boolean;
  public isSmsSent: boolean;
  public isAppNotificationSent: boolean;

  public phoneCall: ShiftPhoneCall;
  public smsResponse: ShiftSmsNotificationResponse;
  public appResponse: ShiftAppNotificationResponse;

  public isSelected: boolean;
  public isChecked: boolean;
  public canReceiveSms: boolean;
  public isAnyResponse: boolean;

  public empOptIn: number;
  public canSendSms: boolean;

  public scheduledShifts: ScheduledShift[];
}


export interface IScheduledShift {
  empId: number;
  jobCode: number;
  jobDescription: string;
  shiftId: number;
  shiftDescription: string;
  unitId: number;
  unitDescription: string;
  workDate: string;
  shiftStartTime: string;
  shiftEndTime: string;
  shiftTotalHours: number;
}

export class ScheduledShift {
  public empId: number;
  public jobCode: number;
  public jobDescription: string;
  public shiftId: number;
  public shiftDescription: string;
  public unitId: number;
  public unitDescription: string;
  public workDate: string;
  public shiftStartTime: string;
  public shiftEndTime: string;
  public shiftTotalHours: number;
}

