export class TotalsDescription {
  public static totalPARLevels = 'PAR Levels / Ideal Schedule';
  public static totalBudgetedPars = 'Budgeted PARs';
  public static totalOpenShifts = 'Open Shifts';
  public static totalDifference = 'Difference';
  public static totalPPD = 'PPD';
  public static totalCensus = 'Census';
  public static totalStaffingRatio = 'Staffing Ratio';
  public static totalActualHours = 'Actual Hours';
  public static totalActualPPD = 'Actual PPD';
  public static totalTotals = 'Total';
  public static totalSubtotals = 'Subtotals';
  public static viewTotalsFTEs = 'View Totals as FTEs';
  public static getTooltip(name: string): string {
    switch (name) {
      case TotalsDescription.totalSubtotals:
        return 'Subtotal of Hours / FTEs excluding constraints with a count of 0';
      case TotalsDescription.totalTotals:
        return 'Total of Hours / FTEs excluding constraints with a count of 0';
      case TotalsDescription.totalPARLevels:
        return 'Sum of Ideal Schedule hours configured for each position or department';
      case TotalsDescription.totalOpenShifts:
        return 'Count of remaining open shifts';
      case TotalsDescription.totalDifference:
        return 'Difference in hours between the Schedule and Ideal Schedule';
      case TotalsDescription.totalCensus:
        return 'Census value entered / imported into the system';
      case TotalsDescription.totalPPD:
        return 'Per Patient Day (Total Hours / Census)';
      case TotalsDescription.totalStaffingRatio:
        return ' Inverse of PPD calculation (Census / Total Hours)';
      case TotalsDescription.totalActualHours:
        return 'Hours calculated from the employee punches in Time and Attendance';
      case TotalsDescription.totalActualPPD:
        return 'Actual Per Patient Day calculated from the employee punches in Time and Attendance and census value';
      case TotalsDescription.totalBudgetedPars:
        return 'Budgeted PARs';
      case TotalsDescription.viewTotalsFTEs:
        return 'View Totals as FTEs';
      default:
        return '';
    }
  }
}
