import { Component, OnInit, ViewChild } from '@angular/core';
import { ShiftGroupManagementService } from './../../../../../app/configuration/services/shift-group/shift-group-management.service';
import { ShiftGroupConfigContainer } from './../../../../../app/configuration/models/shift-group/shift-group-config-container';
import { appConfig, IApplicationConfig } from './../../../../../app/app.config';
import { ConfigurationComponentHelper } from './../../../../../app/configuration/utils/configuration-component-helper';
import { ShiftGroup } from './../../../../../app/configuration/models';
import { KendoGridCustomSelectionHelper, KendoGridStateHelper } from './../../../../../app/common';
import { OrgLevel } from './../../../../../app/state-model/models';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { NgForm } from '@angular/forms';
import { unsubscribe } from './../../../../../app/core/decorators';
import { Subscription } from 'rxjs';
import { dateTimeUtils } from './../../../../../app/common/utils';
import * as _ from 'lodash';

@Component({
  selector: 'slx-shift-group-grid',
  templateUrl: './shift-group-grid.component.html',
  styleUrls: ['./shift-group-grid.component.scss']
})
export class ShiftGroupGridComponent implements OnInit {

  public readonly columnsGroupName: string = 'ConfigureShiftGroup';

  public get container(): ShiftGroupConfigContainer {
    return this.m_container;
  }

  public state: {
    isLoading: boolean;
    bulkEditMode: boolean;
    canBulkEdit: boolean;
    canEdit: boolean;
    canAdd: boolean;
    canDelete: boolean;
  };

  public appConfig: IApplicationConfig;

  public crudHelper: ConfigurationComponentHelper<ShiftGroup>;
  public gridState: KendoGridStateHelper<ShiftGroup>;
  public selectionHelper: KendoGridCustomSelectionHelper<ShiftGroup>;

  public currentOrgLevel: OrgLevel;
  public notDepartment: boolean;

  private m_container: ShiftGroupConfigContainer;

  @ViewChild('kendoGrid', { static: true })
  private set grid(value: GridComponent) {
    if (this.crudHelper) this.crudHelper.grid = value;
    this.m_grid = value;
  }
  private get grid(): GridComponent {
    return this.m_grid;
  }
  private m_grid: GridComponent;

  @ViewChild('templateForm', { static: true })
  private mainForm: NgForm;

  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private removeSubscription: Subscription;
  @unsubscribe()
  private gridSelectSubscription: Subscription;
  @unsubscribe()
  private savedSubscription: Subscription;
  @unsubscribe()
  private removeStartSubscription: Subscription;
  @unsubscribe()
  private defaultsSubscription: Subscription;
  @unsubscribe()
  private mainFormSubscription: Subscription;
  @unsubscribe()
  private editSubscription: Subscription;

  constructor(public management: ShiftGroupManagementService) {

    this.stateSubscription = this.management.onStateChanged$.subscribe((state: { isLoading: boolean }) => {
      if (_.has(state, 'isLoading')) this.state.isLoading = state.isLoading;
    });

    this.gridState = new KendoGridStateHelper<ShiftGroup>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

    this.crudHelper = new ConfigurationComponentHelper<ShiftGroup>();
    this.crudHelper.gridState = this.gridState;
    this.crudHelper.selectionHelper = this.selectionHelper;
    this.crudHelper.management = management;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.state = {
      isLoading: false,
      bulkEditMode: false,
      canBulkEdit: true,
      canEdit: true,
      canAdd: true,
      canDelete: true
    };

    this.crudHelper.grid = this.grid;
    this.crudHelper.init();
  }

  public rowCallback(context: RowClassArgs): any {
    if (context.dataItem.isDirty) {
      return { 'dirty-item': true };
    }
    return {};
  }

  public onShiftGroupDateChanged(shiftGroup: ShiftGroup) {
    let hours: number = dateTimeUtils.getDurationDiffHours(shiftGroup.start, shiftGroup.end);
    shiftGroup.shiftGroupWorkHours = hours;
  }

}
