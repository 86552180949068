export * from './so-dashboard/so-dashboard.component';
export * from './ta-dashboard/ta-dashboard.component';
export * from './applications-dashboard.component';
export * from './leave-management-dashboard/leave-management-dashboard.component';
export * from './favorite-item/favorite-item.component';
export * from '../applications-dashboard/applications-config-dialog/applications-config-dialog.component';
export * from '../applications-dashboard/applications-config/applications-config.component';

import { SoDashboardComponent } from './so-dashboard/so-dashboard.component';
import { TaDashboardComponent } from './ta-dashboard/ta-dashboard.component';
import { ApplicationsDashboardComponent } from './applications-dashboard.component';
import { LeaveManagementDashboardComponent } from './leave-management-dashboard/leave-management-dashboard.component';
import { FavoriteItemComponent } from './favorite-item/favorite-item.component';
import { ApplicationsConfigDialogComponent } from '../applications-dashboard/applications-config-dialog/applications-config-dialog.component';
import { ApplicationsConfigComponent } from '../applications-dashboard/applications-config/applications-config.component';

export const dashboardComponents: any[] = [
  SoDashboardComponent,
  TaDashboardComponent,
  ApplicationsDashboardComponent,
  LeaveManagementDashboardComponent,
  FavoriteItemComponent,
  ApplicationsConfigDialogComponent,
  ApplicationsConfigComponent
];
