import { Injectable } from "@angular/core";
import { HttpRequest, HttpResponse, HttpInterceptor, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType } from "@angular/common/http";
import { delay } from "rxjs/operators";
import { Observable, of, concat } from "rxjs";

@Injectable()
export class UploadInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url === 'saveUrl') {
            const events: Observable<HttpEvent<any>>[] = [0, 30, 60, 100].map((x) => of(<HttpProgressEvent>{
                type: HttpEventType.UploadProgress,
                loaded: x,
                total: 100
            }).pipe(delay(500)));

            const success = of(new HttpResponse({ status: 200 })).pipe(delay(500));
            events.push(success);

            return concat(...events);
        }

        if (req.url === 'removeUrl') {
            return of(new HttpResponse({ status: 200 }));
        }

        return next.handle(req);
    }
}