import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { KendoGridStateHelper } from '../../../../common/index';
import { IdealScheduleApiService } from '../../../services/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
var IdealScheduleOverviewComponent = /** @class */ (function () {
    function IdealScheduleOverviewComponent(api) {
        this.api = api;
        this.gridState = new KendoGridStateHelper();
        this.isLoading = true;
        this.gridState.state.group = [{ field: 'period' }];
    }
    IdealScheduleOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && _.isNumber(orgLevel.id)) {
                _this.isLoading = true;
                _this.orgLevel = orgLevel;
                _this.loadOverview();
            }
        });
    };
    IdealScheduleOverviewComponent.prototype.loadOverview = function () {
        var _this = this;
        this.api.getIdealScheduleOverview(this.orgLevel.id).then(function (data) {
            _this.records = data;
            _this.refreshGrid();
            _this.isLoading = false;
        });
    };
    IdealScheduleOverviewComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        //let filteredRecords: IdealScheduleOverviewItem[] = filterBy(this.records, this.gridState.state.filter);
        //let sortedRecords: IdealScheduleOverviewItem[] = orderBy(filteredRecords, this.gridState.state.sort);
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], IdealScheduleOverviewComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleOverviewComponent.prototype, "orgLevelSubscription", void 0);
    return IdealScheduleOverviewComponent;
}());
export { IdealScheduleOverviewComponent };
