import { UserMenuPinAPIService } from './user-menu-pin-api.service';
import { ApplicationStateManagementService } from '../../../portal/services/application-state-management/application-state-management.service';
import * as i0 from "@angular/core";
import * as i1 from "./user-menu-pin-api.service";
import * as i2 from "../../../portal/services/application-state-management/application-state-management.service";
var UserMenuPinService = /** @class */ (function () {
    function UserMenuPinService(menuPinApiService, appStateManagementService) {
        this.menuPinApiService = menuPinApiService;
        this.appStateManagementService = appStateManagementService;
    }
    UserMenuPinService.prototype.getUserMenuPinStatus = function () {
        var _this = this;
        this.menuPinApiService.getUserMenuPinStatus()
            .then(function (data) {
            _this.appStateManagementService.isMenuPinned = data.isMenuPinned;
        });
    };
    UserMenuPinService.prototype.changeUserMenuPinStatus = function (isPinned) {
        var _this = this;
        this.menuPinApiService.toggleUserMenuPinStatus(isPinned)
            .then(function (data) {
            _this.appStateManagementService.isMenuPinned = data.isMenuPinned;
        });
    };
    UserMenuPinService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserMenuPinService_Factory() { return new UserMenuPinService(i0.ɵɵinject(i1.UserMenuPinAPIService), i0.ɵɵinject(i2.ApplicationStateManagementService)); }, token: UserMenuPinService, providedIn: "root" });
    return UserMenuPinService;
}());
export { UserMenuPinService };
