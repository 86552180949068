<div class="headless-modal-content">
  <div class="modal-header">
    <div class="txt-pad10">
      <span class="pull-left header-cls">Manage Groups</span>
      <div class="pull-right" (click)="onCancel()">
        <span><i class="fa fa-times top-close" aria-hidden="true"></i></span>
      </div>
    </div>
  </div>
  <slx-spinner [show]="isLoading">
    <div class="modal-body">
      <slx-manage-ale-groups #groups (onLoading)="onLoading($event)" (onHasChanges)="onHasChanges($event)" (isGroupChanges)="isGroupChanges($event)" (isSaveComplete)="isSaveComplete($event)" >
      </slx-manage-ale-groups>
    </div>

    <div class="modal-footer center-align">
      <div class="btn-close" (click)="onCancel()">
        <span><i class="fa fa-times marl5" aria-hidden="true"></i>  Cancel</span>
      </div>
      <div class="btn-save" [ngClass]="{'disabledbtn' : isSaveDisabled}">
        <button class="savecls" [ngClass]="{'disabledbtn' : isSaveDisabled}" (click)="groups.saveGroupAssignments()" [disabled]="isSaveDisabled">
          <i class="fas fa-save marl5"
             aria-hidden="true"></i>  Save
        </button>
      </div>
    </div>
  </slx-spinner>
</div>
