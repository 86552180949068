<form #form="ngForm" novalidate class="e-remarks">
  
  <p class="e-remarks__text">The absence of any statement on the part of the EMPLOYEE indicates his/her agreement with this report as started</p>
  
    <textarea class="e-remarks__textarea"
      [(ngModel)]="remarksText"
      (ngModelChange)="onChangeRemarks()"
      maxlength="4000"
      name="remarks"
    ></textarea>

    <span class=" flex employee-signature">
      <span class="flex__grow emp-line">
        <span class="sub-title">I have entered my version of the matter above</span>
      </span> 
      <span class="flex__grow emp-line controls-line">
        <span class="flex__grow emp-ctl">
            <span class="emp-lbl">Employee</span>
            <input class="slx-wide txt__field emp-sign"
            [(ngModel)]="employeeSign"
            (ngModelChange)="onChangeEmpSign()"
            maxlength="255"
            autocomplete="off"  
            name="employeeSign"
            type="text"/>
        </span>
        <span class="flex__grow emp-ctl">
            <span class="emp-lbl">Date</span>
            <slx-datepicker class="slx-wide"
            [(ngModel)]="employeeSignDate"
            [acceptNullDate]="true"
            (ngModelChange)="onChangeEmpSignDate()"
            name="employeeSignDate"
            ></slx-datepicker>
        </span>
      </span>
    </span>

</form>
