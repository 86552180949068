import { Directive, OnInit, Host, Input, HostListener, ElementRef, Renderer2, DoCheck } from '@angular/core';
import { GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import * as domUtils from '../../../utils/domUtils';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';

@Directive({
  selector: '[slxKendoGridAlignDetails]',
})
export class KendoGridAlignDetailsDirective implements OnInit, DoCheck {

  @Input('slxKendoGridAlignDetails')
  public align: number = 100; //0..100%
  @Input()
  public minColumnsAlign: number = 2;
  @Input()
  public colOffset: number = 1;

  @unsubscribe()
  private expandChangeSubscription: Subscription;

  private gridRoot: HTMLElement;
  private colCount: number = 0;
  private lastData: any;


  constructor( @Host() private grid: GridComponent, private elRef: ElementRef, private renderer: Renderer2) {
    this.gridRoot = elRef.nativeElement;
  }

  public ngOnInit(): void {
    this.calcAlignDetails();
    this.expandChangeSubscription = this.grid.detailExpand.asObservable().subscribe((event: { index: number, dataItem: any }) => {
      setTimeout(() => {
        this.calcAlignDetails();
        this.alignDetails();
      }, 100);
    });
  }

  public ngDoCheck(): void {
    if (this.lastData === this.grid.data) {
      return;
    }
    this.lastData = this.grid.data;
    setTimeout(() => {
      this.alignDetails();
    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent): void {
    this.calcAlignDetails();
    this.alignDetails();
  }
  private calcAlignDetails(): void {
    let width: number = window.innerWidth;
    let p = this.align;
    if (!_.isNumber(p)) {
      p = 100;
    }
    this.colCount = 0;
    let w = width * p / 100;
    let acc: number = 0;
    let columnChecked = 0;
    this.grid.columns.forEach((column: any) => {
      if (!column || column.hidden) {
        return;
      }
      columnChecked++;
      if (columnChecked <= this.colOffset) {
        return;
      }
      let colW = column.width ? column.width : 10;
      acc += colW;
      if (acc < w) {
        this.colCount++;
      }
    });
  }
  private alignDetails(): void {
    if (this.colCount > 0) {
      let details: HTMLCollectionOf<Element> = this.gridRoot.getElementsByClassName('k-detail-cell');
      _.forEach(details, (item: HTMLElement) => {
        let cols = _.max([this.colCount, this.minColumnsAlign]);
        this.renderer.setAttribute(item, 'colspan', cols.toString());
      });
    }
  }
}
