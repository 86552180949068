/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./organizations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../work-location-migration/work-location-migration.component.ngfactory";
import * as i3 from "../work-location-migration/work-location-migration.component";
import * as i4 from "../../../services/wfm/wfm-sync.service";
import * as i5 from "../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i6 from "../../../../common/services/busy/busy.service";
import * as i7 from "../department/department.component.ngfactory";
import * as i8 from "../department/department.component";
import * as i9 from "../position/position.component.ngfactory";
import * as i10 from "../position/position.component";
import * as i11 from "@angular/common";
import * as i12 from "./organizations.component";
var styles_OrganizationsComponent = [i0.styles];
var RenderType_OrganizationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrganizationsComponent, data: {} });
export { RenderType_OrganizationsComponent as RenderType_OrganizationsComponent };
function View_OrganizationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-work-location-migration", [], null, null, null, i2.View_WorkLocationMigrationComponent_0, i2.RenderType_WorkLocationMigrationComponent)), i1.ɵdid(2, 114688, null, 0, i3.WorkLocationMigrationComponent, [i4.WfmSyncService, i5.NotificationsService, i6.BusyService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_OrganizationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-department", [], null, null, null, i7.View_DepartmentComponent_0, i7.RenderType_DepartmentComponent)), i1.ɵdid(2, 114688, null, 0, i8.DepartmentComponent, [i4.WfmSyncService, i5.NotificationsService, i6.BusyService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_OrganizationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-position", [], null, null, null, i9.View_PositionComponent_0, i9.RenderType_PositionComponent)), i1.ɵdid(2, 114688, null, 0, i10.PositionComponent, [i4.WfmSyncService, i5.NotificationsService, i6.BusyService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_OrganizationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "providers-tabs flex left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "providers-tabs__slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [["list", 1]], null, 9, "ul", [["class", "providers-tabs__list flex flex-direction-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "li", [["class", "providers-tabs__item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Organization"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "li", [["class", "providers-tabs__item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Department"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "li", [["class", "providers-tabs__item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Position"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationsComponent_1)), i1.ɵdid(13, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationsComponent_2)), i1.ɵdid(15, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrganizationsComponent_3)), i1.ɵdid(17, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.activeTab0; _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.activeTab1; _ck(_v, 15, 0, currVal_4); var currVal_5 = _co.activeTab2; _ck(_v, 17, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeTab0; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.activeTab1; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.activeTab2; _ck(_v, 9, 0, currVal_2); }); }
export function View_OrganizationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-organizations", [], null, null, null, View_OrganizationsComponent_0, RenderType_OrganizationsComponent)), i1.ɵdid(1, 114688, null, 0, i12.OrganizationsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrganizationsComponentNgFactory = i1.ɵccf("slx-organizations", i12.OrganizationsComponent, View_OrganizationsComponent_Host_0, {}, {}, []);
export { OrganizationsComponentNgFactory as OrganizationsComponentNgFactory };
