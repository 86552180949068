<slx-collapsible-section title="Day" mode="gray" class="no-padding" [expanded]="true">
  <ng-container body>
    <slx-ideal-schedule-grid-control
      *ngIf="stateService.selectedPosition"
      [range]="range"
      [showShiftColumn]="true"
      [showUnitColumn]="stateService.selectedPositionType?.id === IdealScheduleConfigTypeDefinition.ShiftUnit"
      [isShiftGroup]="false"
      filterGroup="Day"
    ></slx-ideal-schedule-grid-control>
  </ng-container>
</slx-collapsible-section>

<slx-collapsible-section title="Evening" mode="gray" class="no-padding" [expanded]="true">
  <ng-container body>
    <slx-ideal-schedule-grid-control
      *ngIf="stateService.selectedPosition"
      [range]="range"
      [showShiftColumn]="true"
      [showUnitColumn]="stateService.selectedPositionType?.id === IdealScheduleConfigTypeDefinition.ShiftUnit"
      [isShiftGroup]="false"
      filterGroup="Evening"
    ></slx-ideal-schedule-grid-control>
  </ng-container>
</slx-collapsible-section>

<slx-collapsible-section title="Night" mode="gray" class="no-padding" [expanded]="true">
  <ng-container body>
    <slx-ideal-schedule-grid-control
      *ngIf="stateService.selectedPosition"
      [range]="range"
      [showShiftColumn]="true"
      [showUnitColumn]="stateService.selectedPositionType?.id === IdealScheduleConfigTypeDefinition.ShiftUnit"
      [isShiftGroup]="false"
      filterGroup="Night"
    ></slx-ideal-schedule-grid-control>
  </ng-container>
</slx-collapsible-section>
