import { OrgLevel } from '../../../../state-model/models/index';
import { UserProfileModel } from '../models/user-profile.model';

export class UserProfileWithOrgLevelDecorator {
  public userProfile: UserProfileModel;

  public get orgLevels(): OrgLevel[] {
    return this.m_orgLevels;
  }

  private m_orgLevels: OrgLevel[] = [];

  constructor(userProfile: UserProfileModel) {
    this.userProfile = userProfile;
  }

  public addOrgLevel(user:any): void {
    this.m_orgLevels.push(user);
  }
}
