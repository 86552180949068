import * as tslib_1 from "tslib";
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { BaseOrgDeptPosSyncComponent } from '../base-org-dept-pos-sync.component';
var DepartmentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DepartmentComponent, _super);
    function DepartmentComponent(wfmSyncService, notificationService, busyService) {
        var _this = _super.call(this, wfmSyncService, notificationService, busyService) || this;
        _this.wfmSyncService = wfmSyncService;
        return _this;
    }
    DepartmentComponent.prototype.getSyncItemKey = function (item) {
        return '' + item.orgLevelId;
    };
    DepartmentComponent.prototype.fetchDataCore = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.wfmSyncService.GetOrganizationLevelsChangeLogs('migration', 'department')];
            });
        });
    };
    return DepartmentComponent;
}(BaseOrgDeptPosSyncComponent));
export { DepartmentComponent };
