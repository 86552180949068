import { Routes, RouterModule } from '@angular/router';

import {
  ApplicationContainerComponent,
  LeftSidebarComponent
} from './components/index';

import { BenefitConsoleComponent, BenefitDetailsComponent } from '../app-modules/benefits/components/index';
import { LMRosterComponent, LMConsoleComponent } from '../app-modules/leave-management/components/index';
import {
  WcIncidentRosterComponent,
  WcComparisonsComponent,
  WcCreateOshaRkFormsComponent,
  WcIncidentTrackingComponent,
  WcOshaBudgetTrackerComponent,
  WcRepeatInjuryComponent,
  WcSummaryComponent
} from '../app-modules/workers-compensation/components/index';

import {
  PmRosterComponent, PmEmployeeRosterComponent
} from '../app-modules/performance-management/components/index';

import { OrgLevelResolver } from './services/index';
import { LoaFilterResolver } from '../app-modules/leave-management/services/index';
import { CanLeaveGuard } from '../common/services/change-management/can-leave.guard';
export const hrRoutes: Routes = [
  {
    path: '',
    component: ApplicationContainerComponent,
    children: [
      {
        path: '',
        outlet: 'leftSidebar',
        component: LeftSidebarComponent
      },
      {
        path: '',
        resolve: [OrgLevelResolver],
        redirectTo: 'loa_console',
        pathMatch: 'full',
        data: { componentId: 'loa_console', accessContext: 'OrgLevel' }
      },
      {
        path: 'loa_console',
        resolve: [OrgLevelResolver],
        component: LMConsoleComponent,
        data: { componentId: 'loa_console', accessContext: 'OrgLevel' }
      },
      {
        resolve: [OrgLevelResolver, LoaFilterResolver],
        path: 'loa_roster',
        component: LMRosterComponent,
        data: { componentId: 'loa_roster', accessContext: 'OrgLevel' }
      },
      {
        path: 'incident_roster',
        component: WcIncidentRosterComponent,
        data: { componentId: 'incident_roster', accessContext: 'OrgLevel' }
      },
      {
        path: 'wc_comparisons',
        component: WcComparisonsComponent,
        data: { componentId: 'incident_roster', accessContext: 'OrgLevel' }
      },
      {
        path: 'create_osha_recordkeeping_forms',
        component: WcCreateOshaRkFormsComponent,
        data: { componentId: 'incident_roster', accessContext: 'OrgLevel' }
      },
      {
        path: 'incident_tracking',
        component: WcIncidentTrackingComponent,
        data: { componentId: 'incident_roster', accessContext: 'OrgLevel' }
      },
      {
        path: 'osha_budget_tracker',
        component: WcOshaBudgetTrackerComponent,
        data: { componentId: 'incident_roster', accessContext: 'OrgLevel' }
      },
      {
        path: 'repeat_injury_list',
        component: WcRepeatInjuryComponent,
        data: { componentId: 'incident_roster', accessContext: 'OrgLevel' }
      },
      {
        path: 'wc_summary',
        component: WcSummaryComponent,
        data: { componentId: 'incident_roster', accessContext: 'OrgLevel' }
      },
      {
        path: 'performance_management_roster',
        resolve: [OrgLevelResolver],
        children: [
          {
            path:'',
            component: PmRosterComponent,
            resolve: [OrgLevelResolver],
            data: { componentId: 'performance_management_roster', accessContext: 'OrgLevel' },
          },
          {
            path:'employee/:employeeId',
            resolve: [OrgLevelResolver],
            component: PmEmployeeRosterComponent,
            data: { componentId: 'performance_management_roster', accessContext: 'Employee', orgLevelChangeDisabled: true }
          }
        ]
      },
      {
        path: 'benefits_management_console',
        component: BenefitConsoleComponent,
        data: { componentId: 'benefits_management_console', accessContext: 'OrgLevel' }
      },
      {
        path: 'benefits_management_details',
        component: BenefitDetailsComponent,
        canDeactivate: [CanLeaveGuard],
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        data: { componentId: 'benefits_management_details', accessContext: 'OrgLevel' }
      }
    ]
  },
];
