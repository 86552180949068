import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { appConfig } from '../../../app.config';
import { AcaMeasurementMapService } from './aca-measurement-map.service';
import { configurationConfig } from '../../configuration.config';
var AcaMeasurementApiService = /** @class */ (function () {
    function AcaMeasurementApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    AcaMeasurementApiService.prototype.getAcaMeasurement = function (orgLevelId, year) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAcaMeasurementRootApi() + "/" + configurationConfig.api.ACA.measurement.root + "/details/" + orgLevelId + "/all";
                request = this.urlParamsService.createGetRequest(url, {
                    year: year
                });
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAcaMeasurement(response.data, response.meta, year); })];
            });
        });
    };
    AcaMeasurementApiService.prototype.getAcaMeasurementRootApi = function () {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root;
    };
    AcaMeasurementApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return AcaMeasurementApiService;
}());
export { AcaMeasurementApiService };
