import { TimeclockCommunicationApiService } from './../../../services/timeclock-communication/timeclock-communication-api.service';
import { Employee } from './../../../../../organization/models/index';
import { filterBy,CompositeFilterDescriptor} from '@progress/kendo-data-query';
import { OrgLevel } from '../../../../../state-model/models/index';
import { LookupType, Lookup } from '../../../../../organization/models/index';
import { LookupService } from '../../../../../organization/services/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { Component, OnInit, Input} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'slx-specific-message',
  templateUrl: 'specific-message.component.html',
  styleUrls: ['specific-message.component.scss']
})
export class SpecificMessageComponent implements OnInit {

    @Input()
    public set orgLevel(value: OrgLevel) {
        this.m_orgLevel = value;
        this.updateList();
    }

    public get orgLevel(): OrgLevel {
        return this.m_orgLevel;
    }
    public gridData: any[] ;
    public filter: any;
    public employees: any[];
    public appConfig: IApplicationConfig;
    public isAllSelected: boolean;
    public messageText: string;
    public isLoading: boolean = false;
    public isFilterOn:boolean=false;
    private selectedRecords: Employee[];
    private lookupService: LookupService;
    private m_orgLevel: OrgLevel;
    private apiService: TimeclockCommunicationApiService;
     constructor(lookupService: LookupService, apiService: TimeclockCommunicationApiService) {
        this.lookupService = lookupService;
        this.apiService = apiService;
        this.updateList();
        }

    public get canSend(): boolean {
        return this.selectedRecords
            && this.selectedRecords.length > 0
            && this.messageText
            && this.messageText.trim().length > 0;
    }

    public ngOnInit(): void {
        this.appConfig = appConfig;
    }

    public onToggleAllSelected(): void {
        _.forEach(this.employees, (record: any) => {
            record['isSelected'] = this.isAllSelected;
        });
        this.selectionChange();
    }

    public selectionChange(): void {
        this.selectedRecords = [];
        this.selectedRecords = _.filter(this.employees, (record: any) => {
            return Boolean(record['isSelected']) === true;
        });
        this.isAllSelected = this.selectedRecords.length === this.employees.length;
    }

    public send(): void {
        this.isLoading = true;
        this.apiService.sendEmployeeSpecificMessage(this.messageText, this.getSelectedIds())
            .then((result: any)=> {
               this.isLoading = false;
               this.isAllSelected = false;
               _.forEach(this.selectedRecords, (record: any) => {
                   record['isSelected'] = false;
               });
               this.selectedRecords = null;
            }).catch((error: any) => {
               this.isLoading = false;
            });
    }

    private getSelectedIds(): number[] {
        return _.map(this.selectedRecords, (item: Employee) => item.id);
    }

    private updateList(): void {
        if (!this.orgLevel || !this.lookupService) return;
        this.lookupService.getLookup({lookupType: LookupType.employeeList, orgLevelId: this.orgLevel.id}).then((lookup: Lookup) => {
            this.employees = lookup.items;
            this.gridData= filterBy(this.employees, this.filter);
        }).catch((reason: any) => {
            this.employees = [];
        });
    }

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.filter = filter;
        if(this.employees!=null)
        {
        this.gridData = filterBy(this.employees, filter);
        }
    }

   
}
