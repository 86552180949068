import { FieldData } from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';
import { appConfig } from '../../../../../app/app.config';
import * as moment from 'moment';
import {
  IPayPolicy,
  PayPolicy,
  ShiftDiffPolicy,
  IShiftDiffPolicy,
  EmpType,
  IEmpType,
} from '../../../../organization/models/lookup/index';


export interface IEmployeeSectionsPositionHistory {
  records: IEmployeeSectionsPositionHistoryRecord[];
}
export interface IEmployeeSectionsPositionHistoryRecord {
  archivedDate: string;
  endDate: string;
  jobDescription: string;
  isPrimary: boolean;
  departmentName:string;
  organizationName:string;
  payPolicy: IPayPolicy;
  shiftDifferentialPolicy: IShiftDiffPolicy;
  costCenter: number;
  employeeType: IEmpType;
  activation_date: string;
 emp_dt_termination: string;
 isHideEnddate: boolean;
}

export class EmployeeSectionsPositionHistoryRecord {
  public archivedDate: FieldData<Date>;
  public endDate: FieldData<Date>;
  public jobDescription: FieldData<string>;
  public isPrimary: FieldData<boolean>;
  public departmentName: FieldData<string>;
  public organizationName : FieldData<string>;
  public payPolicy: FieldData<PayPolicy>;
  public shiftDiffPolicy: FieldData<ShiftDiffPolicy>;
  public costCenter: FieldData<number>;
  public employeeType: FieldData<EmpType>;
  public activation_date: FieldData<Date>;
  public emp_dt_termination: FieldData<Date>;
  public isHideEnddate: FieldData<boolean>;
  

  public get startDateFormatForExcel(): string {
    return moment(this.archivedDate.fieldValue).format(appConfig.dateFormat);
  }

  public get endDateFormatForExcel(): string {
    if(this.endDate.fieldValue === null || moment(this.endDate.fieldValue).format(appConfig.dateFormat) === '01/01/1900'){
      return null
    }else{
      return moment(this.endDate.fieldValue).format(appConfig.dateFormat);
    }
    
  }
  public get verifyCostCenterCalue(): number{
    return this.costCenter.fieldValue ? this.costCenter.fieldValue : null;
  }
}

export class EmployeeSectionsPositionHistory extends EmployeeSectionsBase {
  public records: EmployeeSectionsPositionHistoryRecord[];
  public primaryRecords: EmployeeSectionsPositionHistoryRecord[];
  public secondaryRecords: EmployeeSectionsPositionHistoryRecord[];

  constructor() {
    super();
    this.records = [];
    this.primaryRecords = [];
    this.secondaryRecords = [];
  }
}

