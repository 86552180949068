import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { process, State } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common';
import { ISession } from '../../../../authentication/store/index';
import { destroyService, mutableSelect, unsubscribe } from '../../../../core/decorators';
import { NotificationGroup, UsersRoles } from '../../../models/index';
import { NotificationsManagementService, UserRolesApiService,NotificationsApiService } from '../../../services/index';
import { OrgLevel } from '../../../../state-model/models';
import { NotificationRole } from "../../../models/notifications/notifications-role";
import { NotificationEventType } from '../../../models/notifications/notifications-event-type';
import { RoleDefinition } from '../../../../organization';
import { NotificationGroupEvent } from '../../../models/notifications/notifications-group-event';


@Component({
  moduleId: module.id,
  selector: 'slx-notifications-grid',
  templateUrl: 'notifications-grid.component.html',
  styleUrls: ['notifications-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsGridComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public records: NotificationGroup[];
  public gridState: KendoGridStateHelper<NotificationGroup>;
  public isNewMode: boolean;
  public editedRowIndex: number;
  public defaultItem: any = { id: 0, name: 'Email' };
  public uniqueError = false;
  public loadPage;
  private orgLevel: OrgLevel;
  public editedRecord: NotificationGroup;
  private savedEditedRole: NotificationGroup;
  public userRoles: RoleDefinition[];
  public notificationRole : NotificationRole[]=[];
  public bindNotificationEvent:NotificationEventType[]=[];
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<any>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private addCmdSubscription: Subscription;
  @unsubscribe()
  private cloneCmdSubscription: Subscription;
  @unsubscribe()
  private groupSavedSubscription: Subscription;
  @unsubscribe()
  private selectionSubscription: Subscription;

  public userName: string;
  public alias: string;
  public login: string;
  public isLoading : boolean  = false;

  @unsubscribe()
  private userSubscription: Subscription;

  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  @destroyService()
  private managementService: NotificationsManagementService;
  @ViewChild('grid', {static: true})
  public grid: GridComponent;
 
  public deliveryMethodsList =["Email"];
  private changeDetector: ChangeDetectorRef;
  notificationEvents: NotificationEventType[]=[]
  notificationGroupEvent: NotificationGroupEvent[]=[];

  rolesMapData :  Map<number, NotificationRole> = new Map<number, NotificationRole>();
  eventMapData  :  Map<number, NotificationEventType> = new Map<number, NotificationEventType>();
  eventGroupMapData : Map<number, string> = new Map<number, string>();
  eventGroupEventsMapData : Map<number, NotificationEventType[]> = new Map();
  eventDisplayName : string = "";
  roleDisplayName : string = "";
  isInitial : boolean =  true;
  constructor(managementService: NotificationsManagementService, changeDetector: ChangeDetectorRef, private userService: UserRolesApiService,private apiService:NotificationsApiService) {
    this.managementService = managementService;
    this.changeDetector = changeDetector;
    this.gridState = new KendoGridStateHelper<NotificationGroup>();
    
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        this.alias = session.alias;
        if (session.user) {
          this.userName = session.user.name;
          this.login = session.user.username;
        }
      }
    });

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = orgLevel;
        this.closeEditor();
        this.getRoles(this.orgLevel.id);

      }    
    });       
    
    
  
    this.addCmdSubscription = this.managementService.addCmd$.subscribe((value: any) => {
      this.addHandler();
    });
  
    this.groupSavedSubscription = this.managementService.onGroupSaved$.subscribe((result: { savedRole: NotificationGroup, records: NotificationGroup[], index: number }) => {
      if (result.index !== -1 && result.savedRole) {
        this.records[result.index] = result.savedRole;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }       
    });
  
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (records: NotificationGroup[]) => {  
        this.mapNotificationGroup(records);
        
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
    if( this.isInitial) {
      this.isInitial = false;
    this.getRoles(this.orgLevel.id);
    }
  }  
  public ngOnDestroy(): void
  { }
  
  public addHandler(): void {
    this.closeEditor();
    this.isNewMode = true;
    let newGroup: NotificationGroup = new NotificationGroup();
    let roles : NotificationRole [] = [];
    
    newGroup.groupRoles = roles;
    let events : NotificationEventType [] = [];
    newGroup.groupEvents = new NotificationGroupEvent();

    newGroup.groupEvents.events = events;
    this.grid.addRow(newGroup);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  } 
  
  public editHandler(event: { rowIndex: number, dataItem: NotificationGroup }): void {
    this.isNewMode = false;
    this.closeEditor();
    this.editedRowIndex = event.rowIndex;
    this.editedRecord = event.dataItem;
    event.dataItem.modifiedBy = this.userName;
    this.savedEditedRole = Object.assign({}, event.dataItem);
    this.grid.editRow(this.editedRowIndex);
    
  }
 
  public cancelHandler(): void {
    this.closeEditor();
    this.isLoading = true;
    // Deep cloning is required to merge saved and edit records. It is better to call get again.
    this.managementService.loadNotificationGroups(this.orgLevel.id);  
   
  }
  
  public removeHandler(event: { rowIndex: number, dataItem: NotificationGroup }): void {
    this.isLoading = true;
    this.managementService.onRemoveRecord(this.orgLevel.id, event.dataItem );
  }

  public closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);
    this.isNewMode = false;
    this.editedRowIndex = undefined;
    this.editedRecord = undefined;
    this.savedEditedRole = undefined;
  }
  
  public saveHandler(event: { action: string, isNew: boolean, rowIndex: number, dataItem: NotificationGroup }): void {
    this.closeEditor();
    this.isLoading = true;
    if ( event.isNew   ) {
      event.dataItem.modifiedBy = this.userName;
      this.managementService.onAddRecord(this.orgLevel.id, event.dataItem );
    } else {
      this.managementService.onSaveRecord(this.orgLevel.id, event.dataItem);
    }
     
  }  
  
  public onKeyName(event: KeyboardEvent, dataItem: NotificationGroup, nameField: any): void {
    this.uniqueError = false;
    if (!dataItem.groupName) {
      return;
    }
    const name: string = dataItem.groupName;
    const existGroup: NotificationGroup = _.find(this.records, (record: NotificationGroup) => record.groupName === name && record.groupId !== dataItem.groupId);
    if (existGroup) {
      this.uniqueError = true;
    }
  }
  private refreshGrid(): void {  
    if (!this.records) {
      this.gridState.view = null;
      return;
    }    
    this.gridState.view = process(this.records, this.gridState.state);
  }
  public  mapNotificationGroup(records:NotificationGroup[]): void{
    this.records = records;
    if ( this.records != null && this.records.length > 0 ) {
      this.records.forEach(element => { 
        element.groupEvents.name = this.eventGroupMapData.get(element.groupEvents.eventGroupId);   
       
        element.groupRoles.forEach( role => role.name = this.rolesMapData.get(role.roleId).name);
        
        if (element.groupEvents.events.length > 0 ){
          // Remove old mapping if there are associated events
          element.groupEvents.events = element.groupEvents.events.filter((event:NotificationEventType) => this.eventMapData.get(event.eventId) != null );

          element.groupEvents.events.forEach ( event => {
            if ( this.eventMapData.get(event.eventId) != null ) {
              event.name = this.eventMapData.get(event.eventId).name 
            }
          });
        }
        // get display name
        if ( element.groupEvents != null ) {
          element.displayEvents = this.getDisplayNames(element.groupEvents.events);
        }
       
        element.displayRoles = this.getDisplayNames(element.groupRoles);
      });
    }
    this.isLoading = false;
  }
   
  
  public getDisplayNames(data : any) {
    let displayName = "";
    if ( data != null && data.length > 0 ) {
      try {
        displayName = data[0].name;
        if ( data.length > 1 ) {
          displayName +=  " & " + ( data.length - 1  == 1 ? " 1 other" : data.length - 1 + " others" );
        }
      } catch(error) {
        // Kendo break if there is one excption.
        console.log(error);
      }
    }
    return displayName;
  }

  public checkRequiredFields(data : NotificationGroup ) {
    /// find a way to  restrit for new and edit row
    if ( data != null && ( data.groupName == undefined || data.groupName == '' || data.groupEvents == null || data.groupEvents.events == null ||
      data.groupEvents.events.length == 0 || data.groupRoles == null || data.groupRoles.length == 0 ||
      data.deliveryMethod == undefined || data.deliveryMethod == '' )) {
        return true;
      }
    
    return false;
  }
  public getRoles(orgLevelId: number) {
    this.userService.getUsersWithRolesOrglevel(orgLevelId).then((userRoles: UsersRoles) => {
    this.userRoles=userRoles.roles.filter((user:RoleDefinition) => user.id!=-1);
    this.notificationRole = [];
    this.userRoles.forEach(element => {
       // This is for drop down to show all
        let currentRole : NotificationRole = new NotificationRole(element.id, element.name); 
        if ( !this.notificationRole.includes(currentRole) ) {
          this.notificationRole.push(currentRole);  
          // This is to display view part
           this.rolesMapData.set(element.id, currentRole);
        }
     
     });
     this.notificationRole.sort( (role1, role2) : any => role1.name.localeCompare(role2.name) );
     this.getEvents();  
    }).catch((res: any) => {
      console.log("error while getting user roles");
    });
  }   
  public  getEvents():void{
    this.apiService.getEventGroups().then((notificationGroup)=>{
      this.notificationGroupEvent=notificationGroup;
       // This is for drop down to show all
      this.notificationGroupEvent.forEach(element => {
        this.eventGroupMapData.set(element.eventGroupId, element.name);
        let notificationEvents = element.events ;
        notificationEvents.sort( (event1, event2) : any => event1.name.localeCompare(event2.name) );
        this.eventGroupEventsMapData.set(element.eventGroupId,notificationEvents );
         // This is to display view part
        this.notificationEvents = notificationEvents;
        notificationEvents.forEach( event => { 
          this.eventMapData.set(event.eventId, event );
        });
           
      });  
      this.loadNotificationGroups();  
    });
  }

  loadNotificationGroups() {
   
    this.apiService.getNotificationGroups(this.orgLevel.id)
    .then((records: NotificationGroup[]) => {  
      this.records = records;    
      this.mapNotificationGroup(records);
      this.refreshGrid();  
  this.managementService.onLoadStatusChanged(false);

       this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
    })

  
    .catch((reason: any) => {
      console.log("error getting notification groups")
    });  
  }

 
  public getSelectionEvents(dataItem : NotificationGroup) {
    let currentList : NotificationEventType [] = [];
    if ( dataItem.groupEvents != null && dataItem.groupEvents.eventGroupId > 0  ) {
       currentList = this.eventGroupEventsMapData.get(dataItem.groupEvents.eventGroupId);
       if( this.isNewMode ) {
        dataItem.groupEvents.events = currentList;
       }
    }
    return currentList;
  }
} 
    
    