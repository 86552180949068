import { LookupEntity, ILookupEntity } from './lookup-entity';

export interface IConstraintDefinition extends ILookupEntity {
  code: string;
  organizationId: number;
  countAs: number;
}

export class ConstraintDefinition extends LookupEntity implements IConstraintDefinition {
  public code: string;
  public organizationId: number;
  public countAs: number;
}
