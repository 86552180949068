<div class="row">
  <div [ngClass]="IsPBJExportCorpLevelEnabled ? 'col-sm-4' : 'col-sm-6'">
    <p class="f-16" *ngIf="IsPBJExportCorpLevelEnabled">Data to export</p>
    <form #form="ngForm" class="export-body">
      <section class="year">
        <label class="common-label">Fiscal year:</label>
        <div class="year_control">
          <slx-dropdown-list class="slx-wide" name="year" [readonly]="disableYear" [options]="yearsList" [valueField]="'id'"
            [titleField]="'year'" [(ngModel)]="selectedYear" (ngModelChange)="onSelectYear($event)"></slx-dropdown-list>
        </div>
      </section>

      <section class="period">
        <label class="common-label m-bottom">Period:</label>
        <div class="period__control">
          <slx-dropdown-list class="slx-wide slx-short" name="exportRange" [options]="exportRangeTypes"
            [valueField]="'id'" [titleField]="'text'" [(ngModel)]="exportRangeType"
            (ngModelChange)="onChangeExportRange($event)"></slx-dropdown-list>
        </div>
        <div [hidden]="exportRangeType.id !== 'Custom'" class="period__custom">
          <slx-daterange #dateRange class="slx-width-260" startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date" [startDate]="request.startDate" [endDate]="request.endDate"
            (rangeDateChanged)="onDatesChanged($event)" (valueChanged)="onValueChanged($event)"></slx-daterange>
          <span class="slx-error-block" *ngIf="!dateRangeCorrect">The date range must be within 1 quarter within one
            year</span>
        </div>
      </section>

      <section class="sec-to-include">
        <label class="common-label">Sections to Include:</label>
        <div>
          <slx-checkbox-button class="sec-to-include__details p-left-0" [(ngModel)]="request.includeEmployee"
            (ngModelChange)="updateExportRequest()" fieldName="includeEmployee" name="includeEmployee"
            placeholder="Employee"></slx-checkbox-button>
          <div class="sec-to-include__details">
            <slx-checkbox-button class="p-left-0" [(ngModel)]="request.includeStaffingHours"
              (ngModelChange)="updateExportRequest()" fieldName="includeStaffingHours" name="includeStaffingHours"
              placeholder="Staffing Hours"></slx-checkbox-button>
            <div class="sec-to-include__selectors" *ngIf="request.includeStaffingHours">
              <label class="common-label disp-block small">Staffing Export Types:</label>
              <slx-radio-button class="p-left-0" name="staffingTypeMerge" fieldName="staffingTypeMerge" option="Merge"
                caption="Merge" [(ngModel)]="request.staffingHoursExportType"></slx-radio-button>
              <slx-radio-button class="p-left-0" name="staffingTypeReplace" fieldName="staffingTypeReplace"
                option="Replace" caption="Replace" [(ngModel)]="request.staffingHoursExportType"></slx-radio-button>
            </div>
          </div>
        </div>
      </section>

      <section class="warning-section" *ngIf="showConfigWarning && !IsPBJExportCorpLevelEnabled">
        <span class="warning-lbl">Please complete the Configuration for PBJ meal deduction before exporting</span>
      </section>

    </form>

  </div>
  <div class="seperator" *ngIf="IsPBJExportCorpLevelEnabled"></div>
  <div class="col-sm-8" *ngIf="IsPBJExportCorpLevelEnabled">
    <p class="f-16 m-3">Locations to export</p>
    <slx-checkbox-tree-view [items]="items" [config]="config" (selectedChange)="getValues($event)" (selectedOrgIds)="getSelectedIds($event)" ></slx-checkbox-tree-view>
  </div>
</div>