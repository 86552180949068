export interface IApprovalsConfig {
    api: {
        ta: {
            root: string;
            approveRequiredCodes: string;
            unapproveRequiredCodes: string;
            approveOvertime: string;
            unapproveOvertime: string;
        }
    };
}
export const approvalsConfig: IApprovalsConfig = {
    api: {
        ta: {
            root: 'ta',
            approveRequiredCodes: 'approveRequiredCodes',
            unapproveRequiredCodes: 'unapproveRequiredCodes',
            approveOvertime: 'approveOvertime',
            unapproveOvertime: 'unapproveOvertime'
        }
    }
};

