import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { WcCreateOshaFormService } from '../../../../services/index';
import { appConfig } from '../../../../../../app.config';
import { ModalService } from '../../../../../../common/services/index';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { WcIncidentRosterEntry, WcCreateOshaRosterState, WcIncidentRosterContainer, GenerateOshaFormAction, IGenerateOshaFormAction, ICreateOsha300AFormDialogOptions } from '../../../../models/index';
import { WcCreateOshaFormExcelDialogComponent } from './wc-create-osha-form-excel-dialog/wc-create-osha-form-excel-dialog.component';
import { WcCreateEstablishmentSizeDialogComponent } from './wc-create-establishment-size-dialog/wc-create-establishment-size-dialog.component';
var WcCreateOshaGridComponent = /** @class */ (function () {
    function WcCreateOshaGridComponent(oshaService, modalService) {
        this.oshaService = oshaService;
        this.modalService = modalService;
        this.pageSize = 50;
        this.isAllSelected = true;
        this.selectedRecords = [];
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'organization.name', dir: 'asc' },
            { field: 'employee.name', dir: 'asc' }
        ];
        this.gridState.gridComponentKey = 'WCOshaGrid';
        this.gridState.gridControlStateKey = 'GridFiltersState';
        this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.appConfig = appConfig;
    }
    WcCreateOshaGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.generateOshaFormAction = new GenerateOshaFormAction();
        this.subscriptions.onLoaded = this.oshaService
            .subscribeToLoadedRecords(function (r) {
            _this.canEdit = r.canEdit;
            _this.assignRecords(r.records);
        });
        this.subscriptions.state = this.oshaService
            .subscribeToChangeState(function (s) { return _this.applyState(s); });
        this.subscriptions.export = this.oshaService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
        this.subscriptions.oshaForm = this.oshaService
            .subscribeToGenerateOSHAForm300A(function (val) { return _this.generateOshaForm(val); });
        this.subscriptions.dateRange = this.oshaService
            .subscribeToDateRange(function (val) { return _this.assignDateRange(val); });
        this.subscriptions.orgLevel = this.oshaService
            .subscribeToOrgLevel(function (o) { return _this.assignOrgLevel(o); });
        this.subscriptions.state = this.oshaService
            .subscribeToInitDateRange(function (r) { return _this.assignInitDateRange(r); });
        this.subscriptions.establishmentSize = this.oshaService
            .subscribeToestablishmentSize(function (s) { return _this.getEsSize(s); });
    };
    WcCreateOshaGridComponent.prototype.ngOnDestroy = function () {
        this.oshaService.destroy();
    };
    WcCreateOshaGridComponent.prototype.onGridStateChanged = function (state) {
        if (_.isObjectLike(state)) {
            this.gridState.updateStateWithDelay(state);
        }
    };
    WcCreateOshaGridComponent.prototype.isVisible = function (field) {
        return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].displayed : false;
    };
    WcCreateOshaGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    WcCreateOshaGridComponent.prototype.assignRecords = function (records) {
        var filteredOshaRecords = _.filter(records, function (r) { return r && r.isOSHARecordable; });
        this.records = filteredOshaRecords;
        this.onToggleAllSelected();
        this.storedRecords = _.clone(records);
        this.refreshGrid();
    };
    WcCreateOshaGridComponent.prototype.applyState = function (s) {
        this.state = s;
    };
    WcCreateOshaGridComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    WcCreateOshaGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    WcCreateOshaGridComponent.prototype.onToggleAllSelected = function () {
        var _this = this;
        _.forEach(this.records, function (record) {
            record.isSelected = _this.isAllSelected;
        });
        this.selectionChange();
    };
    WcCreateOshaGridComponent.prototype.selectionChange = function () {
        this.selectedRecords = _.filter(this.records, function (record) { return record.isSelected; });
        this.isAllSelected = this.records && this.selectedRecords ? this.records.length === this.selectedRecords.length : false;
    };
    WcCreateOshaGridComponent.prototype.onOpenReport = function (entry) {
        this.oshaService.openEditReportDialog(entry.id);
    };
    WcCreateOshaGridComponent.prototype.assignInitDateRange = function (r) {
        if (_.isDate(r.startDate) && _.isDate(r.endDate)) {
            this.dateRange = r;
        }
    };
    WcCreateOshaGridComponent.prototype.assignOrgLevel = function (o) {
        if (o) {
            this.orgLevel = o.id;
            this.orgLevelRelatedId = o.relatedItemId;
        }
        else {
            this.orgLevel = null;
        }
    };
    WcCreateOshaGridComponent.prototype.getSelectedRecords = function () {
        if (this.selectedRecords.length > 0) {
            return this.selectedRecords;
        }
        else {
            return this.records;
        }
    };
    WcCreateOshaGridComponent.prototype.assignDateRange = function (r) {
        if (_.isDate(r.startDate) &&
            _.isDate(r.endDate)) {
            this.dateRange = r;
        }
    };
    WcCreateOshaGridComponent.prototype.generateOshaForm = function (val) {
        if (val === this.generateOshaFormAction.generateOSHAForm300ACSV) {
            this.excelOsha300();
        }
        else if (val === this.generateOshaFormAction.generateOSHAForm300APDF) {
            this.exportToPDFOsha300();
        }
        else if (val === this.generateOshaFormAction.generateOSHAForm301APDF) {
            this.exportToPDFOsha301PDF();
        }
    };
    ;
    WcCreateOshaGridComponent.prototype.getEsSize = function (s) {
        this.establishmentSize = s.establishmentSize;
    };
    WcCreateOshaGridComponent.prototype.excelOsha300 = function () {
        var _this = this;
        var items = this.getSelectedRecords() || [];
        WcCreateOshaFormExcelDialogComponent.openDialog(this.modalService, function (result) {
            if (result) {
                if (_this.establishmentSize == null) {
                    var componentEstSizeDialog = WcCreateEstablishmentSizeDialogComponent.openDialog(_this.modalService, function (result) {
                        if (result) {
                            _this.oshaService.updateEstablismentSize(_this.orgLevelRelatedId, componentEstSizeDialog.size).then(function () {
                                _this.callToGenerateOshaExcel300(items);
                            });
                        }
                    });
                }
                else {
                    _this.callToGenerateOshaExcel300(items);
                }
            }
        });
    };
    WcCreateOshaGridComponent.prototype.exportToPDFOsha300 = function () {
        var items = this.getSelectedRecords();
        this.oshaService.generateOshaPDF300(this.orgLevel, this.dateRange.startDate, this.dateRange.endDate, items);
    };
    WcCreateOshaGridComponent.prototype.exportToPDFOsha301PDF = function () {
        var items = this.getSelectedRecords();
        this.oshaService.generateOshaPDF301(this.orgLevel, items);
    };
    WcCreateOshaGridComponent.prototype.callToGenerateOshaExcel300 = function (items) {
        this.oshaService.generateOshaExcel300(this.orgLevel, this.dateRange.startDate.getFullYear(), items);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcCreateOshaGridComponent.prototype, "subscriptions", void 0);
    return WcCreateOshaGridComponent;
}());
export { WcCreateOshaGridComponent };
