import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { ActivatedRoute } from '@angular/router';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { LookupApiService, PayCycle } from '../../../organization/index';
import { appConfig } from '../../../app.config';
import { AccrualsManagementService } from './accruals-management.service';
import { AccrualsApiService } from './accruals-api.service';
import { initialBalancesState } from '../models/index';
import { AccrualBalanceColumnsSettings, AccrualBalanceSettings } from '../models/accruals-column-settings';
import { ColumnManagementService, ColumnSettingsStorageService } from '../../../common/services/index';
var AccrualsBalancesManagementService = /** @class */ (function () {
    function AccrualsBalancesManagementService(manService, apiService, lookupApiService, activatedRoute, columnSettingsStorageService, columnManagementService) {
        this.manService = manService;
        this.apiService = apiService;
        this.lookupApiService = lookupApiService;
        this.activatedRoute = activatedRoute;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.columnManagementService = columnManagementService;
        this.defaultDate = new Date();
        this.loading$ = new Subject();
        this.recordsLoaded$ = new Subject();
        this.effectiveDate$ = new ReplaySubject(1);
        this.exportTo$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.stateChanged$ = new BehaviorSubject(initialBalancesState);
        this.calculateAccrualsAction$ = new Subject();
        this.columnState$ = new Subject();
        this.componentId = "AccrualsBalancesComponent";
        this.columnsStateName = 'AccrualsBalances';
        this.subscriptions = {};
    }
    AccrualsBalancesManagementService.prototype.init = function () {
        this.subscribeToUrlParam();
        this.subscribeToOrgLevelChanges();
        this.subscribeToLoadAccrualsBalances();
    };
    AccrualsBalancesManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.effectiveDate$.complete();
        this.exportTo$.complete();
        this.orgLevelChanged$.complete();
        this.stateChanged$.complete();
        this.calculateAccrualsAction$.complete();
        this.columnState$.complete();
    };
    AccrualsBalancesManagementService.prototype.getDefaultEffectiveDate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payCycle;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.currentPayCycle];
                    case 1:
                        payCycle = _a.sent();
                        return [2 /*return*/, payCycle ? payCycle.endDate : this.defaultDate];
                }
            });
        });
    };
    AccrualsBalancesManagementService.prototype.changeEffectiveDate = function (effectiveDate) {
        this.effectiveDate = effectiveDate;
        this.effectiveDate$.next(effectiveDate);
        this.loadAccrualsBalances();
    };
    AccrualsBalancesManagementService.prototype.updateState = function (state) {
        this.stateChanged$.next(state);
    };
    AccrualsBalancesManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    AccrualsBalancesManagementService.prototype.saveColumnState = function (columnState) {
        this.columnSettingsStorageService.setColumnsState(this.componentId, this.columnsStateName, columnState.allColumns);
    };
    AccrualsBalancesManagementService.prototype.setColumnState = function (columnState) {
        this.columnState$.next(_.cloneDeep(columnState));
        this.saveColumnState(columnState);
    };
    AccrualsBalancesManagementService.prototype.restoreSettings = function () {
        var _this = this;
        this.settings = new AccrualBalanceSettings();
        this.settings.columns = new AccrualBalanceColumnsSettings();
        this.settings.columns.createColumns();
        this.settings.columns.setDynamicColumns(this.accrualTypes);
        this.columnManagementService.init('AccrualsBalancesComponent');
        this.columnManagementService.initializeGroupWithColumns(this.columnsStateName, this.settings.columns.allColumns);
        this.subscriptions.state = this.columnManagementService.groupInitialized$
            .filter(function (event) { return event.group === _this.columnsStateName; })
            .subscribe(function () {
            _this.setColumnState(_this.settings.columns);
        });
    };
    AccrualsBalancesManagementService.prototype.initialColumnState = function () {
        this.settings = new AccrualBalanceSettings();
        this.settings.columns = new AccrualBalanceColumnsSettings();
        this.settings.columns.createColumns();
        this.settings.columns.setDynamicColumns(this.accrualTypes);
        return this.settings.columns;
    };
    AccrualsBalancesManagementService.prototype.subscribeToColumnState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.columnState$.subscribe(callback);
    };
    AccrualsBalancesManagementService.prototype.subscribeToState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.stateChanged$.subscribe(callback);
    };
    AccrualsBalancesManagementService.prototype.subscribeToEffectiveDate = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.effectiveDate$.subscribe(callback);
    };
    AccrualsBalancesManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    AccrualsBalancesManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    AccrualsBalancesManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    AccrualsBalancesManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    AccrualsBalancesManagementService.prototype.subscribeTocalculateAccrualsAction = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.calculateAccrualsAction$.subscribe(callback);
    };
    AccrualsBalancesManagementService.prototype.loadAccrualsBalances = function () {
        var _this = this;
        if (!_.isFinite(_.get(this.orgLevel, 'id')) || !_.isDate(this.effectiveDate)) {
            return;
        }
        this.loading$.next(true);
        this.apiService.getAccrualBalances(this.orgLevel.id, this.effectiveDate)
            .then(function (container) {
            _this.recordsLoaded$.next(container);
            _this.calculateAccrualsAction$.next(container.calculateAccruals);
            _this.loading$.next(false);
            _this.accrualTypes = container.accrualTypes;
            _this.restoreSettings();
        })
            .catch(function () {
            _this.loading$.next(false);
            _this.restoreSettings();
        });
    };
    AccrualsBalancesManagementService.prototype.subscribeToUrlParam = function () {
        var _this = this;
        this.activatedRoute.queryParams.subscribe(function (queryParams) {
            _this.filterByDate = queryParams ? (queryParams.filterByDate ? queryParams.filterByDate : null) : null;
        });
    };
    AccrualsBalancesManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) {
            if (_.isUndefined(_this.filterByDate) || _.isNull(_this.filterByDate)) {
                if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                    return;
            }
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
            _this.currentPayCycle = _this.getCurrentPayCycle();
        });
    };
    AccrualsBalancesManagementService.prototype.subscribeToLoadAccrualsBalances = function () {
        var _this = this;
        this.subscriptions.loadAccrualsBalances = this.manService.subscribeToLoadAccrualsBalances(function () { return _this.loadAccrualsBalances(); });
    };
    AccrualsBalancesManagementService.prototype.getCurrentPayCycle = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentPayCycle, payCycles, currentDate_1, currentCycles;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!_.isFinite(_.get(this.orgLevel, 'id'))) {
                            return [2 /*return*/];
                        }
                        currentPayCycle = new PayCycle();
                        if (!this.filterByDate) return [3 /*break*/, 1];
                        currentPayCycle.startDate = moment(this.filterByDate, appConfig.linkDateFormat).toDate();
                        currentPayCycle.endDate = moment(this.filterByDate, appConfig.linkDateFormat).toDate();
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.lookupApiService.getPayCyles(this.orgLevel.id)];
                    case 2:
                        payCycles = _a.sent();
                        currentDate_1 = moment();
                        currentCycles = _.filter(payCycles, function (cycle) {
                            var currStartDate = moment(cycle.startDate);
                            var currEndDate = moment(cycle.endDate);
                            return moment(currentDate_1).isBetween(currStartDate, currEndDate);
                        });
                        currentPayCycle = _.first(currentCycles);
                        _a.label = 3;
                    case 3:
                        this.changeEffectiveDate(currentPayCycle ? currentPayCycle.endDate : this.defaultDate);
                        return [2 /*return*/, currentPayCycle];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AccrualsBalancesManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], AccrualsBalancesManagementService.prototype, "subscriptions", void 0);
    return AccrualsBalancesManagementService;
}());
export { AccrualsBalancesManagementService };
