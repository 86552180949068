import * as _ from 'lodash';
import { IColumnSettings } from '../../../core/models/index';

export class TimecardsColumnState {
  public columnsMap: StringMap<IColumnSettings>;
  public columns: IColumnSettings[];
  public get width(): number {
    let w: number = 0;
    _.forEach(this.columns, (col: IColumnSettings) => {
      if (col.displayed) {
        w += col.width;
      }
    });
    return w;
  }
  public mapColumns(): void {
    this.columnsMap = _.keyBy(this.columns, (column: IColumnSettings) => {
      return column.name;
    });
  }
  public setState(initialState: StringMap<boolean>, defaultState: boolean): void {
    _.forEach(this.columns, (column: IColumnSettings) => {
      if (initialState[column.name] === undefined) {
        column.displayed = defaultState;
      } else {
        column.displayed = initialState[column.name];
      }
    });
  }
}
