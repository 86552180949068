import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State, GroupDescriptor } from '@progress/kendo-data-query';
import { GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Assert } from '../../../../framework/index';
import { appConfig } from '../../../../app.config';
import { GenericListManagementService } from '../../../services/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { IGenericGridConfig } from './generic-grid.config';
import { PaperSizes, UnscaledGridColumnWidthMM, A4 } from '../../../../common/models/media/paper-sizes';
import { MetricLengthUnit, millimeter, convertUnit, getMetricLengthUnitByName } from '../../../../common/models/media/metric-units';
var GenericGridComponent = /** @class */ (function () {
    function GenericGridComponent(genericListManagementService, changeDetector) {
        var _this = this;
        this.pageSize = 30;
        this.applyStyle = true;
        this.columnCustomCssClass = null;
        Assert.isNotNull(genericListManagementService, 'genericListManagementService');
        this.genericListManagementService = genericListManagementService;
        this.changeDetector = changeDetector;
        this.employeeLinkClick = new EventEmitter();
        this.selectionChanged = new EventEmitter();
        this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe(function (defs) {
            _this.listDefinitions = defs;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe(function (list) {
            _this.listValues = list;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.appConfig = appConfig;
    }
    Object.defineProperty(GenericGridComponent.prototype, "isPdfExportConfigured", {
        get: function () {
            return !!_.get(this.genericGridConfig, 'pdfExport', null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenericGridComponent.prototype, "isExcelExportConfigured", {
        get: function () {
            return !!_.get(this.genericGridConfig, 'excelExport', null);
        },
        enumerable: true,
        configurable: true
    });
    GenericGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    GenericGridComponent.prototype.exportExcelData = function () {
        var _this = this;
        return function () {
            var exportedDate = {
                data: _this.genericGridConfig.excelExport.allPages ?
                    process(_this.listValues.rows, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
                    : _this.gridState.view.data
            };
            return exportedDate;
        };
    };
    GenericGridComponent.prototype.getFilter = function (type) {
        if (type === 'int')
            return 'numeric';
        if (type === 'boolean')
            return 'boolean';
        if (type === 'date')
            return 'date';
        if (type === 'datetime')
            return 'date';
        return null;
    };
    GenericGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper(this.gridKey ? this.gridKey : 'defaultGenericGrid');
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    GenericGridComponent.prototype.onToggleAllSelected = function (isAllSelected) {
        var _this = this;
        var rows = [];
        var stateValue = Object.assign({}, this.gridState.state);
        stateValue.skip = 0;
        stateValue.group = [];
        stateValue.take = this.listValues.rows.length;
        var filteredRows = process(this.listValues.rows, stateValue).data;
        _.forEach(filteredRows, function (row) {
            if (row.selectable) {
                row.selected = _this.isAllSelected;
            }
            else {
                row.selected = false;
            }
            if (row.selected) {
                rows.push(row);
            }
        });
        this.genericListManagementService.onRowsSelected(rows);
        this.selectionChanged.emit(rows);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    GenericGridComponent.prototype.selectionChange = function (row) {
        var rows = _.filter(this.listValues.rows, function (row) {
            return row.selected;
        });
        this.genericListManagementService.onRowsSelected(rows);
        this.selectionChanged.emit(rows);
        var stateValue = Object.assign({}, this.gridState.state);
        stateValue.skip = 0;
        stateValue.take = this.listValues.rows.length;
        stateValue.group = [];
        var filteredRows = process(this.listValues.rows, stateValue).data;
        var selectableRows = _.filter(filteredRows, function (selectableRow) {
            return selectableRow.selectable;
        });
        var allSelectedRows = _.every(selectableRows, function (selectedRow) {
            return selectedRow.selected;
        });
        if (allSelectedRows) {
            this.isAllSelected = true;
        }
        if (!row.selected) {
            this.isAllSelected = false;
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    GenericGridComponent.prototype.onEmployeeLinkClick = function (empId) {
        this.employeeLinkClick.emit(empId);
    };
    GenericGridComponent.prototype.exportToExcel = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.gridElem.saveAsExcel();
    };
    GenericGridComponent.prototype.exportToPdf = function () {
        var _this = this;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        if (this.genericGridConfig.pdfExport.autoscale) {
            var fieldCount_1 = 0;
            _.each(this.genericListManagementService.listDefinition.fields, function (field) {
                fieldCount_1++;
            });
            var paper = _.find(PaperSizes, function (paper) {
                return paper.name === _this.genericGridConfig.pdfExport.paperSize;
            });
            var width = void 0;
            if (paper) {
                width = this.genericGridConfig.pdfExport.landscape ? paper.heightMM : paper.widthMM;
            }
            else {
                width = this.genericGridConfig.pdfExport.landscape ? A4.heightMM : A4.widthMM;
            }
            var leftMargin = this.genericGridConfig.pdfExport.left;
            if (leftMargin) {
                width = this.subtractMargin(width, leftMargin);
            }
            var rightMargin = this.genericGridConfig.pdfExport.right;
            if (rightMargin) {
                width = this.subtractMargin(width, rightMargin);
            }
            this.genericGridConfig.pdfExport.scale = width / (fieldCount_1 * UnscaledGridColumnWidthMM);
        }
        this.refreshGrid();
        this.gridElem.saveAsPDF();
    };
    GenericGridComponent.prototype.groupStateChanged = function (groups) {
        this.validateGroups(groups);
    };
    GenericGridComponent.prototype.reCheckSelectAll = function (currentRows) {
        var rows = [];
        for (var _i = 0, currentRows_1 = currentRows; _i < currentRows_1.length; _i++) {
            var row = currentRows_1[_i];
            if (!row.selectable) {
                continue;
            }
            if (row.selected) {
                rows.push(row);
            }
            else {
                this.isAllSelected = false;
            }
        }
        this.genericListManagementService.onRowsSelected(rows);
        this.selectionChanged.emit(rows);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    GenericGridComponent.prototype.subtractMargin = function (width, margin) {
        var unit;
        var value;
        value = this.getMarginValue(margin);
        unit = getMetricLengthUnitByName(this.getMarginUnit(margin));
        if (!isNaN(value)) {
            if (unit && unit !== millimeter) {
                value = convertUnit(unit, millimeter, value);
            }
            width -= value;
        }
        return width;
    };
    GenericGridComponent.prototype.getMarginUnit = function (margin) {
        var unit;
        var value = parseFloat(margin);
        if (!isNaN(value))
            unit = margin.replace(value.toString(10), '');
        return unit;
    };
    GenericGridComponent.prototype.getMarginValue = function (margin) {
        return parseFloat(margin);
    };
    GenericGridComponent.prototype.validateGroups = function (groups) {
        var _this = this;
        var validGroups = [];
        var hadInvalid = false;
        _.each(groups, function (groupField) {
            if (_this.isGroupFieldValid(groupField)) {
                validGroups.push(groupField);
            }
            else {
                hadInvalid = true;
            }
        });
        this.gridState.state.group = validGroups;
        if (hadInvalid)
            this.refreshGrid();
    };
    GenericGridComponent.prototype.isGroupFieldValid = function (groupField) {
        var valid = true;
        if (!groupField.field) {
            valid = false;
        }
        else {
            if (groupField.field.indexOf('Selectable') !== -1) {
                valid = false;
            }
            if (this.genericGridConfig && this.genericGridConfig.groupFieldCustomValidation) {
                if (!this.genericGridConfig.groupFieldCustomValidation(groupField))
                    valid = false;
            }
        }
        return valid;
    };
    GenericGridComponent.prototype.pageChange = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    GenericGridComponent.prototype.refreshGrid = function () {
        if (!this.listValues || !this.listValues.rows) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.listValues.rows, this.gridState.state);
        this.reCheckSelectAll(this.listValues.rows);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], GenericGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], GenericGridComponent.prototype, "definitionsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], GenericGridComponent.prototype, "valuesLoadedSubscription", void 0);
    return GenericGridComponent;
}());
export { GenericGridComponent };
