
<label [attr.class]="cssClass()">
  <input type="radio" class="slx-radio__radio"
    [disabled]="readonly ? true : null"
    [(ngModel)]="value"
    [checked]="isChecked()"
    [value]="option"
    name="fieldName" >
  <span class="slx-radio__label">{{caption}}</span>
</label>
