<kendo-numerictextbox
  #textbox
  [class.active] = "isInputActive"
  [value]="currentValue"
  (valueChange)="onValueChanged($event)"
  [step]="step"
  [min]="min"
  [max]="max"
  [spinners]="spinners"
  [format]="format"
  [decimals]="decimals"
  [autoCorrect]="autoCorrect"
  [disabled]="readonly"
  [placeholder]="placeholder"
  (blur)="onBlur(currentValue)"
  (onFocus)="onFocus(currentValue)"
></kendo-numerictextbox>
