export interface IMessageCenter {
}

export class MessageCenter {
}

export enum MessageSortingOptions {
    Newest_To_Oldest = 'Newest To Oldest',
    Oldest_To_Newest = 'Oldest To Newest',
    Name_A_to_Z = 'Name A to Z',
    Name_Z_to_A = 'Name Z to A'
}

export interface IMessageFilterState {
  isMyMessage: boolean;
}

export const initialMessageFilterState: IMessageFilterState = {
  isMyMessage: true
};
