import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll, mutableSelect } from '../../../../core/decorators/index';
import { Observable } from 'rxjs';
import { StateManagementService, ColumnManagementService } from '../../../../common';
import { AcaMeasurementManagementService } from '../../../services/aca-measurement/aca-measurement-management.service';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { AcaMeasurementSettings, AcaMeasurementColumnsSettings } from '../../../models/aca-measurement/aca-measurement-settings';
import { IAcaMeasurementState, AcaMeasurementFilterState, EmployeeIdList } from '../../../models/aca-measurement/aca-measurement-state';
import { AcaMeasurementActions } from '../../../models/aca-measurement/aca-measurement-actions';
var AcaMeasurementToolbarComponent = /** @class */ (function () {
    function AcaMeasurementToolbarComponent(acaManagementService, columnManagementService, stateManagement) {
        this.acaManagementService = acaManagementService;
        this.columnManagementService = columnManagementService;
        this.stateManagement = stateManagement;
        this.currentYear = moment().startOf('day').toDate().getFullYear();
        this.yearOptions = [];
        this.employeeIdList = [];
        this.acaFilterState = new AcaMeasurementFilterState();
        this.columnsStateName = 'AcaMeasurement';
        this.stateKey = 'AcaMeasurementState';
        this.subscriptions = {};
    }
    Object.defineProperty(AcaMeasurementToolbarComponent.prototype, "defaultYear", {
        get: function () {
            var year = moment().startOf('day').toDate().getFullYear();
            var month = moment().startOf('day').toDate().getMonth();
            if (month < 3) {
                year = year - 1;
            }
            return year;
        },
        enumerable: true,
        configurable: true
    });
    AcaMeasurementToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createYearOptions();
        this.selectedYear = this.defaultYear;
        this.subscriptions.onLoaded = this.acaManagementService
            .subscribeToEmployeeFilter(function (records) {
            _this.filter = records.filter;
            _this.employeeFilterRecords = records.employeeFilterRecord;
        });
        this.subscriptions.onLoaded = this.acaManagementService
            .subscribeToLoadedRecords(function (container) {
            if (!_this.actions) {
                _this.actions = new AcaMeasurementActions();
                _this.actions = container.actions;
                _this.initServices();
            }
        });
    };
    AcaMeasurementToolbarComponent.prototype.createYearOptions = function () {
        this.yearOptions = _.reverse(_.values(_.range(2016, this.currentYear + 1)));
    };
    AcaMeasurementToolbarComponent.prototype.ngOnDestroy = function () { };
    AcaMeasurementToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.acaManagementService.exportTo(isPDF);
    };
    AcaMeasurementToolbarComponent.prototype.initServices = function () {
        this.restoreSettings();
        this.stateManagement.init('AcaMeasurementComponent');
        this.columnManagementService.init('AcaMeasurementComponent');
        this.columnManagementService.initGroup(this.columnsStateName, 6);
        this.columnManagementService.initializeGroupWithColumns(this.columnsStateName, this.settings.columns.columns);
    };
    AcaMeasurementToolbarComponent.prototype.getAcaWidth = function () {
        return (screen.width <= appConfig.mobileMaxWidth) ? 30 : 130;
    };
    AcaMeasurementToolbarComponent.prototype.onChangeYear = function (selYear) {
        this.selectedYear = selYear;
        this.acaManagementService.setSelectedYear(selYear);
    };
    AcaMeasurementToolbarComponent.prototype.onChangeFilter = function (filters) {
        this.acaManagementService.setEmployeeFilter(filters);
    };
    AcaMeasurementToolbarComponent.prototype.restoreSettings = function () {
        this.settings = new AcaMeasurementSettings();
        this.settings.columns = new AcaMeasurementColumnsSettings();
        this.settings.columns.createColumns(this.actions);
    };
    AcaMeasurementToolbarComponent.prototype.onExpandCollapse = function (isExpand) {
        this.acaManagementService.isExpandAll(isExpand);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AcaMeasurementToolbarComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaMeasurementToolbarComponent.prototype, "subscriptions", void 0);
    return AcaMeasurementToolbarComponent;
}());
export { AcaMeasurementToolbarComponent };
