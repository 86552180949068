import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, Provider, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent, PageChangeEvent
} from '@progress/kendo-angular-grid';

import { IFilteredItems, ServerCompositeFilterDescriptor, PagingData, MetadataInfo } from '../../../../../core/models/index';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { PbjExportApiService } from '../../../services/index';
import { PBJExportLogStaffingHours } from '../../../models/index';
import { ServerFilterService } from '../../../../../core/services/index';
import { PbjDetailsBasicGridComponent } from '../pbj-details-basic-grid/pbj-details-basic-grid.component';
import { GridExportType } from '../../../models/pbj-export/grid-export-type';
import { StateManagementService } from '../../../../../common/services/index';
import { PbjExportColumn } from 'app/app-modules/pbj/models/pbj-export/pbj-export-column';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-details-staffing-hours-grid',
  templateUrl: 'pbj-details-staffing-hours-grid.component.html',
  styleUrls: ['pbj-details-staffing-hours-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServerFilterService, StateManagementService]
})
export class PbjDetailsStaffingHoursGridComponent extends PbjDetailsBasicGridComponent<PBJExportLogStaffingHours> implements OnInit, OnChanges {

  @Input()
  public exportId: number;

  @Output()
  public loadStatus: EventEmitter<boolean>;

  public get exportFilename(): string {
    return `export-${this.exportId}-staffing-hours-data`;
  }

  protected readonly controlKey = 'PbjExportStaffingHoursDataComponent';

  constructor(apiService: PbjExportApiService, serverFilterService: ServerFilterService, changeDetector: ChangeDetectorRef, stateManagement: StateManagementService) {
    super(apiService, serverFilterService, changeDetector, stateManagement);
  }

  public refreshData(): void {
    this.onLoadStatus(true);
    this.apiService.getPBJExportLogStaffingHours(this.serverFilterService.createQuery(this.pagingData, this.gridState.state.sort), this.exportId)
      .then((val: IFilteredItems<PBJExportLogStaffingHours>) => {
        this.records = val;
        this.onLoadStatus(false);
        this.showChanges();
      }).catch((reason: any) => {
        this.onLoadStatus(false);
      });
  }

  public loadAndExport(type: GridExportType): void {
    this.onLoadStatus(true);
    let zeroPaging: PagingData = { skip: 0 };
    this.apiService.getPBJExportLogStaffingHours(this.serverFilterService.createQuery(zeroPaging, this.gridState.state.sort), this.exportId)
      .then((val: IFilteredItems<PBJExportLogStaffingHours>) => {
        this.processExportData(type, val);
        this.onLoadStatus(false);
      }).catch((reason: any) => {
        this.onLoadStatus(false);
        this.exportRecords = null;
      });
  }

  protected updateColumnsConfig(): void {
    super.updateColumnsConfig();
    const specificColumns: PbjExportColumn[] = [
      { field: 'employeeName', displayTitle: 'Name', index: 1, width: 150 },
      { field: 'payrollNumber', displayTitle: 'Payroll No', index: 2, width: 150 },
      { field: 'workDate', displayTitle: 'Work Date', index: 3, width: 150 },
      { field: 'workHours', displayTitle: 'Work Hours', index: 4, width: 150 },
      { field: 'cmsJobCode', displayTitle: 'CMS Job Code', index: 5, width: 150 },
      { field: 'cmsJobNameTitle', displayTitle: 'CMS Job Title', index: 6, width: 150 },
      { field: 'exemptType', displayTitle: 'Exempt Type', index: 7, width: 150 },
    ];
    this.xlsExportColumns.push(...specificColumns);
  }

  protected applyDefaultSort(): void {
    this.gridState.state.sort = [{ field: 'employeeName', dir: 'asc' }, { field: 'workDate', dir: 'asc' }];
  }

}

