<div class="slx-datepicker">
  <span style="display: none;" id="_selectedDateVal">{{currentValue}}</span>
    <label *ngIf="getPlaceholder()" class="slx-datepicker__label">{{ placeholder }}:</label>
    <kendo-datepicker class="slx-datepicker__kendo {{ cssClass }}" [ngClass]="{'slx-read-only-input' : readOnlyInput}"
      name="dateInput"
      [min]="minDate"
      [max]="maxDate"
      [format]="format"
      [disabled]="readonly"
      [disabledDates]="disabledDates"
      [value]="currentValue"
      (valueChange)="onValueChanged($event)"
      (open)="onCalendarOpen()"
      (close)="onCalendarClose()"
      (blur)="onBlurInput()"
    >
      <ng-template kendoCalendarCellTemplate let-date>
        <span [ngClass]="getCalendarCellClass(date)">{{ date.getDate() }}</span>
      </ng-template>
    </kendo-datepicker>
</div>
