import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { ResponseBody, Meta } from '../../../core/models/index';
import { punchAttestationConfig } from '../punch-attestation.config';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import {
  IPunchAttestationGroup, PunchAttestationGroup,
  IPunchAttestationDeletionResponse, PunchAttestationDeletionResponse
} from '../models/index';
import { PunchAttestationMapService } from './punch-attestation-map.service';
import { IPunchAttestationGroupPermissions, PunchAttestationGroupPermissions } from '../models/punch-attestation-group-permissions';

@Injectable()
export class PunchAttestationApiService {
  constructor(
    private mapService: PunchAttestationMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public updateGroup(orgLevelId: number, group: PunchAttestationGroup): Promise<PunchAttestationGroup[]> {
    const url: string = `${this.getApiRoot()}/${punchAttestationConfig.api.punchAttestation.orgLevel.root}/${orgLevelId}`;
    let req = this.urlParamsService.createPutRequest(url, this.mapService.mapPeriodDto(group));
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(req)
      .then((response: ResponseBody<IPunchAttestationGroup[], Meta>) => {
        return response.data
      });
    return promise;
  }

  public deleteGroup(orgLevelId: number, groupId: number): Promise<PunchAttestationDeletionResponse> {
    const url: string = `${this.getApiRoot()}/${punchAttestationConfig.api.punchAttestation.orgLevel.root}/${orgLevelId}`;

    let req = this.urlParamsService.createDeleteRequest(url, {
      groupId
    });
    let promise: Promise<PunchAttestationDeletionResponse> = this.apiUtilService.request<IPunchAttestationDeletionResponse, Meta>(req)
      .then((response: ResponseBody<IPunchAttestationDeletionResponse, Meta>) => {
        return this.mapService.mapDeletionResponse(response.data);
      });
    return promise;
  }

  public deleteGroupQuestion(orgLevelId: number, groupId: number, questionId: number): Promise<PunchAttestationGroup[]> {
    const url: string = `${this.getApiRoot()}/${punchAttestationConfig.api.punchAttestation.orgLevel.root}/${orgLevelId}/${groupId}/${questionId}`;

    let req = this.urlParamsService.createDeleteRequest(url, {
      groupId,questionId
    });
    let promise: Promise<PunchAttestationGroup[]> = this.apiUtilService.request<IPunchAttestationGroup[], Meta>(req)
      .then((response: ResponseBody<IPunchAttestationGroup[], Meta>) => {
        //return response.data;
        return this.mapService.mapPeriods(response.data);
      });
    return promise;
  }

  public getAllGroups(): Promise<PunchAttestationGroup[]> {
    const url: string = `${this.getApiRoot()}`;
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<PunchAttestationGroup[]> = this.apiUtilService.request<IPunchAttestationGroup[], Meta>(req)
      .then((response: ResponseBody<IPunchAttestationGroup[], Meta>) => {
        // return response.data;
         return this.mapService.mapPeriods(response.data);
      });
    return promise;
  }
  
  public getPermissions(orgLevelId: number): Promise<PunchAttestationGroupPermissions> {
    const url: string = `${this.getApiRoot()}/rolepermission/${punchAttestationConfig.api.punchAttestation.orgLevel.root}/${orgLevelId}`;
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    let promise: Promise<PunchAttestationGroupPermissions> = this.apiUtilService.request<IPunchAttestationGroupPermissions, Meta>(req)
      .then((response: ResponseBody<IPunchAttestationGroupPermissions, Meta>) => {
        // return response.data;
         return this.mapService.mapPermissions(response.data);
      });
    return promise;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${punchAttestationConfig.api.root}/${punchAttestationConfig.api.punchAttestation.root}`;
  }
}
