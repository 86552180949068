import { IPayloadAction } from '../../../state-model/models/index';
import { WizardActions } from './wizard.actions';
import { IWizardStateRecord } from './wizard.types';
import {
  INITIAL_WIZARD_STATE
} from './wizard.initial-state';

export function wizardStateReducer(state: IWizardStateRecord = INITIAL_WIZARD_STATE, action: IPayloadAction): IWizardStateRecord {
  switch (action.type) {
    case '@@INIT':
      return INITIAL_WIZARD_STATE;
    case WizardActions.SELECT_NEXT_STEP:
      let nextStep: number = state.step + 1;
      return state.merge({
        step: nextStep
      });
    case WizardActions.SELECT_PREV_STEP:
      let prevStep: number = state.step > 1 ? state.step - 1 : 1;
      return state.merge({
        step: prevStep
      });
    case WizardActions.SET_STEP:
      return state.merge({
        step: action.payload
      });
    case WizardActions.RESTORE_STEP:
      let rStep: number = state.step ? state.step : 1;
      return state.merge({
        step: rStep
      });
    default:
      return state;
  }
}
