import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ComponentStateStorageService } from '../component-state/component-state-storage.service';
import { ColumnSettingsStorageService } from '../column-settings/column-settings-storage.service';
var StateManagementService = /** @class */ (function () {
    function StateManagementService(componentStateStorageService, columnSettingsStorageService) {
        this.componentStateStorageService = componentStateStorageService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.restoredInitComponents = {};
        this.onInit$ = new ReplaySubject(1);
        this.onComponentActiveStateChanged$ = new ReplaySubject(1);
        this.loadData$ = new ReplaySubject(1);
        this.loadedData$ = new ReplaySubject(1);
        this.changes$ = new ReplaySubject(1);
        this.onControlRestored$ = new ReplaySubject(1);
    }
    Object.defineProperty(StateManagementService.prototype, "componentKey", {
        get: function () {
            return this.m_componentKey;
        },
        enumerable: true,
        configurable: true
    });
    StateManagementService.prototype.init = function (componentKey, manualLoadManagement) {
        var _this = this;
        this.isInitialized = true;
        this.m_componentKey = componentKey;
        this.m_controls = [];
        this.m_grids = [];
        this.m_restoredControls = 0;
        this.manualLoadManagement = !!manualLoadManagement;
        Promise.all([
            this.componentStateStorageService.restoreServerControlsStorage(this.m_componentKey),
            this.columnSettingsStorageService.restoreServerColumnsState(this.m_componentKey)
        ]).then(function (value) {
            _this.restoredInitComponents = {};
            _this.onInit$.next(null);
        });
    };
    StateManagementService.prototype.controlValueChanged = function (controlKey) {
        this.loadData$.next(null);
    };
    StateManagementService.prototype.registerControl = function (controlKey) {
        if (_.find(this.m_controls, function (key) { return key === controlKey; })) {
            throw new Error("element with same name already exist " + controlKey);
        }
        this.m_controls.push(controlKey);
    };
    StateManagementService.prototype.unregisterControl = function (controlKey) {
        _.remove(this.m_controls, function (key) { return key === controlKey; });
    };
    StateManagementService.prototype.registerGrid = function (controlKey) {
        if (_.find(this.m_grids, function (key) { return key === controlKey; })) {
            throw new Error("element with same name already exist " + controlKey);
        }
        this.m_grids.push(controlKey);
    };
    StateManagementService.prototype.unregisterGrid = function (controlKey) {
        _.remove(this.m_grids, function (key) { return key === controlKey; });
    };
    StateManagementService.prototype.controlRestored = function (controlKey, hadStateValue) {
        this.m_restoredControls++;
        this.onControlRestored$.next({ controlKey: controlKey, hadStateValue: hadStateValue });
    };
    StateManagementService.prototype.onComponentActiveStateChanged = function (context) {
        this.m_restoredControls = 0;
        this.onComponentActiveStateChanged$.next(context);
        this.loadDataAfterInit();
    };
    StateManagementService.prototype.loadedData = function (context) {
        this.loadedData$.next(context);
    };
    StateManagementService.prototype.loadData = function () {
        this.loadData$.next(null);
    };
    StateManagementService.prototype.changes = function (value) {
        this.changes$.next(null);
    };
    StateManagementService.prototype.getControlState = function (control, key) {
        return this.componentStateStorageService.getControlState(this.m_componentKey, control, key);
    };
    StateManagementService.prototype.setControlState = function (control, state, resetType, key) {
        return this.componentStateStorageService.setControlState(this.m_componentKey, control, state, resetType, key);
    };
    StateManagementService.prototype.getGridState = function (gridId, key) {
        return this.componentStateStorageService.getGridState(this.m_componentKey, gridId, key);
    };
    StateManagementService.prototype.setGridState = function (gridId, state, key) {
        return this.componentStateStorageService.setGridState(this.m_componentKey, gridId, state, key);
    };
    StateManagementService.prototype.mapValueToState = function (value, state) {
        this.componentStateStorageService.mapValueToState(value, state);
    };
    StateManagementService.prototype.mapValueFromState = function (state) {
        return this.componentStateStorageService.mapValueFromState(state);
    };
    StateManagementService.prototype.mapFiltersIntoState = function (filter) {
        return this.componentStateStorageService.mapFiltersIntoState(filter);
    };
    StateManagementService.prototype.mapStateIntoFilters = function (filter) {
        return this.componentStateStorageService.mapStateIntoFilters(filter);
    };
    StateManagementService.prototype.loadDataAfterInit = function () {
        if (this.manualLoadManagement) {
            return;
        }
        if (!this.m_controls || this.m_controls.length === 0 || this.m_controls.length === this.m_restoredControls) {
            this.loadData();
        }
    };
    return StateManagementService;
}());
export { StateManagementService };
