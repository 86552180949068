var VacationPlanEmployeeWeek = /** @class */ (function () {
    function VacationPlanEmployeeWeek() {
    }
    Object.defineProperty(VacationPlanEmployeeWeek.prototype, "safeDays", {
        get: function () {
            return this.days === 0 ? null : this.days.toString();
        },
        enumerable: true,
        configurable: true
    });
    return VacationPlanEmployeeWeek;
}());
export { VacationPlanEmployeeWeek };
