import * as _ from 'lodash';
import { Component, Input, OnInit, Output, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { AppServerConfig } from '../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app-settings/services/app-settings-manage.service';
import {
  BenefitEnrolledEmployee,
  BenefitTerminationReason,
  BenefitDetailsCalcMethod,
  EmployeeBenefitsEditResult,
  BenefitDetailsLine,
  BenefitDetailsTier,
  BenefitDetailsOption,
  BenefitEligibleEmployee,
  BenefitPayrollDeductionDates,
  BenefitPayrollDeduction
} from '../../../models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitEmployeesApiService, BenefitEmployeesMapService } from './../../../services/benefit-employees/index';
import { StateManagementService } from '../../../../../common/services/index';
import { BenefitEmployeeManagementService } from '../../../services/benefit-employees/benefit-employees-management.service';
import { InfoDialogComponent, ConfirmOptions, ConfirmDialog2Component } from './../../../../../common/components/index';
import { NotificationsService } from '../../../../../core/components/index';
import * as moment from 'moment';
@Component({
  moduleId: module.id,
  selector: 'slx-benefit-employees-enrollment',
  templateUrl: 'benefit-eligible-employees-enrollment.component.html',
  styleUrls: ['benefit-eligible-employees-enrollment.component.scss'],
  providers: [ StateManagementService ]
})
export class BenefitEmployeesEnrollmentComponent implements OnInit, OnDestroy {

  public get hasValidPlan(): boolean {
    if (this.calcMethod.isFlat) {
      return this.hasFlatEmpContribution && this.hasFlatErContribution;
    } else if (this.calcMethod.is401K) {
      return this.hasOptions;
    } else if (this.calcMethod.isFormula) {
      return this.hasFormula;
    } else if (this.calcMethod.isOptionsRates) {
      return this.hasOptions;
    }
    return true;
  }

  public get hasFlatEmpContribution(): boolean {
    return _.isNumber(this.tierDetails.empContribution);
  }

  public get hasFlatErContribution(): boolean {
    return _.isNumber(this.tierDetails.erContribution);
  }

  public get hasOptions(): boolean {
    return !_.isNil(this.tierDetails.options) && _.size(this.tierDetails.options) > 0;
  }

  public get hasFormula(): boolean {
    return this.coverageDefined && this.formulaEmployeeContributionDefined && this.formulaEmployerContributionDefined;
  }

  public get formulaEmployeeContributionDefined(): boolean {
    if (this.tierDetails && this.tierDetails.empFormula) {
      if (this.tierDetails.empFormula.isFixedCalcType && _.gte(this.tierDetails.empFormula.fixedAmount, 0)) {
        return true;
      } else if (this.tierDetails.empFormula.isFormulaCalcType) {
        return !_.isNil(this.tierDetails.empFormula.expression) && _.size(this.tierDetails.empFormula.expression) > 0;
      }
    }
    return false;
  }

  public get formulaEmployerContributionDefined(): boolean {
    if (this.tierDetails && this.tierDetails.erFormula) {
      if (this.tierDetails.erFormula.isFixedCalcType && _.gte(this.tierDetails.erFormula.fixedAmount, 0)) {
        return true;
      } else if (this.tierDetails.erFormula.isFormulaCalcType) {
        return !_.isNil(this.tierDetails.erFormula.expression) && _.size(this.tierDetails.erFormula.expression) > 0;
      }
    }
    return false;
  }

  public get coverageDefined(): boolean {
    if (this.tierDetails && this.tierDetails.coverageFormula) {
      if (this.tierDetails.coverageFormula.isFixedCalcType && _.gte(this.tierDetails.coverageFormula.fixedAmount, 0)) {
        return true;
      } else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
        return !_.isNil(this.tierDetails.coverageFormula.expression) && _.size(this.tierDetails.coverageFormula.expression) > 0;
      } else if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
        return true;
      } else if (this.tierDetails.coverageFormula.isAnyValueMaxCapCalcType && _.gt(this.tierDetails.coverageFormula.maxCap, 0)) {
        return true;
      } else {
        return false;
      }
    }
    return  false;;
  }

  public showCalculate: boolean;
  public isOptionDetails: boolean;
  public isNotes: boolean;
  public isOptions: boolean = true;
  public hasError: boolean = true;
  public notesText: string;
  public activeTab: number = 0;
  public benefitLineDetails: BenefitDetailsLine;
  public calcMethod: BenefitDetailsCalcMethod;
  public reason: BenefitTerminationReason;
  public appConfig: IApplicationConfig = appConfig;
  public selectedEmployeesList: number[] = [];
  public reasonList: BenefitTerminationReason[] = [];
  public data: BenefitDetailsOption[] = [];
  public updatedEmployeeInfo: BenefitEnrolledEmployee[] = [];
  public isLoading: boolean = false;
  public maxDate: Date = new Date(appConfig.maxCorrectDate);
  public minDate: Date = new Date(appConfig.minCorrectDate);
  public isDedBeforeBeneStartDt: boolean = false;
  public isDedAfterBeneEndDt: boolean = false;
  public minDedDate: Date;
  public maxDedDate: Date;
  public benfitPayrollDeductionDates: BenefitPayrollDeductionDates;
  public warningBeforeStart: string = "Payroll Deduction Start Date is set before the Benefit Coverage Start Date";
  public warningAfterEnd: string = "Payroll Deduction End Date is set after the Benefit Coverage End Date";
  public enableBenefitDeduction:boolean = false;
  @Input()
  public tierId: number;

  @Input()
  public lineId: number;

  @Input()
  public date: Date;

  @Input()
  public canEnroll: boolean;

  @Input()
  public planName: string;

  @Input()
  public method: string;

  @Input()
  public tierDetails: BenefitDetailsTier;

  @Input()
  public selectedEmployees: BenefitEligibleEmployee[];
  @Input()
  public benefitDetailsLine: BenefitDetailsLine;

  @Input()
  mappedDeduction: BenefitPayrollDeduction;

  @Input()
  public canMapPayroll: boolean;

  @Output()
  public onEnrollmentCancel: EventEmitter<true>;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(public modalService: ModalService,
    public apiService: BenefitEmployeesApiService,
    public manService: BenefitEmployeeManagementService,
    public notificationService: NotificationsService,
    public mapService: BenefitEmployeesMapService, 
    private appSettingsManageService: AppSettingsManageService) {
    this.onEnrollmentCancel = new EventEmitter();
  }

  public ngOnInit() {
    this.getSettings();
    this.manService.changeMode(false);
    this.calcMethod = new BenefitDetailsCalcMethod(this.method, this.method, this.method);
    this.setBenefitPayrollDedDates();
    this.updatedEmployeeInfo = this.mapService.mapEligibleToEnrolledEmployees(this.selectedEmployees);
    this.subscriptions.saveButtonState = this.manService.subscribeToIsChangeOccured((hasError: boolean) => {
      this.hasError = hasError;
    });

    this.subscriptions.emloyeeDetails = this.manService.subscribeToUpdatedEmployeeData((updatedEmployees: BenefitEnrolledEmployee[]) => {
      this.updatedEmployeeInfo = updatedEmployees;
    });
  }

  public ngOnDestroy() {

  }

  public onCancel(askConfirmation?: boolean): void {
    if(!askConfirmation) {
      this.onEnrollmentCancel.emit();
      this.manService.getBackToDetailsPage();
    } else if(askConfirmation) {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      options.buttonOKtext = 'Yes';
      options.buttonCanceltext = 'No';
      ConfirmDialog2Component.openDialog(
        'Confirmation',
        'Are you sure you want to cancel? You will lose all unsaved selections.',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.onEnrollmentCancel.emit();
          }
        }, options);
    }
  }

  public setBenefitPayrollDedDates(): void {

    this.benfitPayrollDeductionDates = new BenefitPayrollDeductionDates();
    const { startDate = null, endDate = null, payrollDeductionEndDate = null } = this.benefitDetailsLine || {};
    this.benfitPayrollDeductionDates.benefitDetailsLineStartDate = startDate;
    this.benfitPayrollDeductionDates.benefitDetailsLineEndDate = endDate;
    this.benfitPayrollDeductionDates.payrollDeductionStartDate = this.date;
    this.benfitPayrollDeductionDates.payrollDeductionEndDate = moment( _.isNull(this.benfitPayrollDeductionDates.payrollDeductionEndDate) ?  this.benfitPayrollDeductionDates.payrollDeductionEndDate : endDate).startOf('day').toDate();
    this.isDedBeforeBeneStartDt = !!_.lt(this.benfitPayrollDeductionDates.payrollDeductionStartDate, this.date);
    this.isDedAfterBeneEndDt = !!_.gt(this.benfitPayrollDeductionDates.payrollDeductionEndDate, this.benefitDetailsLine.endDate);

    if (_.isObjectLike(this.mappedDeduction)) {
      this.minDedDate = this.mappedDeduction.startDate ;
      this.maxDedDate = moment(this.mappedDeduction.endDate ? this.mappedDeduction.endDate : this.benefitDetailsLine.endDate).startOf('day').toDate();
    }

    if (_.gt(this.benfitPayrollDeductionDates.payrollDeductionEndDate, this.maxDedDate))
    {
      this.benfitPayrollDeductionDates.payrollDeductionEndDate = this.maxDedDate;
    }

  }

  public onChangeStartDate(): void {
    this.isDedBeforeBeneStartDt = !!_.lt(this.benfitPayrollDeductionDates.payrollDeductionStartDate, this.benefitDetailsLine.startDate);
  }
  public onChangeEndDate(): void {
    this.isDedAfterBeneEndDt = !!_.gt(this.benfitPayrollDeductionDates.payrollDeductionEndDate, this.benefitDetailsLine.endDate);
  }



  public costCalculation(): void {
    if (this.calcMethod.isOptionsRates) {
      this.updatedEmployeeInfo.forEach(employee => {
        employee.employeeContributionAmount = employee.optionCode.empContribution;
        employee.employerContributionAmount = employee.optionCode.erContribution;
      });
    }
    if (this.calcMethod.isFlat && _.isObjectLike(this.tierDetails)) {
      this.updatedEmployeeInfo.forEach(employee => {
        if (!_.isFinite(employee.employeeContributionAmount)) {
          employee.employeeContributionAmount = this.tierDetails.empContribution;
        }
        if (!_.isFinite(employee.employerContributionAmount)) {
          employee.employerContributionAmount = this.tierDetails.erContribution;
        }
      });
    }
  }

  public setOptionCode(): void {
    if (this.calcMethod.isFormulaWithOption) {
      this.updatedEmployeeInfo.forEach(employee => {
        employee.optionCode = employee.optionCode;
      });
    }
  }

  public setformulaValues():void {
    if ((this.calcMethod.isFormula || this.calcMethod.isFormulaWithOption) && _.isObjectLike(this.tierDetails)) {
      const { empFormula, erFormula, coverageFormula } = this.tierDetails;
      if (empFormula.isFixedCalcType || erFormula.isFixedCalcType || coverageFormula.isFixedCalcType) {
        _.forEach(this.updatedEmployeeInfo, (employee: BenefitEnrolledEmployee) => {
          if (empFormula.isFixedCalcType) {
            employee.employeeContributionAmount = empFormula.fixedAmount;
          }
          if (erFormula.isFixedCalcType) {
            employee.employerContributionAmount = erFormula.fixedAmount;
          }
          if (coverageFormula.isFixedCalcType) {
            employee.coverage = coverageFormula.fixedAmount;
          }
        });
      }
    }
  }
  public setPayrollDeductionDates(): void {
    _.forEach(this.updatedEmployeeInfo, (employee: BenefitEnrolledEmployee) => {
      employee.payrollDeductionStartDate = this.benfitPayrollDeductionDates.payrollDeductionStartDate;
      employee.payrollDeductionEndDate = this.benfitPayrollDeductionDates.payrollDeductionEndDate;
    });
  }

  public enrollEmployees(): void {
    this.isLoading = true;
    this.setOptionCode();
    this.costCalculation();
    this.setformulaValues();
    this.setPayrollDeductionDates();
    this.apiService.enrollEmployeesBenefit(this.updatedEmployeeInfo, this.notesText, this.date, this.lineId, this.tierDetails.id, this.calcMethod.id)
    .then((res: EmployeeBenefitsEditResult) => {
      if (res.isSuccess) {
        this.notificationService.success('Benefit Enrollment', 'Record saved successfully');
        this.onCancel(false);
      } else {
        InfoDialogComponent.OpenDialog('Warning', 'Something went wrong', this.modalService);
      }
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public isActiveMenu(activeTab: number): boolean {
    return (this.activeTab === activeTab) ? true : false;
  }

  public onClickTab(activeTab: number): void {
    const prevActiveTab = this.activeTab;
    this.activeTab = activeTab;
    this.isOptions = this.isOptionDetails = this.isNotes = false;
    switch (activeTab) {
      case 0: {
        this.isOptions = true;
        break;
      }
      case 1: {
        this.isOptionDetails = true;
        break;
      }
      case 2: {
        this.isNotes = true;
        break;
      }
      default: {
        this.isOptions = true;
        break;
      }
    }
    if (prevActiveTab !== activeTab) {
      this.manService.changeMode(true);
    }
  }

  public hasCoverageChanges(res: boolean) {
    this.showCalculate = res;
  }

  public calculateOrEnroll() {
    if (this.showCalculate) {
      this.manService.calculateCoverage();
      this.showCalculate = false;
    } else {
      this.enrollEmployees();
    }
  }

  private async getSettings(): Promise<void> {
    const config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.enableBenefitDeduction = config.isBenefitDeductionEnabled;
  }
}
