import { OnInit } from '@angular/core';
import { ModalService, DialogOptions, KendoGridStateHelper } from '../../../../common/index';
import { AccrualRecalculateDialogOptions } from '../../models';
import { GridComponent } from '@progress/kendo-angular-grid';
import { orderBy, process } from '@progress/kendo-data-query';
import { AccrualsApiService } from '../../services/accruals-api.service';
import * as _ from 'lodash';
import { appConfig } from '../../../../app.config';
var RecalculateDialogComponent = /** @class */ (function () {
    function RecalculateDialogComponent(dialogOptions, modalService, apiService) {
        this.dialogOptions = dialogOptions;
        this.modalService = modalService;
        this.apiService = apiService;
        this.accrualRecalculate = new AccrualRecalculateDialogOptions();
        this.sort = [];
        this.isLoading = false;
        this.isRecalculateDisabled = false;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'requestDate', dir: 'desc' }];
        this.dialogResult = false;
        this.options = dialogOptions;
        this.appConfig = appConfig;
    }
    Object.defineProperty(RecalculateDialogComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    RecalculateDialogComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
    };
    RecalculateDialogComponent.prototype.refreshGrid = function () {
        if (!this.accrualRecalculate) {
            this.gridView = null;
            return;
        }
        var filteredRecords = this.accrualRecalculate.accrual;
        var sortedRecords = orderBy(filteredRecords, this.sort);
        this.gridView = {
            data: sortedRecords,
            total: sortedRecords.length
        };
        this.gridState.view = process(this.accrualRecalculate.accrual, this.gridState.state);
        this.recalculateDisabled();
    };
    RecalculateDialogComponent.prototype.recalculateDisabled = function () {
        if (_.findIndex(this.accrualRecalculate.accrual, ['startDate', null]) != -1) {
            this.isRecalculateDisabled = true;
            this.recalculateTooltip = 'Latest request has not yet finished processing';
        }
        else {
            this.isRecalculateDisabled = false;
            this.recalculateTooltip = 'Recalculate';
        }
    };
    RecalculateDialogComponent.prototype.sortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    RecalculateDialogComponent.prototype.onClose = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    RecalculateDialogComponent.prototype.onRefresh = function () {
        this.onLoadRecalculateData();
    };
    RecalculateDialogComponent.prototype.onRecalculate = function () {
        var _this = this;
        if (this.orgLevelId) {
            this.isLoading = true;
            this.apiService.postAccrualsRecalculate(this.orgLevelId).
                then(function (status) {
                _this.isLoading = false;
                _this.onLoadRecalculateData();
            }).catch(function (reason) { _this.isLoading = false; });
        }
    };
    RecalculateDialogComponent.prototype.onLoadRecalculateData = function () {
        var _this = this;
        if (this.orgLevelId) {
            this.isLoading = true;
            this.apiService.getAccrualsRecalculateData(this.orgLevelId).
                then(function (accrualRecalculateDate) {
                _this.isLoading = false;
                _this.accrualRecalculate.accrual = accrualRecalculateDate;
                _this.refreshGrid();
            });
        }
    };
    return RecalculateDialogComponent;
}());
export { RecalculateDialogComponent };
