<div class="rotations">
  <div class="table-responsive" [ngClass]="{'fixed-height-rotations': !isRotationsExpanded && isExpandButtonVisible}">
    <div class="right-top-corner-plus">
      <button *ngIf="isExpandButtonVisible" class="theme-icon-button expand-collapse-visibility" (click)="onExpandCollapse()"><i class="fa"
          [ngClass]="{'fa-compress': isRotationsExpanded, 'fa-expand': !isRotationsExpanded }"
          aria-hidden="true"></i></button>
    </div>
    <table class="table table-rotations" aria-describedby="weekDay">
      <thead>
        <tr>
          <th class="weekday-cell weekday-cell-first" id="weekNumber"></th>
          <th class="weekday-cell" id="weekDay" *ngFor="let dayOfWeek of weekDaysRibbon">{{dayOfWeek?.name}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let weeklyRotation of selectedRotationValue?.weeklyRotations" [ngClass]="{'current-week': weeklyRotation?.isCurrent}">
          <td class="week-cell">
              <div *ngIf="selectedRotationValue?.isCurrent">
                <span class="week-label" *ngIf="weeklyRotation?.isCurrent">Current Week</span>
                <span class="week-label" *ngIf="!weeklyRotation?.isCurrent">Week {{weeklyRotation.weekNumber + 1}}</span>
                <br>
                <span class="date-label">({{weeklyRotation?.weekStartDate| amDateFormat: appConfig.dateFormat}})</span>
              </div>
          </td>
          <td
            [ngClass]="{'daily-rotation-view': weeklyRotation?.isCurrent&&!isInEditMode, 'current-week': weeklyRotation?.isCurrent}"
            *ngFor="let dailyRecord of weeklyRotation?.dailyRecords; let dateInd = index;" class="day-cell" (click)="masterCellClick(dailyRecord)">
            <div class="emp-shift-attribute-title">{{getWeekStartDate(weeklyRotation, dateInd)}}</div>
            <div class="rotation-record">
              <div *ngIf="(isInEditMode&&!dailyRecord?.isValid) || dailyRecord?.showWarning" title="{{dailyRecord?.validationMessage}}"
                class="left-top-corner">
                <i class="fa fa-2x fa-exclamation-circle validation-error" aria-hidden="true"></i>
              </div>
              <div class="tile" [class.cell-dirty]="sh?.isDirty" *ngFor="let sh of dailyRecord?.employeeShifts; let i = index;">
                  <div class="remove-shift" *ngIf="isInEditMode" (click)="onRemoveShiftClicked(sh, dailyRecord, $event)">
                    <i *ngIf="!sh?.isDirty" class="fa fa-times-circle" aria-hidden="true"></i>
                    <i *ngIf="sh?.isDirty" class="fa fa-undo-alt" aria-hidden="true"></i>
                  </div>
                  <div title="{{sh?.position?.name}}"><span [class.cell-dirty]="sh?.isDirty" class="emp-shift-attribute">{{sh?.position?.name}}</span>
                  </div>
                  <div title="{{sh?.shift?.name}}">
                    <span [class.cell-dirty]="sh?.isDirty" class="emp-shift-attribute">{{sh?.shift?.name}}</span>
                    <span [class.cell-dirty]="sh?.isDirty" class="emp-shift-attribute" *ngIf="sh?.constraint"> - ({{sh?.constraint?.code}})</span>
                  </div>
                  <div title="{{sh?.unit?.name}}">
                    <span [class.cell-dirty]="sh?.isDirty" class="emp-shift-attribute">{{sh?.unit?.name}}</span>
                  </div>
              </div>
              <div class="plus-div" *ngIf="isInEditMode && dailyRecord?.employeeShifts?.length < 10">
                <hr class="plus-hr">
                <i class="fa fa-plus-circle plus-sym" aria-hidden="true"></i>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div>
    <div *ngFor="let invalidRotation of invalidRotations">
      <i class="fa fa-exclamation-circle  validation-error" aria-hidden="true"></i> Week {{invalidRotation?.weeklyRotation?.weekNumber
      + 1}} {{invalidRotation?.dayOfWeek}} - {{invalidRotation?.validationMessage}}
    </div>
  </div>
  <div modalAnchor></div>
</div>