import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ConfirmDialog2Component, ConfirmOptions, DialogOptions2, KendoGridStateHelper, ModalService } from '../../../../../common';
import { NgForm } from '@angular/forms';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { process } from '@progress/kendo-data-query';
import { AcaExportParameter } from '../../../models/aca-export/aca-export-parameter';
import { AcaExportConfiguration } from '../../../models/aca-export/aca-export-configuration';
import { AcaExportType } from '../../../enums/aca-export-type';
var AcaExportSupplementalComponent = /** @class */ (function () {
    function AcaExportSupplementalComponent(modalService, options, manService, changeDetector) {
        this.gridData = [''];
        this.dialogResult = false;
        this.isLoading = true;
        this.isButtonEnable = true;
        this.sort = [];
        this.exportParams = new AcaExportParameter();
        this.configuration = new AcaExportConfiguration();
        this.modalService = modalService;
        this.options = options;
        this.modalService = modalService;
        this.changeDetector = changeDetector;
        this.manService = manService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
    }
    Object.defineProperty(AcaExportSupplementalComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaExportSupplementalComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    AcaExportSupplementalComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
        this.isLoading = false;
    };
    AcaExportSupplementalComponent.prototype.filterChange = function (filter) {
        this.gridState.state.filter = filter;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    AcaExportSupplementalComponent.prototype.getAcaSupplemental1095C = function (exectionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoading = true;
                        _a = this;
                        return [4 /*yield*/, this.manService.fetchSupplemental1095CRecords(exectionId)];
                    case 1:
                        _a.records = _b.sent();
                        this.refreshGrid();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(AcaExportSupplementalComponent.prototype, "valid", {
        get: function () {
            var formValid = this.form.invalid ? false : true;
            return formValid;
        },
        enumerable: true,
        configurable: true
    });
    AcaExportSupplementalComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    AcaExportSupplementalComponent.prototype.onBack = function () {
        this.onCancel();
        var acaExportDialogOptions = new AcaExportDialogOptions();
        acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
        acaExportDialogOptions.dialogType = 1;
        acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
        acaExportDialogOptions.acaExportSupplemental1095C = this.records;
        this.manService.openAcaExportDialog(acaExportDialogOptions);
    };
    AcaExportSupplementalComponent.prototype.showGenerateXML = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('XML', 'Are you sure you wish to generate a corrected XML export according to the parameters you specified?', this.modalService, function (result) {
            if (result) {
                _this.exportParams.exportType = AcaExportType.ExportXmlSupplemental1095C;
                _this.exportParams.receiptId = _this.acaCorrectionType.receiptId;
                _this.exportParams.employerId = _this.acaExportExecutionItem.employerId;
                _this.exportParams.employerName = _this.acaExportExecutionItem.employerName;
                _this.exportParams.year = _this.acaExportExecutionItem.year;
                _this.exportParams.qualifiedOfferMethod = _this.acaExportExecutionItem.qualifiedOfferMethod ? 'Yes' : 'No';
                _this.exportParams.offerMethod98 = _this.acaExportExecutionItem.offerMethod98 ? 'Yes' : 'No';
                _this.exportParams.memberOfAleGroup = _this.acaExportExecutionItem.memberOfAleGroup;
                _this.exportParams.empIds = _this.exportParams.empIds;
                _this.configuration.exportParams = _this.exportParams;
                _this.manService.generateAcaExport(_this.configuration.exportParams);
                _this.onCancel();
            }
        }, options);
    };
    AcaExportSupplementalComponent.prototype.showGenerate1095c = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('1095-Cs PDF', 'Do you wish to proceed with generating a PDF export?', this.modalService, function (result) {
            if (result) {
                _this.exportParams.exportType = AcaExportType.ExportPdfSupplemental1095C;
                _this.exportParams.receiptId = _this.acaCorrectionType.receiptId;
                _this.exportParams.employerId = _this.acaExportExecutionItem.employerId;
                _this.exportParams.employerName = _this.acaExportExecutionItem.employerName;
                _this.exportParams.year = _this.acaExportExecutionItem.year;
                _this.exportParams.qualifiedOfferMethod = _this.acaExportExecutionItem.qualifiedOfferMethod ? 'Yes' : 'No';
                _this.exportParams.offerMethod98 = _this.acaExportExecutionItem.offerMethod98 ? 'Yes' : 'No';
                _this.exportParams.memberOfAleGroup = _this.acaExportExecutionItem.memberOfAleGroup;
                _this.exportParams.offeredMec = _this.acaExportExecutionItem.offeredMec;
                _this.exportParams.empIds = _this.exportParams.empMasterIds;
                _this.configuration.exportParams = _this.exportParams;
                _this.manService.generateAcaExport(_this.configuration.exportParams);
                _this.onCancel();
            }
        }, options);
    };
    AcaExportSupplementalComponent.prototype.showGenerate1094c = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('1094-C PDF', 'Do you wish to proceed with generating a PDF export?', this.modalService, function (result) {
            if (result) {
                _this.exportParams.exportType = AcaExportType.ExportPdfSupplemental1094C;
                _this.exportParams.receiptId = _this.acaCorrectionType.receiptId;
                _this.exportParams.employerId = _this.acaExportExecutionItem.employerId;
                _this.exportParams.employerName = _this.acaExportExecutionItem.employerName;
                _this.exportParams.year = _this.acaExportExecutionItem.year;
                _this.exportParams.qualifiedOfferMethod = _this.acaExportExecutionItem.qualifiedOfferMethod ? 'Yes' : 'No';
                _this.exportParams.offerMethod98 = _this.acaExportExecutionItem.offerMethod98 ? 'Yes' : 'No';
                _this.exportParams.memberOfAleGroup = _this.acaExportExecutionItem.memberOfAleGroup;
                _this.exportParams.offeredMec = _this.acaExportExecutionItem.offeredMec;
                _this.exportParams.empIds = _this.exportParams.empMasterIds;
                _this.configuration.exportParams = _this.exportParams;
                _this.manService.generateAcaExport(_this.configuration.exportParams);
                _this.onCancel();
            }
        }, options);
    };
    AcaExportSupplementalComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    AcaExportSupplementalComponent.prototype.onCheckedEmployee = function (isSelected) {
        if (isSelected) {
            this.isButtonEnable = false;
        }
        else {
            this.isButtonEnable = true;
        }
        var selectedEmployees = _.filter(this.records, function (employee) { return (employee.isSelected); });
        this.exportParams.empIds = _.map(selectedEmployees, function (employee) { return (employee.employeeId); });
        this.exportParams.empMasterIds = _.map(selectedEmployees, function (employee) { return (employee.employeeIdMasterId); });
    };
    AcaExportSupplementalComponent.prototype.onToggleAllSelected = function () {
        var _this = this;
        var employees = process(this.records, { filter: this.gridState.state.filter }).data;
        var selectedEmployees = _.filter(employees, function (employee) {
            employee.isSelected = _this.isAllSelected;
            _this.onCheckedEmployee(employee.isSelected);
            return employee.isSelected;
        });
    };
    return AcaExportSupplementalComponent;
}());
export { AcaExportSupplementalComponent };
