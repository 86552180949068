import { Assert } from './../../../../../framework/assert/assert';
import { ValuePairChartDataService } from './../../../../services/value-pair-chart-data/value-pair-chart-data.service';
import { EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
var BarChartVerticalValuePairComponent = /** @class */ (function () {
    function BarChartVerticalValuePairComponent(changeDetection, dataService) {
        this.changeDetection = changeDetection;
        this.dataService = dataService;
        this.hideLabel = false; //Hiding label
        this.displayValue1 = 0;
        this.displayValue2 = 0;
        this.onClick = new EventEmitter();
    }
    BarChartVerticalValuePairComponent.prototype.ngOnInit = function () {
        var _this = this;
        Assert.isNotNull(this.dataService, 'dataService');
        this.dataService.onModelLoaded.subscribe(function (model) {
            _this.setValues(model.input);
            _this.setColors(model.color);
            _this.setLabel(model.additionalColor);
            _this.changeDetection.markForCheck();
            _this.changeDetection.detectChanges();
        });
    };
    Object.defineProperty(BarChartVerticalValuePairComponent.prototype, "maxValue", {
        get: function () {
            if (this.originalValue1 === 0 && this.originalValue2 === 0)
                return 1;
            return Math.max(this.originalValue1, this.originalValue2);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BarChartVerticalValuePairComponent.prototype, "isNegative", {
        get: function () {
            return this.originalValue1 < this.originalValue2;
        },
        enumerable: true,
        configurable: true
    });
    BarChartVerticalValuePairComponent.prototype.setValues = function (values) {
        this.originalValue1 = values.value1;
        this.originalValue2 = values.value2;
        this.isFull = this.originalValue1 === this.originalValue2;
        if (this.originalValue2 > 0) {
            this.displayValue2 = Math.abs(this.originalValue1 - this.originalValue2);
        }
        this.displayValue1 = this.maxValue - this.displayValue2;
        if (this.originalValue1 === 0 && this.originalValue2 === 0) {
            this.displayValue1 = 1;
        }
    };
    BarChartVerticalValuePairComponent.prototype.setColors = function (colors) {
        if (colors) {
            this.seriesColors = [colors.backgroundColor1, colors.backgroundColor2];
            this.fontColor1 = colors.fontColor1;
            this.fontColor2 = colors.fontColor2;
        }
        // } else {
        //   this.fontColor1 = '#FFFFFF';
        //   this.fontColor2 = '#CCCCCC';
        // }
    };
    BarChartVerticalValuePairComponent.prototype.setLabel = function (color) {
        this.labelValue = this.originalValue1 - this.originalValue2;
        this.labelColor = color;
    };
    BarChartVerticalValuePairComponent.prototype.handleClick = function (event) {
        if (event.originalEvent && event.originalEvent.type === 'contextmenu') {
            return;
        }
        this.onClick.emit(event);
    };
    return BarChartVerticalValuePairComponent;
}());
export { BarChartVerticalValuePairComponent };
