import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { IOperationalConsoleEntry, OperationalConsoleEntry, OperationalConsoleField } from '../../../employee/employee-list/models/index';
import { IPosition, Position } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupMapService, OrgLevelMapService } from '../../../organization/services/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class OperationalConsoleMapService {
  private lookupMapService: LookupMapService;
  private orgLevelMapService: OrgLevelMapService;

  constructor(lookupMapService: LookupMapService, orgLevelMapService: OrgLevelMapService) {
    this.lookupMapService = lookupMapService;
    this.orgLevelMapService = orgLevelMapService;
  }

  public mapToConsole(data: IOperationalConsoleEntry[]): OperationalConsoleEntry[] {
    const entries: OperationalConsoleEntry[] = this.mapEntries(data);

    return entries;
  }

  private mapEntries(entries: IOperationalConsoleEntry[]): OperationalConsoleEntry[] {
    return _.map(entries, (e: IOperationalConsoleEntry) => {
      const entry: OperationalConsoleEntry = new OperationalConsoleEntry();
      entry.scheduledEmployees = this.mapField('scheduledEmployees', e.scheduledEmployees);
      entry.absentEmployees = this.mapField('absentEmployees', e.absentEmployees);
      entry.lateEmployees = this.mapField('lateEmployees', e.lateEmployees);
      entry.punchedEmployees = this.mapField('punchedEmployees', e.punchedEmployees);
      entry.notPunchedEmployees = this.mapField('notPunchedEmployees', e.notPunchedEmployees);
      entry.scheduledHours = this.mapField('scheduledHours', e.scheduledHours);
      entry.par = this.mapField('par', e.par);
      entry.diff = this.mapField('diff', e.diff);
      entry.absent = this.mapField('absent', e.absent);
      entry.replaced = this.mapField('replaced', e.replaced);
      entry.diffR = this.mapField('diffR', e.diffR);
      entry.overtime = this.mapField('overtime', e.overtime);

      entry.position = e.position ? this.lookupMapService.mapPosition(e.position) : null;
      entry.orgLevel = this.orgLevelMapService.mapToOrgLevel(e.organizationLevel);

      return entry;
    });
  }

  private mapField(keyName: string, val: number): OperationalConsoleField {
    return new OperationalConsoleField(keyName, val);
  }
}
