import { EssDefaultPassword } from './../../../../../../organization/models/lookup/ess-default-password';
import { NgForm } from '@angular/forms';
import { EmployeeDefinitionsApiService } from './../../../../../../organization/services/employee/employee-definitions-api.service';
import { AppSettingsManageService } from './../../../../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from './../../../../../../app-settings/model/app-server-config';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { ConfirmOptions, ConfirmDialogComponent, ModalService } from './../../../../../../common/index';
import { Observable } from 'rxjs/Observable';
import { AddEmployeeWizardActions } from '../../../../store/index';
import { AddEmployeeModel, AddEmployeeRestrictions } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { LookupApiService } from '../../../../../../organization/services/lookup/lookup-api.service';
import { ShiftDiffPolicy, PayPolicy, PayType, ExemptStatus, BenefitClassDefinition } from '../../../../../../organization/models/lookup/index';
import { EmployeeValidatorAdapter } from '../../../../../../organization/services/index';
import { employeeConfig } from '../../../../employee.config';
import { PbjModes } from '../../../../../../app-settings/model/app-setting-keys';
import { EssTemplateApiService } from '../../../../../../app-modules/ess-templates/services/index';
import { EssTemplate } from '../../../../../../app-modules/ess-templates/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-add-employee-employment',
  templateUrl: 'add-employee-employment.component.html',
  styleUrls: ['add-employee-employment.component.scss']
})

export class AddEmployeeEmploymentComponent extends AddEmployeeBasicComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public addEmployeeRestrictions: AddEmployeeRestrictions;
  @ViewChild('employmentForm', { static: true }) mainForm: NgForm;

  @mutableSelect(['addEmployeeWizard', 'model'])
  public addEmployeeWizard$: Observable<AddEmployeeModel>;
  public state: {
    isPayTypeLoading: boolean;
    isBenefitClassLoading: boolean;
  };

  public minSalariedHour: number = 1;
  public hourlyRateLimit: number = 9999.99;
  public weeklyRateLimit: number = 399999.99;
  public hourlyRateWarningLimit: number = 299.99;
  public weeklyRateWarningLimit: number = 11999.99;

  public payTypeLookup: PayType[];
  public benefitClassLookup: BenefitClassDefinition[];

  public get isSalaryPayType(): boolean {
    return this.addEmployeeModel.payType && this.addEmployeeModel.payType.isSalaryPayType;
  }
  public get isSalariedWithoutHours(): any {
    return this.isSalaryPayType && this.minSalariedHour;
  }
  public get payrollNumberValidation(): string {
    return EmployeeValidatorAdapter.payrollNumberValidation;
  }
  public get ssnValidation(): string {
    return EmployeeValidatorAdapter.ssnValidation;
  }
  public get pbjIdValidation(): string {
    return EmployeeValidatorAdapter.pbjIdValidation;
  }
  public get getRateLimit(): number {
    return this.isSalaryPayType ? this.weeklyRateLimit : this.hourlyRateLimit;
  }
  public get getRateWarningLimit(): number {
    return this.isSalaryPayType ? this.weeklyRateWarningLimit : this.hourlyRateWarningLimit;
  }
  public get getRateTitle(): string {
    return this.isSalaryPayType ? 'Weekly Rate' : 'Hourly Rate';
  }
  public employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter;
  public maxBirthDate: Date;

  public pbjEnabled: boolean = false;
  public pbjIsManual: boolean = false;

  public bswiftEnabled: boolean = false;
  public employmentValidationLoading: boolean = false;

  private appConfigPromise: any;
  private lookupApiService: LookupApiService;

  public essTemplatesEnabled: boolean;
  public essTemplate: EssTemplate;
  public isNgpUser : boolean = false;
  constructor(addEmployeeWizardActions: AddEmployeeWizardActions, lookupApiService: LookupApiService,
    employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter,
    private appSettingsManageService: AppSettingsManageService, private employeeDefinitionApi: EmployeeDefinitionsApiService,
    private modalService: ModalService,private essTemplateApiService: EssTemplateApiService) {
    super(addEmployeeWizardActions);
    this.lookupApiService = lookupApiService;
    this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
    this.state = {
      isPayTypeLoading: false,
      isBenefitClassLoading: false
    };
    this.maxBirthDate = moment().subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
  }

  public ngOnInit(): void {
    this.getSettings();

    this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe((model: AddEmployeeModel) => {
      this.onWizard(model);
      if (this.hasAssignedOrgLevel) {
        this.loadPayTypeLookup();
        this.loadBenefitClassLookup();
      }
      if (model != null && this.addEmployeeRestrictions.ssn.isRequired != true) {
        this.employmentValidationLoading = true;
        this.loadEssTemplate(model.position.id)
          .then((ess: EssTemplate) => {
            if (this.essTemplatesEnabled && ess && ess.defaultPwdTemplate === EssDefaultPassword.last4SSN) {
                this.addEmployeeRestrictions.ssn.isRequired = true;
              if((model.employeeType.name === 'AFT' || model.employeeType.name === 'APT') || model.organization.id == 1000)
              {
                this.addEmployeeRestrictions.ssn.isRequired = false;
              }
            }
              this.employmentValidationLoading = false;
          }).finally(()=>{
            this.employmentValidationLoading = false;
          });;
      }
    });
  }

  public ngAfterViewInit(): void {
    if(this.addEmployeeModel.hireDate){
      this.maxBirthDate = moment(this.addEmployeeModel.hireDate).subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
    }
    this.addChangesSubscriptionToForm(this.mainForm);
  }

  public generatePbjId(): void {
    this.employeeDefinitionApi.generatePbjId()
      .then((value: string) => {
        this.addEmployeeModel.pbjId = value;
      });
  }

  public onHireDateChange(date: Date): void {
    if (date) {
      if (this.addEmployeeModel) {
        this.addEmployeeModel.benefitClassEffectiveDate = date;
      }
      this.maxBirthDate = moment(date).subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
    }
    if (this.addEmployeeModel) {
      this.addEmployeeModel.hireDate = date;
    }
  }

  private loadPayTypeLookup(): void {
    this.state.isPayTypeLoading = true;
    this.lookupApiService.getPayTypes()
      .then((payTypeLookup: PayType[]) => {
        this.payTypeLookup = payTypeLookup;
        this.state.isPayTypeLoading = false;
      })
      .catch((res: any) => {
        this.state.isPayTypeLoading = false;
      });
  }

  private async loadBenefitClassLookup(): Promise<void> {
    await this.getSettings();

    if (!this.bswiftEnabled) {
      return;
    }

    this.state.isBenefitClassLoading = true;
    const orgLevelId = _.get(this.addEmployeeModel, 'department.orgLevelId');

    try {
      const benefitClassLookup = await this.lookupApiService.getBenefitClassesDefinitions(null, orgLevelId);

      this.benefitClassLookup = benefitClassLookup;
      this.showBenefitClassPopup();
    } catch (e) { }

    this.state.isBenefitClassLoading = false;
  }

  private async getSettings(): Promise<void> {
    if (this.appConfigPromise) {
      return;
    }

    const config = await this.appSettingsManageService.getAppServerConfig();

    this.appConfigPromise = config;

    this.pbjEnabled = config.PbjEnabled;
    this.pbjIsManual = config.PbjMode === PbjModes.Manual;
    this.bswiftEnabled = config.bswiftIntegrationEnabled;
    this.essTemplatesEnabled = config.essTemplatesEnabled;
    this.isNgpUser = config.IsNextgenPayrollEnabled || false;
  }

  private showBenefitClassPopup(): void {
    if (!_.isEmpty(this.benefitClassLookup)) {
      return;
    }

    const options = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    ConfirmDialogComponent.openDialog(
      'Warning',
      'Benefit class is required. You must configure Benefit class before the employee record can be created.',
      this.modalService,
      () => { },
      options
    );
  }

  private async loadEssTemplate(positionid: number): Promise<any> {
    return await this.essTemplateApiService.getTemplateForPosition(positionid);
  }
}
