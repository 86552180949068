export * from './roster/index';
export * from './crud/index';
export * from './config/index';

import { rosterComponents, WcCreateOshaFormExcelDialogComponent, WcCreateEstablishmentSizeDialogComponent } from './roster/index';
import { crudComponents, WCIncidentReportDialogComponent } from './crud/index';
import { configComponents } from './config/index';
import { WorkersCompensationComponents } from './workers-compensation-table/index';

export const components: any[] = [
  ...rosterComponents,
  ...crudComponents,
  ...configComponents,
  ...WorkersCompensationComponents
];

export const entryComponents: any[] = [
  WCIncidentReportDialogComponent,
  WcCreateOshaFormExcelDialogComponent,
  WcCreateEstablishmentSizeDialogComponent
];

export const exportComponents: any[] = [
];
