import { AcaExportExecutionItem } from '../aca-export/aca-export-execution';
import { AcaExportType } from '../../enums/aca-export-type';
import { AcaExportParameter } from './aca-export-parameter';

export class AcaExportConfiguration {
  public id: number;
  public exportType: AcaExportType;
  public name: string;
  public lastExecuted: AcaExportExecutionItem;
  public history: AcaExportExecutionItem[];
  public exportParams: AcaExportParameter;
  public isCollapsed: boolean;
  public requiresAtLeastOneEmployeeToBeConfirmed: boolean;
  public requiresAllEmployeesToBeConfirmed: boolean;
  public requiresTccCode: boolean;
  public pageSkip: number = 0;
  public isActionEnabled: boolean;
}