import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { ArrivalsDeparturesTimelineDetailsManagementService } from '../../../services/index';
var ArrivalsDeparturesTimelineDetailsGridComponent = /** @class */ (function () {
    function ArrivalsDeparturesTimelineDetailsGridComponent(changeDetector, managementService) {
        this.changeDetector = changeDetector;
        this.managementService = managementService;
    }
    ArrivalsDeparturesTimelineDetailsGridComponent.prototype.changeDetection = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ArrivalsDeparturesTimelineDetailsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadedSubscription = this.managementService.onLoaded$.subscribe(function (details) {
            _this.details = details;
            _this.workDate = _this.managementService.workDate;
            _this.changeDetection();
        });
    };
    ArrivalsDeparturesTimelineDetailsGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineDetailsGridComponent.prototype, "loadedSubscription", void 0);
    return ArrivalsDeparturesTimelineDetailsGridComponent;
}());
export { ArrivalsDeparturesTimelineDetailsGridComponent };
