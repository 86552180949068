var PBJExportLogHeader = /** @class */ (function () {
    function PBJExportLogHeader() {
    }
    Object.defineProperty(PBJExportLogHeader.prototype, "totalMessagesCount", {
        get: function () {
            return this.errorsCount + this.warningsCount;
        },
        enumerable: true,
        configurable: true
    });
    return PBJExportLogHeader;
}());
export { PBJExportLogHeader };
