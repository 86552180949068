import {
    Organization,
    IOrganization
  } from '../../../organization/models/index';

export interface IWcComparisonsContainer {
    records: IWcComparisonsEntry[];
    canEdit: boolean;
}
  
export class WcComparisonsContainer {
    public records: WcComparisonsEntry[];
    public canEdit: boolean;
}

export interface IMonths {
    count: number;
    startOfMonth: string;
}

export class Months {
    public count: number;
    public startOfMonth: string;
}

export interface IWcComparisonsEntry {
    organization: IOrganization;
    months: IMonths[];
    total: number;
}

export class WcComparisonsEntry {
    public organization: Organization;
    public months: Months[];
    public total: number;
}
