import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { appConfig } from '../../../app.config';
import { pmConfig } from '../performance-management.config';

import { FieldsMeta, Meta } from '../../../core/models/index';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { ReadFile, PmTemplateDefinition, IPmTemplateDefinition } from '../../../organization/models/index';

import { dateTimeUtils } from '../../../common/utils/index';

import { ResponseBody } from '../../../core/models/api/response-body';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { IPmReviewRecords, PmReviewRecords, IPmReviewEntry, PmReviewEntry, CfgPmCode, ICfgPmCode, PmReviewRecord, EmployeeRosterRecord } from '../models/index';
import { PerformanceManagementMapService } from './performance-management-map.service';

@Injectable()
export class PerformanceManagementApiService {

  constructor (
    private apiUtilService: ApiUtilService,
    private mapService: PerformanceManagementMapService,
    private urlParamsService: UrlParamsService
  ) {
  }

  public async getReviewRecords(orgLevelId: number, sDate: Date, eDate: Date): Promise<PmReviewRecords<PmReviewRecord>> {

    const url: string = this.getPmRosterApi();

    const params: StringMap<any> = {
      orgLevelId: orgLevelId,
      start: dateTimeUtils.convertToDtoString(sDate),
      end: dateTimeUtils.convertToDtoString(eDate)
    };

    const request = this.urlParamsService.createGetRequest(url, params);
    return this.apiUtilService
      .request<IPmReviewRecords, FieldsMeta>(request)
      .then((response: ResponseBody<IPmReviewRecords, FieldsMeta>) => this.mapService.mapReviewRecords(response.data));
  }

  public async loadReviewEntry(id: number): Promise<PmReviewEntry> {
    const url: string = `${this.getPmReviewApi()}/${id}`;

    const request = this.urlParamsService.createGetRequest(url);
    return this.apiUtilService
      .request<IPmReviewEntry, FieldsMeta>(request)
      .then((response: ResponseBody<IPmReviewEntry, FieldsMeta>) => this.mapService.mapReviewEntry(response.data));
  }

  public async createReviewEntry(review: PmReviewEntry): Promise<PmReviewEntry> {
    const url: string = `${this.getPmReviewApi()}`;
    const body = this.mapService.mapReviewEntryToDto(review);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<IPmReviewEntry, FieldsMeta>(request)
      .then((response: ResponseBody<IPmReviewEntry, FieldsMeta>) => this.mapService.mapReviewEntry(response.data));
  }

  public async updateReviewEntry(review: PmReviewEntry): Promise<PmReviewEntry> {
    const url: string = `${this.getPmReviewApi()}/${review.id}`;
    const body = this.mapService.mapReviewEntryToDto(review);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<IPmReviewEntry, FieldsMeta>(request)
      .then((response: ResponseBody<IPmReviewEntry, FieldsMeta>) => this.mapService.mapReviewEntry(response.data));
  }

  public async deleteReviewEntry(review: PmReviewEntry): Promise<any> {
    const url: string = `${this.getPmReviewApi()}/${review.id}`;
    const request = this.urlParamsService.createDeleteRequest(url);

    return this.apiUtilService
      .request(request)
      .then((response: ResponseBody<any, FieldsMeta>) => response.data);
  }

  public async completeReviewEntry(review: PmReviewEntry): Promise<PmReviewEntry> {
    const url: string = `${this.getPmReviewApi()}/${review.id}`;
    const body = this.mapService.mapReviewEntryToDto(review);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapReviewEntry(response.data));
  }

  public async saveTemplateAttachments(templateId: number, files: ReadFile[]): Promise<PmTemplateDefinition> {
    const url: string = `${this.getCfgTemplatesApi()}/attachments`;
    const formData = this.mapService.mapFilesToFormData(files);
    const request = this.urlParamsService.requestPost(url, formData, { templateId });
    request.autoContentType = true;

    return this.apiUtilService.requestNew<IPmTemplateDefinition, any>(request)
      .then((response) => this.mapService.mapTemplateDefinition(response.data));
  }


  public async saveAttachments(reviewId: number, files: ReadFile[]): Promise<PmReviewEntry> {
    const url: string = `${this.getPmAttachmentApi()}`;
    const formData = this.mapService.mapFilesToFormData(files);
    const request = this.urlParamsService.requestPost(url, formData, { reviewId });
    request.autoContentType = true;

    return this.apiUtilService.requestNew<IPmReviewEntry, any>(request)
      .then((response) => this.mapService.mapReviewEntry(response.data));
  }

  public async deleteAttachment(reviewId: number, attachmentId: number): Promise<PmReviewEntry> {
    const url: string = `${this.getPmAttachmentApi()}`;
    const request = this.urlParamsService.createDeleteRequest(url, { id: reviewId, documentIds: [attachmentId] });

    return this.apiUtilService.request<IPmReviewEntry, any>(request)
      .then((response) => this.mapService.mapReviewEntry(response.data));
  }

  public downloadAttachment(documentId: number): Promise<FileBlobResponse> {
    const url: string = `${this.getPmAttachmentApi()}`;
    const params = {
      documentId: documentId
    };
    const request = this.urlParamsService.createGetRequest(url, params);
    let promise: Promise<FileBlobResponse> = this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
    return promise;
  }

  public async getTemplateDefinitions(orgLevelId: number): Promise<PmTemplateDefinition[]> {

    const url: string = `${this.getCfgTemplatesApi()}`;
    const params: StringMap<any> = {
      orgLevelId: orgLevelId
    };
    const request = this.urlParamsService.createGetRequest(url, params);

    return this.apiUtilService
      .request<IPmTemplateDefinition[], FieldsMeta>(request)
      .then((response: ResponseBody<IPmTemplateDefinition[], FieldsMeta>) =>
        this.mapService.mapTemplateDefinitions(response.data));
  }

  public async getCodeDefinitions(orgLevelId: number): Promise<CfgPmCode[]> {

    const url: string = `${this.getCfgCodesApi()}`;
    const params: StringMap<any> = {
      orgLevelId: orgLevelId
    };
    const request = this.urlParamsService.createGetRequest(url, params);

    return this.apiUtilService
      .request<ICfgPmCode[], FieldsMeta>(request)
      .then((response: ResponseBody<ICfgPmCode[], FieldsMeta>) =>
        this.mapService.mapCfgPmCodes(response.data));
  }

  public async createCode(code: CfgPmCode): Promise<CfgPmCode> {
    const url: string = `${this.getCfgCodesApi()}`;
    const body = this.mapService.mapCfgPmCodeDto(code);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<ICfgPmCode, FieldsMeta>(request)
      .then((response: ResponseBody<ICfgPmCode, FieldsMeta>) => this.mapService.mapCfgPmCode(response.data));

  }

  public async updateCode(code: CfgPmCode): Promise<CfgPmCode> {
    const url: string = `${this.getCfgCodesApi()}`;
    const body = this.mapService.mapCfgPmCodeDto(code);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<ICfgPmCode, FieldsMeta>(request)
      .then((response: ResponseBody<ICfgPmCode, FieldsMeta>) => this.mapService.mapCfgPmCode(response.data));

  }

  public async removeCode(codeId: number): Promise<any> {
    const url: string = `${this.getCfgCodesApi()}/${codeId}`;
    const request = this.urlParamsService.createDeleteRequest(url);

    return this.apiUtilService
      .request(request)
      .then((response: ResponseBody<any, FieldsMeta>) => response.data);
  }


  public async createTemplate(tpl: PmTemplateDefinition): Promise<PmTemplateDefinition> {
    const url: string = `${this.getCfgTemplatesApi()}`;
    const body = this.mapService.mapTemplateDefinitionDto(tpl);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<IPmTemplateDefinition, FieldsMeta>(request)
      .then((response: ResponseBody<IPmTemplateDefinition, FieldsMeta>) => this.mapService.mapTemplateDefinition(response.data));

  }

  public async updateTemplate(tpl: PmTemplateDefinition): Promise<PmTemplateDefinition> {
    const url: string = `${this.getCfgTemplatesApi()}`;
    const body = this.mapService.mapTemplateDefinitionDto(tpl);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<IPmTemplateDefinition, FieldsMeta>(request)
      .then((response: ResponseBody<IPmTemplateDefinition, FieldsMeta>) => this.mapService.mapTemplateDefinition(response.data));

  }

  public async removeTemplate(tplId: number): Promise<any> {
    const url: string = `${this.getCfgTemplatesApi()}/${tplId}`;
    const request = this.urlParamsService.createDeleteRequest(url);

    return this.apiUtilService
      .request(request)
      .then((response: ResponseBody<any, FieldsMeta>) => response.data);
  }

  public async getEmployeeReviews(employeeId: number, startDate: Date, endDate: Date): Promise<PmReviewRecords<EmployeeRosterRecord>> {
    
    const url: string = this.getPmEmployeeRosterApi();

    const params: StringMap<any> = {
      employeeId: employeeId
    };

    if (startDate != null) {    
      params.startDate = dateTimeUtils.convertToDtoString(startDate);
    }

    if (endDate != null) {    
      params.endDate = dateTimeUtils.convertToDtoString(endDate);
    }

    const request = this.urlParamsService.createGetRequest(url, params);
    return this.apiUtilService
      .request<IPmReviewRecords, FieldsMeta>(request)
      .then((response: ResponseBody<IPmReviewRecords, FieldsMeta>) => this.mapService.mapEmployeeReviewRecords(response.data));
  }

  private getPmAttachmentApi(): string {
    return `${this.getPmRootApi()}/${pmConfig.api.pm.attachment}`;
  }

  private getPmReviewApi(): string {
    return `${this.getPmRootApi()}/${pmConfig.api.pm.review}`;
  }

  private getPmEmployeeRosterApi(): string {
    return `${this.getPmRosterApi()}/${pmConfig.api.pm.employee}`;
  }

  private getPmRosterApi(): string {
    return `${this.getPmRootApi()}/${pmConfig.api.pm.roster}`;
  }

  private getPmRootApi(): string {
    return `${this.getApiRoot()}/${pmConfig.api.pm.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }

  private getConfigRootApi(): string {
    return `${this.getApiRoot()}/${pmConfig.api.configuration.root}/${pmConfig.api.configuration.pm.root}`;
  }

  private getCfgCodesApi(): string {
    return `${this.getConfigRootApi()}/${pmConfig.api.configuration.pm.pmCode.root}`;
  }

  private getCfgTemplatesApi(): string {
    return `${this.getConfigRootApi()}/${pmConfig.api.configuration.pm.templates.root}`;
  }


}
