import { OnInit } from '@angular/core';
import { IDialog, ModalService, DialogOptions } from '../../../../common/index';
import { Assert } from '../../../../framework/index';
import * as moment from 'moment';
import { dateTimeUtils } from '../../../../common/utils/index';
var PayCodesAdditionalDataComponent = /** @class */ (function () {
    function PayCodesAdditionalDataComponent(options, modalService) {
        Assert.isNotNull(options, 'options');
        this.options = options;
        Assert.isNotNull(modalService, 'modalService');
        this.modalService = modalService;
        this.dialogResult = false;
        this.isLoading = false;
        this.maxDate = moment().subtract(1, 'day').startOf('day').toDate();
    }
    Object.defineProperty(PayCodesAdditionalDataComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    PayCodesAdditionalDataComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
    };
    Object.defineProperty(PayCodesAdditionalDataComponent.prototype, "selectedRetroDate", {
        get: function () {
            return this.retroDate;
        },
        enumerable: true,
        configurable: true
    });
    PayCodesAdditionalDataComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    PayCodesAdditionalDataComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    PayCodesAdditionalDataComponent.prototype.load = function (retroDate) {
        if (dateTimeUtils.validateDate(retroDate))
            this.retroDate = retroDate;
        else
            this.retroDate = null;
    };
    PayCodesAdditionalDataComponent.prototype.onChangeRetroDate = function (value) {
        this.retroDate = value;
    };
    return PayCodesAdditionalDataComponent;
}());
export { PayCodesAdditionalDataComponent };
