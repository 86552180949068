import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { ReportAttendanceSetting, ReportConfigPositionCategory } from '../../models/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { ReportsManagementService } from '../../services/index';
import { unsubscribeAll } from '../../../core/decorators/index';
var ReportAttendanceConfigComponent = /** @class */ (function () {
    function ReportAttendanceConfigComponent(reportsManagementService) {
        this.reportsManagementService = reportsManagementService;
        this.dailyAttendanceOptions = [];
        this.isLoading = true;
        this.pageSize = 50;
        this.hasValueChanged = new EventEmitter();
        this.reportConfigChanged = new EventEmitter();
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.dailyAttendanceOptions = [{ id: 0, name: ' ' }, { id: 1, name: 'RNs' }, { id: 2, name: 'LPNs' }, { id: 3, name: 'Others' }];
    }
    ReportAttendanceConfigComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.reportsManagementService.loadAttendanceSettingsData(this.forcedLoad);
        this.subscriptions.loadAttendanceSettingsSubscription = this.reportsManagementService.onReportAttendanceSettingsLoaded$.subscribe(function (reportAttendanceSetting) {
            _this.records = reportAttendanceSetting;
            _this.refreshGrid();
        });
    };
    ReportAttendanceConfigComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            this.isLoading = false;
            return;
        }
        this.gridState.view = process(this.records.positionCategory, this.gridState.state);
        this.isLoading = false;
    };
    ReportAttendanceConfigComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ReportAttendanceConfigComponent.prototype.getPositionCategory = function (id) {
        return this.dailyAttendanceOptions[id];
    };
    ReportAttendanceConfigComponent.prototype.setPositionCategory = function (positionCategoryType, positionCategory) {
        if (positionCategoryType.positionCategoryType !== positionCategory.id) {
            positionCategoryType.positionCategoryType = positionCategory.id;
            this.hasValueChanged.emit(true);
            this.reportConfigChanged.emit(this.records);
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], ReportAttendanceConfigComponent.prototype, "subscriptions", void 0);
    return ReportAttendanceConfigComponent;
}());
export { ReportAttendanceConfigComponent };
