import * as moment from 'moment';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { EventEmitter, OnInit } from '@angular/core';
import { Employee, EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { employeeListConfig } from '../../employee-list.config';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
var AssignPayCyclesComponent = /** @class */ (function () {
    function AssignPayCyclesComponent(groupActivitiesService, employees, fieldDefinition, orgLevel, modalService) {
        this.modalService = modalService;
        this.groupActivitiesService = groupActivitiesService;
        this.employees = employees;
        this.fieldDefinition = fieldDefinition;
        this.orgLevel = orgLevel;
        this.onCancel = new EventEmitter();
        this.onComplete = new EventEmitter();
        this.days = {
            min: 1,
            max: 31,
            canChoose: false
        };
        this.state = {
            isLoading: false,
        };
        this.effectiveDate = moment(this.effectiveDate).toDate();
        this.validateEffectiveDate();
    }
    Object.defineProperty(AssignPayCyclesComponent.prototype, "isSemiMonthly", {
        get: function () {
            return this.period && this.period.name === 'smonthly';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AssignPayCyclesComponent.prototype, "minDay", {
        get: function () {
            return _.isNumber(this.startDay1) ? this.startDay1 : this.days.min;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AssignPayCyclesComponent.prototype, "maxDay", {
        get: function () {
            return _.isNumber(this.startDay2) ? this.startDay2 : this.days.max;
        },
        enumerable: true,
        configurable: true
    });
    AssignPayCyclesComponent.prototype.ngOnInit = function () {
        this.loadEmployeesList();
    };
    AssignPayCyclesComponent.prototype.onModelChanged = function () {
        this.validateEffectiveDate();
    };
    AssignPayCyclesComponent.prototype.validateEffectiveDate = function () {
        if (_.isDate(this.effectiveDate)) {
            this.days.canChoose = _.isDate(this.effectiveDate);
            this.days.max = moment(this.effectiveDate).daysInMonth();
            return;
        }
        this.days.canChoose = false;
    };
    AssignPayCyclesComponent.prototype.cancel = function () {
        this.onCancel.emit();
    };
    AssignPayCyclesComponent.prototype.employeesSelect = function (selectedEmployees) {
        this.selectedEmployees = selectedEmployees;
        this.canSend = this.selectedEmployees && this.selectedEmployees.length > 0;
    };
    AssignPayCyclesComponent.prototype.loadEmployeesList = function () {
        var _this = this;
        this.state.isLoading = true;
        this.maxApprovedDate = new Date(1950, 1);
        var employeeIds = _.map(this.employees, function (employee) { return employee[employeeListConfig.employeeIdentifierName]; });
        this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, true, employeeIds, this.fieldDefinition)
            .then(function (actionEmployees) {
            _.map(actionEmployees, function (employee) {
                if (employee['LastApprovedDate'] > _this.maxApprovedDate) {
                    _this.maxApprovedDate = employee['LastApprovedDate'];
                }
            });
            _this.maxApprovedDate = new Date(_this.maxApprovedDate.getTime() + 86400000);
            _this.actionEmployees = actionEmployees;
            _this.state.isLoading = false;
        });
    };
    AssignPayCyclesComponent.prototype.createPayCycles = function () {
        var _this = this;
        this.state.isLoading = true;
        this.groupActivitiesService.createPayCycles(this.selectedEmployees, this.effectiveDate, this.period, this.startDay1, this.startDay2)
            .then(function (response) {
            _this.state.isLoading = false;
            _this.afterSave();
            _this.modalService.globalAnchor.openInfoDialog('Information', 'Pay Cycle created!', function (result) {
                _this.cancel();
            });
            //this.loadEmployeesList();
        })
            .catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    AssignPayCyclesComponent.prototype.afterSave = function () {
        _.forEach(this.selectedEmployees, function (emp) { emp['isSelected'] = false; });
        this.selectedEmployees = null;
        this.canSend = false;
    };
    return AssignPayCyclesComponent;
}());
export { AssignPayCyclesComponent };
