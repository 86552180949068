import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common';
import { BIPAReportDefinition, BIPAShiftGroupTime, BIPATableData, GetBIPAReportDefinition } from './../../../../../app/reports/models';
import * as _ from 'lodash';
import { ReportsManagementService } from './../../../../../app/reports/services';
import { unsubscribeAll } from './../../../../../app/core/decorators';
import { Subscription } from 'rxjs';
import { setData } from '@telerik/kendo-intl';

@Component({
  selector: 'slx-bipa-report',
  templateUrl: './bipa-report.component.html',
  styleUrls: ['./bipa-report.component.scss']
})
export class BipaReportComponent implements OnInit {

  @Input()
  public configData: BIPAReportDefinition;

  @Input()
  public forceLoad: boolean;

  @Output()
  public hasValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isUnitIncluded: boolean = false;
  public isShiftTimesIncluded: boolean = false;
  public records: any[] = [];
  public gridData: BIPAReportDefinition;

  constructor(public reportsManagementService: ReportsManagementService) {
  }

  ngOnInit() {
    this.reportsManagementService.loadDailyStaffPostingData(this.forceLoad);
    this.reportsManagementService.onReportDailyStaffingPostLoadeded$.subscribe((reportDailyStaffingPost: GetBIPAReportDefinition) => {
      this.isUnitIncluded = reportDailyStaffingPost.showUnitsOnBipaReportValue;
      this.isShiftTimesIncluded = reportDailyStaffingPost.showShiftsOnBipaReportValue;
    });
    this.setDataToModal();
  }

  public onShiftDateChanged() {
    console.log('triggered');
  }

  public setDataToModal() {
    if (!_.isEmpty(this.configData)) {
      this.isUnitIncluded = this.configData.unitShiftData[0];
      this.isShiftTimesIncluded = this.configData.unitShiftData[1];
      this.records = this.configData.gridData;
    }
  }

  public isUnitChanged() {
    this.hasValueChange.emit(true);
    this.configData.unitShiftData[0] = this.isUnitIncluded;
  }

  public isShiftChanged() {
    this.hasValueChange.emit(true);
    this.configData.unitShiftData[1] = this.isShiftTimesIncluded;
  }

  public setData(configData) {
    this.records = configData.gridData;
  }

  public setShiftGroup(data: BIPAShiftGroupTime[]) {
    let dataOutput = [];
    if (data.length > 0) {
      _.forEach(data, (item) => {
        if (item.shiftType === 'Day') {
          dataOutput.push(item.shiftStartTime);
        }
        if (item.shiftType === 'Evening') {
          dataOutput.push(item.shiftStartTime);
        }
        if (item.shiftType === 'Night') {
          dataOutput.push(item.shiftStartTime);
        }
      })
    }
    return dataOutput;
  }

  public setGridData(data: BIPATableData[]) {
    let outputData = [];
    if (data.length > 0) {
      _.map(data, (item) => {
        outputData.push({
          smartlinxPosition: item.slxPosition,
          cmsJobDescription: item.cmsJobDescription
        });
      });
    }
    return outputData;
  }

}
