import { LoaType, ILoaType } from './loa-type';


export interface ILoaTypeModel {
  loaType: ILoaType;
  modifiedBy: string;
  modifiedAt: string;
}

export class LoaTypeModel {
  public loaType: LoaType;
  public modifiedBy: string;
  public modifiedAt: Date;
}

export interface ILoaTypeModelContainer {
  items: ILoaTypeModel[];
  canEdit: boolean;
}

export class LoaTypeModelContainer {
  public static mock: ILoaTypeModelContainer = {
    canEdit: true,
    items: [
      {
        loaType: { id: 1, name: 'Type1', description: 'description1' },
        modifiedAt: '2019-07-11',
        modifiedBy: 'user1'
      },
      {
        loaType: { id: 2, name: 'Type2', description: 'description1' },
        modifiedAt: '2019-07-11',
        modifiedBy: 'user1'
      },
      {
        loaType: { id: 3, name: 'Type3', description: 'description1' },
        modifiedAt: '2019-07-11',
        modifiedBy: 'user1'
      },
      {
        loaType: { id: 4, name: 'Type4', description: 'description1' },
        modifiedAt: '2019-07-11',
        modifiedBy: 'user1'
      }

    ]
  };
  public items: LoaTypeModel[];
  public canEdit: boolean;

}

export interface ILoaTypeEditResult {
  isSuccess: boolean;
  message?: string;
  item: ILoaTypeModel;
}

export class LoaTypeEditResult {
  public isSuccess: boolean;
  public message?: string;
  public item: LoaTypeModel;
}
