import * as _ from 'lodash';
import * as moment from 'moment';

export enum BenefitLineTypeEnum {
  Standart = 'Standart',
  Cobra = 'Cobra'
}

export interface IBenefitDetailsLine {
  id: number;
  name: string;
  benefitLineType: BenefitLineTypeEnum;
  start: string;
  end: string;
  payrollDeductionStartDate: string;
  payrollDeductionEndDate: string;
  totalEnrolledEmployees: number;
}

export class BenefitDetailsLine {
  public id: number;
  public name: string = '';
  public benefitLineType: BenefitLineTypeEnum;
  public startDate: Date;
  public endDate: Date;
  public payrollDeductionStartDate: Date;
  public payrollDeductionEndDate: Date;
  public totalEnrolledEmployees?: number;

  public get isStandart(): boolean {
    return this.benefitLineType === BenefitLineTypeEnum.Standart;
  }

  public get isCobra(): boolean {
    return this.benefitLineType === BenefitLineTypeEnum.Cobra;
  }

  public get startOfStartDate(): Date {
    return _.isDate(this.startDate) ? moment(this.startDate).startOf('day').toDate() : null;
  }

  public get startOfEndDate(): Date {
    return _.isDate(this.endDate) ? moment(this.endDate).startOf('day').toDate() : null;
  }
}
