export * from './portal/portal.component';
export * from './portal-employee/portal-employee.component';
export * from './application-item/application-item.component';
export * from './dashboard-item/dashboard-item.component';
export * from './application-container/application-container.component';
export * from './application/application.component';
export * from './header/header.component';
export * from './header-nav/header-nav.component';
export * from './header-nav-time-element/header-nav-time-element.component';
export * from './header-menu-button/header-menu-button.component';
export * from './header-left-sidebar-button/header-left-sidebar-button.component';
export * from './header-notification-button/header-notification-button.component';
export * from './header-messages-list/header-messages-list.component';
export * from './header-reminders-list/header-reminders-list.component';
export * from './header-timeclocks/header-timeclocks-list/header-timeclocks-list.component';
export * from './footer/footer.component';
export * from './authorizesso/authorizesso.component';
export * from './left-sidebar/left-sidebar.component';
export * from './header-history-bar/header-history-bar.component';
export * from './user-info/user-info.component';
export * from './user-menu/user-menu.component';
export * from './header-notification-popper-content/header-notification-popper-content.component';
export * from './applications-dashboard/index';
export * from './loading-indicator/loading-indicator.component';

import { PortalComponent } from './portal/portal.component';
import { PortalEmployeeComponent } from './portal-employee/portal-employee.component';
import { ApplicationItemComponent } from './application-item/application-item.component';
import { DashboardItemComponent } from './dashboard-item/dashboard-item.component';
import { ApplicationContainerComponent } from './application-container/application-container.component';
import { ApplicationComponent } from './application/application.component';
import { HeaderComponent } from './header/header.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
import { HeaderMenuButtonComponent } from './header-menu-button/header-menu-button.component';
import { HeaderLeftSidebarButtonComponent } from './header-left-sidebar-button/header-left-sidebar-button.component';
import { NotificationButtonComponent } from './header-notification-button/header-notification-button.component';
import { HeaderMessagesListComponent } from './header-messages-list/header-messages-list.component';
import { HeaderRemindersListComponent } from './header-reminders-list/header-reminders-list.component';
import { HeaderTimeclocksListComponent } from './header-timeclocks/header-timeclocks-list/header-timeclocks-list.component';
import { FooterComponent } from './footer/footer.component';
import { AuthorizessoComponent } from './authorizesso/authorizesso.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { HeaderHistoryBarComponent } from './header-history-bar/header-history-bar.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { HeaderNotificaitonPopperContentComponent } from './header-notification-popper-content/header-notification-popper-content.component';
import { HeaderNavTimeElementComponent } from './header-nav-time-element/header-nav-time-element.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { MessageCenterNotificationComponent } from './message-center-notification/message-center-notification.component';
import { MessageCenterNotificationContentComponent } from './message-center-notification-content/message-center-notification-content.component';
import { MessageCenterNotificationListComponent } from './message-center-notification-list/message-center-notification-list.component';

import { dashboardComponents } from './applications-dashboard/index';
import { DashboardAlertsSelectPopupComponent } from './dashboard-alerts/dashboard-alerts-select-popup/dashboard-alerts-select-popup.component';
import { DashboardAlertsSelectPopupDialogComponent } from './dashboard-alerts/dashboard-alerts-select-popup-dialog/dashboard-alerts-select-popup-dialog.component';

export const components: any[] = [
  PortalComponent,
  PortalEmployeeComponent,
  ApplicationItemComponent,
  DashboardItemComponent,
  ApplicationContainerComponent,
  ApplicationComponent,
  HeaderComponent,
  HeaderNavComponent,
  FooterComponent,
  AuthorizessoComponent,
  LeftSidebarComponent,
  HeaderMenuButtonComponent,
  HeaderLeftSidebarButtonComponent,
  HeaderHistoryBarComponent,
  UserInfoComponent,
  UserMenuComponent,
  NotificationButtonComponent,
  HeaderMessagesListComponent,
  HeaderRemindersListComponent,
  HeaderTimeclocksListComponent,
  HeaderNotificaitonPopperContentComponent,
  HeaderNavTimeElementComponent,
  LoadingIndicatorComponent,
  DashboardAlertsSelectPopupComponent,
  DashboardAlertsSelectPopupDialogComponent,
  MessageCenterNotificationComponent,
  MessageCenterNotificationContentComponent,
  MessageCenterNotificationListComponent,
  ...dashboardComponents
];
