<slx-spinner [show]="isLoading">
  <kendo-tabstrip class="slx-tabs" tabPosition="left" class="tabs">
    <ng-container *ngFor="let profile of punchProfile;let i = index">
      <kendo-tabstrip-tab [selected]="i === 0 ? true : false">
        <ng-template kendoTabTitle>
          <h4>{{profile.profileId}}</h4>
        </ng-template>
          <ng-template kendoTabContent>
            <slx-punch-profile-attestation [groups]="groups$ | async" [profileId]="profile.profileId"
              [punchProfileGroup]="punchProfileGroup$ | async" [attestationType]="attestationType" [punchType]="punchType">
            </slx-punch-profile-attestation>
          </ng-template>
      </kendo-tabstrip-tab>
    </ng-container>
  </kendo-tabstrip>
</slx-spinner>