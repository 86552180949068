import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { appCommonRoutes, employeeSectionRoutes } from './portal.common.routes';

import {
  ApplicationContainerComponent,
  HeaderComponent,
  LeftSidebarComponent,
} from './components/index';
import { OrgLevelResolver } from './services/index';

import { TaConsoleComponent, ArrivalsDeparturesDetailsComponent } from '../time-and-attendance/components/index';

export const timeRoutes: Routes = [
  {
    path: '',
    component: ApplicationContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'ta_console',
        pathMatch: 'full'
      },
      {
        path: 'arrivals_departures_details',
        resolve: [OrgLevelResolver],
        component: ArrivalsDeparturesDetailsComponent
      },
      ...appCommonRoutes
    ]
  },
  ...employeeSectionRoutes
];


