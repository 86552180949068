/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeclock-assignment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../timeclock-assignment-header/timeclock-assignment-header.component.ngfactory";
import * as i5 from "../timeclock-assignment-header/timeclock-assignment-header.component";
import * as i6 from "../../../services/timeclock-assignment/timeclock-assignment-management.service";
import * as i7 from "../../../../organization/services/employee/employee-definitions-api.service";
import * as i8 from "../../../../common/services/modal/modal.service";
import * as i9 from "../timeclock-assignment-grid/timeclock-assignment-grid.component.ngfactory";
import * as i10 from "../timeclock-assignment-grid/timeclock-assignment-grid.component";
import * as i11 from "../../../services/timeclock-assignment/timeclock-assignment-api.service";
import * as i12 from "../../../../organization/services/lookup/lookup-api.service";
import * as i13 from "./timeclock-assignment.component";
var styles_TimeclockAssignmentComponent = [i0.styles];
var RenderType_TimeclockAssignmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimeclockAssignmentComponent, data: {} });
export { RenderType_TimeclockAssignmentComponent as RenderType_TimeclockAssignmentComponent };
export function View_TimeclockAssignmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents slx-auto-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-timeclock-assignment-header", [], null, null, null, i4.View_TimeclockAssignmentHeaderComponent_0, i4.RenderType_TimeclockAssignmentHeaderComponent)), i1.ɵdid(5, 180224, null, 0, i5.TimeclockAssignmentHeaderComponent, [i6.TimeclockAssignmentManagementService, i7.EmployeeDefinitionsApiService, i8.ModalService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents slx-auto-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-timeclock-assignment-grid", [], null, null, null, i9.View_TimeclockAssignmentGridComponent_0, i9.RenderType_TimeclockAssignmentGridComponent)), i1.ɵdid(8, 245760, null, 0, i10.TimeclockAssignmentGridComponent, [i6.TimeclockAssignmentManagementService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 8, 0); }, null); }
export function View_TimeclockAssignmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-timeclock-assignment", [], null, null, null, View_TimeclockAssignmentComponent_0, RenderType_TimeclockAssignmentComponent)), i1.ɵprd(512, null, i6.TimeclockAssignmentManagementService, i6.TimeclockAssignmentManagementService, [i11.TimeclockAssignmentApiService, i12.LookupApiService]), i1.ɵdid(2, 245760, null, 0, i13.TimeclockAssignmentComponent, [i6.TimeclockAssignmentManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TimeclockAssignmentComponentNgFactory = i1.ɵccf("slx-timeclock-assignment", i13.TimeclockAssignmentComponent, View_TimeclockAssignmentComponent_Host_0, {}, {}, []);
export { TimeclockAssignmentComponentNgFactory as TimeclockAssignmentComponentNgFactory };
