export class BenAdminMenuItemState {
    public IsEmployeeMappedToUser: boolean;
    public EmployeeRecordHasSSN: boolean;
    public EmployeeRecordHasDateOfBirth: boolean;

    public get isMenuAccessible(): boolean {
        return this.IsEmployeeMappedToUser &&
        this.EmployeeRecordHasSSN &&
        this.EmployeeRecordHasDateOfBirth;
    }

    public get userMessage(): string {
        if (!this.isMenuAccessible) {
            if (!this.IsEmployeeMappedToUser) {
                return 'This user does not have a valid employee record associated with it. Contact your HR administrator for more information.';
            } else if(!this.EmployeeRecordHasSSN || !this.EmployeeRecordHasDateOfBirth) {
                    return 'Your employee record does not have an SSN or DOB. Contact your HR administrator for more information.';
            }
        }
        return '';
    }
}
