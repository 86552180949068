import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Optional, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';

import { CensusEntryApiService } from '../../services/index';
import { AcuityConfiguration, Acuity, CensusEntry, CensusGridData, CensusGridColumnData, CensusGridRecordData } from './../../models/index';
import { CensusCategoryConfig, CensusCategoryConfigRecord, sendCensusCategoryWithCensus } from '../../models/census/census-category';


@Component({
  moduleId: module.id,
  selector: 'slx-census-entries',
  templateUrl: 'census-entries.component.html',
  styleUrls: ['census-entries.component.scss']
})
export class CensusEntriesComponent implements OnInit, OnDestroy {


  @Input()
  public set entryDate(value: Date) {
    if (value && this.m_entryDate && value.getTime() === this.m_entryDate.getTime()) return;
    this.m_entryDate = value;
    if (this.m_entryDate) {
      this.loadData();
    } else {
      this.acuityConfiguration = null;
      this.censusGridData = null;
    }
  }

  @Input()
  public disableDateInput: boolean = false;

  @Input()
  public hideButtons: boolean = false;


  @Output()
  public onSaved: EventEmitter<AcuityConfiguration>;


  public get entryDate(): Date {
    return this.m_entryDate;
  }

  public set acuityConfiguration(value: AcuityConfiguration) {
    this.m_acuityConfiguration = value;
    this.originalCopyToFutureDates = value ? value.copyToFutureDates : false;
  }

  public get acuityConfiguration(): AcuityConfiguration {
    return this.m_acuityConfiguration;
  }

  public get capacityExceeded(): boolean {
    return this.acuityConfiguration ? this.currentCapacity > this.acuityConfiguration.capacity : false;
  }

  public get currentCapacity(): number {
    if (this.censusGridData && this.censusGridData.rows) {
      return this.censusGridData.rows
        .map((r: CensusGridRecordData) => r.capacity)
        .reduce((prev: number, current: number) => prev + current, 0);
    }

    return 0;
  }

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  private get dateNow(): Date {
    return moment().toDate();
  }

  // public allowToExcludeFromTotals: boolean;
  public isCorpOrglevelSelected: boolean;

  public state: {
    isLoading: boolean;
  };

  public originalCensusGridData: CensusGridData;
  public censusGridData: CensusGridData;
  public originalCopyToFutureDates: boolean;
  public hasChanges: boolean;

  @ViewChild('censusForm', {static: true})
  public form: NgForm;

  public totals: any = {};

  private censusEntryApiComponent: CensusEntryApiService;
  private m_entryDate: Date;
  private m_categoriesConfig: CensusCategoryConfig

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private formSubscription: Subscription;

  private selectedOrgLevel: OrgLevel;
  private m_acuityConfiguration: AcuityConfiguration;

  constructor(censusEntryApiComponent: CensusEntryApiService) {
    this.state = {
      isLoading: false
    };
    this.censusEntryApiComponent = censusEntryApiComponent;
    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id)
      .subscribe((o: OrgLevel) => {
        if (o.type !== OrgLevelType.corporation) {
          this.isCorpOrglevelSelected = false;
          if (o.id) {
            this.selectedOrgLevel = o;
            this.loadData();
          }
        } else {
          this.isCorpOrglevelSelected = true;
        }
      });

    this.onSaved = new EventEmitter<AcuityConfiguration>();
  }

  public ngOnInit(): void {
    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.hasChanges = this.isAnyChangesOnModel();
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public saveChanges(): void {
    this.state.isLoading = true;

    // write changes back from grid model to data model
    this.updateAcuityConf();

    // save data

    // this.censusGridData.categoriesConfig.allowToExcludeFromTotals = this.allowToExcludeFromTotals;
    const request: sendCensusCategoryWithCensus = new sendCensusCategoryWithCensus();
    request.configData = this.censusGridData.categoriesConfig;
    request.censusData = this.acuityConfiguration;

    this.censusEntryApiComponent.saveCensusCategories(this.selectedOrgLevel, this.entryDate, request).then(() =>
    this.censusEntryApiComponent.saveCensus(this.selectedOrgLevel, this.entryDate, this.acuityConfiguration)
      .then((ac: AcuityConfiguration) => {
        this.state.isLoading = false;
        this.onSaved.emit(ac);
        this.formInitialize(ac, this.censusGridData.categoriesConfig);
        this.recalcTotals(this.censusGridData);
      })
      .catch(() => {
        this.state.isLoading = false;
      }));
  }

  public discardChanges(): void {
    this.acuityConfiguration.copyToFutureDates = this.originalCopyToFutureDates;
    this.censusGridData = _.cloneDeep(this.originalCensusGridData);
    this.recalcTotals(this.censusGridData);
  }

  public isAnyChangesOnModel(): boolean {
    if (!this.originalCensusGridData) return false;
    if (this.acuityConfiguration.copyToFutureDates !== this.originalCopyToFutureDates) return true;
    let isNotEqual: boolean = false;
    isNotEqual = JSON.stringify(this.censusGridData) !== JSON.stringify(this.originalCensusGridData);
    return isNotEqual;
  }

  public get canSave(): boolean {
    return this.form.valid && !this.capacityExceeded
      && this.hasChanges && !_.isEmpty(this.acuityConfiguration.items);
  }

  public onChanged(event: any, category: string, dataItem: CensusGridRecordData): void {
    dataItem.values[category] = event;
    this.hasChanges = this.isAnyChangesOnModel();
    this.recalcTotals(this.censusGridData);
  }

  public onChangeCopyToFutureDatesCheckbox(): void{
    this.hasChanges = this.isAnyChangesOnModel();
  }

  public onCategoryConfigChange($event, oldValue): void {
    this.hasChanges = this.isAnyChangesOnModel();
    this.recalcTotals(this.censusGridData);
  }

  public onSettingChanged($event): void {
    if(this.censusGridData.categoriesConfig.allowToExcludeFromTotals != $event) {
      this.censusGridData.categoriesConfig.allowToExcludeFromTotals = $event;
      this.hasChanges = this.isAnyChangesOnModel();
      this.formInitialize(this.acuityConfiguration, this.censusGridData.categoriesConfig, false);
    }
    if (this.censusGridData.categoriesConfig != null && !this.censusGridData.categoriesConfig.allowToExcludeFromTotals) {
      for(let entryKey  in this.censusGridData.categoriesConfig.records)
        {
          if (this.censusGridData.categoriesConfig.records.hasOwnProperty(entryKey )) {
            let entry = this.censusGridData.categoriesConfig.records[entryKey];
            entry.excludeFromTotal=false;
            entry.includedInTotal=true;
          }
        }
        this.recalcTotals(this.censusGridData);
    } 
    if (this.censusGridData.categoriesConfig != null && this.originalCensusGridData.categoriesConfig != null &&this.censusGridData.categoriesConfig.allowToExcludeFromTotals && this.originalCensusGridData.categoriesConfig.allowToExcludeFromTotals) {
      this.discardChanges();
    }
  }

  private createCensusGridData(conf: AcuityConfiguration, categoriesConfig: CensusCategoryConfig, resetOriginal: boolean = true): void {
    let gridData: CensusGridData = new CensusGridData();
    gridData.categoriesConfig = categoriesConfig;
    let columnCount: number = 0;
    let columns: CensusGridColumnData[] = [];
    let rows: CensusGridRecordData[] = [];
    let settingsRow = new CensusGridRecordData();
    settingsRow.type = 'Please select the box to exclude from the total';
    settingsRow.capacity = 0;
    settingsRow.isSettings = true;
    columnCount = 0;
    _.each(_.sortBy(categoriesConfig.records, 'sortId'), (r: CensusCategoryConfigRecord) => {
      let settingsCensusEntry = new CensusEntry();
      settingsCensusEntry.isSettings = true;
      settingsCensusEntry.category = r.name;
      settingsCensusEntry.censusConfig = r;
      settingsCensusEntry.value = 0;
      settingsRow.entries['column' + columnCount] = settingsCensusEntry;
      columnCount++;
    });

      rows.push(settingsRow)

      let rowCount: number = 0;
    if (conf) {
      _.each(conf.items, (a: Acuity) => {
        _.sortBy(a.entries, 'category');
        columnCount = 0;
        let rowData: CensusGridRecordData = new CensusGridRecordData();
        rowData.type = a.type;
        rowData.capacity = a.capacity;
        _.each(a.entries, (e: CensusEntry) => {
          let columnData: CensusGridColumnData;
          if (rowCount === 0) {
            columnData = new CensusGridColumnData();
            columnData.field = 'column' + columnCount;
            columnData.title = e.category;
            columns.push(columnData);
          }
          rowData.values['column' + columnCount] = e.value;
          rowData.entries['column' + columnCount] = e;
          columnCount++;
        });
        rowCount++;
        rows.push(rowData);
      });
    }

    gridData.columns = columns;
    gridData.rows = rows;

    this.recalcTotals(gridData);
    
    if (!categoriesConfig.allowToExcludeFromTotals)
      gridData.rows = rows.slice(1);

    if(resetOriginal) {    
      this.originalCensusGridData = gridData;
    }
    this.censusGridData = _.cloneDeep(gridData);
  }

  private updateAcuityConf(): void {
    if (this.censusGridData && this.m_acuityConfiguration) {
      _.forEach(this.censusGridData.rows, (row: CensusGridRecordData) => {
        let valueKeys: string[] = _.keys(row.values);
        let acuity: Acuity = _.find(this.m_acuityConfiguration.items, (a: Acuity) => {
          return a.type === row.type;
        });
        if (acuity) {
          _.each(valueKeys, (key: string) => {
            let entry: CensusEntry = _.find(acuity.entries, (e: CensusEntry) => {
              return e.category === row.entries[key].category;
            });
            if (entry) {
              entry.value = +row.values[key];
            } else {
              throw new Error('Can not update acuity configuration');
            }
          });
        }
      });
    }
  }

  private recalcTotals(censusGridData: CensusGridData): void {

    this.totals = { 'totals': 0 };

    if (censusGridData) {
      _.forEach(censusGridData.rows, (row: CensusGridRecordData) => {
        let keys: string[] = _.keys(row.values);
        let rowTotal: number = 0;
        _.forEach(keys, (key: string) => {
          if (!this.totals[key]) this.totals[key] = 0;
          let configCell = undefined;
          if (censusGridData && censusGridData.rows[0] &&
            censusGridData.rows[0].entries[key] && censusGridData.rows[0].entries[key].censusConfig) {
            configCell = censusGridData.rows[0].entries[key].censusConfig;
          }
          if (!configCell || configCell.includedInTotal) {
            rowTotal += +row.values[key];
            this.totals[key] += +row.values[key];
          }
        });
        row.capacity = rowTotal;
        this.totals['totals'] += rowTotal;
      });
    }
  }

  private formInitialize(value: AcuityConfiguration, config: CensusCategoryConfig, resetOriginal: boolean = true): void {
    Assert.isNotNull(value, 'AcuityConfiguration');
    Assert.isNotNull(config, 'CensusCategoryConfig');
    this.entryDate = value.date;
    this.acuityConfiguration = _.cloneDeep(value);
    this.createCensusGridData(value, config, resetOriginal);
  }

  private loadData(): void {
    if (this.selectedOrgLevel && this.entryDate) {
      this.state.isLoading = true;
      this.censusEntryApiComponent.getCensus(this.selectedOrgLevel, this.entryDate).then((ac: AcuityConfiguration) => {
        this.censusEntryApiComponent.getCensusCategories(this.selectedOrgLevel).then((config: CensusCategoryConfig) => {
          this.formInitialize(ac, config);
          this.state.isLoading = false;
          // this.recalcTotals(this.censusGridData);
          }).catch((): void => {
            this.state.isLoading = false;
          });
      }).catch((): void => {
        this.state.isLoading = false;
      });
    }
  }
}
