import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { OrgLevel } from './../../../../state-model/models/index';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from './../../../../core/decorators/index';
import { OnInit } from '@angular/core';
import { PunchUtils } from '../../../utils/punch-utils';
var DailyPunchesPageComponent = /** @class */ (function () {
    function DailyPunchesPageComponent() {
    }
    DailyPunchesPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (value) {
            _this.orgLevel = value;
            _this.showRollup = PunchUtils.isRollup(value);
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], DailyPunchesPageComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPunchesPageComponent.prototype, "orgLevelSubscription", void 0);
    return DailyPunchesPageComponent;
}());
export { DailyPunchesPageComponent };
