import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';

export class AccrualBalanceNavigationService {
    private router: Router;
    private route: ActivatedRoute;
    constructor(router: Router, route: ActivatedRoute) {
        this.router = router;
        this.route = route;
    }

    public NavigateToAccrualBalance(orgLevelId: number = 0):void{
        let params: any;
         if (orgLevelId) {
            params = { orgLevelId: orgLevelId };
        }
          this.router.navigateByUrl(this.getUrlTree({ orgLevelId: orgLevelId }));
        }
        private getUrlTree(params: any): UrlTree {
            return this.router.createUrlTree(['/apps/accruals/accrual_balances'], { relativeTo: this.route, queryParams: params });
          }
    }


