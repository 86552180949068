import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ACAConfigurationManagementService } from '../../../services/aca-config/aca-manage-management.service';
import { unsubscribe } from '../../../../core/decorators/index';
import { appConfig } from '../../../../app.config';
import { ModalService, KendoGridStateHelper, ConfirmOptions, ConfirmDialogComponent, ChangeManagementService, DeviceDetectorService } from '../../../../common/index';
var ManageAcaPeriodsComponent = /** @class */ (function () {
    function ManageAcaPeriodsComponent(management, modalService, changeService, devDetector) {
        this.management = management;
        this.modalService = modalService;
        this.changeService = changeService;
        this.devDetector = devDetector;
        this.subscriptions = {};
        this.pageSize = 50;
        this.m_minimumDate = new Date("1/1/2014");
        this.newHireMeasMax = false;
        this.newHireAdminMax = false;
        this.newMaxHireStabPer = false;
        this.stdConfigMeasMax = false;
        this.stdConfigAdminMax = false;
        this.stdConfigStabPer = false;
        this.isValidate = false;
        this.isEditMode = false;
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [{ field: 'employer.name', dir: 'asc' }];
    }
    Object.defineProperty(ManageAcaPeriodsComponent.prototype, "isLockedColumn", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    ManageAcaPeriodsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = false;
        this.onloadedSubscription = this.management.subscribeToOnLoaded(function (state) {
            _this.canEdit = state.actions.canEdit;
            _this.canExportToExcel = state.actions.canExportToExcel;
            _this.canExportToPdf = state.actions.canExportToPdf;
            _this.m_container = state;
            _this.originalRecords = _.cloneDeep(state);
            _this.refreshGrid();
        });
        this.stateSubscription = this.management.subscribeToOnStateChanged(function (state) {
            _this.isLoading = state.isLoading;
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function () { return _this.refreshGrid(); });
        this.subscriptions.gridEditSubscription = this.gridState.onEdit$.subscribe(function (item) {
            _this.onEditItems(item);
        });
        this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            _this.gridSaveSubscribe(event.dataItem);
        });
        this.subscriptions.gridCancelSubscription = this.gridState.onCancel$.subscribe(function (item) {
            _this.cancelSubscription();
        });
        this.subscriptions.export = this.management.subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.management.init();
    };
    ManageAcaPeriodsComponent.prototype.gridSaveSubscribe = function (dataItem) {
        var _this = this;
        var options = new ConfirmOptions();
        options.height = 230;
        options.showCancel = true;
        options.showOK = true;
        options.buttonCanceltext = "No";
        options.buttonOKtext = "Yes";
        ConfirmDialogComponent.openDialog('Warning', 'Modifying ACA Measurement settings will require ACA measurements, stability periods, and ACA status determinations to be recalculated, which may result in changes to Form 1095-C indicator code suggestions for any years that are currently unlocked. Are you sure you want to proceed with this change?', this.modalService, function (result) {
            if (result) {
                _this.doSave(dataItem);
            }
            else {
                _this.resetPreviousRecord(dataItem);
            }
        }, options);
    };
    ManageAcaPeriodsComponent.prototype.resetPreviousRecord = function (item) {
        var orgPA = _.find(this.originalRecords.records, ['id', item.id]);
        item.newHireFirstOfTheMonth = orgPA.newHireFirstOfTheMonth;
        item.newHireAllowOverlap = orgPA.newHireAllowOverlap;
        item.newHireMeasurementPeriod = orgPA.newHireMeasurementPeriod;
        item.newHireAdministrativePeriod = orgPA.newHireAdministrativePeriod;
        item.newHireStabilityPeriod = orgPA.newHireStabilityPeriod;
        item.standardConfigFirstDayOfMeasurement = orgPA.standardConfigFirstDayOfMeasurement;
        item.standardConfigMeasurementPeriod = orgPA.standardConfigMeasurementPeriod;
        item.standardConfigAdministrativePeriod = orgPA.standardConfigAdministrativePeriod;
        item.standardConfigStabilityPeriod = orgPA.standardConfigStabilityPeriod;
        this.isEditMode = false;
    };
    ManageAcaPeriodsComponent.prototype.doSave = function (item) {
        this.isLoading = true;
        this.isEditMode = false;
        this.management.postManageACARecord(item);
        this.changeService.clearChanges();
    };
    ManageAcaPeriodsComponent.prototype.cancelSubscription = function () {
        this.isEditMode = false;
        this.stdConfigMeasMax = false;
        this.stdConfigAdminMax = false;
        this.stdConfigStabPer = false;
        this.newHireMeasMax = false;
        this.newHireAdminMax = false;
        this.newMaxHireStabPer = false;
        this.changeService.clearChanges();
    };
    ManageAcaPeriodsComponent.prototype.onClickExport = function (isPDF) {
        this.management.exportTo(isPDF);
    };
    ManageAcaPeriodsComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    ManageAcaPeriodsComponent.prototype.getGridData = function () {
        var _this = this;
        return function () {
            return {
                data: _.cloneDeep(_this.gridState.view.data),
            };
        };
    };
    ManageAcaPeriodsComponent.prototype.minDateLimit = function () {
        return this.m_minimumDate;
    };
    ManageAcaPeriodsComponent.prototype.onChangeValue = function () {
        this.validationCheck();
    };
    ManageAcaPeriodsComponent.prototype.onChangeNewHireMeasPer = function (item) {
        this.newHireMeasMax = false;
        this.newHireAdminMax = false;
        this.newMaxHireStabPer = false;
        var result;
        result = (item.newHireAdministrativePeriod / 30) + item.newHireMeasurementPeriod;
        if (_.isNil(item.newHireMeasurementPeriod) || item.newHireMeasurementPeriod <= 3) {
            this.newHireMeasMax = false;
        }
        else if (result > 13.99) {
            this.newHireMeasMax = true;
        }
        else {
            this.newHireMeasMax = false;
        }
        this.onChangeNewHireStabPer(item);
        this.validationCheck();
    };
    ManageAcaPeriodsComponent.prototype.onChangeNewHireAdminPer = function (item) {
        this.newHireMeasMax = false;
        this.newHireAdminMax = false;
        var result;
        result = (item.newHireAdministrativePeriod / 30) + item.newHireMeasurementPeriod;
        if (_.isNil(item.newHireMeasurementPeriod) || item.newHireMeasurementPeriod <= 3) {
            this.newHireAdminMax = false;
        }
        else if (result > 13.99) {
            this.newHireAdminMax = true;
        }
        else {
            this.newHireAdminMax = false;
        }
        this.validationCheck();
    };
    ManageAcaPeriodsComponent.prototype.onChangeNewHireStabPer = function (item) {
        if (item.newHireMeasurementPeriod > item.newHireStabilityPeriod) {
            this.newMaxHireStabPer = true;
        }
        else {
            this.newMaxHireStabPer = false;
        }
        this.validationCheck();
    };
    ManageAcaPeriodsComponent.prototype.onChangeStdConfigMeasPer = function (item) {
        this.stdConfigMeasMax = false;
        this.stdConfigAdminMax = false;
        this.stdConfigStabPer = false;
        var result;
        result = (item.standardConfigAdministrativePeriod / 30) + item.standardConfigMeasurementPeriod;
        if (_.isNil(item.standardConfigMeasurementPeriod) || item.standardConfigMeasurementPeriod <= 3) {
            this.stdConfigMeasMax = false;
        }
        else if (result > 13.99) {
            this.stdConfigMeasMax = true;
        }
        else {
            this.stdConfigMeasMax = false;
        }
        this.onChangeStdConfigStabPer(item);
        this.validationCheck();
    };
    ManageAcaPeriodsComponent.prototype.onChangeStdConfigAdminPer = function (item) {
        this.stdConfigMeasMax = false;
        this.stdConfigAdminMax = false;
        var result;
        result = (item.standardConfigAdministrativePeriod / 30) + item.standardConfigMeasurementPeriod;
        if (_.isNil(item.standardConfigMeasurementPeriod) || item.standardConfigMeasurementPeriod <= 3) {
            this.stdConfigAdminMax = false;
        }
        else if (result > 13.99) {
            this.stdConfigAdminMax = true;
        }
        else {
            this.stdConfigAdminMax = false;
        }
        this.validationCheck();
    };
    ManageAcaPeriodsComponent.prototype.onChangeStdConfigStabPer = function (item) {
        if (item.standardConfigMeasurementPeriod > item.standardConfigStabilityPeriod) {
            this.stdConfigStabPer = true;
        }
        else {
            this.stdConfigStabPer = false;
        }
        this.validationCheck();
    };
    ManageAcaPeriodsComponent.prototype.validationCheck = function () {
        if (this.newHireMeasMax || this.newHireAdminMax || this.newMaxHireStabPer || this.stdConfigMeasMax || this.stdConfigAdminMax || this.stdConfigStabPer) {
            this.isValidate = true;
        }
        else {
            this.isValidate = false;
        }
    };
    ManageAcaPeriodsComponent.prototype.onEditItems = function (manageACARecord) {
        this.isValidate = true;
        this.isEditMode = true;
        if (_.isNull(manageACARecord.newHireFirstOfTheMonth)) {
            manageACARecord.newHireFirstOfTheMonth = false;
        }
        if (_.isNull(manageACARecord.newHireAllowOverlap)) {
            manageACARecord.newHireAllowOverlap = false;
        }
        if (_.isNull(manageACARecord.newHireMeasurementPeriod)) {
            manageACARecord.newHireMeasurementPeriod = 12;
        }
        if (_.isNull(manageACARecord.newHireAdministrativePeriod)) {
            manageACARecord.newHireAdministrativePeriod = 30;
        }
        if (_.isNull(manageACARecord.newHireStabilityPeriod)) {
            manageACARecord.newHireStabilityPeriod = 12;
        }
        if (_.isNull(manageACARecord.standardConfigMeasurementPeriod)) {
            manageACARecord.standardConfigMeasurementPeriod = 12;
        }
        if (_.isNull(manageACARecord.standardConfigAdministrativePeriod)) {
            manageACARecord.standardConfigAdministrativePeriod = 30;
        }
        if (_.isNull(manageACARecord.standardConfigStabilityPeriod)) {
            manageACARecord.standardConfigStabilityPeriod = 12;
        }
        this.changeService.changeNotify();
    };
    ManageAcaPeriodsComponent.prototype.refreshGrid = function () {
        if (!this.m_container) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_container.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ManageAcaPeriodsComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ManageAcaPeriodsComponent.prototype, "onloadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ManageAcaPeriodsComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ManageAcaPeriodsComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ManageAcaPeriodsComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ManageAcaPeriodsComponent.prototype, "gridCancelSubscription", void 0);
    return ManageAcaPeriodsComponent;
}());
export { ManageAcaPeriodsComponent };
