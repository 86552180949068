export interface IShiftSwapDetails {
    shiftSwapId: number;
    submittedBy: IEmployeeShiftSwapDetails;
    submittedTo: IEmployeeShiftSwapDetails;
}

export interface IEmployeeShiftSwapDetails {
    id: number;
    fullName: string;
    departmentId: number;
    organizationId: number;
    homePositionId: number;
    unionStatus: string;
    type: string;
    dateHired: string;
    projectedHours: number;
    projectedForOvertime: boolean;
}

export class ShiftSwapDetails {
    public shiftSwapId: number;
    public submittedBy: EmployeeShiftSwapDetails;
    public submittedTo: EmployeeShiftSwapDetails;
}

export class EmployeeShiftSwapDetails {
    public id: number;
    public fullName: string;
    public departmentId: number;
    public organizationId: number;
    public homePositionId: number;
    public unionStatus: string;
    public type: string;
    public dateHired: string;
    public projectedHours: number;
    public projectedForOvertime: boolean;
    public department: string;
    public position: string;
}
