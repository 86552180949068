import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { process, aggregateBy } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { DateRange } from '../../../../../core/models/index';
import { PbjReconciliationManagementService } from '../../../services/index';
import { PBJRecColumns } from '../../../models/index';
var PbjReconciliationOrgGridComponent = /** @class */ (function () {
    function PbjReconciliationOrgGridComponent(managementService) {
        this.managementService = managementService;
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'orgName', dir: 'asc' }];
        var rbjRecCols = new PBJRecColumns();
        this.columns = rbjRecCols.makeColumns();
        this.aggregates = rbjRecCols.makeAggregates();
        this.pbjRecOrgs = null;
        this.totals = {};
    }
    PbjReconciliationOrgGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.managementService.subscribeToOrgLevel(function (orgLevel) {
            _this.orgLevel = orgLevel;
            _this.loadData();
        });
        this.dateRangeSubscription = this.managementService.subscribeToReconOrglevel(function (data) {
            _this.dateRange = new DateRange(data.startDate, data.endDate);
            _this.loadData();
        });
        this.expandedDetailsSubscription = this.managementService.subscribeToExpandedDetails(function (isOn) {
            _this.showDetails = isOn;
        });
        this.loadedEntriesSubscription = this.managementService.subscribeToLoadedOrgEntries(function (entries) {
            _this.pbjRecOrgs = entries;
            _this.refreshGrid();
        });
        this.exportToPdfSubscription = this.managementService.subscribeToExportToPdf(function () {
            _this.grid.saveAsPDF();
        });
        this.exportToExcelSubscription = this.managementService.subscribeToExportToExcel(function () {
            _this.grid.saveAsExcel();
        });
        this.refreshGridSubscription = this.gridState.onRefreshGrid.subscribe(function () {
            _this.refreshGrid();
        });
    };
    PbjReconciliationOrgGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(PbjReconciliationOrgGridComponent.prototype, "fileName", {
        get: function () {
            return _.get(this.orgLevel, 'name') + " Pbj Reconciliation.pdf";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjReconciliationOrgGridComponent.prototype, "xlsxFileName", {
        get: function () {
            return _.get(this.orgLevel, 'name') + " Pbj Reconciliation.xlsx";
        },
        enumerable: true,
        configurable: true
    });
    PbjReconciliationOrgGridComponent.prototype.defineRowClass = function () {
        return function (row) {
            return +row.dataItem.reconciliation.variance === 0 ? 'reconcil-row' : 'reconcil-row reconcil-highlighted';
        };
    };
    PbjReconciliationOrgGridComponent.prototype.loadData = function () {
        if (_.isNumber(_.get(this.orgLevel, 'id')) &&
            _.isDate(_.get(this.dateRange, 'startDate')) &&
            _.isDate(_.get(this.dateRange, 'endDate'))) {
            this.managementService.loadOrgEntries(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate);
        }
    };
    PbjReconciliationOrgGridComponent.prototype.refreshGrid = function () {
        if (!this.pbjRecOrgs) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.pbjRecOrgs, this.gridState.state);
        this.totals = aggregateBy(this.gridState.view.data, this.aggregates);
    };
    PbjReconciliationOrgGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.gridState.view.data, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgGridComponent.prototype, "loadedEntriesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgGridComponent.prototype, "refreshGridSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgGridComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgGridComponent.prototype, "dateRangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgGridComponent.prototype, "expandedDetailsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgGridComponent.prototype, "exportToPdfSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgGridComponent.prototype, "exportToExcelSubscription", void 0);
    return PbjReconciliationOrgGridComponent;
}());
export { PbjReconciliationOrgGridComponent };
