import * as _ from 'lodash';
import { Component, Provider, OnChanges, SimpleChanges, OnInit, ViewChild, ViewEncapsulation, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import {
  IDialog,
  DialogOptions2,
} from '../../../../common/models/index';
import { ModalService } from './../../../../common/services/index';
import { Lookup } from '../../../../organization/models';
import { EmployeeSMSContacts, IEmployeeSendSms, EmployeeSendSms, SlateMessageSendDTO, MessageSendDTO } from '../../models/index';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { isNumeric } from "rxjs/util/isNumeric";
import { Subscription } from 'rxjs/Subscription';
import { EmployeeSmsManagementService } from '../../../../../app/configuration/services/employee-sms-management/employee-sms-management.service';
import { EmployeeSmsManagementApiService } from '../../../../../app/configuration/services/employee-sms-management/employee-sms-management-api.service';
import { Subject, Observable } from 'rxjs';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';
import { mutableSelect, unsubscribe, unsubscribeAll } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models';
import {SegmentedMessage} from '../../utils/segments-calculate';
import { NotificationsService } from '../../../../core/components';
import { MessagesManagementService } from '../../services/messages-management.service';
import { ComposeNewTabsPermissions } from '../../models/ComposeNewTabsPermissions';
import { SlateMessagesApiService } from '../../services/slate-messages-api.service';
import { SlateMessagesManagementService } from '../../services/slate-messages-management.service';
@Component({
  selector: 'slx-sms-compose-new-dialog',
  templateUrl: './sms-compose-new-dialog.component.html',
  styleUrls: ['./sms-compose-new-dialog.component.scss'],
  styles: [`
  .k-nodata, .k-nodata .ng-star-inserted {  display: none   }
  `],
  encapsulation: ViewEncapsulation.None,
  providers: [EmployeeSmsManagementService]
})
export class SmsComposeNewDialogComponent implements IDialog {
  public isDisabled = false;
  public activeTab: number = 0;
  public isExpanded: boolean = false;
  public canSendSMS: boolean = false;
  public canSendSlate: boolean = false;
  public subject: string = '';
  public message: string = '';

  @ViewChild('multiselectText', { static: true })
  public multiselectText: MultiSelectComponent;
  @ViewChild('msg1ref', { static: false })
  public msg1ref: ElementRef;
  public message1: string = "";
  public mobileNoParseRegex = /[\(\)\-\s']+/g;
  public maxCharPerSms = 320;
  public dialogResult: boolean;
  public selectEmployeeSMSContacts: EmployeeSMSContacts[] = [];
  public selectEmployeeSlateContacts: EmployeeSMSContacts[] = [];
  public isLoading: boolean;
  private subscriptions: StringMap<Subscription> = {};
  public messageCount: number = 0;
  public employeeSMSContacts: EmployeeSMSContacts[] = [];
  public searchTerm$ = new Subject<string>();
  private appSettings: AppServerConfig;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<any>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  orgLevelID = null;
  searchedValue = '';
  constructor(
    private dialogOptions: DialogOptions2,
    private notificationsService: NotificationsService,
    private modalService: ModalService,
    private employeeManService: EmployeeSmsManagementService,
    private appSettingsManageService: AppSettingsManageService,
    private changeDetectorRef: ChangeDetectorRef,
    private messagesManagementService: MessagesManagementService,
    private slateMessagesApiService: SlateMessagesApiService,
    private slateMessagesManagementService: SlateMessagesManagementService,
  ) {
  }

  public ngOnInit(): void {
    this.employeeManService.searchEmployee(this.searchTerm$)
      .subscribe((v: EmployeeSMSContacts[]) => {
        for (let e of this.selectEmployeeSMSContacts) {
          v = v.filter((val) => e.employeeId != val.employeeId);
        }
        if(this.searchedValue !== '' && this.searchedValue.length >= 2) {
          this.employeeSMSContacts = v;
        }
      });
    this.getAppSettings();

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && orgLevel.id) {
        this.orgLevelID = orgLevel.id;
      }
    });
    
    this.messagesManagementService.getComposeNewTabsPermissions(this.orgLevelID).then((cNewTabs: ComposeNewTabsPermissions)=>{
      this.canSendSMS = cNewTabs.canSendSMS;
      this.canSendSlate = cNewTabs.canSendSlate;
      if (!this.canSendSMS && this.canSendSlate){
        this.activeTab = 1;
      }
      this.multiselectText.toggle(false);
    });
  }

  private async getAppSettings(): Promise<void> {
    try {
      this.appSettings = await this.appSettingsManageService.getAppServerConfig();
    } catch (e) { }
  }

  public static openDialog(
    modalService: ModalService,
    callback: (result: boolean) => void
  ): SmsComposeNewDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();

    dialogOptions.height = "70%";
    dialogOptions.width = "50%";
    dialogOptions.minWidth = 300;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions,
      }
    ];
    let dialog: SmsComposeNewDialogComponent = modalService.globalAnchor.openDialog2(
      SmsComposeNewDialogComponent,
       'Compose New Message',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
      }
    );
    return dialog;
  }

  handleFilter(value): void {
    value = value.trim(' ');
    this.searchedValue = value;
    this.employeeSMSContacts = [];
    if (value != '' && value.length < 100 && value.length >= 2) {
      this.searchTerm$.next(value);
    } else {
      this.searchTerm$.next(null);
    }
  }

  public itemDisabled(itemArgs: { dataItem: EmployeeSMSContacts }) {
    if (itemArgs.dataItem.mobilePhoneNumber === "(___) ___-____")
      itemArgs.dataItem.mobilePhoneNumber = null;
    if (itemArgs.dataItem.mobilePhoneNumber != null && itemArgs.dataItem.empOptIn == 1)
      return false;
    else
      return true;
  }

  public slateEmployeeDisabled(itemArgs: { dataItem: EmployeeSMSContacts }) {
    if (itemArgs.dataItem.mobilePhoneNumber === "(___) ___-____")
      itemArgs.dataItem.mobilePhoneNumber = null;
    if (itemArgs.dataItem.badgeId != null && itemArgs.dataItem.badgeId > 0) {
      return false;
    }

    return true;
  }

  validationMessage(): void {
    this.changeDetectorRef.detectChanges();
    if (this.message1.length > this.maxCharPerSms) {
      this.message1 = this.message1.substring(0, this.maxCharPerSms);
    }
    this.messageCount = this.message1.length;
    this.changeDetectorRef.markForCheck();
  }
  
  validateSlateMessage(): void {
    this.changeDetectorRef.detectChanges();
    if (this.message.length > this.maxCharPerSms) {
      this.message = this.message.substring(0, this.maxCharPerSms);
    }
    this.messageCount = this.message.length;
    this.changeDetectorRef.markForCheck();
  }

  sendSms() {
    const employeeSendSms: IEmployeeSendSms = new EmployeeSendSms();
    employeeSendSms.messages = [];
    for (let emp of this.selectEmployeeSMSContacts) {
      if (emp.empOptIn != 1 || !emp.mobilePhoneNumber) {
        throw new Error(`Error in Employee Object. either empOptIn or mobilePhoneNumber values are incorrect. empOptIn: ${emp.empOptIn}, mobilePhoneNumber: ${emp.mobilePhoneNumber}`);
      }

      employeeSendSms.orgLevelId = this.orgLevelID;
      employeeSendSms.accountSid = this.appSettings.TwilioAccountSid;
      employeeSendSms.authToken = this.appSettings.TwilioAuthToken;
      employeeSendSms.messages.push({
        body: this.message1,
        fullName: emp.employeeName,
        to: emp.countryCode + emp.mobilePhoneNumber.replace(this.mobileNoParseRegex, ""),
        from: this.appSettings.TwilioSenderPhone,
        read: true,
        employeeId: emp.employeeId,
        isOptInRequest: false
      });
    }
    if(employeeSendSms.accountSid == null && employeeSendSms.authToken == null){
      this.isLoading = false;
      this.notificationsService.error('Authorization token not found, Please contact support.');
      this.dialogResult = false;
      this.closeWindow();
      return;
    }
    this.isLoading = true;
    this.employeeManService.sendSmsToEmployee(employeeSendSms).then(() => {
      this.isLoading = false;
      this.notificationsService.success('SMS message sent', 'SMS message was sent successfully.');
      this.dialogResult = true;
      this.closeWindow();
    })
    .catch(() => {
      this.isLoading = false;
      this.notificationsService.error('Unexpected error','Please try again or contact support.');
      this.dialogResult = false;
      this.closeWindow();
    });
  }

  async sendSlateMessage() {
    if (this.subject.trim().length > 0 && this.message.trim().length > 0 && this.selectEmployeeSlateContacts.length > 0) {
      let slateMessageRequest = new SlateMessageSendDTO();
      slateMessageRequest.message = new MessageSendDTO();
      slateMessageRequest.message.messageContent = this.message;
      slateMessageRequest.message.subject = this.subject;
      slateMessageRequest.employeeIds = this.selectEmployeeSlateContacts.map((contact: EmployeeSMSContacts) => {
        return contact.employeeId;
      });
      try {
        this.isLoading = true;
        await this.slateMessagesApiService.sendSlateMessage(this.orgLevelID, slateMessageRequest);
        this.slateMessagesManagementService.updateMessagesData();
        this.notificationsService.success('Slate message sent', 'Slate message was sent successfully.');
        this.dialogResult = true;
        this.closeWindow();
        this.isLoading = false;
      } catch (exeption) {
        this.dialogResult = false;
        this.closeWindow();
        this.isLoading = false;
        this.notificationsService.error('Unexpected error', 'Please try again or contact support.');
      }
    }
  }

  public sendMessage() {
    this.activeTab == 0 ? this.sendSms() : this.sendSlateMessage();
  }

  public cancel() {
    this.closeWindow();
  }

  public closeWindow() {
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public getSegmentCount():string {    
    const encoding = "auto";    
    const segmentedMessage = new SegmentedMessage(this.activeTab == 0 ? this.message1: this.message, encoding);
    return segmentedMessage.segments.length == 1? "1 Segment" : segmentedMessage.segments.length + " Segments";   
  }

  public onClickTab(tab: number): void {
    if (this.activeTab != tab) {
      this.clearData();
    }
    if (this.activeTab === tab || this.isDisabled) return;
    this.activeTab = tab;
    this.isActiveMenu(this.activeTab);
  }

  public isActiveMenu(tabOrder: number): boolean {
    return this.activeTab === tabOrder;
  }

  clearData() {
    this.message = '';
    this.message1 = '';
    this.subject = '';
    this.selectEmployeeSlateContacts = [];
    this.selectEmployeeSMSContacts = [];
  }
}
