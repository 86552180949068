import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { BudgetCensus } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-budget-census-adjust',
  templateUrl: 'budget-census-adjust.component.html',
  styleUrls: ['budget-census-adjust.component.scss']
})
export class BudgetCensusAdjustComponent implements OnChanges {
  @Input()
  public startDate: Date;
  @Input()
  public budgetCensus: BudgetCensus;
  @Output()
  public finishEditing: EventEmitter<boolean>;
  public editedBudgetCensus: BudgetCensus;

  constructor() {
    this.finishEditing = new EventEmitter();
    this.editedBudgetCensus = new BudgetCensus();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.budgetCensus && changes['budgetCensus']) {
      this.editedBudgetCensus.budgetedCensus = this.budgetCensus.budgetedCensus;
      this.editedBudgetCensus.maximumCapacity = this.budgetCensus.maximumCapacity;
    }
  }

  public onDiscard(): void {
    this.finishEditing.emit(false);
  }

  public onSave(): void {
    this.budgetCensus.budgetedCensus = this.editedBudgetCensus.budgetedCensus;
    this.budgetCensus.maximumCapacity = this.editedBudgetCensus.maximumCapacity;
    this.finishEditing.emit(true);
  }

}
