import * as moment from 'moment';
import { LookupEntity, ILookupEntity } from './lookup-entity';
import { appConfig, IApplicationConfig } from '../../../app.config';

const dateFormat: string = 'DD/MM/YY';

export interface IPosition extends ILookupEntity {
  orgLevelId: number;
  organizationName?: string;
  group?: string;
  startDate?: string;
  endDate?: string;
  groupedWithPositionId?: number;
  positionGroupId?: number;
  positionGroupName?: string;
  fullName?: string;
  nameWithDate?: string;
}

export class Position extends LookupEntity {
  public orgLevelId: number;
  public organizationName: string;
  public fullName: string;
  public group: string;
  public startDate: Date;
  public endDate: Date;
  public groupedWithPositionId: number;
  public positionGroupId: number;
  public positionGroupName: string;
  public get nameWithDate(): string {
    const startDate: string = moment(this.startDate).format(appConfig.dateFormatShortYear);
    let endDate: moment.Moment = moment(this.endDate);

    return `${this.name} (${startDate} - ${endDate.isValid() ? endDate.format(appConfig.dateFormatShortYear) : 'current'})`;
  }
}
