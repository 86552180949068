<kendo-grid #kendoGrid [data]="gridView" [sortable]="{ mode: 'multiple' }" [selectable]="{ mode:'single'}" [sort]="gridState.sort"
  (sortChange)="sortChange($event)"  class="positioned-grid">
  <kendo-grid-column title="Name" field="employee.name" [locked]="true" width="150">
    <ng-template kendoGridHeaderTemplate>
      Name
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content">
        <button type="button" class="button-link" [employeeSectionLink]="dataItem?.employee?.id">
        {{dataItem.employee?.name}}
      </button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Position" field="position.name" width="150">
    <ng-template kendoGridHeaderTemplate>
      Position
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content">
        {{dataItem.position?.name}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Shift Start" field="scheduleStart" width="150">
    <ng-template kendoGridHeaderTemplate>
      Shift Start
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content">
        <button type="button" class="button-link" scheduleEntryLink [employeeId]="dataItem.employee.id" [dateOn]="dataItem.scheduleStart">
        {{dataItem.scheduleStart | amDateFormat: appConfig.dateTimeFormatYearless}}
      </button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Shift End" field="scheduleEnd" width="150">
    <ng-template kendoGridHeaderTemplate>
      Shift End
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content">
        <button type="button" class="button-link" scheduleEntryLink [employeeId]="dataItem.employee.id" [dateOn]="dataItem.scheduleEnd">
        {{dataItem.scheduleEnd | amDateFormat: appConfig.dateTimeFormatYearless}}
      </button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Punch In" field="actualStart" width="150">
    <ng-template kendoGridHeaderTemplate>
      Punch In
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <button type="button" class="button-link" [dailyTimecardsLink]="dataItem?.employee?.id" [timecardDate]="dataItem?.actualStart">
        {{dataItem.actualStart | amDateFormat: appConfig.dateTimeFormatYearless}}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Punch Out" field="actualEnd" width="150">
    <ng-template kendoGridHeaderTemplate>
      Punch Out
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <button type="button" class="button-link" [dailyTimecardsLink]="dataItem?.employee?.id" [timecardDate]="dataItem?.actualStart">
        {{dataItem.actualEnd | amDateFormat: appConfig.dateTimeFormatYearless}}
      </button>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column  title="Phone"  field="employee.phoneNumber" width="150">
    <ng-template kendoGridHeaderTemplate>
      Phone
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content">
        {{dataItem.employee?.phoneNumber}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column  title="Mobile Phone"  field="employee.mobilePhoneNumber" width="150">
    <ng-template kendoGridHeaderTemplate>
      Mobile Phone
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="cell-content">
        {{dataItem.employee?.mobilePhoneNumber}}
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel fileName="ArrivalsDepartures.xlsx">
  </kendo-grid-excel>
  <kendo-grid-pdf fileName="ArrivalsDepartures.pdf" [allPages]="true">
    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
  </kendo-grid-pdf>
</kendo-grid>
