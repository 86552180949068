import { ElementRef, forwardRef } from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export var IPV4_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return Ipv4InputComponent; }),
    multi: true
};
var Ipv4InputComponent = /** @class */ (function () {
    function Ipv4InputComponent(elementRef) {
        this.placeholder = 'IP Address';
        this.innerValue = '';
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.hasFocus = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.inEdit = true;
        this.hasFocus = false;
    }
    Object.defineProperty(Ipv4InputComponent.prototype, "readonly", {
        set: function (ro) {
            this.inEdit = !ro;
        },
        enumerable: true,
        configurable: true
    });
    Ipv4InputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    Ipv4InputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
    };
    Ipv4InputComponent.prototype.writeValue = function (value) {
        this.innerValue = value;
    };
    Ipv4InputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    Ipv4InputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    Ipv4InputComponent.prototype.valueChanged = function (value) {
        this.onChangeCallback(value);
    };
    return Ipv4InputComponent;
}());
export { Ipv4InputComponent };
