import { Pipe, Injectable, PipeTransform, ElementRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
@Pipe({
  name: 'slxPbjRecNumber',
  pure: true
})
@Injectable()
export class SlxPbjRecNumberPipe extends DecimalPipe implements PipeTransform {
  public transform(val: number): string {
    const value = +val;
    let result: string = '';
    if (!isNaN(value)) {
      result = super.transform(value, '1.2-2');
    }
    return result;
  }
}
