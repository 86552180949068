import { BenefitDetailsLine } from './../../../models/benefit-details/benefit-details-line';
import { Component, Provider } from '@angular/core';
import { IDialog, DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../../app/common/models/index';
import { ModalService } from '../../../../../../app/common/services/modal/modal.service';
import { BenefitDetailsLineStandart } from '../../../../../app-modules/benefits/models';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../../../app/app.config';
import { dateTimeUtils } from '../../../../../common/utils/index';
import * as moment from 'moment';

@Component({
  selector: 'slx-benefit-details-renew-dialog',
  templateUrl: './benefit-details-renew-dialog.component.html',
  styleUrls: ['./benefit-details-renew-dialog.component.scss']
})
export class BenefitDetailsRenewDialogComponent implements IDialog {
  public dialogResult: boolean;
  public benefitDetailsLineNewEndDate: Date;
  public maxValidDate: Date = new Date(appConfig.maxCorrectDate);
  public newStartDate: Date;
  public appConfig: IApplicationConfig = appConfig;
  public canNotRenew: boolean;

  public static openDialog(benefitDetailsLine: BenefitDetailsLineStandart, modalService: ModalService, nextLineDetails: BenefitDetailsLine, callback?: (result: boolean) => void): BenefitDetailsRenewDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.dialog;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: BenefitDetailsLineStandart,
        useValue: benefitDetailsLine
      },
      {
        provide: BenefitDetailsLine,
        useValue: nextLineDetails
      }
    ];

    return modalService.globalAnchor
      .openDialog2(BenefitDetailsRenewDialogComponent, 'Renew Benefit Plan', dialogOptions, resolvedProviders, (result: boolean) => {
        callback(result);
      });
  }

  constructor(private dialogOptions: DialogOptions, private modalService: ModalService,
              private benefitDetailsLine: BenefitDetailsLineStandart, private nextLineDetails: BenefitDetailsLine) {
    this.modalService = modalService;
    if (this.nextLineDetails) {
      this.maxValidDate = moment(this.nextLineDetails.startDate).add('day', -1).endOf('day').toDate();
    }
    const lineEndDate = benefitDetailsLine ? benefitDetailsLine.line.endDate : new Date();

    const newStartDate = moment(lineEndDate).startOf('day').add(1, 'day').toDate();
    this.newStartDate = moment(newStartDate).isAfter(this.maxValidDate) ? this.maxValidDate : newStartDate;
    let defaultEndDate: Date;
    if(moment(lineEndDate).isBefore(this.maxValidDate)) {
      defaultEndDate = moment(this.newStartDate).startOf('year').add(1, 'year').add(-1, 'minute').toDate();
    } else {
      defaultEndDate = lineEndDate;
    }
    this.benefitDetailsLineNewEndDate = moment(defaultEndDate).isAfter(this.maxValidDate) ? this.maxValidDate : defaultEndDate;

    this.canNotRenew = moment(this.newStartDate).isSameOrAfter(this.maxValidDate) || moment(benefitDetailsLine.line.endDate).endOf('day').isSameOrAfter(this.maxValidDate);
  }

  public save(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

}
