import * as tslib_1 from "tslib";
import { ElementRef, QueryList, OnDestroy, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { appConfig } from '../../../app.config';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/observable/timer';
import { select } from '@angular-redux/store';
import { unsubscribe } from '../../../core/decorators/index';
import { ToolbarBaseService } from '../../../core/services/index';
import { ToolbarSectionTemplateDirective } from '../../directives/toolbar/index';
import { ToolbarSectionTypes } from '../../models/index';
var ToolbarComponent = /** @class */ (function () {
    function ToolbarComponent(elementRef, toolbarService, changeDetector) {
        var _this = this;
        this.elementRef = elementRef;
        this.toolbarService = toolbarService;
        this.changeDetector = changeDetector;
        var subscription = null;
        var period = 300;
        var animationDuration = 1000;
        this.nativeElement = elementRef.nativeElement;
        this.recalc$ = new Subject();
        this.debouncedSubscription = this.recalc$.debounceTime(period).subscribe(function () {
            _this.debouncedRecalc();
        });
        this.leftSidebarSubscripion = this.leftSidebarState$.subscribe(function (value) {
            if (subscription)
                subscription.unsubscribe();
            subscription = Observable.timer(period, period)
                .subscribe(function (val) {
                _this.recalc();
                if (val === +(animationDuration / period).toFixed(0)) {
                    subscription.unsubscribe();
                    subscription = null;
                }
            });
        });
        if (this.toolbarService) {
            this.externalRecalcSubscripion = this.toolbarService.onRecalcToolbar$.subscribe(function () {
                _this.recalc();
            });
        }
        this.counts = this.createCounts();
        this.visibleSections = [];
        this.collapsedSections = [];
        this.rightSections = [];
    }
    ToolbarComponent.prototype.isMobile = function () {
        return (screen.width <= appConfig.mobileMaxWidth);
    };
    ToolbarComponent.prototype.isCollapsedSection = function (sectionType) {
        return sectionType === ToolbarSectionTypes.COLLAPSED_BY_DEFAULT || sectionType === ToolbarSectionTypes.COLLAPSED_BY_RESIZE;
    };
    ToolbarComponent.prototype.ngAfterContentInit = function () {
        this.recalc();
    };
    ToolbarComponent.prototype.showCollapsed = function () {
        this.isExpanded = true;
    };
    ToolbarComponent.prototype.hideCollapsed = function () {
        this.isExpanded = false;
    };
    ToolbarComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    ToolbarComponent.prototype.onResize = function (event) {
        this.recalc();
    };
    ToolbarComponent.prototype.recalc = function () {
        this.recalc$.next();
    };
    ToolbarComponent.prototype.countAsRight = function (item) {
        return item.alignMode === 'right'
            || (!this.isMobile() && item.alignMode === 'rightOnlyDesktop');
    };
    ToolbarComponent.prototype.expandButtonWidth = function (willBeCollapsed) {
        if (this.collapsedSections.length > 0 || willBeCollapsed) {
            return 100;
        }
        return 0;
    };
    ToolbarComponent.prototype.debouncedRecalc = function () {
        var _this = this;
        if (!this.visibleSections || !this.collapsedSections || !this.rightSections) {
            return;
        }
        var counts = this.createCounts();
        this.visibleSections.length = this.collapsedSections.length = this.rightSections.length = 0;
        var width = this.contentChildren.reduce(function (accumulator, item) {
            if (_this.countAsRight(item))
                accumulator += +item.width || 0;
            return accumulator;
        }, 0);
        var projectedWidth = this.contentChildren.reduce(function (accumulator, item) {
            accumulator += +item.width || 0;
            return accumulator;
        }, 0);
        var remainsWidth = this.nativeElement.offsetWidth - projectedWidth - this.expandButtonWidth();
        var directives = this.contentChildren.toArray();
        var orderedDirectives = _.orderBy(directives, function (item) {
            if (_this.countAsRight(item)) {
                return 0;
            }
            if (item.alignMode === 'left') {
                return 1;
            }
            if (item.alignMode === 'leftWithPriorityIfNotEnoughPlace') {
                return remainsWidth > 0 ? 1 : 0.5;
            }
            if (item.alignMode === 'rightIfEnoughPlace') {
                return 2;
            }
            if (item.alignMode === 'rightIfNothingCollapsed') {
                return 3;
            }
            return 1;
        });
        var rightIfNothingCollapsedItems = [];
        var rightIfNothingCollapsedItemsWidth = 0;
        _.forEach(orderedDirectives, function (item) {
            if (_this.countAsRight(item)) {
                item.sectionType = ToolbarSectionTypes.RIGHT;
                counts[item.sectionType]++;
                _this.rightSections.push(item);
            }
            else if (!item.collapsed && item.alignMode === 'rightIfNothingCollapsed') {
                rightIfNothingCollapsedItemsWidth += item.width;
                rightIfNothingCollapsedItems.push(item);
            }
            else {
                if (item.collapsed) {
                    item.sectionType = ToolbarSectionTypes.COLLAPSED_BY_DEFAULT;
                    counts[item.sectionType]++;
                    _this.collapsedSections.push(item);
                }
                else {
                    width += item.width;
                    if (width < (_this.nativeElement.offsetWidth - _this.expandButtonWidth(true))) {
                        item.sectionType = ToolbarSectionTypes.VISIBLE;
                        counts[item.sectionType]++;
                        if (item.alignMode === 'leftWithPriorityIfNotEnoughPlace') {
                            _this.visibleSections.push(item);
                        }
                        if (item.alignMode === 'left') {
                            _this.visibleSections.push(item);
                        }
                        if (item.alignMode === 'rightIfEnoughPlace') {
                            _this.rightSections.push(item);
                        }
                    }
                    else {
                        item.sectionType = ToolbarSectionTypes.COLLAPSED_BY_RESIZE;
                        counts[item.sectionType]++;
                        _this.collapsedSections.push(item);
                    }
                }
            }
        });
        if (this.collapsedSections.length === 0 && rightIfNothingCollapsedItemsWidth < this.nativeElement.offsetWidth - width - this.expandButtonWidth()) {
            _.forEach(rightIfNothingCollapsedItems, function (item) {
                item.sectionType = ToolbarSectionTypes.RIGHT;
                counts[item.sectionType]++;
                _this.rightSections.push(item);
            });
        }
        else {
            _.forEach(rightIfNothingCollapsedItems, function (item) {
                width += item.width;
                if (width < (_this.nativeElement.offsetWidth - _this.expandButtonWidth(true))) {
                    item.sectionType = ToolbarSectionTypes.VISIBLE;
                    counts[item.sectionType]++;
                    _this.visibleSections.push(item);
                }
                else {
                    item.sectionType = ToolbarSectionTypes.COLLAPSED_BY_RESIZE;
                    counts[item.sectionType]++;
                    _this.collapsedSections.push(item);
                }
            });
        }
        this.counts = counts;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ToolbarComponent.prototype.createCounts = function () {
        var _a;
        return _a = {},
            _a[ToolbarSectionTypes.VISIBLE] = 0,
            _a[ToolbarSectionTypes.COLLAPSED_BY_DEFAULT] = 0,
            _a[ToolbarSectionTypes.COLLAPSED_BY_RESIZE] = 0,
            _a[ToolbarSectionTypes.RIGHT] = 0,
            _a;
    };
    Object.defineProperty(ToolbarComponent.prototype, "setLeftWidth", {
        get: function () {
            return this.alignExpandButtonRight && (this.collapsedSections && this.collapsedSections.length > 0) && this.isDuaComponent ? 'dua-responsive-left-width' : 'left-full-width';
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        select(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], ToolbarComponent.prototype, "leftSidebarState$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ToolbarComponent.prototype, "debouncedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ToolbarComponent.prototype, "leftSidebarSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ToolbarComponent.prototype, "externalRecalcSubscripion", void 0);
    return ToolbarComponent;
}());
export { ToolbarComponent };
