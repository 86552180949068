<div class="slider-container">

  <a [ngClass]="{disabled : !prevEnabled}" (click)="prev()"> <i class="fa fa-angle-left fa-2x range-link" aria-hidden="true"></i></a>

    <div class="dropdown">

      <button  [ngClass]="{disabled : !enableDropdown}" class="btn btn-default dropdown-toggle dropdown-button" type="button"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        {{currentItem?.startDate | amDateFormat: appConfig.dateFormat}} - {{currentItem?.endDate | amDateFormat: appConfig.dateFormat}}
      </button>

      <div class="dropdown-menu scrollable-menu" aria-labelledby="group-activities">
        <span class="title"><strong>Select Item</strong></span>

        <div *ngFor="let item of itemsArray">
          <input type="radio" [value]="item" [checked]="item === currentItem" [(ngModel)]="currentItem" (ngModelChange)="onItemSelected(item)">
          {{item.startDate | amDateFormat: appConfig.dateFormat}} - {{item.endDate | amDateFormat: appConfig.dateFormat}}<br>
        </div>
      </div>

    </div>

  <a [ngClass]="{disabled : !nextEnabled}" (click)="next()"> <i class="fa fa-angle-right fa-2x range-link" aria-hidden="true"></i></a>

</div>
