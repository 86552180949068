import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';

import {
  ArrivalDeparturesContainer, AttendanceTypeDefinition, ArrivalsDeparturesAnalyticsDefinition, AttendanceType, ArrivalsDeparturesAnalytics, Departure
} from '../../../models/index';

import { ArrivalDeparturesManagementService } from '../../../services/index';
import * as _ from 'lodash';
@Component({
  moduleId: module.id,
  selector: 'slx-departures-widget',
  templateUrl: 'departures-widget.component.html',
  styleUrls: ['departures-widget.component.scss']
})
export class DeparturesWidgetComponent implements OnInit, OnDestroy {
  @Input()
  public dateOn: Date;

  public appConfig: IApplicationConfig;
  public arrivalDeparturesManagementService: ArrivalDeparturesManagementService;
  public isLoading: boolean;
  public arrivalDeparturesContainer: ArrivalDeparturesContainer;
  public otSum: number = 0;
  public recordsSum: number = 0;
  public collapsed: boolean;

  private activatedRoute: ActivatedRoute;
  private router: Router;

  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;


  constructor(activatedRoute: ActivatedRoute, router: Router, arrivalDeparturesManagementService: ArrivalDeparturesManagementService) {
    Assert.isNotNull(arrivalDeparturesManagementService, 'arrivalDeparturesManagementService');
    this.arrivalDeparturesManagementService = arrivalDeparturesManagementService;
    this.activatedRoute = activatedRoute;
    this.router = router;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.loadStatusSubscription = this.arrivalDeparturesManagementService.onLoadStatus$.subscribe((val: boolean) => { this.isLoading = val; });

    this.loadedSubscription = this.arrivalDeparturesManagementService.onLoaded$.subscribe(
      (container: ArrivalDeparturesContainer) => {
        this.arrivalDeparturesContainer = container;
        this.recordsSum = container.departures ? container.departures.length : 0;
        this.otSum = _.sumBy(this.arrivalDeparturesContainer.departures, (item: Departure) => item.overtime);
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onOutFieldClickHandler(departure: Departure): void {
    if (departure.departureTime) {
      this.navigateToArrivalsDeparturesDetails(AttendanceType.departures, ArrivalsDeparturesAnalytics.out, this.dateOn, departure.departureTime);
    } else if (!departure.departureTime && departure.scheduled === 0) {
      this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.unscheduled, this.dateOn);
    }
  }

  public onScheduledFieldClickHandler(departure: Departure): void {
    this.navigateToArrivalsDeparturesDetails(AttendanceType.departures, ArrivalsDeparturesAnalytics.scheduled, this.dateOn, departure.departureTime);
  }

  public onOvertimeFieldClickHandler(departure: Departure): void {
    this.navigateToArrivalsDeparturesDetails(AttendanceType.departures, ArrivalsDeparturesAnalytics.overtime, this.dateOn, departure.departureTime);
  }

  public onDifferenceFieldClickHandler(departure: Departure): void {
    if (departure.departureTime) {
      this.navigateToArrivalsDeparturesDetails(AttendanceType.departures, ArrivalsDeparturesAnalytics.difference, this.dateOn, departure.departureTime);
    } else if (!departure.departureTime && departure.scheduled === 0) {
      this.navigateToArrivalsDeparturesDetails(AttendanceType.arrivals, ArrivalsDeparturesAnalytics.unscheduled, this.dateOn);
    }
  }

  public onRowSelectedHandler(departures: Departure[]): void {
    const start = _.first(departures);
    const end = _.last(departures);
    if (start && end) {
      this.arrivalDeparturesManagementService.onDeparturesRangeChanged({ startDate: start.departureTime, endDate: end.departureTime });
    } else {
      this.arrivalDeparturesManagementService.onDeparturesRangeChanged(null);
    }
  }

  public toggleState(): void {
    this.collapsed = !this.collapsed;
  }

  private navigateToArrivalsDeparturesDetails(attendanceTypeDefinition: AttendanceTypeDefinition, arrivalsDeparturesAnalyticsDefinition: ArrivalsDeparturesAnalyticsDefinition, dateOn: Date, time: Date = null): void {
    let params: Params = undefined;
    if (time) {
      params = {
        date: moment(dateOn).format(appConfig.linkDateFormat),
        time: moment(time).format(appConfig.linkDateTimeFormat),
        attendance_type: attendanceTypeDefinition,
        analytics: arrivalsDeparturesAnalyticsDefinition
      };
    } else {
      params = {
        date: moment(dateOn).format(appConfig.linkDateFormat),
        attendance_type: attendanceTypeDefinition,
        analytics: arrivalsDeparturesAnalyticsDefinition
      };
    }
    this.router.navigate(['arrivals_departures_details'], { relativeTo: this.activatedRoute.pathFromRoot[2], queryParams: params });
  }
}
