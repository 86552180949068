import * as tslib_1 from "tslib";
import { OnInit, OnChanges, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { PbjExportApiService } from '../../../services/index';
import { ServerFilterService } from '../../../../../core/services/index';
import { PbjDetailsBasicGridComponent } from '../pbj-details-basic-grid/pbj-details-basic-grid.component';
import { StateManagementService } from '../../../../../common/services/index';
var PbjDetailsErrorsDataGridComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PbjDetailsErrorsDataGridComponent, _super);
    function PbjDetailsErrorsDataGridComponent(apiService, serverFilterService, changeDetector, stateManagement) {
        var _this = _super.call(this, apiService, serverFilterService, changeDetector, stateManagement) || this;
        _this.controlKey = 'PbjExportErrorsDataComponent';
        return _this;
    }
    Object.defineProperty(PbjDetailsErrorsDataGridComponent.prototype, "exportFilename", {
        get: function () {
            return "export-" + this.exportId + "-errors-data";
        },
        enumerable: true,
        configurable: true
    });
    PbjDetailsErrorsDataGridComponent.prototype.refreshData = function () {
        var _this = this;
        this.onLoadStatus(true);
        this.apiService.getPBJExportLogError(this.serverFilterService.createQuery(this.pagingData, this.gridState.state.sort), this.exportId)
            .then(function (val) {
            _this.records = val;
            _this.onLoadStatus(false);
            _this.showChanges();
        }).catch(function (reason) {
            _this.onLoadStatus(false);
        });
    };
    PbjDetailsErrorsDataGridComponent.prototype.loadAndExport = function (type) {
        var _this = this;
        this.onLoadStatus(true);
        var zeroPaging = { skip: 0 };
        this.apiService.getPBJExportLogError(this.serverFilterService.createQuery(zeroPaging, this.gridState.state.sort), this.exportId)
            .then(function (val) {
            _this.processExportData(type, val);
            _this.onLoadStatus(false);
        }).catch(function (reason) {
            _this.onLoadStatus(false);
            _this.exportRecords = null;
        });
    };
    PbjDetailsErrorsDataGridComponent.prototype.applyDefaultSort = function () {
        this.gridState.state.sort = [{ field: 'errorId', dir: 'asc' }];
    };
    PbjDetailsErrorsDataGridComponent.prototype.updateColumnsConfig = function () {
        var _a;
        _super.prototype.updateColumnsConfig.call(this);
        var specificColumns = [
            { field: 'errorId', displayTitle: 'Error/Warning Id', index: 1, width: 150 },
            { field: 'section', displayTitle: 'Section', index: 2, width: 150 },
            { field: 'field', displayTitle: 'Field', index: 3, width: 150 },
            { field: 'errorMessage', displayTitle: 'Error Message', index: 4, width: 150 },
            { field: 'employeeName', displayTitle: 'Name', index: 5, width: 150 },
            { field: 'employeePayrollNumber', displayTitle: 'Payroll No', index: 6, width: 150 },
            { field: 'referenceDate', displayTitle: 'Ref Date', index: 7, width: 150 },
            { field: 'entryType', displayTitle: 'Entry Type', index: 8, width: 150 }
        ];
        (_a = this.xlsExportColumns).push.apply(_a, specificColumns);
    };
    return PbjDetailsErrorsDataGridComponent;
}(PbjDetailsBasicGridComponent));
export { PbjDetailsErrorsDataGridComponent };
