import * as _ from 'lodash';
import * as moment from 'moment';
import { Assert } from '../../../../framework/index';
import { EmployeeSectionsTemporalModel } from '../../models/index';
import { appConfig } from '../../../../app.config';
var EmployeeSectionsBasicComponent = /** @class */ (function () {
    function EmployeeSectionsBasicComponent(decorator, ngZone) {
        Assert.isNotNull(decorator, 'decorator');
        this.appConfig = appConfig;
        this.ngZone = ngZone;
        this.decorator = decorator;
        this.decorator.registerSubsectionComponent(this);
        this.state = {
            isEditMode: false,
            isLoaded: false
        };
        this.isDestroyed = false;
    }
    Object.defineProperty(EmployeeSectionsBasicComponent.prototype, "employeeShortInfo", {
        get: function () {
            return this.decorator.employeeShortInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBasicComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBasicComponent.prototype, "hasActiveState", {
        get: function () {
            return this.decorator.hasActiveState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBasicComponent.prototype, "hasAccessToEdit", {
        get: function () {
            return this.decorator.hasAccessToEdit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBasicComponent.prototype, "hasAccessToAdd", {
        get: function () {
            return this.decorator.hasAccessToAdd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBasicComponent.prototype, "hasAccessToDelete", {
        get: function () {
            return this.decorator.hasAccessToDelete;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsBasicComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onStableSubscription = this.ngZone.onStable.subscribe(function (arg) {
            _this.checkIsTemporalDirty();
            if (!_this.isDirty && _this.form && _this.form.dirty && _this.state.isEditMode) {
                _this.setDirty();
            }
        });
    };
    EmployeeSectionsBasicComponent.prototype.ngOnDestroy = function () {
        this.isDestroyed = true;
        if (this.onStableSubscription) {
            this.onStableSubscription.unsubscribe();
        }
    };
    EmployeeSectionsBasicComponent.prototype.load = function (force) {
        if (!force && this.state.isLoaded)
            return;
        this.startProgress();
        this.loadSubsection();
        this.clearDirty();
    };
    EmployeeSectionsBasicComponent.prototype.startProgress = function () {
        this.decorator.startProgress();
    };
    EmployeeSectionsBasicComponent.prototype.stopProgress = function () {
        this.decorator.stopProgress();
    };
    EmployeeSectionsBasicComponent.prototype.setEditState = function (editState) {
        this.state.isEditMode = editState;
        if (!editState) {
            this.clearDirty();
        }
    };
    EmployeeSectionsBasicComponent.prototype.Save = function (effectiveDate) {
        this.doSave(effectiveDate);
    };
    EmployeeSectionsBasicComponent.prototype.canSave = function () {
        return Promise.resolve(true);
    };
    EmployeeSectionsBasicComponent.prototype.checkIsTemporalDirty = function () {
        if (!this.state.isEditMode)
            return;
        this.employeeSectionsTemporalModel = this.checkTemporalDirty();
        this.decorator.setTemporalModel(this.employeeSectionsTemporalModel);
    };
    EmployeeSectionsBasicComponent.prototype.metaFieldsTemporalDirtyArrayChecker = function (models) {
        var _this = this;
        var temporalModel;
        _.forEach(models, function (model) {
            var tm = _this.metaFieldsTemporalDirtyChecker(model);
            temporalModel = _this.metaConcatTemporalModels(temporalModel, tm);
        });
        return temporalModel;
    };
    EmployeeSectionsBasicComponent.prototype.metaFieldsTemporalDirtyChecker = function (model) {
        var _this = this;
        var temporalModel = new EmployeeSectionsTemporalModel();
        if (!model)
            return temporalModel;
        if ((model.isOnlyPayRate && model.isNotOnlyPayRate) && (model.isOnlyPayRate() || model.isNotOnlyPayRate())) {
            temporalModel.isOnlyPayRateDirty = model.isOnlyPayRate();
            temporalModel.isNotOnlyPayRateDirty = model.isNotOnlyPayRate();
            temporalModel.payrollEffectiveDateSetting = model.effectiveDateSetting;
        }
        _.forOwn(model, function (value, key) {
            if (!_.has(value, 'isTemporal') || value.isTemporal !== true)
                return;
            var fieldData = value;
            _this.metaFieldTemporalDirtyChecker(temporalModel, fieldData);
        });
        return temporalModel;
    };
    EmployeeSectionsBasicComponent.prototype.metaFieldTemporalDirtyChecker = function (temporalModel, fieldData) {
        temporalModel.isTemporalDirty = temporalModel.isTemporalDirty || fieldData.isDirty;
        if (!temporalModel.minEffectiveData) {
            temporalModel.minEffectiveData = fieldData.temporalMinDate;
        }
        else {
            temporalModel.minEffectiveData = moment.max(moment(temporalModel.minEffectiveData), moment(fieldData.temporalMinDate)).toDate();
        }
    };
    EmployeeSectionsBasicComponent.prototype.metaConcatTemporalModels = function (temporalModel1, temporalModel2) {
        var temporalModel;
        if (!temporalModel1 && !temporalModel2) {
            return new EmployeeSectionsTemporalModel();
        }
        if (!temporalModel1) {
            return temporalModel2;
        }
        if (!temporalModel2) {
            return temporalModel1;
        }
        temporalModel = temporalModel1;
        temporalModel.isTemporalDirty = temporalModel2.isTemporalDirty || temporalModel.isTemporalDirty;
        if (!temporalModel.minEffectiveData) {
            temporalModel.minEffectiveData = temporalModel2.minEffectiveData;
        }
        else {
            temporalModel.minEffectiveData = moment.max(moment(temporalModel.minEffectiveData), moment(temporalModel2.minEffectiveData)).toDate();
        }
        return temporalModel;
    };
    EmployeeSectionsBasicComponent.prototype.onActionComplete = function (reload) {
        this.load(reload);
        this.clearDirty();
    };
    EmployeeSectionsBasicComponent.prototype.onActionError = function (reason) {
        //TODO error handler (show or log message)
        this.stopProgress();
    };
    EmployeeSectionsBasicComponent.prototype.checkTemporalDirty = function () {
        return null;
    };
    EmployeeSectionsBasicComponent.prototype.doSave = function (effectiveDate) {
        //virtual
    };
    EmployeeSectionsBasicComponent.prototype.showCurrentPayCycleWarning = function () {
        //virtual
    };
    EmployeeSectionsBasicComponent.prototype.disabledDate = function (date) {
        //virtual
    };
    EmployeeSectionsBasicComponent.prototype.isValidEffectiveDate = function (date) {
    };
    EmployeeSectionsBasicComponent.prototype.resetCurrentPayCycleWarning = function () { };
    EmployeeSectionsBasicComponent.prototype.setDirty = function () {
        this.isDirty = true;
        this.decorator.setChanges();
    };
    EmployeeSectionsBasicComponent.prototype.clearDirty = function () {
        if (this.form) {
            this.form.markAsPristine();
        }
        this.isDirty = false;
        this.decorator.clearChanges();
    };
    return EmployeeSectionsBasicComponent;
}());
export { EmployeeSectionsBasicComponent };
