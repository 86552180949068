import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { appConfig } from '../../../../app.config';
import { ShiftReplacementSms } from '../../../models/index';
import { ShiftReplacementManagementService } from '../../../services/index';
import { Observable } from 'rxjs/Rx';
import { NotificationsApiService } from '../../../../portal/services/notifications/notifications-api.service';
var ShiftReplacementSmsComponent = /** @class */ (function () {
    function ShiftReplacementSmsComponent(shiftManagementService, changeDetector, notificationApiService) {
        this.shiftManagementService = shiftManagementService;
        this.changeDetector = changeDetector;
        this.notificationApiService = notificationApiService;
        this.maxSmsCharactersLength = 255;
        this.appConfig = appConfig;
        this.messageChange$ = new Subject();
    }
    Object.defineProperty(ShiftReplacementSmsComponent.prototype, "initialMessage", {
        set: function (msg) {
            if (_.isString(msg)) {
                this.message = msg;
            }
        },
        enumerable: true,
        configurable: true
    });
    ShiftReplacementSmsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.checkedEmployeesSubscription = this.shiftManagementService
            .subscribeToCheckedEmployees(function (selectedEmployees) {
            _this.recipients = selectedEmployees;
            _this.changeDetector.detectChanges();
            _this.changeAvailabilityToSendSms();
        });
        this.messageChangeSubscription = this.messageChange$
            .debounceTime(400)
            .subscribe(function () {
            _this.changeAvailabilityToSendSms();
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.checkMessageCenterAccess();
            }
        });
    };
    ShiftReplacementSmsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ShiftReplacementSmsComponent.prototype.onMessageChange = function () {
        this.messageChange$.next();
    };
    ShiftReplacementSmsComponent.prototype.onRemoveRecipient = function (emp) {
        var index = _.findIndex(this.recipients, function (recipient) { return recipient.employee.id === emp.employee.id; });
        if (index !== -1) {
            this.recipients.splice(index, 1);
            this.changeAvailabilityToSendSms();
            this.shiftManagementService.changeCheckedEmployees(this.recipients);
        }
    };
    ShiftReplacementSmsComponent.prototype.changeAvailabilityToSendSms = function () {
        var smsData = new ShiftReplacementSms();
        if (this.message.length > 0 && this.recipients.length > 0) {
            smsData.message = this.message;
            smsData.recipients = this.recipients;
        }
        this.shiftManagementService.changeAvailabilityToSendSms(smsData);
    };
    ShiftReplacementSmsComponent.prototype.checkMessageCenterAccess = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var access;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.notificationApiService.getIconAccessCheck(this.orgLevel.id)];
                    case 1:
                        access = _a.sent();
                        if (access.isMessageCenterEnabled) {
                            this.maxSmsCharactersLength = 320;
                            this.changeDetector.detectChanges();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSmsComponent.prototype, "checkedEmployeesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSmsComponent.prototype, "messageChangeSubscription", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ShiftReplacementSmsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementSmsComponent.prototype, "orgLevelSubscription", void 0);
    return ShiftReplacementSmsComponent;
}());
export { ShiftReplacementSmsComponent };
