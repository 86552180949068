import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, Provider } from '@angular/core';
import * as moment from 'moment';
import { ScheduleRotationsManagementService } from '../../services/scheduled-rotation-template/schedule-rotations-management.service';
import { DeviceDetectorService, ModalService } from '../../../common/services/index';
import { DateRange } from '../../../core/models/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { OpenPositionsControlApiService } from '../../services';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { OpenConfigOrgLevelType, OpenShiftPositions, OpenShiftPostionsOrgDetails } from '../../models';
import { DialogOptions } from '../../../common';
import { PositionGroupSlideOutComponent } from './position-group-slide-out/position-group-slide-out.component';
var OpenPostionControlComponent = /** @class */ (function () {
    function OpenPostionControlComponent(manService, devDetector, modalService, openPositionsControlApiService) {
        this.manService = manService;
        this.devDetector = devDetector;
        this.modalService = modalService;
        this.openPositionsControlApiService = openPositionsControlApiService;
        this.openShiftPositions = new OpenShiftPositions();
        this.selectedDate = moment().toDate();
        this.dateFrom = moment().toDate();
        this.startDate = moment().startOf('week').toDate();
        this.actualEndDate = moment(this.startDate).add(4, 'week').toDate();
        this.endDate = moment(this.actualEndDate).subtract(1, 'day').toDate();
        this.dateRange = new DateRange(null, null);
        this.isLoading = false;
        this.manService.getScheduleCycles();
    }
    Object.defineProperty(OpenPostionControlComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    OpenPostionControlComponent.prototype.getScreenSize = function (event) {
        this.screenWidth = window.innerWidth;
    };
    OpenPostionControlComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.screenWidth = window.innerWidth;
        this.orglevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
            if (_.isDate(_this.startDate) && _.isDate(_this.endDate)) {
                _this.loadOpenPositions();
            }
        });
    };
    OpenPostionControlComponent.prototype.openPositionGroupSlideOut = function (orgName, departmentDetails) {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = this.isMobile ? 370 : 978;
        dialogOptions.showCloseButton = true;
        dialogOptions.className = 'slx-opc-dialog-custom';
        dialogOptions.isMobile = this.isMobile;
        dialogOptions.headerClassName = dialogOptions.isMobile ? 'slx-opc-dialog-header' : '';
        var deptDetails = new OpenConfigOrgLevelType();
        deptDetails.orgLevelId = departmentDetails.orgLevelId;
        deptDetails.orgLevelName = departmentDetails.departmentName;
        deptDetails.orgLevelType = 'Department';
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: ModalService,
                useValue: this.modalService
            },
            {
                provide: OpenConfigOrgLevelType,
                useValue: deptDetails
            }
        ];
        var dialog = this.modalService.globalAnchor.openDialog(PositionGroupSlideOutComponent, orgName + ': ' + departmentDetails.departmentName, dialogOptions, resolvedProviders, function (result, uniqueId) {
        });
        dialog.orgLevelId = departmentDetails.orgLevelId;
        dialog.startDate = this.startDate;
        dialog.endDate = this.endDate;
        if (dialog.initialized) {
            dialog.LoadData();
        }
    };
    OpenPostionControlComponent.prototype.ngOnDestroy = function () { };
    OpenPostionControlComponent.prototype.onDatesChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadOpenPositions();
    };
    Object.defineProperty(OpenPostionControlComponent.prototype, "dateRangeWidth", {
        get: function () {
            return this.isDesktop ? 325 : 246;
        },
        enumerable: true,
        configurable: true
    });
    OpenPostionControlComponent.prototype.loadOpenPositions = function () {
        var _this = this;
        this.isLoading = true;
        this.openPositionsControlApiService.getOpenPositions(this.currentOrgLevel.id, this.startDate, this.endDate)
            .then(function (openShiftPositions) {
            _this.openShiftPositions = openShiftPositions;
            _this.isLoading = false;
        });
    };
    Object.defineProperty(OpenPostionControlComponent.prototype, "isMobile", {
        get: function () {
            return this.screenWidth < 768 ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], OpenPostionControlComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OpenPostionControlComponent.prototype, "orglevelSubscription", void 0);
    return OpenPostionControlComponent;
}());
export { OpenPostionControlComponent };
