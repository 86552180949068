import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
import { StringUtils } from '../../../../framework/string-utils/string-utils';
var EmployeeSectionsPositions = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPositions, _super);
    function EmployeeSectionsPositions() {
        var _this = _super.call(this) || this;
        _this.positions = [];
        return _this;
    }
    return EmployeeSectionsPositions;
}(EmployeeSectionsBase));
export { EmployeeSectionsPositions };
var EmployeeSectionsPosition = /** @class */ (function () {
    function EmployeeSectionsPosition() {
    }
    Object.defineProperty(EmployeeSectionsPosition.prototype, "orgNavBreadcrumb", {
        get: function () {
            var orgName = this.organization.fieldValue ? this.organization.fieldValue.name : '';
            var depName = this.department.fieldValue ? this.department.fieldValue.name : '';
            var bc = StringUtils.format('{0} > {1}', orgName, depName);
            return bc;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeSectionsPosition;
}());
export { EmployeeSectionsPosition };
