import { Position, Department, EmpType } from '../../../../organization/models/index';

export interface IBenefitEnrolledEmployeePreview {
    employeeId: number;
    employeeName: string;
    position: Position;
    department: Department;
    employeeContributionAmount: number;
    employerContributionAmount: number;
    coverage: number;
    type?: EmpType;
    benefitTierOptionId?: number;
    optionRateCode?: string;
}



export class BenefitEnrolledEmployeePreview {
    public employeeId: number;
    public employeeName: string;
    public position: Position;
    public department: Department;
    public employeeContributionAmount: number;
    public employerContributionAmount: number;
    public coverage: number;
    public type?: EmpType;
    public benefitTierOptionId?: number;
    public optionRateCode?: string;
}
