/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./weather-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./weather-icon.component";
var styles_WeatherIconComponent = [i0.styles];
var RenderType_WeatherIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WeatherIconComponent, data: {} });
export { RenderType_WeatherIconComponent as RenderType_WeatherIconComponent };
function View_WeatherIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconClass; _ck(_v, 0, 0, currVal_0); }); }
function View_WeatherIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconImageUrl; _ck(_v, 0, 0, currVal_0); }); }
export function View_WeatherIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeatherIconComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WeatherIconComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconClass; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.iconImageUrl && !_co.iconClass); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_WeatherIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "weather-icon", [], null, null, null, View_WeatherIconComponent_0, RenderType_WeatherIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.WeatherIconComponent, [], null, null)], null, null); }
var WeatherIconComponentNgFactory = i1.ɵccf("weather-icon", i3.WeatherIconComponent, View_WeatherIconComponent_Host_0, { iconClass: "iconClass", iconImageUrl: "iconImageUrl", title: "title" }, {}, []);
export { WeatherIconComponentNgFactory as WeatherIconComponentNgFactory };
