import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { mutableSelect, unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../common/services/index';
import { OrgLevel } from '../../../../../state-model/models';

import { BenefitListManagementService } from './../../../services/benefit-console/benefit-list-management.service';
import { BenefitDetailsConfig, BenefitDetailsGroup } from './../../../models/index';

import { benefitsConfig } from './../../../benefits.config';

@Component({
  selector: 'benefit-plan-types',
  templateUrl: 'benefit-plan-types.component.html',
  styleUrls: ['benefit-plan-types.component.scss']
})
export class BenefitPlanTypesComponent implements OnInit, OnDestroy {
  public isLoading: boolean = false;
  public creatPlanAccess: boolean = true;
  public showExpired: boolean = false;
  public planTypes: BenefitDetailsGroup[] = [];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private benefitGroupsByType: StringMap<{ type: string, icon: string }> = {};
  private readonly groupConfig = benefitsConfig.benefitGroupsConfig;

  constructor(
    private manService: BenefitListManagementService
  ) {
    this.benefitGroupsByType = _.keyBy(this.groupConfig, (c) => c.type);
  }

  public ngOnInit() {
    this.subscriptions.onLoaded = this.manService
    .subscribeToPlanHeaders((v: BenefitDetailsConfig) => {
      if(this.planTypes.length === 0) {
        this.planTypes = v.groups;
        this.creatPlanAccess = v.canAdd;
      }
    });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public getIcon(typeName: string): string {
    const group = this.benefitGroupsByType[typeName];
    return _.isObjectLike(group) && _.size(group.type) > 0 ? group.icon : '';
  }

}
