<div [ngClass]="{'inactive': state.inactive}">

    <ng-container [ngSwitch]="state.editing">

        <ng-content *ngSwitchCase="false"></ng-content>

        <ng-container *ngSwitchCase="true">

            <ng-template *ngIf="editorTemplateRef" [ngTemplateOutlet]="editorTemplateRef" [ngTemplateOutletContext]="{ $implicit: item, item: item}"></ng-template>

            <div *ngIf="!editorTemplateRef">
                <slx-editable-list-editor [item]="item" slx-list-actions></slx-editable-list-editor>
            </div>

        </ng-container>

    </ng-container>

</div>
