import { AcaExportConfiguration } from './aca-export-configuration';

export interface IAcaExportState {
  acaExportConfigurationList: AcaExportConfiguration[];
}

export const initialAcaExportState: IAcaExportState = {
  acaExportConfigurationList: []
};

