import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';

import { CalendarDataService } from '../../../core/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { LookupMapService, EmployeeDefinitionsMapService, ScheduleDefinitionsMapService } from '../../../organization/services/index';

import { WeeklyData } from '../../../core/models/index';
import {
  EmpType, Department, Position,
  IEmployeeScheduleDefinition, EmployeeScheduleDefinition,
  ScheduleEntryDefinition, IScheduleEntryDefinition,
  ISaveIndividualScheduleRequest, IModifyIndividualScheduleRequest
} from '../../../organization/models/index';
import {
  IIndSchOrgLevelEmployee, IndSchOrgLevelEmployee,
  IIndSchOrgLevelEmployees, IndSchOrgLevelEmployees,
  IndSchEntryWrapper, IndSchDefWrapper
} from '../../models/index';

@Injectable()
export class IndividualScheduleMapService {
  private lookupMapService: LookupMapService;
  private employeeDefinitionsMapService: EmployeeDefinitionsMapService;
  private scheduleDefinitionsMapService: ScheduleDefinitionsMapService;
  private calendarDataService: CalendarDataService;

  constructor(lookupMapService: LookupMapService,
    employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    scheduleDefinitionsMapService: ScheduleDefinitionsMapService,
    calendarDataService: CalendarDataService) {
    this.lookupMapService = lookupMapService;
    this.employeeDefinitionsMapService = employeeDefinitionsMapService;
    this.scheduleDefinitionsMapService = scheduleDefinitionsMapService;
    this.calendarDataService = calendarDataService;
  }

  public mapOrgLevelEmployee(dto: IIndSchOrgLevelEmployee): IndSchOrgLevelEmployee {
    let data: IndSchOrgLevelEmployee = new IndSchOrgLevelEmployee();
    data.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.totalScheduleHours = dto.totalScheduleHours;
    return data;
  }
  public mapOrgLevelEmployees(dto: IIndSchOrgLevelEmployees): IndSchOrgLevelEmployees {
    let data: IndSchOrgLevelEmployees = new IndSchOrgLevelEmployees();
    data.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    data.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    data.orgLevelId = dto.orgLevelId;
    data.entities = _.map(dto.entities, (record: IIndSchOrgLevelEmployee) => {
      return this.mapOrgLevelEmployee(record);
    });
    return data;
  }

  public mapEmployeeScheduleDefinition(dto: IEmployeeScheduleDefinition, startDate: Date, weekCount: number): IndSchDefWrapper {
    let schDef: EmployeeScheduleDefinition = this.scheduleDefinitionsMapService.mapToEmployeeScheduleDefinition(dto);
    let entities: IndSchEntryWrapper[] = this.mapIndSchDateWrappers(schDef.entries);
    let data: IndSchDefWrapper = new IndSchDefWrapper();
    data.employee = schDef.employee;
    data.position = schDef.position;
    data.homeUnit = schDef.homeUnit;
    data.homeShift = schDef.homeShift;
    data.weeklyData = this.calendarDataService.ExtendRangeToWeeklyDataByStartDate(moment(startDate), weekCount, entities);
    data.canEditScheduleApprovedPayPeriod = schDef.canEditScheduleApprovedPayPeriod;
    return data;
  }

  public mapIndSchDateWrapper(entry: ScheduleEntryDefinition): IndSchEntryWrapper {
    let wrapper: IndSchEntryWrapper = new IndSchEntryWrapper();
    wrapper.entryDef = entry;
    wrapper.date = entry.dateOn;
    return wrapper;
  }

  public mapIndSchDateWrappers(entries: ScheduleEntryDefinition[]): IndSchEntryWrapper[] {
    return _.map(entries, (entry: ScheduleEntryDefinition) => {
      return this.mapIndSchDateWrapper(entry);
    });
  }

  public mapToScheduleEntryDefinitionDto(data: IndSchEntryWrapper): IScheduleEntryDefinition {
    return this.scheduleDefinitionsMapService.mapToScheduleEntryDefinitionDto(data.entryDef);
  }

  public mapToScheduleEntriesDefinitionDto(entries: IndSchEntryWrapper[]): IScheduleEntryDefinition[] {
    return _.map(entries, (entry: IndSchEntryWrapper) => {
      return this.mapToScheduleEntryDefinitionDto(entry);
    });
  }

  public mapToSaveIndividualScheduleRequest(employeeId: number, changedEntries: IndSchEntryWrapper[], datesToClear: Date[]): ISaveIndividualScheduleRequest {
    return {
      employeeId: employeeId,
      changedScheduleEntries: this.mapToScheduleEntriesDefinitionDto(changedEntries),
      datesToClear: _.map(datesToClear, (d: Date) => dateTimeUtils.convertToDtoString(d))
    };
  }
}
