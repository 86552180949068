import { Department, LocationUnit, Position} from '../../../organization/models/index';

export class CostCenterModel {
    public id: number;
    public lineNumber: number;
    public code: number;
    public description: string;
    public position: Position;
    public unit: LocationUnit;
    public department: Department;
    public updatedBy: string;
    public updatedOn: Date;

    public employeeCount: number;

    public departmentOrgLevel: number;

    public isSelected: boolean;
    public selectable: boolean;
    public isDirty: boolean;
}

export interface ICostCenterDTO {
    id: number;
    lineNumber: number;
    code: number;
    description: string;
    jobCode: number;
    unit: number;
    department: number;
    employeeCount: number;
    updatedBy: string;
    updatedOn: string;
}
