import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
import { OrgLevel } from '../../../../../../state-model/models';

import { BenefitDetailsProvider, BenefitDetailsLine, BenefitGroupLoader } from './../../../../models/index';
import { BenefitListManagementService, BenefitConsoleCommonService } from './../../../../services/benefit-console/index';

@Component({
  moduleId: module.id,
  selector: 'benefit-type-section-grid',
  templateUrl: 'benefit-type-section-grid.component.html',
})

export class BenefitTypeSectionGridComponent implements OnInit, OnDestroy {
  public isLoadingBenefitType: boolean;
  public isShowingExpired: boolean = false;
  public columnGroup = 'BenefitsRecentActivity';
  public appConfig: IApplicationConfig;
  public data: BenefitDetailsProvider[] = [];
  public isPageable = false;
  public pageSize = 25;

  @Input()
  public groupId: number;
  @Input()
  public collapsedState: boolean;
  @Input()
  public index: number;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private navService: BenefitsNavigationService;
  private orgLevel: OrgLevel;
  private providerLinesToGroups: Map<number, { gId: number, pId: number }>;
  private isShowingExpiredPlans: boolean;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  public gridState: KendoGridStateHelper<BenefitDetailsProvider>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private manService: BenefitListManagementService,
    public commonService: BenefitConsoleCommonService
  ) {
    this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<BenefitDetailsProvider>();
    this.gridState.view = null;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [
      { field: 'startDate', dir: 'desc' }
    ];
    this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
    this.gridState.assignRestoredState = true;
    this.gridState.refreshGridAfterRestoreState = true;
  }

  public ngOnInit() {
    this.isLoadingBenefitType = true;
    this.gridState.gridComponentKey = `BenefitsGroupsGrid_${this.groupId}`;
    this.gridState.gridControlStateKey = 'GridFiltersState';

    this.subscriptions.orgLevel = this.manService
      .subscribeToOrgLevel((v: OrgLevel) => {
        this.orgLevel = v;
      });

    this.subscriptions.showExpired = this.manService
      .subscribeToIsLoadingExpired((r: boolean) => {
        this.isShowingExpiredPlans = r;
      });

    if (this.groupId && this.collapsedState && (this.index === 0)) {
      this.manService.getBenefitPlanTypes(this.groupId, this.isShowingExpiredPlans);
      this.commonService.recordExpandedGroups(this.groupId);
    }
    else {
      this.isLoadingBenefitType = false;
    }

    this.subscriptions.onLoad = this.manService
      .subscribeToPlanTypeLoading((data: BenefitGroupLoader) => {
        if (data.groupId === this.groupId) {
          this.isLoadingBenefitType = data.isLoading;
        }
      });

    this.subscriptions.onLoaded = this.manService
      .subscribeToPlanTypeRecords((r: BenefitDetailsProvider[]) => {
        if (r && r.length > 0) {
          if (r[0].benefitGroupId === Number(this.groupId)) {
            this.data = r;
            this.isPageable = _.size(this.data) > this.pageSize;
            this.refreshGrid();
          }
        }
      });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
      .subscribe((v: State) => {
        this.refreshGrid();
      });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onClickBenefit(providerLine: BenefitDetailsLine): void {
    const data = this.providerLinesToGroups.get(providerLine.id);
    this.navService.navigateToDetails(this.orgLevel.id, data.gId, data.pId, providerLine.id);
  }

  private refreshGrid(): void {
    this.isLoadingBenefitType = false;
    if (!this.data) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.makeListOfBenefitsLines(), this.gridState.state);
  }

  private makeListOfBenefitsLines(): BenefitDetailsLine[] {
    const gridData = [] as BenefitDetailsLine[];
    this.providerLinesToGroups = new Map<number, { gId: number, pId: number }>();
    _.forEach(this.data, (provider) => {
      _.forEach(provider.benefitLines, (providerLine) => {
        this.providerLinesToGroups.set(providerLine.id, { gId: provider.benefitGroupId, pId: provider.id });
        providerLine['orgLevelName'] = provider.orgLevelName;
        gridData.push(providerLine);
      });
    });

    return gridData;
  }
}
