import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { appConfig } from '../../../../app.config';
import { wcConfig } from '../../workers-compensation.config';
import { ApiUtilService } from '../../../../common/index';
import { UrlParamsService } from '../../../../common/services/url-params/url-params.service';
import { dateTimeUtils } from '../../../../common/utils/index';
import { WcRosterMapService } from './wc-roster-map.service';
var WcRosterApiService = /** @class */ (function () {
    function WcRosterApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    WcRosterApiService.prototype.getIncidentRoster = function (orgLevelId, sDate, eDate, incidentsAmount) {
        if (incidentsAmount === void 0) { incidentsAmount = null; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getRosterApi();
                params = {
                    orgLevelId: orgLevelId,
                    start: dateTimeUtils.convertToDtoString(sDate),
                    end: dateTimeUtils.convertToDtoString(eDate)
                };
                if (_.isFinite(incidentsAmount)) {
                    params.minCount = incidentsAmount;
                }
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapInsidentRosterContainer(response.data, response.meta); })];
            });
        });
    };
    WcRosterApiService.prototype.getOshaIncidentRoster = function (orgLevelId, sDate, eDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getOshaApi();
                params = {
                    orgLevelId: orgLevelId,
                    start: dateTimeUtils.convertToDtoString(sDate),
                    end: dateTimeUtils.convertToDtoString(eDate)
                };
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapInsidentRosterContainer(response.data, response.meta); })];
            });
        });
    };
    WcRosterApiService.prototype.generateOshaExcel = function (orgLevelId, year, InjuryIds) {
        var url = this.getApiRoot() + "/wc/generateOsha/" + orgLevelId + "/" + year;
        var request = this.urlParamsService.createPostRequest(url, InjuryIds);
        return this.apiUtilService.requestForFile(request)
            .then(function (file) { return file; });
    };
    WcRosterApiService.prototype.generateOshaPDF300 = function (data) {
        var url = this.getApiRoot() + "/wcpdfform/osha300pdf";
        var body = this.mapService.mapGenerateOshaPDF300TypeModelToDto(data);
        var request = this.urlParamsService.createPostRequest(url, body);
        return this.apiUtilService.requestForFile(request)
            .then(function (file) { return file; });
    };
    WcRosterApiService.prototype.generateOshaPDF301 = function (data) {
        var url = this.getApiRoot() + "/wcpdfform/osha301pdf";
        var body = this.mapService.mapGenerateOshaPDF301TypeModelToDto(data);
        var request = this.urlParamsService.createPostRequest(url, body);
        return this.apiUtilService.requestForFile(request)
            .then(function (file) { return file; });
    };
    WcRosterApiService.prototype.getComparisons = function (orgLevelId, sDate, eDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getComparisonsApi();
                params = {
                    orgLevelId: orgLevelId,
                    start: dateTimeUtils.convertToDtoString(sDate),
                    end: dateTimeUtils.convertToDtoString(eDate)
                };
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapComparisonsContainer(response.data); })];
            });
        });
    };
    WcRosterApiService.prototype.getEstablismentSize = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/configuration/organizations/" + orgLevelId;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapEstablishmentSize(response.data); })];
            });
        });
    };
    WcRosterApiService.prototype.updateEstablismentSize = function (orgLevelId, size) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiRoot() + "/configuration/organizationsize/" + orgLevelId + "/" + size;
                        request = this.urlParamsService.createPutRequest(url);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 1:
                        promise = _a.sent();
                        return [2 /*return*/, promise];
                }
            });
        });
    };
    WcRosterApiService.prototype.getOshaApi = function () {
        return this.getWcApi() + "/" + wcConfig.api.wc.osha.root;
    };
    WcRosterApiService.prototype.getRosterApi = function () {
        return this.getWcApi() + "/" + wcConfig.api.wc.roster.root;
    };
    WcRosterApiService.prototype.getComparisonsApi = function () {
        return this.getWcApi() + "/" + wcConfig.api.wc.comparisons.root;
    };
    WcRosterApiService.prototype.getWcApi = function () {
        return this.getApiRoot() + "/" + wcConfig.api.wc.root;
    };
    WcRosterApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return WcRosterApiService;
}());
export { WcRosterApiService };
