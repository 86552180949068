import { Injectable } from '@angular/core';
import { appConfig } from '../../../app.config';
import { authenticationConfig } from '../../authentication.config';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { ClientMessageMapService } from './client-message-map.service';
import { ApiUtilService } from '../../../common/services/api/api-util.service';
import { UrlParamsService } from '../../../common/services/url-params/url-params.service';
import { IClientMessage, ClientMessage } from '../../models/index';
import { HttpRequest } from '@angular/common/http';
import { CacheType } from '../../../common/models/cache/cache-definition';



@Injectable()
export class ClientMessageApiService {

  constructor(private apiService: ApiUtilService, private urlParamsService: UrlParamsService, private mapService: ClientMessageMapService) {
  }

  public getMessages(orgLevelId: number, updateCacheForced: boolean = false): Promise<ClientMessage[]> {

    const url: string = `${this.apiService.getApiRoot()}/${authenticationConfig.api.client.root}/${authenticationConfig.api.client.messages}/${orgLevelId}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url)

    let promise: Promise<ClientMessage[]> = this.apiService
      .cachedRequest<IClientMessage[], Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IClientMessage[], Meta>) => this.mapService.mapClientMessages(response.data, orgLevelId));
    return promise;
  }
}
