
import { ILoaRequest, LoaRequest } from './loa-request';
import { ILoaType, LoaType } from './loa-type';
import { ILoaTypeOverview, LoaTypeOverview } from './loa-type-overview';



export interface ILoaConsole {
  onLeave: ILoaRequest[];
  returning: ILoaRequest[];
  upcoming: ILoaRequest[];
  pastDue: ILoaRequest[];
  onLeaveTotalCount: number;
  returningTotalCount: number;
  upcomingTotalCount: number;
  pastDueTotalCount: number;
  incompleteTotalCount: number;
  totalCount: number;
  loaTypeOverview: ILoaTypeOverview[];
}

export class LoaConsole {
  public onLeave: LoaRequest[];
  public returning: LoaRequest[];
  public upcoming: LoaRequest[];
  public pastDue: LoaRequest[];
  public onLeaveTotalCount: number;
  public returningTotalCount: number;
  public upcomingTotalCount: number;
  public pastDueTotalCount: number;
  public incompleteTotalCount: number;
  public totalCount: number;
  public loaTypeOverview: LoaTypeOverview[];
}
