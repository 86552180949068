/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aca-c1095-audit-grid.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i3 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i4 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i5 from "ag-grid-angular/dist/agGridAngular";
import * as i6 from "./aca-c1095-audit-grid.component";
import * as i7 from "../../../services/aca-c1095-audit/aca-c1095-audit-management.service";
import * as i8 from "@angular/router";
var styles_AcaC1095AuditGridComponent = [i0.styles];
var RenderType_AcaC1095AuditGridComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AcaC1095AuditGridComponent, data: {} });
export { RenderType_AcaC1095AuditGridComponent as RenderType_AcaC1095AuditGridComponent };
export function View_AcaC1095AuditGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-balham"], ["groupHeaders", ""], ["headerHeight", "20"], ["id", "myAgGrid"], ["style", "width: 100%; height: 100%;"]], null, [[null, "gridReady"], [null, "componentStateChanged"], [null, "cellClicked"], [null, "rowGroupOpened"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } if (("componentStateChanged" === en)) {
        var pd_1 = (_co.onComponentStateChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("cellClicked" === en)) {
        var pd_2 = (_co.onCellClicked($event) !== false);
        ad = (pd_2 && ad);
    } if (("rowGroupOpened" === en)) {
        var pd_3 = (_co.onRowGroupOpened($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_AgGridAngular_0, i2.RenderType_AgGridAngular)), i1.ɵprd(512, null, i3.AngularFrameworkOverrides, i3.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i4.AngularFrameworkComponentWrapper, i4.AngularFrameworkComponentWrapper, []), i1.ɵdid(3, 4898816, null, 1, i5.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i3.AngularFrameworkOverrides, i4.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { gridOptions: [0, "gridOptions"], rowData: [1, "rowData"], columnDefs: [2, "columnDefs"], defaultColDef: [3, "defaultColDef"], headerHeight: [4, "headerHeight"], paginationPageSize: [5, "paginationPageSize"], getRowHeight: [6, "getRowHeight"], suppressContextMenu: [7, "suppressContextMenu"], pagination: [8, "pagination"] }, { rowGroupOpened: "rowGroupOpened", cellClicked: "cellClicked", gridReady: "gridReady", componentStateChanged: "componentStateChanged" }), i1.ɵqud(603979776, 1, { columns: 1 }), (_l()(), i1.ɵeld(5, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-balham"], ["id", "myGrid"], ["style", "width: 100%; height: 100%;"]], [[8, "hidden", 0]], [[null, "gridReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReadyExport($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AgGridAngular_0, i2.RenderType_AgGridAngular)), i1.ɵprd(512, null, i3.AngularFrameworkOverrides, i3.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i4.AngularFrameworkComponentWrapper, i4.AngularFrameworkComponentWrapper, []), i1.ɵdid(8, 4898816, [["agGrid", 4]], 1, i5.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i3.AngularFrameworkOverrides, i4.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { rowData: [0, "rowData"], columnDefs: [1, "columnDefs"] }, { gridReady: "gridReady" }), i1.ɵqud(603979776, 2, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridOptions; var currVal_1 = _co.rowData; var currVal_2 = _co.columnDefs; var currVal_3 = _co.defaultColumnDefs; var currVal_4 = "20"; var currVal_5 = 50; var currVal_6 = _co.getRowHeight; var currVal_7 = true; var currVal_8 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_10 = _co.exportRow; var currVal_11 = _co.gridHelper.columnDefs; _ck(_v, 8, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_9 = true; _ck(_v, 5, 0, currVal_9); }); }
export function View_AcaC1095AuditGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-aca-c1095-audit-grid", [], null, null, null, View_AcaC1095AuditGridComponent_0, RenderType_AcaC1095AuditGridComponent)), i1.ɵdid(1, 245760, null, 0, i6.AcaC1095AuditGridComponent, [i7.AcaC1095AuditManagementService, i8.Router, i8.ActivatedRoute, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AcaC1095AuditGridComponentNgFactory = i1.ɵccf("slx-aca-c1095-audit-grid", i6.AcaC1095AuditGridComponent, View_AcaC1095AuditGridComponent_Host_0, {}, {}, []);
export { AcaC1095AuditGridComponentNgFactory as AcaC1095AuditGridComponentNgFactory };
