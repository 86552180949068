import { LoaRequestClass, LoaCategory, LoaActions } from './loa-enums';

export class DropDownItem {
  constructor(public id: number, public name: string, public icon: string = '') {}
}

export class LoaRequestDropDownItem {
  public id: LoaRequestClass;
  public name: string;
  public icon: string;
}

export class LoaCategoryDropDownItem {
  public id: LoaCategory;
  public name: string;
  public icon: string;
}


export class LoaActionsDropDownItem {
  public id: LoaActions;
  public name: string;
  public icon: string;
}

export const loaDropdownItems: LoaRequestDropDownItem[] = [
  { name: 'Continuous LOA', icon: 'far fa-repeat', id: LoaRequestClass.Continuous },
  { name: 'Intermittent LOA', icon: 'far fa-random', id: LoaRequestClass.Intermittent }
];

export const loaActionsDropdownItems: LoaActionsDropDownItem[] = [
  { name: 'View LOA', icon: 'far fa-eye', id: LoaActions.View },
  { name: 'Create Continuous LOA', icon: 'far fa-repeat', id: LoaActions.CreateNewContinuous },
  { name: 'Create Intermittent LOA', icon: 'far fa-random', id: LoaActions.CreateNewIntermittent }
];

