import { Injectable } from "@angular/core";
import { FileUploadsMapService } from "./file-uploads-map.service";
import { ApiUtilService, UrlParamsService } from "./../../../../app/common";
import { MetaMapService } from "./../../../../app/core/services";
import { LookupService } from "./../../../../app/organization";
import { IExcelData, IImportedFiles, ImportedFiles } from "../../models/imported-files/ImportedFiles";
import { HttpRequest } from "@angular/common/http";
import { Meta, ResponseBody } from "./../../../../app/core/models";
import { configurationConfig } from "../../configuration.config";
import { FileBlobResponse } from "./../../../../app/core/models/api/file-blob-response";

@Injectable()
export class FileUploadsApiService {
    constructor(private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private fileUploadsMapService: FileUploadsMapService,
        private metaMapService: MetaMapService,
        private lookupService: LookupService
    ) { }

    public getImportedFiles(orgLevelId: number): Promise<IImportedFiles[]> {
        const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.configuration.fileUploads.root}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
        let promise: Promise<IImportedFiles[]> = this.apiUtilService.request<IImportedFiles[], Meta>(request)
            .then((response: ResponseBody<ImportedFiles[], Meta>) =>
                this.fileUploadsMapService.mapImportedFiles(response.data, orgLevelId));
        return promise;
    }

    public downloadImportedFile(importId: string): Promise<any[]> {
        const url: string = this.apiUtilService.apiRoot`${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.configuration.fileUploads.downloadImportedFiles.root}/${importId}`;
        const request = this.urlParamsService.createPostRequest(url);
        let promise: Promise<any[]> = this.apiUtilService.request<IExcelData[], Meta>(request)
          .then((response: ResponseBody<IExcelData[], Meta>) => this.fileUploadsMapService.mapExcelResultData(response.data));
        return promise;
      }
    
}