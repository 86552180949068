<div class="slx-high-box">
  <div class="main-menu">
    <div class="date-host">
      <span class="theme-md-text">{{details?.dateOn | amDateFormat: appConfig.monthDayDateFormat}}</span>
    </div>
    <slx-checkbox-toggle [(ngModel)]="managementService.showFilledShifts" (ngModelChange)="onShowFilledShifts()" class="toggler" className="toggle-right" caption="Show filled shifts" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
    <!-- <slx-checkbox-toggle class="toggle" className="toggle-right" caption="Timeline display" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle> -->
    <div class="state-host">
      <span class="fa-stack fa-lg shift-count">
        <i class="far fa-stack-2x" aria-hidden="true" slxTooltip="Open Shift Count" tipPosition="left"
        [ngClass]="{
            'fa-circle': details?.newMessageCount > 0 || details?.partnerShiftCount,
            'fa-square': details?.shiftCount > 0 ,
            'fa-check-circle': details?.shiftCount == 0
          }"></i>
        <span *ngIf="details?.shiftCount > 0" class="fa-stack-1x theme-sm-text">{{details?.shiftCountPartial}}</span>
      </span>
      <div *ngIf="details?.newMessageCount > 0 || details?.partnerShiftCount>0">
        <span class="fa-stack fa-1x" >
          <i class="far fa-envelope fa-stack-2x messages-icon msg-offset" aria-hidden="true"
            slxTooltip="Open Shift Requests" tipPosition="left"></i>
          <span *ngIf="details?.newMessageCount > 0 " class="badge theme-exsm-text">{{details?.newMessageCount}}</span>
          <span *ngIf="details?.partnerShiftCount > 0" class="badge theme-exsm-text agency-request">{{details?.partnerShiftCount}}</span> 
        
        </span>
      </div>
    </div>
    <div style="display: none" class="action-host">
      <div class="btn-group">
        <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Actions <span class="caret"></span>
      </button>
        <ul class="dropdown-menu">
          <li><a href="#">Add Open Shift</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div class="slx-high-box__body">
    <slx-daily-shift-summary-grid [canEditOpenShiftCount]="managementService?.canEditOpenShiftCount" [summary]="details" [canSendSMS]="managementService?.canSendSMS"></slx-daily-shift-summary-grid>
  </div>

  <!-- <div class="message-list-host">
    <slx-daily-shift-messages-container [details]="details"></slx-daily-shift-messages-container>
  </div> -->
</div>
