<form novalidate #gridForm="ngForm">
  <div class="performance-management-section">
    <div class="performance-management-body">
      <kendo-grid #kendoGrid
        scrollable="'scrollable'"
        [data]="gridState?.view"
        [sortable]="true" [sort]="gridState.state.sort"
        [skip]="gridState.state.skip" [pageable]="true" [pageSize]="pageSize"
        [filterable]="true" [filter]="gridState.state.filter"
        [groupable]="false" [selectable]="false"
        [slxKendoGridState]="gridState.gridDefinition"
        (dataStateChange)="gridState.dataStateChange($event)"
        (selectionChange)="gridState.selectionChange($event)"
        (stateRestored)="gridState.dataStateChange($event)"
        (edit)="gridState.editHandler($event)"
        (save)="gridState.saveHandler($event)"
        (cancel)="gridState.cancelHandler($event)"
        (remove)="gridState.removeHandler($event)"
        (pageChange)="pageChanged($event)"
        >

        <ng-template kendoGridToolbarTemplate position="top">
          <div class="col-xs-12" *ngIf="reviews?.canCreateNewReview && isEditable">
            <div class="row">
              <button class="theme-iconed-accent-button pull-right" (click)="onStartAdd()">
                <span class="icon-button-content">
                  <span>Create New</span>
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
        </ng-template>

        <kendo-grid-column field="reviewType.id" title="Review Type" width="170">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-multiselect-filter
              [filter]="filter" [showOperators]="false" [column]="column" [items]="reviewTypeFilters" ></slx-kendo-grid-multiselect-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            Review Type
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.reviewType?.name }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="rating.id" title="Rating" width="170">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-multiselect-filter
              [filter]="filter" [showOperators]="false" [column]="column" [items]="ratingTypeFilters" ></slx-kendo-grid-multiselect-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            Rating
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.rating?.name }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="reviewRecord.reviewDate" title="Review Date" width="120">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-date-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            Review Date
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="edit-link" (click)="onSelectItem(dataItem)">{{ dataItem.reviewRecord.reviewDate | amDateFormat: appConfig.dateFormat }}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="reviewRecord.startDate" title="Start Date" width="120">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-date-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            Start Date
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.reviewRecord.startDate | amDateFormat: appConfig.dateFormat }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="reviewRecord.completionDate" title="Completion Date" width="120">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-date-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            Completion Date
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.reviewRecord.completionDate | amDateFormat: appConfig.dateFormat }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Reviewed By" field="reviewRecord.reviewedBy" width="200">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
              Reviewed By
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.reviewRecord?.reviewedBy }}
          </ng-template>
        </kendo-grid-column>

      </kendo-grid>
    </div>
  </div>
</form>
