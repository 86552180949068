import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';
import * as moment from 'moment';

import { dateTimeUtils } from '../../../common/utils/index';
import { IRoleDefinition, RoleDefinition, IProductModule, ProductModule, ProductModuleType } from '../../../organization/models/index';
import { LookupMapService, ProductModuleMapService } from '../../../organization/services/index';

import {
  Role, IRole,
  RoleProfile, IRoleProfile,
  RoleSection, IRoleSection,
  RoleSubsection, IRoleSubsection,
  RoleRight, IRoleRight, RoleRightGroup, RoleRightModule, IRoleRightModule,
  RoleField, IRoleField, RolesContainer,
  RolesProfileRow, RolesSectionRow, RolesSubsectionRow, RolesFieldRow, RolesRightRow, RolesRightGroupRow, RolesRightModuleRow,
  RoleMenuModule, RoleMenuModuleGroup, IRoleMenuModule, RoleMenu, IRoleMenu, RoleSubmenu, IRoleSubmenu,
  RolesMenuModuleGroupRow, RolesMenuModuleRow, RolesMenuRow, RolesSubmenuRow,
  RoleComponentsModuleGroup, RoleComponentsModule, IRoleComponentsModule, RoleComponent, IRoleComponent,
  RolesComponentsModuleGroupRow, RolesComponentsModuleRow, RolesComponentRow,
  IUpdateRoleRequest, RolesRowDefinition, IRoleAccessTable

} from '../../models/index';


@Injectable()
export class RolesMapService {
  constructor(private lookupMapService: LookupMapService, private productModuleMapService: ProductModuleMapService) { }

  public mapRoleDefitinion(role: IRoleDefinition): RoleDefinition {
    return this.lookupMapService.mapRoleDefinition(role);
  }

  public mapRoleDefitinions(roles: IRoleDefinition[]): RoleDefinition[] {
    return this.lookupMapService.mapRoleDefinitions(roles);
  }

  public mapRoleDefitinionDto(role: RoleDefinition): IRoleDefinition {
    return this.lookupMapService.mapRoleDefinitionDto(role);
  }

  public mapUpdateRoleRequest(role: RoleDefinition): IUpdateRoleRequest {
    let req: IUpdateRoleRequest = { name: role.name };
    return req;
  }

  public mapToRoleField(dto: IRoleField): RoleField {
    let data: RoleField = new RoleField();
    data.id = dto.id;
    data.name = dto.name;
    data.displayName = dto.displayName;
    data.hidden = !!dto.hidden || _.isNull(dto.hidden) || _.isUndefined(dto.hidden) ? true : false;
    data.editable = dto.editable ? true : false;
    data.masked = dto.masked ? true : false;
    data.maskRules = dto.maskRules;
    data.setInitialState();
    return data;
  }

  public mapToRoleFieldDTO(data: RoleField): IRoleField {
    let dto: IRoleField = {
      id: data.id,
      name: data.name,
      displayName: data.displayName,
      hidden: data.hidden,
      editable: data.editable,
      masked: data.masked,
      maskRules: data.maskRules
    };
    return dto;
  }

  public mapToRoleRight(dto: IRoleRight): RoleRight {
    let data: RoleRight = new RoleRight();
    data.id = dto.id;
    data.name = dto.name;
    data.linkedMenuId = dto.linkedMenuId;
    data.displaySequence = dto.displaySequence;    
    data.isEnabled = dto.isEnabled ? true : false;    
    data.setInitialState();
    return data;
  }

  public mapToRoleRightModule(dto: IRoleRightModule, rightsDtos: IRoleRight[]): RoleRightModule {
    let data: RoleRightModule = new RoleRightModule();
    data.id = dto.id;
    data.name = dto.name;
    const filteredRightsDtos = _.filter(rightsDtos, (r: IRoleRight) => r.groupId === data.id);
    data.rights = _.map(filteredRightsDtos, (r: IRoleRight) => this.mapToRoleRight(r));
    data.hasLinkedMenus = !!_.find(data.rights, (r: RoleRight) => !!r.linkedMenuId);
    return data;
  }

  public mapToRoleRightDTO(data: RoleRight): IRoleRight {
    let dto: IRoleRight = {
      id: data.id,
      name: data.name,
      linkedMenuId: data.linkedMenuId,
      isEnabled: data.isEnabled,
      groupId: data.groupId,
      displaySequence : data.displaySequence
    };
    return dto;
  }

  public mapToRoleSubsection(dto: IRoleSubsection): RoleSubsection {
    let data: RoleSubsection = new RoleSubsection();
    data.name = dto.name;
    data.fields = _.map(dto.fields, (fdto: IRoleField) => this.mapToRoleField(fdto));
    return data;
  }

  public mapToRoleSubsectionDTO(data: RoleSubsection): IRoleSubsection {
    let dto: IRoleSubsection = {
      name: data.name,
      fields: _.map(data.fields, (fdata: RoleField) => this.mapToRoleFieldDTO(fdata))
    };
    return dto;
  }

  public mapToRoleSection(dto: IRoleSection): RoleSection {
    let data: RoleSection = new RoleSection();
    data.name = dto.name;
    data.subsections = _.map(dto.subsections, (fdto: IRoleSubsection) => this.mapToRoleSubsection(fdto));
    return data;
  }

  public mapToRoleSectionDTO(data: RoleSection): IRoleSection {
    let dto: IRoleSection = {
      name: data.name,
      subsections: _.map(data.subsections, (fdata: RoleSubsection) => this.mapToRoleSubsectionDTO(fdata))
    };
    return dto;
  }

  public mapToRoleSectionsDTO(data: RoleSection[]): IRoleSection[] {
    return _.map(data, (fdto: RoleSection) => this.mapToRoleSectionDTO(fdto));
  }

  public mapToRoleProfile(dto: IRoleProfile): RoleProfile {
    let data: RoleProfile = new RoleProfile();
    data.sections = _.map(dto.sections, (fdto: IRoleSection) => this.mapToRoleSection(fdto));
    return data;
  }

  public mapToRoleProfileDTO(data: RoleProfile): IRoleProfile {
    let dto: IRoleProfile = {
      sections: this.mapToRoleSectionsDTO(data.sections)
    };
    return dto;
  }

  public mapToRoleSubmenu(dto: IRoleSubmenu): RoleSubmenu {
    let data: RoleSubmenu = new RoleSubmenu();
    data.id = dto.id;
    data.name = dto.name;
    data.isEnabled = dto.isEnabled;
    data.setInitialState();
    return data;
  }

  public mapToRoleSubmenuDTO(data: RoleSubmenu): IRoleSubmenu {
    let dto: IRoleSubmenu = {
      id: data.id,
      name: data.name,
      isEnabled: data.isEnabled
    };
    return dto;
  }

  public mapToRoleMenu(dto: IRoleMenu): RoleMenu {
    let data: RoleMenu = new RoleMenu();
    data.id = dto.id;
    data.name = dto.name;
    data.isEnabled = dto.isEnabled;
    data.setInitialState();
    data.subMenus = _.map(dto.subMenus, (fdto: IRoleSubmenu) => this.mapToRoleSubmenu(fdto));
    return data;
  }

  public mapToRoleMenuDTO(data: RoleMenu): IRoleMenu {
    let dto: IRoleMenu = {
      id: data.id,
      name: data.name,
      isEnabled: data.isEnabled,
      subMenus: _.map(data.subMenus, (fdata: RoleSubmenu) => this.mapToRoleSubmenuDTO(fdata))
    };
    return dto;
  }

  public mapToRoleMenuModule(dto: IRoleMenuModule): RoleMenuModule {
    let data: RoleMenuModule = new RoleMenuModule();
    data.id = dto.id;
    data.name = dto.name;
    data.menus = _.map(dto.menus, (fdto: IRoleMenu) => this.mapToRoleMenu(fdto));
    data.displayOrder = dto.displayOrder
    return data;
  }

  public mapToRoleMenuModuleDTO(data: RoleMenuModule): IRoleMenuModule {
    let dto: IRoleMenuModule = {
      id: data.id,
      name: data.name,
      menus: _.map(data.menus, (fdata: RoleMenu) => this.mapToRoleMenu(fdata)),
      displayOrder: data.displayOrder
    };
    return dto;
  }


  public mapToRoleComponent(dto: IRoleComponent): RoleComponent {
    let data: RoleComponent = new RoleComponent();
    data.id = dto.id;
    data.name = dto.name;
    data.isEnabled = dto.isEnabled;
    data.setInitialState();
    return data;
  }

  public mapToRoleComponentDTO(data: RoleComponent): IRoleComponent {
    let dto: IRoleComponent = {
      id: data.id,
      name: data.name,
      isEnabled: data.isEnabled
    };
    return dto;
  }

  public mapToRoleComponentsModule(dto: IRoleComponentsModule): RoleComponentsModule {
    let data: RoleComponentsModule = new RoleComponentsModule();
    data.id = dto.id;
    data.name = dto.name;
    data.components = _.map(dto.components, (fdto: IRoleComponent) => this.mapToRoleComponent(fdto));
    return data;
  }

  public mapToRoleComponentsModuleDTO(data: RoleComponentsModule): IRoleComponentsModule {
    let dto: IRoleComponentsModule = {
      id: data.id,
      name: data.name,
      components: _.map(data.components, (fdata: RoleComponent) => this.mapToRoleComponentDTO(fdata))
    };
    return dto;
  }

  public mapToRole(dto: IRole): Role {
    let data: Role = new Role();
    data.id = dto.id;
    data.name = dto.name;
    data.reportGroup = dto.reportGroup;
    data.profile = dto.profile ? this.mapToRoleProfile(dto.profile) : null;
    data.rights = _.map(dto.rightGroups, (fdto: IRoleRightModule) => this.mapToRoleRightModule(fdto, dto.rights));
    data.otherRights = _.map(dto.otherRightGroups, (fdto: IRoleRightModule) => this.mapToRoleRightModule(fdto, dto.otherRights));
    data.menuModules = _.map(dto.menuModules, (fdto: IRoleMenuModule) => this.mapToRoleMenuModule(fdto));
    data.componentModules = _.map(dto.componentModules, (fdto: IRoleComponentsModule) => this.mapToRoleComponentsModule(fdto));
    data.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
    data.lastUpdateUsername = dto.lastUpdateUsername;
    return data;
  }

  public mapRolesRightModuleRows(data: RolesContainer): RolesRightModuleRow[] {
    let rightModuleRows: NumberMap<RolesRightModuleRow> = _.reduce(data.roles, (result: NumberMap<RolesRightModuleRow>, role: Role) => {
      _.forEach(role.rights, (right: RoleRightModule) => {
        let row: RolesRightModuleRow = result[right.id];
        if (!row) {
          row = new RolesRightModuleRow();
          row.roleRightModuleId = right.id;
          row.hasLinkedMenus = right.hasLinkedMenus;
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = data.roles;
          row.description = right.name;
          result[right.id] = row;
        }
        row.mapByRole[role.id] = right;
      });
      return result;
    }, {});
    let res: RolesRightModuleRow[] = _.values(rightModuleRows);
    _.forEach(res, (row: RolesRightModuleRow) => {
      row.childRows = this.mapRolesRightRows(row);
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
    });
    return res;
  }

  public mapRolesOtherRightModuleRows(data: RolesContainer): RolesRightModuleRow[] {
    let rightModuleRows: NumberMap<RolesRightModuleRow> = _.reduce(data.roles, (result: NumberMap<RolesRightModuleRow>, role: Role) => {
      _.forEach(role.otherRights, (right: RoleRightModule) => {
        let row: RolesRightModuleRow = result[right.id];
        if (!row) {
          row = new RolesRightModuleRow();
          row.roleRightModuleId = right.id;
          row.hasLinkedMenus = right.hasLinkedMenus;
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = data.roles;
          row.description = right.name;
          result[right.id] = row;
        }
        row.mapByRole[role.id] = right;
      });
      return result;
    }, {});
    let res: RolesRightModuleRow[] = _.values(rightModuleRows);
    _.forEach(res, (row: RolesRightModuleRow) => {
      row.childRows = this.mapRolesRightRows(row);
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
    });
    return res;
  }

  public mapRolesRightRows(moduleRow: RolesRightModuleRow): RolesRightRow[] {
    let rightRows: NumberMap<RolesRightRow> = _.reduce(moduleRow.roles, (result: NumberMap<RolesRightRow>, role: Role) => {
      let rightModule: RoleRightModule = moduleRow.mapByRole[role.id];
      _.forEach(rightModule.rights, (right: RoleRight) => {
        let row: RolesRightRow = result[right.id];
        if (!row) {
          row = new RolesRightRow();
          row.roleRightId = right.id;
          row.linkedMenuId = right.linkedMenuId;
          row.displaySequence = right.displaySequence;
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = moduleRow.roles;
          row.description = right.name;
          result[right.id] = row;
        }
        row.mapByRole[role.id] = right;
      });
      return result;
    }, {});
    let res: RolesRightRow[] = _.values(rightRows);
    _.forEach(res, (row: RolesRightRow) => {
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
    });
    return res;
  }


  public mapRolesFieldRows(subsectionRow: RolesSubsectionRow): RolesFieldRow[] {
    let fieldRows: NumberMap<RolesFieldRow> = _.reduce(subsectionRow.roles, (result: NumberMap<RolesFieldRow>, role: Role) => {
      let subsection: RoleSubsection = subsectionRow.mapByRole[role.id];
      _.forEach(subsection.fields, (field: RoleField) => {
        let row: RolesFieldRow = result[field.id];
        if (!row) {
          row = new RolesFieldRow();
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = subsectionRow.roles;
          row.description = field.displayName;
          result[field.id] = row;
        }
        row.mapByRole[role.id] = field;
      });
      return result;
    }, {});
    let res: RolesFieldRow[] = _.values(fieldRows);
    _.forEach(res, (row: RolesFieldRow) => {
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
    });
    return res;
  }

  public mapRolesSubsectionRows(sectionRow: RolesSectionRow): RolesSubsectionRow[] {
    let subsectionRows: StringMap<RolesSubsectionRow> = _.reduce(sectionRow.roles, (result: StringMap<RolesSubsectionRow>, role: Role) => {
      let section: RoleSection = sectionRow.mapByRole[role.id];
      _.forEach(section.subsections, (subsection: RoleSubsection) => {
        let row: RolesSubsectionRow = result[subsection.name];
        if (!row) {
          row = new RolesSubsectionRow();
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = sectionRow.roles;
          row.description = subsection.name;
          result[subsection.name] = row;
        }
        row.mapByRole[role.id] = subsection;
      });
      return result;
    }, {});
    let res: RolesSubsectionRow[] = _.values(subsectionRows);
    _.forEach(res, (row: RolesSubsectionRow) => {
      row.childRows = this.mapRolesFieldRows(row);
      _.forEach(row.roles, (role: Role) => {
        row.recalcStatus(role.id);
      });
    });
    return res;
  }

  public mapRolesSectionRows(profileRow: RolesProfileRow): RolesSectionRow[] {
    let sectionRows: StringMap<RolesSectionRow> = _.reduce(profileRow.roles, (result: StringMap<RolesSectionRow>, role: Role) => {
      let profile: RoleProfile = profileRow.mapByRole[role.id];
      _.forEach(profile.sections, (section: RoleSection) => {
        let row: RolesSectionRow = result[section.name];
        if (!row) {
          row = new RolesSectionRow();
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = profileRow.roles;
          row.description = section.name;
          result[section.name] = row;
        }
        row.mapByRole[role.id] = section;
      });
      return result;
    }, {});
    let res: RolesSectionRow[] = _.values(sectionRows);
    _.forEach(res, (row: RolesSectionRow) => {
      row.childRows = this.mapRolesSubsectionRows(row);
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
    });
    return res;
  }

  public mapRolesSubmenuRows(menuRow: RolesMenuRow): RolesSubmenuRow[] {
    let submenuRows: NumberMap<RolesSubmenuRow> = _.reduce(menuRow.roles, (result: NumberMap<RolesSubmenuRow>, role: Role) => {
      let menu: RoleMenu = menuRow.mapByRole[role.id];
      _.forEach(menu.subMenus, (submenu: RoleSubmenu) => {
        let row: RolesSubmenuRow = result[submenu.id];
        if (!row) {
          row = new RolesSubmenuRow();
          row.subMenuId = submenu.id;
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = menuRow.roles;
          row.description = submenu.name;
          result[submenu.id] = row;
        }
        row.mapByRole[role.id] = submenu;
      });
      return result;
    }, {});
    let res: RolesSubmenuRow[] = _.values(submenuRows);
    _.forEach(res, (row: RolesSubmenuRow) => {
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
    });
    return res;
  }

  public mapRolesMenuRows(moduleRow: RolesMenuModuleRow, productModules: ProductModule[]): RolesMenuRow[] {
    let menuRows: NumberMap<RolesMenuRow> = _.reduce(moduleRow.roles, (result: NumberMap<RolesMenuRow>, role: Role) => {
      let module: RoleMenuModule = moduleRow.mapByRole[role.id];
      _.forEach(module.menus, (menu: RoleMenu) => {
        let row: RolesMenuRow = result[menu.id];
        if (!row) {
          row = new RolesMenuRow();
          row.menuId = menu.id;
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = moduleRow.roles;
          row.description = menu.name;
          result[menu.id] = row;         
          row.appProductModule = _.find(productModules, (pm: ProductModule) => pm.type === ProductModuleType.Application && pm.applicationId === module.id);          
          row.productModule = _.find(productModules, (pm: ProductModule) => pm.type === ProductModuleType.Menu && pm.applicationId === module.id && pm.menuId === menu.id);
        }
        row.mapByRole[role.id] = menu;
      });
      return result;
    }, {});
    let res: RolesMenuRow[] = _.values(menuRows);
    _.forEach(res, (row: RolesMenuRow) => {
      row.childRows = this.mapRolesSubmenuRows(row);
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
    });
    return res;
  }

  public mapRolesMenuModuleRows(data: RolesContainer): RolesMenuModuleRow[] {
    let moduleRows: NumberMap<RolesMenuModuleRow> = _.reduce(data.roles, (result: NumberMap<RolesMenuModuleRow>, role: Role) => {
      _.forEach(role.menuModules, (module: RoleMenuModule) => {
        let row: RolesMenuModuleRow = result[module.id];
        if (!row) {
          row = new RolesMenuModuleRow();
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = data.roles;
          row.description = module.name;
          row.displayOrder =  module.displayOrder;
          result[module.id] = row;         
          row.productModule = _.find(data.productModules, (pm: ProductModule) => pm.type === ProductModuleType.Application && pm.applicationId === module.id);          
          row.menuProductModules = _.filter(data.productModules, (pm: ProductModule) => pm.type === ProductModuleType.Menu && pm.applicationId === module.id);
        }
        row.mapByRole[role.id] = module;
      });
      return result;
    }, {});
    let res: RolesMenuModuleRow[] = _.values(moduleRows);
    _.forEach(res, (row: RolesMenuModuleRow) => {
      row.childRows = this.mapRolesMenuRows(row, data.productModules);
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
      _.forEach(row.childRows, (menuRow: RolesMenuRow) => {
        data.flatMenus[menuRow.menuId] = menuRow;
        _.forEach(menuRow.childRows, (subMenuRow: RolesSubmenuRow) => {
          data.flatMenus[subMenuRow.subMenuId] = subMenuRow;
        });
      });
    });
    return res;
  }

  public mapRolesProfileRows(data: RolesContainer): RolesProfileRow[] {
    let profiles: RolesProfileRow[] = [];
    let profileRow: RolesProfileRow = new RolesProfileRow();
    profileRow.roles = data.roles;
    profileRow.mapByRole = _.reduce(data.roles, (result: NumberMap<RoleProfile>, role: Role) => {
      result[role.id] = role.profile;
      return result;
    }, {});
    profileRow.dirtyByRole = {};
    profileRow.childRows = this.mapRolesSectionRows(profileRow);
    profileRow.description = 'Field Access';
    profiles.push(profileRow);
    _.forEach(data.roles, (role: Role) => profileRow.recalcStatus(role.id));
    return profiles;
  }

  public mapRolesComponentsModuleRows(data: RolesContainer): RolesComponentsModuleRow[] {
    let moduleRows: NumberMap<RolesComponentsModuleRow> = _.reduce(data.roles, (result: NumberMap<RolesComponentsModuleRow>, role: Role) => {
      _.forEach(role.componentModules, (module: RoleComponentsModule) => {
        let row: RolesComponentsModuleRow = result[module.id];
        if (!row) {
          row = new RolesComponentsModuleRow();
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = data.roles;
          row.description = module.name;
          result[module.id] = row;
        }
        row.mapByRole[role.id] = module;
      });
      return result;
    }, {});
    let res: RolesComponentsModuleRow[] = _.values(moduleRows);
    _.forEach(res, (row: RolesComponentsModuleRow) => {
      row.childRows = this.mapRolesComponentRows(row);
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
    });
    return res;
  }

  public mapRolesComponentRows(moduleRow: RolesComponentsModuleRow): RolesComponentRow[] {
    let menuRows: NumberMap<RolesComponentRow> = _.reduce(moduleRow.roles, (result: NumberMap<RolesComponentRow>, role: Role) => {
      let module: RoleComponentsModule = moduleRow.mapByRole[role.id];
      _.forEach(module.components, (menu: RoleComponent) => {
        let row: RolesComponentRow = result[menu.id];
        if (!row) {
          row = new RolesComponentRow();
          row.mapByRole = {};
          row.dirtyByRole = {};
          row.roles = moduleRow.roles;
          row.description = menu.name;
          result[menu.id] = row;
        }
        row.mapByRole[role.id] = menu;
      });
      return result;
    }, {});
    let res: RolesComponentRow[] = _.values(menuRows);
    _.forEach(res, (row: RolesComponentRow) => {
      _.forEach(row.roles, (role: Role) => row.recalcStatus(role.id));
    });
    return res;
  }

  public mapRolesRightGroupRows(data: RolesContainer): RolesRightGroupRow[] {
    let groups: RolesRightGroupRow[] = [];
    let groupRow: RolesRightGroupRow = new RolesRightGroupRow();
    groupRow.roles = data.roles;
    groupRow.mapByRole = _.reduce(data.roles, (result: NumberMap<RoleRightGroup>, role: Role) => {
      let group: RoleRightGroup = new RoleRightGroup();
      group.rightModules = role.rights;
      result[role.id] = group;
      return result;
    }, {});
    groupRow.dirtyByRole = {};
    groupRow.childRows = this.mapRolesRightModuleRows(data);
    groupRow.description = 'Actions';
    groups.push(groupRow);
    _.forEach(data.roles, (role: Role) => groupRow.recalcStatus(role.id));
    return groups;
  }

  public mapRolesOtherRightGroupRows(data: RolesContainer): RolesRightGroupRow[] {
    let groups: RolesRightGroupRow[] = [];
    let groupRow: RolesRightGroupRow = new RolesRightGroupRow();
    groupRow.type = 'RolesOtherRightGroupRow';
    groupRow.roles = data.roles;
    groupRow.mapByRole = _.reduce(data.roles, (result: NumberMap<RoleRightGroup>, role: Role) => {
      let group: RoleRightGroup = new RoleRightGroup();
      group.rightModules = role.otherRights;
      result[role.id] = group;
      return result;
    }, {});
    groupRow.dirtyByRole = {};
    groupRow.childRows = this.mapRolesOtherRightModuleRows(data);
    groupRow.description = 'Other Rights';
    groups.push(groupRow);
    _.forEach(data.roles, (role: Role) => groupRow.recalcStatus(role.id));
    return groups;
  }

  public mapRolesMenuModuleGroupRows(data: RolesContainer): RolesMenuModuleGroupRow[] {
    let groups: RolesMenuModuleGroupRow[] = [];
    let groupRow: RolesMenuModuleGroupRow = new RolesMenuModuleGroupRow();
    groupRow.roles = data.roles;
    groupRow.mapByRole = _.reduce(data.roles, (result: NumberMap<RoleMenuModuleGroup>, role: Role) => {
      let group: RoleMenuModuleGroup = new RoleMenuModuleGroup();
      group.modules = role.menuModules;
      result[role.id] = group;
      return result;
    }, {});
    groupRow.dirtyByRole = {};
    let childRows = this.mapRolesMenuModuleRows(data);
    groupRow.childRows = _.orderBy(childRows, ['displayOrder'],['asc']); 
    groupRow.description = 'Menu Modules';
    groups.push(groupRow);
    _.forEach(data.roles, (role: Role) => groupRow.recalcStatus(role.id));
    return groups;
  }

  public mapRolesComponentsModuleGroupRows(data: RolesContainer): RolesComponentsModuleGroupRow[] {
    let groups: RolesComponentsModuleGroupRow[] = [];
    let groupRow: RolesComponentsModuleGroupRow = new RolesComponentsModuleGroupRow();
    groupRow.roles = data.roles;
    groupRow.mapByRole = _.reduce(data.roles, (result: NumberMap<RoleComponentsModuleGroup>, role: Role) => {
      let group: RoleComponentsModuleGroup = new RoleComponentsModuleGroup();
      group.modules = role.componentModules;
      result[role.id] = group;
      return result;
    }, {});
    groupRow.dirtyByRole = {};
    groupRow.childRows = this.mapRolesComponentsModuleRows(data);
    groupRow.description = 'Components Modules';
    groups.push(groupRow);
    _.forEach(data.roles, (role: Role) => groupRow.recalcStatus(role.id));
    return groups;
  }

  public mapToRolesContainer(dto: IRoleAccessTable): RolesContainer {
    try {
      let data: RolesContainer = new RolesContainer();
      data.flatMenus = {};
      data.roles = _.map(dto.roles, (fdto: IRole) => this.mapToRole(fdto));
      data.productModules = _.map(dto.productModules, (pdto: IProductModule) => this.productModuleMapService.mapToProductModule(pdto));
      data.rightGroupRows = this.mapRolesRightGroupRows(data);
      data.otherRightGroupRows = this.mapRolesOtherRightGroupRows(data);
      data.profileRows = this.mapRolesProfileRows(data);
      data.menuModuleGroupRows = this.mapRolesMenuModuleGroupRows(data);
      data.componentsModuleGroupRows = this.mapRolesComponentsModuleGroupRows(data);
      this.bindRightsToMenus(data);
      return data;
    } catch (reason) {
      throw new Error('Can`t map RolesContainer');
    }
  }

  public bindRightsToMenus(container: RolesContainer): void {
    _.forEach(container.rightGroupRows, (group: RolesRightGroupRow) => {
      _.forEach(group.childRows, (rightModuleRow: RolesRightModuleRow) => {
        _.forEach(rightModuleRow.childRows, (rightRow: RolesRightRow) => {
          if (rightRow.linkedMenuId) {
            const menu = container.findMenuRowById(rightRow.linkedMenuId);
            if (menu) {
              rightRow.linkedMenuName = menu.description;
              menu.linkedRightName = rightRow.description;
              menu.linkedRightRow = rightRow;
            }
          }
        });
      });
    });
  }
}
