import { TemplateRef, ViewContainerRef } from '@angular/core';
import { IStepCaseConf, StepDefinition } from './wizard-step.types';

export class StepView {
  public stepDefinition: StepDefinition;

  public get step(): number {
    return this.stepDefinition.step;
  }

  private created: boolean;
  private viewContainerRef: ViewContainerRef;
  private templateRef: TemplateRef<Object>;

  constructor(viewContainerRef: ViewContainerRef, templateRef: TemplateRef<Object>) {
    this.viewContainerRef = viewContainerRef;
    this.templateRef = templateRef;
    this.stepDefinition = new StepDefinition();
    this.created = false;
  }

  public setStep(step: number): void {
    this.stepDefinition.step = step;
  }

  public setConf(conf: IStepCaseConf): void {
    this.stepDefinition.name = conf.name;
    this.stepDefinition.description = conf.description;
  }

  public create(): void {
    this.created = true;
    this.viewContainerRef.createEmbeddedView(this.templateRef);
  }

  public destroy(): void {
    this.created = false;
    this.viewContainerRef.clear();
  }

  public enforceState(created: boolean): void {
    if (created && !this.created) {
      this.create();
    } else if (!created && this.created) {
      this.destroy();
    }
  }
}

