/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tree-root.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tree-node/tree-node.component.ngfactory";
import * as i3 from "../tree-node/tree-node.component";
import * as i4 from "../tree-node-children/tree-node-children.component.ngfactory";
import * as i5 from "../tree-node-children/tree-node-children.component";
import * as i6 from "@angular/common";
import * as i7 from "./tree-root.component";
var styles_TreeRootComponent = [i0.styles];
var RenderType_TreeRootComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TreeRootComponent, data: {} });
export { RenderType_TreeRootComponent as RenderType_TreeRootComponent };
function View_TreeRootComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-tree-node-component", [], null, null, null, i2.View_TreeNodeComponent_0, i2.RenderType_TreeNodeComponent)), i1.ɵdid(1, 49152, null, 0, i3.TreeNodeComponent, [], { node: [0, "node"], index: [1, "index"], templates: [2, "templates"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.treeModel.root; var currVal_1 = 0; var currVal_2 = _co.templates; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_TreeRootComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-tree-node-children-component", [], null, null, null, i4.View_TreeNodeChildrenComponent_0, i4.RenderType_TreeNodeChildrenComponent)), i1.ɵdid(1, 49152, null, 0, i5.TreeNodeChildrenComponent, [], { node: [0, "node"], templates: [1, "templates"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.treeModel.root; var currVal_1 = _co.templates; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TreeRootComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeRootComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeRootComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.treeModel.showRoot; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.treeModel.showRoot; _ck(_v, 4, 0, currVal_1); }, null); }
function View_TreeRootComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_TreeRootComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_TreeRootComponent_5)), i1.ɵdid(1, 540672, null, 0, i6.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { $implicit: 0, treeModel: 1, templates: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.treeModel, _co.treeModel, _co.templates); var currVal_1 = _co.templates.treeRootTemplate; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TreeRootComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeRootComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeRootComponent_4)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.templates.treeRootTemplate; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.templates.treeRootTemplate; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TreeRootComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-tree-root-component", [], null, null, null, View_TreeRootComponent_0, RenderType_TreeRootComponent)), i1.ɵdid(1, 49152, null, 0, i7.TreeRootComponent, [], null, null)], null, null); }
var TreeRootComponentNgFactory = i1.ɵccf("slx-tree-root-component", i7.TreeRootComponent, View_TreeRootComponent_Host_0, { treeModel: "treeModel", templates: "templates" }, {}, []);
export { TreeRootComponentNgFactory as TreeRootComponentNgFactory };
