import { EssEntity } from './../../../../../../organization/models/lookup/ess-entity';
import { EssLookupManageService } from './../../../../../../organization/services/lookup/ess-lookup-manage.service';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { AddEmployeeWizardActions } from '../../../../store/index';
import { AddEmployeeModel, AddEmployeeRestrictions } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { LookupApiService } from '../../../../../../organization/services/lookup/lookup-api.service';
import { ModalAnchorDirective } from '../../../../../../common/directives/index';
import { EmployeeActivitiesApiService } from '../../../../services/employee-activities/employee-add-api.service';
import { EmployeeValidatorAdapter, EmployeeDefinitionsApiService } from '../../../../../../organization/services/index';
import { TimeclockDefinition, EssDefaultPassword } from '../../../../../../organization/models/lookup/index';
import { ModalService, SelectableItemsProducer } from '../../../../../../common/services/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../../../../common/components/index';
import { AppSettingsManageService } from '../../../../../../app-settings/services/index';
import { AppServerConfig } from '../../../../../../app-settings/model/app-server-config';
import { EssTemplateApiService } from '../../../../../../app-modules/ess-templates/services/index';
import { EssTemplate } from '../../../../../../app-modules/ess-templates/models/index';


@Component({
  moduleId: module.id,
  selector: 'slx-add-employee-additional',
  templateUrl: 'add-employee-additional.component.html',
  styleUrls: ['add-employee-additional.component.scss']
})
export class AddEmployeeAdditionalComponent extends AddEmployeeBasicComponent implements OnInit, OnDestroy {

  @Input() public addEmployeeRestrictions: AddEmployeeRestrictions;
  @mutableSelect(['addEmployeeWizard', 'model'])
  public addEmployeeWizard$: Observable<AddEmployeeModel>;
  public isPwdVisible: boolean;
  public essTemplatesEnabled: boolean;
  public essTemplate: EssTemplate;
  public state: {
    isTimeclockLoading: boolean;
  };

  public get canSetEssEnabledInd(): boolean {
    if (!this.addEmployeeModel.email1 && !this.addEmployeeModel.mobilePhone) {
      return false;
    }
    return true;
  }

  public get essEnabledInd(): boolean {
    if (!this.addEmployeeRestrictions) {
      return false;
    }
    return this.addEmployeeRestrictions.essEnabledInd.defaultFieldValue;
  }

  public get essTemplateRequirementsInvalid(): boolean {
    return this.essTemplateSsnError || this.essTemplateDOBError;
  }

  public get essTemplateSsnError(): boolean {
    if((this.addEmployeeModel.employeeType.name === 'AFT' || this.addEmployeeModel.employeeType.name === 'APT') || this.addEmployeeModel.organization.id == 1000)
    {
      return false;
    }
    if (!this.essTemplatesEnabled || !this.essTemplate) {
      return false;
    }
    return this.essTemplate.defaultPwdTemplate === EssDefaultPassword.last4SSN && !this.addEmployeeModel.ssn;
  }

  public get essTemplateDOBError(): boolean {
    if (!this.essTemplatesEnabled || !this.essTemplate) {
      return false;
    }
    return (this.essTemplate.defaultPwdTemplate === EssDefaultPassword.dobMMDD
      || this.essTemplate.defaultPwdTemplate === EssDefaultPassword.dobMMYY)
      && !this.addEmployeeModel.birthDate;
  }

  public get timeclocksEnabledInd(): boolean {
    if (!this.addEmployeeRestrictions) {
      return false;
    }
    return this.addEmployeeRestrictions.timeclocksEnabledInd.defaultFieldValue;
  }

  public get badgeIdValidation(): string {
    return EmployeeValidatorAdapter.badgeIdValidation;
  }

  public timeMethods: EssEntity[];

  @ViewChild(ModalAnchorDirective, {static: true})
  private modalAnchor: ModalAnchorDirective;

  constructor(addEmployeeWizardActions: AddEmployeeWizardActions,
    private lookupApiService: LookupApiService,
    private employeeActivitiesApiService: EmployeeActivitiesApiService,
    public employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter,
    private employeeDefinitionApi: EmployeeDefinitionsApiService,
    private selectableItemsProducer: SelectableItemsProducer,
    private modalService: ModalService,
    public essLookupService: EssLookupManageService,
    private appSettingsManageService: AppSettingsManageService,
    private essTemplateApiService: EssTemplateApiService
  ) {
    super(addEmployeeWizardActions);
    this.isPwdVisible = false;
    this.state = {
      isTimeclockLoading: false
    };

    this.essLookupService.onLoaded$.subscribe((value: any) => {
      this.timeMethods = this.essLookupService.timeMethods.concat({ id: 'none', name: 'None' });
    });
    this.appSettingsManageService.getAppServerConfig()
      .then((config: AppServerConfig) => {
        this.essTemplatesEnabled = config.essTemplatesEnabled;
        this.loadEssTemplate();
      });
  }


  public ngOnInit(): void {
    this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe((model: AddEmployeeModel) => {
      this.onWizard(model);
      if (!_.get(this.addEmployeeModel, 'email1') && !_.get(this.addEmployeeModel, 'mobilePhone')) {
        this.addEmployeeModel.enableTimesheetsAccess = false;
      }
      if (this.hasAssignedOrgLevel()) {
        this.loadBadgeOptionsLookup();
      }
    });
  }
  public togglePasswordVisibility(): void {
    this.isPwdVisible = !this.isPwdVisible;

  }
  public generateBadgeId(): void {
    ConfirmDialogComponent.openDialog(
      'Confirmation',
      'Are you sure you want to generate a badge ID?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          //generateBageId
          this.addEmployeeModel.badgeId = '';
          this.employeeDefinitionApi.generateBadgeId(this.addEmployeeModel.organization.id)
            .then((badgeId: string) => {
              this.addEmployeeModel.badgeId = badgeId;
            });
        }
      });
  }
  public generateEssPassword(): void {
    ConfirmDialogComponent.openDialog(
      'Confirmation',
      'Generate ESS Password?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          //generatePassword
          this.employeeDefinitionApi.generatePassword()
            .then((pwd: number) => {
              this.addEmployeeModel.password = pwd.toString();
            });
        }
      });
  }
  private loadBadgeOptionsLookup(): void {
    if (!this.addEmployeeModel) return;
    if (this.addEmployeeModel.timeclockDefinitions && this.addEmployeeModel.timeclockDefinitions.length > 0) return;
    this.state.isTimeclockLoading = true;
    this.lookupApiService.getTimeclockDefinitions(this.addEmployeeModel.department.orgLevelId)
      .then((timeclockLookup: TimeclockDefinition[]) => {
        this.addEmployeeModel.timeclockDefinitions = this.selectableItemsProducer.produceSelectable<TimeclockDefinition>(timeclockLookup);
        this.state.isTimeclockLoading = false;
      });
  }

  private async loadEssTemplate(): Promise<any> {
    this.essTemplate = await this.essTemplateApiService.getTemplateForPosition(this.addEmployeeModel.position.id);
  }
}
