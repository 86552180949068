import { Component, Input, ChangeDetectionStrategy, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { DataResultIterator } from '@progress/kendo-angular-grid/dist/es2015/data/data.collection';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { AttestationType, RestrictedAnswer } from '../../../../../app/organization';
import { appConfig } from '../../../../app.config';
import { OrgLevel } from '../../../../state-model/models/index';
import { IPunchAttestationQuestion } from '../../models/index';
import { PunchAttestationGroup } from '../../models/punch-attestation-group';
import { PunchAttestationManagementService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-punch-attestation-section',
  templateUrl: 'punch-attestation-section.component.html',
  styleUrls: ['punch-attestation-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchAttestationSectionComponent implements OnInit {

  public appConfig = appConfig;
  public isCollapsed: boolean;

  @Input()
  public orgLevel: OrgLevel;

  @Input()
  public group: PunchAttestationGroup;

  @Input()
  public attestationTypeLookup: AttestationType[];

  @Input()
  public restrictedAnswerLookup: RestrictedAnswer[];

  @Input()
  public punchAttestationAnswerCodes: any;

  @Input()
  public punchAttestationPunchType: any;

  public areGroupNamesValid = true;
  public isEditingQuestion = [];
  editedDataItem: IPunchAttestationQuestion;

  @ViewChild('form', null) form: NgForm;

  public get isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }

  private MAX_QUESTIONS_PER_GROUP = 20;
  private MAX_QUESTION_PER_GROUP_FOR_LEGACY = 4;

  public gridData: GridDataResult;
  public sort: SortDescriptor[] = [];

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  constructor(
    private managementService: PunchAttestationManagementService,
    private changeDetector: ChangeDetectorRef
  ) { }


  public ngOnInit(): void {
    this.group.questions.map(question => {
      question.restrictedAnswerId = this.restrictedAnswerLookup.find(x => x.restrictedAnswerId == question.restrictedAnswerId);
      question.punchType = this.punchAttestationPunchType.find(x => x.id == question.punchType);
      question.defaultAnswer = this.punchAttestationAnswerCodes.find(x => x.id == question.defaultAnswer);
      question.attestationTypeId = this.attestationTypeLookup.find(x => x.attestationTypeId == question.attestationTypeId);
      return question;
    });
    this.refreshGrid();
  }

  public save(): void {
    if (this.isFormValid && this.isGroupNameEntered) {
      this.group.questions = this.group.questions.filter(x => x !== null);
      this.managementService.saveGroup(this.orgLevel, this.group);
    }
  }

  public addQuestion() {
    if (this.isAbleToAddQuestions && this.isFormValid && this.isGroupNameEntered) {
      var length = this.group.questions.length;
      const question: IPunchAttestationQuestion = {
        id: 0,
        order: this.group.questions.reduce((op, item) => op = op > item.order ? op : item.order, 0) + 1,
        textLine1: '',
        textLine2: '',
        defaultAnswer: 0,
        punchType: null,
        attestationTypeId: null,
        restrictedAnswerId: 0,
        enable: false
      }
      this.setLocalDataItem(question);
      this.group.questions.push(question);
      this.refreshGrid();
      this.grid.editRow(length);
    }
  }

  get isGroupNameEntered() {
    return this.group.groupName !== null && this.group.groupName.trim() !== '' ? true : false;
  }

  get isAbleToAddQuestions() {
    return this.group.questions ? this.group.questions.length < (this.isLegacyClockGroup ? this.MAX_QUESTION_PER_GROUP_FOR_LEGACY : this.MAX_QUESTIONS_PER_GROUP) : false;
  }

  public delete(e): void {
    if(e.pointerType === 'mouse')
    {
      this.managementService.deleteGroup(this.orgLevel, this.group);
    }
  }

  public deleteQuestion(question: IPunchAttestationQuestion): void {
    if (question.id === null || question.id === 0) {
      this.group.questions = this.group.questions.filter(x => x.order !== question.order);
    } else {
      this.managementService.deleteQuestion(this.orgLevel, this.group, question);
    }
    this.refreshGrid();
  }

  get areGroupNameValid() {
    return this.managementService.areGroupNameValid(this.group);
  }

  attestationTypeName(attestationTypeId: AttestationType) {
    return this.attestationTypeLookup && attestationTypeId !== null ? this.attestationTypeLookup.find(x => x.attestationTypeId === attestationTypeId.attestationTypeId).attestationTypeName : '';
  }

  restrictedAnswerName(restrictedAnswerId: Number) {
    return this.restrictedAnswerLookup && restrictedAnswerId !== 0 ? this.restrictedAnswerLookup.find(x => x.restrictedAnswerId === restrictedAnswerId).restrictedAnswerName : '';
  }

  get isLegacyClockGroup() {
    return this.group ? this.group.groupName.toLocaleLowerCase() === this.managementService.legacyclockquestiongroup.toLocaleLowerCase() : false;
  }

  get getmaxlengths() {
    return this.isLegacyClockGroup ? 50 : 500;
  }

  get isFormValid() {
    return this.form.valid;
  }

  public sortChangeHandler(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  private refreshGrid(): void {
    this.gridData = {
      data: orderBy(this.group.questions, this.sort),
      total: this.group.questions.length
    };
  }

  public removeHandler(event: { dataItem: IPunchAttestationQuestion, rowIndex: number }): void {
    this.deleteQuestion(event.dataItem);
  }

  public editHandler(event: { dataItem: IPunchAttestationQuestion, rowIndex: number }): void {
    this.setLocalDataItem(event.dataItem);
    this.grid.editRow(event.rowIndex);
  }

  public saveHandler(event: { dataItem: IPunchAttestationQuestion, rowIndex: number }): void {
    if (this.isFormValid) {
      this.grid.closeRow(event.rowIndex);
    }
  }

  public cancelHandler(event: { dataItem: IPunchAttestationQuestion, rowIndex: number }) {
    if (event.dataItem.id !== 0) {
      const index = this.group.questions.findIndex(x => x.id == event.dataItem.id);
      this.group.questions[index] = this.editedDataItem;
    } else {
      const index = this.group.questions.findIndex(x => x.order == event.dataItem.order);
      this.group.questions[index] = this.editedDataItem;
    }
    this.grid.closeRow(event.rowIndex);
  }

  private setLocalDataItem(dataItem: IPunchAttestationQuestion){
    this.editedDataItem = {
      id: dataItem.id,
      attestationTypeId: dataItem.attestationTypeId,
      defaultAnswer: dataItem.defaultAnswer,
      enable: dataItem.enable,
      order: dataItem.order,
      punchType: dataItem.punchType,
      restrictedAnswerId: dataItem.restrictedAnswerId,
      textLine1: dataItem.textLine1,
      textLine2: dataItem.textLine2,
    };
  }
  
  get isconfigure(){
    return this.managementService.configurePunchAttestation.getValue() ? true : false;
  }
}
