import { IExportDataConfigurationParameter, ExportDataConfigurationParameter } from './export-data-configuration-parameter';
import { ExportDataExecutionItem, IExportDataExecutionItem } from './export-data-execution';

export interface IExportDataConfigurationInfo {
  id: number;
  name: string;
  parameters: IExportDataConfigurationParameter[];
  lastExecuted: IExportDataExecutionItem;
  history: IExportDataExecutionItem[];
}

export class ExportDataConfigurationInfo {
  public id: number;
  public name: string;
  public parameters: ExportDataConfigurationParameter[];
  public lastExecuted: ExportDataExecutionItem;
  public history: ExportDataExecutionItem[];
}
