import { Injectable } from '@angular/core';
import { GetHiredLink, IGetHiredLink } from '../../../models/index';
@Injectable()
export class GetHiredMenuMapService {

  public mapToModel(item: IGetHiredLink): GetHiredLink {
    let link: GetHiredLink = new GetHiredLink();
    link.linkUrl = item.landing_uri;
    link.errorCode = +item.errorCode;
    link.errorMessage = item.errorMessage;
    link.status = item.status;
    return link;
  }
}
