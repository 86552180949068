export interface IOpenShiftShortDetails {
  dateOn: Date;
  openShiftCount: number;
  overShiftCount: number;
  messageCount: number;
  partnerShiftCount:number;
 }

export class OpenShiftShortDetails {
  public dateOn: Date;
  public openShiftCount: number;
  public overShiftCount: number;
  public messageCount: number;
  public partnerShiftCount:number;
}
