import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { NavigationMenuItem, INavigationMenuItem } from '../../models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { organizationConfig } from '../../../organization/organization.config';
import { NavigationMenuMapService } from './navigation-menu-map.service';
import { ApiUtilService } from '../../../common/services/index';
import { Meta } from '../../../core/models/api/meta';
import { Assert } from '../../../framework/index';
import { CacheType } from '../../../common/models/cache/cache-definition';

@Injectable()
export class NavigationMenuApiService {
  constructor(
    private navigationMenuMapService: NavigationMenuMapService,
    private apiUtilService: ApiUtilService) {
  }

  public getApplicationMenu(orgLevel: OrgLevel, updateCacheForced: boolean = false): Promise<NavigationMenuItem[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.api.orglevels}/${orgLevel.id}/${organizationConfig.api.menu}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<NavigationMenuItem[]> =
      this.apiUtilService
        .cachedRequest<INavigationMenuItem[], Meta>(request, CacheType.longTerm, updateCacheForced)
        .then((response: ResponseBody<INavigationMenuItem[], Meta>) =>
          this.navigationMenuMapService.mapToMenu(response.data));
    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.apiroot}`;
  }
}
