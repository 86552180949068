import { UserMenuPinState } from '../../models/user-menu-pin/user-menu-pin-state';
import * as i0 from "@angular/core";
var UserMenuPinMapService = /** @class */ (function () {
    function UserMenuPinMapService() {
    }
    UserMenuPinMapService.prototype.mapToResponse = function (dto) {
        var response = new UserMenuPinState();
        response.isMenuPinned = dto.isMenuPinned;
        return response;
    };
    UserMenuPinMapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserMenuPinMapService_Factory() { return new UserMenuPinMapService(); }, token: UserMenuPinMapService, providedIn: "root" });
    return UserMenuPinMapService;
}());
export { UserMenuPinMapService };
