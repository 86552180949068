import { DateRange } from './../../../core/models/index';
import { Component, Input, HostBinding, ViewChild, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { appConfig, IApplicationConfig } from '../../../app.config';

import { createValuAccessor } from '../../utils/index';
export type rangeItem = { icon: string, seconds: number, text: string };
//export type dateRange = { startDate: Date, endDate: Date };

@Component({
  moduleId: module.id,
  selector: 'slx-date-range-selector',
  templateUrl: 'date-range-selector.component.html',
  styleUrls: ['date-range-selector.component.scss'],
  providers: [createValuAccessor(DateRangeSelectorComponent)]
})
export class DateRangeSelectorComponent implements ControlValueAccessor {
  @Input()
  public set ranges(rs: rangeItem[]) {
    this.m_ranges = rs;
    this.dataModel = [];
    this.selectedRange = _.first(rs);
    _.forEach(rs, (r: rangeItem) => {
      let model: any = Object.assign({
        click: (dataItem: any) => {
          this.rangeChanged(dataItem);
        }
      }, r);
      this.dataModel.push(model);
    });
  }

  public appConfig: IApplicationConfig;
  public selectedDate: Date;
  public selectedEndDate: Date;
  public selectedRange: rangeItem;
  public dataModel: Array<any>;

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private m_ranges: rangeItem[];

  constructor(private changeDetector: ChangeDetectorRef) {
    this.appConfig = appConfig;
  }

  public writeValue(value: DateRange): void {
    if (!value) {
      this.selectedDate = moment().startOf('day').toDate();
      return;
    }
    this.selectedDate = value.startDate;
    let seconds: number = moment(value.endDate).unix() - moment(value.startDate).unix();
    this.selectedRange = _.find(this.m_ranges, (i: rangeItem) => { return i.seconds === seconds; });
    if (!this.selectedRange) {
      let days: number = _.floor(seconds / 86400, 0);
      let sec: number = days * 86400;
      this.selectedRange = _.find(this.m_ranges, (i: rangeItem) => { return i.seconds === sec; });
      if (!this.selectedRange) {
        let one: boolean = days === -1 || days === 1;
        this.selectedRange = { icon: '', seconds: seconds, text: `${days} day${one ? '' : 's'}` };
      }
    }
    this.calcRange();
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = (value: any) => { Promise.resolve(null).then(() => fn(value)); };
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  public rangeChanged(item: rangeItem): void {
    this.selectedRange = item;
    this.onChangeCallback(this.calcRange());
  }
  public onDateChanged(): void {
    this.onChangeCallback(this.calcRange());
  }

  private calcRange(): DateRange {
    let r: DateRange = {
      startDate: this.selectedDate,
      endDate: moment(this.selectedDate).add(this.selectedRange.seconds, 's').toDate()
    };
    this.selectedEndDate = r.endDate;
    return r;
  }
}
