export * from './master-shedule/index';
export * from './census-entries/census-entries.component';
export * from './detail-screen/detail-screen.component';
export * from './filter-and-groups/filter-and-groups.component';

export * from './vacation-planner/index';
export * from './shift-replacement/index';
export * from './individual-schedule/index';
export * from './daily-unit-grid/daily-unit-grid.component';
export * from './daily-unit-router/daily-unit-router.component';
export * from './post-schedule/post-schedule.component';
export * from './posted-schedules/posted-schedules.component';
export * from './daily-unit-grid/daily-unit-grid-employees/daily-unit-grid-employees.component';
export * from './daily-unit-grid/switch-employee-shift/daily-unit-grid-switch-grid-employee-shift.component';
export * from './daily-unit-router/daily-unit-router.component';

export * from './open-shift-management/index';
export * from './restore-schedule/restore-schedule.component';
export * from './leave-request/leave-request.component';
export * from './leave-request-confirm/leave-request-confirm.component';
export * from './leave-request-details/leave-request-details.component';
export * from './leave-request-toolbar/leave-request-toolbar.component';
export * from './leave-request-grid/leave-request-grid.component';
export * from './schedule-entry/scheduled-shift/scheduled-shift.component';
export * from './schedule-entry/scheduled-shift/shift-main-section/scheduled-shift-main-section.component';
export * from './schedule-entry/scheduled-shift/shift-partial-absence-section/scheduled-shift-partial-absence-section.component';
export * from './schedule-entry/scheduled-shift/shift-lateness-section/scheduled-shift-lateness-section.component';
export * from './schedule-entry/scheduled-shift/shift-overtime-section/scheduled-shift-overtime-section.component';
export * from './schedule-entry/scheduled-shift/shift-notes-section/scheduled-shift-notes-section.component';
export * from './schedule-entry/scheduled-shift/shift-replacements-section/scheduled-shift-replacements-section.component';
export * from './schedule-entry/schedule-entry.component';
export * from './daily-unit-grid/daily-unit-grid-detail-group-view-settings/detail-group-view-settings.component';
export * from './census-entries-popup/census-entries-popup.component';
export * from './census-entries-page/census-entries-page.component';
export * from './budgeted-pars/budgeted-pars-popup.component';
export * from './open-position-control/open-position-control-toolbar/open-position-control-toolbar.component';
export * from './open-position-control/position-group-slide-out/position-group-slide-out.component';

import { masterScheduleComponents, masterScheduleEntryComponents } from './master-shedule/index';
import { PostScheduleComponent } from './post-schedule/post-schedule.component';
import { PostedSchedulesComponent } from './posted-schedules/posted-schedules.component';
import { OpenPostionControlComponent } from './open-position-control/open-position-control.component';
import { OpenPositionControlToolbarComponent } from './open-position-control/open-position-control-toolbar/open-position-control-toolbar.component';

import {
  VacationPlannerComponent, VacationPlannerHeaderComponent, VacationGridComponent,
  VacationEmployeeDialogComponent, VacationEmployeeDetailsComponent, VacationEmployeeSummaryComponent,
  WeeklyAbsencesGridComponent
} from './vacation-planner/index';
import { CensusEntriesComponent } from './census-entries/census-entries.component';
import { DetailScreenComponent } from './detail-screen/detail-screen.component';
import { FilterAndGroupsComponent } from './filter-and-groups/filter-and-groups.component';
import { ScheduledShiftMainSectionComponent } from './schedule-entry/scheduled-shift/shift-main-section/scheduled-shift-main-section.component';
import { ScheduledShiftPartialAbsenceSectionComponent } from './schedule-entry/scheduled-shift/shift-partial-absence-section/scheduled-shift-partial-absence-section.component';
import { ScheduledShiftLatenessSectionComponent } from './schedule-entry/scheduled-shift/shift-lateness-section/scheduled-shift-lateness-section.component';
import { ScheduledShiftOvertimeSectionComponent } from './schedule-entry/scheduled-shift/shift-overtime-section/scheduled-shift-overtime-section.component';
import { ScheduledShiftNotesSectionComponent } from './schedule-entry/scheduled-shift/shift-notes-section/scheduled-shift-notes-section.component';
import { ScheduledShiftReplacementsSectionComponent } from './schedule-entry/scheduled-shift/shift-replacements-section/scheduled-shift-replacements-section.component';

import { ScheduledShiftComponent } from './schedule-entry/scheduled-shift/scheduled-shift.component';
import { ScheduleEntryComponent } from './schedule-entry/schedule-entry.component';
import { shiftReplacementComponents, entryShiftReplacementComponents } from './shift-replacement/index';
import { DailyUnitGridComponent } from './daily-unit-grid/daily-unit-grid.component';
import { DailyUnitGridEmployeesComponent } from './daily-unit-grid/daily-unit-grid-employees/daily-unit-grid-employees.component';
import { RestoreScheduleComponent } from './restore-schedule/restore-schedule.component';
import { EditOpenShiftCountComponent } from './open-shift-management/edit-open-shift-count/edit-open-shift-count.component';
import { LeaveRequestComponent } from './leave-request/leave-request.component';
import { LeaveRequestConfirmComponent } from './leave-request-confirm/leave-request-confirm.component';
import { LeaveRequestDetailsComponent } from './leave-request-details/leave-request-details.component';
import { LeaveRequestToolbarComponent } from './leave-request-toolbar/leave-request-toolbar.component';
import { LeaveRequestGridComponent } from './leave-request-grid/leave-request-grid.component';
import { SwitchGridEmployeeShiftComponent } from './daily-unit-grid/switch-employee-shift/daily-unit-grid-switch-grid-employee-shift.component';
import { DetailGroupGridViewSettingsComponent } from './daily-unit-grid/daily-unit-grid-detail-group-view-settings/detail-group-view-settings.component';
import { ExportExcelGridComponent } from './daily-unit-grid/export-excel-grid/export-excel-grid.component';

export const exportSchedulerComponents: any = [
  CensusEntriesComponent
];
import { individualScheduleComponents } from './individual-schedule/index';
import { openShiftManagementComponents } from './open-shift-management/index';
import { CensusEntriesPopupComponent } from './census-entries-popup/census-entries-popup.component';
import { scheduleDailyDetailsComponents, entryScheduleDailyDetailsComponents } from './schedule-daily-details/index';
import { CensusEntriesPageComponent } from './census-entries-page/census-entries-page.component';
import { shiftSwapComponents, ShiftSwapApproveDenyPopupComponent } from './shift-swap';
import { BudgetedParsPopupComponent } from './budgeted-pars/budgeted-pars-popup.component';
import { OverlappingSchedulePopupComponent } from './individual-schedule/overlapping-schedule-popup/overlapping-schedule-popup.component';
import { ShiftRequestPopupComponent } from './shift-request-popup/shift-request-popup.component';
import { ShiftRequestGridComponent } from './shift-request-grid/shift-request-grid.component';
import { DailyUnitRouterComponent } from './daily-unit-router/daily-unit-router.component';
import { DailyUnitEmployeesComponent } from './daily-unit/daily-unit-employees/daily-unit-employees.component';
import { DailyUnitComponent } from './daily-unit/daily-unit.component';
import { SwitchEmployeeShiftComponent } from './daily-unit/switch-employee-shift/switch-employee-shift.component';
import { DetailGroupViewSettingsComponent } from './daily-unit/detail-group-view-settings/detail-group-view-settings.component';

import { SchedulesNotPostedComponent } from './schedules-not-posted/schedules-not-posted.component';
import { AddOpenShiftsV1Component } from './add-open-shifts/add-open-shifts-v1/add-open-shifts-v1.component';
import { ScheduledRotationsComponent } from './scheduled-rotations/scheduled-rotations.component';
import { PositionGroupSlideOutComponent } from './open-position-control/position-group-slide-out/position-group-slide-out.component';
import { OpenPositionControlConfigurationComponent } from './open-position-control/open-position-control-configuration/open-position-control-configuration.component';
import { ScheduledGenerateRotationComponent } from './scheduled-rotations/scheduled-generate-rotation/scheduled-generate-rotation.component';
import { ShiftOverlapWarningMessageComponent } from './../../../app/common/components/shift-overlap-warning-message/shift-overlap-warning-message.component';
import { SrtViewIndividualScheduleDialogComponent } from './scheduled-rotations/scheduled-rotations-employee-grid/srt-view-individual-schedule-dialog/srt-view-individual-schedule-dialog.component';
import { SrtViewIndividualScheduleComponent } from './scheduled-rotations/scheduled-rotations-employee-grid/srt-view-individual-schedule/srt-view-individual-schedule.component';
import { DailyUnitViewSettingsPopupComponent } from './daily-unit-grid/daily-unit-view-settings-popup/daily-unit-view-settings-popup.component';
import { DailyUnitFilterDialogComponent } from './daily-unit-grid/daily-unit-filter-dialog/daily-unit-filter-dialog.component';


export const entrySchedulerComponent: any[] = [
  RestoreScheduleComponent,
  VacationEmployeeDialogComponent,
  EditOpenShiftCountComponent,
  LeaveRequestConfirmComponent,
  CensusEntriesPopupComponent,
  ...masterScheduleEntryComponents,
  ...entryShiftReplacementComponents,
  ...entryScheduleDailyDetailsComponents,
  ShiftSwapApproveDenyPopupComponent,
  BudgetedParsPopupComponent,
  OverlappingSchedulePopupComponent,
  ShiftRequestPopupComponent,
  AddOpenShiftsV1Component,
  PositionGroupSlideOutComponent,
  OpenPositionControlConfigurationComponent,
  ScheduledGenerateRotationComponent,
  ShiftOverlapWarningMessageComponent,
  SrtViewIndividualScheduleDialogComponent,
  SrtViewIndividualScheduleComponent,
  DailyUnitViewSettingsPopupComponent,
  DailyUnitFilterDialogComponent
];

export const schedulerComponents: any[] = [
  ...masterScheduleComponents,
  DetailScreenComponent,
  FilterAndGroupsComponent,
  ScheduledShiftPartialAbsenceSectionComponent,
  ScheduledShiftLatenessSectionComponent,
  ScheduledShiftOvertimeSectionComponent,
  ScheduledShiftNotesSectionComponent,
  ScheduledShiftReplacementsSectionComponent,
  ScheduledShiftMainSectionComponent,
  ScheduledShiftComponent,
  ScheduleEntryComponent,
  VacationPlannerComponent,
  VacationPlannerHeaderComponent,
  VacationGridComponent,
  VacationEmployeeDialogComponent,
  VacationEmployeeDetailsComponent,
  VacationEmployeeSummaryComponent,
  WeeklyAbsencesGridComponent,
  DailyUnitComponent,
  DailyUnitEmployeesComponent,
  DailyUnitGridComponent,
  DailyUnitRouterComponent,
  DailyUnitGridEmployeesComponent,
  ExportExcelGridComponent,
  SwitchEmployeeShiftComponent,
  SwitchGridEmployeeShiftComponent,
  RestoreScheduleComponent,
  ...shiftReplacementComponents,
  ...exportSchedulerComponents,
  ...individualScheduleComponents,
  ...openShiftManagementComponents,
  PostScheduleComponent,
  PostedSchedulesComponent,
  OpenPostionControlComponent,
  OpenPositionControlToolbarComponent,
  PositionGroupSlideOutComponent,
  OpenPositionControlConfigurationComponent,
  LeaveRequestComponent,
  LeaveRequestDetailsComponent,
  LeaveRequestToolbarComponent,
  LeaveRequestGridComponent,
  DetailGroupViewSettingsComponent,
  DetailGroupGridViewSettingsComponent,
  CensusEntriesPopupComponent,
  LeaveRequestConfirmComponent,
  CensusEntriesPageComponent,
  ...scheduleDailyDetailsComponents,
  ...shiftSwapComponents,
  BudgetedParsPopupComponent,
  OverlappingSchedulePopupComponent,
  ShiftRequestPopupComponent,
  ShiftRequestGridComponent,
  SchedulesNotPostedComponent,
  ScheduledRotationsComponent,
  ScheduledGenerateRotationComponent,
  SrtViewIndividualScheduleDialogComponent,
  SrtViewIndividualScheduleComponent,
  DailyUnitViewSettingsPopupComponent
];

