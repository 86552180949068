import { NgModule, Optional, SkipSelf, Injectable, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HotkeyModule } from 'angular2-hotkeys';

import { coreServices } from './services/index';
import { GlobalErrorHandler } from './handlers/global-error-handler';
import { exportCoreComponents, coreComponents } from './components/index';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HotkeyModule
  ],
  declarations: [...coreComponents],
  providers: [
    ...coreServices,
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ],
  exports: [...exportCoreComponents]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
